export default {
  'oifde.traceCause.000001': '呼叫转移图元中转移类型没有配置',
  'oifde.traceCause.000002': '接口异常，inaction传入错误 {0}',
  'oifde.traceCause.000003': '复合图元没有找到',
  'oifde.traceCause.000004': '没有找到开始节点',
  'oifde.traceCause.000005': '系统异常，内部循坏次数太多。',
  'oifde.traceCause.000006': '图元处理不正常',
  'oifde.traceCause.000007': '出口分支为空',
  'oifde.traceCause.000008': '系统内部错误',
  'oifde.traceCause.000009': '没有找到该分支 {0}',
  'oifde.traceCause.000010': '流程转移图元无法转移到下个节点 {0}',
  'oifde.traceCause.000011': '图元类型错误',
  'oifde.traceCause.000012': '表达式 {0} 执行错误',
  'oifde.traceCause.000013': '执行第三方接口失败',
  'oifde.traceCause.000014': '应答模板与应答变量没有选中',
  'oifde.traceCause.000015': '发送短信图元没有配置短信模板',
  'oifde.traceCause.000016': '应答模板语言与语种设置不一致 {0}',
  'oifde.traceCause.000017': '短信发送图元短信模板语言与语种设置不一致 {0}',
  'oifde.traceCause.000018': '流程转移图元没有配置目标流程和目标节点',
  'oifde.traceCause.000019': '没有找到租户',
  'oifde.traceCause.000020': '租户已经停机',
  'oifde.traceCause.000021': '重复初始化',
  'oifde.traceCause.000022': '该复合图元没有开始节点',
  'oifde.traceCause.000023': '系统内部错误',
  'oifde.traceCause.000024': '流程内部执行异常',
  'oifde.traceCause.000025': '内部异常超过3次自动挂机',
  'oifde.traceCause.000026': '流程没有初始化',
  'oifde.traceCause.000028': '流程已经结束',
  'oifde.traceCause.000029': '当前图元没有找到出口,节点ID为{0}，节点名称为{1}',
  'oifde.traceCause.000030': '图元数据不正常,节点ID为{0}，节点名称为{1}',
  'oifde.traceCause.000031': '系统内部错误',
  'oifde.traceCause.000032': '流程内部执行异常',
  'oifde.traceCause.000033': '呼叫实例信息已经不存在',
  'oifde.traceCause.000034': '结束图元不存在',
  'oifde.traceCause.000035': '检查auth_token错误',
  'oifde.traceCause.000036': '检查auth_token错误',
  'oifde.traceCause.000037': '检查auth_token错误',
  'oifde.traceCause.000038': '处理下一个节点失败',
  'oifde.traceCause.000039': '调用tuc失败 {0}',
  'oifde.traceCause.000040': '没有找到这个错误{0}',
  'oifde.traceCause.000041': '执行存储过程错误{0}',
  'oifde.traceCause.000044': 'URL中的IP和端口不在白名单中',
  'oifde.traceAdvice.000001': '呼叫转移图元配置转移类型',
  'oifde.traceAdvice.000002': '联系系统管理员处理接口传入参数',
  'oifde.traceAdvice.000003': '请确认系统是否存在该复合图元',
  'oifde.traceAdvice.000004': '检查该流程是否已经正常发布或者流程是否正常',
  'oifde.traceAdvice.000005': '联系系统管理员检查系统问题',
  'oifde.traceAdvice.000006': '联系系统管理员检查该图元逻辑是否有问题',
  'oifde.traceAdvice.000007': '联系系统管理员检查该图元逻辑是否有问题',
  'oifde.traceAdvice.000008': '联系系统管理员检查系统问题',
  'oifde.traceAdvice.000009': '检查当前执行的图元的出口是否有未处理分支，建议所有图元都有一个缺省的无条件的分支',
  'oifde.traceAdvice.000010': '检查流程转移图元配置是否正常',
  'oifde.traceAdvice.000011': '联系系统管理员检查系统问题',
  'oifde.traceAdvice.000012': '检查表达式配置语法是否正常，涉及到表达式中的变量是否有值等。',
  'oifde.traceAdvice.000013': '检查第三方接口是否正常',
  'oifde.traceAdvice.000014': '检查应答图元中，应答模板或者应答变量至少有一个不能为空',
  'oifde.traceAdvice.000015': '检查短信发送图元是否配置短信模板',
  'oifde.traceAdvice.000016': '检查资源模板中的内容以及语种配置，同时检查全局变量里的内置语种，以及流程编排中是否重新对语种进行了赋值，播放使用的语种，要在资源模板中配置',
  'oifde.traceAdvice.000017': '检查资源模板中的内容以及语种配置，同时检查全局变量里的内置语种，以及流程编排中是否重新对语种进行了赋值，播放使用的语种，要在资源模板中配置',
  'oifde.traceAdvice.000018': '检查流程转移图元是否配置正常',
  'oifde.traceAdvice.000019': '检查对话接口传入的接入码是否正常，系统中是否存在该接入码。',
  'oifde.traceAdvice.000020': '检查租户状态是否正常',
  'oifde.traceAdvice.000021': '检查第三方APP或者IVR是否重复发送同一个callid的初始化请求',
  'oifde.traceAdvice.000022': '检查复合图元是否正常，系统是否存在该复合图元。',
  'oifde.traceAdvice.000023': '联系系统管理员检查系统问题',
  'oifde.traceAdvice.000024': '请详细检查每个图元的错误信息，根据图元的错误信息做对应处理。',
  'oifde.traceAdvice.000025': '联系系统管理员检查系统问题',
  'oifde.traceAdvice.000026': '执行流程不正常，需要先调用初始化接口请求。',
  'oifde.traceAdvice.000028': '检查流程是否正常',
  'oifde.traceAdvice.000029': '检查图元是否配置了节点出口',
  'oifde.traceAdvice.000030': '联系系统管理员检查该图元配置数据是否有问题',
  'oifde.traceAdvice.000031': '联系系统管理员检查系统问题',
  'oifde.traceAdvice.000032': '请详细检查每个图元的错误信息，根据图元的错误信息做对应处理。',
  'oifde.traceAdvice.000033': '检查挂机请求接口是否发送了错误的callid',
  'oifde.traceAdvice.000034': '检查流程是否配置正常的结束图元',
  'oifde.traceAdvice.000035': '检查是否传入正确的auth_token',
  'oifde.traceAdvice.000036': '检查是否传入正确的auth_token',
  'oifde.traceAdvice.000037': '检查是否传入正确的auth_token',
  'oifde.traceAdvice.000038': '联系系统管理员检查系统问题',
  'oifde.traceAdvice.000039': '1、检查ODFS中配置的TUC地址端口是否正常，正常配置;2、检查TUC各个节点服务是否正常，恢复正常',
  'oifde.traceAdvice.000040': '检查存储过程是否正常',
  'oifde.traceAdvice.000041': '检查存储过程是否正常',
  'oifde.traceAdvice.000044': '请联系系统管理员添加白名单'
}
