<template>
  <sweet-dialog v-model="isShow" :title="title" width="50%" draggable destroy-on-close @close="close" :close-on-click-modal="false"
                :close-on-press-escape="false">
    <div class="video-play-h" @contextmenu.prevent="menuPlayer()">
      <video ref="contactVideoPlayer" :src="videoSrc" controls controlslist="nodownload" autoplay="autoplay" preload="auto"
              disablePictureInPicture style="width: 100%; height: 100%; object-fit: fill" :id="videoId">
      </video>
    </div>
  </sweet-dialog>
</template>

<script setup lang="ts">
import { computed, getCurrentInstance, onBeforeUnmount, ref, toRefs, watch } from "vue";
import AiccElMessage from '@/utils/el-message';

// 国际化封装
const { appContext: { config: { globalProperties } } } = getCurrentInstance() as any;
const i18n = (param: string, ...args: Array<any>): string => {
  return globalProperties.$t(param, args);
};

const props = defineProps({
  recordId: String, // 视频id
  vrcRecordId: String, // 录屏id
  videoType: String,  // 视频的播放类型
  title: String, // 弹框标题
});
const { recordId,vrcRecordId, title } = toRefs(props);
const isShow = ref<boolean>(true);
const contactVideoPlayer = ref();
const videoSrc = computed(() => {
  if (vrcRecordId?.value) {
    return `${location.protocol}//${location.host}/service-cloud/u-route/ccmanagement/recordplay/video?vrcRecordId=${vrcRecordId?.value}`;
  } else {
    return `${location.protocol}//${location.host}/service-cloud/u-route/ccmanagement/recordplay/video?recordId=${recordId?.value}`;
  }
});
const videoId = computed(() => `ccma-video-player-${recordId?.value}`);

const menuPlayer = (): boolean => {
  return false
}

// 打开弹窗
const open = () => isShow.value = true;
// 关闭弹窗
const close = () => isShow.value = false;
const stopWatch = watch(
  () => contactVideoPlayer.value,
  (value, oldValue) => {
    if (value && !oldValue) {
      contactVideoPlayer.value.onerror = function(data: string|Event) {
        AiccElMessage.error(i18n('ccm.ivrvoice.play.error'));
      }
    }
  }
)

onBeforeUnmount(() => {
  stopWatch();
})
// 对外暴露弹窗开启/关闭方法
defineExpose({open, close})
</script>

<style scoped>
.video-play-h {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>