export default {
  mainTitle: 'Customer Service Cloud',
  subTitle: 'Clear UI for Ultimate Service',
  account: 'บัญชี',
  password: 'รหัสผ่าน',
  signIn: 'ลงทะเบียน',
  configCenter: 'ศูนย์การกําหนดค่า',
  configFirstAgent: 'การกําหนดค่าตัวแทน',
  ConfigureFirstBot: 'การกําหนดค่าหุ่นยนต์',
  howToUseTheCallCenter: 'คู่มือผู้ใช้',
  welcomeBack: 'ยินดีต้อนรับกลับ',
  totalNumberOfCalls: 'การโทรทั้งหมด',
  numberOfCallLosses: 'ละทิ้งการโทร',
  totalNumberOfPeopleInLine: 'โทรตามคิว',
  helpCenter: 'ศูนย์ช่วยเหลือ',
  knowledgeCenter: 'ศูนย์ความรู้',
  forum: 'ฟอรัม',
  virtualCallCenterTrendsOfTheDay: 'เทรนด์คอลเซ็นเตอร์เสมือนจริงของวัน',
  quickEntry: 'รายการด่วน',
  myToDo: 'My To Do',
  notificationCenter: 'ศูนย์รับแจ้งเหตุ',
  more: 'เพิ่มเติม',
  addStaff: 'เพิ่มพนักงาน',
  setSkillQueue: 'ตั้งค่าคิวทักษะ',
  agentManagement: 'การจัดการตัวแทน',
  calledSettings: 'การตั้งค่าการโทร',
  workbenchConfiguration: 'การกําหนดค่า Workbench',
  outboundTask: 'งานขาออก',
  workOrder: 'คําสั่งทํางาน',
  message: 'เมสเสจ',
  name: 'ชื่อ',
  taskProgress: 'ความก้าวหน้าของงาน',
  province: 'จังหวัด',
  urbanArea: 'Urban Area',
  address: 'ที่อยู่',
  postCode: 'รหัสไปรษณีย์',
  homePage: 'หน้าหลัก',
  caseHome: 'หน้าหลักเคส',
  robotManagement: 'การจัดการหุ่นยนต์',
  mailMessageManagement: 'การจัดการข้อความอีเมล',
  changePassword: 'เปลี่ยนรหัสผ่าน',
  resetSkillQueue: 'รีเซ็ตคิวสกิล',
  signOut: 'ลงชื่อออก',
  changePasswordSuccess:
    'ตั้งรหัสผ่านใหม่สําเร็จแล้ว โปรดจํารหัสผ่านใหม่ของคุณ หน้าจะกลับมาใน 3 วินาที.',
  passwordInvalid: 'รหัสผ่านปัจจุบันไม่เป็นไปตามกฎของรหัสผ่าน โปรดตั้งค่าใหม่ตามกฎ',
  passwordIsAboutToExpire: 'รหัสผ่านกําลังจะหมดอายุ เปลี่ยนรหัสผ่าน',
  passwordForceChange:
    'รหัสผ่านปัจจุบันคือรหัสผ่านเริ่มต้น เพื่อความปลอดภัย ให้เปลี่ยนรหัสผ่านทันที',
  passwordFirstChange:
    'รหัสผ่านปัจจุบันคือรหัสผ่านเริ่มต้น เพื่อความปลอดภัย ให้เปลี่ยนรหัสผ่านทันที',
  passwordExpireTitle: 'การแจ้งเตือนการหมดอายุของรหัสผ่าน',
  passwordExpireMessage:
    'รหัสผ่านที่คุณตั้งไว้กําลังจะหมดอายุ คุณไม่สามารถเข้าสู่ระบบหลังจากรหัสผ่านหมดอายุ เปลี่ยนรหัสผ่านเดี๋ยวนี้.',
  passwordFirstChangeTitle: 'การแจ้งเตือนรหัสผ่านเริ่มต้น',
  passwordFirstChangeMessage:
    'รหัสผ่านปัจจุบันเป็นรหัสผ่านเริ่มต้นซึ่งไม่ปลอดภัย คุณต้องการเปลี่ยนรหัสผ่านตอนนี้หรือไม่?',
  systemError: 'System error',
  continueLogin: 'เข้าสู่ระบบต่อไป',
  verifyCode: 'รหัสยืนยัน',
  pageInvalid: "หน้านี้ไม่ถูกต้อง' โปรดโหลดหน้านี้ใหม่",
  confirm: 'ยืนยัน',
  verifyCodeInvalid: 'รหัสยืนยันไม่ถูกต้อง',
  passwordHasExpried: 'รหัสผ่านหมดอายุ',
  passWordRuleLength: 'รหัสผ่านประกอบด้วย [1] ถึง [2] ตัวอักษร',
  passWordRuleMinLength: 'รหัสผ่านต้องมีอักขระอย่างน้อย [1]',
  passWordRuleMaxLength: 'รหัสผ่านสามารถมีอักขระได้สูงสุด [1] ตัวอักษร',
  passwordRuleForbidContinueCharacter: 'จํานวนอักขระที่ซ้ําติดต่อกันต้องไม่เกิน [1]',
  passwordRuleForbidName: 'ไม่สามารถบรรจุชื่อได้',
  passwordRuleForbidAccount: 'บัญชีไม่สามารถเรียงลําดับเป็นบวกหรือลบได้',
  digit: 'ตัวเลข',
  letter: 'จดหมาย',
  uppercaseLetters: 'อักษรตัวบน',
  lowercaseLetters: 'อักษรตัวพิมพ์เล็ก',
  specialCharacters: 'ตัวละครพิเศษ',
  necessaryContains: 'การบังคับ',
  specialCharacterCandidates: 'ผู้สมัครตัวละครพิเศษ:',
  descriptionOfInvalidandUnqualifiedPasswords: 'คําอธิบายรหัสผ่านไม่ถูกต้องและไม่ผ่านเกณฑ์:',
  error: 'ข้อผิดพลาด',
  copyrightInfomation:
    'ลิขสิทธิ์  © [1] Huawei Software Technologies Co., Ltd สงวนลิขสิทธิ์.',
  accountEmpty: 'เข้าบัญชี',
  oldPasswordEmpty: 'ใส่รหัสผ่าน',
  newPasswordEmpty: 'ป้อนรหัสผ่านใหม่',
  passwordNotSame: 'รหัสผ่านไม่สอดคล้องกัน',
  verifyCodeEmpty: 'ใส่รหัสยืนยัน',
  oldPassword: 'รหัสผ่านเก่า',
  newPassword: 'รหัสผ่านใหม่',
  confirmPassword: 'ยืนยันรหัสผ่าน',
  ok: 'โอเค',
  cancel: 'ยกเลิก',
  LogoutMessage: 'คุณแน่ใจหรือว่าต้องการออก?',
  keywordFilter: 'ป้อนคําหลักที่จะกรอง',
  noOrganization: 'องค์กรที่ไม่เกี่ยวข้อง',
  createCaseOrder: 'สร้างเคส',
  configMenuSearchPlaceholder: 'คีย์เวิร์ดของเมนูค้นหา',
  changePasswordSuccessAndLogout: 'เปลี่ยนรหัสผ่านเรียบร้อยแล้ว! ระบบกําลังจะออกโดยอัตโนมัติ!',
  accountLocked: 'ผู้ใช้ถูกล็อคเนื่องจากจํานวนการเปลี่ยนรหัสผ่านล้มเหลวเกินเกณฑ์ที่กําหนด หน้าเข้าสู่ระบบจะแสดงใน 3 วินาที',
  'queueNumbers': 'จํานวนคิวปัจจุบัน:',
  'callsToday': 'จํานวนของ วันนี้:',
  'averageCallTime': 'เวลาโทรเฉลี่ย:',
  'entryIsEmpty': 'ทางเข้าเมนูทางลัดปัจจุบันว่าง โปรดตั้งค่า',
  'goToSettings': 'ไปที่การตั้งค่า',
  'settingShortcutEntry': 'ตั้งค่าทางลัด',
  'frontDeskWork': 'งานแผนกต้อนรับส่วนหน้า',
  'selectedShortcutMenu': 'เลือกทางลัดแล้ว',
  'pendingMessages': 'ข้อความที่รอดําเนินการ ({n})',
  'messageManagement': 'การจัดการข้อความ',
  'messageTime': 'เวลาส่งข้อความ',
  'useCallFunction': 'วิธีการใช้คุณลักษณะของสวิตช์',
  'performingOutboundCallTask': 'วิธีการปฏิบัติงานของ KDE',
  'notificationDetails': 'รายละเอียดการแจ้งเตือน',
  'sender': 'ผู้ส่ง',
  'receiveGroup': 'รับกลุ่ม',
  'bulletinType': 'ประเภทประกาศ',
  'internalMessage': 'จดหมายจากสถานี',
  'announcements': 'ประกาศ',
  'systemBulletin': 'ประกาศระบบ',
  'internalBulletin': 'ประกาศภายใน',
  'sendNotification': 'ส่งการแจ้งเตือน',
  'notificationReply': 'การแจ้งเตือนตอบกลับ',
  'notificationDeleteConfirm': 'คุณแน่ใจหรือไม่ว่าต้องการลบการแจ้งเตือนนี้ ?',
  'alarmnote':'ตรวจสอบสมุดบันทึก',
  'alarmmessage':'ข้อมูลคําเตือน',
  'alarmmessagedetail':'คุณมี {0} การแจ้งเตือนการตรวจสอบคุณภาพใหม่ที่รอตรวจสอบ โปรดคลิกเพื่อดูรายละเอียด',
  'schedule':'WFM',
  'privacystatement':'นโยบายความเป็นส่วนตัวของ AICC',
  'id':'ID',
  'decs':'คําอธิบาย',
  'cause':'เหตุผล',
  'action':'แก้ไขการกระทํา',
  personalCenter:"ศูนย์ส่วนบุคคล",
  'noagent':"บัญชีนี้ไม่ได้เชื่อมโยงกับเจ้าหน้าที่ คุณสามารถติดต่อผู้ดูแลระบบเพื่อกําหนดค่าได้",
  'nickName':"ชื่อเล่นของเซสชัน",
  'socailChatNum':"จํานวนลูกค้าที่ให้บริการพร้อมกันในเซสชัน",
  'avatar':"เซสชันที่ขึ้นต้นด้วย",
  'confirmClose':"ยืนยันการปิด",
  'importListView': "การดูงานนําเข้า",
  'listView': "การดูงาน",
  'minorityLanguageTips': 'ขออภัย ระบบ WFM รองรับเฉพาะภาษาจีนและภาษาอังกฤษเท่านั้น โปรดเปลี่ยนเป็นภาษาอื่น',
  'minorityLanguageTipsAgent': 'ขออภัย ระบบ WFM ไม่รองรับภาษาโปรตุเกสและภาษาไทย โปรดเปลี่ยนเป็นภาษาอื่น',
  "sureCloseCurrentPage": "คุณแน่ใจหรือไม่ว่าต้องการปิดหน้านี้",
}
