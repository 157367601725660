<template>
    <div>
        <sweet-dialog v-model="dialogVisible" :title="$t('isales.taskinfo.title.chooseSurvey')" class="aicc-dialog-limit-height" width="616"
                      :close-on-click-modal="false">
            <span class="m-dialog-header">
                <aicc-advanced-search ref='advancedSearch'
                                      :templateMetaData="templateMetaData"
                                      @handleRefresh="handleRefresh"
                                      @handleCommand="handleCommand"
                                      @handleReset="searchPageFirst"
                                      @handleSearch="searchPageFirst"
                                      @scriptNameClear="searchPageFirst">
                </aicc-advanced-search>
            </span>
            <aicc-table
                    :tableData="surveyList"
                    :tableColumns="tableColumns"
                    :tableTools="{ showPagination: true, columnConfig: false }"
                    :paginationConfig="paginationConfig"
                    @handlePagination="handlePagination"
                    ref='refAiccTable' style="margin-top: 8px"
                    :max-height="430">
            </aicc-table>
            <template #footer>
      <span class="dialog-footer">
        <sweet-button @click="dialogVisible = false">{{ $t('isales.common.title.cancel') }}</sweet-button>
        <sweet-button type="primary" @click="confirm">{{ $t('isales.common.title.confirm') }}</sweet-button>
      </span>
            </template>
        </sweet-dialog>
    </div>
</template>

<script>
  import {queryCmsEnvironment, querySurveyListAndCount} from "@/views/isales/api/systemAutoCallTask.js";
  import {SURVEY_TYPE_ENUM} from "@/views/isales/views/taskManagement/enum";

  export default {
    name: "isalesSurveySelect",
    props: {
      show: {
        type: Boolean,
        default: false
      },
      taskType: ''
    },
    mounted() {
      const searchObj = {
        searchType: 'primary', // primary: 有搜索图标的输入框；select: 下拉框; input: 搜索框；date: 日期选择器
        modelValue: 'scriptName', // v-model绑定的key值
        modelDefault: '', // v-model绑定的默认值
        placeholderValue: this.$t('isales.management.placeholder.surveyName'), // placeholder绑定的值 国际化之后的值
        rules: [
          {
            maxLength: 100,
            validator: this.$Validate.maxLengthValidator,
            trigger: ['change', 'blur']
          },
          {validator: this.$Validate.checkSpecialChar, trigger: ['change', 'blur']},
        ],
        clearEvent: 'scriptNameClear',
        colConfig: {
          lg: 8, xl: 8
        }
      }
      if (this.taskType !== '3' && this.taskType !== '7') {
        let surveyTypeEnum = SURVEY_TYPE_ENUM
        const selectObj = {
          searchType: 'select', // 搜索框类型： input 输入框；select 选择器；date 日期选择器;
          modelValue: 'scenarioType', // v-model绑定的值
          modelDefault: '1', // v-model绑定的默认值
          placeholderValue: this.$t('isales.taskinfo.field.taskType'), // 任务类型
          selectOptions: surveyTypeEnum,
          clickEvent: 'handleSearch',
          clearable: false,
          colConfig: {
            lg: 8, xl: 8
          }
        }
        this.templateMetaData.basicConfig.basicSearch.push(selectObj);
      }
      this.templateMetaData.basicConfig.basicSearch.push(searchObj);
    },
    data() {
      return {
        templateMetaData: {
          basicConfig: { // 基础搜索配置
            iconButton: [{buttonType: 'refresh'}], // 图标按钮
            colConfig: {
              lg: 2, xl: 2
            },
            iconAreaColConfig: {lg: 1, xl: 1},
            basicSearch: []
          },

        },
        scriptName: '',
        scenarioType: '1',
        surveyList: [],
        tableColumns: [
          {
            type: 'radio',
            radioKey: 'scriptId', // 单选绑定的字段名
            width: '50px',
            'show-overflow-tooltip': false
          },
          {
            label: this.$t('isales.taskinfo.title.surveyName'),
            prop: 'scriptName',
          },
          {
            label: this.$t('isales.taskinfo.title.versionNo'),
            prop: 'versionNoDesc',
          }
        ],
        paginationConfig: {
          pageSizes: [10, 20, 50], // 每页条数列表
          PageSize: 10, // 每页条数
          total: 0, //总条数
          layout: "sizes,total, prev, pager, next" // 分页组件布局配置
        },
        rows: 10,
        page: 1,
      }
    },
    computed: {
      dialogVisible: {
        get() {
          this.querySurveyListAndCount();
          return this.show
        },
        set(val) {
          this.$emit('update:show', val)
        }
      },
      async isCmsEnvironment() {
        let result = await queryCmsEnvironment()
        return result.cmsEnvironment === 'cms';
      }
    },
    methods: {
      searchPageFirst(){
        this.page = 1;
        this.$refs.refAiccTable.setCurrentPage(1);
        this.querySurveyListAndCount();
      },
      async querySurveyListAndCount() {
        if (await this.isCmsEnvironment) {
          return;
        }
        let params = this.$refs.advancedSearch.getFormatParams()
        let queryCondition = {};
        if (params['scriptName']) {
          queryCondition["scriptName"] = params['scriptName'];
        }
        if (this.taskType === "3") {
          queryCondition["scenarioType"] = "2";
        } else if (this.taskType === "7") {
          queryCondition["scenarioType"] = "3";
        } else {
          queryCondition["scenarioType"] = params['scenarioType'];
        }
        queryCondition['rows'] = this.rows;
        queryCondition['page'] = this.page;
        querySurveyListAndCount(queryCondition).then(res => {
          if (res) {
            this.surveyList = res.data;
            if (this.surveyList && this.surveyList.length > 0) {
              this.surveyList.forEach(item => {
                item['versionNoDesc'] = 'V' + item.versionNo;
              });
            }
            this.paginationConfig.total = res.total;
          }
        })
      },
      handlePagination(params) {
        this.rows = params.pageSize;
        this.page = params.pageNum;
        this.querySurveyListAndCount();
      },
      confirm() {
        this.dialogVisible = false
        this.$emit('isalesSurveySelectConfirm', this.$refs.refAiccTable.getRadioCurrentRow())
      },
      handleRefresh() { // 刷新时重置回第一页，同时会触发handlePagination
        this.$refs.refAiccTable.handleInitChange()
      },
      handleCommand(data) { // 触发事件处理，调用对应方法，方法和eventName同名
        const {eventName, params} = data
        this[eventName](params)
      },
    },
  }
</script>

<style scoped>
    .m-dialog-header {
        height: 40px;
        padding: 16px 24px;
        color: rgba(0, 0, 0, 0.65);
        border-radius: 4px 4px 0 0;
        border-bottom: 0px solid #e8e8e8;
    }

    .u-head {
        margin: 0;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
        font-size: 12px;
        line-height: 22px;
        word-wrap: break-word;
    }

</style>