export default {
  mainTitle: 'Customer Service Cloud',
  subTitle: 'Clear UI for Ultimate Service',
  account: 'Cuenta',
  password: 'Contraseña',
  signIn: 'Iniciar Sesión',
  configCenter: 'Centro de configuración',
  configFirstAgent: 'Configuración del agente',
  ConfigureFirstBot: 'Configuración del robot',
  howToUseTheCallCenter: 'Guía del usuario',
  welcomeBack: 'Bienvenido de vuelta',
  totalNumberOfCalls: 'Llamadas totales',
  numberOfCallLosses: 'Llamadas abandonadas',
  totalNumberOfPeopleInLine: 'Llamadas en cola',
  helpCenter: 'Centro de ayuda',
  knowledgeCenter: 'Centro de Conocimiento',
  forum: 'Foro',
  virtualCallCenterTrendsOfTheDay: 'Tendencias virtuales del día del centro de llamadas',
  quickEntry: 'Entrada rápida',
  myToDo: 'Mi que hacer',
  notificationCenter: 'Centro de Notificación',
  more: 'Más',
  addStaff: 'Añadir personal',
  setSkillQueue: 'Establecer cola de habilidades',
  agentManagement: 'Gestión del agente',
  calledSettings: 'Configuración llamada',
  workbenchConfiguration: 'Configuración del banco de trabajo',
  outboundTask: 'Tarea de salida',
  workOrder: 'Orden de trabajo',
  message: 'Mensaje',
  name: 'Nombre',
  taskProgress: 'Progreso de la tarea',
  province: 'Provincia',
  urbanArea: 'Área urbana',
  address: 'Dirección',
  postCode: 'Código Postal',
  homePage: 'Página de inicio',
  caseHome: 'Gestión de Casos',
  robotManagement: 'Gestión de robots',
  mailMessageManagement: 'Gestión de mensajes de correo',
  changePassword: 'Cambiar contraseña',
  resetSkillQueue: 'Restablecer cola de habilidades',
  signOut: 'Salida de sesión',
  changePasswordSuccess:
    'La nueva contraseña se ha establecido correctamente. Por favor, recuerde su nueva contraseña. La página volverá en 3 segundos.',
  passwordInvalid:
    'La contraseña actual no cumple las reglas de contraseña. Por favor, restablezca de acuerdo con las reglas.',
  passwordIsAboutToExpire: 'La contraseña está a punto de caducar. Cambiar la contraseña.',
  passwordForceChange:
    'La contraseña actual es la contraseña inicial. Por motivos de seguridad, cambie la contraseña inmediatamente',
  passwordFirstChange:
    'La contraseña actual es la contraseña inicial. Para garantizar la seguridad, cambie la contraseña inmediatamente.',
  passwordExpireTitle: 'Notificación de caducidad de contraseña',
  passwordExpireMessage:
    'La contraseña que configuró está a punto de caducar. No puede iniciar sesión en el sistema después de que caduque la contraseña. Cambia la contraseña ahora.',
  passwordFirstChangeTitle: 'Recordatorio de contraseña inicial',
  passwordFirstChangeMessage:
    'La contraseña actual es la contraseña inicial, que es insegura. ¿Quieres cambiar la contraseña ahora?',
  systemError: 'Error del sistema',
  continueLogin: 'Continuar inicio de sesión',
  verifyCode: 'Código de verificación',
  pageInvalid: 'La página no es válida. Por favor, vuelva a cargar la página.',
  confirm: 'Confirmar',
  verifyCodeInvalid: 'El código de verificación no es válido.',
  passwordHasExpried: 'La contraseña ha caducado',
  passWordRuleLength: 'La contraseña contiene de [1] a [2] caracteres',
  passWordRuleMinLength: 'La contraseña debe contener al menos [1] caracteres',
  passWordRuleMaxLength: 'La contraseña puede contener un máximo de [1] caracteres',
  passwordRuleForbidContinueCharacter:
    'El número de caracteres repetidos consecutivos no puede exceder de [1]',
  passwordRuleForbidName: 'El nombre no puede contenerse',
  passwordRuleForbidAccount: 'La cuenta no puede estar en orden positivo o negativo',
  digit: 'Número',
  letter: 'Carta',
  uppercaseLetters: 'Cartas mayúsculas',
  lowercaseLetters: 'Cartas minúsculas',
  specialCharacters: 'Personaje especial',
  necessaryContains: 'Obligatorio',
  specialCharacterCandidates: 'candidato de carácter especial:',
  descriptionOfInvalidandUnqualifiedPasswords:
    'Descripción de la contraseña no válida y no cualificada:',
  error: 'Error',
  copyrightInfomation:
    'Derechos de autor © [1] Huawei Software Technologies Co., Ltd. Reservados todos los derechos.',
  accountEmpty: 'Ingrese una cuenta.',
  oldPasswordEmpty: 'Ingrese una contraseña.',
  newPasswordEmpty: 'Ingrese una nueva contraseña.',
  passwordNotSame: 'Las contraseñas son inconsistentes',
  verifyCodeEmpty: 'Ingrese un código de verificación',
  oldPassword: 'Contraseña antigua',
  newPassword: 'Nueva contraseña',
  confirmPassword: 'Confirmar contraseña',
  ok: 'Está bien',
  cancel: 'Cancelar',
  LogoutMessage: '¿Estás seguro de que quieres salir',
  keywordFilter: 'Por favor ingrese palabras clave para filtrar',
  noOrganization: 'Sin afiliación',
  createCaseOrder: 'Crear caso',
  configMenuSearchPlaceholder: 'Buscador',
  changePasswordSuccessAndLogout: 'Contraseña cambiada con éxito! ¡El sistema está a punto de salir automáticamente!',
  accountLocked: 'El usuario ha sido bloqueado porque el número de errores de cambio de contraseña excede el umbral. La página de inicio de sesión se mostrará en 3 segundos.',
  'queueNumbers': 'Número actual de colas:',
  'callsToday': 'Llamadas de hoy:',
  'averageCallTime': 'Tiempo promedio de conversación:',
  'entryIsEmpty': 'La entrada del menú contextual actual está vacía. Establezca.',
  'goToSettings': 'Ir a la configuración',
  'settingShortcutEntry': 'Configuración de acceso directo',
  'frontDeskWork': 'Trabajo en primer plano',
  'selectedShortcutMenu': 'Menú contextual seleccionado',
  'pendingMessages': 'Mensaje pendiente ({n})',
  'messageManagement': 'Gestión de mensajes',
  'messageTime': 'Tiempo de mensaje',
  'useCallFunction': 'Cómo usar la función de llamada',
  'performingOutboundCallTask': 'Cómo ejecutar una tarea de llamada saliente',
  'notificationDetails': 'Detalles de la notificación',
  'sender': 'Remitente',
  'receiveGroup': 'Grupo de recepción',
  'bulletinType': 'Tipo de boletín',
  'internalMessage': 'Mensajes internos',
  'announcements': 'Anuncios',
  'systemBulletin': 'Boletín del sistema',
  'internalBulletin': 'Anuncios internos',
  'sendNotification': 'Enviar una notificación',
  'notificationReply': 'Respuesta de notificación',
  'notificationDeleteConfirm': 'Está seguro de que desea eliminar esta notificación?',
  'alarmnote':'Nota de inspección de calidad',
  'alarmmessage':'Nota de inspección de calidad',
  'alarmmessagedetail':'Tiene {0} nuevas alarmas de inspección para ver. Haga clic para ver los detalles',
  'schedule':'WFM',
  'privacystatement': 'Declaración de privacidad de AICC',
  'id':'ID',
  'decs':'DESCRIPCIÓN',
  'cause':'Causa',
  'action':'Acción de reparación',
  personalCenter:"Centro personal",
  'noagent':"La cuenta no está vinculada a ningún agente. Póngase en contacto con el administrador para configurar la cuenta",
  'nickName':"Apodo de sesión",
  'socailChatNum':"Número de clientes simultáneos servidos por una sesión",
  'avatar':"Imagen de cabeza de conversación",
  'confirmClose':"Confirmar Cerrar",
  'importListView': "Visualización de tareas de importación",
  'listView': "Visualización de tareas",
  'minorityLanguageTips': 'Lo sentimos, el sistema WFM solo es compatible con chino e inglés. Por favor, cambie a otro idioma.',
  'minorityLanguageTipsAgent': 'Lo sentimos, el sistema WFM no es compatible con portugués y tailandés. Por favor, cambie a otro idioma.',
  "sureCloseCurrentPage": "¿Estás seguro de que quieres cerrar la página?",
}
