export default {
  "oifde.license.valateDate" : "تاريخ انتهاء الصلاحية",
  "oifde.rule.beginNum" : "رقم البدء",
  "oifde.variable.scopes.local" : "المحلية",
  "oifde.logparam.actions.deleteSuccess" : "تم الحذف بنجاح.",
  "oifde.knowledgegraph.kgSynonyms.number" : "لا ..",
  "oifde.operationlog.knowledge_graph_schema_relation" : "علاقة مخطط المعرفة",
  "oifde.ivrJourney.alarmValidity" : "فترة صلاحية الإنذار المسبق",
  "oifde.mxgraph.diamond" : "الماس",
  "oifde.flow.publish.publishMode" : "وضع العمل",
  "oifde.diagram.varType" : "نوع متغير",
  "oifde.rule.actions.editbaseInfo" : "تحرير المعلومات الأساسية",
  "oifde.toc.statusCode.ACCOUNT_LOCKED" : "تم قفل الحساب مؤقتًا. حاول مرة أخرى لاحقًا أو اتصل بالمسؤول لإلغاء قفل الحساب.",
  "oifde.template.typeDetail.tts" : "TTS",
  "oifde.mxgraph.openArrow" : "فتح السهم",
  "oifde.journeylogmgr.accessCodeSetting" : "إعدادات رمز الوصول",
  "oifde.common.validation.schemaRelationNameCharReg" : "يُسمح فقط بالأحرف الصينية والحروف الإنجليزية والأرقام والمسافات وعلامات الاقتباس المفردة (\').",
  "oifde.mxgraph.fontSize" : "حجم الخط",
  "oifde.procedure.driverStart" : "لا يمكن أن تبدأ القيمة بـ (.) نقطي أو واصلة (-).",
  "oifde.recordHistory.callNumber" : "رقم المشترك",
  "oifde.sysMgr.dataClean.paramValue" : "القيمة",
  "oifde.operationlog.online" : "عبر الإنترنت",
  "oifde.ivrJourney.fluctuationService" : "خدمة التذبذب",
  "oifde.operationlog.sensitive_words" : "كلمة حساسة",
  "oifde.knowledgegraph.actions.selectTip" : "-اختر-",
  "oifde.template.selectLanguage" : "اختر لغة. إذا أردت حذف لغة، انقر أيقونة الإغلاق.",
  "oifde.ivrJourney.outflowDistribution" : "توزيع التدفق الخارجي",
  "oifde.diagram.NMS_BranchService" : "فرع الخدمة",
  "oifde.operationlog.flow" : "تدفق",
  "oifde.template.actions.deleteChannelType" : "ستؤدي هذه العملية إلى حذف القناة نهائيًا. هل أنت متأكد من رغبتك في المتابعة؟",
  "oifde.sysMgr.dataClean.flagDescription" : "ما إذا كان سيتم تمكين التسجيل في تدفق مكالمات IVR الذكي.",
  "oifde.knowledgegraph.model.beforeEditPrompt" : "لا يمكن تعديل المخطط الحالي لأنه مرتبط بقاموس الرسم البياني التالي:",
  "oifde.mxgraph.view" : "عرض",
  "oifde.knowledgegraph.kgSynonyms.type.relation" : "العلاقة",
  "oifde.common.time.startDate" : "تاريخ البدء",
  "oifde.route.cell" : "خلية معقدة",
  "oifde.mxgraph.linewidth" : "عرض الخط",
  "oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_TONE.DESC" : "سواء لاستخدام نغمة في بينيين.",
  "oifde.mxgraph.NMS_FlowSaveFail" : "فشل حفظ التدفق.",
  "oifde.procedure.addProcedureDB" : "إضافة قاعدة بيانات الإجراءات المخزنة",
  "oifde.ivrJourney.createTime" : "وقت بيانات التحذير المسبق",
  "oifde.diagram.error.NMS_nullBranchNotNuique" : "يمكن أن تحتوي العقدة على فرع افتراضي واحد فقط.",
  "oifde.tenantinfo.actions.updateSuccess" : "نجح التعديل.",
  "oifde.sysMgr.inputEncryptSecret" : "أدخل مفتاحًا.",
  "oifde.operationlog.knowledge_graph_schema" : "مخطط المعرفة",
  "oifde.sysMgr.dataClean.editTcaFlag" : "تحرير قائمة النطاقات المرتبطة بتحليلات النص",
  "oifde.toc.statusCode.AUTHORIZE_FAILED" : "فشل التحقق.",
  "oifde.ivrJourney.trendAnalysis" : "تحليل الاتجاهات",
  "oifde.asrMgr.qiAsrServerName" : "فحص خادم ASR",
  "oifde.procedure.actions.success" : "النجاح",
  "oifde.sysConfig.DUMMY_VECTOR_CLASSIFIER.NAME" : "وحدة تصنيف النوايا",
  "oifde.route.knowledgegraph.extendQuestion" : "سؤال موسع",
  "oifde.mxgraph.NMS_NodeNameError" : "لا يمكن أن يكون اسم العقدة فارغًا أو يتجاوز 1000 حرف.",
  "oifde.sysMgr.requiredRpaUserName" : "حساب مستخدم RPA مطلوب.",
  "oifde.operationlog.white_list" : "قاعدة المعرفة الرمادية",
  "oifde.knowledgegraph.actions.addSchema" : "إضافة مخطط",
  "oifde.knowledgegraph.dicmgr.active" : "نشط",
  "oifde.flow.add.blankFlow" : "تدفق فارغ",
  "oifde.variable.validate.floatValueCheck" : "القيمة الافتراضية لبيانات النقطة العائمة بتنسيق غير صحيح. يجب أن تكون القيمة بتنسيق 100 أو 99.99",
  "oifde.mxgraph.NMS_ServiceDesciption" : "وصف الخدمة",
  "oifde.ivrJourney.pureIVRServiceVolume" : "حجم خدمة IVR النقي",
  "oifde.flow.grayFlow" : "رمادي",
  "oifde.knowledgegraph.entitymgr.deletePrompt" : "ستؤدي هذه العملية إلى حذف الكيان نهائيًا. هل أنت متأكد من رغبتك في المتابعة؟",
  "oifde.procedure.dbUrlWarning" : "*لاحظ أن عنوان IP أو رقم المنفذ أو اسم المجال في عنوان URL لقاعدة البيانات غير موجود في قائمة الثقة. اتصل بمسؤول النظام لإضافة عنوان IP أو رقم المنفذ أو اسم المجال. ويجب أن يحتوي مسار URL على اسم قاعدة البيانات.",
  "oifde.route.unusedResource.interface" : "عرض الواجهات غير المستخدمة",
  "oifde.trackmgr.validate.accessCode" : "حدد رمز الوصول.",
  "oifde.diagram.NMS_DiagramCode" : "رمز التدفق",
  "oifde.sysConfig.UIR_VECTOR_ENERGY_CHECKER.THR.NAME" : "عتبة الطاقة",
  "oifde.knowledgegraph.attributemgr.attribute" : "قيمة السمة",
  "oifde.mxgraph.perimeter" : "محيط",
  "oifde.operationlog.generate_test_case" : "توليد حالة التلقائي",
  "oifde.mxgraph.gap" : "الفجوة",
  "oifde.qiAsrServerMgr.fileServerAddress" : "عنوان خادم الملفات",
  "oifde.flow.enums.scenarioType.subFlow" : "التدفق الفرعي",
  "oifde.testMgr.requestFailed" : "فشل استدعاء الواجهة لإنشاء حالات الاختبار تلقائيًا.",
  "oifde.testCase.executeStatus" : "الحالة",
  "oifde.flow.publish.monitor" : "مراقب",
  "oifde.mxgraph.bold" : "جريئة",
  "oifde.template.intentionInfo.intentcode" : "الكود",
  "oifde.flow.usage.resourceType.globalvars" : "المتغير العالمي",
  "oifde.variable.innerTitle" : "المدمج في متغير",
  "oifde.toc.statusCode.ACCOUNT_CHANGED" : "تم تغيير الحساب. تسجيل الدخول مرة أخرى.",
  "oifde.mxgraph.formatted" : "تم تنسيقه",
  "oifde.maintenance.start_success" : "تم تمكين وظيفة السجل بنجاح.",
  "oifde.dashBoard.chartView.countTile" : "إجمالي الردود",
  "oifde.knowledgegraph.actions.editSchema" : "تحرير المخطط",
  "oifde.mxgraph.wordWrap" : "كلمة التفاف",
  "oifde.toc.statusCode.NEED_LOGIN" : "لم تقم بتسجيل الدخول أو إجراء عمليات لفترة طويلة. تسجيل الدخول مرة أخرى.",
  "oifde.flow.publish.normal" : "مشترك",
  "oifde.template.defaultValueValidate.ttsVolumeValueDesc" : "نطاق القيمة : [0,100]",
  "oifde.serviceInterface.operation" : "العملية",
  "oifde.procedure.dataTypes.FLOAT" : "رقم النقطة العائمة",
  "oifde.whitelist.whiteTypes.PROCEDURE" : "الإجراء المخزن",
  "oifde.serviceInterface.updatetime" : "تم تحديثه",
  "oifde.testMgr.abortSuccess" : "نجح المقاطعة.",
  "oifde.serviceInterface.safeWarning" : "يتم تحديد بروتوكول شبكة غير آمن للواجهة.",
  "oifde.operationlog.paste" : "لصق",
  "oifde.trackmgr.validate.callingNumber" : "أدخل رقماً متصلاً.",
  "oifde.mxgraph.redo" : "إعادة",
  "oifde.ivrJourney.outflowDetailDistributionAmount" : "التدفق كمية التوزيع التفصيلية",
  "oifde.operationlog.refresh" : "تحديث",
  "oifde.flow.importFlow.bigFilePrompt" : "لا يمكن أن يتجاوز حجم الملف 20 ميغابايت.",
  "oifde.mxgraph.paste" : "لصق",
  "oifde.mxgraph.reset" : "إعادة الضبط",
  "oifde.serviceInterface.miss_ifs_out_parameter_name" : "الحقل الإلزامي التالي فارغ: معلمة الإخراج > الاسم.",
  "oifde.ivrJourney.averageTime" : "متوسط مدة الخدمة",
  "oifde.logparam.actions.success" : "النجاح",
  "oifde.flow.code" : "الكود",
  "oifde.fileTask.INVALID_ITEM_ENCODING" : "وضع الترميز غير صالح.",
  "oifde.hisdialog.startTime_must_earlier_endTime" : "يجب أن يكون وقت البدء قبل وقت الانتهاء. إعادة تعيين معايير البحث.",
  "oifde.diagram.complexCell.name" : "اسم عنصر الرسم البياني",
  "oifde.operationlog.test_case" : "حالة الاختبار",
  "oifde.common.validation.validateFalse" : "لا يمكن أن تحتوي القيمة على characters: ~ `! # {\'$\'} % ^ & * ( ) + الخاص التالي = [ ] {\'{\'} {\'}\'} {\'|\'} \'  : < > /?",
  "oifde.common.language.english" : "الإنجليزية",
  "oifde.mxgraph.layers" : "الطبقات",
  "oifde.common.dialog.confirm.content" : "ستؤدي هذه العملية إلى حذف البيانات نهائيًا. هل أنت متأكد من رغبتك في المتابعة؟",
  "oifde.diagram.error.NMS_actionParamNameError" : "يمكن أن يحتوي اسم المعلمة على {0} أحرف كحد أقصى.",
  "oifde.ivrJourney.outflowDetailDistributionPercentage" : "نسبة التوزيع التفصيلية للتدفق",
  "oifde.recordHistory.recordHistory" : "سجل المكالمات",
  "oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.NE.DESC" : "الحد الأقصى لقيمة N في خوارزمية N-Gram.",
  "oifde.mxgraph.gradient" : "التدرج",
  "oifde.rule.endNum" : "رقم النهاية",
  "oifde.procedure.dataType" : "نوع البيانات",
  "oifde.flow.actions.error" : "فشلت العملية.",
  "oifde.mxgraph.formatGif" : "GIF",
  "oifde.operationlog.domain" : "المجال",
  "oifde.mxgraph.pageScale" : "مقياس الصفحة",
  "oifde.knowledgegraph.editSchema.attributeType" : "نوع السمة",
  "oifde.template.message.validateResult" : "معلومات",
  "oifde.variable.actions.addVariableBaseInfo" : "إضافة متغير",
  "oifde.rule.message.fail_save" : "فشل حفظ قائمة الحظر/قائمة الثقة.",
  "oifde.testCase.caseID" : "معرف الحالة",
  "oifde.route.tuc.ops.perf" : "إحصائيات الأداء",
  "oifde.ivrJourney.detailDistribution" : "التوزيع التفصيلي",
  "oifde.knowledgegraph.kgSynonyms.deleteSynonymsPrompt" : "ستؤدي هذه العملية إلى حذف المرادف نهائيًا. هل أنت متأكد من رغبتك في المتابعة؟",
  "oifde.accesscode.actions.prompt" : "تأكيد",
  "oifde.diagram.error.NMS_NodeDescError" : "يمكن أن يحتوي وصف العقدة على 1000 حرف كحد أقصى.",
  "oifde.template.responseStyleName" : "الاسم",
  "oifde.sysMgr.dataClean.description" : "الوصف",
  "oifde.navbar.password.passwordWillExpire" : "كلمة المرور على وشك الانتهاء. قم بتغييرها.",
  "oifde.sysConfig.CRF_PARAM_EXTRACTOR.NUM_TREES.NAME" : "كمية الشجرة",
  "oifde.mxgraph.tooltips" : "تلميحات الأدوات",
  "oifde.mxgraph.scrollbars" : "أشرطة التمرير",
  "oifde.procedure.driverRule" : "يمكن أن تحتوي القيمة على أحرف وأرقام ونقاط (.), وواصلات (-) فقط.",
  "oifde.qiAsrServerMgr.asrServerNameError" : "اسم خادم ASR هو نفس اسم خادم ASR الافتراضي.",
  "oifde.robotReply.replyTemplate" : "قالب الرد",
  "oifde.flow.defaultFlow" : "التدفق الافتراضي",
  "oifde.common.validation.nameRange" : "الأحرف الخاصة التالية ليست allowed: < >! {\'@\'} # {\'$\'} % ^ & *? ;  ~ / \\",
  "oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_PINYIN.DESC" : "تحويل الصينية إلى pinyin.",
  "oifde.whitelist.whiteIp" : "عنوان IP لقائمة الثقة أو اسم المجال",
  "oifde.flow.importFlow.success" : "العملية ناجحة.",
  "oifde.sysMgr.dataClean.tcaContent" : "قائمة المجالات المرتبطة بتحليل النص.",
  "oifde.procedure.actions.deletePrompt" : "ستؤدي هذه العملية إلى حذف الإجراء المخزن نهائيًا. هل أنت متأكد من رغبتك في المتابعة؟",
  "oifde.ivrJourney.sendResult" : "إرسال النتيجة",
  "oifde.sysConfig.INTENT_DECISION_MAKER.TOP.DESC" : "وتعتبر النية معروفة إذا تجاوزت الحد الأعلى لصانع القرار النية.",
  "oifde.expression.expr_string" : "أدخل قيمة لنوع السلسلة. قم بتضمين القيمة في علامات اقتباس مفردة أو مزدوجة (\' أو ). مشغل سلسلة الأحرف هو علامة الجمع (+).",
  "oifde.mxgraph.targetSpacing" : "تباعد الهدف",
  "oifde.procedure.required" : "هذا الحقل إلزامي.",
  "oifde.sysMgr.dataClean.confidenceDesc" : "عتبة الثقة للوكيل الصامت للتعرف على طلب المساعدة الفردي من chatbot.",
  "oifde.serviceInterface.dataType" : "نوع البيانات",
  "oifde.mxgraph.topRight" : "أعلى اليمين",
  "oifde.qiAsrServerMgr.preStep" : "السابق",
  "oifde.sysMgr.dataClean.recordThirdApi" : "ما إذا كان سيتم تمكين تسجيل استدعاء واجهة الطرف الثالث.",
  "oifde.knowledgegraph.actions.delete" : "حذف",
  "oifde.operationlog.interface_white_list" : "قائمة الثقة",
  "oifde.license.LIN8OIAPVA02" : "عدد المكالمات المتراكمة من وكلاء الظاهري",
  "oifde.tenantinfo.validate.serviceValue" : "أدخل قيمة.",
  "oifde.license.LIN8OIAPVA01" : "عدد الموظفين الظاهريين المتزامنين",
  "oifde.procedure.dataTypes.INT" : "عدد صحيح",
  "oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.BORDER_EXT_ENABLE.NAME" : "تمديد حدود الجملة",
  "oifde.ivrJourney.chooseVersion" : "تحديد إصدار التدفق",
  "oifde.mxgraph.divider" : "المقسم",
  "oifde.serviceInterface.addServiceInterface" : "إضافة تكوين واجهة الأعمال",
  "oifde.operationlog.loginIp" : "عنوان IP لتسجيل الدخول",
  "oifde.operationlog.copy" : "نسخ",
  "oifde.mxgraph.saveAndPublish" : "حفظ ونشر",
  "oifde.mxgraph.rounded" : "دائري",
  "oifde.route.sysAudio" : "إدارة الصوت",
  "oifde.knowledgegraph.dicmgr.dicFunction" : "استخدام الرسم البياني",
  "oifde.qarecord.interface.desc" : "تسجيل معرّف الاستبيان وعنوان السؤال ونية رد المستخدم ورد المستخدم الأصلي.",
  "oifde.testMgr.succeed_delete_case" : "تم حذف حالة الاختبار بنجاح.",
  "oifde.chatbot.errorCode.100051000109" : "ويتجاوز عدد حالات الاختبار 200 حالة.",
  "oifde.sysMgr.success_set_rpa" : "تم تعيين معلومات وصول RPA بنجاح!",
  "oifde.mxgraph.undo" : "تراجع",
  "oifde.analysis.childrenView" : "عرض العقد الفرعية",
  "oifde.diagram.error.NMS_scenarioTypeError" : "لا يمكن أن يكون نوع السيناريو هذا فارغًا.",
  "oifde.rule.actions.error" : "حدث خطأ أثناء استدعاء الواجهة.",
  "oifde.trace.flowId" : "معرف التدفق",
  "oifde.chatBotTest.addTestCaseGroup" : "إضافة مجموعة حالة الاختبار",
  "oifde.trackmgr.validate.callingNumberDub" : "يجب أن يكون الرقم الطالب فريدًا.",
  "oifde.mxgraph.vertical" : "عمودي",
  "oifde.route.unusedResource.globalvars" : "عرض المتغيرات العالمية غير المستخدمة",
  "oifde.mxgraph.pasteHere" : "الصق هنا",
  "oifde.mxgraph.image" : "صورة",
  "oifde.mxgraph.clearWaypoints" : "مسح نقاط الطريق",
  "oifde.trackmgr.validate.needNumber" : "أدخل رقمًا.",
  "oifde.ivrJourney.nodeOverview" : "نظرة عامة على العقدة",
  "oifde.mxgraph.shadow" : "الظل",
  "oifde.serverMgr.asrServer" : "خادم ASR",
  "oifde.mxgraph.general" : "عام",
  "oifde.mxgraph.fileNotFound" : "لم يتم العثور على الملف",
  "oifde.ivrJourney.no" : "الترتيب",
  "oifde.knowledgegraph.editSchema.dataType.string" : "الحرف",
  "oifde.fileTask.INVALID_ENTRY_PATH" : "مسار الملف في الحزمة المضغوطة غير صالح.",
  "oifde.toc.statusCode.ROLE_NOT_FOUND" : "الدور غير موجود.",
  "oifde.knowledgegraph.actions.addAttribute" : "إضافة سمة",
  "oifde.rule.language" : "اللغة",
  "oifde.knowledgegraph.errorCode.100051001013" : "هذا المخطط قيد الاستخدام حاليًا.",
  "oifde.serviceInterface.required" : "هذا الحقل إلزامي.",
  "oifde.knowledgegraph.errorCode.100051001012" : "تم بالفعل تكوين مرادف للاسم.",
  "oifde.trackmgr.actions.updateSuccess" : "نجح التعديل.",
  "oifde.dashBoard.service.unknowCount" : "ردود غير معروفة",
  "oifde.operationlog.password_rule_group" : "مجموعة قواعد كلمة المرور",
  "oifde.mxgraph.lineheight" : "ارتفاع الخط",
  "oifde.serviceInterface.miss_ifs_header_key" : "الحقل الإلزامي التالي فارغ: رأس الرسالة > المفتاح.",
  "oifde.ivrJourney.selfServiceDesc" : "عدد مكالمات IVR التي لم تتم إعادة توجيهها إلى الخدمة اليدوية",
  "oifde.toc.statusCode.INCORRECT_TENANT_SPACE_ID" : "معرف مساحة المستأجر غير صحيح.",
  "oifde.qiAsrServerMgr.fileServerInfo" : "معلومات خادم الملفات",
  "oifde.variable.validate.variableDescLength" : "يحتوي وصف المتغير على أكثر من 1000 حرف.",
  "oifde.testMgr.case_id" : "معرف الحالة",
  "oifde.chatBotTest.executeFailed" : "فشل التنفيذ.",
  "oifde.route.editCellDiagram" : "تحرير قماش مركب",
  "oifde.testMgr.succeed_test_case" : "تم تنفيذ حالة الاختبار بنجاح.",
  "oifde.flow.usage.desc" : "الوصف",
  "oifde.mxgraph.rightToLeft" : "من اليمين إلى اليسار",
  "oifde.accesscode.sendContent" : "إرسال",
  "oifde.flow.importFlow.upload" : "تحميل",
  "oifde.testCase.caseDetail" : "تفاصيل الحالة",
  "oifde.logparam.sourceName" : "اسم مصدر المعلمة",
  "oifde.ivrJourney.IVRSrvTime" : "إجمالي مدة خدمة IVR",
  "oifde.rule.actions.sure" : "نعم",
  "oifde.rule.actions.addbaseInfo" : "إنشاء معلومات أساسية",
  "oifde.flow.usage.status.fail" : "فشل",
  "oifde.ivrJourney.failCalls" : "عدد حالات الفشل",
  "oifde.operationlog.operationTime" : "آخر عملية",
  "oifde.accesscode.flowName" : "اسم التدفق",
  "oifde.toc.statusCode.ACCOUNT_DISABLED" : "تم تعليق الحساب. اتصل بالمسؤول.",
  "oifde.trace.processResult" : "نتيجة التنفيذ",
  "oifde.flow.actions.copySuccess" : "تم نسخ التدفق بنجاح.",
  "oifde.mxgraph.italic" : "الإيطالية",
  "oifde.mxgraph.NMS_NodeDescError" : "يمكن أن يحتوي وصف العقدة على 1000 حرف كحد أقصى.",
  "oifde.procedure.addProcedureInterface" : "إضافة إجراء مخزن",
  "oifde.mxgraph.NMS_ParamDescription" : "الوصف",
  "oifde.sysMgr.dataClean.slientHelpDesc" : "الوقت لانتظار الرد بعد صمت الوكيل.",
  "oifde.complexcell.cell.actions.copySuccess" : "نجح النسخ.",
  "oifde.mxgraph.NMS_analiseXmlFailed" : "فشل تحليل اللوحة القماشية للتدفق.",
  "oifde.fileTask.WRITE_FILE_FAILED" : "فشل حفظ الملف.",
  "oifde.audioVideoResource.cancelUpdate" : "إلغاء التحديث",
  "oifde.mxgraph.pageView" : "عرض الصفحة",
  "oifde.mxgraph.bottomLeft" : "أسفل اليسار",
  "oifde.mxgraph.formatSvg" : "SVG",
  "oifde.accesscode.recordState.enable" : "تمكين",
  "oifde.accesscode.accessCodeSubscribed": 'رمز الوصول إلى chatbot قيد الاستخدام.',
  "oifde.accesscode.bindScene": "عرض المراجع",
  "oifde.accesscode.chatBot.search": "البحث",
  "oifde.accesscode.chatBot.reset": "إعادة الضبط",
  "oifde.accesscode.searchScene": "حدد سيناريو.",
  "oifde.accesscode.sceneName": "السيناريو",
  "oifde.accesscode.subName": "اسم المرجع",
  "oifde.accesscode.createTime": "الوقت المرجعي",
  "oifde.accesscode.chatBot.chatChanel": "قناة الوسائط المتعددة",
  "oifde.accesscode.chatBot.agentAssistant": "وكيل مساعد",
  "oifde.accesscode.chatBot.intelligentOB": "الصادر الذكي",
  "oifde.accesscode.chatBot.intelligentTrain": "القطار الذكي",
  "oifde.knowledgegraph.kgSynonyms.name" : "الاسم",
  "oifde.template.update.templateName" : "الاسم",
  "oifde.mxgraph.delete" : "حذف",
  "oifde.ivrJourney.lessThen" : "أقل من",
  "oifde.operationlog.knowledge_graph_ask_template_element" : "قالب الأسئلة الشائعة للأسئلة الموسعة الرسم البياني",
  "oifde.knowledgegraph.actions.createSuccess" : "تمت الإضافة بنجاح.",
  "oifde.mxgraph.flip" : "فليب",
  "oifde.chatBotTest.testTimes" : "الكمية التي تم اختبارها",
  "oifde.ivrJourney.failedRate" : "معدل الفشل",
  "oifde.ivrJourney.general" : "عام",
  "oifde.knowledgegraph.actions.editSchemaEntity" : "تحرير المفهوم",
  "oifde.dashBoard.pie.talkTitle" : "دردشة",
  "oifde.knowledgegraph.entitymgr.beforeDeletePrompt" : "حذف علاقة المنشأة ومن ثم حذف المنشأة.",
  "oifde.operationlog.train" : "القطار",
  "oifde.flow.actions.prompt" : "تأكيد",
  "oifde.toc.statusCode.TENANT_SPACE_DUPLICATED" : "مساحة المستأجر موجودة بالفعل.",
  "oifde.mxgraph.dashed" : "متقطعة",
  "oifde.mxgraph.NMS_Region" : "المنطقة",
  "oifde.ivrJourney.avgIVRTimeNoSymbol" : "متوسط مدة المكالمة",
  "oifde.knowledgegraph.validation.selectRequired" : "هذا الحقل إلزامي.",
  "oifde.sysConfig.ENERGY_FILTER.MAX.DESC" : "الحد الأقصى لعدد النوايا التي تم إرجاعها في القرار النهائي.",
  "oifde.sysMgr.encryptSecret" : "مفتاح",
  "oifde.tagsView.refresh" : "تحديث",
  "oifde.qiAsrServerMgr.fileServerUser" : "اسم مستخدم خادم الملفات",
  "oifde.ivrJourney.operationStatus" : "حالة العملية",
  "oifde.toc.statusCode.ROLE_FORBIDDEN" : "العمليات على هذا الدور ممنوعة.",
  "oifde.accesscode.actions.cancel" : "لا",
  "oifde.operationlog.account" : "الحساب",
  "oifde.trace.clickToHideDetail" : "انقر لإظهار أقل.",
  "oifde.procedure.actions.miss_pro_out_parameter_name" : "الحقل الإلزامي التالي فارغ: معلمة الإخراج > اسم المعلمة.",
  'oifde.procedure.actions.miss_pro_dbId': 'الحقل الإلزامي التالي فارغ: معلومات أساسية > اتصال قاعدة البيانات.',
  "oifde.testMgr.faqGroup" : "مجموعة الأسئلة الشائعة",
  "oifde.procedure.dbTypes.MYSQL" : "MySQL",
  "oifde.qiAsrServerMgr.asrServerAddress" : "عنوان الخادم",
  "oifde.mxgraph.block" : "بلوك",
  "oifde.variable.ttsVolumeDesc" : "استخدم هذا المتغير لتعيين حجم TTS الخاص بالمستأجر.",
  "oifde.procedure.db.dbUrl" : "عنوان URL لقاعدة البيانات",
  "oifde.sysMgr.inputKey" : "أدخل كلمة مرور API.",
  "oifde.chatBotTest.promptMessage" : "حدد سجلًا واحدًا على الأقل.",
  'oifde.chatBotTest.downloadMessage': 'لقد حددت {0} سجلات. هل أنت متأكد من رغبتك في المتابعة؟',
  'oifde.chatBotTest.downloadAllMessage': 'لم تقم بتحديد سجلات معينة. هل تريد تصدير كافة السجلات؟ يمكنك أيضًا إلغاء العملية وتحديد سجل معين للتصدير.',
  "oifde.knowledgegraph.dicmgr.changeState" : "تعديل الحالة",
  "oifde.mxgraph.left" : "اليسار",
  "oifde.rule.actions.addSuccess" : "تمت إضافة القاعدة بنجاح.",
  "oifde.knowledgegraph.dicmgr.kgState" : "الحالة",
  "oifde.variable.actions.deletePrompt" : "ستؤدي هذه العملية إلى حذف المتغير نهائيًا. هل أنت متأكد من رغبتك في المتابعة؟",
  "oifde.maintenance.duration" : "المدة (دقائق)",
  "oifde.flow.importFlow.title" : "استيراد تدفق",
  "oifde.flow.rules.validationMode" : "حدد ما إذا كان سيتم تمكين التحقق الدقيق.",
  "oifde.operationlog.unused" : "غير مستخدم",
  "oifde.mxgraph.verticalFlow" : "التدفق الرأسي",
  "oifde.expression.expr_boolean" : "أدخل قيمة من النوع Boolean. عوامل المقارنة المتاحة بين القيم الرقمية أو سلاسل الأحرف هي >, <, >=, <=, و!=. عوامل التشغيل المنطقية هي &&, ||, ==, و! =. طريقة السلسلة تدعم startsWith.",
  "oifde.ivrJourney.repeatIncomingCallsDesc" : "عدد العملاء الذين يستخدمون نفس الرقم المتصل للاتصال بالخط الساخن لخدمة العملاء بشكل متكرر خلال ساعتين",
  "oifde.dashBoard.pie.faqTitle" : "الأسئلة الشائعة",
  "oifde.template.aiccTemplateNullTips" : "لا يمكن أن يكون نوع القالب فارغًا.",
  "oifde.serviceInterface.jsonChek" : "JSON بررسی",
  "oifde.operationlog.user_account" : "الحساب",
  "oifde.ivrJourney.indicatorSetting" : "إعداد المؤشر",
  "oifde.rule.update.newEndNum" : "أدخل رقم نهاية.",
  "oifde.diagram.error.NMS_actionParamDescError" : "يمكن أن يحتوي وصف المعلمة على 500 حرف كحد أقصى.",
  "oifde.navbar.current" : "تسجيل الدخول الحالي",
  "oifde.navbar.password.oldPassword" : "كلمة المرور القديمة",
  "oifde.mxgraph.NMS_VariableDefaultValueError" : "لا يمكن أن تحتوي القيمة الافتراضية للمتغير على أكثر من 1024 حرفًا.",
  "oifde.flow.enums.scenarioType.mainFlow" : "التدفق الرئيسي",
  "oifde.asrMgr.groupID" : "معرف مجموعة TTS",
  "oifde.accesscode.actions.confirm" : "نعم",
  "oifde.ivrJourney.alarmLevel" : "مستوى التحذير المسبق",
  "oifde.rate.exitConsultingCount" : "المكالمات التي تتم معالجتها فقط بواسطة Chatbot",
  "oifde.recordHistory.customer" : "العميل",
  "oifde.testCase.detail.channelAssociatedData" : "البيانات المرتبطة",
  "oifde.license.setlicense" : "تعيين الترخيص",
  "oifde.accesscode.dialogType" : "نوع الحوار",
  "oifde.mxgraph.NMS_TemplateCode" : "رمز القالب",
  "oifde.variable.variableTypeDetail.int" : "عدد صحيح",
  "oifde.rate.index" : "لا ..",
  "oifde.mxgraph.leftToRight" : "من اليسار إلى اليمين",
  "oifde.navbar.password.logOut" : "تسجيل الخروج",
  "oifde.serviceInterface.thirdAPILog" : "سجلات استدعاء الواجهة",
  "oifde.mxgraph.straight" : "مستقيم",
  "oifde.diagram.variableTypeDetail.list" : "جدول",
  "oifde.route.ivrOverview" : "نظرة عامة على IVR",
  "oifde.accesscode.actions.createSuccess" : "تم الإنشاء بنجاح.",
  "oifde.trace.logInfo" : "معلومات السجل",
  "oifde.mxgraph.guides" : "المرشدين",
  "oifde.fileTask.FILE_TOO_LARGE" : "تحتوي الحزمة المضغوطة على ملفات كبيرة جدًا.",
  "oifde.ivrJourney.total" : "المجموع",
  "oifde.knowledgegraph.dicmgr.trainAll" : "تدريب جميع",
  "oifde.knowledgegraph.extendFaq.notUse" : "غير مستخدم",
  "oifde.mxgraph.html" : "HTML",
  "oifde.knowledgegraph.relationmgr.doubleRelationTrue" : "نعم",
  "oifde.dashBoard.knowledge.dateConditionTips" : "حدد تاريخًا.",
  "oifde.common.noData" : "لا توجد بيانات",
  "oifde.fileTask.INVALID_TASK_CONTENT" : "بيانات المهمة غير صالحة.",
  "oifde.knowledgegraph.actions.success" : "النجاح",
  "oifde.variable.validate.defaultValueLength" : "تحتوي القيمة الافتراضية على أكثر من 1024 حرفًا.",
  "oifde.mxgraph.toBack" : "إلى الخلف",
  "oifde.mxgraph.NMS_actionParamNameError" : "يمكن أن يحتوي اسم المعلمة على 64 حرفًا كحد أقصى.",
  "oifde.sysConfig.succeed_apply_intent_param" : "تم تعديل معلمات الهدف بنجاح.",
  "oifde.knowledgegraph.attributemgr.deletePrompt" : "ستؤدي هذه العملية إلى حذف السمة نهائيًا. هل أنت متأكد من رغبتك في المتابعة؟",
  "oifde.mxgraph.fontFamily" : "عائلة الخط",
  "oifde.flow.exportFlowList.procedure" : "الإجراء المخزن",
  "oifde.flow.publish.upgrade" : "الترقية والنشر",
  "oifde.mxgraph.pattern" : "نمط",
  "oifde.testCase.status" : "الحالة",
  "oifde.rate.exitNoConsultCount" : "تم قطع الاتصال بالمكالمات مباشرةً",
  "oifde.ivrJourney.validIVRServiceDesc" : "عدد مكالمات IVR التي تتم فيها معالجة الخدمة أو الاستعلام عنها بنجاح، أو يتم فيها تشغيل صوت استشارة الخدمة أكثر من النصف، أو يتم تشغيل رسالة SMS أو MMS أو WAP Push أو البريد الإلكتروني بنجاح",
  "oifde.ivrJourney.beforeRelease" : "قبل الإصدار",
  "oifde.knowledgegraph.dicmgr.modifyTime" : "تم التعديل",
  "oifde.template.actions.confirm" : "نعم",
  "oifde.mxgraph.enterPropertyName" : "أدخل اسم الخاصية",
  "oifde.mxgraph.portrait" : "بورتريه",
  "oifde.operationlog.procedure_db" : "قاعدة بيانات الإجراءات المخزنة",
  "oifde.diagram.NMS_FlowVariableEncryptType" : "نوع التشفير",
  "oifde.fileTask.UNZIP_FAILED" : "فشل فك ضغط الحزمة.",
  "oifde.accesscode.accessCodeSearch" : "اسم Chatbot أو رمز الوصول إلى Chatbot",
  "oifde.template.setInputFlag" : "أدخل المحتوى يدويًا.",
  "oifde.serviceInterface.actions.manual_savePrompt" : "ستوفر هذه العملية „نص الرسالة اليدوي\" كتكوين واجهة الخدمة. هل أنت متأكد من رغبتك في المتابعة؟",
  "oifde.template.actions.deletePrompt" : "ستؤدي هذه العملية إلى حذف القالب نهائيًا. هل أنت متأكد من رغبتك في المتابعة؟",
  "oifde.variable.validate.scope" : "حدد النطاق.",
  "oifde.mxgraph.copy" : "نسخ",
  "oifde.knowledgegraph.model.beforeDeletePrompt" : "لا يمكن حذف المخطط لأنه مرتبط بقاموس الرسم البياني التالي:",
  "oifde.rate.interfaceTimes" : "أوقات استدعاء الواجهة",
  "oifde.mxgraph.NMS_VariableNameError" : "لا يمكن أن يحتوي اسم المتغير على أكثر من 1000 حرف.",
  "oifde.accesscode.trl" : "اكتب من اليمين إلى اليسار",
  "oifde.knowledgegraph.model.schemaDescription" : "الوصف",
  "oifde.operationlog.knowledge_graph_relation" : "علاقة قاموس الرسم البياني",
  "oifde.rule.message.overwrite_import" : "الكتابة فوق التكوين الحالي",
  "oifde.template.actions.delete" : "حذف",
  "oifde.procedure.actions.updateSuccess" : "نجح التعديل.",
  "oifde.common.validation.mrcpIdValueFalse" : "أدخل عددًا صحيحًا موجبًا يتراوح من 0 إلى 255.",
  "oifde.common.help" : "عرض معلومات المساعدة.",
  "oifde.serviceInterface.promptMessage" : "أدخل نص الرسالة.",
  "oifde.dashBoard.graph.talk" : "دردشة",
  "oifde.operationlog.exclude_entity" : "استبعاد الكيانات",
  "oifde.template.createChannelType" : "إضافة قناة",
  "oifde.tagsView.closeOthers" : "إغلاق الآخرين",
  "oifde.mxgraph.numberedList" : "قائمة مرقمة",
  "oifde.asrMgr.slotName" : "فتحة ASR",
  "oifde.rate.accessNum" : "المكالمات المتصلة",
  "oifde.knowledgegraph.actions.addRelation" : "إضافة علاقة",
  "oifde.fileTask.INVALID_FILE_EXTENSION" : "ملحق اسم الملف غير صالح.",
  "oifde.procedure.actions.ok" : "حسناً",
  "oifde.rule.actions.deleteConfirm" : "ستقوم هذه العملية بحذف القاعدة نهائيًا. هل أنت متأكد من رغبتك في المتابعة؟",
  "oifde.ivrJourney.directCalls" : "المباشر",
  "oifde.ivrJourney.selectAlarmIndex" : "يرجى تحديد مؤشر إحصائي.",
  "oifde.diagram.error.NMS_startNodeNotUnique" : "عقدة البدء ليست فريدة.",
  "oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_TONE.NAME" : "استخدام نغمة",
  "oifde.ivrJourney.nonDirectCalls" : "غير المباشر",
  "oifde.chatBotTest.getChatBotTestCaseConfig" : "توليد حالة الحوار",
  "oifde.tenantinfo.actions.edit" : "تحرير",
  "oifde.procedure.ruleRange" : "يمكن أن يحتوي عنوان URL على أحرف وأرقام فقط والأحرف الخاصة التالية:.؟ = : - يمكن أن تنتهي فقط بحرف أو رقم أو شرطة مائلة (/).",
  "oifde.mxgraph.enterName" : "أدخل الاسم",
  "oifde.knowledgegraph.dicmgr.moreOperation" : "المزيد",
  "oifde.complexcell.catalogTitle" : "مخطط مركب عنصر كتالوج",
  "oifde.toc.statusCode.INCORRECT_GENDER" : "الجنس غير صحيح.",
  "oifde.accesscode.validate.accessCode" : "أدخل رمز وصول تدفق.",
  "oifde.ivrJourney.nodeVersionCompareStatistics" : "إحصائيات مقارنة إصدار العقدة",
  "oifde.route.staticCheck" : "فحص التدفق",
  "oifde.qarecord.inputparam.seriesId" : "معرف السؤال والجواب",
  "oifde.mxgraph.drawingTooLarge" : "الرسم البياني كبير جداً.",
  "oifde.diagram.NMS_NodeName" : "اسم العقدة",
  "oifde.mxgraph.NMS_Desciption" : "الوصف",
  "oifde.operationlog.operation_obj_type" : "نوع كائن العملية",
  "oifde.serviceInterface.miss_ifs_out_parameter_type" : "الحقل الإلزامي التالي فارغ: معلمة الإخراج > نوع البيانات.",
  "oifde.mxgraph.updatingSelection" : "جاري تحديث التحديد. الرجاء الانتظار...",
  "oifde.operationlog.endTime" : "وقت الانتهاء",
  "oifde.knowledgegraph.actions.nameRequiredTip" : "أدخل اسمًا.",
  "oifde.operationlog.service_node" : "عقدة الخدمة",
  "oifde.procedure.db.dbDriver" : "برنامج تشغيل قاعدة البيانات",
  "oifde.serviceInterface.signBodyDetail.yes" : "نعم",
  "oifde.procedure.driverEnd" : "لا يمكن أن تنتهي القيمة بـ (.) نقطي أو واصلة (-).",
  "oifde.procedure.urlRule" : "يجب أن يبدأ Url بـ \"jdbc:mysql\" أو \"jdbc:zenith\".",
  "oifde.error.messageHead" : "واجهة الواجهة -",
  "oifde.chatbot.errorCode.100023000004" : "فشل التحقق من معرف الإدخال.",
  "oifde.flow.usage.templateUsageTitle" : "استخدام القالب",
  "oifde.trackmgr.actions.deleteSuccess" : "تم الحذف بنجاح.",
  "oifde.testCase.traceLog" : "مسار التدفق",
  "oifde.operationlog.acess_code" : "رمز الوصول",
  "oifde.flow.actions.copyFailed" : "فشل نسخ التدفق.",
  "oifde.operationlog.binding" : "ربط",
  "oifde.variable.actions.updateSuccess" : "نجح التعديل.",
  "oifde.testMgr.botTestCaseMgr" : "إدارة حالة الاختبار",
  "oifde.route.sysMgr.whiteList" : "التحكم في الواجهة",
  "oifde.table.search" : "البحث",
  "oifde.dashBoard.noData" : "- (لا يمكن عرض البيانات المحذوفة.)",
  "oifde.common.dialog.confirm.operationSucc" : "تم تنفيذ العملية بنجاح.",
  "oifde.service.invoke.breakMinTime.desc" : "الوحدة: ثواني؛",
  "oifde.flow.actions.cancel" : "لا",
  "oifde.mxgraph.NMS_startNodeNotBranchError" : "يمكن أن تحتوي عقدة البدء على فرع واحد فقط.",
  "oifde.toc.statusCode.TRANSIT_FAILED" : "انتهت مهلة الطلب.",
  "oifde.flow.publish.flowVersion" : "الإصدار",
  "oifde.template.language.chinese" : "الصينية",
  "oifde.expression.epxr_input_head" : "الحد الأقصى للطول:",
  "oifde.flow.enums.scenarioType.errorFlow" : "تدفق الاستثناء",
  "oifde.template.validate.contentLengthError" : "يجب أن يحتوي محتوى القالب على حرف واحد إلى 4096 حرفًا.",
  "oifde.rule.message.success_import" : "نجح الاستيراد.",
  "oifde.operationlog.test" : "اختبار",
  "oifde.flow.usage.status.running" : "التنفيذ",
  "oifde.mxgraph.fitWindow" : "تناسب النافذة",
  "oifde.ivrJourney.pleaseSelectAccessCode" : "يرجى اختيار روبوت.",
  "oifde.ivrJourney.pleaseSelectAccessCodeFirst" : "يرجى تحديد روبوت أولاً",
  "oifde.route.addDiagram" : "إضافة تدفق",
  "oifde.template.typeDetail.sms" : "SMS",
  "oifde.sysMgr.dataClean.ccvirrecordFlag" : "ما إذا كان سيتم تمكين التسجيل في تدفقات مكالمات IVR الشائعة.",
  "oifde.flow.add.exceptionFlow" : "تدفق غير طبيعي",
  "oifde.ivrJourney.IVRIncomingCallVolumeTrend" : "اتجاه مستوى الصوت في مكالمة IVR الواردة",
  "oifde.template.title.ResourceName" : "اسم المورد",
  "oifde.logparam.actions.deletePrompt" : "ستقوم هذه العملية بحذف المعلمة الديناميكية بشكل دائم. هل أنت متأكد من رغبتك في المتابعة؟",
  "oifde.flow.actions.deleteConfirm" : "ستؤدي هذه العملية إلى حذف التدفق بشكل دائم. هل أنت متأكد من رغبتك في المتابعة؟",
  "oifde.sysMgr.dataClean.editFlag" : "تعديل التسجيل في تدفق مكالمات IVR الذكي",
  "oifde.diagram.NMS_FlowVariableEncryptTypeTips" : "* لاحظ أنه عندما يكون نوع التشفير هو التشفير، يُنصح بتعيين قيمة لمتغير لمنع تسرب المعلومات.",
  "oifde.whitelist.whitePort" : "منفذ قائمة الثقة",
  "oifde.mxgraph.print" : "طباعة",
  "oifde.knowledgegraph.extendFaq.updateTemplateFailed" : "فشل تعديل قالب الأسئلة الشائعة.",
  "oifde.mxgraph.untitledLayer" : "طبقة بدون عنوان",
  "oifde.flow.usage.unuse" : "لم تستخدم أبداً",
  "oifde.asrMgr.editAsr" : "تعديل TTS/ASR",
  "oifde.rate.dataDimension" : "حدد أبعاد البيانات.",
  "oifde.rate.interfaceSuccessTimes" : "واجهات تم استدعاءها بنجاح",
  "oifde.operationlog.lock_account" : "قفل الحساب",
  "oifde.whitelist.whitePortLen" : "يمكن أن يحتوي رقم منفذ قائمة الثقة على 20 حرفًا كحد أقصى.",
  "oifde.procedure.actions.confirm" : "نعم",
  "oifde.template.updateTime" : "تم التعديل",
  "oifde.mxgraph.increaseIndent" : "زيادة المسافة البادئة",
  "oifde.mxgraph.removeFormat" : "مسح التنسيق",
  "oifde.testMgr.manage" : "إدارة",
  "oifde.sysMgr.dataClean.editSlientHelp" : "تعديل الوقت لانتظار الرد بعد صمت الوكيل",
  "oifde.trace.endTime" : "وقت الانتهاء",
  "oifde.diagram.usedVar" : "متغير مستعمل",
  "oifde.flow.actions.publish" : "نشر",
  "oifde.operationlog.faq_element" : "سؤال وجواب",
  "oifde.table.actions" : "الإجراءات",
  "oifde.template.addLimit" : "يُسمح بثلاثة سجلات محتوى كحد أقصى.",
  "oifde.logparam.actions.prompt" : "تأكيد",
  "oifde.operationlog.unkonw" : "غير معروف",
  "oifde.mxgraph.import" : "استيراد",
  "oifde.diagram.remove" : "حذف",
  "oifde.operationlog.binding_faqgroup" : "Chatbot مجموعة الأسئلة الشائعة",
  "oifde.fileTask.INVALID_SALT" : "الطلب غير صالح.",
  "oifde.variable.variableTypeDetail.string" : "الحرف",
  "oifde.toc.statusCode.INSUFFICIENT_PERMISSION" : "ليس لديك الإذن المطلوب. اتصل بالمسؤول.",
  "oifde.serviceInterface.certScene" : "السيناريو",
  "oifde.sysMgr.dataClean.traceLogAutoCloseDesc" : "وقت التعطيل التلقائي للتتبع.",
  "oifde.diagram.variableTypeDetail.string" : "الحرف",
  "oifde.route.unusedResource.rule" : "عرض القواعد الرمادية غير المستخدمة",
  "oifde.mxgraph.normal" : "عادي",
  "oifde.analysis.chart.default" : "فرع النظام الافتراضي",
  "oifde.template.validate.content" : "محتوى القالب إلزامي.",
  "oifde.expression.expr_simple_string" : "أدخل متغير سلسلة أو أي سلسلة أحرف. لا تضع سلسلة الأحرف في علامات اقتباس مفردة أو مزدوجة (\' أو ).",
  "oifde.mxgraph.NMS_DiagramID" : "معرف العملية",
  "oifde.flow.actions.createOk" : "تم إنشاء إصدار جديد من التدفق بنجاح.",
  "oifde.mxgraph.NMS_ServiceParams" : "معلمة الخدمة",
  "oifde.ivrJourney.alarmTime" : "وقت التحذير المسبق",
  "oifde.knowledgegraph.extendFaq.updateTime" : "تم تحديثه",
  "oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.NE.NAME" : "ماكس. قيمة N",
  "oifde.chatBotTest.passRate" : "معدل النجاح",
  "oifde.flow.exportFlowList.title" : "تصدير",
  "oifde.template.multipart.type.location" : "العنوان",
  "oifde.testCase.detailDialog" : "تفاصيل الحالة",
  "oifde.route.sysMgr.sysMgr" : "إدارة النظام",
  "oifde.procedure.procedureMgr" : "الإجراءات المخزنة",
  "oifde.mxgraph.NMS_flowNameError" : "لا يمكن أن يكون اسم التدفق فارغًا أو يتجاوز 1000 حرف.",
  "oifde.ivrJourney.releaseDate" : "وقت الإصدار",
  "oifde.procedure.dbTypes.GAUSS" : "GaussDB",
  "oifde.dashBoard.chartView.rateTile" : "معدل نجاح الرد",
  "oifde.flow.status" : "الحالة",
  "oifde.license.queryCurrentLicense" : "الاستعلام عن التوافق والتراكم الحاليين",
  "oifde.mxgraph.edit" : "تحرير",
  "oifde.serviceInterface.actions.confirm" : "نعم",
  "oifde.mxgraph.NMS_nodeRefError" : "تمت الإشارة إلى العقدة بواسطة تدفق آخر ولا يمكن حذفها.",
  "oifde.template.actions.deleteResponseStyle" : "ستؤدي هذه العملية إلى حذف النمط نهائيًا. هل أنت متأكد من رغبتك في المتابعة؟",
  "oifde.mxgraph.NMS_ActionTypesGetFail" : "فشل الحصول على معلومات عنصر المخطط.",
  "oifde.ivrJourney.IVRRateNoSymbol" : "معدل خدمة IVR النقي",
  "oifde.template.deleteContent" : "حذف",
  "oifde.flow.importFlow.result" : "النتيجة",
  "oifde.ivrJourney.journeyNumTrend" : "توزيع اتجاه القيمة",
  "oifde.ivrJourney.beforeReleaseRepeatRate" : "معدل إعادة التشغيل قبل الإصدار",
  "oifde.testMgr.getStatusWarn" : "لا يمكن إنشاء حالات الاختبار حالياً بسبب طلب عملية موجودة إنشاء حالة اختبار. الرجاء الانتظار...",
  "oifde.logparam.actions.confirm" : "نعم",
  "oifde.knowledgegraph.dicmgr.inactive" : "غير نشط",
  "oifde.flow.usage.complexcellUsageTitle" : "استخدام عنصر المخطط المركب",
  "oifde.ivrJourney.pleaseSelectVersion" : "الرجاء تحديد إصدار تدفق.",
  "oifde.complexcell.cell.actions.editInfo" : "تعديل المعلومات الأساسية",
  "oifde.rate.rateForCharts" : "صافي معدل دقة الخدمة الذاتية",
  "oifde.template.selectTemplate" : "تحديد قالب",
  "oifde.logparam.message.validateResult" : "معلومات",
  "oifde.mxgraph.autosize" : "الحجم التلقائي",
  "oifde.rule.message.confirm_delete_user_ids" : "هل تريد بالتأكيد حذف أرقام المشتركين؟",
  "oifde.recordHistory.bot" : "Chatbot",
  "oifde.accesscode.actions.addbaseInfo" : "إضافة Chatbot",
  "oifde.audioVideoResource.cancelUpdate.confirm" : "هل تريد بالتأكيد إلغاء التحديث؟",
  "oifde.common.validation.flowMaxElementsRange" : "تتراوح الفاصل الزمني لعقد تدفق IVR من {0} إلى {1}.",
  "oifde.sysConfig.description" : "وصف متغير",
  "oifde.mxgraph.containsValidationErrors" : "تم العثور على خطأ في التحقق",
  "oifde.flow.rules.newVersionDuplicated" : "يجب أن تكون النسخة الجديدة فريدة من نوعها.",
  "oifde.toc.statusCode.ROLE_DUPLICATED" : "الدور موجود بالفعل.",
  "oifde.route.knowledgegraph.relationmgr" : "إدارة العلاقات لـ {0}",
  "oifde.chatbot.errorCode.100051000032" : "فشل حذف حالات الاختبار.",
  "oifde.chatbot.errorCode.100051000031" : "يتجاوز عدد المعرّفات المدخلة الحد الأعلى.",
  "oifde.knowledgegraph.editSchema.entityName" : "اسم المفهوم",
  "oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.BORDER_EXT_ENABLE.TRUE" : "تمكين",
  "oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_PINYIN.NAME" : "استخدام Pinyin",
  "oifde.route.indicatorAnalysis" : "تحليل المؤشرات",
  "oifde.knowledgegraph.model.schemaName" : "اسم المخطط",
  "oifde.mxgraph.remindSave" : "لم تقم بحفظ البيانات لفترة طويلة. احفظ البيانات على الفور.",
  "oifde.flow.exportFlowList.rule" : "القاعدة",
  "oifde.serviceInterface.whiteListCheckWarning" : "عنوان URL (عنوان IP ورقم المنفذ) غير موجود في قائمة الثقة ويمكن استخدامه فقط بعد الاتصال بمسؤول النظام لإضافته إلى قائمة الثقة.",
  "oifde.accesscode.faqGroup" : "مجموعة الأسئلة الشائعة",
  "oifde.diagram.variableTypeDetail.long" : "عدد صحيح طويل",
  "oifde.serviceInterface.auto_msgBody" : "جسم الرسالة التلقائي",
  "oifde.knowledgegraph.kgFAQ.name" : "قاموس الرسم البياني:",
  "oifde.sysConfig.INTENT_DECISION_MAKER.TOP.NAME" : "الحد الأعلى",
  "oifde.chatbot.errorCode.100051000028" : "لم يتم العثور على نتيجة الاختبار.",
  "oifde.sysMgr.success_set_key" : "تم تعيين كلمة مرور API بنجاح!",
  "oifde.asrMgr.selectOne" : "حدد واحدًا على الأقل مما يلي: مزود خادم ASR وخادم ASR وخادم TTS.",
  "oifde.chatbot.errorCode.100051000026" : "فشل تنفيذ الاختبار نظرًا لأن عدد مجموعات الاختبار المحددة كبير جدًا.",
  "oifde.mxgraph.width" : "العرض",
  "oifde.procedure.baseInfo" : "معلومات أساسية",
  "oifde.chatbot.errorCode.100051000023" : "فشل حذف مجموعة الحالات.",
  "oifde.chatbot.errorCode.100051000022" : "فشل حفظ حالة الاختبار.",
  "oifde.diagram.error.NMS_VariableDefaultValueError" : "يمكن أن تحتوي القيمة الافتراضية للمتغير على 1024 حرفًا كحد أقصى.",
  "oifde.toc.statusCode.TRANSIT_ERROR" : "تم إرسال الطلب بنجاح ولكن النظير أرجع خطأ.",
  "oifde.qiAsrServerMgr.ipOrDomainNameError" : "عنوان IP أو اسم مجال غير صحيح.",
  "oifde.mxgraph.addLayer" : "إضافة طبقة",
  "oifde.template.desc" : "الوصف",
  "oifde.audioVideoResource.setLanguage" : "إعداد اللغة",
  "oifde.operationlog.sensitive_group" : "مجموعة الكلمات الحساسة",
  "oifde.mxgraph.NMS_actionParamDescError" : "لا يمكن أن يحتوي وصف المعلمة على أكثر من 500 حرف.",
  "oifde.template.actions.addbaseInfo" : "إضافة قالب",
  "oifde.operationlog.create" : "إنشاء",
  "oifde.diagram.fastGetCell" : "البحث عن عنصر الرسم البياني",
  "oifde.flow.actions.errorMessage" : "رسالة خطأ",
  "oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.MIN_DF.DESC" : "يتم تجاهل كلمة عندما يكون وزنها أقل من هذه العتبة.",
  "oifde.sysConfig.update" : "تطبيق",
  "oifde.mxgraph.invalidName" : "اسم غير صالح",
  "oifde.ivrJourney.increaseValue" : "MoM السعر",
  "oifde.flow.usage.inuse" : "قيد الاستخدام",
  "oifde.recordHistory.userSaying" : "نص الطلب",
  "oifde.knowledgegraph.entitymgr.schemaEntityName" : "مفهوم المخطط المرتبط",
  "oifde.trace.transitionOn" : "فرع نتيجة التنفيذ",
  "oifde.logparam.logParam" : "المعلمات الديناميكية",
  "oifde.common.validation.flowSaveIntervalRange" : "يجب أن يكون الفاصل الزمني لحفظ تنسيق تدفق IVR عددًا صحيحًا موجبًا. يجب ألا يقل عن {0} دقيقة ولا يزيد عن {1} دقيقة",
  "oifde.mxgraph.text" : "نص",
  "oifde.mxgraph.NMS_DiagramName" : "اسم العملية",
  "oifde.whitelist.whiteType" : "نوع قائمة الثقة",
  "oifde.sysMgr.inputRpaSecretKey" : "أدخل مفتاح RPA السري.",
  "oifde.rule.message.import_words_hint_text" : "يمكن استيراد الملفات النصية (*.txt). تنسيق ترميز الملف هو UTF-8.",
  "oifde.fileTask.ENTRY_TOO_LARGE" : "تحتوي الحزمة المضغوطة على ملفات كبيرة الحجم.",
  "oifde.testMgr.case_tested" : "الكمية التي تم اختبارها",
  "oifde.route.serviceInterface" : "واجهة الأعمال",
  "oifde.fileTask.IMPORT_KG_ENTITY_TOO_BIG" : "يسمح بحد أقصى 1 مليون كيانات الرسم البياني",
  "oifde.dashBoard.chartView.title" : "معدل نجاح الرد",
  "oifde.qarecord.interface.name" : "سجل سؤال وجواب",
  'oifde.apiToken.interface.name':'الحصول على رمز APIFabric',
  'oifde.apiToken.interface.desc':'الواجهة المستخدمة للحصول على رمز API Fabric Token',
  'oifde.apiToken.applyType.desc': 'بادئة الرمز المميز',
  'oifde.apiToken.accessToken.desc': 'محتوى الرمز المميز',
  'oifde.apiToken.appKey.desc': 'مفتاح التطبيق',
  'oifde.apiToken.appSecret.desc': 'سر التطبيق',
  "oifde.template.update.templateType" : "النوع",
  "oifde.mxgraph.publishSuccess" : "تم حفظ التدفق ونشره بنجاح.",
  "oifde.flow.publish.selectPlaceholder" : "-اختر-",
  "oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.NAME" : "N-غرام ميزة استخراج وحدة",
  "oifde.mxgraph.NMS_VariableDescError" : "لا يمكن أن يحتوي وصف المتغير على أكثر من 1000 حرف.",
  "oifde.testMgr.natureColon" : ":",
  "oifde.ivrJourney.usageStatistics" : "إحصاءات استخدام مفتاح تشغيل الصوت الحالي",
  "oifde.qiAsrServerMgr.voiceUploadFolder" : "مسار تحميل ملفات التسجيل",
  "oifde.chatBotTest.callingId" : "الرقم المتصل",
  "oifde.flow.scenarioType" : "نوع السيناريو",
  "oifde.mxgraph.navigation" : "الملاحة",
  "oifde.trace.reasonAdvice" : "السبب والاقتراح",
  "oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.TSF_ENABLE.DESC" : "ما إذا كان سيتم تمكين خوارزمية TSF.",
  "oifde.sysConfig.successful_operation" : "تمت العملية بنجاح.",
  "oifde.accesscode.callingnumberinput" : "أدخل الرقم الطالب.",
  "oifde.trace.validate.callingNumber" : "أدخل رقماً متصلاً.",
  "oifde.mxgraph.selectEdges" : "حدد الحواف",
  "oifde.rate.hourConditionTips" : "التاريخ أو رمز الوصول مفقود في معايير البحث.",
  "oifde.navbar.switchUser" : "تبديل المستخدم",
  "oifde.mxgraph.group" : "المجموعة",
  "oifde.accesscode.actions.voicefile" : "ملف الصوت:",
  "oifde.testMgr.select_prompt" : "حدد سجلًا واحدًا على الأقل.",
  "oifde.operationlog.procedure_interface" : "الإجراء المخزن",
  "oifde.flow.usage.usageStatus" : "حالة الإحصاءات:",
  "oifde.toc.statusCode.REFRESH_TIMEOUT" : "لم يتم إجراء أي عملية لفترة طويلة. تسجيل الدخول مرة أخرى.",
  "oifde.flow.setting.validationModeOff" : "تعطيل",
  "oifde.ivrJourney.optimizeSuggestion" : "اقتراحات التحسين",
  "oifde.knowledgegraph.editSchema.dataType.boolean" : "Boolean",
  "oifde.knowledgegraph.extendFaq.extendFaq" : "قائمة قوالب الأسئلة الشائعة",
  "oifde.trackmgr.validate.pattern" : "يُسمح فقط بالحروف والأرقام والمسافات والأحرف الخاصة التالية:. - \' : {\'@\'} /",
  "oifde.mxgraph.noColor" : "لا لون.",
  "oifde.diagram.NMS_ParamValue" : "القيمة",
  "oifde.trace.nodeTrace" : "معلومات سجل العقدة",
  "oifde.serviceInterface.name" : "الاسم",
  "oifde.flow.usage.name" : "الاسم",
  "oifde.mxgraph.NMS_encryptValidate" : "يمكن تعيين متغير IVRREQUEST المشفر ومتغير FLOW ومتغير GLOBAL فقط لمتغيرات FLOW المشفرة ومتغيرات GLOBAL.",
  "oifde.ivrJourney.oneDay" : "يوم واحد",
  "oifde.flow.setting.validationMode" : "تعيين وضع التحقق من الصحة",
  "oifde.knowledgegraph.actions.cancelDelete" : "تم إلغاء الحذف.",
  "oifde.fileTask.UNDEFINED_ERROR" : "حدث خطأ أثناء تنفيذ المهمة.",
  "oifde.fileTask.IMPORT_SCHEMA_TOO_BIG" : "يُسمح بحد أقصى من كيانات مفهوم 10,000 في مخطط المعرفة.",
  "oifde.chatBotTest.getChatBotTestCase" : "توليد حالة الحوار",
  "oifde.route.tuc.ops.node" : "إدارة الصيانة",
  "oifde.ivrJourney.successRate" : "معدل النجاح",
  "oifde.template.createResponseStyle" : "إضافة نمط",
  "oifde.flow.importFlow.select" : "تحديد ملف",
  "oifde.qiAsrServerMgr.qiResultFolder" : "مسار نتيجة الترجمة",
  "oifde.sysMgr.configuration" : "تعيين",
  "oifde.mxgraph.images" : "الصور",
  "oifde.accesscode.isCheck" : "تحقق إملائي",
  "oifde.sysConfig.confirm_apply_intent_param" : "سيؤثر تعديل معلمات النية بشكل كبير على التعرف على النية. هل أنت متأكد من رغبتك في المتابعة؟",
  "oifde.trace.actionContent" : "اسم التعليمات",
  "oifde.template.message.intentNameDuplicate" : "اسم الهدف موجود بالفعل.",
  "oifde.knowledgegraph.extendFaq.updateSuccess" : "تم تغيير الحالة بنجاح.",
  "oifde.mxgraph.setAsDefaultStyle" : "تعيين كنمط افتراضي",
  "oifde.operationlog.test_corpus" : "اختبار كوربوس",
  "oifde.testMgr.confirm_delete_case" : "هل تريد بالتأكيد حذف حالة الاختبار؟",
  "oifde.serviceInterface.busiCode" : "رمز الواجهة",
  "oifde.variable.actions.deleteSuccess" : "تم الحذف بنجاح.",
  "oifde.logparam.actions.cancel" : "لا",
  "oifde.operationlog.setting" : "إعداد",
  "oifde.knowledgegraph.dicmgr.extendQuestion" : "سؤال موسع",
  "oifde.knowledgegraph.kgFAQ.welcomeContent" : "مرحباً، كيف يمكنني مساعدتك اليوم؟",
  "oifde.template.channelTypeName" : "الاسم",
  "oifde.logparam.sourceDetail.TOCResponseInformation" : "استجابة TOC",
  "oifde.serviceInterface.ok" : "حسناً",
  "oifde.testMgr.testMgr" : "اختبار المعرفة",
  "oifde.template.selectChannelType" : "حدد قناة.",
  "oifde.serviceInterface.certUpdateTime" : "تم تحديثه",
  "oifde.mxgraph.constrainProportions" : "تقييد النسب",
  "oifde.mxgraph.orthogonal" : "المتعامد",
  "oifde.mxgraph.replaceIt" : "{0} موجود بالفعل. هل تريد استبدالها؟",
  "oifde.diagram.NMS_VoiceTemplate" : "قالب الاستجابة",
  "oifde.serviceInterface.dataTypes.OBJECT" : "كائن",
  "oifde.license.detailHisInfo" : "تفاصيل تاريخية",
  "oifde.expression.expr_key_repeats" : "اسم شرط مكرر.",
  "oifde.mxgraph.NMS_DiagramProperties" : "سمة العملية",
  "oifde.route.knowledgegraph.dicmgr" : "قاموس الرسم البياني",
  "oifde.ivrJourney.indicatorQuantityStatistics" : "إحصاءات كمية المؤشرات",
  "oifde.rate.dayconditionTips" : "رمز الوصول مفقود في معايير البحث.",
  "oifde.route.view" : "عرض {0}",
  "oifde.tenantinfo.actions.success" : "النجاح",
  "oifde.mxgraph.preview" : "معاينة",
  "oifde.audioVideoResource.cancelUpdate.success" : "تم إلغاء التحديث بنجاح",
  "oifde.ivrJourney.nonDirectRate" : "معدل غير المباشر",
  "oifde.knowledgegraph.extendFaq.edit" : "تعديل -",
  "oifde.ivrJourney.ivrRepeatCallNum" : "IVR تكرار المكالمات",
  "oifde.variable.voiceNameDesc" : "استخدم هذا المتغير لتعيين مكبر صوت TTS الخاص بالمستأجر.",
  "oifde.operationlog.operation_log" : "سجل التشغيل",
  "oifde.diagram.error.NMS_transitToStartNodeError" : "فشل الانتقال إلى عقدة البدء.",
  "oifde.sysConfig.ENERGY_FILTER.MAX.NAME" : "الحد الأقصى للنوايا",
  "oifde.operationlog.faq" : "مجموعة الأسئلة الشائعة",
  "oifde.flow.importFlow.bigFile" : "حجم الملف كبير جداً.",
  "oifde.template.editContent" : "إضافة محتوى قالب",
  "oifde.route.tuc.template.intent" : "إدارة النوايا",
  "oifde.diagram.variableTypeDetail.int" : "عدد صحيح",
  "oifde.rule.message.select_prompt" : "حدد سجلًا واحدًا على الأقل.",
  "oifde.testMgr.testCase" : "حالة الاختبار",
  "oifde.robotReply.displayName" : "روبوت الرد",
  "oifde.rule.flowRule" : "تدفق القاعدة الرمادية",
  "oifde.knowledgegraph.dicmgr.trainSuccess" : "التدريب ناجح.",
  "oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_PINYIN.FALSE" : "لا",
  "oifde.mxgraph.NMS_BranchCondition" : "حالة الفرع",
  "oifde.serviceInterface.invokingDetails" : "تفاصيل الاتصال",
  "oifde.procedure.db.dbType" : "نوع قاعدة البيانات",
  "oifde.mxgraph.solid" : "الصلبة",
  "oifde.chatBotTest.caseTimeRange" : "النطاق الزمني",
  "oifde.complexcell.cell.height" : "الارتفاع",
  "oifde.rate.resolveRate" : "صافي معدل دقة الخدمة الذاتية",
  "oifde.sysMgr.dataClean.editFlowSaveInterval" : "تحرير الفاصل الزمني لحفظ تنسيق تدفق IVR",
  "oifde.trackmgr.track" : "إعدادات المسار",
  "oifde.logparam.actions.add" : "إضافة",
  "oifde.operationlog.logout" : "تسجيل الخروج",
  "oifde.testMgr.import_test_cases_hint" : "يمكن استيراد 200 حالة اختبار كحد أقصى بتنسيق TXT.",
  "oifde.dashBoard.pie.kg" : "الرسم البياني",
  "oifde.route.tuc.ops.license" : "إدارة التراخيص",
  "oifde.ivrJourney.transToManualCallsSuccDesc" : "عدد مكالمات IVR التي تم تحويلها بنجاح إلى موظف",
  "oifde.serviceInterface.dataTypes.FLOAT" : "رقم النقطة العائمة",
  "oifde.operationlog.trainAll" : "تدريب جميع",
  "oifde.ivrJourney.failRate" : "معدل الفشل",
  "oifde.request.timeout" : "انتهت مهلة طلب الواجهة",
  "oifde.variable.ttsSpeedDesc" : "استخدم هذا المتغير لتعيين سرعة حديث TTS للمستأجر.",
  "oifde.route.tuc.template.kg" : "إدارة الرسوم البيانية",
  "oifde.sysMgr.encryptSecretRuleRange" : "لا يفي سر التشفير بالمتطلبات. يجب أن يحتوي على نوعين على الأقل مما يلي: أحرف كبيرة وأحرف صغيرة وأرقام وأحرف خاصة ~ `! {\'@\'} # {\'$\'} % ^ * ( ) - + = {\'|\'},.",
  "oifde.chatBotTest.testCaseGroup" : "مجموعات حالة الاختبار",
  "oifde.navbar.tenantId" : "معرف المستأجر الحالي",
  "oifde.logparam.actions.createSuccess" : "تم الإنشاء بنجاح.",
  "oifde.toc.statusCode.RESPONSE_FAILED" : "فشل الخادم في معالجة الاستجابة.",
  "oifde.sysConfig.DUMMY_VECTOR_CLASSIFIER.MIU.DESC" : "قيمة لوجستية المصنف المقصود بالإنجليزية: Quarter لـ بالإنجليزية: Quarter.",
  "oifde.flow.actions.settingOk" : "تم تكوين التدفق بنجاح.",
  "oifde.mxgraph.editTooltip" : "تحرير تلميح الأدوات",
  "oifde.route.navigation" : "إدارة التدفق",
  "oifde.knowledgegraph.actions.editExtQuestion" : "تحرير سؤال موسع",
  "oifde.operationlog.reset_password" : "إعادة تعيين كلمة المرور",
  "oifde.rate.interfaceSuccessCount" : "نداءات ناجحة",
  "oifde.mxgraph.export" : "تصدير",
  "oifde.mxgraph.backgroundColor" : "لون الخلفية",
  "oifde.serviceInterface.manual_msgBody" : "دليل رسالة الجسم",
  "oifde.dashBoard.graph.title" : "إحصائيات المعرفة الصالحة",
  "oifde.flow.usage.resourceType.flowvars" : "متغير التدفق",
  "oifde.common.detail" : "التفاصيل",
  "oifde.qiAsrServerMgr.fileServerPW" : "كلمة مرور خادم الملفات",
  "oifde.flow.publish.notConfig" : "لم يتم تكوينه",
  "oifde.ivrJourney.logTime" : "LogTime",
  "oifde.ivrJourney.versionName" : "اسم الإصدار",
  "oifde.mxgraph.outline" : "المخطط التفصيلي",
  "oifde.mxgraph.background" : "خلفية",
  "oifde.toc.statusCode.EMPTY_TENANT_SPACE" : "لا يمكن أن تكون مساحة المستأجر فارغة.",
  "oifde.accesscode.flowCode" : "رمز التدفق",
  "oifde.common.validation.numbers" : "يرجى إدخال أرقام فقط.",
  "oifde.operationlog.navigation_info" : "معلومات الملاحة",
  "oifde.common.validation.collectTimeoutRule" : "يتم دعم المتغيرات FLOW و GLOBAL. يمكن أن تكون القيمة أيضًا عددًا صحيحًا يتراوح من 1 إلى 120. إذا كانت القيمة لا تفي بالمتطلبات، يتم استخدام القيمة الافتراضية 5.",
  "oifde.license.license" : "إدارة التراخيص",
  "oifde.template.content.exist" : "تحديد الموارد",
  "oifde.knowledgegraph.entitymgr.entityAttributeMgr.deletePrompt" : "ستؤدي هذه العملية إلى حذف سمة الكيان نهائيًا. هل أنت متأكد من رغبتك في المتابعة؟",
  "oifde.testMgr.case_amount" : "حالات الاختبار",
  "oifde.chatBotTest.failTimes": "فشل",
  "oifde.accesscode.dialogTypeDetail.smartOutboundCall": "مكالمة صادرة ذكية",
  "oifde.sysMgr.apiKey": "كلمة مرور API",
  "oifde.diagram.NMS_BranchProperties": "سمة الفرع",
  "oifde.operationlog.activate": "تفعيل",
  "oifde.flow.actions.copy": "تدفق النسخ",
  "oifde.procedure.dbTypes.ORACLE": "أوراكل",
  "oifde.mxgraph.arrange": "ترتيب",
  "oifde.flow.publish.upgradeMode": "وضع النشر",
  "oifde.common.validation.extendFaqRange": "فقط الحروف الصينية والحروف الإنجليزية والأرقام والمسافات والرموز الخاصة التالية هي allowed: ( ) [ ] {\'@\'} {\'{\'} {\'}\'},, 。. {\'|\'} \'",
  "oifde.table.add": "إضافة",
  "oifde.testMgr.allDomain": "تحديد كافة المجالات",
  "oifde.knowledgegraph.dicmgr.operation": "العملية",
  "oifde.mxgraph.formatPanel": "لوحة التنسيق",
  "oifde.dashBoard.serviceCapabilityDashBoard": "لوحة معلومات قدرة الخدمة",
  "oifde.operationlog.knowledge_graph_ask_template": "سؤال موسع",
  "oifde.ivrJourney.times": "مرات",
  "oifde.mxgraph.clipart": "كليبارت",
  "oifde.toc.statusCode.ACCOUNT_DUPLICATED": "الحساب موجود بالفعل.",
  "oifde.ivrJourney.alarmIndex": "المؤشرات الإحصائية",
  "oifde.testMgr.abortCorpusTestCase": "مقاطعة",
  "oifde.template.contentDetail": "التفاصيل",
  "oifde.rule.message.failed_delete_user_ids": "فشل حذف أرقام المشتركين.",
  "oifde.flow.exportFlowList.askExportAll": "لم تقم بتحديد سجلات معينة. هل تريد تصدير كافة السجلات؟ يمكنك أيضًا إلغاء العملية وتحديد سجل معين للتصدير.",
  "oifde.procedure.actions.delete": "حذف",
  "oifde.mxgraph.collapse": "الانهيار",
  "oifde.flow.rule": "القاعدة الرمادية",
  "oifde.table.delete": "حذف",
  "oifde.trace.validate.needNumber": "أدخل أرقامًا أو حروفًا.",
  "oifde.template.update.intents": "قائمة النوايا",
  "oifde.ivrJourney.selectAccesscodeAndFlow": "حدد الروبوت وإصدار العملية.",
  "oifde.operationlog.user_entity": "كيان المستخدم",
  "oifde.knowledgegraph.kgView.entityName": "اسم الكيان",
  "oifde.mxgraph.bulletedList": "قائمة نقطية",
  "oifde.mxgraph.done": "تم",
  "oifde.flow.copy.flowName": "اسم التدفق الأصلي",
  "oifde.variable.validate.intValueCheck": "القيمة الافتراضية لبيانات العدد الصحيح غير صحيحة. تتراوح من -2147483648 إلى +2147483647.",
  "oifde.template.title.ResourceType": "نوع المورد",
  "oifde.mxgraph.removeFromGroup": "إزالة من مجموعة",
  "oifde.operationlog.license": "الرخصة",
  "oifde.knowledgegraph.actions.confirm": "حسناً",
  "oifde.testCase.detail.close" : "إغلاق",
  "oifde.ivrJourney.hangupRate" : "Hang Up Rate",
  "oifde.ivrJourney.good" : "جيد",
  "oifde.serviceInterface.headerValue" : "القيمة",
  "oifde.sysMgr.variableSecretNotExists" : "المفتاح إلزامي عند استخدام متغير مشفر. اختر إدارة النظام > تكوين النظام وانقر فوق إعدادات المفاتيح لضبط المفتاح.",
  "oifde.mxgraph.NMS_checkDefaultNextNodeForView" : "لا يمكن أن يحتوي عنصر المخطط الخاص بنوع العرض على أكثر من فرع واحد.",
  "oifde.flow.add.outBoundFlow" : "تدفق المكالمات الصادرة",
  "oifde.procedure.in_param" : "معلمة الإدخال",
  "oifde.rule.actions.confirm" : "تأكيد",
  "oifde.knowledgegraph.model.createUser" : "تم الإنشاء بواسطة",
  "oifde.mxgraph.deleteRow" : "حذف صف",
  "oifde.ivrJourney.fillAlarmCondition" : "يرجى إكمال شروط الإنذار المسبق.",
  "oifde.flow.actions.deleteCancel" : "تم إلغاء الحذف.",
  "oifde.variable.channeltypeDesc" : "استخدم هذا المتغير لتعيين قناة المستأجر.",
  "oifde.mxgraph.grid" : "الشبكة",
  "oifde.knowledgegraph.actions.saveFailed" : "فشل الحفظ.",
  "oifde.mxgraph.panTooltip" : "Space+Drag to Scroll",
  "oifde.knowledgegraph.attributemgr.attributeName" : "اسم السمة",
  "oifde.logparam.actions.edit" : "تحرير",
  "oifde.ivrJourney.indicatorComparisonBarChart" : "مقارنة العداد شريط الرسم البياني",
  "oifde.testMgr.add_test_case" : "إضافة حالة اختبار",
  "oifde.operationlog.hang_up" : "شنق",
  "oifde.mxgraph.borderColor" : "لون الحدود",
  "oifde.chatBotTest.testTime" : "تم اختبارها",
  "oifde.diagram.NMS_AdvanceProperties" : "سمة متقدمة",
  "oifde.trace.flowName" : "اسم التدفق",
  "oifde.route.tuc.template.index" : "إدارة المعرفة",
  "oifde.mxgraph.ungroup" : "فك التجميع",
  "oifde.error.createfail" : "فشلت إضافة البيانات المحلية.",
  "oifde.ivrJourney.selfService" : "الخدمة الذاتية",
  "oifde.rate.autonomyRate" : "صافي معدل دقة الخدمة الذاتية",
  "oifde.mxgraph.NMS_PleaseSelectTemplate" : "حدد قالبًا.",
  "oifde.template.validate.templateName" : "أدخل اسم قالب.",
  "oifde.variable.variableDesc" : "الوصف",
  "oifde.logparam.validate.targetValue" : "أدخل قيمة الهدف.",
  "oifde.mxgraph.pageSetup" : "إعداد الصفحة",
  "oifde.ivrJourney.selectRemindObject" : "الرجاء تحديد كائن تذكير.",
  "oifde.whitelist.deletePrompt" : "ستقوم هذه العملية بحذف قائمة الثقة المحددة بشكل دائم. هل أنت متأكد من رغبتك في المتابعة؟",
  "oifde.sysMgr.success_set_encryptSecret" : "تم تعيين المفتاح بنجاح.",
  "oifde.mxgraph.NMS_DefaultBranch" : "الفرع الافتراضي",
  "oifde.asrMgr.asr" : "مزود خادم ASR",
  "oifde.operationlog.lable_corpus" : "Tagged الجسم",
  "oifde.knowledgegraph.extendFaq.similarQuestion" : "سؤال مشابه",
  "oifde.knowledgegraph.validation.schemaName" : "أدخل اسم مخطط.",
  "oifde.qiAsrServerMgr.fileServerIP" : "عنوان IP لخادم الملفات",
  "oifde.toc.statusCode.EMPTY_ACCOUNT" : "رقم الحساب فارغ.",
  "oifde.recordHistory.systemReply" : "نص الاستجابة",
  "oifde.knowledgegraph.kgSynonyms.synonymsDetail" : "مرادف تفاصيل",
  "oifde.dashBoard.pie.faq" : "الأسئلة الشائعة",
  "oifde.template.defaultValueValidate.ttsSpeedValueDesc" : "نطاق القيمة : [-500,500]",
  "oifde.accesscode.actions.deletePrompt" : "ستؤدي هذه العملية إلى حذف chatbot نهائيًا. هل أنت متأكد من رغبتك في المتابعة؟",
  "oifde.flow.usage.globalVarsUsageTitle" : "الاستخدام المتغير العالمي",
  "oifde.ivrJourney.remindObject" : "كائن تذكير",
  "oifde.accesscode.setTraceSuccess" : "تم تطبيق الإعدادات بنجاح.",
  "oifde.accesscode.accessCodeDesc" : "الوصف",
  "oifde.knowledgegraph.dicmgr.dicName" : "اسم الرسم البياني",
  "oifde.accesscode.usingRecord" : "التقاط الحزمة",
  "oifde.robotReply.replMode" : "وضع الرد",
  "oifde.sysMgr.requiredAPIKey" : "أدخل كلمة مرور API.",
  "oifde.mxgraph.NMS_transitToStartNodeError" : "فشل الانتقال إلى عقدة البدء.",
  "oifde.mxgraph.opacity" : "التعتيم",
  "oifde.procedure.db.dbPassword" : "كلمة مرور قاعدة البيانات",
  "oifde.ivrJourney.equal" : "يساوي",
  "oifde.route.tuc.ops.index" : "التحقق من التدريب",
  "oifde.mxgraph.duplicate" : "تكرار",
  "oifde.operationlog.unlock_account" : "فتح الحساب",
  "oifde.ivrJourney.outflowDetailDistribution" : "توزيع مفصّل Outflow",
  "oifde.variable.actions.delete" : "حذف",
  "oifde.common.validation.int" : "يجب أن تكون القيمة عددًا صحيحًا من 0 إلى 2147483647.",
  "oifde.rule.actions.errorMessage" : "رسالة خطأ",
  "oifde.route.tuc.ops.qiAsrServer" : "فحص خادم ASR",
  "oifde.ivrJourney.afterReleaseHangupNum" : "عدد عمليات قطع الاتصال بعد الإصدار",
  "oifde.recordHistory.oneCondition" : "مطلوب إما التاريخ أو رقم الهاتف المحمول.",
  "oifde.fileTask.INCORRECT_PARAMETERS" : "المعلمة غير صالحة.",
  "oifde.diagram.NMS_ScenarioType" : "نوع السيناريو",
  "oifde.route.flow" : "تزامن",
  "oifde.knowledgegraph.relationmgr.doubleRelation" : "العلاقة ثنائية الاتجاه",
  "oifde.qiAsrServerMgr.filePathError" : "تنسيق المسار غير صحيح.",
  "oifde.ivrJourney.hangupNum" : "شنق",
  "oifde.mxgraph.sidebarTooltip" : "انقر لتوسيع أو طي لوحة العقدة.",
  "oifde.serviceInterface.interfaceCert" : "شهادة واجهة",
  "oifde.mxgraph.verticalTree" : "شجرة عمودية",
  "oifde.logparam.validate.sourceName" : "أدخل اسم مصدر المعلمة.",
  "oifde.fileTask.EMPTY_COMMAND" : "لا يمكن أن يكون أمر العملية فارغًا.",
  "oifde.operationlog.download" : "تحميل",
  "oifde.operationlog.kick_account" : "مسح الجلسات",
  "oifde.chatBotTest.deletePrompt" : "ستؤدي هذه العملية إلى حذف مجموعة حالات الاختبار نهائيًا. هل أنت متأكد من رغبتك في المتابعة؟",
  "oifde.knowledgegraph.editSchema.relation" : "العلاقة",
  "oifde.dashBoard.knowledge.topTenChatBot" : "أعلى 10 رموز وصول Chatbot",
  "oifde.trackmgr.accessCodeTrack" : "تتبع رمز الوصول",
  "oifde.rule.message.confirm_name_list" : "هل تريد بالتأكيد حذف قائمة الحظر/قوائم الثقة؟",
  "oifde.mxgraph.direction" : "الاتجاه",
  "oifde.serviceInterface.out_param" : "معلمة الإخراج",
  "oifde.accesscode.setTrace" : "إعدادات التتبع",
  "oifde.dashBoard.knowledge.topTenknowledge" : "أعلى 10 كيانات المعرفة المفيدة",
  "oifde.logparam.sourceDetail.InterfaceResponseInformation" : "استجابة الواجهة",
  "oifde.template.tipForVideo" : "أدخل المسار المطلق إلى ملف الفيديو.",
  "oifde.flow.exportFlowList.export" : "تصدير",
  "oifde.serviceInterface.modifyServiceInterface" : "تحرير تكوين واجهة الأعمال",
  "oifde.knowledgegraph.dicmgr.trainFail" : "فشل التدريب.",
  "oifde.fileTask.UNKNOWN_COMMAND" : "أمر العملية غير معروف.",
  "oifde.variable.customTitle" : "متغير مخصص",
  "oifde.serviceInterface.dataTypes.INT" : "عدد صحيح",
  "oifde.license.queryHistoryLicense" : "الاستعلام عن التوافق التاريخي",
  "oifde.operationlog.role" : "الدور",
  "oifde.sysMgr.parameterSetting" : "إعدادات معلمة المحرك الذكي",
  "oifde.mxgraph.openInThisWindow" : "فتح في هذه النافذة",
  "oifde.testMgr.note_test" : "لم تختبر",
  "oifde.diagram.error.NMS_VariableNameError" : "يمكن أن يحتوي اسم المتغير على 1000 حرف كحد أقصى.",
  "oifde.operationlog.insert" : "إدراج",
  "oifde.qarecord.inputparam.questionTitle" : "عنوان السؤال",
  "oifde.importFile.knowledge_graph" : "الكتابة فوق الأسماء المكررة",
  "oifde.qarecord.inputparam.answerIntent" : "نية الإجابة",
  "oifde.flow.setting.logSwitchOff" : "تعطيل",
  "oifde.mxgraph.NMS_rootError": "فشل تحليل التدفق. لم يتم العثور على عقدة الجذر.",
  "oifde.route.versionComparisonAndAnalysis": "مقارنة الإصدار والتحليل",
  "oifde.flow.actions.edit": "تحرير",
  "oifde.logparam.actions.addLogParamBaseInfo": "إضافة معلمة ديناميكية",
  "oifde.flow.importFlow.prompt": "يجب أن يكون الملف ملفًا مضغوطًا.",
  "oifde.maintenance.level": "المستوى",
  "oifde.navbar.changePwd": "تغيير كلمة المرور",
  "oifde.mxgraph.fillColor": "ملء اللون",
  "oifde.sysMgr.save": "حفظ",
  "oifde.license.PERMANENT": "الدائم",
  "oifde.operationlog.performance_data": "بيانات الأداء",
  "oifde.diagram.error.NMS_actionParamValueEmptyError": "لا يمكن أن تكون قيمة المعلمة فارغة.",
  "oifde.route.accessCodeManage": "رمز الوصول",
  "oifde.mxgraph.NMS_flow": "تدفق",
  "oifde.mxgraph.formatXml": "XML",
  "oifde.trace.ivrRequest": "طلب IVR",
  "oifde.common.actions.edit": "تحرير",
  "oifde.ivrJourney.indicatorDesc": "وصف المؤشر",
  "oifde.variable.langDesc": "استخدم هذا المتغير لتعيين لغة المستأجر.",
  "oifde.audioVideoResource.viewReferences": "عرض المراجع",
  "oifde.chatBotTest.doTest": "تنفيذ",
  "oifde.logparam.sourceDetail.TOCRequestInformation": "طلب TOC",
  "oifde.mxgraph.transparent": "شفاف",
  "oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_TONE.TRUE": "نعم",
  "oifde.variable.variableTypeDetail.list": "قائمة",
  "oifde.mxgraph.openSupported": "يتم دعم ملفات XML المصدرة من هذا النظام فقط.",
  "oifde.recordHistory.respSource": "مصدر الرد",
  "oifde.asrMgr.tenantSapce": "مساحة المستأجر",
  "oifde.trace.reset": "إعادة الضبط",
  "oifde.diagram.NMS_NodeCode": "رمز العقدة",
  "oifde.testMgr.domain": "المجال",
  "oifde.template.responseStyleMgr": "إدارة النمط",
  "oifde.expression.expr_number": "أدخل قيمة من النوع الرقمي. المشغلات المتاحة بين القيم هي +, - و *, و /.",
  "oifde.operationlog.cluster_task": "مهمة التجميع",
  "oifde.fileTask.SERVER_BUSY": "النظام مشغول حاليًا.",
  "oifde.mxgraph.NMS_branchError": "يجب أن يحتوي الفرع على عقدة البدء وعقدة النهاية.",
  "oifde.ivrJourney.pleaseSelectDateRange": "يرجى تحديد نطاق زمني.",
  "oifde.mxgraph.moveSelectionTo": "نقل التحديد إلى {0}",
  "oifde.mxgraph.failInfo": "فشل الاستعلام عن البيانات.",
  "oifde.mxgraph.drawing": "رسم{0}",
  "oifde.mxgraph.default": "افتراضي",
  "oifde.logparam.actions.transDetail" : "تكوين معلومات تحويل المعلمة",
  "oifde.flow.exportFlowList.variable" : "متغير",
  "oifde.knowledgegraph.relationmgr.headEntity" : "الكيان الرئيسي",
  "oifde.serviceInterface.description" : "الوصف",
  "oifde.testMgr.overwrite_import" : "الكتابة فوق جميع الإعدادات نفسها",
  "oifde.chatbot.errorCode.100010000005" : "معلمة الإدخال غير صالحة.",
  "oifde.recordHistory.callStatus" : "مراقبة المكالمات الواردة",
  "oifde.serverMgr.addServer" : "إضافة ملقم",
  "oifde.chatbot.errorCode.100010000003" : "يجب أن يكون الاسم فريدًا.",
  "oifde.rule.actions.success" : "النجاح",
  "oifde.chatbot.errorCode.100010000002" : "التنسيق غير صحيح.",
  "oifde.chatbot.errorCode.100010000001" : "خطأ في قاعدة البيانات.",
  "oifde.chatbot.errorCode.100010000000" : "خطأ في النظام.",
  "oifde.flow.rules.ruleId" : "حدد قاعدة.",
  "oifde.mxgraph.align" : "محاذاة",
  "oifde.common.lang" : "اللغة",
  "oifde.route.viewDiagram" : "عرض التدفق",
  "oifde.common.validation.digits" : "يجب أن تكون القيمة 0 أو عددًا صحيحًا موجبًا.",
  "oifde.flow.enums.tabsValue.depend" : "تبعيات التصدير",
  "oifde.variable.defaultValue" : "القيمة الافتراضية",
  "oifde.sysMgr.rpaSecretKeyLenCheck" : "لا يمكن أن يحتوي مفتاح RPA السري على أكثر من 128 حرفًا.",
  "oifde.knowledgegraph.editSchema.dataType.date" : "التاريخ",
  "oifde.mxgraph.allChangesLost" : "سيتم فقدان جميع التغييرات.",
  "oifde.rule.actions.cancel" : "لا",
  "oifde.diagram.fastByVar" : "البحث عن متغير",
  "oifde.knowledgegraph.kgSynonyms.keyword" : "الاسم",
  "oifde.template.sysErrorTemplateTitle" : "قالب مورد غير طبيعي",
  "oifde.mxgraph.NMS_NodeName" : "اسم العقدة",
  "oifde.flow.usage.type" : "النوع",
  "oifde.template.tipContent" : "أدخل محتوى القالب.",
  "oifde.sysMgr.encryptSecretComfirm" : "تأكيد المفتاح",
  "oifde.mxgraph.noFiles" : "لا ملفات.",
  "oifde.template.name" : "الاسم",
  "oifde.mxgraph.table" : "جدول",
  "oifde.toc.statusCode.INCORRECT_ACCOUNT_INPUT" : "يمكن أن يحتوي الحساب على 6 إلى 32 حرفًا تتكون من حروف وأرقام وأحرف خاصة التالية:. {\'@\'}",
  "oifde.testMgr.noSelection" : "لا يمكن إنشاء حالات الاختبار بسبب عدم تحديد أي مجال أو مجموعة أسئلة شائعة.",
  "oifde.navbar.password.newPassword" : "كلمة مرور جديدة",
  "oifde.ivrJourney.repeatIncomingCalls" : "تكرار المكالمات الواردة",
  "oifde.rule.message.save_user_ids_success" : "تم حفظ أرقام المشتركين بنجاح.",
  "oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.MIN_DF.NAME" : "دقيقة. كلمة الوزن عتبة",
  "oifde.rule.message.add_name_list" : "إضافة قائمة حظر/قائمة استئمانية",
  "oifde.sysMgr.dataClean.flowMaxElementsDesc" : "الحد الأقصى لعناصر الرسم البياني المدعومة بتدفق IVR.",
  "oifde.tip.inputFlowName" : "أدخل اسم تدفق",
  "oifde.rule.rules.beginNumValidator" : "يمكن أن يحتوي رقم البدء على أحرف وأرقام ومسافات والأحرف الخاصة التالية فقط.",
  "oifde.mxgraph.organic" : "العضوية",
  "oifde.ivrJourney.returnKeysByErrors" : "إرجاع خطأ المفتاح",
  "oifde.diagram.error.NMS_flowNameError" : "لا يمكن أن يكون اسم التدفق فارغًا أو يتجاوز 1000 حرف.",
  "oifde.ivrJourney.personTime" : "عدد المرات",
  "oifde.mxgraph.entityRelation" : "علاقة الكيان",
  "oifde.common.dialog.confirm.operation_failed" : "فشل تنفيذ العملية.",
  "oifde.mxgraph.errorSavingFile" : "فشل حفظ الملف.",
  "oifde.template.validate.intentDsp" : "أدخل وصف قالب.",
  "oifde.mxgraph.arrows" : "السهام",
  "oifde.rule.message.add_user_ids" : "إضافة رقم مشترك",
  "oifde.serverMgr.confirmDelete" : "هل تريد بالتأكيد حذف البيانات؟",
  "oifde.route.keyPressRobot" : "مفتاح Chatbot",
  "oifde.operationlog.variable_manage" : "إدارة المتغيرات",
  "oifde.table.save" : "حفظ",
  "oifde.knowledgegraph.extendFaq.import" : "استيراد",
  "oifde.errorCode.100051005025" : "لا يمكن أن يتجاوز العدد الإجمالي 5000.",
  "oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.TSF_ENABLE.NAME" : "خوارزمية TSF",
  "oifde.whitelist.ipRange" : "يمكن أن يحتوي عنوان IP على أحرف وأرقام ونقاط (.), تسطير أسفل (_), والواصلات (-) فقط. لا يمكن أن تبدأ أو تنتهي مع الواصلة.",
  "oifde.serviceInterface.invokingTime" : "استدعاء الوقت",
  "oifde.ivrJourney.IVRSrvCustomers" : "عدد عملاء IVR الخالصين",
  "oifde.procedure.actions.cancelDelete" : "تم إلغاء الحذف.",
  "oifde.serviceInterface.actions.urlNotInWhitelist" : "عنوان URL (عنوان IP ورقم المنفذ) غير موجود في قائمة الثقة ويمكن استخدامه فقط بعد الاتصال بمسؤول النظام لإضافته إلى قائمة الثقة. هل تريد المتابعة؟",
  "oifde.route.unusedResource.template" : "عرض القوالب غير المستخدمة",
  "oifde.ivrJourney.repeatOnceCalls" : "أسمع مرة واحدة",
  "oifde.mxgraph.global" : "العالمية",
  "oifde.mxgraph.enterValue" : "أدخل قيمة",
  "oifde.toc.statusCode.INCORRECT_PASSWORD_UPDATE" : "لا يمكن أن تحتوي كلمة المرور على نفس الحرف المكرر أكثر من 3 مرات متتالية. بالإضافة إلى ذلك، لا يمكن إعادة استخدام آخر 3 كلمات مرور تاريخية. يمكن أن تحتوي كلمة المرور على 8 إلى 20 حرفًا تتكون من أرقام وأحرف صغيرة وأحرف كبيرة وcharacters: ~ `! {\'@\'} # {\'$\'} % ^ * ( ) - + الخاص التالي {\'|\'},",
  "oifde.errorCode.100051005015" : "يمكن أن يحتوي الملف على 5000 سطر كحد أقصى.",
  "oifde.operationlog.complexcellcatalog" : "كتالوج عناصر المخطط المركب",
  "oifde.serviceInterface.miss_ifs_msgBody" : "الحقل الإلزامي التالي فارغ: نص الرسالة.",
  "oifde.errorCode.100051005011" : "فشلت إضافة رقم الخدمة.",
  "oifde.operationlog.disable" : "إلغاء التنشيط",
  "oifde.knowledgegraph.relationmgr.doublePrompt" : "لا يمكن تأسيس العلاقة لأن كيانات الرأس والذيل هي نفسها. حدد كيان رأس أو ذيل آخر.",
  "oifde.testMgr.failed_delete_case" : "فشل حذف حالة الاختبار.",
  "oifde.qiAsrServerMgr.asrVendor" : "بائع",
  "oifde.knowledgegraph.dicmgr.kgFAQ" : "الأسئلة الشائعة حول الرسم البياني",
  "oifde.trace.callId" : "معرف مثيل المكالمة",
  "oifde.accesscode.callingNumberTrace" : "تتبع الرقم المتصل:",
  "oifde.trackmgr.actions.cancelDelete" : "تم إلغاء الحذف.",
  "oifde.mxgraph.bottomAlign" : "محاذاة أسفل",
  "oifde.tenantinfo.validate.serviceValueLength" : "يمكن أن تحتوي القيمة على 64 حرفًا كحد أقصى.",
  "oifde.importFile.white_list" : "الكتابة فوق كافة الإعدادات الموجودة",
  "oifde.sysConfig.intent_params" : "معلمات النية",
  "oifde.testMgr.import_test_cases" : "استيراد حالة الاختبار",
  "oifde.serviceInterface.deleteConfirm" : "ستؤدي هذه العملية إلى حذف واجهة العمل نهائيًا. هل أنت متأكد من رغبتك في المتابعة؟",
  "oifde.sysConfig.INTENT_DECISION_MAKER.NAME" : "وحدة صنع القرار النية",
  "oifde.errorCode.100051005006" : "يمكن أن يحتوي رقم الخدمة على 64 حرفًا كحد أقصى.",
  "oifde.errorCode.100051005005" : "فشل استيراد الملف لأنه كبير جدًا.",
  "oifde.dashBoard.chartView.rightTile" : "معدل نجاح الرد",
  "oifde.errorCode.100051005004" : "فشل استيراد الملف لأن النظام لا يستطيع قراءة الملف.",
  "oifde.errorCode.100051005003" : "فشل استيراد الملف لأنه لا يمكن العثور عليه.",
  "oifde.errorCode.100051005002" : "فشل حذف قائمة الحظر أو قائمة الثقة.",
  "oifde.ivrJourney.indicatorComparisonStatistics" : "إحصائيات مقارنة العداد",
  "oifde.mxgraph.NMS_ScenarioType" : "نوع السيناريو",
  "oifde.errorCode.100051005001" : "فشل حفظ قائمة الحظر وقائمة الثقة.",
  "oifde.toc.statusCode.SESSION_EXPIRED" : "تسجيل الدخول الحالي غير صالح. تسجيل الدخول مرة أخرى.",
  "oifde.template.defaultValueValidate.ttsPitchValueDesc" : "نطاق القيمة : [-500,500]",
  "oifde.qiAsrServerMgr.portTips" : "إذا كان عنوان خادم ASR هو اسم مجال، يكون رقم المنفذ اختياريًا.",
  "oifde.ivrJourney.directRate" : "المعدل المباشر",
  "oifde.mxgraph.underline" : "خط تحتي",
  "oifde.flow.importFlow.message" : "حدد الملف الذي تريد تحميله.",
  "oifde.ivrJourney.defaultBranch" : "الفرع الافتراضي",
  "oifde.common.actions.download" : "تحميل",
  "oifde.license.import" : "التحميل/التحديث",
  "oifde.template.actions.fail" : "فشل",
  "oifde.operationlog.login" : "تسجيل الدخول",
  "oifde.sysMgr.apiNoSame" : "لا يمكن أن تكون كلمة المرور مماثلة لمعرف مساحة المستأجر.",
  "oifde.mxgraph.connect" : "الاتصال",
  "oifde.accesscode.validate.accessCodeLength" : "يمكن أن يحتوي رمز الوصول على 20 حرفًا كحد أقصى.",
  "oifde.qiAsrServerMgr.asrServerPWConform" : "تأكيد كلمة مرور خادم ASR",
  "oifde.operationlog.tps_interface" : "واجهة الخدمة",
  "oifde.audioVideoResource.updateBefore" : "قبل التحديث",
  "oifde.fileTask.FILE_NOT_EXIST" : "الملف غير موجود.",
  "oifde.knowledgegraph.model.schemaCode" : "رمز المخطط",
  "oifde.complexcell.cellTitle" : "عنصر مخطط مركب",
  "oifde.license.import_template_need_dat" : "يجب أن يكون الملف المراد تحميله بتنسيق in.dat.",
  "oifde.mxgraph.actualSize" : "الحجم الفعلي",
  "oifde.route.editDiagram" : "تحرير التدفق",
  "oifde.rule.manage" : "إدارة",
  "oifde.procedure.db.dbUser" : "مستخدم قاعدة البيانات",
  "oifde.mxgraph.position" : "الموضع",
  "oifde.sysMgr.dataClean.editFlowSaveFlag" : "تحرير علامة الحفظ المجدولة لتنسيق تدفق IVR",
  "oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_PINYIN.TRUE" : "نعم",
  "oifde.route.tuc.template.entity" : "إدارة الكيان",
  "oifde.rule.rules.endNumValidator" : "يمكن أن يحتوي رقم النهاية على أحرف وأرقام ومسافات والأحرف الخاصة التالية فقط.",
  "oifde.trackmgr.actions.delete" : "حذف",
  "oifde.knowledgegraph.extendFaq.inUse" : "قيد الاستخدام",
  "oifde.mxgraph.insertLink" : "إدراج رابط",
  "oifde.template.intentionInfo.intentList" : "قائمة",
  "oifde.sysMgr.dataClean.close" : "تعطيل",
  "oifde.ivrJourney.journeyNum" : "الزيارات",
  "oifde.mxgraph.advanced" : "متقدم",
  "oifde.ivrJourney.node" : "العقدة",
  "oifde.navbar.screenfull" : "ملء الشاشة",
  "oifde.sysMgr.requiredRpaSecretKey" : "مفتاح RPA السري مطلوب.",
  "oifde.operationlog.command" : "الأمر",
  "oifde.template.actions.updateSuccess" : "تم تعديل القالب بنجاح.",
  "oifde.knowledgegraph.actions.createFailed" : "فشلت الإضافة.",
  "oifde.operationlog.intent_parameters" : "معلمة النية",
  "oifde.complexcell.catalog.tips.sizeLimit" : "يسمح بحد أقصى 100 كتالوج.",
  "oifde.ivrJourney.alarmContent" : "محتوى التحذير المسبق",
  "oifde.ivrJourney.transToManualCallsSucc" : "تم التحويل إلى يدوي ناجح",
  "oifde.template.validate.templateDescLength" : "لا يمكن أن يحتوي وصف القالب على أكثر من 1024 حرفًا.",
  "oifde.operationlog.set_test" : "تعيين إلى اختبار",
  "oifde.mxgraph.NMS_FlowOpenFail" : "فشل فتح التدفق.",
  "oifde.diagram.NMS_FlowVariableDesc" : "الوصف",
  "oifde.robotReply.replyResource" : "رد الموارد",
  "oifde.template.actions.append" : "إضافة",
  "oifde.ivrJourney.other" : "أخرى",
  "oifde.common.actions.delete" : "حذف",
  "oifde.mxgraph.NMS_DiagramCode" : "رمز المعالجة",
  "oifde.serviceInterface.miss_ifs_request_url" : "الحقل الإلزامي التالي فارغ: معلومات أساسية > طلب URL.",
  "oifde.mxgraph.editDiagram" : "المصدر",
  "oifde.route.knowledgegraph.kgSynonyms" : "مرادف لـ {0}",
  "oifde.qiAsrServerMgr.asrAppKey" : "اسم المستخدم/AK",
  "oifde.asrMgr.addAsr" : "إضافة TTS/ASR",
  "oifde.serverMgr.serverType" : "نوع الخادم",
  "oifde.diagram.error.NMS_checkDefaultNextNodeForView" : "لا يمكن أن يحتوي عنصر المخطط الخاص بنوع العرض على أكثر من فرع واحد.",
  "oifde.fileTask.INVALID_TASK_ARGS" : "معلمة المهمة مفقودة.",
  "oifde.rule.actions.viewBoundFlow" : "عرض العمليات المرتبطة",
  "oifde.ivrJourney.efficiencyOverview" : "الكفاءة نظرة عامة",
  "oifde.mxgraph.NMS_ContentTemplateGetFail" : "فشل الحصول على قائمة قوالب المحتوى.",
  "oifde.procedure.oracleRuleRange" : "يمكن أن يحتوي عنوان URL على أحرف وأرقام فقط و characters:.? {\'@\'} الخاص التالي = : - يمكن أن ينتهي بحرف أو رقم أو شرطة مائلة (/).",
  "oifde.toc.statusCode.SESSION_DUPLICATED" : "لقد قمت بتسجيل الدخول بالفعل. لتسجيل الدخول مرة أخرى، يجب عليك أولاً تسجيل الخروج.",
  "oifde.dashBoard.service.responseSuccessCount" : "الردود الناجحة",
  "oifde.maintenance.startLog" : "تمكين السجل",
  "oifde.common.validation.positiveInt" : "يجب أن تكون القيمة عددًا صحيحًا موجبًا.",
  "oifde.route.tuc.history.dialog" : "مراجعة الرسائل التاريخية",
  "oifde.serviceInterface.callId" : "معرف المكالمة",
  "oifde.template.actions.saveSuccess" : "تم حفظ القالب بنجاح.",
  "oifde.accesscode.isCheckHelp" : "يتم تصحيح الأخطاء الإملائية في كشوفات العميل تلقائيًا قبل التعرف على النية.",
  "oifde.ivrJourney.sendSuccess" : "تم الإرسال بنجاح",
  "oifde.accesscode.description" : "معرف رمز الوصول",
  "oifde.mxgraph.gridSize" : "حجم الشبكة",
  "oifde.knowledgegraph.relationmgr.deletePrompt" : "ستؤدي هذه العملية إلى حذف العلاقة والسمات المرتبطة بها بشكل دائم. هل أنت متأكد من رغبتك في المتابعة؟",
  "oifde.ivrJourney.complexityTrend" : "توزيع تريند التعقيد",
  "oifde.diagram.error.NMS_VariableDescError" : "يمكن أن يحتوي وصف المتغير على 1000 حرف كحد أقصى.",
  "oifde.recordHistory.respTime" : "تم الرد",
  "oifde.procedure.procedureValue" : "القيمة",
  "oifde.mxgraph.NMS_nmsFlowError" : "فشل تحليل التدفق. لم يتم العثور على عقدة nmsFlow.",
  "oifde.testMgr.test_time" : "تم اختبارها",
  "oifde.flow.updateTime" : "تم التعديل",
  "oifde.ivrJourney.high" : "عالية",
  "oifde.ivrJourney.oneMonth" : "شهر واحد",
  "oifde.knowledgegraph.extendFaq.updateFailed" : "فشل تغيير الحالة.",
  "oifde.flow.actions.staticcheck" : "فحص ثابت",
  "oifde.mxgraph.size" : "الحجم",
  "oifde.serviceInterface.innerInterface" : "واجهة داخلية",
  "oifde.sysConfig.INTENT_DECISION_MAKER.BOTTOM.DESC" : "تعتبر النية غير معروفة إذا كانت أقل من الحد الأدنى لصانع القرار النية.",
  "oifde.sysConfig.DUMMY_VECTOR_CLASSIFIER.MIU.NAME" : "μ",
  "oifde.mxgraph.replace" : "استبدال",
  "oifde.errorCode.100070000001" : "فشل استيراد البيانات لأنها نفس مورد موجود.",
  "oifde.flow.rules.logSwitchOn" : "حدد ما إذا كنت تريد تمكين وظيفة السجل.",
  "oifde.ivrJourney.threeDay" : "3 أيام",
  "oifde.route.unusedResource.procedure" : "عرض الإجراءات المخزنة غير المستخدمة",
  "oifde.logparam.sourceDetail.IVRRequestInformation" : "طلب IVR",
  "oifde.mxgraph.NMS_ValidateError" : "فشل التحقق! تحقق من الأجزاء المميزة باللون الأحمر.",
  "oifde.mxgraph.link" : "رابط",
  "oifde.variable.variableTypeDetail.long" : "عدد صحيح طويل",
  "oifde.trackmgr.actions.cancelUpdate" : "تم إلغاء التعديل.",
  "oifde.mxgraph.zoomIn" : "تكبير/تصغير",
  "oifde.mxgraph.line" : "خط",
  "oifde.sysMgr.cancel" : "إلغاء",
  "oifde.mxgraph.formattedText" : "نص منسق",
  "oifde.flow.actions.errorCode" : "رمز الخطأ",
  "oifde.template.template" : "إدارة قالب الموارد",
  "oifde.rule.select" : "تحديد",
  "oifde.operationlog.white_list_users" : "رقم المشترك في قاعدة المعلومات الرمادية",
  "oifde.knowledgegraph.errorCode.100061000006" : "فشل الاتصال بـ ODFS.",
  "oifde.knowledgegraph.errorCode.100061000005" : "تم ربط الرسم البياني للمعرفة بنجاح.",
  "oifde.mxgraph.layout" : "تخطيط",
  "oifde.testMgr.allFaqGroup" : "تحديد كافة مجموعات الأسئلة الشائعة",
  "oifde.toc.statusCode.INCORRECT_TELEPHONE" : "رقم الهاتف غير صحيح.",
  "oifde.toc.statusCode.HTTPS_REQUIRED" : "يتم دعم HTTPS فقط.",
  "oifde.template.validate.templateType" : "حدد نوع قالب.",
  "oifde.mxgraph.selectFont" : "تحديد الخط",
  "oifde.diagram.aimCell" : "عنصر المخطط الهدف",
  "oifde.operationlog.password_rule" : "قاعدة كلمة المرور",
  "oifde.operationlog.targetObjName" : "كائن",
  "oifde.serverMgr.editServer" : "تعديل الملقم",
  "oifde.qiAsrServerMgr.sftp" : "SFTP",
  "oifde.rate.dateSelect" : "حدد تاريخًا.",
  "oifde.mxgraph.removeWaypoint" : "إزالة Waypoint",
  "oifde.route.alarmEvent" : "حدث التنبيه",
  "oifde.mxgraph.circle" : "دائرة",
  "oifde.route.knowledgegraph.kgView" : "التصور الرسم البياني لـ {0}",
  "oifde.variable.actions.cancelDelete" : "تم إلغاء الحذف.",
  "oifde.knowledgegraph.actions.deleteFailed" : "فشل الحذف.",
  "oifde.ivrJourney.IVRTransManualRate" : "نسبة حركة المرور اليدوية (%)",
  "oifde.ivrJourney.extendedMetrics" : "المقاييس الموسعة",
  "oifde.knowledgegraph.kgSynonyms.type.property" : "السمة",
  "oifde.mxgraph.exitGroup" : "خروج المجموعة",
  "oifde.audioVideoResource.cancelUpdate.fail" : "فشل إلغاء التحديث",
  "oifde.operationlog.tenant_space" : "مساحة المستأجر",
  "oifde.dashBoard.pie.talk" : "دردشة",
  "oifde.knowledgegraph.actions.modifyFailed" : "فشل التعديل.",
  "oifde.operationlog.deactivate" : "إيقاف مؤقت",
  "oifde.mxgraph.alignment" : "محاذاة",
  "oifde.operationlog.detail" : "التفاصيل",
  "oifde.testMgr.test_case_details" : "التفاصيل",
  "oifde.common.validation.kgNameCharReg" : "يُسمح فقط بالأحرف الصينية والحروف الإنجليزية والمسافات والرموز الخاصة التالية: -, \' ;. {\'@\'}",
  "oifde.operationlog.flow_trace_log" : "تكوين تتبع اختبار العملية",
  "oifde.diagram.variableTypeDetail.float" : "رقم النقطة العائمة",
  "oifde.chatBotTest.downAllReport" : "تنزيل جميع التقارير",
  "oifde.procedure.actions.deleteSuccess" : "تم الحذف بنجاح.",
  "oifde.sysConfig.restore_defaults" : "استعادة القيم الافتراضية",
  "oifde.procedure.modifyProcedureInterface" : "تعديل الإجراء المخزن",
  "oifde.diagram.usedCell" : "عنصر الرسم البياني",
  "oifde.ivrJourney.transToManualCalls" : "عدد المكالمات المحولة إلى المكالمات اليدوية",
  "oifde.mxgraph.rotation" : "التناوب",
  "oifde.route.edit" : "تحرير {0}",
  "oifde.qiAsrServerMgr.passwordError" : "كلمات المرور التي تم إدخالها مرتين غير متناسقة.",
  "oifde.diagram.add" : "إضافة",
  "oifde.procedure.actions.miss_pro_name" : "الحقل الإلزامي التالي فارغ: معلومات أساسية > اسم الإجراء المخزن.",
  "oifde.mxgraph.noResultsFor" : "لا توجد نتائج لـ \'{0}\'",
  "oifde.diagram.error.NMS_flowDescError" : "يمكن أن يحتوي وصف التدفق على 1000 حرف كحد أقصى.",
  "oifde.template.editResponseStyle" : "تحرير النمط",
  "oifde.template.detail" : "تفاصيل المحتوى",
  "oifde.knowledgegraph.extendFaq.status" : "الحالة",
  "oifde.serviceInterface.baseInfo" : "معلومات أساسية",
  "oifde.chatBotTest.description" : "الوصف",
  "oifde.flow.importFlow.cover" : "السماح بالأسماء المكررة",
  "oifde.variable.validate.variableNameLength" : "يحتوي اسم المتغير على أكثر من 1000 حرف.",
  "oifde.diagram.NMS_PleaseSelect" : "-اختر-",
  "oifde.flow.exportFlowList.accessCode" : "رمز الوصول",
  "oifde.dashBoard.knowledgeDashBoard" : "لوحة معلومات المعرفة",
  "oifde.mxgraph.rotate" : "تدوير",
  "oifde.template.setDefault" : "تعيين كافتراضي",
  "oifde.mxgraph.NMS_KeyRepeats" : "اسم شرط مكرر.",
  "oifde.flow.setting.logSwitch" : "وظيفة السجل",
  "oifde.mxgraph.error" : "خطأ",
  "oifde.route.knowledgegraph.entitymgr" : "إدارة الكيان لـ {0}",
  "oifde.mxgraph.curved" : "منحني",
  "oifde.testMgr.auto_testing_template" : "تنزيل قالب",
  "oifde.flow.rules.workMode" : "حدد وضع عمل.",
  "oifde.trace.traceLog" : "استعلام تتبع السجل",
  "oifde.mxgraph.insertRowAfter" : "إدراج صف أدناه",
  "oifde.mxgraph.connection" : "اتصال",
  "oifde.template.tipForVoice" : "أدخل المسار المطلق إلى ملف الصوت.",
  "oifde.sysConfig.failed_apply_intent_param" : "فشل تعديل معلمة القصد.",
  "oifde.dashBoard.knowledge.intent" : "النية",
  "oifde.chatBotTest.callingNum" : "الرقم المتصل",
  "oifde.template.voicename.cn_chengshuxing_common" : "ناضجة",
  "oifde.mxgraph.linestart" : "بداية الخط",
  "oifde.template.language.english" : "الإنجليزية",
  "oifde.operationlog.call_session" : "مربع حوار الاتصال",
  "oifde.mxgraph.invalidOrMissingFile" : "الملف مفقود أو غير صالح.",
  "oifde.template.selectLang" : "اختر لغة.",
  "oifde.variable.variableName" : "اسم المتغير",
  "oifde.knowledgegraph.actions.updateSuccess" : "نجح التعديل.",
  "oifde.mxgraph.autosave" : "الحفظ التلقائي",
  "oifde.operationlog.file" : "ملف",
  "oifde.mxgraph.NMS_ParamName" : "المعلمة",
  "oifde.maintenance.startTime" : "الوقت الفعال",
  "oifde.mxgraph.help" : "مساعدة",
  "oifde.mxgraph.decreaseIndent" : "تقليل المسافة البادئة",
  "oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.WORD_FREQ.MULTI" : "كل حدث",
  "oifde.knowledgegraph.actions.prompt" : "معلومات",
  "oifde.template.actions.cancelDelete" : "تم إلغاء الحذف.",
  "oifde.flow.flowTypeSelect" : "تحديد قالب تدفق جديد",
  "oifde.serviceInterface.ccivrInterface" : "زر واجهة روبوت",
  "oifde.template.addNewIntent" : "إضافة نية",
  "oifde.flow.importFlow.name" : "الاسم",
  "oifde.knowledgegraph.extendFaq.updateTemplateSuccess" : "تم تعديل قالب الأسئلة الشائعة بنجاح.",
  "oifde.operationlog.knowledge_graph_synonym" : "مرادف الرسم البياني المعرفة",
  "oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.WORD_FREQ.ONCE": "حدث واحد فقط",
  "oifde.dashBoard.service.feedbackCount": "مجموع الملاحظات",
  "oifde.rate.averageInteractPerCall": "متوسط جولات الحوار",
  "oifde.tenantinfo.serviceValue": "القيمة",
  "oifde.operationlog.flume_log_cfg": "تكوين سجل Flume",
  "oifde.mxgraph.textAlignment": "محاذاة النص",
  "oifde.knowledgegraph.actions.graphicPreview": "عرض الرسم البياني",
  "oifde.knowledgegraph.extendFaq.export": "تصدير الكل",
  "oifde.diagram.error.NMS_analiseXmlFailed": "فشل تحليل اللوحة القماشية للتدفق.",
  "oifde.testMgr.abortFailed": "فشل المقاطعة.",
  "oifde.expression.expr_var_fag": "أدخل متغيرًا. ابدأ القيمة بـ GLOBAL (للمتغير العام) ، FLOW (للمتغير التدفق).",
  "oifde.mxgraph.right": "يمين",
  "oifde.mxgraph.NMS_IntentCodeLoadFail": "فشل تحميل رمز الغرض.",
  "oifde.mxgraph.blockquote": "بلوككوت",
  "oifde.ivrJourney.pleaseSelectCompareRange": "يرجى تحديد نطاق المقارنة",
  "oifde.whitelist.whiteIpLen": "يمكن أن يحتوي عنوان IP لقائمة الثقة أو اسم المجال على 128 حرفًا كحد أقصى.",
  "oifde.knowledgegraph.editSchema.objectName": "المفهوم/العلاقة",
  "oifde.tagsView.closeAll": "إغلاق الكل",
  "oifde.flow.rules.newVersionLength": "لا يمكن أن يحتوي الإصدار الجديد على أكثر من 64 حرفًا.",
  "oifde.license.success_set_license": "تم تكوين الترخيص بنجاح.",
  "oifde.testCase.callDetail": "التفاصيل",
  "oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.TSF_ENABLE.TRUE": "تمكين",
  "oifde.testMgr.chatBotTestCaseMgr": "اختبار الحوار",
  "oifde.toc.statusCode.INVALID_ACCOUNT": "فشلت المصادقة. تسجيل الدخول مرة أخرى.",
  "oifde.mxgraph.gradientColor": "اللون",
  "oifde.mxgraph.expand": "توسيع",
  "oifde.accesscode.sendprompt": "اضغط على Enter لإرسال رسالتك.",
  "oifde.mxgraph.leftAlign": "محاذاة اليسار",
  "oifde.fileTask.SERVICE_UNAVAILABLE": "الخدمة غير متوفرة حاليًا.",
  "oifde.rule.message.user_ids_number_once": "يمكن إضافة 100 رقم مشترك كحد أقصى إلى قائمة الحظر/قائمة الثقة في دفعة واحدة.",
  "oifde.accesscode.dialogTypeDetail.ivrProcess": "تدفق IVR",
  "oifde.ivrJourney.wrongKeyCalls": "عدد المفاتيح التي تم إرجاعها بسبب أخطاء المفاتيح",
  "oifde.diagram.NMS_FlowVariableDefaultValue": "القيمة الافتراضية",
  "oifde.knowledgegraph.dicmgr.entityMgr": "إدارة الكيان",
  "oifde.expression.expr_var": "أدخل متغيرًا. ابدأ القيمة بـ GLOBAL (للمتغير الشامل) أو FLOW (للمتغير التدفق) أو SYS (للمتغير النظام) أو TOC (للمتغير TUC) أو IVRREQUEST (للمتغير طلب IVR).",
  "oifde.dashBoard.knowledge.callCount": "الزيارات",
  "oifde.toc.statusCode.SESSION_OVERFLOW": "وصل عدد المستخدمين عبر الإنترنت إلى الحد الأعلى. حاول مرة أخرى لاحقًا.",
  "oifde.operationlog.voice_content": "ملف صوتي",
  "oifde.mxgraph.extras": "إضافات",
  "oifde.dashBoard.knowledge.faq": "الأسئلة الشائعة",
  "oifde.flow.usage.status.init": "التنفيذ قيد التنفيذ",
  "oifde.testMgr.execute_test" : "تنفيذ",
  "oifde.serviceInterface.bizCode" : "كود الأعمال",
  "oifde.chatBotTest.status" : "الحالة",
  "oifde.knowledgegraph.model.modifyTime" : "تم التعديل",
  "oifde.diagram.NMS_ServiceDesciption" : "وصف الخدمة",
  "oifde.operationlog.logging_module" : "الوحدة النمطية",
  "oifde.accesscode.actions.updateSuccess" : "نجح التعديل.",
  "oifde.template.actions.deleteSuccess" : "تم حذف القالب بنجاح.",
  "oifde.mxgraph.confirm" : "حسناً",
  "oifde.sysMgr.dataClean.descriptionContent" : "الفاصل الزمني الذي يتم فيه تنظيف البيانات.",
  "oifde.template.message.searchResourceName" : "البحث عن أسماء الموارد",
  "oifde.mxgraph.bottom" : "أسفل",
  "oifde.operationlog.trace_log" : "تتبع إعدادات السجل",
  "oifde.trackmgr.callingNumberTrack" : "تعقب رقم الاتصال",
  "oifde.knowledgegraph.editSchema.entity" : "المفهوم",
  "oifde.mxgraph.manual" : "يدوي",
  "oifde.mxgraph.comic" : "كوميك",
  "oifde.rule.rules.beginNumLengthValidator" : "يجب أن يكون رقم النهاية أكبر من رقم البدء أو مساويًا له.",
  "oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.WORD_FREQ.DESC" : "ما إذا كان يجب جمع إحصائيات لكل أو تكرار كلمة واحدة فقط داخل الجملة.",
  "oifde.template.type" : "النوع",
  "oifde.ivrJourney.IVRRate" : "معدل خدمة IVR النقي (%)",
  "oifde.route.flowAnalysis" : "تحليل التدفق",
  "oifde.dashBoard.graph.hitRate" : "معدل ضرب",
  "oifde.rate.hourOption" : "بالساعة",
  "oifde.mxgraph.laneColor" : "لانيكولور",
  "oifde.operationlog.binding_kg" : "Chatbot الرسم البياني",
  "oifde.knowledgegraph.extendFaq.operation" : "العملية",
  "oifde.ivrJourney.label" : "المعرّف",
  "oifde.mxgraph.NMS_flowDescError" : "يمكن أن يحتوي وصف التدفق على 1000 حرف كحد أقصى.",
  "oifde.template.language.spanish" : "الأسبانية",
  "oifde.logparam.actions.cancelDelete" : "تم إلغاء الحذف.",
  "oifde.navbar.password.passwordNotSame" : "كلمتا المرور مختلفتان.",
  "oifde.flow.usage.interfaceUsageTitle" : "استخدام الواجهة",
  "oifde.ivrJourney.nodeVersionComparison" : "عقدة مقارنة الإصدار",
  "oifde.trace.validate.callingNumberLength" : "يمكن أن يحتوي الرقم الطالب على 64 حرفًا كحد أقصى.",
  "oifde.qiAsrServerMgr.name" : "الاسم",
  "oifde.route.tuc.template.faq" : "إدارة الأسئلة الشائعة",
  "oifde.route.knowledgegraph.kgModel" : "مخطط المعرفة",
  "oifde.flow.exportFlowList.template" : "قالب",
  "oifde.mxgraph.NMS_BranchProperties" : "سمة الفرع",
  "oifde.flow.importFlow.fileType" : "يجب أن يكون الملف ملفًا مضغوطًا.",
  "oifde.logparam.sourceDetail.InterfaceRequestInformation" : "طلب الواجهة",
  "oifde.operationlog.gray_rule" : "القاعدة الرمادية",
  "oifde.testMgr.expected_output" : "متوقع الإخراج الكلمة الأساسية أو اسم النية",
  "oifde.diagram.NMS_BasicProperties" : "السمة الأساسية",
  "oifde.serviceInterface.actions.auto_savePrompt" : "ستقوم هذه العملية بحفظ «جسم الرسالة التلقائي» كتكوين واجهة الخدمة. هل أنت متأكد من رغبتك في المتابعة؟",
  "oifde.rule.actions.create" : "إضافة",
  "oifde.flow.enums.status.init" : "مشروع",
  "oifde.mxgraph.bottomRight" : "أسفل اليمين",
  "oifde.ivrJourney.transfManualRate" : "معدل التحويل إلى المعدل اليدوي",
  "oifde.dashBoard.graph.amount" : "الكمية",
  "oifde.knowledgegraph.dicmgr.dicCode" : "رمز الرسم البياني",
  "oifde.complexcell.catalog.desc" : "وصف الكتالوج",
  "oifde.testMgr.fail_save_data" : "فشل حفظ مجموعة حالة الاختبار.",
  "oifde.trace.traceAdvice" : "الحل",
  "oifde.table.confirm" : "تأكيد",
  "oifde.flow.usage.resourceType.rule" : "القاعدة الرمادية",
  "oifde.flow.exportFlowList.promptMessage" : "حدد سجلًا واحدًا على الأقل.",
  "oifde.mxgraph.openLink" : "فتح الرابط",
  "oifde.route.tuc.ops.corpustest" : "اختبار كوربوس",
  "oifde.knowledgegraph.dicmgr.creating" : "إنشاء",
  "oifde.flow.actions.upgrade" : "الترقية",
  "oifde.template.voicename.cn_kefuman_common" : "رشيقة",
  "oifde.rule.message.failed_import_file" : "فشل استيراد الملف.",
  "oifde.rate.monthOption" : "آخر 30 يوم",
  "oifde.mxgraph.connectionArrows" : "أسهم الاتصال",
  "oifde.serviceInterface.headerKey" : "مفتاح",
  "oifde.template.actions.editbaseInfo" : "تحرير قالب مورد",
  "oifde.serviceInterface.expireTime" : "إضافة قالب مورد",
  "oifde.mxgraph.formatJpg" : "JPEG",
  "oifde.diagram.error.NMS_NodeCodeError" : "لا يمكن أن يكون رمز العقدة فارغًا أو يتجاوز 64 حرفًا.",
  "oifde.ivrJourney.absoluteValue" : "القيمة المطلقة",
  "oifde.importFile.test_case_group" : "الكتابة فوق كافة الإعدادات الموجودة",
  "oifde.navbar.logOut" : "تسجيل الخروج",
  "oifde.accesscode.validate.domainIdCheck" : "معرف المجال ليس رقماً صالحاً.",
  "oifde.accesscode.actions.fail" : "فشل",
  "oifde.tenantinfo.actions.editTenantBaseInfo" : "تحرير المعلمة الثابتة",
  "oifde.mxgraph.glass" : "الزجاج",
  "oifde.logparam.logParamMgr" : "إعدادات معلمة سجل العمود",
  "oifde.sysMgr.dataClean.second" : "ثواني",
  "oifde.logparam.validate.sourceValueLength" : "الحد الأقصى للطول64",
  "oifde.accesscode.actions.videofile" : "ملف الفيديو:",
  "oifde.mxgraph.distribute" : "توزيع",
  "oifde.testMgr.case_passed" : "مرت",
  "oifde.recordHistory.silentSeat" : "وكيل صامت",
  "oifde.route.tuc.template.bwlist" : "قائمة الحظر وقائمة الثقة",
  "oifde.expression.expr_number_noAction" : "أدخل قيمة من النوع الرقمي.",
  "oifde.mxgraph.cut" : "قطع",
  "oifde.variable.variableTypeDetail.object" : "كائن",
  "oifde.testMgr.succeed_delete_group" : "تم حذف مجموعة حالة الاختبار بنجاح.",
  "oifde.route.knowledgegraph.editSchema" : "تفاصيل المخطط {0}",
  "oifde.knowledgegraph.relationmgr.doubleRelationFalse" : "لا",
  "oifde.mxgraph.change" : "تغيير",
  "oifde.mxgraph.NMS_PleaseSelect" : "الرجاء الاختيار",
  "oifde.mxgraph.placeholders" : "نائب",
  "oifde.diagram.variableTypeDetail.object" : "كائن",
  "oifde.recordHistory.callNumberLength" : "لا يمكن أن يحتوي رقم المشترك على أكثر من 64 حرفًا.",
  "oifde.audio.speedRange" : "نطاق القيمة: [- 200, 500]",
  "oifde.operationlog.business_interface" : "واجهة الأعمال",
  "oifde.toc.statusCode.UNLOCK_REQUIRED" : "تم قفل الحساب مؤقتًا. قم بإلغاء قفل الحساب وحاول مرة أخرى.",
  "oifde.mxgraph.enterGroup" : "أدخل مجموعة",
  "oifde.variable.timezoneDesc" : "استخدم هذا المتغير لتعيين إزاحة المنطقة الزمنية التي يقيم فيها المستأجر. تتراوح القيمة من -12 إلى +12.",
  "oifde.testCase.addTestCase" : "إضافة حالة اختبار",
  "oifde.variable.encryptFlag.one" : "مشفرة للتخزين وفك تشفيرها عند الاسترجاع",
  "oifde.serverMgr.mrcpId" : "معرف المجموعة",
  "oifde.variable.validate.variableName" : "أدخل اسم متغير.",
  "oifde.mxgraph.drawingEmpty" : "الرسم فارغ.",
  "oifde.dashBoard.service.responseRate" : "معدل نجاح الرد",
  "oifde.route.sysMgr.recordHistory" : "نداء تاريخي",
  "oifde.operationlog.recordHistoryLog" : "سجل الحوار",
  "oifde.accesscode.messageTips" : "يرجى تخصيص بعض الوقت لتقديم ملاحظات حول التحسينات التي ترغب في رؤيتها.",
  "oifde.testMgr.failed_query" : "فشل الاستعلام.",
  "oifde.accesscode.recordState.disable" : "تعطيل",
  "oifde.flow.usage.resourceType.interface" : "واجهة",
  "oifde.mxgraph.subscript" : "Subscript",
  "oifde.diagram.NMS_NodeProperties" : "سمة العقدة",
  "oifde.trace.nodeName" : "اسم العقدة",
  "oifde.knowledgegraph.actions.cancel" : "إلغاء",
  "oifde.ivrJourney.sendFail" : "فشل الإرسال",
  "oifde.errorCode.100051006013" : "فشل استيراد الملف.",
  "oifde.template.voicename.cn_ziranzhen_common" : "طبيعي",
  "oifde.chatBotTest.totalNumber" : "حالات الاختبار",
  "oifde.testMgr.failed_delete_group" : "فشل حذف مجموعة حالات الاختبار.",
  "oifde.flow.exportFlowList.complexCell" : "عنصر مخطط مركب",
  "oifde.qarecord.inputparam.originalAnswer" : "الإجابة الأصلية",
  "oifde.error.messageTail" : "تحقق مما إذا كانت الشبكة والخادم يعملان بشكل صحيح.",
  "oifde.qiAsrServerMgr.asrServerName" : "اسم الخادم",
  "oifde.rule.message.succeed_save" : "تم حفظ قائمة الحظر/قائمة الثقة بنجاح.",
  "oifde.operationlog.failed" : "فشل",
  "oifde.diagram.NMS_ServiceName" : "اسم الخدمة",
  "oifde.sysConfig.abort" : "إلغاء",
  "oifde.mxgraph.fill" : "املأ",
  "oifde.procedure.paramDesc" : "الوصف",
  "oifde.mxgraph.file" : "ملف",
  "oifde.variable.actions.createSuccess" : "تم الإنشاء بنجاح.",
  "oifde.operationlog.sentiment_analysis" : "نموذج تحليل المشاعر",
  "oifde.procedure.actions.edit" : "تحرير",
  "oifde.knowledgegraph.validation.attrValue" : "أدخل قيمة سمة.",
  "oifde.operationlog.nms_interface" : "واجهة عملية الحوار",
  "oifde.ivrJourney.beforeReleaseHangupNum" : "عدد عمليات قطع الاتصال قبل الإصدار",
  "oifde.chatBotTest.cancleExcute" : "تم إلغاء التنفيذ.",
  "oifde.sysMgr.dataClean.editCcvirrecordFlag" : "تحرير التسجيل في تدفقات مكالمات IVR الشائعة",
  "oifde.sysMgr.dataClean.editSessionDT" : "تعديل الفاصل الزمني لتدفق مربع الحوار",
  "oifde.errorCode.100010000002" : "التنسيق غير صحيح.",
  "oifde.mxgraph.alreadyConnected" : "العقد المتصلة مسبقاً",
  "oifde.errorCode.100010000000" : "خطأ في النظام.",
  "oifde.route.toc.user" : "الفهم الدلالي",
  "oifde.expression.varGlobal" : "المتغير العالمي",
  "oifde.ivrJourney.receivers" : "المتلقي",
  "oifde.ivrJourney.yoyValue" : "قيمة MoM",
  "oifde.fileTask.EMPTY_TASK_ID" : "لا يمكن أن يكون معرف المهمة فارغًا.",
  "oifde.mxgraph.NMS_startNodeNotFind" : "لم يتم العثور على عقدة البدء.",
  "oifde.serviceInterface.serviceInterfaceDetails" : "تفاصيل تكوين واجهة الأعمال",
  "oifde.mxgraph.success" : "النجاح",
  "oifde.knowledgegraph.editSchema.headEntity" : "مفهوم الرأس",
  "oifde.flow.usage.flowVarsUsageTitle" : "تدفق الاستخدام المتغير",
  "oifde.flow.enums.status.invalid" : "غير صالح",
  "oifde.route.tuc.ops.asr" : "إدارة TTS/ASR",
  'oifde.route.tuc.ops.avatar': 'الإنسان الافتراضي ثنائي الأبعاد',
  "oifde.template.validate.intentcode" : "أدخل رمز نية.",
  "oifde.expression.expr_type_illegal" : "تنسيق التعبير غير صالح.",
  "oifde.accesscode.startcall" : "بدء مكالمة",
  "oifde.serviceInterface.in_param" : "معلمة الإدخال",
  "oifde.rate.interfaceSuccessRate" : "معدل نجاح الاستدعاء",
  "oifde.procedure.operation" : "العملية",
  "oifde.rule.message.import_user_ids" : "استيراد رقم المشترك",
  "oifde.serviceInterface.inputAppSecret" : "أدخل المفتاح المشترك.",
  "oifde.trace.currentNodeInfo" : "معلومات العقدة الحالية",
  "oifde.knowledgegraph.actions.deleteSuccess" : "تم الحذف بنجاح.",
  "oifde.navbar.required" : "هذا الحقل إلزامي.",
  "oifde.mxgraph.resetView" : "إعادة تعيين العرض",
  "oifde.mxgraph.format" : "تنسيق",
  "oifde.diagram.NMS_ServiceParams" : "معلمة الخدمة",
  "oifde.template.actions.prompt" : "تأكيد",
  "oifde.ivrJourney.optimizeVoiceSequence" : "تحسين تسلسل البث الصوتي",
  "oifde.fileTask.INVALID_REQUEST" : "الطلب غير صالح.",
  "oifde.operationlog.operationType" : "نوع العملية",
  "oifde.fileTask.INVALID_ENTRY_EXTENSION" : "ملحق اسم الملف في الحزمة المضغوطة غير صالح.",
  "oifde.serviceInterface.statusCode" : "رمز الحالة",
  "oifde.diagram.NMS_AddVariable" : "إضافة متغير",
  "oifde.route.knowledgegraph.kgFAQ" : "أسئلة الرسم البياني الشائعة لـ {0}",
  "oifde.sysMgr.dataClean.editInterfaceFlag" : "تحرير تسجيل استدعاء واجهة الطرف الثالث",
  "oifde.sysMgr.inputRpaUserName" : "أدخل اسم مستخدم RPA.",
  "oifde.testMgr.getCorpusTestCaseConfig" : "توليد حالة",
  "oifde.mxgraph.misc" : "متفرقات",
  "oifde.logparam.validate.sourceValue" : "أدخل قيمة مصدر.",
  "oifde.testMgr.getCorpusTestCase" : "توليد حالة",
  "oifde.mxgraph.center" : "محاذاة عمودية",
  "oifde.flow.exportFlowList.prompt" : "معلومات",
  "oifde.mxgraph.NMS_NodeCode" : "رمز العقدة",
  "oifde.recordHistory.kgStatus" : "رصد المعرفة",
  "oifde.flow.actions.confirm" : "نعم",
  "oifde.knowledgegraph.editSchema.deleteRelationPrompt" : "ستؤدي هذه العملية إلى حذف العلاقة وسماتها بشكل دائم. هل أنت متأكد من رغبتك في المتابعة؟",
  "oifde.flow.actions.invalidOk" : "التدفق لم ينشر.",
  "oifde.rule.update.newRuleName" : "أدخل اسم قاعدة.",
  "oifde.knowledgegraph.validation.required" : "القيمة إلزامية.",
  "oifde.sysMgr.dataClean.minute" : "دقيقة",
  "oifde.dashBoard.graph.hit" : "ضربات",
  "oifde.ivrJourney.forever" : "صالحة بشكل دائم",
  "oifde.operationlog.delete" : "حذف",
  "oifde.procedure.actions.miss_pro_value" : "الحقل الإلزامي التالي فارغ: معلومات أساسية > قيمة الإجراء المخزن.",
  "oifde.sysConfig.ENERGY_FILTER.NAME" : "وحدة التحكم في كمية النية",
  "oifde.license.processTime" : "تمت المعالجة",
  "oifde.knowledgegraph.dicmgr.relationMgr" : "إدارة العلاقات",
  "oifde.sysMgr.apiKeyCfg" : "إعدادات كلمة مرور API",
  "oifde.variable.scope" : "النطاق",
  "oifde.mxgraph.NMS_NodeCodeError" : "لا يمكن أن يكون رمز العقدة فارغًا أو يتجاوز 64 حرفًا.",
  "oifde.diagram.NMS_FlowVariableName" : "اسم المتغير",
  "oifde.toc.statusCode.INCORRECT_PASSWORD" : "كلمة المرور لا تفي بالمتطلبات.",
  "oifde.ivrJourney.complexityOverview" : "نظرة عامة على التعقيد",
  "oifde.rule.message.save_user_ids_fail" : "فشل حفظ أرقام المشتركين.",
  "oifde.mxgraph.lineJumps" : "قفزات الخط",
  "oifde.mxgraph.lockUnlock" : "قفل / إلغاء القفل",
  "oifde.recordHistory.recordDetail": "تفاصيل المكالمة",
  "oifde.mxgraph.cancel": "إلغاء",
  "oifde.serviceInterface.msgBody": "نص الرسالة",
  "oifde.testMgr.succeed_save_case": "تم حفظ حالة الاختبار بنجاح.",
  "oifde.serverMgr.ttsServer": "خادم TTS",
  "oifde.operationlog.targetObjType": "نوع الكائن",
  "oifde.ivrJourney.selectMailGateway": "يرجى تحديد بوابة بريد إلكتروني.",
  "oifde.mxgraph.height": "الارتفاع",
  "oifde.rate.accessCode": "حدد رمز الوصول إلى chatbot.",
  "oifde.table.manage": "إدارة",
  "oifde.ivrJourney.repeatRate": "معدل السمع",
  "oifde.toc.statusCode.INCORRECT_EMAIL": "عنوان البريد الإلكتروني غير صحيح.",
  "oifde.ivrJourney.transToManualCallsDesc": "عدد مكالمات IVR التي تحاول إعادة توجيهها إلى الخدمة اليدوية",
  "oifde.operationlog.log_parameters": "معلمة السجل",
  "oifde.whitelist.whiteTypes.INTERFACE": "واجهة",
  "oifde.mxgraph.selectVertices": "تحديد Vertices",
  "oifde.operationlog.stop_auto_generate": "توليد الحالات المتقطعة",
  "oifde.route.audioVideoResource": "موارد الصوت والفيديو",
  "oifde.route.knowledgegraph.importExcel": "استيراد الرسم البياني",
  "oifde.toc.statusCode.TENANT_SPACE_NOT_FOUND": "مساحة المستأجر غير موجودة.",
  "oifde.testMgr.batchExec": "تنفيذ",
  "oifde.mxgraph.connectionPoints": "نقاط الاتصال",
  "oifde.whitelist.description": "الوصف",
  "oifde.accesscode.knowledgeGraph": "الرسم البياني للمعرفة",
  "oifde.knowledgegraph.editSchema.selectTip": "---اختر--",
  "oifde.mxgraph.insertImage": "إدراج صورة",
  "oifde.diagram.NMS_FlowVariable": "متغير التدفق",
  "oifde.dashBoard.service.consultCount": "مجموع الردود",
  "oifde.variable.responsestyleDesc": "استخدم هذا المتغير لتعيين نمط المستأجر.",
  "oifde.sysMgr.apiRuleRange": "يجب أن تحتوي كلمة المرور على نوعين على الأقل مما يلي: أحرف كبيرة وأحرف صغيرة وأرقام وأحرف خاصة ~ `! {\'@\'} # {\'$\'} % ^ * ( ) - + = {\'|\'},.",
  "oifde.template.setIntentTemplate": "تكوين قالب النوايا",
  "oifde.serviceInterface.appSecret": "مفتاح مشترك",
  "oifde.serviceInterface.signBody": "التوقيع",
  "oifde.sysConfig.INTENT_DECISION_MAKER.BOTTOM.NAME": "الحد الأدنى",
  "oifde.variable.actions.edit": "تحرير",
  "oifde.mxgraph.formatPng": "PNG",
  "oifde.mxgraph.sourceSpacing": "تباعد المصدر",
  "oifde.logparam.transFlagDetail.YES": "نعم",
  "oifde.serviceInterface.outParamPath": "مسار",
  "oifde.route.tuc.template.config": "تكوين النظام",
  "oifde.knowledgegraph.actions.editSynonyms": "تحرير مرادف",
  "oifde.accesscode.traceClose" : "تعطيل",
  "oifde.mxgraph.font" : "الخط",
  "oifde.license.maxHisInfo" : "القيمة القصوى التاريخية",
  "oifde.license.import_template_hint_dat" : "يمكن تحميل الملفات بتنسيق in.dat فقط.",
  "oifde.ivrJourney.validIVRCalls" : "حجم خدمة IVR صالح",
  "oifde.mxgraph.openInNewWindow" : "فتح في نافذة جديدة",
  "oifde.mxgraph.zoom" : "التكبير",
  "oifde.testCase.caseName" : "اسم الحالة",
  "oifde.variable.variableType" : "نوع البيانات",
  "oifde.rate.successRate" : "معدل نجاح استدعاء الواجهة",
  "oifde.trace.nodeId" : "معرف العقدة",
  "oifde.dashBoard.knowledge.name" : "الاسم",
  "oifde.mxgraph.collapsible" : "قابلة للطي",
  "oifde.mxgraph.automatic" : "أوتوماتيكي",
  "oifde.ivrJourney.efficiencyTrend" : "توزيع اتجاهات الكفاءة",
  "oifde.accesscode.validate.accessCodeCharReg" : "يُسمح فقط بالحروف والأرقام.",
  "oifde.fileTask.TASK_NOT_FOUND" : "لم يتم العثور على المهمة.",
  "oifde.dashBoard.knowledge.hitCount" : "ضربات",
  "oifde.trace.actionParams" : "معلومات معلمة عقدة التنفيذ",
  "oifde.operationlog.quick_release" : "نشر بنقرة واحدة",
  "oifde.index.title" : "ODFS - خدمة تدفق الحوار عبر الإنترنت",
  "oifde.common.dialog.confirm.successInfo" : "تم حذف البيانات بنجاح.",
  "oifde.variable.variableTypeDetail.float" : "رقم النقطة العائمة",
  "oifde.accesscode.validate.dialogType" : "حدد نوع مربع حوار.",
  "oifde.fileTask.INVALID_FILE_PATH" : "مسار الملف غير صحيح.",
  "oifde.accesscode.bindKg" : "ربط المعرفة الرسم البياني",
  "oifde.serviceInterface.miss_ifs_in_parameter_name" : "الحقل الإلزامي التالي فارغ: معلمة الإدخال > الاسم.",
  "oifde.flow.importFlow.type" : "النوع",
  "oifde.knowledgegraph.kgFAQ.title" : "الأسئلة الشائعة حول الرسم البياني",
  "oifde.mxgraph.east" : "الشرق",
  "oifde.procedure.ruleMysql" : "يجب أن تبدأ قاعدة بيانات MySQL بـ jdbc:mysql://.",
  "oifde.operationlog.system_parameters" : "معلمة النظام",
  "oifde.mxgraph.move" : "تحريك",
  "oifde.accesscode.actions.delete" : "حذف",
  "oifde.flow.upgrade.currentVersions" : "الإصدارات الحالية",
  "oifde.mxgraph.editLink" : "تحرير الارتباط",
  "oifde.mxgraph.saved" : "تم الحفظ",
  "oifde.testCase.detail.realResponse" : "نص الاستجابة الفعلية",
  "oifde.mxgraph.isometric" : "Isometric",
  "oifde.ivrJourney.middle" : "الأوسط",
  "oifde.toc.statusCode.TENANT_SPACE_FORBIDDEN" : "العمليات على مساحة المستأجر هذه ممنوعة.",
  "oifde.template.typeDetail.multiMedia" : "الوسائط المتعددة",
  "oifde.operationlog.update" : "تحديث",
  "oifde.mxgraph.reverse" : "عكسي",
  "oifde.serviceInterface.reqURL" : "طلب عنوان URL",
  "oifde.accesscode.validate.domainIdMax" : "لا يمكن أن يتجاوز معرف المجال 2147483647.",
  "oifde.ivrJourney.nodeTrafficChart" : "مخطط حركة العقدة (عدد المؤشرات)",
  "oifde.template.intentionInfo.intentDsp" : "الوصف",
  "oifde.fileTask.GRAPH_IS_ON" : "لا يمكن استيراد رسم بياني نشط.",
  "oifde.navbar.action.success" : "النجاح",
  "oifde.ivrJourney.successCalls" : "عدد النجاحات",
  "oifde.mxgraph.NMS_RegionInfoLoadFail" : "فشل تحميل معلومات المنطقة.",
  "oifde.ivrJourney.alarmCondition" : "شرط التحذير المسبق",
  "oifde.operationlog.tenantAsrName" : "تكوين ASR",
  "oifde.serviceInterface.certCode" : "الكود",
  "oifde.ivrJourney.alarmIndicator" : "مؤشرات التحذير المسبق",
  "oifde.knowledgegraph.extendFaq.similarReply" : "مماثل Reply",
  "oifde.template.actions.cancel" : "لا",
  "oifde.ivrJourney.nodeName" : "اسم العقدة",
  "oifde.ivrJourney.callNum" : "حجم المكالمات",
  "oifde.rate.accessAmount" : "المكالمات المتصلة",
  "oifde.route.tuc.ops.test" : "اختبار الحوار",
  "oifde.trackmgr.callingNumber" : "الرقم المتصل",
  "oifde.template.content" : "المحتوى",
  "oifde.ivrJourney.afterReleaseRepeatRate" : "معدل إعادة التشغيل بعد الإصدار",
  "oifde.ivrJourney.journeySum" : "الأوقات المستخدمة",
  "oifde.toc.statusCode.ACCOUNT_INCONSISTENT" : "لقد قمت بتسجيل الدخول إلى الكمبيوتر المحلي باستخدام حساب آخر. لمتابعة استخدام هذه الخدمة، قم بتسجيل الدخول مرة أخرى.",
  "oifde.flow.usage.procedureUsageTitle" : "استخدام الإجراء المخزن",
  "oifde.mxgraph.saveAndNopublish" : "تم حفظ التدفق بنجاح ولكن فشل نشره.",
  "oifde.mxgraph.horizontalFlow" : "التدفق الأفقي",
  "oifde.accesscode.dialogTypeDetail.chatbot" : "Chatbot",
  "oifde.fileTask.KG_NO_SCHEMA" : "لا يرتبط الرسم البياني بمخطط المعرفة.",
  "oifde.qiAsrServerMgr.asrServerPort" : "منفذ خادم ASR",
  "oifde.ivrJourney.count" : "الأوقات",
  "oifde.testMgr.case_failed" : "فشل",
  "oifde.ivrJourney.avgIVRTime" : "متوسط مدة المكالمة (بالثانية)",
  "oifde.mxgraph.openFile" : "فتح ملف",
  "oifde.logparam.message.logParamNotNull" : "حدد ما إذا كنت تريد تحويل القيمة.",
  "oifde.trackmgr.choose" : "-اختر-",
  "oifde.recordHistory.reqTime" : "مطلوب",
  "oifde.mxgraph.flipV" : "الوجه العمودي",
  "oifde.template.addTopNode" : "إضافة العقدة العلوية",
  "oifde.mxgraph.more" : "المزيد",
  "oifde.common.validation.openNameCharReg" : "يُسمح فقط بالأحرف الصينية والحروف الإنجليزية والأرقام والمسافات وتسطير (_), الواصلات (-) وعلامات الاقتباس المفردة (\').",
  "oifde.mxgraph.editData" : "تحرير البيانات",
  "oifde.mxgraph.searchShapes" : "عقد البحث",
  "oifde.template.addContent" : "إضافة محتوى قالب",
  "oifde.mxgraph.flipH" : "الوجه الأفقي",
  "oifde.mxgraph.NMS_ParamValue" : "القيمة",
  "oifde.common.validation.nodeNameCharReg" : "يمكن أن تحتوي القيمة على أحرف صينية وحروف إنجليزية وأرقام وتسطير سفلي (_), وواصلات (-) فقط.",
  "oifde.operationlog.knowledge_graph_schema_attribute" : "سمة مخطط المعرفة",
  "oifde.testMgr.userId" : "معرف المشترك",
  "oifde.serviceInterface.interfaceName" : "اسم الواجهة",
  "oifde.flow.usage.ruleUsageTitle" : "استخدام القاعدة الرمادية",
  "oifde.flow.version" : "الإصدار",
  "oifde.route.viewTraceDiagram" : "عرض تتبع التدفق",
  "oifde.mxgraph.strokeColor" : "لون الخط",
  "oifde.serviceInterface.msgHeaders" : "رأس الرسالة",
  "oifde.sysMgr.dataClean.open" : "تمكين",
  "oifde.logparam.targetValue" : "القيمة المستهدفة",
  "oifde.ivrJourney.inflowDistribution" : "توزيع التدفق",
  "oifde.mxgraph.duplicateIt" : "تكرار {0}",
  "oifde.knowledgegraph.editSchema.relationName" : "اسم العلاقة",
  "oifde.qiAsrServerMgr.folder" : "المجلد الافتراضي",
  "oifde.common.validation.nameCharReg" : "يمكن أن تحتوي القيمة على أحرف صينية وحروف إنجليزية وأرقام وتسطير سفلي (_), وواصلات (-) فقط.",
  "oifde.logparam.validate.source" : "حدد مصدر معلمة.",
  "oifde.fileTask.IMPORT_KG_RELATION_TOO_BIG" : "يسمح بحد أقصى 100 مليون علاقة الرسم البياني.",
  "oifde.errorCode.100051000006" : "فشل استيراد الملف لأن التنسيق غير صالح.",
  "oifde.whitelist.tenantSpace" : "مساحة المستأجر",
  "oifde.flow.flowTemplate" : "قالب التدفق",
  "oifde.operationlog.tenantAsrTtsName" : "إدارة TTS/ASR",
  "oifde.mxgraph.formatPdf" : "PDF",
  "oifde.variable.scopes.output" : "معلمة الإخراج",
  "oifde.maintain.odfsLogOpen" : "تمكين وظيفة سجل ODFS",
  "oifde.navbar.switchLang" : "تغيير اللغة",
  "oifde.navbar.password.passwordExpired" : "انتهت صلاحية كلمة المرور الخاصة بك. قم بتغييرها.",
  "oifde.ivrJourney.selectSmsGateway" : "يرجى تحديد بوابة الرسائل القصيرة.",
  "oifde.flow.exportFlowList.exportAll" : "تصدير الكل",
  "oifde.route.tuc.history.index" : "حوار تاريخي",
  "oifde.dashBoard.pie.intentTitle" : "النية",
  "oifde.operationlog.api_key" : "مفتاح API",
  "oifde.procedure.modifyProcedureDB" : "تحرير قاعدة بيانات الإجراءات المخزنة",
  "oifde.mxgraph.dotted" : "منقط",
  "oifde.mxgraph.selectNone" : "تحديد لا شيء",
  "oifde.testMgr.actual_output" : "الناتج الفعلي",
  "oifde.ivrJourney.valueOverview" : "نظرة عامة على القيمة",
  "oifde.operationlog.staticcheck" : "فحص ثابت",
  "oifde.logparam.actions.updateSuccess" : "نجح التعديل.",
  "oifde.toc.statusCode.INVALID_JSON" : "الطلب ليس بتنسيق JSON.",
  "oifde.toc.statusCode.INCORRECT_TENANT_SPACE_NAME" : "اسم مساحة المستأجر غير صحيح.",
  "oifde.ivrJourney.poor" : "الفقراء",
  "oifde.serviceInterface.query_param" : "معلمة الاستعلام",
  "oifde.qiAsrServerMgr.fileServerPWConfirm" : "تأكيد كلمة مرور خادم الملفات",
  "oifde.chatBotTest.inputTestCaseGroupName" : "اسم مجموعة حالة الاختبار",
  "oifde.accesscode.descPlaceholder" : "يُستخدم هذا الوصف كوصف التدفق عند تحديد التدفق كتكوين الطرف المطلوب. يجب أن تكون القيمة فريدة من نوعها على الصعيد العالمي.",
  "oifde.ivrJourney.indicateName" : "اسم المؤشر",
  "oifde.common.validation.cellNameRange" : "الأحرف الخاصة التالية ليست allowed: < > {\'@\'} # {\'$\'} % ^ * ;  ~ / \\",
  "oifde.dashBoard.service.positiveFeedbackCount" : "ردود فعل راضية",
  "oifde.diagram.saveAndPublish" : "حفظ ونشر",
  "oifde.mxgraph.heading" : "العنوان",
  "oifde.expression.varFlow" : "متغير التدفق",
  "oifde.logparam.logName" : "اسم المعلمة",
  "oifde.license.failed_set_license" : "فشل تكوين الترخيص.",
  "oifde.sysMgr.dataClean.hour" : "h",
  "oifde.accesscode.actions.cancelDelete" : "تم إلغاء الحذف.",
  "oifde.mxgraph.saveAs" : "حفظ باسم",
  "oifde.chatBotTest.editTestCaseGroup" : "تعديل مجموعة حالة الاختبار",
  "oifde.route.optimizationSuggestions" : "اقتراحات التحسين",
  "oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.WORD_FREQ.NAME" : "جمع إحصائيات عن الكلمات المتكررة",
  "oifde.sysConfig.code" : "رمز المتغير",
  "oifde.sysMgr.dataClean.reportFlagTitle" : "تعديل تسجيل حالة الاختبار",
  "oifde.operationlog.import" : "استيراد",
  "oifde.sysMgr.rpaCfg" : "إعدادات RPA",
  "oifde.ivrJourney.repeatedCallRate" : "معدل تكرار المكالمات",
  "oifde.operationlog.complexcell" : "عنصر الرسم البياني المركب",
  "oifde.flow.setting.logSwitchOn" : "تمكين",
  "oifde.mxgraph.diagram" : "الرسم البياني",
  "oifde.common.validation.urlValidate" : "تنسيق URL غير صحيح.",
  "oifde.mxgraph.noMoreResults" : "لا مزيد من النتائج",
  "oifde.operationlog.release" : "نشر",
  "oifde.route.transferRecord" : "سجل نقل العملية",
  "oifde.sysMgr.dataClean.sessionDTDesc" : "الفاصل الزمني للمهلة لتدفقات الحوار.",
  "oifde.mxgraph.ok" : "حسناً",
  "oifde.variable.validate.variableType" : "حدد نوع متغير.",
  "oifde.table.reset" : "إعادة الضبط",
  "oifde.mxgraph.diamondThin" : "الماس (رقيقة)",
  "oifde.dashBoard.graph.hitRateTitle" : "معدل ضرب",
  "oifde.sysConfig.sysParam" : "معلمات النظام",
  "oifde.accesscode.validate.descriptionLengthValidator" : "يمكن أن يحتوي وصف رمز الوصول على 512 حرفًا كحد أقصى.",
  "oifde.whitelist.whiteIpListNotNull" : "حدد قائمة ثقة واحدة على الأقل ليتم حذفها.",
  "oifde.error.messageBody" : "خطأ في الطلب. رمز الخطأ:",
  "oifde.chatBotTest.passTimes" : "مرت",
  "oifde.toc.statusCode.ACCOUNT_SWITCHED" : "تم تغيير حساب تسجيل الدخول. سيتم تحديث هذه الصفحة لعرض أحدث حساب لتسجيل الدخول.",
  "oifde.dashBoard.pie.intent" : "النية",
  "oifde.logparam.sourceDetail.IVRResponseInformation" : "استجابة IVR",
  "oifde.template.createTime" : "تم الإنشاء",
  "oifde.mxgraph.classic" : "الكلاسيكية",
  "oifde.common.validation.nameNoSpace" : "لا يمكن للقيمة أن تبدأ أو تنتهي بمسافة.",
  "oifde.accesscode.validate.flowCodeLength" : "يمكن أن يحتوي رمز التدفق على 64 حرفًا كحد أقصى.",
  "oifde.chatBotTest.rangeFilter" : "تصفية السجلات المحددة",
  "oifde.ivrJourney.averagePathLength" : "متوسط طول المسار",
  "oifde.flow.actions.delete" : "حذف",
  "oifde.mxgraph.simple" : "بسيطة",
  "oifde.rule.actions.edit" : "تحرير",
  "oifde.mxgraph.loading" : "جار التحميل",
  "oifde.serviceInterface.signBodyDetail.no" : "لا",
  "oifde.mxgraph.deleteColumn" : "حذف عمود",
  "oifde.ivrJourney.visitsTotal" : "الزيارات",
  "oifde.fileTask.IMPORT_SCHEMA_RELATION_TOO_BIG" : "يُسمح بحد أقصى لعلاقات 10,000 في مخطط المعرفة.",
  "oifde.ivrJourney.repeatCalls" : "أسمع",
  "oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.TSF_ENABLE.FALSE" : "تعطيل",
  "oifde.template.intentionInfo.addIntent" : "إضافة نية",
  "oifde.sysMgr.dataClean.flowIvrSaveIntervalDesc" : "الفاصل الزمني للحفظ الدوري في تزامن تدفق IVR.",
  "oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_TONE.FALSE" : "لا",
  "oifde.complexcell.catalog.name" : "اسم الكتالوج",
  "oifde.trackmgr.robotsTrack" : "تتبع الروبوتات",
  "oifde.testMgr.number" : "يُسمح فقط بالأرقام.",
  "oifde.sysMgr.dataClean.editTime" : "تعديل الفاصل الزمني لتنظيف البيانات",
  "oifde.variable.validate.listValueCheck" : "تنسيق قيمة بيانات القائمة الافتراضية غير صحيح. يجب أن تكون القيمة بتنسيق [1,2,3] أو [\"a\",\"b\",\"c\"]، ويمكن أن تكون القيمة [].",
  "oifde.operationlog.knowledge_graph" : "الرسم البياني للمعرفة",
  "oifde.knowledgegraph.editSchema.deleteAttributePrompt" : "ستؤدي هذه العملية إلى حذف السمة نهائيًا. هل تريد بالتأكيد متابعة العملية؟",
  "oifde.flow.usage.status.norecord" : "لا توجد بيانات",
  "oifde.testCase.detail.expectedReturn" : "نص الاستجابة المتوقع",
  "oifde.sysConfig.CRF_PARAM_EXTRACTOR.NAME" : "وحدة وسم فتحة CRF",
  "oifde.ivrJourney.highThan" : "أعلى من",
  "oifde.logparam.validate.logName" : "أدخل اسم معلمة.",
  "oifde.ivrJourney.noNeedToSend" : "لا حاجة للإرسال",
  "oifde.testMgr.actual_output_title" : "الناتج الفعلي (اسم النية بين قوسين)",
  "oifde.operationlog.channel_type" : "قناة",
  "oifde.mxgraph.close" : "إغلاق",
  "oifde.mxgraph.landscape" : "المناظر الطبيعية",
  "oifde.complexcell.cell.validation.bigFile" : "لا يمكن أن يتجاوز حجم الملف {0}",
  "oifde.serviceInterface.actions.prompt" : "تأكيد",
  "oifde.operationlog.start" : "ابدأ",
  "oifde.sysConfig.CRF_PARAM_EXTRACTOR.LEARNING_RATE.DESC" : "المعدل الذي يتم عنده تنفيذ تعلم نموذج CRF.",
  "oifde.template.voicename.cn_tianmeiru_common" : "الحلو",
  "oifde.sysMgr.dataClean.flowIvrSaveFlagDesc" : "ما إذا كان سيتم تمكين إشعار الحفظ الدوري في تنسيق تدفق IVR.",
  "oifde.mxgraph.NMS_Invalid" : "غير صالح",
  "oifde.template.deleteLimit" : "مطلوب سجل محتوى واحد على الأقل.",
  "oifde.operationlog.stop" : "توقف",
  "oifde.serviceInterface.oifdeInterface" : "واجهة روبوت ذكي",
  "oifde.flow.exportFlowList.interface" : "واجهة",
  "oifde.whitelist.editWhiteList" : "تحرير قائمة الثقة",
  "oifde.mxgraph.west" : "والغرب",
  "oifde.mxgraph.plusTooltip" : "انقر للاتصال والاستنساخ (ctrl + انقر للاستنساخ ، Shift + انقر للاتصال). اسحب للاتصال (ctrl + السحب للاستنساخ).",
  "oifde.procedure.dataTypes.STRING" : "الحرف",
  "oifde.mxgraph.fitPage" : "صفحة واحدة",
  "oifde.common.validation.schemaNameCharReg" : "يُسمح فقط بالأحرف الصينية والحروف الإنجليزية والأرقام والمسافات والشرطات السفلية (_), وعلامات الاقتباس المفردة (\').",
  "oifde.sysMgr.encryptSecretLenCheck" : "يجب أن يحتوي المفتاح على 16 حرفًا.",
  "oifde.variable.scopes.input" : "معلمة الإدخال",
  "oifde.mxgraph.NMS_actionParamValueEmptyError" : "لا يمكن أن تكون القيمة فارغة.",
  "oifde.accesscode.hideTraceSet" : "الانهيار",
  "oifde.operationlog.resource_template" : "قالب المورد",
  "oifde.knowledgegraph.attributemgr.description" : "وصف السمة",
  "oifde.qiAsrServerMgr.description" : "الوصف",
  "oifde.toc.statusCode.INCORRECT_OWNER" : "المالك غير صحيح.",
  "oifde.diagram.NMS_ParamName" : "المعلمة",
  "oifde.operationlog.knowledge_graph_object_entity" : "سمة كيان قاموس الرسم البياني",
  "oifde.flow.actions.invalidCancel" : "تم إلغاء الإبطال.",
  "oifde.accesscode.dialogTypeDetail.voiceNavigation" : "الملاحة الصوتية",
  "oifde.ivrJourney.repeatCallOptimizeSuggestion" : "عدد مرات إعادة تشغيل العقدة الحالية مرتفع للغاية. تحقق مما إذا كان محتوى الصوت غير واضح أو صعب الفهم. ننصحك بتحسين محتوى الصوت.",
  "oifde.procedure.ruleGauss" : "يجب أن يبدأ GaussDB بـ jdbc:zenith:{\'@\'}.",
  "oifde.fileTask.READ_FILE_FAILED" : "فشل في قراءة الملف.",
  "oifde.ivrJourney.businessRanking" : "تصنيفات الأعمال",
  "oifde.asrMgr.TENANT_SPACE_NOT_FOUND" : "لم يتم العثور على مساحة المستأجر.",
  "oifde.ivrJourney.distinctCustomers" : "عدد العملاء",
  "oifde.mxgraph.save" : "حفظ",
  "oifde.template.validate.templateContentLength" : "لا يمكن أن يحتوي محتوى القالب على أكثر من 2048 حرفًا.",
  "oifde.robotReply.replyWay" : "طريقة الرد",
  'oifde.robotReply.replyWayBreve': 'الطريق',
  'oifde.robotReply.replyResourceBreve': 'الموارد',
  'oifde.robotReply.replyVariableBreve': 'متغير',
  'oifde.robotReply.replyTemplateBreve': 'قالب',
  'oifde.robotReply.replModeBreve': 'الوضع',
  "oifde.variable.encryptFlag.two" : "مشفرة للتخزين والاسترجاع",
  "oifde.knowledgegraph.extendFaq.setTemplate" : "تعيين سؤال موسع",
  "oifde.diagram.NMS_FlowVariableType" : "نوع البيانات",
  "oifde.fileTask.UNZIPPED_TOO_LARGE" : "حجم الحزمة غير المضغوطة كبير جدًا.",
  "oifde.procedure.paramName" : "المعلمة",
  "oifde.sysMgr.encryptSecretNoSame" : "لا يمكن أن يكون المفتاح هو نفسه معرف مساحة المستأجر.",
  "oifde.serviceInterface.dataTypes.LIST" : "قائمة",
  "oifde.ivrJourney.sms" : "SMS",
  "oifde.qarecord.inputparam.questionType" : "نوع السؤال",
  "oifde.complexcell.cell.actions.editDiagram" : "تحرير Canvas",
  "oifde.flow.actions.invalid" : "غير صالح",
  "oifde.operationlog.clone" : "استنساخ",
  "oifde.fileTask.TOO_MANY_ENTRIES" : "تحتوي الحزمة المضغوطة على ملفات كثيرة جدًا.",
  "oifde.testMgr.confirm_delete_case_group" : "هل تريد بالتأكيد حذف مجموعة حالة الاختبار؟",
  "oifde.common.select" : "-اختر-",
  "oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.NS.DESC" : "الحد الأدنى لقيمة N في خوارزمية N-Gram.",
  "oifde.ivrJourney.smsAndEmail" : "SMS؛ البريد الإلكتروني",
  "oifde.mxgraph.radialTree" : "شجرة شعاعي",
  "oifde.route.tuc.ops.modelMgr" : "إدارة النماذج",
  "oifde.diagram.error.NMS_endNodetransitToError" : "فشل الانتقال من عقدة النهاية إلى عقدة أخرى.",
  "oifde.testMgr.execute_all" : "تنفيذ الكل",
  "oifde.mxgraph.backgroundImage" : "صورة خلفية",
  "oifde.rule.name" : "الاسم",
  "oifde.chatBotTest.executeSuccess" : "تم التنفيذ بنجاح.",
  "oifde.common.language.chinese" : "الصينية",
  "oifde.knowledgegraph.relationmgr.relation" : "العلاقة",
  "oifde.common.validation.nameCharRegNotChinese" : "يمكن أن تحتوي القيمة على أحرف إنجليزية وأرقام وتسطيرات سفلية (_), وواصلات (-) فقط.",
  "oifde.operationlog.invalidate" : "غير صالح",
  "oifde.diagram.error.NMS_startNodeNotFind" : "لم يتم العثور على عقدة البدء.",
  "oifde.procedure.actions.prompt" : "تأكيد",
  "oifde.route.voiceTemplateTitle" : "قالب المورد",
  "oifde.fileTask.UPLOAD_EXCEPTION" : "تمت مقاطعة التحميل.",
  "oifde.sysMgr.dataClean.noRecord" : "عدم التسجيل",
  "oifde.ivrJourney.batchDetele" : "حذف الحزمة",
  "oifde.testMgr.noDomain" : "لم يتم تحديد مجال.",
  "oifde.license.at_least_one_serviceValue" : "قم بتعيين معلمة واحدة على الأقل.",
  "oifde.mxgraph.replaceExistingDrawing" : "استبدال الرسم الموجود",
  "oifde.ivrJourney.currentVoicePlayContent" : "محتوى البث الصوتي الحالي",
  "oifde.diagram.error.NMS_actionParamValueError" : "يمكن أن تحتوي قيمة المعلمة على 4096 حرفًا بحد أقصى.",
  "oifde.mxgraph.NMS_ActionParamsGetFail" : "فشل الحصول على معلمات عنصر المخطط.",
  "oifde.common.validation.min" : "لا يمكن أن تكون القيمة أقل من {0}.",
  "oifde.operationlog.upload" : "تحميل",
  "oifde.knowledgegraph.actions.view" : "عرض",
  "oifde.sysConfig.UIR_VECTOR_ENERGY_CHECKER.NAME" : "وحدة تصفية نية غير معروفة",
  "oifde.mxgraph.NMS_ScenarioType_error" : "سيناريو الاستثناء",
  "oifde.sysMgr.dataClean.setTime" : "تعيين مدة مسح البيانات.",
  "oifde.dashBoard.knowledge.accessCode" : "رمز الوصول",
  "oifde.importFile.knowledge_graph_ask_template" : "الكتابة فوق كافة الإعدادات الموجودة",
  "oifde.sysMgr.rpaSecretKeyCfg" : "مفتاح RPA السري",
  "oifde.mxgraph.waypoints" : "نقاط الطريق",
  "oifde.operationlog.check" : "التحقق من صحة",
  "oifde.knowledgegraph.actions.saveSuccess" : "تم الحفظ بنجاح.",
  "oifde.mxgraph.superscript" : "سوبركت",
  "oifde.mxgraph.create" : "إنشاء",
  "oifde.trace.traceReason" : "سبب الخطأ",
  "oifde.mxgraph.url" : "عنوان URL",
  "oifde.mxgraph.topLeft" : "أعلى اليسار",
  "oifde.whitelist.descriptionLen" : "يمكن أن يحتوي الوصف على 1024 حرفًا كحد أقصى.",
  "oifde.trace.callingNumber" : "الرقم المتصل",
  "oifde.knowledgegraph.extendFaq.question" : "سؤال",
  "oifde.rule.message.succeed_delete_user_ids" : "تم حذف أرقام المشتركين بنجاح.",
  "oifde.asrMgr.tenantId" : "معرف مساحة المستأجر",
  "oifde.knowledgegraph.kgSynonyms.type.entity" : "الكيان",
  "oifde.rule.message.succeed_delete" : "تم حذف قائمة الحظر/قائمة الثقة بنجاح.",
  "oifde.knowledgegraph.extendFaq.faqTemplate" : "قالب الأسئلة الشائعة",
  "oifde.mxgraph.collapseExpand" : "الانهيار/التوسيع",
  "oifde.trackmgr.actions.updatePrompt" : "ستؤثر هذه العملية على أداء النظام، وسيتم حذف الإعداد تلقائيًا {0} h لاحقًا. هل أنت متأكد من رغبتك في المتابعة؟",
  "oifde.ivrJourney.hangUpRate" : "معدل الانتظار",
  "oifde.mxgraph.editStyle" : "تحرير النمط",
  "oifde.analysis.validate.accessCode" : "رمز وصول الروبوت مطلوب",
  "oifde.operationlog.knowledge_graph_entity" : "كيان قاموس الرسم البياني",
  "oifde.procedure.dataTypes.LIST" : "قائمة",
  "oifde.chatBotTest.allAccessCode" : "تحديد جميع رموز الوصول إلى chatbot",
  "oifde.rate.time" : "التاريخ والوقت",
  "oifde.testMgr.case_group" : "مجموعات حالة الاختبار",
  "oifde.common.dialog.confirm.title" : "تأكيد",
  "oifde.audioVideoResource.preview" : "معاينة",
  "oifde.mxgraph.prompt" : "موجه",
  "oifde.rule.message.mgr_name_list" : "إدارة Blocklist/Trustlist —",
  "oifde.dashBoard.graph.count" : "المجموع",
  "oifde.route.rule" : "تدفق القاعدة الرمادية",
  "oifde.chatBotTest.testCaseGroupName" : "اسم مجموعة الحالة",
  "oifde.flow.upgrade.newVersion" : "نسخة جديدة",
  "oifde.flow.usage.resourceType.procedure" : "الإجراء المخزن",
  "oifde.ivrJourney.selectTime" : "يرجى تحديد وقت صالح.",
  "oifde.mxgraph.textOpacity" : "عتامة النص",
  "oifde.qiAsrServerMgr.editQiAsrServer" : "تحرير معلومات خادم ASR لـ QC",
  "oifde.knowledgegraph.actions.addEntity" : "إضافة كيان",
  "oifde.mxgraph.turn" : "تدوير 90 درجة",
  "oifde.dashBoard.service.positiveFeedbackRate" : "معدل الرضا",
  "oifde.operationlog.illeagal_status" : "الوضع غير القانوني",
  "oifde.navbar.action.message" : "موجه",
  "oifde.sysMgr.rpaUserNameLenCheck" : "لا يمكن أن يحتوي حساب مستخدم RPA على أكثر من 64 حرفًا.",
  "oifde.sysMgr.rpaUserNameCfg" : "حساب مستخدم RPA",
  "oifde.mxgraph.writingDirection" : "اتجاه الكتابة",
  "oifde.variable.actions.prompt" : "تأكيد",
  "oifde.variable.actions.add" : "إضافة",
  "oifde.diagram.NMS_DiagramProperties" : "سمة التدفق",
  "oifde.qiAsrServerMgr.asrServerPW" : "كلمة مرور خادم ASR",
  "oifde.mxgraph.updatingDocument" : "جاري تحديث المستند. الرجاء الانتظار...",
  "oifde.qiAsrServerMgr.nextStep" : "التالي",
  "oifde.trace.calledNumber" : "الرقم المطلوب",
  "oifde.testMgr.test_case_group_details" : "التفاصيل",
  "oifde.flow.add.callInFlow" : "تدفق المكالمات الواردة",
  "oifde.knowledgegraph.validation.dicName" : "أدخل اسم قاموس.",
  "oifde.chatBotTest.prompt" : "معلومات",
  "oifde.template.sysErrorTemplate" : "تحرير قالب مورد غير طبيعي",
  "oifde.rule.message.user_ids_number" : "يمكن إضافة 5000 رقم مشترك كحد أقصى إلى قائمة الحظر/قائمة الثقة.",
  "oifde.diagram.NMS_Desciption" : "الوصف",
  "oifde.expression.varIvrRequest" : "متغير طلب IVR",
  "oifde.procedure.procedureDBMgr" : "قواعد بيانات الإجراءات المخزنة",
  "oifde.mxgraph.filename" : "اسم الملف",
  "oifde.serviceInterface.actions.cancel" : "لا",
  "oifde.mxgraph.NMS_endNodeNotFind" : "لم يتم العثور على عقدة النهاية.",
  "oifde.procedure.procedureDesc" : "الوصف",
  "oifde.mxgraph.uml" : "UML",
  "oifde.testMgr.case_passed_rate" : "معدل النجاح",
  "oifde.serviceInterface.signDescription" : "ما إذا كان سيتم التحقق من التوقيع لطرف ثالث عند نقل التوقيع إلى الطرف الثالث كمعلمة واجهة.",
  "oifde.mxgraph.NMS_TargetNode" : "عقدة الهدف",
  "oifde.mxgraph.cannotOpenFile" : "لا يمكن فتح الملف.",
  "oifde.diagram.NMS_BranchCondition" : "حالة الفرع",
  "oifde.common.dialog.confirm.buttonCancel" : "لا",
  "oifde.rule.update.ruleName" : "اسم القاعدة",
  "oifde.flow.importFlow.resultTitle" : "استيراد النتائج",
  "oifde.template.typeDetail.cardTemplate" : "قالب البطاقة",
  "oifde.ivrJourney.noData" : "لا شيء",
  "oifde.knowledgegraph.actions.addDic" : "إضافة قاموس الرسم البياني",
  "oifde.knowledgegraph.actions.editEntity" : "تحرير الكيان",
  "oifde.knowledgegraph.dicmgr.synonyms" : "مرادف",
  "oifde.logparam.actions.delete" : "حذف",
  "oifde.mxgraph.addToExistingDrawing" : "إضافة إلى الرسم الموجود",
  "oifde.operationlog.export" : "تصدير",
  "oifde.procedure.actions.createSuccess" : "تم الإنشاء بنجاح.",
  "oifde.route.tuc.ops.tucTest" : "اختبار المعرفة",
  "oifde.route.sysMgr.sysCfg" : "تكوين النظام",
  "oifde.toc.statusCode.SERVER_BUSY" : "النظام مشغول حاليًا. حاولي لاحقاً",
  "oifde.sysConfig.confirm_restore_defaults" : "هل تريد بالتأكيد استعادة القيم الافتراضية؟",
  "oifde.sysConfig.CRF_PARAM_EXTRACTOR.MAX_NODES.DESC" : "عدد العقد الموجودة داخل كل شجرة في خوارزمية GBRT.",
  "oifde.common.actions.add" : "إضافة",
  "oifde.knowledgegraph.errorCode.100051000114" : "فشل حفظ السؤال الموسع للأسئلة الشائعة عن الرسم البياني.",
  "oifde.knowledgegraph.errorCode.100051000113" : "عنوان URL الذي تم تكوينه لخدمة GES غير صحيح.",
  "oifde.knowledgegraph.errorCode.100051000112" : "جاري إعداد الرسم البياني...يرجى الانتظار.",
  "oifde.knowledgegraph.errorCode.100051000111" : "وقد وصل العدد إلى الحد الأقصى البالغ 190.",
  "oifde.operationlog.knowledge_graph_ask_template_group" : "الرسم البياني الموسع السؤال",
  "oifde.knowledgegraph.errorCode.100051000110" : "وصل العدد إلى الحد الأعلى وهو 10.",
  "oifde.rate.callStaffConsultCount" : "تحويل المكالمات من Chatbot إلى وكيل",
  "oifde.knowledgegraph.relationmgr.tailEntity" : "كيان الذيل",
  "oifde.common.validation.max" : "لا يمكن أن تكون القيمة أكبر من {0}.",
  "oifde.flow.copy.newFlowName" : "اسم التدفق الجديد",
  "oifde.knowledgegraph.actions.viewSchema" : "عرض المخطط",
  "oifde.mxgraph.doubleClickOrientation" : "انقر نقرًا مزدوجًا لتغيير الاتجاه",
  "oifde.chatBotTest.robotName" : "اسم Chatbot",
  "oifde.testCase.doAll" : "تنفيذ الكل",
  "oifde.operationlog.resource_template_exce" : "قالب مورد الاستثناء",
  "oifde.ivrJourney.transManualSuccRate" : "التحويل إلى معدل نجاح الخدمة اليدوية (%)",
  "oifde.mxgraph.NMS_flowCodeError" : "لا يمكن أن يكون رمز التدفق فارغًا أو يتجاوز 64 حرفًا.",
  "oifde.ivrJourney.timeOutOrPressAnotherKey" : "انتهت المهلة أو اضغط على مفتاح آخر",
  "oifde.dashBoard.graph.percent" : "النسبة المئوية",
  "oifde.sysMgr.encryptSecretNotSame" : "المفاتيح لا تتطابق.",
  "oifde.sysMgr.apiKeyComfirm" : "تأكيد كلمة مرور API",
  "oifde.knowledgegraph.actions.addSchemaEntity" : "إضافة مفهوم",
  "oifde.flow.noRule" : "لم يتم تكوين قاعدة",
  "oifde.serviceInterface.interfaceType" : "طريقة الطلب",
  "oifde.knowledgegraph.entitymgr.entityName" : "اسم الكيان",
  "oifde.route.tuc.ops.server" : "إدارة الخادم",
  "oifde.mxgraph.toFront" : "إلى الأمام",
  "oifde.mxgraph.simpleArrow" : "سهم بسيط",
  "oifde.navbar.success" : "آخر تسجيل دخول ناجح",
  "oifde.rate.dayOption" : "حسب اليوم",
  "oifde.accesscode.validate.flowCode" : "حدد رمز تدفق.",
  "oifde.sysConfig.value" : "قيمة متغيرة",
  "oifde.table.filter" : "فلتر",
  "oifde.chatBotTest.downTestReport" : "تنزيل تقرير الاختبار",
  "oifde.complexcell.cell.desc" : "الوصف",
  "oifde.trackmgr.actions.confirm" : "نعم",
  "oifde.route.tuc.template.domain" : "إدارة النطاقات",
  "oifde.rule.rules.ruleNameValidator" : "اسم القاعدة موجود بالفعل.",
  "oifde.toc.statusCode.ADMIN_DISABLED" : "تم تعطيل وظيفة الإدارة. استخدم حسابًا مشتركًا لتسجيل الدخول إلى النظام.",
  "oifde.mxgraph.lineend" : "نهاية الخط",
  "oifde.testCase.detail.operation" : "العملية",
  "oifde.ivrJourney.slectTimeForever" : "يحدد فترة الصلاحية.",
  "oifde.mxgraph.rightAlign" : "محاذاة إلى اليمين",
  "oifde.operationlog.encrypt_Secret" : "سر التشفير",
  "oifde.template.actions.create" : "إضافة",
  "oifde.rule.message.batch_input_prompt" : "اضغط على Enter بعد كل سجل لإضافته. يمكن أن يحتوي كل سجل على 4 إلى 64 حرفًا.",
  "oifde.sysMgr.encryptSecretCfg" : "إعدادات المفاتيح",
  "oifde.logparam.actions.editLogParamBaseInfo" : "تحرير المعلمة الديناميكية",
  "oifde.template.actions.edit" : "تحرير",
  "oifde.accesscode.more" : "المزيد",
  "oifde.tenantinfo.tenanInfoMgr" : "المعلمات الثابتة",
  "oifde.expression.expr_var_paramName" : "أدخل متغيرًا. ابدأ القيمة بـ GLOBAL (للمتغير العام) أو FLOW (للمتغير التدفق).",
  "oifde.operationlog.offline" : "غير متصل",
  "oifde.common.validation.numberRange" : "نطاق القيمة من {0} إلى {1}.",
  "oifde.procedure.actions.cancel" : "لا",
  "oifde.flow.importFlow.import" : "استيراد",
  "oifde.trace.beginTime" : "وقت البدء",
  "oifde.qiAsrServerMgr.asrServerIP" : "عنوان IP لخادم ASR",
  "oifde.table.cancel" : "إلغاء",
  "oifde.variable.validate.longValueCheck" : "القيمة الافتراضية لبيانات عدد صحيح طويل غير صالحة. تتراوح من -9223372036854775808 إلى 9223372036854775807",
  "oifde.common.validation.appSecretLenCheck" : "يجب أن يحتوي المفتاح المشترك على 8 إلى 20 حرفًا.",
  "oifde.flow.enums.tabsValue.none" : "لا تقم بالتصدير",
  "oifde.flow.usage.lasttime" : "آخر إنجاز:",
  "oifde.testMgr.succeed_save_group" : "تم حفظ مجموعة حالة الاختبار بنجاح.",
  "oifde.flow.usage.resourceType.template" : "قالب",
  "oifde.ivrJourney.keyDistribution" : "توزيع المفاتيح",
  "oifde.asrMgr.path" : "مسار ملف قواعد ASR",
  "oifde.trace.nodeParams" : "معلمات إدخال وإخراج العقدة",
  "oifde.license.totalLicense" : "كمية الترخيص",
  "oifde.operationlog.knowledge_graph_object_attribute" : "سمة علاقة قاموس الرسم البياني",
  "oifde.mxgraph.NMS_BasicProperties" : "السمة الأساسية",
  "oifde.ivrJourney.low" : "منخفض",
  "oifde.testCase.description" : "الوصف",
  "oifde.template.channelTypeMgr" : "إدارة نوع القناة",
  "oifde.mxgraph.basic" : "الأساسية",
  "oifde.qiAsrServerMgr.asrServerInfo" : "معلومات خادم ASR",
  "oifde.route.tuc.history.mining" : "مهمة تجميع الأسئلة",
  "oifde.ivrJourney.repeatCallsNum" : "أوقات استماع متكررة",
  "oifde.mxgraph.NMS_FlowSaveSuccess" : "تم حفظ التدفق بنجاح.",
  "oifde.toc.statusCode.INCORRECT_VERIFYCODE" : "رمز التحقق غير صحيح. أدخل الرقم الصحيح.",
  "oifde.trackmgr.validate.callingNumberLength" : "يمكن أن يحتوي الرقم الطالب على 64 حرفًا كحد أقصى.",
  "oifde.serviceInterface.dataTypes.LONG" : "عدد صحيح طويل",
  "oifde.navbar.failed" : "آخر فشل في تسجيل الدخول",
  "oifde.mxgraph.south" : "الجنوب",
  "oifde.route.tuc.ops.sysparam" : "معلمة المحرك الذكي",
  "oifde.sysMgr.APIKeyLenCheck" : "يجب أن تحتوي كلمة مرور API على 16 إلى 64 حرفًا.",
  "oifde.template.actions.createSuccess" : "تم إنشاء القالب بنجاح.",
  "oifde.accesscode.processTimes" : "عدد المكالمات التي تمت معالجتها بواسطة العامل فقط",
  "oifde.sysConfig.DUMMY_VECTOR_CLASSIFIER.GAMMA.DESC" : "قيمة لوجستية المصنف المقصود بالإنجليزية: Quarter لـ بالإنجليزية: Quarter.",
  "oifde.ivrJourney.editAlarmConfig" : "تحرير خدمة شرط التنبيه المسبق",
  "oifde.mxgraph.none" : "لا شيء",
  "oifde.diagram.NMS_DiagramName" : "اسم التدفق",
  "oifde.template.validate.templateNameLength" : "لا يمكن أن يحتوي اسم القالب على أكثر من 256 حرفًا.",
  "oifde.whitelist.number" : "يجب أن تكون القيمة أرقامًا.",
  "oifde.template.selectAction" : "تحديد",
  "oifde.chatBotTest.abortChatBotTestCase" : "مقاطعة حوار توليد حالة",
  "oifde.knowledgegraph.extendFaq.use" : "استخدام/عدم الاستخدام",
  "oifde.diagram.complexCell.variable" : "عنصر الرسم البياني متغير",
  "oifde.mxgraph.NMS_startNodeNotUnique" : "عقدة البدء ليست فريدة.",
  "oifde.flow.setting.title" : "تعديل",
  "oifde.diagram.error.NMS_rootError" : "فشل تحليل التدفق بسبب تعذر العثور على عقدة الجذر.",
  "oifde.mxgraph.NMS_ScenarioType_main" : "التدفق الرئيسي",
  "oifde.testMgr.success_import" : "تم استيراد الملف بنجاح.",
  "oifde.variable.encryptFlag.zero" : "لا يوجد تشفير",
  "oifde.ivrJourney.IVRCalls" : "حجم الخدمة الذاتية",
  "oifde.ivrJourney.email" : "البريد الإلكتروني",
  "oifde.sysMgr.dataClean.reportFlagDesc" : "ما إذا كان سيتم تسجيل حالات الاختبار إلى تقرير.",
  "oifde.headerTitle.flowManagement" : "إدارة تدفق IVR",
  "oifde.mxgraph.arrow" : "السهم",
  "oifde.mxgraph.NMS_AdvanceProperties" : "سمة متقدمة",
  "oifde.flow.usage.showUnused" : "عرض الموارد غير المستخدمة",
  "oifde.logparam.transFlag" : "تحويل القيمة",
  "oifde.diagram.NMS_FlowVariableOption" : "العملية",
  "oifde.flow.setting.validationModeOn" : "تمكين",
  "oifde.toc.statusCode.INCORRECT_ROLE_NAME" : "اسم الدور غير صحيح.",
  "oifde.variable.actions.cancel" : "لا",
  "oifde.flow.importFlow.error" : "موجه",
  "oifde.trace.errorInfo" : "معلومات الخطأ",
  "oifde.template.validate.nameDup" : "الاسم موجود.",
  "oifde.rule.message.failed_query" : "فشل إجراء الاستعلام.",
  "oifde.navbar.menuClose" : "إخفاء القائمة",
  "oifde.analysis.validate.time" : "التاريخ المطلوب",
  "oifde.flow.actions.invalidConfirm" : "ستؤدي هذه العملية إلى جلب التدفق دون اتصال. هل أنت متأكد من رغبتك في المتابعة؟",
  "oifde.ivrJourney.createAlarmConfig" : "إنشاء شرط التنبيه المسبق للخدمة",
  "oifde.toc.statusCode.INCORRECT_ACCOUNT" : "الحساب غير صحيح.",
  "oifde.accesscode.actions.deleteSuccess" : "تم الحذف بنجاح.",
  "oifde.fileTask.DOWNLOAD_EXCEPTION" : "تمت مقاطعة التنزيل.",
  "oifde.common.dialog.confirm.cancelInfo" : "تم إلغاء الحذف.",
  "oifde.operationlog.log_open_task" : "مهمة تمكين السجل",
  "oifde.ivrJourney.afterRelease" : "بعد الإفراج",
  "oifde.knowledgegraph.extendFaq.description" : "الوصف",
  "oifde.flow.name" : "الاسم",
  "oifde.logparam.validate.targetValueLength" : "الحد الأقصى للطول 64",
  "oifde.flow.rules.newFlowName" : "حدد اسم تدفق جديد.",
  "oifde.analysis.chart.timeOther" : "انتهت المهلة أو اضغط على مفتاح آخر",
  "oifde.dashBoard.graph.kg" : "الرسم البياني",
  "oifde.trackmgr.actions.deletePrompt" : "ستؤدي هذه العملية إلى حذف بيانات المسار نهائيًا. هل أنت متأكد من رغبتك في المتابعة؟",
  "oifde.common.selectPlaceHolder" : "أدخل كلمة أساسية.",
  "oifde.qiAsrServerMgr.asrServerUser" : "مستخدم خادم ASR",
  "oifde.sysMgr.dataClean.serviceName" : "اسم المعلمة",
  "oifde.ivrJourney.selectAlarmCondition" : "يرجى تحديد شرط التنبيه المراد حذفه.",
  "oifde.operationlog.use": "مستعملة",
  "oifde.mxgraph.NMS_EditorModelLoadFail": "فشل تحميل تكوين طراز المحرر.",
  "oifde.dashBoard.pie.kgTitle": "الرسم البياني",
  "oifde.operationlog.knowledge_graph_schema_entity": "كيان مخطط المعرفة",
  "oifde.operationlog.binding_domain": "مجال Chatbot",
  "oifde.operationlog.MRCPServerManagement": "إدارة الخادم",
  "oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.NAME": "وحدة المعالجة المسبقة الصينية",
  "oifde.ivrJourney.serviceRate": "معدل الخدمة الفعال",
  "oifde.mxgraph.documentProperties": "خصائص المستند",
  "oifde.testMgr.add_test_case_group": "إضافة مجموعة حالة الاختبار",
  "oifde.expression.varToc": "متغير القصد",
  "oifde.knowledgegraph.actions.addSynonyms": "إضافة مرادف",
  "oifde.mxgraph.NMS_SpaceValidate": "لا يمكن أن تبدأ القيمة بمسافة.",
  "oifde.mxgraph.horizontal": "أفقي",
  "oifde.rate.interfaceRequestCount": "استدعاءات الواجهة",
  "oifde.route.tuc.ops.kgrule": "قاعدة المعرفة الرمادية",
  "oifde.procedure.dataTypes.LONG": "عدد صحيح طويل",
  "oifde.flow.actions.deleteOk": "تم حذف التدفق بنجاح.",
  "oifde.flow.importFlow.timeout": "جاري استيراد البيانات... يُرجى الاستعلام عن أحدث البيانات لاحقًا.",
  "oifde.rate.callStaffNoConsultCount": "المكالمات التي تتم معالجتها بواسطة الموظف فقط",
  "oifde.knowledgegraph.extendFaq.reply": "الرد",
  "oifde.flow.publish.gray" : "الإصدار الرمادي",
  "oifde.hisdialog.direction.DOWNLOAD" : "نص الرد",
  "oifde.dashBoard.graph.intent" : "النية",
  "oifde.trackmgr.actions.prompt" : "تأكيد",
  "oifde.accesscode.callingnumber" : "الرقم المتصل:",
  "oifde.trackmgr.actions.success" : "النجاح",
  "oifde.mxgraph.addWaypoint" : "إضافة نقطة مسار",
  "oifde.route.knowledgegraph.kgExport" : "تصدير الرسم البياني",
  "oifde.knowledgegraph.errorCode.100010000005" : "معلمة الإدخال غير صحيحة.",
  "oifde.knowledgegraph.errorCode.100010000003" : "يجب أن يكون الاسم فريدًا.",
  "oifde.knowledgegraph.errorCode.100010000002" : "خطأ في التنسيق.",
  "oifde.knowledgegraph.errorCode.100010000001" : "خطأ في قاعدة البيانات.",
  "oifde.knowledgegraph.errorCode.100010000000" : "خطأ في النظام.",
  "oifde.flow.enums.status.valid" : "تم النشر",
  "oifde.mxgraph.editImage" : "تحرير الصورة",
  "oifde.knowledgegraph.model.deletePrompt" : "ستقوم هذه العملية بحذف المخطط والمفاهيم والعلاقات والسمات المرتبطة به بشكل دائم. هل أنت متأكد من رغبتك في المتابعة؟",
  "oifde.knowledgegraph.dicmgr.schemaName" : "المخطط المرتبط",
  "oifde.rule.actions.errorCode" : "رمز الخطأ",
  "oifde.mxgraph.insertColumnAfter" : "إدراج يمين العمود",
  "oifde.flow.actions.setting" : "تعيين",
  "oifde.asrMgr.mrcpid" : "معرف مجموعة ASR",
  "oifde.serviceInterface.selfDefinedInterface" : "واجهة معرّفة ذاتيًا",
  "oifde.mxgraph.apply" : "تطبيق",
  "oifde.mxgraph.renameIt" : "إعادة تسمية {0}",
  "oifde.mxgraph.clearDefaultStyle" : "مسح النمط الافتراضي",
  "oifde.navbar.password.firstTimeLogin" : "أنت تستخدم كلمة المرور الأولية. قم بتغييرها.",
  "oifde.rule.message.user_id_not_exceed" : "يمكن أن يحتوي كل رقم مشترك على 4 إلى 64 حرفًا.",
  "oifde.error.gateway.fail" : "فشل مزامنة المعلومات الخاصة بالنظام الأساسي CTI. يُرجى التحقق من تكوين البوابة.",
  "oifde.mxgraph.open" : "مفتوح",
  "oifde.asrMgr.languageTips" : "الافتراضي: يشير إلى أن اللغة غير متمايزة.",
  "oifde.operationlog.startTime" : "وقت البدء",
  "oifde.sysMgr.dataClean.traceLogAutoClose" : "تحرير وقت التعطيل التلقائي للتتبع",
  "oifde.accesscode.callednumber" : "الرقم المطلوب:",
  "oifde.ivrJourney.IVRTotalCalls" : "عدد مكالمات IVR الواردة",
  "oifde.audioVideoResource.updateAfter" : "بعد التحديث",
  "oifde.flow.actions.publishOk" : "تم نشر التدفق بنجاح.",
  "oifde.accesscode.validate.doubleAccessCodeCheck" : "رمز الوصول موجود بالفعل.",
  "oifde.operationlog.result" : "النتيجة",
  "oifde.knowledgegraph.editSchema.unit" : "وحدة",
  "oifde.complexcell.cell.catalog" : "كتالوج",
  "oifde.sysConfig.UIR_VECTOR_ENERGY_CHECKER.THR.DESC" : "تعتبر النوايا غير معروفة إذا كان مجموع جميع طاقات النية المكتشفة أقل من أو يساوي العتبة.",
  "oifde.common.dialog.confirm.buttonSure" : "نعم",
  "oifde.mxgraph.about" : "حول",
  "oifde.mxgraph.NMS_FlowsGetFail" : "فشل الحصول على قائمة التدفق.",
  "oifde.tenantinfo.serviceName" : "الاسم",
  "oifde.common.browserSupporteMsg" : "المتصفح لا يدعم هذه الوظيفة.",
  "oifde.mxgraph.NMS_timeSelectLengthValidate" : "يجب تكوين سجل واحد على الأقل لفترة الخدمة.",
  "oifde.sysConfig.CRF_PARAM_EXTRACTOR.LEARNING_RATE.NAME" : "معدل التعلم",
  "oifde.common.validation.required" : "هذا الحقل إلزامي",
  "oifde.flow.usage.resourceType.complexcell" : "عنصر مخطط مركب",
  "oifde.ivrJourney.halfMonth" : "نصف شهر",
  "oifde.mxgraph.horizontalTree" : "شجرة أفقية",
  "oifde.accesscode.domain" : "المجال",
  "oifde.analysis.chart.button" : "مفتاح الصحافة",
  "oifde.testMgr.failed_import_file" : "فشل استيراد الملف.",
  "oifde.logparam.validate.doublelogNameCheck" : "اسم المعلمة الديناميكية موجود بالفعل.",
  "oifde.accesscode.domainId" : "معرف المجال",
  "oifde.maintenance.stop_success" : "تمكين السجل",
  "oifde.operationlog.operator" : "الحساب",
  "oifde.knowledgegraph.actions.addExtQuestion" : "سؤال موسع جديد",
  "oifde.trace.clickToFlow" : "عرض المسار",
  "oifde.navbar.action.successMessage" : "تم تبديل المستخدم بنجاح.",
  "oifde.ivrJourney.totalDistribution" : "التوزيع العام",
  "oifde.mxgraph.NMS_InterfacesGetFail" : "فشل الحصول على قائمة واجهة الوصول.",
  "oifde.testMgr.expected_output_with_memo" : "الكلمات الرئيسية المتوقعة للإخراج أو أسماء النوايا (فصل الإدخالات المتعددة بفواصل)",
  "oifde.route.tuc.ops.log" : "سجل التشغيل",
  "oifde.qiAsrServerMgr.ipError" : "عنوان IP غير صحيح.",
  "oifde.operationlog.change_password" : "تغيير كلمة المرور",
  "oifde.license.sys" : "النظام",
  "oifde.operationlog.targetObjId" : "معرف الكائن",
  "oifde.trace.nodeIndex" : "رقم عقدة التنفيذ",
  "oifde.trace.sessionLog" : "استعلام عن سجل الحوار",
  "oifde.complexcell.cell.icon" : "رمز",
  "oifde.mxgraph.insert" : "إدراج",
  "oifde.mxgraph.arc" : "قوس",
  "oifde.diagram.NMS_FlowVariableEncryptTypeWarn" : "* لاحظ أن هذا المتغير يتم تخزينه في نص عادي عندما يكون نوع التشفير غير مشفر. لا تقم بتعيين بيانات حساسة لهذا المتغير.",
  "oifde.operationlog.stop_words" : "كلمة التوقف",
  "oifde.ivrJourney.optimizeVoiceDefinition" : "تحسين تعريف الصوت",
  "oifde.flow.publish.flowName" : "الاسم",
  "oifde.analysis.validate.timeInterval" : "الفاصل الزمني لا يزيد عن 7 أيام.",
  "oifde.sysMgr.dataClean.paramValueTca" : "قيمة المعلمة",
  "oifde.journeylogmgr.flowAnalysisMrg" : "إعدادات تحليل التدفق",
  "oifde.operationlog.test_case_group" : "مجموعة حالة الاختبار",
  "oifde.diagram.error.NMS_startNodeNotBranchError" : "يمكن أن تحتوي عقدة البدء على فرع واحد فقط.",
  "oifde.serviceInterface.jsonValidate" : "لا يتوافق نص الرسالة مع تنسيق JSON القياسي.",
  "oifde.dashBoard.chartView.leftTile" : "إجمالي الردود",
  "oifde.rule.update.endNum" : "رقم النهاية",
  "oifde.knowledgegraph.kgView.displayType" : "وضع العرض",
  "oifde.rate.callCountBoard" : "لوحة معلومات إحصائيات المكالمات",
  "oifde.ivrJourney.playingOrder" : "تسلسل بث الصوت الموصى به",
  "oifde.route.unusedResource.complexcell" : "عرض عناصر المخطط المركب غير المستخدمة",
  "oifde.template.typeDetail.videovoice" : "ملف الفيديو",
  "oifde.rate.interactCount" : "جولات الحوار",
  "oifde.ivrJourney.dateTimrRange" : "نطاق البيانات الزمني:",
  "oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.NS.NAME" : "قيمة الحد الأدنى N",
  "oifde.route.knowledgegraph.relationAttribute" : "سمة العلاقة لـ {0}",
  "oifde.procedure.out_param" : "معلمة الإخراج",
  "oifde.operationlog.succeed" : "النجاح",
  "oifde.mxgraph.copyConnect" : "نسخ اتصال",
  "oifde.mxgraph.custom" : "مخصص",
  "oifde.serviceInterface.innerInterfaceDetails" : "تفاصيل الواجهة الداخلية",
  "oifde.navbar.password.confirmNewPassword" : "تأكيد كلمة المرور",
  "oifde.rule.actions.delete" : "حذف",
  "oifde.diagram.error.NMS_endNodeNotFind" : "لم يتم العثور على عقدة النهاية.",
  "oifde.mxgraph.insertColumnBefore" : "إدراج العمود إلى اليسار",
  "oifde.sysConfig.CRF_PARAM_EXTRACTOR.NUM_TREES.DESC" : "عدد الأشجار في خوارزمية GBRT.",
  "oifde.sysMgr.dataClean.confidence" : "تعديل الثقة",
  "oifde.logparam.transFlagDetail.NO" : "لا",
  "oifde.route.unusedResource.flowvars" : "عرض متغيرات التدفق غير المستخدمة",
  "oifde.ivrJourney.remindType" : "وضع التذكير",
  "oifde.mxgraph.insertRowBefore" : "إدراج صف أعلاه",
  "oifde.dashBoard.graph.hitTitle" : "ضربات",
  "oifde.ivrJourney.IVRErrorRate" : "معدل خطأ نظام IVR (%)",
  "oifde.common.validation.sessionDTRange" : "يتراوح الفاصل الزمني لمهلة تدفق مربع الحوار من {0} إلى {1} ثانية.",
  "oifde.rule.actions.updateSuccess" : "تم تعديل القاعدة بنجاح.",
  "oifde.mxgraph.NMS_nullBranchNotNuique" : "يمكن أن تحتوي العقدة على فرع افتراضي واحد فقط.",
  "oifde.mxgraph.fontColor" : "لون الخط",
  "oifde.mxgraph.sharp" : "شارب",
  "oifde.hisdialog.direction.UPLOAD" : "أدخل نص الطلب.",
  "oifde.robotReply.replyVariable" : "رد متغير",
  "oifde.mxgraph.spacing" : "تباعد",
  "oifde.operationlog.query" : "استعلام",
  "oifde.accesscode.actions.testCalledNum" : "اختبار مكالمة",
  "oifde.mxgraph.NMS_NodeProperties" : "سمة العقدة",
  "oifde.sysMgr.sysCfg" : "إعدادات النظام",
  "oifde.template.editChannelType" : "تحرير القناة",
  "oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.BORDER_EXT_ENABLE.FALSE" : "تعطيل",
  "oifde.mxgraph.rotateTooltip" : "انقر واسحب للتدوير، وانقر للتدوير بمقدار 90 درجة",
  "oifde.accesscode.accessCode" : "رمز الوصول إلى Chatbot",
  "oifde.chatBotTest.botAccessCode" : "رمز الوصول إلى Chatbot",
  "oifde.mxgraph.new" : "جديد",
  "oifde.mxgraph.posterPrint" : "طباعة الملصق",
  "oifde.mxgraph.hide" : "إخفاء",
  "oifde.trackmgr.actions.add" : "إضافة",
  "oifde.mxgraph.borderWidth" : "عرض الحدود",
  "oifde.operationlog.authorize" : "شهادة",
  "oifde.ivrJourney.transToNextLevelManuCalls" : "تتدفق إلى القوائم ذات المستوى الأدنى",
  "oifde.complexcell.cell.actions.copy" : "تكرار",
  "oifde.toc.statusCode.ACCOUNT_NOT_FOUND" : "الحساب غير موجود.",
  "oifde.template.validate.doubleNameCheck" : "اسم القالب موجود بالفعل.",
  "oifde.testMgr.event" : "الحدث",
  "oifde.whitelist.tenantId" : "معرف مساحة المستأجر",
  "oifde.testCase.editTestCase" : "تعديل حالة الاختبار",
  "oifde.serviceInterface.miss_ifs_name" : "الحقل الإلزامي التالي فارغ: معلومات أساسية > اسم الواجهة.",
  "oifde.flow.publish.ruleName" : "القاعدة الرمادية",
  "oifde.dashBoard.pie.title" : "ضرب توزيع المعرفة",
  "oifde.flow.enums.tabsValue.all" : "تصدير الكل",
  "oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.BORDER_EXT_ENABLE.DESC" : "ما إذا كان سيتم إضافة علامات تمييز قبل الجملة وبعدها عند استخراج ميزة N-Gram.",
  "oifde.knowledgegraph.actions.editAttribute" : "تحرير السمة",
  "oifde.route.RobotManage" : "Chatbot ذكي",
  "oifde.logparam.sourceValue" : "قيمة المصدر",
  "oifde.license.LIN8OIAPAA01" : "عدد مساعدي الوكلاء المتزامنين",
  "oifde.template.select" : "النوع",
  "oifde.ivrJourney.visitorTotal" : "الزيارات",
  "oifde.complexcell.cell.width" : "العرض",
  "oifde.route.procedure" : "الإجراء المخزن",
  'oifde.route.dataTable': 'جدول داده',
  "oifde.trackmgr.actions.cancel" : "لا",
  "oifde.mxgraph.shape" : "الشكل",
  "oifde.analysis.chart.title" : "إحصائيات فرع عقدة IVR",
  "oifde.variable.actions.confirm" : "نعم",
  "oifde.ivrJourney.transManualSuccCalls" : "عدد المكالمات الناجحة المحولة إلى خدمات يدوية",
  "oifde.audioVideoResource.templateName" : "أدخل اسم قالب.",
  "oifde.mxgraph.addProperty" : "إضافة خاصية",
  "oifde.serviceInterface.actions.cancelSuccess" : "تم إلغاء الحفظ!",
  "oifde.knowledgegraph.editSchema.attributeName" : "اسم السمة",
  "oifde.template.contentName" : "المحتوى",
  "oifde.procedure.ruleOracle" : "يجب أن يبدأ اسم قاعدة بيانات Oracle بـ jdbc:oracle.",
  "oifde.trackmgr.robotsSource" : "المصدر",
  "oifde.trace.createTime" : "تم الإنشاء",
  "oifde.serviceInterface.dataTypes.STRING" : "الحرف",
  "oifde.mxgraph.top" : "أعلى",
  "oifde.sysConfig.updateAll" : "تطبيق كافة المعلمات",
  "oifde.ivrJourney.resolveRate" : "معدل دقة IVR لمرة واحدة",
  "oifde.logparam.message.validateFailed" : "فشل التحقق.",
  "oifde.qiAsrServerMgr.addQiAsrServer" : "إضافة معلومات خادم QC ASR",
  "oifde.ivrJourney.selectAlramRemindType" : "حدد وضع تذكير واحد على الأقل.",
  "oifde.ivrJourney.compareRange" : "نطاق المقارنة",
  "oifde.knowledgegraph.kgView.queryByName" : "حسب اسم الكيان",
  "oifde.testMgr.descriptionLen" : "يمكن أن يحتوي الوصف على 1024 حرفًا كحد أقصى.",
  "oifde.template.voicename.cn_roumeiqian_common" : "لينة",
  "oifde.accesscode.actions.edit" : "تحرير",
  "oifde.ivrJourney.repeatNum" : "أسمع",
  "oifde.mxgraph.middle" : "محاذاة أفقية",
  "oifde.mxgraph.home" : "الصفحة الرئيسية",
  "oifde.toc.statusCode.COMMON_ERROR" : "خطأ في الخادم.",
  "oifde.rule.actions.deleteFailed" : "فشل الحذف. تحقق مما إذا كانت القاعدة مقترنة بتدفق تحرير. قم بإلغاء الإصدار أولاً.",
  "oifde.operationlog.set_white_list" : "تعيين القواعد الرمادية",
  "oifde.qiAsrServerMgr.fileServerPort" : "منفذ خادم الملفات",
  "oifde.ivrJourney.returnKeysByErrorsDesc" : "في عقدة التنقل، بعد الضغط على المفتاح بشكل غير صحيح، يتم تحديد الرقم الذي تم إرجاعه بواسطة مفتاح النجمة (*) كما هو مطلوب",
  "oifde.operationlog.user_role" : "الدور",
  "oifde.knowledgegraph.kgSynonyms.type.type" : "النوع",
  "oifde.template.typeDetail.staticvoice" : "صوت ثابت",
  "oifde.logparam.validate.logNameLength": "يمكن أن يحتوي اسم المعلمة الديناميكية على 64 حرفًا كحد أقصى.",
  "oifde.toc.statusCode.SERVICE_UNAVAILABLE": "الخدمة غير متوفرة حاليًا.",
  "oifde.title.chooseGrayRule": "اختر القاعدة الرمادية",
  "oifde.accesscode.bindFaqGroup": "ربط الأسئلة الشائعة المجموعة",
  "oifde.mxgraph.NMS_ScenarioType_sub": "التدفق الفرعي",
  "oifde.rule.message.name_list_info": "تفاصيل قائمة الحظر/القائمة الاستئمانية",
  "oifde.common.validation.charRegWithDot": "يمكن أن تحتوي القيمة على أحرف صينية فقط وحروف إنجليزية وأرقام ونقاط (.), تسطير أسفلات (_), والواصلات (-).",
  "oifde.diagram.error.NMS_nmsFlowError": "فشل تحليل التدفق لأنه لا يمكن العثور على عقدة nmsFlow.",
  "oifde.variable.validate.objectValueCheck": "تنسيق قيمة بيانات الكائن الافتراضي غير صحيح. يجب أن تكون القيمة في {\'{\'}\"key1\":\"value1\",\"key2\":\"value2\"{\'}\'}, ويمكن أن تكون القيمة {\'{\'}{\'}\'}.",
  "oifde.mxgraph.options": "خيارات",
  "oifde.mxgraph.NMS_NodesGetFail": "فشل الحصول على قائمة عقدة التدفق {0}.",
  "oifde.rule.number": "الكمية",
  "oifde.sysMgr.dataClean.day": "أيام",
  "oifde.trackmgr.accessCode": "رمز الوصول",
  "oifde.title.chatbot": "دردشة بوت",
  "oifde.ivrJourney.reverseFlow": "اتجاه التدفق العكسي",
  "oifde.procedure.procedureName": "الإجراء المخزن",
  "oifde.rule.message.user_number": "رقم المشترك",
  "oifde.flowname.maxlength": "اسم الروبوت طويل جدا.",
  "oifde.rule.update.beginNum": "رقم البدء",
  "oifde.knowledgegraph.actions.editRelation": "تحرير العلاقة",
  "oifde.mxgraph.insertHorizontalRule": "إدراج قاعدة أفقية",
  "oifde.mxgraph.selectAll": "تحديد الكل",
  "oifde.error.NMS.fail": "فشلت المزامنة مع NMS!",
  "oifde.operationlog.intent_template": "قالب النية",
  "oifde.variable.actions.editVariableBaseInfo": "تحرير المتغير",
  "oifde.sysMgr.dataClean.editFlowMaxElements": "تحرير ماكس. عناصر الرسم البياني المدعومة من تدفق IVR",
  "oifde.operationlog.response_style": "نمط",
  "oifde.variable.validate.doubleVariableNameCheck": "اسم المتغير موجود بالفعل.",
  "oifde.flow.usage.check": "تحقق",
  "oifde.knowledgegraph.kgSynonyms.synonyms": "مرادف",
  "oifde.rule.kgRule": "قاعدة المعرفة الرمادية",
  "oifde.sysConfig.CRF_PARAM_EXTRACTOR.MAX_NODES.NAME": "شجرة عقدة الكمية",
  "oifde.flow.publish.insert": "إدراج",
  "oifde.serviceInterface.pathParamValue": "القيمة",
  "oifde.route.trace": "تتبع التدفق",
  "oifde.toc.statusCode.ACCOUNT_FORBIDDEN": "العمليات على هذا الحساب ممنوعة.",
  "oifde.knowledgegraph.actions.editDic": "تحرير قاموس الرسم البياني",
  "oifde.mxgraph.oval": "البيضاوي",
  "oifde.mxgraph.removeIt": "إزالة {0}",
  "oifde.knowledgegraph.editSchema.dataType.int": "عدد صحيح",
  "oifde.dashBoard.graph.faq": "الأسئلة الشائعة",
  "oifde.procedure.ruleLength": "أدخل عنوان IP ورقم المنفذ في عنوان URL.",
  "oifde.flow.rules.newVersion": "حدد إصدارًا جديدًا.",
  "oifde.variable.validate.variableNameRange": "يُسمح فقط بالحروف والأرقام والشرطات السفلية (_).",
  "oifde.route.knowledgegraph.entityAttribute": "سمة الكيان لـ {0}",
  "oifde.mxgraph.paperSize": "حجم الورق",
  "oifde.template.selectResponseStyle": "حدد نمطًا.",
  "oifde.knowledgegraph.model.operation": "العملية",
  "oifde.serviceInterface.prompt": "تأكيد",
  "oifde.serviceInterface.encryptFlag": "مشفرة",
  "oifde.mxgraph.topAlign": "محاذاة أعلى",
  "oifde.ivrJourney.remindObjectMaxNum": "يمكن تحديد 20 حرفًا كحد أقصى لكائن الإعلام.",
  "oifde.navbar.tenantName": "المستأجر الحالي",
  "oifde.rule.actions.cancelDelete": "تم إلغاء الحذف.",
  "oifde.ivrJourney.chooseAccessCode": "تحديد روبوت",
  "oifde.route.toc.sysadmin": "الفهم الدلالي",
  "oifde.operationlog.history_dialogue": "حوار تاريخي",
  "oifde.mxgraph.moreResults": "المزيد من النتائج",
  "oifde.template.update.templateDesc": "الوصف",
  "oifde.ivrJourney.indicatorSettingLengthError": "حدد مؤشرات {count} على الأقل.",
  "oifde.expression.varSys": "متغير النظام",
  "oifde.mxgraph.NMS_Valid": "صالح",
  "oifde.knowledgegraph.actions.clean": "مسح",
  "oifde.knowledgegraph.kgView.queryType": "نوع الاستعلام",
  "oifde.mxgraph.add": "إضافة",
  "oifde.flow.rules.upgradeMode": "حدد وضع النشر.",
  "oifde.knowledgegraph.actions.edit": "تحرير",
  "oifde.flow.createTime": "تم الإنشاء",
  "oifde.accesscode.actions.flowChatbot": "اختبار التدفق Chatbot",
  "oifde.audioVideoResource.upload.tips": "تلميح:رفع الملف نفسه بشكل متكرر\، أو غيّر اسم الملف\، أو تبديل إيداع الملف.",
  "oifde.knowledgegraph.dicmgr.deletePrompt": "ستؤدي هذه العملية إلى حذف القاموس والكيانات والعلاقات والسمات المرتبطة به بشكل دائم. هل أنت متأكد من رغبتك في المتابعة؟",
  "oifde.operationlog.guideflow": "تدفق معالج الإنشاء",
  "oifde.route.variable": "إدارة المتغيرات",
  "oifde.accesscode.robotName": "اسم Chatbot",
  "oifde.diagram.error.NMS_branchError": "يجب أن يحتوي الفرع على عقدة البدء وعقدة النهاية.",
  "oifde.knowledgegraph.extendFaq.setting": "تعيين سؤال موسع",
  "oifde.operationlog.upgrade": "الترقية",
  "oifde.mxgraph.NMS_Status": "الحالة",
  "oifde.toc.statusCode.INCORRECT_TITLE": "لا يمكن أن يتجاوز الطول 8 أحرف.",
  "oifde.sysMgr.apiKeyNotSame": "كلمات مرور API غير متطابقة.",
  "oifde.complexcell.cell.name": "اسم عنصر الرسم البياني",
  "oifde.procedure.actions.miss_pro_in_parameter_name": "الحقل الإلزامي التالي فارغ: معلمة الإدخال > اسم المعلمة.",
  "oifde.navbar.action.changePasswordSuccess": "تم تغيير كلمة المرور بنجاح.",
  "oifde.knowledgegraph.kgSynonyms.deletePrompt": "ستقوم هذه العملية بحذف الإدخال وكافة المرادفات الخاصة به بشكل دائم. هل أنت متأكد من رغبتك في المتابعة؟",
  "oifde.accesscode.bindDomain": "ربط المجال",
  "oifde.testCase.detail.reqText": "نص الطلب",
  "oifde.accesscode.actions.success": "النجاح",
  "oifde.whitelist.addWhiteList": "إضافة قائمة ثقة",
  "oifde.expression.expr_var_string": "أدخل متغيراً من نوع السلسلة. ابدأ القيمة بـ GLOBAL (للمتغير الشامل) أو FLOW (للمتغير التدفق) أو SYS (للمتغير النظام) أو TOC (للمتغير TUC) أو IVRREQUEST (للمتغير طلب IVR).",
  "oifde.common.validation.confidenceRange": "تتراوح حد الثقة من {0} إلى {1}.",
  "oifde.operationlog.system_entity": "كيان النظام",
  "oifde.diagram.NMS_ModifyVariable": "تعديل المتغير",
  "oifde.mxgraph.fitTwoPages": "صفحتين",
  "oifde.table.create": "إنشاء",
  "oifde.chatBotTest.checkPrompt": "قد يؤدي تشغيل حالات اختبار متعددة في وقت واحد إلى إبطاء النظام. هل أنت متأكد من رغبتك في المتابعة؟",
  "oifde.accesscode.actions.editbaseInfo": "تعديل Chatbot",
  "oifde.knowledgegraph.editSchema.type": "النوع",
  "oifde.logparam.source": "مصدر المعلمة",
  "oifde.ivrJourney.validIVRService": "خدمة IVR صالحة",
  "oifde.operationlog.enable": "تفعيل",
  "oifde.knowledgegraph.dicmgr.kgView": "الرسم البياني التصور",
  "oifde.dashBoard.graph.countTitle": "المجموع",
  "oifde.mxgraph.fitPageWidth": "عرض الصفحة",
  "oifde.mxgraph.hideIt": "إخفاء {0}",
  "oifde.procedure.actions.editList": "تحرير نوع البيانات في القائمة",
  "oifde.variable.encryptType": "نوع التشفير",
  "oifde.flow.importFlow.uploadFail": "فشل إيداع الملف.",
  "oifde.mxgraph.NMS_endNodetransitToError": "يتعذر على عقدة النهاية الانتقال إلى عقدة أخرى.",
  "oifde.diagram.error.NMS_NodeNameError": "لا يمكن أن يكون اسم العقدة فارغًا أو يتجاوز 1000 حرف.",
  "oifde.accesscode.actions.callforwarding": "تحويل المكالمات",
  "oifde.route.resources": "إدارة الموارد",
  "oifde.mxgraph.angle": "زاوية",
  "oifde.knowledgegraph.editSchema.dataType.float": "رقم النقطة العائمة",
  "oifde.navbar.menuOpen": "إظهار القائمة",
  "oifde.template.message.intentListLength": "يمكن أن تحتوي قائمة النوايا على 4096 حرفًا بحد أقصى.",
  "oifde.template.actions.success": "النجاح",
  "oifde.variable.actions.success": "النجاح",
  "oifde.diagram.error.NMS_flowCodeError": "لا يمكن أن يكون رمز التدفق فارغًا أو يتجاوز 64 حرفًا.",
  "oifde.toc.statusCode.VERIFYCODE_EXPIRED": "انتهت صلاحية رمز التحقق. حاول مرة أخرى لاحقًا.",
  "oifde.mxgraph.search": "البحث",
  "oifde.ivrJourney.timeRangeCheck30": "لا يمكن أن يتجاوز الفاصل الزمني 30 يومًا.",
  "oifde.logparam.validate.sourceNameLength": "يمكن أن يحتوي اسم مصدر المعلمة على 64 حرفًا كحد أقصى.",
  "oifde.tagsView.close": "إغلاق",
  "oifde.accesscode.traceOpen": "تمكين",
  "oifde.testMgr.noFaqGroup": "لم يتم تحديد مجموعة أسئلة شائعة.",
  "oifde.knowledgegraph.editSchema.deleteEntityPrompt": "ستقوم هذه العملية بحذف المفهوم وسماته نهائيًا. هل أنت متأكد من رغبتك في المتابعة؟",
  "oifde.knowledgegraph.editSchema.schemaNoDataTip": "إضافة مفهوم وبيانات العلاقة في الجزء الأيسر. لعرض محتوى السمة الخاصة به، انقر فوق عرض على يمين المفهوم أو العلاقة المقابلة.",
  "oifde.title.chooseFlow": "اختر تدفق",
  "oifde.headerTitle.flowConfiguration": "تكوين تدفق IVR",
  "oifde.mxgraph.north": "الشمال",
  "oifde.common.time.endDate": "تاريخ الانتهاء",
  "oifde.mxgraph.NMS_actionParamValueError": "يمكن أن تحتوي قيمة المعلمة على 4096 حرفًا بحد أقصى.",
  "oifde.sysMgr.dataClean.operation": "العملية",
  "oifde.flow.usage.status.finish": "اكتملت",
  "oifde.complexcell.cell.viewReference": "عرض المراجع",
  "oifde.qiAsrServerMgr.fileServerType": "نوع خادم الملفات",
  "oifde.testCase.caseDetailMgr": "إدارة تفاصيل الحالة",
  "oifde.sysMgr.dataClean.record": "سجل",
  "oifde.mxgraph.zoomOut": "التصغير",
  "oifde.rule.update.newBeginNum": "أدخل رقم البدء.",
  "oifde.ivrJourney.nowData": "القيمة الحالية",
  "oifde.route.knowledgegraph.knowledgegraph": "الرسم البياني للمعرفة",
  "oifde.mxgraph.publishFailed": "فشل في حفظ التدفق ونشره.",
  "oifde.mxgraph.changeOrientation": "تغيير الاتجاه",
  "oifde.knowledgegraph.editSchema.tailEntity": "مفهوم الذيل",
  "oifde.mxgraph.NMS_ServiceName": "اسم الخدمة",
  "oifde.operationlog.trace_log_query": "تتبع السجل والاستعلام",
  "oifde.variable.ttsPitchDesc": "استخدم هذا المتغير لتعيين تردد TTS الأساسي للمستأجر.",
  "oifde.sysConfig.DUMMY_VECTOR_CLASSIFIER.GAMMA.NAME": "γ",
  "oifde.rule.message.fail_delete": "فشل حذف قائمة الحظر/قائمة الثقة.",
  "oifde.mxgraph.hidden": "مخفي",
  "oifde.common.validation.slientHelpRange": "يتراوح وقت انتظار الرد بعد أن يكون الموظف صامتًا من {0} إلى {1} ثانية.",
  "oifde.mxgraph.NMS_TargetFlow": "تدفق الهدف",
  "oifde.ivrJourney.IVRErrorRateNoSymbol": "معدل خطأ نظام IVR",
  "oifde.trace.clickToDetail": "انقر لإظهار المزيد.",
  "oifde.route.viewCellTraceDiagram": "عرض تتبع عنصر المخطط المركب",
  "oifde.mxgraph.collapse-expand": "الانهيار/التوسيع",
  "oifde.mxgraph.rename": "إعادة تسمية",
  "oifde.serviceInterface.jsonValidateSuccess": "التنسيق صحيح.",
  "oifde.mxgraph.style": "نمط",
  "oifde.ivrJourney.buttons": "زر",
  "oifde.testCase.name": "اسم حالة الاختبار",
  "oifde.ivrJourney.repeatTwiceCalls": "السمع مرتين",
  "oifde.ivrJourney.selectAlarmLevel": "يرجى تحديد مستوى تحذير.",
  "oifde.rule.actions.deleteSuccess": "تم حذف القاعدة بنجاح.",
  "oifde.mxgraph.pages": "الصفحات",
  'oifde.diagram.message.recoverData': 'استعادة البيانات',
  'oifde.diagram.message.recoverDataDesc': 'هل تريد استخدام آخر بيانات عملية تم حفظها مؤقتًا لتصيير اللوحة القماشية؟',
  'oifde.route.workDay': 'تقويم العمل',
  'oifde.workday.workDayName': 'اسم تقويم العمل',
  'oifde.workday.workDayDesc': 'الوصف',
  'oifde.workday.workWeekDay': 'ختيار الأسبوع',
  'oifde.workday.servicePeriod': 'فترة الخدمة',
  'oifde.workday.specificNonServiceDay': 'تاريخ عدم الخدمة المحدد',
  'oifde.workday.specificServiceDay': 'تاريخ الخدمة المحدد',
  'oifde.workday.servicePeriodDesc': 'فترة الخدمة. تكوين 1 إلى 10 سجلات',
  'oifde.workday.specificNonServiceTip': 'في حالة تكوين تاريخ غير خدمة محدد، يُعتبر تاريخ غير خدمة غير يوم عمل ذي أولوية قصوى.',
  'oifde.workday.specificServiceTip': 'في حالة عدم توفر تاريخ خدمة محدد تم تكوينه في اختيار الأسبوع، يتم اعتبار تاريخ الخدمة يوم عمل.',
  'oifde.workday.name': 'الاسم',
  'oifde.workday.time': 'الوقت',
  'oifde.workday.date': 'التاريخ',
  'oifde.workday.addWorkDayTitle': 'إضافة تقويم عمل',
  'oifde.workday.editWorkDayTitle': 'تعديل تقويم العمل',
  'oifde.workday.required': 'هذا الحقل إلزامي.',
  'oifde.workday.updateTime': 'تم التعديل',
  'oifde.workday.operation': 'العملية',
  'oifde.workday.actions.edit': 'تحرير',
  'oifde.workday.actions.delete': 'حذف',
  'oifde.workday.actions.prompt': 'تأكيد',
  'oifde.workday.actions.confirm': 'نعم',
  'oifde.workday.actions.cancel': 'لا',
  'oifde.workday.actions.ok': 'حسناً',
  'oifde.workday.actions.deletePrompt': 'ستؤدي هذه العملية إلى حذف تقويم العمل بشكل دائم. هل أنت متأكد من رغبتك في المتابعة؟',
  'oifde.workday.actions.cancelDelete': 'تم إلغاء الحذف.',
  'oifde.workday.actions.deleteSuccess': 'تم الحذف بنجاح.',
  'oifde.workday.actions.updateSuccess': 'نجح التعديل.',
  'oifde.workday.actions.createSuccess': 'تم الإنشاء بنجاح.',
  'oifde.workday.actions.missWorkDayName': 'لا يمكن أن يكون اسم تقويم العمل فارغًا.',
  'oifde.workday.actions.missWorkWeekDay': 'لا يمكن أن يكون تحديد الأسبوع فارغًا.',
  'oifde.workday.actions.minServicePeriod': 'يجب تكوين سجل واحد على الأقل لفترة الخدمة.',
  'oifde.workday.actions.missPeriodName': 'لا يمكن أن يكون اسم فترة الخدمة فارغًا.',
  'oifde.workday.actions.missPeriodTime': 'لا يمكن أن يكون وقت فترة الخدمة فارغًا.',
  'oifde.workday.actions.missNonServiceName': 'لا يمكن أن يكون اسم تاريخ غير الخدمة المحدد فارغًا.',
  'oifde.workday.actions.missNonServiceDate': 'لا يمكن أن يكون تاريخ عدم الخدمة المحدد فارغًا.',
  'oifde.workday.actions.missServiceName': 'لا يمكن أن يكون اسم تاريخ الخدمة المحدد فارغًا.',
  'oifde.workday.actions.missServiceDate': 'لا يمكن أن يكون تاريخ الخدمة المحدد فارغًا.',
  'oifde.workday.tip.overServicePeriod': 'لا يمكن أن يتجاوز عدد سجلات فترة الخدمة 10.',
  'oifde.workday.tip.overNonServiceDate': 'لا يمكن أن يتجاوز عدد سجلات تاريخ عدم الخدمة المحددة 50.',
  'oifde.workday.tip.overServiceDate': 'لا يمكن أن يتجاوز عدد سجلات تاريخ الخدمة المحدد 50.',
  'oifde.workday.tip.overWorkDayCount': 'لا يمكن أن يتجاوز عدد سجلات تقويم العمل 1000.',
  'oifde.workday.actions.nameError': 'جميع الأسماء مسموح بها فقط الأحرف الصينية والحروف الإنجليزية والأرقام والمسافات والشرطات السفلية (_), (-) وعلامات الاقتباس المفردة (\').',
  'oifde.dataTable.tableName': 'اسم الجدول',
  'oifde.dataTable.tableId': 'معرف الجدول',
  'oifde.dataTable.tableField': 'حقول الجدول',
  'oifde.dataTable.fieldId': 'معرف الحقل',
  'oifde.dataTable.fieldName': 'اسم الحقل',
  'oifde.dataTable.fieldType': 'نوع الحقل',
  'oifde.dataTable.fieldLength': 'طول الحقل',
  'oifde.dataTable.defaultValue': 'القيمة الافتراضية',
  'oifde.dataTable.deleteTips': 'ستؤدي هذه العملية إلى حذف جدول البيانات هذا والبيانات الموجودة فيه بشكل دائم. هل تريد المتابعة؟',
  'oifde.dataTable.deleteBatchTips': 'لا توجد جداول بيانات محددة لحذفها حالياً',
  'oifde.dataTable.editDataTable': 'تحرير ورقة البيانات',
  'oifde.dataTable.addDataTable': 'جدول بيانات جديد',
  'oifde.dataTable.editDataTableField': 'تحرير حقول الجدول',
  'oifde.dataTable.addDataTableField': 'حقل جدول جديد',
  'oifde.dataTable.validation.tableId': 'يُسمح فقط بالأحرف والأرقام والشرطات السفلية، ولا يمكن أن تبدأ برقم',
  'oifde.dataTable.validation.presetField': '"ID" و "TENANTID" و "CREATETIME" و "MODIFYTIME" هي حقول معدة مسبقًا وهي غير حساسة لحالة الأحرف، يرجى عدم استخدامها',
  'oifde.dataTable.fieldsMaxSize': 'لا يمكن أن يتجاوز عدد حقول الجدول 20',
  'oifde.dataTable.fieldsNotEmpty': 'لا يمكن أن يكون حقل الجدول فارغًا',
  'oifde.dataTable.exceedMaxSize': 'لا يمكن أن يتجاوز عدد جداول البيانات 40',
  'oifde.dataTable.saveAndInsert': 'حفظ وإضافة البيانات',
  'oifde.tableData.diagram.addCriteria': 'إضافة مجموعة',
  'oifde.tableData.diagram.logic': 'المنطق',
  'oifde.dataTable.criteria': 'المعايير',
  'oifde.dataTable.diagram.fieldName': 'اسم الحقل',
  'oifde.dataTable.diagram.fieldValue': 'قيمة الحقل',
  'oifde.tableData.diagram.searchCriteria': 'معايير البحث',
  'oifde.tableData.diagram.searchPage': 'صفحة البحث',
  'oifde.tableData.diagram.searchResult': 'نتائج البحث',
  'oifde.tableData.diagram.returnRecords': 'إرجاع السجلات',
  'oifde.tableData.diagram.total': 'المجموع',
  'oifde.title.chooseGreyRule': 'اختر القاعدة الرمادية',
  'oifde.conditionService.param.tips': 'عندما يحتوي التعبير الشرطي على ثوابت سلسلة، يجب إضافة علامات اقتباس لاستخدامها بشكل صحيح',
  'oifde.char.invalid': 'أحرف غير صالحة',
  'oifde.service.table.dialog.nodatedelete': 'لم يتم تحديد بيانات لحذفها',
  'oifde.service.table.dialog.exceedMaxSize': 'يمكن إضافة 10000 سجل كحد أقصى',
  'oifde.service.table.dialog.title': 'البيانات',
  'oifde.service.table.dialog.selectField': 'تحديد حقل',
  'oifde.service.table.dialog.inputValue': 'قيمة حقل الإدخال',
  'oifde.service.table.dialog.addRecord': 'إضافة بيانات',
  'oifde.service.table.dialog.editRecord': 'تحرير البيانات',
  'oifde.service.table.dialog.integer': 'يُسمح فقط بالأعداد الصحيحة',
  'oifde.service.table.dialog.string': 'الحد الأقصى لطول قيمة من نوع السلسلة هو 10000',
  'oifde.service.table.dialog.oneday': 'آخر يوم',
  'oifde.service.table.dialog.threeday': 'آخر 3 أيام',
  'oifde.service.table.dialog.sevenday': 'آخر 7 أيام',
  'oifde.service.table.dialog.custom': 'مخصص',
  'oifde.service.table.dialog.batchdelete': 'تأكيد حذف الحزمة',
  'oifde.service.table.dialog.delete': 'تأكيد الحذف',
  'oifde.validate.validChar': 'نطاق الأحرف المسموح به: ',
  'oifde.expression.expr_var_list': 'أدخل متغير قائمة.',
  'oifde.expression.expr_var_number': 'أدخل متغير int.',
  'oifde.expression.allVar': 'ابدأ القيمة بـ GLOBAL (للمتغير الشامل) أو FLOW (للمتغير التدفق) أو SYS (للمتغير النظام) أو TOC (للمتغير TUC) أو IVRREQUEST (للمتغير طلب IVR).',
  'oifde.expression.flowAndGlobalVar': 'ابدأ القيمة بـ GLOBAL (للمتغير العام) ، FLOW (للمتغير التدفق).',
  'oifde.diagram.node.type': 'نوع العقدة',
  'oifde.service.invoke.timeSelect.workDate': 'تحديد تاريخ العمل',
  'oifde.service.invoke.timeSelect.noWorkDate': 'تحديد تاريخ عدم العمل',
  'oifde.variable.rightList.tips': 'يمكن سحب المتغيرات المعروضة على اليمين إلى مربع نص المتغير.',
  'oifde.screenfull.cancel': 'الخروج من ملء الشاشة',
  'oifde.variable.sys.callMediaType': 'التعرف على كل شيء في الواجهة',
  'oifde.variable.sys.systemDate': 'تاريخ النظام(YYYYMMDD)',
  'oifde.variable.sys.systemMonth': 'سنة النظام وشهره(YYYYMM)',
  'oifde.variable.sys.currentTime': 'وقت النظام الحالي(yyyyMMddHHmmss)',
  'oifde.variable.sys.systemTime': 'وقت النظام الحالي(HHmmss)',
  'oifde.variable.sys.timeStamp': 'الطابع الزمني، نوع السلسلة.01 يناير 1970 الساعة 00: 00 دقيقة 00 ثانية بتوقيت غرينتش(01 يناير 1970 الساعة 08:00 دقيقة 00 ثانية بتوقيت بكين)إجمالي عدد الثواني منذ ذلك الحين.',
  'oifde.variable.sys.createTime': 'وقت بدء المكالمة',
  'oifde.variable.sys.callingNumber': 'رقم العميل.الرقم الطالب عند الاتصال بمركز الاتصال السحابي، والرقم المطلوب عند الاتصال بمركز الاتصال السحابي، وكلاهما من جانب العميل.نوع السلسلة.يتم نقل الرقم من تدفق IVR.',
  'oifde.variable.sys.calledNumber': 'رمز الوصول إلى تدفق النظام.نوع السلسلة.يتم نقل الرقم من تدفق IVR وهو رمز الوصول إلى التدفق. يمكنك تسجيل الدخول إلى وحدة تحكم تكوين WAS الخاصة بالنظام الأساسي CTI لعرضه.',
  'oifde.variable.sys.realCalledNumber': 'رمز الوصول، وهو رمز الوصول الذي تم تكوينه في تكوين الطرف المطلوب في هذا التدفق، أي رقم الخط الساخن الذي تم طلبه في هذا التدفق.',
  'oifde.variable.sys.callId': 'معرف جلسة ODFS، نوع السلسلة.يتم نقل المعرف بواسطة تدفق IVR، أي IVRREQUEST.call_id.',
  'oifde.variable.sys.ctiCallid': 'معرف المكالمة الخاص بـ CTI الذي تم نقله في تدفق IVR، أي معلمة الطلب IVRREQUEST.cti_callid في جانب IVR.',
  'oifde.variable.sys.tenantId': 'معرف المستأجر الحالي',
  'oifde.variable.sys.language': 'لغة العميل الحالية',
  'oifde.variable.ivrRequest.ctiCallid': 'معرف المكالمة الخاص بـ CTI الفعلي الذي تم نقله بواسطة IVR من Huawei. في سيناريوهات أخرى، تكون هذه القيمة فارغة.لا يزيد عن 32 بت.',
  'oifde.variable.ivrRequest.input': 'نتيجة تفاعل المحادثة.',
  'oifde.variable.ivrRequest.IACmd': 'ملء الحقول الخاصة للمطابقة الذكية والتذكرة الذكية.',
  'oifde.variable.ivrRequest.TextSource': 'مصدر نص الطلب.',
  'oifde.variable.ivrRequest.transinData': 'معلمات البيانات المرتبطة بالقناة المتفق عليها من طرف الوصول مع OIAP، على سبيل المثال، معلمات الحزم الواردة من IVR.',
  'oifde.variable.ivrRequest.beginPlay': 'وقت بدء تشغيل الإعلان.',
  'oifde.variable.ivrRequest.endPlay': 'وقت انتهاء تشغيل الإعلان.',
  'oifde.variable.ivrRequest.callId': 'المعرف الفريد للمكالمة. القيمة هي نفس قيمة userid ويتم استخدامها من قبل العملية.',
  'oifde.variable.ivrRequest.interIdx': 'عدد التفاعلات، وهو عدد التفاعلات المتتالية مع المستخدم الذي تم تسجيله في ODFS.',
  'oifde.variable.ivrRequest.feedback': 'الرضا عن التغذية المرتدة: 1 (الرضا)؛0 (غير راض).عدد صحيح.',
  'oifde.variable.ivrRequest.feedbackContent': 'سبب عدم الرضا عن الملاحظات (اترك فارغاً إذا كانت الملاحظات مرضية).',
  'oifde.variable.ivrRequest.channelType': 'نوع قناة وصول العميل. على سبيل المثال، WEB، INSTAGRAM، TELEGRAM، WECHAT، FACEBOOK، TWITTER، EMAIL، LINE، WHATSAPP، 5G، SMS',
  'oifde.variable.ivrRequest.ieValue': 'عنوان البريد الإلكتروني المستلم عند الوصول إلى قناة البريد الإلكتروني',
  'oifde.variable.toc.ChatBot': 'معلمات الفتحة التي تم تكوينها على الهدف',
  'oifde.variable.toc.ChatBotIntentCode': 'رموز النوايا التي تم إرجاعها بواسطة التعرف على الكلام',
  'oifde.variable.toc.ChatBotRespContent': 'معلومات الرد التي تم إرجاعها بواسطة التعرف على الصوت للهدف المطابق، بما في ذلك رقم الفتحة والرد الذي تم تكوينه في الاستجابة.',
  'oifde.variable.toc.ChatBotScenarioName': 'اسم قالب الغرض الذي تمت مطابقته بعد تنفيذ التعرف على الكلام.',
  'oifde.variable.toc.ChatBotResponse': 'التعرف على كل شيء في الواجهة',
  'oifde.datatable.dateValue.tip': 'الطابع الزمني، في ميلي ثانية. أدخل عددًا صحيحًا طويلًا أو قيمة حرف أو متغيرًا.',
  'oifde.datatable.intList.tip': 'مثال: [1، 2، 3].',
  'oifde.datatable.stringList.tip': 'مثال: ["a","b","c"].',
  'oifde.expression.expr_number_varOrValue': 'أدخل قيمة أو متغير من النوع الرقمي.',
  'oifde.expression.expr_list_varOrValue': 'أدخل قيمة أو متغير من نوع القائمة.',
  'oifde.flow.rule.noRule': 'فشل العثور على القاعدة الرمادية. تحقق مما إذا كان قد تم حذف القاعدة.',
  'oifde.procedure.databaseConnection': 'اتصال قاعدة البيانات',
  'oifde.procedure.db.dbName': 'اسم قاعدة البيانات',
  'oifde.cell.viewRefrenceInfo': 'معلومات الاقتباس',
  'oifde.cell.viewRefrenceInfo.flow': 'مرجع العملية',
  'oifde.cell.viewRefrenceInfo.complexcell': 'مرجع العنصر المركب',
  'oifde.accessCode.select.max': 'تم تحديد {0} سجلات. يمكن تحديد {1} سجلات كحد أقصى.',
  'oifde.mxgraph.deleteDraft': 'حذف المسودات',
  'oifde.mxgraph.deleteDraft.confirm': 'هل أنت متأكد من رغبتك في حذف المسودة؟',
  'oifde.mxgraph.deleteDraft.noDraftExist': 'لا توجد بيانات مسودة',
  'oifde.resource.needApproval': 'تم إنشاء موارد الرد بنجاح ولا يمكن استخدامها إلا بعد الموافقة عليها من قبل مسؤول النظام.',
  'oifde.common.validation.accessCode.description': 'غير مسموح بفواصل الأسطر والأحرف الخاصة التالية: "[]\'&()=;<>,',
  'oifde.tableData.import.tableNameBreve': 'اسم الجدول',
  'oifde.procedure.actions.deleteDBFailed2': 'فشل الحذف. تمت الإشارة إلى اتصال قاعدة البيانات بواسطة {1} إجراءات مخزنة، مثل {0}. الرجاء التحقق',
  'oifde.flow.actions.publish.existDraft': 'توجد مسودة بيانات في العملية الحالية. الرجاء تحريرها ونشرها أو تجاهل المسودة.',
  'oifde.export.finish.success': 'تم التصدير بنجاح.',
  'oifde.input.tips.varAndConst': 'أدخل متغيرًا أو ثابتًا. استخدم علامات الاقتباس قبل السلسلة وبعدها.',
  'oifde.export.keyPressRobot.title': 'تصدير سجلات IVR العادية',
  'oifde.export.password': 'ضغط كلمة المرور',
  'oifde.export.password.placeholder': 'يُرجى إدخال كلمة المرور المضغوطة',
  'oifde.export.password.error.tips': 'الرجاء إدخال كلمة مرور مكونة من 8 إلى 12 رقمًا لإنشاء ملف مضغوط. يجب أن تحتوي على أرقام وحروف وأحرف خاصة باستثناء /\\-#(){\'{\'}{\'}\'};&{\'|\'}<>{\'$\'}`\\\\!\\n\\s\\t\\r',
  'oifde.export.password.error.message': 'يجب أن تحتوي على أرقام وحروف وأحرف خاصة باستثناء /\\-#(){\'{\'}{\'}\'};&{\'|\'}<>{\'$\'}`\\\\!\\n\\s\\t\\r',
  'oifde.export.task.status': 'حالة المهمة',
  'oifde.export.task.fail': 'فشل التصدير',
  'oifde.export.businessInterface.title': 'صادرات رکوردهای واسط سفارشی',
  'oifde.export.variable.title': 'تصدير سجلات المتغيرات المخصصة',
  'oifde.export.complexcell.title': 'تصدير سجلات عنصر المخطط المركب',
  'oifde.export.accessCode.title': 'تصدير سجلات الروبوت الذكي',
  'oifde.export.intelligentIVRFlow.title': 'تصدير سجلات العملية',
  'oifde.export.intelligentIVRFlow.button': 'تدفق التصدير',
  'oifde.route.2dDigitalHumanImg': 'الإنسان الافتراضي',
  'oifde.route.add2dDigitalHumanImg': 'إنشاء إنسان افتراضي',
  'oifde.digitalHuman.outputSetting': 'إعداد الإخراج',
  'oifde.digitalHuman.standingPosition': 'وضع المواضع المؤقتة',
  'oifde.digitalHuman.stationTemplate': 'قالب المواضع المؤقتة',
  'oifde.digitalHuman.preciseAdjustment': 'ضبط دقيق',
  'oifde.digitalHuman.left': 'يسار',
  'oifde.digitalHuman.center': 'مركز',
  'oifde.digitalHuman.right': 'يمين',
  'oifde.digitalHuman.imageProportion': 'نسبة الصورة',
  'oifde.exportdata.message.overData': 'تجاوز عدد عمليات التصدير الحد الأقصى البالغ 10000. الرجاء إعادة تحديد معايير البحث.',
  'oifde.flow.title.publishFlow': 'تدفق التحرير',
  'oifde.flow.title.upgrade': 'تدفق الترقية',
  'oifde.flow.action.editInfo': 'تحرير المعلومات',
  'oifde.flow.title.editInfo': 'تحرير معلومات التدفق الأساسية',
  'oifde.robot.title.editInfo': 'تحرير معلومات Chatbot الأساسية',
  'oifde.input.placeholder.searchName': 'اسم البحث',
  'oifde.avatar.tenantId':'معرف المستأجر',
  'oifde.avatar.tenantSpace':'مساحة المستأجر',
  'oifde.avatar.avatarName':'اسم الدور',
  'oifde.avatar.avatarCode':'رمز الدور',
  'oifde.avatar.headerImageUrl':'الصورة',
  'oifde.avatar.serverAddress':'عنوان الخادم',
  'oifde.avatar.avatarAppKey':'اسم مستخدم الملقم/AK',
  'oifde.avatar.create':'إنشاء خدمة بشرية افتراضية',
  'oifde.avatar.update':'تحرير خدمة بشرية افتراضية',
  'oifde.avatar.headerImage': 'صورة الملف الشخصي',
  'oifde.avatar.poseImage': 'صورة ظاهرية',
  'oifde.avatar.actionList': 'قائمة الإجراءات',
  'oifde.avatar.form.message': 'معلومات بشرية افتراضية ثنائية الأبعاد',
  'oifde.avatar.service.message':'معلومات الخادم' ,
  'oifde.avatar.serverPort':'منفذ الخادم' ,
  'oifde.avatar.userName': 'اسم مستخدم الملقم',
  'oifde.avatar.pwd':'كلمة مرور الخادم',
  'oifde.avatar.checkPwd':'تأكيد كلمة مرور الملقم',
  'oifde.avatar.resolution': 'دقة الوضوح',
  'oifde.avatar.imgRule': 'يتم دعم تنسيقات JPG وPNG وJPEG فقط. لا يمكن أن يتجاوز حجم الملف 2 ميغابايت.',
  'oifde.avatar.action_1': 'تحيات طيبة',
  'oifde.avatar.action_2': 'إعادة توجيه اليدين',
  'oifde.avatar.action_3': 'الإيماءة',
  'oifde.avatar.action_4': 'مشبك اليدين',
  'oifde.avatar.action_5': 'انشر يدك اليسرى',
  'oifde.avatar.action_6': 'انشر يدك اليمنى',
  'oifde.avatar.selectTitle': 'تحديد صورة أفاتار',
  'oifde.digitalHuman.imgLimit': 'يتم دعم ملفات صور JPG، PNG، وJPEG فقط. لا يمكن أن يتجاوز حجم الملف 2 ميغابايت.',
  'oifde.digitalHuman.imageProportion0-5': '0.5',
  'oifde.digitalHuman.imageProportion0-75': '0.75',
  'oifde.digitalHuman.imageProportion1': '1',
  'oifde.digitalHuman.canvasNoSupport': 'لا يدعم المستعرض الحالي اللوحة القماشية. قم بترقية المستعرض أو تغييره أولاً.',
  'oifde.digitalHuman.noRoleData': 'لا توجد بيانات دور. اتصل بموظفي العملية لإضافة بيانات الدور.',
  'oifde.digitalHuman.sizeError': 'دقة وضوح الصورة غير صحيحة. قم بتحميل صورة خلفية بتنسيق {0} x {1}.',
  'oifde.title.add.complexCell': 'عنصر مركب جديد',
  'oifde.title.add.complexCellCatalog': 'دليل عنصر مركب جديد',
  'oifde.title.edit.complexCellCatalog': 'تحرير كتالوج العناصر المركبة',
  'oifde.title.add.rule': 'قاعدة جديدة',
  'oifde.title.edit.rule': 'تحرير القاعدة',
  'oifde.ivrJourney.pleaseSelectData': 'لطفاً ، داده ها را برگزینید',
  'oifde.digitalHuman.needRole': 'حدد دورًا.',
  'oifde.digitalHuman.actions.deletePrompt': 'ستؤدي هذه العملية إلى حذف صورة الشخص الرقمي نهائيًا. هل تريد المتابعة؟',
  'oifde.avatar.pleaseSelectData': 'لن يتم حفظ البيانات المعدلة بعد الإغلاق. هل تريد بالتأكيد المتابعة؟',
  'oifde.digitalHuman.actions.savePrompt': 'ستقوم هذه العملية بتحديث صورة الإنسان الرقمية. هل تريد المتابعة؟',
  'oifde.avatar.validation.ttsSoundSpeed': 'أدخل منزلة عشرية واحدة بين 0.5 و1.5.',
  'oifde.avatar.validation.scale': 'أدخل منزلة عشرية واحدة بين 0.5 و1.0.',
  'oifde.digitalHuman.coordinate': 'تنسيق',
  'oifde.digitalHuman.dimensions': 'الأبعاد',
  'oifde.digitalHuman.imgTypeError': 'نوع غير صحيح. يتم دعم تنسيقات JPG وPNG وJPEG فقط.',
  'oifde.expression.expr_var_flow': 'أدخل متغير عملية يبدأ بـ FLOW',
  'oifde.expression.expr_string_or_var': 'أدخل قيمة للمتغير أو نوع سلسلة الأحرف. استخدم علامات اقتباس مزدوجة قبل وبعد السلسلة.',
  'oifde.common.actions.deleteConfig': 'ستؤدي هذه العملية إلى حذف التكوين نهائيًا. هل تريد بالتأكيد المتابعة؟',
  'oifde.expression.numberCharStartWith0': 'استخدم علامات اقتباس مزدوجة قبل وبعد سلسلة رقمية تبدأ بـ 0. على سبيل المثال، يجب إدخال 05 كـ "05". وإلا، سيتم اعتبار القيمة 5.',
}
