<template>
    <div>
        <sweet-dialog v-model="dialogVisible"
                      :title="$t('isales.taskmanagement.title.selectTemplate')"
                      width="616" class="aicc-dialog-limit-height" :close-on-click-modal="false">
            <aicc-advanced-search
                    :templateMetaData="templateMetaData"
                    @handleSearch='searchPageFirst'>
            </aicc-advanced-search>
            <aicc-table
                    :tableData="outboundCallTemplateList"
                    :tableColumns="tableColumns"
                    :tableTools="{ showPagination: true, columnConfig: false }"
                    :paginationConfig="paginationConfig"
                    :height="430"
                    @handlePagination="handlePagination"
                    ref='refAiccTable'>
            </aicc-table>
            <template #footer>
      <span class="dialog-footer">
        <sweet-button @click="dialogVisible = false">{{ $t('isales.common.title.cancel') }}</sweet-button>
        <sweet-button type="primary" @click="confirm">{{ $t('isales.common.title.confirm') }}</sweet-button>
      </span>
            </template>
        </sweet-dialog>
    </div>
</template>

<script>
    import {queryCmsEnvironment, queryOutboundCallTemplateListAndCount} from "@/views/isales/api/systemAutoCallTask";
    import {TASK_TYPE_MAP} from "@/views/isales/views/taskManagement/enum.js";
    import {TASK_TYPE} from "@/views/isales/views/taskManagement/enum";

    export default {
        name: "isalesTemplateSelect",
        props: {
            show: {
                type: Boolean,
                default: false
            },
            taskTypeList: { //任务类型
                type: Array,
                default() {
                    return []
                }
            }
        },
        data() {
            return {
                taskTypeItem: TASK_TYPE,
                outboundCallTemplateList: [],
                tableColumns: [
                    {
                        type: 'radio',
                        radioKey: 'taskId', // 单选绑定的字段名
                        width: '50px',
                        'show-overflow-tooltip': false
                    },
                    {
                        label: this.$t('isales.tasktemplate.field.templateName'),
                        prop: 'taskName',
                    },
                    {
                        label: this.$t('isales.taskmanagement.field.outboundType'),
                        prop: 'taskTypeDesc',
                    }
                ],
                paginationConfig: {
                    PageSize: 10, // 每页条数
                    total: 0, //总条数
                    layout: "total, prev, pager, next" // 分页组件布局配置
                },
                templateMetaData: {
                    basicConfig: { // 基础搜索配置
                        basicSearch: [
                            {
                                searchType: 'primary', // primary: 有搜索图标的输入框；select: 下拉框; input: 搜索框；date: 日期选择器
                                modelValue: 'taskName', // v-model绑定的key值
                                modelDefault: '', // v-model绑定的默认值
                                placeholderValue: this.$t('isales.tasktemplate.title.inputTemplateName'), // placeholder绑定的值 国际化之后的值
                                rules: [],
                                colConfig: {
                                    xl: 12,
                                    lg: 12
                                }
                            }
                        ]
                    }
                },
                limit: 10,
                offset: 0
            }
        },
        computed: {
            dialogVisible: {
                get() {
                    return this.show
                },
                set(val) {
                    this.$emit('update:show', val)
                }
            }
        },
        methods: {
          searchPageFirst(params){
            this.offset = 0;
            this.$refs.refAiccTable.setCurrentPage(1);
            this.queryOutboundCallTemplateListAndCount(params);
          },
            async queryOutboundCallTemplateListAndCount(params) {
                let queryCondition = {};
                let dataObject = {
                    dataMode: 1,
                    status: "9"
                }
                if (params && params.params && params.params.taskName) {
                    dataObject["taskName"] = params.params.taskName;
                }
                if (this.taskTypeList && this.taskTypeList.length > 0) {
                    dataObject["taskTypeList"] = this.taskTypeList;
                }
                queryCondition['dataObject'] = dataObject;
                queryCondition['limit'] = this.limit;
                queryCondition['offset'] = this.offset;
                queryOutboundCallTemplateListAndCount(queryCondition).then(res => {
                    if (res) {
                        this.outboundCallTemplateList = res.outboundCallTemplateList;
                        if (this.outboundCallTemplateList && this.outboundCallTemplateList.length > 0) {
                            this.outboundCallTemplateList.forEach(item => {
                                item['taskTypeDesc'] = TASK_TYPE_MAP[item.taskType];
                            });
                        }
                        this.paginationConfig.total = res.outboundCallTemplateCount;
                    }
                })
            },
            handlePagination(params) {
                this.limit = params.limit;
                this.offset = params.offset;
                this.queryOutboundCallTemplateListAndCount(params);
            },
            confirm() {
                this.dialogVisible = false
                this.$emit('isalesTemplateSelectConfirm', this.$refs.refAiccTable.getRadioCurrentRow())
            },
            async queryCmsEnvironment() {
                let result = await queryCmsEnvironment();
                this.cmsEnvironment = result.cmsEnvironment;
            },
        },
        async created() {
            await this.queryCmsEnvironment();
            if (!this.taskTypeList || this.taskTypeList.length === 0) {
                if ('1' === this.$route.query.taskModel) { // 机器人外呼
                    if (this.cmsEnvironment === 'aicc') {
                        this.taskTypeList.push(this.taskTypeItem.INTELLIGENT);
                    }
                    this.taskTypeList.push(this.taskTypeItem.AUTO);
                } else {
                    this.taskTypeList.push(this.taskTypeItem.PREEMPTION);
                    this.taskTypeList.push(this.taskTypeItem.PREDICT);
                    this.taskTypeList.push(this.taskTypeItem.PREVIEW);
                }
            }
            await this.queryOutboundCallTemplateListAndCount();
        },
    }
</script>

<style scoped>

</style>