import enLocale from './en_US'
import zhLocale from './zh_CN'
import esLocale from './es_ES'
import thLocale from './th_TH'
import ptBRLocale from './pt_BR'
import frLocale from './fr_FR'
import arLocale from './ar_SA'
import deLocale from './de_DE'
import traceCodeZhLocale from './errorCode_zh_CN'
import traceCodeEnLocale from './errorCode_en_US'
import traceCodeThLocale from './errorCode_th_TH'
import traceCodePtBRLocale from './errorCode_pt_BR'
import traceCodeEsESLocale from './errorCode_es_ES'
import traceCodeFrLocale from './errorCode_fr_FR'
import traceCodeArLocale from './errorCode_ar_SA'
import traceCodeDeLocale from './errorCode_de_DE'
import cellZhLocale from './cell_zh_CN'
import cellEnLocale from './cell_en_US'
import cellESLocale from './cell_es_ES'
import cellTHLocale from './cell_th_TH'
import cellPTLocale from './cell_pt_BR'
import cellFRLocale from './cell_fr_FR'
import cellFALocale from './cell_fa_IR'
import cellARLocale from './cell_ar_SA'
import cellDELocale from './cell_de_DE'
import ccivrEn from './ccivr_en_US'
import ccivrEs from './ccivr_es_ES'
import ccivrFr from './ccivr_fr_FR'
import ccivrPt from './ccivr_pt_BR'
import ccivrTh from './ccivr_th_TH'
import ccivrZh from './ccivr_zh_CN'
import ccivrAr from './ccivr_ar_SA'
import ccivrDe from './ccivr_de_DE'

const messages = {
  en_US: {
    ...enLocale,
    ...cellEnLocale,
    ...traceCodeEnLocale,
    ...ccivrEn
  },
  zh_CN: {
    ...zhLocale,
    ...traceCodeZhLocale,
    ...cellZhLocale,
    ...ccivrZh
  },
  es_ES: {
    ...esLocale,
    ...traceCodeEsESLocale,
    ...cellESLocale,
    ...ccivrEs
  },
  th_TH: {
    ...thLocale,
    ...traceCodeThLocale,
    ...cellTHLocale,
    ...ccivrTh
  },
  pt_BR: {
    ...ptBRLocale,
    ...traceCodePtBRLocale,
    ...cellPTLocale,
    ...ccivrPt
  },
  fr_FR: {
    ...frLocale,
    ...traceCodeFrLocale,
    ...cellFRLocale,
    ...ccivrFr
  },
  fa_IR: {
    ...cellFALocale,
  },
  ar_SA: {
    ...arLocale,
    ...traceCodeArLocale,
    ...cellARLocale,
    ...ccivrAr,
  },
  de_DE: {
    ...deLocale,
    ...cellDELocale,
    ...traceCodeDeLocale,
    ...ccivrDe
  },
}

export default messages