<template>
  <div class="oifde-tab-content" :style="initStyle">
    <div class="oifde-tab-content-header">
      <slot name="header"></slot>
    </div>
    <div class="oifde-tab-content-main" ref="screenMain">
      <slot name="main"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'oifde-tab-content',
  props: {
    targetSelector: {
      type: String,
      default() {
        return '.el-table'
      }
    },
    existConfig: {
      type: Array,
      default() {
        // checkedInfo aicc-table内展示顶部已选中信息
        return []
      }
    },
    extendHeight: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {}
  },
  computed: {
    initStyle() {
      // 48-头部title；40-tab；72-分页； 56-返回头  32-选中信息;oifde内部tab 48
      let subtractHeight = 231 + this.extendHeight
      if(this.isApiBuilderDeployment){
        subtractHeight -= 40
      }
      return `height: calc(100vh - ${subtractHeight}px);`;
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      if (this.targetSelector) {
        if (this.targetSelector[0] === '.') {
          this.targetDom = $(this.$el).find(this.targetSelector)[0]
        } else if (this.targetSelector[0] === '#') {
          this.targetDom = $(this.$el).find(this.targetSelector)[0]
        }
      }

      if (this.targetDom) {
        const screenMain = this.$refs.screenMain
        this.observer = new ResizeObserver(this.handleResize)
        this.observer.observe(screenMain, { box: 'border-box' })
      }
    },
    handleResize(val) {
      if (val[0] && val[0].contentRect && val[0].contentRect.height) {
        let height = val[0].contentRect.height
        if (this.existConfig.includes('checkedInfo')) {
          height -= 32
        }
        height -= 72
        this.targetDom.style.cssText += `height: ${height}px`
      }
    },
  }
}
</script>

<style lang="less" scoped>
.oifde-tab-content {
  display: flex;
  flex-direction: column;

&-main {
  width: 100%;
  flex: 1;
  overflow: scroll;
 }
}

</style>