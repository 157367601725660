<template>
    <div>
        <!--预测外呼-->
        <div v-if="showModel.taskInfo.taskType===this.taskTypeItem.PREDICT">
            <sweet-form-item :label="$t('isales.taskpolicy.field.predictMethod')" prop="policy.predictMethod">
                <sweet-radio-group v-model="showModel.policy.predictMethod">
                    <sweet-radio v-for="item in predictMethodItem" :label="item.key">{{item.value}}</sweet-radio>
                </sweet-radio-group>
            </sweet-form-item>
        </div>
        <div v-if="showModel.taskInfo.taskType===this.taskTypeItem.PREDICT">
            <sweet-form-item :label="$t('isales.taskpolicy.field.obOverTime')" :required="!isBatchEdit" prop="policy.obOverTime"
                             :rules="[{ required: true, message: this.$t('isales.business.result.message.must'), trigger: ['blur', 'change'] }
                             ,{ min: 5, max: 120, validator: this.callTimesValidator, trigger: ['blur', 'change'] }]">
                <sweet-input-number @blur="handleBlurObOverTime" v-model="showModel.policy.obOverTime" controls-position="right"
                                    placeholder="20"> </sweet-input-number><span>{{$t('isales.taskpolicy.field.time')}}</span>
            </sweet-form-item>
            <sweet-form-item v-if="showModel.policy.predictMethod!=predictMethodItem[3].key"
                             :label="$t('isales.taskpolicy.field.maxAvgCallTime')" :required="!isBatchEdit" prop="policy.maxAvgCallTime"
                             :rules="[{ required: true, message: this.$t('isales.business.result.message.must'), trigger: ['blur', 'change'] }
                             ,{ min: 50, max: 200, validator: this.callTimesValidator, trigger: ['blur', 'change'] },{validator: this.validMaxAvgCallTime, trigger: ['blur', 'change']}]">
                <sweet-input-number @blur="handleBlurMaxAvgCallTime" v-model="showModel.policy.maxAvgCallTime" controls-position="right" placeholder="100"></sweet-input-number>
                <span>{{$t('isales.taskpolicy.field.time')}}</span>
            </sweet-form-item>
            <sweet-form-item v-if="showModel.policy.predictMethod!=predictMethodItem[3].key"
                             :label="$t('isales.taskpolicy.field.minAvgCallTime')" :required="!isBatchEdit" prop="policy.minAvgCallTime"
                             :rules="[{ required: true, message: this.$t('isales.business.result.message.must'), trigger: ['blur', 'change'] }
                             ,{ min: 50, max: 200, validator: this.callTimesValidator, trigger: ['blur', 'change'] },{validator: this.validMinAvgCallTime, trigger: ['blur', 'change']}]">
                <sweet-input-number @blur="handleBlurMinAvgCallTime" v-model="showModel.policy.minAvgCallTime" controls-position="right" placeholder="100"></sweet-input-number>
                <span>{{$t('isales.taskpolicy.field.time')}}</span>
            </sweet-form-item>
            <!--呼出速度-->
            <sweet-form-item v-if="showModel.policy.predictMethod==predictMethodItem[0].key"
                             :label="$t('isales.taskpolicy.field.talkingRate')" :required="!isBatchEdit" prop="policy.talkingRate"
                             :rules="[{ required: true, message: this.$t('isales.business.result.message.must'), trigger: ['blur', 'change'] }
                             ,{ min: 1, max: 100, validator: this.callTimesValidator, trigger: ['blur', 'change'] }]">
                <sweet-input-number @blur="handleBlurTalkingRate" v-model="showModel.policy.talkingRate" controls-position="right" placeholder="70"></sweet-input-number>
            </sweet-form-item>
            <!--最低呼损率-->
            <sweet-form-item
                    v-if="showModel.policy.predictMethod==predictMethodItem[1].key ||showModel.policy.predictMethod==predictMethodItem[2].key"
                    :label="$t('isales.taskpolicy.field.lossRate')" :required="!isBatchEdit" prop="policy.lossRate"
                    :rules="[{ required: true, message: this.$t('isales.business.result.message.must'), trigger: ['blur', 'change'] }
                             ,{ min: 0, max: 100, validator: this.callTimesValidator, trigger: ['blur', 'change'] }]">
                <sweet-input-number @blur="handleBlurLossRate" v-model="showModel.policy.lossRate" controls-position="right" placeholder="3"></sweet-input-number>
            </sweet-form-item>
            <!--座席利用率-->
            <sweet-form-item v-if="showModel.policy.predictMethod==predictMethodItem[1].key"
                             :label="$t('isales.taskpolicy.field.utilRate')" :required="!isBatchEdit" prop="policy.utilRate"
                             :rules="[{ required: true, message: this.$t('isales.business.result.message.must'), trigger: ['blur', 'change'] }
                             ,{ min: 0, max: 100, validator: this.callTimesValidator, trigger: ['blur', 'change'] }]">
                <sweet-input-number  @blur="handleBlurUtilRate" v-model="showModel.policy.utilRate" controls-position="right" placeholder="70"></sweet-input-number>
            </sweet-form-item>
            <!--座席利用率的计算方法-->
            <sweet-row>
                <!--按数据属性-->
                <sweet-col :span="12">
                    <sweet-form-item v-if="showModel.policy.predictMethod==predictMethodItem[1].key"
                                     :label="$t('isales.taskpolicy.field.utilRateCalculateMethod')" :required="!isBatchEdit" prop="policy.utilRateMethod">
                        <sweet-select v-model="showModel.policy.utilRateMethod"
                                      :placeholder="$t('isales.taskinfo.placeholder.choose')" :fit-input-width="true" :show-resize="false">
                            <sweet-option v-for="(item, index) in this.utilRateMethodItem" :key="item.key"
                                          :label="item.value"
                                          :value="item.key"/>
                        </sweet-select>
                    </sweet-form-item>
                </sweet-col>
            </sweet-row>
            <!--用户等待时间-->
            <sweet-form-item v-if="showModel.policy.predictMethod==predictMethodItem[2].key"
                             :label="$t('isales.taskpolicy.field.waitTime')" :required="!isBatchEdit" prop="policy.waitTime"
                             :rules="[{ required: true, message: this.$t('isales.business.result.message.must'), trigger: ['blur', 'change'] }
                             ,{ min: 0, max: 16, validator: this.callTimesValidator, trigger: ['blur', 'change'] }]">
                <sweet-input-number @blur="handleBlurWaitTime" v-model="showModel.policy.waitTime" controls-position="right" placeholder="5"></sweet-input-number>
                <span>{{$t('isales.taskpolicy.field.time')}}</span>
            </sweet-form-item>
            <!--外呼接通率-->
            <sweet-form-item v-if="showModel.policy.predictMethod==predictMethodItem[2].key"
                             :label="$t('isales.taskpolicy.field.connRate')" :required="!isBatchEdit" prop="policy.connRate"
                             :rules="[{ required: true, message: this.$t('isales.business.result.message.must'), trigger: ['blur', 'change'] }
                             ,{ min: 0, max: 100, validator: this.callTimesValidator, trigger: ['blur', 'change'] }]">
                <sweet-input-number @blur="handleBlurConnRate" v-model="showModel.policy.connRate" controls-position="right" placeholder="95"></sweet-input-number>
            </sweet-form-item>
            <!--计算因子-->
            <sweet-form-item v-if="showModel.policy.predictMethod==predictMethodItem[3].key"
                             :label="$t('isales.taskpolicy.field.calculationFactor')" :required="!isBatchEdit" prop="policy.pickupRate"
                             :rules="[{ required: true, message: this.$t('isales.business.result.message.must'), trigger: ['blur', 'change'] }
                             ,{ min: 50, max: 200, validator: this.callTimesValidator, trigger: ['blur', 'change'] }]">
                <sweet-input-number @blur="handleBlurPickupRate" v-model="showModel.policy.pickupRate" controls-position="right" placeholder="100"></sweet-input-number>
            </sweet-form-item>
        </div>
        <!--预览外呼-->
        <div v-if="showModel.taskInfo.taskType===this.taskTypeItem.PREVIEW">
            <sweet-form-item :label="$t('isales.taskpolicy.field.failInterval')" :required="!isBatchEdit" prop="policy.failInterval"
                             :rules="[{ required: true, message: this.$t('isales.business.result.message.must'), trigger: ['blur', 'change'] }
                             ,{ min: 3, max: 60, validator: this.callTimesValidator, trigger: ['blur', 'change'] }]">
                <sweet-input-number v-model="showModel.policy.failInterval" controls-position="right" placeholder="3"></sweet-input-number>
                <span>{{$t('isales.taskpolicy.field.time')}}</span>
            </sweet-form-item>
            <sweet-form-item :label="$t('isales.taskpolicy.field.obCallCount')" :required="!isBatchEdit" prop="policy.obCallCount"
                             :rules="[{ required: true, message: this.$t('isales.business.result.message.must'), trigger: ['blur', 'change'] }
                             ,{ min: 1, max: 100, validator: this.callTimesValidator, trigger: ['blur', 'change'] }]">
                <sweet-input-number v-model="showModel.policy.obCallCount" controls-position="right" placeholder="20"></sweet-input-number>
            </sweet-form-item>
            <sweet-form-item :label="$t('isales.taskpolicy.field.previewTimeoutInterval')" :required="!isBatchEdit" prop="policy.obOverTime"
                             :rules="[{ required: true, message: this.$t('isales.business.result.message.must'), trigger: ['blur', 'change'] }
                             ,{ min: 9, max: 120, validator: this.callTimesValidator, trigger: ['blur', 'change'] }]">
                <sweet-input-number v-model="showModel.policy.obOverTime" controls-position="right" placeholder="20"></sweet-input-number>
                <span>{{$t('isales.taskpolicy.field.time')}}</span>
            </sweet-form-item>
            <sweet-form-item :label="$t('isales.taskpolicy.field.previewAutoCallTime')" :required="!isBatchEdit" prop="policy.autoCallTime">
                <sweet-input-number v-model="showModel.policy.autoCallTime" controls-position="right" placeholder="5"></sweet-input-number>
                <span>{{$t('isales.taskpolicy.field.time')}}</span>
            </sweet-form-item>
        </div>
        <!--预占外呼-->
        <div v-if="showModel.taskInfo.taskType===this.taskTypeItem.PREEMPTION">
            <sweet-form-item :label="$t('isales.taskpolicy.field.obOverTime')" :required="!isBatchEdit" prop="policy.obOverTime"
                             :rules="[{ required: true, message: this.$t('isales.business.result.message.must'), trigger: ['blur', 'change'] }
                             ,{ min: 5, max: 120, validator: this.callTimesValidator, trigger: ['blur', 'change'] }]">
                <sweet-input-number v-model="showModel.policy.obOverTime" controls-position="right" placeholder="20"></sweet-input-number>
                <span>{{$t('isales.taskpolicy.field.time')}}</span>
            </sweet-form-item>
        </div>
        <!--自动&智能外呼-->
        <div v-if="showModel.taskInfo.taskType===this.taskTypeItem.INTELLIGENT || showModel.taskInfo.taskType===this.taskTypeItem.AUTO">
            <sweet-form-item :label="$t('isales.taskpolicy.field.obOverTime')" :required="!isBatchEdit" prop="policy.obOverTime"
                             :rules="[{ required: true, message: this.$t('isales.business.result.message.must'), trigger: ['blur', 'change'] }
                             ,{ min: 5, max: 120, validator: this.callTimesValidator, trigger: ['blur', 'change'] }]">
                <sweet-input-number v-model="showModel.policy.obOverTime" controls-position="right" placeholder="20"></sweet-input-number>
                <span>{{$t('isales.taskpolicy.field.time')}}</span>
            </sweet-form-item>
            <sweet-form-item :label="$t('isales.taskpolicy.field.obCallCount')" :required="!isBatchEdit" prop="policy.obCallCount"
                             :rules="[{ required: true, message: this.$t('isales.business.result.message.must'), trigger: ['blur', 'change'] }
                             ,{ min: 1, max: 100, validator: this.callTimesValidator, trigger: ['blur', 'change'] }]">
                <sweet-input-number v-model="showModel.policy.obCallCount" controls-position="right" placeholder="10"></sweet-input-number>
            </sweet-form-item>
            <sweet-form-item :label="$t('isales.taskpolicy.field.failInterval')" :required="!isBatchEdit" prop="policy.failInterval"
                             :rules="[{ required: true, message: this.$t('isales.business.result.message.must'), trigger: ['blur', 'change'] }
                             ,{ min: 1, max: 9999, validator: this.callTimesValidator, trigger: ['blur', 'change'] }]">
                <sweet-input-number v-model="showModel.policy.failInterval" controls-position="right" placeholder="20"></sweet-input-number>
                <span>{{$t('isales.taskpolicy.field.time')}}</span>
                <span style="font-size: 11px; padding: 10px; color: #e6b679"  v-if="showModel.policy.failInterval!=null && showModel.policy.failInterval < 10">{{ $t('isales.failInterval.check')}}</span>
            </sweet-form-item>
        </div>
        <!--数据提取条件-->
        <sweet-form-item :label="$t('isales.calldata.prop.filter')" >
            <sweet-switch v-model="isShowCondition" @change="switchFilterPolicy" :disabled="!isCreate && !isCreateTemplate && !isEdit && !isEditTemplate"/>
        </sweet-form-item>
        <sweet-row>
            <sweet-col :span="24" v-if="isShowCondition" class="isales-background-color">
                <sweet-form-item>
                    <template #label><span></span></template>
                    <div style="width: 750px">
                        <!--主条件-->
                        <aicc-table
                                    :tableData="showModel.filterPolicy.primaryCondition"
                                    :tableTools="{ columnConfig: false }"
                                    :tableColumns="conditionTableColumns"
                                    size="small">
                            <template #condition="scope">
                                <div class="aicc-align--absolute-left aicc-p-bottom--small aicc-p-top--small">
                                    <sweet-input v-model="scope.row.propName" class="width-180px-to-rem aicc-m-right--medium" readonly/>
                                    <div class="width-180px-to-rem aicc-m-right--medium">
                                        <sweet-select v-model="scope.row.operator" value-key="value" :placeholder="$t('isales.taskinfo.placeholder.choose')" :fit-input-width="true" :show-resize="false">
                                            <sweet-option v-for="item in OPERATOR" :value="item.value"
                                                          :label="item.label"></sweet-option>
                                        </sweet-select>
                                    </div>
                                    <sweet-form-item :prop="`filterPolicy.primaryCondition.${scope.$index}.value`"
                                                     :rules="[{ required: true, message: this.$t('isales.business.result.message.must'), trigger: ['blur', 'change']},{maxLength: 40, validator: this.$Validate.maxLengthValidator, trigger: ['blur', 'change']}]">
                                        <sweet-input v-model="scope.row.value" class="width-180px-to-rem aicc-m-right--medium"/>
                                    </sweet-form-item>
                                    <div class="width-180px-to-rem">
                                        <sweet-select
                                                v-if="scope.$index < (showModel.filterPolicy.primaryCondition.length - 1)"
                                                v-model="showModel.filterPolicy.operator"
                                                value-key="value" :fit-input-width="true" :show-resize="false">
                                            <sweet-option v-for="item in LOGIC" :value="item.value"
                                                          :label="item.label"></sweet-option>
                                        </sweet-select>
                                    </div>
                                </div>
                            </template>
                            <template #action="scope">
                                <sweet-button class="task-icon" :icon="Delete" type="icon"
                                              @click="deletePrimaryCondition(scope)"/>
                            </template>
                        </aicc-table>
                        <!--添加主条件-->
                        <div v-if="!isView && !isViewTemplate" class="addInfo">
                            <sweet-button @click="addCondition" class="task-icon" style="margin-right: 12px" :icon="CirclePlus" type="icon">
                                <span class="create-button">{{ $t('isales.tasklogic.field.addCondition') }}</span>
                            </sweet-button>
                        </div>
                        <!--子条件-->
                        <div v-show="showModel.filterPolicy.primaryCondition && showModel.filterPolicy.primaryCondition.length>0"
                             class="aicc-m-top--medium">
                            <aicc-table :tableData="showModel.filterPolicy.subConditions"
                                        :tableColumns="conditionTableSubColumns"
                                        :tableTools="{ columnConfig: false }"
                                        size="small">
                                <template #condition="scope">
                                    <div v-for="(item, index) in scope.row.subConditions"
                                         class="aicc-align--absolute-left aicc-p-bottom--small aicc-p-top--small"
                                         :class="{'margin-bottom-10px-to-rem': index < (scope.row.subConditions.length - 1)}">
                                        <sweet-input v-model="item.propName" class="width-180px-to-rem aicc-m-right--medium" readonly/>
                                        <div class="width-180px-to-rem aicc-m-right--medium">
                                            <sweet-select v-model="item.operator" value-key="value" :placeholder="$t('isales.taskinfo.placeholder.choose')" :fit-input-width="true" :show-resize="false">
                                                <sweet-option v-for="operator in OPERATOR" :value="operator.value"
                                                              :label="operator.label"></sweet-option>
                                            </sweet-select>
                                        </div>
                                        <sweet-form-item :prop="`filterPolicy.subConditions.${scope.$index}.subConditions.${index}.value`"
                                                         :rules="[{ required: true, message: this.$t('isales.business.result.message.must'), trigger: ['blur', 'change']}
                                        ,{maxLength: 40, validator: this.$Validate.maxLengthValidator, trigger: ['blur', 'change']}]">
                                            <sweet-input v-model="item.value" class="width-180px-to-rem aicc-m-right--medium" />
                                        </sweet-form-item>
                                        <div class="width-180px-to-rem">
                                            <sweet-select v-if="index < (scope.row.subConditions.length - 1)"
                                                          v-model="scope.row.operator"
                                                          value-key="value"
                                                          disabled :placeholder="$t('isales.taskinfo.placeholder.choose')" :fit-input-width="true" :show-resize="false">
                                                <sweet-option v-for="logic in LOGIC" :value="logic.value"
                                                              :label="logic.label"></sweet-option>
                                            </sweet-select>
                                        </div>
                                    </div>
                                </template>
                                <template #action="scope">
                                    <sweet-button class="task-icon" :icon="Delete" type="icon"
                                                  @click="deleteSubCondition(scope)"/>
                                </template>
                            </aicc-table>
                        </div>
                        <!--添加子条件-->
                        <div v-show="!isView && !isViewTemplate && this.showModel.filterPolicy.primaryCondition && this.showModel.filterPolicy.primaryCondition.length>0"
                             class="addInfo">
                            <sweet-button @click="addSubCondition" class="task-icon" style="margin-right: 12px" :icon="CirclePlus" type="icon">
                                <span class="create-button">{{ $t('isales.tasklogic.field.addSubCondition') }}</span>
                            </sweet-button>
                        </div>
                    </div>
                </sweet-form-item>
            </sweet-col>
        </sweet-row>
    </div>
    <!--数据提取条件-选择属性-->
    <ChooseProp v-if="showChooseProp"
                v-model:show="showChooseProp"
                :selectedAttributes=selectedAttributes
                mode="pickUp"
                @confirm="confirmChooseCondition"></ChooseProp>
</template>

<script>
    import ChooseProp from "@/views/isales/components/ChooseProp/ChooseProp.vue";
    import {LOGIC, OPERATOR, TASK_TYPE} from '@/views/isales/views/taskManagement/enum'
    import {PREDICT_METHOD, UTIL_RATE_METHOD} from "@/views/isales/views/systemAutoCallTask/enum";
    import {Delete, CirclePlus} from '@element-plus/icons-vue'

    export default {
        name: "TaskBasicPolicy",
        components: {
            ChooseProp
        },
        props: {
            showModel: {
                type: Object,
                default() {
                    return {}
                }
            },
        },
        data() {
            return {
                queryType:'',
                taskTypeItem: TASK_TYPE,
                predictMethodItem: PREDICT_METHOD,
                utilRateMethodItem: UTIL_RATE_METHOD,
                isShowCondition: false,
                OPERATOR,
                LOGIC,
                Delete,
                CirclePlus,
                currentTaskType: '',
                isOpenPropertyFilter: false,
                showChooseProp: false,
                selectedAttributes: [], // 记录所有已选择的属性，给弹窗中的选项置灰
                conditionTableColumns: [
                    {
                        label: this.$t('isales.taskInfo.title.filterCondition'), // 筛选条件
                        slot: 'condition'
                    },
                    {
                        label: this.$t('isales.common.title.operation'), // 操作
                        slot: 'action',
                        width: '80px'
                    }
                ],
              conditionTableSubColumns: [
                {
                  label: this.$t('isales.taskInfo.title.filterSubCondition'), // 筛选条件
                  slot: 'condition'
                },
                {
                  label: this.$t('isales.common.title.operation'), // 操作
                  slot: 'action',
                  width: '80px'
                }
              ],
            };
        },
        methods: {
          switchFilterPolicy(){
            if(!this.isShowCondition){
                this.showModel.filterPolicy.primaryCondition = null
                this.showModel.filterPolicy.subConditions = null
            }
          },
          callTimesValidator(rule, value, callback) {
            let reg = /[.]/;
            if (reg.test(value)) {
              callback($t('isales.task.promotion.numberOnly'));
            }
            if (Number(value) < rule.min || Number(value) > rule.max) {
              callback($t('isales.taskpolicy.message.rangeAutoCallTime', [rule.min, rule.max]));
            } else {
              callback();
            }
          },
            validMaxAvgCallTime  (rule, value, callback)  {
                if (Number(value) < Number(this.showModel.policy.minAvgCallTime)) {
                    callback($t('isales.taskpolicy.message.input.maxAvgCallTime'));
                } else {
                    callback()
                }
            },
            validMinAvgCallTime (rule, value, callback) {
                if (Number(value) > Number(this.showModel.policy.maxAvgCallTime)) {
                    callback($t('isales.taskpolicy.message.input.minAvgCallTime'));
                } else {
                    callback()
                }
            },
            confirmChooseCondition(data) {
                if (!data.length) return
                if (this.addConditionType === 'primary') {
                    this.setPrimaryCondition(data)
                } else {
                    this.setSubCondition(data)
                }
            },
            setPrimaryCondition(data) {
                if(!this.showModel.filterPolicy.primaryCondition){
                  this.showModel.filterPolicy.primaryCondition = []
                }
                data.forEach(item => {
                    let count = item.count
                    while (count) {
                        this.showModel.filterPolicy.primaryCondition.push({
                            propName: item.propName,
                            propId: item.propId,
                            mapField: item.mapField,
                            value: '',
                            operator: 'eq'
                        })
                        if (!this.selectedAttributes.includes(item.propName)) {
                            this.selectedAttributes.push(item.propName)
                        }
                        count--
                    }
                })
            },
            setSubCondition(data) {
                if (!data.length) {
                    return
                } else {
                    let temp = []
                    data.forEach(item => {
                        let count = item.count
                        while (count) {
                            temp.push({
                                propName: item.propName,
                                propId: item.propId,
                                mapField: item.mapField,
                                value: '',
                                operator: 'eq'
                            })
                            if (!this.selectedAttributes.includes(item.propName)) {
                                this.selectedAttributes.push(item.propName)
                            }
                            count--
                        }
                    })
                  if(!this.showModel.filterPolicy.subConditions){
                    this.showModel.filterPolicy.subConditions = []
                  }
                    this.showModel.filterPolicy.subConditions.push({
                        operator: 'and',
                        subConditions: temp
                    })
                }
            },
            deletePrimaryCondition(scope) {
                const index = scope.$index
                this.showModel.filterPolicy.primaryCondition.splice(index, 1)
                const selectedAttributeIndex = this.selectedAttributes.findIndex(item => item === scope.row.propName)
                if (selectedAttributeIndex !== -1) {
                    this.selectedAttributes.splice(selectedAttributeIndex, 1)
                }
                // 如果主条件删空了，要清空子条件
                if (!this.showModel.filterPolicy.primaryCondition || this.showModel.filterPolicy.primaryCondition.length<=0) {
                    this.showModel.filterPolicy.subConditions.splice(0, this.showModel.filterPolicy.subConditions.length)
                    // 不能直接重新给数组赋值，需要操作原数组，否则传给弹窗的数据会失去响应式
                    this.selectedAttributes.splice(0, this.selectedAttributes.length)
                }
            },
            deleteSubCondition(scope) {
                const index = scope.$index
                scope.row.subConditions.forEach(item => {
                    const selectedAttributeIndex = this.selectedAttributes.findIndex(selectedItem => selectedItem === item.propName)
                    if (selectedAttributeIndex !== -1) {
                        this.selectedAttributes.splice(selectedAttributeIndex, 1)
                    }
                })
                this.showModel.filterPolicy.subConditions.splice(index, 1)
            },
            addCondition() {
                this.showChooseProp = true
                this.addConditionType = 'primary'
            },
            addSubCondition() {
                this.showChooseProp = true
                this.addConditionType = 'sub'
            },
            setSysAutoSelectedAttributes(param) {
                if(param){
                    this.isShowCondition = true;
                }
                // 从返回体中取出已选的属性值
                let temp = []
                this.showModel.filterPolicy.subConditions.forEach(conditions => {
                    temp.push(...conditions.subConditions)
                })
                temp.push(...this.showModel.filterPolicy.primaryCondition)
                temp = temp.map((item) => item.propName)
                this.selectedAttributes.splice(0, this.selectedAttributes.length)
                this.selectedAttributes.push(...temp);

            },
          handleBlurObOverTime(event) {
            this.showModel.policy.obOverTime = event.target.value;
          },
          handleBlurMaxAvgCallTime(event) {
            this.showModel.policy.maxAvgCallTime = event.target.value;
          },
          handleBlurMinAvgCallTime(event) {
            this.showModel.policy.minAvgCallTime = event.target.value;
          },
          handleBlurTalkingRate(event) {
            this.showModel.policy.talkingRate = event.target.value;
          },
          handleBlurLossRate(event) {
            this.showModel.policy.lossRate = event.target.value;
          },
          handleBlurUtilRate(event) {
            this.showModel.policy.utilRate = event.target.value;
          },
          handleBlurWaitTime(event) {
            this.showModel.policy.waitTime = event.target.value;
          },
          handleBlurConnRate(event) {
            this.showModel.policy.connRate = event.target.value;
          },
          handleBlurPickupRate(event) {
            this.showModel.policy.pickupRate = event.target.value;
          },
        },
        computed: {
            isView() {
                return this.queryType === 'view'
            },
            isEdit() {
                return this.queryType === 'edit'
            },
            isCreate() {
                return this.queryType === 'create'
            },
            isBatchEdit() {
                return this.queryType === 'batchEdit'
            },
            isCreateTemplate(){// 新建外呼任务模型
                return this.queryType === 'createTemplate'
            },
            isEditTemplate(){// 编辑外呼任务模型
                return this.queryType === 'editTemplate'
            },
            isViewTemplate(){// 编辑外呼任务模型
                return this.queryType === 'viewTemplate'
            },
        },
        async created() {
            this.queryType = this.$route.query.type;
        }
    }
</script>

<style lang="less" scoped>
    .card-header {
        padding-bottom: 30px;
        font-size: var(--16px-to-rem);
        color: #1C1C1C;
        font-weight: bold;
    }

    .isales-task-info-add {
        font-size: 14px;
        color: #366BFC;
        font-weight: bold;
    }

    .width-180px-to-rem {
        width: var(--180px-to-rem) !important;
    }

    .icon-delete-condition {
        font-size: 20px !important;
    }

    .task-icon {
        font-size: 18px !important;
        color: #366BFC;
    }

    .margin-bottom-10px-to-rem {
        margin-bottom: 0.625rem;
    }

    .isales-background-color {
        padding: 20px 10px;
        background-color: #FBFBFB;
    }
    .addInfo {
        width: 100%;
        height: 45px;
        border: 1px solid rgba(238, 238, 238, 1);
        border-top: 0;
        display: flex;
        padding-inline-start: 1rem;
        font-size: 14px;
        color: #366BFC;
        font-weight: bold;
        align-items: center;
        &-wrap {
            display: flex;
            gap: 10px;
        }
    }

    .create-button {
        font-size: 14px;
        font-weight: bold;
    }
</style>