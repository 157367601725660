import {_5gEmojiList} from '@/views/ccmessaging/emotion/emoji5GList.js'
import {facebookEmoji} from '@/views/ccmessaging/emotion/emojiFacebookList.js'
import {twitterEmoji} from '@/views/ccmessaging/emotion/emojiTwitterList.js'
import {emojiParse} from "@/views/ccmessaging/emotion/emotionconf"
import {getCCmeAssetsURL, getCCmeAssetsImgURL} from "@/views/ccmessaging/utils/index.js"

/**
 * 过滤位置特殊字符
 *
 * @param chars 字符
 * @returns {*} 过滤后的字符
 */
export const filterSpc = (chars) => {
    return chars.replace(/;/g, '');
}

/**
 * 判断是否是空字符串
 *
 * @param str 字符串
 * @returns {boolean} 是否是空字符串
 */
export const isBlankStr = (str) => {
    return null == str || str.trim() === "";
}

/**
 * 判断是否非空字符串
 *
 * @param str 字符串
 * @returns {boolean} 是否非空字符串
 */
export const isNotBlank = (str) => {
    return !(str == null || str === "");
}

export const generateMessageCode = () =>{
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, str => {
        let randomNumber = Math.random() * 16 | 0
        let result = str === 'x' ? randomNumber : (randomNumber & 0x3 | 0x8)
        return result.toString(16)
    })
}

/**
 * 定位数据修复
 *
 * @param locale 位置
 * @returns {string|*}
 */
export const locateFix = (locale) => {
    if (isBlankStr(locale)) {
        return locale;
    }

    let decimalsArr = locale.split(".");
    if (decimalsArr.length < 2) {
        return locale;
    }

    let decimal = decimalsArr[1];
    return decimalsArr[0] + "." + decimal.substr(0, 6);
}

/**
 * 根据用户所属渠道获取地图服务url
 *
 * @param channel 渠道
 * @param latitude 纬度
 * @param longitude 经度
 * @param name 名称
 * @param desc 描述
 * @param locationUrlArr url
 * @returns {string} url
 */
export const findLocationDetailUrlByCurrentUserChannel = (channel, latitude, longitude, name, desc, locationUrlArr) => {
    for (let pos in locationUrlArr) {
        if (locationUrlArr[pos].channel !== channel) {
            continue;
        }
        let locationUrl = locationUrlArr[pos].locationUrl;
        let mapService = locationUrlArr[pos].mapService;
        // 谷歌地图
        if ("google" === mapService) {
            return locationUrl + "?api=1&query=" + latitude + "," + longitude;
        }
        // 腾讯地图
        if (isBlankStr(desc)) {
            desc = name;
        }
        let marker = "coord:" + latitude + "," + longitude + ";title:" + filterSpc(name) + ";addr:" + filterSpc(desc);
        return locationUrl + "?marker=" + encodeURIComponent(marker) + "&referer=AICC";
    }
}

/**
 * 用于区分不同渠道不同用户的标识，格式为： channelId + " " + userId
 *
 * @param channelId 渠道id
 * @param userId 用户id
 * @returns {string} uid
 */
export const getUID = (channelId, userId) => {
    return channelId + " " + userId;
}

/**
 * 文本html转义
 *
 * @param text 文本
 * @returns {string}
 */
export const escapeText = (text) => {
    text = '' + text;
    var map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#x27;',
        '/': '&#x2F;',
        '(': '&#40;',
        ')': '&#41;'

    };
    return text.replace(/[&<>"'()/]/g, function (m) {
        return map[m];
    });
}

/**
 * 查找iframe页面
 *
 * @param iframeId iframe页面id
 * @returns {*}
 */
export const findIframe = (iframeId) => {
    var iframes = top.document.getElementsByTagName("iframe")
    var targetIframes;
    for (let i = 0; i < iframes.length; i++) {
        targetIframes = iframes[i];
        // eslint-disable-next-line no-undef
        if ($(targetIframes).attr('innerid') == iframeId) {
            break;
        }
    }
    return targetIframes;
}

/**
 * 获取操作系统
 *
 * @param userAgent useragent
 */
export const getOsInfo = (userAgent) => {
    if (!userAgent) {
        return "Unknown";
    }
    let agent = userAgent.toLowerCase();
    let name;
    let version = "Unknown";
    if (agent.indexOf("win") > -1) {
        name = "Windows";
        if (agent.indexOf("windows nt 5.0") > -1) {
            version = "2000";
        } else if (agent.indexOf("windows nt 5.1") > -1 || agent.indexOf("windows nt 5.2") > -1) {
            version = "XP";
        } else if (agent.indexOf("windows nt 6.0") > -1) {
            version = "Vista";
        } else if (agent.indexOf("windows nt 6.1") > -1 || agent.indexOf("windows nt 7") > -1) {
            version = "7";
        } else if (agent.indexOf("windows nt 6.2") > -1 || agent.indexOf("windows nt 8") > -1) {
            version = "8";
        } else if (agent.indexOf("windows nt 6.3") > -1) {
            version = "8.1";
        } else if (agent.indexOf("windows nt 6.2") > -1 || agent.indexOf("windows nt 10.0") > -1) {
            version = "10";
        } else {
            version = "Unknown";
        }
    } else if (agent.indexOf("iphone") > -1) {
        name = "Iphone";
    } else if (agent.indexOf("mac") > -1) {
        name = "Mac";
    } else if (agent.indexOf("x11") > -1 || agent.indexOf("unix") > -1 || agent.indexOf("sunname") > -1 || agent.indexOf("bsd") > -1) {
        name = "Unix";
    } else if (agent.indexOf("linux") > -1) {
        if (agent.indexOf("android") > -1) {
            name = "Android"
        } else {
            name = "Linux";
        }
    } else {
        name = "Unknown";
    }
    return name + " " + version;
}

/**
 * 获取浏览器信息
 *
 * @param userAgent useragent
 */
export const getBrowserInfo = (userAgent) => {
    if (!userAgent) {
        return "Unknown";
    }
    var agentInfo = userAgent.toLowerCase();
    var regStrIe = /msie [\d.]+;/gi;
    var regStrFire = /firefox\/[\d.]+/gi;
    var regStrChrome = /chrome\/[\d.]+/gi;
    var regStrSaf = /safari\/[\d.]+/gi;
    // IE
    if (agentInfo.indexOf("msie") > 0) {
        return agentInfo.match(regStrIe);
    }
    // firefox
    if (agentInfo.indexOf("firefox") > 0) {
        return agentInfo.match(regStrFire);
    }
    // Chrome
    if (agentInfo.indexOf("chrome") > 0) {
        return agentInfo.match(regStrChrome);
    }
    // Safari
    if (agentInfo.indexOf("safari") > 0 && agentInfo.indexOf("chrome") < 0) {
        return agentInfo.match(regStrSaf);
    }
}

/**
 * 获取Blob格式
 *
 * @param fileType 文件类型
 * @returns {string}
 */
export const getBlobFormat = (fileType) => {
    // 获取发送文件的类型 音频、视频、JPG/BPM/PNG图片、Txt/CSV文件
    let messageType;
    switch (fileType) {
        case "txt":
            messageType = "text/plain";
            break;
        case "csv":
            messageType = "text/csv";
            break;
        case "mp3":
            messageType = "audio/mp3";
            break;
        case "jpg":
        case "jpeg":
            messageType = "image/jpeg";
            break;
        case "doc":
            messageType = "application/doc";
            break;
        case "docx":
            messageType = "application/docx";
            break;
        case "pdf":
            messageType = "application/pdf";
            break;
        case "zip":
            messageType = "application/zip";
            break;
        case "png":
            messageType = "image/png";
            break;
        case "gif":
            messageType = "image/gif";
            break;
        default:
            messageType = "";
    }
    return messageType;
}

/**
 * 封装获取缓存：过期删除
 *
 * @param dataKey 键名
 * @param expireTime 超时时间
 * @param dataType 类型
 * @returns data 缓存值
 */
export const getStorageItem = (dataKey, expireTime, dataType) => {
    let exp = expireTime || 1800000;
    let type = dataType || 'general';
    let data = sessionStorage.getItem(dataKey);
    if (typeof data == "undefined" || data == null || data === "") {
        return data;
    }
    let dataObj = JSON.parse(data);
    if (dataObj.data !== [] && dataObj.data.length !== 0) {
        if (new Date().getTime() - dataObj.time > exp) {
            sessionStorage.removeItem(dataKey);
        } else {
            if (type === 'map') {
                let strMap = new Map();
                for (let k of Object.keys(dataObj.data)) {
                    strMap.set(k, dataObj.data[k]);
                }
                return strMap;
            } else {
                return JSON.parse(dataObj.data);
            }
        }
    }
}

/**
 * 判断是否是表情
 *
 * @param content 内容
 * @param channel 渠道
 * @param emotionPath 表情配置路径
 * @returns {string|emojiParse|*}
 */
export const isEmotion = (content, channel, emotionPath) => {
    if (content && (content.lastIndexOf("[:") !== -1 || content.lastIndexOf("[,") !== -1)) {
        let opts = {
            content: content,
            emojis: [
                {
                    path: 'emotion/emoji',
                    code: ',',
                    type: 'emoji'
                },
                {
                    path: 'emotion/wechat',
                    code: ':',
                    type: 'wechat'
                }
            ]
        }
        content = emojiParse(opts)
    }

    if (channel === "FACEBOOK") {
        return parseEmoji(content, emotionPath, facebookEmoji)
    } else if (channel === "TWITTER") {
        return parseEmoji(content, emotionPath, twitterEmoji)
    } else if (channel === "FIVEG" || channel === "5G") {
        return parseEmoji(content, emotionPath, _5gEmojiList)
    }
    return content
}

/**
 * 转换表情
 *
 * @param content 内容
 * @param emotionPath 表情配置路径
 * @param emojiNameList 表情配置列表
 * @returns {*}
 */
export const parseEmoji = (content, emotionPath, emojiNameList) => {
    let reg = /\[,[a-zA-Z0-9._-]+]/g
    let result = content.match(reg)
    if (result) {
        let patt02 = /^\[,/
        let patt03 = /]$/
        for (let i = 0; i < result.length; i++) {
            let tmp = result[i].replace(patt02, '')
            tmp = tmp.replace(patt03, '')
            if (!isImgName(tmp, emojiNameList)) {
                continue
            }
            let path1 = getCCmeAssetsURL(emotionPath + tmp)
            content = content.replace(result[i], '<img src="' + path1 + '" width="25" height="25" alt="">')
        }
    }
    return content
}

/**
 * 判断是否是已有表情图片
 *
 * @param name 表情图片名称
 * @param emojiNameList 表情配置列表
 * @returns {boolean}
 */
export const isImgName = (name, emojiNameList) => {
    for (let i = 0; i < emojiNameList.length; i++) {
        if (emojiNameList[i] === name) {
            return true
        }
    }
    return false
}

/**
 * 兼容处理头像链接
 *
 * @param senderAvatar
 * @returns {string}
 */
export const processAvatarSrc = (senderAvatar) => {
  if (!senderAvatar) {
    return  getCCmeAssetsImgURL('online-chat-workbench/system-avatar/avatar-icon.png');
  }
  let result
  if (senderAvatar === undefined || senderAvatar === null || senderAvatar === '') {
    result = getCCmeAssetsImgURL('online-chat-workbench/user1.png')
  } else if (senderAvatar.startsWith("http")) { // 第三方头像
    result = senderAvatar
  } else if (senderAvatar.startsWith("../../image/system-avatar/")) {
    let senderAvatarArrs = senderAvatar.split("/")
    let imageName = senderAvatarArrs[senderAvatarArrs.length - 1]
    result = getCCmeAssetsImgURL('online-chat-workbench/' + imageName)
  } else if (senderAvatar.length === 1) { // 系统头像
    result = getCCmeAssetsImgURL('online-chat-workbench/system-avatar/' + senderAvatar + ".png")
  } else if (senderAvatar === "../../assets/img/online-chat-workbench/icon_robot.png") { // 机器人头像
    result = getCCmeAssetsImgURL('online-chat-workbench/icon_robot.png')
  } else if (senderAvatar === "DefaultAvatar" || senderAvatar === "../../image/agentAvatar.png") { // 座席头像
    result = getCCmeAssetsImgURL('online-chat-workbench/agentAvatar.png')
  } else if (senderAvatar === "../../image/user1.png") { // 用户头像
    result = getCCmeAssetsImgURL('online-chat-workbench/user1.png')
  }
  return result
}

/**
 * 记录键位判断刷新或者关闭事件
 *
 * @param currentKeyCode 当前按下键位
 * @param altKey alt键位
 * @param rightKey 鼠标右键
 * @return listenType 刷新 or 关闭
 */
export const isReloadOrClose = (currentKeyCode, altKey, rightKey) => {
    let listenType
    if (currentKeyCode === 116 || rightKey) {
        listenType = "reload"; // 标示页面刷新
    } else {
        listenType = "close"; // 标示页面关闭
    }
    return listenType;
}

/**
 * 封装设置缓存：加过期时间
 *
 * @param key 键
 * @param value 值
 * @param type 类型
 */
export const setStorageItem = (key, value, type) => {
    type = type || 'general'
    if (type === 'map') {
        let obj = Object.create(null)
        for (let [k, v] of value) {
            obj[k] = v
        }
        value = obj
    }
    let curTime = new Date().getTime()
    sessionStorage.setItem(key, JSON.stringify({data: value, time: curTime}))
}