export default {
  "provision.encryptinfo.historical.version" : "الإصدار السابق",
  "provision.callcent.platformserver.ip" : "عنوان IP الخاص بالخدمة",
  "provision.subscribedetail.success.save" : "تم الحفظ بنجاح.",
  "provision.subscribedetail.label.createTime" : "التطبيق في",
  "provision.callcent.error.accesscode.deletefail.all.onccgateway" : "فشل حذف رمز الوصول على النظام الأساسي",
  "provision.callcenterinstanceslist.vcallcenter.nameDiffListPromt" : "يوجد مركز مكالمات ظاهري به VccName غير متوافق مع VdnName في WAS. VccId هو",
  "provision.callcent.success.updateinfo" : "تم التحديث بنجاح.",
  "provision.nodepolicy.sync.success" : "نجحت المزامنة.",
  "chat.usefulexpressions.message.saveSuccess" : "تم حفظ العبارة العامة بنجاح.",
  "chat.client.greeting.dear" : "الشرفاء",
  "provision.systemparam.url.safe.tip" : "يتم استخدام بروتوكولات غير آمنة، مما يسبب مخاطر أمنية",
  "provision.spinfo.addspinfosuccess" : "تمت إضافة معلومات SP بنجاح.",
  "provision.tenant.chooseAC.first" : "حدد رمز وصول.",
  "chat.agent.title.enterText" : "يُرجى إدخال",
  "promotion.tenant.management.tenantedit.provisionerror" : "فشل تحديث مساحة المستأجر.",
  "chat.usefulexpressions.message.contentLength" : "تأكد من أن العبارة العامة تحتوي على 1000 حرف كحد أقصى.",
  "provision.callcenterinstanceslist.label.ttschannelUseRate" : "استخدام TTS",
  "provision.callcenterinstance.softPhone.length.Inconsistent" : "أطوال رقم البداية ورقم النهاية غير متناسقة.",
  "provision.softPhone.label.uapaddress.node2" : "عنوان IP UAP للعقدة 2",
  "provision.softPhone.label.uapaddress.node1" : "عنوان IP UAP للعقدة 1",
  "provision.tenantspace.table.chooseStatus" : "حالة مساحة المستأجر",
  "provision.tenantspace.save.success" : "تم الحفظ بنجاح.",
  "chat.agent.title.transfer" : "نقل إلى",
  "provision.tenantspace.popuppageurl.whiteListStyle" : "نوع العنوان",
  "provision.callcenterinstanceslist.config.wnTo" : "معرف الموظف النهائي",
  "provision.callcenterinstanceslist.ccdis.ccdisUpdate" : "التحرير",
  "provision.tenantspace.gw.searchName" : "الرجاء إدخال اسم خادم العبّارة للاستعلام.",
  "provision.callcenterinstanceslist.ccgateway.maxRegAgentNum" : "كمية الوكيل المتزامنة",
  "provision.subscribedetail.error.delete" : "فشل الحذف.",
  "provision.softPhone.table.forcemodifyconfim" : "تأكيد",
  "provision.callcent.error.updateaccesscode.fail.ondatabase" : "فشل \, في تعديل رمز الوصول في قاعدة البيانات",
  "chat.skill.label.inputName" : "الاسم",
  "provision.appsitesmanagement.sureDeleteData" : "قد يؤدي حذف هذا الموقع إلى فشل المستخدمين في تسجيل الدخول إلى تطبيق الهاتف المحمول. هل تريد بالتأكيد حذفه؟",
  "provision.spinfo.issuerId" : "معرّف المُصدر",
  "provision.appversionmanagement.pleaseSelect" : "-اختر-",
  "provision.tenant.management.pwd.checkmsg" : "كلمتا المرور مختلفتان.",
  "chat.session.label.evaluation" : "تقييم الجلسة",
  "provision.tenantspace.config.assignedworknonumber" : "معرفات الموظف المخصصة",
  "provision.tenantspace.obsServer.datasource.id" : "معرف مصدر البيانات",
  "provision.callcenter.ivr.alert" : "يجب أن تكون القيمة هي نفس القدرة المتزامنة لجهاز IVR الذي تم نشره.",
  "provision.callcenterinstance.accesscode.validatebeginaccesscode" : "لا يمكن أن يكون رقم بداية رمز الوصول أكبر من رقم النهاية.",
  "provision.tenantspace.management" : "إدارة TenantSpace",
  "provision.label.tenantexport.addExportTaskError" : "فشلت إضافة مهمة التصدير. يرجى التحقق من السجل.",
  "provision.callcenterinstanceslist.vcallcenter.comma" : ";",
  "provision.tenantspace.feature.agentSendLocalMediaFile.description" : "توفر هذه الميزة وظيفة إرسال ملفات الوسائط المتعددة المحلية بواسطة وكلاء لمساحة المستأجر.",
  "provision.callcent.error.deletesipserver.part.associationvcallcener" : "ترتبط بعض خوادم SIP المحددة بالمستأجرين ولا يمكن حذفها.",
  "provision.appversionmanagement.appPackage" : "حزمة الإصدار",
  "provision.callcenterinstanceslist.config.SysAdminAccount" : "حساب مسؤول النظام",
  "chat.session.label.exitQueueTime" : "وقت الخروج من قائمة الانتظار",
  "ccprovision.recorddrive.config.sftp.identitychecktips" : "تم تغيير حساب أو كلمة مرور خادم SFTP. للحفاظ على أمان المعلومات\، أدخل الحساب الحالي وكلمة المرور.",
  "provision.recorddrive.error.firstletter.repeated" : "حرف محرك الأقراص الأول موجود بالفعل",
  "provision.callcenterinstanceslist.config.adminWnTo" : "معرف الموظف النهائي للمسؤول",
  "provision.tenantspace.feature.VoiceNotificationSupport.featureParams.callPeriod.label" : "فترة المكالمة (من 1 إلى 60 ثانية)",
  "provision.common.message.success" : "النجاح",
  "provision.tenantspace.feature.OneClickTwoCallSupport.featureParams.callNums.label" : "عدد بيانات المكالمات (من 1 إلى 10)",
  "provision.tenantspace.feature.outboundCallMarketingSupport.description" : "بعد تمكين ميزة المكالمة الصادرة الذكية\، تدعم مساحة المستأجر وظيفة المكالمة الصادرة الذكية.",
  "provision.tenantspace.feature.forceVideoSelfRecord.forcibleSelfRecord" : "التسجيل الذاتي القسري لشاشة VDN",
  "provision.callcenterinstanceslist.table.obssyncconfim" : "تأكيد المزامنة",
  "provision.systemparam.error.reset" : "فشل إعادة تعيين المعلمة",
  "chat.session.endType.customerEnd" : "نهاية العميل",
  "provision.tenantspace.config.isCreateAccount" : "إنشاء حساب تلقائي لمركز الاتصال الافتراضي",
  "chat.agent.label.send" : "إرسال",
  "provision.tenantspace.table.stopTenantSpace.confirm" : "تأكيد التعليق",
  "provision.callcent.error.entrynotexsit" : "سجل قائمة الثقة غير موجود.",
  "provision.sysparam.config.reset" : "إعادة الضبط",
  "provision.callcent.platformserver.es" : "البحث عن خدمات (ElasticSearch)",
  "provision.callcenterinstanceslist.nodepolicy.save" : "حفظ",
  "provision.encryptinfo.title.encrypted.information" : "معلومات التشفير",
  "provision.callcent.platformserver.cobrowse" : "خدمة التصفح المشترك (التصفح المشترك)",
  "provision.callcenterinstanceslist.softPhone.newpassword" : "كلمة مرور جديدة لـ Softphone",
  "chat.skill.message.success" : "النجاح.",
  "provision.appversion.msg.uploadfile.strem.fail" : "فشل توليد دفق الملف.",
  "chat.skill.label.skillDetail" : "تفاصيل المهارة",
  "chat.skill.message.isExisted" : "اسم المهارة موجود بالفعل.",
  "provision.label.tenantexport.exportiong" : "تصدير",
  "provision.callcenterinstanceslist.vcallcenterDeatil.passValidateFailed" : "يجب أن تحتوي كلمة المرور على ثلاثة أنواع على الأقل مما يلي: حروف كبيرة، وحروف صغيرة، وأرقام، و characters: ~! {'@'} # $ % ^ & * () - الخاص التالي = + [ ] ; : \" \" \\, <. > /?`",
  "provision.callcenterinstanceslist.softPhone.modifyPassword" : "تغيير كلمة المرور",
  "provision.appsitesmanagement.batchDelete" : "حذف الحزمة",
  "provision.appversionmanagement.versionNumber" : "رقم الإصدار",
  "provision.tenantspace.feature.knowledgeBaseSupport.description" : "بعد تمكين ميزة قاعدة المعلومات، تدعم مساحة المستأجر وظيفة قاعدة المعلومات.",
  "provision.tenantspace.config.voicecall.more.agent.ivr.whithAms" : "لا يمكن أن يكون الحد الأقصى لعدد المكالمات الصوتية المتزامنة أقل من مجموع قنوات الصوت\، والفيديو\، والوسائط\، والوكلاء متعددي الاستخدامات\، وقنوات الصوت والفيديو IVR.",
  "provision.callcenterinstanceslist.vcallcenterDeatil.odfsStrValidateFailed" : "يُسمح فقط بالحروف والأرقام.",
  "provision.callcenterinstanceslist.label.maxcallnums" : "الحد الأقصى للمكالمات الصوتية المتزامنة",
  "provision.obs.success.sync" : "مزامنة ناجحة.",
  "chat.thirdportal.chatWindow.label.userName" : "الاسم",
  "provision.tenantspace.feature.cobrowse.cobrowseMaxNumber.placeholder" : "1–50000",
  "provision.tenant.management.adminaccount" : "حساب المسؤول",
  "provision.tenantspace.feature.moveAgentNum" : "الحد الأقصى لعدد الموظفين",
  "provision.tenant.need.first" : "حدد مركز المكالمات\، ورمز الوصول\، وعدد الموظفين.",
  "provision.appsitesmanagement.protocol.check" : "لاحظ أن بروتوكول الشبكة الذي يستخدمه عنوان الموقع الذي تم إدخاله ليس بروتوكولًا آمنًا. إذا كنت متأكدًا من استخدامه، فقد تكون هناك مخاطر أمنية.",
  "provision.tenantspace.save.fail.078add" : "فشل إنشاء مساحة المستأجر عند تكوين نظام مصادقة الطرف الثالث.",
  "ccprovision.recorddrive.config.sftp.serverconfig" : "تكوين خادم SFTP",
  "provision.tenantspace.config.tenant.webrtc.featurevalue.not.one" : "قيمة ميزة WebRTC المخصصة للمستأجر ليست 1.",
  "chat.usefulexpressions.message.selectExpressions" : "يرجى تحديد عبارة عامة.",
  "ccprovision.vcallecenter.config.popup.tab.configurationinterface.modifypassword" : "تعديل كلمة مرور التكوين",
  "chat.weChatConfig.message.skillExisted" : "تم ربط قائمة انتظار المهارات المحددة بحساب رسمي آخر.",
  "provision.tenantspace.feature.smsOrEmailMarketingSupport.sendMaxNumber.whatsApp.placeholder" : "تتراوح القيمة من 1 إلى 100000. تشير القيمة 0 إلى أنه لم يتم تعيين أي حد.",
  "provision.tenantspace.feature.anonymous" : "مكالمة صادرة مجهولة",
  "provision.spinfo.id" : "معرف الهوية",
  "provision.systemparam.error.validate.notmatchrulealpha" : "يرجى إدخال الحروف الهجائية الرقمية أو الشرطة السفلية، وابدأ فقط بالحروف أو الشرطة السفلية",
  "provision.tenantspace.feature.VoiceNotificationSupport" : "الإعلام الصوتي",
  "provision.tenantspace.cc.videoAgentUsage" : "استخدام وكيل الفيديو",
  "provision.callcent.error.deleteinfo.from.cms.bad.input" : "فشل التحقق من إدخال CMS.",
  "provision.tenantspace.resetpwdfaild.newPwd.information.is.wrong" : "فشل التعديل، معلومات newPwd غير صحيحة",
  "provision.tenantspace.config.success" : "تم تكوين مساحة المستأجر بنجاح",
  "chat.agentConsole.message.content.audio" : "[رسالة صوتية]",
  "ccprovision.IVRSprogress.config.button.async" : "مزامنة",
  "provision.callcenterinstanceslist.config.ccNameUpdateError" : "عدم تغيير اسم مركز الاتصالات عند تحرير مركز الاتصالات",
  "provision.callcent.error.createaccesscode.fail.ondatabase" : "فشل \, في إنشاء رمز الوصول في قاعدة البيانات",
  "provision.callcenterinstanceslist.table.synchronize" : "مزامنة",
  "provision.callcenterinstance.accesscode.batchcreate.fail.repeated" : "جميع رموز الوصول في مقطع الرقم الجديد موجودة بالفعل.",
  "provision.callcent.error.updateSoftNumPasswordInfo" : "خطأ في معلومات الحساب",
  "chat.webconfig.label.greeting" : "تحية طيبة",
  "chat.client.label.loginTitle" : "تسجيل الدخول",
  "provision.callcent.error.deleteccgateway.part.associationvcallcener" : "ترتبط بعض ملقمات CC-Gateway المحددة بالمستأجرين ولا يمكن حذفها.",
  "provision.tenantspace.config.chooseDefaultPhoneNumber" : "تحديد الرقم المتصل الافتراضي",
  "OPERLOGS.TENANTSPACE.SYNC" : "مزامنة مساحة المستأجر.",
  "chat.skill.label.maxQueueTimeInTable" : "الحد الأقصى لوقت الانتظار",
  "provision.subscribedetail.label.createSource" : "المصدر",
  "provision.tenantspace.timezone.config.error" : "فشل تعديل معلومات المنطقة الزمنية.",
  "provision.callcenterinstanceslist.label.videoAgentUseRate" : "استخدام وكيل الفيديو",
  "provision.tenantspace.feature.customercenter.open.confirmmsg" : "تعد ميزة AppCube أساس تشغيل ميزة مركز العملاء وسيتم تمكينها في وقت واحد. لا يمكن للمستأجرين الذين قاموا بتمكين ميزة مركز العملاء تنشيط ميزة البيانات المخصصة وسيتم إيقاف تشغيلها",
  "provision.tenantspace.config.ttschannelnums" : "TTS الكمية",
  "provision.tenantspace.save.fail.104-011" : "فشل تسجيل حذف الملف.",
  "provision.tenantspace.save.fail.104-010" : "لم يتم تكوين معلمة معرف عقدة التسجيل.",
  "provision.callcenterinstanceslist.table.viewing" : "المشاهدة",
  "chat.client.label.chatTitle" : "العميل",
  "provision.tenantspace.selectStastus.noncommercial" : "الاستخدام التجاري التجريبي",
  "provision.softphone.label.authtype.uap" : "مصادقة UAP",
  "provision.callcenterccaccesscodeslist.table.singledeleteconfim" : "هل تريد بالتأكيد حذف رمز الوصول المحدد؟ تأكد من أن رمز الوصول غير مقترن بمركز اتصالات افتراضي.",
  "provision.systemparam.error.validate.notmatchrulemin" : "معلمة الإدخال أقل من الحد الأدنى",
  "provision.callcent.error.updatesoftphonenumber.noexist" : "رقم الهاتف الناعم غير موجود.",
  "provision.tenantspace.sip.sipServerIP" : "عنوان IP لخادم SIP",
  "provision.tenantspace.detail.tenantspace.cannot.open.uploadfeature" : "ما إذا كان سيتم تشغيل تفريغ الموارد وميزة الاستعلام عبر الإنترنت لموارد التفريغ في نفس الوقت",
  "provision.tenantspace.config.videocall.more.videoagent" : "الحد الأقصى لعدد مكالمات الفيديو المتزامنة أكبر من أو يساوي عدد وكلاء الفيديو.",
  "provision.subscribedetail.error.title" : "خطأ",
  "provision.tenantspace.save.fail.104-009" : "فشل تسجيل التحويل.",
  "provision.nodepolicy.save.success" : "تم الحفظ بنجاح.",
  "provision.callcenterinstanceslist.vcallcenter.OBSServer" : "خادم المكالمات الصادرة OBS",
  "provision.tenantspace.save.fail.104-008" : "لم يتم تكوين معلمات نظام تنسيق التسجيل.",
  "provision.callcent.error.saveccgateway.nameinfo" : "فشل إنشاء\، الرجاء التأكد من وجود اسم عبّارة CC بالفعل.",
  "provision.tenantspace.save.fail.104-007" : "تنسيق ملف التسجيل غير مدعوم.",
  "provision.tenantspace.save.fail.104-006" : "المساحة الحرة غير كافية لفشل حذف التسجيل.",
  "provision.callcent.error.delete" : "فشل الحذف",
  "provision.tenantspace.save.fail.104-005" : "ملف التسجيل الذي فشل في حذف التسجيل غير موجود.",
  "provision.tenantspace.save.fail.104-004" : "فشل حذف التسجيل دون تعيين حرف محرك الأقراص.",
  "provision.tenantspace.save.fail.104-003" : "اسم ملف حذف التسجيل الفاشل غير صالح.",
  "chat.thirdportal.chatWindow.label.userEmail" : "صندوق البريد",
  "provision.tenantspace.save.fail.104-002" : "ليس لدى المستخدم إذن تشغيل تسجيل فحص الجودة / فحص الجودة.",
  "provision.tenantspace.save.fail.104-001" : "لم يتم تشغيل وظيفة تنزيل التسجيل.",
  "provision.callcenterinstanceslist.softPhone.password" : "كلمة المرور",
  "provision.authconfig.description.toolong.error" : "الوصف لا يمكن أن أطول تان 200.",
  "provision.callcent.error.edit" : "فشل التحرير.",
  "provision.callcenterinstanceslist.vcallcenter.vccName" : "اسم VDN",
  "provision.callcent.error.save" : "خطأ",
  "provision.tenant.management.tenantname.beginwithalpha" : "يجب أن تبدأ مع رسالة.",
  "provision.callcenterinstanceslist.sipServer.sipServerIpAdd" : "إضافة",
  "provision.tenantspace.save.fail.120222" : "عقدة CTI المشار إليها بواسطة IVR غير موجودة.",
  "provision.tenantspace.save.fail.120221" : "مجموعة المعلمات المشار إليها بواسطة IVR غير موجودة.",
  "provision.tenantspace.save.fail.120220" : "تمت الإشارة إلى IVR بواسطة مجموعة VDN ولا يمكن حذفها.",
  "provision.label.tenantexport.operation" : "العملية",
  "provision.callcenterinstance.softPhone.batchcreate.fail.repeated" : "جميع الأرقام في مقطع الرقم الجديد موجودة بالفعل.",
  "provision.callcenterinstanceslist.softPhone.batchdeleteconfim" : "هل تريد بالتأكيد حذف جميع أرقام الهواتف المحددة؟ تأكد من أن رقم الهاتف غير مرتبط بأي مركز اتصالات ظاهري.",
  "provision.tenant.management.adminname" : "مدير",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.availableWorkNo" : "لا يتوفر معرف موظف في مقطع معرف الموظف.",
  "provision.tenantspace.cc.maxVideoCallnums" : "الحد الأقصى لمكالمات الفيديو المتزامنة",
  "provision.tenantspace.cc.searchName" : "أدخل اسم مركز المكالمات المراد الاستعلام عنه.",
  "provision.tenantspace.save.fail.120219" : "تمت الإشارة إلى IVR بواسطة VDN ولا يمكن حذفه.",
  "provision.callcent.error.savesipserverdesinfo.regAgentNum" : ". يختلف عدد الوكلاء المسجلين المتزامنين عن عدد المقاعد المسجلة المتبقية.",
  "provision.tenantspace.save.fail.120217" : "الحد الأقصى لعدد IVR لنفس عنوان IP هو 20.",
  "provision.tenantspace.save.fail.120216" : "لا يمكن التعديل بين IVR وCCIVR.",
  "chat.weChatConfig.title.deleteTitle" : "حذف عنصر تكوين WeChat",
  "chat.skill.message.willDeleteSkill" : "هل تريد بالتأكيد حذف المهارة؟",
  "provision.tenantspace.config.param.error.videoUsage" : "الرجاء إدخال عدد وكلاء الفيديو.",
  "provision.callcent.error.regaddrformat" : "تنسيق عنوان التسجيل غير صحيح.",
  "chat.weChatConfig.label.codeName" : "رمز الوصول إلى القناة.",
  "ccprovision.IVRSprogress.config.button.edit" : "تحرير",
  "chat.client.message.evaluationFail" : "فشل استطلاع الرضا.",
  "provision.callcenterinstanceslist.obsServer.obsServerIpUpdate" : "تعديل",
  "provision.tenantspace.videoagentchannnels.limit" : "يجب أن يكون عدد قنوات موظف الفيديو أكبر من أو يساوي ضعف عدد موظفي الفيديو.",
  "provision.callcenterinstanceslist.vcallcenter.ServerNumber" : "رقم الخدمة",
  "provision.callcent.error.accesscode.deletefail.all.associationvcallcener" : "رمز الوصول مرتبط بالمستأجر ولا يمكن حذفه.",
  "chat.client.label.order" : "أوامري",
  "provision.tenantspace.config.ivrchannels" : "كمية قناة IVR",
  "provision.callcenterinstanceslist.table.nodeid" : "معرف العقدة",
  "pprovision.tenantspace.feature.emailAgentCCAndBcc.description" : "قم بتمكين نسخة موظف البريد الإلكتروني وميزة Bcc. تدعم المساحة المؤجرة نسخة موظف البريد الإلكتروني و Bcc.",
  "provision.tenantspace.common.agent.explain" : "العامل المشترك: يجيب على المكالمات الواردة، ويتحكم في المكالمات الصوتية ومكالمات الفيديو، ويرسل رسائل الوسائط المتعددة ويستقبلها.",
  "provision.tenantspace.config.chooseAnonymousCallServerTitle" : "حدد خادم مكالمة مجهول.",
  "provision.tenantspace.dataUpload.openResourceDump" : "يوفر وظيفة تفريغ الموارد لمساحة المستأجر. ملاحظة: حاليًا\، يتم دعم HUAWEI CLOUD OBS فقط. لاستخدام ميزة تفريغ الموارد\، يحتاج المستأجرون إلى شراء هذه الخدمة.",
  "provision.tenantspace.save.fail.120205" : "رمز الوصول إلى النظام مشار إليه بواسطة مجموعة VDN ولا يمكن حذفه.",
  "provision.tenantspace.selectStastus.stop" : "موقوف",
  "provision.tenantspace.save.fail.120204" : "رمز الوصول إلى النظام مشار إليه بواسطة VDN ولا يمكن حذفه.",
  "chat.weChatConfig.message.ensureDelete" : "هل تريد بالتأكيد حذف عنصر التكوين المحدد؟",
  "provision.tenantspace.table.success" : "النجاح",
  "provision.tenantspace.save.fail.120203" : "يتم تكرار رمز الوصول إلى النظام ورمز الوصول إلى اتصال شبكة NIRC.",
  "provision.callcenterinstanceslist.vcallcenter.UCSServer" : "خادم الوسائط المتعددة CCUCS",
  "provision.tenantspace.save.fail.120202" : "يتداخل رمز الوصول إلى النظام مع رمز وصول توجيه خاص.",
  "provision.recorddrive.selectrecorddrive.batchdeleteconfim" : "هل أنت متأكد من رغبتك في حذف كافة أحرف محركات الأقراص المحددة؟ يرجى التأكد من أن حرف محرك الأقراص ليست مشغولة من قبل المستأجر",
  "provision.tenantspace.save.fail.120201" : "رمز الوصول إلى النظام مكرر.",
  "provision.systemparam.error.validate.notmatchrulerange" : "تتجاوز معلمة الإدخال الحد الأقصى للقيمة أو أقل من الحد الأدنى للقيمة",
  "provision.tenantspace.sip.maxRegAgentNum" : "كمية الوكيل المسجلة المتزامنة",
  "provision.tenantspace.table.stopTenantSpace" : "هل أنت متأكد من أنك تريد تعليق المستأجر؟",
  "provision.tenant.management.tenantcreate.param.exceed" : "يجب أن يكون طول معلمة الإدخال أقل من 30 حرفًا.",
  "provision.callcenterinstanceslist.label.videoAgentCurCapacity" : "كمية وكيل الفيديو",
  "provision.tenantspace.config.chooseCcdis" : "الرجاء تحديد خادم CCDIS.",
  "provision.callcenterinstanceslist.softPhone.softPhoneNum.title" : "تكوين الهاتف البرمجي",
  "provision.tenant.management.tenantexport.exceedlimit2" : "، تضييق نطاق وقت البيانات أو إضافة شروط تصفية البيانات.",
  "provision.subscribedetail.label.emailStatus" : "إرسال البريد الإلكتروني",
  "provision.tenant.management.tenantexport.exceedlimit1" : "يمكن تصدير سجلات بيانات #*@$20} بحد أقصى.",
  "provision.tenant.management.DSTEndDate" : "تاريخ انتهاء DST",
  "provision.softphone.label.authtype.unified" : "المصادقة الموحدة",
  "chat.webconfig.label.intergratedcode" : "رمز التكامل",
  "provision.sysparam.config.mod" : "تحرير",
  "provision.callcent.error.updateinfo.maxchnsmall" : "لا يمكن أن يكون الحد الأقصى لعدد المكالمات المتزامنة أقل من عدد القنوات المشغولة.",
  "provision.spinfo.logoutUrl" : "عنوان URL لتسجيل الخروج",
  "chat.session.channel.all" : "جميعهم",
  "provision.tenantspace.feature.appCubeSupport" : "AppCube",
  "provision.tenantspace.save.fail.078delete" : "فشل حذف مساحة المستأجر عند تكوين نظام مصادقة الطرف الثالث.",
  "provision.whiteListAddress.PopMenu" : "شاشة منبثقة",
  "provision.tenantspace.feature.webrtc" : "بعد تمكين ميزة WebRTC\، تدعم مساحة المستأجر وظيفة WebRTC.",
  "provision.callcent.error.ccInstidInvalid" : "مثيل مركز المكالمات غير صالح.",
  "provision.ivr.error.sync" : "فشل المزامنة",
  "chat.skill.label.reset" : "إعادة تعيين",
  "provision.tenantspace.table.fail" : "فشل",
  "chat.usefulexpressions.message.contentNotNull" : "لا يمكن أن تكون العبارات العامة فارغة.",
  "provision.encryptinfo.encryptKey.length.error" : "طول المفتاح المطلوب:",
  "provision.tenantspace.common.case.explain" : "عند فتح القضية ، سيقوم المستأجر بفتح إمكانية الخدمة السحابية للخدمة.",
  "chat.timerange.message.timeerror" : "تنسيق الوقت المحدد غير صحيح.",
  "provision.tenantspace.rechoose" : "العشوائية",
  "provision.tenantspace.save.fail.120541" : "وكيل القدرة على المعالجة المتزامنة: 1-60.",
  "provision.tenantspace.ivr.searchName" : "الرجاء إدخال معرف IVR المراد الاستعلام عنه.",
  "provision.tenantspace.save.fail.120540" : "قيمة ترخيص الوسائط المتعددة خارج النطاق.",
  "provision.tenantspace.ConversionNoncommercial" : "التحويل التجريبي الاستخدام التجاري",
  "provision.label.tenantexport.success" : "النجاح",
  "chat.session.label.allChannel" : "جميع قنوات الوصول",
  "provision.tenantspace.cc.ttschannelUsagePercent" : "استخدام TTS",
  "provision.tenantspace.feature.forceVideoSelfRecord.percentageFactor" : "معامل نسبة تسجيل الشاشة (٪)",
  "provision.tenantspace.config.assignedworkno" : "معرف العمل المخصص",
  "provision.callcent.error.accesscode.deletefail.all.onccgateway.with.associationvcallcener" : "فشلت جميع عمليات الحذف لأن جزءًا من رمز الوصول مرتبط بالمستأجر، ويتم حذف بعض رموز الوصول على النظام الأساسي.",
  "provision.tenantspace.table.maxcallnumswithdesc" : "الحد الأقصى للمكالمات الصوتية المتزامنة (>= مجموع الوكلاء وقنوات IVR)",
  "provision.tenant.management.tenantname" : "مساحة المستأجر",
  "provision.tenantspace.feature.appcubeIkbs.close.error" : "قم بتعطيل ميزة قاعدة المعرفة قبل تعطيل ميزة AppCube.",
  "provision.ccp.redis.feature.failed" : "فشل تنفيذ بيانات ميزة المستأجر.",
  "provision.tenantspace.cc.current" : "السعة الحالية",
  "provision.spinfo.serviceUrl" : "عنوان URL للخدمة",
  "provision.tenantspace.save.fail.120539" : "نوع ترخيص وسائط متعددة غير صالح.",
  "provision.tenantspace.save.fail.120538" : "عدد معرفات الموظف القابلة للتحويل غير كافٍ.",
  "provision.tenantspace.save.fail.120537" : "لا يمكن أن تحتوي شبكات VDN المتوفرة على شبكة VDN التي تم نقلها.",
  "provision.tenantspace.save.fail.120536" : "لا يمكن أن يكون طول VDN المتوفر 0.",
  "provision.tenantspace.option.weeklist_fthtolast" : "الأخير ولكن ثلاثة",
  "provision.tenantspace.save.fail.120535" : "نطاق معرف الموظف قيد الاستخدام ولا يمكن إلغاؤه.",
  "provision.tenantspace.save.fail.120534" : "مجموعة موارد CCS المشار إليها بواسطة VDN غير موجودة.",
  "provision.callcenterinstanceslist.vcallcenterDeatil.save" : "حفظ",
  "provision.tenantspace.save.fail.120533" : "نطاق معرف موظف VDN مكرر.",
  "provision.tenantspace.config.worknoassign.filternumber" : "استثناء الأرقام",
  "provision.tenantspace.save.fail.120532" : "تم الإشارة إلى رمز الوصول الأصلي بواسطة رقم مطلوب.",
  "provision.tenantspace.save.fail.120531" : "لم يتم تخصيص رمز الوصول الموجود في معلومات VDN الأساسية إلى VDN.",
  "provision.tenantspace.save.fail.120530" : "محرك الأقراص المشار إليه بواسطة VDN غير موجود.",
  "provision.callcenterinstanceslist.vcallcenterDeatil.defaultCallerNum" : "الرقم الطالب الافتراضي",
  "provision.callcenterinstanceslist.ccgatewayserver.ccGatewayIp" : "عنوان IP لبوابة CC-Gateway",
  "provision.tenant.management.contacts" : "اسم جهة الاتصال",
  "provision.callcenterinstanceslist.sipServer.sipanonymousCall" : "مكالمة SIP مجهولة",
  "provision.tenant.config.maxAgents" : "ماكس. وكلاء الصوت",
  "chat.agent.label.offline" : "غير متصل",
  "provision.tenantspace.config.fail.savedata.tenant.feature.inDatabase" : "فشلت قاعدة البيانات في حفظ معلومات ميزة المستأجر",
  "provision.tenantspace.config.voicecall.more.agent.ivr.whithoutAms" : "لا يمكن أن يكون الحد الأقصى لعدد المكالمات الصوتية المتزامنة أقل من مجموع قنوات الصوت\، والفيديو\، والوكلاء متعددي الاستخدامات\، وقنوات الصوت والفيديو IVR.",
  "provision.callcenterinstanceslist.softPhone.vcallcenterid" : "معرّف مركز اتصالات افتراضي.",
  "provision.callcenterinstanceslist.sipServer.description" : "الوصف",
  "provision.whiteListAddress.cardPop" : "عنوان إعادة توجيه رسالة بطاقة الصفحة",
  "provision.label.tenantexport.exportBeginTime" : "وقت بدء التصدير",
  "provision.sysparam.config.select.count" : "يمكن اختيار 10 معلمات كحد أقصى.",
  "provision.tenantspace.save.fail.120529" : "يجب أن يكون معرف مسؤول VDN ضمن نطاق معرف الموظف الذي تم تكوينه.",
  "provision.tenantspace.save.fail.120528" : "عند تمكين نقل قطع اتصال الموظف\، يتم تشغيل نغمة المطالبة بعد إعادة تحويل المكالمة إلى الموظف. يجب تعيين معلمة نغمة المطالبة.",
  "provision.tenantspace.save.fail.120527" : "تمت الإشارة إلى VDN بواسطة عميل CCP المحلي ولا يمكن حذفه.",
  "provision.tenantspace.save.fail.120526" : "لا يوجد مصدر بيانات IVR الافتراضي في مصادر بيانات VDN أو مصادر بيانات الجهات الخارجية المخصصة لـ VDN.",
  "provision.tenantspace.save.fail.120525" : "لا يتم تضمين مصادر بيانات IVR في مصادر بيانات VDN أو مصادر بيانات الجهات الخارجية المخصصة لـ VDN.",
  "provision.tenantspace.save.fail.120524" : "تم تخصيص رمز وصول النظام المخصص لـ VDN إلى مجموعة VDN.",
  "provision.systemparam.error.validate.notmatchruleemail" : "يرجى إدخال عنوان بريد إلكتروني صحيح",
  "provision.tenantspace.save.fail.120523" : "تم تخصيص رمز وصول النظام المخصص لـ VDN إلى VDN آخر.",
  "provision.tenantspace.save.fail.120522" : "لا يدعم VDN نوع مكالمة ويب\، ولكن يتم تخصيص رموز الوصول إلى النظام الخاصة بنوع مكالمة ويب إلى VDN.",
  "provision.tenantspace.save.fail.120521" : "لا يدعم VDN أنواع مكالمات البريد الإلكتروني والفاكس\، ولكن يتم تخصيص رموز الوصول إلى النظام الخاصة بنوع مكالمات البريد الإلكتروني أو الفاكس إلى VDN.",
  "provision.tenantspace.save.fail.120520" : "رمز وصول النظام المشار إليه بواسطة VDN غير موجود.",
  "chat.weChatConfig.stepname.verify" : "إرسال للتحقق",
  "provision.callcenterinstanceslist.nodepolicy.VNDId" : "معرف VDN",
  "provision.systemparam.url.unsafe" : "(غير آمنة)",
  "provision.subscribedetail.error.save" : "فشل الحفظ.",
  "provision.tenantspace.detail.create.time" : "وقت الإنشاء",
  "provision.tenantspace.config.ivrserver.select.confirm" : "تأكيد التحديد",
  "provision.systemparam.error.validate.notmatchruleminlength" : "معلمة الإدخال أقل من الحد الأدنى للطول",
  "provision.sysparam.config.search" : "البحث",
  "provision.tenantspace.statusRecordTime.6Month" : "6 أشهر",
  "provision.tenantspace.sfuprompt" : "لا يتكيف النظام مع فيديو SFU. يمكن للعملاء التكيف مع فيديو SFU أثناء التطوير الثانوي.",
  "provision.tenantspace.feature.dualCallAppSupport.open.confirmmsg" : "سيتم أيضًا تمكين ميزة AppCube لأنها أساس تشغيل ميزة مهمة الاتصال بالتطبيق.",
  "provision.callcenter.gateway.agentnum.alert" : "يجب أن تكون القيمة هي نفس قدرة التكوين الفعلية لمجموعة CC-Gateway.",
  "provision.tenantspace.ivr.ivrprogid" : "معرف العملية",
  "provision.label.tenantexport.viewExportTask" : "عرض مهام التصدير",
  "provision.tenantspace.agentinfo.message.singledeleteconfim" : "هل تريد بالتأكيد حذف الموظف المحدد؟",
  "provision.systemparam.error.validate.notmatchrulemax" : "تتجاوز معلمة الإدخال الحد الأقصى",
  "provision.callcent.error.saveccaccesscodeinfo" : ". تأكد من عدم وجود رمز الوصول.",
  "provision.tenantspace.whiteNumber" : "تكوين الأرقام في قائمة الثقة",
  "provision.tenantspace.save.fail.120519" : "لم يتم تكوين العقدة الموزعة المستخدمة للتسجيل التلقائي للصوت لـ VDN.",
  "provision.tenantspace.save.fail.120517" : "إذا لم يتم تحديد نظام تسجيل صوتي في تكوين معلومات RMS الأساسية\، يمكن تكوين تسجيل صوت ACD من هواوي فقط لـ VDN الحالي.",
  "provision.tenant.management.accesscode" : "رمز الوصول",
  "provision.tenantspace.save.fail.120516" : "IVR المشار إليه بواسطة VDN غير موجود.",
  "provision.tenantspace.save.fail.120515" : "مصدر البيانات المشار إليه بواسطة VDN غير موجود.",
  "provision.callcent.error.ipValidateFailed" : "يتم التحقق من صحة عنوان ip.",
  "provision.tenantspace.save.fail.120514" : "المورد الموزع المشار إليه بواسطة VDN غير موجود.",
  "provision.tenantspace.save.fail.120513" : "OBS المشار إليه بواسطة VDN غير موجود.",
  "provision.tenantspace.feature.forceVideoSelfRecord" : "تسجيل الشاشة",
  "provision.tenantspace.dataUpload.resourceType" : "نوع تفريغ المورد",
  "provision.tenantspace.save.fail.120512" : "نطاق معرف موظف VDN مكرر.",
  "provision.appsitesmanagement.siteName" : "اسم الموقع",
  "provision.tenantspace.save.fail.120511" : "اسم VDN مكرر مع اسم مجموعة VDN.",
  "provision.tenantspace.save.fail.120510" : "اسم VDN مكرر باسم VDN آخر.",
  "chat.agent.label.chatBot" : "خدمة العملاء الذكية",
  "provision.callcenterinstanceslist.ucsServer.ucsServerPort" : "منفذ خادم CCUCS",
  "provision.tenantspace.config.chooseGatewayServer" : "الرجاء تحديد خادم بوابة",
  "provision.tenantspace.config.agentnum.limit" : "يمكن الاشتراك في {0} موظفين كحد أقصى في كل مرة.",
  "provision.tenantspace.basic.information" : "معلومات المستأجر الأساسية",
  "provision.tenant.management.tenantcreate.failed" : "فشل إنشاء مساحة المستأجر. تأكد من أن اسم المستأجر أو حساب المسؤول فريد.",
  "provision.callcenterinstanceslist.softPhone.used.pleaseselect" : "حدد الاستخدام",
  "chat.weChatConfig.title" : "تكوين توفير وسائل التواصل الاجتماعي",
  "provision.callcenterinstanceslist.ccdis.ccdisDeleteConfirm" : "هل تريد بالتأكيد حذف ملقم CCDIS المحدد؟",
  "chat.session.label.subChannel" : "قناة الوصول الفرعية",
  "provision.callcenterinstanceslist.vcallcenter.maxAmsCalls" : "مكالمات مجهولة متزامنة",
  "provision.tenantspace.save.fail.120509" : "تم تمكين التقييد. بشكل افتراضي\، يبدأ الرقم الطالب بالبادئة.",
  "provision.tenantspace.save.fail.120508" : "تم تمكين التقييد. لا يمكن أن تكون بادئة الرقم الطالب فارغة.",
  "provision.tenantspace.save.fail.120507" : "لا يمكن أن يحتوي الرقم الطالب على أي علاقة تضمين.",
  "chat.weChatConfig.stepname.success" : "تم الوصول بنجاح.",
  "provision.tenantspace.feature.customercenter.close.error" : "قبل تعطيل ميزة مركز العملاء\، تأكد من تعطيل ميزة الحالة 2.0.",
  "provision.tenantspace.save.fail.120506" : "يتجاوز الحد الأقصى لعدد موظفي الفيديو المسموح لهم بتسجيل الدخول إلى النظام بشكل متزامن الحد الأعلى.",
  "provision.spinfo.creator" : "خالق",
  "provision.tenantspace.save.fail.120505" : "عدد الأطراف في المؤتمر يتجاوز الحد الأعلى.",
  "provision.tenantspace.save.fail.120504" : "يتجاوز الحد الأقصى لعدد المكالمات الصادرة المتزامنة الحد الأعلى.",
  "provision.spinfo.issuerId.search" : "أدخل معرّف جهة الإصدار.",
  "provision.tenantspace.save.fail.120503" : "يتجاوز الحد الأقصى لعدد مكالمات الويب المتزامنة الحد الأعلى.",
  "provision.tenantspace.save.fail.120502" : "يتجاوز الحد الأقصى لعدد المكالمات الصوتية المتزامنة الحد الأعلى.",
  "provision.tenantspace.save.fail.120501" : "يتجاوز الحد الأقصى لعدد الموظفين المسموح لهم بتسجيل الدخول إلى النظام بشكل متزامن الحد الأعلى.",
  "provision.tenantspace.agentinfo.message.media" : "الوسائط المتعددة",
  "provision.callcenterinstanceslist.config.odfsvalidate" : "يجب أن تكون كافة تكوينات خدمة ODFS فارغة أو غير فارغة.",
  "provision.callcent.error.updatesoftphonenumber.ondatabase" : "فشل تعديل رقم الهاتف في قاعدة البيانات.",
  "provision.tenantspace.save.successwithsamevccid" : "تم الحفظ بنجاح. توجد مساحة المستأجر الذي يكون معرف VCC الخاص به هو نفس مساحة المستأجر الحالية. اتصل بالمسؤول.",
  "provision.tenantspace.config.configVirtualCallCenter" : "تكوين مركز المكالمات الظاهري",
  "chat.timerange.label.sevendays" : "آخر 7 أيام",
  "provision.subscribedetail.pageTitle" : "تفاصيل الاشتراك بنقرة واحدة",
  "provision.callcenterinstanceslist.vcallcenter.tenantSpaceName" : "TenantSpaceName",
  "provision.tenantspace.config.call.more.agent.ivr" : "يجب أن يكون الحد الأقصى لعدد المكالمات الصوتية المتزامنة أكبر من أو يساوي مجموع عدد وكلاء الصوت وعدد قنوات IVR الصوتية والفيديو.",
  "chat.thirdportal.chatWindow.title" : "خدمة العملاء عبر الإنترنت",
  "provision.accesscode.table.call" : "المكالمات الواردة والصادرة",
  "provision.accesscode.table.whetherSupportTrialUse_Y" : "نعم",
  "provision.tenantspace.dataUpload.reportData" : "تقرير البيانات",
  "ccprovision.IVRSprogress.config.number" : "لا ..",
  "provision.label.tenantexport.refresh" : "تحديث",
  "provision.authconfig.duplicate.description.error" : "duplicate.description.error.",
  "ccprovision.recorddrive.config.storageLimit" : "أقل حد للتخزين (ميجابايت)",
  "provision.callcent.error.accesscode.deletefail.accesscodenoexist" : "فشل الحذف\، رمز الوصول غير موجود",
  "provision.tenantspace.config.amsNumber.unequal" : "يجب أن يكون عدد المكالمات المجهولة المتزامنة ثابتًا.",
  "provision.appversion.msg.uploadfile.0.success" : "تم التحقق من الملف بنجاح.",
  "provision.accesscode.table.whetherSupportTrialUse_N" : "لا",
  "provision.label.tenantexport.addExportTaskSuccess" : "تمت إضافة مهمة التصدير بنجاح.",
  "provision.callcenterinstanceslist.whitelist.Title" : "عنوان رد اتصال التعريف الذكي",
  "promotion.tenant.management.tenantedit.registerserver.notavailable" : "خادم التسجيل غير متوفر.",
  "provision.tenantspace.config.chooseDrive" : "محرك تسجيل الصوت",
  "provision.callcenterinstanceslist.vcallcenter.vccId" : "معرف VCC",
  "provision.tenantspace.config.chooseworkNo.tip" : "الرجاء إدخال مقطع العمل الميمون وفقًا للعمل الخموللا معلومات",
  "provision.tenantspace.cc.currentpercent" : "نسبة السعة الحالية",
  "provision.tenantspace.create.tenantName" : "اسم المستأجر",
  "provision.callcenterinstanceslist.config.confirm" : "قم بتكوين المعلومات الخاصة بالعُقد المرتبطة بالنظام الأساسي لـ WAS.",
  "provision.tenantspace.chooseauthenticationsystem" : "تكوين نظام مصادقة الطرف الثالث",
  "provision.tenantspace.list.search.date.error" : "تاريخ انتهاء صلاحية المستأجر غير صالح. الرجاء إدخال نطاق تاريخ صحيح.",
  "provision.tenantspace.vitualCenterName" : "مركز الاتصال الافتراضي",
  "provision.tenantspace.config.chooseOBSServer" : "حدد خادم OBS.",
  "provision.callcent.error.redisfail" : "فشل تحديث CC-AI Redis.",
  "provision.accesscode.message.invalid" : "أحرف غير صالحة.",
  "provision.callcenterinstanceslist.config.startworkno" : "رقم بداية معرف العمل",
  "provision.callcenterinstanceslist.config.nextStep" : "التالي",
  "provision.tenantspace.config.agentcall.zero" : "لا يمكن أن يكون عدد المكالمات الصوتية المتزامنة 0.",
  "provision.tenantspace.save.fail.101" : "خطأ في المزامنة.",
  "chat.weChatConfig.stepname.selectchannel" : "تحديد قناة وصول",
  "provision.whiteListAddress.downlink.message.apifabric.push" : "عنوان بنية واجهة برمجة التطبيقات API لرسائل الوسائط المتعددة Downstream",
  "provision.tenantspace.feature.cobrowse" : "التصفح المشترك",
  "provision.appsitesmanagement.queryFailed" : "خطأ في البحث.",
  "provision.encryptinfo.paddingScheme" : "وضع الحشو",
  "provision.ivr.success.sync" : "مزامنة ناجحة",
  "chat.weChatConfig.label.nextStep" : "الخطوة التالية",
  "provision.callcenterinstanceslist.table.uapId" : "معرف UAP",
  "chat.thirdportal.chatWindow.label.userPhone" : "عدد",
  "provision.tenantspace.feature.ivr.analysis.description" : "يتم تمكين ميزة تحليل IVR ، ويدعم المستأجر وظيفة تحليل IVR.",
  "provision.callcenterinstanceslist.whiteAddr.regAddrExample" : "مثال:https://ip:port/service-cloud/rest/cc-ai/v1/asrevent/asrresult",
  "provision.callcenterinstanceslist.config.uapId" : "يجب أن تكون هذه القيمة مماثلة لمعرف UAP على WAS.",
  "provision.label.tenantexport.popwindowmessage" : "أدخل كلمة المرور لإنشاء ملفات مضغوطة. يجب أن تحتوي كلمة المرور على أحرف\، وأرقام\، وأحرف خاصة {'@'}%.",
  "provision.label.information" : "تلميحات",
  "ccprovision.IVRSprogress.config.button.cacel" : "إلغاء",
  "chat.timerange.message.timeempty" : "لا يمكن أن يكون الوقت المحدد فارغًا.",
  "provision.subscribedetail.label.contactName" : "اسم جهة الاتصال",
  "provision.tenantspace.feature.customercenter" : "مركز خدمة العملاء",
  "ccprovision.vcallecenter.error.updateinfo.currentacountpasderror" : "كلمة مرور الحساب الحالي غير صحيحة.",
  "provision.tenant.management.batchcreatenumber" : "الكمية",
  "provision.tenantspace.config.agent.ivr.more.zero" : "لا يمكن أن يكون عدد وكلاء الصوت\، وعامل الفيديو\، وعامل الوسائط المتعددة\، وعامل متعدد الاستخدامات\، وقنوات IVR الصوتية وقنوات IVR للفيديو 0.",
  "provision.accesscode.table.accessCode" : "رمز الوصول",
  "provision.callcenterinstanceslist.config.nmsservicePort" : "رقم منفذ ODFS",
  "provision.callcenterinstanceslist.table.nodemodepool" : "وضع التجمع",
  "provision.tenantspace.moveAgentUsage.iszero" : "عدد قنوات IVR الصوتية غير كافٍ.",
  "provision.callcenterinstanceslist.config.previousStep" : "السابق",
  "provision.tenantspace.table.activeTenantSpace" : "هل أنت متأكد من أنك تريد تنشيط المستأجر؟",
  "provision.tenantspace.config.softphoneassign.softphonescope.extensiverange" : "لا يمكن أن يكون نطاق القيمة من رقم البداية إلى رقم النهاية أكبر من 1000.",
  "provision.appversionmanagement.regVersion" : "يتكون رقم الإصدار من حروف وأرقام ونقاط. لا يمكن أن تبدأ أو تنتهي بنقطة.",
  "provision.callcent.success.deletesoftphonenumber.processed" : "جاري حذف البيانات من الخلفية... يُرجى التحقق لاحقًا.",
  "provision.tenantspace.feature.cobrowse.cobrowseMaxNumber" : "الحد الأقصى لعدد اتصالات التصفح المشترك",
  "provision.whiteListAddress.TenantUnExist" : "المستأجر غير موجود.",
  "provision.tenantspace.gw.maxRegAgentNum" : "كمية الوكيل المسجلة المتزامنة",
  "provision.callcent.error.accesscode.deletefail.part.associationvcallcener" : "ترتبط بعض رموز الوصول بالمستأجرين ولا يمكن حذفها.",
  "provision.authconfig.updateMessage" : "هل أنت متأكد من رغبتك في تحديث التطبيق SK؟",
  "ccprovision.recorddrive.config.sftp.filestoragepathStrValidateFailed" : "لا يمكن أن يحتوي مسار تخزين الملفات على './'",
  "provision.appversion.msg.uploadfile.data.fail" : "معلمات ملف غير صحيحة.",
  "provision.tenantspace.timezone.save.success" : "تم حفظ معلومات المنطقة الزمنية بنجاح.",
  "provision.callcent.error.deletesoftphonenumber.part.associationvcallcener" : "ترتبط بعض أرقام الهواتف المحمولة المحددة بالمستأجر ولا يمكن حذفها.",
  "provision.tenantspace.config.worknoassign.filternumber.explain2" : "لن يحتوي الرقم البنكي بعد الفحص على أرقام الأرقام المذكورة أعلاه",
  "provision.tenantspace.config.chooseUCSServer" : "حدد خادم CCUCS.",
  "provision.callcenterinstanceslist.config.createsuccess" : "تم إنشاء مركز المكالمات بنجاح.",
  "provision.tenantspace.config.worknoassign.filternumber.explain1" : "عند إدخال أرقام متعددة\، يُرجى استخدام \",\" للفصل. أدخل حتى 5 أرقام\, على سبيل المثال\, \"4,11,13\"",
  "provision.callcenterinstanceslist.vcallcenterDeatil.vccEnabled" : "تشير إلى ما إذا كان سيتم تمكين VDN.",
  "provision.callcenterinstanceslist.whitepageurl.httptip" : "(غير آمنة)",
  "provision.callcenterinstanceslist.table.updateFault" : "هل أنت متأكد من تعيين صوت خطأ مركز الخلية الظاهري",
  "provision.tenantspace.tenantSpaceCount" : "إجمالي عدد المستأجرين",
  "provision.callcent.error.updateccdis.associationvcallcener" : "ملقم CCDIS المحدد مقترن بالمستأجر ولا يمكن تحديثه.",
  "provision.tenant.management.tenantexport.confirm" : "شكر وتقدير",
  "provision.tenantspace.table.maxCalls" : "الحد الأقصى للمكالمات الصوتية المتزامنة",
  "provision.callcenterinstanceslist.sipServer.uapPort" : "ميناء UAP",
  "promotion.tenant.management.tenantedit.vdnsync.failed" : "فشل مزامنة البيانات إلى مركز المكالمات الظاهري.",
  "provision.appversionmanagement.iOSH5" : "نظام IOS H5",
  "provision.callcenterinstanceslist.config.ttschannelnumsValidate" : "عدد قنوات TTS أصغر من عدد القنوات المخصصة فعليًا في مركز المكالمات.",
  "chat.skill.message.warning" : "تحذيرات",
  "chat.session.label.created_startTime" : "وقت البدء",
  "provision.tenantspace.prompt" : "معلومات",
  "provision.systemparam.error.notmatchrule" : "المعلمة لا تطابق قاعدة التحقق",
  "provision.callcenterccaccesscodeslist.table.batchdeleteconfim" : "هل تريد بالتأكيد حذف كافة رموز الوصول المحددة؟ تأكد من أن رمز الوصول غير مقترن بمركز اتصالات افتراضي.",
  "provision.appversion.msg.process.add" : "إضافة إصدار التطبيق",
  "provision.tenantspace.cc.max" : "ماكس. قدرة",
  "provision.callcenterinstanceslist.ccgatewayServer.singledeleteconfim" : "هل تريد بالتأكيد حذف ملقم CC-Gateway المحدد؟",
  "provision.batchimport.message.uploaded.button" : "تحميل",
  "provision.tenantspace.deleteWhiteNumber" : "هل تريد بالتأكيد حذف الرقم من قائمة الثقة؟",
  "provision.tenantspace.config.softphone.noavailable" : "لا يوجد رقم هاتف محمول متوفر.",
  "provision.tenantspace.config.selectedsoftphone" : "محدد#*@$20} أرقام#*@$21}",
  "provision.tenantspace.select.start" : "تم البدء",
  "chat.session.label.channel" : "قناة الوصول إلى الجلسة",
  "provision.tenantspace.feature.VoiceNotificationSupport.featureParams.placeholder" : "قم بتعيين هذه المعلمة استنادًا إلى عدد جلسات IVR المتزامنة\، وعدد جلسات الشبكة الأساسية المتزامنة\، وعدد جلسات الخط المتزامنة التي تم تكوينها بواسطة المستأجر.",
  "provision.callcenterinstance.softPhone.validateSoftPhoneNumrange" : "لا يمكن أن يتجاوز نطاق الأرقام المضافة حديثًا 1000",
  "provision.tenantspace.config.error.softphone.noagentadd" : "لم تتم إضافة أي صوت \ أو فيديو \ أو وسائط متعددة \ أو وكيل جوال.",
  "provision.tenantspace.common.ivr" : "IVR التقليدية",
  "provision.label.tenantexport.zippwd" : "كلمة مرور مضغوطة",
  "provision.callcent.label.openFaule" : "OpenFault",
  "provision.callcenterinstanceslist.table.videoAgentChannels" : "كمية قناة وكيل الفيديو",
  "provision.tenantspace.popuppageurl.deleteconfim" : "هل تريد بالتأكيد حذف قائمة ثقة العناوين؟",
  "provision.callcenterinstanceslist.obsServer.obsId" : "معرف OBS",
  "ccprovision.recorddrive.config.sftp.port" : "رقم الميناء",
  "provision.tenantspace.table.videoAgentChannels" : "كمية قناة وكيل الفيديو (> = ضعف كمية وكيل الفيديو)",
  "provision.callcenterinstanceslist.vcallcenterDeatil.vccName" : "اسم VDN",
  "provision.whiteListAddress.outboundcall.obs.fileserver" : "خادم ملفات OBS للمكالمة الصادرة",
  "provision.callcenterwhitelist.regAddr" : "عنوان معاودة الاتصال",
  "provision.callcenterinstanceslist.config.agentUsage" : "عدد الوكلاء المعينين",
  "provision.callcenterinstanceslist.table.nodename" : "اسم العقدة",
  "chat.session.label.evaluationTime" : "وقت تقييم الجلسة",
  "provision.callcenterinstanceslist.vcallcenterDeatil.ccInstId" : "رقم مركز الاتصال",
  "provision.appversion.msg.uploadfile.1.fail" : "فشل التحقق من اسم الملف.",
  "provision.tenantspace.smartAgentUsage.iszero" : "قم بتكوين الموظف أولاً.",
  "provision.callcent.label.finish" : "مكتمل",
  "chat.session.channel.facebook" : "الفيسبوك",
  "provision.tenant.management.partdbId" : "معرف قسم قاعدة البيانات",
  "chat.agent.label.waiting" : "في انتظار",
  "chat.skill.message.failDeleteSkill" : "فشل حذف المهارة.",
  "provision.accesscode.table.mediatypeId" : "نوع الوسائط",
  "provision.tenantspace.feature.onlineCustomerServiceAssets.open.confirmmsg" : "سيتم أيضًا تمكين ميزات AppCube وIVR الذكية لأنها تشكل أساسًا لتشغيل ميزة أصول خدمة العملاء عبر الإنترنت.",
  "provision.tenantspace.config.softphone.using" : "رقم الهاتف المحمول #*@$20} قيد الاستخدام.",
  "provision.ccworknos.table.ccworkStartNo" : "معرّف الموظف البدء",
  "provision.callcenterinstanceslist.vcallcenter.ctiInfo" : "معرف مجموعة موارد CTI",
  "provision.tenantspace.feature.OneClickTwoCallSupport.description" : "توفر هذه الميزة وظائف اتصال ثنائية الاتجاه بنقرة واحدة لمساحة المستأجر.",
  "provision.whiteListAddress.CustomerMenu" : "تخصيص القوائم",
  "chat.weChatConfig.message.unknown" : "حالة التحقق غير معروفة!",
  "provision.tenant.management.tenantcreate.timezoneInfo" : "معلومات المنطقة الزمنية",
  "provision.ccworknos.table.ccworkEndNo" : "معرف الموظف النهائي",
  "provision.tenantspace.table.maxcallnums" : "الحد الأقصى لعدد المكالمات الصوتية المتزامنة (> = مجموع عدد وكلاء الصوت وعدد قنوات IVR الصوتية والفيديو)",
  "provision.authconfig.authdescription" : "أدخل وصف معرف المطور.",
  "provision.encryptinfo.strengthdecrease" : "تقليل القوة يقلل من أمان الخوارزمية. توخي الحذر عند تحديد هذا الخيار.",
  "chat.webconfig.label.basesettings" : "التكوين الأساسي",
  "provision.callcenterinstanceslist.vcallcenterDeatil.tenantSpaceId" : "معرف المستأجر",
  "ccprovision.vcallecenter.config.popup.tab.administrator.modifypassword" : "تعديل كلمة مرور المسؤول",
  "provision.tenantspace.cc.videoCallnumUsagePercent" : "الاستخدام المتزامن لمكالمة الفيديو",
  "provision.tenantspace.cc.videoIvrchannels" : "فيديو IVR قناة الكمية",
  "provision.whiteListAddress.resourcedump.css" : "خادم CSS تفريغ الموارد",
  "provision.callcenterinstanceslist.softPhone.softPhoneNum" : "رقم الهاتف",
  "ccprovision.IVRSprogress.config.operate" : "العملية",
  "chat.weChatConfig.label.state" : "الحالة",
  "provision.callcenterinstanceslist.vcallcenter.faultEnable" : "خطأ الصوت",
  "provision.encryptinfo.update" : "تحديث",
  "provision.whiteListAddress.channel.push" : "عنوان دفع رسالة قناة الوسائط المتعددة",
  "provision.system.error" : "النظام غير طبيعي. يُرجى إعادة المحاولة لاحقًا.",
  "provision.callcenterinstanceslist.vcallcentersftp.passLengthValidateFailed" : "يجب أن تحتوي كلمة المرور على 8 إلى 20 حرفًا",
  "provision.appversionmanagement.batchDelete" : "حذف الحزمة",
  "chat.client.evaluate.message" : "يرجى التعليق على خدمتي\، شكرا لك!",
  "provision.callcenterinstanceslist.softPhone.passLengthValidateFailed" : "يجب أن تحتوي كلمة المرور على 8 إلى 16 حرفًا.",
  "provision.tenantspace.passwordValidatefaild" : "فشل التحقق من كلمة المرور.",
  "provision.systemparam.success.save" : "تم حفظ المعلمة بنجاح",
  "provision.tenant.management.button.ok" : "مكتمل",
  "provision.callcenterinstanceslist.vcallcenter.ccdis" : "خادم CCDIS",
  "provision.tenantspace.config.choose.agent.registration.server.type" : "نوع خادم تسجيل الموظف",
  "provision.callcent.error.updateerrorinfo" : "تم إقران رمز الوصول بمركز اتصالات ظاهري ولا يمكن تحريره.",
  "provision.tenantspace.config.error.chooseworkNo.noagent" : "لم يتم تحديد موارد عامل\، لا حاجة لتعيين رقم العمل",
  "provision.accesscode.table.accessCode.selecttype" : "تحديد نوع",
  "provision.tenantspace.config.agentusage.vidoeusage.ivr" : "لا يمكن أن تكون كمية وكيل الصوت \، وكمية وكيل الفيديو \، وكمية وكيل الوسائط المتعددة \، وكمية قناة الصوت IVR وكمية قناة الفيديو IVR صفرًا",
  "provision.tenantspace.error.deleteProvision" : "فشل حذف مساحة المستأجر الموفر.",
  "provision.callcenterinstance.softPhone.validatebeginSoftPhoneNum" : "لا يمكن أن يكون رقم بدء الهاتف المحمول أكبر من رقم النهاية",
  "provision.callcenterinstanceslist.ccdis.remark" : "وصف الاستخدام",
  "provision.callcenterinstanceslist.vcallcenter.open" : "مفتوح",
  "provision.callcent.error.deleteccgateway.associationvcallcener" : "ملقم CC-Gateway المحدد مقترن بالمستأجر ولا يمكن حذفه.",
  "provision.callcent.error.paramUnique" : "المعلمة الفريدة موجودة.",
  "provision.callcenterinstance.accesscode.validateaccesscode" : "أدخل مقطع رقمي صحيح.",
  "provision.tenantspace.error.deleteDDS" : "فشل حذف مساحة مستأجر DDS.",
  "provision.callcenterinstanceslist.config.nodeIdCheckError" : "تأكد من أن معرف معلومات عقدة CTI المدخلة\، وuapId\، والاسم فريدان.",
  "provision.callcenterinstanceslist.table.gwIp" : "عنوان IP لبوابة CC-Gateway",
  "provision.encryptinfo.metadata" : "تشفير وفك تشفير البيانات الوصفية",
  "provision.tenantspace.config.softphoneassign.chooseworknoscope.paramserror" : "معلومات الرقم غير صحيحة.",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.addmaxlimit" : "تمت إضافة خمس مجموعات من الأرقام.",
  "provision.callcenterinstanceslist.table.agentMaxCapacity" : "سعة نظام الوكيل",
  "provision.tenantspace.option.weekday_tue" : "الثلاثاء",
  "provision.tenantspace.select.status" : "حدد حالة.",
  "provision.appversionmanagement.downloadAddress" : "عنوان التنزيل",
  "provision.tenant.management.DSTStartDate" : "DST تاريخ البدء",
  "provision.callcenterinstanceslist.table.resourceinfo" : "معلومات الموارد",
  "provision.callcenterinstanceslist.softPhone.authtype" : "وضع المصادقة",
  "provision.appsitesmanagement.appcubeAvailable" : "AppCube متاح",
  "chat.agentConsole.message.error.notification" : "لا يمكن إنشاء رسالة تذكير جديدة. يُرجى التحقق من تهيئة المستعرض لديك.",
  "provision.callcent.error.regaddrlength" : "عنوان التسجيل طويل جداً.",
  "provision.appsitesmanagement.creator" : "خالق",
  "provision.callcent.selectsoftnumber" : "حدد رقم هاتف محمول.",
  "provision.callcenterinstanceslist.table.asrchannelnums" : "ASR الكمية",
  "provision.tenantspace.config.select.record" : "يُرجى تحديد محرك أقراص للتسجيل.",
  "provision.tenantspace.agentinfo.deletefail.modifyvdn.onwas" : "فشل الاتصال بالنظام الأساسي الذي تم تعديل vdn",
  "provision.callcent.error.partdeleteinfo" : "لا يمكن حذف المحتوى المقترن بمركز اتصالات ظاهري.",
  "chat.client.label.promotions" : "تلميحات",
  "provision.tenantspace.config.maxCalls" : "كمية المكالمات الصوتية المتزامنة",
  "provision.callcenterinstanceslist.vcallcenter.videoAgentChannels" : "VideoAgentchannels",
  "chat.session.channel.web" : "صفحة ويب",
  "provision.tenantspace.config.chooseDriveTime" : "فترة الاحتفاظ بالتسجيل",
  "provision.accesscode.table.acId" : "رقم رمز الوصول",
  "provision.tenantspace.option.month_9" : "أيلول/سبتمبر",
  "provision.tenantspace.option.month_8" : "أغسطس",
  "provision.tenantspace.option.month_7" : "يوليو",
  "provision.tenantspace.option.month_6" : "يونيو",
  "provision.tenantspace.option.month_5" : "أيار/مايو",
  "provision.tenantspace.option.month_4" : "نيسان/أبريل",
  "provision.tenantspace.option.month_3" : "مارس",
  "provision.tenantspace.option.month_2" : "فبراير",
  "provision.tenantspace.config.worknoassign.showchoosedworkno.explain" : "ملاحظة: تحتاج إلى تحديد إجمالي #*@$20} أرقام\، و#*@$21} أرقام. سيقوم النظام بتعيين أرقام لك بالتسلسل.",
  "provision.tenantspace.refresh" : "تحديث",
  "provision.callcenterinstanceslist.softPhone.usedselect.mobileagent" : "رقم مدير العملاء",
  "provision.tenantspace.option.month_1" : "يناير",
  "provision.tenant.management.tenantexport.viewTask" : "الاستعلام عن مهام التصدير",
  "provision.callcenterinstanceslist.softPhone.batchadd" : "إضافة دفعة",
  "provision.tenantspace.feature.intelligent.inspection" : "التفتيش الذكي",
  "provision.callcenterinstanceslist.whiteNumber" : "الأرقام في قائمة الثقة",
  "provision.tenantspace.feature.OneClickTwoCallSupport" : "مكالمة ثنائية الاتجاه بنقرة واحدة",
  "provision.tenantspace.selectStastus.initialize" : "تهيئة",
  "provision.tenantspace.option.weeklist_sectolast" : "أخيرًا ولكن واحد",
  "provision.callcent.selectobsserver" : "يرجى تحديد خادم Obs.",
  "provision.tenantspace.gw.name" : "اسم خادم العبّارة",
  "chat.skill.label.name" : "اسم المستخدم",
  "provision.callcent.label.cancel" : "إلغاء",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.workNocontain" : "تم تحديد معرف العمل. الرجاء إدخال واحدة أخرى.",
  "provision.callcenter.sip.alert" : "يجب أن تكون القيمة هي نفس القدرة الفعلية التي تدعمها UAP وSBC.",
  "provision.tenantspace.cc.ivrchannels" : "الصوت IVR قناة الكمية",
  "provision.tenantspace.passValidateFailed" : "يجب أن يحتوي على أحرف كبيرة\، وأحرف صغيرة\، وأرقام\، وأحرف خاصة ~!{'@'}#$%&*()-_=+[{}];:\"\"\\,<.>/?",
  "chat.session.channel.twitter" : "تويتر",
  "provision.spinfo.beginlettervalidate" : "يرجى البدء مع رسالة.",
  "provision.softphone.label.issyncuap.yes" : "نعم",
  "chat.agent.message.changeFailed" : "فشل تغيير حالة الموظف.",
  "provision.recorddrive.selectrecorddrive.singledeleteconfim" : "هل تريد بالتأكيد حذف حرف محرك الأقراص المحدد؟ يرجى التأكد من أن حرف محرك الأقراص ليست مشغولة من قبل المستأجر",
  "chat.agentConsole.message.content.video" : "[رسالة فيديو]",
  "provision.callcenterinstanceslist.softPhone.usedselect.fixedagent" : "رقم الموظف",
  "provision.callcenterinstanceslist.ccdis.tenantSpaceName" : "اسم المستأجر",
  "chat.client.label.input" : "يُرجى إدخال",
  "provision.tenantspace.config.select.default.phone.in.selectAccesscodes" : "يجب أن يكون الرقم الطالب الافتراضي ضمن رمز الوصول المحدد.",
  "chat.agentConsole.message.content.connectMessage" : "لديك رسالة جديدة!",
  "provision.tenantspace.vdn.id" : "معرف VDN",
  "provision.tenantspace.agentusage.plus.videousage" : "AgentUsage يجب أن يكون VideoUsage أقل من 10000",
  "provision.tenantspace.detail.tenantspace.cannot.close.uploadfeature" : "يرجى إيقاف تشغيل ميزة مراجعة مورد التفريغ عبر الإنترنت أولاً",
  "provision.callcenterinstanceslist.table.eMediaAgentCurCapacity" : "وكيل الوسائط المتعددة الكمية",
  "provision.authconfig.updateConfim" : "تحديث confim",
  "provision.systemparam.success.refresh" : "تم تحديث المعلمة بنجاح",
  "provision.callcenterinstanceslist.table.search" : "استعلام",
  "provision.callcent.error.deletesoftphonenumber.associationvcallcener" : "رقم الهاتف المحدد مرتبط بالمستأجر ولا يمكن حذفه.",
  "provision.callcenterinstanceslist.config.saveAndnextStep" : "حفظ والتالي",
  "provision.appversionmanagement.versionName" : "اسم الإصدار",
  "provision.tenantspace.feature.smsOrEmailMarketingSupport.sendMaxNumber.whatsApp.alert" : "يتراوح الحد الأقصى لعدد الرسائل التي يمكن إرسالها كل يوم من 1 إلى 100000. تشير القيمة 0 إلى أنه لم يتم تحديد أي حد.",
  "promotion.tenant.management.tenantedit.exception" : "حدث استثناء على الخادم.",
  "provision.callcenterinstanceslist.config.odfsappsercret" : "ODFS AppSercret",
  "provision.callcent.error.errorinfo101" : "فشل مزامنة ccm.",
  "provision.callcenterinstanceslist.resource.node.notexist" : "معلومات العقدة غير موجودة.",
  "provision.callcent.error.deleteobsserver.part.associationvcallcener" : "بعض ملقم obs المحدد مرتبط بالمستأجر ولا يمكن حذفه.",
  "provision.callcenterinstanceslist.obsServer.progId" : "معرف prog",
  "provision.whiteListAddress.ivr.voiceupload.callback" : "رد اتصال التحميل الصوتي IVR",
  "provision.sysparam.config.reset.multiple.error" : "لا يمكن إعادة تعيين المعلمات التالية:",
  "provision.tenantspace.ivr.ivrId" : "معرف IVR",
  "provision.tenant.management.pool.ctinode.id" : "معرف العقدة",
  "provision.tenantspace.config.tenant.custdata.featurevalue.not.one" : "قيمة ميزة تخصيص البيانات المخصصة للمستأجر ليست 1.",
  "ccprovision.IVRSprogress.config.logincti.failed" : "فشل نظام الاتصال الأساسي",
  "provision.tenant.management.tenantcreate.name.exist" : "اسم المستأجر موجود بالفعل",
  "provision.appsitesmanagement.createTime" : "إنشاء الوقت",
  "provision.tenantspace.config.applySource" : "تطبيق الموارد",
  "provision.appsitesmanagement.modifyTime" : "تعديل الوقت",
  "provision.appversionmanagement.updateContent" : "تحديث المحتوى",
  "provision.tenantspace.config.isAutoAssignWorkNo" : "تعيين العمل تلقائيًاNo لمركز الاتصالات الظاهري",
  "provision.callcent.selectucsserver" : "يرجى تحديد خادم CCUCS.",
  "provision.tenantspace.create.applyAccount" : "تسجيل مسؤول للمستأجر.",
  "provision.tenantspace.feature.OneClickTwoCallSupport.featureParams.label" : "معلمات المكالمة",
  "provision.encryptinfo.updateInterval.error" : "لا يمكن أن يكون الفاصل الزمني لتحديث المفتاح أقل من 30 يومًا.",
  "provision.tenantspace.feature.forceVideoSelfRecord.percentage" : "السجل حسب النسبة المئوية",
  "provision.tenantspace.selectCC" : "حدد مركز اتصالات.",
  "provision.callcent.error.setinfo" : "فشل التعيين.",
  "chat.webconfig.label.intergratemsg" : "دمج الدردشة الحية إلى موقع الويب الخاص بك",
  "provision.tenantspace.feature.outboundCallMarketingSupport" : "مكالمة خارجية ذكية",
  "provision.whiteListAddress.mobileagenturl" : "معاودة الاتصال بخدمة العملاء عبر الهاتف المحمول",
  "chat.session.label.createdTime" : "وقت إنشاء الجلسة.",
  "provision.callcenterinstanceslist.label.ivrchannelUseRate" : "الصوت IVR استخدام القناة",
  "provision.ccp.redis.feature.inprogress" : "يتم تنفيذ بيانات ميزة المستأجر.",
  "provision.tenantspace.config.chooseAgentRegistrationServer" : "تحديد خادم تسجيل موظف",
  "chat.session.label.endReason" : "سبب نهاية الجلسة",
  "provision.tenantspace.agentinfo.message.deletefail.InProgress" : "يقوم المستأجر حاليًا بمعالجة العمل\، يرجى المحاولة مرة أخرى لاحقًا!",
  "provision.callcenterinstances.agentWasPassLengthValidateFailed" : "يجب أن تحتوي كلمة المرور على 8 إلى 32 حرفًا.",
  "provision.callcenterinstanceslist.config.serviceIP" : "Nginx IP",
  "provision.callcenterinstanceslist.table.maxVideoCallnums" : "الحد الأقصى لمكالمات الفيديو المتزامنة",
  "provision.callcenterinstanceslist.config.createfailed" : "فشل إنشاء مركز اتصالات.",
  "provision.callcent.platformserver.ccbms" : "CCBMS",
  "provision.callcent.success.save" : "النجاح",
  "provision.tenantspace.feature.VoiceNotificationSupport.featureParams.callNums.label" : "عدد بيانات المكالمات (من 1 إلى 10)",
  "ccprovision.recorddrive.config.sftp.ipadress" : "عنوان IP لـ SFTP",
  "provision.subscribedetail.createSource.value_1" : "سحابة البرمجيات الموقع الرسمي",
  "provision.authconfig.update" : "upadate",
  "provision.subscribedetail.createSource.value_0" : "موقع HUAWEI CLOUD الرسمي",
  "ccprovision.vcallecenter.config.popup.enternewpassword" : "أدخل كلمة مرور جديدة",
  "provision.callcenterinstanceslist.config.workAlreadyOccupied" : "معرف الموظف قيد الاستخدام",
  "provision.callcenterinstanceslist.sipServer.uapIp" : "UAP IP",
  "ccprovision.recorddrive.config.recorddrivenameconfig" : "تكوين وحدة التخزين",
  "provision.tenantspace.config.param.error.agentCall" : "الرجاء إدخال عدد المكالمات الصوتية المتزامنة.",
  "provision.authconfig.appkey" : "معرف APP",
  "provision.callcent.error.pagechangednowrefresh" : "تم تغيير محتوى الصفحة ويجب تحديثه.",
  "provision.callcenterinstanceslist.table.ccId" : "معرف مركز الاتصال",
  "provision.callcenterinstanceslist.config.maxVideoCallnumsValidate" : "الحد الأقصى لعدد مكالمات الفيديو أقل من عدد مكالمات الفيديو المخصصة بالفعل من قبل مركز الاتصال.",
  "provision.callcent.error.updateucsserver.ondatabase" : "فشل تعديل خادم CCUCS في قاعدة البيانات.",
  "provision.tenantspace.statusRecordTime.24Month" : "24 شهر",
  "provision.tenantspace.config.error.chooseworkNo.noaddagent" : "لم تتم إضافة مورد عامل\، لا حاجة لتعيين رقم العمل",
  "provision.callcenter.resourceinfo.alert" : "يجب أن تكون معلومات المورد التالية متسقة مع معلومات ترخيص الأجهزة مثل UAP وCTI.",
  "provision.tenantspace.status.invalid.fail" : "فشل الإلغاء.",
  "provision.tenantspace.sip.searchName" : "الرجاء إدخال اسم ملقم SBC المراد الاستعلام عنه.",
  "provision.tenantspace.feature.VoiceNotificationSupport.featureParams.label" : "معلمات المكالمة",
  "provision.tenant.management.please.select" : "حدد\.\.\.",
  "provision.tenantspace.intelligent.agent" : "وكيل ذكي",
  "provision.callcenterinstanceslist.softPhone.singledeleteconfim" : "هل تريد بالتأكيد حذف أرقام الهاتف المحددة؟ تأكد من أن رقم الهاتف غير مرتبط بأي مركز اتصالات ظاهري.",
  "provision.tenantspace.config.fail.modify.tenant.auth.use.sumInterface" : "فشل استدعاء واجهة SUM لتعديل أذونات المستأجر",
  "provision.callcenterinstanceslist.vcallcenterDeatil.whiteNumValidateLength" : "يتراوح طول رقم قائمة الثقة من 1 إلى 32.",
  "chat.session.label.search" : "البحث عن",
  "provision.appversionmanagement.iOS" : "نظام IOS",
  "provision.callcent.error.accesscode.deletefail.part.onccgateway.with.associationvcallcener" : "فشل الحذف الجزئي بسبب ارتباط جزء من رمز الوصول بالمستأجر\، وفشل حذف جزء من رمز الوصول على النظام الأساسي.",
  "promotion.tenant.management.tenantedit.softnumber.insufficient" : "عدد الهواتف غير كافٍ.",
  "chat.session.label.sessionId" : "معرف الجلسة",
  "provision.callcent.label.submit" : "إرسال",
  "provision.callcenterinstanceslist.vcallcenterDeatil.kafkaPwdLength" : "يجب أن يتراوح طول كلمة المرور بين 6 و32",
  "provision.callcenterinstanceslist.sipServerUpdate.failed" : "لا يتوافق عنوان IP أو رقم المنفذ مع القاعدة.",
  "chat.skill.label.deleteSkills" : "الحذف",
  "provision.tenant.management.adminpasswordagain" : "تأكيد كلمة المرور",
  "provision.tenantspace.agentinfo.message.smartagent.iszero" : "\, لا يوجد موظف صوت\، يُرجى إيقاف تشغيل ميزة الموظف الذكي يدويًا",
  "provision.callcenterinstanceslist.table.curCapacityProportion" : "نسبة السعة الفعلية",
  "provision.systemparam.error.validate.ruleinvalid" : "قاعدة التحقق غير صالحة",
  "provision.grayconfig.paramIsError" : "معلمة الإدخال فارغة أو غير صحيحة",
  "provision.tenantspace.table.createStatus" : "الدولة الجديدة",
  "provision.callcenterinstanceslist.config.adminWorkAlreadyOccupied" : "معرف موظف المسؤول قيد الاستخدام",
  "provision.tenantspace.language.zh" : "الصينية",
  "chat.session.label.allEndType" : "جميع أنواع النهاية",
  "provision.tenantspace.config.tenant.intelligent.inspection.featurevalue.not.one" : "قيمة ميزة الفحص الذكي المخصصة للمستأجر ليست 1.",
  "provision.callcent.error.repetenter" : "وقد تم استخدام الرقم. أدخل رقمًا آخر مرة أخرى.",
  "provision.softPhone.label.uapaddress" : "عنوان UAP",
  "provision.tenantspace.agentinfo.agentstatus.online" : "عبر الإنترنت",
  "provision.callcenterinstanceslist.sipServer.name" : "الاسم",
  "provision.appsitesmanagement.cannotContainSpecialCharacters" : "لا يمكن أن تحتوي القيمة على الأحرف الخاصة التالية: ~!{'@'}#$%&*()/ \\_= +{}:'\<>؟ []",
  "provision.callcenterinstanceslist.softPhone.stautus.unoccupied" : "غير مستخدمة",
  "provision.accesscode.table.callType" : "نوع الاتصال",
  "chat.usefulexpressions.message.saveFail" : "فشل حفظ العبارة العامة.",
  "provision.tenantspace.config.worknoassign.chooseworkNo" : "اختر رقماً",
  "provision.callcenterinstanceslist.vcallcenter.videoIvrchannels" : "VideoIVRchannels",
  "provision.tenantspace.chooseCommercial" : "اختيار الحالة التجارية",
  "chat.session.label.reset" : "إعادة تعيين",
  "provision.spinfo.deletespinfofailed" : "فشل حذف معلومات SP.",
  "chat.usefulexpressions.message.failDeleteExpression" : "فشل حذف العبارة العامة.",
  "provision.appsitesmanagement.sureSelectDataDeleted" : "حدد البيانات المراد حذفها.",
  "provision.callcenterinstanceslist.vcallcenter.agentNumErrorListPromt" : "يوجد مركز اتصال ظاهري لا يتوافق عدد مقاعده مع VDN في WAS. VccId هو",
  "chat.agent.title.createCase" : "إنشاء حالة",
  "provision.tenantspace.agentinfo.label.workNo" : "رقم الوكيل",
  "chat.client.label.login" : "تسجيل الدخول",
  "provision.tenantspace.config.need.wertcsipserver" : "لتمكين ميزة WebRTC تحتاج إلى تحديد خادم تسجيل وكيل WebRTC.",
  "promotion.tenant.management.tenantedit.accesscode.noavailable" : "رمز الوصول غير متوفر.",
  "provision.callcent.success.delete" : "نجاح الحذف",
  "provision.tenantspace.config.error.148" : "تم ربط نظام المصادقة بمستخدم متحد ولا يمكن تعديله.",
  "provision.tenantspace.dataUpload.choose" : "من فضلك اختر...",
  "provision.callcenterinstanceslist.softPhone.stautus.select" : "حدد الحالة",
  "provision.callcenterinstanceslist.vcallcenter.maxCalls" : "MaxCalls",
  "provision.appversionmanagement.cannotEnterNumberSmaller" : "يجب أن تكون القيمة أكبر من الحد الأقصى لرقم الإصدار.",
  "provision.callcenterinstanceslist.config.resourceError" : "معلومات الموارد المعدلة أصغر من العدد الفعلي لمراكز الاتصالات.",
  "provision.tenantspace.feature.transfer.onlinecheck.description" : "قم بتمكين ميزة الاستعلام عبر الإنترنت لموارد التفريغ، وتدعم غرفة الإيجار الاستعلام عبر الإنترنت عن موارد التفريغ",
  "provision.tenantspace.config.error.142" : "يجب أن تكون ميزة appcube متاحة عندما تكون ميزة call tas متاحة.",
  "provision.callcent.selectccgatewayservers" : "حدد خادم CC-Gateway.",
  "provision.spinfo.willdeletespinfo" : "هل تريد بالتأكيد حذف معلومات SP المحددة؟",
  "provision.callcenterinstanceslist.vcallcenterDeatil.sipServerIpAndPort" : "عنوان SBC",
  "provision.tenantspace.feature.emailAgentSendLocalAttachment.description" : "توفر هذه الميزة وظيفة إرسال المرفقات المحلية بواسطة وكلاء البريد الإلكتروني لمساحة المستأجر.",
  "provision.callcenterinstanceslist.obsServer.heartbeatPort" : "ميناء هارتبيت",
  "provision.callcenter.ucs.ip.alert" : "عنوان موازنة تحميل مجموعة CCUCS",
  "provision.callcenterinstanceslist.obsServer.obsName" : "اسم OBS",
  "chat.skill.label.priority" : "الأولوية",
  "provision.appversion.msg.uploadfile.type.fail" : "تنسيق ملف غير صحيح.",
  "provision.speciallist.tip.timeVerification" : "يرجى تحديد تاريخ أكبر من اليوم وأقل من 2038-01-19",
  "provision.tenantspace.cc.maxcallnums" : "الحد الأقصى للمكالمات الصوتية المتزامنة",
  "chat.session.channel.wechat" : "Wechat",
  "provision.tenantspace.feature.softphonenounifiedauth.description" : "قم بتمكين ميزة المصادقة الموحدة لرقم الهاتف الليني بحيث تدعم غرفة المستأجر المصادقة الموحدة لأرقام الهاتف الليني.",
  "provision.sysparam.config.title" : "معلمات النظام",
  "provision.tenantspace.statusRecordTime.3Month" : "3 أشهر",
  "provision.callcent.selectsipserver.inconsistenttype" : "نوع ملقم تسجيل الموظف غير متناسق",
  "provision.callcenterinstanceslist.vcallcenter.ttschannelnums" : "TTSchannelnums",
  "provision.tenant.management.tenantcreate.adminaccount.exist" : "حساب المسؤول موجود مسبقاً",
  "provision.callcenterinstanceslist.softPhone.stautus.occupied" : "قيد الاستخدام",
  "provision.ccp.redis.packagefile.success" : "تم تحميل حزمة الإصدار بنجاح.",
  "ccprovision.recorddrive.config.sftp.ipadressport" : "منفذ خادم SFTP",
  "provision.subscribedetail.button.search" : "استعلام",
  "provision.tenantspace.create" : "إنشاء",
  "provision.appversion.msg.uploadfile.3.fail" : "فشل التحقق من حجم الملف. تحقق من معلمة النظام Maximum Size of an Uploaded Mobile App Installation Package في صفحة الإعدادات > تكوين معلمة النظام.",
  "provision.appsitesmanagement.siteAddr" : "عنوان الموقع",
  "provision.callcent.error.deleteinfo" : "فشل الحذف.",
  "provision.tenantspace.table.config" : "تكوين",
  "provision.subscribedetail.label.enableStatus" : "الإشتراك",
  "chat.weChatConfig.label.qcode" : "مسح رمز الاستضافة",
  "chat.skill.label.ok" : "تم التأكيد",
  "chat.weChatConfig.label.delete" : "الحذف",
  "provision.sysparam.config.item.catalog" : "نوع المعلمة",
  "provision.ccworknos.table.vCCInstId" : "مركز الاتصال الافتراضي",
  "provision.tenantspace.gw.idleRegAgentNum" : "الكمية المتبقية المتزامنة المسجلة للوكيل",
  "provision.vcallcenter.message.unknowcause" : "سبب غير معروف",
  "provision.callcenterinstanceslist.config.softUsage.more" : "عدد الهواتف غير كافٍ. تحقق من التكوين.",
  "provision.tenant.management.tenantmodify.cobrowse.akempty" : "لا يمكن أن تكون معلومات AK فارغة لأنك قمت بتمكين ميزة الاستعراض المشترك للمستأجر.",
  "provision.callcenterinstanceslist.recorddrive.Title" : "تسجيل رسالة محرك الأقراص",
  "provision.tenantspace.option.weeklist_firtolast" : "الأخير",
  "provision.callcenterinstanceslist.config.videocallnumsError" : "يجب أن يكون الحد الأقصى لعدد مكالمات الفيديو المتزامنة أكبر من أو يساوي عدد مقاعد الفيديو.",
  "provision.tenantspace.chooseobsdatasource.callCenterDbSource" : "مصادر بيانات مركز الاتصال",
  "chat.skill.message.delete" : "الحذف",
  "provision.tenant.management.tenantexport.addTaskError" : "فشلت إضافة مهمة التصدير. يرجى التحقق من السجل.",
  "chat.webconfig.label.windowTitle.placeholder" : "أدخل العنوان المراد عرضه في نافذة دردشة العميل.",
  "provision.tenant.management.DSTOffset" : "إزاحة DST (دقيقة)",
  "provision.callcenterinstanceslist.ccdis.poolNodeId2" : "عقدة CTI التي ينتمي إليها CCDIS في وضع الاستعداد",
  "provision.tenant.management.tenantcreate.timezone.failed" : "فشل التحقق من معلومات المنطقة الزمنية.",
  "provision.accesscode.table.whetherSupportTrialUse" : "دعم الاستخدام التجريبي",
  "provision.tenantspace.cc.videoIvrchannelUsagePercent" : "فيديو IVR استخدام القناة",
  "provision.tenantspace.resetpwdfaild.tenant.not.exist" : "فشل تغيير كلمة المرور لأن مساحة المستأجر غير موجودة.",
  "provision.callcent.error.createaccesscode.n400Numbervalidate" : "لا يمكن أن يوجد رمز الوصول ورقم الخدمة علاقة شاملة.",
  "provision.tenantspace.cc.asrchannelUsagePercent" : "استخدام ASR",
  "provision.tenantspace.option.weekday_thu" : "الخميس",
  "provision.authconfig.connected.with.tenantspace" : "معرف APP هذا مرتبط بمساحة مستأجر\، لا يمكن حذفه.",
  "provision.tenantspace.feature.smsOrEmailMarketingSupport.sendMaxNumber.placeholder" : "تتراوح القيمة من 1 إلى 1000000000. تشير القيمة 0 إلى أنه لم يتم تعيين أي حد.",
  "provision.tenantspace.config.ivrserver.select.confirm.message" : "يتم نشر عمليات IVR المحددة على نفس الخادم\، مما قد يتسبب في نقطة فشل واحدة. هل أنت متأكد من رغبتك في المتابعة؟",
  "provision.tenantspace.feature.forceVideoSelfRecord.percentageScope" : "القيمة الدنيا هي 0، والقيمة القصوى هي 100. القيمة الافتراضية هي 100.",
  "chat.skill.message.createSuccess" : "تم إنشاء المهارة بنجاح.",
  "provision.accesscode.table.n400Number" : "رقم الخدمة",
  "provision.callcenterinstance.config.maxcallnumsCompareValidate" : "يجب أن يكون الحد الأقصى لعدد المكالمات الصوتية المتزامنة أكبر من أو يساوي مجموع موظف الصوت وقنوات IVR للصوت والفيديو.",
  "chat.webconfig.message.saveFail" : "فشل حفظ التكوين.",
  "provision.callcent.error.savesipserverdesinfo" : ". تأكد من عدم وجود اسم خادم sbc.",
  "provision.appversionmanagement.queryFailed" : "خطأ في البحث.",
  "chat.skill.message.priorityLength" : "تأكد من أن الأولوية لا تحتوي على أكثر من خمسة أرقام.",
  "provision.tenant.management.updatetenant.failed" : "فشل تعديل تكوين مساحة المستأجر.",
  "provision.tenantspace.intelligent.ivr" : "IVR الذكي",
  "provision.tenantspace.error.deleteSUM" : "فشل حذف مساحة المستأجر SUM.",
  "provision.tenantspace.save.fail.079" : "فشل تحليل معلومات المستخدم المحلي أو الخارجي عند تكوين نظام مصادقة الطرف الثالث.",
  "provision.whiteListAddress.resourcedump.cssproxy" : "وكيل خادم CSS لتفريغ الموارد",
  "provision.tenant.management.tenantcreate.param.content.rule" : "تتكون معلمة الإدخال من حروف\، وأرقام\، وتسطير سفلي (_)\. يجب أن تبدأ بحرف ويجب أن تحتوي على حرفين على الأقل.",
  "provision.callcenterinstanceslist.whiteList.whiteListAdd" : "إضافة قائمة ثقة",
  "provision.tenantspace.feature.transfer.onlinecheck" : "عرض موارد التفريغ عبر الإنترنت",
  "provision.tenant.management.tenantcreate.expiryDate" : "تاريخ انتهاء الصلاحية",
  "provision.callcenterinstanceslist.vcallcenter.SIPServer" : "خادم SBC",
  "provision.recorddrive.error.newrecord.repeated" : "غير قادر على إضافة نفس اسم حرف محرك الأقراص تحت نفس مركز المكالمات",
  "provision.tenantspace.config.amsobjnum.unequal" : "قم بتعيين هذه المعلمة على نفس قيمة خادم المكالمات المجهولة.",
  "provision.tenantspace.authenticationsystem.enter.clientName" : "أدخل اسم نظام مصادقة طرف ثالث.",
  "provision.tenantspace.popuppageurl.whiteListAdd" : "إضافة قائمة ثقة عناوين",
  "provision.tenantspace.authenticationsystem.mappingName" : "قاعدة رسم خرائط الاتحاد",
  "provision.tenantspace.agentinfo.message.selectagent" : "الرجاء تحديد موظف!",
  "provision.callcenterinstanceslist.vcallcenter.close" : "إغلاق",
  "provision.callcenterinstanceslist.label.callnumUseRate" : "استخدام المكالمات الصوتية المتزامنة",
  "provision.callcenterinstanceslist.table.versatileAgentCurCapacity" : "وكلاء متعددي الاستخدامات",
  "provision.whiteListAddress.AddressExist" : "قائمة ثقة العناوين موجودة بالفعل.",
  "chat.skill.label.skillId" : "لا ..",
  "ccprovision.vcallecenter.sucess.updateinfo.adminpassword" : "تم تعديل كلمة المرور بنجاح.",
  "chat.session.endType.agentEnd" : "نهاية الموظف",
  "provision.callcenterinstanceslist.config.servicePort" : "منفذ Nginx",
  "provision.tenantspace.feature.dualChannelRecSupport.description" : "توفر هذه الميزة وظائف تسجيل مزدوج المسار لمساحة المستأجر.",
  "provision.callcenterinstanceslist.config.maxcallnumsValidate" : "الحد الأقصى لعدد المكالمات الصوتية المتزامنة أقل من عدد المكالمات الصوتية التي تم تعيينها بالفعل بواسطة مركز الاتصالات.",
  "provision.tenantspace.authenticationsystem.clientURL" : "عنوان URL لنظام مصادقة الطرف الثالث",
  "provision.tenantspace.save.fail.067" : "فشل التحقق من صحة بيانات نظام مصادقة الطرف الثالث.",
  "provision.callcenterinstanceslist.obsServer.standbyObsDirectHeartbeatIp" : "تعيين عنوان IP النبض لـ OBS في وضع الاستعداد",
  "provision.callcenterinstanceslist.ccdis.poolNodeId" : "عقدة CTI التي ينتمي إليها CCDIS النشط",
  "provision.systemparam.error.validate.notmatchruleip" : "الرجاء إدخال عنوان IPV4 أو IPV6 صالح",
  "provision.callcenterinstanceslist.vcallcenter.newvccId" : "VccInstId",
  "provision.encryptinfo.scenario" : "سيناريو التطبيق",
  "provision.tenantspace.save.fail.063" : "فشل حذف عملية IVR.",
  "provision.label.tenantexport.importdata" : "استيراد البيانات",
  "provision.tenantspace.save.fail.062" : "يجب تكوين رمز الوصول إلى الوسائط المتعددة عند تكوين رمز الوصول من chick-to-dial.",
  "provision.label.tenantexport.paracomplexity" : "لا يفي تعقيد كلمة المرور بالمتطلبات.",
  "provision.callcenterinstanceslist.sipServer.maxRegAgentNum" : "عدد الطلبات المتزامنة",
  "provision.tenant.management.email" : "البريد الإلكتروني",
  "provision.appversionmanagement.systemType" : "نوع النظام",
  "provision.ccworknos.table.ccworknos" : "معرف الموظف",
  "provision.tenantspace.ccName" : "مركز الاتصال",
  "provision.callcenterinstanceslist.ucsServer.singledeleteconfim" : "هل تريد بالتأكيد حذف ملقم CCUCS المحدد؟",
  "provision.callcenterinstanceslist.obsServer.singledeleteconfim" : "هل تريد بالتأكيد حذف ملقم obs للتسجيل المحدد؟",
  "provision.callcenterinstanceslist.vcallcenter.accessCode" : "رمز الوصول",
  "provision.tenant.management.akinfo" : "معلومات AK",
  "provision.systemparam.success.reset" : "تمت إعادة تعيين المعلمة بنجاح",
  "provision.tenantspace.sip.idleRegAgentNum" : "الكمية المتبقية المتزامنة المسجلة للوكيل",
  "provision.tenantspace.save.fail.058" : "فشل حفظ البيانات.",
  "provision.tenantspace.save.fail.057" : "المورد المخصص لمساحة المستأجر غير طبيعي.",
  "provision.tenantspace.agentinfo.agentstatus" : "حالة الوكيل",
  "provision.tenantspace.save.fail.056" : "معلومات مساحة المستأجر الأساسية غير صحيحة.",
  "provision.tenantspace.save.fail.055" : "المهام المعلقة موجودة في مساحة المستأجر.",
  "provision.tenantspace.save.fail.054" : "فشل تخصيص موارد أرقام الهاتف المحمول يدويًا.",
  "provision.grayconfig.deleteconfim" : "هل تريد بالتأكيد حذف التكوين الرمادي؟",
  "provision.tenantspace.save.fail.053" : "مصدر بيانات OBS غير موجود.",
  "provision.tenantspace.save.fail.052" : "خادم OBS غير موجود.",
  "provision.tenantspace.feature.knowledge.open.confirmmsg" : "تعد ميزة AppCube أساس تشغيل ميزة قاعدة المعارف وسيتم تمكينها في نفس الوقت.",
  "provision.tenantspace.save.fail.051" : "لم يتم تخصيص مصدر بيانات OBS.",
  "provision.tenantspace.save.fail.050" : "عدد معرفات الموظف المحددة أقل من عدد الموظفين الجدد.",
  "provision.tenantspace.status.active.fail" : "فشل التنشيط.",
  "chat.session.label.enterQueueTime" : "وقت الانتظار",
  "provision.callcenterinstanceslist.table.agentCurCapacity" : "وكيل الصوت الكمية",
  "provision.tenant.management.administratorinfo" : "معلومات المسؤول المستأجر",
  "provision.callcent.platformserver.ccbcs" : "CCBCS",
  "provision.callcenterinstanceslist.resource.associationvcallcener.updatenode.failed" : "تم تعيين المستأجرين\، لا يمكن تعديل العقد.",
  "ccprovision.IVRSprogress.config.progressNo" : "معرف العملية",
  "provision.whiteListAddress.callout.push" : "عنوان الدفع نتيجة المكالمة الصادرة",
  "chat.weChatConfig.message.emptyDeleteArray" : "يرجى تحديد عنصر التكوين المراد حذفه.",
  "provision.tenantspace.selectStastus.edit.active" : "نشط",
  "provision.tenantspace.config.param.error.ttsNumber" : "الرجاء إدخال كمية TTS.",
  "provision.label.tenantexport.confirm" : "شكر وتقدير",
  "provision.tenantspace.cc.asrchannelnums" : "ASR الكمية",
  "chat.skill.label.maxQueueTime" : "الحد الأقصى لوقت قائمة الانتظار (ثانية)",
  "provision.callcenterinstanceslist.IVRSprogress" : "عملية IVR",
  "provision.tenantspace.save.fail.049" : "الرقم المستبعد لا يفي بالمتطلبات.",
  "provision.tenantspace.save.fail.048" : "يحتوي مقطع معرف الموظف الذي تم تخصيصه يدويًا على معرفات الموظف المخصصة.",
  "provision.tenantspace.save.fail.047" : "يحتوي خادم CC-Gateway على موارد غير كافية.",
  "provision.nodepolicy.sync.failed" : "فشلت المزامنة.",
  "provision.callcenterinstanceslist.config.odfconfigerror" : "معلومات تكوين خدمة ODFS غير صحيحة.",
  "provision.subscribedetail.error.query" : "فشل الاستعلام.",
  "provision.tenantspace.save.fail.046" : "فشل الاتصال بالنظام الأساسي في حذف موظف.",
  "provision.tenantspace.save.fail.045" : "موارد عملية IVR غير كافية.",
  "provision.tenantspace.save.fail.044" : "يُرجى تكوين رمز الوصول لنوع الصوت والفيديو.",
  "provision.tenantspace.save.fail.043" : "عدد تطبيقات وكلاء الوسائط المتعددة أكبر من عدد وكلاء الوسائط المتعددة المتبقية.",
  "provision.tenantspace.save.fail.041" : "يجب أن يكون مجموع الصوت والفيديو والوسائط المتعددة والوكلاء متعددي الاستخدامات وقنوات الصوت والفيديو IVR أكبر من 0.",
  "provision.tenantspace.save.fail.040" : "يرجى تكوين رمز الوصول لنوع الوسائط المتعددة.",
  "provision.tenantspace.feature.smsMarketingSupport.description" : "توفر هذه الميزة وظائف التسويق عبر الرسائل النصية القصيرة لمساحة المستأجر.",
  "provision.callcenterinstanceslist.softPhone.newpasswordconf" : "تكرار كلمة المرور الجديدة",
  "provision.appsitesmanagement.videoAvailable" : "الفيديو متاح",
  "provision.tenant.management.DSTEndTime" : "وقت انتهاء DST",
  "provision.callcent.selectvcallcent" : "حدد مركز اتصالات ظاهري.",
  "provision.softPhone.label.isSyncUap" : "مزامنة UAP",
  "provision.tenant.no.more.agentUsage" : "عدد الموظفين كبير جداً.",
  "provision.callcent.error.updatesipserver.noexist" : "خادم SIP غير موجود.",
  "provision.tenantspace.commercialStatus" : "ما إذا كان موقع تجاري",
  "provision.tenantspace.common.ivr.explain" : "IVR التقليدية: يدعم الوظائف الأساسية مثل playback\، receive\، و TTS. يحتاج TTS إلى تكوين عدد قنوات TTS.",
  "provision.encryptinfo.email" : "تشفير وفك تشفير محتوى البريد الإلكتروني",
  "provision.callcenterinstanceslist.obsServer.activeObsDirectHeartbeatIp" : "تعيين عنوان IP النبض لـ OBS النشط",
  "provision.tenantspace.table.ivrchannels" : "الصوت IVR قناة الكمية",
  "provision.sysparam.config.checkmessage" : "يحتوي على أحرف خاصة. (\" < \"\, \" > \"\, \" / \", وما إلى ذلك)",
  "ccprovision.vcallecenter.config.popup.authentication" : "التحقق من الهوية",
  "provision.callcent.error.saveucsserverdesinfo" : "فشل الإنشاء\، تأكد من عدم وجود اسم ملقم CCUCS.",
  "provision.tenantspace.save.fail.032" : "يجب أن يكون حجم AgentUsage VideoUsage أقل من 10000.",
  "provision.tenantspace.save.fail.031" : "تخفيض المستأجر يرجى العمل على صفحة معلومات الموظف.",
  "provision.tenantspace.save.fail.030" : "رقم الهاتف المبرمج ليس كافي.",
  "chat.session.title.basic" : "معلومات الجلسة الأساسية",
  "provision.callcenterinstanceslist.config.videoIvrchannelsValidate" : "عدد قنوات الفيديو IVR أصغر من عدد القنوات المخصصة فعليًا في مركز الاتصال.",
  "chat.client.label.yes" : "إنه الـ",
  "provision.tenantspace.gw.port" : "منفذ خادم العبّارة",
  "chat.client.button.submit" : "التقديم",
  "provision.callcenterinstanceslist.sipServer.batchdeleteconfim" : "هل تريد بالتأكيد حذف كافة ملقمات التسجيل المحددة؟",
  "provision.callcent.error.accesscode.deletefail.sqlexception" : "فشل الحذف\، استثناء في تنفيذ sql",
  "provision.callcent.error.createaccesscode.validate.accessCodeContainAccesscode" : "لا يمكن أن يحتوي رمز الوصول على علاقة تضمين مع رموز وصول أخرى ضمن مركز الاتصال.",
  "provision.ivr.error.cannotdelete.occupyvdn" : "لا يمكن حذف IVR المعين للمستأجر.",
  "chat.skill.label.cancel" : "للإلغاء",
  "ccprovision.IVRSprogress.config.button.add" : "جديد",
  "provision.callcenterinstanceslist.table.refresh" : "تحديث",
  "provision.systemparam.error.validate.notmatchruleurl" : "الرجاء إدخال عنوان URL صالح",
  "provision.tenantspace.config.param.error.videoCall" : "الرجاء إدخال عدد مكالمات الفيديو المتزامنة.",
  "chat.usefulexpressions.message.willDeleteExpression" : "هل تريد بالتأكيد حذف العبارة العامة؟",
  "provision.tenantspace.save.fail.029" : "ما تبقى من رقم ASR ليس كافيًا.",
  "provision.callcenterinstanceslist.label.eMediaAgentUseRate" : "استخدام وكيل الوسائط المتعددة",
  "provision.tenantspace.save.fail.028" : "ما تبقى من رقم TTS غير كافٍ.",
  "provision.tenantspace.save.fail.027" : "ما تبقى من رقم IVR ليس كافيًا.",
  "provision.tenantspace.save.fail.026" : "العدد المتبقي من المكالمات المجهولة المتزامنة غير كافٍ.",
  "provision.tenantspace.save.fail.025" : "اليسار من VoiceCallNumber لا يكفي.",
  "provision.spinfo.description" : "الوصف",
  "provision.tenantspace.save.fail.024" : "اليسار من VidoeUsage لا يكفي.",
  "provision.tenantspace.option.weeklist_tid" : "الثالث",
  "provision.spinfo.modifyspinfosuccess" : "تم تعديل معلومات SP بنجاح.",
  "provision.tenantspace.save.fail.023" : "اليسار من AgentUsage لا يكفي.",
  "provision.callcenterinstanceslist.table.wasip" : "تعيين IP للخادم",
  "provision.tenantspace.save.fail.022" : "لا يمكن أن يكون AgentUsage و MaxVoiceCallNumbers صفرًا.",
  "provision.target.selectDate" : "تحديد التاريخ",
  "provision.tenantspace.save.fail.021" : "فشل تحديث بيانات مساحة المستأجر.",
  "provision.callcent.error.abnormal" : "غير طبيعي",
  "provision.tenantspace.save.fail.020" : "فشل حذف بيانات مركز المكالمات الظاهري.",
  "provision.callcenterinstanceslist.table.ivrchannels" : "الصوت IVR قناة الكمية",
  "provision.tenantspace.ok" : "حسناً",
  "provision.tenantspace.cc.maxAmsCallnums" : "الحد الأقصى للمكالمات المجهولة المتزامنة",
  "ccprovision.IVRSprogress.config.button.complete" : "مكتمل",
  "provision.tenantspace.config.tenant.intelligent.ivr.featurevalue.not.one" : "قيمة ميزة إيفر الذكية المخصصة للمستأجر ليست 1",
  "provision.tenantspace.obsServer.datasource.name" : "اسم مصدر البيانات",
  "chat.timerange.label.ok" : "تم التأكيد",
  "provision.callcenterinstanceslist.sipServer.idleRegAgentNum" : "المرات المتبقيه المتكرره",
  "provision.tenantspace.feature.knowledgeBaseSupport" : "قاعدة المعارف",
  "provision.systemparam.error.noparamselected" : "الرجاء تحديد معلمة أولاً",
  "provision.callcenterinstanceslist.whiteList.IP" : "عنوان قائمة الثقة",
  "provision.tenantspace.config.amsNumber.error" : "لا يمكن أن يكون عدد المكالمات المجهولة المتزامنة فارغًا أو يجب أن يكون 0 أو عددًا صحيحًا موجبًا.",
  "provision.tenantspace.save.fail.019" : "السعة المتبقية لمركز الاتصال أقل من الوكلاء الذين تم تخصيصهم حديثًا.",
  "provision.callcent.error.savewhiteaddripinfo" : "تنسيق عنوان قائمة الثقة غير صحيح.",
  "provision.tenantspace.save.fail.018" : "مركز المكالمات فارغ.",
  "chat.session.label.endType" : "نوع نهاية الجلسة",
  "provision.tenantspace.save.fail.017" : "مركز المكالمات الظاهري فارغ.",
  "provision.tenantspace.save.fail.016" : "فشل تحديث بيانات مركز المكالمات.",
  "provision.tenantspace.save.fail.015" : "فشل تحديث بيانات رقم الهاتف.",
  "chat.skill.message.willDeleteAllSkills" : "هل تريد بالتأكيد حذف جميع المهارات؟",
  "provision.tenantspace.save.fail.014" : "فشل تحديث بيانات معرف الموظف.",
  "chat.thirdportal.chatWindow.button.startChat" : "بدء محادثة",
  "provision.callcenterinstanceslist.vcallcenterDeatil.accessCode" : "رمز الوصول المخصص لـ VDN.",
  "provision.tenantspace.save.fail.013" : "فشل تحديث بيانات رمز الوصول.",
  "provision.tenantspace.dataUpload.voiceFile" : "ملف صوتي",
  "provision.tenantspace.save.fail.012" : "فشل تحديث بيانات مركز المكالمات الظاهري.",
  "provision.tenantspace.sip.name" : "اسم خادم SBC",
  "provision.tenantspace.save.fail.011" : "فشل حفظ بيانات مركز المكالمات الظاهري.",
  "provision.tenantspace.save.fail.010" : "عدد مقاطع الأرقام المشغولة أقل من عدد الموظفين المطبقين. القيمة التي تم إرجاعها خالية.",
  "provision.callcenterinstanceslist.table.nodepolicyconfirmmessage" : "هل تريد بالتأكيد مزامنة تكوينات نهج العقدة؟",
  "provision.callcenterinstanceslist.label.maxAmsCallNums" : "مكالمات مجهولة متزامنة",
  "provision.tenant.management.pool.ctinode2" : "عقدة CTI 2",
  "provision.tenant.management.pool.ctinode1" : "عقدة CTI 1",
  "provision.tenantspace.selectStastus.unallocated" : "معلق",
  "provision.speciallist.tip.userNoLength" : "لا يمكن أن يتجاوز رقم المشترك 100 حرف",
  "provision.accesscode.table.enterpriseName" : "اسم المؤسسة",
  "provision.tenantspace.config.choosesoftphone" : "حدد رقم الهاتف.",
  "provision.tenant.management.tenantexport.cancel" : "إلغاء",
  "provision.callcenterinstanceslist.config.videoAgentChannelsValidate" : "عدد قنوات موظف الفيديو أقل من عدد القنوات المخصصة فعليًا في مركز الاتصالات.",
  "provision.callcenterinstanceslist.table.obssyncConfimMessage" : "هل تريد بالتأكيد مزامنة تكوين OBS؟",
  "provision.tenantspace.save.fail.009" : "مقطع معرف الموظف فارغ.",
  "provision.tenantspace.save.fail.008" : "رقم الهاتف فارغ.",
  "provision.tenantspace.save.fail.007" : "مركز المكالمات غير موجود.",
  "provision.callcenterinstanceslist.sipServer.sip" : "SIP",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.explain2" : "يمكن إضافة خمس مجموعات من الأرقام كحد أقصى.",
  "provision.tenantspace.save.fail.006" : "لا يمكن أن يكون عدد الموظفين تحت المستأجر أكبر من عدد الموظفين المتبقين على خادم SBC.",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.explain1" : "معاينة مكتبة الأرقام",
  "provision.tenantspace.save.fail.005" : "رمز الوصول مشغول.",
  "chat.weChatConfig.label.secret" : "مفتاح الضمان",
  "provision.tenantspace.save.fail.004" : "رمز الوصول فارغ.",
  "provision.tenantspace.save.fail.003" : "الحد الأقصى لعدد مكالمات الفيديو المتزامنة أكبر من أو يساوي عدد وكلاء الفيديو.",
  "provision.subscribedetail.label.emailAddr" : "البريد الإلكتروني للتواصل",
  "provision.tenantspace.save.fail.002" : "الحد الأقصى لعدد المكالمات الصوتية المتزامنة أكبر من أو يساوي عدد الموظفين وقنوات IVR.",
  "provision.tenantspace.save.fail.001" : "لا يمكن أن يكون عدد وكلاء الصوت\، وعامل الفيديو\، وعامل الوسائط المتعددة\، وقنوات IVR 0.",
  "provision.tenantspace.feature.operation" : "تتم معالجة بيانات ميزة المستأجر في الواجهة الخلفية. عرض نتيجة معالجة الميزات على الصفحة الحالية بعد دقيقتين.",
  "provision.tenant.management.tenantspaceid" : "معرف مساحة المستأجر",
  "provision.obs.error.sync" : "فشلت المزامنة.",
  "provision.callcent.platformserver.cms" : "CMS",
  "provision.tenantspace.config.amsServer.idleregagentnum.insufficient.error" : "عدد المكالمات المتزامنة المتبقية على خادم المكالمات المجهولة غير كافٍ.",
  "ccprovision.vcallecenter.config.popup.modifyinfo" : "تعديل كلمة المرور",
  "chat.client.label.accountNumber" : "رقم الحساب",
  "provision.systemparam.error.validate.notmatchruleregex" : "لا تتطابق معلمة الإدخال مع التعبير العادي لقاعدة التحقق",
  "provision.callcenterinstanceslist.vcallcenter.agentNum" : "عدد الوكلاء المعينين",
  "provision.callcenterinstanceslist.ccgatewayserver.ccGatewayPort" : "منفذ عبّارة CC",
  "provision.tenant.management.tenantcreate.success" : "تم إنشاء مساحة المستأجر بنجاح.",
  "chat.agent.label.historicalBill" : "مشاريع القوانين التاريخية",
  "provision.tenantspace.obs.searchName" : "أدخل اسم خادم OBS المراد الاستعلام عنه.",
  "provision.authconfig.deleteconfim" : "هل تريد بالتأكيد حذف معرف التطبيق؟",
  "provision.callcenterinstanceslist.softPhone.softPhoneAdd" : "رقم هاتف جديد",
  "provision.tenant.management.edit.agentusage.mustinc" : "لا يمكن أن يكون عدد الموظفين الجدد أقل من عدد الموظفين الأصليين.",
  "provision.appsitesmanagement.sureBatchDeleteData" : "قد يؤدي حذف هذه المواقع إلى فشل المستخدمين في تسجيل الدخول إلى تطبيق الجوال. هل تريد بالتأكيد حذفها؟",
  "provision.callcenterinstanceslist.sipServer.sipServerIp" : "عنوان IP لخادم SBC",
  "provision.callcenterinstanceslist.table.delete" : "حذف",
  "provision.speciallist.tip.reasonLength" : "لا يمكن أن يتجاوز السبب 1024 حرفًا",
  "provision.spinfo.createTime" : "CreateTime",
  "provision.callcenterinstance.config.ivrchannelsValidate" : "يجب أن يكون مجموع عدد قنوات الصوت والفيديو IVR أكبر من أو يساوي مجموع عدد قنوات TTS وعدد قنوات ASR.",
  "provision.tenantspace.option.weekday_wed" : "الأربعاء",
  "provision.tenantspace.config.select.default.phone" : "يرجى تحديد الرقم الطالب الافتراضي.",
  "provision.callcenterinstanceslist.label.videoAgentChannelUseRate" : "استخدام قناة وكيل الفيديو",
  "provision.tenant.management.tenantexport.addTaskSuccess" : "تمت إضافة مهمة التصدير بنجاح.",
  "ccprovision.IVRSprogress.config.leftovercurcallNo" : "كمية قناة المكالمة المتزامنة المتبقية",
  "provision.tenantspace.agentinfo" : "معلومات الوكيل",
  "provision.callcenterinstanceslist.config.asrchannelnumsValidate" : "عدد قنوات ASR أصغر من عدد القنوات المخصصة فعليًا في مركز الاتصالات.",
  "chat.agentConsole.message.content.locate" : "[رسالة الموقع]",
  "provision.authconfig.authinfomation" : "معلومات المطور",
  "provision.callcenterinstanceslist.vcallcenterDeatil.uapIpAndPort" : "عنوان UAP",
  "provision.callcent.error.accesscode.deletefail.part.onccgateway" : "فشل حذف جزء من رمز الوصول على النظام الأساسي.",
  "provision.callcenterinstanceslist.table.callcenterinstanceslist" : "قائمة مركز الاتصال",
  "provision.callcenterinstanceslist.config.sysAdminpassword" : "كلمة مرور مسؤول CTI",
  "provision.callcenterinstanceslist.vcallcenter.period" : ".",
  "provision.callcenterinstanceslist.table.ttschannelnums" : "TTS الكمية",
  "promotion.tenant.management.tenantedit.vccupdate.failed" : "فشل تحديث المركز الظاهري.",
  "chat.client.message.hiMyWorkno" : "مرحبًا، معرف الموظف الخاص بي هو",
  "provision.callcenterinstanceslist.config.wnFromValidate" : "لا يمكن أن يكون مجموع مقطع رقم البدء وسعة النظام الفعلية أكبر من 50000.",
  "chat.session.label.endTime" : "وقت انتهاء الجلسة",
  "provision.tenantspace.detail.apply.accout" : "الحساب",
  "provision.tenantspace.table.expiryDate" : "الموعد النهائي",
  "provision.tenant.management.tenantedit" : "تحرير معلومات المستأجر",
  "provision.whiteListAddress.resourcedump.obsproxy" : "وكيل خادم OBS لتفريغ الموارد",
  "chat.session.title.detail" : "تفاصيل الجلسة",
  "provision.tenantspace.feature.contractdigitalsignature" : "التوقيع الرقمي للعقد",
  "chat.agent.label.mybill" : "فاتورتي.",
  "chat.skill.label.submit" : "التقديم",
  "chat.weChatConfig.label.bindSkill" : "ربط قائمة انتظار مهارة",
  "ccprovision.recorddrive.config.sftp.filestoragepath" : "مسار الملف",
  "provision.tenant.management.tenantcreate.inter.failed" : "فشل استدعاء الخدمة الداخلية لإنشاء مساحة المستأجر.",
  "chat.weChatConfig.label.verify" : "التحقق",
  "provision.callcenter.gateway.address.alert" : "عنوان موازنة تحميل مجموعة عبّارة CC-Gateway",
  "provision.tenantspace.feature.mobileAppH5.open.confirmmsg" : "ميزة AppCube هي الأساس لتشغيل ميزات MobileApp وسيتم تمكينها في وقت واحد",
  "provision.tenantspace.table.maxVideoCalls" : "الحد الأقصى لمكالمات الفيديو المتزامنة",
  "provision.tenantspace.dstoffsettime.verification.fail" : "القيمة هي عدد صحيح يتراوح من -1440 إلى 1440.",
  "provision.callcent.platformserver.isqm" : "iSQM",
  "provision.callcenterinstanceslist.config.save" : "حفظ",
  "provision.appversion.msg.process.fail" : "فشل معالجة معلومات إصدار التطبيق.",
  "chat.client.message.evaluationSuccess" : "ونجحت الدراسة الاستقصائية عن الرضا.",
  "provision.tenantspace.cc.amsCallnumUsagePercent" : "استخدام المكالمات المجهولة المتزامنة",
  "provision.tenantspace.option.weekday_mon" : "الاثنين",
  "provision.callcent.error.updateccgateway.ondatabase" : "فشل التحديث\، فشل تعديل خادم CC-Gateway في قاعدة البيانات.",
  "provision.tenantspace.config.chooseSIPServer" : "حدد خادم SBC.",
  "provision.subscribedetail.validate.alphavalidate" : "توجد أحرف غير صالحة. يتم دعم الحروف \، والأرقام \، والسفلة السفلية (_) فقط.",
  "provision.callcent.platformserver.kafka.switch.panel" : "تشير إلى ما إذا كان سيتم تمكين الخدمة.",
  "chat.webconfig.message.configdirective" : "انسخ الرمز التالي والصقه في العلامة </body> على موقع الويب الخاص بك\، واحفظ الرمز وأطلقه للوصول بسرعة إلى Huawei Service Cloud. بعد تحرير الرمز، سيتم عرض إدخال Huawei Service Cloud في الزاوية اليمنى السفلى من موقع الويب الخاص بك. يمكنك النقر لإجراء محادثة في الوقت الحقيقي مع طاولة عمل خدمة العملاء.",
  "provision.sysparam.config.refresh" : "تحديث",
  "chat.session.label.remark" : "تعليق على",
  "provision.tenantspace.obsServer.backup.datasource.name" : "اسم مصدر بيانات الاستعداد",
  "provision.tenant.management.pool.ctinode.name" : "اسم العقدة",
  "provision.tenant.management.tenantcreate.companyName" : "الشركة",
  "provision.tenantspace.feature.onlineCustomerServiceAssets.description" : "هذه الميزة تمكن المستأجر لتضمين JS عملاء الدردشة عبر الإنترنت وضعت في AppCube في صفحات طرف ثالث لتنفيذ الدردشة عبر الإنترنت.",
  "provision.tenantspace.resetpwdsuccess" : "تمت إعادة تعيين كلمة المرور بنجاح.",
  "provision.callcenterinstanceslist.config.workNoCheckError" : "يجب أن يكون رقم بداية معرف العمل أصغر من رقم النهاية.",
  "provision.tenantspace.config.error.softphone.noaddagent" : "لم تتم إضافة أي صوت\أو فيديو\أو وسائط\أو وسائط\أو عامل جوال.",
  "provision.callcenterinstanceslist.config.nmsserviceIP" : "عنوان IP لـ ODFS",
  "provision.callcenterinstanceslist.table.deleteconfim" : "تأكيد الحذف",
  "provision.tenantspace.agentinfo.message.searchSipAccount" : "يُرجى إدخال رقم الهاتف البرمجي",
  "chat.usefulexpressions.message.createSuccess" : "تم إنشاء العبارة العامة بنجاح.",
  "provision.tenantspace.config.softphoneassign.softphonescope.required" : "أدخل مقطع رقم الهاتف المراد إضافته وانقر فوق موافق.",
  "ccprovision.vcallecenter.config.popup.confirmnewpassword" : "تأكيد كلمة المرور",
  "provision.appversionmanagement.harmony" : "HarmonyOS",
  "ccprovision.recorddrive.config.sftp.identitycheck" : "التحقق من الهوية",
  "provision.tenantspace.feature.contractdigitalsignature.description" : "تتيح هذه الميزة التوقيعات الرقمية للعقود الإلكترونية.",
  "provision.callcenterinstanceslist.config.adminWorkNoError" : "يجب أن يتراوح رقم بداية معرف موظف المسؤول من 55001 إلى 59999.",
  "provision.callcenterinstanceslist.config.eMediaAgentCurCapacityValidate" : "عدد وكلاء الوسائط المتعددة أقل من عدد مقاعد الوسائط المتعددة المخصصة بالفعل في مركز الاتصال.",
  "provision.tenantspace.choose.accesscode" : "تحديد رمز الوصول",
  "provision.callcenterinstanceslist.sipServer.sipServerPort" : "رقم منفذ SBC",
  "provision.appversionmanagement.historicalVersion" : "النسخة التاريخية",
  "provision.callcent.error.saveucsserverdesinfo.ucsServernoexist" : "خادم CCUCS غير موجود.",
  "provision.callcent.error.deleteinfo.from.cms.failed" : "فشل CMS في حذف المعلومات ذات الصلة.",
  "provision.callcent.success.deleteinfo" : "تم الحذف بنجاح.",
  "provision.spinfo.certificate" : "محتوى الشهادة",
  "provision.tenantspace.feature.smsOrEmailMarketingSupport.sendMaxNumber" : "إرسال الحد الأعلى لكل يوم",
  "provision.callcent.label.reset" : "إعادة الضبط",
  "chat.common.message.delete" : "الحذف",
  "provision.systemparam.error.validate.userNotExists" : "الرجاء إدخال حساب مستخدم صالح",
  "chat.agent.title.userInformation" : "معلومات العميل",
  "provision.callcenterinstanceslist.table.operate" : "العملية",
  "provision.tenantspace.config.select.commercial.status" : "يرجى اختيار دولة تجارية.",
  "provision.appsitesmanagement.pleaseEnter" : "-اختر-",
  "provision.tenantspace.table.videoivrchannels" : "فيديو IVR قناة الكمية",
  "provision.callcenterinstanceslist.table.wasport" : "قم بتعيين رقم منفذ الخادم.",
  "provision.systemparam.error.validate.notmatchruledigits" : "الرجاء إدخال عدد صحيح موجب صحيح",
  "provision.ccworknos.table.creataccessCode" : "جديد",
  "provision.callcenterinstanceslist.table.baseinfo" : "معلومات أساسية",
  "provision.appversionmanagement.new" : "إضافة",
  "provision.callcenterinstanceslist.table.maxcallnums" : "الحد الأقصى للمكالمات الصوتية المتزامنة",
  "provision.callcent.selectcallcent" : "حدد مركز اتصالات.",
  "provision.callcenterinstanceslist.nodepolicy.tenantspacename" : "اسم VDN",
  "pprovision.tenantspace.feature.emailAgentForwarding.description" : "قم بتمكين ميزة إعادة توجيه موظف البريد الإلكتروني. تدعم المساحة المؤجرة وظيفة إعادة توجيه البريد الإلكتروني لموظف البريد الإلكتروني.",
  "provision.tenantspace.save.fail.123053" : "دور الموظف هو الدور الافتراضي ولا يمكن تعديله.",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.error" : "لا يمكن أن يكون معرف الموظف النهائي أقل من معرف موظف البدء.",
  "provision.tenantspace.save.fail.123052" : "تمت الإشارة إلى دور الموظف من قِبل الموظف ولا يمكن حذفه.",
  "provision.tenantspace.save.fail.123051" : "اسم مكرر لدور الموظف.",
  "provision.callcent.platformserver.certificateChoose" : "تحديد شهادة",
  "provision.callcenterinstanceslist.table.allowedTTSAndASRCallNum" : "عدد قنوات IVR الذكية",
  "provision.callcenterinstanceslist.table.update" : "تحرير",
  "provision.accesscode.table.audioAndvideo" : "الصوت والفيديو",
  "provision.batchimport.message.import.button" : "استيراد",
  "chat.client.greeting.evening" : "مساء الخير!",
  "provision.callcent.pool.select.different.sipserver.error" : "يجب تحديد ملقمات تسجيل الموظف من نفس النوع لعقد مختلفة.",
  "provision.callcenterinstanceslist.softPhone.used.select" : "الاستخدام",
  "provision.callcenterinstanceslist.vcallcenter.CcgatewayServer" : "خادم عبّارة CC",
  "provision.tenantspace.config.chooseAgentRegistrationServerTitle" : "حدد خادم تسجيل الموظف.",
  "provision.tenant.management.tenantname.minlength" : "لا يمكن أن يحتوي اسم مساحة المستأجر على أقل من 6 أحرف.",
  "provision.tenantspace.table.maxVideoAgents" : "كمية وكيل الفيديو",
  "provision.tenantspace.selectStastus.edit.invalid" : "غير صالح",
  "provision.appversionmanagement.appPackageTips" : "يمكن تحميل ملف a.apk فقط.",
  "provision.tenantspace.create.onlineUserNumber" : "رقم المستخدم عبر الإنترنت",
  "provision.tenant.management.admininfo" : "تسجيل المستأجر المسؤول",
  "chat.client.label.rateService" : "يرجى تقييم الخدمة",
  "provision.tenant.management.batchcreate.success.createtask" : "تمت إضافة مهمة بنجاح لإنشاء مساحات مستأجرة على دفعات.",
  "provision.callcenterinstanceslist.vcallcenter.vcallcenter" : "مركز الاتصال الافتراضي",
  "provision.tenant.management.phonenumber" : "رقم الهاتف",
  "chat.skill.message.saveSuccess" : "تم حفظ المهارة بنجاح.",
  "chat.weChatConfig.label.create" : "إضافة",
  "provision.tenantspace.option.month_12" : "كانون الأول/ديسمبر",
  "provision.callcent.error.checkparam" : "يحتوي على characters(\"<\"\\,\">\"\\,\"\\\"and غير صالح أكثر)",
  "provision.tenantspace.option.month_11" : "نوفمبر",
  "provision.callcenterinstanceslist.config" : "مركز الاتصال",
  "provision.tenantspace.option.month_10" : "أكتوبر",
  "provision.tenantspace.feature.customdata.open.customcenter.confirmmsg" : "سيؤدي تمكين ميزات البيانات المخصصة إلى تعطيل ميزات مركز العملاء تلقائيًا",
  "provision.callcent.error.saveccgateway.regAgentNumError" : "عدد المقاعد المسجلة المعدلة أقل من العدد الفعلي للمقاعد المخصصة.",
  "provision.tenantspace.config.chooseDPN" : "حدد أحد رموز الوصول كرقم الاتصال الافتراضي.",
  "provision.callcenterinstanceslist.ucsServer.ucsServerIp" : "خادم CCUCS IP",
  "provision.callcent.error.updateaccesscode.fail.syncccmanagement" : "فشل تعديل \, المتزامن للمعلومات إلى غرفة الإيجار",
  "provision.tenantspace.save.fail.107-107" : "ipccbase configproxy استخدام.",
  "chat.webconfig.label.functionalsettings" : "إعدادات الوظيفة",
  "ccprovision.vcallecenter.error.updateinfo.adminpassword" : "خطأ في تعديل كلمة المرور.",
  "provision.tenantspace.feature.dualCallTransparentDisplaySupport.description" : "بعد تمكين هذه الميزة\، يمكنك تخصيص الأرقام ليتم عرضها على الوكلاء والمشتركين (اعتماداً على ما إذا كان خط الأرقام متصلاً). إذا كان رمز الوصول فارغ\، يتم تمكين الميزة بشكل افتراضي.",
  "provision.encryptinfo.strength" : "قوة",
  "chat.webconfig.button.save" : "تم الحفظ",
  "provision.tenantspace.save.fail.078modify" : "فشل تعديل مساحة المستأجر عند تكوين نظام مصادقة الطرف الثالث.",
  "provision.tenantspace.common.whatsapp.cloudapi.explain" : "تمكن هذه الميزة المستأجر من الاتصال بواجهة برمجة تطبيقات سحابة WhatsApp من خلال تكوين القناة للتعامل مع الرسائل النصية. حاليًا ، لا يمكن إظهار هذه الميزة إلا ولا يمكن وضعها قيد الاستخدام التجاري.",
  "provision.tenantspace.table.update" : "تحرير",
  "provision.tenantspace.obsServer.datasource.databasename" : "اسم قاعدة البيانات",
  "chat.skill.label.createSkill" : "إنشاء",
  "provision.tenantspace.popuppageurl.modifyprompt" : "قم بتغيير عنوان IP في قائمة الثقة أولاً.",
  "provision.tenantspace.common.anonymous.explain" : "قم بتمكين ميزة المكالمة الصادرة المجهولة وتمكين وظيفة المكالمة الصادرة المجهولة لمساحة المستأجر.",
  "provision.tenantspace.table.maxVideoCallnums" : "الحد الأقصى لعدد مكالمات الفيديو المتزامنة (≥ عدد وكلاء الفيديو)",
  "provision.tenantspace.table.tenantSpaceStatus" : "الحالة",
  "provision.callcent.success.deletesoftphonenumber.unfinishtask" : "تتم معالجة مهمة الحذف. يُرجى إعادة المحاولة لاحقًا.",
  "provision.callcent.error.updatesoftphonenumber.fail.syncccmanagement" : "فشلت مزامنة الهاتف المحمول لتعديل المعلومات إلى غرفة التأجير.",
  "chat.usefulexpressions.message.willDeleteExpressions" : "هل تريد بالتأكيد حذف العبارة العامة المحددة؟",
  "provision.appversionmanagement.latestVersion" : "أحدث إصدار",
  "provision.callcenterinstanceslist.vcallcenterDeatil.DateValidateFailed" : "يرجى اختيار تاريخ أكبر من اليوم وأقل من 2038-1-19",
  "ccprovision.IVRSprogress.config.IPAddress" : "عنوان IP",
  "provision.subscribedetail.emailStatus.value_1" : "تم الإرسال بنجاح",
  "provision.callcenterinstance.config.videoCallValidate" : "يجب أن يكون الحد الأقصى لعدد مكالمات الفيديو المتزامنة أكبر من أو يساوي عدد مقاعد الفيديو.",
  "provision.subscribedetail.emailStatus.value_0" : "فشل الإرسال",
  "provision.tenantspace.option.weekday_sun" : "الأحد",
  "provision.tenantspace.config.softphoneassign.softphonescope.contain" : "تم تحديد رقم الهاتف. الرجاء إدخال واحدة أخرى.",
  "provision.callcent.error.createaccesscode.fail.onccgateway" : "فشل \, في إضافة رمز وصول إلى النظام الأساسي.",
  "provision.label.tenantexport.passValidateFailed" : "يجب أن تحتوي القيمة على حروف\، وأرقام\، وأحرف خاصة {'@'}%.",
  "provision.tenantspace.config.worknoassign.filterworkNo" : "رقم الفحص البنك",
  "provision.tenantspace.ivr.maxChnNum" : "كمية المكالمات المتزامنة",
  "provision.callcent.selectcallcentccgateway" : "يرجى تحديد CC-Gateway.",
  "chat.agent.label.billDetail" : "تفاصيل الفاتورة",
  "provision.tenantspace.language.cn" : "الإنجليزية",
  "provision.tenantexport.download" : "تنزيل معلومات المستأجر",
  "ccprovision.vcallecenter.config.popup.adminworkno" : "معرف مسؤول التكوين",
  "chat.client.label.bill" : "فاتورتي.",
  "chat.session.label.skillQueueId" : "معرف قائمة انتظار المهارات.",
  "provision.callcenterinstanceslist.label.maxVideoCallnums" : "الحد الأقصى لمكالمات الفيديو المتزامنة",
  "provision.whiteListAddress.outboundcall.sftp.fileserver" : "خادم ملفات SFTP للمكالمات الصادرة",
  "provision.callcenterinstanceslist.table.download" : "تحميل",
  "provision.callcenterinstanceslist.ccdis.syncconfim.msg" : "هل تريد بالتأكيد مزامنة تكوينات CCDIS؟",
  "provision.label.tenantexport.unprocessed" : "غير المجهزة",
  "provision.callcenterinstanceslist.vcallcenter.CcgatewayServer.title" : "تكوين عبّارة CC",
  "ccp.version.versioncode" : "الإصدار",
  "provision.sysparam.config.itemname.tips" : "أدخل اسم معلمة.",
  "chat.client.label.like" : "أعتقد أنك تحب",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.paramserror" : "معلومات الرقم غير صحيحة.",
  "provision.tenantspace.feature.softphonenounifiedauth" : "المصادقة الموحدة لرقم الهاتف",
  "ccprovision.recorddrive.config.sftp.account" : "حساب SFTP",
  "provision.tenantspace.chooseFeature" : "تحديد الميزات",
  "provision.appsitesmanagement.modifier" : "معدّل",
  "provision.tenant.management.tenantid" : "معرف المستأجر",
  "ccprovision.recorddrive.config.sftp.accountpassword" : "كلمة مرور خادم SFTP",
  "provision.callcent.label.close" : "إغلاق",
  "provision.spinfo.addspinfofailed" : "فشلت إضافة معلومات موفر الخدمة.",
  "provision.tenantspace.feature.emailMarketingSupport" : "التسويق عبر البريد الإلكتروني",
  "chat.webconfig.label.bandingskill" : "ربط قائمة انتظار مهارة",
  "provision.vcallcenter.message.unknowsolution" : "اتصل بمهندسي Huawei لتحليل الخطأ وتصحيحه",
  "chat.agent.label.online" : "عبر الإنترنت",
  "provision.callcenterinstanceslist.whitelist.singledeleteconfim" : "هل تريد بالتأكيد حذف عنوان قائمة الثقة المحددة؟",
  "provision.callcent.error.updatesipserver.ipandport.alreadyexist" : "IP ورقم المنفذ الخاص بخادم SBC موجودان بالفعل ولا يمكن تعديلهما.",
  "provision.tenantspace.save.fail.123004" : "تتم الإشارة إلى العناصر المهارة بواسطة قائمة انتظار المهارة ولا يمكن تعديل الاسم.",
  "provision.tenantspace.save.fail.123003" : "تتم الإشارة إلى العناصر المهارة بواسطة قائمة انتظار المهارة ولا يمكن حذفها.",
  "provision.tenantspace.save.fail.123002" : "عنصر المهارة غير موجود.",
  "provision.tenantspace.save.fail.123001" : "اسم مكرر لعنصر المهارة.",
  "provision.tenantspace.intelligent.agent.description" : "وكيل ذكي: يدعم اتصال القناة الأساسي والتحليل الدلالي للنص والتوصية الذكية. قم بتكوين عدد الموظفين قبل تكوين الموظفين الأذكياء. قم بتكوين موارد ASR للعملاء الأذكياء الذين يدعمون جلسات الصوت.",
  "provision.tenantspace.reset" : "إعادة الضبط",
  "provision.tenantspace.agentinfo.message.audio" : "الصوت",
  "provision.tenantspace.config.select.vitual.ccname" : "الرجاء إدخال اسم مركز المكالمات الظاهري.",
  "provision.softPhone.table.forcemodifyconfim.message" : "ستؤدي هذه العملية إلى إعادة تعيين كلمة مرور الهاتف. لتغيير كلمة المرور\، يجب تسجيل الدخول إلى مساحة المستأجر. هل أنت متأكد من رغبتك في المتابعة؟",
  "provision.tenantspace.option.weeklist_tidtolast" : "الأخير ولكن اثنين",
  "chat.timerange.label.customizes" : "التخصيص",
  "provision.tenantspace.config.select.whiteRepeate" : "رقم قائمة الثقة المكرر.",
  "provision.callcenterinstanceslist.vcallcenterDeatil.vccId" : "معرف VCC",
  "provision.tenantspace.create.basicInfo" : "معلومات أساسية",
  "chat.client.greeting.info" : "كيف يمكنني مساعدتك؟",
  "provision.title.select" : "تحديد",
  "provision.callcenter.videoapplicationmodel.mcuAndsfuModel" : "MCU + SFU الفيديو",
  "provision.tenantspace.feature.cobrowse.selectcobrowsevalue.invalid" : "يتراوح الحد الأقصى لعدد اتصالات الاستعراض المشترك من 1 إلى 50000.",
  "provision.tenantspace.feature.forceVideoSelfRecord.attendant" : "تمكين لجميع الحاضرين",
  "provision.tenantspace.selectStastus.edit.stop" : "تعليق",
  "provision.tenantspace.table.maxAgents" : "ماكس. وكلاء الصوت",
  "provision.tenantspace.create.tenantExpiryDate" : "وقت انتهاء صلاحية المستأجر",
  "provision.tenantspace.authenticationsystem.selectClientName" : "حدد نظام مصادقة تابع لجهة خارجية.",
  "provision.callcenterinstanceslist.vcallcenter.nodepolicy" : "تكوين نهج تحديد العقدة لموارد المكالمات الصادرة",
  "provision.tenantspace.config.chooseAnonymousCallServer" : "تحديد ملقم اتصال مجهول",
  "chat.agentConsole.message.error.emptyMessage" : "[لا تحتوي الرسالة على محتوى ليتم عرضه.]",
  "provision.spinfo.msg.certificateformatinfo" : "لا يمكن حذف معرفات البداية والنهاية -----BEGIN CERTIFICATION-EEND CERTIFICATION--",
  "provision.callcenterinstanceslist.sipServer.webRtcanonymousCall" : "مكالمة WebRTC مجهولة الهوية",
  "provision.tenantspace.popuppageurl.tableheadname" : "العنوان",
  "provision.nodepolicy.save.failed" : "فشل الحفظ.",
  "provision.tenantspace.config.choosedatasource" : "حدد مصدر بيانات OBS.",
  "promotion.tenant.management.tenantedit.vcccapacity.insufficient" : "سعة المركز الافتراضي غير كافية.",
  "provision.tenantspace.common.newcase.explain" : "قم بتمكين ميزة الحالة 2.0 لتوفير إمكانات إدارة الحالة وتكوينها للمستأجرين.",
  "provision.tenantspace.ExpireTenantSpaceCount" : "عدد المستأجرين الذين على وشك الانتهاء في غضون 30 يومًا",
  "provision.callcenterinstanceslist.config.maxAmsCallNumsValidate" : "لا يمكن أن يكون الحد الأقصى لعدد المكالمات المجهولة المتزامنة أقل من عدد المكالمات المجهولة التي يخصصها مركز الاتصالات.",
  "provision.tenantspace.selectStastus.resource.allocation.failed" : "فشل في تخصيص المورد",
  "chat.client.label.header" : "Telco+",
  "provision.tenantspace.dataUpload.resourceDump" : "تفريغ الموارد",
  "provision.systemparam.error.validate.notmatchrulerequired" : "معلمة الإدخال مطلوبة",
  "provision.encryptinfo.mode" : "الوضع",
  "chat.weChatConfig.label.verifycode" : "الرمز المميز",
  "provision.callcenterinstanceslist.ccdis.ccdisNetId" : "معرف مكون الشبكة النشط",
  "provision.tenantspace.feature.dualCallAPPTransparentDisplaySupport.description" : "تمكن هذه الميزة وكلاء المستأجر من التعامل مع مهام المكالمات على تطبيقات الجوال.",
  "provision.tenantspace.agentinfo.agentstatus.offline" : "غير متصل",
  "provision.log.downloadSK" : "تحميل SK",
  "provision.tenant.management.updatetenant.success" : "تم تعديل تكوين مساحة المستأجر بنجاح.",
  "provision.tenantspace.chooseobsdatasource.error1" : "حدد خادم OBS.",
  "chat.skill.message.saveFail" : "فشل حفظ المهارة.",
  "provision.callcenterinstanceslist.obsServer.taskRefreshPeriod" : "فترة تحديث المهمة",
  "provision.tenantspace.config.agentusage.ivr" : "وكيل الصوت الكمية\, وكيل الفيديو الكمية\, وكيل الوسائط المتعددة الكمية\, تنوعا وكيل الكمية\, الصوت IVR قناة الكمية والفيديو IVR قناة الكمية لا يمكن أن يكون كل صفر",
  "chat.session.channel.mobile" : "محطة متنقلة",
  "provision.accesscode.table.endNumber" : "رمز الوصول النهائي",
  "chat.weChatConfig.label.facebook" : "الفيسبوك",
  "provision.callcenterinstanceslist.sipServer.sipServerType" : "نوع خادم SBC",
  "provision.systemparam.error.validate.notmatchrulerangelength" : "تتجاوز معلمة الإدخال الحد الأقصى للطول أو أقل من الحد الأدنى للطول",
  "provision.tenantspace.config.worknoassign.availableworknocount" : "الأرقام المتوفرة",
  "provision.tenant.management.DSTStartTime" : "وقت بدء التوقيت الصيفي",
  "provision.callcenterinstanceslist.table.nodemoderesource" : "وضع المورد",
  "ccprovision.IVRSprogress.config.button.delete" : "حذف",
  "provision.tenantspace.sip.port" : "منفذ خادم SIP",
  "provision.tenantspace.feature.VoiceNotificationSupport.description" : "توفر هذه الميزة وظائف الإعلام الصوتي لمساحة المستأجر.",
  "provision.tenantspace.config.worknoassign.add" : "إضافة",
  "provision.tenantspace.gw.serverIP" : "عنوان IP لخادم العبّارة",
  "provision.tenantspace.config.datasource" : "مصدر بيانات OBS",
  "provision.callcent.error.accesscode.deletefail.ondatabase" : "فشل حذف رمز الوصول في قاعدة البيانات",
  "provision.label.tenantexport.inputzippwd" : "أدخل كلمة مرور الضغط.",
  "provision.tenantspace.config.softphoneassign" : "تخصيص رقم الهاتف",
  "provision.tenantspace.create.account" : "معلومات الحساب",
  "provision.subscribedetail.button.update" : "تحرير",
  "provision.tenantspace.save.oldsuccess" : "لم يتم تعديل معلومات المنطقة الزمنية.",
  "chat.timerange.label.oneday" : "آخر يوم",
  "provision.tenant.management.tenantcreate.mobileillegal" : "رقم الجوال غير صالح.",
  "provision.tenantspace.chooseCC" : "تحديد مركز اتصالات",
  "chat.agent.title.close" : "لإيقاف التشغيل",
  "provision.tenantspace.timezone" : "المنطقة الزمنية الافتراضية",
  "provision.spinfo.selecdeletetspinfo" : "يرجى تحديد معلومات SP المراد حذفها.",
  "provision.tenantspace.selectStastus.config" : "ليتم تكوينه",
  "provision.appsitesmanagement.details" : "تفاصيل الموقع",
  "provision.tenantspace.ivr.ivrServer" : "عنوان IP",
  "provision.callcent.error.savevccfirst" : "الموظف غير مرتبط بخادم التسجيل. احفظ VDN مرة أخرى.",
  "provision.callcenterinstanceslist.softPhone.accountpassword" : "كلمة مرور الحساب",
  "provision.tenantspace.feature.multiChannelSupport.description" : "تم تمكين ميزة دعم القنوات المتعددة. يمكن إجراء ضبط مؤقت لقائمة الانتظار للموظفين عبر قنوات الخدمة. ملاحظة: إذا كان لدى الموظف إذن قناة الخدمة المشتركة\، فسيشغل الموظف موارد الصوت\ والفيديو\ والوسائط المتعددة في نفس الوقت أثناء تسجيل الدخول. الوكيل تحت المستأجر قد لا يقوم بتسجيل الدخول لخدمة العملاء بسبب استباق الموارد.",
  "provision.callcenterinstanceslist.label.videoCallnumUseRate" : "الاستخدام المتزامن لمكالمة الفيديو",
  "chat.agent.label.customerSource" : "المصدر: تطبيق الجوال - المركز الشخصي",
  "provision.systemparam.error.validate.inner" : "فشل التحقق من المعلمة",
  "provision.callcent.selectsipserver" : "حدد خادم تسجيل موظف.",
  "provision.tenantspace.config.maxVideoCalls" : "كمية مكالمة الفيديو المتزامنة",
  "chat.session.title.history" : "سجلات الدردشة التاريخية",
  "provision.subscribedetail.button.save" : "حفظ",
  "provision.callcent.error.deleteucsserver.part.associationvcallcener" : "ترتبط بعض ملقمات CCUCS المحددة بالمستأجرين ولا يمكن حذفها.",
  "provision.callcenterinstanceslist.config.updatefailed" : "فشل تعديل مركز المكالمات.",
  "chat.skill.message.createFail" : "فشل إنشاء المهارة.",
  "provision.subscribedetail.error.query.maxDay_30" : "لا يمكن أن يكون الفاصل الزمني للاستعلام أطول من 30 يومًا.",
  "provision.tenantspace.timezone.updateconfim" : "تأكيد",
  "provision.sysparam.config.itemdesc" : "الوصف",
  "provision.callcenterinstanceslist.softPhone.tenantname" : "اسم المستأجر",
  "provision.tenantspace.table.deleteTenantSpace" : "هل تريد بالتأكيد حذف مساحة المستأجر المحددة؟",
  "provision.callcenterinstanceslist.vcallcenter.invalidVdnListPromt" : "توجد شبكات VDN في WAS حيث يكون مركز الاتصال الظاهري مفقودًا في AICC. VdnID هو",
  "provision.tenantspace.feature.intelligent.inspection.description" : "قم بتمكين ميزة الفحص الذكي وتمكين وظيفة الفحص الذكي لمساحة المستأجر.",
  "provision.tenantspace.step7" : "الخطوة السابعة",
  "provision.tenantspace.step6" : "الخطوة السادسة",
  "provision.tenantspace.step5" : "الخطوة 5",
  "provision.tenantspace.step4" : "الخطوة الرابعة",
  "provision.tenantspace.step3" : "الخطوة الثالثة",
  "chat.client.transfer.link" : "التحويل إلى الخدمة اليدوية؟",
  "provision.callcenterinstanceslist.obsServer.obsServerIpSync" : "مزامنة",
  "provision.tenantspace.cc.videoAgentchannels" : "كمية قناة وكيل الفيديو",
  "provision.tenantspace.step2" : "الخطوة 2",
  "provision.tenant.management.tenantcreate.contact" : "طريقة الاتصال",
  "provision.tenantspace.step1" : "الخطوة 1",
  "provision.tenant.chooseCC.first" : "حدد مركز اتصالات.",
  "provision.tenantspace.cc.ttschannelnums" : "TTS الكمية",
  "provision.tenantspace.config.select.default.whiteNumber" : "لا يمكن أن يكون مخزن الأرقام المدرجة في القائمة الموثوقة أكبر من 5 بشكل افتراضي.",
  "provision.tenantspace.cc.percent" : "نسبة السعة",
  "provision.whiteListAddress.resourcedump.obs" : "تفريغ الموارد خادم OBS",
  "provision.callcenterinstanceslist.config.updatesuccess" : "تم تعديل مركز المكالمات بنجاح.",
  "provision.appsitesmanagement.enable" : "نعم",
  "provision.callcent.error.saveucsserveripinfo" : "فشل الإنشاء\، تأكد من عدم وجود عنوان IP الخاص بـ CCUCS ورقم منفذ الخادم.",
  "provision.callcenterinstanceslist.vcallcenterDeatil.passLengthValidateFailed" : "تحتوي كلمة المرور على ثمانية أحرف كحد أدنى.",
  "provision.callcenterinstanceslist.obsServer.activeObsServerIp" : "نشط Obs خادم Ip",
  "provision.tenantspace.feature.emailAgentSendLocalAttachment" : "إرسال المرفقات المحلية عن طريق وكلاء البريد الإلكتروني",
  "chat.skill.label.agents" : "وكلاء",
  "provision.tenantspace.deleteWhiteNumber.confirm" : "تأكيد الحذف",
  "provision.callcenterinstanceslist.vcallcenter.InProgress" : "قيد التقدم.",
  "provision.tenantspace.ucs.name" : "اسم خادم CCUCS",
  "provision.callcenterinstanceslist.table.create" : "جديد",
  "provision.tenantspace.config.gw" : "خادم العبّارة",
  "provision.tenantspace.detail.tenantspace.status.not.change" : "المستأجر في الحالة غير النشطة ولا يمكن تعديله.",
  "provision.tenantspace.modifyTimeZoneVerification" : "يمكن تعديل معلومات المنطقة الزمنية فقط لمساحة المستأجر في الحالة التجارية أو التجريبية.",
  "provision.systemparam.algorithm.unsafe" : "يتم استخدام خوارزمية غير آمنة ، مما يشكل مخاطر أمنية",
  "provision.tenantspace.feature.emailAgentForwarding" : "إعادة توجيه وكيل البريد",
  "provision.encryptinfo.modedecrease" : "وضع CBC أقل أمانًا من وضع GCM. توخي الحذر عند تحديد وضع CBC.",
  "provision.callcenterinstanceslist.label.amsCallNumUseRate" : "استخدام المكالمات المجهولة المتزامنة",
  "provision.vcallcenter.message.unknowdesc" : "استثناء غير معروف.",
  "provision.callcent.error.createaccesscode.validate.servicenumberContainServicenumber" : "لا يمكن أن يكون لرقم الخدمة علاقة تضمين مع أرقام خدمة أخرى ضمن مركز الاتصالات.",
  "provision.tenantspace.table.asrchannelnums" : "ASR الكمية",
  "provision.callcenterinstanceslist.vcallcenterDeatil.maxCalls" : "الحد الأقصى لعدد المكالمات المتزامنة",
  "provision.sysparam.config.item.detail" : "معلومات المعلمة",
  "provision.label.tenantexport.exception" : "حدث استثناء أثناء إنشاء مهمة التصدير. يرجى التحقق من السجل.",
  "provision.recorddrive.label.isfirstletter.no" : "لا",
  "provision.ivr.selectivrprogress.singledeleteconfim" : "هل تريد بالتأكيد حذف عملية IVR المحددة؟",
  "chat.weChatConfig.message.codeNameExisted" : "رمز الوصول إلى القناة موجود بالفعل.",
  "provision.callcenterinstanceslist.vcallcenter.vdnName" : "اسم VDN",
  "provision.callcenterinstanceslist.vcallcenter.accessCodeConfig" : "تكوين AccessCode",
  "provision.tenantspace.option.weekday_fri" : "الجمعة",
  "provision.callcenterinstanceslist.vcallcenter.UCSServer.title" : "تكوين خادم CCUCS",
  "chat.session.label.servedAgent" : "موظف الخدمة",
  "provision.callcenterinstanceslist.vcallcenter.maxMediaAgents" : "MaxMediaAgents",
  "provision.callcenterinstanceslist.config.title" : "تهيئة مركز الاتصال",
  "provision.tenantspace.table.maxAmsCalls" : "الحد الأقصى للمكالمات المجهولة المتزامنة",
  "provision.tenantspace.agentusage.plus.videousage.plus.maxVersatileAgents" : "لا يمكن أن يتجاوز العدد الإجمالي للموظفين الصوتيين\، والفيديو\، والموظفين متعددي الاستخدامات 10000.",
  "provision.callcenter.videoapplicationmodel.sfumodel" : "SFU الفيديو",
  "promotion.tenant.management.tenantedit.vcc.notexist" : "مركز المكالمات الظاهري غير موجود.",
  "provision.tenant.management.agentnum" : "وكلاء",
  "provision.callcent.error.updatesipserver.fail.syncccmanagement" : "فشلت مزامنة خادم SBC لتعديل المعلومات إلى غرفة التأجير.",
  "provision.tenantspace.accesscode.searchName" : "يُرجى إدخال رمز الوصول ليتم الاستعلام عنه.",
  "provision.callcenterinstanceslist.obsServer.config.explain" : "يجب أن تكون القيمة مماثلة لتكوين OBS على النظام الأساسي CTI.",
  "chat.skill.label.edit" : "التحرير",
  "provision.tenantspace.feature.speechAnalysis" : "تحليل الكلام",
  "provision.tenantspace.feature.open" : "تمكين",
  "provision.systemparam.algorithm.suggest" : "تحذير: كن متسقًا مع UAP",
  "provision.tenantspace.feature.appcube.close.error" : "قبل تعطيل ميزة AppCube\، قم بتعطيل الميزات الأساسية {0}.",
  "chat.skill.label.save" : "تم الحفظ",
  "provision.callcent.error.deleteccdis.associationvcallcener" : "ملقم CCDIS المحدد مقترن بالمستأجر ولا يمكن حذفه.",
  "provision.tenantspace.feature.close" : "تعطيل",
  "provision.tenantspace.common.moveagent.explain" : "يخصص النظام رقم هاتف محمول للمستأجر. يستخدم نظام المستأجر رقم الهاتف المحمول للتسجيل في خادم نظام AICC. يستحضر نظام المستأجر الواجهة المفتوحة التي يوفرها نظام AICC لتنفيذ وظيفة رد الاتصال ثنائي الاتجاه. (الحد الأقصى لعدد وكلاء المحمول القابلين للتخصيص = كمية قناة إيفر الصوتية / 2 \ ، تقريبًا لأسفل).",
  "provision.callcent.error.savesipserverdesinfo.regAgentNumError" : "عدد الوكلاء المسجلين المتزامنين المعدلين أقل من العدد الفعلي للمقاعد المسجلة.",
  "provision.tenantspace.config.select.record.time" : "يرجى تحديد فترة الاحتفاظ بالتسجيل.",
  "provision.tenantspace.table.invalidTenantSpace.confirm" : "تأكيد الإلغاء",
  "provision.ccp.redis.feature.success" : "تم تنفيذ بيانات ميزة المستأجر بنجاح.",
  "chat.weChatConfig.stepname.selectmode" : "تحديد أسلوب تكوين",
  "provision.callcenterinstanceslist.vcallcenter.maxAgents" : "MaxAgents",
  "provision.callcenterinstanceslist.table.ccName" : "اسم مركز الاتصال",
  "provision.callcent.error.updateccgateway.regAgentNumError" : "فشل التحديث، عدد المقاعد المسجلة التي تم تعديلها أقل من العدد الفعلي للمقاعد المخصصة.",
  "provision.tenantspace.config.maxVideoAgents" : "كمية وكيل الفيديو",
  "provision.tenantspace.save.fail.120006" : "الحد الأقصى لعدد الخيوط.",
  "provision.tenantspace.save.fail.120005" : "فشل التحقق من عنصر التكوين.",
  "provision.tenantspace.save.fail.120004" : "فشلت العملية لأن عدد شبكات VDN الممكّنة وصل إلى الحد الأقصى.",
  "provision.tenantspace.save.fail.120003" : "التكوين غير موجود.",
  "provision.tenantspace.save.fail.120002" : "التكوين موجود بالفعل.",
  "provision.tenantspace.save.fail.120001" : "الحد الأقصى لعدد الدفعات هو 500.",
  "provision.tenantspace.save.fail.120000" : "خطأ في الواجهة.",
  "provision.tenantspace.feature.dualCallTransparentDisplaySupport" : "إخفاء هوية الرقم المتصل",
  "ccp.version.microservice" : "مايكروسرفيس",
  "provision.tenantspace.common.customercenter.explain" : "تفعيل ميزة مركز العملاء، وتوفير إدارة العملاء المحلية للمستأجرين، وتسجيل المعلومات التاريخية لقنوات الاتصال المختلفة لنفس العميل",
  "provision.tenantspace.selectStastus.invalid" : "معطل",
  "chat.agent.label.money" : "المبلغ",
  "provision.systemparam.error.validate.notmatchrule" : "معلمة الإدخال لا تتطابق مع قاعدة التحقق",
  "provision.tenantspace.feature.multiChannelSupport" : "دعم متعدد القنوات",
  "ccp.version.updateTime" : "UpdateTime",
  "provision.callcenterinstanceslist.sipServer.tenantname" : "اسم المستأجر",
  "provision.callcent.packagefile.updateinfo" : "تم التحديث بنجاح. تتم معالجة حزمة الإصدار. انتظر حتى تقوم الصفحة بتحديث نتيجة معالجة حزمة الإصدار.",
  "provision.callcenterinstanceslist.obsServer.standbyObsServerIp" : "الاستعداد Obs خادم IP",
  "chat.webconfig.message.saveSuccess" : "تم حفظ التكوين بنجاح.",
  "provision.tenantspace.error.deleteConfigTenantSpace" : "تم تخصيص الموارد لمساحة المستأجر. لا يمكنك حذف مساحة المستأجر.",
  "chat.usefulexpressions.label.createExpressionTab" : "إنشاء عبارات مشتركة",
  "chat.weChatConfig.label.configId" : "معرف التكوين",
  "provision.spinfo.deletespinfosuccess" : "تم حذف معلومات SP بنجاح.",
  "provision.spinfo.validatehttp" : "تنسيق URL غير صحيح.",
  "provision.tenantspace.agentinfo.message.batchdeleteconfim" : "هل تريد بالتأكيد حذف كافة الموظفين المحددين؟",
  "provision.callcenterinstanceslist.config.warning" : "تحذير",
  "provision.tenantspace.modifyPassword" : "إعادة تعيين كلمة المرور",
  "provision.callcent.error.whiteaddrduplicate" : "عنوان مكرر في قائمة الثقة.",
  "provision.ccworknos.table.ccworkQuantity" : "عدد الكمية في عدد المقطع",
  "provision.tenantspace.obsServer.datasource.type" : "نوع مصدر البيانات",
  "provision.tenantspace.config.intelligent.agentNumber.not.exceed.agentNumber" : "لا يمكن أن يكون عدد الموظفين الذكيين المخصصين لمستأجر أكبر من إجمالي عدد الموظفين.",
  "provision.ccp.redis.packagefile.inprogress" : "يتم تحميل حزمة الإصدار.",
  "provision.callcent.error.specialStrValidateFailed" : "لا يمكن أن يحتوي اسم obs على أحرف خاصة.",
  "provision.callcent.platformserver.kafka" : "رسالة الوسيطة (Kafka)",
  "provision.tenantspace.feature.emailMarketingSupport.description" : "توفر هذه الميزة وظائف التسويق عبر البريد الإلكتروني لمساحة المستأجر.",
  "provision.tenantspace.config.param.error.ivrNumber" : "الرجاء إدخال عدد قنوات IVR.",
  "provision.callcenterinstanceslist.table.gwPort" : "منفذ عبّارة CC",
  "chat.skill.label.createSkillTab" : "إنشاء مهارة",
  "provision.tenantspace.selectvideomodel.isnull" : "حدد وضع تطبيق فيديو لمورد الفيديو الذي يطلبه المستأجر.",
  "provision.callcent.error.updateccgateway.ccgatewayServernoexist" : "فشل التحديث\، خادم عبّارة CC غير موجود.",
  "provision.tenantspace.feature.forceVideoSelfRecord.settingError" : "تم تكوين معلمات تسجيل الشاشة بشكل غير صحيح.",
  "provision.tenant.management.positiveInteger.checkmsg" : "أدخل عددًا صحيحًا موجبًا.",
  "chat.common.message.warning" : "تحذيرات",
  "provision.tenantspace.agentinfo.message.versatile" : "متعددة الاستخدامات",
  "provision.callcenterinstanceslist.table.maxAmsCallNums" : "الحد الأقصى للمكالمات المجهولة المتزامنة",
  "provision.tenantspace.worknoValidatefaild" : "WorkNo إلزامي.",
  "provision.tenantspace.search" : "استعلام",
  "provision.accesscode.table.accessCode.clicktodial" : "انقر للاتصال",
  "provision.tenantspace.option.weeklist_fth" : "رابعاً",
  "provision.callcenterinstanceslist.config.videoAgentCurCapacityValidate" : "عدد مقاعد الفيديو أقل من عدد مقاعد الفيديو المخصصة بالفعل في مركز الاتصال.",
  "provision.callcent.error.updatesipserver.ondatabase" : "فشل تعديل خادم SBC في قاعدة البيانات.",
  "provision.callcenterinstanceslist.vcallcenter.plateformServer" : "خادم النظام الأساسي",
  "provision.appversionmanagement.cannotContainSpecialCharacters" : "لا يمكن أن تحتوي القيمة على الأحرف الخاصة التالية: ~!{'@'}#$%&*()/ \\_= +{}:'\<>؟ []",
  "provision.callcent.error.accesscode.deletefail" : "فشل الحذف\، الإدخال فارغ",
  "provision.accesscode.table.tenantname" : "المستأجر",
  "provision.tenantspace.accessCode.choose" : "حدد مجموعة من رموز الوصول.",
  "chat.skill.message.completeInputs" : "يرجى إكمال جميع الإدخالات.",
  "provision.tenantspace.cc.videoAgentchannelUsagePercent" : "استخدام قناة وكيل الفيديو",
  "provision.subscribedetail.enableStatus.value_1" : "تم الاشتراك بنجاح",
  "provision.callcent.error.deletesipserver.associationvcallcener" : "يرتبط خادم SIP المحدد بالمستأجر ولا يمكن حذفه.",
  "provision.subscribedetail.enableStatus.value_0" : "فشل الاشتراك",
  "provision.appversion.msg.throttling.fail" : "قم بإنشاء أو تعديل حد حركة مرور الواجهة كل دقيقتين. حاول مرة أخرى لاحقًا.",
  "chat.client.label.privacyHintContent" : "لتحسين جودة الخدمة\، سيتم تسجيل محادثتك بواسطة النظام. هل تريد المتابعة؟",
  "provision.callcenterinstanceslist.obsServer.batchdeleteconfim" : "هل تريد بالتأكيد حذف كافة ملقمات obs للتسجيل المحددة؟",
  "provision.callcenterinstanceslist.config.alreadyinuse" : "تم استخدام مقطع معرف الموظف.",
  "provision.appversion.msg.process.modify" : "تعديل إصدار التطبيق",
  "provision.label.tenantexport.failed" : "فشل",
  "provision.callcent.platformserver.account" : "حساب الخدمة",
  "chat.agent.title.userMenu" : "قائمة العملاء",
  "provision.callcent.error.paramValidateFailed" : "فشلت الإضافة. يجب أن تتكون قيم obsId\ وprogId\ و heartbeatPort من جميع الأرقام.",
  "provision.common.message.error" : "أخطاء",
  "provision.callcent.error.partSetinfo" : "فشل تحديد مجموعة الأجزاء.",
  "chat.client.label.evaluation" : "استبيان الرضا",
  "provision.tenant.management.batchcreate" : "يتم إنشاء المساحات المستأجرة على دفعات.",
  "provision.callcenterinstanceslist.sipServer.sipServerIpUpdate" : "تكوين خادم SIP",
  "provision.tenantspace.obs.name" : "اسم خادم OBS",
  "provision.tenantspace.selectStastus.CommercialUse" : "الاستخدام التجاري",
  "provision.callcenterinstanceslist.sipServer.webRTC" : "WebRTC",
  "provision.tenant.management.select.accesscode" : "تحديد رمز الوصول",
  "provision.callcent.selectamsserver.inconsistenttype" : "نوع خادم المكالمة المجهولة غير متناسق",
  "provision.callcenterinstanceslist.vcallcenter.maxVideoCalls" : "MaxVideoCalls",
  "chat.timerange.label.alldays" : "كل وقت الإنشاء",
  "provision.tenantspace.common.agent.number" : "كمية الوكيل الذكي",
  "provision.spinfo.modifyspinfofailed" : "فشل تعديل معلومات SP.",
  "provision.callcenterinstanceslist.vcallcenter.maxVideoAgents" : "MaxVideoAgents",
  "provision.whitepopuppageurl.protocol.check" : "لاحظ أن بروتوكول الشبكة الذي يستخدمه العنوان المنبثق ليس بروتوكولًا آمنًا. إذا قررت استخدام هذا البروتوكول، فقد تكون هناك مخاطر أمنية.",
  "provision.callcenterinstanceslist.vcallcenterDeatil.wasPsdLengthRangeValidateFailed" : "يجب أن يتراوح طول كلمة المرور بين 16 و32",
  "provision.subscribedetail.validate.laterThanEndTime" : "لا يمكن أن يكون وقت البدء بعد وقت الانتهاء.",
  "provision.tenantspace.config.softphone.Validatefaild" : "رقم الهاتف غير المخصص",
  "provision.callcenter.videoapplicationmodel" : "وضع تطبيق الفيديو",
  "provision.callcent.error.savesipserveripinfo" : ". تأكد من عدم وجود عنوان IP ورقم منفذ الخادم.",
  "provision.tenantspace.cc.callnumUsagePercent" : "استخدام المكالمات الصوتية المتزامنة",
  "provision.tenantspace.feature.forceVideoSelfRecord.description" : "تشير إلى ما إذا كان سيتم تسجيل حالة تشغيل الشاشة تلقائيًا عند قيام الموظف بمعالجة مكالمة.",
  "provision.sysparam.config.select.prompt" : "تلميحات",
  "provision.tenantspace.detail.expiry.time" : "وقت انتهاء الصلاحية",
  "provision.tenantspace.table.activeTenantSpace.confirm" : "تأكيد التنشيط",
  "provision.tenant.management.tenantcreate" : "المستأجر الجديد",
  "provision.callcentaccesscodes.table.callcentaccesscodes" : "رمز الوصول",
  "provision.tenantspace.tenantid" : "معرف الإيجار الحالي.",
  "provision.tenantspace.resetpwdfaild.accountpassword.error" : "كلمة مرور الحساب الحالية غير صحيحة.",
  "provision.callcenterinstanceslist.config.agentCurCapacityValidate" : "عدد وكلاء الصوت أقل من عدد المقاعد الصوتية التي تم تعيينها بالفعل بواسطة مركز الاتصال.",
  "chat.webconfig.label.transHuman" : "التحويل إلى خدمة العملاء اليدوية",
  "provision.callcenterinstanceslist.vcallcenterDeatil.saveerror" : "فشل الحفظ.",
  "provision.tenantspace.customerAndmobileAgent.conflict" : "لا يمكن تمكين ميزة موظف الجوال وميزة الاتصال بشخص في نفس الوقت.",
  "provision.callcent.selectaccesscode" : "حدد رمز وصول.",
  "provision.ccworknos.table.virtualcallcenterNumber" : "معرف VCC",
  "provision.callcenterinstanceslist.config.odfsconfig" : "تكوين خادم ODFS",
  "provision.callcent.error.deleteucsserver.associationvcallcener" : "ملقم CCUCS المحدد مقترن بالمستأجر ولا يمكن حذفه.",
  "provision.subscribedetail.delete.confirm" : "هل تريد بالتأكيد حذف تفاصيل الاشتراك؟",
  "provision.tenantspace.save.fail.120663" : "تمت الإشارة إلى التكوين المطلوب بواسطة الموظف ولا يمكن حذفه.",
  "provision.appversionmanagement.sureDeleteData" : "هل تريد بالتأكيد حذف البيانات؟",
  "provision.tenantspace.save.fail.120662" : "التكوين المطلوب مشار إليه بواسطة قائمة انتظار المهارة ولا يمكن حذفه.",
  "provision.tenantspace.save.fail.120661" : "يتم الرجوع إلى التكوين المطلوب بواسطة قائمة انتظار المهارات الخاصة الافتراضية ولا يمكن حذفه.",
  "provision.tenantspace.save.fail.120660" : "رمز الوصول إلى النظام الذي تم تكوينه بواسطة الطرف المطلوب غير متوافق مع نوع وسائط جهاز التكوين المطلوب.",
  "chat.skill.label.delete" : "الحذف",
  "ccprovision.recorddrive.config.sftp.accountconfirmpasd" : "SFTP Server تأكيد كلمة المرور",
  "chat.weChatConfig.label.AppSecret" : "كلمة مرور المطور",
  "provision.tenantspace.feature.smsOrEmailMarketingSupport.sendMaxNumber.alert" : "يتراوح الحد الأقصى لعدد الرسائل التي يمكن إرسالها كل يوم من 1 إلى 1000000000. تشير القيمة 0 إلى أنه لم يتم تحديد أي حد.",
  "provision.subscribedetail.label.companySize" : "حجم الشركة",
  "provision.tenantspace.gw.ServerIP" : "عنوان IP لخادم العبّارة",
  "provision.tenantspace.feature.agenttype" : "نوع الوكيل",
  "provision.tenant.management.timezone" : "المنطقة الزمنية",
  "provision.tenantspace.save.fail.120659" : "البرنامج النصي للتوجيه المشار إليه بواسطة التكوين المطلوب غير موجود.",
  "provision.callcenterinstanceslist.vcallcenter.SIPServer.title" : "تكوين خادم SBC",
  "provision.callcenterinstanceslist.config.ccIdUpdateError" : "لا تقم بتغيير معرف مركز الاتصالات عند تحرير مركز الاتصالات",
  "provision.tenantspace.save.fail.120658" : "نوع الجهاز هو IVR\، ويمكن فقط النقر فوق نوع وسائط رمز الوصول للاتصال\، وطلب معاودة الاتصال\، ومكالمة عادية.",
  "provision.encryptinfo.version" : "الإصدار",
  "provision.tenantspace.save.fail.120656" : "قائمة انتظار المهارة المشار إليها بواسطة التكوين المطلوب غير موجودة.",
  "provision.tenantspace.save.fail.120655" : "رمز الوصول المشار إليه بواسطة التكوين المطلوب غير موجود.",
  "provision.appversionmanagement.pleaseEnter" : "-أدخل",
  "provision.tenantspace.config.chooseworkNo" : "اختر WorkNo",
  "provision.tenantspace.intelligent.ivr.explain" : "تقنية IVR الذكية: بالإضافة إلى وظيفة IVR التقليدية، تدعم تقنية الملاحة IVR الذكية التعرف على الكلام والفهم الدلالي.",
  "provision.tenantspace.save.fail.120652" : "لا يمكن تكرار تركيبة رمز الوصول إلى النظام ورموز الوصول اللاحقة التي تم تكوينها بواسطة الطرف المطلوب.",
  "provision.tenantspace.save.fail.120651" : "لم يتم تعيين رمز الوصول إلى النظام الذي تم تكوينه بواسطة الطرف المطلوب.",
  "provision.tenantspace.feature.ivrtype" : "نوع IVR",
  "provision.subscribedetail.success.title" : "النجاح",
  "provision.callcenterinstanceslist.table.hasaccesscodeAndivrConfim" : "فشل الحذف. يوجد رمز وصول أو IVR في مركز الاتصالات. تحتاج إلى حذفها يدويا.",
  "chat.session.endType.overtimeEnd" : "انتهاء المهلة",
  "provision.callcenterinstanceslist.obsServer.obsServerIpAdd" : "إضافة",
  "provision.callcent.error.updateaccesscode.fail.accesscodenoexist" : "\, تحقق مما إذا كان رمز الوصول موجودًا ومقترنًا بمركز اتصالات ظاهري.",
  "chat.skill.label.description" : "الوصف",
  "provision.callcent.error.savesipserverdesinfo.maxRegAgentNumError" : ". عدد الوكلاء المسجلين المتزامنين المعدلين أقل من العدد الفعلي للمقاعد المسجلة.",
  "provision.callcenterinstanceslist.table.ctinodesec" : "معلومات العقدة CTI 2",
  "provision.callcenterinstanceslist.callcenter" : "مركز الاتصال",
  "chat.skill.message.error" : "الأخطاء",
  "provision.grayconfig.tenantHasConfig" : "تم تكوين مساحة المستأجر",
  "provision.tenantspace.option.weekday_sat" : "السبت",
  "chat.weChatConfig.message.error" : "الأخطاء",
  "provision.tenant.management.tenantcreate.provision.failed" : "فشل إنشاء مساحة المستأجر المحلية.",
  "provision.callcent.error.addcallcenterdesinfo" : "تأكد من عدم وجود مركز الاتصال.",
  "provision.log.downloadIdpMetadtaXml" : "تحميل IDP البيانات الوصفية Xml",
  "chat.usefulexpressions.label.expression" : "عبارات شائعة",
  "provision.callcenterinstanceslist.vcallcenterDeatil.agentNum" : "عدد الوكلاء المعينين",
  "provision.subscribedetail.validate.beginwithalpha" : "يجب أن تبدأ مع رسالة.",
  "provision.tenantspace.Conversioncommercial" : "تحويل تجاري",
  "provision.callcenterinstanceslist.softPhone.passValidateFailed" : "يجب أن يحتوي على 3 أحرف كبيرة على الأقل\، أحرف صغيرة\، أرقام\، أحرف خاصة ~{'@'}^*_+.?.",
  "provision.callcenterinstanceslist.ucsServer.batchdeleteconfim" : "هل تريد بالتأكيد حذف كافة ملقمات CCUCS المحددة؟",
  "provision.tenantspace.config.giveSource" : "تخصيص الموارد",
  "provision.tenantspace.updateTenantSpaceStatus" : "هل تريد بالتأكيد تغيير حالة مساحة المستأجر؟",
  "provision.tenantspace.feature.cobrowse.description" : "توفر هذه الميزة وظيفة التصفح المشترك لمساحة المستأجر.",
  "provision.callcenter.videoapplicationmodel.mcumodel" : "MCU فيديو",
  "ccprovision.recorddrive.config.sftp.identitychecktips.placeholder" : "أدخل حسابك الحالي وكلمة المرور.",
  "provision.callcent.error.modifyParamUnique" : "نشط ObsServerIp موجود.",
  "provision.tenantspace.feature.customData.description" : "بعد تمكين ميزة تخصيص البيانات، تدعم مساحة المستأجر وظيفة تخصيص البيانات.",
  "provision.callcent.error.savesipserverdesinfo.sipServernoexist" : "خادم \, sbc غير موجود",
  "chat.usefulexpressions.message.isExisted" : "اسم العبارة العامة موجود بالفعل.",
  "provision.accesscode.table.callout" : "مكالمة صادرة",
  "chat.weChatConfig.message.appIdExisted" : "معرّف مطور الحساب الرسمي موجود بالفعل.",
  "provision.ivr.selectivrprogress.batchdeleteconfim" : "هل تريد بالتأكيد حذف جميع عمليات IVR المحددة؟",
  "provision.tenantspace.intelligent.agent.explain" : "الوكيل الذكي: بالإضافة إلى وظيفة التحكم الصوتي الأساسية ، فإنه يدعم تحويل الصوت إلى نص في الوقت الفعلي والتوصية الذكية ذات الصلة. لتكوين وكلاء ذكيين\، تحتاج أولاً إلى تكوين وكلاء الصوت وموارد ASR.",
  "provision.tenantspace.feature.case.open.confirmmsg" : "تعد ميزة AppCube أساس تشغيل ميزة Case وسيتم تمكينها في نفس الوقت.",
  "chat.agent.label.balance" : "الإيرادات والنفقات",
  "provision.sysparam.config.select.null" : "يرجى تحديد معلمة.",
  "chat.client.label.password" : "كلمة المرور",
  "provision.tenantspace.feature.whatsapp.cloudapi" : "اتصال واجهة برمجة تطبيقات سحابة WhatsApp",
  "provision.recorddrive.error.cannotdelete.occupyvdn" : "لا يمكن حذف حرف محرك الأقراص المعين إلى المستأجر",
  "chat.weChatConfig.label.verifying" : "التحقق",
  "provision.tenantspace.common.agent" : "وكيل عادي",
  "provision.tenantspace.save.fail.107-087" : "لم يتم تسجيل رقم UAP الذي تم إلغاء تسجيله.",
  "provision.tenantspace.table.batchcreate" : "إنشاء دفعة",
  "provision.tenantspace.save.fail.107-086" : "لم يتم تكوين بادئة مكالمة لرقم على UAP.",
  "provision.tenantspace.table.maxVersatileAgents" : "ماكس. وكلاء تنوعا",
  "provision.tenantspace.save.fail.107-085" : "لا يمكن تحديد نوع منتج UAP.",
  "provision.tenantspace.save.fail.107-084" : "فشل UAP في الحصول على نوع المنتج.",
  "provision.tenantspace.save.fail.107-083" : "فشل UAP في إرسال الأمر MML.",
  "provision.tenantspace.ucs.searchName" : "الرجاء إدخال اسم خادم CCUCS المراد الاستعلام عنه.",
  "ccprovision.vcallecenter.config.popup.curcountpassword" : "كلمة مرور الحساب الجاري",
  "provision.tenantspace.save.fail.107-082" : "فشل تسجيل الدخول إلى UAP أو تم قطع الاتصال عن UAP.",
  "provision.appversionmanagement.protocol.check" : "لاحظ أن بروتوكول الشبكة الذي يستخدمه عنوان التنزيل المدخل غير آمن. إذا كنت متأكدًا من استخدامه، فقد تكون هناك مخاطر أمنية.",
  "provision.callcent.platformserver.identitychecktips" : "لقد قمت بتغيير كلمة مرور الخادم. لضمان أمان المعلومات\، الرجاء إدخال حسابك الحالي وكلمة مرورك.",
  "provision.tenantspace.save.fail.107-081" : "يفشل UAP في التحقق من إذن VDN لأن VDN لا يحتوي على رقم المستخدم.",
  "provision.tenantspace.save.fail.107-080" : "يتجاوز عدد أرقام UAP الحد.",
  "provision.grayconfig.tenantSpaceId" : "tenantSpaceId",
  "provision.tenantspace.config.choose.agent.registration.server" : "خادم تسجيل الموظف",
  "provision.callcenterinstanceslist.table.ctinodefir" : "معلومات عقدة CTI",
  "provision.tenantspace.feature.ivr.analysis" : "تحليل IVR",
  "provision.tenantspace.table.delete" : "حذف",
  "chat.client.message.gladtoServe" : "إنه لمن دواعي سروري أن أخدمك!",
  "provision.tenantspace.resetpwdfaild.newpassword.not.meet.passwordrule" : "لا تتوافق كلمة المرور الجديدة مع قاعدة كلمة المرور.",
  "provision.tenantspace.save.fail.120624" : "كلمة مرور إرساء vdn المعدلة هي نفس كلمة المرور القديمة.",
  "provision.systemparam.error.validate.notmatchrulenumber" : "الرجاء إدخال رقم",
  "provision.tenantspace.save.fail.120622" : "ممثل الأعمال هو قائد الفريق ولا يمكنه إجراء عمليات تغيير المجموعة.",
  "provision.callcenterinstanceslist.ccdis.ccdisId" : "معرف الهوية",
  "provision.appversion.msg.uploadfile.2.fail" : "فشل التحقق من تنسيق الملف.",
  "provision.tenantspace.save.fail.120621" : "يجب أن تحتوي كلمة المرور على ثلاثة أحرف على الأقل من الأحرف الأربعة التالية: حروف إنجليزية كبيرة، وحروف إنجليزية صغيرة، وأرقام، وأحرف غير أبجدية رقمية، ولا يمكن أن تكون مطابقة لرقم الحساب أو الترتيب العكسي لرقم الحساب.",
  "provision.systemparam.error.validate.notmatchruleoption" : "معلمة الإدخال ليست في نطاق التعداد",
  "provision.tenantspace.save.fail.120620" : "طول كلمة مرور الموظف أقل من الحد الأدنى لطول كلمة المرور لتكوين الأمان: {0}.",
  "provision.label.tenantexport.addExportTask" : "إضافة مهمة تصدير",
  "promotion.tenant.management.tenantedit.vdncreate.failed" : "فشل إنشاء معلومات VDN أو تعديلها.",
  "provision.callcent.error.portoutsiderange" : "يقع HeartbeatPort خارج النطاق من 1 إلى 65535.",
  "provision.tenantspace.save.fail.107-079" : "رقم نهاية UAP أصغر من رقم البداية.",
  "provision.tenantspace.save.fail.107-078" : "طول رقم البداية ورقم النهاية على UAP غير متناسق.",
  "chat.weChatConfig.label.appId" : "معرف المطور.",
  "provision.tenantspace.dataUpload.selectType" : "حدد نوع خدمة تفريغ الموارد.",
  "provision.tenantspace.save.fail.107-077" : "نوع عنوان IP UAP غير مدعوم.",
  "provision.tenantspace.save.fail.107-076" : "كلمة مرور UAP غير متناسقة مع تأكيد كلمة المرور.",
  "provision.tenantspace.save.fail.107-075" : "طول كلمة مرور UAP خارج النطاق.",
  "provision.tenantspace.save.fail.107-074" : "كلمة مرور UAP هي nul",
  "provision.tenantspace.feature.emailAgentCCAndBcc" : "وكيل البريد الإلكتروني CC Bcc",
  "provision.tenantspace.save.fail.107-073" : "عنوان IP الخاص بـ UAP خالٍ.",
  "provision.tenantspace.option.weeklist_sec" : "ثانياً",
  "provision.tenantspace.save.fail.107-072" : "رقم مستخدم UAP غير صالح.",
  "provision.tenantspace.save.fail.107-071" : "رقم مستخدم UAP خالٍ.",
  "provision.tenantspace.save.fail.107-070" : "فشل إلغاء تسجيل UAP للنظام.",
  "provision.callcenterinstanceslist.ccdis.ccdisIP" : "عنوان IP الأساسي",
  "chat.thirdportal.title" : "بوابة العملاء على الويب",
  "provision.tenantspace.save.fail.120619" : "لا يمكن تكرار معرف ممثل المبيعات.",
  "chat.webconfig.title" : "تمكين قناة الويب",
  "chat.agent.label.goAway" : "للمغادرة",
  "provision.tenantspace.save.fail.120618" : "يتم تكرار رقم وظيفة ممثل الأعمال ورقم وظيفة قائد الفرقة.",
  "provision.tenantspace.save.fail.120617" : "حرف محرك الأقراص المشار إليه من قِبل الموظف غير موجود.",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.required" : "أدخل مقطع معرف الموظف المراد إضافته وانقر فوق موافق.",
  "chat.weChatConfig.label.tenantId" : "معرف المستأجر",
  "chat.agent.label.total" : "المجموع",
  "provision.tenantspace.save.fail.120616" : "التكوين المطلوب المشار إليه بواسطة الموظف غير موجود.",
  "provision.tenantspace.save.fail.120615" : "العملية المشار إليها من قبل الموظف غير موجودة.",
  "provision.tenantspace.save.fail.120614" : "ممثل الأعمال الآخر الذي استشهد به ممثل الأعمال غير موجود.",
  "provision.grayconfig.tenantSpaceName" : "المساحة المستأجرةName",
  "provision.tenantspace.config.asrchannelnums" : "ASR الكمية",
  "provision.tenantspace.save.fail.120613" : "دور الموظف المشار إليه من قِبل الموظف غير موجود.",
  "provision.tenantspace.common.mobileAppH5.explain" : "تمكين ميزات MobileApp H5 ، يمكن للمستأجرين تثبيت وظائف وكيل المحمول ذات الصلة تلقائيا على appcube ، ودعم الوصول من خلال الهواتف المحمولة",
  "provision.tenantspace.save.fail.120612" : "المجموعة المختصة المشار إليها من قبل الموظف غير موجودة.",
  "provision.tenantspace.feature.moveAgents" : "وكيل المحمول",
  "provision.tenantspace.save.fail.120611" : "قائمة انتظار المهارة المشار إليها من قبل الموظف غير موجودة.",
  "provision.tenantspace.save.fail.120972" : "نوع الوسائط الفرعية المشار إليها بواسطة قائمة انتظار المهارة غير موجود.",
  "provision.tenantspace.save.fail.120610" : "فريق تطبيق الموظف غير موجود.",
  "chat.agentConsole.message.content.image" : "[رسالة مصورة]",
  "provision.callcenterinstanceslist.vcallcenterDeatil.password" : "قم بتكوين كلمة مرور المسؤول.",
  "provision.tenantspace.save.fail.120971" : "لا يمكن حذف مرجع المجموعة المختصة لقائمة انتظار المهارات\.",
  "provision.callcenterinstanceslist.config.differentPassword" : "كلمتا المرور غير متناسقتين.",
  "provision.callcenterinstanceslist.config.error" : "خطأ",
  "provision.tenantspace.save.fail.120970" : "تتم الإشارة إلى قائمة انتظار المهارات بواسطة الجهاز المشترك ولا يمكن حذفها.",
  "provision.appversionmanagement.forceUpdate" : "التحديث القسري",
  "chat.weChatConfig.label.save" : "تم الحفظ",
  "provision.tenantspace.save.fail.107-069" : "فشل UAP في الاستعلام عن حالة التسجيل.",
  "provision.tenantspace.save.fail.107-068" : "فشل حذف مستخدم الوسائط المتعددة UAP.",
  "provision.tenantspace.save.fail.107-067" : "يفشل مستخدم الوسائط المتعددة UAP في تغيير كلمة المرور.",
  "provision.tenantspace.save.fail.107-066" : "استثناء تكوين UAP",
  "provision.tenantspace.save.fail.107-065" : "فشل الاستعلام عن مستخدم الوسائط المتعددة UAP.",
  "provision.callcenterinstanceslist.whitelist.batchdeleteconfim" : "هل تريد بالتأكيد حذف كافة عناوين قائمة الثقة المحددة؟",
  "provision.tenantspace.save.fail.107-064" : "فشلت إضافة مستخدم الوسائط المتعددة UAP.",
  "provision.tenantspace.feature.OneClickTwoCallSupport.featureParams.callPeriod.label" : "فترة المكالمة (من 1 إلى 60 ثانية)",
  "provision.tenantspace.agentinfo.message.selectagenttype" : "الرجاء تحديد نوع الموظف",
  "chat.client.label.privacyHint" : "نصائح الخصوصية",
  "chat.skill.message.selectSkills" : "يرجى تحديد مهارة.",
  "provision.callcenterinstanceslist.vcallcenterDeatil.specialStrValidateFailed" : "لا يمكن أن يحتوي اسم مركز المكالمات على أحرف خاصة.",
  "provision.tenantspace.agentinfo.message.searchworkno" : "يرجى إدخال رقم الموظف",
  "provision.callcenterinstanceslist.config.ivrnumsError" : "يجب أن يكون عدد قنوات IVR أكبر من مجموع عدد قنوات TTS وعدد قنوات ASR.",
  "provision.tenantspace.save.fail.120609" : "يتم الرجوع إلى ممثلي الأعمال التجارية من قبل ممثلي الأعمال الآخرين ولا يمكن حذفهم.",
  "provision.tenantspace.save.fail.120608" : "تمت الإشارة إلى الموظف بواسطة قائمة انتظار المهارة ولا يمكن حذفه.",
  "provision.tenantspace.save.fail.120969" : "تمت الإشارة إلى قائمة انتظار المهارة بواسطة ACDDN ولا يمكن حذفها.",
  "provision.tenantspace.save.fail.120607" : "تمت الإشارة إلى الموظف بواسطة قائمة انتظار المهارات الخاصة الافتراضية ولا يمكن حذفه.",
  "provision.callcenterinstanceslist.config.success" : "النجاح",
  "provision.callcenterinstanceslist.vcallcenterDeatil.odfsPasswordKeyLengthValidateFailed" : "لا يمكن أن يتجاوز الطول 50 حرفًا.",
  "provision.tenantspace.save.fail.120606" : "تمت الإشارة إلى الموظف بواسطة العملية ولا يمكن حذفه.",
  "provision.tenantspace.save.fail.120968" : "لا يمكن أن يتجاوز الحد الأدنى لمعدل اتصال قائمة انتظار المهارة معدل الاتصال المتوقع.",
  "provision.appsitesmanagement.siteDesc" : "وصف الموقع",
  "provision.tenantspace.save.fail.120605" : "ممثل المبيعات هو قائد الفريق ولا يمكن حذفه.",
  "provision.tenantspace.save.fail.120967" : "نوع وسائط قائمة انتظار المهارة هو مكالمة فاكس\، ويجب تكوين علبة بريد.",
  "provision.label.tenantexport.parainnull" : "الرجاء تحديد البيانات المراد تصديرها.",
  "provision.tenantspace.save.fail.120604" : "الموظف هو مسؤول VDN الافتراضي ولا يمكن حذفه.",
  "provision.tenantspace.save.fail.120966" : "حرف محرك الأقراص المشار إليه من قبل قائمة انتظار المهارة غير موجود.",
  "provision.label.tenantexport.exportTaskStatus" : "تصدير حالة المهمة",
  "provision.subscribedetail.label.operate" : "التشغيل",
  "provision.callcent.error.updateinfo" : "فشل التحديث.",
  "ccprovision.IVRSprogress.config.curcallNo" : "كمية المكالمات المتزامنة",
  "chat.timerange.label.thirtydays" : "آخر 30 يوم",
  "provision.tenantspace.save.fail.120603" : "لا يمكن لمسؤول VDN الافتراضي تعديل الدور.",
  "provision.tenantspace.save.fail.120965" : "تمت الإشارة إلى قائمة انتظار المهارة بواسطة التكوين المطلوب ولا يمكن حذفها.",
  "provision.callcenterinstanceslist.config.voicecallnumsError" : "يجب أن يكون الحد الأقصى لعدد المكالمات الصوتية المتزامنة أكبر من أو يساوي مجموع عدد وكلاء الصوت وعدد قنوات IVR الصوتية والفيديو.",
  "provision.tenantspace.save.fail.120602" : "لا يمكن لمسؤول VDN الافتراضي تغيير كلمة المرور.",
  "provision.tenantspace.save.fail.120964" : "تم الرجوع إلى قائمة انتظار المهارة بواسطة العملية ولا يمكن حذفها.",
  "provision.callcent.platformserver.isales" : "iSales",
  "provision.tenantspace.save.fail.120963" : "يتم الرجوع إلى قوائم انتظار المهارات من قبل ممثلي العمل ولا يمكن حذفها.",
  "provision.tenantspace.save.fail.120601" : "لا يمكن لمسؤول VDN الافتراضي تعديل الاسم.",
  "chat.session.label.skillQueueName" : "اسم قائمة انتظار المهارة",
  "provision.tenantspace.save.fail.120962" : "قائمة انتظار المهارات مشار إليها من قبل مجموعة العملاء ولا يمكن حذفها.",
  "provision.tenantspace.save.fail.120961" : "تمت الإشارة إلى قائمة انتظار المهارة بواسطة CDN ولا يمكن حذفها.",
  "provision.tenantspace.save.fail.120960" : "تم الرجوع إلى قائمة انتظار المهارة بواسطة قوائم انتظار مهارة أخرى ولا يمكن حذفها.",
  "provision.tenantspace.config.IVR" : "عملية IVR",
  "provision.appversionmanagement.androidH5" : "أندرويد H5",
  "provision.callcent.error.deleteobsserver.associationvcallcener" : "رقم ملقم obs المحدد مقترن بالمستأجر ولا يمكن حذفه.",
  "provision.callcenterwhitelist.whiteList" : "عناوين قائمة الثقة",
  "provision.tenantspace.config.SIP" : "خادم SBC",
  "chat.common.message.success" : "النجاح.",
  "provision.callcenterinstanceslist.whiteList.whiteNumberAdd" : "إضافة رقم إلى قائمة الثقة",
  "provision.tenantspace.config.error" : "فشل التكوين. تحقق من التكوينات ذات الصلة أو اتصل بالمسؤول.",
  "provision.tenantspace.option.weeklist_fir" : "أولاً",
  "provision.tenantspace.save.fail.120959" : "تتم الإشارة إلى قائمة انتظار المهارة بواسطة قائمة انتظار المهارة الخاصة الافتراضية ولا يمكن حذفها.",
  "provision.tenantspace.save.fail.120958" : "التكوين المطلوب المشار إليه بواسطة قائمة انتظار المهارة غير موجود.",
  "provision.tenantspace.save.fail.120957" : "العملية المشار إليها بواسطة قائمة انتظار المهارة غير موجودة.",
  "provision.callcenterinstanceslist.vcallcenter.tenantSpaceId" : "معرف المستأجر",
  "provision.callcenterinstanceslist.softPhone.endNumber" : "رقم النهاية",
  "provision.tenantspace.save.fail.120956" : "الموظف المشار إليه بواسطة قائمة انتظار المهارة غير موجود.",
  "provision.tenantspace.save.fail.120955" : "قائمة انتظار المهارة المشار إليها بواسطة قائمة انتظار المهارة غير موجودة.",
  "provision.appversionmanagement.updateTime" : "تحديث الوقت",
  "provision.tenantspace.save.fail.120954" : "نوع الخدمة المشار إليه بواسطة قائمة انتظار المهارة غير موجود.",
  "provision.tenantspace.status.invalid.success" : "نجح الإلغاء.",
  "provision.tenantspace.save.fail.120953" : "العناصر المهارة في قائمة انتظار المهارات مكررة.",
  "provision.callcent.platformserver.ccfs" : "CCFS",
  "provision.callcenterinstanceslist.config.ccIdError" : "تم استخدام رقم مركز الاتصال.",
  "provision.callcenterinstanceslist.ccdis.progId" : "ProgID",
  "provision.label.tenantexport.download" : "تحميل",
  "provision.callcenterinstanceslist.table.deleteConfimMessage" : "هل تريد بالتأكيد حذف مركز الاتصالات المحدد والتكوين المرتبط به؟",
  "provision.encryptinfo.updateTime" : "تم التحديث في",
  "provision.tenantspace.feature.newcase.open.confirmmsg" : "سيتم أيضًا تمكين ميزات AppCube ومركز العملاء نظرًا لأنها أساس تشغيل ميزة الحالة 2.0. بالإضافة إلى ذلك، لا يمكن للمستأجرين الذين قاموا بتمكين ميزة الحالة 2.0 تمكين ميزة البيانات المخصصة وسيقومون بتعطيل ميزة البيانات المخصصة.",
  "provision.tenantspace.feature.customdata.open.confirmmsg" : "سيؤدي تمكين ميزات البيانات المخصصة إلى تعطيل ميزة مركز العملاء وميزة Case 2.0 تلقائيًا",
  "provision.tenantspace.save.fail.107-045" : "يقوم VDN الخاص بالنظام بحذف رمز الاستثناء حيث لا يتطابق VDNID وVDNName.",
  "provision.tenantspace.save.fail.107-044" : "استثناء فشل حذف VDN للنظام.",
  "chat.webconfig.label.windowTitle" : "عنوان الصفحة",
  "provision.tenantspace.save.fail.107-043" : "استثناء فشل استعلام VDN للنظام.",
  "provision.tenantspace.save.fail.107-042" : "استثناء فشل تعديل VDN للنظام.",
  "provision.label.tenantexport.exportEndTime" : "وقت انتهاء التصدير",
  "provision.tenantspace.table.tenantSpaceName" : "مساحة المستأجر",
  "provision.tenantspace.save.fail.107-041" : "فشلت إضافة VDN للنظام.",
  "provision.tenantspace.save.fail.107-040" : "استثناء فشل عملية حذف خدمة IVR.",
  "provision.authconfig.description.empty.error" : "لا يمكن أن يكون الوصف فارغًا.",
  "provision.callcent.label.closeFaule" : "CloseFault",
  "provision.tenantspace.config.fail.modifydata.tenant.feature.inDatabase" : "فشلت قاعدة البيانات في تعديل معلومات ميزة المستأجر",
  "provision.sysparam.config.save" : "حفظ",
  "provision.callcenterinstanceslist.vcallcenterDeatil.whiteNumValidateFailed" : "يُسمح فقط بالأرقام.",
  "provision.tenantspace.table.invalidTenantSpace" : "لا يمكن إجراء هذه العملية بعد الإلغاء\، الرجاء تأكيد ما إذا كان المستأجر يحتاج إلى الإلغاء!",
  "provision.tenantspace.config.agent.zero" : "لا يمكن أن يكون عدد موظفي الصوت 0.",
  "provision.tenantspace.save.fail.107-039" : "استثناء فشل الاستعلام عن عملية خدمة IVR.",
  "provision.tenantspace.cc.videoAgentCurCapacity" : "كمية وكيل الفيديو",
  "provision.spinfo.idpfile" : "ملف IDP",
  "provision.tenantspace.save.fail.107-038" : "استثناء فشل تعديل عملية خدمة IVR.",
  "provision.tenantspace.save.fail.107-037" : "استثناء في عملية زيادة فشل خدمة IVR.",
  "provision.callcent.platformserver.port" : "رقم منفذ الخدمة",
  "provision.tenantspace.save.fail.107-036" : "استثناء فشل حذف رمز الوصول إلى النظام.",
  "provision.tenantspace.save.fail.107-035" : "فشل الاستعلام عن رمز الوصول إلى النظام بشكل غير طبيعي.",
  "provision.callcenterinstanceslist.label.asrchannelUseRate" : "استخدام ASR",
  "provision.tenantspace.save.fail.107-034" : "استثناء فشل تعديل رمز الوصول إلى النظام.",
  "provision.systemparam.error.save" : "فشل حفظ المعلمة",
  "provision.tenantspace.save.fail.107-033" : "فشل إضافة رمز الوصول إلى النظام غير طبيعي.",
  "provision.accesscode.table.multimedia" : "الوسائط المتعددة",
  "provision.tenantspace.agentUsage.iszero" : "يُرجى تكوين موظف الصوت وموارد ASR أولاً.",
  "provision.tenantspace.save.fail.107-032" : "استثناء فشل رسائل تأكيد الاشتراك.",
  "provision.callcenterinstanceslist.table.videoAgentCurCapacity" : "كمية وكيل الفيديو",
  "provision.tenantspace.save.fail.107-031" : "استثناء فشل إلغاء الاشتراك.",
  "provision.tenantspace.save.fail.107-030" : "استثناء الاشتراك.",
  "chat.client.greeting.afternoon" : "مساء الخير!",
  "provision.callcent.selectwhitelist" : "الرجاء تحديد عنوان قائمة ثقة.",
  "ccprovision.recorddrive.config.recorddrivename" : "اسم حرف محرك الأقراص",
  "provision.callcent.error.errorinfo" : "فشل الإنشاء.",
  "ccprovision.recorddrive.config.isfirstletter" : "الإشارة إلى ما إذا كان محرك الأقراص هو الأول",
  "provision.tenantspace.config.softphoneassign.tip" : "يُرجى إدخال مقطع رقم الهاتف.",
  "provision.callcenterinstanceslist.vcallcenter.maxVersatileAgents" : "وكلاء متعددي الاستخدامات",
  "provision.callcent.packagefile.saveinfo" : "تم الإنشاء بنجاح. تتم معالجة حزمة الإصدار. انتظر حتى تقوم الصفحة بتحديث نتيجة معالجة حزمة الإصدار.",
  "provision.tenantspace.authenticationsystem.clientName" : "نظام مصادقة الطرف الثالث",
  "provision.tenantspace.save.fail.107-029" : "استثناء مهلة طلب الاشتراك.",
  "provision.tenantspace.save.fail.107-028" : "استثناء فشل طلب الاشتراك.",
  "provision.tenantspace.feature.selectAppCubeSupport.description" : "قم بتمكين ميزة AppCube بحيث يمكن لمساحة المستأجر دمج وظيفة AppCube.",
  "provision.tenantspace.save.fail.107-027" : "استثناء فشل تسجيل الاشتراك.",
  "provision.tenantspace.save.fail.107-026" : "استثناء فشل الاشتراك.",
  "provision.callcenterinstanceslist.vcallcenter.asrchannelnums" : "ASRchannelnums",
  "provision.tenantspace.save.fail.107-025" : "اسم ملف عملية IVR فارغ.",
  "provision.callcenterinstanceslist.table.ctiversion" : "إصدار CTI",
  "provision.tenantspace.save.fail.107-024" : "استثناء فشل استعلام عملية IVR.",
  "provision.callcent.error.deleteobsserverdesinfo" : "تأكد من عدم وجود اسم خادم obs.",
  "provision.subscribedetail.success.delete" : "تم الحذف بنجاح.",
  "provision.tenantspace.save.fail.107-023" : "استثناء فشل استعلام VDN.",
  "provision.tenantspace.save.fail.107-022" : "استثناء فشل تعديل VDN.",
  "provision.tenantspace.save.fail.107-021" : "استثناء فشل حذف تم استدعاؤه.",
  "provision.tenantspace.save.fail.107-020" : "فشل الاستعلام المطلوب بشكل غير طبيعي.",
  "provision.tenantspace.chooseobsdatasource.thirdPartyDbSource" : "مصادر بيانات الطرف الثالث",
  "provision.appversionmanagement.sureDeleteVersionNumber" : "هل تريد بالتأكيد حذف معلومات الإصدار؟",
  "provision.tenantspace.agentinfo.message.video" : "فيديو",
  "provision.tenantspace.cc.agentCurCapacity" : "كمية الوكيل المستخدمة",
  "provision.tenantspace.config.amsNumber.positiveInteger" : "لا يمكن أن يكون عدد المكالمات المجهولة المتزامنة فارغًا ويجب أن يكون عددًا صحيحًا موجبًا.",
  "provision.recorddrive.label.isfirstletter.yes" : "نعم",
  "provision.appversion.msg.uploadfile.9.fail" : "فشل التحقق من الملف.",
  "provision.softphone.label.issyncuap.no" : "لا",
  "provision.tenantspace.save.fail.107-019" : "فشل التعديل المطلوب بشكل غير طبيعي.",
  "provision.tenantspace.save.fail.107-018" : "تم استدعاء استثناء فشل الزيادة.",
  "provision.tenantspace.save.fail.107-017" : "استثناء فشل حذف الدور.",
  "provision.callcenterinstanceslist.config.interfacepassword" : "كلمة مرور CTI ConfigInterface",
  "provision.subscribedetail.button.delete" : "حذف",
  "provision.tenantspace.save.fail.107-016" : "استثناء فشل الاستعلام عن الدور.",
  "provision.subscribedetail.label.phoneNo" : "الاتصال الهاتف",
  "provision.tenantspace.save.fail.107-015" : "استثناء فشل تعديل الدور.",
  "provision.tenantspace.save.fail.107-014" : "دور غير طبيعي زيادة الفشل.",
  "provision.systemparam.error.validate.watermarkinfo.notmatchruleregex" : "تسمح المعلمة فقط بـ 0 إلى 20 حرفًا تحتوي على أحرف كبيرة وصغيرة\ أو أرقام أو -_/{'@'}.",
  "provision.systemparam.error.validate.notmatchrulemaxlength" : "تتجاوز معلمة الإدخال الحد الأقصى للطول",
  "provision.tenantspace.save.fail.107-013" : "استثناء فشل حذف الموظف.",
  "provision.tenantspace.save.fail.107-012" : "استعلام الموظف غير طبيعي.",
  "provision.tenantspace.save.fail.107-011" : "واجه تعديل العامل أو تعديل عامل الحزمة استثناءً.",
  "provision.tenantspace.timezone.updatemessage" : "قد يؤثر تعديل معلومات المنطقة الزمنية على معالجة الأعمال خلال الفترة الانتقالية.",
  "provision.tenantspace.save.fail.107-010" : "واجهت إضافة موظفين أو إضافة موظفين في دفعات استثناءً.",
  "provision.tenantspace.feature.appcube.close.confirmmsg" : "بعد الإغلاق، سيتم مسح بيانات العمل (مثل بيانات طلب العمل وبيانات جهة الاتصال وما إلى ذلك) في AppCube ولا يمكن استعادتها. يرجى تأكيد ما إذا كنت ستغلق أم لا",
  "provision.tenantspace.status.stop.fail" : "فشل الإيقاف المؤقت.",
  "provision.tenantspace.dataUpload.contactRecord" : "سجل جهة الاتصال",
  "provision.tenant.management.timezoneOffset" : "إزاحة المنطقة الزمنية",
  "provision.callcenterinstanceslist.vcallcenter.syncVccSuccess" : "اكتملت المزامنة.",
  "provision.whiteListAddress.internal.message.notification" : "عنوان IP ومنفذ خدمة استدعاء التطبيق لإشعار الرسائل الداخلية",
  "provision.tenant.management.batchcreate.error.accesscodenumber" : "يُسمح بحد أقصى 100 رمز وصول.",
  "chat.agent.label.consumption" : "إنفاقي",
  "provision.systemparam.error.validate.ruleregexinvalid" : "خطأ في بناء جملة القاعدة بالتحقق من صحتها",
  "provision.tenantspace.status.active.success" : "التنشيط الناجح.",
  "provision.tenant.management.adminpassword" : "كلمة المرور",
  "provision.tenantspace.feature.agentSendLocalMediaFile" : "إرسال ملف الوسائط المتعددة المحلي بواسطة الوكلاء",
  "provision.tenant.management.tenantexport.addTask" : "إنشاء مهمة تصدير",
  "provision.tenantspace.save.fail.107-007" : "فشلت المصادقة قبل تكوين واجهة التكوين.",
  "provision.callcent.success.setinfo" : "تم التعيين بنجاح.",
  "provision.tenantspace.save.fail.107-005" : "فشل اتصال واجهة التكوين.",
  "provision.tenantspace.save.fail.107-004" : "حساب مسؤول التكوين غير صالح.",
  "provision.tenantspace.config.vccName" : "اسم VCC",
  "provision.tenantspace.save.fail.107-003" : "كلمة مرور مسؤول التكوين غير صحيحة\، قفل الحساب بعد إعادة المحاولة عدة مرات.",
  "provision.tenantspace.save.fail.107-002" : "معلمة الإدخال غير صالحة\، الحقل المطلوب فارغ.",
  "provision.tenantspace.save.fail.107-001" : "تم تقديم استثناء عند استدعاء واجهة التكوين.",
  "provision.obs.sync.needDelete" : "تم ربط OBS التالي بالمستأجرين ويجب إلغاء ارتباطه يدويًا ثم مزامنته:",
  "provision.tenantspace.save.fail.107-000" : "معلومات تسجيل الدخول المخزنة مؤقتًا غير صالحة\، تحتاج إلى إعادة مصادقة تسجيل الدخول.",
  "chat.session.label.created_endTime" : "وقت الانتهاء",
  "provision.tenantspace.selectStastus.active" : "تم التنشيط",
  "provision.ccp.redis.packagefile.failed" : "فشل إيداع حزمة الإصدار.",
  "provision.tenantspace.feature.dualChannelRecSupport" : "تسجيل ثنائي المسار",
  "provision.accesscode.table.beginNumber" : "بدء رمز الوصول",
  "provision.tenantspace.save.fail.120905" : "حرف محرك الأقراص المشار إليه من قبل نوع العمل غير موجود.",
  "provision.tenantspace.save.fail.120904" : "تتم الإشارة إلى نوع العمل حسب الوقت القياسي للعمل ولا يمكن حذفه.",
  "provision.tenantspace.feature.dualCallAPPTransparentDisplaySupport" : "مهمة مكالمة التطبيق",
  "provision.callcenterinstanceslist.config.workNoError" : "يجب أن يكون معرف موظف البدء في النطاق من 101 إلى 50000.",
  "provision.tenantspace.save.fail.120903" : "تمت الإشارة إلى نوع العمل بواسطة قائمة انتظار المهارة ولا يمكن حذفه.",
  "provision.tenantspace.save.fail.120902" : "تمت الإشارة إلى نوع العمل بواسطة العملية ولا يمكن حذفه.",
  "chat.client.greeting.morning" : "صباح الخير!",
  "provision.tenantspace.save.fail.120901" : "وصف مكرر لنوع العمل.",
  "provision.tenantspace.feature.onlineCustomerServiceAssets" : "أصول خدمة العملاء عبر الإنترنت",
  "chat.common.message.error" : "الأخطاء",
  "provision.callcent.success.saveinfo" : "تم الإنشاء بنجاح.",
  "provision.callcent.error.deleteinfo.from.cms.unknown.error" : "حدث خطأ غير معروف في CMS.",
  "provision.tenant.management.tenantcreate.emailillegal" : "عنوان بريد إلكتروني غير صالح.",
  "chat.usefulexpressions.message.createFail" : "فشل إنشاء العبارة العامة.",
  "provision.accesscode.error.transfer.queryinterface" : "فشل الاستعلام عن بيانات النظام الأساسي.",
  "provision.tenantspace.updateTenantSpaceStatus.confirm" : "تأكيد تغيير حالة مساحة المستأجر",
  "provision.tenantspace.statusRecordTime.12Month" : "12 شهر",
  "provision.callcenterinstanceslist.sipServer.singledeleteconfim" : "هل تريد بالتأكيد حذف ملقم التسجيل المحدد؟",
  "provision.tenantspace.feature.OneClickTwoCallSupport.featureParams.placeholder" : "قم بتعيين هذه المعلمة استنادًا إلى عدد جلسات IVR المتزامنة\، وعدد جلسات الشبكة الأساسية المتزامنة\، وعدد جلسات الخط المتزامنة التي تم تكوينها بواسطة المستأجر.",
  "provision.tenantspace.save.fail" : "فشل الحفظ.",
  "provision.callcenterinstanceslist.vcallcenter.ivrchannels" : "AudioIVRchannels",
  "provision.tenantspace.config.worknoassign.filternumber.error" : "الرقم المستبعد لا يفي بالمتطلبات.",
  "provision.tenantspace.resetpwdfaild.user.is.locked" : "فشل تغيير كلمة المرور نظرًا لقفل المستخدم.",
  "provision.tenant.management.tenantcreate.dds.failed" : "فشل إنشاء مساحة المستأجر في DDS.",
  "provision.systemparam.error.refresh" : "فشل تحديث المعلمة",
  "provision.tenantspace.table.ttschannelnums" : "TTS الكمية",
  "chat.webconfig.label.greeting.placeholder" : "الرجاء إدخال محتوى الترحيب الذي سيتم عرضه في نافذة الدردشة الخاصة بالعميل.",
  "provision.tenantspace.config.choose.anonymous.call.server.type" : "نوع خادم مكالمة مجهولة",
  "provision.tenantspace.resetpwdfaild.user.not.exist" : "فشل تغيير كلمة المرور لأن المستخدم غير موجود.",
  "provision.tenant.management.tenantname.alphavalidateFailed" : "توجد أحرف غير صالحة. يتم دعم الحروف \، والأرقام \، والسفلة السفلية (_) فقط.",
  "provision.tenant.management.tenantcreate.param.failed" : "معلمة الإدخال غير صالحة.",
  "provision.callcenterinstanceslist.vcallcenter.invalidVccListPromt" : "توجد مراكز اتصال افتراضية افتراضية في AICC حيث يكون VDN مفقودًا في WAS. VccId",
  "chat.weChatConfig.label.search" : "البحث عن",
  "provision.tenantspace.chooseobsdatasource.platformDbSource" : "مصادر بيانات المنصة",
  "ccprovision.IVRSprogress.config.tenantname" : "المستأجر",
  "provision.callcent.error.paramNotavailable" : "معلمة الطلب فارغة.",
  "chat.skill.label.skillName" : "الاسم",
  "provision.tenantspace.config.tenant.featurevalue.not.digits" : "معلومات الميزة المعينة إلى المستأجر غير صالحة.",
  "provision.tenantspace.feature.smartivr.close.error" : "قم بتعطيل ميزة أصل خدمة العملاء عبر الإنترنت قبل تعطيل ميزة IVR الذكية.",
  "provision.callcenterinstanceslist.IVRSprogress.Title" : "تكوين عملية IVR",
  "provision.tenant.management.mobile" : "رقم الجوال",
  "provision.subscribedetail.label.companyName" : "الشركة",
  "provision.callcenterinstanceslist.ccgateway.idleRegAgentNum" : "عامل الخمول الكمية",
  "ccprovision.vcallecenter.config.popup.adminpassword" : "تغيير كلمة مرور مسؤول التكوين",
  "provision.tenant.management.batchcreate.error.accesscode.inadequacy" : "لا يمكن أن يكون عدد رموز الوصول أقل من عدد مساحات المستأجر.",
  "provision.recorddrive.selectneed.delete.recorddrive" : "الرجاء تحديد حرف محرك الأقراص المراد حذفه",
  "provision.callcenterinstanceslist.ccdis.ccdisbackupIP" : "عنوان IP للاستعداد",
  "ccprovision.recorddrive.config.sftp.accountname" : "حساب خادم SFTP",
  "provision.tenantspace.config.choose.anonymous.call.server" : "خادم المكالمات المجهولة",
  "provision.callcenterinstanceslist.config.systemAdmin" : "معرف مسؤول CTI",
  "provision.tenantspace.feature.customeragenttype" : "قائد واحد على واحد",
  "provision.tenantspace.config.param.error.agentUsage" : "الرجاء إدخال عدد موظفي الصوت.",
  "provision.callcenterinstanceslist.softPhone.usedselect.isnotoccupied" : "غير مستخدمة",
  "provision.tenantspace.config.agentUsage" : "وكلاء",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.noempty" : "معلومات الرقم إلزامية.",
  "provision.common.title.tipsInfo" : "معلومات",
  "provision.callcent.error.saveccgateway.ipportinfo" : "فشل إنشاء\، الرجاء تأكيد ما إذا كان IP الخاص بـ CC-Gateway والمنفذ موجودين بالفعل.",
  "provision.callcenterinstanceslist.vcallcenterDeatil.configWorkNo" : "معرف مسؤول التكوين",
  "provision.tenantspace.ivr.idlechnNum" : "كمية المكالمات المتزامنة المتبقية",
  "provision.callcenterinstanceslist.table.hasVcallcenterConfim" : "لا يمكن حذف مركز المكالمات الذي تم تكوينه باستخدام مركز المكالمات الظاهري.",
  "provision.tenantspace.input.agentnum" : "قم بتعيين عدد الموظفين المطلوب طلبهم.",
  "provision.callcenterinstanceslist.table.nodemodesingle" : "وضع واحد",
  "chat.session.label.alias" : "اسم العميل",
  "provision.tenantspace.detail.apply.name" : "مقدم الطلب",
  "provision.tenantspace.config.isAutoAssignSoftPhone" : "ما إذا كان يتم تخصيص رقم الهاتف تلقائيًا لـ VCC",
  "provision.callcenterinstance.accesscode.batchcreate.fail.processingtask" : "معالجة مهمة إنشاء رموز الوصول على دفعات",
  "provision.tenantspace.open.uploadfeature" : "تأكيد لفتح",
  "provision.callcent.error.portValidateFailed" : "HeartbeatPort ليس رقمًا.",
  "provision.callcenterinstanceslist.config.adminWnFrom" : "معرف موظف بدء المسؤول",
  "chat.weChatConfig.message.deleteError" : "فشل حذف عنصر التكوين.",
  "provision.tenantspace.config.selectedworkNo" : "معرفات العمل المحددة #*@$20} #*@$21}",
  "provision.callcent.pool.select.different.amsservice.error" : "يجب أن تحدد العقد المختلفة نفس نوع خادم المكالمات المجهولة.",
  "chat.weChatConfig.message.savesuccess" : "نجح الوصول.",
  "provision.label.tenantexport.parainvalid" : "فشل التحقق من شرط التصدير.",
  "provision.tenantspace.resetpwdfaild" : "فشل في إعادة تعيين كلمة المرور.",
  "provision.callcenterinstanceslist.table.nodemodeselect" : "تحديد الوضع",
  "provision.accesscode.table.callin" : "مكالمة واردة",
  "provision.tenantspace.dstEndDateVerification" : "يمكن أن يكون تاريخ انتهاء التوقيت الصيفي بعد تاريخ بدء التوقيت الصيفي فقط.",
  "provision.callcent.error.updateccgateway.fail.syncccmanagement" : "فشل التحديث\، فشلت مزامنة CC-Gateway لتعديل المعلومات إلى غرفة التأجير.",
  "chat.client.alert.message.invildtoken" : "انتهت مهلة جلسة العمل\، أو قمت بتسجيل الدخول إلى مكان آخر. يُرجى إغلاق نافذة الدردشة والمحاولة مرة أخرى. شكرًا لك!",
  "provision.tenant.management.daylightSavingTime" : "DST",
  "provision.callcent.error.updateucsserver.ipandport.alreadyexist" : "IP ورقم المنفذ لملقم CCUCS موجودان بالفعل ولا يمكن تعديلهما.",
  "provision.tenantspace.feature.whatsAppMarketingSupport.description" : "توفر هذه الميزة وظائف تسويق WhatsApp لمساحة المستأجر.",
  "provision.tenantspace.feature.speechAnalysis.description" : "تم تمكين ميزة تحليل الكلام. تدعم مساحة المستأجر تحليل النص والصوت.",
  "provision.tenantspace.config.anonymous.call.number" : "عدد المكالمات المجهولة المتزامنة",
  "provision.tenantspace.config.softphoneassign.softphonescope.inadequacy" : "رقم الهاتف المحمول المخصص غير كافٍ.",
  "provision.appversionmanagement.selectDataDeleted" : "حدد البيانات المراد حذفها.",
  "provision.tenantspace.feature.outboundCallMarketingSupport.error" : "قم بتعطيل ميزات التسويق عبر الرسائل القصيرة والبريد الإلكتروني و WhatsApp أولاً.",
  "provision.callcent.error.updateccgateway.ipandport.alreadyexist" : "فشل التحديث\، يُرجى تأكيد وجود IP ومنفذ عبّارة CC-Gateway بالفعل.",
  "provision.callcenterinstanceslist.config.wnFrom" : "معرّف الموظف البدء",
  "provision.encryptinfo.encryptKey" : "مفتاح التحديث",
  "provision.tenantspace.feature.customData" : "تخصيص البيانات",
  "provision.tenantspace.ivr.id" : "لا ..",
  "provision.callcenterinstanceslist.label.eMediaAgentCurCapacity" : "وكيل الوسائط المتعددة الكمية",
  "provision.subscribedetail.label.remark" : "ملاحظة",
  "chat.client.label.forgotPassword" : "نسيت كلمة المرور",
  "provision.tenantspace.feature.whatsAppMarketingSupport" : "WhatsApp التسويق",
  "provision.tenantspace.feature.smsMarketingSupport" : "SMS التسويق",
  "provision.tenantspace.config.chooseIVR" : "حدد عملية IVR.",
  "provision.tenantspace.cc.ivrchannelUsagePercent" : "الصوت IVR استخدام القناة",
  "chat.weChatConfig.link.wechat" : "WeChat وراء الكواليس",
  "provision.callcent.success.oj8k" : "مكتمل",
  "provision.tenantspace.input.searchName" : "أدخل اسم مركز المكالمات المراد الاستعلام عنه.",
  "provision.subscribedetail.delete.title" : "حذف التأكيد",
  "provision.appsitesmanagement.new" : "إضافة",
  "chat.client.label.more" : "المزيد",
  "provision.tenantspace.feature.mobileAppH5" : "إصدار MobileApp H5",
  "provision.tenantspace.configureauthentication" : "تكوين المصادقة",
  "provision.ivr.selectivrprogress" : "حدد عملية IVR التي تريد حذفها.",
  "provision.callcenterinstanceslist.vcallcenter.vcallcenterDeatil" : "تفاصيل VCC",
  "provision.tenantspace.feature.cobrowse.needak" : "تحتاج إلى تكوين معلومات AK قبل تمكين ميزة التصفح المشترك.",
  "chat.skill.message.willDeleteSkills" : "هل تريد بالتأكيد حذف المهارة المحددة؟",
  "provision.encryptinfo.validityPeriod" : "فترة الصلاحية (الشهر)",
  "provision.appsitesmanagement.unable" : "لا",
  "provision.appversionmanagement.android" : "أندرويد",
  "provision.callcenterinstanceslist.ucsServer.ucsServerIpUpdate" : "تحرير",
  "provision.callcenterinstanceslist.config.odfsappkey" : "ODFS AppKey",
  "provision.callcenterinstanceslist.table.videoIvrChannels" : "فيديو IVR قناة الكمية",
  "chat.skill.label.maxQueueNumber" : "الحد الأقصى لعدد قوائم الانتظار",
  "chat.skill.label.account" : "الحساب",
  "provision.subscribedetail.button.reset" : "إعادة الضبط",
  "chat.weChatConfig.label.Domain" : "عنوان الخادم",
  "chat.timerange.label.threedays" : "آخر 3 أيام",
  "provision.callcenterinstanceslist.table.agentUseRate" : "استخدام وكيل الصوت",
  "promotion.tenant.management.tenantedit.cc.null" : "معرف مركز المكالمات الذي ينتمي إليه مركز المكالمات الظاهري فارغ.",
  "provision.tenantspace.select.stop" : "توقفت",
  "provision.sysparam.config.value" : "قيمة المعلمة",
  "provision.tenant.management.button.cancel" : "إلغاء",
  "chat.agent.title.createSkill" : "إنشاء مهارة",
  "provision.label.tenantexport.conditions" : "معايير البحث",
  "provision.label.tenantexport.confirmMessage" : "هل تريد بالتأكيد إنشاء مهمة تصدير؟",
  "provision.appversion.msg.uploadfile.fail" : "فشل إيداع الملف. قم بتعديله وتحميله مرة أخرى.",
  "provision.encryptinfo.algorithm" : "خوارزمية التشفير",
  "provision.callcenterinstanceslist.config.passwordConfirm" : "تأكيد كلمة المرور",
  "chat.webconfig.label.language" : "اللغة الافتراضية",
  "provision.web.validator.ip" : "عنوان IP غير صالح. (أدخل عنوان IPv4 أو IPv6 أو اسم مجال.)",
  "provision.sysparam.config.itemname" : "اسم المعلمة",
  "provision.callcenterinstanceslist.label.videoIvrChannelUseRate" : "فيديو IVR استخدام القناة",
  "provision.tenantspace.verification.fail" : "فشل التحقق من صحة تنسيق الوقت.",
  "provision.tenant.management.companyName" : "الشركة",
  "provision.callcenterinstanceslist.softPhone.beginNumber" : "رقم البدء",
  "chat.client.message.noAgent" : "عذرًا\، خدمة العملاء مشغولة. يُرجى إعادة المحاولة لاحقًا.",
  "provision.sysparam.config.opterate" : "العملية",
  "provision.tenantspace.status.stop.success" : "النجاح.",
  "provision.tenantspace.config.agent.videoagent.ivr.more.zero" : "لا يمكن أن يكون عدد وكلاء الصوت\ وعامل الفيديو\ وعامل الوسائط المتعددة\ وقنوات IVR الصوتية وقنوات IVR للفيديو 0.",
  "provision.tenant.management.tenantcreate.sum.failed" : "فشل إنشاء مساحة المستأجر في SUM.",
  "provision.ccworknos.table.updateaccessCode" : "تحرير",
  "provision.callcenterinstanceslist.softPhone.passwordconf" : "تأكيد كلمة المرور",
  "provision.callcenterinstanceslist.config.ivrchannelsValidate" : "عدد قنوات IVR الصوتية أصغر من عدد القنوات المخصصة فعليًا في مركز الاتصال.",
  "provision.callcenterinstanceslist.config.ccNameUsed" : "تم استخدام اسم مركز المكالمات.",
  "provision.tenantspace.feature.newcase.open.customcenter.confirmmsg" : "سيتم أيضًا تمكين ميزات AppCube ومركز العملاء نظرًا لأنها أساس تشغيل ميزة الحالة 2.0.",
  "chat.client.label.no" : "لا ..",
  "provision.tenantspace.detail" : "تفاصيل المستأجر",
  "provision.callcenterinstance.accesscode.validateaccesscoderange" : "يمكن إضافة 100 رمز وصول كحد أقصى على دفعات.",
  "provision.tenantspace.dataUpload.cdrData" : "بيانات CDR",
  "provision.tenant.management.languagetype" : "نوع اللغة",
  "provision.appversion.msg.uploadfile.4.fail" : "يحتوي الملف على مخاطر الحقن. قم بإجراء تحليل.",
  "provision.tenantspace.config.param.error.asrNumber" : "الرجاء إدخال كمية ASR.",
  "provision.callcenterinstanceslist.ccgatewayServer.batchdeleteconfim" : "هل تريد بالتأكيد حذف كافة ملقمات CC-Gateway المحددة؟",
  "chat.webconfig.message.demodirective" : "يمكنك أيضًا فتح عنوان URL التالي في النافذة غير المرئية لمتصفح Chrome للوصول إلى صفحة العرض التوضيحي التي يوفرها النظام.",
  "provision.tenantspace.clear" : "مسح",
  "chat.usefulexpressions.label.expressionDetail" : "تفاصيل العبارة العامة",
  "chat.weChatConfig.label.wechat" : "Wechat",

  "provision.log.config.agent.track.config.title": "تكوين تتبع الموظف",
  "provision.log.config.agent.track.config.refresh": "تحديث",
  "provision.log.config.agent.track.config.tenantSpaceName": "اسم المستأجر",
  "provision.log.config.agent.track.config.workNo": "معرف الموظف",
  "provision.log.config.agent.track.config.tenantSpaceName.error.hints": "يُسمح فقط بالأحرف الرقمية والشرطات السفلية",
  "provision.log.config.agent.track.config.tenantSpaceName.length.error.hints": "أدخل اسمًا من 8 إلى 30 رقمًا يبدأ بحرف ويحتوي فقط على حروف رقمية وتسطير سفلي",
  "provision.log.config.agent.track.config.logLevel": "مستوى السجل",
  "provision.log.config.agent.track.config.createTime": "وقت الإنشاء",
  "provision.log.config.agent.track.config.endTime": "تاريخ انتهاء التتبع",
  "provision.log.config.agent.track.config.operation": "العمليات",
  "provision.log.config.agent.track.config.delete": "حذف",
  "provision.log.config.agent.track.config.batch.delete": "حذف دفعة",
  "provision.log.config.agent.track.config.add": "جديد",
  "provision.log.config.agent.track.config.batchDelete.hints.pleaseSelect": "الرجاء تحديد معلومات تكوين سجل تتبع الموظف",
  "provision.log.config.agent.track.config.batchDelete.hints.exceed.max.operate.num": "لا يمكن حذف أكثر من 100 سجل في وقت واحد",
  "provision.log.config.agent.track.config.create.title": "إنشاء تكوين تتبع الموظف",
  "provision.log.config.agent.track.config.create.confirm": "تأكيد",
  "provision.log.config.agent.track.config.create.cancel": "ألغي الأمر",
  "provision.log.config.agent.track.config.create.endTime.placeholder": "الرجاء تحديد وقت انتهاء التتبع",
  "provision.log.config.agent.track.config.create.must": "مطلوب",
  "provision.log.config.agent.track.config.create.tenantSpaceName.min.length": "لا يمكن أن يكون اسم المستأجر أقل من 8 أرقام",
  "provision.log.config.agent.track.config.create.workNol.check.hints": "الرجاء إدخال عدد صحيح بين 101-50000",
  "provision.log.config.agent.track.config.create.workNol.check.hints.1": "أدخل عددًا صحيحًا لا يزيد عن 50000",
  "provision.log.config.agent.track.config.create.end.check.hints": "يُرجى تحديد نقطة زمنية خلال الأيام الثلاثة القادمة",
  "provision.log.config.agent.track.config.success": "نجح الأمر",
  "provision.log.config.agent.track.config.failed": "فشل",
  "provision.log.config.agent.track.config.delete.success": "تم الحذف بنجاح",
  "provision.log.config.agent.track.config.delete.failed.11": "فشل الحذف",
  "provision.log.config.agent.track.config.delete.failed.10": "خطأ في حذف معلمة العملية",
  "provision.log.config.agent.track.config.create.top.hints": "بعد تمكين تتبع سجل الوكيل المستأجر، يقوم الوكيل بتوصيل المكالمات وجمع سجلات التتبع التي تم إنشاؤها إلى خدمة CCManagement.ستؤثر هذه العملية على أداء النظام. يُرجى تمكينها وفقًا لمتطلبات التتبع الفعلية.",
  "provision.log.config.agent.track.config.create.success": "تم الإنشاء بنجاح",
  "provision.log.config.agent.track.config.create.failed": "فشل الإنشاء",
  "provision.log.config.agent.track.config.create.failed.1": "طول اسم مساحة المستأجر غير صالح",
  "provision.log.config.agent.track.config.create.failed.2": "يحتوي اسم المستأجر على أحرف خاصة",
  "provision.log.config.agent.track.config.create.failed.3": "فشل التحقق من اسم المستأجر",
  "provision.log.config.agent.track.config.create.failed.4": "فشل التحقق من معرف العمل",
  "provision.log.config.agent.track.config.create.failed.5": "فشل التحقق من مستوى السجل",
  "provision.log.config.agent.track.config.create.failed.6": "فشل التحقق من وقت انتهاء التتبع",
  "provision.log.config.agent.track.config.create.failed.7": "تم تجاوز الحد الأقصى القابل للتكوين",
  "provision.log.config.agent.track.config.create.failed.8": "تكوين تتبع السجل موجود بالفعل لهذا الموظف",
  "provision.log.config.agent.track.config.create.failed.9": "فشل الإنشاء",
  "provision.log.config.agent.track.config.max.length": "الحد الأقصى للطول هو",

  "provision.tenant.management.tenantname.contact.blocklist.check": "هناك أحرف غير صالحة. الأحرف الخاصة غير مسموح بها: & < > \" ; % / \\ ^ [ ] = + @",
}