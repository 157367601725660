export function transTime(time, connector) {
    if (!time && time !== "0") return undefined;
        let con = (connector || '-'); // 日期分割符默认调整为'-'
        // 判断入参是否全部是数字，如果全部是数字，则表示是时间戳转换的字符串，需要转换回数字，否则new Date会报错。
        let date;
        if (time) {
            date = isNumber(time) ? new Date(parseInt(time)) : new Date(time);
        } else {
            date = new Date();
        }
        let Y = `${date.getFullYear()}${con}`;
        let M = `${
            date.getMonth() + 1 < 10
                ? "0" + (date.getMonth() + 1)
                : date.getMonth() + 1
            }${con}`;
        let D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
        let h =
            (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
        let m =
            (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
            ":";
        let s =
            date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    return Y + M + D + h + m + s;
}


export function getUTCTimestamp (time) {
    // 判断入参是否全部是数字，如果全部是数字，则表示是时间戳转换的字符串，需要转换回数字，否则new Date会报错。
    let date;
    if (time) {
        date = isNumber(time) ? new Date(parseInt(time)) : new Date(time);
    } else {
        date = new Date();
    }

    /**
     if ($UEE.$Tz.dstsupport) { // 如果服务端设置了时区，则按照服务端设置的时区返回时间戳
        let serverTime =  date.getTime() + $UEE.$Tz.stz.rawOffset + (new Date()).getTimezoneOffset() * 60000;
        date = new Date(serverTime);
    }**/

    let resultTime;
    resultTime = Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds(),
        date.getUTCMilliseconds()
    );
    return resultTime;
}
/**
* 获取系统当前时间(本地时区）
* @param connector 日期分隔符，默认为'-'
* @returns 系统当前时间（本地时区），格式为YYYY-MM-DD HH24:Mi:SS
*/
export function getCurrentTime (connector="-"){
        let d = (new Date()).getTime().toString();
        return transTime(d, connector);
}

//时间类型转换 YYYY-MM-DD HH:MM:SS 
export function formatTime(date) {
    let Y = date.getFullYear()
    let M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    let D = date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate();
    let h = date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours();
    let m = date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes();
    let s = date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds();
    return Y + "-" + M + "-" + D + ' ' + h + ":" + m + ":" + s;
}

function isNumber(num) {
    let reg = /^[0-9]+.?[0-9]*$/;
    return reg.test(num);
}

export function transTimeWithZone(time, connector) {
    if (!time && time !== 0) return ''
    let offsetInMin = new Date().getTimezoneOffset()
    let utcTime = time + offsetInMin * 60000
    return transTime(utcTime.toString(), connector)
}

export function getVdnTimeZoneTime (time) {
    let Y = new Date(time).getFullYear();
    let M = new Date(time).getMonth() + 1;
    let D = new Date(time).getDate();
    let date = Y + "/" + M + "/" + D;
    return new Date(date).getTime();
}

export function getZeroTimeZone(time) {
    if (!time && !isNumber(time)) {
        return time;
    }
    let oDate = new Date();
    let timeZone = -oDate.getTimezoneOffset() / 60;
    let date;
    if (isNumber(time)) {
        date = new Date(parseInt(time));
    } else {
        date = new Date(time);
    }
    return preDays(-timeZone, "H", date);
}

export function getVdnTimeZone(time) {
    if (!time && !isNumber(time)) {
        return time;
    }
    let date;
    if (isNumber(time)) {
        date = new Date(parseInt(time));
    } else {
        date = new Date(time);
    }
    return preDays(0, "H", date);
}

/**
 * 获取指定偏移的日期字符串（YYYY/MM/DD HH24:Mi:SS）
 * @param num  偏移量。
 * @param method 偏移单位，Y - 年， M - 月，D - 日， H - 时，Mi - 分， S - 秒。
 * @param time  可选参数。如果是数字值，dateVal 表示指定日期与 1970 年 1 月 1 日午夜间全球标准时间（UTC）的毫秒数。
 *                    如果是字符串，则 dateVal 按照 Date.parse 方法中的规则进行解析。
 *                    默认取值为系统当前时间
 * @returns string 日期分割符为'-'的本地时区日期字符串
 */
function preDays(num, method, time) {
    const date = time ? new Date(time) : new Date()
    let dateTime = date.getTime()
    const yearStep = 1000 * 3600 * 24 * 365
    const monthStep = 1000 * 3600 * 24 * 30
    const dayStep = 1000 * 3600 * 24
    const hourStep = 1000 * 3600
    const minuteStep = 1000 * 60
    const secondStep = 1000

    switch (method) {
        case 'Y':
            dateTime = dateTime + num * yearStep
            break
        case 'M':
            dateTime = dateTime + num * monthStep
            break
        case 'D':
            dateTime = dateTime + num * dayStep
            break
        case 'H':
            dateTime = dateTime + num * hourStep
            break
        case 'Mi':
            dateTime = dateTime + num * minuteStep
            break
        case 'S':
            dateTime = dateTime + num * secondStep
            break
        default:
            break
    }
    return transTime(new Date(dateTime).toString())
}

export default {}
