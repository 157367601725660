export default {
  'aicc.loading': 'Cargando',
  'aicc.select': 'Seleccione',
  'aicc.numberOnly': 'Solo puede contener dígitos',
  'aicc.unorganized': 'No hay organización',
  'aicc.organized': 'Organización organizada',
  'aicc.columnConfig': 'Configuración de columna',
  'aicc.selectAll': 'Todas las columnas',
  'aicc.selectedColumns': 'Columnas seleccionadas',
  'aicc.selectedColumnsTip': ' (se pueden arrastrar para organizar)',
  'aicc.columnSettings': 'Configuración del encabezado de la tabla',
  'aicc.selectedInfo': '{n} elementos seleccionados',
  'aicc.advancedSearch': 'Búsqueda avanzada',
  'aicc.lengthLimit': 'La longitud no puede exceder {limit} caracteres',
  'aicc.ok': 'OK',
  'aicc.error': 'Error',
  'aicc.search': 'Buscar',
  'aicc.reset': 'Restablecer',
  'aicc.skip': 'Saltar',
  'aicc.goto': 'Ir al check out',
  'aicc.more': 'Más',
  'aicc.intRangeLimit': 'Introduzca un entero que va desde {min} a {max}',
  'aicc.MaxLength': 'Ingrese una cadena que consista en un máximo de 0 caracteres',
  'aicc.previous': 'Anterior',
  'aicc.next': 'Siguiente',
  'aicc.noData': 'Sin datos',
  'aicc.lengthMinLimit': 'Debe contener al menos {0} caracteres',
  'aicc.traceId.error':
  'TraceId: 0. Envíe la información TraceId al personal de O&M para la localización de fallas',
  'aicc.apibuilder.dev': 'Desarrollo de interfaz',
  'aicc.timeout.desc': 'ucidad de la sesión',
  'aicc.timeout.solution':
  'Se agotó el tiempo de espera de su inicio de sesión. Necesitas iniciar sesión de nuevo.',
  'aicc.input': 'Por favor ingrese',
}
