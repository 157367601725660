import { defineStore } from "pinia";

// 当前会话用户
export const useCurrentSessionUserStore = defineStore('currentSessionUser', {
  state: () => {
    return {
      eventsKey: '',
      sessionUserInfo: {}, // 用户会话信息
      qualitySessionUserInfo: {}, // 质检工作台用户会话信息
      pageUserInfo:{},//页面的用户信息
      chatContent: { // 会话内容
        chatsKey: '', // 会话key
        chatMsg: {}, // 前台展示的会话消息（msgModel）
        sendMsgToCCAgentParams: {} // 发给CTI的会话消息（s_user/msgModel/saveIndex/sendToB/arg）
      },
      agentContent: { // 只需要前台展示的内容
        chatsKey: '', // 会话key
        agentMsgModel: {}, // 坐席消息内容
        content: {}, //消息状态信息
      },
      closeChatInfo: '', // 关闭会话参数
      waitCorrectInfo: {}, // 待纠错信息
      mediaTransferInfo:'', // dy多媒体转移消息
      qualityCheckCloseInfo: '', // 关闭质检工作台参数
      qualityCheckConnectStatusInfo: '', // 质检工作台会话状态参数
      qualityCheckPageLoaded: '', // 质检工作台加载完毕  1、加载完毕  0、页面关闭
    }
  },
  actions: {
    // 切换当前用户
    changeCurrentSessionUserInfo(info, eventsKey = 'sessionUserInfo') {
      this.eventsKey = eventsKey
      this.sessionUserInfo = Object.assign({}, info)
    },
    // 切换当前用户
    changeQualityCheckSessionUserInfo(info, eventsKey = 'qualityCheckSessionUserInfo') {
      this.eventsKey = eventsKey
      this.qualitySessionUserInfo = Object.assign({}, info)
    },
    setNextPageCurrentUserInfo(info, eventsKey = 'userInfo') {
      this.eventsKey = eventsKey
      this.pageUserInfo = Object.assign({}, info)
    },
    // 修改会话内容
    changeChatContent(chatsKey, chatMsg, sendMsgToCCAgentParams, isQualityCheck = false, eventsKey = 'chatContent') {
      this.eventsKey = eventsKey
      this.chatContent = Object.assign({}, { chatsKey, chatMsg, sendMsgToCCAgentParams, isQualityCheck })
    },
    // 只需要前台展示的内容
    changeAgentContent(chatsKey, agentMsgModel, eventsKey = 'agentContent') {
      this.eventsKey = eventsKey
      this.agentContent = Object.assign({}, { chatsKey, agentMsgModel })
    },
    // 修改关闭会话
    changeCloseChatInfo(selectedUser, eventsKey = 'closeChatInfo') {
      this.eventsKey = eventsKey
      this.closeChatInfo = selectedUser
    },
    // 修改关闭会话
    changeTransferMediaChatInfo(selectedUser, eventsKey = 'changeTransferMediaChatInfo') {
      this.eventsKey = eventsKey
      this.mediaTransferInfo = selectedUser
    },
    // 修改待纠错信息
    changeWaitCorrectInfo(waitCorrectItem, chatsKey, eventsKey = 'waitCorrectInfo') {
      this.eventsKey = eventsKey
      this.waitCorrectInfo[chatsKey] = waitCorrectItem
    },
    // 重置eventsKey, 避免直接修改仓库的值而没有修改eventskey导致的多次监听
    changeEventsKeyIsNull() {
      this.eventsKey = ''
    },
    // 质检工作台关闭按钮
    changeQualityCheckCloseInfo(selectedUser, eventsKey = 'qualityCheckCloseInfo') {
      this.eventsKey = eventsKey
      this.qualityCheckCloseInfo = selectedUser
    },
    // 质检工作台连接状态修改
    changeQualityCheckConnectStatusInfo(selectedUser, eventsKey = 'qualityCheckConnectStatusInfo') {
      this.eventsKey = eventsKey
      this.qualityCheckConnectStatusInfo = selectedUser
    },
    // 质检工作台加载完毕
    changeQualityCheckPageLoadedInfo(isLoad, eventsKey = 'qualityCheckPageLoadedInfo') {
      this.eventsKey = eventsKey
      this.qualityCheckPageLoaded = isLoad
    },
    // 座席发送消息状态(Redis连接失败)
    changeAgentMsgStatue(chatsKey, agentMsgModel, content, eventsKey = 'agentMsgStatues') {
      this.eventsKey = eventsKey
      this.agentContent = Object.assign({}, {chatsKey, agentMsgModel, content})
    }
  }
})

// 座席侧的状态标识
export const useAgentStatusStore = defineStore('agentStatus', {
  state: () => {
    return {
      eventsKey: '',
      showPreview: false, // 展示用户输入状态标识
      showPreviewSwitch: false, // 展示用户输入状态开关
      showFooterType:0,
      userInfo:{}, // 用户信息
    }
  },
  actions: {
    // 切换用户输入状态标识
    changeShowPreview(showPreview, eventsKey = 'showPreview') {
      this.eventsKey = eventsKey
      this.showPreview = showPreview
    },
    // 切换用户输入状态开关
    changeShowPreviewSwitch(showPreviewSwitch, eventsKey = 'showPreviewSwitch') {
      this.eventsKey = eventsKey
      this.showPreviewSwitch = showPreviewSwitch
    },
    // 改变子页面
    changeTab(showPreviewSwitch, eventsKey = 'changeTab') {
      this.eventsKey = eventsKey
      this.showPreviewSwitch = showPreviewSwitch
    },
    // 改变聊天页面的页脚样式 0-展示输入框,1-展示reconnect,2-影藏所有
    changeFooterTab(showPreviewSwitch, eventsKey = 'changeFooterTab') {
      this.eventsKey = eventsKey
      this.showFooterType = showPreviewSwitch
    },
    updateUserInfo(userInfo, eventsKey = 'updateUserInfo') {
      this.eventsKey = eventsKey
      this.userInfo = userInfo
    },
  }
})

// 质检工作台
export const useQualityCheckStatusStore = defineStore('qualityCheckStatus', {
  state: () => {
    return {
      eventsKey: '',
      selectedUser: '',
      qualityCheckStatus: 1,
      changeUser: '',
      lastWhatsAppSendTime: 0,
    }
  },
  actions: {
    changeQualityCheckStatus(qualityCheckStatus, selectedUser, eventsKey = 'changeQualityCheckStatus') {
      this.eventsKey = eventsKey;
      this.selectedUser = selectedUser;
      this.qualityCheckStatus = qualityCheckStatus;
    },
    changeWhatsAppLastSendTime(changeUser, lastWhatsAppSendTime, eventsKey = 'changeWhatsAppLastSendTime') {
      this.eventsKey = eventsKey;
      this.changeUser = changeUser;
      this.lastWhatsAppSendTime = lastWhatsAppSendTime;
    }
  }
})

/**
 * dy多媒体页面提示消息
 *
 * @param msg 提示文本
 */
export const dyChatTopTipMsg = (msg) =>{
  dyChatTopTipMsgBytime(msg, 5000);
}

/**
 * dy多媒体页面提示消息
 *
 * @param msg 提示文本
 */
export const dyChatTopTipMsgBytime = (msg, time) =>{
  if (msg) {
    $("#dy_tip").show();
    $("#dy_tip").css("display", "flex");
    $("#dy_tipMsg").html(msg);
  } else {
    $("#dy_tipMsg").html("");
    $("#dy_tip").hide();
    $("#dy_tip").css("display", "none");
  }
  if (time) {
    setTimeout(function () {
      $("#dy_tipMsg").html("");
      $("#dy_tip").hide();
      $("#dy_tip").css("display", "none");
    }, time);
  }
}

export const AiccLog = (logMsg, callId) =>{
  if(callId){
    console.log("[AICCLOG", new Date().toLocaleString() ,"] [", CCAgent.agentId, "][", callId, "] :", logMsg);
  } else {
    console.log("[AICCLOG ", new Date().toLocaleString() ,"] [", CCAgent.agentId, "] :", logMsg);
  }
}

// 陌生呼叫
export const offlineCallSubmitStore = defineStore('offlineCallSubmit', {
  state: () => {
    return {
      eventsKey: '',
      offlineCallParam: {}, // 陌生呼叫信息
    }
  },
  actions: {
    // 改变陌生呼叫信息
    changeOfflineCallParam(offlineCallParam, eventsKey = 'offlineCallParam') {
      this.eventsKey = eventsKey
      this.offlineCallParam = offlineCallParam
    },
    changeOfflineCallEventsKeyIsNull() {
      this.eventsKey = ''
    }
  }
})

// 发送事件
export const useChatSendEventStore = defineStore('chatSendEvent', {
  state: () => {
    return {
      eventsKey: '',
      sendEmailEventName: '', // 发送邮件事件名称
      sendEmailEventParams: {}, // 邮件事件参数
      sendMultiEventName: '', // 发送多媒体事件名称
      sendMultiEventParams: {}, // 发送多媒体事件参数
      sendEventName: '', // 发送事件名称
      sendEventParams: '', // 发送事件参数
    }
  },
  actions: {
    // 修改邮件事件
    changeSendEmailEvent(emailEventName = '', emailEventParams = {}, eventsKey = 'sendEmailEventName') {
      this.eventsKey = eventsKey
      this.sendEmailEventParams = Object.assign({}, emailEventParams)
      this.sendEmailEventName = emailEventName
    },
    // 修改多媒体事件
    changeSendMultiEvent(multiEventName = '', multiEventParams = {}, eventsKey = 'sendMultiEventName') {
      this.eventsKey = eventsKey
      // 事件名称要放在后面，不然监听到的key值是参数
      this.sendMultiEventParams = Object.assign({}, multiEventParams)
      this.sendMultiEventName = multiEventName
    },
    // 发送事件名称
    changeSendEventName(eventName = '', eventParams = {}, eventsKey = 'sendEventName') {
      this.eventsKey = eventsKey
      this.sendEventName = eventName
      this.sendEventParams = eventParams
    }
  }
})

export const useMessageDataStore = defineStore('messageDataStore', {
  state: () => {
    return {
      sendEventName: '',
      data: {}
    };
  },
  getters: {},
  actions: {
    setMessageData(data, sendEventName) {
      this.sendEventName = sendEventName
      this.data = data;
    }
  }
})

export const useIntentStore = defineStore('intentStore', {
  state: () => {
    return {
      sendEventName: '',
      data: {}
    };
  },
  getters: {},
  actions: {
    setIntent(data, sendEventName) {
      this.sendEventName = sendEventName
      this.data = data;
    }
  }
})

export const useCaseEnableStore = defineStore('caseEnableStore', {
  state: () => {
    return {
      sendEventName: '',
      data: {}
    };
  },
  getters: {},
  actions: {
    setCaseEnable(data, sendEventName) {
      this.sendEventName = sendEventName
      this.data = data;
    }
  }
})

export const useCaseOpenStore = defineStore('caseOpenStore', {
  state: () => {
    return {
      sendEventName: '',
      data: {}
    };
  },
  getters: {},
  actions: {
    setCaseOpen(data, sendEventName) {
      this.sendEventName = sendEventName
      this.data = data;
    }
  }
})

export const useCaseDataStore = defineStore('caseDataStore', {
  state: () => {
    return {
      sendEventName: '',
      data: {}
    };
  },
  getters: {},
  actions: {
    setCaseData(data, sendEventName) {
      this.sendEventName = sendEventName
      this.data = data;
    }
  }
})


export const useGenerateAiSummaryStore = defineStore('generateAiSummaryStore', {
  state: () => {
    return {
      sendEventName: '',
      data: {}
    };
  },
  getters: {},
  actions: {
    setGenerateAiSummaryData(data, sendEventName) {
      this.sendEventName = sendEventName
      this.data = data;
    }
  }
})

export const useCaseForChatStore = defineStore('caseForChatStore', {
  state: () => {
    return {
      sendEventName: '',
      data: {}
    };
  },
  getters: {},
  actions: {
    setCaseCaseForChat(data, sendEventName) {
      this.sendEventName = sendEventName
      this.data = data;
    }
  }
})

export const useOnlineAiSwitchStore = defineStore('onlineAiSwitchStore', {
  state: () => {
    return {
      sendEventName: '',
      data: false
    };
  },
  getters: {},
  actions: {
    setOnlineAiSwitchData(data, sendEventName) {
      this.data = data;
      this.sendEventName = sendEventName
    }
  }
})

export const useAssistAiSwitchStore = defineStore('assistAiSwitchStore', {
  state: () => {
    return {
      sendEventName: '',
      data: {}
    };
  },
  getters: {},
  actions: {
    setAssistAiSwitchData(data, sendEventName) {
      this.data = data;
      this.sendEventName = sendEventName
    }
  }
})