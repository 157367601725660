export default {
  "cms.recorddrive.label.IPAddress_add": "IP-Adresse",
  "cms.recorddrive.label.button_add": "Neu",
  "cms.recorddrive.label.button_cacel": "Abbrechen",
  "cms.recorddrive.label.button_complete": "Abgeschlossen",
  "cms.recorddrive.label.button_edit": "Bearbeiten",
  "cms.recorddrive.field.number": "Nein.",
  "cms.agentMonitor.label.Idle_error": "Der Inspektor befindet sich nicht im Ruhezustand.",
  "cms.agentMonitor.label.batchSelectAgent": "Sie können nur einen Agenten auswählen.",
  "cms.agentMonitor.laber.hasbeenCheck": "Der Agent wurde inspiziert.",
  "cms.agentMonitor.laber.hasbeenMediaCheck": "Der Agent wurde durch eine Multimediasitzung überwacht.",
  "cms.agentMonitor.laber.stopMediaMonitor": "Die Überwachung von Multimediasitzungen wurde angehalten.",
  "cms.agentMonitor.laber.notInTalking": "Der Agent hat keinen Anruf. Aktualisieren Sie den Status und versuchen Sie es erneut.",
  "cms.agentMonitor.label.beinbusy_error": "Der inspizierte Agent ist beschäftigt.",
  "cms.agentMonitor.label.beinidle_error": "Der inspizierte Agent ist im Leerlauf.",
  "cms.agentMonitor.label.beinrest_error": "Der inspizierte Agent befindet sich im Ruhezustand.",
  "cms.agentMonitor.label.details": "Einzelheiten",
  "cms.agentMonitor.label.error": "Fehler",
  "cms.agentMonitor.label.forceidle_repeat": "Der Agent hat sich im Leerlauf befunden. Leerlauf nicht wieder anzeigen.",
  "cms.agentMonitor.label.forcebusy_repeat": "Der Agent hat sich beschäftigt gezeigt. Nicht wieder besetzt anzeigen.",
  "cms.agentMonitor.label.forcebusy_stateerror": "Der Agent ruht sich aus. Nicht beschäftigt anzeigen.",
  "cms.agentMonitor.label.forcebusy_talking": "Der Agent befindet sich in einem Gespräch. Nicht beschäftigt anzeigen.",
  "cms.agentMonitor.label.forceidle_talking": "Der Agent befindet sich in einem Gespräch. Leerlauf nicht anzeigen.",
  "cms.agentMonitor.message.operaFailedAgent": "Vorgang fehlgeschlagen. Der Agent wird inspiziert.",
  "cms.agentMonitor.label.forcebusy_error": "Es ist fehlgeschlagen, einen Agenten zwangsweise in den Besetzt-Status zu versetzen.",
  "cms.agentMonitor.label.forcebusy_success": "Ein Agent wurde erfolgreich zwangsweise in den Besetzt-Status versetzt.",
  "cms.agentMonitor.label.forcebusy_success_calling": "Ein Agent wird erfolgreich zwangsweise in den Besetzt-Status versetzt. Nach Ende des Anrufs wechselt der Agent in den Besetzt-Status.",
  "cms.agentMonitor.label.forceidle_success_calling": "Ein Agent wurde erfolgreich zwangsweise in den Ruhezustand versetzt. Nach Beendigung des Anrufs wechselt der Agent in den Ruhezustand.",
  "cms.agentMonitor.label.forceidle_error": "Es konnte nicht erzwungen werden, dass ein Agent in den Ruhezustand versetzt wird.",
  "cms.agentMonitor.label.forceidle_success": "Ein Agent wurde erfolgreich zwangsweise in den Ruhezustand versetzt.",
  "cms.agentMonitor.label.forcerelease_error": "Anruf konnte nicht gewaltsam freigegeben werden.",
  "cms.agentMonitor.label.forcerelease_success": "Ein Anruf wurde erfolgreich erzwungen.",
  "cms.agentMonitor.label.forcesignout_error": "Abmeldung als Agent fehlgeschlagen.",
  "cms.agentMonitor.label.forcesignout_success": "Ein Agent meldet sich erfolgreich ab.",
  "cms.agentMonitor.label.gateway_error": "Die Gateway-Schnittstelle konnte nicht aufgerufen werden.",
  "cms.agentMonitor.label.insets_error": "Einfügen fehlgeschlagen",
  "cms.agentMonitor.label.insets_success": "Einfügen erfolgreich",
  "cms.agentMonitor.label.whisper_error": "Flüstern fehlgeschlagen",
  "cms.agentMonitor.label.whisper_success": "Flüsternder Erfolg",
  "cms.agentMonitor.label.cancelwhisper_error": "Das Flüstern konnte nicht abgebrochen werden.",
  "cms.agentMonitor.label.cancelwhisper_success": "Flüstern erfolgreich abgebrochen.",
  "cms.agentMonitor.label.switch_error": "Die Umschaltung ist fehlgeschlagen.",
  "cms.agentMonitor.label.switch_success": "Die Umstellung ist erfolgreich.",
  "cms.agentMonitor.label.intercept_error": "Abfangen fehlgeschlagen",
  "cms.agentMonitor.label.intercept_success": "Abfangen erfolgreich.",
  "cms.agentMonitor.label.listen_error": "Hören schlägt fehl",
  "cms.agentMonitor.label.listen_success": "Das Zuhören ist erfolgreich.",
  "cms.agentMonitor.label.cancelInsert_error": "Das Einfügen konnte nicht abgebrochen werden.",
  "cms.agentMonitor.label.cancelInsert_success": "Das Einfügen wurde erfolgreich abgebrochen.",
  "cms.agentMonitor.label.cancelListening_error": "Abhören konnte nicht abgebrochen werden.",
  "cms.agentMonitor.label.cancelListening_success": "Das Zuhören wurde erfolgreich abgebrochen.",
  "cms.agentMonitor.label.been_inspected": "Der Agent wird überprüft. Versuchen Sie es später erneut.",
  "cms.agentmonitor.label.qcstate_error": "Abbruch fehlgeschlagen. Auf dem ausgewählten Agenten wird kein QK-Vorgang ausgeführt.",
  "cms.agentMonitor.label.nosignin_error": "Der inspizierte Agent hat sich nicht angemeldet.",
  "cms.agentMonitor.label.switchNotice": "Diese Funktion verwenden, wenn der aktuelle Agent abgehört oder unterbrochen wird.",
  "cms.agentMonitor.message.operaFailed": "Vorgang fehlgeschlagen. Eine Inspektion oder ein Anruf ist im Gange.",
  "cms.agentMonitor.label.interceptNotice": "Diese Funktion verwenden, wenn der aktuelle Agent abgehört, unterbrochen oder geflüstert wird.",
  "cms.agentMonitor.label.qcinspector_error": "Der Vorgang kann nicht ausgeführt werden, da Sie kein Inspektor sind.",
  "cms.agentMonitor.label.permission_error": "Sie haben nicht die Befugnis.",
  "cms.agentMonitor.label.reset": "Zurücksetzen",
  "cms.agentMonitor.label.search": "Suche",
  "cms.agentMonitor.label.selectAgent": "Wählen Sie einen Agenten aus",
  "cms.agentMonitor.label.success": "Erfolg",
  "cms.agentMonitor.label.talk_error": "Der ausgewählte Agent befindet sich nicht im Sprechstatus.",
  "cms.agentMonitor.label.talkingerror": "Der ausgewählte Agent befindet sich nicht in einem Gespräch oder in einem Multimedia-Gespräch.",
  "cms.agentMonitor.label.talking_error": "Der inspizierte Agent befindet sich im Sprechzustand.",
  "cms.agentMonitor.label.warning": "Warnung",
  "cms.agentmonitor.label.account": "Dienstkonto",
  "cms.agentmonitor.label.terminalStatus": "Anmeldestatus des Terminals",
  "cms.agentmonitor.label.terminalStatusRefresh": "Aktualisierungsstatus",
  "cms.agentmonitor.label.terminalStatusFix": "Status wiederherstellen",
  "cms.agentmonitor.label.accountCode": "Geben Sie ein Dienstkonto ein",
  "cms.agentmonitor.label.agent": "Agent",
  "cms.agentmonitor.label.agentId": "Agenten-ID",
  "cms.agentmonitor.label.agentName": "Agentenname",
  "cms.agentmonitor.label.agentStatus": "Agentenstatus",
  "cms.agentmonitor.label.answering": "Antworten",
  "cms.agentmonitor.label.busy": "Beschäftigt erzwingen",
  "cms.agentmonitor.label.busyState": "Ausgelastet",
  "cms.agentmonitor.label.enterID": "Geben Sie eine Agenten-ID ein",
  "cms.agentmonitor.label.forceSignOut": "Herauszwingen",
  "cms.agentmonitor.label.header": "Tabellenkopfzeile festlegen",
  "cms.agentmonitor.label.idle": "Leerlauf erzwingen",
  "cms.agentmonitor.label.idleState": "Leerlauf",
  "cms.agentmonitor.label.whisper": "Flüstern",
  "cms.agentmonitor.label.cancelwhisper": "Flüstern abbrechen",
  "cms.agentmonitor.label.intercept": "abfangen",
  "cms.agentmonitor.label.switch": "Schalter",
  "cms.agentmonitor.label.insets": "Einfügen",
  "cms.agentmonitor.label.adjust": "Zusammenfassung",
  "cms.agentmonitor.label.learnState": "Lernen",
  "cms.agentmonitor.label.listening": "Hören",
  "cms.agentmonitor.label.occupationState": "Besetzt",
  "cms.agentmonitor.label.offline": "Offline",
  "cms.agentmonitor.label.operation": "Betrieb",
  "cms.agentmonitor.label.possessingSkills": "Anmeldewarteschlange",
  "cms.agentmonitor.label.preoccupationState": "beunruhigt",
  "cms.agentmonitor.label.qualityInspection": "Qualitätsprüfung",
  "cms.agentmonitor.label.queueAdjustment": "Warteschlangen anpassen",
  "cms.agentmonitor.label.readjustmentState": "Anpassung",
  "cms.agentmonitor.label.release": "Freigabe erzwingen",
  "cms.agentmonitor.label.restState": "Ruhe",
  "cms.agentmonitor.label.sendNotes": "Notizen senden",
  "cms.agentmonitor.label.signOut": "Offline",
  "cms.agentmonitor.label.statusDuration": "Dauer des Agentenstatus",
  "cms.agentmonitor.label.alarmState": "Alarm überwachen",
  "cms.agentmonitor.label.talkingState": "Gespräch",
  "cms.agentmonitor.label.total": "Agenten insgesamt",
  "cms.agentmonitor.label.syncScreenMonitor": "Monitorbildschirm",
  "cms.agentMonitor.label.queryNum": "Anmeldenummer festlegen",
  "cms.agentMonitor.label.queryNumSuccess": "Anmeldenummer erfolgreich festgelegt",
  "cms.agentMonitor.label.queryNumFailed": "Das Festlegen der Anmeldenummer ist fehlgeschlagen",
  "cms.agentMonitor.label.qcSipNum": "Festlegen der Anmeldenummer des aktuellen Benutzers",
  "cms.agentMonitor.label.updateSip_error": "Die Nummer ist falsch geschrieben",
  "cms.agentMonitor.label.updateSip_repeat": "Die Nummer wurde zugewiesen",
  "cms.agentMonitor.label.querynum_error": "Anmeldenummer konnte nicht aktualisiert werden",
  "cms.agentMonitor.label.querynum_success": "Telefonnummer:",
  "cms.agentmonitor.label.monitorState": "Prüfstatus",
  "cms.agentmonitor.label.all": "Alle",
  "cms.agentmonitor.label.noListenedAndInserted": "Nicht geprüft",
  "cms.agentmonitor.label.listenedAndInserted": "Überprüft",
  "cms.agentmonitor.label.noAlarm": "Kein Alarm",
  "cms.agentmonitor.label.haveAlarm": "Alarm",
  "cms.agentmonitor.label.alarmDetail": "Einzelheiten",
  "cms.agentmonitor.label.mutilMediaMonitor": "Multimediasitzungsüberwachung",
  "cms.agentmonitor.label.stopMutilMediaMonitor": "Sitzungsüberwachung wird beendet",
  "cms.agentmonitor.label.showAlarmDetail": "Anzeigen von Alarmdetails",
  "cms.agentmonitor.label.showAlarmCallId": "Anrufkennung",
  "cms.agentmonitor.label.showAlarmAgentNo": "Agenten-ID",
  "cms.agentmonitor.label.showAlarmTime": "Zeit",
  "cms.agentmonitor.label.showAlarmContent": "Dialoginhalt",
  "cms.agentmonitor.label.showAlarmSensitive": "Sensibles Wort",
  "cms.agentmonitor.label.showAlarmEmotionType": "Gefühlstyp",
  "cms.agentmonitor.label.dayStatistic": "Statistiken von heute",
  "cms.agentmonitor.label.countAndDuration": "Zeiten/Dauer",
  "cms.agentmonitor.label.idleStatistic": "Leerlauf",
  "cms.agentmonitor.label.Performance": "Leistung",
  "cms.agentmonitor.label.signInDetail": "Details zum SignIn",
  "cms.agentmonitor.syncScreenMonitor.getVrpIpError": "Der überprüfte Agent hat sich nicht beim VRC-Client angemeldet",
  "cms.agentmonitor.syncScreenMonitor.agentStatus": "Bitte melden Sie sich vor der Überwachung an",
  "cms.sendnote.field.cancel": "Abbrechen",
  "cms.sendnote.message.checkInput": "Ungültige Zeichen",
  "cms.sendnote.field.notecontent": "Inhalt",
  "cms.sendnote.field.ok": "OK",
  "cms.sendnote.title.selectAgents": "Ausgewählter Agent",
  "cms.sendnote.title.tips": "Informationen",
  "cms.sendnote.message.tipsError": "Notiz konnte nicht gesendet werden",
  "cms.sendnote.message.tipsNoteContent": "Geben Sie die Notizinformationen ein",
  "cms.sendnote.message.tipsSuccess": "Die Notiz wurde erfolgreich gesendet",
  "cms.skilladjustment.field.inputskill": "Geben Sie einen Skill-Warteschlangennamen ein",
  "cms.skilladjustment.field.seach": "Suche",
  "cms.skilladjustment.field.selectskill": "Wählen Sie eine Warteschlange aus",
  "cms.skilladjustment.field.skillid": "Skill-Warteschlangen-ID",
  "cms.skilladjustment.field.skillname": "Skill-Warteschlangenname",
  "cms.skilladjustment.field.skilltype": "Skill-Warteschlangentyp",
  "cms.skilladjustment.title.tips": "Informationen",
  "cms.skilladjustment.message.tipsError": "Die Skill-Warteschlange konnte nicht angepasst werden",
  "cms.skilladjustment.message.tipsOutSide": "Es können maximal 10 Agenten gleichzeitig angepasst werden.",
  "cms.skilladjustment.message.tipsNotMonitor": "Kein QA-Spezialist. Die Skill-Warteschlange konnte nicht angepasst werden.",
  "cms.skilladjustment.message.notMatchAgent": "Der angepasste Skill-Warteschlangentyp stimmt nicht mit dem Agententyp überein",
  "cms.skilladjustment.message.tipsSelectSkill": "Wählen Sie eine Skill-Warteschlange aus",
  "cms.skilladjustment.message.tipsSuccess": "Die Skill-Warteschlange wurde erfolgreich angepasst",
  "cms.agentmonitor.label.detail": "Details zur Agentenüberwachung",
  "cms.qualitycheck.label.create": "Erstellen",
  "cms.qualitycheck.label.delete": "Löschen",
  "cms.qualitycheck.label.edit": "Bearbeiten",
  "cms.qualitycheck.label.editMember": "Gruppenmitglied bearbeiten",
  "cms.qualitycheck.label.view": "Ansicht",
  "cms.qualitycheck.field.selected": "Ausgewählt",
  "cms.qualitycheck.field.unselected": "UnSelected",
  "cms.qualitycheck.title.delete": "Löschfehler",
  "cms.qualitycheck.message.deleteinfo": "Löschen fehlgeschlagen",
  "cms.qualitycheck.message.error_errorinfo": "Speichern fehlgeschlagen",
  "cms.qualitycheck.title.saveError": "Fehler",
  "cms.qualitycheck.label.cancel": "Abbrechen",
  "cms.qualitycheck.label.configQcInformation": "Qualitätsprüfungsinformationen konfigurieren",
  "cms.qualitycheck.label.createusergroup": "UserGroup erstellen",
  "cms.qualitycheck.label.editusergroup": "UserGroup bearbeiten",
  "cms.qualitycheck.label.editusergroupmember": "Gruppenmitglied bearbeiten",
  "cms.qualitycheck.label.groupname": "GroupName",
  "cms.qualitycheck.label.input": "Bitte geben Sie etwas ein",
  "cms.qualitycheck.label.save": "Speichern",
  "cms.qualitycheck.label.viewusergroup": "UserGroup anzeigen",
  "cms.qualitycheck.title.deleteSuccess": "Löschung erfolgreich",
  "cms.qualitycheck.message.deleteSuccess": "Erfolgreich gelöscht",
  "cms.qualitycheck.title.saveSuccess": "Erfolg",
  "cms.qualitycheck.message.saveSuccess": "Speichern erfolgreich",
  "cms.qualitycheck.label.userGroup": "Benutzergruppe",
  "cms.qualitycheck.label.userGroupMemberConfig": "Benutzergruppenmitglieder konfigurieren",
  "cms.qualitycheck.label.qcRelationConfig": "Inspektionsbeziehungen",
  "cms.qualitycheck.label.queryQcRelation": "Abfrageprüfungsbeziehungen",
  "cms.qualitycheck.label.unselectedGroup": "Nicht ausgewählte Benutzergruppen",
  "cms.qualitycheck.label.selectedGroup": "Ausgewählte Benutzergruppen",
  "cms.qualitycheck.field.groupName": "Benutzergruppe",
  "cms.qualitycheck.placeholder.userGroupName": "Geben Sie eine Benutzergruppe ein",

  "cms.qualitycheck.label.trafficGroup": "Verkehrsgruppe",
  "cms.qualitycheck.label.qualityInspectionGroup": "Qualitätsinspektionsgruppe",

  "cms.recorddrive.label.recorddrivename": "Name des Laufwerksbuchstabens",
  "cms.recorddrive.title.recorddrivenameconfig": "Volumekonfiguration",
  "cms.recorddrive.field.configSftpAccount": "SFTP-Konto",
  "cms.recorddrive.field.configSftpAccountConfirmPwd": "Kennwort bestätigen für den SFTP-Server",
  "cms.recorddrive.field.configSftpAccountPwd": "Kennwort des SFTP-Servers",
  "cms.recorddrive.field.configSftpPath": "Dateipfad-",
  "cms.recorddrive.field.configSftpPathError": "Der Dateispeicherpfad darf nicht die Zeichenfolge './' enthalten.",
  "cms.recorddrive.title.sftpIdentityCheck": "Identitätsprüfung",
  "cms.recorddrive.message.sftpIdentityChecktips": "Das Konto oder Kennwort des SFTP-Servers wurde geändert. Geben Sie Ihr aktuelles Konto und Kennwort ein, um die Informationssicherheit zu gewährleisten.",
  "cms.recorddrive.placeholder.sftpIdentityChecktips": "Geben Sie Ihr aktuelles Konto und Kennwort ein.",
  "cms.recorddrive.field.sftpIpadress": "SFTP-IP-Adresse",
  "cms.recorddrive.label.sftpPort": "SFTP-Serverport",
  "cms.recorddrive.field.sftpPort": "Anschlussnummer.",
  "cms.recorddrive.title.sftpServerConfig": "SFTP-Serverkonfiguration",
  "cms.recorddrive.field.storageLimit": "Niedrigste Speichergrenze (MB)",
  "cms.vcallecenter.field.adminworkno": "Administrator-ID",
  "cms.vcallecenter.title.authentication": "Identität überprüfen",
  "cms.vcallecenter.field.confirmnewpassword": "Kennwort bestätigen",
  "cms.vcallecenter.field.confirmwaspassword": "Kennwort bestätigen",
  "cms.vcallecenter.field.curcountpassword": "Aktuelles Kontokennwort",
  "cms.vcallecenter.field.enternewpassword": "Geben Sie ein neues Kennwort ein",
  "cms.vcallecenter.field.enterwaspassword": "Kennwort",
  "cms.vcallecenter.title.modifyinfo": "Kennwort ändern",
  "cms.vcallecenter.title.modifyinfo2": "Kennwort konfigurieren",
  "cms.vcallecenter.title.modifyadministratorpassword": "CTI-Administratorkennwort",
  "cms.vcallecenter.title.modifybmspassword": "BMS-Kennwort ändern",
  "cms.callecenter.field.setbmspwd": "CCBMS-Kennwort",
  "cms.callecenter.field.confirmbmspwd": "CCBMS-Kennwort bestätigen",
  "cms.vcallecenter.title.modifyconfigurationinterfacepassword": "CCBCS-Schnittstellenkennwort in VDN festlegen",
  "cms.vcallecenter.message.configurationinterfacenopassword": "Das Konfigurationskennwort ist nicht konfiguriert.",
  "cms.vcallecenter.message.syncagentfail": "Der Agent konnte nicht synchronisiert werden",
  "cms.vcallecenter.message.syncrolefail": "Rolle konnte nicht synchronisiert werden",
  "cms.vcallecenter.message.syncskillfail": "Die Fähigkeiten konnten nicht synchronisiert werden",
  "cms.vcallecenter.message.modifyadminpasswordfail": "Passwort konnte nicht geändert werden",
  "cms.vcallecenter.message.currentacountpasderror": "Das Passwort des aktuellen Kontos ist falsch",
  "cms.vcallecenter.message.modifyadminpasswordsuccess": "Passwort erfolgreich geändert",
  "cms.voiceinspection.field.batchDownload": "Batch-Download",
  "cms.voiceinspection.field.reset": "Zurücksetzen",
  "cms.voiceinspection.field.search": "Abfrage",
  "cms.voiceinspection.message.downloadFailed": "Aufzeichnung konnte nicht heruntergeladen werden",
  "cms.voiceinspection.message.downloadSucceed": "Die Aufzeichnung wurde erfolgreich heruntergeladen",
  "cms.record-playback.voice.playing": "Abspielen",
  "cms.record-playback.voice.pause": "Pause",
  "cms.voiceinspection.field.audioPlay": "Aufnahmevorsprechen",
  "cms.voiceinspection.field.videoPlay": "Videowiedergabe",
  "cms.voiceinspection.field.rate": "Wiedergaberate",
  "cms.voiceinspection.field.download": "Herunterladen",
  "cms.voiceinspection.field.agentId": "Agent",
  "cms.voiceinspection.field.beginTime": "Startzeit der Aufzeichnung",
  "cms.voiceinspection.field.queryBeginTime": "Startzeit",
  "cms.voiceinspection.field.callId": "Aufzeichnungs-SN",
  "cms.voiceinspection.field.callType": "Anruftyp",
  "cms.voiceinspection.field.calleeNo": "Angerufene Nummer",
  "cms.voiceinspection.field.callerNo": "Anrufernummer",
  "cms.voiceinspection.field.currentSkillId": "Skill-Warteschlange",
  "cms.voiceinspection.field.endTime": "Endzeit der Aufzeichnung",
  "cms.voiceinspection.field.queryEndTime": "Endzeit",
  "cms.voiceinspection.field.operat": "Betrieb",
  "cms.voiceinspection.field.serviceId": "Unternehmensart",
  "cms.record-playback.callType0": "Eingehender Sprachanruf",
  "cms.record-playback.callType1": "Ausgehender Sprachanruf",
  "cms.record-playback.callType2": "Ausgehender IVR-Anruf",
  "cms.record-playback.callType3": "OBS-Ausgehender Anruf",
  "cms.voiceinspection.field.textChat": "Textchat",
  "cms.voiceinspection.field.clickToCall": "Voice Click to Dial (Wählen durch Klicken mit der Stimme)",
  "cms.voiceinspection.field.videoClickToCall": "Video-Klicken zum Wählen",
  "cms.voiceinspection.field.callbackRequest": "Rückrufanforderung",
  "cms.voiceinspection.field.ordinaryVoiceCall": "Stimme",
  "cms.voiceinspection.field.predictOutbound": "Prognostizierender ausgehender Verkehr",
  "cms.voiceinspection.field.preViewOutbound": "Vorschau für ausgehende Nachrichten",
  "cms.voiceinspection.field.messageCall": "Nachrichtenanruf",
  "cms.voiceinspection.field.webphoneVideo": "Videoanruf durch Klicken-zum-Wählen",
  "cms.voiceinspection.field.phoneVideo": "Gemeinsamer Videoanruf",
  "cms.voiceinspection.field.socialChat": "Multimedia",
  "cms.voiceinspection.field.nonRealTimeCall": "Nicht-Echtzeitanruf",
  "cms.voiceinspection.field.video": "Video",
  "cms.voiceinspection.title.tips": "Informationen",
  "cms.voiceinspection.video.tolong.info": "Laden Sie das aufgenommene Video herunter, um es abzuspielen, da es lang ist",
  "cms.voiceinspection.message.selectVoice": "Wählen Sie eine Aufzeichnung aus",
  "cms.voiceinspection.title.error": "Fehler",
  "cms.voiceinspection.message.errorContent": "Die Ressource konnte nicht geladen werden. Überprüfen Sie die Konfiguration.",
  "cms.voiceinspection.message.playsuccess": "Die Ansage wurde erfolgreich abgespielt",
  "cms.voiceinspection.message.playfailure": "Die Ansage konnte nicht abgespielt werden",
  "cms.voiceinspection.message.errorTime": "Datumsabfrage kann Monate nicht überschreiten",
  "cms.voiceinspection.message.frequentOperat": "Die Operation kommt zu häufig vor. Versuchen Sie es später erneut.",
  "cms.voiceinspection.message.frequentDownload": "Der Download erfolgt zu häufig. Versuchen Sie es später erneut.",
  "cms.voiceinspection.message.frequentPlay": "Das Spiel ist zu häufig. Versuchen Sie es später erneut.",
  "cms.voiceinspection.message.frequentExport": "Wird zu häufig exportiert. Versuchen Sie es später erneut.",
  "cms.voiceinspection.message.invalidParam": "Ungültiger Eingabeparameter",
  "cms.voiceinspection.message.invalidQcRelation": "Die Inspektionsbeziehung stimmt nicht überein",
  "cms.voiceinspection.message.noRecordFound": "Es wurde keine passende Aufzeichnung gefunden",
  "cms.voiceinspection.message.noSftpFound": "Die Abfrage der Verbindungsinformationen des SFTP-Servers ist fehlgeschlagen",
  "cms.voiceinspection.message.downloadVoiceRecordFailed": "Aufzeichnungsdatei konnte nicht vom SFTP-Server heruntergeladen werden.",
  "cms.agent.message.duplicatepwd": "Das neue Kennwort ist dasselbe wie das aktuelle.",
  "cms.agent.message.modifypasswordfailed": "Passwort konnte nicht geändert werden",
  "cms.agent.message.modifypasswordhintdetail": "Geben Sie das Kennwort ein",
  "cms.agent.message.modifypasswordsuccess": "Das Kennwort wurde erfolgreich geändert",
  "cms.agent.title.modifypassword": "Anmeldekennwort ändern",
  "cms.callcenter.message.configcallcenter": "Callcenter konfigurieren",
  "cms.callcenter.field.deletefailure": "Löschfehler",
  "cms.callcenter.message.deletefailed": "Löschen fehlgeschlagen.",
  "cms.callcenter.message.createfailed": "Erstellung fehlgeschlagen.",
  "cms.callcenter.message.updatefailed": "Aktualisierung fehlgeschlagen",
  "cms.callcenter.message.sameletterovermaxnum": "Einem Laufwerksbuchstaben können maximal acht Datensätze hinzugefügt werden.",
  "cms.callcenter.field.savefailed": "Fehler",
  "cms.callcenter.field.cancel": "Abbrechen",
  "cms.callcenter.field.finish": "Abgeschlossen",
  "cms.callcenter.field.reset": "Zurücksetzen",
  "cms.callcenter.message.selectcallcent": "Wählen Sie ein Callcenter aus.",
  "cms.callcenter.message.selectccgatewayservers": "Wählen Sie einen CC-Gateway-Server aus.",
  "cms.callcenter.field.deletesuccess": "Löschung erfolgreich",
  "cms.callcenter.message.deletesuccess": "Erfolgreich gelöscht.",
  "cms.callcenter.message.updatesuccess": "Aktualisiert erfolgreich.",
  "cms.callcenter.field.savesuccess": "Erfolg",
  "cms.callcenter.message.createsuccess": "Erstellt erfolgreich.",
  "cms.callcenterinstanceslist.title.callcenter": "Anrufzentrale",
  "cms.callcenterinstanceslist.message.ccgatewayServerBatchdeleteconfim": "Möchten Sie wirklich alle ausgewählten CC-Gateway-Server löschen?",
  "cms.callcenterinstanceslist.message.ccgatewayServerSingledeleteconfim": "Möchten Sie den ausgewählten CC-Gateway-Server wirklich löschen?",
  "cms.callcenterinstanceslist.field.ccGatewayIp": "IP des CC-Gateways",
  "cms.callcenterinstanceslist.field.ccGatewayPort": "CC-Gateway-Anschluss",
  "cms.callcenterinstanceslist.message.adminWorkNoError": "Die Startnummer der Administrator-Mitarbeiter-ID muss zwischen 55001 und 59999 liegen.",
  "cms.callcenterinstanceslist.message.alreadyInuse": "Das Mitarbeiter-ID-Segment wurde verwendet.",
  "cms.callcenterinstanceslist.message.ccIdError": "Die Callcenter-Nummer wurde verwendet.",
  "cms.callcenterinstanceslist.message.ccIdUpdateError": "Die Callcenter-ID beim Bearbeiten des Callcenters nicht ändern",
  "cms.callcenterinstanceslist.message.ccNameUpdateError": "Ändern Sie den Callcenter-Namen nicht, wenn Sie das Callcenter bearbeiten",
  "cms.callcenterinstanceslist.message.ccNameUsed": "Der Name des Callcenters wurde verwendet.",
  "cms.callcenterinstanceslist.message.createSuccess": "Das Callcenter wurde erfolgreich erstellt.",
  "cms.callcenterinstanceslist.message.createFailed": "Callcenter konnte nicht erstellt werden.",
  "cms.callcenterinstanceslist.message.error": "Fehler",
  "cms.callcenterinstanceslist.field.interfacePassword": "ConfigInterface-Kennwort (CCBCS)",
  "cms.callcenterinstanceslist.message.ivrNumsError": "Die Anzahl der IVR-Kanäle muss größer sein als die Summe der Anzahl der TTS-Kanäle und der Anzahl der ASR-Kanäle.",
  "cms.callcenterinstanceslist.label.nextStep": "Weiter",
  "cms.callcenterinstanceslist.message.nodeIdCheckError": "Stellen Sie sicher, dass die eingegebenen CTI-Knoteninformationen ID, uapId und Name eindeutig sind.",
  "cms.callcenterinstanceslist.message.odfConfigError": "Die Konfigurationsinformationen des ODF-Dienstes sind falsch.",
  "cms.callcenterinstanceslist.field.passwordConfirm": "Kennwort bestätigen",
  "cms.callcenterinstanceslist.message.resourceError": "Die geänderten Ressourceninformationen sind kleiner als die tatsächliche Anzahl der Callcenter.",
  "cms.callcenterinstanceslist.title.success": "Erfolg",
  "cms.callcenterinstanceslist.field.sysAdminPassword": "CTI-Administratorkennwort",
  "cms.callcenterinstanceslist.field.systemAdmin": "CTI-Administrator-ID",
  "cms.callcenterinstanceslist.title.callCenter": "Anrufzentrale",
  "cms.callcenterinstanceslist.message.updateFailed": "Callcenter konnte nicht geändert werden.",
  "cms.callcenterinstanceslist.message.updateSuccess": "Das Callcenter wurde erfolgreich geändert.",
  "cms.callcenterinstanceslist.message.videoCallNumsError": "Die maximale Anzahl gleichzeitiger Videoanrufe muss größer oder gleich der Anzahl der Videoplätze sein.",
  "cms.callcenterinstanceslist.message.voiceCallNumsError": "Die maximale Anzahl gleichzeitiger Sprachanrufe muss größer oder gleich der Summe aus der Anzahl der Sprachplätze und der Anzahl der IVR-Kanäle sein.",
  "cms.callcenterinstanceslist.title.warning": "Warnung",
  "cms.callcenterinstanceslist.message.workNoCheckError": "Die Startnummer der Arbeits-ID muss kleiner als die Endnummer sein.",
  "cms.callcenterinstanceslist.message.workNoError": "Die Anfangsmitarbeiter-ID muss zwischen 101 und 50000 liegen.",
  "cms.callcenterinstanceslist.label.callCenterRecordDrive": "Buchstabe des Call Center-Aufzeichnungslaufwerks",
  "cms.callcenterinstanceslist.label.recordDrive": "Laufwerksbuchstabe wird aufgenommen",
  "cms.callcenterinstanceslist.label.batchAdd": "Batch hinzufügen",
  "cms.callcenterinstanceslist.field.tenantName": "Name des Mieters",
  "cms.callcenterinstanceslist.title.baseInfo": "Grundlegende Informationen",
  "cms.callcenterinstanceslist.field.ccBcsIp": "IP-Adresse des CCBCS LB",
  "cms.callcenterinstanceslist.field.ccBcsPort": "CCBCS-LB-Anschluss",
  "cms.callcenterinstanceslist.field.ccBmsIp": "IP-Adresse des CCBMS LB",
  "cms.callcenterinstanceslist.field.ccBmsPort": "CCBMS-LB-Anschluss",
  "cms.callcenterinstanceslist.field.ccId": "Callcenter-ID",
  "cms.callcenterinstanceslist.field.ccName": "Name des Callcenters",
  "cms.callcenterinstanceslist.field.cmsIp": "IP-Adresse des CMS LB",
  "cms.callcenterinstanceslist.field.cmsPort": "CMS LB-Anschluss",
  "cms.callcenterinstanceslist.label.create": "Neu",
  "cms.callcenterinstanceslist.label.delete": "Löschen",
  "cms.callcenterinstanceslist.message.deleteConfirmMessage": "Möchten Sie das ausgewählte Callcenter und die zugehörige Konfiguration wirklich löschen?",
  "cms.callcenterinstanceslist.title.deleteconfim": "Löschvorgang bestätigen",
  "cms.callcenterinstanceslist.field.gwIp": "IP des CC-Gateways",
  "cms.callcenterinstanceslist.field.gwPort": "CC-Gateway-Anschluss",
  "cms.callcenterinstanceslist.field.operate": "Betrieb",
  "cms.callcenterinstanceslist.label.refresh": "Aktualisieren",
  "cms.callcenterinstanceslist.label.search": "Abfrage",
  "cms.callcenterinstanceslist.label.synchronize": "Synchronisierung",
  "cms.callcenterinstanceslist.field.ctiversion": "CTI-VERSION",
  "cms.callcenterinstanceslist.field.adminCount": "CTI-Administrator-ID",
  "cms.callcenterinstanceslist.field.tenantSpaceName": "Administratorkonto",
  "cms.callcenterinstanceslist.label.vCallCenter": "Virtuelles Callcenter",
  "cms.callcenterinstanceslist.field.vccId": "ID des virtuellen Callcenters",
  "cms.callcenterinstanceslist.field.vdnName": "VDN-Name",
  "cms.callcenterinstanceslist.message.passLengthValidateFailed": "Ein Kennwort muss mindestens acht Zeichen enthalten.",
  "cms.callcenterinstanceslist.message.passValidateFailed": "Das Kennwort muss mindestens drei der folgenden Typen enthalten: Großbuchstaben, Kleinbuchstaben, Ziffern und folgende Sonderzeichen characters: {'~! @ # $ % ^ & * () -",
  "cms.callcenterinstanceslist.message.passValidateContinuityFailed": "Das Kennwort darf keine drei aufeinander folgenden identischen Zeichen enthalten.",
  "cms.callcenterinstanceslist.message.syncPassValidateFailed": "Der Wert muss Großbuchstaben, Kleinbuchstaben, Ziffern und Sonderzeichen enthalten `~!@#$%^&*()-_=+|[{}];: '\", <. >/?",
  "cms.callcenterinstanceslist.label.save": "Speichern",
  "cms.callcenterinstanceslist.message.specialStrValidateFailed": "Der Name des Callcenters darf keine Sonderzeichen enthalten.",
  "cms.callcenterinstanceslist.message.wasPsdLengthRangeValidateFailed": "Das Kennwort sollte zwischen 16 und 32 Zeichen lang sein",
  "cms.callcenterinstanceslist.message.uapPsdLengthRangeValidateFailed": "Das Kennwort sollte zwischen 6 und 32 Zeichen lang sein",
  "cms.callcenterinstanceslist.message.passWordLengthValidateFailed": "Das Kennwort muss 8 bis 20 Zeichen enthalten.",
  "cms.callcenterinstanceslist.label.assignDeveloper": "Mitarbeitender Entwickler",
  "cms.callcenterinstanceslist.field.developer": "Mitarbeitender Entwickler",
  "cms.callcenterinstanceslist.message.selectdeveloper": "Wählen Sie einen Entwickler aus.",
  "cms.callcenterinstanceslist.title.nodemodeselect": "Auswahlmodus",
  "cms.callcenterinstanceslist.field.nodemodesingle": "Einzelmodus",
  "cms.callcenterinstanceslist.field.nodemodepool": "Pool-Modus",
  "cms.callcenterinstanceslist.field.open": "Aktivieren",
  "cms.callcenterinstanceslist.field.close": "Deaktivieren",
  "cms.callcenterinstanceslist.title.chooseFeature": "Funktionsauswahl",
  "cms.callcenterinstanceslist.field.ctiIndexMonitor": "Überwachung der CTI-Systemanzeige",
  "cms.callcenterinstanceslist.field.uapIndexMonitor": "Überwachung der UAP-Systemanzeige",
  "cms.callcenterinstanceslist.field.kafkaPushSwitch": "Pushen von KAFKA-Daten",
  "cms.callcenterinstanceslist.field.ccStatus": "Status des Callcenters",
  "cms.callcenterinstanceslist.field.backupcc": "DR-Callcenter",
  "cms.callcenterinstanceslist.field.normalStatus": "Normal",
  "cms.callcenterinstanceslist.field.faultStatus": "Fehlerhaft",
  "cms.callcenterinstanceslist.message.maxNumber": "Es können maximal 10 Callcenter hinzugefügt werden.",
  "cms.failurecall.field.accessCode": "Zugangscode",
  "cms.failurecall.field.callSequenceNumber": "S/N anrufen",
  "cms.failurecall.field.queueName": "Warteschlangenname",
  "cms.failurecall.field.reset": "Zurücksetzen",
  "cms.failurecall.field.search": "Suche",
  "cms.failurecall.field.userPhone": "Servicenummer",
  "cms.failurecall.field.waitTime": "Wartedauer",
  "cms.failurecall.message.currentFailureCall": "Es gibt ",
  "cms.failurecall.message.person": "Anrufe wurden während des Einreihens in die Warteschlange abgebrochen.",
  "cms.failurecall.placeholder.accessCodeInput": "Geben Sie einen Zugangscode ein",
  "cms.failurecall.placeholder.userPhoneInput": "Geben Sie eine Servicenummer ein",
  "cms.vcallecenter.title.success": "Erfolg",
  "cms.vcallecenter.message.syncSuccess": "Die virtuellen Callcenter wurden erfolgreich synchronisiert.",
  "cms.vcallecenter.message.syncLoading": "Operation läuft.“ Versuchen Sie es später erneut.",
  "cms.vcallecenter.title.failed": "Fehler",
  "cms.vcallecenter.message.syncFailed": "Synchronisierung fehlgeschlagen",
  "cms.monitordetail.field.account": "Konto",
  "cms.monitordetail.field.adjustCount": "Agent tritt in den Vorbereitungsstatus ein",
  "cms.monitordetail.field.adjustDuration": "Anordnen der Dauer",
  "cms.monitordetail.field.agentReleaseCount": "Agent gibt Anrufe frei",
  "cms.monitordetail.field.answerCount": "Angenommene Anrufe",
  "cms.monitordetail.field.audio": "Audio/Video",
  "cms.monitordetail.field.avgTalkingDuration": "Durchschnittliche Anrufdauer",
  "cms.monitordetail.field.busy": "Ausgelastet",
  "cms.monitordetail.field.busyCount": "Agent wechselt in den Beschäftigt-Status",
  "cms.monitordetail.field.busyDuration": "Gebuchte Dauer",
  "cms.monitordetail.field.busydurationavg": "Durchschnittliche Belegungsdauer",
  "cms.monitordetail.field.calling": "Gespräch",
  "cms.monitordetail.field.conferencecount": "Drei-Parteien-Anrufe",
  "cms.monitordetail.field.holdcount": "Anrufe zurückhalten",
  "cms.monitordetail.field.holdduration": "Aufbewahrungsdauer",
  "cms.monitordetail.field.holddurationavg": "Durchschnittliche Haltedauer",
  "cms.monitordetail.field.idle": "Leerlauf",
  "cms.monitordetail.field.idlecount": "Agent geht in den Leerlaufzustand über",
  "cms.monitordetail.field.innerforward": "Intern weitergeleitete Anrufe",
  "cms.monitordetail.field.intercallcount": "Interne Anrufe",
  "cms.monitordetail.field.media": "Multimedia",
  "cms.monitordetail.field.mediatype": "Medientyp anrufen",
  "cms.monitordetail.field.noanswercount": "Anrufe, die lange Zeit nicht beantwortet wurden",
  "cms.monitordetail.field.outboundcount": "Erfolgreiche ausgehende Anrufe",
  "cms.monitordetail.field.outerforward": "Extern weitergeleitete Anrufe",
  "cms.monitordetail.field.redirectcount": "Umgeleitete Anrufe",
  "cms.monitordetail.field.rest": "Ruhe",
  "cms.monitordetail.field.restcount": "Ruhe",
  "cms.monitordetail.field.restduration": "Ruhedauer",
  "cms.monitordetail.field.restoutcount": "Zeitüberschreitungspausen",
  "cms.monitordetail.field.restoutduration": "Dauer der Ruhezeitüberschreitung",
  "cms.monitordetail.field.role": "Agentenrolle",
  "cms.monitordetail.field.signinskill": "Anmeldewarteschlange",
  "cms.monitordetail.field.sipNumber": "Anmeldeanruf",
  "cms.monitordetail.field.skill": "Skill-Warteschlange",
  "cms.monitordetail.field.totaltalkingcount": "Gesamtanzahl der Anrufe",
  "cms.monitordetail.field.totaltalkingduration": "Gesamte Anrufdauer",
  "cms.monitordetail.field.detail": "Agentendetails",
  "cms.monitordetail.field.information": "Grundlegende Informationen",
  "cms.monitordetail.field.duration": "Statistiken zur Dauer",
  "cms.monitordetail.field.count": "Statistiken zur Anzahl der Male",
  "cms.monitordetail.title.statistics": "Statistik",
  "cms.agentmonitor.label.autoRefresh": "Automatische Aktualisierung",
  "cms.agentmonitor.label.pageRefreshTime": "Daten aktualisiert",
  "cms.qcrelationconf.field.accountCode": "Dienstkonto",
  "cms.qcrelationconf.field.accountName": "Agentenname",
  "cms.qcrelationconf.field.addGroupMember": "Gruppenmitglied hinzufügen",
  "cms.qcrelationconf.field.agentGroup": "Agentengruppe",
  "cms.qcrelationconf.field.cancel": "Abbrechen",
  "cms.qcrelationconf.field.delete": "Löschen",
  "cms.qcrelationconf.field.deleteGroupMember": "Gruppenmitglied löschen",
  "cms.qcrelationconf.field.group": "Gruppe",
  "cms.qcrelationconf.field.groupInfo": "Grundlegende Informationen zur Benutzergruppe",
  "cms.qcrelationconf.field.groupMemberInfo": "Informationen zu Benutzergruppenmitgliedern",
  "cms.qcrelationconf.field.groupName": "Benutzergruppenname",
  "cms.qcrelationconf.field.groupType": "Benutzergruppentyp",
  "cms.qcrelationconf.placeholder.groupType": "Bitte auswählen",
  "cms.qcrelationconf.title.confirm": "Bestätigen",
  "cms.qcrelationconf.field.ok": "Ok",
  "cms.qcrelationconf.field.operation": "Aktion",
  "cms.qcrelationconf.field.operator": "Gemeinsamer Agent",
  "cms.qcrelationconf.field.configAdmin": "Konfigurationsadministrator",
  "cms.qcrelationconf.field.outgoingAgent": "Callout-Mitarbeiter",
  "cms.qcrelationconf.field.qcGroup": "Qualitätsprüfgruppe",
  "cms.qcrelationconf.field.qualityInspection": "Qualitätsprüfer",
  "cms.qcrelationconf.field.remark": "Anmerkung",
  "cms.qcrelationconf.field.reset": "Zurücksetzen",
  "cms.qcrelationconf.field.role": "Rolle",
  "cms.qcrelationconf.field.search": "Abfrage",
  "cms.qcrelationconf.field.workNo": "Agenten-ID",
  "cms.qcrelationconf.message.addSuccess": "Erfolgreich hinzugefügt",
  "cms.qcrelationconf.message.deleteSuccess": "Löschvorgang erfolgreich",
  "cms.qcrelationconf.message.isDeleteGroupInfo": "Bitte bestätigen Sie, ob die Benutzergruppe gelöscht werden soll",
  "cms.qcrelationconf.message.isDeleteGroupMember": "Bitte bestätigen Sie, ob das Gruppenmitglied gelöscht werden soll",
  "cms.qcrelationconf.message.paramOutOfRange": "Die Eingabe darf nicht mehr als 100 Zeichen umfassen",
  "cms.qcrelationconf.message.queryFailed": "Abfrage fehlgeschlagen",
  "cms.qcrelationconf.message.queryFailedLimit": "Die Anzahl der Datensätze pro Seite muss größer als 0 und kleiner als 101 sein.",
  "cms.qcrelationconf.message.queryParamNull": "Agenten-ID und Benutzergruppenname dürfen nicht gleichzeitig leer sein",
  "cms.qcrelationconf.message.selectAgent": "Wählen Sie den zu löschenden Agenten aus",
  "cms.qcrelationconf.message.selectGroup": "Wählen Sie die Benutzergruppe aus, die gelöscht werden soll.",
  "cms.qcrelationconf.message.selectGroupType": "Bitte wählen Sie zuerst den Benutzergruppentyp aus",
  "cms.qcrelationconf.message.specialChar": "Ungültiges Zeichen",
  "cms.qcrelationconf.message.updateSuccess": "Aktualisierung erfolgreich",
  "cms.qcrelationconf.placeholder.pleaseInput": "Bitte geben Sie etwas ein",
  "cms.qcrelationconf.placeholder.workNo": "Bitte geben Sie die Agenten-ID ein",
  "cms.qcrelationconf.placeholder.groupName": "Gruppenname",
  "cms.qcrelationconf.title.error": "Fehler",
  "cms.qcrelationconf.title.success": "Erfolg",
  "cms.qcrelationconf.title.warning": "Warnung",
  "cms.qcrelationconf.placeholder.workName": "Geben Sie einen Agentennamen ein",
  "cms.qcrelationconf.placeholder.accountCode": "Geben Sie ein Geschäftskonto ein.",
  "cms.qcrelationconf.field.qcGroupName": "Inspektorengruppe",
  "cms.qcrelationconf.field.qcWorkNo": "Inspektor-ID",
  "cms.qcrelationconf.field.qcAccountName": "Name des Inspektors",
  "cms.qcrelationconf.field.qcCcRoleName": "Rolle des Inspektors",
  "cms.qcrelationconf.field.agentGroupName": "Inspizierte Gruppe",
  "cms.qcrelationconf.field.agentWorkNo": "ID der inspizierten Partei",
  "cms.qcrelationconf.field.agentAccountName": "Name der inspizierten Partei",
  "cms.qcrelationconf.field.agentCcRoleName": "Geprüfte Parteirolle",
  "cms.recorddrive.placeholder.deleteRecorddrive": "Wählen Sie den zu löschenden Laufwerksbuchstaben aus",
  "cms.recorddrive.placeholder.batchdeleteconfim": "Möchten Sie wirklich alle ausgewählten Laufwerksbuchstaben löschen?",
  "cms.recorddrive.placeholder.singledeleteconfim": "Möchten Sie den ausgewählten Laufwerkbuchstaben wirklich löschen?",
  "cms.tenant.message.adminpassword": "Kennwort",
  "cms.tenant.message.adminpasswordagain": "Kennwort bestätigen",
  "cms.tenant.message.pwdCheckmsg": "Die beiden Passwörter sind unterschiedlich.",
  "cms.tenant.message.tenantspaceOk": "OK",
  "cms.indexmonitor.title.callCenterDayMonitor": "Heutige Statistiken zu Callcenter-Indikatoren",
  "cms.indexmonitor.title.callCenterDayTrend": "Der heutige Trend des Callcenters",
  "cms.indexmonitor.title.vdnNowMonitor": "Echtzeit-Überwachungsstatistiken auf dem VDN während der Ausführung",
  "cms.indexmonitor.title.vdnDayMonitor": "Heutige Statistiken zu VDN-Indikatoren",
  "cms.indexmonitor.title.vdnDayTrend": "Der heutige Trend des VDN",
  "cms.indexmonitor.title.filteringIndicator": "Filterindikator",
  "cms.indexmonitor.title.error": "Fehler",
  "cms.indexmonitor.title.success": "Erfolg",
  "cms.indexmonitor.field.confirm": "Bestätigen",
  "cms.indexmonitor.field.cancel": "Abbrechen",
  "cms.indexmonitor.message.warningFiltering": "Wählen Sie mindestens einen Indikator aus",
  "cms.indexmonitor.message.updateSuccess": "Aktualisierung erfolgreich",
  "cms.indexmonitor.message.updateFailed": "Aktualisierung fehlgeschlagen",
  "cms.indexmonitor.field.chooseAll": "Alle auswählen",
  "cms.indexmonitor.field.totalCallNum": "Anrufe insgesamt",
  "cms.indexmonitor.message.totalCallNum": "Anzahl der eingehenden Anrufe + Anzahl der ausgehenden Anrufe",
  "cms.indexmonitor.field.totalQueueCallNum": "Gesamtzahl der Anrufe in der Warteschlange",
  "cms.indexmonitor.message.totalQueueCallNum": "Anzahl der Anrufe, die in Skill-Warteschlangen eingereiht sind und auf Agentendienste warten",
  "cms.indexmonitor.field.totalConnectCallNum": "Gesamtzahl verbundener Anrufe",
  "cms.indexmonitor.field.totalAgentConnectCallNum": "Anzahl der Agenten im Gesprächsstatus",
  "cms.indexmonitor.message.totalAgentCallNumDesc": "Gesamtzahl der verbundenen Anrufe (statistischer Zeitraum: aktuelle Anmeldung)",
  "cms.indexmonitor.message.totalConnectCallNum": "Anzahl der verbundenen eingehenden Anrufe + Anzahl der verbundenen ausgehenden Anrufe",
  "cms.indexmonitor.field.totalAbandonedCallNum": "Gesamtzahl verlorener Anrufe",
  "cms.indexmonitor.message.totalAbandonedCallNum": "Gesamtanzahl der Anrufe – Gesamtanzahl der verbundenen Anrufe",
  "cms.indexmonitor.field.inboundCallNum": "Eingehende Anrufe",
  "cms.indexmonitor.message.inboundCallNum": "Anzahl der manuellen eingehenden Anrufe + Anzahl der eingehenden IVR-Anrufe",
  "cms.indexmonitor.field.inboundConnectNum": "Verbundene eingehende Anrufe",
  "cms.indexmonitor.message.inboundConnectNum": "Anzahl der verbundenen eingehenden manuellen Anrufe + Anzahl der verbundenen eingehenden IVR-Anrufe",
  "cms.indexmonitor.field.outboundCallNum": "Ausgehende Anrufe",
  "cms.indexmonitor.message.outboundCallNum": "Anzahl der manuellen ausgehenden Anrufe + Anzahl der ausgehenden IVR-Anrufe",
  "cms.indexmonitor.field.outboundConnectNum": "Verbundene ausgehende Anrufe",
  "cms.indexmonitor.field.outboundConnectNumDesc": "cms.report.field.outOquireyNumDesc=Anzahl der Male, die ein Agent ausgehende Anrufe verbindet.",
  "cms.report.field.outOccupyNumDesc": "Anzahl der Male, die ein Agent ausgehende Anrufe verbindet.",
  "cms.report.field.threePartyCallCountDesc": "Anzahl der Drei-Parteien-Anrufe eines Agenten.",
  "cms.indexmonitor.message.outboundConnectNum": "Anzahl der verbundenen manuellen ausgehenden Anrufe + Anzahl der verbundenen ausgehenden IVR-Anrufe",
  "cms.indexmonitor.field.agentInboundNum": "Manuelle eingehende Anrufe",
  "cms.indexmonitor.message.agentInboundNum": "Anzahl der eingehenden Anrufe, die Kompetenzwarteschlangen oder Agenten zugewiesen sind",
  "cms.indexmonitor.field.agentInboundConnectNum": "Verbundene manuelle eingehende Anrufe",
  "cms.indexmonitor.message.agentInboundConnectNum": "Anzahl der manuell eingehenden Anrufe, die von Agenten beantwortet werden",
  "cms.indexmonitor.field.agentOutboundNum": "Manuelle ausgehende Anrufe",
  "cms.indexmonitor.message.agentOutboundNum": "Anzahl der manuellen ausgehenden Anrufe.",
  "cms.indexmonitor.field.agentOutboundConnectNum": "Verbundene manuelle ausgehende Anrufe",
  "cms.indexmonitor.message.agentOutboundConnectNum": "Anzahl der manuellen ausgehenden Anrufe, die von den angerufenen Teilnehmern beantwortet werden.",
  "cms.indexmonitor.field.inboundConnectRate": "Verbundene eingehende Anrufe",
  "cms.indexmonitor.message.inboundConnectRate": "Prozentualer Anteil der verbundenen eingehenden Anrufe an der Anzahl der eingehenden Anrufe.",
  "cms.indexmonitor.field.inboundAbandonRate": "Abgelehnte eingehende Anrufe",
  "cms.indexmonitor.message.inboundAbandonRate": "Der Prozentsatz der Anzahl der eingehenden Anrufe, die nicht verbunden wurden, zur Anzahl der eingehenden Anrufe. Wenn die Anzahl der eingehenden Anrufe 0 ist, ist der Indikatorwert 0.",
  "cms.indexmonitor.field.outboundConnectRate": "Verbundene ausgehende Anrufe",
  "cms.indexmonitor.field.outboundAbandonRate": "Abgelehnte ausgehende Anrufe",
  "cms.indexmonitor.message.outboundConnectRate": "Prozentualer Anteil der verbundenen ausgehenden Anrufe an der Anzahl der ausgehenden Anrufe.",
  "cms.indexmonitor.message.outboundAbandonRate": "Prozentualer Anteil der aufgegebenen ausgehenden Anrufe an der Anzahl der ausgehenden Anrufe.",
  "cms.indexmonitor.field.agentBoundConnectRate": "Verbundene manuelle eingehende Anrufe",
  "cms.indexmonitor.message.agentBoundConnectRate": "Prozentualer Anteil der manuell verbundenen Anrufe an der Anzahl der manuell eingehenden Anrufe.",
  "cms.indexmonitor.field.agentBoundAbandonRate": "Aufgegebene manuelle eingehende Anrufe",
  "cms.indexmonitor.message.agentBoundAbandonRate": "100 – Rate der verbundenen eingehenden manuellen Anrufe",
  "cms.indexmonitor.field.abandonInThree": "Anrufe in drei Sekunden abgebrochen",
  "cms.indexmonitor.message.abandonInThree": "Prozentualer Anteil der innerhalb von 3 Sekunden abgebrochenen Anrufe an der Gesamtzahl der aufgegebenen Anrufe.",
  "cms.indexmonitor.field.abandonInFive": "Anrufe in 5 Sekunden abgebrochen",
  "cms.indexmonitor.message.abandonInFive": "Prozentualer Anteil der innerhalb von 5 Sekunden abgebrochenen Anrufe an der Gesamtzahl der aufgegebenen Anrufe.",
  "cms.indexmonitor.field.abandonInTen": "Anrufe in 10s abgebrochen",
  "cms.indexmonitor.message.abandonInTen": "Prozentualer Anteil der Anrufe, die innerhalb von 10 Sekunden abgebrochen wurden, an der Gesamtzahl der aufgegebenen Anrufe.",
  "cms.indexmonitor.field.abandonInThirty": "Anrufe in 30er Jahren aufgegeben",
  "cms.indexmonitor.message.abandonInThirty": "Prozentualer Anteil der Anrufe, die innerhalb von 30s abgebrochen wurden, an der Gesamtzahl der aufgegebenen Anrufe.",
  "cms.indexmonitor.field.abandonInSixty": "Anrufe in 60er Jahren aufgegeben",
  "cms.indexmonitor.message.abandonInSixty": "Prozentualer Anteil der Anrufe, die innerhalb von 60 Jahren abgebrochen wurden, an der Gesamtzahl der aufgegebenen Anrufe.",
  "cms.indexmonitor.field.agentConnectInThree": "Anrufe manuell in 3 Sekunden verbunden",
  "cms.indexmonitor.message.agentConnectInThree": "Prozentualer Anteil der Anrufe, die innerhalb von 3 Sekunden manuell verbunden werden, an der Gesamtzahl der manuell verbundenen Anrufe.",
  "cms.indexmonitor.field.agentConnectInFive": "Anrufe werden in 5 Sekunden manuell verbunden",
  "cms.indexmonitor.message.agentConnectInFive": "Prozentualer Anteil der Anrufe, die innerhalb von 5 Sekunden manuell verbunden werden, an der Gesamtzahl der manuell verbundenen Anrufe.",
  "cms.indexmonitor.field.agentConnectInTen": "Anrufe werden in 10 Sekunden manuell verbunden",
  "cms.indexmonitor.message.agentConnectInTen": "Prozentualer Anteil der Anrufe, die innerhalb von 10 Sekunden manuell verbunden werden, an der Gesamtzahl der manuell verbundenen Anrufe.",
  "cms.indexmonitor.field.agentConnectInThirty": "Anrufe werden in 30 Sekunden manuell verbunden",
  "cms.indexmonitor.message.agentConnectInThirty": "Prozentualer Anteil der Anrufe, die innerhalb von 30 Sekunden manuell verbunden werden, an der Gesamtzahl der manuell verbundenen Anrufe.",
  "cms.indexmonitor.field.agentConnectInSixty": "Anrufe werden in 60s manuell verbunden",
  "cms.indexmonitor.message.agentConnectInSixty": "Prozentualer Anteil der manuell verbundenen Anrufe innerhalb von 60 Sekunden an der Gesamtzahl der manuell verbundenen Anrufe.",
  "cms.indexmonitor.field.agentConnectAfterInTen": "Anrufe werden nach 10 Sekunden manuell verbunden",
  "cms.indexmonitor.message.agentConnectAfterInTen": "Prozentualer Anteil der nach 10 Sekunden manuell verbundenen Anrufe an der Gesamtzahl der manuell verbundenen Anrufe.",
  "cms.indexmonitor.field.agentConnectAfterInSixty": "Anrufe werden nach 60 Sekunden manuell verbunden",
  "cms.indexmonitor.message.agentConnectAfterInSixty": "Prozentualer Anteil der nach 60 Sekunden manuell verbundenen Anrufe an der Gesamtzahl der manuell verbundenen Anrufe.",
  "cms.indexmonitor.field.durationAvgVdnInbound": "Durchschnittliche Dauer eingehender Anrufe",
  "cms.indexmonitor.message.durationAvgVdnInbound": "Gesamtdauer, die eingehende Anrufe den VDN belegen/Gesamtzahl eingehender Anrufe",
  "cms.indexmonitor.field.durationAvgVdnOutbound": "Durchschnittliche Dauer des ausgehenden Anrufs",
  "cms.indexmonitor.message.durationAvgVdnOutbound": "Gesamtdauer, die ausgehende Anrufe den VDN belegen/Gesamtzahl der ausgehenden Anrufe",
  "cms.indexmonitor.field.durationAvgConnect": "Durchschnittliche Anrufdauer",
  "cms.indexmonitor.message.durationAvgConnect": "Gesamtdauer des verbundenen Anrufs/Gesamtanzahl der verbundenen Anrufe",
  "cms.indexmonitor.field.durationAvgWait": "Durchschnittliche Wartezeit für eingehende Anrufe",
  "cms.indexmonitor.message.durationAvgWait": "Durchschnittliche Wartezeit eingehender Anrufe",
  "cms.indexmonitor.field.totalAgentNum": "Agenten insgesamt",
  "cms.indexmonitor.message.totalAgentNum": "Gesamtzahl der aktuellen Agenten",
  "cms.indexmonitor.field.totalTalkingNum": "Agenten in Verbindung",
  "cms.indexmonitor.message.totalTalkingNum": "Anzahl der Agenten, die Anrufe bearbeiten",
  "cms.indexmonitor.field.totalLoginNum": "Anmeldeagenten",
  "cms.indexmonitor.message.totalLoginNum": "Anzahl der Mitarbeiter, die sich beim Callcenter angemeldet haben",
  "cms.indexmonitor.message.totalLoginNumDesc": "Anzahl der Agenten, die sich im Callcenter angemeldet haben und nur eingehende Anrufe bearbeiten",
  "cms.indexmonitor.field.totalLogoutNum": "Agenten abmelden",
  "cms.indexmonitor.message.totalLogoutNum": "Anzahl der Service Desk-Mitarbeiter, die sich nicht beim Call Center angemeldet haben und sich im Offline-Status befinden",
  "cms.indexmonitor.field.totalBusyNum": "Agenten im beschäftigten Zustand",
  "cms.indexmonitor.message.totalBusyNum": "Anzahl der Agenten im Besetzt-Status",
  "cms.indexmonitor.field.totalIdeaNum": "Agenten im Ruhezustand",
  "cms.indexmonitor.message.totalIdeaNum": "Anzahl der Agenten, die sich im Leerlauf befinden und Anrufe sofort bearbeiten können",
  "cms.indexmonitor.field.totalLearningNum": "Agenten im Lernstatus",
  "cms.indexmonitor.message.totalLearningNum": "Anzahl der Agenten im Lernstatus",
  "cms.indexmonitor.field.totalAwcNum": "Agenten im Nachbearbeitungsstatus",
  "cms.indexmonitor.message.totalAwcNum": "Anzahl der Agenten im Abschlussstatus nach der Geschäftsabwicklung",
  "cms.indexmonitor.field.totalAnsweringNum": "Agenten im Antwortstatus",
  "cms.indexmonitor.message.totalAnsweringNum": "Anzahl der Agenten, deren Telefone klingeln",
  "cms.indexmonitor.field.totalRestNum": "Agenten im Ruhezustand",
  "cms.indexmonitor.message.totalRestNum": "Anzahl der Agenten im Ruhezustand",
  "cms.indexmonitor.field.callNum": "Anzahl der Anrufe",
  "cms.indexmonitor.field.realTimeCalls": "Echtzeitanrufe",
  "cms.indexmonitor.field.callsOfTheDay": "Anrufe des Tages",
  "cms.indexmonitor.field.callRate": "Anrufrate",
  "cms.indexmonitor.field.duration": "Dauer",
  "cms.indexmonitor.field.agentNum": "Anzahl der Agenten",
  "cms.indexmonitor.field.agentName": "Name des Mieters",
  "cms.indexmonitor.field.pageDataUpdateTime": "Daten aktualisiert:",
  "cms.indexmonitor.field.ccName": "Name des Callcenters",
  "cms.indexmonitor.title.selectCcName": "Callcenter auswählen",
  "cms.indexmonitor.title.selectAgentName": "Mieter auswählen",
  "cms.indexmonitor.field.all": "Alle",
  "cms.indexmonitor.field.status": "Status",
  "cms.indexmonitor.label.reset": "Zurücksetzen",
  "cms.indexmonitor.label.search": "Suche",
  "cms.indexmonitor.label.previous": "Zurück",
  "cms.indexmonitor.label.next": "Weiter",
  "cms.indexmonitor.field.indicatorName": "Anzeige",
  "cms.indexmonitor.field.description": "Beschreibung",
  "cms.indexmonitor.label.CommercialUse": "Gewerbsmäßige Nutzung",
  "cms.indexmonitor.label.noncommercial": "Probeweise kommerzielle Nutzung",
  "cms.indexmonitor.label.callCenterNode": "Callcenter-Knoten",
  "cms.indexmonitor.field.ivrCallNum": "Anfragen eingehender IVR-Anrufe",
  "cms.indexmonitor.message.ivrCallNum": "Anzahl der Anrufe, für die automatische Dienste von IVR-Geräten erbracht werden",
  "cms.indexmonitor.field.ivrTotalCallNum": "Gesamte eingehende IVR-Anrufe",
  "cms.indexmonitor.message.ivrTotalCallNum": "Anzahl der Anrufe, die dem IVR zugewiesen und vollständig vom IVR bearbeitet werden",
  "cms.indexmonitor.field.ivrInboundNum": "Verbundene eingehende IVR-Anrufe",
  "cms.indexmonitor.message.ivrInboundNum": "Anzahl der eingehenden IVR-Anrufanfragen, deren Anrufdauer größer als 0 ist",
  "cms.indexmonitor.field.ivrOutboundNum": "Verbundene ausgehende IVR-Anrufe",
  "cms.indexmonitor.message.ivrOutboundNum": "Anzahl der ausgehenden IVR-Anrufe, die vom angerufenen Teilnehmer beantwortet wurden",
  "cms.indexmonitor.field.ivrInboundFlowOut": "Weitergeleitete eingehende IVR-Anrufe",
  "cms.indexmonitor.message.ivrInboundFlowOut": "Anzahl der Anrufe, die an ein anderes Gerät weitergeleitet werden, nachdem das IVR eine Ansage abgespielt hat",
  "cms.indexmonitor.field.ivrInboundUserAbort": "Freigegebene eingehende IVR-Anrufe",
  "cms.indexmonitor.message.ivrInboundUserAbort": "Anzahl der Anrufe, die proaktiv von einem Benutzer im IVR beendet wurden",
  "cms.indexmonitor.field.ivrInboundConnectRate": "Verbundene eingehende IVR-Anrufe",
  "cms.indexmonitor.message.ivrInboundConnectRate": "Prozentualer Anteil der verbundenen eingehenden IVR-Anrufe an der Anzahl der eingehenden IVR-Anrufanfragen",
  "cms.indexmonitor.field.ivrDurationAvgConnect": "Durchschnittliche IVR-Anrufdauer",
  "cms.indexmonitor.message.ivrDurationAvgConnect": "Gesamtdauer der IVR-Anrufe/Anzahl der IVR-Anrufe",
  "cms.indexmonitor.title.ivrDayMonitor": "Heutige Statistiken zu IVR-Indikatoren",
  "cms.indexmonitor.title.ivrDayTrend": "Der aktuelle Trend des IVR",
  "cms.indexmonitor.message.mediaTypeMetion": "Das Filterelement für den Medientyp ist nur für die KPIs des aktuellen Tages und den Trend des aktuellen Tages gültig.",
  "cms.indexmonitor.title.ivrNowMonitor": "Echtzeit-IVR-Überwachung",
  "cms.indexmonitor.label.callOutIVR": "Anzahl der ausgehenden IVR-Online-Anrufe",
  "cms.indexmonitor.label.callInIVR": "Anzahl der eingehenden IVR-Online-Anrufe",
  "cms.indexmonitor.field.callOutIVR": "Ausgehende IVR-Onlineanrufe",
  "cms.indexmonitor.field.callInIVR": "Eingehende IVR-Online-Anrufe",
  "cms.indexmonitor.field.agentInCallCount": "Anzahl der eingehenden Anrufe von Online-Agenten",
  "cms.indexmonitor.message.InCallAgentCount": "Anzahl der verbundenen eingehenden Anrufe",
  "cms.indexmonitor.field.agentOutCallCount": "Anzahl der ausgehenden Anrufe von Online-Agenten",
  "cms.indexmonitor.field.queueInCallCount": "Anzahl der eingehenden Online-Anrufe, die in die Warteschlange eingereiht wurden",
  "cms.indexmonitor.field.queueOutCallCount": "Anzahl der ausgehenden Online-Anrufe, die in die Warteschlange gestellt wurden",
  "cms.indexmonitor.field.totalInCallCount": "Eingehende Online-Anrufe",
  "cms.indexmonitor.message.agentInCallCount": "Anzahl der verbundenen eingehenden Anrufe.(Multimediasitzungen nicht eingeschlossen)",
  "cms.indexmonitor.message.agentConnectIn3Rate": "Prozentualer Anteil der manuell verbundenen Anrufe innerhalb von 3 Sekunden (einschließlich 3 Sekunden) an der Gesamtzahl der Anrufe.",
  "cms.indexmonitor.message.agentConnectIn5Rate": "Prozentualer Anteil der manuell verbundenen Anrufe innerhalb von 5 Sekunden (einschließlich 5 Sekunden) an der Gesamtzahl der Anrufe.",
  "cms.indexmonitor.message.agentConnectIn10Rate": "Prozentualer Anteil der manuell verbundenen Anrufe innerhalb von 10 Sekunden (einschließlich 10 Sekunden) an der Gesamtzahl der Anrufe.",
  "cms.indexmonitor.message.agentConnectIn30Rate": "Prozentualer Anteil der manuell verbundenen Anrufe innerhalb von 30 Sekunden (einschließlich 30 Sekunden) an der Gesamtzahl der Anrufe.",
  "cms.indexmonitor.message.agentConnectIn60Rate": "Prozentualer Anteil der manuell verbundenen Anrufe innerhalb von 60 Sekunden (einschließlich 60 Sekunden) an der Gesamtzahl der Anrufe.",
  "cms.indexmonitor.message.agentAbandonIn3Rate": "Prozentualer Anteil der aufgegebenen Anrufe innerhalb von 3 Sekunden an der Gesamtzahl der aufgegebenen Anrufe",
  "cms.indexmonitor.message.agentAbandonIn5Rate": "Prozentualer Anteil der aufgegebenen Anrufe innerhalb von 5 Sekunden an der Gesamtzahl der aufgegebenen Anrufe",
  "cms.indexmonitor.message.agentAbandonIn10Rate": "Prozentualer Anteil der aufgegebenen Anrufe innerhalb von 10 Sekunden an der Gesamtzahl der aufgegebenen Anrufe",
  "cms.indexmonitor.message.agentAbandonIn30Rate": "Prozentualer Anteil der aufgegebenen Anrufe innerhalb von 30 Sekunden an der Gesamtzahl der aufgegebenen Anrufe",
  "cms.indexmonitor.message.agentAbandonIn60Rate": "Prozentualer Anteil der aufgegebenen Anrufe innerhalb von 60 Sekunden an der Gesamtzahl der aufgegebenen Anrufe",
  "cms.indexmonitor.message.agentConnectedIn3NDesc": "Gesamtanzahl der Anrufe, deren Antwortdauer 3 Sekunden oder mehr beträgt",
  "cms.indexmonitor.message.agentConnectedIn5NDesc": "Gesamtanzahl der Anrufe, deren Antwortdauer 5 Sekunden oder mehr beträgt",
  "cms.indexmonitor.message.agentConnectedIn10NDesc": "Gesamtanzahl der Anrufe, deren Antwortdauer 10 Sekunden oder mehr beträgt",
  "cms.indexmonitor.message.agentConnectedIn30NDesc": "Gesamtanzahl der Anrufe, deren Antwortdauer 30 Sekunden oder mehr beträgt",
  "cms.indexmonitor.message.agentConnectedIn60NDesc": "Gesamtanzahl der Anrufe, deren Antwortdauer 60 Sekunden oder mehr beträgt",
  "cms.indexmonitor.message.agentAbandonIn3NDesc": "Gesamtzahl der Abbrüche mit einer Antwortdauer von 3 Sekunden oder mehr",
  "cms.indexmonitor.message.agentAbandonIn5NDesc": "Gesamtzahl der Abbrüche mit einer Antwortdauer von mindestens 5 Sekunden",
  "cms.indexmonitor.message.agentAbandonIn10NDesc": "Gesamtzahl der Abbrüche mit einer Antwortdauer von 10 Sekunden oder mehr",
  "cms.indexmonitor.message.agentAbandonIn30NDesc": "Gesamtzahl der Abbrüche mit einer Antwortdauer von 30 Sekunden oder mehr",
  "cms.indexmonitor.message.agentAbandonIn60NDesc": "Gesamtzahl der Abbrüche mit einer Antwortdauer von 60 Sekunden oder mehr",
  "cms.indexmonitor.message.agentConnectedOut10NDesc": "Gesamtzahl der verbundenen Anrufe, deren Antwortdauer länger als 10 Sekunden ist.",
  "cms.indexmonitor.message.agentConnectedOut60NDesc": "Gesamtzahl der verbundenen Anrufe, deren Antwortdauer mehr als 60 Sekunden beträgt.",
  "cms.indexmonitor.message.agentAbandonOut10NDesc": "Gesamtzahl der aufgegebenen Anrufe, deren Antwortdauer länger als 10 Sekunden ist.",
  "cms.indexmonitor.message.agentAbandonOut60NDesc": "Gesamtzahl der aufgegebenen Anrufe, deren Antwortdauer mehr als 60 Sekunden beträgt.",
  "cms.indexmonitor.message.totalCallNumDesc": "Gesamtanzahl der Anrufe, die von einem Agenten verbunden werden.",
  "cms.indexmonitor.message.callOutSuccNum": "Anzahl der Male, die ein Agent ausgehende Anrufe verbindet.",
  "cms.indexmonitor.message.agentOutCallCount": "Anzahl der ausgehenden Anrufe, die vom aktuellen Agenten getätigt wurden.(Enthält keine Multimediasitzungen)",
  "cms.indexmonitor.message.queueInCallCount": "Anzahl der eingehenden Anrufe, die in Skill-Warteschlangen eingereiht werden.(Enthält keine Multimedia-Sitzungen)",
  "cms.indexmonitor.message.queueOutCallCount": "Anzahl der ausgehenden Anrufe, die in Skill-Warteschlangen eingereiht werden.(Enthält keine Multimedia-Sitzungen)",
  "cms.indexmonitor.message.totalInCallCount": "Gesamtzahl der eingehenden Anrufe.(Multimediasitzungen nicht eingeschlossen)",
  "cms.indexmonitor.message.ivrInCallCount": "Anzahl der eingehenden Anrufe, deren aktuelles Dienstgerät ein IVR ist.",
  "cms.indexmonitor.message.ivrOutCallCount": "Anzahl der ausgehenden Anrufe, deren aktuelles Dienstgerät ein IVR ist.",
  "cms.indexmonitor.field.skillOnlineInbondCall": "Anzahl der Online-Anrufe",
  "cms.indexmonitor.field.skillOnlineWaitCall": "Online-Warteschlangenanrufe",
  "cms.indexmonitor.field.skillOnlineConnected": "Anzahl der Anrufe, die von Agenten im aktuellen 5-Minuten-Block beantwortet wurden",
  "cms.indexmonitor.message.skillOnlineInbondCallDesc": "Anzahl der Anrufe, die gerade bearbeitet werden + Anzahl der Anrufe, die sich in der Warteschleife befinden",
  "cms.indexmonitor.message.skillOnlineWaitCallDesc": "Anzahl der Anrufe in der Warteschlange.",
  "cms.indexmonitor.message.skillOnlineConnectedDesc": "Anzahl der Anrufe, die von Agenten im aktuellen 5-Minuten-Block beantwortet wurden. 5-Minuten-Blöcke fahren von 00:00 bis 00:05 Uhr, 00:05 bis 00:10 Uhr und so weiter.",
  "cms.indexmonitor.field.allType": "Alle Arten",
  "cms.developerlist.label.create": "Hinzufügen",
  "cms.developerlist.label.delete": "Löschen",
  "cms.developerlist.label.refreshsk": "SK aktualisieren",
  "cms.developerlist.field.ak": "Entwickler AK",
  "cms.developerlist.field.description": "Beschreibung",
  "cms.developerlist.field.operate": "Betrieb",
  "cms.developerlist.title.create": "Entwickler hinzufügen",
  "cms.developerlist.title.deleteconfim": "Bestätigen",
  "cms.developerlist.message.deleteConfirmMessage": "Möchten Sie die ausgewählten Entwickler und die zugehörigen Konfigurationen wirklich löschen?",
  "cms.developerlist.title.success": "Erfolg",
  "cms.developerlist.message.deletesuccess": "Löschen erfolgreich.",
  "cms.developerlist.message.error": "Fehler",
  "cms.developerlist.message.deletefailed": "Löschen fehlgeschlagen.",
  "cms.developerlist.title.refreshsk": "SK aktualisieren",
  "cms.developerlist.message.refreshConfirmMessage": "Möchten Sie den SK des Entwicklers wirklich aktualisieren?",
  "cms.developerlist.message.refreshfailed": "SK konnte nicht aktualisiert werden.",
  "cms.developerlist.message.createfailed": "Entwickler konnte nicht erstellt werden.",
  "cms.developerlist.message.checkInput": "Der Wert darf keine Sonderzeichen enthalten.",
  "cms.developer.message.addDeveloper": "Entwicklerinformationen hinzufügen",
  "cms.developer.message.modifyDeveloper": "Entwicklerinformationen aktualisieren",
  "cms.developerlist.field.updateTime": "Geändert um",
  "cms.developerlist.field.createTime": "Erstellt um",
  "cms.developerlist.field.search": "Suche",
  "cms.callcenterinstanceslist.field.developerDesc": "Beschreibung",
  "cms.indexmonitor.field.skillRequest": "Serviceanfragen",
  "cms.indexmonitor.field.skillConnected": "Anforderungen verbundener Anrufe",
  "cms.indexmonitor.field.skillAbandon": "Verlorene Anrufanfragen",
  "cms.indexmonitor.field.skillUserAbandon": "Aufgegebene Anrufe",
  "cms.indexmonitor.field.skillAgentAbandon": "Anrufe, die von Agenten unterbrochen wurden",
  "cms.indexmonitor.field.skillQueueDisconnected": "Getrennte Anrufe in die Warteschlange stellen",
  "cms.indexmonitor.field.skillSystemLost": "Plattformunterbrechungen",
  "cms.indexmonitor.field.skillLostRing": "Unbeantwortete Anrufe",
  "cms.indexmonitor.field.skillTimeoutQueue": "Zeitüberschreitung bei Anrufen in Warteschleifen",
  "cms.indexmonitor.field.skillTimeoutAgent": "Timeout-Anrufe an Agenten",
  "cms.indexmonitor.field.skillOverQueue": "Anrufe an Warteschleifen überlaufen",
  "cms.indexmonitor.field.skillOverAgent": "Überlauf von Anrufen an Agenten",
  "cms.indexmonitor.field.skillConnectedIn3": "Anrufe in 3 Sekunden verbunden",
  "cms.indexmonitor.field.skillConnectedIn5": "Anrufe in 5 Sekunden verbunden",
  "cms.indexmonitor.field.skillConnectedIn10": "Anrufe in 10s verbunden",
  "cms.indexmonitor.field.skillConnectedIn20": "Anrufe in 20s verbunden",
  "cms.indexmonitor.field.skillConnectedIn30": "Anrufe in 30s verbunden",
  "cms.indexmonitor.field.skillConnectedIn60": "Anrufe in 60er Jahren verbunden",
  "cms.indexmonitor.field.skillAbandonIn3": "Anrufe in drei Sekunden abgebrochen",
  "cms.indexmonitor.field.skillAbandonIn5": "Anrufe in 5 Sekunden abgebrochen",
  "cms.indexmonitor.field.skillAbandonIn10": "Anrufe in 10s abgebrochen",
  "cms.indexmonitor.field.skillAbandonIn20": "Anrufe in 20er Jahren aufgegeben",
  "cms.indexmonitor.field.skillAbandonIn30": "Anrufe in 30er Jahren aufgegeben",
  "cms.indexmonitor.field.skillAbandonIn60": "Anrufe in 60er Jahren aufgegeben",
  "cms.indexmonitor.field.skillConnectedAfter10": "Anrufe werden nach 10 Sekunden verbunden",
  "cms.indexmonitor.field.skillConnectedAfter60": "Anrufe, die nach 60 Jahren verbunden wurden",
  "cms.indexmonitor.field.skillAbandonAfter10": "Anrufe nach 10 Sekunden abgebrochen",
  "cms.indexmonitor.field.skillAbandonAfter60": "Anrufe nach 60 Jahren eingestellt",
  "cms.indexmonitor.field.skillConnectedRate": "Anforderungen verbundener Anrufe",
  "cms.indexmonitor.field.SkillAbandonRate": "Verlorene Anrufanfragen",
  "cms.indexmonitor.field.skillConnectedIn3Rate": "Anrufe in 3 Sekunden verbunden",
  "cms.indexmonitor.field.skillConnectedIn5Rate": "Anrufe in 5 Sekunden verbunden",
  "cms.indexmonitor.field.skillConnectedIn10Rate": "Anrufe in 10s verbunden",
  "cms.indexmonitor.field.skillConnectedIn30Rate": "Anrufe in 30s verbunden",
  "cms.indexmonitor.field.skillConnectedIn60Rate": "Anrufe in 60er Jahren verbunden",
  "cms.indexmonitor.field.skillAbandonIn3Rate": "Anrufe in drei Sekunden abgebrochen",
  "cms.indexmonitor.field.skillAbandonIn5Rate": "Anrufe in 5 Sekunden abgebrochen",
  "cms.indexmonitor.field.skillAbandonIn10Rate": "Anrufe in 10s abgebrochen",
  "cms.indexmonitor.field.skillAbandonIn20Rate": "Anrufe in 20er Jahren aufgegeben",
  "cms.indexmonitor.field.skillAbandonIn30Rate": "Anrufe in 30er Jahren aufgegeben",
  "cms.indexmonitor.field.skillAbandonIn60Rate": "Anrufe in 60er Jahren aufgegeben",
  "cms.indexmonitor.field.skillAvgConnected": "Durchschnittliche Anrufdauer",
  "cms.indexmonitor.field.skillAvgQueueAck": "Durchschnittliche Wartezeit",
  "cms.indexmonitor.field.skillAvgConnectedAck": "Durchschnittliche Klingeldauer verbundener Anrufe",
  "cms.indexmonitor.field.skillAvgAbandonAck": "Durchschnittliche Klingeldauer verlorener Anrufe",
  "cms.indexmonitor.field.skillAvgConnectedQueue": "Durchschnittliche Warteschlangendauer verbundener Anrufe",
  "cms.indexmonitor.field.skillAvgAbandonQueue": "Durchschnittliche Warteschlangendauer verlorener Anrufe",
  "cms.indexmonitor.field.skillMaxQueueAck": "Maximale Wartezeit",
  "cms.indexmonitor.field.skillMinQueueAck": "Mindestwartedauer",
  "cms.indexmonitor.title.skillNowMonitor": "Echtzeit-Überwachungsstatistiken zur Skill-Queue während der Ausführung",
  "cms.indexmonitor.title.skillDayMonitor": "Heutige Statistiken zu Kompetenzwarteschlangen-Indikatoren",
  "cms.indexmonitor.title.skillDayTrend": "Der heutige Trend der Skill-Queue",
  "cms.indexmonitor.title.skillQueueStatistics": "Skill-Warteschlangenstatistiken am aktuellen Tag",
  "cms.indexmonitor.title.agentNowMonitor": "Echtzeit-Überwachungsstatistiken auf dem Agenten während der Ausführung",
  "cms.indexmonitor.title.agentDayMonitor": "Heutige Statistiken zu Agentenindikatoren",
  "cms.indexmonitor.title.agentDayTrend": "Der aktuelle Trend des Agenten",
  "cms.indexmonitor.message.skillRequestDesc": "Anzahl der eingehenden Anrufe, die Nutzerdienste anfordern",
  "cms.indexmonitor.message.skillConnectedDesc": "Anzahl der Anrufe, die mit Agenten in der Skill-Warteschleife verbunden sind.",
  "cms.indexmonitor.message.skillAbandonDesc": "Anzahl der verlorenen manuellen eingehenden Anrufanfragen = Anzahl der manuellen eingehenden Anrufanfragen – Anzahl der verbundenen manuellen eingehenden Anrufanfragen",
  "cms.indexmonitor.message.skillUserAbandonDesc": "Anzahl der Anrufe, die proaktiv von Benutzern beim Einreihen in die Warteschlange und beim Klingeln abgebrochen wurden",
  "cms.indexmonitor.message.skillAgentAbandonDesc": "Anzahl der Anrufe, die während des Klingelns proaktiv von Agenten beendet wurden",
  "cms.indexmonitor.message.skillQueueDisconnectedDesc": "Anzahl der Anrufe, die von der CTI-Plattform getrennt werden, wenn sich die Anrufe in der Warteschlange befinden",
  "cms.indexmonitor.message.skillSystemLostDesc": "Anzahl der Plattformunterbrechungen, die z. B. durch Zeitüberschreitung der Agentenreaktion verursacht wurden",
  "cms.indexmonitor.message.skillLostRingDesc": "Anzahl der unbeantworteten Anrufe nach Zuweisung an Agenten",
  "cms.indexmonitor.message.skillTimeoutQueueDesc": "Anzahl der Anrufe, die aufgrund von Warteschlangen-Timeouts von der ursprünglichen Skill-Warteschleife an andere Skill-Warteschleifen weitergeleitet wurden",
  "cms.indexmonitor.message.skillTimeoutAgentDesc": "Anzahl der Anrufe, die aufgrund von Warteschlangen-Timeouts aus der ursprünglichen Skill-Warteschleife an andere Agenten weitergeleitet wurden",
  "cms.indexmonitor.message.skillOverQueueDesc": "Anzahl der Anrufe, die aufgrund von Überlauf von der ursprünglichen Skill-Warteschleife an andere Skill-Warteschleifen weitergeleitet wurden",
  "cms.indexmonitor.message.skillOverAgentDesc": "Anzahl der Anrufe, die aufgrund von Überlauf aus der ursprünglichen Skill-Warteschleife an andere Agenten weitergeleitet wurden",
  "cms.indexmonitor.message.skillConnectedInNDesc": "Anzahl der manuell verbundenen Anrufanforderungen in Ns. Derzeit können Ns 3s, 5s, 10s, 20s, 30s oder 60s sein.",
  "cms.indexmonitor.message.skillAbandonInNDesc": "Anzahl der manuell abgebrochenen Anrufanfragen in Ns. Derzeit können Ns 3s, 5s, 10s, 20s, 30s oder 60s sein.",
  "cms.indexmonitor.message.skillConnectedAfterNDesc": "Anzahl der Anrufanfragen, die nach Ns manuell verbunden wurden. Derzeit können Ns 10s oder 60s sein.",
  "cms.indexmonitor.message.skillAbandonAfterNDesc": "Anzahl der nach Ns manuell abgebrochenen Anrufanfragen. Derzeit können Ns 10s oder 60s sein.",
  "cms.indexmonitor.message.skillConnectedRateDesc": "Prozentualer Anteil der verbundenen manuellen eingehenden Anrufanfragen an der Anzahl manueller eingehender Anrufanfragen",
  "cms.indexmonitor.message.SkillAbandonRateDesc": "Rate verlorener Anrufe = 100 – Rate verbundener Anrufe",
  "cms.indexmonitor.message.skillConnectedInNRate": "Prozentualer Anteil der manuell verbundenen Anrufanfragen in Ns an der Gesamtzahl der Anrufe, die Nutzerdienste anfordern",
  "cms.indexmonitor.message.skillAbandonInNRateDesc": "Prozentualer Anteil der manuell aufgegebenen Anrufanfragen in Ns an der Gesamtzahl der anfordernden Nutzerdienste",
  "cms.indexmonitor.message.skillAvgConnectedDesc": "Durchschnittliche Anrufdauer. Durchschnittliche Dauer des manuellen eingehenden Anrufs = Dauer des manuellen eingehenden Anrufs/Anzahl der manuell verbundenen eingehenden Anrufe.",
  "cms.indexmonitor.message.skillAvgQueueAckDesc": "Durchschnittliche Wartezeit von Anrufen Durchschnittliche Wartezeit (Warteschlangen- und Klingeldauer) aller Anrufe.",
  "cms.indexmonitor.message.skillAvgConnectedAckDesc": "Durchschnittliche Klingeldauer aller verbundenen Anrufe in einer Warteschleife",
  "cms.indexmonitor.message.skillAvgAbandonAckDesc": "Durchschnittliche Klingeldauer aller unbeantworteten Anrufe",
  "cms.indexmonitor.message.skillAvgConnectedQueueDesc": "Durchschnittliche Warteschleifendauer aller verbundenen Anrufe in einer Warteschleife",
  "cms.indexmonitor.message.skillAvgAbandonQueueDesc": "Durchschnittliche Warteschlangendauer aller unbeantworteten Anrufe in einer Warteschleife",
  "cms.indexmonitor.message.skillMaxQueueAckDesc": "Maximale Wartezeit (Warteschlangen- und Klingeldauer) aller Anrufe",
  "cms.indexmonitor.message.skillMinQueueAckDesc": "Mindestwartedauer (Warteschlangen- und Klingeldauer) aller Anrufe",
  "cms.indexmonitor.message.errorInfo": "Bitte wählen Sie mindestens eine Skill-Warteschlange aus.",
  "cms.indexmonitor.message.errorAgentInfo": "Wählen Sie mindestens einen Agenten aus.",
  "cms.indexmonitor.message.errorOrgInfo": "Bitte wählen Sie zuerst eine Organisation aus.",
  "cms.indexmonitor.message.skillIdMaxSize": "Es können maximal fünf Skill-Warteschlangen ausgewählt werden.",
  "cms.qualitycheck.message.adjustskill": "Ihre Skill-Warteschlange wurde vorübergehend an {skillList} angepasst. Die ursprüngliche Fähigkeit wird wiederhergestellt, nachdem Sie sich erneut anmelden.",
  "cms.agentmonitor.label.cancelInsert": "Einfügung abbrechen",
  "cms.agentmonitor.label.cancellistening": "Abhören abbrechen",
  "cms.indexmonitor.field.currentSelectedSkill": "Ausgewählte Skill-Warteschlange:",
  "cms.indexmonitor.message.queryCallCenterError": "Bei der Abfrage des ausgewählten Callcenters ist eine Ausnahme aufgetreten.",
  "cms.indexmonitor.message.skillLimit": "Wählen Sie 1 bis 10 Skill-Warteschlangen aus.",
  "cms.report.title.error": "Fehler",
  "cms.report.label.reset": "Zurücksetzen",
  "cms.report.label.search": "Suche",
  "cms.report.field.startTime": "Startdatum",
  "cms.report.field.endTime": "Enddatum",
  "cms.report.field.skillId": "Skill-Warteschlangen-ID",
  "cms.report.field.skillName": "Skill-Warteschlangenname",
  "cms.report.field.agentId": "Agenten-ID",
  "cms.report.field.inOccupyNum": "Serviceanfragen",
  "cms.report.field.inCallSuccNum": "Anzahl der verbundenen manuellen Anrufe",
  "cms.report.field.callSuccRate": "Verbindungsrate anfordern",
  "cms.report.field.inCallFailNum": "Verlorene Anrufanfragen",
  "cms.report.field.call3Rate": "Verbindungsrate des manuellen eingehenden Anrufs in 15 Sekunden",
  "cms.report.field.call4Rate": "Verbindungsrate des manuellen eingehenden Anrufs in 20s",
  "cms.report.field.call6Rate": "Verbindungsrate des manuellen eingehenden Anrufs in 30 Sekunden",
  "cms.report.field.succWaitAnsRate": "Durchschnittliche Wartezeit",
  "cms.report.field.failQueueWaitRate": "Durchschnittliche Klingeldauer verlorener Anrufe",
  "cms.report.field.inCallRate": "Durchschnittliche Anrufdauer",
  "cms.report.field.waitAnsRate": "Durchschnittliche Klingeldauer manueller eingehender Anrufe",
  "cms.report.field.abortQueueWaitNum": "Aufgegebene Warteschlangenanrufe",
  "cms.report.field.noAckNum": "Unbeantwortete Anrufe",
  "cms.report.field.inWaitAnsNum": "Manuelle eingehende Anrufe",
  "cms.report.field.inAnswerRate": "Verbindungsrate eingehender Anrufe",
  "cms.report.field.callAbandonedRing": "Anzahl der verlorenen eingehenden manuellen Anrufe",
  "cms.report.field.inAvgRingTime": "Durchschnittliche Klingeldauer manueller eingehender Anrufe",
  "cms.report.field.inAvgCallTime": "Durchschnittliche Anrufdauer",
  "cms.report.field.outWaitAnsNum": "Manuelle ausgehende Anrufe",
  "cms.report.field.outCallSuccNum": "Verbundene manuelle ausgehende Anrufe",
  "cms.report.field.outAnswerRate": "Verbindungsrate ausgehender Anrufe",
  "cms.report.field.outAvgRingTime": "Durchschnittliche Klingeldauer manueller ausgehender Anrufe",
  "cms.report.field.outAvgCallTime": "Durchschnittliche Dauer des manuellen ausgehenden Anrufs",
  "cms.report.field.acdCall": "Gesamte verbundene Anrufe",
  "cms.report.field.avgCallPerHour": "Durchschnittliche eingehende manuelle Anrufe pro Stunde",
  "cms.report.field.occupancyRate": "Arbeitsdauerrate",
  "cms.report.field.utilizationRate": "Rate der Arbeitsstatusdauer",
  "cms.report.field.workTime": "Gesamtarbeitsdauer",
  "cms.report.field.acdTime": "Dauer des manuellen Anrufs",
  "cms.report.field.acdTimeRate": "Rate der manuellen Anrufdauer",
  "cms.report.field.avgAcdTime": "Durchschnittliche Dauer des manuellen Anrufs",
  "cms.report.field.acwTime": "Gesamte Nachbearbeitungsdauer",
  "cms.report.field.acwTimeRate": "Rate der Nachbearbeitungsdauer",
  "cms.report.field.avgAcwTime": "Durchschnittliche Nachbearbeitungsdauer",
  "cms.report.field.avgHandleTime": "Durchschnittliche Verarbeitungsdauer",
  "cms.report.field.auxTime": "Gesamtruhezeit",
  "cms.report.field.auxTimeRate": "Rate der Ruhezeit",
  "cms.report.field.busyTime": "Gesamte Belegungsdauer",
  "cms.report.field.busyTimeRate": "Rate der Belegungsdauer",
  "cms.report.field.ringTime": "Gesamte Klingeldauer",
  "cms.report.field.availTime": "Leerlaufdauer",
  "cms.report.field.availTimeRate": "Leerlaufrate",
  "cms.report.field.holdTime": "Dauer halten",
  "cms.report.field.avgHoldTime": "Durchschnittliche Haltedauer",
  "cms.report.field.outOccupyNum": "Ausgehende Anrufe",
  "cms.report.field.outCallRate": "Verbindungsrate ausgehender Anrufe",
  "cms.report.field.outAboundRing": "Unbeantwortete ausgehende Anrufe",
  "cms.report.field.outInvalidCall": "Ungültige ausgehende Anrufe",
  "cms.report.field.outFailNum": "Gesamte fehlgeschlagene ausgehende Anrufe",
  "cms.report.field.outCallTime": "Gesamtdauer des ausgehenden Anrufs",
  "cms.report.field.outWaitAnsTime": "Gesamte Klingeldauer ausgehender Anrufe",
  "cms.report.field.outAvgAnsTime": "Durchschnittliche Klingeldauer ausgehender Anrufe",
  "cms.report.field.acwCount": "Nachbearbeitungszeiten",
  "cms.report.field.acwOutTime": "Nachbearbeitungsdauer für ausgehende Anrufe",
  "cms.report.field.acwOutCount": "Nachbearbeitungszeiten für ausgehende Anrufe",
  "cms.report.field.acwInCount": "Nachbearbeitungszeiten eingehender Anrufe",
  "cms.report.field.acwInTime": "Nachbearbeitungsdauer eingehender Anrufe",
  "cms.report.field.auxCount": "Ruhezeiten",
  "cms.report.field.holdCount": "Haltezeiten",
  "cms.report.field.muteCount": "Zeiten der Stille",
  "cms.report.field.muteTime": "Gesamte Stilledauer",
  "cms.report.field.assistsCount": "Interne Hilfeanfragen",
  "cms.report.field.assistTime": "Dauer der internen Hilfeanfrage",
  "cms.report.field.busyCount": "Gebuchte Zeiten",
  "cms.report.field.playVoiceCount": "Stimmwiedergabezeiten",
  "cms.report.field.playVoiceTime": "Stimmwiedergabedauer",
  "cms.report.field.otherTime": "Gesamtdauer anderer Staaten",
  "cms.report.field.loginCount": "Anmeldezeiten",
  "cms.report.field.logoutCount": "Abmeldezeiten",
  "cms.report.field.threePartyCallCount": "Drei-Parteien-Anrufe",
  "cms.report.field.internalTransferCount": "Interne Transfers",
  "cms.report.field.transferOutCount": "Manuelle Transfer-Out-Zeiten",
  "cms.report.field.transferIvrCount": "Übertragungszeiten von manuell zu IVR",
  "cms.report.field.internalCallCount": "Interne Anrufe",
  "cms.report.field.internalCallTime": "Interne Anrufdauer",
  "cms.report.field.manToSkillNum": "Manuelle Übertragungen von Warteschlangen an Kompetenzen",
  "cms.report.field.agentHangNumCti": "Auflegen von Agenten",
  "cms.report.field.custHangNumCti": "Auflegen von Kunden",
  "cms.report.field.otherHangNumCti": "Systemabstürze",
  "cms.report.field.manToIvrNumCti": "Anzahl der Male, die ein Agent für die Übertragung an das IVR suspendiert wurde",
  "cms.report.field.manTransferOutNumCti": "Anzahl der manuellen Übertragungen an IVR und der Rückübertragungen",
  "cms.report.field.calleeNo": "Zugangscode",
  "cms.report.field.systemInBound": "Gesamte eingehende Systemanrufe",
  "cms.report.field.systemInSucc": "Erfolgreiche eingehende Systemanrufe",
  "cms.report.field.sysSuccRate": "Erfolgsquote eingehender Systemanrufe",
  "cms.report.field.sysLostCalls": "Fehlerhafte eingehende Systemanrufe",
  "cms.report.field.IVRInBound": "Eingehende IVR-Anrufe",
  "cms.report.field.IVRInSucc": "Verbundene eingehende IVR-Anrufe",
  "cms.report.field.IVRSuccRate": "Verbindungsrate eingehender IVR-Anrufe",
  "cms.report.field.onlyIVRService": "Erfolgreiche eingehende Pure-IVR-Anrufe",
  "cms.report.field.IVRServiceRate": "Pure-IVR-Erfolgsquote eingehender Anrufe",
  "cms.report.field.agentOffered": "Manuelle eingehende Anrufe",
  "cms.report.field.agentService": "Vom Agenten beantwortete Anrufe",
  "cms.report.field.awarnessRate": "Erfolgsquote des manuellen eingehenden Anrufs",
  "cms.report.field.agentServiceRate": "Manuelle Erfolgsquote bei der Gesamtzahl der eingehenden Anrufe",
  "cms.report.field.succAnsRate": "Verbindungsrate anfordern",
  "cms.report.field.avgAnsTime": "Durchschnittliche Warteschlangendauer verbundener Anrufe",
  "cms.report.field.ans20s": "Rate der manuellen Anrufverbindung in 20s",
  "cms.report.field.avgCallInTime": "Durchschnittliche Anrufdauer",
  "cms.report.field.avgInRingTime": "Durchschnittliche Dauer eingehender Anrufe von Terminals",
  "cms.report.field.failAnsRate": "Anforderungsverlustrate",
  "cms.report.field.failAvgTime": "Durchschnittliche Klingeldauer verlorener Anrufe",
  "cms.report.field.maxQueueWaitTime": "Maximale Wartezeit",
  "cms.report.field.outSuccAnsRate": "Verbindungsrate ausgehender Anrufe",
  "cms.report.field.acdCalls": "Gesamte verbundene Anrufe",
  "cms.report.field.avgPerHNum": "Durchschnittliche erfolgreiche Anrufe pro Stunde",
  "cms.report.field.agentLoginNum": "Angerufene Agenten",
  "cms.report.field.perAgentNum": "Agentenanrufe",
  "cms.report.field.workRate": "Arbeitsdauerrate",
  "cms.report.field.onlineRate": "Online-Preis",
  "cms.report.field.otherTimeRate": "Andere Dauerrate",
  "cms.report.field.inCallNum": "Eingehende Anrufe",
  "cms.report.field.inCallSuccNumber": "Verbundene eingehende Anrufe",
  "cms.report.field.agentInBound": "Manuelle eingehende Anrufe",
  "cms.report.field.agentInSucc": "Verbundene manuelle eingehende Anrufe",
  "cms.report.field.outCallNum": "Ausgehende Anrufe",
  "cms.report.field.outCallSuccNumber": "Verbundene ausgehende Anrufe",
  "cms.report.field.IVROutCallNum": "Ausgehende Anrufe von reinem IVR",
  "cms.report.field.IVROutSuccNum": "Verbundene ausgehende Anrufe mit reinem IVR",
  "cms.report.field.agentOutBound": "Manuelle ausgehende Anrufe",
  "cms.report.field.agentOutSucc": "Verbundene manuelle ausgehende Anrufe",
  "cms.report.field.inCallSuccRate": "Verbindungsrate eingehender Anrufe",
  "cms.report.field.IVRInSuccRate": "Verbindungsrate eingehender IVR-Anrufe",
  "cms.report.field.agentInSuccRate": "Verbindungsrate des manuellen eingehenden Anrufs",
  "cms.report.field.outCallSuccRate": "Verbindungsrate ausgehender Anrufe",
  "cms.report.field.IVROutSuccRate": "Pure-IVR-Verbindungsrate für ausgehende Anrufe",
  "cms.report.field.agentOutSuccRate": "Verbindungsrate manueller ausgehender Anrufe",
  "cms.report.field.inVdnAvgTime": "Durchschnittliche Dauer eingehender Anrufe in VDN",
  "cms.report.field.outVdnAvgTime": "Durchschnittliche Dauer ausgehender Anrufe in VDN",
  "cms.report.field.IVRCallOutNum": "Ausgehende IVR-Anrufe",
  "cms.report.field.IVRCallOutSuccNum": "Verbundene ausgehende IVR-Anrufe",
  "cms.report.field.IVRCallOutSuccRate": "Verbindungsrate ausgehender IVR-Anrufe",
  "cms.report.field.userBusyFailNum": "Verlorene Anrufe aufgrund besetzter Leitungen",
  "cms.report.field.userRefusedFailNum": "Verlorene Anrufe aufgrund von Anrufablehnung",
  "cms.report.field.userNoAnswerNum": "Verlorene Anrufe aufgrund von Klingelzeitüberschreitung",
  "cms.report.field.callLostOtherNum": "Andere verlorene Anrufe",
  "cms.report.field.minAgentInCount": "Min. gleichzeitige eingehende Agentenanrufe",
  "cms.report.field.maxAgentInCount": "Maximale Anzahl gleichzeitiger eingehender Agentenanrufe",
  "cms.report.field.avgAgentInCount": "Durchschn. gleichzeitige eingehende Agentenanrufe",
  "cms.report.field.minAgentOutCount": "Min. gleichzeitige ausgehende Agentenanrufe",
  "cms.report.field.maxAgentOutCount": "Maximale gleichzeitige ausgehende Agentenanrufe",
  "cms.report.field.avgAgentOutCount": "Durchschn. gleichzeitige ausgehende Agentenanrufe",
  "cms.report.field.minAgentCount": "Min. gleichzeitige Agentenanrufe",
  "cms.report.field.maxAgentCount": "Maximale gleichzeitige Agentenanrufe",
  "cms.report.field.avgAgentCount": "Durchschn. gleichzeitige Agentenanrufe",
  "cms.report.field.minIVRInCount": "Min. gleichzeitige eingehende IVR-Anrufe",
  "cms.report.field.maxIVRInCount": "Maximale Anzahl gleichzeitiger eingehender IVR-Anrufe",
  "cms.report.field.avgIVRInCount": "Durchschnittl. gleichzeitige eingehende IVR-Anrufe",
  "cms.report.field.minIVROutCount": "Min. gleichzeitige ausgehende IVR-Anrufe",
  "cms.report.field.maxIVROutCount": "Maximale gleichzeitige ausgehende IVR-Anrufe",
  "cms.report.field.avgIVROutCount": "Durchschnittl. gleichzeitige ausgehende IVR-Anrufe",
  "cms.report.field.minIVRCount": "Min. gleichzeitige IVR-Anrufe",
  "cms.report.field.maxIVRCount": "Maximale gleichzeitige IVR-Anrufe",
  "cms.report.field.avgIVRCount": "Durchschnittl. gleichzeitige IVR-Anrufe",
  "cms.report.field.minQueueInCount": "Min. gleichzeitige eingehende Skill-Queue-Anrufe",
  "cms.report.field.maxQueueInCount": "Maximale gleichzeitige eingehende Anrufe in der Skill-Queue",
  "cms.report.field.avgQueueInCount": "Durchschn. gleichzeitige eingehende Anrufe in der Skill-Queue",
  "cms.report.field.minQueueOutCount": "Min. gleichzeitige ausgehende Skill-Queue-Anrufe",
  "cms.report.field.maxQueueOutCount": "Maximale gleichzeitige ausgehende Skill-Queue-Anrufe",
  "cms.report.field.avgQueueOutCount": "Durchschn. gleichzeitige ausgehende Skill-Queue-Anrufe",
  "cms.report.field.minQueueCount": "Min. gleichzeitige Skill-Queue-Anrufe",
  "cms.report.field.maxQueueCount": "Maximale gleichzeitige Skill-Queue-Anrufe",
  "cms.report.field.avgQueueCount": "Durchschn. gleichzeitige Skill-Queue-Anrufe",
  "cms.report.field.voiceCallType": "Sprachanruftyp",
  "cms.report.field.oneClickDualCall": "Bidirektionaler Anruf mit einem Klick",
  "cms.report.field.voiceNotification": "Stimmenbenachrichtigung",
  "cms.report.field.intelligentOutCall": "Intelligenter ausgehender Anruf",
  "cms.report.field.reportPeriod": "Statistischer Schritt",
  "cms.report.field.fifteenminutes": "15 Minuten",
  "cms.report.field.thirtyminutes": "30 Minuten",
  "cms.report.field.onehour": "1 Stunde",
  "cms.report.message.queryTimeCheck": "Die Zeit darf nicht vor {0} liegen.",
  "cms.report.message.nowTimeCheck": "Die Zeit überschreitet die aktuelle Zeit.",
  "cms.report.message.timeRangeCheck": "Der Zeitraum darf einen Tag nicht überschreiten.",
  "cms.report.message.timeRangeCheckSaas": "Der Zeitraum darf 5 Tage nicht überschreiten.",
  "cms.report.message.monthRangeCheck": "Start- und Endzeit müssen im selben Monat liegen.",
  "cms.report.message.maxGarpMonth": "Daten von mehr als 12 Monaten können nicht abgefragt werden.",
  "cms.report.field.statInterval": "Zeitraum",
  "cms.report.field.queryBeginTime": "Startzeit",
  "cms.report.field.queryEndTime": "Endzeit",
  "cms.report.title.selectReportTime": "Startzeit festlegen",
  "cms.report.field.hourandminute": "Zeit",
  "cms.report.message.timeCheckError": "Der Zeitraum darf sieben Tage nicht überschreiten.",
  "cms.report.message.currentDateCheck": "Die ausgewählte Zeit muss vor dem aktuellen Tag liegen.",
  "cms.report.label.header": "Tabellenkopfzeile festlegen",
  "cms.report.title.filteringIndicator": "Filterindikator",
  "cms.report.field.indicatorName": "Anzeige",
  "cms.report.field.description": "Beschreibung",
  "cms.report.field.confirm": "OK",
  "cms.report.field.cancel": "Abbrechen",
  "cms.report.title.saveError": "Fehler",
  "cms.report.message.errorInfo": "Daten konnten nicht gespeichert werden. Wählen Sie mindestens einen Indikator aus.",
  "cms.report.message.queryErrorInfo": "Abfrage fehlgeschlagen.",
  "cms.report.message.calleeNoLength": "Geben Sie einen Zugangscode mit mindestens drei Ziffern ein.",
  "cms.report.field.reportType": "Berichtstyp",
  "cms.report.field.byDay": "Tagesbericht",
  "cms.report.field.byWeek": "Wochenbericht",
  "cms.report.field.byMonth": "Monatsbericht",
  "cms.report.field.week": "Woche",
  "cms.report.field.month": "Monat",
  "cms.report.field.year": "Jahr",
  "cms.report.field.startWeek": "Startwoche",
  "cms.report.field.startMonth": "Startmonat",
  "cms.report.field.startYear": "Startjahr",
  "cms.report.field.endWeek": "Wochenende",
  "cms.report.field.endMonth": "Monatsende",
  "cms.report.field.endYear": "Endjahr",
  "cms.report.field.querySkill": "Skill-Warteschlange",
  "cms.report.field.startAgentId": "Agenten-ID starten",
  "cms.report.field.endAgentId": "Endagenten-ID",
  "cms.report.field.filetype": "Exportdateiformat",
  "cms.report.field.userLevel": "Benutzerebene",
  "cms.report.field.unspecifiedUserLevel": "Nicht angegeben",
  "cms.report.field.unspecifiedSkillId": "Nicht angegeben",
  "cms.report.message.lessThanError": "Die Endagenten-ID darf nicht kleiner als die Startagenten-ID sein.",
  "cms.report.message.dategt31days": "Der Zeitraum darf 31 Tage nicht überschreiten.",
  "cms.report.message.dategt12weeks": "Der Wochenbereich darf 12 Wochen nicht überschreiten.",
  "cms.report.message.dategt1year": "Der Monatsbereich darf 12 Monate nicht überschreiten.",
  "cms.report.message.weekLessThan": "Die Endjahreswoche kann nicht vor der Startjahreswoche liegen.",
  "cms.report.message.monthLessThan": "Der Endjahresmonat darf nicht vor dem Startjahresmonat liegen.",
  "cms.report.field.ivrtrafficreport.statdate": "Datum",
  "cms.report.field.ivrtrafficreport.ivrinbound": "IVR-Belegungsnummer",
  "cms.report.field.ivrtrafficreport.ivrshortcalls": "Anzahl der IVR-Kurzanrufe (innerhalb von 3 Sekunden)",
  "cms.report.field.ivrtrafficreport.totalivrservice": "Gesamte IVR-Servicezeit",
  "cms.report.field.ivrtrafficreport.avgivrservice": "Durchschnittliche Dauer eingehender IVR-Anrufe",
  "cms.fullscreen.message.enterMessage": "Eingabe",
  "cms.fullscreen.message.selectMessage": "-Auswahl-",
  "cms.fullscreen.field.chartName": "Diagrammname",
  "cms.fullscreen.field.indexDimension": "Überwachtes Objekt",
  "cms.fullscreen.field.skillQueue": "Skill-Warteschlange",
  "cms.fullscreen.field.indexdMode": "Überwachungsmodus",
  "cms.fullscreen.field.dayMonitor": "Heutige Indikatorstatistiken",
  "cms.fullscreen.field.nowMonitor": "Echtzeitüberwachung während der Ausführung",
  "cms.fullscreen.field.dayTrend": "Der Trend von heute",
  "cms.fullscreen.field.monitorType": "Indikatortyp",
  "cms.fullscreen.field.refreshPeriod": "Aktualisierungszeitraum",
  "cms.fullscreen.field.statisticPeriod": "Statistischer Zeitraum",
  "cms.fullscreen.field.chartType": "Diagrammtyp",
  "cms.fullscreen.field.fifteenMin": "15 Minuten",
  "cms.fullscreen.field.halfHour": "30 Minuten",
  "cms.fullscreen.field.oneHour": "1 Stunde",
  "cms.fullscreen.field.fiveSec": "5 Sekunden",
  "cms.fullscreen.field.oneMin": "1 Minute",
  "cms.fullscreen.field.fiveMin": "5 Minuten",
  "cms.fullscreen.field.bar": "Balkendiagramm",
  "cms.fullscreen.field.line": "Liniendiagramm",
  "cms.fullscreen.field.pie": "Tortendiagramm",
  "cms.fullscreen.field.table": "Tisch",
  "cms.fullscreen.field.richtext": "Reicher Text",
  "cms.fullscreen.field.text": "Text",
  "cms.fullscreen.field.add": "Erstellen",
  "cms.fullscreen.field.edit": "Ändern",
  "cms.fullscreen.field.delete": "Löschen",
  "cms.fullscreen.field.movedown": "Unten",
  "cms.fullscreen.field.moveup": "Auf",
  "cms.fullscreen.field.addCharts": "Diagramm erstellen",
  "cms.fullscreen.field.saveTheme": "Layout speichern",
  "cms.fullscreen.title.newChart": "Diagramm erstellen",
  "cms.fullscreen.title.editChart": "Diagramm ändern",
  "cms.fullscreen.title.newTheme": "Betreff erstellen",
  "cms.fullscreen.title.editTheme": "Betreff ändern",
  "cms.fullscreen.style.dark": "Dunkel",
  "cms.fullscreen.style.light": "Licht",
  "cms.fullscreen.style.title": "Stil",
  "cms.fullscreen.message.errorMonitorMethod": "Wählen Sie einen Überwachungsmodus.",
  "cms.fullscreen.title.deleteconfim": "Bestätigen",
  "cms.fullscreen.message.deleteconfim": "Möchten Sie das Löschen dieses Themas bestätigen?",
  "cms.fullscreen.message.deletechart": "Möchten Sie das Löschen dieses Diagramms bestätigen?",
  "cms.fullscreen.field.virtualcallcenter": "VDN",
  "cms.fullscreen.field.screenreSolution": "Auflösung",
  "cms.fullscreen.field.custom": "Anpassung:",
  "cms.fullscreen.message.themeDuplicate": "Doppelter Name.",
  "cms.fullscreen.message.selectAgent": "Die Organisationseinheit und der Agent dürfen nicht leer sein.",
  "cms.fullscreen.message.selectAgentMax": "Es können maximal 5 Agenten ausgewählt werden.",
  "cms.fullscreen.field.themeName": "Betreffname",
  "cms.fullscreen.field.preview": "Vorschau",
  "cms.fullscreen.message.screenreSolution": "Die Bildschirmauflösung darf nicht leer sein.",
  "cms.fullscreen.message.numberFirst": "Der Höchstwert ist 7680.",
  "cms.fullscreen.message.numberSecond": "Der maximale Wert ist 4320.",
  "cms.fullscreen.message.maxThemeNum": "Es können maximal 20 private und 20 öffentliche Themen hinzugefügt werden.",
  "cms.fullscreen.message.numberFirstMin": "Der Mindestwert beträgt 1280",
  "cms.fullscreen.message.numberSecondMin": "Der Mindestwert ist 720",
  "cms.fullscreen.message.chartDataError": "Das Diagramm konnte nicht geladen werden.",
  "cms.fullscreen.field.fullscreen": "Vollbild",
  "cms.fullscreen.field.fonts": "Schriftart",
  "cms.fullscreen.field.fontSize": "Größe",
  "cms.fullscreen.field.weighted": "Schriftartgewicht",
  "cms.fullscreen.field.color": "Farbe",
  "cms.fullscreen.field.normalBold": "Normal",
  "cms.fullscreen.field.bold": "Fett",
  "cms.fullscreen.field.indicatorData": "Indikatordaten",
  "cms.fullscreen.field.editRichText": "Rich-Text ändern",
  "cms.fullscreen.field.yaHei": "YaHei von Microsoft",
  "cms.fullscreen.field.simsun": "SimSun",
  "cms.fullscreen.field.FangSong": "FangSong",
  "cms.fullscreen.field.SimHei": "SimHei",
  "cms.fullscreen.field.play": "Abspielen",
  "cms.fullscreen.field.second": "(s)",
  "cms.fullscreen.field.percentage": "(%)",
  "cms.fullscreen.message.addTheme": "Fügen Sie einen Betreff hinzu.",
  "cms.fullscreen.message.addChart": "Fügen Sie ein Diagramm hinzu.",
  "cms.fullscreen.message.chooseTheme": "Das Layout wird nicht gespeichert. Möchten Sie das Thema wirklich wechseln?",
  "cms.fullscreen.message.addThemeError": "Das Layout wird nicht gespeichert. Möchten Sie wirklich einen Betreff hinzufügen?",
  "cms.fullscreen.message.saveLayouts": "Das Layout wird nicht gespeichert. Speichern Sie es zuerst.",
  "cms.fullscreen.message.addLayout": "Die Leinwand konnte nicht hinzugefügt werden, da die Leinwand voll ist.",
  "cms.fullscreen.message.addVdnMost": "Es können maximal 10 Mandanten ausgewählt werden.",
  "cms.fullscreen.message.addVdnLeast": "Wählen Sie mindestens einen Mieter aus.",
  "cms.fullscreen.message.playTheme": "Dieses Thema wurde abgespielt.",
  "cms.fullscreen.field.report0": "Täglicher Bericht (relative Zeit)",
  "cms.fullscreen.field.report1": "Täglicher Bericht (feste Zeit)",
  "cms.fullscreen.field.reportType": "Berichtstyp",
  "cms.fullscreen.field.reportTime": "Sammlungszeitraum",
  "cms.fullscreen.field.hisMonitor": "Statistiken zu historischen Indikatoren",
  "cms.fullscreen.field.reportDate": "Vor 1 Tag",
  "cms.fullscreen.field.reportDates": "Vor {0} Tagen",
  "cms.fullscreen.field.noanswerCountDesc": "Anzahl der verspäteten Antworten durch Agenten nach Anrufzuteilung.",
  "cms.fullscreen.field.totalCallTime": "Gesamte Anrufdauer",
  "cms.fullscreen.field.totalCallTimeDesc": "Gesamtdauer der von Agenten beantworteten Anrufe.",
  "cms.fullscreen.field.avgCallTime": "Durchschnittliche Anrufdauer",
  "cms.fullscreen.field.avgCallTimeDesc": "Durchschnittliche Dauer der von Agenten beantworteten Anrufe.",
  "cms.fullscreen.field.acwCountTimeDesc": "Anzahl der Male, die sich Agenten im Nachbearbeitungsstatus befinden.",
  "cms.fullscreen.field.auxCountTimeDesc": "Anzahl der Male, die sich Agenten im Ruhezustand befinden.",
  "cms.fullscreen.field.busyCountTimeDesc": "Anzahl der Male, die Agenten im Besetzt-Status sind.",
  "cms.fullscreen.field.idleCountTimeDesc": "Anzahl der Male, die sich Agenten im Leerlauf befinden.",
  "cms.fullscreen.field.holdCountTimeDesc": "Anzahl der Male, die Agenten im Haltezustand sind.",
  "cms.fullscreen.field.avgCallInTime": "Durchschnittliche Dauer eingehender manueller Anrufe.",
  "cms.voiceinspection.field.callTime": "Aufzeichnungsdauer",
  "cms.report.field.inOccupyNumField": "Serviceanfragen",
  "cms.report.field.inCallSuccNumField": "Anzahl der verbundenen manuellen Anrufe",
  "cms.report.field.callSuccRateField": "Verbindungsrate anfordern",
  "cms.report.field.inCallFailNumField": "Verlorene Anrufanfragen",
  "cms.report.field.call3RateField": "Manuelle Verbindungsrate eingehender Anrufe in 15s",
  "cms.report.field.call4RateField": "Manuelle Verbindungsrate eingehender Anrufe in 20s",
  "cms.report.field.call6RateField": "Manuelle Verbindungsrate eingehender Anrufe in 30s",
  "cms.report.field.succWaitAnsRateField": "Durchschnittliche Wartezeit",
  "cms.report.field.failQueueWaitRateField": "Durchschnittliche Klingeldauer verlorener Anrufe",
  "cms.report.field.inCallRateField": "Durchschnittliche Anrufdauer",
  "cms.report.field.waitAnsRateField": "Durchschnittliche Klingeldauer manueller eingehender Anrufe",
  "cms.report.field.abortQueueWaitNumField": "Aufgegebene Anrufe in der Warteschlange",
  "cms.report.field.noAckNumField": "Unbeantwortete Anrufe",
  "cms.report.field.inWaitAnsNumField": "Manuelle eingehende Anrufe",
  "cms.report.field.inAnswerRateField": "Verbindungsrate eingehender Anrufe",
  "cms.report.field.callAbandonedRingField": "Anzahl der verlorenen eingehenden manuellen Anrufe",
  "cms.report.field.inAvgRingTimeField": "Durchschnittliche Klingeldauer manueller eingehender Anrufe",
  "cms.report.field.inAvgCallTimeField": "Durchschnittliche Anrufdauer",
  "cms.report.field.outWaitAnsNumField": "Manuelle ausgehende Anrufe",
  "cms.report.field.outCallSuccNumField": "Verbundene manuelle ausgehende Anrufe",
  "cms.report.field.outAnswerRateField": "Verbindungsrate ausgehender Anrufe",
  "cms.report.field.outAvgRingTimeField": "Durchschnittliche Klingeldauer manueller ausgehender Anrufe",
  "cms.report.field.outAvgCallTimeField": "Durchschnittliche Dauer des manuellen ausgehenden Anrufs",
  "cms.report.field.acdCallField": "Gesamtzahl verbundener Anrufe",
  "cms.report.field.avgCallPerHourField": "Durchschnittliche manuelle eingehende Anrufe pro Stunde",
  "cms.report.field.occupancyRateField": "Arbeitsdauer",
  "cms.report.field.utilizationRateField": "Dauerrate des Arbeitsstatus",
  "cms.report.field.workTimeField": "Gesamtarbeitsdauer",
  "cms.report.field.acdTimeField": "Manuelle Anrufdauer",
  "cms.report.field.acdTimeRateField": "Rate der manuellen Anrufdauer",
  "cms.report.field.avgAcdTimeField": "Durchschnittliche manuelle Anrufdauer",
  "cms.report.field.acwTimeField": "Gesamtdauer der Nachbearbeitung",
  "cms.report.field.acwTimeRateField": "Rate der Nachbearbeitungsdauer",
  "cms.report.field.avgAcwTimeField": "Durchschnittliche Nachbearbeitungsdauer",
  "cms.report.field.avgHandleTimeField": "Durchschnittliche Verarbeitungsdauer",
  "cms.report.field.auxTimeField": "Gesamtruhezeit",
  "cms.report.field.auxTimeRateField": "Rate der Ruhezeit",
  "cms.report.field.busyTimeField": "Gesamtdauer der Belegung",
  "cms.report.field.busyTimeRateField": "Rate der Belegungsdauer",
  "cms.report.field.ringTimeField": "Gesamte Klingeldauer",
  "cms.report.field.availTimeField": "Leerlaufdauer",
  "cms.report.field.availTimeRateField": "Leerlaufrate",
  "cms.report.field.holdTimeField": "Aufbewahrungsdauer",
  "cms.report.field.avgHoldTimeField": "Durchschnittliche Haltedauer",
  "cms.report.field.outOccupyNumField": "Ausgehende Anrufe",
  "cms.report.field.outCallRateField": "Verbindungsrate ausgehender Anrufe",
  "cms.report.field.outAboundRingField": "Unbeantwortete ausgehende Anrufe",
  "cms.report.field.outInvalidCallField": "Ungültige ausgehende Anrufe",
  "cms.report.field.outFailNumField": "Gesamte fehlgeschlagene ausgehende Anrufe",
  "cms.report.field.outCallTimeField": "Gesamtdauer des ausgehenden Anrufs",
  "cms.report.field.outWaitAnsTimeField": "Gesamte Klingeldauer der ausgehenden Anrufe",
  "cms.report.field.outAvgAnsTimeField": "Durchschnittliche Klingeldauer ausgehender Anrufe",
  "cms.report.field.acwCountField": "Nachbearbeitungszeiten",
  "cms.report.field.acwOutTimeField": "Nachbearbeitungsdauer für ausgehende Anrufe",
  "cms.report.field.acwOutCountField": "Nachbearbeitungszeiten für ausgehende Anrufe",
  "cms.report.field.acwInCountField": "Nachbearbeitungszeiten eingehender Anrufe",
  "cms.report.field.acwInTimeField": "Nachbearbeitungsdauer eingehender Anrufe",
  "cms.report.field.auxCountField": "Ruhezeiten",
  "cms.report.field.holdCountField": "Wartezeiten",
  "cms.report.field.muteCountField": "Zeiten der Stille",
  "cms.report.field.muteTimeField": "Gesamte Stilledauer",
  "cms.report.field.assistsCountField": "Interne Hilfeanfragen",
  "cms.report.field.assistTimeField": "Dauer der internen Hilfeanfrage",
  "cms.report.field.busyCountField": "Gebuchte Zeiten",
  "cms.report.field.playVoiceCountField": "Stimmwiedergabezeiten",
  "cms.report.field.playVoiceTimeField": "Dauer der Stimmwiedergabe",
  "cms.report.field.otherTimeField": "Gesamtdauer anderer Zustände",
  "cms.report.field.loginCountField": "Anmeldezeiten",
  "cms.report.field.logoutCountField": "Abmeldezeiten",
  "cms.report.field.threePartyCallCountField": "Drei-Parteien-Anrufe",
  "cms.report.field.internalTransferCountField": "Interne Transfers",
  "cms.report.field.transferOutCountField": "Manuelle Transfer-Out-Zeiten",
  "cms.report.field.transferIvrCountField": "Übertragungszeiten von manuell zu IVR",
  "cms.report.field.internalCallCountField": "Interne Anrufe",
  "cms.report.field.internalCallTimeField": "Interne Anrufdauer",
  "cms.report.field.manToSkillNumField": "Manuelle Übertragungen von Warteschlangen an Kompetenzen",
  "cms.report.field.agentHangNumCtiField": "Auflegen von Agenten",
  "cms.report.field.custHangNumCtiField": "Auflegen von Kunden",
  "cms.report.field.otherHangNumCtiField": "Systemabstürze",
  "cms.report.field.manToIvrNumCtiField": "Anzahl der Male, die ein Agent für die Übertragung an das IVR suspendiert wurde",
  "cms.report.field.manTransferOutNumCtiField": "Anzahl der manuellen Übertragungen an IVR und der Rückübertragungen",
  "cms.report.field.systemInBoundField": "Gesamtzahl der eingehenden Systemanrufe",
  "cms.report.field.systemInSuccField": "Erfolgreiche eingehende Systemanrufe",
  "cms.report.field.sysSuccRateField": "Erfolgsquote eingehender Systemanrufe",
  "cms.report.field.sysLostCallsField": "Fehlerhafte eingehende Systemanrufe",
  "cms.report.field.IVRInBoundField": "Eingehende IVR-Anrufe",
  "cms.report.field.IVRInSuccField": "Verbundene eingehende IVR-Anrufe",
  "cms.report.field.IVRSuccRateField": "Verbindungsrate eingehender IVR-Anrufe",
  "cms.report.field.onlyIVRServiceField": "Erfolgreiche eingehende Anrufe mit reinem IVR",
  "cms.report.field.IVRServiceRateField": "Erfolgsquote eingehender Rein-IVR-Anrufe",
  "cms.report.field.agentOfferedField": "Manuelle eingehende Anrufe",
  "cms.report.field.agentServiceField": "Agent nahm Anrufe entgegen",
  "cms.report.field.awarnessRateField": "Erfolgsquote manueller eingehender Anrufe",
  "cms.report.field.agentServiceRateField": "Manuelle Erfolgsquote bei allen eingehenden Anrufen",
  "cms.report.field.avgAnsTimeField": "Durchschnittliche Warteschlangendauer verbundener Anrufe",
  "cms.report.field.ans20sField": "Rate der manuellen Anrufverbindung in 20s",
  "cms.report.field.avgCallInTimeField": "Durchschnittliche Anrufdauer",
  "cms.report.field.avgInRingTimeField": "Durchschnittliche Dauer eingehender Anrufe von Terminals",
  "cms.report.field.failAnsRateField": "Anforderungsverlustrate",
  "cms.report.field.failAvgTimeField": "Durchschnittliche Klingeldauer verlorener Anrufe",
  "cms.report.field.maxQueueWaitTimeField": "Maximale Wartezeit",
  "cms.report.field.outSuccAnsRateField": "Verbindungsrate ausgehender Anrufe",
  "cms.report.field.acdCallsField": "Gesamtzahl verbundener Anrufe",
  "cms.report.field.avgPerHNumField": "Durchschnittlich erfolgreiche Anrufe pro Stunde",
  "cms.report.field.agentLoginNumField": "Angerufene Agenten",
  "cms.report.field.perAgentNumField": "Agent ruft an",
  "cms.report.field.workRateField": "Arbeitsdauer",
  "cms.report.field.onlineRateField": "Online-Tarif",
  "cms.report.field.otherTimeRateField": "Andere Laufzeitrate",
  "cms.report.field.inCallNumField": "Eingehende Anrufe",
  "cms.report.field.inCallSuccNumberField": "Verbundene eingehende Anrufe",
  "cms.report.field.agentInBoundField": "Manuelle eingehende Anrufe",
  "cms.report.field.agentInSuccField": "Verbundene manuelle eingehende Anrufe",
  "cms.report.field.outCallNumField": "Ausgehende Anrufe",
  "cms.report.field.outCallSuccNumberField": "Verbundene ausgehende Anrufe",
  "cms.report.field.IVROutCallNumField": "Ausgehende Anrufe mit reinem IVR",
  "cms.report.field.IVROutSuccNumField": "Verbundene ausgehende Anrufe mit reinem IVR",
  "cms.report.field.agentOutBoundField": "Manuelle ausgehende Anrufe",
  "cms.report.field.agentOutSuccField": "Verbundene manuelle ausgehende Anrufe",
  "cms.report.field.inCallSuccRateField": "Verbindungsrate eingehender Anrufe",
  "cms.report.field.IVRInSuccRateField": "Verbindungsrate eingehender IVR-Anrufe",
  "cms.report.field.agentInSuccRateField": "Verbindungsrate für manuelle eingehende Anrufe",
  "cms.report.field.outCallSuccRateField": "Verbindungsrate ausgehender Anrufe",
  "cms.report.field.IVROutSuccRateField": "Verbindungsrate für ausgehende Rein-IVR-Anrufe",
  "cms.report.field.agentOutSuccRateField": "Verbindungsrate für manuelle ausgehende Anrufe",
  "cms.report.field.inVdnAvgTimeField": "Durchschnittliche Dauer eingehender Anrufe in VDN",
  "cms.report.field.outVdnAvgTimeField": "Durchschnittliche Dauer des ausgehenden Anrufs in VDN",
  "cms.report.field.IVRCallOutNumField": "Ausgehende IVR-Anrufe",
  "cms.report.field.IVRCallOutSuccNumField": "Verbundene ausgehende IVR-Anrufe",
  "cms.report.field.IVRCallOutSuccRateField": "Verbindungsrate des ausgehenden IVR-Anrufs",
  "cms.report.field.userBusyFailNumField": "Verlorene Anrufe aufgrund besetzter Leitungen",
  "cms.report.field.userRefusedFailNumField": "Verlorene Anrufe aufgrund von Anrufablehnung",
  "cms.report.field.userNoAnswerNumField": "Verlorene Anrufe aufgrund von Klingelzeitüberschreitung",
  "cms.report.field.callLostOtherNumField": "Andere verlorene Anrufe",
  "cms.report.field.minAgentInCountField": "Min. gleichzeitige eingehende Agentenanrufe",
  "cms.report.field.maxAgentInCountField": "Maximale Anzahl gleichzeitiger eingehender Agentenanrufe",
  "cms.report.field.avgAgentInCountField": "Durchschn. gleichzeitig eingehende Agentenanrufe",
  "cms.report.field.minAgentOutCountField": "Min. gleichzeitige ausgehende Agentenanrufe",
  "cms.report.field.maxAgentOutCountField": "Maximale gleichzeitige ausgehende Agentenanrufe",
  "cms.report.field.avgAgentOutCountField": "Durchschn. gleichzeitige ausgehende Agentenanrufe",
  "cms.report.field.minAgentCountField": "Min. gleichzeitige Agentenanrufe",
  "cms.report.field.maxAgentCountField": "Maximale gleichzeitige Agentenanrufe",
  "cms.report.field.avgAgentCountField": "Durchschn. gleichzeitige Agentenanrufe",
  "cms.report.field.minIVRInCountField": "Min. gleichzeitige eingehende IVR-Anrufe",
  "cms.report.field.maxIVRInCountField": "Maximale Anzahl gleichzeitiger eingehender IVR-Anrufe",
  "cms.report.field.avgIVRInCountField": "Durchschnittlich gleichzeitige eingehende IVR-Anrufe",
  "cms.report.field.minIVROutCountField": "Min. gleichzeitige ausgehende IVR-Anrufe",
  "cms.report.field.maxIVROutCountField": "Maximale gleichzeitige ausgehende IVR-Anrufe",
  "cms.report.field.avgIVROutCountField": "Durchschn. gleichzeitige ausgehende IVR-Anrufe",
  "cms.report.field.minIVRCountField": "Min. gleichzeitige IVR-Anrufe",
  "cms.report.field.maxIVRCountField": "Maximale gleichzeitige IVR-Anrufe",
  "cms.report.field.avgIVRCountField": "Durchschn. gleichzeitige IVR-Anrufe",
  "cms.report.field.minQueueInCountField": "Mindest gleichzeitige eingehende Anrufe in der Skill-Warteschlange",
  "cms.report.field.maxQueueInCountField": "Maximale Anzahl gleichzeitiger eingehender Anrufe in der Skill-Warteschlange",
  "cms.report.field.avgQueueInCountField": "Durchschn. gleichzeitige eingehende Anrufe in der Skill-Warteschlange",
  "cms.report.field.minQueueOutCountField": "Mindest gleichzeitige ausgehende Anrufe in der Skill-Warteschlange",
  "cms.report.field.maxQueueOutCountField": "Maximale gleichzeitige ausgehende Anrufe in der Skill-Warteschlange",
  "cms.report.field.avgQueueOutCountField": "Durchschn. gleichzeitige ausgehende Anrufe in der Skill-Warteschlange",
  "cms.report.field.minQueueCountField": "Min. gleichzeitige Skill-Warteschlangenanrufe",
  "cms.report.field.maxQueueCountField": "Maximale gleichzeitige Skill-Warteschlangenanrufe",
  "cms.report.field.avgQueueCountField": "Durchschn. gleichzeitige Skill-Queue-Anrufe",
  "cms.report.message.inOccupyNumDesc": "Anzahl der eingehenden Anrufe, die manuelle Dienste anfordern.",
  "cms.report.message.inCallSuccNumDesc": "Eingehende Anrufe mit Agentenverbindung.",
  "cms.report.message.callSuccRateDesc": "Prozentualer Anteil der verbundenen manuellen eingehenden Anrufanfragen an der Anzahl der manuell eingehenden Anrufe, die Dienste anfordern.",
  "cms.report.message.inCallFailNumDesc": "Anzahl der verlorenen Anrufanfragen = Anzahl der Serviceanfragen – Anzahl der verbundenen Anrufanfragen",
  "cms.report.message.call3RateDesc": "Prozentualer Anteil der Anrufanfragen, die innerhalb von 15 Sekunden manuell verbunden wurden, an der Gesamtzahl der Anrufe, die manuelle Dienste anfordern.",
  "cms.report.message.call4RateDesc": "Prozentsatz der Anzahl der Anrufanfragen, die innerhalb von 20 Sekunden manuell verbunden wurden, an der Gesamtzahl der Anrufe, die manuelle Dienste anfordern.",
  "cms.report.message.call6RateDesc": "Prozentsatz der Anzahl der Anrufanfragen, die innerhalb von 30 Sekunden manuell verbunden wurden, an der Gesamtzahl der Anrufe, die manuelle Dienste anfordern.",
  "cms.report.message.succWaitAnsRateDesc": "Durchschnittliche Wartezeit aller verbundenen Anrufe in einer Warteschleife.",
  "cms.report.message.failQueueWaitRateDesc": "Durchschnittliche Klingeldauer aller unbeantworteten Anrufe.",
  "cms.report.message.inCallRateDesc": "Durchschnittliche Anrufdauer. Durchschnittliche Dauer verbundener manueller eingehender Anrufe = Dauer verbundener manueller eingehender Anrufe/Anzahl verbundener manueller eingehender Anrufe.",
  "cms.report.message.waitAnsRateDesc": "Durchschnittliche Rufdauer des Terminals eines Agenten. Durchschnittliche Klingeldauer manueller eingehender Anrufe = Gesamtklingeldauer manueller eingehender Anrufe/Anzahl der Antwortzeiten.",
  "cms.report.message.abortQueueWaitNumDesc": "Anzahl der Anrufe, die von Teilnehmern während des Einreihens in die Warteschlange und des Klingelns proaktiv abgebrochen wurden.",
  "cms.report.message.noAckNumDesc": "Anzahl der unbeantworteten Anrufe nach Zuweisung an Agenten.",
  "cms.report.message.inWaitAnsNumDesc": "Anzahl der eingehenden Anrufanfragen für den manuellen Service.",
  "cms.report.message.inAnswerRateDesc": "Prozentualer Anteil der verbundenen manuell eingehenden Anrufe an der Anzahl der manuell eingehenden Anrufe.",
  "cms.report.message.callAbandonedRingDesc": "Anzahl der verlorenen eingehenden manuellen Anrufe = Anzahl der eingehenden manuellen Anrufe – Anzahl der verbundenen eingehenden manuellen Anrufe",
  "cms.report.message.inAvgRingTimeDesc": "Durchschnittliche Rufdauer des Terminals eines Agenten. Durchschnittliche Klingeldauer manueller eingehender Anrufe = Gesamtklingeldauer manueller eingehender Anrufe/Anzahl der Antwortzeiten.",
  "cms.report.message.inAvgCallTimeDesc": "Durchschnittliche Anrufdauer. Durchschnittliche Dauer verbundener manueller eingehender Anrufe = Dauer verbundener manueller eingehender Anrufe/Anzahl verbundener manueller eingehender Anrufe.",
  "cms.report.message.outWaitAnsNumDesc": "Anzahl der manuellen ausgehenden Anrufe, einschließlich der von Agenten initiierten ausgehenden Anrufe und der vom IVR an manuelle Dienste weitergeleiteten Anrufe.",
  "cms.report.message.outCallSuccNumDesc": "Anzahl der verbundenen manuellen ausgehenden Anrufe.",
  "cms.report.message.outAnswerRateDesc": "Prozentualer Anteil der verbundenen ausgehenden Anrufe an der Anzahl der ausgehenden Anrufe.",
  "cms.report.message.outAvgRingTimeDesc": "Durchschnittliche Zeit, die ein Agent auf die Annahme ausgehender Anrufe von Kunden wartet.",
  "cms.report.message.outAvgCallTimeDesc": "Die durchschnittliche Dauer des ausgehenden Anrufs zwischen einem Agenten und Kunden.",
  "cms.report.message.acdCallDesc": "Anzahl der verbundenen eingehenden und ausgehenden Anrufe.",
  "cms.report.message.avgCallPerHourDesc": "Durchschnittliche Anzahl der Anrufe, die von einem Agenten pro Stunde im ausgewählten Arbeitszeitsegment beantwortet werden.",
  "cms.report.message.occupancyRateDesc": "Arbeitsdauer = (Gesamtarbeitsdauer – Ruhedauer)/Gesamtarbeitsdauer x 100",
  "cms.report.message.utilizationRateDesc": "Dauer des Arbeitsstatus = (Gesamte Anrufdauer + Gesamte Nachbearbeitungsdauer)/(Gesamte Arbeitsdauer – Ruhedauer) x 100",
  "cms.report.message.overNumReason": "Multimedia-Agenten und Allround-Agenten können Anrufe über mehrere Kanäle gleichzeitig bearbeiten.",
  "cms.report.message.workTimeDesc": "Gesamtdauer von dem Zeitpunkt, zu dem sich ein Agent anmeldet, bis zu dem Zeitpunkt, zu dem sich der Agent abmeldet.",
  "cms.report.message.acdTimeDesc": "Gesamte Anrufdauer eines Service Desk-Mitarbeiters ohne die Dauer des Textchats.",
  "cms.report.message.acdTimeRateDesc": "Dauer des manuellen Anrufs = Dauer des manuellen Anrufs/Gesamtarbeitsdauer x 100",
  "cms.report.message.avgAcdTimeDesc": "Durchschnittliche Anrufdauer eines Agenten. Durchschnittliche Dauer des manuellen Anrufs = Dauer des manuellen Anrufs/Anzahl erfolgreicher manueller Anrufe",
  "cms.report.message.acwTimeDesc": "Gesamtdauer, wenn sich ein Agent im Nachbearbeitungsstatus befindet.",
  "cms.report.message.acwTimeRateDesc": "Rate der Nachbearbeitungsdauer = (Gesamtnachbearbeitungsdauer/Gesamtarbeitsdauer) x 100",
  "cms.report.message.avgAcwTimeDesc": "Durchschnittliche Dauer, wenn sich ein Agent im Nachbearbeitungsstatus befindet. Durchschnittliche Nachbearbeitungsdauer = Gesamte Nachbearbeitungsdauer/Anzahl der Nachbearbeitungszeiten",
  "cms.report.message.avgHandleTimeDesc": "Durchschnittliche Verarbeitungsdauer = (Dauer des manuellen Anrufs + Gesamtdauer der Nachbearbeitung)/Anzahl erfolgreicher manueller Anrufe",
  "cms.report.message.auxTimeDesc": "Gesamte Urlaubsdauer eines Agenten oder Dauer, wenn sich der Agent im Ruhezustand befindet.",
  "cms.report.message.auxTimeRateDesc": "Ruhedauer = (Ruhedauer insgesamt/Arbeitsdauer insgesamt) x 100",
  "cms.report.message.busyTimeDesc": "Gesamtdauer, wenn sich ein Agent im Besetzt-Status befindet.",
  "cms.report.message.busyTimeRateDesc": "Gebucht-Dauer = (Gesamtbelegt-Dauer/Gesamtarbeitsdauer) x 100",
  "cms.report.message.ringTimeDesc": "Gesamte Rufdauer eines Agenten ohne die Anrufdauer.",
  "cms.report.message.availTimeDesc": "Gesamtdauer, wenn sich ein Agent im Leerlauf befindet.",
  "cms.report.message.availTimeRateDesc": "Prozentualer Anteil der gesamten Leerlaufdauer eines Agenten an der gesamten Arbeitsdauer.",
  "cms.report.message.holdTimeDesc": "Gesamtdauer, wenn sich ein Agent im Haltezustand befindet. Die Anrufdauer eines Agenten umfasst die Haltezeit des Anrufs.",
  "cms.report.message.avgHoldTimeDesc": "Durchschnittliche Haltedauer des Anrufs. Durchschnittliche Haltedauer = Haltedauer/Anzahl der Haltezeiten",
  "cms.report.message.outOccupyNumDesc": "Anzahl der Anrufe eines Agenten bei Kunden.",
  "cms.report.message.outCallRateDesc": "Prozentualer Anteil der verbundenen ausgehenden Anrufe an der Anzahl der ausgehenden Anrufe.",
  "cms.report.message.outAboundRingDesc": "Anzahl der ausgehenden Anrufe, die von Kunden abgelehnt oder nach dem Klingeln nicht angenommen werden.",
  "cms.report.message.outInvalidCallDesc": "Anzahl der ausgehenden Anrufe, die Kunden aufgrund ungültiger Nummern oder ausgeschalteter Anrufe nicht erreichen können.",
  "cms.report.message.outFailNumDesc": "Gesamtzahl der fehlgeschlagenen ausgehenden Anrufe.",
  "cms.report.message.outCallTimeDesc": "Gesamtdauer des ausgehenden Anrufs zwischen einem Agenten und Kunden.",
  "cms.report.message.outWaitAnsTimeDesc": "Gesamte Klingeldauer der ausgehenden Anrufe.",
  "cms.report.message.outAvgAnsTimeDesc": "Durchschnittliche Klingeldauer ausgehender Anrufe = Gesamtklingeldauer ausgehender Anrufe/Anzahl der Antwortzeiten",
  "cms.report.message.acwCountDesc": "Anzahl der Male, die ein Agent in den Nachbearbeitungsstatus übergeht.",
  "cms.report.message.acwOutTimeDesc": "Nachbearbeitungsdauer nach Ende der ausgehenden Anrufe.",
  "cms.report.message.acwOutCountDesc": "Anzahl der Male, die ein Agent in den Nachbearbeitungsstatus übergeht, nachdem ausgehende Anrufe beendet wurden.",
  "cms.report.message.acwInCountDesc": "Anzahl der Male, die ein Agent in den Nachbearbeitungsstatus übergeht, nachdem eingehende Anrufe beendet wurden.",
  "cms.report.message.acwInTimeDesc": "Nachbearbeitungsdauer nach Ende eingehender Anrufe.",
  "cms.report.message.auxCountDesc": "Anzahl der Male, die ein Agent in den Ruhezustand übergeht.",
  "cms.report.message.holdCountDesc": "Anzahl der Haltezeiten.",
  "cms.report.message.muteCountDesc": "Anzahl der Stillezeiten.",
  "cms.report.message.muteTimeDesc": "Dauer der Stille.",
  "cms.report.message.assistsCountDesc": "Anzahl der internen Hilfeanfragen.",
  "cms.report.message.assistTimeDesc": "Dauer interner Hilfeanfragen.",
  "cms.report.message.busyCountDesc": "Anzahl der Male, die ein Agent in den Besetzt-Status wechselt.",
  "cms.report.message.playVoiceCountDesc": "Anzahl der Stimmenwiedergabezeiten.",
  "cms.report.message.playVoiceTimeDesc": "Dauer der Stimmwiedergabe.",
  "cms.report.message.otherTimeDesc": "Gesamtdauer, wenn sich ein Agent in einem anderen Status befindet, oder Anrufdauer anderer Skills, wenn mehrere Skills vorhanden sind.",
  "cms.report.message.loginCountDesc": "Anzahl der Anmeldezeiten.",
  "cms.report.message.logoutCountDesc": "Anzahl der Abmeldezeiten.",
  "cms.report.message.threePartyCallCountDesc": "Anzahl der Anrufe von drei Teilnehmern.",
  "cms.report.message.internalTransferCountDesc": "Anzahl der internen Übertragungszeiten.",
  "cms.report.message.transferOutCountDesc": "Anzahl der manuellen Transfer-Out-Zeiten.",
  "cms.report.message.transferIvrCountDesc": "Anzahl der manuellen an IVR-Übertragungszeiten.",
  "cms.report.message.internalCallCountDesc": "Anzahl der internen Anrufe.",
  "cms.report.message.internalCallTimeDesc": "Interne Anrufdauer.",
  "cms.report.message.manToSkillNumDesc": "Anzahl der manuellen Transfers in die Skill-Warteschlange.",
  "cms.report.message.agentHangNumCtiDesc": "Anzahl der Agentenabstürze.",
  "cms.report.message.custHangNumCtiDesc": "Anzahl der Kundenabstürze.",
  "cms.report.message.otherHangNumCtiDesc": "Anzahl der Systemabstürze.",
  "cms.report.message.manToIvrNumCtiDesc": "Anzahl der Male, die der Agent den Anruf unterbricht und an das IVR weiterleitet, nachdem der Anruf verbunden wurde.",
  "cms.report.message.manTransferOutNumCtiDesc": "Anzahl der Male, die der Agent zum IVR überträgt und dann wieder hinaus überträgt.",
  "cms.report.message.systemInBoundDesc": "Gesamtzahl der eingehenden Systemanrufe (ohne interne eingehende Anrufe).",
  "cms.report.message.systemInSuccDesc": "Anzahl der erfolgreichen eingehenden Systemanrufe, einschließlich der Anrufe während der IVR-Sprachwiedergabe, der Warteschlangen, des Klingelns an Agenten-Terminals und der Anrufannahme durch Agenten (mit Ausnahme interner eingehender Anrufe).",
  "cms.report.message.sysSuccRateDesc": "Prozentualer Anteil der erfolgreichen eingehenden Systemanrufe an der Gesamtzahl der eingehenden Systemanrufe.",
  "cms.report.message.sysLostCallsDesc": "Anzahl der fehlgeschlagenen eingehenden Systemanrufe = Gesamtanzahl der eingehenden Systemanrufe – Anzahl der erfolgreichen eingehenden Systemanrufe.",
  "cms.report.message.IVRInBoundDesc": "Gesamtzahl der eingehenden Anrufe, die über das IVR getätigt wurden.",
  "cms.report.message.IVRInSuccDesc": "Anzahl der eingehenden IVR-Anrufe, deren Anrufdauer größer als 0 ist.",
  "cms.report.message.IVRSuccRateDesc": "Prozentualer Anteil der verbundenen eingehenden IVR-Anrufe an der Anzahl der eingehenden IVR-Anrufanfragen.",
  "cms.report.message.onlyIVRServiceDesc": "Anzahl der erfolgreichen eingehenden Anrufe, die nur vom IVR verarbeitet wurden, d. h. die Anzahl der erfolgreichen eingehenden Anrufe, die von keinem Agenten verarbeitet wurden.",
  "cms.report.message.IVRServiceRateDesc": "Prozentualer Anteil der Anrufe, die nur vom IVR bearbeitet werden, an der Gesamtzahl der eingehenden Anrufe. Erfolgsquote eingehender reiner IVR-Anrufe = Erfolgreiche eingehende reine IVR-Anrufe/ (Erfolgreiche eingehende Anrufe von reinem IVR + Anzahl der von Agenten beantworteten Anrufe) x 100 Zoll",
  "cms.report.message.agentOfferedDesc": "Gesamtzahl der eingehenden Anrufe, die Agentendienste anfordern.",
  "cms.report.message.agentServiceDesc": "Anzahl der erfolgreich von Agenten beantworteten Anrufe.",
  "cms.report.message.awarnessRateDesc": "Prozentualer Anteil der von Agenten beantworteten Anrufe an der Anzahl der manuell eingehenden Anrufe.",
  "cms.report.message.agentServiceRateDesc": "Prozentualer Anteil der von Agenten beantworteten Anrufe an der Gesamtzahl der eingehenden Anrufe. Manuelle Erfolgsquote bei allen eingehenden Anrufen = Anzahl der vom Agenten beantworteten Anrufe/ (Anzahl der erfolgreichen eingehenden IVR-Anrufe + Anzahl der vom Agenten beantworteten Anrufe) x 100",
  "cms.report.message.avgAnsTimeDesc": "Durchschnittliche Warteschleifendauer aller verbundenen Anrufe in einer Warteschleife.",
  "cms.report.message.ans20sDesc": "Prozentualer Anteil der manuell verbundenen Anrufe in 20 Sekunden an der Gesamtzahl der manuell verbundenen Anrufe.",
  "cms.report.message.avgCallInTimeDesc": "Durchschnittliche Anrufdauer. Durchschnittliche Dauer verbundener manueller eingehender Anrufe = Dauer verbundener manueller eingehender Anrufe/Anzahl verbundener manueller eingehender Anrufe.",
  "cms.report.message.avgInRingTimeDesc": "Durchschnittliche Dauer eingehender Anrufe über Agententerminals.",
  "cms.report.message.failAnsRateDesc": "Anforderungsverlustrate = 100 – Anrufverbindungsrate",
  "cms.report.message.failAvgTimeDesc": "Durchschnittliche Klingeldauer aller unbeantworteten Anrufe.",
  "cms.report.message.maxQueueWaitTimeDesc": "Maximale Wartezeit (Warteschlangen- und Klingeldauer) aller Anrufe.",
  "cms.report.message.outSuccAnsRateDesc": "Prozentualer Anteil der verbundenen ausgehenden Anrufe an der Anzahl der ausgehenden Anrufe.",
  "cms.report.message.acdCallsDesc": "Anzahl der verbundenen eingehenden und ausgehenden Anrufe.",
  "cms.report.message.avgPerHNumDesc": "Durchschnittliche Anzahl erfolgreicher Anrufe pro Stunde im ausgewählten Arbeitszeitsegment.",
  "cms.report.message.agentLoginNumDesc": "Anzahl der Agenten, die eingehende Anrufe erhalten.",
  "cms.report.message.perAgentNumDesc": "Die Anzahl der eingehenden Anrufe, die jedem Agenten während des ausgewählten Zeitraums zugewiesen wurden.",
  "cms.report.message.workRateDesc": "Arbeitsdauer = (Gesamtarbeitsdauer – Ruhedauer)/Gesamtarbeitsdauer x 100",
  "cms.report.message.onlineRateDesc": "Online-Rate = (Manuelle Anrufdauer + Gesamtdauer der Nachbearbeitung + Gesamtdauer anderer Status)/(Gesamtarbeitsdauer – Ruhezeit) x 100",
  "cms.report.message.otherTimeRateDesc": "Rate der sonstigen Dauer = (Gesamtdauer anderer Status/Gesamtarbeitsdauer) x 100",
  "cms.report.message.inCallNumDesc": "Gesamtzahl der eingehenden Anrufe im VDN. Der Wert ist die Summe der manuell eingehenden Anrufe und der eingehenden Anrufe, die automatisch über das IVR getätigt werden.",
  "cms.report.message.inCallSuccNumberDesc": "Anzahl der verbundenen eingehenden Anrufe. Der Wert ist die Summe der verbundenen manuellen eingehenden Anrufe und der eingehenden Anrufe, die automatisch über das IVR verbunden werden.",
  "cms.report.message.agentInBoundDesc": "Anzahl der eingehenden Anrufe, die Kompetenzwarteschlangen oder Agenten zugewiesen wurden.",
  "cms.report.message.agentInSuccDesc": "Anzahl der manuellen eingehenden Anrufe, die mit Agenten verbunden sind, d. h. die Anzahl der manuellen eingehenden Anrufe, deren Anrufdauer größer als 0 ist.",
  "cms.report.message.outCallNumDesc": "Anzahl der manuellen und automatischen ausgehenden Anrufe im VDN. Der Wert ist die Summe der manuellen ausgehenden Anrufe und der automatisch über das IVR getätigten ausgehenden Anrufe.",
  "cms.report.message.outCallSuccNumberDesc": "Anzahl der automatischen und manuellen ausgehenden Anrufe, die von den angerufenen Teilnehmern beantwortet werden. Der Wert ist die Summe der verbundenen manuellen ausgehenden Anrufe und der automatisch über das IVR verbundenen ausgehenden Anrufe.",
  "cms.report.message.IVROutCallNumDesc": "Anzahl der ausgehenden Anrufe, die nur vom IVR getätigt wurden.",
  "cms.report.message.IVROutSuccNumDesc": "Anzahl der verbundenen ausgehenden IVR-Anrufe, deren IVR-Gerätebelegungsdauer größer als 0 ist.",
  "cms.report.message.agentOutBoundDesc": "Anzahl der manuellen ausgehenden Anrufe von Agenten.",
  "cms.report.message.agentOutSuccDesc": "Anzahl der manuellen ausgehenden Anrufe, die mit Benutzern verbunden sind, d. h. die Anzahl der manuellen ausgehenden Anrufe, deren Anrufdauer größer als 0 ist.",
  "cms.report.message.inCallSuccRateDesc": "Prozentsatz der verbundenen eingehenden Anrufe im Verhältnis zur Gesamtzahl der eingehenden Anrufe.",
  "cms.report.message.IVRInSuccRateDesc": "Prozentsatz der verbundenen eingehenden IVR-Anrufe im Verhältnis zur Gesamtzahl der eingehenden IVR-Anrufe.",
  "cms.report.message.agentInSuccRateDesc": "Prozentsatz der verbundenen eingehenden manuellen Anrufe im Verhältnis zur Gesamtzahl der eingehenden manuellen Anrufe.",
  "cms.report.message.outCallSuccRateDesc": "Prozentsatz der verbundenen ausgehenden Anrufe im Verhältnis zur Gesamtzahl der ausgehenden Anrufe.",
  "cms.report.message.IVROutSuccRateDesc": "Prozentsatz der verbundenen ausgehenden Anrufe mit reinem IVR im Verhältnis zur Gesamtzahl der ausgehenden Anrufe mit reinem IVR.",
  "cms.report.message.agentOutSuccRateDesc": "Prozentsatz der verbundenen manuellen ausgehenden Anrufe im Verhältnis zur Gesamtzahl der manuellen ausgehenden Anrufe.",
  "cms.report.message.inVdnAvgTimeDesc": "Durchschnittliche Dauer eingehender Anrufe im VDN. Der Wert ist die Gesamtdauer eingehender Anrufe geteilt durch die Anzahl der eingehenden Anrufe.",
  "cms.report.message.outVdnAvgTimeDesc": "Durchschnittliche Dauer des ausgehenden Anrufs im VDN. Der Wert ist die Gesamtdauer des ausgehenden Anrufs geteilt durch die Anzahl der ausgehenden Anrufe.",
  "cms.report.message.IVRCallOutNumDesc": "Gesamtzahl der ausgehenden IVR-Anrufe.",
  "cms.report.message.IVRCallOutSuccNumDesc": "Anzahl der ausgehenden IVR-Anrufe, deren Anrufdauer länger als 0 ist.",
  "cms.report.message.IVRCallOutSuccRateDesc": "Prozentsatz der verbundenen ausgehenden IVR-Anrufe im Verhältnis zu den gesamten ausgehenden IVR-Anrufanfragen.",
  "cms.report.message.userBusyFailNumDesc": "Anzahl der Anrufe, die aufgrund besetzter Leitungen nicht angenommen werden.",
  "cms.report.message.userRefusedFailNumDesc": "Anzahl der Anrufe, die aufgrund einer Anrufablehnung nicht angenommen werden.",
  "cms.report.message.userNoAnswerNumDesc": "Anzahl der Anrufe, die aufgrund einer Klingelzeitüberschreitung nicht angenommen werden.",
  "cms.report.message.callLostOtherNumDesc": "Anzahl der Anrufe, die aus anderen Gründen als besetzte Leitungen, Anrufablehnung und Klingelzeitüberschreitung nicht angenommen werden.",
  "cms.report.message.minAgentInCountDesc": "Mindestanzahl gleichzeitiger eingehender Anrufe, deren Servicegerätetyp Agent ist.",
  "cms.report.message.maxAgentInCountDesc": "Maximale Anzahl gleichzeitiger eingehender Anrufe, deren Servicegerätetyp Agent ist.",
  "cms.report.message.avgAgentInCountDesc": "Durchschnittliche Anzahl gleichzeitiger eingehender Anrufe, deren Servicegerätetyp Agent ist.",
  "cms.report.message.minAgentOutCountDesc": "Mindestanzahl gleichzeitiger ausgehender Anrufe, deren Servicegerätetyp Agent ist.",
  "cms.report.message.maxAgentOutCountDesc": "Maximale Anzahl gleichzeitiger ausgehender Anrufe, deren Servicegerätetyp Agent ist.",
  "cms.report.message.avgAgentOutCountDesc": "Durchschnittliche Anzahl der gleichzeitigen ausgehenden Anrufe, deren Servicegerätetyp Agent ist.",
  "cms.report.message.minAgentCountDesc": "Mindestanzahl gleichzeitiger Anrufe, deren Servicegerätetyp Agent ist.",
  "cms.report.message.maxAgentCountDesc": "Maximale Anzahl gleichzeitiger Anrufe, deren Servicegerätetyp Agent ist.",
  "cms.report.message.avgAgentCountDesc": "Durchschnittliche Anzahl gleichzeitiger Anrufe, deren Servicegerätetyp Agent ist.",
  "cms.report.message.minIVRInCountDesc": "Mindestanzahl gleichzeitiger eingehender Anrufe, deren Servicegerätetyp IVR ist.",
  "cms.report.message.maxIVRInCountDesc": "Maximale Anzahl gleichzeitiger eingehender Anrufe, deren Servicegerätetyp IVR ist.",
  "cms.report.message.avgIVRInCountDesc": "Durchschnittliche Anzahl gleichzeitiger eingehender Anrufe, deren Servicegerätetyp IVR ist.",
  "cms.report.message.minIVROutCountDesc": "Mindestanzahl gleichzeitiger ausgehender Anrufe, deren Servicegerätetyp IVR ist.",
  "cms.report.message.maxIVROutCountDesc": "Maximale Anzahl gleichzeitiger ausgehender Anrufe, deren Servicegerätetyp IVR ist.",
  "cms.report.message.avgIVROutCountDesc": "Durchschnittliche Anzahl gleichzeitiger ausgehender Anrufe, deren Servicegerätetyp IVR ist.",
  "cms.report.message.minIVRCountDesc": "Mindestanzahl gleichzeitiger Anrufe, deren Servicegerätetyp IVR ist.",
  "cms.report.message.maxIVRCountDesc": "Maximale Anzahl gleichzeitiger Anrufe, deren Servicegerätetyp IVR ist.",
  "cms.report.message.avgIVRCountDesc": "Durchschnittliche Anzahl gleichzeitiger Anrufe, deren Servicegerätetyp IVR ist.",
  "cms.report.message.minQueueInCountDesc": "Mindestanzahl gleichzeitiger eingehender Anrufe, deren Servicegerätetyp eine Skill-Warteschlange ist.",
  "cms.report.message.maxQueueInCountDesc": "Maximale Anzahl gleichzeitiger eingehender Anrufe, deren Servicegerätetyp eine Skill-Warteschlange ist.",
  "cms.report.message.avgQueueInCountDesc": "Durchschnittliche Anzahl gleichzeitiger eingehender Anrufe, deren Servicegerätetyp eine Fertigkeitswarteschlange ist.",
  "cms.report.message.minQueueOutCountDesc": "Mindestanzahl gleichzeitiger ausgehender Anrufe, deren Servicegerätetyp eine Skill-Warteschlange ist.",
  "cms.report.message.maxQueueOutCountDesc": "Maximale Anzahl gleichzeitiger ausgehender Anrufe, deren Servicegerätetyp eine Skill-Warteschlange ist.",
  "cms.report.message.avgQueueOutCountDesc": "Durchschnittliche Anzahl der gleichzeitigen ausgehenden Anrufe, deren Servicegerätetyp eine Fertigkeitswarteschleife ist.",
  "cms.report.message.minQueueCountDesc": "Mindestanzahl gleichzeitiger Anrufe, deren Servicegerätetyp eine Skill-Warteschlange ist.",
  "cms.report.message.maxQueueCountDesc": "Maximale Anzahl gleichzeitiger Anrufe, deren Servicegerättyp eine Skill-Warteschlange ist.",
  "cms.report.message.avgQueueCountDesc": "Durchschnittliche Anzahl gleichzeitiger Anrufe, deren Servicegerätetyp eine Fertigkeitswarteschleife ist.",
  "cms.report.field.acwRate": "Rate der Nachbearbeitungsdauer",
  "cms.report.field.IVRShortCallsField": "Anzahl der IVR-Kurzanrufe (innerhalb von 3 Sekunden)",
  "cms.report.field.totalLIVRServiceField": "Gesamte IVR-Servicezeit",
  "cms.report.field.avgIVRServiceField": "Durchschnittliche Dauer eingehender IVR-Anrufe",
  "cms.report.message.IVRShortCallsDesc": "Anzahl der Anrufe, deren IVR-Dauer größer als 0 und kleiner oder gleich 3 Sekunden ist.",
  "cms.report.message.totalLIVRServiceDesc": "Gesamtdauer des IVR-Anrufs. Wenn ein Anruf mehrmals mit dem IVR verbunden ist, entspricht die IVR-Dienstdauer der gesamten IVR-Anrufdauer.",
  "cms.report.message.avgIVRServiceDesc": "Durchschnittliche Dauer eingehender IVR-Anrufe = Dauer eingehender IVR-Anrufe/Anzahl der verbundenen eingehenden IVR-Anrufe.",
  "cms.report.label.export": "Exportieren",
  "cms.indexmonitor.field.accesscodeCalloutNum": "Anzahl der ausgehenden Anrufe",
  "cms.indexmonitor.message.accesscodeCalloutNum": "Anzahl der ausgehenden Anrufe von Rufnummern innerhalb einer Stunde",
  "cms.report.message.exportSucceed": "Export erfolgreich.",
  "cms.report.message.exportFailed": "Export fehlgeschlagen.",
  "cms.report.message.nomorethanerror": "Das ausgewählte Datum darf nicht vor {0} liegen.",
  "cms.report.field.callAbandonedInRingField": "Rufe im Ring verlassen",
  "cms.synchronize.message.notConfigured": "Nicht konfiguriert",
  "cms.synchronize.message.configured": "Konfiguriert",
  "cms.synchronize.message.incorrectPassword": "Falsch",
  "cms.synchronize.message.invalidAdminPwd": "Falsches Passwort. Ändern Sie das Administratorkennwort.",
  "cms.synchronize.message.noConfAdminPwd": "Das Kennwort ist nicht konfiguriert. Konfigurieren Sie das Administratorkennwort.",
  "cms.synchronize.message.invalidConfPwd": "Falsches Passwort. Ändern Sie das Kennwort der Konfigurationsoberfläche.",
  "cms.synchronize.message.noConfConfPwd": "Das Kennwort ist nicht konfiguriert. Konfigurieren Sie das Schnittstellenkennwort.",
  "cms.synchronize.message.adminPwdStatus": "Status des CTI-Administratorkennworts",
  "cms.synchronize.message.confPwdStatus": "Status des Kennworts der CCBCS-Schnittstelle",
  "cms.synchronize.message.syncStatus": "Synchronisierungsstatus",
  "cms.synchronize.message.syncStatusZero": "Zur Synchronisierung",
  "cms.synchronize.message.syncStatusOne": "Synchronisieren",
  "cms.synchronize.message.syncStatusTwo": "Synchronisierung erfolgreich.",
  "cms.synchronize.message.syncStatusThree": "Synchronisierung fehlgeschlagen.",
  "cms.report.message.noData": "Keine Daten verfügbar.",
  "cms.report.field.date": "Datum",
  "cms.report.field.accessNo": "Zugangscode:",
  "cms.report.field.skillQueue": "Skill-Warteschlange:",
  "cms.report.field.agentNum": "Agenten-ID",
  "cms.indexmonitor.message.warningFilterMax": "Es können maximal {0} Indikatoren ausgewählt werden.",
  "cms.report.title.callCenterMonitor": "VDN-Leistungsüberwachung",
  "cms.report.title.callCenterTrend": "VDN-Leistungstrend",
  "cms.report.title.callCenterAccessMonitor": "VDN-Leistungsüberwachung nach Zugangscode",
  "cms.report.title.callCenterAccessTrend": "VDN-Leistungstrend nach Zugangscode",
  "cms.report.title.IVRMonitor": "IVR-Datenüberwachung",
  "cms.report.title.IVRTrend": "IVR-Datentrend",
  "cms.report.title.IVRAccessMonitor": "Überwachen von Statistiken zu IVR-Daten nach Zugriffscode",
  "cms.report.title.IVRAccessTrend": "Trend der IVR-Daten nach Zugangscode",
  "cms.report.title.skillMonitor": "Leistungsüberwachung der Skill-Warteschlange",
  "cms.report.title.skillTrend": "Leistungstrend der Skill-Warteschlange",
  "cms.report.title.skillAccessMonitor": "Überwachen von Statistiken zur Leistung der Skill-Warteschlange nach Zugriffscode",
  "cms.report.title.skillAccessTrend": "Trend der Leistung der Skill-Warteschlange nach Zugriffscode",
  "cms.report.title.skillAllMonitor": "Überwachung der Kompetenzwarteschlangen-Zusammenfassung",
  "cms.report.title.skillAllTrend": "Trend der Zusammenfassung der Skill-Warteschlange",
  "cms.report.title.agentMonitor": "Überwachung der Agentenleistungsübersicht",
  "cms.report.title.agentTrend": "Trend der Agentenleistungsübersicht",
  "cms.report.title.agentOutCallMonitor": "Überwachung der Zusammenfassung ausgehender Anrufe des Agenten",
  "cms.report.title.agentOutCallTrend": "Trend der Zusammenfassung der ausgehenden Anrufe des Agenten",
  "cms.report.title.agentOperationMonitor": "Überwachung der Details des Agentenverbindungsvorgangs",
  "cms.report.title.agentOperationTrend": "Trend der Details zum Agentenverbindungsvorgang",
  "cms.report.title.abandonRingMonitor": "Überwachen der Zusammenfassung aufgegebener Anrufe während des Klingelns",
  "cms.report.title.abandonRingTrend": "Trend der aufgegebenen Anrufe während des Klingelns",
  "cms.report.title.vdnTrafficPeriodMonitor": "Überwachung der VDN-Traffic-Periode",
  "cms.report.title.vdnTrafficPeriodTrend": "Trend des VDN-Datenverkehrszeitraums",
  "cms.report.title.ivrOutboundMonitor": "Überwachung ausgehender IVR-Anrufe",
  "cms.report.title.ivrOutboundTrend": "Trend der ausgehenden IVR-Anrufe",
  "cms.report.title.vdnSystemPerformanceMonitor": "Überwachung der Systemleistung",
  "cms.report.title.vdnSystemPerformanceTrend": "Trend der Systemleistung",
  "cms.report.title.changeTable": "Zur Tabelle wechseln",
  "cms.report.title.changeChart": "Zu Diagramm wechseln",
  "cms.callcenterinstanceslist.message.updateCtiVersion": "Überprüfen Sie, ob die CTI-Datenmigration abgeschlossen ist. Gehen Sie bei diesem Vorgang mit Vorsicht vor.",
  "cms.callcenterinstanceslist.message.updateCtiVersion2": "Überprüfen Sie, ob die CTI-Datenmigration abgeschlossen ist. Seien Sie bei der Durchführung dieses Vorgangs vorsichtig. (Nach der Bestätigung des Vorgangs wird die automatische Synchronisierungsfunktion deaktiviert.)",
  "cms.callcenterinstanceslist.message.jobSwitch": "Automatische Synchronisierung aktivieren",

  "cms.reportsubscribe.field.subscriptionList": "Abonnementliste",
  "cms.reportsubscribe.field.subscribeContent": "Inhalte abonnieren",
  "cms.reportsubscribe.field.subscribeName": "Name des Abonnements",
  "cms.reportsubscribe.field.templateName": "Name der E-Mail-Vorlage",
  "cms.reportsubscribe.field.sentTime": "Gesendete Zeit",
  "cms.reportsubscribe.field.sentPeriod": "Gesendeter Zeitraum ",
  "cms.reportsubscribe.field.reportName": "Berichtsname",
  "cms.reportsubscribe.field.status": "Status",
  "cms.reportsubscribe.field.publish": "Veröffentlichen",
  "cms.reportsubscribe.field.prohibit": "Verboten",
  "cms.reportsubscribe.field.sentReport": "Bericht gesendet",
  "cms.reportsubscribe.field.selectedReport": "Liste der ausgewählten Berichte",
  "cms.reportsubscribe.field.basicInfo": "Grundlegende Informationen",
  "cms.reportsubscribe.field.enable": "Veröffentlicht",
  "cms.reportsubscribe.field.suspended": "Unveröffentlicht",
  "cms.reportsubscribe.field.monday": "Montag",
  "cms.reportsubscribe.field.tuesday": "Dienstag ",
  "cms.reportsubscribe.field.wednesday": "Mittwoch",
  "cms.reportsubscribe.field.thursday": "Donnerstag",
  "cms.reportsubscribe.field.friday": "Freitag",
  "cms.reportsubscribe.field.saturday": "Samstag",
  "cms.reportsubscribe.field.sunday": "Sonntag",
  "cms.reportsubscribe.title.modifyConfim": "Änderungsbestätigung",
  "cms.reportsubscribe.title.tips": "Informationen",
  "cms.reportsubscribe.message.searchSubscribeName": "Bitte geben Sie den Abonnementnamen ein",
  "cms.reportsubscribe.message.searchTemplateName": "Geben Sie den Vorlagennamen ein",
  "cms.reportsubscribe.message.deleteConfim": "Möchten Sie wirklich löschen?",
  "cms.reportsubscribe.message.modifyStatus": "Möchten Sie den Status wirklich ändern?",
  "cms.reportsubscribe.message.reportNumberError": "Alle Berichte wurden hinzugefügt",
  "cms.report.title.callCenterReport": "VDN-Verkehrsbericht",
  "cms.report.title.callCenterAccessReport": "VDN-Verkehrsbericht von AccessCode",
  "cms.report.title.IVRReport": "IVR-Verkehrsbericht",
  "cms.report.title.IVRAccessReport": "IVR-Datenverkehrsbericht nach Zugangscode",
  "cms.report.title.skillReport": "Bericht zum Skill-Warteschlangenverkehr",
  "cms.report.title.skillAccessReport": "Bericht über den Skill-Warteschlangenverkehr von AccessCode",
  "cms.report.title.skillAllReport": "Verkehrszusammenfassungsbericht",
  "cms.report.title.agentReport": "Bericht zum Agentenverkehr",
  "cms.report.title.agentOutCallReport": "Bericht über ausgehende Agentenanrufe",
  "cms.report.title.agentOperationReport": "Agentenbetriebsbericht",
  "cms.report.title.abandonRingReport": "Bericht über aufgegebenen Anruf in Klingelton",
  "cms.report.title.vdnTrafficPeriodReport": "Bericht zum VDN-Verkehrszeitraum",
  "cms.report.title.ivrOutboundReport": "Bericht über ausgehende IVR-Anrufe",
  "cms.report.title.vdnSystemPerformanceReport": "Überwachungsbericht zur Systemleistung",
  "cms.report.message.invalidParam": "Ungültiger Parameter.",
  "cms.report.message.tooManyExport": "Zu viele {0}-Exporte in kurzer Zeit.",
  "cms.report.message.queryFailed": "Abfrage {0} fehlgeschlagen.",
  "cms.report.message.dataOverError": "Abfragedatengröße von {0} über 10.000.",
  "cms.report.message.validateFailed": "Berichtsexport konnte nicht verifiziert werden.",
  "cms.report.message.callAbandonedInRingDesc": "Anzahl der Anrufe, die vom Anrufer abgebrochen wurden, während er klingelte, ohne angenommen zu werden.",
  "cms.report.field.succAnsRateField": "Verbindungsrate anfordern",
  "cms.report.message.succAnsRateDesc": "Prozentualer Anteil der verbundenen manuellen eingehenden Anrufanfragen an der Anzahl der manuell eingehenden Anrufe, die Dienste anfordern.",
  "cms.calllink.field.skillQueue": "Skill-Warteschlange",
  "cms.calllink.field.businessRepresentative": "Agent",
  "cms.calllink.field.ivrServiceProcess": "IVR-Geschäftsablauf",
  "cms.calllink.field.call": "Anruf",
  "cms.calllink.field.routingTemporaryDevice": "Temporäres Routinggerät",
  "cms.calllink.field.virtualDevice": "Virtuelles Gerät",
  "cms.calllink.field.cdnDevice": "CDN-Gerät",
  "cms.calllink.field.MELCASDevice": "MELCAS-Gerät",
  "cms.calllink.field.PGR": "PGR (Standard-CDN-Route, die von der SCAI gesteuert wird)",
  "cms.calllink.field.teleconference": "Konferenzgespräch",
  "cms.calllink.field.commonAccessTransfer": "Gemeinsamer Zugang oder Transfer.",
  "cms.calllink.field.overflowing": "Ein Anruf wird aufgrund eines Überlaufs weitergeleitet.",
  "cms.calllink.field.timeoutTransfer": "Ein Anruf wurde aufgrund einer Zeitüberschreitung weitergeleitet.",
  "cms.calllink.field.noOnDutyTransfer": "Ein Anruf wird weitergeleitet, weil niemand Dienst hat.",
  "cms.calllink.field.cancelQueuingTransfer": "Ein Anruf wird weitergeleitet, wenn das Einreihen in die Warteschlange abgebrochen wird.",
  "cms.calllink.field.noAnswerTransfer": "Ein Anruf wird weitergeleitet, weil lange Zeit keine Antwort von einem Agenten empfangen wurde.",
  "cms.calllink.field.suspendedAndResumed": "Ein unterbrochener Anruf wird fortgesetzt.",
  "cms.calllink.field.connectionHold": "Ein gehaltener Anruf ist verbunden.",
  "cms.calllink.field.threePartyHelp": "Der Anruf wird verwendet, um Hilfe von drei Parteien anzufordern.",
  "cms.calllink.field.unhold": "Ein Anruf wird nicht gehalten.",
  "cms.calllink.field.threePartyCall": "Der Anruf ist ein Anruf mit drei Teilnehmern.",
  "cms.calllink.field.helperRelease": "Ein Anruf wird von dem Teilnehmer, der die interne Hilfeanfrage sendet, getrennt.",
  "cms.calllink.field.intercepts": "Ein Anruf wird abgefangen.",
  "cms.calllink.field.pickup": "Ein Anruf wird von anderen angenommen.",
  "cms.calllink.field.analysisNotConfig": "Die Analyse der angerufenen Nummer ist nicht für einen Anruf konfiguriert.",
  "cms.calllink.field.activeRelease": "Der Teilnehmer trennt einen Anruf.",
  "cms.calllink.field.agentRelease": "Der Agent trennt einen Anruf.",
  "cms.calllink.field.transferOut": "Ein Anruf wird weitergeleitet.",
  "cms.calllink.field.hold": "Ein Anruf wird gehalten.",
  "cms.calllink.field.internalError": "Interner Fehler.",
  "cms.calllink.field.calledBusy": "Der angerufene Teilnehmer ist beschäftigt.",
  "cms.calllink.field.powerOff": "Das Telefon ist ausgeschaltet.",
  "cms.calllink.field.outOfService": "Das Telefon ist außer Betrieb.",
  "cms.calllink.field.unavailableNumber": "Die Nummer ist nicht zugewiesen.",
  "cms.calllink.field.noResources": "Auf dem ACD ist keine Ressource verfügbar.",
  "cms.calllink.field.doNotAnswer": "Der Teilnehmer nimmt einen Anruf nicht an.",
  "cms.calllink.field.callRejected": "Der angerufene Teilnehmer lehnt einen Anruf ab.",
  "cms.calllink.field.leaveReason28": "Erfolgreich in die Warteschlange übertragen.",
  "cms.calllink.field.leaveReason29": "Weiterleitung von Drittanbietern an Warteschlange.",
  "cms.calllink.field.leaveReason30": "Zeitüberschreitung bei erfolgreicher Übertragung in die Warteschlange.",
  "cms.calllink.field.leaveReason31": "Zeitüberschreitung bei der Weiterleitung an die Warteschlange durch Dritte.",
  "cms.calllink.field.leaveReason34": "Timeout/Überlauf/Netzwerkanruf-Warteschlangenfehler bei Besetzt.",
  "cms.calllink.field.leaveReason35": "Der Netzwerkanruf wird an das ursprüngliche Callcenter zurückgesendet und in die Warteschlange gestellt.",
  "cms.calllink.field.leaveReason36": "Umleitung von Anrufen in der Warteschlange.",
  "cms.calllink.field.leaveReason37": "Der Anruf wird an das CDN weitergeleitet.",
  "cms.calllink.field.leaveReason38": "Melden Sie sich nach Rücksprache mit dem Agenten beim CDN an.",
  "cms.calllink.field.leaveReason39": "Das CDN gibt das Routing-Ergebnis zurück.",
  "cms.calllink.field.leaveReason40": "CDN-Routing-Zeitüberschreitung.",
  "cms.calllink.field.leaveReason41": "Agentenabsage oder Routingfehler während der Beratung.",
  "cms.calllink.field.leaveReason42": "Die CDN-Route läuft ab und tritt in den PGR ein.",
  "cms.calllink.field.leaveReason43": "Das CDN gibt eine normale Route an den PGR zurück.",
  "cms.calllink.field.leaveReason44": "Eingehende Anrufe an PGR weiterleiten.",
  "cms.calllink.field.leaveReason45": "PGR wird über die Agentenberatung aufgerufen.",
  "cms.calllink.field.leaveReason46": "Agent nicht erreichbar, CDN-Umleitung",
  "cms.calllink.field.leaveReason47": "Anruf in mehreren Warteschlangen eingereiht.",
  "cms.calllink.field.leaveReason48": "Annehmen eines Anrufs in der primären Warteschleife in der sekundären Warteschleife.",
  "cms.calllink.field.leaveReason50": "Agent lehnt einen Anruf ab",
  "cms.calllink.field.leaveReason51": "Ein Benutzer ruft an, um an einer Telefonkonferenz teilzunehmen.",
  "cms.calllink.field.leaveReason52": "Der Vorsitzende gibt die Konferenz proaktiv frei, oder die Konferenz wird aufgrund einer CCS-Ausnahme freigegeben.",
  "cms.calllink.field.leaveReason53": "Der Teilnehmer wurde aus der Konferenz entfernt.",
  "cms.calllink.field.leaveReason54": "Die Anrufrate liegt unter dem Schwellenwert.",
  "cms.calllink.field.leaveReason88": "Der Benutzer legt auf, nachdem der Agent eine Verbindung mit dem Benutzer hergestellt hat.",
  "cms.calllink.field.leaveReason89": "Nachdem ein Agent die Antwortschnittstelle aufgerufen hat, stellt er keine Verbindung zum Benutzer her und der Benutzer legt auf.",
  "cms.mediaType1": "Textchat",
  "cms.mediaType2": "Klicken-zum-Wählen",
  "cms.mediaType3": "Betreutes Browsen und gemeinsame Nutzung von Formularen",
  "cms.mediaType4": "Anruf zur Rückrufanforderung",
  "cms.mediaType5": "Gemeinsamer Sprachanruf",
  "cms.mediaType6": "E-Mail-Anruf",
  "cms.mediaType7": "Faxanruf",
  "cms.mediaType8": "IP-Videoanruf (H.323)",
  "cms.mediaType9": "Elektronisches Whiteboard",
  "cms.mediaType10": "Anwendungsfreigabe",
  "cms.mediaType11": "Dateiübertragung",
  "cms.mediaType12": "2B+D ISDN-Videoanruf",
  "cms.mediaType13": "6B+D ISDN-Videoanruf",
  "cms.mediaType14": "OPS-Aufruf",
  "cms.mediaType15": "Prognostizierter ausgehender Anruf",
  "cms.mediaType16": "Vorschau für ausgehenden Anruf",
  "cms.mediaType17": "Nachrichtenmedien",
  "cms.mediaType18": "Videoanruf durch Klicken-zum-Wählen",
  "cms.mediaType19": "Gemeinsamer Videoanruf",
  "cms.mediaType20": "Nicht-Echtzeitanruf",
  "cms.mediaType53": "Multimedia",
  "cms.calllink.field.callType33": "Häufiger eingehender Anruf",
  "cms.calllink.field.callType32": "Eingehender gebührenpflichtiger Anruf",
  "cms.calllink.field.callType31": "Interner Anruf",
  "cms.calllink.field.callType30": "Allgemeiner ausgehender Anruf",
  "cms.calllink.field.callType29": "Manueller ausgehender Anruf",
  "cms.calllink.field.callType28": "Art des ausgehenden Agentenanrufs",
  "cms.calllink.field.callType27": "Ausgehender PRI-Anruf",
  "cms.calllink.field.callType26": "Ausgehender IVR-Anruf",
  "cms.calllink.field.callType0": "Ausgehender Anruf eines Telefonagenten",
  "cms.calllink.field.callType1": "Ausgehender IVR PRI-Anruf",
  "cms.calllink.field.callType2": "Eingehender Anruf des Netzwerk-Callcenters",
  "cms.calllink.field.callType3": "Ausgehender Anruf des Netzwerk-Callcenters",
  "cms.calllink.field.callType4": "Eingehender Inspektionsanruf des Netzwerk-Callcenters",
  "cms.calllink.field.callType5": "Interner eingehender Netzwerkaufruf an einen Virtual Agent",
  "cms.calllink.field.callType6": "Interner ausgehender Netzwerkanruf an einen Virtual Agent",
  "cms.calllink.field.callType7": "Hilfsanruf",
  "cms.calllink.field.callType8": "Eingehender Anruf (internationaler Agent)",
  "cms.calllink.field.callType9": "Weitergeleiteter Anruf",
  "cms.calllink.field.callType10": "Ausgehender Anruf (internationaler Agent)",
  "cms.calllink.field.callType11": "Reservierter ausgehender Anruf",
  "cms.calllink.field.callType12": "Besetzter ausgehender Anruf",
  "cms.calllink.field.callType13": "Vorverbundener ausgehender Anruf",
  "cms.calllink.field.callType14": "Prognostizierter ausgehender Anruf",
  "cms.calllink.field.callType15": "Vorschau für ausgehenden Anruf",
  "cms.calllink.field.callType16": "Rückrufanforderung",
  "cms.calllink.field.callType17": "Internationaler eingehender gebührenpflichtiger Anruf",
  "cms.calllink.field.callType18": "RBT-Geschäftsanruf",
  "cms.calllink.field.callType19": "Hilfeanruf von zwei Parteien",
  "cms.calllink.field.callType20": "Hilfsanruf von drei Parteien",
  "cms.calllink.field.callType21": "Besetzter ausgehender PRI-Anruf",
  "cms.calllink.field.callType22": "Vorverbundener ausgehender PRI-Anruf",
  "cms.calllink.field.callType23": "Virtuelle eingehende und ausgehende PRI-Anrufe",
  "cms.calllink.field.callType24": "Vorschau für ausgehenden PRI-Anruf",
  "cms.calllink.field.callType25": "PRI-Rückrufanfrage",
  "cms.calllink.title.callId": "Anrufkennung",
  "cms.calllink.title.callType": "Anruftyp",
  "cms.calllink.title.caller": "Anrufernummer",
  "cms.calllink.title.callee": "Angerufene Nummer",
  "cms.calllink.title.mediaType": "Medientyp",
  "cms.calllink.title.deviceType": "Gerätetyp",
  "cms.calllink.title.deviceNo": "Gerätekennung",
  "cms.calllink.title.deviceDesc": "Gerätebeschreibung",
  "cms.calllink.title.leaveReason": "Grund für das Verlassen des Geräts",
  "cms.calllink.title.callTrace": "Anrufverbindung",
  "cms.calllink.title.beginTime": "Startzeit der Statistik",
  "cms.calllink.title.endTime": "Endzeit der Statistiken",
  "cms.calllink.title.logDate": "Statistikzeit",
  "cms.calllink.title.callBeginTime": "Startzeit des Anrufs",
  "cms.calllink.title.callEndTime": "Endzeit des Anrufs",
  "cms.calllink.title.agentId": "ID des verbundenen Agenten",
  "cms.calllink.title.skillQueue": "Verbundene Kompetenzwarteschlange",
  "cms.calllink.msg.numberInvalid": "Falsches Zahlenformat.",
  "cms.calldetail.msg.callIdInvalid": "Das Format ist falsch.",
  "cms.calllink.msg.callerPlaceHolder": "Geben Sie die Nummer des Anrufers ein",
  "cms.calllink.msg.calleePlaceHolder": "Geben Sie die angerufene Nummer ein",
  "cms.calllink.msg.dateTooLate": "Das ausgewählte Datum darf nicht nach {0} liegen.",
  "cms.calldetail.msg.timeTooLate": "Die ausgewählte Zeit darf nicht nach {0} liegen.",
  "cms.calllink.title.callTrack": "Anrufspur",
  "cms.calllind.field.deviceQueue": "Skill-Warteschlange",
  "cms.calllind.field.deviceAgent": "Agent",
  "cms.calllind.field.deviceRoute": "Temporäres Routinggerät",
  "cms.calllind.field.deviceVnr": "Virtuelles Gerät",
  "cms.uapmonitor.field.callSip": "Endgerätkennung",
  "cms.uapmonitor.field.callSize": "Terminaltyp",
  "cms.uapmonitor.field.callUapIp": "UAP-IP",
  "cms.uapmonitor.field.callStatus": "Registrierungsstatus",
  "cms.uapmonitor.field.callSocket": "Socketstatus",
  "cms.uapmonitor.field.callIpPort": "IP und Port",
  "cms.uapmonitor.field.callIpSize": "IP-Typ",
  "cms.uapmonitor.field.callOperator": "Betrieb",
  "cms.uapmonitor.field.Logout": "Abmeldung",
  "cms.uapmonitor.field.Search": "Suche",
  "cms.uapmonitor.field.registered": "registriert",
  "cms.uapmonitor.field.unregister": "nicht registrieren",
  "cms.uapmonitor.field.socketFault": "Fehler",
  "cms.uapmonitor.field.socketNormal": "Normal",
  "cms.uapmonitor.field.socketConnecting": "Verbindung wird hergestellt",
  "cms.uapmonitor.field.socketUninstall": "Deinstallieren",
  "cms.uapmonitor.label.error": "Fehler",
  "cms.uapmonitor.label.registererror": "Abmeldung fehlgeschlagen",
  "cms.uapmonitor.title.regist": "Anmeldestatus des Terminals",
  "cms.uapmonitor.title.deregis": "Bestätigung der Abmeldung",
  "cms.uapmonitor.field.deregister": "Möchten Sie die Registrierung des Geräts wirklich aufheben?",
  "cms.interfaceperflog.message.dvSwitch": "Datenberichterstattung aktivieren",
  "cms.interfaceperflog.message.modifySuccess": "Änderung erfolgreich",
  "cms.interfaceperflog.message.modifyFail": "Änderung fehlgeschlagen",
  "cms.fullscreen.field.themeType": "Thementyp",
  "cms.fullscreen.field.themeTypeY": "Öffentliche Themen",
  "cms.fullscreen.field.themeTypeN": "Private Themen",
  "cms.report.message.voicedataOverError": "Die Anzahl der exportierten Dateien überschreitet 0. Bitte wählen Sie die Suchkriterien erneut aus.",
  "cms.fullscreen.message.publicTheme": "(Öffentlich)",
  "cms.fullscreen.field.setIndicatorThreshold": "Indikatorschwellenwerte festlegen",
  "cms.fullscreen.field.minThreshold": "Unterer Schwellenwert",
  "cms.fullscreen.field.maxThreshold": "Max. Schwellenwert",
  "cms.fullscreen.field.no": "Nein",
  "cms.fullscreen.field.yes": "Ja",
  "cms.fullscreen.field.IVR": "IVR",
  "cms.fullscreen.message.numberCheck": "Der Wert muss 0, eine positive ganze Zahl oder eine positive Dezimalzahl mit maximal zwei Dezimalstellen sein.",
  "cms.fullscreen.message.digitsCheck": "Der Wert muss eine positive ganze Zahl sein",
  "cms.fullscreen.message.thresholdLessThan": "Der Wert muss größer als der Mindestwert sein",
  "cms.fullscreen.message.warningSelectOne": "Wählen Sie nur einen Indikator für die Schwellenwerteinstellung.",
  "cms.export.field.addExportTask": "Neue Exportaufgabe",
  "cms.export.field.selectFileType": "Auswählen des Formats der Exportdatei",
  "cms.export.field.viewExportTask": "Aufgabenansicht exportieren",
  "cms.export.field.refresh": "Aktualisieren",
  "cms.export.field.download": "Herunterladen",
  "cms.export.field.exportBeginTime": "Startzeit des Exports",
  "cms.export.field.exportEndTime": "Endzeit des Exports",
  "cms.export.field.exportTaskStatus": "Status",
  "cms.export.field.operation": "Betrieb",
  "cms.export.message.addExportTaskSuccess": "Neue Exportaufgabe erfolgreich",
  "cms.export.message.addExportTaskError": "Neue Exportaufgabe fehlgeschlagen. Bitte überprüfen Sie das Protokoll",
  "cms.export.field.unprocessed": "Unverarbeitet",
  "cms.export.field.exportiong": "Wird exportiert",
  "cms.export.field.success": "Erfolg",
  "cms.export.field.failed": "Fehlgeschlagen",
  "cms.export.message.passValidateFailed": "Der Wert ist eine Zeichenfolge von 8 bis 12 Zeichen und muss Buchstaben, Ziffern und Sonderzeichen (außer |;&$-<>/\\`!#(){},-Leerzeichen, Zeilenumbruchzeichen und Tabulatorzeichen) enthalten.",
  "cms.export.field.zippwd": "Komprimiertes Kennwort",
  "cms.export.message.popwindowmessage": "Passwort zum Erstellen einer komprimierten Datei. Der Wert ist eine Zeichenfolge von 8 bis 12 Zeichen und muss Buchstaben, Ziffern und Sonderzeichen enthalten (ausgenommen {'|;&$-<>/'}{'\\\\'}{'`!#(){}'}, Leerzeichen, Zeilenumbruchzeichen und Tabulatorzeichen ).",
  "cms.custdata.operlog.popwindowmessage": "Bitte geben Sie das Kennwort ein, das zum Erstellen der komprimierten Datei verwendet wurde. Das komprimierte Kennwort sollte Buchstaben, Zahlen und Sonderzeichen enthalten _BAR_ %",
  "cms.export.message.exception": "Bei der Generierung der Exportaufgabe ist ein Fehler aufgetreten. Überprüfen Sie die Protokolle.",
  "cms.export.message.parainvalid": "Die Ausfuhrbedingungen konnten nicht überprüft werden.",
  "cms.export.message.exceedlimit1": "Es können maximal 0 Datensätze exportiert werden. Grenzen Sie den Datenzeitbereich ein, oder fügen Sie Datenfilterkriterien hinzu.",
  "cms.export.message.exceedlimit2": ". Grenzen Sie den Datenzeitbereich ein, oder fügen Sie Datenfilterkriterien hinzu.",
  "cms.export.message.exceedNumberLimit": "Übermäßiges Datenvolumen. Grenzen Sie den Datenzeitbereich ein, oder fügen Sie Datenfilterkriterien hinzu.",
  "cms.export.message.nocount": "Es wurden keine Daten gefunden, die die Bedingungen erfüllen.",

  "cms.title.select": "Auswählen",

  "cms.vcallecenter.title.resetpwd": "Passwort zurücksetzen",
  "cms.vcallecenter.label.resetpwd": "Administratorkennwort zurücksetzen",
  "cms.vcallecenter.label.tenantaccount": "Administratorkonto",
  "cms.vcallecenter.label.inputnewpwd": "Neues Kennwort",
  "cms.vcallecenter.label.renewpwd": "Kennwort bestätigen",
  "cms.vcallecenter.title.tips": "Informationen",
  "cms.vcallecenter.message.validatefailed": "Kennwort konnte nicht bestätigt werden.",
  "cms.vcallecenter.resetpwd.message.fail": "Passwort konnte nicht zurückgesetzt werden.",

  "cms.report.field.mediatype.vedio": "Video",
  "cms.report.field.mediatype.H5Vedio": "H5-Video",
  "cms.report.field.mediatype.clickvediocall": "Video-Klick-zum-Wählen",
  "cms.report.field.submediatype.web": "Web",
  "cms.report.field.submediatype.whatsapp": "WhatsApp",
  "cms.report.field.submediatype.line": "LINIE",
  "cms.report.field.submediatype.wechat": "WeChat",
  "cms.report.field.submediatype.facebook": "Facebook",
  "cms.report.field.submediatype.twitter": "X (Twitter)",
  "cms.report.field.submediatype.5gmessage": "5G RCS",
  "cms.report.field.submediatype.email": "E-Mail-Adresse",
  "cms.report.field.submediatype.sms": "SMS",
  "cms.report.title.submediaType": "Multimediatyp",
  "cms.systemmonitor.label.search": "Suche",
  "cms.systemmonitor.label.reset": "Zurücksetzen",
  "cms.systemmonitor.field.selectCcName": "Callcenter auswählen",
  "cms.systemmonitor.field.selectAgentName": "Mieter auswählen",
  "cms.systemmonitor.message.dategt31days": "Der Zeitraum darf 31 Tage nicht überschreiten.",
  "cms.systemmonitor.message.addVdnMost": "Es können maximal 100 Mandanten ausgewählt werden.",
  "cms.systemmonitor.field.cancel": "Abbrechen",
  "cms.systemmonitor.field.confirm": "Bestätigen",
  "cms.systemmonitor.field.time": "Erfasste Statistiken",
  "cms.systemmonitor.field.ccName": "Name des Callcenters",
  "cms.systemmonitor.field.vdnName": "Mieter",
  "cms.systemmonitor.field.onlineAgentNums": "Online-Agenten",
  "cms.systemmonitor.field.ideaAgentNum": "Leerlaufende Agenten",
  "cms.systemmonitor.field.talkingNums": "Gesprächspartner",
  "cms.systemmonitor.field.busyNums": "Beschäftigte Agenten",
  "cms.systemmonitor.field.incallNums": "Eingehende Systemanrufe",
  "cms.systemmonitor.field.rateNum": "Systemverbundene Anrufe",
  "cms.systemmonitor.field.callRate": "Verbindungsrate von Systemanrufen",
  "cms.systemmonitor.field.uapIP": "UAP-IP-Adresse",
  "cms.systemmonitor.field.ofcNo": "Eingehende Nummer.",
  "cms.systemmonitor.field.systemInBound": "Eingehende Systemanrufe",
  "cms.systemmonitor.field.systemInSucc": "Systemverbundene Anrufe",
  "cms.systemmonitor.field.systemCallRate": "Verbindungsrate von Systemanrufen",
  "cms.systemmonitor.field.agentOnlineCount": "Registrierte Softphone-Nummern",
  "cms.systemmonitor.field.queryTime": "Abfragezeit",
  "cms.systemmonitor.label.thirtyminutes": "30 Minuten",
  "cms.systemmonitor.label.onehour": "1 Stunde",
  "cms.systemmonitor.label.threehours": "3 Stunden",
  "cms.systemmonitor.label.thirtyonedays": "31 Tage",
  "cms.systemmonitor.label.customizes": "Anpassung",
  "cms.systemmonitor.message.timeempty": "* Start- oder Endzeit darf nicht leer sein.",
  "cms.systemmonitor.message.timeerror": "* Die Startzeit muss vor der Endzeit liegen.",
  "cms.systemmonitor.message.timelarge": "* Der Zeitraum darf nicht länger als 31 Tage sein.",
  "cms.systemmonitor.field.ok": "OK",
  "cms.systemmonitor.title.tips": "Informationen",
  "cms.systemmonitor.title.error": "Fehler",
  "cms.systemmonitor.message.queryErrorInfo": "Abfrage fehlgeschlagen.",
  "cms.systemmonitor.label.CommercialUse": "Gewerbsmäßige Nutzung",
  "cms.systemmonitor.label.noncommercial": "Probeweise kommerzielle Nutzung",
  "cms.vdnconfiguration.label.configure": "Festlegen",
  "cms.vdnconfiguration.field.defaultCallCenter": "Standard-Callcenter",
  "cms.vdnconfiguration.title.vdnConfigure": "Konfigurieren des Callcenters, in dem VDN ausgeführt wird",
  "cms.vdnconfiguration.field.cancel": "Abbrechen",
  "cms.vdnconfiguration.field.ok": "OK",
  "cms.vdnconfiguration.title.success": "Erfolg",
  "cms.vdnconfiguration.title.error": "Fehler",
  "cms.vdnconfiguration.message.updatesuccess": "Aktualisierung erfolgreich.",
  "cms.vdnconfiguration.message.updatefailed": "Aktualisierung fehlgeschlagen.",
  "cms.callcenterselect.title.selectCcName": "Callcenter auswählen",

  "cms.report.field.selectAgent": "Agenten auswählen",
  "cms.report.field.selectOrg": "Organisationseinheit auswählen",
  "cms.report.placeholder.workName": "Geben Sie einen Agentennamen ein.",
  "cms.report.message.selectAgent": "Wählen Sie mindestens eine Agenten-ID aus.",
  "cms.agentmonitor.label.cancellisteningOrInsert": "Anhören oder Einfügen abbrechen",
  "cms.agentmonitor.label.cancelsuccess": "Abbruch erfolgreich.",
  "cms.agentmonitor.label.cancelfail": "Abbruch fehlgeschlagen.",
  "cms.agentmonitor.message.header": "Speichern fehlgeschlagen. Wählen Sie mindestens eine Tabellenüberschrift aus.",
  "cms.export.title.voiceDownload": "Aufzeichnungsinspektion",
  "cms.export.message.downloadSucceed": "Der Bericht wurde erfolgreich heruntergeladen.",
  "cms.export.message.downloadFailed": "Bericht konnte nicht heruntergeladen werden.",
  "cms.report.message.selectAgentMax": "Es können maximal 100 Agenten-IDs ausgewählt werden.",
  "cms.report.field.acwRateField": "Rate der Nachbearbeitungsdauer",
  "cms.report.message.acwRateDesc": "Rate der Nachbearbeitungsdauer = (Gesamtnachbearbeitungsdauer/Gesamtarbeitsdauer) x 100",

  "cms.callcenterinstanceslist.title.chooseMode": "Auswahlmodus",
  "cms.callcenterinstanceslist.field.cmsAuthName": "CCDIS-Authentifizierungskonto",
  "cms.callcenterinstanceslist.field.cmsAuthPassword": "CCDIS-Kennwort",
  "cms.callcenterinstanceslist.field.disPwdConfirm": "CCDIS-Kennwort bestätigen",
  "cms.callcenterinstanceslist.field.single": "Einzelmodus",
  "cms.callcenterinstanceslist.field.pool": "Pool-Modus",
  "cms.callcenterinstanceslist.field.dvDataReport": "DigitalView-Datenberichterstellung",
  "cms.callcenterinstanceslist.message.cleanUap": "UAP-Knotenkonfiguration löschen",
  "cms.callcenterinstanceslist.message.cleanKafka": "KAFKA-Knotenkonfiguration löschen",
  "cms.callcenterinstanceslist.message.uapNodeInfo": "UAP-Knoten",
  "cms.callcenterinstanceslist.message.uapNode1Info": "UAP-Knoten 1",
  "cms.callcenterinstanceslist.message.uapNode2Info": "UAP-Knoten 2",
  "cms.callcenterinstanceslist.field.uapIp": "UAP-NMU-IP-Adresse",
  "cms.callcenterinstanceslist.field.uapCmdPort": "UAP-MML-Befehlsport",
  "cms.callcenterinstanceslist.field.uapPerfPort": "UAP-Leistungsüberwachungsport",
  "cms.callcenterinstanceslist.field.uapUserName": "UAP-CDE-Benutzername",
  "cms.callcenterinstanceslist.field.uapPassword": "UAP-CDE-Kennwort",
  "cms.callcenterinstanceslist.message.clearUapConfig": "Möchten Sie die UAP-Knotenkonfiguration wirklich löschen?",
  "cms.callcenterinstanceslist.message.clearKafkaConfig": "Möchten Sie die KAFKA-Knotenkonfiguration wirklich löschen?",
  "cms.callcenterinstanceslist.field.uapPasswordConfirm": "UAP-CDE-Kennwort bestätigen",

  "cms.callcenterinstanceslist.message.kafkaNodeInfo": "KAFKA-Knoten",
  "cms.callcenterinstanceslist.message.needKafkaSSL": "SSL ÖFFNEN",
  "cms.callcenterinstanceslist.field.kafkaIp": "IP-Adresse der KAFKA",
  "cms.callcenterinstanceslist.field.kafkaPort": "Hafen KAFKA",
  "cms.callcenterinstanceslist.field.kafkaUserName": "KAFKA-Benutzername",
  "cms.callcenterinstanceslist.field.kafkaPassword": "KAFKA-Kennwort",
  "cms.callcenterinstanceslist.field.kafkaPasswordConfirm": "KAFKA-Passwort bestätigen",

  "cms.callcenterinstanceslist.message.datasourceInfo": "Fertigstellen der Datenquellenkonfiguration.",
  "cms.callcenterinstanceslist.message.dsmodifyconfirm": "Die Datenquellenkonfiguration wurde geändert. Starten Sie die Umgebung neu, damit die Änderung wirksam wird. Möchten Sie sie wirklich ändern?",

  "cms.datasource.label.create": "Hinzufügen",
  "cms.datasource.label.delete": "Löschen",
  "cms.datasource.label.edit": "Bearbeiten",
  "cms.datasource.message.dbNameInput": "Geben Sie einen Datenquellennamen ein.",
  "cms.datasource.field.dbName": "Datenquellenname",
  "cms.datasource.field.dbServiceType": "Unternehmensart",
  "cms.datasource.field.dbType": "Datenquellentyp",
  "cms.datasource.field.dbUsername": "Benutzername",
  "cms.datasource.field.isBackup": "Sicherungsdatenbank",
  "cms.datasource.field.backupDbName": "Datenquelle sichern",
  "cms.datasource.field.operation": "Betrieb",
  "cms.datasource.title.addDatasource": "Datenquelle hinzufügen",
  "cms.datasource.field.dbUrl": "Datenbankverbindungszeichenfolge",
  "cms.datasource.field.dbPwd": "Kennwort",
  "cms.datasource.field.remark": "Nutzungsbeschreibung",
  "cms.datasource.field.test": "Verbindung testen",
  "cms.datasource.field.cancel": "Abbrechen",
  "cms.datasource.field.ok": "OK",
  "cms.datasource.field.cmsDatabase": "CMS-Berichtsdatenbank",
  "cms.datasource.field.uidbDatabase": "UIDB",
  "cms.datasource.field.no": "Nein",
  "cms.datasource.field.yes": "Ja",
  "cms.datasource.field.passwordConfirm": "Kennwort bestätigen",
  "cms.datasource.title.modifyDatasource": "Datenquelle aktualisieren",
  "cms.datasource.message.isDeleteDatasource": "Möchten Sie die Datenquelle wirklich löschen?",
  "cms.datasource.message.addsuccess": "Hinzufügung erfolgreich.",
  "cms.datasource.message.addfailed": "Hinzufügen fehlgeschlagen.",
  "cms.datasource.message.updatesuccess": "Aktualisierung erfolgreich.",
  "cms.datasource.message.updatefailed": "Aktualisierung fehlgeschlagen.",
  "cms.datasource.message.deletesuccess": "Löschung erfolgreich.",
  "cms.datasource.message.deletefailed": "Löschen fehlgeschlagen.",
  "cms.callcenterinstanceslist.title.sourceconfiguration": "Datenquelleneinstellungen",
  "cms.datasource.message.mysql5UrlTip": "Datenbankverbindungszeichenfolge: &#8220jdbc:mysql://ip:port/schema&#8220&#10;ip (IP-Adresse) port (Portnummer) schema (Modus)",
  "cms.datasource.message.mysql8UrlTip": "Datenbankverbindungszeichenfolge: &#8220jdbc:mysql://ip:port/schema&#8220&#10;ip (IP-Adresse) port (Portnummer) schema (Modus)",
  "cms.datasource.message.oracleUrlTip": "Datenbankverbindungszeichenfolge: &#8220jdbc:oracle:thin:@ip:port:dbservicename&#8220&#10;ip (IP-Adresse) port (Portnummer) dbservicename (Datenbankname)",
  "cms.datasource.message.gaussUrlTip": "Datenbankverbindungszeichenfolge: &#8220jdbc:zenith:@ip:port&#8220&#10;ip (IP-Adresse) Port (Portnummer)",
  "cms.datasource.message.dmUrlTip": "Datenbankverbindungszeichenfolge: &#8220jdbc:dm://ip:port/dbservicename&#8220&#10;ip (IP-Adresse) port (Portnummer) dbservicename (Datenbankname)",
  "cms.datasource.message.passValidateFailed": "Der Wert darf die Zeichen eval, exec und die folgenden Sonderzeichen nicht enthalten: <=>/",
  "cms.datasource.message.already": "Der Datenquellenname ist bereits vorhanden.",
  "cms.datasource.message.testsuccess": "Die Verbindung wurde erfolgreich getestet.",
  "cms.datasource.message.testfailed": "Die Verbindung konnte nicht getestet werden.",
  "cms.datasource.message.ccDsRelationExist": "Auf die Datenquelle wurde im Callcenter verwiesen.",
  "cms.datasource.message.masterSalveRelationExist": "Die Datenquelle wurde als Sicherungsdatenquelle verwendet.",
  "cms.datasource.message.dsmodifyconfirm": "Die Datenquelle wurde im Callcenter referenziert und wird erst nach einem Neustart der Umgebung wirksam. Möchten Sie sie wirklich ändern?",

  "cms.systemparam.success.save": "Parameter erfolgreich gespeichert",
  "cms.systemparam.error.save": "Speichern der Parameter fehlgeschlagen",
  "cms.systemparam.success.reset": "Parameter wurde erfolgreich zurückgesetzt",
  "cms.systemparam.error.reset": "Zurücksetzen des Parameters fehlgeschlagen",
  "cms.systemparam.success.refresh": "Parameter erfolgreich aktualisiert",
  "cms.systemparam.error.refresh": "Parameteraktualisierung fehlgeschlagen",
  "cms.systemparam.error.notmatchrule": "Der Parameter erfüllt die Prüfregel nicht",
  "cms.systemparam.error.noparamselected": "Bitte wählen Sie zuerst einen Parameter aus",

  "cms.sysparam.config.search": "Suche",
  "cms.sysparam.config.reset": "Zurücksetzen",
  "cms.sysparam.config.refresh": "Aktualisieren",
  "cms.sysparam.config.itemname": "Parametername",
  "cms.sysparam.config.value": "Parameterwert",
  "cms.sysparam.config.itemdesc": "Beschreibung",
  "cms.sysparam.config.opterate": "Betrieb",
  "cms.sysparam.config.save": "Speichern",
  "cms.sysparam.config.mod": "Bearbeiten",
  "cms.sysparam.config.checkmessage": "Enthält Sonderzeichen. (<, >, /, usw.)",
  "cms.sysparam.config.itemname.tips": "Geben Sie einen Parameternamen ein.",
  "cms.sysparam.config.select.prompt": "Tipps",
  "cms.sysparam.config.select.null": "Wählen Sie einen Parameter aus.",
  "cms.sysparam.config.select.count": "Es können maximal 10 Parameter ausgewählt werden.",
  "cms.sysparam.config.item.detail": "Parameterinformationen",
  "cms.sysparam.config.item.catalog": "Parametertyp",
  "cms.sysparam.config.title": "Systemparameter",
  "cms.sysparam.config.reset.multiple.error": "Die folgenden Parameter können nicht zurückgesetzt werden:",
  "cms.systemparam.error.validate.inner": "Parameterüberprüfung fehlgeschlagen",
  "cms.systemparam.error.validate.ruleinvalid": "Die Überprüfungsregel ist ungültig",
  "cms.systemparam.error.validate.ruleregexinvalid": "Syntaxfehler der Validierungsregel",
  "cms.systemparam.error.validate.notmatchrule": "Der Eingabeparameter stimmt nicht mit der Überprüfungsregel überein",
  "cms.systemparam.error.validate.notmatchruleregex": "Der Eingabeparameter stimmt nicht mit dem regulären Ausdruck der Überprüfungsregel überein",
  "cms.systemparam.error.validate.notmatchrulealpha": "Bitte geben Sie alphanumerische Zeichen oder Unterstriche ein und beginnen Sie nur mit Buchstaben oder Unterstrichen",
  "cms.systemparam.error.validate.notmatchruledigits": "Bitte geben Sie eine gültige positive Ganzzahl ein",
  "cms.systemparam.error.validate.notmatchruleemail": "Geben Sie eine gültige E-Mail-Adresse ein",
  "cms.systemparam.error.validate.notmatchruleip": "Geben Sie eine gültige IPv4- oder IPv6-Adresse ein",
  "cms.systemparam.error.validate.notmatchrulemaxlength": "Parameter überschreitet die maximale Länge 0",
  "cms.systemparam.error.validate.notmatchrulemax": "Der Eingabeparameter überschreitet das Maximum",
  "cms.systemparam.error.validate.notmatchruleminlength": "Der Eingabeparameter ist kleiner als die Mindestlänge",
  "cms.systemparam.error.validate.notmatchrulemin": "Der Eingabeparameter ist niedriger als das Minimum",
  "cms.systemparam.error.validate.notmatchrulenumber": "Bitte geben Sie eine Zahl ein",
  "cms.systemparam.error.validate.notmatchruleoption": "Der Eingabeparameter befindet sich nicht im Aufzählungsbereich",
  "cms.systemparam.error.validate.notmatchrulerangelength": "Der Eingabeparameter überschreitet die maximale Länge oder unterschreitet die minimale Länge",
  "cms.systemparam.error.validate.notmatchrulerange": "Der Eingabeparameter überschreitet den Maximalwert oder unterschreitet den Minimalwert",
  "cms.systemparam.error.validate.notmatchrulerequired": "Pflichtfelder",
  "cms.systemparam.error.validate.notmatchruleurl": "Geben Sie eine gültige URL-Adresse ein",

  "cms.uapmonitor.labe.interval": "Überwachungszeitraum",
  "cms.uapmonitor.labe.eid": "Anschlussnummernsegment",
  "cms.uapmonitor.labe.qcworkno": "QC-Mitarbeiter-ID",
  "cms.uapmonitor.labe.inspectorcheckinnum": "Anmeldenummer des QC-Inspektors",
  "cms.uapmonitor.labe.inspectorcheckinpwd": "Anmeldekennwort für den QC-Inspektor",
  "cms.uapmonitor.labe.openmonitor": "Überwachung",
  "cms.uapmonitor.labe.uapconfing": "Terminalstatusüberwachung und Wiederherstellungskonfiguration",
  "cms.uapmonitor.labe.eidmention": "Sie können maximal 10 Zahlensegmente eingeben, die durch Kommas getrennt sind (,).-Regel für jedes Zahlensegment: * und% stimmen mit keinem oder mehreren Zeichen überein. Beispielsweise stimmen 6666* und 6666 % mit allen Zeichenfolgen überein, die mit 6666 beginnen. Das Fragezeichen (?) stimmt nur mit einem Zeichen überein. Zum Beispiel 6666? stimmt nur mit der Zeichenfolge mit fünf Zeichen überein, die mit 6666 beginnt. Die Kombination von Platzhaltern (*?%) wird unterstützt. Zum Beispiel %3? stimmt mit allen Zeichenfolgen überein, deren vorletztes Zeichen 3 ist. Hinweis: Es wird empfohlen, dass für jeden Platzhalter maximal 100 Ergebnisse zugeordnet werden. Andernfalls sind Unternehmen im UAP betroffen.",
  "cms.uapmonitor.message.intervalvalidate": "Geben Sie eine Zahl zwischen 300 und 86400 ein.",
  "cms.uapmonitor.message.eidvalidate": "Geben Sie die Parameter nach Aufforderung ein.",
  "cms.uapmonitor.labe.mentiontitle": "Hinweis:",
  "cms.uapmonitor.labe.mention": "1. Nach Aktivierung der Überwachungsfunktion werden Agenten, deren Terminal-Registrierungsstatus nicht mit dem Anmeldestatus des Agenten übereinstimmt, in regelmäßigen Abständen zwangsweise abgemeldet.",
  "cms.uapmonitor.labe.mentionnotice": "2. Stellen Sie sicher, dass das virtuelle Callcenter Inspektoren enthält und die Inspektoren die Softphone-Nummer und das Anmeldepasswort konfiguriert haben. Nur Agenten im anormalen Status können zwangsweise abgemeldet werden.",
  "cms.uapmonitor.labe.uapoverload": "Die Anzahl der vom Klemmennummernsegment abgefragten Nummern überschreitet die Obergrenze:",
  "cms.uapmonitor.labe.eidoverlength": "Die Anzahl der Klemmennummernsegmente überschreitet die Obergrenze von 10.",
  "cms.errormessage.inputspecialchar": "Der Wert enthält ungültige Sonderzeichen.",

  "cms.calldetail.label.inputcallid": "Bitte geben Sie die Anrufsequenznummer ein",
  "cms.report.field.callIdField": "Anrufsequenznummer",
  "cms.report.field.callIdNumField": "CDR-Sequenznummer",
  "cms.report.field.callerNoField": "Anrufernummer",
  "cms.report.field.calleeNoField": "Angerufene Nummer",
  "cms.report.field.waitBeginField": "Startzeit warten",
  "cms.report.field.ackBeginField": "Startzeit der Antwort",
  "cms.report.field.callBeginField": "Startzeit des Anrufs",
  "cms.report.field.callEndField": "Endzeit des Anrufs",
  "cms.report.field.deviceTypeField": "Gerätetyp",
  "cms.report.field.deviceNoField": "Gerätenr.",
  "cms.report.field.callTypeField": "Anruftyp",
  "cms.report.field.mediaTypeField": "Medientyp",
  "cms.report.field.enterReasonField": "Gibt den Grund für das Betreten des Geräts an",
  "cms.report.field.billInfo1Field": "Sprachanruftyp",
  "cms.report.field.obsServiceIdField": "ID der ausgehenden Kampagne",
  "cms.report.field.currentSkillIdField": "Skill-Warteschlangen-ID",
  "cms.report.field.subMediaTypeField": "Submedientyp",
  "cms.report.field.waitEndField": "Endzeit wird gewartet",
  "cms.report.field.ackEndField": "Endzeit der Antwort",
  "cms.report.field.waitCauseField": "Grund für das Warten",
  "cms.report.field.releaseCauseField": "Freigabegrund",
  "cms.report.field.uvidField": "Benutzerzugriffs-ID",
  "cms.report.field.userLevelField": "Kundenebene",
  "cms.report.field.callInCauseField": "Grund für Anrufeingabe",
  "cms.report.field.leaveReasonField": "Grund für das Verlassen des Geräts",
  "cms.report.field.preDeviceTypeField": "Vorheriger Gerätetyp",
  "cms.report.field.preDeviceNoField": "Gibt die Geräte-ID des vorherigen Geräts an",
  "cms.report.field.skillIdField": "ID der Skill-Queue, zu der der Anruf gehört",
  "cms.report.field.locationIdField": "ID des verteilten Knotens",
  "cms.report.field.billInfo2Field": "Eindeutige ID eines Doppelanrufs",
  "cms.report.field.uapIdField": "UAP-ID",
  "cms.report.field.netEntIdField": "NE ID des Medienservers",
  "cms.report.message.callIdDesc": "Anrufsequenznummer, die einen Anruf eindeutig identifiziert.",
  "cms.report.message.callIdNumDesc": "Sequenznummer mehrerer CDRs, die für einen Anruf generiert wurden.",
  "cms.report.message.callerNoDesc": "Rufnummer.",
  "cms.report.message.calleeNoDesc": "Angerufene Nummer.",
  "cms.report.message.waitBeginDesc": "Startzeit der Anrufwartezeit.",
  "cms.report.message.ackBeginDesc": "Startzeit der Anrufannahme.",
  "cms.report.message.callBeginDesc": "Startzeit des Anrufs.",
  "cms.report.message.callEndDesc": "Endzeit des Anrufs.",
  "cms.report.message.deviceTypeDesc": "Gerätetyp, der den Anruf verarbeitet.",
  "cms.report.message.deviceNoDesc": "Gibt die Gerätenummer des Geräts an, das den Anruf verarbeitet.",
  "cms.report.message.callTypeDesc": "Anruftyp.",
  "cms.report.message.mediaTypeDesc": "Medientyp anrufen.",
  "cms.report.message.enterReasonDesc": "Gibt den Grund für das Betreten des Geräts an.",
  "cms.report.message.billInfo1Desc": "Sprachanruftyp.",
  "cms.report.message.obsServiceIdDesc": "ID einer Kampagne für ausgehende Anrufe.",
  "cms.report.message.currentSkillIdDesc": "ID der Skill-Queue, die Anrufe verarbeitet.",
  "cms.report.message.subMediaTypeDesc": "Submedientyp.",
  "cms.report.message.waitEndDesc": "Zeigt die Endzeit des auf dem aktuellen Gerät wartenden Anrufs an.",
  "cms.report.message.ackEndDesc": "Endzeit der Anrufannahme, die sich auf die Endzeit der Anrufannahme auf dem aktuellen Gerät bezieht.",
  "cms.report.message.waitCauseDesc": "Zeigt den Grund an, warum ein Anruf auf dem aktuellen Gerät wartet.",
  "cms.report.message.releaseCauseDesc": "Grund der Anruffreigabe.",
  "cms.report.message.uvidDesc": "Vom CCS jedem Benutzer, der auf das Call Center zugreift, eine global eindeutige ID zugewiesen.",
  "cms.report.message.userLevelDesc": "Kundenebene eines Abonnenten im Call Center.",
  "cms.report.message.callInCauseDesc": "Grund, warum ein Anruf im Call Center eingeht (für Sprachanrufe).",
  "cms.report.message.leaveReasonDesc": "Grund für das Verlassen des Geräts.",
  "cms.report.message.preDeviceTypeDesc": "Gerätetyp des letzten Geräts, über das der Anruf ging.",
  "cms.report.message.preDeviceNoDesc": "Gibt die Geräte-ID des letzten Geräts an, über das der Anruf erfolgt.",
  "cms.report.message.skillIdDesc": "ID der Skill-Warteschlange, zu der der Anruf gehört.",
  "cms.report.message.locationIdDesc": "ID des verteilten Knotens, zu dem der Anruf gehört.",
  "cms.report.message.billInfo2Desc": "Eindeutige ID eines doppelten Anrufs.",
  "cms.report.message.uapIdDesc": "ID des UAP, das Anrufe mit der Callcenter-Plattform verbindet.",
  "cms.report.message.netEntIdDesc": "NE ID des Medienservers, der den aktuellen CDR generiert.",
  "cms.report.title.calldetailoriginal": "Anrufinhaltsdetails",
  "cms.vcallcenter.message.unknowcause": "Unbekannte Ursache",
  "cms.vcallcenter.message.unknowdesc": "Unbekannte Ausnahme.",
  "cms.vcallcenter.message.unknowsolution": "Wenden Sie sich an die Ingenieure von Huawei, um den Fehler zu analysieren und zu beheben",

  "cms.report.message.startOrEndTimeNoExist": "Wenn Sie ein Zeitsegment für die Abfrage auswählen, müssen Start- und Endzeit gleichzeitig vorhanden sein.",
  "cms.report.message.startTimeLessThanEndTime": "Die Startzeit darf nicht nach der Endzeit liegen.",
  "cms.report.field.stateField": "Verbindungsstatus",
  "cms.report.field.connected": "Verbunden",
  "cms.report.field.notConnected": "Nicht verbunden",
  "cms.report.field.hangUpModeField": "Auflegemethode",
  "cms.report.field.startTimeField": "Startzeit des Anrufs",
  "cms.report.field.endTimeField": "Endzeit des Anrufs",
  "cms.report.field.accessCodeField": "Zugangscode",
  "cms.report.field.callNoPlaceholder": "Anrufen oder Nein gerufen",
  "cms.report.field.callStartTimeField": "Startzeit des Anrufs",
  "cms.report.field.callEndTimeField": "Endzeit des Anrufs",
  "cms.report.field.alertingTimeField": "Anrufbenachrichtigungszeit",
  "cms.report.field.talkingTimeField": "Gesprächszeit anrufen",
  "cms.report.field.talkDurationField": "Gesprächsdauer",
  "cms.report.field.agentReleaseTimeField": "Zeit der Anruffreigabe",
  "cms.report.field.agentAlertingTimeField": "Anruferbenachrichtigungszeit",
  "cms.report.field.agentTalkingTimeField": "Gesprächszeit des Anrufers",
  "cms.report.field.agentTalkDurationField": "Gesprächsdauer des Anrufers",
  "cms.report.field.createTimeField": "Erstellungszeit",
  "cms.report.field.agentAlertingDurationField": "Dauer der Anrufbenachrichtigung",
  "cms.report.field.alertingDurationField": "Dauer der angerufenen Warnung",
  "cms.report.field.callerHangUp": "Anrufer legt auf",
  "cms.report.field.calledHangUp": "Auflegen aufgerufen",
  "cms.report.field.dualCallType": "Zwei Anrufe mit einem Klick",
  "cms.report.field.exceptionHangUp": "Ausnahme-Auflegen",
  "cms.report.title.filteringHeader": "Tabellenkopfzeile filtern",
  "cms.report.field.tableHeader": "Tabellenkopfzeile",
  "cms.report.message.stateDesc": "Verbindungsstatus, umfasst verbunden und nicht verbunden.",
  "cms.report.message.hangUpModeDesc": "Auflegemethode.",
  "cms.report.message.accessCodeDesc": "Zugangscode.",
  "cms.report.message.callStartTimeDesc": "Startzeit des Anrufs.",
  "cms.report.message.callEndTimeDesc": "Endzeit des Anrufs.",
  "cms.report.message.alertingTimeDesc": "Anrufalarmierungszeit.",
  "cms.report.message.talkingTimeDesc": "Ruf Sprechzeit an.",
  "cms.report.message.talkDurationDesc": "Gesprächsdauer.",
  "cms.report.message.agentReleaseTimeDesc": "Zeit der Anruffreigabe.",
  "cms.report.message.agentAlertingTimeDesc": "Anrufer-Alarmierungszeit.",
  "cms.report.message.agentTalkingTimeDesc": "Gesprächszeit des Anrufers.",
  "cms.report.message.agentTalkDurationDesc": "Gesprächsdauer des Anrufers.",
  "cms.report.message.createTimeDesc": "Zeit schaffen.",
  "cms.report.message.agentAlertingDurationDesc": "Dauer der Anrufbenachrichtigung.",
  "cms.report.message.alertingDurationDesc": "Dauer der Benachrichtigung bei Anruf.",
  "cms.report.title.callNo": "Anrufnummer",
  "cms.report.title.dualCallDetail": "Details zum dualen Anruf",
  "cms.report.message.inputZipPassword": "Bitte geben Sie das Komprimierungskennwort ein",
  "cms.report.task.sucess.export": "Es wurde eine Exportaufgabe erstellt. Möchten Sie es wirklich in der Exportliste anzeigen?",
  "cms.report.task.export.records": "Kontaktdatensätze werden exportiert",
  "cms.report.field.length.limit": "Der Wert muss eine Zahl mit maximal 0 Zeichen sein.",
  "cms.report.field.number": "Ungültige Nummer",
  "cms.report.field.view.export.task": "Exportaufgaben anzeigen ",
  "cms.report.field.enter.callin": "Bitte geben Sie die Seriennummer des eingehenden Anrufs ein",
  "cms.agent.lable.alarm": "Alarm",
  "cms.agent.label.agentlist": "Liste der Agenten",
  "cms.agent.label.agentdetailInfo": "Agentendetails",
  "cms.agent.label.enterdetail": "Details anzeigen",
  "cms.agent.label.statetime": "Statusdauer",
  "cms.agent.label.duracount": "Dauerstatistiken",
  "cms.agent.label.numcount": "Zählstatistiken",
  "cms.agent.label.agentname": "Agentenname",
  "cms.agent.label.logintime": "Anmeldezeit",
  "cms.agent.label.idlenum": "Leerlaufzeit",
  "cms.agentmonitor.label.countAndTime": "Zeiten/Dauer",
  "cms.agent.label.arrangetime": "Zeit vereinbaren",
  "cms.agent.label.logouttime": "Offlinezeit",
  "cms.agent.label.alarmstate": "Alarmstatus",
  "cms.input.format.invalid": "Das Eingabeformat ist falsch",
  "cms.calllind.field.deviceUnkown": "Unbekanntes Gerät",
  "cms.normal.text.validate": "Nur Ziffern, Buchstaben und -._{'@'} sind zulässig.",
  "cms.agent.label.signinphone": "Anmeldenummer",
  "cms.agent.label.currentagentstate": "Aktueller Status des Agenten",
  "cms.agent.label.currentstatetime": "Aktuelle Statusdauer eines Agenten",
  "cms.agent.label.choosestatetime": "Wählen Sie die Mindestdauer des Status.",
  "cms.agent.label.busyitem": "Ausgelastet",
  "cms.agent.label.talkitem": "Anruf",
  "cms.agent.label.online": "Online",
  "cms.agent.label.offline": "OffLine",
  "cms.agent.label.callinrate": "Rate der Beendigung eingehender Anrufe",
  "cms.agent.label.callintime": "Dauer eingehender Anrufe",
  "cms.agent.label.avgcallintime": "Durchschnittliche Dauer eingehender Anrufe",
  "cms.agent.label.avgwaittime": "Durchschnittliche Wartezeit für eingehende Anrufe",
  "cms.agent.label.maxwaittime": "Maximale Wartezeit bei eingehendem Anruf",
  "cms.agent.label.calloutsuccrate": "Abschlussrate ausgehender Anrufe",
  "cms.agent.label.callouttime": "Dauer des ausgehenden Anrufs",
  "cms.agent.label.avgcallouttime": "Durchschnittliche Dauer der ausgehenden Anrufe",
  "cms.agent.label.choosesybmediatype": "Wählen Sie einen Kanaltyp aus",
  "cms.agent.label.busytime": "Gebuchte Zeit",
  "cms.agent.label.avgbusytime": "Durchschn. Auslastungszeit",
  "cms.agent.label.acgtalktime": "Durchschn. Gesprächszeit",
  "cms.agent.label.outresttime": "Dauer der Ruhezeitüberschreitung",
  "cms.agent.label.totaltalktime": "Gesamte Anrufdauer",
  "cms.agent.label.redirtnum": "Umleitungen",
  "cms.agent.label.totaltalknums": "Gesamtanzahl der Anrufe",
  "cms.agent.tips.selectstatetime": "Wählen Sie die Statusdauer aus. Sie müssen den aktuellen Status auswählen",
  "cms.agent.label.selectstatetime": ">=-Statusdauer (Minute)",
  "cms.agent.tips.sipaccountcheck": "Wählen Sie eine positive Ganzzahl mit 1 bis 32 Ziffern als Anmeldenummer",
  "cms.indexmonitor.field.totalAgentConnectCallTime": "Anrufdauer",
  "cms.indexmonitor.title.agentDate": "Agentendaten",
  "cms.agent.tips.notassined": "Nicht zugewiesen",
  "cms.indexmonitor.set.limitA": "Auswählen",
  "cms.indexmonitor.set.limitB": "Anzeigen für die Anzeige",
  "cms.indexmonitor.titile.indicatorSetting": "'Anzeigeeinstellung',",
  "cms.indexmonitor.realTime.indicator": "Echtzeitmetriken",
  "cms.indexmonitor.index.multimediaCalls": "Multimedia",
  "cms.indexmonitor.index.callTrends": "Anruftrends",
  "cms.indexmonitor.index.skillQueueServiceLevel": "Übersicht über Service-Level der Skill-Warteschlange",
  "cms.indexmonitor.index.met": "Met",
  "cms.indexmonitor.index.missed": "Vermisst",
  "cms.incoming.record.not.sameday": "Cross-day-Abfragen werden nicht unterstützt.",
  "cms.requiredcallback.message.allowTimeRange": "unterstützt die Abfrage von Daten innerhalb von maximal sieben Tagen",
  "cms.incoming.record.field.releasecause": "Ursache der Anruffreigabe",
  "cms.incoming.record.field.releasecause.input": "Bitte geben Sie den Grund für die Anruffreigabe ein",
  "cms.incoming.record.field.releasecause.id": "Bitte geben Sie den Code für den Grund der Anruffreigabe ein",
  "cms.incoming.record.field.releasecause.causecode": "Bitte geben Sie den Zuordnungscode für den Grund ein",
  "cms.incoming.record.field.ifReqManual": "Wurde der manuelle Service angefordert",
  "cms.incoming.record.field.isReqManual": "Arbeitsplatz angefordert",
  "cms.incoming.record.field.notReqManual": "Arbeitsplatz wird nicht angefordert",
  "cms.incoming.record.field.ifAnswerCall": "Manuelle Antwort",
  "cms.incoming.record.field.isAnswerCall": "Beantwortet",
  "cms.incoming.record.field.notAnswerCall": "Der Agent nimmt den Anruf nicht an",
  "cms.incoming.record.field.releasecause.choose": "Wählen Sie den Grund für die Anruffreigabe aus",
  "cms.incoming.record.field.release.id": "Code der Anruffreigabeursache",
  "cms.incoming.record.field.release.causecode": "Ursachenzuordnungscode",
  "cms.incoming.releasecause.causecode.tip": "Weil der Zuordnungscode außerhalb des Bereichs liegt (0 bis 45)",
  "cms.incoming.record.report.beginTime": "Startzeit der Abfrage",
  "cms.incoming.record.report.endTime": "Endzeit der Abfrage",
  "cms.requiredcallback.message.timeRangeCheck": "Der Zeitbereich ist derselbe Tag",
  "cms.failurecall.field.waitTime.export": "Wartedauer (s)",
  "cms.failurecall.field.waitBegin": "Startzeit der Anrufwartezeit",
  "cms.failurecall.field.ackBegin": "Startzeit der Anrufannahme",
  "cms.failurecall.field.waitAnsNum": "Auf Antworten warten",
  "cms.failurecall.field.waitAnsTime": "Antwortdauer (s) warten",
  "cms.failurecall.field.succWaitAnsTime": "Dauer(n) der erfolgreichen Anrufannahme",
  "cms.failurecall.field.noAckNum": "Keine Anrufbeantwortung",
  "cms.failurecall.field.succQueueWaitTime": "Warteschlangendauer erfolgreicher Anrufe (s)",
  "cms.failurecall.field.succQueueWaitNum": "Warteschlangenzeiten erfolgreicher Anrufe",
  "cms.failurecall.field.failQueueWaitNum": "Anrufausfallwartezeit (s)",
  "cms.failurecall.field.inOccupyNum": "Manuelle Anfragen",
  "cms.failurecall.field.inCallSuccNum": "Manuelle Anrufzeiten",
  "cms.failurecall.field.inCallTime": "Manuelle Anrufdauer (s)",
  "cms.failurecall.field.queOverToIvrNum": "Warteschlange zum IVR",
  "cms.failurecall.field.queOverToIvrTime": "Warteschlangenweiterleitung an IVR (zweite)",
  "cms.failurecall.field.hangUpNum": "Anzahl der Male, die der Agent an das IVR überträgt",
  "cms.failurecall.field.hangUpTime": "Gesamtzeit für die Weiterleitung manueller Anrufe an IVR",
  "cms.failurecall.field.hangUpReleaseNum": "Anzahl der aufgelegten Anrufe, die von manuell an IVR weitergeleitet wurden",
  "cms.indexmonitor.index.noTrafficData": "Keine Verkehrsdaten",
  "cms.indexmonitor.index.SkillQueueTips": "Das Service-Level der Skill-Warteschlange wird anhand der Anrufrate innerhalb von 20 Sekunden gemessen. Der standardmäßig erwartete Service-Level beträgt 80 %. Der Mandantenadministrator kann das erwartete Serviceniveau ändern, indem er auf der Seite Mandantenparameterkonfiguration die Option Mandantenparameter > Call Center-Überwachung > Indikatorüberwachung > Erwartetes Serviceniveau der Skill-Queue auswählt.",
  "cms.indexmonitor.index.SkillQueueTips2": "Das Service-Level der Skill-Warteschlange wird an der Rate gemessen, mit der der Anruf innerhalb von 20 Sekunden abgeschlossen wird. Der standardmäßig erwartete Service-Level beträgt 80 %. Der Systemadministrator kann das erwartete Serviceniveau ändern, indem er Systemparameter > Call-Center-Überwachung > Indikatorüberwachung > Expected Service Level of Skill Queue auswählt.",
  "cms.indexmonitor.index.loadFail": "Laden fehlgeschlagen",
  "cms.failurecall.queue.abandon": "Aufgegebener Anruf",
  "cms.failurecall.callback": "Eingehender Anruf",
  "cms.faliurecall.incomingcall": "Abfrage zur Aufzeichnung eingehender Anrufe",
  "cms.vcallecenter.message.checkpasswordfailed": "Die Regel für das Anmeldekennwort konnte nicht verifiziert werden",
  "cms.agent.field.admin": "Administratoren",
  "cms.agent.field.commonagent": "Gemeinsamer Agent",
  "cms.agent.field.qualitychecker": "Qualitätsprüfer",
  "cms.failure.callerNo.maxLength.error": "Es sind maximal 64 Zeichen zulässig.",
  "cms.indexmonitor.curDay.indicator": "Anzeige des Tages",
  "cms.indexmonitor.field.skillConnectedIn20Rate": "Anrufabschlussrate innerhalb von 20 Sekunden",
  "cms.agent.label.agentDetail": "Agentendetails",
  "cms.multimedia.monitor.field.callNum": "Anzahl der IVR-Anrufe",
  "cms.multimedia.monitor.field.transNum": "Anzahl der IVR-zu-manuellen Anrufe",
  "cms.multimedia.monitor.field.transRate": "IVR-zu-Manuell-Rate",
  "cms.multimedia.monitor.field.connectedNum": "Anzahl der verbundenen IVR-Anrufe",
  "cms.multimedia.monitor.field.connectedRate": "IVR-Anrufabschlussrate",
  "cms.multimedia.monitor.field.durationAverage": "Durchschnittliche IVR-Anrufdauer",
  "cms.multimedia.monitor.field.callNum.desc": "Anzahl der Anrufe, die derzeit automatisch vom IVR-Gerät bearbeitet werden",
  "cms.multimedia.monitor.field.transNum.desc": "Anzahl der IVR-zu-manuellen Anrufe",
  "cms.multimedia.monitor.field.transRate.desc": "Prozentsatz der IVR-zu-manuellen Anrufe zu IVR-Anrufen",
  "cms.multimedia.monitor.field.connectedNum.desc": "Anzahl der IVR-Anrufe, deren Anrufdauer größer als 0 ist",
  "cms.multimedia.monitor.field.connectedRate.desc": "Prozentualer Anteil der verbundenen IVR-Anrufe an der Anzahl der IVR-Anrufe",
  "cms.multimedia.monitor.field.durationAverage.desc": "Gesamte IVR-Anrufdauer/Anzahl der verbundenen IVR-Anrufe",
  "cms.report.field.submediatype.instagram": "Instagram",
  "cms.report.field.submediatype.telegram": "Telegramm",
  "cms.qcrelationconf.field.attendantGroupName": "Agentengruppe",
  "cms.report.field.selectTrafficGroup": "Agentengruppe auswählen",
  "cms.report.placeholder.attendantGroupName": "Name der Agentengruppe",
  "cms.qcrelationconf.field.attendantGroup": "Agentengruppe",
  "cms.report.message.selectTrafficGroupMax": "Es können maximal 1000 Agentengruppen ausgewählt werden.",
  "cms.agentMonitor.message.operatorChecked": "Vorgang fehlgeschlagen. Das inspizierte Objekt ist ein Inspektor und führt die Inspektion durch.",
  "cms.export.message.required": "Pflichtfeld",
  "cms.field.length.limit": "Die Länge darf 0 nicht überschreiten",
  "cms.sysparameter.batchreset": "Stapelzurücksetzung",
  "cms.sysparameter.batchrefresh": "Stapelsynchronisierung",
  "cms.report.subscribe.add": "Neues Berichtsabonnement",
  "cms.report.subscribe.edit": "Bearbeiten eines Berichtsabonnements",
  "cms.qcrelationconf.tab.member": "Mitglieder",
  "cms.qcrelationconf.add.attendantGroup": "Fügen Sie die inspizierte Verkehrsgruppe hinzu",
  "cms.qcrelationconf.message.isDeleteTrafficGroup": "Möchten Sie die Teilnehmergruppe wirklich löschen?",
  "cms.report.subscribe.checkreportnum": "Wählen Sie mindestens einen Bericht aus.",
  "cms.sysparameter.edit.basic.information": "Grundlegende Informationen bearbeiten",
  "cms.sysparameter.edit.character": "Eigenschaften bearbeiten",
  "cms.sysparameter.edit.validate.ip": "Geben Sie eine korrekte IP-Adresse ein.",
  "cms.message.choose": "Wählen Sie Daten aus.",
  "cms.subscribe.template.isused": "Die E-Mail-Vorlage wurde verwendet.",
  "cms.subscribe.template.statuserror": "Der Status der E-Mail-Vorlage ist falsch.",
  "cms.qcrelationconf.message.unauthorized": "Die gespeicherten Daten enthalten ungültige Agenten.",
  "cms.report.subscribe.editreport": "Bericht bearbeiten,",
  "cms.report.subscribe.addreport": "Hinzufügen eines Berichts,",
  "cms.fullscreen.agent.auth": "Sie sind nicht berechtigt, die aktuelle Organisation anzuzeigen. Wählen Sie eine andere Organisation",
  "cms.agentMonitor.label.changestate_error": "Inspektionsstatus konnte nicht gewechselt werden, Betriebsfehler.,",
  "cms.agent.label.systempara": "Parameterkonfiguration,",
  "cms.report.message.talkTimeRateDesc": "(Gesprächsdauer insgesamt/Arbeitsdauer insgesamt) x 100",
  "cms.report.field.talkTimeRateField": "Prozentsatz der Anrufdauer (%)",
  "cms.report.field.totalTalkTimeField": "Gesamtgesprächsdauer (s)",
  "cms.report.message.totalTalkTimeDesc": "Gesamtdauer eines Agenten im Konversationsstatus",
}
