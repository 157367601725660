import request from '@/utils/request'
import cookieUtils from "@/utils/cookie"
import {useOifdeUserStore} from '../../stores/user'

export function apisecret(params) {
    return request({
        url: '/oifde/rest/apisecret',
        method: 'post',
        data: params
    })
}

export function rpaSecret(params) {
    return request({
        url: '/oifde/rest/rpaSecret',
        method: 'post',
        data: params
    })
}

export function querySystemTenantextInfos(query) {
    const userStore = useOifdeUserStore();
    const data = {
        tenantId: cookieUtils.getCookie('tenant_space_id'),
        source: userStore.source
    }
    for (let prop in query) {
        if (typeof query[prop] === "undefined") {
            continue
        }
        if (typeof query[prop] === 'string' && query[prop] === '') {
            continue
        }
        data[prop] = query[prop]
    }
    if (typeof data.pageNum === 'undefined') {
        data.pageNum = 1
    }
    if (typeof data.pageSize === 'undefined') {
        data.pageSize = 10
    }
    return request({
        url: '/oifde/rest/querysystemtenantextinfos',
        method: 'post',
        data
    })
}

export function queryInterfaceList(query) {
    const data = {
        tenantId: cookieUtils.getCookie('tenant_space_id'),
        source: useOifdeUserStore().source
    }
    for (let prop in query) {
        if (typeof query[prop] === "undefined") {
            continue
        }
        if (typeof query[prop] === 'string' && query[prop] === '') {
            continue
        }
        data[prop] = query[prop]
    }
    if (typeof data.pageNum === 'undefined') {
        data.pageNum = 1
    }
    if (typeof data.pageSize === 'undefined') {
        data.pageSize = 20
    }
    return request({
        url: '/oifde/rest/queryserviceinterface',
        method: 'post',
        timeout: 6000 * 30,
        data: data
    })
}

export function updateSystemTenantextInfo(TenantInfo) {
    const data = {
        tenantId: cookieUtils.getCookie('tenant_space_id'),
        source: useOifdeUserStore().source
    }
    for (let prop in TenantInfo) {
        if (typeof TenantInfo[prop] === "undefined") {
            continue
        }
        if (typeof TenantInfo[prop] === 'string' && TenantInfo[prop] === '') {
            continue
        }
        data[prop] = TenantInfo[prop]
    }

    return request({
        url: '/oifde/rest/updatesystemtenantextinfo',
        method: 'post',
        data
    })
}


export function queryLogParamList(query) {
    const data = {
        tenantId: cookieUtils.getCookie('tenant_space_id'),
    }
    for (let prop in query) {
        if (typeof query[prop] === "undefined") {
            continue
        }
        if (typeof query[prop] === 'string' && query[prop] === '') {
            continue
        }
        data[prop] = query[prop]
    }
    return request({
        url: '/oifde/rest/querylogparams',
        method: 'post',
        data: data
    })
}

export function deleteLogParam(logParamList) {
    const data = {
        tenantId: cookieUtils.getCookie('tenant_space_id'),
        logConfigIdList: logParamList
    }

    return request({
        url: '/oifde/rest/deletelogparam',
        method: 'post',
        data
    })
}

export function createLogParam(logParamInfo) {
    const data = {
        tenantId: cookieUtils.getCookie('tenant_space_id'),
    }
    for (let prop in logParamInfo) {
        if (typeof logParamInfo[prop] === "undefined") {
            continue
        }
        if (typeof logParamInfo[prop] === 'string' && logParamInfo[prop] === '') {
            continue
        }
        data[prop] = logParamInfo[prop]
    }

    return request({
        url: '/oifde/rest/createlogparam',
        method: 'post',
        data
    })
}

export function updateLogParam(logParamInfo) {
    const data = {
        tenantId: cookieUtils.getCookie('tenant_space_id'),
    }
    for (let prop in logParamInfo) {
        if (typeof logParamInfo[prop] === "undefined") {
            continue
        }
        if (typeof logParamInfo[prop] === 'string' && logParamInfo[prop] === '') {
            continue
        }
        data[prop] = logParamInfo[prop]
    }

    return request({
        url: '/oifde/rest/updatelogparam',
        method: 'post',
        data
    })
}

export function queryCallingNumberTenantextInfos(query) {
    const data = {
        tenantId: cookieUtils.getCookie('tenant_space_id'),
        source: useOifdeUserStore().source
    }
    for (var prop in query) {
        if (typeof query[prop] === "undefined") {
            continue
        }
        if (typeof query[prop] === 'string' && query[prop] === '') {
            continue
        }
        data[prop] = query[prop]
    }
    if (typeof data.pageNum === 'undefined') {
        data.pageNum = 1
    }
    if (typeof data.pageSize === 'undefined') {
        data.pageSize = 20
    }
    return request({
        url: '/oifde/rest/querycallingnumbertenantextinfos',
        method: 'post',
        data
    })
}

export function deleteSystemTenantextInfo(serviceNameList) {
    const data = {
        tenantId: cookieUtils.getCookie('tenant_space_id'),
        serviceNameList: serviceNameList,
        source: useOifdeUserStore().source
    }
    return request({
        url: '/oifde/rest/deletesystemtenantextinfo',
        method: 'post',
        data
    })
}

export function queryAccessCodeList(query) {
    const data = {
        tenantId: cookieUtils.getCookie('tenant_space_id')
    }
    for (let prop in query) {
        if (typeof query[prop] === "undefined") {
            continue
        }
        if (typeof query[prop] === 'string' && query[prop] === '') {
            continue
        }
        data[prop] = query[prop]
    }
    return request({
        url: '/oifde/rest/queryaccesscodes',
        method: 'post',
        data: data
    })
}