import request from "@/utils/request";
import {getRestService} from "@/views/ccmessaging/api/online-chat-workbench/utils";

// 帖子保存-无附件
export const saveChatPostInfo = data => {
    let serviceUrl = getRestService("/ccmessaging/v1/chatPostInfo", null, "/savePost");
    return request({
        url: serviceUrl,
        method: 'post',
        data
    })
}
// 帖子保存-有附件
export const uploadChatPostInfo = data => {
    return request({
        url: '/service-cloud/u-route/ccmessaging/uploadPostFileService/uploadVideo',
        method: 'post',
        data
    })
}
// 发起人-帖子列表
export const postListCreator = data => {
    let serviceUrl = getRestService("/ccmessaging/v1/chatPostInfo", null, "/postListCreator");
    return request({
        url: serviceUrl,
        method: 'post',
        data
    })
}
// 发起人-帖子下渠道列表
export const channelListCreator = data => {
    let serviceUrl = getRestService("/ccmessaging/v1/chatPostInfo", null, "/channelListCreator");
    return request({
        url: serviceUrl,
        method: 'post',
        data
    })
}
// 发起人-帖子详情
export const postDetailCreator = data => {
    let serviceUrl = getRestService("/ccmessaging/v1/chatPostInfo", null, "/postDetailCreator");
    return request({
        url: serviceUrl,
        method: 'post',
        data
    })
}
// 获取youTube的视频分类
export const youTubecategoryList = data => {
    let serviceUrl = getRestService("/ccmessaging/v1/chatPostInfo", null, "/youTubecategoryList");
    return request({
        url: serviceUrl,
        method: 'post',
        data
    })
}
// 发起人-删除帖子
export const deletePost = data => {
    let serviceUrl = getRestService("/ccmessaging/v1/chatPostInfo", null, "/deletePost");
    return request({
        url: serviceUrl,
        method: 'post',
        data
    })
}
// 发起人-撤回
export const postRecall = data => {
    let serviceUrl = getRestService("/ccmessaging/v1/chatPostInfo", null, "/recall");
    return request({
        url: serviceUrl,
        method: 'post',
        data
    })
}
// 查询审批人名称
export const queryApproveName = data => {
    let serviceUrl = getRestService("/ccmessaging/v1/chatPostInfo", null, "/queryApproveName");
    return request({
        url: serviceUrl,
        method: 'post',
        data
    })
}

// 审批人-帖子列表
export const postListApprove = function(data, type) {
    let serviceUrl = "";
    if (type === 'approve') {
        serviceUrl = getRestService("/ccmessaging/v1/chatPostInfo", null, "/postListApprove");
    } else if (type === 'history') {
        serviceUrl = getRestService("/ccmessaging/v1/chatPostInfo", null, "/postListHistory");
    }
    return request({
        url: serviceUrl,
        method: 'post',
        data
    })
}
// 审批人-帖子下渠道列表
export const channelListApprove = function(data, type) {
    if (!data) {
        return;
    }
    let serviceUrl = "";
    if (type === 'approve') {
        serviceUrl = getRestService("/ccmessaging/v1/chatPostInfo", null, "/channelListApprove");
    } else if (type === 'history') {
        serviceUrl = getRestService("/ccmessaging/v1/chatPostInfo", null, "/channelListHistory");
    }
    return request({
        url: serviceUrl,
        method: 'post',
        data
    })
}
export const postDetail = function(data, type) {
    let serviceUrl = "";
    if (type === 'approve') {
        serviceUrl = getRestService("/ccmessaging/v1/chatPostInfo", null, "/postDetailApprove");
    } else if (type === 'history') {
        serviceUrl = getRestService("/ccmessaging/v1/chatPostInfo", null, "/postDetailHistory");
    } else {
        serviceUrl = getRestService("/ccmessaging/v1/chatPostInfo", null, "/postDetailHistory");
    }
    return request({
        url: serviceUrl,
        method: 'post',
        data
    })
}
// 审批人-审批提交
export const postApproveSubmit = data => {
    let serviceUrl = getRestService("/ccmessaging/v1/chatPostInfo", null, "/approve");
    return request({
        url: serviceUrl,
        method: 'post',
        data
    })
}

// 申请人，查询渠道列表
export const channelList = data => {
    let serviceUrl = getRestService("/ccmessaging/v1/chatPostInfo", null, "/publishInit");
    return request({
        url: serviceUrl,
        method: 'post',
        data
    })
}

export const querySystemParam = data => {
    let serviceUrl = getRestService("/ccmessaging/v1/chatPostInfo", null, "/querySystemParam");
    return request({
        url: serviceUrl,
        method: 'post',
        data
    })
}
export const getUserPermissions = (tenantSpaceId, userId) => {
    return request({
        url: '/service-cloud/rest/sum/v2/tenantSpaces/' + tenantSpaceId + '/users/' + userId + '/perms',
        method: 'get',
    })
}
export const rePublish = data => {
    let serviceUrl = getRestService("/ccmessaging/v1/chatPostInfo", null, "/rePublish");
    return request({
        url: serviceUrl,
        method: 'post',
        data
    })
}