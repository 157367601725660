export default {
  "aicc.reset" : "إعادة الضبط",
  "aicc.intRangeLimit" : "أدخل عددًا صحيحًا يتراوح من {min} إلى {max}",
  "aicc.organized" : "المنظمة",
  "aicc.goto" : "تحقق من",
  "aicc.previous" : "السابق",
  "aicc.apibuilder.dev" : "تطوير واجهة",
  "aicc.loading" : "جار التحميل",
  "aicc.numberOnly" : "يمكن أن تحتوي على أرقام فقط",
  "aicc.search" : "البحث",
  "aicc.ok" : "حسناً",
  "aicc.traceId.error" : "TraceId: 0. أرسل معلومات TraceId إلى موظفي التشغيل والصيانة لتحديد موقع الخطأ.",
  "aicc.select" : "اختر ",
  "aicc.columnConfig" : "تكوين العمود",
  "aicc.selectedInfo" : "{n} تم تحديد",
  "aicc.more" : "المزيد",
  "aicc.selectedColumns" : "الأعمدة المحددة",
  "aicc.selectedColumnsTip" : "(يمكن سحبها للترتيب)",
  "aicc.MaxLength" : "إدخال سلسلة تتكون من 0 حرف كحد أقصى",
  "aicc.lengthMinLimit" : "يجب أن يحتوي على {0} أحرف على الأقل",
  "aicc.selectAll" : "تم تحديد الكل",
  "aicc.columnSettings" : "إعدادات الأعمدة",
  "aicc.next" : "التالي",
  "aicc.error" : "خطأ",
  "aicc.unorganized" : "غير منظمة",
  "aicc.skip" : "تخطي",
  "aicc.lengthLimit" : "لا يمكن أن يتجاوز الطول {limit} من الأحرف",
  "aicc.advancedSearch" : "البحث المتقدم",
  "aicc.noData" : "لا توجد بيانات متاحة",
  'aicc.timeout.desc':'انتهاء الجلسة',
  'aicc.timeout.solution':'انتهت مهلة تسجيل الدخول. تحتاج إلى تسجيل الدخول مرة أخرى.',
  'aicc.input': 'يُرجى إدخال',
}