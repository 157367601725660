import ccprovisionEnUS from './ccprovision_en_US'
import ccprovisionZhCN from './ccprovision_zh_CN'
import ccprovisionEsEs from './ccprovision_es_ES'
import ccprovisionThTH from './ccprovision_th_TH'
import ccprovisionPtBr from './ccprovision_pt_BR'
import ccprovisionFrFr from './ccprovision_fr_FR'
import ccprovisionarSA from "./ccprovision_ar_SA"
import ccprovisiondeDE from "./ccprovision_de_DE";

const ccprovision = {
    en_US: {
        ...ccprovisionEnUS,
    },
    zh_CN: {
        ...ccprovisionZhCN,
    },
    es_ES: {
        ...ccprovisionEsEs,
    },
    th_TH: {
        ...ccprovisionThTH,
    },
    pt_BR: {
        ...ccprovisionPtBr,
    },
    fr_FR: {
        ...ccprovisionFrFr
    },
  ar_SA: {
    ...ccprovisionarSA
  },
    de_DE: {
        ...ccprovisiondeDE
    }
}

export default ccprovision