<template>
  <div>
    <sweet-dialog v-model="dialogVisible" :title="$t('isales.exportdata.title.exportresult')" width="896px" class="aicc-dialog-limit-height">
      <div class="aicc-align--absolute-right aicc-m-bottom--medium">
        <sweet-button class="aicc-button-icon" @click="handleRefresh">
          <aicc-tooltip :open-delay="800" :content="$t('ccmessaging.chat.chatconfig.refresh')" placement="top">
            <img class="sweetui-icon-download-l refresh-icon-img" src="@/assets/img/search/search_refresh.png" />
          </aicc-tooltip>
        </sweet-button>
      </div>
      <aicc-table
          :tableData="tableData"
          :tableColumns="tableColumns"
          :tableTools="{ showPagination: true ,columnConfig: false}"
          :paginationConfig="paginationConfig"
          @handlePagination="handlePagination"
          ref='refAiccTable'
          :height="430">
        <!--结果-->
        <template #result="scope">{{ OIFDE_EXPORT_RESULT_ENUM[scope.row.result] }}</template>
        <template #downloadButton="scope">
          <sweet-button v-if="scope.row.result === '2'" text type="primary" @click="download(scope.row)">{{ $t('isales.taskresultexport.title.download') }}</sweet-button>
        </template>
      </aicc-table>
    </sweet-dialog>
  </div>
</template>

<script>
import {OIFDE_EXPORT_RESULT_ENUM} from "@/views/oifde/components/OifdeExport/enums";
import {queryExportResultList, downloadFile} from "@/views/oifde/request/api/exportResult";
import * as $TimeUtils from "@/views/cms/utils/timeUtils";
import {aiccDownloadNew} from "@/utils/aiccUploadAndDownload";

export default {
  name: 'oifdeExportResultListDialog',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    servType: String,
  },
  computed: {
    $TimeUtils() {
      return $TimeUtils
    },
    dialogVisible: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      }
    }
  },
  data() {
    return {
      OIFDE_EXPORT_RESULT_ENUM,
      tableColumns: [
        {
          label: this.$t('oifde.trace.beginTime'),
          prop: 'beginTime',
        },
        {
          label: this.$t('oifde.trace.endTime'),
          prop: 'endTime',
        },
        {
          label: this.$t('oifde.export.task.status'),
          slot: 'result',
        },
        {
          label: this.$t('oifde.table.actions'),
          slot:'downloadButton'
        }
      ],
      paginationConfig: {
        pageSizes: [10], // 每页条数列表
        pageSize: 10, // 每页条数
        pageNum: 1,
        total: 0, //总条数
        layout: 'sizes, total, prev, pager, next', // 分页组件布局配置
        limit: 10,
        offset: 0,
      },
      exportResultObject:{},
      tableData:[],
    }
  },
  created() {
    this.queryExportResult();
  },
  methods: {
    // 更新
    handleRefresh() {
      this.$refs.refAiccTable.handleInitChange()
    },
    // 查询导出结果
    queryExportResult() {
      this.tableData.length = 0
      const queryParam = {
        pageNum : this.paginationConfig.pageNum,
        pageSize : this.paginationConfig.pageSize,
        servType: this.servType
      }
      queryExportResultList(queryParam).then(res => {
        this.tableData = res.records;
        this.paginationConfig.total = res.total;
        if (this.tableData) {
          for (let index of this.tableData) {
            for (let proty in index) {
              if (proty === "beginTime" || proty === "endTime") {
                index[proty] = this.$TimeUtils.transTime(index[proty]);
              }
            }
          }
        }
      })
    },
    // 分页
    handlePagination(data) {
      Object.assign(this.paginationConfig, data)
      this.queryExportResult()
    },
    // 刷新
    refreshData() {
      this.$refs.refAiccTable.handleInitChange()
    },
    // 下载
    download(params) {
      const queryParam = {
        id: params.id
      }
      downloadFile(queryParam).then(response => {
        aiccDownloadNew(response)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.aicc-button-icon {
  position: relative;
  align-self: flex-end;

  img {
    width: 16px;
    position: relative;
    top: 1px;
  }
}
</style>