export default {
  "ccmessaging.chat.chat.closeDialog": "确认关闭",
  "ccmesaging.chat.agentconsole.commom.label": "标签",
  "ccmesaging.chat.agentconsole.label.callReason": "来电原因",
  "ccmesaging.chat.agentconsole.label.commonreply": "常用回复语",
  "ccmesaging.chat.agentconsole.label.expression": "表情符号",
  "ccmesaging.chat.agentconsole.label.fontstyle": "字体样式",
  "ccmesaging.chat.agentconsole.label.historicalcontact": "历史接触",
  "ccmesaging.chat.agentconsole.label.incallmessage": "来电消息",
  "ccmesaging.chat.agentconsole.label.Realtimetransfer": "实时转写",
  "ccmesaging.chat.agentconsole.label.robotChat": "智能语音机器人对话",
  "ccmesaging.chat.agentconsole.label.screenshot": "屏幕截图",
  "ccmesaging.chat.agentconsole.label.seeMore": "查看更多",
  "ccmesaging.chat.agentconsole.label.service": "服务",
  "ccmesaging.chat.agentconsole.label.uploadattachment": "上传附件",
  "ccmesaging.chat.agentconsole.label.uploadimage": "上传图片",
  "ccmesaging.chat.agentconsole.title.release": "释放",
  "ccmesaging.chat.cobrowse.accept": "接受",
  "ccmesaging.chat.cobrowse.agentMax": "当前座席正在网页协同，请稍后再尝试",
  "ccmesaging.chat.cobrowse.isAcceptWeb": "是否接受网页协同邀请？",
  "ccmesaging.chat.cobrowse.isAcceptWebMax": "已超出当前租户允许的网页协同最大连接数",
  "ccmesaging.chat.cobrowse.noWebSiteAuth": "坐席没有网页协同权限",
  "ccmesaging.chat.cobrowse.refused": "拒绝",
  "ccmesaging.chat.cobrowse.title": "网页协同邀请",
  "ccmesaging.chat.cobrowse.webSite": "网页协同网址：",
  "ccmesaging.chat.cobrowse.webSiteButton": "网页协同",
  "ccmesaging.chat.cobrowse.webSitePlaceholder": "请输入分享网址",
  "ccmessaging.address.protocol.check": "请注意，当前输入的地址所使用的网络协议不是安全协议，如确定使用，可能存在安全风险。",
  "ccmessaging.channelconfig.algorithm": "对接加密算法",
  "ccmessaging.channelconfig.email.freeTimeText": "非工作时间自动回复",
  "ccmessaging.channelconfig.email.freeTimeText.tips": "开启后非工作时间对接收到的客户邮件进行回复",
  "ccmessaging.channelconfig.email.reply.each.eamil": "每封邮件自动回复",
  "ccmessaging.channelconfig.email.selectAutoReply": "邮件模板",
  "ccmessaging.channelconfig.email.selectFreeTimeText": "请选择非工作日回复邮件模板",
  "ccmessaging.channelconfig.email.worktime.autoReply": "工作时间自动回复",
  "ccmessaging.channelconfig.email.worktime.autoReply.tips": "开启后工作时间对收到的客户邮件进行回复",
  "ccmessaging.channelconfig.sms.autoReply": "自动回复内容",
  "ccmessaging.channelconfig.sms.autoReply.tips": "接入座席成功后自动回复内容",
  "ccmessaging.channelconfig.sms.freeTimeText": "非工作日回复内容",
  "ccmessaging.channelconfig.sms.selectAutoReply": "请选择自动回复短信模板",
  "ccmessaging.channelconfig.sms.selectFreeTimeText": "请选择非工作日回复短信模板",
  "ccmessaging.char.agentstatus.after.callends": "座席通话结束后状态配置生效优先级: 组织级 > 租户级。配置值为空表示此配置不生效，将使用租户级配置",
  "ccmessaging.char.call.Idle.state": "空闲态",
  "ccmessaging.char.call.work.state": "整理态",
  "ccmessaging.char.channelconfig.duplicateCodeName": "渠道名称已存在",
  "ccmessaging.chat.agent.organizational.structure": "组织机构",
  "ccmessaging.chat.agent.refreshFail": "请勿连续多次刷新页面",
  "ccmessaging.chat.agent.transferSessionFail": "转移会话失败",
  "ccmessaging.chat.agentconsole.accesschannel": "渠道",
  "ccmessaging.chat.agentconsole.agentStatus.error": "座席不处于在线和通话中状态，无法发起会话",
  "ccmessaging.chat.agentconsole.allResults": "全部结果",
  "ccmessaging.chat.agentconsole.asyncTimeBeyond": "发送失败，已超过不允许回复间隔",
  "ccmessaging.chat.agentconsole.beyondLimitNum": "当前消息发送量超出上限！",
  "ccmessaging.chat.agentconsole.browserecord": "浏览记录",
  "ccmessaging.chat.agentconsole.chat.close": "当前会话已结束",
  "ccmessaging.chat.agentconsole.chat.userId": "用户标识",
  "ccmessaging.chat.agentconsole.chat.userSettings": "用户设置",
  "ccmessaging.chat.agentconsole.chatPhrase": "常用语",
  "ccmessaging.chat.agentconsole.cobrowse": "网页协同",
  "ccmessaging.chat.agentconsole.contract.pdfUploadMaxSizeTip": "合同大小不能超过{n}M。",
  "ccmessaging.chat.agentconsole.contract.statisticSigns": "签名统计",
  "ccmessaging.chat.agentconsole.contract.statisticTotalSigns": "统计",
  "ccmessaging.chat.agentconsole.contract.statisticTotalSigns.failTip": "统计合同中的签名项失败！",
  "ccmessaging.chat.agentconsole.contract.statisticTotalSigns.noSigns.failTip": "请上传有签名信息的合同！",
  "ccmessaging.chat.agentconsole.contract.statisticTotalSigns.noStaticSigns.failTip": "请先统计带有签名的合同！",
  "ccmessaging.chat.agentconsole.contract.upload.failTip": "上传合同失败！",
  "ccmessaging.chat.agentconsole.contract.upload.noSelectSigns.failTip": "该合同没有勾选签名项！",
  "ccmessaging.chat.agentconsole.contract.upload.sameContract.failTip": "用户存在未失效的合同！",
  "ccmessaging.chat.agentconsole.current": "当前会话",
  "ccmessaging.chat.agentconsole.customerinformation": "客户信息",
  "ccmessaging.chat.agentconsole.defaultOrgName": "无归属组织",
  "ccmessaging.chat.agentconsole.document": "文档",
  "ccmessaging.chat.agentconsole.emailNotSupport": "邮件渠道不支持多媒体消息",
  "ccmessaging.chat.agentconsole.enter.total.number": "请输入签名总数",
  "ccmessaging.chat.agentconsole.filterByCondition": "条件筛选",
  "ccmessaging.chat.agentconsole.fivegNotSupport": "5G渠道不支持多媒体消息",
  "ccmessaging.chat.agentconsole.hisconversation": "历史会话",
  "ccmessaging.chat.agentconsole.history": "历史会话",
  "ccmessaging.chat.agentconsole.image": "图片",
  "ccmessaging.chat.agentconsole.information": "信息",
  "ccmessaging.chat.agentconsole.intention": "意图",
  "ccmessaging.chat.agentconsole.IpAddress": "IP地址",
  "ccmessaging.chat.agentconsole.label.defaultwelcome": "欢迎接入在线客服系统！",
  "ccmessaging.chat.agentconsole.label.endsession": "是否结束会话？",
  "ccmessaging.chat.agentconsole.label.filesFacebookNotsupport": "Facebook messenger目前不支持接收位置信息",
  "ccmessaging.chat.agentconsole.label.filesTwitterNotsupport": "推特不支持音频、地址，也不支持时长超过30秒的视频！",
  "ccmessaging.chat.agentconsole.label.filesWechatNotsupport": "微信不支持时长超过60秒的音频！",
  "ccmessaging.chat.agentconsole.label.messageRejected": "消息被推特拒绝接收，因为看起来像垃圾消息，请使用同义语句或稍后再试:",
  "ccmessaging.chat.agentconsole.label.NoAudioDuration": "未能获取到音频时长，请重新上传！",
  "ccmessaging.chat.agentconsole.label.NoVideoDuration": "未能获取到视频时长，请重新上传！",
  "ccmessaging.chat.agentconsole.label.ongoingsession": "当前无进行中的会话！",
  "ccmessaging.chat.agentconsole.label.ongoingwx": "当前渠道不能发送位置信息",
  "ccmessaging.chat.agentconsole.label.skillNoAgent": "该技能队列下座席不在线！",
  "ccmessaging.chat.agentconsole.loading": "加载更多",
  "ccmessaging.chat.agentconsole.locate": "位置",
  "ccmessaging.chat.agentconsole.mailDraft": "邮件草稿",
  "ccmessaging.chat.agentconsole.message.notworktime": "座席不在工作时间！",
  "ccmessaging.chat.agentconsole.messageloading": "正在加载...",
  "ccmessaging.chat.agentconsole.multimedia": "多媒体",
  "ccmessaging.chat.agentconsole.multimediaid": "多媒体ID",
  "ccmessaging.chat.agentconsole.multimedianame": "多媒体名称",
  "ccmessaging.chat.agentconsole.myconversation": "我的会话",
  "ccmessaging.chat.agentconsole.nocontact": "当前无联系人",
  "ccmessaging.chat.agentconsole.noHiscontact": "无历史会话",
  "ccmessaging.chat.agentconsole.notSupport": "该渠道不支持此类型的多媒体消息",
  "ccmessaging.chat.agentconsole.noUserArrive": "当前无用户接入!",
  "ccmessaging.chat.agentconsole.novoEmail": "新建邮件",
  "ccmessaging.chat.agentconsole.nowconversation": "当前会话",
  "ccmessaging.chat.agentconsole.only.pdf.supported": "仅支持pdf类型的文件",
  "ccmessaging.chat.agentconsole.photo": "照片",
  "ccmessaging.chat.agentconsole.promptConetnt.cannot.rollback": "该消息禁止撤回",
  "ccmessaging.chat.agentconsole.promptConetnt.ContentLengthExceeds": "不允许发送长度超过限制的文本内容!",
  "ccmessaging.chat.agentconsole.promptConetnt.ContentNull": "不允许发送空的内容!",
  "ccmessaging.chat.agentconsole.promptConetnt.delectMessageRecord": "您已删除一条记录",
  "ccmessaging.chat.agentconsole.promptConetnt.onlyFiveG": "5G卡片消息仅支持5G渠道!",
  "ccmessaging.chat.agentconsole.promptConetnt.onlyWebCard": "web卡片消息仅支持web渠道!",
  "ccmessaging.chat.agentconsole.promptConetnt.operateFail": "不能操作未发送成功的消息",
  "ccmessaging.chat.agentconsole.promptConetnt.operateFailWithChannel": "只能操作web渠道消息",
  "ccmessaging.chat.agentconsole.promptConetnt.operateRollBackFail": "发送时间超过两分钟的消息，不支持撤回",
  "ccmessaging.chat.agentconsole.promptConetnt.rollBackMessage": "您已撤回一条消息",
  "ccmessaging.chat.agentconsole.promptConetnt.userAlreadyInSession": "发送失败，用户正在通话中",
  "ccmessaging.chat.agentconsole.promptConetnt.userBusy": "待坐席空闲时候再处理",
  "ccmessaging.chat.agentconsole.promptConetnt.userInfoNull": "用户信息获取不正确，会话将断开!",
  "ccmessaging.chat.agentconsole.promptConetnt.userIsTalking.saveDraftFailed": "邮件发送失败，用户正在通话中，邮件存入草稿失败",
  "ccmessaging.chat.agentconsole.promptConetnt.userIsTalking.saveDraftSuccess": "邮件发送失败，用户正在通话中，当前邮件已存入草稿",
  "ccmessaging.chat.agentconsole.promptConetnt.userNoArrive": "当前无用户接入或者没有选中用户!",
  "ccmessaging.chat.agentconsole.promptConetnt.userOffline": "当前用户已离线!",
  "ccmessaging.chat.agentconsole.queuing": "排队中",
  "ccmessaging.chat.agentconsole.quoteReply": " 引用此条回复语",
  "ccmessaging.chat.agentconsole.recommendedCaseType": "推荐工单分类",
  "ccmessaging.chat.agentconsole.replyEmail": "回复邮件",
  "ccmessaging.chat.agentconsole.robotAssistant": " 机器人助手",
  "ccmessaging.chat.agentconsole.select.contract.uploaded": "请选择要上传的文档",
  "ccmessaging.chat.agentconsole.select.mandatory.signature": "请选择必填签名",
  "ccmessaging.chat.agentconsole.selectCaseType": "选择工单分类",
  "ccmessaging.chat.agentconsole.send.contract": "发送合同",
  "ccmessaging.chat.agentconsole.send.repeat": "请勿重复点击！",
  "ccmessaging.chat.agentconsole.sendEmail": "发送邮件",
  "ccmessaging.chat.agentconsole.sendLocalFile": "发送本地文件",
  "ccmessaging.chat.agentconsole.sendmessage": "发送消息",
  "ccmessaging.chat.agentconsole.setHandledNumber": "设置受理号码",
  "ccmessaging.chat.agentconsole.signature.variable": "签名{n}",
  "ccmessaging.chat.agentconsole.signInCallNum": " 本次签入通话数：",
  "ccmessaging.chat.agentconsole.smsTemplate": "短信模板",
  "ccmessaging.chat.agentconsole.software": "软件环境",
  "ccmessaging.chat.agentconsole.spellcheck.correcting.not.finished": "您的错别字未全部纠正，是否结束检查？",
  "ccmessaging.chat.agentconsole.spellcheck.correcting.cancel.confirmation": "取消后已纠正的内容将不会被保存，是否取消检查？",
  "ccmessaging.chat.agentconsole.spellcheck.correctly.writing": "正确字",
  "ccmessaging.chat.agentconsole.spellcheck.failed": "拼写检查失败。",
  "ccmessaging.chat.agentconsole.spellcheck.language": "语言",
  "ccmessaging.chat.agentconsole.spellcheck.next": "下一个",
  "ccmessaging.chat.agentconsole.spellcheck.previous": "上一个",
  "ccmessaging.chat.agentconsole.spellcheck.replace": "替换",
  "ccmessaging.chat.agentconsole.spellcheck.replaceall": "全部替换",
  "ccmessaging.chat.agentconsole.spellcheck.sentence.length.failed": "待检查的文字长度超出1024的限制",
  "ccmessaging.chat.agentconsole.spellcheck.sentence.length.failed.front": "待检查的文字长度超出1024的限制",
  "ccmessaging.chat.agentconsole.spellcheck.sentence.no.context": "当前输入框无文本内容，不需要做拼写检查",
  "ccmessaging.chat.agentconsole.spellcheck.sentence.no.mistake": "无拼写错误",
  "ccmessaging.chat.agentconsole.spellcheck.title": "拼写检查",
  "ccmessaging.chat.agentconsole.spellcheck.wrongly.writing": "错别字",
  "ccmessaging.chat.agentconsole.talkavg": " 平均通话时长(s)：",
  "ccmessaging.chat.agentconsole.talkNum": " 今日通话数：",
  "ccmessaging.chat.agentconsole.thumbnail": "缩略图",
  "ccmessaging.chat.agentconsole.total.number.signatures": "签名总数",
  "ccmessaging.chat.agentconsole.upload": "上传文件",
  "ccmessaging.chat.agentconsole.uploadfail": "文件上传失败",
  "ccmessaging.chat.agentconsole.userInputStatus": "预览用户输入态",
  "ccmessaging.chat.agentconsole.video": "视频",
  "ccmessaging.chat.agentconsole.voice": "语音",
  "ccmessaging.chat.agentconsole.waittime": "等待时长",
  "ccmessaging.chat.async.asyncMsgNum": "离线消息数",
  "ccmessaging.chat.async.asyncMsgTrending": "离线消息趋势",
  "ccmessaging.chat.async.asyncUserNum": "离线用户数",
  "ccmessaging.chat.async.asyncUserTrending": "离线用户趋势",
  "ccmessaging.chat.async.channelSelect": "渠道筛选",
  "ccmessaging.chat.async.requestError": "请求失败",
  "ccmessaging.chat.async.statistics": "离线消息监控",
  "ccmessaging.chat.async.statistics.asynChannelTotal": "离线渠道总数",
  "ccmessaging.chat.async.statistics.asynMsgTotal": "离线消息总数",
  "ccmessaging.chat.async.statistics.asynUserTotal": "离线用户总数",
  "ccmessaging.chat.async.statistics.updateTime": "数据更新时间",
  "ccmessaging.chat.call.end.state": "通话结束状态",
  "ccmessaging.chat.card": "卡片",
  "ccmessaging.chat.edit.card": "编辑卡片",
  "ccmessaging.chat.multimedia.selectTemplate": "选择模板",
  "ccmessaging.chat.card.template": "WhatsApp模板",
  "ccmessaging.chat.channelconfig.accessToken": "访问令牌",
  "ccmessaging.chat.channelconfig.accessTokenSecret": "访问令牌密钥",
  "ccmessaging.chat.channelconfig.accountKeyRepeat": "WhatsApp渠道accountKey重复",
  "ccmessaging.chat.channelconfig.add": "新建",
  "ccmessaging.chat.channelconfig.addressValidate": "必须为sip:号码{'@'}botplatform.rcs.chinamobile.com的格式，且长度小于100个字符",
  "ccmessaging.chat.channelconfig.afterhalf": "，可点击去除浏览器拦截）",
  "ccmessaging.chat.channelconfig.agentTimeout": "自定义座席未回复超时时间和重新分配新座席提示语。",
  "ccmessaging.chat.channelconfig.agentTimeout.emailTimeOutTips": "自定义座席未回复超时时间。",
  "ccmessaging.chat.channelconfig.agentWorkTime": "座席工作时间",
  "ccmessaging.chat.channelconfig.alphavalidateFailed": "存在非法字符，输入值只能由英文字母、数字或下划线组成(首字符只能用英文字母或下划线）",
  "ccmessaging.chat.channelconfig.apifabric.ak": "APP标识",
  "ccmessaging.chat.channelconfig.apifabric.sk": "APP密钥",
  "ccmessaging.chat.channelconfig.apiKey": "API码",
  "ccmessaging.chat.channelconfig.apiKeySecret": "API密钥",
  "ccmessaging.chat.channelconfig.appId": "App Id",
  "ccmessaging.chat.channelconfig.appSecret": "App密钥",
  "ccmessaging.chat.channelconfig.assertionSigningKey": "Assertion签名密钥",
  "ccmessaging.chat.channelconfig.assistantAccessCode": "机器人助手接入码",
  "ccmessaging.chat.channelconfig.assistantSet": "机器人助手配置",
  "ccmessaging.chat.channelconfig.asyncFlag": "外呼离线消息配置",
  "ccmessaging.chat.channelconfig.asyncTip": "若关闭，会导致已有离线消息丢失。",
  "ccmessaging.chat.channelconfig.asyncTime": "离线路由限制时长",
  "ccmessaging.chat.channelconfig.asyncTime.tip": "离线场景下，在设置的时长内，客户发送的离线消息将不再路由至座席，并直接存储为离线消息。",
  "ccmessaging.chat.channelconfig.auth.duplicateAuth": "该公众号授权已经存在，无需再次配置",
  "ccmessaging.chat.channelconfig.auth.relation.timeout": "授权可能失效，请用微信重新扫码授权",
  "ccmessaging.chat.channelconfig.beforhalf": "您可以点击“试一试”按钮，查看集成效果。（如果网址正确并在浏览器右上角出现拦截标志",
  "ccmessaging.chat.channelconfig.bsp": "服务路径提供商",
  "ccmessaging.chat.channelconfig.cert.empty": "推送地址为https协议时，证书不能为空",
  "ccmessaging.chat.channelconfig.Certification": "认证方式",
  "ccmessaging.chat.channelconfig.changeLogo": "更换头像",
  "ccmessaging.chat.channelconfig.channelID": "渠道ID",
  "ccmessaging.chat.channelconfig.channelSecret": "渠道密钥",
  "ccmessaging.chat.channelconfig.chatbotAddress": "聊天机器人地址",
  "ccmessaging.chat.channelconfig.checkAgentTimeOutInterval": "座席超时未回复转移时间不应大于客户未回复提醒间隔",
  "ccmessaging.chat.channelconfig.checkEncodingAESKey": "密钥长度为43且只能包含A-Z,a-z,0-9",
  "ccmessaging.chat.channelconfig.checkmessage": `不能含有特殊字符<>'"/\\`,
  "ccmessaging.chat.channelconfig.checkNickName": "不能含有特殊字符+~!{'@'}#$%&*()/=+{'{'}{'}'};:'\",<.>?",
  "ccmessaging.chat.channelconfig.checkName": "名称不能为系统等字样",
  "ccmessaging.chat.channelconfig.checkNumber": "无效的整数",
  "ccmessaging.chat.channelconfig.checkSocialChatNum": "不可以含有小数",
  "ccmessaging.chat.channelconfig.checkWhatsAppBaseUrl": "该URL非法无效",
  "ccmessaging.chat.channelconfig.checkConfirmTokenEqualToToken": "必须输入相同的值",
  "ccmessaging.chat.channelconfig.checkWhatsAppPhoneNum": "非法的手机号码",
  "ccmessaging.chat.channelconfig.clicktocall.empty": "技能队列或者ivr接入码不能为空",
  "ccmessaging.chat.channelconfig.clickToCallSkillList": "点击通话技能队列",
  "ccmessaging.chat.channelconfig.close": "关闭",
  "ccmessaging.chat.channelconfig.codeName": "渠道接入编码",
  "ccmessaging.chat.channelconfig.defaultReply": "默认回复语",
  "ccmessaging.chat.channelconfig.delete": "删除",
  "ccmessaging.chat.channelconfig.delete.exist.asyncMsg": "【{n}】渠道删除失败，建议离线消息分配后再尝试",
  "ccmessaging.chat.channelconfig.delete.exist.asyncUser": "【{n}】渠道删除失败，建议离线用户分配后再尝试",
  "ccmessaging.chat.channelconfig.delete.exist.session": "【{n}】渠道删除失败，建议会话结束后再尝试",
  "ccmessaging.chat.channelconfig.edit": "操作",
  "ccmessaging.chat.channelconfig.email.bccLimit": "密送人上限",
  "ccmessaging.chat.channelconfig.email.ccLimit": "抄送人上限",
  "ccmessaging.chat.channelconfig.email.address.limit": "个",
  "ccmessaging.chat.channelconfig.email.number": "邮件地址数量配置",
  "ccmessaging.chat.channelconfig.email.personal": "使用个人邮箱回复",
  "ccmessaging.chat.channelconfig.email.placeholder": "1-100,非必填",
  "ccmessaging.chat.channelconfig.email.range": "请输入1-100的正整数",
  "ccmessaging.chat.channelconfig.email.receiverLimit": "收件人上限",
  "ccmessaging.chat.channelconfig.email.selectSignature": "请选择邮件签名",
  "ccmessaging.chat.channelconfig.email.signature": "邮件签名",
  "ccmessaging.chat.channelconfig.email.signature.desc": "邮件签名描述",
  "ccmessaging.chat.channelconfig.email.signature.name": "邮件签名名称",
  "ccmessaging.chat.channelconfig.email.totalEmailNumber": "收件人、抄送人和密送人的邮件地址总数不能超过100",
  "ccmessaging.chat.channelconfig.emailRepeate": "邮件接收网关已经被使用，请前往消息路由配置页面查看！",
  "ccmessaging.chat.channelconfig.endchat": "是否结束会话？",
  "ccmessaging.chat.channelconfig.endOfSession": "客户超时结束会话",
  "ccmessaging.chat.channelconfig.envName": "环境名称",
  "ccmessaging.chat.channelconfig.envNameValidate": "必须为字母或数字，且长度小于24个字符",
  "ccmessaging.chat.channelconfig.facebookBackground": "Facebook",
  "ccmessaging.chat.channelconfig.fiveGBackground": "5G消息CSP",
  "ccmessaging.chat.channelconfig.gender": "性别",
  "ccmessaging.chat.channelconfig.guestTimeout": "自定义访客未回复超时时间和会话结束回复语，若不开启，默认20分钟超时。",
  "ccmessaging.chat.channelconfig.guestTimeout.timeOutTips": "自定义访客未回复超时时间，若不开启，默认{n}分钟超时。",
  "ccmessaging.chat.channelconfig.hostingMethod": "托管方式",
  "ccmessaging.chat.channelconfig.hour": "时",
  "ccmessaging.chat.channelconfig.identityCertificate": "身份证书",
  "ccmessaging.chat.channelconfig.importAvatarSize": "头像图片大小超过6M",
  "ccmessaging.chat.channelconfig.importAvatartype": "头像图片类型错误",
  "ccmessaging.chat.channelconfig.infobipPassword": "密码",
  "ccmessaging.chat.channelconfig.infobipUserName": "用户名称",
  "ccmessaging.chat.channelconfig.infoConfiguration": "信息配置",
  "ccmessaging.chat.channelconfig.keyEscrow": "秘钥托管",
  "ccmessaging.chat.channelconfig.KeyValidate": "只能由字母、数字和11位特殊字符_~!{'@'}#$%^&*-任意组成",
  "ccmessaging.chat.channelconfig.whatsappNameValidate": "只能由字母、数字和12位特殊字符_~!{'@'}#$%^&*-.任意组成",
  "ccmessaging.chat.channelconfig.lastagent.time": "时间范围",
  "ccmessaging.chat.channelconfig.lastModifyTime": "最后修改时间",
  "ccmessaging.chat.channelconfig.logo": "头像",
  "ccmessaging.chat.channelconfig.messagePushUrl.not.configure.trustList": "该地址未配置白名单，请联系系统管理员",
  "ccmessaging.chat.channelconfig.hrefUrl.not.configure.trustList": "超链接地址未配置白名单，请联系系统管理员",
  "ccmessaging.chat.channelconfig.minute": "分",
  "ccmessaging.chat.channelconfig.name": "名字",
  "ccmessaging.chat.channelconfig.nochatonline": "当前无进行中的会话！",
  "ccmessaging.chat.channelconfig.noLimit": "无限制",
  "ccmessaging.chat.channelconfig.NonworkingTimeReminder": "非工作时间提醒信息",
  "ccmessaging.chat.channelconfig.nonWorkTimeDay": "非工作日",
  "ccmessaging.chat.channelconfig.noReplyIntervalTime": "客户超时时长",
  "ccmessaging.chat.channelconfig.noReplyIntervalTime.tip": "外呼场景下，客户回复超出设置时长，将自动结束会话。",
  "ccmessaging.chat.channelconfig.noReplyIntervalTimeValidate": "客户未回复中断间隔时间不能大于客户未回复提醒间隔时间",
  "ccmessaging.chat.channelconfig.noReplyTime": "座席外呼限制时长",
  "ccmessaging.chat.channelconfig.noReplyTime.tip": "客户最近一次联系座席后，间隔超出设置时长，不允许座席主动外呼。",
  "ccmessaging.chat.channelconfig.offBeginTime": "起始时间到当前",
  "ccmessaging.chat.channelconfig.offLastAgentType": "熟客类型",
  "ccmessaging.chat.channelconfig.offTimeRange": "时间范围内",
  "ccmessaging.chat.channelconfig.offToday": "当天内",
  "ccmessaging.chat.channelconfig.onlyContainSubType": "座席转移会话时仅展示对应渠道类型的技能队列",
  "ccmessaging.chat.channelconfig.orgConfiguration": "组织配置",
  "ccmessaging.chat.channelconfig.pageId": "主页ID",
  "ccmessaging.chat.channelconfig.personalSetting": "个性化设置",
  "ccmessaging.chat.channelconfig.phoneNumber": "电话号码",
  "ccmessaging.chat.channelconfig.pleaseSelect": "请选择...",
  "ccmessaging.chat.channelconfig.PreferredLastAgent": "熟客模式",
  "ccmessaging.chat.channelconfig.Previous": "上一步",
  "ccmessaging.chat.channelconfig.privateKeyValidate": "需为JSON格式，JSON中的键值只能由字母、数字和11位特殊字符_~!{'@'}#$%^&*-任意组成",
  "ccmessaging.chat.channelconfig.publicConfiguration": "公共配置",
  "ccmessaging.chat.channelconfig.pushMessageUrlValid": "保存渠道配置失败，请检查消息推送地址或证书是否合法",
  "ccmessaging.chat.channelconfig.qryaddressconfigfailed": "查询5G渠道chatbotAddress校验规则失败，校验规则未配置",
  "ccmessaging.chat.channelconfig.queryType": "选择方式",
  "ccmessaging.chat.channelconfig.queueReminder": "排队提醒",
  "ccmessaging.chat.channelconfig.queueReminderContent": "排队提醒内容",
  "ccmessaging.chat.channelconfig.queueReminderInterval": "排队提醒间隔",
  "ccmessaging.chat.channelconfig.reCreateScenario": "生成",
  "ccmessaging.chat.channelconfig.refreshAccessToken": "刷新 AccessToken 失败.",
  "ccmessaging.chat.channelconfig.robotAccessCode": "机器人接入码",
  "ccmessaging.chat.channelconfig.robotApplicationKey": "机器人应用密钥",
  "ccmessaging.chat.channelconfig.robotSet": "机器人设置",
  "ccmessaging.chat.channelconfig.routeType": "路由方式",
  "ccmessaging.chat.channelconfig.routeTypeSkill": "技能队列路由",
  "ccmessaging.chat.channelconfig.saveAndToNext": "保存并进行下一步",
  "ccmessaging.chat.channelconfig.savechannelconfigfailed": "保存渠道配置失败！",
  "ccmessaging.chat.channelconfig.scanCodeHosting": "扫码托管",
  "ccmessaging.chat.channelconfig.scenario.status.existent": "scenario key：存在",
  "ccmessaging.chat.channelconfig.scenario.status.noExistent": "scenario key：不存在",
  "ccmessaging.chat.channelconfig.select.algorithm": "请选择加密算法",
  "ccmessaging.chat.channelconfig.select.algorithm.configtips": "请确保渠道配置的加密算法跟平台一致",
  "ccmessaging.chat.channelconfig.select.algorithm.safetips": "该算法强度不够，如果平台支持建议选择更高强度的加密算法",
  "ccmessaging.chat.channelconfig.selectAccessChannel": "选择接入渠道",
  "ccmessaging.chat.channelconfig.selectConfigurationMethod": "选择配置方法",
  "ccmessaging.chat.channelconfig.selectRobot": "选择机器人",
  "ccmessaging.chat.channelconfig.selectRobotAssistant": "选择机器人助手",
  "ccmessaging.chat.channelconfig.sessionTransferConfig": "会话转移设置",
  "ccmessaging.chat.channelconfig.silentAgentDelete": "确定删除所选的语音静默座席配置？",
  "ccmessaging.chat.channelconfig.silentAgentSelect": "请选择要删除的语音静默座席配置",
  "ccmessaging.chat.channelconfig.silentAgentSkillList": "静默座席技能队列",
  "ccmessaging.chat.channelconfig.skill": "技能队列名称（接入码+扩展码）",
  "ccmessaging.chat.channelconfig.skillList": "技能队列",
  "ccmessaging.chat.channelconfig.smsRepeate": "短信网关已经被使用，请前往消息路由配置页面查看",
  "ccmessaging.chat.channelconfig.startTime": "起始时间",
  "ccmessaging.chat.channelconfig.sub": "将下面一段代码复制并粘贴到您的网站标签之前，保存并发布，即可完成Huawei ServiceCloud的快速接入，发布后您的网站右下角将会出现Huawei Service Cloud入口，点击可以与客服工作台进行实时对话。",
  "ccmessaging.chat.channelconfig.submit": "提交",
  "ccmessaging.chat.channelconfig.submitVerification": "集成说明",
  "ccmessaging.chat.channelconfig.successfulAccess": "接入成功",
  "ccmessaging.chat.channelconfig.thirdPartySecretKey": "第三方认证密钥",
  "ccmessaging.chat.channelconfig.time": "时间范围（分钟）",
  "ccmessaging.chat.channelconfig.timeoutReply": "超时回复语",
  "ccmessaging.chat.channelconfig.timerange": "时间范围",
  "ccmessaging.chat.channelconfig.timeZone": "时区",
  "ccmessaging.chat.channelconfig.today": "当天",
  "ccmessaging.chat.channelconfig.triggerText": "离线消息提示语",
  "ccmessaging.chat.channelconfig.trustCertificate": "信任证书",
  "ccmessaging.chat.channelconfig.trybutton": "试一试",
  "ccmessaging.chat.channelconfig.turnAI": "接入智能机器人",
  "ccmessaging.chat.channelconfig.turnArtificial": "转人工关键字",
  "ccmessaging.chat.channelconfig.turnArtificial.tip": "用中文或英文分号分隔",
  "ccmessaging.chat.channelconfig.turnAssistant": "接入机器人助手",
  "ccmessaging.chat.channelconfig.turnManualPrompt": "转人工提示语",
  "ccmessaging.chat.channelconfig.twitterBackground": "推特后台",
  "ccmessaging.chat.channelconfig.twittertips": "已为你获取以下网址进行接入认证，请点击提交按钮进行推特认证",
  "ccmessaging.chat.channelconfig.update": "修改",
  "ccmessaging.chat.channelconfig.updatecontactfailed": "更新接触失败！",
  "ccmessaging.chat.channelconfig.voiceAccess": "被叫IVR",
  "ccmessaging.chat.channelconfig.voiceAgentSkill": "人工座席技能队列",
  "ccmessaging.chat.channelconfig.webhookConfirmPassword": "Webhook确认密码",
  "ccmessaging.chat.channelconfig.webHookException": "获取webhook密码有效时间失败",
  "ccmessaging.chat.channelconfig.webhookPassword": "Webhook密码",
  "ccmessaging.chat.channelconfig.webhookPassword.newPassword": "Webhook新密码",
  "ccmessaging.chat.channelconfig.webhookPassword.noValidPassword": "无可用密码！",
  "ccmessaging.chat.channelconfig.webhookPassword.oldPassword": "Webhook锁定期密码",
  "ccmessaging.chat.channelconfig.webhookPassword.validPeriod": "有效期至：",
  "ccmessaging.chat.channelconfig.webhookPassword.validPeriod.fifthDayTip": "有效期小于15天",
  "ccmessaging.chat.channelconfig.webhookPassword.validPeriod.oneDayTip": "有效期不足一天，请修改!",
  "ccmessaging.chat.channelconfig.wechat.encryption.tip": "请注意，关闭消息加密，可能存在安全风险。注意与微信公众平台配置保持一致。",
  "ccmessaging.chat.channelconfig.wechat.qr.label": "扫码授权",
  "ccmessaging.chat.channelconfig.wechat.qr.label2": "重新授权",
  "ccmessaging.chat.channelconfig.wechat.qr.label3": "授权成功",
  "ccmessaging.chat.channelconfig.weChatBackground": "微信后台",
  "ccmessaging.chat.channelconfig.weChatMessageEncryptionSwitch": "微信消息加密开关",
  "ccmessaging.chat.channelconfig.welcomeMessage": "欢迎语",
  "ccmessaging.chat.channelconfig.whatsAppBackground": "WhatsApp后台",
  "ccmessaging.chat.channelconfig.workTimeDay": "工作日",
  "ccmessaging.chat.channelconfig.worktimeerror": "请输入正常的时间段！",
  "ccmessaging.chat.chatcard.audio": "语音",
  "ccmessaging.chat.chatcard.button.addtabs": "新增卡片选项",
  "ccmessaging.chat.chatcard.cancel": "取消",
  "ccmessaging.chat.chatcard.cardId": "模板id",
  "ccmessaging.chat.chatcard.cardName": "名称",
  "ccmessaging.chat.chatcard.cardNamePlaceholder": "请输入卡片模板名称",
  "ccmessaging.chat.chatcard.cardTitle": "标题",
  "ccmessaging.chat.chatcard.cardTitlePlaceholder": "请输入卡片标题",
  "ccmessaging.chat.chatcard.create": "保存",
  "ccmessaging.chat.chatcard.delete": "删除",
  "ccmessaging.chat.chatcard.deleteFail": "删除失败",
  "ccmessaging.chat.chatcard.deleteLimit": "此模板已被引用，不允许删除",
  "ccmessaging.chat.chatcard.deleteSuccess": "删除成功",
  "ccmessaging.chat.chatcard.deleteTips": "参数校验失败",
  "ccmessaging.chat.chatcard.deleteWhatsAppTemplate.Fail": "删除失败，该模板被模板消息引用。",
  "ccmessaging.chat.chatcard.description": "描述",
  "ccmessaging.chat.chatcard.descriptionPlaceholder": "请输入卡片描述信息",
  "ccmessaging.chat.chatcard.details": "卡片详情",
  "ccmessaging.chat.chatcard.dial": "拨号",
  "ccmessaging.chat.chatcard.down": "下移",
  "ccmessaging.chat.chatcard.edit": "编辑",
  "ccmessaging.chat.chatcard.fail": "失败",
  "ccmessaging.chat.chatcard.failTip": "新增失败，新增选项条数已至最大",
  "ccmessaging.chat.chatcard.folded": "收起",
  "ccmessaging.chat.chatcard.id": "请输入模板id",
  "ccmessaging.chat.chatcard.image": " 图片",
  "ccmessaging.chat.chatcard.input": "请输入模板名称",
  "ccmessaging.chat.chatcard.invalidUrl": "链接地址不是以http://,https://开头",
  "ccmessaging.chat.chatcard.linking": "链接",
  "ccmessaging.chat.chatcard.mediaFileType": "媒体文件类型",
  "ccmessaging.chat.chatcard.mediaHeight": "媒体高度",
  "ccmessaging.chat.chatcard.mediaType": "媒体类型",
  "ccmessaging.chat.chatcard.mediumHeight": " 中等高度",
  "ccmessaging.chat.chatcard.messageTemplateCreate": "创建卡片模板",
  "ccmessaging.chat.chatcard.messageTemplateDelete": "删除模板",
  "ccmessaging.chat.chatcard.messageTemplateModify": "修改模板",
  "ccmessaging.chat.chatcard.multiMediaId": "多媒体资源",
  "ccmessaging.chat.chatcard.multimediaid": "多媒体数据ID",
  "ccmessaging.chat.chatcard.multimedianame": "多媒体文件名",
  "ccmessaging.chat.chatcard.name": "请输入名称",
  "ccmessaging.chat.chatcard.NoneTitle": "无标题",
  "ccmessaging.chat.chatcard.operate": "操作",
  "ccmessaging.chat.chatcard.queryTemplateFail": "查询模板失败",
  "ccmessaging.chat.chatcard.replay": "回复",
  "ccmessaging.chat.chatcard.saveFail": "保存失败",
  "ccmessaging.chat.chatcard.saveSuccess": "保存成功",
  "ccmessaging.chat.chatcard.selectMultiMediaId": "选择多媒体资源",
  "ccmessaging.chat.chatcard.selectMultiMediaTips": "请选择多媒体资源",
  "ccmessaging.chat.chatcard.closeDialog": "是否确认关闭该对话框？",
  "ccmessaging.chat.chatcard.shortHeight": " 较低高度",
  "ccmessaging.chat.chatcard.strcontentTip": " http请求有安全风险，请悉知",
  "ccmessaging.chat.chatcard.success": "成功",
  "ccmessaging.chat.chatcard.tabs": "卡片选项",
  "ccmessaging.chat.chatcard.tabs.displaytext": "卡片展示文字",
  "ccmessaging.chat.chatcard.tabs.selectcontent": "选项对应的内容",
  "ccmessaging.chat.chatcard.tabs.selectType": "选项类型",
  "ccmessaging.chat.chatcard.tabsname": "卡片选项名称",
  "ccmessaging.chat.chatcard.tallHeight": " 较高高度",
  "ccmessaging.chat.chatcard.up": "上移",
  "ccmessaging.chat.chatcard.video": "视频",
  "ccmessaging.chat.chatcard.viewTips": "该卡片模板已经被删除，不支持预览",
  "ccmessaging.chat.chatcard.whatsappTemplateCreate": "WhatsApp模板创建",
  "ccmessaging.chat.chatcard.willdeletechatcard": "是否删除此条卡片",
  "ccmessaging.chat.chatcard.willdeletechatTemplate": "是否确定要删除此WhatsApp模板?",
  "ccmessaging.chat.chatcard.nameExist": "名称已存在，请修改",
  "ccmessaging.chat.chatconfig.add": "新增",
  "ccmessaging.chat.chatconfig.channelAccessCode": "渠道接入编码",
  "ccmessaging.chat.chatconfig.channelOccupied": "渠道已被占用!",
  "ccmessaging.chat.chatconfig.channelType": "渠道类型",
  "ccmessaging.chat.chatconfig.configurationId": "配置ID",
  "ccmessaging.chat.chatconfig.delete": "删除",
  "ccmessaging.chat.chatconfig.deletechannelAccessCode": "删除渠道配置",
  "ccmessaging.chat.chatconfig.deletefail": "删除失败",
  "ccmessaging.chat.chatconfig.deletesuccess": "删除成功",
  "ccmessaging.chat.chatconfig.idisnull": "ID信息为空！",
  "ccmessaging.chat.chatconfig.inquire": "查询",
  "ccmessaging.chat.chatconfig.modify": "修改",
  "ccmessaging.chat.chatconfig.operate": "操作",
  "ccmessaging.chat.chatconfig.refresh": "刷新",
  "ccmessaging.chat.chatconfig.reset": "重置",
  "ccmessaging.chat.chatconfig.rollBack": "撤回",
  "ccmessaging.chat.chatconfig.selectchannelAccessCode": "请选择渠道配置！",
  "ccmessaging.chat.chatconfig.skillId": "绑定技能队列",
  "ccmessaging.chat.chatconfig.willdeletechannelAccessCode": "确定删除所选的渠道配置吗？",
  "ccmessaging.chat.chatemail.cancelhandled": "取消已处理",
  "ccmessaging.chat.chatemail.delete": "删除",
  "ccmessaging.chat.chatemail.details": "查看",
  "ccmessaging.chat.chatemail.forwarded": "已转发",
  "ccmessaging.chat.chatemail.forwardedTime": "转发该邮件的时间为",
  "ccmessaging.chat.chatemail.handled": "已处理",
  "ccmessaging.chat.chatemail.maxNumberImages": "上传本地图片已达20张上限",
  "ccmessaging.chat.chatemail.saveDraft": " 保存草稿",
  "ccmessaging.chat.chatemail.sender": "发件人：",
  "ccmessaging.chat.chatholiday.calendar.preview": "日历预览",
  "ccmessaging.chat.chatholiday.checkHolidaySize": "已有条目过多，请先删除多余条目再新增",
  "ccmessaging.chat.chatholiday.date": "日期",
  "ccmessaging.chat.chatholiday.dateConfiguration": "日期配置",
  "ccmessaging.chat.chatholiday.delete": "删除",
  "ccmessaging.chat.chatholiday.description": "描述",
  "ccmessaging.chat.chatholiday.designation.alert": "结束日期必须在开始日期之后",
  "ccmessaging.chat.chatholiday.designation.cancel": "取消",
  "ccmessaging.chat.chatholiday.designation.complete": "完成",
  "ccmessaging.chat.chatholiday.designation.days": "多天",
  "ccmessaging.chat.chatholiday.designation.end": "结束日期",
  "ccmessaging.chat.chatholiday.designation.norepeating": "不重复",
  "ccmessaging.chat.chatholiday.designation.oneday": "一天",
  "ccmessaging.chat.chatholiday.designation.repeating": "每年重复",
  "ccmessaging.chat.chatholiday.designation.selectrule": "所选日期不能与已选指定日期重合",
  "ccmessaging.chat.chatholiday.designation.show": "每年",
  "ccmessaging.chat.chatholiday.designation.start": "开始日期",
  "ccmessaging.chat.chatholiday.designation.type": "日期类型",
  "ccmessaging.chat.chatholiday.friday": "每周五",
  "ccmessaging.chat.chatholiday.holidays.explanation": "您可以指定某几天为非工作日，例：指定2019年9月13日（周五、中秋节）为非工作日。",
  "ccmessaging.chat.chatholiday.isDelete.otherdays": "是否确定要删除此条例外日期？",
  "ccmessaging.chat.chatholiday.isDelete.specified": "是否确定要删除此条指定日期？",
  "ccmessaging.chat.chatholiday.monday": "每周一",
  "ccmessaging.chat.chatholiday.new": "新增",
  "ccmessaging.chat.chatholiday.nonWorkbench": "非工作日配置",
  "ccmessaging.chat.chatholiday.noworking": "非工作日",
  "ccmessaging.chat.chatholiday.operate": "操作",
  "ccmessaging.chat.chatholiday.other.prompt": "所选日期与已有日期重复",
  "ccmessaging.chat.chatholiday.otherdays.exception": "例外日期",
  "ccmessaging.chat.chatholiday.otherdays.explanation": "您可以选择某几天为工作日，最长可选一年内日期，例：2019年7月13日（周六、临时加班）为工作日。",
  "ccmessaging.chat.chatholiday.repeat": "重复规则",
  "ccmessaging.chat.chatholiday.saturday": "每周六",
  "ccmessaging.chat.chatholiday.savechatholidayfailed": "保存节假日配置失败",
  "ccmessaging.chat.chatholiday.specified.date": "指定日期",
  "ccmessaging.chat.chatholiday.sunday": "每周日",
  "ccmessaging.chat.chatholiday.thursday": "每周四",
  "ccmessaging.chat.chatholiday.tuesday": "每周二",
  "ccmessaging.chat.chatholiday.wednesday": "每周三",
  "ccmessaging.chat.chatholiday.weekend.explanation": "-您可以选择每周的某几天为非工作日，例：每周六、日为非工作日。",
  "ccmessaging.chat.chatholiday.weekly": "每周",
  "ccmessaging.chat.chatholiday.workbench": "工作台配置",
  "ccmessaging.chat.chatholiday.working.day": "工作日",
  "ccmessaging.chat.chatholiday.tip.choosedate": "请选择日期",
  "ccmessaging.chat.chatholiday.tip.descinvalid": "描述不能超过20个字符",
  "ccmessaging.chat.chatmanage.agentIntervalTime": "座席回复超时时长",
  "ccmessaging.chat.chatmanage.agentSessionEndText": "座席重配提示语",
  "ccmessaging.chat.chatmanage.autoEndSession": "访客超时未回复自动结束会话",
  "ccmessaging.chat.chatmanage.autoEndSessionMorethan": "客户未回复时间间隔描述不得超过100字",
  "ccmessaging.chat.chatmanage.autoEndSessionnotnull": "客户未回复时间间隔描述不得为空",
  "ccmessaging.chat.chatmanage.autoEndSessionNum": "客户未回复提醒间隔必须是纯数字",
  "ccmessaging.chat.chatmanage.autoEndSessionRange": "客户未回复提醒间隔数字范围必须在0到60之间",
  "ccmessaging.chat.chatmanage.cancel": "取消",
  "ccmessaging.chat.chatmanage.edit": "编辑",
  "ccmessaging.chat.chatmanage.endTime": "结束时间",
  "ccmessaging.chat.chatmanage.endTimeNum": "结束时间必须是纯数字",
  "ccmessaging.chat.chatmanage.endTimeRange": "结束时间数字范围必须在0到24之间",
  "ccmessaging.chat.chatmanage.fail": "失败",
  "ccmessaging.chat.chatmanage.freeTimeText": "非工作时间提醒信息",
  "ccmessaging.chat.chatmanage.freeTimeTextMorethan": "非工作时间提醒信息描述不得超过100字",
  "ccmessaging.chat.chatmanage.freeTimeTextnotnull": "非工作时间提醒信息描述不得为空",
  "ccmessaging.chat.chatmanage.intervalTime": "客户回复超时时长",
  "ccmessaging.chat.chatmanage.save": "保存",
  "ccmessaging.chat.chatmanage.savefailed": "保存失败",
  "ccmessaging.chat.chatmanage.savesuccess": "保存成功",
  "ccmessaging.chat.chatmanage.sessionEndText": "会话结束语",
  "ccmessaging.chat.chatmanage.sessionEndTextMorethan": "会话结束语描述不得超过100字",
  "ccmessaging.chat.chatmanage.sessionEndTextnotnull": "会话结束语描述不得为空",
  "ccmessaging.chat.chatmanage.specialCharacters": "含有特殊字符，请修改",
  "ccmessaging.chat.chatmanage.startTime": "开始时间",
  "ccmessaging.chat.chatmanage.startTimeNum": "开始时间必须是纯数字",
  "ccmessaging.chat.chatmanage.startTimeRange": "开始时间数字范围必须在0到24之间",
  "ccmessaging.chat.chatmanage.success": "成功",
  "ccmessaging.chat.chatmanage.useRobot": "是否接入智能机器人",
  "ccmessaging.chat.chatmanage.workTime": "座席工作时间(24小时制)",
  "ccmessaging.chat.chatmanage.workTimeMorethan": "工作时间描述不得超过100字",
  "ccmessaging.chat.chatpersonalization.add": "新增",
  "ccmessaging.chat.chatpersonalization.avatarerror": "请选择一张图片！",
  "ccmessaging.chat.chatpersonalization.cancel": "取消",
  "ccmessaging.chat.chatpersonalization.changeAvatar": "更换头像",
  "ccmessaging.chat.chatpersonalization.concurrentAgentsNumber": "座席并发服务多媒体客户数",
  "ccmessaging.chat.chatpersonalization.concurrentNumberPriority": "座席并发服务多媒体客户数配置生效优先级:座席级 > 组织级 > 租户级，配置值为0表示此级配置不生效，将使用其他级配置",
  "ccmessaging.chat.chatpersonalization.delete": "删除",
  "ccmessaging.chat.chatpersonalization.edit": "编辑",
  "ccmessaging.chat.chatpersonalization.hour": "时",
  "ccmessaging.chat.chatpersonalization.MaximumConcurrent": "并发最大服务客户数",
  "ccmessaging.chat.chatpersonalization.nickname": "昵称",
  "ccmessaging.chat.chatpersonalization.operating": "操作",
  "ccmessaging.chat.chatpersonalization.prompt": "提示",
  "ccmessaging.chat.chatpersonalization.save": "保存",
  "ccmessaging.chat.chatpersonalization.savefailed": "保存失败",
  "ccmessaging.chat.chatpersonalization.saveSuccess": "保存成功",
  "ccmessaging.chat.chatpersonalization.specialStrValidateFailed": "不允许包含特殊字符",
  "ccmessaging.chat.chatpersonalization.tips1": "提交的配置没有验证通过！",
  "ccmessaging.chat.chatpersonalization.welcome": "欢迎语",
  "ccmessaging.chat.chatpersonalization.welcomeLimit": "个性欢迎语最多能配置4个",
  "ccmessaging.chat.chatpersonalization.welcomeText": "个性化欢迎语",
  "ccmessaging.chat.chatpersonalization.welcometime": "时间段",
  "ccmessaging.chat.chatpersonalization.welcometimeerror": "请输入正常的时间段！",
  "ccmessaging.chat.chatpersonalization.welcometimeerror.nolist": "请至少设置一条个性化欢迎语！",
  "ccmessaging.chat.chatpersonalization.welcometimeerror.startGreaterThanEnd": "个性化欢迎语的开始时间不能大于或等于结束时间！",
  "ccmessaging.chat.chatpersonalization.welcometimeerror.timeOverlap": "个性化欢迎语的时间段不能重叠！",
  "ccmessaging.chat.chinese.separator": "：",
  "ccmessaging.chat.clickCallClient.denial": "链接已失效，如有需要请重新联系座席发送链接",
  "ccmessaging.chat.close": "秒后自动关闭",
  "ccmessaging.chat.connnect.offlineuser.failed": "座席发起离线通话失败",
  "ccmessaging.chat.connnect.offlineuser.userId.beyondMaxLength": "用户Id超过128位",
  "ccmessaging.chat.contact.callin": "呼入",
  "ccmessaging.chat.contact.callinandcallout": "呼入和呼出",
  "ccmessaging.chat.contact.callout": "呼出",
  "ccmessaging.chat.email.AgentAccountName": "座席帐号名",
  "ccmessaging.chat.email.agentNumber": "座席工号",
  "ccmessaging.chat.email.autosave": "您编辑的内容已自动保存至草稿",
  "ccmessaging.chat.email.button.advancedSearch": "高级搜索",
  "ccmessaging.chat.email.button.batchAssignment": "批量指派",
  "ccmessaging.chat.email.button.bulkMove": "批量移动",
  "ccmessaging.chat.email.card.reply": "回复",
  "ccmessaging.chat.email.channel.attendeeError": "抄送人数量超出限制",
  "ccmessaging.chat.email.channel.BccSenderError": "密送人数量超出限制",
  "ccmessaging.chat.email.channel.ccandbcc.containchannelemail": "抄送人、密送人不能为渠道的公共邮箱地址",
  "ccmessaging.chat.email.channel.confdeletedraft": "是否确认删除？",
  "ccmessaging.chat.email.channel.confdmodifydraft": "是否确认已处理邮件？",
  "ccmessaging.chat.email.channel.deletedraft": "删除确认",
  "ccmessaging.chat.email.channel.deletedraftfail": "删除邮件草稿失败",
  "ccmessaging.chat.email.channel.deletedraftsuccess": "删除邮件草稿成功",
  "ccmessaging.chat.email.channel.draftOverMax": "邮件草稿数量超过最大限制！",
  "ccmessaging.chat.email.channel.emailaddressinvalid": "邮件地址不合法",
  "ccmessaging.chat.email.channel.modifydraft": "修改确认",
  "ccmessaging.chat.email.channel.modifydraftfail": "修改失败",
  "ccmessaging.chat.email.channel.modifydraftsuccess": "修改成功",
  "ccmessaging.chat.email.channel.paramisempty": "参数为空",
  "ccmessaging.chat.email.channel.paramiserror": "参数错误",
  "ccmessaging.chat.email.channel.receiveGateway": "接收邮件网关",
  "ccmessaging.chat.email.channel.receivererror": "收件人不包含选中的用户",
  "ccmessaging.chat.email.channel.savedraftfail": "保存邮件草稿失败",
  "ccmessaging.chat.email.channel.savedraftsuccess": "保存草稿成功",
  "ccmessaging.chat.email.channel.sendGateway": "发送邮件网关",
  "ccmessaging.chat.email.channel.title.reply": "答复：",
  "ccmessaging.chat.email.check.containGatewayEmail": "不允许发送邮件给渠道接收邮件地址。",
  "ccmessaging.chat.email.check.contentOverMax": "邮件正文超过限制大小",
  "ccmessaging.chat.email.check.localImageNumberOverMax": "邮件正文上传本地图片数量超过范围",
  "ccmessaging.chat.email.check.localImageOverMax": "邮件正文上传本地图片大小超过范围",
  "ccmessaging.chat.email.check.localImageType": "暂不支持上传的图片格式",
  "ccmessaging.chat.email.check.uploadATTACHMENTPermissionAndFeature": "上传附件没有权限或开关未开启",
  "ccmessaging.chat.email.check.uploadImagePermissionAndFeature": "上传本地图片没有权限或开关未开启",
  "ccmessaging.chat.email.forward": "转发",
  "ccmessaging.chat.email.forward.prefix": "转发：",
  "ccmessaging.chat.email.localImage.confirm": "确定",
  "ccmessaging.chat.email.localImage.height": "高",
  "ccmessaging.chat.email.localImage.lable": "文件",
  "ccmessaging.chat.email.localImage.width": "宽",
  "ccmessaging.chat.email.param.empty": "邮件标题和内容不能为空！",
  "ccmessaging.chat.email.reply": "答复",
  "ccmessaging.chat.email.reply.separator": "：",
  "ccmessaging.chat.email.tips.addFailDuplicateName": "新增失败：名称重复",
  "ccmessaging.chat.email.tips.addFailOtherErrors": "新增失败：其它错误",
  "ccmessaging.chat.email.tips.addMaximumLimit": "同层子目录最多20个",
  "ccmessaging.chat.email.tips.addSuccess": "新增成功",
  "ccmessaging.chat.email.tips.amendFailDuplicateName": "修改失败：名称重复",
  "ccmessaging.chat.email.tips.amendFailOtherErrors": "修改失败：其它错误",
  "ccmessaging.chat.email.tips.amendSuccess": "修改成功",
  "ccmessaging.chat.email.tips.deleteFailExistEmail": "删除失败：当前目录存在邮件",
  "ccmessaging.chat.email.tips.deleteFailExistSub": "删除失败：当前目录存在子目录",
  "ccmessaging.chat.email.tips.deleteFailOtherErrors": "删除失败：其它错误",
  "ccmessaging.chat.email.tips.deleteSuccess": "删除成功",
  "ccmessaging.chat.email.tips.isDeleteDirectory": "是否删除当前目录？",
  "ccmessaging.chat.emailmessage.abandoned": "已丢弃",
  "ccmessaging.chat.emailmessage.agent": "座席",
  "ccmessaging.chat.emailmessage.all": "所有",
  "ccmessaging.chat.emailmessage.assigned": "已分配",
  "ccmessaging.chat.emailmessage.assignEmail.inputAgentName": "输入座席帐号名称",
  "ccmessaging.chat.emailmessage.assignEmail.queryAgentName": "请输入业务帐号",
  "ccmessaging.chat.emailmessage.assignEmailFailed": "指派邮件失败",
  "ccmessaging.chat.emailmessage.assignEmailPartialSuccess": "部分邮件指派失败",
  "ccmessaging.chat.emailmessage.assignEmailSuccess": "指派邮件成功",
  "ccmessaging.chat.emailmessage.assigning": "指派",
  "ccmessaging.chat.emailmessage.assignOtherEmailFailed": "发件人的邮件正在处理，暂时不能指派",
  "ccmessaging.chat.emailmessage.assignStatus": "分配状态",
  "ccmessaging.chat.emailmessage.channel": "渠道自动回复",
  "ccmessaging.chat.emailmessage.channelAccessCode": "邮件渠道接入编码",
  "ccmessaging.chat.emailmessage.disAssigned": "不分配",
  "ccmessaging.chat.emailmessage.fail.assign.AbandonedEmail.assigning.tip": "已丢弃的邮件不可指派！",
  "ccmessaging.chat.emailmessage.fail.assign.AbandonedEmail.moving.tip": "已丢弃的邮件不能移动！",
  "ccmessaging.chat.emailmessage.fail.assign.UnAssignedEmail.tip": "不分配的邮件不可指派！",
  "ccmessaging.chat.emailmessage.handler": "处理人",
  "ccmessaging.chat.emailmessage.handlerType": "处理人类型",
  "ccmessaging.chat.emailmessage.handleStatus": "处理状态",
  "ccmessaging.chat.emailmessage.moving": "移动",
  "ccmessaging.chat.emailmessage.operation.noMoreThanOneDay": "查询时间范围不能超过1天",
  "ccmessaging.chat.emailmessage.operation.noMoreThanSevenDay": "查询时间范围不能超过7天",
  "ccmessaging.chat.emailmessage.receiver": "收件人",
  "ccmessaging.chat.emailmessage.robot": "机器人",
  "ccmessaging.chat.emailmessage.sender": "发件人",
  "ccmessaging.chat.emailmessage.sendStatus": "发送结果",
  "ccmessaging.chat.emailmessage.sendTime": "发送时间",
  "ccmessaging.chat.emailmessage.skillQueue": "技能队列",
  "ccmessaging.chat.emailmessage.title": "邮件标题",
  "ccmessaging.chat.emailmessage.unassigned": "未分配",
  "ccmessaging.chat.EMOTICONS.beyondFileSize": "上传多媒体文件格式错误或超出最大限制：{n}KB!",
  "ccmessaging.chat.facebookpageconfig.add": "新建Facebook主页",
  "ccmessaging.chat.facebookpageconfig.config": "配置",
  "ccmessaging.chat.facebookpageconfig.deleteError": "删除失败",
  "ccmessaging.chat.facebookpageconfig.deleteSuccess": "删除成功",
  "ccmessaging.chat.facebookpageconfig.facebookconfig": "Facebook主页配置",
  "ccmessaging.chat.facebookpageconfig.idNotExist": "删除的数据为空",
  "ccmessaging.chat.facebookpageconfig.isDeleteFacebookPage": "是否确认删除Facebook主页配置？",
  "ccmessaging.chat.facebookpageconfig.modify": "编辑Facebook主页",
  "ccmessaging.chat.facebookpageconfig.modifyError": "修改Facebook主页失败",
  "ccmessaging.chat.facebookpageconfig.modifySuccess": "修改Facebook主页成功",
  "ccmessaging.chat.facebookpageconfig.numoversize": "该渠道的Facebook主页数量超出限制",
  "ccmessaging.chat.facebookpageconfig.pageId": "主页ID",
  "ccmessaging.chat.facebookpageconfig.pageidexist": "该Facebook主页ID已存在",
  "ccmessaging.chat.facebookpageconfig.saveError": "新建Facebook主页失败",
  "ccmessaging.chat.facebookpageconfig.saveSuccess": "新建Facebook主页成功",
  "ccmessaging.chat.facebookpageconfig.tokenSecret": "令牌密钥",
  "ccmessaging.chat.fivegCard": "5G卡片",
  "ccmessaging.chat.ifame.param": "参数",
  "ccmessaging.chat.iframeagentconsole.mail": "邮件",
  "ccmessaging.chat.iframeagentconsole.mailCollapse": "收起",
  "ccmessaging.chat.iframeagentconsole.mailExpand": "详情",
  "ccmessaging.chat.label.accessCode": "接入码",
  "ccmessaging.chat.label.extCode": "扩展码",
  "ccmessaging.chat.label.skill": "技能队列",
  "ccmessaging.chat.localFile.authFail": "没有本地文件发送权限",
  "ccmessaging.chat.multi.card": "5G轮播卡片",
  "ccmessaging.chat.multi.card.add": "选择卡片",
  "ccmessaging.chat.multi.card.id": "卡片id",
  "ccmessaging.chat.multi.card.num.error": "请添加1-5张单卡片",
  "ccmessaging.chat.multi.chatcard.alignment": "图片对齐方式",
  "ccmessaging.chat.multi.chatcard.alignment.left": "左对齐",
  "ccmessaging.chat.multi.chatcard.alignment.right": "右对齐",
  "ccmessaging.chat.multi.chatcard.choose": "卡片选择",
  "ccmessaging.chat.multi.chatcard.delete": "此模板已被引用，不允许删除，引用的轮播卡片为:",
  "ccmessaging.chat.multi.chatcard.sort": "卡片排列方向",
  "ccmessaging.chat.multi.chatcard.sort.flow": "浮动",
  "ccmessaging.chat.multi.chatcard.sort.horizontal": "水平",
  "ccmessaging.chat.multi.chatcard.sort.vertical": "垂直",
  "ccmessaging.chat.multi.chatcard.width": "卡片宽度",
  "ccmessaging.chat.multi.chatcard.width.medium": "中等宽度",
  "ccmessaging.chat.multi.chatcard.width.small": "较小宽度",
  "ccmessaging.chat.multi.media.resource.invalid": "卡片引用失效的媒体资源，卡片名称为：",
  "ccmessaging.chat.multimedia.addCategory": "新增类别",
  "ccmessaging.chat.multimedia.audioManagement": "语音库",
  "ccmessaging.chat.multimedia.audioUploadTipFormat": "上传的语音文件目前仅支持mp3、aac、m4a格式，文件大小不得超过{n}M。",
  "ccmessaging.chat.multimedia.beyondFileSize": "上传多媒体文件大小超出最大限制：{n}MB！",
  "ccmessaging.chat.multimedia.cancel": "取消",
  "ccmessaging.chat.multimedia.cardManagement": "卡片模板",
  "ccmessaging.chat.multimedia.categoryName": "分类名称",
  "ccmessaging.chat.multimedia.chatPhraseManagement": "常用语库",
  "ccmessaging.chat.multimedia.cobrowseSite": "网页协同地址",
  "ccmessaging.chat.multimedia.createRichText": "创建富文本",
  "ccmessaging.chat.multimedia.delete": "删除",
  "ccmessaging.chat.multimedia.deleteLimit": "此资源已被卡片模板{0}引用，不允许删除",
  "ccmessaging.chat.multimedia.deleteLimit.usedbyODFS": "此资源已被智能IVR引用，不允许删除",
  "ccmessaging.chat.multimedia.deleteLimit.whatsapp.templatemsg": "此资源已被WhatsApp模板消息引用，不允许删除。模板消息：",
  "ccmessaging.chat.multimedia.deleteMultiMediaInfo": "删除多媒体数据",
  "ccmessaging.chat.multimedia.deleteMultiMediaInfoError": "删除多媒体数据失败！",
  "ccmessaging.chat.multimedia.deleteMultiMediaInfoSuccess": "删除多媒体数据成功！",
  "ccmessaging.chat.multimedia.deleteMultiMediaType": "删除多媒体库类别",
  "ccmessaging.chat.multimedia.deleteMultiMediaTypeError": "删除多媒体库类别失败！",
  "ccmessaging.chat.multimedia.deleteMultiMediaTypeError.usedbyODFS": "删除多媒体库类别失败！存在被智能IVR引用的资源！",
  "ccmessaging.chat.multimedia.deleteMultiMediaTypeError.referenced.byCard": "删除多媒体库类别失败！存在被卡片引用的资源！",
  "ccmessaging.chat.multimedia.deleteMultiMediaTypeError.referenced.byWhatsAppTemplateMsg": "删除多媒体库类别失败！存在被WhatsApp模板消息引用的资源！",
  "ccmessaging.chat.multimedia.deleteMultiMediaTypeSuccess": "删除多媒体库类别成功！",
  "ccmessaging.chat.multimedia.description": "描述",
  "ccmessaging.chat.multimedia.descriptionContainsSpecialCharacters": "描述存在特殊字符",
  "ccmessaging.chat.multimedia.descriptionSuperLong": "描述超过最大长度",
  "ccmessaging.chat.multimedia.documentManagement": "文档库",
  "ccmessaging.chat.multimedia.documentUploadTipFormat": "上传文档支持word，pdf，ppt，excel，zip。zip内支持word，pdf，ppt，excel格式，文件大小不超过{n}MB。",
  "ccmessaging.chat.multimedia.download": "下载",
  "ccmessaging.chat.multimedia.edit": "编辑",
  "ccmessaging.chat.multimedia.emoticonsManagement": "个性化表情",
  "ccmessaging.chat.multimedia.emoticonsUploadTipFormat": "上传的个性化表情文件目前支持jpg，jpeg，png，gif格式，文件大小不得超过{n}KB。",
  "ccmessaging.chat.multimedia.exceedFileSize": "上传多媒体文件格式错误或超出最大限制：{n}！",
  "ccmessaging.chat.multimedia.fileName": "文件名称",
  "ccmessaging.chat.multimedia.fileSize": "文件大小",
  "ccmessaging.chat.multiMedia.fileUploadError": "上传的文件格式错误",
  "ccmessaging.chat.multiMedia.fileUploadInjectionRisk": "本次上传存在注入风险，请检查内容后重试",
  "ccmessaging.chat.multimedia.fileUploadLabel": "请选择需要上传的文件",
  "ccmessaging.chat.multiMedia.fileUploadNameError": "上传的文件命名格式错误",
  "ccmessaging.chat.multiMedia.fileUploadTypeError": "上传的文件类型格式错误",
  "ccmessaging.chat.multimedia.finish": "完成",
  "ccmessaging.chat.multimedia.getRichTextInfoError": "获取富文本数据失败",
  "ccmessaging.chat.multimedia.id": "ID",
  "ccmessaging.chat.multimedia.imageManagement": "图片库",
  "ccmessaging.chat.multimedia.imageUploadTipFormat": "上传的图片文件目前仅支持jpg，png，jpeg格式，文件大小不得超过{n}M。",
  "ccmessaging.chat.multimedia.isDeleteMultiMediaInfo": "是否确定要删除此多媒体数据？",
  "ccmessaging.chat.multimedia.isDeleteMultiMediaType": "是否确定要删除此多媒体类别及下面的多媒体数据？",
  "ccmessaging.chat.multimedia.latitude": "纬度",
  "ccmessaging.chat.multimedia.latitudeValidateFailed": "纬度整数部分为-90~90，小数部分为0到6位！",
  "ccmessaging.chat.multimedia.locationManagement": "地址库",
  "ccmessaging.chat.multimedia.longitude": "经度",
  "ccmessaging.chat.multimedia.longitudeValidateFailed": "经度整数部分为-180~180，小数部分为0到6位！",
  "ccmessaging.chat.multimedia.mediaFileType": "多媒体文件类型",
  "ccmessaging.chat.multimedia.modifyCategory": "修改类别",
  "ccmessaging.chat.multimedia.modifyMultiMediaInfo": "修改多媒体数据成功！",
  "ccmessaging.chat.multimedia.modifyMultiMediaInfoError": "修改多媒体数据失败！",
  "ccmessaging.chat.multimedia.modifyMultiMediaType": "修改多媒体库类别成功！",
  "ccmessaging.chat.multimedia.modifyMultiMediaTypeError": "修改多媒体库类别失败！",
  "ccmessaging.chat.multimedia.modifyRichText": "编辑富文本",
  "ccmessaging.chat.multimedia.multiMediaInfoInputEmpty": "输入的多媒体数据存在部分为空！",
  "ccmessaging.chat.multimedia.multiMediaInfoInputInvalid": "输入的多媒体数据格式错误！",
  "ccmessaging.chat.multimedia.multiMediaManagement": "多媒体库设置\u000d\u000a",
  "ccmessaging.chat.multimedia.multiMediaNameExist": "多媒体数据名称在该类别中已存在！",
  "ccmessaging.chat.multimedia.multiMediaTypeIdNotExist": "选择的多媒体库类别不存在，请选择多媒体库类别！",
  "ccmessaging.chat.multimedia.multiMediaTypeInputEmpty": "输入的多媒体库类别数据部分为空！",
  "ccmessaging.chat.multimedia.multiMediaTypeInputInvalid": "输入的多媒体库类别数据格式错误！",
  "ccmessaging.chat.multimedia.multiMediaTypeNameExist": "多媒体类别名称已存在！",
  "ccmessaging.chat.multimedia.name": "名称",
  "ccmessaging.chat.multimedia.nameContainsSpecialCharacters": "名称存在特殊字符",
  "ccmessaging.chat.multimedia.nameSuperLong": "名称超过最大长度",
  "ccmessaging.chat.multimedia.operating": "操作",
  "ccmessaging.chat.multimedia.play": "播放",
  "ccmessaging.chat.multimedia.preview": "预览",
  "ccmessaging.chat.multimedia.richTextContentEmpty": "富文本内容为空",
  "ccmessaging.chat.multimedia.richTextContentLongLimit": "富文本内容不超过最大长度5000",
  "ccmessaging.chat.multimedia.richTextContentSuperLong": "富文本实际内容超过最大长度5000",
  "ccmessaging.chat.multimedia.richTextManagement": "富文本",
  "ccmessaging.chat.multimedia.richTextTypeEmpty": "富文本分类不存在",
  "ccmessaging.chat.multimedia.categorizeEmpty": "请先选择分类或创建分类",
  "ccmessaging.chat.multimedia.saveMultiMediaInfo": "保存多媒体数据成功！",
  "ccmessaging.chat.multimedia.saveMultiMediaInfoError": "保存多媒体数据失败！",
  "ccmessaging.chat.multimedia.saveMultiMediaType": "保存多媒体类别成功！",
  "ccmessaging.chat.multimedia.saveMultiMediaTypeError": "保存多媒体库类别失败！",
  "ccmessaging.chat.multimedia.selectFile": "选择文件",
  "ccmessaging.chat.multimedia.selectImage": "选择图片",
  "ccmessaging.chat.multimedia.upload": "上传",
  "ccmessaging.chat.multimedia.uploadTime": "上传时间",
  "ccmessaging.chat.multimedia.videoManagement": "视频库",
  "ccmessaging.chat.multimedia.videoUploadTipFormat": "上传的视频文件目前仅支持mp4格式，文件大小不得超过{n}M。",
  "ccmessaging.chat.multimedia.viewRichText": "查看富文本",
  "ccmessaging.chat.multimedia.whatsAppTemplate": "WhatsApp模板",
  "ccmessaging.chat.multimediaasccociation.approvalstatus": "审核状态",
  "ccmessaging.chat.multimediaasccociation.checkmediainfofailed": "该多媒体数据没有未发布到对应的5G渠道或未审核通过！",
  "ccmessaging.chat.multimediaasccociation.deleteassociation": "删除发布结果",
  "ccmessaging.chat.multimediaasccociation.deleteassociationinfo": "是否确定要删除该条多媒体数据与对应5G渠道配置的发布结果？",
  "ccmessaging.chat.multimediaasccociation.deleteError": "删除失败！",
  "ccmessaging.chat.multimediaasccociation.deleteSuccess": "删除成功！",
  "ccmessaging.chat.multimediaasccociation.examinationfailed": "不通过",
  "ccmessaging.chat.multimediaasccociation.examinationpassed": "通过",
  "ccmessaging.chat.multimediaasccociation.expirationtime": "失效时间",
  "ccmessaging.chat.multimediaasccociation.isrelease": "是否确定发布多媒体数据到5G平台？",
  "ccmessaging.chat.multimediaasccociation.refresh": "重新发布",
  "ccmessaging.chat.multimediaasccociation.refreshfailed": "重新发布失败",
  "ccmessaging.chat.multimediaasccociation.refreshsuccess": "重新发布成功",
  "ccmessaging.chat.multimediaasccociation.release": "发布",
  "ccmessaging.chat.multimediaasccociation.releaseerror": "发布多媒体数据失败！",
  "ccmessaging.chat.multimediaasccociation.releaseresult": "发布结果",
  "ccmessaging.chat.multimediaasccociation.releasesuccess": "发布多媒体数据成功！",
  "ccmessaging.chat.multimediaasccociation.releasetitle": "发布多媒体数据",
  "ccmessaging.chat.multimediaasccociation.unpublishconfigempty": "该多媒体数据未发布的5G渠道配置为空",
  "ccmessaging.chat.multimediaasccociation.unreviewed": "未审核",
  "ccmessaging.chat.offlineCall.channelType": "渠道类型",
  "ccmessaging.chat.offlineCall.select.noChannel": "该渠道不存在！",
  "ccmessaging.chat.offlineCall.userId": "用户ID",
  "ccmessaging.chat.offlineSwitch.closed": "发送失败，渠道离线消息开关关闭",
  "ccmessaging.chat.phrase.add": "新增",
  "ccmessaging.chat.phrase.addPhraseError": "新建常用语失败",
  "ccmessaging.chat.phrase.addPhraseSuccess": "新建常用语成功",
  "ccmessaging.chat.phrase.addType": "新增类别",
  "ccmessaging.chat.phrase.AllCount": "总字数",
  "ccmessaging.chat.phrase.cancel": "取消",
  "ccmessaging.chat.phrase.currentCount": "当前字数",
  "ccmessaging.chat.phrase.delete": "删除",
  "ccmessaging.chat.phrase.deleteType": "删除类别",
  "ccmessaging.chat.phrase.delPhraseError": "删除常用语失败",
  "ccmessaging.chat.phrase.delPhraseSucess": "删除常用语成功",
  "ccmessaging.chat.phrase.delPhraseTypeError": "删除常用语类别失败",
  "ccmessaging.chat.phrase.delPhraseTypeSuccess": "删除常用语类别成功",
  "ccmessaging.chat.phrase.edit": "编辑",
  "ccmessaging.chat.phrase.editType": "编辑类别",
  "ccmessaging.chat.phrase.enter_keyword": "关键词",
  "ccmessaging.chat.phrase.enterPhrase": "请输入常用语",
  "ccmessaging.chat.phrase.enterPhraseType": "请输入常用语类别",
  "ccmessaging.chat.phrase.error": "失败",
  "ccmessaging.chat.phrase.isDeletePhrase": "是否确定要删除此常用语？",
  "ccmessaging.chat.phrase.isDeletePhraseType": "是否确定要删除此常用语类型及下面的常用语？",
  "ccmessaging.chat.phrase.isSavePhrase": "是否确定要保存此常用语？",
  "ccmessaging.chat.phrase.isSavePhraseType": "是否确定保存此常用语类别？",
  "ccmessaging.chat.phrase.phrase": "常用语",
  "ccmessaging.chat.phrase.phraseList": "常用语列表",
  "ccmessaging.chat.phrase.phraseRepeat": "常用语名称重复",
  "ccmessaging.chat.phrase.phraseType": "常用语类别",
  "ccmessaging.chat.phrase.phraseTypeEmpty": "删除的常用语类别不存在，请选择常用语类别",
  "ccmessaging.chat.phrase.phraseTypeRepeat": "常用语类别重复",
  "ccmessaging.chat.phrase.phraseTypeLimit": "常用语类别达到上限100",
  "ccmessaging.chat.phrase.prompt": "提示",
  "ccmessaging.chat.phrase.save": "保存",
  "ccmessaging.chat.phrase.saveError": "保存失败",
  "ccmessaging.chat.phrase.saveType": "保存类别",
  "ccmessaging.chat.phrase.saveTypeSuccess": "保存常用语类别成功",
  "ccmessaging.chat.phrase.shortcut": "快捷键",
  "ccmessaging.chat.phrase.success": "成功",
  "ccmessaging.chat.phrase.updatePhraseTypeError": "更新常用语类别失败",
  "ccmessaging.chat.phrase.updatePhraseTypeSucess": "更新常用语类别成功",
  "ccmessaging.chat.phrase.updateError": "更新失败",
  "ccmessaging.chat.phrase.updatePhraseError": "更新常用语失败",
  "ccmessaging.chat.phrase.updatePhraseSucess": "更新常用语成功",
  "ccmessaging.chat.phrase.updateSuccess": "更新成功",
  "ccmessaging.chat.portraitchange.customize": "自定义",
  "ccmessaging.chat.portraitchange.systemAvatar": "系统头像",
  "ccmessaging.chat.querymessage": "查询多媒体历史消息",
  "ccmessaging.chat.refresh.fail": "刷新失败",
  "ccmessaging.chat.repeatClick": "请勿重复点击！",
  "ccmessaging.chat.richText.add": "新建",
  "ccmessaging.chat.richText.cancel": "取消",
  "ccmessaging.chat.richText.idInput": "请输入id",
  "ccmessaging.chat.richText.nameInput": "请输入名称",
  "ccmessaging.chat.richText.openInNewWindow": "在新窗口中打开",
  "ccmessaging.chat.richText.save": "保存",
  "ccmessaging.chat.selectchannel.vaildate1": "渠道接入码不能重复",
  "ccmessaging.chat.sessiontransfer.tips": "只有示闲状态的座席和未达到最大并发数的通话状态的座席支持转移",
  "ccmessaging.chat.silentagent.aceesscode.empty": "接入码不能为空!",
  "ccmessaging.chat.silentagent.config.accesscode.same": "当前租间已存在该音频IVR被叫配置的静默座席配置",
  "ccmessaging.chat.silentagent.config.del.error": "删除语音静默座席配置失败",
  "ccmessaging.chat.silentagent.config.del.success": "删除语音静默座席配置成功",
  "ccmessaging.chat.silentagent.config.add.error": "新建语音静默座席配置失败",
  "ccmessaging.chat.silentagent.config.add.success": "新建语音静默座席配置成功",
  "ccmessaging.chat.silentagent.config.willdel": "确定删除所选的语音静默座席配置吗？",
  "ccmessaging.chat.silentagent.DeleteFail": "删除静默座席配置失败",
  "ccmessaging.chat.silentagent.DeleteSuccess": "删除静默座席配置成功",
  "ccmessaging.chat.silentagent.sendContent.noOIAPNode": "静默座席无法获得当前发送节点信息",
  "ccmessaging.chat.silentagent.sendContent.supervise": "监察状态下，静默座席不支持发送消息",
  "ccmessaging.chat.silentagent.silent.skill.empty": "静默座席技能队列不能为空",
  "ccmessaging.chat.silentAgent.workMode.changeWorkMode.fail": "静默座席切换状态失败",
  "ccmessaging.chat.silentAgent.workMode.changeWorkMode.prompt": "提示",
  "ccmessaging.chat.silentAgent.workMode.changeWorkMode.success": "静默座席切换状态成功",
  "ccmessaging.chat.silentAgent.workMode.common": "普通",
  "ccmessaging.chat.silentAgent.workMode.insert": "插入",
  "ccmessaging.chat.silentAgent.workMode.supervise": "监察",
  "ccmessaging.chat.silentAgent.workMode.transfer": "转移",
  "ccmessaging.chat.single.media.resource.invalid": "卡片引用失效的媒体资源.",
  "ccmessaging.chat.sms.channel.gateway": "短信网关",
  "ccmessaging.chat.social.media.enterprise.default.username": "匿名用户",
  "ccmessaging.chat.social.media.enterprise.manage": "企业帐号消息处理",
  "ccmessaging.chat.social.media.enterprise.post.add.failed": "添加失败",
  "ccmessaging.chat.social.media.enterprise.post.add.success": "添加成功",
  "ccmessaging.chat.social.media.enterprise.post.all.reply": "全部回复",
  "ccmessaging.chat.social.media.enterprise.post.batch.reply": "批量回复",
  "ccmessaging.chat.social.media.enterprise.post.cancel": "取消",
  "ccmessaging.chat.social.media.enterprise.post.comment": "评论",
  "ccmessaging.chat.social.media.enterprise.post.comment.tips": "添加评论...",
  "ccmessaging.chat.social.media.enterprise.post.reply": "回复",
  "ccmessaging.chat.social.media.enterprise.post.reply.cancel": "取消",
  "ccmessaging.chat.social.media.enterprise.post.reply.tips": "添加回复...",
  "ccmessaging.chat.social.media.enterprise.post.title": "帖子名称",
  "ccmessaging.chat.social.media.enterprise.post.topcomment.cancel": "取消评论",
  "ccmessaging.chat.social.media.enterprise.tips.close": "收起",
  "ccmessaging.chat.social.media.enterprise.tips.imgnum": "图片最多添加 1 张",
  "ccmessaging.chat.social.media.enterprise.tips.open": "展开",
  "ccmessaging.chat.social.media.multimedia.manage": "多媒体消息处理",
  "ccmessaging.chat.speciallist.channels": "限制渠道",
  "ccmessaging.chat.speciallist.channelsType": "限制渠道类型",
  "ccmessaging.chat.speciallist.choosechannel": "选择渠道",
  "ccmessaging.chat.speciallist.confirmdelete": "是否确认删除？",
  "ccmessaging.chat.speciallist.delete": "已废弃",
  "ccmessaging.chat.speciallist.deletelevel": "（已废弃）",
  "ccmessaging.chat.speciallist.emailchannels": "Email渠道",
  "ccmessaging.chat.speciallist.haverestrictall": "该渠道类型的全部渠道已被限制，无需再选择指定渠道",
  "ccmessaging.chat.speciallist.haverestrictchannel": "该渠道已被限制，无需重复选择",
  "ccmessaging.chat.speciallist.level": "特殊名单级别",
  "ccmessaging.chat.speciallist.leveName": "特殊名单类型名称",
  "ccmessaging.chat.speciallist.msgchoosechannel": "请先选择渠道类型",
  "ccmessaging.chat.speciallist.remark": "说明",
  "ccmessaging.chat.speciallist.repeatlevel": "特殊名单级别已存在，请勿重复创建",
  "ccmessaging.chat.speciallist.restrictHours": "限制时长",
  "ccmessaging.chat.speciallist.restrictUnit": "小时",
  "ccmessaging.chat.speciallist.restrictMode": "限制模式",
  "ccmessaging.chat.speciallist.restrictStyle": "应用方式",
  "ccmessaging.chat.speciallist.selectchannel": "指定渠道",
  "ccmessaging.chat.speciallist.smschannels": "短信渠道",
  "ccmessaging.chat.speciallist.unuse": "不可用",
  "ccmessaging.chat.speciallist.unuselevel": "（不可用）",
  "ccmessaging.chat.speciallist.use": "可用",
  "ccmessaging.chat.successaccess": "接入成功！!",
  "ccmessaging.chat.sumbitverification.Appld": "App Id",
  "ccmessaging.chat.sumbitverification.AppSecret": "密钥",
  "ccmessaging.chat.sumbitverification.facebook.tips": "请至Facebook messenger开发者配置页面进行配置网址和验证码",
  "ccmessaging.chat.sumbitverification.fiveG.tips": "请至5G消息平台配置信息处进行配置网址",
  "ccmessaging.chat.sumbitverification.line.tips": "请至LINE的Official Account Manager中进行Webhook URL配置",
  "ccmessaging.chat.sumbitverification.tips": "请至微信公众平台接口配置信息处进行配置网址和验证码",
  "ccmessaging.chat.sumbitverification.tips.qr": "该授权模式下无需更多配置，请继续下一步操作",
  "ccmessaging.chat.sumbitverification.Url": "网址",
  "ccmessaging.chat.sumbitverification.Url.infobipMessAgeUrl": "上行消息处理url",
  "ccmessaging.chat.sumbitverification.Url.infobipReportUrl": "发送报告url",
  "ccmessaging.chat.sumbitverification.VerityCode": "验证码",
  "ccmessaging.chat.sumbitverification.whatsApp.fail": "生成失败",
  "ccmessaging.chat.sumbitverification.whatsApp.prompt": "提示",
  "ccmessaging.chat.sumbitverification.whatsApp.success": "生成成功",
  "ccmessaging.chat.sumbitverification.whatsApp.tips": "WhatsApp 服务提供商处理消息的url",
  "ccmessaging.chat.system.error": "系统繁忙，请稍后重试。",
  "ccmessaging.chat.template.buttonSend": "发送",
  "ccmessaging.chat.template.butttonName": "选择",
  "ccmessaging.chat.template.card": "WhatsApp模板消息",
  "ccmessaging.chat.template.Description": "描述",
  "ccmessaging.chat.template.languageCode": "语言",
  "ccmessaging.chat.template.name": "模板消息名称",
  "ccmessaging.chat.template.namePlaceholder": "请输入模板消息名称",
  "ccmessaging.chat.template.table.button": "按钮",
  "ccmessaging.chat.template.table.buttonPlaceholder": "按钮快速回复内容",
  "ccmessaging.chat.template.table.no": "序号",
  "ccmessaging.chat.template.table.parameterContent": "参数内容",
  "ccmessaging.chat.template.table.parameterDescription": "参数描述",
  "ccmessaging.chat.template.table.parameterPlaceholder": "请输入",
  "ccmessaging.chat.template.whatsAppChannel": "渠道",
  "ccmessaging.chat.template.whatsAppTemplateName": "模板名称",
  "ccmessaging.chat.templateMsg.chooseMultimedia": "请选择多媒体资源",
  "ccmessaging.chat.templateMsg.sendTemplateMsg": "发送",
  "ccmessaging.chat.templateMsg.sendTemplateMsgTips": "发送模板消息",
  "ccmessaging.chat.tinymce.button.clear": "清空",
  "ccmessaging.chat.tinyMCE.FangSong": " 仿宋体",
  "ccmessaging.chat.tinyMCE.SimHei": " 黑体",
  "ccmessaging.chat.tinyMCE.simsun": " 宋体",
  "ccmessaging.chat.tinyMCE.yaHei": " 微软雅黑",
  "ccmessaging.chat.uploadLocalFile": "上传本地文件",
  "ccmessaging.chat.warning.sharescreen": "共享桌面时需注意个人隐私保护",
  "ccmessaging.chat.web.agent.assist": " 座席辅助",
  "ccmessaging.chat.web.agent.prohibitedmess": " 违禁语！请注意用词。",
  "ccmessaging.chat.web.agent.wrogmess": " 措辞不当！请注意使用适当的话术。",
  "ccmessaging.chat.web.channel.called.config": "点击通话被叫配置",
  "ccmessaging.chat.web.channel.clickivr": "点击获取IVR接入码",
  "ccmessaging.chat.webCard": "Web卡片",
  "ccmessaging.chat.webCard.copyUrl": "复制链接",
  "ccmessaging.chat.webCard.copyUrl.success": "复制链接成功",
  "ccmessaging.chat.webcard.pop.confirm.outside": "是否跳转至第三方链接?",
  "ccmessaging.chat.webcard.pop.confirm.prefix": "消息将跳转到站外地址，域名",
  "ccmessaging.chat.webcard.pop.confirm.suffix": "请确认是否跳转",
  "ccmessaging.chat.webcard.pop.error.prefix": "当前跳转地址域名",
  "ccmessaging.chat.webcard.pop.error.suffix": "不在地址白名单内，请联系运营人员添加",
  "ccmessaging.chat.webclient.chatapi.evaluation": "请您对本次服务进行评价，谢谢！",
  "ccmessaging.chat.webclient.chatapi.failTransToAgent": "转人工失败，请重试或联系管理员",
  "ccmessaging.chat.webclient.chathtml.customer": "客户",
  "ccmessaging.chat.webclient.chatjs.inpuStatus": "对方正在输入中",
  "ccmessaging.chat.whatsapp.body": "正文区",
  "ccmessaging.chat.whatsapp.buttons": "按钮区",
  "ccmessaging.chat.whatsapp.footer": "页脚区",
  "ccmessaging.chat.whatsapp.header": "标题区",
  "ccmessaging.chat.whatsapp.languageAF": "南非荷兰文",
  "ccmessaging.chat.whatsapp.languageAR": "阿拉伯文",
  "ccmessaging.chat.whatsapp.languageAZ": "阿塞拜疆文",
  "ccmessaging.chat.whatsapp.languageBG": "保加利亚文",
  "ccmessaging.chat.whatsapp.languageBN": "孟加拉文",
  "ccmessaging.chat.whatsapp.languageCA": "加泰隆尼亚文",
  "ccmessaging.chat.whatsapp.languageCS": "捷克文",
  "ccmessaging.chat.whatsapp.languageDA": "丹麦文",
  "ccmessaging.chat.whatsapp.languageDE": "德文",
  "ccmessaging.chat.whatsapp.languageEL": "希腊文",
  "ccmessaging.chat.whatsapp.languageEN": "英文",
  "ccmessaging.chat.whatsapp.languageENGB": "英文（英国）",
  "ccmessaging.chat.whatsapp.languageES": "西班牙文",
  "ccmessaging.chat.whatsapp.languageESAR": "西班牙文（阿根廷）",
  "ccmessaging.chat.whatsapp.languageESES": "西班牙文（西班牙）",
  "ccmessaging.chat.whatsapp.languageESMX": "西班牙文（墨西哥）",
  "ccmessaging.chat.whatsapp.languageET": "爱沙尼亚文",
  "ccmessaging.chat.whatsapp.languageFA": "波斯文",
  "ccmessaging.chat.whatsapp.languageFI": "芬兰文",
  "ccmessaging.chat.whatsapp.languageFIL": "菲律宾文",
  "ccmessaging.chat.whatsapp.languageFR": "法文",
  "ccmessaging.chat.whatsapp.languageGA": "爱尔兰文",
  "ccmessaging.chat.whatsapp.languageGU": "古吉拉特文",
  "ccmessaging.chat.whatsapp.languageHA": "豪萨文",
  "ccmessaging.chat.whatsapp.languageHE": "希伯来文",
  "ccmessaging.chat.whatsapp.languageHI": "印地文",
  "ccmessaging.chat.whatsapp.languageHR": "克罗地亚文",
  "ccmessaging.chat.whatsapp.languageHU": "匈牙利文",
  "ccmessaging.chat.whatsapp.languageID": "印尼文",
  "ccmessaging.chat.whatsapp.languageIT": "意大利文",
  "ccmessaging.chat.whatsapp.languageJA": "日文",
  "ccmessaging.chat.whatsapp.languageKK": "哈萨克文",
  "ccmessaging.chat.whatsapp.languageKN": "卡纳达文",
  "ccmessaging.chat.whatsapp.languageKO": "韩文",
  "ccmessaging.chat.whatsapp.languageLO": "老挝文",
  "ccmessaging.chat.whatsapp.languageLT": "立陶宛文",
  "ccmessaging.chat.whatsapp.languageLV": "拉脱维亚文",
  "ccmessaging.chat.whatsapp.languageMK": "北马其顿文",
  "ccmessaging.chat.whatsapp.languageML": "马拉雅拉姆文",
  "ccmessaging.chat.whatsapp.languageMR": "马拉地文",
  "ccmessaging.chat.whatsapp.languageMS": "马来文",
  "ccmessaging.chat.whatsapp.languageNB": "挪威文",
  "ccmessaging.chat.whatsapp.languageNL": "荷兰文",
  "ccmessaging.chat.whatsapp.languagePA": "旁遮普文",
  "ccmessaging.chat.whatsapp.languagePL": "波兰文",
  "ccmessaging.chat.whatsapp.languagePTBR": "葡萄牙文（巴西）",
  "ccmessaging.chat.whatsapp.languagePTPT": "葡萄牙文（葡萄牙）",
  "ccmessaging.chat.whatsapp.languageRO": "罗马尼亚文",
  "ccmessaging.chat.whatsapp.languageRU": "俄文",
  "ccmessaging.chat.whatsapp.languageSK": "斯洛伐克文",
  "ccmessaging.chat.whatsapp.languageSL": "斯洛文尼亚文",
  "ccmessaging.chat.whatsapp.languageSQ": "阿尔巴尼亚文",
  "ccmessaging.chat.whatsapp.languageSR": "塞尔维亚文",
  "ccmessaging.chat.whatsapp.languageSV": "瑞典文",
  "ccmessaging.chat.whatsapp.languageSW": "斯瓦希里文",
  "ccmessaging.chat.whatsapp.languageTA": "泰米尔文",
  "ccmessaging.chat.whatsapp.languageTE": "泰卢固文",
  "ccmessaging.chat.whatsapp.languageTH": "泰文",
  "ccmessaging.chat.whatsapp.languageTR": "土耳其文",
  "ccmessaging.chat.whatsapp.languageUK": "乌克兰文",
  "ccmessaging.chat.whatsapp.languageUR": "乌尔都文",
  "ccmessaging.chat.whatsapp.languageUS": "英文（美国）",
  "ccmessaging.chat.whatsapp.languageUZ": "乌兹别克文",
  "ccmessaging.chat.whatsapp.languageVI": "越南文",
  "ccmessaging.chat.whatsapp.languageZHCN": "简体中文",
  "ccmessaging.chat.whatsapp.languageZHHK": "繁体中文（香港）",
  "ccmessaging.chat.whatsapp.languageZHTW": "繁体中文（台湾）",
  "ccmessaging.chat.whatsapp.languageZU": "祖鲁文",
  "ccmessaging.chat.whatsapp.only.send.template": "whatsapp新用户或者联系间隔超过24小时，只能发送模板消息",
  "ccmessaging.chat.whatsapp.selectTemplateOrgPlaceHolder": "请选择组织机构",
  "ccmessaging.chat.whatsapp.template.dailyLimit": "模板每天可用次数",
  "ccmessaging.chat.whatsapp.template.dailyLimitDescription": "请输入模板每天可用次数",
  "ccmessaging.chat.whatsapp.template.no": "序号",
  "ccmessaging.chat.whatsapp.template.updateTime": "更新时间",
  "ccmessaging.chat.whatsapp.templateAddButton": "添加",
  "ccmessaging.chat.whatsapp.templateBodyParamDesc": "参数描述",
  "ccmessaging.chat.whatsapp.templateBodyParamDescPlaceholder": "请输入参数描述",
  "ccmessaging.chat.whatsapp.templateBodyText": "body文本内容",
  "ccmessaging.chat.whatsapp.templateBodyTextPlaceholder": "请输入文本内容，{'{{}}'}中的内容为模板body中可编辑部分！",
  "ccmessaging.chat.whatsapp.templateButton.clicktoAction": "动作",
  "ccmessaging.chat.whatsapp.templateButton.none": "没有",
  "ccmessaging.chat.whatsapp.templateButton.qickReply": "快速回复",
  "ccmessaging.chat.whatsapp.templateButtonContent": "模板按钮内容",
  "ccmessaging.chat.whatsapp.templateButtonContentPlaceholder": "请输入按钮内容",
  "ccmessaging.chat.whatsapp.templateButtonName": "模板按钮名称",
  "ccmessaging.chat.whatsapp.templateButtonNamePlaceholder": "请输入按钮名称",
  "ccmessaging.chat.whatsapp.templateButtonType": "模板按钮类型",
  "ccmessaging.chat.whatsapp.templateChannel": "模板渠道",
  "ccmessaging.chat.whatsapp.templateContent": "模板内容",
  "ccmessaging.chat.whatsapp.templateCreate": "创建WhatsApp模板",
  "ccmessaging.chat.whatsapp.templateDescription": "模板描述",
  "ccmessaging.chat.whatsapp.templateDescriptionPlaceholder": "请输入模板描述",
  "ccmessaging.chat.whatsapp.templateFooterText": "footer文本内容",
  "ccmessaging.chat.whatsapp.templateFooterTextPlaceholder": "请输入footer文本内容",
  "ccmessaging.chat.whatsapp.templateHeaderText": "Header文本内容",
  "ccmessaging.chat.whatsapp.templateHeaderTextPlaceholder": "请输入Header文本内容",
  "ccmessaging.chat.whatsapp.templateHeaderType": "Header类型",
  "ccmessaging.chat.whatsapp.templateHeaderType.image": "图片",
  "ccmessaging.chat.whatsapp.templateHeaderType.locate": "位置",
  "ccmessaging.chat.whatsapp.templateHeaderType.none": "没有",
  "ccmessaging.chat.whatsapp.templateHeaderType.text": "文本",
  "ccmessaging.chat.whatsapp.templateHeaderType.video": "视频",
  "ccmessaging.chat.whatsapp.templateLanguage": "语言",
  "ccmessaging.chat.whatsapp.templateName": "模板名称",
  "ccmessaging.chat.whatsapp.templateNamePlaceholder": "请输入模板名称",
  "ccmessaging.chat.whatsAppTemplateMsg.selectWhatsAppTemplateMsg": "选择对应的WhatsApp模板消息",
  "ccmessaging.chat.whatsAppTemplateMsg.selectWhatsAppTemplateMsgId": "WhatsApp模板消息ID",
  "ccmessaging.chat.whatsAppTemplateMsg.selectWhatsAppTemplateMsgName": "WhatsApp模板消息名称",
  "ccmessaging.chat.workbench.body.iolp": "业务引导",
  "ccmessaging.chat.workbench.body.transfer": "呼叫转移",
  "ccmessaging.chat.workbench.transfer.agent": "座席",
  "ccmessaging.chat.workbench.transfer.noagents": "当前技能队列下无在线座席",
  "ccmessaging.chat.workbench.transfer.skill": "技能队列",
  "ccmessaging.chat.workbench.transfer.skilllogin": "登录的座席数",
  "ccmessaging.chat.workbench.transfer.skillname": "技能队列名称",
  "ccmessaging.chat.workbench.transfer.skillqueue": "当前排队人数",
  "ccmessaging.chat.workbench.transfer.skillsearch": "请输入技能队列名称",
  "ccmessaging.chatbot.button.avatarImport": " 上传",
  "ccmessaging.chatbot.button.avatarImportLimit": " 上传文件大小不超过6M，支持JPG、PNG格式",
  "ccmessaging.chatbot.button.cancel": " 取消",
  "ccmessaging.chatbot.button.edit": " 编辑",
  "ccmessaging.chatbot.label.avatar": "头像",
  "ccmessaging.chatbot.label.avatarImport": " 上传头像",
  "ccmessaging.chatbot.label.avatarImportFailed": " 上传图片失败",
  "ccmessaging.chatbot.label.avatarImportSuccess": " 上传图片成功",
  "ccmessaging.chatbot.label.avatarModifing": "修改头像",
  "ccmessaging.chatbot.label.basicInformation": "基本信息",
  "ccmessaging.chatbot.label.commonLanguageSetting": "常用语设置",
  "ccmessaging.chatbot.label.defaultReply": "默认回复语",
  "ccmessaging.chatbot.label.gender": "性别",
  "ccmessaging.chatbot.label.name": "昵称",
  "ccmessaging.chatbot.label.save": "保存",
  "ccmessaging.chatbot.label.timeoutReply": "超时回复语",
  "ccmessaging.chatbot.label.turnManualPrompt": "转人工提示语",
  "ccmessaging.chatbot.label.welcomeMessage": "欢迎语",
  "ccmessaging.chatbot.message.error": "失败",
  "ccmessaging.chatbot.message.modifyFailure": "修改失败",
  "ccmessaging.chatbot.message.modifySuccess": "修改成功",
  "ccmessaging.chatbot.message.specialStrValidateFailed": "输入内容不能含有&<>/",
  "ccmessaging.chatbot.message.success": "成功",
  "ccmessaging.chatofflineCall.channelName": "渠道名称",
  "ccmessaging.chatofflineCall.invalidUserIdTip": "该用户标识非法",
  "ccmessaging.chatofflineCall.noSelectChanelTip": "未选择渠道",
  "ccmessaging.chatofflineCall.noSupportText": "该呼叫为陌生者呼叫！",
  "ccmessaging.chatofflineCall.strangeOfflineTip": "未查询到历史会话",
  "ccmessaging.cobrowsesite.protocol.check": "使用不安全协议，存在安全风险。",
  "ccmessaging.cobrowsesite.mediaurl.format.error": "网页协同地址格式不正确",
  "ccmessaging.email.attachment.fileType.tip1": "邮件附件支持如下文件格式：",
  "ccmessaging.email.attachment.fileType.tip2": "，",
  "ccmessaging.email.attachment.fileType.tip3": "文件大小不超过",
  "ccmessaging.email.attachment.fileType.tip4": "M。",
  "ccmessaging.emailcatalog.add": "新建目录",
  "ccmessaging.emailcatalog.canNotAdd": "最多两层目录",
  "ccmessaging.emailcatalog.deleteTopCatalog": "默认目录不能删除",
  "ccmessaging.emailcatalog.duplicateName": "名称已存在，请使用其他名称",
  "ccmessaging.emailcatalog.existEmail": "目录下存在邮件",
  "ccmessaging.emailcatalog.existSubCatalog": "目录下存在子目录",
  "ccmessaging.emailcatalog.modify": "编辑目录",
  "ccmessaging.emailcatalog.modifyTopCatalog": "默认目录不能修改",
  "ccmessaging.emailcatalog.moveCatalogFailed": "移动目录失败",
  "ccmessaging.emailcatalog.moveCatalogPartialSuccess": "部分邮件移动目录失败",
  "ccmessaging.emailcatalog.moveCatalogSuccess": "移动目录成功",
  "ccmessaging.emailcatalog.moveEmailQuantityExceedsMaxValue": "移动邮件数量超过30或参数超过最大长度",
  "ccmessaging.emailcatalog.moveOtherCatalogFailed": "不能移动其他座席的邮件",
  "ccmessaging.emailcatalog.catalogname": "目录名称",
  "ccmessaging.emailcatalog.parentName": "父目录名称",
  "ccmessaging.emailcatalog.pleaseSelectCatalog": "请先选择目录",
  "ccmessaging.emailcatalog.selectcatalog": "选择目录",
  "ccmessaging.emailMessageManage.forward": "转发记录",
  "ccmessaging.flowControl.addFail": "流控配置新增失败",
  "ccmessaging.flowControl.addSuccess": "流控配置新增成功",
  "ccmessaging.flowControl.alarmInterval": "告警间隔时间",
  "ccmessaging.flowControl.alarmInterval.unit": "分钟",
  "ccmessaging.flowControl.alarmThreshold": "告警阈值",
  "ccmessaging.flowControl.channelType": "渠道类型",
  "ccmessaging.flowControl.count.beyondMaxLimit": "该租间下流控规则数量超过上限！",
  "ccmessaging.flowControl.createTime": "创建时间",
  "ccmessaging.flowControl.deleteFail": "流控配置删除失败",
  "ccmessaging.flowControl.deleteSelect": "确定删除选择的规则？",
  "ccmessaging.flowControl.deleteSuccess": "流控配置删除成功",
  "ccmessaging.flowControl.description": "描述",
  "ccmessaging.flowControl.enable": "是否启用",
  "ccmessaging.flowControl.limitNum": "消息限制数量",
  "ccmessaging.flowControl.name": "流控名称",
  "ccmessaging.flowControl.operate": "操作",
  "ccmessaging.flowControl.periodType": "周期范围",
  "ccmessaging.flowControl.periodType.day": "每天",
  "ccmessaging.flowControl.periodType.month": "每月",
  "ccmessaging.flowControl.periodType.time": "自定义",
  "ccmessaging.flowControl.periodType.week": "每周",
  "ccmessaging.flowControl.periodValue": "周期时间",
  "ccmessaging.flowControl.period.select": "周期选择",
  "ccmessaging.flowControl.selectStop": "请先停用流控配置",
  "ccmessaging.flowControl.selectRule": "请选择流控配置",
  "ccmessaging.flowControl.selectStartTime": "请选择起始时间",
  "ccmessaging.flowcontrol.target.deleted": "流控对象已经删除，请删除对应的流控规则。",
  "ccmessaging.flowControl.targetId": "流控对象",
  "ccmessaging.flowControl.tipText": "提示消息",
  "ccmessaging.flowControl.tipTextInterval": "提示周期",
  "ccmessaging.flowControl.updateFail": "流控配置更新失败",
  "ccmessaging.flowControl.updateSuccess": "流控配置更新成功",
  "ccmessaging.flowControl.usage": "用量",
  "ccmessaging.flowControl.title.create": "新建消息流控",
  "ccmessaging.flowControl.title.modify": "编辑消息流控",
  "ccmessaging.flowControl.title.view": "查看消息流控",
  "ccmessaging.webCollaboration.ruleName": "规则名称",
  "ccmessaging.webCollaboration.pageMatchType": "页面匹配类型",
  "ccmessaging.webCollaboration.pageMatchConfiguration": "页面匹配配置",
  "ccmessaging.webCollaboration.pageElementSelector": "页面元素选择器",
  "ccmessaging.webCollaboration.createTime": "创建时间",
  "ccmessaging.webCollaboration.operation": "操作",
  "cobrowse.sensitiveinfo.pagematchtype.commonmatch": "通用匹配",
  "cobrowse.sensitiveinfo.pagematchtype.urlmatch": "根据URL路径匹配",
  "cobrowse.sensitiveinfo.pagematchtype.pageidmatch": "根据页面ID匹配",
  "ccmessaging.message.location.latitude": "纬度：",
  "ccmessaging.message.location.longitude": "经度：",
  "ccmessaging.message.selectEmail.count": "一次最多选择30个邮件！",
  "ccmessaging.message.selectEmail.null": "请选择邮件！",
  "ccmessaging.message.sendstatus.deliverable": "送达",
  "ccmessaging.message.sendstatus.pending": "等待中",
  "ccmessaging.message.sendstatus.read": "已读",
  "ccmessaging.message.sendstatus.undeliverable": "发送失败",
  "ccmessaging.message.sendstatus.unread": "未读",
  "ccmessaging.message.timeerror.laterThanCurTime": "起始时间不能大于当前时间",
  "ccmessaging.messagecontroll.timeerror.beyondMaxLimit": "结束时间不能超过开始时间90天！",
  "ccmessaging.multimedialibrary.cobrowsesite.card.target": "浏览目标：",
  "ccmessaging.multimedialibrary.cobrowsesite.card.title": "座席请求与用户进行协同浏览",
  "ccmessaging.multimedialibrary.tips": "该地址用于座席发送给用户进行网页协同，请合理配置",
  "ccmessaging.operlogs.emailAttachment.download": "下载邮件附件",
  "ccmessaging.operlogs.multimedialibrary.create.richtext": "新增富文本",
  "ccmessaging.operlogs.multimedialibrary.modify.richtext": "修改富文本",
  "ccmessaging.operlogs.multimedialibrary.save": "保存多媒体库数据配置",
  "ccmessaging.operlogs.multimedialibrary.save.audio": "保存多媒体库数据配置，上传音频类型文件数据。",
  "ccmessaging.operlogs.multimedialibrary.save.emoticons": "保存多媒体库数据配置，上传个性化表情类型文件数据。",
  "ccmessaging.operlogs.multimedialibrary.save.image": "保存多媒体库数据配置，上传图片类型文件数据。",
  "ccmessaging.operlogs.multimedialibrary.save.video": "保存多媒体库数据配置，上传视频类型文件数据。",
  "ccmessaging.operlogs.preview.contract": "预览合同，用户：",
  "ccmessaging.operlogs.signature.submit": "提交签名合同",
  "ccmessaging.operlogs.upload.contract": "上传合同",
  "ccmessaging.pop.org.window.noorg": "无归属组织",
  "ccmessaging.pop.org.window.org": "归属组织",
  "ccmessaging.ruleconfig.add": "添加",
  "ccmessaging.ruleconfig.all": "所有渠道",
  "ccmessaging.ruleconfig.applicationChannel": "应用渠道",
  "ccmessaging.ruleconfig.content": "内容",
  "ccmessaging.ruleconfig.contentNotNull": "内容不能为空！",
  "ccmessaging.ruleconfig.createdTime": "创建时间",
  "ccmessaging.ruleconfig.dateEnd": "结束时间",
  "ccmessaging.ruleconfig.dateStart": "起始时间",
  "ccmessaging.ruleconfig.deleteFail": "删除规则失败！",
  "ccmessaging.ruleconfig.deleteSelectRule": "确定删除选择的规则？",
  "ccmessaging.ruleconfig.deleteSuccess": "删除规则成功！",
  "ccmessaging.ruleconfig.duplicateName": "规则名称重复",
  "ccmessaging.ruleconfig.effective": "生效",
  "ccmessaging.ruleconfig.emailaddress.error": "以下地址被禁止发送：",
  "ccmessaging.ruleconfig.emailAddressExists": "邮件地址已存在",
  "ccmessaging.ruleconfig.emailAdress": "邮箱地址",
  "ccmessaging.ruleconfig.emailChannel": "邮件渠道",
  "ccmessaging.ruleconfig.ID": "序号",
  "ccmessaging.ruleconfig.invalidation": "失效",
  "ccmessaging.ruleconfig.invalidEmailAddress": "无效的邮件地址",
  "ccmessaging.ruleconfig.invalidSMSNumber": "无效手机号码",
  "ccmessaging.ruleconfig.maxEmailAddressCount": "内容中最多可输入10条邮箱地址",
  "ccmessaging.ruleconfig.maxSMSNumberCount": "内容中最多可输入10条手机号码",
  "ccmessaging.ruleconfig.maxValidityPeriod": "有效期设置为最大值",
  "ccmessaging.ruleconfig.ruleContentKeyword": "规则内容关键字",
  "ccmessaging.ruleconfig.ruleDescribe": "规则描述",
  "ccmessaging.ruleconfig.ruleName": "规则名称",
  "ccmessaging.ruleconfig.selectRule": "请选择规则",
  "ccmessaging.ruleconfig.SMSChannel": "短信渠道",
  "ccmessaging.ruleconfig.SMSNumber": "手机号码",
  "ccmessaging.ruleconfig.SMSNumberExists": "手机号码已存在",
  "ccmessaging.ruleconfig.SMStips1": `手机号码可输入通配符，"\\*"表示匹配0到多个字符，"\\?"表示匹配0到1个字符`,
  "ccmessaging.ruleconfig.SMStips2": `例如"\\*9988"表示拒绝接收所有"9988"结尾的手机号码发送的短信`,
  "ccmessaging.ruleconfig.SMStips3": `例如"132\\*"表示拒绝接收所有"132"开头的手机号码发送的短信`,
  "ccmessaging.ruleconfig.SMStips4": `"\\*"会屏蔽所有手机号码发送的短信，请谨慎使用`,
  "ccmessaging.ruleconfig.timeerror.dateValidateFailed": "请选择大于当前并小于2038-1-19的日期",
  "ccmessaging.ruleconfig.timeerror.empty": "*起始时间和结束时间不能都为空",
  "ccmessaging.ruleconfig.timeerror.endTime": "*结束时间不能早于当前时间",
  "ccmessaging.ruleconfig.timeerror.laterThanEndTime": "*起始时间不能大于结束时间",
  "ccmessaging.ruleconfig.timeerror.laterThanEndTime2": "起始时间不能大于结束时间",
  "ccmessaging.ruleconfig.timeerror.startTime": "*起始时间不能早于当前时间",
  "ccmessaging.ruleconfig.tips1": `邮箱地址可输入通配符，"\\*"表示匹配0到多个字符，"\\?"表示匹配0到1个字符`,
  "ccmessaging.ruleconfig.tips2": `例如"\\*{'@'}huawei.com"表示拒绝接收所有域名为"huawei.com"的邮件`,
  "ccmessaging.ruleconfig.tips3": `内容输入框除"\\*"\u0020"\\?"外需满足邮箱地址规则`,
  "ccmessaging.ruleconfig.tips4": `"\\*{'@'}\\*.com"会屏蔽所有域名为".com"的邮件，请谨慎使用`,
  "ccmessaging.ruleconfig.validityPeriod": "有效期",
  "ccmessaging.ruleconfig.validityPeriodDay": "Access Token 有效期（天）",
  "ccmessaging.ruleconfig.duplicateEmailAddress": "邮箱地址重复",
  "ccmessaging.ruleconfig.duplicateSmsAddress": "手机号码重复",
  "ccmessaging.socialPostOperation.button.approve": "审批",
  "ccmessaging.socialPostOperation.button.batchDelete": "批量删除",
  "ccmessaging.socialPostOperation.button.cancel": "撤回",
  "ccmessaging.socialPostOperation.button.channelConfig": "渠道设置",
  "ccmessaging.socialPostOperation.button.creatPost": "发帖",
  "ccmessaging.socialPostOperation.button.edit": "编辑",
  "ccmessaging.socialPostOperation.button.delete": "删除",
  "ccmessaging.socialPostOperation.button.saveDraft": "存为草稿",
  "ccmessaging.socialPostOperation.button.upload": "上传",
  "ccmessaging.socialPostOperation.ceratePageTitle": "帐号运营/发帖",
  "ccmessaging.socialPostOperation.edit.approveInformation": "审批信息",
  "ccmessaging.socialPostOperation.edit.basicInformation": "基本信息",
  "ccmessaging.socialPostOperation.edit.must": "必填项",
  "ccmessaging.socialPostOperation.edit.placeholder": "请输入",
  "ccmessaging.socialPostOperation.edit.publishInformation": "发布信息",
  "ccmessaging.socialPostOperation.facebook.publishAt.tips": "定时发布需在创建后的20分钟到75天之间发布",
  "ccmessaging.socialPostOperation.facebook.tips.emptyPost": "Facebook不能发布空白贴，请至少选择一项（内容、图片、视频）",
  "ccmessaging.socialPostOperation.field.applicationType": "申请类型",
  "ccmessaging.socialPostOperation.field.applicationType.create": "发帖",
  "ccmessaging.socialPostOperation.field.approveComment": "审批意见",
  "ccmessaging.socialPostOperation.field.approverName": "审批人",
  "ccmessaging.socialPostOperation.field.approveStatus": "审批结果",
  "ccmessaging.socialPostOperation.field.approveStatus.pass": "通过",
  "ccmessaging.socialPostOperation.field.approveStatus.rejected": "驳回",
  "ccmessaging.socialPostOperation.field.channelName": "渠道名称",
  "ccmessaging.socialPostOperation.field.content": "内容",
  "ccmessaging.socialPostOperation.field.createUserName": "申请人",
  "ccmessaging.socialPostOperation.field.image": "图片",
  "ccmessaging.socialPostOperation.field.operation": "操作",
  "ccmessaging.socialPostOperation.field.postConfigInfo": "渠道信息",
  "ccmessaging.socialPostOperation.field.postType": "发布渠道",
  "ccmessaging.socialPostOperation.field.publishAt": "发布时间",
  "ccmessaging.socialPostOperation.field.publishChannel": "发布频道",
  "ccmessaging.socialPostOperation.field.publishStatus": "发布状态",
  "ccmessaging.socialPostOperation.field.publishStatus.published": "已发布",
  "ccmessaging.socialPostOperation.field.publishStatus.unpublished": "未发布",
  "ccmessaging.socialPostOperation.field.publishTime": "预计发布时间",
  "ccmessaging.socialPostOperation.field.publishTimeSet": "发布时间设置",
  "ccmessaging.socialPostOperation.field.publishTyle.now": "实时发布",
  "ccmessaging.socialPostOperation.field.publishTyle.time": "定时发布",
  "ccmessaging.socialPostOperation.field.publishType": "发布类型",
  "ccmessaging.socialPostOperation.field.remark": "备注",
  "ccmessaging.socialPostOperation.field.status": "状态",
  "ccmessaging.socialPostOperation.field.status.approved": "已完成",
  "ccmessaging.socialPostOperation.field.status.draft": "草稿",
  "ccmessaging.socialPostOperation.field.status.toApproved": "待审批",
  "ccmessaging.socialPostOperation.field.title": "主题",
  "ccmessaging.socialPostOperation.field.video": "视频",
  "ccmessaging.socialPostOperation.res.recall": "撤回成功",
  "ccmessaging.socialPostOperation.search.searchTitle": "主题",
  "ccmessaging.socialPostOperation.select.pleaseChoose": "请选择",
  "ccmessaging.socialPostOperation.tab.history": "审批历史",
  "ccmessaging.socialPostOperation.tips.approveSet": "请检查审批人配置项",
  "ccmessaging.socialPostOperation.tips.approveSuccess": "审批成功",
  "ccmessaging.socialPostOperation.tips.approvingDelete": "待审批状态数据不可删除",
  "ccmessaging.socialPostOperation.tips.cancelError": "非待审批状态数据不可撤回",
  "ccmessaging.socialPostOperation.tips.cannotDel": "待审批的帖子不可以删除",
  "ccmessaging.socialPostOperation.tips.delConfirm": "是否确认删除选中数据？",
  "ccmessaging.socialPostOperation.tips.deleteApproveSuccess": "提交删除成功，待审批",
  "ccmessaging.socialPostOperation.tips.deleteNoApprove": "审批人为空，提交后将直接删除，请确认是否提交？",
  "ccmessaging.socialPostOperation.tips.fileTip": "文件大小超过限制，最大{n}MB",
  "ccmessaging.socialPostOperation.tips.imageNumTip": "图片上传最多支持9个",
  "ccmessaging.socialPostOperation.tips.imageType": "支持png、jpg、gif、bmp、tiff格式",
  "ccmessaging.socialPostOperation.tips.imgAndVideoChoose": "图片和视频不支持同时上传",
  "ccmessaging.socialPostOperation.tips.postTypesChoose": "至少选中一个发布渠道",
  "ccmessaging.socialPostOperation.tips.publishFailed": "发布失败",
  "ccmessaging.socialPostOperation.tips.saveApproveSuccess": "提交成功，待审批",
  "ccmessaging.socialPostOperation.tips.submit": "审批人为空，提交后将直接发布，请确认是否提交？",
  "ccmessaging.socialPostOperation.tips.submitSuccess": "提交成功",
  "ccmessaging.socialPostOperation.tips.titleError": "不能包含<和>",
  "ccmessaging.socialPostOperation.tips.uploadVideo": "请上传视频文件",
  "ccmessaging.socialPostOperation.tips.videoType": "支持mp4格式",
  "ccmessaging.socialPostOperation.tips.youTubeFileChoose": "Youtube渠道仅支持上传视频",
  "ccmessaging.socialPostOperation.youTube.addLabel": "添加标签",
  "ccmessaging.socialPostOperation.youTube.audience": "观众",
  "ccmessaging.socialPostOperation.youTube.audienceRule": "根据法律要求，无论您身在何处，都必须遵守《儿童在线隐私保护法》(COPPA) 和/或其他法律。您必须指明自己的视频是否为面向儿童的内容。",
  "ccmessaging.socialPostOperation.youTube.category": "类别",
  "ccmessaging.socialPostOperation.youTube.category.car": "汽车",
  "ccmessaging.socialPostOperation.youTube.category.Comedy": "喜剧",
  "ccmessaging.socialPostOperation.youTube.category.education": "教育",
  "ccmessaging.socialPostOperation.youTube.category.entertainment": "娱乐",
  "ccmessaging.socialPostOperation.youTube.category.film": "电影和动画",
  "ccmessaging.socialPostOperation.youTube.category.gaming": "游戏",
  "ccmessaging.socialPostOperation.youTube.category.howto": "DIY 和生活百科",
  "ccmessaging.socialPostOperation.youTube.category.music": "音乐",
  "ccmessaging.socialPostOperation.youTube.category.news": "新闻和政治",
  "ccmessaging.socialPostOperation.youTube.category.nonprofits": "公益与社会活动",
  "ccmessaging.socialPostOperation.youTube.category.people": "人物和博客",
  "ccmessaging.socialPostOperation.youTube.category.pets": "宠物和动物",
  "ccmessaging.socialPostOperation.youTube.category.science": "科学和技术",
  "ccmessaging.socialPostOperation.youTube.category.shortMovies": "短片",
  "ccmessaging.socialPostOperation.youTube.category.sport": "体育",
  "ccmessaging.socialPostOperation.youTube.category.travel": "旅游和活动",
  "ccmessaging.socialPostOperation.youTube.categoryDetail": "将您的视须添加到某个类别中, 方便观看者找到它",
  "ccmessaging.socialPostOperation.youTube.disclosureScope": "公开范围",
  "ccmessaging.socialPostOperation.youTube.disclosureScopeDetail": "选择哪些人可以看到您的视频",
  "ccmessaging.socialPostOperation.youTube.embedding": "允许嵌入",
  "ccmessaging.socialPostOperation.youTube.embedding.tips": "允许他人在其网站中嵌入您的视频。",
  "ccmessaging.socialPostOperation.youTube.knowledgePermission": "知识共享-署名",
  "ccmessaging.socialPostOperation.youTube.labels": "标签",
  "ccmessaging.socialPostOperation.youTube.labelsDetail": "如果视频内容中有容易拼错的字词，标签可以帮助观看者找到您的视频。除此之外，标签对查找视频的作用不大。",
  "ccmessaging.socialPostOperation.youTube.noKids": "不是面向儿童的",
  "ccmessaging.socialPostOperation.youTube.normalPermission": "标准YouTube许可",
  "ccmessaging.socialPostOperation.youTube.permissions": "许可",
  "ccmessaging.socialPostOperation.youTube.permissionsKnow": "了解许可类型。",
  "ccmessaging.socialPostOperation.youTube.scopePrivate": "私享",
  "ccmessaging.socialPostOperation.youTube.scopePublic": "公开",
  "ccmessaging.socialPostOperation.youTube.scopeUnlisted": "不公开列出",
  "ccmessaging.socialPostOperation.youTube.tags.tips": "在每个标签后输入一个逗号",
  "ccmessaging.socialPostOperation.youTube.videoToKids": "此视频是面向儿童的内容吗？",
  "ccmessaging.socialPostOperation.youTube.view.commentNum": "14条评论",
  "ccmessaging.socialPostOperation.youTube.view.dataResolve": "数据分析",
  "ccmessaging.socialPostOperation.youTube.view.editVideo": "编辑视频",
  "ccmessaging.socialPostOperation.youTube.view.lookNum": "38次观看  2天前",
  "ccmessaging.socialPostOperation.youTube.view.orderType": "排序方式",
  "ccmessaging.socialPostOperation.youTube.view.share": "分享",
  "ccmessaging.socialPostOperation.youTube.view.subscriber": "1位订阅者",
  "ccmessaging.socialPostOperation.youTube.view.unfolding": "展开",
  "ccmessaging.socialPostOperation.youTube.yesKids": "是，内容是面向儿童的",
  "ccmessaging.socialPostOperation.youTube.publishAtTips": "仅当公开范围设置为“私享”时，才能进行此设置",
  "ccmessaging.socialPostOperation.facebook.threeDay": "3天",
  "ccmessaging.socialPostOperation.facebook.awful": "赞",
  "ccmessaging.socialPostOperation.facebook.comment": "评论",
  "ccmessgaing.chat.web.channel.Agent": "音视频座席",
  "ccmessgaing.chat.web.channel.ivr": "IVR",
  "ccmessgaing.chat.web.channel.webrtc.share": "共享桌面涉及隐私展示，请确定",
  "ccmessgaing.chatbot.message.female": "女",
  "ccmessgaing.chatbot.message.male": "男",
  "ccmessgaing.postChannelConfig.accessToken": "访问凭证",
  "ccmessgaing.postChannelConfig.add": "新增渠道",
  "ccmessgaing.postChannelConfig.apiFabricAK": "ApiFabric标识",
  "ccmessgaing.postChannelConfig.apiFabricSK": "ApiFabric秘钥",
  "ccmessgaing.postChannelConfig.apiKey": "API秘钥",
  "ccmessgaing.postChannelConfig.appId": "客户端标识",
  "ccmessgaing.postChannelConfig.appKey": "客户端秘钥",
  "ccmessgaing.postChannelConfig.cancel": "取消",
  "ccmessgaing.postChannelConfig.channelConfig": "渠道配置",
  "ccmessgaing.postChannelConfig.channelCreate": "创建渠道",
  "ccmessgaing.postChannelConfig.channelEdit": "编辑渠道配置",
  "ccmessgaing.postChannelConfig.channelType": "渠道类型",
  "ccmessgaing.postChannelConfig.confirm": "确认",
  "ccmessgaing.postChannelConfig.addUsers": "新增",
  "ccmessgaing.postChannelConfig.createFailed": "创建失败,请检查表单参数及网络连接",
  "ccmessgaing.postChannelConfig.createSuccess": "创建社交多媒体渠道配置信息成功",
  "ccmessgaing.postChannelConfig.delete": "删除",
  "ccmessgaing.postChannelConfig.deleteFailed": "删除社交多媒体渠道配置信息失败",
  "ccmessgaing.postChannelConfig.deleteFailed.db": "删除失败,操作数据库失败",
  "ccmessgaing.postChannelConfig.deleteFailed.permission": "删除失败,未通过权限检查",
  "ccmessgaing.postChannelConfig.deleteFailed.inUse": "删除失败,渠道存在发布信息",
  "ccmessgaing.postChannelConfig.deleteSuccess": "删除社交多媒体渠道配置信息成功",
  "ccmessgaing.postChannelConfig.edit": "编辑",
  "ccmessgaing.postChannelConfig.editFailed": "更新失败,请检查表单参数及网络连接",
  "ccmessgaing.postChannelConfig.editSuccess": "更新社交多媒体渠道配置信息成功",
  "ccmessgaing.postChannelConfig.failed": "失败",
  "ccmessgaing.postChannelConfig.maxLength": "最大长度为{n}个字符",
  "ccmessgaing.postChannelConfig.operation": "操作",
  "ccmessgaing.postChannelConfig.operation.person": "运营人员",
  "ccmessgaing.postChannelConfig.placeSelectChannelType": "请选择渠道类型",
  "ccmessgaing.postChannelConfig.postChannelName": "渠道名称",
  "ccmessgaing.postChannelConfig.refreshToken": "刷新凭证",
  "ccmessgaing.postChannelConfig.repeatedly": "频道已存在",
  "ccmessgaing.postChannelConfig.users.overSize": "运营人员数量超过10个",
  "ccmessgaing.postChannelConfig.selectByChannelName": "渠道名称",
  "ccmessgaing.postChannelConfig.subChannelId": "频道标识",
  "ccmessgaing.postChannelConfig.success": "成功",
  "ccmessgaing.postChannelConfig.unauthorized": "操作未认证",
  "chat.agent.chatOnlineWorkbench": "在线交谈工作台",
  "chat.agent.label.balance": "收支",
  "chat.agent.label.billDetail": "账单明细",
  "chat.agent.label.chatBot": "智能客服",
  "chat.agent.label.consumption": "我的消费",
  "chat.agent.label.customerSource": "客户来源：手机应用-个人中心",
  "chat.agent.label.downagainfile": "重新下载",
  "chat.agent.label.goAway": "离开",
  "chat.agent.label.historicalBill": "历史账单",
  "chat.agent.label.money": "金额",
  "chat.agent.label.mybill": "我的账单",
  "chat.agent.label.offline": "下线",
  "chat.agent.label.online": "在线",
  "chat.agent.label.recivefile": "接收",
  "chat.agent.label.refusefile": "拒绝",
  "chat.agent.label.send": "发送",
  "chat.agent.label.total": "合计",
  "chat.agent.label.waiting": "等待",
  "chat.agent.message.changeFailed": "变更座席状态失败！",
  "chat.agent.title.changeWorkMode": "状态切换",
  "chat.agent.title.close": "关闭",
  "chat.agent.title.createCase": "创建Case",
  "chat.agent.title.createSkill": "创建技能",
  "chat.agent.title.enterText": "请输入",
  "chat.agent.title.OfflineCall": "离线通话",
  "chat.agent.title.transfer": "转给",
  "chat.agent.title.userInformation": "客户信息",
  "chat.agent.title.userMenu": "客户菜单",
  "chat.agent.title.workMode": "工作状态",
  "chat.agentConsole.message.content.audio": "[语音消息]",
  "chat.agentConsole.message.content.connectMessage": "您有一条新的消息！",
  "chat.agentConsole.message.content.image": "[图片消息]",
  "chat.agentConsole.message.content.locate": "[位置消息]",
  "chat.agentConsole.message.content.video": "[视频消息]",
  "chat.agentConsole.message.error.emptyMessage": "【消息包含无可显示内容】",
  "chat.agentConsole.message.error.notification": "!!!无法创建新消息提醒，请检查浏览器配置!!!",
  "chat.client.alert.message.invildtoken": "您会话已超时或已在其它地方登录，请关闭交谈窗口后重试，谢谢！",
  "chat.client.button.submit": "提交",
  "chat.client.evaluate.message": "请对我的服务做出评价，谢谢！",
  "chat.client.greeting.afternoon": "下午好！",
  "chat.client.greeting.dear": "尊敬的",
  "chat.client.greeting.evening": "晚上好！",
  "chat.client.greeting.info": "有什么可以帮助您的？",
  "chat.client.greeting.morning": "上午好！",
  "chat.client.label.accountNumber": "帐号",
  "chat.client.label.bill": "我的账单",
  "chat.client.label.chatTitle": "客户端",
  "chat.client.label.download": "下载",
  "chat.client.label.evaluation": "满意度调查",
  "chat.client.label.forgotPassword": "忘记密码",
  "chat.client.label.header": "Telco+",
  "chat.client.label.input": "请输入",
  "chat.client.label.like": "猜你喜欢",
  "chat.client.label.login": "登录",
  "chat.client.label.loginTitle": "登录",
  "chat.client.label.more": "更多",
  "chat.client.label.no": "否",
  "chat.client.label.order": "我的订单",
  "chat.client.label.password": "密码",
  "chat.client.label.privacyHint": "隐私提示",
  "chat.client.label.privacyHintContent": "为了提升服务质量，您的交谈内容将被系统记录，是否同意并继续交谈？",
  "chat.client.label.promotions": "提示",
  "chat.client.label.rateService": "请对服务打分",
  "chat.client.label.system": "系统繁忙，请稍后再试",
  "chat.client.label.yes": "是",
  "chat.client.message.evaluationFail": "满意度调查失败！",
  "chat.client.message.evaluationSuccess": "满意度调查成功！",
  "chat.client.message.gladtoServe": "。很高兴为您服务！",
  "chat.client.message.hiMyWorkno": "您好！我的工号是",
  "chat.client.message.noAgent": "对不起，客服正忙，请稍后再试！",
  "chat.client.transfer.link": "是否转接人工服务？",
  "chat.common.message.cancel": "取消",
  "chat.common.message.create": "新增",
  "ccmessaging.chat.fiveGCard.add": "新建5G卡片",
  "ccmessaging.chat.fiveGCard.edit": "编辑5G卡片",
  "ccmessaging.chat.fiveGCarouselCard.add": "新建5G轮播卡片",
  "ccmessaging.chat.fiveGCarouselCard.edit": "编辑5G轮播卡片",
  "ccmessaging.chat.webCard.add": "新建Web卡片",
  "ccmessaging.chat.webCard.edit": "编辑Web卡片",
  "ccmessaging.chat.coBrowseSite.add": "新建网页协同地址",
  "ccmessaging.chat.coBrowseSite.edit": "编辑网页协同地址",
  "ccmessaging.chat.richTextPage.add": "新建富文本",
  "ccmessaging.chat.richTextPage.edit": "编辑富文本",
  "ccmessaging.chat.chatPhrase.add": "新建常用语",
  "ccmessaging.chat.chatPhrase.edit": "编辑常用语",
  "ccmessaging.chat.audioLibrary.add": "新建语音",
  "ccmessaging.chat.audioLibrary.edit": "编辑语音",
  "ccmessaging.chat.videoLibrary.add": "新建视频",
  "ccmessaging.chat.videoLibrary.edit": "编辑视频",
  "ccmessaging.chat.imgLibrary.add": "新建图片",
  "ccmessaging.chat.imgLibrary.edit": "编辑图片",
  "ccmessaging.chat.emoLibrary.add": "新建个性化表情",
  "ccmessaging.chat.emoLibrary.edit": "编辑个性化表情",
  "ccmessaging.chat.docLibrary.add": "新建文档",
  "ccmessaging.chat.docLibrary.edit": "编辑文档",
  "ccmessaging.chat.locate.add": "新建地址",
  "ccmessaging.chat.locate.edit": "编辑地址",
  "chat.common.message.dateEnd": "结束时间",
  "chat.common.message.dateStart": "起始时间",
  "chat.common.message.delete": "删除",
  "chat.common.message.error": "错误",
  "chat.common.message.fail": "失败",
  "chat.common.message.finish": "完成",
  "chat.common.message.modify": "修改",
  "chat.common.message.promotions": "提示",
  "chat.common.message.success": "成功",
  "chat.common.message.warning": "警告",
  "chat.confirm.no": "否",
  "chat.confirm.ok": "确认",
  "chat.confirm.yes": "是",
  "chat.session.channel.all": "全部",
  "chat.session.channel.facebook": "Facebook",
  "chat.session.channel.mobile": "移动终端",
  "chat.session.channel.twitter": "X (Twitter)",
  "chat.session.channel.web": "网页",
  "chat.session.channel.wechat": "微信",
  "chat.session.endType.agentEnd": "座席结束",
  "chat.session.endType.customerEnd": "客户结束",
  "chat.session.endType.overtimeEnd": "超时结束",
  "chat.session.label.alias": "客户名称",
  "chat.session.label.allChannel": "所有接入渠道",
  "chat.session.label.allEndType": "所有结束类型",
  "chat.session.label.channel": "会话接入渠道",
  "chat.session.label.created_endTime": "结束时间",
  "chat.session.label.created_startTime": "开始时间",
  "chat.session.label.createdTime": "会话创建时间",
  "chat.session.label.endReason": "会话结束原因",
  "chat.session.label.endTime": "会话结束时间",
  "chat.session.label.endType": "会话结束类型",
  "chat.session.label.enterQueueTime": "入队列时间",
  "chat.session.label.evaluation": "会话评价",
  "chat.session.label.evaluationTime": "会话评价时间",
  "chat.session.label.exitQueueTime": "出队列时间",
  "chat.session.label.remark": "评论",
  "chat.session.label.reset": "重置",
  "chat.session.label.search": "搜索",
  "chat.session.label.servedAgent": "服务座席",
  "chat.session.label.sessionId": "会话ID",
  "chat.session.label.skillQueueId": "技能队列ID",
  "chat.session.label.skillQueueName": "技能队列名称",
  "chat.session.label.subChannel": "子接入渠道",
  "chat.session.title.basic": "会话基础信息",
  "chat.session.title.detail": "会话详细信息",
  "chat.session.title.history": "历史聊天记录",
  "chat.skill.label.account": "帐户",
  "chat.skill.label.agents": "座席",
  "chat.skill.label.cancel": "取消",
  "chat.skill.label.createSkill": "创建",
  "chat.skill.label.createSkillTab": "创建技能",
  "chat.skill.label.delete": "删除",
  "chat.skill.label.deleteSkills": "删除",
  "chat.skill.label.description": "描述",
  "chat.skill.label.edit": "编辑",
  "chat.skill.label.inputName": "名称",
  "chat.skill.label.maxQueueNumber": "最大排队人数",
  "chat.skill.label.maxQueueTime": "最大排队时间(秒)",
  "chat.skill.label.maxQueueTimeInTable": "最大排队时间",
  "chat.skill.label.name": "用户名",
  "chat.skill.label.ok": "确认",
  "chat.skill.label.priority": "优先级",
  "chat.skill.label.reset": "重置",
  "chat.skill.label.save": "保存",
  "chat.skill.label.skill": "技能队列-",
  "chat.skill.label.skillDetail": "技能详情",
  "chat.skill.label.skillId": "编号",
  "chat.skill.label.skillName": "名称",
  "chat.skill.label.submit": "提交",
  "chat.skill.message.completeInputs": "请完成所有输入。",
  "chat.skill.message.createFail": "技能创建失败！",
  "chat.skill.message.createSuccess": "技能创建成功！",
  "chat.skill.message.delete": "删除",
  "chat.skill.message.error": "错误",
  "chat.skill.message.failDeleteSkill": "删除技能失败！",
  "chat.skill.message.isExisted": "该技能名称已存在。",
  "chat.skill.message.priorityLength": "确保优先级不大于5位。",
  "chat.skill.message.saveFail": "技能保存失败！",
  "chat.skill.message.saveSuccess": "技能保存成功！",
  "chat.skill.message.selectSkills": "请选择技能！",
  "chat.skill.message.success": "成功",
  "chat.skill.message.warning": "警告",
  "chat.skill.message.willDeleteAllSkills": "确定删除所有的技能吗？",
  "chat.skill.message.willDeleteSkill": "确定删除该技能吗？",
  "chat.skill.message.willDeleteSkills": "确定删除所选的技能吗？",
  "chat.thirdportal.chatWindow.button.startChat": "开始交谈",
  "chat.thirdportal.chatWindow.label.userEmail": "邮箱",
  "chat.thirdportal.chatWindow.label.userName": "姓名",
  "chat.thirdportal.chatWindow.label.userPhone": "号码",
  "chat.thirdportal.title": "客户Web Portal",
  "chat.timerange.label.alldays": "所有创建时间",
  "chat.timerange.label.confirmSocailChatNum": "并发最大服务客户数是0将采用全局配置",
  "chat.timerange.label.customizes": "自定义",
  "chat.timerange.label.ok": "确认",
  "chat.timerange.label.oneday": "最近1天",
  "chat.timerange.label.sevendays": "最近7天",
  "chat.timerange.label.thirtydays": "最近30天",
  "chat.timerange.label.threedays": "最近3天",
  "chat.timerange.message.timeempty": "选择的时间不能为空。",
  "chat.timerange.message.timeerror": "选择的时间格式错误。",
  "chat.usefulexpressions.label.createExpressionTab": "创建常用语",
  "chat.usefulexpressions.label.expression": "常用语",
  "chat.usefulexpressions.label.expressionDetail": "常用语详情",
  "chat.usefulexpressions.message.contentLength": "确保常用语长度不大于1000！",
  "chat.usefulexpressions.message.contentNotNull": "常用语不可为空！",
  "chat.usefulexpressions.message.createFail": "常用语创建失败！",
  "chat.usefulexpressions.message.createSuccess": "常用语创建成功！",
  "chat.usefulexpressions.message.failDeleteExpression": "删除常用语失败！",
  "chat.usefulexpressions.message.isExisted": "该常用语名称已存在。",
  "chat.usefulexpressions.message.saveFail": "常用语保存失败！",
  "chat.usefulexpressions.message.saveSuccess": "常用语保存成功！",
  "chat.usefulexpressions.message.selectExpressions": "请选择常用语！",
  "chat.usefulexpressions.message.willDeleteExpression": "确定删除该常用语吗？",
  "chat.usefulexpressions.message.willDeleteExpressions": "确定删除所选的常用语吗？",
  "chat.web.evaluation.star1": "很不满意",
  "chat.web.evaluation.star2": "不满意",
  "chat.web.evaluation.star3": "一般",
  "chat.web.evaluation.star4": "满意",
  "chat.web.evaluation.star5": "很满意",
  "chat.webconfig.button.save": "保存",
  "chat.webconfig.label.bandingskill": "绑定技能队列",
  "chat.webconfig.label.basesettings": "基本配置",
  "chat.webconfig.label.functionalsettings": "功能设置",
  "chat.webconfig.label.greeting": "欢迎语",
  "chat.webconfig.label.greeting.placeholder": "请输入用于显示在客户端交谈窗口的欢迎语内容",
  "chat.webconfig.label.intergratedcode": "集成代码",
  "chat.webconfig.label.intergratemsg": "集成Live Chat到您的Web网站",
  "chat.webconfig.label.language": "默认语种",
  "chat.webconfig.label.language.en": "英文",
  "chat.webconfig.label.language.zh": "中文",
  "chat.webconfig.label.language.pt": "葡萄牙语",
  "chat.webconfig.label.transHuman": "转人工客服",
  "chat.webconfig.label.windowTitle": "网页标题",
  "chat.webconfig.label.windowTitle.placeholder": "请输入用于显示在客户端交谈窗口的标题内容",
  "chat.webconfig.message.configdirective": "将下面一段代码复制并粘贴到您的网站</body>标签之前，保存并发布，即可完成Huawei Service Cloud的快速接入，发布后您的网站右下角将会出现Huawei Service Cloud入口，点击可以与客服工作台进行实时对话。",
  "chat.webconfig.message.demodirective": "您也可以在Chrome浏览器的隐身窗口中打开如下地址，访问系统提供的演示界面。",
  "chat.webconfig.message.saveFail": "配置保存失败！",
  "chat.webconfig.message.saveSuccess": "配置保存成功！",
  "chat.webconfig.title": "启用Web渠道",
  "chat.weChatConfig.label.appId": "开发者ID",
  "chat.weChatConfig.label.AppSecret": "开发者密码",
  "chat.weChatConfig.label.bindSkill": "绑定技能队列",
  "chat.weChatConfig.label.codeName": "渠道接入编码",
  "chat.weChatConfig.label.configId": "配置ID",
  "chat.weChatConfig.label.create": "新增",
  "chat.weChatConfig.label.delete": "删除",
  "chat.weChatConfig.label.Domain": "服务器地址",
  "chat.weChatConfig.label.facebook": "Facebook",
  "chat.weChatConfig.label.nextStep": "下一步",
  "chat.weChatConfig.label.qcode": "扫码托管",
  "chat.weChatConfig.label.save": "保存",
  "chat.weChatConfig.label.search": "搜索",
  "chat.weChatConfig.label.secret": "秘钥托管",
  "chat.weChatConfig.label.state": "状态",
  "chat.weChatConfig.label.tenantId": "租户ID",
  "chat.weChatConfig.label.verify": "验证",
  "chat.weChatConfig.label.verifycode": "令牌",
  "chat.weChatConfig.label.verifying": "正在验证",
  "chat.weChatConfig.label.wechat": "微信",
  "chat.weChatConfig.link.wechat": "微信后台",
  "chat.weChatConfig.message.appIdExisted": "输入的公众号开发者ID已存在！",
  "chat.weChatConfig.message.codeNameExisted": "渠道接入编码已存在！",
  "chat.weChatConfig.message.deleteError": "删除配置项失败!",
  "chat.weChatConfig.message.emptyDeleteArray": "请选中待删除配置项！",
  "chat.weChatConfig.message.ensureDelete": "确认删除选中的配置项吗？",
  "chat.weChatConfig.message.error": "错误",
  "chat.weChatConfig.message.savesuccess": "接入成功！",
  "chat.weChatConfig.message.skillExisted": "选择的技能队列已绑定其它公众号！",
  "chat.weChatConfig.message.unknown": "验证状态未知！",
  "chat.weChatConfig.stepname.selectchannel": "选择接入渠道",
  "chat.weChatConfig.stepname.selectmode": "选择配置方法",
  "chat.weChatConfig.stepname.success": "接入成功",
  "chat.weChatConfig.stepname.verify": "提交验证",
  "chat.weChatConfig.title": "社交媒体开通配置",
  "chat.weChatConfig.title.deleteTitle": "删除微信配置项",
  "cmessaging.chat.answerUser.error": "创建用户回话失败",
  "cmessaging.chat.answerUser.Tips": "创建用户回话",
  "cmessaging.chat.channelconfig.isPushWebMessage": "消息推送",
  "cmessaging.chat.channelconfig.pushWebMessageKey": "消息推送密钥",
  "cmessaging.chat.channelconfig.pushWebMessageUrl": "消息推送地址",
  "cmessaging.chat.channelconfig.pushWebCertTypeSignature": "签名认证",
  "cmessaging.chat.channelconfig.pushWebCertTypeOAuth": "OAuth认证",
  "cmessaging.chat.channelconfig.setAgentTimeout": "座席超时转移会话",
  "cmessaging.chat.multimedia.numOverMaxSum": "您保存的多媒体数据的数量超出了限制",
  "cmessaging.chat.multimediatype.numOverMaxSum": "您保存的分类数量超出了限制",
  "ccmessaging.socialPostOperation.search.searchContent": "请输入内容",
  "ccmessaging.socialPostOperation.link": "访问原文",
  "ccmessaging.chat.social.media.enterprise.post.load.failed": "附件加载失败",
  "ccmessaging.chat.multimedia.imageUploadChannelFormat": "WhatsApp、Web、Telegram、LINE、微信、Facebook、X (Twitter)渠道支持jpg、png、jpeg格式。",
  "ccmessaging.chat.multimedia.audioUploadChannelFormat": "WhatsApp渠道支持acc格式。\nWeb渠道支持mp3、aac格式。\nInstagram、LINE渠道支持m4a格式。\n微信、Facebook渠道支持mp3格式。\nTelegram渠道支持mp3、m4a格式。",
  "ccmessaging.chat.multimedia.documentUploadChannelFormat": "WhatsApp渠道支持doc、docx、pdf、ppt、pptx、xlsx、xls格式。\nWeb渠道支持doc、docx、pdf、zip格式。\nTelegram渠道支持pdf、zip格式。",
  "ccmessaging.chat.channelconfig.searchChannelName": "搜索渠道名称",
  "ccmessaging.chat.channelconfig.createChannel": "新建渠道",
  "ccmessaging.chat.channelconfig.modifyChannel": "修改渠道",
  "ccmessaging.chat.channelconfig.channelIntegrate": "渠道集成",
  "ccmessaging.chat.channelconfig.baseConfig": "基础配置",
  "ccmessaging.chat.channelconfig.robotConfig": "机器人配置",
  "ccmessaging.chat.channelconfig.chooseChannel": "渠道选择",
  "ccmessaging.chat.channelconfig.range.placeholder": "{0}-{1}",
  "ccmessaging.chat.channelconfig.advanceConfig": "更多配置",
  "ccmessaging.chat.channelconfig.web.clickToCall": "点击通话",
  "ccmessaging.chat.channelconfig.time.timeNoRange": "第{n}个时段中，开始时间应小于结束时间",
  "ccmessaging.chat.channelconfig.time.timeCross": "第{0}个时段和第{1}个时段存在交叉",
  "ccmessaging.chat.channelconfig.time.timePeriod0": "请填写第{n}个时段",
  "ccmessaging.chat.channelconfig.time.timePeriod": "请填写或删除第{n}个时段",
  "ccmessaging.silentagent.button.create": "新建",
  "ccmessaging.silentagent.dialog.title.create": "新建语音静默座席",
  "ccmessaging.silentagent.dialog.title.edit": "编辑语音静默座席",
  "ccmessaging.silentagent.dialog.title.view": "查看语音静默座席",
  "ccmessaging.silentagent.delete.selectone": "请至少选择一条记录",
  "ccmessaging.silentagent.delete.select.morethan.100": "一次性删除的数据量不能超过100",
  "ccmessaging.silentagent.placeholder.selectaudioskill": "请选择语音技能队列",
  'ccmessaging.silentagent.btn.add.period': '添加时段',
  'ccmessaging.silentagent.agent.workday.worktime.error': '座席工作日工作时间配置有误，时间段存在交叉',
  'ccmessaging.silentagent.agent.holiday.worktime.error': '座席非工作日工作时间配置有误，时间段存在交叉',
  'ccmessaging.silentagent.agent.workday.worktime.empty': '座席工作日工作时间存在空的配置',
  'ccmessaging.silentagent.agent.holiday.worktime.empty': '座席非工作日工作时间存在空的配置',
  "ccmessaging.silentagent.voiceaccess.empty": "音频IVR被叫配置不能为空",
  "ccmessaging.chat.channelconfig.searchCertCode": "搜索证书编码",
  "ccmessaging.chat.channelconfig.cloudApi.accessToken.tips": "access token为开发者平台生成的访问cloud api的access token\n,有效期填入请根据实际减少2天\n,比如实际60天\n,建议填入58天\n,为空代表是永久性token\n,容易泄露请谨慎使用",
  "ccmessaging.chat.channelconfig.cloudApi.bsp.tips": "目前Whatsapp Cloud API只对接文本消息",
  "ccmessaging.chat.channelconfig.checkWhatsAppAccessTokenDuration": "access token有效时间应为1-60之间的正整数",
  "ccmessaging.chat.channelconfig.copy": "复制",
  "ccmessaging.chat.channelconfig.copySuccess": "复制成功",
  "ccmessaging.chat.sumbitverification.whatsApp.cloudapi.tips": "请到Facebook开发者平台的WhatsApp应用中进行配置网址和验证码",
  "ccmessaging.chatbot.label.useIntelligentRobot": "接入智能机器人",
  "ccmessaging.chatbot.label.useRobotAssistants": "接入机器人助手",
  "ccmessaging.chatbot.label.update": "更新",
  "ccmessaging.chatbot.label.pleaseSelect": "请选择",
  "ccmessaging.chatbot.label.pleaseInput": "请输入",
  "ccmessaging.chatbot.label.intelligentRobotAccessCode": "智能机器人接入码",
  "ccmessaging.chatbot.label.selectIntelligentRobot": "选择智能机器人",
  "ccmessaging.chatbot.label.selectRobotAssistants": "选择机器人助手",
  "ccmessaging.chatbot.check.systeam": "系统",
  "ccmessaging.chatbot.label.robotName": "机器人名称",
  "ccmessaging.chatbot.label.selectRobotAvatar": "选择机器人头像",
  "ccmessaging.fiveG.label.chatRobotAddress": "聊天机器人地址",
  "ccmessaging.fiveG.label.appId": "App标识",
  "ccmessaging.fiveG.label.appSecret": "App密钥",
  "ccmessaging.fiveG.label.certCode": "证书文件",
  "ccmessaging.email.label.selectGateway": "选择网关",
  "ccmessaging.email.label.gatewayName": "网关名称",
  "ccmessaging.email.label.InputGatewayName": "请输入网关名称",
  "ccmessaging.email.label.defaultReceiveGateway": "默认接收邮箱",
  "ccmessaging.email.label.defaultSendGateway": "默认发送邮箱",
  "ccmessaging.sms.label.defaultSendGateway": "默认发送号码",
  "ccmessaging.sms.label.defaultReceiveGateway": "默认接收号码",
  "ccmessaging.email.valid.signatureSearch": "仅允许10位以内的字母或数字",
  "ccmessaging.service.time.label": "工作日历配置",
  "ccmessaging.service.time.choose.time": "选择休息日",
  "ccmessaging.service.time.select.holiday": "指定休息日期",
  "ccmessaging.service.time.select.weekday": "指定工作日期",
  "ccmessaging.multimedia.tip.not.exist": "多媒体资源不存在",
  "ccmessaging.multimedia.tip.modified": "多媒体资源已经被修改，请重新发送",
  "ccmessaging.service.time.close.alert": "关闭将清空该表格中的所有数据，是否确认关闭",
  "ccmessaging.chat.phrase.create": "新建常用语",
  "ccmessaging.chat.phrase.update": "编辑常用语",
  "ccmessaging.chat.voice.record.tip": "录音时间不超过10min",
  "ccmessaging.chat.voice.record.stop": "停止录制",
  "ccmessaging.chat.voice.record.start": "点击录制",
  "ccmessaging.chat.voice.record.send": "发送语音",
  "ccmessaging.chat.specialChannel.create": "新建特殊名单规则",
  "ccmessaging.chat.specialChannel.update": "编辑特殊名单规则",
  "ccmessaging.emailAdress.contentNotNull": "邮箱地址不能为空",
  "ccmessaging.smsNumber.contentNotNull": "手机号码不能为空",
  "ccmessaging.callcenterinstanceslist.vcallcenterDeatil.passValidateFailed": "应包含大写字母，小写字母，数字，特殊字符{n}中的任意三种",
  "ccmessaging.chat.shortcut.shortcutRepeat": "快捷键已被使用",
  "ccmessaging.chat.multimedia.agentHangupSessionResult": "会话结束成功，请关闭CRM工单",
  "ccmessaging.chat.multimedia.agentAutoTransferResult": "呼叫转移成功，请关闭CRM工单",
  "ccmessaging.chat.multimedia.agentTimeOutTransferResult": "会话超时转移技能队列，请关闭CRM工单",
  "ccmessaging.chat.multimedia.userHangupSessionResult": "用户主动挂断会话，请关闭CRM工单",
  "ccmessaging.chat.multimedia.userTimeOutHangupResult": "会话超时结束，请关闭CRM工单",
  "ccmessaging.chat.multimedia.agentLogoutHangupResult": "座席强制签出，会话结束，请关闭CRM工单",
  "ccmessaging.chat.dy.reconnect": "重连",
  "ccmessaging.chat.dy.uploadLocalFile": "本地文件",
  "ccmessaging.chat.dy.call.end": '会话结束',
  "ccmessaging.chat.richtext.httpTip": "插入的链接使用不安全协议，存在安全风险，是否提交",
  "ccmessaging.chat.riskTip": "风险提示",
  "ccmessaging.chat.chatcard.location": "地址",
  "ccmessaging.chat.chatcard.alignment": "卡片展示风格",
  "ccmessaging.chat.chatcard.templateType": "模板类型",
  "ccmessaging.chat.chatcard.templateTypeList0": "普通类型",
  "ccmessaging.chat.chatcard.templateTypeList1": "卡片选项",
  "ccmessaging.chat.chatcard.tabMediaResource": "图片媒体资源",
  "ccmessaging.chat.chatcard.tabnamevalidate": "redirectUrl为系统默认，不允许使用",
  "ccmessaging.chat.chatcard.transverse": "横向",
  "ccmessaging.chat.chatcard.longitudinal": "纵向",
  "ccmessaging.chat.selectorgs.maxlimit.warn": "选择的组织数量不能超过100个",
  "ccmessaging.chat.chatconfig.deleteFacebookPage": "删除Facebook主页",
  "ccmessaging.chat.search.name": "名称",
  "ccmessaging.chat.search.phraseName": "常用语",
  "ccmessaging.chat.offline.userIsInService": "已有座席服务用户",
  "ccmessaging.chat.email.attachment.tips.maxOnceUpload": "上传附件数量不得大于5个",
  "ccmessaging.chat.email.attachment.tips.maxTotalUpload": "上传附件个数超过10个",
  "ccmessaging.chat.voice.record": "录音",
  "ccmessaging.chat.channelconfig.search.by.robotNameKeyword": "搜索机器人名称或接入码",
  "ccmessaging.chat.channelconfig.searchSkillQueueName": "搜索技能队列名称",
  "ccmessaging.chat.user.online": "用户已在线",
  "ccmessaging.chat.async.echarts.user": "人数 (次)",
  "ccmessaging.chat.async.echarts.message": "消息 (个)",
  "cobrowse.sessionrecord.roomId": "房间号",
  "cobrowse.sessionrecord.searchroomId": "请输入房间号",
  "cobrowse.sessionrecord.workNo": "座席工号",
  "cobrowse.sessionrecord.searchworkNo": "请输入座席工号",
  "cobrowse.sessionrecord.caller": "主叫信息",
  "cobrowse.sessionrecord.searchcaller": "请输入主叫信息",
  "cobrowse.sessionrecord.startAt": "开始时间",
  "cobrowse.sessionrecord.endAt": "结束时间",
  "cobrowse.sessionrecord.duration": "持续时长",
  "cobrowse.sessionrecord.positiveInteger.checkmsg": "输入值必须为正整数",
  "cobrowse.sensitiveinfo.pageMatchValue": "页面匹配配置",

  "cobrowse.sensitiveinfo.rulenamecheck": "规则名称仅可包含字母数字下划线和点号，长度128位以内",
  "cobrowse.sensitiveinfo.url.check": "页面匹配配置的URL路径格式不正确",
  "cobrowse.sensitiveinfo.cssselectorcheck": "CSS选择器语法错误",
  "cobrowse.sensitiveinfo.pageIdCheck": "页面ID仅可包含字母数字下划线横杠冒号和小数点，长度1024位以内",
  "cobrowse.sensitiveinfo.create": "新建灰化信息规则",
  "cobrowse.sensitiveinfo.edit": "编辑灰化信息规则",
  "cobrowse.sensitiveinfo.createnameexist": "名称已存在",
  "cobrowse.sensitiveinfo.exceedmaxcount": "每个租户最多可配置100条灰化规则",
  "cobrowse.sensitiveinfo.delete": "删除灰化信息规则",
  "cobrowse.sensitiveinfo.willdeletetips": "确定删除所选的灰化信息规则吗？",
  "cobrowse.sensitiveinfo.deletenum.outstripping": "一次至多可以删除一百条数据",
  "cobrowse.common.tips.selectempty": "请先选择要操作的数据",
  "cobrowse.sessionrecord.workNo.rules": "需在1-65535范围内",
  "ccmessaging.chat.chatconfig.batch.delete": "批量删除",
  "ccmessaging.chat.chatconfig.create": "新建",
  "ccmessaging.channelconfig.export.datascope": "数据范围",
  "ccmessaging.channelconfig.export.allscope": "全部",
  "ccmessaging.channelconfig.export.curPage": "当前页",
  "ccmessaging.channelconfig.export.customField": "自定义",
  "ccmessaging.channelconfig.export.selectedField": "导出字段",
  "ccmessaging.channelconfig.export.selectAll": "全选",
  "ccmessaging.channelconfig.export.zipPwd": "压缩密码",
  "ccmessaging.channelconfig.export.zipPwdHint": "请输入压缩密码",
  "ccmessaging.channelconfig.export.pwdMsg": "请输入用于生成压缩文件的密码,8-12位,压缩密码应包含字母,数字,特殊字符,特殊字符不包含{'|;&$-<>/'}{'\\\\'}{'`!#(){}'}空格、换行符、制表符",
  'ccmessaging.channelconfig.export.pwdMsgError': "8-12位,需包含字母,数字,特殊字符,特殊字符不包含{'|;&$-<>/'}{'\\\\'}{'`!#(){}'}空格、换行符、制表符",
  "ccmessaging.channelconfig.export": "导出",
  "ccmessaging.channelconfig.viewExportTask": "导出任务查看",
  "ccmessaging.channelconfig.export.title": "导出渠道配置",
  'ccmessaging.channelconfig.export.exportAll': '您当前未选择具体记录，是否全部导出？您也可以取消后选择具体记录进行导出操作。',
  'ccmessaging.channelconfig.export.exportAllTitle': "导出所有配置",
  "ccmessaging.channelconfig.export.allExportConfirm": "全部导出",
  'ccmessaging.channelconfig.export.exportSelect': "您已选择{0}条记录，是否继续导出？",
  "ccmessaging.channelconfig.export.exportBeginTime": "导出开始时间",
  "ccmessaging.channelconfig.export.exportEndTime": "导出结束时间",
  "ccmessaging.channelconfig.export.exportTaskStatus": "导出任务状态",
  "ccmessaging.channelconfig.export.exporting": "导出中",
  "ccmessaging.channelconfig.export.unprocessed": "未处理",
  'ccmessaging.channelconfig.export.resultMsg': '已成功创建导出任务，是否前往导出列表进行查看？',
  "ccmessaging.channelconfig.export.resultSuccess": "创建成功",
  "ccmessaging.channelconfig.export.exception": "生成导出任务遇到异常，请检查日志",
  "ccmessaging.channelconfig.export.error": "新增导出任务失败,请检查日志",
  "ccmessaging.channelconfig.export.paramInvalid": "导出条件校验失败",
  "ccmessaging.channelconfig.export.exceedlimit": "最多可以导出{0}条数据",
  "ccmessaging.channelconfig.export.nodata": "没有符合条件的渠道配置",
  "ccmessaging.chat.holiday.deleteWorkDay": "删除指定工作日期",
  "ccmessaging.chat.holiday.deleteAllWorkDay": "删除所有指定工作日期",
  "ccmessaging.chat.holiday.deleteHoliday": "删除指定休息日期",
  "ccmessaging.chat.holiday.deleteAllHoliday": "删除所有指定休息日期",
  "ccmessaging.multimedia.leftMenu.category": '分类',
  "ccmessaging.multimedia.leftMenu.createCategory": '新建分类',
  "ccmessaging.multimedia.leftMenu.editCategory": '编辑分类',
  "ccmessaging.multimedia.audio.create": '新建语音',
  "ccmessaging.multimedia.audio.edit": '编辑语音',
  "ccmessaging.multimedia.video.create": '新建视频',
  "ccmessaging.multimedia.video.edit": '编辑视频',
  "ccmessaging.multimedia.image.create": '新建图片',
  "ccmessaging.multimedia.image.edit": '编辑图片',
  "ccmessaging.multimedia.emoticon.create": '新建表情',
  "ccmessaging.multimedia.emoticon.edit": '编辑表情',
  "ccmessaging.multimedia.document.create": '新建文档',
  "ccmessaging.multimedia.document.edit": '编辑文档',
  "ccmessaging.multimedia.richText.create": '新建富文本',
  "ccmessaging.multimedia.richText.edit": '编辑富文本',
  "ccmessaging.multimedia.local.create": '新建地址',
  "ccmessaging.multimedia.local.edit": '编辑地址',
  'ccmessaging.multimedia.whatsAppTemplate.create': '新建WhatsApp模板',
  'ccmessaging.multimedia.whatsAppTemplate.edit': '编辑WhatsApp模板',
  "ccmessaging.multimedia.siteAddr.create": '新建网页协同地址',
  "ccmessaging.multimedia.siteAddr.edit": '编辑网页协同地址',
  "ccmessaging.multimedia.cardFiveG.create": '新建5G卡片',
  "ccmessaging.multimedia.cardFiveG.edit": '编辑5G卡片',
  "ccmessaging.multimedia.carouselCardFiveG.create": '新建5G轮播卡片',
  "ccmessaging.multimedia.carouselCardFiveG.edit": '编辑5G轮播卡片',
  'ccmessaging.multimedia.whatsAppTemplateMsg.create': '新建WhatsApp模板消息',
  'ccmessaging.multimedia.whatsAppTemplateMsg.edit': '编辑WhatsApp模板消息',
  "ccmessaging.multimedia.cardWeb.create": '新建Web卡片',
  "ccmessaging.multimedia.cardWeb.edit": '编辑Web卡片',
  "chat.agent.qualityCheckWorkbench.title": "质检工作台",
  "chat.agent.qualityCheckWorkbench.currentAgent": "当前监控座席",
  "chat.agent.qualityCheckWorkbench.subscribeSessionFailed": "订阅座席会话失败",
  "chat.agent.qualityCheckWorkbench.cancelSubscribeSessionFailed": "取消订阅座席会话失败",
  "chat.agent.qualityCheckWorkbench.pollMsgFailed": "获取座席消息失败",
  "chat.agent.qualityCheckWorkbench.listen": "取消插入",
  "chat.agent.qualityCheckWorkbench.insert": "插入",
  "chat.agent.qualityCheckWorkbench.intercept": "拦截",
  "chat.agent.qualityCheckWorkbench.changeQualityStatus.fail": "修改质检状态失败",
  "chat.agent.msg.redis.connection.exception.description": "当前系统故障,请稍侯",
  "ccmessaging.chat.multimedia.editMultiMediaInfo": "编辑多媒体数据成功！",
  "ccmessaging.chat.multimedia.editMultiMediaInfoError": "编辑多媒体数据失败！",
  "ccmessaging.chat.multimedia.editMultiMediaType": "编辑多媒体库类别成功！",
  "ccmessaging.chat.multimedia.editMultiMediaTypeError": "编辑多媒体库类别失败！",
  "ccmessaging.chat.multimedia.createMultiMediaInfo": "新建多媒体数据成功！",
  "ccmessaging.chat.multimedia.createMultiMediaInfoError": "新建多媒体数据失败！",
  "ccmessaging.chat.multimedia.createMultiMediaType": "新建多媒体类别成功！",
  "ccmessaging.chat.multimedia.createMultiMediaTypeError": "新建多媒体库类别失败！",
  "ccmessaging.chat.phrase.createTypeSuccess": "新建常用语类别成功",
  "ccmessaging.chat.phrase.editPhraseTypeFail": "编辑常用语类别失败",
  "ccmessaging.chat.phrase.editPhraseTypeSucess": "编辑常用语类别成功",
  "ccmessaging.chat.phrase.editPhraseFail": "编辑常用语失败",
  "ccmessaging.chat.phrase.editPhraseSucess": "编辑常用语成功",
  "ccmessaging.chat.multimedia.createVideoInfo": "新建视频数据成功！",
  "ccmessaging.chat.multimedia.createVideoInfoError": "新建视频数据失败！",
  "ccmessaging.chat.multimedia.createVideoType": "新建视频类别成功！",
  "ccmessaging.chat.multimedia.createVideoTypeError": "新建视频类别失败！",
  "ccmessaging.chat.multimedia.editVideoInfo": "编辑视频数据成功！",
  "ccmessaging.chat.multimedia.editVideoInfoError": "编辑视频数据失败！",
  "ccmessaging.chat.multimedia.editVideoType": "编辑视频类别成功！",
  "ccmessaging.chat.multimedia.editVideoTypeError": "编辑视频类别失败！",
  "ccmessaging.chat.multimedia.deleteVideoInfo": "删除视频数据成功！",
  "ccmessaging.chat.multimedia.deleteVideoInfoError": "删除视频数据失败！",
  "ccmessaging.chat.multimedia.deleteVideoType": "删除视频类别成功",
  "ccmessaging.chat.multimedia.deleteVideoTypeError": "删除视频类别失败！",
  "ccmessaging.chat.multimedia.deleteVideoTypeError.usedbyODFS": "删除视频类别失败！存在被智能IVR引用的资源！",
  "ccmessaging.chat.multimedia.deleteVideoTypeError.referenced.byCard": "删除视频类别失败！存在被卡片引用的资源！",
  "ccmessaging.chat.multimedia.deleteVideoTypeError.referenced.byWhatsAppTemplateMsg": "删除视频类别失败！存在被WhatsApp模板消息引用的资源！",
  "ccmessaging.chat.multimedia.createAudioInfo": "新建语音数据成功！",
  "ccmessaging.chat.multimedia.createAudioInfoError": "新建语音数据失败！",
  "ccmessaging.chat.multimedia.createAudioType": "新建语音类别成功！",
  "ccmessaging.chat.multimedia.createAudioTypeError": "新建语音类别失败！",
  "ccmessaging.chat.multimedia.editAudioInfo": "编辑语音数据成功！",
  "ccmessaging.chat.multimedia.editAudioInfoError": "编辑语音数据失败！",
  "ccmessaging.chat.multimedia.editAudioType": "编辑语音类别成功！",
  "ccmessaging.chat.multimedia.editAudioTypeError": "编辑语音类别失败！",
  "ccmessaging.chat.multimedia.deleteAudioInfo": "删除语音数据成功！",
  "ccmessaging.chat.multimedia.deleteAudioInfoError": "删除语音数据失败！",
  "ccmessaging.chat.multimedia.deleteAudioType": "删除语音类别成功",
  "ccmessaging.chat.multimedia.deleteAudioTypeError": "删除语音类别失败！",
  "ccmessaging.chat.multimedia.deleteAudioTypeError.usedbyODFS": "删除语音类别失败！存在被智能IVR引用的资源！",
  "ccmessaging.chat.multimedia.deleteAudioTypeError.referenced.byCard": "删除语音类别失败！存在被卡片引用的资源！",
  "ccmessaging.chat.multimedia.deleteAudioTypeError.referenced.byWhatsAppTemplateMsg": "删除语音类别失败！存在被WhatsApp模板消息引用的资源！",
  "ccmessaging.chat.multimedia.createImageInfo": "新建图片数据成功！",
  "ccmessaging.chat.multimedia.createImageInfoError": "新建图片数据失败！",
  "ccmessaging.chat.multimedia.createImageType": "新建图片类别成功！",
  "ccmessaging.chat.multimedia.createImageTypeError": "新建图片类别失败！",
  "ccmessaging.chat.multimedia.editImageInfo": "编辑图片数据成功！",
  "ccmessaging.chat.multimedia.editImageInfoError": "编辑图片数据失败！",
  "ccmessaging.chat.multimedia.editImageType": "编辑图片类别成功！",
  "ccmessaging.chat.multimedia.editImageTypeError": "编辑图片类别失败！",
  "ccmessaging.chat.multimedia.deleteImageInfo": "删除图片数据成功！",
  "ccmessaging.chat.multimedia.deleteImageInfoError": "删除图片数据失败！",
  "ccmessaging.chat.multimedia.deleteImageType": "删除图片类别成功",
  "ccmessaging.chat.multimedia.deleteImageTypeError": "删除图片类别失败！",
  "ccmessaging.chat.multimedia.deleteImageTypeError.usedbyODFS": "删除图片类别失败！存在被智能IVR引用的资源！",
  "ccmessaging.chat.multimedia.deleteImageTypeError.referenced.byCard": "删除图片类别失败！存在被卡片引用的资源！",
  "ccmessaging.chat.multimedia.deleteImageTypeError.referenced.byWhatsAppTemplateMsg": "删除图片类别失败！存在被WhatsApp模板消息引用的资源！",
  "ccmessaging.chat.multimedia.createDocumentInfo": "新建文档数据成功！",
  "ccmessaging.chat.multimedia.createDocumentInfoError": "新建文档数据失败！",
  "ccmessaging.chat.multimedia.createDocumentType": "新建文档类别成功！",
  "ccmessaging.chat.multimedia.createDocumentTypeError": "新建文档类别失败！",
  "ccmessaging.chat.multimedia.editDocumentInfo": "编辑文档数据成功！",
  "ccmessaging.chat.multimedia.editDocumentInfoError": "编辑文档数据失败！",
  "ccmessaging.chat.multimedia.editDocumentType": "编辑文档类别成功！",
  "ccmessaging.chat.multimedia.editDocumentTypeError": "编辑文档类别失败！",
  "ccmessaging.chat.multimedia.deleteDocumentInfo": "删除文档数据成功！",
  "ccmessaging.chat.multimedia.deleteDocumentInfoError": "删除文档数据失败！",
  "ccmessaging.chat.multimedia.deleteDocumentType": "删除文档类别成功",
  "ccmessaging.chat.multimedia.deleteDocumentTypeError": "删除文档类别失败！",
  "ccmessaging.chat.multimedia.deleteDocumentTypeError.usedbyODFS": "删除文档类别失败！存在被智能IVR引用的资源！",
  "ccmessaging.chat.multimedia.deleteDocumentTypeError.referenced.byCard": "删除文档类别失败！存在被卡片引用的资源！",
  "ccmessaging.chat.multimedia.deleteDocumentTypeError.referenced.byWhatsAppTemplateMsg": "删除文档类别失败！存在被WhatsApp模板消息引用的资源！",
  "ccmessaging.chat.multimedia.createLocateInfo": "新建地址数据成功！",
  "ccmessaging.chat.multimedia.createLocateInfoError": "新建地址数据失败！",
  "ccmessaging.chat.multimedia.createLocateType": "新建地址类别成功！",
  "ccmessaging.chat.multimedia.createLocateTypeError": "新建地址类别失败！",
  "ccmessaging.chat.multimedia.editLocateInfo": "编辑地址数据成功！",
  "ccmessaging.chat.multimedia.editLocateInfoError": "编辑地址数据失败！",
  "ccmessaging.chat.multimedia.editLocateType": "编辑地址类别成功！",
  "ccmessaging.chat.multimedia.editLocateTypeError": "编辑地址类别失败！",
  "ccmessaging.chat.multimedia.deleteLocateInfo": "删除地址数据成功！",
  "ccmessaging.chat.multimedia.deleteLocateInfoError": "删除地址数据失败！",
  "ccmessaging.chat.multimedia.deleteLocateType": "删除地址类别成功",
  "ccmessaging.chat.multimedia.deleteLocateTypeError": "删除地址类别失败！",
  "ccmessaging.chat.multimedia.deleteLocateTypeError.usedbyODFS": "删除地址类别失败！存在被智能IVR引用的资源！",
  "ccmessaging.chat.multimedia.deleteLocateTypeError.referenced.byCard": "删除地址类别失败！存在被卡片引用的资源！",
  "ccmessaging.chat.multimedia.deleteLocateTypeError.referenced.byWhatsAppTemplateMsg": "删除地址类别失败！存在被WhatsApp模板消息引用的资源！",
  "ccmessaging.chat.multimedia.createEmotionInfo": "新建个性化表情数据成功！",
  "ccmessaging.chat.multimedia.createEmotionInfoError": "新建个性化表情数据失败！",
  "ccmessaging.chat.multimedia.createEmotionType": "新建个性化表情类别成功！",
  "ccmessaging.chat.multimedia.createEmotionTypeError": "新建个性化表情类别失败！",
  "ccmessaging.chat.multimedia.editEmotionInfo": "编辑个性化表情数据成功！",
  "ccmessaging.chat.multimedia.editEmotionInfoError": "编辑个性化表情数据失败！",
  "ccmessaging.chat.multimedia.editEmotionType": "编辑个性化表情类别成功！",
  "ccmessaging.chat.multimedia.editEmotionTypeError": "编辑个性化表情类别失败！",
  "ccmessaging.chat.multimedia.deleteEmotionInfo": "删除个性化表情数据成功！",
  "ccmessaging.chat.multimedia.deleteEmotionInfoError": "删除个性化表情数据失败！",
  "ccmessaging.chat.multimedia.deleteEmotionType": "删除个性化表情类别成功",
  "ccmessaging.chat.multimedia.deleteEmotionTypeError": "删除个性化表情类别失败！",
  "ccmessaging.chat.multimedia.deleteEmotionTypeError.usedbyODFS": "删除个性化表情类别失败！存在被智能IVR引用的资源！",
  "ccmessaging.chat.multimedia.deleteEmotionTypeError.referenced.byCard": "删除个性化表情类别失败！存在被卡片引用的资源！",
  "ccmessaging.chat.multimedia.deleteEmotionTypeError.referenced.byWhatsAppTemplateMsg": "删除个性化表情类别失败！存在被WhatsApp模板消息引用的资源！",
  "ccmessaging.chat.multimedia.createRichTextInfo": "新建富文本数据成功！",
  "ccmessaging.chat.multimedia.createRichTextInfoError": "新建富文本数据失败！",
  "ccmessaging.chat.multimedia.createRichTextType": "新建富文本类别成功！",
  "ccmessaging.chat.multimedia.createRichTextTypeError": "新建富文本类别失败！",
  "ccmessaging.chat.multimedia.editRichTextInfo": "编辑富文本数据成功！",
  "ccmessaging.chat.multimedia.editRichTextInfoError": "编辑富文本数据失败！",
  "ccmessaging.chat.multimedia.editRichTextType": "编辑富文本类别成功！",
  "ccmessaging.chat.multimedia.editRichTextTypeError": "编辑富文本类别失败！",
  "ccmessaging.chat.multimedia.deleteRichTextInfo": "删除富文本数据成功！",
  "ccmessaging.chat.multimedia.deleteRichTextInfoError": "删除富文本数据失败！",
  "ccmessaging.chat.multimedia.deleteRichTextType": "删除富文本类别成功",
  "ccmessaging.chat.multimedia.deleteRichTextTypeError": "删除富文本类别失败！",
  "ccmessaging.chat.multimedia.deleteRichTextTypeError.usedbyODFS": "删除富文本类别失败！存在被智能IVR引用的资源！",
  "ccmessaging.chat.multimedia.deleteRichTextTypeError.referenced.byCard": "删除富文本类别失败！存在被卡片引用的资源！",
  "ccmessaging.chat.multimedia.deleteRichTextTypeError.referenced.byWhatsAppTemplateMsg": "删除富文本类别失败！存在被WhatsApp模板消息引用的资源！",
  "ccmessaging.chat.multimedia.createSiteAddrInfo": "新建网页协同地址数据成功！",
  "ccmessaging.chat.multimedia.createSiteAddrInfoError": "新建网页协同地址数据失败！",
  "ccmessaging.chat.multimedia.createSiteAddrType": "新建网页协同地址类别成功！",
  "ccmessaging.chat.multimedia.createSiteAddrTypeError": "新建网页协同地址类别失败！",
  "ccmessaging.chat.multimedia.editSiteAddrInfo": "编辑网页协同地址数据成功！",
  "ccmessaging.chat.multimedia.editSiteAddrInfoError": "编辑网页协同地址数据失败！",
  "ccmessaging.chat.multimedia.editSiteAddrType": "编辑网页协同地址类别成功！",
  "ccmessaging.chat.multimedia.editSiteAddrTypeError": "编辑网页协同地址类别失败！",
  "ccmessaging.chat.multimedia.deleteSiteAddrInfo": "删除网页协同地址数据成功！",
  "ccmessaging.chat.multimedia.deleteSiteAddrInfoError": "删除网页协同地址数据失败！",
  "ccmessaging.chat.multimedia.deleteSiteAddrType": "删除网页协同地址类别成功",
  "ccmessaging.chat.multimedia.deleteSiteAddrTypeError": "删除网页协同地址类别失败！",
  "ccmessaging.chat.multimedia.deleteSiteAddrTypeError.usedbyODFS": "删除网页协同地址类别失败！存在被智能IVR引用的资源！",
  "ccmessaging.chat.multimedia.deleteSiteAddrTypeError.referenced.byCard": "删除网页协同地址类别失败！存在被卡片引用的资源！",
  "ccmessaging.chat.multimedia.deleteSiteAddrTypeError.referenced.byWhatsAppTemplateMsg": "删除网页协同地址类别失败！存在被WhatsApp模板消息引用的资源！",
  "ccmessaging.chat.chatcard.createFiveGCardFail": "新建5G卡片失败",
  "ccmessaging.chat.chatcard.createFiveGCardSuccess": "新建5G卡片成功",
  "ccmessaging.chat.chatcard.editFiveGCardFail": "编辑5G卡片失败",
  "ccmessaging.chat.chatcard.editFiveGCardSuccess": "编辑5G卡片成功",
  "ccmessaging.chat.chatcard.deleteFiveGCardFail": "删除5G卡片失败",
  "ccmessaging.chat.chatcard.deleteFiveGCardSuccess": "删除5G卡片成功",
  "ccmessaging.chat.chatcard.createFiveGCarouselFail": "新建5G轮播卡片失败",
  "ccmessaging.chat.chatcard.createFiveGCarouselSuccess": "新建5G轮播卡片成功",
  "ccmessaging.chat.chatcard.editFiveGCarouselFail": "编辑5G轮播卡片失败",
  "ccmessaging.chat.chatcard.editFiveGCarouselSuccess": "编辑5G轮播卡片成功",
  "ccmessaging.chat.chatcard.deleteFiveGCarouselFail": "删除5G轮播卡片失败",
  "ccmessaging.chat.chatcard.deleteFiveGCarouselSuccess": "删除5G轮播卡片成功",
  "ccmessaging.chat.chatcard.createWhatAppTemplateMeaasgeFail": "新建WhatsApp模板消息失败",
  "ccmessaging.chat.chatcard.createWhatAppTemplateMeaasgeSuccess": "新建WhatsApp模板消息成功",
  "ccmessaging.chat.chatcard.editWhatAppTemplateMeaasgeFail": "编辑WhatsApp模板消息失败",
  "ccmessaging.chat.chatcard.editWhatAppTemplateMeaasgeSuccess": "编辑WhatsApp模板消息成功",
  "ccmessaging.chat.chatcard.deleteWhatAppTemplateMeaasgeFail": "删除WhatsApp模板消息失败",
  "ccmessaging.chat.chatcard.deleteWhatAppTemplateMeaasgeSuccess": "删除WhatsApp模板消息成功",
  "ccmessaging.chat.chatcard.createWebCardFail": "新建Web卡片失败",
  "ccmessaging.chat.chatcard.createWebCardSuccess": "新建Web卡片成功",
  "ccmessaging.chat.chatcard.editWebCardFail": "编辑Web卡片失败",
  "ccmessaging.chat.chatcard.editWebCardSuccess": "编辑Web卡片成功",
  "ccmessaging.chat.chatcard.deleteWebCardFail": "删除Web卡片失败",
  "ccmessaging.chat.chatcard.deleteWebCardSuccess": "删除Web卡片成功",
  "ccmessaging.chat.WhatsApp.createWhatsAppTemplateSuccess": "新建WhatsApp模板成功",
  "ccmessaging.chat.WhatsApp.createWhatsAppTemplateFail": "新建WhatsApp模板失败",
  "ccmessaging.chat.WhatsApp.editWhatsAppTemplateSuccess": "编辑WhatsApp模板成功",
  "ccmessaging.chat.WhatsApp.editWhatsAppTemplateFail": "编辑WhatsApp模板失败",
  "ccmessaging.chat.WhatsApp.deleteWhatsAppTemplateSuccess": "删除WhatsApp模板成功",
  "ccmessaging.chat.WhatsApp.deleteWhatsAppTemplateFail": "删除WhatsApp模板失败",
  "ccmessaging.chat.WhatsApp.deleteWhatsAppTemplateFailMsg": "删除WhatsApp模板失败，该模板被模板消息引用。",
  "ccmessaging.multimedia.download.office.risktips":"您即将下载Office文档，存在一定的安全风险。建议您打开时做好杀毒操作，Office软件的相关安全设置调整为开启状态",
  "ccmessaging.chat.multimedia.documentUploadRiskTips":"该文档用于座席发送给用户，请勿上传包含安全风险的文件",
  "ccmessaging.config.names.channels.web": "Web",
  "ccmessaging.config.names.channels.instagram": "Instagram",
  "ccmessaging.config.names.channels.telegram": "Telegram",
  "ccmessaging.config.names.channels.whatsapp": "WhatsApp",
  "ccmessaging.config.names.channels.line": "LINE",
  "ccmessaging.config.names.channels.wechat": "微信",
  "ccmessaging.config.names.channels.facebook": "Facebook",
  "ccmessaging.config.names.channels.twitter": "X (Twitter)",
  "ccmessaging.config.names.channels.rcs": "5G消息",
  "ccmessaging.config.names.channels.email": "邮件",
  "ccmessaging.config.names.channels.sms": "短信",
  "ccmessaging.config.names.channels.youtube": "YouTube",
  "ccmessaging.chat.multimedia.whatsApp.interactiveMsg":"WhatsApp交互式消息",
  "ccmessaging.chat.whatsapp.interactiveMsgNamePlaceholder":"请输入交互式消息名称",
  "ccmessaging.chat.whatsapp.interactiveMsgName":"消息名称",
  "ccmessaging.chat.whatsapp.interactiveMsgTitle":"标题",
  "ccmessaging.chat.whatsapp.interactiveMsgDescription":"消息描述",
  "ccmessaging.chat.whatsapp.interactiveMsgDescriptionPlaceholder":"请输入交互式消息描述",
  "ccmessaging.chat.whatsapp.interactiveMsgType":"消息类型",
  "ccmessaging.chat.whatsapp.orgName":"组织名称",
  "ccmessaging.chat.chatcard.queryInteractiveMsg":"查询交互式消息失败",
  "ccmessaging.chat.whatsapp.interactiveType.buttons":"按钮消息",
  "ccmessaging.chat.whatsapp.interactiveType.list":"列表消息",
  "ccmessaging.chat.whatsapp.delete.interactiveMsg":"删除交互式消息",
  "ccmessaging.chat.whatsapp.willdeleteinteractiveMsg":"是否确定要删除此交互式消息?",
  "ccmessaging.chat.whatsapp.interactiveTypes":"交互式消息类型",
  "ccmessaging.chat.whatsapp.interactiveMsgTitlePlaceholder":"请输入交互式标题内容",
  "ccmessaging.chat.whatsapp.interactiveMsg.bodyContent":"正文内容",
  "ccmessaging.chat.whatsapp.interactiveMsg.bodyContentPlaceholder":"请输入正文内容",
  "ccmessaging.chat.whatsapp.interactiveMsg.footerContent":"页脚内容",
  "ccmessaging.chat.whatsapp.interactiveMsg.footerContentPlaceholder":"请输入页脚内容",
  "ccmessaging.chat.whatsapp.interactiveMsg.pleaseChoose":"请选择",
  "ccmessaging.chat.whatsapp.interactiveMsg.attachments":"附件",
  "ccmessaging.chat.multimedia.whatsApp.interactiveMsg.create":"新建交互式消息",
  "ccmessaging.chat.multimedia.whatsApp.interactiveMsg.update":"修改交互式消息",
  "ccmessaging.chat.whatsapp.interactiveType.list.title":"列表",
  "ccmessaging.chat.whatsapp.interactiveType.list.titlePlaceholder":"请输入列表标题",
  "ccmessaging.chat.whatsapp.interactiveMsg.openRow.info":"分组标题为空，不能设置选项",
  "ccmessaging.chat.whatsapp.interactiveType.section.titlePlaceholder":"请输入分组标题",
  "ccmessaging.chat.whatsapp.interactiveType.row.titlePlaceholder":"请输入选项标题",
  "ccmessaging.chat.whatsapp.interactiveType.row.descPlaceholder":"请输入选项描述",
  "ccmessaging.chat.whatsapp.interactiveMsg.sectionTitle":"请输入列表标题",
  "ccmessaging.chat.whatsapp.interactiveMsg.queryInteractiveError":"查询交互式消息失败",
  "ccmessaging.chat.whatsapp.interactiveMsg.createInteractiveError":"保存交互式消息失败，列表设置分组后，分组选项不能为空",
  "ccmessaging.chat.whatsapp.interactiveMsg.saveFail.duplicateName":"保存失败，该交互式消息名称已存在",
  "ccmessaging.chat.whatsapp.interactiveMsg.noHeader":"无",
  "ccmessaging.chat.whatsapp.interactiveMsg.headerContent":"标题内容",
  "ccmessaging.chat.whatsapp.interactiveMsg.no.buttons":"保存交互式消息失败，没有设置按钮",
  "ccmessaging.chat.whatsapp.interactiveMsg.no.lists":"保存交互式消息失败，没有设置列表",
  "ccmessaging.chat.multimedia.deleteLimit.whatsapp.interactiveMsg":"此资源已被whatsApp交互式消息引用，不允许删除。交互式消息名称：",
  "ccmessaging.chat.whatsapp.interactiveMsg.saveFail.multiMedia.notExist":"保存失败，多媒体资源不存在",
  "ccmessaging.chat.whatsapp.interactiveMsg.createInteractiveError.repeateButtonContent":"保存失败，按钮内容不能重复",
  "ccmessaging.chat.case.title":"工单标题",
  "ccmessaging.chat.case.type":"工单类型",
  "ccmessaging.chat.case.callReason":"来电原因",
  "ccmessaging.chat.case.puzzled":"疑问不解",
  "ccmessaging.chat.case.calm":"平静",
  "ccmessaging.chat.case.happy":"开心",
  "ccmessaging.chat.case.upset":"烦躁",
  "ccmessaging.chat.case.surprise":"惊讶",
  "ccmessaging.chat.case.pleasant":"愉快",
  "ccmessaging.chat.case.angry":"恼火",
  "ccmessaging.chat.case.little.angry":"有一点不满",
  "ccmessaging.chat.case.very.angry":"非常不满",
  "ccmessaging.chat.case.complaint.yes":"会",
  "ccmessaging.chat.case.complaint.shi":"是",
  "ccmessaging.chat.case.very.knowledgeId":"1223872757297582080",
  "ccmessaging.chat.degree.view":"360视图",
}
