export default {
  'oifde.index.title': 'ODFS-在线对话流程服务',
  'oifde.common.help': '查看帮助',
  'oifde.common.select': '请选择',
  'oifde.common.selectPlaceHolder': '请输入关键字',
  'oifde.common.lang': '语言',
  'oifde.common.language.chinese': '中文',
  'oifde.common.language.english': '英文',
  'oifde.common.time.startDate': '开始日期',
  'oifde.common.time.endDate': '结束日期',
  'oifde.common.browserSupporteMsg': '浏览器不支持',
  'oifde.common.validation.required': '必填',
  'oifde.common.validation.extendFaqRange': '仅允许输入汉字，字母，空格，数字，冒号，问号和()[]{\'@\'}{\'|\'}{\'{\'}{\'}\'}，,。.\'_-',
  'oifde.common.validation.nameRange': '不允许输入特殊符号<>!{\'@\'}#{\'$\'}%^&*?;"~/\\',
  'oifde.common.validation.cellNameRange': '不允许输入特殊符号<>{\'@\'}#{\'$\'}%^*;"~/\\',
  'oifde.shortMessage.validation.text': '不允许输入特殊符号`~{\'@\'}^#%&*/=+{\'{\'}{\'}\'};"<.>?[]',
  'oifde.common.validation.nameNoSpace': '开头和结尾不能是空格',
  'oifde.common.validation.nodeNameCharReg': '允许输入字母，数字，中文，下划线和连字符，开头结尾不允许空格',
  'oifde.common.validation.nameCharRegNotChinese': '只允许输入字母，数字，下划线和连字符',
  'oifde.common.validation.nameCharReg': '只允许输入字母，数字，中文，下划线和连字符',
  'oifde.common.validation.schemaNameCharReg': '只允许字母，数字，中文，空格，下划线和英文单引号',
  'oifde.common.validation.schemaRelationNameCharReg': '只允许字母，数字，中文，空格和英文单引号',
  'oifde.common.validation.openNameCharReg': '只允许字母，数字，中文，空格，下划线，连字符和英文单引号',
  'oifde.common.validation.kgNameCharReg': '只允许字母，数字，中文，空格，下划线，连字符，逗号，英文单引号，分号，点 和 {\'@\'} ',
  'oifde.common.validation.charRegWithDot': '只允许输入字母，数字，中文，小数点，下划线和连字符',
  'oifde.common.validation.int': '只允许输入整数，整数范围0-2147483647',
  'oifde.common.validation.min': '数值不能小于{0}',
  'oifde.common.validation.max': '数值不能大于{0}',
  'oifde.common.validation.collectTimeoutRule': '支持FLOW和GLOBAL变量,也支持输入整数，范围[1-120],不符合取默认值5',
  'oifde.common.validation.numberRange': '数值范围{0}-{1}',
  'oifde.common.validation.sessionDTRange': '对话流程超时时间为正整数，不得小于{0}秒，不超过{1}秒',
  'oifde.common.validation.slientHelpRange': '等待静默座席回复时间为正整数，不得小于{0}秒，不超过{1}秒',
  'oifde.common.validation.confidenceRange': '置信度阈值不得小于{0}，不超过{1}',
  'oifde.common.validation.flowSaveIntervalRange': 'IVR流程编排定时保存间隔为正整数，不得小于{0}分钟，不超过{1}分钟',
  'oifde.common.validation.urlValidate': 'url格式错误！',
  'oifde.common.validation.appSecretLenCheck': '共享密钥长度必须在8到20之间',
  'oifde.common.validation.positiveInt': '只允许输入正整数',
  'oifde.common.validation.digits': '输入值必须为0或正整数',
  'oifde.common.validation.numbers': '请输入纯数字',
  'oifde.common.validation.validateFalse': '不允许输入下列特殊字符： ~ ` ! # {\'$\'} % ^ & * ( ) + = [ ] {\'{\'}{\'}\'} \\ {\'|\'} \' " : < > / ?',
  'oifde.common.validation.mrcpIdValueFalse': '请输入0-255的正整数',
  'oifde.common.validation.flowMaxElementsRange': 'IVR流程支持的最大图元数量为正整数，不得小于1个，不超过200个',
  'oifde.common.dialog.confirm.title': '提示',
  'oifde.common.dialog.confirm.content': '此操作将永久删除该资源，是否继续？',
  'oifde.common.dialog.confirm.buttonSure': '确认',
  'oifde.common.dialog.confirm.buttonCancel': '取消',
  'oifde.common.dialog.confirm.successInfo': '删除成功！',
  'oifde.common.dialog.confirm.cancelInfo': '已取消删除！',
  'oifde.common.dialog.confirm.operationSucc': '操作成功！',
  'oifde.common.dialog.confirm.operation_failed': '操作失败！',
  'oifde.common.actions.add': '添加',
  'oifde.common.actions.edit': '编辑',
  'oifde.common.actions.delete': '删除',
  'oifde.common.actions.download': '下载',
  'oifde.common.detail': '详情',
  'oifde.common.noData': '无数据',
  'oifde.serviceInterface.oifdeInterface': '智能机器人接口',
  'oifde.serviceInterface.ccivrInterface': '普通IVR接口',
  'oifde.serviceInterface.selfDefinedInterface': '自定义接口',
  'oifde.serviceInterface.innerInterface': '内置接口',
  'oifde.serviceInterface.jsonValidateSuccess': '格式正确。',
  'oifde.serviceInterface.jsonChek': 'JSON格式校验',
  'oifde.serviceInterface.interfaceCert': '接口证书',
  'oifde.serviceInterface.certCode': '编码',
  'oifde.serviceInterface.certScene': '场景',
  'oifde.serviceInterface.expireTime': '过期时间',
  'oifde.serviceInterface.certUpdateTime': '更新时间',
  'oifde.serviceInterface.jsonValidate': '消息体格式非标准Json格式，请注意排查格式问题',
  'oifde.serviceInterface.safeWarning': '*请注意，当前输入的接口所使用的网络协议不是安全协议，如确定使用，可能存在安全风险',
  'oifde.serviceInterface.whiteListCheckWarning': '*请注意，接口URL中的IP、端口或域名不在白名单中，需要联系系统管理员添加后才能正常调用',
  'oifde.serviceInterface.interfaceName': '接口名称',
  'oifde.serviceInterface.bizCode': '业务代码',
  'oifde.serviceInterface.description': '描述',
  'oifde.serviceInterface.updatetime': '更新时间',
  'oifde.serviceInterface.operation': '操作',
  'oifde.serviceInterface.addServiceInterface': '新增业务接口配置',
  'oifde.serviceInterface.modifyServiceInterface': '编辑业务接口配置',
  'oifde.serviceInterface.serviceInterfaceDetails': '业务接口配置详情',
  'oifde.serviceInterface.innerInterfaceDetails': '内置接口详情',
  'oifde.serviceInterface.baseInfo': '基本信息',
  'oifde.serviceInterface.reqURL': '请求URL',
  'oifde.serviceInterface.signBody': '是否签名',
  'oifde.serviceInterface.signBodyDetail.no': '否',
  'oifde.serviceInterface.signBodyDetail.yes': '是',
  'oifde.serviceInterface.signDescription': 'Sign签名作为接口参数传给第三方，第三方需做Sign签名验证',
  'oifde.serviceInterface.appSecret': '共享密钥',
  'oifde.serviceInterface.inputAppSecret': '请输入共享密钥',
  'oifde.serviceInterface.busiCode': '接口代码',
  'oifde.serviceInterface.interfaceType': '请求方法',
  'oifde.serviceInterface.required': '必填项',
  'oifde.serviceInterface.query_param': '查询参数',
  'oifde.serviceInterface.pathParamValue': '参数值',
  'oifde.serviceInterface.outParamPath': '参数路径',
  'oifde.serviceInterface.in_param': '入参',
  'oifde.serviceInterface.out_param': '出参',
  'oifde.serviceInterface.name': '名称',
  'oifde.serviceInterface.dataType': '数据类型',
  'oifde.serviceInterface.encryptFlag': '是否加密',
  'oifde.serviceInterface.dataTypes.INT': '整型',
  'oifde.serviceInterface.dataTypes.STRING': '字符串',
  'oifde.serviceInterface.dataTypes.FLOAT': '浮点型',
  'oifde.serviceInterface.dataTypes.LONG': '长整型',
  'oifde.serviceInterface.dataTypes.LIST': '列表',
  'oifde.serviceInterface.dataTypes.OBJECT': '对象',
  'oifde.serviceInterface.msgHeaders': '消息头',
  'oifde.serviceInterface.msgBody': '消息体',
  'oifde.serviceInterface.headerKey': '键',
  'oifde.serviceInterface.headerValue': '值',
  'oifde.serviceInterface.deleteConfirm': '此操作将永久删除该服务接口配置, 是否继续?',
  'oifde.serviceInterface.miss_ifs_name': '缺少必选字段：基本信息->接口名称。',
  'oifde.serviceInterface.prompt': '提示',
  'oifde.serviceInterface.ok': '确定',
  'oifde.serviceInterface.miss_ifs_request_url': '缺少必选字段：基本信息->请求URL。',
  'oifde.serviceInterface.miss_ifs_in_parameter_name': '缺少必选字段：入参->参数名称。',
  'oifde.serviceInterface.miss_ifs_out_parameter_name': '缺少必选字段：出参->参数名称。',
  'oifde.serviceInterface.miss_ifs_out_parameter_type': '缺少必选字段：出参->参数类型。',
  'oifde.serviceInterface.miss_ifs_header_key': '缺少必选字段：消息头->键。',
  'oifde.serviceInterface.miss_ifs_msgBody': '缺少必选字段：消息体',
  'oifde.serviceInterface.auto_msgBody': '自动生成消息体',
  'oifde.serviceInterface.manual_msgBody': '手动输入消息体',
  'oifde.serviceInterface.promptMessage': '请输入消息体',
  'oifde.serviceInterface.actions.cancelSuccess': '已取消保存！',
  'oifde.serviceInterface.actions.auto_savePrompt': '此操作将「自动生成消息体」保存为业务接口配置，是否继续？',
  'oifde.serviceInterface.actions.urlNotInWhitelist': 'URL中的IP和端口未添加白名单，需要联系系统管理员增加白名单后才能正常使用，是否继续？',
  'oifde.serviceInterface.actions.manual_savePrompt': '此操作将「手动输入消息体」保存为业务接口配置，是否继续？',
  'oifde.serviceInterface.actions.prompt': '提示',
  'oifde.serviceInterface.actions.confirm': '确认',
  'oifde.serviceInterface.actions.cancel': '取消',
  'oifde.qarecord.interface.name': '记录问答',
  'oifde.qarecord.interface.desc': '用于记录问卷标识、问题标题、用户回答意图、用户原始答等信息',
  'oifde.apiToken.interface.name': '获取APIFabric token',
  'oifde.apiToken.interface.desc': '用于获取APIFabric token的接口',
  'oifde.apiToken.applyType.desc': 'token的前缀',
  'oifde.apiToken.accessToken.desc': 'token的内容',
  'oifde.apiToken.appKey.desc': 'APP标识',
  'oifde.apiToken.appSecret.desc': 'APP密钥',
  'oifde.qarecord.inputparam.seriesId': '问答的系列标识',
  'oifde.qarecord.inputparam.questionTitle': '问题标题',
  'oifde.qarecord.inputparam.questionType': '问题类型',
  'oifde.qarecord.inputparam.answerIntent': '回答意图',
  'oifde.qarecord.inputparam.originalAnswer': '原始答',
  'oifde.sysMgr.sysCfg': '系统设置',
  'oifde.sysMgr.apiKeyCfg': 'API口令设置',
  'oifde.sysMgr.configuration': '设置',
  'oifde.sysMgr.apiKey': 'API口令',
  'oifde.sysMgr.apiKeyComfirm': 'API口令确认',
  'oifde.sysMgr.apiKeyNotSame': '两次输入API口令不一致',
  'oifde.sysMgr.inputKey': '请输入口令',
  'oifde.sysMgr.cancel': '取消',
  'oifde.sysMgr.save': '保存',
  'oifde.sysMgr.apiRuleRange': '口令不符合规范，必须为数字、大写字母、小写字母或特殊字符中的2种或以上组合，特殊字符范围是 ~`!{\'@\'}{\'$\'}{\'|\'}{\'{\'}{\'}\'}#%^*()-+_=,./<>?;\':"[]&',
  'oifde.sysMgr.apiNoSame': '口令不能与租间ID相同',
  'oifde.sysMgr.success_set_key': '设置API口令成功！',
  'oifde.sysMgr.requiredAPIKey': 'API口令必填',
  'oifde.sysMgr.APIKeyLenCheck': 'API口令至少包含16个字符，最大不超过64个字符',
  'oifde.sysMgr.success_set_rpa': '设置RPA访问信息成功！',
  'oifde.sysMgr.requiredRpaUserName': 'RPA用户帐号必填',
  'oifde.sysMgr.requiredRpaSecretKey': 'RPA用户口令必填',
  'oifde.sysMgr.rpaUserNameLenCheck': 'RPA用户帐号长度不能超过64',
  'oifde.sysMgr.rpaSecretKeyLenCheck': 'RPA用户口令长度不能超过128',
  'oifde.sysMgr.inputRpaUserName': '请输入帐号',
  'oifde.sysMgr.inputRpaSecretKey': '请输入口令',
  'oifde.sysMgr.dataClean.setTime': '数据清理时间设置',
  'oifde.sysMgr.dataClean.serviceName': '参数名称',
  'oifde.sysMgr.dataClean.paramValue': '参数值',
  'oifde.sysMgr.dataClean.description': '描述',
  'oifde.sysMgr.dataClean.descriptionContent': '设置数据清理的时间',
  'oifde.sysMgr.dataClean.tcaContent': '文本分析绑定的领域列表',
  'oifde.sysMgr.dataClean.editTcaFlag': '编辑文本分析绑定的领域列表',
  'oifde.sysMgr.dataClean.operation': '操作',
  'oifde.sysMgr.dataClean.editTime': '修改数据清理时间',
  'oifde.sysMgr.dataClean.day': '天',
  'oifde.sysMgr.dataClean.hour': '小时',
  'oifde.sysMgr.dataClean.minute': '分钟',
  'oifde.sysMgr.dataClean.flagDescription': '智能IVR通话流程是否开启录音的标识',
  'oifde.sysMgr.dataClean.editFlag': '编辑智能IVR通话流程录音标识',
  'oifde.sysMgr.dataClean.recordThirdApi': '是否开启调用第三方接口日志标识',
  'oifde.sysMgr.dataClean.editInterfaceFlag': '编辑调用第三方接口日志标识',
  'oifde.sysMgr.dataClean.ccvirrecordFlag': '普通IVR通话流程是否开启录音的标识',
  'oifde.sysMgr.dataClean.editCcvirrecordFlag': '编辑普通IVR通话流程录音标识',
  'oifde.sysMgr.dataClean.paramValueTca': '参数值(领域列表)',
  'oifde.sysMgr.dataClean.open': '开启',
  'oifde.sysMgr.dataClean.close': '关闭',
  'oifde.sysMgr.dataClean.record': '记录',
  'oifde.sysMgr.dataClean.noRecord': '不记录',
  'oifde.sysMgr.dataClean.second': '秒',
  'oifde.sysMgr.dataClean.reportFlagDesc': '是否将测试用例记录到报表',
  'oifde.sysMgr.dataClean.reportFlagTitle': '编辑记录测试用例标识',
  'oifde.sysMgr.dataClean.sessionDTDesc': '对话流程超时时间',
  'oifde.sysMgr.dataClean.editSessionDT': '编辑对话流程超时时间',
  'oifde.sysMgr.dataClean.slientHelpDesc': '等待静默座席回复时间',
  'oifde.sysMgr.dataClean.editSlientHelp': '编辑等待静默座席回复时间',
  'oifde.sysMgr.dataClean.confidenceDesc': '机器人单次求助静默座席识别置信度阈值',
  'oifde.sysMgr.dataClean.traceLogAutoCloseDesc': '轨迹跟踪设置自动关闭时间',
  'oifde.sysMgr.dataClean.confidence': '编辑置信度',
  'oifde.sysMgr.dataClean.traceLogAutoClose': '编辑轨迹跟踪设置自动关闭时间',
  'oifde.sysMgr.dataClean.flowIvrSaveFlagDesc': 'IVR流程编排是否开启定时保存的标识',
  'oifde.sysMgr.dataClean.editFlowSaveFlag': '编辑IVR流程编排定时保存标识',
  'oifde.sysMgr.dataClean.flowIvrSaveIntervalDesc': 'IVR流程编排定时保存时间间隔',
  'oifde.sysMgr.dataClean.editFlowSaveInterval': '编辑IVR流程编排定时保存时间间隔',
  'oifde.sysMgr.dataClean.flowMaxElementsDesc': 'IVR流程支持的最大图元数量',
  'oifde.sysMgr.dataClean.editFlowMaxElements': '编辑IVR流程支持的最大图元数量',
  'oifde.sysMgr.encryptSecretCfg': '秘钥设置',
  'oifde.sysMgr.encryptSecret': '秘钥',
  'oifde.sysMgr.encryptSecretComfirm': '秘钥确认',
  'oifde.sysMgr.encryptSecretNotSame': '两次输入秘钥不一致',
  'oifde.sysMgr.inputEncryptSecret': '请输入秘钥',
  'oifde.sysMgr.success_set_encryptSecret': '设置秘钥成功！',
  'oifde.sysMgr.encryptSecretRuleRange': '秘钥不符合规范，必须为数字、大写字母、小写字母或特殊字符中的2种或以上组合，特殊字符范围是 ~`!{\'@\'}#{\'$\'}%^*()-+_={\'|\'},./<>?;\':"[]{\'{\'}{\'}\'}&',
'oifde.sysMgr.encryptSecretNoSame': '秘钥不能与租间ID相同',
  'oifde.sysMgr.encryptSecretLenCheck': '秘钥长度为16个字符',
  'oifde.sysMgr.parameterSetting': '智能引擎参数配置',
  'oifde.sysMgr.variableSecretNotExists': '变量使用加密类型，请先前往系统管理->系统设置->秘钥设置进行秘钥设置',
  'oifde.sysMgr.rpaCfg': 'RPA设置',
  'oifde.sysMgr.rpaUserNameCfg': 'RPA用户帐号',
  'oifde.sysMgr.rpaSecretKeyCfg': 'RPA用户口令',
  'oifde.route.flow': '流程编排',
  'oifde.route.rule': '流程灰度规则',
  'oifde.route.trace': '流程轨迹',
  'oifde.route.flowAnalysis': '流程分析',
  'oifde.route.ivrOverview': 'IVR概览',
  'oifde.route.indicatorAnalysis': '指标分析',
  'oifde.route.versionComparisonAndAnalysis': '版本对比分析',
  'oifde.route.optimizationSuggestions': '业务优化建议',
  'oifde.route.alarmEvent': '告警事件',
  'oifde.route.addDiagram': '新建流程',
  'oifde.route.editCellDiagram': '编辑复合画布',
  'oifde.route.edit': '编辑{0}',
  'oifde.route.view': '查看{0}',
  'oifde.route.editDiagram': '编辑流程',
  'oifde.route.viewDiagram': '查看流程',
  'oifde.route.viewTraceDiagram': '查看流程轨迹',
  'oifde.route.viewCellTraceDiagram': '查看复合图元轨迹',
  'oifde.route.voiceTemplateTitle': '资源模板',
  'oifde.route.staticCheck': '流程检查',
  'oifde.route.toc.sysadmin': '语义理解管理',
  'oifde.route.toc.user': '语义理解服务',
  'oifde.route.unusedResource.complexcell': '查看未使用复合图元',
  'oifde.route.unusedResource.interface': '查看未使用接口',
  'oifde.route.unusedResource.procedure': '查看未使用存储过程',
  'oifde.route.unusedResource.rule': '查看未使用灰度规则',
  'oifde.route.unusedResource.template': '查看未使用模板',
  'oifde.route.unusedResource.globalvars': '查看未使用全局变量',
  'oifde.route.unusedResource.flowvars': '查看未使用流程变量',
  'oifde.route.tuc.template.index': '知识管理',
  'oifde.route.tuc.template.domain': '领域管理',
  'oifde.route.tuc.template.intent': '意图管理',
  'oifde.route.tuc.template.entity': '实体管理',
  'oifde.route.tuc.template.faq': '问答管理',
  'oifde.route.tuc.template.kg': '图谱管理',
  'oifde.route.tuc.template.config': '系统配置',
  'oifde.route.tuc.template.bwlist': '黑白名单',
  'oifde.route.tuc.history.index': '历史对话',
  'oifde.route.tuc.history.dialog': '历史消息审核',
  'oifde.route.tuc.history.mining': '问题聚类任务',
  'oifde.route.tuc.ops.index': '检查训练',
  'oifde.route.tuc.ops.test': '对话测试',
  'oifde.route.tuc.ops.tucTest': '知识测试',
  'oifde.route.tuc.ops.perf': '性能统计',
  'oifde.route.tuc.ops.node': '维护管理',
  'oifde.route.tuc.ops.log': '操作日志',
  'oifde.route.tuc.ops.corpustest': '语料测试',
  'oifde.route.tuc.ops.server': '服务器管理',
  'oifde.route.tuc.ops.qiAsrServer': '质检管理',
  'oifde.route.tuc.ops.asr': 'TTS/ASR管理',
  'oifde.route.tuc.ops.avatar': '2D数字人服务管理',
  'oifde.route.tuc.ops.modelMgr': '模型管理',
  'oifde.route.tuc.ops.sysparam': '智能引擎参数',
  'oifde.route.tuc.ops.kgrule': '知识灰度规则',
  'oifde.route.tuc.ops.license': '许可管理',
  'oifde.route.keyPressRobot': '普通IVR',
  'oifde.route.RobotManage': '智能机器人',
  'oifde.route.accessCodeManage': '接入码',
  'oifde.route.sysMgr.sysMgr': '系统管理',
  'oifde.route.sysMgr.sysCfg': '系统设置',
  'oifde.route.sysMgr.whiteList': '白名单',
  'oifde.route.sysMgr.recordHistory': '呼叫历史管理',
  'oifde.route.navigation': '流程管理',
  'oifde.route.resources': '资源管理',
  'oifde.route.serviceInterface': '业务接口',
  'oifde.route.variable': '变量管理',
  'oifde.route.cell': '复合图元',
  'oifde.route.procedure': '存储过程',
  'oifde.route.workDay': '工作日历',
  'oifde.route.dataTable': '数据表',
  'oifde.route.knowledgegraph.knowledgegraph': '知识图谱',
  'oifde.route.knowledgegraph.kgModel': '知识建模',
  'oifde.route.knowledgegraph.editSchema': 'Schema-{0}详情',
  'oifde.route.knowledgegraph.dicmgr': '图谱字典',
  'oifde.route.knowledgegraph.entitymgr': '{0}-实体管理',
  'oifde.route.knowledgegraph.entityAttribute': '{0}-实体属性',
  'oifde.route.knowledgegraph.relationmgr': '{0}-关系管理',
  'oifde.route.knowledgegraph.relationAttribute': '{0}-关系属性',
  'oifde.route.knowledgegraph.kgView': '{0}-图谱可视',
  'oifde.route.knowledgegraph.kgExport': '图谱导出',
  'oifde.route.knowledgegraph.importExcel': '图谱导入',
  'oifde.route.knowledgegraph.kgFAQ': '{0}-图谱问答',
  'oifde.route.knowledgegraph.kgSynonyms': '{0}-同义词',
  'oifde.route.knowledgegraph.extendQuestion': '扩展问',
  'oifde.flow.flowTypeSelect': '新建流程模板选择',
  'oifde.flow.flowTemplate': '流程模板',
  'oifde.flow.code': '编码',
  'oifde.flow.name': '名称',
  'oifde.flow.version': '版本',
  'oifde.flow.scenarioType': '场景类型',
  'oifde.flow.status': '状态',
  'oifde.flow.rule': '灰度规则',
  'oifde.flow.createTime': '创建时间',
  'oifde.flow.updateTime': '修改时间',
  'oifde.flow.grayFlow': '灰度',
  'oifde.flow.noRule': '未配置规则',
  'oifde.flow.defaultFlow': '默认流程',
  'oifde.flow.actions.publish': '发布',
  'oifde.flow.actions.delete': '删除',
  'oifde.flow.actions.edit': '编辑',
  'oifde.flow.actions.invalid': '失效',
  'oifde.flow.actions.upgrade': '升级',
  'oifde.flow.actions.copy': '复制流程',
  'oifde.flow.actions.copySuccess': '复制流程成功！',
  'oifde.flow.actions.copyFailed': '复制流程失败！',
  'oifde.flow.actions.setting': '设置',
  'oifde.flow.actions.deleteConfirm': '此操作将永久删除该流程, 是否继续?',
  'oifde.flow.actions.deleteCancel': '已取消删除！',
  'oifde.flow.actions.deleteOk': '删除成功！',
  'oifde.flow.actions.invalidConfirm': '此操作将该流程取消发布, 是否继续?',
  'oifde.flow.actions.invalidOk': '流程已成功取消发布！',
  'oifde.flow.actions.invalidCancel': '已取消失效！',
  'oifde.flow.actions.createOk': '创建新版本流程成功！',
  'oifde.flow.actions.settingOk': '流程设置成功！',
  'oifde.flow.actions.publishOk': '发布成功！',
  'oifde.flow.actions.confirm': '确定',
  'oifde.flow.actions.prompt': '提示',
  'oifde.flow.actions.cancel': '取消',
  'oifde.flow.actions.staticcheck': '静态检查',
  'oifde.flow.actions.error': '操作失败！',
  'oifde.flow.actions.errorCode': '错误码',
  'oifde.flow.actions.errorMessage': '错误信息',
  'oifde.flow.rules.ruleId': '规则必填',
  'oifde.flow.rules.upgradeMode': '发布方式必填',
  'oifde.flow.rules.logSwitchOn': '日志开关必填',
  'oifde.flow.rules.validationMode': '严格校验必填',
  'oifde.flow.rules.newVersion': '新版本必填',
  'oifde.flow.rules.newVersionDuplicated': '新版本不能重复',
  'oifde.flow.rules.newVersionLength': '新版本长度不能超过64位',
  'oifde.flow.rules.newFlowName': '新流程名称必填',
  'oifde.flow.rules.workMode': '发布模式必选',
  'oifde.flow.enums.scenarioType.mainFlow': '主流程',
  'oifde.flow.enums.scenarioType.errorFlow': '异常流程',
  'oifde.flow.enums.scenarioType.subFlow': '子流程',
  'oifde.flow.enums.status.init': '草稿',
  'oifde.flow.enums.status.valid': '发布',
  'oifde.flow.enums.status.invalid': '失效',
  'oifde.flow.enums.tabsValue.all': '全部导出',
  'oifde.flow.enums.tabsValue.none': '不导出',
  'oifde.flow.enums.tabsValue.depend': '依赖导出',
  'oifde.flow.publish.flowName': '流程名称',
  'oifde.flow.publish.flowVersion': '流程版本',
  'oifde.flow.publish.upgradeMode': '发布方式',
  'oifde.flow.publish.upgrade': '升级发布',
  'oifde.flow.publish.gray': '灰度发布',
  'oifde.flow.publish.ruleName': '灰度规则',
  'oifde.flow.publish.selectPlaceholder': '请选择',
  'oifde.flow.publish.publishMode': '发布模式',
  'oifde.flow.publish.normal': '普通',
  'oifde.flow.publish.monitor': '监控',
  'oifde.flow.publish.insert': '插入',
  'oifde.flow.publish.notConfig': '未配置',
  'oifde.flow.copy.flowName': '原流程名称',
  'oifde.flow.copy.newFlowName': '新流程名称',
  'oifde.flow.setting.logSwitch': '业务日志开关',
  'oifde.flow.setting.logSwitchOn': '开启',
  'oifde.flow.setting.logSwitchOff': '关闭',
  'oifde.flow.setting.title': '设置',
  'oifde.flow.setting.validationMode': '严格校验',
  'oifde.flow.setting.validationModeOn': '开启',
  'oifde.flow.setting.validationModeOff': '关闭',
  'oifde.flow.upgrade.currentVersions': '当前版本列表',
  'oifde.flow.upgrade.newVersion': '新版本',
  'oifde.flow.exportFlowList.accessCode': '接入码',
  'oifde.flow.exportFlowList.rule': '规则',
  'oifde.flow.exportFlowList.template': '模板',
  'oifde.flow.exportFlowList.interface': '接口',
  'oifde.flow.exportFlowList.variable': '变量',
  'oifde.flow.exportFlowList.title': '导出',
  'oifde.flow.exportFlowList.askExportAll': '您当前未选择具体记录，是否全部导出？您也可以取消后选择具体记录进行导出操作。',
  'oifde.flow.exportFlowList.prompt': '提示',
  'oifde.flow.exportFlowList.promptMessage': '请至少选择一条记录',
  'oifde.flow.exportFlowList.export': '导出',
  'oifde.flow.exportFlowList.exportAll': '全部导出',
  'oifde.flow.exportFlowList.procedure': '存储过程',
  'oifde.flow.exportFlowList.complexCell': '复合图元',
  'oifde.flow.importFlow.title': '导入流程',
  'oifde.flow.importFlow.select': '选取文件',
  'oifde.flow.importFlow.upload': '上传',
  'oifde.flow.importFlow.prompt': '支持导入ZIP格式',
  'oifde.flow.importFlow.cover': '允许重名覆盖',
  'oifde.flow.importFlow.resultTitle': '导入结果',
  'oifde.flow.importFlow.name': '名称',
  'oifde.flow.importFlow.type': '类型',
  'oifde.flow.importFlow.result': '结果',
  'oifde.flow.importFlow.error': '错误',
  'oifde.flow.importFlow.message': '请选择上传的文件',
  'oifde.flow.importFlow.fileType': '上传附件只支持zip格式',
  'oifde.flow.importFlow.import': '导入',
  'oifde.flow.importFlow.success': '成功',
  'oifde.flow.importFlow.bigFile': '上传文件过大！',
  'oifde.flow.importFlow.uploadFail': '上传失败！',
  'oifde.flow.importFlow.bigFilePrompt': '上传文件大于20M！',
  'oifde.flow.importFlow.timeout': '数据导入中，请稍后查询最新数据',
  'oifde.flow.usage.usageStatus': '统计状态：',
  'oifde.flow.usage.lasttime': '上次完成时间：',
  'oifde.flow.usage.check': '检查',
  'oifde.flow.usage.showUnused': '查看未使用资源',
  'oifde.flow.usage.complexcellUsageTitle': '复合图元使用统计',
  'oifde.flow.usage.interfaceUsageTitle': '接口使用统计',
  'oifde.flow.usage.procedureUsageTitle': '存储过程使用统计',
  'oifde.flow.usage.ruleUsageTitle': '灰度规则使用统计',
  'oifde.flow.usage.templateUsageTitle': '模板使用统计',
  'oifde.flow.usage.globalVarsUsageTitle': '全局变量使用统计',
  'oifde.flow.usage.flowVarsUsageTitle': '流程变量使用统计',
  'oifde.flow.usage.inuse': '使用中',
  'oifde.flow.usage.unuse': '未使用',
  'oifde.flow.usage.status.norecord': '无记录',
  'oifde.flow.usage.status.init': '待执行',
  'oifde.flow.usage.status.running': '执行中',
  'oifde.flow.usage.status.finish': '完成',
  'oifde.flow.usage.status.fail': '失败',
  'oifde.flow.usage.name': '名称',
  'oifde.flow.usage.type': '类型',
  'oifde.flow.usage.desc': '描述',
  'oifde.flow.usage.resourceType.complexcell': '复合图元',
  'oifde.flow.usage.resourceType.interface': '接口',
  'oifde.flow.usage.resourceType.procedure': '存储过程',
  'oifde.flow.usage.resourceType.rule': '灰度规则',
  'oifde.flow.usage.resourceType.template': '模板',
  'oifde.flow.usage.resourceType.globalvars': '全局变量',
  'oifde.flow.usage.resourceType.flowvars': '流程变量',
  'oifde.flow.add.blankFlow': '空白流程',
  'oifde.flow.add.callInFlow': '呼入流程',
  'oifde.flow.add.outBoundFlow': '外呼流程',
  'oifde.flow.add.exceptionFlow': '异常流程',
  'oifde.template.setInputFlag': '手动输入内容',
  'oifde.template.sysErrorTemplateTitle': '异常资源模板',
  'oifde.template.sysErrorTemplate': '异常资源模板编辑',
  'oifde.template.contentDetail': '内容详情',
  'oifde.template.aiccTemplateNullTips': '模板类型不能为空！',
  'oifde.template.template': '资源模板管理',
  'oifde.template.channelTypeMgr': '渠道类型管理',
  'oifde.template.responseStyleMgr': '风格管理',
  'oifde.template.channelTypeName': '渠道名称',
  'oifde.template.createChannelType': '新增渠道',
  'oifde.template.editChannelType': '编辑渠道',
  'oifde.template.responseStyleName': '风格名称',
  'oifde.template.createResponseStyle': '新增风格',
  'oifde.template.editResponseStyle': '编辑风格',
  'oifde.template.selectChannelType': '渠道选择',
  'oifde.template.selectResponseStyle': '风格选择',
  'oifde.template.selectLang': '语种选择',
  'oifde.template.editContent': '添加模板内容',
  'oifde.template.setDefault': '设置为默认',
  'oifde.template.name': '名称',
  'oifde.template.type': '模板类型',
  'oifde.template.desc': '描述',
  'oifde.template.content': '模板内容',
  'oifde.template.createTime': '创建时间',
  'oifde.template.updateTime': '修改时间',
  'oifde.template.detail': '模板内容详情',
  'oifde.template.select': '模板类型',
  'oifde.template.contentName': '内容',
  'oifde.template.deleteContent': '删除',
  'oifde.template.addContent': '新增模板内容',
  'oifde.template.tipContent': '请输入文本内容',
  'oifde.template.tipForVoice': '请输入语音文件的完整路径',
  'oifde.template.tipForVideo': '请输入视频文件的完整路径',
  'oifde.template.addLimit': '最多支持三条内容',
  'oifde.template.deleteLimit': '最少需要存在一条内容',
  'oifde.template.selectLanguage': '请选择对应语言，如需删除，请点击关闭按钮。',
  'oifde.template.addNewIntent': '增加新意图',
  'oifde.template.setIntentTemplate': '意图模板配置',
  'oifde.template.addTopNode': '添加顶级节点',
  'oifde.template.selectTemplate': '模板选择',
  'oifde.template.selectAction': '选择',
  'oifde.template.actions.create': '新增',
  'oifde.template.actions.delete': '删除',
  'oifde.template.actions.edit': '编辑',
  'oifde.template.actions.editbaseInfo': '编辑资源模板',
  'oifde.template.actions.addbaseInfo': '新建资源模板',
  'oifde.template.actions.success': '成功',
  'oifde.template.actions.fail': '失败',
  'oifde.template.actions.createSuccess': '新建成功！',
  'oifde.template.actions.saveSuccess': '保存成功！',
  'oifde.template.actions.updateSuccess': '修改成功！',
  'oifde.template.actions.deleteSuccess': '删除成功！',
  'oifde.template.actions.cancelDelete': '已取消删除！',
  'oifde.template.actions.deletePrompt': '此操作将永久删除该模板, 是否继续?',
  'oifde.template.actions.deleteChannelType': '此操作将永久删除该渠道, 是否继续?',
  'oifde.template.actions.deleteResponseStyle': '此操作将永久删除该风格, 是否继续?',
  'oifde.template.actions.prompt': '提示',
  'oifde.template.actions.confirm': '确认',
  'oifde.template.actions.cancel': '取消',
  'oifde.template.actions.append': '添加',
  'oifde.template.update.templateName': '模板名称',
  'oifde.template.update.templateType': '模板类型',
  'oifde.template.update.templateDesc': '模板描述',
  'oifde.template.update.intents': '意图列表',
  'oifde.template.typeDetail.tts': 'TTS',
  'oifde.template.typeDetail.sms': '短信',
  'oifde.template.typeDetail.staticvoice': '静态语音',
  'oifde.template.typeDetail.videovoice': '视频文件',
  'oifde.template.typeDetail.multiMedia': '多媒体',
  'oifde.template.typeDetail.cardTemplate': '卡片模板',
  'oifde.template.language.chinese': '中文',
  'oifde.template.language.english': '英文',
  'oifde.template.language.spanish': '西班牙语',
  'oifde.template.voicename.cn_kefuman_common': '客服曼',
  'oifde.template.voicename.cn_chengshuxing_common': '成熟型',
  'oifde.template.voicename.cn_roumeiqian_common': '柔美倩',
  'oifde.template.voicename.cn_tianmeiru_common': '甜美茹',
  'oifde.template.voicename.cn_ziranzhen_common': '自然真',
  'oifde.template.defaultValueValidate.ttsSpeedValueDesc': '取值范围[-500,500]',
  'oifde.template.defaultValueValidate.ttsVolumeValueDesc': '取值范围[0,100]',
  'oifde.template.defaultValueValidate.ttsPitchValueDesc': '取值范围[-500,500]',
  'oifde.template.validate.content': '模板内容必填',
  'oifde.template.validate.templateName': '模板名称必填',
  'oifde.template.validate.templateType': '模板类型必选',
  'oifde.template.validate.doubleNameCheck': '模板名称已存在',
  'oifde.template.validate.intentDsp': '意图描述必填',
  'oifde.template.validate.intentcode': '意图码必填',
  'oifde.template.validate.nameDup': '名称已存在',
  'oifde.template.validate.contentLengthError': '模板内容不能为空或者长度不能超过4096位!',
  'oifde.template.intentionInfo.addIntent': '添加意图',
  'oifde.template.intentionInfo.intentDsp': '意图描述',
  'oifde.template.intentionInfo.intentcode': '意图码',
  'oifde.template.intentionInfo.intentList': '意图列表',
  'oifde.template.message.intentListLength': '意图列表字符总长度不能超过4096!',
  'oifde.template.message.intentNameDuplicate': '意图名称重复',
  'oifde.template.message.validateResult': '提示',
  'oifde.template.content.exist': '当前所选的模板内容已添加',
  'oifde.template.selectResource': '选择资源',
  'oifde.template.message.searchResourceName': '请输入资源名称',
  'oifde.template.title.ResourceName': '资源名称',
  'oifde.template.title.ResourceType': '资源类型',
  'oifde.template.multipart.type.location': '地址',
  'oifde.rule.flowRule': '流程灰度规则',
  'oifde.rule.kgRule': '知识灰度规则',
  'oifde.rule.name': '规则名称',
  'oifde.rule.beginNum': '起始号码',
  'oifde.rule.endNum': '结束号码',
  'oifde.rule.language': '语言',
  'oifde.rule.select': '请选择',
  'oifde.rule.number': '数量',
  'oifde.rule.manage': '管理',
  'oifde.rule.actions.error': '调用接口出错',
  'oifde.rule.actions.errorCode': '错误码',
  'oifde.rule.actions.errorMessage': '错误信息',
  'oifde.rule.actions.create': '新增',
  'oifde.rule.actions.delete': '删除',
  'oifde.rule.actions.edit': '编辑',
  'oifde.rule.actions.success': '成功',
  'oifde.rule.actions.addSuccess': '添加成功！',
  'oifde.rule.actions.updateSuccess': '更新成功！',
  'oifde.rule.actions.deleteSuccess': '删除成功！',
  'oifde.rule.actions.editbaseInfo': '编辑基本信息',
  'oifde.rule.actions.addbaseInfo': '新建基本信息',
  'oifde.rule.actions.confirm': '提示',
  'oifde.rule.actions.sure': '确认',
  'oifde.rule.actions.cancel': '取消',
  'oifde.rule.actions.cancelDelete': '已取消删除！',
  'oifde.rule.actions.deleteConfirm': '此操作将永久删除该规则, 是否继续?',
  'oifde.rule.actions.deleteFailed': '删除失败，请检查此规则是否已关联发布流程，请先取消发布',
  'oifde.rule.actions.viewBoundFlow': '查看已绑定流程',
  'oifde.rule.rules.ruleNameValidator': '规则名称已存在',
  'oifde.rule.rules.beginNumValidator': '开始号码仅允许字母，数字，下划线，连字符，英文单引号，冒号，空格，点，{\'@\'} 和 /',
  'oifde.rule.rules.endNumValidator': '结束号码仅允许字母，数字，下划线，连字符，英文单引号，冒号，空格，点，{\'@\'} 和 /',
  'oifde.rule.rules.beginNumLengthValidator': '结束号码必须大于等于起始号码',
  'oifde.rule.update.ruleName': '规则名称',
  'oifde.rule.update.beginNum': '起始号码',
  'oifde.rule.update.newRuleName': '规则名称必填',
  'oifde.rule.update.newBeginNum': '起始号码必填',
  'oifde.rule.update.newEndNum': '结束号码必填',
  'oifde.rule.update.endNum': '结束号码',
  'oifde.rule.message.add_name_list': '新增黑白名单',
  'oifde.rule.message.name_list_info': '黑白名单信息',
  'oifde.rule.message.succeed_save': '保存知识灰度规则成功！',
  'oifde.rule.message.fail_save': '保存黑白名单失败。',
  'oifde.rule.message.failed_query': '查询失败。',
  'oifde.rule.message.select_prompt': '请至少选择一条记录。',
  'oifde.rule.message.confirm_name_list': '确认删除黑白名单？',
  'oifde.rule.message.succeed_delete': '删除黑白名单成功！',
  'oifde.rule.message.fail_delete': '删除黑白名单失败！',
  'oifde.rule.message.mgr_name_list': '管理黑白名单--',
  'oifde.rule.message.add_user_ids': '新增用户号码',
  'oifde.rule.message.batch_input_prompt': '每次回车代表新增一条记录。单条记录长度为4到64个字符',
  'oifde.rule.message.user_number': '用户号码',
  'oifde.rule.message.user_ids_number': '黑白名单用户号码上限5000条',
  'oifde.rule.message.user_ids_number_once': '黑白名单用户号码单次新增上限100条',
  'oifde.rule.message.user_id_not_exceed': '单个用户号码长度不能少于4个字符或者超过64个字符。',
  'oifde.rule.message.save_user_ids_success': '保存用户号码成功！',
  'oifde.rule.message.save_user_ids_fail': '保存用户号码失败！',
  'oifde.rule.message.confirm_delete_user_ids': '确认删除用户号码？',
  'oifde.rule.message.succeed_delete_user_ids': '删除用户号码成功！',
  'oifde.rule.message.failed_delete_user_ids': '删除用户号码失败！',
  'oifde.rule.message.import_user_ids': '导入用户号码',
  'oifde.rule.message.import_words_hint_text': '支持导入纯文本格式（*.txt）。文件编码格式为UTF-8。',
  'oifde.rule.message.overwrite_import': '全覆盖已有配置',
  'oifde.rule.message.success_import': '导入成功！',
  'oifde.rule.message.failed_import_file': '导入文件失败！',
  'oifde.accesscode.usingRecord': '抓包会话服务',
  'oifde.accesscode.messageTips': '请告诉我不满意的地方，我会努力改进',
  'oifde.accesscode.more': '更多',
  'oifde.accesscode.bindDomain': '绑定领域',
  'oifde.accesscode.bindFaqGroup': '绑定问答组',
  'oifde.accesscode.bindKg': '绑定知识图谱',
  'oifde.accesscode.robotName': '机器人名称',
  'oifde.accesscode.domain': '领域',
  'oifde.accesscode.faqGroup': '问答组',
  'oifde.accesscode.knowledgeGraph': '知识图谱',
  'oifde.accesscode.setTrace': '跟踪设置',
  'oifde.accesscode.callingNumberTrace': '主叫号码跟踪：',
  'oifde.accesscode.traceOpen': '开启',
  'oifde.accesscode.traceClose': '关闭',
  'oifde.accesscode.hideTraceSet': '收起',
  'oifde.accesscode.setTraceSuccess': '设置成功！',
  'oifde.accesscode.accessCode': '机器人接入码',
  'oifde.accesscode.accessCodeSearch': '机器人名称或机器人接入码',
  'oifde.accesscode.accessCodeDesc': '接入码描述',
  'oifde.accesscode.description': '接入码标识',
  'oifde.accesscode.descPlaceholder': '此描述将在被叫配置选择流程时作为流程描述使用，配置时要保证全局唯一',
  'oifde.accesscode.flowCode': '流程编码',
  'oifde.accesscode.flowName': '流程名称',
  'oifde.accesscode.domainId': '领域编号',
  'oifde.accesscode.dialogType': '对话类型',
  'oifde.accesscode.callingnumber': '主叫号码',
  'oifde.accesscode.callednumber': '被叫号码：',
  'oifde.accesscode.processTimes': '直接转人工对话次数',
  'oifde.accesscode.startcall': '开始呼叫',
  'oifde.accesscode.sendprompt': '按 Enter 发送',
  'oifde.accesscode.callingnumberinput': '请输入主叫号码',
  'oifde.accesscode.isCheck': '是否进行拼写检查',
  'oifde.accesscode.isCheckHelp': '勾选后用户输入存在错别字情况时，会对输入纠错后进行意图识别',
  'oifde.accesscode.sendContent': '发送',
  'oifde.accesscode.trl': '从右到左输入',
  'oifde.accesscode.actions.edit': '编辑',
  'oifde.accesscode.actions.testCalledNum': '呼叫测试',
  'oifde.accesscode.actions.delete': '删除',
  'oifde.accesscode.actions.editbaseInfo': '编辑机器人',
  'oifde.accesscode.actions.addbaseInfo': '新建机器人',
  'oifde.accesscode.actions.success': '成功',
  'oifde.accesscode.actions.fail': '失败',
  'oifde.accesscode.actions.createSuccess': '新建成功！',
  'oifde.accesscode.actions.updateSuccess': '修改成功！',
  'oifde.accesscode.actions.deleteSuccess': '删除成功！',
  'oifde.accesscode.actions.cancelDelete': '已取消删除！',
  'oifde.accesscode.actions.deletePrompt': '此操作将永久删除该机器人, 是否继续?',
  'oifde.accesscode.actions.prompt': '提示',
  'oifde.accesscode.actions.confirm': '确认',
  'oifde.accesscode.actions.cancel': '取消',
  'oifde.accesscode.actions.flowChatbot': '流程测试聊天机器人',
  'oifde.accesscode.actions.callforwarding': '呼叫转移',
  'oifde.accesscode.actions.voicefile': '语音文件：',
  'oifde.accesscode.actions.videofile': '视频文件：',
  'oifde.accesscode.dialogTypeDetail.voiceNavigation': '语音导航',
  'oifde.accesscode.dialogTypeDetail.smartOutboundCall': '智能外呼',
  'oifde.accesscode.dialogTypeDetail.chatbot': '聊天机器人',
  'oifde.accesscode.dialogTypeDetail.ivrProcess': 'IVR流程',
  'oifde.accesscode.validate.accessCode': '流程接入码必填',
  'oifde.accesscode.validate.accessCodeCharReg': '只允许输入字母和数字',
  'oifde.accesscode.validate.doubleAccessCodeCheck': '接入码已存在',
  'oifde.accesscode.validate.dialogType': '对话类型必填',
  'oifde.accesscode.validate.flowCode': '流程必填',
  'oifde.accesscode.validate.accessCodeLength': '接入码长度大于20个字符',
  'oifde.accesscode.validate.flowCodeLength': '流程编码长度大于64个字符',
  'oifde.accesscode.validate.descriptionLengthValidator': '接入码描述长度大于512个字符',
  'oifde.accesscode.validate.domainIdMax': '领域编号数值大于2147483647',
  'oifde.accesscode.validate.domainIdCheck': '领域编号不是有效数字',
  'oifde.accesscode.recordState.disable': '不启用',
  'oifde.accesscode.recordState.enable': '启用',
  'oifde.accesscode.accessCodeSubscribed': '机器人接入码已被使用',
  'oifde.accesscode.bindScene': '查看引用',
  'oifde.accesscode.chatBot.search': '搜索',
  'oifde.accesscode.chatBot.reset': '重置',
  'oifde.accesscode.searchScene': '请选择场景',
  'oifde.accesscode.sceneName': '使用场景',
  'oifde.accesscode.subName': '引用名称',
  'oifde.accesscode.createTime': '引用时间',
  'oifde.accesscode.chatBot.chatChanel': '多媒体渠道',
  'oifde.accesscode.chatBot.agentAssistant': '座席助手',
  'oifde.accesscode.chatBot.intelligentOB': '智能外呼',
  'oifde.accesscode.chatBot.intelligentTrain': '智能实训',
  'oifde.toc.statusCode.SERVER_BUSY': '系统忙',
  'oifde.toc.statusCode.NEED_LOGIN': '您未登录或长时间未操作，请重新登录',
  'oifde.toc.statusCode.ACCOUNT_INCONSISTENT': '您在本机已通过其他帐号登录，如继续请重新登录',
  'oifde.toc.statusCode.ACCOUNT_SWITCHED': '登录帐号已经发生切换， 当前页面将切换到最新登录的帐号',
  'oifde.toc.statusCode.INCORRECT_VERIFYCODE': '验证码不对，请重新输入',
  'oifde.toc.statusCode.INVALID_JSON': '非JSON格式的请求',
  'oifde.toc.statusCode.HTTPS_REQUIRED': '请使用HTTPS访问',
  'oifde.toc.statusCode.EMPTY_ACCOUNT': '帐号为空',
  'oifde.toc.statusCode.INVALID_ACCOUNT': '认证失败，请重新登录',
  'oifde.toc.statusCode.INSUFFICIENT_PERMISSION': '您的权限不够，如需要请联系管理员分配权限',
  'oifde.toc.statusCode.VERIFYCODE_EXPIRED': '验证码已失效，请重试',
  'oifde.toc.statusCode.ACCOUNT_LOCKED': '被临时锁定，请稍后再试或联系管理员解锁',
  'oifde.toc.statusCode.ACCOUNT_CHANGED': '帐号发生变更，请重新登录',
  'oifde.toc.statusCode.ACCOUNT_DISABLED': '帐号已经停用，请联系管理员',
  'oifde.toc.statusCode.UNLOCK_REQUIRED': '帐号已经被临时锁定，请先将该帐号解锁',
  'oifde.toc.statusCode.ADMIN_DISABLED': '管理功能已关闭，请使用普通帐号登陆系统',
  'oifde.toc.statusCode.SESSION_OVERFLOW': '在线用户数已经达最大值，请稍后再尝试登陆',
  'oifde.toc.statusCode.SESSION_EXPIRED': '当前登录已经失效，请重新登录',
  'oifde.toc.statusCode.SESSION_DUPLICATED': '您已经登录，如重新登录请先退出该帐号的登录:',
  'oifde.toc.statusCode.INCORRECT_TENANT_SPACE_ID': '租间ID不正确',
  'oifde.toc.statusCode.INCORRECT_TENANT_SPACE_NAME': '租间名称不正确',
  'oifde.toc.statusCode.INCORRECT_ACCOUNT': '帐号不正确',
  'oifde.toc.statusCode.INCORRECT_PASSWORD': '密码不符合密码规则要求',
  'oifde.toc.statusCode.INCORRECT_GENDER': '性别不正确',
  'oifde.toc.statusCode.INCORRECT_OWNER': '拥有者不正确',
  'oifde.toc.statusCode.INCORRECT_TITLE': '职务长度不应超过8',
  'oifde.toc.statusCode.INCORRECT_TELEPHONE': '电话号码不正确',
  'oifde.toc.statusCode.INCORRECT_EMAIL': 'Email地址不正确',
  'oifde.toc.statusCode.INCORRECT_ROLE_NAME': '角色名称不正确',
  'oifde.toc.statusCode.EMPTY_TENANT_SPACE': '租间为空',
  'oifde.toc.statusCode.ACCOUNT_DUPLICATED': '帐号已经存在',
  'oifde.toc.statusCode.ROLE_DUPLICATED': '角色已经存在！',
  'oifde.toc.statusCode.TENANT_SPACE_DUPLICATED': '租间已经存在！',
  'oifde.toc.statusCode.ACCOUNT_NOT_FOUND': '帐号不存在！',
  'oifde.toc.statusCode.ROLE_NOT_FOUND': '角色不存在！',
  'oifde.toc.statusCode.TENANT_SPACE_NOT_FOUND': '租间不存在！',
  'oifde.toc.statusCode.ACCOUNT_FORBIDDEN': '禁止对该帐号操作！',
  'oifde.toc.statusCode.ROLE_FORBIDDEN': '禁止对该角色操作！',
  'oifde.toc.statusCode.TENANT_SPACE_FORBIDDEN': '禁止对该租间操作！',
  'oifde.toc.statusCode.INCORRECT_ACCOUNT_INPUT': '帐号长度至少为6，不超过32，可以包含英文字母、数字或特殊字符.{\'@\'}_-',
  'oifde.toc.statusCode.INCORRECT_PASSWORD_UPDATE': '密码连续重复字符数不大于3（另外：已有用户新密码不能与最近3次的相同），应包含8至20个字符，必须带有：数字、小写字母、大写字母、特殊字符之一：~`!{\'@\'}#{\'$\'}%^*()-+_={\'|\'},./<>?;\':"[]{\'{\'}{\'}\'}\\&',
'oifde.toc.statusCode.COMMON_ERROR': '服务器错误！',
  'oifde.toc.statusCode.SERVICE_UNAVAILABLE': '服务不可用！',
  'oifde.toc.statusCode.AUTHORIZE_FAILED': '认证失败！',
  'oifde.toc.statusCode.TRANSIT_FAILED': '请求转发超时！',
  'oifde.toc.statusCode.TRANSIT_ERROR': '请求成功转发，但对端返回了错误！',
  'oifde.toc.statusCode.RESPONSE_FAILED': '服务器处理响应失败！',
  'oifde.toc.statusCode.REFRESH_TIMEOUT': '长时间未操作，请重新登录！',
  'oifde.logparam.logParamMgr': 'Flume日志参数配置',
  'oifde.logparam.logParam': '动态参数配置',
  'oifde.logparam.logName': '参数名',
  'oifde.logparam.source': '参数来源',
  'oifde.logparam.sourceName': '参数来源名称',
  'oifde.logparam.transFlag': '是否转换值',
  'oifde.logparam.sourceValue': '原始值',
  'oifde.logparam.targetValue': '目标值',
  'oifde.logparam.actions.edit': '编辑',
  'oifde.logparam.actions.transDetail': '配置参数转换信息',
  'oifde.logparam.actions.delete': '删除',
  'oifde.logparam.actions.editLogParamBaseInfo': '编辑动态参数',
  'oifde.logparam.actions.addLogParamBaseInfo': '新增动态参数',
  'oifde.logparam.actions.success': '成功',
  'oifde.logparam.actions.createSuccess': '新建成功！',
  'oifde.logparam.actions.updateSuccess': '修改成功！',
  'oifde.logparam.actions.deleteSuccess': '删除成功！',
  'oifde.logparam.actions.cancelDelete': '已取消删除！',
  'oifde.logparam.actions.deletePrompt': '此操作将永久删除该动态参数, 是否继续?',
  'oifde.logparam.actions.prompt': '提示',
  'oifde.logparam.actions.confirm': '确认',
  'oifde.logparam.actions.cancel': '取消',
  'oifde.logparam.actions.add': '增加',
  'oifde.logparam.sourceDetail.IVRRequestInformation': 'IVR请求消息',
  'oifde.logparam.sourceDetail.IVRResponseInformation': 'IVR响应消息',
  'oifde.logparam.sourceDetail.TOCRequestInformation': 'TOC请求消息',
  'oifde.logparam.sourceDetail.TOCResponseInformation': 'TOC响应消息',
  'oifde.logparam.sourceDetail.InterfaceRequestInformation': '接口请求消息',
  'oifde.logparam.sourceDetail.InterfaceResponseInformation': '接口响应消息',
  'oifde.logparam.transFlagDetail.YES': '需要',
  'oifde.logparam.transFlagDetail.NO': '不需要',
  'oifde.logparam.validate.logName': '参数名称必填',
  'oifde.logparam.validate.source': '参数来源必填',
  'oifde.logparam.validate.sourceName': '参数来源名称必填',
  'oifde.logparam.validate.sourceValue': '原始值必填',
  'oifde.logparam.validate.targetValue': '目标值必填',
  'oifde.logparam.validate.doublelogNameCheck': '动态参数名称已存在',
  'oifde.logparam.validate.logNameLength': '动态参数名称长度大于64个字符',
  'oifde.logparam.validate.sourceNameLength': '参数来源名称长度大于64个字符',
  'oifde.logparam.validate.sourceValueLength': '原始值长度不能超过64',
  'oifde.logparam.validate.targetValueLength': '目标值长度不能超过64',
  'oifde.logparam.message.logParamNotNull': '参数转换信息未填写!',
  'oifde.logparam.message.validateFailed': '校验不通过',
  'oifde.logparam.message.validateResult': '提示',
  'oifde.tenantinfo.tenanInfoMgr': '静态参数配置',
  'oifde.tenantinfo.serviceName': '配置名',
  'oifde.tenantinfo.serviceValue': '配置值',
  'oifde.tenantinfo.actions.edit': '编辑',
  'oifde.tenantinfo.actions.editTenantBaseInfo': '编辑静态信息参数',
  'oifde.tenantinfo.actions.success': '成功',
  'oifde.tenantinfo.actions.updateSuccess': '修改成功！',
  'oifde.tenantinfo.validate.serviceValue': '配置值必填',
  'oifde.tenantinfo.validate.serviceValueLength': '配置值长度大于64个字符',
  'oifde.variable.innerTitle': '内置变量',
  'oifde.variable.customTitle': '自定义变量',
  'oifde.variable.variableName': '变量名称',
  'oifde.variable.variableDesc': '变量描述',
  'oifde.variable.variableType': '数据类型',
  'oifde.variable.scope': '作用域',
  'oifde.variable.defaultValue': '缺省值',
  'oifde.variable.encryptType': '加密类型',
  'oifde.variable.timezoneDesc': '可以设置为租户自己对应的时区，范围为-12到12，例如东8区配置为8。',
  'oifde.variable.channeltypeDesc': '可以设置为租户自己对应的渠道。',
  'oifde.variable.responsestyleDesc': '可以设置为租户自己对应的风格。',
  'oifde.variable.langDesc': '可以设置为租户自己对应的语言。',
  'oifde.variable.ttsSpeedDesc': '可以设置为租户自己对应的tts语速，调节语速快慢。',
  'oifde.variable.ttsVolumeDesc': '可以设置为租户自己对应的tts音量，调节音量大小。',
  'oifde.variable.ttsPitchDesc': '可以设置为租户自己对应的tts基频，调节音调高低。',
  'oifde.variable.voiceNameDesc': '可以设置为租户自己对应的tts发音人。',
  'oifde.variable.variableTypeDetail.int': '整型',
  'oifde.variable.variableTypeDetail.string': '字符型',
  'oifde.variable.variableTypeDetail.long': '长整型',
  'oifde.variable.variableTypeDetail.float': '浮点型',
  'oifde.variable.variableTypeDetail.list': '列表',
  'oifde.variable.variableTypeDetail.object': '对象',
  'oifde.variable.scopes.input': '入参',
  'oifde.variable.scopes.output': '出参',
  'oifde.variable.scopes.local': '局部',
  'oifde.variable.actions.edit': '编辑',
  'oifde.variable.actions.delete': '删除',
  'oifde.variable.actions.editVariableBaseInfo': '编辑变量',
  'oifde.variable.actions.addVariableBaseInfo': '新增变量',
  'oifde.variable.actions.success': '成功',
  'oifde.variable.actions.createSuccess': '新建成功！',
  'oifde.variable.actions.updateSuccess': '修改成功！',
  'oifde.variable.actions.deleteSuccess': '删除成功！',
  'oifde.variable.actions.cancelDelete': '已取消删除',
  'oifde.variable.actions.deletePrompt': '此操作将永久删除该变量, 是否继续?',
  'oifde.variable.actions.prompt': '提示',
  'oifde.variable.actions.confirm': '确认',
  'oifde.variable.actions.cancel': '取消',
  'oifde.variable.actions.add': '增加',
  'oifde.variable.validate.variableName': '变量名称必填',
  'oifde.variable.validate.variableNameRange': '只允许输入字母，数字和下划线',
  'oifde.variable.validate.variableType': '数据类型必选',
  'oifde.variable.validate.scope': '作用域必选',
  'oifde.variable.validate.doubleVariableNameCheck': '变量名称已经存在',
  'oifde.variable.validate.intValueCheck': '整型数据缺省值错误，整型数据范围-2147483648~2147483647',
  'oifde.variable.validate.longValueCheck': '长整型数据缺省值错误，数据范围-9223372036854775808~9223372036854775807',
  'oifde.variable.validate.floatValueCheck': '浮点型数据缺省值格式错误，格式应如：100 或 99.99',
  'oifde.variable.validate.listValueCheck': '列表型数据缺省值格式错误，格式应如：[1,2,3] 或 ["a","b","c"]，可以为空：[]',
  'oifde.variable.validate.objectValueCheck': '对象型数据缺省值格式错误，格式应如：{\'{\'}"key1":"value1","key2":"value2"{\'}\'}，可以为空：{\'{\'}{\'}\'}',
  'oifde.variable.validate.variableNameLength': '变量名长度需要不超过1000位！',
  'oifde.variable.validate.variableDescLength': '变量描述长度需要不超过1000位！',
  'oifde.variable.validate.defaultValueLength': '缺省值长度需要不超过1024位！',
  'oifde.variable.encryptFlag.zero': '不加密',
  'oifde.variable.encryptFlag.one': '加密保存，获取时解密',
  'oifde.variable.encryptFlag.two': '加密保存，获取时不解密',
  'oifde.diagram.varType': '变量类型',
  'oifde.diagram.usedVar': '已使用变量',
  'oifde.diagram.usedCell': '对应图元',
  'oifde.diagram.fastGetCell': '图元快速定位',
  'oifde.diagram.fastByVar': '变量快速定位',
  'oifde.diagram.aimCell': '目标图元',
  'oifde.diagram.saveAndPublish': '保存并发布',
  'oifde.diagram.NMS_PleaseSelect': '请选择',
  'oifde.diagram.NMS_ParamName': '参数名称',
  'oifde.diagram.NMS_ParamValue': '参数值',
  'oifde.diagram.NMS_DiagramProperties': '流程属性',
  'oifde.diagram.NMS_BasicProperties': '基本属性',
  'oifde.diagram.NMS_DiagramCode': '流程编码',
  'oifde.diagram.NMS_DiagramName': '流程名称',
  'oifde.diagram.NMS_ScenarioType': '场景类型',
  'oifde.diagram.NMS_Desciption': '描述',
  'oifde.diagram.NMS_NodeCode': '节点编码',
  'oifde.diagram.NMS_NodeName': '节点名称',
  'oifde.diagram.NMS_AdvanceProperties': '高级属性',
  'oifde.diagram.NMS_ServiceName': '服务名称',
  'oifde.diagram.NMS_ServiceParams': '服务参数',
  'oifde.diagram.NMS_ServiceDesciption': '服务描述',
  'oifde.diagram.add': '添加',
  'oifde.diagram.remove': '删除',
  'oifde.diagram.NMS_BranchProperties': '分支属性',
  'oifde.diagram.NMS_BranchCondition': '分支条件',
  'oifde.diagram.NMS_BranchService': '服务分支',
  'oifde.diagram.NMS_NodeProperties': '节点属性',
  'oifde.diagram.NMS_FlowVariable': '流程变量',
  'oifde.diagram.NMS_FlowVariableName': '变量名称',
  'oifde.diagram.NMS_FlowVariableDesc': '变量描述',
  'oifde.diagram.NMS_FlowVariableType': '数据类型',
  'oifde.diagram.NMS_FlowVariableDefaultValue': '缺省值',
  'oifde.diagram.NMS_FlowVariableEncryptType': '加密类型',
  'oifde.diagram.NMS_FlowVariableEncryptTypeWarn': '*请注意，加密类型为不加密时，该变量将被明文存储，请勿将敏感数据赋值给该变量',
  'oifde.diagram.NMS_FlowVariableEncryptTypeTips': '*请注意，加密类型为加密时，为防止信息泄露，建议使用变量对其进行赋值',
  'oifde.diagram.NMS_FlowVariableOption': '操作',
  'oifde.diagram.NMS_AddVariable': '添加变量',
  'oifde.diagram.NMS_ModifyVariable': '修改变量',
  'oifde.diagram.NMS_VoiceTemplate': '应答模板',
  'oifde.diagram.variableTypeDetail.int': '整型',
  'oifde.diagram.variableTypeDetail.string': '字符型',
  'oifde.diagram.variableTypeDetail.long': '长整型',
  'oifde.diagram.variableTypeDetail.float': '浮点型',
  'oifde.diagram.variableTypeDetail.list': '列表',
  'oifde.diagram.variableTypeDetail.object': '对象',
  'oifde.diagram.error.NMS_flowCodeError': '流程编码不能为空或者长度不能大于64位！',
  'oifde.diagram.error.NMS_flowNameError': '流程名称不能为空或者长度不能大于1000位！',
  'oifde.diagram.error.NMS_scenarioTypeError': '场景类型不能为空',
  'oifde.diagram.error.NMS_flowDescError': '流程描述不能大于1000位！',
  'oifde.diagram.error.NMS_nmsFlowError': '流程解析失败，没有找到nmsFlow节点！',
  'oifde.diagram.error.NMS_rootError': '流程解析失败，没有找到root节点！',
  'oifde.diagram.error.NMS_VariableNameError': '变量名长度不能超过1000位！',
  'oifde.diagram.error.NMS_VariableDescError': '变量描述不能超过1000位！',
  'oifde.diagram.error.NMS_VariableDefaultValueError': '变量缺省值不能超过1024位！',
  'oifde.diagram.error.NMS_NodeCodeError': '节点编码不能为空或者不能超过64位！',
  'oifde.diagram.error.NMS_NodeNameError': '节点名不能为空或者不能超过1000位！',
  'oifde.diagram.error.NMS_NodeDescError': '节点描述不能超过1000位！',
  'oifde.diagram.error.NMS_actionParamNameError': '参数名不能超过{0}位！',
  'oifde.diagram.error.NMS_actionParamValueError': '参数值不能超过4096位！',
  'oifde.diagram.error.NMS_actionParamDescError': '参数描述不能超过500位！',
  'oifde.diagram.error.NMS_transitToStartNodeError': '不能跳转到开始节点！',
  'oifde.diagram.error.NMS_endNodetransitToError': '结束节点不能跳转到别的节点！',
  'oifde.diagram.error.NMS_startNodeNotFind': '没有找到开始节点！',
  'oifde.diagram.error.NMS_startNodeNotUnique': '开始节点不唯一！',
  'oifde.diagram.error.NMS_startNodeNotBranchError': '开始节点没有分支或者多于一个分支！',
  'oifde.diagram.error.NMS_endNodeNotFind': '没有找到结束节点！',
  'oifde.diagram.error.NMS_analiseXmlFailed': '解析流程画布失败！',
  'oifde.diagram.error.NMS_branchError': '分支没有起始节点或者结束节点！',
  'oifde.diagram.error.NMS_nullBranchNotNuique': '节点的缺省分支不能超过1条！',
  'oifde.diagram.error.NMS_checkDefaultNextNodeForView': 'view类型的图元不能有多个分支！',
  'oifde.diagram.error.NMS_actionParamValueEmptyError': '参数值不能为空！',
  'oifde.diagram.complexCell.name': '图元名称',
  'oifde.diagram.complexCell.variable': '图元变量',
  'oifde.diagram.speechRecognition.recognitionParam': '识别参数',
  'oifde.diagram.speechRecognition.resultParam': '结果处理',
  'oifde.navbar.tenantId': '当前登入租户Id',
  'oifde.navbar.tenantName': '当前登入租户',
  'oifde.navbar.current': '当前登入时间',
  'oifde.navbar.success': '上次成功登入时间',
  'oifde.navbar.failed': '上次失败登入时间',
  'oifde.navbar.logOut': '退出登录',
  'oifde.navbar.switchUser': '切换用户',
  'oifde.navbar.screenfull': '全屏',
  'oifde.navbar.switchLang': '切换语言',
  'oifde.navbar.menuOpen': '点击展开菜单',
  'oifde.navbar.menuClose': '点击折叠菜单',
  'oifde.navbar.changePwd': '修改密码',
  'oifde.navbar.required': '必填项',
  'oifde.navbar.action.message': '提示',
  'oifde.navbar.action.success': '成功',
  'oifde.navbar.action.successMessage': '成功切换',
  'oifde.navbar.action.changePasswordSuccess': '成功修改密码',
  'oifde.navbar.password.logOut': '退出',
  'oifde.navbar.password.oldPassword': '请输入旧密码',
  'oifde.navbar.password.newPassword': '请输入新密码',
  'oifde.navbar.password.confirmNewPassword': '请确认新密码',
  'oifde.navbar.password.passwordExpired': '您的密码已经过期，请更改',
  'oifde.navbar.password.firstTimeLogin': '您当前使用的是初始密码，请更改',
  'oifde.navbar.password.passwordWillExpire': '您的密码即将过期，建议更改',
  'oifde.navbar.password.passwordNotSame': '密码不一致',
  'oifde.journeylogmgr.flowAnalysisMrg': '流程分析设置',
  'oifde.journeylogmgr.accessCodeSetting': '接入码设置',
  'oifde.trackmgr.track': '跟踪设置',
  'oifde.trackmgr.accessCodeTrack': '接入码跟踪',
  'oifde.trackmgr.callingNumberTrack': '主叫号码跟踪',
  'oifde.trackmgr.callingNumber': '主叫号码',
  'oifde.trackmgr.accessCode': '接入码',
  'oifde.trackmgr.choose': '请选择',
  'oifde.trackmgr.validate.accessCode': '接入码必填',
  'oifde.trackmgr.validate.callingNumber': '主叫号码必填',
  'oifde.trackmgr.validate.needNumber': '请输入数字',
  'oifde.trackmgr.validate.callingNumberLength': '主叫号码长度大于64个字符',
  'oifde.trackmgr.validate.callingNumberDub': '主叫号码重复',
  'oifde.trackmgr.validate.pattern': '仅允许字母，数字，下划线，连字符，英文单引号，冒号，空格，点，{\'@\'} 和 /',
  'oifde.trackmgr.actions.delete': '删除',
  'oifde.trackmgr.actions.success': '成功',
  'oifde.trackmgr.actions.updateSuccess': '修改成功！',
  'oifde.trackmgr.actions.add': '增加',
  'oifde.trackmgr.actions.deleteSuccess': '删除成功！',
  'oifde.trackmgr.actions.cancelDelete': '已取消删除！',
  'oifde.trackmgr.actions.cancelUpdate': '已取消修改！',
  'oifde.trackmgr.actions.deletePrompt': '此操作将永久删除该跟踪数据, 是否继续?',
  'oifde.trackmgr.actions.updatePrompt': '此操作将影响系统性能，此配置将于{0}小时后自动删除, 是否继续?',
  'oifde.trackmgr.actions.prompt': '提示',
  'oifde.trackmgr.actions.confirm': '确认',
  'oifde.trackmgr.actions.cancel': '取消',
  'oifde.trace.traceReason': '错误原因',
  'oifde.trace.traceAdvice': '错误建议',
  'oifde.trace.reasonAdvice': '原因与建议',
  'oifde.trace.sessionLog': '会话日志查询',
  'oifde.trace.traceLog': '日志轨迹查询',
  'oifde.trace.beginTime': '开始时间',
  'oifde.trace.endTime': '结束时间',
  'oifde.trace.callingNumber': '主叫号码',
  'oifde.trace.calledNumber': '被叫号码',
  'oifde.trace.createTime': '创建时间',
  'oifde.trace.flowId': '流程ID',
  'oifde.trace.flowName': '流程名称',
  'oifde.trace.logInfo': '日志信息',
  'oifde.trace.errorInfo': '错误信息',
  'oifde.trace.callId': '呼叫实例ID',
  'oifde.trace.nodeId': '节点ID',
  'oifde.trace.nodeName': '节点名称',
  'oifde.trace.actionContent': '指令名称',
  'oifde.trace.nodeIndex': '执行节点序号',
  'oifde.trace.ivrRequest': 'IVR请求信息',
  'oifde.trace.actionParams': '执行节点的参数信息',
  'oifde.trace.processResult': '执行结果信息',
  'oifde.trace.transitionOn': '执行结果分支',
  'oifde.trace.reset': '重置',
  'oifde.trace.validate.needNumber': '请输入数字或字母',
  'oifde.trace.validate.callingNumber': '主叫号码必填',
  'oifde.trace.validate.callingNumberLength': '主叫号码长度大于64个字符',
  'oifde.trace.clickToDetail': '点击展示更多',
  'oifde.trace.clickToHideDetail': '点击收起更多',
  'oifde.trace.currentNodeInfo': '当前节点信息',
  'oifde.trace.clickToFlow': '查看轨迹',
  'oifde.trace.nodeTrace': '节点日志信息',
  'oifde.trace.nodeParams': '节点出入参信息',
  'oifde.expression.epxr_input_head': '输入最大长度为',
  'oifde.expression.expr_type_illegal': '表达式格式非法',
  'oifde.expression.expr_key_repeats': '条件名重复',
  'oifde.expression.expr_var': '请输入变量。全局变量以GLOBAL开头，流程变量以FLOW开头，系统变量以SYS开头，TUC变量以TOC开头，IVR请求变量以IVRREQUEST开头。',
  'oifde.expression.expr_var_fag': '请输入变量。全局变量以GLOBAL开头，流程变量以FLOW开头。',
  'oifde.expression.expr_var_paramName': '请输入变量。全局变量以GLOBAL开头，流程变量以FLOW开头。',
  'oifde.expression.expr_var_string': '请输入string类型的变量。全局变量以GLOBAL开头，流程变量以FLOW开头，系统变量以SYS开头，TUC变量以TOC开头，IVR请求变量以IVRREQUEST开头。',
  'oifde.expression.expr_number': '请输入数值类型的值。数值间操作符支持+-*/。',
  'oifde.expression.expr_number_noAction': '请输入数值类型的值。',
  'oifde.expression.expr_var_list': '请输入列表类型的变量。',
  'oifde.expression.expr_var_number': '请输入数值类型的变量。',
  'oifde.expression.allVar': '全局变量以GLOBAL开头，流程变量以FLOW开头，系统变量以SYS开头，TUC变量以TOC开头，IVR请求变量以IVRREQUEST开头。',
  'oifde.expression.flowAndGlobalVar': '全局变量以GLOBAL开头，流程变量以FLOW开头。',
  'oifde.expression.expr_string': '请输入字符串类型的值。字符串前后请用单引号或双引号。字符串操作符支持+。',
  'oifde.expression.expr_simple_string': '请输入string变量或任意字符串内容。字符串前后不用引号。',
  'oifde.expression.expr_boolean': '请输入布尔类型的值。数值或字符串间比较操作符支持> < >= <= !=。字符串方法支持startsWith。布尔值操作符支持&& || == !=。',
  'oifde.expression.varFlow': '流程变量',
  'oifde.expression.varGlobal': '全局变量',
  'oifde.expression.varToc': '意图变量',
  'oifde.expression.varSys': '系统变量',
  'oifde.expression.varIvrRequest': 'IVR请求变量',
  'oifde.procedure.procedureMgr': '存储过程管理',
  'oifde.procedure.procedureDBMgr': '存储过程数据库管理',
  'oifde.procedure.procedureName': '存储过程名',
  'oifde.procedure.procedureDesc': '存储过程描述',
  'oifde.procedure.procedureValue': '存储过程值',
  'oifde.procedure.operation': '操作',
  'oifde.procedure.baseInfo': '基本信息',
  'oifde.procedure.in_param': '入参',
  'oifde.procedure.paramName': '参数名',
  'oifde.procedure.paramDesc': '参数描述',
  'oifde.procedure.dataType': '数据类型',
  'oifde.procedure.out_param': '出参',
  'oifde.procedure.addProcedureInterface': '新增存储过程配置',
  'oifde.procedure.modifyProcedureInterface': '编辑存储过程配置',
  'oifde.procedure.addProcedureDB': '新增存储过程数据库配置',
  'oifde.procedure.modifyProcedureDB': '编辑存储过程数据库配置',
  'oifde.procedure.required': '必填项',
  'oifde.procedure.ruleMysql': 'MYSQL数据库必须以jdbc:mysql://开头',
  'oifde.procedure.ruleGauss': 'GAUSS数据库必须以jdbc:zenith:{\'@\'}开头',
  'oifde.procedure.ruleOracle': 'ORACLE数据库必须以jdbc:oracle:thin:{\'@\'}开头',
  'oifde.procedure.ruleRange': 'URL只能包含数字，字母和.?=:-_&/，且不能以.?=:-_&结尾',
  'oifde.procedure.oracleRuleRange': 'URL只能包含数字，字母和.?{\'@\'}=:-_&/，且不能以.?{\'@\'}=:-_&结尾',
  'oifde.procedure.driverRule': '只能包含数字，字母，点和连字符',
  'oifde.procedure.driverStart': '不能以点和连字符开头',
  'oifde.procedure.driverEnd': '不能以点和连字符结尾',
  'oifde.procedure.ruleLength': 'URL的IP和端口未输入',
  'oifde.procedure.dbUrlWarning': '*请注意，数据库URL中的IP、端口或域名不在白名单中，需要联系系统管理员添加后才能正常调用',
  'oifde.procedure.actions.editList': '编辑列表内数据类型',
  'oifde.procedure.actions.edit': '编辑',
  'oifde.procedure.actions.delete': '删除',
  'oifde.procedure.actions.success': '成功',
  'oifde.procedure.actions.updateSuccess': '修改成功！',
  'oifde.procedure.actions.deleteSuccess': '删除成功！',
  'oifde.procedure.actions.createSuccess': '新建成功',
  'oifde.procedure.actions.cancelDelete': '已取消删除',
  'oifde.procedure.actions.deletePrompt': '此操作将永久删除该存储过程, 是否继续?',
  'oifde.procedure.actions.prompt': '提示',
  'oifde.procedure.actions.confirm': '确认',
  'oifde.procedure.actions.cancel': '取消',
  'oifde.procedure.actions.ok': '确定',
  'oifde.procedure.actions.miss_pro_name': '缺少必选字段：基本信息->存储过程名。',
  'oifde.procedure.actions.miss_pro_value': '缺少必选字段：基本信息->存储过程值。',
  'oifde.procedure.actions.miss_pro_in_parameter_name': '缺少必选字段：入参->参数名。',
  'oifde.procedure.actions.miss_pro_out_parameter_name': '缺少必选字段：出参->参数名。',
  'oifde.procedure.actions.miss_pro_dbId': '缺少必选字段：数据库连接。',
  'oifde.procedure.dataTypes.INT': '整型',
  'oifde.procedure.dataTypes.STRING': '字符串',
  'oifde.procedure.dataTypes.FLOAT': '浮点型',
  'oifde.procedure.dataTypes.LONG': '长整型',
  'oifde.procedure.dataTypes.LIST': '列表',
  'oifde.procedure.db.dbType': '数据库类型',
  'oifde.procedure.db.dbDriver': '数据库驱动包',
  'oifde.procedure.db.dbUrl': '数据库URL',
  'oifde.procedure.db.dbUser': '数据库用户',
  'oifde.procedure.db.dbPassword': '数据库密码',
  'oifde.procedure.dbTypes.MYSQL': 'MYSQL',
  'oifde.procedure.dbTypes.GAUSS': 'GAUSS',
  'oifde.procedure.dbTypes.ORACLE': 'ORACLE',
  'oifde.complexcell.cellTitle': '复合图元',
  'oifde.complexcell.catalogTitle': '复合图元目录',
  'oifde.complexcell.catalog.name': '目录名称',
  'oifde.complexcell.catalog.desc': '目录描述',
  'oifde.complexcell.catalog.tips.sizeLimit': '目录不能超过100个',
  'oifde.complexcell.cell.name': '图元名称',
  'oifde.complexcell.cell.desc': '图元描述',
  'oifde.complexcell.cell.height': '图元高度',
  'oifde.complexcell.cell.width': '图元宽度',
  'oifde.complexcell.cell.icon': '图标',
  'oifde.complexcell.cell.catalog': '目录',
  'oifde.complexcell.cell.actions.editInfo': '修改基本信息',
  'oifde.complexcell.cell.actions.editDiagram': '编辑画布',
  'oifde.complexcell.cell.actions.copy': '复制',
  'oifde.complexcell.cell.validation.bigFile': '上传文件不能大于{0}',
  'oifde.complexcell.cell.viewReference':'查看引用',
  'oifde.complexcell.cell.actions.copySuccess':'复制成功！',
  'oifde.table.search': '搜索',
  'oifde.table.add': '添加',
  'oifde.table.actions': '操作',
  'oifde.table.cancel': '取 消',
  'oifde.table.confirm': '确 定',
  'oifde.table.create': '新建',
  'oifde.table.delete': '删除',
  'oifde.table.manage': '管理',
  'oifde.table.save': '保存',
  'oifde.table.reset': '重置',
  'oifde.table.filter': '筛选',
  'oifde.tagsView.refresh': '刷新',
  'oifde.tagsView.close': '关闭',
  'oifde.tagsView.closeOthers': '关闭其它',
  'oifde.tagsView.closeAll': '关闭所有',
  'oifde.mxgraph.saveAndNopublish': '保存成功，发布失败！',
  'oifde.mxgraph.publishSuccess': '保存发布成功！',
  'oifde.mxgraph.publishFailed': '保存发布失败！',
  'oifde.mxgraph.saveAndPublish': '保存并发布',
  'oifde.mxgraph.failInfo': '查询数据失败！',
  'oifde.mxgraph.alreadyConnected': '节点已连接',
  'oifde.mxgraph.cancel': '取消',
  'oifde.mxgraph.close': '关闭',
  'oifde.mxgraph.collapse-expand': '折叠/展开',
  'oifde.mxgraph.containsValidationErrors': '包含验证错误',
  'oifde.mxgraph.done': '完成',
  'oifde.mxgraph.doubleClickOrientation': '双击以更改方向',
  'oifde.mxgraph.error': '错误',
  'oifde.mxgraph.errorSavingFile': '保存文件时出错',
  'oifde.mxgraph.ok': '确定',
  'oifde.mxgraph.updatingDocument': '更新文件，请稍候。。。',
  'oifde.mxgraph.updatingSelection': '更新选择，请稍候。。。',
  'oifde.mxgraph.about': '关于',
  'oifde.mxgraph.actualSize': '实际尺寸',
  'oifde.mxgraph.add': '添加',
  'oifde.mxgraph.addLayer': '添加图层',
  'oifde.mxgraph.addProperty': '添加属性',
  'oifde.mxgraph.addToExistingDrawing': '添加到现有的绘图',
  'oifde.mxgraph.addWaypoint': '添加路标',
  'oifde.mxgraph.advanced': '高级',
  'oifde.mxgraph.align': '对齐',
  'oifde.mxgraph.alignment': '对准',
  'oifde.mxgraph.allChangesLost': '所有更改都将丢失！',
  'oifde.mxgraph.angle': '角度',
  'oifde.mxgraph.apply': '应用',
  'oifde.mxgraph.arc': '弧',
  'oifde.mxgraph.arrange': '布置',
  'oifde.mxgraph.arrow': '箭头',
  'oifde.mxgraph.arrows': '箭头',
  'oifde.mxgraph.automatic': '自动',
  'oifde.mxgraph.autosave': '自动保存',
  'oifde.mxgraph.autosize': '自动尺寸',
  'oifde.mxgraph.background': '背景',
  'oifde.mxgraph.backgroundColor': '背景颜色',
  'oifde.mxgraph.backgroundImage': '背景图片',
  'oifde.mxgraph.basic': '基本',
  'oifde.mxgraph.block': '块',
  'oifde.mxgraph.blockquote': '块引用',
  'oifde.mxgraph.bold': '粗体',
  'oifde.mxgraph.borderWidth': '边框宽度',
  'oifde.mxgraph.borderColor': '边框颜色',
  'oifde.mxgraph.bottom': '底部',
  'oifde.mxgraph.bottomAlign': '底部对齐',
  'oifde.mxgraph.bottomLeft': '左下方',
  'oifde.mxgraph.bottomRight': '右下方',
  'oifde.mxgraph.bulletedList': '项目符号列表',
  'oifde.mxgraph.cannotOpenFile': '不能打开文件',
  'oifde.mxgraph.center': '纵向对齐',
  'oifde.mxgraph.change': '更改',
  'oifde.mxgraph.changeOrientation': '改变方向',
  'oifde.mxgraph.circle': '圆',
  'oifde.mxgraph.classic': '经典',
  'oifde.mxgraph.clearDefaultStyle': '清除默认样式',
  'oifde.mxgraph.clearWaypoints': '清除路标',
  'oifde.mxgraph.clipart': '剪贴',
  'oifde.mxgraph.collapse': '折叠',
  'oifde.mxgraph.collapseExpand': '折叠/展开',
  'oifde.mxgraph.collapsible': '可折叠',
  'oifde.mxgraph.comic': '平滑',
  'oifde.mxgraph.connect': '连接',
  'oifde.mxgraph.connection': '连接',
  'oifde.mxgraph.connectionPoints': '连接点',
  'oifde.mxgraph.connectionArrows': '连接箭头',
  'oifde.mxgraph.constrainProportions': '约束比例',
  'oifde.mxgraph.copy': '拷贝',
  'oifde.mxgraph.copyConnect': '复制连接',
  'oifde.mxgraph.create': '创建',
  'oifde.mxgraph.curved': '弯曲',
  'oifde.mxgraph.custom': '自定义',
  'oifde.mxgraph.cut': '剪切',
  'oifde.mxgraph.dashed': '虚线',
  'oifde.mxgraph.decreaseIndent': '减少缩进',
  'oifde.mxgraph.default': '默认',
  'oifde.mxgraph.delete': '删除',
  'oifde.mxgraph.deleteColumn': '删除列',
  'oifde.mxgraph.deleteRow': '删除行',
  'oifde.mxgraph.diagram': '流程图',
  'oifde.mxgraph.diamond': '菱形',
  'oifde.mxgraph.diamondThin': '菱形（薄）',
  'oifde.mxgraph.direction': '方向',
  'oifde.mxgraph.distribute': '分发',
  'oifde.mxgraph.divider': '分配者',
  'oifde.mxgraph.documentProperties': '文档属性',
  'oifde.mxgraph.dotted': '有点的',
  'oifde.mxgraph.drawing': '绘图{0}',
  'oifde.mxgraph.drawingEmpty': '绘图是空的',
  'oifde.mxgraph.drawingTooLarge': '流程图太大',
  'oifde.mxgraph.duplicate': '复制',
  'oifde.mxgraph.duplicateIt': '复制{0}',
  'oifde.mxgraph.east': '东',
  'oifde.mxgraph.edit': '编辑',
  'oifde.mxgraph.editData': '编辑数据',
  'oifde.mxgraph.editDiagram': '源码',
  'oifde.mxgraph.editImage': '编辑图片',
  'oifde.mxgraph.editLink': '编辑链接',
  'oifde.mxgraph.editStyle': '编辑样式',
  'oifde.mxgraph.editTooltip': '编辑提示',
  'oifde.mxgraph.enterGroup': '输入组',
  'oifde.mxgraph.enterValue': '输入值',
  'oifde.mxgraph.enterName': '输入名称',
  'oifde.mxgraph.enterPropertyName': '输入属性名',
  'oifde.mxgraph.entityRelation': '实体关系',
  'oifde.mxgraph.exitGroup': '退出组',
  'oifde.mxgraph.expand': '展开',
  'oifde.mxgraph.export': '导出',
  'oifde.mxgraph.extras': '附加',
  'oifde.mxgraph.file': '文件',
  'oifde.mxgraph.fileNotFound': '文件未找到',
  'oifde.mxgraph.filename': '文件名',
  'oifde.mxgraph.fill': '填充',
  'oifde.mxgraph.fillColor': '填充颜色',
  'oifde.mxgraph.fitPage': '适应一页',
  'oifde.mxgraph.fitPageWidth': '适应页宽',
  'oifde.mxgraph.fitTwoPages': '适应两页',
  'oifde.mxgraph.fitWindow': '适应窗口',
  'oifde.mxgraph.flip': '翻转',
  'oifde.mxgraph.flipH': '水平翻转',
  'oifde.mxgraph.flipV': '垂直翻转',
  'oifde.mxgraph.font': '字体',
  'oifde.mxgraph.fontFamily': '字体系列',
  'oifde.mxgraph.fontColor': '字体颜色',
  'oifde.mxgraph.fontSize': '字体大小',
  'oifde.mxgraph.format': '格式',
  'oifde.mxgraph.formatPanel': '属性面板',
  'oifde.mxgraph.formatPdf': 'PDF',
  'oifde.mxgraph.formatPng': 'PNG',
  'oifde.mxgraph.formatGif': 'GIF',
  'oifde.mxgraph.formatJpg': 'JPEG',
  'oifde.mxgraph.formatSvg': 'SVG',
  'oifde.mxgraph.formatXml': 'XML',
  'oifde.mxgraph.formatted': '格式化',
  'oifde.mxgraph.formattedText': '格式化文本',
  'oifde.mxgraph.gap': '间隙',
  'oifde.mxgraph.general': '普通',
  'oifde.mxgraph.glass': '透明',
  'oifde.mxgraph.global': '全局',
  'oifde.mxgraph.gradient': '梯度',
  'oifde.mxgraph.gradientColor': '颜色',
  'oifde.mxgraph.grid': '网格',
  'oifde.mxgraph.gridSize': '网格大小',
  'oifde.mxgraph.group': '组',
  'oifde.mxgraph.guides': '向导',
  'oifde.mxgraph.heading': '标题',
  'oifde.mxgraph.height': '高度',
  'oifde.mxgraph.help': '帮助',
  'oifde.mxgraph.hide': '隐藏',
  'oifde.mxgraph.hideIt': '隐藏{0}',
  'oifde.mxgraph.hidden': '隐蔽',
  'oifde.mxgraph.home': '首页',
  'oifde.mxgraph.horizontal': '水平',
  'oifde.mxgraph.horizontalFlow': '水平的流',
  'oifde.mxgraph.horizontalTree': '水平的树',
  'oifde.mxgraph.html': 'HTML',
  'oifde.mxgraph.image': '图片',
  'oifde.mxgraph.images': '图片',
  'oifde.mxgraph.import': '导入',
  'oifde.mxgraph.increaseIndent': '增加缩进',
  'oifde.mxgraph.insert': '插入',
  'oifde.mxgraph.insertColumnBefore': '向左插入列',
  'oifde.mxgraph.insertColumnAfter': '向右插入列',
  'oifde.mxgraph.insertHorizontalRule': '插入水平分隔',
  'oifde.mxgraph.insertImage': '插入图片',
  'oifde.mxgraph.insertLink': '插入链接',
  'oifde.mxgraph.insertRowBefore': '在上面插入行',
  'oifde.mxgraph.insertRowAfter': '在下面插入行',
  'oifde.mxgraph.invalidName': '无效名称',
  'oifde.mxgraph.invalidOrMissingFile': '文件无效或缺失',
  'oifde.mxgraph.isometric': '等距',
  'oifde.mxgraph.italic': '斜体',
  'oifde.mxgraph.layers': '图层',
  'oifde.mxgraph.landscape': '横向',
  'oifde.mxgraph.laneColor': '泳道颜色',
  'oifde.mxgraph.layout': '布局',
  'oifde.mxgraph.left': '左',
  'oifde.mxgraph.leftAlign': '左对齐',
  'oifde.mxgraph.leftToRight': '左到右',
  'oifde.mxgraph.line': '线',
  'oifde.mxgraph.link': '链接',
  'oifde.mxgraph.lineJumps': '线跳过',
  'oifde.mxgraph.lineend': '线结束',
  'oifde.mxgraph.lineheight': '线高度',
  'oifde.mxgraph.linestart': '线开始',
  'oifde.mxgraph.linewidth': '线宽',
  'oifde.mxgraph.loading': '载入中',
  'oifde.mxgraph.lockUnlock': '锁定/解锁',
  'oifde.mxgraph.manual': '手册',
  'oifde.mxgraph.middle': '横向对齐',
  'oifde.mxgraph.misc': '杂项',
  'oifde.mxgraph.more': '更多',
  'oifde.mxgraph.moreResults': '更多结果',
  'oifde.mxgraph.move': '移动',
  'oifde.mxgraph.moveSelectionTo': '将选项移至{0}',
  'oifde.mxgraph.navigation': '导航',
  'oifde.mxgraph.new': '新建',
  'oifde.mxgraph.noColor': '没有颜色',
  'oifde.mxgraph.noFiles': '没有文件',
  'oifde.mxgraph.noMoreResults': '没有更多结果',
  'oifde.mxgraph.none': '空',
  'oifde.mxgraph.noResultsFor': '没有结果\'{0}\'',
  'oifde.mxgraph.normal': '正常',
  'oifde.mxgraph.north': '北',
  'oifde.mxgraph.numberedList': '编号列表',
  'oifde.mxgraph.opacity': '不透明度',
  'oifde.mxgraph.open': '打开',
  'oifde.mxgraph.openArrow': '打开箭头',
  'oifde.mxgraph.openFile': '打开文件',
  'oifde.mxgraph.openLink': '打开链接',
  'oifde.mxgraph.openSupported': '支持的格式是从本系统导出的.XML文件',
  'oifde.mxgraph.openInNewWindow': '在新窗口中打开',
  'oifde.mxgraph.openInThisWindow': '在当前窗口中打开',
  'oifde.mxgraph.options': '选项',
  'oifde.mxgraph.organic': '有机',
  'oifde.mxgraph.orthogonal': '正交',
  'oifde.mxgraph.outline': '鸟瞰图',
  'oifde.mxgraph.oval': '椭圆',
  'oifde.mxgraph.pages': '页',
  'oifde.mxgraph.pageView': '页面视图',
  'oifde.mxgraph.pageScale': '页面比例',
  'oifde.mxgraph.pageSetup': '页面设置',
  'oifde.mxgraph.panTooltip': '空格+拖动滚动',
  'oifde.mxgraph.paperSize': '纸张大小',
  'oifde.mxgraph.paste': '粘贴',
  'oifde.mxgraph.pasteHere': '粘贴在这里',
  'oifde.mxgraph.pattern': '模式',
  'oifde.mxgraph.perimeter': '周长',
  'oifde.mxgraph.placeholders': '占位符',
  'oifde.mxgraph.plusTooltip': '点击连接并克隆（按Ctrl +单击克隆，Shift +单击连接）。 拖动连接（按Ctrl +拖动克隆）。',
  'oifde.mxgraph.portrait': '纵向',
  'oifde.mxgraph.position': '位置',
  'oifde.mxgraph.posterPrint': '海报打印',
  'oifde.mxgraph.preview': '预览',
  'oifde.mxgraph.print': '打印',
  'oifde.mxgraph.radialTree': '径向树',
  'oifde.mxgraph.redo': '重做',
  'oifde.mxgraph.remindSave': '您已长时间未保存，请及时保存。',
  'oifde.mxgraph.removeFormat': '清除格式',
  'oifde.mxgraph.removeFromGroup': '从组中删除',
  'oifde.mxgraph.removeIt': '删除{0}',
  'oifde.mxgraph.removeWaypoint': '删除路标',
  'oifde.mxgraph.rename': '重命名',
  'oifde.mxgraph.renameIt': '重命名{0}',
  'oifde.mxgraph.replace': '替换',
  'oifde.mxgraph.replaceIt': '{0}已经存在，你想替换它吗？',
  'oifde.mxgraph.replaceExistingDrawing': '替换现有的绘图',
  'oifde.mxgraph.reset': '重置',
  'oifde.mxgraph.resetView': '重置视图',
  'oifde.mxgraph.reverse': '倒转',
  'oifde.mxgraph.right': '右',
  'oifde.mxgraph.rightAlign': '右对齐',
  'oifde.mxgraph.rightToLeft': '右到左',
  'oifde.mxgraph.rotate': '旋转',
  'oifde.mxgraph.rotateTooltip': '点击并拖动旋转，点击旋转90度',
  'oifde.mxgraph.rotation': '旋转',
  'oifde.mxgraph.rounded': '圆形',
  'oifde.mxgraph.save': '保存',
  'oifde.mxgraph.saveAs': '另存为',
  'oifde.mxgraph.saved': '已保存',
  'oifde.mxgraph.scrollbars': '滚动条',
  'oifde.mxgraph.search': '搜索',
  'oifde.mxgraph.searchShapes': '搜索节点',
  'oifde.mxgraph.selectAll': '全选',
  'oifde.mxgraph.selectEdges': '选择连线',
  'oifde.mxgraph.selectFont': '选择一个字体',
  'oifde.mxgraph.selectNone': '没有选择',
  'oifde.mxgraph.selectVertices': '选择节点',
  'oifde.mxgraph.setAsDefaultStyle': '设置为默认样式',
  'oifde.mxgraph.shadow': '阴影',
  'oifde.mxgraph.shape': '形状',
  'oifde.mxgraph.sharp': '尖锐',
  'oifde.mxgraph.sidebarTooltip': '点击展开或收起节点面板。',
  'oifde.mxgraph.simple': '简单',
  'oifde.mxgraph.simpleArrow': '简单箭头',
  'oifde.mxgraph.size': '大小',
  'oifde.mxgraph.solid': '实心',
  'oifde.mxgraph.sourceSpacing': '源间距',
  'oifde.mxgraph.south': '南',
  'oifde.mxgraph.spacing': '间距',
  'oifde.mxgraph.straight': '直线',
  'oifde.mxgraph.strokeColor': '边框颜色',
  'oifde.mxgraph.style': '样式',
  'oifde.mxgraph.subscript': '订阅',
  'oifde.mxgraph.superscript': '上标',
  'oifde.mxgraph.table': '表格',
  'oifde.mxgraph.targetSpacing': '目标间距',
  'oifde.mxgraph.text': '文本',
  'oifde.mxgraph.textAlignment': '文本对齐',
  'oifde.mxgraph.textOpacity': '文本不透明度',
  'oifde.mxgraph.toBack': '图层下移',
  'oifde.mxgraph.toFront': '图层上移',
  'oifde.mxgraph.tooltips': '工具提示',
  'oifde.mxgraph.top': '顶端',
  'oifde.mxgraph.topAlign': '顶端对齐',
  'oifde.mxgraph.topLeft': '左上角',
  'oifde.mxgraph.topRight': '右上角',
  'oifde.mxgraph.transparent': '透明',
  'oifde.mxgraph.turn': '旋转90度',
  'oifde.mxgraph.uml': 'UML',
  'oifde.mxgraph.underline': '下划线',
  'oifde.mxgraph.undo': '撤销',
  'oifde.mxgraph.ungroup': '取消组合',
  'oifde.mxgraph.url': 'URL',
  'oifde.mxgraph.untitledLayer': '无标题层',
  'oifde.mxgraph.vertical': '垂直',
  'oifde.mxgraph.verticalFlow': '垂直流',
  'oifde.mxgraph.verticalTree': '垂直树',
  'oifde.mxgraph.view': '查看',
  'oifde.mxgraph.waypoints': '路标',
  'oifde.mxgraph.west': '西',
  'oifde.mxgraph.width': '宽度',
  'oifde.mxgraph.wordWrap': '自动换行',
  'oifde.mxgraph.writingDirection': '写的方向',
  'oifde.mxgraph.zoom': '放大',
  'oifde.mxgraph.zoomIn': '放大',
  'oifde.mxgraph.zoomOut': '缩小',
  'oifde.mxgraph.success': '成功',
  'oifde.mxgraph.prompt': '提示',
  'oifde.mxgraph.NMS_DiagramProperties': '流程属性',
  'oifde.mxgraph.NMS_BasicProperties': '基本属性',
  'oifde.mxgraph.NMS_DiagramID': '流程ID',
  'oifde.mxgraph.NMS_Status': '状态',
  'oifde.mxgraph.NMS_DiagramCode': '流程编码',
  'oifde.mxgraph.NMS_Valid': '有效',
  'oifde.mxgraph.NMS_Invalid': '无效',
  'oifde.mxgraph.NMS_DiagramName': '流程名称',
  'oifde.mxgraph.NMS_Region': '区域',
  'oifde.mxgraph.NMS_ScenarioType': '场景类型',
  'oifde.mxgraph.NMS_Desciption': '描述',
  'oifde.mxgraph.NMS_NodeProperties': '节点属性',
  'oifde.mxgraph.NMS_NodeCode': '节点编码',
  'oifde.mxgraph.NMS_NodeName': '节点名称',
  'oifde.mxgraph.NMS_BranchProperties': '分支属性',
  'oifde.mxgraph.NMS_BranchCondition': '分支条件',
  'oifde.mxgraph.NMS_DefaultBranch': '默认分支',
  'oifde.mxgraph.NMS_AdvanceProperties': '高级属性',
  'oifde.mxgraph.NMS_ServiceName': '服务名称',
  'oifde.mxgraph.NMS_ServiceParams': '服务参数',
  'oifde.mxgraph.NMS_ServiceDesciption': '服务描述',
  'oifde.mxgraph.NMS_PleaseSelect': '请选择',
  'oifde.mxgraph.NMS_ParamName': '参数名称',
  'oifde.mxgraph.NMS_ParamValue': '参数值',
  'oifde.mxgraph.NMS_ParamDescription': '参数说明',
  'oifde.mxgraph.NMS_FlowSaveSuccess': '流程保存成功！',
  'oifde.mxgraph.NMS_FlowSaveFail': '流程保存失败！',
  'oifde.mxgraph.NMS_FlowOpenFail': '流程打开失败！',
  'oifde.mxgraph.NMS_ActionTypesGetFail': '逻辑类型获取失败。',
  'oifde.mxgraph.NMS_ActionParamsGetFail': '逻辑参数获取失败。',
  'oifde.mxgraph.NMS_FlowsGetFail': '流程列表获取失败。',
  'oifde.mxgraph.NMS_NodesGetFail': '流程{0}的节点列表获取失败。',
  'oifde.mxgraph.NMS_InterfacesGetFail': '访问接口列表获取失败。',
  'oifde.mxgraph.NMS_ContentTemplateGetFail': '内容模板列表获取失败。',
  'oifde.mxgraph.NMS_EditorModelLoadFail': '编辑器模型配置加载失败。',
  'oifde.mxgraph.NMS_RegionInfoLoadFail': '区域信息加载失败。',
  'oifde.mxgraph.NMS_IntentCodeLoadFail': '意图码加载失败。',
  'oifde.mxgraph.NMS_PleaseSelectTemplate': '请选择模板。',
  'oifde.mxgraph.NMS_TargetFlow': '目标流程',
  'oifde.mxgraph.NMS_TargetNode': '目标节点',
  'oifde.mxgraph.NMS_SearchTemplate': '搜索模板',
  'oifde.mxgraph.NMS_TemplateCode': '模板编码',
  'oifde.mxgraph.NMS_flow': '流程',
  'oifde.mxgraph.NMS_ScenarioType_main': '主流程',
  'oifde.mxgraph.NMS_ScenarioType_sub': '子流程',
  'oifde.mxgraph.NMS_ScenarioType_error': '异常场景',
  'oifde.mxgraph.NMS_flowCodeError': '流程编码不能为空或者长度不能大于64位！',
  'oifde.mxgraph.NMS_flowNameError': '流程名称不能为空或者长度不能大于1000位！',
  'oifde.mxgraph.NMS_flowDescError': '流程描述不能大于1000位！',
  'oifde.mxgraph.NMS_nmsFlowError': '流程解析失败，没有找到nmsFlow节点！',
  'oifde.mxgraph.NMS_rootError': '流程解析失败，没有找到root节点！',
  'oifde.mxgraph.NMS_VariableNameError': '变量名长度不能超过1000位！',
  'oifde.mxgraph.NMS_VariableDescError': '变量描述不能超过1000位！',
  'oifde.mxgraph.NMS_VariableDefaultValueError': '变量缺省值不能超过1024位！',
  'oifde.mxgraph.NMS_NodeCodeError': '节点编码不能为空或者不能超过64位！',
  'oifde.mxgraph.NMS_NodeNameError': '节点名不能为空或者不能超过1000位！',
  'oifde.mxgraph.NMS_NodeDescError': '节点描述不能超过1000位！',
  'oifde.mxgraph.NMS_actionParamNameError': '参数名不能超过64位！',
  'oifde.mxgraph.NMS_actionParamValueEmptyError': '参数值不能为空！',
  'oifde.mxgraph.NMS_actionParamValueError': '参数值不能超过4096位！',
  'oifde.mxgraph.NMS_actionParamDescError': '参数描述不能超过500位！',
  'oifde.mxgraph.NMS_transitToStartNodeError': '不能跳转到开始节点！',
  'oifde.mxgraph.NMS_endNodetransitToError': '结束节点不能跳转到别的节点！',
  'oifde.mxgraph.NMS_startNodeNotFind': '没有找到开始节点！',
  'oifde.mxgraph.NMS_startNodeNotUnique': '开始节点不唯一！',
  'oifde.mxgraph.NMS_startNodeNotBranchError': '开始节点没有分支或者多于一个分支！',
  'oifde.mxgraph.NMS_endNodeNotFind': '没有找到结束节点！',
  'oifde.mxgraph.NMS_analiseXmlFailed': '解析流程画布失败！',
  'oifde.mxgraph.NMS_branchError': '分支没有起始节点或者结束节点！',
  'oifde.mxgraph.NMS_nullBranchNotNuique': '节点的缺省分支不能超过1条！',
  'oifde.mxgraph.NMS_checkDefaultNextNodeForView': 'view类型的图元不能有多个分支！',
  'oifde.mxgraph.NMS_nodeRefError': '节点被其他流程引用，不能删除！',
  'oifde.mxgraph.NMS_ValidateError': '校验出错，请检查标红处！',
  'oifde.mxgraph.NMS_SpaceValidate': '不能以空格开头!',
  'oifde.mxgraph.NMS_KeyRepeats': '条件名重复',
  'oifde.mxgraph.NMS_encryptValidate': '加密类型的IVRREQUEST变量，流程变量和全局变量只能赋值给加密类型的流程变量和全局变量!',
  'oifde.mxgraph.NMS_timeSelectLengthValidate': '服务时间段最少需要配置一条数据!',
  'oifde.knowledgegraph.actions.edit': '编辑',
  'oifde.knowledgegraph.actions.view': '查看',
  'oifde.knowledgegraph.actions.delete': '删除',
  'oifde.knowledgegraph.actions.clean': '清空',
  'oifde.knowledgegraph.actions.prompt': '提示',
  'oifde.knowledgegraph.actions.confirm': '确认',
  'oifde.knowledgegraph.actions.nameRequiredTip': '名称必填',
  'oifde.knowledgegraph.actions.selectTip': '请选择',
  'oifde.knowledgegraph.actions.cancel': '取消',
  'oifde.knowledgegraph.actions.cancelDelete': '已取消删除',
  'oifde.knowledgegraph.actions.success': '成功',
  'oifde.knowledgegraph.actions.updateSuccess': '修改成功！',
  'oifde.knowledgegraph.actions.deleteSuccess': '删除成功！',
  'oifde.knowledgegraph.actions.createSuccess': '新增成功！',
  'oifde.knowledgegraph.actions.createFailed': '新增失败！',
  'oifde.knowledgegraph.actions.modifyFailed': '修改失败！',
  'oifde.knowledgegraph.actions.deleteFailed': '删除失败！',
  'oifde.knowledgegraph.actions.saveSuccess': '保存成功！',
  'oifde.knowledgegraph.actions.saveFailed': '保存失败！',
  'oifde.knowledgegraph.actions.addSchema': '新增Schema',
  'oifde.knowledgegraph.actions.editSchema': '编辑Schema',
  'oifde.knowledgegraph.actions.addDic': '新增图谱字典',
  'oifde.knowledgegraph.actions.editDic': '编辑图谱字典',
  'oifde.knowledgegraph.actions.addEntity': '新增实体',
  'oifde.knowledgegraph.actions.editEntity': '编辑实体',
  'oifde.knowledgegraph.actions.addRelation': '新增关系',
  'oifde.knowledgegraph.actions.editRelation': '编辑关系',
  'oifde.knowledgegraph.actions.addAttribute': '新增属性',
  'oifde.knowledgegraph.actions.editAttribute': '编辑属性',
  'oifde.knowledgegraph.actions.addSynonyms': '新增同义词',
  'oifde.knowledgegraph.actions.editSynonyms': '编辑同义词',
  'oifde.knowledgegraph.actions.addExtQuestion': '新增扩展问',
  'oifde.knowledgegraph.actions.editExtQuestion': '编辑扩展问',
  'oifde.knowledgegraph.actions.viewSchema': 'Schema查看',
  'oifde.knowledgegraph.actions.graphicPreview': '图形预览',
  'oifde.knowledgegraph.actions.addSchemaEntity': '新增概念',
  'oifde.knowledgegraph.actions.editSchemaEntity': '编辑概念',
  'oifde.knowledgegraph.model.schemaName': 'Schema名称',
  'oifde.knowledgegraph.model.schemaCode': 'Schema编码',
  'oifde.knowledgegraph.model.schemaDescription': '描述',
  'oifde.knowledgegraph.model.modifyTime': '修改时间',
  'oifde.knowledgegraph.model.operation': '操作',
  'oifde.knowledgegraph.model.createUser': '创建者',
  'oifde.knowledgegraph.model.beforeDeletePrompt': '当前Schema已与图谱字典关联, 无法删除。关联图谱字典：',
  'oifde.knowledgegraph.model.beforeEditPrompt': '当前Schema已与图谱字典关联, 无法修改。关联图谱字典：',
  'oifde.knowledgegraph.model.deletePrompt': '此操作将永久删除该Schema以及Schema所包含的概念、关系和属性, 是否继续?',
  'oifde.knowledgegraph.editSchema.entity': '概念',
  'oifde.knowledgegraph.editSchema.entityName': '概念名',
  'oifde.knowledgegraph.editSchema.relation': '关系',
  'oifde.knowledgegraph.editSchema.relationName': '关系名',
  'oifde.knowledgegraph.editSchema.headEntity': '头概念',
  'oifde.knowledgegraph.editSchema.tailEntity': '尾概念',
  'oifde.knowledgegraph.editSchema.selectTip': '请选择',
  'oifde.knowledgegraph.editSchema.objectName': '概念/关系',
  'oifde.knowledgegraph.editSchema.attributeName': '属性名',
  'oifde.knowledgegraph.editSchema.attributeType': '属性类型',
  'oifde.knowledgegraph.editSchema.unit': '单位',
  'oifde.knowledgegraph.editSchema.type': '类型',
  'oifde.knowledgegraph.editSchema.schemaNoDataTip': '请先在左侧栏添加概念和关系数据然后点击对应概念或关系右侧的查看按钮以查看对应数据的属性内容。',
  'oifde.knowledgegraph.editSchema.deleteEntityPrompt': '此操作将永久删除该概念以及该概念所包含的属性, 是否继续?',
  'oifde.knowledgegraph.editSchema.deleteRelationPrompt': '此操作将永久删除该关系以及该关系所包含的属性, 是否继续?',
  'oifde.knowledgegraph.editSchema.deleteAttributePrompt': '此操作将永久删除该属性, 是否继续?',
  'oifde.knowledgegraph.editSchema.dataType.int': '整型',
  'oifde.knowledgegraph.editSchema.dataType.float': '浮点型',
  'oifde.knowledgegraph.editSchema.dataType.boolean': '布尔型',
  'oifde.knowledgegraph.editSchema.dataType.string': '字符型',
  'oifde.knowledgegraph.editSchema.dataType.date': '日期型',
  'oifde.knowledgegraph.dicmgr.dicName': '图谱名称',
  'oifde.knowledgegraph.dicmgr.dicCode': '图谱编码',
  'oifde.knowledgegraph.dicmgr.dicFunction': '图谱用途',
  'oifde.knowledgegraph.dicmgr.modifyTime': '修改时间',
  'oifde.knowledgegraph.dicmgr.schemaName': '关联Schema',
  'oifde.knowledgegraph.dicmgr.kgState': '图谱状态',
  'oifde.knowledgegraph.dicmgr.operation': '操作',
  'oifde.knowledgegraph.dicmgr.moreOperation': '更多',
  'oifde.knowledgegraph.dicmgr.entityMgr': '实体管理',
  'oifde.knowledgegraph.dicmgr.relationMgr': '关系管理',
  'oifde.knowledgegraph.dicmgr.kgView': '图谱可视',
  'oifde.knowledgegraph.dicmgr.kgFAQ': '图谱问答',
  'oifde.knowledgegraph.dicmgr.active': '激活',
  'oifde.knowledgegraph.dicmgr.inactive': '去激活',
  'oifde.knowledgegraph.dicmgr.creating': '创建中',
  'oifde.knowledgegraph.dicmgr.trainAll': '全部训练',
  'oifde.knowledgegraph.dicmgr.trainSuccess': '训练成功！',
  'oifde.knowledgegraph.dicmgr.trainFail': '训练失败！',
  'oifde.knowledgegraph.dicmgr.changeState': '修改状态',
  'oifde.knowledgegraph.dicmgr.synonyms': '同义词',
  'oifde.knowledgegraph.dicmgr.extendQuestion': '扩展问',
  'oifde.knowledgegraph.dicmgr.deletePrompt': '此操作将永久删除该字典以及字典所包含的实体、关系和属性, 是否继续?',
  'oifde.knowledgegraph.entitymgr.entityName': '实体名称',
  'oifde.knowledgegraph.entitymgr.schemaEntityName': '关联Schema概念',
  'oifde.knowledgegraph.entitymgr.beforeDeletePrompt': '请先删除该实体对应的关系后再删除该实体',
  'oifde.knowledgegraph.entitymgr.deletePrompt': '此操作将永久删除该实体, 是否继续?',
  'oifde.knowledgegraph.entitymgr.entityAttributeMgr.deletePrompt': '此操作将永久删除该实体属性, 是否继续?',
  'oifde.knowledgegraph.relationmgr.headEntity': '头实体',
  'oifde.knowledgegraph.relationmgr.tailEntity': '尾实体',
  'oifde.knowledgegraph.relationmgr.relation': '关系',
  'oifde.knowledgegraph.relationmgr.doubleRelation': '双向关系',
  'oifde.knowledgegraph.relationmgr.doubleRelationTrue': '是',
  'oifde.knowledgegraph.relationmgr.doubleRelationFalse': '否',
  'oifde.knowledgegraph.relationmgr.doublePrompt': '头实体与尾实体一致，无法建立关系，请重新选择头实体或尾实体。',
  'oifde.knowledgegraph.relationmgr.deletePrompt': '此操作将永久删除该关系以及关系中包含的属性, 是否继续?',
  'oifde.knowledgegraph.attributemgr.attributeName': '属性名称',
  'oifde.knowledgegraph.attributemgr.attribute': '属性值',
  'oifde.knowledgegraph.attributemgr.description': '属性描述',
  'oifde.knowledgegraph.attributemgr.deletePrompt': '此操作将永久删除该属性, 是否继续?',
  'oifde.knowledgegraph.kgFAQ.title': '图谱问答',
  'oifde.knowledgegraph.kgFAQ.name': '图谱字典: ',
  'oifde.knowledgegraph.kgFAQ.welcomeContent': '你好！我是智能助手，很高兴为您服务。',
  'oifde.knowledgegraph.kgSynonyms.keyword': '名称',
  'oifde.knowledgegraph.kgSynonyms.synonyms': '同义词',
  'oifde.knowledgegraph.kgSynonyms.number': 'No.',
  'oifde.knowledgegraph.kgSynonyms.synonymsDetail': '同义词详情',
  'oifde.knowledgegraph.kgSynonyms.name': '名称',
  'oifde.knowledgegraph.kgSynonyms.deletePrompt': '此操作将永久删除该词条以及其所有同义词, 是否继续?',
  'oifde.knowledgegraph.kgSynonyms.deleteSynonymsPrompt': '此操作将永久删除该同义词, 是否继续?',
  'oifde.knowledgegraph.kgSynonyms.type.type': '类型',
  'oifde.knowledgegraph.kgSynonyms.type.entity': '实体',
  'oifde.knowledgegraph.kgSynonyms.type.relation': '关系',
  'oifde.knowledgegraph.kgSynonyms.type.property': '属性',
  'oifde.knowledgegraph.kgView.queryType': '查询类型',
  'oifde.knowledgegraph.kgView.entityName': '实体名称',
  'oifde.knowledgegraph.kgView.displayType': '展示方式',
  'oifde.knowledgegraph.kgView.queryByName': '按实体名称查询',
  'oifde.knowledgegraph.validation.schemaName': 'Schema名称必填',
  'oifde.knowledgegraph.validation.dicName': '字典名必填',
  'oifde.knowledgegraph.validation.selectRequired': '必选',
  'oifde.knowledgegraph.validation.attrValue': '属性值必填',
  'oifde.knowledgegraph.validation.required': '必填',
  'oifde.knowledgegraph.errorCode.100010000000': '系统错误。',
  'oifde.knowledgegraph.errorCode.100010000003': '不允许重名。',
  'oifde.knowledgegraph.errorCode.100010000002': '格式错误。',
  'oifde.knowledgegraph.errorCode.100051001013': 'Schema正在使用。',
  'oifde.knowledgegraph.errorCode.100051001012': '该名称已配置同义词。',
  'oifde.knowledgegraph.errorCode.100010000001': '数据库错误。',
  'oifde.knowledgegraph.errorCode.100051000110': '数量已经达到上限10。',
  'oifde.knowledgegraph.errorCode.100051000111': '数量已经达到上限190。',
  'oifde.knowledgegraph.errorCode.100051000113': 'GES服务URL配置不正确。',
  'oifde.knowledgegraph.errorCode.100051000112': '图谱正在准备中...',
  'oifde.knowledgegraph.errorCode.100061000005': '该知识图谱已经被绑定',
  'oifde.knowledgegraph.errorCode.100010000005': '输入参数不正确',
  'oifde.knowledgegraph.errorCode.100051000114': '保存图谱问答扩展问失败',
  'oifde.knowledgegraph.errorCode.100061000006': '连接ODFS失败',
  'oifde.knowledgegraph.extendFaq.setting': '扩展问配置',
  'oifde.knowledgegraph.extendFaq.import': '导入',
  'oifde.knowledgegraph.extendFaq.export': '全部导出',
  'oifde.knowledgegraph.extendFaq.extendFaq': '问答模板列表',
  'oifde.knowledgegraph.extendFaq.faqTemplate': '问答模板',
  'oifde.knowledgegraph.extendFaq.description': '描述',
  'oifde.knowledgegraph.extendFaq.updateTime': '更新时间',
  'oifde.knowledgegraph.extendFaq.status': '状态',
  'oifde.knowledgegraph.extendFaq.setTemplate': '配置扩展问',
  'oifde.knowledgegraph.extendFaq.use': '使用/不使用',
  'oifde.knowledgegraph.extendFaq.question': '问',
  'oifde.knowledgegraph.extendFaq.reply': '回复',
  'oifde.knowledgegraph.extendFaq.similarQuestion': '相似问',
  'oifde.knowledgegraph.extendFaq.similarReply': '相似回复',
  'oifde.knowledgegraph.extendFaq.edit': '编辑-',
  'oifde.knowledgegraph.extendFaq.inUse': '使用',
  'oifde.knowledgegraph.extendFaq.notUse': '不使用',
  'oifde.knowledgegraph.extendFaq.operation': '操作',
  'oifde.knowledgegraph.extendFaq.updateSuccess': '修改使用状态成功！',
  'oifde.knowledgegraph.extendFaq.updateFailed': '修改使用状态失败！',
  'oifde.knowledgegraph.extendFaq.updateTemplateSuccess': '修改问答模板成功！',
  'oifde.knowledgegraph.extendFaq.updateTemplateFailed': '修改问答模板失败！',
  'oifde.whitelist.tenantId': '租间ID',
  'oifde.whitelist.tenantSpace': '租间',
  'oifde.whitelist.whiteIp': '白名单IP或域名',
  'oifde.whitelist.whitePort': '白名单端口',
  'oifde.whitelist.whiteType': '白名单类型',
  'oifde.whitelist.description': '描述',
  'oifde.whitelist.whiteTypes.INTERFACE': '接口',
  'oifde.whitelist.whiteTypes.PROCEDURE': '存储过程',
  'oifde.whitelist.addWhiteList': '新建白名单',
  'oifde.whitelist.editWhiteList': '编辑白名单',
  'oifde.whitelist.whiteIpLen': '白名单Ip或域名不能超过128位',
  'oifde.whitelist.whitePortLen': '白名单端口不能超过20位',
  'oifde.whitelist.descriptionLen': '描述不能超过1024位',
  'oifde.whitelist.number': '只允许输入数字',
  'oifde.whitelist.whiteIpListNotNull': '请至少选择一个要删除的白名单',
  'oifde.whitelist.deletePrompt': '此操作将永久删除选中的白名单, 是否继续?',
  'oifde.whitelist.ipRange': 'IP仅支持由字母、数字、点、下划线和中划线组成，且不能以中划线开头或结尾。',
  'oifde.operationlog.generate_test_case': '用例自动生成',
  'oifde.operationlog.stop_auto_generate': '中断用例生成',
  'oifde.operationlog.logging_module': '模块',
  'oifde.operationlog.operation_log': '操作日志',
  'oifde.operationlog.operator': '帐号',
  'oifde.operationlog.operation_obj_type': '操作对象类型',
  'oifde.operationlog.startTime': '开始时间',
  'oifde.operationlog.endTime': '结束时间',
  'oifde.operationlog.operationType': '操作类型',
  'oifde.operationlog.targetObjType': '对象类型',
  'oifde.operationlog.targetObjId': '对象ID',
  'oifde.operationlog.targetObjName': '对象名称',
  'oifde.operationlog.result': '结果',
  'oifde.operationlog.detail': '详细信息',
  'oifde.operationlog.operationTime': '操作时间',
  'oifde.operationlog.loginIp': '登录IP',
  'oifde.operationlog.check': '校验',
  'oifde.operationlog.query': '查询',
  'oifde.operationlog.create': '创建',
  'oifde.operationlog.copy': '复制',
  'oifde.operationlog.paste': '粘贴',
  'oifde.operationlog.clone': '克隆',
  'oifde.operationlog.import': '导入',
  'oifde.operationlog.export': '导出',
  'oifde.operationlog.update': '更新',
  'oifde.operationlog.delete': '删除',
  'oifde.operationlog.enable': '激活',
  'oifde.operationlog.disable': '去激活',
  'oifde.operationlog.set_test': '设置为测试',
  'oifde.operationlog.hang_up': '挂断',
  'oifde.operationlog.release': '发布',
  'oifde.operationlog.invalidate': '失效',
  'oifde.operationlog.upgrade': '升级',
  'oifde.operationlog.set_white_list': '设置灰度规则',
  'oifde.operationlog.intent_template': '意图模板',
  'oifde.operationlog.user_entity': '用户实体',
  'oifde.operationlog.system_entity': '系统实体',
  'oifde.operationlog.voice_content': '语音文件',
  'oifde.operationlog.system_parameters': '系统参数',
  'oifde.operationlog.intent_parameters': '意图参数',
  'oifde.operationlog.call_session': '呼叫会话',
  'oifde.operationlog.cluster_task': '聚类任务',
  'oifde.operationlog.history_dialogue': '历史对话',
  'oifde.operationlog.test_case_group': '测试用例组',
  'oifde.operationlog.test_case': '测试用例',
  'oifde.operationlog.faq': '问答组',
  'oifde.operationlog.faq_element': '问/答',
  'oifde.operationlog.log_parameters': '日志参数',
  'oifde.operationlog.tps_interface': '业务服务接口',
  'oifde.operationlog.nms_interface': '对话流程接口',
  'oifde.operationlog.white_list': '知识灰度规则',
  'oifde.operationlog.white_list_users': '知识灰度规则用户号码',
  'oifde.operationlog.sensitive_words': '敏感词',
  'oifde.operationlog.stop_words': '停用词',
  'oifde.operationlog.navigation_info': '导航信息',
  'oifde.operationlog.command': '命令',
  'oifde.operationlog.api_key': 'API密钥',
  'oifde.operationlog.trace_log': '跟踪日志配置',
  'oifde.operationlog.succeed': '成功',
  'oifde.operationlog.failed': '失败',
  'oifde.operationlog.domain': '领域',
  'oifde.operationlog.service_node': '服务节点',
  'oifde.operationlog.online': '上线',
  'oifde.operationlog.offline': '下线',
  'oifde.operationlog.flow': '流程',
  'oifde.operationlog.performance_data': '性能数据',
  'oifde.operationlog.login': '登录',
  'oifde.operationlog.logout': '登出',
  'oifde.operationlog.change_password': '修改密码',
  'oifde.operationlog.reset_password': '重置密码',
  'oifde.operationlog.authorize': '认证',
  'oifde.operationlog.tenant_space': '租间',
  'oifde.operationlog.account': '帐号',
  'oifde.operationlog.role': '角色',
  'oifde.operationlog.password_rule': '密码规则',
  'oifde.operationlog.password_rule_group': '密码规则组',
  'oifde.operationlog.user_account': '帐号',
  'oifde.operationlog.user_role': '角色',
  'oifde.operationlog.knowledge_graph': '知识图谱',
  'oifde.operationlog.knowledge_graph_synonym': '知识图谱同义词',
  'oifde.operationlog.acess_code': '接入码',
  'oifde.operationlog.gray_rule': '灰度规则',
  'oifde.operationlog.resource_template': '资源模板',
  'oifde.operationlog.business_interface': '业务接口',
  'oifde.operationlog.variable_manage': '变量管理',
  'oifde.operationlog.flume_log_cfg': 'Flume日志配置',
  'oifde.operationlog.procedure_db': '存储过程数据库',
  'oifde.operationlog.procedure_interface': '存储过程',
  'oifde.operationlog.complexcellcatalog': '复合图元目录',
  'oifde.operationlog.complexcell': '复合图元',
  'oifde.operationlog.lock_account': '锁定帐号',
  'oifde.operationlog.unlock_account': '解锁帐号',
  'oifde.operationlog.kick_account': '清除会话',
  'oifde.operationlog.start': '启动',
  'oifde.operationlog.stop': '停止',
  'oifde.operationlog.log_open_task': '日志开启任务',
  'oifde.operationlog.activate': '激活',
  'oifde.operationlog.deactivate': '暂停',
  'oifde.operationlog.illeagal_status': '非法状态',
  'oifde.operationlog.file': '文件',
  'oifde.operationlog.trace_log_query': '日志跟踪查询',
  'oifde.operationlog.staticcheck': '静态检查',
  'oifde.operationlog.unkonw': '未知',
  'oifde.operationlog.interface_white_list': '白名单',
  'oifde.operationlog.license': '许可证',
  'oifde.operationlog.test_corpus': '语料测试',
  'oifde.operationlog.test': '测试',
  'oifde.operationlog.refresh': '刷新',
  'oifde.operationlog.train': '训练',
  'oifde.operationlog.trainAll': '训练所有',
  'oifde.operationlog.lable_corpus': '标记语料',
  'oifde.operationlog.knowledge_graph_schema': '知识建模',
  'oifde.operationlog.knowledge_graph_ask_template': '扩展问',
  'oifde.operationlog.knowledge_graph_entity': '图谱字典实体',
  'oifde.operationlog.knowledge_graph_relation': '图谱字典关系',
  'oifde.operationlog.knowledge_graph_object_attribute': '图谱字典关系属性',
  'oifde.operationlog.knowledge_graph_object_entity': '图谱字典实体属性',
  'oifde.operationlog.knowledge_graph_schema_entity': '知识建模概念',
  'oifde.operationlog.knowledge_graph_schema_relation': '知识建模关系',
  'oifde.operationlog.knowledge_graph_schema_attribute': '知识建模属性',
  'oifde.operationlog.knowledge_graph_ask_template_group': '图谱扩展问',
  'oifde.operationlog.knowledge_graph_ask_template_element': '图谱扩展问问答模板',
  'oifde.operationlog.encrypt_Secret': '加密秘钥',
  'oifde.operationlog.flow_trace_log': '流程测试跟踪配置',
  'oifde.operationlog.channel_type': '渠道',
  'oifde.operationlog.response_style': '风格',
  'oifde.operationlog.resource_template_exce': '异常资源模板',
  'oifde.operationlog.setting': '设置',
  'oifde.operationlog.quick_release': '一键发布',
  'oifde.operationlog.upload': '上传',
  'oifde.operationlog.binding_domain': '机器人领域',
  'oifde.operationlog.binding_faqgroup': '机器人问答组',
  'oifde.operationlog.binding_kg': '机器人图谱',
  'oifde.operationlog.binding': '绑定',
  'oifde.operationlog.download': '下载',
  'oifde.operationlog.guideflow': '创建向导流程',
  'oifde.operationlog.sensitive_group': '敏感词组',
  'oifde.operationlog.recordHistoryLog': '对话日志',
  'oifde.operationlog.sentiment_analysis': '情感分析模型',
  'oifde.operationlog.use': '使用',
  'oifde.operationlog.unused': '未使用',
  'oifde.operationlog.tenantAsrName': 'asr配置',
  'oifde.operationlog.tenantAsrTtsName': 'TTS/ASR管理',
  'oifde.operationlog.MRCPServerManagement': '服务器管理',
  'oifde.operationlog.insert': '插入',
  'oifde.operationlog.exclude_entity': '排除实体',
  'oifde.sysConfig.sysParam': '系统参数',
  'oifde.sysConfig.intent_params': '意图参数',
  'oifde.sysConfig.code': '变量代码',
  'oifde.sysConfig.description': '变量描述',
  'oifde.sysConfig.value': '变量值',
  'oifde.sysConfig.update': '应用',
  'oifde.sysConfig.abort': '撤销',
  'oifde.sysConfig.updateAll': '应用全部参数',
  'oifde.sysConfig.restore_defaults': '恢复默认',
  'oifde.sysConfig.successful_operation': '操作成功！',
  'oifde.sysConfig.confirm_apply_intent_param': '修改意图参数，会对意图识别产生较大影响，确认应用修改？',
  'oifde.sysConfig.succeed_apply_intent_param': '成功应用意图参数！',
  'oifde.sysConfig.failed_apply_intent_param': '修改意图参数失败！',
  'oifde.sysConfig.confirm_restore_defaults': '确认恢复默认值？',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.NAME': '中文预处理模块',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_PINYIN.NAME': '使用拼音',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_PINYIN.DESC': '是否将中文转换成拼音来处理',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_PINYIN.TRUE': '启用',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_PINYIN.FALSE': '禁用',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_TONE.NAME': '使用声调',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_TONE.DESC': '拼音是否使用声调',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_TONE.TRUE': '启用',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.USE_TONE.FALSE': '禁用',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.WORD_FREQ.MULTI': '多次',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.WORD_FREQ.ONCE': '一次',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.TSF_ENABLE.TRUE': '启用',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.TSF_ENABLE.FALSE': '禁用',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.BORDER_EXT_ENABLE.TRUE': '启用',
  'oifde.sysConfig.CHINESE_DUMMY_TOKENIZER.BORDER_EXT_ENABLE.FALSE': '禁用',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.NAME': 'n-gram特征提取模块',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.NS.NAME': 'n最小值',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.NS.DESC': 'n-gram算法中最小的n值',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.NE.NAME': 'n最大值',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.NE.DESC': 'n-gram算法中最大的n值',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.MIN_DF.NAME': '词语权重阈值',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.MIN_DF.DESC': '当词语权重小于该阈值时忽略',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.WORD_FREQ.NAME': '相同词语统计次数',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.WORD_FREQ.DESC': '当一个句子中同样的词语出现多次时，统计一次或多次',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.TSF_ENABLE.NAME': 'TSF算法',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.TSF_ENABLE.DESC': '是否启用TSF算法',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.BORDER_EXT_ENABLE.NAME': '句子边界延拓',
  'oifde.sysConfig.SENTENCE_VECTORIZER_BY_NGRAMS.BORDER_EXT_ENABLE.DESC': '提取n-gram特征时，是否在句子前后边界添加标记',
  'oifde.sysConfig.ENERGY_FILTER.NAME': '意图数量控制模块',
  'oifde.sysConfig.ENERGY_FILTER.MAX.NAME': '最多意图数量',
  'oifde.sysConfig.ENERGY_FILTER.MAX.DESC': '最终决策中返回的最多意图数量',
  'oifde.sysConfig.UIR_VECTOR_ENERGY_CHECKER.NAME': '未知意图过滤模块',
  'oifde.sysConfig.UIR_VECTOR_ENERGY_CHECKER.THR.NAME': '能量阈值',
  'oifde.sysConfig.UIR_VECTOR_ENERGY_CHECKER.THR.DESC': '当检测出的所有意图能量之和小于或等于该阈值时，判断为未知意图',
  'oifde.sysConfig.DUMMY_VECTOR_CLASSIFIER.NAME': '意图分类模块',
  'oifde.sysConfig.DUMMY_VECTOR_CLASSIFIER.MIU.NAME': 'miu值',
  'oifde.sysConfig.DUMMY_VECTOR_CLASSIFIER.MIU.DESC': '意图分类器逻辑函数miu值',
  'oifde.sysConfig.DUMMY_VECTOR_CLASSIFIER.GAMMA.NAME': 'gamma值',
  'oifde.sysConfig.DUMMY_VECTOR_CLASSIFIER.GAMMA.DESC': '意图分类器逻辑函数miu值',
  'oifde.sysConfig.INTENT_DECISION_MAKER.NAME': '意图决策模块',
  'oifde.sysConfig.INTENT_DECISION_MAKER.TOP.NAME': 'top阈值',
  'oifde.sysConfig.INTENT_DECISION_MAKER.TOP.DESC': '意图决策器top阈值，高于此阈值的意图被认为是已知的意图',
  'oifde.sysConfig.INTENT_DECISION_MAKER.BOTTOM.NAME': 'bottom阈值',
  'oifde.sysConfig.INTENT_DECISION_MAKER.BOTTOM.DESC': '意图决策器bottom阈值，低于此阈值的意图被认为是未知意图',
  'oifde.sysConfig.CRF_PARAM_EXTRACTOR.NAME': 'CRF槽位标记模块',
  'oifde.sysConfig.CRF_PARAM_EXTRACTOR.LEARNING_RATE.NAME': '学习速率',
  'oifde.sysConfig.CRF_PARAM_EXTRACTOR.LEARNING_RATE.DESC': 'CRF模型学习速率',
  'oifde.sysConfig.CRF_PARAM_EXTRACTOR.MAX_NODES.NAME': '树的节点数量',
  'oifde.sysConfig.CRF_PARAM_EXTRACTOR.MAX_NODES.DESC': 'GBRT算法中每棵树的节点数量',
  'oifde.sysConfig.CRF_PARAM_EXTRACTOR.NUM_TREES.NAME': '树的数量',
  'oifde.sysConfig.CRF_PARAM_EXTRACTOR.NUM_TREES.DESC': 'GBRT算法中树的数量',
  'oifde.errorCode.100010000000': '系统错误。',
  'oifde.errorCode.100010000002': '格式错误。',
  'oifde.errorCode.100051005001': '保存黑白名单失败。',
  'oifde.errorCode.100070000001': '导入数据与已有资源重名，导致导入失败。',
  'oifde.errorCode.100051005002': '删除黑白名单失败。',
  'oifde.errorCode.100051000006': '导入失败，文件格式错误。',
  'oifde.errorCode.100051005005': '导入失败，文件过大。',
  'oifde.errorCode.100051005006': '用户号码过长，不能超过64个字符。',
  'oifde.errorCode.100051005015': '文件行数不能超过5千行。',
  'oifde.errorCode.100051005003': '导入失败，文件未找到。',
  'oifde.errorCode.100051005004': '导入失败，文件读取错误。',
  'oifde.errorCode.100051006013': '导入失败。',
  'oifde.errorCode.100051005025': '总条数不能超出5千',
  'oifde.errorCode.100051005011': '添加用户号码失败。',
  'oifde.fileTask.EMPTY_COMMAND': '操作命令为空',
  'oifde.fileTask.UNKNOWN_COMMAND': '未识别的操作命令',
  'oifde.fileTask.INVALID_SALT': '非法请求',
  'oifde.fileTask.INVALID_REQUEST': '非法请求',
  'oifde.fileTask.EMPTY_TASK_ID': '任务标识为空',
  'oifde.fileTask.TASK_NOT_FOUND': '任务未找到',
  'oifde.fileTask.SERVER_BUSY': '系统忙',
  'oifde.fileTask.SERVICE_UNAVAILABLE': '服务不可用',
  'oifde.fileTask.UPLOAD_EXCEPTION': '上传异常终止',
  'oifde.fileTask.INVALID_ITEM_ENCODING': '非法的编码方式',
  'oifde.fileTask.INVALID_FILE_EXTENSION': '非法的文件扩展名',
  'oifde.fileTask.WRITE_FILE_FAILED': '保存文件失败',
  'oifde.fileTask.READ_FILE_FAILED': '读取文件失败',
  'oifde.fileTask.INVALID_FILE_PATH': '文件路径不正确',
  'oifde.fileTask.FILE_NOT_EXIST': '文件不存在',
  'oifde.fileTask.FILE_TOO_LARGE': '文件太大',
  'oifde.fileTask.ENTRY_TOO_LARGE': '压缩包中有超大文件',
  'oifde.fileTask.TOO_MANY_ENTRIES': '压缩包中文件太多',
  'oifde.fileTask.INVALID_ENTRY_PATH': '压缩包中文件路径异常',
  'oifde.fileTask.INVALID_ENTRY_EXTENSION': '压缩包中文件扩展名非法',
  'oifde.fileTask.UNZIPPED_TOO_LARGE': '压缩包解压后太大',
  'oifde.fileTask.UNZIP_FAILED': '压缩包解压失败',
  'oifde.fileTask.DOWNLOAD_EXCEPTION': '下载异常终止',
  'oifde.fileTask.UNDEFINED_ERROR': '任务执行遇到错误',
  'oifde.fileTask.INVALID_TASK_ARGS': '任务参数缺失',
  'oifde.fileTask.INVALID_TASK_CONTENT': '任务数据不正确',
  'oifde.fileTask.GRAPH_IS_ON': '图谱激活状态下不能执行导入',
  'oifde.fileTask.INCORRECT_PARAMETERS': '参数不合法',
  'oifde.fileTask.KG_NO_SCHEMA': '图谱没有关联知识建模',
  'oifde.fileTask.IMPORT_KG_ENTITY_TOO_BIG': '图谱实体不能超过1百万',
  'oifde.fileTask.IMPORT_KG_RELATION_TOO_BIG': '图谱关系总数不能超过1亿',
  'oifde.fileTask.IMPORT_SCHEMA_TOO_BIG': '知识建模概念实体不能超过1万',
  'oifde.fileTask.IMPORT_SCHEMA_RELATION_TOO_BIG': '知识建模关系不能超过1万',
  'oifde.importFile.white_list': '全覆盖已有配置',
  'oifde.importFile.test_case_group': '全覆盖已有配置',
  'oifde.importFile.knowledge_graph': '允许重名覆盖',
  'oifde.importFile.knowledge_graph_ask_template': '全覆盖已有配置',
  'oifde.recordHistory.recordHistory': '呼叫历史',
  'oifde.recordHistory.recordDetail': '通话详情',
  'oifde.recordHistory.userSaying': '请求文本',
  'oifde.recordHistory.systemReply': '返回文本',
  'oifde.recordHistory.reqTime': '请求时间',
  'oifde.recordHistory.respTime': '回复时间',
  'oifde.recordHistory.callNumber': '用户号码',
  'oifde.recordHistory.callStatus': '呼入监控',
  'oifde.recordHistory.kgStatus': '知识监控',
  'oifde.recordHistory.callNumberLength': '用户号码长度大于64个字符',
  'oifde.recordHistory.oneCondition': '日期或号码至少需要一个',
  'oifde.recordHistory.respSource': '回复来源',
  'oifde.recordHistory.silentSeat': '静默座席回复',
  'oifde.recordHistory.bot': '机器人回复',
  'oifde.recordHistory.customer': '客户',
  'oifde.error.messageHead': '前台接口-',
  'oifde.error.messageBody': '请求异常，错误码：',
  'oifde.error.messageTail': '请检查网络或服务器是否异常！',
  'oifde.testMgr.noSelection': '未选择领域或者问答组，无法生成用例。',
  'oifde.testMgr.abortSuccess': '中断成功！',
  'oifde.testMgr.abortFailed': '中断失败！',
  'oifde.testMgr.getStatusWarn': '已有进程请求生成测试用例，暂时无法再次申请，请稍后...',
  'oifde.testMgr.noDomain': '未选择领域',
  'oifde.testMgr.noFaqGroup': '未选择问答组',
  'oifde.testMgr.requestFailed': '自动生成用例接口请求失败！',
  'oifde.testMgr.getCorpusTestCase': '用例生成',
  'oifde.testMgr.abortCorpusTestCase': '中断用例生成',
  'oifde.testMgr.domain': '领域',
  'oifde.testMgr.faqGroup': '问答组',
  'oifde.testMgr.getCorpusTestCaseConfig': '用例生成配置',
  'oifde.testMgr.allDomain': '覆盖所有领域',
  'oifde.testMgr.allFaqGroup': '覆盖所有问答组',
  'oifde.testMgr.testMgr': '知识测试',
  'oifde.testMgr.chatBotTestCaseMgr': '对话测试',
  'oifde.testMgr.botTestCaseMgr': '测试用例管理',
  'oifde.testMgr.testCase': '测试用例',
  'oifde.testMgr.case_group': '测试用例组',
  'oifde.testMgr.case_amount': '测试用例数',
  'oifde.testMgr.case_tested': '测试数',
  'oifde.testMgr.case_passed': '通过数',
  'oifde.testMgr.case_failed': '失败数',
  'oifde.testMgr.case_passed_rate': '通过率',
  'oifde.testMgr.test_time': '测试时间',
  'oifde.testMgr.add_test_case_group': '新增测试用例组',
  'oifde.testMgr.test_case_group_details': '测试用例组详细信息',
  'oifde.testMgr.descriptionLen': '描述不能超过1024位',
  'oifde.testMgr.number': '只允许输入数字',
  'oifde.testMgr.auto_testing_template': '模板下载',
  'oifde.testMgr.execute_test': '执行',
  'oifde.testMgr.manage': '管理',
  'oifde.testMgr.case_id': '用例编号',
  'oifde.testMgr.userId': '用户ID',
  'oifde.testMgr.expected_output': '期望输出关键字或意图名称',
  'oifde.testMgr.actual_output': '实际输出',
  'oifde.testMgr.actual_output_title': '实际输出（括号内为意图名称）',
  'oifde.testMgr.event': '事件',
  'oifde.testMgr.note_test': '未测试',
  'oifde.testMgr.expected_output_with_memo': '期望输出关键字或意图名称（多个关键字用“，”隔开）',
  'oifde.testMgr.add_test_case': '新增测试用例',
  'oifde.testMgr.test_case_details': '测试用例详细信息',
  'oifde.testMgr.execute_all': '执行全部',
  'oifde.testMgr.batchExec': '批量执行',
  'oifde.testMgr.succeed_save_case': '保存测试用例成功！',
  'oifde.testMgr.succeed_test_case': '执行测试用例成功！',
  'oifde.testMgr.natureColon': ':',
  'oifde.testMgr.failed_query': '查询失败。',
  'oifde.testMgr.succeed_save_group': '保存测试用例组成功！',
  'oifde.testMgr.fail_save_data': '保存失败。',
  'oifde.testMgr.select_prompt': '请至少选择一条记录。',
  'oifde.testMgr.confirm_delete_case_group': '确认删除测试用例组？',
  'oifde.testMgr.failed_delete_group': '删除测试用例组失败！',
  'oifde.testMgr.succeed_delete_group': '删除测试用例组成功！',
  'oifde.testMgr.confirm_delete_case': '确认删除测试用例？',
  'oifde.testMgr.succeed_delete_case': '删除测试用例成功！',
  'oifde.testMgr.failed_delete_case': '删除测试用例失败！',
  'oifde.testMgr.import_test_cases': '导入测试用例',
  'oifde.testMgr.import_test_cases_hint': '支持导入Txt格式,上限200条测试用例。',
  'oifde.testMgr.overwrite_import': '全覆盖已有配置',
  'oifde.testMgr.failed_import_file': '导入文件失败。',
  'oifde.testMgr.success_import': '导入成功！',
  'oifde.hisdialog.startTime_must_earlier_endTime': '开始时间必须早于结束时间，请重新设置查询条件。',
  'oifde.hisdialog.direction.UPLOAD': '请求文本',
  'oifde.hisdialog.direction.DOWNLOAD': '返回文本',
  'oifde.chatBotTest.rangeFilter': '覆盖已有记录范围筛选',
  'oifde.chatBotTest.botAccessCode': '机器人接入码',
  'oifde.chatBotTest.callingNum': '主叫号码',
  'oifde.chatBotTest.caseTimeRange': '用例时间范围',
  'oifde.chatBotTest.getChatBotTestCase': '对话用例生成',
  'oifde.chatBotTest.getChatBotTestCaseConfig': '对话用例生成配置',
  'oifde.chatBotTest.abortChatBotTestCase': '中断对话用例生成',
  'oifde.chatBotTest.allAccessCode': '覆盖所有机器人接入码',
  'oifde.chatBotTest.deletePrompt': '此操作将永久删除选中的测试用例组, 是否继续?',
  'oifde.chatBotTest.deleteTestCase': '此操作将永久删除选中的测试用例, 是否继续?',
  'oifde.chatBotTest.checkPrompt': '同时进行的测试用例较多，可能影响系统运行，是否继续？',
  'oifde.chatBotTest.cancleExcute': '取消执行！',
  'oifde.chatBotTest.inputTestCaseGroupName': '测试用例组名称',
  'oifde.chatBotTest.doTest': '执行测试',
  'oifde.chatBotTest.executeSuccess': '执行成功！',
  'oifde.chatBotTest.executeFailed': '执行异常！',
  'oifde.chatBotTest.downTestReport': '导出测试报告',
  'oifde.chatBotTest.downAllReport': '导出所有报告',
  'oifde.chatBotTest.testCaseGroup': '测试用例组',
  'oifde.chatBotTest.totalNumber': '测试用例数',
  'oifde.chatBotTest.testTimes': '测试数',
  'oifde.chatBotTest.passTimes': '通过数',
  'oifde.chatBotTest.failTimes': '失败数',
  'oifde.chatBotTest.passRate': '通过率',
  'oifde.chatBotTest.testTime': '测试时间',
  'oifde.chatBotTest.status': '执行状态',
  'oifde.chatBotTest.status.initial': '初始化',
  'oifde.chatBotTest.status.waiting': '等待中',
  'oifde.chatBotTest.status.running': '运行中',
  'oifde.chatBotTest.status.finish': '已完成',
  'oifde.chatBotTest.status.failed': '失败',
  'oifde.chatBotTest.status.success': '成功',
  'oifde.chatBotTest.description': '描述',
  'oifde.chatBotTest.addTestCaseGroup': '新增测试用例组',
  'oifde.chatBotTest.editTestCaseGroup': '编辑测试用例组',
  'oifde.chatBotTest.testCaseGroupName': '用例组名称',
  'oifde.chatBotTest.robotName': '机器人名称',
  'oifde.chatBotTest.callingId': '主叫ID',
  'oifde.chatBotTest.prompt': '提示',
  'oifde.chatBotTest.promptMessage': '请至少选择一条数据',
  'oifde.chatBotTest.downloadMessage': '您已选择{0}条记录，是否继续导出？',
  'oifde.chatBotTest.downloadAllMessage': '您当前未选择具体记录，是否全部导出？您也可以取消后选择具体记录进行导出操作。',
  'oifde.testCase.name': '测试用例名称',
  'oifde.testCase.status': '状态',
  'oifde.testCase.caseID': '用例编号',
  'oifde.testCase.doAll': '执行全部',
  'oifde.testCase.caseName': '用例名称',
  'oifde.testCase.executeStatus': '执行状态',
  'oifde.testCase.description': '描述',
  'oifde.testCase.caseDetail': '用例详情',
  'oifde.testCase.traceLog': '流程轨迹',
  'oifde.testCase.callDetail': '详情',
  'oifde.testCase.addTestCase': '新增测试用例',
  'oifde.testCase.editTestCase': '编辑测试用例',
  'oifde.testCase.caseDetailMgr': '管理用例详情',
  'oifde.testCase.detailDialog': '用例详情',
  'oifde.testCase.detail.reqText': '请求文本',
  'oifde.testCase.detail.expectedReturn': '预期返回文本',
  'oifde.testCase.detail.channelAssociatedData': '随路数据',
  'oifde.testCase.detail.operation': '操作',
  'oifde.testCase.detail.realResponse': '实际响应文本',
  'oifde.testCase.detail.close': '关闭',
  'oifde.rate.accessCode': '机器人接入码',
  'oifde.rate.callCountBoard': '呼叫统计看板',
  'oifde.rate.resolveRate': '净自助解决率',
  'oifde.rate.dayOption': '按天查询',
  'oifde.rate.hourOption': '按小时查询',
  'oifde.rate.monthOption': '近30天查询',
  'oifde.rate.dateSelect': '选择日期',
  'oifde.rate.index': '序号',
  'oifde.rate.time': '时间',
  'oifde.rate.dataDimension': '数据粒度',
  'oifde.rate.accessAmount': '接入量',
  'oifde.rate.exitNoConsultCount': '直接退出量',
  'oifde.rate.callStaffNoConsultCount': '直接转人工量',
  'oifde.rate.exitConsultingCount': '仅咨询机器人量',
  'oifde.rate.callStaffConsultCount': '咨询后转人工量',
  'oifde.rate.interfaceTimes': '接口调用次数',
  'oifde.rate.interfaceSuccessTimes': '接口成功数',
  'oifde.rate.successRate': '接口成功率',
  'oifde.rate.autonomyRate': '净自助解决率',
  'oifde.rate.interactCount': '对话轮次',
  'oifde.rate.averageInteractPerCall': '平均对话轮次',
  'oifde.rate.accessNum': '接入量',
  'oifde.rate.dayconditionTips': '查询条件缺少接入码',
  'oifde.rate.hourConditionTips': '查询条件缺少日期或接入码',
  'oifde.rate.rateForCharts': '净自助解决率',
  'oifde.rate.interfaceRequestCount': '接口调用总数',
  'oifde.rate.interfaceSuccessCount': '接口调用成功数',
  'oifde.rate.interfaceSuccessRate': '接口调用成功率',
  'oifde.dashBoard.noData': '---（已删除数据无法展示）',
  'oifde.dashBoard.serviceCapabilityDashBoard': '服务能力看板',
  'oifde.dashBoard.knowledgeDashBoard': '知识看板',
  'oifde.dashBoard.service.consultCount': '总回复数',
  'oifde.dashBoard.service.responseSuccessCount': '成功回复数',
  'oifde.dashBoard.service.unknowCount': '未知回复数',
  'oifde.dashBoard.service.responseRate': '成功回复率',
  'oifde.dashBoard.service.feedbackCount': '总参评数',
  'oifde.dashBoard.service.positiveFeedbackCount': '满意参评数',
  'oifde.dashBoard.service.positiveFeedbackRate': '满意参评率',
  'oifde.dashBoard.knowledge.dateConditionTips': '日期必选',
  'oifde.dashBoard.knowledge.topTenChatBot': 'Top 10 热点机器人',
  'oifde.dashBoard.knowledge.accessCode': '接入码',
  'oifde.dashBoard.knowledge.name': '名称',
  'oifde.dashBoard.knowledge.callCount': '访问量',
  'oifde.dashBoard.knowledge.topTenknowledge': 'Top 10 热点知识',
  'oifde.dashBoard.knowledge.intent': '意图',
  'oifde.dashBoard.knowledge.faq': '问答',
  'oifde.dashBoard.knowledge.hitCount': '命中次数',
  'oifde.dashBoard.chartView.title': '回复成功率',
  'oifde.dashBoard.chartView.countTile': '总回复数',
  'oifde.dashBoard.chartView.rateTile': '成功回复率',
  'oifde.dashBoard.chartView.leftTile': '总回复数',
  'oifde.dashBoard.chartView.rightTile': '成功回复率',
  'oifde.dashBoard.graph.title': '有效知识统计',
  'oifde.dashBoard.graph.faq': '问答',
  'oifde.dashBoard.graph.intent': '意图',
  'oifde.dashBoard.graph.kg': '图谱',
  'oifde.dashBoard.graph.talk': '语聊',
  'oifde.dashBoard.graph.countTitle': '总数',
  'oifde.dashBoard.graph.hitTitle': '命中数',
  'oifde.dashBoard.graph.hitRateTitle': '命中率',
  'oifde.dashBoard.graph.amount': '数量',
  'oifde.dashBoard.graph.percent': '百分比',
  'oifde.dashBoard.graph.count': '总数',
  'oifde.dashBoard.graph.hit': '命中数',
  'oifde.dashBoard.graph.hitRate': '命中率',
  'oifde.dashBoard.pie.title': '命中知识分布',
  'oifde.dashBoard.pie.intentTitle': '意图',
  'oifde.dashBoard.pie.kgTitle': '图谱',
  'oifde.dashBoard.pie.faqTitle': '问答',
  'oifde.dashBoard.pie.talkTitle': '语聊',
  'oifde.dashBoard.pie.intent': '意图',
  'oifde.dashBoard.pie.kg': '图谱',
  'oifde.dashBoard.pie.faq': '问答',
  'oifde.dashBoard.pie.talk': '语聊',
  'oifde.chatbot.errorCode.100010000002': '格式错误！',
  'oifde.chatbot.errorCode.100051000031': '输入id个数过多！',
  'oifde.chatbot.errorCode.100051000023': '删除用例组失败！',
  'oifde.chatbot.errorCode.100010000000': '系统错误！',
  'oifde.chatbot.errorCode.100010000001': '数据库错误！',
  'oifde.chatbot.errorCode.100010000003': '不允许重名！',
  'oifde.chatbot.errorCode.100010000005': '输入参数不正确！',
  'oifde.chatbot.errorCode.100023000004': '输入id校验失败！',
  'oifde.chatbot.errorCode.100051000022': '保存测试用例失败！',
  'oifde.chatbot.errorCode.100051000032': '删除测试用例失败！',
  'oifde.chatbot.errorCode.100051000026': '选择测试组数目过多，执行测试失败！',
  'oifde.chatbot.errorCode.100051000028': '没有测试结果！',
  'oifde.chatbot.errorCode.100051000109': '测试用例数超过200！',
  'oifde.serverMgr.serverType': '服务器类型',
  'oifde.serverMgr.mrcpId': '分组ID',
  'oifde.serverMgr.asrServer': 'ASR服务器',
  'oifde.serverMgr.ttsServer': 'TTS服务器',
  'oifde.serverMgr.confirmDelete': '请确认是否要继续删除数据？',
  'oifde.serverMgr.addServer': '新建服务器信息',
  'oifde.serverMgr.editServer': '编辑服务器信息',
  'oifde.qiAsrServerMgr.asrVendor': '厂商',
  'oifde.qiAsrServerMgr.asrServerName': '服务器名称',
  'oifde.qiAsrServerMgr.asrServerAddress': '服务器地址',
  'oifde.qiAsrServerMgr.asrAppKey': '用户名/AK',
  'oifde.qiAsrServerMgr.fileServerAddress': '文件服务器地址',
  'oifde.qiAsrServerMgr.folder': '默认文件夹',
  'oifde.qiAsrServerMgr.description': '描述',
  'oifde.qiAsrServerMgr.addQiAsrServer': '新建质检ASR服务器信息',
  'oifde.qiAsrServerMgr.editQiAsrServer': '编辑质检ASR服务器信息',
  'oifde.qiAsrServerMgr.asrServerInfo': 'ASR服务器信息',
  'oifde.qiAsrServerMgr.name': '名称',
  'oifde.qiAsrServerMgr.asrServerIP': 'ASR服务器地址',
  'oifde.qiAsrServerMgr.asrServerPort': 'ASR服务器端口',
  'oifde.qiAsrServerMgr.asrServerUser': 'ASR服务器用户',
  'oifde.qiAsrServerMgr.asrServerPW': 'ASR服务器密码',
  'oifde.qiAsrServerMgr.asrServerPWConform': '再次输入ASR服务器密码',
  'oifde.qiAsrServerMgr.nextStep': '下一步',
  'oifde.qiAsrServerMgr.preStep': '上一步',
  'oifde.qiAsrServerMgr.fileServerInfo': '文件服务器信息',
  'oifde.qiAsrServerMgr.fileServerType': '文件服务器类型',
  'oifde.qiAsrServerMgr.fileServerIP': '文件服务器地址',
  'oifde.qiAsrServerMgr.fileServerPort': '文件服务器端口',
  'oifde.qiAsrServerMgr.fileServerUser': '文件服务器用户名',
  'oifde.qiAsrServerMgr.fileServerPW': '文件服务器密码',
  'oifde.qiAsrServerMgr.fileServerPWConfirm': '再次输入文件服务器密码',
  'oifde.qiAsrServerMgr.voiceUploadFolder': '录音文件上传路径',
  'oifde.qiAsrServerMgr.qiResultFolder': '转写结果路径',
  'oifde.qiAsrServerMgr.sftp': 'SFTP',
  'oifde.qiAsrServerMgr.passwordError': '两次输入的密码不一致',
  'oifde.qiAsrServerMgr.ipOrDomainNameError': 'ip或域名输入错误',
  'oifde.qiAsrServerMgr.ipError': 'ip输入错误',
  'oifde.qiAsrServerMgr.filePathError': '路径格式错误',
  'oifde.qiAsrServerMgr.portTips': '当ASR服务器地址为域名时，端口可不填',
  'oifde.qiAsrServerMgr.asrServerNameError': '和系统默认ASR服务器名称重复',
  'oifde.asrMgr.tenantId': '租间ID',
  'oifde.asrMgr.tenantSapce': '租间',
  'oifde.asrMgr.asr': '质检ASR服务器厂家',
  'oifde.asrMgr.qiAsrServerName': '质检ASR服务器',
  'oifde.asrMgr.mrcpid': 'ASR分组ID',
  'oifde.asrMgr.path': 'ASR语法文件路径',
  'oifde.asrMgr.slotName': 'ASR槽位',
  'oifde.asrMgr.groupID': 'TTS分组ID',
  'oifde.asrMgr.addAsr': '新增TTS/ASR信息',
  'oifde.asrMgr.editAsr': '编辑TTS/ASR信息',
  'oifde.asrMgr.selectOne': '质检ASR服务器厂家，ASR服务器，TTS服务器至少选择一个',
  'oifde.asrMgr.TENANT_SPACE_NOT_FOUND': '租间不存在',
  'oifde.asrMgr.languageTips': 'default表示不区分语种',
  'oifde.license.license': '许可管理',
  'oifde.license.import': '加载/更新',
  'oifde.license.setlicense': '分配许可',
  'oifde.license.queryCurrentLicense': '查询当前并发和累计信息',
  'oifde.license.queryHistoryLicense': '查询历史并发信息',
  'oifde.license.valateDate': '有效期',
  'oifde.license.totalLicense': '系统总量',
  'oifde.license.LIN8OIAPAA01': '并发座席助手数',
  'oifde.license.LIN8OIAPVA01': '并发虚拟座席数',
  'oifde.license.LIN8OIAPVA02': '虚拟座席数累计呼叫数',
  'oifde.license.PERMANENT': '永久',
  'oifde.license.processTime': '处理时间',
  'oifde.license.at_least_one_serviceValue': '至少有一个值不为空',
  'oifde.license.failed_set_license': '配置license失败！',
  'oifde.license.success_set_license': '配置license成功！',
  'oifde.license.import_template_hint_dat': '支持上传dat格式',
  'oifde.license.import_template_need_dat': '上传文件需要为dat格式',
  'oifde.license.maxHisInfo': '历史最大值信息',
  'oifde.license.detailHisInfo': '历史详细信息',
  'oifde.license.sys': '系统',
  'oifde.request.timeout': '接口请求超时',
  'oifde.analysis.validate.time': '日期必填',
  'oifde.analysis.validate.accessCode': '机器人接入码必选',
  'oifde.analysis.validate.timeInterval': '间隔不超过7天',
  'oifde.analysis.chart.title': 'IVR节点分支统计',
  'oifde.analysis.chart.timeOther': '超时或按其他键',
  'oifde.analysis.chart.button': '按键',
  'oifde.analysis.chart.default': '系统默认分支',
  'oifde.analysis.childrenView': '查看子节点',
  'oifde.service.invoke.breakMinTime.desc': '单位：秒;',
  'oifde.ivrJourney.nodeOverview': '节点概览',
  'oifde.ivrJourney.trendAnalysis': '趋势分析',
  'oifde.ivrJourney.keyDistribution': '按键分布',
  'oifde.ivrJourney.nodeVersionComparison': '节点版本对比',
  'oifde.ivrJourney.businessRanking': '业务排行榜',
  'oifde.ivrJourney.no': '排名',
  'oifde.ivrJourney.nodeName': '节点名称',
  'oifde.ivrJourney.visitsTotal': '访问量',
  'oifde.ivrJourney.yoyValue': '同比值',
  'oifde.ivrJourney.increaseValue': '涨跌幅',
  'oifde.ivrJourney.visitorTotal': '访问量',
  'oifde.ivrJourney.successRate': '成功率',
  'oifde.ivrJourney.failedRate': '失败率',
  'oifde.ivrJourney.hangUpRate': '挂机率',
  'oifde.ivrJourney.journeyNumTrend': '价值趋势分布图',
  'oifde.ivrJourney.efficiencyTrend': '效率趋势分布图',
  'oifde.ivrJourney.complexityTrend': '复杂度趋势分布图',
  'oifde.ivrJourney.repeatNum': '重听数量',
  'oifde.ivrJourney.directCalls': '直达量',
  'oifde.ivrJourney.nonDirectCalls': '冲浪量',
  'oifde.ivrJourney.journeyNum': '访问量',
  'oifde.ivrJourney.hangupNum': '挂机量',
  'oifde.ivrJourney.successCalls': '成功量',
  'oifde.ivrJourney.failCalls': '失败量',
  'oifde.ivrJourney.directRate': '直达率',
  'oifde.ivrJourney.nonDirectRate': '冲浪率',
  'oifde.ivrJourney.hangupRate': '挂机率',
  'oifde.ivrJourney.failRate': '失败率',
  'oifde.ivrJourney.repeatCalls': '重听量',
  'oifde.ivrJourney.repeatOnceCalls': '重听一次',
  'oifde.ivrJourney.repeatTwiceCalls': '重听两次',
  'oifde.ivrJourney.repeatRate': '重听率',
  'oifde.ivrJourney.averageTime': '平均服务时长',
  'oifde.ivrJourney.averagePathLength': '平均路径长度',
  'oifde.ivrJourney.timeRangeCheck30': '时间间隔不能超过30天',
  'oifde.ivrJourney.dateTimrRange': '数据时间范围：',
  'oifde.ivrJourney.total': '总计',
  'oifde.ivrJourney.inflowDistribution': '流入分布',
  'oifde.ivrJourney.outflowDistribution': '流出分布',
  'oifde.ivrJourney.totalDistribution': '整体分布',
  'oifde.ivrJourney.detailDistribution': '详细分布',
  'oifde.ivrJourney.outflowDetailDistribution': '流出详细分布',
  'oifde.ivrJourney.outflowDetailDistributionAmount': '流出详细分布量',
  'oifde.ivrJourney.outflowDetailDistributionPercentage': '流出详细分布占比',
  'oifde.ivrJourney.nodeTrafficChart': '节点话务图(指标量个数)',
  'oifde.ivrJourney.indicatorQuantityStatistics': '指标量统计',
  'oifde.ivrJourney.other': '其它',
  'oifde.ivrJourney.indicateName': '指标名称',
  'oifde.ivrJourney.alarmContent': '预警内容',
  'oifde.ivrJourney.alarmTime': '预警时间',
  'oifde.ivrJourney.createTime': '预警数据时间',
  'oifde.ivrJourney.alarmLevel': '预警级别',
  'oifde.ivrJourney.alarmIndicator': '预警指标',
  'oifde.ivrJourney.remindType': '提醒方式',
  'oifde.ivrJourney.receivers': '接收人',
  'oifde.ivrJourney.sendResult': '发送结果',
  'oifde.ivrJourney.low': '低',
  'oifde.ivrJourney.middle': '中',
  'oifde.ivrJourney.high': '高',
  'oifde.ivrJourney.noNeedToSend': '无需发送',
  'oifde.ivrJourney.sendFail': '发送失败',
  'oifde.ivrJourney.sendSuccess': '发送成功',
  'oifde.ivrJourney.sms': '短信',
  'oifde.ivrJourney.email': '邮件',
  'oifde.ivrJourney.smsAndEmail': '短信; 邮件',
  'oifde.ivrJourney.nowData': '当前值',
  'oifde.ivrJourney.label': '标识',
  'oifde.ivrJourney.transToManualCalls': '转人工量',
  'oifde.ivrJourney.transToNextLevelManuCalls': '流入下层菜单量',
  'oifde.ivrJourney.timeOutOrPressAnotherKey': '超时或按其他键',
  'oifde.ivrJourney.buttons': '按键',
  'oifde.ivrJourney.defaultBranch': '默认分支',
  'oifde.ivrJourney.count': '次数',
  'oifde.ivrJourney.versionName': '版本名称',
  'oifde.ivrJourney.compareRange': '对比范围',
  'oifde.ivrJourney.pleaseSelectAccessCode': '请选择机器人',
  'oifde.ivrJourney.pleaseSelectAccessCodeFirst': '请先选择机器人',
  'oifde.ivrJourney.oneDay': '1天',
  'oifde.ivrJourney.threeDay': '3天',
  'oifde.ivrJourney.halfMonth': '半个月',
  'oifde.ivrJourney.oneMonth': '一个月',
  'oifde.ivrJourney.chooseAccessCode': '选择机器人',
  'oifde.ivrJourney.chooseVersion': '选择流程版本',
  'oifde.ivrJourney.releaseDate': '发布时间',
  'oifde.ivrJourney.nodeVersionCompareStatistics': '节点版本对比统计',
  'oifde.ivrJourney.indicatorComparisonBarChart': '指标对比柱状图',
  'oifde.ivrJourney.indicatorComparisonStatistics': '指标对比统计',
  'oifde.ivrJourney.beforeRelease': '发布前',
  'oifde.ivrJourney.afterRelease': '发布后',
  'oifde.ivrJourney.validIVRService': 'IVR有效服务量',
  'oifde.ivrJourney.transToManualCallsSucc': '转人工成功量',
  'oifde.ivrJourney.selfService': '自助服务量',
  'oifde.ivrJourney.repeatIncomingCalls': '重复呼入量',
  'oifde.ivrJourney.returnKeysByErrors': '按键错误返回量',
  'oifde.ivrJourney.pleaseSelectVersion': '请选择流程版本',
  'oifde.ivrJourney.pleaseSelectCompareRange': '请选择对比范围',
  'oifde.ivrJourney.logTime': '日期',
  'oifde.ivrJourney.validIVRCalls': 'IVR有效服务量',
  'oifde.ivrJourney.IVRCalls': '自助服务量',
  'oifde.ivrJourney.IVRTransManualRate': '人工话务占比 (%)',
  'oifde.ivrJourney.IVRRate': '纯IVR服务率 (%)',
  'oifde.ivrJourney.IVRRateNoSymbol': '纯IVR服务率',
  'oifde.ivrJourney.transManualSuccCalls': '转人工成功量',
  'oifde.ivrJourney.transManualSuccRate': '转人工成功率 (%)',
  'oifde.ivrJourney.avgIVRTime': '平均通话时长 (秒)',
  'oifde.ivrJourney.avgIVRTimeNoSymbol': '平均通话时长',
  'oifde.ivrJourney.IVRErrorRate': 'IVR系统差错率 (%)',
  'oifde.ivrJourney.IVRErrorRateNoSymbol': 'IVR系统差错率',
  'oifde.ivrJourney.IVRTotalCalls': 'IVR呼入量',
  'oifde.ivrJourney.IVRSrvTime': 'IVR服务总时长 (秒)',
  'oifde.ivrJourney.wrongKeyCalls': '按键错误返回量',
  'oifde.ivrJourney.distinctCustomers': '客户数',
  'oifde.ivrJourney.IVRSrvCustomers': '纯IVR客户数',
  'oifde.ivrJourney.callNum': '呼入量',
  'oifde.ivrJourney.ivrRepeatCallNum': 'IVR重复呼入量',
  'oifde.ivrJourney.resolveRate': 'IVR一次解决率',
  'oifde.ivrJourney.serviceRate': '有效服务率',
  'oifde.ivrJourney.node': '节点',
  'oifde.ivrJourney.indicatorDesc': '指标描述',
  'oifde.ivrJourney.validIVRServiceDesc': '业务办理或查询成功、业务咨询语音播放过半，或成功触发短信、彩信、WAPPUSH、电子邮件等手段的IVR呼叫量',
  'oifde.ivrJourney.transToManualCallsDesc': '尝试转人工的IVR呼叫量',
  'oifde.ivrJourney.transToManualCallsSuccDesc': '转入人工坐席且转入成功的IVR呼叫量',
  'oifde.ivrJourney.selfServiceDesc': '没有转人工的IVR呼叫量',
  'oifde.ivrJourney.repeatIncomingCallsDesc': '在两小时内，使用同一主叫号码重复拨打客户服务热线的客户数量',
  'oifde.ivrJourney.returnKeysByErrorsDesc': '导航节点中，按键错误后，按提示选择*键返回的量',
  'oifde.ivrJourney.indicatorSetting': '指标设置',
  'oifde.ivrJourney.indicatorSettingLengthError': '请选择至少{count}个指标',
  'oifde.ivrJourney.extendedMetrics': '扩展指标',
  'oifde.ivrJourney.optimizeVoiceSequence': '语音播报顺序优化',
  'oifde.ivrJourney.optimizeVoiceDefinition': '语音清晰度优化',
  'oifde.ivrJourney.pleaseSelectDateRange': '请选择时间范围',
  'oifde.ivrJourney.reverseFlow': '反向流向',
  'oifde.ivrJourney.currentVoicePlayContent': '当前语音播报内容',
  'oifde.ivrJourney.repeatCallsNum': '重听次数',
  'oifde.ivrJourney.optimizeSuggestion': '优化建议',
  'oifde.ivrJourney.repeatCallOptimizeSuggestion': '当前节点的重听次数过高，请确认语音内容是否存在描述不清晰或难以理解的内容，建议优化语音播报内容。',
  'oifde.ivrJourney.operationStatus': '运营情况',
  'oifde.ivrJourney.poor': '较差',
  'oifde.ivrJourney.general': '一般',
  'oifde.ivrJourney.good': '良好',
  'oifde.ivrJourney.fluctuationService': '波动业务',
  'oifde.ivrJourney.IVRIncomingCallVolumeTrend': 'IVR呼入量趋势',
  'oifde.ivrJourney.efficiencyOverview': '效率概览',
  'oifde.ivrJourney.repeatedCallRate': '重复拨打率',
  'oifde.ivrJourney.valueOverview': '价值概览',
  'oifde.ivrJourney.pureIVRServiceVolume': '纯IVR服务量',
  'oifde.ivrJourney.complexityOverview': '复杂度概览',
  'oifde.ivrJourney.personTime': '人数（次）',
  'oifde.ivrJourney.times': '次',
  'oifde.ivrJourney.noData': '无',
  'oifde.ivrJourney.usageStatistics': '当前语音播报按键使用统计',
  'oifde.ivrJourney.playingOrder': '建议语音播报顺序',
  'oifde.ivrJourney.journeySum': '使用次数',
  'oifde.ivrJourney.forever': '永久有效',
  'oifde.ivrJourney.slectTimeForever': '指定有效时间',
  'oifde.ivrJourney.alarmIndex': '统计指标',
  'oifde.ivrJourney.alarmCondition': '预警条件',
  'oifde.ivrJourney.alarmValidity': '预警有效期',
  'oifde.ivrJourney.selectAlarmCondition': '请选择要删除的预警条件',
  'oifde.ivrJourney.remindObject': '提醒对象',
  'oifde.ivrJourney.transfManualRate': '转人工率',
  'oifde.ivrJourney.absoluteValue': '绝对值',
  'oifde.ivrJourney.highThan': '高于',
  'oifde.ivrJourney.equal': '等于',
  'oifde.ivrJourney.lessThen': '低于',
  'oifde.ivrJourney.createAlarmConfig': '新建业务预警条件',
  'oifde.ivrJourney.editAlarmConfig': '编辑业务预警条件',
  'oifde.ivrJourney.selectAccesscodeAndFlow': '请选择机器人和流程版本',
  'oifde.ivrJourney.selectAlarmIndex': '请选择统计指标',
  'oifde.ivrJourney.fillAlarmCondition': '请完善预警条件',
  'oifde.ivrJourney.selectAlarmLevel': '请选择预警级别',
  'oifde.ivrJourney.selectTime': '请选择有效时间',
  'oifde.ivrJourney.selectAlramRemindType': '至少选择一种提醒方式',
  'oifde.ivrJourney.selectRemindObject': '请选择提醒对象',
  'oifde.ivrJourney.remindObjectMaxNum': '提醒对象最多选择20位',
  'oifde.ivrJourney.batchDetele': '批量删除',
  'oifde.ivrJourney.selectSmsGateway': '请选择短信网关',
  'oifde.ivrJourney.selectMailGateway': '请选择邮件网关',
  'oifde.ivrJourney.beforeReleaseRepeatRate': '发布前重听率',
  'oifde.ivrJourney.afterReleaseRepeatRate': '发布后重听率',
  'oifde.ivrJourney.beforeReleaseHangupNum': '发布前挂机量',
  'oifde.ivrJourney.afterReleaseHangupNum': '发布后挂机量',
  'oifde.headerTitle.flowManagement': '流程管理',
  'oifde.headerTitle.flowConfiguration': '流程配置',
  'oifde.trackmgr.robotsTrack': '机器人跟踪',
  'oifde.trackmgr.robotsSource': '来源',
  'oifde.maintain.odfsLogOpen': 'ODFS日志开启',
  'oifde.maintenance.level': '级别',
  'oifde.maintenance.startTime': '生效时间',
  'oifde.maintenance.duration': '持续时间（分钟）',
  'oifde.maintenance.startLog': '日志开启',
  'oifde.maintenance.stop_success': '停止日志成功！',
  'oifde.maintenance.start_success': '开启日志成功！',
  'oifde.route.transferRecord': '流程转接记录',
  'oifde.route.audioVideoResource': '音视频资源',
  'oifde.serviceInterface.thirdAPILog': '接口调用记录',
  'oifde.serviceInterface.statusCode': '状态码',
  'oifde.serviceInterface.invokingTime': '调用时间',
  'oifde.serviceInterface.callId': '呼叫ID',
  'oifde.serviceInterface.invokingDetails': '调用详情',
  'oifde.diagram.element.expired': '过时图元',
  'oifde.diagram.element.basic': '基础图元',
  'oifde.diagram.element.logic': '逻辑图元',
  'oifde.diagram.element.business': '业务图元',
  'oifde.diagram.element.composite': '复合图元',
  'oifde.title.chooseFlow': '选择流程',
  'oifde.tip.inputFlowName': '请输入流程名称',
  'oifde.error.createfail': '本地数据新增失败！',
  'oifde.error.gateway.fail': '同步CTI平台失败，请检查gateway配置！',
  'oifde.error.NMS.fail': '同步NMS失败！',
  'oifde.flowname.maxlength': '机器人名称超长',
  'oifde.audioVideoResource.setLanguage': '语种设置',
  'oifde.audioVideoResource.preview': '预览',
  'oifde.audioVideoResource.viewReferences': '查看引用',
  'oifde.audioVideoResource.cancelUpdate': '取消更新',
  'oifde.audioVideoResource.updateBefore': '更新前',
  'oifde.audioVideoResource.updateAfter': '更新后',
  'oifde.audioVideoResource.templateName': "请输入模板名称",
  'oifde.diagram.node.type': '节点类型',
  'oifde.audio.speedRange': '取值范围[-200,500]',
  'oifde.route.sysAudio': '语音管理',
  'oifde.param.maxLength': '参数长度不能超过',
  'oifde.tip.oldNode': '过时图元不建议使用',
  'oifde.title.chooseGreyRule': '选择灰度规则',
  'oifde.title.chatbot': '机器人',
  'oifde.audioVideoResource.cancelUpdate.confirm': '是否确认取消更新？',
  'oifde.audioVideoResource.cancelUpdate.success': '取消更新成功！',
  'oifde.audioVideoResource.cancelUpdate.fail': '取消更新失败！',
  'oifde.audioVideoResource.upload.tips': '提示：重复上传相同文件，更改文件名或切换文件上传',
  'oifde.robotReply.displayName': '机器人回复',
  'oifde.robotReply.replyWay': '回复方式',
  'oifde.robotReply.replyTemplate': '回复模板',
  'oifde.robotReply.selectReplyTemplate': '去选择模板',
  'oifde.robotReply.replyVariable': '回复变量',
  'oifde.robotReply.replyResource': '回复资源',
  'oifde.robotReply.selectReplyResource': '去选择资源',
  'oifde.robotReply.replMode': '回复模式',
  'oifde.robotReply.replyWayBreve': '方式',
  'oifde.robotReply.replyResourceBreve': '资源',
  'oifde.robotReply.replyVariableBreve': '变量',
  'oifde.robotReply.replyTemplateBreve': '模板',
  'oifde.robotReply.replModeBreve': '模式',
  'oifde.validation.repeats.varName': '变量名称重复',
  'oifde.procedure.mysqlUrlRule': 'url格式错误，url格式参考：jdbc:mysql://ip:port/db_name?prop1=value1&prop2=value2',
  'oifde.procedure.oracleUrlRule': 'url格式错误，url格式参考：jdbc:oracle:thin:{\'@\'}ip:port:SID或者jdbc:oracle:thin:{\'@\'}//ip:port/service',
  'oifde.procedure.gaussUrlRule': 'url格式错误，url格式参考：jdbc:zenith:{\'@\'}ip:port[?key=value[&key=value]..]',
  'oifde.chatBotTest.testCaseGroupName.maxLen': '用例组名称长度不能超过128',
  'oifde.chatBotTest.testCaseName.maxLen': '用例名称长度不能超过128',
  'oifde.chatBotTest.createCase.maxLen': '选中的用例数不能超过100条',
  'oifde.element.varAssign': '变量赋值',
  'oifde.promptType.title': '提示类型',
  'oifde.procedure.selectProcedure': '选择存储过程',
  'oifde.procedure.resultProcess': '结果处理',
  'oifde.robotReply.title.chooseTemplate': '选择回复模板',
  'oifde.robotReply.title.chooseResource': '选择回复资源',
  'oifde.interfaceInvoke.header': '接口消息头',
  'oifde.interfaceInvoke.body': '接口入参',
  'oifde.interfaceInvoke.queryParam': '接口查询参数',
  'oifde.interfaceInvoke.method.return': '方法返回值',
  'oifde.unit.millisecond': '毫秒',
  'oifde.title.transData': '获取随路数据',
  'oifde.robotReply.title.addResource': '添加回复资源',
  'oifde.mxgraph.saveDraft': '保存草稿',
  'ccivr.nms.conditionService.expression.placeholder':'请输入条件表达式',
  'oifde.diagram.error.basicEnd.length': '不能超过4096位！',
  'oifde.tableData.template': '下载数据模板',
  'oifde.tableData.download.template': '下载模板',
  'oifde.tableData.import.max': '最多导入10000条数据',
  'oifde.tableData.import.tableName': '数据表名',
  'oifde.procedure.db.dbName': '数据库名称',
  'oifde.procedure.procedureDbName':'存储过程数据库名',
  'oifde.procedure.dbNameWarning' : '数据库名称只支持字母，数字，中文，空格，下划线和英文单引号',
  'oifde.procedure.dbNameError': '存储过程数据库名称不能为空字符串或者长度不能超过64位',
  'oifde.procedure.actions.deleteOnlyOneDb': '删除失败！不能删除所有数据库连接信息。',
  'oifde.serviceInterface.actions.deleteDbInfo': '此操作将删除选择的数据库信息，是否继续？',
  'oifde.procedure.actions.deleteDBFailed': '删除失败！该数据库连接已经被存储过程{0}引用，不能删除，请您核对。',
  'oifde.procedure.actions.deleteDBFailed2': '删除失败！该数据库连接已经被 {0}] 等共计{1}个存储过程引用，不能删除，请您核对。',
  'oifde.procedure.actions.deleteFailed': '删除失败！',
  'oifde.procedure.actions.createDBFailed': '创建失败，数据库已达最大数量限制！',
  'oifde.procedure.databaseConnection': '数据库连接',
  'oifde.tableData.diagram.elementName': '数据表',
  'oifde.tableData.diagram.searchCriteria': '检索条件',
  'oifde.tableData.diagram.searchPage': '检索页',
  'oifde.tableData.diagram.searchResult': '检索结果',
  'oifde.tableData.diagram.returnRecords': '返回记录',
  'oifde.tableData.diagram.total': '返回总数',
  'oifde.workday.workDayName':'工作日历名称',
  'oifde.workday.workDayDesc':'工作日历描述',
  'oifde.workday.workWeekDay':'星期选择',
  'oifde.workday.servicePeriod':'选择工作时间',
  'oifde.workday.specificNonServiceDay':'指定休息日期',
  'oifde.workday.specificServiceDay':'指定工作日期',
  'oifde.workday.servicePeriodDesc':'工作时间至少配置一条且不能超过十条',
  'oifde.workday.specificNonServiceTip':'优先级最高，配置了指定休息日期与其他日期重合时，就会被认定为非工作日',
  'oifde.workday.specificServiceTip':'配置了指定工作日期，但不在星期选择中，也会被认定为工作日',
  'oifde.workday.name':'名称',
  'oifde.workday.time':'时间',
  'oifde.workday.date':'日期',
  'oifde.workday.addWorkDayTitle':'新增工作日历配置',
  'oifde.workday.editWorkDayTitle':'工作日历配置',
  'oifde.workday.required':'必填项',
  'oifde.workday.updateTime': '更新时间',
  'oifde.workday.operation':'操作',
  'oifde.workday.actions.edit':'编辑',
  'oifde.workday.actions.delete':'删除',
  'oifde.workday.actions.prompt':'提示',
  'oifde.workday.actions.confirm':'确认',
  'oifde.workday.actions.cancel':'取消',
  'oifde.workday.actions.ok':'确定',
  'oifde.workday.actions.deletePrompt':'此操作将删除该工作日历, 是否继续?',
  'oifde.workday.actions.cancelDelete':'已取消删除',
  'oifde.workday.actions.deleteSuccess':'删除成功！',
  'oifde.workday.actions.updateSuccess':'修改成功！',
  'oifde.workday.actions.createSuccess':'新建成功！',
  'oifde.workday.actions.missWorkDayName':'缺少工作日历名',
  'oifde.workday.actions.missWorkWeekDay':'请选择至少一个工作日',
  'oifde.workday.actions.minServicePeriod':'请填写至少一条工作时间',
  'oifde.workday.actions.missPeriodName':'请填写工作时间名',
  'oifde.workday.actions.missPeriodTime':'请填写工作时间段',
  'oifde.workday.actions.missNonServiceName':'请填写休息日期名',
  'oifde.workday.actions.missNonServiceDate':'请填写休息日期时间',
  'oifde.workday.actions.missServiceName':'请填写工作日期名',
  'oifde.workday.actions.missServiceDate': '请填写工作日期时间',
  'oifde.workday.actions.nameError': '所有名称只允许字母，数字，中文，空格，下划线，连字符和英文单引号',
  'oifde.workday.tip.overServicePeriod': '工作时间不能超过10条',
  'oifde.workday.tip.overNonServiceDate': '休息日期不能超过50条',
  'oifde.workday.tip.overServiceDate': '工作日期不能超过50条',
  'oifde.workday.tip.overWorkDayCount': '工作日历不能超过1000条',
  'oifde.tableData.diagram.addCriteria': '添加条件组',
  'oifde.tableData.diagram.logic': '逻辑',
  'oifde.diagram.message.recoverData': '恢复数据',
  'oifde.diagram.message.recoverDataDesc': '是否使用最后一次临时保存的流程数据渲染画布？',
  'oifde.button.openVariable': '打开变量 >',
  'oifde.button.closeVariable': '收起变量 <',
  'oifde.varGlobal.builtIn': '全局变量（内置）',
  'oifde.varGlobal.custom': '全局变量',
  'oifde.dataTable.tableName': '表名称',
  'oifde.dataTable.tableId': '表ID',
  'oifde.dataTable.tableField': '表字段',
  'oifde.dataTable.fieldId': '字段ID',
  'oifde.dataTable.fieldName': '字段名称',
  'oifde.dataTable.fieldType': '字段类型',
  'oifde.dataTable.fieldLength': '字段长度',
  'oifde.dataTable.defaultValue': '默认值',
  'oifde.dataTable.deleteTips': '此操作将永久删除该数据表以及数据表中的数据，是否继续？',
  'oifde.dataTable.deleteBatchTips': '当前未选择需要删除的数据表',
  'oifde.dataTable.editDataTable': '编辑数据表',
  'oifde.dataTable.addDataTable': '新增数据表',
  'oifde.dataTable.editDataTableField': '编辑表字段',
  'oifde.dataTable.addDataTableField': '新增表字段',
  'oifde.dataTable.validation.tableId': '只允许字母，数字，下划线，不能以数字开头',
  'oifde.dataTable.validation.presetField': 'ID、TENANTID、CREATETIME、MODIFYTIME为预置字段，且大小写不敏感，请不要使用',
  'oifde.dataTable.fieldsMaxSize': '表字段不能超过20个',
  'oifde.dataTable.fieldsNotEmpty': '表字段不能为空',
  'oifde.dataTable.exceedMaxSize': '数据表数量不能超过40个',
  'oifde.dataTable.saveAndInsert': '保存并添加数据',
  'oifde.service.table.dialog.title': '数据',
  'oifde.service.table.dialog.selectField': '选择字段',
  'oifde.service.table.dialog.inputValue': '输入字段值',
  'oifde.service.table.dialog.addRecord': '添加数据',
  'oifde.service.table.dialog.editRecord': '编辑数据',
  'oifde.service.table.dialog.integer': '只允许输入整数',
  'oifde.service.table.dialog.string': '字符串类型的值长度和最大为10000',
  'oifde.service.table.dialog.oneday': '最近1天',
  'oifde.service.table.dialog.threeday': '最近3天',
  'oifde.service.table.dialog.sevenday': '最近7天',
  'oifde.service.table.dialog.custom': '自定义',
  'oifde.service.table.dialog.batchdelete': '批量删除确认',
  'oifde.service.table.dialog.delete': '删除确认',
  'oifde.service.table.dialog.nodatedelete': '没有选择需要删除的数据',
  'oifde.dataTable.criteria': '条件',
  'oifde.dataTable.diagram.fieldName': '字段名',
  'oifde.dataTable.diagram.fieldValue': '字段值',
  'oifde.service.table.import.max.data': '导入的数据总量超过10000',
  'oifde.service.table.import.file.empty': '读取的文件内容为空',
  'oifde.service.table.import.file.read': '解析文件失败',
  'oifde.service.table.import.excute.max': '已存在执行中的导入任务，请等待上次导入完成',
  'oifde.service.table.import.field.error': '表字段不存在',
  'oifde.service.table.import.string.max': '字符串类型的值长度和超过10000',
  'oifde.service.table.import.fieldType.error': '字段类型和值不匹配',
  'oifde.conditionService.param.tips': '条件表达式包含字符串常量时，需要增加引号才能正常使用',
  'oifde.common.btn.batch.operation': '批量操作',
  'oifde.char.invalid': '非法字符',
  'oifde.validate.validChar': '允许的字符范围：',
  'oifde.service.table.dialog.exceedMaxSize': '允许添加的数据最多为10000条',
  'oifde.variable.rightList.tips': '右侧展示的变量可以被拖拽到变量输入框',
  'oifde.service.table.import.field.length.error': '字段长度超过限制',
  'oifde.screenfull.cancel': '退出全屏',
  'oifde.variable.sys.callMediaType': '呼叫的媒体类型',
  'oifde.variable.sys.systemDate': '系统日期(YYYYMMDD)',
  'oifde.variable.sys.systemMonth': '系统年月(YYYYMM)',
  'oifde.variable.sys.currentTime': '系统当前时间(yyyyMMddHHmmss)',
  'oifde.variable.sys.systemTime': '系统当前时间(HHmmss)',
  'oifde.variable.sys.timeStamp': '时间戳，字符串类型。格林威治时间1970年01月01日00时00分00秒(北京时间1970年01月01日08时00分00秒)起至现在的总秒数.',
  'oifde.variable.sys.createTime': '呼叫开始时间',
  'oifde.variable.sys.callingNumber': '客户号码。呼入云联络中心时表示主叫号码，云联络中心呼出时表示被叫号码，均为客户侧的号码。字符串类型。该号码由IVR流程传入。',
  'oifde.variable.sys.calledNumber': '系统流程接入码。字符串类型。该号码由IVR流程传入，为流程接入码，您可登录CTI平台的WAS配置台查看 。',
  'oifde.variable.sys.realCalledNumber': '接入码，该流程被叫配置中配置的接入码，即呼入该流程拨打的热线号码。',
  'oifde.variable.sys.callId': 'ODFS会话标识，字符串类型。该标识由IVR流程传入，即IVRREQUEST.call_id。',
  'oifde.variable.sys.ctiCallid': 'IVR流程传入的实际呼叫的CTI的callid，即IVR侧的请求参数IVRREQUEST.cti_callid。',
  'oifde.variable.sys.tenantId': '当前租户ID',
  'oifde.variable.sys.language': '当前客户语种',
  'oifde.variable.ivrRequest.ctiCallid': '华为IVR传入的实际呼叫的CTI的callid，其他场景此值为空。不超过32位。',
  'oifde.variable.ivrRequest.input': '对话交互结果。',
  'oifde.variable.ivrRequest.IACmd': '智能匹配和智能工单填写专用字段。',
  'oifde.variable.ivrRequest.TextSource': '请求文本的来源。',
  'oifde.variable.ivrRequest.transinData': '由接入方跟OIAP约定的随路数据参数，例如IVR传入的数据包参数。',
  'oifde.variable.ivrRequest.beginPlay': '放音开始时间。',
  'oifde.variable.ivrRequest.endPlay': '放音结束时间。',
  'oifde.variable.ivrRequest.callId': '呼叫唯一标志，与userid 取值相同，给流程使用。',
  'oifde.variable.ivrRequest.interIdx': '交互次数，ODFS侧记录的与用户连续交互的次数。',
  'oifde.variable.ivrRequest.feedback': '反馈的满意度: 1(满意)；0(不满意)。整数型。',
  'oifde.variable.ivrRequest.feedbackContent': '反馈的不满意原因（如果反馈为满意则为空）。',
  'oifde.variable.ivrRequest.channelType': '客户接入的渠道类型。例如：WEB、INSTAGRAM、TELEGRAM、WECHAT、FACEBOOK、TWITTER、EMAIL、LINE、WHATSAPP、5G、SMS',
  'oifde.variable.ivrRequest.ieValue': 'email渠道接入时，接收到的邮件的标题',
  'oifde.variable.toc.ChatBot': '意图上配置的槽位参数',
  'oifde.variable.toc.ChatBotIntentCode': '语音识别匹配到的意图返回意图码',
  'oifde.variable.toc.ChatBotRespContent': '语音识别匹配到的意图返回的答复信息，包括槽位和响应中配置的答复。',
  'oifde.variable.toc.ChatBotScenarioName': '语音识别执行后匹配到的意图模板的名称。',
  'oifde.variable.toc.ChatBotResponse': '识别接口中所有的内容',
  'oifde.datatable.open.type': '导出后请使用UTF-8格式打开。',
  'oifde.datatable.dateValue.tip': '时间戳（单位：毫秒）。请输入长整型或字符型的值或变量。',
  'oifde.datatable.intList.tip': '例：[1,2,3]。',
  'oifde.datatable.stringList.tip': '例：["a","b","c"]。',
  'oifde.expression.expr_number_varOrValue': '请输入数值类型的值或变量。',
  'oifde.expression.expr_list_varOrValue': '请输入列表类型的值或变量。',
  'oifde.service.table.import.index': '第{0}行',
  'oifde.flow.rule.noRule': '查询不到灰度规则，请确认规则是否已被删除',
  'oifde.cell.viewRefrenceInfo': '引用信息',
  'oifde.cell.viewRefrenceInfo.flow': '流程引用',
  'oifde.cell.viewRefrenceInfo.complexcell': '复合图元引用',
  'oifde.accessCode.select.max': '已选{0}条，最多可选择{1}条',
  'oifde.mxgraph.deleteDraft': '删除草稿',
  'oifde.mxgraph.deleteDraft.confirm': '确认删除草稿？',
  'oifde.mxgraph.deleteDraft.noDraftExist': '不存在草稿数据',
  'oifde.resource.needApproval': '已成功创建回复资源，请通知系统管理员，审核通过后才可以使用。',
  'oifde.common.validation.accessCode.description': '不允许换行和输入特殊字符："[]\'&()=;<>,',
  'oifde.tableData.import.tableNameBreve': '表名',
  'oifde.flow.actions.publish.existDraft': '当前流程存在草稿数据，请编辑后发布或忽略草稿直接发布。',
  'oifde.export.finish.success': '导出成功！',
  'oifde.input.tips.varAndConst': '请输入变量或常量，字符串前后请使用引号。',
  'oifde.export.keyPressRobot.title': '导出普通IVR记录',
  'oifde.export.password': '压缩密码',
  'oifde.export.password.placeholder': '请输入压缩密码',
  'oifde.export.password.error.tips': '请输入用于生成压缩文件8-12位的密码，必须包含除/\\-#(){\'{\'}{\'}\'};&{\'|\'}<>{\'$\'}`\\\\!\\n\\s\\t\\r外的数字,字母,特殊字符',
  'oifde.export.password.error.message': '必须包含除/\\-#(){\'{\'}{\'}\'};&{\'|\'}<>{\'$\'}`\\\\!\\n\\s\\t\\r外的数字,字母,特殊字符',
  'oifde.export.task.status': '任务状态',
  'oifde.export.task.fail': '导出失败！',
  'oifde.export.businessInterface.title': '导出自定义接口记录',
  'oifde.export.variable.title': '导出自定义变量记录',
  'oifde.export.complexcell.title': '导出复合图元记录',
  'oifde.export.accessCode.title': '导出智能机器人记录',
  'oifde.export.intelligentIVRFlow.title': '导出流程记录',
  'oifde.export.intelligentIVRFlow.button': '导出流程',
  'oifde.route.2dDigitalHumanImg': '2D数字人设置',
  'oifde.route.add2dDigitalHumanImg': '新建2D数字人',
  'oifde.digitalHuman.outputSetting': '输出设置',
  'oifde.digitalHuman.standingPosition': '站位',
  'oifde.digitalHuman.stationTemplate': '站位模板',
  'oifde.digitalHuman.preciseAdjustment': '精确调整',
  'oifde.digitalHuman.left': '居左',
  'oifde.digitalHuman.center': '居中',
  'oifde.digitalHuman.right': '居右',
  'oifde.digitalHuman.imageProportion': '形象比例',
  'oifde.exportdata.message.overData': '导出超过最大限制10000，请重新选择查询条件。',
  'oifde.flow.title.publishFlow': '发布流程',
  'oifde.flow.title.upgrade': '升级流程',
  'oifde.flow.action.editInfo': '编辑信息',
  'oifde.flow.title.editInfo': '编辑流程基本信息',
  'oifde.robot.title.editInfo': '编辑机器人基本信息',
  'oifde.input.placeholder.searchName': '搜索名称',
  'oifde.avatar.tenantId': '租间ID',
  'oifde.avatar.tenantSpace': '租间',
  'oifde.avatar.avatarName': '角色名称',
  'oifde.avatar.avatarCode': '角色code',
  'oifde.avatar.headerImageUrl': '形象',
  'oifde.avatar.serverAddress': '服务器地址',
  'oifde.avatar.avatarAppKey': '服务器用户名/AK',
  'oifde.avatar.create': '新建数字人服务',
  'oifde.avatar.update': '编辑数字人服务',
  'oifde.avatar.headerImage': '头像',
  'oifde.avatar.poseImage': '姿势',
  'oifde.avatar.actionList': '动作列表',
  'oifde.avatar.form.message': '2D数字人形象信息',
  'oifde.avatar.service.message': '服务器信息',
  'oifde.avatar.serverPort': '服务器端口',
  'oifde.avatar.userName': '服务器用户名',
  'oifde.avatar.pwd': '服务器密码',
  'oifde.avatar.checkPwd': '确认服务器密码',
  'oifde.avatar.resolution': '分辨率',
  'oifde.avatar.imgRule': '仅支持jpg, png, jpeg格式, 文件大小不超过2M',
  'oifde.avatar.action_1': '打招呼',
  'oifde.avatar.action_2': '双手往前',
  'oifde.avatar.action_3': '点头',
  'oifde.avatar.action_4': '双手合十',
  'oifde.avatar.action_5': '左手摊开',
  'oifde.avatar.action_6': '右手摊开',
  'oifde.avatar.selectTitle': '选择数字人',
  'oifde.digitalHuman.imgLimit': '仅支持jpg、png、jpeg格式，文件大小不超过2M',
  'oifde.digitalHuman.imageProportion0-5': '0.5倍',
  'oifde.digitalHuman.imageProportion0-75': '0.75倍',
  'oifde.digitalHuman.imageProportion1': '1倍',
  'oifde.digitalHuman.canvasNoSupport': '当前浏览器不支持canvas，请先升级或更换浏览器。',
  'oifde.digitalHuman.noRoleData': '暂无角色数据，请先联系运营人员添加。',
  'oifde.digitalHuman.sizeError': '图片分辨率错误，请上传{0} x {1}的背景图',
  'oifde.title.add.complexCell': '新建复合图元',
  'oifde.title.add.complexCellCatalog': '新建复合图元目录',
  'oifde.title.edit.complexCellCatalog': '编辑复合图元目录',
  'oifde.title.add.rule': '新建规则',
  'oifde.title.edit.rule': '编辑规则',
  'oifde.ivrJourney.pleaseSelectData': '请选择数据',
  'oifde.digitalHuman.needRole': '请选择角色',
  'oifde.digitalHuman.actions.deletePrompt': '此操作将永久删除数字人，是否继续？',
  'oifde.avatar.pleaseSelectData': '关闭后已修改的数据不会保存，是否继续?',
  'oifde.digitalHuman.actions.savePrompt': '此操作将更新数字人，是否继续？',
  'oifde.avatar.validation.ttsSoundSpeed': '请输入0.5-1.5之间的一位小数',
  'oifde.avatar.validation.scale': '请输入0.5-1.0之间的一位小数',
  'oifde.digitalHuman.coordinate': '坐标',
  'oifde.digitalHuman.dimensions': '尺寸',
  'oifde.digitalHuman.imgTypeError': '类型错误，仅支持jpg, png, jpeg格式',
  'oifde.expression.expr_var_flow': '请输入以FLOW开头的流程变量',
  'oifde.expression.expr_string_or_var': '请输入变量或字符串类型的值，字符串前后请使用双引号。',
  'oifde.common.actions.deleteConfig': '此操作将永久删除该配置, 是否继续?',
  'oifde.expression.numberCharStartWith0': '0开头的纯数字字符串前后请使用双引号，如：05 应输入为 "05"，否则会当做数值 5 处理。',
}
