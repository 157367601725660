export default {
  "cms.report.field.length.limit" : "يجب أن تكون القيمة رقمًا بحد أقصى 0 حرفًا.",
  "cms.report.message.maxQueueCountDesc" : "الحد الأقصى لعدد المكالمات المتزامنة التي يكون نوع جهاز خدمتها هو قائمة انتظار المهارات.",
  "cms.report.message.deviceTypeDesc" : "نوع الجهاز الذي يعالج المكالمة.",
  "cms.report.message.agentTalkingTimeDesc" : "وقت التحدث المتصل.",
  "cms.report.field.maxAgentOutCount" : "الحد الأقصى للمكالمات الصادرة للموظف المتزامنة",
  "cms.report.field.inCallSuccNum" : "عدد المكالمات اليدوية المتصلة",
  "cms.agentMonitor.label.whisper_success" : "النجاح الهمس",
  "cms.report.message.maxQueueInCountDesc" : "أقصى عدد للمكالمات الواردة المتزامنة التي يكون نوع جهاز خدمتها هو قائمة انتظار المهارات.",
  "cms.report.field.agentReleaseTimeField" : "وقت إصدار المتصل",
  "cms.calllink.field.leaveReason39" : "تقوم CDN بإرجاع نتيجة التوجيه.",
  "cms.indexmonitor.message.InCallAgentCount" : "عدد المكالمات الواردة المتصلة",
  "cms.calllink.field.suspendedAndResumed" : "يتم استئناف المكالمة المعلقة.",
  "cms.calllink.field.leaveReason38" : "قم بتسجيل الدخول إلى CDN من خلال استشارة الموظف.",
  "cms.fullscreen.field.line" : "مخطط خطي",
  "cms.calllink.field.leaveReason37" : "يتم توجيه المكالمة إلى CDN.",
  "cms.calllink.field.leaveReason36" : "إعادة توجيه المكالمة الموضوعة في قائمة انتظار.",
  "cms.report.message.busyTimeRateDesc" : "معدل مدة الانشغال = (إجمالي مدة الانشغال/إجمالي مدة العمل) × 100",
  "cms.calllink.field.leaveReason35" : "يتم إرجاع مكالمة الشبكة إلى مركز الاتصالات الأصلي ووضعها في قائمة الانتظار.",
  "cms.calllink.field.leaveReason34" : "انتهاء المهلة/تجاوز التدفق/فشل استدعاء الشبكة في قائمة انتظار مشغول.",
  "cms.report.field.agentNum" : "معرف الموظف",
  "cms.calllink.field.leaveReason31" : "انتهت مهلة إعادة توجيه الطرف الثالث إلى قائمة الانتظار.",
  "cms.calllink.field.leaveReason30" : "انتهت مهلة التحويل إلى قائمة الانتظار بنجاح.",
  "cms.qcrelationconf.field.groupMemberInfo" : "معلومات عضو مجموعة المستخدمين",
  "cms.report.field.skillIdField" : "معرف قائمة انتظار المهارة التي تنتمي إليها المكالمة",
  "cms.agentMonitor.label.selectAgent" : "يرجى تحديد موظف.",
  "cms.report.field.maxQueueCountField" : "الحد الأقصى لمكالمات قائمة انتظار المهارات المتزامنة",
  "cms.report.message.IVRInBoundDesc" : "إجمالي عدد المكالمات الواردة التي تم إجراؤها باستخدام IVR.",
  "cms.fullscreen.field.yaHei" : "مايكروسوفت YaHei",
  "cms.report.field.releaseCauseField" : "سبب الافراج",
  "cms.qualitycheck.message.deleteSuccess" : "تم الحذف بنجاح.",
  "cms.developerlist.field.description" : "الوصف",
  "cms.fullscreen.title.newChart" : "إنشاء مخطط",
  "cms.indexmonitor.field.ivrTotalCallNum" : "إجمالي مكالمات IVR الواردة",
  "cms.report.field.agentTalkDurationField" : "مدة التحدث للمتصل",
  "cms.recorddrive.field.configSftpAccountPwd" : "كلمة مرور خادم SFTP",
  "cms.indexmonitor.field.totalAgentConnectCallTime" : "مدة المكالمة",
  "cms.calllink.field.leaveReason29" : "تحويل طرف ثالث إلى قائمة الانتظار.",
  "cms.report.message.outCallRateDesc" : "النسبة المئوية لعدد المكالمات الصادرة المتصلة في عدد المكالمات الصادرة.",
  "cms.calllink.field.leaveReason28" : "تم النقل بنجاح إلى قائمة الانتظار.",
  "cms.recorddrive.field.configSftpPathError" : "لا يمكن أن يحتوي مسار تخزين الملفات على './'",
  "cms.qualitycheck.label.selectedGroup" : "مجموعات المستخدمين المحددة",
  "cms.indexmonitor.field.skillAbandonIn60Rate" : "تم التخلي عن المكالمات في الستينيات",
  "cms.voiceinspection.field.socialChat" : "الوسائط المتعددة",
  "cms.systemparam.error.validate.ruleinvalid" : "قاعدة التحقق غير صالحة",
  "cms.tenant.message.pwdCheckmsg" : "كلمتا المرور مختلفتان.",
  "cms.calllink.title.skillQueue" : "قائمة انتظار المهارات المتصلة",
  "cms.fullscreen.field.fiveSec" : "5 ثواني",
  "cms.calllink.msg.numberInvalid" : "تنسيق رقم غير صحيح.",
  "cms.fullscreen.field.indicatorData" : "بيانات المؤشر",
  "cms.report.message.ackBeginDesc" : "وقت بدء الرد على المكالمة.",
  "cms.fullscreen.field.themeName" : "اسم الموضوع",
  "cms.report.field.callerHangUp" : "المتصل شنق",
  "cms.report.field.waitEndField" : "وقت انتهاء الانتظار",
  "cms.report.message.sysSuccRateDesc" : "النسبة المئوية لعدد المكالمات الواردة الناجحة للنظام في إجمالي عدد المكالمات الواردة للنظام.",
  "cms.agentMonitor.label.qcinspector_error" : "لا يمكن إجراء العملية لأنك لست مراقباً.",
  "cms.callcenterinstanceslist.message.ccNameUsed" : "تم استخدام اسم مركز المكالمات.",
  "cms.report.field.outAnswerRate" : "معدل اتصال المكالمة الصادرة",
  "cms.indexmonitor.field.description" : "الوصف",
  "cms.callcenterinstanceslist.message.createSuccess" : "تم إنشاء مركز المكالمات بنجاح.",
  "cms.indexmonitor.field.totalCallNum" : "إجمالي المكالمات",
  "cms.voiceinspection.message.selectVoice" : "حدد تسجيلًا.",
  "cms.report.title.skillMonitor" : "مراقبة أداء قائمة انتظار المهارات",
  "cms.vdnconfiguration.message.updatesuccess" : "تم التحديث بنجاح.",
  "cms.report.field.IVRCallOutSuccNum" : "مكالمات IVR الصادرة المتصلة",
  "cms.report.message.timeRangeCheckSaas" : "لا يمكن أن يتجاوز نطاق الوقت 5 أيام.",
  "cms.report.field.avgInRingTime" : "متوسط مدة المكالمات الواردة للمحطات الطرفية",
  "cms.calllink.field.powerOff" : "تم إيقاف تشغيل الهاتف.",
  "cms.report.message.inAvgRingTimeDesc" : "متوسط مدة رنين طرف الوكيل. متوسط مدة الرنين للمكالمات الواردة اليدوية = إجمالي مدة الرنين للمكالمات الواردة اليدوية/عدد مرات الرد.",
  "cms.report.field.availTimeRate" : "معدل الخمول",
  "cms.report.task.sucess.export" : "تم إنشاء مهمة تصدير. هل تريد بالتأكيد عرضه في قائمة التصدير؟",
  "cms.developerlist.title.success" : "النجاح",
  "cms.agent.label.logouttime" : "الوقت دون اتصال",
  "cms.report.field.avgAcwTime" : "متوسط مدة الالتفاف",
  "cms.recorddrive.placeholder.sftpIdentityChecktips" : "أدخل حسابك الحالي وكلمة المرور.",
  "cms.fullscreen.field.fullscreen" : "ملء الشاشة",
  "cms.report.title.agentMonitor" : "مراقبة ملخص أداء الموظف",
  "cms.systemparam.error.validate.notmatchrulemin" : "معلمة الإدخال أقل من الحد الأدنى",
  "cms.datasource.label.delete" : "حذف",
  "cms.fullscreen.field.dayMonitor" : "إحصائيات مؤشر اليوم",
  "cms.report.field.call6Rate" : "معدل الاتصال اليدوي للمكالمة الواردة في 30 ثانية",
  "cms.report.message.workTimeDesc" : "إجمالي المدة من الوقت الذي يقوم فيه الموظف بتسجيل الدخول إلى الوقت الذي يقوم فيه الموظف بتسجيل الخروج.",
  "cms.indexmonitor.title.callCenterDayMonitor" : "إحصائيات اليوم حول مؤشرات مركز الاتصال",
  "cms.sysparam.config.item.catalog" : "نوع المعلمة",
  "cms.report.field.systemInSucc" : "المكالمات الواردة الناجحة للنظام",
  "cms.systemmonitor.message.timeempty" : "* لا يُسمح بأن يكون وقت البدء أو وقت الانتهاء فارغًا.",
  "cms.failurecall.field.queOverToIvrTime" : "تحويل قائمة الانتظار إلى IVR (ثانية)",
  "cms.report.message.systemInSuccDesc" : "عدد المكالمات الواردة إلى النظام الناجحة، بما في ذلك المكالمات أثناء تشغيل صوت IVR، والوضع في قائمة الانتظار، والرنين على الأجهزة الطرفية للوكيل، ورد الموظف (باستثناء المكالمات الواردة الداخلية).",
  "cms.callcenterinstanceslist.title.chooseMode" : "تحديد الوضع",
  "cms.indexmonitor.message.totalAwcNum" : "عدد الوكلاء في حالة الالتفاف بعد معالجة الأعمال",
  "cms.recorddrive.label.sftpPort" : "منفذ خادم SFTP",
  "cms.report.field.agentLoginNumField" : "الوكلاء الذين تم الاتصال بهم",
  "cms.indexmonitor.message.totalTalkingNum" : "عدد الموظفين الذين يقومون بمعالجة المكالمات",
  "cms.agentMonitor.label.switch_success" : "تم التبديل بنجاح.",
  "cms.agent.label.statetime" : "مدة الحالة",
  "cms.report.message.maxGarpMonth" : "لا يمكن الاستعلام عن البيانات التي تزيد عن 12 شهرًا.",
  "cms.agentmonitor.label.showAlarmTime" : "الوقت",
  "cms.report.title.vdnTrafficPeriodReport" : "تقرير فترة حركة مرور VDN",
  "cms.indexmonitor.field.totalInCallCount" : "المكالمات الواردة عبر الإنترنت",
  "cms.callcenterinstanceslist.field.ccName" : "اسم مركز الاتصال",
  "cms.fullscreen.field.SimHei" : "SimHei",
  "cms.callcenterinstanceslist.field.kafkaUserName" : "KAFKA اسم المستخدم",
  "cms.report.field.queryEndTime" : "وقت الانتهاء",
  "cms.failurecall.field.waitTime.export" : "مدة الانتظار (ثانية)",
  "cms.indexmonitor.field.skillAgentAbandon" : "تم تعليق المكالمات من قبل الوكلاء",
  "cms.report.field.ringTime" : "إجمالي مدة الخاتم",
  "cms.report.field.minAgentCount" : "الحد الأدنى لمكالمات الموظف المتزامنة",
  "cms.report.field.acwTimeRateField" : "معدل مدة الالتفاف",
  "cms.skilladjustment.field.seach" : "البحث",
  "cms.datasource.message.oracleUrlTip" : "سلسلة اتصال قاعدة البيانات: &#8220jdbc:oracle:thin:@ip:port:dbservicename&#8220&#10;ip (عنوان IP) منفذ (رقم المنفذ) dbservicename (اسم قاعدة البيانات)",
  "cms.uapmonitor.labe.mentionnotice" : "2. تأكد من أن مركز المكالمات الظاهري يحتوي على مراقبين وأن المراقبين قاموا بتكوين رقم الهاتف وكلمة مرور تسجيل الدخول. يمكن فرض تسجيل الخروج فقط على الموظفين الذين هم في حالة غير طبيعية.",
  "cms.agentMonitor.label.search" : "البحث",
  "cms.report.title.IVRMonitor" : "مراقبة بيانات IVR",
  "cms.indexmonitor.message.errorInfo" : "الرجاء تحديد قائمة انتظار مهارة واحدة على الأقل.",
  "cms.qualitycheck.field.selected" : "تم تحديده",
  "cms.recorddrive.field.storageLimit" : "أقل حد للتخزين (ميجابايت)",
  "cms.datasource.message.dbNameInput" : "أدخل اسم مصدر بيانات.",
  "cms.indexmonitor.field.ivrCallNum" : "طلبات مكالمات IVR الواردة",
  "cms.qcrelationconf.field.search" : "استعلام",
  "cms.report.field.playVoiceCount" : "أوقات تشغيل الصوت",
  "cms.sysparam.config.reset" : "إعادة الضبط",
  "cms.report.field.threePartyCallCountField" : "مكالمات ثلاثية الأطراف",
  "cms.report.field.maxIVRInCountField" : "الحد الأقصى لمكالمات IVR الواردة المتزامنة",
  "cms.skilladjustment.field.skillid" : "معرف قائمة انتظار المهارات",
  "cms.report.title.agentTrend" : "اتجاه ملخص أداء الوكيل",
  "cms.agent.label.redirtnum" : "إعادة التوجيه",
  "cms.fullscreen.field.busyCountTimeDesc" : "عدد مرات وجود الموظفين في الحالة مشغول.",
  "cms.report.label.export" : "تصدير",
  "cms.export.message.exceedlimit2" : ". تضييق نطاق وقت البيانات أو إضافة معايير تصفية البيانات.",
  "cms.export.message.exceedlimit1" : "يمكن تصدير 0 من سجلات البيانات كحد أقصى. تضييق نطاق وقت البيانات أو إضافة معايير تصفية البيانات.",
  "cms.report.message.minAgentOutCountDesc" : "الحد الأدنى لعدد المكالمات الصادرة المتزامنة التي يكون نوع جهاز خدمتها هو الموظف.",
  "cms.agentMonitor.label.forcebusy_talking" : "الوكيل في محادثة. لا تظهر مشغولا.",
  "cms.voiceinspection.field.videoPlay" : "تشغيل الفيديو",
  "cms.agentmonitor.label.talkingState" : "التحدث",
  "cms.indexmonitor.index.skillQueueServiceLevel" : "نظرة عامة على مستوى خدمة قائمة انتظار المهارات",
  "cms.calllink.field.connectionHold" : "مكالمة قيد الانتظار متصلة.",
  "cms.report.field.loginCountField" : "أوقات تسجيل الدخول",
  "cms.report.field.manToIvrNumCtiField" : "عدد المرات التي يتم فيها إيقاف العامل مؤقتاً لنقله إلى IVR",
  "cms.report.field.minAgentInCountField" : "أدنى مكالمات واردة للموظف متزامنة",
  "cms.callcenter.field.reset" : "إعادة الضبط",
  "cms.uapmonitor.labe.interval" : "فترة المراقبة",
  "cms.report.field.intelligentOutCall" : "مكالمة خارجية ذكية",
  "cms.report.field.inWaitAnsNumField" : "مكالمات واردة يدوية",
  "cms.indexmonitor.field.totalIdeaNum" : "الموظفون في الحالة خامل",
  "cms.callcenterinstanceslist.field.gwPort" : "منفذ عبّارة CC",
  "cms.report.field.playVoiceCountField" : "أوقات تشغيل الصوت",
  "cms.agent.label.duracount" : "إحصائيات المدة",
  "cms.agent.label.avgbusytime" : "متوسط الوقت مشغول",
  "cms.report.field.transferOutCountField" : "مرات التحويل اليدوي",
  "cms.uapmonitor.label.error" : "خطأ",
  "cms.systemparam.error.validate.notmatchrulealpha" : "يرجى إدخال الحروف الهجائية الرقمية أو الشرطة السفلية، وابدأ فقط بالحروف أو الشرطة السفلية",
  "cms.agent.label.currentstatetime" : "مدة الحالة الحالية للعامل",
  "cms.recorddrive.message.sftpIdentityChecktips" : "تم تغيير حساب أو كلمة مرور خادم SFTP. للحفاظ على أمان المعلومات، أدخل حسابك الحالي وكلمة المرور.",
  "cms.report.field.otherTimeRateField" : "معدل المدة الأخرى",
  "cms.monitordetail.field.signinskill" : "قائمة انتظار تسجيل الدخول",
  "cms.report.message.maxAgentInCountDesc" : "الحد الأقصى لعدد المكالمات الواردة المتزامنة التي يكون نوع جهاز الخدمة الخاص بها موظفًا.",
  "cms.report.message.netEntIdDesc" : "معرف NE لخادم الوسائط الذي يولد CDR الحالي.",
  "cms.callcenter.message.updatesuccess" : "تم التحديث بنجاح.",
  "cms.report.field.IVRCallOutNum" : "المكالمات الصادرة لـ IVR",
  "cms.report.message.minIVROutCountDesc" : "الحد الأدنى لعدد المكالمات الصادرة المتزامنة التي يكون نوع جهاز خدمتها هو IVR.",
  "cms.qcrelationconf.title.error" : "خطأ",
  "cms.failurecall.message.currentFailureCall" : "هناك",
  "cms.report.field.avgCallInTimeField" : "متوسط مدة المكالمة",
  "cms.indexmonitor.title.vdnNowMonitor" : "إحصائيات مراقبة في الوقت الفعلي على VDN أثناء التشغيل",
  "cms.agentMonitor.label.interceptNotice" : "استخدم هذه الوظيفة عند الاستماع إلى الموظف الحالي أو مقاطعته أو همسه.",
  "cms.callcenterinstanceslist.field.ccBcsPort" : "منفذ CCBCS LB",
  "cms.custdata.operlog.popwindowmessage" : "الرجاء إدخال كلمة المرور المستخدمة لإنشاء الملف المضغوط. يجب أن تحتوي كلمة المرور المضغوطة على أحرف وأرقام ورموز خاصة باللغة الإنجليزية باللغة الإنجليزية... باللغة",
  "cms.report.field.failAvgTimeField" : "متوسط مدة رنين المكالمات المفقودة",
  "cms.report.message.callerNoDesc" : "رقم الاتصال.",
  "cms.report.field.avgAgentOutCountField" : "متوسط مكالمات الموظف الصادرة المتزامنة",
  "cms.report.field.callSuccRate" : "طلب معدل اتصال",
  "cms.vcallecenter.message.checkpasswordfailed" : "فشل التحقق من قاعدة كلمة مرور تسجيل الدخول",
  "cms.report.message.succWaitAnsRateDesc" : "متوسط مدة الانتظار لجميع المكالمات المتصلة في قائمة الانتظار.",
  "cms.report.field.onlineRate" : "السعر عبر الإنترنت",
  "cms.callcenter.field.deletefailure" : "فشل الحذف",
  "cms.report.field.stateField" : "حالة الاتصال",
  "cms.report.message.nomorethanerror" : "لا يمكن أن يكون التاريخ المحدد قبل {0}.",
  "cms.report.field.avgHoldTimeField" : "متوسط مدة الانتظار",
  "cms.qualitycheck.title.deleteSuccess" : "نجاح الحذف",
  "cms.calllink.field.cancelQueuingTransfer" : "يتم تحويل المكالمة عند إلغاء قائمة الانتظار.",
  "cms.systemmonitor.title.error" : "خطأ",
  "cms.report.field.acwOutCount" : "أوقات التفاف المكالمة الصادرة",
  "cms.indexmonitor.message.agentAbandonIn10NDesc" : "إجمالي عدد مرات الإهمال التي تكون مدة الإجابة فيها أطول من أو تساوي 10 ثوانٍ",
  "cms.report.field.preDeviceNoField" : "يشير إلى معرف الجهاز الخاص بالجهاز السابق",
  "cms.systemmonitor.field.incallNums" : "المكالمات الواردة للنظام",
  "cms.systemmonitor.message.queryErrorInfo" : "فشل الاستعلام.",
  "cms.report.message.inputZipPassword" : "الرجاء إدخال كلمة مرور الضغط",
  "cms.datasource.title.modifyDatasource" : "تحديث مصدر البيانات",
  "cms.indexmonitor.field.inboundConnectRate" : "المكالمات الواردة المتصلة",
  "cms.report.title.abandonRingReport" : "مكالمة مهجورة في تقرير الرنين",
  "cms.uapmonitor.field.registered" : "مسجل",
  "cms.reportsubscribe.field.suspended" : "غير منشور",
  "cms.indexmonitor.message.skillAvgAbandonQueueDesc" : "متوسط مدة قائمة الانتظار لجميع المكالمات التي لم يتم الرد عليها في قائمة الانتظار",
  "cms.indexmonitor.field.callInIVR" : "المكالمات الواردة عبر الإنترنت IVR",
  "cms.fullscreen.field.holdCountTimeDesc" : "عدد المرات التي يكون فيها الموظفون في حالة الانتظار.",
  "cms.calllink.field.analysisNotConfig" : "لم يتم تكوين تحليل الأرقام التي تم الاتصال بها لإجراء مكالمة.",
  "cms.voiceinspection.video.tolong.info" : "قم بتنزيل الفيديو المسجل لتشغيله لأنه طويل.",
  "cms.developerlist.title.refreshsk" : "تحديث SK",
  "cms.failurecall.field.succQueueWaitNum" : "مرات وضع المكالمات في قائمة انتظار ناجحة",
  "cms.calllink.title.caller" : "رقم المتصل",
  "cms.report.field.onlineRateField" : "السعر عبر الإنترنت",
  "cms.indexmonitor.message.agentConnectedIn10NDesc" : "إجمالي عدد المكالمات التي تكون مدة الرد عليها أطول من أو تساوي 10 ثوانٍ",
  "cms.reportsubscribe.field.sunday" : "الأحد",
  "cms.export.message.exception" : "حدث خطأ عند إنشاء مهمة التصدير. تحقق من السجلات.",
  "cms.report.title.vdnSystemPerformanceReport" : "تقرير مراقبة أداء النظام",
  "cms.calllink.title.callee" : "الرقم المطلوب",
  "cms.callcenterinstanceslist.message.dsmodifyconfirm" : "تم تغيير تكوين مصدر البيانات. أعد تشغيل البيئة حتى يصبح التعديل ساري المفعول. هل تريد بالتأكيد تعديله؟",
  "cms.callcenter.message.deletesuccess" : "تم الحذف بنجاح.",
  "cms.report.field.dualCallType" : "بنقرة واحدة اتصالان",
  "cms.callcenter.message.configcallcenter" : "قم بتكوين مركز المكالمات.",
  "cms.report.title.selectReportTime" : "تعيين وقت البدء",
  "cms.report.field.manToSkillNumField" : "تحويلات قائمة انتظار يدوية إلى مهارة",
  "cms.report.message.playVoiceCountDesc" : "عدد مرات تشغيل الصوت.",
  "cms.indexmonitor.label.search" : "البحث",
  "cms.systemmonitor.field.selectCcName" : "اختر مركز الاتصال",
  "cms.systemparam.error.validate.notmatchrule" : "معلمة الإدخال لا تتطابق مع قاعدة التحقق",
  "cms.report.message.agentInBoundDesc" : "عدد المكالمات الواردة التي تم تعيينها إلى قوائم انتظار المهارات أو الموظفين.",
  "cms.report.field.auxTimeRate" : "معدل مدة الراحة",
  "cms.developerlist.message.refreshfailed" : "فشل تحديث SK.",
  "cms.callcenterinstanceslist.message.clearKafkaConfig" : "هل تريد بالتأكيد حذف تكوين عقدة KAFKA؟",
  "cms.fullscreen.message.numberSecondMin" : "الحد الأدنى للقيمة هو 720",
  "cms.indexmonitor.field.totalLoginNum" : "وكلاء تسجيل الدخول",
  "cms.agentMonitor.label.beinidle_error" : "الموظف الذي تم فحصه خامل.",
  "cms.export.field.success" : "النجاح",
  "cms.report.message.agentReleaseTimeDesc" : "وقت إصدار المتصل.",
  "cms.report.field.agentInSuccRateField" : "معدل اتصال المكالمات الواردة يدويًا",
  "cms.qualitycheck.label.editMember" : "تحرير عضو المجموعة",
  "cms.report.field.fifteenminutes" : "15 دقيقة",
  "cms.fullscreen.message.errorMonitorMethod" : "حدد وضع مراقبة.",
  "cms.qualitycheck.label.createusergroup" : "إنشاء UserGroup",
  "cms.mediaType53" : "الوسائط المتعددة",
  "cms.report.message.abortQueueWaitNumDesc" : "عدد المكالمات التي تم إهمالها بشكل استباقي من قبل المشتركين أثناء وضع قائمة الانتظار والرنين.",
  "cms.report.field.avgQueueOutCount" : "متوسط المكالمات الصادرة في قائمة انتظار المهارة المتزامنة",
  "cms.systemparam.error.validate.notmatchrulemax" : "تتجاوز معلمة الإدخال الحد الأقصى",
  "cms.indexmonitor.field.cancel" : "إلغاء",
  "cms.indexmonitor.field.outboundConnectRate" : "المكالمات الصادرة المتصلة",
  "cms.failurecall.field.waitAnsNum" : "انتظار الردود",
  "cms.callcenterinstanceslist.field.vdnName" : "اسم VDN",
  "cms.indexmonitor.title.agentDate" : "بيانات الوكيل",
  "cms.uapmonitor.field.socketNormal" : "عادي",
  "cms.report.field.avgCallInTime" : "متوسط مدة المكالمة",
  "cms.synchronize.message.noConfConfPwd" : "لم يتم تكوين كلمة المرور. قم بتكوين كلمة مرور الواجهة.",
  "cms.report.field.startAgentId" : "معرّف موظف البدء",
  "cms.report.field.avgQueueCountField" : "متوسط مكالمات قائمة انتظار المهارات المتزامنة",
  "cms.report.field.auxCount" : "أوقات الراحة",
  "cms.uapmonitor.labe.inspectorcheckinnum" : "رقم تسجيل دخول مفتش QC",
  "cms.report.field.avgQueueInCount" : "متوسط المكالمات الواردة في قائمة انتظار المهارة المتزامنة",
  "cms.systemparam.error.validate.notmatchrulerange" : "تتجاوز معلمة الإدخال الحد الأقصى للقيمة أو أقل من الحد الأدنى للقيمة",
  "cms.report.message.transferIvrCountDesc" : "عدد مرات التحويل اليدوي إلى IVR.",
  "cms.callcenterinstanceslist.message.wasPsdLengthRangeValidateFailed" : "يجب أن يتراوح طول كلمة المرور بين 16 و32",
  "cms.indexmonitor.field.skillAbandonIn10Rate" : "المكالمات التي تم إهمالها في 10 ثوانٍ",
  "cms.incoming.record.report.beginTime" : "وقت بدء الاستعلام",
  "cms.indexmonitor.message.skillAvgConnectedDesc" : "متوسط مدة المكالمة. متوسط مدة المكالمة الواردة اليدوية = دوارتيون المكالمة الواردة اليدوية / عدد المكالمات الواردة اليدوية المتصلة.",
  "cms.monitordetail.field.mediatype" : "نوع وسائط الاتصال",
  "cms.agentMonitor.label.beinbusy_error" : "الموظف الذي تم فحصه مشغول.",
  "cms.qualitycheck.title.saveSuccess" : "النجاح",
  "cms.sysparam.config.itemdesc" : "الوصف",
  "cms.report.message.manTransferOutNumCtiDesc" : "عدد المرات التي يقوم فيها الموظف بالتحويل إلى IVR ثم التحويل إلى الخارج.",
  "cms.developerlist.field.ak" : "المطور AK",
  "cms.fullscreen.message.numberFirstMin" : "الحد الأدنى للقيمة هو 1280",
  "cms.datasource.title.addDatasource" : "إضافة مصدر بيانات",
  "cms.vcallecenter.message.modifyadminpasswordsuccess" : "تم تعديل كلمة المرور بنجاح.",
  "cms.qcrelationconf.placeholder.groupName" : "اسم المجموعة",
  "cms.calllink.title.agentId" : "معرف الموظف المتصل",
  "cms.indexmonitor.field.outboundConnectNum" : "المكالمات الصادرة المتصلة",
  "cms.indexmonitor.index.callTrends" : "اتجاهات المكالمات",
  "cms.reportsubscribe.field.saturday" : "السبت",
  "cms.indexmonitor.message.inboundConnectRate" : "النسبة المئوية لعدد المكالمات الواردة المتصلة في عدد المكالمات الواردة.",
  "cms.agentmonitor.label.qualityInspection" : "فحص الجودة",
  "cms.indexmonitor.field.skillConnectedIn60Rate" : "المكالمات المتصلة في 60s",
  "cms.qcrelationconf.message.addSuccess" : "إضافة ناجحة",
  "cms.failurecall.field.callSequenceNumber" : "اتصل بـ S/N",
  "cms.report.field.inCallFailNum" : "طلبات المكالمات المفقودة",
  "cms.report.field.submediatype.email" : "Email",
  "cms.report.field.IVRCallOutSuccRate" : "معدل اتصال مكالمة صادرة IVR",
  "cms.qcrelationconf.field.qcCcRoleName" : "دور المفتش",
  "cms.indexmonitor.field.chooseAll" : "تحديد الكل",
  "cms.report.message.outSuccAnsRateDesc" : "النسبة المئوية لعدد المكالمات الصادرة المتصلة في عدد المكالمات الصادرة.",
  "cms.export.message.addExportTaskError" : "فشلت مهمة التصدير الجديدة، يُرجى التحقق من السجل",
  "cms.agentmonitor.label.haveAlarm" : "إنذار",
  "cms.calllink.title.callBeginTime" : "وقت بدء المكالمة",
  "cms.sysparam.config.opterate" : "العملية",
  "cms.agentmonitor.label.idle" : "قوة الخمول",
  "cms.report.field.ivrtrafficreport.ivrinbound" : "IVR احتلال Num",
  "cms.report.field.perAgentNum" : "مكالمات الوكيل",
  "cms.report.message.auxCountDesc" : "عدد المرات التي يدخل فيها الموظف حالة الراحة.",
  "cms.indexmonitor.field.callOutIVR" : "المكالمات الصادرة عبر الإنترنت IVR",
  "cms.agentmonitor.label.preoccupationState" : "مشغول",
  "cms.calllink.field.threePartyCall" : "المكالمة عبارة عن مكالمة ثلاثية الأطراف.",
  "cms.report.message.talkingTimeDesc" : "وقت التحدث للمكالمات.",
  "cms.systemmonitor.field.time" : "الإحصائيات التي تم جمعها",
  "cms.report.field.acdTimeField" : "مدة المكالمة اليدوية",
  "cms.mediaType20" : "مكالمة في الوقت غير الحقيقي",
  "cms.report.title.skillAccessReport" : "تقرير حركة قائمة انتظار المهارات حسب رمز الوصول",
  "cms.callcenter.field.cancel" : "إلغاء",
  "cms.uapmonitor.labe.qcworkno" : "معرف موظف QC",
  "cms.report.field.threePartyCallCountDesc" : "عدد المكالمات ثلاثية الأطراف لأحد الموظفين.",
  "cms.voiceinspection.message.noRecordFound" : "لم يتم العثور على تسجيل مطابق.",
  "cms.report.field.muteTimeField" : "إجمالي مدة الصمت",
  "cms.report.message.timeCheckError" : "لا يمكن أن يتجاوز النطاق الزمني سبعة أيام.",
  "cms.indexmonitor.field.skillAvgConnectedQueue" : "متوسط مدة الانتظار في قائمة الانتظار للمكالمات المتصلة",
  "cms.failurecall.field.reset" : "إعادة الضبط",
  "cms.report.message.billInfo2Desc" : "معرف فريد لمكالمة مزدوجة.",
  "cms.indexmonitor.field.ivrInboundConnectRate" : "مكالمات IVR الواردة المتصلة",
  "cms.report.message.availTimeDesc" : "إجمالي المدة عندما يكون الموظف في الحالة خامل.",
  "cms.voiceinspection.field.operat" : "العملية",
  "cms.voiceinspection.field.predictOutbound" : "التنبؤ الصادر",
  "cms.fullscreen.field.totalCallTime" : "إجمالي مدة المكالمة",
  "cms.callcenterinstanceslist.field.ctiIndexMonitor" : "مراقبة مؤشر نظام CTI",
  "cms.indexmonitor.message.totalInCallCount" : "إجمالي عدد المكالمات الواردة. (لا يتضمن جلسات عمل وسائط متعددة)",
  "cms.fullscreen.field.monitorType" : "نوع المؤشر",
  "cms.indexmonitor.field.skillAbandonIn5Rate" : "المكالمات التي تم إهمالها في 5 ثوانٍ",
  "cms.sendnote.title.tips" : "معلومات",
  "cms.mediaType19" : "مكالمة فيديو شائعة",
  "cms.indexmonitor.field.callRate" : "معدل المكالمات",
  "cms.mediaType18" : "انقر لطلب مكالمة فيديو",
  "cms.sysparam.config.item.detail" : "معلومات المعلمة",
  "cms.skilladjustment.message.notMatchAgent" : "لا يتطابق نوع قائمة انتظار المهارة التي تم ضبطها مع نوع الموظف.",
  "cms.mediaType17" : "وسائط الرسائل",
  "cms.mediaType16" : "معاينة مكالمة صادرة",
  "cms.sendnote.field.notecontent" : "المحتوى",
  "cms.mediaType15" : "مكالمة صادرة متوقعة",
  "cms.mediaType14" : "مكالمة OPS",
  "cms.report.message.dategt12weeks" : "لا يمكن أن يتجاوز نطاق الأسبوع 12 أسبوعًا.",
  "cms.mediaType13" : "مكالمة فيديو لشبكة ISDN 6B+D",
  "cms.qcrelationconf.field.agentCcRoleName" : "فحص دور الحزب",
  "cms.mediaType12" : "مكالمة فيديو لشبكة ISDN 2B+D",
  "cms.mediaType11" : "نقل الملفات",
  "cms.agentMonitor.label.cancelInsert_error" : "فشل إلغاء الإدراج.",
  "cms.mediaType10" : "مشاركة التطبيقات",
  "cms.indexmonitor.message.ivrCallNum" : "عدد المكالمات التي يتم توفير خدمات تلقائية لها بواسطة أجهزة IVR",
  "cms.report.message.otherTimeDesc" : "إجمالي المدة عندما يكون العامل في حالة أخرى، أو مدة الاتصال بمهارات أخرى عند وجود مهارات متعددة.",
  "cms.report.field.outCallTime" : "إجمالي مدة المكالمة الصادرة",
  "cms.callcenterinstanceslist.field.pool" : "وضع التجمع",
  "cms.developerlist.field.search" : "البحث",
  "cms.systemparam.error.validate.notmatchruleemail" : "يرجى إدخال عنوان بريد إلكتروني صحيح",
  "cms.indexmonitor.message.skillIdMaxSize" : "يمكن تحديد خمس قوائم انتظار مهارات كحد أقصى.",
  "cms.report.message.dategt1year" : "لا يمكن أن يتجاوز نطاق الشهر 12 شهرًا.",
  "cms.report.field.callSuccRateField" : "طلب معدل الاتصال",
  "cms.uapmonitor.field.Logout" : "تسجيل الخروج",
  "cms.indexmonitor.message.agentInboundConnectNum" : "عدد المكالمات الواردة يدوياً التي أجاب عليها الموظفون",
  "cms.report.field.minAgentOutCountField" : "الحد الأدنى للمكالمات الصادرة للموظف المتزامنة",
  "cms.agentmonitor.label.noAlarm" : "لا يوجد إنذار",
  "cms.indexmonitor.message.agentConnectInThirty" : "النسبة المئوية لعدد المكالمات المتصلة يدوياً في غضون 30 ثانية من إجمالي عدد المكالمات المتصلة يدوياً.",
  "cms.qcrelationconf.field.agentGroup" : "مجموعة الوكيل",
  "cms.indexmonitor.field.skillAbandonAfter60" : "تم التخلي عن المكالمات بعد الستينيات",
  "cms.export.field.refresh" : "تحديث",
  "cms.report.field.avgIVRInCountField" : "متوسط مكالمات IVR الواردة المتزامنة",
  "cms.datasource.field.backupDbName" : "مصدر بيانات النسخ الاحتياطي",
  "cms.indexmonitor.message.outboundConnectNum" : "عدد المكالمات الصادرة يدوياً والمتصلة رقم مكالمات بروتوكول الاستجابة الصوتية الصوتية IVR الموصولة رقم مكالمات بروتوكول الاستجابة الصوتية IVR المتجهة للخارج",
  "cms.report.message.minIVRCountDesc" : "الحد الأدنى لعدد المكالمات المتزامنة التي يكون نوع جهاز خدمتها هو IVR.",
  "cms.report.field.busyTimeField" : "إجمالي مدة الانشغال",
  "cms.fullscreen.field.bold" : "جريئة",
  "cms.vcallecenter.title.authentication" : "التحقق من الهوية",
  "cms.recorddrive.field.configSftpAccountConfirmPwd" : "SFTP Server تأكيد كلمة المرور",
  "cms.indexmonitor.label.reset" : "إعادة الضبط",
  "cms.agent.label.currentagentstate" : "الحالة الحالية للموظف",
  "cms.callcenterinstanceslist.message.updateCtiVersion2" : "تحقق مما إذا كان ترحيل بيانات CTI قد اكتمل. توخي الحذر عند تنفيذ هذه العملية. (بعد تأكيد العملية، يتم تعطيل وظيفة المزامنة التلقائية.)",
  "cms.skilladjustment.field.inputskill" : "أدخل اسم قائمة انتظار مهارة.",
  "cms.report.title.callCenterReport" : "تقرير حركة VDN",
  "cms.qcrelationconf.field.operator" : "الوكيل العام",
  "cms.incoming.record.field.ifAnswerCall" : "إجابة يدوية",
  "cms.report.field.noAckNumField" : "المكالمات التي لم يتم الرد عليها",
  "cms.agentmonitor.label.showAlarmEmotionType" : "النوع العاطفي",
  "cms.report.message.inAvgCallTimeDesc" : "متوسط مدة المكالمة. متوسط مدة المكالمات الواردة اليدوية المتصلة = مدة المكالمات الواردة اليدوية المتصلة/عدد المكالمات الواردة اليدوية المتصلة.",
  "cms.report.field.agentAlertingTimeField" : "وقت تنبيه المتصل",
  "cms.agentmonitor.label.answering" : "الرد",
  "cms.qcrelationconf.field.role" : "الدور",
  "cms.voiceinspection.field.webphoneVideo" : "انقر لطلب مكالمة فيديو",
  "cms.report.field.muteTime" : "إجمالي مدة الصمت",
  "cms.report.field.outAvgRingTimeField" : "متوسط مدة حلقة المكالمات اليدوية الصادرة",
  "cms.report.field.avgHandleTime" : "متوسط مدة المعالجة",
  "cms.incoming.record.field.ifReqManual" : "هل تم طلب الخدمة اليدوية",
  "cms.report.field.maxAgentOutCountField" : "الحد الأقصى للمكالمات الصادرة للموظف المتزامنة",
  "cms.indexmonitor.message.totalAnsweringNum" : "عدد الموظفين الذين ترن هواتفهم",
  "cms.fullscreen.message.playTheme" : "وقد لعبت هذا الموضوع.",
  "cms.fullscreen.field.yes" : "نعم",
  "cms.indexmonitor.message.skillTimeoutQueueDesc" : "عدد المكالمات المحولة من قائمة انتظار المهارة الأصلية إلى قوائم انتظار مهارة أخرى بسبب انتهاء مهلة قائمة الانتظار",
  "cms.report.field.mediatype.clickvediocall" : "النقر لطلب الفيديو",
  "cms.sysparam.config.itemname.tips" : "أدخل اسم معلمة.",
  "cms.qualitycheck.label.view" : "عرض",
  "cms.agentMonitor.label.error" : "خطأ",
  "cms.vcallecenter.field.adminworkno" : "معرف المسؤول",
  "cms.reportsubscribe.field.subscribeContent" : "الاشتراك المحتوى",
  "cms.voiceinspection.field.callType" : "نوع الاتصال",
  "cms.agentmonitor.label.accountCode" : "أدخل حساب خدمة",
  "cms.indexmonitor.message.agentOutCallCount" : "عدد المكالمات الصادرة التي أجراها العامل الحالي.(لا يتضمن جلسات عمل وسائط متعددة)",
  "cms.developerlist.message.deletefailed" : "فشل الحذف.",
  "cms.reportsubscribe.field.thursday" : "الخميس",
  "cms.report.field.acwTimeField" : "إجمالي مدة الالتفاف",
  "cms.report.field.callerNoField" : "الرقم المتصل",
  "cms.indexmonitor.field.skillOverQueue" : "تجاوز المكالمات إلى قوائم الانتظار",
  "cms.failurecall.queue.abandon" : "استعلام \"الوضع في قائمة انتظار\"",
  "cms.report.field.availTimeField" : "مدة الخمول",
  "cms.indexmonitor.message.mediaTypeMetion" : "عنصر تصفية نوع الوسائط صالح فقط لمؤشرات الأداء الرئيسية في اليوم الحالي واتجاه اليوم الحالي.",
  "cms.agentmonitor.label.occupationState" : "المحتلة",
  "cms.indexmonitor.index.SkillQueueTips2" : "يتم قياس مستوى خدمة قائمة انتظار المهارات بمعدل إكمال المكالمة في غضون 20 ثانية. مستوى الخدمة المتوقع الافتراضي هو 80%. يمكن لمسؤول النظام تعديل مستوى الخدمة المتوقع عن طريق اختيار معلمات النظام > مراقبة مركز الاتصال > مراقبة المؤشر > مستوى الخدمة المتوقع لقائمة انتظار المهارة.",
  "cms.report.field.minIVRInCount" : "الحد الأدنى للمكالمات الواردة المتزامنة IVR",
  "cms.synchronize.message.invalidAdminPwd" : "كلمة مرور غير صحيحة. قم بتغيير كلمة مرور المسؤول.",
  "cms.vcallecenter.title.success" : "النجاح",
  "cms.agentMonitor.label.forceidle_success" : "تم تعيين الموظف إجبارياً إلى الحالة خامل بنجاح.",
  "cms.report.message.currentDateCheck" : "يجب أن يكون الوقت المحدد قبل اليوم الحالي.",
  "cms.vcallcenter.message.unknowsolution" : "اتصل بمهندسي Huawei لتحليل الخطأ وتصحيحه",
  "cms.vcallecenter.message.modifyadminpasswordfail" : "فشل تغيير كلمة المرور.",
  "cms.report.field.onlyIVRServiceField" : "نجاح المكالمات الواردة نقية IVR",
  "cms.monitordetail.field.account" : "الحساب",
  "cms.report.field.agentInBound" : "مكالمات واردة يدوية",
  "cms.report.field.assistsCount" : "طلبات المساعدة الداخلية",
  "cms.callcenterinstanceslist.field.cmsIp" : "عنوان IP LB لـ CMS",
  "cms.systemmonitor.field.agentOnlineCount" : "أرقام الهواتف المحمولة المسجلة",
  "cms.datasource.field.dbServiceType" : "نوع العمل",
  "cms.indexmonitor.message.agentConnectedOut60NDesc" : "إجمالي عدد المكالمات المتصلة التي تزيد مدة الإجابة عليها عن 60 ثانية.",
  "cms.report.field.avgPerHNum" : "متوسط عدد المكالمات الناجحة في الساعة",
  "cms.indexmonitor.field.inboundCallNum" : "المكالمات الواردة",
  "cms.report.field.statInterval" : "الفترة الزمنية",
  "cms.indexmonitor.message.totalCallNum" : "عدد المكالمات الواردة وعدد المكالمات الصادرة",
  "cms.calllind.field.deviceUnkown" : "جهاز غير معروف",
  "cms.uapmonitor.field.socketFault" : "خطأ",
  "cms.agentMonitor.label.insets_success" : "نجح الإدراج.",
  "cms.vcallecenter.message.currentacountpasderror" : "كلمة مرور الحساب الحالي غير صحيحة.",
  "cms.indexmonitor.field.skillAvgQueueAck" : "متوسط مدة الانتظار",
  "cms.report.message.transferOutCountDesc" : "عدد مرات التحويل اليدوي.",
  "cms.qcrelationconf.field.group" : "المجموعة",
  "cms.qcrelationconf.message.queryParamNull" : "لا يمكن أن يكون معرف الموظف واسم مجموعة المستخدمين فارغين في نفس الوقت",
  "cms.agentmonitor.label.Performance" : "الأداء",
  "cms.agentMonitor.label.switch_error" : "فشل التبديل.",
  "cms.report.message.agentServiceRateDesc" : "النسبة المئوية لعدد المكالمات التي أجاب عنها العامل في إجمالي عدد المكالمات الواردة. معدل النجاح اليدوي في إجمالي المكالمات الواردة = عدد المكالمات التي أجاب عليها العامل/ (عدد المكالمات الواردة الناجحة لـ IVR) عدد المكالمات التي أجاب عنها الموظف) × 100",
  "cms.report.field.auxTimeField" : "إجمالي مدة الراحة",
  "cms.reportsubscribe.field.subscribeName" : "اسم الاشتراك",
  "cms.report.field.outAvgAnsTimeField" : "متوسط مدة الرنين للمكالمات الصادرة",
  "cms.export.field.exportBeginTime" : "وقت بدء التصدير",
  "cms.callcenterinstanceslist.field.faultStatus" : "عيب",
  "cms.developerlist.message.error" : "خطأ",
  "cms.report.message.IVRServiceRateDesc" : "النسبة المئوية لعدد المكالمات التي تتم معالجتها بواسطة IVR فقط في إجمالي عدد المكالمات الواردة. معدل نجاح المكالمة الواردة نقية IVR = مكالمات واردة نقية نقية ناجحة / (المكالمات الواردة الناجحة التي تتم عبر نظام الرد التفاعلي (IVR) النقي (IVR)) عدد المكالمات التي تم الرد عليها من قبل الوكيل) × 100.",
  "cms.agentMonitor.label.cancelwhisper_error" : "فشل إلغاء الهمس.",
  "cms.qualitycheck.label.cancel" : "إلغاء",
  "cms.systemmonitor.label.search" : "البحث",
  "cms.indexmonitor.title.filteringIndicator" : "مؤشر الفلتر",
  "cms.report.field.byMonth" : "التقرير الشهري",
  "cms.report.message.availTimeRateDesc" : "النسبة المئوية لإجمالي مدة الخمول للموظف في إجمالي مدة العمل.",
  "cms.recorddrive.title.recorddrivenameconfig" : "تكوين وحدة التخزين",
  "cms.report.field.acwOutTime" : "مدة التفاف المكالمة الصادرة",
  "cms.report.title.callCenterTrend" : "اتجاه أداء VDN",
  "cms.qcrelationconf.field.qcGroup" : "مجموعة فحص الجودة",
  "cms.datasource.field.yes" : "نعم",
  "cms.indexmonitor.message.updateSuccess" : "نجاح التحديث",
  "cms.report.message.timeRangeCheck" : "لا يمكن أن يتجاوز نطاق الوقت يومًا واحدًا.",
  "cms.report.field.call3Rate" : "معدل الاتصال اليدوي للمكالمة الواردة في 15 ثانية",
  "cms.callcenterinstanceslist.message.kafkaNodeInfo" : "KAFKA عقدة",
  "cms.fullscreen.message.addThemeError" : "لم يتم حفظ التخطيط. هل تريد بالتأكيد إضافة موضوع؟",
  "cms.agentMonitor.label.been_inspected" : "جاري فحص الموظف. حاول مرة أخرى لاحقًا.",
  "cms.agentmonitor.label.cancelfail" : "فشل الإلغاء.",
  "cms.report.message.noData" : "لا توجد بيانات متاحة.",
  "cms.agent.label.idlenum" : "وقت الخمول",
  "cms.indexmonitor.field.skillAbandonAfter10" : "تم التخلي عن المكالمات بعد 10 ثوانٍ",
  "cms.monitordetail.field.role" : "دور الوكيل",
  "cms.systemmonitor.field.cancel" : "إلغاء",
  "cms.datasource.label.create" : "إضافة",
  "cms.indexmonitor.message.skillAbandonAfterNDesc" : "عدد طلبات المكالمات التي تم إهمالها يدويًا بعد Ns. حاليًا ، يمكن أن تكون Ns 10s أو 60s.",
  "cms.voiceinspection.field.search" : "استعلام",
  "cms.uapmonitor.label.registererror" : "فشل تسجيل الخروج",
  "cms.report.field.systemInSuccField" : "مكالمات واردة ناجحة للنظام",
  "cms.indexmonitor.field.indicatorName" : "المؤشر",
  "cms.voiceinspection.field.callTime" : "مدة التسجيل",
  "cms.callcenterinstanceslist.field.ccBmsIp" : "عنوان IP LB لـ CCBMS",
  "cms.report.field.auxCountField" : "أوقات الراحة",
  "cms.indexmonitor.message.outboundCallNum" : "عدد المكالمات الصادرة يدوياً رقم المكالمات الصادرة من بروتوكول الإنترنت IVR رقم المكالمات الصادرة من بروتوكول الإنترنت IVR",
  "cms.vcallecenter.message.syncagentfail" : "فشل في مزامنة الموظف.",
  "cms.developerlist.field.updateTime" : "تم التعديل في",
  "cms.callcenterinstanceslist.field.sysAdminPassword" : "كلمة مرور مسؤول CTI",
  "cms.report.field.outVdnAvgTimeField" : "متوسط مدة المكالمة الصادرة في VDN",
  "cms.calllink.msg.dateTooLate" : "لا يمكن أن يكون التاريخ المحدد بعد {0}.",
  "cms.indexmonitor.message.skillConnectedDesc" : "عدد المكالمات المتصلة بالموظفين في قائمة انتظار المهارة.",
  "cms.callcenterinstanceslist.label.search" : "استعلام",
  "cms.uapmonitor.field.socketUninstall" : "إلغاء التثبيت",
  "cms.report.message.threePartyCallCountDesc" : "عدد المكالمات ثلاثية الأطراف.",
  "cms.report.field.callLostOtherNum" : "مكالمات أخرى ضائعة",
  "cms.voiceinspection.field.queryEndTime" : "وقت الانتهاء",
  "cms.callcenterinstanceslist.field.ccBcsIp" : "CCBCS LB عنوان IP",
  "cms.report.field.inAvgCallTime" : "متوسط مدة المكالمة",
  "cms.report.field.outOccupyNumField" : "المكالمات الصادرة",
  "cms.systemparam.error.validate.notmatchrulerequired" : "معلمة الإدخال مطلوبة",
  "cms.agentmonitor.label.agentId" : "معرف الموظف",
  "cms.uapmonitor.message.eidvalidate" : "أدخل المعلمات كما هو مطلوب.",
  "cms.voiceinspection.field.serviceId" : "نوع العمل",
  "cms.fullscreen.field.second" : " (s)",
  "cms.report.field.netEntIdField" : "معرف NE لخادم الوسائط",
  "cms.report.field.description" : "الوصف",
  "cms.reportsubscribe.message.searchSubscribeName" : "الرجاء إدخال اسم الاشتراك",
  "cms.qualitycheck.message.deleteinfo" : "فشل الحذف",
  "cms.report.title.ivrOutboundMonitor" : "مراقبة مكالمة IVR الصادرة",
  "cms.report.message.obsServiceIdDesc" : "معرّف حملة مكالمة صادرة.",
  "cms.agentMonitor.label.forcerelease_success" : "تم تحرير المكالمة إجبارياً بنجاح.",
  "cms.indexmonitor.set.limitB" : "مؤشرات للعرض.",
  "cms.indexmonitor.set.limitA" : "تحديد",
  "cms.indexmonitor.message.skillOnlineWaitCallDesc" : "عدد المكالمات التي يتم وضعها في قائمة الانتظار.",
  "cms.report.message.outFailNumDesc" : "إجمالي عدد المكالمات الصادرة الفاشلة.",
  "cms.qcrelationconf.field.agentAccountName" : "اسم الطرف الذي تم فحصه",
  "cms.report.field.failAnsRateField" : "طلب معدل الخسارة",
  "cms.indexmonitor.field.skillConnectedIn10Rate" : "المكالمات المتصلة في 10 ثواني",
  "cms.failurecall.field.failQueueWaitNum" : "مدة انتظار فقدان المكالمة (الفترات)",
  "cms.calllink.field.pickup" : "يتم التقاط مكالمة من قبل الآخرين.",
  "cms.report.field.acwInCountField" : "مرات التفاف المكالمة الواردة",
  "cms.agentMonitor.label.talking_error" : "الموظف الذي تم فحصه في الحالة تحدث.",
  "cms.sysparam.config.reset.multiple.error" : "لا يمكن إعادة تعيين المعلمات التالية:",
  "cms.callcenterinstanceslist.field.dvDataReport" : "تقرير بيانات DigitalView",
  "cms.indexmonitor.message.inboundConnectNum" : "عدد المكالمات الواردة اليدوية المتصلة برقم الهاتف المحمول رقم مكالمات IVR الواردة المتصلة",
  "cms.calllink.title.beginTime" : "وقت بدء الإحصائيات",
  "cms.incoming.record.field.release.id" : "رمز سبب تحرير المكالمة",
  "cms.indexmonitor.label.callCenterNode" : "عقدة مركز الاتصال",
  "cms.agentmonitor.label.adjust" : "الالتفاف",
  "cms.report.field.endWeek" : "نهاية الأسبوع",
  "cms.qcrelationconf.field.remark" : "ملاحظة",
  "cms.indexmonitor.titile.indicatorSetting" : "إعداد المؤشر",
  "cms.report.message.custHangNumCtiDesc" : "عدد عمليات تعليق العملاء.",
  "cms.reportsubscribe.field.friday" : "الجمعة",
  "cms.indexmonitor.field.ivrOutboundNum" : "مكالمات IVR الصادرة المتصلة",
  "cms.report.field.avgAgentInCountField" : "متوسط المكالمات الواردة إلى الموظف المتزامنة",
  "cms.agent.lable.alarm" : "إنذار",
  "cms.report.field.assistTimeField" : "مدة طلب المساعدة الداخلية",
  "cms.voiceinspection.title.tips" : "معلومات",
  "cms.callcenterinstanceslist.label.nextStep" : "التالي",
  "cms.indexmonitor.message.ivrDurationAvgConnect" : "إجمالي مدة مكالمات IVR/عدد مكالمات IVR",
  "cms.incoming.record.field.releasecause.choose" : "تحديد سبب تحرير المكالمة",
  "cms.report.title.vdnTrafficPeriodTrend" : "اتجاه فترة حركة مرور VDN",
  "cms.developerlist.message.createfailed" : "فشل إنشاء المطور.",
  "cms.report.field.outCallSuccRate" : "معدل اتصال المكالمة الصادرة",
  "cms.report.title.callCenterMonitor" : "مراقبة أداء VDN",
  "cms.datasource.message.dmUrlTip" : "سلسلة اتصال قاعدة البيانات: &#8220jdbc:dm://ip:port/dbservicename&#8220&#10;ip (عنوان IP) منفذ (رقم المنفذ) dbservicename (اسم قاعدة البيانات)",
  "cms.callcenterinstanceslist.message.ivrNumsError" : "يجب أن يكون عدد قنوات IVR أكبر من مجموع عدد قنوات TTS وعدد قنوات ASR.",
  "cms.qcrelationconf.field.delete" : "حذف",
  "cms.reportsubscribe.message.reportNumberError" : "تم إضافة جميع التقارير",
  "cms.report.message.maxAgentCountDesc" : "الحد الأقصى لعدد المكالمات المتزامنة التي يكون نوع جهاز خدمتها هو موظف.",
  "cms.report.field.selectAgent" : "تحديد موظف",
  "cms.vdnconfiguration.message.updatefailed" : "فشل التحديث.",
  "cms.agentmonitor.label.pageRefreshTime" : "البيانات المحدثة",
  "cms.fullscreen.field.report1" : "التقرير اليومي (الوقت المحدد)",
  "cms.indexmonitor.field.confirm" : "تأكيد",
  "cms.report.message.occupancyRateDesc" : "معدل مدة العمل = (إجمالي مدة العمل – مدة الراحة)/إجمالي مدة العمل × 100",
  "cms.fullscreen.field.report0" : "التقرير اليومي (الوقت النسبي)",
  "cms.fullscreen.message.publicTheme" : "(عامة)",
  "cms.report.message.avgInRingTimeDesc" : "متوسط مدة المكالمة الواردة من خلال المحطات الطرفية للعامل.",
  "cms.report.field.outCallSuccNum" : "مكالمات صادرة يدوية متصلة",
  "cms.sendnote.field.ok" : "حسناً",
  "cms.report.field.minIVROutCountField" : "الحد الأدنى من مكالمات IVR المتزامنة الصادرة",
  "cms.synchronize.message.syncStatusOne" : "مزامنة",
  "cms.datasource.message.isDeleteDatasource" : "هل تريد بالتأكيد حذف مصدر البيانات؟",
  "cms.fullscreen.message.selectMessage" : "-اختر-",
  "cms.report.field.systemInBound" : "إجمالي المكالمات الواردة للنظام",
  "cms.fullscreen.field.moveup" : "لأعلى",
  "cms.agentMonitor.label.forceidle_talking" : "الوكيل في محادثة. لا تقم بإظهار الخامل.",
  "cms.failurecall.placeholder.accessCodeInput" : "أدخل رمز وصول",
  "cms.developerlist.title.deleteconfim" : "تأكيد",
  "cms.callcenterinstanceslist.field.developerDesc" : "الوصف",
  "cms.voiceinspection.field.callId" : "تسجيل SN",
  "cms.agentMonitor.label.qcSipNum" : "تعيين رقم تسجيل دخول المستخدم الحالي",
  "cms.reportsubscribe.title.modifyConfim" : "تعديل تأكيد",
  "cms.calllink.field.virtualDevice" : "الجهاز الظاهري",
  "cms.report.field.outAvgCallTimeField" : "متوسط مدة المكالمة اليدوية الصادرة",
  "cms.report.field.workRateField" : "معدل مدة العمل",
  "cms.report.field.avgAcdTimeField" : "متوسط مدة المكالمة اليدوية",
  "cms.monitordetail.field.conferencecount" : "مكالمات ثلاثية الأطراف",
  "cms.report.field.callInCauseField" : "سبب إدخال المكالمة",
  "cms.tenant.message.tenantspaceOk" : "حسناً",
  "cms.report.message.callStartTimeDesc" : "وقت بدء المكالمة.",
  "cms.calllink.field.intercepts" : "تم اعتراض مكالمة.",
  "cms.agentMonitor.label.permission_error" : "ليس لديك السلطة.",
  "cms.agent.label.offline" : "غير متصل",
  "cms.qcrelationconf.field.reset" : "إعادة الضبط",
  "cms.datasource.message.gaussUrlTip" : "سلسلة اتصال قاعدة البيانات: &#8220jdbc:zenith:@ip:port&#8220&#10;ip (عنوان IP) منفذ (رقم المنفذ)",
  "cms.fullscreen.field.no" : "لا",
  "cms.indexmonitor.message.SkillAbandonRateDesc" : "معدل المكالمات المفقودة = 100 – معدل المكالمات المتصلة",
  "cms.report.message.callIdDesc" : "الرقم التسلسلي للمكالمة، الذي يحدد بشكل فريد المكالمة.",
  "cms.report.field.view.export.task" : "عرض مهام التصدير",
  "cms.monitordetail.field.rest" : "الراحة",
  "cms.report.field.outInvalidCallField" : "مكالمات صادرة غير صالحة",
  "cms.fullscreen.field.richtext" : "نص غني",
  "cms.monitordetail.field.totaltalkingcount" : "إجمالي عدد المكالمات",
  "cms.synchronize.message.configured" : "تم التكوين",
  "cms.report.message.callEndDesc" : "وقت انتهاء المكالمة.",
  "cms.report.field.transferOutCount" : "أوقات التحويل اليدوي",
  "cms.agentMonitor.label.updateSip_repeat" : "تم تعيين الرقم.",
  "cms.agentmonitor.label.terminalStatusRefresh" : "تحديث الحالة",
  "cms.report.message.waitAnsRateDesc" : "متوسط مدة رنين طرف الوكيل. متوسط مدة الرنين للمكالمات الواردة اليدوية = إجمالي مدة الرنين للمكالمات الواردة اليدوية/عدد مرات الرد.",
  "cms.recorddrive.field.configSftpAccount" : "حساب SFTP",
  "cms.indexmonitor.title.skillDayMonitor" : "إحصائيات اليوم حول مؤشرات قائمة انتظار المهارات",
  "cms.report.message.agentInSuccRateDesc" : "النسبة المئوية للمكالمات الواردة اليدوية المتصلة إلى إجمالي المكالمات الواردة اليدوية.",
  "cms.report.field.inOccupyNum" : "طلبات الخدمة",
  "cms.callcenterinstanceslist.title.chooseFeature" : "تحديد الميزات",
  "cms.report.field.otherTimeField" : "المدة الإجمالية للدول الأخرى",
  "cms.voiceinspection.message.downloadVoiceRecordFailed" : "فشل تنزيل ملف التسجيل من خادم SFTP.",
  "cms.agent.message.duplicatepwd" : "كلمة المرور الجديدة هي نفس كلمة المرور الحالية.",
  "cms.sysparam.config.select.null" : "يرجى تحديد معلمة.",
  "cms.agentmonitor.label.showAlarmContent" : "محتوى الحوار",
  "cms.report.message.utilizationRateDesc" : "معدل مدة حالة العمل = (إجمالي مدة المكالمة) / (إجمالي مدة العمل – مدة الراحة) × 100",
  "cms.vcallcenter.message.unknowdesc" : "استثناء غير معروف.",
  "cms.report.field.outFailNumField" : "إجمالي المكالمات الصادرة الفاشلة",
  "cms.report.field.agentService" : "المكالمات التي أجاب عليها الوكيل",
  "cms.report.message.maxQueueWaitTimeDesc" : "الحد الأقصى لمدة الانتظار (قائمة الانتظار ومدة الرنين) لجميع المكالمات.",
  "cms.report.message.calleeNoLength" : "أدخل accessCode ثلاثة أرقام على الأقل.",
  "cms.report.field.ivrtrafficreport.totalivrservice" : "إجمالي وقت خدمة IVR",
  "cms.agent.label.talkitem" : "مكالمة",
  "cms.report.field.workRate" : "معدل مدة العمل",
  "cms.indexmonitor.message.agentConnectedIn3NDesc" : "إجمالي عدد المكالمات التي تكون مدة الرد عليها أطول من أو تساوي 3 ثوانٍ",
  "cms.report.title.skillAccessMonitor" : "مراقبة الإحصائيات حول أداء قائمة انتظار المهارة حسب رمز الوصول",
  "cms.indexmonitor.field.totalAnsweringNum" : "الوكلاء في حالة الرد",
  "cms.vdnconfiguration.title.error" : "خطأ",
  "cms.systemmonitor.field.confirm" : "تأكيد",
  "cms.systemmonitor.field.ofcNo" : "رقم الوارد",
  "cms.skilladjustment.field.skillname" : "اسم قائمة انتظار المهارة",
  "cms.fullscreen.field.themeType" : "نوع الموضوع",
  "cms.fullscreen.field.delete" : "حذف",
  "cms.indexmonitor.field.skillAbandonIn30Rate" : "المكالمات التي تم إهمالها في الثلاثينيات",
  "cms.report.field.maxAgentInCountField" : "الحد الأقصى للمكالمات الواردة إلى الموظف المتزامنة",
  "cms.indexmonitor.field.abandonInTen" : "المكالمات التي تم إهمالها في 10 ثوانٍ",
  "cms.report.field.submediatype.line" : "LINE",
  "cms.fullscreen.field.halfHour" : "30 دقيقة",
  "cms.agent.label.alarmstate" : "حالة التنبيه",
  "cms.monitordetail.field.outboundcount" : "مكالمات صادرة ناجحة",
  "cms.datasource.message.updatefailed" : "فشل التحديث.",
  "cms.report.message.call3RateDesc" : "النسبة المئوية لعدد طلبات المكالمات المتصلة يدوياً في 15 ثانية من إجمالي عدد المكالمات التي تطلب خدمات يدوية.",
  "cms.indexmonitor.field.status" : "الحالة",
  "cms.failurecall.field.succWaitAnsTime" : "مدة (مدة) الرد على المكالمة الناجحة",
  "cms.report.message.monthLessThan" : "لا يمكن أن يكون شهر نهاية السنة قبل شهر بداية السنة.",
  "cms.agentMonitor.label.listen_success" : "الاستماع ناجح.",
  "cms.indexmonitor.field.totalRestNum" : "الوكلاء في حالة الراحة",
  "cms.failurecall.field.inCallTime" : "مدة المكالمة اليدوية (بالثانية)",
  "cms.fullscreen.field.nowMonitor" : "مراقبة في الوقت الحقيقي أثناء التشغيل",
  "cms.indexmonitor.field.agentOutboundNum" : "المكالمات الصادرة يدويًا",
  "cms.indexmonitor.message.callOutSuccNum" : "عدد المرات التي يتصل فيها العامل بالمكالمات الصادرة.",
  "cms.title.select" : "تحديد",
  "cms.report.field.avgAcwTimeField" : "متوسط مدة الالتفاف",
  "cms.report.field.outWaitAnsTimeField" : "إجمالي مدة الرنين للمكالمات الصادرة",
  "cms.callcenterinstanceslist.message.nodeIdCheckError" : "تأكد من أن معرف معلومات عقدة CTI وuapId واسمها فريدون.",
  "cms.export.field.selectFileType" : "تحديد تنسيق ملف التصدير",
  "cms.fullscreen.field.reportDates" : "{0} أيام مضت",
  "cms.input.format.invalid" : "تنسيق الإدخال غير صحيح",
  "cms.agentmonitor.label.autoRefresh" : "تحديث تلقائي",
  "cms.export.message.popwindowmessage" : "كلمة المرور لإنشاء ملف مضغوط. القيمة عبارة عن سلسلة من 8 إلى 12 حرفًا ويجب أن تحتوي على أحرف وأرقام وأحرف خاصة (باستثناء مسافات {'|;&$-<>/'}{'\\\\'}{'`!#(){}'}, وأحرف السطر الجديد وأحرف الجدولة ).",
  "cms.report.field.minAgentOutCount" : "الحد الأدنى للمكالمات الصادرة للموظف المتزامنة",
  "cms.indexmonitor.field.agentConnectInTen" : "المكالمات متصلة يدويًا في 10 ثوانٍ",
  "cms.report.field.callEndField" : "وقت انتهاء المكالمة",
  "cms.monitordetail.field.noanswercount" : "لم يتم الرد على المكالمات لفترة طويلة",
  "cms.qcrelationconf.message.selectAgent" : "الرجاء تحديد الموظف المراد حذفه",
  "cms.qcrelationconf.message.selectGroup": "الرجاء تحديد مجموعة المستخدمين المراد حذفها",
  "cms.report.message.uvidDesc" : "معرّف فريد عالميًا تم تخصيصه بواسطة CCS لكل مستخدم يصل إلى مركز الاتصال.",
  "cms.systemmonitor.field.selectAgentName" : "اختر المستأجر",
  "cms.report.message.muteTimeDesc" : "مدة الصمت.",
  "cms.report.field.userLevel" : "مستوى المستخدم",
  "cms.indexmonitor.label.noncommercial" : "الاستخدام التجاري التجريبي",
  "cms.report.title.vdnSystemPerformanceMonitor" : "مراقبة أداء النظام",
  "cms.indexmonitor.field.totalAbandonedCallNum" : "إجمالي المكالمات المفقودة",
  "cms.agent.message.modifypasswordfailed" : "فشل تغيير كلمة المرور.",
  "cms.agentmonitor.label.agentStatus" : "حالة الوكيل",
  "cms.report.message.outCallSuccNumDesc" : "عدد المكالمات اليدوية الصادرة المتصلة.",
  "cms.uapmonitor.field.callIpPort" : "IP والمنفذ",
  "cms.report.field.acdCalls" : "إجمالي المكالمات المتصلة",
  "cms.calllink.field.ivrServiceProcess" : "تدفق الأعمال IVR",
  "cms.report.field.perAgentNumField" : "مكالمات الموظف",
  "cms.indexmonitor.message.ivrInboundFlowOut" : "عدد المكالمات المحولة إلى جهاز آخر بعد أن يقوم IVR بتشغيل إعلان",
  "cms.calllink.field.routingTemporaryDevice" : "جهاز توجيه مؤقت",
  "cms.sysparam.config.mod" : "تحرير",
  "cms.report.field.exceptionHangUp" : "استثناء شنق",
  "cms.export.field.zippwd" : "كلمة مرور مضغوطة",
  "cms.indexmonitor.index.noTrafficData" : "لا توجد بيانات حركة المرور",
  "cms.qualitycheck.message.error_errorinfo" : "فشل الحفظ",
  "cms.synchronize.message.confPwdStatus" : "حالة كلمة مرور واجهة CCBCS",
  "cms.vcallecenter.title.modifyconfigurationinterfacepassword" : "تعيين كلمة مرور واجهة CCBCS في VDN",
  "cms.report.title.skillAllReport" : "تقرير ملخص حركة",
  "cms.report.field.outOccupyNumDesc" : "عدد المرات التي يتصل فيها العامل بالمكالمات الصادرة.",
  "cms.report.field.agentServiceRateField" : "معدل النجاح اليدوي في إجمالي المكالمات الواردة",
  "cms.report.title.IVRAccessTrend" : "اتجاه بيانات IVR حسب رمز الوصول",
  "cms.indexmonitor.message.ivrInCallCount" : "عدد المكالمات الواردة التي يكون جهاز خدمتها الحالي هو IVR.",
  "cms.report.field.avgAgentOutCount" : "متوسط مكالمات الموظف الصادرة المتزامنة",
  "cms.indexmonitor.field.allType" : "جميع الأنواع",
  "cms.monitordetail.field.restduration" : "مدة الراحة",
  "cms.indexmonitor.message.skillAgentAbandonDesc" : "عدد المكالمات التي قام الموظفون بقطعها بشكل استباقي أثناء الرنين",
  "cms.report.field.minAgentCountField" : "الحد الأدنى من مكالمات الموظف المتزامنة",
  "cms.report.field.outWaitAnsTime" : "إجمالي مدة الرنين للمكالمات الصادرة",
  "cms.indexmonitor.field.skillAvgConnectedAck" : "متوسط مدة الرنين للمكالمات المتصلة",
  "cms.report.field.talkingTimeField" : "وقت التحدث للمكالمة",
  "cms.indexmonitor.message.skillSystemLostDesc" : "عدد حالات انقطاع النظام الأساسي الناجمة عن أسباب مثل مهلة استجابة الموظف",
  "cms.agentMonitor.label.updateSip_error" : "تم كتابة الرقم بشكل غير صحيح.",
  "cms.report.field.avgAnsTime" : "متوسط مدة انتظار المكالمات المتصلة",
  "cms.monitordetail.field.information" : "معلومات أساسية",
  "cms.voiceinspection.message.frequentExport" : "تصدير في كثير من الأحيان. يُرجى إعادة المحاولة لاحقًا.",
  "cms.report.field.logoutCountField" : "أوقات تسجيل الخروج",
  "cms.agent.label.agentdetailInfo" : "تفاصيل الوكيل",
  "cms.export.message.exceedNumberLimit" : "حجم البيانات المفرط. تضييق نطاق وقت البيانات أو إضافة معايير تصفية البيانات.",
  "cms.report.field.logoutCount" : "أوقات تسجيل الخروج",
  "cms.vcallecenter.message.syncSuccess" : "تمت مزامنة مراكز المكالمات الظاهرية بنجاح.",
  "cms.report.field.userRefusedFailNumField" : "مكالمات ضائعة بسبب رفض المكالمة",
  "cms.report.message.callSuccRateDesc" : "النسبة المئوية لعدد طلبات المكالمات الواردة اليدوية المتصلة في عدد المكالمات الواردة اليدوية التي تطلب الخدمات.",
  "cms.report.field.agentServiceField" : "الموظف أجاب على المكالمات",
  "cms.report.field.inCallNumField" : "المكالمات الواردة",
  "cms.callcenter.message.sameletterovermaxnum" : "يمكن إضافة ثمانية سجلات بحد أقصى إلى نفس حرف محرك الأقراص.",
  "cms.monitordetail.field.detail" : "تفاصيل الوكيل",
  "cms.report.field.sysLostCallsField" : "فشل مكالمات النظام الواردة",
  "cms.qualitycheck.label.viewusergroup" : "عرض UserGroup",
  "cms.report.field.avgAgentCount" : "متوسط مكالمات الوكيل المتزامنة",
  "cms.monitordetail.field.holdduration" : "مدة الإحتفاظ",
  "cms.indexmonitor.field.skillTimeoutAgent" : "انتهاء مهلة المكالمات إلى الموظفين",
  "cms.report.message.acwInCountDesc" : "عدد المرات التي يدخل فيها العامل حالة التفاف بعد انتهاء المكالمات الواردة.",
  "cms.qcrelationconf.message.isDeleteGroupInfo" : "يُرجى تأكيد حذف مجموعة المستخدمين",
  "cms.calllink.title.logDate" : "وقت الإحصاء",
  "cms.voiceinspection.field.ordinaryVoiceCall" : "صوت",
  "cms.calllink.msg.callerPlaceHolder" : "أدخل رقم المتصل",
  "cms.fullscreen.field.avgCallInTime" : "متوسط مدة المكالمة الواردة اليدوية.",
  "cms.report.title.IVRAccessReport" : "تقرير حركة IVR بواسطة رمز الوصول",
  "cms.datasource.field.test" : "اختبار اتصال",
  "cms.developerlist.message.checkInput" : "لا يمكن أن تحتوي القيمة على أحرف خاصة.",
  "cms.calllink.field.skillQueue" : "قائمة انتظار المهارات",
  "cms.callcenterinstanceslist.message.createFailed" : "فشل إنشاء مركز المكالمات.",
  "cms.failurecall.field.queueName" : "اسم قائمة الانتظار",
  "cms.report.message.workRateDesc" : "معدل مدة العمل = (إجمالي مدة العمل – مدة الراحة)/إجمالي مدة العمل × 100",
  "cms.fullscreen.field.setIndicatorThreshold" : "تعيين عتبات المؤشر",
  "cms.calldetail.msg.timeTooLate" : "لا يمكن أن يكون الوقت المحدد بعد {0}.",
  "cms.report.message.selectAgent" : "حدد معرف موظف واحد على الأقل.",
  "cms.report.field.startYear" : "بداية السنة",
  "cms.agentmonitor.label.countAndTime" : "الوقت/المدة",
  "cms.report.field.userRefusedFailNum" : "مكالمات ضائعة بسبب رفض المكالمة",
  "cms.indexmonitor.title.agentDayMonitor" : "إحصائيات اليوم حول مؤشرات الوكيل",
  "cms.vcallecenter.message.validatefailed" : "فشل التحقق من كلمة المرور.",
  "cms.recorddrive.label.button_add" : "جديد",
  "cms.agentMonitor.label.forceidle_repeat" : "قام الموظف بإظهار خامل. لا تظهر خامل مرة أخرى.",
  "cms.indexmonitor.field.skillSystemLost" : "انقطاعات المنصة",
  "cms.callcenter.field.savefailed" : "خطأ",
  "cms.fullscreen.field.auxCountTimeDesc" : "عدد المرات التي يكون فيها الموظفون في حالة الراحة.",
  "cms.callcenter.message.selectccgatewayservers" : "حدد خادم CC-Gateway.",
  "cms.report.message.acdTimeRateDesc" : "معدل مدة المكالمة اليدوية = مدة المكالمة اليدوية / إجمالي مدة العمل × 100",
  "cms.qcrelationconf.title.confirm" : "تأكيد",
  "cms.report.message.startTimeLessThanEndTime" : "لا يمكن أن يكون وقت البدء بعد وقت الانتهاء.",
  "cms.synchronize.message.invalidConfPwd" : "كلمة مرور غير صحيحة. قم بتغيير كلمة مرور واجهة التكوين.",
  "cms.report.message.inCallNumDesc" : "إجمالي عدد المكالمات الواردة في VDN. القيمة هي مجموع المكالمات الواردة اليدوية والمكالمات الواردة التي يتم إجراؤها تلقائيًا باستخدام IVR.",
  "cms.fullscreen.message.addTheme" : "إضافة موضوع.",
  "cms.indexmonitor.message.skillAbandonDesc" : "عدد طلبات المكالمات الواردة اليدوية المفقودة = عدد طلبات المكالمات الواردة اليدوية - عدد طلبات المكالمات الواردة اليدوية المتصلة",
  "cms.callcenterinstanceslist.field.uapUserName" : "UAP CDE اسم المستخدم",
  "cms.datasource.message.mysql5UrlTip" : "سلسلة اتصال قاعدة البيانات: &#8220jdbc:mysql://ip:port/schema&#8220&#10;ip (عنوان IP) منفذ (رقم المنفذ) مخطط (وضع)",
  "cms.sysparam.config.select.prompt" : "تلميحات",
  "cms.indexmonitor.field.inboundConnectNum" : "المكالمات الواردة المتصلة",
  "cms.indexmonitor.field.skillQueueDisconnected" : "المكالمات التي تم قطع اتصالها في قائمة الانتظار",
  "cms.monitordetail.field.busydurationavg" : "متوسط مدة الانشغال",
  "cms.interfaceperflog.message.modifyFail" : "فشل التعديل",
  "cms.report.field.otherTimeRate" : "معدل المدة الأخرى",
  "cms.vcallecenter.field.enternewpassword" : "أدخل كلمة مرور جديدة",
  "cms.report.field.unspecifiedSkillId" : "غير محدد",
  "cms.calllind.field.deviceQueue" : "قائمة انتظار المهارات",
  "cms.report.field.callLostOtherNumField" : "مكالمات أخرى مفقودة",
  "cms.report.field.IVRCallOutSuccNumField" : "مكالمات IVR الصادرة المتصلة",
  "cms.report.field.abortQueueWaitNum" : "مكالمات وضع في قائمة انتظار مهملة",
  "cms.report.field.busyTime" : "إجمالي مدة الانشغال",
  "cms.callcenterinstanceslist.field.gwIp" : "عنوان IP لبوابة CC-Gateway",
  "cms.indexmonitor.message.warningFilterMax" : "يمكن تحديد مؤشرات {0} كحد أقصى.",
  "cms.report.field.IVROutSuccRate" : "معدل اتصال مكالمة Pure-IVR الصادرة",
  "cms.callcenterinstanceslist.field.kafkaPasswordConfirm" : "تأكيد كلمة مرور كافكا",
  "cms.failurecall.field.hangUpNum" : "عدد المرات التي ينقل فيها الموظف إلى IVR",
  "cms.callcenterinstanceslist.message.specialStrValidateFailed" : "لا يمكن أن يحتوي اسم مركز المكالمات على أحرف خاصة.",
  "cms.callcenterinstanceslist.message.jobSwitch" : "تمكين المزامنة التلقائية",
  "cms.report.message.outCallSuccRateDesc" : "النسبة المئوية للمكالمات الصادرة المتصلة إلى إجمالي المكالمات الصادرة.",
  "cms.indexmonitor.field.durationAvgVdnInbound" : "متوسط مدة المكالمة الواردة",
  "cms.developer.message.modifyDeveloper" : "تحديث معلومات المطور",
  "cms.agent.field.commonagent" : "الوكيل العام",
  "cms.reportsubscribe.field.sentPeriod" : "فترة الإرسال",
  "cms.report.message.IVRInSuccRateDesc" : "النسبة المئوية لمكالمات IVR الواردة المتصلة إلى إجمالي مكالمات IVR الواردة.",
  "cms.report.field.minAgentInCount" : "الحد الأدنى للمكالمات الواردة إلى الموظف المتزامنة",
  "cms.report.field.avgIVRServiceField" : "متوسط مدة مكالمة IVR الواردة",
  "cms.systemmonitor.field.systemInSucc" : "المكالمات المتصلة بالنظام",
  "cms.report.field.inCallSuccNumberField" : "المكالمات الواردة المتصلة",
  "cms.indexmonitor.field.skillConnected" : "طلبات المكالمات المتصلة",
  "cms.report.message.noAckNumDesc" : "عدد المكالمات التي لم يتم الرد عليها بعد تخصيصها للموظفين.",
  "cms.fullscreen.title.editChart" : "تعديل التخطيط",
  "cms.report.field.auxTime" : "إجمالي مدة الراحة",
  "cms.indexmonitor.index.loadFail" : "فشل التحميل",
  "cms.monitordetail.field.outerforward" : "المكالمات المحولة خارجياً",
  "cms.reportsubscribe.field.reportName" : "اسم التقرير",
  "cms.indexmonitor.realTime.indicator" : "مقاييس الوقت الحقيقي",
  "cms.report.field.tableHeader" : "رأس الجدول",
  "cms.indexmonitor.message.queryCallCenterError" : "حدث استثناء عند الاستعلام عن مركز المكالمات المحدد.",
  "cms.systemparam.error.validate.inner" : "فشل التحقق من المعلمة",
  "cms.fullscreen.field.IVR" : "IVR",
  "cms.report.field.IVRCallOutSuccRateField" : "معدل اتصال المكالمات الصادرة IVR",
  "cms.report.message.busyTimeDesc" : "إجمالي المدة عندما يكون الموظف في حالة مشغول.",
  "cms.datasource.message.addfailed" : "فشلت الإضافة.",
  "cms.indexmonitor.message.outboundConnectRate" : "النسبة المئوية لعدد المكالمات الصادرة المتصلة في عدد المكالمات الصادرة.",
  "cms.indexmonitor.message.skillQueueDisconnectedDesc" : "عدد المرات التي يتم فيها قطع اتصال المكالمات بواسطة النظام الأساسي لـ CTI عند وضع المكالمات في قائمة الانتظار",
  "cms.agent.tips.notassined" : "لم يتم تعيينه",
  "cms.report.message.otherHangNumCtiDesc" : "عدد عمليات تعليق النظام.",
  "cms.report.field.avgIVROutCountField" : "متوسط مكالمات IVR الصادرة المتزامنة",
  "cms.reportsubscribe.field.selectedReport" : "قائمة التقارير المحددة",
  "cms.report.message.lessThanError" : "لا يمكن أن يكون معرف موظف الانتهاء أقل من معرف موظف البدء.",
  "cms.report.message.holdTimeDesc" : "إجمالي المدة عندما يكون الموظف في حالة تعليق المكالمة. تتضمن مدة مكالمة الموظف وقت تعليق المكالمة.",
  "cms.report.field.agentOffered" : "مكالمات واردة يدوية",
  "cms.voiceinspection.field.beginTime" : "وقت بدء التسجيل",
  "cms.report.title.skillReport" : "تقرير حركة المهارات",
  "cms.report.field.occupancyRateField" : "معدل مدة العمل",
  "cms.indexmonitor.message.agentAbandonIn30NDesc" : "إجمالي عدد مرات الإهمال التي تكون مدة الإجابة فيها أطول من أو تساوي 30 ثانية",
  "cms.export.message.downloadFailed" : "فشل تنزيل التقرير.",
  "cms.report.message.overNumReason" : "يمكن لوكلاء الوسائط المتعددة والوكلاء من جميع النواحي معالجة المكالمات متعددة القنوات في نفس الوقت.",
  "cms.recorddrive.field.sftpPort" : "رقم الميناء",
  "cms.callcenterinstanceslist.message.odfConfigError" : "معلومات تكوين خدمة ODFS غير صحيحة.",
  "cms.uapmonitor.labe.mentiontitle" : "ملاحظة:",
  "cms.callcenterinstanceslist.message.syncPassValidateFailed" : "يجب أن تحتوي القيمة على أحرف كبيرة وأحرف صغيرة وأرقام وأحرف خاصة `~!@#$%^&*()-_=+|[{}];: '\, <. > /?",
  "cms.callcenterinstanceslist.field.developer" : "مطور مرتبط",
  "cms.callcenterinstanceslist.message.passValidateContinuityFailed" : "لا يمكن أن تحتوي كلمة المرور على ثلاثة أحرف متطابقة متتالية.",
  "cms.report.field.acwInCount" : "أوقات التفاف المكالمة الواردة",
  "cms.qualitycheck.label.qcRelationConfig" : "علاقة فحص الجودة",
  "cms.calllink.field.timeoutTransfer" : "يتم تحويل المكالمة بسبب انتهاء المهلة.",
  "cms.systemparam.success.reset" : "تمت إعادة تعيين المعلمة بنجاح",
  "cms.indexmonitor.message.skillAvgConnectedAckDesc" : "متوسط مدة الرنين لجميع المكالمات المتصلة في قائمة الانتظار",
  "cms.skilladjustment.message.tipsSuccess" : "تم ضبط قائمة انتظار المهارات بنجاح.",
  "cms.report.title.agentOperationReport" : "تقرير عملية الموظف",
  "cms.callcenterinstanceslist.field.kafkaPort" : "ميناء كافكا",
  "cms.agentmonitor.label.cancellisteningOrInsert" : "إلغاء الاستماع أو الإدراج",
  "cms.report.field.acdTime" : "مدة المكالمة اليدوية",
  "cms.report.message.stateDesc" : "حالة الاتصال، وتشمل متصلة وغير متصلة.",
  "cms.indexmonitor.message.agentConnectedIn30NDesc" : "إجمالي عدد المكالمات التي تكون مدة الرد عليها أطول من أو تساوي 30 ثانية",
  "cms.fullscreen.field.fontSize" : "الحجم",
  "cms.indexmonitor.field.skillOnlineConnected" : "عدد المكالمات التي أجاب عنها الموظفون في كتلة الخمس دقائق الحالية",
  "cms.agentmonitor.label.readjustmentState" : "الضبط",
  "cms.indexmonitor.field.agentBoundAbandonRate" : "مكالمات واردة يدوية مهجورة",
  "cms.report.field.ans20sField" : "معدل الاتصال اليدوي في 20 ثانية",
  "cms.indexmonitor.field.durationAvgConnect" : "متوسط مدة المكالمة",
  "cms.agentMonitor.label.talkingerror" : "الموظف المحدد ليس في محادثة أو في محادثة وسائط متعددة.",
  "cms.callcenter.field.finish" : "مكتمل",
  "cms.report.field.inWaitAnsNum" : "مكالمات واردة يدوية",
  "cms.report.field.outCallSuccNumField" : "مكالمات صادرة يدوية متصلة",
  "cms.report.message.outWaitAnsTimeDesc" : "إجمالي مدة الرنين للمكالمات الصادرة.",
  "cms.report.field.userNoAnswerNumField" : "المكالمات المفقودة بسبب مهلة الرنين",
  "cms.report.field.outCallSuccNumberField" : "المكالمات الصادرة المتصلة",
  "cms.report.field.sysLostCalls" : "فشل المكالمات الواردة للنظام",
  "cms.qcrelationconf.placeholder.groupType" : "من فضلك اختر",
  "cms.report.field.otherTime" : "المدة الإجمالية للدول الأخرى",
  "cms.report.field.hangUpModeField" : "طريقة Hang Up",
  "cms.indexmonitor.message.updateFailed" : "فشل التحديث",
  "cms.systemmonitor.label.CommercialUse" : "الاستخدام التجاري",
  "cms.report.field.acwInTime" : "مدة التفاف المكالمة الواردة",
  "cms.fullscreen.message.warningSelectOne" : "حدد مؤشرًا واحدًا فقط لإعداد الحد.",
  "cms.callcenterinstanceslist.message.uapNodeInfo" : "عقدة UAP",
  "cms.calllink.field.threePartyHelp" : "يتم استخدام المكالمة لطلب المساعدة من ثلاثة أطراف.",
  "cms.incoming.record.field.releasecause.causecode" : "الرجاء إدخال رمز تعيين السبب",
  "cms.voiceinspection.field.agentId" : "الوكيل",
  "cms.calllink.field.MELCASDevice" : "جهاز MELCAS",
  "cms.calllink.field.businessRepresentative" : "الوكيل",
  "cms.report.field.availTime" : "مدة الخمول",
  "cms.report.message.deviceNoDesc" : "تشير إلى رقم الجهاز الخاص بالجهاز الذي يعالج المكالمة.",
  "cms.calllink.title.callId" : "معرف المكالمة",
  "cms.failurecall.field.waitAnsTime" : "مدة انتظار الاستجابة (بالثانية)",
  "cms.agent.label.online" : "عبر الإنترنت",
  "cms.fullscreen.field.reportDate" : "منذ 1 يوم",
  "cms.report.title.agentOperationTrend" : "اتجاه تفاصيل عملية اتصال الموظف",
  "cms.indexmonitor.field.skillConnectedIn30Rate" : "المكالمات المتصلة في 30s",
  "cms.agentmonitor.label.signInDetail" : "تفاصيل SignIn",
  "cms.agentmonitor.syncScreenMonitor.getVrpIpError": "لم يقم الموظف الذي تم فحصه بتسجيل الدخول إلى عميل VRCي",
  "cms.agentmonitor.syncScreenMonitor.agentStatus": "يُرجى تسجيل الدخول قبل المراقبة",
  "cms.faliurecall.incomingcall" : "استعلام عن سجل مكالمة واردة",
  "cms.agentmonitor.label.agent" : "الوكيل",
  "cms.vcallecenter.field.enterwaspassword" : "كلمة المرور",
  "cms.indexmonitor.message.agentOutboundNum" : "عدد المكالمات اليدوية الصادرة.",
  "cms.report.message.uapIdDesc" : "معرف UAP الذي يربط المكالمات بمنصة مركز الاتصال.",
  "cms.agentMonitor.label.cancelwhisper_success" : "تم إلغاء Whisper بنجاح.",
  "cms.agent.label.busytime" : "الوقت مشغول",
  "cms.report.field.outCallSuccNumber" : "المكالمات الصادرة المتصلة",
  "cms.reportsubscribe.field.publish" : "نشر",
  "cms.fullscreen.field.play" : "الصوت",
  "cms.report.field.date" : "التاريخ",
  "cms.report.message.holdCountDesc" : "عدد مرات الانتظار.",
  "cms.skilladjustment.message.tipsNotMonitor" : "ليس متخصص QA. فشل ضبط قائمة انتظار المهارات.",
  "cms.calldetail.msg.callIdInvalid" : "التنسيق غير صحيح.",
  "cms.failurecall.field.ackBegin" : "وقت بدء الرد على المكالمة",
  "cms.report.message.avgQueueOutCountDesc" : "متوسط عدد المكالمات الصادرة المتزامنة التي يكون نوع جهاز خدمتها هو قائمة انتظار المهارات.",
  "cms.report.field.succAnsRateField" : "طلب معدل الاتصال",
  "cms.tenant.message.adminpassword" : "كلمة المرور",
  "cms.voiceinspection.field.rate" : "معدل التشغيل",
  "cms.agentmonitor.label.agentName" : "اسم الموظف",
  "cms.fullscreen.field.oneHour" : "1 ساعة",
  "cms.report.field.occupancyRate" : "معدل مدة العمل",
  "cms.report.field.totalLIVRServiceField" : "إجمالي وقت خدمة IVR",
  "cms.report.message.acwOutTimeDesc" : "مدة الالتفاف بعد انتهاء المكالمات الصادرة.",
  "cms.report.field.avgIVRCount" : "متوسط مكالمات IVR المتزامنة",
  "cms.report.field.custHangNumCti" : "تعليق العملاء",
  "cms.callcenterinstanceslist.field.nodemodepool" : "وضع التجمع",
  "cms.report.field.IVRSuccRate" : "معدل اتصال مكالمة IVR الواردة",
  "cms.report.field.inCallFailNumField" : "طلبات المكالمات المفقودة",
  "cms.report.field.month" : "الشهر",
  "cms.indexmonitor.message.skillMinQueueAckDesc" : "الحد الأدنى لمدة الانتظار (الطابور ومدة الرنين) لجميع المكالمات",
  "cms.agentMonitor.message.operaFailed" : "فشلت العملية. جارٍ إجراء فحص أو مكالمة.",
  "cms.report.field.outCallRateField" : "معدل اتصال المكالمات الصادرة",
  "cms.indexmonitor.message.skillOverQueueDesc" : "عدد المكالمات المحولة من قائمة انتظار المهارة الأصلية إلى قوائم انتظار مهارة أخرى بسبب تجاوز السعة",
  "cms.report.message.IVROutSuccNumDesc" : "عدد مكالمات IVR الخارجية المتصلة التي تكون مدة تشغيل جهاز IVR فيها أكبر من 0.",
  "cms.indexmonitor.message.abandonInSixty" : "النسبة المئوية لعدد المكالمات التي تم إهمالها في غضون 60 ثانية من إجمالي عدد المكالمات التي تم إهمالها.",
  "cms.indexmonitor.field.ivrInboundFlowOut" : "مكالمات IVR الواردة المحولة",
  "cms.report.message.call6RateDesc" : "النسبة المئوية لعدد طلبات المكالمات الموصلة يدوياً خلال 30 ثانية من إجمالي عدد المكالمات التي تطلب خدمات يدوية.",
  "cms.agentMonitor.label.intercept_success" : "نجح في اعتراض",
  "cms.indexmonitor.message.ivrOutCallCount" : "عدد المكالمات الصادرة التي يكون جهاز خدمتها الحالي هو IVR.",
  "cms.report.title.skillAllTrend" : "اتجاه ملخص قائمة انتظار المهارات",
  "cms.callcenterinstanceslist.message.ccNameUpdateError" : "عدم تغيير اسم مركز الاتصالات عند تحرير مركز الاتصالات",
  "cms.report.field.callAbandonedInRingField" : "المكالمات المهجورة في Ring",
  "cms.report.message.IVRSuccRateDesc" : "النسبة المئوية لعدد مكالمات IVR الواردة المتصلة في عدد طلبات مكالمات IVR الواردة.",
  "cms.report.title.agentReport" : "تقرير حركة الوكيل",
  "cms.callcenterinstanceslist.field.ccId" : "معرف مركز الاتصال",
  "cms.report.message.failAvgTimeDesc" : "متوسط مدة الرنين لجميع المكالمات التي لم يتم الرد عليها.",
  "cms.export.field.operation" : "التشغيل",
  "cms.indexmonitor.field.agentInCallCount" : "عدد المكالمات الواردة إلى الموظف عبر الإنترنت",
  "cms.indexmonitor.message.durationAvgConnect" : "إجمالي مدة المكالمة المتصلة/إجمالي عدد المكالمات المتصلة",
  "cms.fullscreen.field.bar" : "شريط الرسم البياني",
  "cms.agentmonitor.label.switch" : "التبديل",
  "cms.qualitycheck.field.groupName" : "مجموعة المستخدمين",
  "cms.agentMonitor.label.nosignin_error" : "لم يقم الموظف الذي تم فحصه بتسجيل الدخول.",
  "cms.report.message.otherTimeRateDesc" : "معدل المدة الأخرى = (إجمالي مدة الحالات الأخرى/إجمالي مدة العمل) × 100",
  "cms.report.field.calledHangUp" : "اتصل بـ Hang Up",
  "cms.recorddrive.placeholder.batchdeleteconfim" : "هل أنت متأكد من رغبتك في حذف كافة أحرف محركات الأقراص المحددة؟",
  "cms.callcenterinstanceslist.title.callcenter" : "مركز الاتصال",
  "cms.record-playback.voice.playing" : "الصوت",
  "cms.report.message.acwCountDesc" : "عدد المرات التي يدخل فيها العامل حالة التفاف.",
  "cms.callcenterinstanceslist.label.callCenterRecordDrive" : "رسالة تسجيل محرك مركز الاتصال",
  "cms.qualitycheck.placeholder.userGroupName" : "أدخل مجموعة مستخدمين.",
  "cms.synchronize.message.syncStatusThree" : "فشل المزامنة",
  "cms.datasource.message.masterSalveRelationExist" : "تم استخدام مصدر البيانات كمصدر بيانات النسخ الاحتياطي.",
  "cms.indexmonitor.message.totalLoginNum" : "عدد الموظفين الذين قاموا بتسجيل الدخول إلى مركز الاتصالات",
  "cms.fullscreen.field.maxThreshold" : "الحد الأقصى للعتبة",
  "cms.report.field.inAnswerRateField" : "معدل اتصال المكالمات الواردة",
  "cms.qcrelationconf.field.agentGroupName" : "المجموعة التي تم فحصها",
  "cms.reportsubscribe.message.deleteConfim" : "هل أنت متأكد من رغبتك في الحذف؟",
  "cms.report.message.outVdnAvgTimeDesc" : "متوسط مدة المكالمة الصادرة في VDN. القيمة هي إجمالي مدة المكالمة الصادرة مقسوماً على عدد المكالمات الصادرة.",
  "cms.indexmonitor.message.abandonInThree" : "النسبة المئوية لعدد المكالمات المهملة خلال 3 ثواني من إجمالي عدد المكالمات المهملة.",
  "cms.report.field.IVRInSuccField" : "مكالمات IVR الواردة المتصلة",
  "cms.incoming.record.field.isAnswerCall" : "تم الرد",
  "cms.systemmonitor.field.vdnName" : "المستأجر",
  "cms.report.field.reportType" : "نوع التقرير",
  "cms.agentmonitor.label.noListenedAndInserted" : "لم يتم فحصها",
  "cms.agent.label.selectstatetime" : ">= مدة الحالة (دقائق)",
  "cms.fullscreen.message.enterMessage" : "أدخل",
  "cms.report.field.acwOutTimeField" : "مدة التفاف المكالمة الصادرة",
  "cms.qcrelationconf.field.qualityInspection" : "مدقق الجودة",
  "cms.agent.label.agentlist" : "قائمة الوكلاء",
  "cms.indexmonitor.message.agentConnectIn3Rate" : "النسبة المئوية لعدد المكالمات الموصولة يدوياً في غضون 3 ثوان (بما في ذلك 3 ثوان) إلى إجمالي عدد المكالمات.",
  "cms.report.field.avgIVROutCount" : "متوسط مكالمات IVR الصادرة المتزامنة",
  "cms.voiceinspection.field.video" : "فيديو",
  "cms.voiceinspection.field.reset" : "إعادة الضبط",
  "cms.report.field.internalTransferCountField" : "التحويلات الداخلية",
  "cms.fullscreen.field.table" : "جدول",
  "cms.report.message.acwTimeDesc" : "إجمالي المدة عندما يكون العامل في حالة التفاف.",
  "cms.report.field.outCallNum" : "المكالمات الصادرة",
  "cms.report.field.failAvgTime" : "متوسط مدة حلقة المكالمات المفقودة",
  "cms.report.message.preDeviceTypeDesc" : "نوع الجهاز من آخر جهاز تم الاتصال به.",
  "cms.report.field.muteCountField" : "أوقات الصمت",
  "cms.report.field.ivrtrafficreport.statdate" : "التاريخ",
  "cms.sysparam.config.title" : "معلمات النظام",
  "cms.reportsubscribe.field.wednesday" : "الأربعاء",
  "cms.indexmonitor.field.skillAvgAbandonAck" : "متوسط مدة رنين المكالمات المفقودة",
  "cms.report.message.agentHangNumCtiDesc" : "عدد مرات تعليق الموظف.",
  "cms.sysparam.config.itemname" : "اسم المعلمة",
  "cms.tenant.message.adminpasswordagain" : "تأكيد كلمة المرور",
  "cms.systemmonitor.label.customizes" : "التخصيص",
  "cms.systemparam.success.save" : "تم حفظ المعلمة بنجاح",
  "cms.callcenterinstanceslist.title.baseInfo" : "معلومات أساسية",
  "cms.voiceinspection.field.clickToCall" : "صوت انقر للاتصال",
  "cms.report.field.deviceTypeField" : "نوع الجهاز",
  "cms.mediaType9" : "السبورة الإلكترونية",
  "cms.indexmonitor.field.agentInboundConnectNum" : "مكالمات واردة يدوية متصلة",
  "cms.mediaType8" : "مكالمة فيديو IP (H.323)",
  "cms.reportsubscribe.field.basicInfo" : "معلومات أساسية",
  "cms.mediaType7" : "مكالمة فاكس",
  "cms.uapmonitor.field.socketConnecting" : "جارٍ الاتصال",
  "cms.mediaType6" : "مكالمة عبر البريد الإلكتروني",
  "cms.callcenterinstanceslist.title.sourceconfiguration" : "إعدادات مصدر البيانات",
  "cms.mediaType5" : "مكالمة صوتية شائعة",
  "cms.report.message.callLostOtherNumDesc" : "عدد المكالمات التي لم يتم الرد عليها لأسباب غير الخطوط مشغولة ورفض المكالمة ومهلة الرنين.",
  "cms.mediaType4" : "مكالمة طلب معاودة الاتصال",
  "cms.mediaType3" : "تصفح مرافقة وتقاسم النموذج",
  "cms.interfaceperflog.message.dvSwitch" : "تمكين الإبلاغ عن البيانات",
  "cms.report.message.acdTimeDesc" : "إجمالي مدة مكالمة الموظف، باستثناء مدة المحادثة النصية.",
  "cms.calllink.field.call" : "مكالمة",
  "cms.mediaType2" : "انقر لطلب",
  "cms.mediaType1" : "دردشة نصية",
  "cms.qcrelationconf.field.groupName" : "اسم مجموعة المستخدمين",
  "cms.systemmonitor.field.uapIP" : "عنوان IP لـ UAP",
  "cms.agentmonitor.label.terminalStatusFix" : "حالة الاستعادة",
  "cms.indexmonitor.message.agentConnectedIn5NDesc" : "إجمالي عدد المكالمات التي تكون مدة الرد عليها أطول من أو تساوي 5 ثوانٍ",
  "cms.indexmonitor.message.skillTimeoutAgentDesc" : "عدد المكالمات المحولة من قائمة انتظار المهارة الأصلية إلى موظفين آخرين بسبب انتهاء مهلة الانتظار",
  "cms.reportsubscribe.message.searchTemplateName" : "الرجاء إدخال اسم القالب",
  "cms.report.message.tooManyExport" : "يتم تصدير عدد كبير جدًا من {0} في وقت قصير.",
  "cms.report.field.maxIVRCount" : "الحد الأقصى لمكالمات IVR المتزامنة",
  "cms.report.field.IVRServiceRate" : "معدل نجاح مكالمة Pure-IVR الواردة",
  "cms.fullscreen.field.virtualcallcenter" : "VDN",
  "cms.systemparam.error.save" : "فشل حفظ المعلمة",
  "cms.callcenterinstanceslist.message.uapNode1Info" : "عقدة UAP 1",
  "cms.indexmonitor.message.abandonInTen" : "النسبة المئوية لعدد المكالمات المهملة في غضون 10 ثوانٍ من إجمالي عدد المكالمات المهملة.",
  "cms.systemparam.error.validate.notmatchruleurl" : "الرجاء إدخال عنوان URL صالح",
  "cms.callcenterinstanceslist.message.datasourceInfo" : "أكمل تكوين مصدر البيانات.",
  "cms.report.field.workTime" : "إجمالي مدة العمل",
  "cms.vcallecenter.title.modifyadministratorpassword" : "كلمة مرور مسؤول CTI",
  "cms.callcenterinstanceslist.message.alreadyInuse" : "تم استخدام مقطع معرف الموظف.",
  "cms.calllink.field.callType9" : "مكالمة مرحّلة",
  "cms.report.message.maxQueueOutCountDesc" : "أقصى عدد للمكالمات الصادرة المتزامنة التي يكون نوع جهاز خدمتها هو قائمة انتظار المهارات.",
  "cms.indexmonitor.field.skillLostRing" : "المكالمات التي لم يتم الرد عليها",
  "cms.calllink.field.callType8" : "مكالمة واردة (موظف دولي)",
  "cms.calllink.field.callType7" : "مكالمة مساعدة",
  "cms.report.title.vdnTrafficPeriodMonitor" : "مراقبة فترة حركة مرور VDN",
  "cms.calllink.field.callType6" : "اتصال الشبكة الداخلية الصادرة بوكيل ظاهري",
  "cms.report.field.otherHangNumCtiField" : "عمليات قطع النظام",
  "cms.calllink.field.callType5" : "اتصال الشبكة الداخلية الواردة بوكيل ظاهري",
  "cms.calllink.field.callType4" : "مكالمة فحص واردة لمركز مكالمات الشبكة",
  "cms.report.message.inWaitAnsNumDesc" : "عدد طلبات المكالمات الواردة للخدمة اليدوية.",
  "cms.calllink.field.callType3" : "مكالمة صادرة لمركز مكالمات الشبكة",
  "cms.indexmonitor.message.totalRestNum" : "عدد الموظفين في حالة الراحة",
  "cms.calllink.field.callType2" : "مكالمة واردة لمركز مكالمات الشبكة",
  "cms.calllink.field.callType1" : "مكالمة صادرة لـ IVR PRI",
  "cms.calllink.field.callType0" : "مكالمة صادرة لموظف هاتف",
  "cms.monitordetail.field.innerforward" : "المكالمات المحولة داخليًا",
  "cms.indexmonitor.title.vdnDayTrend" : "اتجاه اليوم من VDN",
  "cms.indexmonitor.field.queueOutCallCount" : "عدد المكالمات الصادرة في قوائم الانتظار عبر الإنترنت",
  "cms.indexmonitor.title.selectAgentName" : "اختر المستأجر",
  "cms.datasource.field.ok" : "حسناً",
  "cms.calllink.field.transferOut" : "تم تحويل مكالمة للخارج.",
  "cms.fullscreen.field.avgCallTime" : "متوسط مدة المكالمة",
  "cms.report.message.agentAlertingDurationDesc" : "مدة تنبيه المتصل.",
  "cms.monitordetail.field.busyDuration" : "مشغول المدة",
  "cms.report.field.submediatype.twitter" : "X (Twitter)",
  "cms.calllind.field.deviceVnr" : "الجهاز الظاهري",
  "cms.indexmonitor.message.warningFiltering" : "حدد مؤشراً واحداً على الأقل",
  "cms.monitordetail.title.statistics" : "إحصائيات",
  "cms.qcrelationconf.field.outgoingAgent" : "موظف الاستدعاء",
  "cms.report.title.abandonRingTrend" : "اتجاه المكالمات المهملة أثناء الرنين",
  "cms.indexmonitor.message.agentConnectAfterInTen" : "النسبة المئوية لعدد المكالمات التي يتم توصيلها يدوياً بعد 10 ثوان من إجمالي عدد المكالمات التي يتم توصيلها يدوياً.",
  "cms.monitordetail.field.totaltalkingduration" : "إجمالي مدة المكالمة",
  "cms.normal.text.validate" : "يُسمح فقط بالأرقام والحروف و-.{'@'}",
  "cms.report.message.skillIdDesc" : "معرف قائمة انتظار المهارة التي تنتمي إليها المكالمة.",
  "cms.monitordetail.field.audio" : "الصوت/الفيديو",
  "cms.fullscreen.message.themeDuplicate" : "اسم مكرر.",
  "cms.sysparam.config.refresh" : "تحديث",
  "cms.report.field.transferIvrCount" : "أوقات التحويل اليدوي إلى IVR",
  "cms.report.field.outAvgCallTime" : "متوسط مدة المكالمة اليدوية الصادرة",
  "cms.datasource.field.dbUsername" : "اسم المستخدم",
  "cms.indexmonitor.title.ivrNowMonitor" : "مراقبة IVR في الوقت الفعلي",
  "cms.indexmonitor.field.skillRequest" : "طلبات الخدمة",
  "cms.datasource.field.no" : "لا",
  "cms.calllink.title.endTime" : "وقت انتهاء الإحصائيات",
  "cms.calllink.title.deviceType" : "نوع الجهاز",
  "cms.callcenterinstanceslist.field.tenantSpaceName" : "حساب المسؤول",
  "cms.agentMonitor.label.forcebusy_repeat" : "أظهر الوكيل أنه مشغول. لا تظهر مشغول مرة أخرى.",
  "cms.failurecall.field.noAckNum" : "لم يتم الرد على أي مكالمات",
  "cms.callcenterinstanceslist.field.vccId" : "معرف مركز الاتصال الافتراضي",
  "cms.indexmonitor.message.agentAbandonIn60Rate" : "النسبة المئوية للمكالمات المهملة خلال 60 ثانية إلى إجمالي المكالمات المهملة",
  "cms.agentmonitor.label.cancelwhisper" : "إلغاء الهمس",
  "cms.indexmonitor.message.skillConnectedInNDesc" : "عدد طلبات المكالمات المتصلة يدويًا بـ Ns. حاليًا ، يمكن أن تكون Ns 3s أو 5s أو 10s أو 20s أو 30s أو 60s.",
  "cms.indexmonitor.field.skillConnectedIn5Rate" : "المكالمات المتصلة في 5 ثواني",
  "cms.report.field.startTime" : "تاريخ البدء",
  "cms.report.field.acwRate" : "معدل مدة الالتفاف",
  "cms.indexmonitor.field.currentSelectedSkill" : "قائمة انتظار المهارات المحددة:",
  "cms.agentMonitor.label.querynum_error" : "فشل تحديث رقم تسجيل الدخول.",
  "cms.report.message.queryErrorInfo" : "فشل الاستعلام.",
  "cms.report.field.skillName" : "اسم قائمة انتظار المهارة",
  "cms.report.field.outCallSuccRateField" : "معدل اتصال المكالمات الصادرة",
  "cms.report.field.loginCount" : "أوقات تسجيل الدخول",
  "cms.callcenterinstanceslist.field.uapCmdPort" : "منفذ أوامر UAP MML",
  "cms.indexmonitor.field.skillMinQueueAck" : "الحد الأدنى لمدة الانتظار",
  "cms.report.field.enterReasonField" : "يشير إلى سبب دخول الجهاز",
  "cms.reportsubscribe.field.subscriptionList" : "قائمة الاشتراك",
  "cms.report.field.callIdField" : "رقم تسلسل المكالمة",
  "cms.report.field.IVRSuccRateField" : "معدل اتصال مكالمة IVR الواردة",
  "cms.qcrelationconf.message.paramOutOfRange" : "لا يمكن أن يتجاوز الإدخال 100 حرف",
  "cms.monitordetail.field.busy" : "مشغول",
  "cms.agent.label.callouttime" : "مدة المكالمة الصادرة",
  "cms.agent.label.signinphone" : "رقم تسجيل الدخول",
  "cms.fullscreen.message.maxThemeNum" : "يمكن إضافة 20 موضوعًا خاصًا و 20 موضوعًا عامًا كحد أقصى.",
  "cms.report.message.releaseCauseDesc" : "سبب تحرير المكالمة.",
  "cms.agent.tips.sipaccountcheck" : "حدد عددًا صحيحًا موجبًا يتراوح من 1 إلى 32 رقمًا كرقم تسجيل الدخول",
  "cms.report.message.perAgentNumDesc" : "عدد المكالمات الواردة المعينة لكل موظف خلال الفترة الزمنية المحددة.",
  "cms.indexmonitor.message.abandonInThirty" : "النسبة المئوية لعدد المكالمات المهملة في غضون 30 ثانية من إجمالي عدد المكالمات المهملة.",
  "cms.qualitycheck.field.unselected" : "UnSelected",
  "cms.report.title.ivrOutboundTrend" : "اتجاه المكالمة الصادرة IVR",
  "cms.monitordetail.field.holdcount" : "المكالمات التي تم تعليقها",
  "cms.report.field.failQueueWaitRateField" : "متوسط مدة رنين المكالمات المفقودة",
  "cms.monitordetail.field.duration" : "إحصائيات عن المدة",
  "cms.indexmonitor.field.skillConnectedAfter60" : "تم الاتصال بالمكالمات بعد الستينيات",
  "cms.qualitycheck.label.trafficGroup" : "مجموعة المرور",
  "cms.report.message.avgIVRServiceDesc" : "متوسط مدة مكالمة IVR الواردة = مدة مكالمة IVR الواردة / عدد مكالمات IVR الواردة المتصلة.",
  "cms.indexmonitor.message.totalCallNumDesc" : "إجمالي عدد المكالمات التي تم توصيلها بواسطة موظف.",
  "cms.report.field.IVRCallOutNumField" : "مكالمات IVR الصادرة",
  "cms.agent.label.choosesybmediatype" : "تحديد نوع قناة",
  "cms.uapmonitor.labe.inspectorcheckinpwd" : "كلمة مرور تسجيل دخول مراقب QC",
  "cms.fullscreen.title.deleteconfim" : "تأكيد",
  "cms.indexmonitor.field.totalBusyNum" : "وكلاء في حالة مشغول",
  "cms.recorddrive.title.sftpServerConfig" : "تكوين خادم SFTP",
  "cms.uapmonitor.labe.eid" : "مقطع رقم المحطة الطرفية",
  "cms.report.field.manToSkillNum" : "تحويلات قائمة انتظار يدوية إلى مهارة",
  "cms.systemparam.success.refresh" : "تم تحديث المعلمة بنجاح",
  "cms.report.message.agentOutSuccDesc" : "عدد المكالمات اليدوية الصادرة المتصلة بالمستخدمين، وهو عدد المكالمات اليدوية الصادرة التي تزيد مدة الاتصال بها عن 0.",
  "cms.callcenterinstanceslist.field.operate" : "العملية",
  "cms.report.field.maxQueueInCount" : "الحد الأقصى للمكالمات الواردة في قائمة انتظار المهارات المتزامنة",
  "cms.indexmonitor.message.agentConnectInTen" : "النسبة المئوية لعدد المكالمات التي يتم توصيلها يدوياً في غضون 10 ثوان من إجمالي عدد المكالمات التي يتم توصيلها يدوياً.",
  "cms.report.field.call4Rate" : "معدل اتصال المكالمات الواردة يدويًا في 20 ثانية",
  "cms.report.message.enterReasonDesc" : "يشير إلى سبب الدخول إلى الجهاز.",
  "cms.report.message.avgHandleTimeDesc" : "متوسط مدة المعالجة = (مدة المكالمة اليدوية) إجمالي مدة الالتفاف)/عدد المكالمات اليدوية الناجحة",
  "cms.agentmonitor.label.total" : "مجموع الوكلاء",
  "cms.agentmonitor.label.syncScreenMonitor": "شاشة المراقبة",
  "cms.indexmonitor.field.inboundAbandonRate" : "المكالمات الواردة المهملة",
  "cms.report.title.IVRReport" : "تقرير حركة IVR",
  "cms.report.field.outWaitAnsNum" : "المكالمات الصادرة يدويًا",
  "cms.agentMonitor.label.Idle_error" : "المراقب ليس في الحالة خامل.",
  "cms.report.field.preDeviceTypeField" : "نوع الجهاز السابق",
  "cms.requiredcallback.message.timeRangeCheck" : "النطاق الزمني هو نفس اليوم",
  "cms.recorddrive.placeholder.singledeleteconfim" : "هل تريد بالتأكيد حذف حرف محرك الأقراص المحدد؟",
  "cms.vcallecenter.title.modifyinfo2" : "تكوين كلمة المرور",
  "cms.export.field.download" : "تحميل",
  "cms.report.field.selectOrg" : "اختر OU",
  "cms.report.field.maxQueueWaitTimeField" : "أقصى مدة انتظار",
  "cms.voiceinspection.message.invalidQcRelation" : "علاقة الفحص غير متطابقة.",
  "cms.qualitycheck.label.input" : "يُرجى الإدخال",
  "cms.report.field.acwOutCountField" : "مرات التفاف المكالمة الصادرة",
  "cms.report.message.outInvalidCallDesc" : "عدد المكالمات الصادرة التي لا يمكن الوصول إلى العملاء بسبب أرقام غير صالحة أو انقطاع التيار الكهربائي.",
  "cms.report.field.userNoAnswerNum" : "المكالمات المفقودة بسبب مهلة الرنين",
  "cms.report.field.agentOfferedField" : "مكالمات واردة يدوية",
  "cms.monitordetail.field.intercallcount" : "المكالمات الداخلية",
  "cms.datasource.field.dbPwd" : "كلمة المرور",
  "cms.report.field.holdCountField" : "أوقات الانتظار",
  "cms.indexmonitor.message.agentBoundConnectRate" : "النسبة المئوية لعدد المكالمات المتصلة يدوياً في عدد المكالمات الواردة يدوياً.",
  "cms.indexmonitor.field.skillConnectedIn60" : "المكالمات المتصلة في 60s",
  "cms.indexmonitor.field.ivrInboundNum" : "مكالمات IVR الواردة المتصلة",
  "cms.uapmonitor.field.deregister" : "هل تريد بالتأكيد إلغاء تسجيل الجهاز؟",
  "cms.indexmonitor.field.totalTalkingNum" : "الوكلاء في المكالمة",
  "cms.callcenterinstanceslist.title.deleteconfim" : "تأكيد الحذف",
  "cms.agentmonitor.label.forceSignOut" : "إجبار على الخروج",
  "cms.callcenterinstanceslist.message.updateSuccess" : "تم تعديل مركز المكالمات بنجاح.",
  "cms.report.message.locationIdDesc" : "معرف العقدة الموزعة التي تنتمي إليها المكالمة.",
  "cms.systemmonitor.message.dategt31days" : "لا يمكن أن يتجاوز نطاق التاريخ 31 يومًا.",
  "cms.agentmonitor.label.countAndDuration" : "الوقت/المدة",
  "cms.agentMonitor.label.forceidle_error" : "فشل في فرض تعيين موظف إلى الحالة خامل.",
  "cms.report.message.outCallNumDesc" : "عدد المكالمات الصادرة يدوياً وتلقائياً في VDN. القيمة هي مجموع المكالمات الصادرة يدويًا والمكالمات الصادرة التي يتم إجراؤها تلقائيًا باستخدام IVR.",
  "cms.indexmonitor.title.selectCcName" : "اختر مركز الاتصال",
  "cms.qcrelationconf.field.accountName" : "اسم الموظف",
  "cms.indexmonitor.message.agentAbandonIn5Rate" : "النسبة المئوية للمكالمات المهملة في غضون 5 ثوانٍ إلى إجمالي المكالمات المهملة",
  "cms.report.field.minQueueCount" : "أدنى مكالمات قائمة انتظار المهارات المتزامنة",
  "cms.report.title.agentOutCallReport" : "تقرير مكالمة صادرة عن الموظف",
  "cms.report.field.submediatype.5gmessage" : "5G RCS",
  "cms.report.field.inCallSuccNumber" : "المكالمات الواردة المتصلة",
  "cms.voiceinspection.message.frequentPlay" : "الصوت متكرر جدا. حاول مرة أخرى لاحقًا.",
  "cms.indexmonitor.title.skillQueueStatistics" : "إحصائيات قائمة انتظار المهارات في اليوم الحالي",
  "cms.calllink.field.outOfService" : "الهاتف خارج الخدمة.",
  "cms.report.field.byWeek" : "التقرير الأسبوعي",
  "cms.indexmonitor.field.abandonInFive" : "المكالمات التي تم إهمالها في 5 ثوانٍ",
  "cms.report.field.IVRInSuccRate" : "معدل اتصال مكالمة IVR الواردة",
  "cms.callcenterinstanceslist.field.backupcc" : "مركز اتصال DR",
  "cms.report.message.callBeginDesc" : "وقت بدء المكالمة.",
  "cms.report.message.failAnsRateDesc" : "معدل فقدان الطلب = 100 - معدل اتصال المكالمة",
  "cms.indexmonitor.message.agentInCallCount" : "عدد المكالمات الواردة المتصلة. (لا يتضمن جلسات وسائط متعددة)",
  "cms.vcallecenter.field.curcountpassword" : "كلمة مرور الحساب الجاري",
  "cms.agent.label.totaltalktime" : "إجمالي مدة المكالمة",
  "cms.report.field.alertingDurationField" : "تسمى مدة التنبيه",
  "cms.agentmonitor.label.showAlarmCallId" : "معرف المكالمة",
  "cms.report.message.waitCauseDesc" : "يشير إلى سبب انتظار المكالمة على الجهاز الحالي.",
  "cms.qcrelationconf.placeholder.workName" : "أدخل اسم موظف",
  "cms.failurecall.field.hangUpReleaseNum" : "عدد المكالمات التي تم تحويلها من يدوي إلى IVR",
  "cms.fullscreen.field.themeTypeY" : "الموضوعات العامة",
  "cms.report.message.currentSkillIdDesc" : "معرف قائمة انتظار المهارات التي تعالج المكالمات.",
  "cms.callcenterinstanceslist.field.uapIp" : "عنوان IP لـ UAP NMU",
  "cms.report.message.startOrEndTimeNoExist" : "في حالة تحديد مقطع وقت للاستعلام، يجب أن يكون وقت البدء ووقت الانتهاء موجودين في نفس الوقت.",
  "cms.systemmonitor.field.systemCallRate" : "معدل اتصال مكالمة النظام",
  "cms.qualitycheck.label.delete" : "حذف",
  "cms.report.field.talkDurationField" : "مدة التحدث",
  "cms.indexmonitor.field.skillAvgAbandonQueue" : "متوسط مدة الانتظار للمكالمات المفقودة",
  "cms.indexmonitor.message.inboundAbandonRate" : "النسبة المئوية لعدد المكالمات الواردة التي لم تكن متصلة بعدد المكالمات الواردة. إذا كان عدد المكالمات الواردة هو 0، فإن قيمة المؤشر هي 0.",
  "cms.callcenterinstanceslist.message.ccgatewayServerBatchdeleteconfim" : "هل تريد بالتأكيد حذف كافة ملقمات CC-Gateway المحددة؟",
  "cms.fullscreen.field.themeTypeN" : "المواضيع الخاصة",
  "cms.systemmonitor.title.tips" : "معلومات",
  "cms.fullscreen.message.deletechart" : "هل تريد تأكيد حذف هذا الرسم البياني؟",
  "cms.report.field.maxQueueOutCount" : "الحد الأقصى لمكالمات قائمة انتظار المهارات المتزامنة الصادرة",
  "cms.report.title.IVRTrend" : "اتجاه بيانات IVR",
  "cms.agentMonitor.label.forcebusy_error" : "فشل في فرض تعيين موظف إلى الحالة مشغول.",
  "cms.systemparam.error.validate.notmatchruleregex" : "لا تتطابق معلمة الإدخال مع التعبير العادي لقاعدة التحقق",
  "cms.report.message.createTimeDesc" : "خلق الوقت.",
  "cms.report.title.changeChart" : "التبديل إلى تخطيط",
  "cms.indexmonitor.message.totalIdeaNum" : "عدد الموظفين الذين هم في حالة خامل ويمكنهم معالجة المكالمات على الفور",
  "cms.calllink.field.helperRelease" : "تم قطع اتصال المكالمة من قِبل الطرف الذي يرسل طلب المساعدة الداخلي.",
  "cms.voiceinspection.field.phoneVideo" : "مكالمة فيديو شائعة",
  "cms.report.message.minQueueOutCountDesc" : "الحد الأدنى لعدد المكالمات الصادرة المتزامنة التي يكون نوع جهاز خدمتها هو قائمة انتظار المهارات.",
  "cms.report.field.callNoPlaceholder" : "الرقم المتصل أو المتصل",
  "cms.indexmonitor.title.skillNowMonitor" : "إحصائيات المراقبة في الوقت الفعلي على قائمة انتظار المهارة أثناء التشغيل",
  "cms.indexmonitor.field.skillConnectedAfter10" : "تم الاتصال بالمكالمات بعد 10 ثوانٍ",
  "cms.report.message.dategt31days" : "لا يمكن أن يتجاوز نطاق التاريخ 31 يومًا.",
  "cms.developerlist.label.refreshsk" : "تحديث SK",
  "cms.callcenterinstanceslist.label.recordDrive" : "تسجيل رسالة محرك الأقراص",
  "cms.fullscreen.title.newTheme" : "إنشاء موضوع",
  "cms.fullscreen.field.edit" : "تعديل",
  "cms.systemmonitor.label.threehours" : "3 ساعات",
  "cms.export.field.viewExportTask" : "تصدير عرض المهمة",
  "cms.indexmonitor.field.skillConnectedIn30" : "المكالمات المتصلة في 30s",
  "cms.fullscreen.message.chooseTheme" : "لم يتم حفظ التخطيط. هل تريد بالتأكيد تغيير الموضوع؟",
  "cms.report.field.minQueueCountField" : "أدنى مكالمات قائمة انتظار المهارات المتزامنة",
  "cms.callcenterinstanceslist.message.ccIdUpdateError" : "لا تقم بتغيير معرف مركز الاتصالات عند تحرير مركز الاتصالات",
  "cms.report.field.agentInSucc" : "مكالمات واردة يدوية متصلة",
  "cms.report.field.waitAnsRateField" : "متوسط مدة الرنين للمكالمات الواردة اليدوية",
  "cms.report.field.outVdnAvgTime" : "متوسط مدة المكالمة الصادرة في VDN",
  "cms.report.field.inAvgRingTime" : "متوسط مدة رنين المكالمات الواردة اليدوية",
  "cms.indexmonitor.field.skillMaxQueueAck" : "أقصى مدة انتظار",
  "cms.indexmonitor.title.vdnDayMonitor" : "إحصائيات اليوم حول مؤشرات VDN",
  "cms.agent.label.numcount" : "إحصائيات العد",
  "cms.report.field.maxAgentCount" : "الحد الأقصى لمكالمات الموظف المتزامنة",
  "cms.report.field.ivrtrafficreport.ivrshortcalls" : "رقم المكالمات القصيرة IVR (خلال 3 ثواني)",
  "cms.report.field.currentSkillIdField" : "معرف قائمة انتظار المهارات",
  "cms.skilladjustment.field.skilltype" : "نوع قائمة انتظار المهارات",
  "cms.export.field.exportiong" : "تصدير",
  "cms.fullscreen.message.selectAgent" : "لا يمكن أن يكون OU والموظف فارغين.",
  "cms.indexmonitor.field.skillConnectedIn20" : "المكالمات المتصلة في 20s",
  "cms.export.message.parainvalid" : "فشل التحقق من شروط التصدير.",
  "cms.report.field.submediatype.web" : "Web",
  "cms.systemmonitor.field.rateNum" : "المكالمات المتصلة بالنظام",
  "cms.fullscreen.field.screenreSolution" : "القرار",
  "cms.report.message.weekLessThan" : "لا يمكن أن يكون أسبوع نهاية السنة أقل من أسبوع بداية السنة.",
  "cms.synchronize.message.adminPwdStatus" : "حالة كلمة مرور مسؤول CTI",
  "cms.fullscreen.message.numberFirst" : "القيمة القصوى هي 7680.",
  "cms.qcrelationconf.message.isDeleteGroupMember" : "يُرجى تأكيد حذف عضو المجموعة",
  "cms.indexmonitor.message.durationAvgVdnOutbound" : "إجمالي المدة التي تشغلها المكالمات الصادرة VDN/إجمالي عدد المكالمات الصادرة",
  "cms.report.message.agentInSuccDesc" : "عدد المكالمات اليدوية الواردة المتصلة بالموظفين، وهو عدد المكالمات اليدوية الواردة التي تزيد مدة المكالمة بها عن 0.",
  "cms.agentMonitor.label.cancelListening_error" : "فشل إلغاء الاستماع.",
  "cms.indexmonitor.message.ivrInboundUserAbort" : "عدد المكالمات التي تم قطعها بشكل استباقي بواسطة مستخدم في IVR",
  "cms.reportsubscribe.title.tips" : "معلومات",
  "cms.fullscreen.field.FangSong" : "FangSong",
  "cms.datasource.message.deletesuccess" : "تم الحذف بنجاح.",
  "cms.systemmonitor.label.reset" : "إعادة الضبط",
  "cms.report.message.minQueueCountDesc" : "الحد الأدنى لعدد المكالمات المتزامنة التي يكون نوع جهاز خدمتها هو قائمة انتظار المهارات.",
  "cms.agentMonitor.label.beinrest_error" : "الموظف الذي تم فحصه في حالة راحة.",
  "cms.systemparam.error.refresh" : "فشل تحديث المعلمة",
  "cms.report.field.IVRInBound" : "المكالمات الواردة IVR",
  "cms.indexmonitor.field.skillAbandonIn60" : "تم التخلي عن المكالمات في الستينيات",
  "cms.report.message.auxTimeRateDesc" : "معدل مدة الراحة = (إجمالي مدة الراحة/إجمالي مدة العمل) × 100",
  "cms.indexmonitor.message.totalLogoutNum" : "عدد الموظفين الذين لم يقوموا بتسجيل الدخول إلى مركز المكالمات وهم في حالة عدم الاتصال",
  "cms.indexmonitor.field.skillConnectedIn10" : "المكالمات المتصلة في 10 ثواني",
  "cms.agent.message.modifypasswordsuccess" : "تم تغيير كلمة المرور بنجاح.",
  "cms.report.field.busyTimeRate" : "مدة مشغول معدل",
  "cms.callcenterinstanceslist.title.callCenter" : "مركز الاتصال",
  "cms.indexmonitor.field.totalAgentNum" : "مجموع الوكلاء",
  "cms.report.field.acwInTimeField" : "مدة التفاف المكالمة الواردة",
  "cms.report.message.inAnswerRateDesc" : "النسبة المئوية لعدد المكالمات اليدوية الواردة المتصلة إلى عدد المكالمات اليدوية الواردة.",
  "cms.indexmonitor.message.skillConnectedRateDesc" : "النسبة المئوية لعدد طلبات المكالمات الواردة اليدوية المتصلة في عدد طلبات المكالمات الواردة اليدوية",
  "cms.report.message.avgPerHNumDesc" : "متوسط عدد المكالمات الناجحة في الساعة في مقطع وقت العمل المحدد.",
  "cms.qcrelationconf.field.qcGroupName" : "مجموعة المفتشين",
  "cms.callcenterinstanceslist.message.workNoCheckError" : "يجب أن يكون رقم بداية معرف العمل أصغر من رقم النهاية.",
  "cms.fullscreen.field.text" : "نص",
  "cms.report.field.leaveReasonField" : "سبب ترك الجهاز",
  "cms.calllink.field.hold" : "تم تعليق مكالمة.",
  "cms.uapmonitor.field.callSize" : "نوع المحطة الطرفية",
  "cms.report.message.voicedataOverError" : "عدد الملفات التي تم تصديرها يتجاوز 0. يُرجى إعادة تحديد معايير البحث.",
  "cms.agentMonitor.label.forcesignout_success" : "تم تسجيل خروج موظف بنجاح.",
  "cms.report.title.skillTrend" : "اتجاه أداء قائمة انتظار المهارات",
  "cms.callcenterinstanceslist.field.tenantName" : "اسم المستأجر",
  "cms.report.message.exportSucceed" : "تم التصدير بنجاح.",
  "cms.report.field.skillQueue" : "قائمة انتظار المهارات:",
  "cms.report.title.calldetailoriginal" : "تفاصيل محتوى المكالمة",
  "cms.report.message.IVRShortCallsDesc" : "عدد المكالمات التي تكون مدة IVR الخاصة بها أكبر من 0 وأقل من أو تساوي 3 ثوانٍ.",
  "cms.agentmonitor.label.cancellistening" : "إلغاء الاستماع",
  "cms.indexmonitor.field.skillAbandonIn5" : "المكالمات التي تم إهمالها في 5 ثوانٍ",
  "cms.systemparam.error.validate.notmatchruledigits" : "الرجاء إدخال عدد صحيح موجب صحيح",
  "cms.indexmonitor.field.skillAbandonIn3" : "المكالمات التي تم إهمالها في 3 ثواني",
  "cms.fullscreen.field.minThreshold" : "الحد الأدنى للعتبة",
  "cms.report.message.selectAgentMax" : "يمكن تحديد 100 معرف موظف كحد أقصى.",
  "cms.monitordetail.field.answerCount" : "المكالمات التي تم الرد عليها",
  "cms.qcrelationconf.field.ok" : "حسناً",
  "cms.indexmonitor.message.agentAbandonIn10Rate" : "النسبة المئوية للمكالمات المهملة في غضون 10 ثوانٍ إلى إجمالي المكالمات المهملة",
  "cms.monitordetail.field.restoutcount" : "فترات استراحة المهلة",
  "cms.qcrelationconf.field.configAdmin" : "مسؤول التكوين",
  "cms.report.field.endMonth" : "نهاية الشهر",
  "cms.report.field.call3RateField" : "معدل اتصال المكالمات الواردة يدويًا في 15 ثانية",
  "cms.indexmonitor.message.ivrTotalCallNum" : "عدد المكالمات التي يتم تخصيصها لـ IVR ويتم خدمتها بواسطة IVR بالكامل",
  "cms.qualitycheck.label.userGroup" : "مجموعة المستخدمين",
  "cms.report.message.maxIVROutCountDesc" : "الحد الأقصى لعدد المكالمات الصادرة المتزامنة التي يكون نوع جهاز خدمتها هو IVR.",
  "cms.fullscreen.field.refreshPeriod" : "فترة التحديث",
  "cms.callcenterinstanceslist.field.uapPassword" : "UAP CDE كلمة المرور",
  "cms.failurecall.field.inOccupyNum" : "الطلبات اليدوية",
  "cms.systemmonitor.field.callRate" : "معدل اتصال مكالمة النظام",
  "cms.report.field.billInfo2Field" : "معرف فريد لمكالمة مزدوجة",
  "cms.voiceinspection.field.preViewOutbound" : "معاينة الصادر",
  "cms.report.field.uapIdField" : "معرف UAP",
  "cms.vcallecenter.resetpwd.message.fail" : "فشل في إعادة تعيين كلمة المرور.",
  "cms.report.message.mediaTypeDesc" : "نوع وسائط الاتصال.",
  "cms.report.message.invalidParam" : "معلمة غير صالحة.",
  "cms.report.message.avgQueueCountDesc" : "متوسط عدد المكالمات المتزامنة التي يكون نوع جهاز خدمتها هو قائمة انتظار المهارات.",
  "cms.callcenterinstanceslist.message.maxNumber" : "يمكن إضافة 10 مراكز اتصال كحد أقصى.",
  "cms.report.field.deviceNoField" : "رقم الجهاز",
  "cms.agentMonitor.laber.notInTalking" : "الموظف ليس في مكالمة. قم بتحديث الحالة وحاول مرة أخرى.",
  "cms.report.field.avgAgentInCount" : "متوسط مكالمات الموظف الواردة المتزامنة",
  "cms.callcenter.message.deletefailed" : "فشل الحذف.",
  "cms.report.field.maxQueueWaitTime" : "أقصى مدة انتظار",
  "cms.report.field.minIVRInCountField" : "الحد الأدنى من مكالمات IVR الواردة المتزامنة",
  "cms.developerlist.message.deleteConfirmMessage" : "هل تريد بالتأكيد حذف المطورين المحددين والتكوينات ذات الصلة؟",
  "cms.report.message.outAboundRingDesc" : "عدد المكالمات الصادرة التي رفضها العملاء أو لم يتم الرد عليها بعد الرنين.",
  "cms.report.title.filteringHeader" : "رأس جدول المرشح",
  "cms.export.field.unprocessed" : "غير المجهزة",
  "cms.vcallecenter.message.syncLoading" : "العملية قيد التقدم. يُرجى إعادة المحاولة لاحقًا.",
  "cms.report.field.agentAlertingDurationField" : "مدة تنبيه المتصل",
  "cms.indexmonitor.field.skillAbandonIn30" : "المكالمات التي تم إهمالها في الثلاثينيات",
  "cms.report.message.acdCallDesc" : "عدد المكالمات الواردة والصادرة المتصلة.",
  "cms.report.message.outCallSuccNumberDesc" : "عدد المكالمات الصادرة التلقائية واليدوية التي يتم الرد عليها بواسطة الأطراف المطلوبة. القيمة هي مجموع المكالمات الصادرة اليدوية المتصلة والمكالمات الصادرة المتصلة تلقائيًا باستخدام IVR.",
  "cms.fullscreen.field.custom" : "التخصيص:",
  "cms.calllink.field.calledBusy" : "الطرف المطلوب مشغول.",
  "cms.report.message.agentServiceDesc" : "عدد المكالمات التي تم الرد عليها بنجاح من قبل الموظفين.",
  "cms.sysparam.config.save" : "حفظ",
  "cms.report.field.outCallNumField" : "المكالمات الصادرة",
  "cms.report.field.holdTime" : "مدة الانتظار",
  "cms.calllink.field.unavailableNumber" : "الرقم غير مخصص.",
  "cms.fullscreen.field.saveTheme" : "حفظ التخطيط",
  "cms.recorddrive.label.button_edit" : "تحرير",
  "cms.indexmonitor.field.agentOutboundConnectNum" : "مكالمات صادرة يدوية متصلة",
  "cms.indexmonitor.field.totalQueueCallNum" : "إجمالي مكالمات قائمة الانتظار",
  "cms.fullscreen.field.chartName" : "اسم المخطط",
  "cms.sendnote.title.selectAgents" : "الوكيل المحدد",
  "cms.report.message.totalLIVRServiceDesc" : "إجمالي مدة مكالمة IVR. في حالة اتصال مكالمة بـ IVR لعدة مرات، تكون مدة خدمة IVR هي إجمالي مدة مكالمة IVR.",
  "cms.indexmonitor.field.skillAbandonIn20" : "المكالمات التي تم إهمالها في العشرينات",
  "cms.report.message.IVRCallOutSuccNumDesc" : "عدد مكالمات IVR الصادرة التي تزيد مدة الاتصال بها عن 0.",
  "cms.report.field.holdCount" : "عقد الأوقات",
  "cms.synchronize.message.syncStatusTwo" : "نجحت المزامنة",
  "cms.agent.field.admin" : "الإداريين",
  "cms.vcallecenter.label.inputnewpwd" : "كلمة مرور جديدة",
  "cms.agent.label.agentname" : "اسم الموظف",
  "cms.systemmonitor.field.talkingNums" : "وكلاء يتحدثون",
  "cms.indexmonitor.field.agentNum" : "عدد الوكلاء",
  "cms.indexmonitor.field.totalLogoutNum" : "وكلاء تسجيل الخروج",
  "cms.vcallecenter.title.resetpwd" : "إعادة تعيين كلمة المرور",
  "cms.indexmonitor.message.agentAbandonIn3NDesc" : "إجمالي عدد مرات الإهمال التي تكون مدة الإجابة فيها أطول من أو تساوي 3 ثوانٍ",
  "cms.recorddrive.label.button_cacel" : "إلغاء",
  "cms.indexmonitor.field.agentConnectInThirty" : "المكالمات متصلة يدويا في 30s",
  "cms.indexmonitor.field.ivrInboundUserAbort" : "إصدار مكالمات IVR الواردة",
  "cms.report.field.submediatype.facebook" : "Facebook",
  "cms.indexmonitor.field.skillAbandonIn10" : "المكالمات التي تم إهمالها في 10 ثوانٍ",
  "cms.failurecall.field.waitBegin" : "وقت انتظار المكالمة",
  "cms.indexmonitor.field.skillAbandonIn3Rate" : "المكالمات التي تم إهمالها في 3 ثواني",
  "cms.calllink.field.doNotAnswer" : "لا يرد المشترك على مكالمة.",
  "cms.datasource.message.mysql8UrlTip" : "سلسلة اتصال قاعدة البيانات: &#8220jdbc:mysql://ip:port/schema&#8220&#10;ip (عنوان IP) منفذ (رقم المنفذ) مخطط (وضع)",
  "cms.callcenterinstanceslist.message.deleteConfirmMessage" : "هل تريد بالتأكيد حذف مركز الاتصالات المحدد والتكوين المرتبط به؟",
  "cms.failurecall.message.person" : "تم إلغاء المكالمات أثناء الانتظار.",
  "cms.report.field.enter.callin" : "الرجاء إدخال الرقم التسلسلي للمكالمة الواردة",
  "cms.callcenterinstanceslist.field.ccGatewayIp" : "عنوان IP لبوابة CC-Gateway",
  "cms.monitordetail.field.restcount" : "الراحة",
  "cms.callcenterinstanceslist.field.interfacePassword" : "كلمة مرور ConfigInterface (CCBCS)",
  "cms.vcallecenter.message.syncrolefail" : "فشل مزامنة الدور.",
  "cms.report.field.IVRInSuccRateField" : "معدل اتصال مكالمة IVR الواردة",
  "cms.fullscreen.field.idleCountTimeDesc" : "عدد مرات وجود الموظفين في الحالة خامل.",
  "cms.uapmonitor.labe.mention" : "1. بعد تمكين وظيفة المراقبة، يتم فرض تسجيل خروج الموظفين الذين تكون حالة تسجيلهم الطرفية غير متناسقة مع حالة تسجيل دخول الموظف بشكل دوري.",
  "cms.report.field.internalCallTimeField" : "مدة المكالمة الداخلية",
  "cms.report.field.filetype" : "تنسيق ملف التصدير",
  "cms.report.field.outSuccAnsRateField" : "معدل اتصال المكالمات الصادرة",
  "cms.vcallecenter.label.tenantaccount" : "حساب المسؤول",
  "cms.report.field.IVROutCallNum" : "المكالمات الصادرة عبر بروتوكول IVR النقي",
  "cms.incoming.record.field.release.causecode" : "سبب تعيين رمز",
  "cms.report.field.endAgentId" : "معرف الموظف النهائي",
  "cms.calllink.title.callTrack" : "مسار المكالمة",
  "cms.qualitycheck.label.editusergroupmember" : "تحرير عضو المجموعة",
  "cms.reportsubscribe.field.tuesday" : "الثلاثاء",
  "cms.agentmonitor.label.alarmDetail" : "التفاصيل",
  "cms.agentmonitor.label.mutilMediaMonitor": "مراقبة جلسة الوسائط المتعددة",
  "cms.agentmonitor.label.stopMutilMediaMonitor": "إيقاف مراقبة الجلسة",
  "cms.callcenter.message.createsuccess" : "تم الإنشاء بنجاح.",
  "cms.callcenterinstanceslist.message.voiceCallNumsError" : "يجب أن يكون الحد الأقصى لعدد المكالمات الصوتية المتزامنة أكبر من أو يساوي مجموع عدد المقاعد الصوتية وعدد قنوات IVR.",
  "cms.calllink.field.noAnswerTransfer" : "يتم تحويل مكالمة بسبب عدم تلقي أي رد من موظف لفترة طويلة.",
  "cms.calllink.title.callTrace" : "رابط الاتصال",
  "cms.report.field.utilizationRate" : "معدل مدة حالة العمل",
  "cms.report.message.monthRangeCheck" : "يجب أن يكون وقت البدء ووقت الانتهاء في نفس الشهر.",
  "cms.agentmonitor.label.cancelsuccess" : "تم الإلغاء بنجاح.",
  "cms.uapmonitor.message.intervalvalidate" : "أدخل رقمًا يتراوح من 300 إلى 86400.",
  "cms.qualitycheck.label.edit" : "تحرير",
  "cms.agentMonitor.label.forcesignout_error" : "فشل تسجيل الخروج كموظف.",
  "cms.report.field.auxTimeRateField" : "معدل مدة الراحة",
  "cms.qcrelationconf.field.qcAccountName" : "اسم المراقب",
  "cms.qualitycheck.label.save" : "حفظ",
  "cms.report.field.year" : "السنة",
  "cms.agent.label.logintime" : "وقت تسجيل الدخول",
  "cms.indexmonitor.field.skillConnectedRate" : "طلبات المكالمات المتصلة",
  "cms.systemparam.error.validate.ruleregexinvalid" : "خطأ في بناء جملة القاعدة بالتحقق من صحتها",
  "cms.fullscreen.field.avgCallTimeDesc" : "متوسط مدة المكالمات التي أجاب عليها الموظفون.",
  "cms.report.field.minIVRCountField" : "الحد الأدنى من مكالمات IVR المتزامنة",
  "cms.report.message.ans20sDesc" : "النسبة المئوية لعدد المكالمات المتصلة يدوياً في العشرينات من إجمالي عدد المكالمات المتصلة يدوياً.",
  "cms.failurecall.field.waitTime" : "مدة الانتظار",
  "cms.export.message.downloadSucceed" : "تم تنزيل التقرير بنجاح.",
  "cms.report.field.callEndTimeField" : "وقت انتهاء المكالمة",
  "cms.qcrelationconf.field.addGroupMember" : "إضافة عضو مجموعة",
  "cms.report.message.assistTimeDesc" : "مدة طلبات المساعدة الداخلية.",
  "cms.indexmonitor.field.agentName" : "اسم المستأجر",
  "cms.synchronize.message.syncStatus" : "حالة التزامن",
  "cms.systemmonitor.label.thirtyminutes" : "30 دقيقة",
  "cms.report.field.hourandminute" : "الوقت",
  "cms.report.field.acwRateField" : "معدل مدة الالتفاف",
  "cms.callcenterinstanceslist.message.passWordLengthValidateFailed" : "يجب أن تحتوي كلمة المرور على 8 إلى 20 حرفًا.",
  "cms.agentMonitor.label.querynum_success" : "رقم الهاتف:",
  "cms.report.message.IVRInSuccDesc" : "عدد مكالمات IVR الواردة التي تزيد مدة الاتصال بها عن 0.",
  "cms.indexmonitor.message.skillAvgQueueAckDesc" : "متوسط مدة انتظار المكالمات متوسط مدة الانتظار (قائمة الانتظار ومدة الرنين) لجميع المكالمات.",
  "cms.incoming.record.not.sameday" : "الاستعلام عبر اليوم غير مدعوم",
  "cms.indexmonitor.message.agentAbandonIn60NDesc" : "إجمالي عدد مرات الإهمال التي تكون مدة الإجابة فيها أطول من أو تساوي 60 ثانية",
  "cms.report.message.minQueueInCountDesc" : "الحد الأدنى لعدد المكالمات الواردة المتزامنة التي يكون نوع جهاز خدمتها هو قائمة انتظار المهارة.",
  "cms.indexmonitor.field.skillOverAgent" : "تجاوز المكالمات إلى الموظفين",
  "cms.report.label.header" : "تعيين رأس الجدول",
  "cms.report.field.outWaitAnsNumField" : "المكالمات الصادرة يدويًا",
  "cms.report.field.accessNo" : "رمز الوصول:",
  "cms.agentmonitor.label.insets" : "إدراج",
  "cms.agentmonitor.label.enterID" : "أدخل معرف الموظف",
  "cms.report.field.maxIVRCountField" : "الحد الأقصى لمكالمات IVR المتزامنة",
  "cms.report.title.saveError" : "خطأ",
  "cms.report.field.internalCallCountField" : "المكالمات الداخلية",
  "cms.report.field.acwTime" : "إجمالي مدة الالتفاف",
  "cms.qcrelationconf.field.workNo" : "معرف الموظف",
  "cms.report.field.maxIVROutCountField" : "الحد الأقصى لمكالمات IVR المتزامنة الصادرة",
  "cms.report.field.inOccupyNumField" : "طلبات الخدمة",
  "cms.report.field.waitBeginField" : "وقت الانتظار للبدء",
  "cms.callcenter.message.createfailed" : "فشل الإنشاء.",
  "cms.agentmonitor.label.intercept" : "اعتراض",
  "cms.report.field.call4RateField" : "معدل اتصال المكالمات الواردة يدويًا في 20 ثانية",
  "cms.sendnote.message.tipsError" : "فشل إرسال ملاحظة.",
  "cms.report.message.hangUpModeDesc" : "شنق الأسلوب.",
  "cms.indexmonitor.message.agentConnectedIn60NDesc" : "إجمالي عدد المكالمات التي تكون مدة الرد عليها أطول من أو تساوي 60 ثانية",
  "cms.report.field.succWaitAnsRate" : "متوسط مدة الانتظار",
  "cms.reportsubscribe.field.templateName" : "اسم قالب البريد الإلكتروني",
  "cms.agentmonitor.label.header" : "تعيين رأس الجدول",
  "cms.agentMonitor.label.cancelInsert_success" : "تم إلغاء الإدراج بنجاح.",
  "cms.report.message.outAvgAnsTimeDesc" : "متوسط مدة رنين المكالمات الصادرة = إجمالي مدة رنين المكالمات الصادرة/عدد مرات الرد",
  "cms.report.message.validateFailed" : "فشل التحقق من تصدير التقرير.",
  "cms.report.field.agentOutSucc" : "مكالمات صادرة يدوية متصلة",
  "cms.indexmonitor.message.totalBusyNum" : "عدد الموظفين في الحالة مشغول",
  "cms.reportsubscribe.message.modifyStatus" : "هل أنت متأكد من رغبتك في تعديل الحالة؟",
  "cms.report.message.queryTimeCheck" : "لا يمكن أن يكون الوقت قبل {0}.",
  "cms.agentMonitor.label.intercept_error" : "فشل الاعتراض.",
  "cms.systemparam.error.validate.notmatchrulemaxlength" : "تتجاوز معلمة الإدخال الحد الأقصى للطول 0",
  "cms.report.field.maxAgentInCount" : "الحد الأقصى للمكالمات الواردة إلى الموظف المتزامنة",
  "cms.agent.field.qualitychecker" : "مدقق الجودة",
  "cms.report.field.notConnected" : "غير متصل",
  "cms.indexmonitor.message.queueOutCallCount" : "عدد المكالمات الصادرة في قائمة الانتظار في قوائم انتظار المهارات.(لا يتضمن جلسات عمل وسائط متعددة)",
  "cms.indexmonitor.field.ccName" : "اسم مركز الاتصال",
  "cms.report.field.ackBeginField" : "وقت بدء الإجابة",
  "cms.indexmonitor.field.queueInCallCount" : "عدد المكالمات الواردة في قائمة الانتظار عبر الإنترنت",
  "cms.systemmonitor.field.ok" : "حسناً",
  "cms.agentMonitor.label.success" : "النجاح",
  "cms.report.field.busyCountField" : "أوقات مشغولة",
  "cms.agentMonitor.label.switchNotice" : "استخدم هذه الوظيفة عند الاستماع إلى الموظف الحالي أو مقاطعته.",
  "cms.report.field.sysSuccRateField" : "معدل نجاح المكالمة الواردة إلى النظام",
  "cms.fullscreen.message.numberCheck" : "يجب أن تكون القيمة 0 أو عددًا صحيحًا موجبًا أو عددًا عشريًا موجبًا بحد أقصى منزلين عشريين.",
  "cms.report.field.endTimeField" : "وقت انتهاء المكالمة",
  "cms.recorddrive.field.configSftpPath" : "مسار الملف",
  "cms.failurecall.field.succQueueWaitTime" : "مدة (مدة) وضع المكالمات في قائمة انتظار ناجحة",
  "cms.callcenterinstanceslist.field.open" : "تمكين",
  "cms.agentmonitor.label.busy" : "القوة مشغول",
  "cms.report.title.abandonRingMonitor" : "مراقبة ملخص المكالمات المهملة أثناء الرنين",
  "cms.skilladjustment.message.tipsOutSide" : "يمكن ضبط 10 وكلاء كحد أقصى في كل مرة.",
  "cms.report.field.inCallSuccRateField" : "معدل اتصال المكالمات الواردة",
  "cms.report.label.reset" : "إعادة الضبط",
  "cms.systemmonitor.field.ideaAgentNum" : "وكلاء الخمول",
  "cms.callcenterinstanceslist.message.updateFailed" : "فشل تعديل مركز المكالمات.",
  "cms.agentmonitor.label.terminalStatus" : "حالة تسجيل المحطة الطرفية",
  "cms.report.field.outInvalidCall" : "مكالمات صادرة غير صالحة",
  "cms.callcenter.field.savesuccess" : "النجاح",
  "cms.qcrelationconf.placeholder.pleaseInput" : "يرجى إدخال",
  "cms.monitordetail.field.calling" : "التحدث",
  "cms.agent.label.calloutsuccrate" : "معدل إتمام المكالمة الصادرة",
  "cms.vdnconfiguration.field.ok" : "حسناً",
  "cms.indexmonitor.message.skillLimit" : "حدد من 1 إلى 10 قوائم انتظار مهارات.",
  "cms.agentMonitor.label.forcebusy_success_calling" : "تم تعيين الموظف إجبارياً إلى الحالة مشغول بنجاح. بعد انتهاء المكالمة، يدخل الموظف الحالة مشغول.",
  "cms.report.field.startMonth" : "بداية الشهر",
  "cms.indexmonitor.message.skillAvgAbandonAckDesc" : "متوسط مدة الرنين لجميع المكالمات التي لم يتم الرد عليها",
  "cms.report.label.search" : "البحث",
  "cms.report.field.succAnsRate" : "طلب معدل اتصال",
  "cms.systemmonitor.field.onlineAgentNums" : "الوكلاء عبر الإنترنت",
  "cms.report.field.internalTransferCount" : "التحويلات الداخلية",
  "cms.report.field.ivrtrafficreport.avgivrservice" : "متوسط مدة مكالمة IVR الواردة",
  "cms.report.field.inVdnAvgTimeField" : "متوسط مدة المكالمة الواردة في VDN",
  "cms.indexmonitor.message.agentConnectedOut10NDesc" : "إجمالي عدد المكالمات المتصلة التي تزيد مدة الإجابة عليها عن 10 ثوانٍ.",
  "cms.recorddrive.label.button_complete" : "مكتمل",
  "cms.report.field.callAbandonedRing" : "عدد المكالمات اليدوية الواردة المفقودة",
  "cms.qualitycheck.message.adjustskill" : "تم تعديل قائمة انتظار المهارات الخاصة بك مؤقتاً إلى {skillList}. ستتم استعادة المهارة الأصلية بعد تسجيل الدخول مرة أخرى.",
  "cms.recorddrive.title.sftpIdentityCheck" : "التحقق من الهوية",
  "cms.report.field.succWaitAnsRateField" : "متوسط مدة الانتظار",
  "cms.report.field.acdCallField" : "إجمالي المكالمات المتصلة",
  "cms.report.field.transferIvrCountField" : "أوقات التحويل اليدوي إلى IVR",
  "cms.report.message.call4RateDesc" : "النسبة المئوية لعدد طلبات المكالمات التي يتم توصيلها يدوياً خلال 20 ثانية من إجمالي عدد المكالمات التي تطلب خدمات يدوية.",
  "cms.report.field.callIdNumField" : "رقم تسلسل CDR",
  "cms.indexmonitor.title.ivrDayTrend" : "اتجاه اليوم من IVR",
  "cms.report.message.agentOutSuccRateDesc" : "النسبة المئوية للمكالمات الصادرة اليدوية المتصلة إلى إجمالي المكالمات الصادرة اليدوية.",
  "cms.indexmonitor.field.totalAgentConnectCallNum" : "عدد المرات التي يكون فيها الموظفون في حالة محادثة",
  "cms.indexmonitor.message.ivrInboundConnectRate" : "النسبة المئوية لعدد مكالمات IVR الواردة المتصلة في عدد طلبات مكالمات IVR الواردة",
  "cms.callcenterinstanceslist.field.nodemodesingle" : "وضع واحد",
  "cms.indexmonitor.field.durationAvgWait" : "متوسط مدة انتظار المكالمات الواردة",
  "cms.indexmonitor.field.agentConnectAfterInSixty" : "يتم توصيل المكالمات يدويًا بعد الستينيات",
  "cms.indexmonitor.label.previous" : "السابق",
  "cms.callcenterselect.title.selectCcName" : "اختر مركز الاتصال",
  "cms.report.field.awarnessRate" : "معدل نجاح المكالمة الواردة اليدوية",
  "cms.vcallecenter.message.syncskillfail" : "فشل في مزامنة المهارات.",
  "cms.agentmonitor.label.qcstate_error" : "فشل الإلغاء. لا يتم تنفيذ أي عملية QC على الموظف المحدد.",
  "cms.indexmonitor.field.skillOnlineWaitCall" : "مكالمات قائمة الانتظار عبر الإنترنت",
  "cms.indexmonitor.message.accesscodeCalloutNum" : "عدد المكالمات الصادرة عن طريق أرقام الاتصال في غضون ساعة واحدة",
  "cms.report.title.callCenterAccessReport" : "تقرير حركة VDN بواسطة رمز الوصول",
  "cms.datasource.message.ccDsRelationExist" : "تمت الإشارة إلى مصدر البيانات في مركز الاتصالات.",
  "cms.report.field.alertingTimeField" : "وقت تنبيه المكالمة",
  "cms.report.field.onehour" : "1 ساعة",
  "cms.report.message.avgIVROutCountDesc" : "متوسط عدد المكالمات الصادرة المتزامنة التي يكون نوع جهاز خدمتها هو IVR.",
  "cms.indexmonitor.label.next" : "التالي",
  "cms.callcenterinstanceslist.field.cmsAuthName" : "حساب مصادقة CCDIS",
  "cms.agent.message.modifypasswordhintdetail" : "أدخل كلمة المرور.",
  "cms.agentMonitor.label.reset" : "إعادة الضبط",
  "cms.report.message.inCallSuccNumDesc" : "المكالمات الواردة المتصلة بالعامل.",
  "cms.report.field.minIVRCount" : "الحد الأدنى لمكالمات IVR المتزامنة",
  "cms.report.message.sysLostCallsDesc" : "عدد المكالمات الواردة الفاشلة للنظام = إجمالي عدد المكالمات الواردة للنظام – عدد المكالمات الواردة الناجحة للنظام.",
  "cms.agent.label.avgcallouttime" : "متوسط مدة المكالمات الصادرة",
  "cms.indexmonitor.message.skillOverAgentDesc" : "عدد المكالمات المحولة من قائمة انتظار المهارة الأصلية إلى موظفين آخرين بسبب تجاوز السعة",
  "cms.report.field.agentId" : "معرف الموظف",
  "cms.report.message.logoutCountDesc" : "عدد مرات تسجيل الخروج.",
  "cms.report.field.sysSuccRate" : "معدل نجاح المكالمة الواردة للنظام",
  "cms.reportsubscribe.field.enable" : "تم النشر",
  "cms.report.field.IVROutSuccRateField" : "معدل اتصال مكالمة صادرة نقية IVR",
  "cms.systemparam.error.validate.notmatchruleip" : "الرجاء إدخال عنوان IPV4 أو IPV6 صالح",
  "cms.agent.label.choosestatetime" : "حدد الحد الأدنى لمدة الحالة.",
  "cms.qualitycheck.label.groupname" : "اسم المجموعة",
  "cms.report.field.submediatype.wechat" : "WeChat",
  "cms.report.field.acwCount" : "أوقات الالتفاف",
  "cms.indexmonitor.field.agentInboundNum" : "مكالمات واردة يدوية",
  "cms.report.field.uvidField" : "معرف وصول المستخدم",
  "cms.indexmonitor.field.skillAbandon" : "طلبات المكالمات المفقودة",
  "cms.agent.label.callinrate" : "معدل إتمام المكالمة الواردة",
  "cms.report.field.mediatype.H5Vedio" : "H5 فيديو",
  "cms.indexmonitor.message.skillAbandonInNRateDesc" : "النسبة المئوية لعدد طلبات المكالمات التي يتم إهمالها يدويًا في Ns في إجمالي عدد المكالمات التي تطلب خدمات المستخدم",
  "cms.qualitycheck.title.saveError" : "خطأ",
  "cms.voiceinspection.field.download" : "تحميل",
  "cms.agentMonitor.label.queryNumSuccess" : "نجح تعيين رقم تسجيل الدخول",
  "cms.report.field.busyCount" : "الأوقات المزدحمة",
  "cms.monitordetail.field.holddurationavg" : "متوسط مدة الحيازة",
  "cms.indexmonitor.message.errorAgentInfo" : "يرجى تحديد موظف واحد على الأقل.",
  "cms.fullscreen.field.oneMin" : "1 دقيقة",
  "cms.report.field.agentOutBoundField" : "المكالمات الصادرة يدويًا",
  "cms.report.message.avgIVRCountDesc" : "متوسط عدد المكالمات المتزامنة التي يكون نوع جهاز خدمتها هو IVR.",
  "cms.report.field.byDay" : "التقرير اليومي",
  "cms.calllink.field.cdnDevice" : "جهاز CDN",
  "cms.systemmonitor.field.queryTime" : "وقت الاستعلام",
  "cms.report.message.userNoAnswerNumDesc" : "عدد المكالمات التي لم يتم الرد عليها بسبب انتهاء مهلة الرنين.",
  "cms.voiceinspection.message.downloadFailed" : "فشل تنزيل التسجيل.",
  "cms.agentMonitor.label.details" : "التفاصيل",
  "cms.report.message.acwTimeRateDesc" : "معدل مدة الالتفاف = (إجمالي مدة الالتفاف/إجمالي مدة العمل) × 100",
  "cms.report.field.acwCountField" : "أوقات الالتفاف",
  "cms.sendnote.message.checkInput" : "أحرف غير صالحة.",
  "cms.calllink.msg.calleePlaceHolder" : "أدخل الرقم المطلوب",
  "cms.report.message.dataOverError" : "حجم بيانات الاستعلام {0} أكثر من 10000.",
  "cms.systemmonitor.label.noncommercial" : "الاستخدام التجاري التجريبي",
  "cms.recorddrive.placeholder.deleteRecorddrive" : "الرجاء تحديد حرف محرك الأقراص المراد حذفه",
  "cms.report.field.calleeNo" : "رمز الوصول",
  "cms.uapmonitor.field.callOperator" : "العملية",
  "cms.indexmonitor.field.skillAvgConnected" : "متوسط مدة المكالمة",
  "cms.indexmonitor.field.totalConnectCallNum" : "إجمالي المكالمات المتصلة",
  "cms.datasource.field.dbName" : "اسم مصدر البيانات",
  "cms.qualitycheck.label.qualityInspectionGroup" : "مجموعة فحص الجودة",
  "cms.vcallecenter.title.tips" : "معلومات",
  "cms.report.message.muteCountDesc" : "عدد مرات الصمت.",
  "cms.callcenterinstanceslist.message.resourceError" : "معلومات الموارد المعدلة أصغر من العدد الفعلي لمراكز الاتصالات.",
  "cms.indexmonitor.field.SkillAbandonRate" : "طلبات المكالمات المفقودة",
  "cms.report.message.minAgentInCountDesc" : "الحد الأدنى لعدد المكالمات الواردة المتزامنة التي يكون نوع جهاز خدمتها هو الموظف.",
  "cms.voiceinspection.field.callbackRequest" : "طلب معاودة الاتصال",
  "cms.report.field.agentHangNumCti" : "وكيل Hang-Ups",
  "cms.qualitycheck.label.create" : "إنشاء",
  "cms.qcrelationconf.field.groupType" : "نوع مجموعة المستخدم",
  "cms.agentmonitor.label.all" : "الكل",
  "cms.indexmonitor.title.skillDayTrend" : "اتجاه اليوم من قائمة انتظار المهارات",
  "cms.report.field.startWeek" : "أسبوع البدء",
  "cms.indexmonitor.message.skillMaxQueueAckDesc" : "الحد الأقصى لمدة الانتظار (قائمة الانتظار ومدة الرنين) لجميع المكالمات",
  "cms.report.message.systemInBoundDesc" : "إجمالي عدد المكالمات الواردة إلى النظام (باستثناء المكالمات الواردة الداخلية).",
  "cms.report.title.dualCallDetail" : "تفاصيل المكالمة المزدوجة",
  "cms.report.field.ackEndField" : "وقت انتهاء الإجابة",
  "cms.report.message.agentLoginNumDesc" : "عدد الموظفين الذين يتلقون مكالمات واردة.",
  "cms.report.title.callCenterAccessTrend" : "اتجاه أداء VDN حسب رمز الوصول",
  "cms.fullscreen.message.saveLayouts" : "لم يتم حفظ التخطيط. حفظه أولا.",
  "cms.report.field.minIVROutCount" : "الحد الأدنى للمكالمات الصادرة المتزامنة IVR",
  "cms.developerlist.message.deletesuccess" : "تم الحذف بنجاح.",
  "cms.indexmonitor.message.agentConnectIn60Rate" : "النسبة المئوية لعدد المكالمات الموصولة يدوياً في غضون 60 ثانية (بما في ذلك 60 ثانية) إلى إجمالي عدد المكالمات.",
  "cms.vcallecenter.label.renewpwd" : "تأكيد كلمة المرور",
  "cms.report.message.leaveReasonDesc" : "سبب ترك الجهاز.",
  "cms.report.field.avgQueueInCountField" : "متوسط المكالمات الواردة في قائمة انتظار المهارة المتزامنة",
  "cms.failurecall.field.hangUpTime" : "إجمالي الوقت لإعادة توجيه المكالمات اليدوية إلى IVR",
  "cms.agentMonitor.label.queryNumFailed" : "فشل تعيين رقم تسجيل الدخول",
  "cms.qcrelationconf.message.specialChar" : "حرف غير صالح",
  "cms.voiceinspection.field.textChat" : "نص الدردشة",
  "cms.fullscreen.field.add" : "إنشاء",
  "cms.report.field.avgAnsTimeField" : "متوسط مدة الانتظار في قائمة الانتظار للمكالمات المتصلة",
  "cms.fullscreen.message.selectAgentMax" : "يمكن اختيار 5 وكلاء كحد أقصى.",
  "cms.uapmonitor.field.callStatus" : "حالة التسجيل",
  "cms.agent.label.arrangetime" : "ترتيب الوقت",
  "cms.vcallecenter.label.resetpwd" : "إعادة تعيين كلمة مرور المسؤول",
  "cms.voiceinspection.message.downloadSucceed" : "تم تنزيل التسجيل بنجاح.",
  "cms.callcenterinstanceslist.field.single" : "وضع واحد",
  "cms.report.message.alertingTimeDesc" : "وقت تنبيه المكالمة.",
  "cms.indexmonitor.field.skillAbandonIn20Rate" : "المكالمات التي تم إهمالها في العشرينات",
  "cms.report.task.export.records" : "تصدير سجلات جهات الاتصال",
  "cms.agentmonitor.label.release" : "قوة الافراج",
  "cms.report.field.submediatype.sms" : "SMS",
  "cms.callcenterinstanceslist.label.delete" : "حذف",
  "cms.qualitycheck.label.configQcInformation" : "معلومات التحقق من جودة التكوين",
  "cms.qcrelationconf.title.success" : "النجاح",
  "cms.agentmonitor.label.showAlarmAgentNo" : "معرف الموظف",
  "cms.agentmonitor.label.offline" : "غير متصل",
  "cms.fullscreen.field.reportType" : "نوع التقرير",
  "cms.report.message.alertingDurationDesc" : "تسمى مدة التنبيه.",
  "cms.voiceinspection.message.errorContent" : "فشل تحميل المورد. تحقق من التكوين.",
  "cms.report.field.confirm" : "حسناً",
  "cms.callcenterinstanceslist.message.ccIdError" : "تم استخدام رقم مركز الاتصال.",
  "cms.report.message.IVRCallOutSuccRateDesc" : "النسبة المئوية لمكالمات IVR الصادرة المتصلة إلى إجمالي طلبات مكالمات IVR الصادرة.",
  "cms.report.field.waitCauseField" : "سبب الانتظار",
  "cms.report.message.failQueueWaitRateDesc" : "متوسط مدة الرنين لجميع المكالمات التي لم يتم الرد عليها.",
  "cms.indexmonitor.title.error" : "خطأ",
  "cms.indexmonitor.message.agentAbandonOut60NDesc" : "إجمالي عدد المكالمات المهملة التي تزيد مدة الإجابة عليها عن 60 ثانية.",
  "cms.sysparam.config.search" : "البحث",
  "cms.agentMonitor.laber.hasbeenCheck" : "تم فحص الموظف.",
  "cms.agentMonitor.laber.hasbeenMediaCheck": "تمت مراقبة هذا الموظف بالفعل من خلال جلسة الوسائط المتعددة",
  "cms.agentMonitor.laber.stopMediaMonitor": "تم إيقاف مراقبة جلسة الوسائط المتعددة",
  "cms.datasource.message.already" : "اسم مصدر البيانات موجود بالفعل.",
  "cms.report.field.utilizationRateField" : "معدل مدة حالة العمل",
  "cms.agentmonitor.label.showAlarmDetail" : "عرض تفاصيل التنبيه",
  "cms.callcenterinstanceslist.field.uapPasswordConfirm" : "تأكيد كلمة مرور UAP CDE",
  "cms.report.field.calleeNoField" : "الرقم المطلوب",
  "cms.agentMonitor.label.talk_error" : "الموظف المحدد ليس في حالة التحدث.",
  "cms.calllink.field.agentRelease" : "يقوم الموظف بقطع اتصال مكالمة.",
  "cms.callcenterinstanceslist.field.normalStatus" : "عادي",
  "cms.agentmonitor.label.alarmState" : "إنذار الشاشة",
  "cms.indexmonitor.message.totalAgentCallNumDesc" : "إجمالي عدد المكالمات المتصلة (الفترة الإحصائية: تسجيل الدخول الحالي)",
  "cms.report.message.acwRateDesc" : "معدل مدة الالتفاف = (إجمالي مدة الالتفاف/إجمالي مدة العمل) × 100",
  "cms.developer.message.addDeveloper" : "إضافة معلومات المطور",
  "cms.report.message.internalCallTimeDesc" : "مدة المكالمة الداخلية.",
  "cms.synchronize.message.notConfigured" : "لم يتم تكوينه",
  "cms.indexmonitor.field.agentConnectInSixty" : "المكالمات متصلة يدويا في 60s",
  "cms.agentmonitor.label.monitorState" : "حالة الفحص",
  "cms.callcenterinstanceslist.field.close" : "تعطيل",
  "cms.report.message.onlyIVRServiceDesc" : "عدد المكالمات الواردة الناجحة التي تمت معالجتها بواسطة IVR فقط، أي عدد المكالمات الواردة الناجحة التي لم تتم معالجتها بواسطة أي عامل.",
  "cms.callcenterinstanceslist.title.success" : "النجاح",
  "cms.callcenterinstanceslist.message.uapNode2Info" : "عقدة UAP 2",
  "cms.voiceinspection.field.endTime" : "وقت انتهاء التسجيل",
  "cms.report.field.assistsCountField" : "طلبات المساعدة الداخلية",
  "cms.report.message.outOccupyNumDesc" : "عدد المكالمات التي أجراها موظف للعملاء.",
  "cms.export.message.addExportTaskSuccess" : "نجحت مهمة التصدير الجديدة",
  "cms.report.field.inVdnAvgTime" : "متوسط مدة المكالمة الواردة في VDN",
  "cms.export.message.passValidateFailed" : "القيمة عبارة عن سلسلة من 8 إلى 12 حرفًا ويجب أن تحتوي على أحرف وأرقام وأحرف خاصة (باستثناء مسافات |;&$-<>/\\`!#(){}, وأحرف السطر الجديد وأحرف الجدولة).",
  "cms.vdnconfiguration.field.defaultCallCenter" : "مركز الاتصال الافتراضي",
  "cms.report.field.subMediaTypeField" : "نوع الوسائط الفرعية",
  "cms.indexmonitor.message.agentAbandonIn30Rate" : "النسبة المئوية للمكالمات المهملة خلال 30 ثانية إلى إجمالي المكالمات المهملة",
  "cms.agentMonitor.label.insets_error" : "فشل الإدراج.",
  "cms.uapmonitor.title.regist" : "حالة تسجيل المحطة الطرفية",
  "cms.indexmonitor.field.ivrDurationAvgConnect" : "متوسط مدة مكالمة IVR",
  "cms.indexmonitor.title.agentDayTrend" : "اتجاه الوكيل اليوم",
  "cms.datasource.field.passwordConfirm" : "تأكيد كلمة المرور",
  "cms.qcrelationconf.placeholder.accountCode" : "أدخل حساب نشاط تجاري.",
  "cms.report.field.connected" : "متصل",
  "cms.agentmonitor.label.listenedAndInserted" : "تم فحصه",
  "cms.export.field.addExportTask" : "مهمة تصدير جديدة",
  "cms.report.message.callTypeDesc" : "نوع المكالمة.",
  "cms.indexmonitor.field.totalAwcNum" : "الوكلاء في حالة التفاف",
  "cms.calllink.field.noOnDutyTransfer" : "يتم تحويل المكالمة لأنه لا يوجد أحد في الخدمة.",
  "cms.report.field.ringTimeField" : "إجمالي مدة الرنين",
  "cms.indexmonitor.field.agentConnectAfterInTen" : "يتم توصيل المكالمات يدويًا بعد 10 ثوانٍ",
  "cms.reportsubscribe.field.status" : "الحالة",
  "cms.vdnconfiguration.field.cancel" : "إلغاء",
  "cms.indexmonitor.field.agentConnectInThree" : "المكالمات متصلة يدويًا في 3 ثوانٍ",
  "cms.report.field.agentOutSuccRateField" : "معدل اتصال المكالمات الصادرة يدويًا",
  "cms.uapmonitor.labe.openmonitor" : "الرصد",
  "cms.sendnote.field.cancel" : "إلغاء",
  "cms.report.message.outCallTimeDesc" : "إجمالي مدة المكالمة الصادرة بين الوكيل والعملاء.",
  "cms.callcenterinstanceslist.message.cleanKafka" : "مسح تكوين عقدة KAFKA",
  "cms.report.message.internalTransferCountDesc" : "عدد مرات التحويل الداخلي.",
  "cms.report.message.succAnsRateDesc" : "النسبة المئوية لعدد طلبات المكالمات الواردة اليدوية المتصلة في عدد المكالمات الواردة اليدوية التي تطلب الخدمات.",
  "cms.export.message.nocount" : "لم يتم العثور على بيانات تستوفي الشروط.",
  "cms.incoming.record.field.releasecause.input" : "الرجاء إدخال سبب تحرير المكالمة",
  "cms.indexmonitor.message.totalLoginNumDesc" : "عدد الموظفين الذين قاموا بتسجيل الدخول إلى مركز الاتصالات ومعالجة المكالمات الواردة فقط",
  "cms.fullscreen.message.addVdnMost" : "يمكن اختيار 10 مستأجرين كحد أقصى.",
  "cms.vcallcenter.message.unknowcause" : "سبب غير معروف",
  "cms.systemmonitor.field.ccName" : "اسم مركز الاتصال",
  "cms.callcenterinstanceslist.field.cmsPort" : "ميناء CMS LB",
  "cms.agentmonitor.label.queueAdjustment" : "ضبط قوائم الانتظار",
  "cms.record-playback.callType3" : "مكالمة OBS الصادرة",
  "cms.record-playback.callType2" : "مكالمة صادرة IVR",
  "cms.report.field.unspecifiedUserLevel" : "غير محدد",
  "cms.qualitycheck.title.delete" : "فشل الحذف",
  "cms.qualitycheck.label.userGroupMemberConfig" : "تكوين أعضاء مجموعة المستخدمين",
  "cms.record-playback.callType1" : "مكالمة صوتية صادرة",
  "cms.agent.label.busyitem" : "مشغول",
  "cms.record-playback.callType0" : "مكالمة صوتية واردة",
  "cms.report.message.avgQueueInCountDesc" : "متوسط عدد المكالمات الواردة المتزامنة التي يكون نوع جهاز خدمتها هو قائمة انتظار المهارات.",
  "cms.voiceinspection.field.nonRealTimeCall" : "مكالمة في الوقت غير الحقيقي",
  "cms.monitordetail.field.sipNumber" : "تسجيل الدخول مكالمة",
  "cms.report.field.mediatype.vedio" : "فيديو",
  "cms.calllink.title.mediaType" : "نوع الوسائط",
  "cms.qualitycheck.label.queryQcRelation" : "الاستعلام عن علاقات الفحص",
  "cms.indexmonitor.message.queueInCallCount" : "عدد المكالمات الواردة في قائمة انتظار في قوائم انتظار المهارات.(لا يتضمن جلسات عمل وسائط متعددة)",
  "cms.report.field.avgInRingTimeField" : "متوسط مدة المكالمة الواردة للمحطات الطرفية",
  "cms.fullscreen.field.reportTime" : "فترة التحصيل",
  "cms.report.field.maxQueueInCountField" : "الحد الأقصى لقائمة انتظار المهارة المتزامنة للمكالمات الواردة",
  "cms.indexmonitor.message.outboundAbandonRate" : "النسبة المئوية لعدد المكالمات الصادرة المهملة في عدد المكالمات الصادرة.",
  "cms.fullscreen.field.color" : "اللون",
  "cms.report.field.avgIVRInCount" : "متوسط المكالمات الواردة المتزامنة IVR",
  "cms.agentMonitor.label.queryNum" : "تعيين رقم تسجيل الدخول",
  "cms.agentmonitor.label.possessingSkills" : "قائمة انتظار تسجيل الدخول",
  "cms.calllink.field.callRejected" : "يرفض الطرف المطلوب مكالمة.",
  "cms.report.title.vdnSystemPerformanceTrend" : "اتجاه أداء النظام",
  "cms.report.message.maxIVRCountDesc" : "الحد الأقصى لعدد المكالمات المتزامنة التي يكون نوع جهاز خدمتها هو IVR.",
  "cms.datasource.field.cmsDatabase" : "قاعدة بيانات تقارير CMS",
  "cms.indexmonitor.field.abandonInSixty" : "تم التخلي عن المكالمات في الستينيات",
  "cms.report.field.createTimeField" : "إنشاء الوقت",
  "cms.callcenterinstanceslist.label.assignDeveloper" : "مطور مرتبط",
  "cms.vdnconfiguration.label.configure" : "تعيين",
  "cms.qualitycheck.label.unselectedGroup" : "مجموعات مستخدمين غير محددة",
  "cms.report.title.filteringIndicator" : "مؤشر الفلتر",
  "cms.callcenterinstanceslist.field.kafkaPushSwitch" : "KAFKA دفع البيانات",
  "cms.datasource.label.edit" : "تحرير",
  "cms.skilladjustment.title.tips" : "معلومات",
  "cms.datasource.field.isBackup" : "قاعدة بيانات النسخ الاحتياطي",
  "cms.report.message.calleeNoDesc" : "الرقم المتصل.",
  "cms.agentMonitor.label.batchSelectAgent" : "يمكنك اختيار موظف واحد فقط.",
  "cms.report.message.avgCallInTimeDesc" : "متوسط مدة المكالمة. متوسط مدة المكالمات الواردة اليدوية المتصلة = مدة المكالمات الواردة اليدوية المتصلة/عدد المكالمات الواردة اليدوية المتصلة.",
  "cms.indexmonitor.message.agentAbandonIn5NDesc" : "إجمالي عدد مرات الإهمال التي تكون مدة الإجابة فيها أطول من أو تساوي 5 ثوانٍ",
  "cms.report.field.ans20s" : "معدل الاتصال اليدوي للمكالمة في 20 ثانية",
  "cms.report.field.agentHangNumCtiField" : "عمليات تعليق الموظف",
  "cms.uapmonitor.field.unregister" : "عدم التسجيل",
  "cms.report.field.startTimeField" : "وقت بدء المكالمة",
  "cms.fullscreen.message.digitsCheck" : "يجب أن تكون القيمة عددًا صحيحًا موجبًا",
  "cms.report.message.loginCountDesc" : "عدد مرات تسجيل الدخول.",
  "cms.report.title.ivrOutboundReport" : "تقرير مكالمة صادرة IVR",
  "cms.callcenterinstanceslist.field.ccStatus" : "حالة مركز الاتصال",
  "cms.voiceinspection.message.errorTime" : "لا يمكن لاستعلام التاريخ أن يتقاطع مع الأشهر.",
  "cms.report.field.internalCallCount" : "المكالمات الداخلية",
  "cms.indexmonitor.field.abandonInThree" : "المكالمات التي تم إهمالها في 3 ثواني",
  "cms.agentmonitor.label.cancelInsert" : "إلغاء الإدراج",
  "cms.callcenterinstanceslist.message.workNoError" : "يجب أن يكون معرف موظف البدء في النطاق من 101 إلى 50000.",
  "cms.report.field.inCallNum" : "المكالمات الواردة",
  "cms.export.field.exportEndTime" : "وقت انتهاء التصدير",
  "cms.report.message.acwInTimeDesc" : "مدة الالتفاف بعد انتهاء المكالمات الواردة.",
  "cms.vcallecenter.field.confirmnewpassword" : "تأكيد كلمة المرور",
  "cms.report.field.inCallRateField" : "متوسط مدة المكالمة",
  "cms.datasource.message.passValidateFailed" : "لا يمكن أن تحتوي القيمة على \"eval\" و\"exec\" والأحرف الخاصة التالية: <=>/\"",
  "cms.report.message.auxTimeDesc" : "إجمالي مدة الإجازة للموظف أو المدة عندما يكون الموظف في حالة الراحة.",
  "cms.requiredcallback.message.allowTimeRange" : "يدعم الاستعلام عن البيانات في غضون سبعة أيام كحد أقصى.",
  "cms.report.message.agentOutBoundDesc" : "عدد المكالمات اليدوية الصادرة التي أجراها الوكلاء.",
  "cms.report.field.muteCount" : "أوقات الصمت",
  "cms.sysparam.config.select.count" : "يمكن اختيار 10 معلمات كحد أقصى.",
  "cms.incoming.record.field.notReqManual" : "العمل غير مطلوب",
  "cms.callcenterinstanceslist.message.needKafkaSSL" : "افتح SSL",
  "cms.incoming.record.field.notAnswerCall" : "لا يرد الموظف على المكالمة",
  "cms.callcenterinstanceslist.label.synchronize" : "مزامنة",
  "cms.report.field.agentTalkingTimeField" : "وقت التحدث المتصل",
  "cms.indexmonitor.message.skillAvgConnectedQueueDesc" : "متوسط مدة قائمة الانتظار لجميع المكالمات المتصلة في قائمة الانتظار",
  "cms.report.message.internalCallCountDesc" : "عدد المكالمات الداخلية.",
  "cms.incoming.record.field.releasecause.id" : "الرجاء إدخال رمز سبب تحرير المكالمة",
  "cms.report.field.outAvgRingTime" : "متوسط مدة حلقة المكالمات اليدوية الصادرة",
  "cms.report.message.assistsCountDesc" : "عدد طلبات المساعدة الداخلية.",
  "cms.callcenterinstanceslist.message.clearUapConfig" : "هل تريد بالتأكيد حذف تكوين عقدة UAP؟",
  "cms.report.field.acwTimeRate" : "معدل مدة الالتفاف",
  "cms.fullscreen.field.acwCountTimeDesc" : "عدد المرات التي يكون فيها الموظفون في حالة التفاف.",
  "cms.report.message.IVRCallOutNumDesc" : "إجمالي عدد مكالمات IVR الصادرة.",
  "cms.report.field.agentInBoundField" : "مكالمات واردة يدوية",
  "cms.report.field.call6RateField" : "معدل اتصال المكالمات الواردة يدويًا في 30 ثانية",
  "cms.report.message.userBusyFailNumDesc" : "عدد المكالمات التي لم يتم الرد عليها بسبب انشغال الخطوط.",
  "cms.indexmonitor.message.totalQueueCallNum" : "عدد المكالمات التي يتم وضعها في قائمة انتظار في قوائم انتظار المهارات وانتظار خدمات الموظف",
  "cms.report.field.inCallSuccNumField" : "عدد المكالمات اليدوية المتصلة",
  "cms.callcenterinstanceslist.field.uapIndexMonitor" : "مراقبة مؤشر نظام UAP",
  "cms.callcenterinstanceslist.label.refresh" : "تحديث",
  "cms.report.field.avgQueueCount" : "متوسط مكالمات قائمة انتظار المهارة المتزامنة",
  "cms.report.message.talkDurationDesc" : "مدة التحدث.",
  "cms.callcenter.message.selectcallcent" : "حدد مركز اتصالات.",
  "cms.report.field.avgAcdTime" : "متوسط مدة المكالمة اليدوية",
  "cms.synchronize.message.noConfAdminPwd" : "لم يتم تكوين كلمة المرور. قم بتكوين كلمة مرور المسؤول.",
  "cms.agentMonitor.label.forcebusy_stateerror" : "الوكيل يأخذ استراحة. لا تظهر مشغولا.",
  "cms.monitordetail.field.adjustDuration" : "ترتيب المدة",
  "cms.indexmonitor.message.skillOnlineInbondCallDesc" : "عدد المكالمات التي تتم معالجتها في قائمة الانتظار عدد المكالمات التي يتم وضعها في قائمة الانتظار",
  "cms.synchronize.message.incorrectPassword" : "غير صحيح",
  "cms.report.message.minAgentCountDesc" : "الحد الأدنى لعدد المكالمات المتزامنة التي يكون نوع جهاز خدمتها هو موظف.",
  "cms.report.field.acdTimeRate" : "معدل مدة المكالمة اليدوية",
  "cms.agentmonitor.label.whisper" : "الهمس",
  "cms.systemmonitor.field.busyNums" : "وكلاء مشغول",
  "cms.recorddrive.field.sftpIpadress" : "عنوان IP لـ SFTP",
  "cms.recorddrive.label.IPAddress_add" : "عنوان IP",
  "cms.report.message.minIVRInCountDesc" : "الحد الأدنى لعدد المكالمات الواردة المتزامنة التي يكون نوع جهاز خدمتها هو IVR.",
  "cms.report.message.accessCodeDesc" : "رمز الوصول.",
  "cms.indexmonitor.field.callNum" : "عدد المكالمات",
  "cms.indexmonitor.field.pageDataUpdateTime" : "البيانات المحدثة:",
  "cms.report.message.callInCauseDesc" : "سبب دخول مكالمة إلى مركز الاتصال (للمكالمات الصوتية).",
  "cms.developerlist.field.createTime" : "إنشاء في",
  "cms.indexmonitor.field.skillOnlineInbondCall" : "عدد المكالمات عبر الإنترنت",
  "cms.report.message.inOccupyNumDesc" : "عدد المكالمات الواردة التي تطلب خدمات يدوية.",
  "cms.incoming.record.report.endTime" : "وقت انتهاء الاستعلام",
  "cms.qcrelationconf.message.selectGroupType" : "يُرجى تحديد نوع مجموعة المستخدمين أولاً",
  "cms.incoming.record.field.isReqManual" : "العمالة المطلوبة",
  "cms.report.field.availTimeRateField" : "معدل الخمول",
  "cms.indexmonitor.label.callInIVR" : "عدد المكالمات الواردة عبر الإنترنت IVR",
  "cms.recorddrive.label.recorddrivename" : "اسم حرف محرك الأقراص",
  "cms.fullscreen.field.editRichText" : "تعديل النص المنسق",
  "cms.callcenterinstanceslist.field.disPwdConfirm" : "تأكيد كلمة مرور CCDIS",
  "cms.callcenterinstanceslist.message.cleanUap" : "مسح تكوين عقدة UAP",
  "cms.report.field.avgPerHNumField" : "متوسط عدد المكالمات الناجحة في الساعة",
  "cms.calllind.field.deviceAgent" : "الوكيل",
  "cms.agentmonitor.label.listening" : "الاستماع",
  "cms.report.field.playVoiceTimeField" : "مدة تشغيل الصوت",
  "cms.report.field.mediaTypeField" : "نوع الوسائط",
  "cms.report.field.avgHoldTime" : "متوسط مدة الحجز",
  "cms.agentMonitor.label.cancelListening_success" : "تم إلغاء الاستماع بنجاح.",
  "cms.callcenterinstanceslist.field.adminCount" : "معرف مسؤول CTI",
  "cms.report.message.exportFailed" : "فشل التصدير.",
  "cms.voiceinspection.field.batchDownload" : "دفعة تحميل",
  "cms.voiceinspection.field.callerNo" : "رقم المتصل",
  "cms.indexmonitor.message.agentConnectIn10Rate" : "النسبة المئوية لعدد المكالمات الموصولة يدوياً في غضون 10 ثوانٍ (بما في ذلك 10 ثوانٍ) إلى إجمالي عدد المكالمات.",
  "cms.report.field.maxAgentCountField" : "الحد الأقصى لمكالمات الموظف المتزامنة",
  "cms.agentMonitor.message.operaFailedAgent" : "فشلت العملية. جاري فحص الموظف.",
  "cms.report.field.agentOutSuccRate" : "معدل الاتصال اليدوي للمكالمة الصادرة",
  "cms.indexmonitor.message.errorOrgInfo" : "يرجى تحديد منظمة أولاً.",
  "cms.report.field.acdCall" : "إجمالي المكالمات المتصلة",
  "cms.report.message.outAvgCallTimeDesc" : "متوسط مدة المكالمة الصادرة بين الوكيل والعملاء.",
  "cms.report.field.oneClickDualCall" : "مكالمة ثنائية الاتجاه بنقرة واحدة",
  "cms.report.field.IVRInSucc" : "مكالمات IVR الواردة المتصلة",
  "cms.report.field.agentOutBound" : "المكالمات الصادرة يدويًا",
  "cms.voiceinspection.field.messageCall" : "رسالة مكالمة",
  "cms.indexmonitor.field.outboundAbandonRate" : "المكالمات الصادرة المهملة",
  "cms.callcenterinstanceslist.message.uapPsdLengthRangeValidateFailed" : "يجب أن يتراوح طول كلمة المرور بين 6 و32",
  "cms.developerlist.field.operate" : "العملية",
  "cms.qcrelationconf.field.qcWorkNo" : "معرف المراقب",
  "cms.sysparam.config.checkmessage" : "يحتوي على أحرف خاصة. (\"<\", \">\", \"/\", وما إلى ذلك)",
  "cms.agentmonitor.label.statusDuration" : "مدة حالة الموظف",
  "cms.vcallecenter.title.failed" : "خطأ",
  "cms.indexmonitor.field.accesscodeCalloutNum" : "عدد المكالمات الصادرة",
  "cms.indexmonitor.field.outboundConnectNumDesc" : "<bpt i=\"1\" type=\"1\" />cms.report.field.out<ept i=\"1\" /><bpt i=\"2\" type=\"2\" />=<ept i=\"2\" /><bpt i=\"3\" type=\"3\" />عدد المرات التي يتصل فيها العامل بالمكالمات الصادرة.<ept i=\"3\" />",
  "cms.indexmonitor.field.all" : "الكل",
  "cms.agentmonitor.label.sendNotes" : "إرسال الملاحظات",
  "cms.report.message.avgAgentCountDesc" : "متوسط عدد المكالمات المتزامنة التي يكون نوع جهاز خدمتها هو موظف.",
  "cms.report.message.avgAgentInCountDesc" : "متوسط عدد المكالمات الواردة المتزامنة التي يكون نوع جهاز خدمتها هو موظف.",
  "cms.qcrelationconf.placeholder.workNo" : "يُرجى إدخال معرف الموظف",
  "cms.report.field.onlyIVRService" : "مكالمات Pure-IVR الواردة الناجحة",
  "cms.indexmonitor.message.ivrInboundNum" : "عدد طلبات مكالمات IVR الواردة التي تزيد مدة الاتصال بها عن 0",
  "cms.calllink.field.noResources" : "لا يتوفر أي مورد على ACD.",
  "cms.report.message.avgIVRInCountDesc" : "متوسط عدد المكالمات الواردة المتزامنة التي يكون نوع جهاز خدمتها هو IVR.",
  "cms.report.message.avgAnsTimeDesc" : "متوسط مدة قائمة الانتظار لجميع المكالمات المتصلة في قائمة انتظار.",
  "cms.report.field.minQueueOutCount" : "أدنى مكالمات صادرة في قائمة انتظار المهارات المتزامنة",
  "cms.developerlist.label.delete" : "حذف",
  "cms.fullscreen.field.preview" : "معاينة",
  "cms.indexmonitor.message.totalAbandonedCallNum" : "إجمالي عدد المكالمات - إجمالي عدد المكالمات المتصلة",
  "cms.incoming.releasecause.causecode.tip" : "رمز تعيين السبب خارج النطاق (0 إلى 45)",
  "cms.systemparam.error.validate.notmatchrulerangelength" : "تتجاوز معلمة الإدخال الحد الأقصى للطول أو أقل من الحد الأدنى للطول",
  "cms.systemparam.error.notmatchrule" : "المعلمة لا تطابق قاعدة التحقق",
  "cms.report.field.callStartTimeField" : "وقت بدء المكالمة",
  "cms.report.title.agentOutCallMonitor" : "مراقبة ملخص مكالمة الموظف الصادرة",
  "cms.report.field.custHangNumCtiField" : "تعليق العملاء",
  "cms.indexmonitor.index.SkillQueueTips" : "يتم قياس مستوى خدمة قائمة انتظار المهارات استنادًا إلى معدل إكمال المكالمة في غضون 20 ثانية. مستوى الخدمة المتوقع الافتراضي هو 80%. يمكن لمسؤول المستأجر تعديل مستوى الخدمة المتوقع عن طريق اختيار معلمات المستأجر > مراقبة مركز الاتصال > مراقبة المؤشر > مستوى خدمة قائمة انتظار المهارة المتوقعة في صفحة تكوين معلمة المستأجر.",
  "cms.report.field.thirtyminutes" : "30 دقيقة",
  "cms.datasource.message.updatesuccess" : "تم التحديث بنجاح.",
  "cms.report.field.workTimeField" : "إجمالي مدة العمل",
  "cms.fullscreen.message.chartDataError" : "فشل تحميل التخطيط.",
  "cms.indexmonitor.field.durationAvgVdnOutbound" : "متوسط مدة المكالمة الصادرة",
  "cms.export.field.exportTaskStatus" : "الحالة",
  "cms.report.field.callBeginField" : "وقت بدء المكالمة",
  "cms.report.field.busyTimeRateField" : "معدل مدة الانشغال",
  "cms.report.message.inCallRateDesc" : "متوسط مدة المكالمة. متوسط مدة المكالمات الواردة اليدوية المتصلة = مدة المكالمات الواردة اليدوية المتصلة/عدد المكالمات الواردة اليدوية المتصلة.",
  "cms.skilladjustment.field.selectskill" : "تحديد قائمة انتظار",
  "cms.vcallecenter.field.confirmwaspassword" : "تأكيد كلمة المرور",
  "cms.indexmonitor.message.abandonInFive" : "النسبة المئوية لعدد المكالمات المهملة في غضون 5 ثوانٍ من إجمالي عدد المكالمات المهملة.",
  "cms.export.field.failed" : "فشل",
  "cms.report.message.inCallSuccRateDesc" : "النسبة المئوية للمكالمات الواردة المتصلة إلى إجمالي المكالمات الواردة.",
  "cms.vcallecenter.message.syncFailed" : "فشل المزامنة",
  "cms.fullscreen.field.fifteenMin" : "15 دقيقة",
  "cms.report.message.subMediaTypeDesc" : "نوع الوسائط الفرعية.",
  "cms.systemparam.error.validate.notmatchrulenumber" : "الرجاء إدخال رقم",
  "cms.systemmonitor.message.addVdnMost" : "يمكن اختيار 100 مستأجر كحد أقصى.",
  "cms.systemparam.error.validate.notmatchruleoption" : "معلمة الإدخال ليست في نطاق التعداد",
  "cms.qcrelationconf.field.deleteGroupMember" : "حذف عضو مجموعة",
  "cms.report.field.avgHandleTimeField" : "متوسط مدة المعالجة",
  "cms.report.field.minQueueInCountField" : "أقل مكالمات واردة في قائمة انتظار المهارات المتزامنة",
  "cms.report.title.agentOutCallTrend" : "اتجاه ملخص مكالمة الموظف الصادرة",
  "cms.agent.label.avgwaittime" : "متوسط مدة انتظار المكالمات الواردة",
  "cms.report.field.outAvgAnsTime" : "متوسط مدة رنين المكالمات الصادرة",
  "cms.calllind.field.deviceRoute" : "جهاز توجيه مؤقت",
  "cms.report.message.awarnessRateDesc" : "النسبة المئوية لعدد المكالمات التي أجاب عليها الموظف في عدد المكالمات الواردة اليدوية.",
  "cms.agent.title.modifypassword" : "تغيير كلمة مرور تسجيل الدخول",
  "cms.report.message.avgAcwTimeDesc" : "متوسط المدة عندما يكون العامل في حالة التفاف. متوسط مدة الالتفاف = إجمالي مدة الالتفاف / عدد مرات الالتفاف",
  "cms.fullscreen.message.addChart" : "إضافة مخطط.",
  "cms.report.message.inCallSuccNumberDesc" : "عدد المكالمات الواردة المتصلة. القيمة هي مجموع المكالمات الواردة اليدوية المتصلة والمكالمات الواردة المتصلة تلقائيًا باستخدام IVR.",
  "cms.voiceinspection.message.playsuccess" : "تم تشغيل الإعلان بنجاح.",
  "cms.agentMonitor.label.warning" : "تحذير",
  "cms.failurecall.field.queOverToIvrNum" : "قائمة الانتظار إلى IVR",
  "cms.indexmonitor.message.agentOutboundConnectNum" : "عدد المكالمات اليدوية الصادرة التي يتم الرد عليها بواسطة الأطراف المطلوبة.",
  "cms.agentmonitor.label.showAlarmSensitive" : "كلمة حساسة",
  "cms.report.message.manToSkillNumDesc" : "عدد عمليات النقل اليدوية إلى قائمة انتظار المهارات.",
  "cms.calllink.field.commonAccessTransfer" : "الوصول المشترك أو النقل.",
  "cms.export.title.voiceDownload" : "تسجيل التفتيش",
  "cms.calllink.field.overflowing" : "يتم تحويل مكالمة بسبب تجاوز التدفق.",
  "cms.report.message.avgAcdTimeDesc" : "متوسط مدة مكالمة الموظف. متوسط مدة المكالمة اليدوية = مدة المكالمة اليدوية / عدد المكالمات اليدوية الناجحة",
  "cms.datasource.message.testsuccess" : "تم اختبار الاتصال بنجاح.",
  "cms.indexmonitor.field.skillUserAbandon" : "المكالمات المهجورة",
  "cms.report.message.userLevelDesc" : "مستوى العميل من المشترك في مركز الاتصال.",
  "cms.report.message.acwOutCountDesc" : "عدد المرات التي يدخل فيها العامل حالة التفاف بعد انتهاء المكالمات الصادرة.",
  "cms.indexmonitor.index.missed" : "مفقود",
  "cms.fullscreen.field.chartType" : "نوع المخطط",
  "cms.fullscreen.message.deleteconfim" : "هل تريد تأكيد حذف هذا الموضوع؟",
  "cms.fullscreen.field.addCharts" : "إنشاء مخطط",
  "cms.agent.label.callintime" : "مدة المكالمة الواردة",
  "cms.monitordetail.field.count" : "إحصائيات عن عدد المرات",
  "cms.report.field.IVROutCallNumField" : "المكالمات الصادرة بـIVR نقية",
  "cms.fullscreen.field.skillQueue" : "قائمة انتظار المهارات",
  "cms.calllink.title.leaveReason" : "سبب ترك الجهاز",
  "cms.callcenterinstanceslist.field.kafkaIp" : "عنوان IP كافكا",
  "cms.indexmonitor.message.inboundCallNum" : "عدد المكالمات الواردة يدوياً رقم المكالمات الواردة إلى بروتوكول الإنترنت IVR رقم المكالمات الواردة إلى بروتوكول الإنترنت IVR",
  "cms.report.field.submediatype.whatsapp" : "WhatsApp",
  "cms.calllink.field.PGR" : "PGR (مسار CDN الافتراضي الذي تسيطر عليه SCAI)",
  "cms.report.field.voiceNotification" : "إشعار صوتي",
  "cms.report.field.querySkill" : "قائمة انتظار المهارات",
  "cms.agentmonitor.message.header" : "فشل الحفظ. حدد رأس جدول واحد على الأقل.",
  "cms.indexmonitor.field.totalLearningNum" : "الوكلاء في حالة التعلم",
  "cms.report.field.agentInSuccRate" : "معدل الاتصال اليدوي للمكالمة الواردة",
  "cms.uapmonitor.labe.uapoverload" : "يتجاوز عدد الأرقام التي تم الاستعلام عنها بواسطة مقطع الرقم الطرفي الحد الأعلى:",
  "cms.callcenterinstanceslist.message.adminWorkNoError" : "يجب أن يتراوح رقم بداية معرف موظف المسؤول من 55001 إلى 59999.",
  "cms.report.field.outAboundRingField" : "المكالمات الصادرة التي لم يتم الرد عليها",
  "cms.monitordetail.field.skill" : "قائمة انتظار المهارات",
  "cms.report.field.holdTimeField" : "مدة الانتظار",
  "cms.report.field.manTransferOutNumCtiField" : "عدد عمليات النقل اليدوي إلى IVR وإعادة النقل",
  "cms.agentMonitor.label.listen_error" : "يفشل الاستماع.",
  "cms.calllink.field.unhold" : "مكالمة غير محجوبة.",
  "cms.failurecall.field.accessCode" : "رمز الوصول",
  "cms.agentmonitor.label.learnState" : "التعلم",
  "cms.report.message.preDeviceNoDesc" : "يشير إلى معرف الجهاز الخاص بآخر جهاز تمر المكالمة من خلاله.",
  "cms.indexmonitor.message.durationAvgVdnInbound" : "إجمالي المدة التي تشغلها المكالمات الواردة VDN/إجمالي عدد المكالمات الواردة",
  "cms.indexmonitor.message.totalAgentNum" : "إجمالي عدد الوكلاء الحاليين",
  "cms.datasource.field.cancel" : "إلغاء",
  "cms.report.message.userRefusedFailNumDesc" : "عدد المكالمات التي لم يتم الرد عليها بسبب رفض المكالمة.",
  "cms.report.field.outFailNum" : "إجمالي المكالمات الصادرة الفاشلة",
  "cms.fullscreen.field.fiveMin" : "5 دقائق",
  "cms.fullscreen.title.editTheme" : "تعديل الموضوع",
  "cms.fullscreen.style.dark": "لون داكن",
  "cms.fullscreen.style.light": "لون فاتح",
  "cms.fullscreen.style.title": "نمط",
  "cms.report.title.callNo" : "رقم الاتصال",
  "cms.calllink.field.teleconference" : "مكالمة جماعية",
  "cms.agent.tips.selectstatetime" : "حدد مدة الحالة. تحتاج إلى تحديد الحالة الحالية",
  "cms.voiceinspection.field.calleeNo" : "الرقم المطلوب",
  "cms.report.field.IVRShortCallsField" : "رقم المكالمات القصيرة IVR (خلال 3 ثواني)",
  "cms.report.field.internalCallTime" : "مدة المكالمة الداخلية",
  "cms.uapmonitor.labe.eidmention" : "يمكنك إدخال 10 مقاطع أرقام كحد أقصى، مفصولة بفواصل (,). قاعدة لكل مقطع رقمي: * و % لا يطابق أي حرف أو أحرف متعددة. على سبيل المثال، يتطابق 6666* و6666% مع جميع سلاسل الأحرف التي تبدأ بـ 6666. علامة الاستفهام (?) تطابق حرفًا واحدًا فقط. على سبيل المثال، 6666؟ تطابق فقط السلسلة المكونة من خمسة أحرف التي تبدأ بـ 6666. تركيبة أحرف البدل (*?%) مدعومة. على سبيل المثال، %3؟ يطابق جميع سلاسل الأحرف التي يكون الحرف الأخير منها هو 3. ملاحظة: يوصى بمطابقة 100 نتيجة بحد أقصى لكل حرف بدل. خلاف ذلك ، تتأثر الشركات على UAP.",
  "cms.report.field.inAvgRingTimeField" : "متوسط مدة الرنين للمكالمات الواردة اليدوية",
  "cms.datasource.field.remark" : "وصف الاستخدام",
  "cms.agent.label.maxwaittime" : "الحد الأقصى لمدة الانتظار لمكالمة واردة",
  "cms.calllink.title.callType" : "نوع الاتصال",
  "cms.report.message.ackEndDesc" : "وقت انتهاء الرد على المكالمة، والذي يشير إلى وقت انتهاء الرد على المكالمة على الجهاز الحالي.",
  "cms.report.field.obsServiceIdField" : "معرف الحملة الصادرة",
  "cms.indexmonitor.index.multimediaCalls" : "الوسائط المتعددة",
  "cms.qcrelationconf.message.queryFailedLimit" : "يجب أن يكون عدد السجلات في كل صفحة أكبر من 0 وأقل من 101.",
  "cms.failurecall.placeholder.userPhoneInput" : "أدخل رقم خدمة",
  "cms.report.field.outSuccAnsRate" : "معدل اتصال المكالمة الصادرة",
  "cms.report.field.skillId" : "معرف قائمة انتظار المهارات",
  "cms.callcenterinstanceslist.field.cmsAuthPassword" : "كلمة مرور CCDIS",
  "cms.uapmonitor.field.callUapIp" : "UAP IP",
  "cms.report.field.endYear" : "نهاية السنة",
  "cms.report.field.agentInSuccField" : "مكالمات واردة يدوية متصلة",
  "cms.callcenterinstanceslist.label.save" : "حفظ",
  "cms.indexmonitor.message.skillConnectedAfterNDesc" : "عدد طلبات المكالمات التي تم توصيلها يدويًا بعد Ns. حاليًا ، يمكن أن تكون Ns 10s أو 60s.",
  "cms.uapmonitor.title.deregis" : "تأكيد إلغاء التسجيل",
  "cms.indexmonitor.index.met" : "اجتمع",
  "cms.fullscreen.field.normalBold" : "عادي",
  "cms.report.message.outWaitAnsNumDesc" : "عدد المكالمات الصادرة يدويًا، بما في ذلك المكالمات الصادرة التي بدأها العملاء وتلك التي تم نقلها من IVR إلى الخدمات اليدوية.",
  "cms.report.message.billInfo1Desc" : "نوع المكالمة الصوتية.",
  "cms.agent.label.acgtalktime" : "متوسط وقت التحدث",
  "cms.agentMonitor.label.forcerelease_error" : "فشل فرض تحرير مكالمة.",
  "cms.failure.callerNo.maxLength.error" : "يُسمح بحد أقصى 64 حرفًا.",
  "cms.report.message.waitBeginDesc" : "وقت بدء انتظار المكالمة.",
  "cms.indexmonitor.field.skillTimeoutQueue" : "انتهاء مهلة المكالمات إلى قوائم الانتظار",
  "cms.indexmonitor.field.agentConnectInFive" : "يتم توصيل المكالمات يدويًا في 5 ثوانٍ",
  "cms.callcenterinstanceslist.message.videoCallNumsError" : "يجب أن يكون الحد الأقصى لعدد مكالمات الفيديو المتزامنة أكبر من أو يساوي عدد مقاعد الفيديو.",
  "cms.report.title.agentOperationMonitor" : "مراقبة تفاصيل عملية اتصال الموظف",
  "cms.vcallecenter.title.modifybmspassword" : "تعديل كلمة مرور BMS",
  "cms.callcenterinstanceslist.label.create" : "جديد",
  "cms.indexmonitor.title.agentNowMonitor" : "إحصائيات مراقبة في الوقت الفعلي على العامل أثناء التشغيل",
  "cms.indexmonitor.title.callCenterDayTrend" : "اتجاه اليوم من مركز الاتصال",
  "cms.voiceinspection.field.currentSkillId" : "قائمة انتظار المهارات",
  "cms.report.field.indicatorName" : "المؤشر",
  "cms.indexmonitor.message.agentAbandonOut10NDesc" : "إجمالي عدد المكالمات المهملة التي تزيد مدة الإجابة عليها عن 10 ثوانٍ.",
  "cms.fullscreen.field.percentage" : " (%)",
  "cms.agentMonitor.label.gateway_error" : "فشل استدعاء واجهة العبّارة.",
  "cms.report.field.otherHangNumCti" : "تعليق النظام",
  "cms.calllink.field.internalError" : "خطأ داخلي.",
  "cms.indexmonitor.message.skillAbandonInNDesc" : "عدد طلبات المكالمات التي تم إهمالها يدويًا في Ns. حاليًا ، يمكن أن تكون Ns 3s أو 5s أو 10s أو 20s أو 30s أو 60s.",
  "cms.uapmonitor.field.callSip" : "معرف الجهاز الطرفي",
  "cms.report.message.callAbandonedInRingDesc" : "عدد المكالمات التي تم إهمالها من قِبل المتصل أثناء الرنين دون الرد عليها.",
  "cms.report.field.awarnessRateField" : "معدل نجاح المكالمة الواردة يدويًا",
  "cms.callcenterinstanceslist.message.selectdeveloper" : "حدد مطورًا.",
  "cms.callcenterinstanceslist.message.error" : "خطأ",
  "cms.report.title.changeTable" : "التبديل إلى جدول",
  "cms.callcenterinstanceslist.field.ccBmsPort" : "ميناء CCBMS LB",
  "cms.indexmonitor.message.agentBoundAbandonRate" : "100 - معدل المكالمات الواردة اليدوية المتصلة",
  "cms.report.field.number" : "رقم غير صالح",
  "cms.indexmonitor.message.skillRequestDesc" : "عدد المكالمات الواردة التي تطلب خدمات المستخدم",
  "cms.callecenter.field.confirmbmspwd" : "تأكيد كلمة مرور CCBMS",
  "cms.systemparam.error.validate.notmatchruleminlength" : "معلمة الإدخال أقل من الحد الأدنى للطول",
  "cms.voiceinspection.field.videoClickToCall" : "فيديو انقر للاتصال",
  "cms.callcenterinstanceslist.message.passLengthValidateFailed" : "تحتوي كلمة المرور على ثمانية أحرف كحد أدنى.",
  "cms.monitordetail.field.avgTalkingDuration" : "متوسط مدة المكالمة",
  "cms.agentmonitor.label.busyState" : "مشغول",
  "cms.qcrelationconf.message.queryFailed" : "فشل الاستعلام",
  "cms.interfaceperflog.message.modifySuccess" : "نجح التعديل",
  "cms.datasource.field.uidbDatabase" : "UIDB",
  "cms.agent.label.enterdetail" : "عرض التفاصيل",
  "cms.indexmonitor.message.ivrOutboundNum" : "عدد مكالمات IVR الصادرة التي أجاب عليها الطرف المطلوب",
  "cms.report.field.agentServiceRate" : "معدل النجاح اليدوي في إجمالي المكالمات الواردة",
  "cms.qcrelationconf.title.warning" : "تحذير",
  "cms.fullscreen.message.numberSecond" : "الحد الأقصى للقيمة هو 4320.",
  "cms.report.field.maxIVROutCount" : "الحد الأقصى لمكالمات IVR المتزامنة الصادرة",
  "cms.reportsubscribe.field.prohibit" : "حظر",
  "cms.uapmonitor.field.callSocket" : "حالة المقبس",
  "cms.voiceinspection.message.frequentOperat" : "العملية متكررة للغاية. يرجى المحاولة مرة أخرى في وقت لاحق.",
  "cms.voiceinspection.message.frequentDownload" : "التنزيل متكرر للغاية. حاول مرة أخرى لاحقًا.",
  "cms.report.message.callEndTimeDesc" : "وقت انتهاء المكالمة.",
  "cms.report.field.agentLoginNum" : "الوكلاء الذين تم الاتصال بهم",
  "cms.vcallecenter.title.modifyinfo" : "تعديل كلمة المرور",
  "cms.indexmonitor.message.skillConnectedInNRate" : "النسبة المئوية لعدد طلبات المكالمات الموصلة يدويًا في Ns من إجمالي عدد المكالمات التي تطلب خدمات المستخدم",
  "cms.callcenterinstanceslist.field.passwordConfirm" : "تأكيد كلمة المرور",
  "cms.callcenterinstanceslist.message.ccgatewayServerSingledeleteconfim" : "هل تريد بالتأكيد حذف ملقم CC-Gateway المحدد؟",
  "cms.report.title.error" : "خطأ",
  "cms.report.field.minQueueInCount" : "أدنى مكالمات واردة في قائمة انتظار المهارة المتزامنة",
  "cms.report.field.avgAgentCountField" : "متوسط مكالمات الموظف المتزامنة",
  "cms.systemmonitor.message.timelarge" : "* لا يمكن أن يكون النطاق الزمني أكبر من 31 يومًا.",
  "cms.agent.label.avgcallintime" : "متوسط مدة المكالمة الواردة",
  "cms.fullscreen.field.fonts" : "الخط",
  "cms.qcrelationconf.message.updateSuccess" : "نجاح التحديث",
  "cms.sendnote.message.tipsNoteContent" : "أدخل معلومات الملاحظة.",
  "cms.agentmonitor.label.detail" : "تفاصيل مراقبة الوكيل.",
  "cms.datasource.message.deletefailed" : "فشل الحذف.",
  "cms.indexmonitor.message.skillUserAbandonDesc" : "عدد المكالمات التي تم إهمالها بشكل استباقي من قبل المستخدمين أثناء وضع قائمة الانتظار والرنين",
  "cms.callcenterinstanceslist.message.updateCtiVersion" : "تحقق مما إذا كان ترحيل بيانات CTI قد اكتمل. توخي الحذر عند تنفيذ هذه العملية.",
  "cms.fullscreen.field.indexdMode" : "وضع المراقبة",
  "cms.calllink.title.callEndTime" : "وقت انتهاء المكالمة",
  "cms.calllink.title.deviceDesc" : "وصف الجهاز",
  "cms.report.field.failQueueWaitRate" : "متوسط مدة حلقة المكالمات المفقودة",
  "cms.callcenterinstanceslist.title.warning" : "تحذير",
  "cms.voiceinspection.message.invalidParam" : "معلمة إدخال غير صالحة.",
  "cms.developerlist.title.create" : "إضافة مطور",
  "cms.uapmonitor.field.Search" : "البحث",
  "cms.callcenterinstanceslist.field.ctiversion" : "نسخة CTI",
  "cms.record-playback.voice.pause" : "إيقاف مؤقت",
  "cms.synchronize.message.syncStatusZero" : "إلى أن تكون متزامنة",
  "cms.indexmonitor.message.agentConnectInFive" : "النسبة المئوية لعدد المكالمات التي يتم توصيلها يدوياً في غضون 5 ثوانٍ من إجمالي عدد المكالمات التي يتم توصيلها يدوياً.",
  "cms.indexmonitor.message.agentConnectAfterInSixty" : "النسبة المئوية لعدد المكالمات التي تم توصيلها يدوياً بعد الستينيات من إجمالي عدد المكالمات التي تم توصيلها يدوياً.",
  "cms.report.title.skillAllMonitor" : "مراقبة ملخص قائمة انتظار المهارات",
  "cms.indexmonitor.message.durationAvgWait" : "متوسط مدة انتظار المكالمات الواردة",
  "cms.report.message.acdCallsDesc" : "عدد المكالمات الواردة والصادرة المتصلة.",
  "cms.report.message.agentAlertingTimeDesc" : "وقت تنبيه المتصل.",
  "cms.agentMonitor.label.whisper_error" : "فشل الهمس",
  "cms.indexmonitor.title.ivrDayMonitor" : "إحصائيات اليوم حول مؤشرات IVR",
  "cms.indexmonitor.field.skillConnectedIn3Rate" : "المكالمات المتصلة في 3 ثواني",
  "cms.report.field.locationIdField" : "معرف العقدة الموزعة",
  "cms.report.field.IVROutSuccNum" : "مكالمات Pure-IVR الصادرة المتصلة",
  "cms.report.field.avgQueueOutCountField" : "متوسط المكالمات الصادرة في قائمة انتظار المهارة المتزامنة",
  "cms.report.field.IVROutSuccNumField" : "مكالمات IVR الخالصة المتصلة الصادرة",
  "cms.report.field.assistTime" : "مدة طلب المساعدة الداخلية",
  "cms.systemmonitor.label.onehour" : "1 ساعة",
  "cms.voiceinspection.field.audioPlay" : "تسجيل الاختبار",
  "cms.callcenterinstanceslist.title.nodemodeselect" : "تحديد الوضع",
  "cms.vdnconfiguration.title.vdnConfigure" : "تكوين مركز الاتصال حيث يعمل VDN",
  "cms.calldetail.label.inputcallid" : "يرجى إدخال الرقم التسلسلي للمكالمة",
  "cms.fullscreen.field.statisticPeriod" : "الفترة الإحصائية",
  "cms.report.field.acdTimeRateField" : "معدل مدة المكالمة اليدوية",
  "cms.report.title.skillAccessTrend" : "اتجاه أداء قائمة انتظار المهارات حسب رمز الوصول",
  "cms.skilladjustment.message.tipsError" : "فشل ضبط قائمة انتظار المهارات.",
  "cms.report.field.threePartyCallCount" : "مكالمات ثلاثية الأطراف",
  "cms.report.message.queryFailed" : "فشل الاستعلام {0}.",
  "cms.vcallecenter.message.configurationinterfacenopassword" : "لم يتم تكوين كلمة مرور التكوين.",
  "cms.datasource.message.addsuccess" : "تمت الإضافة بنجاح.",
  "cms.incoming.record.field.releasecause" : "سبب تحرير المكالمة",
  "cms.agentmonitor.label.dayStatistic" : "إحصائيات اليوم",
  "cms.indexmonitor.message.agentInboundNum" : "عدد المكالمات الواردة التي تم تخصيصها لقوائم انتظار المهارات أو الموظفين",
  "cms.qcrelationconf.field.accountCode" : "حساب الخدمة",
  "cms.report.field.accessCodeField" : "رمز الوصول",
  "cms.indexmonitor.field.abandonInThirty" : "المكالمات التي تم إهمالها في الثلاثينيات",
  "cms.report.field.inAnswerRate" : "معدل اتصال المكالمة الواردة",
  "cms.report.field.outAboundRing" : "المكالمات الصادرة التي لم يتم الرد عليها",
  "cms.report.field.avgIVRCountField" : "متوسط مكالمات IVR المتزامنة",
  "cms.indexmonitor.message.totalConnectCallNum" : "عدد المكالمات الواردة المتصلة: عدد المكالمات الصادرة المتصلة: عدد المكالمات الصادرة المتصلة",
  "cms.agentmonitor.label.restState" : "الراحة",
  "cms.qcrelationconf.field.operation" : "الإجراء",
  "cms.fullscreen.message.screenreSolution" : "لا يمكن أن تكون دقة الشاشة فارغة.",
  "cms.agentMonitor.label.forceidle_success_calling" : "تم تعيين الموظف إجبارياً إلى الحالة خامل بنجاح. بعد انتهاء المكالمة، يدخل الموظف في الحالة خامل.",
  "cms.qcrelationconf.message.deleteSuccess" : "تم الحذف بنجاح",
  "cms.monitordetail.field.redirectcount" : "المكالمات المعاد توجيهها",
  "cms.agentmonitor.label.signOut" : "غير متصل",
  "cms.qcrelationconf.field.cancel" : "إلغاء",
  "cms.report.field.waitAnsRate" : "متوسط مدة رنين المكالمات الواردة اليدوية",
  "cms.indexmonitor.field.realTimeCalls" : "مكالمات في الوقت الحقيقي",
  "cms.fullscreen.field.movedown" : "إلى الأسفل",
  "cms.report.field.voiceCallType" : "نوع المكالمة الصوتية",
  "cms.indexmonitor.field.duration" : "المدة",
  "cms.uapmonitor.labe.uapconfing" : "مراقبة حالة المحطة الطرفية واستعادة التكوين",
  "cms.report.message.avgAgentOutCountDesc" : "متوسط عدد المكالمات الصادرة المتزامنة التي يكون نوع جهاز خدمتها هو موظف.",
  "cms.report.field.manTransferOutNumCti" : "عدد عمليات النقل اليدوي إلى IVR وإعادة النقل",
  "cms.report.message.onlineRateDesc" : "السعر عبر الإنترنت = (مدة المكالمة اليدويّة (مدة الالتفاف) إجمالي مدة الالتفاف (مدة المكالمة اليدوية) إجمالي مدة الالتفاف (مدة الالتفاف) إجمالي مدة الحالات الأخرى) / (إجمالي مدة العمل - مدة الراحة) × 100",
  "cms.callcenterinstanceslist.label.vCallCenter" : "مركز الاتصال الافتراضي",
  "cms.agentmonitor.label.operation" : "العملية",
  "cms.fullscreen.message.addVdnLeast" : "اختر مستأجرًا واحدًا على الأقل.",
  "cms.monitordetail.field.idlecount" : "الموظف يدخل الحالة خامل",
  "cms.report.field.userBusyFailNum" : "المكالمات المفقودة بسبب الخطوط المزدحمة",
  "cms.agent.label.outresttime" : "مدة مهلة الراحة",
  "cms.report.field.failAnsRate" : "طلب معدل الخسارة",
  "cms.qcrelationconf.field.groupInfo" : "المعلومات الأساسية لمجموعة المستخدمين",
  "cms.datasource.field.dbType" : "نوع مصدر البيانات",
  "cms.report.field.avgCallPerHour" : "متوسط المكالمات الواردة اليدوية لكل ساعة",
  "cms.systemparam.error.reset" : "فشل إعادة تعيين المعلمة",
  "cms.datasource.field.operation" : "العملية",
  "cms.report.field.noAckNum" : "المكالمات التي لم يتم الرد عليها",
  "cms.failurecall.field.inCallSuccNum" : "أوقات المكالمات اليدوية",
  "cms.callcenterinstanceslist.label.batchAdd" : "إضافة دفعة",
  "cms.voiceinspection.title.error" : "خطأ",
  "cms.report.field.userLevelField" : "مستوى العميل",
  "cms.report.field.inAvgCallTimeField" : "متوسط مدة المكالمة",
  "cms.report.message.maxIVRInCountDesc" : "الحد الأقصى لعدد المكالمات الواردة المتزامنة التي يكون نوع جهاز خدمتها هو IVR.",
  "cms.report.message.errorInfo" : "فشل حفظ البيانات. حدد مؤشرًا واحدًا على الأقل.",
  "cms.qualitycheck.label.editusergroup" : "تحرير UserGroup",
  "cms.report.field.callAbandonedRingField" : "عدد المكالمات اليدوية الواردة المفقودة",
  "cms.report.message.callAbandonedRingDesc" : "عدد المكالمات اليدوية الواردة المفقودة = عدد المكالمات اليدوية الواردة - عدد المكالمات اليدوية الواردة المتصلة",
  "cms.voiceinspection.message.noSftpFound" : "فشل الاستعلام عن معلومات اتصال خادم SFTP.",
  "cms.report.field.IVRServiceRateField" : "معدل نجاح المكالمة الواردة نقية IVR",
  "cms.systemparam.error.noparamselected" : "الرجاء تحديد معلمة أولاً",
  "cms.fullscreen.field.totalCallTimeDesc" : "إجمالي مدة المكالمات التي أجاب عليها الوكلاء.",
  "cms.indexmonitor.message.totalLearningNum" : "عدد العاملين في حالة التعلم",
  "cms.report.message.avgHoldTimeDesc" : "متوسط مدة تعليق المكالمة. متوسط مدة الانتظار = مدة الانتظار/عدد مرات الانتظار",
  "cms.report.message.callIdNumDesc" : "الرقم التسلسلي لوحدات CDR المتعددة التي تم إنشاؤها للمكالمة.",
  "cms.report.field.queryBeginTime" : "وقت البدء",
  "cms.fullscreen.field.pie" : "مخطط دائري",
  "cms.indexmonitor.message.agentAbandonIn3Rate" : "النسبة المئوية للمكالمات المهملة خلال 3 ثوانٍ إلى إجمالي المكالمات المهملة",
  "cms.callcenter.message.updatefailed" : "فشل التحديث",
  "cms.indexmonitor.message.agentConnectIn30Rate" : "النسبة المئوية لعدد المكالمات المتصلة يدوياً في غضون 30 ثانية (بما في ذلك 30 ثانية) إلى إجمالي عدد المكالمات.",
  "cms.agentmonitor.label.account" : "حساب الخدمة",
  "cms.report.message.IVROutSuccRateDesc" : "النسبة المئوية للمكالمات الصادرة بصيغة IVR الخالصة المتصلة إلى إجمالي المكالمات الصادرة بصيغة Pure-IVR.",
  "cms.report.field.cancel" : "إلغاء",
  "cms.report.message.manToIvrNumCtiDesc" : "عدد المرات التي يقوم فيها الموظف بتعليق المكالمة وتحويلها إلى IVR بعد توصيل المكالمة.",
  "cms.report.field.manToIvrNumCti" : "عدد المرات التي يتم فيها إيقاف العامل مؤقتاً لنقله إلى IVR",
  "cms.report.message.inCallFailNumDesc" : "عدد طلبات المكالمات المفقودة = عدد طلبات الخدمة – عدد طلبات المكالمات المتصلة",
  "cms.callcenter.field.deletesuccess" : "نجاح الحذف",
  "cms.fullscreen.field.dayTrend" : "اتجاه اليوم",
  "cms.calllink.title.deviceNo" : "معرف الجهاز",
  "cms.report.title.submediaType" : "نوع الوسائط الفرعية",
  "cms.agent.label.totaltalknums" : "إجمالي عدد المكالمات",
  "cms.voiceinspection.message.playfailure" : "فشل تشغيل الإعلان.",
  "cms.report.field.outCallTimeField" : "إجمالي مدة المكالمة الصادرة",
  "cms.report.message.outAvgRingTimeDesc" : "متوسط الوقت الذي يستغرقه الموظف لانتظار العملاء للرد على المكالمات الصادرة.",
  "cms.indexmonitor.message.agentConnectInSixty" : "النسبة المئوية لعدد المكالمات التي يتم توصيلها يدوياً في غضون 60 ثانية من إجمالي عدد المكالمات التي يتم توصيلها يدوياً.",
  "cms.callcenterinstanceslist.field.uapPerfPort" : "منفذ مراقبة أداء UAP",
  "cms.callecenter.field.setbmspwd" : "كلمة مرور CCBMS",
  "cms.datasource.message.testfailed" : "فشل اختبار الاتصال.",
  "cms.monitordetail.field.idle" : "الخمول",
  "cms.calllink.field.leaveReason89" : "بعد استدعاء الموظف لواجهة الإجابة، لا يتصل الموظف بالمستخدم ويقوم المستخدم بإنهاء الاتصال.",
  "cms.report.field.maxIVRInCount" : "الحد الأقصى لمكالمات IVR المتزامنة الواردة",
  "cms.reportsubscribe.field.monday" : "الاثنين",
  "cms.calllink.field.leaveReason88" : "يقوم المستخدم بإنهاء المكالمة بعد اتصال الموظف بالمستخدم.",
  "cms.systemmonitor.label.thirtyonedays" : "31 يوم",
  "cms.failurecall.callback" : "استعلام عن سجل رد الاتصال",
  "cms.report.field.acdCallsField" : "إجمالي المكالمات المتصلة",
  "cms.callcenterinstanceslist.field.ccGatewayPort" : "منفذ عبّارة CC",
  "cms.reportsubscribe.field.sentTime" : "وقت الإرسال",
  "cms.datasource.message.dsmodifyconfirm" : "تمت الإشارة إلى مصدر البيانات في مركز الاتصالات ولا يسري مفعوله إلا بعد إعادة تشغيل البيئة. هل تريد بالتأكيد تعديله؟",
  "cms.systemmonitor.message.timeerror" : "* يجب أن يكون وقت البدء قبل وقت الانتهاء.",
  "cms.errormessage.inputspecialchar" : "تحتوي القيمة على أحرف خاصة غير صالحة.",
  "cms.indexmonitor.field.callsOfTheDay" : "نداءات اليوم",
  "cms.indexmonitor.field.outboundCallNum" : "المكالمات الصادرة",
  "cms.failurecall.field.search" : "البحث",
  "cms.fullscreen.message.thresholdLessThan" : "يجب أن يكون أكبر من الحد الأدنى",
  "cms.agentmonitor.label.idleStatistic" : "الخمول",
  "cms.report.message.ringTimeDesc" : "إجمالي مدة الرنين للموظف، باستثناء مدة المكالمة.",
  "cms.fullscreen.field.hisMonitor" : "إحصائيات المؤشرات التاريخية",
  "cms.calllink.field.callType33" : "مكالمة واردة شائعة",
  "cms.calllink.field.callType32" : "مكالمة رقمية واردة",
  "cms.calllink.field.callType31" : "مكالمة داخلية",
  "cms.calllink.field.callType30" : "مكالمة صادرة شائعة",
  "cms.systemmonitor.field.systemInBound" : "المكالمات الواردة للنظام",
  "cms.agentmonitor.label.idleState" : "الخمول",
  "cms.report.message.IVROutCallNumDesc" : "عدد المكالمات الصادرة التي تتم بواسطة IVR فقط.",
  "cms.monitordetail.field.agentReleaseCount" : "الموظف يحرر المكالمات",
  "cms.vdnconfiguration.title.success" : "النجاح",
  "cms.developerlist.message.refreshConfirmMessage" : "هل تريد بالتأكيد تحديث SK الخاص بالمطور؟",
  "cms.report.message.avgCallPerHourDesc" : "متوسط عدد المكالمات التي يرد عليها العامل في الساعة في مقطع وقت العمل المحدد.",
  "cms.report.placeholder.workName" : "أدخل اسم موظف.",
  "cms.indexmonitor.message.agentConnectInThree" : "النسبة المئوية لعدد المكالمات التي يتم توصيلها يدوياً في غضون 3 ثواني من إجمالي عدد المكالمات التي يتم توصيلها يدوياً.",
  "cms.report.field.avgCallPerHourField" : "متوسط المكالمات الواردة اليدوية في الساعة",
  "cms.indexmonitor.message.skillLostRingDesc" : "عدد المكالمات التي لم يتم الرد عليها بعد تخصيصها للموظفين",
  "cms.report.field.userBusyFailNumField" : "مكالمات ضائعة بسبب الخطوط المزدحمة",
  "cms.report.field.inCallSuccRate" : "معدل اتصال المكالمة الواردة",
  "cms.report.message.outAnswerRateDesc" : "النسبة المئوية لعدد المكالمات الصادرة المتصلة في عدد المكالمات الصادرة.",
  "cms.calllink.field.callType29" : "مكالمة صادرة يدوية",
  "cms.indexmonitor.message.agentConnectIn5Rate" : "النسبة المئوية لعدد المكالمات الموصولة يدوياً في غضون 5 ثوان (بما في ذلك 5 ثوان) إلى إجمالي عدد المكالمات.",
  "cms.calllink.field.callType28" : "نوع اتصال الموظف الصادر",
  "cms.indexmonitor.field.skillConnectedIn5" : "المكالمات المتصلة في 5 ثواني",
  "cms.calllink.field.callType27" : "مكالمة PRI الصادرة",
  "cms.calllink.field.callType26" : "مكالمة صادرة IVR",
  "cms.indexmonitor.field.skillConnectedIn3" : "المكالمات المتصلة في 3 ثواني",
  "cms.failurecall.field.userPhone" : "رقم الخدمة",
  "cms.report.field.systemInBoundField" : "إجمالي المكالمات الواردة للنظام",
  "cms.calllink.field.callType25" : "طلب رد اتصال PRI",
  "cms.fullscreen.message.addLayout" : "فشلت إضافة اللوحة القماشية لأن اللوحة القماشية ممتلئة.",
  "cms.calllink.field.callType24" : "معاينة مكالمة PRI الصادرة",
  "cms.report.message.waitEndDesc" : "تشير إلى وقت انتهاء انتظار المكالمة على الجهاز الحالي.",
  "cms.report.field.outAnswerRateField" : "معدل اتصال المكالمات الصادرة",
  "cms.report.field.week" : "أسبوع",
  "cms.calllink.field.callType23" : "مكالمات PRI الظاهرية الواردة والصادرة",
  "cms.calllink.field.callType22" : "مكالمة PRI الصادرة المتصلة مسبقًا",
  "cms.calllink.field.callType21" : "مكالمة PRI الصادرة المشغولة",
  "cms.calllink.field.callType20" : "مكالمة مساعدة ثلاثية الأطراف",
  "cms.datasource.field.dbUrl" : "سلسلة اتصال قاعدة البيانات",
  "cms.callcenterinstanceslist.field.kafkaPassword" : "كلمة مرور كافكا",
  "cms.developerlist.label.create" : "إضافة",
  "cms.report.field.inCallRate" : "متوسط مدة المكالمة",
  "cms.report.field.playVoiceTime" : "مدة تشغيل الصوت",
  "cms.report.title.IVRAccessMonitor" : "رصد الإحصاءات على بيانات IVR عن طريق رمز الوصول",
  "cms.report.message.maxAgentOutCountDesc" : "الحد الأقصى لعدد المكالمات الصادرة المتزامنة التي يكون نوع جهاز الخدمة الخاص بها موظفًا.",
  "cms.report.message.nowTimeCheck" : "الوقت يتجاوز الوقت الحالي.",
  "cms.fullscreen.field.weighted" : "وزن الخط",
  "cms.qcrelationconf.field.agentWorkNo" : "معرف الطرف الذي تم فحصه",
  "cms.report.message.agentTalkDurationDesc" : "مدة التحدث للمتصل.",
  "cms.callcenterinstanceslist.field.systemAdmin" : "معرف مسؤول CTI",
  "cms.report.field.billInfo1Field" : "نوع المكالمة الصوتية",
  "cms.report.field.minQueueOutCountField" : "أدنى مكالمات صادرة في قائمة انتظار المهارات المتزامنة",
  "cms.indexmonitor.title.success" : "النجاح",
  "cms.indexmonitor.field.agentOutCallCount" : "عدد المكالمات الصادرة للموظف عبر الإنترنت",
  "cms.indexmonitor.field.agentBoundConnectRate" : "مكالمات واردة يدوية متصلة",
  "cms.calllink.field.callType19" : "مكالمة مساعدة من طرفين",
  "cms.calllink.field.callType18" : "مكالمة عمل نغمة الرد",
  "cms.calllink.field.callType17" : "مكالمة رقمية دولية واردة",
  "cms.report.field.IVRInBoundField" : "مكالمات IVR الواردة",
  "cms.calllink.field.callType16" : "طلب معاودة الاتصال",
  "cms.report.field.agentOutSuccField" : "مكالمات صادرة يدوية متصلة",
  "cms.calllink.field.callType15" : "معاينة مكالمة صادرة",
  "cms.calllink.field.callType14" : "مكالمة صادرة متوقعة",
  "cms.report.message.playVoiceTimeDesc" : "مدة تشغيل الصوت.",
  "cms.calllink.field.callType13" : "مكالمة صادرة متصلة مسبقًا",
  "cms.report.message.agentOfferedDesc" : "إجمالي عدد المكالمات الواردة التي تطلب خدمات موظف.",
  "cms.calllink.field.callType12" : "مكالمة صادرة مشغولة",
  "cms.callcenterinstanceslist.message.passValidateFailed" : "يجب أن تحتوي كلمة المرور على ثلاثة أنواع على الأقل مما يلي: أحرف كبيرة، وأحرف صغيرة، وأرقام، و characters: {'~! @ # $ % ^ & * () -'} الخاص التالي {'= + | [ { } ] ; : \' \", <. > /?'}",
  "cms.calllink.field.callType11" : "مكالمة صادرة محجوزة",
  "cms.calllink.field.callType10" : "مكالمة صادرة (موظف دولي)",
  "cms.agentMonitor.label.forcebusy_success" : "تم تعيين الموظف إجبارياً إلى الحالة مشغول بنجاح.",
  "cms.skilladjustment.message.tipsSelectSkill" : "حدد قائمة انتظار مهارة.",
  "cms.report.field.outCallRate" : "معدل اتصال المكالمة الصادرة",
  "cms.report.field.maxQueueCount" : "الحد الأقصى لمكالمات قائمة انتظار المهارات المتزامنة",
  "cms.report.title.callCenterAccessMonitor" : "مراقبة أداء VDN بواسطة رمز الوصول",
  "cms.report.field.outOccupyNum" : "المكالمات الصادرة",
  "cms.calllink.field.leaveReason54" : "معدل إتمام المكالمة أقل من الحد الأدنى.",
  "cms.report.message.inVdnAvgTimeDesc" : "متوسط مدة المكالمة الواردة في VDN. القيمة هي إجمالي مدة المكالمة الواردة مقسوماً على عدد المكالمات الواردة.",
  "cms.calllink.field.leaveReason53" : "تتم إزالة المشارك من المؤتمر.",
  "cms.sendnote.message.tipsSuccess" : "تم إرسال الملاحظة بنجاح.",
  "cms.calllink.field.leaveReason52" : "يقوم الرئيس بإصدار المؤتمر بشكل استباقي ، أو يتم إصدار المؤتمر بسبب استثناء CCS.",
  "cms.calllink.field.leaveReason51" : "يتصل مستخدم للدخول في مكالمة مؤتمر.",
  "cms.voiceinspection.field.queryBeginTime" : "وقت البدء",
  "cms.calllink.field.leaveReason50" : "رفض الموظف مكالمة",
  "cms.report.field.reportPeriod" : "الخطوة الإحصائية",
  "cms.monitordetail.field.media" : "الوسائط المتعددة",
  "cms.recorddrive.field.number" : "لا ..",
  "cms.indexmonitor.label.CommercialUse" : "الاستخدام التجاري",
  "cms.uapmonitor.field.callIpSize" : "نوع IP",
  "cms.monitordetail.field.adjustCount" : "الموظف يدخل حالة الترتيب",
  "cms.indexmonitor.message.skillOnlineConnectedDesc" : "عدد المكالمات التي أجاب عليها الموظفون في كتلة الخمس دقائق الحالية. 5 دقائق كتل تشغيل من 00:00 حتي 00:05، 00:05 حتي 00:10، وهلم جرا.",
  "cms.indexmonitor.label.callOutIVR" : "عدد المكالمات الصادرة عبر الإنترنت لـ IVR",
  "cms.reportsubscribe.field.sentReport" : "تم إرسال التقرير",
  "cms.report.field.maxQueueOutCountField" : "الحد الأقصى لمكالمات قائمة انتظار المهارة المتزامنة الصادرة",
  "cms.fullscreen.field.simsun" : "SimSun",
  "cms.sysparam.config.value" : "قيمة المعلمة",
  "cms.report.message.busyCountDesc" : "عدد المرات التي يدخل فيها الموظف الحالة مشغول.",
  "cms.uapmonitor.labe.eidoverlength" : "يتجاوز عدد مقاطع الأرقام الطرفية الحد الأعلى: 10.",
  "cms.monitordetail.field.busyCount" : "الموظف يدخل حالة مشغول",
  "cms.calllink.field.leaveReason48" : "الرد على مكالمة في قائمة الانتظار الأساسية في قائمة الانتظار الثانوية.",
  "cms.calllink.field.leaveReason47" : "تم وضع المكالمة في قائمة انتظار متعددة.",
  "cms.calllink.field.leaveReason46" : "تعذر الوصول إلى العامل، إعادة توجيه CDN",
  "cms.fullscreen.field.indexDimension" : "كائن مراقب",
  "cms.calllink.field.leaveReason45" : "إدخال PGR من استشارة الموظف.",
  "cms.calllink.field.leaveReason44" : "توجيه المكالمات الواردة إلى PGR.",
  "cms.calllink.field.leaveReason43" : "تقوم CDN بإرجاع مسار عادي إلى PGR.",
  "cms.calllink.field.leaveReason42" : "انتهت مهلة مسار CDN ويدخل PGR.",
  "cms.calllink.field.leaveReason41" : "إلغاء الموظف أو فشل التوجيه أثناء الاستشارة.",
  "cms.report.field.endTime" : "تاريخ الانتهاء",
  "cms.calllink.field.leaveReason40" : "انتهت مهلة توجيه CDN.",
  "cms.qualitycheck.message.saveSuccess" : "تم الحفظ بنجاح.",
  "cms.report.field.callTypeField" : "نوع الاتصال",
  "cms.monitordetail.field.restoutduration" : "مدة مهلة الراحة",
  "cms.calllink.field.activeRelease" : "يقوم المشترك بقطع اتصال المكالمة.",
  "cms.report.field.abortQueueWaitNumField" : "مكالمات قائمة انتظار مهملة",
  "cms.fullscreen.field.noanswerCountDesc" : "عدد الإجابات المتأخرة من قبل الموظفين بعد تخصيص المكالمة.",
  "cms.report.field.submediatype.instagram": "Instagram",
  "cms.report.field.submediatype.telegram": "Telegram",
  "cms.qcrelationconf.field.attendantGroupName": "مجموعة الوكيل",
  "cms.report.field.selectTrafficGroup": "تحديد مجموعة موظف",
  "cms.report.placeholder.attendantGroupName": "الرجاء إدخال اسم مجموعة الحاضرين.",
  "cms.qcrelationconf.field.attendantGroup": "مجموعة المرافق",
  "cms.report.message.selectTrafficGroupMax": "يمكن اختيار 1000 فرد كحد أقصى.",
  "cms.agentMonitor.message.operatorChecked": "فشلت العملية. الكائن الذي تم فحصه هو مراقب ويقوم بإجراء الفحص.",
  "cms.export.message.required": "حقل إلزامي",
  "cms.field.length.limit": "لا يمكن أن يتجاوز الطول 0",
  "cms.indexmonitor.field.skillConnectedIn20Rate":"تا 20 ثانيه ديگه کامل ميشه",
  "cms.multimedia.monitor.field.callNum":"عدد مكالمات IVR",
  "cms.multimedia.monitor.field.transNum":"عدد المكالمات من IVR إلى دليل",
  "cms.multimedia.monitor.field.transRate":"معدل IVR إلى دليل",
  "cms.multimedia.monitor.field.connectedNum":"عدد مكالمات IVR المتصلة",
  "cms.multimedia.monitor.field.connectedRate":"معدل إتمام مكالمة IVR",
  "cms.multimedia.monitor.field.durationAverage":"متوسط مدة مكالمة IVR",
  "cms.multimedia.monitor.field.callNum.desc":"عدد المكالمات التي يتم حاليًا خدمتها تلقائيًا بواسطة جهاز IVR",
  "cms.multimedia.monitor.field.transNum.desc":"عدد المكالمات من IVR إلى دليل",
  "cms.multimedia.monitor.field.transRate.desc":"النسبة المئوية للمكالمات من IVR إلى دليل لمكالمات IVR",
  "cms.multimedia.monitor.field.connectedNum.desc":"عدد مكالمات IVR التي تزيد مدة الاتصال بها عن 0",
  "cms.multimedia.monitor.field.connectedRate.desc":"النسبة المئوية لعدد مكالمات IVR المتصلة إلى عدد مكالمات IVR",
  "cms.multimedia.monitor.field.durationAverage.desc":"إجمالي مدة مكالمة IVR/عدد مكالمات IVR المتصلة",
  "cms.sysparameter.batchreset": "إعادة تعيين دفعة",
  "cms.sysparameter.batchrefresh": "مزامنة الدفعات",
  "cms.report.subscribe.add": "اشتراك تقرير جديد",
  "cms.report.subscribe.edit": "تحرير الاشتراك في التقرير",
  "cms.qcrelationconf.tab.member": "الأعضاء",
  "cms.qcrelationconf.add.attendantGroup": "إضافة مجموعة حركة مرور تم فحصها",
  "cms.qcrelationconf.message.isDeleteTrafficGroup": "يُرجى تأكيد ما إذا كنت تريد حذف مجموعة المكالمات",
  "cms.report.subscribe.checkreportnum": "حدد تقريرًا واحدًا على الأقل",
  "cms.sysparameter.edit.basic.information": "تحرير المعلومات الأساسية",
  "cms.sysparameter.edit.character": "تحرير الخصائص",
  "cms.sysparameter.edit.validate.ip": "أدخل عنوان IP صحيحًا.",
  "cms.message.choose": "يرجى تحديد البيانات.",
  "cms.subscribe.template.isused": "قالب البريد قيد الاستخدام بالفعل",
  "cms.subscribe.template.statuserror": "حالة قالب البريد الإلكتروني غير صحيحة",
  "cms.qcrelationconf.message.unauthorized": "يوجد موظفون غير قانونيين في البيانات المحفوظة",
  "cms.report.subscribe.editreport":"تحرير التقرير",
  "cms.report.subscribe.addreport":"تقرير جديد",
  "cms.fullscreen.agent.auth": "لا يوجد إذن لعرض المؤسسة الحالية. الرجاء إعادة التحديد",
  "cms.agentMonitor.label.changestate_error":"فشل تبديل حالة الفحص. حدث خطأ في العملية",
  "cms.agent.label.systempara":"إعدادات المعلمات",
  "cms.indexmonitor.message.skillConnectedQueue": "إجمالي مدة الانتظار لجميع المكالمات المتصلة في قائمة الانتظار",
  "cms.indexmonitor.message.skillAbandonQueue": "إجمالي مدة المكالمات التي لم يتم توصيلها في قائمة الانتظار",  
  "cms.indexmonitor.field.skillConnectedQueue": "إجمالي مدة انتظار المكالمة",
  "cms.indexmonitor.field.skillAbandonQueue": "إجمالي مدة الانتظار في حالة فقدان المكالمة",
}