import odfsRequest from '@oifde/utils/odfsRequest'

export function getMenus() {
  return odfsRequest({
    url: '/oifde/rest/queryMenus',
    method: 'get'
  })
}

export function tenantSpaces() {
  return odfsRequest({
    url: '/oifde/rest/queryAllTenantSpace',
    method: 'get'
  })
}

export function tenantSpacesByFeatures() {
  return odfsRequest({
    url: '/oifde/rest/avatarSource/queryFeatureTenants',
    method: 'get'
  })
}
