export default {
  'oifde.custom.displayName': 'การบริการธุรกิจ',
  'oifde.common.displayName': 'โหนดทั่วไป',
  'oifde.basic.displayName': 'การบริการขั้นพื้นฐาน',
  'oifde.test.displayName': 'การบริการตัวอย่างการทดสอบ',
  'oifde.start.displayName': 'เริ่ม',
  'oifde.start.description': 'เริ่ม',
  'oifde.start.keywords': 'เริ่ม',
  'oifde.commonBusinessInvokeService.displayName': 'การเรียกอินเทอร์เฟซทางธุรกิจ',
  'oifde.commonBusinessInvokeService.description': 'การเรียกอินเทอร์เฟซทางธุรกิจ (commonBusinessInvokeService)',
  'oifde.commonBusinessInvokeService.keywords': 'การเรียกอินเทอร์เฟซทางธุรกิจ',
  'oifde.procedureService.displayName': 'กระบวนงานที่จัดเก็บไว้',
  'oifde.procedureService.description': 'กระบวนงานที่จัดเก็บ (procedureService)',
  'oifde.procedureService.keywords': 'กระบวนงานที่จัดเก็บไว้',
  'oifde.conditionService.displayName': 'การตัดสินเงื่อนไข',
  'oifde.conditionService.description': 'การตัดสินเงื่อนไข(conditionService)',
  'oifde.conditionService.keywords': 'การตัดสินเงื่อนไข',
  'oifde.callEndService.displayName': 'สิ้นสุดการโทร',
  'oifde.callEndService.description': 'การสิ้นสุดการโทร(callEndService)',
  'oifde.callEndService.keywords': 'สิ้นสุดการโทร',
  'oifde.sendSmsService.displayName': 'การส่ง SMS',
  'oifde.sendSmsService.description': 'การส่ง SMS(sendSmsService)',
  'oifde.sendSmsService.keywords': 'การส่ง SMS',
  'oifde.commonVoiceService.displayName': 'การตอบสนอง',
  'oifde.commonVoiceService.description': 'การตอบสนอง(commonResponseService)',
  'oifde.commonVoiceService.keywords': 'การตอบสนอง',
  'oifde.transforFlowService.displayName': 'การถ่ายโอนโฟลว์',
  'oifde.transforFlowService.description': 'การถ่ายโอนโฟลว์(transforFlowService)',
  'oifde.transforFlowService.keywords': 'การถ่ายโอนโฟลว์',
  'oifde.callTransferService.displayName': 'โอนสาย',
  'oifde.callTransferService.description': 'โอนสาย(callTransferService)',
  'oifde.callTransferService.keywords': 'โอนสาย',
  'oifde.speechRecognitionService.displayName': 'การจดจำความหมาย',
  'oifde.speechRecognitionService.description': 'การจดจำความหมาย(speechRecognitionService)',
  'oifde.speechRecognitionService.keywords': 'การจดจำความหมาย',
  'oifde.speechRecognitionService.confidence2TopError': 'ช่วงความเชื่อมั่นต้องอยู่ระหว่าง 0 ถึง 1',
  'oifde.keyboardRecognitionService.displayName': 'การจดจำคีย์',
  'oifde.keyboardRecognitionService.description': 'การจดจำคีย์(keyboardRecognitionService)',
  'oifde.keyboardRecognitionService.keywords': 'การจดจำคีย์',
  'oifde.textVariableToChatbotService.displayName': 'ส่งข้อความไปยัง chatbot',
  'oifde.textVariableToChatbotService.description': 'ส่งข้อความไปยัง chatbot(textVariableToChatbotService)',
  'oifde.textVariableToChatbotService.keywords': 'ส่งข้อความไปยัง chatbot',
  'oifde.checkRegularService.displayName': 'การตรวจสอบกฎ',
  'oifde.checkRegularService.description': 'การตรวจสอบกฎ(checkRegularService)',
  'oifde.checkRegularService.keywords': 'การตรวจสอบกฎ',
  'oifde.dateProcessService.displayName': 'การประมวลผลวันที่',
  'oifde.dateProcessService.description': 'การประมวลผลวันที่(dateProcessService)',
  'oifde.dateProcessService.keywords': 'การประมวลผลวันที่',
  'oifde.errTimesClearService.displayName': 'การตรวจนับจำนวนข้อผิดพลาด',
  'oifde.errTimesClearService.description': 'การตรวจนับจำนวนข้อผิดพลาด(errTimesClearService)',
  'oifde.errTimesClearService.keywords': 'การตรวจนับจำนวนข้อผิดพลาด',
  'oifde.CircleService.displayName': 'วงจรตรรกะ',
  'oifde.CircleService.description': 'วงจรตรรกะ(CircleService)',
  'oifde.CircleService.keywords': 'วงจรตรรกะ',
  'oifde.compositeTestService.displayName': 'ตัวอย่างองค์ประกอบแผนภาพคอมโพสิต',
  'oifde.compositeTestService.description': 'ตัวอย่างองค์ประกอบแผนภาพคอมโพสิต(compositeTestService)',
  'oifde.compositeTestService.keywords': 'ตัวอย่างองค์ประกอบแผนภาพคอมโพสิต',
  'oifde.service.condition.condgroup.desc': 'ตั้งค่าแอตทริบิวต์เงื่อนไขการตัดสิน',
  'oifde.service.condition.condgroup.displayName': 'รายการเงื่อนไขการตัดสิน',
  'oifde.service.condition.condgroup.keyTitle': 'เงื่อนไข',
  'oifde.service.condition.condgroup.valueTitle': 'นิพจน์เงื่อนไข',
  'oifde.service.invokemethod.group.desc': 'วิธีการ Invocation',
  'oifde.service.invokemethod.group.displayName': 'วิธีการ Invocation',
  'oifde.service.invokemethod.group.value1Title': 'คืนวัตถุ',
  'oifde.service.invokemethod.group.value2Title': 'ชื่อวิธีการ',
  'oifde.service.invokemethod.group.value3Title': 'วิธี พารามิเตอร์',
  'oifde.service.invokemethod.group.value1Tips': 'วิธีการรับค่าส่งคืนวัตถุ',
  'oifde.service.invokemethod.group.value2Tips': 'เลือกชื่อเมธอด',
  'oifde.service.invokemethod.group.value3Tips': 'วิธี พารามิเตอร์ 1',
  'oifde.service.invokemethod.group.value4Tips': 'วิธี พารามิเตอร์ 2',
  'oifde.service.invokemethod.group.value5Tips': 'วิธี พารามิเตอร์ 3',
  'oifde.service.invokemethod.group.mathodType1': 'วิธีการทํางานของสตริง',
  'oifde.service.invokemethod.group.mathodType2': 'วิธีการทํางานของอาร์เรย์',
  'oifde.service.invokemethod.group.mathodType3': 'วิธีการทํางานของแผนที่',
  'oifde.service.invokemethod.strSplit.desc': 'แยกสตริงตามตัวคั่นที่ให้ไว้',
  'oifde.service.invokemethod.strSubstring.desc': 'แยกข้อความย่อย',
  'oifde.service.invokemethod.strIndexOf.desc': 'ได้รับ subscript ของการเกิดขึ้นครั้งแรกของสตริงที่ระบุ',
  'oifde.service.invokemethod.strStartsWith.desc': 'ทดสอบว่าสตริงขึ้นต้นด้วยคํานําหน้าที่ระบุหรือไม่',
  'oifde.service.invokemethod.strEndsWith.desc': 'ทดสอบว่าสตริงลงท้ายด้วยส่วนต่อท้ายที่ระบุหรือไม่',
  'oifde.service.invokemethod.strParseJSON.desc': 'แปลงสตริง JSON เป็นออบเจ็กต์',
  'oifde.service.invokemethod.strParseInt.desc': 'แปลงสตริงเป็นจํานวนเต็ม',
  'oifde.service.invokemethod.strParseFloat.desc': 'แปลงสตริงเป็นเลขลอยตัว',
  'oifde.service.invokemethod.strLength.desc': 'ความยาวสตริง',
  'oifde.service.invokemethod.strToUpperCase.desc': 'แปลงอักขระ string ตัวพิมพ์เล็กเป็นตัวพิมพ์ใหญ่',
  'oifde.service.invokemethod.strToLowerCase.desc': 'แปลงอักขระตัวพิมพ์ใหญ่ให้เป็นตัวพิมพ์เล็ก',
  'oifde.service.invokemethod.arrayAdd.desc': 'เพิ่มองค์ประกอบไปยังอาร์เรย์',
  'oifde.service.invokemethod.arrayAddAll.desc': 'เพิ่มองค์ประกอบทั้งหมดของคอลเลกชันอื่นลงในอาร์เรย์',
  'oifde.service.invokemethod.arrayContains.desc': 'กําหนดว่าองค์ประกอบที่อยู่ในอาร์เรย์',
  'oifde.service.invokemethod.arrayGet.desc': 'รับองค์ประกอบในอาร์เรย์ตามค่าดัชนี',
  'oifde.service.invokemethod.arrayRemoveIdx.desc': 'ลบองค์ประกอบ subscript ที่ระบุจากอาร์เรย์',
  'oifde.service.invokemethod.arrayRemoveAllIdx.desc': 'ลบองค์ประกอบ subscript ในอาร์เรย์ที่มีอยู่ในคอลเลกชันที่ระบุ',
  'oifde.service.invokemethod.arrayClear.desc': 'ลบองค์ประกอบทั้งหมดจากอาร์เรย์',
  'oifde.service.invokemethod.arraySize.desc': 'ได้รับความยาวของอาร์เรย์',
  'oifde.service.invokemethod.arraySubList.desc': 'Truncates และส่งกลับส่วนหนึ่งของอาร์เรย์',
  'oifde.service.invokemethod.arrayJoin.desc': 'วิธีแปลงอาร์เรย์เป็นสตริง',
  'oifde.service.invokemethod.mapPut.desc': 'เพิ่มธาตุลงในแผนที่',
  'oifde.service.invokemethod.mapGet.desc': 'ได้รับค่าของการแม็ปคีย์ที่ระบุในแผนที่',
  'oifde.service.invokemethod.mapRemove.desc': 'ลบคู่ค่ากุญแจที่ระบุออกจากแผนที่',
  'oifde.service.invokemethod.mapClear.desc': 'ล้างคู่ค่าที่สําคัญทั้งหมดของแผนที่',
  'oifde.service.invokemethod.mapSize.desc': 'ได้รับจํานวนคีย์ทั้งหมดของแผนที่',
  'oifde.service.invokemethod.strSplit.parm1.desc': 'สตริงที่จะแยก',
  'oifde.service.invokemethod.strSplit.parm2.desc': 'ตัวคั่น',
  'oifde.service.invokemethod.strSubstring.parm1.desc': 'ข้อความอักขระที่จะดักจับ',
  'oifde.service.invokemethod.strSubstring.parm2.desc': 'เริ่มตัวห้อย (จํานวนเต็ม)',
  'oifde.service.invokemethod.strSubstring.parm3.desc': 'ตัวห้อยท้าย (จํานวนเต็ม)',
  'oifde.service.invokemethod.strIndexOf.parm1.desc': 'สตริงอักขระที่จะค้นหา',
  'oifde.service.invokemethod.strIndexOf.parm2.desc': 'ระบุสตริง',
  'oifde.service.invokemethod.strStartsWith.parm1.desc': 'อักขระอักขระที่จะตรวจจับ',
  'oifde.service.invokemethod.strStartsWith.parm2.desc': 'ข้อความนําหน้าที่ระบุ',
  'oifde.service.invokemethod.strEndsWith.parm1.desc': 'สตริงอักขระที่จะทดสอบ',
  'oifde.service.invokemethod.strEndsWith.parm2.desc': 'ระบุข้อความส่วนต่อท้าย',
  'oifde.service.invokemethod.strParseJSON.parm1.desc': 'สตริงที่จะแปลง',
  'oifde.service.invokemethod.strParseInt.parm1.desc': 'สตริงที่จะแปลง',
  'oifde.service.invokemethod.strParseFloat.parm1.desc': 'สตริงที่จะแปลง',
  'oifde.service.invokemethod.strLength.parm1.desc': 'สตริงอักขระของความยาวที่จะคํานวณ',
  'oifde.service.invokemethod.strToUpperCase.parm1.desc': 'สตริงอักขระที่จะแปลง',
  'oifde.service.invokemethod.strToLowerCase.parm1.desc': 'สตริงอักขระที่จะแปลง',
  'oifde.service.invokemethod.arrayAdd.parm1.desc': 'อาร์เรย์ที่จะเพิ่ม (วิธีการนี้จะปรับเปลี่ยนอาร์เรย์ที่จะเพิ่ม)',
  'oifde.service.invokemethod.arrayAdd.parm2.desc': 'เพิ่มตําแหน่ง (Default Tail)',
  'oifde.service.invokemethod.arrayAdd.parm3.desc': 'องค์ประกอบที่เพิ่มเข้ามา',
  'oifde.service.invokemethod.arrayAddAll.parm1.desc': 'อาร์เรย์ที่จะเพิ่ม (วิธีนี้ปรับเปลี่ยนอาร์เรย์ที่จะเพิ่ม)',
  'oifde.service.invokemethod.arrayAddAll.parm2.desc': 'เพิ่มตําแหน่ง (Default Tail)',
  'oifde.service.invokemethod.arrayAddAll.parm3.desc': 'องค์ประกอบที่เพิ่มเข้ามา',
  'oifde.service.invokemethod.arrayContains.parm1.desc': 'อาร์เรย์ที่จะตรวจจับ',
  'oifde.service.invokemethod.arrayContains.parm2.desc': 'องค์ประกอบที่จะตัดสิน',
  'oifde.service.invokemethod.arrayGet.parm1.desc': 'อาร์เรย์ที่จะใช้',
  'oifde.service.invokemethod.arrayGet.parm2.desc': 'ตัวห้อยอาร์เรย์',
  'oifde.service.invokemethod.arrayRemoveIdx.parm1.desc': 'อาร์เรย์ที่จะใช้',
  'oifde.service.invokemethod.arrayRemoveIdx.parm2.desc': 'ลบตัวห้อยอาร์เรย์',
  'oifde.service.invokemethod.arrayRemoveAllIdx.parm1.desc': 'อาร์เรย์ที่จะใช้',
  'oifde.service.invokemethod.arrayRemoveAllIdx.parm2.desc': 'ระบุคอลเลกชัน subscript ของอาร์เรย์',
  'oifde.service.invokemethod.arrayClear.parm1.desc': 'อาร์เรย์ที่จะเคลียร์',
  'oifde.service.invokemethod.arraySize.parm1.desc': 'อาร์เรย์ของความยาวที่จะคํานวณ',
  'oifde.service.invokemethod.arraySubList.parm1.desc': 'อาร์เรย์ที่จะสกัดกั้น',
  'oifde.service.invokemethod.arraySubList.parm2.desc': 'ตําแหน่งเริ่มตัดบอล',
  'oifde.service.invokemethod.arraySubList.parm3.desc': 'ตําแหน่งสิ้นสุดการสกัดกั้น',
  'oifde.service.invokemethod.arrayJoin.parm1.desc': 'อาร์เรย์ที่จะแปลง',
  'oifde.service.invokemethod.arrayJoin.parm2.desc': 'การเชื่อมต่อสตริง',
  'oifde.service.invokemethod.mapPut.parm1.desc': 'แผนที่ที่จะเพิ่ม (วิธีนี้ปรับเปลี่ยนแผนที่ที่จะเพิ่ม)',
  'oifde.service.invokemethod.mapPut.parm2.desc': 'คีย์ (รองรับเฉพาะประเภทสตริงอักขระเท่านั้น)',
  'oifde.service.invokemethod.mapPut.parm3.desc': 'องค์ประกอบที่เพิ่มเข้ามา',
  'oifde.service.invokemethod.mapGet.parm1.desc': 'แผนที่ที่จะประมวลผล',
  'oifde.service.invokemethod.mapGet.parm2.desc': 'คีย์ (รองรับเฉพาะประเภทสตริงเท่านั้น)',
  'oifde.service.invokemethod.mapRemove.parm1.desc': 'แผนที่ที่จะประมวลผล',
  'oifde.service.invokemethod.mapRemove.parm2.desc': 'คีย์ (รองรับเฉพาะประเภทสตริงอักขระเท่านั้น)',
  'oifde.service.invokemethod.mapClear.parm1.desc': 'แผนที่ที่จะล้าง',
  'oifde.service.invokemethod.mapSize.parm1.desc': 'แผนที่ของปริมาณที่จะคํานวณ',
  'oifde.service.invokemethod.strSplit.resultObj.desc': 'วัตถุที่ส่งกลับเป็นประเภทอาร์เรย์และเก็บอาร์เรย์สตริงแยก',
  'oifde.service.invokemethod.strSubstring.resultObj.desc': 'วัตถุที่ส่งกลับเป็นประเภทสตริงอักขระและเป็นสตริงย่อยที่ถูกตัดทอน',
  'oifde.service.invokemethod.strIndexOf.resultObj.desc': 'วัตถุที่ส่งคืนเป็นค่าเครื่องหมายย่อยซึ่งเป็นจํานวนเต็ม',
  'oifde.service.invokemethod.strStartsWith.resultObj.desc': 'วัตถุที่ส่งคืนคือผลการตรวจสอบ ค่าเป็นจํานวนเต็มของ 0/1',
  'oifde.service.invokemethod.strEndsWith.resultObj.desc': 'วัตถุที่ส่งคืนคือผลการตรวจสอบซึ่งเป็นจํานวนเต็ม 0/1',
  'oifde.service.invokemethod.strParseJSON.resultObj.desc': 'วัตถุที่ส่งคืนคือวัตถุ JSON ที่แปลงแล้ว',
  'oifde.service.invokemethod.strParseInt.resultObj.desc': 'แสดงผลจํานวนเต็มแปลงของวัตถุ',
  'oifde.service.invokemethod.strParseFloat.resultObj.desc': 'วัตถุที่ส่งคืนเป็นตัวเลขลอยตัวหลังจากการแปลง',
  'oifde.service.invokemethod.strLength.resultObj.desc': 'ความยาวของวัตถุที่ส่งคืนเป็นสตริง ค่าเป็นจํานวนเต็ม.',
  'oifde.service.invokemethod.strToUpperCase.resultObj.desc': 'วัตถุที่ส่งคืนคือสตริงที่แปลงแล้ว',
  'oifde.service.invokemethod.strToLowerCase.resultObj.desc': 'วัตถุที่ส่งคืนคือสตริงอักขระหลังจากการแปลง',
  'oifde.service.invokemethod.arrayAdd.resultObj.desc': 'วัตถุที่ส่งคืนเป็นผลมาจากการเพิ่มวัตถุ ถ้าเพิ่มอ็อบเจ็กต์สําเร็จ 1 จะถูกส่งกลับ ไม่อย่างนั้น 0 จะถูกคืนกลับมา',
  'oifde.service.invokemethod.arrayAddAll.resultObj.desc': 'วัตถุที่ส่งคืนเป็นผลมาจากการเพิ่มวัตถุ ถ้าเพิ่มอ็อบเจ็กต์สําเร็จ 1 จะถูกส่งกลับ ไม่อย่างนั้น 0 จะถูกคืนกลับมา',
  'oifde.service.invokemethod.arrayContains.resultObj.desc': 'วัตถุที่ส่งคืนคือผลการตัดสิน ค่าเป็นจํานวนเต็มของ 0/1',
  'oifde.service.invokemethod.arrayGet.resultObj.desc': 'ส่งกลับองค์ประกอบที่มีวัตถุเป็นอาร์เรย์',
  'oifde.service.invokemethod.arrayRemoveIdx.resultObj.desc': 'อ็อบเจ็กต์ที่ส่งคืนคือผลการลบ หากการลบสําเร็จองค์ประกอบห้อยที่เกี่ยวข้องจะถูกส่งกลับ มิฉะนั้นวัตถุที่ส่งคืนจะเป็นโมฆะ',
  'oifde.service.invokemethod.arrayRemoveAllIdx.resultObj.desc': 'อ็อบเจ็กต์ที่ส่งคืนคือผลการลบ หากลบอ็อบเจ็กต์สําเร็จ 1 จะถูกส่งกลับ ไม่อย่างนั้น 0 จะถูกคืนกลับมา',
  'oifde.service.invokemethod.arrayClear.resultObj.desc': 'วัตถุที่ส่งคืนเป็นโมฆะ กล่องป้อนข้อมูลเป็นสีเทาและไม่สามารถแก้ไขได้',
  'oifde.service.invokemethod.arraySize.resultObj.desc': 'วัตถุที่ส่งคืนคือความยาวของอาร์เรย์ ค่าเป็นจํานวนเต็ม.',
  'oifde.service.invokemethod.arraySubList.resultObj.desc': 'วัตถุที่ส่งคืนมาคือ subarray',
  'oifde.service.invokemethod.arrayJoin.resultObj.desc': 'วัตถุที่ส่งคืนคือสตริงอักขระที่แปลงแล้ว',
  'oifde.service.invokemethod.mapPut.resultObj.desc': 'ตรรกะของอ็อบเจ็กต์กลับมีดังนี้: ถ้าคีย์ไม่มีอยู่, null จะถูกส่งกลับ หากมีคีย์อยู่ ค่าและประเภทอ็อบเจ็กต์ที่ถูกแทนที่จะถูกส่งกลับ',
  'oifde.service.invokemethod.mapGet.resultObj.desc': 'ส่งกลับค่าของอ็อบเจ็กต์สําหรับการแมปคีย์ที่ระบุ',
  'oifde.service.invokemethod.mapRemove.resultObj.desc': 'Return Object: วิธีนี้จะส่งกลับค่าที่เชื่อมโยงกับคีย์นี้ก่อนหน้านี้สําหรับแผนที่นี้ ผลตอบแทนเป็นโมฆะถ้าการทําแผนที่ไม่ได้มีการทําแผนที่สําหรับคีย์',
  'oifde.service.invokemethod.mapClear.resultObj.desc': 'วัตถุที่ส่งคืนเป็นโมฆะ กล่องป้อนข้อมูลเป็นสีเทาและไม่สามารถแก้ไขได้',
  'oifde.service.invokemethod.mapSize.resultObj.desc': 'จํานวนของกุญแจในแผนที่ ค่าเป็นจํานวนเต็ม',
  'oifde.service.invoke.cachegroup.desc': 'ตั้งค่าแอตทริบิวต์ตัวแปรที่สอดคล้องกัน',
  'oifde.service.invoke.cachegroup.displayName': 'แอตทริบิวต์ตัวแปร',
  'oifde.service.invoke.cachegroup.keyTitle': 'ตัวแปรแคช',
  'oifde.service.invoke.cachegroup.valueTitle': 'แอตทริบิวต์การตอบสนอง',
  'oifde.service.invoke.interfaceId': 'อินเทอร์เฟซที่เรียกใช้',
  'oifde.service.invoke.interfaceId.desc': 'อินเทอร์เฟซที่เรียกใช้',
  'oifde.service.invoke.paramgroup.desc': 'รายการพารามิเตอร์อินพุตอินเทอร์เฟซ',
  'oifde.service.invoke.paramgroup.name': 'รายการพารามิเตอร์อินพุตอินเทอร์เฟซ',
  'oifde.service.invoke.startTime': 'เวลาเริ่มต้น',
  'oifde.service.invoke.startTime.desc': 'รูปแบบ HH:MM:SS ตัวอย่างเช่น:08:00 น',
  'oifde.service.invoke.endTime': 'เวลาสิ้นสุด',
  'oifde.service.invoke.endTime.desc': 'รูปแบบ HH:MM:SS ตัวอย่างเช่น 23:00 น',
  'oifde.service.invoke.sendCircle': 'ช่วงการส่ง',
  'oifde.service.invoke.sendCircle.desc': 'ระยะเวลาที่เรียกใช้อินเทอร์เฟซ นับเป็นวัน ครบชุด ไม่เกิน 365 ถ้าการปรับแต่ง - 1 หมายถึงการไม่เรียกใช้ตามรอบระยะเวลา การเดินไปจนถึงสิ้นสุดกราฟจะเป็นการเรียกใช้หากการเรียกใช้กราฟไม่ถูกเรียกใช้ตามรอบระยะเวลา',
  'oifde.service.invoke.CallTransferCallingNum': 'หมายเลขการโทร',
  'oifde.service.invoke.CallTransferCallingNum.desc': 'เมื่อตั้งค่าประเภทการโอนสายเป็น 5 (โอนสายไปยังบุคคลที่สาม) ค่านี้จะใช้เป็นหมายเลขการโทร หมายเลขโทรต้องเป็นรหัสการเข้าถึงที่จัดสรรโดยระบบหรือพารามิเตอร์ที่กำหนดเอง ตัวอย่าง: 660001 หรือ FLOW.accessCode',
  'oifde.service.invoke.CallTransferCallingNum.lastAgentDesc': 'หมายเลขโทรโทรเข้าคือหมายเลขโทรเข้าของลูกค้า เช่น SYS.callingNumber',
  'oifde.service.invoke.CallTransferType': 'ประเภทการถ่ายโอน',
  'oifde.service.invoke.CallTransferType.desc': 'ประเภทการถ่ายโอนกล่องโต้ตอบ 1: ถ่ายโอนไปยังตัวแทนด้วยตนเอง (โอนไปยังคิวทักษะโดยรหัสเส้นทาง) 2: ย้ายไปยัง IVR; 3: ย้ายไปยังตัวแทนด้วยตนเอง (โอนไปยังคิวทักษะตามชื่อทักษะ) 4: โอนไปยังตัวแทน (โอนไปยัง ID ตัวแทน) 5: หมายเลขบุคคลที่สาม; 6: ความช่วยเหลือทางดิจิตอลออนไลน์ 7: โอนไปยังคู่มือ (โอนไปยังลูกค้า ปัจจุบันรองรับเฉพาะมัลติมีเดียเท่านั้น) 8: โอนไปยังคู่มือ (คิวทักษะซึ่งตัวแทนอยู่ด้วย ปัจจุบันรองรับเฉพาะมัลติมีเดียเท่านั้น) 31: โอนไปยังข้อความมัลติมีเดีย (โอนไปยังลูกค้าที่คุ้นเคย) 32: โอนไปยังข้อความมัลติมีเดีย (โอนไปยังคิวทักษะ) 33: การส่งต่อไปยังข้อความมัลติมีเดีย (โอนไปยังตัวแทน)',
  'oifde.service.invoke.CallTransferRouteValue': 'ค่ากําหนดค่า',
  'oifde.service.invoke.CallTransferRouteValue.desc': 'ค่าเส้นทางการโอนสาย กำหนดค่าข้อมูลรหัสเส้นทางที่สอดคล้องกันตามประเภทการโอน',
  'oifde.service.invoke.CallTransferRouteTypePolicy': 'นโยบายการกําหนดเส้นทาง',
  'oifde.service.invoke.CallTransferRoutePolicyType.percentage': 'ร้อยละ',
  'oifde.service.invoke.CallTransferRoutePolicyType.numberSegment': 'ส่วนตัวเลข',
  'oifde.service.invoke.CallTransferRouteDefaultSkillName': 'คิวทักษะปริยาย',
  'oifde.service.invoke.CallTransferMatchingType': 'กฎการจับคู่',
  'oifde.service.invoke.CallTransferMatchingType.prefix': 'คํานําหน้า',
  'oifde.service.invoke.CallTransferMatchingType.suffix': 'คําต่อท้าย',
  'oifde.service.invoke.CallTransferRouteTypePolicy.percentTotalError': 'ร้อยละรวมไม่เท่ากับ 100',
  'oifde.service.invoke.CallTransferRouteTypePolicy.percentValueError' : 'ใส่จํานวนเต็มบวกตั้งแต่ 1 ถึง 100',
  'oifde.service.invoke.CallTransferTransData': 'ข้อมูลที่เกี่ยวข้อง',
  'oifde.service.invoke.CallTransferTransData.desc': 'ข้อมูลที่เกี่ยวข้องดำเนินการระหว่างการโอนสายไปยังตัวเจ้าหน้าที่',
  'oifde.service.invoke.CallTransferTransData.desc2': 'นอกจากนี้คุณยังสามารถป้อนตัวแปรโพรเซสหรือตัวแปรระดับโลกได้หลายตัว เช่น {"data":steinFLOW.XXX},"type":®GLOBAL.XXX}, "time":"2000"}',
  'oifde.service.invoke.textVariable': 'ตัวแปรข้อความ',
  'oifde.service.invoke.textVariable.desc': 'ตัวแปรข้อความที่ส่งไปยัง chatbot ตัวแปรต้องได้รับการกำหนดค่าก่อนที่โหนดนี้จะถูกดำเนินการ',
  'oifde.service.invoke.KeyboardInputRegExp': 'นิพจน์กฎ',
  'oifde.service.invoke.KeyboardInputRegExp.desc': 'นิพจน์นี้ใช้เพื่อตรวจสอบว่าข้อความที่ผู้ใช้ป้อนตรงตามกฎที่ระบุหรือไม่ ค่าสามารถเป็นนิพจน์ทั่วไป ตัวอย่าง: ^(1([0-9]{10})|([*]|[#]))$',
  'oifde.service.invoke.voiceType': 'ประเภทการตอบสนอง',
  'oifde.service.invoke.voiceSource': 'แหล่งที่มาของการตอบสนอง',
  'oifde.service.invoke.staticVoice': 'เสียงคงที่',
  'oifde.service.invoke.TTSVoice': 'TTS',
  'oifde.service.invoke.voiceTemplateCode': 'เทมเพลตการตอบสนอง',
  'oifde.service.invoke.modelType': 'โหมดการตอบสนอง',
  'oifde.service.invoke.modelType.desc': 'ระบุว่าสามารถขัดจังหวะเสียงได้หรือไม่',
  'oifde.service.invoke.timeOut': 'ช่วงหมดเวลา',
  'oifde.service.invoke.timeOut.desc': 'ช่วงเวลาหมดเวลา(หน่วย: ินาที) ซึ่งเป็นจํานวนเต็ม ตัวอย่าง: 15',
  'oifde.service.invoke.voiceVariable': 'ตัวแปรการตอบสนอง',
  'oifde.service.invoke.voiceVariable.desc': 'ตัวแปรเสียงหรือตัวแปรข้อความในการตอบสนอง ตัวแปรต้องได้รับการกำหนดค่าก่อนที่โหนดนี้จะถูกดำเนินการ',
  'oifde.service.invoke.offsetType': 'ประเภทการชดเชย',
  'oifde.service.invoke.offsetType.desc': 'ชดเชยตามวันหรือชั่วโมง',
  'oifde.service.invoke.sourceVariable': 'สายอักขระต้นทาง',
  'oifde.service.invoke.sourceVariable.desc': 'ตัวแปรสายอักขระต้นทาง ต้องตั้งค่าก่อนที่โหนดนี้จะถูกดำเนินการ ในกรณีที่การชดเชยตามเดือน รูปแบบตัวแปรคือ yyyyMM ในกรณีที่การชดเชยตามเดือนเช่น 201809 ในกรณีของการชดเชยตามชั่วโมง รูปแบบตัวแปรคือ yyyyMMddHHmmss ตัวอย่างเช่น 20180808122020',
  'oifde.service.invoke.destVariable': 'สายอักขระเป้าหมาย',
  'oifde.service.invoke.destVariable.desc': 'ตัวแปรสายอักขระเป้าหมายซึ่งใช้ในการบันทึกผลลัพธ์',
  'oifde.service.invoke.offsetMonths': 'จำนวนการชดเชย',
  'oifde.service.invoke.offsetMonths.desc': 'จำนวนการชดเชย ค่าเป็นจำนวนเต็มและอาจเป็นค่าลบได้ คุณสามารถป้อนจำนวนการชดเชยตามประเภทการชดเชย (การชดเชยตามเดือนหรือชั่วโมง) ตัวอย่างเช่นในกรณีของการชดเชยตามเดือนค่า \u20136 บ่งชี้ว่าสายอักขระต้นทางถูกย้อนกลับไปเมื่อหกเดือนที่แล้ว',
  'oifde.service.invoke.keyBoardType': 'ประเภทอินพุตแป้นพิมพ์',
  'oifde.service.invoke.keyBoardType.desc': 'ประเภทอินพุตแป้นพิมพ์ ค่าอาจเป็น keyBoard_phone, checkoldpassword หรือว่างก็ได้',
  'oifde.service.invoke.inputVariable': 'ตัวแปร',
  'oifde.service.invoke.inputVariable.desc': 'ตัวแปรสำหรับการบันทึกอินพุตของผู้ใช้',
  'oifde.service.invoke.inputVariableTitle': 'การเพิ่มค่าตัวแปร',
  'oifde.service.invoke.inputVariableValue': 'ค่าตัวแปร',
  'oifde.service.invoke.conditionTitle': 'สาขาเงื่อนไข',
  'oifde.service.invoke.branchGroup.timeout': 'หมดเวลา',
  'oifde.service.invoke.branchGroup.nomatch': 'ไม่ตรงกัน',
  'oifde.service.invoke.branchGroup.error': 'ข้อผิดพลาด',
  'oifde.service.invoke.branchGroup.silentAgent': 'ตัวแทนเงียบ',
  'oifde.service.invoke.targetFlowCode': 'รหัส Flow เป้าหมาย',
  'oifde.service.invoke.targetNodeId': 'ID โหนดเป้าหมาย',
  'oifde.service.invoke.smsTemplateCode': 'เทมเพลต SMS',
  'oifde.service.invoke.smsContent': 'เนื้อหา SMS',
  'oifde.service.invoke.smsContent.desc': 'เนื้อหา SMS',
  'oifde.service.invoke.modelType.voiceBreak': 'การขัดจังหวะของการจดจำ',
  'oifde.service.invoke.modelType.noVoiceBreak': 'เล่นย้อนหลัง แต่ไม่จดจำและกดปุ่ม',
  'oifde.service.invoke.modelType.keyboardBreak': 'การขัดจังหวะด้วยคีย์',
  'oifde.service.invoke.modelType.voiceAndKeyboard': 'การจดจำและการกดปุ่ม',
  'oifde.service.invoke.modelType.voiceAndNobreak': 'การจดจำหลังการเล่นย้อนหลัง',
  'oifde.service.invoke.modelType.voiceAndInputAndNobreak': 'การรับรู้และการกดปุ่มหลังจากเล่น',
  'oifde.service.invoke.context': 'เนื้อหา',
  'oifde.service.invoke.context.desc': 'ข้อมูลเนื้อหาถ่ายโอนไปยัง TUC',
  'oifde.service.invoke.vendorParam': 'พารามิเตอร์เพิ่มเติม',
  'oifde.service.invoke.vendorParam.desc': 'พารามิเตอร์การระบุเสียงเพิ่มเติมส่งกลับไปยัง IVR',
  'oifde.service.invoke.input': 'เนื้อหาการระบุความหมาย',
  'oifde.service.invoke.input.desc': 'เนื้อหาการระบุความหมายที่ถ่ายโอนไปยัง TUC สามารถเป็นตัวแปรได้ หากเนื้อหาว่างเปล่า เนื้อหาที่ป้อนโดยผู้ใช้จะถูกถ่ายโอนโดยค่าเริ่มต้น。',
  'oifde.service.invoke.event': 'เหตุการณ์',
  'oifde.service.invoke.event.desc': 'ส่งเหตุการณ์ไปยัง TUC แล้ว',
  'oifde.service.invoke.event.cleanup': 'ล้างแคชเซสชัน',
  'oifde.service.invoke.event.cleanCurrent': 'ล้างแคชเนื้อหาปัจจุบัน',
  'oifde.service.invoke.procedureCode': 'กระบวนงานที่จัดเก็บไว้',
  'oifde.service.invoke.complexcellgroupin.name': 'รายการพารามิเตอร์อินพุต C',
  'oifde.service.invoke.complexcellgroupin.desc': 'พารามิเตอร์อินพุต',
  'oifde.service.invoke.complexcellgroupout.name': 'พารามิเตอร์เอาต์พุต',
  'oifde.service.invoke.complexcellgroupout.desc': 'พารามิเตอร์เอาต์พุต',
  'oifde.service.invoke.targetIntentCount': 'ปริมาณเจตนา',
  'oifde.service.invoke.targetIntentCount.desc': 'จำนวนเจตนาสูงสุดที่ต้องถูกส่งคืนโดย TUC',
  'oifde.service.invoke.confidence2Top': 'ช่วงความเชื่อมั่น',
  'oifde.service.invoke.confidence2Top.desc': 'ช่วงเวลาระหว่างความเชื่อมั่นของหลายเจตนาและเจตนาแรกเมื่อหลายเจตนาจำเป็นต้องถูกส่งคืน ',
  'oifde.service.invoke.index.desc': 'ตัวนับ',
  'oifde.service.invoke.index': 'ตัวนับ',
  'oifde.service.invoke.oblist.desc': 'รายการวงวน',
  'oifde.service.invoke.oblist': 'รายการวงวน',
  'oifde.service.invoke.object.desc': 'อ็อบเจกต์วงวน',
  'oifde.service.invoke.object': 'อ็อบเจกต์วงวน',
  'oifde.service.invoke.voiceType.staticVoice': 'เสียงคงที่',
  'oifde.service.invoke.voiceType.TTSVoice': 'TTS',
  'oifde.service.invoke.voiceType.video': 'การเล่นวิดีโอ',
  'oifde.service.invoke.voiceType.multi-media': 'มัลติมีเดีย',
  'oifde.service.invoke.offsetType.month': 'ชดเชยตามเดือน',
  'oifde.service.invoke.offsetType.hour': 'ชดเชยตามชั่วโมง',
  'oifde.service.invoke.KeyboardInputRegExp.MobileAll': 'การตรวจสอบรูปแบบหมายเลขโทรศัพท์มือถือ (ที่มี # และ *)',
  'oifde.service.invoke.KeyboardInputRegExp.Mobile': 'การตรวจสอบรูปแบบหมายเลขโทรศัพท์มือถือ (ที่ม่มี # หรือ *)',
  'oifde.service.invoke.KeyboardInputRegExp.PasswordAll': 'การตรวจสอบรูปแบบรหัสผ่าน (ที่มี # และ *)',
  'oifde.service.invoke.KeyboardInputRegExp.PasswordStar': 'การตรวจสอบรูปแบบรหัสผ่าน (ที่มี *)',
  'oifde.service.invoke.KeyboardInputRegExp.NumberAll': 'การตรวจสอบหมายเลขโทรศัพท์มือถือ (ที่มี # และ *)',
  'oifde.service.invoke.KeyboardInputRegExp.NumberStar': 'การตรวจสอบหมายเลขโทรศัพท์มือถือ (ที่มี *)',
  'oifde.service.invoke.KeyboardInputRegExp.Month': 'การตรวจสอบอินพุตข้อมูลเดือน',
  'oifde.service.invoke.modelType.inputAndNobreak': 'การประกาศที่ไม่ถูกขัดจังหวะที่เล่นหลังจากการรวบรวมตัวเลข',
  'oifde.service.invoke.maxCollectLength': 'จำนวนสูงสุดของตัวเลขที่รวบรวมได้',
  'oifde.service.invoke.maxCollectLength.desc': 'กำหนดจำนวนสูงสุดของตัวเลขที่รวบรวมได้',
  'oifde.service.invoke.cancelCollectFlag': 'ปุ่มยกเลิก',
  'oifde.service.invoke.cancelCollectFlag.yes': 'ใช้ปุ่มดอกจัน (*) เป็นปุ่มยกเลิก',
  'oifde.service.invoke.cancelCollectFlag.no': 'ไม่ใช้ปุ่มดอกจัน (*) เป็นปุ่มยกเลิก',
  'oifde.service.invoke.confirmCollectFlag': 'ปุ่มยืนยัน',
  'oifde.service.invoke.confirmCollectFlag.yes': 'ใช้ปุ่มสี่เหลี่ยม (#) เป็นปุ่มยืนยัน',
  'oifde.service.invoke.confirmCollectFlag.no': 'ไม่ใช้ปุ่มสี่เหลี่ยม (#) เป็นปุ่มยืนยัน',
  'oifde.service.invoke.ttsAdvancedSetting': 'การตั้งค่าขั้นสูง TTS',
  'oifde.service.invoke.ttsAdvancedSetting.desc': 'การตั้งค่าขั้นสูง TTS ซึ่งระบุการเล่นเสียงตาม TTS เปิดใช้งานฟังก์ชันนี้ตามต้องการ',
  'oifde.service.invoke.ttsAdvancedSetting.open': 'เปิดใช้งาน',
  'oifde.service.invoke.ttsAdvancedSetting.close': 'ปิดใช้งาน',
  'oifde.service.invoke.ttsSoundEffect': 'ซาวด์เอฟเฟกต์',
  'oifde.service.invoke.ttsSoundEffect.desc': 'ซาวด์เอฟเฟกต์ของการเล่นเสียงตาม TTS ตัวเลือกคือเสียงผู้ชายและเสียงผู้หญิง',
  'oifde.service.invoke.ttsSoundEffect.male': 'เสียงผู้ชาย',
  'oifde.service.invoke.ttsSoundEffect.female': 'เสียงผู้หญิง',
  'oifde.service.invoke.ttsSoundSpeed': 'ความเร็วในการพูด',
  'oifde.service.invoke.ttsSoundSpeed.desc': 'ความเร็วในการเล่นเสียง TTS ตัวเลือกคือต่ำ ปานกลาง และสูง',
  'oifde.service.invoke.ttsSoundSpeed.low': 'ต่ำ',
  'oifde.service.invoke.ttsSoundSpeed.normal': 'ปานกลาง',
  'oifde.service.invoke.ttsSoundSpeed.high': 'สูง',
  'oifde.service.invoke.ttsMRCPVendor': 'พารามิเตอร์เพิ่มเติม TTS',
  'oifde.service.invoke.ttsMRCPVendor.desc': 'พารามิเตอร์เพิ่มเติมของเซิร์ฟเวอร์ TTS ตั้งค่าพารามิเตอร์นี้ตามข้อกำหนดของซัพพลายเออร์เซิร์ฟเวอร์ TTS',
  'oifde.service.invoke.asrAdvancedSetting': 'การตั้งค่าขั้นสูง ASR',
  'oifde.service.invoke.asrAdvancedSetting.desc': 'การตั้งค่าขั้นสูง ASR ซึ่งระบุการจดจำ ASR เปิดใช้งานฟังก์ชันนี้ตามต้องการ',
  'oifde.service.invoke.asrAdvancedSetting.open': 'เปิดใช้งาน',
  'oifde.service.invoke.asrAdvancedSetting.close': 'ปิดใช้งาน',
  'oifde.service.invoke.asrRecognizeType': 'ประเภทการจดจำ',
  'oifde.service.invoke.asrRecognizeType.desc': 'ประเภทการระบุ ASR ปัจจุบันรองรับเฉพาะการระบุทั่วไปเท่านั้น',
  'oifde.service.invoke.asrRecognizeType.normal': 'ทั่วไป',
  'oifde.service.invoke.asrRecognizeType.intelligent': 'อัจฉริยะ',
  'oifde.service.invoke.asrNoInputTimeout': 'ช่วงหมดเวลาการเงียบของผู้รับบริการ',
  'oifde.service.invoke.asrNoInputTimeout.desc': 'ช่วงหมดเวลาเมื่อผู้รับบริการไม่พูดระหว่างการจดจำ ASR เป็นวินาที ค่าอยู่ในช่วงตั้งแต่ 0 ถึง 3200 ค่าเริ่มต้นคือ 100',
  'oifde.service.invoke.asrRecognitionTimeout': 'ช่วงหมดเวลาการจดจำ',
  'oifde.service.invoke.asrRecognitionTimeout.desc': 'ช่วงเวลาเมื่อการจดจำ ASR หมดเวลาเป็นวินาที ค่าอยู่ในช่วงตั้งแต่ 0 ถึง 600 ค่าเริ่มต้นคือ 200',
  'oifde.service.invoke.asrSpeechCompleteTimeout': 'ผู้รับบริการหยุดช่วงหมดเวลา',
  'oifde.service.invoke.asrSpeechCompleteTimeout.desc': 'ช่วงหมดเวลาเมื่อผู้รับบริการหยุดระหว่างการจดจำ ASR เป็นวินาที ค่ามีตั้งแต่ 300 ถึง 2000 ค่าเริ่มต้นคือ 500',
  'oifde.service.invoke.asrMRCPVendor': 'พารามิเตอร์เพิ่มเติม ASR',
  'oifde.service.invoke.asrMRCPVendor.desc': 'พารามิเตอร์เพิ่มเติมของเซิร์ฟเวอร์ ASR ตั้งค่าพารามิเตอร์นี้ตามข้อกำหนดของซัพพลายเออร์เซิร์ฟเวอร์ ASR',
  'oifde.service.invoke.voiceType.card': 'บัตร',
  'oifde.service.invoke.operationType': 'ประเภทการดำเนินงาน',
  'oifde.service.invoke.operationType.open': 'เริ่มการบันทึก',
  'oifde.service.invoke.operationType.close': 'หยุดการบันทึก',
  'oifde.service.invoke.processRecord': 'การบันทึกแบบเต็มกระบวนการ',
  'oifde.service.invoke.processRecord.open': 'ใช่',
  'oifde.service.invoke.processRecord.close': 'ไม่ใช่',
  'oifde.service.invoke.recordType': 'ประเภทการบันทึก',
  'oifde.service.invoke.recordType.audio': 'เสียง',
  'oifde.service.invoke.recordType.video': 'เสียงและวิดีโอ',
  'oifde.service.invoke.multiVoiceType': 'ประเภท',
  'oifde.yugaoRecordService.displayName': 'การบันทึก VoiceCyber',
  'oifde.yugaoRecordService.description': 'การบันทึก VoiceCyber (yugaoRecordService)',
  'oifde.yugaoRecordService.keywords': 'การบันทึก VoiceCyber',
  'oifde.jsonDataProcessService.displayName': 'การเรียกข้อมูล JSON',
  'oifde.jsonDataProcessService.description': 'การเรียกข้อมูล JSON (jsonDataProcessService)',
  'oifde.jsonDataProcessService.keywords': 'การเรียกข้อมูล JSON',
  'oifde.timeSelectService.displayName': 'เลือกเวลา',
  'oifde.timeSelectService.description': 'เลือกเวลา (timeSelectService).',
  'oifde.timeSelectService.keywords': 'เลือกเวลา',
  'oifde.queryMessageService.displayName': 'สอบถามข้อมูล',
  'oifde.queryMessageService.description': 'สอบถามข้อมูล(queryMessageService).',
  'oifde.queryMessageService.keywords': 'สอบถามข้อมูล',
  'oifde.service.invoke.recordMode': 'โหมดบันทึก',
  'oifde.service.invoke.recordMode.onlyuser': 'บันทึกเฉพาะผู้สมัครเป็นสมาชิก',
  'oifde.service.invoke.recordMode.onlyivr': 'บันทึกเฉพาะ IVR',
  'oifde.service.invoke.recordMode.all': 'บันทึกผู้สมัครเป็นสมาชิกและ IVR',
  'oifde.service.invoke.timeSelect.NonWorkTime': 'สาขาเวลาที่ไม่ระบุ',
  'oifde.service.invoke.queryMsg.MessageQueryType': 'ประเภทของแบบสอบถาม',
  'oifde.service.invoke.queryMsg.MessageQueryType.desc': 'สถานการณ์การใช้งานข้อมูลการเข้าคิว: ข้อมูลการเข้าคิวด้วยกระบวนการ IVR จะถูกเปิดใช้งานเมื่อมีการโอนสายไปยังคิวทักษะ',
  'oifde.service.invoke.queryMsg.skillMsg': 'ข้อมูลการเข้าคิว',
  'oifde.service.invoke.queryMsg.skillInfo': 'ข้อมูลคิว',
  'oifde.service.invoke.queryMsg.specifiedSkill': 'ชื่อคิว',
  'oifde.service.invoke.queryMsg.specialListQuery': 'ข้อมูลรายการพิเศษ',
  'oifde.service.invoke.queryMsg.result': 'ผลลัพธ์การค้นหา',
  'oifde.service.invoke.queryMsg.MessageQueryQueueIndex': 'ตําแหน่งคิว',
  'oifde.service.invoke.queryMsg.MessageQueryQueueNumber': 'จํานวนคิว',
  'oifde.service.invoke.queryMsg.MessageQueryOnlineNum': 'จํานวนตัวแทนออนไลน์',
  'oifde.service.invoke.queryMsg.MessageIdleAgentNum': 'จํานวนตัวแทนที่ไม่ได้ใช้งาน',
  'oifde.service.invoke.queryMsg.MessageQueryWaitingTime': 'เวลารอที่คาดไว้',
  'oifde.service.invoke.queryMsg.MessageQueryWaitingTime.desc': 'เวลารอที่คาดไว้ของคิวจะคํานวณตามรูปแบบคิว ผล ที่ ได้ อาจ ต่าง ออก ไป. ฉะนั้น จง ใช้ อย่าง ถูก ต้อง.',
  'oifde.service.invoke.queryMsg.params': 'ขอพารามิเตอร์',
  'oifde.service.invoke.queryMsg.skillQueue': 'คิวทักษะ',
  'oifde.service.invoke.queryMsg.agentId': 'รหัสตัวแทน',
  'oifde.service.invoke.queryMsg.specialList': 'ID ที่มีอยู่ในรายการพิเศษ',
  'oifde.service.invoke.CallTransferType.transferToSkillQueueByRoutingCode': 'เปลี่ยนคิวทักษะ (ระบุรหัสขยายเส้นทาง)',
  'oifde.service.invoke.CallTransferType.transferToIVR': 'ย้ายไปยัง IVR',
  'oifde.service.invoke.CallTransferType.transferToSkillQueueBySkillName': 'แปลงคิวทักษะ (ระบุชื่อ)',
  'oifde.service.invoke.CallTransferType.transferToAgentByID': 'เปลี่ยนตัวแทน',
  'oifde.service.invoke.CallTransferType.transferToThirdPartyNumber': 'หมายเลขบุคคลที่สาม',
  'oifde.service.invoke.CallTransferType.onlineDigitalAssistant': 'ความช่วยเหลือทางดิจิตอลออนไลน์',
  'oifde.service.invoke.CallTransferType.transferToLastAgent': 'แปลงมัลติมีเดีย',
  'oifde.service.invoke.CallTransferType.transferToSkillQueueBelongAgent': 'แปลงคิวทักษะ (คิวทักษะที่มีอยู่ตัวแทนมัลติมีเดีย)',
  'oifde.service.invoke.CallTransferType.transferToSkillQueueByRouteConfig': 'โอนไปยังคิวทักษะ (เส้นทางที่กําหนดเอง)',
  'oifde.service.invoke.CallTransferType.transferToMultimediaMessageToLastAgent': 'ส่งข้อความไปยังเพื่อนสนิท',
  'oifde.service.invoke.CallTransferType.transferToMultimediaMessageToSkillQueue': 'ส่งข้อความไปยังคิวทักษะ (ระบุชื่อหรือรหัสการเข้าถึง)',
  'oifde.service.invoke.CallTransferType.transferToMultimediaMessageToAgent': 'ส่งข้อความไปยังเจ้าหน้าที่',
  'oifde.service.invoke.CallTransferTypeGroup.multimediaMessage': 'ข้อความมัลติมีเดีย',
  'oifde.service.invoke.CallTransferRecordFlag': 'เปิดใช้งานการบันทึก',
  'oifde.service.invoke.CallTransferRecordFlag.desc': '', // 本身就为空不展示
  'oifde.service.invoke.recordPath': 'ชื่อแฟ้มบันทึก',
  'oifde.service.invoke.recordPath.desc': 'ป้อนตัวแปรสําหรับจัดเก็บชื่อแฟ้มบันทึก',
  'oifde.service.invoke.recordServiceNodeID': 'บันทึก ID โหนดบริการ',
  'oifde.service.invoke.recordServiceNodeID.desc': 'ป้อนตัวแปรที่จะเก็บรหัสประจําของโหนดบริการการบันทึก (ใช้ในโหมดพูลของศูนย์บริการลูกค้า)',
  'oifde.service.invoke.modelType.customization.desc': 'Interrupt ระบุว่าสามารถขัดจังหวะได้หรือไม่ หากสามารถถูกขัดจังหวะได้ให้กรอกข้อมูลลงในช่องป้อนเวลาเล่นขั้นต่ํา ตัวแปรจะเต็มไปด้วยจริงหรือเท็จจริงบ่งชี้ว่าพวกเขาสามารถรับรู้และทําลายและเวลาเล่นขั้นต่ําสามารถผ่านไปได้ False สามารถรับรู้ได้ แต่ไม่สามารถหักได้ และการกรอกในข้อผิดพลาดคือค่าเริ่มต้นของ false',
  'oifde.ringService.displayName': 'เสียงเรียกเข้า',
  'oifde.assignValueService.displayName': 'กำหนด',
  'oifde.transferService.displayName': 'โอน',
  'oifde.service.inpytOrDrag': 'ป้อนหรือลากตัวแปร',
  'oifde.service.operateAsFunction': 'ดําเนินการตามวิธีการที่ให้ไว้',
  'oifde.basicOdfsSubFlowService.displayName': 'กระบวนการย่อย (สมาร์ท)',
  'oifde.basicCcivrSubFlowService.displayName': 'กระบวนการย่อย (ปกติ)',
  'oifde.yugaoRecordServiceNew.displayName': 'บันทึกการเก็บข้อมูล',
  'oifde.conditionServiceNew.displayName': 'การตัดสินสาขา',
  'oifde.service.invoke.callTranseferService.transData.wait': 'คิวเข้าคิว',
  'oifde.service.invoke.callTranseferService.transData.data': 'ส่งข้อมูล',
  'oifde.service.invoke.callTranseferService.transData.isSpecifyAccessCode': 'ระบุรหัสการเข้าถึงช่อง',
  'oifde.service.invoke.callTranseferService.transData.type': 'โหมดผู้รู้จัก',
  'oifde.service.invoke.callTranseferService.transData.time': 'ช่วงเวลา (นาที)',
  'oifde.service.invoke.callTranseferService.transData.timeTips': 'ตัวอย่าง: 100, โอนไปยังตัวแทนที่ติดต่อล่าสุดภายใน 100 นาที; คําแนะนําตามเวลา 1 ถึง 10080',
  'oifde.service.invoke.callTranseferService.transData.timeErrorTips': 'โปรดป้อนจํานวนเต็มบวกหรือตัวแปร',
  'oifde.service.invoke.callTranseferService.transData.starttime': 'ช่วงวันที่',
  'oifde.service.invoke.callTranseferService.transData.starttimeTips': 'ตัวแทนที่ติดต่อล่าสุดในช่วงวันที่ระบุ',
  'oifde.service.invoke.callTranseferService.transData.type.selectTime': 'กําหนดนาที',
  'oifde.service.invoke.callTranseferService.transData.type.selectStartTime': 'กําหนดวันที่',
  'oifde.service.invoke.callTranseferService.transData.type.currentDay': 'วันนั้น',
  'oifde.service.invoke.callTranseferService.transData.paramValueTips': "ป้อนค่าพารามิเตอร์ ",
  'oifde.service.invoke.callTranseferService.transData.varTips': "สามารถป้อนตัวแปรได้ ตัวแปรทั่วโลกเริ่มต้นด้วย GLOBAL, ตัวแปรการไหล, ตัวแปรระบบเริ่มต้นด้วย SYS, ตัวแปร TUC เริ่มต้นด้วย TOC, และตัวแปรคําขอ IVR เริ่มต้นด้วย IVRREQUEST ใช้ {'${}'} เพื่อตัดตัวแปร ตัวอย่างเช่น {'${FLOW.variable}'}",
  'oifde.service.invoke.ruleValidationService.name': 'ตรวจสอบกฎ V2',
  'oifde.service.invoke.ruleValidationService.variable': 'ตัวแปรที่รอการตรวจสอบ',
  'oifde.service.invoke.ruleValidationService.specialEndKey': 'สิ้นสุดปุ่มพิเศษ',
  'oifde.service.invoke.ruleValidationService.specialEndKey.option1': 'ด้วยหมายเลข #',
  'oifde.service.invoke.ruleValidationService.specialEndKey.option2': 'หมายเลข *',
  'oifde.service.invoke.ruleValidationService.specialEndKey.option3': 'ด้วยหมายเลข # หรือ *',
  'oifde.service.invoke.ruleValidationService.specialEndKey.option4': 'ไม่มีปุ่มพิเศษ',
  'oifde.service.invoke.ruleValidationService.containsChar': 'รวมอักขระ',
  'oifde.service.invoke.ruleValidationService.containsChar.option1': 'จับคู่ตัวเลข',
  'oifde.service.invoke.ruleValidationService.containsChar.option2': 'เข้าคู่อักษรตัวพิมพ์ใหญ่',
  'oifde.service.invoke.ruleValidationService.containsChar.option3': 'จับคู่อักษรตัวพิมพ์เล็ก',
  'oifde.service.invoke.ruleValidationService.containsChar.option4': 'จับคู่ตัวอักษร, ตัวเลข, ขีดล่าง',
  'oifde.service.invoke.ruleValidationService.containsChar.option5': 'อักขระว่าง',
  'oifde.service.invoke.ruleValidationService.validationRules': 'ตรวจสอบกฎ',
  'oifde.service.invoke.ruleValidationService.validationRules.ruleParam': 'พารามิเตอร์กฎ',
  'oifde.service.invoke.ruleValidationService.validationRules.ruleParamValueTips': "ตัวแปรที่สามารถป้อนได้ ตัวแปรทั่วไปเริ่มต้นด้วย GLOBAL ตัวแปรกระบวนการเริ่มต้นด้วย FLOW ตัวแปรระบบเริ่มต้นด้วย SYS ตัวแปร TUC เริ่มต้นด้วย TOC และตัวแปรที่ร้องขอ IVR เริ่มต้นด้วย IVRQUEST ตัวอย่างเช่น FLOW.variable",
  'oifde.service.invoke.ruleValidationService.validationRules.minLength': 'ความยาวขั้นต่ํา',
  'oifde.service.invoke.ruleValidationService.validationRules.minLength.valueTips': 'โปรดป้อนความยาวขั้นต่ํา',
  'oifde.service.invoke.ruleValidationService.validationRules.maxLength': 'ความยาวสูงสุด',
  'oifde.service.invoke.ruleValidationService.validationRules.maxLength.valueTips': 'โปรดป้อนความยาวสูงสุด',
  'oifde.service.invoke.ruleValidationService.validationRules.rangeLength': 'ช่วงความยาว',
  'oifde.service.invoke.ruleValidationService.validationRules.min': 'ค่าต่ําสุด',
  'oifde.service.invoke.ruleValidationService.validationRules.min.valueTips': 'โปรดป้อนค่าต่ําสุด',
  'oifde.service.invoke.ruleValidationService.validationRules.max': 'ค่าสูงสุด',
  'oifde.service.invoke.ruleValidationService.validationRules.max.valueTips': 'โปรดป้อนค่าสูงสุด',
  'oifde.service.invoke.ruleValidationService.validationRules.range': 'ช่วงต้นแบบ',
  'oifde.service.invoke.ruleValidationService.validationRules.email': 'อีเมล์',
  'oifde.service.invoke.ruleValidationService.validationRules.url': 'URL',
  'oifde.service.invoke.ruleValidationService.validationRules.dateFormat': 'รูปแบบเวลา',
  'oifde.service.invoke.ruleValidationService.validationRules.dateFormat.valueTips': 'เติมรูปแบบเวลา เช่น วันที่ (yyyy-MM-dd) หรือวันที่ (yyyy-MM-dd HH:mm:ss)',
  'oifde.service.invoke.ruleValidationService.validationRules.number': 'เลขฐานสิบ',
  'oifde.service.invoke.ruleValidationService.validationRules.digits': 'จํานวนเต็ม',
  'oifde.service.invoke.ruleValidationService.validationRules.ip': 'ที่อยู่ IP',
  'oifde.service.invoke.ruleValidationService.validationRules.alpha': 'ขีดฆ่าเลขตัวอักษร (ไม่ใช่ตัวเลขเริ่มต้น)',
  'oifde.service.invoke.timeSelect.serviceTimeParameter': 'ระยะเวลาการให้บริการ',
  'oifde.service.invoke.timeSelect.workDate': 'ระบุวันทํางาน',
  'oifde.service.invoke.timeSelect.noWorkDate': 'ระบุวันที่ไม่ทํางาน',
  'oifde.service.invokemethod.offset Month.desc': 'การเบี่ยงเบนรายเดือน',
  'oifde.service.invokemethod.offset Month.parm1.desc': 'ตัวแปรเวลารออยู่, ประเภทสตริง, รูปแบบ yyyyyMM',
  'oifde.service.invokemethod.offset Month.parm2.desc': 'ค่าเบี่ยงเบน, ค่าลบ',
  'oifde.service.invokemethod.offset Month.resultObj.desc': 'ส่งคืนข้อมูลหลังจากวัตถุถูกเบี่ยงเบน',
  'oifde.service.invokemethod.offsetHour.desc': 'การเบี่ยงเบนรายชั่วโมง',
  'oifde.service.invokemethod.offsetHour.parm1.desc': 'ตัวแปรชั่วคราว, ประเภทสตริง, รูปแบบ yyyyMMddHmmsss',
  'oifde.service.invokemethod.offsetHour.parm2.desc': 'ค่าเบี่ยงเบน, ค่าลบ',
  'oifde.service.invokemethod.offsetHour.resultObj.desc': 'ส่งคืนข้อมูลหลังจากวัตถุถูกเบี่ยงเบน',
  'oifde.interfaceInvoke.displayName': 'การเรียกใช้อินเทอร์เฟซ',
  'oifde.service.invoke.yugaoRecord.type': 'ประเภท:',
  'oifde.service.invoke.yugaoRecord.mode': 'โหมด:',
  'oifde.service.invoke.subflow.subFlowNode': 'กระบวนการย่อย',
  'oifde.service.invokemethod.group.mathodType4': 'วิธีการปฏิบัติการวันที่',
  'oifde.service.invoke.timeSelect.specificWorkDateParameter.desc': 'รองรับเร็กคอร์ดได้สูงสุด 50 รายการ',
  'oifde.qarecord.interface.name': 'บันทึกคําถาม & ตอบ',
  'oifde.qarecord.interface.desc': 'ข้อมูลสําหรับบันทึกเอกลักษณ์แบบสอบถาม ชื่อคําถาม ความตั้งใจของผู้ใช้ คําตอบดั้งเดิมของผู้ใช้',
  'oifde.apiToken.interface.name': 'รับโทเค็น APIFabric',
  'oifde.apiToken.interface.desc': 'อินเทอร์เฟซที่ใช้เพื่อรับโทเค็น API Fabric',
  'oifde.apiToken.applyType.desc': 'นําหน้าโทเค็น',
  'oifde.apiToken.accessToken.desc': 'เนื้อหาของโทเค็น',
  'oifde.apiToken.appKey.desc': 'คีย์แอป',
  'oifde.apiToken.appSecret.desc': 'ความลับของแอป',
  'oifde.qarecord.inputparam.seriesId': 'การระบุชุดคําถามและคําตอบ',
  'oifde.qarecord.inputparam.questionTitle': 'ส่วนหัวปัญหา',
  'oifde.qarecord.inputparam.questionType': 'ประเภทปัญหา',
  'oifde.qarecord.inputparam.answerIntent': 'ตอบเจตนา',
  'oifde.qarecord.inputparam.originalAnswer': 'คําตอบดั้งเดิม',
  'oifde.service.invoke.workdays.Monday': 'วันจันทร์',
  'oifde.service.invoke.workdays.Tuesday': 'วันอังคาร',
  'oifde.service.invoke.workdays.Wednesday': 'วันพุธ',
  'oifde.service.invoke.workdays.Thursday': 'วันพฤหัสบดี',
  'oifde.service.invoke.workdays.Friday': 'วันศุกร์',
  'oifde.service.invoke.workdays.Saturday': 'วันเสาร์',
  'oifde.service.invoke.workdays.Sunday': 'วันอาทิตย์',
  'oifde.transdata.get.example': 'ตัวอย่างเช่น หากข้อมูลที่เกี่ยวข้องคือ {\'{\'}"data":{\'{\'}"test": "111"{\'}\'}{\'}\'} คุณสามารถป้อน data.test ในค่าตัวแปรเพื่อให้ได้ข้อมูล "111"',
  'oifde.service.invoke.basicAvatarReplyService.name': 'Avatar ตอบกลับ',
  'oifde.service.invoke.basicAvatarReplyService.actionType': 'การดําเนินการ',
  'oifde.service.invoke.basicAvatarReplyService.start': 'ริเริ่ม',
  'oifde.service.invoke.basicAvatarReplyService.image': 'รูปภาพ',
  'oifde.service.invoke.basicAvatarReplyService.avatarImage': 'ภาพอวตาร',
  'oifde.service.invoke.basicAvatarReplyService.ttsSpeaker': 'ลําโพง',
  'oifde.service.invoke.basicAvatarReplyService.compositeVideoSetting': 'การกําหนดค่าวิดีโอแบบคอมโพสิต',
  'oifde.service.invoke.basicAvatarReplyService.compositeVideoSetting.desc': 'การกําหนดค่าวิดีโอแบบคอมโพสิต ซึ่งส่งผลโดยตรงต่อเอฟเฟกต์การแสดงผลของบุคคลดิจิทัล เปิดใช้งานฟังก์ชันนี้เมื่อจําเป็น',
  'oifde.service.invoke.basicAvatarReplyService.imageSetting': 'การตั้งค่ารูปภาพและวิดีโอ',
  'oifde.service.invoke.basicAvatarReplyService.action': 'การดําเนินการ',
  'oifde.service.invoke.basicAvatarReplyService.frontImage': 'ภาพด้านหน้า',
  'oifde.service.invoke.basicAvatarReplyService.backendImage': 'อิมเมจแบ็กเอนด์',
  'oifde.service.invoke.basicAvatarReplyService.frontVideo': 'วิดีโอด้านหน้า',
  'oifde.service.invoke.basicAvatarReplyService.backendVideo': 'วิดีโอแบ็กเอนด์',
  'oifde.service.invoke.basicAvatarReplyService.xPosition': 'อับซิสซา',
  'oifde.service.invoke.basicAvatarReplyService.yPosition': 'อุปสมบท',
  'oifde.service.invoke.basicAvatarReplyService.scale': 'มาตราส่วน',
  'oifde.service.invoke.basicAvatarReplyService.beginTime': 'เวลาเริ่มต้น (มิลลิวินาที)',
  'oifde.service.invoke.basicAvatarReplyService.duration': 'ระยะเวลาการแสดงผล (มิลลิวินาที)',
  'service.invoke.virtualHumanConfig': 'การกําหนดค่ามนุษย์เสมือน',
  'service.virtualHuman.videoParam': 'พารามิเตอร์ในการตัดต่อวิดีโอ',
  'service.virtualHuman.captions': 'คําบรรยาย',
  'service.invoke.asrAdvancedSetting.open': 'เปิดใช้งาน',
  'service.invoke.asrAdvancedSetting.close': 'ปิดใช้งาน',
}
