<template>
  <div>
    <sweet-row id="queryCondition" v-if="langOptions.length > 0 && callType != 5 && callType != 10">
      <!-- 普通双呼和ita双呼不展示发送信息按钮 -->
      <sweet-col>
        <sweet-dropdown class="aicc-button-icon">
          <sweet-button type="primary">
            {{ i18n('ccm.contact.detail.sendmessage')
            }}<sweet-icon class="el-icon--right"><arrow-down /></sweet-icon>
          </sweet-button>
          <template #dropdown>
            <sweet-dropdown-menu>
              <sweet-dropdown-item
                v-for="(langaue, index) in langOptions"
                :key="index"
                v-text="langaue.name"
                @click="changLanguage(langaue)"
              ></sweet-dropdown-item>
            </sweet-dropdown-menu>
          </template>
        </sweet-dropdown>
      </sweet-col>
    </sweet-row>
    <div class="send-record-content">
      <sweet-row>
        <sweet-col>
          <aicc-table
            class="aicc-table table-height"
            :cell-class-name="getCellStyleClass"
            :tableData="tableData"
            :tableColumns="tableColumns"
            :tableTools="{ showPagination: false, columnConfig: false }"
            ref="refAiccTable"
          >
            <template #test1="scope">
              <sweet-button text @click="viewMessageContent(scope.row)" type="primary">{{
                i18n('ccm.contact.detail.view.message.content')
              }}</sweet-button>
            </template>
          </aicc-table>
        </sweet-col>
      </sweet-row>
      <sweet-pagination
        :total="paginationConfig.total"
        :page-sizes="[10, 20, 50, 100]"
        :current-page="paginationConfig.currentPage"
        layout="total, prev, pager, next"
        :page-size="paginationConfig.pageSize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>

  <message-content
    :snd-msg-id="sndMsgId"
    :media-type="mediaType"
    v-if="isShowMessageContent"
    :is-show="isShowMessageContent"
    @close="() => (isShowMessageContent = false)"
  ></message-content>
  <!--发送whatsapp消息-->
  <SendWhatsappDialog v-if="whatsappDialogVisible"
              :whatsappDialogVisible="whatsappDialogVisible"
              :audioVideoCaller="audioVideoCaller" 
              :audioVideoCallId="whatsAppCallIdAndSerialNo"
              @handleWhatsappClose="handleWhatsappClose"/>

  <!--发送短信消息-->
  <SendSMSNotificationDialog v-if="SMSDialogVisible"
                             :SMSDialogVisible="SMSDialogVisible"
                             :callInfo="callInfo"
                             @handleSMSClose="handleSMSDialogClose"/>
</template>

<script setup lang="ts">
import { defineProps, ref, onMounted, getCurrentInstance, reactive, toRefs, computed } from 'vue'
import {
  checkThirdpartAuths,
  handleMessageSendRecordPagination
} from '@/views/ccmanagement/ccma-api'
import cookieUtils from '@/utils/cookie'
import MessageContent from '@/views/ccmanagement/pages/contract-record-detail/message-content.vue'
import SendWhatsappDialog from '@/views/ccmanagement/pages/notification/SendWhatsapppDialog.vue'
import SendSMSNotificationDialog from '@/views/ccmanagement/pages/notification/send-sms-notification-dialog/SendSMSNotificationDialog.vue'

// 国际化封装
const {
  appContext: {
    config: { globalProperties }
  }
} = getCurrentInstance() as any
const i18n = (param: string, ...args: Array<any>): string => {
  return globalProperties.$t(param, args)
}

const tableColumns = reactive([
  {
    label: i18n('ccm.agent.contact.mediaType'),
    prop: 'mediaTypeI18',
    display: true,
    configFixed: true,
    description: ''
  },
  {
    label: i18n('ccm.contact.detail.receiver'),
    prop: 'msgRecipient',
    display: true,
    configFixed: true,
    description: ''
  },
  {
    label: i18n('ccm.contact.detail.sending.time'),
    prop: 'sendTime',
    display: true,
    configFixed: true,
    description: ''
  },
  {
    label: i18n('ccm.agent.label.operate'),
    prop: 'result',
    display: true,
    configFixed: true,
    description: '',
    slot: 'test1'
  }
])

let total: any = 0
let limit: number = 5
let selectedPageNumber = 1
const tableData = ref<Array<any>>([]) // 表数据
const whatsappDialogVisible = ref(false);
const audioVideoCaller = ref('');
const audioVideoCallId = ref('');
const whatsAppCallIdAndSerialNo = ref('');
const SMSDialogVisible = ref(false);
const callInfo = reactive({}) // 对话对象
const handleSMSDialogClose = () => {
  SMSDialogVisible.value = false;
}
onMounted(() => {
  searchModel.limit = 10
  searchModel.offset = 0
  checkAuth()
  search()
})

const props = defineProps({
  callSerialNo: String,
  countId: String,
  callType: String,
  msgData: Object
})

const langOptions = ref<Array<any>>([])

const info = toRefs(props)
const sndMsgId = ref<String>()
const mediaType = ref<String>()
const isShowMessageContent = ref<Boolean>(false)

/**
 * UX改版后的查询定义
 */
declare interface SearchModel {
  callserialno: string
  locale: string // 国际化
  beginTime?: number | string | Date //查询起始时间，非工作台跳转时必填
  offset: number | string // 偏移量
  limit: number | string // 每页条数
}

const searchModel = reactive<SearchModel>({
  callserialno: '',
  beginTime: '',
  locale: cookieUtils.getCookie('u-locale'),
  limit: 10,
  offset: 0
})

const search = () => {
  handleMessageSendRecord(searchModel)
}

const handleWhatsappClose = () => {
  whatsappDialogVisible.value = false;
}

// 查询是否有客户接触中外发通知的权限
const checkAuth = () => {
  if (window.$Scope && window.$Scope.$authids) {
    initOptions(window.$Scope.$authids);
  } else {
    checkThirdpartAuths().then((resp: any) => {
      initOptions(resp);
    })
  }
}

const initOptions = (resp: any) => {
  const userId = cookieUtils.getCookie('user_id')
  const tmp = ref<Array<any>>([])
  if (resp && userId == info.countId.value) {
    if (resp['permCodes'].indexOf('80031001014008') != -1) {
      tmp.value.push({
        type: 'sms',
        name: i18n('agentconsole.header.title.send.sms.notification')
      })
    }
    if (resp['permCodes'].indexOf('80031001014009') != -1) {
      tmp.value.push({
        type: 'whatsapp',
        name: i18n('agentconsole.header.title.send.whatsapp.notification')
      })
    }
    if (resp['permCodes'].indexOf('80031001014010') != -1) {
      tmp.value.push({
        type: 'email',
        name: i18n('agentconsole.header.title.send.email.notification')
      })
    }
  }
  langOptions.value = tmp.value;
}

const paginationConfig = reactive({
  pageSize: 10,
  total: 0,
  currentPage: 1
})

const handleSizeChange = (pageSize: number) => {
  paginationConfig.pageSize = pageSize
  searchModel.limit = pageSize
  searchModel.offset = offset.value
  search()
}
const handleCurrentChange = (currentPage: number) => {
  paginationConfig.currentPage = currentPage
  searchModel.limit = paginationConfig.pageSize
  searchModel.offset = offset.value
  search()
}

const offset = computed(() => (paginationConfig.currentPage - 1) * paginationConfig.pageSize) // 偏移量

const callSerialno = ref<string>()
const messageRecordModel = ref<Array<any>>([])

const I18MediaType = reactive({
  '0': i18n('ccm.agent.contact.email'),
  '1': i18n('ccm.satisfactionconfig.message.sms'),
  '2': i18n('ccm.agent.contact.whatsapp')
})

/**
 * 消息发送记录分页
 */
const handleMessageSendRecord = (searchModel) => {
  let limitValue = searchModel.limit
  let offsetValue = searchModel.offset
  let param: any = {}
  param = { limit: limitValue, offset: offsetValue, callSerialno: info.callSerialNo.value }
  handleMessageSendRecordPagination(param).then((resp: any) => {
    paginationConfig.total = resp['count']
    messageRecordModel.value = resp['contactSndMsgList']
    for (let i = 0; i < messageRecordModel.value.length; i++) {
      messageRecordModel.value[i].sendTime = globalProperties.$TimeUtils.transTime(
        messageRecordModel.value[i].sendTime
      )
      messageRecordModel.value[i].mediaTypeI18 = I18MediaType[messageRecordModel.value[i].mediaType]
    }
    tableData.value = messageRecordModel.value
  })
}

const viewMessageContent = (param) => {
  sndMsgId.value = param.sndMsgId
  mediaType.value = param.mediaType
  isShowMessageContent.value = true
}

const changLanguage = (param: any) => {
  transToSendNotification(param.type)
}

const transToSendNotification = (type: string) => {
  let audioVideoCallerTemp = ''
  if (!info.msgData) {
    return
  }
  audioVideoCallerTemp = getSendMsgDefaultNumber(audioVideoCaller)
  audioVideoCaller.value = getSendMsgDefaultNumber(audioVideoCaller);

  let audioVideoCallIdTemp = info.msgData.value.callId
  audioVideoCallId.value = info.msgData.value.callId;
  if (!audioVideoCaller.value || !audioVideoCallId.value) {
    return
  }
  const key = 'transToSendNotification' + type
  let detailUrl
  if ('whatsapp' == type) {
    whatsAppCallIdAndSerialNo.value = audioVideoCallId.value + ";" + info.callSerialNo.value;
    whatsappDialogVisible.value = true;
    return;
  } else if ('sms' == type) {
    callInfo.audioVideoCaller = audioVideoCaller.value
    callInfo.audioVideoCallId = audioVideoCallId.value
    callInfo.callSerialNo = info.callSerialNo.value
    SMSDialogVisible.value = true;
    return;
  } else if ('email' == type) {
    window["showTab"]({
      title: i18n('ccmessaging.chat.agentconsole.sendEmail'),
      id: "SendEmailMsg",
      name: 'SendEmailMsg',
      query: {
        audioVideoCaller: audioVideoCaller.value,
        audioVideoCallId: audioVideoCallId.value,
        audioVideoType: type,
        callSerialNo: info.callSerialNo.value
      }
    })
    return;
  } else {
    detailUrl =
      '/service-cloud/ccnotification/page/send-message-audioandvideo/sendaudiovideomessage.html?'
    detailUrl += '&audioVideoCaller=' + audioVideoCallerTemp
    detailUrl += '&audioVideoCallId=' + audioVideoCallIdTemp
    detailUrl += '&audioVideoType=' + type
  }
  top?.window.$BES.$Portal.tabpanel.createTabItemByConf(
    key,
    i18n('agentconsole.header.title.send.notification'),
    detailUrl
  )
}

const getSendMsgDefaultNumber = (audioVideoCaller: any) => {
  const msgData = info.msgData
  // 5：媒体类型为多媒体
  if (msgData.value.mediaAbility == '5') {
    return '6'
  }
  if (msgData.value.mediaTypeId == '0') {
    // 呼入
    audioVideoCaller = msgData.value.caller
  } else if (isInner(msgData.value.mediaTypeId)) {
    //内部两方三方多方通话
    audioVideoCaller = '6'
  } else {
    audioVideoCaller = msgData.value.called
  }
  return audioVideoCaller
}

const isInner = (callType: String) => {
  if (callType == '6' || callType == '51' || callType == '52' || callType == '53') {
    //6:内部通话 51:内部两方咨询；52：内部三方咨询;53：多方通话
    return true
  }
  return false
}
</script>

<style lang="less" scoped>
.aicc-button-icon {
  float: right;
  padding: 1.5rem;
}
// 88：顶部栏;  56：下沉页路由条; 24：padding,35:tab页签header
.send-record-content {
  margin-top: 33px;
  overflow: auto;
  height: calc(100vh - 88px - 56px - 24px - 35px - 130px);
}
body.RTL {
  .aicc-button-icon {
    float: left;
  }
}
</style>