import request from '@/utils/request'

export const ccmeQueryMultiMediaLibraryById = data => {
  return request({
    url: '/service-cloud/rest/ccmessaging/v1/multiMediaLibrary/queryById',
    method: 'get',
    data
  })
}

// 从配置文件读取出腾讯地图的api
export const ccmeGetLocationUrl = data => {
  return request({
    url: '/service-cloud/rest/ccmessaging/v1/medias/getLocationUrl',
    method: 'get',
  })
}

// 从配置文件读取emoji图片路径
export const ccmeGetEmojiPath = data => {
  return request({
    url: '/service-cloud/rest/ccmessaging/v1/userInfo/getEmojiPath',
    method: 'get',
  })
}

export const ccmeQueryEmail = data => {
  return request({
    url: '/service-cloud/rest/ccmessaging/v1/emailchannel/queryEmail',
    method: 'post',
    data
  })
}
export const ccmeTenantDownLoadStream = data => {
  return request({
    url: '/service-cloud/rest/ccmessaging/v1/fileservice/tenantDownLoadStream',
    method: 'post',
    data
  })
}

export const ccmeDownloadAgentDocumentFromAgent = data => {
  return request({
    url: '/service-cloud/rest/ccmessaging/v1/fileservice/downloadAgentDocumentFromAgent',
    method: 'post',
    data
  })
}

// 通过objectKey读取文件流
export const ccmeAgentDownLoadStream = data => {
  return request({
    url: "/service-cloud/rest/ccmessaging/v1/fileservice/agentDownLoadStream",
    method: 'post',
    data
  })
}

/**
 * 获取用户权限信息
 *
 * @param tenantId 租户id
 * @param userId 用户id
 * @returns {Promise<*>}
 */
export const sumGetPerms = (tenantId, userId) => {
  return request({
    url: `/service-cloud/rest/sum/v2/tenantSpaces/${tenantId}/users/${userId}/perms`,
    method: "get"
  })
}

export const querySysMessageTemplate = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/messageTemplate/querySysMessageTemplate',
    method: "post",
    data,
  })
}

// 查询字典
export const queryDataDictionary = data => {
  return request({
    url: "/service-cloud/u-route/dictionary/queryDataDictionary",
    method: "get",
    data
  })
}

// 查询wahtsapp渠道
export const queryWhatsAppChannelList = () => {
  return request({
    url: "/service-cloud/rest/ccmessaging/v1/CardInfo/queryWhatsAppChannelList",
    method: "get"
  })
}

// 根据渠道查模板卡片消息
export const getWhatsAppTemplateMsgByChannelId = data => {
  return request({
    url: "/service-cloud/rest/ccmessaging/v1/whatsapptemplatemsg/getWhatsAppTemplateMsgByChannelId",
    method: "post",
    data
  })
}

// 根据渠道查模板卡片消息
export const queryMultiMediaLibraryListByTypeCommon = data => {
  return request({
    url: "/service-cloud/rest/ccmessaging/v1/multiMediaLibrary/queryMultiMediaLibraryListByTypeCommon",
    method: "post",
    data
  })
}

// 查短信模板
export const queryMessageTemplate = data => {
  return request({
    url: "/service-cloud/rest/ccnotification/v1/messageTemplate/queryMessageTemplate",
    method: "post",
    data
  })
}

// 查询短信网关模板的变量
export const queryVariableByTemplateId = data => {
  return request({
    url: "/service-cloud/rest/ccnotification/v1/variable/queryVariableByTemplateId",
    method: "post",
    data
  })
}

// 查发送号码
export const querySmsNumberConfigList = data => {
  return request({
    url: "/service-cloud/rest/ccnotification/v1/smsnumbercofig/querySmsNumberConfigList",
    method: "post",
    data
  })
}

// 查询租户特性信息
export const queryFeatureInfo = data => {
  return request({
    url: "/service-cloud/rest/ipccprovision/v1/vcallcenter/queryFeatureInfo",
    method: "post",
    data
  })
}