export default {
  "ikbs.constableTmp.config" : "กําหนดค่า",
  "ikbs.constableTmp.status.draft" : "แบบร่าง",
  "ikbs.cntTmp.import.rowNumOver" : "สามารถนําเข้าข้อมูลได้สูงสุด 10 รายการ",
  "ikbs.column.unlock.message.warning" : "ข้อมูล",
  "ikbs.knowledge.label.abstract" : "สรุปย่อ",
  "ikbs.properties.add.information" : "เพิ่มแอตทริบิวต์ขยาย",
  "ikbs.contTmp.exception.maxCountPerTenant" : "ผู้เช่าสามารถมีเทมเพลตได้สูงสุด 500 แบบ",
  "ikbs.knowledge.properties.operation.upMove" : "ขึ้น",
  "ikbs.column.delete.selectNoColumnKngToDelete" : "ไม่ได้เลือกหมวดหมู่ความรู้",
  "ikbs.tips.canNotAddColumnKngOnHistoryColumn" : "ไม่สามารถเพิ่มความรู้ในหมวดหมู่ประวัติได้",
  "ikbs.knowledge.properties.label.inputWay" : "ประเภทอินพุต",
  "ikbs.contentTmp.exception.tmpContentAndEditStandard" : "เนื้อหาเทมเพลตและข้อมูลจําเพาะการแก้ไขไม่สามารถเว้นว่างในเวลาเดียวกันได้",
  "ikbs.knowledge.label.uploadSingleFile" : "อัปโหลดได้เพียงเอกสารแนบเดียวต่อครั้ง",
  "ikbs.knowledge.tip.operateSuccess" : "การดำเนินการสำเร็จ",
  "ikbs.constableTmp.operator.lt" : "น้อยกว่า",
  "ikbs.enter.search.condition" : "โปรดป้อนเงื่อนไขการค้นหา",
  "ikbs.search.label.sortByDefault" : "การเรียงเริ่มต้น",
  "ikbs.knowledge.recycle.coluKngType" : "ประเภท",
  "ikbs.consult.table" : "แบบฟอร์มคําปรึกษา",
  "ikbs.knowledge.label.path" : "เส้นทาง",
  "ikbs.column.release.message.fail" : "การเผยแพร่ล้มเหลว",
  "ikbs.column.knowledge.columnInformation" : "เพิ่มหมวดหมู่",
  "ikbs.constableTmp.operator.le" : "น้อยกว่าหรือเท่ากับ",
  "ikbs.knowledge.service.common" : "ทั่วไป",
  "ikbs.knowledge.recycle.select.knowledgeType" : "ประเภทความรู้",
  "ikbs.tips.propertyDictValueAtLeastOne.warning" : "แอตทริบิวต์ขยายต้องมีค่าพจนานุกรมอย่างน้อยหนึ่งค่า",
  "ikbs.column.displayflag.hide" : "ไม่",
  "ikbs.column.knowledge.button.add" : "ใหม่",
  "ikbs.column.knowledge.modify.properties" : "แก้ไขแอตทริบิวต์เป็นชุด",
  "ikbs.knowledge.release.public" : "เผยแพร่โดยตรง",
  "ikbs.knowledge.label.selectProperty" : "เลือก",
  "ikbs.contentTmp.status.draft" : "แบบร่าง",
  "ikbs.knowledge.label.serviceStatus" : "สถานะธุรกิจ",
  "ikbs.knowledge.service.update" : "ธุรกิจที่อัปเดตแล้ว",
  "ikbs.column.serviceStatus.newService" : "ธุรกิจใหม่",
  "ikbs.knowledge.label.answer" : "คำตอบ",
  "ikbs.constableTmp.reset" : "รีเซ็ต",
  "ikbs.knowledge.label.modifyTime" : "อัปเดตล่าสุดเมื่อ",
  "ikbs.column.type.historyMark" : "ประวัติ",
  "ikbs.kngCnt.cntTmp.icon.tip" : "เทมเพลตเนื้อหา",
  "ikbs.knowledge.label.currentVersion" : "เวอร์ชันปัจจุบัน",
  "ikbs.columntype.column" : "หมวดหมู่",
  "ikbs.enter.search.keywords" : "ป้อนคําค้นหา",
  "ikbs.contentTmp.delete.fail" : "การลบล้มเหลว",
  "ikbs.column.label.coluKngMaintaining" : "กลุ่มการบํารุงรักษา",
  "ikbs.knowledge.label.kngAttachmentDownload" : "ดาวน์โหลดเอกสารแนบของหมวดหมู่ความรู้",
  "ikbs.search.label.updateEndTime" : "เวลาสิ้นสุดการอัปเดต",
  "ikbs.knowledgeaudit.label.pass" : "อนุมัติ",
  "ikbs.knowledge.tip.selectColumn" : "เลือกหมวดหมู่",
  "ikbs.constableTmp.searchFlag" : "Search เกณฑ์หรือไม่",
  "ikbs.properties.add.cancel" : "ยกเลิก",
  "ikbs.column.needAudit.yes" : "ใช่",
  "ikbs.knowledge.properties.operation.enable" : "เปิดใช้งาน",
  "ikbs.column.type.normal" : "หมวดหมู่ปกติ",
  "ikbs.knowledge.label.passProcecWay" : "เมื่อหมดอายุ",
  "ikbs.column.knowledge.MoveType" : "ตำแหน่งการปรับ",
  "ikbs.confirm.delete.by.select" : "คุณแน่ใจหรือไม่ว่าต้องการลบเร็กคอร์ดที่เลือก",
  "ikbs.knowledge.label.relaKngs" : "การเชื่อมโยงความรู้",
  "ikbs.constableTmp.fieldName" : "สนาม",
  "ikbs.column.copy.message.warning" : "ข้อมูล",
  "ikbs.column.deletePermanently.message.comfirm" : "ยืนยัน",
  "ikbs.column.moveto.front" : "ด้านหน้า",
  "ikbs.kbshome.button.orderByTime" : "เวลา",
  "ikbs.constableTmp.moveDown" : "ข้างล่าง",
  "ikbs.tips.canNotAddKngOnRootColumn" : "ไม่สามารถเพิ่มความรู้ในหมวดหมู่รากได้",
  "ikbs.column.knowledge.button.up" : "ขึ้น",
  "ikbs.constableTmp.modify" : "แก้ไข",
  "ikbs.constableTmp.operator.gt" : "มากขึ้น",
  "ikbs.constableTmp.operator.include" : "รวมไปถึง",
  "ikbs.knghisverman.label.revert" : "คืนค่า",
  "ikbs.constableTmp.plsSelectModifyBeginTime" : "ตั้งเวลาเริ่มการแก้ไข",
  "ikbs.knowledgeaudit.label.submitter" : "ส่งโดย",
  "ikbs.column.label.kngExtended" : "แอตทริบิวต์ขยาย",
  "ikbs.constableTmp.operator.ge" : "มากกว่าหรือเท่ากับ",
  "ikbs.knowledge.label.listProp" : "รายการแบบเลื่อนลง",
  "ikbs.knowledgeaudit.title.auditor" : "ตรวจสอบโดย",
  "ikbs.knowledge.recycle.pleaseSelect" : "-เลือก-",
  "ikbs.constableTmp.tab.addTitle" : "การเพิ่มเทมเพลต",
  "ikbs.knghisverman.tab.title.confirm" : "ยืนยัน",
  "ikbs.column.knowledge.button.move" : "ย้าย",
  "ikbs.favorite.msg.plsSelectDir" : "เลือกไดเรกทอรีที่จะลบ",
  "ikbs.knowledge.tip.fileNameError" : "ล้มเหลวในการตรวจสอบชื่อแฟ้ม",
  "ikbs.knowledge.message.uploadImgSizeError" : "ขนาดภาพต้องไม่เกิน 10 MB",
  "ikbs.knowledge.display.no" : "ไม่",
  "ikbs.properties.inputway.select" : "รายการแบบเลื่อนลง",
  "ikbs.favorite.title.afterModifiedKngName" : "หลังเปลี่ยน",
  "ikbs.contentTmp.status" : "สถานะแม่แบบ",
  "ikbs.column.topMove.message.warning" : "ข้อมูล",
  "ikbs.constableManage.fieldOprator" : "ผู้ประกอบการ",
  "ikbs.constableTmp.modifyEndTime" : "เวลาสิ้นสุดการแก้ไข",
  "ikbs.cntTmp.import.noData" : "ไฟล์ที่อัปโหลดไม่มีข้อมูลที่จะนําเข้า",
  "ikbs.addtype.label.knowledge" : "เพิ่มความรู้",
  "ikbs.contentTemp.export.tempname" : "ชื่อแม่แบบ",
  "ikbs.constableTmp.operator.eq" : "เท่ากับ",
  "ikbs.knowledge.tip.success" : "สำเร็จ",
  "ikbs.knowledge.label.radioProp" : "กล่องตัวเลือก",
  "ikbs.intelligent.search" : "การค้นหาอัจฉริยะ",
  "ikbs.contentTmp.contentName" : "ชื่อแม่แบบ",
  "ikbs.kbshome.tab.home" : "หน้าหลักของฐานความรู้",
  "ikbs.column.passProcessWay.mark" : "ทำเครื่องหมาย",
  "ikbs.column.modify.modifyValidTimeCheckError" : "เวลาเริ่มต้นใช้งานต้องไม่เกินเวลาสิ้นสุดของระยะเวลาที่ใช้ได้",
  "ikbs.constableTmp.delete.selectTmp.confirm" : "คุณแน่ใจหรือไม่ว่าต้องการลบ?",
  "ikbs.contentTmp.ModifyEndTime" : "สิ้นสุดการแก้ไข",
  "ikbs.knowledge.recycle.beyondColuPath" : "ไดเรกทอรี",
  "ikbs.favorite.msg.canNotSelectRoot" : "ไม่สามารถลบไดเรกทอรีรากได้ เลือกอันอื่น",
  "ikbs.column.knowledge.button.modify" : "แก้ไข",
  "ikbs.knowledgeaudit.message.plsSelectBeginTime" : "เลือกเวลาเริ่มต้นของงาน",
  "ikbs.contentTmp.tab.addTitle" : "การเพิ่มเทมเพลต",
  "ikbs.column.clearRecycle.comfirm" : "คุณแน่ใจหรือไม่ว่าต้องการทิ้งขยะในถังขยะรีไซเคิล",
  "ikbs.knowledgeaudit.message.plsSelectEndTime" : "เลือกเวลาสิ้นสุดของงาน",
  "ikbs.contTmp.importTmp.popup.title" : "นําเข้าแม่แบบ",
  "ikbs.knowledge.label.uploadFileTypeError" : "เอกสารแนบต้องอยู่ในรูปแบบ DOC, DOCX, PPT, PPTX, XLS, XLSX, PDF, PNG, JPG หรือ GIF เท่านั้น",
  "ikbs.column.modify.selectModifyColumnKngNumError" : "เลือกรายการความรู้สำหรับการแก้ไขได้เพียงรายการเดียวเท่านั้น",
  "ikbs.column.knowledge.create" : "ใหม่",
  "ikbs.confirm.delete" : "คุณแน่ใจหรือไม่ว่าต้องการลบเร็กคอร์ดนี้",
  "ikbs.column.operation.message.warning" : "ข้อมูล",
  "ikbs.contentTmp.copy" : "สําเนา",
  "ikbs.column.label.passProcessWay" : "เมื่อหมดอายุ",
  "ikbs.knowledgeaudit.label.auditStatus" : "สถานะ",
  "ikbs.favorite.tab.message.isDeleteFavoriteKng" : "คุณแน่ใจหรือไม่ว่าต้องการลบรายการความรู้ออกจากรายการโปรด",
  "ikbs.select.consultation.form" : "กรุณาเลือกแบบฟอร์มคําปรึกษา",
  "ikbs.knowledge.label.sendEmail" : "ส่งอีเมล",
  "ikbs.tips.operation.success" : "การดำเนินการสำเร็จ",
  "ikbs.column.knowledge.KngStatus" : "สถานะ",
  "ikbs.contentTmp.add" : "เพิ่ม",
  "ikbs.properties.edit.information" : "แก้ไขแอตทริบิวต์ขยาย",
  "ikbs.column.knowledge.title.moveTo" : "ปรับไปยัง",
  "ikbs.column.knowledge.button.more" : "เพิ่มเติม",
  "ikbs.knowledge.properties.operation.downMove" : "ลง",
  "ikbs.kbshome.label.kngBase" : "ฐานความรู้",
  "ikbs.confirm.delete.by.condition" : "คุณแน่ใจหรือไม่ว่าต้องการลบระเบียนตามเงื่อนไข",
  "ikbs.column.serviceStatus.normal" : "ทั่วไป",
  "ikbs.constableTmp.comfirm" : "ยืนยัน",
  "ikbs.tips.moveColumnKnowledgeOperation.warning" : "ไม่ได้เลือกหมวดหมู่",
  "ikbs.column.serviceStatus.updateService" : "ธุรกิจที่อัปเดตแล้ว",
  "ikbs.knowledge.tip.cannotCompare" : "มีเพียงสองความรู้ทั่วไปเท่านั้นที่สามารถเปรียบเทียบได้",
  "ikbs.cntTmp.import.importSuccess" : "นําเข้าสําเร็จ",
  "ikbs.column.label.coluType" : "ประเภทหมวดหมู่",
  "ikbs.knowledge.label.submit" : "ส่ง",
  "ikbs.knowledge.message.uploadFileSizeError" : "ขนาดไฟล์ต้องไม่เกิน 10 MB",
  "ikbs.knowledge.label.deleteProblem" : "ลบ Q&A",
  "ikbs.knowledge.recycle.select.columnType" : "ประเภทคอลัมน์",
  "ikbs.column.delete.property.comfirm" : "คุณแน่ใจหรือไม่ว่าต้องการลบแอตทริบิวต์",
  "ikbs.knowledge.placeholder.keyword" : "กรอกคำสำคัญที่คั่นด้วยเครื่องหมายอัฒภาค (;)",
  "ikbs.kngCnt.cntTmp.popup.title" : "เลือกเทมเพลตเนื้อหา",
  "ikbs.knowledge.properties.operation.delete" : "ลบ",
  "ikbs.column.knowledge.compareAdd" : "ใหม่",
  "ikbs.knowledgeaudit.label.waitForAudit" : "รอการตรวจสอบ",
  "ikbs.constableTmp.cnstTblTmpDesc" : "คําอธิบายแม่แบบ",
  "ikbs.column.label.colukngname" : "ชื่อหมวดหมู่",
  "ikbs.knowledgeaudit.label.batchAuditReject" : "ปฏิเสธเป็นชุด",
  "ikbs.constableTmp.display.no" : "ไม่",
  "ikbs.title.tips" : "ข้อมูล",
  "ikbs.column.delete.selectNoColumn" : "ไม่ได้เลือกหมวดหมู่",
  "ikbs.column.knowledge.title.move" : "ย้าย",
  "ikbs.column.isInheritProp.no" : "ไม่",
  "ikbs.column.message.plsSelectModifyEndTime" : "เลือกเวลาสิ้นสุด",
  "ikbs.column.label.servicesVaildStartTime" : "ระยะเวลาที่ใช้งานได้ทางธุรกิจเริ่มต้น",
  "ikbs.knowledge.properties.operation.edit" : "แก้ไข",
  "ikbs.knowledge.pass.mark" : "ทำเครื่องหมาย",
  "ikbs.column.modify.modifyServiceTimeCheckError" : "เวลาเริ่มต้นของระยะเวลาที่ใช้งานได้ทางธุรกิจต้องไม่เร็วกว่าเวลาสิ้นสุด",
  "ikbs.column.modify.properties.batch.message.warning" : "ข้อมูล",
  "ikbs.cntTmp.import.error.oversize" : "ขนาดไฟล์ไม่เกิน 20 MB",
  "ikbs.knowledgeaudit.label.cancel" : "ยกเลิก",
  "ikbs.search.label.keyword" : "คำสำคัญ",
  "ikbs.knghisverman.tab.message.deleteSuccess" : "การลบสำเร็จ",
  "ikbs.search.label.sortByClick" : "คลิก",
  "ikbs.knowledge.tip.fileInjectionError" : "ไม่สามารถตรวจสอบเนื้อหาของแฟ้มได้",
  "ikbs.knowledge.label.correlative" : "เชื่อมโยง",
  "ikbs.column.needAudit.no" : "ไม่",
  "ikbs.column.unlock.message.success" : "การปลดล็อคสำเร็จ",
  "ikbs.contentTmp.ModifyStartTime" : "เริ่มที่แก้ไขแล้ว",
  "ikbs.konwledgetype.question" : "ความรู้ Q&A",
  "ikbs.knowledge.service.timeout" : "หมดอายุแล้ว",
  "ikbs.column.topMove.message.success" : "ปักหมุดสำเร็จแล้ว",
  "ikbs.contentTmp.Modifier" : "แก้ไขโดย",
  "ikbs.knowledgeaudit.label.error" : "ข้อผิดพลาด",
  "ikbs.knowledge.exception.AnswerNum.error" : "คําถามหนึ่งสามารถมีคําตอบได้ไม่เกิน 10 ข้อ",
  "ikbs.knowledgeaudit.title.submitTime" : "ส่งเมื่อ",
  "ikbs.column.label.colukngpath" : "เส้นทางหมวดหมู่",
  "ikbs.knowledge.label.saveAndNext" : "บันทึกและ NextStep",
  "ikbs.constableTmp.save" : "ประหยัด",
  "ikbs.knghisverman.label.operation" : "การดำเนินการ",
  "ikbs.column.label.summaryInfo" : "สรุป",
  "ikbs.knowledge.recycle.button.reset" : "รีเซ็ต",
  "ikbs.knowledge.recycle.button.delete" : "ลบ",
  "ikbs.constableTmp.status.invalid" : "ยกเลิก",
  "ikbs.cntTmp.import.contTmpNameEditStandEmpty" : "เนื้อหาเทมเพลตและข้อมูลจําเพาะการแก้ไขไม่สามารถเว้นว่างในเวลาเดียวกันได้",
  "ikbs.column.knowledge.button.copy" : "คัดลอก",
  "ikbs.knowledge.properties.properties.inputWay" : "ประเภทอินพุต",
  "ikbs.column.knowledge.compareModified" : "แตกต่าง",
  "ikbs.constableTmp.innerFieldFlag" : "สนามในตัว",
  "ikbs.column.passProcessWay.move" : "ย้ายไปยังประวัติ",
  "ikbs.knowledge.msg.fileUpload" : "อัปโหลดไฟล์ไปยังความรู้",
  "ikbs.column.unlock.message.failed" : "การปลดล็อคล้มเหลว",
  "ikbs.cntTmp.import.editStandardOversize" : "ข้อกําหนดการแก้ไขต้องไม่เกิน 400 ตัวอักษร",
  "ikbs.contentTmp.save" : "ประหยัด",
  "ikbs.knowledge.check.kngName" : "ชชื่อความรู้หรือชื่อคอลัมน์ต้องไม่มี characters: /\\:*?<>| พิเศษต่อไปนี้",
  "ikbs.contentTmp.tab.copyTitle" : "การทําสําเนา",
  "ikbs.column.downMove.message.fail" : "ไม่สามารถย้ายลงได้",
  "ikbs.column.knowledge.compareDel" : "ไม่สามารถใช้ได้",
  "ikbs.knowledge.service.hot" : "เป็นที่นิยม",
  "ikbs.contentTmp.modify.fail" : "การแก้ไขล้มเหลว",
  "ikbs.knowledge.label.servicesStartTime" : "ระยะเวลาที่ใช้งานได้ทางธุรกิจเริ่มต้น",
  "ikbs.constableTmp.modifyTime" : "แก้ไขที่",
  "ikbs.knowledge.index.no" : "ไม่",
  "ikbs.kbshome.label.ClickList" : "คลิกสูงสุด",
  "ikbs.knowledge.properties.properties.status" : "สถานะ",
  "ikbs.knowledge.properties.dictValue" : "ค่าพจนานุกรม",
  "ikbs.column.delete.message.warning" : "ข้อมูล",
  "ikbs.column.delete.message.fail" : "การลบล้มเหลว",
  "ikbs.constableTmp.cnstTblName" : "ตารางให้คําปรึกษา",
  "ikbs.cntTmp.import.error.importType" : "เลือกโหมดการนําเข้า",
  "ikbs.contTmp.import.popup.title" : "นําเข้าแม่แบบ",
  "ikbs.column.title.selectUser" : "เลือกผู้ใช้",
  "ikbs.column.recycle.noSelectRecycles" : "เลือกบันทึก",
  "ikbs.knowledge.label.servicesEndTime" : "ระยะเวลาที่ใช้งานได้ทางธุรกิจสิ้นสุด",
  "ikbs.knghisverman.label.modifier" : "แก้ไขโดย",
  "ikbs.cntTmp.import.result" : "กําลังนําเข้าเทมเพลตเนื้อหาเสร็จสมบูรณ์ จํานวนทั้งหมด {0}, หมายเลขความสําเร็จ {1}, หมายเลขความล้มเหลว {2}",
  "ikbs.cntTmp.export.crosspage.info" : "ไม่รองรับการส่งออกข้ามหน้า สามารถส่งออกเฉพาะข้อมูลที่เลือกไว้ในหน้าปัจจุบันเท่านั้น",
  "ikbs.column.knowledge.expired" : "หมดอายุแล้ว",
  "ikbs.knowledgeaudit.title.auditTime" : "ตรวจสอบเมื่อ",
  "ikbs.knowledge.tip.uploadFailed" : "การอัปโหลดล้มเหลว",
  "ikbs.column.knowledge.CurVersion" : "เวอร์ชันต้นทาง",
  "ikbs.content.upload.file.Frequent" : "มีการเรียกใช้ API มากเกินไป โปรดลองใหม่ในภายหลัง",
  "ikbs.knowledge.check.keyword" : "คำสำคัญต้องไม่มีอักขระพิเศษ",
  "ikbs.knowledge.tip.fileTypeError" : "ล้มเหลวในการตรวจสอบชนิดของแฟ้ม",
  "ikbs.column.upMove.message.warning" : "ข้อมูล",
  "ikbs.knowledgeaudit.label.taskEndTime" : "เวลาสิ้นสุดของงาน",
  "ikbs.constableTmp.modifyBeginTime" : "เวลาเริ่มต้นการแก้ไข",
  "ikbs.knowledge.lable.copy" : "ซ้ำ",
  "ikbs.knowledgeaudit.label.batchAuditPass" : "อนุมัติเป็นชุด",
  "ikbs.contentTmp.delete" : "ลบออก",
  "ikbs.knowledge.tab.title" : "ประมวลความรู้",
  "ikbs.knowledge.recycle.label.knowledgeType" : "ประเภท",
  "ikbs.column.knowledge.Type" : "ประเภท",
  "ikbs.kbshome.button.thirtyDayList" : "30 วัน",
  "ikbs.cntTmp.import.queryContTempErr" : "ล้มเหลวในการค้นหาเทมเพลตเนื้อหา",
  "ikbs.favorite.table.delete" : "ลบ",
  "ikbs.column.move.message.warning" : "ข้อมูล",
  "ikbs.knowledge.title.propTitle" : "ชื่อ",
  "ikbs.constableTmp.modify.selectedOne" : "สามารถเลือกเทมเพลตได้เพียงอันเดียว",
  "ikbs.knowledge.label.uploadAttachment" : "อัปโหลดเอกสารแนบ",
  "ikbs.knowledge.pass.move" : "ย้ายไปยังประวัติ",
  "ikbs.favorite.title.warning" : "ข้อมูล",
  "ikbs.kbshome.label.favorite" : "ทางลัด",
  "ikbs.knowledge.label.deleteProperty" : "ลบ",
  "ikbs.column.knowledge.button.publish" : "เผยแพร่",
  "ikbs.column.resumeRecycle.comfirm" : "คุณแน่ใจหรือไม่ว่าต้องการคืนค่า",
  "ikbs.constableTmp.status.valid" : "เปิดใช้งานแล้ว",
  "ikbs.knowledgeaudit.label.reject" : "ปฏิเสธ",
  "ikbs.contentTmp.export" : "ส่งออกt",
  "ikbs.properties.operation.message.fail" : "ความล้มเหลว",
  "ikbs.knowledge.label.validEndTime" : "ระยะเวลาที่ใช้งานได้สิ้นสุด",
  "ikbs.knowledge.label.preStep" : "ขั้นตอนก่อนหน้า",
  "ikbs.properties.add.save" : "ตกลง",
  "ikbs.knowledgeaudit.label.query" : "สืบค้น",
  "ikbs.knowledge.tip.keywordCountError" : "รองรับคำสำคัญได้สูงสุด {count} รายการ",
  "ikbs.favorite.table.edit" : "แก้ไข",
  "ikbs.knowledge.label.addKngPoint" : "เพิ่มคำถาม",
  "ikbs.knowledge.label.creatorName" : "จัดเตรียมโดย",
  "ikbs.knowledge.tip.operateFailed" : "การดำเนินการล้มเหลว",
  "ikbs.knghisverman.tab.message.isDeleteHisVer" : "คุณแน่ใจหรือไม่ว่าต้องการลบเวอร์ชันนี้ออกจากประวัติ",
  "ikbs.favorite.title.addDirectory" : "เพิ่มไดเรกทอรี",
  "ikbs.knowledge.label.type" : "ประเภท",
  "ikbs.column.Move.message.success" : "การย้ายสำเร็จ",
  "ikbs.column.knowledge.update" : "อัปเดตแล้ว",
  "ikbs.search.label.sortByTime" : "เวลา",
  "ikbs.knowledge.label.summaryInfo" : "สรุป",
  "ikbs.properties.detail.information" : "รายละเอียดแอตทริบิวต์ขยาย",
  "ikbs.column.release.message.success" : "การเผยแพร่สำเร็จ",
  "ikbs.knowledge.label.ok" : "ตกลง",
  "ikbs.knowledge.label.upload" : "อัปโหลด",
  "ikbs.topMove.selectTopMoveColumnKngNumError" : "ไม่ได้เลือกหมวดหมู่ความรู้",
  "ikbs.constableTmp.display.yes" : "ใช่",
  "ikbs.column.modify.properties.batch.message.error" : "ไม่ได้เลือกหมวดหมู่ความรู้",
  "ikbs.cntTmp.import.failReason" : "อัปโหลดเร็กคอร์ด {0} ไม่สําเร็จ สาเหตุเกิดจากความล้มเหลว {1}",
  "ikbs.knowledge.properties.properties.title" : "ชื่อ",
  "ikbs.knowledgeaudit.label.auditor" : "ตรวจสอบโดย",
  "ikbs.cntTmp.export.exportNum.blank" : "เลือกเทมเพลตเนื้อหาที่จะส่งออก",
  "ikbs.contentTmp.status.valid" : "ใช้ได้",
  "ikbs.column.modify.selectModifyColumnKngNumZERO" : "ไม่ได้เลือกหมวดหมู่ความรู้",
  "ikbs.column.tip.success" : "ข้อมูล",
  "ikbs.knowledge.tip.relaKngCountError" : "รองรับรายการความรู้ที่เชื่อมโยงได้สูงสุด {count} รายการ",
  "ikbs.contentTemp.export.tempcontent" : "เนื้อหาเทมเพลต",
  "ikbs.contentTmp.query" : "แบบสอบถาม",
  "ikbs.properties.status.draft" : "แบบร่าง",
  "ikbs.column.knowledge.ModifyTime" : "แก้ไขเมื่อ",
  "ikbs.knowledgeaudit.title.status" : "สถานะ",
  "ikbs.column.knowledge.compare" : "เปรียบเทียบ",
  "ikbs.knowledge.label.uploadFileSizeError" : "เอกสารแนบต้องไม่เกิน 10 MB",
  "ikbs.cntTmp.import.invalidData" : "ข้อมูลไม่ถูกต้อง",
  "ikbs.column.delete.message.success" : "การลบสำเร็จ",
  "ikbs.favorite.tree.favoriteRootName" : "รายการโปรด",
  "ikbs.column.moveTo.selectColumnKngNumError" : "ไม่ได้เลือกหมวดหมู่ความรู้",
  "ikbs.cntTmp.export.error.syserr" : "ไม่สามารถส่งออกแฟ้มเทมเพลตเนื้อหาได้",
  "ikbs.column.button.confirm" : "ส่ง",
  "ikbs.constableTmp.delete.noSelectTmp" : "ยังไม่มีการเลือกแม่แบบ",
  "ikbs.content.transfer.html.success" : "เนื้อหาถูกแปลงเป็น HTML เรียบร้อยแล้ว",
  "ikbs.column.modify.modifyValidServiceEndTimeCheckError" : "เวลาสิ้นสุดของระยะเวลาที่ใช้งานได้ทางธุรกิจต้องไม่ช้ากว่าเวลาสิ้นสุดของระยะเวลาที่ใช้งานได้",
  "ikbs.knowledge.properties.label.propertiesTitle" : "ชื่อ",
  "ikbs.knowledge.label.addAnswer" : "เพิ่มคำตอบ",
  "ikbs.column.delete.message.comfirm" : "ยืนยัน",
  "ikbs.column.button.search" : "สืบค้น",
  "ikbs.knghisverman.label.modifyTime" : "แก้ไขเมื่อ",
  "ikbs.select.record.to.delete" : "โปรดเลือกเร็กคอร์ดที่จะลบ",
  "ikbs.knowledgeaudit.label.submitterOrAuditor" : "ส่งโดย/ตรวจสอบโดย",
  "ikbs.constableManage.fieldValue" : "มูลค่า",
  "ikbs.column.history.down.empty" : "ไม่สามารถเปิดได้หากไม่มีคอลัมน์หรือความรู้",
  "ikbs.constableTmp.name" : "ชื่อแม่แบบ",
  "ikbs.knowledgeaudit.label.pleaseSelect" : "-เลือก-",
  "ikbs.cntTmp.import.syserr" : "ไม่สามารถนําเข้าเทมเพลตเนื้อหาได้",
  "ikbs.column.copy.selectCopyColumnKngNumError" : "เลือกรายการความรู้สำหรับการคัดลอกได้เพียงรายการเดียวเท่านั้น",
  "ikbs.contentTmp.status.invalid" : "ไม่ถูกต้อง",
  "ikbs.column.message.ids.max.size" : "จำนวนรายการสำหรับการดำเนินการเป็นชุดไม่เกิน 100 รายการ",
  "ikbs.knowledge.properties.label.query" : "สืบค้น",
  "ikbs.kngCnt.cntTmp.importType.new" : "สร้างโดยตรง",
  "ikbs.column.upMove.message.success" : "ย้ายขึ้นสำเร็จแล้ว.",
  "ikbs.content.transfer.html.fail" : "ไม่สามารถแปลงเนื้อหาไปเป็น HTML ได้",
  "ikbs.knghisverman.title.kngHisVerMan" : "จัดการประวัติความรู้",
  "ikbs.favorite.field.directoryName" : "ชื่อไดเรกทอรี",
  "ikbs.column.Move.message.fail" : "การย้ายล้มเหลว",
  "ikbs.column.deletePermanently.message.fail" : "การลบถาวรล้มเหลว",
  "ikbs.columntype.knowledge" : "ความรู้",
  "ikbs.knowledge.type.common" : "ความรู้ทั่วไป",
  "ikbs.knowledgeaudit.label.ok" : "ตกลง",
  "ikbs.contentTmp.exception.contTmpName" : "การป้อนข้อมูลผิดกฎหมาย",
  "ikbs.kngCnt.cntTmp.use.cntReplaceConfirm" : "คุณแน่ใจหรือไม่ว่าต้องการแทนที่เนื้อหาความรู้ปัจจุบัน",
  "ikbs.search.button.search" : "ค้นหา",
  "ikbs.constableTmp.delete.success" : "การลบสําเร็จ",
  "ikbs.knowledge.recycle.coluKngName" : "ชื่อ",
  "ikbs.constableTmp.add.success" : "การเพิ่มความสําเร็จ",
  "ikbs.kbshome.button.orderByName" : "ตามชื่อ",
  "ikbs.knowledge.tip.uploadSuccess" : "การอัปโหลดสำเร็จ",
  "ikbs.constableManage.incorrect" : "ไม่ถูกต้อง",
  "ikbs.column.modify.properties.batch.message.success" : "แก้ไขแอตทริบิวต์เป็นชุดสำเร็จแล้ว",
  "ikbs.properties.inputway.text" : "อินพุตข้อความ",
  "ikbs.column.move.selectMoveColumnKngNumError" : "ไม่ได้เลือกหมวดหมู่ความรู้",
  "ikbs.knowledgeaudit.title.auditRemark" : "หมายเหตุ",
  "ikbs.knowledge.label.indexNeedUpdate" : "อัปเดตดัชนีการค้นหา",
  "ikbs.knowledge.recycle.status" : "สถานะ",
  "ikbs.kbshome.button.orderByClicks" : "คลิก",
  "ikbs.favorite.table.name" : "ชื่อ",
  "ikbs.column.knowledge.RelVersion" : "เวอร์ชันเป้าหมาย",
  "ikbs.kbshome.button.dayList" : "1 วัน",
  "ikbs.column.modify.modifyValidTimeServiceTimeSame" : "เปลี่ยนทั้งเวลาเริ่มต้นและสิ้นสุดของระยะเวลาที่ใช้งานได้และของระยะเวลาที่ใช้งานได้ทางธุรกิจ",
  "ikbs.column.label.servicesVaildEndTime" : "ระยะเวลาที่ใช้งานได้ทางธุรกิจสิ้นสุด",
  "ikbs.knowledgeaudit.label.submitAudit" : "รอดำเนินการ",
  "ikbs.column.release.selectreleaseColumnKngTypeError" : "เผยแพร่ได้เฉพาะความรู้เท่านั้น",
  "ikbs.knowledge.type.points" : "ความรู้ Q&A",
  "ikbs.column.knowledge.modifyStartTime" : "การแก้ไขเริ่มต้น",
  "ikbs.favorite.title.preModifiedKngName" : "ก่อนเปลี่ยน",
  "ikbs.column.deleteRecycle.comfirm" : "คุณแน่ใจหรือไม่ว่าต้องการลบ",
  "ikbs.recycle.title" : "ถังขยะรีไซเคิล",
  "ikbs.column.tree.rootName" : "หมวดหมู่ความรู้",
  "ikbs.column.downMove.message.warning" : "ข้อมูล",
  "ikbs.knghisverman.tab.title.kngBaseInfo" : "ข้อมูลพื้นฐาน",
  "ikbs.addtype.label.column" : "เพิ่มหมวดหมู่",
  "ikbs.knowledge.msg.updateKngPath" : "แปลงรายละเอียดความรู้เป็นแบบคงที่",
  "ikbs.kbshome.label.recentViews" : "ประวัติของฉัน",
  "ikbs.column.knowledge.publish" : "เผยแพร่แล้ว",
  "ikbs.contentTemp.export.tempdesc" : "คําอธิบายแม่แบบ",
  "ikbs.column..topMove.message.fail" : "ไม่สามารถปักหมุดที่ด้านบนได้",
  "ikbs.knowledge.recycle.button.clearAll" : "ทิ้งขยะในถังขยะรีไซเคิล",
  "ikbs.constableTmp.status" : "สถานะแม่แบบ",
  "ikbs.contentTmp.contentDesc" : "คําอธิบายแม่แบบ",
  "ikbs.knghisverman.label.version" : "เวอร์ชัน",
  "ikbs.column.knowledge.button.unLock" : "ปลดล็อค",
  "ikbs.knowledge.error.property" : "เลือกค่าของแอตทริบิวต์ [{}]",
  "ikbs.knowledgeaudit.label.mySubmitAudit" : "การประยุกต์ใช้ของฉัน",
  "ikbs.column.label.kngReleaseWay" : "ตรวจสอบและเผยแพร่ความรู้ในหมวดหมู่",
  "ikbs.contentTmp.add.fail" : "การเพิ่มล้มเหลว",
  "ikbs.constableTmp.moveUp" : "ข้างบน",
  "ikbs.constableManage.condition.limit" : "จํานวนเงื่อนไขไม่เกิน 10 เงื่อนไข",
  "ikbs.column.downMove.selectDowmMoveColumnKngNumError" : "ไม่ได้เลือกหมวดหมู่ความรู้",
  "ikbs.column.modify.modifyzeropropertieserror" : "ไม่มีการแก้ไขแอตทริบิวต์",
  "ikbs.column.label.isInheritProp" : "สืบทอดแอตทริบิวต์หมวดหมู่หลัก",
  "ikbs.column.knowledge.reject" : "ปฏิเสธแล้ว",
  "ikbs.column.deletePermanently.columnkng" : "ไม่ได้เลือกหมวดหมู่ความรู้",
  "ikbs.constableManage.modifyConstable" : "แก้ไขข้อมูลตารางการให้คําปรึกษา",
  "ikbs.contTmp.importTmp.title" : "ดาวน์โหลดแม่แบบนําเข้า",
  "ikbs.constableTmp.fieldViewName" : "แสดงชื่อ",
  "ikbs.cntTmp.import.contTmpDesc" : "คําอธิบายแม่แบบไม่ถูกต้อง",
  "ikbs.constableManage.addConstable" : "เพิ่มข้อมูลตารางการให้คําปรึกษา",
  "ikbs.contentTmp.operate" : "ดําเนินการ",
  "ikbs.column.upMove.message.fail" : "ไม่สามารถย้ายขึ้นได้",
  "ikbs.constableTmp.query" : "แบบสอบถาม",
  "ikbs.column.recycle.message.comfirm" : "ยืนยัน",
  "ikbs.kbshome.button.advancedSearch" : "การค้นหาขั้นสูง",
  "ikbs.constableTmp.modify.success" : "การแก้ไขสําเร็จ",
  "ikbs.kbshome.tip.refresh" : "รีเฟรช",
  "ikbs.constableTmp.delete.fail" : "การลบล้มเหลว",
  "ikbs.properties.operation.message.success" : "สำเร็จ",
  "ikbs.column.knowledge.clear" : "ล้าง",
  "ikbs.knowledge.label.auditor" : "ตรวจสอบโดย",
  "ikbs.column.tip.error" : "ข้อผิดพลาด",
  "ikbs.column.deletePermanently.columnkng.comfirm" : "คุณแน่ใจหรือไม่ว่าต้องการลบหมวดหมู่ความรู้ถาวร",
  "ikbs.favorite.tab.message.isDeleteFavoriteDir" : "คุณแน่ใจหรือไม่ว่าว่าต้องการลบไดเรกทอรีรายการโปรดและความรู้ในไดเรกทอรี",
  "ikbs.column.tips.modifyColumnOperation.error" : "ไม่สามารถแก้ไขหมวดหมู่ความรู้ได้",
  "ikbs.column.button.reset" : "รีเซ็ต",
  "ikbs.knowledgeaudit.message.auditSuccess" : "อนุมัติการประยุกต์ใช้สำเร็จแล้ว",
  "ikbs.column.knowledge.Modifier" : "แก้ไขโดย",
  "ikbs.cntTmp.import.error.contTmpNameExist" : "มีชื่อแม่แบบนี้อยู่แล้ว",
  "ikbs.cntTmp.export.info.success" : "ส่งออกสําเร็จ",
  "ikbs.knowledge.label.kngAttachmentUpload" : "อัปโหลดเอกสารแนบของหมวดหมู่ความรู้",
  "ikbs.knowledge.tip.propCountLimited" : "สามารถเชื่อมโยงแอตทริบิวต์ได้สูงสุด 20 รายการ",
  "ikbs.column.knowledge.button.deletePermanently" : "ลบอย่างถาวร",
  "ikbs.knowledge.recycle.label.operateTimeEnd" : "เวลาสิ้นสุดการลบ",
  "ikbs.knowledge.label.display" : "แสดงบนหน้าแรก",
  "ikbs.cntTmp.export.result" : "การส่งออกเทมเพลตเนื้อหาเสร็จสมบูรณ์ จํานวนทั้งหมด {0}, หมายเลขความสําเร็จ {1}, หมายเลขความล้มเหลว {2}",
  "ikbs.knowledge.label.deleteAnswer" : "ลบคำตอบ",
  "ikbs.knowledge.properties.operation.detail" : "รายละเอียด",
  "ikbs.column.knowledge.modifyEndTime" : "การแก้ไขสิ้นสุด",
  "ikbs.knowledgeaudit.title.name" : "ชื่อ",
  "ikbs.knowledge.label.modifierName" : "อัปเดตล่าสุดโดย",
  "ikbs.column.knowledge.button.moveTo" : "ปรับไปยัง",
  "ikbs.knowledge.title.knowledgeContent" : "เนื้อหา",
  "ikbs.knowledge.tip.fileSizeError" : "ล้มเหลวในการตรวจสอบขนาดแฟ้ม",
  "ikbs.constableTmp.delete" : "ลบออก",
  "ikbs.tips.copy.onlyKngCanCopy" : "คัดลอกได้เฉพาะความรู้เท่านั้น",
  "ikbs.column.passProcessWay.delete" : "ลบ",
  "ikbs.column.label.serviceStatus" : "สถานะธุรกิจ",
  "ikbs.column.button.cancel" : "ปิด",
  "ikbs.knowledge.label.clickNum" : "ดู",
  "ikbs.Column.MoveTo.message.success" : "การดำเนินการสำเร็จ",
  "ikbs.knowledge.index.yes" : "ใช่",
  "ikbs.knowledge.tip.fileIOError" : "ไม่สามารถอัปโหลดไฟล์ได้",
  "ikbs.column.modify.properties.message.warning" : "ข้อมูล",
  "ikbs.constableTmp.operate" : "การดําเนินงาน",
  "ikbs.knowledgeaudit.title.path" : "เส้นทาง",
  "ikbs.column.serviceStatus.expired" : "หมดอายุแล้ว",
  "ikbs.column.knowledge.audit" : "ตรวจสอบแล้ว",
  "ikbs.contTmp.import.tip" : "1. สามารถอัปโหลดไฟล์ an.xlsx ได้เท่านั้น 2. ขนาดไฟล์สูงสุดคือ 20 MB 3. สามารถนําเข้าข้อมูลได้สูงสุด 10 รายการ",
  "ikbs.knowledge.label.save" : "บันทึก",
  "ikbs.knowledge.title.knowledgeExtended" : "ข้อมูลขยาย",
  "ikbs.column.knowledge.button.modifyPropBatch" : "แก้ไขแอตทริบิวต์",
  "ikbs.column.type.history" : "ประวัติ",
  "ikbs.cntTmp.import.contTmpNameError" : "ชื่อแม่แบบไม่ถูกต้อง",
  "ikbs.knowledge.tip.error" : "ข้อผิดพลาด",
  "ikbs.contentTmp.delete.success" : "การลบสําเร็จ",
  "ikbs.knowledgeaudit.label.success" : "สำเร็จ",
  "ikbs.contentTmp.editStandard" : "การแก้ไขข้อมูลจําเพาะ",
  "ikbs.favorite.title.editFavoriteKngName" : "เปลี่ยนชื่อ",
  "ikbs.column.knowledge.button.down" : "ลง",
  "ikbs.column.modify.message.error" : "การแก้ไขล้มเหลว",
  "ikbs.column.knowledge.compareKng" : "เปรียบเทียบความรู้",
  "ikbs.knowledge.check.inputText" : "ไม่อนุญาตให้ใช้อักขระพิเศษ",
  "ikbs.column.knowledge.button.delete" : "ลบ",
  "ikbs.knowledge.service.new" : "ธุรกิจใหม่",
  "ikbs.knowledge.properties.operation.disable" : "ปิดใช้งาน",
  "ikbs.column.serviceStatus.hot" : "เป็นที่นิยม",
  "ikbs.contentTmp.add.success" : "การเพิ่มความสําเร็จ",
  "ikbs.favorite.msg.modifyNodeNameSuccess" : "การดำเนินการสำเร็จ",
  "ikbs.column.deletePermanently.message.warning" : "ข้อมูล",
  "ikbs.contentTmp.modify" : "แก้ไข",
  "ikbs.kbshome.button.sevenDayList" : "7 วัน",
  "ikbs.column.knowledge.button.turnToHistory" : "ย้ายไปยังประวัติ",
  "ikbs.constableTmp.cancle" : "ยกเลิก",
  "ikbs.knowledge.recycle.button.resume" : "คืนค่า",
  "ikbs.knowledgeaudit.label.auditing" : "อยู่ระหว่างการตรวจสอบ",
  "ikbs.knowledge.release.audit" : "ตรวจสอบแล้วเผยแพร่",
  "ikbs.constableTmp.yes" : "ใช่ค่ะ",
  "ikbs.column.label.displayFlag" : "แสดงบนหน้าแรก",
  "ikbs.column.moveto.back" : "ถัดจาก",
  "ikbs.column.modify.message.warning" : "ข้อมูล",
  "ikbs.knowledge.label.nextStep" : "ขั้นตอนต่อไป",
  "ikbs.column.moveTo.message.warning" : "ข้อมูล",
  "ikbs.column.isInheritProp.yes" : "ใช่",
  "ikbs.knowledge.title.knowledgeBase" : "ข้อมูลพื้นฐาน",
  "ikbs.knowledge.label.keyword" : "คำสำคัญ",
  "ikbs.knowledgeaudit.label.reset" : "รีเซ็ต",
  "ikbs.knowledge.message.uploadImgTypeError" : "รูปแบบภาพไม่ถูกต้อง",
  "ikbs.knowledge.label.moveUp" : "ขึ้น",
  "ikbs.properties.inputway.checkbox" : "กล่องทำเครื่องหมาย",
  "ikbs.knowledgeaudit.label.auditReject" : "ปฏิเสธแล้ว",
  "ikbs.contentTmp.ModifyTime" : "แก้ไขเมื่อ",
  "ikbs.knowledge.label.checkboxProp" : "กล่องทำเครื่องหมาย",
  "ikbs.knowledge.label.kngMsg" : "ข้อมูลความรู้",
  "ikbs.column.knowledge.compareResult" : "ผลลัพธ์การเปรียบเทียบ",
  "ikbs.cntTmp.import.invalidFile" : "ไฟล์ไม่ถูกต้อง",
  "ikbs.kbshome.button.orderByOrderId" : "ตามหมายเลข",
  "ikbs.column.knowledge.modifyColumnInformation" : "แก้ไขหมวดหมู่",
  "ikbs.knowledgeaudit.placeholder.pleaseSelect" : "-เลือก-",
  "ikbs.constableTmp.tab.modifyTitle" : "กําลังแก้ไขแม่แบบ",
  "ikbs.knowledge.label.modifyRemark" : "หมายเหตุ",
  "ikbs.column.release.selectreleaseColumnKngNumError" : "ไม่ได้เลือกความรู้",
  "ikbs.column.knowledge.button.order" : "เรียงตาม",
  "ikbs.contentTmp.import" : "นําเข้า",
  "ikbs.column.label.validEndTime" : "ระยะเวลาที่ใช้งานได้สิ้นสุด",
  "ikbs.properties.status.enable" : "เปิดใช้งาน",
  "ikbs.column.modify.modifyValidServiceStartTimeCheckError" : "เวลาเริ่มต้นของระยะเวลาที่ใช้งานได้ทางธุรกิจต้องไม่เร็วกว่าเวลาเริ่มต้นของระยะเวลาที่ใช้งานได้",
  "ikbs.knowledge.error.contentEmpty" : "ห้ามเว้นว่างเนื้อหาความรู้",
  "ikbs.column.upMove.selectUpMoveColumnKngNumError" : "ไม่ได้เลือกหมวดหมู่ความรู้",
  "ikbs.constableTmp.add" : "เพิ่ม",
  "ikbs.constableTmp.operator.exclude" : "ไม่รวม",
  "ikbs.column.knowledge.LockedFlat" : "ล็อคโดย",
  "ikbs.knowledge.label.cancelCollection" : "ยกเลิกรายการโปรด",
  "ikbs.contentTmp.reset" : "รีเซ็ต",
  "ikbs.knowledge.label.textProp" : "อินพุตข้อความ",
  "ikbs.knowledge.title.propType" : "ประเภทอินพุต",
  "ikbs.cntTmp.export.exportNum.oversize" : "สามารถส่งออกเทมเพลตเนื้อหาได้สูงสุด 10 แบบ",
  "ikbs.properties.status.disable" : "ปิดใช้งาน",
  "ikbs.knowledgeaudit.label.knowledgeName" : "ชื่อความรู้",
  "ikbs.constableManage.fieldName" : "ชื่อคอลัมน์",
  "ikbs.contentTmp.close" : "ปิดบริการ",
  "ikbs.knowledge.properties.label.add" : "ใหม่",
  "ikbs.knowledgeaudit.label.auditPass" : "อนุมัติแล้ว",
  "ikbs.common.search" : "ค้นหาทั่วไป",
  "ikbs.contentTmp.modify.success" : "การแก้ไขสําเร็จ",
  "ikbs.column.downMove.message.success" : "ย้ายลงสำเร็จแล้ว",
  "ikbs.knowledge.label.draft" : "แบบร่าง",
  "ikbs.knowledge.label.creationTime" : "สร้างเมื่อ",
  "ikbs.column.modify.properties.batch.message.fail" : "ไม่สามารถแก้ไขแอตทริบิวต์เป็นชุดได้",
  "ikbs.column.message.plsSelectModifyStartTime" : "เลือกเวลาเริ่มต้น",
  "ikbs.knowledge.label.problem" : "คำถาม",
  "ikbs.constableTmp.displayFlag" : "แสดงหรือไม่แสดง",
  "ikbs.Column.MoveTo.message.fail" : "การดำเนินการล้มเหลว",
  "ikbs.column.knowledge.columnKnowledgeName" : "ชื่อหมวดหมู่ความรู้",
  "ikbs.column.knowledge.button.top" : "ปักหมุด",
  "ikbs.tips.addColumnOperation.error" : "ไม่สามารถเพิ่มหมวดหมู่ได้",
  "ikbs.search.label.sortByName" : "ตามชื่อ",
  "ikbs.column.delete.selectdeletecolumn.comfirm" : "คุณแน่ใจหรือไม่ว่าต้องการลบหมวดหมู่และหมวดหมู่ย่อยและความรู้ทั้งหมด",
  "ikbs.constableTmp.modifierName" : "แก้ไขโดย",
  "ikbs.knowledge.label.attachment" : "เอกสารแนบ",
  "ikbs.column.unlock.selectUnlockColumnKngNumError" : "ไม่ได้เลือกหมวดหมู่ความรู้",
  "ikbs.contentTmp.tmpContent" : "เนื้อหาเทมเพลต",
  "ikbs.column.label.coluKngBrowsing" : "เรียกดูกลุ่ม",
  "ikbs.kbshome.button.totalList" : "ทุกเวลา",
  "ikbs.search.label.resultDes" : "ไม่มีข้อมูล",
  "ikbs.knowledge.recycle.button.query" : "สืบค้น",
  "ikbs.knowledge.properties.label.reset" : "รีเซ็ต",
  "ikbs.constableTmp.warning" : "ข้อมูลทั่วไป",
  "ikbs.knowledge.label.kngReleaseWay" : "โหมดเผยแพร่",
  "ikbs.properties.inputway.radio" : "กล่องตัวเลือก",
  "ikbs.knowledge.recycle.operationTime" : "ลบเมื่อ",
  "ikbs.constableTmp.plsSelectModifyEndTime" : "ตั้งเวลาสิ้นสุดการแก้ไข",
  "ikbs.column.knowledge.button.turnToNormal" : "ย้ายไปยังปกติ",
  "ikbs.knowledge.label.validStartTime" : "ระยะเวลาที่ใช้งานได้เริ่มต้น",
  "ikbs.knowledge.pass.delete" : "ลบ",
  "ikbs.column.displayflag.show" : "ใช่",
  "ikbs.knowledge.recycle.select.knowledgeStatus" : "สถานะความรู้",
  "ikbs.column.deletePermanently.message.success" : "การลบถาวรสำเร็จ",
  "ikbs.time.lessThan.currentTime" : "จุดจบต้องไม่เร็วกว่าเวลาปัจจุบัน",
  "ikbs.knowledge.recycle.label.operateTimeStart" : "เวลาเริ่มต้นการลบ",
  "ikbs.knowledge.recycle.label.knowledgeName" : "ชื่อหมวดหมู่ความรู้",
  "ikbs.knghisverman.label.delete" : "ลบ",
  "ikbs.column.knowledge.Name" : "ชื่อ",
  "ikbs.knowledgeaudit.placeholder.pleaseInput" : "กรอกชื่อ",
  "ikbs.column.label.validStartTime" : "ระยะเวลาที่ใช้งานได้เริ่มต้น",
  "ikbs.knowledge.display.yes" : "ใช่",
  "ikbs.knowledge.label.servicesTime" : "ระยะเวลาที่ใช้งานได้",
  "ikbs.search.label.updateStartTime" : "เวลาเริ่มต้นการอัปเดต",
  "ikbs.favorite.msg.addDirectorySuccess" : "เพิ่มไดเรกทอรีสำเร็จแล้ว",
  "ikbs.search.label.result" : "ผลการค้นหา",
  "ikbs.constableTmp.modify.fail" : "การแก้ไขล้มเหลว",
  "ikbs.constableTmp.add.fail" : "การเพิ่มล้มเหลว",
  "ikbs.column.knowledge.modifierId" : "แก้ไขโดย",
  "ikbs.tips.propertyDictValueNumExceedMaxValue.warning" : "จำนวนของค่าพจนานุกรมแอตทริบิวต์เกินจำนวนสูงสุด",
  "ikbs.constableTmp.no" : "ไม่ ไม่",
  "ikbs.knowledge.label.close" : "ปิด",
  "ikbs.favorite.table.operate" : "การดำเนินการ",
  "ikbs.kngCnt.cntTmp.editStandard.title" : "การแก้ไขข้อมูลจําเพาะ",
  "ikbs.constableTmp.anonymizationFlag" : "ไม่ระบุชื่อหรือไม่",
  "ikbs.knowledge.tip.fileCountError" : "รองรับเอกสารแนบได้สูงสุด {count} รายการ",
  "ikbs.column.copy.selectCopyColumnKngNumZERO" : "ไม่ได้เลือกความรู้เพื่อคัดลอก",
  "ikbs.constableTmp.delete.byCondition" : "ลบตามเงื่อนไข",
  "ikbs.column.delete.selectdeletecolumnkng.comfirm" : "คุณแน่ใจหรือไม่ว่าต้องการลบหมวดหมู่หรือความรู้",
  "ikbs.knowledge.label.name" : "ชื่อความรู้",
  "ikbs.knowledge.check.textArea" : "ไม่อนุญาตให้ใช้อักขระพิเศษต่อไปนี้: <>/",
  "ikbs.knowledge.label.addToCollection" : "เพิ่มในรายการโปรด",
  "ikbs.knowledge.recycle.operator" : "ผู้ดำเนินการ",
  "ikbs.konwledgetype.knowledge" : "ความรู้ทั่วไป",
  "ikbs.knowledgeaudit.label.taskBeginTime" : "เวลาเริ่มต้นงาน",
  "ikbs.kngCnt.cntTmp.importType.sameNameCover" : "เขียนทับแม่แบบที่มีชื่อเดียวกัน",
  "ikbs.column.knowledge.Path" : "เส้นทาง",
  "ikbs.kbshome.label.plsInputKeyWord" : "กรอกคำสำคัญ",
  "ikbs.knowledge.label.addProperty" : "เพิ่ม",
  "ikbs.knowledgeaudit.title.submitter" : "ส่งโดย",
  "ikbs.knowledge.properties.properties.operation" : "การดำเนินการ",
  "ikbs.knowledge.label.moveDown" : "ลง"
}