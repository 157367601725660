export default {
    'isales.basicinfo.title.chooseCallNo': 'Anrufnummer auswählen',
    'isales.common.title.cancel': 'Abbrechen',
    'isales.common.title.close': 'Schließen',
    'isales.common.title.confirm': 'OK',
    'isales.common.title.dataReset': 'Zurücksetzen',
    'isales.common.title.dataSearch': 'Suche',
    'isales.common.title.fail': 'Nicht bestanden',
    'isales.common.title.save': 'Speichern',
    'isales.common.title.ready': 'Bereit',
    'isales.common.title.view': 'Ansicht',
    'isales.common.title.success': 'Erfolgreich',
    'isales.common.title.operation': 'Operation',
    'isales.common.title.tipsInfo': 'Informationen',
    'isales.data.import.task.status.complete': 'Abgeschlossen',
    'isales.data.import.task.status.error': 'Fehler',
    'isales.data.import.task.status.executing': 'Importieren',
    'isales.data.import.task.status.ready': 'Vorbereitung',
    'isales.data.title.create': 'Neu',
    'isales.data.title.edit': 'Bearbeiten',
    'isales.data.title.undoEdit': 'Bearbeitung rückgängig machen',
    'isales.data.title.importData': 'Importieren',
    'isales.data.title.importResult': 'Ergebnis importieren',
    'isales.exportdata.message.failinfo': 'Daten konnten nicht exportiert werden',
    'isales.exportdata.message.nodata': 'Es können keine Daten exportiert werden, die die Bedingungen erfüllen!',
    'isales.exportdata.message.successinfo': 'Daten werden exportiert ...Details im Exportergebnis anzeigen',
    'isales.exportdata.title.exportresult': 'Ergebnis exportieren',
    'isales.exportdata.title.fail': 'Nicht bestanden',
    'isales.exportdata.title.success': 'Erfolgreich',
    'isales.import.message.template.show': 'Fügen Sie Kundendaten nach Zeilen und mehrere Anrufnummern nach Spalten hinzu',
    'isales.import.message.begintime': 'Startzeit',
    'isales.import.message.endtime': 'Endzeit',
    'isales.import.message.fail': 'Daten konnten nicht importiert werden',
    'isales.import.message.numberTooMany': 'Es können maximal 50000 Nummern hochgeladen werden',
    'isales.import.message.failnum': 'Anzahl der fehlgeschlagenen Datensätze',
    'isales.import.message.pleaseuploadfile': 'Wählen Sie eine Datei',
    'isales.import.message.repeatnum': 'Anzahl doppelter Datensätze',
    'isales.import.message.result.tip': 'Das Ergebnis der Differentialauswahl konnte nicht exportiert werden',
    'isales.import.message.status': 'Status',
    'isales.import.message.success': 'Daten werden importiert ...Klicken Sie auf "Später aktualisieren", um die neuesten Daten zu erhalten.',
    'isales.import.message.succnum': 'Anzahl der erfolgreichen Datensätze',
    'isales.import.message.taskstatuswrong': 'Daten können nicht in die Aufgabe für ausgehende Anrufe importiert werden, da sie sich nicht im Entwurfs- oder unterbrochenen Status befinden',
    'isales.import.message.uploadedfileformatvalidate': 'Falsches Dateiformat. CSV-Datei auswählen',
    'isales.import.message.uploadedfileNameformatvalidate': 'Falscher Dateiname. Wählen Sie eine Datei aus, deren Name nur chinesische Zeichen\, Kleinbuchstaben\, Ziffern\ oder Unterstriche enthält (_)',
    'isales.import.message.uploadedfiletoolarge': 'Wählen Sie eine Datei mit weniger als 20 MB',
    'isales.import.title.result': 'Importergebnis anzeigen',
    'isales.import.title.statusComplete': 'Abgeschlossen',
    'isales.import.title.statusExecuting': 'Ausführung',
    'isales.import.title.statusReady': 'Zur Hinrichtung',
    'isales.repeatfile.export.repeatline': 'Repeatline',
    'isales.repeatfile.export.repeatCalledNo': 'repeatCalledNo',
    'isales.failfile.export.failline': 'failLine',
    'isales.failfile.export.failCalledNo': 'failCalledNo',
    'isales.failfile.export.failReason': 'FailReason',
    'isales.input.message.large': 'Der Eingabewert ist zu groß',
    'isales.input.message.small': 'Der Eingabewert ist zu klein',
    'isales.management.message.sessionId': 'Sitzungsnummer',
    'isales.management.message.calledNo': 'Angerufene Nummer',
    'isales.management.message.calledNoEmpty': 'Die angerufene Nummer ist leer',
    'isales.management.message.calledNoExists': 'Die angerufene Nummer existiert bereits',
    'isales.management.message.calledNoFormatIncorrect': 'Die Nummer des ausgehenden Anrufs darf nur Ziffern enthalten',
    'isales.management.message.sessionIdFormatIncorrect': 'Die Sitzungs-ID darf nur Ziffern enthalten',
    'isales.management.message.calledNoLengthError': 'Die Länge der angerufenen Nummer überschreitet den Grenzwert',
    'isales.management.message.calledNoOne': 'Erst angerufene Nummer',
    'isales.management.message.calledNoOneCategory': 'Kategorie der zuerst angerufenen Nummer',
    'isales.management.message.calledNoOneInterval': 'Intervall der ersten angerufenen Nummer',
    'isales.management.message.calledNoTwo': 'Zweite angerufene Nummer',
    'isales.management.message.calledNoTwoCategory': 'Kategorie der zweiten angerufenen Nummer',
    'isales.management.message.calledNoTwoInterval': 'Intervall der zweiten angerufenen Nummer',
    'isales.management.message.createFailed': 'Daten für ausgehende Anrufe konnten nicht erstellt werden',
    'isales.management.message.createSuccess': 'Die Daten des ausgehenden Anrufs wurden erfolgreich erstellt',
    'isales.management.message.createTaskstatusError': 'Für die Aufgabe des ausgehenden Anrufs können keine Daten erstellt werden, da sie sich nicht im Entwurfs- oder unterbrochenen Status befindet',
    'isales.management.message.deleteConfirm': 'Möchten Sie die ausgewählten Daten für ausgehende Anrufe wirklich löschen?',
    'isales.management.message.deleteCount': 'Es können maximal 100 Datensätze ausgehender Anrufe gleichzeitig gelöscht werden',
    'isales.management.message.deletedataIdsEmpty': 'Die Markierung der zu löschenden ausgehenden Anrufdaten ist leer',
    'isales.management.message.deletedataIdsError': 'Falsche Kennzeichnung der zu löschenden ausgehenden Anrufdaten',
    'isales.management.message.deleteFailed': 'Die Daten des ausgehenden Anrufs konnten nicht gelöscht werden',
    'isales.management.message.deleteSuccess': 'Die Daten des ausgehenden Anrufs wurden erfolgreich gelöscht',
    'isales.management.message.deleteTaskstatusError': 'Die Aufgabe für ausgehende Anrufe kann nicht gelöscht werden, da sie sich nicht im Entwurfs- oder Pausenstatus befindet',
    'isales.management.message.download': 'Vorlage für ausgehende Anrufdaten herunterladen',
    'isales.management.message.filter': 'Daten filtern',
    'isales.management.message.filterNo': 'Nein',
    'isales.management.message.filterYes': 'Ja',
    'isales.management.message.import': 'Angehängte Daten importieren',
    'isales.management.message.importOrder': 'Importsequenz',
    'isales.management.message.orderReverse': 'Absteigende Reihenfolge',
    'isales.management.message.orderSequence': 'Aufsteigende Reihenfolge',
    'isales.management.message.taskIdEmpty': 'Das Aufgaben-Flag für ausgehende Anrufe ist leer',
    'isales.management.message.upload': 'Hochladen',
    'isales.management.message.uploadSelect': 'Wählen Sie eine Datei',
    'isales.management.placeholder.calledNo': 'Geben Sie eine angerufene Nummer ein',
    'isales.management.placeholder.ivrName': 'Geben Sie den Namen des intelligenten Roboterprozesses ein',
    'isales.management.placeholder.commonIvrName': 'Geben Sie einen allgemeinen IVR-Flussnamen ein',
    'isales.management.placeholder.commonIvrFlowAccessCode': 'Geben Sie einen allgemeinen IVR-Flow-Zugangscode ein',
    'isales.management.placeholder.skillName': 'Geben Sie einen Skill-Warteschlangennamen ein',
    'isales.management.title.calledNo': 'Kundennummer',
    'isales.management.title.createNew': 'Daten für ausgehende Anrufe erstellen',
    'isales.management.title.deleteInfo': 'Wählen Sie die Daten für ausgehende Anrufe aus',
    'isales.management.title.details': 'Details',
    'isales.management.title.editPolicy': 'Richtlinie ändern',
    'isales.management.title.edit': 'Ändern',
    'isales.management.placeholder.surveyName': 'Fragebogennamen eingeben',
    'isales.management.title.extendedField': 'Erweitertes Feld',
    'isales.management.title.import': 'Daten für ausgehende Anrufe importieren',
    'isales.management.title.warning': 'Warnung',
    'isales.management.title.callbot': 'Chatbot für ausgehende Anrufe',
    'isales.management.title.refresh': 'Aktualisieren',
    'isales.management.title.busiresult': 'Geschäftsergebnis',
    'isales.management.title.toblocklist': 'Zur Blocklist hinzufügen',
    'isales.outboundtime.field.hour': 'Stunde',
    'isales.outboundtime.field.minute': 'Minute',
    'isales.outboundtime.message.crossover': 'Die Zeitsegmente des ausgehenden Anrufs überschneiden sich',
    'isales.outboundtime.message.empty': 'Es ist keine Zeit für ausgehende Anrufe festgelegt',
    'isales.outboundtime.title.beginTime': 'Startzeit',
    'isales.outboundtime.title.clear': 'Klar',
    'isales.outboundtime.title.endTime': 'Endzeit',
    'isales.outboundtime.title.period': 'Zeitsegment des ausgehenden Anrufs',
    'isales.outboundtime.title.set': 'Zeit für ausgehenden Anruf festlegen',
    'isales.outboundtime.title.timePeriod': 'Zeitsegment des ausgehenden Anrufs',
    'isales.specialday.field.beginDate': 'Startdatum',
    'isales.specialday.field.byDate': 'Nach Datum',
    'isales.specialday.field.byWeek': 'Nach Woche',
    'isales.specialday.field.byYear': 'Nach Jahr wiederholen',
    'isales.specialday.field.endDate': 'Enddatum',
    'isales.specialday.field.Friday': 'Freitag',
    'isales.specialday.field.Monday': 'Montag',
    'isales.specialday.field.Saturday': 'Samstag',
    'isales.specialday.field.Sunday': 'Sonntag',
    'isales.specialday.field.Thursday': 'Donnerstag',
    'isales.specialday.field.Tuesday': 'Dienstag',
    'isales.specialday.field.Wednesday': 'Mittwoch',
    'isales.specialday.message.confirmDelete': 'Möchten Sie die Daten wirklich löschen?',
    'isales.specialday.message.createFail': 'Das spezielle Datum konnte nicht erstellt werden',
    'isales.specialday.message.createSuccess': 'Das Sonderdatum wurde erfolgreich erstellt',
    'isales.specialday.message.crossover': 'Die Zeitabschnitte ausgehender Anrufe an bestimmten Tagen überschneiden sich',
    'isales.specialday.message.dateEmpty': 'Das Sonderdatum ist leer',
    'isales.specialday.message.dateFormatError': 'Falsches spezielles Datumsformat',
    'isales.specialday.message.dateParseError': 'Fehler beim Konvertieren des Zeittyps des Sonderdatums',
    'isales.specialday.message.deleteFail': 'Das spezielle Datum konnte nicht gelöscht werden',
    'isales.specialday.message.deletePartiallyFail': 'Einige spezielle Daten konnten nicht gelöscht werden',
    'isales.specialday.message.deleteSpecialday': 'Wählen Sie ein bestimmtes Datum',
    'isales.specialday.message.deleteSuccess': 'Das Sonderdatum wurde erfolgreich gelöscht',
    'isales.specialday.message.deleteTips': 'Das Sonderdatum kann nicht gelöscht werden, da es mit einer Aufgabe für ausgehende Anrufe verknüpft ist',
    'isales.specialday.message.idEmpty': 'Das Flag für das Sonderdatum ist leer',
    'isales.specialday.message.idError': 'Falsches spezielles Datumskennzeichen',
    'isales.specialday.message.isEmpty': 'Es ist keine Uhrzeit für ausgehende Anrufe am Sonderdatum festgelegt',
    'isales.specialday.message.nameError': 'Der spezielle Datumsname hat ein falsches Format',
    'isales.specialday.message.nameExists': 'Der spezielle Datumsname existiert bereits',
    'isales.specialday.message.typeError': 'Falscher spezieller Datumstyp',
    'isales.specialday.message.updateFail': 'Das Sonderdatum konnte nicht geändert werden',
    'isales.specialday.message.updateSuccess': 'Das Sonderdatum wurde erfolgreich geändert',
    'isales.specialday.message.weeklyEmpty': 'Die Sonderdatumsperiode ist leer',
    'isales.specialday.message.weeklyError': 'Falscher spezieller Datumszeitraum',
    'isales.specialday.message.weeklyFormatError': 'Das spezielle Datumsperiodenformat ist falsch',
    'isales.specialday.message.weeklyRepeat': 'Sonderdatumszeitraum duplizieren',
    'isales.specialday.placeholder.searchname': 'Geben Sie einen speziellen Datumsnamen ein',
    'isales.specialday.title.add': 'Besonderes Datum hinzufügen',
    'isales.specialday.title.addSpecialTime': 'Besonderes Datum hinzufügen',
    'isales.specialday.title.choose': 'Besonderes Datum auswählen',
    'isales.specialday.title.date': 'Besonderes Datum',
    'isales.specialday.title.chooseDate': 'Datum auswählen',
    'isales.specialday.title.create': 'Erstellen',
    'isales.specialday.title.createspecialday': 'Sondertermin erstellen',
    'isales.specialday.title.delete': 'Löschen',
    'isales.specialday.title.scoreDetail': 'Bewertungsdetails',
    'isales.specialday.title.deleteError': 'Fehler',
    'isales.specialday.title.deleteSuccess': 'Erfolgreich',
    'isales.specialday.title.edit': 'Bearbeiten',
    'isales.specialday.title.editSpecialday': 'Sonderdatum bearbeiten',
    'isales.specialday.title.holiday': 'Besonderes Datum',
    'isales.specialday.title.holidayName': 'Besonderer Datumsname',
    'isales.specialday.title.holidayType': 'Besonderer Datumstyp',
    'isales.specialday.title.reset': 'Zurücksetzen',
    'isales.specialday.title.search': 'Abfrage',
    'isales.specialday.title.timeSet': 'Sonderdatum für ausgehende Anrufzeit festlegen',
    'isales.specialday.title.timePeriod': 'Sonderdatum Uhrzeit des ausgehenden Anrufs',
    'isales.specialday.title.warning': 'Warnung',
    'isales.task.field.callFail': 'Nicht bestanden',
    'isales.task.field.callFlagCompletion': 'Abgeschlossen',
    'isales.task.field.callFlagExecuted': 'Zur Hinrichtung',
    'isales.task.field.callFlagProgress': 'Ausführung',
    'isales.task.field.callSuccess': 'Erfolgreich',
    'isales.task.message.completionRate': 'Aufgabenabschlussrate',
    'isales.task.message.download': 'Aufzeichnung herunterladen',
    'isales.task.message.numberCalled': 'Aufgerufen: {0}',
    'isales.task.message.numberFailed': 'Fehlerhafte Datensätze',
    'isales.task.message.numberNotcalled': 'Fehlerhafte Datensätze: {0}',
    'isales.task.message.numbersTotal': 'Gesamt: {0}',
    'isales.task.message.numberSuccessful': 'Erfolgsbilanzen',
    'isales.task.message.pauseFail': 'Die Aufgabe für ausgehende Anrufe konnte nicht angehalten werden',
    'isales.task.message.pauseSuccess': 'Die Aufgabe für ausgehende Anrufe wurde erfolgreich ausgesetzt',
    'isales.task.message.play': 'Spielen',
    'isales.task.message.saveFail': 'Die Aufgabe für ausgehende Anrufe konnte nicht konfiguriert werden',
    'isales.task.message.saveSuccess': 'Die Aufgabe für ausgehende Anrufe wurde erfolgreich konfiguriert',
    'isales.taskpolicy.message.saveSuccess': 'Die Richtlinie für ausgehende Anrufe wurde erfolgreich konfiguriert',
    'isales.taskpolicy.message.saveFail': 'Richtlinie für ausgehende Anrufe konnte nicht konfiguriert werden',
    'isales.task.message.specialdayEmpty': 'Es wurde kein spezielles Datum ausgewählt',
    'isales.task.message.startFail': 'Die Aufgabe für ausgehende Anrufe konnte nicht gestartet werden',
    'isales.task.message.startSuccess': 'Die Aufgabe für ausgehende Anrufe wurde erfolgreich gestartet',
    'isales.task.message.startWarning': 'Daten werden exportiert ...Die Aufgabe für ausgehende Anrufe kann nicht gestartet werden',
    'isales.task.message.statistics': 'Statistik',
    'isales.task.message.successRate': 'Erfolgsquote',
    'isales.task.message.timeIncompletes': 'Das Zeitsegment eines einzelnen ausgehenden Anrufs ist nicht vollständig',
    'isales.task.title.basicInfo': 'Grundlegende Informationen',
    'isales.task.title.taskSample': 'Objektverwaltung',
    'isales.task.title.callBack': 'Ablehnen',
    'isales.task.title.callpolicy': 'Richtlinie für ausgehende Anrufe',
    'isales.task.title.callTime': 'Dauer des ausgehenden Anrufs',
    'isales.task.title.complete': 'Fertig stellen',
    'isales.task.title.nextStep': 'Weiter',
    'isales.task.title.preStep': 'Zurück',
    'isales.taskinfo.field.beginDate': 'Startzeit',
    'isales.taskinfo.field.callNo': 'Anrufernummer',
    'isales.taskinfo.field.endDate': 'Endzeit',
    'isales.taskinfo.field.flowName': 'IVR-Fluss',
    'isales.taskinfo.field.skillName': 'Skill-Warteschlange',
    'isales.taskinfo.field.taskType': 'Aufgabentyp',
    'isales.taskinfo.field.smsTemplate': 'SMS-Vorlage für fehlgeschlagenen ausgehenden Anruf',
    'isales.taskinfo.field.description': 'Aufgabenbeschreibung',
    'isales.taskinfo.field.surveyAnswerNum': 'Anzahl der Umfrageantworten',
    'isales.taskinfo.field.questionNumber': 'Anzahl der Fragen',
    'isales.taskinfo.field.surveyScore': 'Gesamtergebnis der Umfrage',
    'isales.taskinfo.message.beginTimeError': 'Die Startzeit darf nicht nach der Endzeit liegen',
    'isales.taskinfo.message.endTimeError': 'Die Endzeit muss nach der aktuellen Zeit liegen',
    'isales.taskinfo.message.input': 'Ungültige Zeichen',
    'isales.taskinfo.message.callintervalrange': 'Geben Sie eine ganze Zahl zwischen 1 und 3600 ein',
    'isales.taskinfo.message.nameExists': 'Der Aufgabenname für ausgehende Anrufe muss eindeutig sein',
    'isales.taskinfo.message.require': 'Legen Sie alle erforderlichen Felder fest',
    'isales.taskinfo.placeholder.choose': '--Auswahl--',
    'isales.taskinfo.placeholder.input': '--Eingabetaste--',
    'isales.taskinfo.title.chooseIvrFlow': 'IVR-Fluss auswählen',
    'isales.taskinfo.title.chooseCommonIvrFlow': 'Gemeinsames IVR auswählen',
    'isales.taskinfo.title.chooseSkill': 'Skill-Warteschlange auswählen',
    'isales.taskinfo.title.ivrFlow': 'Intelligenter Chatbot',
    'isales.taskinfo.title.commonIvrFlow': 'Gemeinsames IVR',
    'isales.taskinfo.title.skillName': 'Skill-Warteschlange',
    'isales.taskinfo.title.chooseSmsTemplateQuery': 'SMS-Vorlage auswählen',
    'isales.taskinfo.title.smsTemplate': 'SMS-Vorlage',
    'isales.taskmanagement.field.intelligence': 'Intelligenter ausgehender Anruf',
    'isales.taskmanagement.field.Automatic': 'Automatischer ausgehender Anruf',
    'isales.taskmanagement.field.outboundType': 'Anruftyp',
    'isales.taskmanagement.field.prediction': 'Prognostizierter ausgehender Anruf',
    'isales.taskmanagement.field.taskName': 'Aufgabenname',
    'isales.taskmanagement.field.taskTime': 'Dauer',
    'isales.taskinfo.title.chooseSurvey': 'Fragebogen auswählen',
    'isales.taskmanagement.field.taskStatus': 'Aufgabenstatus',
    'isales.taskmanagement.message.deleteConfirm': 'Möchten Sie es wirklich löschen?',
    'isales.taskmanagement.message.queryDateError': 'Falsches Datum. Wählen Sie einen anderen aus',
    'isales.taskmanagement.message.queryFailed': 'Abfrage fehlgeschlagen',
    'isales.taskmanagement.message.queryLimit': 'Die Anzahl der Datensätze auf jeder Seite muss eine positive ganze Zahl kleiner als 101 sein',
    'isales.taskinfo.title.surveyName': 'Name des Fragebogens',
    'isales.taskinfo.title.versionNo': 'Fragebogenversion',
    'isales.taskmanagement.message.queryTime': 'Geben Sie eine vollständige Zeit ein',
    'isales.taskmanagement.message.timeerror': 'Die Startzeit darf nicht nach der Endzeit liegen',
    'isales.taskmanagement.title.addAuto': 'Automatisch ausgehenden Systemanruf erstellen',
    'isales.task.message.startTips': 'Die Aufgabe ist abgelaufen. Die Aufgabe für ausgehende Anrufe kann nicht gestartet werden',
    'isales.taskmanagement.title.chooseBegintime': 'Startzeit',
    'isales.taskmanagement.title.begintime': 'Startzeit',
    'isales.taskmanagement.title.chooseEndtime': 'Endzeit',
    'isales.taskmanagement.title.endtime': 'Endzeit',
    'isales.taskmanagement.title.configData': 'Anrufdaten',
    'isales.taskmanagement.title.createTask': 'Erstellen',
    'isales.taskmanagement.title.deleteFailed': 'Aufgabe konnte nicht gelöscht werden',
    'isales.common.message.choose': 'Wählen Sie Daten aus.',
    'isales.taskmanagement.title.deleteSuccess': 'Die Aufgabe wurde erfolgreich gelöscht',
    'isales.taskmanagement.title.edit': 'Bearbeiten',
    'isales.taskmanagement.title.editPolicy': 'Richtlinie bearbeiten',
    'isales.taskmanagement.title.inputTaskName': 'Aufgabennamen eingeben',
    'isales.taskmanagement.title.outbound': 'Chatbot für ausgehende Anrufe',
    'isales.taskmanagement.title.pause': 'Aussetzen',
    'isales.taskmanagement.title.query': 'Abfrage',
    'isales.taskmanagement.title.queryTimeTip': 'Informationen',
    'isales.taskmanagement.title.reset': 'Zurücksetzen',
    'isales.taskmanagement.title.start': 'Starten',
    'isales.taskmanagement.title.status': 'Status',
    'isales.taskmanagement.title.viewTaskResult': 'Aufgabenergebnis',
    'isales.taskmanagment.title.viewstatistics': 'Statistiken anzeigen',
    'isales.taskpolicy.field.connRate': 'Verbindungsrate ausgehender Anrufe',
    'isales.taskpolicy.field.connRateMethod': 'Algorithmus für die Verbindungsrate ausgehender Anrufe',
    'isales.taskpolicy.field.experienceMethod': 'Algorithmus für empirische Vorhersagen',
    'isales.taskpolicy.field.failInterval': 'Intervall für ausgehende Anrufe',
    'isales.taskpolicy.field.lossRate': 'Min. Anrufverlustrate',
    'isales.taskpolicy.field.maxAvgCallTime': 'Max. Durchschnittliche Anrufdauer',
    'isales.taskpolicy.field.minAvgCallTime': 'Min. Durchschnittliche Anrufdauer ',
    'isales.taskpolicy.field.minChangeTime': 'Min. Aufenthaltsdauer',
    'isales.taskpolicy.field.multiSkill': 'Hybrid-Skill-Queue',
    'isales.taskpolicy.field.notSupportMultiSkill': 'Hybrid-Agenten für eingehende und ausgehende Anrufe werden nicht unterstützt',
    'isales.taskpolicy.field.obCallCount': 'Anzahl der gleichzeitig ausgehenden Anrufe',
    'isales.taskpolicy.field.obOverTime': 'Max. Antwortdauer',
    'isales.taskpolicy.field.pickupRateMethod': 'Algorithmus für die Aushängerate',
    'isales.taskpolicy.field.predictMethod': 'Algorithmus für erwartete ausgehende Anrufe',
    'isales.taskpolicy.field.supportMultiSkill': 'Hybrid-Agenten für eingehende und ausgehende Anrufe werden unterstützt',
    'isales.taskpolicy.field.talkingRate': 'Geschwindigkeit ausgehender Anrufe',
    'isales.taskpolicy.field.utilRate': 'Agentennutzung',
    'isales.taskpolicy.field.utilRateCalculateMethod': 'Methode zur Berechnung der Agentennutzung',
    'isales.taskpolicy.field.utilRateCalculateOne': 'Agentennutzung = Gesamte Anrufdauer/Gesamte Anmeldedauer',
    'isales.taskpolicy.field.utilRateCalculateTwo': 'Agentennutzung = (Gesamte Anrufdauer + Dauer des Arbeitsstatus)/Gesamte Anmeldedauer',
    'isales.taskpolicy.field.utilRateMethod': 'Algorithmus für die Agentennutzung',
    'isales.taskpolicy.field.waitTime': 'Wartezeit des angerufenen Teilnehmers',
    'isales.taskpolicy.message.input.maxAvgCallTime': 'Der Max. Die durchschnittliche Anrufdauer kann nicht weniger als die Min. Durchschnittliche Anrufdauer',
    'isales.taskpolicy.message.input.minAvgCallTime': 'Die Min. Die durchschnittliche Anrufdauer kann nicht länger als die maximale Dauer sein. Durchschnittliche Anrufdauer',
    'isales.taskresult.field.failedtooccupytheagent': 'Einen Agenten nicht besetzen',
    'isales.taskresult.field.failedtopause': 'Die Aufgabe kann nicht angehalten werden, da sie sich bereits im angehaltenen Status befindet',
    'isales.taskresult.field.failedtoresume': 'Die Aufgabe kann nicht wiederhergestellt werden, da sie sich nicht im angehaltenen Status befindet',
    'isales.taskresult.field.failsAcdtimeout': 'Die ACD-Zeit läuft ab',
    'isales.taskresult.field.failsAgentdevicetimesout': 'Die Agentenbesetzung läuft ab',
    'isales.taskresult.field.failsAgentfailswithuser': 'Es konnte kein Sprachkanal zwischen einem Agenten und einem Abonnenten eingerichtet werden.',
    'isales.taskresult.field.failsAns1': 'Texttelefon (ANS1)',
    'isales.taskresult.field.failsAns2': 'Texttelefon (ANS2)',
    'isales.taskresult.field.failsAns3': 'Texttelefon (ANS3)',
    'isales.taskresult.field.failsAns4': 'Texttelefon (ANS4)',
    'isales.taskresult.field.failsAns5': 'Texttelefon (ANS5)',
    'isales.taskresult.field.failsAutomaticansweringmachine': 'Der angerufene Teilnehmer ist ein automatischer Anrufbeantworter',
    'isales.taskresult.field.failsAutomaticdevicedetected': 'Automatische Geräte wurden erkannt',
    'isales.taskresult.field.failsBeingchanged': 'Der angerufene Teilnehmer wechselt und der Anruf wird weitergeleitet',
    'isales.taskresult.field.failsBusytone': 'Besetztton',
    'isales.taskresult.field.failsCallattemptfails': 'Die Schnittstelle für ausgehende Anrufe konnte nicht aufgerufen werden',
    'isales.taskresult.field.failsCalldatatimeout': 'Zeitüberschreitung bei Anrufdaten',
    'isales.taskresult.field.failsCalledmodem': 'Der angerufene Teilnehmer ist ein Modem',
    'isales.taskresult.field.failsCallednumberincorrect': 'Falsche angerufene Nummer in der Vorschau',
    'isales.taskresult.field.failsCalledpartybusy': 'Der angerufene Teilnehmer ist beschäftigt',
    'isales.taskresult.field.failsCallqueueoverflow': 'Eine Anrufwarteschlange läuft über',
    'isales.taskresult.field.failsCallrejected': 'Der Anruf wurde abgelehnt',
    'isales.taskresult.field.failsCallwaiting': 'Anklopfen',
    'isales.taskresult.field.failsCpp': 'Angerufene Weiterleitung durch Einhaken',
    'isales.taskresult.field.failsDestinationunreachable': 'Das Ziel ist nicht erreichbar',
    'isales.taskresult.field.failsDisconnection': 'Anruffehler',
    'isales.taskresult.field.failsDnd': 'Der Anruf wird vorübergehend abgelehnt',
    'isales.taskresult.field.failsDtmf': 'Texttelefon (DTMF)',
    'isales.taskresult.field.failsEdt': 'Texttelefon (EDT)',
    'isales.taskresult.field.failsEmptyorwrong': 'Nicht erreichbare oder falsche Nummer',
    'isales.taskresult.field.failsFak': 'Der angerufene Teilnehmer ist ein Fax',
    'isales.taskresult.field.failsFaxdetected': 'Es wurde ein Fax erkannt',
    'isales.taskresult.field.failsFaxormodem': 'Der angerufene Teilnehmer ist ein Fax oder Modem',
    'isales.taskresult.field.failsFsk': 'Texttelefon (FSK)',
    'isales.taskresult.field.failsHangsupbeforeanswering': 'Der angerufene Teilnehmer legt auf, bevor der Anruf angenommen wird',
    'isales.taskresult.field.failsHangsupwhencall': 'Wenn ein Agent einen Anruf über die Agentenleistensteuerung beantwortet\, wird das Telefon des Agenten getrennt',
    'isales.taskresult.field.failsInsufficientresources': 'Unzureichende Ressourcen',
    'isales.taskresult.field.failsInvalidstatus': 'Ungültiger DTE-Status',
    'isales.taskresult.field.failsModemdetected': 'Es wurde ein Modem erkannt',
    'isales.taskresult.field.failsModemorfax': 'Es wurde ein Modem oder Fax erkannt',
    'isales.taskresult.field.failsMusicdetected': 'Musik wird erkannt',
    'isales.taskresult.field.failsNoacdresource': 'Auf dem ACD ist keine Ressource verfügbar',
    'isales.taskresult.field.failsNoagentavailable': 'Wenn das OBS in den Standby-Status wechselt\, wenn kein Agent für eine sichere Beendigung oder belegte ausgehende Anrufe verfügbar ist\, werden fehlgeschlagene Aufzeichnungen abgebrochen',
    'isales.taskresult.field.failsNoanswer': 'Der angerufene Teilnehmer nimmt den Anruf nicht an',
    'isales.taskresult.field.failsNoreason': 'Unbedingte DTE-Übertragung',
    'isales.taskresult.field.failsNoreply': 'Der angerufene Teilnehmer nimmt den Anruf nicht an',
    'isales.taskresult.field.failsNoresource': 'Keine Ressource verfügbar',
    'isales.taskresult.field.failsNoresponse': 'Der angerufene Teilnehmer antwortet nicht',
    'isales.taskresult.field.failsNotintheservicearea': 'Das Telefon ist außer Betrieb',
    'isales.taskresult.field.failsNumberchange': 'Die Nummer wurde geändert',
    'isales.taskresult.field.failsNumberreacheslimit': 'Die Anzahl der OBS-Anrufe erreicht die Obergrenze',
    'isales.taskresult.field.failsObsexits': 'Wenn das OBS beendet wird\, werden unbeantwortete laufende Datensätze storniert und zurückgeschrieben',
    'isales.taskresult.field.failsPhonenumberisnull': 'Die ausgehende Nummer ist leer',
    'isales.taskresult.field.failsPlayingvoices': 'Die Signalisierung erkennt, dass der Peer-Switch Stimmen abspielt',
    'isales.taskresult.field.failsPoweredoff': 'Das Telefon ist ausgeschaltet',
    'isales.taskresult.field.failsQueuingtimesout': 'Die Warteschlange läuft ab und die Plattform gibt den Anruf frei',
    'isales.taskresult.field.failsRecordcanceled': 'Wenn das OBS in den Standby-Zustand wechselt\, werden laufende Aufzeichnungen abgebrochen',
    'isales.taskresult.field.failsRecordswrittenback': 'Fehlerhafte Datensätze werden zurückgeschrieben, wenn das OBS beendet wird (oder eine Aufgabe gelöscht wird)',
    'isales.taskresult.field.failsReleasesbeforecall': 'Bevor ein Sprachkanal eingerichtet wird\, gibt ein Agent einen Anruf über die Agentenleistensteuerung frei',
    'isales.taskresult.field.failsReleaseswhencall': 'Wenn ein Sprachkanal eingerichtet ist\, gibt ein Agent einen Anruf über die Steuerung der Agentenleiste frei',
    'isales.taskresult.field.failsResultnotobtained': 'Ausgehende Anrufdaten werden zurückgeschrieben, wenn nach dem Anruf-Timeout kein Ergebnis erhalten wird',
    'isales.taskresult.field.failsResulttimesoutafterpicksup': 'Das Erkennungsergebnis für ausgehende Anrufe nach ACD-Ausstiegs-Timeout',
    'isales.taskresult.field.failsRingbacktone': 'Rückrufton',
    'isales.taskresult.field.failsRingingtimesout': 'Die Klingelzeit läuft ab und die Plattform gibt den Anruf frei.',
    'isales.taskresult.field.failsSittone': 'Besonderer Informationston',
    'isales.taskresult.field.failsSsit': 'Ton zum Senden spezieller Informationen',
    'isales.taskresult.field.failsSubscriberbusy': 'Der angerufene Teilnehmer ist beschäftigt',
    'isales.taskresult.field.failsSubscriberhangsup': 'Der angerufene Teilnehmer legt auf',
    'isales.taskresult.field.failsSwitchingdevicebusy': 'Der Schalter ist besetzt',
    'isales.taskresult.field.failsTasknotexist': 'Keine Aufgabe für ausgehende Anrufe verfügbar',
    'isales.taskresult.field.failsTransferbeingalerted': 'Der Anruf wird während des Klingelns weitergeleitet',
    'isales.taskresult.field.failsTransfernoreply': 'Der Anruf wird ohne Antwort weitergeleitet',
    'isales.taskresult.field.failsTransferonbusy': 'Der Anruf wird bei Besetzt weitergeleitet',
    'isales.taskresult.field.failsTransferunconditionally': 'Der Anruf wird bedingungslos weitergeleitet',
    'isales.taskresult.field.failsUnallocatednumber': 'Nicht zugewiesene Nummer',
    'isales.taskresult.field.failsUndefinedsignaltone': 'Undefinierter Signalton',
    'isales.taskresult.field.failsUnreachable': 'Unerreichbarer Mobilfunkteilnehmer',
    'isales.taskresult.field.failsUserhangsupinthequeue': 'Alle Agenten sind beschäftigt\, und der Benutzer legt proaktiv auf',
    'isales.taskresult.field.failsUserrejection': 'Der Anruf wurde abgelehnt',
    'isales.taskresult.field.failsWaitresulttimesout': 'Zeitüberschreitung beim Warten auf Ergebnisse ausgehender Anrufe auf der Plattform',
    'isales.taskresult.field.failsWithoutringing': 'Der Anruf wird bei sofortiger Antwort vor dem Klingeln weitergeleitet',
    'isales.taskresult.field.failsCallRouting': 'Anruf konnte nicht weitergeleitet werden',
    'isales.taskresult.field.failsAgentHangsUp': 'Der Agent legt auf',
    'isales.taskresult.field.failsAgentCancelingCall': 'Der Agent bricht den Anruf ab',
    'isales.taskresult.field.failsAgentDisconnection': 'Der Agent ist nicht verbunden',
    'isales.taskresult.field.failsCallTimeout': 'Die Anrufzeit ist abgelaufen',
    'isales.taskresult.field.failsResourceOverload': 'Unzureichende Ressourcen',
    'isales.taskresult.field.failsUnallocatedOrIncorrectNumber': 'Nicht erreichbare oder falsche Nummer',
    'isales.taskresult.field.failsTimeout': 'Das System läuft ab',
    'isales.taskresult.field.failsInvalidUser': 'Ungültiger Benutzer',
    'isales.taskresult.field.failsInvalidDevice': 'Ungültiges Gerät',
    'isales.taskresult.field.failsNoAnsweringDeviceAvailable': 'Kein Anrufbeantworter verfügbar',
    'isales.taskresult.field.failsAbnormal': 'Fehler',
    'isales.taskresult.field.failsInvalidRoutingDevice': 'Ungültiges Routinggerät',
    'isales.taskresult.field.failsSwitchRestart': 'Der Schalter wurde neu gestartet',
    'isales.taskresult.field.failsUnknownError': 'Unbekannter Fehler',
    'isales.taskresult.field.failsCallResourcesInsufficient': 'Unzureichende Anrufressourcen',
    'isales.taskresult.field.failsNoValidVDN': 'Kein gültiger VDN verfügbar',
    'isales.taskresult.field.failsCallFailure': 'Der Anruf ist fehlgeschlagen',
    'isales.taskresult.field.failsFailedRouteAgent': 'Anruf konnte nicht an den Agenten weitergeleitet werden',
    'isales.taskresult.field.failsInvalidSkillQueue': 'Ungültige Skill-Warteschlange',
    'isales.taskresult.field.failsFailedRouteSkillQueue': 'Die Skill-Warteschlange konnte nicht weitergeleitet werden',
    'isales.taskresult.field.failsFailedRouteCallToAttendant': 'Anruf konnte nicht an den Agenten weitergeleitet werden',
    'isales.taskresult.field.failsFailedAgentIsBusy': 'Der Agent befindet sich nicht im Ruhezustand',
    'isales.taskresult.field.failsCalledOnHook': 'Der Agent fängt bei einem Anruf an',
    'isales.taskresult.field.success': 'Erfolgreich',
    'isales.taskresult.field.no': 'Nein',
    'isales.taskresult.message.details': 'Details',
    'isales.taskresult.message.queryTime': 'Geben Sie eine vollständige Zeit ein',
    'isales.taskresult.title.busiresultupdate': 'Busiresult anhängen',
    'isales.taskresult.title.beginTime': 'Startzeit',
    'isales.taskresult.title.chooseBeginTime': 'Wählen Sie die Startzeit aus',
    'isales.taskresult.title.calledNo': 'Ausgehende Nummer',
    'isales.taskresult.title.callFlag': 'Status',
    'isales.taskresult.title.chooseCallFlag': 'Wählen Sie einen Status aus',
    'isales.taskresult.title.callResult': 'Ergebnis',
    'isales.taskresult.title.endTime': 'Endzeit',
    'isales.taskresult.title.chooseEndTime': 'Bitte wählen Sie eine Endzeit aus',
    'isales.taskresult.title.export': 'Exportieren',
    'isales.taskresult.title.exportQuestion': 'Frage zum Export',
    'isales.taskresult.title.trainingQuestion': 'Schulungstestfragen',
    'isales.taskresult.title.exportResult': 'Exportergebnis anzeigen',
    'isales.taskresult.title.failCode': 'Weil',
    'isales.taskresult.title.inputCallerNo': 'Geben Sie eine ausgehende Nummer ein',
    'isales.taskresult.title.queryTime': 'Informationen',
    'isales.taskresult.title.result': 'Anrufergebnis',
    'isales.taskresult.title.busisubstatus': 'SubStatus für Unternehmen',
    'isales.taskresult.title.busidesc': 'Beschreibung des Unternehmens',
    'isales.taskresult.title.chooseResult': 'Bitte wählen Sie das Anrufergebnis aus',
    'isales.taskresult.title.choosebusisubstatus': 'Bitte wählen Sie ein Geschäftsergebnis aus, bevor Sie einen Substatus auswählen',
    'isales.taskresult.title.choosebusiresult': 'Bitte wählen Sie ein Geschäftsergebnis aus',
    'isales.taskresult.title.resultDetail': 'Anrufergebnisdetails',
    'isales.taskresultexport.message.begintime': 'Startzeit',
    'isales.taskresultexport.message.downloadConfirmInfo': 'Möchten Sie die Datei wirklich herunterladen?',
    'isales.taskresultexport.message.downloadExportdatafailinfo': 'Überprüfung fehlgeschlagen. Die exportierten Dateidaten wurden nicht gefunden',
    'isales.taskresultexport.message.downloadFail': 'Download fehlgeschlagen',
    'isales.taskresultexport.message.downloadFilesuffixfailinfo': 'Download fehlgeschlagen. Die exportierten Dateiinformationen enthalten nicht die Dateinamenerweiterung',
    'isales.taskresultexport.message.downloadRedischeckfailinfo': 'Eine Datei kann erst dann erneut heruntergeladen werden, wenn der vorherige Download abgeschlossen ist',
    'isales.taskresultexport.message.endtime': 'Endzeit',
    'isales.taskresultexport.message.result': 'Ergebnis',
    'isales.taskresultexport.title.download': 'Herunterladen',
    'isales.taskresultexport.title.downloadConfirm': 'OK',
    'isales.taskresultexport.title.exported': 'Zu exportieren',
    'isales.taskresultexport.title.fail': 'Nicht bestanden',
    'isales.taskresultexport.title.progress': 'Exportieren',
    'isales.taskresultexport.title.success': 'Erfolgreich',
    'isales.taskstatistics.field.calling': 'Anrufen',
    'isales.taskstatistics.field.completedcalls': 'Aufgerufen',
    'isales.taskstatistics.field.notcompletedcalls': 'Ausstehend',
    'isales.taskstatistics.placeholder.called': 'Aufgerufen: {0}',
    'isales.taskstatistics.placeholder.calling': 'Aufruf: {0}',
    'isales.taskstatistics.placeholder.notcalled': 'Ausstehend: {0}',
    'isales.taskstatistics.placeholder.statistics': 'Statistik',
    'isales.taskstatus.field.all': 'Alle',
    'isales.taskstatus.field.draft': 'Entwurf',
    'isales.taskstatus.field.execute': 'Ausführung',
    'isales.taskstatus.field.finish': 'Abgelaufen',
    'isales.taskstatus.field.suspend': 'Ausgesetzt',
    'isales.tasktype.field.intelligence': 'Intelligenter ausgehender Anruf',
    'isales.tasktype.field.prediction': 'Prognostizierter ausgehender Anruf',
    'isales.tasktype.field.automatic': 'Automatischer ausgehender Anruf',
    'isales.validate.message.formatError': 'Geben Sie ein Datum im Format MM/TT ein',
    'isales.validate.message.holidayDateComparisonError': 'Das Enddatum darf nicht vor dem Startdatum liegen',
    'isales.validate.message.notSpecial': 'Der Name darf keine Sonderzeichen enthalten',
    'isales.operlog.message.data.import': 'Daten für Aufgabe {0} importieren',
    'isales.operlog.message.data.downloadtemplate': 'Vorlage für den Datenimport herunterladen',
    'isales.operlog.message.data.downloadrepeatfile': 'Duplizierte Datendatei herunterladen',
    'isales.operlog.message.data.downloadfailfile': 'Datendatei konnte nicht heruntergeladen werden',
    'isales.operlog.message.data.downloadexportfile': 'Exportergebnisdatei der Aufgabe {0} herunterladen',
    'isales.statistics.field.addHis': 'Archivierte Datenerfassung',
    'isales.statistics.field.addHisYes': 'Ja',
    'isales.taskresult.title.queryArchivedData': 'Archivierte Daten abfragen',
    'isales.taskresult.field.yes': 'Ja',
    'isales.taskmanagement.message.afterNow': 'Die Startzeit darf nicht nach der aktuellen Zeit liegen',
    'isales.taskresult.title.failedDetail': 'Anruffehlerdetails',
    'isales.taskmanagement.field.dailyStatistics': 'Tägliche Statistiken',
    'isales.taskmanagement.field.statisticsDate': 'Datum der Statistikerhebung',
    'isales.taskmanagement.field.statisticsType': 'Statistikart',
    'isales.blacklist.title.calledNo': 'Angerufene Nummer',
    'isales.blacklist.title.remark': 'Anmerkungen',
    'isales.blacklist.placeholder.searchcalledno': 'Geben Sie die angerufene Nummer ein',
    'isales.blacklist.title.createblacklist': 'Nummer zur Blockliste hinzufügen',
    'isales.blacklist.title.name': 'Name',
    'isales.tasklogic.field.retryDay': 'Max.Tage für Anrufversuche vor der Verbindung',
    'isales.tasklogic.field.retryTimes': 'Max. Anzahl der täglichen Anrufversuche',
    'isales.tasklogic.title.addPolicy': 'Richtlinie hinzufügen',
    'isales.tasklogic.field.result': 'Ergebnis',
    'isales.tasklogic.field.retryNumber': 'Wiederholungszeiten',
    'isales.tasklogic.field.callInterval': 'Abrufintervall (s)',
    'isales.task.title.calllogic': 'Logik für ausgehende Anrufe',
    'isales.blacklist.title.restrictTimes': 'Maximale Anzahl von Anrufen',
    'isales.blacklist.placeholder.restrictTimes': 'Geben Sie die maximale Anzahl von Anrufen ein',
    'isales.blacklist.title.restrictPeriod': 'Beschränkungszeitraum',
    'isales.blacklist.title.hour': 'Jede Stunde',
    'isales.blacklist.title.day': 'Jeden Tag',
    'isales.blacklist.message.Description': 'Maximale Anzahl von Anrufen pro Stunde oder Tag innerhalb des Gültigkeitszeitraums',
    'isales.blacklist.message.periodAndTime': 'Der Einschränkungszeitraum und die maximale Anzahl von Anrufen müssen gleichzeitig festgelegt oder leer gelassen werden.',
    'isales.blacklist.placeholder.searchname': 'Name',
    'isales.blacklist.placeholder.searchremark': 'Anmerkungen',
    'isales.blacklist.message.requestEmpty': 'Der Parameter für die Aufnahme der Nummer in die Blocklist konnte nicht validiert werden',
    'isales.blacklist.message.createSuccess': 'Die Nummer wurde erfolgreich zur Blocklist hinzugefügt',
    'isales.blacklist.message.createFail': 'Die Nummer konnte nicht zur Blocklist hinzugefügt werden',
    'isales.blacklist.message.error': 'Es tritt ein Fehler auf, wenn die Nummer der Sperrliste hinzugefügt wird',
    'isales.blacklist.message.confirmDelete': 'Möchten Sie die ausgewählten Nummern wirklich aus der Blocklist löschen?',
    'isales.blacklist.message.deleteBlacklist': 'Wählen Sie die zu löschende Blocklist-Nummer aus',
    'isales.blacklist.title.deleteError': 'Fehler',
    'isales.blacklist.message.paramEmpty': 'Wählen Sie eine Blocklist-Nummer aus',
    'isales.blacklist.message.deletePartiallyFail': 'Einige Nummern konnten nicht aus der Blocklist gelöscht werden',
    'isales.blacklist.title.deleteSuccess': 'Erfolg',
    'isales.blacklist.message.deleteSuccess': 'Die Nummer wurde erfolgreich aus der Blocklist gelöscht',
    'isales.calledNo.message.input': 'Es sind nur Ziffern zulässig',
    'isales.blacklist.message.calledno.empty': 'Es wurde keine Nummer eingegeben',
    'isales.blacklist.message.name.reg.empty': 'Die normale Namensregel ist leer. Wenden Sie sich an den Administrator, um es hinzuzufügen',
    'isales.blacklist.message.calledno.error': 'Die Nummer enthält ungültige Zeichen',
    'isales.blacklist.message.name.error': 'Der Name enthält ungültige Zeichen',
    'isales.blacklist.message.remark.error': 'Die Anmerkungen enthalten ungültige Zeichen',
    'isales.blacklist.message.calledno.exist': 'Die Nummer existiert in der Blocklist',
    'isales.blacklist.message.customer.notincall': 'Der Kunde hat keinen Anruf',
    'isales.blacklist.message.customer.empty': 'Kein Kunde in Kontakt',
    'isales.taskcalllogic.message.choosePolicy': 'Wählen Sie mindestens eine Ergebnisverarbeitungsstrategie aus',
    'isales.taskcalllogic.title.resultPolicySet': 'Ergebnisverarbeitungsstrategie',
    'isales.taskcalllogic.title.retryPolicySet': 'Richtlinie für Testanrufe',
    'isales.taskcalllogic.title.resultSetPolicy': 'Richtlinie basierend auf Ergebnissen konfigurieren',
    'isales.taskcalllogic.message.resultTips': 'Es kann nur eine Strategie für ein Ergebnis geben',
    'isales.taskcalllogic.field.customerbusy': 'Kunde beschäftigt',
    'isales.taskcalllogic.field.customerMissed': 'Kunde verpasst',
    'isales.taskcalllogic.field.customerRefused': 'Der Kunde lehnte ab',
    'isales.taskcalllogic.field.emptyNumber': 'Außerbetriebnahmenummer',
    'isales.taskcalllogic.field.shutdown': 'Kundenabschaltung',
    'isales.taskcalllogic.field.notServiceArea': 'Der Kunde befindet sich nicht im Servicebereich',
    'isales.taskcalllogic.field.fax': 'Fax\, Switchboard\ usw.',
    'isales.taskcalllogic.field.callTimeout': 'Anruf-Timeout',
    'isales.taskcalllogic.field.callFailed': 'Anruf fehlgeschlagen',
    'isales.taskcalllogic.field.other': 'Sonstige',
    'isales.management.message.blackCalledNo': 'Die angerufene Nummer befindet sich in der Blocklist und kann nicht hinzugefügt werden',
    'isales.taskstatistics.field.failCode': 'Fehlerursache',
    'isales.taskstatistics.field.failCount': 'Anzahl der fehlgeschlagenen Anrufe',
    'isales.appointment.title.appointTime': 'Reservierte Anrufzeit',
    'isales.appointment.title.task': 'Aufgabe für ausgehende Anrufe zuordnen',
    'isales.appointment.title.failCode': 'Fehlerursache',
    'isales.appointment.title.remark': 'Anmerkungen',
    'isales.appointment.title.call': 'Anruf',
    'isales.appointment.title.createAppoint': 'Reservierten ausgehenden Anruf erstellen',
    'isales.appointment.title.editAppoint': 'Reservierten ausgehenden Anruf ändern',
    'isales.appointment.placeholder.appointTime': 'Wählen Sie die reservierte Anrufzeit aus',
    'isales.appointment.placeholder.remark': 'Geben Sie Anmerkungen ein',
    'isales.appointment.message.afterNow': 'Die reservierte Zeit darf nicht vor der aktuellen Zeit liegen',
    'isales.appointment.message.createSuccess': 'Der reservierte ausgehende Anruf wurde erfolgreich erstellt',
    'isales.appointment.message.updateSuccess': 'Der reservierte ausgehende Anruf wurde erfolgreich geändert',
    'isales.appointment.message.createFail': 'Der reservierte ausgehende Anruf konnte nicht erstellt werden',
    'isales.appointment.message.updateFail': 'Der reservierte ausgehende Anruf konnte nicht geändert werden',
    'isales.manual.message.query.agentstatus.error': 'Bei der Abfrage des Agentenstatus ist ein Fehler aufgetreten',
    'isales.manual.message.agentstatus.isnofree': 'Der aktuelle Agent befindet sich nicht im Ruhezustand',
    'isales.manual.message.task.isnorunning': 'Die aktuelle Aufgabe für ausgehende Anrufe befindet sich nicht im Status Wird ausgeführt',
    'isales.manual.message.update.fail': 'Datenstatus des ausgehenden Anrufs konnte nicht aktualisiert werden',
    'isales.manual.message.data.query.error': 'Bei der Abfrage der Daten ausgehender Anrufe ist ein Fehler aufgetreten',
    'isales.manual.message.data.checklock.error': 'Die aktuellen Daten für ausgehende Anrufe sind nicht gesperrt oder wurden von einem anderen Agenten gesperrt',
    'isales.manual.message.data.checklock.inblack': 'Die Nummer des ausgehenden Anrufs befindet sich in der Sperrliste',
    'isales.manual.message.precall.success': 'Ein ausgehender Anruf wird initiiert',
    'isales.manual.title.fail.reason': 'Fehlerursache',
    'isales.manual.title.result': 'Ergebnis',
    'isales.manual.title.status': 'Status',
    'isales.manual.title.calledNo': 'Ausgehende Nummer',
    'isales.manual.title.inputfailcode': 'Geben Sie eine Fehlerursache ein',
    'isales.manual.title.inputresult': 'Geben Sie ein Ergebnis für ausgehende Anrufe ein',
    'isales.manual.title.inputstatus': 'Geben Sie den Status eines ausgehenden Anrufs ein',
    'isales.manual.field.callflag.inprocess': 'Anrufen',
    'isales.manual.field.callflag.finish': 'Abgeschlossen',
    'isales.manual.field.callflag.return': 'Zurück',
    'isales.manual.field.result.fail': 'Nicht bestanden',
    'isales.manual.field.result.success': 'Erfolgreich',
    'isales.manual.message.data.isalready.called': 'Der Kunde hat angerufen und kann keinen weiteren Anruf tätigen',
    'isales.manutask.title.viewdata': 'Daten für ausgehende Anrufe',
    'isales.manutask.title.viewresult': 'Ergebnis des ausgehenden Anrufs',
    'isales.manual.message.call.success': 'Der ausgehende Anruf wurde erfolgreich getätigt',
    'isales.manual.message.not.call': 'Es wird kein ausgehender Anruf getätigt',
    'isales.manual.message.call.failed': 'Ausgehender Anruf konnte nicht getätigt werden',
    'isales.manual.message.call.update.error': 'Ergebnis des ausgehenden Anrufs konnte nicht aktualisiert werden',
    'isales.manual.message.call.error': 'Bei der Verarbeitung des Ergebnisses des ausgehenden Anrufs ist ein Fehler aufgetreten',
    'isales.manualdata.title.lock': 'Sperre',
    'isales.manualdata.title.release': 'Freigabe',
    'isales.manualdata.title.order': 'Ausgehenden Anruf reservieren',
    'isales.manualdata.title.call': 'Anruf',
    'isales.manual.title.lock': 'Sperre',
    'isales.manual.message.confirmLockData': 'Möchten Sie die Daten für ausgehende Anrufe wirklich sperren?',
    'isales.manual.message.empty': '{0} ist leer',
    'isales.manual.message.lock.failed': 'Die ausgehenden Anrufdaten konnten nicht gesperrt werden',
    'isales.manual.message.lock.success': 'Die Daten des ausgehenden Anrufs wurden erfolgreich gesperrt',
    'isales.manual.message.error': 'Bei der Aktualisierung der Daten ist ein Fehler aufgetreten',
    'isales.manual.message.confirmReleaseData': 'Möchten Sie die Daten des ausgehenden Anrufs wirklich freigeben?',
    'isales.manual.message.release.success': 'Die Daten des ausgehenden Anrufs wurden erfolgreich freigegeben',
    'isales.manual.message.release.failed': 'Die Daten des ausgehenden Anrufs konnten nicht freigegeben werden',
    'isales.manual.message.releaseData': 'Wählen Sie die Daten der ausgehenden Anrufe aus, die freigegeben werden sollen',
    'isales.manual.message.lockData': 'Wählen Sie die zu sperrenden Daten für ausgehende Anrufe aus',
    'isales.manual.message.taskstatus.error': 'Der ausgehende Anruf befindet sich nicht im Status Ausführen',
    'isales.appointment.message.callNotAllowed': 'Kann keinen reservierten ausgehenden Anruf tätigen, da sich der Agent nicht im Leerlauf- oder Besetztzustand befindet',
    'isales.appointment.message.confirmDelete': 'Möchten Sie die reservierten ausgehenden Anrufe wirklich löschen?',
    'isales.appointment.message.deleteSuccess': 'Die reservierten ausgehenden Anrufe wurden erfolgreich gelöscht',
    'isales.appointment.message.deleteFail': 'Die reservierten ausgehenden Anrufe konnten nicht gelöscht werden',
    'isales.appointment.message.agentNotCalling': 'Kann keinen reservierten ausgehenden Anruf hinzufügen, da der Agent keinen Anruf führt',
    'isales.taskmanagement.title.viewAppointList': 'Reservierung',
    'isales.taskmanagement.title.viewAppointListDetail': 'Reservierung',
    'isales.taskmanagement.title.appoint': 'Ausgehenden Anruf reservieren',
    'isales.appointment.title.userName': 'Initiiert von',
    'isales.appointment.title.callTime': 'Anrufzeitsegment',
    'isales.manualdata.title.updateCalledNo': 'Ausgehende Nummer wird geändert',
    'isales.appointment.message.callSuccess': 'Erfolgreich',
    'isales.appointment.message.callFail': 'Nicht bestanden',
    'isales.appointment.message.timeout': '{0} ernannte ausgehende Anrufe haben die Terminzeit überschritten\, bitte rufen Sie rechtzeitig an',
    'isales.appointment.message.soontimeout': '{0} benannte ausgehende Anrufe treffen in den nächsten 5 Minuten ein\, Bitte bereiten Sie sich auf Anrufe vor',
    'isales.taskmanagement.field.manual': 'Ausgehender Agentenanruf',
    'isales.taskresult.title.userAccount': 'Initiiert von',
    'isales.taskresult.title.chooseUserAccount': 'Bitte wählen Sie initiiert von',
    'isales.taskmanagement.title.addManual': 'Ausgehenden Agentenanruf erstellen',
    'isales.taskinfo.field.noSpecifiedAgent': 'Gesamte Skill-Warteschlange',
    'isales.taskinfo.field.specifiedAgent': 'Spezifizierter Agent',
    'isales.taskinfo.title.chooseAgent': 'Agent auswählen',
    'isales.manual.message.afterNow': 'Die Endzeit darf nicht vor der aktuellen Zeit liegen',
    'isales.appointment.title.add': 'Hinzufügen',
    'isales.agent.addblocklist.numbertype': 'Nummerntyp',
    'isales.agent.addblocklist.calldatatype': 'Kundentyp',
    'isales.agent.toblocklist.addType': 'Blocklistentyp hinzufügen',
    'isales.data.message.confirmaddblock.calldatatype': 'Der aktuelle Typ sind Anrufdaten. Bitte bestätigen Sie erneut',
    'isales.taskmanagement.title.export': 'Anrufaufzeichnung exportieren',
    'isales.taskmanagement.field.zip.password': 'Kennwort für komprimiertes Paket',
    'isales.taskmanagement.field.zip.input.password': 'Geben Sie mindestens acht und maximal zwölf Zeichen ein, die Ziffern\, Buchstaben\ und Sonderzeichen enthalten, aber keine /\\-#(){\'{\'}{\'}\'};&{\'|\'}<>{\'$\'}`\\\\!\\n\\s\\t\\r',
    'isales.export.message.input.error': 'Das Kennwort hat nicht das richtige Format',
    'isales.taskmanagement.message.period.seven': 'Der Zeitraum darf nicht länger als 7 Tage sein',
    'isales.taskmanagement.title.view.callrecord': 'Anrufaufzeichnung',
    'isales.taskmanagement.field.calledNo': 'Angerufene Nummer',
    'isales.taskmanagement.field.calltime': 'Eingeleitet um',
    'isales.taskmanagement.field.usersaying': 'Anforderungstext',
    'isales.taskmanagement.field.answer': 'Antworttext',
    'isales.taskmanagement.title.callRecord': 'Aufzeichnung ausgehender Anrufe',
    'isales.taskmanagement.title.out.callresult': 'Ergebnis des ausgehenden Anrufs',
    'isales.taskmanagement.field.intention': 'Anerkannte Absicht',
    'isales.taskinfo.message.agentsEmpty': 'Es wurde kein Agent ausgewählt',
    'isales.appointment.message.queryTimeError': 'Die Startzeit muss vor der Endzeit liegen',
    'isales.taskinfo.field.audioAgentType': 'Audio',
    'isales.taskinfo.field.videoAgentType': 'Video',
    'isales.taskinfo.field.mediaAgentType': 'Multimedia',
    'isales.taskinfo.field.versatileAgentType': 'Vielseitig',
    'isales.appointment.message.tasknotrun': 'Die aktuelle Aufgabe für ausgehende Anrufe wurde nicht gestartet. Der Anruf ist verboten',
    'isales.appointment.message.notInWhiteList': 'Die Nummer befindet sich nicht in der Vertrauensliste und kann nicht angerufen werden',
    'isales.taskresult.field.failsCallednumbernotinwhitelist': 'Die Nummer befindet sich nicht in der Vertrauensliste für ausgehende Anrufe',
    'isales.taskresult.title.retryTime': 'Anrufversuche',
    'isales.appointment.message.callTimeTooEarly': 'Die Anrufzeit liegt vor der reservierten Zeit. Der Anruf ist verboten!',
    'isales.management.message.remarkLengthError': 'Es sind maximal 1000 Zeichen zulässig',
    'isales.management.message.callNoInBlackList': 'Die Nummer kann nicht angerufen werden, da sie auf der Blocklist steht',
    'isales.taskmanagement.field.zip.makesure.password': 'Geben Sie das Kennwort für das komprimierte Paket erneut ein',
    'isales.taskmanagement.message.password.not.same': 'Die beiden Passwörter stimmen nicht überein',
    'isales.taskresultexport.message.type': 'Ausfuhrart',
    'isales.taskpolicy.message.input.digits': 'Geben Sie eine ganze Zahl ein',
    'isales.tasktype.field.intelligent': 'Ausgehender Anruf des intelligenten Roboters',
    'isales.tasktype.field.predict': 'Prognostizierter ausgehender Anruf',
    'isales.tasktype.field.auto': 'Allgemeiner ausgehender IVR-Anruf',
    'isales.callNo.field.type': 'Nummerntyp',
    'isales.callNo.field.delayTime': 'Anrufausfall-Verzögerungszeit(en)',
    'isales.callNo.field.add': 'Hinzufügen',
    'isales.callno.message.delayTime': 'Anruffehler-Verzögerungszeit: Intervall zwischen der aktuellen und der vorherigen Nummer',
    'isales.data.message.addNumberUpperLimit': 'Es können maximal 10 Zahlen hinzugefügt werden',
    'isales.data.message.duplicateExist': 'Doppelte Nummern existieren',
    'isales.tasklogic.field.policyType': 'PolicyType',
    'isales.tasklogic.field.addCallnoLogic': 'Kontaktrichtlinie hinzufügen',
    'isales.tasklogic.field.dataType': 'Datentyp',
    'isales.tasklogic.field.isResultCallnoLogic': 'Ob die Konfiguration auf Grundlage des Ergebnisses erfolgen soll',
    'isales.taskcalllogic.field.globalPolicy': 'Globale Richtlinie',
    'isales.taskcalllogic.field.callnoPolicy': 'Kontaktrichtlinie',
    'isales.tasklogic.title.addCallnoPolicy': 'Ergebnisrichtlinie hinzufügen',
    'isales.tasklogic.field.addDataType': 'DataType hinzufügen',
    'isales.tasklogic.field.existingDataType': 'Wählen Sie einen vorhandenen Datentyp',
    'isales.taskcalllogic.message.alreadyLastPolicy': 'Schon letzte Richtlinie',
    'isales.data.filed.noResult': 'Nein',
    'isales.data.filed.isResult': 'Ja',
    'isales.taskcalllogic.message.dataTypeExist': 'DataType bereits vorhanden',
    'isales.busi.result.title.result': 'Ergebnis',
    'isales.busi.result.title.description': 'Beschreibung',
    'isales.busi.result.item.unset': 'Nicht festgelegt',
    'isales.busi.result.item.succ': 'Erfolg',
    'isales.busi.result.item.uncertain': 'Unsicher',
    'isales.busi.result.item.fail': 'Versagen',
    'isales.busi.result.message.recordsucc': 'Das Geschäftsergebnis wird erfolgreich erfasst',
    'isales.busi.result.message.recordfail': 'Das Geschäftsergebnis wird als gescheitert erfasst',
    'isales.busi.result.message.lastrecord': 'Pleanse legt das Geschäftsergebnis des letzten ausgehenden zuerst fest',
    'isales.busi.result.message.lastappointrecord': 'Pleanse legt das Geschäftsergebnis des letzten reservierten ausgehenden Anrufs zuerst fest',
    'isales.busi.result.message.nodatarecord': 'Es wurden keine Anrufinformationen gefunden. Bitte wählen Sie den Datensatz für ausgehende Anrufe aus, dessen Geschäftsergebnis festgelegt werden muss',
    'isales.data.message.confirmSubmit': 'Die aktuelle Nummernfolge ist die Anruffolge. Bitte überprüfen Sie die Daten erneut',
    'isales.data.title.tableTitle': 'Nummernliste',
    'isales.callNo.title.no': 'Nein',
    'isales.callpolicy.title.add': 'Hinzufügen',
    'isales.management.message.updateSuccess': 'Die Daten des ausgehenden Anrufs wurden erfolgreich geändert',
    'isales.management.message.updateFail': 'Die Daten des ausgehenden Anrufs konnten nicht geändert werden',
    'isales.blacklist.title.validityTime': 'Gültigkeitsdauer (Stunde)',
    'isales.blacklist.title.expirationTime': 'Ablaufzeit',
    'isales.blacklist.title.operateAgent': 'Agent hinzugefügt',
    'isales.management.title.updateData': 'Daten ausgehender Anrufe ändern',
    'isales.management.title.detailData': 'Datendetails für ausgehende Anrufe',
    'isales.common.message.checknonnegativeinteger': 'Geben Sie eine nicht negative Ganzzahl im richtigen Format ein',
    'isales.common.message.checkpositiveinteger': 'Geben Sie eine positive Ganzzahl im richtigen Format ein',
    'isales.taskcalllogic.message.mostPolicys': 'Für eine bestimmte Anzahl können maximal 10 Ergebnisrichtlinien konfiguriert werden',
    'isales.tasklogic.field.callresultpolicy': 'Ergebnisrichtlinie für ausgehende Anrufe',
    'isales.tasklogic.field.busiresultpolicy': 'Geschäftsergebnispolitik',
    'isales.tasklogic.field.enablebusiresultpolicy': 'Die Geschäftsergebnispolitik ermöglichen',
    'isales.tasklogic.field.connectsameagent': 'Verbindung mit demselben Agenten herstellen',
    'isales.tasklogic.field.addbusiresultpolicy': 'Richtlinie für Geschäftsergebnisse hinzufügen',
    'isales.tasklogic.field.substate': 'Unterstaat',
    'isales.tasklogic.field.policy': 'Richtlinie',
    'isales.tasklogic.field.interval': 'Intervall (Sekunde)',
    'isales.common.title.edit': 'Bearbeiten',
    'isales.taskcalllogic.busiresultpolicy.stopcall': 'Anruf stoppen',
    'isales.taskcalllogic.busiresultpolicy.callnext': 'Nächste Nummer anrufen',
    'isales.taskcalllogic.busiresultpolicy.callcurrent': 'Die aktuelle Nummer anrufen',
    'isales.tasklogic.message.busiresultpolicyIncomplete': 'Die Informationen zur Geschäftsergebnispolitik sind unvollständig',
    'isales.tasklogic.message.busiresultpolicyNoadded': 'Es wird keine Geschäftsergebnisrichtlinie hinzugefügt',
    'isales.tasklogic.message.busiresultpolicyNotsaved': 'Einige Geschäftsergebnisrichtlinien werden nicht gespeichert',
    'isales.tasklogic.message.busiresultpolicySame': 'Die Geschäftsergebnisse und Unterstatus mehrerer Geschäftsergebnisrichtlinien sind identisch',
    'isales.tasklogic.message.busiresultpolicyExists': 'Es gibt bereits eine Geschäftsergebnispolitik mit dem gleichen Geschäftsergebnis und dem gleichen Teilstatus',
    'isales.business.result.searchname': 'Geben Sie den Namen des Geschäftsergebnisses ein',
    'isales.business.result.name': 'Bezeichnung des Geschäftsergebnisses',
    'isales.business.result.code': 'Kennzahl des Geschäftsergebnisses',
    'isales.business.result.description': 'Beschreibung des Geschäftsergebnisses',
    'isales.business.result.create': 'Geschäftsergebnis erzielen',
    'isales.business.result.edit': 'Geschäftsergebnis ändern',
    'isales.business.result.view': 'Geschäftsergebnis anzeigen',
    'isales.business.result.children': 'Unterstatus',
    'isales.business.name': 'Name',
    'isales.business.code': 'Code',
    'isales.business.description': 'Beschreibung',
    'isales.business.result.addchildren': 'Unterstatus erstellen',
    'isales.business.result.deletetips': 'Möchten Sie das Geschäftsergebnis wirklich löschen?',
    'isales.business.result.delete.error': 'Es wurde kein Geschäftsergebnis ausgewählt',
    'isales.business.result.addchildrentips': 'In einem Geschäftsergebnis sind maximal 50 Teilgeschäftsergebnisse zulässig',
    'isales.business.result.message.specialStr': 'Nicht unterstützt',
    'isales.business.result.message.checkCode': 'Nur Ziffern\, Buchstaben\ und Bindestriche (-) sind zulässig',
    'isales.business.result.delete.data.error': 'Es können maximal 100 Datensätze gleichzeitig gelöscht werden',
    'isales.tasklogic.message.busiresultpolicyNotSaved': 'Speichern Sie zuerst die vorhandene Geschäftsergebnisrichtlinie!',
    'isales.taskpolicy.field.previewTimeoutInterval': 'Vorschau-Timeout-Intervall (s)',
    'isales.tasktype.field.preview': 'Vorschau des ausgehenden Anrufs',
    'isales.manual.field.previewinformation': 'Vorschauinformationen',
    'isales.manual.field.handlednumber': 'Sitzungsnummer',
    'isales.manual.field.callednumber': 'Angerufene Nummer',
    'isales.manual.field.callinginformation': 'Anrufinformationen',
    'isales.manual.field.callingtask': 'Aufrufaufgabe',
    'isales.taskresult.title.userName': 'Benutzername',
    'isales.taskresult.title.operateBeginTime': 'beginTime',
    'isales.taskresult.title.operateEndTime': 'Endzeit',
    'isales.taskresult.title.operationTime': 'Betriebszeit',
    'isales.taskresult.title.busiresultLog': 'Geschäftsergebnisprotokoll',
    'isales.manual.message.release.DataNotWaitExists': 'Ausgehende Anrufdaten, die abgerufen oder abgeschlossen werden, sind vorhanden',
    'isales.manual.message.dataId.Empty': 'Daten-ID darf nicht leer sein',
    'isales.busi.result.message.agentstatus.fail': 'Agentenstatus konnte nicht abgefragt werden',
    'isales.busi.result.message.systemparams.fail': 'Systemparameter konnten nicht abgefragt werden',
    'isales.business.result.message.add.tips': 'Das Geschäftsergebnis ist nicht definiert',
    'isales.business.result.message.must': 'Dieser Parameter ist obligatorisch',
    'isales.blacklist.title.modifyRemark': 'Anmerkung ändern',
    'isales.blacklist.message.updateSuccess': 'Änderungskommentar erfolgreich',
    'isales.taskresult.field.userisunleashed': 'Der Anruf auf dem Medienserver, bei dem sich der Agent anmeldet, wird freigegeben',
    'isales.taskresult.field.invalidCall': 'Ungültiger Anruf',
    'isales.taskresult.field.notCheckIn': 'Der Agent hat sich nicht angemeldet',
    'isales.manual.title.viewManualData': 'Daten für ausgehende Agentenanrufe-',
    'isales.manual.title.viewManualResult': 'Ergebnis des ausgehenden Agentenanrufs-',
    'isales.manual.title.viewPreviewResult': 'Vorschau des Ergebnisses des ausgehenden Anrufs-',
    'isales.manual.title.viewPredictionResult': 'Prognostiziertes Ergebnis des ausgehenden Anrufs-',
    'isales.taskresult.field.workNo': 'WorkNo',
    'isales.taskresult.field.skillqueue': 'Skill-Warteschlange',
    'isales.taskresult.field.operation': 'Operation',
    'isales.agent.field.skill': 'SkillName',
    'isales.agent.message.pwdRepeat': 'Das gesendete Kennwort stimmt mit dem kürzlich geänderten Kennwort überein',
    'isales.data.prop.publish': 'Veröffentlichen',
    'isales.data.prop.status.published': 'Veröffentlicht',
    'isales.taskresult.field.other': 'Sonstiges',
    'isales.taskmanagement.title.speclistmanage.reason': 'der Grund',
    'isales.speclistmanage.title.calledNo': 'Anzahl',
    'isales.speclistmanage.title.reason': 'Grund',
    'isales.speclistmanage.title.operation': 'In Betrieb',
    'isales.specreason.field.all': 'Bitte wählen',
    'isales.specialday.title.confirm': 'Bestätigen',
    'isales.tasklogic.field.addOrderPolicy': 'Requisite hinzufügen',
    'isales.tasklogic.field.prop': 'Eigentum',
    'isales.skillqueue.agentrole.administrator': 'Raummanager',
    'isales.skillqueue.agentrole.qc': 'Qualitätsprüfer',
    'isales.data.prop.orderType': 'Auftragsart',
    'isales.data.orderType.asc': 'Aufsteigend',
    'isales.data.orderType.desc': 'Absteigend',
    'isales.speclist.blacklist.message.moveSuccess': 'Nummer erfolgreich an Schwarze Liste übertragen',
    'isales.speclist.blacklist.message.movePartiallyFail': 'Übertragung der Nummer auf die Schwarze Liste fehlgeschlagen',
    'isales.taskinfo.field.callTimes': 'Anrufversuche',
    'isales.taskmanagement.title.batchStartTask': 'Stapelstart',
    'isales.taskmanagement.title.batchPauseTask': 'Stapel anhalten',
    'isales.taskmanagement.title.batchEdit': 'Stapel ändern',
    'isales.taskmanagement.title.batchEditTaskInfo': 'Batch Modify Basic Information',
    'isales.taskmanagement.title.batchEditCallTime': 'Zeit für ausgehende Anrufe stapelweise ändern',
    'isales.taskmanagement.title.batchEditCallPolicy': 'Batch Modify Richtlinie für ausgehende Anrufe',
    'isales.taskmanagement.title.batchEditCallLogic': 'Batch Modify Outbound Call Logic (Batch-Änderung der Logik für ausgehende Anrufe)',
    'isales.taskmanagement.title.tipsBatchStartTask': 'Wählen Sie die zu startende Aufgabe aus',
    'isales.taskmanagement.title.batchStart.finishedStatus': 'Abgeschlossene Aufgaben können nicht gestartet werden',
    'isales.taskmanagement.title.batchPause.statusError': 'Aufgaben im Status Entwurf oder Abgelaufen können nicht ausgesetzt werden',
    'isales.taskmanagement.title.startCount': 'Es können maximal 30 ausgehende Anrufaufgaben gleichzeitig gestartet werden',
    'isales.taskmanagement.title.startConfirm': 'Möchten Sie die ausgewählte Aufgabe für ausgehende Anrufe wirklich starten?',
    'isales.taskmanagement.title.tipsBatchPauseTask': 'Wählen Sie die Aufgabe aus, die ausgesetzt werden soll',
    'isales.taskmanagement.title.pauseCount': 'Es können maximal 30 ausgehende Anrufaufgaben gleichzeitig unterbrochen werden',
    'isales.taskmanagement.title.pauseConfirm': 'Möchten Sie die ausgewählte Aufgabe für ausgehende Anrufe wirklich unterbrechen?',
    'isales.taskmanagement.title.batchEditTaskInfo.errStatus': 'Wählen Sie eine Aufgabe im Status \,Entwurf\, Ausgesetzt\, oder Ausführung\, aus',
    'isales.taskmanagement.title.batchEditCallPolicy.sameTaskType': 'Richtlinien für ausgehende Anrufe können nur für Aufgaben desselben Typs stapelweise geändert werden',
    'isales.taskmanagement.title.batchEditCallTime.manualError': 'Die Zeit des manuellen ausgehenden Anrufs kann nicht geändert werden',
    'isales.taskmanagement.title.batchEditTaskInfo.manual': 'Automatisch ausgehender Anruf und manuell ausgehender Anruf können nicht gleichzeitig geändert werden',
    'isales.taskmanagement.title.batchEditCallPolicy.manualError': 'Richtlinien für manuelle ausgehende Anrufe können nicht geändert werden',
    'isales.task.message.NoStartTask': 'Es ist keine Aufgabe verfügbar, die die Bedingungen erfüllt',
    'isales.task.message.NoPauseTask': 'Es ist keine Aufgabe verfügbar, die die Bedingungen erfüllt',
    'isales.taskinfo.field.priority': 'Priorität',
    'isales.taskmanagement.title.editInfo': 'Informationen ändern',
    'isales.taskmanagement.title.editSample': 'Objektverwaltung',
    'isales.taskmanagement.title.editTime': 'Zeit ändern',
    'isales.taskmanagement.title.editLogic': 'Logik ändern',
    'isales.taskpolicy.field.calculationFactor': 'Berechnungsfaktor',
    'isales.taskpolicy.field.pickupRate': 'Ablegerrate',
    'isales.taskresult.title.inputSessionId': 'Geben Sie die Sitzungsnummer ein',
    'isales.taskstatus.field.waiting': 'Warten',
    'isales.taskstatus.field.callfinish': 'Abgeschlossen',
    'isales.taskInfo.message.saveSuccess': 'Die Informationen wurden erfolgreich geändert',
    'isales.taskInfo.message.saveFail': 'Die Informationen konnten nicht geändert werden',
    'isales.tasklogic.field.callpolicy': 'Anrufrichtlinie',
    'isales.tasklogic.field.callmethod': 'Anrufmodus',
    'isales.tasklogic.field.callbyorder': 'Aufeinanderfolgend',
    'isales.tasklogic.field.callbyturn': 'Durch Runde',
    'isales.tasklogic.field.callturntype': 'Rundmodus',
    'isales.tasklogic.field.callturntype.numbertype': 'Nach Nummerntyp',
    'isales.tasklogic.field.callturntype.numberorder': 'Nach fortlaufender Nummer',
    'isales.tasklogic.field.addturn': 'Runde hinzufügen',
    'isales.tasklogic.field.turn': 'Rund',
    'isales.tasklogic.field.numberorder': 'Sequenznummer',
    'isales.tasklogic.field.numbertype': 'Nummerntyp',
    'isales.tasklogic.field.add.numbertype': 'Neuer Nummerntyp',
    'isales.tasklogic.field.exist.numbertype': 'Vorhandener Nummerntyp',
    'isales.tasklogic.field.default': 'Standard',
    'isales.tasklogic.message.no.numbertype': 'Die Nummernfolgenummer ist null.',
    'isales.tasklogic.message.exist.orderNo': 'Die Nummernfolge existiert bereits. Geben Sie eine andere ein',
    'isales.tasklogic.message.need.turns': 'Fügen Sie mindestens ein Rundendatum hinzu',
    'isales.taskpolicy.field.previewAutoCallTime': 'Dauer des in der Vorschau angezeigten ausgehenden Anrufs',
    'isales.taskinfo.message.autoCallTimeValueError': 'Die Dauer des in der Vorschau angezeigten ausgehenden Anrufs muss kürzer sein als die Dauer des in der Vorschau angezeigten Timeouts',
    'isales.taskinfo.message.batchUpdate.paramsNull': 'Bearbeiten Sie den zu ändernden Parameter',
    'isales.agent.contact.Voice': 'Stimme',
    'isales.agent.contact.multimedia': 'Multimedia',
    'isales.agent.contact.vidio': 'Video',
    'isales.agent.contact.audioclicktodial': 'Voice Click-to-Wal',
    'isales.agent.contact.videoclicktodial': 'Video-Klick-zum-Wählen',
    'isales.agent.message.searchSkillName': 'Geben Sie den Namen der Skill-Warteschlange ein',
    'isales.agent.button.agent.association': 'Agenten konfigurieren',
    'isales.agent.title.skillCreate': 'Skill-Queue erstellen',
    'isales.agent.message.updateCtiAndDbSuccess': 'Die Skill-Warteschlange wurde erfolgreich aktualisiert',
    'isales.agent.message.deleteSkillAndAgentFail': 'Die Zuordnung der Skill-Warteschlange konnte nicht gelöscht werden',
    'isales.agent.message.addSkillFail': 'Die Skill-Warteschlange konnte nicht hinzugefügt werden',
    'isales.agent.message.modifySkillFail': 'Die Skill-Warteschlange konnte nicht aktualisiert werden',
    'isales.agent.message.queryCallCenterFail': 'Call-Center-Daten konnten nicht abgefragt werden',
    'isales.agent.message.refreshSkillException': 'Bei der Aktualisierung der Skill-Warteschlange ist ein Fehler aufgetreten',
    'isales.agent.message.deleteSkillConfFail': 'Die Informationen der Skill-Warteschlange konnten nicht gelöscht werden',
    'isales.agent.message.agentMsgException': 'Anfangskennwort des Agenten ändern und das Kennwort mit dem Agenten synchronisieren',
    'isales.agent.label.skillName': 'Skill-Warteschlangenname',
    'isales.agent.label.maxWaitTime': 'Max. Warteschlangenzeit',
    'isales.agent.label.agentList': 'Agenten-ID',
    'ccivr.ivr.ivrflow.type': 'Typ',
    'isales.agent.label.adjustTime': 'Nachbearbeitungsdauer (s)',
    'isales.agent.title.skillModify': 'Skill-Warteschlange ändern',
    'isales.agent.message.deleteSkill': 'Skill-Warteschlange löschen',
    'isales.agent.message.willDeleteSkills': 'Dieser Vorgang kann dazu führen, dass der der Skill-Warteschlange zugeordnete ausgehende Aufgabenanruf fehlschlägt. Bitte gehen Sie mit Vorsicht vor. Müssen Sie sie löschen?',
    'isales.agent.message.deleteSkillConfSuccess': 'Die Skill-Warteschlange wurde erfolgreich gelöscht',
    'isales.agent.message.idIsEmpty': 'Die Skill-Warteschlangen-ID darf nicht leer sein',
    'isales.agent.label.theAgent': 'Agent',
    'isales.agent.label.useTheSkill': 'Löschen Sie die Bindungsbeziehung zwischen dem Agenten und der Skill-Warteschlange in Agent konfigurieren',
    'isales.agent.label.skillQueue': 'Skill-Warteschlange:',
    'isales.agent.label.bindTheSkill': 'Die Konfiguration des angerufenen Teilnehmers wurde gebunden. Binden Sie es zuerst los',
    'isales.agent.label.deleteCtiFailed': 'Daten konnten nicht gelöscht werden',
    'isales.agent.message.skillConfFail': 'Die Skill-Warteschlange konnte nicht konfiguriert werden',
    'isales.agent.message.selectSkills': 'Wählen Sie eine Skill-Warteschlange aus',
    'isales.agent.label.skills': 'Skill-Warteschlange',
    'isales.agent.label.release': 'Freigabe',
    'isales.agent.label.transfer': 'Überweisung',
    'isales.agent.label.playVoice': 'Warteton spielen',
    'isales.agent.label.maxWaitNum': 'Max. Anrufe in der Warteschleife',
    'isales.agent.label.skillpara': 'Parametereinstellungen',
    'isales.agent.label.timeOut': 'Warteschlangen-Timeout',
    'isales.agent.label.transferType': 'Handhabungsmodus',
    'isales.agent.Transfer.devicetype': 'Zielgerätetyp',
    'isales.agent.label.ivrChoice': 'IVR',
    'isales.agent.label.deviceType': 'Gerätetyp',
    'isales.agent.label.skillChoice': 'Skill-Warteschlange',
    'isales.agent.label.noAgents': 'Abwesenheit eines diensthabenden Agenten',
    'isales.agent.label.waitVoice': 'Wartender Ton',
    'isales.agent.label.parameterType': 'Warteschlangenmodus',
    'isales.agent.label.answerMode': 'Agentenantwortmodus',
    'isales.agent.label.busy': 'Besetzte Warteschlange oder Überlauf',
    'isales.agent.label.answerType': 'Agentenantwortmodus',
    'isales.agent.label.answerType0': 'Berichtsagenten-ID',
    'isales.agent.label.answerType2': 'ID des Stimm-nicht-Berichtspflichtigen',
    'isales.agent.message.name.limit': 'Der Name der Skill-Warteschlange überschreitet die maximale Länge',
    'isales.agent.calledroute.require': 'Alle Pflichtfelder festlegen',
    'isales.agent.message.createSkillConfSuccess': 'Die Skill-Warteschlange wurde erfolgreich erstellt',
    'isales.agent.calledroute.gateway.fail': 'Daten konnten nicht mit der CTI-Plattform synchronisiert werden. Überprüfen Sie die Gateway-Konfiguration',
    'isales.skillcreate.maxnumber.failed': 'Die Anzahl der Mandanten-Skill-Warteschlangen darf die Obergrenze nicht überschreiten',
    'isales.agent.message.createSkillConfFail': 'Die Skill-Warteschlange konnte nicht erstellt werden',
    'isales.agent.accesscode.description.limit': 'Die Beschreibung des Zugangscodes darf maximal 50 Zeichen enthalten',
    'isales.agent.message.modifySkillConfSuccess': 'Die Skill-Warteschlange wurde erfolgreich geändert',
    'isales.agent.message.modifySkillConfFail': 'Die Skill-Warteschlange konnte nicht geändert werden',
    'isales.agent.label.maxWaitTimeSpan': 'Der Wert liegt zwischen 0 und 60000',
    'isales.agent.label.maxAgentWrapupTime': 'Der Wert liegt zwischen 0 und 3600',
    'isales.agent.contact.mediaType': 'Medientyp',
    'isales.calledroute.title.selectivrflow': 'Wählen Sie einen intelligenten Roboter',
    'isales.management.message.SelectNumber': 'Wählen Sie eine Nummer aus',
    'isales.taskmanagement.title.tip.batchEditTask': 'Wählen Sie die zu ändernde Aufgabe aus',
    'isales.taskmanagement.title.editCountOutOfBounds': 'Es können maximal 30 ausgehende Anrufaufgaben gleichzeitig geändert werden',
    'isales.taskinfo.message.batchEditCallLogic.differentPolicyTypeExist': 'Die Logik für ausgehende Anrufe kann nicht stapelweise für die Aufgaben mit unterschiedlichen Richtlinientypen geändert werden',
    'isales.management.message.batchEditCallLogic.notSupportBusiresult': 'Geschäftsergebnisrichtlinien können nicht für automatische oder intelligente ausgehende Anrufe konfiguriert werden. Möchten Sie die Stapeländerung wirklich ausführen?',
    'isales.management.message.batchEditCallLogic.notSupportBusiresultInCMS': 'Geschäftsergebnisrichtlinien können nicht für automatische ausgehende Anrufe konfiguriert werden. Möchten Sie die Stapeländerung wirklich ausführen?',
    'isales.management.message.batchEditCallLogic.statusError': 'Die Logik für ausgehende Anrufe kann nur für Aufgaben im Status Entwurf, Ausführung oder Unterbrochen stapelweise geändert werden',
    'isales.tasklogic.message.confirmBatchUpdate': 'Die Stapeländerung gilt nur für ausgewählte Regionen. Möchten Sie wirklich fortfahren?',
    'isales.taskinfo.message.batchEditCallLogic.manualExist': 'Logik manueller ausgehender Anrufe kann nicht geändert werden',
    'isales.taskinfo.message.batchEditCallLogic.selectEmpty': 'Der Vorgang ist ungültig, da keine Region ausgewählt wurde. Wählen Sie eine Region',
    'isales.taskinfo.message.batchEditCallLogic.differentCallModeExist': 'Die Logik für ausgehende Anrufe kann nicht stapelweise für die Aufgaben mit verschiedenen Anrufmodi geändert werden',
    'isales.taskinfo.message.batchEditCallLogic.differentRoundTypeExist': 'Die Logik für ausgehende Anrufe kann nicht stapelweise für die Aufgaben mit unterschiedlichen Anrufrunde-Modi geändert werden',
    'isales.taskinfo.message.batchEditCallLogic.CallPolicyNotification': 'Die bestehenden Runden bleiben unverändert\, und neue Runden werden neben den bestehenden hinzugefügt',
    'isales.taskinfo.message.batchEditCallLogic.CallResultNotification': 'Bestehende Richtlinien können geändert werden\, und neue Richtlinien werden neben den bestehenden hinzugefügt',
    'isales.taskinfo.message.batchEditCallLogic.BusiResultNotification': 'Neue Richtlinien werden die bestehenden überschreiben',
    'isales.taskcalllogic.message.typeConflictWhenDataTypeSame': 'Es existiert eine Richtlinie, deren Nummerntyp mit dem der vorhandenen Richtlinie identisch ist\, aber je nach Ergebnis werden unterschiedliche Richtlinien konfiguriert',
    'isales.taskcalllogic.message.callNoPolicyOutOfRangeAfetUpdate': 'Nachdem die Anzahl der nicht standardmäßigen Nummernrichtlinien in der Richtlinie für das Ergebnis ausgehender Anrufe geändert wurde\, wird das Maximum von fünf überschritten',
    'isales.taskcalllogic.message.roundPolicyLengthTooLong': 'Nach der Änderung der Anzahl der Runden in der Anrufrichtlinie\ wird die maximale Länge überschritten',
    'isales.taskcalllogic.message.dataTypeDefaultNoCreate': 'Standardnummerntyp',
    'isales.taskcalllogic.message.callNoPolicyEmpty': 'Fügen Sie mindestens eine Nummernrichtlinie hinzu',
    'isales.result.search.displayResult': 'Geben Sie die Fehlerursache für den ausgehenden Anruf ein',
    'isales.result.resultCode': 'Ursachencode',
    'isales.result.displayResult': 'Fehlerursache für ausgehenden Anruf',
    'isales.result.view': 'Fehlerursache für ausgehenden Anruf anzeigen',
    'isales.result.edit': 'Fehlerursache für ausgehenden Anruf ändern',
    'isales.result.delete.error': 'Wählen Sie eine Fehlerursache für ausgehende Anrufe aus',
    'isales.result.deletetips': 'Möchten Sie die Fehlerursache des ausgehenden Anrufs wirklich löschen?',
    'isales.result.create': 'Fehlerursache für ausgehenden Anruf erstellen',
    'isales.result.outgoingCallResult': 'Ergebnis des ausgehenden Anrufs',
    'isales.result.addOutgoingCallResult': 'Ergebnis hinzufügen',
    'isales.result.outgoingCall.failCode': 'Ergebniscode',
    'isales.result.outgoingCall.Description': 'Beschreibung',
    'isales.result.addResultMaptips': 'Es können maximal 50 Ergebnisse ausgehender Anrufe hinzugefügt werden',
    'isales.result.addAgentTips': 'Es können maximal 20000 Agenten hinzugefügt werden',
    'isales.result.outgoingCall.input.description': 'Geben Sie den Ergebniscode oder die Beschreibung des ausgehenden Anrufs ein',
    'isales.result.obResultIsNull': 'Das Ergebnis des ausgehenden Anrufs der Fehlerursache darf nicht leer sein',
    'isales.calledroute.title.selectskill': 'Skill-Warteschlange auswählen',
    'isales.calledroute.title.selectagent': 'Agent auswählen',
    'isales.agent.placeholder.agentdesc': 'Geben Sie einen Agentennamen ein',
    'isales.agent.label.workNo': 'Agenten-ID',
    'isales.agent.label.accountName': 'Agentenname',
    'isales.skillqueue.button.add': 'Skill-Warteschlange hinzufügen',
    'isales.specialday.message.enterworkno': 'Geben Sie eine Agenten-ID ein',
    'isales.taskresult.field.sipAccount': 'Softphone-Nummer',
    'isales.taskresult.field.agentRole': 'Name der Agentenrolle',
    'isales.taskInfo.message.skillAlreadyExists': 'Die Skill-Warteschlange existiert bereits',
    'isales.agent.message.saveSuccess': 'Die Agentenkonfiguration wurde erfolgreich gespeichert',
    'isales.agent.field.skillWeight': 'Fertigkeitsgewicht',
    'isales.agent.label.agentWeight': 'Agentengewicht',
    'isales.agent.message.chooseAnAgent': 'Wählen Sie einen Agenten aus',
    'isales.agent.message.batchSaveSuccess': 'Die Agenten wurden erfolgreich in Batches modifiziert',
    'isales.agent.message.batchSaveFail': 'Die Agenten konnten nicht stapelweise geändert werden',
    'isales.agent.message.configAgent': 'Agenten konfigurieren oder synchronisieren',
    'isales.tasktemplate.title.saveAsTemplate': 'Als Vorlage speichern',
    'isales.taskmanagement.title.createDirectly': 'Direkt erstellen',
    'isales.taskmanagement.title.newFromTemplate': 'Mit Vorlage erstellen',
    'isales.taskmanagement.title.selectTemplate': 'Vorlage auswählen',
    'isales.tasktemplate.title.inputTemplateName': 'Geben Sie einen Vorlagennamen ein',
    'isales.tasktemplate.title.addTemplate': 'Vorlage für ausgehende Anrufe erstellen',
    'isales.tasktemplate.title.editTemplate': 'Vorlage für ausgehende Anrufe ändern',
    'isales.tasktemplate.title.viewTemplate': 'Vorlage für ausgehende Anrufe anzeigen',
    'isales.tasktemplate.title.autoTemplate': 'Vorlage für automatische ausgehende Anrufe',
    'isales.tasktemplate.title.manualTemplate': 'Vorlage für manuelle ausgehende Anrufe',
    'isales.tasktemplate.title.addAutoTemplate': 'Vorlage für automatische ausgehende Anrufe erstellen',
    'isales.tasktemplate.title.addManualTemplate': 'Manuelle Vorlage für ausgehende Anrufe erstellen',
    'isales.tasktemplate.field.templateName': 'Vorlagenname',
    'isales.tasktemplate.field.release': 'Veröffentlichen',
    'isales.tasktemplate.field.released': 'Veröffentlicht',
    'isales.tasktemplate.message.deleteConfirm': 'Möchten Sie die ausgewählte Vorlage für ausgehende Anrufe wirklich löschen?',
    'isales.tasktemplate.message.releasesuccess': 'Veröffentlichung erfolgreich',
    'isales.tasktemplate.message.releasefailed': 'Veröffentlichung fehlgeschlagen',
    'isales.tasktemplate.message.pauseFail': 'Aussetzung fehlgeschlagen',
    'isales.tasktemplate.message.pauseSuccess': 'Aussetzung erfolgreich',
    'isales.tasktemplate.message.saveFail': 'Die Vorlage für ausgehende Anrufe konnte nicht konfiguriert werden',
    'isales.tasktemplate.message.saveSuccess': 'Die Vorlage für ausgehende Anrufe wurde erfolgreich konfiguriert',
    'isales.tasktemplate.message.nameDuplicated': 'Es existiert bereits eine Vorlage für ausgehende Anrufe mit demselben Namen',
    'isales.data.prop.propName': 'Attributname',
    'isales.data.prop.propCode': 'Attributcode',
    'isales.data.prop.propType': 'Attributtyp',
    'isales.data.prop.isPushAgent': 'Zum Agenten drücken',
    'isales.data.prop.mandatory': 'Obligatorisch',
    'isales.data.prop.anonym': 'Anonymisiert',
    'isales.data.prop.status': 'Status',
    'isales.data.prop.stringType': 'String',
    'isales.data.prop.numberType': 'Ziffer',
    'isales.data.prop.anonymType': 'Verschlüsselung',
    'isales.data.prop.status.unpublished': 'Nicht veröffentlicht',
    'isales.data.prop.editDataProp': 'Attribut ändern',
    'isales.data.prop.createDataProp': 'Attribut erstellen',
    'isales.data.prop.inputPropName': 'Geben Sie einen Attributnamen ein',
    'isales.data.prop.inputPropCode': 'Geben Sie einen Attributcode ein',
    'isales.data.prop.editDataPropSuccess': 'Das Attribut wurde erfolgreich geändert',
    'isales.data.prop.createDataPropSuccess': 'Das Attribut wurde erfolgreich erstellt',
    'isales.data.prop.editDataPropFail': 'Attribut konnte nicht geändert werden',
    'isales.data.prop.createDataPropFail': 'Attribut konnte nicht erstellt werden',
    'isales.data.prop.propParamsError': 'Attributparameter konnte nicht verifiziert werden',
    'isales.data.prop.propCodeExists': 'Der Attributcode existiert bereits',
    'isales.data.prop.propNameExists': 'Der Attributname ist bereits vorhanden',
    'isales.data.prop.message.confirmDelete': 'Möchten Sie das ausgewählte Attribut wirklich löschen?',
    'isales.data.prop.stringType.countError': 'Es können maximal 30 Zeichenkettenattribute definiert werden',
    'isales.data.prop.numberType.countError': 'Es können maximal 10 stellige Attribute definiert werden',
    'isales.data.prop.isPushAgent.countError': 'Es können maximal 15 Attribute definiert werden, die an einen Agenten gepusht werden',
    'isales.data.prop.anonymType.countError': 'Es können maximal 10 Verschlüsselungstypattribute definiert werden',
    'isales.data.prop.isPushAgent.anonymTypeError': 'Die Attribute des Verschlüsselungstyps können nicht an einen Agenten gepusht werden',
    'isales.data.propConfig.button.configProp': 'Attribute festlegen',
    'isales.data.propConfig.title.isPushAgent': 'Zum Agenten drücken',
    'isales.data.propConfig.message.isUseProp': 'Benutzerdefiniertes Attribut aktivieren',
    'isales.data.propConfig.title.prop': 'Attribut',
    'isales.file.title.ip': 'IP-Adresse',
    'isales.file.title.port': 'Hafen',
    'isales.file.title.username': 'Benutzername',
    'isales.file.title.filepath': 'Standardpfad',
    'isales.file.title.password': 'Passwort',
    'isales.file.title.ip.placeholder': 'Geben Sie die IP-Adresse des Servers ein',
    'isales.file.title.port.placeholder': 'Geben Sie die Serverportnummer ein',
    'isales.file.title.username.placeholder': 'Geben Sie einen Benutzernamen ein',
    'isales.file.title.filepath.placeholder': 'Geben Sie einen Dateipfad ein\, z. B.\/home',
    'isales.file.title.password.placeholder': 'Geben Sie das Kennwort ein',
    'isales.file.title.addserver': 'Server hinzufügen',
    'isales.file.title.server.info': 'Die Serverinformationen wurden erfolgreich geändert',
    'isales.file.title.server.fail': 'Die Serverinformationen konnten nicht geändert werden',
    'isales.file.title.delete.fail': 'Die Serverinformationen konnten nicht gelöscht werden',
    'isales.file.title.editserver': 'Server ändern',
    'isales.file.title.rightpath': 'Geben Sie einen gültigen Pfad ein, z. B. /isales/upload',
    'isales.file.title.rule.basic': 'Grundlegende Informationen',
    'isales.file.title.rule.name': 'Regelname',
    'isales.file.title.rule.desc': 'Beschreibung',
    'isales.file.title.addrule': 'Regel hinzufügen',
    'isales.file.title.editrule': 'Regel ändern',
    'isales.file.title.rule.name.placeHolder': 'Geben Sie einen Regelnamen ein',
    'isales.file.title.rule.desc.placeHolder': 'Geben Sie eine Regelbeschreibung ein',
    'isales.file.title.rule.data': 'Zahlendaten',
    'isales.file.title.rule.number': 'Zahlenspalte',
    'isales.file.title.rule.type': 'Typspalte',
    'isales.file.title.rule.interval': 'Anrufintervallspalte',
    'isales.file.title.rule.property': 'Dienstattribut',
    'isales.file.title.rule.property.row': 'Attributspalte',
    'isales.file.title.rule.validator.row': 'Geben Sie eine Sequenznummer von der Spalte A bis EZ ein',
    'isales.file.title.rule.validator.maxdata': 'Es können maximal 40 Konfigurationsdatensätze hinzugefügt werden',
    'isales.file.title.rule.validator.ruleId': 'Die Zuordnungsregel existiert nicht',
    'isales.file.title.rule.validator.taskId': 'Die Aufgabeninformationen sind nicht vorhanden',
    'isales.file.title.import.file.invalid': 'Datei konnte nicht verifiziert werden',
    'isales.file.title.rule.validator.prop': 'Das benutzerdefinierte Attribut der Aufgabe stimmt nicht mit dem in der Regel konfigurierten Attribut überein',
    'isales.file.title.rule.validator.server': 'Die Serverkonfiguration ist falsch',
    'isales.management.message.fileImport': 'Datei wird verwendet',
    'isales.management.message.serverImport': 'Server wird verwendet',
    'isales.management.message.appendMode': 'Hinzufügen',
    'isales.management.message.coverMode': 'Überschreiben',
    'isales.management.message.templateAnalyse': 'Nach Vorlage',
    'isales.management.message.ruleAnalyse': 'Nach Regel',
    'isales.management.message.importType': 'Importmethode',
    'isales.management.message.importMode': 'Importmodus',
    'isales.management.message.import.server': 'Server',
    'isales.management.message.import.filePath': 'Adressdatei',
    'isales.management.message.import.fileName': 'Dateiname',
    'isales.management.message.import.analyseMode': 'Parsing-Modus',
    'isales.management.message.import.mapRule': 'Zuordnungsregel',
    'isales.data.title.timeImport': 'Geplanter Import',
    'isales.management.title.timeImport': 'Geplanten Import konfigurieren',
    'isales.management.title.timeImport.execTime': 'Hingerichtet',
    'isales.timeImport.message.success': 'Die Konfiguration für den geplanten Import wurde erfolgreich hinzugefügt',
    'isales.timeImport.message.fail': 'Konfiguration für geplanten Import konnte nicht hinzugefügt werden',
    'isales.agent.field.curcountpassword': 'Aktuelles Passwort',
    'isales.agent.button.batchmodify': 'Stapeländerung',
    'isales.agent.button.restAgentPwd': 'Passwort ändern',
    'isales.agent.title.agentEdit': 'Agenteninformationen ändern',
    'isales.agent.field.newpassword': 'Neues Passwort',
    'isales.agent.field.newpasswordconf': 'Passwort bestätigen',
    'isales.agent.message.passValidateFailed': 'Der Wert muss mindestens drei Arten der folgenden Zeichen enthalten: Großbuchstaben\, Kleinbuchstaben\, Ziffern\ und Sonderzeichen (`-!{\'@\'}#{\'$\'}^&*()-_+{\'|\'}[{\'{\'}{\'}\'}]<.>/?)',
    'isales.agent.message.passLengthValidateFailed': 'Das Kennwort muss 8 Zeichen enthalten',
    'isales.agent.message.modifyPwdSuccess': 'Das Agentenkennwort wurde erfolgreich geändert',
    'isales.agent.message.syncPwdFailed': 'Das Agentenkennwort konnte nicht synchronisiert werden',
    'isales.agent.message.modifyPwdFailed': 'Agentenkennwort konnte nicht geändert werden',
    'isales.file.message.rule.dataIsNull': 'Die Nummerndaten dürfen nicht leer sein',
    'isales.timeImport.message.rulePropsError': 'Das Dienstattribut der Regel für den geplanten Import muss mit dem in der aktuellen Aufgabe definierten Attribut übereinstimmen',
    'isales.import.message.selectServer.tips': 'Definieren Sie den Dateiserver für ausgehende Anrufe',
    'isales.import.message.selectRule.tips': 'Definieren Sie die zu importierende Zuordnungsregel',
    'isales.taskmanagement.title.speclistmanage.calledno': 'Ausgehende Nummer',
    'isales.specialday.title.speclistmanage.moveblacklists': 'Zur Blocklist weiterleiten',
    'isales.specialday.title.speclistmanage.misjudgment': 'Fehlurteil',
    'isales.specreason.field.emptynumber': 'Die Nummer ist nicht zugewiesen',
    'isales.specreason.field.systemtone': 'Systemaufforderungston',
    'isales.specist.message.speclistmanage': 'Wählen Sie die zu verwendende Sonderlistennummer',
    'isales.blacklist.message.confirmOpt': 'Möchten Sie wirklich Vorgänge für die ausgewählte spezielle Listennummer ausführen?',
    'isales.specist.title.misjudgment': 'Fehlurteil',
    'isales.specist.message.updateFail': 'Änderung fehlgeschlagen',
    'isales.taskcalllogic.message.maxPolicyNo': 'Es sind maximal fünf nicht standardmäßige Nummerntypen zulässig',
    'isales.import.message.upload.fail': 'Datei konnte nicht hochgeladen werden',
    'isales.taskLogic.message.saveSuccess': 'Die Logik für ausgehende Anrufe wurde erfolgreich geändert',
    'isales.taskLogic.message.saveFail': 'Die Logik für ausgehende Anrufe konnte nicht geändert werden',
    'isales.taskTime.message.saveSuccess': 'Die Zeit des ausgehenden Anrufs wurde erfolgreich geändert',
    'isales.taskTime.message.saveFail': 'Die Zeit des ausgehenden Anrufs konnte nicht geändert werden',
    'isales.tasklogic.field.callfilter': 'Anruffilterung',
    'isales.tasklogic.field.addCondition': 'Bedingung hinzufügen',
    'isales.tasklogic.field.addSubCondition': 'Unterbedingung hinzufügen',
    'isales.tasklogic.field.logic': 'Bediener',
    'isales.tasklogic.field.value': 'Wert',
    'isales.tasklogic.field.selectProp': 'Attribut hinzufügen',
    'isales.management.title.selectProp': 'Attribut auswählen',
    'isales.data.prop.quantity': 'Menge',
    'isales.tasklogic.field.and': 'Und',
    'isales.tasklogic.field.or': 'Oder',
    'isales.tasklogic.field.eq': '=',
    'isales.tasklogic.field.neq': '!=',
    'isales.tasklogic.field.gt': '>',
    'isales.tasklogic.field.egt': '>=',
    'isales.tasklogic.field.lt': '<',
    'isales.tasklogic.field.elt': '<=',
    'isales.tasklogic.field.like': 'Einschließen',
    'isales.tasklogic.field.orderPolicy': 'Anrufsortierung',
    'isales.tasklogic.field.moveUp': 'Auf',
    'isales.tasklogic.field.moveDown': 'Unten',
    'isales.tasklogic.message.repeatProp': 'Doppelte Attribute können nicht ausgewählt werden',
    'isales.tasklogic.label.primaryCondition': 'Hauptzustand',
    'isales.tasklogic.label.subCondition': 'Unterbedingung',
    'isales.agent.label.skillId': 'Skill-Warteschlangen-ID',
    'isales.management.title.editTime': 'Zeit ändern',
    'isales.management.title.editLogic': 'Logik ändern',
    'isales.agent.button.search': 'Abfrage',
    'isales.agent.label.information': 'Informationen',
    'isales.agent.label.description': 'Beschreibung',
    'isales.agent.label.operate': 'Operation',
    'isales.agent.label.cti': 'cti',
    'isales.agent.message.warning': 'Warnung',
    'isales.agent.button.edit': 'Bearbeiten',
    'isales.agent.button.createComplete': 'Fertig stellen',
    'isales.agent.button.cancel': 'Abbrechen',
    'isales.agent.pageurls.errorsave': 'Fehler',
    'isales.agent.pageurls.success': 'Erfolg',
    'isales.agent.calledroute.fail': 'Versagen',
    'isales.agent.label.ivr': 'IVR',
    'isales.skill.label.skillname': 'Skill-Warteschlangenname',
    'isales.skill.label.skilldesc': 'Beschreibung',
    'isales.skill.label.maxwaittime': 'Max. Warteschlangenzeit',
    'isales.skill.label.maxwaitnum': 'Max. Anrufe in der Warteschleife',
    'isales.skill.placeholder.skilldesc': 'Geben Sie den Namen der Skill-Warteschlange ein',
    'isales.calledroute.button.cancel': 'Abbrechen',
    'isales.calledroute.button.finish': 'Fertig stellen',
    'isales.file.title.create': 'Hinzufügen',
    'isales.file.title.rule.index': 'Sequenznummer',
    'isales.file.title.rule.property.name': 'Attributname',
    'isales.agent.label.ccRoleName': 'Rollenname',
    'isales.taskstatistics.field.callstatistics': 'Anrufstatistiken',
    'isales.taskstatistics.field.totalNumberOfUsers': 'Gesamtanzahl der Benutzer',
    'isales.taskstatistics.field.numberOfConnectedCalls': 'Verbundene Anrufe',
    'isales.taskstatistics.field.numberOfUnconnectedCalls': 'Nicht verbundene Anrufe',
    'isales.taskstatistics.field.touchRate': 'Berührungsrate',
    'isales.taskstatistics.field.callCompletionRate': 'Anrufabschlussrate',
    'isales.taskstatistics.field.abandonRate': 'Aufgabenrate',
    'isales.business.result.delete': 'Löschen',
    'isales.business.result.search': 'Suche',
    'isales.business.result.deleteChildrenTips': 'Möchten Sie das Nebengeschäftsergebnis wirklich löschen?',
    'isales.business.result.searchChildrenName': 'Geben Sie einen Namen für das Untergeschäftsergebnis ein',
    'isales.taskmanagment.title.callMonitor': 'Anrufüberwachung',
    'isales.taskstatistics.field.callResultStatistics': 'Anrufergebnisstatistiken',
    'isales.file.message.deletefiletips': 'Möchten Sie den ausgehenden Dateiserver wirklich löschen?',
    'isales.file.message.deleteruletips': 'Möchten Sie die Import-Zuordnungsregel wirklich löschen?',
    'isales.taskmanagment.callquality.maxConnectedRate': 'Maximale Verbindungsrate',
    'isales.taskmanagment.callquality.minConnectedRate': 'Mindestanschlussrate',
    'isales.work.title.serviceAccount': 'Dienstkonto',
    'isales.work.title.surveyAnswerNum': 'Anzahl der Antworten',
    'isales.work.title.reservedCalls': 'Reservierte Anrufe',
    'isales.work.title.totalOutgoingCalls': 'Ausgehende Anrufe',
    'isales.work.title.successNum': 'Erfolgreiche ausgehende Anrufe',
    'isales.work.title.completeNum': 'Abgeschlossene Umfragen',
    'isales.work.title.calledNum': 'Verarbeitete Anrufe',
    'isales.work.title.callTimeTotal': 'Gesamte Anrufdauer (s)',
    'isales.work.title.callTimeAvg': 'Durchschnittliche Anrufdauer (s)',
    'isales.work.title.busiSuccess': 'Erfolgreiches Geschäft',
    'isales.work.title.busiFail': 'Gescheitertes Unternehmen',
    'isales.work.title.outboundCallTask': 'Aufgabe für ausgehende Anrufe',
    'isales.work.title.multimediaManage': 'Marketing-Multimedia-Management',
    'isales.work.message.dategt7days': 'Der Zeitraum darf sieben Tage nicht überschreiten',
    'isales.work.message.agentOrTaskChange': 'Wählen Sie mindestens eine Anrufaufgabe und einen Agenten aus',
    'isales.import.message.closeExtProp': 'Benutzerdefinierte Attribute müssen für den Import nach Vorlage deaktiviert werden. Klicken Sie neben Importieren auf die Schaltfläche Attribut konfigurieren, um benutzerdefinierte Attribute zu deaktivieren und zu aktivieren.',
    'isales.file.message.endPoint.placeholder': 'Geben Sie eine Adresse ein',
    'isales.file.title.bucket': 'Eimer',
    'isales.file.message.bucket.placeholder': 'Geben Sie einen Eimer ein',
    'isales.file.title.AK': 'AK',
    'isales.file.title.SK': 'SK',
    'isales.file.message.AK.placeholder': 'Einen AK eingeben',
    'isales.file.message.SK.placeholder': 'Geben Sie einen SK ein',
    'isales.file.title.serverInformation': 'Serverinformationen',
    'isales.file.title.userNameOrAK': 'Benutzername/AK',
    'isales.file.message.isDeleteFile': 'Quelldatei löschen',
    'isales.data.message.preview.addNumberUpperLimit': 'Für ausgehende Anrufe mit Vorschau können maximal {count} Nummern hinzugefügt werden',
    'isales.management.title.adjustment': 'Anpassen',
    'isales.agent.query.error': 'Der Zielagent darf nicht mit dem Ausführenden des ausgewählten Datensatzes identisch sein',
    'isales.management.title.adjustment.log': 'Anpassungsaufzeichnung',
    'isales.management.title.adjustment.changeTime': 'Angepasst am',
    'isales.management.title.adjustment.orgAccountName': 'Alter Testamentsvollstrecker',
    'isales.management.title.adjustment.destAccountName': 'Neuer Testamentsvollstrecker',
    'isales.management.title.adjustment.modifier': 'Angepasst von',
    'isales.management.title.adjustment.remark': 'Anmerkungen',
    'isales.appointment.message.adjustmentSuccess': 'Der reservierte ausgehende Anruf wurde erfolgreich angepasst',
    'isales.appointment.message.adjustmentFail': 'Reservierter ausgehender Anruf konnte nicht angepasst werden',
    'isales.appointment.message.adjustmentEmpty': 'Es wurden keine gültigen reservierten ausgehenden Anrufdaten ausgewählt',
    'isales.tasksample.message.agentEmpty': 'Es wurde kein Trainingsobjekt ausgewählt',
    'isales.work.title.selectTask': 'Aufgabe für ausgehende Anrufe auswählen',
    'isales.import.button.configProp': 'Filterregel konfigurieren',
    'isales.import.filter.button.lineRepeat': 'Doppelte Daten in der Zeile herausfiltern',
    'isales.import.filter.button.noRepeat': 'Doppelte Daten im Stapel herausfiltern',
    'isales.import.filter.button.recentCall': 'Daten kürzlich abgeschlossener Anrufe herausfiltern',
    'isales.import.filter.button.callResult': 'Daten von Geschäftsergebnissen herausfiltern',
    'isales.import.filter.button.readyCall': 'Daten von nicht initiierten Anrufen herausfiltern',
    'isales.import.filter.button.calling': 'Daten nicht abgeschlossener Anrufe herausfiltern',
    'isales.import.filter.title.popup': 'Filterregeln',
    'isales.import.filter.message.selectRuleTips': 'Wählen Sie mindestens eine Filterregel aus',
    'isales.taskmanagment.title.callMonitor.tips': 'Automatisch alle 15 Minuten aktualisiert',
    'isales.taskmanagement.field.basicTaskInfo': 'Grundlegende Informationen',
    'isales.taskmanagement.field.pickUpRule': 'Regel für automatische Extraktion',
    'isales.taskmanagement.field.isAutoPickUp': 'Automatische Extraktionsregel aktivieren',
    'isales.taskmanagement.field.pickUpRuleLimit': 'Einschränkungen',
    'isales.taskmanagement.field.pickUpRuleBusiResult': 'Geschäftsergebnis',
    'isales.taskmanagement.field.quotas': 'Obergrenze',
    'isales.taskmanagement.field.callCompletedNum': 'Abgeschlossene Anrufe',
    'isales.taskmanagement.field.pickUpNum': 'Jedes Mal extrahierte Anrufe',
    'isales.taskmanagement.field.pickUpCondition': 'Bedingungen',
    'isales.manualdata.title.pickUp': 'Auszug',
    'isales.manualData.message.pickUpSuccess': 'Extrahierung erfolgreich',
    'isales.manualData.message.reachQuatos': 'Es können keine weiteren ausgehenden Anrufdaten extrahiert werden, da die Anzahl der Anrufe in der Aufgabe die Extraktionsobergrenze erreicht hat',
    'isales.manualData.message.reachMax': 'Es können keine weiteren ausgehenden Anrufdaten extrahiert werden, da die maximale Anzahl von Anrufen in der Aufgabe erreicht wurde',
    'isales.manualData.message.pickUpFailed': 'Keine Daten zum Extrahieren',
    'isales.taskstatistics.message.no.busiResult': 'Es wird kein Geschäftsergebnis markiert',
    'isales.appointmanagement.field.remark': 'Anmerkungen',
    'isales.appointmanagement.title.inputRemark': 'Geben Sie Bemerkungen ein',
    'isales.agent.query.emptyError': 'Es wurden keine gültigen Agentendaten ausgewählt',
    'isales.statistics.field.touchRateDescription': 'Reichweite',
    'isales.statistics.field.callCompletionRateDescription': 'Verbindungsrate',
    'isales.statistics.field.abandonmentRateDescription': 'Aufgabenrate',
    'isales.taskstatistics.message.Unit.Piece': 'Einheit: Nummer',
    'isales.skillqueue.agentrole.agent': 'Agent',
    'isales.statistics.field.addHisDetail': 'Nein: Es werden Daten von {0} Monaten erhoben. Ja: Es werden Daten von {1} Monaten erhoben',
    'isales.import.filter.message.busiResultIsNull': 'Das Geschäftsergebnis darf nicht leer sein',
    'isales.import.filter.message.busiSubStatusSame': 'Ergebnisse von Teilunternehmen unter demselben Geschäftsergebnis können nicht gleich sein',
    'isales.file.server.message.checkObsPath': 'Nur Ziffern\, Buchstaben\, Bindestriche (-)\ und Punkte (.) sind zulässig',
    'isales.file.server.message.notChinese': 'Chinesische Schriftzeichen sind nicht zulässig',
    'isales.import.filter.title.callDay': 'Letzte Aktivität (Tage)',
    'isales.manual.title.sendEmail': 'E-Mail senden',
    'isales.manual.button.send': 'Senden',
    'isales.manual.title.recipient': 'Empfänger',
    'isales.manual.title.title': 'Titel',
    'isales.file.message.isDeleteFileTips': 'Dateien aus dem OBS löschen',
    'isales.file.message.rule.success': 'Die Regel wurde erfolgreich geändert',
    'isales.file.message.rule.fail': 'Regel konnte nicht geändert werden',
    'isales.file.message.rule.columnRepeat': 'Die ID-Spalte für ausgehende Anrufe\, die Nummernspalte\, die Kategoriespalte\, die Anrufintervallspalte\ und die Attributspalte dürfen nicht identisch sein',
    'isales.manual.message.recipient': 'Geben Sie einen Empfänger ein',
    'isales.manual.message.title': 'Geben Sie einen Titel ein',
    'isales.operlog.message.downloadAgentWorkExportFile': 'Agenten-Workload-Exportdatei herunterladen',
    'isales.operlog.message.downloadSurveyExportFile': 'Schulungsfragen exportieren',
    'isales.taskinfo.field.chooseOrInputCallNo': 'Sie können die Anrufnummer gleichzeitig eingeben oder auswählen',
    'isales.agent.message.curcountpasswordWrong': 'Das aktuelle Kontokennwort ist falsch',
    'isales.taskinfo.callno.repeat': 'Anrufnummer ist Wiederholung',
    'isales.file.title.password.again': 'Passwort bestätigen',
    'isales.file.title.password.same': 'Die beiden Passwörter sind unterschiedlich',
    'isales.file.title.sk.same': 'Die beiden SKs sind unterschiedlich',
    'isales.file.title.SK.again': 'SK bestätigen',
    'isales.import.message.template.dataNum': 'Es können maximal {0} Datensätze gleichzeitig importiert werden',
    'isales.import.message.allow.task.statue': ',Eine Aufgabe kann nur importiert werden, wenn sie sich im Status Entwurf oder Unterbrochen befindet',
    'isales.taskstatistics.field.callRatePie': 'Anrufabschlussrate',
    'isales.taskstatistics.field.connectedCallsPie': 'Anzahl der verbundenen Anrufe',
    'isales.taskstatistics.field.Completed': 'lect Anrufnummer',
    'isales.taskstatistics.field.inProgress': 'Anrufende Kunden',
    'isales.taskstatistics.field.incomplete': 'Kunden, die auf Anrufe warten',
    'isales.taskresultexport.message.downloadFilenopemission': 'Sie haben keine Berechtigung zum Exportieren von Dateien',
    'isales.callrecordexport.message.downloadFilenopemission': 'Sie haben nicht das Recht, intelligente Kommunikationsdatensätze für ausgehende Anrufe zu exportieren.',
    'isales.taskmanagment.title.callquality.viewstatistics': 'Anrufqualitätsstatistik',
    'isales.taskmanagment.message.chartDataError': 'Das Diagramm konnte nicht geladen werden',
    'isales.taskmanagment.title.open.agentMonitor': 'Agentenüberwachung',
    'isales.taskmanagment.title.open.callMonitor': 'Anrufüberwachung',
    'isales.callquality.statistics.message.afterNow': 'Sie können keine Zeit vor der Startzeit der Aufgabe oder nach dem aktuellen Tag auswählen!',
    'isales.task.promotion.tempalteError': 'Die Marketingbenachrichtigungsvorlage stimmt nicht mit dem Vorlagentyp überein',
    'isales.task.promotion.gatewwayError': 'Die Gateway-Konfiguration stimmt nicht mit dem Medientyp überein',
    'isales.task.promotion.baseInfo': 'Grundlegende Informationen',
    'isales.appointment.title.timeout': 'Die Reservierungszeit ist abgelaufen',
    'isales.appointment.title.soontimeout': 'Die Reservierungszeit steht kurz bevor',
    'isales.file.message.rule.receiver.columnRepeat': 'Die Empfängerspalte darf nicht mit der Attributspalte identisch sein',
    'isales.taskinfo.field.mutiCallNo': 'Geben Sie die Anrufnummer ein',
    'isales.taskinfo.callno.length': 'Die Länge einer einzelnen Anrufnummer überschreitet 32 Zeichen',
    'isales.workbench.execution': 'Ausgehende Anrufausführung',
    'isales.workbench.task.management': 'Verwaltung ausgehender Anrufe',
    'isales.workbench.appointment.task': 'Reservierungsaufgabe',
    'isales.workbench.number.execution': 'Ausführen',
    'isales.notification.receiver.address': 'Empfängeradresse',
    'isales.notification.receiver.number': 'Empfängernummer',
    'isales.notification.receiver.address.placeholder': 'Geben Sie die Empfängeradresse ein',
    'isales.notification.receiver.number.placeholder': 'Geben Sie die Empfängernummer ein',
    'isales.notification.configVariable': 'Variable festlegen',
    'isales.notification.receiver.tips': 'Der Empfänger muss einzigartig sein',
    'isales.marketing.mode': 'Marketingmodus',
    'isales.marketing.OutgoingCall': 'Telemarketing',
    'isales.marketing.notification': 'Multimedia-Marketing',
    'isales.notification.receiver': 'Empfänger',
    'isales.notification.receiver.info': 'Empfängerinformationen',
    'isales.import.message.errMsg': 'Ausnahmegrund',
    'isales.notification.variable.name': 'Name der Variablen',
    'isales.notification.propo.name': 'Attributname',
    'isales.notification.variable.value': 'Variabler Wert',
    'isales.notification.import.button': 'Daten importieren',
    'isales.task.promotion.pauseSuccess': 'Die Multimedia-Marketing-Aufgabe wurde erfolgreich eingestellt',
    'isales.task.promotion.pauseFaied': 'Die Aufgabe des Multimedia-Marketings konnte nicht eingestellt werden',
    'isales.task.promotion.startSuccess': 'Die Multimedia-Marketingaufgabe wurde erfolgreich veröffentlicht',
    'isales.task.promotion.startFailed': 'Die Multimedia-Marketingaufgabe konnte nicht veröffentlicht werden',
    'isales.task.promotion.isExp': 'Die Aufgabe des Multimedia-Marketings ist abgelaufen',
    'isales.task.promotion.saveSuccess': 'Die Multimedia-Marketingaufgabe wurde erfolgreich gespeichert',
    'isales.task.promotion.nameRepeat': 'Der Name der Multimedia-Marketingaufgabe existiert bereits',
    'isales.task.promotion.saveFailed': 'Die Multimedia-Marketingaufgabe konnte nicht gespeichert werden',
    'isales.task.promotion.deleteSuccess': 'Die Multimedia-Marketingaufgabe wurde erfolgreich gelöscht',
    'isales.task.promotion.statusStart': 'Die Multimedia-Marketing-Aufgabe läuft',
    'isales.task.promotion.deleteFailed': 'Die Multimedia-Marketingaufgabe konnte nicht gelöscht werden',
    'isales.task.promotion.updateSuccess': 'Die Multimedia-Marketingaufgabe wurde erfolgreich geändert',
    'isales.task.promotion.updateFailed': 'Die Multimedia-Marketing-Aufgabe konnte nicht geändert werden',
    'isales.task.promotion.messageTemplate': 'Benachrichtigungsvorlage',
    'isales.task.promotion.promotionName': 'Marketingaufgabe',
    'isales.task.promotion.inputPromotionName': 'Bitte geben Sie einen Marketingnamen ein',
    'isales.task.promotion.basicInfo': 'Grundlegende Informationen',
    'isales.task.promotion.promotionType': 'Medientyp',
    'isales.task.promotion.promotionEffTime': 'Effektive Zeit',
    'isales.task.promotion.promotionExpTime': 'Ablaufzeit',
    'isales.task.promotion.promotionPolicy': 'Marketingpolitik',
    'isales.task.promotion.templateType': 'Vorlagentyp',
    'isales.task.promotion.sendTemplate': 'Benachrichtigungsvorlage',
    'isales.task.promotion.selectSendTemplate': 'Wählen Sie eine Vorlage aus',
    'isales.task.promotion.prefix': 'Ländercode',
    'isales.task.promotion.sendGateway': 'Gateway',
    'isales.task.promotion.selectSendGateway': 'Wählen Sie ein Gateway aus',
    'isales.task.promotion.sender': 'Absender',
    'isales.task.promotion.sendLimit': 'Tägliche Sendegrenze',
    'isales.task.promotion.isRetry': 'Wiederholung bei Fehlschlagen',
    'isales.task.promotion.retryTimes': 'Wiederholungszeiten',
    'isales.task.promotion.isSendNow': 'Sofort senden',
    'isales.task.promotion.expectedTime': 'Erwartete Sendezeit',
    'isales.task.promotion.dndTime': 'Zeitraum Bitte nicht stören',
    'isales.task.promotion.sms': 'SMS',
    'isales.task.promotion.email': 'E-Mail',
    'isales.task.promotion.hwsms': 'HUAWEI CLOUD SMS',
    'isales.task.promotion.smsgw': 'SMS-Gateway',
    'isales.task.promotion.promotionStatus': 'Marketingstatus',
    'isales.task.promotion.tobesent': 'Zu sendende Nachrichten',
    'isales.task.promotion.invalidation': 'Ungültig',
    'isales.task.promotion.all': 'Alle',
    'isales.task.promotion.create': 'Multimedia-Marketingaufgabe erstellen',
    'isales.task.promotion.edit': 'Multimedia-Marketingaufgabe ändern',
    'isales.task.promotion.view': 'Multimedia-Marketingaufgabe anzeigen',
    'isales.task.promotion.message.variableIsNull': 'Die Liste der Attributkonfigurationen ist leer',
    'isales.task.promotion.message.variableNotConfig': 'Einige Variablen sind nicht konfiguriert',
    'isales.task.promotion.message.limitGtTenantLimit': 'Das tägliche Sendelimit überschreitet das für den Mieter zulässige Maximum',
    'isales.task.promotion.message.dndEndTimeltBeginTime': 'Das Ende der Bitte nicht stören-Periode liegt vor dem Beginn.',
    'isales.task.promotion.message.dndTimeltCrossExpectedTime': 'Die erwartete Sendezeit kann nicht in der Bitte-nicht-Stören-Periode liegen',
    'isales.notification.viewResult.button': 'Marketingergebnis anzeigen',
    'isales.task.promotion.message.dndTimeError': 'Die Bitte-nicht-stören-Periode ist unvollständig',
    'isales.task.promotion.message.selectPromotionType': 'Wählen Sie einen Medientyp aus',
    'isales.import.filter.button.notification.noisPush': 'Daten, die nicht gepusht wurden, herausfiltern',
    'isales.import.filter.button.notification.isPushCom': 'Daten, die gepusht werden, herausfiltern',
    'isales.import.filter.title.notification.isPushDay': 'Pushed Days',
    'isales.notification.configVariableRel': 'Beziehung zwischen Variable und Attribut konfigurieren',
    'isales.notification.configVariableValue': 'Variablenwert festlegen',
    'isales.notification.prop.tips': 'Das Attribut muss eindeutig sein',
    'isales.notification.receiver.delete.error': 'Es wurden keine Daten ausgewählt',
    'isales.notification.receiver.delete.variable': 'Attribut konfigurieren',
    'isales.import.filter.button.notification.isPush': 'Kürzlich gepushte Daten herausfiltern',
    'isales.task.promotion.templateCode': 'Vorlagencode',
    'isales.task.promotion.gateway': 'Gateway senden',
    'isales.task.promotion.sendStatus': 'Sendestatus',
    'isales.task.promotion.beginTime': 'Startzeit',
    'isales.task.promotion.endTime': 'Endzeit',
    'isales.task.promotion.receiver': 'Empfänger',
    'isales.task.promotion.sendTime': 'Gesendet am',
    'isales.sendStatus.field.prepare': 'Vorbereitung',
    'isales.sendStatus.field.sending': 'Senden',
    'isales.sendStatus.field.success': 'Erfolgreich',
    'isales.sendStatus.field.fail': 'Nicht bestanden',
    'isales.sendStatus.field.all': 'Alle',
    'isales.task.promotion.message.selectTemplateType': 'Wählen Sie einen Vorlagentyp aus',
    'isales.task.promotion.remark': 'Fehlerursache',
    'isales.task.promotion.createTime': 'Erstellt am',
    'isales.task.promotion.message.monthGtThree': 'Nur die Ergebnisse von drei Monaten können abgefragt werden',
    'isales.notification.configVariableTe': 'Vorlagenvariable konfigurieren',
    'isales.notification.config': 'Konfiguration',
    'isales.notification.receiver.number.tips': 'Der Wert muss aus Ziffern bestehen',
    'isales.notification.variable.defvalue': 'Standardwert',
    'isales.task.promotion.message.noprop': 'Ein Attribut über das Menü Attributdefinition für ausgehende Anrufdaten hinzufügen',
    'isales.task.promotion.message.variableSelected': 'Wählen Sie ein Attribut aus',
    'isales.notification.receiver.deletetips': 'Möchten Sie die ausgewählten Daten wirklich löschen?',
    'isales.management.message.notification.download': 'Marketingdatenvorlage herunterladen',
    'isales.management.title.notification.import': 'Marketingdaten importieren',
    'isales.taskpolicy.message.obOverTime': 'Geben Sie zuerst die Dauer des Vorschau-Timeouts ein',
    'isales.taskpolicy.message.rangeAutoCallTime': 'Der Wert muss zwischen {0} und {1} liegen',
    'isales.taskpolicy.message.obOverTimeRange': 'Die Dauer des Vorschau-Timeouts liegt außerhalb des zulässigen Bereichs: 9 bis 120. Ändern Sie zuerst die Dauer des Vorschau-Timeouts',
    'isales.taskmanagement.message.beforeNow': 'Die Startzeit darf nicht vor der aktuellen Zeit liegen',
    'isales.operation.success': 'Operation erfolgreich',
    'isales.operation.training.success': 'Operation erfolgreich. Das Training dauert etwa mehrere Stunden. Melden Sie sich bei Lodas an, um den Trainingsfortschritt anzuzeigen',
    'isales.operation.failed': 'Operation fehlgeschlagen',
    'isales.taskmanagement.field.taskId': 'Aufgaben-ID',
    'isales.taskmanagement.title.inputTaskId': 'Geben Sie eine Aufgaben-ID ein',
    'isales.taskinfo.field.callBackUrl': 'Rückrufadresse',
    'isales.taskinfo.message.callBackUrl': 'Geben Sie eine Rückrufadresse ein',
    'isales.tasktype.field.preemption': 'Vorbelegter ausgehender Anruf',
    'isales.taskmanagement.title.toBeCallCount': 'Ausstehende Anrufe',
    'isales.manual.field.canBeCallCount': 'Verfügbare Anrufe',
    'isales.manual.field.noDataCanbeCallTips': 'Derzeit können keine Anrufe getätigt werden. Prüfen Sie, ob die Attributkonfiguration die Filterkriterien erfüllt',
    'isales.tasklogic.field.callOrderType.noOrder': 'Unbestellter Anruf',
    'isales.tasklogic.field.callOrderType.isOrder': 'Geordneter Anruf',
    'isales.tasklogic.field.callOrderMethod': 'Reihenfolgeregel',
    'isales.tasklogic.field.callOrderMethod.byOrderNo': 'Durch Nein',
    'isales.tasklogic.field.callOrderMethod.bySelf': 'Nach benutzerdefinierter Regel',
    'isales.tasklogic.message.need.orderPolicy': 'Fügen Sie mindestens eine benutzerdefinierte Bestellregel hinzu',
    'isales.taskmanagement.title.clicktorefresh': 'Klicken Sie zum Aktualisieren',
    'isales.audio.content.calltype': 'Ausgehender Sprachanruf',
    'isales.audio.message.errortip1': 'Die Aufzeichnungsinformationen konnten nicht abgefragt werden. Wenden Sie sich an den Administrator',
    'isales.audio.title.audiorate': 'Wiedergaberate',
    'isales.audio.title.callId': 'Aufnahme-SN',
    'isales.audio.title.audiotime': 'Dauer(HH:mm:ss)',
    'isales.audio.title.starttime': 'Startzeit',
    'isales.audio.title.endtime': 'Endzeit',
    'isales.audio.title.calltype': 'Anruftyp',
    'isales.audio.title.recording': 'Aufzeichnungsvorschau',
    'isales.datasourcemanagement.field.vdnId': 'VDNID',
    'isales.datasourcemanagement.title.inputVdnId': 'Geben Sie eine VDNID ein',
    'isales.datasourcemanagement.field.dbId': 'DataSource-ID',
    'isales.datasourcemanagement.title.inputDbId': 'Datenquellen-ID eingeben',
    'isales.datasourcemanagement.field.dbschema': 'Name der DataSource',
    'isales.datasourcemanagement.title.inputDbschema': 'DataSource-Namen eingeben',
    'isales.datasourcemanagement.field.dbType': 'DataSource-Typ',
    'isales.datasourcemanagement.field.dbdriver': 'DataSource-Treiber',
    'isales.datasourcemanagement.field.dsType.all': 'Alle',
    'isales.datasourcemanagement.field.dsType.mysql': 'MYSQL',
    'isales.datasourcemanagement.field.dsType.oracle': 'ORACLE',
    'isales.datasourcemanagement.field.dsType.gauss': 'GAUSS',
    'isales.datasourcemanagement.field.dsType.dm': 'DM',
    'isales.datasourcemanagement.field.add': 'DataSource hinzufügen',
    'isales.datasourcemanagement.field.sync': 'DataSource synchronisieren',
    'isales.datasourcemanagement.field.backDbdriver': 'Name der BackUp-DataSource',
    'isales.datasourcemanagement.field.addDataSource': 'DataSource hinzufügen',
    'isales.datasourcemanagement.field.dbIp': 'IP-Adresse oder Alias der Datenquelle',
    'isales.datasourcemanagement.field.dbPort': 'DataSource-Hafen',
    'isales.datasourcemanagement.field.dbUserName': 'Name der DataSource',
    'isales.datasourcemanagement.field.dbPass': 'DataSource',
    'isales.datasourcemanagement.field.dbPass2': 'DataSource bestätigen',
    'isales.datasourcemanagement.field.dbCnUrl': 'DataSource-URL',
    'isales.data.propConfig.message.isExistBackDb': 'Zurück DataSource',
    'isales.datasourcemanagement.field.backdbId': 'DataSource-ID zurück',
    'isales.datasourcemanagement.field.backdbUserName': 'Zurück DataSource',
    'isales.datasourcemanagement.field.backdbType': 'DataSource-Typ hinten',
    'isales.datasourcemanagement.field.backdbIp': 'IP oder Alias der Standby-Datenbank',
    'isales.datasourcemanagement.field.backdbport': 'Hinterer DataSource-Anschluss',
    'isales.datasourcemanagement.field.backDbCnUrl': 'Zurück-DataSource-URL',
    'isales.datasourcemanagement.field.backdbpass': 'Zurück DataSource',
    'isales.datasourcemanagement.field.backdbpass2': 'Zurückbestätigung DataSource-PassWord',
    'isales.datasourcemanagement.field.backdbSchema': 'Back-DataSource-Schema',
    'isales.datasourcemanagement.field.backdbdriver': 'DataSource-Treiber hinten',
    'isales.datasourcemanagement.field.editDataSource': 'DataSource bearbeiten',
    'isales.datasourcemanagement.message.dbIp': 'Das Format der Datenbank-IP-Adresse ist falsch',
    'isales.datasourcemanagement.message.dbpass': 'Bitte geben Sie dasselbe Kennwort ein',
    'isales.datasourcemanagement.message.addDataSourceFail': 'DataSource-Erstellung fehlgeschlagen',
    'isales.datasourcemanagement.message.addDataSourceSuccess': 'DataSource erfolgreich erstellen',
    'isales.datasourcemanagement.message.alreadyExistDs': 'Die Datenquelle des VDN existiert bereits',
    'isales.datasourcemanagement.message.confirDelete': 'Das Löschen der Datenquelle ist ein Vorgang mit hohem Risiko. Seien Sie bei der Durchführung dieses Vorgangs vorsichtig',
    'isales.datasourcemanagement.message.deleteDataSourceFail': 'DataSource konnte nicht gelöscht werden',
    'isales.datasourcemanagement.message.deleteDataSourceSuccess': 'DataSource erfolgreich gelöscht',
    'isales.datasourcemanagement.field.viewDataSource': 'DataSource anzeigen',
    'isales.datasourcemanagement.message.frequentOperation': 'Häufige Operationen. Bitte warten Sie',
    'isales.datasourcemanagement.message.noObsINFO': 'Der ausgehende Anruf DataSource wurde nicht oder nicht richtig konfiguriert',
    // 'isales.datasourcemanagement.message.passValidateFailed':'Der Wert muss mindestens drei Arten der folgenden Zeichen enthalten: Großbuchstaben\, Kleinbuchstaben\, Ziffern\ und Sonderzeichen (`-!{\'@\'}#{\'$\'}^&*()-_+{\'|\'}[{\'{\'}{\'}\'}]<.>/?),
    'isales.datasourcemanagement.message.syncSuccess': 'DataSource-Synchronisierung erfolgreich',
    'isales.tasklogic.field.noRetryLogic': 'Ergebniscode für Nicht-Wiederholung hinzufügen',
    'isales.management.title.selectResultCode': 'Ergebniscode auswählen',
    'isales.management.title.setResultCode': 'Bitte legen Sie den Ergebniscode fest',
    'isales.manualData.message.noPickCondition': 'Bitte Bedingungen festlegen',
    'isales.management.message.addPropTips': 'Personenbezogene oder sensible Daten müssen verschlüsselt, gespeichert und maskiert werden',
    'isales.enum.workSpace': 'Aufgabe für ausgehende Agentenanrufe ',
    'isales.management.title.black.import': 'Blocklist importieren',
    'isales.black.filed.number': 'Nummer (obligatorisch)',
    'isales.black.filed.expTime': 'Gültigkeitsdauer (optional, in Stunden)',
    'isales.black.filed.remark': 'Anmerkungen (optional)',
    'isales.survey.statistic': 'Befragtenstatistiken',
    'isales.survey.querydetail': 'Umfrageergebnis',
    'isales.manual.task.survey.questiontotal': 'Gesamtzahl der {0} Fragen',
    'isales.manual.task.survey.unanswered': 'Unbeantwortet',
    'isales.manual.task.survey.answered': 'Beantwortet',
    'isales.manual.task.survey.currentprogress': 'Aktuelle Fortschritte',
    'isales.manual.task.survey.previousquestion': 'Vorherige Frage',
    'isales.manual.task.survey.nextquestion': 'Nächste Frage',
    'isales.manual.task.survey.submit': 'Senden',
    'isales.taskinfo.field.survey': 'Fragebogen',
    'isales.manual.task.survey.noAnswer': 'Keine Antwort erforderlich',
    'isales.manual.task.survey.answerSuccess': 'Erfolgreich beantwortet!',
    'isales.agent.calledroute.search.devicedesc': 'Geben Sie einen Gerätenamen ein',
    'isales.agent.calledroute.create': 'Hinzufügen',
    'isales.agent.calledroute.device': 'Wählen Sie ein Gerät aus',
    'isales.agent.calledroute.devicetype': 'Ausrüstungsart',
    'isales.agent.calledroute.extcode': 'Verbreitungscode',
    'isales.agent.calledroute.ivr': 'Wählen Sie ein IVR aus',
    'isales.agent.calledroute.placeholder.ivr': 'Wählen Sie ein IVR aus',
    'isales.agent.calledroute.skill': 'Skill-Warteschlange',
    'isales.agent.calledroute.placeholder.skill': 'Bitte wählen Sie eine Skill-Warteschlange aus',
    'isales.agent.calledroute.accesscode': 'Zugangscode',
    'isales.agent.calledroute.placeholder.accesscode': 'Wählen Sie einen Zugangscode aus',
    'isales.agent.calledroute.placeholder.extcode': 'Bitte geben Sie den Erweiterungscode ein',
    'isales.calledroute.title.selectaccesscode': 'Wählen Sie einen Zugangscode',
    'isales.agent.calledroute.extcodewarn': 'Erweiterungscodes dürfen nur Zahlen enthalten',
    'isales.agent.calledroute.extlengthcodewarn': 'Die Länge des Erweiterungscodes darf 12 nicht überschreiten',
    'isales.agent.calledroute.editsuccess': 'Erfolgreich bearbeitet',
    'isales.agent.calledroute.exception': 'Bitte überprüfen Sie den Redis-Status oder die CC-Gateway-Konfiguration',
    'isales.agent.calledroute.editfail': 'Bearbeitung der lokalen Daten fehlgeschlagen',
    'isales.agent.calledroute.accesscodewarn': 'Zugangscodes dürfen nur Zahlen enthalten',
    'isales.agent.calledroute.createsuccess': 'erfolgreich hinzugefügt',
    'isales.agent.calledroute.createfail': 'Lokale Daten konnten nicht hinzugefügt werden',
    'isales.agent.calledroute.create.accesscode.none': 'Der vom angerufenen Teilnehmer konfigurierte Systemzugangscode wurde nicht zugewiesen',
    'isales.agent.calledroute.create.accesscode.repeat': 'Die vom angerufenen Teilnehmer konfigurierte Kombination aus Systemzugangscode und Erweiterungscode kann nicht wiederholt werden.',
    'isales.agent.calledroute.create.accesscode.noexist': 'Der Zugangscode, auf den die aufgerufene Konfiguration verweist, existiert nicht',
    'isales.agent.calledroute.create.skill.none': 'Die Skill-Warteschlange, auf die die angerufene Konfiguration verweist, existiert nicht',
    'isales.agent.calledroute.create.ivr.limit': 'Der Gerätetyp ist IVR, und der Medientyp des Zugangscodes kann nur Click-to-Call, Rückrufanforderung oder normaler Anruf sein',
    'isales.agent.calledroute.create.gateway.none': 'Das Routing-Skript, auf das die aufgerufene Konfiguration verweist, existiert nicht',
    'isales.agent.calledroute.create.media.match': 'Der vom angerufenen Teilnehmer konfigurierte Systemzugangscode stimmt nicht mit dem vom angerufenen Teilnehmer konfigurierten Medientyp des Geräts überein.',
    'isales.agent.calledroute.recordexist': 'Der Zugangscode und der Erweiterungscode sind bereits gebunden. Bitte geben Sie einen neuen Erweiterungscode ein',
    'isales.agent.calledroute.nmsdata.error': 'Die Synchronisierung des ODFS schlägt fehl und die Konfigurationsdaten des vom ODFS angerufenen Teilnehmers stimmen nicht mit den lokalen Daten des CCM überein',
    'isales.agent.calledroute.ccivrdata.error': 'ODFS konnte nicht synchronisiert werden, CC-IVR-Prozessdaten sind falsch',
    'isales.agent.calledroute.nmsconnect.fail': 'Verbindung mit ODFS konnte nicht hergestellt werden',
    'isales.management.placeholder.accesscodeDesc': 'Geben Sie eine Beschreibung des Zugangscodes ein',
    'isales.taskinfo.title.accesscode': 'Zugangscode',
    'isales.taskinfo.title.accesscodedesc': 'Beschreibung des Zugangscodes',
    'isales.agent.contact.voiceVidio': 'Audio und Video',
    'isales.agent.contact.clicktodial': 'Klicken, um anzurufen',
    'isales.note.config.success': 'Erfolg',
    'isales.agent.calledroute.refreshsuccess': 'Aktualisierung erfolgreich',
    'isales.agent.calledroute.updatecalledroute': 'Bearbeiten',
    'isales.agent.calledroute.confdelete': 'Löschbestätigung',
    'isales.agent.calledroute.isconfdelete': 'Möchten Sie wirklich löschen?',
    'isales.agent.calledroute.success': 'Erfolg',
    'isales.agent.calledroute.delsuccess': 'erfolgreich gelöscht',
    'isales.agent.message.redislocknull': 'Redis-Sperre konnte nicht abgerufen werden',
    'isales.calledroute.message.selectcalledroute': 'Wählen Sie die aufgerufene Konfiguration aus',
    'isales.agent.calledroute.delfail': 'Löschen lokaler Daten fehlgeschlagen',
    'isales.label.appraise.call.quality.agent': 'Agent',
    'isales.label.appraise.call.quality.customer': 'Kunde',
    'isales.label.appraise.call.quality.customerInfo': 'Kundeninformationen',
    'isales.data.message.empty': 'Die angerufene Nummer darf nicht leer sein!',
    'isales.export.message.noSelectData': 'Wählen Sie die zu exportierenden Daten aus',
    'isales.export.message.noSelectTrainingData': 'Wählen Sie die zu trainierenden Daten aus',
    'isales.export.message.selectToomach': 'Es können maximal 10 Datensätze ausgewählt werden',
    'isales.training.scoreDetails': 'Bewertungsdetails',
    'isales.training.startTraining': 'Schulung starten',
    'isales.training.queryQuestionnaire': 'Fragebogenabfrage',
    'isales.training.Questionnaire': 'Schulungsfragebogen',
    'isales.training.MaxAnswerNumber': 'Maximale Anzahl von Fragebogenantworten',
    'isales.training.forwardNumber': 'Weiterleitungsnummer',
    'isales.training.AssociatedSurvey': 'Zugehöriger Fragebogen',
    'isales.training.resultAnalysis': 'Ergebnisanalyse',
    'isales.training.surveyDetail': 'Fragebogendetails',
    'isales.training.questionNumber': 'Anzahl der Fragen',
    'isales.training.surveyScore': 'Gesamtergebnis der Umfrage',
    'isales.training.callTime': 'Rufzeitsegment',
    'isales.training.callTimeSegment': 'Rufzeitsegment',
    'isales.training.specialday.callTime': 'Gesprächszeitsegment für besonderes Datum',
    'isales.training.startTraining.exists': 'Ausbildung läuft. Versuchen Sie es nach Abschluss der Schulung erneut',
    'isales.taskstatistics.field.feedBackStatistics': 'Feedback-Statistiken',
    'isales.task.message.feedBackRate': 'Rückmelderate',
    'isales.task.message.numberEffFeedback': 'Anzahl der gültigen Feedbackanrufe',
    'isales.task.message.numberInvalFeedback': 'Anzahl ungültiger Feedbackanrufe',
    'isales.survey.details': 'Fragebogendetails',
    'isales.survey.createTrainingTask': 'Erstellen einer Schulungsaufgabe',
    'isales.taskstatistics.field.specialCall': 'Besondere Liste',
    'isales.taskmanagment.title.open.obsMonitor': 'OBS-Datenüberwachung',
    'isales.training.taskdetails': 'Aufgabendetails',
    'isales.training.sampleTotal': 'Gesamtstichprobenumfang',
    'isales.training.completeSamples': 'Abgeschlossene Probengröße',
    'isales.training.remainingSamples': 'Restmenge',
    'isales.training.trainingRate': 'Ausbildungsquote',
    'isales.training.surveydetails': 'Einzelheiten der Fragebogenstatistik',
    'isales.training.averageScore': 'Durchschnittsergebnis',
    'isales.training.voiceAverageScore': 'Durchschnittliche Stimmenbewertung',
    'isales.training.operationAveragecore': 'Durchschnittliche Vorgangsbewertung',
    'isales.training.questionScoreDetails': 'Problembewertungsdetails',
    'isales.training.answerNumber': 'Anzahl der Antworten',
    'isales.training.fullScoreNumber': 'Anzahl der Vollpunktzahlen',
    'isales.training.averageDuration': 'Durchschnittliche Dauer',
    'isales.training.tip1': 'Die Anzahl der Antworten hat die Obergrenze erreicht',
    'isales.training.answeringRecord': 'Antwortprotokoll',
    'isales.training.tip2': 'Die Frage wird trainiert. Klicken Sie nicht häufig',
    'isales.management.message.black.download': 'Herunterladen einer Blocklist-Vorlage',
    'isales.taskmanagment.title.obs.viewstatistics': 'Von OBS erhaltene Daten überwachen',
    'isales.task.statistics.obs.startTime': 'Die Startzeit darf nicht früher als sieben Tage zurückliegen',
    'isales.task.statistics.obs.endTime': 'Die Differenz zwischen Start- und Endzeit darf eine halbe Stunde nicht überschreiten.',
    'isales.warning.data.export': 'Die exportierten Daten enthalten personenbezogene Daten. Seien Sie bei der Durchführung dieses Vorgangs vorsichtig',
    'isales.statistics.business.taskId': 'Aufgaben-ID',
    'isales.statistics.business.reportType': 'Berichtstyp',
    'isales.statistics.business.beginDate': 'Startdatum',
    'isales.statistics.business.endDate': 'Enddatum',
    'isales.statistics.business.startYear': 'Startjahr',
    'isales.statistics.business.startWeek': 'Startwoche',
    'isales.statistics.business.startMonth': 'Startmonat',
    'isales.statistics.business.endYear': 'Jahresende',
    'isales.statistics.business.endWeek': 'Wochenende',
    'isales.statistics.business.endMonth': 'Monatsende',
    'isales.statistics.business.search': 'Suche',
    'isales.statistics.business.reset': 'Zurücksetzen',
    'isales.statistics.business.date': 'Datum',
    'isales.statistics.business.week': 'Woche',
    'isales.statistics.business.month': 'Monat',
    'isales.statistics.business.byDay': 'Tagesbericht',
    'isales.statistics.business.byWeek': 'Wochenbericht',
    'isales.statistics.business.byMonth': 'Monatsbericht',
    'isales.statistics.business.title': 'Geschäftsbericht über ausgehende Anrufe',
    'isales.statistics.business.trend.title': 'Geschäftlicher Trendbericht für ausgehende Anrufe',
    'isales.statistics.business.nomorethanerror': 'Das ausgewählte Datum darf nicht vor {0} liegen',
    'isales.statistics.business.currentDateCheck': 'Das ausgewählte Datum muss vor dem aktuellen Tag liegen',
    'isales.statistics.business.dategt31days': 'Der Zeitraum darf 31 Tage nicht überschreiten',
    'isales.statistics.business.dategt12weeks': 'Der Wochenbereich darf 12 Wochen nicht überschreiten',
    'isales.statistics.business.dategt6month': 'Die Monatsspanne darf 6 Monate nicht überschreiten',
    'isales.statistics.business.weekLessThan': 'Die Endjahreswoche kann nicht kürzer sein als die Startjahreswoche',
    'isales.statistics.business.monthLessThan': 'Der Endjahresmonat darf nicht vor dem Startjahresmonat liegen',
    'isales.statistics.business.yearLessThan': 'Das Endjahr kann nicht vor dem Startjahr liegen',
    'isales.statistics.business.noData': 'Keine Daten verfügbar',
    'isales.statistics.business.noMoreThanEight': 'Die Anzahl der ausgewählten Elemente darf 8 nicht überschreiten',
    'isales.statistics.business.noMoreThanFive': 'Die Anzahl der ausgewählten Elemente darf 5 nicht überschreiten',
    'isales.statistics.business.busiResult': 'Geschäftsergebnisse',
    'isales.statistics.business.subBusiResult': 'Teilgeschäftsergebnis',
    'isales.manual.title.viewPreemptionResult': 'Ergebnis des vorbelegten ausgehenden Anrufs-',
    'isales.statistics.business.filteringIndicator': 'Filterindikatoren',
    'isales.statistics.business.indicatorName': 'Indikatorname',
    'isales.statistics.business.description': 'Beschreibung',
    'isales.statistics.business.cancel': 'Abbrechen',
    'isales.statistics.business.confirm': 'Bestätigung',
    'isales.statistics.business.saveError': 'Fehler',
    'isales.statistics.business.errorInfo': 'Speichern fehlgeschlagen. Wählen Sie mindestens einen Indikator',
    'isales.statistics.business.error': 'Fehler',
    'isales.statistics.business.warningFilterMax': 'Es können maximal {0} Indikatoren ausgewählt werden',
    'isales.agent.message.description.limit': 'Die Beschreibung der Skill-Warteschlange darf nicht länger als 50 Zeichen sein',
    'isales.agent.message.updateCtiAndDbFail': 'Aktualisierung fehlgeschlagen',
    'isales.operlog.message.exportSurveyData': 'Schulungsfragen exportieren',
    'isales.taskinfo.message.agent': 'Die Agenteninformationen sind anormal. Prüfen Sie, ob es andere Agenten als die aktuelle Skill-Warteschlange gibt',
    'isales.appointment.message.plaintext': 'Der Benutzer hat keine Klartextberechtigung und kann keine Anrufe initiieren. Bitte aktivieren Sie die Klartextberechtigung',
    'isales.language.label.language': 'Wählen Sie eine Sprache aus',
    'isales.language.label.zh_CN': 'Chinesisch',
    'isales.language.label.en_US': 'Englisch',
    'isales.language.label.es_ES': 'Spanisch',
    'isales.taskmanagement.title.batchOpt': 'Batchbetrieb',
    'isales.audio.title.beginTime': 'Startzeit der Aufzeichnung',
    'isales.audio.title.endTime': 'Endzeit der Aufzeichnung',
    'isales.audio.title.asrTransResult': 'Ergebnisse der Sprachtranskription',
    'isales.audio.title.confirmedResult': 'Ergebnisse der Rückruftonerkennung',
    'isales.audio.title.confirmStatus': 'Geschäftsergebnisse',
    'isales.taskstatus.field.confirming': 'Noch zu bestätigen',
    'isales.taskstatus.field.confirmed': 'Bestätigt',
    'isales.rbtresult.field.other': 'Sonstiges',
    'isales.rbtresult.field.emptynumber': 'Leere Zahl',
    'isales.rbtresult.field.cusclose': 'Der angerufene Benutzer schaltet das Telefon aus',
    'isales.rbtresult.field.interbusy': 'Netzwerk ausgelastet',
    'isales.rbtresult.field.callershutdown': 'Aufhängung von Anruferrückständen / Einwegaufhängung',
    'isales.rbtresult.field.calledshutdown': 'Zum Anhalten aufgerufen',
    'isales.rbtresult.field.calledbusy': 'Der angerufene Teilnehmer ist besetzt und der angerufene Teilnehmer hat die Anklopffunktion registriert',
    'isales.rbtresult.field.calledbusycalling': 'Der angerufene Teilnehmer ist besetzt und der angerufene Teilnehmer hat die Anklopffunktion nicht registriert',
    'isales.rbtresult.field.calledoutarea': 'Der angerufene Teilnehmer befindet sich nicht im Versorgungsbereich',
    'isales.rbtresult.field.transfer': 'Anrufweiterleitung',
    'isales.rbtresult.field.tollcaller': 'Einschränkungen für internationale Anrufe',
    'isales.rbtresult.field.longtimemissed': 'Lange Zeit kein Anruf',
    'isales.rbtresult.field.calledrestriction': 'Der angerufene Benutzer hat Einschränkungen für eingehende Anrufe festgelegt',
    'isales.rbtresult.field.linefaulty': 'Leitungsfehler in Richtung anderer Bediener',
    'isales.rbtresult.field.calledunconnected': 'Der angerufene Teilnehmer ist nicht verfügbar und die Erinnerung an eingehende Anrufe ist aktiviert',
    'isales.rbtresult.emptyparam.message': 'Die Start- und Endzeit darf nicht leer sein',
    'isales.asrtransresult.field.undefine': 'nicht definiert',
    'isales.taskmanagement.message.crossmonth': 'Die Start- und Endzeit darf nicht mehrere Monate umfassen',
    'isales.taskinfo.url.not.whitelist': 'Die Adresse befindet sich nicht in der Vertrauensliste',
    'isales.whitepopuppageurl.protocol.check': 'Es werden unsichere Protokolle verwendet\, was ein Sicherheitsrisiko darstellt',
    'isales.import.message.ruleExtProp': 'Benutzerdefinierte Attribute wurden aktiviert. Konfigurieren Sie zuerst die Import-Zuordnungsregel\ und klicken Sie dann auf Nach Regel importieren',
    'isales.datasourcemanagement.field.load': 'Laden',
    'isales.datasourcemanagement.message.loadSuccess': 'Datenquelle erfolgreich geladen',
    'isales.datasourcemanagement.message.loadfail': 'Datenquelle konnte nicht geladen werden',
    'isales.datasourcemanagement.message.confirLoad': 'Das Laden der Datenquellenkonfiguration ist ein Vorgang mit hohem Risiko \, bitte gehen Sie mit Vorsicht vor',
    'isales.task.promotion.whatsApp': 'WhatsApp',
    'isales.task.promotion.whatsAppChannel': 'WhatsApp-Kanal',
    'isales.task.promotion.whatsApp.message.template': 'Vorlagennachricht',
    'isales.task.promotion.whatsApp.message.template.select': 'WhatsApp-Vorlagennachricht auswählen',
    'isales.task.promotion.whatsApp.channel.select': 'Wählen Sie einen WhatsApp-Kanal aus',
    'isales.task.promotion.whatsApp.message.id': 'Vorlagennachrichten-ID',
    'isales.task.promotion.whatsApp.message.name': 'Name der Vorlagennachricht',
    'isales.task.promotion.whatsApp.variable.description': 'Parameterbeschreibung',
    'isales.task.promotion.whatsApp.variable.content': 'Parameterinhalt',
    'isales.management.message.SelectNumberMax': 'Wählen Sie bis zu 100 Anrufnummern gleichzeitig aus',
    'isales.basicinfo.title.addCallNo': 'Anrufnummer hinzufügen',
    'isales.taskmanagement.title.deleteFailExitOne': 'Konfigurieren Sie mindestens eine Anrufnummer für die Aufgabe des ausgehenden Anrufs',
    'isales.failInterval.check': 'Es können Risiken bestehen, wenn das Intervall weniger als 10 s beträgt',
    'isales.taskmanagement.title.agentStatistics': 'Statistiken zur Agentenarbeit',
    'isales.work.message.daterequest': 'Die Start- und Endzeit müssen gleichzeitig verstreichen',
    'isales.work.title.selectAgent': 'Agent auswählen',
    'isales.work.title.agent.workno': 'Agenten-ID',
    'isales.message.promptForAgent': 'Geben Sie die Agenten-ID ein',
    'isales.button.search': 'Anfragen',
    'isales.button.reset': 'Zurücksetzen',
    'isales.title.agent.workno': 'Agenten-ID',
    'isales.label.agent.accountcode': 'Dienstkonto',
    'isales.agent.work.statistics.AgentName': 'Agentenname',
    'isales.button.cancel': 'Abbrechen',
    'isales.button.confirm': 'Bestätigen',
    'isales.file.message.FilePathTips': 'Dieser Parameter ist optional. Wenn die Datei für ausgehende Anrufe im Standardpfad gespeichert ist\, ist dieser Parameter optional. Wenn die Datei für ausgehende Anrufe im Ordner im Standardpfad gespeichert ist\, muss dieser Parameter festgelegt werden',
    'isales.blacklist.message.timeserror': 'Unzulässige Grenzwertzeiten',
    'isales.blacklist.message.perioderror': 'Falscher Einschränkungszeitraum',
    'isales.datasourcemanagement.message.updateDataSourceSuccess': 'Die Datenquelle wurde erfolgreich geändert',
    'isales.datasourcemanagement.message.updateDataSourceFail': 'Datenquelle konnte nicht geändert werden',
    'isales.task.status.running': 'In Bearbeitung',
    'isales.task.status.pause': 'Pause',
    'isales.task.finished': 'Abgeschlossen',
    'isales.task.notFinished': 'Unvollständig',
    'isales.task.info': 'Aufgabeninformationen',
    'isales.task.benginEndTime': 'Start- und Endzeit',
    'isales.task.detail': 'Aufgabendetails für ausgehende Anrufe',
    'isales.taskmanagement.startTask': 'Aufgabe starten',
    'isales.taskInfo.title.taskTemplate': 'Aufgabenvorlage',
    'isales.taskmanagement.title.taskTime': 'Aufgabenzeit',
    'isales.taskmanagement.title.setAgent': 'Agenten zuweisen',
    'isales.taskInfo.title.quotas': 'Erfüllung der Ergebnisse',
    'isales.taskInfo.title.quotasTips': 'Wenn die Anzahl der vom Agenten als Ergebnis des vorhergehenden Dienstes markierten Anrufe den Wert dieses Parameters übersteigt, ruft das System die Anrufe nicht automatisch ab',
    'isales.taskInfo.title.callCompletedNum': 'Abgeschlossene Anrufe',
    'isales.taskInfo.title.callCompletedNumTips': 'Wenn die Anzahl der abgeschlossenen Anrufe den Wert dieses Parameters überschreitet, ruft das System Anrufe nicht automatisch ab.',
    'isales.taskInfo.title.pickUpNum': 'Einmalig beanspruchte Menge',
    'isales.taskInfo.title.pickUpCondition': 'Bedingungen für die Datenextraktion',
    'isales.step.taskinfo': 'Aufgabeninformationen',
    'isales.step.callpolicy': 'Ausgehende Strategie',
    'isales.step.outboundcalldata': 'Aufgabendaten',
    'isales.calltime.title.default.datetime': 'Reguläre Datum-Zeit-Periode',
    'isales.calltime.title.spec.date': 'Besonderes Datum',
    'isales.calltime.title.add.date': 'Sonderdatum hinzufügen',
    'isales.calltime.title.add.time.period': 'Zeitraum hinzufügen',
    'isales.calltime.title.holidayName': 'Besonderer Datumsname',
    'isales.calltime.title.holidayType': 'Typ',
    'isales.calltime.title.holiday': 'Besondere Zeit',
    'isales.calltime.title.spec.datetime.period': 'Besonderer Zeitraum',
    'isales.calltime.task.template': 'Aufgabenvorlage',
    'isales.calltime.task.template.select.placeholder': 'Option zum Erstellen ausgehender Aufgaben aus Vorlagen',
    'isales.calltime.task.callerno.select.placeholder': 'Wählen Sie die Anrufnummer aus',
    'isales.calltime.task.begin.end.date': 'Start- und Endzeit der Aufgabe',
    'isales.calldata.prop.filter': 'Datenattributfilterung für ausgehende Anrufe',
    'isales.taskinfo.header.policy': 'Aufgabenstrategie',
    'isales.taskinfo.call.header.policy': 'Anrufstrategie',
    'isales.taskmanagement.title.sysAuto': 'Das System ruft automatisch aus',
    'isales.work.message.dateRangeRequest': 'Wählen Sie den Abfragezeitraum aus',
    'isales.work.message.taskNameLengthLimit': 'Der Aufgabenname darf nicht länger als 50 Zeichen sein',
    'isales.work.message.workNoLengthLimit': 'Die Arbeitsnummer darf nicht länger als 5 Zeichen sein',
    'isales.work.message.workNoTypeLimit': 'Der Wert muss 0 oder eine positive ganze Zahl sein',
    'isales.taskInfo.title.filterCondition': 'Zustand',
    'isales.label.agent.agentType': 'Agententyp',
    'isales.label.agent.role': 'Rolle',
    'isales.calleeNo.length.max32': 'Die Kundennummer darf maximal 32 Zeichen enthalten',
    'isales.label.calldata.order': 'Anrufsequenz',
    'isales.tasklogic.field.callOrderMethod.byData': 'Nach Datenattribut',
    'isales.tasklogic.field.callOrderMethod.orderType': 'sortieren nach',
    'isales.label.multiple.callno.round': 'Kundengesprächsrunden mit mehreren Nummern',
    'isales.task.result.export.query': 'Zur Ansicht wechseln',
    'isales.task.result.export.skip': 'Überspringen',
    'isales.task.result.export.message': 'Es wurde eine Exportaufgabe erstellt. Möchten Sie die Exportliste wirklich anzeigen?',
    'isales.rbtrecognition.remark.length': 'Die Zeichenfolge darf maximal 200 Zeichen enthalten',
    'isales.task.field.callFlag0': 'Zur Hinrichtung',
    'isales.task.field.callFlag1': 'Ausführung',
    'isales.task.field.callFlag2': 'Erfolgreich',
    'isales.taskinfo.field.agentType4': 'Audio',
    'isales.taskinfo.field.agentType11': 'Video',
    'isales.taskinfo.field.agentType5': 'Multimedia',
    'isales.taskinfo.field.agentType99': 'Vielseitig',
    'isales.appointment.title.appointState': 'Reservierungsstatus',
    'isales.task.field.appointState1': 'Reserviert',
    'isales.task.field.appointState2': 'Bereit zum Rückruf',
    'isales.task.field.appointState3': 'Rückruf',
    'isales.task.field.appointState4': 'Abgeschlossen',
    'isales.taskinfo.title.adjustAgent': 'Einstellmittel',
    'isales.taskinfo.check.empty': 'Parameter darf nicht leer sein',
    'isales.tasktype.field.robot.create': 'Neuer ausgehender Anruf des Roboters',
    'isales.tasktype.field.auto.info': 'Gemeinsames IVR',
    'isales.tasktype.field.intelligent.info': 'Intelligenter Roboter',
    'isales.taskmanagement.title.batchEditTaskInfo.errTaskType': 'Aufgaben unterschiedlicher Art können nicht stapelweise geändert werden',
    'isales.taskmanagement.title.pauseTask.askConfirm': 'Möchten Sie die Aufgabe für ausgehende Anrufe wirklich anhalten? Wenn die Aufgabe zwangsweise angehalten wird, kann dies Auswirkungen auf laufende Anrufe haben',
    'isales.task.result.callrecord.conversation': 'Gesprächsaufzeichnung',
    'isales.tasktype.field.auto.robot.create': 'Ein neues gemeinsames IVR erstellen',
    'isales.tasktype.field.intelligent.robot.create': 'Schaffe einen neuen intelligenten Roboter',
    'isales.management.title.callbot.auto': 'Konfiguriere die Schaltfläche, um den Roboter zu rufen',
    'isales.taskmanagement.title.taskDataVolume': 'Aufgabendatenvolumen',
    'isales.taskmanagement.title.callCompletionRate': 'Anrufabschlussrate',
    'isales.taskmanagement.title.importData.template': 'Vorlage',
    'isales.taskmanagement.title.importData.upload': 'Hochladen',
    'isales.taskmanagement.title.importData.selectFile': 'Datei auswählen',
    'isales.taskmanagement.pauseTask': 'Aufgabe unterbrechen',
    'isales.taskInfo.spec.date.title.quotasTips': 'Diese Konfiguration gibt das spezielle Datum und die spezielle Uhrzeit an, für die ausgehende Anrufe erforderlich sind.',
    'isales.calltime.title.spec.date.empty.warn': 'Sonderdatum nicht konfiguriert!',
    'isales.tasklogic.message.empty.orderNo': 'Es gibt eine leere Seriennummer, bitte geben Sie sie erneut ein',
    'isales.taskmanagement.exportResult': 'Aufgabenergebnis exportieren',
    'isales.outboundBusiness.statistic.di': 'Woche',
    'isales.outboundBusiness.statistic.week': 'Der ',
    'isales.rbtrecognition.remark.tip': 'Darf keine Sonderzeichen enthalten:',
    'isales.business.callInterval.message.must': 'Intervall ist erforderlich',
    'isales.tasktype.field.robot': 'Roboter ausgehend',
    'isales.taskmanagement.title.sysAuto.template': 'Systemvorlage für automatische ausgehende Anrufe',
    'isales.taskmanagement.title.sysAuto.templateAdd': 'Erstellen einer automatischen Vorlage für ausgehende Anrufe',
    'isales.tasktype.field.robot.template': 'Vorlage für ausgehende Anrufe des Roboters',
    'isales.tasktype.field.robot.templateAdd': 'Erstellen einer Robotervorlage für ausgehende Anrufe',
    'isales.template.title.addManual': 'Vorlage für manuelle ausgehende Anrufe',
    'isales.manual.field.callingRecord': 'Aufzeichnung ausgehender Anrufe',
    'isales.call.count': 'Anzahl der Anrufe',
    'isales.task.promotion.createBeginTime': 'Startzeit der Erstellung',
    'isales.task.promotion.createEndTime': 'Endzeit der Erstellung',
    'isales.task.promotion.timeerror': 'Die effektive Zeit muss vor der Ablaufzeit liegen',
    'isales.busiresult.description.length': 'Die Länge der Zeichenfolge überschreitet den Wertebereich. Die Länge darf höchstens 500 Zeichen betragen',
    'isales.import.coverMode.tips': 'Durch das Überschreiben des Imports werden alle abzurufenden Daten gelöscht, was lange dauert. Seien Sie bei der Durchführung dieses Vorgangs vorsichtig',
    'isales.taskmanagement.title.taskContent': 'Details',
    'isales.taskmanagement.title.saveAndImportData': 'Daten speichern und importieren',
    'isales.task.promotion.numberOnly': 'Nur nicht negative Ganzzahlen sind zulässig',
    'isales.data.lock': 'Einige Daten abgehender Anrufe werden nicht verarbeitet. Beispielsweise importieren, fügen Sie Daten zu ausgehenden Anrufen hinzu oder löschen diese. Bitte versuchen Sie es später erneut',
    'isales.survey.type': 'Fragebogentyp',
    'ccsurvey.label.scenarioType.manual': 'Manueller Fragebogen',
    'ccsurvey.label.scenarioType.manualIvr': 'Manuell vs. IVR-Fragebogen',
    'isales.tasktype.field.manualIvr.info': 'Fragebogenprozess abspielen',
    'ccsurvey.info.tip36': 'Die Frage ist erforderlich',
    'ccsurvey.transfer.tip36': 'Umgang zum IVR-Wiedergabe-Fragebogen unterbrechen',
    'isales.taskinfo.message.callintervalrangeMax': 'Geben Sie eine positive ganze Zahl zwischen 1 und 86400 ein.',
    'isales.notification.receiver.whatsapp.placeholder': 'Bitte geben Sie die Nummer des Empfängers mit der internationalen Vorwahl ein',
    'isales.data.title.more': 'Mehr',
    'isales.manual.exit.preview': 'Ausführen einer Vorschau eines ausgehenden Anrufs',
    'isales.manual.exit.preview.tips': 'Wenn die Vorschau das Zeitlimit überschreitet oder das Fenster geschlossen wird, ruft das System Sie automatisch an.',
    'isales.result.search.displayResultOrCode': 'Bitte geben Sie einen Code oder eine Beschreibung ein',
    'isales.management.message.dbIp': 'IP-Format ist falsch',
    'isales.agent.message.modifyPwdVdnFailed': 'Das Kennwort des Konfigurationsadministrators darf nicht geändert werden!',
    'isales.taskmanagement.title.addSysAuto': 'Erstellen Sie ein neues System, um automatisch anzurufen',
    'isales.business.result.message.checkCodePoint': 'Nur Zahlen, Buchstaben, -,. eingegeben werden kann.',
    'isales.import.calldata.selecttime': 'Wählen Sie die Ausführungszeit',
    'isales.statistics.message.chooseCondition': 'Bitte wählen Sie zuerst Abfragebedingungen aus',
    'isales.taskpolicy.field.time': '(s)',
    'isales.taskInfo.title.filterSubCondition': 'Filterunterbedingungen',
    'isales.agent.work.statistics.tableTips': 'Wählen Sie die Aufgabe oder den Agenten für ausgehende Anrufe aus, die bzw. den Sie zuerst anzeigen möchten.',
    'isales.file.message.rule.add.success': 'Regel erfolgreich erstellt.',
    'isales.file.title.server.add.success': 'Serverinformationen konnten erfolgreich erstellt werden.',
    'isales.management.title.selectResult': 'Wählen Sie das Ergebnis des ausgehenden Anrufs aus',
    'isales.specialday.placeholder.year.beginTime': 'Das Format ist MM-DD, z. B. 12-01',
    'isales.management.message.appointTime': 'Die reservierte Anrufzeit darf nicht vor der aktuellen Systemzeit liegen',
    'isales.management.message.intelligence.taskName': 'Die Länge des Aufgabennamens des ausgehenden Anruftyps des intelligenten Roboters darf 36 Byte nicht überschreiten. Ein Buchstabe oder eine Zahl belegt ein Byte, und ein chinesisches Zeichen belegt drei Byte'
}