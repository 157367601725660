import i18n from '@/lang/index'

const $t = i18n.global.t

export const SPECIALDAY_WEEKLY_ENUM = {
    "1": $t('isales.specialday.field.Monday'),
    "2": $t('isales.specialday.field.Tuesday'),
    "3": $t('isales.specialday.field.Wednesday'),
    "4": $t('isales.specialday.field.Thursday'),
    "5": $t('isales.specialday.field.Friday'),
    "6": $t('isales.specialday.field.Saturday'),
    "7": $t('isales.specialday.field.Sunday')
}

export const TASK_HOLIDAY_TYPES = [
    {
        "value": "1",
        "label": $t('isales.specialday.field.byWeek')
    },
    {
        "value": "2",
        "label": $t('isales.specialday.field.byDate')
    },
    {
        "value": "3",
        "label": $t('isales.specialday.field.byYear')
    }
];

export const CALL_DATA_ORDER_TYPE = [
    {
        "value": 0,
        "label": $t('isales.tasklogic.field.callOrderType.noOrder')
    },
    {
        "value": 1,
        "label": $t('isales.tasklogic.field.callOrderMethod.byOrderNo')
    },
    {
        "value": 2,
        "label": $t('isales.tasklogic.field.callOrderMethod.byData')
    }
]

export const CALL_DATA_ORDER_METHOD = [
    {
        "typeId": "1",
        "typeName": $t('isales.data.orderType.asc')
    },
    {
        "typeId": "2",
        "typeName": $t('isales.data.orderType.desc')
    }
]

export const CALL_DATA_ORDER_ROUND = [
    {
        "value": "0",
        "label": $t('isales.tasklogic.field.callbyorder')
    },
    {
        "value": "1",
        "label": $t('isales.tasklogic.field.callturntype.numbertype')
    },
    {
        "value": "2",
        "label": $t('isales.tasklogic.field.callturntype.numberorder')
    }
]

export const BUSI_RESULT_POLICYS= [
    {
        "value": "0",
        "label": $t('isales.taskcalllogic.busiresultpolicy.stopcall')
    },
    {
        "value": "1",
        "label": $t('isales.taskcalllogic.busiresultpolicy.callnext')
    },
    {
        "value": "2",
        "label": $t('isales.taskcalllogic.busiresultpolicy.callcurrent')
    }
];

export const PREDICT_METHOD = [
    {
        "key": 1,
        "value": $t('isales.taskpolicy.field.experienceMethod')
    },
    {
        "key": 2,
        "value": $t('isales.taskpolicy.field.utilRateMethod')
    },
    {
        "key": 3,
        "value": $t('isales.taskpolicy.field.connRateMethod')
    },
    {
        "key": 5,
        "value": $t('isales.taskpolicy.field.pickupRateMethod')
    }
];

export const UTIL_RATE_METHOD = [
    {
        "key": "1",
        "value": $t('isales.taskpolicy.field.utilRateCalculateOne')
    },
    {
        "key": "2",
        "value": $t('isales.taskpolicy.field.utilRateCalculateTwo')
    }
];

export const POLICE_TYPES = [
    {
        "value": 0,
        "label": $t('isales.taskcalllogic.field.globalPolicy')
    },
    {
        "value": 1,
        "label": $t('isales.taskcalllogic.field.callnoPolicy')
    }
];

export const RESULT_TYPES = [
    {
        "value": "0",
        "label": $t('isales.data.filed.noResult')
    }, {
        "value": "1",
        "label": $t('isales.data.filed.isResult')
    }
];

export const RETRY_DAYS = [
    {
        "value": "1",
        "label": "1"
    }, {
        "value": "2",
        "label": "2"
    }, {
        "value": "3",
        "label": "3"
    }, {
        "value": "4",
        "label": "4"
    }, {
        "value": "5",
        "label": "5"
    }
];

export const RETRY_TIMES = [
    {
        "value": "1",
        "label": "1"
    }, {
        "value": "2",
        "label": "2"
    }, {
        "value": "3",
        "label": "3"
    }, {
        "value": "4",
        "label": "4"
    }, {
        "value": "5",
        "label": "5"
    }, {
        "value": "6",
        "label": "6"
    }
];

export const RETRY_NUMBER_ITEMS_CODES = [
    {
        "value": "0",
        "label": "0"
    }, {
        "value": "1",
        "label": "1"
    }, {
        "value": "2",
        "label": "2"
    }, {
        "value": "3",
        "label": "3"
    }, {
        "value": "4",
        "label": "4"
    }, {
        "value": "5",
        "label": "5"
    }, {
        "value": "6",
        "label": "6"
    }
];

export const RESULT_ITEMS_CODES = [
    {
        "resultId": 1,
        "resultName": $t('isales.taskcalllogic.field.customerbusy')
    },
    {
        "resultId": 2,
        "resultName": $t('isales.taskcalllogic.field.customerMissed')
    },
    {
        "resultId": 3,
        "resultName": $t('isales.taskcalllogic.field.customerRefused')
    },
    {
        "resultId": 4,
        "resultName": $t('isales.taskcalllogic.field.emptyNumber')
    },
    {
        "resultId": 5,
        "resultName": $t('isales.taskcalllogic.field.shutdown')
    },
    {
        "resultId": 6,
        "resultName": $t('isales.taskcalllogic.field.notServiceArea')
    },
    {
        "resultId": 7,
        "resultName": $t('isales.taskcalllogic.field.fax')
    },
    {
        "resultId": 8,
        "resultName": $t('isales.taskcalllogic.field.callTimeout')
    },
    {
        "resultId": 9,
        "resultName": $t('isales.taskcalllogic.field.callFailed')
    },
    {
        "resultId": 0,
        "resultName": $t('isales.taskcalllogic.field.other')
    }
];