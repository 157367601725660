export default {
    "cms.report.message.deviceTypeDesc" : "ประเภทของอุปกรณ์ที่จัดการการโทร",
    "cms.report.message.maxQueueCountDesc" : "จํานวนสูงสุดของการโทรพร้อมกันที่มีอุปกรณ์บริการเป็นคิวทักษะ",
    "cms.report.message.agentTalkingTimeDesc" : "เวลาในการพูดคุย",
    "cms.report.field.maxAgentOutCount" : "จํานวนสูงสุดของการโทรออกพร้อมกันของเอเจนต์",
    "cms.agentMonitor.label.whisper_success" : "กระซิบความสําเร็จ",
    "cms.report.field.inCallSuccNum" : "จํานวนการโทรแบบแมนนวลที่เชื่อมต่ออยู่",
    "cms.report.message.maxQueueInCountDesc" : "จํานวนสูงสุดของสายเรียกเข้าพร้อมกันที่มีอุปกรณ์บริการเป็นคิวทักษะ",
    "cms.report.field.agentReleaseTimeField" : "เวลาวางจําหน่ายของผู้โทร",
    "cms.calllink.field.leaveReason39" : "CDN ส่งกลับผลการกำหนดเส้นทาง",
    "cms.indexmonitor.message.InCallAgentCount" : "จํานวนสายเรียกเข้าที่เชื่อมต่ออยู่",
    "cms.calllink.field.suspendedAndResumed" : "สายที่ถูกระงับจะดำเนินการต่อไป",
    "cms.calllink.field.leaveReason38" : "เข้าสู่ระบบ CDN ผ่านการให้คำปรึกษาของเจ้าหน้าที่",
    "cms.fullscreen.field.line" : "แผนภูมิเส้น",
    "cms.calllink.field.leaveReason37" : "การโทรถูกส่งไปที่ CDN",
    "cms.calllink.field.leaveReason36" : "การกำหนดการจัดคิวสายเข้าใหม่",
    "cms.report.message.busyTimeRateDesc" : "อัตราระยะเวลาไม่ว่าง = (ระยะเวลาไม่ว่างทั้งหมด / ระยะเวลาการทำงานทั้งหมด) x 100",
    "cms.calllink.field.leaveReason35" : "การโทรผ่านเครือข่ายถูกส่งกลับไปที่ศูนย์บริการลูกค้าเดิมและอยู่ในคิว",
    "cms.calllink.field.leaveReason34" : "หมดเวลา / overflow / การโทรผ่านเครือข่ายล้มเหลวขณะรอคิวที่ไม่ว่าง",
    "cms.report.field.agentNum" : "ID เจ้าหน้าที่",
    "cms.calllink.field.leaveReason31" : "การส่งต่อของบุคคลที่สามเพื่อเข้าคิวหมดเวลา",
    "cms.calllink.field.leaveReason30" : "โอนสายเข้าคิวก่อนหมดเวลาได้สำเร็จ",
    "cms.qcrelationconf.field.groupMemberInfo" : "ข้อมูลสมาชิกกลุ่มผู้ใช้",
    "cms.report.field.skillIdField" : "ID ของคิวทักษะที่การโทรเป็น",
    "cms.report.field.maxQueueCountField" : "จํานวนสูงสุดของการโทรพร้อมกันในคิว",
    "cms.agentMonitor.label.selectAgent" : "โปรดเลือกเจ้าหน้าที่",
    "cms.report.message.IVRInBoundDesc" : "จํานวนการโทรเข้าทั้งหมดโดยใช้ IVR",
    "cms.fullscreen.field.yaHei" : "Microsoft YaHei",
    "cms.report.field.releaseCauseField" : "เหตุผลการปลดปล่อย",
    "cms.qualitycheck.message.deleteSuccess" : "ลบสำเร็จ",
    "cms.developerlist.field.description" : "คำอธิบาย",
    "cms.fullscreen.title.newChart" : "สร้างแผนภูมิ",
    "cms.indexmonitor.field.ivrTotalCallNum" : "การโทรเข้า IVR ทั้งหมด",
    "cms.report.field.agentTalkDurationField" : "ระยะเวลาการพูดคุยของผู้โทร ",
    "cms.recorddrive.field.configSftpAccountPwd" : "รหัสผ่านเซิร์ฟเวอร์ SFTP",
    "cms.calllink.field.leaveReason29" : "บุคคลที่สามโอนไปยังคิว",
    "cms.report.message.outCallRateDesc" : "เปอร์เซ็นต์ของจำนวนการโทรออกที่เชื่อมต่อในจำนวนของการโทรออก",
    "cms.calllink.field.leaveReason28" : "โอนเข้าคิวสำเร็จ",
    "cms.qualitycheck.label.selectedGroup" : "กลุ่มผู้ใช้ที่เลือก",
    "cms.recorddrive.field.configSftpPathError" : "เส้นทางจัดเก็บไฟล์ไม่สามารถใส่ './'",
    "cms.indexmonitor.field.skillAbandonIn60Rate" : "วางสายใน 60 วินาที",
    "cms.voiceinspection.field.socialChat" : "มัลติมีเดีย",
    "cms.systemparam.error.validate.ruleinvalid" : "กฎการตรวจสอบไม่ถูกต้อง",
    "cms.tenant.message.pwdCheckmsg" : "รหัสผ่านทั้งสองแตกต่างกัน",
    "cms.calllink.title.skillQueue" : "เชื่อมต่อลำดับทักษะ",
    "cms.fullscreen.field.fiveSec" : "5 วินาที",
    "cms.calllink.msg.numberInvalid" : "รูปแบบตัวเลขไม่ถูกต้อง",
    "cms.fullscreen.field.indicatorData" : "ข้อมูลตัวบ่งชี้",
    "cms.report.message.ackBeginDesc" : "เวลาเริ่มต้นรับสาย",
    "cms.fullscreen.field.themeName" : "ชื่อเรื่อง",
    "cms.report.field.callerHangUp" : "ผู้โทรวางสาย",
    "cms.report.field.waitEndField" : "รอเวลาสิ้นสุด",
    "cms.report.message.sysSuccRateDesc" : "เปอร์เซ็นต์ของจำนวนการโทรเข้าที่สำเร็จในจำนวนการโทรเข้าทั้งหมด",
    "cms.agentMonitor.label.qcinspector_error" : "ไม่สามารถดำเนินการได้เนื่องจากคุณไม่ใช่ผู้ตรวจสอบ",
    "cms.callcenterinstanceslist.message.ccNameUsed" : "มีการใช้ชื่อศูนย์บริการลูกค้า",
    "cms.report.field.outAnswerRate" : "อัตราการเชื่อมต่อการโทรออก",
    "cms.indexmonitor.field.description" : "คำอธิบาย",
    "cms.callcenterinstanceslist.message.createSuccess" : "สร้างศูนย์บริการลูกค้าสำเร็จ",
    "cms.indexmonitor.field.totalCallNum" : "การโทรทั้งหมด",
    "cms.voiceinspection.message.selectVoice" : "เลือกการบันทึก",
    "cms.report.field.IVRCallOutSuccNum" : "การโทรขาออก IVR ที่เชื่อมต่อ",
    "cms.report.message.timeRangeCheckSaas" : "ช่วงการสอบถามต้องไม่เกินห้าวัน",
    "cms.vdnconfiguration.message.updatesuccess" : "อัปเดตสำเร็จ",
    "cms.report.title.skillMonitor" : "การตรวจสอบประสิทธิภาพของลำดับทักษะ",
    "cms.report.field.avgInRingTime" : "ระยะเวลาการโทรขาเข้าเฉลี่ยของเทอร์มินัล",
    "cms.calllink.field.powerOff" : "โทรศัพท์ปิด",
    "cms.report.message.inAvgRingTimeDesc" : "ค่าเฉลี่ยระยะเวลาเสียงเรียกเข้าของเทอร์มินัลของเอเจนต์ ระยะเวลาเสียงกริ่งเฉลี่ยของสายโทรเข้าด้วยตนเอง = ระยะเวลาเสียงกริ่งรวมของสายโทรเข้าด้วยตนเอง/จํานวนครั้งในการรับสาย",
    "cms.report.field.availTimeRate" : "อัตราว่าง",
    "cms.developerlist.title.success" : "สำเร็จ",
    "cms.report.field.avgAcwTime" : "ระยะเวลาที่ใช้สรุปผลการสนทนาโดยเฉลี่ย",
    "cms.recorddrive.placeholder.sftpIdentityChecktips" : "ป้อนบัญชีปัจจุบันและรหัสผ่านของคุณ",
    "cms.fullscreen.field.fullscreen" : "เต็มจอ",
    "cms.systemparam.error.validate.notmatchrulemin" : "พารามิเตอร์อินพุตต่ำกว่าค่าต่ำสุด",
    "cms.report.title.agentMonitor" : "การตรวจสอบสรุปผลการดำเนินงานของเจ้าหน้าที่",
    "cms.datasource.label.delete" : "ลบ",
    "cms.fullscreen.field.dayMonitor" : "สถิติตัวบ่งชี้วันนี้",
    "cms.report.field.call6Rate" : "อัตราเชื่อมต่อสายโทรเข้าด้วยตนเองใน 30 วินาที",
    "cms.report.message.workTimeDesc" : "ระยะเวลารวมตั้งแต่การลงชื่อเข้าระบบของเอเจนต์จนถึงการลงชื่อออกของเอเจนต์",
    "cms.indexmonitor.title.callCenterDayMonitor" : "สถิติตัวบ่งชี้ศูนย์บริการวันนี้",
    "cms.sysparam.config.item.catalog" : "ประเภทพารามิเตอร์",
    "cms.report.field.systemInSucc" : "ความสำเร็จในการโทรเข้า",
    "cms.systemmonitor.message.timeempty" : "* ไม่อนุญาตให้เวลาเริ่มหรือเวลาสิ้นสุดว่างเปล่า",
    "cms.report.message.systemInSuccDesc" : "จํานวนการโทรเข้าระบบที่สําเร็จ รวมถึงการโทรระหว่างการเล่นด้วยเสียง IVR, การเข้าคิว, เสียงเรียกเข้าที่เทอร์มินัลของตัวแทน, และการตอบรับของเจ้าหน้าที่ (ไม่รวมสายโทรเข้าภายใน)",
    "cms.callcenterinstanceslist.title.chooseMode" : "เลือกโหมด",
    "cms.indexmonitor.message.totalAwcNum" : "จำนวนเจ้าหน้าที่ในสถานะสรุปผลการสนทนาหลังจากการจัดการธุรกิจ",
    "cms.recorddrive.label.sftpPort" : "พอร์ตเซิร์ฟเวอร์ SFTP",
    "cms.report.field.agentLoginNumField" : "เจ้าหน้าที่ได้รับการโทร",
    "cms.indexmonitor.message.totalTalkingNum" : "จำนวนเจ้าหน้าที่ที่กำลังประมวลผลการโทร",
    "cms.agentMonitor.label.switch_success" : "การสับเปลี่ยนสําเร็จแล้ว",
    "cms.report.message.maxGarpMonth" : "ไม่สามารถค้นหาข้อมูลมากกว่า 12 เดือนได้",
    "cms.agentmonitor.label.showAlarmTime" : "ช่วงเวลา",
    "cms.report.title.vdnTrafficPeriodReport" : "รายงานปริมาณการใช้ VDN แบบ Interval-based",
    "cms.indexmonitor.field.totalInCallCount" : "การโทรเข้าแบบออนไลน์",
    "cms.callcenterinstanceslist.field.ccName" : "ชื่อศูนย์บริการลูกค้า",
    "cms.fullscreen.field.SimHei" : "SimHei",
    "cms.report.field.queryEndTime" : "หมดเวลา",
    "cms.callcenterinstanceslist.field.kafkaUserName" : "ชื่อผู้ใช้ KAFKA",
    "cms.indexmonitor.field.skillAgentAbandon" : "วางสายโดยเจ้าหน้าที่",
    "cms.report.field.ringTime" : "ระยะเวลาเสียงเรียกเข้าทั้งหมด",
    "cms.report.field.minAgentCount" : "จํานวนขั้นต่ําของการโทรเข้าพร้อมกันของตัวแทน",
    "cms.report.field.acwTimeRateField" : "อัตราระยะเวลาที่ใช้สรุปผลการสนทนา",
    "cms.datasource.message.oracleUrlTip" : "สตริงการเชื่อมต่อฐานข้อมูล: &#8220jdbc:oracle:thin:@ip:port:dbservicename&#8220&#10;ip (ที่อยู่ IP) port (หมายเลข Port) dbservicename (ชื่อฐานข้อมูล)",
    "cms.skilladjustment.field.seach" : "ค้นหา",
    "cms.uapmonitor.labe.mentionnotice" : "2. ตรวจสอบให้แน่ใจว่าศูนย์บริการเสมือนมีผู้ตรวจสอบและผู้ตรวจสอบได้กําหนดค่าหมายเลขซอฟต์โฟนและรหัสผ่านการลงชื่อเข้าใช้แล้ว เฉพาะตัวแทนที่อยู่ในสถานะผิดปกติเท่านั้นที่สามารถบังคับให้ออกจากระบบได้",
    "cms.agentMonitor.label.search" : "ค้นหา",
    "cms.report.title.IVRMonitor" : "การตรวจสอบข้อมูล IVR",
    "cms.indexmonitor.message.errorInfo" : "โปรดเลือกลำดับทักษะอย่างน้อยหนึ่งอย่าง",
    "cms.qualitycheck.field.selected" : "เลือก",
    "cms.recorddrive.field.storageLimit" : "ขีดจำกัดการจัดเก็บต่ำสุด (MB)",
    "cms.datasource.message.dbNameInput" : "โปรดป้อนชื่อแหล่งข้อมูล",
    "cms.indexmonitor.field.ivrCallNum" : "คำขอการโทรเข้าระบบ IVR",
    "cms.qcrelationconf.field.search" : "การค้นหาข้อมูล",
    "cms.report.field.playVoiceCount" : "เวลาเล่นเสียง",
    "cms.sysparam.config.reset" : "รีเซ็ต",
    "cms.report.field.threePartyCallCountField" : "การโทรสามฝ่าย",
    "cms.report.field.maxIVRInCountField" : "จํานวนสูงสุดของสายเรียกเข้า IVR พร้อมกัน",
    "cms.skilladjustment.field.skillid" : "ID คิวทักษะ",
    "cms.report.title.agentTrend" : "แนวโน้มสรุปผลการดำเนินงานของเจ้าหน้าที่",
    "cms.fullscreen.field.busyCountTimeDesc" : "จํานวนครั้งที่เจ้าหน้าที่อยู่ในสถานะไม่ว่าง",
    "cms.report.label.export" : "ส่งออก",
    "cms.export.message.exceedlimit2" : "จำกัดช่วงเวลาข้อมูลให้แคบลงหรือเพิ่มเกณฑ์กรองข้อมูล",
    "cms.report.message.minAgentOutCountDesc" : "จํานวนขั้นต่ําของการโทรออกพร้อมกันที่มีอุปกรณ์บริการเป็นตัวแทน",
    "cms.export.message.exceedlimit1" : "สามารถส่งออกบันทึกข้อมูลได้สูงสุด 0 รายการ จำกัดช่วงเวลาข้อมูลให้แคบลงหรือเพิ่มเกณฑ์กรองข้อมูล",
    "cms.voiceinspection.field.videoPlay" : "การเล่นวิดีโอ",
    "cms.agentmonitor.label.talkingState" : "กำลังสื่อสาร",
    "cms.calllink.field.connectionHold" : "การโทรเชื่อมต่ออยู่",
    "cms.report.field.loginCountField" : "จำนวนลงชื่อเข้าใช้",
    "cms.report.field.manToIvrNumCtiField" : "การโอนด้วยตนเองไปยัง IVR",
    "cms.report.field.minAgentInCountField" : "จํานวนขั้นต่ําของสายเรียกเข้าของเจ้าหน้าที่พร้อมกัน",
    "cms.callcenter.field.reset" : "รีเซ็ต",
    "cms.uapmonitor.labe.interval" : "ระยะเวลาการตรวจสอบ",
    "cms.report.field.intelligentOutCall" : "การโทรขาออกอัจฉริยะ",
    "cms.report.field.inWaitAnsNumField" : "สายเรียกเข้าด้วยตนเอง",
    "cms.indexmonitor.field.totalIdeaNum" : "เจ้าหน้าที่อยู่ในสถานะว่าง",
    "cms.callcenterinstanceslist.field.gwPort" : "CC-พอร์ตเกตเวย์",
    "cms.report.field.playVoiceCountField" : "เวลาเล่นเสียง",
    "cms.report.field.transferOutCountField" : "จำนวนการโอนออกด้วยตนเอง",
    "cms.uapmonitor.label.error" : "ข้อผิดพลาด",
    "cms.systemparam.error.validate.notmatchrulealpha" : "โปรดป้อนตัวอักษรและตัวเลขหรือขีดล่าง และขึ้นต้นด้วยตัวอักษรหรือขีดล่างเท่านั้น",
    "cms.recorddrive.message.sftpIdentityChecktips" : "บัญชีหรือรหัสผ่านของเซิร์ฟเวอร์ SFTP มีการเปลี่ยนแปลง เพื่อรักษาความปลอดภัยของข้อมูล ป้อนบัญชีปัจจุบันและรหัสผ่านของคุณ",
    "cms.report.field.otherTimeRateField" : "อัตราระยะเวลาอื่น",
    "cms.monitordetail.field.signinskill" : "คิวลงชื่อเข้าใช้",
    "cms.report.message.maxAgentInCountDesc" : "จํานวนสูงสุดของสายเรียกเข้าพร้อมกันที่มีอุปกรณ์บริการเป็นตัวแทน",
    "cms.report.message.netEntIdDesc" : "ID ของ ME ที่สร้าง CDR ปัจจุบัน",
    "cms.callcenter.message.updatesuccess" : "อัปเดตสำเร็จ",
    "cms.report.field.IVRCallOutNum" : "การโทรขาออก IVR",
    "cms.report.message.minIVROutCountDesc" : "จํานวนขั้นต่ําของการโทรออกพร้อมกันที่มีอุปกรณ์บริการคือ IVR",
    "cms.qcrelationconf.title.error" : "ผิดพลาด",
    "cms.failurecall.message.currentFailureCall" : "มี",
    "cms.report.field.avgCallInTimeField" : "ระยะเวลาการโทรโดยเฉลี่ย",
    "cms.indexmonitor.title.vdnNowMonitor" : "สถิติการตรวจสอบแบบเรียลไทม์บน VDN ในระหว่างที่ทำงาน",
    "cms.agentMonitor.label.interceptNotice" : "ใช้คุณสมบัตินี้ขณะฟัง เปล่งเสียง หรือกระซิบกับตัวแทนปัจจุบัน",
    "cms.callcenterinstanceslist.field.ccBcsPort" : "พอร์ต CCBCS LB",
    "cms.report.message.callerNoDesc" : "หมายเลขโทร",
    "cms.report.field.failAvgTimeField" : "ระยะเวลาเสียงเรียกเข้าโดยเฉลี่ยของสายที่หลุดไป",
    "cms.report.field.avgAgentOutCountField" : "จํานวนเฉลี่ยของการโทรออกพร้อมกันของเอเจนต์",
    "cms.report.field.callSuccRate" : "อัตราการเชื่อมต่อ",
    "cms.report.message.succWaitAnsRateDesc" : "ระยะเวลาการเข้าคิวโดยเฉลี่ยของการโทรที่เชื่อมต่อทั้งหมดในคิว",
    "cms.report.field.onlineRate" : "อัตราออนไลน์",
    "cms.callcenter.field.deletefailure" : "การลบล้มเหลว",
    "cms.report.field.stateField" : "รัฐเชื่อมต่อ",
    "cms.report.message.nomorethanerror" : "วันที่ที่เลือกไม่สามารถเร็วกว่า {0}",
    "cms.report.field.avgHoldTimeField" : "ระยะเวลาถือสายโดยเฉลี่ย",
    "cms.qualitycheck.title.deleteSuccess" : "การลบสำเร็จ",
    "cms.calllink.field.cancelQueuingTransfer" : "การโทรถูกโอนเมื่อการเข้าคิวรอสายถูกยกเลิก",
    "cms.systemmonitor.title.error" : "ผิดพลาด",
    "cms.report.field.acwOutCount" : "เวลาที่ใช้สรุปผลการโทรออก",
    "cms.indexmonitor.message.agentAbandonIn10NDesc" : "จํานวนการละทิ้งทั้งหมดที่มีระยะเวลาคําตอบนานกว่าหรือเท่ากับ 10 วินาที",
    "cms.report.field.preDeviceNoField" : "หมายเลขอุปกรณ์ของอุปกรณ์ก่อนหน้า",
    "cms.systemmonitor.field.incallNums" : "สายเรียกเข้าของระบบ",
    "cms.systemmonitor.message.queryErrorInfo" : "การค้นหาข้อมูลล้มเหลว",
    "cms.datasource.title.modifyDatasource" : "แหล่งข้อมูลอัปเดต",
    "cms.indexmonitor.field.inboundConnectRate" : "เชื่อมต่อการโทรเข้า",
    "cms.report.title.abandonRingReport" : "รายงานสรุปการโทรที่ถูกละทิ้ง (ระหว่างการเรียกเข้า)",
    "cms.uapmonitor.field.registered" : "ลงทะเบียนแล้ว",
    "cms.reportsubscribe.field.suspended" : "ไม่ได้เผยแพร่",
    "cms.indexmonitor.message.skillAvgAbandonQueueDesc" : "ระยะเวลาเข้าคิวโดยเฉลี่ยของการโทรที่ไม่ได้รับทั้งหมดในคิว",
    "cms.indexmonitor.field.callInIVR" : "สายเรียกเข้าออนไลน์ระบบ IVR",
    "cms.fullscreen.field.holdCountTimeDesc" : "จํานวนครั้งที่ตัวแทนอยู่ในสถานะกักตัว",
    "cms.calllink.field.analysisNotConfig" : "การวิเคราะห์หมายเลขที่เรียกไม่ได้กำหนดค่าสำหรับการโทรไว้",
    "cms.voiceinspection.video.tolong.info" : "วิดีโอที่บันทึกในปัจจุบันยาวเกินไป โปรดดาวน์โหลดและเล่น",
    "cms.developerlist.title.refreshsk" : "อัปเดต SK",
    "cms.calllink.title.caller" : "เบอร์ต้นทาง",
    "cms.report.field.onlineRateField" : "อัตราออนไลน์",
    "cms.indexmonitor.message.agentConnectedIn10NDesc" : "จํานวนการโทรทั้งหมดที่มีระยะเวลาการรับสายนานกว่าหรือเท่ากับ 10 วินาที",
    "cms.export.message.exception" : "เกิดข้อผิดพลาดขณะสร้างงานส่งออก ตรวจสอบบันทึก",
    "cms.reportsubscribe.field.sunday" : "วันอาทิตย์",
    "cms.report.title.vdnSystemPerformanceReport" : "รายงานการตรวจสอบประสิทธิภาพระบบ",
    "cms.calllink.title.callee" : "เบอร์โทร",
    "cms.callcenterinstanceslist.message.dsmodifyconfirm" : "การกําหนดค่าแหล่งข้อมูลถูกเปลี่ยน เริ่มต้นสภาพแวดล้อมใหม่เพื่อให้การแก้ไขมีผลบังคับใช้ คุณแน่ใจหรือไม่ว่าคุณต้องการแก้ไข?",
    "cms.callcenter.message.deletesuccess" : "ลบสำเร็จ",
    "cms.report.field.dualCallType" : "วัน-คลิก ทู-คอล",
    "cms.report.title.selectReportTime" : "ตั้งเวลาเริ่มต้น",
    "cms.callcenter.message.configcallcenter" : "กำหนดค่าศูนย์บริการลูกค้า",
    "cms.report.field.manToSkillNumField" : "การโอนคิวแบบ Manual-to-skill",
    "cms.report.message.playVoiceCountDesc" : "จำนวนครั้งการเล่นด้วยเสียง",
    "cms.indexmonitor.label.search" : "ค้นหา",
    "cms.systemparam.error.validate.notmatchrule" : "พารามิเตอร์อินพุตไม่ตรงกับกฎการตรวจสอบ",
    "cms.systemmonitor.field.selectCcName" : "เลือกศูนย์บริการลูกค้า",
    "cms.report.message.agentInBoundDesc" : "จํานวนสายเรียกเข้าที่กําหนดให้กับคิวทักษะหรือตัวแทน",
    "cms.callcenterinstanceslist.message.clearKafkaConfig" : "ล้างข้อมูลการกําหนดค่าโหนด KAFKA",
    "cms.report.field.auxTimeRate" : "อัตราระยะเวลาพัก",
    "cms.developerlist.message.refreshfailed" : "อัปเดต SK ล้มเหลว",
    "cms.export.field.success" : "สำเร็จ",
    "cms.fullscreen.message.numberSecondMin" : "ค่าขั้นต่ำคือ 720",
    "cms.indexmonitor.field.totalLoginNum" : "เข้าสู่ระบบเจ้าหน้าที่",
    "cms.agentMonitor.label.beinidle_error" : "เจ้าหน้าที่ที่ตรวจสอบว่างอยู่",
    "cms.report.message.agentReleaseTimeDesc" : "เวลาปล่อยคอลเลอร์",
    "cms.report.field.agentInSuccRateField" : "อัตราการเชื่อมต่อสายเข้าด้วยตนเอง",
    "cms.report.field.fifteenminutes" : "15 นาที",
    "cms.qualitycheck.label.editMember" : "แก้ไขสมาชิกกลุ่ม",
    "cms.fullscreen.message.errorMonitorMethod" : "เลือกโหมดตรวจสอบ",
    "cms.qualitycheck.label.createusergroup" : "สร้างกลุ่มผู้ใช้",
    "cms.record-playback.callType3" : "การโทรออก OBS",
    "cms.report.message.abortQueueWaitNumDesc" : "จำนวนการโทรที่ผู้ใช้บริการละทิ้งระหว่างการเข้าคิวรอสายและเสียงเรียกเข้า",
    "cms.report.field.avgQueueOutCount" : "จํานวนเฉลี่ยของการโทรออกพร้อมกันในคิว",
    "cms.systemparam.error.validate.notmatchrulemax" : "พารามิเตอร์อินพุตเกินค่าสูงสุด",
    "cms.indexmonitor.field.cancel" : "ยกเลิก",
    "cms.indexmonitor.field.outboundConnectRate" : "ละทิ้งการโทรออก",
    "cms.indexmonitor.field.outboundAbandonRate": "ละทิ้งการโทรออก",
    "cms.callcenterinstanceslist.field.vdnName" : "ชื่อ VDN",
    "cms.uapmonitor.field.socketNormal" : "ปกติ",
    "cms.report.field.avgCallInTime" : "ระยะเวลาการโทรโดยเฉลี่ย",
    "cms.synchronize.message.noConfConfPwd" : "ไม่ได้กำหนดค่ารหัสผ่าน ทำการกำหนดค่ารหัสผ่านอินเตอร์เฟซเพื่อกำหนดค่า",
    "cms.report.field.startAgentId" : "ID เจ้าหน้าที่-เริ่มต้น",
    "cms.report.field.avgQueueCountField" : "จํานวนเฉลี่ยของการโทรพร้อมกันในคิว",
    "cms.report.field.auxCount" : "เวลาพัก",
    "cms.uapmonitor.labe.inspectorcheckinnum" : "หมายเลขลงชื่อเข้าใช้ผู้ตรวจสอบ QC",
    "cms.report.field.avgQueueInCount" : "จํานวนเฉลี่ยของสายเรียกเข้าพร้อมกันในคิว",
    "cms.systemparam.error.validate.notmatchrulerange" : "พารามิเตอร์อินพุตเกินค่าสูงสุดหรือต่ำกว่าค่าต่ำสุด",
    "cms.report.message.transferIvrCountDesc" : "จำนวนครั้งในการโอนสายด้วยตนเองไปยัง IVR",
    "cms.callcenterinstanceslist.message.wasPsdLengthRangeValidateFailed" : "ความยาวของรหัสผ่านควรอยู่ระหว่าง 16 ถึง 32",
    "cms.indexmonitor.field.skillAbandonIn10Rate" : "วางสายใน 10 วินาที",
    "cms.indexmonitor.field.skillAbandonIn20Rate" : "วางสายใน 20 วินาที",
    "cms.indexmonitor.message.skillAvgConnectedDesc" : "ระยะเวลาการโทรโดยเฉลี่ย ระยะเวลาการโทรเข้าด้วยตนเองโดยเฉลี่ย = ระยะเวลาการโทรเข้าด้วยตนเอง / จํานวนการโทรเข้าด้วยตนเองที่เชื่อมต่อด้วยตนเอง",
    "cms.monitordetail.field.mediatype" : "การโทรประเภทสื่อ",
    "cms.agentMonitor.label.beinbusy_error" : "เจ้าหน้าที่ที่ตรวจสอบไม่ว่าง",
    "cms.qualitycheck.title.saveSuccess" : "สำเร็จ",
    "cms.sysparam.config.itemdesc" : "คําอธิบาย",
    "cms.report.message.manTransferOutNumCtiDesc" : "จํานวนครั้งที่ตัวแทนถ่ายโอนไปยัง IVR แล้วโอนออก",
    "cms.developerlist.field.ak" : "นักพัฒนา AK",
    "cms.datasource.title.addDatasource" : "การเพิ่มแหล่งข้อมูล",
    "cms.fullscreen.message.numberFirstMin" : "ค่าต่ำสุดคือ 1280",
    "cms.vcallecenter.message.modifyadminpasswordsuccess" : "ปรับเปลี่ยนรหัสผ่านสำเร็จ",
    "cms.qcrelationconf.placeholder.groupName" : "ชื่อกลุ่ม",
    "cms.calllink.title.agentId" : "ID เจ้าหน้าที่ที่เชื่อมต่อ",
    "cms.indexmonitor.field.outboundConnectNum" : "การโทรออกที่เชื่อมต่อ",
    "cms.reportsubscribe.field.saturday" : "วันเสาร์",
    "cms.indexmonitor.message.inboundConnectRate" : "เปอร์เซ็นต์ของจํานวนสายเรียกเข้าที่เชื่อมต่อในจํานวนสายเรียกเข้า",
    "cms.agentmonitor.label.qualityInspection" : "การตรวจสอบคุณภาพ",
    "cms.indexmonitor.field.skillConnectedIn60Rate" : "เชื่อมต่อการโทรใน 60 วินาที",
    "cms.qcrelationconf.message.addSuccess" : "เพิ่มสำเร็จ",
    "cms.failurecall.field.callSequenceNumber" : "SN Call",
    "cms.report.field.inCallFailNum" : "คำขอสายที่หลุดไป",
    "cms.report.field.submediatype.email" : "Email",
    "cms.report.field.IVRCallOutSuccRate" : "อัตราการเชื่อมต่อสายโทรออก IVR",
    "cms.qcrelationconf.field.qcCcRoleName" : "บทบาทของผู้ตรวจสอบ",
    "cms.indexmonitor.field.chooseAll" : "เลือกทั้งหมด",
    "cms.report.message.outSuccAnsRateDesc" : "เปอร์เซ็นต์ของจํานวนสายโทรออกที่เชื่อมต่อในจํานวนสายโทรออก",
    "cms.export.message.addExportTaskError" : "งานส่งออกใหม่ล้มเหลวกรุณาตรวจสอบการบันทึก",
    "cms.agentmonitor.label.haveAlarm" : "มีสัญญาณเตือนภัย",
    "cms.calllink.title.callBeginTime" : "เวลาเริ่มการโทร",
    "cms.sysparam.config.opterate" : "ปฏิบัติการ",
    "cms.agentmonitor.label.idle" : "บังคับให้ว่าง",
    "cms.report.field.ivrtrafficreport.ivrinbound" : "IVR ถือครอง Num",
    "cms.report.field.perAgentNum" : "สายของเจ้าหน้าที่",
    "cms.report.message.auxCountDesc" : "จำนวนครั้งที่เจ้าหน้าที่เข้าสู่สถานะการพัก",
    "cms.indexmonitor.field.callOutIVR" : "การโทรออกออนไลน์ระบบ IVR",
    "cms.agentmonitor.label.preoccupationState" : "จองการใช้งานล่วงหน้า",
    "cms.calllink.field.threePartyCall" : "การโทรเป็นการโทรแบบสามฝ่าย",
    "cms.report.message.talkingTimeDesc" : "เวลาในการพูดคุย",
    "cms.systemmonitor.field.time" : "เวลาสถิติ",
    "cms.report.field.acdTimeField" : "ระยะเวลาการโทรด้วยตนเอง",
    "cms.report.title.skillAccessReport" : "รายงานสถิติประสิทธิภาพของคิวทักษะตามรหัสการเข้าถึง",
    "cms.callcenter.field.cancel" : "ยกเลิก",
    "cms.uapmonitor.labe.qcworkno" : "ID พนักงาน QC",
    "cms.report.field.threePartyCallCountDesc" : "จํานวน 3 สายของเอเย่นต์",
    "cms.voiceinspection.message.noRecordFound" : "ไม่พบการบันทึกที่ตรงกัน",
    "cms.report.field.muteTimeField" : "ระยะเวลาเงียบทั้งหมด",
    "cms.report.message.timeCheckError" : "ช่วงเวลาต้องไม่เกินเจ็ดวัน",
    "cms.indexmonitor.field.skillAvgConnectedQueue" : "ระยะเวลาเข้าคิวโดยเฉลี่ย(วินาที)",
    "cms.report.message.billInfo2Desc" : "รหัสเฉพาะของการโทรสองสาย",
    "cms.failurecall.field.reset" : "รีเซ็ต",
    "cms.indexmonitor.field.ivrInboundConnectRate" : "การโทรเข้า IVR ที่เชื่อมต่อ",
    "cms.report.message.availTimeDesc" : "ระยะเวลาทั้งหมดเมื่อเจ้าหน้าที่อยู่ในสถานะว่าง",
    "cms.voiceinspection.field.operat" : "การปฏิบัติการ",
    "cms.voiceinspection.field.predictOutbound" : "การโทรออกเชิงคาดการณ์",
    "cms.fullscreen.field.totalCallTime" : "ระยะเวลาการโทรทั้งหมด ",
    "cms.callcenterinstanceslist.field.ctiIndexMonitor" : "จอแสดงตัวบ่งชี้ระบบ CTI",
    "cms.indexmonitor.message.totalInCallCount" : "จำนวนการโทรเข้าทั้งหมด(ไม่มีเซสชันมัลติมีเดีย)",
    "cms.fullscreen.field.monitorType" : "ประเภทตัวบ่งชี้",
    "cms.indexmonitor.field.skillAbandonIn5Rate" : "วางสายใน 5 วินาที",
    "cms.sendnote.title.tips" : "ข้อมูล",
    "cms.indexmonitor.field.callRate" : "อัตราการโทร",
    "cms.sysparam.config.item.detail" : "ข้อมูลพารามิเตอร์",
    "cms.skilladjustment.message.notMatchAgent" : "ประเภทของลำดับทักษะที่ปรับแต่งไม่ตรงกับประเภทเจ้าหน้าที่",
    "cms.sendnote.field.notecontent" : "เนื้อหา",
    "cms.report.message.dategt12weeks" : "ช่วงสัปดาห์ไม่สามารถเกิน 12 สัปดาห์",
    "cms.qcrelationconf.field.agentCcRoleName" : "บทบาทของพรรคที่ตรวจสอบแล้ว",
    "cms.agentMonitor.label.cancelInsert_error" : "ยกเลิกการแทรกล้มเหลว",
    "cms.indexmonitor.message.ivrCallNum" : "จำนวนการโทรโดยอุปกรณ์ IVR ที่ให้บริการแบบอัตโนมัติ",
    "cms.report.message.otherTimeDesc" : "ระยะเวลาทั้งหมดเมื่อเจ้าหน้าที่อยู่ในสถานะอื่น หรือระยะเวลาการโทรของทักษะอื่นเมื่อมีทักษะหลากหลายอย่าง",
    "cms.report.field.outCallTime" : "ระยะเวลาการโทรออกทั้งหมด",
    "cms.callcenterinstanceslist.field.pool" : "โหมดพูล",
    "cms.systemparam.error.validate.notmatchruleemail" : "โปรดป้อนที่อยู่อีเมลที่ถูกต้อง",
    "cms.developerlist.field.search" : "ค้นหา",
    "cms.indexmonitor.message.skillIdMaxSize" : "สามารถเลือกลำดับทักษะได้สูงสุดห้าคิว",
    "cms.report.message.dategt1year" : "ช่วงเดือนไม่สามารถเกิน 12 เดือน",
    "cms.report.field.minAgentOutCountField" : "จํานวนขั้นต่ําของการโทรออกพร้อมกันของเอเจนต์",
    "cms.uapmonitor.field.Logout" : "ออกจากระบบ",
    "cms.report.field.callSuccRateField" : "อัตราการเชื่อมต่อ",
    "cms.indexmonitor.message.agentInboundConnectNum" : "จำนวนการโทรเข้าด้วยตนเองที่ตอบรับสายโดยเจ้าหน้าที่",
    "cms.export.field.refresh" : "รีเฟรช",
    "cms.agentmonitor.label.noAlarm" : "ไม่มีการแจ้งเตือน",
    "cms.indexmonitor.message.agentConnectInThirty" : "เปอร์เซ็นต์ของจํานวนสายที่เชื่อมต่อด้วยตนเองภายใน 30 วินาทีของจํานวนสายที่เชื่อมต่อด้วยตนเองทั้งหมด",
    "cms.qcrelationconf.field.agentGroup" : "กลุ่มเจ้าหน้าที่",
    "cms.indexmonitor.field.skillAbandonAfter60" : "วางสายหลังจาก 60 วินาที",
    "cms.report.field.avgIVRInCountField" : "จํานวนเฉลี่ยของสายเรียกเข้า IVR พร้อมกัน",
    "cms.datasource.field.backupDbName" : "แหล่งข้อมูลสํารองข้อมูล",
    "cms.indexmonitor.message.outboundConnectNum" : "จำนวนการเชื่อมต่อการโทรออกด้วยตนเอง + จำนวนของการโทรออก IVR ที่เชื่อมต่อ",
    "cms.report.message.minIVRCountDesc" : "จํานวนขั้นต่ําของการโทรพร้อมกันที่มีอุปกรณ์บริการคือ IVR",
    "cms.report.field.busyTimeField" : "ระยะเวลาไม่ว่างทั้งหมด",
    "cms.fullscreen.field.bold" : "ตัวหนา",
    "cms.vcallecenter.title.authentication" : "ยืนยันตัวตน",
    "cms.recorddrive.field.configSftpAccountConfirmPwd" : "ยืนยันรหัสผ่านเซิร์ฟเวอร์ SFTP",
    "cms.indexmonitor.label.reset" : "รีเซ็ต",
    "cms.callcenterinstanceslist.message.updateCtiVersion2" : "ตรวจสอบว่าการโอนย้ายข้อมูล CTI เสร็จสมบูรณ์หรือไม่ ใช้ความระมัดระวังเมื่อดำเนินการนี้ (หลังจากคุณยืนยันการปฏิบัติการ ฟังก์ชันการซิงโครไนซ์อัตโนมัติจะปิดการใช้งาน)",
    "cms.skilladjustment.field.inputskill" : "ป้อนชื่อคิวทักษะ",
    "cms.report.title.callCenterReport" : "รายงานประสิทธิภาพ VDN",
    "cms.qcrelationconf.field.operator" : "ผตัวแทนทั่วไป",
    "cms.report.field.noAckNumField" : "สายที่ไม่ได้รับ",
    "cms.agentmonitor.label.showAlarmEmotionType" : "ประเภทอารมณ์",
    "cms.report.message.inAvgCallTimeDesc" : "ระยะเวลาการโทรโดยเฉลี่ย ระยะเวลาเฉลี่ยของการโทรเข้าด้วยตนเองที่เชื่อมต่ออยู่ = ระยะเวลาของการโทรเข้าด้วยตนเองที่เชื่อมต่ออยู่/จํานวนการโทรเข้าด้วยตนเองที่เชื่อมต่ออยู่",
    "cms.report.field.agentAlertingTimeField" : "เวลาแจ้งเตือนผู้โทร",
    "cms.agentmonitor.label.answering" : "กำลังตอบคำถาม",
    "cms.qcrelationconf.field.role" : "บทบาท",
    "cms.voiceinspection.field.webphoneVideo" : "คลิกเพื่อโทรวิดีโอ",
    "cms.report.field.muteTime" : "ระยะเวลาเงียบทั้งหมด",
    "cms.report.field.outAvgRingTimeField" : "ระยะเวลาเสียงเรียกเข้าโดยเฉลี่ยของการโทรออกด้วยตนเอง",
    "cms.report.field.avgHandleTime" : "ระยะเวลาการประมวลผลโดยเฉลี่ย",
    "cms.report.field.maxAgentOutCountField" : "จํานวนสูงสุดของการโทรออกพร้อมกันของเอเจนต์",
    "cms.indexmonitor.message.totalAnsweringNum" : "จำนวนเจ้าหน้าที่ที่โทรศัพท์ดังขึ้น",
    "cms.fullscreen.message.playTheme" : "มีการเล่นชื่อเรื่องนี้แล้ว",
    "cms.fullscreen.field.yes" : "ใช่",
    "cms.indexmonitor.message.skillTimeoutQueueDesc" : "จำนวนการโทรถูกโอนจากลำดับทักษะเดิมไปยังลำดับทักษะอื่นเนื่องจากหมดเวลาการเข้าคิว",
    "cms.report.field.mediatype.clickvediocall" : "คลิก Video Call",
    "cms.sysparam.config.itemname.tips" : "ป้อนชื่อพารามิเตอร์",
    "cms.qualitycheck.label.view" : "ดู",
    "cms.agentMonitor.label.error" : "ผิดพลาด",
    "cms.vcallecenter.field.adminworkno" : "ID ผู้ดูแลระบบ",
    "cms.reportsubscribe.field.subscribeContent" : "เนื้อหาผู้ใช้บริการ",
    "cms.voiceinspection.field.callType" : "ประเภทการโทร",
    "cms.agentmonitor.label.accountCode" : "ป้อนบัญชีบริการ",
    "cms.indexmonitor.message.agentOutCallCount" : "จํานวนการโทรออกโดยตัวแทน(ไม่มีเซสชันมัลติมีเดีย)",
    "cms.developerlist.message.deletefailed" : "การลบล้มเหลว",
    "cms.reportsubscribe.field.thursday" : "วันพฤหัสบดี",
    "cms.report.field.acwTimeField" : "ระยะเวลาที่ใช้สรุปผลการสนทนาทั้งหมด",
    "cms.report.field.callerNoField" : "หมายเลขโทร",
    "cms.indexmonitor.field.skillOverQueue" : "การโอนสาย overflow ไปยังคิว",
    "cms.report.field.availTimeField" : "ระยะเวลาที่ไม่ได้ทำงาน",
    "cms.indexmonitor.message.mediaTypeMetion" : "รายการตัวกรองประเภทสื่อใช้ได้เฉพาะสําหรับ KPIs ที่เป็นวันปัจจุบันและแนวโน้มที่เป็นวันปัจจุบันเท่านั้น",
    "cms.agentmonitor.label.occupationState" : "ถูกใช้งานแล้ว",
    "cms.report.field.minIVRInCount" : "จํานวนขั้นต่ําของสายเรียกเข้า IVR พร้อมกัน",
    "cms.synchronize.message.invalidAdminPwd" : "รหัสผ่านไม่ถูกต้อง เปลี่ยนแปลงรหัสผ่านผู้ดูแลระบบ",
    "cms.vcallecenter.title.success" : "สำเร็จ",
    "cms.agentMonitor.label.forceidle_success" : "เจ้าหน้าที่ถูกบังคับให้ตั้งค่าอยู่ในสถานะว่างสำเร็จ",
    "cms.report.message.currentDateCheck" : "เวลาที่เลือกต้องเร็วกว่าวันปัจจุบัน",
    "cms.vcallcenter.message.unknowsolution" : "โปรดติดต่อวิศวกร Huawei เพื่อประมวลผลการวิเคราะห์ที่ผิดปกติ",
    "cms.vcallecenter.message.modifyadminpasswordfail" : "เปลี่ยนแปลงรหัสผ่านล้มเหลว",
    "cms.report.field.onlyIVRServiceField" : "การโทรขาเข้า Pure-IVR ที่ประสบความสําเร็จ",
    "cms.monitordetail.field.account" : "บัญชี",
    "cms.report.field.agentInBound" : "การโทรเข้าด้วยตนเอง",
    "cms.report.field.assistsCount" : "คำขอความช่วยเหลือภายใน",
    "cms.callcenterinstanceslist.field.cmsIp" : "ที่อยู่ IP CMS LB",
    "cms.datasource.field.dbServiceType" : "ประเภทบริการ",
    "cms.systemmonitor.field.agentOnlineCount" : "ลูกค้าซอฟต์ที่ลงทะเบียน",
    "cms.indexmonitor.message.agentConnectedOut60NDesc" : "จํานวนสายที่เชื่อมต่อทั้งหมดที่มีระยะเวลารับสายนานกว่า 60 วินาที",
    "cms.report.field.avgPerHNum" : "ค่าเฉลี่ยความสำเร็จของสายต่อชั่วโมง",
    "cms.indexmonitor.field.inboundCallNum" : "การโทรเข้า",
    "cms.report.field.statInterval" : "ช่วงเวลา",
    "cms.indexmonitor.message.totalCallNum" : "จำนวนการโทรเข้า + จำนวนการโทรออก",
    "cms.uapmonitor.field.socketFault" : "ข้อผิดพลาด",
    "cms.agentMonitor.label.insets_success" : "การแทรกสำเร็จแล้ว",
    "cms.vcallecenter.message.currentacountpasderror" : "รหัสผ่านของบัญชีปัจจุบันไม่ถูกต้อง",
    "cms.indexmonitor.field.skillAvgQueueAck" : "ระยะเวลารอโดยเฉลี่ย",
    "cms.report.message.transferOutCountDesc" : "จำนวนครั้งในการโอนออกด้วยตนเอง",
    "cms.qcrelationconf.field.group" : "กลุ่ม",
    "cms.qcrelationconf.message.queryParamNull" : "ID เจ้าหน้าที่และชื่อกลุ่มผู้ใช้ไม่สามารถเว้นว่างในเวลาเดียวกัน",
    "cms.agentMonitor.label.switch_error" : "การส่งมอบล้มเหลว",
    "cms.report.message.agentServiceRateDesc" : "เปอร์เซ็นต์ของการรับสายโทรเข้าทั้งหมด อัตราความสำเร็จ  = จำนวนของสายที่เจ้าหน้าที่รับ / (จำนวนการโทรเข้า IVR ที่สำเร็จ + จำนวนของเจ้าหน้าที่ที่รับสาย) x 100",
    "cms.report.field.auxTimeField" : "ระยะเวลาพักทั้งหมด",
    "cms.export.field.exportBeginTime" : "เวลาเริ่มต้นการส่งออก",
    "cms.reportsubscribe.field.subscribeName" : "ชื่อผู้ใช้บริการ",
    "cms.report.field.outAvgAnsTimeField" : "ระยะเวลาเสียงเรียกเข้าโดยเฉลี่ยของการโทรออก",
    "cms.callcenterinstanceslist.field.faultStatus" : "ข้อผิดพลาด",
    "cms.developerlist.message.error" : "ผิดพลาด",
    "cms.report.message.IVRServiceRateDesc" : "เปอร์เซ็นต์ของจํานวนการโทรที่ประมวลผลโดย IVR เท่านั้นในจํานวนการโทรขาเข้าทั้งหมด อัตราความสําเร็จของการโทรขาเข้า Pure-IVR = การโทรขาเข้า Pure-IVR ที่ประสบความสําเร็จ / (การโทรเข้า IVR บริสุทธิ์ที่ประสบความสําเร็จ + จํานวนสายที่ตัวแทนรับสาย) x 100",
    "cms.agentMonitor.label.cancelwhisper_error" : "ไม่สามารถยกเลิกเสียงกระซิบได้",
    "cms.qualitycheck.label.cancel" : "ยกเลิก",
    "cms.systemmonitor.label.search" : "ค้นหา",
    "cms.indexmonitor.title.filteringIndicator" : "การบ่งชี้ตัวกรอง",
    "cms.report.field.byMonth" : "รายงานประจำเดือน",
    "cms.report.message.availTimeRateDesc" : "เปอร์เซ็นต์ของระยะเวลาที่ไม่ได้ทำงานทั้งหมดของเจ้าหน้าที่ภายในระยะเวลาการทำงานทั้งหมด",
    "cms.recorddrive.title.recorddrivenameconfig" : "การกำหนดค่าระดับเสียง",
    "cms.report.field.acwOutTime" : "ระยะเวลาที่ใช้สรุปผลการโทรออก",
    "cms.report.title.callCenterTrend" : "แนวโน้มประสิทธิภาพ VDN",
    "cms.qcrelationconf.field.qcGroup" : "กลุ่มตรวจสอบคุณภาพ",
    "cms.datasource.field.yes" : "ใช่",
    "cms.indexmonitor.message.updateSuccess" : "อัปเดตสำเร็จ",
    "cms.report.message.timeRangeCheck" : "ช่วงเวลาต้องไม่เกินหนึ่งวัน",
    "cms.report.field.call3Rate" : "อัตราการเชื่อมต่อการโทรเข้าด้วยตนเองใน 15 วินาที",
    "cms.callcenterinstanceslist.message.kafkaNodeInfo" : "การกําหนดค่าโหนด KAFKA",
    "cms.fullscreen.message.addThemeError" : "ไม่ได้บันทึกผัง คุณต้องการเพิ่มชื่อเรื่องใช่หรือไม่?",
    "cms.agentMonitor.label.been_inspected" : "เอเย่นต์ปัจจุบันกําลังถูกตรวจสอบ โปรดลองอีกครั้งในภายหลัง",
    "cms.agentmonitor.label.cancelfail" : "การยกเลิกสําเร็จ",
    "cms.report.message.noData" : "ไม่มีข้อมูลที่ใช้ได้",
    "cms.indexmonitor.field.skillAbandonAfter10" : "วางสายหลังจาก 10 วินาที",
    "cms.systemmonitor.field.cancel" : "ยกเลิก",
    "cms.monitordetail.field.role" : "บทบาทเจ้าหน้าที่",
    "cms.datasource.label.create" : "เพิ่ม",
    "cms.uapmonitor.label.registererror" : "ล็อกเอาต์ล้มเหลว",
    "cms.indexmonitor.message.skillAbandonAfterNDesc" : "จำนวนคำขอโทรที่ละทิ้งด้วยตนเองหลังจาก Ns ปัจจุบัน Ns อาจเป็น 10 วินาทีหรือ 60 วินาที",
    "cms.voiceinspection.field.search" : "การค้นหาข้อมูล",
    "cms.report.field.systemInSuccField" : "การโทรเข้าสำเร็จ",
    "cms.indexmonitor.field.indicatorName" : "ตัวบ่งชี้",
    "cms.voiceinspection.field.callTime" : "ระยะเวลาการบันทึก",
    "cms.callcenterinstanceslist.field.ccBmsIp" : "ที่อยู่ IP ของ CCBMS LB",
    "cms.report.field.auxCountField" : "เวลาพัก",
    "cms.indexmonitor.message.outboundCallNum" : "จำนวนการโทรออกด้วยตนเอง + จำนวนการโทรออกของ IVR",
    "cms.vcallecenter.message.syncagentfail" : "ซิงโครไนซ์เจ้าหน้าที่ล้มเหลว",
    "cms.developerlist.field.updateTime" : "ปรับเปลี่ยนเมื่อ",
    "cms.report.field.outVdnAvgTimeField" : "ระยะเวลาการโทรขาออกโดยเฉลี่ยใน VDN",
    "cms.callcenterinstanceslist.field.sysAdminPassword" : "รหัสผ่านผู้ดูแลระบบ CTI",
    "cms.calllink.msg.dateTooLate" : "วันที่ที่เลือกไม่สามารถช้ากว่า {0}",
    "cms.indexmonitor.message.skillConnectedDesc" : "จำนวนการโทรเข้าคิวและรับสายโดยเจ้าหน้าที่ในลำดับทักษะหรือการโทรที่โอนไปและตอบโดยเจ้าหน้าที่ในลำดับทักษะอื่น",
    "cms.callcenterinstanceslist.label.search" : "การค้นหาข้อมูล",
    "cms.uapmonitor.field.socketUninstall" : "ยังไม่ได้กําหนดค่า",
    "cms.report.field.callLostOtherNum" : "สายที่สูญหายอื่น ๆ",
    "cms.report.message.threePartyCallCountDesc" : "จำนวนของการโทรสามฝ่าย",
    "cms.voiceinspection.field.queryEndTime" : "หมดเวลา",
    "cms.callcenterinstanceslist.field.ccBcsIp" : "ที่อยู่ IP ของ CCBCS LB",
    "cms.report.field.inAvgCallTime" : "ระยะเวลาการโทรโดยเฉลี่ย",
    "cms.systemparam.error.validate.notmatchrulerequired" : "รายการที่ต้องการ",
    "cms.report.field.outOccupyNumField" : "การโทรออก",
    "cms.agentmonitor.label.agentId" : "ID เจ้าหน้าที่",
    "cms.uapmonitor.message.eidvalidate" : "ป้อนพารามิเตอร์ตามพร้อมท์",
    "cms.voiceinspection.field.serviceId" : "ประเภทธุรกิจ",
    "cms.fullscreen.field.second" : "(วินาที)",
    "cms.report.field.netEntIdField" : "เซิร์ฟเวอร์สื่อ NE ID",
    "cms.report.field.description" : "คำอธิบาย",
    "cms.reportsubscribe.message.searchSubscribeName" : "โปรดป้อนชื่อผู้ใช้บริการ",
    "cms.report.title.ivrOutboundMonitor" : "การตรวจสอบการโทรออก IVR",
    "cms.qualitycheck.message.deleteinfo" : "การลบล้มเหลว",
    "cms.report.message.obsServiceIdDesc" : "ID ของกิจกรรมการโทรออก",
    "cms.agentMonitor.label.forcerelease_success" : "การบังคับปล่อยสายสำเร็จ",
    "cms.indexmonitor.message.skillOnlineWaitCallDesc" : "จำนวนการโทรที่อยู่ในคิว",
    "cms.report.message.outFailNumDesc" : "จำนวนการโทรออกที่ล้มเหลวทั้งหมด",
    "cms.qcrelationconf.field.agentAccountName" : "ชื่อฝ่ายที่ตรวจสอบแล้ว",
    "cms.report.field.failAnsRateField" : "อัตราคำขอที่เสียไป",
    "cms.indexmonitor.field.skillConnectedIn10Rate" : "เชื่อมต่อการโทรใน 10 วินาที",
    "cms.indexmonitor.field.skillConnectedIn20Rate" : "เชื่อมต่อการโทรใน 20 วินาที",
    "cms.calllink.field.pickup" : "รับสายโดยผู้อื่น",
    "cms.sysparam.config.reset.multiple.error" : "ไม่สามารถรีเซ็ตพารามิเตอร์ต่อไปนี้:",
    "cms.report.field.acwInCountField" : "เวลาที่ใช้สรุปผลการโทรเข้า",
    "cms.agentMonitor.label.talking_error" : "เจ้าหน้าที่ที่ตรวจสอบอยู่ในสถานะพูดคุย",
    "cms.callcenterinstanceslist.field.dvDataReport" : "การรายงานข้อมูล DV",
    "cms.indexmonitor.message.inboundConnectNum" : "จำนวนการเชื่อมต่อการโทรเข้าด้วยตนเอง + จำนวนของการโทรเข้า IVR ที่เชื่อมต่อ",
    "cms.calllink.title.beginTime" : "เริ่ม",
    "cms.indexmonitor.label.callCenterNode" : "โหนดศูนย์บริการลูกค้า",
    "cms.report.field.endWeek" : "สิ้นสัปดาห์",
    "cms.agentmonitor.label.adjust" : "สรุปผล",
    "cms.qcrelationconf.field.remark" : "ข้อสังเกต",
    "cms.report.message.custHangNumCtiDesc" : "จำนวนของลูกค้าที่วางสาย",
    "cms.reportsubscribe.field.friday" : "วันศุกร์",
    "cms.indexmonitor.field.ivrOutboundNum" : "การโทรออก IVR ที่เชื่อมต่อ",
    "cms.report.field.avgAgentInCountField" : "จํานวนเฉลี่ยของสายเรียกเข้าของตัวแทนพร้อมกัน",
    "cms.report.field.assistTimeField" : "ระยะเวลาคำขอความช่วยเหลือภายใน",
    "cms.voiceinspection.title.tips" : "ข้อมูล",
    "cms.callcenterinstanceslist.label.nextStep" : "ต่อไป",
    "cms.indexmonitor.message.ivrDurationAvgConnect" : "ระยะเวลาทั้งหมดของการโทร IVR / จำนวนการโทรของ IVR",
    "cms.report.title.vdnTrafficPeriodTrend" : "แนวโน้มการจราจร VDN แบบ Interval-based",
    "cms.developerlist.message.createfailed" : "สร้างนักพัฒนาล้มเหลว",
    "cms.report.field.outCallSuccRate" : "อัตราการเชื่อมต่อการโทรขาออก",
    "cms.report.title.callCenterMonitor" : "การตรวจสอบประสิทธิภาพ VDN",
    "cms.datasource.message.dmUrlTip" : "สตริงการเชื่อมต่อฐานข้อมูล: &8220jdbc:dm://ip:port/dbservicename&#8220&#10; ip (ip address) port (พอร์ต) dbservicename (ชื่อฐานข้อมูล)",
    "cms.callcenterinstanceslist.message.ivrNumsError" : "จำนวนของช่อง IVR ต้องมากกว่าผลรวมของจำนวนช่อง TTS และจำนวนช่อง ASR",
    "cms.qcrelationconf.field.delete" : "ลบ",
    "cms.reportsubscribe.message.reportNumberError" : "เพิ่มรายงานทั้งหมดแล้ว",
    "cms.report.message.maxAgentCountDesc" : "จํานวนสูงสุดของการโทรพร้อมกันที่มีอุปกรณ์บริการเป็นตัวแทน",
    "cms.report.field.selectAgent" : "เลือกตัวแทน",
    "cms.vdnconfiguration.message.updatefailed" : "อัปเดตล้มเหลว",
    "cms.agentmonitor.label.pageRefreshTime" : "อัปเดตเมื่อ",
    "cms.fullscreen.message.publicTheme" : "(สาธารณะ)",
    "cms.indexmonitor.field.confirm" : "ยืนยัน",
    "cms.report.message.occupancyRateDesc" : "อัตราระยะเวลาการทำงาน = (ระยะเวลาการทำงานทั้งหมด – ระยะเวลาพัก)/ระยะเวลาทำงานทั้งหมด x 100",
    "cms.report.message.avgInRingTimeDesc" : "ระยะเวลาการโทรขาเข้าเฉลี่ยผ่านเทอร์มินัลของตัวแทน",
    "cms.report.field.outCallSuccNum" : "การโทรออกที่เชื่อมต่อด้วยตนเอง",
    "cms.sendnote.field.ok" : "ตกลง",
    "cms.report.field.minIVROutCountField" : "จํานวนขั้นต่ําของการโทรออก IVR พร้อมกัน",
    "cms.synchronize.message.syncStatusOne" : "กําลังซิงค์",
    "cms.datasource.message.isDeleteDatasource" : "คุณแน่ใจหรือไม่ว่าคุณต้องการลบแหล่งข้อมูล",
    "cms.fullscreen.message.selectMessage" : "-เลือก-",
    "cms.report.field.systemInBound" : "การโทรเข้า",
    "cms.record-playback.callType0" : "การโทรเข้าด้วยวิดีโอคอล",
    "cms.fullscreen.field.moveup" : "เพิ่มขึ้น",
    "cms.failurecall.placeholder.accessCodeInput" : "ป้อนรหัสการเข้าถึง",
    "cms.developerlist.title.deleteconfim" : "ยืนยัน",
    "cms.callcenterinstanceslist.field.developerDesc" : "คำอธิบาย",
    "cms.voiceinspection.field.callId" : "หมายเลข S/N",
    "cms.agentMonitor.label.qcSipNum" : "การตั้งค่าหมายเลขลงชื่อเข้าใช้ของผู้ตรวจสอบคุณภาพ",
    "cms.reportsubscribe.title.modifyConfim" : "ยืนยันปรับเปลี่ยน",
    "cms.calllink.field.virtualDevice" : "อุปกรณ์เสมือน",
    "cms.report.field.outAvgCallTimeField" : "ระยะเวลาการโทรออกด้วยตนเองโดยเฉลี่ย",
    "cms.report.field.workRateField" : "อัตราระยะเวลาการทำงาน",
    "cms.report.field.avgAcdTimeField" : "ระยะเวลาการโทรด้วยตนเองโดยเฉลี่ย",
    "cms.monitordetail.field.conferencecount" : "การโทรสามฝ่าย",
    "cms.report.field.callInCauseField" : "เหตุผลในการเข้าโทร",
    "cms.tenant.message.tenantspaceOk" : "ตกลง",
    "cms.report.message.callStartTimeDesc" : "เวลาเริ่มต้นการโทร",
    "cms.calllink.field.intercepts" : "การโทรถูกดักฟัง",
    "cms.agentMonitor.label.permission_error" : "คุณไม่มีสิทธิ์นี้",
    "cms.datasource.message.gaussUrlTip" : "สตริงการเชื่อมต่อฐานข้อมูล: &#8220jdbc:zenith:@ip:port&#8220;ip (ที่อยู่ IP) port (หมายเลข Port)",
    "cms.qcrelationconf.field.reset" : "รีเซ็ต",
    "cms.fullscreen.field.no" : "ไม่",
    "cms.indexmonitor.message.SkillAbandonRateDesc" : "อัตราสายหลุด = 100 – อัตราการโทรที่เชื่อมต่อ",
    "cms.report.message.callIdDesc" : "หมายเลขลําดับการโทรซึ่งเป็น ID เฉพาะของการโทร",
    "cms.monitordetail.field.rest" : "พัก",
    "cms.report.field.outInvalidCallField" : "การโทรออกไม่ถูกต้อง",
    "cms.fullscreen.field.richtext" : "รูปแบบ Rich text (ข้อความการจัดรูปแบบทั่วไป)",
    "cms.monitordetail.field.totaltalkingcount" : "จำนวนการโทรทั้งหมด",
    "cms.report.message.callEndDesc" : "เวลาสิ้นสุดการโทร",
    "cms.synchronize.message.configured" : "กำหนดค่า",
    "cms.report.field.transferOutCount" : "จำนวนการโอนออกด้วยตนเอง",
    "cms.agentMonitor.label.updateSip_repeat" : "กำหนดหมายเลขแล้ว",
    "cms.agentmonitor.label.terminalStatusRefresh" : "อัปเดตสถานะ",
    "cms.report.message.waitAnsRateDesc" : "ค่าเฉลี่ยระยะเวลาเสียงเรียกเข้าของเทอร์มินัลของเอเจนต์ ระยะเวลาเสียงกริ่งเฉลี่ยของสายโทรเข้าด้วยตนเอง = ระยะเวลาเสียงกริ่งรวมของสายโทรเข้าด้วยตนเอง/จํานวนครั้งในการรับสาย",
    "cms.recorddrive.field.configSftpAccount" : "บัญชี SFTP",
    "cms.indexmonitor.title.skillDayMonitor" : "สถิติตัวบ่งชี้คิวทักษะวันนี้",
    "cms.report.message.agentInSuccRateDesc" : "เปอร์เซ็นต์ของการโทรเข้าด้วยตนเองที่เชื่อมต่อไปยังการโทรเข้าด้วยตนเองทั้งหมด",
    "cms.report.field.inOccupyNum" : "คำขอบริการ",
    "cms.callcenterinstanceslist.title.chooseFeature" : "การเลือกคุณสมบัติ",
    "cms.report.field.otherTimeField" : "ระยะเวลาทั้งหมดของสถานะอื่น ๆ ",
    "cms.voiceinspection.message.downloadVoiceRecordFailed" : "ไม่สามารถดาวน์โหลดไฟล์การบันทึกจากเซิร์ฟเวอร์ SFTP ได้",
    "cms.agent.message.duplicatepwd" : "รหัสผ่านใหม่เหมือนกับปัจจุบัน",
    "cms.sysparam.config.select.null" : "โปรดเลือกพารามิเตอร์",
    "cms.vcallcenter.message.unknowdesc" : "ไม่ทราบข้อยกเว้น",
    "cms.agentmonitor.label.showAlarmContent" : "เนื้อหาการสนทนา",
    "cms.report.message.utilizationRateDesc" : "อัตราระยะเวลาสถานะการทำงาน = (ระยะเวลาการโทรทั้งหมด + ระยะเวลาที่ใช้สรุปผลการสนทนาทั้งหมด)/(ระยะเวลาการทำงานทั้งหมด – ระยะเวลาพัก) x 100",
    "cms.report.field.outFailNumField" : "การโทรออกที่ล้มเหลวทั้งหมด",
    "cms.report.field.agentService" : "เจ้าหน้าที่รับสายการโทร",
    "cms.report.message.maxQueueWaitTimeDesc" : "ระยะเวลาการรอสายสูงสุด (ระยะเวลาในการรอคิวและเสียงเรียกเข้า) ของการโทร",
    "cms.report.message.calleeNoLength" : "ป้อนรหัสการเข้าถึงอย่างน้อยสามตัวเลข",
    "cms.report.field.ivrtrafficreport.totalivrservice" : "เวลาการให้บริการ IVR ทั้งหมด",
    "cms.report.field.workRate" : "อัตราระยะเวลาการทำงาน",
    "cms.indexmonitor.message.agentConnectedIn3NDesc" : "จํานวนการโทรทั้งหมดที่มีระยะเวลาการรับสายนานกว่าหรือเท่ากับ 3 วินาที",
    "cms.report.title.skillAccessMonitor" : "การตรวจสอบค่าสถิติเกี่ยวกับประสิทธิภาพของลำดับทักษะด้วยรหัสการเข้าถึง",
    "cms.indexmonitor.field.totalAnsweringNum" : "เจ้าหน้าที่อยู่ระหว่างตอบคำถาม",
    "cms.vdnconfiguration.title.error" : "ผิดพลาด",
    "cms.systemmonitor.field.confirm" : "ยืนยัน",
    "cms.systemmonitor.field.ofcNo" : "หมายเลขเข้า",
    "cms.skilladjustment.field.skillname" : "ชชื่อคิวทักษะ",
    "cms.fullscreen.field.themeType" : "ประเภทหัวข้อ",
    "cms.fullscreen.field.delete" : "ลบ",
    "cms.indexmonitor.field.skillAbandonIn30Rate" : "วางสายใน 30 วินาที",
    "cms.report.field.maxAgentInCountField" : "จํานวนสูงสุดของสายเรียกเข้าของเจ้าหน้าที่พร้อมกัน",
    "cms.report.field.submediatype.line" : "LINE",
    "cms.indexmonitor.field.abandonInTen" : "วางสายใน 10 วินาที",
    "cms.fullscreen.field.halfHour" : "30 นาที",
    "cms.datasource.message.updatefailed" : "การอัปเดตล้มเหลว",
    "cms.monitordetail.field.outboundcount" : "จำนวนการโทรออกที่สำเร็จ",
    "cms.report.message.call3RateDesc" : "เปอร์เซ็นต์ของจำนวนคำขอโทรที่เชื่อมต่อด้วยตนเองใน 15 วินาทีของจำนวนการโทรทั้งหมดที่ร้องขอบริการด้วยตนเอง",
    "cms.indexmonitor.field.status" : "สถานะ",
    "cms.record-playback.voice.playing" : "เล่น",
    "cms.record-playback.voice.pause" : "หยุดชั่วคราว",
    "cms.report.message.monthLessThan" : "เดือนสิ้นปีไม่สามารถเร็วกว่าเดือนเริ่มต้นปี",
    "cms.agentMonitor.label.listen_success" : "การฟังสำเร็จ",
    "cms.indexmonitor.field.totalRestNum" : "เจ้าหน้าที่อยู่ในสถานะพัก",
    "cms.fullscreen.field.nowMonitor" : "การตรวจสอบระหว่างการทำงานแบบเรียลไทม์",
    "cms.indexmonitor.field.agentOutboundNum" : "การโทรออกด้วยตนเอง",
    "cms.indexmonitor.message.callOutSuccNum" : "จํานวนครั้งที่ตัวแทนเชื่อมต่อสายโทรออก",
    "cms.title.select" : "เลือก",
    "cms.report.field.avgAcwTimeField" : "ระยะเวลาที่ใช้สรุปผลการสนทนาโดยเฉลี่ย",
    "cms.report.field.outWaitAnsTimeField" : "ระยะเวลาเสียงเรียกเข้าทั้งหมดของการโทรออก",
    "cms.callcenterinstanceslist.message.nodeIdCheckError" : "ตรวจสอบให้แน่ใจว่า ID ข้อมูลโหนด CTI ที่ป้อน, uapId และชื่อไม่ซ้ำกัน",
    "cms.export.field.selectFileType" : "เลือกรูปแบบแฟ้มส่งออก",
    "cms.export.message.popwindowmessage" : "โปรดป้อนรหัสผ่านที่ใช้สร้างแฟ้มบีบอัด รหัสผ่านที่บีบอัดควรประกอบด้วย 8-12 อักขระ, ประกอบด้วยตัวอักษร, ตัวเลข, อักขระพิเศษ , อักขระพิเศษไม่รวม {'|;&$-<>/'}{'\\\\'}{'`!#(){}'} ช่องว่าง, อักขระและแท็บบรรทัดใหม่",
    "cms.agentmonitor.label.autoRefresh" : "รีเฟรชอัตโนมัติ",
    "cms.report.field.minAgentOutCount" : "จํานวนขั้นต่ําของการโทรออกพร้อมกันของเอเจนต์",
    "cms.indexmonitor.field.agentConnectInTen" : "โทรเชื่อมต่อด้วยตนเองใน 10 วินาที",
    "cms.report.field.callEndField" : "เวลาสิ้นสุดการโทร",
    "cms.monitordetail.field.noanswercount" : "จำนวนการโทรไม่ได้รับการตอบรับเป็นเวลานาน",
    "cms.qcrelationconf.message.selectAgent" : "โปรดเลือกเจ้าหน้าที่ที่จะลบ",
  "cms.qcrelationconf.message.selectGroup": "โปรดเลือกกลุ่มผู้ใช้ที่จะลบ",
    "cms.report.message.uvidDesc" : "รหัสที่ไม่ซ้ํากันทั่วโลกที่จัดสรรโดย CCS ให้กับสมาชิกแต่ละคนที่เข้าถึงศูนย์บริการข้อมูล",
    "cms.systemmonitor.field.selectAgentName" : "เลือกผู้เช่า",
    "cms.report.message.muteTimeDesc" : "ระยะเวลาเงียบ",
    "cms.report.field.userLevel" : "ระดับผู้ใช้",
    "cms.report.title.vdnSystemPerformanceMonitor" : "การตรวจสอบประสิทธิภาพของระบบ",
    "cms.indexmonitor.label.noncommercial" : "การทดลองใช้งานเชิงพาณิชย์",
    "cms.indexmonitor.field.totalAbandonedCallNum" : "สายหลุดทั้งหมด",
    "cms.agent.message.modifypasswordfailed" : "เปลี่ยนแปลงรหัสผ่านล้มเหลว",
    "cms.agentmonitor.label.agentStatus" : "สถานะของเจ้าหน้าที่",
    "cms.uapmonitor.field.callIpPort" : "ที่อยู่ IP และหมายเลข Port",
    "cms.report.message.outCallSuccNumDesc" : "จำนวนการเชื่อมต่อการโทรออกด้วยตนเอง",
    "cms.report.field.acdCalls" : "การโทรที่เชื่อมต่อทั้งหมด",
    "cms.calllink.field.ivrServiceProcess" : "โฟลว์ธุรกิจ IVR",
    "cms.report.field.perAgentNumField" : "สายของเจ้าหน้าที่",
    "cms.indexmonitor.message.ivrInboundFlowOut" : "จำนวนการโทรที่โอนไปยังอุปกรณ์อื่นหลังจาก IVR เล่นคำประกาศ",
    "cms.sysparam.config.mod" : "แก้ไข",
    "cms.calllink.field.routingTemporaryDevice" : "อุปกรณ์กำหนดเส้นทางชั่วคราว",
    "cms.report.field.exceptionHangUp" : "ข้อยกเว้น วางสาย",
    "cms.export.field.zippwd" : "รหัสผ่านที่บีบอัด",
    "cms.qualitycheck.message.error_errorinfo" : "บันทึกล้มเหลว",
    "cms.synchronize.message.confPwdStatus" : "การกำหนดค่ารหัสผ่านอินเตอร์เฟซ",
    "cms.vcallecenter.title.modifyconfigurationinterfacepassword" : "การกําหนดค่ารหัสผ่านอินเตอร์เฟซ",
    "cms.report.title.skillAllReport" : "รายงานสรุปคิวทักษะ",
    "cms.report.field.outOccupyNumDesc" : "จํานวนครั้งที่ตัวแทนเชื่อมต่อสายโทรออก",
    "cms.report.field.agentServiceRateField" : "อัตราความสำเร็จด้วยตนเองในการโทรเข้าทั้งหมด",
    "cms.report.title.IVRAccessTrend" : "แนวโน้มข้อมูล IVR ด้วยรหัสการเข้าถึง",
    "cms.indexmonitor.message.ivrInCallCount" : "จำนวนการโทรเข้าที่มีอุปกรณ์บริการปัจจุบันเป็น IVR",
    "cms.report.field.avgAgentOutCount" : "จํานวนเฉลี่ยของการโทรออกพร้อมกันของเอเจนต์",
    "cms.indexmonitor.field.allType" : "ทุกประเภท",
    "cms.monitordetail.field.restduration" : "ระยะเวลาพัก",
    "cms.indexmonitor.message.skillAgentAbandonDesc" : "จำนวนการโทรเชิงรุกโดยเจ้าหน้าที่วางสายระหว่างเสียงเรียกเข้า",
    "cms.report.field.minAgentCountField" : "จํานวนขั้นต่ําของการโทรของเอเจนต์พร้อมกัน",
    "cms.report.field.outWaitAnsTime" : "ระยะเวลาเสียงเรียกเข้าทั้งหมดของการโทรออก",
    "cms.indexmonitor.field.skillAvgConnectedAck" : "ระยะเวลาเสียงเรียกเข้าโดยเฉลี่ยของสายที่เชื่อมต่อ",
    "cms.report.field.talkingTimeField" : "เวลาในการพูดคุย",
    "cms.indexmonitor.message.skillSystemLostDesc" : "จำนวนการหยุดของแพลตฟอร์มที่เกิดขึ้นจากสาเหตุต่าง ๆ เช่น การตอบรับของเจ้าหน้าที่หมดเวลา",
    "cms.agentMonitor.label.updateSip_error" : "เขียนหมายเลขไม่ถูกต้อง",
    "cms.report.field.avgAnsTime" : "ระยะเวลาเข้าคิวโดยเฉลี่ย",
    "cms.voiceinspection.message.frequentExport" : "ส่งออกบ่อยเกินไป โปรดลองอีกครั้งในภายหลัง",
    "cms.monitordetail.field.information" : "ข้อมูลพื้นฐาน",
    "cms.report.field.logoutCountField" : "เวลาลงชื่อออก",
    "cms.export.message.exceedNumberLimit" : "ปริมาณข้อมูลที่มากเกินไป จํากัดช่วงเวลาข้อมูลให้แคบลงหรือเพิ่มเกณฑ์การกรองข้อมูล",
    "cms.report.field.logoutCount" : "เวลาลงชื่อออก",
    "cms.report.field.userRefusedFailNumField" : "สายที่หายไปเนื่องจากการปฏิเสธการโทร",
    "cms.vcallecenter.message.syncSuccess" : "ซิงโครไนซ์ศูนย์บริการเสมือนจริงสำเร็จ",
    "cms.report.field.inCallNumField" : "สายเข้า",
    "cms.report.message.callSuccRateDesc" : "เปอร์เซ็นต์ของจำนวนการร้องขอการโทรเข้าด้วยตนเองที่เชื่อมต่อในจำนวนการโทรเข้าด้วยตนเองที่ร้องขอบริการ",
    "cms.report.field.agentServiceField" : "เจ้าหน้าที่รับสายการโทร",
    "cms.callcenter.message.sameletterovermaxnum" : "สามารถเพิ่มบันทึกได้สูงสุดแปดบันทึกลงในไดรฟ์ที่ใช้ตัวอักษรเดียวกัน",
    "cms.monitordetail.field.detail" : "รายละเอียดเจ้าหน้าที่",
    "cms.report.field.sysLostCallsField" : "การโทรเข้าล้มเหลว",
    "cms.qualitycheck.label.viewusergroup" : "ดูกลุ่มผู้ใช้",
    "cms.report.field.avgAgentCount" : "จํานวนเฉลี่ยของการโทรของเอเจนต์ที่เกิดขึ้นพร้อมกัน",
    "cms.monitordetail.field.holdduration" : "ระยะเวลาถือสายรอ",
    "cms.indexmonitor.field.skillTimeoutAgent" : "หมดเวลาเรียกเจ้าหน้าที่",
    "cms.report.message.acwInCountDesc" : "จำนวนครั้งที่เจ้าหน้าที่เข้าสู่สถานะที่ใช้สรุปผลการสนทนาหลังจากการโทรเข้าสิ้นสุดลง",
    "cms.qcrelationconf.message.isDeleteGroupInfo" : "โปรดยืนยันว่าจะลบกลุ่มผู้ใช้หรือไม่",
    "cms.calllink.title.logDate" : "เวลาสถิติ",
    "cms.voiceinspection.field.ordinaryVoiceCall" : "เสียง",
    "cms.calllink.msg.callerPlaceHolder" : "ป้อนเลขหมายโทรศัพท์",
    "cms.fullscreen.field.avgCallInTime" : "ระยะเวลาเฉลี่ยของสายเรียกเข้าด้วยตนเอง",
    "cms.datasource.field.test" : "การเชื่อมต่อทดสอบ",
    "cms.report.title.IVRAccessReport" : "รายงานข้อมูล IVR ตามรหัสการเข้าถึง",
    "cms.developerlist.message.checkInput" : "ค่าไม่สามารถมีอักขระพิเศษ",
    "cms.calllink.field.skillQueue" : "ลำดับทักษะ",
    "cms.callcenterinstanceslist.message.createFailed" : "สร้างศูนย์บริการลูกค้าล้มเหลว",
    "cms.fullscreen.field.setIndicatorThreshold" : "ตั้งค่าเกณฑ์ตัวชี้วัด",
    "cms.failurecall.field.queueName" : "ชื่อคิว",
    "cms.report.message.workRateDesc" : "อัตราระยะเวลาการทำงาน = (ระยะเวลาการทำงานทั้งหมด – ระยะเวลาพัก) / ระยะเวลาทำงานทั้งหมด x 100",
    "cms.calldetail.msg.timeTooLate" : "เวลาของการคัดเลือกจะต้องไม่ช้ากว่า {0}",
    "cms.report.message.selectAgent" : "เลือก ID ของตัวแทนอย่างน้อยหนึ่งคน",
    "cms.report.field.startYear" : "เริ่มต้นปี",
    "cms.report.field.userRefusedFailNum" : "สายที่หายไปเนื่องจากการปฏิเสธการโทร",
    "cms.indexmonitor.title.agentDayMonitor" : "สถิติวันนี้เกี่ยวกับตัวบ่งชี้ตัวแทน",
    "cms.vcallecenter.message.validatefailed" : "ล้มเหลวในการทวนสอบรหัสผ่าน",
    "cms.recorddrive.label.button_add" : "ใหม่",
    "cms.agentMonitor.label.forceidle_repeat" : "เจ้าหน้าที่ไม่ว่าง กรุณาอย่าพูดซ้ํา",
    "cms.indexmonitor.field.skillSystemLost" : "การหยุดแพลตฟอร์ม",
    "cms.callcenter.field.savefailed" : "ผิดพลาด",
    "cms.fullscreen.field.auxCountTimeDesc" : "จํานวนครั้งที่เจ้าหน้าที่อยู่ในสถานะที่เหลือ",
    "cms.callcenter.message.selectccgatewayservers" : "เลือกเซิร์ฟเวอร์ CC-เกตเวย์",
    "cms.report.message.acdTimeRateDesc" : "อัตราระยะเวลาการโทรด้วยตนเอง = ระยะเวลาการโทรด้วยตนเอง / ระยะเวลาการทำงานทั้งหมด x 100",
    "cms.qcrelationconf.title.confirm" : "ยืนยัน",
    "cms.report.message.startTimeLessThanEndTime" : "เวลาเริ่มต้นต้องไม่เกินเวลาสิ้นสุด",
    "cms.report.message.inCallNumDesc" : "จํานวนรวมของสายเรียกเข้าใน VDN ค่านี้คือผลรวมของการโทรเข้าด้วยตนเองและการโทรเข้าโดยอัตโนมัติโดยใช้ IVR",
    "cms.synchronize.message.invalidConfPwd" : "รหัสผ่านไม่ถูกต้อง เปลี่ยนการกำหนดค่ารหัสผ่านอินเตอร์เฟซ",
    "cms.mediaType9" : "กระดานอิเล็กทรอนิกส์",
    "cms.mediaType8" : "IP video call (H.323)",
    "cms.fullscreen.message.addTheme" : "เพิ่มชื่อเรื่อง",
    "cms.mediaType7" : "โทรแฟกซ์",
    "cms.mediaType6" : "โทรอีเมล",
    "cms.datasource.message.mysql5UrlTip" : "สตริงการเชื่อมต่อฐานข้อมูล: &#8220jdbc:mysql://ip:port/schema&#8220&#10;ip (ที่อยู่ IP) port (หมายเลข Port) schema (แผนผัง)",
    "cms.callcenterinstanceslist.field.uapUserName" : "ชื่อผู้ใช้ UAP CDE",
    "cms.indexmonitor.message.skillAbandonDesc" : "จำนวนคำขอโทรเข้าด้วยตนเองหายไป = จำนวนคำขอโทรเข้าด้วยตนเอง – จำนวนคำขอการโทรเข้าด้วยตนเองที่เชื่อมต่ออยู่",
    "cms.mediaType5" : "การใช้เสียงสนทนาทั่วไป",
    "cms.sysparam.config.select.prompt" : "คําใบ้",
    "cms.mediaType4" : "โทรกลับการโทร",
    "cms.mediaType3" : "ติดตามเรียกดูและแบ่งปันแบบฟอร์ม",
    "cms.indexmonitor.field.inboundConnectNum" : "เชื่อมต่อการโทรเข้า",
    "cms.mediaType2" : "คลิกเพื่อโทร",
    "cms.indexmonitor.field.skillQueueDisconnected" : "คิวการโทรที่ตัดการเชื่อมต่อ",
    "cms.mediaType1" : "แชทข้อความ",
    "cms.monitordetail.field.busydurationavg" : "ระยะเวลาที่ไม่ว่างโดยเฉลี่ย",
    "cms.interfaceperflog.message.modifyFail" : "การแก้ไขไม่สำเร็จ",
    "cms.report.field.otherTimeRate" : "อัตราระยะเวลาอื่น",
    "cms.vcallecenter.field.enternewpassword" : "ป้อนรหัสผ่านใหม่",
    "cms.report.field.unspecifiedSkillId" : "ยังไม่ระบุ",
    "cms.report.field.callLostOtherNumField" : "สายที่หายไปอื่นๆ",
    "cms.calllind.field.deviceQueue" : "ลำดับทักษะ",
    "cms.report.field.IVRCallOutSuccNumField" : "การโทรขาออก IVR ที่เชื่อมต่อ",
    "cms.report.field.abortQueueWaitNum" : "สายที่ไม่ได้รับการบริการ",
    "cms.indexmonitor.message.warningFilterMax" : "คุณสามารถเลือกตัวบ่งชี้สูงสุด {0}",
    "cms.report.field.busyTime" : "ระยะเวลาไม่ว่างทั้งหมด",
    "cms.callcenterinstanceslist.field.gwIp" : "CC-IP เกตเวย์",
    "cms.report.field.IVROutSuccRate" : "Pure-IVR อัตราการเชื่อมต่อการโทรขาออก",
    "cms.callcenterinstanceslist.field.kafkaPasswordConfirm" : "ยืนยันรหัสผ่าน KAFKA",
    "cms.callcenterinstanceslist.message.specialStrValidateFailed" : "ชื่อศูนย์บริการลูกค้าไม่สามารถมีอักขระพิเศษ",
    "cms.callcenterinstanceslist.message.jobSwitch" : "เปิดใช้งานการซิงโครไนซ์อัตโนมัติ",
    "cms.report.message.outCallSuccRateDesc" : "เปอร์เซ็นต์ของสายโทรออกที่เชื่อมต่อไปยังสายโทรออกทั้งหมด",
    "cms.indexmonitor.field.durationAvgVdnInbound" : "ระยะเวลาการโทรเข้าโดยเฉลี่ย",
    "cms.developer.message.modifyDeveloper" : "อัปเดตข้อมูลนักพัฒนา",
    "cms.report.message.IVRInSuccRateDesc" : "เปอร์เซ็นต์ของสายขาเข้า IVR ที่เชื่อมต่อไปยังสายขาเข้า IVR ทั้งหมด",
    "cms.reportsubscribe.field.sentPeriod" : "ช่วงเวลาส่ง",
    "cms.report.field.minAgentInCount" : "จํานวนขั้นต่ําของสายเรียกเข้าพร้อมกันของตัวแทน",
    "cms.systemmonitor.field.systemInSucc" : "สายที่เชื่อมต่อกับระบบ",
    "cms.report.field.avgIVRServiceField" : "เวลาให้บริการ IVR โดยเฉลี่ย",
    "cms.report.field.inCallSuccNumberField" : "สายขาเข้าที่เชื่อมต่อแล้ว",
    "cms.record-playback.callType1" : "การโทรออกด้วยเสียงสนทนา",
    "cms.indexmonitor.field.skillConnected" : "คำขอเชื่อมต่อการโทร",
    "cms.report.message.noAckNumDesc" : "จำนวนสายที่ไม่ได้รับหลังจากจัดสรรให้กับเจ้าหน้าที่",
    "cms.fullscreen.title.editChart" : "ปรับเปลี่ยนแผนภูมิ",
    "cms.report.field.auxTime" : "ระยะเวลาพักทั้งหมด",
    "cms.monitordetail.field.outerforward" : "จำนวนการโทรที่โอนจากภายนอก",
    "cms.reportsubscribe.field.reportName" : "ชื่อรายงาน",
    "cms.report.field.tableHeader" : "หัวตาราง",
    "cms.systemparam.error.validate.inner" : "ตรวจสอบพารามิเตอร์ล้มเหลว",
    "cms.indexmonitor.message.queryCallCenterError" : "ล้มเหลวในการสอบถามศูนย์การโทรที่เลือก",
    "cms.fullscreen.field.IVR" : "IVR",
    "cms.report.field.IVRCallOutSuccRateField" : "อัตราการเชื่อมต่อการโทรขาออก IVR",
    "cms.report.message.busyTimeDesc" : "ระยะเวลาทั้งหมดเมื่อเจ้าหน้าที่อยู่ในสถานะไม่ว่าง",
    "cms.datasource.message.addfailed" : "การเพิ่มล้มเหลว.",
    "cms.indexmonitor.message.outboundConnectRate" : "เปอร์เซ็นต์ของจํานวนสายโทรออกที่เชื่อมต่อในจํานวนสายโทรออก",
    "cms.indexmonitor.message.outboundAbandonRate": "เปอร์เซ็นต์ของจำนวนการโทรออกที่ไม่ได้เชื่อมต่อกับจำนวนการโทรออก หากจำนวนการโทรออกเป็น 0 ค่าตัวบ่งชี้จะเป็น 0",
    "cms.indexmonitor.message.skillQueueDisconnectedDesc" : "จำนวนครั้งที่การโทรถูกตัดการเชื่อมต่อโดยแพลตฟอร์ม CTI เมื่อสายกำลังรอคิว",
    "cms.report.message.otherHangNumCtiDesc" : "จำนวนที่ระบบวางสาย",
    "cms.report.field.avgIVROutCountField" : "จํานวนเฉลี่ยของการโทรออก IVR พร้อมกัน",
    "cms.reportsubscribe.field.selectedReport" : "รายงานที่เลือก",
    "cms.report.message.lessThanError" : "ID เจ้าหน้าที่ปลายทางต้องไม่น้อยกว่า ID เจ้าหน้าที่เริ่มต้น",
    "cms.report.message.holdTimeDesc" : "ระยะเวลาทั้งหมดเมื่อเจ้าหน้าที่อยู่ในสถานะพักสาย ระยะเวลาการโทรที่เกิดขึ้นรวมถึงระยะเวลาเมื่อเจ้าหน้าที่อยู่ในสถานะพักสาย",
    "cms.report.field.agentOffered" : "การโทรเข้าด้วยตนเอง",
    "cms.voiceinspection.field.beginTime" : "เวลาเริ่มการบันทึก",
    "cms.report.title.skillReport" : "รายงานประสิทธิภาพของคิวทักษะ",
    "cms.report.field.occupancyRateField" : "อัตราระยะเวลาการทำงาน",
    "cms.export.message.downloadFailed" : "ล้มเหลวในการดาวน์โหลดรายงาน",
    "cms.indexmonitor.message.agentAbandonIn30NDesc" : "จํานวนการละทิ้งทั้งหมดที่มีระยะเวลาคําตอบนานกว่าหรือเท่ากับ 30 วินาที",
    "cms.report.message.overNumReason" : "เอเจนต์มัลติมีเดียและเอเจนต์รอบด้านสามารถประมวลผลการโทรหลายช่องทางในเวลาเดียวกัน",
    "cms.recorddrive.field.sftpPort" : "หมายเลขพอร์ต",
    "cms.callcenterinstanceslist.message.odfConfigError" : "ข้อมูลการตั้งค่าบริการ ODFS ไม่ถูกต้อง",
    "cms.uapmonitor.labe.mentiontitle" : "หมายเหตุ:",
    "cms.callcenterinstanceslist.message.syncPassValidateFailed" : "ค่าจะต้องประกอบด้วยตัวอักษรตัวสูง ตัวอักษรตัวเล็ก ตัวเลขและอักขระพิเศษ `~!@#$%^&*()-_=+|[{}];: '/, <. มีสามและช่องว่างใดๆ",
    "cms.callcenterinstanceslist.field.developer" : "ผู้พัฒนาที่เกี่ยวข้อง",
    "cms.callcenterinstanceslist.message.passValidateContinuityFailed" : "รหัสผ่านไม่สามารถมีอักขระที่เหมือนกันสามตัวติดต่อกัน",
    "cms.report.field.acwInCount" : "เวลาที่ใช้สรุปผลการโทรเข้า",
    "cms.qualitycheck.label.qcRelationConfig" : "ความสัมพันธ์ในการตรวจสอบ",
    "cms.calllink.field.timeoutTransfer" : "การโทรถูกโอนเนื่องจากหมดเวลา",
    "cms.systemparam.success.reset" : "รีเซ็ตพารามิเตอร์สําเร็จ",
    "cms.indexmonitor.message.skillAvgConnectedAckDesc" : "ระยะเวลาเสียงเรียกเข้าโดยเฉลี่ยของการโทรที่เชื่อมต่อทั้งหมดในคิว",
    "cms.skilladjustment.message.tipsSuccess" : "ปรับแต่งลำดับทักษะสำเร็จแล้ว",
    "cms.report.title.agentOperationReport" : "รายงานการดำเนินการเชื่อมต่อเจ้าหน้าที่",
    "cms.callcenterinstanceslist.field.kafkaPort" : "พอร์ต KAFKA",
    "cms.agentmonitor.label.cancellisteningOrInsert" : "ยกเลิกการฟังหรือแทรก",
    "cms.report.field.acdTime" : "ระยะเวลาการโทรด้วยตนเอง",
    "cms.report.message.stateDesc" : "สถานะการเชื่อมต่อรวมถึงการเชื่อมต่อและไม่เชื่อมต่อ",
    "cms.indexmonitor.message.agentConnectedIn30NDesc" : "จํานวนการโทรทั้งหมดที่มีระยะเวลาการรับสายนานกว่าหรือเท่ากับ 30 วินาที",
    "cms.fullscreen.field.fontSize" : "ขนาด",
    "cms.indexmonitor.field.skillOnlineConnected" : "จำนวนการตอบกลับการโทรโดยเจ้าหน้าที่ในช่วง 5 นาทีล่าสุด",
    "cms.agentmonitor.label.readjustmentState" : "กำลังปรับแต่ง",
    "cms.indexmonitor.field.agentBoundAbandonRate" : "การทิ้งสายการโทรเข้าด้วยตนเอง",
    "cms.report.field.ans20sField" : "อัตราการเชื่อมต่อการโทรด้วยตนเองใน20 วินาที",
    "cms.indexmonitor.field.durationAvgConnect" : "ระยะเวลาการโทรโดยเฉลี่ย",
    "cms.agentMonitor.label.talkingerror" : "",
    "cms.callcenter.field.finish" : "เสร็จสมบูรณ์",
    "cms.report.field.inWaitAnsNum" : "สายเรียกเข้าด้วยตนเอง",
    "cms.report.field.outCallSuccNumField" : "การเชื่อมต่อการโทรออกด้วยตนเอง",
    "cms.report.message.outWaitAnsTimeDesc" : "ระยะเวลาเสียงเรียกเข้าทั้งหมดของการโทรออก",
    "cms.report.field.userNoAnswerNumField" : "สายหายเนื่องจากหมดเวลาของเสียงเรียกเข้า",
    "cms.report.field.outCallSuccNumberField" : "การโทรขาออกที่เชื่อมต่อแล้ว",
    "cms.report.field.sysLostCalls" : "การโทรเข้าล้มเหลว",
    "cms.qcrelationconf.placeholder.groupType" : "โปรดเลือก",
    "cms.report.field.hangUpModeField" : "วิธีการแขวนขึ้น",
    "cms.report.field.otherTime" : "ระยะเวลาทั้งหมดของสถานะอื่น ๆ",
    "cms.systemmonitor.label.CommercialUse" : "การใช้งานเชิงพาณิชย์",
    "cms.indexmonitor.message.updateFailed" : "อัปเดตล้มเหลว",
    "cms.report.field.acwInTime" : "ระยะเวลาที่ใช้สรุปผลการโทรเข้า",
    "cms.fullscreen.message.warningSelectOne" : "เลือกตัวบ่งชี้เพียงตัวเดียวสําหรับการตั้งค่าขีด จํากัด",
    "cms.callcenterinstanceslist.message.uapNodeInfo" : "การกําหนดค่าโหนด UAP",
    "cms.calllink.field.threePartyHelp" : "การโทรใช้เพื่อขอความช่วยเหลือจากสามฝ่าย",
    "cms.voiceinspection.field.agentId" : "เจ้าหน้าที่",
    "cms.calllink.field.MELCASDevice" : "อุปกรณ์ MELCAS",
    "cms.calllink.field.businessRepresentative" : "เจ้าหน้าที่",
    "cms.report.field.availTime" : "ระยะเวลาที่ไม่ได้ทำงาน",
    "cms.report.message.deviceNoDesc" : "จํานวนอุปกรณ์ที่ประมวลผลการโทร",
    "cms.calllink.title.callId" : "ID การโทร",
    "cms.report.title.agentOperationTrend" : "แนวโน้มรายละเอียดการปฏิบัติการการเชื่อมต่อของเจ้าหน้าที่",
    "cms.indexmonitor.field.skillConnectedIn30Rate" : "เชื่อมต่อการโทรใน 30 วินาที",
    "cms.agentmonitor.label.agent" : "เอเย่นต์",
    "cms.vcallecenter.field.enterwaspassword" : "รหัสผ่าน",
    "cms.indexmonitor.message.agentOutboundNum" : "จํานวนการโทรออกด้วยตนเอง",
    "cms.report.message.uapIdDesc" : "ID ของ UAP ที่เชื่อมต่อการโทรไปยังแพลตฟอร์มศูนย์บริการ",
    "cms.agentMonitor.label.cancelwhisper_success" : "ยกเลิกเสียงกระซิบเรียบร้อยแล้ว",
    "cms.report.field.outCallSuccNumber" : "การโทรขาออกที่เชื่อมต่อ",
    "cms.reportsubscribe.field.publish" : "ปล่อย",
    "cms.fullscreen.field.play" : "เล่น",
    "cms.report.field.date" : "วันที่",
    "cms.report.message.holdCountDesc" : "จำนวนครั้งที่ถือสายรอ",
    "cms.skilladjustment.message.tipsNotMonitor" : "ไม่ใช่ผู้เชี่ยวชาญ QA การปรับแต่งลำดับทักษะล้มเหลว",
    "cms.calldetail.msg.callIdInvalid" : "รูปแบบไม่ถูกต้อง",
    "cms.report.message.avgQueueOutCountDesc" : "จํานวนเฉลี่ยของการโทรออกพร้อมกันที่มีอุปกรณ์บริการเป็นคิวทักษะ",
    "cms.report.field.succAnsRateField" : "อัตราการเชื่อมต่อ",
    "cms.tenant.message.adminpassword" : "รหัสผ่าน",
    "cms.voiceinspection.field.rate" : "อัตราการเล่น",
    "cms.agentmonitor.label.agentName" : "ชื่อเจ้าหน้าที่",
    "cms.fullscreen.field.oneHour" : "1 ชั่วโมง",
    "cms.report.field.occupancyRate" : "อัตราระยะเวลาการทำงาน",
    "cms.report.field.totalLIVRServiceField" : "เวลาการให้บริการ IVR ทั้งหมด",
    "cms.report.message.acwOutTimeDesc" : "ระยะเวลาที่ใช้สรุปผลการสนทนาหลังจากการโทรออกสิ้นสุดลง",
    "cms.report.field.avgIVRCount" : "จํานวนเฉลี่ยของการโทร IVR พร้อมกัน",
    "cms.report.field.custHangNumCti" : "ลูกค้าวางสาย",
    "cms.callcenterinstanceslist.field.nodemodepool" : "โหมดแหล่งรวม",
    "cms.report.field.month" : "เดือน",
    "cms.report.field.IVRSuccRate" : "อัตราการเชื่อมต่อการโทรเข้า IVR",
    "cms.report.field.inCallFailNumField" : "คำขอสายที่หลุดไป",
    "cms.indexmonitor.message.skillMinQueueAckDesc" : "ระยะเวลาการรอสายต่ำสุด (ระยะเวลาในการรอคิวและเสียงเรียกเข้า) ของการโทร",
    "cms.agentMonitor.message.operaFailed" : "การดําเนินการล้มเหลว กําลังดําเนินการ QC หรือสายเรียกเข้า",
    "cms.report.field.outCallRateField" : "อัตราการเชื่อมต่อการโทรออก",
    "cms.indexmonitor.message.skillOverQueueDesc" : "จำนวนการโทรถูกโอนจากลำดับทักษะเดิมไปยังลำดับทักษะอื่นเนื่องจาก overflow",
    "cms.report.message.IVROutSuccNumDesc" : "จํานวนการโทรขาออก IVR ที่เชื่อมต่อซึ่งมีระยะเวลาการครอบครองอุปกรณ์ IVR มากกว่า 0",
    "cms.indexmonitor.message.abandonInSixty" : "เปอร์เซ็นต์ของจํานวนสายที่ถูกทิ้งภายใน 60 วินาทีของจํานวนสายที่ถูกทิ้งทั้งหมด",
    "cms.indexmonitor.field.ivrInboundFlowOut" : "โอนสายเรียกเข้า IVR",
    "cms.report.message.call6RateDesc" : "เปอร์เซ็นต์ของจำนวนคำขอโทรที่เชื่อมต่อด้วยตนเองใน 30 วินาทีของจำนวนการโทรทั้งหมดที่ร้องขอบริการด้วยตนเอง",
    "cms.agentMonitor.label.intercept_success" : "ประสบความสําเร็จในการสกัดกั้น",
    "cms.indexmonitor.message.ivrOutCallCount" : "จำนวนการโทรออกที่มีอุปกรณ์บริการปัจจุบันเป็น IVR",
    "cms.report.title.skillAllTrend" : "แนวโน้มสรุปผลลำดับทักษะ",
    "cms.callcenterinstanceslist.message.ccNameUpdateError" : "อย่าเปลี่ยนชื่อศูนย์บริการลูกค้าเมื่อกำลังแก้ไขศูนย์บริการลูกค้า",
    "cms.report.field.callAbandonedInRingField" : "วางสายขณะมีเสียงเรียกเข้า",
    "cms.report.message.IVRSuccRateDesc" : "เปอร์เซ็นต์ของจำนวนการโทรเข้า IVR ที่เชื่อมต่อในจำนวนคำขอโทรเข้าระบบ IVR",
    "cms.report.title.agentReport" : "รายงานประสิทธิภาพของเจ้าหน้าที่",
    "cms.callcenterinstanceslist.field.ccId" : "ID ศูนย์บริการลูกค้า",
    "cms.report.message.failAvgTimeDesc" : "ระยะเวลาเสียงเรียกเข้าโดยเฉลี่ยของสายที่ไม่ได้รับทั้งหมด",
    "cms.export.field.operation" : "ดำเนินการ",
    "cms.indexmonitor.field.agentInCallCount" : "จำนวนการโทรเข้าของเจ้าหน้าที่ที่ออนไลน์",
    "cms.indexmonitor.message.durationAvgConnect" : "ระยะเวลาการโทรที่เชื่อมต่อกันทั้งหมด / จำนวนการโทรที่เชื่อมต่อทั้งหมด",
    "cms.fullscreen.field.bar" : "แผนภูมิแท่ง",
    "cms.qualitycheck.field.groupName" : "กลุ่มผู้ใช้",
    "cms.agentmonitor.label.switch" : "สลับ",
    "cms.agentMonitor.label.nosignin_error" : "เจ้าหน้าที่ที่ตรวจสอบยังไม่ได้ลงชื่อเข้าใช้",
    "cms.report.field.calledHangUp" : "เรียกว่าวางสาย",
    "cms.report.message.otherTimeRateDesc" : "อัตราระยะเวลาอื่น = (ระยะเวลาทั้งหมดของสถานะอื่น ๆ / ระยะเวลาการทำงานทั้งหมด) x 100",
    "cms.recorddrive.placeholder.batchdeleteconfim" : "ต้องการลบ drive letters ที่เลือกไว้ทั้งหมดใช่หรือไม่?",
    "cms.callcenterinstanceslist.title.callcenter" : "ศูนย์บริการลูกค้า",
    "cms.report.message.acwCountDesc" : "จำนวนครั้งที่เจ้าหน้าที่เข้าสู่สถานะที่ใช้สรุปผลการสนทนา",
    "cms.qualitycheck.placeholder.userGroupName" : "ป้อนกลุ่มผู้ใช้",
    "cms.callcenterinstanceslist.label.callCenterRecordDrive" : "การบันทึก Drive Letter",
    "cms.datasource.message.masterSalveRelationExist" : "แหล่งข้อมูลถูกใช้เป็นแหล่งข้อมูลสํารองอยู่แล้ว",
    "cms.synchronize.message.syncStatusThree" : "การซิงค์ล้มเหลว",
    "cms.fullscreen.field.maxThreshold" : "เกณฑ์สูงสุด",
    "cms.indexmonitor.message.totalLoginNum" : "จำนวนเจ้าหน้าที่ที่เข้าสู่ระบบไปยังศูนย์บริการลูกค้า",
    "cms.qcrelationconf.field.agentGroupName" : "กลุ่มที่ถูกตรวจสอบ",
    "cms.report.field.inAnswerRateField" : "อัตราการเชื่อมต่อการโทรเข้า",
    "cms.report.message.outVdnAvgTimeDesc" : "ระยะเวลาการโทรขาออกโดยเฉลี่ยใน VDN ค่าคือระยะเวลาการโทรออกทั้งหมดหารด้วยจํานวนการโทรออก",
    "cms.reportsubscribe.message.deleteConfim" : "คุณแน่ใจว่าจะลบใช่หรือไม่?",
    "cms.indexmonitor.message.abandonInThree" : "เปอร์เซ็นต์ของจํานวนสายที่ถูกทิ้งภายใน 3 วินาทีของจํานวนสายที่ถูกทิ้งทั้งหมด",
    "cms.report.field.IVRInSuccField" : "สายขาเข้า IVR ที่เชื่อมต่อ",
    "cms.systemmonitor.field.vdnName" : "ผู้เช่า",
    "cms.report.field.reportType" : "ประเภทรายงาน",
    "cms.agentmonitor.label.noListenedAndInserted" : "ไม่ได้ตรวจสอบ",
    "cms.fullscreen.message.enterMessage" : "ป้อน",
    "cms.report.field.acwOutTimeField" : "ระยะเวลาที่ใช้สรุปผลการโทรออก",
    "cms.qcrelationconf.field.qualityInspection" : "ผตัวตรวจสอบคุณภาพ",
    "cms.indexmonitor.message.agentConnectIn3Rate" : "ร้อยละของจํานวนการโทรที่เชื่อมต่อด้วยตนเองภายใน 3 วินาที (รวม 3 วินาที) ต่อจํานวนการโทรทั้งหมด",
    "cms.report.field.avgIVROutCount" : "จํานวนเฉลี่ยของการโทรออก IVR พร้อมกัน",
    "cms.voiceinspection.field.video" : "วิดีโอ",
    "cms.voiceinspection.field.reset" : "รีเซ็ต",
    "cms.report.field.internalTransferCountField" : "การโอนภายใน",
    "cms.fullscreen.field.table" : "ตาราง",
    "cms.report.message.acwTimeDesc" : "ระยะเวลาทั้งหมดเมื่อเจ้าหน้าที่อยู่ในสถานะการสรุปผลการสนทนา",
    "cms.report.field.outCallNum" : "การโทรขาออก",
    "cms.report.field.failAvgTime" : "ระยะเวลาเสียงเรียกเข้าโดยเฉลี่ยของสายที่หลุดไป",
    "cms.report.message.preDeviceTypeDesc" : "ประเภทอุปกรณ์ของอุปกรณ์ล่าสุดที่โทรได้ประสบ",
    "cms.report.field.muteCountField" : "เวลาเงียบ",
    "cms.report.field.ivrtrafficreport.statdate" : "วันที่",
    "cms.sysparam.config.title" : "พารามิเตอร์ของระบบ",
    "cms.reportsubscribe.field.wednesday" : "วันพุธ",
    "cms.indexmonitor.field.skillAvgAbandonAck" : "ระยะเวลาเสียงเรียกเข้าโดยเฉลี่ยของสายที่หลุดไป",
    "cms.sysparam.config.itemname" : "ชื่อพารามิเตอร์",
    "cms.report.message.agentHangNumCtiDesc" : "จำนวนเจ้าหน้าที่ที่วางสาย",
    "cms.systemmonitor.label.customizes" : "กำหนดเอง",
    "cms.tenant.message.adminpasswordagain" : "ยืนยันรหัสผ่าน",
    "cms.systemparam.success.save" : "บันทึกพารามิเตอร์สําเร็จ",
    "cms.callcenterinstanceslist.title.baseInfo" : "ข้อมูลพื้นฐาน",
    "cms.voiceinspection.field.clickToCall" : "ใช้เสียงเพื่อโทร",
    "cms.report.field.deviceTypeField" : "ประเภทอุปกรณ์",
    "cms.indexmonitor.field.agentInboundConnectNum" : "การเชื่อมต่อการโทรเข้าด้วยตนเอง",
    "cms.reportsubscribe.field.basicInfo" : "ข้อมูลพื้นฐาน",
    "cms.callcenterinstanceslist.title.sourceconfiguration" : "การกําหนดค่าแหล่งข้อมูล",
    "cms.uapmonitor.field.socketConnecting" : "กําลังเชื่อมต่อ",
    "cms.report.message.callLostOtherNumDesc" : "จํานวนสายที่ไม่ได้รับการตอบรับเนื่องจากสาเหตุอื่นนอกเหนือจากสายไม่ว่าง การปฏิเสธการโทร และเวลานอกของเสียงเรียกเข้า",
    "cms.interfaceperflog.message.dvSwitch" : "เปิดใช้งานการรายงานข้อมูล",
    "cms.report.message.acdTimeDesc" : "ระยะเวลาการโทรทั้งหมดของเจ้าหน้าที่ ไม่รวมถึงระยะเวลาการแชทด้วยข้อความ",
    "cms.calllink.field.call" : "โทร",
    "cms.qcrelationconf.field.groupName" : "ชื่อกลุ่มผู้ใช้",
    "cms.systemmonitor.field.uapIP" : "UAP IP",
    "cms.agentmonitor.label.terminalStatusFix" : "คืนค่าสถานะ",
    "cms.indexmonitor.message.agentConnectedIn5NDesc" : "จํานวนการโทรทั้งหมดที่มีระยะเวลาการรับสายนานกว่าหรือเท่ากับ 5 วินาที",
    "cms.indexmonitor.message.skillTimeoutAgentDesc" : "จำนวนการโทรถูกโอนจากลำดับทักษะเดิมไปยังลำดับทักษะอื่นเนื่องจากการรอคิวหมดเวลา",
    "cms.reportsubscribe.message.searchTemplateName" : "โปรดป้อนชื่อเทมเพลต",
    "cms.report.message.tooManyExport" : "การส่งออก {0} มากเกินไปในช่วงเวลาสั้นๆ",
    "cms.report.field.maxIVRCount" : "จํานวนสูงสุดของการโทร IVR พร้อมกัน",
    "cms.report.field.IVRServiceRate" : "อัตราความสําเร็จของการโทรเข้าของ Pure-IVR",
    "cms.fullscreen.field.virtualcallcenter" : "VDN",
    "cms.systemparam.error.save" : "ล้มเหลวในการบันทึกพารามิเตอร์",
    "cms.callcenterinstanceslist.message.uapNode1Info" : "การกําหนดค่า UAP Node 1",
    "cms.indexmonitor.message.abandonInTen" : "เปอร์เซ็นต์ของจํานวนสายที่ถูกทิ้งภายใน 10 วินาทีของจํานวนสายที่ถูกทิ้งทั้งหมด",
    "cms.systemparam.error.validate.notmatchruleurl" : "โปรดป้อนที่อยู่ URL ที่ถูกต้อง",
    "cms.callcenterinstanceslist.message.datasourceInfo" : "โปรดกรอกการกําหนดค่าแหล่งข้อมูล",
    "cms.report.field.workTime" : "ระยะเวลาการทำงานทั้งหมด",
    "cms.vcallecenter.title.modifyadministratorpassword" : "รหัสผ่านผู้ดูแลระบบ CTI",
    "cms.callcenterinstanceslist.message.alreadyInuse" : "มีการใช้ ID พนักงาน",
    "cms.calllink.field.callType9" : "Relayed call",
    "cms.report.message.maxQueueOutCountDesc" : "จํานวนสูงสุดของการโทรออกพร้อมกันที่มีอุปกรณ์บริการเป็นคิวทักษะ",
    "cms.indexmonitor.field.skillLostRing" : "สายที่ไม่ได้รับ",
    "cms.calllink.field.callType8" : "การโทรเข้า (เจ้าหน้าที่ระหว่างประเทศ)",
    "cms.report.title.vdnTrafficPeriodMonitor" : "การตรวจสอบการจราจร VDN แบบ Interval-based",
    "cms.calllink.field.callType7" : "Auxiliary call",
    "cms.calllink.field.callType6" : "การโทรภายในระบบเครือข่ายขาออกไปยังเจ้าหน้าที่เสมือน",
    "cms.report.field.otherHangNumCtiField" : "ระบบ hang-ups",
    "cms.calllink.field.callType5" : "การโทรภายในระบบเครือข่ายขาเข้าไปยังเจ้าหน้าที่เสมือน",
    "cms.calllink.field.callType4" : "การโทรตรวจสอบขาเข้าของศูนย์บริการเครือข่าย",
    "cms.report.message.inWaitAnsNumDesc" : "จํานวนคําขอสายเรียกเข้าสําหรับบริการด้วยตนเอง",
    "cms.calllink.field.callType3" : "การโทรออกของศูนย์บริการเครือข่าย",
    "cms.indexmonitor.message.totalRestNum" : "จำนวนเจ้าหน้าที่ในสถานะพัก",
    "cms.calllink.field.callType2" : "การโทรเข้าของศูนย์บริการเครือข่าย",
    "cms.calllink.field.callType1" : "การโทรออกจาก IVR PRI",
    "cms.calllink.field.callType0" : "สายโทรออกจากโทรศัพท์ของเจ้าหน้าที่",
    "cms.monitordetail.field.innerforward" : "จำนวนการโทรที่โอนภายใน",
    "cms.indexmonitor.title.vdnDayTrend" : "แนวโน้ม VDN ของวันนี้",
    "cms.indexmonitor.field.queueOutCallCount" : "จำนวนการโทรเข้าคิวออนไลน์",
    "cms.datasource.field.ok" : "ตกลง",
    "cms.indexmonitor.title.selectAgentName" : "เลือกผู้เช่า",
    "cms.calllink.field.transferOut" : "มีการโอนสายออก",
    "cms.fullscreen.field.avgCallTime" : "ระยะเวลาการโทรเฉลี่ย",
    "cms.report.message.agentAlertingDurationDesc" : "ระยะเวลาการแจ้งเตือนการโทร",
    "cms.report.field.submediatype.twitter" : "X (Twitter)",
    "cms.monitordetail.field.busyDuration" : "ระยะเวลาไม่ว่าง",
    "cms.calllind.field.deviceVnr" : "อุปกรณ์เสมือน",
    "cms.indexmonitor.message.warningFiltering" : "เลือกตัวบ่งชี้อย่างน้อยหนึ่งตัว",
    "cms.monitordetail.title.statistics" : "สถิติ",
    "cms.qcrelationconf.field.outgoingAgent" : "ตัวแทนสายเรียกเข้า",
    "cms.report.title.abandonRingTrend" : "แนวโน้มของสายที่ไม่ได้รับการบริการระหว่างเสียงเรียกเข้า",
    "cms.indexmonitor.message.agentConnectAfterInTen" : "เปอร์เซ็นต์ของจํานวนสายที่เชื่อมต่อด้วยตนเองหลังจาก 10 วินาทีของจํานวนสายที่เชื่อมต่อด้วยตนเองทั้งหมด",
    "cms.monitordetail.field.totaltalkingduration" : "ระยะเวลาการโทรทั้งหมด",
    "cms.report.message.skillIdDesc" : "ID ของคิวทักษะที่การโทรเป็น",
    "cms.sysparam.config.refresh" : "รีเฟรช",
    "cms.monitordetail.field.audio" : "เสียง / วิดีโอ",
    "cms.fullscreen.message.themeDuplicate" : "ชื่อที่ซ้ำกัน",
    "cms.report.field.transferIvrCount" : "การโอนสายด้วยตนเองไปยัง IVR",
    "cms.report.field.outAvgCallTime" : "ระยะเวลาการโทรออกด้วยตนเองโดยเฉลี่ย",
    "cms.datasource.field.dbUsername" : "ชื่อผู้ใช้",
    "cms.indexmonitor.title.ivrNowMonitor" : "การตรวจสอบการทำงานของ IVR แบบเรียลไทม์",
    "cms.datasource.field.no" : "ไม่",
    "cms.indexmonitor.field.skillRequest" : "คำขอบริการ",
    "cms.calllink.title.endTime" : "สิ้นสุด",
    "cms.calllink.title.deviceType" : "ประเภทอุปกรณ์",
    "cms.callcenterinstanceslist.field.tenantSpaceName" : "บัญชีผู้ดูแลระบบ",
    "cms.agentMonitor.label.forcebusy_repeat" : "เจ้าหน้าที่ไม่ว่าง กรุณาอย่าพูดซ้ํา",
    "cms.agentMonitor.label.forcebusy_talking": "เจ้าหน้าที่กําลังโทรอยู่ กรุณาอย่ายุ่ง",
    "cms.agentMonitor.label.forceidle_talking": "เจ้าหน้าที่กําลังโทรอยู่ กรุณาอย่าหยุดพัก",
    "cms.callcenterinstanceslist.field.vccId" : "ID ศูนย์บริการเสมือนจริง",
    "cms.indexmonitor.message.agentAbandonIn60Rate" : "เปอร์เซ็นต์ของสายที่ถูกทิ้งภายใน 60 วินาทีต่อสายที่ถูกทิ้งทั้งหมด",
    "cms.agentmonitor.label.cancelwhisper" : "ยกเลิกการกระซิบ",
    "cms.indexmonitor.message.skillConnectedInNDesc" : "จำนวนคำขอโทรที่เชื่อมต่อด้วยตนเองใน Ns. ตอนนี้ Ns สามารถเป็น 3 วินาที, 5 วินาที, 10 วินาที, 20 วินาที, 30 วินาที หรือ 60 วินาที",
    "cms.indexmonitor.field.skillConnectedIn5Rate" : "เชื่อมต่อการโทรใน 5 วินาที",
    "cms.report.field.startTime" : "วันเริ่มต้น",
    "cms.report.field.acwRate" : "อัตราระยะเวลาที่ใช้สรุปผลการสนทนา",
    "cms.indexmonitor.field.currentSelectedSkill" : "เลือกลำดับทักษะ:",
    "cms.agentMonitor.label.querynum_error" : "อัปเดตหมายเลขลงชื่อเข้าใช้ล้มเหลว",
    "cms.report.message.queryErrorInfo" : "การค้นหาข้อมูลล้มเหลว",
    "cms.report.field.outCallSuccRateField" : "อัตราการเชื่อมต่อการโทรขาออก",
    "cms.callcenterinstanceslist.field.uapCmdPort" : "พอร์ทคําสั่ง UAP MML",
    "cms.report.field.skillName" : "ชื่อคิวทักษะ",
    "cms.report.field.loginCount" : "จำนวนลงชื่อเข้าใช้",
    "cms.indexmonitor.field.skillMinQueueAck" : "ระยะเวลาการรอสายต่ำสุด",
    "cms.report.field.enterReasonField" : "เหตุผลของอุปกรณ์รายการการโทร",
    "cms.reportsubscribe.field.subscriptionList" : "บัญชีสมาชิก",
    "cms.report.field.callIdField" : "หมายเลขลําดับการโทร",
    "cms.report.field.IVRSuccRateField" : "อัตราการเชื่อมต่อการโทรเข้า IVR",
    "cms.qcrelationconf.message.paramOutOfRange" : "ใส่อักขระไม่สามารถเกิน 100 ตัว",
    "cms.monitordetail.field.busy" : "ไม่ว่าง",
    "cms.report.message.releaseCauseDesc" : "เหตุผลในการปล่อยสาย",
    "cms.fullscreen.message.maxThemeNum" : "สามารถเพิ่มธีมส่วนตัวได้สูงสุด 20 ธีมสาธารณะและ 20 ธีมสาธารณะ",
    "cms.report.message.perAgentNumDesc" : "จำนวนการโทรที่จัดสรรให้กับเจ้าหน้าที่ในส่วนเวลาที่เลือก",
    "cms.indexmonitor.message.abandonInThirty" : "เปอร์เซ็นต์ของจํานวนสายที่ถูกทิ้งภายใน 30 วินาทีของจํานวนสายที่ถูกทิ้งทั้งหมด",
    "cms.qualitycheck.field.unselected" : "ไม่ได้เลือก",
    "cms.report.title.ivrOutboundTrend" : "แนวโน้มการโทรขาออกของ IVR",
    "cms.monitordetail.field.holdcount" : "รอสาย",
    "cms.report.field.failQueueWaitRateField" : "ระยะเวลาเสียงเรียกเข้าโดยเฉลี่ยของสายที่หลุดไป",
    "cms.monitordetail.field.duration" : "สถิติขึ้นกับระยะเวลา",
    "cms.indexmonitor.field.skillConnectedAfter60" : "เชื่อมต่อการโทรหลังจาก 60 วินาที",
    "cms.qualitycheck.label.trafficGroup" : "กลุ่มทราฟฟิก",
    "cms.report.message.avgIVRServiceDesc" : "ระยะเวลาการโทร IVR โดยเฉลี่ย ซึ่งเท่ากับระยะเวลาการโทรเข้า IVR ที่หารด้วยจำนวนของการโทรเข้า IVR ที่เชื่อมต่อ",
    "cms.report.field.IVRCallOutNumField" : "การโทรขาออก IVR",
    "cms.indexmonitor.message.totalCallNumDesc" : "จํานวนรวมของการโทรที่เชื่อมต่อโดยเอเจนต์",
    "cms.uapmonitor.labe.inspectorcheckinpwd" : "รหัสผ่านเข้าสู่ระบบผู้ตรวจสอบ QC",
    "cms.fullscreen.title.deleteconfim" : "ยืนยัน",
    "cms.indexmonitor.field.totalBusyNum" : "เจ้าหน้าที่อยู่ในสถานะไม่ว่าง",
    "cms.uapmonitor.labe.eid" : "ส่วนหมายเลขเทอร์มินอล",
    "cms.recorddrive.title.sftpServerConfig" : "การกำหนดค่าเซิร์ฟเวอร์ SFTP",
    "cms.report.field.manToSkillNum" : "การโอนคิวแบบ Manual-to-skill",
    "cms.systemparam.success.refresh" : "อัปเดตพารามิเตอร์สําเร็จ",
    "cms.report.message.agentOutSuccDesc" : "จํานวนการโทรออกด้วยตนเองที่เชื่อมต่อกับผู้ใช้ ซึ่งเป็นจํานวนการโทรออกด้วยตนเองที่มีระยะเวลาการโทรมากกว่า 0",
    "cms.report.field.maxQueueInCount" : "จํานวนสูงสุดของสายเรียกเข้าพร้อมกันในคิว",
    "cms.callcenterinstanceslist.field.operate" : "การปฏิบัติการ",
    "cms.indexmonitor.message.agentConnectInTen" : "เปอร์เซ็นต์ของจํานวนสายที่เชื่อมต่อด้วยตนเองภายใน 10 วินาทีของจํานวนสายที่เชื่อมต่อด้วยตนเองทั้งหมด",
    "cms.report.field.call4Rate" : "อัตราการเชื่อมต่อการโทรเข้าด้วยตนเองใน 20 วินาที",
    "cms.report.message.enterReasonDesc" : "เหตุผลของอุปกรณ์รายการการโทร",
    "cms.report.message.avgHandleTimeDesc" : "ระยะเวลาการประมวลผลโดยเฉลี่ย = (ระยะเวลาการโทรด้วยตนเอง + ระยะเวลาที่ใช้สรุปผลการสนทนาทั้งหมด)/จำนวนการโทรด้วยตนเองที่สำเร็จ",
    "cms.agentmonitor.label.total" : "ตัวแทนทั้งหมด",
  "cms.agentmonitor.label.syncScreenMonitor": "หน้าจอมอนิเตอร์",
    "cms.indexmonitor.field.inboundAbandonRate" : "การโทรเข้าที่ถูกวางสายทิ้ง",
    "cms.report.title.IVRReport" : "รายงานข้อมูล IVR",
    "cms.report.field.outWaitAnsNum" : "การโทรออกด้วยตนเอง",
    "cms.agentMonitor.label.Idle_error" : "ผู้ตรวจสอบไม่ได้อยู่ในสถานะว่าง",
    "cms.report.field.preDeviceTypeField" : "ประเภทอุปกรณ์ก่อนหน้า",
    "cms.recorddrive.placeholder.singledeleteconfim" : "คุณแน่ใจที่จะลบ drive letter ที่เลือกไว้ใช่หรือไม่?",
    "cms.vcallecenter.title.modifyinfo2" : "กำหนดค่ารหัสผ่าน",
    "cms.export.field.download" : "ดาวน์โหลด",
    "cms.report.field.selectOrg" : "เลือก OU",
    "cms.report.field.maxQueueWaitTimeField" : "ระยะเวลาการรอสายสูงสุด",
    "cms.voiceinspection.message.invalidQcRelation" : "ความสัมพันธ์ในการตรวจสอบไม่ตรงกัน",
    "cms.qualitycheck.label.input" : "โปรดใส่",
    "cms.report.field.acwOutCountField" : "เวลาที่ใช้สรุปผลการโทรออก",
    "cms.report.message.outInvalidCallDesc" : "จำนวนการโทรออกที่ไม่สามารถเข้าถึงลูกค้าเนื่องจากตัวเลขไม่ถูกต้องหรือปิดเครื่อง",
    "cms.report.field.userNoAnswerNum" : "สายที่หายไปเนื่องจากหมดเวลาของเสียงเรียกเข้า",
    "cms.report.field.agentOfferedField" : "การโทรเข้าด้วยตนเอง",
    "cms.datasource.field.dbPwd" : "รหัสผ่าน",
    "cms.monitordetail.field.intercallcount" : "จำนวนการเรียกภายใน",
    "cms.report.field.holdCountField" : "ถือสาย",
    "cms.indexmonitor.message.agentBoundConnectRate" : "เปอร์เซ็นต์ของจํานวนสายที่เชื่อมต่อด้วยตนเองในจํานวนสายที่โทรเข้าด้วยตนเอง",
    "cms.indexmonitor.field.skillConnectedIn60" : "เชื่อมต่อการโทรใน 60 วินาที",
    "cms.indexmonitor.field.ivrInboundNum" : "การโทรเข้า IVR ที่เชื่อมต่อ",
    "cms.uapmonitor.field.deregister" : "คุณแน่ใจหรือไม่ว่าต้องการยกเลิกการลงทะเบียนอุปกรณ์นี้",
    "cms.indexmonitor.field.totalTalkingNum" : "เจ้าหน้าที่อยู่ในสาย",
    "cms.callcenterinstanceslist.title.deleteconfim" : "ยืนยันการลบ",
    "cms.agentmonitor.label.forceSignOut" : "บังคับให้ออก",
    "cms.callcenterinstanceslist.message.updateSuccess" : "ปรับเปลี่ยนศูนย์บริการลูกค้าสำเร็จ",
    "cms.report.message.locationIdDesc" : "ID ของโหนดที่กระจายที่การโทรเป็น",
    "cms.systemmonitor.message.dategt31days" : "ช่วงวันที่ต้องไม่เกิน 31 วัน",
    "cms.agentMonitor.label.forceidle_error" : "บังคับตั้งค่าเจ้าหน้าที่ให้อยู่ในสถานะว่างล้มเหลว",
    "cms.report.message.outCallNumDesc" : "จํานวนการโทรออกด้วยตนเองและอัตโนมัติใน VDN ค่านี้คือผลรวมของการโทรออกด้วยตนเองและการโทรออกโดยอัตโนมัติโดยใช้ IVR",
    "cms.indexmonitor.title.selectCcName" : "เลือกศูนย์บริการลูกค้า",
    "cms.qcrelationconf.field.accountName" : "ชื่อบัญชี",
    "cms.report.field.minQueueCount" : "จํานวนขั้นต่ําของการโทรพร้อมกันในคิว",
    "cms.indexmonitor.message.agentAbandonIn5Rate" : "เปอร์เซ็นต์ของสายที่ถูกทิ้งภายใน 5 วินาทีต่อสายที่ถูกทิ้งทั้งหมด",
    "cms.report.title.agentOutCallReport" : "รายงานการโทรออกของเจ้าหน้าที่่",
    "cms.report.field.inCallSuccNumber" : "สายขาเข้าที่เชื่อมต่อแล้ว",
    "cms.report.field.submediatype.5gmessage" : "5G RCS",
    "cms.voiceinspection.message.frequentPlay" : "การเล่นถี่เกินไป ลองใหม่อีกครั้งในภายหลัง",
    "cms.calllink.field.outOfService" : "โทรศัพท์ไม่สามารถให้บริการได้",
    "cms.report.field.byWeek" : "รายงานประจำสัปดาห์",
    "cms.indexmonitor.field.abandonInFive" : "วางสายใน 5 วินาที",
    "cms.report.field.IVRInSuccRate" : "อัตราการเชื่อมต่อสายขาเข้า IVR",
    "cms.report.message.callBeginDesc" : "เวลาเริ่มต้นการโทร",
    "cms.callcenterinstanceslist.field.backupcc" : "ศูนย์การโทรสํารอง",
    "cms.report.message.failAnsRateDesc" : "อัตราที่เสียไป = 100 – อัตราการเชื่อมต่อการโทร",
    "cms.indexmonitor.message.agentInCallCount" : "จจํานวนสายเรียกเข้าที่เชื่อมต่ออยู่(ไม่มีเซสชันมัลติมีเดีย)",
    "cms.vcallecenter.field.curcountpassword" : "รหัสผ่านบัญชีปัจจุบัน",
    "cms.report.field.alertingDurationField" : "ระยะเวลาการแจ้งเตือนที่โทรได้",
    "cms.report.message.waitCauseDesc" : "เหตุผลที่สายเรียกเข้ารอบนอุปกรณ์ปัจจุบัน",
    "cms.qcrelationconf.placeholder.workName" : "ใส่ชื่อตัวแทน",
    "cms.agentmonitor.label.showAlarmCallId" : "ID โทร",
    "cms.fullscreen.field.themeTypeY" : "หัวข้อสาธารณะ",
    "cms.report.message.currentSkillIdDesc" : "ID ของคิวทักษะที่ประมวลผลการโทร",
    "cms.report.message.startOrEndTimeNoExist" : "หากเลือกช่วงเวลาสําหรับแบบสอบถาม เวลาเริ่มต้นและเวลาสิ้นสุดจะต้องมีอยู่ในเวลาเดียวกัน",
    "cms.callcenterinstanceslist.field.uapIp" : "IP NMU UAP",
    "cms.systemmonitor.field.systemCallRate" : "อัตราการโทรเสร็จสมบูรณ์ของระบบ",
    "cms.qualitycheck.label.delete" : "ลบ",
    "cms.report.field.talkDurationField" : "ระยะเวลาการพูดคุย",
    "cms.indexmonitor.field.skillAvgAbandonQueue" : "ระยะเวลาเข้าคิวโดยเฉลี่ยของสายที่หลุดไป",
    "cms.fullscreen.field.themeTypeN" : "ธีมส่วนตัว",
    "cms.indexmonitor.message.inboundAbandonRate" : "เปอร์เซ็นต์ของจำนวนสายเรียกเข้าที่ไม่ได้เชื่อมต่อกับจำนวนสายเรียกเข้า หากจำนวนสายเรียกเข้าเป็น 0 ค่าตัวบ่งชี้จะเป็น 0",
    "cms.callcenterinstanceslist.message.ccgatewayServerBatchdeleteconfim" : "ต้องการลบเซิร์ฟเวอร์ CC-เกตเวย์ที่เลือกไว้ทั้งหมดใช่หรือไม่?",
    "cms.systemmonitor.title.tips" : "ข้อมูล",
    "cms.fullscreen.message.deletechart" : "คุณต้องการยืนยันการลบแผนภูมินี้หรือไม่?",
    "cms.report.field.maxQueueOutCount" : "จํานวนสูงสุดของการโทรออกพร้อมกันในคิว",
    "cms.report.title.IVRTrend" : "แนวโน้มข้อมูล IVR",
    "cms.agentMonitor.label.forcebusy_error" : "บังคับการตั้งค่าเจ้าหน้าที่เป็นสถานะไม่ว่างล้มเหลว",
    "cms.report.message.createTimeDesc" : "สร้างเวลา",
    "cms.systemparam.error.validate.notmatchruleregex" : "พารามิเตอร์อินพุตไม่ตรงกับนิพจน์ทั่วไปของกฎการตรวจสอบ",
    "cms.report.title.changeChart" : "สลับไปที่แผนภูมิ",
    "cms.indexmonitor.message.totalIdeaNum" : "จำนวนของเจ้าหน้าที่ที่อยู่ในสถานะว่างและสามารถดำเนินการโทรได้ทันที",
    "cms.calllink.field.helperRelease" : "การโทรถูกตัดการเชื่อมต่อโดยผู้รัยสายที่ส่งคำขอความช่วยเหลือภายใน",
    "cms.report.message.minQueueOutCountDesc" : "จํานวนขั้นต่ําของการโทรออกพร้อมกันที่มีอุปกรณ์บริการเป็นคิวทักษะ",
    "cms.voiceinspection.field.phoneVideo" : "วิดีโอคอลปกติ",
    "cms.report.field.callNoPlaceholder" : "หมายเลขที่โทรหรือโทร",
    "cms.indexmonitor.title.skillNowMonitor" : "สถิติการตรวจสอบแบบเรียลไทม์เกี่ยวกับลำดับทักษะระหว่างการทำงาน",
    "cms.indexmonitor.field.skillConnectedAfter10" : "เชื่อมต่อการโทรหลังจาก 10 วินาที",
    "cms.report.message.dategt31days" : "ช่วงวันที่ต้องไม่เกิน 31 วัน",
    "cms.developerlist.label.refreshsk" : "อัปเดต SK",
    "cms.callcenterinstanceslist.label.recordDrive" : "การบันทึก Drive Letter",
    "cms.systemmonitor.label.threehours" : "3 ชั่วโมง",
    "cms.fullscreen.title.newTheme" : "สร้างชื่อเรื่อง",
    "cms.fullscreen.field.edit" : "ปรับเปลี่ยน",
    "cms.export.field.viewExportTask" : "ส่งออกมุมมองงาน",
    "cms.indexmonitor.field.skillConnectedIn30" : "เชื่อมต่อการโทรใน 30 วินาที",
    "cms.fullscreen.message.chooseTheme" : "ไม่ได้บันทึกผัง คุณต้องการเปลี่ยนชื่อเรื่องใช่หรือไม่?",
    "cms.report.field.minQueueCountField" : "จํานวนขั้นต่ําของการโทรพร้อมกันในคิว",
    "cms.callcenterinstanceslist.message.ccIdUpdateError" : "อย่าเปลี่ยน ID ศูนย์บริการลูกค้าเมื่อทำการแก้ไขศูนย์บริการลูกค้า",
    "cms.report.field.agentInSucc" : "สายขาเข้าแบบแมนนวลที่เชื่อมต่ออยู่",
    "cms.report.field.outVdnAvgTime" : "ค่าเฉลี่ยระยะเวลาการโทรออกใน VDN",
    "cms.report.field.waitAnsRateField" : "ระยะเวลาเสียงเรียกเข้าโดยเฉลี่ยของการโทรเข้าด้วยตนเอง",
    "cms.report.field.inAvgRingTime" : "ระยะเวลาเสียงเรียกเข้าโดยเฉลี่ยของการโทรเข้าด้วยตนเอง",
    "cms.indexmonitor.field.skillMaxQueueAck" : "ระยะเวลาการรอสายสูงสุด",
    "cms.indexmonitor.title.vdnDayMonitor" : "สถิติตัวบ่งชี้ VDN วันนี้",
    "cms.report.field.currentSkillIdField" : "ID คิวทักษะ",
    "cms.report.field.maxAgentCount" : "จํานวนสูงสุดของการโทรของตัวแทนพร้อมกัน",
    "cms.report.field.ivrtrafficreport.ivrshortcalls" : "หมายเลขโทรสั้นของ IVR (ภายใน 3 วินาที)",
    "cms.skilladjustment.field.skilltype" : "ประเภทลำดับทักษะ",
    "cms.export.field.exportiong" : "การส่งออก",
    "cms.fullscreen.message.selectAgent" : "องค์กรและเจ้าหน้าที่ไม่สามารถว่างเปล่าได้",
    "cms.report.field.submediatype.web" : "Web",
    "cms.export.message.parainvalid" : "ยืนยันเงื่อนไขการส่งออกไม่สำเร็จ",
    "cms.systemmonitor.field.rateNum" : "สายที่เชื่อมต่อกับระบบ",
    "cms.fullscreen.field.screenreSolution" : "ความละเอียด",
    "cms.report.message.weekLessThan" : "สัปดาห์สิ้นปีต้องไม่น้อยกว่าสัปดาห์เริ่มต้นปี",
    "cms.synchronize.message.adminPwdStatus" : "รหัสผ่านผู้ดูแลระบบ",
    "cms.fullscreen.message.numberFirst" : "ค่าสูงสุดคือ 7680",
    "cms.qcrelationconf.message.isDeleteGroupMember" : "โปรดยืนยันว่าจะลบสมาชิกกลุ่มหรือไม่",
    "cms.indexmonitor.message.durationAvgVdnOutbound" : "ระยะเวลาทั้งหมดที่การโทรออกใช้ VDN/จำนวนการโทรออกทั้งหมด",
    "cms.report.message.agentInSuccDesc" : "จํานวนสายโทรเข้าด้วยตนเองที่เชื่อมต่อกับเอเจนต์ ซึ่งเป็นจํานวนสายโทรเข้าด้วยตนเองที่มีระยะเวลาการโทรมากกว่า 0",
    "cms.agentMonitor.label.cancelListening_error" : "ยกเลิกการฟังล้มเหลว",
    "cms.indexmonitor.message.ivrInboundUserAbort" : "จำนวนการโทรเชิงรุกที่วางสายโดยผู้ใช้ในระบบ IVR",
    "cms.reportsubscribe.title.tips" : "ข้อมูล",
    "cms.fullscreen.field.FangSong" : "FangSong",
    "cms.datasource.message.deletesuccess" : "ลบสําเร็จ",
    "cms.systemmonitor.label.reset" : "รีเซ็ต",
    "cms.report.message.minQueueCountDesc" : "จํานวนขั้นต่ําของการโทรพร้อมกันที่มีอุปกรณ์บริการเป็นคิวทักษะ",
    "cms.agentMonitor.label.beinrest_error" : "เจ้าหน้าที่ที่ตรวจสอบอยู่ในสถานะการพัก",
    "cms.systemparam.error.refresh" : "ล้มเหลวในการอัปเดตพารามิเตอร์",
    "cms.report.field.IVRInBound" : "สายขาเข้า IVR",
    "cms.indexmonitor.field.skillAbandonIn60" : "วางสายใน 60 วินาที",
    "cms.report.message.auxTimeRateDesc" : "อัตราระยะเวลาพัก = (ระยะเวลาพักทั้งหมด/ระยะเวลาการทำงานทั้งหมด) x 100",
    "cms.indexmonitor.message.totalLogoutNum" : "จำนวนเจ้าหน้าที่ที่ไม่ได้เข้าสู่ระบบศูนย์บริการและอยู่ในสถานะออฟไลน์",
    "cms.indexmonitor.field.skillConnectedIn10" : "เชื่อมต่อการโทรใน 10 วินาที",
    "cms.indexmonitor.field.skillConnectedIn20" : "เชื่อมต่อการโทรใน 20 วินาที",
    "cms.agent.message.modifypasswordsuccess" : "เปลี่ยนรหัสผ่านสำเร็จแล้ว",
    "cms.report.field.busyTimeRate" : "อัตราระยะเวลาไม่ว่าง",
    "cms.callcenterinstanceslist.title.callCenter" : "ศูนย์บริการลูกค้า",
    "cms.indexmonitor.field.totalAgentNum" : "เจ้าหน้าที่ทั้งหมด",
    "cms.report.field.acwInTimeField" : "ระยะเวลาที่ใช้สรุปผลการโทรเข้า",
    "cms.report.message.inAnswerRateDesc" : "เปอร์เซ็นต์ของจํานวนสายเรียกเข้าที่เชื่อมต่อด้วยตนเองไปยังจํานวนสายเรียกเข้าด้วยตนเอง",
    "cms.indexmonitor.message.skillConnectedRateDesc" : "เปอร์เซ็นต์ของจำนวนการร้องขอการโทรเข้าด้วยตนเองที่เชื่อมต่อในจำนวนการโทรเข้าด้วยตนเองที่ร้องขอ",
    "cms.qcrelationconf.field.qcGroupName" : "กลุ่มผู้ตรวจสอบ",
    "cms.callcenterinstanceslist.message.workNoCheckError" : "หมายเลขเริ่มต้นของ ID งานต้องน้อยกว่าหมายเลขสิ้นสุด",
    "cms.report.message.avgPerHNumDesc" : "จำนวนการโทรที่สำเร็จเฉลี่ยต่อชั่วโมงในส่วนเวลาทำงานที่เลือก",
    "cms.fullscreen.field.text" : "ข้อความ",
    "cms.report.field.leaveReasonField" : "เหตุผลของอุปกรณ์รายการการโทร",
    "cms.calllink.field.hold" : "โทรศัพท์มีการใช้งาน",
    "cms.uapmonitor.field.callSize" : "ประเภทเทอร์มินอล",
    "cms.report.message.voicedataOverError" : "จํานวนข้อมูลที่ส่งออกเกิน 0 สูงสุด โปรดเลือกเงื่อนไขการสอบถามอื่น",
    "cms.agentMonitor.label.forcesignout_success" : "เจ้าหน้าที่ลงชื่อออกสำเร็จ",
    "cms.report.title.skillTrend" : "แนวโน้มประสิทธิภาพของลำดับทักษะ",
    "cms.callcenterinstanceslist.field.tenantName" : "ชื่อผู้เช่า",
    "cms.report.message.exportSucceed" : "ขาออกสำเร็จ",
    "cms.report.title.calldetailoriginal" : "รายละเอียดเนื้อหาการโทร",
    "cms.report.field.skillQueue" : "ลำดับทักษะ",
    "cms.report.message.IVRShortCallsDesc" : "จำนวนการโทรที่มีระยะเวลา IVR มากกว่า 0 และน้อยกว่าหรือเท่ากับ 3 วินาที",
    "cms.agentmonitor.label.cancellistening" : "ยกเลิกการฟัง",
    "cms.indexmonitor.field.skillAbandonIn5" : "วางสายใน 5 วินาที",
    "cms.systemparam.error.validate.notmatchruledigits" : "โปรดป้อนจำนวนเต็มบวกที่ถูกต้อง",
    "cms.fullscreen.field.minThreshold" : "เกณฑ์ขั้นต่ํา",
    "cms.indexmonitor.field.skillAbandonIn3" : "วางสายใน 3 วินาที",
    "cms.report.message.selectAgentMax" : "สามารถเลือก ID เอเจนท์ได้สูงสุด 100 คน",
    "cms.monitordetail.field.answerCount" : "สายที่รับ",
    "cms.qcrelationconf.field.ok" : "ตกลง",
    "cms.monitordetail.field.restoutcount" : "จำนวนหมดเวลาพัก",
    "cms.indexmonitor.message.agentAbandonIn10Rate" : "เปอร์เซ็นต์ของสายที่ถูกทิ้งภายใน 10 วินาทีต่อสายที่ถูกทิ้งทั้งหมด",
    "cms.qcrelationconf.field.configAdmin" : "ผู้ดูแลระบบการตั้งค่า",
    "cms.report.field.endMonth" : "สิ้นเดือน",
    "cms.report.field.call3RateField" : "อัตราการเชื่อมต่อการโทรเข้าด้วยตนเองใน 15 วินาที",
    "cms.report.message.maxIVROutCountDesc" : "จํานวนสูงสุดของการโทรออกพร้อมกันที่มีอุปกรณ์บริการคือ IVR",
    "cms.qualitycheck.label.userGroup" : "กลุ่มผู้ใช้",
    "cms.indexmonitor.message.ivrTotalCallNum" : "จำนวนการโทรที่จัดสรรให้กับ IVR และให้บริการโดย IVR อย่างสมบูรณ์",
    "cms.callcenterinstanceslist.field.uapPassword" : "รหัสผ่านผู้ใช้ UAP CDE",
    "cms.fullscreen.field.refreshPeriod" : "ช่วงรีเฟรช",
    "cms.systemmonitor.field.callRate" : "อัตราการโทรเสร็จสมบูรณ์ของระบบ",
    "cms.report.field.billInfo2Field" : "รหัสเฉพาะของการโทรสองสาย",
    "cms.voiceinspection.field.preViewOutbound" : "ดูตัวอย่างโทรออก",
    "cms.report.field.uapIdField" : "หมายเลข UAP",
    "cms.vcallecenter.resetpwd.message.fail" : "ล้มเหลวในการรีเซ็ตรหัสผ่าน ตรวจสอบว่ารหัสผ่านเป็นไปตามกฎรหัสผ่านหรือไม่",
    "cms.report.message.mediaTypeDesc" : "ประเภทสื่อการโทร",
    "cms.report.message.avgQueueCountDesc" : "จํานวนเฉลี่ยของการโทรพร้อมกันที่มีอุปกรณ์บริการเป็นคิวทักษะ",
    "cms.report.message.invalidParam" : "พารามิเตอร์ไม่ถูกต้อง",
    "cms.report.field.deviceNoField" : "หมายเลขอุปกรณ์",
    "cms.callcenterinstanceslist.message.maxNumber" : "สามารถเพิ่มศูนย์โทรได้สูงสุด 10 แห่ง",
    "cms.report.field.avgAgentInCount" : "จํานวนเฉลี่ยของสายเรียกเข้าพร้อมกันของตัวแทน",
    "cms.agentMonitor.laber.notInTalking" : "เจ้าหน้าที่ไม่อยู่ในสาย กรุณารีเฟรชสถานะแล้วลองอีกครั้ง",
    "cms.report.field.maxQueueWaitTime" : "ระยะเวลาการรอสายสูงสุด",
    "cms.callcenter.message.deletefailed" : "การลบล้มเหลว",
    "cms.report.field.minIVRInCountField" : "จํานวนขั้นต่ําของสายเรียกเข้า IVR พร้อมกัน",
    "cms.developerlist.message.deleteConfirmMessage" : "ต้องการลบนักพัฒนาที่เลือกและกำหนดค่าที่เกี่ยวข้องใช่หรือไม่?",
    "cms.report.message.outAboundRingDesc" : "จำนวนการโทรออกที่ถูกปฏิเสธโดยลูกค้าและไม่ได้รับสายหลังจากเสียงเรียกเข้า",
    "cms.report.title.filteringHeader" : "หัวตารางตัวกรอง",
    "cms.export.field.unprocessed" : "ยังไม่ได้ดำเนินการ",
    "cms.vcallecenter.message.syncLoading" : "กําลังดําเนินการอยู่ โปรดลองอีกครั้งในภายหลัง",
    "cms.report.field.agentAlertingDurationField" : "ระยะเวลาการแจ้งเตือนการโทร",
    "cms.report.message.acdCallDesc" : "จำนวนการโทรเข้าและโทรออกที่เชื่อมต่อ",
    "cms.indexmonitor.field.skillAbandonIn30" : "วางสายใน 30 วินาที",
    "cms.report.message.outCallSuccNumberDesc" : "จํานวนการโทรออกอัตโนมัติและการโทรออกด้วยตนเองที่ได้รับการตอบรับจากฝ่ายที่เรียก ค่านี้คือผลรวมของการโทรออกด้วยตนเองที่เชื่อมต่อและการโทรออกที่เชื่อมต่อโดยอัตโนมัติโดยใช้ IVR",
    "cms.fullscreen.field.custom" : "การปรับแต่ง:",
    "cms.report.message.agentServiceDesc" : "จำนวนสายที่เจ้าหน้าที่ทำการรับสาย",
    "cms.calllink.field.calledBusy" : "คู่สายไม่ว่าง",
    "cms.sysparam.config.save" : "บันทึก",
    "cms.report.field.outCallNumField" : "โทรออก",
    "cms.report.field.holdTime" : "ระยะเวลาถือสาย",
    "cms.fullscreen.field.saveTheme" : "บันทึกผัง",
    "cms.calllink.field.unavailableNumber" : "ไม่ได้รับการจัดสรรหมายเลข",
    "cms.recorddrive.label.button_edit" : "แก้ไข",
    "cms.indexmonitor.field.agentOutboundConnectNum" : "การเชื่อมต่อการโทรออกด้วยตนเอง",
    "cms.indexmonitor.field.totalQueueCallNum" : "การโทรเข้าคิวทั้งหมด",
    "cms.fullscreen.field.chartName" : "ชื่อแผนภูมิ",
    "cms.report.message.totalLIVRServiceDesc" : "ระยะเวลาการโทร IVR ทั้งหมด หากการโทรเชื่อมต่อไปยัง IVR เป็นเวลาหลายครั้ง ระยะเวลาการให้บริการ IVR คือระยะเวลาการโทร IVR ทั้งหมด",
    "cms.sendnote.title.selectAgents" : "เลือกเจ้าหน้าที่",
    "cms.report.message.IVRCallOutSuccNumDesc" : "จํานวนสายโทรออก IVR ที่มีระยะเวลาการโทรมากกว่า 0",
    "cms.synchronize.message.syncStatusTwo" : "การซิงค์สําเร็จ",
    "cms.report.field.holdCount" : "ถือสาย",
    "cms.vcallecenter.label.inputnewpwd" : "กรอกรหัสผ่านใหม่",
    "cms.systemmonitor.field.talkingNums" : "เอเย่นต์พูด",
    "cms.indexmonitor.field.agentNum" : "จำนวนเจ้าหน้าที่",
    "cms.indexmonitor.field.totalLogoutNum" : "เจ้าหน้าที่ที่ออกจากระบบ",
    "cms.vcallecenter.title.resetpwd" : "ตั้งรหัสผ่านใหม่",
    "cms.recorddrive.label.button_cacel" : "ยกเลิก",
    "cms.indexmonitor.message.agentAbandonIn3NDesc" : "จํานวนการละทิ้งทั้งหมดที่มีระยะเวลาคําตอบนานกว่าหรือเท่ากับ 3 วินาที",
    "cms.indexmonitor.field.ivrInboundUserAbort" : "ปล่อยสายการโทรเข้า IVR",
    "cms.indexmonitor.field.agentConnectInThirty" : "โทรเชื่อมต่อด้วยตนเองใน 30 วินาที",
    "cms.report.field.submediatype.facebook" : "Facebook",
    "cms.indexmonitor.field.skillAbandonIn10" : "วางสายใน 10 วินาที",
    "cms.indexmonitor.field.skillAbandonIn20" : "วางสายใน 20 วินาที",
    "cms.indexmonitor.field.skillAbandonIn3Rate" : "วางสายใน 3 วินาที",
    "cms.calllink.field.doNotAnswer" : "ผู้ใช้บริการไม่ได้รับสาย",
    "cms.datasource.message.mysql8UrlTip" : "สตริงการเชื่อมต่อฐานข้อมูล: &#8220jdbc:mysql://ip:port/schema&#8220&#10;ip (ที่อยู่ IP) port (หมายเลข Port) schema (แผนผัง)",
    "cms.failurecall.message.person" : "สายที่ถูกยกเลิกระหว่างรอคิว",
    "cms.callcenterinstanceslist.message.deleteConfirmMessage" : "คุณต้องการลบศูนย์บริการที่เลือกและการกำหนดค่าที่เกี่ยวข้องกันใช่หรือไม่?",
    "cms.monitordetail.field.restcount" : "เวลาพัก",
    "cms.callcenterinstanceslist.field.ccGatewayIp" : "CC-IP เกตเวย์",
    "cms.vcallecenter.message.syncrolefail" : "ซิงโครไนซ์บทบาทล้มเหลว",
    "cms.callcenterinstanceslist.field.interfacePassword" : "รหัสผ่าน ConfigInterface(CCBCS)",
    "cms.report.field.IVRInSuccRateField" : "อัตราการเชื่อมต่อสายขาเข้า IVR",
    "cms.fullscreen.field.idleCountTimeDesc" : "จํานวนครั้งที่เจ้าหน้าที่อยู่ในสถานะว่างงาน",
    "cms.uapmonitor.labe.mention" : "1. หลังจากเปิดใช้งานฟังก์ชันการตรวจสอบ ตัวแทนที่มีสถานะการลงทะเบียนเทอร์มินัลไม่สอดคล้องกับสถานะการลงชื่อเข้าใช้ของเอเจนต์จะถูกบังคับให้ออกจากระบบเป็นระยะ,เจ้าหน้าที่ที่สามารถเช็คอินได้จํากัดเฉพาะเจ้าหน้าที่ที่ลงชื่อเข้าใช้เฉพาะคิวทักษะเสียงและวิดีโอเท่านั้น",
    "cms.report.field.internalCallTimeField" : "ระยะเวลาการเรียกภายใน",
    "cms.report.field.filetype" : "ส่งออกรูปแบบแฟ้ม",
    "cms.report.field.outSuccAnsRateField" : "อัตราการเชื่อมต่อการโทรออก",
    "cms.vcallecenter.label.tenantaccount" : "บัญชีผู้ดูแลระบบ",
    "cms.report.field.IVROutCallNum" : "Pure-IVR โทรออก",
    "cms.report.field.endAgentId" : "ID เจ้าหน้าที่-สิ้นสุด",
    "cms.calllink.title.callTrack" : "การโทรติดตาม",
    "cms.qualitycheck.label.editusergroupmember" : "แก้ไขสมาชิกกลุ่ม",
    "cms.callcenter.message.createsuccess" : "สร้างสำเร็จ",
    "cms.agentmonitor.label.alarmDetail" : "รายละเอียดการแจ้งเตือน",
    "cms.agentmonitor.label.mutilMediaMonitor": "การตรวจสอบเซสชันมัลติมีเดีย",
    "cms.agentmonitor.label.stopMutilMediaMonitor": "หยุดการตรวจสอบเซสชัน",
    "cms.reportsubscribe.field.tuesday" : "วันอังคาร",
    "cms.callcenterinstanceslist.message.voiceCallNumsError" : "จำนวนการโทรด้วยเสียงพร้อมกันสูงสุดต้องมากกว่าหรือเท่ากับผลรวมของจำนวนที่นั่ง (voice seats) และจำนวนช่อง IVR",
    "cms.calllink.field.noAnswerTransfer" : "การโทรถูกโอนเนื่องจากไม่ได้รับการตอบกลับจากเจ้าหน้าที่เป็นเวลานาน",
    "cms.report.field.utilizationRate" : "อัตราระยะเวลาสถานะการทำงาน",
    "cms.calllink.title.callTrace" : "ลิงค์การโทร",
    "cms.report.message.monthRangeCheck" : "เวลาเริ่มต้นและเวลาสิ้นสุดต้องอยู่ในเดือนเดียวกัน",
    "cms.agentmonitor.label.cancelsuccess" : "Cancellation successful.",
    "cms.uapmonitor.message.intervalvalidate" : "กรุณากรอกหมายเลขตั้งแต่ 300 ถึง 86400",
    "cms.qualitycheck.label.edit" : "แก้ไข",
    "cms.agentMonitor.label.forcesignout_error" : "เจ้าหน้าที่ลงชื่อออกล้มเหลว",
    "cms.report.field.auxTimeRateField" : "อัตราระยะเวลาพัก",
    "cms.qcrelationconf.field.qcAccountName" : "ชื่อผู้ตรวจสอบ",
    "cms.qualitycheck.label.save" : "บันทึก",
    "cms.report.field.year" : "ปี",
    "cms.systemparam.error.validate.ruleregexinvalid" : "ตรวจสอบข้อผิดพลาดทางไวยากรณ์ของกฎ",
    "cms.indexmonitor.field.skillConnectedRate" : "คำขอเชื่อมต่อการโทร",
    "cms.fullscreen.field.avgCallTimeDesc" : "ระยะเวลาการโทรเฉลี่ยของการโทรที่เชื่อมต่อของตัวแทน",
    "cms.report.field.minIVRCountField" : "จํานวนขั้นต่ําของการโทร IVR พร้อมกัน",
    "cms.report.message.ans20sDesc" : "เปอร์เซ็นต์ของจํานวนสายที่เชื่อมต่อด้วยตนเองใน 20 วินาทีของจํานวนสายที่เชื่อมต่อด้วยตนเองทั้งหมด",
    "cms.failurecall.field.waitTime" : "ระยะเวลาที่รอ",
    "cms.export.message.downloadSucceed" : "ดาวน์โหลดรายงานได้สําเร็จ",
    "cms.report.field.callEndTimeField" : "เวลาสิ้นสุดการโทร",
    "cms.qcrelationconf.field.addGroupMember" : "เพิ่มสมาชิกกลุ่ม",
    "cms.report.message.assistTimeDesc" : "ระยะเวลาของความช่วยเหลือภายใน",
    "cms.indexmonitor.field.agentName" : "ชื่อผู้เช่า",
    "cms.systemmonitor.label.thirtyminutes" : "30 นาที",
    "cms.synchronize.message.syncStatus" : "สถานะการซิงค์",
    "cms.report.field.hourandminute" : "ช่วงเวลา",
    "cms.report.field.acwRateField" : "อัตราระยะเวลาที่ใช้สรุปผลการสนทนา",
    "cms.agentMonitor.label.querynum_success" : "หมายเลขโทรศัพท์:",
    "cms.callcenterinstanceslist.message.passWordLengthValidateFailed" : "รหัสผ่านต้องมีอักขระ 8 ถึง 20 ตัว",
    "cms.report.message.IVRInSuccDesc" : "จํานวนสายโทรเข้า IVR ที่มีระยะเวลาการโทรมากกว่า 0",
    "cms.indexmonitor.message.skillAvgQueueAckDesc" : "ระยะเวลารอรับสายเฉลี่ย ระยะเวลารอสายเฉลี่ย (การเข้าคิวและระยะเวลาเรียกเข้า) ของสายทั้งหมด",
    "cms.report.message.minQueueInCountDesc" : "จํานวนขั้นต่ําของสายเรียกเข้าพร้อมกันที่มีอุปกรณ์บริการเป็นคิวทักษะ",
    "cms.indexmonitor.message.agentAbandonIn60NDesc" : "จํานวนการละทิ้งทั้งหมดที่มีระยะเวลาคําตอบนานกว่าหรือเท่ากับ 60 วินาที",
    "cms.indexmonitor.field.skillOverAgent" : "โทร Overflow ไปยังเจ้าหน้าที่",
    "cms.report.label.header" : "ตั้งค่าส่วนหัวของตาราง",
    "cms.report.field.outWaitAnsNumField" : "การโทรออกด้วยตนเอง",
    "cms.report.field.accessNo" : "รหัสการเข้าถึง:",
    "cms.report.field.maxIVRCountField" : "จํานวนสูงสุดของการโทร IVR พร้อมกัน",
    "cms.agentmonitor.label.insets" : "แทรก",
    "cms.agentmonitor.label.enterID" : "ป้อน ID เจ้าหน้าที่",
    "cms.report.title.saveError" : "ผิดพลาด",
    "cms.report.field.internalCallCountField" : "การเรียกภายใน",
    "cms.qcrelationconf.field.workNo" : "ID เจ้าหน้าที่",
    "cms.report.field.acwTime" : "ระยะเวลาที่ใช้สรุปผลการสนทนาทั้งหมด",
    "cms.report.field.maxIVROutCountField" : "จํานวนสูงสุดของการโทรออก IVR พร้อมกัน",
    "cms.report.field.inOccupyNumField" : "คำขอบริการ",
    "cms.report.field.waitBeginField" : "รอเวลาเริ่มต้น",
    "cms.callcenter.message.createfailed" : "การสร้างล้มเหลว",
    "cms.report.field.call4RateField" : "อัตราการเชื่อมต่อการโทรเข้าด้วยตนเองใน 20 วินาที",
    "cms.agentmonitor.label.intercept" : "การสกัดกั้น",
    "cms.report.message.hangUpModeDesc" : "แขวนวิธี",
    "cms.sendnote.message.tipsError" : "ส่งบันทึกล้มเหลว",
    "cms.indexmonitor.message.agentConnectedIn60NDesc" : "จํานวนการโทรทั้งหมดที่มีระยะเวลาการรับสายนานกว่าหรือเท่ากับ 60 วินาที",
    "cms.report.field.succWaitAnsRate" : "ระยะเวลาเข้าคิวโดยเฉลี่ย",
    "cms.reportsubscribe.field.templateName" : "ชื่อแม่แบบอีเมล",
    "cms.agentMonitor.label.cancelInsert_success" : "ยกเลิกการแทรกสำเร็จแล้ว",
    "cms.agentmonitor.label.header" : "ตั้งค่าส่วนหัวของตาราง",
    "cms.report.message.validateFailed" : "ล้มเหลวในการตรวจสอบการส่งออกรายงาน",
    "cms.report.message.outAvgAnsTimeDesc" : "ระยะเวลาเสียงเรียกเข้าโดยเฉลี่ยของการโทรออก = ระยะเวลาเสียงเรียกเข้าทั้งหมดของการโทรออก/จำนวนครั้งที่ตอบรับ",
    "cms.report.field.agentOutSucc" : "การโทรออกด้วยตนเองที่เชื่อมต่อ",
    "cms.indexmonitor.message.totalBusyNum" : "จำนวนของเจ้าหน้าที่อยู่ในสถานะไม่ว่าง",
    "cms.reportsubscribe.message.modifyStatus" : "คุณแน่ใจที่จะปรับเปลี่ยนค่าสถานะหรือไม่?",
    "cms.report.message.queryTimeCheck" : "เวลาต้องไม่เร็วกว่า {0}",
    "cms.report.field.maxAgentInCount" : "จํานวนสูงสุดของสายเรียกเข้าของเจ้าหน้าที่พร้อมกัน",
    "cms.systemparam.error.validate.notmatchrulemaxlength" : "พารามิเตอร์อินพุตเกินความยาวสูงสุด 0",
    "cms.agentMonitor.label.intercept_error" : "การสกัดกั้นล้มเหลว",
    "cms.report.field.notConnected" : "ยกเลิกการเชื่อมต่อ",
    "cms.indexmonitor.message.queueOutCallCount" : "จำนวนการโทรออกที่เข้าคิวในลำดับทักษะ(ไม่มีเซสชันมัลติมีเดีย)",
    "cms.report.field.ackBeginField" : "เวลาเริ่มต้นการตอบสนอง",
    "cms.indexmonitor.field.ccName" : "ชื่อศูนย์บริการลูกค้า",
    "cms.indexmonitor.field.queueInCallCount" : "จำนวนการโทรเข้าในคิวออนไลน์",
    "cms.systemmonitor.field.ok" : "ตกลง",
    "cms.agentMonitor.label.success" : "สำเร็จ",
    "cms.report.field.busyCountField" : "ช่วงที่ไม่ว่าง",
    "cms.agentMonitor.label.switchNotice" : "ใช้ฟังก์ชันนี้เมื่อเอเจนต์ปัจจุบันอยู่ในสถานะการฟังหรือการพรวดพราด",
    "cms.report.field.endTimeField" : "เวลาสิ้นสุดการโทร",
    "cms.fullscreen.message.numberCheck" : "ค่าต้องเป็น 0, จํานวนเต็มบวก, หรือทศนิยมบวกที่มีทศนิยมสูงสุดสองตําแหน่ง",
    "cms.report.field.sysSuccRateField" : "อัตราสำเร็จการโทรเข้า",
    "cms.recorddrive.field.configSftpPath" : "เส้นทางไฟล์",
    "cms.callcenterinstanceslist.field.open" : "เปิดการใช้งาน",
    "cms.report.title.abandonRingMonitor" : "สรุปผลการตรวจสอบสายที่ไม่ได้รับการบริการระหว่างมีเสียงเรียกเข้า",
    "cms.agentmonitor.label.busy" : "การบังคับให้ไม่ว่าง",
    "cms.report.field.inCallSuccRateField" : "อัตราการเชื่อมต่อสายขาเข้า",
    "cms.skilladjustment.message.tipsOutSide" : "สามารถปรับแต่งโดยเจ้าหน้าที่้ได้สูงสุด 10 คนต่อครั้ง",
    "cms.report.label.reset" : "รีเซ็ต",
    "cms.systemmonitor.field.ideaAgentNum" : "เอเย่นต์ว่าง",
    "cms.callcenterinstanceslist.message.updateFailed" : "ปรับเปลี่ยนศูนย์บริการลูกค้าล้มเหลว",
    "cms.agentmonitor.label.terminalStatus" : "สถานะการลงทะเบียนเทอร์มินัล",
    "cms.report.field.outInvalidCall" : "การโทรออกไม่ถูกต้อง",
    "cms.qcrelationconf.placeholder.pleaseInput" : "โปรดใส่",
    "cms.callcenter.field.savesuccess" : "สำเร็จ",
    "cms.monitordetail.field.calling" : "การโทร",
    "cms.vdnconfiguration.field.ok" : "ตกลง",
    "cms.agentMonitor.label.forcebusy_success_calling" : "เจ้าหน้าที่ถูกบังคับให้ตั้งค่าสถานะเป็นไม่ว่างสำเร็จ หลังสิ้นสุดการโทร เจ้าหน้าที่จะเข้าสู่สถานะไม่ว่าง",
    "cms.report.field.startMonth" : "เดือนเริ่มต้น",
    "cms.indexmonitor.message.skillAvgAbandonAckDesc" : "ระยะเวลาเสียงเรียกเข้าโดยเฉลี่ยของสายที่ไม่ได้รับทั้งหมด",
    "cms.report.label.search" : "ค้นหา",
    "cms.report.field.succAnsRate" : "อัตราการเชื่อมต่อ",
    "cms.systemmonitor.field.onlineAgentNums" : "ตัวแทนออนไลน์",
    "cms.report.field.internalTransferCount" : "การโอนภายใน",
    "cms.report.field.ivrtrafficreport.avgivrservice" : "เวลาให้บริการ IVR โดยเฉลี่ย",
    "cms.report.field.inVdnAvgTimeField" : "ระยะเวลาการโทรขาเข้าเฉลี่ยใน VDN",
    "cms.recorddrive.label.button_complete" : "เสร็จสมบูรณ์",
    "cms.indexmonitor.message.agentConnectedOut10NDesc" : "จํานวนสายที่เชื่อมต่อทั้งหมดที่มีระยะเวลารับสายนานกว่า 10 วินาที",
    "cms.qualitycheck.message.adjustskill" : "ลำดับทักษะของคุณถูกปรับแต่งเป็น {skillList} ชั่วคราว ทักษะเดิมจะเรียกคืนหลังจากลงชื่อเข้าใช้อีกครั้ง",
    "cms.report.field.callAbandonedRing" : "จํานวนสายเรียกเข้าด้วยตนเองที่หายไป",
    "cms.report.field.acdCallField" : "การโทรที่เชื่อมต่อทั้งหมด",
    "cms.report.field.succWaitAnsRateField" : "ระยะเวลาเข้าคิวโดยเฉลี่ย(วินาที)",
    "cms.recorddrive.title.sftpIdentityCheck" : "การยืนยันตัวตน",
    "cms.report.field.transferIvrCountField" : "การโอนสายด้วยตนเองไปยัง IVR",
    "cms.report.field.callIdNumField" : "หมายเลขลําดับ CDR",
    "cms.report.message.call4RateDesc" : "เปอร์เซ็นต์ของจำนวนคำขอโทรที่เชื่อมต่อด้วยตนเองใน 20 วินาทีของจำนวนการโทรทั้งหมดที่ร้องขอบริการด้วยตนเอง",
    "cms.report.message.agentOutSuccRateDesc" : "เปอร์เซ็นต์ของการโทรออกด้วยตนเองที่เชื่อมต่อไปยังการโทรออกด้วยตนเองทั้งหมด",
    "cms.indexmonitor.title.ivrDayTrend" : "แนวโน้ม IVR ของวันนี้",
    "cms.indexmonitor.message.ivrInboundConnectRate" : "เปอร์เซ็นต์ของจำนวนการโทรเข้า IVR ที่เชื่อมต่อในจำนวนคำขอการโทรเข้าระบบ IVR",
    "cms.indexmonitor.field.totalAgentConnectCallNum" : "จํานวนตัวแทนในสถานะการพูดคุย",
    "cms.callcenterinstanceslist.field.nodemodesingle" : "โหมดเดียว",
    "cms.indexmonitor.field.durationAvgWait" : "ระยะเวลารอสายโทรเข้าเฉลี่ย",
    "cms.indexmonitor.label.previous" : "ก่อนหน้า",
    "cms.indexmonitor.field.agentConnectAfterInSixty" : "การโทรเชื่อมต่อด้วยตนเองหลังจาก 60 วินาที",
    "cms.callcenterselect.title.selectCcName" : "เลือกศูนย์บริการลูกค้า",
    "cms.report.field.awarnessRate" : "อัตราความสำเร็จของการโทรเข้าด้วยตนเอง",
    "cms.vcallecenter.message.syncskillfail" : "ซิงโครไนซ์ทักษะล้มเหลว",
    "cms.indexmonitor.field.skillOnlineWaitCall" : "การโทรเข้าคิวออนไลน์",
    "cms.indexmonitor.message.accesscodeCalloutNum" : "จำนวนการโทรออกโดยใช้หมายเลขโทรภายในหนึ่งชั่วโมง",
    "cms.agentmonitor.label.qcstate_error" : "การยกเลิกล้มเหลว ยังไม่ได้ดําเนินการตรวจสอบความถูกต้องกับตัวแทนที่เลือกไว้",
    "cms.datasource.message.ccDsRelationExist" : "แหล่งข้อมูลได้รับการอ้างอิงใน Call Center",
    "cms.report.title.callCenterAccessReport" : "รายงานสถิติประสิทธิภาพ VDN ตามรหัสการเข้าุถึง",
    "cms.report.field.alertingTimeField" : "เวลาแจ้งเตือนการโทร",
    "cms.report.message.avgIVROutCountDesc" : "จํานวนเฉลี่ยของการโทรออกพร้อมกันที่มีอุปกรณ์บริการคือ IVR",
    "cms.report.field.onehour" : "1 ชั่วโมง",
    "cms.indexmonitor.label.next" : "ต่อไป",
    "cms.callcenterinstanceslist.field.cmsAuthName" : "บัญชีการตรวจสอบความถูกต้อง CCDIS",
    "cms.agent.message.modifypasswordhintdetail" : "ป้อนรหัสผ่าน",
    "cms.report.field.minIVRCount" : "จํานวนขั้นต่ําของการโทร IVR พร้อมกัน",
    "cms.agentMonitor.label.reset" : "รีเซ็ต",
    "cms.report.message.inCallSuccNumDesc" : "สายเรียกเข้าที่เชื่อมต่อกับตัวแทน",
    "cms.report.message.sysLostCallsDesc" : "จำนวนการโทรเข้าที่ล้มเหลว = จำนวนการโทรเข้า – จำนวนการโทรเข้าที่สำเร็จ",
    "cms.indexmonitor.message.skillOverAgentDesc" : "จำนวนการโทรถูกโอนจากลำดับทักษะเดิมไปยังเจ้าหน้าที่คนอื่นเนื่องจาก overflow",
    "cms.report.field.agentId" : "ID เจ้าหน้าที่",
    "cms.report.message.logoutCountDesc" : "จำนวนครั้งลงชื่อออก",
    "cms.report.field.sysSuccRate" : "อัตราความสำเร็จในการโทรเข้า",
    "cms.reportsubscribe.field.enable" : "เผยแพร่แล้ว",
    "cms.report.field.IVROutSuccRateField" : "อัตราการเชื่อมต่อการโทรขาออก Pure-IVR",
    "cms.systemparam.error.validate.notmatchruleip" : "โปรดป้อนที่อยู่ IPV4 หรือ IPV6 ที่ถูกต้อง",
    "cms.qualitycheck.label.groupname" : "ชื่อกลุ่ม",
    "cms.report.field.submediatype.wechat" : "WeChat",
    "cms.report.field.acwCount" : "เวลาที่ใช้สรุปผลการสนทนา",
    "cms.report.field.uvidField" : "ID การเข้าถึงของผู้ใช้",
    "cms.indexmonitor.field.agentInboundNum" : "การโทรเข้าด้วยตนเอง",
    "cms.indexmonitor.field.skillAbandon" : "คำขอสายที่หลุดไป",
    "cms.report.field.mediatype.H5Vedio" : "วิดีโอ H5",
    "cms.qualitycheck.title.saveError" : "ผิดพลาด",
    "cms.indexmonitor.message.skillAbandonInNRateDesc" : "เปอร์เซ็นต์ของจำนวนคำขอที่วางสายด้วยตนเองใน Ns ในจำนวนการโทรทั้งหมดที่ร้องขอการบริการผู้ใช้",
    "cms.voiceinspection.field.download" : "ดาวน์โหลด",
    "cms.monitordetail.field.holddurationavg" : "ระยะเวลาถือสายรอโดยเฉลี่ย",
    "cms.report.field.busyCount" : "ช่วงที่ไม่ว่าง",
    "cms.indexmonitor.message.errorAgentInfo" : "โปรดเลือกตัวแทนอย่างน้อยหนึ่งคน",
    "cms.fullscreen.field.oneMin" : "1 นาที",
    "cms.report.message.avgIVRCountDesc" : "จํานวนเฉลี่ยของการโทรพร้อมกันที่มีอุปกรณ์บริการคือ IVR",
    "cms.report.field.agentOutBoundField" : "การโทรออกด้วยตนเอง",
    "cms.systemmonitor.field.queryTime" : "เวลาสอบถาม",
    "cms.report.field.byDay" : "รายงานประจำวัน",
    "cms.calllink.field.cdnDevice" : "อุปกรณ์ CDN",
    "cms.report.message.userNoAnswerNumDesc" : "จํานวนสายที่ไม่ได้รับการตอบรับเนื่องจากหมดเวลาของเสียงเรียกเข้า",
    "cms.report.message.acwTimeRateDesc" : "อัตราระยะเวลาที่ใช้สรุปผลการสนทนา = (ระยะเวลาที่ใช้สรุปผลการสนทนาทั้งหมด / ระยะเวลาการทำงานทั้งหมด) x 100",
    "cms.agentMonitor.label.details" : "รายละเอียด",
    "cms.voiceinspection.message.downloadFailed" : "ดาวน์โหลดการบันทึกล้มเหลว",
    "cms.sendnote.message.checkInput" : "อักขระไม่ถูกต้อง",
    "cms.report.field.acwCountField" : "เวลาที่ใช้สรุปผลการสนทนา",
    "cms.calllink.msg.calleePlaceHolder" : "ป้อนเลขเบอร์โทร",
    "cms.systemmonitor.label.noncommercial" : "การทดลองใช้งานเชิงพาณิชย์",
    "cms.report.message.dataOverError" : "จํานวนข้อมูล {0} ที่สอบถามเกิน 10000",
    "cms.uapmonitor.field.callOperator" : "การดําเนินการ",
    "cms.report.field.calleeNo" : "รหัสการเข้าถึง",
    "cms.recorddrive.placeholder.deleteRecorddrive" : "โปรดเลือก drive letter เพื่อทำการลบ",
    "cms.indexmonitor.field.skillAvgConnected" : "ระยะเวลาการโทรโดยเฉลี่ย",
    "cms.datasource.field.dbName" : "ชื่อแหล่งข้อมูล",
    "cms.indexmonitor.field.totalConnectCallNum" : "การโทรที่เชื่อมต่อทั้งหมด",
    "cms.qualitycheck.label.qualityInspectionGroup" : "กลุ่มตรวจสอบคุณภาพ",
    "cms.vcallecenter.title.tips" : "ข้อมูล",
    "cms.report.message.muteCountDesc" : "จำนวนครั้งที่เงียบ",
    "cms.callcenterinstanceslist.message.resourceError" : "ข้อมูลทรัพยากรที่ปรับเปลี่ยนเล็กกว่าจำนวนศูนย์บริการจริง",
    "cms.report.message.minAgentInCountDesc" : "จํานวนขั้นต่ําของสายเรียกเข้าพร้อมกันที่มีอุปกรณ์บริการเป็นตัวแทน",
    "cms.indexmonitor.field.SkillAbandonRate" : "คำขอสายที่หลุดไป",
    "cms.voiceinspection.field.callbackRequest" : "คำขอโทรกลับ",
    "cms.qualitycheck.label.create" : "สร้าง",
    "cms.report.field.agentHangNumCti" : "เจ้าหน้าที่ที่วางสาย",
    "cms.agentmonitor.label.all" : "ทั้งหมด",
    "cms.qcrelationconf.field.groupType" : "ประเภทกลุ่มผู้ใช้",
    "cms.report.field.startWeek" : "สัปดาห์เริ่มต้น",
    "cms.indexmonitor.title.skillDayTrend" : "แนวโน้มเกี่ยวกับลำดับทักษะของวันนี้",
    "cms.indexmonitor.title.skillQueueStatistics" : "สถิติของคิวทักษะวัน",
    "cms.indexmonitor.message.skillMaxQueueAckDesc" : "ระยะเวลาการรอสายต่ำสุด (ระยะเวลาในการรอคิวและเสียงเรียกเข้า) ของการโทรทั้งหมด",
    "cms.report.title.dualCallDetail" : "รายละเอียดการโทรแบบคู่",
    "cms.report.message.systemInBoundDesc" : "จำนวนการโทรเข้าทั้งหมด ไม่รวมการโทรเข้าภายใน",
    "cms.report.field.ackEndField" : "เวลาสิ้นสุดการตอบ",
    "cms.report.message.agentLoginNumDesc" : "จำนวนเจ้าหน้าที่ผู้รับสายการโทรเข้า",
    "cms.report.title.callCenterAccessTrend" : "แนวโน้มของ VDN ประสิทธิภาพตามรหัสการเข้าถึง",
    "cms.report.field.minIVROutCount" : "จํานวนการโทรออก IVR พร้อมกันขั้นต่ํา",
    "cms.fullscreen.message.saveLayouts" : "ไม่ได้บันทึกผัง ทำการบันทึกก่อน",
    "cms.developerlist.message.deletesuccess" : "ลบสำเร็จ",
    "cms.indexmonitor.message.agentConnectIn60Rate" : "ร้อยละของจํานวนการโทรที่เชื่อมต่อด้วยตนเองภายใน 60 วินาที (รวม 60 วินาที) ต่อจํานวนการโทรทั้งหมด",
    "cms.report.message.leaveReasonDesc" : "ออกจากการโทร เหตุผลของอุปกรณ์",
    "cms.vcallecenter.label.renewpwd" : "ยืนยันรหัสผ่าน",
    "cms.report.field.avgQueueInCountField" : "จํานวนเฉลี่ยของสายเรียกเข้าพร้อมกันในคิว",
    "cms.qcrelationconf.message.specialChar" : "ตัวแปรไม่ถูกต้อง",
    "cms.fullscreen.field.add" : "สร้าง",
    "cms.voiceinspection.field.textChat" : "แชทข้อความ",
    "cms.report.field.avgAnsTimeField" : "ระยะเวลาเข้าคิวโดยเฉลี่ยการโทรที่เชื่อมต่อ",
    "cms.uapmonitor.field.callStatus" : "สถานะการลงทะเบียน",
    "cms.fullscreen.message.selectAgentMax" : "สามารถเลือกเจ้าหน้าที่ได้สูงสุด 5 คน",
    "cms.vcallecenter.label.resetpwd" : "การรีเซ็ตรหัสผ่านบัญชีผู้ดูแลระบบ",
    "cms.voiceinspection.message.downloadSucceed" : "ดาวน์โหลดการบันทึกสำเร็จแล้ว",
    "cms.report.message.alertingTimeDesc" : "เวลาแจ้งเตือนการโทร",
    "cms.callcenterinstanceslist.field.single" : "โหมดเดียว",
    "cms.agentmonitor.label.release" : "บังคับปล่อยสาย",
    "cms.report.field.submediatype.sms" : "SMS",
    "cms.qualitycheck.label.configQcInformation" : "กำหนดค่าการตรวจสอบคุณภาพข้อมูล",
    "cms.callcenterinstanceslist.label.delete" : "ลบ",
    "cms.qcrelationconf.title.success" : "สำเร็จ",
    "cms.agentmonitor.label.showAlarmAgentNo" : "ID ตัวแทน",
    "cms.agentmonitor.label.offline" : "ออฟไลน์",
    "cms.report.message.alertingDurationDesc" : "ระยะเวลาการแจ้งเตือนที่โทรแล้ว",
    "cms.voiceinspection.message.errorContent" : "โหลดทรัพยากรล้มเหลว ตรวจสอบการกำหนดค่า",
    "cms.report.field.confirm" : "ตกลง",
    "cms.callcenterinstanceslist.message.ccIdError" : "มีการใช้หมายเลขของศูนย์บริการแล้ว",
    "cms.report.message.IVRCallOutSuccRateDesc" : "เปอร์เซ็นต์ของการโทรขาออก IVR ที่เชื่อมต่อไปยังคําขอการโทรขาออก IVR ทั้งหมด",
    "cms.report.field.waitCauseField" : "เหตุผลที่รอคอย",
    "cms.report.message.failQueueWaitRateDesc" : "ระยะเวลาเสียงเรียกเข้าโดยเฉลี่ยของสายที่ไม่ได้รับทั้งหมด",
    "cms.indexmonitor.message.agentAbandonOut60NDesc" : "จํานวนรวมของสายที่ถูกทิ้งซึ่งมีระยะเวลาการรับสายนานกว่า 60 วินาที",
    "cms.indexmonitor.title.error" : "ผิดพลาด",
    "cms.sysparam.config.search" : "สอบถาม",
    "cms.datasource.message.already" : "แหล่งข้อมูลมีอยู่แล้ว อย่าเพิ่มอีก",
    "cms.agentMonitor.laber.hasbeenCheck" : "เจ้าหน้าที่ถูกตรวจสอบแล้ว",
    "cms.agentMonitor.laber.hasbeenMediaCheck": "เจ้าหน้าที่ถูกตรวจสอบโดยเซสชันมัลติมีเดียแล้ว",
    "cms.agentMonitor.laber.stopMediaMonitor": "หยุดการตรวจสอบเซสชันมัลติมีเดียแล้ว",
    "cms.callcenterinstanceslist.field.uapPasswordConfirm" : "ยืนยันรหัสผ่าน UAP CDE",
    "cms.agentmonitor.label.showAlarmDetail" : "ดูรายละเอียดการแจ้งเตือน",
    "cms.report.field.utilizationRateField" : "อัตราระยะเวลาสถานะการทำงาน",
    "cms.report.field.calleeNoField" : "หมายเลขที่เรียก",
    "cms.agentMonitor.label.talk_error" : "เจ้าหน้าที่ที่เลือกไม่อยู่สถานะการพูดคุย",
    "cms.calllink.field.agentRelease" : "เจ้าหน้าที่ยกเลิกการโทร",
    "cms.callcenterinstanceslist.field.normalStatus" : "ปกติ",
    "cms.agentmonitor.label.alarmState" : "สถานะการแจ้งเตือน",
    "cms.indexmonitor.message.totalAgentCallNumDesc" : "จํานวนสายที่เชื่อมต่อทั้งหมด (ระยะเวลาสถิติ: การลงชื่อเข้าใช้ปัจจุบัน)",
    "cms.report.message.acwRateDesc" : "อัตราระยะเวลาที่ใช้สรุปผลการสนทนา = (ระยะเวลาที่ใช้สรุปผลการสนทนาทั้งหมด / ระยะเวลาการทำงานทั้งหมด) x 100",
    "cms.report.message.internalCallTimeDesc" : "ระยะเวลาการเรียกภายใน",
    "cms.developer.message.addDeveloper" : "เพิ่มข้อมูลนักพัฒนา",
    "cms.synchronize.message.notConfigured" : "ไม่ได้กำหนดค่า",
    "cms.agentmonitor.label.monitorState" : "สถานะการตรวจสอบ",
    "cms.indexmonitor.field.agentConnectInSixty" : "โทรเชื่อมต่อด้วยตนเองใน 60 วินาที",
    "cms.callcenterinstanceslist.field.close" : "ปิดการใช้งาน",
    "cms.report.message.onlyIVRServiceDesc" : "จำนวนสายโทรเข้าที่ประมวลผลด้วย IVR โดยไม่ผ่านเจ้าหน้าที่",
    "cms.callcenterinstanceslist.title.success" : "สำเร็จ",
    "cms.callcenterinstanceslist.message.uapNode2Info" : "การกําหนดค่า UAP Node 2",
    "cms.voiceinspection.field.endTime" : "เวลาสิ้นสุดการบันทึก",
    "cms.report.message.outOccupyNumDesc" : "จำนวนการโทรโดยเจ้าหน้าที่ไปยังลูกค้า",
    "cms.report.field.assistsCountField" : "คำขอความช่วยเหลือภายใน",
    "cms.export.message.addExportTaskSuccess" : "งานส่งออกใหม่สำเร็จ",
    "cms.report.field.inVdnAvgTime" : "ค่าเฉลี่ยระยะเวลาการโทรขาเข้าใน VDN",
    "cms.export.message.passValidateFailed" : "8-12 อักขระ, ซึ่งต้องมีตัวอักษร , ตัวเลข , อักขระพิเศษ , อักขระพิเศษไม่รวม |;&$-<>/\\`!#(){} ช่องว่าง, อักขระและแท็บบรรทัดใหม่",
    "cms.report.field.subMediaTypeField" : "ประเภทสื่อย่อย",
    "cms.vdnconfiguration.field.defaultCallCenter" : "Call Center ที่ VDN ทํางาน",
    "cms.indexmonitor.message.agentAbandonIn30Rate" : "เปอร์เซ็นต์ของสายที่ถูกทิ้งภายใน 30 วินาทีต่อสายที่ถูกทิ้งทั้งหมด",
    "cms.uapmonitor.title.regist" : "สถานะการลงทะเบียนอุปกรณ์",
    "cms.agentMonitor.label.insets_error" : "การแทรกล้มเหลว",
    "cms.indexmonitor.field.ivrDurationAvgConnect" : "ระยะเวลาการโทร IVR โดยเฉลี่ย",
    "cms.indexmonitor.title.agentDayTrend" : "แนวโน้มของเอเย่นต์วันนี้",
    "cms.datasource.field.passwordConfirm" : "ยืนยันรหัสผ่าน",
    "cms.qcrelationconf.placeholder.accountCode" : "ป้อนบัญชีธุรกิจ",
    "cms.report.field.connected" : "เชื่อมต่อแล้ว",
    "cms.export.field.addExportTask" : "งานส่งออกใหม่",
    "cms.agentmonitor.label.listenedAndInserted" : "กําลังตรวจสอบ",
    "cms.report.message.callTypeDesc" : "ประเภทการโทร",
    "cms.calllink.field.noOnDutyTransfer" : "การโทรถูกโอนสายเนื่องจากไม่มีคนปฏิบัติหน้าที่",
    "cms.indexmonitor.field.totalAwcNum" : "เจ้าหน้าที่อยู่ในสถานะการสรุปผลการสนทนา",
    "cms.indexmonitor.field.agentConnectAfterInTen" : "การโทรเชื่อมต่อด้วยตนเองหลังจาก 10 วินาที",
    "cms.report.field.ringTimeField" : "ระยะเวลาเสียงเรียกเข้าทั้งหมด",
    "cms.reportsubscribe.field.status" : "สถานะ",
    "cms.vdnconfiguration.field.cancel" : "ยกเลิก",
    "cms.report.field.agentOutSuccRateField" : "อัตราการเชื่อมต่อการโทรออกด้วยตนเอง",
    "cms.uapmonitor.labe.openmonitor" : "เปิดใช้งานการตรวจสอบ",
    "cms.indexmonitor.field.agentConnectInThree" : "การโทรเชื่อมต่อด้วยตนเองใน 3 วินาที",
    "cms.sendnote.field.cancel" : "ยกเลิก",
    "cms.callcenterinstanceslist.message.cleanKafka" : "=ล้างการกําหนดค่าโหนด KAFKA",
    "cms.report.message.outCallTimeDesc" : "ระยะเวลาการโทรออกทั้งหมดระหว่างเจ้าหน้าที่และลูกค้า",
    "cms.export.message.nocount" : "ไม่พบข้อมูลที่ตรงตามเงื่อนไข",
    "cms.report.message.succAnsRateDesc" : "เปอร์เซ็นต์ของจำนวนการร้องขอการโทรเข้าด้วยตนเองที่เชื่อมต่อในจำนวนการโทรเข้าด้วยตนเองที่ร้องขอบริการ",
    "cms.report.message.internalTransferCountDesc" : "จำนวนครั้งในการโอนภายใน",
    "cms.fullscreen.message.addVdnMost" : "สามารถเลือกผู้เช่าได้สูงสุด 10 ราย",
    "cms.indexmonitor.message.totalLoginNumDesc" : "จำนวนเจ้าหน้าที่ที่ลงชื่อเข้าใช้ศูนย์บริการและจัดการเฉพาะการโทรเข้า",
    "cms.vcallcenter.message.unknowcause" : "ไม่ทราบสาเหตุ",
    "cms.systemmonitor.field.ccName" : "ชื่อศูนย์บริการลูกค้า",
    "cms.callcenterinstanceslist.field.cmsPort" : "พอร์ต CMS LB",
    "cms.agentmonitor.label.queueAdjustment" : "ปรับคิว",
    "cms.qualitycheck.title.delete" : "การลบล้มเหลว",
    "cms.report.field.unspecifiedUserLevel" : "ยังไม่ระบุ",
    "cms.qualitycheck.label.userGroupMemberConfig" : "กําหนดค่าสมาชิกกลุ่มผู้ใช้",
    "cms.report.message.avgQueueInCountDesc" : "จํานวนเฉลี่ยของสายเรียกเข้าพร้อมกันที่มีอุปกรณ์บริการเป็นคิวทักษะ",
    "cms.voiceinspection.field.nonRealTimeCall" : "การโทรแบบไม่เรียลไทม์",
    "cms.report.field.mediatype.vedio" : "วิดีโอ",
    "cms.monitordetail.field.sipNumber" : "โทรแบบลงชื่อเข้าใช้",
    "cms.calllink.title.mediaType" : "ประเภทสื่อ",
    "cms.qualitycheck.label.queryQcRelation" : "ความสัมพันธ์การตรวจสอบแบบสอบถาม",
    "cms.indexmonitor.message.queueInCallCount" : "จำนวนสายเรียกเข้าที่เข้าคิวในลำดับทักษะ(ไม่มีเซสชันมัลติมีเดีย)",
    "cms.report.field.avgInRingTimeField" : "ระยะเวลาการโทรขาเข้าเฉลี่ยของเทอร์มินัล",
    "cms.report.field.maxQueueInCountField" : "จํานวนสูงสุดของสายเรียกเข้าพร้อมกันในคิว",
    "cms.report.field.avgIVRInCount" : "จํานวนเฉลี่ยของสายเรียกเข้า IVR พร้อมกัน",
    "cms.fullscreen.field.color" : "สี",
    "cms.agentmonitor.label.possessingSkills" : "คิวลงชื่อเข้าใช้",
    "cms.agentMonitor.label.queryNum" : "ตั้งค่าหมายเลขลงชื่อเข้าใช้",
    "cms.agentMonitor.label.queryNumSuccess": "ตั้งค่าหมายเลขการลงชื่อเข้าใช้สําเร็จ",
    "cms.agentMonitor.label.queryNumFailed": "ตั้งค่าหมายเลขการลงชื่อเข้าใช้ล้มเหลว",
    "cms.calllink.field.callRejected" : "ฝ่ายที่ถูกเรียกปฏิเสธสาย",
    "cms.report.title.vdnSystemPerformanceTrend" : "แนวโน้มประสิทธิภาพของระบบ",
    "cms.report.message.maxIVRCountDesc" : "จํานวนสูงสุดของการโทรพร้อมกันที่มีอุปกรณ์บริการคือ IVR",
    "cms.datasource.field.cmsDatabase" : "ฐานข้อมูลรายงาน CMS",
    "cms.report.field.createTimeField" : "สร้างเวลา",
    "cms.indexmonitor.field.abandonInSixty" : "วางสายใน 60 วินาที",
    "cms.vdnconfiguration.label.configure" : "กําหนดค่า",
    "cms.callcenterinstanceslist.label.assignDeveloper" : "นักพัฒนาที่เกี่ยวข้อง",
    "cms.qualitycheck.label.unselectedGroup" : "กลุ่มผู้ใช้ที่ไม่ได้เลือก",
    "cms.report.title.filteringIndicator" : "การบ่งชี้ตัวกรอง",
    "cms.callcenterinstanceslist.field.kafkaPushSwitch" : "KAFKA ผลักดันข้อมูล",
    "cms.datasource.label.edit" : "ปรับเปลี่ยน",
    "cms.skilladjustment.title.tips" : "ข้อมูล",
    "cms.report.message.calleeNoDesc" : "หมายเลขที่เรียก",
    "cms.datasource.field.isBackup" : "ระบุว่าเป็นฐานข้อมูลสํารองหรือไม่",
    "cms.agentMonitor.label.batchSelectAgent" : "คุณสามารถเลือกเจ้าหน้าที่ได้เพียงคนเดียว",
    "cms.report.message.avgCallInTimeDesc" : "ระยะเวลาการโทรโดยเฉลี่ย ระยะเวลาเฉลี่ยของการโทรเข้าด้วยตนเองที่เชื่อมต่ออยู่ = ระยะเวลาของการโทรเข้าด้วยตนเองที่เชื่อมต่ออยู่/จํานวนการโทรเข้าด้วยตนเองที่เชื่อมต่ออยู่",
    "cms.indexmonitor.message.agentAbandonIn5NDesc" : "จํานวนการละทิ้งทั้งหมดที่มีระยะเวลาคําตอบนานกว่าหรือเท่ากับ 5 วินาที",
    "cms.report.field.agentHangNumCtiField" : "เจ้าหน้าที่ที่วางสาย",
    "cms.report.field.ans20s" : "อัตราการเชื่อมต่อด้วยตนเองใน 20 วินาที",
    "cms.uapmonitor.field.unregister" : "ยังไม่ได้ลงทะเบียน",
    "cms.report.field.startTimeField" : "เวลาเริ่มต้นการโทร",
    "cms.fullscreen.message.digitsCheck" : "ค่าต้องเป็นจํานวนเต็มบวก",
    "cms.report.title.ivrOutboundReport" : "รายงานการโทรออก IVR",
    "cms.report.message.loginCountDesc" : "จำนวนครั้งในการลงชื่อเข้าใช้",
    "cms.callcenterinstanceslist.field.ccStatus" : "สถานะศูนย์การโทร",
    "cms.voiceinspection.message.errorTime" : "วันที่สอบถามต้องไม่ข้ามเดือน",
    "cms.report.field.internalCallCount" : "การเรียกภายใน",
    "cms.callcenterinstanceslist.message.workNoError" : "ID พนักงานเริ่มต้นต้องอยู่ในช่วง 101 ถึง 50000",
    "cms.agentmonitor.label.cancelInsert" : "ยกเลิกการแทรก",
    "cms.indexmonitor.field.abandonInThree" : "วางสายใน 3 วินาที",
    "cms.report.field.inCallNum" : "สายเรียกเข้า",
    "cms.export.field.exportEndTime" : "ส่งออกเวลาสิ้นสุด",
    "cms.report.message.acwInTimeDesc" : "ระยะเวลาการสรุปหลังจากการโทรเข้าสิ้นสุด",
    "cms.datasource.message.passValidateFailed" : "เนื้อหาที่ป้อนไม่สามารถมี <=> / —eval หรือ exec",
    "cms.report.field.inCallRateField" : "ระยะเวลาการโทรโดยเฉลี่ย",
    "cms.vcallecenter.field.confirmnewpassword" : "ยืนยันรหัสผ่าน",
    "cms.report.message.auxTimeDesc" : "ระยะเวลาการออกทั้งหมดของเจ้าหน้าที่หรือระยะเวลาเมื่อเจ้าหน้าที่อยู่ในสถานะการพัก",
    "cms.report.message.agentOutBoundDesc" : "จํานวนการโทรออกด้วยตนเองโดยตัวแทน",
    "cms.sysparam.config.select.count" : "สามารถเลือกพารามิเตอร์ได้สูงสุด 10 ตัว",
    "cms.report.field.muteCount" : "เวลาเงียบ",
    "cms.callcenterinstanceslist.message.needKafkaSSL" : "เปิดการใช้งาน SSL",
    "cms.report.field.agentTalkingTimeField" : "เวลาในการพูดคุย",
    "cms.callcenterinstanceslist.label.synchronize" : "ซิงค์",
    "cms.report.message.internalCallCountDesc" : "จำนวนการเรียกภายใน",
    "cms.indexmonitor.message.skillAvgConnectedQueueDesc" : "ระยะเวลาเข้าคิวโดยเฉลี่ยการโทรที่เชื่อมต่อทั้งหมดในคิว",
    "cms.report.field.outAvgRingTime" : "ระยะเวลาเสียงเรียกเข้าโดยเฉลี่ยของการโทรออกด้วยตนเอง",
    "cms.report.message.assistsCountDesc" : "จำนวนของคำขอความช่วยเหลือภายใน",
    "cms.callcenterinstanceslist.message.clearUapConfig" : "ล้างข้อมูลการกําหนดค่าโหนด UAP",
    "cms.report.field.acwTimeRate" : "อัตราระยะเวลาที่ใช้สรุปผลการสนทนา",
    "cms.report.message.IVRCallOutNumDesc" : "จํานวนการโทรขาออก IVR ทั้งหมด",
    "cms.fullscreen.field.acwCountTimeDesc" : "จํานวนครั้งที่เจ้าหน้าที่อยู่ในสถานะคัดแยก",
    "cms.report.field.agentInBoundField" : "การโทรเข้าด้วยตนเอง",
    "cms.report.field.call6RateField" : "อัตราเชื่อมต่อสายโทรเข้าด้วยตนเองใน 30 วินาที",
    "cms.report.message.userBusyFailNumDesc" : "จํานวนสายที่ไม่รับสายเนื่องจากสายไม่ว่าง",
    "cms.indexmonitor.message.totalQueueCallNum" : "จำนวนการโทรเข้าคิวในลำดับทักษะและรอการบริการของเจ้าหน้าที่",
    "cms.callcenterinstanceslist.field.uapIndexMonitor" : "จอแสดงตัวบ่งชี้ระบบ UAP",
    "cms.report.field.inCallSuccNumField" : "จํานวนการโทรแบบแมนนวลที่เชื่อมต่ออยู่",
    "cms.report.field.avgQueueCount" : "จํานวนเฉลี่ยของการโทรพร้อมกันในคิว",
    "cms.callcenterinstanceslist.label.refresh" : "รีเฟรช",
    "cms.report.message.talkDurationDesc" : "ระยะเวลาการพูดคุย",
    "cms.report.field.avgAcdTime" : "ระยะเวลาการโทรด้วยตนเองโดยเฉลี่ย",
    "cms.callcenter.message.selectcallcent" : "เลือกศูนย์บริการลูกค้า",
    "cms.synchronize.message.noConfAdminPwd" : "ไม่ได้กำหนดค่ารหัสผ่าน กำหนดค่ารหัสผ่านของผู้ดูแลระบบ",
    "cms.agentMonitor.label.forcebusy_stateerror" : "เจ้าหน้าที่กําลังพักอยู่ อย่ายุ่ง",
    "cms.monitordetail.field.adjustDuration" : "ระยะเวลาการจัดการ",
    "cms.synchronize.message.incorrectPassword" : "ไม่ถูกต้อง",
    "cms.indexmonitor.message.skillOnlineInbondCallDesc" : "จำนวนการโทรที่กำลังประมวลผล + จำนวนการโทรที่อยู่ในคิว",
    "cms.report.message.minAgentCountDesc" : "จํานวนขั้นต่ําของการโทรพร้อมกันที่มีอุปกรณ์บริการเป็นตัวแทน",
    "cms.systemmonitor.field.busyNums" : "ตัวแทนที่ยุ่ง",
    "cms.agentmonitor.label.whisper" : "กระซิบ",
    "cms.report.field.acdTimeRate" : "อัตราระยะเวลาการโทรด้วยตนเอง",
    "cms.recorddrive.field.sftpIpadress" : "ที่อยู่ IP SFTP",
    "cms.report.message.minIVRInCountDesc" : "จํานวนขั้นต่ําของสายเรียกเข้าพร้อมกันที่มีอุปกรณ์บริการคือ IVR",
    "cms.recorddrive.label.IPAddress_add" : "ที่อยู่ IP",
    "cms.report.message.accessCodeDesc" : "รหัสการเข้าถึง",
    "cms.indexmonitor.field.callNum" : "จำนวนการโทร",
    "cms.indexmonitor.field.realTimeCalls": "โทรตามเวลาจริง",
    "cms.indexmonitor.field.callsOfTheDay": "โทรของวัน",
    "cms.indexmonitor.field.pageDataUpdateTime" : "อัปเดตเมื่อ",
    "cms.report.message.callInCauseDesc" : "เหตุผลที่การโทรเข้าศูนย์บริการ (สําหรับการโทรด้วยเสียง)",
    "cms.developerlist.field.createTime" : "สร้างเมื่อ",
    "cms.indexmonitor.field.skillOnlineInbondCall" : "จำนวนการโทรออนไลน์",
    "cms.report.message.inOccupyNumDesc" : "จำนวนการโทรเข้าที่ขอบริการด้วยตนเอง",
    "cms.qcrelationconf.message.selectGroupType" : "โปรดเลือกประเภทกลุ่มผู้ใช้เป็นอันดับแรก",
    "cms.report.field.availTimeRateField" : "อัตราว่าง",
    "cms.recorddrive.label.recorddrivename" : "ชื่อ Drive Letter",
    "cms.indexmonitor.label.callInIVR" : "จำนวนสายเรียกเข้าออนไลน์ระบบ IVR",
    "cms.fullscreen.field.editRichText" : "ปรับเปลี่ยนรูปแบบ Rich Text",
    "cms.callcenterinstanceslist.message.cleanUap" : "กําลังล้างการตั้งค่าโหนด UAP",
    "cms.callcenterinstanceslist.field.disPwdConfirm" : "ยืนยันรหัสผ่านการตรวจสอบสิทธิ์ CCDIS",
    "cms.report.field.avgPerHNumField" : "ค่าเฉลี่ยความสำเร็จของสายต่อชั่วโมง",
    "cms.calllind.field.deviceAgent" : "เจ้าหน้าที่",
    "cms.agentmonitor.label.listening" : "ฟัง",
    "cms.report.field.mediaTypeField" : "ประเภทสื่อ",
    "cms.report.field.playVoiceTimeField" : "ระยะเวลาการเล่นด้วยเสียง",
    "cms.report.field.avgHoldTime" : "ระยะเวลาถือสายโดยเฉลี่ย",
    "cms.agentMonitor.label.cancelListening_success" : "ยกเลิกการโทรสำเร็จ",
    "cms.callcenterinstanceslist.field.adminCount" : "CTI AdminCount",
    "cms.report.message.exportFailed" : "ขาออกล้มเหลว",
    "cms.voiceinspection.field.batchDownload" : "ดาวน์โหลด Batch",
    "cms.voiceinspection.field.callerNo" : "เบอร์โทร",
    "cms.report.field.maxAgentCountField" : "จํานวนสูงสุดของการโทรของเอเจนต์พร้อมกัน",
    "cms.indexmonitor.message.agentConnectIn10Rate" : "ร้อยละของจํานวนการโทรที่เชื่อมต่อด้วยตนเองภายใน 10 วินาที (รวม 10 วินาที) ต่อจํานวนการโทรทั้งหมด",
    "cms.agentMonitor.message.operaFailedAgent" : "การดําเนินการล้มเหลว กําลังตรวจสอบเจ้าหน้าที่",
    "cms.report.field.agentOutSuccRate" : "อัตราการเชื่อมต่อการโทรออกด้วยตนเอง",
    "cms.indexmonitor.message.errorOrgInfo" : "โปรดเลือกองค์กรก่อน",
    "cms.report.field.acdCall" : "การโทรที่เชื่อมต่อทั้งหมด",
    "cms.report.message.outAvgCallTimeDesc" : "ระยะเวลาการโทรออกโดยเฉลี่ยระหว่างเจ้าหน้าที่และลูกค้า",
    "cms.report.field.oneClickDualCall" : "วการโทรแบบสองทิศทางเพียงคลิกเดียว",
    "cms.report.field.IVRInSucc" : "สายขาเข้า IVR ที่เชื่อมต่อ",
    "cms.report.field.agentOutBound" : "การโทรออกด้วยตนเอง",
    "cms.voiceinspection.field.messageCall" : "ข้อความ",
    "cms.callcenterinstanceslist.message.uapPsdLengthRangeValidateFailed" : "ความยาวของรหัสผ่านควรอยู่ระหว่าง 6 ถึง 32",
    "cms.developerlist.field.operate" : "การปฏิบัติการ",
    "cms.sysparam.config.checkmessage" : "มีอักขระพิเศษ (\"<\", \">\", \"/\", ฯลฯ)",
    "cms.qcrelationconf.field.qcWorkNo" : "รหัสผู้ตรวจสอบ",
    "cms.agentmonitor.label.statusDuration" : "ระยะเวลาสถานะ",
    "cms.vcallecenter.title.failed" : "ผิดพลาด",
    "cms.indexmonitor.field.accesscodeCalloutNum" : "จำนวนการโทรออก",
    "cms.report.message.avgAgentCountDesc" : "จํานวนเฉลี่ยของการโทรพร้อมกันที่มีอุปกรณ์บริการเป็นตัวแทน",
    "cms.indexmonitor.field.all" : "ทั้งหมด",
    "cms.agentmonitor.label.sendNotes" : "ส่งบันทึก",
    "cms.report.message.avgAgentInCountDesc" : "จํานวนเฉลี่ยของสายเรียกเข้าพร้อมกันที่มีอุปกรณ์บริการเป็นตัวแทน",
    "cms.qcrelationconf.placeholder.workNo" : "โปรดใส่ ID เจ้าหน้าที่",
    "cms.report.field.onlyIVRService" : "การโทรขาเข้า Pure-IVR ที่ประสบความสําเร็จ",
    "cms.report.message.avgIVRInCountDesc" : "จํานวนเฉลี่ยของสายเรียกเข้าพร้อมกันที่มีอุปกรณ์บริการคือ IVR",
    "cms.indexmonitor.message.ivrInboundNum" : "จำนวนคำขอการโทรเข้าระบบ IVR ที่มีระยะเวลาการโทรมากกว่า 0",
    "cms.calllink.field.noResources" : "ไม่มีทรัพยากรที่ใช้ได้ใน ACD",
    "cms.report.field.minQueueOutCount" : "จํานวนขั้นต่ําของการโทรออกพร้อมกันในคิว",
    "cms.report.message.avgAnsTimeDesc" : "ระยะเวลาการเข้าคิวโดยเฉลี่ยของการโทรที่เชื่อมต่อทั้งหมดในคิว",
    "cms.developerlist.label.delete" : "ลบ",
    "cms.fullscreen.field.preview" : "ดูตัวอย่าง",
    "cms.indexmonitor.message.totalAbandonedCallNum" : "จำนวนการโทรทั้งหมด – จำนวนการโทรที่เชื่อมต่อทั้งหมด",
    "cms.systemparam.error.validate.notmatchrulerangelength" : "พารามิเตอร์อินพุตเกินความยาวสูงสุดหรือต่ำกว่าความยาวขั้นต่ำ",
    "cms.report.field.callStartTimeField" : "เวลาเริ่มต้นการโทร",
    "cms.systemparam.error.notmatchrule" : "พารามิเตอร์ไม่ตรงกับกฎการตรวจสอบ",
    "cms.report.title.agentOutCallMonitor" : "การตรวจสอบสรุปการโทรออกของเจ้าหน้าที่",
    "cms.report.field.custHangNumCtiField" : "ลูกค้าวางสาย",
    "cms.report.field.thirtyminutes" : "30 นาที",
    "cms.datasource.message.updatesuccess" : "การอัปเดตสําเร็จ",
    "cms.report.field.workTimeField" : "ระยะเวลาการทำงานทั้งหมด",
    "cms.fullscreen.message.chartDataError" : "โหลดแผนภูมิล้มเหลว",
    "cms.indexmonitor.field.durationAvgVdnOutbound" : "ระยะเวลาการโทรออกโดยเฉลี่ย",
    "cms.export.field.exportTaskStatus" : "สถานะ",
    "cms.report.field.callBeginField" : "เวลาเริ่มต้นการโทร",
    "cms.report.message.inCallRateDesc" : "ระยะเวลาการโทรเฉลี่ย ระยะเวลาเฉลี่ยของการโทรเข้าด้วยตนเองที่เชื่อมต่ออยู่ = ระยะเวลาของการโทรเข้าด้วยตนเองที่เชื่อมต่ออยู่/จํานวนการโทรเข้าด้วยตนเองที่เชื่อมต่ออยู่",
    "cms.report.field.busyTimeRateField" : "อัตราระยะเวลาไม่ว่าง",
    "cms.skilladjustment.field.selectskill" : "เลือกคิว",
    "cms.vcallecenter.field.confirmwaspassword" : "ยืนยันรหัสผ่าน",
    "cms.indexmonitor.message.abandonInFive" : "เปอร์เซ็นต์ของจํานวนสายที่ถูกทิ้งภายใน 5 วินาทีในจํานวนสายที่ถูกทิ้งทั้งหมด",
    "cms.export.field.failed" : "ล้มเหลว",
    "cms.report.message.inCallSuccRateDesc" : "เปอร์เซ็นต์ของสายโทรเข้าที่เชื่อมต่อไปยังสายโทรเข้าทั้งหมด",
    "cms.vcallecenter.message.syncFailed" : "การซิงโครไนซ์ล้มเหลว",
    "cms.fullscreen.field.fifteenMin" : "15 นาที",
    "cms.report.message.subMediaTypeDesc" : "ประเภทสื่อย่อย",
    "cms.systemparam.error.validate.notmatchrulenumber" : "โปรดป้อนตัวเลข",
    "cms.systemmonitor.message.addVdnMost" : "สามารถเลือกผู้เช่าได้สูงสุด 100 ราย",
    "cms.systemparam.error.validate.notmatchruleoption" : "พารามิเตอร์อินพุตไม่อยู่ในช่วงการแจงนับ",
    "cms.qcrelationconf.field.deleteGroupMember" : "ลบสมาชิกกลุ่ม",
    "cms.report.field.avgHandleTimeField" : "ระยะเวลาการประมวลผลโดยเฉลี่ย",
    "cms.report.field.minQueueInCountField" : "จํานวนขั้นต่ําของสายเรียกเข้าพร้อมกันในคิว",
    "cms.report.title.agentOutCallTrend" : "แนวโน้มสรุปผลการโทรออกของเจ้าหน้าที่",
    "cms.report.field.outAvgAnsTime" : "ระยะเวลาเสียงเรียกเข้าโดยเฉลี่ยของการโทรออก",
    "cms.calllind.field.deviceRoute" : "อุปกรณ์กำหนดเส้นทางชั่วคราว",
    "cms.report.message.awarnessRateDesc" : "เปอร์เซ็นต์ของจำนวนเจ้าหน้าที่ที่รับสายของจำนวนการโทรเข้าด้วยตนเอง",
    "cms.agent.title.modifypassword" : "เปลี่ยนรหัสผ่านลงชื่อเข้าใช้",
    "cms.report.message.avgAcwTimeDesc" : "ระยะเวลาโดยเฉลี่ยเมื่อเจ้าหน้าที่อยู่ในสถานะการสรุปผลการสนทนา ระยะเวลาการสรุปโดยเฉลี่ย = ระยะเวลาที่ใช้สรุปผลการสนทนาทั้งหมด / จำนวนครั้งที่ใช้สรุปผลการสนทนา",
    "cms.report.message.inCallSuccNumberDesc" : "จํานวนสายโทรเข้าที่เชื่อมต่อ ค่านี้คือผลรวมของสายขาเข้าที่เชื่อมต่อด้วยตนเองและสายขาเข้าที่เชื่อมต่อโดยอัตโนมัติโดยใช้ IVR",
    "cms.fullscreen.message.addChart" : "เพิ่มแผนภูมิ",
    "cms.voiceinspection.message.playsuccess" : "เล่นประกาศเรียบร้อยแล้ว",
    "cms.agentMonitor.label.warning" : "คำเตือน",
    "cms.indexmonitor.message.agentOutboundConnectNum" : "จํานวนสายโทรออกด้วยตนเองที่ได้รับการตอบรับจากฝ่ายที่เรียก",
    "cms.report.message.manToSkillNumDesc" : "จำนวนโอนจาก Manual ไปยัง Skill Queue",
    "cms.agentmonitor.label.showAlarmSensitive" : "คําที่ละเอียดอ่อน",
    "cms.calllink.field.commonAccessTransfer" : "การเข้าถึงหรือโอนทั่วไป",
    "cms.export.title.voiceDownload" : "การตรวจสอบการบันทึก",
    "cms.calllink.field.overflowing" : "การโทรถูกโอนเนื่องจาก overflow",
    "cms.datasource.message.testsuccess" : "การเชื่อมต่อการทดสอบสําเร็จ",
    "cms.report.message.avgAcdTimeDesc" : "ระยะเวลาการโทรโดยเฉลี่ยของเจ้าหน้าที่ ระยะเวลาการโทรโดยเฉลี่ยด้วยตนเอง = ระยะเวลาการโทรด้วยตนเอง / จำนวนการโทรด้วยตนเองที่สำเร็จ",
    "cms.indexmonitor.field.skillUserAbandon" : "สายที่ไม่ได้รับการบริการ",
    "cms.report.message.userLevelDesc" : "ระดับลูกค้าของผู้ใช้บริการในศูนย์บริการลูกค้า",
    "cms.report.message.acwOutCountDesc" : "จำนวนครั้งที่เจ้าหน้าที่เข้าสู่สถานะสรุปผลการสนทนาหลังจากการโทรออกสิ้นสุด",
    "cms.fullscreen.field.chartType" : "ประเภทแผนภูมิ",
    "cms.fullscreen.message.deleteconfim" : "คุณต้องการยืนยันการลบหัวข้อนี้หรือไม่?",
    "cms.fullscreen.field.addCharts" : "สร้างแผนภูมิ",
    "cms.monitordetail.field.count" : "สถิติเกี่ยวกับจำนวนครั้ง",
    "cms.report.field.IVROutCallNumField" : "การโทรขาออก Pure-IVR",
    "cms.fullscreen.field.skillQueue" : "ลำดับทักษะ",
    "cms.calllink.title.leaveReason" : "เหตุผลในการออกจากอุปกรณ์",
    "cms.callcenterinstanceslist.field.kafkaIp" : "IP KAFKA",
    "cms.indexmonitor.message.inboundCallNum" : "จำนวนการโทรเข้าด้วยตนเอง + จำนวนการโทรเข้า IVR",
    "cms.report.field.submediatype.whatsapp" : "WhatsApp",
    "cms.calllink.field.PGR" : "PGR (เส้นทาง CDN เริ่มต้นที่ควบคุมโดย SCAI)",
    "cms.report.field.voiceNotification" : "การแจ้งเตือนด้วยเสียง",
    "cms.agentmonitor.message.header" : "การบันทึกล้มเหลว เลือกหัวโต๊ะอย่างน้อยหนึ่งหัว",
    "cms.report.field.querySkill" : "ลำดับทักษะ",
    "cms.indexmonitor.field.totalLearningNum" : "เจ้าหน้าที่อยู่ในสถานะการเรียนรู้",
    "cms.report.field.agentInSuccRate" : "อัตราการเชื่อมต่อสายขาเข้าด้วยตนเอง",
    "cms.uapmonitor.labe.uapoverload" : "จํานวนตัวเลขที่ค้นหาโดยส่วนหมายเลขเทอร์มินัลเกินขีด จํากัด บน:",
    "cms.callcenterinstanceslist.message.adminWorkNoError" : "หมายเลขเริ่มต้นของ ID พนักงานผู้ดูแลระบบต้องอยู่ในช่วงตั้งแต่ 55001 ถึง 59999",
    "cms.report.field.outAboundRingField" : "การโทรออกที่ยังไม่ได้รับสาย",
    "cms.monitordetail.field.skill" : "ลำดับทักษะ",
    "cms.report.field.holdTimeField" : "ระยะเวลาถือสาย",
    "cms.report.field.manTransferOutNumCtiField" : "จจํานวนการโอนด้วยตนเองไปยัง IVR และโอนออกอีกครั้ง",
    "cms.agentMonitor.label.listen_error" : "การฟังล้มเหลว",
    "cms.calllink.field.unhold" : "ไม่ได้รอสาย",
    "cms.failurecall.field.accessCode" : "รหัสการเข้าถึง",
    "cms.report.message.preDeviceNoDesc" : "ระบุรหัสอุปกรณ์ของอุปกรณ์ล่าสุดที่เริ่มการโทร",
    "cms.agentmonitor.label.learnState" : "กำลังเรียนรู้",
    "cms.indexmonitor.message.durationAvgVdnInbound" : "ระยะเวลาทั้งหมดที่การโทรเข้าใช้ VDN / จำนวนการโทรเข้าทั้งหมด",
    "cms.report.message.userRefusedFailNumDesc" : "จํานวนสายที่ไม่ได้รับการตอบรับเนื่องจากการปฏิเสธสาย",
    "cms.datasource.field.cancel" : "ยกเลิก",
    "cms.indexmonitor.message.totalAgentNum" : "จำนวนเจ้าหน้าที่ปัจจุบันทั้งหมด",
    "cms.report.field.outFailNum" : "การโทรออกที่ล้มเหลวทั้งหมด",
    "cms.fullscreen.field.fiveMin" : "5 นาที",
    "cms.report.title.callNo" : "หมายเลขโทร",
    "cms.fullscreen.title.editTheme" : "ปรับเปลี่ยนชื่อเรื่อง",
  "cms.fullscreen.style.dark": "สีเข้ม",
  "cms.fullscreen.style.light": "สีอ่อน",
  "cms.fullscreen.style.title": "สไตล์",
  "cms.calllink.field.teleconference" : "การประชุมทางโทรศัพท์",
    "cms.voiceinspection.field.calleeNo" : "เบอร์โทร",
    "cms.report.field.IVRShortCallsField" : "IVR Short Calls Num (ภายใน 3 วินาที)",
    "cms.report.field.internalCallTime" : "ระยะเวลาการเรียกภายใน",
    "cms.uapmonitor.labe.eidmention" : "คุณสามารถป้อนเซกชันหมายเลขสูงสุด 10 ชิ้นแยกด้วยจุลภาค กฎสําหรับแต่ละส่วนตัวเลข:* และ% ตรงกับอักขระ 0 หรือมากกว่า ตัวอย่างเช่น * 6666 และ 6666% สตริงทั้งหมดเริ่มต้นด้วย 6666 ตรงกัน? ตรงกับอักขระโดยพลการเพียง 1 ตัวเท่านั้น ตัวอย่างเช่น 6666? , ตรงกับสตริงเท่านั้นเริ่มต้นด้วย 6666 และความยาว 5ตัวอักษรไวด์การ์ด * และ? ได้รับการสนับสนุน การรวมกันของ % เช่น %3? , ตรงกับสตริงทั้งหมดที่มีอักขระก่อนสุดท้ายคือ 3หมายเหตุ: ขอแนะนําให้ผลลัพธ์ที่ตรงกันของไวลด์การ์ดแต่ละใบน้อยกว่า 100 มิฉะนั้น บริการปกติในฝั่ง UAP จะได้รับผลกระทบ",
    "cms.report.field.inAvgRingTimeField" : "ระยะเวลาเสียงเรียกเข้าโดยเฉลี่ยของการโทรเข้าด้วยตนเอง",
    "cms.datasource.field.remark" : "คําอธิบายการใช้งาน",
    "cms.calllink.title.callType" : "ประเภทการโทร",
    "cms.report.message.ackEndDesc" : "เวลาสิ้นสุดการรับสาย ซึ่งหมายถึงเวลาสิ้นสุดของการรับสายบนอุปกรณ์ปัจจุบัน",
    "cms.report.field.obsServiceIdField" : "ID กิจกรรมการโทรออก",
    "cms.record-playback.callType2" : "โทรออกระบบ IVR",
    "cms.qcrelationconf.message.queryFailedLimit" : "จำนวนการบันทึกต่อหน้าต้องมากกว่า 0 และน้อยกว่า 101",
    "cms.failurecall.placeholder.userPhoneInput" : "ป้อนหมายเลขบริการ",
    "cms.report.field.outSuccAnsRate" : "อัตราการเชื่อมต่อสายโทรออก",
    "cms.callcenterinstanceslist.field.cmsAuthPassword" : "รหัสผ่านการตรวจสอบความถูกต้อง CCDIS",
    "cms.report.field.skillId" : "ID คิวทักษะ",
    "cms.uapmonitor.field.callUapIp" : "UAP IP",
    "cms.report.field.endYear" : "สิ้นปี",
    "cms.report.field.agentInSuccField" : "สายขาเข้าแบบแมนนวลที่เชื่อมต่ออยู่",
    "cms.callcenterinstanceslist.label.save" : "บันทึก",
    "cms.uapmonitor.title.deregis" : "การยืนยันการยกเลิกการลงทะเบียน",
    "cms.indexmonitor.message.skillConnectedAfterNDesc" : "จำนวนคำขอโทรที่เชื่อมต่อด้วยตนเองหลัง Ns. ในตอนนี้ Ns สามารถเป็น 10 วินาทีหรือ 60 วินาที",
    "cms.report.message.billInfo1Desc" : "ประเภทการโทรด้วยเสียง",
    "cms.report.message.outWaitAnsNumDesc" : "จำนวนการโทรออกด้วยตนเอง รวมถึงการโทรออกโดยเจ้าหน้าที่และสายที่โอนจาก IVR ไปยังการบริการด้วยตนเอง",
    "cms.fullscreen.field.normalBold" : "ปกติ",
    "cms.agentMonitor.label.forcerelease_error" : "บังคับให้ปล่อยบริการโทรล้มเหลว",
    "cms.report.message.waitBeginDesc" : "เวลาเริ่มต้นรอสาย",
    "cms.indexmonitor.field.skillTimeoutQueue" : "หมดเวลาเรียกคิว",
    "cms.indexmonitor.field.agentConnectInFive" : "เชื่อมต่อการโทรด้วยตนเองใน 5 วินาที",
    "cms.callcenterinstanceslist.message.videoCallNumsError" : "จำนวนวิดีโอคอลพร้อมกันสูงสุดต้องมากกว่าหรือเท่ากับจำนวนของที่นั่ง (video seats)",
    "cms.report.title.agentOperationMonitor" : "การตรวจสอบรายละเอียดการปฏิบัติการการเชื่อมต่อของเจ้าหน้าที่",
    "cms.vcallecenter.title.modifybmspassword" : "ปรับเปลี่ยนรหัสผ่าน BMS",
    "cms.callcenterinstanceslist.label.create" : "ใหม่",
    "cms.indexmonitor.title.agentNowMonitor" : "สถิติการตรวจสอบแบบเรียลไทม์ของเอเจนต์ระหว่างการทํางาน",
    "cms.indexmonitor.title.callCenterDayTrend" : "แนวโน้มศูนย์บริการของวันนี้",
    "cms.voiceinspection.field.currentSkillId" : "ลำดับทักษะ",
    "cms.report.field.indicatorName" : "ตัวบ่งชี้",
    "cms.fullscreen.field.percentage" : "(%)",
    "cms.agentMonitor.label.gateway_error" : "เรียกอินเตอร์เฟซเกตเวย์ล้มเหลว",
    "cms.indexmonitor.message.agentAbandonOut10NDesc" : "จํานวนรวมของสายที่ถูกทิ้งซึ่งมีระยะเวลาการรับสายนานกว่า 10 วินาที",
    "cms.report.field.otherHangNumCti" : "ระบบ Hang-Ups",
    "cms.calllink.field.internalError" : "ข้อผิดพลาดภายใน",
    "cms.indexmonitor.message.skillAbandonInNDesc" : "จำนวนคำขอโทรที่ละทิ้งสายด้วยตนเองใน Ns ตอนนี้ Ns สามารถเป็น 3 วินาที, 5 วินาที, 10 วินาที, 20 วินาที, 30 วินาทีหรือ 60 วินาที",
    "cms.uapmonitor.field.callSip" : "ตัวระบุอุปกรณ์เทอร์มินอล",
    "cms.report.message.callAbandonedInRingDesc" : "จำนวนการโทรที่ถูกวางสายโดยผู้โทรในขณะที่มีเสียงเรียกเข้าที่ไม่ได้รับสาย",
    "cms.report.field.awarnessRateField" : "อัตราความสำเร็จของการโทรเข้าด้วยตนเอง",
    "cms.callcenterinstanceslist.message.selectdeveloper" : "เลือกนักพัฒนา",
    "cms.callcenterinstanceslist.message.error" : "ผิดพลาด",
    "cms.report.title.changeTable" : "สลับไปที่ตาราง",
    "cms.callcenterinstanceslist.field.ccBmsPort" : "พอร์ต CCBMS LB",
    "cms.indexmonitor.message.agentBoundAbandonRate" : "100 – อัตราของการเชื่อมต่อการโทรเข้าด้วยตนเอง",
    "cms.systemparam.error.validate.notmatchruleminlength" : "พารามิเตอร์อินพุตน้อยกว่าความยาวขั้นต่ำ",
    "cms.indexmonitor.message.skillRequestDesc" : "จำนวนการโทรเข้าที่ร้องขอการบริการผู้ใช้",
    "cms.callecenter.field.confirmbmspwd" : "ยืนยันรหัสผ่าน CCBMS",
    "cms.voiceinspection.field.videoClickToCall" : "วิดีโอคลิกเพื่อโทร",
    "cms.callcenterinstanceslist.message.passLengthValidateFailed" : "รหัสผ่านมีอักขระอย่างน้อย 8 ตัว",
    "cms.monitordetail.field.avgTalkingDuration" : "ระยะเวลาการโทรโดยเฉลี่ย",
    "cms.agentmonitor.label.busyState" : "ไม่ว่าง",
    "cms.qcrelationconf.message.queryFailed" : "การค้นหาข้อมูลล้มเหลว",
    "cms.datasource.field.uidbDatabase" : "UIDB",
    "cms.interfaceperflog.message.modifySuccess" : "การแก้ไขเสร็จสมบูรณ์",
    "cms.indexmonitor.message.ivrOutboundNum" : "จำนวนการตอบรับสายโทรออก IVR โดยผู้รับสาย",
    "cms.report.field.agentServiceRate" : "อัตราความสำเร็จด้วยตนเองในการโทรเข้าทั้งหมด",
    "cms.qcrelationconf.title.warning" : "คำเตือน",
    "cms.report.field.maxIVROutCount" : "จํานวนสูงสุดของการโทรออก IVR พร้อมกัน",
    "cms.fullscreen.message.numberSecond" : "ค่าสูงสุดคือ 4320",
    "cms.uapmonitor.field.callSocket" : "Socket สถานะ",
    "cms.reportsubscribe.field.prohibit" : "ไม่อนุญาต",
    "cms.report.message.callEndTimeDesc" : "เวลาสิ้นสุดการโทร",
    "cms.voiceinspection.message.frequentOperat" : "ทํางานบ่อยเกินไป โปรดลองอีกครั้งในภายหลัง",
    "cms.voiceinspection.message.frequentDownload" : "ดาวน์โหลดถี่เกินไป ลองใหม่อีกครั้งในภายหลัง",
    "cms.report.field.agentLoginNum" : "เจ้าหน้าที่ได้รับการโทร",
    "cms.vcallecenter.title.modifyinfo" : "ปรับเปลี่ยนรหัสผ่าน",
    "cms.indexmonitor.message.skillConnectedInNRate" : "เปอร์เซ็นต์ของจำนวนคำขอโทรที่เชื่อมต่อด้วยตนเองใน Ns ของ จำนวนการโทรทั้งหมดที่ร้องขอการบริการผู้ใช้",
    "cms.callcenterinstanceslist.field.passwordConfirm" : "ยืนยันรหัสผ่าน",
    "cms.callcenterinstanceslist.message.ccgatewayServerSingledeleteconfim" : "ต้องการลบเซิร์ฟเวอร์ CC-เกตเวย์ที่เลือกไว้ใช่หรือไม่?",
    "cms.report.field.avgAgentCountField" : "จํานวนเฉลี่ยของการโทรผ่านตัวแทนพร้อมกัน",
    "cms.report.field.minQueueInCount" : "จํานวนขั้นต่ําของสายเรียกเข้าพร้อมกันในคิว",
    "cms.report.title.error" : "ผิดพลาด",
    "cms.systemmonitor.message.timelarge" : "* ช่วงเวลาต้องไม่เกิน 31 วัน",
    "cms.fullscreen.field.fonts" : "แบบอักษร",
    "cms.qcrelationconf.message.updateSuccess" : "อัปเดตสำเร็จ",
    "cms.datasource.message.deletefailed" : "ลบล้มเหลว",
    "cms.sendnote.message.tipsNoteContent" : "ป้อนข้อมูลบันทึก",
    "cms.agentmonitor.label.detail" : "รายละเอียดการตรวจสอบของเจ้าหน้าที่",
    "cms.indexmonitor.message.skillUserAbandonDesc" : "จำนวนการโทรที่ผู้ใช้ละทิ้งในระหว่างการเข้าคิวรอสายและเสียงเรียกเข้า",
    "cms.callcenterinstanceslist.message.updateCtiVersion" : "ตรวจสอบว่าการโอนย้ายข้อมูล CTI เสร็จสมบูรณ์หรือไม่ ใช้ความระมัดระวังเมื่อปฏิบัติการนี้",
    "cms.fullscreen.field.indexdMode" : "โหมดการตรวจสอบ",
    "cms.calllink.title.callEndTime" : "เวลาสิ้นสุดการโทร",
    "cms.calllink.title.deviceDesc" : "คำอธิบายอุปกรณ์",
    "cms.report.field.failQueueWaitRate" : "ระยะเวลาเสียงเรียกเข้าโดยเฉลี่ยของสายที่หลุดไป",
    "cms.callcenterinstanceslist.title.warning" : "คำเตือน",
    "cms.voiceinspection.message.invalidParam" : "พารามิเตอร์อินพุตไม่ถูกต้อง",
    "cms.uapmonitor.field.Search" : "กําลังสอบถาม",
    "cms.developerlist.title.create" : "เพิ่มนักพัฒนา",
    "cms.callcenterinstanceslist.field.ctiversion" : "เวอร์ชัน CTI",
    "cms.synchronize.message.syncStatusZero" : "ที่จะซิงค์",
    "cms.indexmonitor.message.agentConnectInFive" : "เปอร์เซ็นต์ของจํานวนสายที่เชื่อมต่อด้วยตนเองภายใน 5 วินาทีของจํานวนสายที่เชื่อมต่อด้วยตนเองทั้งหมด",
    "cms.indexmonitor.message.agentConnectAfterInSixty" : "เปอร์เซ็นต์ของจํานวนสายที่เชื่อมต่อด้วยตนเองหลังจาก 60 วินาทีของจํานวนสายที่เชื่อมต่อด้วยตนเองทั้งหมด",
    "cms.report.title.skillAllMonitor" : "การตรวจสอบสรุปผลลำดับทักษะ",
    "cms.indexmonitor.message.durationAvgWait" : "ระยะเวลารอเฉลี่ยของสายโทรเข้า",
    "cms.report.message.acdCallsDesc" : "จำนวนการโทรเข้าและโทรออกที่เชื่อมต่อ",
    "cms.report.message.agentAlertingTimeDesc" : "เวลาแจ้งเตือนผู้โทร",
    "cms.indexmonitor.title.ivrDayMonitor" : "สถิติตัวบ่งชี้ IVR วันนี้",
    "cms.indexmonitor.field.skillConnectedIn3Rate" : "เชื่อมต่อการโทรใน 3 วินาที",
    "cms.agentMonitor.label.whisper_error" : "การกระซิบล้มเหลว     ",
    "cms.report.field.locationIdField" : "ID โหนดที่กระจาย",
    "cms.report.field.avgQueueOutCountField" : "จํานวนเฉลี่ยของการโทรออกพร้อมกันในคิว",
    "cms.report.field.IVROutSuccNum" : "การโทรขาออก Pure-IVR ที่เชื่อมต่อ",
    "cms.report.field.IVROutSuccNumField" : "สายขาออก IVR บริสุทธิ์ที่เชื่อมต่อ",
    "cms.report.field.assistTime" : "ระยะเวลาของคำขอความช่วยเหลือภายใน",
    "cms.systemmonitor.label.onehour" : "1 ชั่วโมง",
    "cms.voiceinspection.field.audioPlay" : "การบันทึกการออดิชั่น",
    "cms.callcenterinstanceslist.title.nodemodeselect" : "เลือกโหมด",
    "cms.vdnconfiguration.title.vdnConfigure" : "กําหนดค่า Call Center ที่ VDN ทํางาน",
    "cms.calldetail.label.inputcallid" : "โปรดป้อนหมายเลขลําดับการโทร",
    "cms.fullscreen.field.statisticPeriod" : "ช่วงเวลาทางสถิติ",
    "cms.report.field.acdTimeRateField" : "อัตราระยะเวลาการโทรด้วยตนเอง",
    "cms.report.title.skillAccessTrend" : "แนวโน้มทางประสิทธิภาพของลำดับทักษะด้วยรหัสการเข้าถึง",
    "cms.skilladjustment.message.tipsError" : "ปรับแต่งลำดับทักษะล้มเหลว",
    "cms.report.field.threePartyCallCount" : "การโทรสามฝ่าย",
    "cms.report.message.queryFailed" : "ล้มเหลวในการสอบถาม {0}",
    "cms.vcallecenter.message.configurationinterfacenopassword" : "ไม่ได้กำหนดรหัสผ่านการกำหนดค่า",
    "cms.datasource.message.addsuccess" : "เพิ่มสําเร็จ",
    "cms.indexmonitor.message.agentInboundNum" : "จำนวนการโทรเข้าที่จัดสรรลำดับทักษะหรือเจ้าหน้าที่",
    "cms.qcrelationconf.field.accountCode" : "บัญชีบริการ",
    "cms.report.field.accessCodeField" : "รหัสการเข้าถึง",
    "cms.indexmonitor.field.abandonInThirty" : "วางสายใน 30 วินาที",
    "cms.report.field.inAnswerRate" : "อัตราเชื่อมต่อการโทรเข้า",
    "cms.report.field.outAboundRing" : "การโทรออกที่ยังไม่ได้รับสาย",
    "cms.report.field.avgIVRCountField" : "จํานวนเฉลี่ยของการโทร IVR พร้อมกัน",
    "cms.indexmonitor.message.totalConnectCallNum" : "จำนวนของการเชื่อมต่อการโทรเข้า  + จำนวนของการเชื่อมต่อการโทรออก",
    "cms.agentmonitor.label.restState" : "พัก",
    "cms.qcrelationconf.field.operation" : "การดำเนินการ",
    "cms.fullscreen.message.screenreSolution" : "ความละเอียดหน้าจอต้องไม่ว่างเปล่า",
    "cms.agentMonitor.label.forceidle_success_calling" : "เจ้าหน้าที่ถูกบังคับให้ตั้งค่าอยู่ในสถานะว่างสำเร็จ หลังสิ้นสุดการโทร เจ้าหน้าที่เข้าสู่สถานะว่าง",
    "cms.qcrelationconf.message.deleteSuccess" : "ลบสำเร็จ",
    "cms.monitordetail.field.redirectcount" : "เปลี่ยนเส้นทางการโทร",
    "cms.agentmonitor.label.signOut" : "ออฟไลน์",
    "cms.qcrelationconf.field.cancel" : "ยกเลิก",
    "cms.report.field.waitAnsRate" : "ระยะเวลาเสียงเรียกเข้าโดยเฉลี่ยของการโทรเข้าด้วยตนเอง",
    "cms.fullscreen.field.movedown" : "ลดลง",
    "cms.report.field.voiceCallType" : "ประเภทการโทรด้วยเสียง",
    "cms.indexmonitor.field.duration" : "ระยะเวลา",
    "cms.report.message.avgAgentOutCountDesc" : "จํานวนเฉลี่ยของการโทรออกพร้อมกันที่มีอุปกรณ์บริการเป็นตัวแทน",
    "cms.uapmonitor.labe.uapconfing" : "การตรวจสอบสถานะเทอร์มินัลและการกําหนดค่าการกู้คืน",
    "cms.report.field.manTransferOutNumCti" : "จํานวนการโอนด้วยตนเองไปยัง IVR และโอนออกอีกครั้ง",
    "cms.report.message.onlineRateDesc" : "อัตราออนไลน์ = (ระยะเวลาการโทรด้วยตนเอง + ระยะเวลาที่ใช้สรุปผลการสนทนาทั้งหมด + ระยะเวลาทั้งหมดของสถานะอื่น ๆ ) / (ระยะเวลาการทำงานทั้งหมด – ระยะเวลาพัก) x 100",
    "cms.callcenterinstanceslist.label.vCallCenter" : "ศูนย์บริการเสมือนจริง",
    "cms.agentmonitor.label.operation" : "การปฏิบัติการ",
    "cms.fullscreen.message.addVdnLeast" : "เลือกผู้เช่าอย่างน้อยหนึ่งราย",
    "cms.report.field.userBusyFailNum" : "สายหายเนื่องจากสายไม่ว่าง",
    "cms.monitordetail.field.idlecount" : "จำนวนครั้งที่เจ้าหน้าที่เข้าสู่สถานะว่าง",
    "cms.datasource.field.dbType" : "ประเภทแหล่งข้อมูล",
    "cms.report.field.failAnsRate" : "อัตราคำขอที่เสียไป",
    "cms.qcrelationconf.field.groupInfo" : "ข้อมูลพื้นฐานกลุ่มผู้ใช้",
    "cms.systemparam.error.reset" : "ล้มเหลวในการรีเซ็ตพารามิเตอร์",
    "cms.report.field.avgCallPerHour" : "ค่าเฉลี่ยการโทรเข้าด้วยตนเองต่อชั่วโมง",
    "cms.datasource.field.operation" : "ดำเนินการ",
    "cms.report.field.noAckNum" : "สายที่ไม่ได้รับ",
    "cms.callcenterinstanceslist.label.batchAdd" : "เพิ่ม Batch",
    "cms.voiceinspection.title.error" : "ผิดพลาด",
    "cms.report.field.userLevelField" : "ระดับลูกค้า",
    "cms.report.field.inAvgCallTimeField" : "ระยะเวลาการโทรโดยเฉลี่ย",
    "cms.report.message.maxIVRInCountDesc" : "จํานวนสูงสุดของสายเรียกเข้าพร้อมกันที่มีอุปกรณ์บริการคือ IVR",
    "cms.report.message.errorInfo" : "บันทึกข้อมูลล้มเหลว เลือกอย่างน้อยหนึ่งตัวบ่งชี้",
    "cms.qualitycheck.label.editusergroup" : "แก้ไขกลุ่มผู้ใช้",
    "cms.report.field.callAbandonedRingField" : "จํานวนสายเรียกเข้าด้วยตนเองที่หายไป",
    "cms.report.message.callAbandonedRingDesc" : "จํานวนสายเรียกเข้าด้วยตนเองที่หายไป = จํานวนสายเรียกเข้าด้วยตนเอง - จํานวนสายเรียกเข้าด้วยตนเองที่เชื่อมต่ออยู่",
    "cms.voiceinspection.message.noSftpFound" : "ไม่สามารถสอบถามข้อมูลการเชื่อมต่อเซิร์ฟเวอร์ SFTP ได้",
    "cms.report.field.IVRServiceRateField" : "อัตราความสําเร็จของการโทรเข้าของ Pure-IVR",
    "cms.systemparam.error.noparamselected" : "โปรดเลือกพารามิเตอร์ก่อน",
    "cms.report.message.callIdNumDesc" : "หมายเลขลําดับของ CDR หลายรายการที่สร้างขึ้นสําหรับการโทร",
    "cms.report.message.avgHoldTimeDesc" : "ระยะเวลาการพักสายโดยเฉลี่ย ระยะเวลาการถือสายโดยเฉลี่ย = ระยะเวลาถือสาย / จำนวนครั้งที่ถือสาย",
    "cms.indexmonitor.message.totalLearningNum" : "จำนวนของเจ้าหน้าที่อยู่ในสถานะเรียนรู้",
    "cms.fullscreen.field.totalCallTimeDesc" : "ระยะเวลาการโทรรวมของการโทรที่เชื่อมต่อของตัวแทน",
    "cms.report.field.queryBeginTime" : "เวลาเริ่มต้น",
    "cms.fullscreen.field.pie" : "แผนภูมิวงกลม",
    "cms.indexmonitor.message.agentAbandonIn3Rate" : "เปอร์เซ็นต์ของสายที่ถูกทิ้งภายใน 3 วินาทีต่อสายที่ถูกทิ้งทั้งหมด",
    "cms.callcenter.message.updatefailed" : "อัปเดตล้มเหลว",
    "cms.indexmonitor.message.agentConnectIn30Rate" : "ร้อยละของจํานวนการโทรที่เชื่อมต่อด้วยตนเองภายใน 30 วินาที (รวม 30 วินาที) ต่อจํานวนการโทรทั้งหมด",
    "cms.agentmonitor.label.account" : "บัญชีบริการ",
    "cms.report.message.IVROutSuccRateDesc" : "เปอร์เซ็นต์ของการโทรขาออก Pure-IVR ที่เชื่อมต่อไปยังการโทรขาออก Pure-IVR ทั้งหมด",
    "cms.report.message.manToIvrNumCtiDesc" : "จำนวนการโอนด้วยตนเองไปยัง IVR",
    "cms.report.field.cancel" : "ยกเลิก",
    "cms.report.field.manToIvrNumCti" : "เวลาของการถ่ายโอนจากการระงับตัวแทนไปยัง IVR",
    "cms.report.message.inCallFailNumDesc" : "จำนวนคำขอสายที่หลุดไป = จำนวนคำขอบริการ – จำนวนคำขอโทรที่เชื่อมต่อ",
    "cms.callcenter.field.deletesuccess" : "การลบสำเร็จ",
    "cms.fullscreen.field.dayTrend" : "แนวโน้มวันนี้",
    "cms.calllink.title.deviceNo" : "ID อุปกรณ์",
    "cms.voiceinspection.message.playfailure" : "ไม่สามารถเล่นประกาศได้",
    "cms.report.message.outAvgRingTimeDesc" : "เวลาโดยเฉลี่ยสำหรับเจ้าหน้าที่ในการรอลูกค้าเพื่อตอบสายการโทรออก",
    "cms.report.field.outCallTimeField" : "ระยะเวลาการโทรออกทั้งหมด",
    "cms.indexmonitor.message.agentConnectInSixty" : "เปอร์เซ็นต์ของจํานวนสายที่เชื่อมต่อด้วยตนเองภายใน 60 วินาทีของจํานวนสายที่เชื่อมต่อด้วยตนเองทั้งหมด",
    "cms.callcenterinstanceslist.field.uapPerfPort" : "พอร์ตการตรวจสอบประสิทธิภาพ UAP",
    "cms.datasource.message.testfailed" : "การเชื่อมต่อการทดสอบล้มเหลว",
    "cms.callecenter.field.setbmspwd" : "รหัสผ่าน CCBMS",
    "cms.report.field.maxIVRInCount" : "จํานวนสูงสุดของสายเรียกเข้า IVR พร้อมกัน",
    "cms.monitordetail.field.idle" : "ว่าง",
    "cms.calllink.field.leaveReason89" : "หลังจากที่เจ้าหน้าที่เรียกอินเตอร์เฟซด้วยคำตอบ เจ้าหน้าที่ไม่ได้ติดต่อผู้ใช้และผู้ใช้วางสาย",
    "cms.reportsubscribe.field.monday" : "วันจันทร์",
    "cms.calllink.field.leaveReason88" : "ผู้ใช้วางสายหลังจากเจ้าหน้าที่ติดต่อไปยังผู้ใช้",
    "cms.systemmonitor.label.thirtyonedays" : "31 วัน",
    "cms.report.field.acdCallsField" : "การโทรที่เชื่อมต่อทั้งหมด",
    "cms.datasource.message.dsmodifyconfirm" : "แหล่งข้อมูลได้รับการอ้างอิงใน Call Center และจะมีผลบังคับใช้เฉพาะหลังจากการเริ่มต้นสภาพแวดล้อมใหม่เท่านั้น คุณแน่ใจหรือไม่ว่าคุณต้องการแก้ไข?",
    "cms.callcenterinstanceslist.field.ccGatewayPort" : "CC-พอร์ตเกตเวย์",
    "cms.reportsubscribe.field.sentTime" : "เวลาส่ง",
    "cms.errormessage.inputspecialchar" : "ค่านี้มีอักขระพิเศษที่ไม่ถูกต้อง",
    "cms.systemmonitor.message.timeerror" : "* เวลาเริ่มต้นต้องมาก่อนเวลาสิ้นสุด",
    "cms.indexmonitor.field.outboundCallNum" : "การโทรออก",
    "cms.failurecall.field.search" : "ค้นหา",
    "cms.fullscreen.message.thresholdLessThan" : "ต้องมากกว่าค่าต่ําสุด",
    "cms.report.message.ringTimeDesc" : "ระยะเวลาเสียงเรียกเข้าทั้งหมดของเจ้าหน้าที่ ไม่รวมถึงระยะเวลาการโทร",
    "cms.calllink.field.callType33" : "การโทรเข้าทั่วไป",
    "cms.calllink.field.callType32" : "การโทรเข้าแบบเก็บค่าธรรมเนียม",
    "cms.calllink.field.callType31" : "การเรียกภายใน",
    "cms.systemmonitor.field.systemInBound" : "สายเรียกเข้าของระบบ",
    "cms.calllink.field.callType30" : "การโทรออกทั่วไป",
    "cms.report.message.IVROutCallNumDesc" : "จํานวนการโทรออกที่โทรออกโดย IVR เท่านั้น",
    "cms.agentmonitor.label.idleState" : "ว่าง",
    "cms.vdnconfiguration.title.success" : "สำเร็จ",
    "cms.monitordetail.field.agentReleaseCount" : "จำนวนครั้งที่เจ้าหน้าที่ปล่อยสาย",
    "cms.developerlist.message.refreshConfirmMessage" : "คุณต้องการอัปเดต SK ของนักพัฒนาใช่หรือไม่?",
    "cms.report.placeholder.workName" : "ป้อนชื่อตัวแทน",
    "cms.report.message.avgCallPerHourDesc" : "จำนวนตอบรับสายโดยเฉลี่ยของเจ้าหน้าที่ต่อชั่วโมงในส่วนเวลาของการทำงานที่เลือก",
    "cms.indexmonitor.message.agentConnectInThree" : "เปอร์เซ็นต์ของจํานวนสายที่เชื่อมต่อด้วยตนเองภายใน 3 วินาทีของจํานวนสายที่เชื่อมต่อด้วยตนเองทั้งหมด",
    "cms.report.field.avgCallPerHourField" : "ค่าเฉลี่ยการโทรเข้าด้วยตนเองต่อชั่วโมง",
    "cms.indexmonitor.message.skillLostRingDesc" : "จำนวนสายที่ไม่ได้รับหลังจากจัดสรรให้กับเจ้าหน้าที่",
    "cms.report.field.userBusyFailNumField" : "สายขาดหายเนื่องจากสายไม่ว่าง",
    "cms.report.field.inCallSuccRate" : "อัตราการเชื่อมต่อสายขาเข้า",
    "cms.report.message.outAnswerRateDesc" : "เปอร์เซ็นต์ของจํานวนสายโทรออกที่เชื่อมต่อในจํานวนสายโทรออก",
    "cms.calllink.field.callType29" : "การโทรออกด้วยตนเอง",
    "cms.calllink.field.callType28" : "ประเภทการโทรออกของเจ้าหน้าที่",
    "cms.indexmonitor.message.agentConnectIn5Rate" : "ร้อยละของจํานวนการโทรที่เชื่อมต่อด้วยตนเองภายใน 5 วินาที (รวม 5 วินาที) ต่อจํานวนการโทรทั้งหมด",
    "cms.indexmonitor.field.skillConnectedIn5" : "เชื่อมต่อการโทรใน 5 วินาที",
    "cms.calllink.field.callType27" : "การโทรออก PRI",
    "cms.calllink.field.callType26" : "การโทรออก IVR",
    "cms.report.field.systemInBoundField" : "การโทรเข้า",
    "cms.indexmonitor.field.skillConnectedIn3" : "เชื่อมต่อการโทรใน 3 วินาที",
    "cms.failurecall.field.userPhone" : "หมายเลขบริการ",
    "cms.calllink.field.callType25" : "คำขอโทรกลับ PRI",
    "cms.report.message.waitEndDesc" : "เวลาสิ้นสุดการรอสาย ซึ่งหมายถึงเวลาสิ้นสุดของการรอสายบนอุปกรณ์ปัจจุบัน",
    "cms.fullscreen.message.addLayout" : "เพิ่มโมเดล canvas ล้มเหลวเนื่องจากโมเดล canvas เต็มแล้ว",
    "cms.calllink.field.callType24" : "ดูตัวอย่างการโทรออกจาก PRI",
    "cms.report.field.outAnswerRateField" : "อัตราการเชื่อมต่อการโทรออก",
    "cms.report.field.week" : "สัปดาห์",
    "cms.calllink.field.callType23" : "การโทรเข้าและโทรออกของ PRI เสมือน",
    "cms.calllink.field.callType22" : "การโทรออกจาก PRI ที่เชื่อมต่อล่วงหน้า",
    "cms.calllink.field.callType21" : "การโทรออกจาก PRI ที่ยุ่งเกินไป",
    "cms.calllink.field.callType20" : "การโทรขอความช่วยเหลือสามฝ่าย",
    "cms.datasource.field.dbUrl" : "Url การเชื่อมต่อฐานข้อมูล",
    "cms.callcenterinstanceslist.field.kafkaPassword" : "รหัสผ่าน KAFKA",
    "cms.developerlist.label.create" : "เพิ่ม",
    "cms.report.field.inCallRate" : "ระยะเวลาการโทรโดยเฉลี่ย",
    "cms.report.field.playVoiceTime" : "ระยะเวลาการเล่นด้วยเสียง",
    "cms.report.message.maxAgentOutCountDesc" : "จํานวนสูงสุดของการโทรออกพร้อมกันที่มีอุปกรณ์บริการเป็นตัวแทน",
    "cms.report.title.IVRAccessMonitor" : "การตรวจสอบสถิติเกี่ยวกับข้อมูล IVR ด้วยรหัสการเข้าถึง",
    "cms.fullscreen.field.weighted" : "น้ำหนักหรือความหนาของอักษร (Font weight)",
    "cms.qcrelationconf.field.agentWorkNo" : "ID ฝ่ายที่ตรวจสอบแล้ว",
    "cms.report.message.agentTalkDurationDesc" : "ระยะเวลาการพูดคุยของผู้โทร",
    "cms.callcenterinstanceslist.field.systemAdmin" : "ID ผู้ดูแลระบบ CTI",
    "cms.report.field.billInfo1Field" : "ประเภทการโทรด้วยเสียง",
    "cms.report.field.minQueueOutCountField" : "จํานวนขั้นต่ําของการโทรออกพร้อมกันในคิว",
    "cms.indexmonitor.title.success" : "สำเร็จ",
    "cms.indexmonitor.field.agentOutCallCount" : "จำนวนการโทรออกของเจ้าหน้าที่ออนไลน์",
    "cms.indexmonitor.field.agentBoundConnectRate" : "การเชื่อมต่อการโทรเข้าด้วยตนเอง",
    "cms.calllink.field.callType19" : "การโทรขอความช่วยเหลือสองฝ่าย",
    "cms.calllink.field.callType18" : "สายธุรกิจ RBT",
    "cms.calllink.field.callType17" : "ค่าธรรมเนียมการโทรเข้าระหว่างประเทศ",
    "cms.report.field.IVRInBoundField" : "สายขาเข้า IVR",
    "cms.calllink.field.callType16" : "คำขอการโทรกลับ",
    "cms.report.field.agentOutSuccField" : "เชื่อมต่อการโทรขาออกด้วยตนเอง",
    "cms.calllink.field.callType15" : "ดูตัวอย่างการโทรออก",
    "cms.calllink.field.callType14" : "การโทรออกแบบ PREDICTED",
    "cms.report.message.playVoiceTimeDesc" : "ระยะเวลาการเล่นด้วยเสียง",
    "cms.mediaType20" : "โทรแบบไม่เรียลไทม์",
    "cms.mediaType53": "มัลติมีเดีย",
    "cms.calllink.field.callType13" : "การโทรออกที่เชื่อมต่อล่วงหน้า",
    "cms.report.message.agentOfferedDesc" : "จำนวนการโทรเข้าทั้งหมดที่ร้องขอการบริการของเจ้าหน้าที่",
    "cms.calllink.field.callType12" : "การโทรออกยุ่งเกินไป",
    "cms.callcenterinstanceslist.message.passValidateFailed" : "รหัสผ่านต้องมีอย่างน้อยสามประเภทดังต่อไปนี้: อักษรตัวพิมพ์ใหญ่ ตัวพิมพ์เล็ก ตัวเลข และอักขระพิเศษต่อไปนี้: {'~ ! @ # $ % ^ & * () - _ = + | [ { } ] ; : \' \"  , < . > / ?'}",
    "cms.calllink.field.callType11" : "การโทรออกที่สงวนไว้",
    "cms.calllink.field.callType10" : "การโทรออก (เจ้าหน้าที่ระหว่างประเทศ)",
    "cms.agentMonitor.label.forcebusy_success" : "เจ้าหน้าที่ถูกบังคับให้ตั้งค่าสถานะเป็นไม่ว่างสำเร็จ",
    "cms.skilladjustment.message.tipsSelectSkill" : "เลือกลำดับทักษะ",
    "cms.report.field.outCallRate" : "อัตราการเชื่อมต่อการโทรออก",
    "cms.report.field.maxQueueCount" : "จํานวนสูงสุดของการโทรพร้อมกันในคิว",
    "cms.report.title.callCenterAccessMonitor" : "การตรวจสอบสถิติเกี่ยวกับประสิทธิภาพของ VDN ด้วยรหัสการเข้าถึง",
    "cms.report.field.outOccupyNum" : "การโทรออก",
    "cms.calllink.field.leaveReason54" : "อัตราการบรรลุผลการโทรสำเร็จต่ำกว่าเกณฑ์",
    "cms.report.message.inVdnAvgTimeDesc" : "ระยะเวลาการโทรขาเข้าเฉลี่ยใน VDN ค่าคือระยะเวลาการโทรเข้าทั้งหมดหารด้วยจํานวนการโทรเข้า",
    "cms.calllink.field.leaveReason53" : "ผู้เข้าร่วมถูกลบออกจากการประชุม",
    "cms.sendnote.message.tipsSuccess" : "ส่งบันทึกสำเร็จแล้ว",
    "cms.calllink.field.leaveReason52" : "ประธานจะปล่อยสายการประชุมเชิงรุกหรือการประชุมได้รับการปล่อยสายเนื่องจากข้อยกเว้นของ CCS",
    "cms.calllink.field.leaveReason51" : "ผู้ใช้โทรเพื่อเข้าสู่การประชุมทางโทรศัพท์",
    "cms.calllink.field.leaveReason50" : "เจ้าหน้าที่ปฏิเสธการให้บริการ",
    "cms.voiceinspection.field.queryBeginTime" : "เวลาเริ่มต้น",
    "cms.report.field.reportPeriod" : "ขั้นตอนทางสถิติ",
    "cms.monitordetail.field.media" : "Multimedia",
    "cms.recorddrive.field.number" : "เลขที่",
    "cms.indexmonitor.label.CommercialUse" : "การใช้งานเชิงพาณิชย์",
    "cms.mediaType19" : "วิดีโอคอลปกติ",
    "cms.uapmonitor.field.callIpSize" : "ประเภท IP",
    "cms.mediaType18" : "คลิกเพื่อโทรวิดีโอคอล",
    "cms.mediaType17" : "สื่อข้อความ",
    "cms.monitordetail.field.adjustCount" : "จำนวนครั้งที่เจ้าหน้าที่เข้าสู่สถานะการจัดการ",
    "cms.indexmonitor.message.skillOnlineConnectedDesc" : "จำนวนการตอบรับสายโดยเจ้าหน้าที่ในช่วง 5 นาที ช่วง 5 นาทีจะเริ่มตั้งแต่ 00:00 ถึง 00:05, 00:05 ถึง 00:10 เป็นต้น",
    "cms.indexmonitor.message.skillLimit": "เลือกคิวสกิล 1 ถึง 10 คิว.",
    "cms.indexmonitor.label.callOutIVR" : "จำนวนการโทรออกออนไลน์ระบบ IVR",
    "cms.mediaType16" : "ตัวอย่างการโทรออก",
    "cms.reportsubscribe.field.sentReport" : "ส่งรายงาน",
    "cms.mediaType15" : "การโทรออกแบบ PREDICTED",
    "cms.mediaType14" : "การโทร OPS",
    "cms.report.field.maxQueueOutCountField" : "จํานวนสูงสุดของการโทรออกพร้อมกันในคิว",
    "cms.mediaType13" : "6B+วิดีโอคอล D ISDN",
    "cms.fullscreen.field.simsun" : "SimSun",
    "cms.mediaType12" : "2B+วิดีโอคอล D ISDN",
    "cms.mediaType11" : "การถ่ายโอนไฟล์",
    "cms.mediaType10" : "การแชร์แอปพลิเคชัน",
    "cms.sysparam.config.value" : "ค่าพารามิเตอร์",
    "cms.uapmonitor.labe.eidoverlength" : "จํานวนส่วนของหมายเลขเทอร์มินัลเกินขีด จํากัด บน: 10",
    "cms.report.message.busyCountDesc" : "จำนวนครั้งที่เจ้าหน้าที่เข้าสู่สถานะไม่ว่าง",
    "cms.monitordetail.field.busyCount" : "จำนวนครั้งที่เจ้าหน้าที่เข้าสู่สถานะไม่ว่าง",
    "cms.calllink.field.leaveReason48" : "ตอบคำถามการโทรในคิวหลักคิวรอง",
    "cms.calllink.field.leaveReason47" : "โทรเข้าคิวในหลายคิว",
    "cms.calllink.field.leaveReason46" : "ไม่สามารถเข้าถึงเจ้าหน้าที่ได้ กำลังเปลี่ยนเส้นทาง CDN",
    "cms.fullscreen.field.indexDimension" : "วัตถุที่ถูกตรวจสอบ",
    "cms.calllink.field.leaveReason45" : "เข้าสู่ PGR จากการปรึกษาตัวแทน",
    "cms.calllink.field.leaveReason44" : "กำหนดเส้นทางการโทรเข้าสู่ PGR",
    "cms.calllink.field.leaveReason43" : "CDN ส่งคืนเส้นทางปกติไปยัง PGR",
    "cms.calllink.field.leaveReason42" : "เส้นทาง CDN หมดเวลาและเข้าสู่ PGR",
    "cms.calllink.field.leaveReason41" : "การยกเลิกเจ้าหน้าที่หรือการกำหนดเส้นทางล้มเหลวในระหว่างการให้คำปรึกษา",
    "cms.report.field.endTime" : "วันที่สิ้นสุด",
    "cms.calllink.field.leaveReason40" : "หมดเวลาการกำหนดเส้นทาง CDN",
    "cms.qualitycheck.message.saveSuccess" : "บันทึกสำเร็จ",
    "cms.report.field.callTypeField" : "ประเภทการโทร",
    "cms.monitordetail.field.restoutduration" : "หมดระยะเวลาพัก",
    "cms.calllink.field.activeRelease" : "ผู้ใช้บริการยกเลิกการโทร",
    "cms.report.field.abortQueueWaitNumField" : "สายที่ไม่ได้รับการบริการ",
    "cms.fullscreen.field.noanswerCountDesc" : "จํานวนครั้งที่ตัวแทนไม่รับสายในเวลาหลังจากสายถูกจัดสรรให้กับตัวแทน",
    "cms.report.message.inputZipPassword": "โปรดป้อนรหัสผ่านการบีบอัด",
    "cms.report.task.sucess.export": "สร้างงานส่งออกแล้ว คุณแน่ใจหรือไม่ว่าต้องการดูมันในรายการส่งออก?",
    "cms.report.task.export.records": "การส่งออกระเบียนผู้ติดต่อ",
    "cms.report.field.length.limit": "ค่าต้องเป็นตัวเลขที่มีอักขระสูงสุด 0 ตัว",
    "cms.report.field.number": "หมายเลขไม่ถูกต้อง",
    "cms.report.field.view.export.task": "ดูเอ็กซ์พอร์ตงาน",
    "cms.report.field.enter.callin": "โปรดป้อนหมายเลขประจําเครื่องของสายเรียกเข้า",
    "cms.agentmonitor.label.agenttotal"  : "เจ้าหน้าที่ทั้งหมด",
    "cms.agent.lable.alarm"    : "การแจ้งเตือน",
    "cms.agent.label.agentlist" : "รายชื่อตัวแทน",
    "cms.agent.label.agentdetailInfo" : "รายละเอียดตัวแทน",
    "cms.agent.label.enterdetail"  : "ดูรายละเอียด",
    "cms.agent.label.statetime" : "ระยะเวลาสถานะ",
    "cms.agent.label.duracount" : "สถิติระยะเวลา",
    "cms.agent.label.numcount" : "สถิติจํานวนครั้ง",
    "cms.agent.label.agentname" : "ชื่อตัวแทน",
    "cms.agent.label.logintime"  : "ระยะเวลาออนไลน์",
    "cms.agent.label.idlenum"  : "จํานวนการว่าง",
    "cms.agent.label.arrangetime" : "ระยะเวลาการจัดเรียง",
    "cms.agent.label.logouttime" : "ระยะเวลาออฟไลน์",
    "cms.agent.label.alarmstate" : "สถานะการแจ้งเตือน",
    "cms.agent.label.watchdetail" : "ดูรายละเอียด",
    "cms.agent.label.agentDetail" : "รายละเอียดตัวแทน",
    "cms.input.format.invalid": "รูปแบบการป้อนข้อมูลไม่ถูกต้อง",
    "cms.normal.text.validate": "อนุญาตให้ป้อนตัวเลข ตัวอักษร และ-._{'@'} เท่านั้น",
    "cms.calllind.field.deviceUnkown" :"อุปกรณ์ที่ไม่รู้จัก",
    "cms.agent.label.signinphone": "ลงทะเบียนหมายเลข",
    "cms.agent.label.currentagentstate" : "สถานะปัจจุบันของตัวแทน",
    "cms.agent.label.currentstatetime": "ระยะเวลาสถานะปัจจุบัน",
    "cms.agent.label.choosestatetime": "เลือกสถานะระยะเวลาสั้นที่สุด",
    "cms.agent.label.busyitem":"ธุระ",
    "cms.agent.label.talkitem":"โทรหา",
    "cms.agent.label.online":"ออนไลน์",
    "cms.agent.label.offline":"ออฟไลน์",
    "cms.agent.label.callinrate": "อัตราการเปิดรับ",
    "cms.agent.label.callintime": "ระยะเวลาการโทร",
    "cms.agent.label.avgcallintime":"เปลี่ยนระยะเวลาการโทรโดยเฉลี่ย",
    "cms.agent.label.avgwaittime": "ระยะเวลาการรอโดยเฉลี่ย",
    "cms.agent.label.maxwaittime":"ระยะเวลาในการรอสูงสุด",
    "cms.agent.label.calloutsuccrate":"อัตราการเปิดรับ",
    "cms.agent.label.callouttime": "ระยะเวลาการโทรออก",
    "cms.agent.label.avgcallouttime":"แปลระยะเวลาการโทรโดยเฉลี่ย",
    "cms.agent.label.choosesybmediatype":"เลือกประเภทช่อง",
    "cms.agent.label.busytime":"ระยะเวลาว่าง",
    "cms.agent.label.avgbusytime":"ระยะเวลาการว่างทั้งหมด",
    "cms.agent.label.acgtalktime":"ระยะเวลาการโทรเฉลี่ย",
    "cms.agent.label.outresttime":"ระยะเวลาพักพัก",
    "cms.agent.label.totaltalktime":"ระยะเวลาการโทรทั้งหมด",
    "cms.agent.label.redirtnum":"เปลี่ยนเส้นทางจํานวนครั้ง",
    "cms.agent.label.totaltalknums":"จํานวนการโทรทั้งหมด",
    "cms.agentmonitor.label.dayStatistic": "สถิติประจําวัน",
    "cms.agentmonitor.label.countAndTime": "จํานวน/ ระยะเวลา",
    "cms.agentmonitor.label.Performance": "ประสิทธิภาพ",
    "cms.agentmonitor.label.signInDetail": "รายละเอียดการลงชื่อเข้าใช้",
  "cms.agentmonitor.syncScreenMonitor.getVrpIpError": "เจ้าหน้าที่ที่ตรวจสอบไม่ได้ลงชื่อเข้าใช้ในไคลเอ็นต์ VRC",
  "cms.agentmonitor.syncScreenMonitor.agentStatus": "โปรดลงชื่อเข้าระบบก่อนตรวจสอบ",
    "cms.agentmonitor.label.idleStatistic": "ว่าง",
    "cms.agent.tips.selectstatetime": "เลือกระยะเวลาของสถานะ, เลือกสถานะปัจจุบัน",
    "cms.agent.label.selectstatetime":">=ระยะเวลาสถานะ (นาที)",
    "cms.agent.tips.sipaccountcheck":"โปรดเลือกเลขเต็มจํานวน 1 ถึง 32 หลักเพื่อลงทะเบียน",
    "cms.indexmonitor.field.totalAgentConnectCallTime": "ระยะเวลาการโทร",
    "cms.indexmonitor.title.agentDate":"ข้อมูลตัวแทน",
    "cms.agent.tips.notassined":"ยังไม่ได้กําหนด",
    "cms.indexmonitor.set.limitA": "เลือก",
    "cms.indexmonitor.set.limitB": "ตัวบ่งชี้สําหรับการแสดงผล",
    "cms.indexmonitor.titile.indicatorSetting": 'การตั้งค่าตัวบ่งชี้',
    "cms.indexmonitor.realTime.indicator": "เมตริกแบบเรียลไทม์",
    "cms.indexmonitor.index.multimediaCalls": "มัลติมีเดีย",
    "cms.indexmonitor.index.callTrends": "แนวโน้มการโทร",
    "cms.indexmonitor.index.skillQueueServiceLevel": "ภาพรวมระดับบริการคิวทักษะ",
    "cms.indexmonitor.index.met": "ตกลง",
    "cms.indexmonitor.index.missed": "ยังไม่เสร็จ",
    "cms.incoming.record.not.sameday": "ไม่รองรับการสืบค้นแบบข้ามวัน",
    "cms.requiredcallback.message.allowTimeRange":"ข้อมูลสนับสนุนสูงสุด 7 วัน",
    "cms.incoming.record.field.releasecause": " ปล่อยสาเหตุ",
    "cms.incoming.record.field.releasecause.input": "โปรดป้อนข้อมูลการปล่อยของไฟร์วอลล์",
    "cms.incoming.record.field.releasecause.id": "โปรดป้อนรหัสสาเหตุการปล่อยของสวิตช์",
    "cms.incoming.record.field.releasecause.causecode": "โปรดป้อนรหัสสาเหตุ",
    "cms.incoming.record.field.ifReqManual": "เคยร้องขอมนุษย์หรือไม่",
    "cms.incoming.record.field.isReqManual": "ร้องขอมนุษย์",
    "cms.incoming.record.field.notReqManual": "ไม่ร้องขอมนุษย์",
    "cms.incoming.record.field.ifAnswerCall": "มนุษย์รับสายหรือไม่",
    "cms.incoming.record.field.isAnswerCall": "มนุษย์รับสาย",
    "cms.incoming.record.field.notAnswerCall": "ไม่รับสายโดยมนุษย์",
    "cms.incoming.record.field.releasecause.choose": "เลือกสวิตช์เพื่อปล่อยสาเหตุ",
    "cms.incoming.record.field.release.id": " ปล่อยรหัสสาเหตุ",
    "cms.incoming.record.field.release.causecode": "รหัสแผนที่สาเหตุ",
    "cms.incoming.releasecause.causecode.tip": "รหัสการทําแผนที่สาเหตุเกินขอบเขต (0~45)",
    "cms.incoming.record.direct.beginTime": "การสืบค้นเวลาเริ่มต้น",
    "cms.incoming.record.direct.endTime": "การสืบค้นเวลาสิ้นสุด",
    "cms.requiredcallback.message.timeRangeCheck": "ช่วงเวลาคือวันเดียวกัน",
    "cms.failurecall.field.waitTime.export": "ระยะเวลารอ(s)",
    "cms.failurecall.field.waitBegin": " รอเวลาเริ่มต้น",
    "cms.failurecall.field.ackBegin": "[i-Fi ตอบเวลาเริ่มต้น",
    "cms.failurecall.field.waitAnsNum": "จํานวนการรอคําตอบ",
    "cms.failurecall.field.waitAnsTime": "ระยะเวลารอคําตอบ (วินาที)",
    "cms.failurecall.field.succWaitAnsTime": "ระยะเวลาในการตอบรับสายสําเร็จ (วินาที)",
    "cms.failurecall.field.noAckNum": "จํานวนการตอบกลับที่ไม่มีการโทร",
    "cms.failurecall.field.succQueueWaitTime": "ระยะเวลาในการโทรเข้าคิวที่ประสบความสําเร็จ (วินาที)",
    "cms.failurecall.field.succQueueWaitNum": "จํานวนการโทรเข้าเข้าที่สําเร็จ",
    "cms.failurecall.field.failQueueWaitNum": "ระยะเวลารอ (วินาที)",
    "cms.failurecall.field.inOccupyNum": "จํานวนคําขอของมนุษย์",
    "cms.failurecall.field.inCallSuccNum": "จํานวนการโทรด้วยตนเอง",
    "cms.failurecall.field.inCallTime": "ระยะเวลาในการโทรของมนุษย์ (วินาที)",
    "cms.failurecall.field.queOverToIvrNum": "คิวเป็นเลขทศนิยมที่แขวนอยู่",
    "cms.failurecall.field.queOverToIvrTime": "คิวต่อเวลาทั้งหมด (วินาที)",
    "cms.failurecall.field.hangUpNum": "จํานวนครั้งที่เปลี่ยนจากมนุษย์เป็น IVR",
    "cms.failurecall.field.hangUpTime": "เวลารวมของ IVR เทียม",
    "cms.failurecall.field.hangUpReleaseNum": "การหมุนหมายเลข IVR ด้วยตนเอง",
    "cms.indexmonitor.index.noTrafficData": "ไม่มีข้อมูลเครือข่าย",
    "cms.indexmonitor.index.SkillQueueTips": "ระดับบริการคิวทักษะจะวัดอัตราการเปิดรับภายใน 20 วินาที โดยระดับบริการที่คาดหวังไว้คือ 80% โดยผู้ดูแลระบบผู้เช่าสามารถแก้ไขได้ในการกําหนดค่าพารามิเตอร์ของผู้เช่า: พารามิเตอร์ผู้เช่า > การตรวจสอบการเชื่อมต่อศูนย์ > ตัวบ่งชี้ > ระดับบริการที่คาดหวังของคิวทักษะ",
    "cms.indexmonitor.index.SkillQueueTips2": "ระดับบริการคิวทักษะจะวัดอัตราการเปิดรับภายใน 20 วินาที โดยมีระดับบริการตามค่าเริ่มต้นอยู่ที่ 80% และผู้ดูแลระบบสามารถแก้ไขได้ในการกําหนดค่าพารามิเตอร์: พารามิเตอร์ของระบบ > การตรวจสอบการเชื่อมต่อของศูนย์สวิตช์ > การตรวจสอบตัววัด > ระดับบริการที่คาดหวังของคิวทักษะ",
    "cms.indexmonitor.index.loadFail": "การโหลดล้มเหลว",
    "cms.failurecall.queue.abandon": "ยกเลิกคิวการสืบค้น",
    "cms.failurecall.callback": "ต้องการคืนค่าการสืบค้นประวัติ",
    "cms.faliurecall.incomingcall": "การสืบค้นบันทึก",
    "cms.vcallecenter.message.checkpasswordfailed": "ล้มเหลวในการเช็คอินกฎรหัสผ่าน",
    "cms.agent.field.admin":"ผู้ดูแลระบบ",
    "cms.agent.field.commonagent":"เจ้าหน้าที่",
    "cms.agent.field.qualitychecker":"ผู้ตรวจสอบคุณภาพ",
    "cms.failure.callerNo.maxLength.error":"อนุญาตให้ใช้อักขระสูงสุดได้ 64 ตัวอักษร",
    "cms.fullscreen.field.hisMonitor":"สถิติของตัวชี้วัดทางประวัติศาสตร์",
    "cms.report.message.nowTimeCheck":"เวลาเกินเวลาปัจจุบัน",
    "cms.fullscreen.field.report0":"รายงานประจําวัน (เวลาสัมพัทธ์)",
    "cms.fullscreen.field.report1":"รายงานประจําวัน (เวลาคงที่)",
    "cms.indexmonitor.curDay.indicator":"ตัวชี้วัดวัน",
    "cms.fullscreen.field.reportTime":"เลือกเวลา",
    "cms.incoming.record.report.endTime":"เวลาสิ้นสุดการสืบค้น",
    "cms.fullscreen.field.reportType":"ประเภทรายงาน",
    "cms.fullscreen.field.reportDate":"ก่อนหน้า {0} วัน",
    "cms.incoming.record.report.beginTime":"สืบค้นเวลาเริ่มต้น",
    "cms.custdata.operlog.popwindowmessage":"โปรดป้อนรหัสผ่านสําหรับการสร้างแฟ้มบีบอัด รหัสผ่านการบีบอัดควรมีตัวอักษรตัวเลขอักขระพิเศษ _",

  "cms.report.title.submediaType" : "ประเภทมัลติมีเดีย",
  "cms.multimedia.monitor.field.callNum":"จํานวนการโทร IVR",
  "cms.multimedia.monitor.field.transNum":"จํานวนสาย IVR-to-Manual",
  "cms.multimedia.monitor.field.transRate":"อัตรา IVR-to-Manual",
  "cms.multimedia.monitor.field.connectedNum":"จํานวนการโทร IVR ที่เชื่อมต่อ",
  "cms.multimedia.monitor.field.connectedRate":"อัตราการเสร็จสิ้นการโทร IVR",
  "cms.multimedia.monitor.field.durationAverage":"ระยะเวลาการโทร IVR เฉลี่ย",
  "cms.multimedia.monitor.field.callNum.desc":"จํานวนการโทรที่อุปกรณ์ IVR ให้บริการโดยอัตโนมัติในปัจจุบัน",
  "cms.multimedia.monitor.field.transNum.desc":"จํานวนสาย IVR-to-Manual",
  "cms.multimedia.monitor.field.transRate.desc":"เปอร์เซ็นต์ของการโทร IVR-to-Manual ไปยังการโทร IVR",
  "cms.multimedia.monitor.field.connectedNum.desc":"จํานวนการโทร IVR ที่มีระยะเวลาการโทรมากกว่า 0",
  "cms.multimedia.monitor.field.connectedRate.desc":"เปอร์เซ็นต์ของจํานวนการโทร IVR ที่เชื่อมต่อไปยังจํานวนการโทร IVR",
  "cms.multimedia.monitor.field.durationAverage.desc":"ระยะเวลาการโทร IVR ทั้งหมด/จํานวนการโทร IVR ที่เชื่อมต่อ",
  "cms.report.field.submediatype.instagram": "Instagram",
  "cms.report.field.submediatype.telegram": "Telegram",
  "cms.qcrelationconf.field.attendantGroupName": "ชื่อกลุ่มผู้เข้าร่วม",
  "cms.report.field.selectTrafficGroup": "เลือกกลุ่มผู้เข้าร่วม",
  "cms.report.placeholder.attendantGroupName": "โปรดป้อนชื่อกลุ่มผู้เข้าร่วม",
  "cms.qcrelationconf.field.attendantGroup": "กลุ่มผู้เข้าร่วมประชุม",
  "cms.report.message.selectTrafficGroupMax": "สามารถเลือกกลุ่มผู้เข้าร่วมได้สูงสุด 1000 กลุ่ม",
  "cms.agentMonitor.message.operatorChecked": "การดําเนินการล้มเหลว ผู้ตรวจสอบคุณภาพเป็นผู้ตรวจสอบคุณภาพ และกําลังดําเนินการตรวจสอบคุณภาพ",
  "cms.export.message.required": "จำเป็นต้องกรอก",
  "cms.field.length.limit": "ความยาวไม่เกิน 0",
  "cms.sysparameter.batchreset": "รีเซ็ตเป็นชุด",
  "cms.sysparameter.batchrefresh": "การซิงโครไนซ์เป็นชุด",
  "cms.report.subscribe.add": "การสมัครรับรายงานใหม่",
  "cms.report.subscribe.edit": "แก้ไขการสมัครรับรายงาน",
  "cms.qcrelationconf.tab.member": "สมาชิก",
  "cms.qcrelationconf.add.attendantGroup": "เพิ่มกลุ่มที่ตรวจสอบ",
  "cms.qcrelationconf.message.isDeleteTrafficGroup": "ตรวจสอบให้แน่ใจว่าได้ลบกลุ่มเซสชันนี้แล้ว",
  "cms.report.subscribe.checkreportnum": "เลือกรายงานอย่างน้อยหนึ่งฉบับ",
  "cms.sysparameter.edit.basic.information": "แก้ไขข้อมูลพื้นฐาน",
  "cms.sysparameter.edit.character": "แก้ไขคุณสมบัติ",
  "cms.sysparameter.edit.validate.ip": "ป้อนที่อยู่ IP ที่ถูกต้อง",
  "cms.message.choose": "โปรดเลือกข้อมูล.",
  "cms.subscribe.template.isused": "มีการใช้แม่แบบอีเมลอยู่แล้ว",
  "cms.subscribe.template.statuserror": "สถานะแม่แบบอีเมลไม่ถูกต้อง.",
  "cms.qcrelationconf.message.unauthorized": "เจ้าหน้าที่ที่ผิดกฎหมายในการจัดเก็บข้อมูล.",
  "cms.report.subscribe.editreport":"แก้ไขรายงาน",
  "cms.report.subscribe.addreport":"การเพิ่มรายงาน",
  "cms.fullscreen.agent.auth": "ไม่มีสิทธิ์ในการดูองค์กรปัจจุบัน โปรดเลือกอีกครั้ง",
  "cms.agentMonitor.label.changestate_error":"การดําเนินการไม่ถูกต้องเนื่องจากการสลับสถานะการตรวจสอบล้มเหลว",
  "cms.agent.label.systempara":"การกําหนดค่าพารามิเตอร์",
  "cms.indexmonitor.message.skillConnectedQueue": "ระยะเวลารวมของสายที่เชื่อมต่อทั้งหมดในคิว",
  "cms.indexmonitor.message.skillAbandonQueue": "ระยะเวลารวมของสายที่ไม่ได้รับทั้งหมดในคิว",
  "cms.indexmonitor.field.skillMinQueueAck" : "ระยะเวลาการรอสายต่ำสุด",
  "cms.indexmonitor.field.skillConnectedQueue": "ระยะเวลาในการโทรทั้งหมด",
}