export default {
  "ccd.agent.agentevent.AgentOther_All_Agent_Busy": "Todos os agentes estão ocupados.",
  "ccd.agent.agentevent.AgentOther_Restskill_Queue": "Operação de fila de habilidades bem-sucedida.",
  "ccd.agent.label.offline": "Off-line",
  "ccd.agent.agentevent.AgentEvent_Customer_Release": "O cliente saiu da chamada.",
  "ccd.agent.button.hangUp": "Desligar",
  "ccd.agent.agentevent.AgentEvent_Call_Out_Fail": "O agente não inicia uma chamada externa.",
  "ccd.agent.agentevent.AgentEvent_Auto_Answer": "O agente atende automaticamente a chamada.",
  "ccd.agent.button.transfer": "Transferir",
  "ccd.agent.openeye.sipconfig.success": "O SIP foi configurado com sucesso",
  "ccd.agent.button.mediaswitch": "Seletor de áudio/vídeo",
  "ccd.agent.agentevent.feature.caller": "Número do originador da chamada:",
  "ccd.agent.agentevent.AgentEvent_Conference": "A chamada a três foi efetuada com sucesso.",
  "ccd.agent.button.sayFree": "Exibir Disponível",
  "ccd.agent.agentevent.AgentEvent_Talking": "O agente entrou no estado Falando.",
  "ccd.agent.webrtc.label.misspeaker": "O alto-falante não está disponível.",
  "ccd.agent.agentevent.AgentEvent_ReturnFromIvr": "A chamada é retornada do IVR para o agente com sucesso.",
  "ccd.agent.agentevent.AgentEvent_No_Answer": "A chamada é liberada porque um agente não atende à chamada.",
  "ccd.agent.tips.confSuccess": "Configuração realizada com sucesso.",
  "ccd.agent.webrtc.label.unsupportbrower": "O navegador não é compatível com esta função.",
  "ccd.agent.agentevent.AgentState_Force_SetNotReady": "O agente é definido para o estado ocupado com sucesso.",
  "ccd.agent.agentevent.AgentState_SetRest_Success": "O agente é definido para o estado de repouso com sucesso.",
  "ccd.agent.agentevent.AgentEvent_Agent_Occupyed": "O agente está ocupado.",
  "ccd.agent.agentevent.success": "sucesso",
  "ccd.agent.agentevent.feature.called": "Número chamado:",
  "ccd.agent.button.innerHelp": "Buscar ajuda",
  "ccd.agent.agentevent.AgentEvent_Call_Release": "O agente sai da chamada.",
  "ccd.agent.agentevent.AgentConf_SelfJoinResult": "Agente Ingressando em uma conferência",
  "ccd.agent.webrtc.label.sotpsendvideo": "Parar envio de vídeo",
  "ccd.agent.agentevent.AgentMediaEvent_StopPlayDone": "A reprodução da gravação é interrompida.",
  "ccd.agent.agentevent.mediaAblity.voiceandvideo": "Áudio e vídeo",
  "ccd.agent.webrtc.label.connetfailed": "Falha",
  "ccd.agent.webrtc.label.buildfailed": "Falha ao configurar a conexão.",
  "ccd.agent.tips.confFail": "Falha na configuração.",
  "ccd.agent.agentevent.AgentEvent_Hold": "A chamada é colocada em espera com sucesso.",
  "ccd.agent.agentevent.AgentState_CancelWork_Success": "O agente cancela o estado de trabalho com sucesso.",
  "ccd.agent.button.signOut": "Sair",
  "ccd.agent.agentevent.AgentEvent_Consult_Fail": "Falha na busca da ajuda do agente.",
  "ccd.agent.webrtc.label.small": "Pequeno",
  "ccd.agent.openeye.deamonready.fail": "Falha ao iniciar o OpenEye. Registe-se manualmente e faça login no OpenEye. Caso contrário, a função de resposta automática não pode ser usada.",
  "ccd.agent.tips.success": "sucesso",
  "ccd.agent.openeye.deamonready": "O OpenEye foi iniciado.",
  "ccd.agent.openeye.deamonready.failinfo": "Falha ao iniciar o OpenEye.",
  "ccd.agent.agentevent.AgentEvent_Ringing": "O agente está recebendo uma chamada.",
  "ccd.agent.webrtc.label.remotevideo": "Vídeo remoto",
  "ccd.agent.openeye.register": "O número do softphone foi registrado com sucesso.",
  "ccd.agent.openeye.already.register": "O número do softphone foi registrado.",
  "ccd.agent.agentevent.mediaAblity.text": "texto",
  "ccd.agent.button.endMute": "Ativar microfone",
  "ccd.agent.agentevent.otherPhone": "Número/ID do telefone do originador da chamada:",
  "ccd.agent.button.rest": "Descansar",
  "ccd.agent.agentevent.AgentState_CancelNotReady_Success": "O agente cancela o estado ocupado com sucesso.",
  "ccd.agent.openeye.register.fail": "O número do softphone já está registrado.",
  "ccd.agent.label.reply": "Responder",
  "ccd.agent.agentevent.callType": "Tipo de chamada:",
  "ccd.agent.resetphonepass.inputagain": "Confirmar a senha",
  "ccd.agent.agentevent.failed": "Falha",
  "ccd.agent.agentevent.AgentMediaEvent_PausePlayDone": "A reprodução da gravação é pausada com sucesso.",
  "ccd.agent.agentevent.AgentEvent_Release_Preview": "A chamada pré-visualizada é liberada com sucesso.",
  "ccd.agent.message.agentNoConfSkill": "O agente não está configurado na fila de habilidades.",
  "ccd.agent.agentevent.AgentEvent_Preview": "O agente recebe a solicitação de chamada visualizada com sucesso.",
  "ccd.agent.webrtc.label.reject": "Rejeitar",
  "ccd.agent.button.notscreenShare": "Cancelar compartilhamento da área de trabalho",
  "ccd.agent.webrtc.label.videosize": "Tamanho do vídeo",
  "ccd.agent.agentevent.AgentEvent_Preview_CallOut.controlid": "ID de controle de bloqueio de chamada prévisualizada:",
  "ccd.agent.agentevent.feature.callin": "Chamada recebida comum",
  "ccd.agent.openeye.autoanswer.fail": "Falha ao atender a chamada automaticamente.",
  "ccd.agent.agentevent.AgentEvent_Hold.desc": "O agente está mantendo uma chamada em espera.",
  "ccd.agent.webrtc.label.medium": "Médio",
  "ccd.agent.webrtc.label.sippwd": "Senha do Softphone",
  "ccd.agent.webrtc.label.online": "Registrado",
  "ccd.agent.agentevent.AgentState_Ready": "O agente definiu disponível com sucesso.",
  "ccd.agent.frontpage.callreason": "Motivo da chamada",
  "ccd.agent.agentevent.AgentMediaEvent_JumpPlayDone": "A gravação é encaminhada rápida ou rebobinada com sucesso.",
  "ccd.agent.button.screenShare": "Compartilhar área de trabalho",
  "ccd.agent.agentevent.AgentEvent_Customer_Alerting": "O telefone toca.",
  "ccd.agent.agentevent.AgentEvent_No_Answer.number": "Número da parte desligada:",
  "ccd.agent.webrtc.label.talk": "Chamando",
  "ccd.agent.agentevent.info": "Dicas",
  "ccd.agent.agentevent.AgentState_SetWork_Success": "O agente entra no estado de trabalho.",
  "ccd.agent.agentevent.AgentEvent_Inside_Call_Fail": "O agente falhou ao iniciar uma chamada interna.",
  "ccd.agent.agentevent.AgentOther_PhoneAlerting": "O agente está a tocar",
  "ccd.agent.button.answer": "Responder",
  "ccd.agent.agentevent.mediaAblity.video": "Vídeo",
  "ccd.agent.agentevent.callId": "ID Chamada",
  "ccd.agent.agentevent.AgentOther_PhoneRelease": "O agente desligou",
  "ccd.agent.button.sayBusy": "Definir ocupado",
  "ccd.agent.webrtc.label.connect": "Registrar-se",
  "ccd.agent.agentevent.AgentMediaEvent_Play": "A reprodução de gravação começa.",
  "ccd.agent.query.sip.fail": "Falha ao consultar dados SIP. Inicie sessão como agente novamente ou faça login manualmente no OpenEye.",
  "ccd.agent.webrtc.label.large": "Grande",
  "ccd.agent.button.threeParty": "Terceiro",
  "ccd.agent.agentevent.AgentOther_PhoneOffhook": "O agente ficou fora do gancho",
  "ccd.agent.flash.calling": "Chamando",
  "ccd.agent.button.mute": "Mudo",
  "ccd.agent.button.twostage": "Dialpad",
  "ccd.agent.agentevent.warning": "Aviso",
  "ccd.agent.webrtc.label.offline": "Não registrado",
  "ccd.agent.openeye.videowindowparam.fail": "Falha ao inicializar os parâmetros de vídeo, causando anormalidades nas funções de vídeo.",
  "ccd.agent.message.AgentIsEmpty": "Conta não vinculada a um Agente",
  "ccd.agent.label.rest": "Pausa",
  "ccd.agent.agentevent.mediaAblity": "Tipo de mídia:",
  "ccd.agent.label.idle": "Disponível",
  "ccd.agent.tips.info": "Dicas",
  "ccd.agent.agentevent.feature.callout": "chamada originada comum",
  "ccd.agent.flash.message": "Mensagem",
  "ccd.agent.button.signIn": "Entrar",
  "ccd.agent.label.busy": "Ocupado",
  "ccd.agent.resetphonepass.inputcur": "Senha da conta",
  "ccd.agent.webrtc.label.localvideo": "Vídeo local",
  "ccd.agent.openeye.videowindowparam": "Os parâmetros de vídeo são inicializados com sucesso.",
  "ccd.agent.resetphonepass.input": "Senha",
  "ccd.agent.agentevent.AgentState_Work": "O agente entra no estado de finalização",
  "ccd.agent.agentevent.AgentEvent_MediaSwith.failed": "Seleção de áudio/vídeo falhou.",
  "ccd.agent.agentevent.AgentEvent_MediaSwith.success": "Seleção de áudio/vídeo com sucesso.",
  "ccd.agent.agentevent.AgentState_Busy": "O agente está a trabalhar",
  "ccd.agent.callrelease.end": "Chamada encerrada",
  "ccd.agent.button.cancelRest": "Cancelar repouso",
  "ccd.agent.button.restSkillQueue": "Redefinir habilidades",
  "ccd.agent.openeye.poolsipconfig.fail": "Falha ao configurar o POOL. Inicie sessão como agente novamente ou faça login manualmente no OpenEye.",
  "ccd.agent.webrtc.label.videopop": "Janela de vídeo",
  "ccd.agent.button.queryStatus": "Status da Consulta",
  "ccd.agent.agentevent.AgentEvent_Preview_CallOut": "Visualização da chamada de saída bem-sucedida.",
  "ccd.agent.label.adjust": "Pós-atendimento",
  "ccd.agent.agentevent.AgentOther_ShutdownService": "O agente saiu com sucesso.",
  "ccd.agent.agentevent.AgentOther_PhoneUnknow": "O status do telefone do agente é desconhecido.",
  "ccd.agent.webrtc.label.accept": "Responder",
  "ccd.agent.message.AgentIsSupend": "O agente atual está congelado.",
  "ccd.agent.button.getHold": "Tirar espera",
  "ccd.agent.openeye.deregister": "O número do softphone foi desativado com sucesso.",
  "ccd.agent.agentevent.AgentEvent_Preview_CallOut.dialeddigits": "Visualizar o número chamado de saída:",
  "ccd.agent.agentevent.AgentState_CancelRest_Success": "O agente cancelou o descanso com sucesso.",
  "ccd.agent.button.callOut": "chamada originada",
  "ccd.agent.openeye.deregister.fail": "Falha ao desativar o número do softphone. Fazer logout manualmente do OpenEye.",
  "ccd.agent.webrtc.label.missmic": "O microfone não está disponível.",
  "ccd.agent.agentevent.type.assistant": "Chamada colaborativa",
  "ccd.agent.agentevent.mediaAblity.voice": "Áudio",
  "ccd.agent.tips.error": "Erro",
  "ccd.agent.openeye.poolsipconfig.success": "O POOL foi configurado com sucesso.",
  "ccd.agent.webrtc.label.hidelocalvideo": "Ocultar vídeo local",
  "ccd.agent.agentevent.type.main": "Chamada principal",
  "ccd.agent.button.hold": "Em espera",
  "ccd.agent.agentevent.AgentMediaEvent_ResumePlayDone": "A reprodução da gravação é retomada com sucesso.",
  "ccd.agent.agentevent.AgentEvent_Conference.partner": "Número do originador da chamada:",
  "ccd.agent.openeye.sipconfig.fail": "Falha ao configurar o SIP. Faça login como agente novamente ou faça login manualmente no OpenEye.",
  "ccd.agent.agentevent.AgentEvent_Connect_Fail": "Falha na conexão.",
  "ccd.agent.agentevent.AgentOther_InService": "O agente faz login no sistema com sucesso.",
  "ccd.agent.openeye.autoanswer": "A chamada foi atendida automaticamente.",
  "ccd.agent.tips.welcome": "Bem-vindo de volta e bom trabalho!",
  "ccd.agent.label.talk": "Em Ligação",
  "ccd.agent.openeye.videolayoutmode.success": "Conseguiu definir o modo de arranjo de imagem da janela de vídeo.",
  "ccd.agent.openeye.videolayoutmode.fail": "Falha ao definir o modo de organização da imagem da janela de vídeo.",
  "ccd.agent.openeye.videodisplaymode.success": "Conseguiu definir o modo de recorte da janela de vídeo.",
  "ccd.agent.openeye.videodisplaymode.fail": "Falha ao definir o modo de corte da janela de vídeo.",
  "ccd.agent.openeye.share": "Por favor, selecione...",
  "ccd.ccaction.title.shareapp": "Selecionar Programa Compartilhado",
  "ccd.agent.openeye.switchshare.success": "A função de compartilhamento de área de trabalho foi ativada com sucesso.",
  "ccd.agent.openeye.switchshare.fail": "Falha ao ativar a função de compartilhamento de área de trabalho.",
  "ccd.agent.openeye.getapplist.fail": "Falha ao obter a lista de aplicativos aberta.",
  "ccd.agent.openeye.setshareapp.fail": "Falha ao definir o compartilhamento de tela.",
  "ccd.agent.openeye.startshare.fail": "Falha ao iniciar o compartilhamento.",
  "ccd.agent.openeye.stopshare.fail": "Falha ao interromper o compartilhamento.",
  "ccd.agent.webrtc.label.logoutregistered": "Quer fazer logout.",
  "ccd.agent.webrtc.label.passwordvalidator": "O comprimento do caractere inserido excede o intervalo de valores. O comprimento deve ser menor ou igual a 50 caracteres.",
  "ccd.agent.webrtc.label.busy.tip": "Chamada",
  "ccd.agent.webrtc.label.busy.info": "dicas",
  "ccd.agent.webrtc.label.busy.confirm": "Sim",
  "ccd.agent.webrtc.label.button.yes": "Sim",
  "ccd.agent.webrtc.label.button.no": "Não",
  "ccd.agent.webrtc.label.sippwd.placeholder": "Por favor, entre",
  "ccd.agent.webrtc.label.remote.name": "O outro lado",
  "ccd.agent.webrtc.label.local.name": "Agente",
  "ccd.agent.webrtc.label.changeVoice": "Transferir para voz",
  "ccd.agent.login.fail": "Falha no registo",
  "ccd.agent.login.failinfo": ", Atualmente, funções como busca de ajuda interna não estão disponíveis.",
  "ccd.agent.login.multimedia.failinfo": ", Atualmente, as funções de áudio/vídeo e de ajuda interna não estão disponíveis.",
  "ccd.agent.reset.success": "Descanso marcado com sucesso",
  "ccd.agent.openeye.initsuccess": "O OpenEye foi inicializado com sucesso.",
  "ccd.agent.pagination.total": "Total:",
  "ccd.agent.title.consult": "Consultar",
  "ccd.agent.warning.sharescreen": "Preste atenção à privacidade pessoal ao compartilhar o desktop",
  "ccd.agentmonitor.span.listening": "Ouvindo",
  "ccd.agentmonitor.span.insert": "Inserção",
  "ccd.agentmonitor.span.whisper": "Sussurrando",
  "ccd.message.title.audioIncomingCallNotify": "Lembrete de chamadas recebidas em áudio e vídeo",
  "ccd.message.title.mediaIncomingCallNotify": "Lembrete multimídia de chamadas recebidas",
  "ccd.message.title.mediaNewMsgNotify": "Notificações de novas mensagens multimídia",
  "ccd.agentMonitor.label.switchlistenorinsert": "No estado de pré-inspeção, um agente sussurrado não pode clicar no botão Ouvir ou Inserir.",
  "ccd.agent.agentevent.quality.calloutconnectfail": "Não é possível fazer uma chamada de saída porque o QC está em andamento.",
  "ccd.agent.agentevent.calloutconnectfail": "O assinante está ocupado ou o número não existe.",
  "ccd.agent.tips.maxCountQuery.info": "O volume de dados atual é muito grande . Somente os últimos { 0 } dados serão exibidos",
  "ccd.agent.warning.notification.cancel": "Cancelar",
  "ccd.agent.warning.notification.setting": "Configurações",
  "ccd.agent.warning.notification.allowed": "Para proteger os lembretes de mensagens de sessão, você precisa permitir que o site exiba notificações!",
  "ccd.agent.msg.notification.allowed": "O site da AICC solicita permissão para o navegador exibir notificações!",
  "ccd.agent.msg.notification.permission.denied": "O usuário recusou a autorização e não pode exibir a notificação. Por favor, modifique-a nas configurações do navegador",
  "ccd.agent.msg.queryMsg.specialListQuery": "Lembrete da lista especial",
  "ccd.agent.message.AgentIsProhibited": "O uso do locatário foi proibido.",
  "ccd.agent.message.repeatPhonenum": "O número de telefone usado pelo agente para fazer login foi usado por outro agente para fazer login. Verifique a configuração.",
  "ccd.agent.message.destinationAgentFailed": "Falha ao ocupar o agente de destino.",
  "ccd.agent.message.noAgent": "Nenhum agente em serviço.",
  "ccd.agent.warning.waserror": "Erro de recurso WAS inacessível ou gera um erro interno.",
  "ccd.agent.button.offlineSignIn": "Shift Móvel",
  "ccd.agent.button.offlineSignOut": "Sair Móvel",
  "ccd.agent.button.close": "Cancelar",
  "ccd.agent.button.ok": "OK",
  "ccd.quality.message.cancelwhisper": "Parar sussurro",
  "ccd.agent.videocall.stateError": "O status da chamada está incorreto quando o modo de exibição é ajustado para uma chamada de vídeo de três partes.",
  "ccd.cccbar.label.backspace": "Barra de espaço",
  "ccd.agent.videomode.triangular": "Triangular",
  "ccd.ccaction.message.holdError": "A chamada atual não pode ser mantida.",
  "ccd.agent.status.hold": "Em Espera",
  "ccd.agent.message.restInvalid": "O parâmetro ou endereço transferido através da URL não está em conformidade com a definição.",
  "ccd.transfer.message.inputIvrName": "Insira o nome do IVR a ser transferido.",
  "ccd.agent.videomode.maximizeThreeparty": "Maximizar três partes",
  "ccd.ccaction.message.retCode": "Erro Cancelar trabalho ao definir ocupado. Código de erro:",
  "ccd.agent.videocall.paramError": "Erro de parâmetro ao ajustar o modo de exibição de uma chamada de vídeo de três partes.",
  "ccd.transfer.label.queueSize": "Chamadas atuais em fila",
  "ccd.transfer.label.queueSize.ccbar": "Aguarde na fila",
  "ccd.ccaction.message.isForceBusy": "O agente foi mostrado ocupado.Não mostre ocupado repetidamente.",
  "ccd.transfer.message.NoAgentInSkill": "Nenhum agente on-line existe na fila de habilidades atual.",
  "ccd.cccbar.message.syserrormessage": "Erro de sistema.",
  "ccd.agent.status.help": "Buscando ajuda",
  "ccd.ccaction.message.logoutfailed": "Falha ao fazer logout. Verifique o status do agente.",
  "ccm.agent.message.selectIvr": "Selecione um fluxo IVR.",
  "ccd.ccaction.message.search.restCauseDesc": "Informe a descrição do motivo de pausa.",
  "ccd.quality.message.insert": "Inserir",
  "ccd.outgoing.out.workbench": "Workbench de saída",
  "ccd.transfer.label.skill": "Fila de habilidades",
  "ccd.transfer.button.refresh": "Atualizar",
  "ccd.agent.status.innerThree": "Três Partes",
  "ccd.agent.message.threePartyNoCall": "Nenhuma chamada para iniciar uma chamada de três partes.",
  "ccd.innerhelp.label.innerHelpToSkill": "Solicitação de consulta da fila de habilidades",
  "ccd.record.message.stopPlay": "Um erro de estado ocorre quando a reprodução é interrompida.",
  "ccd.transfer.label.loggedOnAgents": "Quantidade do Agente Online",
  "ccd.transfer.label.loggedOnAgents.ccbar": "Agente disponível",
  "ccd.transfer.label.ivr": "IVR",
  "ccd.ccaction.message.abilityError": "Você não pode silenciar o usuário na função de mídia atual.",
  "ccd.agent.message.agentNotLogin": "O agente não faz login.",
  "ccd.agent.videocall.adjustvideo": "Ajustar o tamanho do vídeo",
  "ccd.agent.status.askAnswer": "Aguardando Resposta",
  "ccd.transfer.label.agent": "Agente",
  "ccd.cccbar.label.voice": "Áudio",
  "ccd.cccbar.label.cancel": "Cancelar",
  "ccd.cccbar.label.send": "Enviar",
  "ccd.agent.message.loginInvalidPara": "Falha no login. O parâmetro de login está vazio ou inválido.",
  "ccd.innerhelp.label.innerHelpToAgent": "Solicitação de consulta do agente",
  "ccd.quality.message.whisper": "Sussurrar",
  "ccd.agent.mediaswitch.unsupportedagenttype": "O agente atual não suporta chamadas de vídeo.",
  "ccd.ccaction.message.unHold": "Nenhuma chamada para reter no estado atual.",
  "ccd.agent.title.warning": "Alarme",
  "ccd.cccbar.label.video": "Vídeo",
  "ccd.ccaction.message.errorRetCode": "Erro! Código de erro:",
  "ccd.agent.message.mediaSwitchNoCall": "Nenhuma chamada é feita durante a comutação de áudio / vídeo.",
  "ccd.agent.mediaswitch.switchaudiofailed": "O agente já está em uma chamada de áudio.",
  "ccd.ccaction.message.recording": "A voz só pode ser reproduzida quando o agente está em estado disponível. Altere o status do agente.",
  "ccd.record.message.resumePlay": "Um erro de estado ocorre quando a reprodução continua.",
  "ccd.transfer.label.successTransfer": "Transferência de sucesso",
  "ccd.ccaction.message.confJoinFail": "O agente não faz login e a chamada de terceiros falha.",
  "ccd.agent.message.noHoldCall": "A chamada não foi realizada.",
  "ccd.agent.message.releaseNumberNoCall": "Nenhuma chamada para liberar um número de telefone especificado.",
  "ccd.cccbar.label.total": "Total:",
  "ccd.quality.message.forceexit": "Forçar saída",
  "ccd.agent.videocall.noCall": "Nenhum erro de chamada ocorre quando o modo de exibição de uma chamada de vídeo de três partes é ajustado.",
  "ccd.innerhelp.label.twoPartyHelp": "Consulta de duas partes",
  "ccd.quality.message.interceptErr": "O agente a verificar não está em uma chamada e não pode ser interceptado.",
  "ccd.cccbar.label.callcenter": "Central de atendimento",
  "ccd.quality.message.noAgentInfo": "Nenhuma informação do agente foi encontrada durante o monitoramento da verificação de qualidade.",
  "ccd.cccbar.message.pleaseAnswer": "Responder chamada",
  "ccd.transfer.label.hangUpTransfer": "Transferência de desligar",
  "ccd.agent.message.holdCallStatusErr": "Um erro de estado de chamada ocorre quando você segura a chamada.",
  "ccd.agent.message.mediaSwitchFailed": "Seleção de áudio/vídeo falhou.",
  "ccd.record.message.jumpBack": "Um erro de estado ocorre durante o retrocesso.",
  "ccd.agent.message.endMuteNoCall": "Nenhuma chamada existe quando você cancela o silêncio.",
  "ccd.quality.message.forcebusy": "Forçar ocupado",
  "ccd.transfer.label.externalNumber": "Número externo",
  "ccd.agent.message.beginMuteNoCall": "Não há chamada para silenciar.",
  "ccd.quality.message.noProvider": "O erro NOPROVIDER ocorre durante a verificação de qualidade.",
  "ccd.transfer.label.statusUnknow": "Desconhecido",
  "ccd.ccaction.message.repeateRest": "O agente é descanso. Não force o show ocupado.",
  "ccd.agent.message.logOutStatusErr": "Falha ao sair. Você pode ter um asunto pendente para lidar.",
  "ccd.agent.videomode.maximizeUser": "Maximizar usuário",
  "ccd.agent.status.mute": "Mudo",
  "ccd.agent.videomode.maximizeAgent": "Maximizar Agente",
  "ccd.agent.tips.restTooLong": "O resto esgota-se.",
  "ccd.agent.status.occupy": "Em Atendimento",
  "ccd.record.message.startRecord": "Um erro de estado ocorre quando a gravação é iniciada.",
  "ccd.agent.message.monitorSelf": "O agente inspecionado e o inspetor não podem ser os mesmos.",
  "ccd.agent.message.hangupnocall": "A chamada não pode ser desligada. Se a chamada estiver em espera, Cancele a espera primeiro.",
  "ccd.agent.message.wait": "A chamada estásendo processada. Por favor, tente novamente mais tarde.",
  "ccd.agent.message.reset": "Muitas falhas de processamento de chamadas, tentando sincronizar o status do agente",
  "ccd.transfer.label.skillName": "Fila de habilidades",
  "ccd.ccaction.message.retMessEnd": ".",
  "ccd.record.message.pauseRecord": "Um erro de estado ocorre durante a pausa da gravação.",
  "ccd.agent.videocall.typeError": "O tipo de chamada está incorreto quando o modo de exibição é ajustado para uma chamada de vídeo de três partes.",
  "ccd.ccaction.message.recordFail": "O agente não faz login e a reprodução de voz falha.",
  "ccd.transfer.label.transferToSkill": "Transferir para a fila de habilidades",
  "ccd.ccaction.message.confJoinErr": "Nenhuma chamada foi retida. Uma chamada de três partes não é permitida.",
  "ccd.agent.message.insultNoCall": "Nenhuma chamada para consulta.",
  "ccd.callmessage.workbench": "Workbench de áudio e vídeo",
  "ccd.agent.message.beginMuteStateErr": "Um erro de estado de chamada ocorre quando você se silencia.",
  "ccd.agent.status.threeParty": "Três Partes",
  "ccd.agent.message.loginInvalidParam": "O número de telefone é inválido ou foi iniciado por outro agente.",
  "ccd.agent.message.checkAgentStatus": "Execute esta operação quando o inspetor estiver disponível.",
  "ccd.agent.message.loginInvalidPhone": "Número de telefone inválido.",
  "ccd.agent.message.InvalidNumber": "Número inválido.",
  "ccd.quality.message.intercept": "Interceptar",
  "ccd.agent.mediaswitch.switchvideofailed": "O agente já está em uma chamada de vídeo.",
  "ccd.agent.message.mediaSwitchSuccess": "Seleção de áudio/vídeo com sucesso.",
  "ccd.quality.message.searchWorkno": "Insira a ID do agente para pesquisar.",
  "ccd.cccbar.label.yourself": "Eu mesmo",
  "ccd.transfer.label.transferToAgent": "Transferir para agente",
  "ccd.cccbar.label.cccbar": "Barra de conexão",
  "ccd.quality.message.qualityTitle": "Verificação de qualidade",
  "ccd.cccbar.label.empty": "Limpar tudo",
  "ccd.ccaction.message.error": "Erro",
  "ccd.quality.message.stopListen": "Parar de ouvir",
  "ccd.quality.message.switch": "Alternar",
  "ccd.agent.status.innerCall": "Chamada Interna",
  "ccd.quality.message.statusErr": "O status é anormal durante a verificação de qualidade.",
  "ccd.callout.message.numberFormat": "Um número de telefone pode conter apenas os dígitos, o sinal numérico (#), e o asterisco (*), e contém no máximo 24 caracteres.",
  "ccd.ccaction.message.inputWorkNo": "A ID do agente está vazia. Insira a ID do agente.",
  "ccd.cccbar.message.calltip": "Olá, uma nova chamada está chegando!",
  "ccd.agent.message.getHoldCallStatusErr": "Um erro de estado de chamada ocorre quando você cancela a chamada.",
  "ccd.ccaction.message.statusError": "Nenhuma chamada para silenciar no estado atual.",
  "ccd.innerhelp.label.helpType": "Tipo de ajuda",
  "ccd.cccbar.label.nocall": "Sem chamada.",
  "ccd.callmessage.chatOnlineWorkbench": "Workbench de bate-papo online",
  "ccd.cccbar.label.inputNumber": "Insira um número.",
  "ccd.record.message.startPlay": "Um erro de estado ocorre quando a reprodução é iniciada.",
  "ccd.innerhelp.message.inputExNumhelp": "inserir um número para Consultar",
  "ccd.agent.message.connectHoldNoCall": "Não há chamada para esperar.",
  "ccd.agent.message.restNoRight": "O agente não tem permissão para chamar a interface. O agente pode ter sido forçado a sair.",
  "ccd.agent.label.recordPlay": "Reprodução de voz",
  "ccd.agentstate.notTalking": "O agente selecionado não está em estado de fala.",
  "ccd.quality.message.resourceUnable": "Um erro ocorre durante a inspeção. Verifique os registros relacionados.",
  "ccd.agent.message.setStatErr": "Falha ao definir o status do agente.",
  "ccd.quality.message.inspectInspectorErr": "O agente inspecionado não pode ser um inspetor.",
  "ccd.transfer.button.sure": "OK",
  "ccd.quality.message.listen": "Ouvindo",
  "ccd.cccbar.label.callout": "Chamar",
  "ccd.agent.message.endMuteStateErr": "Ocorre um erro de estado de chamada quando você cancela o silenciamento.",
  "ccd.transfer.label.releaseTransfer": "Transferência de Liberação",
  "ccd.agent.status.talk": "Em Ligação",
  "ccd.transfer.label.transferType": "Tipo de transferência",
  "ccd.transfer.label.transferTypeColon": "Tipo de transferência:",
  "ccd.transfer.label.transferTypeColon.ccbar": "Tipo:",
  "ccd.agent.message.eventMethodErr": "O método para obter o evento agente está incorreto.",
  "ccd.transfer.button.cancel": "Cancelar",
  "ccd.ccaction.message.retMessage": "Mensagem de erro:",
  "ccd.agent.mediaswitch.video": "Chamada de vídeo",
  "ccd.agent.message.AgentAlreadyLogout": "O agente se desconectou.",
  "ccd.ccaction.message.repeateBusy": "O agente está ocupado agora. Não defina ocupado repetidamente.",
  "ccd.agent.message.invalidWasURL": "O caminho de link WAS configurado no servidor está incorreto.",
  "ccd.agent.message.threePartyNoHoldCall": "Nenhuma chamada é realizada para iniciar uma chamada de três partes.",
  "ccd.record.message.resumeRecord": "Um erro de estado ocorre quando a gravação continua.",
  "ccd.callinner.label.callinner": "Chamada Interna",
  "ccd.agent.message.getHoldNoHoldCall": "Não há chamada para cancelar.",
  "ccd.ccaction.message.inputIvrName": "O nome do fluxo IVR está vazio. Insira o nome do fluxo IVR.",
  "ccd.agent.message.userOrPasswdInvalid": "Falha no login. A conta ou senha está incorreta.",
  "ccd.transfer.label.talkingTransfer": "Transferência de conversa",
  "ccd.agent.message.misconnection": "Falha ao chamar a interface de persistência da sessão.",
  "ccd.transfer.message.inputExNumber": "Insira o número a ser transferido",
  "ccd.transfer.message.microsoftTeamsLogin": "Iniciar sessão",
  "ccd.transfer.message.inputTeamsUser": "Insira uma conta do Teams.",
  "ccd.agent.message.accoutnLocked": "A conta está bloqueada. Inscreva-se mais tarde.",
  "ccd.quality.message.back": "Voltar",
  "ccd.transfer.label.callTransfer": "Transferir chamada",
  "ccd.agent.message.answerNoCall": "Nenhuma chamada para atender.",
  "ccd.record.message.jumpForw": "Um erro de estado ocorre durante o avanço rápido.",
  "ccd.agent.message.releaseNumberNoNumber": "Nenhuma chamada do número de telefone para liberar.",
  "ccd.agent.message.AgentInnerError": "Falha na operação. A causa possível é que ocorra um erro interno, não haja recursos disponíveis ou o agente não esteja ocioso.",
  "ccd.callinner.message.inputExNumhelp": "Insira um número para a chamada interna.",
  "ccd.agent.previewcall.invalParam": "Os parâmetros de chamada de saída de visualização estão incorretos.",
  "ccd.agent.message.default": "Erro interno. Verifique o registro.",
  "ccd.agent.callout.notrest": "Cancele o intervalo e faça uma chamada.",
  "ccd.agent.status.innerTwo": "Consulta",
  "ccd.ccaction.message.rest": "Descanso",
  "ccd.ccaction.message.seconds": "segundos",
  "ccd.ccaction.message.minutes": "minutos",
  "ccd.ccaction.message.hour": "horas",
  "ccd.agent.message.callOutPhoneErr": "O número de chamada de saída é inválido.",
  "ccd.agent.message.holdNoCall": "Não há chamada para esperar.",
  "ccd.innerhelp.label.threePartyHelp": "Consulta de terceiro",
  "ccd.record.message.pausePlay": "Um erro de estado ocorre quando a reprodução é pausada.",
  "ccd.agent.message.transferNoCall": "Nenhuma chamada para transferir.",
  "ccd.ccaction.message.resignin": "A senha do assento é inválida, faça o check-in novamente!",
  "ccd.callmessage.whiteNumberExist": "O número não existe na lista de confiança.",
  "ccd.agent.mediaswitch.audio": "Chamada de áudio",
  "ccd.transfer.message.refreshTab": "Não atualize a página repetidamente.",
  "ccd.quality.message.stopInsert": "Interromper inserção",
  "ccd.agent.videocall.displayError": "Uma exceção ocorre ao ajustar o modo de exibição de uma chamada de vídeo a três.",
  "ccd.transfer.label.transferToExNumber": "Transferir para o Número Externo",
  "ccd.agent.status.callout": "Chamada Enviada",
  "ccd.quality.message.forcerest": "Forçar disponível",
  "ccd.innerhelp.label.help": "Buscar ajuda",
  "ccd.transfer.message.NoIvrOnline": "Nenhum fluxo de IVR está disponível.",
  "ccd.quality.message.noAuth": "Sem permissão para Verificação de Qualidade",
  "ccd.agent.message.releaseNoCall": "Não há chamada para desligar.",
  "ccd.transfer.label.threeParthTransfer": "Transferência de terceiros",
  "ccd.agent.message.needLogin": "Inicie sessão como agente e tente novamente.",
  "ccd.agent.message.logOutInvalidParam": "Falha no login. O parâmetro é inválido.",
  "ccd.agent.void.call": "Chamada de saída de voz",
  "ccd.agent.chat.mutimedia.call": "Chamada de saída Multimídia",
  "ccd.agent.chat.start": "Iniciando uma sessão",
  "ccd.agent.chat.channle.choose": "Seleção de canais",
  "ccd.login.message.logoutReason.sessionInvalid": "A sessão é inválida. Inscreva-se novamente, por favor.",
  "ccd.ccaction.message.registerPhone": "Registre o soft phone.",
  "ccd.ccaction.message.loginagent": "Por favor, entre no agente.",
  "ccd.contact.message.timeerror": "A hora inicial não pode ser maior que a hora final.",
  "ccd.contact.message.timerangeerror": "O intervalo de tempo não pode exceder {0} dias.",
  "ccd.contact.message.timeisempty": "O intervalo de tempo não pode estar vazio.",
  "ccd.ccaction.message.registerPhoneFailed": "Falha no registro do WebRTC. No caso atual, a ajuda interna não está disponível.",
  "ccd.agent.message.selectTeams": "Selecione uma conta Teams",
  "ccd.skill.selectskill": "Selecione pelo menos uma fila de habilidades.",
  "ccd.agent.message.qc.restskill": "A fila de habilidades não pode ser reposta se um inspetor não atender uma chamada recebida.",
  "ccd.agentMonitor.label.selectAgentOne": "Encerre o agente que está sendo processado e selecione outra operação do agente.",
  "ccd.quality.message.qualityAgentTitle": "Monitoramento de agentes",
  "ccd.agentMonitor.forceRelease.noAudio": "Nenhuma chamada de áudio ou vídeo pode ser liberada.",
  "ccd.agentMonitor.intercept.noAudio": "Nenhuma chamada de áudio ou vídeo pode ser interceptada.",
  "ccd.transfer.label.transferNumber": "Habilitar Cliente para Digitar em URA",
  "ccd.agent.forcesignout.confirm": "Sair afetará o serviço de conexão que o agente está processando. Tem certeza de que deseja sair forçosamente?",
  "ccd.ccagent.contract.preview": "Pré-visualização",
  "ccd.label.search": "Pesquisar",
  "ccd.label.org.all": "Todas as organizações acessíveis",
  "ccd.agent.message.phone": "Número chamado para",
  "ccd.title.call.three": "Chamada de três vias",
  "ccd.media.tips.new.online.customer": "Você tem um novo cliente online",
  'ccd.agent.message.calloutagent.restskill': 'O agente de callout não atende chamadas recebidas e não pode redefinir filas de habilidades.',
  "ccd.agent.message.videoLimit": "Os recursos do agente de vídeo são insuficientes e o agente não consegue entrar no servidor de mídia de vídeo.",
  "ccd.agent.message.withoutskill": "A fila de habilidades está faltando.",
  "ccdesktop.login.wertc.failed": "O registo do telemóvel Webrtc falhou",
  "ccd.agent.label.skillGroupOrSkill": "Grupo de habilidades/Fila de habilidades",
  "ccd.agent.tips.media.agentType.no.support.callout": "Agentes multimídia não podem fazer chamadas de saída",
  "ccd.transfer.placeholder.agentName": "Insira o nome do agente",
  "ccd.agent.videocall.displayWarn": "O tamanho da janela de vídeo pode ser ajustado somente quando todas as três partes estiverem reproduzindo vídeos.",
  "ccd.ccaction.message.nosupport": "Esta operação não é suportada na página web. Use Open Eye para silenciar.",
  "ccd.ccbar.before.signin.tips":"Antes de utilizar a função de ligação, clique em para iniciar sessão.",
  "ccd.ccbar.calling.number":"Número de chamada",
  "ccd.ccbar.mediaSwitch.failed":"Falha na alternância de mídia.",
  "ccd.ccbar.mediaSwitch.notsupport":"A comutação de áudio e vídeo não é permitida para chamadas internas no modo de vídeo no MCU.",
  "ccd.webrtc.message.bad":"Maldade",
  "ccd.webrtc.message.poor":"Mais pobre",
  "ccd.webrtc.message.good":"Bom",
  "ccd.webrtc.message.recive":"Receber",
  "ccd.webrtc.message.Packetloss":"Perda de pacotes",
  "ccd.webrtc.message.delay":"Atraso",
  "ccd.webrtc.message.jitter":"Jitter",
  "ccd.webrtc.message.send":"Enviar",
  "ccd.webrtc.message.signal":"Sinal",
  'ccd.webrtc.message.medium':'Médio',
  'ccd.webrtc.login.success':'O login do softphone foi bem-sucedido',
  'ccd.webrtc.login.fail':'Falha de login do Softphone',
  'ccd.webrtc.login.fail.noPwd':'Nenhuma senha inicial está disponível. Redefina a senha do softphone e tente novamente.',
  'ccd.webrtc.login.fail.pop.tips':'Falha ao efetuar login automaticamente no softphone. Você não pode usar a função de voz. Entrar no softphone novamente?',
  'ccd.webrtc.login.try':'Tentar Novamente',
  'ccd.webrtc.login.ignore':'Ignorar',
  'ccd.webrtc.login.fail.top.tips':'O login do softphone é anormal e funções relacionadas, como voz, não podem ser usadas.',
  "ccd.ccbar.webrtc.network.signal.pool":"O sinal de rede atual é ruim, o que pode afetar a chamada.",
  "ccd.agentMonitor.label.switchwhisper": "Você não pode clicar em Sussurro para um agente em estado de pré-escuta ou por inserção.",
  'ccd.agent.webrtc.register.tip':'Registrar softphone?',
  "ccd.consult.message.two.inputExNumber": "Por favor, insira o número para consulta de duas partes",
  "ccd.consult.message.three.inputExNumber": "Por favor, insira o número para consulta de três partes",
  "ccd.ccbar.mediaSwitch.sfunotsupport":"A chamada atual não suporta alternância de mídia.",
  "ccd.agent.tips.welcome.agentlogout": "Bem-vindo!",
  "ccd.agent.message.continuously.online":"O serviço online será mantido após o check-in. Por favor, confira a tempo após o trabalho é concluído.",
  "ccd.agent.vrpserviceaddress.isempty" : "O endereço do servidor VRP está vazio. Entre em contato com o administrador do sistema para configurá-lo.",
  "ccd.agent.initializeagentex.success" : "O agente é registrado com êxito no VRC. Seu desktop será monitorado e sua chamada será gravada na tela. Por favor, mantenha um bom status de trabalho.",
  "ccd.agent.initializeagentex.passworderror" : "ID de agente ou senha incorreta. Falha ao registar-se com o VRC.",
  "ccd.agent.initializeagentex.alreadyregistered" : "O agente registrou-se no VRC.",
  "ccd.agent.vrpexe.closed" : "O software VRC está a ser desligado.",
  "ccd.agent.startMonitorEx.uninitialize" : "A conexão vrc é inválida. Entre no agente atual novamente.",
  "ccd.agent.startMonitorEx.qcnotexit" : "O objeto QC não existe. Verifique se o atendente está conectado corretamente ao VRC.",
  "ccd.agent.startMonitorEx.qctimelimitexceed" : "Falha na conexão do atendente com o vrc. Por favor, faça login novamente.",
  "ccd.agent.startMonitorEx.qcunsigned": "O agente não entrou. Verifique se o agente fez login ou precisa fazer login novamente.",
  "ccd.agent.startMonitorEx.othererror": "Falha ao conectar ao servidor. Entre em contato com o pessoal de manutenção ou tente novamente mais tarde.",

}
