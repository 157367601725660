// 表情配置文件
export const emotionconf = {
    emoji: {
        1: '开心',
        2: '色',
        3: '忧郁',
        4: '呆',
        5: '呲牙',
        6: '飞吻',
        7: '媚眼',
        8: '气愤',
        9: '囧',
        10: '尴尬',
        11: '大哭',
        12: '调皮',
        13: '愤怒',
        14: '累',
        15: '鄙视',
        16: '高兴',
        17: '口罩',
        18: '亲亲',
        19: '擦汗',
        20: '哭笑',
        21: '可爱',
        22: '难过',
        23: '鬼脸',
        24: '我晕',
        25: '紧张',
        26: '抓狂',
        27: '奸笑',
        28: '害怕',
        29: '冷汗',
        30: '困惑',
        31: '笑',
        32: '恶魔',
        33: '鬼',
        34: '圣诞老人',
        35: '小狗',
        36: '猪',
        37: '猫',
        38: '厉害',
        39: '你不行',
        40: '拳头',
        41: '握拳',
        42: '手势',
        43: '强壮',
        44: '鼓掌',
        45: '左边',
        46: '上边',
        47: '右边',
        48: '下边',
        49: '好的',
        50: '爱心',
        51: '心碎',
        52: '太阳',
        53: '月亮',
        54: '星星',
        55: '闪电',
        56: '云',
        57: '亲一下',
        58: '玫瑰花',
        59: '咖啡',
        60: '蛋糕',
        61: '钟',
        62: '啤酒',
        63: '放大镜',
        64: '手机',
        65: '楼房',
        66: '车',
        67: '礼物',
        68: '足球',
        69: '炸弹',
        70: '钻石',
        71: '外星人',
        72: '满分',
        73: '美元',
        74: '游戏柄',
        75: '便便',
        76: '求救',
        77: '困了',
        78: '麦霸',
        79: '雨伞',
        80: '看书',
        81: '阿弥陀佛',
        82: '火箭',
        83: '绿豆汤',
        84: '西瓜'
    }, wechat: {
        1: '微笑',
        2: '撇嘴',
        3: '色',
        4: '发呆',
        5: '酷',
        6: '伤心',
        7: '害羞',
        8: '闭嘴',
        9: '困',
        10: '委屈',
        11: '尴尬',
        12: '愤怒',
        13: '调皮',
        14: '咧嘴笑',
        15: '惊讶',
        16: '悲伤',
        17: '脸红',
        18: '抓狂',
        19: '呕吐',
        20: '捂嘴笑',
        21: '可爱',
        22: '白眼',
        23: '傲慢',
        24: '被掏空',
        25: '惊恐',
        26: '汗',
        27: '憨笑',
        28: '绿帽',
        29: '奋斗',
        30: '破口大骂',
        31: '疑问',
        32: '嘘',
        33: '眩晕',
        34: '雷',
        35: '骷髅',
        36: '敲打',
        37: '再见',
        38: '擦汗',
        39: '抠鼻',
        40: '鼓掌',
        41: '坏笑',
        42: '左嘘嘘',
        43: '右嘘嘘',
        44: '哈欠',
        45: '鄙视',
        46: '很委屈',
        47: '委屈到变形',
        48: '嘿嘿',
        49: '么么哒',
        50: '可怜',
        51: '菜刀',
        52: '西瓜',
        53: '啤酒',
        54: '咖啡',
        55: '小猪猪',
        56: '比心',
        57: '心碎',
        58: '红唇',
        59: '爱你',
        60: '分手',
        61: '蛋糕',
        62: '地雷',
        63: '大便',
        64: '月亮',
        65: '太阳',
        66: '拥抱',
        67: '牛',
        68: '垃圾',
        69: '握手',
        70: '胜利',
        71: '抱拳了',
        72: '勾引',
        73: '加油',
        74: '好的',
        75: '跳跳',
        76: '发抖',
        77: '怄火',
    }, iframe: {
        1: '🖤',
        2: '🗻',
        3: '🗿',
        4: '😀',
        5: '😁',
        6: '😆',
        7: '😇',
        8: '😈',
        9: '😉',
        10: '😍',
        11: '😎',
        12: '😏',
        13: '😐',
        14: '😑',
        15: '😒',
        16: '😔',
        17: '😕',
        18: '😖',
        19: '😗',
        20: '😘',
        21: '😙',
        22: '😚',
        23: '😛',
        24: '😜',
        25: '😞',
        26: '😟',
        27: '😠',
        28: '😥',
        29: '😦',
        30: '😧',
        31: '😨',
        32: '😫',
        33: '😬',
        34: '😭',
        35: '😮',
        36: '😯',
        37: '😰',
        38: '😳',
        39: '😴',
        40: '😵',
        41: '😶',
        42: '😷',
        43: '🙀',
        44: '🙁',
        45: '🙄',
        46: '🙅',
        47: '🙏'
    }
};