export default {
  "agentconsole.menu.title": "音视频工作台",
  "casemanagement.casequery.button.create": "创建工单",
  "agentconsole.callreason.tips.saved": "来电原因已保存",
  "iolp.workflow.title.name": "业务引导",
  "agentconsole.title.onlycustomer": "只看客户",
  "agentconsole.title.allchat": "全部会话",
  "agentconsole.title.realtimetransfer": "实时转写",
  "agentconsole.title.intelligentcase": "智能填单",
  'agentconsole.management.message.filterYes': '是',
  'agentconsole.management.message.filterNo': '否',
  "agentconsole.dashboard.enumeration.aweek": "最近一周",
  "SM.LOGIN.BUTTON.CHANGEPWD_CONFIRM": "确定",
  "agentconsole.Intelligent.details": "详情",
  "agentconsole.workbench.label.intelligentrecommendation": "座席辅助",
  "agentconsole.dashboard.title.case": "工单情况概览",
  "agentconsole.header.label.searchtask": "在\"任务\"中查找",
  "SM.LOGIN.TITLE.NOTICE_EXP_TIME": "失效时间",
  "SM.LOGIN.LABEL.LOGIN_CONFIRM_SUFFIX": "），请确认：",
  "SM.LOGIN.LABEL.NOTICE_CREATE_TIME_TO": "到",
  "SM.LOGIN.LABEL.OPER_PHONE": "手机号码：",
  "agentconsole.workbench.label.callinforeasons": "一级原因/二级原因/三级原因/四级原因",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_NOT_OPEN_ES": "当前未开启应急系统功能，请访问生产菜单进行业务操作",
  "agentconsole.dashboard.title.averageevalution": "平均满意度",
  "agentconsole.header.title.send.sms.notification": "外发短信通知消息",
  "agentconsole.index.label.caseconsole": "工单工作台",
  "agentconsole.login.label.empty": "用户名或密码为空",
  "agentconsole.authmanage.label.authtype.menu": "菜单权限",
  "SM.LOGIN.LABEL.FORGETPWD_ACCOUNT_TIPS": "请输入登录帐号",
  "SM.LOGIN.LABEL.CHANGEPWD_OLDPWD": "原密码",
  "agentconsole.callmessage.setHandledNumber": "设置受理号码",
  "agentconsole.login.label.password": "密码",
  "agentconsole.header.title.send.whatsapp.notification": "外发whatsapp通知消息",
  "agentconsole.login.label.signin": "登录",
  "agentconsole.index.label.chatconsole": "在线客服工作台",
  "SM.ORGSTAFF.LABEL.PWD_UNMATCH_RULE": "密码不符合密码规则",
  "agentconsole.menumanage.message.paramvalidatefailed": "创建菜单失败，请检查参数",
  "SM.LOGIN.LABEL.OPER_REGION": "所属区域：",
  "SM.LOGIN.TIPS.CHANGEPWD_COMPARE": "新密码和确认密码必须一样",
  "SM.LOGIN.TITLE.UPDATE_PWD_VERIFYCODE_EMPTY": "验证码为空",
  "SM.LOGIN.LABEL.NOTICE_INCLUDE_SUB_ORG": "是否包含子组织",
  "agentconsole.common.button.modify": "修改",
  "agentconsole.msg.noData": "暂无数据",
  "SM.LOGIN.TITLE.NOTICE": "公告",
  "SM.LOGIN.TIPS.LOGINSUCCESS": "登录成功",
  "SM.LOGIN.TITLE.PORTAL_REGION_TITLE": "区域",
  "agentconsole.quickentrance.classname": "请为目录起一个归类名称，例：租户资源管理",
  "SM.LOGIN.TITLE.PORTAL_TITLE": "BES Portal",
  "SM.LOGIN.MESSAGE.LOGOUT": "是否确定退出？",
  "SM.LOGIN.LABEL.LOGIN_CONFIRM_SWITCH": "访问所属系统",
  "SM.LOGIN.TITLE.UPDATE_PWD_NEWPWD_EMPTY": "新密码为空",
  "agentconsole.dashboard.title.taskunassigned": "未分配情况",
  "SM.LOGIN.BUTTON.RESET": "重置",
  "agentconsole.ccnotification.inputRecipient": "请输入收件人，多个收件人以英文逗号分割",
  "agentconsole.ccnotification.inputOneRecipient": "请输入收件人",
  "agentconsole.ccnotification.recipient.validate.limit.one": "收件人数量不能超过1",
  "agentconsole.callmessage.callerNo": "来电主叫号码",
  "SM.LOGIN.LABEL.SUM": "系统用户管理",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_SWITCH_ES": "当前业务已切换到应急状态，该业务需要待系统恢复后才能办理",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_VERIFY_CODE_NOT_EXIST": "验证码不存在",
  "agentconsole.dashboard.evaluation.medium": "3-4分",
  "agentconsole.header.title.logout": "退出登录",
  "agentconsole.index.label.casedec": "为您的客户的每一个服务请求创建一个工单，通过工单来跟踪客户服务请求处理的完整生命周期，保证每一个客户请求都得到闭环，提升客户的满意度。",
  "agentconsole.common.tips.error": "错误",
  "agentconsole.menumanage.title.selectauth": "请选择权限",
  "SM.LOGIN.BUTTON.FORGETPWD_CANCEL": "返回",
  "agentconsole.Intelligent.noRecord": "非业务受理中或查询结果为空.",
  "agentconsole.dashboard.evaluation.low": "1-2分",
  "SM.LOGIN.TITLE.UPDATE_PWD_NEWPWD_RULE": "新密码不满足密码规则",
  "SM.LOGIN.MESSAGE.SENDSMS_FAILED": "发送短息失败，请查看后台日志",
  "agentconsole.header.title.notificationcenter": "通知查看 ",
  "agentconsole.contacthistory.title": "历史接触",
  "agentconsole.login.indexforipcc.help": "求助",
  "agentconsole.authmanage.field.auth_type": "权限类型",
  "SM.PORTAL.LABEL.INPUT_OLD_PWD": "请输入旧密码",
  "SM.LOGIN.BUTTON.FORGETPWD_CONFIRM": "确定",
  "SM.LOGIN.LABEL.FORGETPWD_EMAIL_TIPS": "请输入邮箱地址",
  "SM.AUC.MESSAGE.PWDRULE_NO": "否",
  "agentconsole.callmessage.faq.spread": "展开",
  "agentconsole.querycustominfo.msg.inputHandleNumber": "请输入受理号码",
  "agentconsole.login.indexforipcc.difbutton": "可根据客服人员权限显示不同的操作控制按钮，避免误操作和不必要的干扰，从而提高客服人员的服务效率",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_LOGIN_ID_NULL": "登录帐号不能为空",
  "agentconsole.common.button.ok": "确认",
  "agentconsole.login.indexforipcc.agentcall": "座席呼叫控制，例如接听呼叫、呼叫转移、三方通话、求助等",
  "agentconsole.contacthistory.oneMin": "1分钟前",
  "SM.LOGIN.TITLE.PASSWORD_HASEXPIRED": "用户的密码已过期，请联系管理员重置密码",
  "SM.LOGIN.LABEL.FRAMEWORK_CALCULATOR": "计算器",
  "SM.LOGIN.LABEL.NOTICE_GRADE_NORMAL": "普通",
  "SM.LOGIN.LABEL.CUS_LOGIN": "客户登录",
  "SM.SERVLET.BATCH.RESET.PASSWORD.EMPLOYEE": "批量重置租间用户密码",
  "agentconsole.sessionmanagement.label.clientIp": "客户端IP",
  "agentconsole.header.label.searchbase": "在\"知识中心\"中查找",
  "agentconsole.login.resetpwd.empty": "用户名或邮箱为空",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED": "打开菜单失败",
  "agentconsole.login.indexforipcc.cccbar": "座席框架中的座席接续条提供主要的语音呼叫处理和座席状态控制的功能",
  "SM.LOGIN.TITLE.LOGIN_TITLE": "BES Portal登录页面",
  "SM.LOGIN.TIPS.UPDATE_PWD": "修改密码",
  "SM.ORGSTAFF.LABEL.TEAM_ACCOUNT": "帐号",
  "SM.LOGIN.TITLE.CURRENT_BE": "当前地市",
  "agentconsole.callmessage.faq.folded": "收起",
  "agentconsole.workbench.queNum": "当前排队人数：",
  "SM.LOGIN.TITLE.PROJECT_CREATER": "创建人：",
  "SM.LOGIN.MESSAGE.LOGIN.LABEL.EMPTY": "用户名或密码为空",
  "agentconsole.common.expiry.phone": "服务热线",
  "agentconsole.menumanage.message.urlvalidatefailed": "创建菜单失败，请联系系统管理员检查地址白名单配置",
  "SM.LOGIN.TITLE.PASSWORD_INIT_SHOULD": "您使用的是系统初始密码，为安全起见，请修改密码",
  "SM.LOGIN.LABEL.RESETPWD.FAIL": "重置密码失败\r\n",
  "agentconsole.login.indexforipcc.connectcontrol": "接续状态控制",
  "agentconsole.dashboard.title.caseunhandle": "未处理",
  "SM.LOGIN.TIPS.MODIFY_SUCCESS": "修改成功",
  "SM.ORGSTAFF.TIPS.REGION_DST_SWITCHINTERVAL_TIPS": "单位：秒，范围：1-86400，为空则默认为3600秒",
  "agentconsole.dashboard.title.casecreate": "创建工单数",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_LOGINID_NO_BIND_MSISDN": "用户未绑定手机号码",
  "SM.PORTAL.LABEL.INPUT_NEW_PWD": "请输入新密码",
  "agentconsole.menumanage.message.renamenode": "菜单名称重复",
  "agentconsole.contacthistory.accept": "受理号码",
  "SM.LOGIN.LABEL.REMEMBER": "记住用户名",
  "agentconsole.login.indexforipcc.signinsuccess": "座席签入成功后，座席才能接听客户电话，处理业务，系统根据签入的座席分配客户呼叫，管理人员根据座席的签入、签出时间进行考勤管理",
  "agentconsole.login.indexforipcc.usenow": "立即使用",
  "SM.LOGIN.TITLE.UPDATE_PWD_CONFIRMPWD": "确认新密码：",
  "agentconsole.authmanage.label.authtype.interface": "接口权限",
  "agentconsole.login.indexforipcc.callcontrol": "语音呼叫控制",
  "SM.LOGIN.LABEL.RESETPWD": "点击重置按钮，新的密码会发送到邮箱",
  "agentconsole.header.title.send.notification": "发送通知",
  "agentconsole.login.indexforipcc.difauth": "管理租户的组织机构，角色、权限以及员工，客服人员使用员工帐号登录后，根据权限不同，能够查看的呼叫控制按钮、页面菜单也不一样，仅登录而未签入，是无法进行和呼叫相关的操作的",
  "SM.LOGIN.MESSAGE.LOGOUT_REASON.IP_CHECK_FAILED": "IP校验失败，当前IP地址与首次登录IP地址不相同",
  "agentconsole.menumanage.message.menuname": "字节数必须小于等于50",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_OPEN_BE_ES": "当前地区已切换到应急状态，请登录应急系统继续办理该业务",
  "agentconsole.login.indexforipcc.doublecontrol": "智能客户服务云对座席人员登录系统存在两层认证控制：",
  "SM.LOGIN.MESSAGE.SUGGESTION": "提示",
  "SM.LOGIN.MESSAGE.SEARCH_HISTORY_CLEAR": "清空历史记录",
  "agentconsole.common.expiry.prefix": "您的租户将于",
  "SM.LOGIN.TITLE.ICPCODE": "苏ICP备{0}号-{1}",
  "SM.LOGIN.LABEL.OPER_LOGINID": "登录帐号：",
  "SM.LOGIN.LABEL.NOTICE_ORG": "组织机构",
  "agentconsole.quickentrance.classdesc": "请为该目录写一段备注说明，例：管理租户资源，您可以在此对租户资源进行查看的维护",
  "SM.LOGIN.LABEL.CHANGEPWD_ACCOUNT": "帐号",
  "SM.LOGIN.TIPS.LOGINRESULT": "登录结果：",
  "SM.LOGIN.TITLE.CHANGEPWD_ERROR": "错误",
  "SM.LOGIN.LABEL.AUTH_FAILED_ES": "当前地市已进入应急状态，请登录应急系统",
  "agentconsole.custinfo.label.amount": "欠款金额",
  "agentconsole.sessionmanagement.label.startime": "开始时间",
  "agentconsole.sessionmanagement.label.accountNumber": "帐号",
  "agentconsole.callmessage.setNumerError": "设置受理号码失败！",
  "agentconsole.callmessage.query.external.custom.info": "查询外部自定义信息失败，请联系运维查看日志",
  "SM.LOGIN.LABEL.FRAMEWORK_ONLINE_HELP": "联机帮助",
  "SM.INDEX.LABEL.TASK": "任务",
  "SM.LOGIN.LABEL.VCODE_INVALID": "验证码错误",
  "agentconsole.dashboard.evaluation.unit": "个",
  "SM.LOGIN.LABEL.ERRORPAGE_ERRORNODETAIL_CLOSE": "详情>",
  "agentconsole.workbench.talkNum": "今日通话数：",
  "agentconsole.authmanage.message.renamenode": "请重新命名权限名称",
  "SM.LOGIN.LABEL.SMS_RECONFIRM_GETCODE": "获取验证码",
  "agentconsole.index.label.querycase": "查询工单",
  "agentconsole.custinfo.label.dueDate": "到期时间",
  "agentconsole.login.label.verifycode": "验证码",
  "SM.INDEX.LABEL.CHATDEC": "与您的客户进行多媒体在线交谈的同时，可查看客户全方位的历史接触信息，最大化您的服务效率，并为您的客户提供渠道协同（Omni-Channel）的服务体验。",
  "SM.SERVLET.BATCH.RESET.PASSWORD.EMPFAIL": ",失败员工：",
  "SM.LOGIN.BUTTON.LOGIN_CONFIRM_MSG": "访问所属运营单位的系统将会退出当前系统，并需要重新输入用户名密码进行登录，是否继续？",
  "agentconsole.wordreminder.norecommend": "暂无话术推荐",
  "agentconsole.sessionmanagement.table.deleteConfimMessage": "是否确定删除本条会话？",
  "agentconsole.login.indexforipcc.agent": "座席认证",
  "agentconsole.dashboard.title.taskunhandle": "未处理",
  "SM.LOGIN.MESSAGE.CHANGEPWD_FAILED": "修改密码失败",
  "agentconsole.login.indexforipcc.transfer": "呼叫转移",
  "agentconsole.custinfo.label.custName": "客户姓名",
  "SM.COMMON.MESSAGE.VALIDATE_MINLENGTHB": "请至少输入{0}个字符",
  "agentconsole.index.label.taskconsole": "任务工作台",
  "agentconsole.authmanage.message.authlimit": "权限不足或其它原因导致异常，请联系华为工程师",
  "SM.LOGIN.MESSAGE.SENDMAIL_FAILED": "邮件发送失败",
  "SM.LOGIN.LABEL.OPER_BE": "所属运营单位：",
  "agentconsole.index.label.taskdec": "在工单处理过程中，您可以通过派发任务的方式，协同多个部门和人员共同处理，同一条工单可以创建多个任务",
  "agentconsole.menumanage.message.notdelnoleafmenu": "不允许删除有子菜单的菜单",
  "agentconsole.dashboard.title.casepriority": "待办情况",
  "SM.ORGSTAFF.LABEL.ADDR_PLEASE_INPUT": "请输入名称",
  "SM.LOGIN.TITLE.PORTAL_SWITCH_PROJECT": "切换工程",
  "agentconsole.header.title.messageCenter": "站内信管理",
  "agentconsole.login.label.email": "邮箱",
  "SM.LOGIN.BUTTON.SMS_RECONFIRM_CANCEL": "返回",
  "SM.LOGIN.LABEL.FORGETPWD_SENDTYPE": "找回方式",
  "agentconsole.authmanage.message.authtype": "权限名称不能输入特殊字符不为空且字节数必须小于等于50",
  "SM.LOGIN.TITLE.PROJECT_DESC": "工程描述：",
  "agentconsole.dashboard.title.caseperday": "日处理情况",
  "agentconsole.menumanage.message.createsubnodefail": "菜单层级不能超过3层",
  "SM.LOGIN.TIPS.WELCOME": "欢迎使用BES系统！",
  "SM.LOGIN.TITLE.LOGIN": "登录",
  "agentconsole.workbench.signInCallNum": "本次签入通话数：",
  "SM.LOGIN.LABEL.SMS_RECONFIRM_VCODE": "验证码",
  "SM.LOGIN.BUTTON.UPDATE_PWD_MODIFY": "修改",
  "SM.LOGIN.LABEL.OPER_EMAIL": "电子邮箱：",
  "SM.LOGIN.MESSAGE.SUM": "总计：",
  "SM.LOGIN.LABEL.VCODE": "验证码",
  "agentconsole.login.indexforipcc.recordcontrol": "录放音控制",
  "SM.LOGIN.MESSAGE.MODIFY_PWD_SUCCESS": "密码修改成功",
  "SM.LOGIN.TITLE.PROJECT_CREATE_TIME": "创建时间：",
  "agentconsole.header.label.searchcase": "在\"Case\"中查找",
  "SM.LOGIN.TITLE.NOTICE_EFF_TIME": "生效时间",
  "SM.LOGIN.SSO.IS.INVALID": "页面已失效，点击刷新页面",
  "agentconsole.history.lastloginfailattempts": "上次登录失败的尝试次数",
  "SM.LOGIN.LABEL.ERRORPAGE_RETURNBTN": "返回登录页面",
  "agentconsole.menu.extended.apps": "扩展应用",
  "SM.LOGIN.MESSAGE.SWITCH": "是否确定切换到ESOP系统？",
  "SM.LOGIN.RESETPWD.FAIL": "重置密码请求处理失败，请检查输入的登录帐号和邮箱地址",
  "agentconsole.history.loginip": "登录IP",
  "agentconsole.login.indexforipcc.call": "语音呼叫控制",
  "agentconsole.index.label.task": "任务",
  "agentconsole.quickentrance.checkcharacterlength": "输入的字符不能超过{0}",
  "agentconsole.index.label.entry": "快速入口",
  "agentconsole.quickentrance.information": "提示",
  "SM.LOGIN.LABEL.FRAME_SEARCH_EMPLOYEE": "员工查询",
  "agentconsole.querycustominfo.msg.specialStrValidateFailed": "输入内容不支持输入{'@'}.-以外其他特殊字符",
  "agentconsole.modifycustominfo.modifyfailed": "修改失败",
  "agentconsole.modifycustominfo.modifyfailed.validate": "修改失败,不符合字符串规则或长度",
  "agentconsole.modifycustominfo.modifyfailed.validate.num": "修改失败,不符合数字规则或长度",
  "agentconsole.modifycustominfo.modifyfailed.validate.date":"修改失败,不符合日期格式",
  "agentconsole.modifycustominfo.modifyfailed.validate.time":"修改失败,不符合日期时间格式",
  "SM.LOGIN.TITLE.UPDATE_PWD_OLDPWD_EMPTY": "旧密码为空",
  "SM.LOGIN.LABEL.FORGETPWD_ACCOUNT": "登录帐号",
  "provision.menuurl.protocol.check": "请注意，当前输入的菜单地址所使用的网络协议不是安全协议，如确定使用，可能存在安全风险",
  "agentconsole.dashboard.title.taskpriority": "待办情况",
  "agentconsole.authmanage.field.auth_name": "权限名称",
  "agentconsole.inputplaceholder.enter_keyword": "请输入关键词",
  "agentconsole.login.indexforipcc.gateway": "开放网关",
  "SM.LOGIN.TITLE.UNIFIED_CASHER": "统一收银",
  "SM.LOGIN.TITLE.SHOPPING_CART": "购物车",
  "SM.LOGIN.LABEL.NOTICE_CREATE_TIME": "创建时间",
  "SM.LOGIN.RESETPWD.EMPTY": "登录帐号或邮箱地址为空",
  "agentconsole.header.label.searchhelp": "在\"帮助中心\"中查找",
  "SM.LOGIN.LABEL.NOTICE_CHAN_TYPE": "渠道类型",
  "agentconsole.login.label.service_cloud_title": "客户服务云",
  "agentconsole.custinfo.title.custinfo": "客户信息",
  "SM.LOGIN.TITLE.SEARCH_CONDITION": "查询条件",
  "SM.LOGIN.TITLE.OPERATOR": "当前操作员",
  "agentconsole.history.passwordremainingdays": "口令将到期的天数",
  "SM.LOGIN.TIPS.REQUIRED": "必填项",
  "SM.LOGIN.LABEL.FORGETPWD_EMAIL": "邮箱地址：",
  "agentconsole.menumanage.message.homepagesubnode": "不能为首页添加子菜单",
  "SM.LOGIN.LABEL.ADD_FAST_MENU": "增加快捷菜单",
  "agentconsole.login.restpwd.success": "修重置密码成功",
  "SM.LOGIN.TITLE.CHOOSE_SELECT_ORG": "选择组织",
  "agentconsole.modifycustominfo.modifyexternalfailed": "目前不支持修改第三方数据",
  "SM.LOGIN.LABEL.HOME": "首页",
  "SM.LOGIN.TIPS.MODIFY": "修改",
  "agentconsole.authmanage.paneltitle.authmanage": "权限管理",
  "agentconsole.menumanage.label.newmenu": "新建菜单",
  "SM.LOGIN.MESSAGE.HAVE_LOCK_USER": "该用户已被锁定，请进行解锁操作或等待系统自动解锁",
  "SM.LOGIN.LABEL.RECHARGE": "充值",
  "SM.LOGIN.MESSAGE.UNLOCK": "是否确定锁定系统？",
  "agentconsole.querycustominfo.msg.noData": "暂无数据",
  "agentconsole.callmessage.setNumerSuccess": "设置受理号码成功！",
  "agentconsole.workbench.talkavg": "平均通话时长(s)：",
  "agentconsole.dashboard.title.taskdone": "办理任务数",
  "agentconsole.dashboard.title.web": "网页",
  "SM.LOGIN.TITLE.UPDATE_PWD_NEWPWD": "新密码：",
  "SM.LOGIN.TIPS.ENTER_PROJECT_NAME": "输入配置工程名称",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_VERIFY_CODE_NULL": "输入的验证码为空",
  "agentconsole.index.label.querycasenew": "查询工单",
  "agentconsole.login.indexforipcc.thirdpartysystem": "第三方系统可通过集成接口实现：",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_SWITCH_ES_PREFIX": "当前业务已切换到应急状态，请访问“",
  "SM.LOGIN.TITLE.REGISTERCODE": "苏公网安备 {0} 号",
  "SM.LOGIN.LABEL.LOCK": "锁屏",
  "agentconsole.workbench.label.callreason": "来电原因",
  "SM.LOGIN.TITLE.UPDATE_PWD_OLDPWD_NEWPWD": "新密码不能等于旧密码",
  "SM.LOGIN.TITLE.NOTICE_MORE": "更多",
  "SM.LOGIN.MESSAGE.CHANGE_PWD_RULE": "修改密码失败，新密码不满足密码规则组：",
  "SM.LOGIN.RESETPWD.SUCCESS": "重置密码请求已成功处理，请查收邮件",
  "SM.LOGIN.LABEL.FRAMEWORK_NOTEPAD": "记事本",
  "agentconsole.menumanage.message.notdelbasemenu": "不允许删除公共菜单",
  "agentconsole.quickentrance.newpop": "新增入口",
  "agentconsole.login.label.account": "帐号",
  "SM.LOGIN.LABEL.NOTICE_TITLE": "公告标题",
  "SM.LOGIN.TITLE.FORGETPWD_FINDEMAILBYPWD": "通过邮箱找回密码",
  "agentconsole.login.indexforipcc.advantage": "产品优势",
  "agentconsole.login.indexforipcc.characteristics": "业务特性",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_BE_SWITCH_ES_PREFIX": "当前地区已切换到应急状态，请登录应急系统后访问“",
  "agentconsole.Intelligent.robot": "智能机器人",
  "agentconsole.header.label.servicecloud.home": "工单首页",
  "agentconsole.header.title.news": "新闻",
  "SM.LOGIN.TIPS.FIRSTLOGINSYSTEM": "首次登录系统",
  "agentconsole.sessionmanagement.label.operation": "操作",
  "SM.LOGIN.MESSAGE.LOGOUT_REASON.SESSION_INVALID": "当前会话已失效，请重新登录",
  "SM.LOGIN.TITLE.UPDATE_PWD": "修改密码",
  "agentconsole.workbench.label.callsummary": "话后小结",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_VERIFY_CODE_EXPIRE": "验证码校验失败",
  "agentconsole.login.label.remember": "记住用户名",
  "agentconsole.workbench.label.usermenu": "用户菜单",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_SEND_SMS_SUCCESS": "验证码已发送，请验证",
  "agentconsole.quickentrance.addentrance": "添加入口",
  "agentconsole.history.lastloginsuccess": "上次登录成功",
  "agentconsole.login.indexforipcc.description": "IPCC（IP Contact Center，IP联络中心）作为企业与客户之间沟通的重要桥梁，提供了语音呼叫、在线客服等多种渠道为企业的客户提供问题解答、业务办理指引以及投诉处理等服务，同时基于当前高带宽、高网速的网络环境还支持视频座席、自助语音导航等新特性，在向客户提供新颖服务体验的同时，发掘基于视频与人工智能的新营销模式。",
  "SM.LOGIN.LABEL.OPER_ORG": "所属组织：",
  "SM.LOGIN.LABEL.USERNAME": "帐号",
  "agentconsole.contacthistory.contactRecord": "接触记录",
  "agentconsole.login.label.resetpwd": "点击重置按钮，新的密码会发送到邮箱",
  "agentconsole.wordreminder.greetings": "欢迎语",
  "agentconsole.common.expiry.email": "邮箱地址",
  "agentconsole.modifycustominfo.modifysuccess": "修改成功",
  "SM.LOGIN.LABEL.FORGETPWD_FINDPWD": "找回密码",
  "agentconsole.common.label.delete": "删除",
  "agentconsole.login.label.verifycode.error": "验证码不正确",
  "agentconsole.icontitle.delete": "删除",
  "SM.LOGIN.LABEL.LOGIN_CONFIRM_PREFIX": "系统检测到当前访问的系统地址不是您归属的运营单位所在的系统地址（",
  "SM.LOGIN.BUTTON.UPDATE_PWD_RULE": "密码规则",
  "SM.LOGIN.TITLE.NOTICE_NUMBER": "序号",
  "agentconsole.icontitle.create_child": "新增子级",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_NOT_SWITCH_ES": "当前未切换至应急状态，请访问生产菜单进行业务操作",
  "SM.LOGIN.TITLE.OPERATION_FAILED": "操作失败",
  "agentconsole.login.label.refresh_verify_code": "看不清，换一张",
  "agentconsole.login.indexforipcc.difstatus": "座席状态指客服代表登录座席后，可能处于的各种状态，包括“空闲态”、“通话态”、“整理态”、“示忙态”和“休息态”，客服代表通过执行接听电话、请假、示忙等接续操作，实现座席状态的转换，系统根据座席状态，判断是否可以为客服代表分配来电",
  "SM.LOGIN.TITLE.PORTAL_PROJECT_TITLE": "选择配置工程",
  "SM.LOGIN.LABEL.FORGETPWD_NUMBER": "手机号码",
  "agentconsole.modifycustominfo.nomodifyauth": "当前操作员没有修改自定义数据的权限",
  "SM.LOGIN.MESSAGE.SWITCHERROR": "切换失败",
  "SM.LOGIN.LABEL.RESETPWD.SUCCESS": "修重置密码成功",
  "agentconsole.authmanage.message.createsubnodefail": "权限层级不能超过3层",
  "agentconsole.login.indexforipcc.qualitycheck": "实时质检相关功能，例如插入、拦截、监听等",
  "agentconsole.authmanage.tab.basic_information": "基本信息",
  "SM.LOGIN.LABEL.NOTICE_ROLE": "接收角色",
  "agentconsole.Intelligent.labelname": "智能语音记录",
  "agentconsole.iolp.labelname": "业务请求",
  "agentconsole.iolp.entrancename": "业务引导",
  "agentconsole.header.label.changelog": "更新记录",
  "SM.LOGIN.BUTTON.CHANGEPWD_CANCEL": "取消",
  "SM.LOGIN.LABEL.LANG": "语言",
  "agentconsole.login.indexforipcc.idle": "系统只能将来电分配给处于“空闲态”的座席",
  "SM.LOGIN.LABEL.ACTIVITY": "营销活动",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_VERIFY_FAILED": "验证失败，请重试",
  "SM.LOGIN.LABEL.NOTICE_NOTE_TYPE": "公告类型",
  "SM.LOGIN.TITLE.SELECTBE": "选择运营实体",
  "agentconsole.dashboard.enumeration.otherday": "其他日期",
  "SM.LOGIN.TITLE.SERACH_MENU": "请输入菜单名称",
  "agentconsole.dashboard.evaluation.high": "5分",
  "SM.LOGIN.TITLE.CHOOSE_ORG_LIST": "请选择组织",
  "agentconsole.login.indexforipcc.mute": "静音",
  "SM.LOGIN.LABEL.CUS_LOGIN_REDIRECT": "登录跳转",
  "SM.LOGIN.TITLE.SELECT_ACCOUNT_TYPE": "请选择帐号类型",
  "SM.LOGIN.TITLE.PRINT_FREE_FILLING": "打印免填单",
  "agentconsole.sessionmanagement.label.search": "查询",
  "agentconsole.menumanage.message.menu_url": "菜单地址不能为中文",
  "SM.LOGIN.LABEL.NOTICE_CREATOR": "创建人",
  "SM.LOGIN.LABEL.CHANGEPWD_COMFIRMPWD": "确认新密码",
  "agentconsole.index.label.chat": "在线客服",
  "agentconsole.authmanage.rule.tips": "请选择一个您自定义的权限，系统预定义的权限不能选择",
  "agentconsole.login.indexforipcc.title": "云呼叫中心",
  "agentconsole.dashboard.title.casedone": "办理工单数",
  "SM.SERVLET.BATCH.RESET.PASSWORD.EMPLISTEND": "]",
  "SM.LOGIN.BUTTON.FORGETPWD_EMAIL": "邮箱",
  "agentconsole.login.indexforipcc.lightweight": "轻量级",
  "agentconsole.Intelligent.close": "关闭",
  "agentconsole.header.label.searchcommunity": "在\"社区\"中查找",
  "agentconsole.dashboard.title.interactioncount": "当日接触量共计：",
  "agentconsole.modifycustominfo.noqueryauth": "当前操作员没有查询自定义数据的权限",
  "agentconsole.common.tips.warning": "警告",
  "SM.LOGIN.TITLE.HELPCENTER": "帮助中心",
  "agentconsole.contacthistory.talking": "语音会话",
  "agentconsole.login.indexforipcc.hold": "呼叫保持",
  "agentconsole.menumanage.tab.basic_information": "基本信息",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_VERIFY_CODE_MINTIME_ERROR": "验证码已经生成",
  "agentconsole.contacthistory.readMore": "查看更多",
  "agentconsole.authmanage.message.deleteauthfail": "权限删除失败，请检查该权限是否已经被菜单引用或者赋予了某个角色",
  "agentconsole.quickentrance.deleteinfo": "您确定要删除这条目录吗?",
  "SM.LOGIN.RESETPWD.EMAILPATTERN_ERROR": "非法的邮箱地址",
  "SM.LOGIN.TITLE.NONLOCAL_USER_CHANEPWD_ERROR": "非本地用户不能修改密码",
  "agentconsole.login.indexforipcc.onepoint": "客服人员签入平台、接听来话、转移呼叫、静音、状态切换或求助等操作均可通过一次点击完成",
  "agentconsole.login.indexforipcc.loginportal": "登录客户服务云Portal",
  "SM.LOGIN.BUTTON.UPDATE_PWD_CLOSE": "关闭",
  "agentconsole.common.button.cancel": "取消",
  "SM.LOGIN.BUTTON.LOGIN_CONFIRM_CONTINUE": "继续访问",
  "SM.LOGIN.TITLE.COPYRIGHT": "版权所有 {0} {1} 华为软件技术有限公司 保留一切权利",
  "agentconsole.menumanage.message.createfailures": "创建菜单失败",
  "SM.INDEX.LABEL.CASEDEC": "为您的客户的每一个服务请求创建一个工单，通过工单来跟踪客户服务请求处理的完整生命周期，保证每一个客户请求都得到闭环，提升客户的满意度",
  "agentconsole.menumanage.message.sysadmincannotaddmenu": "当前系统管理员不能创建菜单",
  "SM.LOGIN.MESSAGE.UNLOCK_FAILED": "解除锁定失败，请输入正确的密码",
  "agentconsole.authmanage.label.newauth": "新建权限",
  "agentconsole.sessionmanagement.input.validatorMessage": "不允许输入除了下划线、点和{'@'}的特殊字符",
  "agentconsole.callmessage.calledNo": "被叫号码",
  "agentconsole.login.indexforipcc.difplat": "容易集成到不同的平台中，不占用门户和工作台的主要页面",
  "agentconsole.login.indexforipcc.auth": "权限控制",
  "agentconsole.index.label.case": "工单",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_BE_SWITCH_ES": "当前地区已切换到应急状态，该业务需要待系统恢复后才能办理，请登录应急系统继续办理其它业务",
  "agentconsole.header.title.send": "发送站内信",
  "agentconsole.dashboard.priority.medium": "优先级 中",
  "agentconsole.ccnotification.not.currently.on.call": "当前不在通话中",
  "SM.LOGIN.BUTTON.LOGIN_CONFIRM_CANCEL": "取消",
  "agentconsole.authmanage.message.selectanauth": "请选择一个权限",
  "SM.LOGIN.BUTTON.SMS_RECONFIRM_CONFIRM": "确定",
  "agentconsole.workbench.label.remarks": "备注",
  "SM.LOGIN.TITLE.INFO": "提示",
  "SM.LOGIN.LABEL.LOGIN_CONFIRM_CONTINUE": "继续访问当前系统",
  "agentconsole.sessionmanagement.table.fail.delete": "删除失败",
  "SM.LOGIN.BUTTON.CANCEL": "取消",
  "SM.LOGIN.TITLE.CHANGEPWD_RULE": "新密码必须符合以下规则：",
  "SM.LOGIN.BUTTON.FORGETPWD_SMS": "短信",
  "SM.LOGIN.MESSAGE.LOGOUT_REASON.FORCE_CHANGE_PASSWORD": "登录后请先修改密码",
  "SM.LOGIN.TITLE.CURRENT_SELECTED_PROJECT": "当前选择：",
  "SM.LOGIN.LABEL.FRAMEWORK_AD_DEMAND": "广告点播",
  "SM.LOGIN.LABEL.NOTICE_GRADE_URGENT": "紧急",
  "SM.LOGIN.LABEL.CHANGEPWD_NEWPWD": "新密码",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_BIND_INFO_NULL": "登录帐号或绑定信息为空",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_SEND_SMS_FAILED": "发送短息失败，请查看后台日志",
  "SM.LOGIN.TITLE.UPDATE_PWD_NEWPWD_CONFIRMPWD": "新密码和确认密码必须相同",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_VERIFY_CODE_DATE_INVALID": "验证码校验失败",
  "SM.LOGIN.MESSAGE.REPEAT_SUBMITED": "请稍等，已经发送请求，等待回复",
  "agentconsole.dashboard.title.unassigned": "未分配",
  "SM.LOGIN.TIPS.SAVE": "保存",
  "SM.LOGIN.MESSAGE.NONLOCAL_USER_RETRIEVEPASSWORD_FAILED": "找回密码失败，非本地用户不能使用找回密码功能",
  "SM.LOGIN.TITLE.PASSWORD_INIT": "您使用的是系统初始密码，为安全起见，请立即修改密码",
  "SM.LOGIN.BUTTON.UPDATE_PWD_MODIFY_SUCCESS": "修改成功",
  "agentconsole.dashboard.title.interactionperday": "日接触量",
  "SM.PORTAL.LABEL.INPUT_CFM_PWD": "请确认新密码",
  "SM.LOGIN.TITLE.CHANGEPWD_SUCCESS": "提示",
  "SM.LOGIN.LABEL.COPYRIGHT": "版权所有  (c)  华为技术有限公司  2016.",
  "SM.LOGIN.TITLE.PASSWORD_WILLEXPIRED": "您的密码即将过期，为安全起见，请修改密码",
  "agentconsole.dashboard.title.taskcreate": "创建任务数",
  "agentconsole.dashboard.title.task": "任务情况概览",
  "SM.LOGIN.BUTTON.QUERY": "查询",
  "agentconsole.login.restpwd.fail": "重置密码失败\r\n",
  "SM.LOGIN.LABEL.AUTH_FAILED": "帐号或密码错误",
  "agentconsole.login.label.reset": "重置",
  "SM.LOGIN.TIPS.LASTLOGINTIME": "上次登录时间：",
  "SM.LOGIN.LABEL.SYSTEM_ERROR": "系统错误",
  "agentconsole.dashboard.priority.high": "优先级 高",
  "SM.LOGIN.TITLE.NOTICE_LIST": "公告列表",
  "agentconsole.login.label.username": "用户名",
  "agentconsole.index.label.chatdec": "与您的客户进行多媒体在线交谈的同时，可查看客户全方位的历史接触信息，最大化您的服务效率，并为您的客户提供渠道协同（Omni-Channel）的服务体验。",
  "agentconsole.ccnotification.has.been.sent": "已发送",
  "SM.LOGIN.TITLE.UPDATE_PWD_MODIFY_FAILED": "修改失败",
  "SM.LOGIN.TITLE.BE_CATALOG": "运营实体目录：",
  "agentconsole.index.label.casedraft": "工单草稿",
  "agentconsole.login.indexforipcc.opengateway": "开放网关",
  "agentconsole.login.label.forgot": "忘记密码?",
  "agentconsole.sessionmanagement.table.success.delete": "删除成功",
  "agentconsole.callmessage.title": "来电消息",
  "SM.LOGIN.TITLE.SMS_RECONFIRM": "短信二次认证",
  "agentconsole.quickentrance.deleteclass": "删除该目录",
  "agentconsole.login.indexforipcc.thirdparty": "三方通话",
  "agentconsole.sessionmanagement.table.deleteconfim": "删除确认",
  "agentconsole.login.indexforipcc.sequence": "队列信息查询",
  "SM.LOGIN.TITLE.UPDATE_PWD_LOGINID": "帐号：",
  "SM.LOGIN.LABEL.FAST_ENTER": "快捷入口",
  "agentconsole.menumanage.field.url": "菜单地址",
  "agentconsole.login.indexforipcc.statecontrol": "接续状态控制",
  "agentconsole.common.expiry.suffix": "到期，请您及时关注，如需继续使用请联系运营人员",
  "agentconsole.login.indexforipcc.signin": "需要处理语音呼叫的客服人员签入CTI平台",
  "agentconsole.login.indexforipcc.answer": "接听来话",
  "agentconsole.authmanage.message.notdelnoleafauth": "不允许删除有子权限的权限",
  "agentconsole.quickentrance.managemententrance": "入口管理",
  "SM.LOGIN.TITLE.APPEND_MENU_SEARCH": "搜索条件：",
  "agentconsole.authmanage.label.authtype.operation": "操作权限",
  "SM.LOGIN.TITLE.APPEND_MENU_TITLE": "添加快捷菜单",
  "agentconsole.dashboard.title.todo": "待办",
  "SM.CHANGELANGAUGE.REFRESH.MESSAGE": "切换语言后将会重新加载整个页面，是否继续？",
  "agentconsole.sessionmanagement.tip.error": "错误",
  "agentconsole.callmessage.callskill": "技能队列",
  "agentconsole.faqwordreminder.norecommend": "暂无FAQ推荐",
  "SM.LOGIN.TITLE.UPDATE_PWD_OLDPWD": "旧密码：",
  "SM.LOGIN.MESSAGE.SELECT_PROJECT": "请选择工程",
  "agentconsole.icontitle.create_sibling": "新增同级",
  "SM.LOGIN.LABEL.PASSWORD": "密码",
  "agentconsole.callmessage.faq.reminder": "FAQ推荐",
  "SM.SERVLET.BATCH.RESET.PASSWORD.EMPLIST": "批量重置租间用户密码：[员工列表:",
  "SM.LOGIN.MESSAGE.CHANGEPWD_SUCCESS": "修改密码成功",
  "agentconsole.login.indexforipcc.opengatewayde": "开放网关为第三系统集成智能客户服务云的语音呼叫功能提供了基于标准RESTful协议的接口，第三方系统可以不用购买自己的呼叫中心设备，而通过购买服务的方式在自己的系统中实现呼叫中心的能力。",
  "SM.LOGIN.LABEL.SETTINGS": "设置",
  "SM.LOGIN.MESSAGE.MENU_ACCESS_TIME_NOT_MATCH": "当前时间不在菜单允许访问时间内，菜单允许访问时间为：",
  "agentconsole.menumanage.field.menu_name": "菜单名称",
  "agentconsole.common.button.save": "保存",
  "agentconsole.login.restpwd.errorinfo": "用户名或邮箱不正确",
  "SM.LOGIN.BUTTON.OK": "确定",
  "SM.LOGIN.TIPS.LOCK": "锁屏",
  "SM.LOGIN.LABEL.NOTICE_GRADE_EXTRA_URGENT": "特急",
  "agentconsole.common.tips.information": "提示",
  "SM.INDEX.LABEL.TASKDEC": "在工单处理过程中，您可以通过派发任务的方式，协同多个部门和人员共同处理，同一条工单可以创建多个任务",
  "agentconsole.dashboard.title.taskperday": "日处理情况",
  "agentconsole.authmanage.message.notdelbaseauth": "不允许删除公共权限",
  "agentconsole.ccnotification.not.currently.on.three": "当前通话状态不能发起该流程",
  "agentconsole.custinfo.label.custId": "客户ID",
  "agentconsole.header.label.home": "首页",
  "agentconsole.menumanage.message.deletemenufail": "菜单删除失败",
  "SM.LOGIN.LABEL.ERRORPAGE_ERRORSTACK": "错误堆栈：",
  "SM.LOGIN.LABEL.ERRORPAGE_ERRORNODETAIL_OPEN": "详情<",
  "agentconsole.dashboard.enumeration.today": "今天",
  "agentconsole.header.title.search": "搜索",
  "agentconsole.wordreminder.recommend": "话术推荐",
  "agentconsole.wordreminder.ai.recommend": "AI话术推荐",
  "agentconsole.dashboard.priority.low": "优先级 低",
  "agentconsole.quickentrance.complete": "完成",
  "SM.LOGIN.TIPS.LOGINIP": "IP 地址：",
  "SM.LOGIN.LABEL.FINISH": "完成",
  "SM.LOGIN.MESSAGE.GRAFIC.VERIFY.CODE.REFRESH": "看不清，换一张",
  "SM.LOGIN.LABEL.RESET": "重置",
  "agentconsole.authmanage.message.deleteconfirm": "是否确定要删除此权限？",
  "SM.LOGIN.LABEL.LOGOUT": "退出",
  "agentconsole.contacthistory.oneMinAgo": "分钟前",
  "SM.LOGIN.TITLE.UPDATE_PWD_CONFIRMPWD_EMPTY": "确认新密码为空",
  "agentconsole.contacthistory.hourAgo": "小时前",
  "agentconsole.login.indexforipcc.useage": "智能客户服务云提供支持多租户能力的座席框架，实现座席开通即可用，结合华为的CTI、UAP平台，为客户提供完整的云化语音/视频客服能力",
  "SM.LOGIN.TITLE.UPDATE_PWD_OLDPWD_ERROR": "旧密码错误",
  "agentconsole.dashboard.title.caseunassigned": "未分配情况",
  "SM.LOGIN.TITLE.PORTAL_SWITCH_CONTEXTDATA": "切换BE",
  "SM.ORGSTAFF.LABEL.ORG_FORBID_TIPS": "确认禁用组织？",
  "agentconsole.ccnotification.max.recipients": "收件人不能超过5个",
  "SM.LOGIN.LABEL.NOTICE_STATUS": "状态",
  "SM.LOGIN.BUTTON.UPDATE_PWD_CANCEL": "取消",
  "SM.ORGSTAFF.TITLE.ASSIGNE_MANAGER": "分配主管",
  "agentconsole.login.label.errorinfo": "用户名或密码不正确",
  "agentconsole.ccnotification.recipient.cannot.empty": "收件人不能为空",
  "agentconsole.dashboard.title.mobile": "移动终端",
  "SM.LOGIN.LABEL.LOCKED": "已锁屏",
  "agentconsole.authmanage.message.sysauthsubnode": "不允许在系统管理下添加子权限",
  "SM.LOGIN.TITLE.SWITCH_ESOP": "一键切换ESOP",
  "agentconsole.quickentrance.all": "全部",
  "agentconsole.workbench.label.userinfo": "用户信息",
  "agentconsole.dashboard.enumeration.thressdays": "最近3天",
  "agentconsole.modifycustominfo.nodatamodify": "没有数据发生变更",
  "agentconsole.login.label.service_cloud_desc": "至 臻 至 简     极 致 服 务",
  "SM.LOGIN.LABEL.CANCEL": "取消",
  "SM.AUC.MESSAGE.PWDRULE_YES": "是",
  "SM.LOGIN.TITLE.NOTICE_CONTENT": "内容",
  "agentconsole.callmessage.setNumerLengthError": "受理号码长度不能超过24！",
  "agentconsole.contacthistory.dayAgo": "天前",
  "agentconsole.login.indexforipcc.agentstate": "座席状态控制，例如座席示忙、示闲、休息等",
  "SM.LOGIN.TIPS.LOGOUT": "退出系统",
  "SM.LOGIN.LABEL.OPER_NAME": "用户名：",
  "SM.LOGIN.MESSAGE.CHANGEPWD_OLDNEW": "新密码和旧密码不能相同",
  "SM.LOGIN.LABEL.UPDATE_PWD": "修改密码",
  "agentconsole.quickentrance.title.toomanymenus": "超出可容纳的快捷菜单数量，请减少设置的快捷菜单，再重新保存",
  "SM.ORGSTAFF.MESSAGE.STAFF_DELETE_CONFIRM": "员工被删除后，该员工对应的帐号不可恢复只能重新创建，是否删除？",
  "agentconsole.header.title.case": "新建工单",
  "SM.LOGIN.LABEL.NOTICE_GRADE": "优先级",
  "SM.ORGSTAFF.TITLE.OPERATION": "操作",
  "SM.LOGIN.MESSAGE.SENDMAIL_SUCCESS": "新密码已经发送到您的邮箱，请使用新密码登录",
  "SM.LOGIN.TIPS.LOGINFAILED": "登录失败",
  "agentconsole.menumanage.message.deleteconfirm": "是否确定要删除此菜单？",
  "agentconsole.dashboard.enumeration.thirtydays": "最近30天",
  "agentconsole.login.indexforipcc.easyuse": "易于操作",
  "agentconsole.dashboard.title.total": "共计",
  "SM.LOGIN.TIPS.WELCOME_LABEL": "欢迎词：",
  "SM.LOGIN.LABEL.EMERGENCY_NOTICE": "应急公告",
  "agentconsole.login.indexforipcc.deal": "客服人员可对客户呼叫做如下处理",
  "agentconsole.login.label.cancel": "取消",
  "agentconsole.menu.config.center": "配置中心",
  "SM.LOGIN.MESSAGE.SENDSMS_SUCCESS": "新密码已经发送到您的手机，请使用新密码登录",
  "agentconsole.login.restpwd.emailpattern_error": "非法的邮箱地址",
  "message.center.message.messageManage": "站内信管理",
  "agentconsole.index.label.dashboard": "仪表盘",
  "agentconsole.menumanage.field.auth": "菜单权限",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_SWITCH_ES_SUFFIX": "”继续办理该业务",
  "agentconsole.login.indexforipcc.release": "呼叫释放",
  "agentconsole.dashboard.title.chat": "在线交谈情况概览",
  "agentconsole.index.label.querytask": "查询任务",
  "agentconsole.sessionmanagement.tip.success": "成功",
  "SM.LOGIN.TITLE.NOTICE_CRUM": "系统管理 > 系统维护 > 公告查询",
  "agentconsole.header.title.help": "帮助",
  "agentconsole.header.title.send.email.notification": "外发邮件通知消息",
  "agentconsole.login.indexforipcc.calldata": "呼叫数据查询",
  "agentconsole.dashboard.enumeration.yesterday": "昨天",
  "SM.LOGIN.LABEL.FRAMEWORK_CALENDAR": "日历",
  "agentconsole.contacthistory.nocontactinfo": "暂无接触历史",
  "SM.LOGIN.BUTTON.LOGIN": "登录",
  "SM.LOGIN.LABEL.FORGOTPASSWORD": "忘记密码？",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_BE_SWITCH_ES_SUFFIX": "”菜单继续办理该业务",
  "SM.LOGIN.MESSAGE.CONFIRM": "确认",
  "SM.LOGIN.TITLE.COMMONTOOLS": "常用工具",
  "SM.LOGIN.MESSAGE.LOGOUT_REASON.NO_LOGIN": "当前未登录，请登录后再操作",
  "SM.LOGIN.LABEL.NOTICE_INCLUDE_SUB_ORG_Y": "是",
  "agentconsole.dashboard.title.wechat": "微信",
  "agentconsole.login.label.verifycode.empty": "校验码为空",
  "SM.LOGIN.LABEL.VCODE_EMPTY": "验证码为空",
  "SM.LOGIN.TITLE.DROPLIST_MENU": "菜单",
  "agentconsole.contact.customerInfo": "客户信息",
  "agentconsole.history.lastloginfail": "上次登录失败",
  "SM.LOGIN.LABEL.NOTICE_INCLUDE_SUB_ORG_N": "否",
  "agentconsole.history.logintime": "时间",
  "agentconsole.menumanage.paneltitle.menumanage": "菜单管理",
  "SM.LOGIN.RESETPWD.ERRORINFO": "登录帐号或邮箱地址不正确",
  "agentconsole.dashboard.title.evaluation": "客户满意度",
  "agentconsole.must.be.a.number":"必须是数字",
  "WEB.VALIDATOR.RANGE":"输入值超出范围 {0} - {1}",
  "agentconsole.voicetransfer.user" : "用户",
  "agentconsole.voicetransfer.agent" : "座席",
  "agentconsole.voicetransfer.chatbot" : "机器人",
  "agentconsole.title.transcription" : "实时转写",
  "agentconsole.title.agent.overview" : "座席概览",
  "agentconsole.agent.assistant.recomend.history" : "历史推荐",
  "agentconsole.agent.assistant.faq.history" : "历史问答",
  "agentconsole.agent.assistant.auto.create.case" : "自动创建工单",
  "agentconsole.agent.assistant.recomend.action" : "操作推荐",
  "agentconsole.agent.assistant.summary" : "生成摘要",
  "agentconsole.agent.assistant.case" : "生成案例",
  "agentconsole.agent.refine.simple" : "简洁",
  "agentconsole.agent.refine.formal" : "正式",
  "agentconsole.agent.refine.friendly" : "友好的",
  "agentconsole.agent.refine.humorous" : "幽默的",
  "agentconsole.agent.refine" : "润色",
  "agentconsole.agent.please.notice" : "请注意话术",
  "agentconsole.agent.knowledge.recommend" : "知识推荐",
  "agentconsole.agent.knowledge.map":"地图",
  "agentconsole.agent.knowledge.product.recommend" : "推荐产品",
  "agentconsole.agent.knowledge.product.popular" : "热门产品",
  "agentconsole.agent.knowledge.product.link" : "发现优惠",
  "agentconsole.agent.complaint.risk" : "投诉风险",
  "agentconsole.agent.call.context" : "呼叫上下文",
  "agentconsole.agent.customer.info" : "客户信息",
  "agentconsole.agent.session.monitor" : "会话监控",
  "agentconsole.agent.basic.info" : "基础信息",
  "agentconsole.agent.customer.name" : "客户姓名",
  "agentconsole.agent.phone.number" : "手机号码",
  "agentconsole.agent.service.duration" : "入网时长",
  "agentconsole.agent.customer.brand" : "所属品牌",
  "agentconsole.agent.customer.status" : "用户状态",
  "agentconsole.agent.customer.address" : "归属地",
  "agentconsole.agent.bussiness.info" : "业务信息",
  "agentconsole.agent.order.status" : "订购情况",
  "agentconsole.agent.business.usage" : "业务使用",
  "agentconsole.agent.terminal.profile" : "终端信息",
  "agentconsole.agent.terminal.vendor" : "终端厂商",
  "agentconsole.agent.terminal.model" : "终端型号",
  "agentconsole.agent.network.standard" : "网络制式",
  "agentconsole.agent.generate.summary": "生成摘要",
  "agentconsole.agent.summary": "摘要",
  "agentconsole.agent.submit.success": "提交成功",
  "agentconsole.agent.intelligent.fill": "智能填单",
  "agentconsole.agent.intelligent.click": "一键填单",
}