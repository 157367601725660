<template>
    <sweet-dialog class="ccme-chat-email-view" append-to-body destroy-on-close v-model="dialogVisible" width="1248px"
        @opened="handleOpen" @closed="handleClosed" ccmeCustom :close-on-click-modal="false">
        <template #header>
            <div class="email-view-header">
                <div class="email-view-header__title">{{ $t('ccmessaging.chat.multimedia.preview') }}</div>
                <div class="email-view-header__button">
                    <sweet-button text>
                        <sweet-tooltip :show-after="800" :content="$t('ccmessaging.chat.chatconfig.refresh')"
                            placement="bottom">
                            <img class="icon-button refresh-icon-img" @click="refreshEmail" src="@/assets/img/search/search_refresh.png">
                        </sweet-tooltip>
                        <sweet-tooltip :show-after="800" :content="$t('ccmessaging.chat.chatemail.handled')"
                            placement="bottom">
                            <img class="icon-button" @click="handleEmailStatus(messageInformationInstance.id, 2)"
                                src="@/assets/img/icon/icon_handle_email.png"
                                v-if="messageInformationInstance.isRead != 2 && messageInformationInstance.emailType == 0 && messageInformationInstance.readAndHandleAuth == 'true'">
                        </sweet-tooltip>
                    </sweet-button>
                </div>
            </div>
        </template>
        <div class="chat-email-view">
            <div class="email-preview-header">
                <div class="email-preview-header__title" :title="messageInformationInstance.title">
                    <span class="title-text">{{ messageInformationInstance.title }}</span>
                    <span class="email-status"
                        v-if="messageInformationInstance.isRead == 2 && messageInformationInstance.emailType == 0 && messageInformationInstance.readAndHandleAuth == 'true'">({{
                            $t('ccmessaging.chat.chatemail.handled') }})</span>
                </div>
                <div class="email-preview-header__wrap">
                    <div class="email-preview-header__text">
                        <span class="label-text">{{ $t('message.center.nocitiction.sender') }}：</span>
                        <span :title='messageInformationInstance.sender'>{{ messageInformationInstance.sender }}</span>
                    </div>
                    <div class="email-preview-header__text">
                        <span class="label-text">{{ $t('message.center.label.receiver') }}：</span>
                        <span :title="messageInformationInstance.receiver">{{ messageInformationInstance.receiver }}</span>
                    </div>
                    <div class="email-preview-header__text" v-if='messageInformationInstance.ccList'>
                        <span class="label-text">{{ $t('ccnotification.notifition.sendmessage.ccRecipient') }}：</span>
                        <span :title="messageInformationInstance.ccList">{{ messageInformationInstance.ccList }}</span>
                    </div>
                    <div class="email-preview-header__text" v-if='messageInformationInstance.bccList'>
                        <span class="label-text">{{ $t('ccnotification.notifition.sendmessage.bccRecipient') }}：</span>
                        <span :title="messageInformationInstance.bccList">{{ messageInformationInstance.bccList }}</span>
                    </div>
                    <div class="email-preview-header__text">
                        <span class="label-text">{{ $t('message.center.label.sendTime') }}：</span>
                        <span :title="messageInformationInstance.sendTime">{{ messageInformationInstance.sendTime }}</span>
                    </div>
                    <div class="email-preview-header__text" v-if="messageInformationInstance.forwardTime">
                        <span class="label-text">
                            <img src="@/views/ccmessaging/assets/img/online-chat-workbench/icon_forwarded.png"
                                style="height: 14px; width: 14px" />
                            {{ $t('ccmessaging.chat.chatemail.forwardedTime') }}：</span>
                        <span :title="messageInformationInstance.forwardTime">{{ messageInformationInstance.forwardTime
                        }}</span>
                    </div>
                    <div class="email-preview-header__attachment" v-if="messageInformationInstance.attachmentNameList">
                        <span class="label-text">{{ $t('ccnotification.notification.label.attachment') }}：</span>
                        <span class="attachment-list" :title="messageInformationInstance.attachmentNameList">{{
                            messageInformationInstance.attachmentNameList }}</span>
                        <i @click="downloadRecEmail(messageInformationInstance)"
                            class="sweetui-icon-download-l icon-button"></i>
                    </div>
                </div>
            </div>
            <div class='email-preview-content' v-html="orignalEmailContent"></div>
        </div>
    </sweet-dialog>
</template>

<script setup>
import { ref, reactive, getCurrentInstance, computed, toRefs } from "vue";
const emit = defineEmits(['update:chatEmailView',])
const { appContext: { config: { globalProperties } } } = getCurrentInstance();
import { ElMessageBox } from 'element-plus';
import {
    queryEmail,
    handleEmail
} from '@ccme/api/online-chat-workbench/index.js'
import {
    getEmailWhiteList,
    tipHandler,
} from '@/views/ccmessaging/utils/index'
import {
    aiccDownload
} from '@/utils/aiccUploadAndDownload.js'
const props = defineProps({
    chatEmailViewDialog: {
        default: false
    },
    chatEmailViewInfo: {
        default: null
    }
})
const { chatEmailViewDialog, chatEmailViewInfo } = toRefs(props)

const dialogVisible = computed({
    get() {
        return chatEmailViewDialog.value
    },
    set(val) {
        emit('update:chatEmailViewDialog', val)
    }
});

let messageInformationInstance = reactive({})

// 重复点击按钮标志
let refreshClick = false
const refreshEmail = () => {
    getEmail();
}

const handleOpen = () => {
    getEmail()
}

const handleClosed = () => {
    Object.keys(messageInformationInstance).forEach(item => {
        delete messageInformationInstance[item]
    })
    orignalEmailContent.value = ''
}

// xss标签白名单校验器
const filterXssFunc = window['filterXSS']

// 原始邮件内容
const orignalEmailContent = ref('')
const getEmail = () => {
    if (refreshClick) {
        return false
    }

    const {
        id,
        emailMessagePage,
        handlerId,
        handlerName,
        sendTime,
        readAndHandleAuth = 'true',
        forwardTime
    } = chatEmailViewInfo.value
    refreshClick = true;
    const params = {
        id,
        emailMessagePage,
        handlerId,
        handlerName,
        handlerType: 0,
        readAndHandleAuth,
        sendTime
    }
    queryEmail(params).then((viewResultModels = {}) => {
        const { result, isSuccess } = viewResultModels
        Object.keys(result).forEach(item => messageInformationInstance[item] = result[item])
        messageInformationInstance.originSendTime = messageInformationInstance.sendTime;
        messageInformationInstance.sendTime = globalProperties.$TimeUtils.transTime(messageInformationInstance.sendTime);
        messageInformationInstance.readAndHandleAuth = readAndHandleAuth;
        if (forwardTime) {
            messageInformationInstance.forwardTime = globalProperties.$TimeUtils.transTime(forwardTime);
        }
        if (messageInformationInstance.attachmentList) {
            let attachment = messageInformationInstance.attachmentList
            let attachments = JSON.parse(attachment);
            let attachmentNameList = "";
            for (let key in attachments) {
                if ('' != attachmentNameList) {
                    attachmentNameList += ",";
                }
                attachmentNameList += attachments[key];
            }
            messageInformationInstance.attachmentNameList = attachmentNameList;
        }

        if (isSuccess && refreshClick) {
            tipHandler(globalProperties.$t('ccmessaging.chat.refresh.fail'), 'error');
            return false
        }

        if (!messageInformationInstance.content) {
            return false
        }

        // XSS注入问题
        let contents = messageInformationInstance.content?.replace(/<.*?script.*?>.*?<.*?\/script.*?>/ig, '');
        // 白名单过滤
        let emailWhiteList = getEmailWhiteList();
        orignalEmailContent.value = filterXssFunc(contents, { // 自定义js-xss的options
            whiteList: emailWhiteList, // 设置标签白名单
            stripIgnoreTag: true, // 去除不在白名单中的标签
            stripIgnoreTagBody: ["script", "style"], // 去除不在白名单标签的标签体里的内容
        });
    }).finally(() => {
        refreshClick = false
    })
}

// 下载收件箱邮件附件
const downloadRecEmail = ({ id, originSendTime }) => {
    ElMessageBox.confirm(globalProperties.$t("ccnotification.notification.label.downloadAttachment"), globalProperties.$t("ccm.agent.mobileagent.confirm"), {
        confirmButtonText: globalProperties.$t('ccmessaging.chat.email.localImage.confirm'),
        cancelButtonText: globalProperties.$t('ccmessaging.chatbot.button.cancel'),
        type: 'warning',
        closeOnClickModal: false
    }).then(() => {
        let downInfo = {};
        downInfo["downloadInfo"] = {};
        downInfo["downloadInfo"]["id"] = id;
        downInfo["downloadInfo"]["sendTime"] = originSendTime;
        const url = '/service-cloud/u-route/ccmessaging/fileservice/downLoadFile'
        aiccDownload(downInfo, url)
    })
}

// 处理邮件 id:邮件id status:0-已读 1-未读 2-已处理
const handleEmailStatus = (id, status) => {
    const { handlerId, handlerName, sendTime } = chatEmailViewInfo.value
    let paramObj = {};
    paramObj["id"] = id;
    paramObj["isRead"] = status;
    paramObj["handlerId"] = handlerId;
    paramObj["handlerName"] = handlerName;
    paramObj["handlerType"] = 0;
    paramObj["sendTime"] = sendTime;

    handleEmail(paramObj).then((result = {}) => {
        const { returnCode } = result
        if (returnCode?.toString() === '0') {
            messageInformationInstance.isRead = status
            tipHandler(globalProperties.$t('ccmessaging.chat.email.channel.modifydraftsuccess'), 'success')
        } else {
            tipHandler(globalProperties.$t('ccmessaging.chat.email.channel.modifydraftfail'), 'error')

        }
    })
}
</script>

<style lang="less" scoped>
.ccme-chat-email-view {
  .el-dialog__body {
    border-top: 1px solid #EEE;
    padding-top: 20px;
  }

  .sweet-dialog-header__title {
    width: 100%
  }

  .email-view-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 32px;

    &__title {
      font-weight: 600;
      color: rgb(46, 46, 46);
      font-size: 16px;
      word-break: break-all;
    }

    &__button {
      .icon-button {
        margin-left: 1rem;
        width: var(--14px-to-rem);
        height: var(--14px-to-rem);
      }
    }
  }

  .chat-email-view {

    .tools-button-wrap {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }

    .email-preview-header {
      width: 100%;
      box-sizing: border-box;
      border-bottom: 1px solid #EEE;
      line-height: 1.5;

      .email-preview-header__title {
        display: flex;
        align-items: center;
        width: 100%;
        font-size: 1rem;
        font-weight: 600;
        color: #1C1C1C;

        .title-text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .email-status {
          white-space: nowrap;
          color: #9E9E9E;
        }
      }

      .email-preview-header__wrap {
        padding: 1rem 0;
        display: flex;
        gap: 1rem;
        flex-direction: column;
        align-items: center;

        .email-preview-header__text {
          width: 100%;
          font-size: 0.75rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: rgb(46, 46, 46);

          .label-text {
            display: inline-block;
            min-width: 6rem;
            white-space: nowrap;
            color: #707070;
          }
        }

        .email-preview-header__attachment {
          width: 100%;
          font-size: 0.75rem;
          display: flex;
          align-items: center;

          .label-text {
            display: inline-block;
            min-width: 6rem;
            white-space: nowrap;
            color: #707070;
          }

          .attachment-list {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .icon-button {
            cursor: pointer;
            font-size: var(--14px-to-rem);
            padding-left: var(--12px-to-rem);
            line-height: normal;
          }
        }
      }
    }

    .email-preview-content {
      padding-top: 1rem;
      width: 100%;
      height: 25rem;
      overflow: auto;
      white-space: normal;
      word-wrap: normal;
      word-break: break-all;
      text-align: justify;
      line-height: 22px;
      cursor: default;
    }
  }
}

.el-dialog[ccmeCustom] {
  .el-dialog__header {
    .el-dialog__headerbtn {
      width: 16px;
      height: 16px;
      margin: 22px 22px;
    }
  }
}

body.RTL {
  .email-view-header img {
    transform: scaleX(1);
  }

  .email-preview-content img {
    -moz-transform: none;
    -webkit-transform: none;
    -o-transform: none;
    transform: none;
  }
}

:deep(.email-preview-content) {
  em {
    font-style: italic !important;
  }
}
</style>