
const cssRoutes = [
  {
    path: '/css',
    name: 'css',
    component: () => import(/* webpackChunkName: "aicc-cms" */'@/views/css/App.vue'),
    children: [
    ]
  }
]

export default cssRoutes
