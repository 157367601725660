export const languageMap = {
  en: 'en_US',
  'en-AU': 'en_US',
  'en-IE': 'en_US',
  'en-ZA': 'en_US',
  'en-GB': 'en_US',
  'en-GB-oxendict': 'en_US',
  'en-IN': 'en_US',
  'en-NZ': 'en_US',
  'en-US': 'en_US',
  'en-CA': 'en_US',
  fr: 'fr_FR',
  'fr-FR': 'fr_FR',
  'fr-CA': 'fr_FR',
  'fr-CH': 'fr_FR',
  es: 'es_ES',
  'es-AR': 'es_ES',
  'es-CO': 'es_ES',
  'es-CR': 'es_ES',
  'es-HN': 'es_ES',
  'es-419': 'es_ES',
  'es-US': 'es_ES',
  'es-PE': 'es_ES',
  'es-MX': 'es_ES',
  'es-VE': 'es_ES',
  'es-UY': 'es_ES',
  'es-ES': 'es_ES',
  'es-CL': 'es_ES',
  th: 'th_TH',
  'th-TH': 'th_TH',
  pt: 'pt_BR',
  'pt-BR': 'pt_BR',
  'pt-PT': 'pt_BR',
  ar: 'ar_SA',
  'ar-SA': 'ar_SA',
  zh: 'zh_CN',
  'zh-CN': 'zh_CN',
  'zh-TW': 'zh_CN'
}
