import request from "@/utils/request.js";


export function querySocialMedia(data) {
  return request({
    url: '/service-cloud/rest/ccmessaging/v1/channelconfig/querySocialMedia',
    method: 'post',
    data
  })
}

/**
 * 根据accessMode查询渠道列表
 *
 * @param data 查询参数
 * @returns {*}
 */
export function querySocialMediaByAccessMode(data) {
  return request({
    url: '/service-cloud/rest/ccmessaging/v1/socialMedia/querySocialMediaByAccessMode',
    method: 'post',
    data
  })
}