import AiccFullScreen from './aicc-full-screen/index.vue'
import AiccPagingLoadSelect from './aicc-paging-load-select/index.vue'
import AiccTable from './aicc-table/index.vue'
import aiccTableMultHeader from './aicc-table-multHeader/index.vue'
import AiccAdvancedSearch from './aicc-advancedSearch/index.vue'
import AiccAudio from './aicc-audio/index.vue'
import AiccChatAudio from './aicc-chat-audio/index.vue'
import AiccTabs from './aicc-tabs/AiccTabs.vue'
import AiccTabPane from './aicc-tabs/AiccTabPane.vue'
import AiccTooltip from './aicc-tooltip/index.vue'
import AiccTree from './aicc-tree/index.vue'
import AiccSelect from './aicc-select/index.vue'
import AiccButton from './aicc-button/index.vue'
import OrgTreeSelect from './org-tree-select/index.vue'
import OrgTree from './org-tree/index.vue'

const components = [
  { 'aicc-full-screen': AiccFullScreen },
  { 'aicc-paging-load-select': AiccPagingLoadSelect },
  { 'aicc-table': AiccTable },
  { 'aicc-table-multHeader': aiccTableMultHeader },
  { 'aicc-advanced-search': AiccAdvancedSearch },
  { 'aicc-audio': AiccAudio },
  { 'aicc-chat-audio': AiccChatAudio },
  { 'aicc-tabs': AiccTabs },
  { 'aicc-tab-pane': AiccTabPane },
  { 'aicc-tooltip': AiccTooltip },
  { 'aicc-tree': AiccTree },
  { 'aicc-select': AiccSelect },
  { 'aicc-button': AiccButton },
  { 'org-tree-select': OrgTreeSelect },
  { 'org-tree': OrgTree }
]

function registComponents(app) {
  components.forEach((component) => {
    const name = Object.keys(component)[0]
    app.component(name, component[name])
  })
}
export default registComponents
