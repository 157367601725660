import request from '@/utils/request'

/**
 * 包装上传请求参数
 *
 * @param params 上传业务参数
 * @param file 上传文件
 * @return 上传接口入参
 */
export function aiccSetImportParam(params, file) {
  let param = new FormData()
  param.append('#Model.file', file)
  const requestParam = {
    model: {'#Model.file': {}},
    params,
  }
  param.append('ueefire', JSON.stringify(requestParam))
  return param
}

/**
 * 包装批量上传请求参数
 *
 * @param params 上传业务参数
 * @param file 上传文件
 * @return 上传接口入参
 */
export function aiccSetImportParamBatch(params, files) {
    let param = new FormData()
    for (let i = 0; i < files.length; i++) {
        param.append('#Model.multiFile', files[i])
    }
    const requestParam = {
        model: { '#Model.multiFile': [{}] },
        params,
    }
    param.append('ueefire', JSON.stringify(requestParam))
    return param
}

/**
 * 下载文件
 *
 * @param params 下载入参
 * @param url 下载请求接口
 * @return 下载返回结果
 */
export function aiccDownload(params, url) {
  return new Promise((resolve, reject) => {
    let dateTest = new Date().getTime()
    const reqUrl = `${url}?t=${dateTest}&u-jqueryForm&u-export` //组装url
    let reqData = new FormData() //入参
    const requestParam = {model: null, params: params}
      reqData.append('ueefire', JSON.stringify(requestParam))
      request({
          url: reqUrl,
          method: 'post',
          data: reqData,
          responseType: 'blob',
          requestHeaders: [{
              'Content-Type': 'multipart/form-data;'
          }]
      }).then((result) => {
          aiccDownloadSaveToLocal(result) // 下载文件到本地
          let resolveRst = result
          if (result.type === 'text/plain') { // 处理下载失败的异常场景
              // 将blob类型数据转换为json
              resolveRst = blobToJson(result)
          }
          resolve(resolveRst) // 返回response给业务
      }).catch((e) => {
          reject(e)
      })
  })
}

// uee后台 throw new UEEException 返回的是个文本 ，下载返回的类型是blob类型，需要将blob转换成文本或者json
async function blobToJson(result) {
  let resolveText = await result.text()
  let resultStr = resolveText.substring(resolveText.indexOf('{'), resolveText.indexOf('}') + 1)
  let resultJson = JSON.parse(resultStr)
  return resultJson
}

// 将文件以附件形式下载到本地
export function aiccDownloadSaveToLocal(result) {
  const data = result.data
  if (!data) {
    return
  }
  const header = result.headers['content-disposition']
  const filename = header.match(/filename="(.*)"/)[1] // 获取文件名
  let url = window.URL.createObjectURL(new Blob([data]))
  let a = document.createElement('a')
  a.style.display = 'none'
  a.href = url
  a.setAttribute('download', decodeURIComponent(escape(filename))) // 解决中文乱码问题
  document.body.appendChild(a)
  a.click() //执行下载
  window.URL.revokeObjectURL(a.href)
  document.body.removeChild(a)
}

// 将文件以附件形式下载到本地（如果文件名包含中文,在后端先转码）
export function aiccDownloadNew(result) {
  const data = result.data
  if (!data) {
    return
  }
  const header = result.headers['content-disposition']
  const filename = header.match(/filename="(.*)"/)[1] // 获取文件名
  let url = window.URL.createObjectURL(new Blob([data]))
  let a = document.createElement('a')
  a.style.display = 'none'
  a.href = url
  a.setAttribute('download', decodeURIComponent(filename)) // 解决中文乱码问题
  document.body.appendChild(a)
  a.click() //执行下载
  window.URL.revokeObjectURL(a.href)
  document.body.removeChild(a)
}