<template>
    <div class="aicc-p-top--medium">
        <div class="aicc-p-top--medium aicc-p-bottom--small audio-right" v-if="isShowPlayer">
            <aicc-audio :src="playerSrc" ref="aiccAudio" :can-down-load="false" :can-close="true"
                class="audio-width" autoplay="autoplay" @closeAudioHandle="closeAudioHandle"
                @audioPlayingHandle="audioPlayingHandle" />
        </div>

        <aicc-table id="aicc-ccmanegement-same-time-record" class="aicc-table table-height" v-loading="loading"
            :row-key="(row) => row.callSerialno" ref="contractRecordTable" :tableData="tableData"
            :cell-class-name="getCellStyleClass" :tableColumns="tableColumns" @row-click="rowClick"
            :tableTools="{ showPagination: true, columnConfig: false }" :paginationConfig="paginationConfig"
            @handlePagination="handlePagination" max-height='900'>

            <template #callSerialno="scope">
                <sweet-link :underline="false" type="primary" @click="jumpToDetail(scope.row)" v-if="scope.row.callSerialno != callSerialno">
                    {{ scope.row.callSerialno }}
                </sweet-link>
                <span v-else>{{ scope.row.callSerialno }}</span>
            </template>
            <template #callNo="scope">
                <agent-name-show-pop :is-show-pop="scope.row.callNoIsWorkNo" :work-number="scope.row.callNo"/>
            </template>
            <template #caller="scope">
                <agent-name-show-pop :is-show-pop="scope.row.callerIsWorkNo" :work-number="scope.row.caller" />
            </template>
            <template #called="scope">
                <agent-name-show-pop :is-show-pop="scope.row.calledIsWorkNo" :work-number="scope.row.called" />
            </template>
            <template #acceptNo="scope">
                <agent-name-show-pop :is-show-pop="scope.row.acceptNoIsWorkNo" :work-number="scope.row.acceptNo" />
            </template>
            <template #mediaAbility="scope">
                <span>
                    {{ scope.row.mediaAbilityStr || "-" }}
                    <span v-if="scope.row.channelType">
                        -{{ scope.row.channelTypeStr }}
                    </span>
                </span>
            </template>
            <template #staffHangup="scope">
                <span>
                    {{ scope.row.staffHangupStr || "-" }}
                </span>
            </template>
            <template #calltype="scope">
                <span>
                    {{ scope.row.calltypeStr || "-" }}
                </span>
            </template>
            <template #recordingfiles="scope">
                <div class="cell-style__recordingfiles">
                    <!--没有录音-->
                    <div v-if="!scope.row.recordId && !scope.row.isExist" class="button-butween">
                        <sweet-button text type="primary" disabled>
                          {{ i18n("ccm.agent.contact.play") }}
                        </sweet-button>
                        <sweet-button text type="primary" disabled>
                          {{ i18n("ccm.agent.contact.download") }}
                        </sweet-button>
                    </div>
                    <div v-else class="button-butween">
                        <sweet-button v-if="!scope.row.audioPlaying" text type="primary"
                            :disabled="scope.row.recordInfoDisable || !play || scope.row.isPlayVideoTag"
                            @click="handleMediaPlay(scope.row)">
                            {{ i18n("ccm.agent.contact.play") }}
                        </sweet-button>
                        <sweet-button v-else text type="primary"
                            :disabled="scope.row.recordInfoDisable || !play || scope.row.isPlayVideoTag"
                            @click="handleMediaPlay(scope.row)">
                            {{ i18n("ccm.transtask.label.pause") }}
                        </sweet-button>
                        <sweet-button text type="primary" :disabled="scope.row.recordInfoDisable || !down"
                            @click="downloadRecord(scope.row)">
                            {{ i18n("ccm.agent.contact.download") }}
                        </sweet-button>
                    </div>
                </div>
            </template>
        </aicc-table>
        <span class="footer--tips" v-if="isShowTips">*{{safeHtmlEncode(i18n("ccm.speciallist.msg.maxCountQuery", maxCountQuery)) }}</span>
        <download-file id="contactRecordDownloadFilePop" ref="downloadFileDialog" :title="downloadFileDialogTitle" useExtendedValidator
            :data-info="downloadFileDialogDataInfo" :data-type="downloadFileDialogDataType" :required-pwd="requiredZipPwd"
            @get-download-result="downloadFileResult" />
        <contact-video-pop ref="contactVideoPopRef" v-if="isShowContactVideoPop" :is-show="isShowContactVideoPop"
            :title="videoPlayTitle" :record-id="videoPlayRecordId" :video-type="videoPlayType"
            @close="() => isShowContactVideoPop = false" />
        <contact-file-pop ref="ContactFilePopRef" v-if="isShowContactFilePop" :is-show="isShowContactFilePop"
            :record-ids="contactFilePopRecordIds" :video-disable="contactFilePopVideoDisable"
            :title="i18n('ccm.agent.contact.recordInfo')" @play-video="playFile" @download="downloadRecord"
            @close="() => isShowContactFilePop = false" />
    </div>
</template>

<script setup lang="ts">
import { computed, getCurrentInstance, onBeforeMount, reactive, ref, inject, toRefs } from "vue";
import { queryAllContactRecord, queryAllContactCount, queryContactExtNumRecord, queryCallReasonInfo, initContactItemConfig } from "@/views/ccmanagement/ccma-api/index.js";
import cookieUtils from "@/utils/cookie.js";
import AiccElMessage from '@/utils/el-message';
import timeUtils from "@/utils/timeUtils";
import {
  channelTypeMap,
  mediaAbilityMap,
  staffHangupMap,
  callTypeMap,
  getContactFileRecordsColumnWidth, getContactCallSerialnoColumnWidth, getContactDateTimeColumnWidth
} from "@/views/ccmanagement/common/constants";
import AgentNameShowPop from "@/views/ccmanagement/pages/agent-name-show-pop/agent-name-show-pop.vue";
import { safeHtmlEncode } from "@/views/ccmanagement/common/common";
import AiccAudio from "@/components/aicc-audio/index.vue";
import DownloadFile from "@/views/ccmanagement/pages/download-file/download-file.vue";
import ContactFilePop from "@/views/ccmanagement/pages/contact-file-pop/contact-file-pop.vue";
import ContactVideoPop from "@/views/ccmanagement/pages/contact-video-pop/contact-video-pop.vue";

const VDN_NOT_ENABLE: string = "9"; // vdn未启用错误码
const provideData = inject<any>('resp');
const props = defineProps({
    callSerialno: {
        type: [String, Number],
        required: true,
    }
});
const {callSerialno} = toRefs(props);
const tableColumns = ref<Array<any>>(); // 表头信息
const tableData = ref<Array<any>>([]); // 表数据
const loading = ref<Boolean>(false); // 是否展示加载模块
const play = ref<Boolean>(false); // 播放是否可用
const down = ref<Boolean>(false); // 下载是否可用
const auth = ref<Boolean>(false); // 是否是租间管理员
const isQC = ref<Boolean>(false); // 是否质检员
const workNo = ref<String>(); // 工号
const contactItem = ref<Array<string>>();
const maxCountQuery = ref<number>(); // 最大允许的查询数量,默认预置为1w，后续查询时会更新
const isShowTips = ref<Boolean>(false); // 是否展示footer的tips

const aiccAudio = ref();
const isShowPlayer = ref<boolean>(false); // 是否展示播放器
const playerSrc = ref<string>(""); // 播放资源地址
const playingId = ref<string>(); // 播放id
const downloadFileDialog = ref(); // 录制内容下载弹窗
const downloadFileDialogTitle = ref<any>(); // 录制内容下载弹窗的标题
const downloadFileDialogDataType = ref<any>(); // 录制内容下载弹窗的 dataType参数
const downloadFileDialogDataInfo = ref<Object>(); // 录制内容下载弹窗的 dataInfo参数
const videoPlayRecordId = ref<String>(); // 视频的记录id
const videoPlayType = ref<String>("video/mp4"); // 视频的播放类型
const videoPlayTitle = ref<String>(''); // 视频弹窗标题
const contactVideoPopRef = ref();
const isShowContactVideoPop = ref<Boolean>(false);
const isShowContactFilePop = ref<Boolean>(false);
const contactFilePopRecordIds = ref<String>("");
const contactFilePopVideoDisable = ref<Boolean>(false);
const searchModel = reactive<SearchModel>({
    contactId: "",
    beginTime: "",
    locale: cookieUtils.getCookie("u-locale"),
    limit: 10,
    offset: 0
});

const paginationConfig = reactive({
    pageSizes: [10, 20, 50, 100],
    pageSize: 10,
    total: 0,
    layout: "total, prev, pager, next",
    currentPage: 1
});

const offset = computed(() => (paginationConfig.currentPage - 1) * paginationConfig.pageSize); // 偏移量
const requiredZipPwd = ref(true) // 下载录音文件时，是否需要压缩密码

/**
 * UX改版后的查询定义
 */
declare interface SearchModel {
    contactId: string,
    locale: string, // 国际化
    beginTime?: number | string | Date, //查询起始时间，非工作台跳转时必填
    offset: number | string, // 偏移量
    limit: number | string, // 每页条数
}

// 国际化封装
const { appContext: { config: { globalProperties } } } = getCurrentInstance() as any;
const i18n = (param: string, ...args: Array<any>): string => {
    return globalProperties.$t(param, args);
};


const getCellStyleClass = ({ column }: any) => {
    if (column.property == 'recordingfiles') {
        return '';
    }
    return 'clickable-cell-style'
}

onBeforeMount(() => {
    initPage();
});

const initPage = () => {
    searchModel.contactId = provideData.value.contactId;
    searchModel.beginTime = provideData.value.beginTime;
    paginationConfig.pageSize = 10;
    paginationConfig.currentPage = 1;
    searchModel.offset = 0;
    searchModel.limit = 10;

    queryAllContactRecord(searchModel).then((resp: any) => {
        if (resp["returnCode"] == VDN_NOT_ENABLE) {
            AiccElMessage({
                showClose: true,
                message: i18n("ccm.agent.operation.vdnNotEnabled"),
                type: "success"
            });
            return;
        }
        contactItem.value = resp["contactItem"];
        if (!resp["column"]) {
            initContactBasicDataConfig();
            return;
        }
        combineTableColumn(resp["column"], resp["contactItem"]);
        if (resp["maxCountQuery"]) {
            maxCountQuery.value = resp["maxCountQuery"];
        }
        auth.value = resp["tenantManager"];
        play.value = resp.playAuth;
        down.value = resp.downAuth;
        // 无表单数据，直接返回
        if (!resp["contactRecordList"]) {
            return;
        }
        isQC.value = resp["isQC"];
        workNo.value = resp["workNo"];
        fillContactRecordData(resp);
        getMediaAbilitype();
        initAgentNumbers(searchModel);
        queryAllCallReason();
    });
    loading.value = false;
}

/**
 * 查询所有的呼叫原因
 */
const queryAllCallReason = () => {
    if (!contactItem.value || contactItem.value.indexOf("talkReason") === -1) {
        return;
    }
    queryCallReasonInfo().then((resp: any) => {
        if (!resp) {
            return;
        }
        for (let index = 0; index < tableData.value.length; ++index) {
            if (tableData.value[index].talkReason) {
                let reason: string = "";
                const list: string[] = tableData.value[index].talkReason.split(",");
                for (let i = 0, len = list.length; i < len; ++i) {
                    const reasonData = (resp.find((item: any): boolean => item.id == list[i]));
                    if (reasonData) {
                        reason = (i == 0 || reason == "") ? reasonData.name : reason + "," + reasonData.name;
                    }
                }
                tableData.value[index].talkReason = reason;
            }
        }
    });
};

const initAgentNumbers = (param: any): void => {
    // 非租间管理员，只会查询自己相关的接触记录总数
    if (!auth.value) {
        queryContactExtNumRecord(param).then((resp: number) => {
            paginationConfig.total = resp;
        });
        return;
    }
    queryAllContactCount(param).then((count: number) => {
        if (count >= maxCountQuery.value!) {
            isShowTips.value = true;
        }
        paginationConfig.total = Math.min(count, maxCountQuery.value!);
    });
};

const combineTableColumn = (columns: Array<string>, contactItems: Array<string>): void => {
    // 针对无数据时，contactItems返回为空进行处理
    const len = Math.min(columns.length, contactItems.length);
    const tableHeaders: Array<any> = [
        {
            label: i18n("ccm.agent.contact.callserno"),
            prop: "callSerialno",
            slot: "callSerialno",
            "show-overflow-tooltip": true,
            "min-width": getContactCallSerialnoColumnWidth(),  // 需要完整展示呼叫流水号内容
            fixed: 'left',
        }
    ];
    let hasRecordFiles = false;
    let midColumns = [];
    const recordfilesWidth: String = getContactFileRecordsColumnWidth();
    for (let i = 0; i < len; i++) {
        const column: any = {
            label: columns[i],
            prop: contactItems[i],
            "show-overflow-tooltip": true
        };
        if (slotSet.has(contactItems[i])) {
            column.slot = contactItems[i];
        }
        // 开始时间和结束时间，需要完整展示其内容
        if (["beginTime", "endTime"].includes(contactItems[i])) {
            column["min-width"] = getContactDateTimeColumnWidth();
        }
        if (contactItems[i] == "recordingfiles") {
            hasRecordFiles = true;
            column.fixed = "right";
            column.slot = "recordingfiles";
            column['width'] = recordfilesWidth;
            column['show-overflow-tooltip'] = false;
        }
      midColumns.push(column);
    }
  if (len > 0) {
    if (window.isRTL) {
      midColumns.reverse();
    }
    tableHeaders.push(...midColumns);
  }
    // 不存在录音文件，且不是从工作台进入，需要在前端固定添加该列
    if (!hasRecordFiles) {
        const column: any = {
            label: i18n('ccm.agent.contact.recordingfiles'),
            prop: 'recordingfiles',
            fixed: 'right',
            slot: "recordingfiles",
            "min-width": recordfilesWidth
        }
        tableHeaders.push(column);
    }
    tableColumns.value = tableHeaders;
};

const slotSet: ReadonlySet<String> = new Set<String>([
    "callSerialno", "channelType", "mediaAbility", "staffHangup", "calltype", "recordingfiles",
    "acceptNo", "called", "caller", "callNo"
]);

/**
 * 填充接触记录数据
 */
const fillContactRecordData = (resp: any) => {
    tableData.value = resp["contactRecordList"];
    if (!tableData.value) {
        return;
    }
    for (let i: number = 0; i < resp["contactRecordList"].length; i++) {
        const index: any = tableData.value[i];
        const mediaTypeId: number | undefined = index["mediaTypeId"];
        const mediaAbility: number | undefined = index["mediaAbility"];
        const isInnerCall: boolean = innerCallCheck(mediaTypeId, mediaAbility);
        // 时间转化，以及内部呼叫特殊标识
        for (const proto in index) {
            if (proto == "beginTime") {
                index[proto] = timeUtils.transTime(index[proto]);
                continue;
            }
            if (proto == "endTime") {
                // 数据库时间改为utc时间戳
                index[proto] = timeUtils.transTime(index[proto]);
                continue;
            }
            if (proto == "acceptNo" || proto == "called" || proto == "caller" || proto == "callNo") {
                const key: string = proto + "IsWorkNo";
                tableData.value[i][key] = isInnerCall && isWorkNo(index[proto]);
            }
            if (proto == "skillId" && index[proto] == -1) {
              tableData.value[i][proto] = null;
            }
        }
        // 视频文件格式支持宇高在线播放，其他隐藏播放按钮
        if (index.RecordInfo && index.RecordInfo.length == 1 && index.RecordInfo[0].mediaAbility == 2) {
            index.isPlayVideoTag = !index.RecordInfo[0].recordfileaddr || index.RecordInfo[0].recordfileaddr.indexOf(":/") === -1;
        }
        // 视频播放下载需要判断当前座席工号是否是质检员，如果不是，判断当前工号是否和接触记录里的相同。
        if (recordPlayAndDownloadCheck(index)) {
            index.recordInfoDisable = true;
        }
    }
};

/**
 * 视频播放下载需要判断当前座席工号是否是质检员，如果不是，判断当前工号是否和接触记录里的相同。
 *
 * @param colValue 表格中当前行数据
 */
const recordPlayAndDownloadCheck = (colValue: any): boolean => {
    if (!colValue.RecordInfo || colValue.RecordInfo.length != 1 || colValue.RecordInfo[0].mediaAbility != 2) {
        return false;
    }
    return !isQC.value && workNo.value != colValue.workNo;
};

/**
 * 检查是否为内部呼叫
 *
 * @param mediaTypeId 媒体类型
 * @param mediaAbility 媒体渠道
 * @returns { boolean }
 */
const innerCallCheck = (mediaTypeId: number | undefined, mediaAbility: number | undefined): boolean => {
    // 6-内部呼叫 51-内部两方求助 52-内部三方求助 以及 5-多媒体 的工号才需要转
    if (mediaTypeId === 6 || mediaTypeId === 51 || mediaTypeId === 52) {
        return true;
    }
    return mediaAbility === 5;
};

/**
 * 判断是否是工号
 * @param callNum 呼叫号码
 */
const isWorkNo = (callNum: string | number | undefined | null): boolean => {
    if (!callNum) {
        return false;
    }
    const callNumStr: string = String(callNum);
    const reg: RegExp = /^\d{3,5}$/;
    if (!reg.test(callNumStr)) {
        return false;
    }
    const callNumber: number = Number(callNumStr);
    return callNumber > 100 && callNumber <= 50000;
};

/**
 * 获取媒体类别信息
 */
const getMediaAbilitype = () => {
    if (!tableData.value) {
        return;
    }
    for (const index of tableData.value) {
        index["channelTypeStr"] = loadChannelType(index["channelType"]) || "";
        index["mediaAbilityStr"] = mediaAbilityMap.get(String(index["mediaAbility"])) || "";
        index["staffHangupStr"] = staffHangupMap.get(String(index["staffHangup"])) || "";
        index["calltypeStr"] = callTypeMap.get(String(index["calltype"])) || "";
    }
};

const newSocialMedias = ref<Array<any>>();
const loadChannelType = (key: any) => {
    const arr = newSocialMedias.value?.filter((e : any) => e.socialId == key);
    if (arr && arr.length > 0) {
        return arr[0].socialCode;
    }
    return channelTypeMap.get(String(key));
}

const handleMediaPlay = (row: any) => {
    // 播放/暂停操作
    if (row.audioPlaying === undefined) {
        if (row.associateCall) {
            playAudioRecord(row, () => {
                isShowPlayer.value = true;
                row.audioPlaying = true;
                playerSrc.value = `${location.protocol}//${location.host}/service-cloud/u-route/ccmanagement/recordplay/recordext?associateCall=${row.associateCall}`;
            });
            return;
        }
        const recordIds: Array<String> = row.recordId.split(",");
        if (recordIds.length > 1) {
            contactFilePopVideoDisable.value = !isQC.value && row.workNo != workNo.value;
            contactFilePopRecordIds.value = row.recordId;
            isShowContactFilePop.value = true;
        } else {
            playFile(row.RecordInfo[0], row);
        }
        return;
    }
    // 以下操作均只涉及到音频，故可以直接根据row.audioPlaying的状态判断
    // 正在播放中，切换至暂停
    if (row.audioPlaying) {
        row.audioPlaying = false;
        aiccAudio.value.playPauseAudio("");
    } else {
        row.audioPlaying = true;
        aiccAudio.value.playPauseAudio("");
    }
};

/**
 * 播放音频文件
 * @param row 表格列所在数据
 * @param callback 播放音频的回调
 */
function playAudioRecord(row: any, callback: () => void) {
    // 针对非本次的播放/暂停，需要将原状态重置
    if (playingId.value && row.callSerialno != playingId.value) {
        playingId.value = row.callSerialno;
        resetAudioPlayStatus();
    }
    playingId.value = row.callSerialno;
    callback();
}

const playFile = (recordInfo: any, row: any) => {
    const mediaAbility = recordInfo.mediaAbility || 1;
    // 语音文件
    if (mediaAbility == 1) {
        playAudioRecord(row, () => {
            isShowPlayer.value = true;
            playerSrc.value = `${location.protocol}//${location.host}/service-cloud/u-route/ccmanagement/recordplay/voice?recordId=${recordInfo.recordId}`;
            row.audioPlaying = true;
        });
        return;
    }
    //视频文件,打开弹窗播放
    if (mediaAbility == 2) {
        playVideo(recordInfo.recordId);
    }
};

/**
 * 重置音频播放状态
 * @param resetAll 是否重置所有，默认为true
 */
const resetAudioPlayStatus = (resetAll: boolean = true): void => {
    if (!tableData.value) {
        return;
    }
    for (const data of tableData.value) {
            delete data.audioPlaying;
            if (!resetAll) {
                return;
            }
    }
};

const playVideo = (recordId: String) => {
    if (window.top?.agentStatus.isLogOut()) {
        AiccElMessage.error(i18n("ccm.agent.leavemessage.notLoginIn"));
        return;
    }
    videoPlayRecordId.value = recordId;
    videoPlayTitle.value = i18n("ccm.agent.contact.vidio");
    isShowContactVideoPop.value = true;
};

/**
 * 下载录制文件
 * @param row 对应数据
 */
const downloadRecord = (row: any) => {
    if (row.associateCall) {
        popDownloadFileDialog(row, "mobileRecord");
        return;
    }
    const recordIds: Array<String> = row.recordId.split(",");
    if (recordIds.length > 1) {
        contactFilePopVideoDisable.value = !isQC.value && row.workNo != workNo.value;
        contactFilePopRecordIds.value = row.recordId;
        isShowContactFilePop.value = true;
        return;
    }
    popDownloadFileDialog(row, "contactRecord");
};

/**
 * 弹出文件下载框
 * @param row 对应列的信息
 * @param dataType 下载文件类型
 */
const popDownloadFileDialog = (row: any, dataType: String) => {
    const msg = row.mediaAbility == 1 ? "ccm.agent.contact.downloadrecord" : "ccm.agent.contact.downloadvideo";
    downloadFileDialog.value.handleDialog(true);
    downloadFileDialogTitle.value = i18n(msg);
    downloadFileDialogDataType.value = dataType;
    downloadFileDialogDataInfo.value = JSON.parse(JSON.stringify(row));
};

const handlePagination = (params: any) => {
    searchModel.limit = params.limit;
    searchModel.offset = params.offset;
    paginationConfig.currentPage = params.currentPage;
    paginationConfig.pageSize = params.pageSize;
    search();
}

const search = () => {
    loading.value = true;
    initAgentNumbers(searchModel);
    queryAllContactRecord(searchModel).then((resp: any) => {
        if (resp["returnCode"] == VDN_NOT_ENABLE) {
            AiccElMessage({
                showClose: true,
                message: i18n("ccm.agent.operation.vdnNotEnabled"),
                type: "success"
            });
            return;
        }
        contactItem.value = resp["contactItem"];
        if (!resp["column"]) {
            initContactBasicDataConfig();
            return;
        }
        auth.value = resp["tenantManager"];
        // 组装列数据
        combineTableColumn(resp["column"], resp["contactItem"]);
        fillContactRecordData(resp);
        getMediaAbilitype();
        queryAllCallReason();
    }).finally(() => loading.value = false);
};

const initContactBasicDataConfig = () => {
    initContactItemConfig().then((resp: any) => {
        if (resp && resp["returnCode"] == 0) {
            // 查询接触记录数据配置项
            initPage();
        }
    });
};

const downloadFileResult = (result: boolean | undefined) => {
    downloadFileDialog.value.handleDialog();
};

const closeAudioHandle = () => {
    resetAudioPlayStatus();
    isShowPlayer.value = false;
};

const audioPlayingHandle = (status: boolean) => {
    if (!tableData.value) {
        return;
    }
    for (const data of tableData.value) {
        if (data.audioPlaying !== undefined) {
            data.audioPlaying = status;
        }
    }
};

const rowClick = (row: any, column: any, event: any) => {
    if (event.target.className == 'el-link__inner') {
        return;
    }
    if (!column || column.property == 'recordingfiles') {
        return;
    }
    jumpToDetail(row);
}

const jumpToDetail = (row: any) => {
    if (callSerialno.value == row.callSerialno) {
        return;
    }
    const route = {
        title: i18n('ccm.agent.contact.contactdetail'),
        name: 'ccmaContactDetailNewTab',
        id: 'ccmaContactDetailNewTab',
        query: {
            callSerialno: row.callSerialno,
            beginTime: timeUtils.getUTCTimestamp(row.beginTime),
        },
    };
    top?.window.showTab(route);
}

</script>

<style lang="less" scoped>
:deep(.el-button+.el-button){
    margin-left: 12px!important;
}
.contact-record-query-layout {
    padding: 1rem;
    background: #fff;
}

.contact-record-query-layout :deep(.clickable-cell-style) {
    cursor: pointer;
}

.table-header-cell {
    width: 141px;
    height: 28px;
    background: rgba(249, 249, 249, 1);
}

.agent-name-show-pop {
    width: 280px;
    padding: 0;

    .agent-name-show-pop-card {
        width: inherit;
        height: 150px;
        padding: 0;
    }
}

.footer--tips {
    align-content: center;
    color: #6E6E6E;
    line-height: 20px;
    font-weight: 400;
}

.el-form-item {
    margin-bottom: 0;
}

.button-butween {
    display: flex;
    justify-content: space-between
}

.audio-right {
    display: flex;
    justify-content: flex-end;
    padding-top: 1rem;
}

.audio-width {
    width: 380px
}

@media screen and (max-width: 1919px){
  // 页面链接字体大小，需要适配分辨率
  :deep(.el-link) {
    font-size: var(--12px-to-rem);
  }
}
</style>
