import i18n from '@/lang'

const $t = i18n.global.t

// 特殊字符校验
export function specialStrValidateMgs(rule, value, callback) {
  let regEn = /^=|["\/<>\\]|exec|eval/gi
  let regCn = /[]/gi
  if (!(regEn.test(value) || regCn.test(value))) {
    callback()
  } else {
    callback(new Error($t('ccnotification.notification.special.charactor.check')))
  }
}

// 纯数字校验
export const validateNumber = (rule, value, callback) => {
  let numberReg = /^\d+$|^\d+[.]?\d+$/
  if (value !== '') {
    if (!numberReg.test(value)) {
      callback(new Error($t('cms.report.field.number')))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

export const validatePhone = (rule, value, callback) => {
  let reg = /^\d{1,15}$/
  if (value !== '') {
    if (!reg.test(value)) {
      callback(new Error($t('ccnotification.notifition.template.message.error')))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

export const validateCharDigit = (rule, value, callback) => {
  let reg = /^([a-z]|[A-Z]|[0-9]){1,20}$/
  if (value !== '') {
    if (!reg.test(value)) {
      callback(new Error($t('ccnotification.notifition.template.message.error')))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

export const validateDateTime = (rule, value, callback) => {
  let regA = /^\d{4}\-\d{2}\-\d{2}$/
  let regB = /^\d{2}\:\d{2}\:\d{2}$/
  if (value !== '') {
    if (!regA.test(value) && !regB.test(value)) {
      callback(new Error($t('ccnotification.notifition.template.message.error')))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

export const validateMoney = (rule, value, callback) => {
  let reg = /^(\.|[0-9]){1,20}$/
  if (value !== '') {
    if (!reg.test(value)) {
      callback(new Error($t('ccnotification.notifition.template.message.error')))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

export const validateText = (rule, value, callback) => {
  let reg = /[`~@^#%&*/=+{};'"<.>?\[\]]/
  if (value !== '') {
    if (reg.test(value)) {
      callback(new Error($t('ccnotification.notifition.template.message.error')))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

// 校验通知变量的名称
export const validateVariableName = (rule, value, callback) => {
  let val = value.trim()
  if (!val) {
    callback(new Error($t('cms.systemparam.error.validate.notmatchrulerequired')))
  }
  if (val.length > 100) {
    callback(new Error($t('aicc.MaxLength').replace('0', '100')))
  }
  let myReg = /^[a-zA-Z0-9_.]+$/
  if (!myReg.test(value)) {
    callback(new Error($t('ccnotification.notifition.variable.namerule')))
  }
  callback()
}

// 校验通知变量的描述
export const validateVariableDes = (rule, value, callback) => {
  if (!value) {
    callback()
  }
  let val = value.trim()
  if (val.length > 200) {
    callback(new Error($t('aicc.MaxLength').replace('0', '200')))
  }
  let regEn = /^=|["\/<>\\]|exec|eval/gi
  let regCn = /[]/gi
  if (regEn.test(value) || regCn.test(value)) {
    callback(new Error($t('ccnotification.notification.special.charactor.check')))
  }
  callback()
}

// 校验通知变量的值
export const validateVariableValue = (rule, value, callback) => {
  if (!value) {
    callback()
  }
  let val = value.trim()
  if (val.length > 500) {
    callback(new Error($t('aicc.MaxLength').replace('0', '500')))
  }
  let regEn = /^=|["\/<>\\]|exec|eval/gi
  let regCn = /[]/gi
  if (regEn.test(value) || regCn.test(value)) {
    callback(new Error($t('ccnotification.notification.special.charactor.check')))
  }
  callback()
}

// 校验整数
export const checkPositiveInteger = (rule, value, callback) => {
  let reg = /^\d+$/
  if (value === '' || reg.test(value)) {
    //校验通过
    callback()
  } else {
    callback(new Error($t('cms.input.format.invalid')))
  }
}

// 校验短信配置短信号码的长度
export const checkSmsConfigPhoneLen = (rule, value, callback) => {
  if (value.length > 0 && value.length < 20) {
    //校验通过
    callback()
  } else {
    callback(
      new Error(
        $t('ccnotification.notification.notificationRoute.receiveAddrLen').replaceAll('1024', '20')
      )
    )
  }
}

// 特殊字符校验
export const specialCharacters = (rule, value, callback) => {
  let reg = new RegExp('^[0-9a-zA-Z]*$')
  if (!reg.test(value)) {
    //校验不通过
    callback(new Error($t('ccnotification.smshuawei.message.specialCharacters')))
  } else {
    callback()
  }
}

// https校验
export const httpvalidate = (rule, value, callback) => {
  // 定义以"https://"开头的正则表达式
  let httpsRegex = /^https:\/\//
  if (!httpsRegex.test(value)) {
    //校验不通过
    callback(new Error($t('ccnotification.urlconfig.pageurls.urlvalidate')))
  } else {
    callback()
  }
}
