import {defineStore} from 'pinia'

// 接续条打开音视频工作台页面store
export const useAgentConsoleTabStore = defineStore('agentConsoleTabStore', {
  state: () => {
    return {
      pageParams: {},
      dataSrc: ''
    };
  },
  getters: {},
  actions: {
    setPageParams(params) {
      this.pageParams = Object.assign({}, params);
    },
    setDataSrc(dataSrc) {
      this.dataSrc = dataSrc;
    }
  }
})

// 接续条的事件store
export const useAgentEventStore = defineStore('agentEventStore', {
  state: () => {
    return {
      eventName: '',
      eventData: {}
    };
  },
  getters: {},
  actions: {
    setEventData(name, data) {
      this.eventName = name;
      this.eventData = data;
    },
    getEventData() {
      return {
        eventName: this.eventName,
        eventData: this.eventData
      };
    }
  }
})

// 发送给多媒体工作台事件
export const useChatEventStore = defineStore('chatEvent', {
  state: () => {
    return {
      eventsKey: '',
      chatEventName: '', // 发送事件名称
      chatEventParams: '', // 发送事件参数
    }
  },
  getters: {},
  actions: {
    // 发送事件名称
    changeSendName(eventName = '', eventParams = {}, eventsKey = 'chatEventName') {
      this.eventsKey = eventsKey
      this.chatEventName = eventName
      this.chatEventParams = eventParams
    }
  }
})

// 网页协同
export const useCobrowseEventStore = defineStore('cobrowseEvent', {
  state: () => {
    return {
      eventsKey: '',
      connectionInfoDate: {},
      cobrowseKey: '',
      cobrowseResponse: {}
    }
  },
  actions: {
    // 坐席侧网页协同tab页签加载完毕
    cobrowseLoadWebCollaboration() {
      this.eventsKey = 'loadWebCollaboration'
    },

    setLoadWebCollaboration() {
      this.eventsKey = ''
    },

    coBrowseConnectionInfo(data = {}, eventsKey = 'CoBrowseConnectionInfo') {
      this.eventsKey = eventsKey
      this.connectionInfoDate = data
    },

    setConnectionInfoDate() {
      this.connectionInfoDate = {}
      this.eventsKey = ''
    },

    changeCobrowseInfo(key, response) {
      this.cobrowseKey = key
      this.cobrowseResponse = response
    }
  }
})

// 暂存工作台前发送的事件
export const useWorkbenchBeforLoad = defineStore('workbenchBeforLoad', {
  state: () => {
    return {
      // 加载完成标识
      loadCompleted: false,
      // 事件内容
      eventContent: []
    }
  },
  actions: {
    // 添加事件内容
    pushEventContent(content) {
      this.eventContent.push(content)
    },
    // 清空事件内容
    clearEventContent() {
      this.eventContent = []
    },
    // 修改加载完成标识
    changeLoadCompleted(tag) {
      this.loadCompleted = tag
    }
  }
})

// 接续前端日志緩存
export const useContactLoggerStore = defineStore('contactLoggerStore', {
  state: () => {
    return {
      // 跟踪截止日期
      trackDeadline: '',
      // 日志级别
      logLevel: '',
      // 最大日志缓存记录数
      maxCachedLogsNum: 0,
      // 前台日志缓存
      logCache: []
    }
  },
  getters: {},
  actions: {
    // 最大日志缓存记录数
    setMaxCachedLogsNum(maxCachedLogsNum) {
      this.maxCachedLogsNum = maxCachedLogsNum;
    },
    // 跟踪截止日期
    setTrackDeadline(deadLine) {
      this.trackDeadline = deadLine;
    },
    // 日志级别
    setLogLevel(logLevel) {
      this.logLevel = logLevel;
    },
    // 写入前台日志缓存，弹出写入后台日志
    pushLogCache(logContent, operateType) {
      this.logCache.push(logContent)
      if (this.logCache.length >= this.maxCachedLogsNum) {
        return this.logCache.splice(0, this.maxCachedLogsNum);
      }
      return [];
    },
    clear() {
      this.maxCachedLogsNum = 0;
      this.logLevel = '';
      this.trackDeadline = '';
      this.logCache = [];
    },
    getLogCache() {
      return this.logCache;
    }
  }
})