// 资源管理
import Layout from '@oifde/components/OifdeLayout/OifdeLayout.vue'

export default [{
  path: '/aiccOifde/voiceTemplate',
  component: Layout,
  redirect: '/voiceTemplate/index',
  alwaysShow: true,
  meta: {
    title: 'oifde.route.resources'
  },
  children: [
    {
      path: '/aiccOifde/audioVideoResource', // 音视频资源
      name: 'oifdeAudioVideoResource',
      meta: {
        title: 'oifde.route.audioVideoResource',
        noCache: true,
        menuId: 'oifde-resource-audio-video'
      },
      component: () => import('@/views/oifde/views/audioVideoResource/audioVideoResource.vue')
    },
    {
      path: '/aiccOifde/voiceTemplate/index', // 资源模板
      name: 'oifdeVoiceTemplate',
      meta: {
        title: 'oifde.route.voiceTemplateTitle',
        noCache: true,
        menuId: 'oifde-resource-templates'
      },
      component: () => import('@/views/oifde/views/voicetemplate/voicetemplate.vue')
    },
    {
      path: '/aiccOifde/voiceTemplate/serviceInterface', // 业务接口
      name: 'oifdeServiceInterface',
      meta: {
        title: 'oifde.route.serviceInterface',
        noCache: false,
        menuId: 'oifde-business-interfaces'
      },
      component: () => import('@/views/oifde/views/serviceInterface/interfaceMgr.vue')
    },
    {
      path: '/aiccOifde/voiceTemplate/variable', // 变量管理
      name: 'oifdeVariableIndex',
      meta: {
        title: 'oifde.route.variable',
        noCache: false,
        menuId: 'oifde-variable-management'
      },
      component: () => import('@/views/oifde/views/variable/index.vue')
    },
    {
      path: '/aiccOifde/cell/index', // 复合图元
      name: 'oifdeCellManage',
      meta: {
        title: 'oifde.route.cell',
        noCache: false,
        menuId: 'oifde-cell-management'
      },
      component: () => import('@/views/oifde/views/cells/index.vue')
    },
    {
      path: '/aiccOifde/voiceTemplate/procedure', // 存储过程
      name: 'oifdeProcedureInterface',
      meta: {
        title: 'oifde.route.procedure',
        noCache: false,
        menuId: 'oifde-stored-procedure'
      },
      component: () => import('@/views/oifde/views/storedProcedureInterface/storedProcedureInterface.vue')
    },
    {
      path: '/aiccOifde/voiceTemplate/workDay', // 工作日历
      name: 'workDay',
      meta: {
        title: 'oifde.route.workDay',
        noCache: false,
        menuId: 'oifde-work-day'
      },
      component: () => import('@/views/oifde/views/workDay/workDay.vue')
    },
    {
      path: '/aiccOifde/voiceTemplate/dataTable', // 数据表
      name: 'dataTable',
      meta: {
        title: 'oifde.route.dataTable',
        noCache: false,
        menuId: 'oifde-data-table'
      },
      component: () => import('@/views/oifde/views/dataTable/dataTable.vue')
    },
    {
      path: '/aiccOifde/voiceTemplate/2dDigitalHumanImg', // 2D数字人形象
      name: '2dDigitalHumanImg',
      meta: {
        title: 'oifde.route.2dDigitalHumanImg',
        noCache: false,
        menuId: 'oifde-2d-digital-human-img'
      },
      component: () => import('@/views/oifde/views/digitalHumanImg/digitalHumanImg.vue')
    }
  ]
}]