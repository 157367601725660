export default {
  "ikbs.constableTmp.config" : "配置",
  "ikbs.constableTmp.status.draft" : "草稿",
  "ikbs.cntTmp.import.rowNumOver" : "最多只能导入10条数据",
  "ikbs.column.unlock.message.warning" : "提示",
  "ikbs.knowledge.label.abstract" : "摘要",
  "ikbs.properties.add.information" : "新增扩展属性",
  "ikbs.contTmp.exception.maxCountPerTenant" : "单个租户的模板数量最多500个",
  "ikbs.knowledge.properties.operation.upMove" : "上移",
  "ikbs.column.delete.selectNoColumnKngToDelete" : "没有选中栏目知识",
  "ikbs.tips.canNotAddColumnKngOnHistoryColumn" : "不能在历史栏目下新增知识",
  "ikbs.knowledge.properties.label.inputWay" : "输入方式",
  "ikbs.contentTmp.exception.tmpContentAndEditStandard" : "模板内容和编辑规范不能同时为空",
  "ikbs.knowledge.label.uploadSingleFile" : "一次只能上传一个附件",
  "ikbs.knowledge.tip.operateSuccess" : "操作成功",
  "ikbs.constableTmp.operator.lt" : "小于",
  "ikbs.enter.search.condition" : "请输入搜索条件",
  "ikbs.search.label.sortByDefault" : "默认排序",
  "ikbs.knowledge.recycle.coluKngType" : "类型",
  "ikbs.consult.table" : "咨询表",
  "ikbs.knowledge.label.path" : "知识路径",
  "ikbs.column.release.message.fail" : "发布失败",
  "ikbs.column.knowledge.columnInformation" : "新增栏目",
  "ikbs.constableTmp.operator.le" : "小于等于",
  "ikbs.knowledge.service.common" : "普通",
  "ikbs.knowledge.recycle.select.knowledgeType" : "知识类型",
  "ikbs.tips.propertyDictValueAtLeastOne.warning" : "扩展属性至少要有一个字典值",
  "ikbs.column.displayflag.hide" : "否",
  "ikbs.column.knowledge.button.add" : "新增",
  "ikbs.column.knowledge.modify.properties" : "批量修改栏目知识属性",
  "ikbs.knowledge.release.public" : "直接发布",
  "ikbs.knowledge.label.selectProperty" : "选择属性",
  "ikbs.contentTmp.status.draft" : "草稿",
  "ikbs.knowledge.label.serviceStatus" : "业务状态",
  "ikbs.knowledge.service.update" : "更新业务",
  "ikbs.column.serviceStatus.newService" : "新业务",
  "ikbs.knowledge.label.answer" : "答案",
  "ikbs.constableTmp.reset" : "重置",
  "ikbs.knowledge.label.modifyTime" : "最后更新时间",
  "ikbs.column.type.historyMark" : "历史",
  "ikbs.kngCnt.cntTmp.icon.tip" : "内容模板",
  "ikbs.knowledge.label.currentVersion" : "当前版本",
  "ikbs.columntype.column" : "栏目",
  "ikbs.enter.search.keywords" : "请输入搜索关键字",
  "ikbs.contentTmp.delete.fail" : "删除失败",
  "ikbs.column.label.coluKngMaintaining" : "维护群组",
  "ikbs.knowledge.label.kngAttachmentDownload" : "栏目知识附件下载",
  "ikbs.search.label.updateEndTime" : "更新结束时间",
  "ikbs.knowledgeaudit.label.pass" : "通过",
  "ikbs.knowledge.tip.selectColumn" : "请选择栏目",
  "ikbs.constableTmp.searchFlag" : "是否搜索条件",
  "ikbs.properties.add.cancel" : "取消",
  "ikbs.column.needAudit.yes" : "是",
  "ikbs.knowledge.properties.operation.enable" : "启用",
  "ikbs.column.type.normal" : "正常栏目",
  "ikbs.knowledge.label.passProcecWay" : "过期处理方式",
  "ikbs.column.knowledge.MoveType" : "调整至类型",
  "ikbs.confirm.delete.by.select" : "确认删除选中的记录",
  "ikbs.knowledge.label.relaKngs" : "关联知识",
  "ikbs.constableTmp.fieldName" : "字段名称",
  "ikbs.column.copy.message.warning" : "提示",
  "ikbs.column.deletePermanently.message.comfirm" : "确认",
  "ikbs.column.moveto.front" : "调整至......前",
  "ikbs.kbshome.button.orderByTime" : "时间",
  "ikbs.constableTmp.moveDown" : "下移",
  "ikbs.tips.canNotAddKngOnRootColumn" : "不能在根目录下新增知识",
  "ikbs.column.knowledge.button.up" : "上移",
  "ikbs.constableTmp.modify" : "修改",
  "ikbs.constableTmp.operator.gt" : "大于",
  "ikbs.constableTmp.operator.include" : "包含",
  "ikbs.knghisverman.label.revert" : "恢复",
  "ikbs.constableTmp.plsSelectModifyBeginTime" : "请选择修改起始时间",
  "ikbs.knowledgeaudit.label.submitter" : "提交人",
  "ikbs.column.label.kngExtended" : "扩展属性",
  "ikbs.constableTmp.operator.ge" : "大于等于",
  "ikbs.knowledge.label.listProp" : "下拉列表",
  "ikbs.knowledgeaudit.title.auditor" : "审核人",
  "ikbs.knowledge.recycle.pleaseSelect" : "请选择",
  "ikbs.constableTmp.tab.addTitle" : "新增模板",
  "ikbs.knghisverman.tab.title.confirm" : "确认",
  "ikbs.column.knowledge.button.move" : "移动",
  "ikbs.favorite.msg.plsSelectDir" : "请选择要删除的目录",
  "ikbs.knowledge.tip.fileNameError" : "文件名校验失败",
  "ikbs.knowledge.message.uploadImgSizeError" : "图片大小不能超过10M",
  "ikbs.knowledge.display.no" : "否",
  "ikbs.properties.inputway.select" : "下拉列表",
  "ikbs.favorite.title.afterModifiedKngName" : "修改后名称",
  "ikbs.contentTmp.status" : "模板状态",
  "ikbs.column.topMove.message.warning" : "提示",
  "ikbs.constableManage.fieldOprator" : "运算符",
  "ikbs.constableTmp.modifyEndTime" : "修改结束时间",
  "ikbs.cntTmp.import.noData" : "上传文件中没有需要导入的数据",
  "ikbs.addtype.label.knowledge" : "新增知识",
  "ikbs.contentTemp.export.tempname" : "模板名称",
  "ikbs.constableTmp.operator.eq" : "等于",
  "ikbs.knowledge.tip.success" : "成功",
  "ikbs.knowledge.label.radioProp" : "单选框",
  "ikbs.intelligent.search" : "智能搜索",
  "ikbs.contentTmp.contentName" : "模板名称",
  "ikbs.kbshome.tab.home" : "知识库主页",
  "ikbs.column.passProcessWay.mark" : "标识",
  "ikbs.column.modify.modifyValidTimeCheckError" : "有效期开始时间不能晚于有效期结束时间",
  "ikbs.constableTmp.delete.selectTmp.confirm" : "确定删除？",
  "ikbs.knowledge.recycle.beyondColuPath" : "路径",
  "ikbs.favorite.msg.canNotSelectRoot" : "不能删除根目录，请重新选择",
  "ikbs.column.knowledge.button.modify" : "修改",
  "ikbs.knowledgeaudit.message.plsSelectBeginTime" : "请选择任务起始时间",
  "ikbs.contentTmp.tab.addTitle" : "新增模板",
  "ikbs.column.clearRecycle.comfirm" : "你确定要清空回收站吗？",
  "ikbs.knowledgeaudit.message.plsSelectEndTime" : "请选择任务截止时间",
  "ikbs.contTmp.importTmp.popup.title" : "模板导入",
  "ikbs.knowledge.label.uploadFileTypeError" : "附件只支持以下格式：doc、docx、ppt、pptx、xls、xlsx、pdf、png、jpg、gif",
  "ikbs.column.modify.selectModifyColumnKngNumError" : "只能选择一个栏目知识进行修改",
  "ikbs.column.knowledge.create" : "新建",
  "ikbs.confirm.delete" : "确认删除这条记录",
  "ikbs.column.operation.message.warning" : "提示",
  "ikbs.contentTmp.copy" : "复制",
  "ikbs.contentTmp.modifyEndTime" : "修改结束时间",
  "ikbs.column.label.passProcessWay" : "过期处理方式",
  "ikbs.knowledgeaudit.label.auditStatus" : "审核状态",
  "ikbs.favorite.tab.message.isDeleteFavoriteKng" : "请确认是否从收藏夹中删除该条收藏知识",
  "ikbs.select.consultation.form" : "请选择咨询表",
  "ikbs.knowledge.label.sendEmail" : "发送Email",
  "ikbs.tips.operation.success" : "操作成功",
  "ikbs.column.knowledge.KngStatus" : "状态",
  "ikbs.contentTmp.add" : "新增",
  "ikbs.properties.edit.information" : "编辑扩展属性",
  "ikbs.column.knowledge.title.moveTo" : "调整至",
  "ikbs.column.knowledge.button.more" : "更多",
  "ikbs.knowledge.properties.operation.downMove" : "下移",
  "ikbs.kbshome.label.kngBase" : "知识库",
  "ikbs.confirm.delete.by.condition" : "确认按条件删除记录",
  "ikbs.column.serviceStatus.normal" : "普通",
  "ikbs.constableTmp.comfirm" : "确认",
  "ikbs.tips.moveColumnKnowledgeOperation.warning" : "没有选择要移动的目标栏目",
  "ikbs.column.serviceStatus.updateService" : "更新业务",
  "ikbs.knowledge.tip.cannotCompare" : "只能选择两个普通知识进行比较",
  "ikbs.cntTmp.import.importSuccess" : "导入成功",
  "ikbs.column.label.coluType" : "栏目类型",
  "ikbs.knowledge.label.submit" : "提交",
  "ikbs.knowledge.message.uploadFileSizeError" : "文件大小不能超过10M",
  "ikbs.knowledge.label.deleteProblem" : "删除问答",
  "ikbs.knowledge.recycle.select.columnType" : "栏目类型",
  "ikbs.column.delete.property.comfirm" : "确认删除这条属性？",
  "ikbs.knowledge.placeholder.keyword" : "多个关键字间以;分割",
  "ikbs.kngCnt.cntTmp.popup.title" : "选择内容模板",
  "ikbs.knowledge.properties.operation.delete" : "删除",
  "ikbs.column.knowledge.compareAdd" : "新增",
  "ikbs.knowledgeaudit.label.waitForAudit" : "待办审核",
  "ikbs.constableTmp.cnstTblTmpDesc" : "模板描述",
  "ikbs.column.label.colukngname" : "栏目名称",
  "ikbs.knowledgeaudit.label.batchAuditReject" : "批量审核驳回",
  "ikbs.constableTmp.display.no" : "否",
  "ikbs.title.tips" : "提示",
  "ikbs.column.delete.selectNoColumn" : "没有选中栏目",
  "ikbs.column.knowledge.title.move" : "移动操作",
  "ikbs.column.isInheritProp.no" : "否",
  "ikbs.column.message.plsSelectModifyEndTime" : "请选择修改结束时间",
  "ikbs.column.label.servicesVaildStartTime" : "业务有效期开始时间",
  "ikbs.knowledge.properties.operation.edit" : "编辑",
  "ikbs.knowledge.pass.mark" : "标识",
  "ikbs.column.modify.modifyServiceTimeCheckError" : "业务有效期开始时间不能早于业务有效期结束时间",
  "ikbs.column.modify.properties.batch.message.warning" : "提示",
  "ikbs.cntTmp.import.error.oversize" : "内容模板文件最大20M",
  "ikbs.knowledgeaudit.label.cancel" : "取消",
  "ikbs.search.label.keyword" : "关键词",
  "ikbs.knghisverman.tab.message.deleteSuccess" : "删除成功",
  "ikbs.search.label.sortByClick" : "点击量",
  "ikbs.knowledge.tip.fileInjectionError" : "文件内容校验失败",
  "ikbs.knowledge.label.correlative" : "关联",
  "ikbs.column.needAudit.no" : "否",
  "ikbs.column.unlock.message.success" : "解锁成功",
  "ikbs.konwledgetype.question" : "问答知识",
  "ikbs.knowledge.service.timeout" : "过期",
  "ikbs.column.topMove.message.success" : "置顶成功",
  "ikbs.contentTmp.Modifier" : "修改人",
  "ikbs.knowledgeaudit.label.error" : "错误",
  "ikbs.knowledge.exception.AnswerNum.error" : "同一问题的回答不能超过10个",
  "ikbs.knowledgeaudit.title.submitTime" : "提交审核时间",
  "ikbs.column.label.colukngpath" : "栏目路径",
  "ikbs.knowledge.label.saveAndNext" : "保存进入下一步",
  "ikbs.constableTmp.save" : "保存",
  "ikbs.knghisverman.label.operation" : "操作",
  "ikbs.column.label.summaryInfo" : "概要信息",
  "ikbs.knowledge.recycle.button.reset" : "重置",
  "ikbs.knowledge.recycle.button.delete" : "删除",
  "ikbs.constableTmp.status.invalid" : "废弃",
  "ikbs.cntTmp.import.contTmpNameEditStandEmpty" : "模板内容和编辑规范不能同时为空",
  "ikbs.column.knowledge.button.copy" : "复制",
  "ikbs.knowledge.properties.properties.inputWay" : "输入方式",
  "ikbs.column.knowledge.compareModified" : "差异",
  "ikbs.constableTmp.innerFieldFlag" : "内置字段",
  "ikbs.column.passProcessWay.move" : "转移至历史栏目",
  "ikbs.knowledge.msg.fileUpload" : "栏目知识内容中文件上传",
  "ikbs.column.unlock.message.failed" : "解锁失败",
  "ikbs.cntTmp.import.editStandardOversize" : "编辑规范不能超过400个字符",
  "ikbs.contentTmp.save" : "保存",
  "ikbs.knowledge.check.kngName" : "知识或栏目名称不能包含/\\:*?\"<>|",
  "ikbs.contentTmp.tab.copyTitle" : "副本",
  "ikbs.column.downMove.message.fail" : "下移失败",
  "ikbs.column.knowledge.compareDel" : "缺失",
  "ikbs.knowledge.service.hot" : "热点",
  "ikbs.contentTmp.modify.fail" : "修改失败",
  "ikbs.knowledge.label.servicesStartTime" : "业务有效期开始时间",
  "ikbs.constableTmp.modifyTime" : "修改时间",
  "ikbs.knowledge.index.no" : "否",
  "ikbs.kbshome.label.ClickList" : "点击榜",
  "ikbs.knowledge.properties.properties.status" : "状态",
  "ikbs.knowledge.properties.dictValue" : "字典值",
  "ikbs.column.delete.message.warning" : "提示",
  "ikbs.column.delete.message.fail" : "删除失败",
  "ikbs.constableTmp.cnstTblName" : "对应咨询表",
  "ikbs.cntTmp.import.error.importType" : "请选择导入方式",
  "ikbs.contTmp.import.popup.title" : "导入模板",
  "ikbs.column.title.selectUser" : "选择用户",
  "ikbs.column.recycle.noSelectRecycles" : "没有选中任何记录",
  "ikbs.knowledge.label.servicesEndTime" : "业务有效期结束时间",
  "ikbs.knghisverman.label.modifier" : "修改人",
  "ikbs.cntTmp.import.result" : "导入内容模板完成，导入总数{0}，成功{1}条，失败{2}条",
  "ikbs.cntTmp.export.crosspage.info" : "不支持跨页导出，仅导出当前页选中数据",
  "ikbs.column.knowledge.expired" : "过期",
  "ikbs.knowledgeaudit.title.auditTime" : "审核时间",
  "ikbs.knowledge.tip.uploadFailed" : "上传失败",
  "ikbs.column.knowledge.CurVersion" : "当前版本",
  "ikbs.content.upload.file.Frequent" : "调用接口过于频繁，请稍后再试",
  "ikbs.knowledge.check.keyword" : "关键字不能包含符号",
  "ikbs.knowledge.tip.fileTypeError" : "文件类型校验失败",
  "ikbs.column.upMove.message.warning" : "提示",
  "ikbs.contentTmp.modifyStartTime" : "修改起始时间",
  "ikbs.knowledgeaudit.label.taskEndTime" : "任务截止时间",
  "ikbs.constableTmp.modifyBeginTime" : "修改起始时间",
  "ikbs.knowledge.lable.copy" : "副本",
  "ikbs.knowledgeaudit.label.batchAuditPass" : "批量审核通过",
  "ikbs.contentTmp.delete" : "删除",
  "ikbs.knowledge.tab.title" : "知识采编",
  "ikbs.knowledge.recycle.label.knowledgeType" : "栏目知识类型",
  "ikbs.column.knowledge.Type" : "类型",
  "ikbs.kbshome.button.thirtyDayList" : "30日榜",
  "ikbs.cntTmp.import.queryContTempErr" : "查询内容模板失败",
  "ikbs.favorite.table.delete" : "删除",
  "ikbs.column.move.message.warning" : "提示",
  "ikbs.knowledge.title.propTitle" : "属性标题",
  "ikbs.constableTmp.modify.selectedOne" : "只能选择一个模板",
  "ikbs.knowledge.label.uploadAttachment" : "上传附件",
  "ikbs.knowledge.pass.move" : "转移到历史栏目",
  "ikbs.favorite.title.warning" : "提示",
  "ikbs.kbshome.label.favorite" : "快捷访问",
  "ikbs.knowledge.label.deleteProperty" : "删除属性",
  "ikbs.column.knowledge.button.publish" : "发布",
  "ikbs.column.resumeRecycle.comfirm" : "确定执行恢复操作？",
  "ikbs.constableTmp.status.valid" : "启用",
  "ikbs.knowledgeaudit.label.reject" : "驳回",
  "ikbs.contentTmp.export" : "导出",
  "ikbs.properties.operation.message.fail" : "操作失败",
  "ikbs.knowledge.label.validEndTime" : "有效期结束时间",
  "ikbs.knowledge.label.preStep" : "上一步",
  "ikbs.properties.add.save" : "保存",
  "ikbs.knowledgeaudit.label.query" : "查询",
  "ikbs.knowledge.tip.keywordCountError" : "最多支持{count}个关键词",
  "ikbs.favorite.table.edit" : "编辑",
  "ikbs.knowledge.label.addKngPoint" : "增加问答",
  "ikbs.knowledge.label.creatorName" : "作者",
  "ikbs.knowledge.tip.operateFailed" : "操作失败",
  "ikbs.knghisverman.tab.message.isDeleteHisVer" : "请确认是否删除该知识历史版本",
  "ikbs.favorite.title.addDirectory" : "新增目录",
  "ikbs.knowledge.label.type" : "知识类型",
  "ikbs.column.Move.message.success" : "移动成功",
  "ikbs.column.knowledge.update" : "更新",
  "ikbs.search.label.sortByTime" : "时间",
  "ikbs.knowledge.label.summaryInfo" : "概要信息",
  "ikbs.properties.detail.information" : "扩展属性详情",
  "ikbs.column.release.message.success" : "发布成功",
  "ikbs.knowledge.label.ok" : "确定",
  "ikbs.knowledge.label.upload" : "上传",
  "ikbs.topMove.selectTopMoveColumnKngNumError" : "没有选中栏目知识",
  "ikbs.constableTmp.display.yes" : "是",
  "ikbs.column.modify.properties.batch.message.error" : "没有选中栏目知识",
  "ikbs.cntTmp.import.failReason" : "第{0}条数据上传失败，失败原因：{1}。",
  "ikbs.knowledge.properties.properties.title" : "属性标题",
  "ikbs.knowledgeaudit.label.auditor" : "审核人",
  "ikbs.cntTmp.export.exportNum.blank" : "请选择需要导出的内容模板",
  "ikbs.contentTmp.status.valid" : "启用",
  "ikbs.column.modify.selectModifyColumnKngNumZERO" : "没有选中栏目知识",
  "ikbs.column.tip.success" : "提示",
  "ikbs.knowledge.tip.relaKngCountError" : "最多支持{count}个关联知识",
  "ikbs.contentTemp.export.tempcontent" : "模板内容",
  "ikbs.contentTmp.query" : "查询",
  "ikbs.properties.status.draft" : "草稿",
  "ikbs.column.knowledge.ModifyTime" : "修改时间",
  "ikbs.knowledgeaudit.title.status" : "状态",
  "ikbs.column.knowledge.compare" : "比较",
  "ikbs.knowledge.label.uploadFileSizeError" : "附件大小不能超过10M",
  "ikbs.cntTmp.import.invalidData" : "非法数据",
  "ikbs.column.delete.message.success" : "删除成功",
  "ikbs.favorite.tree.favoriteRootName" : "收藏夹",
  "ikbs.column.moveTo.selectColumnKngNumError" : "没有选中栏目知识",
  "ikbs.cntTmp.export.error.syserr" : "导出内容模板文件失败",
  "ikbs.column.button.confirm" : "提交",
  "ikbs.constableTmp.delete.noSelectTmp" : "没有选中模板",
  "ikbs.content.transfer.html.success" : "内容转HTML成功",
  "ikbs.column.modify.modifyValidServiceEndTimeCheckError" : "业务有效期结束时间不能晚于有效期结束时间",
  "ikbs.knowledge.properties.label.propertiesTitle" : "属性标题",
  "ikbs.knowledge.label.addAnswer" : "增加答案",
  "ikbs.column.delete.message.comfirm" : "确认",
  "ikbs.column.button.search" : "查询",
  "ikbs.knghisverman.label.modifyTime" : "修改时间",
  "ikbs.select.record.to.delete" : "请选择要删除的记录",
  "ikbs.knowledgeaudit.label.submitterOrAuditor" : "提交人/审核人",
  "ikbs.constableManage.fieldValue" : "值",
  "ikbs.column.history.down.empty" : "该栏目下没有栏目或者知识，无法打开",
  "ikbs.constableTmp.name" : "模板名称",
  "ikbs.knowledgeaudit.label.pleaseSelect" : "请选择",
  "ikbs.cntTmp.import.syserr" : "导入内容模板失败",
  "ikbs.column.copy.selectCopyColumnKngNumError" : "只能选择一个知识进行复制",
  "ikbs.contentTmp.status.invalid" : "废弃",
  "ikbs.column.message.ids.max.size" : "批量操作的内容项不能超过100个",
  "ikbs.knowledge.properties.label.query" : "查询",
  "ikbs.kngCnt.cntTmp.importType.new" : "直接新建",
  "ikbs.content.transfer.html.fail" : "内容转HTML失败",
  "ikbs.column.upMove.message.success" : "上移成功",
  "ikbs.knghisverman.title.kngHisVerMan" : "知识历史版本管理",
  "ikbs.favorite.field.directoryName" : "目录名称",
  "ikbs.column.Move.message.fail" : "移动失败",
  "ikbs.column.deletePermanently.message.fail" : "彻底删除失败",
  "ikbs.columntype.knowledge" : "知识",
  "ikbs.knowledge.type.common" : "普通知识",
  "ikbs.knowledgeaudit.label.ok" : "确认",
  "ikbs.contentTmp.exception.contTmpName" : "非法输入",
  "ikbs.kngCnt.cntTmp.use.cntReplaceConfirm" : "是否替换当前知识内容",
  "ikbs.search.button.search" : "搜索",
  "ikbs.constableTmp.delete.success" : "删除成功",
  "ikbs.knowledge.recycle.coluKngName" : "名称",
  "ikbs.constableTmp.add.success" : "新增成功",
  "ikbs.kbshome.button.orderByName" : "按名称",
  "ikbs.knowledge.tip.uploadSuccess" : "上传成功",
  "ikbs.constableManage.incorrect" : "条件填写不正确",
  "ikbs.column.modify.properties.batch.message.success" : "批量修改属性成功",
  "ikbs.properties.inputway.text" : "文本输入",
  "ikbs.column.move.selectMoveColumnKngNumError" : "没有选中栏目知识",
  "ikbs.knowledgeaudit.title.auditRemark" : "审核备注",
  "ikbs.knowledge.label.indexNeedUpdate" : "是否更新索引",
  "ikbs.knowledge.recycle.status" : "状态",
  "ikbs.kbshome.button.orderByClicks" : "点击量",
  "ikbs.favorite.table.name" : "名称",
  "ikbs.column.knowledge.RelVersion" : "发布版本",
  "ikbs.kbshome.button.dayList" : "日榜",
  "ikbs.column.modify.modifyValidTimeServiceTimeSame" : "请同时修改有效期起止时间和业务有效期起止时间",
  "ikbs.column.label.servicesVaildEndTime" : "业务有效期结束时间",
  "ikbs.knowledgeaudit.label.submitAudit" : "提交审核",
  "ikbs.column.release.selectreleaseColumnKngTypeError" : "只有知识可以发布",
  "ikbs.knowledge.type.points" : "问答知识",
  "ikbs.column.knowledge.modifyStartTime" : "修改起始时间",
  "ikbs.favorite.title.preModifiedKngName" : "修改前名称",
  "ikbs.column.deleteRecycle.comfirm" : "确定执行删除操作？",
  "ikbs.recycle.title" : "回收站",
  "ikbs.column.tree.rootName" : "栏目知识",
  "ikbs.column.downMove.message.warning" : "提示",
  "ikbs.knghisverman.tab.title.kngBaseInfo" : "知识基本信息",
  "ikbs.addtype.label.column" : "新增栏目",
  "ikbs.knowledge.msg.updateKngPath" : "知识详情静态化",
  "ikbs.kbshome.label.recentViews" : "我的历史",
  "ikbs.column.knowledge.publish" : "发布",
  "ikbs.contentTemp.export.tempdesc" : "模板描述",
  "ikbs.column..topMove.message.fail" : "置顶失败",
  "ikbs.knowledge.recycle.button.clearAll" : "清空回收站",
  "ikbs.constableTmp.status" : "模板状态",
  "ikbs.contentTmp.contentDesc" : "模板描述",
  "ikbs.knghisverman.label.version" : "版本",
  "ikbs.column.knowledge.button.unLock" : "解锁",
  "ikbs.knowledge.error.property" : "请选择属性[{}]的值",
  "ikbs.knowledgeaudit.label.mySubmitAudit" : "我提交的审核",
  "ikbs.column.label.kngReleaseWay" : "栏目下知识是否需要审核发布",
  "ikbs.contentTmp.add.fail" : "新增失败",
  "ikbs.constableTmp.moveUp" : "上移",
  "ikbs.constableManage.condition.limit" : "条件数量不超过10条",
  "ikbs.column.downMove.selectDowmMoveColumnKngNumError" : "没有选中栏目知识",
  "ikbs.column.modify.modifyzeropropertieserror" : "没有修改任何属性",
  "ikbs.column.label.isInheritProp" : "是否继承父栏目属性",
  "ikbs.column.knowledge.reject" : "驳回",
  "ikbs.column.deletePermanently.columnkng" : "没有选中栏目知识",
  "ikbs.constableManage.modifyConstable" : "修改咨询表数据",
  "ikbs.contTmp.importTmp.title" : "下载导入模板",
  "ikbs.constableTmp.fieldViewName" : "显示名称",
  "ikbs.cntTmp.import.contTmpDesc" : "模板描述错误",
  "ikbs.constableManage.addConstable" : "新增咨询表数据",
  "ikbs.contentTmp.operate" : "操作",
  "ikbs.column.upMove.message.fail" : "上移失败",
  "ikbs.constableTmp.query" : "查询",
  "ikbs.column.recycle.message.comfirm" : "确认",
  "ikbs.kbshome.button.advancedSearch" : "高级搜索",
  "ikbs.constableTmp.modify.success" : "修改成功",
  "ikbs.kbshome.tip.refresh" : "刷新",
  "ikbs.constableTmp.delete.fail" : "删除失败",
  "ikbs.properties.operation.message.success" : "操作成功",
  "ikbs.column.knowledge.clear" : "清空",
  "ikbs.knowledge.label.auditor" : "审核人",
  "ikbs.column.tip.error" : "错误",
  "ikbs.column.deletePermanently.columnkng.comfirm" : "确认彻底删除栏目知识？",
  "ikbs.favorite.tab.message.isDeleteFavoriteDir" : "请确认是否删除收藏目录及该目录下的收藏知识",
  "ikbs.column.tips.modifyColumnOperation.error" : "修改栏目知识操作失败",
  "ikbs.column.button.reset" : "重置",
  "ikbs.knowledgeaudit.message.auditSuccess" : "审核成功",
  "ikbs.column.knowledge.Modifier" : "修改人",
  "ikbs.cntTmp.import.error.contTmpNameExist" : "模板名称已存在",
  "ikbs.cntTmp.export.info.success" : "导出完成",
  "ikbs.knowledge.label.kngAttachmentUpload" : "栏目知识附件上传",
  "ikbs.knowledge.tip.propCountLimited" : "最多只能关联20个属性",
  "ikbs.column.knowledge.button.deletePermanently" : "彻底删除",
  "ikbs.knowledge.recycle.label.operateTimeEnd" : "删除结束时间",
  "ikbs.knowledge.label.display" : "是否展示",
  "ikbs.cntTmp.export.result" : "导出内容模板完成，导出总数{0}，成功{1}条，失败{2}条",
  "ikbs.knowledge.label.deleteAnswer" : "删除答案",
  "ikbs.knowledge.properties.operation.detail" : "详情",
  "ikbs.column.knowledge.modifyEndTime" : "修改结束时间",
  "ikbs.knowledgeaudit.title.name" : "名称",
  "ikbs.knowledge.label.modifierName" : "最后更新者",
  "ikbs.column.knowledge.button.moveTo" : "调整至",
  "ikbs.knowledge.title.knowledgeContent" : "知识内容",
  "ikbs.knowledge.tip.fileSizeError" : "文件大小校验失败",
  "ikbs.constableTmp.delete" : "删除",
  "ikbs.tips.copy.onlyKngCanCopy" : "只有知识才能复制",
  "ikbs.column.passProcessWay.delete" : "删除",
  "ikbs.column.label.serviceStatus" : "业务状态",
  "ikbs.column.button.cancel" : "关闭",
  "ikbs.knowledge.label.clickNum" : "浏览次数",
  "ikbs.Column.MoveTo.message.success" : "操作成功",
  "ikbs.knowledge.index.yes" : "是",
  "ikbs.knowledge.tip.fileIOError" : "文件上传失败",
  "ikbs.column.modify.properties.message.warning" : "提示",
  "ikbs.constableTmp.operate" : "操作",
  "ikbs.knowledgeaudit.title.path" : "路径",
  "ikbs.column.serviceStatus.expired" : "过期",
  "ikbs.column.knowledge.audit" : "审核",
  "ikbs.contTmp.import.tip" : "1.只能上传.xlsx文件; 2.文件最大支持20M; 3.最多只能导入10条数据",
  "ikbs.knowledge.label.save" : "保存",
  "ikbs.knowledge.title.knowledgeExtended" : "知识扩展信息",
  "ikbs.column.knowledge.button.modifyPropBatch" : "修改属性",
  "ikbs.column.type.history" : "历史栏目",
  "ikbs.cntTmp.import.contTmpNameError" : "模板名称错误",
  "ikbs.knowledge.tip.error" : "错误",
  "ikbs.contentTmp.delete.success" : "删除成功",
  "ikbs.knowledgeaudit.label.success" : "成功",
  "ikbs.contentTmp.editStandard" : "编辑规范",
  "ikbs.favorite.title.editFavoriteKngName" : "修改名称",
  "ikbs.column.knowledge.button.down" : "下移",
  "ikbs.column.modify.message.error" : "修改失败",
  "ikbs.column.knowledge.compareKng" : "比较知识",
  "ikbs.knowledge.check.inputText" : "不能输入特殊字符",
  "ikbs.column.knowledge.button.delete" : "删除",
  "ikbs.knowledge.service.new" : "新业务",
  "ikbs.knowledge.properties.operation.disable" : "停用",
  "ikbs.column.serviceStatus.hot" : "热点",
  "ikbs.contentTmp.add.success" : "新增成功",
  "ikbs.favorite.msg.modifyNodeNameSuccess" : "操作成功",
  "ikbs.column.deletePermanently.message.warning" : "提示",
  "ikbs.contentTmp.modify" : "修改",
  "ikbs.kbshome.button.sevenDayList" : "7日榜",
  "ikbs.column.knowledge.button.turnToHistory" : "转历史",
  "ikbs.constableTmp.cancle" : "取消",
  "ikbs.knowledge.recycle.button.resume" : "恢复",
  "ikbs.knowledgeaudit.label.auditing" : "审核中",
  "ikbs.knowledge.release.audit" : "审核发布",
  "ikbs.constableTmp.yes" : "是",
  "ikbs.column.label.displayFlag" : "是否展示",
  "ikbs.column.moveto.back" : "调整至......后",
  "ikbs.column.modify.message.warning" : "提示",
  "ikbs.knowledge.label.nextStep" : "下一步",
  "ikbs.column.moveTo.message.warning" : "提示",
  "ikbs.column.isInheritProp.yes" : "是",
  "ikbs.knowledge.title.knowledgeBase" : "知识基本信息",
  "ikbs.knowledge.label.keyword" : "关键字",
  "ikbs.knowledgeaudit.label.reset" : "重置",
  "ikbs.knowledge.message.uploadImgTypeError" : "图片格式错误",
  "ikbs.knowledge.label.moveUp" : "上移",
  "ikbs.properties.inputway.checkbox" : "复选框",
  "ikbs.knowledgeaudit.label.auditReject" : "审核驳回",
  "ikbs.contentTmp.ModifyTime" : "修改时间",
  "ikbs.knowledge.label.checkboxProp" : "复选框",
  "ikbs.knowledge.label.kngMsg" : "知识信息",
  "ikbs.column.knowledge.compareResult" : "比较结果",
  "ikbs.cntTmp.import.invalidFile" : "非法文件",
  "ikbs.kbshome.button.orderByOrderId" : "按序号",
  "ikbs.column.knowledge.modifyColumnInformation" : "修改栏目",
  "ikbs.knowledgeaudit.placeholder.pleaseSelect" : "请选择",
  "ikbs.constableTmp.tab.modifyTitle" : "修改模板",
  "ikbs.knowledge.label.modifyRemark" : "备注",
  "ikbs.column.release.selectreleaseColumnKngNumError" : "没有选中知识",
  "ikbs.column.knowledge.button.order" : "排序",
  "ikbs.contentTmp.import" : "导入",
  "ikbs.column.label.validEndTime" : "有效期结束时间",
  "ikbs.properties.status.enable" : "启用",
  "ikbs.column.modify.modifyValidServiceStartTimeCheckError" : "业务有效期开始时间不能早于有效期开始时间",
  "ikbs.knowledge.error.contentEmpty" : "知识内容不能为空",
  "ikbs.column.upMove.selectUpMoveColumnKngNumError" : "没有选中栏目知识",
  "ikbs.constableTmp.add" : "新增",
  "ikbs.constableTmp.operator.exclude" : "不包含",
  "ikbs.column.knowledge.LockedFlat" : "锁定人",
  "ikbs.knowledge.label.cancelCollection" : "取消收藏",
  "ikbs.contentTmp.reset" : "重置",
  "ikbs.knowledge.label.textProp" : "文本输入",
  "ikbs.knowledge.title.propType" : "输入方式",
  "ikbs.cntTmp.export.exportNum.oversize" : "内容模板最多导出10条",
  "ikbs.properties.status.disable" : "停用",
  "ikbs.knowledgeaudit.label.knowledgeName" : "知识名称",
  "ikbs.constableManage.fieldName" : "列名",
  "ikbs.contentTmp.close" : "关闭",
  "ikbs.knowledge.properties.label.add" : "新增",
  "ikbs.knowledgeaudit.label.auditPass" : "审核通过",
  "ikbs.common.search" : "普通搜索",
  "ikbs.contentTmp.modify.success" : "修改成功",
  "ikbs.column.downMove.message.success" : "下移成功",
  "ikbs.knowledge.label.draft" : "草稿",
  "ikbs.knowledge.label.creationTime" : "创建时间",
  "ikbs.column.modify.properties.batch.message.fail" : "批量修改属性失败",
  "ikbs.column.message.plsSelectModifyStartTime" : "请选择修改起始时间",
  "ikbs.knowledge.label.problem" : "问题",
  "ikbs.constableTmp.displayFlag" : "是否显示",
  "ikbs.Column.MoveTo.message.fail" : "操作失败",
  "ikbs.column.knowledge.columnKnowledgeName" : "栏目知识名称",
  "ikbs.column.knowledge.button.top" : "置顶",
  "ikbs.tips.addColumnOperation.error" : "新增栏目失败",
  "ikbs.search.label.sortByName" : "按名称",
  "ikbs.column.delete.selectdeletecolumn.comfirm" : "确定删除这个栏目及栏目下的子栏目和知识？",
  "ikbs.constableTmp.modifierName" : "修改人",
  "ikbs.knowledge.label.attachment" : "附件",
  "ikbs.column.unlock.selectUnlockColumnKngNumError" : "没有选中栏目知识",
  "ikbs.contentTmp.tmpContent" : "模板内容",
  "ikbs.column.label.coluKngBrowsing" : "浏览群组",
  "ikbs.kbshome.button.totalList" : "总榜",
  "ikbs.search.label.resultDes" : "无数据",
  "ikbs.knowledge.recycle.button.query" : "查询",
  "ikbs.knowledge.properties.label.reset" : "重置",
  "ikbs.constableTmp.warning" : "提示",
  "ikbs.knowledge.label.kngReleaseWay" : "发布方式",
  "ikbs.properties.inputway.radio" : "单选框",
  "ikbs.knowledge.recycle.operationTime" : "删除时间",
  "ikbs.constableTmp.plsSelectModifyEndTime" : "请选择修改结束时间",
  "ikbs.column.knowledge.button.turnToNormal" : "转正常",
  "ikbs.knowledge.label.validStartTime" : "有效期开始时间",
  "ikbs.knowledge.pass.delete" : "删除",
  "ikbs.column.displayflag.show" : "是",
  "ikbs.knowledge.recycle.select.knowledgeStatus" : "知识状态",
  "ikbs.column.deletePermanently.message.success" : "彻底删除成功",
  "ikbs.time.lessThan.currentTime" : "结束时间不能早于当前时间",
  "ikbs.knowledge.recycle.label.operateTimeStart" : "删除起始时间",
  "ikbs.knowledge.recycle.label.knowledgeName" : "栏目知识名称",
  "ikbs.knghisverman.label.delete" : "删除",
  "ikbs.column.knowledge.Name" : "名称",
  "ikbs.knowledgeaudit.placeholder.pleaseInput" : "请输入",
  "ikbs.column.label.validStartTime" : "有效期开始时间",
  "ikbs.knowledge.display.yes" : "是",
  "ikbs.knowledge.label.servicesTime" : "业务有效期",
  "ikbs.search.label.updateStartTime" : "更新开始时间",
  "ikbs.favorite.msg.addDirectorySuccess" : "新增目录成功",
  "ikbs.search.label.result" : "搜索结果",
  "ikbs.constableTmp.modify.fail" : "修改失败",
  "ikbs.constableTmp.add.fail" : "新增失败",
  "ikbs.column.knowledge.modifierId" : "修改人",
  "ikbs.tips.propertyDictValueNumExceedMaxValue.warning" : "属性字典值数量超过最大值限制",
  "ikbs.constableTmp.no" : "否",
  "ikbs.knowledge.label.close" : "关闭",
  "ikbs.favorite.table.operate" : "操作",
  "ikbs.kngCnt.cntTmp.editStandard.title" : "编辑规范",
  "ikbs.constableTmp.anonymizationFlag" : "是否匿名化",
  "ikbs.knowledge.tip.fileCountError" : "最多支持{count}个附件",
  "ikbs.column.copy.selectCopyColumnKngNumZERO" : "没有选中知识进行复制",
  "ikbs.constableTmp.delete.byCondition" : "按条件删除",
  "ikbs.column.delete.selectdeletecolumnkng.comfirm" : "确定删除这些栏目或知识？",
  "ikbs.knowledge.label.name" : "知识名称",
  "ikbs.knowledge.check.textArea" : "不能输入特殊字符<>/",
  "ikbs.knowledge.label.addToCollection" : "添加收藏",
  "ikbs.knowledge.recycle.operator" : "操作员",
  "ikbs.konwledgetype.knowledge" : "普通知识",
  "ikbs.knowledgeaudit.label.taskBeginTime" : "任务起始时间",
  "ikbs.kngCnt.cntTmp.importType.sameNameCover" : "同名覆盖",
  "ikbs.column.knowledge.Path" : "路径",
  "ikbs.kbshome.label.plsInputKeyWord" : "请输入关键字",
  "ikbs.knowledge.label.addProperty" : "添加属性",
  "ikbs.knowledgeaudit.title.submitter" : "提交人",
  "ikbs.knowledge.properties.properties.operation" : "操作",
  "ikbs.knowledge.label.moveDown" : "下移"
}