export default {
    "cms.report.message.maxQueueCountDesc" : "Nombre maximal d'appels simultanés dont le type de périphérique de service est une file d'attente de compétences.",
    "cms.report.message.deviceTypeDesc" : "Type de l'appareil qui traite l'appel.",
    "cms.report.message.agentTalkingTimeDesc" : "Heure de conversation de l'appelant",
    "cms.report.field.maxAgentOutCount" : "Max. Appels sortants de l'agent simultané",
    "cms.report.field.inCallSuccNum" : "Nombre d'appels manuels connectés",
    "cms.agentMonitor.label.whisper_success" : "Succès chuchotant",
    "cms.report.message.maxQueueInCountDesc" : "Nombre maximal d'appels entrants simultanés dont le type de périphérique de service est une file d'attente de compétences.",
    "cms.report.field.agentReleaseTimeField" : "Heure de raccrochage de l'appelant",
    "cms.calllink.field.leaveReason39" : "Le CDN renvoie le résultat du routage.",
    "cms.indexmonitor.message.InCallAgentCount" : "Nombre d'appels entrants connectés",
    "cms.calllink.field.suspendedAndResumed" : "Un appel suspendu reprend.",
    "cms.calllink.field.leaveReason38" : "Connectez-vous au CDN en consultant les agents.",
    "cms.fullscreen.field.line" : "Graphique linéaire",
    "cms.calllink.field.leaveReason37" : "L'appel est acheminé vers le CDN.",
    "cms.calllink.field.leaveReason36" : "Reroutage d'appel en file d'attente.",
    "cms.report.message.busyTimeRateDesc" : "Taux de durée d'occupation = (Durée totale d'occupation/Durée totale de travail) x 100",
    "cms.calllink.field.leaveReason35" : "L'appel réseau est renvoyé au centre d'appel d'origine et mis en file d'attente.",
    "cms.calllink.field.leaveReason34" : "Délai d'attente/débordement/échec de l'appel réseau en cas d'occupation.",
    "cms.report.field.agentNum" : "ID de l'agent",
    "cms.calllink.field.leaveReason31" : "Transfert tiers vers les délais de file d'attente.",
    "cms.calllink.field.leaveReason30" : "Transfert réussi aux délais de file d'attente.",
    "cms.qcrelationconf.field.groupMemberInfo" : "Informations sur les membres du groupe d'utilisateurs",
    "cms.report.field.skillIdField" : "ID de la file d'attente de compétences à laquelle l'appel appartient",
    "cms.agentMonitor.label.selectAgent" : "Veuillez sélectionner un agent.",
    "cms.report.field.maxQueueCountField" : "Max. appels de file d'attente de compétences simultanées",
    "cms.report.message.IVRInBoundDesc" : "Nombre total d'appels entrants effectués à l'aide de la RVI.",
    "cms.fullscreen.field.yaHei" : "YaHei de Microsoft",
    "cms.report.field.releaseCauseField" : "Raison de la libération",
    "cms.qualitycheck.message.deleteSuccess" : "Supprimé avec succès.",
    "cms.developerlist.field.description" : "Descriptif",
    "cms.fullscreen.title.newChart" : "Créer un graphique",
    "cms.indexmonitor.field.ivrTotalCallNum" : "Total des appels entrants IVR",
    "cms.report.field.agentTalkDurationField" : "Durée(s) de conversation de l'appelant",
    "cms.recorddrive.field.configSftpAccountPwd" : "Mot de passe du serveur SFTP",
    "cms.calllink.field.leaveReason29" : "Transfert de tiers à la file d'attente.",
    "cms.report.message.outCallRateDesc" : "Pourcentage du nombre d'appels sortants connectés dans le nombre d'appels sortants.",
    "cms.calllink.field.leaveReason28" : "Transféré avec succès à la file d'attente.",
    "cms.recorddrive.field.configSftpPathError" : "Le chemin de stockage du fichier ne peut pas contenir './'",
    "cms.qualitycheck.label.selectedGroup" : "Groupes d'utilisateurs sélectionnés",
    "cms.indexmonitor.field.skillAbandonIn60Rate" : "Appels abandonnés dans les années 60",
    "cms.voiceinspection.field.socialChat" : "Multimédia",
    "cms.systemparam.error.validate.ruleinvalid" : "La règle de vérification n'est pas valide",
    "cms.tenant.message.pwdCheckmsg" : "Les deux mots de passe sont différents.",
    "cms.calllink.title.skillQueue" : "File d'attente de compétences connectée",
    "cms.fullscreen.field.fiveSec" : "5 Secondes",
    "cms.calllink.msg.numberInvalid" : "Format de nombre incorrect",
    "cms.fullscreen.field.indicatorData" : "Données de l'indicateur",
    "cms.report.message.ackBeginDesc" : "Heure de début de la réponse à l'appel.",
    "cms.fullscreen.field.themeName" : "Nom du sujet",
    "cms.report.field.callerHangUp" : "Appelant Raccrocher",
    "cms.report.field.waitEndField" : "Heure de fin d'attente",
    "cms.report.message.sysSuccRateDesc" : "Pourcentage du nombre d'appels entrants du système réussis par rapport au nombre total d'appels entrants du système.",
    "cms.agentMonitor.label.qcinspector_error" : "L'opération ne peut pas être effectuée parce que vous n'êtes pas un inspecteur.",
    "cms.callcenterinstanceslist.message.ccNameUsed" : "Le nom du centre d'appels a été utilisé.",
    "cms.report.field.outAnswerRate" : "Taux de connexion des appels sortants",
    "cms.indexmonitor.field.description" : "Descriptif",
    "cms.callcenterinstanceslist.message.createSuccess" : "Le centre d'appels est créé avec succès.",
    "cms.indexmonitor.field.totalCallNum" : "Total des appels",
    "cms.voiceinspection.message.selectVoice" : "Sélectionnez un enregistrement.",
    "cms.report.title.skillMonitor" : "Surveillance des performances de la file d'attente de compétences",
    "cms.vdnconfiguration.message.updatesuccess" : "Mise à jour réussie.",
    "cms.report.field.IVRCallOutSuccNum" : "Appels sortants IVR connectés",
    "cms.report.message.timeRangeCheckSaas" : "L'intervalle de temps ne peut pas dépasser 5 jours",
    "cms.report.field.avgInRingTime" : "Durée moyenne des appels entrants des terminaux",
    "cms.calllink.field.powerOff" : "Le téléphone est éteint.",
    "cms.report.message.inAvgRingTimeDesc" : "Durée moyenne de sonnerie du terminal d'un agent. Durée moyenne de sonnerie des appels entrants manuels = Durée totale de sonnerie des appels entrants manuels/Nombre de temps de réponse.",
    "cms.report.field.availTimeRate" : "Taux d'inactivité",
    "cms.developerlist.title.success" : "Succès",
    "cms.report.field.avgAcwTime" : "Durée moyenne de récapitulation",
    "cms.recorddrive.placeholder.sftpIdentityChecktips" : "Entrez votre compte actuel et votre mot de passe.",
    "cms.fullscreen.field.fullscreen" : "Plein écran",
    "cms.report.title.agentMonitor" : "Surveillance du résumé des performances de l'agent",
    "cms.systemparam.error.validate.notmatchrulemin" : "Le paramètre d'entrée est inférieur au minimum",
    "cms.datasource.label.delete" : "Supprimer",
    "cms.fullscreen.field.dayMonitor" : "Statistiques de l'indicateur d'aujourd'hui",
    "cms.report.field.call6Rate" : "Taux de connexion d'appel entrant manuel en 30s",
    "cms.report.message.workTimeDesc" : "Durée totale à partir du moment où un agent se connecte à l'heure où l'agent se déconnecte.",
    "cms.indexmonitor.title.callCenterDayMonitor" : "Statistiques d'aujourd'hui sur les indicateurs de centre d'appels",
    "cms.sysparam.config.item.catalog" : "Type de paramètre",
    "cms.report.field.systemInSucc" : "Appels entrants système réussis",
    "cms.systemmonitor.message.timeempty" : "* l'heure de début ou l'heure de fin ne sont pas autorisées à être vides.",
    "cms.report.message.systemInSuccDesc" : "Nombre d'appels entrants système réussis, y compris les appels pendant la lecture de la voix IVR, la mise en file d'attente, la sonnerie aux terminaux d'agent et la réponse de l'agent (à l'exclusion des appels entrants internes).",
    "cms.callcenterinstanceslist.title.chooseMode" : "Sélectionner le mode",
    "cms.indexmonitor.message.totalAwcNum" : "Nombre d'agents dans l'état de clôture après le traitement des affaires",
    "cms.recorddrive.label.sftpPort" : "Port du serveur SFTP",
    "cms.report.field.agentLoginNumField" : "Agents appelés",
    "cms.indexmonitor.message.totalTalkingNum" : "Nombre d'agents qui traitent les appels",
    "cms.agentMonitor.label.switch_success" : "Le basculement est réussi.",
    "cms.report.message.maxGarpMonth" : "Les données de plus de 12 mois ne peuvent pas être interrogées",
    "cms.agentmonitor.label.showAlarmTime" : "Temps",
    "cms.report.title.vdnTrafficPeriodReport" : "Rapport de période de trafic VDN",
    "cms.indexmonitor.field.totalInCallCount" : "Appels entrants en ligne",
    "cms.callcenterinstanceslist.field.ccName" : "Nom du centre d'appels",
    "cms.fullscreen.field.SimHei" : "SimHei",
    "cms.callcenterinstanceslist.field.kafkaUserName" : "Nom d'utilisateur KAFKA",
    "cms.report.field.queryEndTime" : "Heure de fin",
    "cms.indexmonitor.field.skillAgentAbandon" : "Appels raccrochés par des agents",
    "cms.report.field.ringTime" : "Durée totale de l'anneau",
    "cms.report.field.minAgentCount" : "Min. Appels d'agents simultanés",
    "cms.report.field.acwTimeRateField" : "Taux de durée de clôture",
    "cms.skilladjustment.field.seach" : "Rechercher",
    "cms.datasource.message.oracleUrlTip" : "Chaîne de connexion à la base de données: &#8220jdbc:oracle:thin:@ip:port:dbservicename&#8220&#10;ip (adresse IP) port (numéro de port) dbservicename (nom de la base de données)",
    "cms.uapmonitor.labe.mentionnotice" : "2. Assurez-vous que le centre d'appels virtuel contient des inspecteurs et que ceux-ci ont configuré le numéro de téléphone logiciel et le mot de passe de connexion. Seuls les agents en état anormal peuvent être déconnectés de force.",
    "cms.agentMonitor.label.search" : "Rechercher",
    "cms.report.title.IVRMonitor" : "Surveillance des données IVR",
    "cms.indexmonitor.message.errorInfo" : "Veuillez sélectionner au moins une file d'attente de compétences.",
    "cms.qualitycheck.field.selected" : "Sélectionné",
    "cms.recorddrive.field.storageLimit" : "Limite de stockage la plus basse (Mo)",
    "cms.datasource.message.dbNameInput" : "Saisissez un nom de source de données.",
    "cms.indexmonitor.field.ivrCallNum" : "Demandes d'appels entrants IVR",
    "cms.qcrelationconf.field.search" : "Requête",
    "cms.report.field.playVoiceCount" : "Temps de jeu de la voix",
    "cms.sysparam.config.reset" : "Réinitialiser",
    "cms.report.field.threePartyCallCountField" : "Appels à trois parties",
    "cms.report.field.maxIVRInCountField" : "Max. appels entrants IVR simultanés",
    "cms.skilladjustment.field.skillid" : "ID de file d'attente de compétence",
    "cms.report.title.agentTrend" : "Résumé des performances de l'agent Tendance",
    "cms.fullscreen.field.busyCountTimeDesc" : "Nombre de fois où les agents sont en état d'occupation.",
    "cms.report.label.export" : "Exporter",
    "cms.export.message.exceedlimit2" : "Affinez la plage de temps des données ou ajoutez des critères de filtre de données.",
    "cms.export.message.exceedlimit1" : "Un maximum de 0 enregistrements de données peut être exporté. Affinez la plage de temps des données ou ajoutez des critères de filtre de données.",
    "cms.report.message.minAgentOutCountDesc" : "Nombre minimal d'appels sortants simultanés dont le type de périphérique de service est agent.",
    "cms.voiceinspection.field.videoPlay" : "Lecture vidéo",
    "cms.agentmonitor.label.talkingState" : "Parler",
    "cms.calllink.field.connectionHold" : "Un appel maintenu est connecté.",
    "cms.report.field.loginCountField" : "Heures de connexion",
    "cms.report.field.manToIvrNumCtiField" : "Nombre de fois qu'un agent est suspendu pour le transfert vers le RVI",
    "cms.report.field.minAgentInCountField" : "Appels entrants d'agent simultanés min",
    "cms.callcenter.field.reset" : "Réinitialiser",
    "cms.uapmonitor.labe.interval" : "Période(s) de surveillance",
    "cms.report.field.intelligentOutCall" : "Appel sortant intelligent",
    "cms.report.field.inWaitAnsNumField" : "Appels entrants manuels",
    "cms.indexmonitor.field.totalIdeaNum" : "Agents en état inactif",
    "cms.callcenterinstanceslist.field.gwPort" : "Port CC-Gateway",
    "cms.report.field.playVoiceCountField" : "Temps de jeu de la voix",
    "cms.report.field.transferOutCountField" : "Temps de transfert manuel",
    "cms.uapmonitor.label.error" : "Erreur",
    "cms.systemparam.error.validate.notmatchrulealpha" : "Veuillez entrer des caractères alphanumériques ou des traits de soulignement, et ne commencer que par des lettres ou des traits de soulignement",
    "cms.recorddrive.message.sftpIdentityChecktips" : "Le compte ou le mot de passe du serveur SFTP est modifié. Pour assurer la sécurité des informations, entrez votre compte actuel et votre mot de passe.",
    "cms.report.field.otherTimeRateField" : "Autres taux de durée",
    "cms.monitordetail.field.signinskill" : "File d'attente de connexion",
    "cms.report.message.maxAgentInCountDesc" : "Nombre maximal d'appels entrants simultanés dont le type de périphérique de service est agent.",
    "cms.report.message.netEntIdDesc" : "NE ID du serveur multimédia qui génère le CDR actuel.",
    "cms.callcenter.message.updatesuccess" : "Mis à jour avec succès.",
    "cms.report.field.IVRCallOutNum" : "Appels sortants IVR",
    "cms.report.message.minIVROutCountDesc" : "Nombre minimal d'appels sortants simultanés dont le type de périphérique de service est IVR.",
    "cms.qcrelationconf.title.error" : "Erreur",
    "cms.failurecall.message.currentFailureCall" : "Il y a",
    "cms.report.field.avgCallInTimeField" : "Durée moyenne d'appel",
    "cms.indexmonitor.title.vdnNowMonitor" : "Statistiques de surveillance en temps réel sur le VDN pendant l'exécution",
    "cms.agentMonitor.label.interceptNotice" : "Utilisez cette fonction lorsque l'agent courant est écouté, interrompu ou chuchoté.",
    "cms.callcenterinstanceslist.field.ccBcsPort" : "Port CCBCS LB",
    "cms.report.field.failAvgTimeField" : "Durée moyenne de sonnerie des appels perdus",
    "cms.report.message.callerNoDesc" : "Numéro d'appel.",
    "cms.report.field.avgAgentOutCountField" : "Moyenne des appels sortants de l'agent simultané",
    "cms.report.field.callSuccRate" : "Taux de connexion de demande",
    "cms.report.message.succWaitAnsRateDesc" : "Durée moyenne d'attente de tous les appels connectés dans une file d'attente.",
    "cms.report.field.onlineRate" : "Tarif en ligne",
    "cms.callcenter.field.deletefailure" : "Échec de la suppression",
    "cms.report.field.stateField" : "État de connexion",
    "cms.report.message.nomorethanerror" : "La date sélectionnée ne peut pas être antérieure à {0}.",
    "cms.report.field.avgHoldTimeField" : "Durée moyenne d'attente",
    "cms.qualitycheck.title.deleteSuccess" : "Succès de suppression",
    "cms.calllink.field.cancelQueuingTransfer" : "Un appel est transféré lorsque la file d'attente est annulée.",
    "cms.systemmonitor.title.error" : "Erreur",
    "cms.report.field.acwOutCount" : "Heures de récapitulation des appels sortants",
    "cms.indexmonitor.message.agentAbandonIn10NDesc" : "Nombre total d'abandons avec une durée de réponse supérieure ou égale à 10 secondes",
    "cms.report.field.preDeviceNoField" : "Indique l'identifiant de l'appareil précédent",
    "cms.systemmonitor.field.incallNums" : "Appels entrants système",
    "cms.systemmonitor.message.queryErrorInfo" : "La requête a échoué.",
    "cms.datasource.title.modifyDatasource" : "Mettre à jour la source de données",
    "cms.indexmonitor.field.inboundConnectRate" : "Appels entrants connectés",
    "cms.report.title.abandonRingReport" : "Rapport d'appels abandonnés durant la sonnerie",
    "cms.uapmonitor.field.registered" : "enregistré",
    "cms.reportsubscribe.field.suspended" : "Non publié",
    "cms.indexmonitor.message.skillAvgAbandonQueueDesc" : "Durée moyenne de mise en file d'attente de tous les appels sans réponse dans une file d'attente",
    "cms.indexmonitor.field.callInIVR" : "Appels entrants en ligne IVR",
    "cms.fullscreen.field.holdCountTimeDesc" : "Nombre de fois où les agents sont en état d'attente.",
    "cms.calllink.field.analysisNotConfig" : "L'analyse du numéro appelé n'est pas configurée pour un appel.",
    "cms.voiceinspection.video.tolong.info" : "Téléchargez la vidéo enregistrée pour la lire car elle est longue.",
    "cms.developerlist.title.refreshsk" : "Mettre à jour SK",
    "cms.calllink.title.caller" : "Numéro de l'appelant",
    "cms.report.field.onlineRateField" : "Taux en ligne",
    "cms.indexmonitor.message.agentConnectedIn10NDesc" : "Nombre total d'appels dont la durée de réponse est supérieure ou égale à 10 secondes",
    "cms.reportsubscribe.field.sunday" : "Dimanche",
    "cms.export.message.exception" : "Une erreur s'est produite lors de la génération de la tâche d'exportation. Vérifie les journaux.",
    "cms.report.title.vdnSystemPerformanceReport" : "Rapport de surveillance des performances du système",
    "cms.calllink.title.callee" : "Numéro appelé",
    "cms.callcenterinstanceslist.message.dsmodifyconfirm" : "La configuration de la source de données a été modifiée. Redémarrez l'environnement pour que la modification prenne effet. Êtes-vous sûr de vouloir le modifier ?",
    "cms.callcenter.message.deletesuccess" : "Supprimé avec succès.",
    "cms.report.field.dualCallType" : "Un clic Deux appels",
    "cms.callcenter.message.configcallcenter" : "Configurez le centre d'appels.",
    "cms.report.title.selectReportTime" : "Définir l'heure de début",
    "cms.report.field.manToSkillNumField" : "Transferts de file d'attente manuel-compétence",
    "cms.report.message.playVoiceCountDesc" : "Nombre de temps de jeu de voix.",
    "cms.indexmonitor.label.search" : "Rechercher",
    "cms.systemmonitor.field.selectCcName" : "Sélectionnez le centre d'appels",
    "cms.systemparam.error.validate.notmatchrule" : "Le paramètre d'entrée ne correspond pas à la règle de vérification",
    "cms.report.message.agentInBoundDesc" : "Nombre d'appels entrants attribués à des files d'attente de compétences ou à des agents.",
    "cms.callcenterinstanceslist.message.clearKafkaConfig" : "Voulez -vous vraiment supprimer la configuration du nœud KAFKA ?",
    "cms.report.field.auxTimeRate" : "Taux de durée de repos",
    "cms.developerlist.message.refreshfailed" : "Impossible de mettre à jour le SK.",
    "cms.fullscreen.message.numberSecondMin" : "La valeur minimale est 720",
    "cms.indexmonitor.field.totalLoginNum" : "Agents de connexion",
    "cms.agentMonitor.label.beinidle_error" : "L'agent inspecté est inactif.",
    "cms.export.field.success" : "Succès",
    "cms.report.message.agentReleaseTimeDesc" : "Heure de raccrochage de l'appelant",
    "cms.report.field.agentInSuccRateField" : "Taux de connexion des appels entrants manuels",
    "cms.qualitycheck.label.editMember" : "Modifier le membre du groupe",
    "cms.report.field.fifteenminutes" : "15 minutes",
    "cms.fullscreen.message.errorMonitorMethod" : "Sélectionnez un mode de surveillance.",
    "cms.qualitycheck.label.createusergroup" : "Créer des UserGroup",
    "cms.record-playback.callType3" : "Appel sortant OBS",
    "cms.report.message.abortQueueWaitNumDesc" : "Nombre d'appels abandonnés de façon proactive par les abonnés pendant la file d'attente et la sonnerie.",
    "cms.report.field.avgQueueOutCount" : "Avg. Appels sortants en file d'attente de compétences simultanées",
    "cms.systemparam.error.validate.notmatchrulemax" : "Le paramètre d'entrée dépasse le maximum",
    "cms.indexmonitor.field.cancel" : "Annuler",
    "cms.indexmonitor.field.outboundConnectRate" : "Appels sortants connectés",
    "cms.indexmonitor.field.outboundAbandonRate": "Appels sortants abandonnés",
    "cms.callcenterinstanceslist.field.vdnName" : "Nom du VDN",
    "cms.uapmonitor.field.socketNormal" : "Normal",
    "cms.report.field.avgCallInTime" : "Durée moyenne des appels",
    "cms.synchronize.message.noConfConfPwd" : "Le mot de passe n'est pas configuré. Configurez le mot de passe de l'interface.",
    "cms.report.field.startAgentId" : "ID de l'agent de démarrage",
    "cms.report.field.avgQueueCountField" : "Moyenne des appels de file d'attente de compétences simultanées",
    "cms.report.field.auxCount" : "Temps de repos",
    "cms.uapmonitor.labe.inspectorcheckinnum" : "Numéro de connexion de l'inspecteur QC",
    "cms.report.field.avgQueueInCount" : "Avg. Appels entrants en file d'attente de compétences simultanées",
    "cms.systemparam.error.validate.notmatchrulerange" : "Le paramètre d'entrée dépasse la valeur maximale ou est inférieur à la valeur minimale",
    "cms.report.message.transferIvrCountDesc" : "Nombre de temps de transfert manuel-à-IVR.",
    "cms.callcenterinstanceslist.message.wasPsdLengthRangeValidateFailed" : "La longueur du mot de passe doit être comprise entre 16 et 32",
    "cms.indexmonitor.field.skillAbandonIn10Rate" : "Appels abandonnés en 10s",
    "cms.indexmonitor.field.skillAbandonIn20Rate" : "Appels abandonnés en 20s",
    "cms.indexmonitor.message.skillAvgConnectedDesc" : "Durée moyenne des appels. Durée moyenne des appels entrants manuels = Duartion des appels entrants manuels/Nombre d'appels entrants manuels connectés.",
    "cms.monitordetail.field.mediatype" : "Type de média d'appel",
    "cms.agentMonitor.label.beinbusy_error" : "L'agent inspecté est occupé.",
    "cms.qualitycheck.title.saveSuccess" : "Succès",
    "cms.sysparam.config.itemdesc" : "Descriptif",
    "cms.report.message.manTransferOutNumCtiDesc" : "Nombre de fois que l'agent transfère à l'IRV, puis transfère à l'extérieur.",
    "cms.developerlist.field.ak" : "Développeur AK",
    "cms.fullscreen.message.numberFirstMin" : "La valeur minimale est 1280",
    "cms.datasource.title.addDatasource" : "Ajouter une source de données",
    "cms.vcallecenter.message.modifyadminpasswordsuccess" : "Modifiez le mot de passe avec succès.",
    "cms.qcrelationconf.placeholder.groupName" : "Nom du groupe",
    "cms.calllink.title.agentId" : "ID de l'agent connecté",
    "cms.indexmonitor.field.outboundConnectNum" : "Appels sortants connectés",
    "cms.reportsubscribe.field.saturday" : "Samedi",
    "cms.indexmonitor.message.inboundConnectRate" : "Pourcentage du nombre d'appels entrants connectés dans le nombre d'appels entrants.",
    "cms.agentmonitor.label.qualityInspection" : "Contrôle de qualité",
    "cms.indexmonitor.field.skillConnectedIn60Rate" : "Appels connectés en années 60",
    "cms.qcrelationconf.message.addSuccess" : "Ajouter avec succès",
    "cms.failurecall.field.callSequenceNumber" : "Appel S/N",
    "cms.report.field.inCallFailNum" : "Demandes d'appels perdus",
    "cms.report.field.submediatype.email" : "Email",
    "cms.report.field.IVRCallOutSuccRate" : "Taux de connexion des appels sortants IVR",
    "cms.qcrelationconf.field.qcCcRoleName" : "Rôle de l'inspecteur",
    "cms.indexmonitor.field.chooseAll" : "Sélectionner tout",
    "cms.report.message.outSuccAnsRateDesc" : "Pourcentage du nombre d'appels sortants connectés dans le nombre d'appels sortants.",
    "cms.export.message.addExportTaskError" : "La nouvelle tâche d'exportation a échoué, veuillez vérifier le journal",
    "cms.agentmonitor.label.haveAlarm" : "Alarme",
    "cms.calllink.title.callBeginTime" : "Heure de début de l'appel",
    "cms.sysparam.config.opterate" : "Opération",
    "cms.agentmonitor.label.idle" : "Forcer le ralenti",
    "cms.report.field.ivrtrafficreport.ivrinbound" : "IVR Occupy Num",
    "cms.report.field.perAgentNum" : "Appels d'agents",
    "cms.report.message.auxCountDesc" : "Nombre de fois qu'un agent entre dans l'état de repos.",
    "cms.indexmonitor.field.callOutIVR" : "Appels sortants en ligne IVR",
    "cms.agentmonitor.label.preoccupationState" : "préoccupés",
    "cms.calllink.field.threePartyCall" : "L'appel est un appel à trois parties.",
    "cms.report.message.talkingTimeDesc" : "Heure de conversation de l'appelant",
    "cms.systemmonitor.field.time" : "Statistiques collectées",
    "cms.report.field.acdTimeField" : "Durée(s) d'appel manuel(s)",
    "cms.report.title.skillAccessReport" : "Rapport de trafic de la file d'attente des compétences par code d'accès",
    "cms.callcenter.field.cancel" : "Annuler",
    "cms.uapmonitor.labe.qcworkno" : "QC ID de l'employé",
    "cms.report.field.threePartyCallCountDesc" : "Nombre d'appels à trois d'un agent.",
    "cms.voiceinspection.message.noRecordFound" : "Aucun enregistrement correspondant n'est trouvé.",
    "cms.report.field.muteTimeField" : "Durée totale du silence",
    "cms.report.message.timeCheckError" : "L'intervalle de temps ne peut excéder sept jours",
    "cms.indexmonitor.field.skillAvgConnectedQueue" : "Durée moyenne de mise en file d'attente des appels connectés",
    "cms.failurecall.field.reset" : "Réinitialiser",
    "cms.report.message.billInfo2Desc" : "ID unique d'un double appel.",
    "cms.indexmonitor.field.ivrInboundConnectRate" : "Appels entrants RVI connectés",
    "cms.report.message.availTimeDesc" : "Durée totale lorsqu'un agent est dans l'état inactif.",
    "cms.voiceinspection.field.operat" : "Opération",
    "cms.voiceinspection.field.predictOutbound" : "Sortant prédictif",
    "cms.fullscreen.field.totalCallTime" : "Durée totale de l'appel",
    "cms.callcenterinstanceslist.field.ctiIndexMonitor" : "Surveillance des indicateurs du système CTI",
    "cms.indexmonitor.message.totalInCallCount" : "Nombre total d'appels entrants. (N'inclut pas les sessions multimédias)",
    "cms.fullscreen.field.monitorType" : "Type d'indicateur",
    "cms.indexmonitor.field.skillAbandonIn5Rate" : "Appels abandonnés en 5s",
    "cms.sendnote.title.tips" : "Informations",
    "cms.indexmonitor.field.callRate" : "Tarif d'appel",
    "cms.sysparam.config.item.detail" : "Informations sur les paramètres",
    "cms.skilladjustment.message.notMatchAgent" : "Le type de file d'attente de compétences ajusté ne correspond pas au type d'agent.",
    "cms.sendnote.field.notecontent" : "Contenu",
    "cms.report.message.dategt12weeks" : "La plage hebdomadaire ne peut pas dépasser 12 semaines.",
    "cms.qcrelationconf.field.agentCcRoleName" : "Rôle de la partie inspectée",
    "cms.agentMonitor.label.cancelInsert_error" : "Impossible d'annuler l'insertion.",
    "cms.indexmonitor.message.ivrCallNum" : "Nombre d'appels pour lesquels des services automatiques sont fournis par des dispositifs IVR",
    "cms.report.message.otherTimeDesc" : "Durée totale lorsqu'un agent est dans un autre état, ou durée d'appel d'autres compétences lorsqu'il existe plusieurs compétences.",
    "cms.report.field.outCallTime" : "Durée totale des appels sortants",
    "cms.callcenterinstanceslist.field.pool" : "Mode pool",
    "cms.developerlist.field.search" : "Rechercher",
    "cms.systemparam.error.validate.notmatchruleemail" : "Veuillez entrer une adresse email valide",
    "cms.indexmonitor.message.skillIdMaxSize" : "Un maximum de cinq files d'attente de compétences peuvent être sélectionnées.",
    "cms.report.message.dategt1year" : "La fourchette de mois ne peut excéder 12 mois.",
    "cms.report.field.callSuccRateField" : "Taux de connexion des demandes",
    "cms.uapmonitor.field.Logout" : "Déconnexion",
    "cms.indexmonitor.message.agentInboundConnectNum" : "Nombre d'appels entrants manuels auxquels les agents répondent",
    "cms.report.field.minAgentOutCountField" : "Minimum d'appels sortants d'agent simultanés",
    "cms.agentmonitor.label.noAlarm" : "Pas d'alarme",
    "cms.indexmonitor.message.agentConnectInThirty" : "Pourcentage du nombre d'appels raccordés manuellement en moins de 30s dans le nombre total d'appels raccordés manuellement.",
    "cms.qcrelationconf.field.agentGroup" : "Groupe d'agents",
    "cms.indexmonitor.field.skillAbandonAfter60" : "Appels abandonnés après les années 60",
    "cms.export.field.refresh" : "Actualiser",
    "cms.report.field.avgIVRInCountField" : "Moyenne des appels entrants IVR simultanés",
    "cms.datasource.field.backupDbName" : "Source de données de sauvegarde",
    "cms.indexmonitor.message.outboundConnectNum" : "Nombre d'appels sortants manuels connectés + Nombre d'appels sortants IVR connectés",
    "cms.report.message.minIVRCountDesc" : "Nombre minimal d'appels simultanés dont le type d'appareil de service est IVR.",
    "cms.report.field.busyTimeField" : "Durée totale d'occupation",
    "cms.fullscreen.field.bold" : "Gras",
    "cms.vcallecenter.title.authentication" : "Vérifier l'identité",
    "cms.recorddrive.field.configSftpAccountConfirmPwd" : "Serveur SFTP Confirmer le mot de passe",
    "cms.indexmonitor.label.reset" : "Réinitialiser",
    "cms.callcenterinstanceslist.message.updateCtiVersion2" : "Vérifiez si la migration des données CTI est terminée. Faites preuve de prudence lorsque vous effectuez cette opération. (Après que vous confirmiez l'opération, la fonction de synchronisation automatique est désactivée.)",
    "cms.skilladjustment.field.inputskill" : "Saisissez un nom de file d'attente de compétences.",
    "cms.report.title.callCenterReport" : "Rapport de trafic VDN",
    "cms.qcrelationconf.field.operator" : "Agent commun",
    "cms.report.field.noAckNumField" : "Appels sans réponse",
    "cms.agentmonitor.label.showAlarmEmotionType" : "Type émotionnel",
    "cms.report.message.inAvgCallTimeDesc" : "Durée moyenne des appels. Durée moyenne des appels entrants manuels connectés = Durée des appels entrants manuels connectés/Nombre d'appels entrants manuels connectés.",
    "cms.report.field.agentAlertingTimeField" : "Heure d'alerte de l'appelant",
    "cms.agentmonitor.label.answering" : "Répondre",
    "cms.qcrelationconf.field.role" : "Rôle",
    "cms.voiceinspection.field.webphoneVideo" : "Appel vidéo click-to-dial",
    "cms.report.field.muteTime" : "Durée totale de silence",
    "cms.report.field.outAvgRingTimeField" : "Durée moyenne de sonnerie des appels sortants manuels",
    "cms.report.field.avgHandleTime" : "Durée moyenne de traitement",
    "cms.report.field.maxAgentOutCountField" : "Max. Appels sortants de l'agent simultané",
    "cms.indexmonitor.message.totalAnsweringNum" : "Nombre d'agents dont le téléphone sonne",
    "cms.fullscreen.message.playTheme" : "Ce sujet a été joué.",
    "cms.fullscreen.field.yes" : "Oui",
    "cms.indexmonitor.message.skillTimeoutQueueDesc" : "Nombre d'appels transférés de la file d'attente de compétences d'origine vers d'autres files d'attente de compétences en raison du délai de mise en file d'attente",
    "cms.report.field.mediatype.clickvediocall" : "Vidéo click-to-dial",
    "cms.sysparam.config.itemname.tips" : "Saisissez un nom de paramètre.",
    "cms.qualitycheck.label.view" : "voir",
    "cms.agentMonitor.label.error" : "Erreur",
    "cms.vcallecenter.field.adminworkno" : "ID administrateur",
    "cms.reportsubscribe.field.subscribeContent" : "S'abonner au contenu",
    "cms.voiceinspection.field.callType" : "Type d'appel",
    "cms.agentmonitor.label.accountCode" : "Entrez un compte de service",
    "cms.indexmonitor.message.agentOutCallCount" : "Nombre d'appels sortants effectués par l'agent actuel. (N'inclut pas les sessions multimédias)",
    "cms.developerlist.message.deletefailed" : "La suppression a échoué.",
    "cms.reportsubscribe.field.thursday" : "Jeudi",
    "cms.report.field.acwTimeField" : "Durée totale de clôture",
    "cms.report.field.callerNoField" : "Numéro d'appel",
    "cms.indexmonitor.field.skillOverQueue" : "Débordement des appels vers les files d'attente",
    "cms.report.field.availTimeField" : "Durée(s) d'inactivité",
    "cms.indexmonitor.message.mediaTypeMetion" : "L'élément de filtre de type de média n'est valide que pour les KPI du jour en cours et la tendance du jour en cours.",
    "cms.agentmonitor.label.occupationState" : "Occupé",
    "cms.report.field.minIVRInCount" : "Min. Appels entrants IVR simultanés",
    "cms.synchronize.message.invalidAdminPwd" : "Mot de passe incorrect. Modifiez le mot de passe administrateur.",
    "cms.vcallecenter.title.success" : "Succès",
    "cms.agentMonitor.label.forceidle_success" : "Un agent est placé de force à l'état inactif avec succès.",
    "cms.report.message.currentDateCheck" : "L'heure sélectionnée doit être antérieure au jour actuel.",
    "cms.vcallcenter.message.unknowsolution" : "Contactez les ingénieurs de Huawei pour analyser et corriger la panne",
    "cms.vcallecenter.message.modifyadminpasswordfail" : "Impossible de modifier le mot de passe.",
    "cms.report.field.onlyIVRServiceField" : "Appels entrants Pure-IVR réussis",
    "cms.monitordetail.field.account" : "Compte",
    "cms.report.field.agentInBound" : "Appels entrants manuels",
    "cms.report.field.assistsCount" : "Demandes d'aide interne",
    "cms.callcenterinstanceslist.field.cmsIp" : "Adresse IP CMS LB",
    "cms.systemmonitor.field.agentOnlineCount" : "Numéros de softphone enregistrés",
    "cms.datasource.field.dbServiceType" : "Type d'entreprise",
    "cms.indexmonitor.message.agentConnectedOut60NDesc" : "Nombre total d'appels connectés dont la durée de réponse est supérieure à 60 secondes.",
    "cms.report.field.avgPerHNum" : "Moyenne des appels réussis par heure",
    "cms.indexmonitor.field.inboundCallNum" : "Appels entrants",
    "cms.report.field.statInterval" : "Période de temps",
    "cms.indexmonitor.message.totalCallNum" : "Nombre d'appels entrants + Nombre d'appels sortants",
    "cms.uapmonitor.field.socketFault" : "défaut",
    "cms.agentMonitor.label.insets_success" : "L'insertion a réussi.",
    "cms.vcallecenter.message.currentacountpasderror" : "Le mot de passe du compte courant est incorrect.",
    "cms.indexmonitor.field.skillAvgQueueAck" : "Durée moyenne d'attente",
    "cms.report.message.transferOutCountDesc" : "Nombre de temps de transfert manuel.",
    "cms.qcrelationconf.field.group" : "Groupe",
    "cms.qcrelationconf.message.queryParamNull" : "L'ID de l'agent et le nom du groupe d'utilisateurs ne peuvent pas être vides en même temps",
    "cms.agentMonitor.label.switch_error" : "Le basculement a échoué.",
    "cms.report.message.agentServiceRateDesc" : "Pourcentage du nombre d'agents ayant répondu aux appels par rapport au nombre total d'appels entrants. Taux de succès manuel dans le total des appels entrants = Nombre d'appels répondus par l'agent/ (Nombre d'appels entrants RVI réussis + Nombre d'appels répondus par l'agent) x 100",
    "cms.report.field.auxTimeField" : "Durée totale de repos",
    "cms.reportsubscribe.field.subscribeName" : "Nom d'abonnement",
    "cms.report.field.outAvgAnsTimeField" : "Durée moyenne de sonnerie des appels sortants",
    "cms.export.field.exportBeginTime" : "Exporter l'heure de début",
    "cms.callcenterinstanceslist.field.faultStatus" : "Défectueux",
    "cms.developerlist.message.error" : "Erreur",
    "cms.report.message.IVRServiceRateDesc" : "Pourcentage du nombre d'appels traités uniquement par le RVI dans le nombre total d'appels entrants. Taux de réussite des appels entrants purs-IVR = appels entrants purs-IVR réussis/ (Appels entrants pur-IVR réussis + Nombre d'appels répondus par l'agent) x 100.",
    "cms.agentMonitor.label.cancelwhisper_error" : "Impossible d'annuler le chuchotement.",
    "cms.qualitycheck.label.cancel" : "Annuler",
    "cms.systemmonitor.label.search" : "Rechercher",
    "cms.indexmonitor.title.filteringIndicator" : "Indicateur de filtre",
    "cms.report.field.byMonth" : "Rapport mensuel",
    "cms.report.message.availTimeRateDesc" : "Pourcentage de la durée inactive totale d'un agent dans la durée totale du travail.",
    "cms.recorddrive.title.recorddrivenameconfig" : "Configuration du volume",
    "cms.report.field.acwOutTime" : "Durée de récapitulation des appels sortants",
    "cms.report.title.callCenterTrend" : "Tendance des performances VDN",
    "cms.qcrelationconf.field.qcGroup" : "Groupe de contrôle de qualité",
    "cms.datasource.field.yes" : "Oui",
    "cms.indexmonitor.message.updateSuccess" : "Succès de mise à jour",
    "cms.report.message.timeRangeCheck" : "L'intervalle de temps ne peut pas dépasser 1 jour",
    "cms.report.field.call3Rate" : "Taux de connexion d'appel entrant manuel en 15s",
    "cms.callcenterinstanceslist.message.kafkaNodeInfo" : "Nœud KAFKA",
    "cms.fullscreen.message.addThemeError" : "La mise en page n'est pas sauvegardée. Êtes-vous sûr de vouloir ajouter un sujet ?",
    "cms.agentMonitor.label.been_inspected" : "L'agent est inspecté. Réessayez plus tard.",
    "cms.agentmonitor.label.cancelfail" : "L'annulation a échoué.",
    "cms.report.message.noData" : "Pas de données disponibles.",
    "cms.indexmonitor.field.skillAbandonAfter10" : "Appels abandonnés après 10s",
    "cms.monitordetail.field.role" : "Rôle de l'agent",
    "cms.systemmonitor.field.cancel" : "Annuler",
    "cms.datasource.label.create" : "Ajouter",
    "cms.indexmonitor.message.skillAbandonAfterNDesc" : "Nombre de demandes d'appel abandonnées manuellement après Ns. Actuellement, Ns peut être 10s ou 60s.",
    "cms.voiceinspection.field.search" : "Requête",
    "cms.uapmonitor.label.registererror" : "La déconnexion a échoué",
    "cms.report.field.systemInSuccField" : "Appels entrants système réussis",
    "cms.indexmonitor.field.indicatorName" : "Indicateur",
    "cms.voiceinspection.field.callTime" : "Durée d'enregistrement",
    "cms.callcenterinstanceslist.field.ccBmsIp" : "Adresse IP CCBMS LB",
    "cms.report.field.auxCountField" : "Temps de repos",
    "cms.indexmonitor.message.outboundCallNum" : "Nombre d'appels sortants manuels + Nombre d'appels sortants SVI",
    "cms.vcallecenter.message.syncagentfail" : "Impossible de synchroniser l'agent.",
    "cms.developerlist.field.updateTime" : "Modifié à",
    "cms.callcenterinstanceslist.field.sysAdminPassword" : "Mot de passe administrateur CTI",
    "cms.report.field.outVdnAvgTimeField" : "Durée moyenne des appels sortants en VDN",
    "cms.calllink.msg.dateTooLate" : "La date sélectionnée ne peut pas être supérieure à {0}.",
    "cms.indexmonitor.message.skillConnectedDesc" : "Nombre d'appels connectés à des agents dans la file d'attente de compétences.",
    "cms.callcenterinstanceslist.label.search" : "Requête",
    "cms.uapmonitor.field.socketUninstall" : "désinstaller",
    "cms.report.message.threePartyCallCountDesc" : "Nombre d'appels à trois.",
    "cms.report.field.callLostOtherNum" : "Autres appels perdus",
    "cms.voiceinspection.field.queryEndTime" : "Heure de fin",
    "cms.callcenterinstanceslist.field.ccBcsIp" : "Adresse IP CCBCS LB",
    "cms.report.field.inAvgCallTime" : "Durée moyenne des appels",
    "cms.report.field.outOccupyNumField" : "Appels sortants",
    "cms.systemparam.error.validate.notmatchrulerequired" : "Obligatoire",
    "cms.agentmonitor.label.agentId" : "ID de l'agent",
    "cms.uapmonitor.message.eidvalidate" : "Entrez les paramètres comme demandé.",
    "cms.voiceinspection.field.serviceId" : "Type d'entreprise",
    "cms.fullscreen.field.second" : "(s)",
    "cms.report.field.netEntIdField" : "ID NE du serveur multimédia",
    "cms.report.field.description" : "Descriptif",
    "cms.reportsubscribe.message.searchSubscribeName" : "S'il vous plaît entrer le nom d'abonnement",
    "cms.qualitycheck.message.deleteinfo" : "Échec de la suppression",
    "cms.report.title.ivrOutboundMonitor" : "Surveillance des appels sortants IVR",
    "cms.report.message.obsServiceIdDesc" : "ID d'une campagne d'appel sortant.",
    "cms.agentMonitor.label.forcerelease_success" : "Un appel est relâché de force avec succès.",
    "cms.indexmonitor.message.skillOnlineWaitCallDesc" : "Nombre d'appels en file d'attente.",
    "cms.report.message.outFailNumDesc" : "Nombre total d'appels sortants échoués.",
    "cms.qcrelationconf.field.agentAccountName" : "Nom de la partie inspectée",
    "cms.report.field.failAnsRateField" : "Taux de perte de demandes",
    "cms.indexmonitor.field.skillConnectedIn10Rate" : "Appels connectés en 10s",
    "cms.indexmonitor.field.skillConnectedIn20Rate" : "Appels connectés en 20s",
    "cms.calllink.field.pickup" : "Un appel est pris par d'autres.",
    "cms.report.field.acwInCountField" : "Heures de récapitulation des appels entrants",
    "cms.agentMonitor.label.talking_error" : "L'agent inspecté est dans l'état de parler.",
    "cms.sysparam.config.reset.multiple.error" : "Les paramètres suivants ne peuvent pas être réinitialisés :",
    "cms.callcenterinstanceslist.field.dvDataReport" : "Rapports de données DigitalView",
    "cms.indexmonitor.message.inboundConnectNum" : "Nombre d'appels entrants manuels connectés + Nombre d'appels entrants IVR connectés",
    "cms.calllink.title.beginTime" : "Heure de début des statistiques",
    "cms.indexmonitor.label.callCenterNode" : "Nœud de centre d'appels",
    "cms.report.field.endWeek" : "Fin de la semaine",
    "cms.agentmonitor.label.adjust" : "Récapitulation",
    "cms.qcrelationconf.field.remark" : "Remarque",
    "cms.report.message.custHangNumCtiDesc" : "Nombre de clients raccrochés.",
    "cms.reportsubscribe.field.friday" : "Vendredi",
    "cms.indexmonitor.field.ivrOutboundNum" : "Appels sortants IVR connectés",
    "cms.report.field.avgAgentInCountField" : "Moyenne des appels entrants d'agents simultanés",
    "cms.report.field.assistTimeField" : "Durée de la demande d'aide interne",
    "cms.voiceinspection.title.tips" : "Informations",
    "cms.callcenterinstanceslist.label.nextStep" : "Suivant",
    "cms.indexmonitor.message.ivrDurationAvgConnect" : "Durée totale des appels RVI/Nombre d'appels RVI",
    "cms.report.title.vdnTrafficPeriodTrend" : "Tendance de la période de trafic VDN",
    "cms.developerlist.message.createfailed" : "Impossible de créer le développeur.",
    "cms.report.field.outCallSuccRate" : "Taux de connexion des appels sortants",
    "cms.report.title.callCenterMonitor" : "Surveillance des performances VDN",
    "cms.datasource.message.dmUrlTip" : "Chaîne de connexion à la base de données: &#8220jdbc:dm://ip:port/dbservicename&#8220&#10;ip (adresse IP) port (numéro de port) dbservicename (nom de la base de données)",
    "cms.callcenterinstanceslist.message.ivrNumsError" : "Le nombre de canaux IVR doit être supérieur à la somme du nombre de canaux TTS et du nombre de canaux ASR.",
    "cms.qcrelationconf.field.delete" : "Supprimer",
    "cms.reportsubscribe.message.reportNumberError" : "Tous les rapports ont été ajoutés",
    "cms.report.message.maxAgentCountDesc" : "Nombre maximal d'appels simultanés dont le type de périphérique de service est agent.",
    "cms.report.field.selectAgent" : "Sélectionner un agent",
    "cms.vdnconfiguration.message.updatefailed" : "La mise à jour a échoué.",
    "cms.agentmonitor.label.pageRefreshTime" : "Données mises à jour",
    "cms.fullscreen.field.report1" : "Rapport quotidien (heure fixe)",
    "cms.indexmonitor.field.confirm" : "Confirmer",
    "cms.report.message.occupancyRateDesc" : "Taux de durée du travail = (Durée totale du travail – Durée de repos)/Durée totale du travail x 100",
    "cms.fullscreen.field.report0" : "Rapport quotidien (heure relative)",
    "cms.fullscreen.message.publicTheme" : "(Publique)",
    "cms.report.message.avgInRingTimeDesc" : "Durée moyenne des appels entrants via les terminaux d'agent.",
    "cms.report.field.outCallSuccNum" : "Appels sortants manuels connectés",
    "cms.sendnote.field.ok" : "OK",
    "cms.report.field.minIVROutCountField" : "Minimum d'appels sortants IVR simultanés",
    "cms.synchronize.message.syncStatusOne" : "Synchronisation",
    "cms.datasource.message.isDeleteDatasource" : "Êtes-vous sûr de vouloir supprimer la source de données?",
    "cms.fullscreen.message.selectMessage" : "-Sélectionner-",
    "cms.report.field.systemInBound" : "Total des appels entrants du système",
    "cms.record-playback.callType0" : "Appel vocal entrant",
    "cms.fullscreen.field.moveup" : "Vers le haut",
    "cms.failurecall.placeholder.accessCodeInput" : "Entrez un code d'accès",
    "cms.developerlist.title.deleteconfim" : "Confirmer",
    "cms.callcenterinstanceslist.field.developerDesc" : "Descriptif",
    "cms.voiceinspection.field.callId" : "Enregistrement SN",
    "cms.agentMonitor.label.qcSipNum" : "Définition du numéro de connexion de l'utilisateur actuel",
    "cms.reportsubscribe.title.modifyConfim" : "Modifier confirmer",
    "cms.calllink.field.virtualDevice" : "Appareil virtuel",
    "cms.report.field.outAvgCallTimeField" : "Durée moyenne des appels sortants manuels",
    "cms.report.field.workRateField" : "Taux de durée du travail",
    "cms.report.field.avgAcdTimeField" : "Durée moyenne d'appel manuel",
    "cms.monitordetail.field.conferencecount" : "Appels à trois parties",
    "cms.report.field.callInCauseField" : "Raison d'entrée d'appel",
    "cms.tenant.message.tenantspaceOk" : "OK",
    "cms.report.message.callStartTimeDesc" : "Heure de début de l'appel.",
    "cms.calllink.field.intercepts" : "Un appel est intercepté.",
    "cms.agentMonitor.label.permission_error" : "Vous n'avez pas l'autorité.",
    "cms.qcrelationconf.field.reset" : "Réinitialiser",
    "cms.datasource.message.gaussUrlTip" : "Chaîne de connexion à la base de données: &#8220jdbc:zenith:@ip:port&#8220&#10;ip (adresse IP) port (numéro de port)",
    "cms.fullscreen.field.no" : "Non",
    "cms.indexmonitor.message.SkillAbandonRateDesc" : "Taux d'appels perdus = 100 – Taux d'appels connectés",
    "cms.report.message.callIdDesc" : "Numéro de séquence d'appel, qui identifie de manière unique un appel.",
    "cms.monitordetail.field.rest" : "Repos",
    "cms.report.field.outInvalidCallField" : "Appels sortants non valides",
    "cms.fullscreen.field.richtext" : "Texte riche",
    "cms.monitordetail.field.totaltalkingcount" : "Nombre total d'appels",
    "cms.synchronize.message.configured" : "Configuré",
    "cms.report.message.callEndDesc" : "Appeler l'heure de fin.",
    "cms.report.field.transferOutCount" : "Temps de transfert manuel",
    "cms.agentMonitor.label.updateSip_repeat" : "Le numéro a été attribué.",
    "cms.agentmonitor.label.terminalStatusRefresh" : "Statut de mise à jour",
    "cms.report.message.waitAnsRateDesc" : "Durée moyenne de sonnerie du terminal d'un agent. Durée moyenne de sonnerie des appels entrants manuels = Durée totale de sonnerie des appels entrants manuels/Nombre de temps de réponse.",
    "cms.recorddrive.field.configSftpAccount" : "Compte SFTP",
    "cms.indexmonitor.title.skillDayMonitor" : "Statistiques d'aujourd'hui sur les indicateurs de file d'attente de compétences",
    "cms.indexmonitor.title.skillQueueStatistics" : "Statistiques quotidiennes de la file d'attente de compétences",
    "cms.report.message.agentInSuccRateDesc" : "Pourcentage des appels entrants manuels connectés par rapport au total des appels entrants manuels.",
    "cms.report.field.inOccupyNum" : "Demandes de service",
    "cms.callcenterinstanceslist.title.chooseFeature" : "Sélection des fonctionnalités",
    "cms.report.field.otherTimeField" : "Durée totale des autres états",
    "cms.voiceinspection.message.downloadVoiceRecordFailed" : "Impossible de télécharger le fichier d'enregistrement à partir du serveur SFTP.",
    "cms.agent.message.duplicatepwd" : "Le nouveau mot de passe est le même que le mot de passe actuel.",
    "cms.sysparam.config.select.null" : "Veuillez sélectionner un paramètre.",
    "cms.agentmonitor.label.showAlarmContent" : "Contenu de la boîte de dialogue",
    "cms.report.message.utilizationRateDesc" : "Taux de durée de l'état de travail = (Durée totale de l'appel + Durée totale de la clôture)/(Durée totale du travail – Durée de repos) x 100",
    "cms.vcallcenter.message.unknowdesc" : "Exception inconnue.",
    "cms.report.field.outFailNumField" : "Total des appels sortants échoués",
    "cms.report.field.agentService" : "Appels répondus par l'agent",
    "cms.report.message.maxQueueWaitTimeDesc" : "Durée maximale d'attente (mise en file d'attente et sonnerie) de tous les appels.",
    "cms.report.message.calleeNoLength" : "Entrez accessCode au moins trois chiffres.",
    "cms.report.field.ivrtrafficreport.totalivrservice" : "Temps total de service d'IVR",
    "cms.report.field.workRate" : "Taux de durée du travail",
    "cms.indexmonitor.message.agentConnectedIn3NDesc" : "Nombre total d'appels dont la durée de réponse est supérieure ou égale à 3 secondes",
    "cms.report.title.skillAccessMonitor" : "Surveillance des statistiques sur les performances des files d'attente de compétences par code d'accès",
    "cms.indexmonitor.field.totalAnsweringNum" : "Agents en état de réponse",
    "cms.vdnconfiguration.title.error" : "Erreur",
    "cms.systemmonitor.field.confirm" : "Confirmer",
    "cms.systemmonitor.field.ofcNo" : "N° entrant.",
    "cms.skilladjustment.field.skillname" : "Nom de file d'attente de compétence",
    "cms.fullscreen.field.themeType" : "Type de thème",
    "cms.fullscreen.field.delete" : "Supprimer",
    "cms.indexmonitor.field.skillAbandonIn30Rate" : "Appels abandonnés en 30s",
    "cms.report.field.maxAgentInCountField" : "Max. appels entrants d'agent simultanés",
    "cms.indexmonitor.field.abandonInTen" : "Appels abandonnés en 10s",
    "cms.report.field.submediatype.line" : "LINE",
    "cms.fullscreen.field.halfHour" : "30 minutes",
    "cms.monitordetail.field.outboundcount" : "Appels sortants réussis",
    "cms.datasource.message.updatefailed" : "La mise à jour a échoué.",
    "cms.report.message.call3RateDesc" : "Pourcentage du nombre de demandes d'appels connectées manuellement en 15 secondes dans le nombre total d'appels demandant des services manuels.",
    "cms.indexmonitor.field.status" : "Statut",
    "cms.record-playback.voice.playing" : "Jouer",
    "cms.record-playback.voice.pause" : "Suspendre",
    "cms.report.message.monthLessThan" : "Le mois de fin d'année ne peut pas être antérieur au mois de début d'année.",
    "cms.agentMonitor.label.listen_success" : "L'écoute est réussie.",
    "cms.indexmonitor.field.totalRestNum" : "Agents en état de repos",
    "cms.fullscreen.field.nowMonitor" : "Surveillance en temps réel pendant la course",
    "cms.indexmonitor.field.agentOutboundNum" : "Appels sortants manuels",
    "cms.indexmonitor.message.callOutSuccNum" : "Nombre de fois qu'un agent connecte des appels sortants.",
    "cms.title.select" : "Sélectionner",
    "cms.report.field.avgAcwTimeField" : "Durée moyenne",
    "cms.report.field.outWaitAnsTimeField" : "Durée totale de sonnerie des appels sortants",
    "cms.callcenterinstanceslist.message.nodeIdCheckError" : "Assurez-vous que l'ID, l'uapId et le nom des informations de nœud CTI entrés sont uniques.",
    "cms.export.field.selectFileType" : "Sélectionner le format de fichier d'exportation",
    "cms.fullscreen.field.reportDates" : "Il y a {0} jours",
    "cms.agentmonitor.label.autoRefresh" : "Rafraîchissement automatique",
    "cms.export.message.popwindowmessage" : "Mot de passe pour générer un fichier compressé. La valeur est une chaîne de 8 à 12 caractères et doit contenir des lettres, des chiffres et des caractères spéciaux (à l'exclusion des espaces de {'|;&$-<>/'}{'\\\\'}{'`!#(){}'},, des caractères de nouvelle ligne et des caractères de tabulation).",
    "cms.report.field.minAgentOutCount" : "Minimum d'appels sortants d'agent simultanés",
    "cms.indexmonitor.field.agentConnectInTen" : "Appels connectés manuellement en 10s",
    "cms.report.field.callEndField" : "Heure de fin d'appel",
    "cms.monitordetail.field.noanswercount" : "Appels non répondus pendant une longue période",
    "cms.qcrelationconf.message.selectAgent" : "Veuillez sélectionner l'agent à supprimer",
  "cms.qcrelationconf.message.selectGroup": "Veuillez sélectionner le groupe d'utilisateurs à supprimer",
    "cms.report.message.uvidDesc" : "ID globalement unique attribué par le CCS à chaque utilisateur qui accède au centre d'appels.",
    "cms.systemmonitor.field.selectAgentName" : "Sélectionner un locataire",
    "cms.report.message.muteTimeDesc" : "Durée du silence.",
    "cms.report.field.userLevel" : "Niveau utilisateur",
    "cms.indexmonitor.label.noncommercial" : "Utilisation commerciale d'essai",
    "cms.report.title.vdnSystemPerformanceMonitor" : "Surveillance des performances du système",
    "cms.indexmonitor.field.totalAbandonedCallNum" : "Total des appels perdus",
    "cms.agent.message.modifypasswordfailed" : "Impossible de modifier le mot de passe.",
    "cms.agentmonitor.label.agentStatus" : "Statut de l'agent",
    "cms.report.message.outCallSuccNumDesc" : "Nombre d'appels sortants manuels connectés.",
    "cms.uapmonitor.field.callIpPort" : "IP et port",
    "cms.report.field.acdCalls" : "Nombre total d'appels connectés",
    "cms.calllink.field.ivrServiceProcess" : "Flux d'affaires IVR",
    "cms.report.field.perAgentNumField" : "Appels d'agents",
    "cms.indexmonitor.message.ivrInboundFlowOut" : "Nombre d'appels transférés vers un autre appareil après la lecture d'une annonce par l'IVR",
    "cms.calllink.field.routingTemporaryDevice" : "Dispositif de routage temporaire",
    "cms.sysparam.config.mod" : "Modifier",
    "cms.report.field.exceptionHangUp" : "Exception Raccrocher",
    "cms.export.field.zippwd" : "Mot de passe compressé",
    "cms.qualitycheck.message.error_errorinfo" : "L'enregistrement a échoué",
    "cms.synchronize.message.confPwdStatus" : "État du mot de passe de l'interface CCBCS",
    "cms.vcallecenter.title.modifyconfigurationinterfacepassword" : "Définir le mot de passe de l'interface CCBCS dans VDN",
    "cms.report.title.skillAllReport" : "Rapport de synthèse de trafic",
    "cms.report.field.outOccupyNumDesc" : "Nombre de fois qu'un agent connecte des appels sortants.",
    "cms.report.field.agentServiceRateField" : "Taux de succès manuel dans le total des appels entrants",
    "cms.report.title.IVRAccessTrend" : "Tendance des données IVR par code d'accès",
    "cms.indexmonitor.message.ivrInCallCount" : "Nombre d'appels entrants dont le dispositif de service actuel est un RVI.",
    "cms.report.field.avgAgentOutCount" : "Moyenne des appels sortants de l'agent simultané",
    "cms.indexmonitor.field.allType" : "Tous les types",
    "cms.monitordetail.field.restduration" : "Durée de repos",
    "cms.indexmonitor.message.skillAgentAbandonDesc" : "Nombre d'appels bloqués de manière proactive par les agents pendant la sonnerie",
    "cms.report.field.minAgentCountField" : "Min. Appels d'agents simultanés",
    "cms.report.field.outWaitAnsTime" : "Durée totale de sonnerie des appels sortants",
    "cms.indexmonitor.field.skillAvgConnectedAck" : "Durée moyenne de sonnerie des appels connectés",
    "cms.report.field.talkingTimeField" : "Temps de conversation d'appel",
    "cms.indexmonitor.message.skillSystemLostDesc" : "Nombre d'interruptions de plate-forme causées par des raisons telles que le délai de réponse de l'agent",
    "cms.agentMonitor.label.updateSip_error" : "Le numéro est incorrectement écrit.",
    "cms.report.field.avgAnsTime" : "Durée moyenne de mise en file d'attente des appels connectés",
    "cms.monitordetail.field.information" : "Informations de base",
    "cms.voiceinspection.message.frequentExport" : "Exportation trop fréquente. Veuillez réessayer plus tard.",
    "cms.report.field.logoutCountField" : "Heures de signature",
    "cms.export.message.exceedNumberLimit" : "Volume de données excessif. Affinez la plage de temps des données ou ajoutez des critères de filtre de données.",
    "cms.report.field.logoutCount" : "Heures de signature",
    "cms.vcallecenter.message.syncSuccess" : "Les centres d'appels virtuels sont synchronisés avec succès.",
    "cms.report.field.userRefusedFailNumField" : "Appels perdus en raison du rejet d'appels",
    "cms.report.message.callSuccRateDesc" : "Pourcentage du nombre de demandes d'appels entrants manuels connectés dans le nombre d'appels entrants manuels demandant des services.",
    "cms.report.field.agentServiceField" : "Appels répondus par l'agent",
    "cms.report.field.inCallNumField" : "Appels entrants",
    "cms.callcenter.message.sameletterovermaxnum" : "Un maximum de huit enregistrements peuvent être ajoutés à la même lettre de lecteur.",
    "cms.monitordetail.field.detail" : "Détails de l'agent",
    "cms.report.field.sysLostCallsField" : "Échec des appels entrants système",
    "cms.qualitycheck.label.viewusergroup" : "Voir UserGroup",
    "cms.report.field.avgAgentCount" : "Avg. Appels d'agents simultanés",
    "cms.monitordetail.field.holdduration" : "Durée d'exploitation",
    "cms.indexmonitor.field.skillTimeoutAgent" : "Appels de délai d'attente aux agents",
    "cms.report.message.acwInCountDesc" : "Nombre de fois qu'un agent entre dans l'état de clôture après la fin des appels entrants.",
    "cms.qcrelationconf.message.isDeleteGroupInfo" : "Veuillez confirmer si vous souhaitez supprimer le groupe d'utilisateurs",
    "cms.calllink.title.logDate" : "Statistiques Temps",
    "cms.voiceinspection.field.ordinaryVoiceCall" : "Voix",
    "cms.calllink.msg.callerPlaceHolder" : "Entrez le numéro d'appel",
    "cms.fullscreen.field.avgCallInTime" : "Durée moyenne des appels entrants manuels.",
    "cms.report.title.IVRAccessReport" : "Rapport de trafic SVI par code d’accès",
    "cms.datasource.field.test" : "Tester la connexion",
    "cms.developerlist.message.checkInput" : "La valeur ne peut pas contenir de caractères spéciaux.",
    "cms.calllink.field.skillQueue" : "File d'attente de compétences",
    "cms.callcenterinstanceslist.message.createFailed" : "Impossible de créer le centre d'appels.",
    "cms.failurecall.field.queueName" : "Nom de la file d'attente",
    "cms.report.message.workRateDesc" : "Taux de durée du travail = (Durée totale du travail – Durée de repos)/Durée totale du travail x 100",
    "cms.fullscreen.field.setIndicatorThreshold" : "Définir les seuils d'indicateur",
    "cms.calldetail.msg.timeTooLate" : "L'heure sélectionnée ne peut pas être supérieure à {0}.",
    "cms.report.message.selectAgent" : "Sélectionnez au moins un ID d'agent.",
    "cms.report.field.startYear" : "Année de début",
    "cms.report.field.userRefusedFailNum" : "Appels perdus en raison du rejet d'appels",
    "cms.indexmonitor.title.agentDayMonitor" : "Statistiques d'aujourd'hui sur les indicateurs d'agent",
    "cms.vcallecenter.message.validatefailed" : "Impossible de vérifier le mot de passe.",
    "cms.recorddrive.label.button_add" : "Nouveau",
    "cms.agentMonitor.label.forceidle_repeat" : "L'agent s'est montré inactif. Ne plus afficher l'inactivité.",
    "cms.indexmonitor.field.skillSystemLost" : "Interruptions de plate-forme",
    "cms.callcenter.field.savefailed" : "Erreur",
    "cms.fullscreen.field.auxCountTimeDesc" : "Nombre de fois où les agents sont en état de repos.",
    "cms.callcenter.message.selectccgatewayservers" : "Sélectionnez un serveur CC-Gateway.",
    "cms.report.message.acdTimeRateDesc" : "Taux de durée d'appel manuel = Durée d'appel manuel/Durée totale de travail x 100",
    "cms.qcrelationconf.title.confirm" : "Confirmer",
    "cms.report.message.startTimeLessThanEndTime" : "L'heure de début ne peut pas être postérieure à l'heure de fin",
    "cms.synchronize.message.invalidConfPwd" : "Mot de passe incorrect. Modifiez le mot de passe de l'interface de configuration.",
    "cms.report.message.inCallNumDesc" : "Nombre total d'appels entrants dans le VDN. La valeur est la somme des appels entrants manuels et des appels entrants effectués automatiquement à l'aide de l'IVR.",
    "cms.mediaType9" : "Tableau blanc électronique",
    "cms.mediaType8" : "Appel vidéo IP (H.323)",
    "cms.fullscreen.message.addTheme" : "Ajouter un sujet.",
    "cms.mediaType7" : "Appel fax",
    "cms.mediaType6" : "Appel par e-mail",
    "cms.indexmonitor.message.skillAbandonDesc" : "Nombre de demandes d'appel entrant manuelles perdues = Nombre de demandes d'appel entrant manuelles – Nombre de demandes d'appel entrant manuelles connectées",
    "cms.callcenterinstanceslist.field.uapUserName" : "UAP CDE Nom d'utilisateur",
    "cms.datasource.message.mysql5UrlTip" : "Chaîne de connexion à la base de données: &#8220jdbc:mysql://ip:port/schema&#8220&#10;ip (adresse IP) port (numéro de port) schéma (mode)",
    "cms.mediaType5" : "Appel vocal commun",
    "cms.sysparam.config.select.prompt" : "Astuces",
    "cms.mediaType4" : "Appel de demande de rappel",
    "cms.mediaType3" : "Navigation accompagnée et partage de formulaires",
    "cms.indexmonitor.field.inboundConnectNum" : "Appels entrants connectés",
    "cms.mediaType2" : "Cliquer pour composer",
    "cms.indexmonitor.field.skillQueueDisconnected" : "Mettre en file d'attente les appels déconnectés",
    "cms.mediaType1" : "Chat texte",
    "cms.monitordetail.field.busydurationavg" : "Durée moyenne d'occupation",
    "cms.interfaceperflog.message.modifyFail" : "Échec de la modification",
    "cms.report.field.otherTimeRate" : "Autre taux de durée",
    "cms.vcallecenter.field.enternewpassword" : "Entrez un nouveau mot de passe",
    "cms.report.field.unspecifiedSkillId" : "Non spécifié",
    "cms.calllind.field.deviceQueue" : "File d'attente de compétences",
    "cms.report.field.callLostOtherNumField" : "Autres appels perdus",
    "cms.report.field.IVRCallOutSuccNumField" : "Appels sortants IVR connectés",
    "cms.report.field.abortQueueWaitNum" : "Appels de file d'attente abandonnés",
    "cms.report.field.busyTime" : "Durée totale d'occupation",
    "cms.callcenterinstanceslist.field.gwIp" : "CC-Gateway IP",
    "cms.indexmonitor.message.warningFilterMax" : "Un maximum de {0} indicateurs peut être sélectionné.",
    "cms.callcenterinstanceslist.field.kafkaPasswordConfirm" : "Confirmer le mot de passe KAFKA",
    "cms.report.field.IVROutSuccRate" : "Taux de connexion des appels sortants Pure-IVR",
    "cms.callcenterinstanceslist.message.specialStrValidateFailed" : "Le nom du centre d'appel ne peut pas contenir de caractères spéciaux.",
    "cms.callcenterinstanceslist.message.jobSwitch" : "Activer la synchronisation automatique",
    "cms.report.message.outCallSuccRateDesc" : "Pourcentage des appels sortants connectés par rapport au total des appels sortants.",
    "cms.indexmonitor.field.durationAvgVdnInbound" : "Durée moyenne des appels entrants",
    "cms.developer.message.modifyDeveloper" : "Mettre à jour les informations du développeur",
    "cms.reportsubscribe.field.sentPeriod" : "Période envoyée",
    "cms.report.message.IVRInSuccRateDesc" : "Pourcentage d'appels entrants RVI connectés par rapport au nombre total d'appels entrants RVI.",
    "cms.report.field.minAgentInCount" : "Appels entrants d'agent simultanés min",
    "cms.report.field.avgIVRServiceField" : "Durée(s) moyenne(s) des appels entrants IVR",
    "cms.systemmonitor.field.systemInSucc" : "Appels connectés au système",
    "cms.record-playback.callType1" : "Appel vocal sortant",
    "cms.report.field.inCallSuccNumberField" : "Appels entrants connectés",
    "cms.indexmonitor.field.skillConnected" : "Demandes d'appels connectés",
    "cms.report.message.noAckNumDesc" : "Nombre d'appels sans réponse après avoir été attribués aux agents.",
    "cms.fullscreen.title.editChart" : "Modifier le graphique",
    "cms.report.field.auxTime" : "Durée totale de repos",
    "cms.monitordetail.field.outerforward" : "Appels transférés en externe",
    "cms.reportsubscribe.field.reportName" : "Nom du rapport",
    "cms.report.field.tableHeader" : "En-tête de tableau",
    "cms.indexmonitor.message.queryCallCenterError" : "Une exception s'est produite lors de l'interrogation du centre d'appels sélectionné.",
    "cms.systemparam.error.validate.inner" : "Échec de la validation du paramètre",
    "cms.fullscreen.field.IVR" : "IVR",
    "cms.report.field.IVRCallOutSuccRateField" : "Taux de connexion des appels sortants IVR",
    "cms.report.message.busyTimeDesc" : "Durée totale lorsqu'un agent est en état occupé.",
    "cms.datasource.message.addfailed" : "L'ajout a échoué.",
    "cms.indexmonitor.message.outboundConnectRate" : "Pourcentage du nombre d'appels sortants connectés dans le nombre d'appels sortants.",
    "cms.indexmonitor.message.outboundAbandonRate": "Le pourcentage du nombre d'appels sortants qui ne sont pas connectés au nombre d'appels sortants. Si le nombre d'appels sortants est 0, la valeur de l'indicateur est 0.",
    "cms.indexmonitor.message.skillQueueDisconnectedDesc" : "Nombre de fois que les appels sont déconnectés par la plate-forme CTI lorsque les appels sont en file d'attente",
    "cms.report.message.otherHangNumCtiDesc" : "Nombre de blocages du système.",
    "cms.report.field.avgIVROutCountField" : "Moyenne des appels sortants IVR simultanés",
    "cms.reportsubscribe.field.selectedReport" : "Liste des rapports sélectionnés",
    "cms.report.message.lessThanError" : "L'ID de l'agent final ne peut pas être inférieur à l'ID de l'agent de démarrage.",
    "cms.report.message.holdTimeDesc" : "Durée totale lorsqu'un agent est en état d'attente d'appel. La durée d'appel d'un agent comprend le temps d'attente d'appel.",
    "cms.report.field.agentOffered" : "Appels entrants manuels",
    "cms.voiceinspection.field.beginTime" : "Heure de début de l'enregistrement",
    "cms.report.title.skillReport" : "Rapport de SVI d’appels sortants",
    "cms.report.field.occupancyRateField" : "Taux de durée du travail",
    "cms.indexmonitor.message.agentAbandonIn30NDesc" : "Nombre total d'abandons dont la durée de réponse est supérieure ou égale à 30 secondes",
    "cms.export.message.downloadFailed" : "Impossible de télécharger le rapport.",
    "cms.report.message.overNumReason" : "Les agents multimédias et les agents polyvalents peuvent traiter des appels multicanaux en même temps.",
    "cms.recorddrive.field.sftpPort" : "Port No.",
    "cms.callcenterinstanceslist.message.odfConfigError" : "Les informations de configuration du service ODFS sont incorrectes.",
    "cms.uapmonitor.labe.mentiontitle" : "Note:",
    "cms.callcenterinstanceslist.message.syncPassValidateFailed" : "La valeur doit contenir des lettres majuscules, des lettres minuscules, des chiffres et des caractères spéciaux `~!@#$%^&*()-_=+|[{}];: ', <?",
    "cms.callcenterinstanceslist.field.developer" : "Développeur associé",
    "cms.callcenterinstanceslist.message.passValidateContinuityFailed" : "Le mot de passe ne peut pas contenir trois caractères identiques consécutifs.",
    "cms.report.field.acwInCount" : "Heures de récapitulation des appels entrants",
    "cms.qualitycheck.label.qcRelationConfig" : "Les relations d'inspection",
    "cms.calllink.field.timeoutTransfer" : "Un appel est transféré en raison d'un timeout.",
    "cms.systemparam.success.reset" : "La réinitialisation des paramètres a réussi",
    "cms.indexmonitor.message.skillAvgConnectedAckDesc" : "Durée moyenne de sonnerie de tous les appels connectés dans une file d'attente",
    "cms.skilladjustment.message.tipsSuccess" : "La file d'attente de compétences est ajustée avec succès.",
    "cms.report.title.agentOperationReport" : "Rapport d'opérations de l'agent",
    "cms.callcenterinstanceslist.field.kafkaPort" : "Port de KAFKA",
    "cms.agentmonitor.label.cancellisteningOrInsert" : "Annuler l'écoute ou l'insertion",
    "cms.report.field.acdTime" : "Durée de l'appel manuel",
    "cms.report.message.stateDesc" : "Etat de connexion, comprend connecté et non connecté.",
    "cms.indexmonitor.message.agentConnectedIn30NDesc" : "Nombre total d'appels dont la durée de réponse est supérieure ou égale à 30 secondes",
    "cms.fullscreen.field.fontSize" : "Taille",
    "cms.indexmonitor.field.skillOnlineConnected" : "Nombre d'appels auxquels ont répondu les agents dans le bloc actuel de 5 minutes",
    "cms.agentmonitor.label.readjustmentState" : "Ajustement",
    "cms.indexmonitor.field.agentBoundAbandonRate" : "Appels entrants manuels abandonnés",
    "cms.report.field.ans20sField" : "Taux de connexion d'appels manuels en 20s",
    "cms.indexmonitor.field.durationAvgConnect" : "Durée moyenne d'appel",
    "cms.agentMonitor.label.talkingerror" : "L'agent sélectionné n'est pas dans une conversation ou est dans une conversation multimédia.",
    "cms.callcenter.field.finish" : "Complète",
    "cms.report.field.inWaitAnsNum" : "Appels entrants manuels",
    "cms.report.field.outCallSuccNumField" : "Appels sortants manuels connectés",
    "cms.report.message.outWaitAnsTimeDesc" : "Durée totale de sonnerie des appels sortants.",
    "cms.report.field.userNoAnswerNumField" : "Appels perdus en raison de la sonnerie Timeout",
    "cms.report.field.outCallSuccNumberField" : "Appels sortants connectés",
    "cms.report.field.sysLostCalls" : "Échec des appels entrants système",
    "cms.qcrelationconf.placeholder.groupType" : "veuillez sélectionner",
    "cms.report.field.otherTime" : "Durée totale des autres États",
    "cms.report.field.hangUpModeField" : "Méthode Raccrocher",
    "cms.indexmonitor.message.updateFailed" : "La mise à jour a échoué",
    "cms.systemmonitor.label.CommercialUse" : "Utilisation commerciale",
    "cms.report.field.acwInTime" : "Durée de récapitulation des appels entrants",
    "cms.fullscreen.message.warningSelectOne" : "Sélectionnez un seul indicateur pour le réglage du seuil.",
    "cms.callcenterinstanceslist.message.uapNodeInfo" : "Nœud UAP",
    "cms.calllink.field.threePartyHelp" : "L'appel est utilisé pour demander l'aide de trois parties.",
    "cms.voiceinspection.field.agentId" : "Agent",
    "cms.calllink.field.MELCASDevice" : "Dispositif MELCAS",
    "cms.calllink.field.businessRepresentative" : "Agent",
    "cms.report.field.availTime" : "Durée d'inactivité",
    "cms.report.message.deviceNoDesc" : "Indique le numéro d'appareil de l'appareil qui traite l'appel.",
    "cms.calllink.title.callId" : "ID d'appel",
    "cms.fullscreen.field.reportDate" : "Il y a 1 jour",
    "cms.report.title.agentOperationTrend" : "Détails de l'opération de connexion de l'agent Tendance",
    "cms.indexmonitor.field.skillConnectedIn30Rate" : "Appels connectés en 30s",
    "cms.agentmonitor.label.agent" : "Agent",
    "cms.vcallecenter.field.enterwaspassword" : "Mot de passe",
    "cms.indexmonitor.message.agentOutboundNum" : "Nombre d'appels sortants manuels.",
    "cms.report.message.uapIdDesc" : "ID de l'UAP qui relie les appels à la plate-forme du centre d'appels.",
    "cms.agentMonitor.label.cancelwhisper_success" : "Whisper annulé avec succès.",
    "cms.report.field.outCallSuccNumber" : "Appels sortants connectés",
    "cms.reportsubscribe.field.publish" : "Publier",
    "cms.fullscreen.field.play" : "Jouer",
    "cms.report.field.date" : "Date",
    "cms.report.message.holdCountDesc" : "Nombre de temps d'attente.",
    "cms.skilladjustment.message.tipsNotMonitor" : "Pas un spécialiste de l'AQ. Impossible d'ajuster la file d'attente de compétences.",
    "cms.calldetail.msg.callIdInvalid" : "Le format est incorrect",
    "cms.report.message.avgQueueOutCountDesc" : "Nombre moyen d'appels sortants simultanés dont le type de périphérique de service est une file d'attente de compétences.",
    "cms.report.field.succAnsRateField" : "Taux de connexion des demandes",
    "cms.tenant.message.adminpassword" : "Mot de passe",
    "cms.voiceinspection.field.rate" : "Taux de lecture",
    "cms.agentmonitor.label.agentName" : "Nom de l'agent",
    "cms.fullscreen.field.oneHour" : "1 heure",
    "cms.report.field.occupancyRate" : "Taux de durée du travail",
    "cms.report.field.totalLIVRServiceField" : "Temps de service total de RVI",
    "cms.report.message.acwOutTimeDesc" : "Durée de récapitulation après la fin des appels sortants.",
    "cms.report.field.avgIVRCount" : "Moyenne des appels IVR simultanés",
    "cms.report.field.custHangNumCti" : "Accrochages clients",
    "cms.callcenterinstanceslist.field.nodemodepool" : "Mode pool",
    "cms.report.field.IVRSuccRate" : "Taux de connexion des appels entrants IVR",
    "cms.report.field.inCallFailNumField" : "Demandes d'appels perdus",
    "cms.report.field.month" : "Mois",
    "cms.indexmonitor.message.skillMinQueueAckDesc" : "Durée minimale d'attente (mise en file d'attente et sonnerie) de tous les appels",
    "cms.agentMonitor.message.operaFailed" : "L'opération a échoué. Une inspection ou un appel est en cours.",
    "cms.report.field.outCallRateField" : "Taux de connexion des appels sortants",
    "cms.indexmonitor.message.skillOverQueueDesc" : "Nombre d'appels transférés de la file d'attente de compétences d'origine vers d'autres files d'attente de compétences en raison d'un débordement",
    "cms.report.message.IVROutSuccNumDesc" : "Nombre d'appels sortants RVI connectés dont la durée d'occupation du dispositif RVI est supérieure à 0.",
    "cms.indexmonitor.message.abandonInSixty" : "Pourcentage du nombre d'appels abandonnés dans les années 60 dans le nombre total d'appels abandonnés.",
    "cms.indexmonitor.field.ivrInboundFlowOut" : "Appels entrants IVR transférés",
    "cms.report.message.call6RateDesc" : "Pourcentage du nombre de demandes d'appels connectées manuellement en 30 secondes dans le nombre total d'appels demandant des services manuels.",
    "cms.agentMonitor.label.intercept_success" : "Réussir à intercepter",
    "cms.indexmonitor.message.ivrOutCallCount" : "Nombre d'appels sortants dont le dispositif de service actuel est un RVI.",
    "cms.report.title.skillAllTrend" : "Tendance du résumé de la file d'attente de compétences",
    "cms.callcenterinstanceslist.message.ccNameUpdateError" : "Ne changez pas le nom du centre d'appels lors de l'édition du centre d'appels",
    "cms.report.field.callAbandonedInRingField" : "Appels abandonnés en anneau",
    "cms.report.message.IVRSuccRateDesc" : "Pourcentage du nombre d'appels entrants RVI connectés dans le nombre de demandes d'appels entrants RVI.",
    "cms.report.title.agentReport" : "Rapport de synthèse sur le trafic",
    "cms.callcenterinstanceslist.field.ccId" : "ID du centre d'appels",
    "cms.report.message.failAvgTimeDesc" : "Durée moyenne de sonnerie de tous les appels sans réponse.",
    "cms.export.field.operation" : "Opérer",
    "cms.indexmonitor.field.agentInCallCount" : "Nombre d'appels entrants de l'agent en ligne",
    "cms.indexmonitor.message.durationAvgConnect" : "Durée totale des appels connectés/Nombre total des appels connectés",
    "cms.fullscreen.field.bar" : "Diagramme à barres",
    "cms.agentmonitor.label.switch" : "commutateur",
    "cms.qualitycheck.field.groupName" : "Groupe d'utilisateurs",
    "cms.agentMonitor.label.nosignin_error" : "L'agent inspecté n'a pas signé.",
    "cms.report.message.otherTimeRateDesc" : "Autre taux de durée = (Durée totale des autres états/Durée totale du travail) x 100",
    "cms.report.field.calledHangUp" : "Appelé Raccrocher",
    "cms.recorddrive.placeholder.batchdeleteconfim" : "Êtes-vous sûr de supprimer toutes les lettres de lecteur sélectionnées?",
    "cms.callcenterinstanceslist.title.callcenter" : "Centre d'appels",
    "cms.report.message.acwCountDesc" : "Nombre de fois qu'un agent entre dans l'état de clôture.",
    "cms.callcenterinstanceslist.label.callCenterRecordDrive" : "Lettre de lecteur d'enregistrement de centre d'appels",
    "cms.qualitycheck.placeholder.userGroupName" : "Entrez un groupe d'utilisateurs.",
    "cms.synchronize.message.syncStatusThree" : "Échec de la synchronisation",
    "cms.datasource.message.masterSalveRelationExist" : "La source de données a été utilisée comme source de données de sauvegarde.",
    "cms.indexmonitor.message.totalLoginNum" : "Nombre d'agents qui se sont connectés au centre d'appels",
    "cms.fullscreen.field.maxThreshold" : "Seuil maximum",
    "cms.report.field.inAnswerRateField" : "Taux de connexion des appels entrants",
    "cms.qcrelationconf.field.agentGroupName" : "Équipe d'inspection",
    "cms.reportsubscribe.message.deleteConfim" : "Êtes-vous sûr de supprimer?",
    "cms.report.message.outVdnAvgTimeDesc" : "Durée moyenne des appels sortants dans le VDN. La valeur est la durée totale des appels sortants divisée par le nombre d'appels sortants.",
    "cms.indexmonitor.message.abandonInThree" : "Pourcentage du nombre d'appels abandonnés en moins de 3s dans le nombre total d'appels abandonnés.",
    "cms.report.field.IVRInSuccField" : "Appels entrants IVR connectés",
    "cms.systemmonitor.field.vdnName" : "Locataire",
    "cms.report.field.reportType" : "Type de rapport",
    "cms.agentmonitor.label.noListenedAndInserted" : "Non inspecté",
    "cms.fullscreen.message.enterMessage" : "Entrer",
    "cms.report.field.acwOutTimeField" : "Durée de clôture de l'appel sortant",
    "cms.qcrelationconf.field.qualityInspection" : "Contrôleur de qualité",
    "cms.indexmonitor.message.agentConnectIn3Rate" : "Pourcentage du nombre d'appels connectés manuellement dans un délai de 3 secondes (dont 3 secondes) par rapport au nombre total d'appels.",
    "cms.report.field.avgIVROutCount" : "Moyenne des appels sortants IVR simultanés",
    "cms.voiceinspection.field.video" : "Vidéo",
    "cms.voiceinspection.field.reset" : "Réinitialiser",
    "cms.report.field.internalTransferCountField" : "Transferts internes",
    "cms.fullscreen.field.table" : "Tableau",
    "cms.report.message.acwTimeDesc" : "Durée totale lorsqu'un agent est dans l'état de clôture.",
    "cms.report.field.outCallNum" : "Appels sortants",
    "cms.report.field.failAvgTime" : "Durée moyenne de la sonnerie des appels perdus",
    "cms.report.message.preDeviceTypeDesc" : "Type d'appareil du dernier appareil que l'appel a traversé.",
    "cms.report.field.muteCountField" : "Temps de silence",
    "cms.report.field.ivrtrafficreport.statdate" : "Date",
    "cms.sysparam.config.title" : "Paramètres du système",
    "cms.reportsubscribe.field.wednesday" : "Mercredi",
    "cms.indexmonitor.field.skillAvgAbandonAck" : "Durée moyenne de sonnerie des appels perdus",
    "cms.report.message.agentHangNumCtiDesc" : "Nombre d'agents raccrochés.",
    "cms.sysparam.config.itemname" : "Nom du paramètre",
    "cms.tenant.message.adminpasswordagain" : "Confirmer le mot de passe",
    "cms.systemmonitor.label.customizes" : "Personnalisation",
    "cms.systemparam.success.save" : "Paramètre enregistré avec succès",
    "cms.callcenterinstanceslist.title.baseInfo" : "Informations de base",
    "cms.voiceinspection.field.clickToCall" : "Cliquer pour composer la voix",
    "cms.report.field.deviceTypeField" : "Type d'appareil",
    "cms.indexmonitor.field.agentInboundConnectNum" : "Appels entrants manuels connectés",
    "cms.reportsubscribe.field.basicInfo" : "Informations de base",
    "cms.uapmonitor.field.socketConnecting" : "connexion",
    "cms.callcenterinstanceslist.title.sourceconfiguration" : "Paramètres de la source de données",
    "cms.report.message.callLostOtherNumDesc" : "Nombre d'appels qui ne reçoivent pas de réponse pour des raisons autres que les lignes occupées, le rejet d'appels et le délai d'expiration de la sonnerie.",
    "cms.interfaceperflog.message.dvSwitch" : "Activer le reporting des données",
    "cms.report.message.acdTimeDesc" : "Durée totale de l'appel d'un agent, à l'exclusion de la durée du chat textuel.",
    "cms.calllink.field.call" : "Appeler",
    "cms.qcrelationconf.field.groupName" : "Nom du groupe d'utilisateurs",
    "cms.systemmonitor.field.uapIP" : "Adresse IP UAP",
    "cms.agentmonitor.label.terminalStatusFix" : "État de restauration",
    "cms.indexmonitor.message.agentConnectedIn5NDesc" : "Nombre total d'appels dont la durée de réponse est supérieure ou égale à 5 secondes",
    "cms.indexmonitor.message.skillTimeoutAgentDesc" : "Nombre d'appels transférés de la file d'attente de compétences d'origine à d'autres agents en raison du délai de mise en file d'attente",
    "cms.reportsubscribe.message.searchTemplateName" : "Veuillez entrer le nom du modèle",
    "cms.report.message.tooManyExport" : "Trop d'exports {0} en peu de temps.",
    "cms.report.field.maxIVRCount" : "Max. Appels IVR simultanés",
    "cms.report.field.IVRServiceRate" : "Taux de réussite des appels entrants Pure-IVR",
    "cms.fullscreen.field.virtualcallcenter" : "VDN",
    "cms.systemparam.error.save" : "L'enregistrement des paramètres a échoué",
    "cms.callcenterinstanceslist.message.uapNode1Info" : "Nœud UAP 1",
    "cms.indexmonitor.message.abandonInTen" : "Pourcentage du nombre d'appels abandonnés en moins de 10s dans le nombre total d'appels abandonnés.",
    "cms.systemparam.error.validate.notmatchruleurl" : "Veuillez entrer une adresse URL valide",
    "cms.callcenterinstanceslist.message.datasourceInfo" : "Terminer la configuration de la source de données.",
    "cms.report.field.workTime" : "Durée totale du travail",
    "cms.vcallecenter.title.modifyadministratorpassword" : "Mot de passe administrateur CTI",
    "cms.callcenterinstanceslist.message.alreadyInuse" : "Le segment d'identification de l'employé a été utilisé.",
    "cms.calllink.field.callType9" : "Appel relayé",
    "cms.report.message.maxQueueOutCountDesc" : "Nombre maximal d'appels sortants simultanés dont le type de périphérique de service est une file d'attente de compétences.",
    "cms.indexmonitor.field.skillLostRing" : "Appels sans réponse",
    "cms.calllink.field.callType8" : "Appel entrant (agent international)",
    "cms.calllink.field.callType7" : "Appel auxiliaire",
    "cms.report.title.vdnTrafficPeriodMonitor" : "Surveillance de la période de trafic VDN",
    "cms.calllink.field.callType6" : "Appel réseau sortant interne à un agent virtuel",
    "cms.report.field.otherHangNumCtiField" : "Systèmes Hang-Ups",
    "cms.calllink.field.callType5" : "Appel de réseau entrant interne à un agent virtuel",
    "cms.calllink.field.callType4" : "Appel d'inspection entrant du centre d'appels réseau",
    "cms.report.message.inWaitAnsNumDesc" : "Nombre de demandes d'appel entrant pour un service manuel.",
    "cms.calllink.field.callType3" : "Appel sortant du centre d'appels réseau",
    "cms.indexmonitor.message.totalRestNum" : "Nombre d'agents en état de repos",
    "cms.calllink.field.callType2" : "Appel entrant du centre d'appels réseau",
    "cms.calllink.field.callType1" : "Appel sortant IVR PRI",
    "cms.calllink.field.callType0" : "Appel sortant d'un agent téléphonique",
    "cms.monitordetail.field.innerforward" : "Appels transférés en interne",
    "cms.indexmonitor.title.vdnDayTrend" : "Tendance d'aujourd'hui du VDN",
    "cms.indexmonitor.field.queueOutCallCount" : "Nombre d'appels sortants en file d'attente en ligne",
    "cms.indexmonitor.title.selectAgentName" : "Sélectionner un locataire",
    "cms.datasource.field.ok" : "OK",
    "cms.calllink.field.transferOut" : "Un appel est transféré.",
    "cms.fullscreen.field.avgCallTime" : "Durée moyenne d'appel",
    "cms.report.message.agentAlertingDurationDesc" : "Durée(s) de l'alerte de l'appelant.",
    "cms.monitordetail.field.busyDuration" : "Durée(s) occupée(s)",
    "cms.report.field.submediatype.twitter" : "X (Twitter)",
    "cms.calllind.field.deviceVnr" : "Appareil virtuel",
    "cms.indexmonitor.message.warningFiltering" : "Sélectionnez au moins un indicateur",
    "cms.monitordetail.title.statistics" : "Statistiques",
    "cms.qcrelationconf.field.outgoingAgent" : "Agent de callout",
    "cms.report.title.abandonRingTrend" : "Tendance des appels abandonnés pendant la sonnerie",
    "cms.indexmonitor.message.agentConnectAfterInTen" : "Pourcentage du nombre d'appels connectés manuellement après 10s dans le nombre total d'appels connectés manuellement.",
    "cms.monitordetail.field.totaltalkingduration" : "Durée totale de l'appel",
    "cms.report.message.skillIdDesc" : "ID de la file d'attente de compétences à laquelle l'appel appartient.",
    "cms.monitordetail.field.audio" : "Audio/Vidéo",
    "cms.fullscreen.message.themeDuplicate" : "Nom en double.",
    "cms.sysparam.config.refresh" : "Actualiser",
    "cms.report.field.transferIvrCount" : "Temps de transfert manuel-à-IVR",
    "cms.report.field.outAvgCallTime" : "Durée moyenne des appels sortants manuels",
    "cms.datasource.field.dbUsername" : "Nom d'utilisateur",
    "cms.indexmonitor.title.ivrNowMonitor" : "Surveillance en cours d'exécution en temps réel de l'IVR",
    "cms.indexmonitor.field.skillRequest" : "Demandes de service",
    "cms.datasource.field.no" : "Non",
    "cms.calllink.title.endTime" : "Heure de fin des statistiques",
    "cms.calllink.title.deviceType" : "Type d'appareil",
    "cms.callcenterinstanceslist.field.tenantSpaceName" : "Compte Administrateur",
    "cms.agentMonitor.label.forcebusy_repeat" : "L'agent s'est montré occupé. Ne plus afficher occupé.",
    "cms.callcenterinstanceslist.field.vccId" : "ID du centre d'appel virtuel",
    "cms.indexmonitor.message.agentAbandonIn60Rate" : "Pourcentage d'appels abandonnés dans les 60 secondes par rapport au nombre total d'appels abandonnés",
    "cms.agentmonitor.label.cancelwhisper" : "Annuler Whispering",
    "cms.indexmonitor.message.skillConnectedInNDesc" : "Nombre de demandes d'appels manuellement connectées en Ns. Actuellement, Ns peut être 3s, 5s, 10s, 20s, 30s, ou 60s.",
    "cms.indexmonitor.field.skillConnectedIn5Rate" : "Appels connectés en 5s",
    "cms.report.field.startTime" : "Date de début",
    "cms.report.field.acwRate" : "Taux de durée de récapitulation",
    "cms.indexmonitor.field.currentSelectedSkill" : "File d'attente de compétences sélectionnée",
    "cms.agentMonitor.label.querynum_error" : "Impossible de mettre à jour le numéro de connexion.",
    "cms.report.message.queryErrorInfo" : "La requête a échoué.",
    "cms.report.field.skillName" : "Nom de file d'attente de compétence",
    "cms.report.field.outCallSuccRateField" : "Taux de connexion des appels sortants",
    "cms.report.field.loginCount" : "Heures de connexion",
    "cms.callcenterinstanceslist.field.uapCmdPort" : "Port de commande UAP MML",
    "cms.indexmonitor.field.skillMinQueueAck" : "Durée(s) d'attente minimale(s)",
    "cms.report.field.enterReasonField" : "Indique la raison de l'entrée dans l'appareil",
    "cms.reportsubscribe.field.subscriptionList" : "Liste des abonnements",
    "cms.report.field.callIdField" : "Numéro de séquence d'appel",
    "cms.report.field.IVRSuccRateField" : "Taux de connexion des appels entrants IVR",
    "cms.qcrelationconf.message.paramOutOfRange" : "L'entrée ne peut pas dépasser 100 caractères",
    "cms.monitordetail.field.busy" : "Occupé",
    "cms.fullscreen.message.maxThemeNum" : "Un maximum de 20 thèmes privés et 20 thèmes publics peuvent être ajoutés.",
    "cms.report.message.releaseCauseDesc" : "Appeler Raison de libération.",
    "cms.report.message.perAgentNumDesc" : "Le nombre d'appels entrants attribués à chaque agent pendant la période sélectionnée.",
    "cms.indexmonitor.message.abandonInThirty" : "Pourcentage du nombre d'appels abandonnés dans la trentaine par rapport au nombre total d'appels abandonnés.",
    "cms.qualitycheck.field.unselected" : "UnSelected",
    "cms.report.title.ivrOutboundTrend" : "Tendance des appels sortants IVR",
    "cms.monitordetail.field.holdcount" : "Appels tenus",
    "cms.report.field.failQueueWaitRateField" : "Durée moyenne de sonnerie des appels perdus",
    "cms.monitordetail.field.duration" : "Statistiques sur la durée",
    "cms.indexmonitor.field.skillConnectedAfter60" : "Appels connectés après les années 60",
    "cms.qualitycheck.label.trafficGroup" : "Groupe de trafic",
    "cms.report.message.avgIVRServiceDesc" : "Durée moyenne des appels entrants de RVI = durée des appels entrants de RVI/nombre d'appels entrants de RVI connectés.",
    "cms.indexmonitor.message.totalCallNumDesc" : "Nombre total d'appels connectés par un agent.",
    "cms.report.field.IVRCallOutNumField" : "Appels sortants IVR",
    "cms.uapmonitor.labe.inspectorcheckinpwd" : "Mot de passe de connexion de l'inspecteur QC",
    "cms.fullscreen.title.deleteconfim" : "Confirmer",
    "cms.indexmonitor.field.totalBusyNum" : "Agents en état occupé",
    "cms.recorddrive.title.sftpServerConfig" : "Configuration du serveur SFTP",
    "cms.uapmonitor.labe.eid" : "Segment de numéro de terminal",
    "cms.report.field.manToSkillNum" : "Transferts de file d'attente manuel-compétence",
    "cms.systemparam.success.refresh" : "Paramètre actualisé avec succès",
    "cms.report.message.agentOutSuccDesc" : "Nombre d'appels sortants manuels connectés aux utilisateurs, c'est-à-dire le nombre d'appels sortants manuels dont la durée d'appel est supérieure à 0.",
    "cms.callcenterinstanceslist.field.operate" : "Opération",
    "cms.report.field.maxQueueInCount" : "Appels entrants en file d'attente de compétences simultanés max",
    "cms.indexmonitor.message.agentConnectInTen" : "Pourcentage du nombre d'appels connectés manuellement en moins de 10s dans le nombre total d'appels connectés manuellement.",
    "cms.report.field.call4Rate" : "Taux de connexion d'appel entrant manuel en 20s",
    "cms.report.message.enterReasonDesc" : "Indique la raison de l'entrée dans l'appareil.",
    "cms.report.message.avgHandleTimeDesc" : "Durée moyenne de traitement = (Durée d'appels manuels + Durée totale de clôture)/Nombre d'appels manuels réussis",
    "cms.agentmonitor.label.total" : "Total des agents",
  "cms.agentmonitor.label.syncScreenMonitor": "Écran de surveillance",
    "cms.indexmonitor.field.inboundAbandonRate" : "Appels entrants abandonnés",
    "cms.report.title.IVRReport" : "Rapport de trafic SVI",
    "cms.report.field.outWaitAnsNum" : "Appels sortants manuels",
    "cms.agentMonitor.label.Idle_error" : "L'inspecteur n'est pas en état d'inactivité.",
    "cms.report.field.preDeviceTypeField" : "Type de périphérique précédent",
    "cms.recorddrive.placeholder.singledeleteconfim" : "Êtes-vous sûr de supprimer la lettre de lecteur sélectionnée?",
    "cms.vcallecenter.title.modifyinfo2" : "Configurer le mot de passe",
    "cms.export.field.download" : "Téléchargement",
    "cms.report.field.selectOrg" : "Sélectionner OU",
    "cms.report.field.maxQueueWaitTimeField" : "Durée(s) d'attente max",
    "cms.voiceinspection.message.invalidQcRelation" : "La relation d'inspection ne correspond pas.",
    "cms.qualitycheck.label.input" : "S'il vous plaît entrer",
    "cms.report.field.acwOutCountField" : "Heures de récapitulation des appels sortants",
    "cms.report.message.outInvalidCallDesc" : "Nombre d'appels sortants qui ne peuvent pas atteindre les clients en raison de numéros invalides ou de mise hors tension.",
    "cms.report.field.userNoAnswerNum" : "Appels perdus en raison de la sonnerie Timeout",
    "cms.report.field.agentOfferedField" : "Appels entrants manuels",
    "cms.monitordetail.field.intercallcount" : "Appels internes",
    "cms.datasource.field.dbPwd" : "Mot de passe",
    "cms.report.field.holdCountField" : "Temps de maintien",
    "cms.indexmonitor.message.agentBoundConnectRate" : "Pourcentage du nombre d'appels connectés manuellement dans le nombre d'appels entrants manuels.",
    "cms.indexmonitor.field.skillConnectedIn60" : "Appels connectés en années 60",
    "cms.indexmonitor.field.ivrInboundNum" : "Appels entrants IVR connectés",
    "cms.uapmonitor.field.deregister" : "Êtes-vous sûr de vouloir annuler l'enregistrement de l'appareil ?",
    "cms.indexmonitor.field.totalTalkingNum" : "Agents en appel",
    "cms.callcenterinstanceslist.title.deleteconfim" : "Confirmer la suppression",
    "cms.agentmonitor.label.forceSignOut" : "Forcer la sortie",
    "cms.callcenterinstanceslist.message.updateSuccess" : "Le centre d'appels est modifié avec succès.",
    "cms.report.message.locationIdDesc" : "ID du nœud distribué auquel appartient l'appel.",
    "cms.systemmonitor.message.dategt31days" : "La plage de dates ne peut excéder 31 jours.",
    "cms.agentMonitor.label.forceidle_error" : "Impossible de placer de force un agent à l'état inactif.",
    "cms.report.message.outCallNumDesc" : "Nombre d'appels sortants manuels et automatiques dans le VDN. La valeur est la somme des appels sortants manuels et des appels sortants effectués automatiquement à l'aide du RVI.",
    "cms.indexmonitor.title.selectCcName" : "Sélectionnez le centre d'appels",
    "cms.qcrelationconf.field.accountName" : "Nom de l'agent",
    "cms.indexmonitor.message.agentAbandonIn5Rate" : "Pourcentage d'appels abandonnés dans les 5 secondes par rapport au nombre total d'appels abandonnés",
    "cms.report.field.minQueueCount" : "Min. Appels simultanés de file d'attente de compétences",
    "cms.report.title.agentOutCallReport" : "Rapport d'appels sortants de l'agent",
    "cms.report.field.submediatype.5gmessage" : "5G RCS",
    "cms.report.field.inCallSuccNumber" : "Appels entrants connectés",
    "cms.voiceinspection.message.frequentPlay" : "Le jeu est trop fréquent. Réessayez plus tard.",
    "cms.calllink.field.outOfService" : "Le téléphone est hors service.",
    "cms.report.field.byWeek" : "Rapport hebdomadaire",
    "cms.indexmonitor.field.abandonInFive" : "Appels abandonnés en 5s",
    "cms.report.field.IVRInSuccRate" : "Taux de connexion des appels entrants IVR",
    "cms.callcenterinstanceslist.field.backupcc" : "Centre d'appels DR",
    "cms.report.message.callBeginDesc" : "Heure de début de l'appel.",
    "cms.report.message.failAnsRateDesc" : "Taux de perte de demandes = 100 – Taux de connexion des appels",
    "cms.indexmonitor.message.agentInCallCount" : "Nombre d'appels entrants connectés. (N'inclut pas les sessions multimédias)",
    "cms.vcallecenter.field.curcountpassword" : "Mot de passe du compte actuel",
    "cms.report.field.alertingDurationField" : "Durée(s) d'alerte de l'appelé",
    "cms.agentmonitor.label.showAlarmCallId" : "ID d'appel",
    "cms.report.message.waitCauseDesc" : "Indique la raison pour laquelle un appel est en attente sur l'appareil actuel.",
    "cms.qcrelationconf.placeholder.workName" : "Saisissez un nom d'agent",
    "cms.fullscreen.field.themeTypeY" : "Thèmes publics",
    "cms.report.message.currentSkillIdDesc" : "ID de la file d'attente de compétences qui traite les appels.",
    "cms.callcenterinstanceslist.field.uapIp" : "Adresse IP UAP NMU",
    "cms.report.message.startOrEndTimeNoExist" : "Si vous sélectionnez un segment temporel pour la requête, l'heure de début et l'heure de fin doivent exister en même temps.",
    "cms.systemmonitor.field.systemCallRate" : "Taux de connexion d'appels système",
    "cms.qualitycheck.label.delete" : "Supprimer",
    "cms.report.field.talkDurationField" : "Durée(s) de conversation",
    "cms.indexmonitor.field.skillAvgAbandonQueue" : "Durée moyenne de mise en file d'attente des appels perdus",
    "cms.indexmonitor.message.inboundAbandonRate" : "Le pourcentage du nombre d'appels entrants qui n'étaient pas connectés au nombre d'appels entrants. Si le nombre d'appels entrants est 0, la valeur de l'indicateur est 0.",
    "cms.callcenterinstanceslist.message.ccgatewayServerBatchdeleteconfim" : "Voulez -vous vraiment supprimer tous les serveurs CC-Gateway sélectionnés ?",
    "cms.fullscreen.field.themeTypeN" : "Thèmes privés",
    "cms.systemmonitor.title.tips" : "Informations",
    "cms.fullscreen.message.deletechart" : "Souhaitez-vous confirmer la suppression de ce graphique ?",
    "cms.report.field.maxQueueOutCount" : "Appels sortants en file d'attente de compétences simultanées max",
    "cms.report.title.IVRTrend" : "Tendance des données IVR",
    "cms.agentMonitor.label.forcebusy_error" : "Impossible de placer de force un agent à l'état occupé.",
    "cms.systemparam.error.validate.notmatchruleregex" : "Le paramètre d'entrée ne correspond pas à l'expression rationnelle de la règle de vérification",
    "cms.report.message.createTimeDesc" : "Créer un temps",
    "cms.report.title.changeChart" : "Passer au graphique",
    "cms.indexmonitor.message.totalIdeaNum" : "Nombre d'agents qui sont en état d'inactivité et peuvent traiter les appels immédiatement",
    "cms.calllink.field.helperRelease" : "Un appel est déconnecté par la partie qui envoie la demande d'aide interne.",
    "cms.voiceinspection.field.phoneVideo" : "Appel vidéo commun",
    "cms.report.message.minQueueOutCountDesc" : "Nombre minimal d'appels sortants simultanés dont le type de périphérique de service est une file d'attente de compétences.",
    "cms.report.field.callNoPlaceholder" : "Numéro d'appel ou d'appel",
    "cms.indexmonitor.title.skillNowMonitor" : "Statistiques de surveillance en temps réel sur la file d'attente de compétences pendant l'exécution",
    "cms.indexmonitor.field.skillConnectedAfter10" : "Appels connectés après 10s",
    "cms.report.message.dategt31days" : "La plage de dates ne peut excéder 31 jours.",
    "cms.developerlist.label.refreshsk" : "Mettre à jour SK",
    "cms.callcenterinstanceslist.label.recordDrive" : "Lettre de lecteur d'enregistrement",
    "cms.fullscreen.title.newTheme" : "Créer un sujet",
    "cms.fullscreen.field.edit" : "Modifier",
  "cms.fullscreen.style.dark": "foncé",
  "cms.fullscreen.style.light": "Couleur claire",
  "cms.fullscreen.style.title": "Style",
  "cms.systemmonitor.label.threehours" : "3 Heures",
    "cms.export.field.viewExportTask" : "Exporter la vue des tâches",
    "cms.indexmonitor.field.skillConnectedIn30" : "Appels connectés en 30s",
    "cms.fullscreen.message.chooseTheme" : "La mise en page n'est pas sauvegardée. Êtes-vous sûr de vouloir changer de sujet ?",
    "cms.report.field.minQueueCountField" : "Min. Appels simultanés de file d'attente de compétences",
    "cms.callcenterinstanceslist.message.ccIdUpdateError" : "Ne changez pas l'ID du centre d'appels lors de la modification du centre d'appels",
    "cms.report.field.agentInSucc" : "Appels entrants manuels connectés",
    "cms.report.field.waitAnsRateField" : "Durée moyenne de sonnerie des appels entrants manuels",
    "cms.report.field.outVdnAvgTime" : "Durée moyenne des appels sortants en VDN",
    "cms.report.field.inAvgRingTime" : "Durée moyenne de sonnerie des appels entrants manuels",
    "cms.indexmonitor.field.skillMaxQueueAck" : "Durée(s) d'attente max",
    "cms.indexmonitor.title.vdnDayMonitor" : "Statistiques d'aujourd'hui sur les indicateurs VDN",
    "cms.report.field.maxAgentCount" : "Max. Appels d'agents simultanés",
    "cms.report.field.ivrtrafficreport.ivrshortcalls" : "Nombre d'appels courts d'IVR (dans 3s)",
    "cms.report.field.currentSkillIdField" : "ID de file d'attente de compétence",
    "cms.skilladjustment.field.skilltype" : "Type de file d'attente de compétences",
    "cms.export.field.exportiong" : "Exportation",
    "cms.fullscreen.message.selectAgent" : "L'OU et l'agent ne peuvent pas être vides.",
    "cms.export.message.parainvalid" : "Impossible de vérifier les conditions d'exportation.",
    "cms.report.field.submediatype.web" : "Web",
    "cms.systemmonitor.field.rateNum" : "Appels connectés au système",
    "cms.fullscreen.field.screenreSolution" : "Résolution",
    "cms.report.message.weekLessThan" : "La semaine de fin d'année ne peut pas être inférieure à la semaine de début d'année.",
    "cms.synchronize.message.adminPwdStatus" : "Statut du mot de passe de l'administrateur CTI",
    "cms.fullscreen.message.numberFirst" : "La valeur maximale est 7680.",
    "cms.qcrelationconf.message.isDeleteGroupMember" : "Veuillez confirmer si vous souhaitez supprimer le membre du groupe",
    "cms.indexmonitor.message.durationAvgVdnOutbound" : "Durée totale pendant laquelle les appels sortants occupent le VDN/Nombre total d'appels sortants",
    "cms.report.message.agentInSuccDesc" : "Nombre d'appels entrants manuels connectés à des agents, c'est-à-dire le nombre d'appels entrants manuels dont la durée d'appel est supérieure à 0.",
    "cms.agentMonitor.label.cancelListening_error" : "Impossible d'annuler l'écoute.",
    "cms.indexmonitor.message.ivrInboundUserAbort" : "Nombre d'appels bloqués de manière proactive par un utilisateur dans l'IVR",
    "cms.reportsubscribe.title.tips" : "Informations",
    "cms.fullscreen.field.FangSong" : "FangSong",
    "cms.datasource.message.deletesuccess" : "Suppression réussie.",
    "cms.systemmonitor.label.reset" : "Réinitialiser",
    "cms.report.message.minQueueCountDesc" : "Nombre minimal d'appels simultanés dont le type de périphérique de service est une file d'attente de compétences.",
    "cms.agentMonitor.label.beinrest_error" : "L'agent inspecté est en état de repos.",
    "cms.systemparam.error.refresh" : "Échec de l'actualisation des paramètres",
    "cms.report.field.IVRInBound" : "Appels entrants IVR",
    "cms.indexmonitor.field.skillAbandonIn60" : "Appels abandonnés dans les années 60",
    "cms.report.message.auxTimeRateDesc" : "Taux de durée de repos = (Durée totale de repos/Durée totale de travail) x 100",
    "cms.indexmonitor.message.totalLogoutNum" : "Nombre d'agents qui ne se sont pas connectés au centre d'appels et qui sont hors ligne",
    "cms.indexmonitor.field.skillConnectedIn10" : "Appels connectés en 10s",
    "cms.indexmonitor.field.skillConnectedIn20" : "Appels connectés en 20s",
    "cms.agent.message.modifypasswordsuccess" : "Le mot de passe est changé avec succès.",
    "cms.report.field.busyTimeRate" : "Taux de durée occupé",
    "cms.callcenterinstanceslist.title.callCenter" : "Centre d'appels",
    "cms.indexmonitor.field.totalAgentNum" : "Total des agents",
    "cms.report.field.acwInTimeField" : "Durée de clôture de l'appel entrant",
    "cms.report.message.inAnswerRateDesc" : "Pourcentage du nombre d'appels entrants manuels connectés par rapport au nombre d'appels entrants manuels.",
    "cms.indexmonitor.message.skillConnectedRateDesc" : "Pourcentage du nombre de demandes d'appels entrants manuels connectés dans le nombre de demandes d'appels entrants manuels",
    "cms.callcenterinstanceslist.message.workNoCheckError" : "Le numéro de début de l'ID de travail doit être plus petit que le numéro de fin.",
    "cms.qcrelationconf.field.qcGroupName" : "Groupe des inspecteurs",
    "cms.report.message.avgPerHNumDesc" : "Nombre moyen d'appels réussis par heure dans le segment de temps de travail sélectionné.",
    "cms.fullscreen.field.text" : "Texte",
    "cms.report.field.leaveReasonField" : "Raison de quitter l'appareil",
    "cms.calllink.field.hold" : "Un appel est retenu.",
    "cms.uapmonitor.field.callSize" : "Type de terminal",
    "cms.report.message.voicedataOverError" : "Le nombre de fichiers exportés dépasse 0. Veuillez resélectionner les critères de recherche.",
    "cms.agentMonitor.label.forcesignout_success" : "Un agent signe avec succès.",
    "cms.report.title.skillTrend" : "Tendance des performances en file d'attente de compétences",
    "cms.callcenterinstanceslist.field.tenantName" : "Nom du locataire",
    "cms.report.message.exportSucceed" : "Export réussi.",
    "cms.report.title.calldetailoriginal" : "Détails du contenu de l'appel",
    "cms.report.field.skillQueue" : "File d'attente de compétences",
    "cms.report.message.IVRShortCallsDesc" : "Nombre d'appels dont la durée IVR est supérieure à 0 et inférieure ou égale à 3 secondes.",
    "cms.agentmonitor.label.cancellistening" : "Annuler l'écoute",
    "cms.indexmonitor.field.skillAbandonIn5" : "Appels abandonnés en 5s",
    "cms.systemparam.error.validate.notmatchruledigits" : "Veuillez entrer un entier positif valide",
    "cms.fullscreen.field.minThreshold" : "Seuil minimum",
    "cms.indexmonitor.field.skillAbandonIn3" : "Appels abandonnés en 3s",
    "cms.report.message.selectAgentMax" : "Un maximum de 100 ID d'agent peuvent être sélectionnés.",
    "cms.monitordetail.field.answerCount" : "Appels répondus",
    "cms.qcrelationconf.field.ok" : "OK",
    "cms.monitordetail.field.restoutcount" : "Restes de délai d'expiration",
    "cms.indexmonitor.message.agentAbandonIn10Rate" : "Pourcentage d'appels abandonnés dans les 10 secondes par rapport au nombre total d'appels abandonnés",
    "cms.qcrelationconf.field.configAdmin" : "Administrateur de configuration",
    "cms.report.field.endMonth" : "Fin du mois",
    "cms.report.field.call3RateField" : "Taux de connexion d'appel entrant manuel en 15s",
    "cms.report.message.maxIVROutCountDesc" : "Nombre maximal d'appels sortants simultanés dont le type de périphérique de service est IVR.",
    "cms.qualitycheck.label.userGroup" : "Groupe d'utilisateurs",
    "cms.indexmonitor.message.ivrTotalCallNum" : "Nombre d'appels qui sont attribués à l'IVR et desservis par l'IVR complètement",
    "cms.callcenterinstanceslist.field.uapPassword" : "Mot de passe UAP CDE",
    "cms.fullscreen.field.refreshPeriod" : "Période de rafraîchissement",
    "cms.systemmonitor.field.callRate" : "Taux de connexion d'appels système",
    "cms.report.field.billInfo2Field" : "ID unique d'un double appel",
    "cms.voiceinspection.field.preViewOutbound" : "Prévisualiser les sorties",
    "cms.report.field.uapIdField" : "ID UAP",
    "cms.vcallecenter.resetpwd.message.fail" : "Impossible de réinitialiser le mot de passe.",
    "cms.report.message.mediaTypeDesc" : "Type de média d'appel.",
    "cms.report.message.invalidParam" : "Param non valide.",
    "cms.report.message.avgQueueCountDesc" : "Nombre moyen d'appels simultanés dont le type de périphérique de service est une file d'attente de compétences.",
    "cms.report.field.deviceNoField" : "No de l'appareil",
    "cms.callcenterinstanceslist.message.maxNumber" : "Un maximum de 10 centres d'appels peuvent être ajoutés.",
    "cms.agentMonitor.laber.notInTalking" : "L'agent n'est pas dans un appel. Actualisez l'état et réessayez.",
    "cms.report.field.avgAgentInCount" : "Moyenne des appels entrants d'agents simultanés",
    "cms.report.field.maxQueueWaitTime" : "Max. Durée d'attente",
    "cms.callcenter.message.deletefailed" : "La suppression a échoué.",
    "cms.report.field.minIVRInCountField" : "Min. Appels entrants IVR simultanés",
    "cms.developerlist.message.deleteConfirmMessage" : "Êtes-vous sûr de vouloir supprimer les développeurs sélectionnés et les configurations associées?",
    "cms.report.message.outAboundRingDesc" : "Nombre d'appels sortants qui sont rejetés par les clients ou qui ne reçoivent pas de réponse après la sonnerie.",
    "cms.report.title.filteringHeader" : "En-tête de table de filtre",
    "cms.export.field.unprocessed" : "Non-traité",
    "cms.vcallecenter.message.syncLoading" : "Opération en cours. Veuillez réessayer plus tard.",
    "cms.report.field.agentAlertingDurationField" : "Durée(s) d'alerte de l'appelant",
    "cms.report.message.acdCallDesc" : "Nombre d'appels entrants et sortants connectés.",
    "cms.indexmonitor.field.skillAbandonIn30" : "Appels abandonnés en 30s",
    "cms.report.message.outCallSuccNumberDesc" : "Nombre d'appels sortants automatiques et manuels auxquels les appelés répondent. La valeur est la somme des appels sortants manuels connectés et des appels sortants connectés automatiquement à l'aide de l'IVR.",
    "cms.fullscreen.field.custom" : "Personnalisation :",
    "cms.calllink.field.calledBusy" : "La fête appelée est occupée.",
    "cms.report.message.agentServiceDesc" : "Nombre d'appels répondus avec succès par les agents.",
    "cms.sysparam.config.save" : "Enregistrer",
    "cms.report.field.outCallNumField" : "Appels sortants",
    "cms.report.field.holdTime" : "Durée de maintien",
    "cms.calllink.field.unavailableNumber" : "Le numéro n'est pas attribué.",
    "cms.fullscreen.field.saveTheme" : "Enregistrer la mise en page",
    "cms.recorddrive.label.button_edit" : "Modifier",
    "cms.indexmonitor.field.agentOutboundConnectNum" : "Appels sortants manuels connectés",
    "cms.indexmonitor.field.totalQueueCallNum" : "Total des appels en file d'attente",
    "cms.fullscreen.field.chartName" : "Nom du graphique",
    "cms.report.message.totalLIVRServiceDesc" : "Durée totale de l'appel IVR. Si un appel est connecté au RVI plusieurs fois, la durée du service RVI est la durée totale de l'appel RVI.",
    "cms.sendnote.title.selectAgents" : "Agent sélectionné",
    "cms.report.message.IVRCallOutSuccNumDesc" : "Nombre d'appels sortants IVR dont la durée est supérieure à 0.",
    "cms.synchronize.message.syncStatusTwo" : "Synchronisation réussie.",
    "cms.report.field.holdCount" : "Temps de maintien",
    "cms.vcallecenter.label.inputnewpwd" : "Nouveau mot de passe",
    "cms.systemmonitor.field.talkingNums" : "Agents parlants",
    "cms.indexmonitor.field.agentNum" : "Nombre d'agents",
    "cms.indexmonitor.field.totalLogoutNum" : "Agents de déconnexion",
    "cms.vcallecenter.title.resetpwd" : "Réinitialiser le mot de passe",
    "cms.recorddrive.label.button_cacel" : "Annuler",
    "cms.indexmonitor.message.agentAbandonIn3NDesc" : "Nombre total d'abandons avec une durée de réponse supérieure ou égale à 3 secondes",
    "cms.indexmonitor.field.ivrInboundUserAbort" : "Appels entrants IVR publiés",
    "cms.indexmonitor.field.agentConnectInThirty" : "Appels connectés manuellement en 30s",
    "cms.report.field.submediatype.facebook" : "Facebook",
    "cms.indexmonitor.field.skillAbandonIn10" : "Appels abandonnés en 10s",
    "cms.indexmonitor.field.skillAbandonIn20" : "Appels abandonnés en 20s",
    "cms.indexmonitor.field.skillAbandonIn3Rate" : "Appels abandonnés dans 3s",
    "cms.calllink.field.doNotAnswer" : "L'abonné ne répond pas à un appel.",
    "cms.datasource.message.mysql8UrlTip" : "Chaîne de connexion à la base de données: &#8220jdbc:mysql://ip:port/schema&#8220&#10;ip (adresse IP) port (numéro de port) schéma (mode)",
    "cms.failurecall.message.person" : "appels annulés pendant la file d'attente.",
    "cms.callcenterinstanceslist.message.deleteConfirmMessage" : "Êtes-vous sûr de vouloir supprimer le centre d'appels sélectionné et la configuration associée ?",
    "cms.monitordetail.field.restcount" : "Repos",
    "cms.callcenterinstanceslist.field.ccGatewayIp" : "CC-Gateway IP",
    "cms.vcallecenter.message.syncrolefail" : "Impossible de synchroniser le rôle.",
    "cms.callcenterinstanceslist.field.interfacePassword" : "Mot de passe ConfigInterface (CCBCS)",
    "cms.report.field.IVRInSuccRateField" : "Taux de connexion des appels entrants IVR",
    "cms.fullscreen.field.idleCountTimeDesc" : "Nombre de fois où les agents sont en état d'inactivité.",
    "cms.uapmonitor.labe.mention" : "1. Une fois que la fonction de surveillance est activée, les agents dont l'état d'enregistrement du terminal n'est pas conforme à l'état de connexion de l'agent sont déconnectés de force périodiquement,Seuls les agents qui se sont connectés aux files d'attente de comp\\u00E9tences vocales et vid\\u00E9o peuvent \\u00EAtre déconnect\\u00E9s de force..",
    "cms.report.field.internalCallTimeField" : "Durée de l'appel interne",
    "cms.report.field.filetype" : "Format de fichier d'exportation",
    "cms.report.field.outSuccAnsRateField" : "Taux de connexion des appels sortants",
    "cms.vcallecenter.label.tenantaccount" : "Compte Administrateur",
    "cms.report.field.IVROutCallNum" : "Appels sortants Pure-IVR",
    "cms.report.field.endAgentId" : "ID de l'agent final",
    "cms.calllink.title.callTrack" : "Suivi d'appel",
    "cms.qualitycheck.label.editusergroupmember" : "Modifier le membre du groupe",
    "cms.reportsubscribe.field.tuesday" : "Mardi",
    "cms.callcenter.message.createsuccess" : "Créé avec succès.",
    "cms.agentmonitor.label.alarmDetail" : "Détail",
    "cms.agentmonitor.label.mutilMediaMonitor": "Surveillance des sessions multimédia",
    "cms.agentmonitor.label.stopMutilMediaMonitor": "Arrêter la surveillance de la session",
    "cms.callcenterinstanceslist.message.voiceCallNumsError" : "Le nombre maximal d'appels vocaux simultanés doit être supérieur ou égal à la somme du nombre de sièges vocaux et du nombre de canaux RVI.",
    "cms.calllink.field.noAnswerTransfer" : "Un appel est transféré parce qu'aucune réponse n'est reçue d'un agent pendant une longue période.",
    "cms.calllink.title.callTrace" : "Lien d'appel",
    "cms.report.field.utilizationRate" : "État de travail Durée Taux",
    "cms.report.message.monthRangeCheck" : "L'heure de début et l'heure de fin doivent être dans le même mois",
    "cms.agentmonitor.label.cancelsuccess" : "Annulation réussie.",
    "cms.uapmonitor.message.intervalvalidate" : "Entrez un nombre allant de 300 à 86400.",
    "cms.qualitycheck.label.edit" : "Modifier",
    "cms.agentMonitor.label.forcesignout_error" : "Impossible de se déconnecter en tant qu'agent.",
    "cms.report.field.auxTimeRateField" : "Taux de durée de repos",
    "cms.qualitycheck.label.save" : "Enregistrer",
    "cms.qcrelationconf.field.qcAccountName" : "Nom de l'inspecteur",
    "cms.report.field.year" : "année",
    "cms.systemparam.error.validate.ruleregexinvalid" : "Valider une erreur de syntaxe de règle",
    "cms.indexmonitor.field.skillConnectedRate" : "Demandes d'appels connectés",
    "cms.fullscreen.field.avgCallTimeDesc" : "Durée moyenne des appels répondus par les agents.",
    "cms.report.field.minIVRCountField" : "Minimum d'appels IVR simultanés",
    "cms.report.message.ans20sDesc" : "Pourcentage du nombre d'appels connectés manuellement en 20s dans le nombre total d'appels connectés manuellement.",
    "cms.failurecall.field.waitTime" : "Durée d'attente",
    "cms.export.message.downloadSucceed" : "Le rapport est téléchargé avec succès.",
    "cms.report.field.callEndTimeField" : "Heure de fin d'appel",
    "cms.qcrelationconf.field.addGroupMember" : "Ajouter un membre du groupe",
    "cms.report.message.assistTimeDesc" : "Durée des demandes d'aide interne.",
    "cms.indexmonitor.field.agentName" : "Nom du locataire",
    "cms.systemmonitor.label.thirtyminutes" : "30 minutes",
    "cms.synchronize.message.syncStatus" : "État de synchronisation",
    "cms.report.field.hourandminute" : "Temps",
    "cms.report.field.acwRateField" : "Taux de durée de clôture",
    "cms.agentMonitor.label.querynum_success" : "Numéro de téléphone:",
    "cms.callcenterinstanceslist.message.passWordLengthValidateFailed" : "Le mot de passe doit contenir de 8 à 20 caractères.",
    "cms.report.message.IVRInSuccDesc" : "Nombre d'appels entrants IVR dont la durée d'appel est supérieure à 0.",
    "cms.indexmonitor.message.skillAvgQueueAckDesc" : "Durée moyenne d'attente des appels Durée moyenne d'attente (mise en file d'attente et sonnerie) de tous les appels.",
    "cms.report.message.minQueueInCountDesc" : "Nombre minimal d'appels entrants simultanés dont le type de périphérique de service est une file d'attente de compétences.",
    "cms.indexmonitor.message.agentAbandonIn60NDesc" : "Nombre total d'abandons dont la durée de réponse est supérieure ou égale à 60 secondes",
    "cms.indexmonitor.field.skillOverAgent" : "Appels de débordement vers les agents",
    "cms.report.label.header" : "Définir l'en-tête du tableau",
    "cms.report.field.outWaitAnsNumField" : "Appels sortants manuels",
    "cms.report.field.accessNo" : "Code d'accès:",
    "cms.report.field.maxIVRCountField" : "Max. Appels IVR simultanés",
    "cms.agentmonitor.label.insets" : "Insérer",
    "cms.agentmonitor.label.enterID" : "Entrez un ID d'agent",
    "cms.report.title.saveError" : "Erreur",
    "cms.report.field.internalCallCountField" : "Appels internes",
    "cms.qcrelationconf.field.workNo" : "ID de l'agent",
    "cms.report.field.acwTime" : "Durée totale de récapitulation",
    "cms.report.field.maxIVROutCountField" : "Max. appels sortants IVR simultanés",
    "cms.report.field.inOccupyNumField" : "Demandes de service",
    "cms.report.field.waitBeginField" : "Attendre l'heure de début",
    "cms.callcenter.message.createfailed" : "La création a échoué.",
    "cms.report.field.call4RateField" : "Taux de connexion d'appel entrant manuel en 20s",
    "cms.agentmonitor.label.intercept" : "intercepter",
    "cms.report.message.hangUpModeDesc" : "Méthode Raccrocher.",
    "cms.sendnote.message.tipsError" : "Impossible d'envoyer une note.",
    "cms.indexmonitor.message.agentConnectedIn60NDesc" : "Nombre total d'appels dont la durée de réponse est supérieure ou égale à 60 secondes",
    "cms.reportsubscribe.field.templateName" : "Nom du modèle de courriel",
    "cms.report.field.succWaitAnsRate" : "Durée moyenne d'attente",
    "cms.agentMonitor.label.cancelInsert_success" : "L'insertion est annulée avec succès.",
    "cms.agentmonitor.label.header" : "Définir l'en-tête du tableau",
    "cms.report.message.validateFailed" : "Impossible de vérifier l'exportation du rapport.",
    "cms.report.message.outAvgAnsTimeDesc" : "Durée moyenne de sonnerie des appels sortants = Durée totale de sonnerie des appels sortants/Nombre de temps de réponse",
    "cms.indexmonitor.message.totalBusyNum" : "Nombre d'agents en état occupé",
    "cms.report.field.agentOutSucc" : "Appels sortants manuels connectés",
    "cms.reportsubscribe.message.modifyStatus" : "Êtes-vous sûr de modifier le statut ?",
    "cms.report.message.queryTimeCheck" : "L'heure ne peut pas être antérieure à {0}.",
    "cms.systemparam.error.validate.notmatchrulemaxlength" : "dépasse la longueur maximale 0",
    "cms.report.field.maxAgentInCount" : "Max. appels entrants d'agent simultanés",
    "cms.agentMonitor.label.intercept_error" : "L'interception a échoué.",
    "cms.report.field.notConnected" : "Non connecté",
    "cms.indexmonitor.message.queueOutCallCount" : "Nombre d'appels sortants en file d'attente dans les files d'attente de compétences. (N'inclut pas les sessions multimédias)",
    "cms.report.field.ackBeginField" : "Réponse Heure de début",
    "cms.indexmonitor.field.ccName" : "Nom du centre d'appels",
    "cms.indexmonitor.field.queueInCallCount" : "Nombre d'appels entrants en file d'attente en ligne",
    "cms.systemmonitor.field.ok" : "OK",
    "cms.agentMonitor.label.success" : "Succès",
    "cms.report.field.busyCountField" : "Temps occupés",
    "cms.agentMonitor.label.switchNotice" : "Utilisez cette fonction lorsque l'agent courant est écouté ou interrompu.",
    "cms.report.field.endTimeField" : "Heure de fin d'appel",
    "cms.fullscreen.message.numberCheck" : "La valeur doit être 0, un nombre entier positif ou une décimale positive avec un maximum de deux décimales.",
    "cms.report.field.sysSuccRateField" : "Taux de réussite des appels entrants du système",
    "cms.recorddrive.field.configSftpPath" : "Chemin du fichier",
    "cms.callcenterinstanceslist.field.open" : "Activer",
    "cms.report.title.abandonRingMonitor" : "Sommaire de surveillance des appels abandonnés pendant la sonnerie",
    "cms.agentmonitor.label.busy" : "Force Occupé",
    "cms.report.field.inCallSuccRateField" : "Taux de connexion des appels entrants",
    "cms.skilladjustment.message.tipsOutSide" : "Un maximum de 10 agents peuvent être réglés à la fois.",
    "cms.report.label.reset" : "Réinitialiser",
    "cms.systemmonitor.field.ideaAgentNum" : "Agents d'inactivité",
    "cms.callcenterinstanceslist.message.updateFailed" : "Impossible de modifier le centre d'appels.",
    "cms.agentmonitor.label.terminalStatus" : "Statut de l'enregistrement du terminal",
    "cms.report.field.outInvalidCall" : "Appels sortants non valides",
    "cms.qcrelationconf.placeholder.pleaseInput" : "S'il vous plaît entrer",
    "cms.callcenter.field.savesuccess" : "Succès",
    "cms.monitordetail.field.calling" : "Appeler",
    "cms.vdnconfiguration.field.ok" : "OK",
    "cms.agentMonitor.label.forcebusy_success_calling" : "Un agent est placé de force à l'état occupé avec succès. Après la fin de l'appel, l'agent entre dans l'état occupé.",
    "cms.report.field.startMonth" : "Mois de début",
    "cms.indexmonitor.message.skillAvgAbandonAckDesc" : "Durée moyenne de sonnerie de tous les appels sans réponse",
    "cms.report.label.search" : "Rechercher",
    "cms.report.field.succAnsRate" : "Taux de connexion de demande",
    "cms.systemmonitor.field.onlineAgentNums" : "Agents en ligne",
    "cms.report.field.internalTransferCount" : "Transferts internes",
    "cms.report.field.ivrtrafficreport.avgivrservice" : "Durée moyenne des appels entrants IVR",
    "cms.report.field.inVdnAvgTimeField" : "Durée moyenne des appels entrants en VDN",
    "cms.recorddrive.label.button_complete" : "Complète",
    "cms.indexmonitor.message.agentConnectedOut10NDesc" : "Nombre total d'appels connectés dont la durée de réponse est supérieure à 10 secondes.",
    "cms.qualitycheck.message.adjustskill" : "Votre file d'attente de compétences a été temporairement ajustée à {skillList}. La compétence d'origine sera restaurée après que vous vous connectez à nouveau.",
    "cms.report.field.callAbandonedRing" : "Nombre d'appels entrants manuels perdus",
    "cms.report.field.acdCallField" : "Nombre total d'appels connectés",
    "cms.report.field.succWaitAnsRateField" : "Durée moyenne d'attente",
    "cms.recorddrive.title.sftpIdentityCheck" : "Vérification d'identité",
    "cms.report.field.transferIvrCountField" : "Temps de transfert manuel-à-IVR",
    "cms.report.field.callIdNumField" : "Numéro de séquence CDR",
    "cms.report.message.call4RateDesc" : "Pourcentage du nombre de demandes d'appels connectées manuellement en 20 secondes dans le nombre total d'appels demandant des services manuels.",
    "cms.report.message.agentOutSuccRateDesc" : "Pourcentage des appels sortants manuels connectés par rapport au total des appels sortants manuels.",
    "cms.indexmonitor.title.ivrDayTrend" : "Tendance du jour de l'IVR",
    "cms.indexmonitor.message.ivrInboundConnectRate" : "Pourcentage du nombre d'appels entrants RVI connectés dans le nombre de demandes d'appels entrants RVI",
    "cms.indexmonitor.field.totalAgentConnectCallNum" : "Nombre d'appels",
    "cms.callcenterinstanceslist.field.nodemodesingle" : "Mode unitaire",
    "cms.indexmonitor.field.durationAvgWait" : "Durée moyenne d'attente des appels entrants",
    "cms.indexmonitor.label.previous" : "Précédent",
    "cms.indexmonitor.field.agentConnectAfterInSixty" : "Appels connectés manuellement après 60 ans",
    "cms.callcenterselect.title.selectCcName" : "Sélectionnez le centre d'appels",
    "cms.report.field.awarnessRate" : "Taux de réussite des appels entrants manuels",
    "cms.vcallecenter.message.syncskillfail" : "Impossible de synchroniser les compétences.",
    "cms.indexmonitor.message.accesscodeCalloutNum" : "Nombre d'appels sortants passés par les numéros appelant dans l'heure",
    "cms.indexmonitor.field.skillOnlineWaitCall" : "Appels de file d'attente en ligne",
    "cms.agentmonitor.label.qcstate_error" : "L'annulation a échoué. Aucune opération de contrôle de qualité n'est effectuée sur l'agent sélectionné.",
    "cms.datasource.message.ccDsRelationExist" : "La source de données a été référencée dans le centre d'appels.",
    "cms.report.title.callCenterAccessReport" : "Rapport de trafic VDN par code d'accès",
    "cms.report.field.alertingTimeField" : "Heure d'alerte d'appel",
    "cms.report.message.avgIVROutCountDesc" : "Nombre moyen d'appels sortants simultanés dont le type de périphérique de service est IVR.",
    "cms.report.field.onehour" : "1 heure",
    "cms.indexmonitor.label.next" : "Suivant",
    "cms.callcenterinstanceslist.field.cmsAuthName" : "Compte d'authentification CCDIS",
    "cms.agent.message.modifypasswordhintdetail" : "Entrez le mot de passe.",
    "cms.report.message.inCallSuccNumDesc" : "Appels entrants connectés à l'agent.",
    "cms.agentMonitor.label.reset" : "Réinitialiser",
    "cms.report.field.minIVRCount" : "Minimum d'appels IVR simultanés",
    "cms.report.message.sysLostCallsDesc" : "Nombre d'appels entrants système ayant échoué = Nombre total d'appels entrants système – Nombre d'appels entrants système réussis.",
    "cms.indexmonitor.message.skillOverAgentDesc" : "Nombre d'appels transférés de la file d'attente de compétences d'origine à d'autres agents en raison d'un débordement",
    "cms.report.field.agentId" : "ID de l'agent",
    "cms.report.message.logoutCountDesc" : "Nombre d'heures de déconnection.",
    "cms.report.field.sysSuccRate" : "Taux de réussite des appels entrants du système",
    "cms.reportsubscribe.field.enable" : "Publié",
    "cms.systemparam.error.validate.notmatchruleip" : "Veuillez entrer une adresse IPV4 ou IPV6 valide",
    "cms.report.field.IVROutSuccRateField" : "Taux de connexion des appels sortants Pure-IVR",
    "cms.qualitycheck.label.groupname" : "GroupName",
    "cms.report.field.submediatype.wechat" : "WeChat",
    "cms.report.field.acwCount" : "Temps de récapitulation",
    "cms.report.field.uvidField" : "ID d'accès utilisateur",
    "cms.indexmonitor.field.agentInboundNum" : "Appels entrants manuels",
    "cms.indexmonitor.field.skillAbandon" : "Demandes d'appels perdus",
    "cms.report.field.mediatype.H5Vedio" : "Vidéo H5",
    "cms.qualitycheck.title.saveError" : "Erreur",
    "cms.indexmonitor.message.skillAbandonInNRateDesc" : "Pourcentage du nombre de demandes d'appels abandonnées manuellement en Ns dans le nombre total de services utilisateurs demandant des appels",
    "cms.voiceinspection.field.download" : "Téléchargement",
    "cms.monitordetail.field.holddurationavg" : "Durée moyenne de détention",
    "cms.report.field.busyCount" : "Temps occupés",
    "cms.indexmonitor.message.errorAgentInfo" : "Veuillez sélectionner au moins un agent.",
    "cms.fullscreen.field.oneMin" : "1 Minute",
    "cms.report.message.avgIVRCountDesc" : "Nombre moyen d'appels simultanés dont le type d'appareil de service est IVR.",
    "cms.report.field.agentOutBoundField" : "Appels sortants manuels",
    "cms.systemmonitor.field.queryTime" : "Temps de requête",
    "cms.calllink.field.cdnDevice" : "Dispositif CDN",
    "cms.report.field.byDay" : "Rapport quotidien",
    "cms.report.message.userNoAnswerNumDesc" : "Nombre d'appels qui ne reçoivent pas de réponse en raison du délai d'expiration de la sonnerie.",
    "cms.report.message.acwTimeRateDesc" : "Taux de durée de récapitulation = (Durée totale de récapitulation/Durée totale du travail) x 100",
    "cms.agentMonitor.label.details" : "Détails",
    "cms.voiceinspection.message.downloadFailed" : "Impossible de télécharger l'enregistrement.",
    "cms.report.field.acwCountField" : "Temps de récapitulation",
    "cms.sendnote.message.checkInput" : "Caractères non valides.",
    "cms.calllink.msg.calleePlaceHolder" : "Entrez le numéro appelé",
    "cms.systemmonitor.label.noncommercial" : "Utilisation commerciale d'essai",
    "cms.report.message.dataOverError" : "Requête {0} taille de données supérieure à 10000.",
    "cms.uapmonitor.field.callOperator" : "Opération",
    "cms.report.field.calleeNo" : "Code d'accès",
    "cms.recorddrive.placeholder.deleteRecorddrive" : "Veuillez sélectionner la lettre de lecteur à supprimer",
    "cms.indexmonitor.field.skillAvgConnected" : "Durée moyenne d'appel",
    "cms.datasource.field.dbName" : "Nom de la source de données",
    "cms.indexmonitor.field.totalConnectCallNum" : "Nombre total d'appels connectés",
    "cms.qualitycheck.label.qualityInspectionGroup" : "Groupe d'inspection de la qualité",
    "cms.vcallecenter.title.tips" : "Informations",
    "cms.report.message.muteCountDesc" : "Nombre de temps de silence.",
    "cms.callcenterinstanceslist.message.resourceError" : "Les informations de ressources modifiées sont inférieures au nombre réel de centres d'appels.",
    "cms.report.message.minAgentInCountDesc" : "Nombre minimal d'appels entrants simultanés dont le type de périphérique de service est agent.",
    "cms.indexmonitor.field.SkillAbandonRate" : "Demandes d'appel perdues",
    "cms.voiceinspection.field.callbackRequest" : "Demande de rappel",
    "cms.qualitycheck.label.create" : "Créer",
    "cms.report.field.agentHangNumCti" : "Agent Hang-Ups",
    "cms.agentmonitor.label.all" : "Tous",
    "cms.qcrelationconf.field.groupType" : "Type de groupe d'utilisateurs",
    "cms.report.field.startWeek" : "Semaine de début",
    "cms.indexmonitor.title.skillDayTrend" : "Tendance d'aujourd'hui de la file d'attente de compétences",
    "cms.indexmonitor.message.skillMaxQueueAckDesc" : "Durée maximale d'attente (mise en file d'attente et sonnerie) de tous les appels",
    "cms.report.title.dualCallDetail" : "Détail du double appel",
    "cms.report.message.systemInBoundDesc" : "Nombre total d'appels entrants système (à l'exclusion des appels entrants internes).",
    "cms.report.field.ackEndField" : "Heure de fin de réponse",
    "cms.report.message.agentLoginNumDesc" : "Nombre d'agents qui reçoivent des appels entrants.",
    "cms.report.title.callCenterAccessTrend" : "Tendance des performances VDN par code d'accès",
    "cms.report.field.minIVROutCount" : "Minimum d'appels sortants IVR simultanés",
    "cms.fullscreen.message.saveLayouts" : "La mise en page n'est pas sauvegardée. Sauvegardez-le d'abord.",
    "cms.developerlist.message.deletesuccess" : "Effacer avec succès.",
    "cms.indexmonitor.message.agentConnectIn60Rate" : "Pourcentage du nombre d'appels connectés manuellement dans un délai de 60 secondes (y compris 60 secondes) par rapport au nombre total d'appels.",
    "cms.report.message.leaveReasonDesc" : "Raison de quitter l'appareil.",
    "cms.vcallecenter.label.renewpwd" : "Confirmer le mot de passe",
    "cms.report.field.avgQueueInCountField" : "Avg. Appels entrants en file d'attente de compétences simultanées",
    "cms.qcrelationconf.message.specialChar" : "Caractère non valide",
    "cms.fullscreen.field.add" : "Créer",
    "cms.voiceinspection.field.textChat" : "Chat texte",
    "cms.report.field.avgAnsTimeField" : "Durée moyenne de mise en file d'attente des appels connectés",
    "cms.uapmonitor.field.callStatus" : "Statut de l'enregistrement",
    "cms.fullscreen.message.selectAgentMax" : "Un maximum de 5 agents peuvent être sélectionnés.",
    "cms.vcallecenter.label.resetpwd" : "Réinitialiser le mot de passe administrateur",
    "cms.voiceinspection.message.downloadSucceed" : "L'enregistrement est téléchargé avec succès.",
    "cms.report.message.alertingTimeDesc" : "Heure d'alerte d'appel.",
    "cms.callcenterinstanceslist.field.single" : "Mode unitaire",
    "cms.agentmonitor.label.release" : "Forcer la libération",
    "cms.report.field.submediatype.sms" : "SMS",
    "cms.qualitycheck.label.configQcInformation" : "Informations de vérification de la qualité de la configuration",
    "cms.callcenterinstanceslist.label.delete" : "Supprimer",
    "cms.qcrelationconf.title.success" : "Succès",
    "cms.agentmonitor.label.showAlarmAgentNo" : "ID de l'agent",
    "cms.agentmonitor.label.offline" : "Hors ligne",
    "cms.report.message.alertingDurationDesc" : "Durée(s) d'alerte de l'appelé",
    "cms.fullscreen.field.reportType" : "Type de rapport",
    "cms.voiceinspection.message.errorContent" : "Impossible de charger la ressource. Vérifiez la configuration.",
    "cms.report.field.confirm" : "OK",
    "cms.callcenterinstanceslist.message.ccIdError" : "Le numéro du centre d'appels a été utilisé.",
    "cms.report.message.IVRCallOutSuccRateDesc" : "Pourcentage d'appels sortants RVI connectés par rapport au nombre total de demandes d'appels sortants RVI.",
    "cms.report.field.waitCauseField" : "Raison d'attente",
    "cms.report.message.failQueueWaitRateDesc" : "Durée moyenne de sonnerie de tous les appels sans réponse.",
    "cms.indexmonitor.message.agentAbandonOut60NDesc" : "Nombre total d'appels abandonnés dont la durée de réponse est supérieure à 60 secondes.",
    "cms.indexmonitor.title.error" : "Erreur",
    "cms.sysparam.config.search" : "Rechercher",
    "cms.datasource.message.already" : "Le nom de la source de données existe déjà.",
    "cms.agentMonitor.laber.hasbeenCheck" : "L'agent a été inspecté.",
    "cms.agentMonitor.laber.hasbeenMediaCheck": "Cet agent est déjà surveillé par une session multimédia",
    "cms.agentMonitor.laber.stopMediaMonitor": "Moniteur de session multimédia arrêté",
    "cms.callcenterinstanceslist.field.uapPasswordConfirm" : "Confirmer le mot de passe UAP CDE",
    "cms.report.field.utilizationRateField" : "Taux de durée de l'état de travail",
    "cms.agentmonitor.label.showAlarmDetail" : "Afficher les détails de l'alarme",
    "cms.report.field.calleeNoField" : "Numéro appelé",
    "cms.calllink.field.agentRelease" : "L'agent déconnecte un appel.",
    "cms.agentMonitor.label.talk_error" : "L'agent sélectionné n'est pas dans l'état parlant.",
    "cms.callcenterinstanceslist.field.normalStatus" : "Normal",
    "cms.indexmonitor.message.totalAgentCallNumDesc" : "Nombre total d'appels connectés (période statistique : connexion actuelle)",
    "cms.agentmonitor.label.alarmState" : "Moniteur d'alarme",
    "cms.report.message.acwRateDesc" : "Taux de durée de récapitulation = (Durée totale de récapitulation/Durée totale du travail) x 100",
    "cms.synchronize.message.notConfigured" : "Non configuré",
    "cms.report.message.internalCallTimeDesc" : "Durée de l'appel interne.",
    "cms.developer.message.addDeveloper" : "Ajouter des informations sur le développeur",
    "cms.agentmonitor.label.monitorState" : "Statut de l'inspection",
    "cms.indexmonitor.field.agentConnectInSixty" : "Appels connectés manuellement en années 60",
    "cms.report.message.onlyIVRServiceDesc" : "Nombre d'appels entrants réussis traités uniquement par le RVI, c'est-à-dire nombre d'appels entrants réussis qui ne sont traités par aucun agent.",
    "cms.callcenterinstanceslist.field.close" : "Désactiver",
    "cms.callcenterinstanceslist.title.success" : "Succès",
    "cms.callcenterinstanceslist.message.uapNode2Info" : "Nœud UAP 2",
    "cms.voiceinspection.field.endTime" : "Heure de fin d'enregistrement",
    "cms.report.message.outOccupyNumDesc" : "Nombre d'appels passés par un agent aux clients.",
    "cms.report.field.assistsCountField" : "Demandes d'aide interne",
    "cms.export.message.addExportTaskSuccess" : "Nouvelle tâche d'exportation réussie",
    "cms.report.field.inVdnAvgTime" : "Durée moyenne des appels entrants en VDN",
    "cms.export.message.passValidateFailed" : "La valeur est une chaîne de 8 à 12 caractères et doit contenir des lettres, des chiffres et des caractères spéciaux (à l'exclusion des espaces de |;&$-<>/\\`!#(){},, des caractères de nouvelle ligne et des caractères de tabulation).",
    "cms.report.field.subMediaTypeField" : "Type de sous-média",
    "cms.vdnconfiguration.field.defaultCallCenter" : "Centre d'appels par défaut",
    "cms.indexmonitor.message.agentAbandonIn30Rate" : "Pourcentage d'appels abandonnés dans les 30 secondes par rapport au nombre total d'appels abandonnés",
    "cms.uapmonitor.title.regist" : "Statut de l'enregistrement du terminal",
    "cms.agentMonitor.label.insets_error" : "L'insertion a échoué.",
    "cms.indexmonitor.field.ivrDurationAvgConnect" : "Durée moyenne d'un appel RVI",
    "cms.indexmonitor.title.agentDayTrend" : "Tendance d'aujourd'hui de l'agent",
    "cms.datasource.field.passwordConfirm" : "Confirmer le mot de passe",
    "cms.qcrelationconf.placeholder.accountCode" : "Entrez un compte d'entreprise.",
    "cms.report.field.connected" : "Connecté",
    "cms.export.field.addExportTask" : "Nouvelle tâche d'exportation",
    "cms.agentmonitor.label.listenedAndInserted" : "Inspecté",
    "cms.report.message.callTypeDesc" : "Type d'appel.",
    "cms.calllink.field.noOnDutyTransfer" : "Un appel est transféré parce que personne n'est en service.",
    "cms.indexmonitor.field.totalAwcNum" : "Agents dans l'état de clôture",
    "cms.report.field.ringTimeField" : "Durée totale de l'anneau",
    "cms.indexmonitor.field.agentConnectAfterInTen" : "Appels connectés manuellement après 10s",
    "cms.reportsubscribe.field.status" : "Statut",
    "cms.vdnconfiguration.field.cancel" : "Annuler",
    "cms.uapmonitor.labe.openmonitor" : "Surveillance",
    "cms.report.field.agentOutSuccRateField" : "Taux de connexion manuelle des appels sortants",
    "cms.indexmonitor.field.agentConnectInThree" : "Appels connectés manuellement en 3s",
    "cms.sendnote.field.cancel" : "Annuler",
    "cms.callcenterinstanceslist.message.cleanKafka" : "Effacer la configuration du nœud KAFKA",
    "cms.report.message.outCallTimeDesc" : "Durée totale des appels sortants entre un agent et les clients.",
    "cms.export.message.nocount" : "Aucune donnée répondant aux conditions n'est trouvée.",
    "cms.report.message.succAnsRateDesc" : "Pourcentage du nombre de demandes d'appels entrants manuels connectés dans le nombre d'appels entrants manuels demandant des services.",
    "cms.report.message.internalTransferCountDesc" : "Nombre de temps de transfert interne.",
    "cms.fullscreen.message.addVdnMost" : "Un maximum de 10 locataires peuvent être sélectionnés.",
    "cms.indexmonitor.message.totalLoginNumDesc" : "Nombre d'agents qui se sont connectés au centre d'appels et qui ne traitent que les appels entrants",
    "cms.vcallcenter.message.unknowcause" : "Cause inconnue",
    "cms.systemmonitor.field.ccName" : "Nom du centre d'appels",
    "cms.callcenterinstanceslist.field.cmsPort" : "Port CMS LB",
    "cms.agentmonitor.label.queueAdjustment" : "Ajuster les files d'attente",
    "cms.qualitycheck.title.delete" : "Échec de la suppression",
    "cms.report.field.unspecifiedUserLevel" : "Non spécifié",
    "cms.qualitycheck.label.userGroupMemberConfig" : "Configurer les membres du groupe d'utilisateurs",
    "cms.report.message.avgQueueInCountDesc" : "Nombre moyen d'appels entrants simultanés dont le type de périphérique de service est une file d'attente de compétences.",
    "cms.voiceinspection.field.nonRealTimeCall" : "Appel non en temps réel",
    "cms.report.field.mediatype.vedio" : "Vidéo",
    "cms.monitordetail.field.sipNumber" : "Appel de connexion",
    "cms.calllink.title.mediaType" : "Type de média",
    "cms.report.field.avgInRingTimeField" : "Durée moyenne des appels entrants des terminaux",
    "cms.indexmonitor.message.queueInCallCount" : "Nombre d'appels entrants en file d'attente dans les files d'attente de compétences. (N'inclut pas les sessions multimédias)",
    "cms.qualitycheck.label.queryQcRelation" : "Relation d'inspection de requête",
    "cms.fullscreen.field.reportTime" : "Période de collecte",
    "cms.report.field.maxQueueInCountField" : "Appels entrants en file d'attente de compétences simultanés max",
    "cms.fullscreen.field.color" : "Couleur",
    "cms.report.field.avgIVRInCount" : "Moyenne des appels entrants IVR simultanés",
    "cms.agentmonitor.label.possessingSkills" : "File d'attente de connexion",
    "cms.agentMonitor.label.queryNum" : "Définir le numéro de connexion",
    "cms.agentMonitor.label.queryNumSuccess": "Définir le succès du numéro de connexion",
    "cms.agentMonitor.label.queryNumFailed": "Échec de la définition du numéro de connexion",
    "cms.calllink.field.callRejected" : "La partie appelée rejette un appel.",
    "cms.report.title.vdnSystemPerformanceTrend" : "Tendance des performances du système",
    "cms.report.message.maxIVRCountDesc" : "Nombre maximal d'appels simultanés dont le type de périphérique de service est IVR.",
    "cms.datasource.field.cmsDatabase" : "Base de données de rapports CMS",
    "cms.report.field.createTimeField" : "Créer du temps",
    "cms.indexmonitor.field.abandonInSixty" : "Appels abandonnés dans les années 60",
    "cms.vdnconfiguration.label.configure" : "Définir",
    "cms.callcenterinstanceslist.label.assignDeveloper" : "Développeur associé",
    "cms.qualitycheck.label.unselectedGroup" : "Groupes d'utilisateurs non sélectionnés",
    "cms.report.title.filteringIndicator" : "Indicateur de filtre",
    "cms.callcenterinstanceslist.field.kafkaPushSwitch" : "KAFKA poussée de données",
    "cms.datasource.label.edit" : "Modifier",
    "cms.skilladjustment.title.tips" : "Informations",
    "cms.report.message.calleeNoDesc" : "Numéro appelé.",
    "cms.datasource.field.isBackup" : "Base de données de sauvegarde",
    "cms.agentMonitor.label.batchSelectAgent" : "Vous ne pouvez sélectionner qu'un seul agent.",
    "cms.report.message.avgCallInTimeDesc" : "Durée moyenne des appels. Durée moyenne des appels entrants manuels connectés = Durée des appels entrants manuels connectés/Nombre d'appels entrants manuels connectés.",
    "cms.indexmonitor.message.agentAbandonIn5NDesc" : "Nombre total d'abandons avec une durée de réponse supérieure ou égale à 5 secondes",
    "cms.report.field.agentHangNumCtiField" : "Agent Hang-Ups",
    "cms.report.field.ans20s" : "Taux de connexion d'appel manuel en 20s",
    "cms.uapmonitor.field.unregister" : "ne pas s'inscrire",
    "cms.report.field.startTimeField" : "Heure de début de l'appel",
    "cms.fullscreen.message.digitsCheck" : "La valeur doit être un entier positif",
    "cms.report.title.ivrOutboundReport" : "Rapport d'appel sortant IVR",
    "cms.report.message.loginCountDesc" : "Nombre d'heures de connexion.",
    "cms.callcenterinstanceslist.field.ccStatus" : "Statut du centre d'appels",
    "cms.voiceinspection.message.errorTime" : "La requête de date ne peut pas traverser plusieurs mois.",
    "cms.report.field.internalCallCount" : "Appels internes",
    "cms.callcenterinstanceslist.message.workNoError" : "L'ID de l'employé de départ doit être de l'ordre de 101 à 50000.",
    "cms.agentmonitor.label.cancelInsert" : "Annuler l'insertion",
    "cms.indexmonitor.field.abandonInThree" : "Appels abandonnés dans 3s",
    "cms.export.field.exportEndTime" : "Exporter l'heure de fin",
    "cms.report.field.inCallNum" : "Appels entrants",
    "cms.report.message.acwInTimeDesc" : "Durée de récapitulation après la fin des appels entrants.",
    "cms.datasource.message.passValidateFailed" : "La valeur ne peut pas contenir \"eval\", \"exec\" et les caractères spéciaux suivants:<=>/\"",
    "cms.report.field.inCallRateField" : "Durée moyenne d'appel",
    "cms.vcallecenter.field.confirmnewpassword" : "Confirmer le mot de passe",
    "cms.report.message.auxTimeDesc" : "Durée totale des congés d'un agent ou durée lorsque l'agent est en état de repos.",
    "cms.report.message.agentOutBoundDesc" : "Nombre d'appels manuels sortants effectués par les agents.",
    "cms.sysparam.config.select.count" : "Un maximum de 10 paramètres peut être sélectionné.",
    "cms.report.field.muteCount" : "Temps de silence",
    "cms.callcenterinstanceslist.message.needKafkaSSL" : "OUVRIR SSL",
    "cms.report.field.agentTalkingTimeField" : "Temps de conversation de l'appelant",
    "cms.callcenterinstanceslist.label.synchronize" : "Synchroniser",
    "cms.report.message.internalCallCountDesc" : "Nombre d'appels internes.",
    "cms.indexmonitor.message.skillAvgConnectedQueueDesc" : "Durée moyenne de mise en file d'attente de tous les appels connectés dans une file d'attente",
    "cms.report.field.outAvgRingTime" : "Durée moyenne de sonnerie des appels sortants manuels",
    "cms.report.message.assistsCountDesc" : "Nombre de demandes d'aide interne.",
    "cms.callcenterinstanceslist.message.clearUapConfig" : "Êtes-vous sûr de vouloir supprimer la configuration du nœud UAP?",
    "cms.report.field.acwTimeRate" : "Taux de durée de récapitulation",
    "cms.report.message.IVRCallOutNumDesc" : "Nombre total d'appels sortants RVI.",
    "cms.fullscreen.field.acwCountTimeDesc" : "Nombre de fois où les agents sont en état de clôture.",
    "cms.report.field.agentInBoundField" : "Appels entrants manuels",
    "cms.report.field.call6RateField" : "Taux de connexion d'appel entrant manuel en 30s",
    "cms.report.message.userBusyFailNumDesc" : "Nombre d'appels auxquels on ne répond pas en raison de lignes occupées.",
    "cms.indexmonitor.message.totalQueueCallNum" : "Nombre d'appels en file d'attente dans les files d'attente de compétences et en attente de services d'agent",
    "cms.callcenterinstanceslist.field.uapIndexMonitor" : "Surveillance de l'indicateur du système UAP",
    "cms.report.field.inCallSuccNumField" : "Nombre d'appels manuels connectés",
    "cms.report.field.avgQueueCount" : "Moyenne des appels de file d'attente de compétences simultanées",
    "cms.callcenterinstanceslist.label.refresh" : "Actualiser",
    "cms.report.message.talkDurationDesc" : "Durée(s) de conversation.",
    "cms.report.field.avgAcdTime" : "Durée moyenne des appels manuels",
    "cms.callcenter.message.selectcallcent" : "Sélectionnez un centre d'appels.",
    "cms.synchronize.message.noConfAdminPwd" : "Le mot de passe n'est pas configuré. Configurez le mot de passe administrateur.",
    "cms.agentMonitor.label.forcebusy_stateerror" : "L'agent se repose. Ne pas montrer occupé.",
    "cms.agentMonitor.label.forcebusy_talking": "L'agent est dans un appel. Ne montrez pas occupé.",
    "cms.agentMonitor.label.forceidle_talking": "L'agent est dans un appel. Ne montrez pas l'état inactif",
    "cms.monitordetail.field.adjustDuration" : "Organiser la durée",
    "cms.synchronize.message.incorrectPassword" : "Incorrect",
    "cms.indexmonitor.message.skillOnlineInbondCallDesc" : "Nombre d'appels en cours de traitement + Nombre d'appels en file d'attente",
    "cms.report.message.minAgentCountDesc" : "Nombre minimal d'appels simultanés dont le type de périphérique de service est agent.",
    "cms.systemmonitor.field.busyNums" : "Agents occupés",
    "cms.agentmonitor.label.whisper" : "Whisper",
    "cms.report.field.acdTimeRate" : "Tarif de durée d'appel manuel",
    "cms.recorddrive.field.sftpIpadress" : "Adresse IP SFTP",
    "cms.report.message.minIVRInCountDesc" : "Nombre minimal d'appels entrants simultanés dont le type de périphérique de service est IVR.",
    "cms.recorddrive.label.IPAddress_add" : "Adresse IP",
    "cms.report.message.accessCodeDesc" : "Code d'accès.",
    "cms.indexmonitor.field.callNum" : "Nombre d'appels",
    "cms.indexmonitor.field.realTimeCalls": "llamadas en tiempo real",
    "cms.indexmonitor.field.callsOfTheDay": "llamadas del dia",
    "cms.indexmonitor.field.pageDataUpdateTime" : "Données mises à jour :",
    "cms.report.message.callInCauseDesc" : "Raison pour laquelle un appel entre dans le centre d'appels (pour les appels vocaux).",
    "cms.developerlist.field.createTime" : "Créé à",
    "cms.indexmonitor.field.skillOnlineInbondCall" : "Nombre d'appels en ligne",
    "cms.report.message.inOccupyNumDesc" : "Nombre d'appels entrants demandant des services manuels.",
    "cms.qcrelationconf.message.selectGroupType" : "Veuillez d'abord sélectionner le type de groupe d'utilisateurs",
    "cms.report.field.availTimeRateField" : "Taux d'inactivité",
    "cms.recorddrive.label.recorddrivename" : "Nom de la lettre du lecteur",
    "cms.indexmonitor.label.callInIVR" : "Nombre d'appels entrants en ligne IVR",
    "cms.fullscreen.field.editRichText" : "Modifier le texte riche",
    "cms.callcenterinstanceslist.message.cleanUap" : "Effacer la configuration du nœud UAP",
    "cms.callcenterinstanceslist.field.disPwdConfirm" : "Confirmer le mot de passe CCDIS",
    "cms.report.field.avgPerHNumField" : "Moyenne des appels réussis par heure",
    "cms.calllind.field.deviceAgent" : "Agent",
    "cms.agentmonitor.label.listening" : "Écouter",
    "cms.report.field.mediaTypeField" : "Type de média",
    "cms.report.field.playVoiceTimeField" : "Durée du jeu de voix",
    "cms.report.field.avgHoldTime" : "Durée moyenne d'attente",
    "cms.callcenterinstanceslist.field.adminCount" : "ID administrateur CTI",
    "cms.agentMonitor.label.cancelListening_success" : "L'écoute est annulée avec succès.",
    "cms.report.message.exportFailed" : "L'exportation a échoué.",
    "cms.voiceinspection.field.batchDownload" : "Téléchargement par lots",
    "cms.voiceinspection.field.callerNo" : "Numéro de l'appelant",
    "cms.report.field.maxAgentCountField" : "Max. Appels d'agents simultanés",
    "cms.indexmonitor.message.agentConnectIn10Rate" : "Pourcentage du nombre d'appels connectés manuellement dans un délai de 10 secondes (y compris 10 secondes) par rapport au nombre total d'appels.",
    "cms.agentMonitor.message.operaFailedAgent" : "L'opération a échoué. L'agent est inspecté.",
    "cms.indexmonitor.message.errorOrgInfo" : "Veuillez d'abord sélectionner une organisation.",
    "cms.report.field.agentOutSuccRate" : "Taux de connexion manuelle des appels sortants",
    "cms.report.field.acdCall" : "Nombre total d'appels connectés",
    "cms.report.message.outAvgCallTimeDesc" : "Durée moyenne des appels sortants entre un agent et les clients.",
    "cms.report.field.oneClickDualCall" : "Appel bidirectionnel en un clic",
    "cms.report.field.IVRInSucc" : "Appels entrants IVR connectés",
    "cms.report.field.agentOutBound" : "Appels sortants manuels",
    "cms.voiceinspection.field.messageCall" : "Appel de message",
    "cms.callcenterinstanceslist.message.uapPsdLengthRangeValidateFailed" : "La longueur du mot de passe doit être comprise entre 6 et 32",
    "cms.developerlist.field.operate" : "Opération",
    "cms.sysparam.config.checkmessage" : "Contient des caractères spéciaux. (\"<\", \">\", \"/\", etc.)",
    "cms.qcrelationconf.field.qcWorkNo" : "ID de l'inspecteur",
    "cms.agentmonitor.label.statusDuration" : "Statut de l'agent Durée",
    "cms.indexmonitor.field.accesscodeCalloutNum" : "Nombre d'appels sortants",
    "cms.vcallecenter.title.failed" : "Erreur",
    "cms.report.message.avgAgentCountDesc" : "Nombre moyen d'appels simultanés dont le type de périphérique de service est agent.",
    "cms.agentmonitor.label.sendNotes" : "Envoyer des notes",
    "cms.indexmonitor.field.all" : "Tous",
    "cms.indexmonitor.field.outboundConnectNumDesc" : "cms.report.field.outOccupyNumDesc=Nombre de fois qu'un agent connecte des appels sortants.",
    "cms.report.message.avgAgentInCountDesc" : "Nombre moyen d'appels entrants simultanés dont le type de périphérique de service est agent.",
    "cms.report.field.onlyIVRService" : "Appels entrants Pure-IVR réussis",
    "cms.qcrelationconf.placeholder.workNo" : "Veuillez saisir l'identifiant de l'agent",
    "cms.calllink.field.noResources" : "Aucune ressource disponible sur le RVA.",
    "cms.report.message.avgIVRInCountDesc" : "Nombre moyen d'appels entrants simultanés dont le type de périphérique de service est IVR.",
    "cms.indexmonitor.message.ivrInboundNum" : "Nombre de demandes d'appels entrants IVR dont la durée d'appel est supérieure à 0",
    "cms.report.message.avgAnsTimeDesc" : "Durée moyenne de mise en file d'attente de tous les appels connectés dans une file d'attente.",
    "cms.report.field.minQueueOutCount" : "Appels sortants en file d'attente de compétences simultanées min",
    "cms.developerlist.label.delete" : "Supprimer",
    "cms.indexmonitor.message.totalAbandonedCallNum" : "Nombre total d'appels – Nombre total d'appels connectés",
    "cms.fullscreen.field.preview" : "Aperçu",
    "cms.systemparam.error.validate.notmatchrulerangelength" : "Le paramètre d'entrée dépasse la longueur maximale ou est inférieur à la longueur minimale",
    "cms.report.field.callStartTimeField" : "Heure de début de l'appel",
    "cms.systemparam.error.notmatchrule" : "Le paramètre ne répond pas à la règle de contrôle",
    "cms.report.title.agentOutCallMonitor" : "Surveillance du résumé des appels sortants de l'agent",
    "cms.report.field.custHangNumCtiField" : "Accrochages clients",
    "cms.report.field.thirtyminutes" : "30 minutes",
    "cms.datasource.message.updatesuccess" : "Mise à jour réussie.",
    "cms.report.field.workTimeField" : "Durée totale du travail",
    "cms.fullscreen.message.chartDataError" : "Impossible de charger le graphique.",
    "cms.indexmonitor.field.durationAvgVdnOutbound" : "Durée(s) moyenne(s) des appels sortants",
    "cms.export.field.exportTaskStatus" : "Statut",
    "cms.report.field.callBeginField" : "Heure de début de l'appel",
    "cms.report.message.inCallRateDesc" : "Durée moyenne des appels. Durée moyenne des appels entrants manuels connectés = Durée des appels entrants manuels connectés/Nombre d'appels entrants manuels connectés.",
    "cms.report.field.busyTimeRateField" : "Taux de durée d'occupation",
    "cms.vcallecenter.field.confirmwaspassword" : "Confirmer le mot de passe",
    "cms.skilladjustment.field.selectskill" : "Sélectionnez une file d'attente",
    "cms.indexmonitor.message.abandonInFive" : "Pourcentage du nombre d'appels abandonnés en moins de 5s dans le nombre total d'appels abandonnés.",
    "cms.export.field.failed" : "Échec",
    "cms.report.message.inCallSuccRateDesc" : "Pourcentage des appels entrants connectés par rapport au total des appels entrants.",
    "cms.vcallecenter.message.syncFailed" : "Échec de la synchronisation",
    "cms.fullscreen.field.fifteenMin" : "15 minutes",
    "cms.report.message.subMediaTypeDesc" : "Type de sous-média.",
    "cms.systemparam.error.validate.notmatchrulenumber" : "Veuillez entrer un numéro",
    "cms.systemmonitor.message.addVdnMost" : "Un maximum de 100 locataires peuvent être sélectionnés.",
    "cms.systemparam.error.validate.notmatchruleoption" : "Le paramètre d'entrée n'est pas dans la plage d'énumération",
    "cms.qcrelationconf.field.deleteGroupMember" : "Supprimer un membre du groupe",
    "cms.report.field.avgHandleTimeField" : "Durée moyenne de traitement",
    "cms.report.field.minQueueInCountField" : "Appels entrants de file d'attente de compétences simultanées min",
    "cms.report.title.agentOutCallTrend" : "Tendance du résumé des appels sortants de l'agent",
    "cms.calllind.field.deviceRoute" : "Dispositif de routage temporaire",
    "cms.report.field.outAvgAnsTime" : "Durée moyenne de sonnerie des appels sortants",
    "cms.report.message.awarnessRateDesc" : "Pourcentage du nombre d'agents ayant répondu aux appels par rapport au nombre d'appels entrants manuels.",
    "cms.agent.title.modifypassword" : "Modifier le mot de passe de connexion",
    "cms.report.message.avgAcwTimeDesc" : "Durée moyenne lorsqu'un agent est dans l'état de clôture. Durée moyenne d'exécution = Durée totale d'exécution/Nombre de temps d'exécution",
    "cms.report.message.inCallSuccNumberDesc" : "Nombre d'appels entrants connectés. La valeur est la somme des appels entrants manuels connectés et des appels entrants connectés automatiquement à l'aide de l'IVR.",
    "cms.fullscreen.message.addChart" : "Ajouter un graphique.",
    "cms.voiceinspection.message.playsuccess" : "L'annonce est jouée avec succès.",
    "cms.agentMonitor.label.warning" : "Avertissement",
    "cms.indexmonitor.message.agentOutboundConnectNum" : "Nombre d'appels sortants manuels auxquels les personnes appelées répondent.",
    "cms.report.message.manToSkillNumDesc" : "Nombre de transferts de file d'attente manuel-compétence.",
    "cms.agentmonitor.label.showAlarmSensitive" : "Mot sensible",
    "cms.calllink.field.commonAccessTransfer" : "Accès commun ou transfert.",
    "cms.export.title.voiceDownload" : "Inspection d'enregistrement",
    "cms.calllink.field.overflowing" : "Un appel est transféré en raison d'un débordement.",
    "cms.datasource.message.testsuccess" : "La connexion est testée avec succès.",
    "cms.report.message.avgAcdTimeDesc" : "Durée moyenne d'appel d'un agent. Durée moyenne d'appel manuel = Durée d'appel manuel/Nombre d'appels manuels réussis",
    "cms.indexmonitor.field.skillUserAbandon" : "Appels abandonnés",
    "cms.report.message.userLevelDesc" : "Niveau de client d'un abonné dans le centre d'appels.",
    "cms.report.message.acwOutCountDesc" : "Nombre de fois qu'un agent entre dans l'état de clôture après la fin des appels sortants.",
    "cms.fullscreen.message.deleteconfim" : "Souhaitez-vous confirmer la suppression de ce sujet ?",
    "cms.fullscreen.field.chartType" : "Type de graphique",
    "cms.fullscreen.field.addCharts" : "Créer un graphique",
    "cms.monitordetail.field.count" : "Statistiques sur le nombre de fois",
    "cms.report.field.IVROutCallNumField" : "Appels sortants Pure-IVR",
    "cms.fullscreen.field.skillQueue" : "File d'attente de compétences",
    "cms.calllink.title.leaveReason" : "Raison de quitter l'appareil",
    "cms.callcenterinstanceslist.field.kafkaIp" : "Adresse IP KAFKA",
    "cms.indexmonitor.message.inboundCallNum" : "Nombre d'appels entrants manuels + Nombre d'appels entrants IVR",
    "cms.report.field.submediatype.whatsapp" : "WhatsApp",
    "cms.calllink.field.PGR" : "PGR (route CDN par défaut contrôlée par le SCAI)",
    "cms.report.field.voiceNotification" : "Notification vocale",
    "cms.agentmonitor.message.header" : "L'enregistrement a échoué. Sélectionnez au moins un en-tête de tableau.",
    "cms.report.field.querySkill" : "File d'attente de compétences",
    "cms.indexmonitor.field.totalLearningNum" : "Agents en état d'apprentissage",
    "cms.report.field.agentInSuccRate" : "Taux de connexion des appels entrants manuels",
    "cms.uapmonitor.labe.uapoverload" : "Le nombre de numéros interrogés par le segment de numéro de terminal dépasse la limite supérieure :",
    "cms.report.field.outAboundRingField" : "Appels sortants sans réponse",
    "cms.callcenterinstanceslist.message.adminWorkNoError" : "Le numéro de départ de l'ID de l'employé administrateur doit aller de 55001 à 59999.",
    "cms.report.field.holdTimeField" : "Durée de maintien",
    "cms.monitordetail.field.skill" : "File d'attente de compétences",
    "cms.report.field.manTransferOutNumCtiField" : "Nombre de transferts manuels vers IVR et de re-transferts",
    "cms.agentMonitor.label.listen_error" : "L'écoute échoue.",
    "cms.calllink.field.unhold" : "Un appel n'est pas retenu.",
    "cms.failurecall.field.accessCode" : "Code d'accès",
    "cms.report.message.preDeviceNoDesc" : "Indique l'ID de l'appareil du dernier appareil que l'appel passe.",
    "cms.agentmonitor.label.learnState" : "Apprentissage",
    "cms.indexmonitor.message.durationAvgVdnInbound" : "Durée totale pendant laquelle les appels entrants occupent le VDN/Nombre total d'appels entrants",
    "cms.datasource.field.cancel" : "Annuler",
    "cms.report.message.userRefusedFailNumDesc" : "Nombre d'appels auxquels on ne répond pas en raison d'un rejet d'appels.",
    "cms.indexmonitor.message.totalAgentNum" : "Nombre total d'agents actuels",
    "cms.report.field.outFailNum" : "Total des appels sortants échoués",
    "cms.fullscreen.field.fiveMin" : "5 Minutes",
    "cms.report.title.callNo" : "Numéro d'appel",
    "cms.fullscreen.title.editTheme" : "Modifier le sujet",
    "cms.calllink.field.teleconference" : "Conférence téléphonique",
    "cms.voiceinspection.field.calleeNo" : "Numéro appelé",
    "cms.report.field.IVRShortCallsField" : "Nombre d'appels courts d'IVR (dans 3s)",
    "cms.report.field.internalCallTime" : "Durée de l'appel interne",
    "cms.uapmonitor.labe.eidmention" : "Vous pouvez entrer un maximum de 10 segments de nombre, qui sont séparés par des virgules (,). Règle pour chaque segment de nombre: * et % ne correspondent à aucun caractère ou plusieurs caractères. Par exemple, 6666* et 6666% correspondent à toutes les chaînes de caractères commençant par 6666. Le point d'interrogation (?) ne correspond qu'à un caractère. Par exemple, 6666 ? correspond uniquement à la chaîne de cinq caractères commençant par 6666. La combinaison de caractères génériques (*?%) est prise en charge. Par exemple, %3 & #160;? correspond à toutes les chaînes de caractères dont l'avant-dernier caractère est 3. Note : Il est recommandé de faire correspondre un maximum de 100 résultats pour chaque combinaison. Sinon, les entreprises du PAN sont touchées.",
    "cms.report.field.inAvgRingTimeField" : "Durée moyenne de sonnerie des appels entrants manuels",
    "cms.datasource.field.remark" : "Description d'utilisation",
    "cms.calllink.title.callType" : "Type d'appel",
    "cms.report.message.ackEndDesc" : "Heure de fin de réponse d'appel, qui se réfère à l'heure de fin de la réponse d'appel sur l'appareil actuel.",
    "cms.report.field.obsServiceIdField" : "ID de campagne sortante",
    "cms.record-playback.callType2" : "Appel sortant IVR",
    "cms.qcrelationconf.message.queryFailedLimit" : "Le nombre d'enregistrements par page doit être supérieur à 0 et inférieur à 101.",
    "cms.failurecall.placeholder.userPhoneInput" : "Entrez un numéro de service",
    "cms.report.field.outSuccAnsRate" : "Taux de connexion des appels sortants",
    "cms.callcenterinstanceslist.field.cmsAuthPassword" : "Mot de passe CCDIS",
    "cms.report.field.skillId" : "ID de file d'attente de compétence",
    "cms.uapmonitor.field.callUapIp" : "UAP IP",
    "cms.report.field.endYear" : "Fin de l'année",
    "cms.report.field.agentInSuccField" : "Appels entrants manuels connectés",
    "cms.callcenterinstanceslist.label.save" : "Enregistrer",
    "cms.uapmonitor.title.deregis" : "Confirmation de désinscription",
    "cms.indexmonitor.message.skillConnectedAfterNDesc" : "Nombre de demandes d'appels connectées manuellement après Ns. Actuellement, Ns peut être 10s ou 60s.",
    "cms.report.message.billInfo1Desc" : "Type d'appel vocal.",
    "cms.report.message.outWaitAnsNumDesc" : "Nombre d'appels sortants manuels, y compris les appels sortants lancés par les agents et ceux transférés du RVI aux services manuels.",
    "cms.fullscreen.field.normalBold" : "Normal",
    "cms.agentMonitor.label.forcerelease_error" : "Impossible de lâcher un appel de force.",
    "cms.report.message.waitBeginDesc" : "Heure de début de l'appel en attente.",
    "cms.indexmonitor.field.skillTimeoutQueue" : "Délai d'attente des appels vers les files d'attente",
    "cms.indexmonitor.field.agentConnectInFive" : "Appels connectés manuellement en 5s",
    "cms.callcenterinstanceslist.message.videoCallNumsError" : "Le nombre maximal d'appels vidéo simultanés doit être supérieur ou égal au nombre de sièges vidéo.",
    "cms.report.title.agentOperationMonitor" : "Détails de l'opération de connexion de l'agent Surveillance",
    "cms.vcallecenter.title.modifybmspassword" : "Modifier le mot de passe BMS",
    "cms.indexmonitor.title.agentNowMonitor" : "Statistiques de surveillance en temps réel sur l'Agent pendant l'exécution",
    "cms.callcenterinstanceslist.label.create" : "Nouveau",
    "cms.indexmonitor.title.callCenterDayTrend" : "Tendance du centre d'appels d'aujourd'hui",
    "cms.report.field.indicatorName" : "Indicateur",
    "cms.voiceinspection.field.currentSkillId" : "File d'attente de compétences",
    "cms.agentMonitor.label.gateway_error" : "Impossible d'appeler l'interface de la passerelle.",
    "cms.fullscreen.field.percentage" : "(%)",
    "cms.indexmonitor.message.agentAbandonOut10NDesc" : "Nombre total d'appels abandonnés dont la durée de réponse est supérieure à 10 secondes.",
    "cms.calllink.field.internalError" : "Erreur interne.",
    "cms.report.field.otherHangNumCti" : "Systèmes Hang-Ups",
    "cms.indexmonitor.message.skillAbandonInNDesc" : "Nombre de demandes d'appels abandonnées manuellement en Ns. Actuellement, Ns peut être 3s, 5s, 10s, 20, 30s, ou 60s.",
    "cms.uapmonitor.field.callSip" : "Identifiant terminal",
    "cms.report.message.callAbandonedInRingDesc" : "Nombre d'appels abandonnés par l'appelant pendant qu'il sonne sans réponse.",
    "cms.report.field.awarnessRateField" : "Taux de réussite des appels entrants manuels",
    "cms.callcenterinstanceslist.message.selectdeveloper" : "Sélectionnez un développeur.",
    "cms.callcenterinstanceslist.message.error" : "Erreur",
    "cms.report.title.changeTable" : "Passer à la table",
    "cms.callcenterinstanceslist.field.ccBmsPort" : "Port CCBMS LB",
    "cms.indexmonitor.message.agentBoundAbandonRate" : "100 – Taux d'appels entrants manuels connectés",
    "cms.systemparam.error.validate.notmatchruleminlength" : "Le paramètre d'entrée est inférieur à la longueur minimale",
    "cms.callecenter.field.confirmbmspwd" : "Confirmer le mot de passe CCBMS",
    "cms.indexmonitor.message.skillRequestDesc" : "Nombre d'appels entrants demandant des services utilisateurs",
    "cms.callcenterinstanceslist.message.passLengthValidateFailed" : "Un mot de passe contient un minimum de huit caractères.",
    "cms.voiceinspection.field.videoClickToCall" : "Vidéo Cliquez pour composer",
    "cms.agentmonitor.label.busyState" : "Occupé",
    "cms.monitordetail.field.avgTalkingDuration" : "Durée moyenne d'appel",
    "cms.qcrelationconf.message.queryFailed" : "La requête a échoué",
    "cms.datasource.field.uidbDatabase" : "UIDB",
    "cms.interfaceperflog.message.modifySuccess" : "Modification réussie",
    "cms.indexmonitor.message.ivrOutboundNum" : "Nombre d'appels sortants RVI répondus par l'appelé",
    "cms.qcrelationconf.title.warning" : "Avertissement",
    "cms.report.field.agentServiceRate" : "Taux de réussite manuel dans le total des appels entrants",
    "cms.fullscreen.message.numberSecond" : "La valeur maximale est 4320.",
    "cms.report.field.maxIVROutCount" : "Max. appels sortants IVR simultanés",
    "cms.uapmonitor.field.callSocket" : "Statut du socket",
    "cms.reportsubscribe.field.prohibit" : "Interdire",
    "cms.report.message.callEndTimeDesc" : "Appeler l'heure de fin.",
    "cms.voiceinspection.message.frequentOperat" : "L'opération est trop fréquente. Veuillez réessayer plus tard",
    "cms.voiceinspection.message.frequentDownload" : "Le téléchargement est trop fréquent. Réessayez plus tard.",
    "cms.report.field.agentLoginNum" : "Agents appelés",
    "cms.vcallecenter.title.modifyinfo" : "Modifier le mot de passe",
    "cms.indexmonitor.message.skillConnectedInNRate" : "Pourcentage du nombre de demandes d'appels manuellement connectées en Ns dans le nombre total d'appels demandant des services aux utilisateurs",
    "cms.callcenterinstanceslist.field.passwordConfirm" : "Confirmer le mot de passe",
    "cms.callcenterinstanceslist.message.ccgatewayServerSingledeleteconfim" : "Voulez -vous vraiment supprimer le serveur CC-Gateway sélectionné ?",
    "cms.report.field.avgAgentCountField" : "Avg. Appels d'agents simultanés",
    "cms.report.field.minQueueInCount" : "Appels entrants de file d'attente de compétences simultanées min",
    "cms.report.title.error" : "Erreur",
    "cms.systemmonitor.message.timelarge" : "* L'intervalle de temps ne peut pas dépasser 31 jours.",
    "cms.fullscreen.field.fonts" : "Police",
    "cms.qcrelationconf.message.updateSuccess" : "Succès de mise à jour",
    "cms.datasource.message.deletefailed" : "La suppression a échoué.",
    "cms.agentmonitor.label.detail" : "Détails de surveillance de l'agent.",
    "cms.sendnote.message.tipsNoteContent" : "Entrez les informations de la note.",
    "cms.indexmonitor.message.skillUserAbandonDesc" : "Nombre d'appels abandonnés de manière proactive par les utilisateurs pendant la file d'attente et la sonnerie",
    "cms.callcenterinstanceslist.message.updateCtiVersion" : "Vérifiez si la migration des données CTI est terminée. Faites preuve de prudence lorsque vous effectuez cette opération.",
    "cms.calllink.title.callEndTime" : "Heure de fin d'appel",
    "cms.fullscreen.field.indexdMode" : "Mode de surveillance",
    "cms.calllink.title.deviceDesc" : "Description de l'appareil",
    "cms.report.field.failQueueWaitRate" : "Durée moyenne de la sonnerie des appels perdus",
    "cms.callcenterinstanceslist.title.warning" : "Avertissement",
    "cms.uapmonitor.field.Search" : "Rechercher",
    "cms.developerlist.title.create" : "Ajouter un développeur",
    "cms.voiceinspection.message.invalidParam" : "Paramètre d'entrée non valide.",
    "cms.callcenterinstanceslist.field.ctiversion" : "VERSION CTI",
    "cms.synchronize.message.syncStatusZero" : "À synchroniser",
    "cms.indexmonitor.message.agentConnectInFive" : "Pourcentage du nombre d'appels connectés manuellement en moins de 5s dans le nombre total d'appels connectés manuellement.",
    "cms.report.title.skillAllMonitor" : "Surveillance du résumé des files d'attente de compétences",
    "cms.indexmonitor.message.agentConnectAfterInSixty" : "Pourcentage du nombre d'appels connectés manuellement après 60s dans le nombre total d'appels connectés manuellement.",
    "cms.report.message.acdCallsDesc" : "Nombre d'appels entrants et sortants connectés.",
    "cms.indexmonitor.message.durationAvgWait" : "Durée moyenne d'attente des appels entrants",
    "cms.report.message.agentAlertingTimeDesc" : "Heure d'alerte de l'appelant.",
    "cms.indexmonitor.field.skillConnectedIn3Rate" : "Appels connectés en 3s",
    "cms.indexmonitor.title.ivrDayMonitor" : "Statistiques d'aujourd'hui sur les indicateurs de RVI",
    "cms.agentMonitor.label.whisper_error" : "Le chuchotement a échoué",
    "cms.report.field.locationIdField" : "ID de nœud distribué",
    "cms.report.field.avgQueueOutCountField" : "Avg. Appels sortants en file d'attente de compétences simultanées",
    "cms.report.field.IVROutSuccNum" : "Appels sortants Pure-IVR connectés",
    "cms.report.field.IVROutSuccNumField" : "Appels sortants Pure-IVR connectés",
    "cms.report.field.assistTime" : "Durée de la demande d'aide interne",
    "cms.systemmonitor.label.onehour" : "1 heure",
    "cms.voiceinspection.field.audioPlay" : "Audition d'enregistrement",
    "cms.callcenterinstanceslist.title.nodemodeselect" : "Sélectionner le mode",
    "cms.vdnconfiguration.title.vdnConfigure" : "Configurer le centre d'appels où le VDN s'exécute",
    "cms.calldetail.label.inputcallid" : "Veuillez entrer le numéro de séquence de l'appel",
    "cms.fullscreen.field.statisticPeriod" : "Période statistique",
    "cms.report.field.acdTimeRateField" : "Taux de durée d'appel manuel",
    "cms.report.title.skillAccessTrend" : "Tendance des performances des files d'attente de compétences par code d'accès",
    "cms.skilladjustment.message.tipsError" : "Impossible d'ajuster la file d'attente de compétences.",
    "cms.report.field.threePartyCallCount" : "Appels à trois parties",
    "cms.report.message.queryFailed" : "La requête {0} a échoué.",
    "cms.vcallecenter.message.configurationinterfacenopassword" : "Le mot de passe de configuration n'est pas configuré.",
    "cms.datasource.message.addsuccess" : "Addition réussie.",
    "cms.qcrelationconf.field.accountCode" : "Compte de service",
    "cms.indexmonitor.message.agentInboundNum" : "Nombre d'appels entrants attribués à des files d'attente de compétences ou à des agents",
    "cms.report.field.accessCodeField" : "Code d'accès",
    "cms.report.field.inAnswerRate" : "Taux de connexion des appels entrants",
    "cms.indexmonitor.field.abandonInThirty" : "Appels abandonnés en 30s",
    "cms.report.field.outAboundRing" : "Appels sortants sans réponse",
    "cms.report.field.avgIVRCountField" : "Moyenne des appels IVR simultanés",
    "cms.agentmonitor.label.restState" : "Repos",
    "cms.indexmonitor.message.totalConnectCallNum" : "Nombre d'appels entrants connectés + Nombre d'appels sortants connectés",
    "cms.qcrelationconf.field.operation" : "L'action",
    "cms.fullscreen.message.screenreSolution" : "La résolution de l'écran ne peut pas être vide.",
    "cms.agentMonitor.label.forceidle_success_calling" : "Un agent est placé de force à l'état inactif avec succès. Après la fin de l'appel, l'agent entre dans l'état inactif.",
    "cms.qcrelationconf.message.deleteSuccess" : "Succès de suppression",
    "cms.monitordetail.field.redirectcount" : "Appels redirigés",
    "cms.qcrelationconf.field.cancel" : "Annuler",
    "cms.agentmonitor.label.signOut" : "Hors ligne",
    "cms.report.field.waitAnsRate" : "Durée moyenne de sonnerie des appels entrants manuels",
    "cms.fullscreen.field.movedown" : "En bas",
    "cms.report.field.voiceCallType" : "Type d'appel vocal",
    "cms.indexmonitor.field.duration" : "Durée",
    "cms.uapmonitor.labe.uapconfing" : "Configuration de la surveillance et de la restauration de l'état du terminal",
    "cms.report.message.avgAgentOutCountDesc" : "Nombre moyen d'appels sortants simultanés dont le type de périphérique de service est agent.",
    "cms.report.field.manTransferOutNumCti" : "Nombre de transferts manuels vers IVR et de re-transferts",
    "cms.report.message.onlineRateDesc" : "Tarif en ligne =  (Durée de l'appel manuel + Durée totale de la clôture + Durée totale des autres états)/(Durée totale du travail – Durée de repos) x 100",
    "cms.callcenterinstanceslist.label.vCallCenter" : "Centre d'appel virtuel",
    "cms.agentmonitor.label.operation" : "Opération",
    "cms.fullscreen.message.addVdnLeast" : "Sélectionnez au moins un locataire.",
    "cms.monitordetail.field.idlecount" : "L'agent entre dans l'état d'inactivité",
    "cms.report.field.userBusyFailNum" : "Appels perdus en raison de lignes occupées",
    "cms.datasource.field.dbType" : "Type de source de données",
    "cms.qcrelationconf.field.groupInfo" : "Informations de base du groupe d'utilisateurs",
    "cms.report.field.failAnsRate" : "Demander le taux de perte",
    "cms.systemparam.error.reset" : "Échec de la réinitialisation des paramètres",
    "cms.report.field.avgCallPerHour" : "Moyenne des appels entrants manuels par heure",
    "cms.datasource.field.operation" : "Opération",
    "cms.report.field.noAckNum" : "Appels sans réponse",
    "cms.callcenterinstanceslist.label.batchAdd" : "Ajout par lot",
    "cms.voiceinspection.title.error" : "Erreur",
    "cms.report.field.userLevelField" : "Niveau de client",
    "cms.report.field.inAvgCallTimeField" : "Durée moyenne d'appel",
    "cms.report.message.maxIVRInCountDesc" : "Nombre maximal d'appels entrants simultanés dont le type de périphérique de service est IVR.",
    "cms.report.message.errorInfo" : "Impossible d'enregistrer les données. Sélectionnez au moins un indicateur.",
    "cms.report.field.callAbandonedRingField" : "Nombre d'appels entrants manuels perdus",
    "cms.qualitycheck.label.editusergroup" : "Modifier les UserGroup",
    "cms.report.message.callAbandonedRingDesc" : "Nombre d'appels entrants manuels perdus = Nombre d'appels entrants manuels – Nombre d'appels entrants manuels connectés",
    "cms.voiceinspection.message.noSftpFound" : "Impossible d'interroger les informations de connexion du serveur SFTP.",
    "cms.report.field.IVRServiceRateField" : "Taux de réussite des appels entrants Pure-IVR",
    "cms.systemparam.error.noparamselected" : "Veuillez d'abord sélectionner un paramètre",
    "cms.report.message.callIdNumDesc" : "Numéro d'ordre de plusieurs CDR générés pour un appel.",
    "cms.report.message.avgHoldTimeDesc" : "Durée moyenne d'attente de l'appel. Durée moyenne d'attente = Durée d'attente/Nombre de temps d'attente",
    "cms.indexmonitor.message.totalLearningNum" : "Nombre d'agents en état d'apprentissage",
    "cms.fullscreen.field.totalCallTimeDesc" : "Durée totale des appels auxquels ont répondu les agents.",
    "cms.report.field.queryBeginTime" : "Heure de début",
    "cms.fullscreen.field.pie" : "Diagramme à secteurs",
    "cms.indexmonitor.message.agentAbandonIn3Rate" : "Pourcentage d'appels abandonnés dans les 3 secondes par rapport au nombre total d'appels abandonnés",
    "cms.callcenter.message.updatefailed" : "La mise à jour a échoué",
    "cms.indexmonitor.message.agentConnectIn30Rate" : "Pourcentage du nombre d'appels connectés manuellement dans un délai de 30 secondes (y compris 30 secondes) par rapport au nombre total d'appels.",
    "cms.agentmonitor.label.account" : "Compte de service",
    "cms.report.message.IVROutSuccRateDesc" : "Pourcentage d'appels sortants connectés pur-IVR par rapport au total des appels sortants Pure-IVR.",
    "cms.report.message.manToIvrNumCtiDesc" : "Nombre de fois que l'agent suspend l'appel et transfère l'appel à l'IVR après la connexion de l'appel.",
    "cms.report.field.cancel" : "Annuler",
    "cms.report.message.inCallFailNumDesc" : "Nombre de demandes d'appel perdues = Nombre de demandes de service – Nombre de demandes d'appel connectées",
    "cms.report.field.manToIvrNumCti" : "Nombre de fois qu'un agent est suspendu pour le transfert vers le RVI",
    "cms.callcenter.field.deletesuccess" : "Succès de suppression",
    "cms.calllink.title.deviceNo" : "ID de périphérique",
    "cms.fullscreen.field.dayTrend" : "Tendance d'aujourd'hui",
    "cms.voiceinspection.message.playfailure" : "Impossible de lire l'annonce.",
    "cms.report.message.outAvgRingTimeDesc" : "Temps moyen pour un agent d'attendre que les clients répondent aux appels sortants.",
    "cms.report.field.outCallTimeField" : "Durée totale des appels sortants",
    "cms.indexmonitor.message.agentConnectInSixty" : "Pourcentage du nombre d'appels connectés manuellement dans les années 60 dans le nombre total d'appels connectés manuellement.",
    "cms.callcenterinstanceslist.field.uapPerfPort" : "Port de surveillance des performances UAP",
    "cms.datasource.message.testfailed" : "Impossible de tester la connexion.",
    "cms.callecenter.field.setbmspwd" : "Mot de passe CCBMS",
    "cms.calllink.field.leaveReason89" : "Après qu'un agent a appelé l'interface de réponse, l'agent ne se connecte pas à l'utilisateur et l'utilisateur raccroche.",
    "cms.report.field.maxIVRInCount" : "Max. appels entrants IVR simultanés",
    "cms.monitordetail.field.idle" : "Idle",
    "cms.calllink.field.leaveReason88" : "L'utilisateur raccroche après que l'agent s'est connecté à l'utilisateur.",
    "cms.reportsubscribe.field.monday" : "Lundi",
    "cms.systemmonitor.label.thirtyonedays" : "31 Jours",
    "cms.report.field.acdCallsField" : "Nombre total d'appels connectés",
    "cms.datasource.message.dsmodifyconfirm" : "La source de données a été référencée dans le centre d'appels et ne prend effet qu'après le redémarrage de l'environnement. Êtes-vous sûr de vouloir le modifier ?",
    "cms.reportsubscribe.field.sentTime" : "Temps envoyé",
    "cms.callcenterinstanceslist.field.ccGatewayPort" : "Port CC-Gateway",
    "cms.errormessage.inputspecialchar" : "La valeur contient des caractères spéciaux non valides.",
    "cms.systemmonitor.message.timeerror" : "* L'heure de début doit être plus tôt à l'heure de fin.",
    "cms.indexmonitor.field.outboundCallNum" : "Appels sortants",
    "cms.failurecall.field.search" : "Rechercher",
    "cms.fullscreen.message.thresholdLessThan" : "Doit être supérieur à la valeur minimale",
    "cms.report.message.ringTimeDesc" : "Durée totale de sonnerie d'un agent, à l'exclusion de la durée de l'appel.",
    "cms.calllink.field.callType33" : "Appel entrant commun",
    "cms.fullscreen.field.hisMonitor" : "Statistiques historiques des indicateurs",
    "cms.calllink.field.callType32" : "Appel interurbain entrant",
    "cms.calllink.field.callType31" : "Appel interne",
    "cms.systemmonitor.field.systemInBound" : "Appels entrants système",
    "cms.calllink.field.callType30" : "Appel sortant commun",
    "cms.report.message.IVROutCallNumDesc" : "Nombre d'appels sortants effectués uniquement par le RVI.",
    "cms.agentmonitor.label.idleState" : "Idle",
    "cms.vdnconfiguration.title.success" : "Succès",
    "cms.monitordetail.field.agentReleaseCount" : "L'agent lance des appels",
    "cms.developerlist.message.refreshConfirmMessage" : "Êtes-vous sûr de vouloir mettre à jour le SK du développeur?",
    "cms.report.placeholder.workName" : "Saisissez un nom d'agent.",
    "cms.report.message.avgCallPerHourDesc" : "Nombre moyen d'appels répondus par un agent par heure dans le segment de temps de travail sélectionné.",
    "cms.indexmonitor.message.agentConnectInThree" : "Pourcentage du nombre d'appels connectés manuellement en moins de 3s dans le nombre total d'appels connectés manuellement.",
    "cms.report.field.avgCallPerHourField" : "Moyenne des appels entrants manuels par heure",
    "cms.indexmonitor.message.skillLostRingDesc" : "Nombre d'appels sans réponse après avoir été attribués aux agents",
    "cms.report.field.userBusyFailNumField" : "Appels perdus en raison de lignes occupées",
    "cms.report.message.outAnswerRateDesc" : "Pourcentage du nombre d'appels sortants connectés dans le nombre d'appels sortants.",
    "cms.report.field.inCallSuccRate" : "Taux de connexion des appels entrants",
    "cms.calllink.field.callType29" : "Appel sortant manuel",
    "cms.calllink.field.callType28" : "Type d'appel sortant de l'agent",
    "cms.indexmonitor.message.agentConnectIn5Rate" : "Pourcentage du nombre d'appels connectés manuellement dans les 5 secondes (y compris 5 secondes) par rapport au nombre total d'appels.",
    "cms.calllink.field.callType27" : "Appel sortant PRI",
    "cms.indexmonitor.field.skillConnectedIn5" : "Appels connectés en 5s",
    "cms.calllink.field.callType26" : "Appel sortant IVR",
    "cms.calllink.field.callType25" : "Demande de rappel PRI",
    "cms.report.field.systemInBoundField" : "Total des appels entrants du système",
    "cms.failurecall.field.userPhone" : "Numéro de service",
    "cms.indexmonitor.field.skillConnectedIn3" : "Appels connectés en 3s",
    "cms.report.message.waitEndDesc" : "Indique l'heure de fin de l'appel en attente sur l'appareil actuel.",
    "cms.calllink.field.callType24" : "Prévisualisation de l'appel sortant PRI",
    "cms.fullscreen.message.addLayout" : "Impossible d'ajouter le canevas car le canevas est plein.",
    "cms.calllink.field.callType23" : "Appels entrants et sortants PRI virtuels",
    "cms.report.field.outAnswerRateField" : "Taux de connexion des appels sortants",
    "cms.report.field.week" : "Semaine",
    "cms.calllink.field.callType22" : "Appel sortant PRI préconnecté",
    "cms.calllink.field.callType21" : "Appel sortant PRI préoccupé",
    "cms.calllink.field.callType20" : "Appel d'aide à trois parties",
    "cms.datasource.field.dbUrl" : "Chaîne de connexion à la base de données",
    "cms.callcenterinstanceslist.field.kafkaPassword" : "Mot de passe KAFKA",
    "cms.report.field.playVoiceTime" : "Durée de lecture de la voix",
    "cms.report.field.inCallRate" : "Durée moyenne des appels",
    "cms.developerlist.label.create" : "Ajouter",
    "cms.report.title.IVRAccessMonitor" : "Surveillance des statistiques sur les données IVR par code d'accès",
    "cms.report.message.maxAgentOutCountDesc" : "Nombre maximal d'appels sortants simultanés dont le type de périphérique de service est agent.",
    "cms.report.message.nowTimeCheck" : "L'heure dépasse l'heure actuelle",
    "cms.qcrelationconf.field.agentWorkNo" : "ID de la partie inspectée",
    "cms.fullscreen.field.weighted" : "Poids de la police",
    "cms.report.message.agentTalkDurationDesc" : "Durée(s) de conversation de l'appelant",
    "cms.callcenterinstanceslist.field.systemAdmin" : "ID administrateur CTI",
    "cms.report.field.billInfo1Field" : "Type d'appel vocal",
    "cms.report.field.minQueueOutCountField" : "Appels sortants en file d'attente de compétences simultanées min",
    "cms.indexmonitor.title.success" : "Succès",
    "cms.calllink.field.callType19" : "Appel d'aide bipartite",
    "cms.indexmonitor.field.agentBoundConnectRate" : "Appels entrants manuels connectés",
    "cms.indexmonitor.field.agentOutCallCount" : "Nombre d'appels sortants d'agents en ligne",
    "cms.calllink.field.callType18" : "Appel d'affaires RBT",
    "cms.calllink.field.callType17" : "Appel interurbain entrant international",
    "cms.calllink.field.callType16" : "Demande de rappel",
    "cms.report.field.IVRInBoundField" : "Appels entrants IVR",
    "cms.calllink.field.callType15" : "Prévisualisation de l'appel sortant",
    "cms.report.field.agentOutSuccField" : "Appels sortants manuels connectés",
    "cms.calllink.field.callType14" : "Appel sortant prévu",
    "cms.calllink.field.callType13" : "Appel sortant préconnecté",
    "cms.mediaType20" : "Appel non en temps réel",
    "cms.mediaType53": "Multimédia",
    "cms.report.message.playVoiceTimeDesc" : "Durée du jeu de voix.",
    "cms.calllink.field.callType12" : "Appel sortant préoccupé",
    "cms.report.message.agentOfferedDesc" : "Nombre total d'appels entrants demandant des services d'agent.",
    "cms.callcenterinstanceslist.message.passValidateFailed" : "Le mot de passe doit contenir au moins trois types de: majuscules, minuscules, chiffres, et characters: {'~! @ # $ % ^ & * () -'} spéciales suivantes {'_ = + | [ { } ] ; : \' \", <. > /?'}",
    "cms.calllink.field.callType11" : "Appel sortant réservé",
    "cms.calllink.field.callType10" : "Appel sortant (agent international)",
    "cms.skilladjustment.message.tipsSelectSkill" : "Sélectionnez une file d'attente de compétences.",
    "cms.agentMonitor.label.forcebusy_success" : "Un agent est placé de force à l'état occupé avec succès.",
    "cms.report.field.outCallRate" : "Taux de connexion des appels sortants",
    "cms.report.field.maxQueueCount" : "Max. appels de file d'attente de compétences simultanées",
    "cms.report.title.callCenterAccessMonitor" : "Surveillance des performances VDN par code d'accès",
    "cms.calllink.field.leaveReason54" : "Le taux d'achèvement des appels est inférieur au seuil.",
    "cms.report.field.outOccupyNum" : "Appels sortants",
    "cms.calllink.field.leaveReason53" : "Le participant est retiré de la conférence.",
    "cms.report.message.inVdnAvgTimeDesc" : "Durée moyenne des appels entrants dans le VDN. La valeur est la durée totale des appels entrants divisée par le nombre d'appels entrants.",
    "cms.calllink.field.leaveReason52" : "Le président publie la conférence de façon proactive, ou la conférence est publiée en raison d'une exception CSC.",
    "cms.sendnote.message.tipsSuccess" : "La note est envoyée avec succès.",
    "cms.calllink.field.leaveReason51" : "Un utilisateur appelle pour entrer une conférence téléphonique.",
    "cms.calllink.field.leaveReason50" : "L'agent rejette un appel",
    "cms.voiceinspection.field.queryBeginTime" : "Heure de début",
    "cms.report.field.reportPeriod" : "Étape statistique",
    "cms.recorddrive.field.number" : "Non.",
    "cms.monitordetail.field.media" : "Multimédia",
    "cms.indexmonitor.label.CommercialUse" : "Utilisation commerciale",
    "cms.mediaType19" : "Appel vidéo commun",
    "cms.uapmonitor.field.callIpSize" : "Type d'IP",
    "cms.mediaType18" : "Appel vidéo click-to-dial",
    "cms.mediaType17" : "Médias de message",
    "cms.mediaType16" : "Prévisualisation de l'appel sortant",
    "cms.indexmonitor.label.callOutIVR" : "Nombre d'appels sortants en ligne IVR",
    "cms.indexmonitor.message.skillOnlineConnectedDesc" : "Nombre d'appels auxquels ont répondu les agents dans le bloc actuel de 5 minutes. Des blocs de 5 minutes vont de 00:00 à 00:05, 00:05 à 00:10, et ainsi de suite.",
    "cms.indexmonitor.message.skillLimit": "Sélectionnez 1 à 10 files d'attente de compétences.",
    "cms.monitordetail.field.adjustCount" : "L'agent entre dans l'état d'organisation",
    "cms.mediaType15" : "Appel sortant prévu",
    "cms.reportsubscribe.field.sentReport" : "Rapport envoyé",
    "cms.mediaType14" : "Appel OPS",
    "cms.mediaType13" : "Appel vidéo RNIS 6B+D",
    "cms.report.field.maxQueueOutCountField" : "Appels sortants en file d'attente de compétences simultanées max",
    "cms.mediaType12" : "Appel vidéo RNIS 2B+D",
    "cms.fullscreen.field.simsun" : "SimSun",
    "cms.mediaType11" : "Transfert de fichiers",
    "cms.mediaType10" : "Partage d'applications",
    "cms.sysparam.config.value" : "Valeur de paramètre",
    "cms.uapmonitor.labe.eidoverlength" : "Le nombre de segments de numéro de terminal dépasse la limite supérieure : 10.",
    "cms.report.message.busyCountDesc" : "Nombre de fois qu'un agent entre dans l'état occupé.",
    "cms.calllink.field.leaveReason48" : "Répondre à un appel dans la file d'attente principale dans la file d'attente secondaire.",
    "cms.monitordetail.field.busyCount" : "L'agent entre dans l'état occupé",
    "cms.calllink.field.leaveReason47" : "Appel mis en file d'attente dans plusieurs files d'attente.",
    "cms.calllink.field.leaveReason46" : "Agent injoignable, reroutage CDN",
    "cms.calllink.field.leaveReason45" : "Saisie du PGR de la consultation de l'agent.",
    "cms.fullscreen.field.indexDimension" : "Objet surveillé",
    "cms.calllink.field.leaveReason44" : "Acheminer les appels entrants vers PGR.",
    "cms.calllink.field.leaveReason43" : "Le CDN retourne une route normale au PGR.",
    "cms.calllink.field.leaveReason42" : "L'itinéraire CDN s'éteint et entre dans le PGR.",
    "cms.calllink.field.leaveReason41" : "Annulation de l'agent ou défaillance du routage pendant la consultation.",
    "cms.calllink.field.leaveReason40" : "Délai d'expiration du routage CDN.",
    "cms.report.field.endTime" : "Date de fin",
    "cms.qualitycheck.message.saveSuccess" : "Enregistrer avec succès.",
    "cms.report.field.callTypeField" : "Type d'appel",
    "cms.calllink.field.activeRelease" : "L'abonné déconnecte un appel.",
    "cms.monitordetail.field.restoutduration" : "Durée du délai d'attente de repos",
    "cms.report.field.abortQueueWaitNumField" : "Appels de file d'attente abandonnés",
    "cms.fullscreen.field.noanswerCountDesc" : "Nombre de réponses retardées par les agents après l'attribution des appels.",
    "cms.report.message.inputZipPassword": "Veuillez saisir le mot de passe de compression",
    "cms.report.task.sucess.export": "Une tâche d'exportation a été créée. Êtes-vous sûr de vouloir le voir dans la liste d'exportation ?",
    "cms.report.task.export.records": "Exportation des enregistrements de contacts",
    "cms.report.field.length.limit": "La valeur doit être un nombre avec un maximum de 0 caractères.",
    "cms.report.field.number": "Numéro non valide",
    "cms.report.field.view.export.task": "Afficher les tâches d'exportation",
    "cms.report.field.enter.callin": "Veuillez entrer le numéro de série de l'appel entrant",
    "cms.agent.lable.alarm"    : "Alarme",
    "cms.agent.label.agentlist" : "Liste des agents",
    "cms.agent.label.agentdetailInfo" : "Détails de l'agent",
    "cms.agent.label.enterdetail"  : "Voir les détails",
    "cms.agent.label.statetime" : "Durée de l'état",
    "cms.agent.label.duracount" : "Statistiques de durée",
    "cms.agent.label.numcount" : "Statistiques de temps",
    "cms.agent.label.agentname" : "Nom de l'agent",
    "cms.agent.label.logintime"  : "Durée en ligne",
    "cms.agent.label.idlenum"  : "Temps d'inactivité",
    "cms.agent.label.arrangetime" : "Durée du tri",
    "cms.agent.label.logouttime" : "Durée hors ligne",
    "cms.agent.label.alarmstate" : "État de l'alarme",
    "cms.input.format.invalid": "Le format d'entrée est incorrect.",
    "cms.normal.text.validate": "Seuls les chiffres, les lettres et -._{'@'} sont autorisés",
    "cms.calllind.field.deviceUnkown" :"Périphérique inconnu",
    "cms.agent.label.signinphone": "Nombre total d'appels",
    "cms.agent.label.currentagentstate" : "Statut actuel de l'agent",
    "cms.agent.label.currentstatetime": "Durée de l'état actuel",
    "cms.agent.label.choosestatetime": "Durée minimale de sélection de l'état",
    "cms.agent.label.busyitem":"Affichage occupé",
    "cms.agent.label.talkitem":"Appel",
    "cms.agent.label.online":"EN LIGNE",
    "cms.agent.label.offline":"Hors ligne",
    "cms.agent.label.callinrate": "Taux d'achèvement des appels entrants",
    "cms.agent.label.callintime": "Durée de l'appel entrant",
    "cms.agent.label.avgcallintime":"Durée moyenne des appels entrants",
    "cms.agent.label.avgwaittime": "Durée moyenne d'attente des appels entrants",
    "cms.agent.label.maxwaittime":"Durée maximale d'attente d'appel entrant",
    "cms.agent.label.calloutsuccrate":"Taux d'achèvement des appels sortants",
    "cms.agent.label.callouttime": "Durée de l'appel sortant",
    "cms.agent.label.avgcallouttime":"Durée moyenne des appels sortants",
    "cms.agent.label.choosesybmediatype":"Sélectionner le type de canal",
    "cms.agent.label.busytime":"Durée de l'indication d'occupation",
    "cms.agent.label.avgbusytime":"Durée moyenne de l'affichage occupé",
    "cms.agent.label.acgtalktime":"Durée moyenne de l'appel",
    "cms.agent.label.outresttime":"Intervalle de temporisation de repos",
    "cms.agent.label.totaltalktime":"Durée totale de l'appel",
    "cms.agent.label.redirtnum":"Redirections",
    "cms.agent.label.totaltalknums":"Nombre total d'appels",
    "cms.agentmonitor.label.dayStatistic": "Statistiques d'aujourd'hui",
    "cms.agentmonitor.label.countAndTime": "Temps/Durée",
    "cms.agentmonitor.label.Performance": "Performances",
    "cms.agentmonitor.label.signInDetail": "Détails de l'enregistrement",
  "cms.agentmonitor.syncScreenMonitor.getVrpIpError": "L'agent inspecté ne s'est pas connecté au client VRC",
  "cms.agentmonitor.syncScreenMonitor.agentStatus": "Veuillez vous connecter avant de surveiller",
    "cms.agentmonitor.label.idleStatistic": "Indication inactive",
    "cms.agent.tips.selectstatetime": "Sélectionnez la durée de l'état. Vous devez sélectionner l'état actuel",
    "cms.agent.label.selectstatetime":">= Durée de l'état (minutes)",
    "cms.agent.tips.sipaccountcheck":"Sélectionnez un entier positif de 1 à 32 chiffres comme numéro d'enregistrement.",
    "cms.indexmonitor.field.totalAgentConnectCallTime": "Durée de l'état de l'appel",
    "cms.indexmonitor.title.agentDate":"Données de l'agent",
    "cms.agent.tips.notassined":"Non attribué",
    "cms.indexmonitor.set.limitA": "Sélectionnez",
    "cms.indexmonitor.set.limitB": "indicateurs pour l'affichage.",
    "cms.indexmonitor.titile.indicatorSetting": 'Réglage de l\'indicateur',
    "cms.indexmonitor.realTime.indicator": "Métriques en temps réel",
    "cms.indexmonitor.index.multimediaCalls": "Multimédia",
    "cms.indexmonitor.index.callTrends": "Tendances de l'appel",
    "cms.indexmonitor.index.skillQueueServiceLevel": "Présentation du niveau de service de la file d'attente de compétences",
    "cms.indexmonitor.index.met": "atteint",
    "cms.indexmonitor.index.missed": "Pas atteint",
    "cms.incoming.record.not.sameday":"La requête interjournée n'est pas prise en charge.",
    "cms.requiredcallback.message.allowTimeRange":"Un maximum de sept jours peuvent être interrogés.",
    "cms.incoming.record.field.releasecause":"Cause de libération d'appel",
    "cms.incoming.record.field.releasecause.input":"Veuillez saisir la raison de la libération de l'appel.",
    "cms.incoming.record.field.releasecause.id":"Entrez le code de cause de libération d'appel.",
    "cms.incoming.record.field.releasecause.causecode":"Entrez le code de mappage de raison.",
    "cms.incoming.record.field.ifReqManual": "Vérifier si le travail manuel a été demandé",
    "cms.incoming.record.field.isReqManual": "La main-d'œuvre a été demandée",
    "cms.incoming.record.field.notReqManual": "La main-d'œuvre n'est pas demandée",
    "cms.incoming.record.field.ifAnswerCall":"Répondre manuellement à l'appel",
    "cms.incoming.record.field.isAnswerCall":"Réponse manuelle",
    "cms.incoming.record.field.notAnswerCall":"Aucune réponse manuelle",
    "cms.incoming.record.field.releasecause.choose":"Sélectionner la cause de libération d'appel",
    "cms.incoming.record.field.release.id": "Code de cause de libération d'appel",
    "cms.incoming.record.field.release.causecode":"Code de mappage de cause",
    "cms.incoming.releasecause.causecode.tip": "Le code de mappage de cause est hors de la plage (0~45)",
    "cms.incoming.record.report.beginTime":"Heure de début de la requête",
    "cms.incoming.record.report.endTime": "Heure de fin de requête",
    "cms.requiredcallback.message.timeRangeCheck": "La plage de temps est le même jour",
    "cms.failurecall.field.waitTime.export":"Durée d'attente (s)",
    "cms.failurecall.field.waitBegin":"Heure de début d'appel en attente",
    "cms.failurecall.field.ackBegin":"Heure de début de réponse d'appel",
    "cms.failurecall.field.waitAnsNum":"Heures d'attente de réponse",
    "cms.failurecall.field.waitAnsTime":"Durée d'attente de réponse (seconde)",
    "cms.failurecall.field.succWaitAnsTime":"Durée de réponse d'appel réussie, en secondes",
    "cms.failurecall.field.noAckNum":"Nombre d'appels sans réponse",
    "cms.failurecall.field.succQueueWaitTime":"Durée de file d'attente d'appels réussis, en secondes",
    "cms.failurecall.field.succQueueWaitNum":"Nombre d'appels réussis en file d'attente",
    "cms.failurecall.field.failQueueWaitNum":"Durée d'attente de perte d'appel (seconde)",
    "cms.failurecall.field.inOccupyNum":"Nombre de demandes manuelles",
    "cms.failurecall.field.inCallSuccNum":"Nombre d'appels manuels",
    "cms.failurecall.field.inCallTime":"Durée de l'appel manuel (seconde)",
    "cms.failurecall.field.queOverToIvrNum":"Nombre d'appels de la file d'attente qui sont transférés à l'IVR raccrochés",
    "cms.failurecall.field.queOverToIvrTime":"Temps total de raccrochage de la file d'attente vers l'IVR, en secondes",
    "cms.failurecall.field.hangUpNum":"Nombre de transferts manuels vers IVR",
    "cms.failurecall.field.hangUpTime":"Temps total de transfert manuel vers IVR",
    "cms.failurecall.field.hangUpReleaseNum":"Nombre d'appels raccrochés transférés manuellement à l'IVR",
    "cms.indexmonitor.index.noTrafficData": "Pas de données de trafic",
    "cms.indexmonitor.index.SkillQueueTips": "Le niveau de service de la file d'attente de compétences est mesuré en fonction du taux d'achèvement des appels dans les 20 secondes. Le niveau de service attendu par défaut est de 80%. L'administrateur locataire peut modifier le niveau de service de la file d'attente de compétences.",
    "cms.indexmonitor.index.SkillQueueTips2": "Le niveau de service d'une file d'attente de compétences est mesuré en fonction du taux d'achèvement des appels dans les 20 secondes. Le niveau de service attendu par défaut est de 80%. L'administrateur système peut modifier les paramètres en choisissant Paramètres système > Surveillance du centre d'appels.",
    "cms.indexmonitor.index.loadFail": "Échec du chargement",
    "cms.failurecall.queue.abandon": "Appel abandonné",
    "cms.failurecall.callback":"Appel requérant un rappel",
    "cms.faliurecall.incomingcall": "Appel entrant",
    "cms.vcallecenter.message.checkpasswordfailed": "Impossible de vérifier la règle du mot de passe de connexion",
    "cms.agent.field.admin":"Administrateurs",
    "cms.agent.field.commonagent":"Préposés",
    "cms.agent.field.qualitychecker":"Inspecteur qualité",
    "cms.failure.callerNo.maxLength.error":"Un maximum de caractères 64 est autorisé.",
    "cms.indexmonitor.curDay.indicator":"Indicateurs du jour en cours",
    "cms.agent.label.agentDetail":"Détails de l'agent",
    "cms.custdata.operlog.popwindowmessage":"Saisissez le mot de passe utilisé pour générer le fichier compressé. Le mot de passe compressé doit contenir des lettres, des chiffres et des caractères spéciaux {'@'} %",

  "cms.report.title.submediaType" : "Type de multimédia",
  "cms.multimedia.monitor.field.callNum":"Nombre d'appels IVR",
  "cms.multimedia.monitor.field.transNum":"Nombre d'appels RVI-manuels",
  "cms.multimedia.monitor.field.transRate":"Taux IVR-à-Manuel",
  "cms.multimedia.monitor.field.connectedNum":"Nombre d'appels IVR connectés",
  "cms.multimedia.monitor.field.connectedRate":"Taux d'achèvement des appels IVR",
  "cms.multimedia.monitor.field.durationAverage":"Durée moyenne des appels IVR",
  "cms.multimedia.monitor.field.callNum.desc":"Nombre d'appels actuellement traités automatiquement par le dispositif IVR",
  "cms.multimedia.monitor.field.transNum.desc":"Nombre d'appels RVI-manuels",
  "cms.multimedia.monitor.field.transRate.desc":"Pourcentage d'appels RVI-manuels aux appels RVI",
  "cms.multimedia.monitor.field.connectedNum.desc":"Nombre d'appels IVR dont la durée est supérieure à 0",
  "cms.multimedia.monitor.field.connectedRate.desc":"Pourcentage du nombre d'appels RVI connectés par rapport au nombre d'appels RVI",
  "cms.multimedia.monitor.field.durationAverage.desc":"Durée totale des appels SVI/Nombre d'appels SVI connectés",
  "cms.report.field.submediatype.instagram": "Instagram",
  "cms.report.field.submediatype.telegram": "Telegram",
  "cms.qcrelationconf.field.attendantGroupName": "Groupe d'agents",
  "cms.report.field.selectTrafficGroup": "Sélectionner un groupe d'agents",
  "cms.report.placeholder.attendantGroupName": "Veuillez entrer le nom du groupe d'accompagnateur.",
  "cms.qcrelationconf.field.attendantGroup": "Groupe de préposés",
  "cms.report.message.selectTrafficGroupMax": "Un maximum de 1000 groupes d'accompagnateurs peut être sélectionné.",
  "cms.agentMonitor.message.operatorChecked": "Échec de l'opération. L'objet d'inspection est un inspecteur et l'opération d'inspection est en cours",
  "cms.export.message.required": "Champ obligatoire",
  "cms.field.length.limit": "La longueur ne peut pas dépasser 0",
  "cms.sysparameter.batchreset": "Réinitialisation par lots",
  "cms.sysparameter.batchrefresh": "Synchronisation par lots",
  "cms.report.subscribe.add": "Nouvel abonnement au rapport",
  "cms.report.subscribe.edit": "Modifier l'abonnement au rapport",
  "cms.qcrelationconf.tab.member": "Membres",
  "cms.qcrelationconf.add.attendantGroup": "Ajouter un groupe de trafic inspecté",
  "cms.qcrelationconf.message.isDeleteTrafficGroup": "Êtes-vous sûr de supprimer le groupe d'appels",
  "cms.report.subscribe.checkreportnum": "Sélectionner au moins un rapport",
  "cms.sysparameter.edit.basic.information": "Modifier les informations de base",
  "cms.sysparameter.edit.character": "Modifier les propriétés",
  "cms.sysparameter.edit.validate.ip": "Entrez une adresse IP correcte.",
  "cms.message.choose": "Veuillez sélectionner les données.",
  "cms.subscribe.template.isused": "Modèle d'e-mail déjà utilisé",
  "cms.subscribe.template.statuserror": "Erreur d'état du modèle de courrier.",
  "cms.qcrelationconf.message.unauthorized": "Les données sauvegardées contiennent des agents non valides",
  "cms.agentMonitor.label.changestate_error":"Échec de la commutation de l'état de l'inspection, erreur d'opération",
  "cms.fullscreen.agent.auth": "Pas d'autorisation pour voir l'organisation actuelle. Veuillez en sélectionner une autre",
  "cms.report.subscribe.editreport":"Modifier le rapport",
  "cms.report.subscribe.addreport":"Ajouter un rapport",
  "cms.agent.label.systempara":"Configuration des paramètres",
  "cms.indexmonitor.message.skillConnectedQueue": "Durée totale de mise en file d'attente de tous les appels connectés",
  "cms.indexmonitor.message.skillAbandonQueue": "La durée totale de tous les appels non connectés dans la file d'attente",
  "cms.indexmonitor.field.skillConnectedQueue": "Durée totale de la file d'attente",
  "cms.indexmonitor.field.skillAbandonQueue": "Durée totale de la file d'attente en cas de perte d'appel",
}