export default {
  'oifde.traceCause.000001': 'The transfer type is not configured for the Call transfer diagram element.',
  'oifde.traceCause.000002': 'The interface is abnormal because the input value of inaction is incorrect. {0}',
  'oifde.traceCause.000003': 'The composite diagram element is not found.',
  'oifde.traceCause.000004': 'The start node is not found.',
  'oifde.traceCause.000005': 'The number of internal recycles exceeds the limit.',
  'oifde.traceCause.000006': 'The diagram element processing is abnormal.',
  'oifde.traceCause.000007': 'The exit branch is empty.',
  'oifde.traceCause.000008': 'Internal system error.',
  'oifde.traceCause.000009': 'The branch is not found. {0}',
  'oifde.traceCause.000010': 'The Flow transfer diagram element cannot be transferred to the next node. {0}',
  'oifde.traceCause.000011': 'The diagram element type is incorrect.',
  'oifde.traceCause.000012': 'Expression {0} execution error.',
  'oifde.traceCause.000013': 'Failed to execute the third-party interface.',
  'oifde.traceCause.000014': 'The response template and  variable are not selected.',
  'oifde.traceCause.000015': 'The SMS template is not configured for the SMS sending diagram element.',
  'oifde.traceCause.000016': 'The language of the response template is inconsistent with the set language. {0}',
  'oifde.traceCause.000017': 'The language of the SMS template is different from the language set in the SMS sending diagram element. {0}',
  'oifde.traceCause.000018': 'The target flow and target node are not configured for the Flow transfer diagram element.',
  'oifde.traceCause.000019': 'No tenant is found.',
  'oifde.traceCause.000020': 'The tenant has been suspended.',
  'oifde.traceCause.000021': 'The module is initialized more than once.',
  'oifde.traceCause.000022': 'The composite diagram element does not have a start node.',
  'oifde.traceCause.000023': 'Internal system error.',
  'oifde.traceCause.000024': 'The internal flow execution is abnormal.',
  'oifde.traceCause.000025': 'Automatic hangup is performed if internal exceptions occur more than three times.',
  'oifde.traceCause.000026': 'The flow is not initialized.',
  'oifde.traceCause.000028': 'The flow has ended.',
  'oifde.traceCause.000029': 'No exit is found in the current diagram element whose node ID is {0} and node name is {1}.',
  'oifde.traceCause.000030': 'Data of the diagram element whose node ID is {0} and node name is {1} is abnormal.',
  'oifde.traceCause.000031': 'Internal system error.',
  'oifde.traceCause.000032': 'The internal flow execution is abnormal.',
  'oifde.traceCause.000033': 'The call instance information does not exist.',
  'oifde.traceCause.000034': 'The Call ending diagram element does not exist.',
  'oifde.traceCause.000035': 'An error occurred when checking auth_token.',
  'oifde.traceCause.000036': 'An error occurred when checking auth_token.',
  'oifde.traceCause.000037': 'An error occurred when checking auth_token.',
  'oifde.traceCause.000038': 'Failed to process the next node.',
  'oifde.traceCause.000039': 'Failed to invoke the TUC. {0}',
  'oifde.traceCause.000040': 'The error is not found. {0}',
  'oifde.traceCause.000041': 'Stored procedure execution error. {0}',
  'oifde.traceCause.000044': 'The URL (IP address and port number) is not in the trustlist.',
  'oifde.traceAdvice.000001': 'Configure the call transfer type for the Call transfer diagram element.',
  'oifde.traceAdvice.000002': 'Contact the system administrator to modify input parameters of the interface.',
  'oifde.traceAdvice.000003': 'Check whether the composite diagram element exists in the system.',
  'oifde.traceAdvice.000004': 'Check whether the flow is released or whether the flow is normal.',
  'oifde.traceAdvice.000005': 'Contact the system administrator to check the system.',
  'oifde.traceAdvice.000006': 'Contact the system administrator to check whether the diagram element logic is correct.',
  'oifde.traceAdvice.000007': 'Contact the system administrator to check whether the diagram element logic is correct.',
  'oifde.traceAdvice.000008': 'Contact the system administrator to check the system.',
  'oifde.traceAdvice.000009': 'Check whether the exit of the current diagram element has unprocessed branches. It is recommended that all diagram elements have a default unconditional branch.',
  'oifde.traceAdvice.000010': 'Check whether the Flow transfer diagram element is correctly configured.',
  'oifde.traceAdvice.000011': 'Contact the system administrator to check the system.',
  'oifde.traceAdvice.000012': 'Check whether the syntax configured for the expression is correct and whether variables in the expression have values.',
  'oifde.traceAdvice.000013': 'Check whether the third-party interface is normal.',
  'oifde.traceAdvice.000014': 'Check the Response diagram element to ensure that at least one of the response template or response variable has a value.',
  'oifde.traceAdvice.000015': 'Check whether the SMS template is configured for the SMS sending diagram element.',
  'oifde.traceAdvice.000016': 'Check the content and language configuration in the resource template, built-in language in the global variable, and whether a new value is assigned to the language in the flow orchestration. The language used for playback must be configured in the resource template.',
  'oifde.traceAdvice.000017': 'Check the content and language configuration in the resource template, built-in language in the global variable, and whether a new value is assigned to the language in the flow orchestration. The language used for playback must be configured in the resource template.',
  'oifde.traceAdvice.000018': 'Check whether the Flow transfer diagram element is correctly configured.',
  'oifde.traceAdvice.000019': 'Check whether the access code transferred by the dialog interface is normal and exists in the system.',
  'oifde.traceAdvice.000020': 'Check whether the tenant status is normal.',
  'oifde.traceAdvice.000021': 'Check whether the third-party app or IVR repeatedly sends an initialization request with the same callid</b>.',
  'oifde.traceAdvice.000022': 'Check whether the composite diagram element is normal and exists in the system.',
  'oifde.traceAdvice.000023': 'Contact the system administrator to check the system.',
  'oifde.traceAdvice.000024': 'Check the error information of each diagram element and rectify errors accordingly.',
  'oifde.traceAdvice.000025': 'Contact the system administrator to check the system.',
  'oifde.traceAdvice.000026': 'The execution flow is abnormal. You need to request to invoke the initialization interface first.',
  'oifde.traceAdvice.000028': 'Check whether the flow is normal.',
  'oifde.traceAdvice.000029': 'Check whether a node exit is configured for the diagram element.',
  'oifde.traceAdvice.000030': 'Contact the system administrator to check whether the configuration of the diagram element is correct.',
  'oifde.traceAdvice.000031': 'Contact the system administrator to check the system.',
  'oifde.traceAdvice.000032': 'Check the error information of each diagram element and rectify errors accordingly.',
  'oifde.traceAdvice.000033': 'Check whether the interface for the hang-up request sends the incorrect callid</b>.',
  'oifde.traceAdvice.000034': 'Check whether the Call ending diagram element is correctly configured for the flow.',
  'oifde.traceAdvice.000035': 'Check whether the input auth_token is correct.',
  'oifde.traceAdvice.000036': 'Check whether the input auth_token is correct.',
  'oifde.traceAdvice.000037': 'Check whether the input auth_token is correct.',
  'oifde.traceAdvice.000038': 'Contact the system administrator to check the system.',
  'oifde.traceAdvice.000039': '1. Check whether the TUC address and port configured in the ODFS are normal. 2. Check whether services on each TUC node are normal.',
  'oifde.traceAdvice.000040': 'Check whether the stored procedure is normal.',
  'oifde.traceAdvice.000041': 'Check whether the stored procedure is normal.',
  'oifde.traceAdvice.000044': 'Contact the system administrator to add it to the trustlist.'
}
