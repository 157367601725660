<template>
  <aicc-full-screen ref="fullScreen" cardPadding="20" isCardType="true" :existConfig="['backHeader', 'tablePagination', 'checkedInfo']">
    <template #header>
      <aicc-advanced-search ref='advancedSearch'
                            :templateMetaData="templateMetaData"
                            @handleSearch="searchPageFirst"
                            @handleRefresh="handleRefresh"
                            @handleCommand="handleCommand"></aicc-advanced-search>
    </template>
    <template #main>
      <aicc-table ref='aiccTable'
                  :tableData="tableData"
                  :tableColumns="tableColumns"
                  :tableTools="{ showPagination: true, columnConfig: false, checkedInfo: true }"
                  :paginationConfig="paginationConfig"
                  :row-key="getKey"
                  @handlePagination="handlePagination">
        <template #calledNo="scope">
          <span>{{ scope.row.calledNo }}</span>
        </template>
        <template #action="scope">
          <sweet-button type="primary" text @click="handleView(scope.row)">{{ $t('isales.management.title.details') }}</sweet-button>
          <sweet-button type="primary" text @click="handleEdit(scope.row)">{{ $t('isales.management.title.edit') }}</sweet-button>
          <sweet-button type="primary" text @click="deleteData([scope.row.dataId])">{{ $t('isales.specialday.title.delete') }}</sweet-button>
        </template>
      </aicc-table>
    <!--导入-->
      <ImportCallData v-if="showImport"
                      v-model:show="showImport"
                      :taskId="taskId"
                      :taskType="taskInfo.taskType"
                      :policyType="policyType"
                      :marketingMode=1
                      @importSuccess="handleRefresh"></ImportCallData>
      <!--定时导入-->
      <ScheduledImportCallData v-if="showScheduledImport"
                               :taskId="taskId"
                               :taskType="taskInfo.taskType"
                               :policyType="policyType"
                               :marketingMode=1
                               v-model:show="showScheduledImport"></ScheduledImportCallData>
      <!--配置属性-->
      <ConfigProp v-if="showConfigProp"
                  :marketingMode="1"
                  :taskId="taskId"
                  :mode="tableData.length ? 'view' : 'edit'"
                  v-model:show="showConfigProp"></ConfigProp>
      <!--新建/修改/详情-->
      <DialogCallData v-if="showDialogCallData"
                      v-model:show="showDialogCallData"
                      :taskId="taskId"
                      :dataId="dataId"
                      :type="dialogCallDataType"
                      :taskType="taskInfo.taskType"
                      :currentData="currentData"
                      :policyType="policyType"
                      @refreshDataTable="handleRefresh"></DialogCallData>
      <!--导入结果，marketingMode是写死的-->
      <ImportResult v-if="showImportResult"
                    v-model:show="showImportResult"
                    :marketingMode="1"
                    :taskId="taskInfo.taskId"></ImportResult>
    </template>
  </aicc-full-screen>
</template>

<script>
import ImportCallData from '@/views/isales/components/OutboundCallData/ImportCallData.vue'
import ScheduledImportCallData from '@/views/isales/components/OutboundCallData/ScheduledImportCallData.vue'
import ConfigProp from '@/views/isales/components/OutboundCallData/ConfigProp.vue'
import DialogCallData from '@/views/isales/components/OutboundCallData/DialogCallData.vue'
import ImportResult from '@/views/isales/components/OutboundCallData/ImportResult.vue'
import {queryTaskCallData, queryCanBeCallData, deleteCallData} from '@/views/isales/api/taskManagement'
import { ElMessageBox } from 'element-plus'
import { RETURN_CODE } from '@/views/isales/views/taskManagement/enum'
import {queryCallPolicy} from "@/views/isales/api/systemAutoCallTask"
import {ElLoading} from 'element-plus'
import appConfig from '@/config'

export default {
  name: 'ConfigData',
  components: {
    ImportCallData,
    ScheduledImportCallData,
    ConfigProp,
    DialogCallData,
    ImportResult
  },
  props: {
    taskInfo: Object,
    taskId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showImport: false,
      showScheduledImport: false,
      showConfigProp: false,
      showDialogCallData: false,
      showImportResult: false,
      templateMetaData: {
        basicConfig: { // 基础搜索配置
          iconButton: [
            {buttonType: 'refresh'}
          ], // 图标按钮
          colConfig:{
            lg:2, xl:2
          },
          iconAreaColConfig:{lg:1, xl:1},
          otherAreaColConfig:{lg:17, xl:18},
          otherOperation: [ // 右侧其他操作按钮
            {
              basicButton: true,
              buttonName: this.$t('isales.specialday.title.delete'), // 删除
              eventName: 'batchDeleteDatas'
            },
            {
              basicButton: true,
              buttonName: this.$t('isales.data.propConfig.button.configProp'), // 配置属性
              eventName: 'handleConfigProp'
            },
            {
              basicButton: true,
              buttonName: this.$t('isales.data.title.importData'), // 导入
              eventName: 'handleImport'
            },
            {
              basicButton: true,
              buttonName: this.$t('isales.data.title.timeImport'), // 定时导入
              eventName: 'handleScheduledImport'
            },
            {
              basicButton: true,
              buttonName: this.$t('isales.data.title.importResult'), // 导入结果
              eventName: 'handleImportResult'
            },
            {
              basicButton: true,
              buttonStyle: 'primary',
              buttonName: this.$t('isales.data.title.create'), // 新建
              eventName: 'handleCreateCallData'
            },
          ],
          basicSearch: [
            {
              searchType: 'primary', // primary: 有搜索图标的输入框；select: 下拉框; input: 搜索框；date: 日期选择器
              modelValue: 'sessionId', // v-model绑定的key值
              modelDefault: '', // v-model绑定的默认值
              placeholderValue: this.$t('isales.taskresult.title.inputSessionId'), // placeholder绑定的值 国际化之后的值
              changeEvent: 'handleSearch'
            }
          ]
        }
      },
      tableData: [],
      tableColumns: [
        {
          type: 'selection', // 多选
          width: '50px',
          'reserve-selection': true
        },
        {
          label: this.$t('isales.manual.field.handlednumber'), // 外呼标识
          prop: 'sessionId',
        },
        {
          label: this.$t('isales.manual.field.canBeCallCount'), // 可呼叫量
          prop: 'canBeCallCount', // 对应表数据对象数组中的键名
        },
        {
          label: this.$t('isales.common.title.operation'), // 操作
          slot: 'action'
        }
      ],
      paginationConfig: {
        pageSizes: [10, 20, 50, 100], // 每页条数列表
        pageSize: 10, // 每页条数
        total: 0, //总条数
        limit: 10,
        offset: 0,
        layout: appConfig.pagination.LAYOUT.FULL // 分页组件布局配置
      },
      policyType: null,
      dialogCallDataType: '',
      currentData: null,
      callMode: 0,
      tableHeight: ''
    }
  },
  async created() {
    const loadingInstance = ElLoading.service({fullscreen: true})
    await this.queryCallPolicyByTaskId();
    if(this.callMode !==1){
      this.tableColumns.splice(2, 0, {
        label: this.$t('isales.management.title.calledNo'), // 客户号码
        slot: 'calledNo', // 对应表数据对象数组中的键名
      });
    }
    await this.queryCallData();
    loadingInstance.close()
  },
  methods: {
    handleRefresh() {
      this.$refs.aiccTable.handleInitChange()
      this.$refs.aiccTable.table.clearSelection()
    },
    searchPageFirst(data) {
      this.paginationConfig.offset = 0;
      this.$refs.aiccTable.setCurrentPage(1);
      this.queryCallData(data);
    },
    handlePagination(data) {
      Object.assign(this.paginationConfig, data)
      this.queryCallData()
    },
    handleCommand(data) {
      const {eventName, params} = data
      this[eventName](params)
    },
    handleView(row) {
      this.dialogCallDataType = 'view'
      this.currentData = row
      this.dataId = row.dataId
      this.showDialogCallData = true
    },
    handleEdit(row) {
      this.dialogCallDataType = 'edit'
      this.currentData = row
      this.dataId = row.dataId
      this.showDialogCallData = true
    },
    handleImport() {
      this.showImport = true
    },
    handleScheduledImport() {
      this.showScheduledImport = true
    },
    handleConfigProp() {
      this.showConfigProp = true
    },
    handleImportResult() {
      this.showImportResult = true
    },
    handleCreateCallData() {
      this.dialogCallDataType = 'create'
      this.currentData = null
      this.showDialogCallData = true
    },
    async queryCallData() {
      const {limit, offset} = this.paginationConfig
      let params = this.$refs.advancedSearch.getFormatParams()
      const sessionId = params && params.sessionId ? params.sessionId : ''
      const param = {limit, offset, dataDO: {taskId: this.taskId, sessionId}, isConfigInit: true}
      const callDataResult = await queryTaskCallData(param)
      this.paginationConfig.total = callDataResult.count
      this.policyType = callDataResult.policyType || 0
      if (!callDataResult || !callDataResult.datas.length) {
        this.tableData = callDataResult.datas
        return
      }
      const dataIds = callDataResult.datas.map(item => item.dataId)
      const canBeCallDataList = await queryCanBeCallData({dataIds, taskId: this.taskId})
      callDataResult.datas.forEach(item => {
        let temp = canBeCallDataList.find(data => data.dataId === item.dataId)
        if (temp && temp.canBeCallCount) {
          item.canBeCallCount = temp.canBeCallCount
        }
      })
      this.tableData = callDataResult.datas
    },
    async queryCallPolicyByTaskId() {
      const callPolicy = await queryCallPolicy({"taskId": this.$route.query.taskId})
      console.log(callPolicy);
      if(callPolicy){
        this.callMode = callPolicy.callMode;
      }
    },
    //批量删除
    batchDeleteDatas() {
      const selectRows = this.$refs.aiccTable.table.getSelectionRows()
      if (selectRows.length <= 0) {
        this.$message({
          type: 'error',
          duration: 10000,
          offset: 56,
          'show-close': true,
          message: this.$t('isales.management.title.deleteInfo')
        })
      } else if (selectRows.length > 100) {
        this.$message({
          type: 'error',
          duration: 10000,
          offset: 56,
          'show-close': true,
          message: this.$t('isales.management.message.deleteCount')
        })
      } else {
        let dataIds = selectRows.map(item => item.dataId)
        this.deleteData(dataIds)
      }
    },
    //单条删除
    deleteData(dataIds) {
      ElMessageBox.confirm(this.$t('isales.management.message.deleteConfirm'), this.$t('isales.common.title.tipsInfo'), {
        confirmButtonText: this.$t('isales.common.title.confirm'),
        cancelButtonText: this.$t('isales.common.title.cancel'),
        'close-on-click-modal':false,
        type: 'info'
      }).then(async () => {
        let param = { deleteDataIds: dataIds.toString(), taskId: this.taskId }
        const { returnCode, leftCount, description } = await deleteCallData(param)
        if (returnCode === RETURN_CODE.SUCCESS) {
          if (leftCount <= this.queryOffset) {
            let page = Math.ceil(leftCount / this.queryLimit) - 1;
            this.queryOffset = page * this.queryLimit > 0 ? page * this.queryLimit : 0;
          }
          this.handleRefresh()
          this.$message({
            type: 'success',
            duration: 5000,
            offset: 56,
            message: this.$t('isales.management.message.deleteSuccess')
          })
        } else if (returnCode === '04000018') {
          this.$message({
            type: 'error',
            duration: 10000,
            offset: 56,
            'show-close': true,
            message: this.$t('isales.data.lock')
          })
        } else {
          this.$message({
            type: 'error',
            duration: 10000,
            offset: 56,
            'show-close': true,
            message: this.getDeleteErrorMsg(description)
          })
        }
      }).catch(() => {})
    },
    //根据删除失败返回的信息，得到正确的错误提示
    getDeleteErrorMsg(description) {
      if (description === 'taskId is empty') {
        return this.$t('isales.management.message.taskIdEmpty')
      } else if (description === 'The task status is error') {
        return this.$t('isales.management.message.deleteTaskstatusError')
      } else if (description === 'deleteDataIds is empty') {
        return this.$t('isales.management.message.deletedataIdsEmpty')
      } else if (description === 'deleteDataIds count is error') {
        return this.$t('isales.management.message.deleteCount')
      } else if (description === 'deleteDataIds is error') {
        return this.$t('isales.management.message.deletedataIdsError')
      } else {
        return this.$t('isales.management.message.deleteFailed')
      }
    },
    getKey(row){
      return row.dataId
    }
  }
}
</script>

<style scoped>
  .aicc-background-white {
    margin: 16px;
    padding: 20px;
    border-radius: 8px;
    height: calc(100vh - 179px) !important;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  }
</style>