<template>
    <div id="system-auto-call-task">
        <aicc-full-screen v-if="active === 0 || active === 1" ref="fullScreen"
                          :existConfig="['backHeader', 'footerAction']" targetSelector=".system-auto-task-main" >
            <template #main>
                <div ref="main" class="system-auto-task-main">
                    <div style="padding: 20px 16px;">
                        <!--步骤条-->
                        <sweet-steps class="aicc-step--left-right" :active="active" finish-status="success"
                                     align-center>
                            <sweet-step v-for="(step, index) in stepsList" :key="step.title"
                                        :title="step.title"></sweet-step>
                        </sweet-steps>
                        <!--任务信息-->
                        <sweet-form class="isales-task-info-form" :model="showModel" :rules="sysAutoRules"
                                    :label-position="labelPosition"
                                    label-width="auto" ref="sysAutoForm"
                                    :disabled="isView || (isBatchEdit && showModel.basicInfoDisabled) || isViewTemplate">
                            <!--基本信息-->
                            <sweet-card style="margin: 20px 0 16px;" v-show="active === 0">
                                <div class="card-header" style="margin: 0px 0px 10px 0px;">{{
                                    $t('isales.task.promotion.basicInfo') }}
                                    <sweet-icon v-show="isBatchEdit" class="aicc-m-left--small"
                                                @click="showModel.basicInfoDisabled = !showModel.basicInfoDisabled">
                                        <i v-show="showModel.basicInfoDisabled" class="sweetui-icon-edit-l"
                                           :title="$t('isales.data.title.edit')"></i>
                                        <i v-show="!showModel.basicInfoDisabled" class="sweetui-icon-undo"
                                           :title="$t('isales.data.title.undoEdit')"></i>
                                    </sweet-icon>
                                </div>
                                <SystemAutoTaskBasicInfo :showModel="showModel" :type="$route.query.type"
                                                         @templateSysSelectConfirm="templateSysSelectConfirm"></SystemAutoTaskBasicInfo>
                            </sweet-card>
                        </sweet-form>
                        <!--外呼时间-->
                        <sweet-form class="isales-task-info-form" :model="showModel"
                                    :label-position="labelPosition"
                                    label-width="auto"
                                    :disabled="isView || (isBatchEdit && showModel.callTimeDisabled) || isViewTemplate">
                            <sweet-card v-show="active === 0">
                                <div class="aicc-align--absolute-left card-header" style="margin: 0px 0px 10px 0px;">
                                    <div style="margin-right: 36px">{{$t('isales.task.title.callTime')}}</div>
                                    <sweet-icon v-show="isBatchEdit" class="aicc-m-left--small"
                                                @click="showModel.callTimeDisabled = !showModel.callTimeDisabled">
                                        <i v-show="showModel.callTimeDisabled" class="sweetui-icon-edit-l"
                                           :title="$t('isales.data.title.edit')"></i>
                                        <i v-show="!showModel.callTimeDisabled" class="sweetui-icon-undo"
                                           :title="$t('isales.data.title.undoEdit')"></i>
                                    </sweet-icon>
                                </div>
                                <CallTime :showModel="showModel" ref="callTimeRef"></CallTime>
                            </sweet-card>
                        </sweet-form>
                        <!--外呼策略-->
                        <TaskCallPolicy :showModel="showModel" :type="$route.query.type" v-show="active === 1"
                                        ref="taskCallPolicyRef"></TaskCallPolicy>
                    </div>
                </div>
            </template>
            <template #footer>
                <sweet-button @click="cancel">{{ $t('isales.button.cancel') }}</sweet-button>
                <sweet-button v-show="active === 1 && (isCreate || isEdit)" @click="saveAsTemplate">{{
                    $t('isales.tasktemplate.title.saveAsTemplate') }}
                </sweet-button>
                <sweet-button v-show="active === 0" type="primary" @click="nextStep">{{ $t('isales.task.title.nextStep')
                    }}
                </sweet-button>
                <sweet-button v-show="active === 1" @click="preStep">{{ $t('isales.task.title.preStep') }}
                </sweet-button>
                <sweet-button
                        v-show="active === 1 && !isBatchEdit && !isView && !isCreateTemplate && !isEditTemplate && !isViewTemplate"
                        :type="isCreate ? '' : 'primary'" @click="save">{{
                    $t('isales.common.title.save')
                    }}
                </sweet-button>
                <sweet-button
                        v-show="active === 1 && isCreate"
                        type="primary" @click="nextStepOnConfirm">{{
                    $t('isales.taskmanagement.title.saveAndImportData')
                    }}
                </sweet-button>
                <sweet-button v-show="active === 2 || (active === 1 && isBatchEdit) || (active === 1 && isView) || (active === 1 && isCreateTemplate)
                    || (active === 1 && isEditTemplate) || (active === 1 && isViewTemplate)"
                              type="primary" @click="confirm">{{ $t('isales.button.confirm') }}
                </sweet-button>
            </template>
        </aicc-full-screen>

        <!--外呼数据-->
        <OutboundCallData v-if="active === 2" :task-info="showModel.taskInfo"
                          :taskId="showModel.taskInfo.taskId"></OutboundCallData>
    </div>
</template>
<script>
    import SystemAutoTaskBasicInfo from "@/views/isales/views/systemAutoCallTask/SystemAutoTaskBasicInfo.vue";
    import CallTime from "@/views/isales/views/systemAutoCallTask/CallTime.vue";
    import OutboundCallData from "@/views/isales/components/OutboundCallData/OutboundCallData.vue";
    import TaskCallPolicy from "@/views/isales/views/systemAutoCallTask/TaskCallPolicy.vue";
    import {createTask, queryCmsEnvironment, updateTask} from "@/views/isales/api/systemAutoCallTask.js";
    import {RETURN_CODE} from "@/views/isales/views/taskManagement/enum.js";
    import {queryTaskByCondition} from "@/views/isales/api/taskManagement";
    import {
        batchUpdate,
        batchUpdateTask,
        batchUpdateTaskCallTime,
        batchUpdateTaskPolicy, createAutoOutboundCallTemplate,
        getOutboundCallTemplate,
        queryObResultListByCode,
        queryRetryPolicy,
        querySurveyListAndCount, updateAutoOutboundCallTemplate
    } from "@/views/isales/api/systemAutoCallTask";
    import {TASK_TYPE} from "@/views/isales/views/taskManagement/enum";
    import {useIsalesTaskDetailStore, useIsalesTaskManagementStore} from "@/views/isales/stores/taskManagement";
    import {deepClone} from "@/views/isales/views/taskDetail/enum";
    import {CALL_DATA_ORDER_TYPE} from "@/views/isales/views/systemAutoCallTask/enum";
    import {ElMessage, ElMessageBox} from "element-plus";
    import {useBaseStore} from "@/views/base/stores";
    import {querySurveyList} from "@/views/isales/api/outboundCallWorkbenchDetails";
    export default {
        name: 'isalesSystemAutoCallTask',
        setup() {
            const baseStore = useBaseStore()
            const taskManagementStore = useIsalesTaskManagementStore()
            return {
                baseStore,
                taskManagementStore
            }
        },
        components: {
            SystemAutoTaskBasicInfo,
            CallTime,
            OutboundCallData,
            TaskCallPolicy
        },
        data() {
            return {
                labelPosition: window.isRTL ? 'right' : 'left',
                queryType: '',
                queryTaskType: '',
                queryTaskModel: '',
                active: null,
                cmsEnvironment: 'aicc',
                returnCodeItem: RETURN_CODE,
                taskTypeItem: TASK_TYPE,
                stepsList: [
                    {title: this.$t('isales.step.taskinfo')},
                    {title: this.$t('isales.step.callpolicy')}
                ],
                stepsListTemplate: [
                    {title: this.$t('isales.step.taskinfo')},
                    {title: this.$t('isales.step.callpolicy')},
                ],
                showModel: { //定义好
                    taskInfo: {
                        taskId: '',
                        taskName: '',
                        taskType: '',
                        vdnId: '',
                        skillId: '',
                        ivrFlowCode: '', //智能
                        status: '1',
                        lockFlag: '',
                        tenantId: '',
                        skillName: '',
                        smsTemplateId: '',
                        smsTemplateName: '',
                        description: '',
                        surveyId: '',
                        surveyName: '',
                        scenarioType: '',
                        priority: '',
                        templateId: '',
                        callerNo: [],
                        queryCallerNos: [],
                        callerNoDatas: [],
                        beginDate: '',
                        endDate: '',
                        ivrAccessCode: '', //自动
                        flowId: '', //智能
                        flowName: '', //智能 自动
                        callBackUrl: '',
                    },
                    policy: {
                        predictMethod: 1,
                        multiSkill: 2
                    },
                    taskHoliday: [],
                    policyType: 0,
                    callPolicy: {
                        callType: 0, //多号码轮次
                        chooseType: 0,
                        turns: [],
                        orderPolicy: [], //多客户呼叫顺序
                        callOrder: 0
                    },
                    retryPolicy: {
                        resultRetry: '',
                        retryDay: '',
                        retryTimes: '',
                        callInterval: '',
                        isSameAgent: '0',
                        policyType: 0,
                        busiPolicy: '',
                        policyJson: '',
                        noRetryPolicyList: [],
                        callNoPolicyList: [],
                        globalPolicyList: [],
                        busiResultPolicyList: [],
                        isResultRetry: false,
                        noRetryPolicyString: '',
                        busiPolicyType: null,
                        encryptedAttributes: [],
                        retryPolicyId: '',
                        selectRegions: null,
                    },
                    filterPolicy: { //呼叫筛选
                        primaryCondition: [],
                        subConditions: [],
                        operator: 'and',
                    },
                    newNumberTypeList: [],
                    basicInfoDisabled: true, // 默认关闭
                    callTimeDisabled: true, // 默认关闭
                    callPolicyDisabled: true,
                    callRetryPolicyDisabled: true,
                },
                defaultDataType: this.$t('isales.tasklogic.field.default'),
                totalSubCodes: [],
                // 业务结果策略数据标识当前最大值
                busiPolicyListMaxId: 0,
                // 业务结果数据字典,每次加载页面需查询一次
                isLoading: false,
            }
        },
        methods: {
            preStep() {
                if (this.active > 0) {
                    this.active--
                    this.$refs.fullScreen.mainScrollTop()
                }
            },
            async nextStep() {
                const result = await this.$refs.sysAutoForm.validate().catch(err => err);
                if (result !== true) return
                // 校验时间
                // 1, 常规时间必传 2，特殊日期有值则特殊时间段至少存在一个值
                if (!this.checkCallTime()) {
                    return;
                }
                if (this.active < 2) {
                    this.active++
                    this.$refs.fullScreen.mainScrollTop()
                }
            },
            checkCallTime() {
                if (this.isBatchEdit && this.showModel.callTimeDisabled) {
                    return true;
                }
                // 校验常规时间是否配置
                if (!this.showModel.policy.oBeginTime1 || this.showModel.policy.oBeginTime1 === '' ||
                    !this.showModel.policy.oEndTime1 || this.showModel.policy.oEndTime1 === '') {
                    this.$message({
                        type: 'error',
                        duration: 10000,
                        offset: 56,
                        'show-close': true,
                        message: this.$t('isales.outboundtime.message.empty')
                    })
                    return false;
                }
                // 查看是否存在交叉
                if (!this.$refs.callTimeRef.checkCallTimeOnConfirm() || !this.$refs.callTimeRef.checkSpecTimeOnConfirm()) {
                    this.$message({
                        type: 'error',
                        duration: 10000,
                        offset: 56,
                        'show-close': true,
                        message: this.$t('isales.outboundtime.message.crossover')
                    })
                    return false;
                }
                // 开启特殊日期开关但是未配置 予以告警
                if (!this.$refs.callTimeRef.checkOpenSpecDate()) {
                    this.$message({
                        type: 'warning',
                        duration: 10000,
                        offset: 56,
                        'show-close': true,
                        message: this.$t('isales.calltime.title.spec.date.empty.warn')
                    })
                }
                // 校验特殊日期存在&特殊时间不存在
                if (!this.showModel.taskHoliday || this.showModel.taskHoliday.length === 0) {
                    return true;
                }
                if (!this.showModel.policy.hBeginTime1 || this.showModel.policy.hBeginTime1 === '' ||
                    !this.showModel.policy.hEndTime1 || this.showModel.policy.hEndTime1 === '') {
                    this.$message({
                        type: 'error',
                        duration: 10000,
                        offset: 56,
                        'show-close': true,
                        message: this.$t('isales.specialday.message.isEmpty')
                    })
                    return false;
                }
                return true;

            },
            async save(){
                if (!this.isLoading && this.isCreate && !this.showModel.taskInfo.taskId) {
                    this.isLoading = true
                    const isSuccess = await this.saveTask()
                    this.isLoading = false
                    if (!isSuccess) return
                } else if (!this.isLoading && this.isEdit) {
                    this.isLoading = true
                    const isSuccess = await this.saveTask()
                    this.isLoading = false
                    if (!isSuccess) return
                }
                if (!this.showModel.taskInfo.taskId) return
                this.$event.backInTab()
            },
            async nextStepOnConfirm() {
                if (!this.isLoading && this.isCreate && !this.showModel.taskInfo.taskId) {
                    this.isLoading = true
                    const isSuccess = await this.saveTask()
                    this.isLoading = false
                    if (!isSuccess) return
                } else if (!this.isLoading && this.isEdit) {
                    this.isLoading = true
                    const isSuccess = await this.saveTask()
                    this.isLoading = false
                    if (!isSuccess) return
                }
                if (!this.showModel.taskInfo.taskId) return
                if (this.active < 2) {
                    this.active++
                    this.$refs.fullScreen.mainScrollTop()
                }
                if('1' === this.queryTaskModel){
                    this.baseStore.routeTitles['isalesSystemAutoCallTask'] = `${this.$t('isales.manutask.title.viewdata')}-${this.showModel.taskInfo.taskName}`
                }else {
                    this.baseStore.routeTitles['isalesSystemAutoCallTask'] = `${this.$t('isales.manutask.title.viewdata')}-${this.showModel.taskInfo.taskName}`
                }
            },
            cancel() {
                this.$event.backInTab()
            },
            async saveTask() {
                // 校验呼叫策略
                if (!this.checkCallPolicy()) {
                    return;
                }
                if (!this.checkRetryPolicy()) return
                let promise = await this.$refs.taskCallPolicyRef.policyValidate();
                if (promise !== true) {
                  return;
                }
                this.assembleNoRetryPolicy();
                this.showModel.retryPolicy = this.getTrueRetryPolicy();
                if ((this.showModel.filterPolicy.primaryCondition && this.showModel.filterPolicy.primaryCondition.length > 0)
                    || (this.showModel.filterPolicy.subConditions && this.showModel.filterPolicy.subConditions.length > 0)) {
                    let {primaryCondition, subConditions, ...filterPolicy} = this.showModel.filterPolicy
                    filterPolicy.conditions = []
                    filterPolicy.operator = 'and';
                    filterPolicy.conditions.push(...primaryCondition)
                    filterPolicy.conditions.push(...subConditions)
                    this.showModel.filterPolicy = filterPolicy;
                } else {
                    this.showModel.filterPolicy = {};
                }
                // 补充
                if (!this.showModel.policy.multiSkill) {
                    this.showModel.policy.multiSkill = 2;
                }
                let result;
                if (this.isCreate && !this.showModel.taskInfo.taskId) {
                    result = await createTask(this.showModel);
                } else if (this.isEdit || (this.isCreate && this.showModel.taskInfo.taskId)) {
                    result = await updateTask(this.showModel);
                }
                switch (result.returnCode) {
                    case RETURN_CODE.SUCCESS:
                        this.$message({
                            type: 'success',
                            duration: 5000,
                            offset: 56,
                            message: this.$t('isales.task.message.saveSuccess')
                        })
                        if (this.isCreate) {
                            this.showModel.taskInfo.taskId = result.taskId
                        }
                        return true
                    case RETURN_CODE.FAIL_EXIT:
                        this.$message({
                            type: 'error',
                            duration: 10000,
                            offset: 56,
                            'show-close': true,
                            message: this.$t('isales.taskinfo.message.nameExists')
                        })
                        return false
                    case RETURN_CODE.WARNING:
                        this.$message({
                            type: 'error',
                            duration: 10000,
                            offset: 56,
                            'show-close': true,
                            message: this.$t('isales.taskinfo.url.not.whitelist')
                        })
                        return false
                    case RETURN_CODE.DUPLICATED:
                        ElMessage({
                          type: 'error',
                          offset: 56,
                          duration: 10000,
                          'show-close': true,
                          message: this.$t('isales.tasktemplate.message.nameDuplicated')
                        })
                        return false
                    default:
                        this.$message({
                            type: 'error',
                            duration: 10000,
                            offset: 56,
                            'show-close': true,
                            message: this.$t('isales.task.message.saveFail')
                        })
                        return false
                }
            },
            rspCode(result) {
                switch (result.returnCode) {
                    case RETURN_CODE.SUCCESS:
                        return true
                    case RETURN_CODE.FAIL_EXIT:
                        this.$message({
                            type: 'error',
                            duration: 10000,
                            offset: 56,
                            'show-close': true,
                            message: this.$t('isales.taskinfo.message.nameExists')
                        })
                        return false
                    case RETURN_CODE.WARNING:
                        this.$message({
                            type: 'error',
                            duration: 10000,
                            offset: 56,
                            'show-close': true,
                            message: this.$t('isales.taskinfo.url.not.whitelist')
                        })
                        return false
                    case RETURN_CODE.DUPLICATED:
                        ElMessage({
                            type: 'error',
                            duration: 10000,
                            offset: 56,
                            'show-close': true,
                            message: this.$t('isales.tasktemplate.message.nameDuplicated')
                        })
                        return false
                    case RETURN_CODE.FAIL:
                        let description = result.description;
                        if (description.indexOf("DataType is already exist but type is different") >= 0) {
                            ElMessage({
                                type: 'error',
                                duration: 10000,
                                offset: 56,
                                'show-close': true,
                                message: this.$t('isales.taskcalllogic.message.typeConflictWhenDataTypeSame')
                            })

                        } else if (description.indexOf("Count of callNoPolicy out of range in task after update") >= 0) {
                            ElMessage({
                                type: 'error',
                                duration: 10000,
                                offset: 56,
                                'show-close': true,
                                message: this.$t('isales.taskcalllogic.message.callNoPolicyOutOfRangeAfetUpdate')
                            })
                        } else if (description.indexOf("Length of roundPolicy after update out of range") >= 0) {
                            ElMessage({
                                type: 'error',
                                duration: 10000,
                                offset: 56,
                                'show-close': true,
                                message: this.$t('isales.taskcalllogic.message.roundPolicyLengthTooLong')
                            })
                        } else {
                            ElMessage({
                                type: 'error',
                                duration: 10000,
                                offset: 56,
                                'show-close': true,
                                message: this.$t('isales.task.message.saveFail')
                            })
                        }
                        return false
                    default:
                        this.$message({
                            type: 'error',
                            duration: 10000,
                            offset: 56,
                            'show-close': true,
                            message: this.$t('isales.task.message.saveFail')
                        })
                        return false
                }
            },
            checkCallPolicy() {
                // 呼叫顺序
                if (this.showModel.callPolicy.callOrder === CALL_DATA_ORDER_TYPE[2].value) {
                    if (!this.showModel.callPolicy.orderPolicy || this.showModel.callPolicy.orderPolicy.length === 0) {
                        this.$message({
                            type: 'error',
                            duration: 10000,
                            offset: 56,
                            'show-close': true,
                            message: this.$t('isales.tasklogic.message.need.orderPolicy')
                        })
                        return false
                    }
                }
                if (this.showModel.callPolicy.callType === 0) {
                    return true;
                }
                // 呼叫轮次
                if (!this.showModel.callPolicy.turns || this.showModel.callPolicy.turns.length === 0) {
                    this.$message({
                        type: 'error',
                        duration: 10000,
                        offset: 56,
                        'show-close': true,
                        message: this.$t('isales.tasklogic.message.need.turns')
                    })
                    return false
                }
                // 按轮次
                if (this.showModel.callPolicy.chooseType === 0) {
                    for (let i = 0; i < this.showModel.callPolicy.turns.length; i++) {
                        let turn = this.showModel.callPolicy.turns[i];
                        if (!turn || !turn.turnType) {
                            this.$message({
                                type: 'error',
                                duration: 10000,
                                offset: 56,
                                'show-close': true,
                                message: this.$t('isales.tasklogic.message.empty.orderNo')
                            })
                            return false
                        }
                    }
                }
                // 按顺序
                if (this.showModel.callPolicy.chooseType === 1) {
                    for (let i = 0; i < this.showModel.callPolicy.turns.length; i++) {
                        let turn = this.showModel.callPolicy.turns[i];
                        if (!turn || !turn.turnOrder) {
                            this.$message({
                                type: 'error',
                                duration: 10000,
                                offset: 56,
                                'show-close': true,
                                message: this.$t('isales.tasklogic.message.no.numbertype')
                            })
                            return false
                        }
                    }
                }
                return true;
            },
            confirm() {
                if (this.isBatchEdit) { // 批量修改
                    this.batchEditTask()
                } else if (this.isCreateTemplate || this.isEditTemplate) { //保存模板
                    this.saveTemplate();
                } else {
                    this.$event.backInTab()
                }
            },
            async queryCmsEnvironment() {
                let result = await queryCmsEnvironment();
                this.cmsEnvironment = result.cmsEnvironment;
            },
            templateSysSelectConfirm(taskId = null, type = null) {
                this.querySysAutoTaskInfo(taskId, type)
            },
            async querySysAutoTaskInfo(taskId = null, type = null) {
                let param = {
                    dataObject: {taskId: taskId || this.showModel.taskInfo.taskId},
                    limit: 1,
                    offset: 0,
                }

                let result
                this.showModel.taskInfo.queryCallerNos = [];
                if (type === 'template') {
                    // 选择任务模板时，不修改任务名称
                    let param = {taskId: taskId};
                    let resultModel = await getOutboundCallTemplate(param);
                    this.deepCopyTaskInfo(resultModel);
                } else {
                    const resultList = await queryTaskByCondition(param);
                    result = resultList[0];
                    this.showModel.taskInfo = result;
                    this.showModel.taskInfo.taskId = taskId;
                    this.showModel.taskInfo.callerNo = this.showModel.taskInfo.callerNoDatas;
                    this.showModel.taskInfo.queryCallerNos = this.showModel.taskInfo.callerNoDatas.map(item => {
                        return item.accessCode;
                    });
                    if (this.showModel.taskInfo.beginDate && this.showModel.taskInfo.endDate) {
                        this.showModel.taskInfo.date = [this.$TimeUtils.transDate(this.showModel.taskInfo.beginDate), this.$TimeUtils.transDate(this.showModel.taskInfo.endDate)]
                    }
                }
                // 补充问卷类型
                await this.querySurveyVersion(this.showModel.taskInfo.surveyId);
                if (this.$route.query.step !== '3') {
                  this.setSurveyName(this.showModel.taskInfo.surveyId);
                  this.setCallTime(taskId);
                  await this.setTaskCallPolicy(taskId);
                  await this.queryRetryPolicy(taskId);
                }
            },
            deepCopyTaskInfo(resultModel) {
                let taskInfo = resultModel["taskInfo"];
                if (!this.isCreate) {
                    this.showModel.taskInfo.taskName = taskInfo.taskName
                    this.showModel.taskInfo.taskId = taskInfo.taskId
                    this.showModel.taskInfo.status = taskInfo.status
                }
                this.showModel.taskInfo.surveyId = taskInfo.surveyId
                this.showModel.taskInfo.taskType = taskInfo.taskType
                this.showModel.taskInfo.callBackUrl = taskInfo.callBackUrl
                this.showModel.taskInfo.description = taskInfo.description
                this.showModel.taskInfo.tenantId = taskInfo.tenantId
                this.showModel.taskInfo.skillId = taskInfo.skillId
                this.showModel.taskInfo.ivrFlowCode = taskInfo.ivrFlowCode
                this.showModel.taskInfo.skillName = taskInfo.skillName
                this.showModel.taskInfo.smsTemplateId = taskInfo.smsTemplateId
                this.showModel.taskInfo.smsTemplateName = taskInfo.smsTemplateName
                this.showModel.taskInfo.priority = taskInfo.priority
                this.showModel.taskInfo.callerNoDatas = taskInfo.callerNoDatas
                this.showModel.taskInfo.ivrAccessCode = taskInfo.ivrAccessCode
                this.showModel.taskInfo.flowId = taskInfo.flowId
                this.showModel.taskInfo.flowName = taskInfo.flowName
                this.showModel.taskInfo.callerNoDatas = taskInfo.callerNoDatas;
                this.showModel.taskInfo.callerNo = taskInfo.callerNoDatas;
                this.showModel.taskInfo.queryCallerNos = taskInfo.callerNoDatas.map(item => {
                    return item.accessCode;
                });
            },
            async setSurveyName(surveyId) {
                if (!surveyId || surveyId === '') {
                    return;
                }
                let result = await querySurveyListAndCount({rows: 1, page: 1, scriptId: surveyId})
                if (result && result.data && result.data.length > 0) {
                    this.showModel.taskInfo.surveyName = result.data[0].scriptName + '_V' + result.data[0].versionNo;
                }
            },
            async setCallTime(taskId) {
                this.$refs.callTimeRef.initCallTime(taskId);
            },
            async setTaskCallPolicy(taskId) {
                await this.$refs.taskCallPolicyRef.initTaskCallPolicy(taskId);
            },
            async queryRetryPolicy(taskId) {
                let param = {
                    "taskType": this.showModel["taskInfo"]["taskType"],
                    "taskId": taskId
                }
                let result = await queryRetryPolicy(param);
                this.deepCopyRetryPolicy(result["retryPolicy"]);
                await this.initRetryPolicy();
            },
            isValueEmpty(valueStr) {
                return valueStr == null || valueStr == '' || valueStr == undefined;
            },
            async initRetryPolicy() {
                if (!this.showModel.retryPolicy) {
                    return;
                }
                this.showModel["policyType"] = this.showModel.retryPolicy.policyType ? this.showModel.retryPolicy.policyType : 0;
                this.showModel.retryPolicy.policyType = this.showModel.retryPolicy.policyType ? this.showModel.retryPolicy.policyType : 0;
                this.showModel.retryPolicy.isResultRetry = this.showModel.retryPolicy.globalPolicyList && this.showModel.retryPolicy.globalPolicyList.length > 0 ? true : false;
                // 外呼结果策略->全局策略->结果处理策略
                this.showModel.retryPolicy.globalPolicyList = this.showModel.retryPolicy.globalPolicyList ? this.showModel.retryPolicy.globalPolicyList : [];
                if (this.showModel.retryPolicy.globalPolicyList && this.showModel.retryPolicy.globalPolicyList.length > 0) {
                    this.showModel.retryPolicy.globalPolicyList.forEach(element => {
                        if (element["retryNumber"] == 0) {
                            element["retryNumber"] = "0";
                        }
                    });
                }
                // 全局策略
                this.showModel.retryPolicy.noRetryPolicyList = [];
                if (this.showModel.retryPolicy.policyType === 0 && this.showModel.retryPolicy.policyJson) {
                    await this.assembleNoRetry()
                }
                // 外呼结果策略->号码分类->号码策略
                this.showModel.retryPolicy.callNoPolicyList = this.showModel.retryPolicy.callNoPolicyList ? this.showModel.retryPolicy.callNoPolicyList : [];
                if (this.showModel.policyType === 1) {
                    // 添加默认号码分类匹配策略
                    let exist = this.showModel.retryPolicy.callNoPolicyList.filter(logic => {
                        return logic.dataType == '9D9T9';
                    });
                    if (null != exist && exist.length > 0) {
                        exist[0].dataType = this.defaultDataType;
                    } else {
                        let callnoLogic = {"dataType": this.defaultDataType, "type": "0"};
                        let policy = {"dataType": this.defaultDataType, "retryDay": 0, "retryTimes": 0};
                        callnoLogic['policy'] = []
                        callnoLogic['policy'].push(policy);
                        this.showModel.retryPolicy.callNoPolicyList.unshift(callnoLogic);
                    }
                    // 给号码分类下的策略信息加上号码类型
                    for (let callNoPolicy of this.showModel.retryPolicy.callNoPolicyList || []) {
                        for (let policy of callNoPolicy.policy || []) {
                            policy["dataType"] = callNoPolicy.dataType;
                            if (policy.result == 0) {
                                policy.result = "0";
                            }
                        }
                    }
                }
            },
            async assembleNoRetry() {
                const params = {'noRetryPolicyString': this.showModel.retryPolicy.policyJson};
                const tempListFromDb = this.showModel.retryPolicy.policyJson.split(",");
                const result = await queryObResultListByCode(params);
                let resultCodeList = [];
                // 存在自定义外呼结果
                if (result && result != [] && result.length != 0) {
                    resultCodeList = result;
                } else {
                    ElMessage({
                      type: 'error',
                      duration: 10000,
                      showClose: true,
                      offset: 56,
                      message: this.$t('isales.management.title.setResultCode')
                    });
                }
                if (!this.showModel.retryPolicy.noRetryPolicyList) {
                    this.showModel.retryPolicy.noRetryPolicyList = [];
                }
                for (let index = 0; index < tempListFromDb.length; index++) {
                    for (let innerIndex = 0; innerIndex < resultCodeList.length; innerIndex++) {
                        if (parseInt(tempListFromDb[index]) === parseInt(resultCodeList[innerIndex]['resultCode'])) {
                            const tmp = {
                                "resultCode": resultCodeList[innerIndex]['resultCode'],
                                "displayResult": resultCodeList[innerIndex]['displayResult']
                            }
                            this.showModel.retryPolicy.noRetryPolicyList.push(tmp);
                        }
                    }
                }
            },
            getTrueRetryPolicy() {
                let retryPolicy = deepClone(this.showModel.retryPolicy);
                if (this.showModel.retryPolicy.policyType === 0) {
                    if (this.showModel.retryPolicy.globalPolicyList && this.showModel.retryPolicy.globalPolicyList.length > 0) {
                        retryPolicy["retryDay"] = null;
                        retryPolicy["retryTimes"] = null;
                        retryPolicy["callInterval"] = null;
                        let globalPolicyList = deepClone(this.showModel.retryPolicy.globalPolicyList);
                        retryPolicy["resultRetry"] = JSON.stringify(globalPolicyList);
                    } else {
                        retryPolicy["resultRetry"] = null
                        retryPolicy["retryDay"] = this.showModel.retryPolicy.retryDay;
                        retryPolicy["retryTimes"] = this.showModel.retryPolicy.retryTimes;
                        retryPolicy["callInterval"] = this.showModel.retryPolicy.callInterval;
                    }
                    return retryPolicy;
                }

                retryPolicy["retryDay"] = null;
                retryPolicy["retryTimes"] = null;
                retryPolicy["callInterval"] = null;
                retryPolicy["resultRetry"] = null;

                this.dealCallNoPolicy(retryPolicy);

                if (!this.isCreate && !this.isCreateTemplate) {
                    retryPolicy['retryPolicyId'] = this.showModel.retryPolicy['retryPolicyId'];
                }
                return retryPolicy;
            },
            dealCallNoPolicy(retryPolicy) {
                // 号码分类策略配置
                let numberPolicyList = [];
                for (let i = 0; i < this.showModel.retryPolicy.callNoPolicyList.length; i++) {
                    let numberPolicy = {};
                    numberPolicy["dataType"] = this.showModel.retryPolicy.callNoPolicyList[i]['dataType'];
                    if(this.isBatchEdit && !this.showModel.callRetryPolicyDisabled){
                        numberPolicy["isNewType"]= this.showModel.retryPolicy.callNoPolicyList[i]['isNewType'];
                    }
                    // 默认号码类型转换成实际值
                    if (this.defaultDataType == numberPolicy["dataType"]) {
                        numberPolicy["dataType"] = "9D9T9";
                    }
                    numberPolicy["type"] = this.showModel.retryPolicy.callNoPolicyList[i]['type'];
                    if (this.showModel.retryPolicy.callNoPolicyList[i]['type'] == 0) {
                        // 不根据结果配置
                        let retryDay = this.showModel.retryPolicy.callNoPolicyList[i]["policy"][0]["retryDay"];
                        let retryTimes = this.showModel.retryPolicy.callNoPolicyList[i]["policy"][0]["retryTimes"];
                        numberPolicy["policy"] = {retryDay: retryDay, retryTimes: retryTimes};
                    } else {
                        // 根据结果配置
                        let policy = [];
                        let results = [];
                        let policyList = this.showModel.retryPolicy.callNoPolicyList[i]['policy'];
                        for (let j = 0; j < policyList.length; j++) {
                            let callInterval = policyList[j]["callInterval"];
                            let result = policyList[j]["result"];
                            let retryNumber = policyList[j]["retryNumber"];
                            policy.push({callInterval: callInterval, result: result, retryNumber: retryNumber});
                            if (results.indexOf(result) < 0) {
                                results.push(result);
                            }
                        }
                        // 判断结果是否重复
                        if (results.length != policy.length) {
                            ElMessage({
                              type: 'error',
                              duration: 10000,
                              showClose: true,
                              offset: 56,
                              message: this.$t('isales.taskcalllogic.message.resultTips')
                            });
                            return false;
                        }
                        numberPolicy["policy"] = policy;
                    }
                    numberPolicyList.push(numberPolicy);
                }
                retryPolicy["policyJson"] = JSON.stringify(numberPolicyList);
            },
            assembleNoRetryPolicy() {
                let tempList;
                if (this.showModel.retryPolicy.noRetryPolicyList) {
                    for (let index = 0; index < this.showModel.retryPolicy.noRetryPolicyList.length; index++) {
                        if (!tempList || tempList == undefined) {
                            tempList = this.showModel.retryPolicy.noRetryPolicyList[index]['resultCode'];
                        } else {
                            tempList = tempList + "," + this.showModel.retryPolicy.noRetryPolicyList[index]['resultCode'];
                        }
                    }
                    this.showModel.retryPolicy['noRetryPolicyString'] = tempList;
                }
            },
            checkRetryPolicy() {
                // 校验根据结果配置全局策略是否为空
                if (this.showModel.policyType === 0 && this.showModel.retryPolicy.isResultRetry) {
                    if (this.showModel.retryPolicy.globalPolicyList.length <= 0) {
                        ElMessage({
                          type: 'error',
                          duration: 10000,
                          showClose: true,
                          offset: 56,
                          message: this.$t('isales.taskcalllogic.message.choosePolicy')
                        });
                        return false;
                    }
                    this.showModel.retryPolicy.resultRetry = JSON.stringify(this.showModel.retryPolicy.globalPolicyList);
                }
                return true;
            },
            deepCopyRetryPolicy(retryPolicy) {
                this.showModel.retryPolicy.resultRetry = retryPolicy.resultRetry
                this.showModel.retryPolicy.retryDay = retryPolicy.retryDay
                this.showModel.retryPolicy.retryTimes = retryPolicy.retryTimes
                this.showModel.retryPolicy.callInterval = retryPolicy.callInterval
                if (retryPolicy.isSameAgent && '' !== retryPolicy.isSameAgent) {
                    this.showModel.retryPolicy.isSameAgent = retryPolicy.isSameAgent
                } else {
                    this.showModel.retryPolicy.isSameAgent = '0'
                }
                this.showModel.retryPolicy.policyType = retryPolicy.policyType
                this.showModel.retryPolicy.policyJson = retryPolicy.policyJson
                this.showModel.retryPolicy.callNoPolicyList = retryPolicy.callNoPolicyList
                this.showModel.retryPolicy.globalPolicyList = retryPolicy.globalPolicyList
                this.showModel.retryPolicy.noRetryPolicyString = retryPolicy.noRetryPolicyString;
                this.showModel.retryPolicy.encryptedAttributes = retryPolicy.encryptedAttributes
                this.showModel.retryPolicy.retryPolicyId = retryPolicy.retryPolicyId
                this.showModel.retryPolicy.selectRegions = retryPolicy.selectRegions
            },
            async batchEditTask() {
                if (this.showModel.basicInfoDisabled && this.showModel.callPolicyDisabled
                        && this.showModel.callTimeDisabled && this.showModel.callRetryPolicyDisabled) {
                    this.$message({
                        type: 'warning',
                      duration: 10000,
                      offset: 56,
                      'show-close': true,
                        message: this.$t('isales.taskinfo.message.batchUpdate.paramsNull')
                    })
                    return;
                }
                let param = {
                    taskInfo: {
                        taskIds: this.taskManagementStore.batchEditTaskIds.join(','),
                        taskType: this.queryTaskType
                    },
                }
                if (!this.showModel.basicInfoDisabled) {
                    if (this.showModel.taskInfo.callerNo && this.showModel.taskInfo.callerNo.length > 0) {
                        param.taskInfo.callerNo = this.showModel.taskInfo.callerNo;
                    }
                    if (this.showModel.taskInfo.surveyId) {
                        param.taskInfo.surveyId = this.showModel.taskInfo.surveyId;
                    }
                    if (this.showModel.taskInfo.scenarioType) {
                        param.taskInfo.scenarioType = this.showModel.taskInfo.scenarioType;
                    }

                    if (this.showModel.taskInfo.beginDate && this.showModel.taskInfo.endDate) {
                        param.taskInfo.beginDate = this.showModel.taskInfo.beginDate
                        param.taskInfo.endDate = this.showModel.taskInfo.endDate
                    }
                    if (this.showModel.taskInfo.smsTemplateName) {
                        param.taskInfo.smsTemplateId = this.showModel.taskInfo.smsTemplateId
                        param.taskInfo.smsTemplateName = this.showModel.taskInfo.smsTemplateName
                    }
                    if (this.showModel.taskInfo.skillId) {
                        param.taskInfo.skillId = this.showModel.taskInfo.skillId;
                        param.taskInfo.skillName = this.showModel.taskInfo.skillName;
                    }
                    if (this.showModel.taskInfo.priority) {
                        param.taskInfo.priority = this.showModel.taskInfo.priority;
                    }
                    if (this.showModel.taskInfo.callBackUrl) {
                        param.taskInfo.callBackUrl = this.showModel.taskInfo.callBackUrl;
                    }
                    if (this.showModel.taskInfo.ivrFlowCode) {
                        param.taskInfo.ivrFlowCode = this.showModel.taskInfo.ivrFlowCode;
                    }
                    if (this.showModel.taskInfo.ivrAccessCode) {
                        param.taskInfo.ivrAccessCode = this.showModel.taskInfo.ivrAccessCode;
                    }
                    if (this.showModel.taskInfo.flowId) {
                        param.taskInfo.flowId = this.showModel.taskInfo.flowId;
                    }
                    if (this.showModel.taskInfo.flowName) {
                        param.taskInfo.flowName = this.showModel.taskInfo.flowName;
                    }

                    // 调用
                    const resultUpdateTask = await batchUpdateTask({taskInfo: param.taskInfo});
                    if (!this.rspCode(resultUpdateTask)) {
                        return;
                    }
                }
                if (!this.showModel.callTimeDisabled) {
                    param.policy = {};
                    if (this.showModel.policy.oBeginTime1 && this.showModel.policy.oBeginTime1 !== '' &&
                        this.showModel.policy.oEndTime1 && this.showModel.policy.oEndTime1 !== '') {
                        param.policy.oBeginTime1 = this.showModel.policy.oBeginTime1
                        param.policy.oEndTime1 = this.showModel.policy.oEndTime1
                        param.policy.oTimeExistFlag = true
                    }
                    if (this.showModel.policy.oBeginTime2 && this.showModel.policy.oBeginTime2 !== '' &&
                        this.showModel.policy.oEndTime2 && this.showModel.policy.oEndTime2 !== '') {
                        param.policy.oBeginTime2 = this.showModel.policy.oBeginTime2
                        param.policy.oEndTime2 = this.showModel.policy.oEndTime2
                    }
                    if (this.showModel.policy.oBeginTime3 && this.showModel.policy.oBeginTime3 !== '' &&
                        this.showModel.policy.oEndTime3 && this.showModel.policy.oEndTime3 !== '') {
                        param.policy.oBeginTime3 = this.showModel.policy.oBeginTime3
                        param.policy.oEndTime3 = this.showModel.policy.oEndTime3
                    }
                    if (this.showModel.taskHoliday && this.showModel.taskHoliday.length > 0) {
                        param.taskHoliday = [];
                        param.taskHoliday = this.showModel.taskHoliday;
                    }

                    if (this.showModel.policy.hBeginTime1 && this.showModel.policy.hBeginTime1 !== '' &&
                        this.showModel.policy.hEndTime1 && this.showModel.policy.hEndTime1 !== '') {
                        param.policy.hBeginTime1 = this.showModel.policy.hBeginTime1
                        param.policy.hEndTime1 = this.showModel.policy.hEndTime1
                        param.policy.sTimeExistFlag = true
                    }
                    if (this.showModel.policy.hBeginTime2 && this.showModel.policy.hBeginTime2 !== '' &&
                        this.showModel.policy.hEndTime2 && this.showModel.policy.hEndTime2 !== '') {
                        param.policy.hBeginTime2 = this.showModel.policy.hBeginTime2
                        param.policy.hEndTime2 = this.showModel.policy.hEndTime2
                    }
                    if (this.showModel.policy.hBeginTime3 && this.showModel.policy.hBeginTime3 !== '' &&
                        this.showModel.policy.hEndTime3 && this.showModel.policy.hEndTime3 !== '') {
                        param.policy.hBeginTime3 = this.showModel.policy.hBeginTime3
                        param.policy.hEndTime3 = this.showModel.policy.hEndTime3
                    }
                    let paramTemp2 = {
                        policy: param.policy,
                        taskHoliday: param.taskHoliday,
                        taskInfo: {
                            taskIds: this.taskManagementStore.batchEditTaskIds.join(','),
                        }
                    }
                    const resultCallTime = await batchUpdateTaskCallTime(paramTemp2);
                    if (!this.rspCode(resultCallTime)) {
                        return;
                    }
                }
                if (!this.showModel.callPolicyDisabled) {
                    param.policy = {};
                    if (this.showModel.policy.predictMethod) {
                        param.policy.multiSkill = this.showModel.policy.multiSkill;
                        param.policy.predictMethod = this.showModel.policy.predictMethod + "";
                    }
                    if (this.showModel.policy.obOverTime) {
                        param.policy.obOverTime = this.showModel.policy.obOverTime + "";
                    }
                    if (this.showModel.policy.maxAvgCallTime) {
                        param.policy.maxAvgCallTime = this.showModel.policy.maxAvgCallTime + "";
                    }
                    if (this.showModel.policy.minAvgCallTime) {
                        param.policy.minAvgCallTime = this.showModel.policy.minAvgCallTime + "";
                    }
                    if (this.showModel.policy.talkingRate) {
                        param.policy.talkingRate = this.showModel.policy.talkingRate + "";
                    }
                    if (this.showModel.policy.lossRate) {
                        param.policy.lossRate = this.showModel.policy.lossRate + "";
                    }
                    if (this.showModel.policy.utilRate) {
                        param.policy.utilRate = this.showModel.policy.utilRate + "";
                    }
                    if (this.showModel.policy.utilRateMethod) {
                        param.policy.utilRateMethod = this.showModel.policy.utilRateMethod + "";
                    }
                    if (this.showModel.policy.waitTime) {
                        param.policy.waitTime = this.showModel.policy.waitTime + "";
                    }
                    if (this.showModel.policy.connRate) {
                        param.policy.connRate = this.showModel.policy.connRate + "";
                    }
                    if (this.showModel.policy.pickupRate) {
                        param.policy.pickupRate = this.showModel.policy.pickupRate + "";
                    }
                    if (this.showModel.policy.failInterval) {
                        param.policy.failInterval = this.showModel.policy.failInterval + "";
                    }
                    if (this.showModel.policy.obCallCount) {
                        param.policy.obCallCount = this.showModel.policy.obCallCount + "";
                    }
                    if (this.showModel.policy.obOverTime) {
                        param.policy.obOverTime = this.showModel.policy.obOverTime + "";
                    }
                    if (this.showModel.policy.autoCallTime) {
                        param.policy.autoCallTime = this.showModel.policy.autoCallTime + "";
                    }
                    let paramTemp = {
                        taskInfo: {
                            taskIds: this.taskManagementStore.batchEditTaskIds.join(','),
                            taskType: this.queryTaskType
                        },
                        policy: param.policy,
                    }
                    const resultPredictMethod = await batchUpdateTaskPolicy(paramTemp);
                    if (!this.rspCode(resultPredictMethod)) {
                        return;
                    }
                }
                if (!this.showModel.callRetryPolicyDisabled) {
                    if (!this.checkRetryPolicy()) return
                    this.assembleNoRetryPolicy();
                    this.showModel.retryPolicy = this.getTrueRetryPolicy();

                    param.callPolicy = {}
                    param.retryPolicy = {}
                    param.selectedRegions = [];
                    //呼叫策略
                    if (this.showModel.callPolicy) {
                        param.selectedRegions.push("callPolicyRegion");
                        param.callPolicy = this.showModel.callPolicy
                    }
                    // 判断重试
                    let retryFlag=false;
                    if (this.showModel.retryPolicy.retryDay) {
                        param.retryPolicy.retryDay = this.showModel.retryPolicy.retryDay
                        retryFlag=true;
                    }
                    if (this.showModel.retryPolicy.retryTimes) {
                        param.retryPolicy.retryTimes = this.showModel.retryPolicy.retryTimes
                        retryFlag=true;
                    }
                    if (this.showModel.retryPolicy.callInterval) {
                        param.retryPolicy.callInterval = this.showModel.retryPolicy.callInterval
                        retryFlag=true;
                    }
                    if (this.showModel.retryPolicy.noRetryPolicyString) {
                        param.retryPolicy.noRetryPolicyString = this.showModel.retryPolicy.noRetryPolicyString
                        retryFlag=true;
                    }
                    if (this.showModel.retryPolicy.globalPolicyList && this.showModel.retryPolicy.globalPolicyList.length > 0) {
                        param.retryPolicy.resultRetryList=[]
                        param.retryPolicy.resultRetryList = this.showModel.retryPolicy.globalPolicyList
                        retryFlag=true;
                    }
                    if (this.showModel.retryPolicy.policyJson && this.showModel.retryPolicy.policyJson!=='' && this.showModel.retryPolicy.policyJson!='[]') {
                        param.retryPolicy.callNoPolicyList = [];
                        param.retryPolicy.callNoPolicyList = JSON.parse(this.showModel.retryPolicy.policyJson);
                        retryFlag=true;
                    }
                    if(retryFlag){
                        param.retryPolicy.policyType = this.showModel.retryPolicy.policyType
                        param.selectedRegions.push("callResultRegion");
                    }

                    // 判断业务结果和重试结果是否存在值
                    if (this.showModel.retryPolicy.busiPolicy) {
                        if (this.showModel.retryPolicy.busiPolicyType) {
                            param.retryPolicy.busiPolicyType = this.showModel.retryPolicy.busiPolicyType
                        }
                        if (this.showModel.retryPolicy.busiPolicy) {
                            param.retryPolicy.busiPolicy = this.showModel.retryPolicy.busiPolicy
                            param.selectedRegions.push("busiResultRegion");
                            param.selectedTaskIds = this.taskManagementStore.batchEditTaskIds;
                        }
                    }
                    // 组装请求数据
                    let paramTemp2 = {
                        selectedTaskIds: this.taskManagementStore.batchEditTaskIds,
                    }
                    if(param.callPolicy){
                        paramTemp2.callPolicy = param.callPolicy;
                    }
                    if(param.retryPolicy){
                        paramTemp2.retryPolicy = param.retryPolicy;
                    }
                    paramTemp2.selectedRegions = param.selectedRegions;
                    const resultRegions = await batchUpdate(paramTemp2);
                    if (!this.rspCode(resultRegions)) {
                        return;
                    }
                }
                this.$message({
                    type: 'success',
                  duration: 5000,
                  offset: 56,
                  message: this.$t('isales.task.message.saveSuccess')
                });
                this.$event.backInTab()
            },
            async saveAsTemplate() {
                let promise = await this.$refs.taskCallPolicyRef.policyValidate();
                if (promise !== true) {
                    return;
                }
                // 校验呼叫策略
                if (!this.checkCallPolicy()) {
                    return;
                }
                if (!this.checkRetryPolicy()) return
                ElMessageBox.prompt(this.$t('isales.tasktemplate.field.templateName'), this.$t('isales.tasktemplate.title.saveAsTemplate'), {
                    confirmButtonText: this.$t('isales.specialday.title.confirm'),
                    cancelButtonText: this.$t('isales.statistics.business.cancel'),
                    customStyle: {'width': '616px'},
                    closeOnClickModal:false,
                    inputValidator: this.$Validate.checkNotEmptyAndSpecialChar,
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            const {inputValue} = instance
                            this.createTemp(inputValue, (this.isCreate || this.isEdit));
                            done()
                        } else {
                            done()
                        }
                    }
                });
            },
            async createTemp(value, taskBasic) {
                if(!value){
                    this.$message({
                      type: 'error',
                      duration: 10000,
                      showClose: true,
                      offset: 56,
                      message: this.$t('isales.tasktemplate.title.inputTemplateName')
                    });
                    return ;
                }
                let params = await this.getRequestParamsOfOutboundCallTemplate(value, taskBasic);
                if (!params) {
                    return;
                }
                let result = await createAutoOutboundCallTemplate(params);
                if (this.rspCode(result)) {
                    this.$message({
                        type: 'success',
                      duration: 5000,
                      offset: 56,
                      message: this.$t('isales.tasktemplate.message.saveSuccess')
                    })
                }
                this.$event.backInTab();
            },
            // 获取外呼模板入参
            async getRequestParamsOfOutboundCallTemplate(value, taskBasic) {
                if(!taskBasic){
                    let promise = await this.$refs.taskCallPolicyRef.policyValidate();
                    if (promise !== true) {
                        return;
                    }
                }
                // 校验呼叫策略
                if (!this.checkCallPolicy()) {
                    return;
                }
                if (!this.checkRetryPolicy()) return

                // 1 基本信息
                let requestParams = {"taskTemplate": this.showModel.taskInfo};
                if (taskBasic===true) {
                    requestParams.taskTemplate.taskName = value;
                }
                requestParams.taskTemplate.beginDate = null;
                requestParams.taskTemplate.endDate = null;
                // 2 oTimeModels sTimeModels policy
                this.compleTimeModels();
                requestParams["oTimeModels"] = this.showModel.oTimeModels;
                requestParams["sTimeModels"] = this.showModel.sTimeModels;
                requestParams["policy"] = this.showModel.policy;

                // 3 taskHolidays
                let taskHolidays = [];
                this.showModel.taskHoliday.forEach(element => {
                    taskHolidays.push({holidayId: element["holidayId"]});
                });
                requestParams["taskHolidays"] = taskHolidays;

                // 4 callPolicy
                let callPolicy = {};
                // 4.1 呼叫方式
                callPolicy.callMode = this.showModel.callPolicy.callType;
                callPolicy.roundCallMode = this.showModel.callPolicy.chooseType;
                let turns = [];
                if (1 == callPolicy.callMode && this.showModel.callPolicy.turns && this.showModel.callPolicy.turns.length > 0) {
                    this.showModel.callPolicy.turns.forEach(element => {
                        if (0 == callPolicy.roundCallMode) {
                            turns.push({orderNo: element["orderNo"], turnType: element["turnType"]})
                        } else if (1 == callPolicy.roundCallMode) {
                            turns.push({orderNo: element["orderNo"], turnOrder: element["turnOrder"]})
                        }
                    });
                }
                callPolicy.turns = turns;
                // 4.2 过滤
                this.assembleCallFilter(callPolicy);
                // 4.3 排序
                callPolicy.callOrder=this.showModel.callPolicy.callOrder;
                let orderPolicyList = [];
                if (this.showModel.callPolicy.orderPolicy && this.showModel.callPolicy.orderPolicy.length > 0) {
                    for (let i = 0; i <this.showModel.callPolicy.orderPolicy.length; i++) {
                        let orderPolicyElement = this.showModel.callPolicy.orderPolicy[i];
                        orderPolicyList.push({
                            propName: orderPolicyElement["propName"],
                            propNo: i,
                            propId: orderPolicyElement["propId"],
                            typeId: orderPolicyElement["typeId"]
                        })
                    }
                }
                callPolicy.orderPolicyList = orderPolicyList;
                requestParams["callPolicy"] = callPolicy;

                // 5 retryPolicy
                let retryPolicy = deepClone(this.showModel.retryPolicy);
                // 5.1 外呼结果策略
                retryPolicy.resultRetry = "";
                retryPolicy.policyJson = "";
                retryPolicy.globalPolicyList = [];
                retryPolicy.callNoPolicyList = [];
                retryPolicy.retryDay = null;
                retryPolicy.retryTimes = null;
                retryPolicy.callInterval = null;
                if (this.showModel.retryPolicy.policyType == 0 && (!this.showModel.retryPolicy.globalPolicyList || this.showModel.retryPolicy.globalPolicyList.length <= 0)) {
                    retryPolicy.retryDay = this.showModel.retryPolicy.retryDay;
                    retryPolicy.retryTimes = this.showModel.retryPolicy.retryTimes;
                    retryPolicy.callInterval = this.showModel.retryPolicy.callInterval;
                } else if (this.showModel.retryPolicy.policyType == 0 && this.showModel.retryPolicy.globalPolicyList && this.showModel.retryPolicy.globalPolicyList.length > 0) {
                    for (let item of this.showModel.retryPolicy.globalPolicyList) {
                        retryPolicy.globalPolicyList.push({
                            result: item["result"],
                            retryNumber: item["retryNumber"],
                            callInterval: item["callInterval"]
                        });
                    }
                } else if (this.showModel.retryPolicy.policyType == 1 && this.showModel.retryPolicy.callNoPolicyList && this.showModel.retryPolicy.callNoPolicyList.length > 0) {
                    // 号码分类策略配置
                    let tempList = deepClone(this.showModel.retryPolicy.callNoPolicyList);
                    for (var i = 0; i < tempList.length; i++) {
                        let numberPolicy = {};
                        numberPolicy["dataType"] = tempList[i]['dataType'];
                        // 默认号码类型转换成实际值
                        if (this.defaultDataType == numberPolicy["dataType"]) {
                            numberPolicy["dataType"] = "9D9T9";
                        }
                        numberPolicy["type"] = tempList[i]['type'];
                        if (tempList[i]['type'] == 0) {
                            // 不根据结果配置
                            let retryDay = tempList[i]["policy"][0]["retryDay"];
                            let retryTimes = tempList[i]["policy"][0]["retryTimes"];
                            numberPolicy["policy"] = {retryDay: retryDay, retryTimes: retryTimes};
                        } else {
                            // 根据结果配置
                            let policy = [];
                            let results = [];
                            let policyList = tempList[i]['policy'];
                            for (var j = 0; j < policyList.length; j++) {
                                let callInterval = policyList[j]["callInterval"];
                                let result = policyList[j]["result"];
                                let retryNumber = policyList[j]["retryNumber"];
                                policy.push({callInterval: callInterval, result: result, retryNumber: retryNumber});
                                if (results.indexOf(result) < 0) {
                                    results.push(result);
                                }
                            }
                            // 判断结果是否重复
                            if (results.length != policy.length) {
                                ElMessage({
                                  type: 'error',
                                  duration: 10000,
                                  showClose: true,
                                  offset: 56,
                                  message: this.$t('isales.taskcalllogic.message.resultTips')
                                });
                                return false;
                            }
                            numberPolicy["policy"] = policy;
                        }
                        retryPolicy.callNoPolicyList.push(numberPolicy);
                    }
                }

                // 5.2 业务结果策略
                retryPolicy.busiPolicyType = 0;
                retryPolicy.busiPolicy = "";
                retryPolicy.busiResultPolicyList = [];
                if(this.showModel.retryPolicy.busiPolicy && this.showModel.retryPolicy.busiPolicy!==''){
                    retryPolicy.busiPolicy = this.showModel.retryPolicy.busiPolicy;
                    retryPolicy.busiPolicyType = this.showModel.retryPolicy.busiPolicyType;
                    this.showModel.retryPolicy.busiResultPolicyList = JSON.parse(this.showModel.retryPolicy.busiPolicy);
                    if (this.showModel.retryPolicy.busiResultPolicyList && this.showModel.retryPolicy.busiResultPolicyList.length > 0) {
                        this.showModel.retryPolicy.busiResultPolicyList.forEach(item => {
                            let callInterval = item["busiResultPolicy"] == "0" ? "" : item["callInterval"];
                            let tempObj = {
                                "busiResultCode": item["busiResultCode"],
                                "busiResultSubCode": item["busiResultSubCode"],
                                "busiResultPolicy": item["busiResultPolicy"],
                                "callInterval": callInterval
                            };
                            retryPolicy.busiResultPolicyList.push(tempObj);
                        });
                    }
                }
                requestParams['retryPolicy'] = retryPolicy;
                this.assembleNoRetryPolicy(requestParams);
                return requestParams;
            },
            async compleTimeModels() {
                // 2 oTimeModels sTimeModels policy
                let oTimeModels = [];
                if (this.showModel.policy.oBeginTime1 && this.showModel.policy.oBeginTime1 !== '') {
                    let oBTimes = this.showModel.policy.oBeginTime1.split(":");
                    let oETimes = this.showModel.policy.oEndTime1.split(":");
                    oTimeModels.push({
                        orderNo: 1,
                        beginTime1: oBTimes[0],
                        beginTime2: oBTimes[1],
                        endTime1: oETimes[0],
                        endTime2: oETimes[1]
                    })
                } else {
                    oTimeModels.push({orderNo: 1, beginTime1: null, beginTime2: null, endTime1: null, endTime2: null})
                }
                if (this.showModel.policy.oBeginTime2 && this.showModel.policy.oBeginTime2 !== '') {
                    let oBTimes = this.showModel.policy.oBeginTime2.split(":");
                    let oETimes = this.showModel.policy.oEndTime2.split(":");
                    oTimeModels.push({
                        orderNo: 2,
                        beginTime1: oBTimes[0],
                        beginTime2: oBTimes[1],
                        endTime1: oETimes[0],
                        endTime2: oETimes[1]
                    })
                } else {
                    oTimeModels.push({orderNo: 2, beginTime1: null, beginTime2: null, endTime1: null, endTime2: null})
                }
                if (this.showModel.policy.oBeginTime3 && this.showModel.policy.oBeginTime3 !== '') {
                    let oBTimes = this.showModel.policy.oBeginTime3.split(":");
                    let oETimes = this.showModel.policy.oEndTime3.split(":");
                    oTimeModels.push({
                        orderNo: 3,
                        beginTime1: oBTimes[0],
                        beginTime2: oBTimes[1],
                        endTime1: oETimes[0],
                        endTime2: oETimes[1]
                    })
                } else {
                    oTimeModels.push({orderNo: 3, beginTime1: null, beginTime2: null, endTime1: null, endTime2: null})
                }
                let sTimeModels = [];
                if (this.showModel.policy.hBeginTime1 && this.showModel.policy.hBeginTime1 !== '') {
                    let sBTimes = this.showModel.policy.hBeginTime1.split(":");
                    let sETimes = this.showModel.policy.hEndTime1.split(":");
                    sTimeModels.push({
                        orderNo: 1,
                        beginTime1: sBTimes[0],
                        beginTime2: sBTimes[1],
                        endTime1: sETimes[0],
                        endTime2: sETimes[1]
                    })
                } else {
                    sTimeModels.push({orderNo: 1, beginTime1: null, beginTime2: null, endTime1: null, endTime2: null})
                }
                if (this.showModel.policy.hBeginTime2 && this.showModel.policy.hBeginTime2 !== '') {
                    let sBTimes = this.showModel.policy.hBeginTime2.split(":");
                    let sETimes = this.showModel.policy.hEndTime2.split(":");
                    sTimeModels.push({
                        orderNo: 2,
                        beginTime1: sBTimes[0],
                        beginTime2: sBTimes[1],
                        endTime1: sETimes[0],
                        endTime2: sETimes[1]
                    })
                } else {
                    sTimeModels.push({orderNo: 2, beginTime1: null, beginTime2: null, endTime1: null, endTime2: null})
                }
                if (this.showModel.policy.hBeginTime3 && this.showModel.policy.hBeginTime3 !== '') {
                    let sBTimes = this.showModel.policy.hBeginTime3.split(":");
                    let sETimes = this.showModel.policy.hEndTime3.split(":");
                    sTimeModels.push({
                        orderNo: 3,
                        beginTime1: sBTimes[0],
                        beginTime2: sBTimes[1],
                        endTime1: sETimes[0],
                        endTime2: sETimes[1]
                    })
                } else {
                    sTimeModels.push({orderNo: 3, beginTime1: null, beginTime2: null, endTime1: null, endTime2: null})
                }
                this.showModel.oTimeModels = oTimeModels;
                this.showModel.sTimeModels = sTimeModels;
            },
            assembleCallFilter(requestParams) {
                // 主条件为空,直接返回
                if ((this.showModel.filterPolicy.primaryCondition && this.showModel.filterPolicy.primaryCondition.length > 0)
                    || (this.showModel.filterPolicy.subConditions && this.showModel.filterPolicy.subConditions.length > 0)) {
                    let {primaryCondition, subConditions, ...filterPolicy} = this.showModel.filterPolicy
                    filterPolicy.conditions = []
                    filterPolicy.conditions.push(...primaryCondition)
                    filterPolicy.conditions.push(...subConditions)
                    this.showModel.filterPolicy = filterPolicy;
                } else {
                    this.showModel.filterPolicy = null;
                }
                requestParams.filterPolicy = this.showModel.filterPolicy
            },
            async saveTemplate() {
                let templateParam = await this.getRequestParamsOfOutboundCallTemplate(null, false);
                if (!templateParam) {
                    return;
                }
                let result;
                if (this.isCreateTemplate) {
                    result = await createAutoOutboundCallTemplate(templateParam);
                } else {
                    result = await updateAutoOutboundCallTemplate(templateParam);
                }
                if (this.rspCode(result)) {
                    this.$message({
                        type: 'success',
                      duration: 5000,
                      offset: 56,
                      message: this.$t('isales.tasktemplate.message.saveSuccess')
                    })
                }
                this.$event.backInTab()
            },
            /**
             * 查询问卷版本
             */
            async querySurveyVersion(scriptId) {
                if(!scriptId ||scriptId===''){
                    return;
                }
                let params = {
                    "rows": 1,
                    "page": 1,
                    "scriptId": scriptId
                };
                querySurveyList(params).then(result => {
                    if (result && result.data && result.data.length > 0) {
                        this.showModel.taskInfo.scenarioType = result.data[0].scenarioType;
                    }
                })
            },
            getByteLength(str) {
              let byteLength = 0;
              for (let i = 0; i < str.length; i++) {
                const charCode = str.charCodeAt(i);
                if (charCode <= 0x007f) {
                  byteLength += 1; // 英文字符占用一个字节
                } else if (charCode <= 0x07ff) {
                  byteLength += 2; // 拉丁文字符占用两个字节
                } else if (charCode <= 0xffff) {
                  byteLength += 3; // 中文字符占用三个字节
                } else {
                  byteLength += 4; // 其他特殊字符占用四个字节
                }
              }
              return byteLength;
            },
        },
        computed: {
            isView() {
                return this.queryType === 'view'
            },
            isEdit() {
                return this.queryType === 'edit'
            },
            isCreate() {
                return this.queryType === 'create'
            },
            isBatchEdit() {
                return this.queryType === 'batchEdit'
            },
            isCreateTemplate() {// 新建外呼任务模型
                return this.queryType === 'createTemplate'
            },
            isEditTemplate() {// 编辑外呼任务模型
                return this.queryType === 'editTemplate'
            },
            isViewTemplate() {// 编辑外呼任务模型
                return this.queryType === 'viewTemplate'
            },
            // 系统外呼校验
            sysAutoRules() {
                const taskNameValidator = (rule, value, callback) => {
                    if (!this.isBatchEdit && !this.showModel.taskInfo.taskName) {
                        callback($t('isales.business.result.message.must'))
                    } else {
                        // 智能外呼需要去CTI创建流程，流程接入码描述总字节长度不能超过50，否则会报错，而isales后台是根据taskName+当前时分秒生成流程接入码描述
                        // 当前时分秒格式为类似为20240124101504这种，占14个字节，因此智能外呼taskName最长（50 - 14） = 36个字节，是要经过编码转换后字节数
                      if (this.showModel.taskInfo.taskType && this.showModel.taskInfo.taskType === TASK_TYPE.INTELLIGENT
                          && this.getByteLength(this.showModel.taskInfo.taskName) > 36) {
                        callback($t('isales.management.message.intelligence.taskName'));
                      }
                      callback();
                    }
                }
                return {
                    taskInfo: {
                        taskName: [
                            {validator: taskNameValidator, trigger: ['blur', 'change']},
                            {validator: this.$Validate.checkSpecialChar, trigger: ['change', 'blur']},
                            {maxLength: 100, validator: this.$Validate.maxLengthValidator, trigger: ['blur', 'change']}
                        ],
                        queryCallerNos: {
                            required: true,
                            message: this.$t('isales.business.result.message.must'),
                            trigger: ['blur','change']
                        },
                        date: {
                            required: true,
                            message: this.$t('isales.business.result.message.must')
                        },
                        skillName: {
                            required: true,
                            message: this.$t('isales.business.result.message.must')
                        },
                        flowName: {
                            required: true,
                            message: this.$t('isales.business.result.message.must')
                        },
                        priority: [
                            {
                                required: true,
                                message: this.$t('isales.business.result.message.must')
                            }
                        ],
                        callBackUrl: [
                            {validator: this.$Validate.checkUrlChar, trigger: ['change', 'blur']},
                            {maxLength: 256, validator: this.$Validate.maxLengthValidator, trigger: ['blur', 'change']}
                        ],
                        description: [
                            {validator: this.$Validate.checkSpecialChar, trigger: ['change', 'blur']},
                            {maxLength: 4000, validator: this.$Validate.maxLengthValidator, trigger: ['blur', 'change']}
                        ],
                    },
                }

            },
        },
        async activated() {
            await this.queryCmsEnvironment();
        },
        async created() {
            if (this.$route.query.step) {
                this.active = this.$route.query.step - 1
            }
            this.queryType = this.$route.query.type;
            this.queryTaskType = this.$route.query.taskType;
            this.queryTaskModel = this.$route.query.taskModel;
            await this.queryCmsEnvironment();
            if (this.isCreate || this.isCreateTemplate) {
                if ('1' === this.queryTaskModel) { // 机器人外呼
                    this.showModel.taskInfo.taskType = this.taskTypeItem.AUTO;
                } else {
                    this.showModel.taskInfo.taskType = this.taskTypeItem.PREDICT;
                }
            } else if (this.isBatchEdit) {
                this.showModel.taskInfo.taskType = this.$route.query.taskType;
            } else if (this.isEditTemplate || this.isViewTemplate) {
                this.showModel.taskInfo.taskId = this.$route.query.taskId;
                this.showModel.taskInfo.taskType = this.$route.query.taskType;
                await this.querySysAutoTaskInfo(this.showModel.taskInfo.taskId, 'template');
            } else {
                this.showModel.taskInfo.taskId = this.$route.query.taskId;
                this.showModel.taskInfo.taskType = this.$route.query.taskType;
                await this.querySysAutoTaskInfo(this.showModel.taskInfo.taskId);
            }
        }
    }
</script>
<style scoped>
    .card-header {
        font-size: var(--16px-to-rem);
        color: #1C1C1C;
        font-weight: bold;
    }
</style>
