<script>
import {Search} from "@element-plus/icons-vue";
import {queryAllSkillGroup, queryAllSkillGroupCount} from "@/views/sumweb/api";
import {nextTick} from "vue";

export default{
  name: "skill-group-dialog",
  components: {
    Search
  },
  data() {
    return {
      display: false,
      tableData: [],
      limit: 10,
      offset: 0,
      total: 0,
      pageSize: 10,
      currentPage: 1,
      pageSizes: [10],
      filterSkill: undefined,
      selected: [],
      selectGroup: [],
      placeHolderToolVisible: false,
      placeHolderToolAccess: false,
    }
  },
  methods: {
    open(skillGroupList) {
      this.selectGroup = skillGroupList;
      this.display = true;
    },
    handleOpen() {
      this.total = 0;
      this.offset = 0;
      this.limit = 10;
      this.currentPage = 1;
      this.tableData = [];
      this.filterSkill = undefined;
      if (this.selectGroup.length > 0) {
        nextTick(() => {
          for (let row of this.selectGroup) {
            this.$refs.multipleTable.toggleRowSelection(row, true);
          }
        })
      }
      this.handleToolTips()
      this.handleRefresh()
    },
    handleRefresh() {
      queryAllSkillGroupCount(this.filterSkill).then(res => {
        this.total = res;
      })

      queryAllSkillGroup(this.limit, this.offset, this.filterSkill).then(res => {
        if (res && res.length > 0) {
          this.tableData = res;
        } else {
          this.tableData = [];
        }
      })
    },
    handleToolTips() {
      let inputRef = this.$refs.inputRef.ref;
      let fontSize = window.getComputedStyle(inputRef).fontSize;
      let placeholder = inputRef.placeholder;
      let inputWidth = inputRef.offsetWidth;

      let span = document.createElement('span');
      span.innerText = placeholder;
      span.style.fontSize = fontSize;
      document.body.appendChild(span);
      let placeHolderWidth = span.offsetWidth;
      document.body.removeChild(span);

      if (inputWidth < placeHolderWidth) {
        this.placeHolderToolAccess = true;
      }
    },
    mounseEnter() {
      if (this.placeHolderToolAccess) {
        this.placeHolderToolVisible = true
      }
    },
    mounseLeave() {
      if (this.placeHolderToolAccess) {
        this.placeHolderToolVisible = false
      }
    },
    handleCurrentChange(currentPage) {
      this.offset = (currentPage - 1) * this.limit
      this.handleRefresh()
    },
    handleSizeChange(count) {
      this.limit = count
      this.offset = 0
      this.currentPage = 1
      this.handleRefresh()
    },
    getRowKey(row) {
      return row.skillGroupId
    },
    handleConfirm() {
      this.selected = this.$refs.multipleTable.getSelectionRows();
      this.$emit('getSkillGroup', this.selected)
      this.display = false
    },
    clearSelected() {
      this.$refs.multipleTable.clearSelection()
    },
    handleClose() {
      this.clearSelected();
    },
  }
}
</script>

<template>
  <el-dialog v-model="display"
             :title="$t('ccm.agent.label.selectSkillGroup')"
             @open="handleOpen"
             @close="handleClose"
             width="616" class="aicc-dialog-limit-height">
    <el-tooltip :content="$t('ccm.skillgroup.label.searchname')"
                :visible="placeHolderToolVisible"
                placement="top"
                effect="light">
      <el-input
          v-model="filterSkill"
          ref="inputRef"
          style="width: 240px;margin-end: 10px"
          clearable
          autocomplete="off"
          @keyup.enter="handleRefresh"
          @mouseenter="mounseEnter"
          @mouseleave="mounseLeave"
          :placeholder="$t('ccm.note.config.notename')">
        <template #prefix>
          <el-icon class="search-icon" @click="handleRefresh">
            <Search />
          </el-icon>
        </template>
      </el-input>
    </el-tooltip>
    <el-tooltip :content="$t('cms.export.field.refresh')"
                placement="top"
                effect="light">
      <el-button class="aicc-button-icon" @click="handleRefresh">
        <img class="refresh-icon-img" src="@/assets/img/search/search_refresh.png" alt="search">
      </el-button>
    </el-tooltip>
    <el-table :data="tableData"
              ref="multipleTable"
              style="margin-top: 10px"
              height="430"
              :row-key="getRowKey"
              tooltip-effect="light">
      <el-table-column type="selection"
                       width="55"
                       reserve-selection/>
      <el-table-column property="name"
                       :show-overflow-tooltip="true"
                       :label="$t('ccm.skillgroup.label.searchname')" />
      <el-table-column property="skills"
                       :show-overflow-tooltip="true"
                       :label="$t('ccm.agent.label.skills')"  />
    </el-table>
    <el-pagination
        v-model:page-size="pageSize"
        :page-sizes="pageSizes"
        :total="total"
        v-model:current-page="currentPage"
        :small="false"
        :disabled="false"
        :background="false"
        layout="total, prev, pager, next"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
    />
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="display = false">{{$t('cms.indexmonitor.field.cancel')}}</el-button>
        <el-button type="primary" @click="handleConfirm">
          {{$t('cms.indexmonitor.field.confirm')}}
        </el-button>
      </div>
    </template>
  </el-dialog>

</template>

<style scoped lang="less">

.search-icon {
  height: 16px;
  margin-top: 0;
  cursor: pointer;
  transition: all 0.2s;

  &:active {
    transform: scale(0.9);
  }
}

img {
  width: 16px;
  position: relative;
  top: 1px;
}

</style>