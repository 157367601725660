export default {
  "dy.agent.audio.tab.outgoingcall" : "Chamada de saída",
  "dy.agent.audio.tab.internalcall" : "Chamada Interna",
  "dy.agent.tab.over.number.hints" : "Há no máximo 8 guias abertas. Você não pode abrir mais guias. Por favor, feche as outras guias primeiro",
  "dy.chat.multimedia.update" : "Atualização",
  "dy.chat.agent.logout.hints" : "Não está registado, por favor inicie sessão para iniciar o seu trabalho",
  "dy.chat.label.contacts" : "Atendimentos",
  "dy.chat.label.call" : "Chamada",
  "dy.chat.label.multimedia" : "Multimídia",
  "dy.chat.label.history" : "Histórico",
  "dy.chat.label.monitor" : "Inspeção",
  "dy.agent.label.id" : "IDENTIFICAÇÃO:",
  "dy.agent.label.enter.exnum" : "Introduzir Número Externo",
  "dy.chat.multimedia.required" : "Campo Obrigatório",
  "dy.chat.history.disconnect.tip" : "A chamada foi desconectada, você pode enviar uma mensagem offline para se reconectar ao cliente",
  "dy.agent.voice.extnumber.isnull" : "O número externo é nulo.",
  "dy.agent.voice.msg.noskill" : "Aucune file d'attente de compétences n'est disponible",
  'dy.agent.session.list.timeout': 'Tempo limite',
  'dy.session.transfer.button': 'Eu sei',
  'dy.agent.transfer.failed': 'Falha ao transferir para o agente de destino',
  'dy.agent.twoinnerhelp.failed': 'Falha ao consultar o agente de destino',
  'dy.agent.threeinnerhelp.failed': 'Falha de três partes do agente de destino',
  'dy.agent.message.tipsNotMonitor': 'Você não é um inspetor. Você não pode realizar a inspeção.',
  'dy.agent.message.selectInfo': 'Clique no agente primeiro para inspecionar o agente.',
  'dy.agent.attachment.notPreview': 'O tipo de arquivo não suporta visualização, faça o download do arquivo para visualizar o conteúdo.',
  'dy.chat.agent.novoEmail': 'Novo e-mail',
  "dy.chat.sendmessage.recipient" : "Para",
  "dy.chat.chatcard.folded": "Dobrado",
  "dy.chat.status.drafts": "Rascunhos",
  "dy.agent.call.selectextnumber": "Selecione um número externo.",
  "dy.agent.call.inputextnumber": "Introduza um número externo."
}
