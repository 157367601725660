export default {
  "dy.agent.audio.tab.outgoingcall" : "外呼",
  "dy.agent.audio.tab.internalcall" : "内部呼叫",
  "dy.agent.tab.over.number.hints" : "Tab页签最多打开8个，您不能打开更多页签，请先关闭其他页签",
  "dy.chat.multimedia.update" : "更换",
  "dy.chat.agent.logout.hints" : "未签入，请登录以开始您的工作",
  "dy.chat.label.contacts" : "会话列表",
  "dy.chat.label.call" : "语音外呼",
  "dy.chat.label.multimedia" : "多媒体外呼",
  "dy.chat.label.history" : "接触记录",
  "dy.chat.label.monitor" : "座席监控",
  "dy.agent.label.id" : "ID:",
  "dy.agent.label.enter.exnum" : "输入外部号码",
  "dy.chat.multimedia.required" : "必填",
  "dy.chat.history.disconnect.tip" : "呼叫已断开，您可以发送离线消息重新连接客户",
  "dy.agent.voice.extnumber.isnull" : "外部号码为空",
  "dy.agent.voice.configextnumber.isnull" : "当前无外部号码数据",
  "dy.agent.voice.msg.noskill" : "当前无可用技能队列",
  'dy.agent.session.list.timeout': '超时',
  'dy.session.transfer.button': '知道了',
  'dy.agent.transfer.failed': '目的座席转移失败',
  'dy.agent.twoinnerhelp.failed': '目的座席咨询失败',
  'dy.agent.threeinnerhelp.failed': '目的座席三方失败',
  'dy.agent.message.tipsNotMonitor': '您当前不是质检员，无法进行质检操作！',
  'dy.agent.message.selectInfo': '请先点击座席，对座席进行质检操作',
  'dy.agent.attachment.notPreview': '文件类型不支持预览，请下载文件以查看内容。',
  "dy.chat.agent.novoEmail": "新邮件",
  "dy.chat.sendmessage.recipient" : "收件人",
  "dy.chat.chatcard.folded": "收起",
  "dy.chat.status.drafts": "草稿",
  "dy.agent.call.selectextnumber": "请选择外部号码",
  "dy.agent.call.inputextnumber": "请输入外部号码"
}