import Layout from '@oifde/components/OifdeLayout/OifdeLayout.vue'

export default [
  {
    path: '/aiccOifde/flow',
    component: Layout,
    redirect: '/aiccOifde/flow/flowList',
    alwaysShow: true,
    meta: {
      title: 'oifde.route.navigation'
    },
    children: [
      {
        path: '/aiccOifde/flow/flowList', // 流程编排
        name: 'oifdeFlowList',
        meta: {
          title: 'oifde.route.flow',
          noCache: false,
          menuId: 'oifde-navigation-flows'
        },
        component: () => import('@/views/oifde/views/flow/flowList.vue')
      },
      {
        path: '/aiccOifde/flow/trace', // 流程轨迹
        name: 'oifdeTraceLog',
        meta: { title: 'oifde.route.trace', noCache: false, menuId: 'oifde-process-track' },
        component: () => import('@/views/oifde/views/tracelog/tracelog.vue')
      },
      {
        path: '/aiccOifde/transferRecord', // 流程转接记录
        name: 'oifdeTransferRecord',
        meta: {
          title: 'oifde.route.transferRecord',
          noCache: true,
          menuId: 'oifde-transfer_record'
        },
        component: () => import('@/views/oifde/views/transferRecord/transferRecord.vue')
      },
      {
        path: '/aiccOifde/test', // 对话测试
        name: 'oifdeChatBotTestCaseMgr',
        meta: {
          title: 'oifde.route.tuc.ops.test',
          noCache: false,
          menuId: 'oifde-test-management'
        },
        component: () => import('@/views/oifde/views/test/chatBotTestCase.vue')
      },
      {
        path: '/aiccOifde/staticCheck', // 流程检查
        name: 'oifdeStaticCheck',
        meta: {
          title: 'oifde.route.staticCheck',
          noCache: true,
          menuId: 'static-check'
        },
        component: () => import('@/views/oifde/views/staticCheck/staticCheck.vue')
      }
    ]
  }
]