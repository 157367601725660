export default {
  "ccm.agent.button.search": "جستجو",
  "ccm.agent.button.clear": "بازنشانی کنید",
  "ccm.agent.button.refresh": "بازخوانی",
  "ccm.agent.button.export": "صدور",
  "ccm.agent.button.edit": "ویرایش کنید",
  "ccm.agent.button.cancel": "لغو کنید",
  "ccm.agent.button.submit": "ارسال",
  "ccm.agent.button.ok": "خوب",
  "ccm.agent.button.create": "جدید",
  "ccm.agent.button.createComplete": "کامل",
  "ccm.agent.button.save": "ذخیره",
  "ccm.agent.button.delete": "حذف",
  "ccm.agent.button.downloadvideoterminal": "بارگیری OpenEye",
  "ccm.agent.label.querymobileagent": "شماره تلفن نرم افزاری را استعلام کنید",
  "ccm.agent.label.modifymobileagent": "شماره تلفن نرم افزاری را ویرایش کنید",
  "ccm.agent.label.mobileagentremark": "ملاحظات",
  "ccm.agent.label.mobileagententer": "وارد",
  "ccm.agent.label.isaireconition": "درست ",
  "ccm.callreason.case.check.error": "نوع التذكرة {0} غير موجود.",
  "ccm.agent.label.noaireconition": "نادرست",
  "ccm.agent.message.weight.limit": "[100-1]",
  "ccm.agent.message.agentweight.limit": "[300-1]",
  "ccm.agent.label.startworkno": "شناسه کارمند را شروع کنید",
  "ccm.agent.label.endworkno": "شناسه کارمند را پایان دهید",
  "ccm.agent.label.inputstartworkno": "لطفاً شناسه کارمند شروع را وارد کنید",
  "ccm.agent.label.inputendworkno": "لطفا شناسه کارمند پایان را وارد کنید",
  "ccm.agent.label.batchselect": "برگزیدن عاملها در گروهها",
  "ccm.agent.label.batchselectway": "حالت انتخاب دسته ای",
  "ccm.agent.label.selectagent": "نمایندگان منتخب",
  "ccm.agent.ten.mediaagents": "تعداد نمایندگان چند رسانه ای",
  "ccm.agent.ten.versatileagents": "نمایندگان چند منظوره",
  "ccm.agent.ten.allowedTTSAndASRCallNum": "تعداد کانال های تلفن گویا هوشمند",
  "ccm.agent.label.airecog": "تشخیص هوشمند",
  "ccm.agent.label.singlePhoneFlag": "مامور فقط تلفن",
  "ccm.agent.label.dualChannelRecFlag": "ضبط آهنگ دوگانه",
  "ccm.agent.message.addAIRecogFail": "تعداد نمایندگان هوشمند فعال نمی‌تواند از تعداد نمایندگان تخصیص داده شده در زمان فعال بودن محیط کاری tenant بیشتر باشد.",
  "ccm.agent.message.ctipasswdrepeat": "رمز عبور جدید نمی تواند مانند پنج رمز عبور قبلی باشد.",
  "ccm.agent.message.ctipasswdsimple": "رمز عبور الزامات پیچیدگی پلتفرم را برآورده نمی کند. برای بررسی رمز عبور با پرسنل O M تماس بگیرید.",
  "ccm.agent.message.ctipasswdless": "طول رمز عبور نمی تواند کمتر از 8 باشد.",
  "ccm.agent.label.transparentDisplayFlag": "پرچم ناشناس کردن شماره نماینده",
  "ccm.agent.label.selfPhoneNotNull": "وقتی ویژگی ناشناس کردن شماره نماینده فعال است، شماره تلفن خط ثابت نماینده یا شماره تلفن همراه نمی تواند خالی باشد.",
  "ccm.agent.label.theAgent": "نماینده:",
  "ccm.agent.label.bindTheSkill": "قبل از اینکه از صف مهارت\ استفاده کنید، ابتدا مسیر فراخوانی را باز کنید.",
  "ccm.agent.label.skillQueue": "صف مهارت:",
  "ccm.agent.label.cti": "cti:",
  "ccm.agent.contact.agent.recall": "نماینده پیام را پس گرفته است",
  "ccm.agent.contact.user.recall": "کاربر پیام را پس گرفته است",
  "ccm.agent.label.deleteAgentFailed": "یک صف مهارت به یک حساب تجاری متصل شده است. ابتدا پیکربندی صف مهارت نماینده را حذف کنید.",
  "ccm.agent.label.deleteCtiFailed": "اطلاعات پلتفرم Cti حذف نشد.",
  "ccm.agent.label.useTheSkill": "صف مهارت ها به مامور شماره شناسايي شد ابتدا پیکربندی صف مهارت زیر مأمور حذف شود.",
  "ccm.agent.label.information": "نکات",
  "iolp.configur.service.label.none.desc": "پیکربندی دسته سرویسی موجود نیست. اول یک دسته سرویس ایجاد کنید.",
  "ccm.agent.recall.fail": "تماس مجدد با مشتری انجام نشد.",
  "ccm.agent.recall.param.callid.empty": "هیچ شماره تماس برگشتی در دسترس نیست.",
  "ccm.agent.recall": "تماس برگشتی",
  "ccm.agent.recall.agent.calltype.invalid": "نوع تماس فعلی از عملیات تماس برگشتی پشتیبانی نمی کند.",
  "ccm.agent.recall.agent.status.invalid": "نماینده در حالت بیکار یا مشغول نیست و نمی تواند تماس بگیرد.",
  "ccm.agent.label.addChildReason": "تنظیمات علت نباید از سطح 6 تجاوز کند",
  "ccm.agent.label.paramsConfig": "پیکربندی پارامتر سیستم",
  "ccm.agent.label.registrationMessage": "اطلاعات ثبت نام",
  "ccm.agent.label.basicInfo": "اطلاعات پایه",
  "ccm.agent.label.serverAddress": "آدرس سرور ثبت نام",
  "ccm.agent.label.fixedLine": "شماره تلفن ثابت / تلفن همراه",
  "ccm.agent.label.audio": "نماینده صدا",
  "ccm.agent.label.video": "نماینده ویدیو",
  "ccm.agent.label.mediaagent": "نماینده چند رسانه ای",
  "ccm.agent.label.versatileagent": "نماینده چند منظوره",
  "ccm.agent.label.on": "روشن کن",
  "ccm.agent.label.off": "خاموش شدن",
  "ccm.agent.label.selectSkill": "صف مهارت را انتخاب کنید",
  "ccm.agent.label.workNo": "شناسه کارمند",
  "ccm.agent.label.sipAccount": "شماره تلفن نرم افزاری",
  "ccm.agent.label.sipPwd": "رمز عبور تلفن نرم افزاری",
  "ccm.agent.label.agentType": "نوع نماینده",
  "ccm.agent.label.agentTel": "شماره موبایل نماینده/ تلفن ثابت ",
  "ccm.agent.label.validTime": "دوره اعتبار رمز عبور (روزها)",
  "ccm.agent.label.timeRemaining": "انقضای رمز عبور (روز)",
  "ccm.agent.label.ccRole": "نقش",
  "ccm.agent.label.ccRoleId": "نقش پلت فرم",
  "ccm.agent.label.serviceAccountId": "شناسه حساب سرویس ابر ",
  "ccm.agent.label.serviceAccountName": "نام حساب سرویس ابر",
  "ccm.agent.label.serviceAccountCode": "حساب",
  "ccm.agent.label.skills": "صف مهارت",
  "ccm.agent.label.serviceCode": "نوع خدمت",
  "ccm.agent.label.duration": "المدة الزمنية",
  "ccm.agent.label.queueTime": "مدت صف",
  "ccm.agent.label.availabilityTime": "مدت زمان موجود",
  "ccm.agent.label.mutetime": "مدت بی صدا",
  "ccm.agent.label.calltime": "بدون انحراف فراخوانی بدون صدا",
  "ccm.agent.label.ringingTime": "مدت زنگ زدن",
  "ccm.agent.label.serviceLevel": "مدت زمان انتظار %",
  "ccm.agent.label.transferInformation": "انتقال یا نه",
  "ccm.agent.label.transfer": "تعداد دفعات انتقال",
  "ccm.agent.label.ivrUsage": "تعداد دفعات انتقال ivr",
  "ccm.agent.label.originId": "شناسه ی کاری انتقال",
  "ccm.agent.label.queueName": "صف مهارتهای انتقال",
  "ccm.agent.label.wrapUpCode": "وضعیت کلی",
  "ccm.agent.label.uuiInfo": "اطلاعات uui",
  "ccm.agent.label.overallState": "وضعیت کلی",
  "ccm.agent.label.maxWaitTime": "حداکثر زمان انتظار",
  "ccm.agent.label.maxWaitNum": "حداکثر تماس در صف",
  "ccm.agent.label.agentList": "حساب خدمات",
  "ccm.agent.label.Account": "حساب",
  "ccm.agent.label.adjustTime": "مدت زمان (ها) در حالت تنظیم",
  "ccm.agent.label.description": "شرح",
  "ccm.agent.label.subtype": "زیرنوع",
  "ccm.agent.contact.subtype.none": "هیچ یک",
  "ccm.agent.label.skillId ": "شناسه صف مهارت",
  "ccm.agent.label.skillName": "نام صف مهارت",
  "ccm.agent.label.maxWaitTimeSpan": "مقدار [1\u201360000]",
  "ccm.agent.label.maxAgentWrapupTime": "مقدار [0\u20133600]",
  "ccm.agent.label.operate": "عمل",
  "ccm.agent.label.ordinaryAgent": "نماینده معمولی",
  "ccm.agent.label.configAdmin": "مدیر پیکربندی",
  "ccm.agent.label.weight": "وزن",
  "ccm.agent.label.skillWeight": "وزن مهارت نماینده ",
  "ccm.agent.label.agentWeight": "وزن نماینده ",
  "ccm.agent.label.skillWeightTip": "هنگامی که یک نماینده وارد چندین صف می شود، ابتدا تماس های موجود در صف مهارت با بیشترین وزن پردازش می شوند.",
  "ccm.agent.label.agentWeightTip": "تماس ها ترجیحاً به نمایندگانی با بالاترین وزن اختصاص داده می شود.",
  "ccm.agent.label.waitSkillWeight": "وزن مهارت را وارد کنید.",
  "ccm.agent.label.waitAgentWeight": "وزن نماینده را وارد کنید.",
  "ccm.agent.label.param.index": "شماره",
  "ccm.agent.label.paramValue": "ارزش",
  "ccm.agent.label.CommomAgent": "نماینده معمولی",
  "ccm.agent.label.qualityChecker": "بررسی کننده کیفیت",
  "ccm.agent.label.CallOutAgent": "Callout Agent",
  "ccm.agent.label.skillpara": "پیکربندی پارامتر مهارت",
  "ccm.agent.label.timeOut": "Skill Timeout",
  "ccm.agent.label.busy": "مهارت Busy",
  "ccm.agent.label.noAgents": "فراخوانی پردازش هیچ کس در ووتی",
  "ccm.agent.label.transferType": "روش فرآیند",
  "ccm.agent.label.deviceType": "نوع وسیله",
  "ccm.agent.label.ivrChoice": "تلفن گویا را انتخاب کنید",
  "ccm.agent.label.skillChoice": "مهارت را انتخاب کنید",
  "ccm.agent.label.release": "آزاد کردن",
  "ccm.agent.label.transfer": "انتقال",
  "ccm.agent.label.ivr": "تلفن گویا",
  "ccm.agent.label.withinFifteenDays": "ظرف 15 روز",
  "ccm.agent.label.withinSevenDays": "در عرض 7 روز",
  "ccm.agent.label.expired": "منقضی شده",
  "ccm.agent.label.waitVoiceConfig": "تنظیمات صف و انتظار",
  "ccm.agent.label.waitVoiceSelect": "لطفاً یک آهنگ انتظار را انتخاب کنید",
  "ccm.agent.label.waitVoiceDefault": "آهنگ انتظار پیش‌فرض",
  "ccm.agent.label.waitVoiceCustom": "سفارشی کردن آهنگ انتظار",
  "ccm.agent.label.parameterType": "روش نوبت دهی",
  "ccm.agent.label.playVoice": "آهنگ انتظار را پخش کنید",
  "ccm.agent.label.answerType": "نوع پاسخ",
  "ccm.agent.label.answerType0": "شناسه کارمند را گزارش دهید",
  "ccm.agent.label.answerType2": "گزارش بدون صدا",
  "ccm.agent.label.keepVoiceConfig": "پیکربندی نگهداری و انتظار",
  "ccm.agent.label.keepWaitType": "روش نگهداری",
  "ccm.agent.label.keepVoiceDefault": "پیش فرض Keeping Tone",
  "ccm.agent.label.keepVoiceSelect": "لطفاً یک Keeping Tone انتخاب کنید",
  "ccm.agent.label.keepVoiceCustom": "سفارشی کردن Keeping Tone",
  "ccm.agent.label.createWorkOrdeType": "نوع پرونده مرتبط",
  "ccm.agent.message.queryctiagentmessage": "اطلاعات نماینده در CTI یافت نشد.",
  "ccm.agent.message.nulagentincti": "نماینده فعلی انتخاب شده در CTI ایجاد نشده است، باید اطلاعات نقش را انتخاب کنید.",
  "ccm.agent.message.isornoairecognition": "وضعیت هوشمند",
  "ccm.agent.message.querycenterfailed": "جستجو از نماینده هوشمند مرکز تماس انجام نشد.",
  "ccm.agent.message.aiagentnummore": "تعداد نمایندگان هوشمند فعال نمی‌تواند از تعداد نمایندگان تخصیص داده شده در زمان فعال بودن محیط کاری tenant بیشتر باشد.",
  "ccm.agent.message.aiagentclosed": "ویژگی شناسایی هوشمند محیط کاری tenant غیرفعال است. با مدیر سیستم تماس بگیرید.",
  "ccm.agent.message.updateagentandskillfail": "ارتباط بین نماینده و صف مهارت به‌روزرسانی نشد.",
  "ccm.agent.message.worknull": "شناسه کارمند خالی است.",
  "ccm.agent.message.airecognull": "پرچم تشخیص هوشمند خالی است.",
  "ccm.agent.message.worknonoexit": "شناسه کارمند وجود ندارد.",
  "ccmanagement.agent.batchmodify.agentnum": "با شناسه کارمند",
  "ccmanagement.agent.batchmodify.agentlis": "توسط بخش",
  "ccm.agent.button.batchmodify": "اصلاح دسته ای",
  "ccm.agent.message.worknostartbig": "شناسه کارمند شروع نمی تواند بزرگتر از شناسه کارمند پایانی باشد.",
  "ccm.agent.message.typenosame": "انواع صف مهارت باید یکسان باشد.",
  "ccm.agent.message.agentandskillnotmatch": "نوع مهارت باید با نوع نماینده مطابقت داشته باشد",
  "ccm.agent.message.agenttypecannotbenull": "اگر صف مهارت انتخاب شده است، نوع نماینده را انتخاب کنید.",
  "ccm.agent.message.agentnumreachmax": "تعداد نوع نماینده هدف به حداکثر رسیده است",
  "ccm.agent.message.omsAgentTypeCheck": "نقش انتخاب شده با نوع نماینده برای یک نماینده آنلاین مطابقت ندارد.",
  "ccm.agent.message.audioagentzero": "تعداد نمایندگان صوتی 0 است. صف مهارت کلیک برای شماره گیری صوتی قابل پیکربندی نیست.",
  "ccm.agent.message.videoagentzero": "تعداد نمایندگان ویدیو 0 است. صف مهارت کلیک برای شماره گیری ویدیو قابل پیکربندی نیست.",
  "ccm.agent.message.omsAgentTypeBatchCheck": "وقتی نماینده (شناسه کارمند [\{0}]) به صورت آنلاین فعال باشد، نقش یا نوع نماینده انتخاب شده مطابقت ندارد.",
  "ccm.agent.message.agentnumcannotmuchthanmax": "تعداد نوع نماینده هدف نمی تواند بیشتر از حداکثر باشد",
  "ccm.agent.message.agentnummaxoperation": "حداکثر 100 نماینده را انتخاب کنید.",
  "ccm.agent.message.searchAccount": "یک شناسه نماینده وارد کنید.",
  "ccm.agent.message.searchSipAccount": "یک شماره تلفن نرم افزاری SIP وارد کنید.",
  "ccm.agent.message.searchAccountName": "یک حساب کاربری وارد کنید.",
  "ccm.agent.message.warning": "هشدار",
  "ccm.agent.message.selectAgents": "لطفا یک نماینده انتخاب کنید",
  "ccm.agent.message.selectSkills": "لطفاً یک صف مهارت انتخاب کنید.",
  "ccm.agent.message.clear": "همه را پاک کن",
  "ccm.agent.message.delete": "حذف",
  "ccm.agent.message.deleteSkill": "صف مهارت را حذف کنید",
  "ccm.agent.message.willClearAgents": "آیا مطمئن هستید که می خواهید نمایندگان انتخاب شده را پاک کنید؟",
  "ccm.agent.message.willDeleteAgents": "آیا مطمئن هستید که می خواهید نماینده انتخاب شده را حذف کنید؟",
  "ccm.agent.message.willDeleteSkills": "هل تريد بالتأكيد حذف {0} قوائم انتظار المهارات؟",
  "ccm.agent.message.agentConfSuccess": "اطلاعات نماینده با موفقیت پیکربندی شد.",
  "ccm.agent.message.agentConfFail": "پیکربندی اطلاعات نماینده ناموفق بود.",
  "ccm.agent.message.accRepeatFail": "پارامتر حساب تکراری است.",
  "ccm.agent.message.agentNotFoundFail": "اطلاعات نماینده یافت نشد.",
  "ccm.agent.message.agentCreateFail": "نماینده در CTI ایجاد نشد.",
  "ccm.agent.message.agentUpdateFail": "گزارش به‌روزرسانی انجام نشد.",
  "ccm.agent.message.description.limit": "طول توضیحات نمی تواند بیشتر از 50 باشد.",
  "ccm.agent.message.name.limit": "طول نام نمی تواند بیشتر از 20 باشد.",
  "ccm.agent.message.name.grouplimit": "اسم نميتونه از 64 بيشتر بشه",
  "ccm.agent.message.sysParamCofig": "پیکربندی پارامترهای سیستم ناموفق بود.",
  "ccm.agent.message.sysParamCofig.openVersatileAgentFail": "نوع نماینده را در صفحه مدیریت نماینده تغییر دهید. تعداد نمایندگان چند منظوره و نمایندگان ویدیویی نمی تواند بیشتر از حداکثر تعداد نمایندگان ویدیویی باشد که محیط کاری tenant می تواند مشترک آنها باشد.",
  "ccm.agent.message.sysParamCofig.closeVersatileAgentFail": "نوع نماینده را در صفحه مدیریت نماینده تغییر دهید. یک نماینده چند منظوره را نمی توان با صف مهارت کلیک برای شماره گیری ویدیو یا ویدیو مرتبط کرد.",
  "ccm.agent.message.deleteAgentSkillFail": "ارتباط صف مهارت نماینده حذف نشد.",
  "ccm.agent.message.updateAgentAndSkillFail": "ارتباط صف مهارت نماینده به‌روزرسانی نشد.",
  "ccm.agent.message.createAgentAndSkillFail": "ایجاد ارتباط صف مهارت نماینده انجام نشد.",
  "ccm.agent.message.searchSkillName": "نام صف مهارت را وارد کنید.",
  "ccm.agent.message.searchsoftphone": "شماره تلفن نرم افزاری را وارد کنید.",
  "ccm.agent.message.skillConfSuccess": "اطلاعات صف مهارت با موفقیت پیکربندی شد.",
  "ccm.agent.message.deleteSkillConfSuccess": "اطلاعات صف مهارت با موفقیت حذف شد.",
  "ccm.agent.message.createSkillConfSuccess": "اطلاعات صف مهارت با موفقیت ایجاد شد.",
  "ccm.agent.message.modifySkillConfSuccess": "اطلاعات صف مهارت با موفقیت اصلاح شد.",
  "ccm.agent.message.modifymobileagentsuccess": "اطلاعات مربوط به شماره تلفن نرم افزاری نماینده سیار با موفقیت اصلاح شد.",
  "ccm.agent.message.skillConfFail": "پیکربندی اطلاعات صف مهارت انجام نشد.",
  "ccm.agent.message.deleteSkillConfFail": "اطلاعات صف مهارت حذف نشد.",
  "ccm.agent.message.deleteSkillConfFailID": "خرابی در حذف {0}.",
  "ccm.agent.message.createSkillConfFail": "اطلاعات صف مهارت ایجاد نشد.",
  "ccm.agent.message.modifySkillConfFail": "اصلاح اطلاعات صف مهارت انجام نشد.",
  "ccm.agent.message.modifymobileagentfail": "اطلاعات مربوط به شماره تلفن نرم افزاری نماینده سیار تغییر نکرد.",
  "ccm.agent.message.idIsEmpty": "شناسه صف مهارت خالی است.",
  "ccm.agent.message.skillHasbeenUsedByAgent": "صف مهارت توسط یک نماینده اشغال شده است. ابتدا نماینده را حذف کنید.",
  "ccm.agent.message.updateCtiAndDbSuccess": "همگام سازی موفقیت آمیز بود",
  "ccm.agent.message.deleteSkillAndAgentFail": "حذف مهارت و نماینده انجام نشد.",
  "ccm.agent.message.addSkillFail": "افزودن مهارت انجام نشد.",
  "ccm.agent.message.modifySkillFail": "به‌روزرسانی مهارت انجام نشد.",
  "ccm.agent.message.queryCallCenterFail": "جستجو مرکز تماس ناموفق بود.",
  "ccm.agent.message.refreshSkillException": "بازخوانی استثناء مهارت.",
  "ccm.agent.message.updateCtiAndDbFail": "به روز رسانی cti و Db ناموفق بود.",
  "ccm.agent.message.inputWeight": "مقدار وزن را وارد کنید.",
  "ccm.agent.message.agentCTIFail": "نمایندگان جستجو اصلاح شده در دروازه انجام نشد. لطفاً پیکربندی دروازه را بررسی کنید.",
  "ccm.agent.message.longCharacters": "این عدد می تواند حداکثر 25 کاراکتر داشته باشد.",
  "ccm.agent.message.longCharacters.form": "این عدد می تواند حداکثر 25 کاراکتر داشته باشد",
  "ccm.agent.message.specialCharacters": "شماره شامل کاراکترها و حروف خاص است.",
  "ccm.agent.message.export": "صدور",
  "ccm.agent.message.export.skill": "صادرات صف مهارت",
  "ccm.agent.info.message.export": "صادرات اطلاعات مامور",
  "ccm.agent.message.selected": "شما انتخاب کرده اید\u0020",
  "ccm.agent.message.willExportAgent": "  \u0020پیام ها آیا مطمئن به صدور هستید؟",
  "ccm.agent.message.allExportAgent": "آیا مطمئن هستید که می خواهید تمام اطلاعات نماینده را صدور کنید؟",
  "ccm.agent.message.configvalidatefailed": "تأیید مورد پیکربندی دروازه ناموفق بود",
  "ccm.agent.message.batchExport": "صدور دسته ای",
  "ccm.agent.message.allExport": "صادرات همه",
  "ccm.agent.message.searchTimeRemaining": "انقضای رمز عبور",
  "ccm.agent.message.redislocknull": "به دست آوردن قفل Redis ناموفق بود.",
  "ccm.agent.message.webRTCVerification": "هنگامی که ویژگی WebRTC فعال است، نمی توان این پارامتر را پیکربندی کرد.",
  "ccm.agent.title.agentEdit": "پیکربندی اطلاعات نماینده",
  "ccm.agent.title.batchagentEdit": "پیکربندی دسته ای اطلاعات نماینده",
  "ccm.agent.title.selectskill": "صف مهارت را انتخاب کنید",
  "ccm.agent.title.serviceAccount": "حساب سرویس ابر مرتبط",
  "ccm.agent.title.skillModify": "صف مهارت را اصلاح کنید",
  "ccm.agent.title.skillCreate": "صف مهارت جدید",
  "ccm.agent.title.specialListHis": "تاریخچه لیست ویژه",
  "ccm.agent.tips.confSuccess": "پیکربندی با موفقیت انجام شد.",
  "ccm.agent.tips.noagent": "هیچ عاملی در قطعۀ عدد برگزیده وجود ندارد.",
  "ccm.agent.tips.confFail": "پیکربندی انجام نشد",
  "ccm.agent.pageurls.create": "جدید",
  "ccm.agent.pageurls.sernum": "شماره",
  "ccm.agent.pageurls.pagename": "نام صفحه",
  "ccm.agent.pageurls.accessaddr": "دسترسی به آدرس",
  "ccm.agent.pageurls.openMode": "حالت باز",
  "ccm.agent.pageurls.configpage": "صفحه پیکربندی",
  "ccm.agent.pageurls.config": "پیکربندی",
  "ccm.agent.pageurls.isenable": "فعال کردن",
  "ccm.agent.pageurls.operation": "عمل",
  "ccm.agent.pageurls.delete": "حذف",
  "ccm.agent.pageurls.edit": "ویرایش کنید",
  "ccm.agent.pageurls.moveup": "بالا",
  "ccm.agent.pageurls.movedown": "پایین",
  "ccm.agent.pageurls.createcall": "پاپ آپ صفحه نمایش تماس ورودی جدید ",
  "ccm.agent.pageurls.config.screen.pop.up": "بالاپری پردۀ پیکربندی",
  "ccm.agent.pageurls.unsaved.data.exists":"داده های ذخیره نشده وجود دارد. لطفا اول نگهش دار.",
  "ccm.agent.pageurls.errorsave": "خطا",
  "ccm.agent.pageurls.errorinfo": "ایجاد ناموفق بود.",
  "ccm.agent.pageurls.successinfo": "با موفقیت ایجاد شد.",
  "ccm.agent.pageurls.successinfo.noPoint": "با موفقیت ایجاد شد",
  "ccm.agent.pageurls.maxcalling": "حداکثر تعداد صفحه های صدا بالا - بالا - {limit} است.",
  "ccm.agent.pageurls.updatecall": "پنجره بازشو صفحه تماس ورودی را اصلاح کنید",
  "ccm.agent.pageurls.confdelete": "تایید حذف",
  "ccm.agent.pageurls.confreset": "تنظیم مجدد را تایید کنید",
  "ccm.agent.pageurls.isconfdelete": "آیا مطمئن هستید که می خواهید آن را حذف کنید؟",
  "ccm.agent.pageurls.isconfreset": "آیا مطمئن هستید که می خواهید آن را بازنشانی کنید؟",
  "ccm.agent.pageurls.success": "موفقیت آمیز",
  "ccm.agent.pageurls.delsuccess": "با موفقیت حذف شد.",
  "ccm.agent.pageurls.fail": "خطا",
  "ccm.agent.pageurls.delfail": "حذف نشد.",
  "ccm.agent.pageurls.adressval": "آدرس دسترسی باید با «https://» یا «http://» شروع شود.",
  "ccm.agent.pageurls.adressval.noPoint": "آدرس دسترسی باید با «https://» یا «http://» شروع شود",
  "ccm.agent.pageurls.updateinfo": "با موفقیت به روز شد.",
  "ccm.agent.moveup.error": "خرابی در حرکت.",
  "ccm.agent.moveup.success": "با موفقیت حرکت کنید.",
  "ccm.agent.movedown.error": "خرابی در حرکت.",
  "ccm.agent.movedown.success": "حرکت با موفقیت.",
  "ccm.agent.pageurls.errorupdate": "به روز رسانی نا موفق بود.",
  "ccm.agent.pageurls.confnaming": ". اطمینان حاصل کنید که نام در سیستم وجود ندارد.",
  "ccm.agent.pageurls.confonlynaming": ". اطمینان حاصل کنید که نام منحصر به فرد است.",
  "ccm.agent.pageurls.maxcallingnum": ". اطمینان حاصل کنید که تعداد صفحه های پاپ آپ از 5 بیشتر نباشد.",
  "ccm.agent.pageurls.whitelisturl": ". لطفاً با مدیر سیستم تماس بگیرید تا آدرس اینترنتی را به لیست اعتماد اضافه کنید.",
  "ccm.agent.pageurls.typenosupported": "حالت چند رسانه ای از پیکربندی صفحه داخلی پشتیبانی نمی کند.",
  "ccm.agent.pageurls.independentlynosupported": "پیکربندی صفحه داخلی برای باز کردن مستقل پشتیبانی نمی شود.",
  "ccm.agent.pageurls.integrationType": "نوع ادغام",
  "ccm.pageurls.select.embedding": "جاسازی کنید",
  "ccm.pageurls.select.independently": "به طور مستقل باز شد",
  "ccm.pageurls.select.urlParameter": "پارامتر URL GET REQUEST ",
  "ccm.pageurls.select.webApi": "Web API",
  "ccm.pageurls.select.postParameter": "پارامتر URL POST REQUEST ",
  "ccm.agent.pageurl.httptip": "(امن نیست)",
  "ccm.agent.protocol.check": "توجه داشته باشید که پروتکل شبکه استفاده شده توسط آدرس url یک پروتکل امن نیست. اگر تصمیم به استفاده از این پروتکل دارید، ممکن است خطرات امنیتی وجود داشته باشد.",
  "ccm.agent.url.params.tips": "توجه داشته باشید که نحوه ساخت پارامتر URL GET REQUEST، پارامترهای شما را در معرض یک آدرس باز قرار می دهد.",
  "ccm.agent.contact.accountname": "نام کاربری",
  "ccm.agent.contact.caller": "شماره تماس گیرنده",
  "ccm.agent.contact.called": "شماره تماس گرفته شده ",
  "ccm.agent.contact.acceptno": "شماره ی بررسی شده",
  "ccm.agent.contact.customername": "نام مشتری",
  "CCM.AGENT.CONTACT.ORG": "سازمان مأمور",
  "ccm.agent.contact.allcalltype": "همه انواع تماس",
  "ccm.agent.contact.callserno": "S/N تماس",
  "ccm.agent.contact.minCallDuration": "مدت زمان مکالمه آغاز می شود",
  "ccm.agent.contact.maxCallDuration": "پایان مدت تماس", 
  "ccm.agent.contact.lessThan": "کمتر از",
  "ccm.agent.contact.callDurationError":"زمان آغاز مدت تماس کوتاه تر از زمان پایان تماس است",
  "ccm.agent.contact.talkDurationError":"مدت تماس ها نمي تونه بيشتر از 31 روز طول بکشه",
  "ccm.agent.contact.fuzzyCallNoError":"محدوده ی شماره ی تماس (گردش فاز) نمی تواند بیش از ۳۱ روز باشد.",
  "ccm.agent.contact.fuzzyCallNo": "شماره تماس) پرس وجوی مبهم (",
  "ccm.agent.contact.workno": "شناسه کارمند",
  "ccm.agent.contact.begintime": "زمان شروع",
  "ccm.agent.contact.endtime": "زمان پایان",
  "ccm.agent.contact.calltime": "مدت زمان تماس",
  "ccm.agent.contact.recordtime": "مدت زمان ضبط",
  "ccm.agent.contact.hungup": "قطع کننده تماس",
  "ccm.agent.contact.oircall": "شماره تماس اصلی",
  "ccm.agent.contact.contactno": "شماره تماس",
  "ccm.agent.contact.callid": "شناسه تماس",
  "ccm.agent.contact.assistantCallid": "شناسه تماس مشترک",
  "ccm.agent.contact.calltype": "نوع تماس",
  "ccm.agent.contact.recordingfiles": "فایل ضبط",
  "ccm.agent.contact.userhungup": "کاربر",
  "ccm.agent.contact.agenthungup": "نماینده",
  "ccm.agent.contact.robothungup": "ربات",
  "ccm.agent.contact.otherhungup": "دیگران",
  "ccm.agent.contact.skillhungup": "انتقال به صف مهارت",
  "ccm.agent.contact.businesshungup": "انتقال به نماینده",
  "ccm.agent.contact.numhungup": "انتقال به شماره خارجی",
  "ccm.agent.contact.ivrhungup": "انتقال به تلفن گویا",
  "ccm.agent.contact.callin": "تماس ورودی",
  "ccm.agent.contact.callout": "تماس خروجی",
  "ccm.agent.contact.callinandout": "دوبار تماس بگیرید",
  "ccm.agent.contact.predictiveCall": "تماس خروجی پیش بینی شده",
  "ccm.agent.contact.preCallout": "پیش نمایش تماس خروجی",
  "ccm.agent.contact.assistant": "تماس مشارکتی",
  "ccm.agent.contact.clicktocall": "برای شماره گیری کلیک کنید",
  "ccm.agent.contact.multi-media": "چند رسانه ای",
  "ccm.agent.contact.audio-visual": "صوتی/تصویری",
  "ccm.agent.contact.web": "Web",
  "ccm.agent.contact.weChat": "WeChat",
  "ccm.agent.contact.faceBook": "Facebook",
  "ccm.agent.contact.twitter": "X (Twitter)",
  "ccm.agent.contact.fiveg": "5G RCS",
  "ccm.agent.contact.email": "ایمیل",
  "ccm.agent.contact.Email": "Email",
  "ccm.agent.contact.voiceBot": "صدا",
  "ccm.agent.contact.line": "LINE",
  "ccm.agent.contact.whatsapp": "WhatsApp",
  "ccm.agent.contact.sms": "SMS",
  "ccm.agent.contact.Instagram": "Instagram",
  "ccm.agent.contact.Telegram": "Telegram",
  "ccm.agent.contact.others-channels": "کانال های دیگر",
  "ccm.agent.contact.satiscomment": "ارزیابی رضایت",
  "ccm.ivr.button.traceInfo": "برای مشاهده گزارش ردیابی فعلی کلیک کنید.",
  "ccm.agent.title.IVRTrace": "ردیابی تلفن گویا",
  "ccm.agent.contact.noAuth": "بدون مجوز.",
  "ccm.agent.contact.authHandle": "با پرسنل مدیریت مرتبط تماس بگیرید.",
  "ccm.agent.contact.Voice": "صدا",
  "ccm.agent.contact.vidio": "ویدئو",
  "ccm.agent.contact.mediaType": "نوع رسانه",
  "ccm.agent.contact.reset": "بازنشانی کنید",
  "ccm.agent.contact.resetAll": "همه را بازنشانی کنید",
  "ccm.agent.contact.refreshAll": "بازخوانی",
  "ccm.agent.contact.play": "بازی",
  "ccm.agent.contact.playcomfirm": "پخش را تایید کنید",
  "ccm.agent.contact.playdefault": "مدت زمان ضبط خالی است. آیا می خواهید از مدت زمان پیش فرض 60 دقیقه استفاده کنید؟",
  "ccm.agent.contact.download": "دانلود",
  "ccm.agent.contact.downloadrecord": "دانلود ضبط",
  "ccm.agent.contact.downloadvideo": "دانلود ویدیو",
  "ccm.agent.contact.playLeaveMessage": "پیام زیر پخش می شود:",
  "ccm.agent.contact.playRecording": "در حال پخش یک ضبط",
  "ccm.agent.contact.playVideo": "در حال پخش یک ویدیو",
  "ccm.agent.contact.norecording": "بدون ضبط",
  "ccm.agent.contact.customizes": "سفارشی سازی",
  "ccm.agent.contact.downloadFail": "دانلود نشد.",
  "ccm.agent.contact.QUERU_RECORDINFO_FAIL_CODE": "اطلاعات ضبط خالی است.",
  "ccm.agent.contact.QUERU_SFTP_FAIL_CODE": "اطلاعات SFTP خالی است.",
  "ccm.agent.contact.QUERU_VCCINFO_FAIL_CODE": "اطلاعات مرکز تماس مجازی خالی است.",
  "ccm.agent.contact.SFTP_DOWNLOAD_FAIL_CODE": "دانلود فایل ضبط شده از سرور SFTP انجام نشد.",
  "ccm.agent.contact.CONNECT_FAIL_CODE": "اتصال به سرور SFTP ناموفق بود.",
  "ccm.agent.contact.QUERY_SERVER_FAIL_CODE": "فراخوانی سرویس جستجو انجام نشد.",
  "ccm.agent.contact.SFTP_INCORRECT_FILE_CODE": "اطلاعات سرور sftp نادرست است",
  "ccm.agent.contact.resourceloading": "در حال بارگیری منابع...",
  "ccm.agent.contact.playfail": "پخش نشد.",
  "ccm.agent.contact.resourcefail": "هنگام بارگیری ضبط خطایی رخ می دهد.",
  "ccm.agent.contact.download.frequently": "شما هم سعی کردید بارها آن را دانلود کنید. لطفاً بعداً دوباره امتحان کنید.",
  "ccm.agent.contact.contactdetail": "اطلاعات تماس",
  "ccm.agent.contact.talkReason": "دلیل تماس ",
  "ccm.agent.contact.sameContactRecord": "سابقه تماس همان تماس",
  "ccm.agent.contact.transferRecord": "تاریخچه انتقال",
  "ccm.agent.contact.talkRemark": "اظهارات تماس",
  "ccm.agent.contact.voiceVidio": "صوتی/تصویری",
  "ccm.agent.contact.multimedia": "چند رسانه ای",
  "ccm.agent.contact.clicktodial": "برای شماره گیری کلیک کنید",
  "ccm.agent.contact.audioclicktodial": "کلیک صوتی برای شماره گیری ",
  "ccm.agent.contact.videoclicktodial": "کلیک ویدیویی برای شماره گیری ",
  "ccm.agent.contact.callerPresent": "شماره نمایش تماس کاربر",
  "ccm.agent.contact.agentPresent": "شماره نمایش تماس نماینده",
  "ccm.agent.contact.reasonCode": "کد دلیل",
  "ccm.agent.contact.relCallBackFlag": "پرچم تماس برگشتی را آزاد کنید",
  "ccm.agent.contact.talkCallbackFlag": "پرچم پاسخ به تماس برگشتی",
  "ccm.agent.contact.nohaveevent": "چنین رویدادی وجود ندارد",
  "ccm.agent.contact.calledhangup": "پایان تماس\، قطع تماس گرفته شده",
  "ccm.agent.contact.callershutdown": "پایان تماس\، خاموش شدن تماس گیرنده",
  "ccm.agent.contact.callerfailed": "تماس گیرنده خروجی ناموفق بود",
  "ccm.agent.contact.calledfailed": "تماس خروجی ناموفق بود",
  "ccm.agent.contact.callernook": "تماس گیرنده خروجی پاسخی نداد",
  "ccm.agent.contact.callednook": "تماس گرفته شده خروجی پاسخی نداد",
  "ccm.agent.contact.startcallfailed": "شروع تماس ناموفق بود",
  "ccm.agent.contact.otherexception": "استثنای دیگر",
  "ccm.agent.contact.recordInfo": "ضبط جزئیات فایل",
  "ccm.agent.contact.gateway.fail": "درخواست استثنای دروازه. لطفاً پیکربندی دروازه را بررسی کنید.",
  "ccm.agent.contact.gateway.exception": "استثنای داخلی دروازه. لطفاً پیکربندی دروازه را بررسی کنید.",
  "ccm.agent.operation.noMoreThan1Days": "محدوده زمانی نمی تواند بیش از 1 روز باشد.",
  "ccm.agent.operation.noMoreThan3Days": "محدوده زمانی نمی تواند بیش از 3 روز باشد.",
  "ccm.agent.operation.noMoreThan7Days": "محدوده زمانی نمی تواند بیش از 7 روز باشد.",
  "ccm.agent.operation.noMoreThan30Days": "محدوده زمانی نمی تواند بیش از 30 روز باشد.",
  "ccm.agent.operation.noMoreThan31Days": "محدوده زمانی نمی تواند از 31 روز تجاوز کند.",
  "ccm.agent.operation.noMoreThan90Days": "محدوده زمانی نمی تواند از 90 روز تجاوز کند.",
  "ccm.agent.label.thirtyonedays": "31 روز",
  "ccm.agent.label.ninetydays": "90 روز",
  "ccm.agent.operation.searchcountid": "حساب خدمات",
  "ccm.agent.operation.countid": "شمارۀ حساب",
  "ccm.agent.operation.searchworkno": "لطفاً ، شناسۀ کارمند را وارد کن",
  "ccm.agent.operation.optype": "نوع عمل",
  "ccm.agent.operation.optime": "زمان عمل",
  "ccm.agent.operation.opresult": "نتیجه",
  "ccm.agent.operation.ID": "شماره عمليات",
  "ccm.agent.operation.failCode": "کد دليل شکست",
  "ccm.agent.operation.vdnNotEnabled": "VDN فعال نیست.",
  "ccm.agent.operation.HOLD": "نگه داشتن تماس",
  "ccm.agent.operation.UNHOLD": "لغو نگه داشتن تماس",
  "ccm.agent.operation.MUTE": "بی صدا",
  "ccm.agent.operation.UNMUTE": "باصدا کردن",
  "ccm.agent.operation.RECORDPLAY": "پخش ضبط",
  "ccm.agent.operation.LISTEN": "بررسی کیفیت گوش دادن",
  "ccm.agent.operation.INSERT": "درج بررسی کیفیت",
  "ccm.agent.operation.CANCELLISTEN": "لغو گوش دادن",
  "ccm.agent.operation.CANCELINSERT": "لغو درج",
  "ccm.agent.operation.SWITCH": "تعویض",
  "ccm.agent.operation.INTERCEPT": "رهگیری",
  "ccm.agent.operation.FORCELOGOUT": "خروج اجباری",
  "ccm.agent.operation.FORCEIDEL": "بیکاری اجباری",
  "ccm.agent.operation.FORCEBUSY": "اشغال اجباری",
  "ccm.agent.operation.HELP": "مشورت کردن",
  "ccm.agent.operation.CALLINNER": "تماس داخلی",
  "ccm.agent.operation.TWOSTAGEDIALING": "شماره گیری دو مرحله ای",
  "ccm.agent.operation.WHISPER": "نجوا",
  "ccm.agent.operation.CANCELWHISPER": "زمزمه را متوقف کنید",
  "ccm.agent.operation.SAYBUSY": "مشغول",
  "ccm.agent.operation.SAYFREE": "بیکار",
  "ccm.agent.operation.REST": "درخواست استراحت",
  "ccm.agent.operation.CANCELREST": "لغو استراحت",
  "ccm.agent.operation.CONFERENCE": "کنفرانس سه جانبه",
  "ccm.agent.operation.AllOpType": "همۀ انواع عملیات",
  "ccm.agent.operation.CALLOUT": "CallOut",
  "ccm.agent.operation.ANSWER": "پاسخ دادن",
  "ccm.agent.operation.mediaswitch": "تعویض صوتی/تصویری",
  "ccm.agent.operation.RELEASE": "آزاد کردن",
  "ccm.agent.operation.ADJUSTSKILL": "صف مهارت را تنظیم کنید",
  "ccm.agent.operation.FORCEDROPCALL": "به زور آزاد کن",
  "ccm.agent.operation.ADJUSTVIDEOMODE ": "تنظیم اندازه پنجره ویدیو",
  "ccm.agent.login.logIP": "آدرس IP ورود به سیستم",
  "ccm.agent.login.ID": "توالی عملیات",
  "ccm.agent.login.LOGIN": "ورود",
  "ccm.agent.login.LOGOUT": "خروج از سیستم",
  "ccm.agent.login.FORCELOGOUT": "خروج اجباری",
  "ccm.agent.login.FORCELOGIN": "ورود اجباری",
  "ccm.agent.Transfer.CallForwarding": "انتقال تماس",
  "ccm.agent.Transfer.Intercept": "رهگیری",
  "ccm.agent.Transfer.tranfermode": "حالت انتقال",
  "ccm.agent.Transfer.devicetype": "نوع دستگاه مقصد",
  "ccm.agent.Transfer.deviceDesc": "دستگاه مقصد",
  "ccm.agent.Transfer.deviceAddr": "آدرس دستگاه مقصد",
  "ccm.agent.Transfer.theAgent": "مامور",
  "ccm.agent.restrecord.reststatus": "حالت استراحت",
  "ccm.agent.restrecord.reststatus.resting": "استراحت كردن",
  "ccm.agent.restrecord.reststatus.normal.endrest": "استراحت به صورت عادی تمام شد",
  "ccm.agent.restrecord.reststatus.timeout.endrest": "زمان استراحت تمام شد",
  "ccm.agent.restrecord.reststatus.timeout.Forcible.endrest": "استراحت به اجبار تمام شود",
  "ccm.agent.restrecord.reststatus.Abnormal.endrest": "استراحت به صورت غیرعادی به پایان رسید",
  "ccm.agent.restrecord.rest.beginTime": "زمان شروع استراحت",
  "ccm.agent.restrecord.rest.endTime": "زمان پایان استراحت",
  "ccm.agent.restrecord.rest.duration": "مدت زمان استراحت",
  "ccm.agent.restrecord.rest.currentstatus": "وضعیت فعلی",
  "ccm.agent.restrecord.rest.reason": "دلیل استراحت",
  "SM.ORGSTAFF.LABEL.INPUT_SEARCH_KEYWORD": "کلمه کلیدی",
  "casemanagement.common.label.nodata": "اطلاعاتی وجود ندارد.",
  "casemanagement.user.title.name": "نام کاربری",
  "casemanagement.user.title.select": "انتخاب کنید",
  "casemanagement.user.title.account": "حساب",
  "casemanagement.user.button.cancel": "لغو کنید",
  "casemanagement.user.button.ok": "خوب",
  "ccm.agent.verify.fail": "تأیید ناموفق بود.",
  "ccm.agent.password.wrong": "رمز عبور تأیید نشد.",
  "ccm.agent.password.agentNull": "اطلاعات نماینده خالی است.",
  "ccm.agent.tips.inputpassword": "رمز ورود حساب کاربری فعلی را وارد کنید.",
  "ccm.agent.title.agentpassword": "رمز عبور حساب کاربری",
  "ccm.agent.button.close": "بستن",
  "ccm.agent.title.agentinfo": "اطلاعات نماینده",
  "ccm.agent.title.querysippwd": "رمز عبور تلفن نرم افزاری را بررسی کنید",
  "ccm.agent.skill.nameInfo": "نام صف مهارت:",
  "ccm.agent.skill.skillExist": "درحال حاضر وجود دارد. نام دیگری وارد کنید.",
  "ccm.agent.ten.tenantInfor": "اطلاعات محیط کاری tenant",
  "ccm.agent.ten.tenantName": "نام محیط کاری tenant",
  "ccm.agent.ten.companyName": "شرکت",
  "ccm.agent.ten.createDate": "زمان ایجاد",
  "ccm.agent.ten.expiryDate": "تاریخ انقضا",
  "ccm.agent.ten.contactInfor": "روش تماس",
  "ccm.agent.ten.phonenumber": "شماره موبایل",
  "ccm.agent.ten.email": "ایمیل",
  "ccm.agent.ten.resourceinfor": "اطلاعات منابع",
  "ccm.agent.ten.maxAgents": "نمایندگان صوتی",
  "ccm.agent.ten.maxCalls": "حداکثر تماس های صوتی همزمان",
  "ccm.agent.ten.maxVideoAgents": "تعداد نماینده ویدیو",
  "ccm.agent.ten.maxVideoCalls": "حداکثر تماس های ویدیویی همزمان",
  "ccm.agent.ten.ivrchannels": "تعداد کانال تلفن گویا صوتی",
  "ccm.agent.ten.videoIvrchannels": "تعداد کانال تلفن گویا ویدیویی",
  "ccm.agent.ten.ttschannelnums": "مقدار TTS",
  "ccm.agent.ten.asrchannelnums": "مقدار ASR",
  "ccm.agent.ten.recordtime": "دوره نگهداری ضبط",
  "ccm.agent.ten.vdnid": "شناسه VDN",
  "ccm.agent.ten.tenantId": "شناسۀ فضایی مستاجر",
  "ccm.agent.ten.sipInfo": "آدرس SIP و پورت",
  "ccm.agent.ten.agentSipInfo": "آدرس IP و شماره پورت سرور ثبت نماینده",
  "ccm.agent.ten.anonymousSipInfo": "آدرس IP و شماره پورت سرور تماس ناشناس",
  "ccm.agent.calledroute.createcalledroute": "جدید",
  "ccm.agent.calledroute.errorsave": "خطا",
  "ccm.agent.calledroute.createfail": "داده های محلی اضافه نشد.",
  "ccm.agent.calledroute.createsuccess": "با موفقیت اضافه شد.",
  "ccm.agent.calledroute.updatecalledroute": "ویرایش کنید",
  "ccm.agent.calledroute.title.create": "ایجاد پیکربندی به نام حزب",
  "ccm.agent.calledroute.title.updatecalledroute": "ویرایش پیکربندی حزب فراخوانی شده",
  "ccm.agent.calledroute.confdelete": "تایید حذف",
  "ccm.agent.calledroute.isconfdelete": "آیا مطمئن هستید که می خواهید طرف انتخاب شده را حذف کنید؟",
  "ccm.agent.calledroute.success": "موفقیت",
  "ccm.agent.calledroute.delsuccess": "با موفقیت حذف شد.",
  "ccm.agent.calledroute.fail": "شکست",
  "ccm.agent.calledroute.exception": "وضعیت Redis یا پیکربندی CC-Gateway را بررسی کنید.",
  "ccm.agent.calledroute.delfail": "داده های محلی حذف نشد.",
  "ccm.agent.calledroute.editfail": "ویرایش داده های محلی انجام نشد.",
  "ccm.agent.calledroute.editsuccess": "با موفقیت ویرایش شد.",
  "ccm.agent.calledroute.create": "جدید",
  "ccm.agent.calledroute.accesscode": "کد دسترسی",
  "ccm.agent.calledroute.devicetype": "نوع دستگاه",
  "ccm.agent.calledroute.devicedesc": "نام دستگاه",
  "ccm.agent.calledroute.extcode": "کد پسوند",
  "ccm.agent.calledroute.delete": "حذف",
  "ccm.agent.calledroute.edit": "ویرایش کنید",
  "ccm.agent.calledroute.pagename": "نام صفحه",
  "ccm.agent.calledroute.operation": "عمل",
  "ccm.agent.calledroute.search.devicedesc": "لطفاً توضیحات دستگاه را وارد کنید.",
  "ccm.calledroute.title.selectaccesscode": "کد دسترسی را انتخاب کنید",
  "ccm.calledroute.tip.selectaccesscode": "لطفاً ، ابتدا یک کد دستیابی برگزینید.",
  "ccm.accesscode.placeholder.accessdesc": "لطفاً شرح کد دسترسی را وارد کنید.",
  "ccm.agent.calledroute.refresh": "بازخوانی",
  "ccm.agent.calledroute.refreshsuccess": "بازخوانی با موفقیت انجام شد.",
  "ccm.agent.calledroute.ivr": "لطفا تلفن گویا را انتخاب کنید",
  "ccm.agent.calledroute.skill": "صف مهارت",
  "ccm.calledroute.button.cancel": "لغو کنید",
  "ccm.calledroute.button.finish": "کامل",
  "ccm.agent.calledroute.placeholder.extcode": "لطفا یک کد داخلی وارد کنید.",
  "ccm.agent.calledroute.placeholder.accesscode": "لطفا یک کد دسترسی انتخاب کنید.",
  "ccm.agent.calledroute.placeholder.ivr": "لطفا یک تلفن گویا انتخاب کنید.",
  "ccm.agent.calledroute.placeholder.skill": "لطفاً یک صف مهارت انتخاب کنید.",
  "ccm.calledroute.title.selectivrflow": "تلفن گویا را انتخاب کنید",
  "ccm.calledroute.title.selectskill": "صف مهارت را انتخاب کنید",
  "ccm.calledroute.tip.selectivrexception": "جستجو از جریان های تلفن گویا در CTI انجام نشد.",
  "ccm.calledroute.message.selectcalledroute": "لطفاً پیکربندی طرف فراخوانده شده را انتخاب کنید.",
  "ccm.agent.calledroute.dnid": "شناسه پیکربندی",
  "ccm.agent.calledroute.create.accesscode.none": "کد دسترسی به سیستم پیکربندی شده برای طرف فراخوان شده تخصیص داده نشده است.",
  "ccm.agent.calledroute.create.accesscode.repeat": "ترکیب کد دسترسی به سیستم و کد داخلی برای طرف فراخوان شده باید منحصر به فرد باشد.",
  "ccm.agent.calledroute.create.accesscode.noexist": "کد دسترسی ارجاع شده توسط پیکربندی طرف فراخوانی شده وجود ندارد.",
  "ccm.agent.calledroute.create.skill.none": "صف مهارت ارجاع شده توسط پیکربندی طرف فراخوانی شده وجود ندارد.",
  "ccm.agent.calledroute.create.ivr.limit": "اگر نوع دستگاه، تلفن گویا باشد\، نوع رسانه کد دسترسی فقط می‌تواند «کلیک برای شماره‌گیری»، «درخواست پاسخ به تماس» یا تماس معمولی باشد.",
  "ccm.agent.calledroute.create.gateway.none": "اسکریپت مسیریابی ارجاع شده توسط پیکربندی طرف فراخوان شده وجود ندارد.",
  "ccm.agent.calledroute.create.media.match": "کد دسترسی به سیستم پیکربندی شده با نوع رسانه پیکربندی شده برای شخص فراخوان شده متفاوت است.",
  "ccm.agent.calledroute.gateway.fail": "همگام سازی اطلاعات پلت فرم CTI انجام نشد. لطفاً پیکربندی دروازه را بررسی کنید.",
  "ccm.agent.calledroute.delete.skill.default": "پیکربندی طرف فراخوان توسط صف مهارت خصوصی پیش‌فرض ارجاع داده می‌شود و نمی‌توان آن را حذف کرد.",
  "ccm.agent.calledroute.delete.skill.use": "پیکربندی طرف فراخوانده شده توسط یک صف مهارت ارجاع داده می شود و نمی توان آن را حذف کرد.",
  "ccm.agent.calledroute.delete.work.use": "پیکربندی طرف فراخوان توسط نماینده ارجاع داده می شود و نمی توان آن را حذف کرد.",
  "ccm.agent.calledroute.nmsfail": "همگام سازی با NMS انجام نشد.",
  "ccm.agent.calledroute.nmsdata.error": "همگام سازی داده ها با NMS انجام نشد. داده‌های پیکربندی طرف فراخوانده شده NMS با داده‌های محلی CCM ناسازگار است.",
  "ccm.agent.calledroute.ccivrdata.error": "همگام سازی داده ها با NMS انجام نشد. داده های فرآیند CC-IVR نادرست است.",
  "ccm.agent.calledroute.nmsconnect.fail": "اتصال NMS ناموفق بود.",
  "ccm.agent.accesscode.description.notempty": "شرح کد دسترسی نمی تواند خالی باشد.",
  "ccm.agent.accesscode.description.containspatial": "شرح کد دسترسی نمی تواند شامل کاراکترهای خاص % _ ^ & * < > $ / باشد",
  "ccm.accesscode.label.index": "شماره",
  "ccm.accesscode.label.accesscode": "کد دسترسی",
  "ccm.accesscode.label.accesscodedesc": "توضیحات کد دسترسی",
  "ccm.accesscode.title.accesscodedesc": "ویرایش توصیف کد دستیابی",
  "ccm.ivr.label.processid": "شناسه فرآیند",
  "ccm.ivr.label.processdesc": "شرح فرایند",
  "ccm.ivr.label.processaccesscode": "کد جریان",
  "ccm.ivr.label.processfilename": "نام فایل",
  "ccm.ivr.placeholder.ivrdesc": "لطفا شرح فرآیند را وارد کنید.",
  "ccm.skill.label.skillname": "نام صف مهارت",
  "ccm.skill.label.skilldesc": "شرح",
  "ccm.skill.label.maxwaittime": "حداکثر مدت زمان صف",
  "ccm.skill.label.maxwaitnum": "حداکثر تماس در صف",
  "ccm.skill.placeholder.skilldesc": "لطفاً یک نام صف مهارت وارد کنید.",
  "ccm.skill.message.skillpara": "نمی توان خود مهارت را پیکربندی کرد",
  "ccm.agent.calledroute.recordexist": "کد دسترسی به کد پسوند متصل است. لطفا یک کد پسوند جدید وارد کنید.",
  "ccm.agent.calledroute.require": "تمام موارد اجباری را وارد کنید.",
  "ccm.agent.calledroute.extcodewarn": "کد پسوند فقط می تواند شامل ارقام باشد.",
  "ccm.agent.calledroute.extlengthcodewarn": "طول کد پسوند نمی تواند بیشتر از 12 باشد",
  "ccm.agent.calledroute.accesscodewarn": "کد دسترسی فقط می تواند شامل ارقام باشد!",
  "ccm.agent.calledroute.device": "یک دستگاه را انتخاب کنید.",
  "ccm.agent.accesscode.index": "شماره",
  "ccm.agent.accesscode.accesscode": "کد دسترسی",
  "ccm.agent.accesscode.description": "توضیحات کد دسترسی",
  "ccm.agent.accesscode.n400number": "شماره سرویس",
  "ccm.agent.accesscode.description.require": "شرح کد دسترسی نمی تواند خالی باشد.",
  "ccm.agent.accesscode.description.limit": "شرح کد دسترسی نمی تواند بیش از 50 کاراکتر داشته باشد.",
  "ccm.agent.sysparamconfig.paramwarn": "لطفاً یک مقدار پارامتر معتبر انتخاب کنید.",
  "ccm.agent.sysparamconfig.resttimewarn": "مقدار باید یک عدد صحیح باشد.",
  "ccm.agent.sysparamconfig.worknowarn": "از کاما (\,) برای جدا کردن شناسه های نمایندگان استفاده کنید.",
  "ccm.agent.sysparamconfig.worknonumwarn": "تعداد شناسه های نمایندگان نمی تواند از 10 تجاوز کند.",
  "ccm.agent.sysparamconfig.hasSelectAgentWorkRepeat": "در قسمت انتخاب شده تکراری وجود دارد!",
  "ccm.agent.sysparamconfig.hasSelectAgentWorkLimit": "بیش از پنج صندلی مجاز نیست.",
  "ccm.agent.sysparamconfig.positiveinteger": "لطفا یک عدد صحیح مثبت وارد کنید!",
  "ccm.agent.sysparamconfig.resttimenumberwarn": "مدت زمان استراحت باید از 1 تا 1440 باشد (شامل 1440 نیست)",
  "ccm.agent.sysparamconfig.worknorangewarn": "شناسه نماینده باید از 101 تا 50000 باشد.",
  "ccm.agent.sysparamconfig.multimediawarn": "تعداد پیام های چندرسانه ای همزمان باید از 1 تا 60 باشد.",
  "ccm.agent.sysparamconfig.outboundtimecheck": "محدودیت زمانی برای پیش‌نمایش تماس خروجی خودکار باید از 1 ثانیه تا 3600 ثانیه باشد.",
  "ccm.agent.sysparamconfig.checkWebhookValidityDays": "گواهی Webhook باید بین 7 تا 365 روز معتبر باشد",
  "ccm.agent.sysparamconfig.verifyTemplateIdLength": "شناسه الگو پیام داخلی باید یک عدد صحیح باشد و نمی تواند یک عدد منفی باشد.",
  "ccm.agent.sysparamconfig.refreshSuccess": "با یک کلیک بازنشانی انجام شد.",
  "ccm.agent.sysparamconfig.resetAllParamsFail": "فایل پیکربندی پارامتر وجود ندارد. لطفا بررسی کنید.",
  "ccm.agent.sysparamconfig.refreshAllSysParamSuccess": "بازخوانی با موفقیت انجام شد.",
  "ccm.agent.sysparamconfig.refreshAllSysParamFail": "خطای سیستم\، بازخوانی انجام نشد.",
  "ccm.vdn.label.index": "ماه ها",
  "ccm.agent.message.sysparamservicerr": "خطای سرویس داخلی لطفا گزارش را بررسی کنید.",
  "ccm.agent.message.download.err": "خرابی در بارگیری. لطفاً ، با پرسنل عملیات سیستم تماس بگیرید.",
  "ccm.vdn.label.defaultvoice": "پیش فرض",
  "ccm.recognition.label.sucess": "موفقیت",
  "ccm.recognition.label.fail": "شکست",
  "ccm.recognition.label.ordersucess": "اشتراک با موفقیت انجام شد.",
  "ccm.recognition.label.orderfail": "اشتراک ناموفق بود.",
  "ccm.recognition.label.orderfailwhilte": "اشتراک ناموفق بود. همگام سازی لیست اعتماد انجام نشد.",
  "ccm.recognition.label.cancelordersucess": "اشتراک با موفقیت لغو شد.",
  "ccm.recognition.label.updatesucess": "به روز رسانی موفق fautl محیط کاری tenant.",
  "ccm.recognition.label.updatefail": "به روز رسانی fautl محیط کاری tenant انجام نشد.",
  "ccm.recognition.label.cancelorderfail": "لغو اشتراک انجام نشد.",
  "ccm.recognition.label.orderfailpd": "اشتراک ناموفق بود. بعدا دوباره تلاش کنید.",
  "ccm.recognition.label.cancelorderfailpd": "لغو اشتراک انجام نشد. بعدا دوباره تلاش کنید.",
  "ccm.recognition.label.characteristics": "ویژگی",
  "ccm.recognition.label.intellrecogn": "تشخیص هوشمند",
  "ccm.recognition.label.intellagent": "دستیار هوشمند نماینده",
  "ccm.recognition.label.processaccesscode": "یک کد دسترسی جریان وارد کنید.",
  "ccm.recognition.label.save": "صرفه جویی",
  "ccm.recognition.label.openIvrAgentSuccess": "دستیار هوشمند نماینده با موفقیت فعال شد.",
  "ccm.recognition.label.closeIvrAgentSuccess": "دستیار هوشمند نماینده با موفقیت غیرفعال شد.",
  "ccm.recognition.label.mobileagent": "نماینده سیار/تماس دوطرفه با یک کلیک",
  "ccm.recognition.label.modifymobileagentsuccess": "وضعیت نماینده سیار با موفقیت تغییر کرد!",
  "ccm.recognition.label.openmobileagentsuccess": "نماینده سیار با موفقیت فعال شد.!",
  "ccm.recognition.label.closemobileagentsuccess": "نماینده سیار با موفقیت غیرفعال شد.!",
  "ccm.recognition.label.modifymobileagentfailed": "تغییر وضعیت نماینده سیار انجام نشد.!",
  "ccm.recognition.label.modifymobileagentclose": "ویژگی های نماینده سیار و تماس دوطرفه با یک کلیک برای محیط کاری tenant فعال نیست!",
  "ccm.recognition.label.modifymobileagentflowidnull": "شناسه جریان، خالی است!",
  "ccm.recognition.label.openBreakDown": "صدای شکست",
  "ccm.recognition.label.breakDownVoice": "صدای شکست",
  "ccm.recognition.label.voiceNotification": "پرچم اعلان صوتی",
  "ccm.recognition.label.openVoiceNotificationSuccess": "ویژگی اعلان صوتی با موفقیت فعال شد.",
  "ccm.recognition.label.closeVoiceNotificationSuccess": "ویژگی اعلان صوتی با موفقیت غیرفعال شد.",
  "ccm.recognition.label.modifyVoiceNotificationClose": "ویژگی اعلان صوتی برای محیط کاری tenant فعال نیست.",
  "ccm.callreason.label.input": "لطفا وارد شوید",
  "ccm.callreason.label.callreason": "دلیل تماس ",
  "ccm.callreason.title.callreason": "به طور خودکار تماس",
  "ccm.callreason.label.parentcallreason": "تماس پدر",
  "ccm.callReason.title.createCallReason": "ایجاد دليل تماس",
  "ccm.callReason.title.editCallReason": "ویرایش دليل تماس",
  "ccm.callreason.label.selectedcallreason": "دلیل تماس انتخاب شده",
  "ccm.callreason.label.mark": "ملاحظات",
  "ccm.callreason.label.enable": "فعال کردن",
  "ccm.callreason.label.disable": "غیر فعال کردن",
  "ccm.callreason.label.setcallreason": "پیکربندی دلیل تماس",
  "ccm.callreason.label.name": "نام",
  "ccm.callreason.label.status": "وضعیت",
  "ccm.agent.label.deletecallreason": "دلیل فعلی حاوی دلایل زیر مجموعه است، نمی توان آن را حذف کرد",
  "ccm.agent.label.notselectcallreason": "لطفا دلیل تماس را انتخاب کنید",
  "ccm.agent.contact.talkcontent": "محتوای گفتگو",
  "ccm.callreason.input.specialStrValidateFailed": "نام دلیل تماس نمی تواند شامل کاراکترهای خاص باشد.",
  "ccm.callreason.label.allreason": "همه دلایل",
  "ccm.callreason.label.selectedreason": "دلایل انتخاب شده",
  "ccm.calledconfig.label.numbers": "شماره ها",
  "ccm.calledconfig.label.querysoftnum": "نمایش شمارۀ تلفنهای نرم",
  "ccm.calledconfig.label.add": "اضافه کردن",
  "ccm.calledconfig.label.selectcalled": "تماس گرفته شده را انتخاب کنید",
  "ccm.calledconfig.table.devtype": "نوع دستگاه هدف",
  "ccm.calledconfig.table.skillormumbers": "دستگاه هدف",
  "ccm.calledconfig.table.selectnumbers": "شماره ها را انتخاب کنید",
  "ccm.calledconfig.table.addnumbers": "اضافه کردن اعداد",
  "ccm.calledconfig.title.numbersadded": "اعداد اضافه شد",
  "ccm.calledconfig.message.deletemobileagent": "نماینده سیار را حذف کنید",
  "ccm.calledconfig.message.willdeletemobileagent": "آیا مطمئن هستید که می خواهید نماینده سیار انتخاب شده را حذف کنید؟",
  "ccm.calledconfig.message.deletemobileagentsuccess": "پیکربندی نماینده سیار با موفقیت حذف شد",
  "ccm.calledconfig.message.deletemobileagentfail": "ارتباط پیکربندی نماینده سیار حذف نشد",
  "ccm.calledconfig.message.savemobileagentsuccess": "پیکربندی نماینده سیار با موفقیت ذخیره شد",
  "ccm.calledconfig.message.savemobileagentfail": "پیوند پیکربندی نماینده سیار ذخیره نشد",
  "ccm.calledconfig.message.numbersinvalid": "بیش از 15 یا کمتر از 1 عدد",
  "ccm.calledconfig.message.nocalled": "لطفاً تماس گرفته شده را انتخاب کنید",
  "ccm.calledconfig.message.duplicatecalled": "تکراری تماس گرفت",
  "ccm.calledconfig.message.nonumbers": "لطفا اعداد را انتخاب کنید",
  "ccm.calledconfig.message.noskill": "لطفا صف مهارت را انتخاب کنید",
  "ccm.calledconfig.message.willdeleteassociation": "آیا مطمئن هستید که می خواهید اطلاعات مرتبط با دلیل تماس را حذف کنید",
  "ccm.calledconfig.message.callreasontips": "لطفاً توجه داشته باشید: TT مرتبط در سطح فعلی در دسترس نیست. یکی از دلایل تماس زیر مجموعه را برای تنظیم TT مرتبط انتخاب کنید.",
  "casemanagement.casedraft.label.casetype": "نوع پرونده ",
  "casemanagement.casedraft.label.selectcasetype": "نوع پرونده را انتخاب کنید",
  "ccm.callout.lable.index": "فهرست مطالب",
  "ccm.callout.lable.type": "نوع پارامتر",
  "ccm.callout.lable.number": "مقدار پارامتر",
  "ccm.callout.lable.default": "مقدار پیش فرض",
  "ccm.callout.lable.reset": "بازنشانی کنید",
  "ccm.callout.lable.caller": "تماس گیرنده تماس خروجی",
  "ccm.callout.placeholder.caller": "لطفا شماره تماس تماس خروجی را وارد کنید",
  "ccm.callout.title.fail": "شکست",
  "ccm.callout.title.success": "موفقیت",
  "ccm.callout.message.confreset": "تایید بازنشانی ",
  "ccm.callout.message.isconfreset": "آیا مطمئن هستید که می خواهید تماس گیرنده تماس را بازنشانی کنید؟",
  "ccm.callout.message.choosecaller": "لطفاً تماس گیرنده تماس خروجی را انتخاب کنید",
  "ccm.callout.message.nocallers": "هیچ تماس گیرنده ای انتخاب نشده است",
  "ccm.callout.message.editsuccess": "ویرایش تماس گیرنده تماس با موفقیت انجام شد",
  "ccm.callout.message.editfail": "تماس گیرنده تماس خروجی ویرایش نشد",
  "ccm.callout.message.resetsuccess": "در تنظیم مجدد پارامتر موفق شد.",
  "ccm.callout.message.resetfail": "تنظیم مجدد پارامترها انجام نشد.",
  "ccm.agentparam.message.editsuccess": "با موفقیت ویرایش شد.",
  "ccm.agentparam.message.editfail": "ویرایش انجام نشد.",
  "ccm.agent.mobileagent.notice": "اطلاع",
  "ccm.agent.mobileagent.confirm": "تایید",
  "ccm.agent.mobileagent.success": "موفق شد",
  "ccm.agent.mobileagent.fail": "ناموفق",
  "ccm.agent.mobileagent.label.nodata": "هیچ داده منطبقی پیدا نشد!",
  "ccm.agent.mobileagent.label.choosehint": "لطفاً نمایندگان را قبل از حذف آنها انتخاب کنید!",
  "ccm.agent.mobileagent.label.deletehint": "مطمئنی حذف می کنی؟",
  "ccm.agent.mobileagent.label.deletecomplete": "حذف نماینده کامل شد!",
  "ccm.agent.label.usedMode": "& نوع",
  "ccm.agent.label.usedMode.phone": "شماره تلفن",
  "ccm.agent.label.usedMode.sipAccount": "اکانت SIP",
  "ccm.agent.message.searchAgentServiceNo": "لطفا شماره خدمات نماینده را وارد کنید",
  "ccm.agent.message.nocomments": "بدون نظر",
  "ccm.agent.message.deletefailed": "حذف نماینده انجام نشد",
  "ccm.agent.message.createfailed": "ایجاد نماینده ناموفق بود",
  "ccm.urlconfig.label.talkurl": "URL اتصال تماس برگشتی",
  "ccm.urlconfig.label.talkmethod": "روش اتصال به تماس",
  "ccm.urlconfig.label.relurl": "قطع کردن URL پاسخ به تماس",
  "ccm.urlconfig.label.relmethod": "روش درخواست قام",
  "ccm.urlconfig.message.addsuccess": "با موفقیت در افزودن URL ها",
  "ccm.urlconfig.message.addfail": "URL ها اضافه نشد",
  "ccm.urlconfig.message.modifysuccess": "تغییر URL ها با موفقیت انجام شد",
  "ccm.urlconfig.message.modifyfail": "URL ها اصلاح نشدند",
  "ccm.urlconfig.message.deletesuccess": "بازنشانی URL ها با موفقیت انجام شد",
  "ccm.urlconfig.message.deletefail": "بازنشانی URL ها انجام نشد",
  "ccm.urlconfig.pageurls.urlvalidate": "آدرس ها باید با http:// یا https:// شروع شوند",
  "ccm.agent.message.validatormsg": "ورودی های مربوط به رمز عبور باید خالی باشند یا اصلاً خالی نباشند",
  "ccm.agent.message.validatordigit": "رمز عبور راه اندازی نماینده تک تلفن فقط باید عددی باشد",
  "ccm.agent.agentcontact.error": "جستجو انجام نشد",
  "ccm.agent.agentcontact.nosearch": "در مدیریت غیر تجاری نمی توان جستجو کرد.",
  "ccm.urlconfig.message.httpurl.safetip": "(امن نیست)",
  "ccm.callback.url.callback": "پیکربندی تماس دوطرفه",
  "ccm.callback.url.callnotify": "پیکربندی تماس اعلان صوتی",
  "ccm.urlconfig.label.oauth.label": "اطلاعات مجوز OAuth 2.0",
  "ccm.agent.message.samepassword": "رمز عبور جدید نمی تواند با رمز عبور فعلی یکسان باشد",
  "ccm.urlconfig.label.appsecret": "کلید مشترک",
  "ccm.urlconfig.label.key": "کلید سری",
  "ccm.urlconfig.modify.authtype1": "کلید مشترک",
  "ccm.urlconfig.modify.authtype2": "OAuth 2.0",
  "ccm.urlconfig.modify.authtype3": "هیچ کدام",
  "ccm.urlconfig.label.oauth.ak": "AK",
  "ccm.urlconfig.label.oauth.sk": "SK",
  "ccm.urlconfig.label.oauth.oauth2loginurl": "نشانی وب ورود احراز هویت نسخه ۲. ۰",
  "ccm.callback.url.auth.tip": "حالت احراز هویت URL تماس برگشتی",
  "ccm.urlconfig.modify.callnotify.ak.tooshort": "کلید باید حداقل 16 کاراکتر داشته باشد.",
  "ccm.urlconfig.modify.oauth.ak.tooshort": "AK باید حداقل 16 کاراکتر داشته باشد.",
  "ccm.urlconfig.modify.oauth.sk.tooshort": "SK باید حداقل 16 کاراکتر داشته باشد.",
  "ccm.urlconfig.modify.authbothnull": "اطلاعات مجوز نمی تواند خالی باشد.",
  "ccm.urlconfig.label.oauth.oauth2loginurl.illegal": "URL ورود نامعتبر است.",
  "ccm.urlconfig.label.relurl.voicemsg.alerttip": "اگر هنگام فراخوانی API ایجاد اعلان صوتی، URL تماس برگشتی منتقل نشود، پس از تکمیل اعلان صوتی، این URL دوباره فراخوانی می شود. در غیر این صورت، URL منتقل شده فراخوانی می شود.",
  "ccm.urlconfig.label.oauth.voicemsg.alerttip": "اگر هنگام فراخوانی API ایجاد اعلان صوتی و پیکربندی اطلاعات احراز هویت، URL تماس برگشتی منتقل شود، پس از تکمیل اعلان صوتی، از این اطلاعات در هنگام پاسخ به تماس URL استفاده می‌شود. در غیر این صورت، هیچ گونه احراز هویتی در طول تماس مجدد انجام نمی شود.",
  "ccm.urlconfig.label.relurl.callback.alerttip": "اگر هنگام فراخوانی API ایجاد تماس دوطرفه، URL تماس برگشتی انتقال داده نشود، پس از انتشار تماس دوطرفه، این URL دوباره فراخوانی می شود. در غیر این صورت، URL منتقل شده فراخوانی می شود.",
  "ccm.urlconfig.label.oauth.callback.alerttip": "اگر هنگام فراخوانی API ایجاد تماس دوطرفه و پیکربندی اطلاعات احراز هویت زیر، URL تماس برگشتی منتقل شود، این اطلاعات در هنگام انتشار تماس دوطرفه در هنگام پاسخ به تماس URL استفاده می شود. در غیر این صورت، هیچ گونه احراز هویتی در طول تماس مجدد انجام نمی شود.",
  "ccm.urlconfig.label.noramalOrITA": "نوع تماس دو طرفه CEC یا تماس دو طرفه ITA را انتخاب کنید.",
  "ccm.urlconfig.label.radio.radioType1": "تماس دو طرفه CEC",
  "ccm.urlconfig.label.radio.radioType2": "تماس دو طرفه ITA",
  "ccm.urlconfig.message.warning": "مطمئن شوید که URL فشار CDR روی ITA پیکربندی شده است. در غیر این صورت، ITA نمی تواند CDR ها را به CEC فشار دهد. نمونه فشار URL به شرح زیر است:",
  "ccm.urlconfig.message.tip.warning": "اطمینان حاصل کنید که خدمات مربوط به ITA را خریداری کرده اید. در غیر این صورت، تماس ها تحت تأثیر قرار خواهند گرفت.",
  "ccm.urlconfig.message.oauth.tip.warning": "عدم احراز هویت URL تماس برگشتی ممکن است خطرات امنیتی ایجاد کند. آیا مطمئن هستید که می خواهید از این حالت احراز هویت استفاده کنید؟",
  "ccm.urlconfig.label.noramalOrITA.input": "پیکربندی پارامتر ITA",
  "ccm.urlconfig.label.input.appId": "AccessKey",
  "ccm.urlconfig.label.input.appSecret": "SecretKey",
  "ccm.urlconfig.message.missing.appIdOrAppSecret": "کلید دسترسی یا کلید مخفی در پیکربندی تماس دوطرفه ITA تنظیم نشده است.",
  "ccm.urlconfig.message.overlimit.appId": "کلید دسترسی پیکربندی شده برای تماس های دوطرفه ITA نمی تواند بیش از 128 کاراکتر باشد.",
  "ccm.urlconfig.message.overlimit.appSecret": "کلید مخفی پیکربندی شده برای تماس های دوطرفه ITA نمی تواند بیش از 512 کاراکتر باشد.",
  "ccm.urlconfig.label.numberAllocationMode1": "خودکار",
  "ccm.urlconfig.label.numberAllocationMode2": "مشخص شده",
  "ccm.urlconfig.label.input.appKey": "AppKey",
  "ccm.urlconfig.label.input.domainName": "نام دامنه ی ایTA",
  "ccm.urlconfig.label.xNumber": "شمارۀ X",
  "ccm.urlconfig.message.missing.appKeyOrDomainName": "کلید برنامه و نام دامنه در پیکربندی تماس دوطرفه ITA باید تنظیم شود.",
  "ccm.urlconfig.message.overlimit.appKey": "کلید برنامه در پیکربندی تماس دوطرفه ITA نمی تواند از 128 کاراکتر تجاوز کند.",
  "ccm.urlconfig.message.overlimit.domainName": "نام دامنه در پیکربندی تماس دو طرفه ITA نمی تواند از 128 کاراکتر تجاوز کند.",
  "ccm.urlconfig.message.overlimit.xNumber": "شماره X پیکربندی شده برای تماس های دوطرفه ITA نمی تواند از 20 کاراکتر تجاوز کند.",
  "ccm.urlconfig.message.notNumber.xNumber": "شماره X پیکربندی شده برای تماس های دوطرفه ITA عدد نیست.",
  "ccm.satisfactionconfig.web.switch.remark": "پس از روشن شدن این سوئیچ، نظرسنجی رضایت مشتری پس از تکمیل سرویس به مشتری ارسال می شود.",
  "ccm.satisfactionconfig.param.faile": "پارامتر نادرست",
  "ccm.satisfactionconfig.select.ivr.process": "لطفاً جریان تلفن گویا نظرسنجی رضایت را انتخاب کنید.",
  "ccm.satisfactionconfig.web.satisfaction.survey": "نظرسنجی رضایتمندی از کانال وب",
  "ccm.satisfactionconfig.sms.satisfaction.survey": "نظرسنجی رضایتمندی پیامکی",
  "ccm.satisfactionconfig.ivr.voice.satisfaction.survey": "نظرسنجی رضایتمندی صوتی تلفن گویا",
  "ccm.satisfactionconfig.audio.and.video.remark": "هنگامی که سوئیچ نظرسنجی رضایتمندی صوتی تلفن گویا و پیامکی روشن است و نماینده ابتدا تلفن را قطع می کند\، اگر نظرسنجی رضایتمندی صدای تلفن گویا انجام شده باشد، هیچ نظرسنجی رضایتمندی پیامکی انجام نخواهد شد.",
  "ccm.satisfactionconfig.audio.and.video": "نظرسنجی رضایتمندی از کانال صوتی یا تصویری",
  "ccm.satisfactionconfig.satisfaction.sending.channel": "کانال ارسال پیامک",
  "ccm.satisfactionconfig.sms.switch.remark": "پس از روشن شدن این سوئیچ\ پس از پایان تماس، پیامک رضایتمندی برای مشتری ارسال می شود",
  "ccm.satisfactionconfig.satisfaction.process": "جریان بررسی رضایتمندی",
  "ccm.satisfactionconfig.satisfaction.switch": "تبديل",
  "ccm.satisfactionconfig.message.audio": "نظرسنجی رضایتمندی",
  "ccm.satisfactionconfig.message.media": "کانال های چند رسانه ای",
  "ccm.satisfactionconfig.message.satisreport": "گزارش نظرسنجی رضایتمندی",
  "ccm.satisfactionconfig.message.satis": "نظرسنجی رضایتمندی",
  "ccm.satisfactionconfig.message.staistip1": "پس از روشن شدن این سوئیچ\، مشتری پس از قطع تماس توسط نماینده به نظرسنجی رضایتمندی منتقل می شود.",
  "ccm.satisfactionconfig.message.surveymethod": "روش بررسی",
  "ccm.satisfactionconfig.message.surveyconfig": "پیکربندی نظرسنجی",
  "ccm.satisfactionconfig.message.flowname": "نام فرآیند",
  "ccm.satisfactionconfig.message.save": "ذخیره",
  "ccm.satisfactionconfig.message.success": "موفق",
  "ccm.satisfactionconfig.message.failed": "ناموفق",
  "ccm.satisfactionconfig.message.createsuccess": "پیکربندی نظرسنجی رضایتمندی با موفقیت اضافه شد",
  "ccm.satisfactionconfig.message.createfailed": "پیکربندی جدید نظرسنجی رضایتمندی ناموفق بود",
  "ccm.satisfactionconfig.message.updatesuccess": "پیکربندی نظرسنجی رضایت با موفقیت به‌روزرسانی شد",
  "ccm.satisfactionconfig.message.updatefailed": "به‌روزرسانی پیکربندی نظرسنجی رضایتمندی ناموفق بود",
  "ccm.satisfactionconfig.message.enterflowname": "لطفا نام فرآیند را وارد کنید",
  "ccm.satisfactionconfig.message.number": "عدد",
  "ccm.satisfactionconfig.message.flowaccesscode": "کد دسترسی به فرآیند",
  "ccm.satisfactionconfig.message.version": "شماره نسخه",
  "ccm.satisfactionconfig.message.confirm": "تایید",
  "ccm.satisfactionconfig.message.cancel": "لغو ",
  "ccm.satisfactionconfig.message.sms": "پیامک",
  "ccm.satisfactionconfig.message.chooseivrflow": "جریان تلفن گویا را انتخاب کنید",
  "ccm.satisfactionconfig.message.failtogetivrflow": "لطفا نظرسنجی رضایتمندی را روشن کنید",
  "ccm.agent.leavemessage.serviceContactNumber": "شماره تماس",
  "ccm.agent.leavemessage.allStatus": "وضعیت همه پیام ها",
  "ccm.agent.leavemessage.status.toBeAssigned": "اختصاص یابد",
  "ccm.agent.leavemessage.status.unprocessed": "پردازش نشده",
  "ccm.agent.leavemessage.status.inProcess": "در حال پردازش",
  "ccm.agent.leavemessage.status.solved": "فرآوری شده",
  "ccm.agent.leavemessage.status.allocationFailed": "تخصیص ناموفق بود",
  "ccm.agent.leavemessage.contactNumber": "شماره تماس",
  "ccm.agent.leavemessage.workNo": "نماینده",
  "ccm.agent.leavemessage.channel": "کانال",
  "ccm.agent.leavemessage.status": "وضعیت",
  "ccm.agent.leavemessage.auditAccount": "حساب حسابرسی",
  "ccm.agent.leavemessage.auditcomments": "نظرات حسابرسی",
  "ccm.agent.leavemessage.auditdate": "زمان به روز رسانی",
  "ccm.agent.leavemessage.assign": "اختصاص دهید",
  "ccm.agent.leavemessage.title.assign": "انتساب پیامها",
  "ccm.agent.leavemessage.process": "روند",
  "ccm.agent.leavemessage.messageprocess": "پردازش پیام",
  "ccm.agent.leavemessage.inputauditcomments": "پیشنهادات را وارد کنید",
  "ccm.agent.leavemessage.processsuccess": "پیام با موفقیت پردازش شد.",
  "ccm.agent.leavemessage.processfail": "پیام پردازش نشد.",
  "ccm.agent.leavemessage.lengthcodewarn": "یک پیام می تواند حداکثر 300 کاراکتر داشته باشد.",
  "ccm.agent.leavemessage.downloadLeaveMessage": "دانلود پیام",
  "ccm.agent.leavemessage.assignSuccess": "پیام با موفقیت تخصیص یافت",
  "ccm.agent.leavemessage.assignFailed": "تخصیص پیام ناموفق بود.",
  "ccm.agent.leavemessage.paramisempty": "پارامتر خالی.",
  "ccm.agent.leavemessage.QUERU_LEAVEMESSAGE_FAIL_CODE": "اطلاعات SFTP را برای درخواست پیام ها خالی کنید.",
  "ccm.agent.leavemessage.statusFailed": "تغییر وضعیت ضبط پیام انجام نشد.",
  "ccm.agent.leavemessage.cannotBeModified": "نمی توان پیام را در وضعیت فعلی تغییر داد.",
  "ccm.agent.leavemessage.notLoginIn": "نماینده وارد نشده است.",
  "ccm.agent.leavemessage.notAgent": "حساب به یک نماینده محدود نمی شود.",
  "ccm.agent.leavemessage.view": "مشاهده پیام ها",
  "ccm.agent.leavemessage.channelType": "نوع کانال",
  "ccm.agent.label.positiveEmotionNumber": "مثبت",
  "ccm.agent.label.negativeEmotionNumber": "منفی",
  "ccm.agent.label.sensitiveWordNumber": "حرف های ممنوع",
  "ccm.agent.label.positiveEmotion": "آفرین. ادامه بده.",
  "ccm.agent.label.negativeEmotion": "جمله بندی نادرست از یک اسکریپت مناسب استفاده کنید.",
  "ccm.agent.label.sensitiveWord": "کلمات ممنوعه شناسایی می شوند. به جمله بندی خود توجه کنید",
  "ccm.agent.videoDownload.disabled": "عملکرد دانلود ویدیو غیرفعال است",
  "ccm.agent.videoDownload.noPermission": "نماینده اجازه دانلود ندارد",
  "ccm.agent.videoDownload.RSETimeout": "زمان درخواست سرویس RSE تمام شد",
  "ccm.agent.log.downloadVideoTerminal": "ترمینال ویدیویی OpenEye را دانلود کنید",
  "ccm.page.label.status": "وضعیت صفحه",
  "ccm.agent.page.release": "آزاد کردن",
  "ccm.agent.page.cancel.release": "آزاد نشده",
  "ccm.label.pageName": "نام صفحه",
  "ccm.page.label.pageInput": "نام صفحه را وارد کنید",
  "ccm.label.page.createPage": "صفحۀ جدید",
  "ccm.label.page.modifyPage": "صفحه را اصلاح کنید",
  "ccm.page.select.blockLayout": "طرح بندی بلوک",
  "ccm.page.select.tileLayout": "طرح شبکه",
  "ccm.agent.message.pageConfSuccess": "عملیات با موفقیت انجام شد",
  "ccm.agent.message.pageConfFail": "عملیات شکست خورد",
  "ccm.agent.message.inSpecialStrFail": "نام صفحه نمی تواند شامل کاراکترهای خاص باشد.",
  "ccm.agent.message.samePageNameFail": "نام صفحه تکراری",
  "ccm.agent.message.numInRowFail": "مقدار باید یک عدد صحیح از 2 تا 4 باشد.",
  "ccm.agent.message.pageNumFail": "حداکثر {0} صفحه مجاز است.",
  "ccm.agent.message.pageLayoutFail": "طرح صفحه یا تعداد ردیف ها انتخاب نشده است.",
  "ccm.agent.message.quotingDataOperationFail": "داده ها توسط صفحه پاپ آپ ارجاع داده می شوند. عملیات شکست خورد.",
  "ccm.agent.message.dataIntegrityFail": "داده های ناقص برای طرح بندی صفحه یا مجموعه رابط پیکربندی شده است. عملیات شکست خورد.",
  "ccm.label.page.delete.confirm": "آیا مطمئن هستید که می خواهید آن را حذف کنید؟",
  "ccm.pageconfig.label.layoutType": "نوع چیدمان",
  "ccm.pageconfig.label.numInRow": "تعداد ردیف ها",
  "ccm.agent.message.pageLayout": "صفحه آرایی",
  "ccm.agent.message.pageInterface": "رابط صفحه",
  "ccm.agent.label.numInRow": "یک عدد صحیح از 2 تا 4 وارد کنید.",
  "ccm.agent.message.noReleaseFail": "فقط داده های منتشر نشده را می توان حذف کرد.",
  "ccm.agent.message.pageNameValidateFail": "کاراکترهای خاص مجاز نیستند",
  "ccm.agent.message.itemNameValidateFail": "نویسه های ویژۀ زیر مجاز نیستند &<>\";%/[]=+{'@'}",
  "ccm.agent.page.pageParameter": "پارامترهای صفحه",
  "ccm.agent.label.parameter": "پارامتر 1",
  "ccm.agent.input.userNumber": "شماره کاربر",
  "ccm.agent.button.restSoftPhonePwd": "بازنشانی رمز عبور تلفن نرم افزاری",
  "ccm.agent.button.restSoftPhonePwdError": "نماینده را ببندید.",
  "ccm.agent.button.restSoftPhonePwd.tips": "بازنشانی گذرواژه تلفن نرم افزاری بر تلفن نرم افزاری در حال استفاده تأثیر می گذارد و نماینده را ملزم به ورود مجدد به سیستم می کند. آیا مطمئن هستید که می خواهید رمز عبور تلفن نرم افزاری را بازنشانی کنید؟",
  "ccm.agent.button.unregister": "لغو ثبت نام",
  "ccm.agent.button.restores": "بازیابی می کند",
  "ccm.agent.ccagentinfo.unregister.success": "لغو ثبت نام با موفقیت انجام شد",
  "ccm.agent.ccagentinfo.unregister.fail": "لغو ثبت نام انجام نشد",
  "ccm.agent.ccagent.modify.fail": "تشخیص هوشمند و ضبط دو آهنگ را نمی توان همزمان فعال کرد.",
  "ccm.agent.ccagent.qcmodify.fail": "نوع نماینده صوتی را انتخاب کنید.",
  "ccm.agent.softPhoneStatus": "وضعیت شماره تلفن نرم افزاری",
  "ccm.agent.softPhoneStatusNode1": "وضعیت گره 1 شماره تلفن نرم افزاری",
  "ccm.agent.softPhoneStatusNode2": "وضعیت گره 2 شماره تلفن نرم افزاری",
  "ccm.agent.softPhoneStatus.Registered": "ثبت شده است",
  "ccm.agent.softPhoneStatus.UnRegistered": "ثبت نشده است",
  "ccm.custdata.title.create": "در حال ایجاد یک جدول پایه",
  "ccm.custdata.title.modify": "ویرایش جدول پایه",
  "ccm.custdata.button.detail": "جزئیات",
  "ccm.custdata.button.release": "آزاد کردن",
  "ccm.custdata.button.withdraw": "عقب کشیدن",
  "ccm.custdata.button.abandon": "نادیده گرفتن",
  "ccm.custdata.button.return": "برگشت",
  "ccm.custdata.button.moveTop": "بالا",
  "ccm.custdata.button.moveUp": "بالا",
  "ccm.custdata.button.moveDown": "پایین",
  "ccm.custdata.button.moveBottom": "پایین",
  "ccm.custdata.button.exportTemplate": "صدور الگو",
  "ccm.custdata.button.importData": "وارد کردن داده ها",
  "ccm.custdata.button.importRecord": "نمای نتیجه را وارد کنید",
  "ccm.custdata.title.edit": "ویرایش داده",
  "ccm.custdata.title.coloum.add": "ایجاد یک ستون حوزه",
  "ccm.custdata.title.coloum.edit": "ویرایش ستون حوزه",
  "ccm.custdata.title.coloum.detail": "مشاهدۀ جزئیات حوزه",
  "ccm.custdata.label.tableName": "نام جدول",
  "ccm.custdata.label.columnNum": "زمینه های",
  "ccm.custdata.label.status": "وضعیت",
  "ccm.custdata.label.description": "شرح",
  "ccm.custdata.label.columnNo": "شماره ",
  "ccm.custdata.label.columnName": "نام زمینه",
  "ccm.custdata.label.columnType": "نوع زمینه",
  "ccm.custdata.label.columnLength": "طول زمینه",
  "ccm.custdata.label.valueRange": "محدوده ارزش",
  "ccm.custdata.label.isIndex": "به عنوان شاخص استفاده می شود",
  "ccm.custdata.label.isEncrypt": "رمزگذاری شده است",
  "ccm.custdata.label.isMandatory": "اجباری",
  "ccm.custdata.label.isMaskDisplay": "پوشانده",
  "ccm.custdata.label.maskStyle": "سبک پوشاندن",
  "ccm.custdata.label.importantTips": "توجه: داده های شخصی یا داده های حساس باید برای ذخیره سازی رمزگذاری شده و برای نمایش پوشانده شوند.",
  "ccm.custdata.label.relaBaseColumn": "فیلد در جدول زیرین",
  "ccm.custdata.label.dataImportRecord": "تاریخچه ورود داده",
  "ccm.custdata.label.beginTime": "زمان شروع",
  "ccm.custdata.label.endTime": "زمان پایان",
  "ccm.custdata.label.sucNum": "تعداد سوابق موفق",
  "ccm.custdata.label.failNum": "تعداد سوابق ناموفق",
  "ccm.custdata.label.template": "_الگو",
  "ccm.custdata.label.failreaon": "علت شکست",
  "ccm.custdata.option.all": "همه",
  "ccm.custdata.option.status.draft": "پیش نویس",
  "ccm.custdata.option.status.release": "آزاد کردن",
  "ccm.custdata.option.status.abandon": "نادیده گرفتن",
  "ccm.custdata.option.columnType.string": "رشته",
  "ccm.custdata.option.columnType.number": "عدد",
  "ccm.custdata.option.columnType.date": "تاریخ",
  "ccm.custdata.option.columnType.time": "زمان قرار",
  "ccm.custdata.option.columnType.dictionary": "فرهنگ لغت",
  "ccm.custdata.option.isIndex.yes": "بله",
  "ccm.custdata.option.isIndex.no": "خیر",
  "ccm.custdata.option.isEncrypt.yes": "بله",
  "ccm.custdata.option.isEncrypt.no": "خیر",
  "ccm.custdata.option.isMandatory.yes": "ضروری",
  "ccm.custdata.option.isMandatory.no": "خیر",
  "ccm.custdata.option.isMaskDisplay.yes": "بله",
  "ccm.custdata.option.isMaskDisplay.no": "خیر",
  "ccm.custdata.option.maskStyle.retainLast4": "چهار رقم آخر را رزرو کنید",
  "ccm.custdata.option.maskStyle.retainFirst4": "چهار رقم اول را رزرو کنید",
  "ccm.custdata.option.maskStyle.maskLast4": "چهار رقم آخر را بپوشانید",
  "ccm.custdata.option.maskStyle.maskFirst4": "چهار رقم اول را بپوشانید",
  "ccm.custdata.option.maskStyle.maskAll": "همه ارقام را بپوشانید",
  "ccm.custdata.option.maskStyle.maskDate": "پوشاندن تاریخ",
  "ccm.custdata.option.maskStyle.maskEmail": "پوشاندن ایمیل",
  "ccm.custdata.option.maskStyle.maskBankAccountNum": "پوشاندن حساب بانکی",
  "ccm.custdata.option.maskStyle.maskIdCardNum": "پوشاندن شماره کارت شناسایی",
  "ccm.custdata.option.importDoing": "در حال پردازش",
  "ccm.custdata.option.importDone": "تکمیل شد",
  "ccm.custdata.option.importFail": "ناموفق",
  "ccm.custdata.msg.msgValidateFailed": "کاراکترهای خاص مجاز نیستند.",
  "ccm.custdata.msg.dictionaryValidateFailed": "الگو نادرست گسترۀ مقدار واژه نامۀ داده",
  "ccm.custdata.msg.inputTableName": "نام جدول را وارد کنید.",
  "ccm.custdata.msg.confirmDelete": "آیا مطمئنید که می خواهید آن را حذف کنید؟",
  "ccm.custdata.msg.confirmRelease": "مطمئنيد که مي خواهید آزادش کنيد؟",
  "ccm.custdata.msg.confirmWithdraw": "مطمئنيد که مي خواهید برش داري؟",
  "ccm.custdata.msg.confirmAbandon": "مطمئنيد که مي خواهيد اين را رها کنيد؟",
  "ccm.custdata.msg.operationSuccess": "عملیات با موفقیت انجام شد.",
  "ccm.custdata.msg.operationFailed": "عملیات شکست خورد.",
  "ccm.custdata.msg.notAllowOperation": "این عملیات مجاز نیست. صفحه را بازخوانی کنید و دوباره امتحان کنید.",
  "ccm.custdata.msg.duplicateTableName": "نام جدول تکراری آن را تغییر نام دهید.",
  "ccm.custdata.msg.deletedItemNotExist": "مورد حذف وجود ندارد. صفحه را بازخوانی کنید و دوباره امتحان کنید.",
  "ccm.custdata.msg.columnNoMatch": "فیلد جدول با آن در پایگاه داده ناسازگار است. جدول را تازه کنید و دوباره امتحان کنید.",
  "ccm.custdata.msg.duplicateColumnName": "نام فیلد تکراری آن را تغییر نام دهید.",
  "ccm.custdata.msg.updatedItemNotExist": "فیلدی که باید به روز شود وجود ندارد. صفحه را بازخوانی کنید و دوباره امتحان کنید.",
  "ccm.custdata.msg.typeColumnNumValidateFailed": "تعداد فیلدها از حد بالایی فراتر رفته است: فقط یک فیلد نمایه\ و حداکثر 30 فیلد غیر رمزگذاری شده و 20 فیلد رمزگذاری شده مجاز است.",
  "ccm.custdata.msg.confirmReturn": "مرتب سازی کامل نیست. آیا مطمئن هستید که می خواهید برای مشاهده جدول سفارشی سازی شده به صفحه بازگردید؟، ",
  "ccm.custdata.msg.completeSort": "ابتدا مرتب سازی را کامل کنید.",
  "ccm.custdata.msg.sortNoChange": "مرتب سازی تغییر نمی کند.",
  "ccm.custdata.msg.dictionaryInputFormat": "الگو: کلید 1: مقدار 1\\، کلید 2: مقدار 2",
  "ccm.custdata.msg.indexColumnValidateFailed": "فیلدی که به عنوان نمایه استفاده می شود اجباری است و نمی توان آن را رمزگذاری کرد.",
  "ccm.custdata.msg.uploadSelect": "یک فایل را انتخاب کنید.",
  "ccm.custdata.msg.fileNameValidateFail": "نام فایل نادرست است. لطفا فایلی را انتخاب کنید که نام آن فقط شامل نویسه های چینی\\، حروف کوچک\\، ارقام\\ یا زیرخط (_) باشد.",
  "ccm.custdata.msg.fileSuffixValidateFail": "فرمت فایل نادرست یک فایل CSV را انتخاب کنید.",
  "ccm.custdata.msg.fileSizeValidateFail": "فایلی با حجم کمتر از 20 مگابایت انتخاب کنید.",
  "ccm.custdata.msg.importDataSuccess": "در حال صدور داده ها ... جزئیات را در نتیجه ورود مشاهده کنید.",
  "ccm.custdata.msg.importDataFail": "وارد کردن داده ها انجام نشد.",
  "ccm.custdata.msg.reachUpperTableNum": "تعداد جداول سفارشی سازی شده به حد بالایی رسیده است.",
  "ccm.custdata.msg.missRequiredColumn": "یک جدول سفارشی سازی شده تنها زمانی می تواند منتشر شود که دارای حداقل یک فیلد فهرست و سایر فیلدهای اطلاعاتی باشد.",
  "ccm.custdata.msg.tableBeUsed": "جدول سفارشی سازی شده توسط یک پیکربندی page object ارجاع داده می شود. قبل از انجام این عملیات اطمینان حاصل کنید که جدول به آن ارجاع داده نشده است.",
  "ccm.custdata.msg.tableExistInstData": "جدول سفارشی سازی شده حاوی داده های نمونه است و نمی توان آن را برای اصلاح برداشت.",
  "ccm.custdata.msg.selectItem": "قبل از مرتب سازی یک مورد را انتخاب کنید.",
  "ccm.custdata.operlog.downloadTemplate": "دانلود الگو جدول پایه",
  "ccm.custdata.operlog.uploadDataFile": "آپلود فایل داده های جدول پایه",
  "ccm.custdata.operlog.downloadData": "دانلود داده های جدول پایه",
  "ccm.custdata.operlog.authFailed": "احراز هویت ناموفق بود",
  "ccm.custdata.operlog.addExportTask": "وظیفه صدور جدید",
  "ccm.custdata.operlog.viewExportTask": "صدور نمای وظیفه ",
  "ccm.custdata.operlog.confirm": "تایید",
  "ccm.custdata.operlog.confirmMessage": "آیا مطمئن هستید که یک وظیفه صدور جدید ایجاد می کنید؟",
  "ccm.custdata.operlog.refresh": "تازه کردن",
  "ccm.custdata.operlog.download": "دانلود",
  "ccm.custdata.operlog.exportBeginTime": "زمان شروع صدور",
  "ccm.custdata.operlog.exportEndTime": "زمان پایان صدور",
  "ccm.custdata.operlog.conditions": "شرایط",
  "ccm.custdata.operlog.exportTaskStatus": "وضعیت",
  "ccm.custdata.operlog.displayMask": "نمایش mask",
  "ccm.custdata.operlog.operation": "عمل کند",
  "ccm.custdata.operlog.addExportTaskSuccess": "وظیفه صدور جدید با موفقیت انجام شد",
  "ccm.custdata.operlog.addExportTaskError": "وظیفه صدور جدید انجام نشد\، لطفاً گزارش را بررسی کنید",
  "ccm.custdata.operlog.exportiong": "صدور",
  "ccm.custdata.operlog.success": "موفقیت",
  "ccm.custdata.operlog.failed": "ناموفق",
  "ccm.custdata.operlog.passValidateFailed": "باید حاوی حروف، اعداد، نویسه‌های خاص باشد _ {'@'} {'%'}",
  "ccm.custdata.operlog.zippwd": "رمز عبور فشرده",
  "ccm.custdata.operlog.inputzippwd": "رمز عبور فشرده سازی را وارد کنید",
  "ccm.custdata.operlog.importdata": "وارد کردن داده ها",
  "ccm.custdata.operlog.popwindowmessage": "لطفا رمز عبور استفاده شده برای تولید فایل فشرده را وارد کنید. رمز عبور فشرده باید شامل حروف، اعداد، کاراکترهای خاص باشد _ {'@'} {'%'}",
  "ccm.querycustdata.msg.inputIndexData": "لطفا وارد کنید",
  "ccm.querycustdata.msg.noData": "اطلاعاتی وجود ندارد",
  "ccm.querycustdata.msg.selectTable": "نام جدول را انتخاب کنید",
  "ccm.agent.updateSoftPhone.queryNodeFailed": "جستجوی اطلاعات گره محیط کاری tenant انجام نشد.",
  "ccm.agent.updateSoftPhone.modifyLocalPwd": "تغییر رمز عبور تلفن نرم افزاری محلی انجام نشد.",
  "ccm.agent.updateSoftPhone.newPasswordFailed": "رمز عبور جدید تأیید نشد.",
  "ccm.agent.updateSoftPhone.accountPasswordFailed": "رمز عبور حساب تأیید نشد.",
  "ccm.agent.updateSoftPhone.updateGatewayFailed": "تغییر رمز عبور دروازه انجام نشد.",
  "ccm.agent.updateSoftPhone.updateFailed": "تغییر رمز عبور انجام نشد",
  "ccm.agent.updateSoftPhone.updateSuccess": "رمز عبور با موفقیت تغییر کرد.",
  "ccm.agent.updateSoftPhone.unFoundInfo": "مرکز تماس هماهنگ نیست.",
  "ccm.agent.updateSoftPhone.unErrorInfo": "استثنای سیستم.",
  "ccm.agent.updateSoftPhone.updateOpertionSuccess": "هشدار",
  "ccm.agent.updateSoftPhone.reason": "دلیل",
  "ccm.agent.pageurls.pagetype": "نوع صفحه",
  "ccm.pageurls.select.externalInterface": "صفحه خارجی",
  "ccm.pageurls.select.internalInterface": "صفحه داخلی",
  "ccm.pageinterface.title": "رابط صفحه",
  "ccm.pageinterface.objectList": "فهرست Object",
  "ccm.pageinterface.addObject": "افزودن Object",
  "ccm.pageinterface.objectName": "نام Object",
  "ccm.pageinterface.custTableName": "نام جدول زیرین",
  "ccm.pageinterface.selectedAttributeNum": "ویژگی های Object انتخاب شده",
  "ccm.pageinterface.pleaseSelectAttribute": "یک ویژگی Object را انتخاب کنید.",
  "ccm.pageinterface.interfaceName": "نام رابط",
  "ccm.pageinterface.inputInterfaceName": "وارد",
  "ccm.pageinterface.interfaceType.internal": "رابط داخلی",
  "ccm.pageinterface.interfaceType.external": "رابط خارجی",
  "ccm.pageinterface.selectAttributeTitle": "ویژگی Object را انتخاب کنید",
  "ccm.pageinterface.pleaseSelectInterfaceType": "یک نوع رابط را انتخاب کنید.",
  "ccm.pageinterface.saveSuccess": "مجموعه رابط صفحه با موفقیت ذخیره شد.",
  "ccm.pageinterface.optionalAttribute": "ویژگی اختیاری",
  "ccm.pageinterface.selectedAttribute": "ویژگی انتخاب شده",
  "ccm.pageinterface.viewAttribute": "مشاهده ویژگی Object",
  "ccm.pageinterface.interfaceType": "نوع رابط",
  "ccm.pageinterface.interfaceType.externalExplain": "رابط های خارجی در صفحه مدیریت API پیکربندی شده اند. با مدیر سیستم تماس بگیرید.",
  "ccm.pageinterface.selectedAll": "انتخاب همه",
  "ccm.pageinterface.cancelAll": "لغو همه",
  "ccm.agent.callacceptno.set": "تنظیم",
  "ccm.recognition.label.interconnection.parameters": "پارامترهای اتصال",
  "ccm.recognition.label.api.fabric.id": "شناسه فابریک API",
  "ccm.recognition.label.appSecret.download": "SK را دانلود کنید",
  "ccm.recognition.label.appSecret.reset": "SK را بازنشانی کنید",
  "ccm.recognition.queryAK.error": "هنگام جستجوی اطلاعات کلید APP خطایی روی داد.",
  "ccm.recognition.queryAK.not.exist": "فضای محیط کاری tenant فعلی کلید برنامه ندارد.",
  "ccm.vcallcenter.config.popup.authentication": "تصدیق کردن",
  "ccm.vcallcenter.config.popup.currentaccount.pwd": "رمز عبور حساب جاری",
  "ccm.vcallcenter.config.popup.authentication.failed": "احراز هویت ناموفق بود.",
  "ccm.recognition.resetSK.success": "SK با موفقیت بازنشانی شد.",
  "ccm.recognition.resetSK.failed": "تنظیم مجدد SK انجام نشد.",
  "ccm.recognition.label.appSecret.download.appId.empty.error": "فیلد appId نمی تواند خالی باشد.",
  "ccm.recognition.label.appSecret.download.querySK.error": "جستجو از SK انجام نشد.",
  "ccm.recognition.label.appSecret.download.SK.not.exist": "اطلاعات SK وجود ندارد.",
  "ccm.vcallcenter.config.popup.current.user.locked.error": "کاربر فعلی قفل است و 10 دقیقه بعد باز خواهد شد.",
  "ccm.recognition.label.appSecret.download.SK.only.once.error": "اگر کلید را فراموش کردید، کلید را بازنشانی کنید و دوباره دانلود کنید.",
  "ccm.recognition.label.appSecret.download.SK.io.error": "SK بارگیری نشد. استثناء I/O",
  "ccm.agent.pageurlparam.urlParamName": "پارامتر نشانی وب بالاپر پرده",
  "ccm.agent.pageurlparam.relSysParamType": "نوع پارامترۀ داده سیستم وابسته",
  "ccm.agent.pageurlparam.calldata": "داده تماس",
  "ccm.agent.pageurlparam.channelassociateddata": "داده های مرتبط با کانال",
  "ccm.certificate.management.status.value.inuse": "در استفاده",
  "ccm.agent.pageurlparam.relSysParamName": "پارامتر داده سیستم وابسته",
  "ccm.agent.pageurlparam.paramIsConfiged": "پارامتر نشانی وب بالاپر یا پارامتر دادۀ سیستم مرتبط از قبل وجود دارد.",
  "ccm.agent.pageurlparam.checkSpecialChar": "وارد کردن ارقام ، حروف ، خط های زیر خط های (_), و خطهای) - (. خط (.) باید در وسط باشد.",
  "ccm.agent.pageurlparam.checkSpecialChar.noPoint": "وارد کردن ارقام ، حروف ، خط های زیر خط های (_), و خطهای) - (. خط (.) باید در وسط باشد",
  "ccm.agent.pageurlparam.checkSpecialChar.name": "وارد کردن رقمها ، حروف ، خط زیرین (_), و خط مفدۀ) - (",
  "ccm.agent.pageurlparam.paramLengthLimit": "طول پارامتر نمی تواند بیش از 64 کاراکتر باشد",
  "ccm.agent.pageurlparam.paramCountLimit": "حداکثر {limit} پارامتر URL پاپ آپ را می توان پیکربندی کرد.",
  "ccm.skillcreate.maxnumber.failed": "تعداد صف های مهارت محیط کاری tenant نمی تواند از حد بالایی تجاوز کند:",
  "ccm.agent.restReason.create": "اضافه کردن",
  "ccm.agent.restReason.createReason": "دلیل استراحت را اضافه کنید",
  "ccm.agent.restReason.operation": "عمل",
  "ccm.agent.restReason.delete": "حذف",
  "ccm.agent.restReason.edit": "ویرایش دليل استراحت",
  "ccm.agent.restReason.restCauseCode": "کد دلیل استراحت",
  "ccm.agent.restReason.confOnlyCauseCode": "عملیات شکست خورد. کد دلیل از قبل وجود دارد.",
  "ccm.agent.restReason.restCauseDesc": "شرح",
  "ccm.agent.restReason.restCauseTime": "مدت زمان استراحت",
  "ccm.agent.restReason.restCauseTime.formate": "مدت زمان استراحت باید در الگو HH:MM:SS باشد.",
  "ccm.agent.restReason.restCauseTime.limit": "مدت زمان استراحت باید بیشتر از 0 ثانیه و کمتر از 24 ساعت باشد.",
  "ccm.agent.restReason.restCauseDesc.limit": "توضیح دلیل بقیه می تواند حداکثر 100 کاراکتر داشته باشد.",
  "ccm.agent.restReason.restCauseDesc.limit.form": "توضیح دلیل بقیه می تواند حداکثر 100 کاراکتر داشته باشد",
  "ccm.agent.restReason.selectRestReasons": "دلیل استراحت را انتخاب کنید",
  "ccm.agent.restReason.selectRestReasons.limit": "حداکثر 10 دلیل استراحت را می توان به صورت دسته ای حذف کرد.",
  "ccm.agent.restReason.pleaseRefresh": "همگام سازي و دوباره تلاش کن",
  "ccm.agent.restReason.updateFailed": "اصلاح نشد.",
  "ccm.agent.restReason.createFailed": "افزودن ناموفق بود.",
  "ccm.agent.restReason.partDeleteFailed": "حذف جزئی انجام نشد.",
  "ccm.agent.restReason.isusing.warning": "کدهای دلیل استراحت در حال اجرا هستند. این ممکن است باعث شود که دلیل بقیه به اشتباه در سابقه استراحت نمایش داده شود.",
  "ccm.certificate.management.search.certcode": "لطفا کد گواهی را وارد کنید",
  "ccm.certificate.management.search.scenename": "لطفاً صحنه گواهی را وارد کنید",
  "ccm.certificate.management.certcode": "نام گواهی",
  "ccm.certificate.management.scenename": "شرح گواهی",
  "ccm.certificate.management.type": "نوع گواهی",
  "ccm.certificate.management.expiretime": "تاریخ انقضاء",
  "ccm.certificate.management.updatetime": "زمان به روز رسانی",
  "ccm.certificate.management.passwd": "رمز عبور گواهی",
  "ccm.certificate.management.certificatefile": "فایل گواهی",
  "ccm.certificate.management.certificatecrlfile": "فایل CRL",
  "ccm.certificate.management.certificatefile.notupload": "لطفا فایل گواهی را آپلود کنید.",
  "ccm.certificate.management.certificatefile.parseerror": "تجزیه گواهی انجام نشد. بررسی کنید که آیا الگو گواهی یا رمز عبور صحیح است یا خیر.",
  "ccm.certificate.management.certificatefile.certcodeexist": "کد گواهی از قبل وجود دارد.",
  "ccm.certificate.message.tip.warning": "الگوریتم امضا و طول کلید گواهی الزامات را برآورده نمی‌کند، که خطرناک است. آیا مطمئن هستید که میخواهید ادامه دهید؟",
  "ccm.certificate.management.certificatefile.suffixerror": "فقط فایل هایی با فرمت jks\,cer\,crt\,pem\,pfx\,p12 قابل آپلود هستند.",
  "ccm.certificate.management.certificatecrlfile.suffixerror": "فایل فهرست ابطال گواهی باید با فرمت crl. باشد.",
  "ccm.certificate.management.certificatefile.suffixerror.pre": "گواهی‌های نوع فقط می‌توانند حاوی فایل‌هایی با پسوند باشند",
  "ccm.certificate.management.certificatefile.suffixerror.suffix": "یا پسوند خالی",
  "ccm.certificate.management.certificate.crlfile.sizeerror": "فایل CRL نمی تواند بیش از 2048 کیلوبایت باشد.",
  "ccm.certificate.management.certificatefile.certfile.sizeerror": "اندازه فایل گواهی نمی تواند بیش از 20 کیلوبایت باشد.",
  "ccm.certificate.management.certificatefile.maxrecord": "تعداد گواهی ها به حداکثر رسیده است.",
  "ccm.certificate.management.certificatefile.fileempty": "فایل خالی است",
  "ccm.certificate.management.certificatefile.typeerror": "نوع فایل نادرست",
  "ccm.certificate.management.certificatefile.injectionerror": "فایل دارای injection risks است.",
  "ccm.certificate.management.certificatefile.uploadcertfile": "آپلود فایل گواهی",
  "ccm.certificate.management.certificatefile.uploadcertcrlfile": "فایل CRL را آپلود کنید",
  "ccm.certificate.management.certificate.crlfile.certhasexpired": "گواهی منقضی شده است.",
  "ccm.certificate.management.certificate.crlfile.crlfileinvalid": "فرمت فایل CRL نادرست است.",
  "ccm.certificate.management.update.certfile": "به روز رسانی فایل گواهی",
  "ccm.certificate.management.add.title": "افزودن گواهی",
  "ccm.certificate.management.update.title": "در حال به روزرسانی گواهی نامه ها",
  "ccm.certificate.management.update.crlfile": "فایل CRL را به روز کنید",
  "ccm.certificate.management.update.cancel.certfile": "آیا مطمئن هستید که می خواهید به روز رسانی گواهی را لغو کنید؟",
  "ccm.certificate.management.update.cancel.crlfile": "آیا مطمئن هستید که می خواهید به روز رسانی فایل crl را لغو کنید؟",
  "ccm.certificate.management.certificate.tip": "نکات:",
  "ccm.certificate.management.certificatefile.certcodeerror": "کد گواهی خطا است.",
  "ccm.certificate.management.certificatefile.certsceneerror": "صحنه گواهی خطا است.",
  "ccm.certificate.management.certificatefile.certpwderror": "رمز عبور گواهی خطا است.",
  "ccm.certificate.management.certificatefile.certtypeerror": "نوع گواهی خطا است.",
  "ccm.certificate.management.warn.selectcerttype": "لطفا نوع گواهی را انتخاب کنید.",
  "ccm.basicdata.msg.queryfail": "جستجوی داده های اصلی انجام نشد.",
  "ccm.basicdata.msg.modifyfail": "تغییر داده های اصلی انجام نشد.",
  "ccm.basicdata.msg.modifysuccess": "داده های اصلی با موفقیت اصلاح شد.",
  "ccm.basicdata.msg.deletefail": "داده های اصلی حذف نشد.",
  "ccm.basicdata.msg.deletesuccess": "داده های اصلی با موفقیت حذف شدند.",
  "ccm.basicdata.msg.nodata": "اطلاعاتی وجود ندارد",
  "ccm.basicdata.msg.nodatamodify": "هیچ داده ای نیاز به تغییر ندارد.",
  "ccm.basicdata.msg.error": "خطا",
  "ccm.basicdata.error.syserror": "خطای سیستم. با پرسنل تعمیر و نگهداری تماس بگیرید",
  "ccm.basicdata.error.capacitylimit": "ظرفیت داده به حد بالایی رسیده است {0}",
  "ccm.basicdata.error.firstlinenotmatch": "خط اول فایل وارد شده با آن در الگو مطابقت ندارد",
  "ccm.basicdata.error.datatypenotmatch": "فرمت داده با الگو تعریف فیلد ناسازگار است",
  "ccm.basicdata.error.databaseerror": "داده های شاخص تکراری است. در نتیجه\، داده ها به صورت دسته ای به پایگاه داده وارد نمی شوند",
  "ccm.basicdata.error.suffix": ".",
  "ccm.note.config": "پیکربندی زنگ برگشتی",
  "ccm.note.select": "برگزیدن یک تون عقب حلقه ای",
  "ccm.note.config.policy": "مامور تماس رو جواب بده",
  "ccm.note.config.agent.ring.back.tone": "پیکربندی زنگ برگشتی نماینده",
  "ccm.note.config.filepath": "فایل یادداشت را انتخاب کنید",
  "ccm.note.config.choosenote": "لطفاً یک فایل یادداشت انتخاب کنید",
  "ccm.worknobeforevoice.config.choosenote": "یک مقدار برای Voice Before Employee ID انتخاب کنید.",
  "ccm.worknoaftervoice.config.choosenote": "یک مقدار برای Voice After Employee ID انتخاب کنید.",
  "ccm.note.config.policy.systemdefault": "پیش فرض سیستم",
  "ccm.note.config.policy.custom": "سفارشی سازی",
  "ccm.note.config.error": "خطا",
  "ccm.note.config.save": "صرفه جویی",
  "ccm.note.config.failed": "پیکربندی یادداشت انجام نشد",
  "ccm.note.config.success": "موفقیت",
  "ccm.note.config.configsuccess": "پیکربندی موفقیت یادداشت.",
  "ccm.note.config.searchnotename": "لطفا نام را وارد کنید",
  "ccm.note.config.notename": "نام",
  "ccm.recognition.label.anonymous": "پرچم تماس خروجی ناشناس",
  "ccm.recognition.label.openanonymoussuccess": "پرچم تماس خروجی ناشناس با موفقیت فعال شد. لطفاً یک کد دسترسی کلیک به تماس را که برای مسیر فراخوانی پیکربندی شده است انتخاب کنید.",
  "ccm.recognition.label.closeanonymoussuccess": "پرچم تماس خروجی ناشناس با موفقیت غیرفعال شد",
  "ccm.recognition.label.clicktocallaccesscode": "کد دسترسی کلیک برای تماس",
  "ccm.contactitemconfig.message.itemValidatefirst": "حرف اول باید حرفی باشد ، علامت (_), با علامت دلاری ($)",
  "ccm.contactitemconfig.message.itemValidateall": "حروف علامت (_), یا ارقام علامت علامتی ($), که بيش از & رقمها را وارد کنید",
  "ccm.recognition.label.calledroutesuccess": "مسیر فراخوانده شده با موفقیت پیکربندی شد",
  "ccm.anonymous.message.fail.feature": "ویژگی اعتبار تماس خروجی ناشناس غیرعادی است",
  "ccm.anonymous.message.fail.open": "پرچم باز نشد. پیکربندی CTI را بررسی کنید",
  "ccm.anonymous.message.fail.close": "بستن پرچم انجام نشد. پیکربندی CTI را بررسی کنید",
  "ccm.anonymous.message.fail.notmatch": "پرچم ویژگی با وضعیت فعلی مطابقت ندارد",
  "ccm.anonymous.message.fail.paranull": "کد دسترسی در پارامتر خالی است",
  "ccm.anonymous.message.fail.cti": "جستجو از جریان تماس خروجی ناشناس در CTI انجام نشد",
  "ccm.anonymous.message.fail.save": "مسیر فراخوانی ذخیره نشد. لطفاً پیکربندی CTI را بررسی کنید",
  "ccm.anonymous.message.fail.delete": "مسیر فراخوانده شده حذف نشد. لطفاً پیکربندی CTI را بررسی کنید",
  "ccm.satisfactionlevel.message.savesuccess": "سطح رضایت با موفقیت ذخیره شد. اگر یک فرآیند رضایت پیکربندی شده است، آن را دوباره در مدیریت جریان آزاد کنید. در غیر این صورت\ نتیجه رضایت به اشتباه نمایش داده می شود. نظرسنجی جدید رضایتمندی بر اساس نتایج این پیکربندی خواهد بود.",
  "ccm.satisfactionlevel.message.savefail": "ذخیره سطح رضایت انجام نشد.",
  "ccm.satisfactionlevel.message.resetsuccess": "سطح رضایت با موفقیت بازنشانی شد. اگر یک فرآیند رضایت پیکربندی شده است، آن را دوباره در مدیریت جریان آزاد کنید. در غیر این صورت\ نتیجه رضایت به اشتباه نمایش داده می شود. نظرسنجی جدید رضایت بر اساس نتایج این پیکربندی خواهد بود.",
  "ccm.satisfactionlevel.message.resetfail": "بازنشانی سطح رضایت انجام نشد.",
  "ccm.satisfactionlevel.message.satislevel": "سطح رضایت",
  "ccm.satisfactionlevel.message.satistip": "اطمینان حاصل کنید که سطوح رضایت مورد استفاده در نظرسنجی رضایت از هر کانال یکسان است. مانند نظرسنجی رضایتمندی صوتی\، نظرسنجی رضایتمندی پیامکی\ و نظرسنجی رضایتمندی از وب.",
  "ccm.satisfactionlevel.message.nopara": "توضیحات نمی تواند خالی باشد.",
  "ccm.satisfactionlevel.message.samedesc": "توضیحات تکراری وجود دارد. لطفا آن را اصلاح کنید.",
  "ccm.satisfactionlevel.message.leastcalltype": "لطفاً حداقل یک نوع تماس را انتخاب کنید.",
  "ccm.agent.contact.isExistTalkReason": "دلیل تماس تنظیم شده یا نه",
  "ccm.agent.callreason.need": "لطفاً حداقل یک دلیل تماس را انتخاب کنید.",
  "ccm.agent.callreason.modify.success": "تغییر دلیل تماس با موفقیت انجام شد",
  "ccm.agent.callreason.modify.failed": "تغییر دلیل تماس انجام نشد.",
  "ccm.agent.callreason.delete.confirm": "آیا مطمئنید که می خواهید دلیل تماس را حذف کنید؟",
  "ccm.common.label.yes": "بله.",
  "ccm.common.label.no": "خیر",
  "ccm.common.label.error": "خطا",
  "ccm.label.contactexport.selectrange": "محدوده را انتخاب کنید",
  "ccm.label.contactexport.selectdata": "داده ها را انتخاب کنید",
  "ccm.label.contactexport.selectall": "همه",
  "ccm.label.contactexport.curpage": "صفحه فعلی",
  "ccm.label.contactexport.exception": "هنگام ایجاد وظیفه صدور خطایی روی داد. لاگ ها را بررسی کنید.",
  "ccm.label.contactexport.parainvalid": "شرایط صدور تأیید نشد.",
  "ccm.label.contactexport.parainnull": "داده هایی که باید صادر شوند را انتخاب کنید.",
  "ccm.label.chatRecord.channeltypeinnull": "انواع کانال را انتخاب کنید",
  "ccm.transtask.label.task.chatrecordall.uniqueness": "شما نمی توانید همه انواع کانال را اضافه کنید زیرا وظایف برای برخی از انواع کانال وجود دارد.",
  "ccm.transtask.label.task.chatrecord.uniqueness": "وظایف برای برخی از انواع کانال انتخابی وجود دارد.",
  "ccm.label.contactexport.exceedlimit1": "حداکثر {0} سابقه داده را می توان صادر کرد",
  "ccm.label.contactexport.exceedlimit2": ". محدوده زمانی داده را محدود کنید یا معیارهای فیلتر داده را اضافه کنید.",
  "ccm.label.contactexport.nocount": "هیچ سابقه تماسی شرایط را برآورده نمی کند.",
  "ccm.operlog.contactexport.download": "دانلود سوابق تماس",
  "ccm.contactitemconfig.label.contact.recorddata": "سوابق تماس data item",
  "ccm.contactitemconfig.label.contact.extended.information.dataset": "مجموعه داده های اطلاعات تماس گسترده",
  "ccm.contactitemconfig.label.data.item.name": "نام data item",
  "ccm.contactitemconfig.label.data.item.identification": "شناسه data item",
  "ccm.contactitemconfig.label.data.item.category": "نوع داده",
  "ccm.contactitemconfig.label.data.item.contact.list.visibility": "سابقه تماس قابل مشاهده",
  "ccm.contactitemconfig.label.data.item.contact.list.visibility.yes": "بله",
  "ccm.contactitemconfig.label.data.item.contact.list.visibility.no": "خیر",
  "ccm.contactitemconfig.label.data.item.sequence.number": "شماره",
  "ccm.contactitemconfig.label.data.extended.set": "مجموعه داده منبع",
  "ccm.contactitemconfig.label.data.extended.dataset.mapping.field": "فیلد نگاشت مجموعه داده",
  "ccm.contactitemconfig.label.data.extended.writeback.extended.field": "زمینه بازنویسی گسترده",
  "ccm.contactitemconfig.label.data.extended.Type": "نوع داده",
  "ccm.contactitemconfig.label.data.extended.Type.String": "رشته",
  "ccm.contactitemconfig.label.data.extended.Type.DataDictionary": "فرهنگ لغت داده",
  "ccm.contactitemconfig.label.data.extended.dictionary.value": "ارزش دیکشنری داده",
  "ccm.contactitemconfig.label.data.item.basic": "داده های پایه",
  "ccm.contactitemconfig.label.data.item.extended": "داده های برنامه افزودنی",
  "ccm.contactitemconfig.label.data.item.basicext": "داده های توسعه یافته پایه",
  "ccm.contactitemconfig.label.data.extended.dataset.name": "نام مجموعه داده",
  "ccm.contactitemconfig.label.data.extended.dataset.source.interface": "رابط منبع مجموعه داده",
  "ccm.contactitemconfig.message.init.basicdata.success": "داده های اولیه با موفقیت راه اندازی شد.",
  "ccm.contactitemconfig.message.confOnlyDataItemName": "عملیات انجام نشد زیرا نام data item از قبل وجود دارد.",
  "ccm.contactitemconfig.message.confOnlyDataItemCode": "عملیات انجام نشد زیرا شناسه data item از قبل وجود دارد.",
  "ccm.contactitemconfig.message.confOnlyDatasetName": "عملیات انجام نشد زیرا نام مجموعه داده از قبل وجود دارد.",
  "ccm.contactitemconfig.message.DatasetName.inuse": "مجموعه داده را نمی توان حذف کرد زیرا قبلاً در data item سابقه تماس پیکربندی شده است.",
  "ccm.contactitemconfig.message.itemValidate": "حرف اول باید یک حرف، زیرخط (_) یا علامت دلار ($) باشد. سایر حروف می توانند زیرخط (_)، علامت دلار ($)، حروف یا ارقام باشند.",
  "ccm.agent.contact.pictures": "تصاویر",
  "ccm.agent.message.videoNotEnough": "تعداد منابع ویدئویی کافی نیست زیرا هم نمایندگان ویدئویی و هم نمایندگانی که با صف‌های مهارت ویدئویی پیکربندی شده‌اند منابع ویدئویی را اشغال می‌کنند. منابع ویدئویی را به درستی اختصاص دهید.",
  "ccm.callreasonconfig.message.deletecallreason.failed": "حذف نشد.",
  "ccm.callreasonconfig.message.deletecallreason.used": "حذف نشد\، دلیل تماس استفاده شده است.",
  "ccm.callreasonconfig.message.deletecallreason.success": "با موفقیت حذف شد.",
  "ccm.common.label.success": "موفقیت آمیز",
  "ccm.agent.message.agenttypebothempty": "یک نوع نماینده برای نماینده انتخاب شده پیکربندی نشده است. باید نوع نماینده را پیکربندی کنید.",
  "ccm.agent.button.batchconfig": "پیکربندی دسته ای",
  "ccm.agent.button.config": "پیکربندی کنید",
  "ccm.agent.button.cancelconfig": "لغو پیکربندی",
  "ccm.agent.message.cancelconfig": "لغو پیکربندی",
  "ccm.agent.message.willcancelconfig": "اگر پیکربندی\ را لغو کنید، برای نمایندگانی که از پیکربندی استفاده می‌کنند در دسترس نخواهد بود. آیا مطمئن هستید که می خواهید پیکربندی را لغو کنید؟",
  "ccm.agent.callreason.exceedmaxnum": "حداکثر پنج دلیل تماس مجاز است.",
  "ccm.agent.clear.message.agentnotexist": "به دلیل اینکه نماینده وجود ندارد، پیکربندی لغو نشد.",
  "ccm.agent.message.agentnotexist": "نماینده وجود ندارد. صفحه مدیریت نماینده را بازخوانی کنید و دوباره امتحان کنید.",
  "ccm.agent.message.agenttypeinvalid": "نوع نماینده نامعتبر است.",
  "ccm.basicpage.not.sk.download": "هیچ مجوز دانلود SK در دسترس نیست.",
  "ccm.label.contactexport.selectdatarange": "محدوده داده",
  "ccm.label.contactexport.selectdatafield": "زمینه صدور",
  "ccm.label.contactexport.currentfield": "فیلد در لیست فعلی",
  "ccm.label.contactexport.customfield": "فیلد سفارشی",
  "ccm.transserver.label.edit": "سرور dump",
  "ccm.transserver.label.servername": "نام سرور",
  "ccm.transserver.label.servertype": "تایپ کنید",
  "ccm.transserver.label.serveraddr": "آدرس",
  "ccm.transserver.label.obs": "سرور OBS",
  "ccm.transserver.label.objobs": "OBS",
  "ccm.transserver.label.obsaddr": "آدرس OBS",
  "ccm.transserver.label.view": "چشم انداز",
  "ccm.transserver.delete.success": "سرور dump با موفقیت حذف شد.",
  "ccm.transserver.delete.fail": "سرور dump حذف نشد.",
  "ccm.transserver.discard.confirm": "آیا مطمئن هستید که می خواهید کار را نادیده بگیرید؟",
  "ccm.transserverparam.label.info": "اطلاعات اولیه",
  "ccm.transserverparam.label.bucket": "سطل داده",
  "ccm.transserverparam.label.ak": "AK",
  "ccm.transserverparam.label.sk": "SK",
  "ccm.transserverparam.label.isagent": "فعال کردن پروکسی",
  "ccm.transserverparam.label.agentip": "آدرس سرور پروکسی",
  "ccm.transserverparam.label.agentport": "پورت",
  "ccm.transserverparam.label.proxyUserName": "نام کاربری",
  "ccm.transserverparam.label.password": "کلمه عبور",
  "ccm.transtask.label.taskedit": "وظیفه dump",
  "ccm.transtask.label.taskid": "شناسه وظیفه",
  "ccm.transtask.label.taskname": "نام وظیفه",
  "ccm.transtask.label.tasktype": "تایپ کنید",
  "ccm.transtask.label.taskupload": "سرور آپلود",
  "ccm.transtask.label.status": "وضعیت",
  "ccm.transtask.label.start": "شروع کنید",
  "ccm.transtask.label.contactRecord": "سابقه تماس",
  "ccm.transtask.label.chatRecord": "داده های چت چند رسانه ای",
  "ccm.transtask.label.intelligentInspection": "داده های بازرسی کیفیت هوشمند",
  "ccm.transtask.label.cdrData": "داده های CDR",
  "ccm.transtask.label.voiceFile": "فایل صوتی",
  "ccm.transtask.label.reportData": "گزارش داده ها",
  "ccm.transtask.label.notEnabled": "غیر فعال کردن",
  "ccm.transtask.label.discarded": "نادیده گرفته شده",
  "ccm.transtask.label.View": "چشم انداز",
  "ccm.transtask.label.enable": "فعال کردن",
  "ccm.transtask.label.pause": "مکث کنید",
  "ccm.transtask.label.discard": "نادیده گرفتن",
  "ccm.transtask.label.restores": "بازگرداندن",
  "ccm.transtask.label.cantDelete": "سرور را نمی توان حذف کرد زیرا قبلاً توسط یک وظیفه dump به آن ارجاع داده شده است.",
  "ccm.transtask.label.delete": "وظیفه dump با موفقیت حذف شد.",
  "ccm.transserver.delete.time.fail": "10 دقیقه پس از حذف کار، آن را حذف کنید.",
  "ccm.transtask.label.cantsave": "سرور را نمی توان تغییر داد زیرا توسط یک وظیفه dump به آن ارجاع داده شده است.",
  "ccm.transtask.label.savefail": "افزودن یک سرور dump انجام نشد زیرا حداکثر {0} سرور dump مجاز است.",
  "ccm.transtask.label.saveexit": "افزودن سرور dump ناموفق بود زیرا نام سرور از قبل وجود دارد.",
  "ccm.transtask.label.datatype": "نوع داده dump ",
  "ccm.transtask.label.dataname": "نام سرور dump",
  "ccm.transtask.label.datapath": "مسیر فایل dump ",
  "ccm.transtask.label.datapath.tips": "مسیر OBS",
  "ccm.transtask.label.dumpinterval": "مدت زمان dump",
  "ccm.transtask.label.dumpintervalInitial": "شروع مدت زمان dump",
  "ccm.transtask.label.reportedir": "گزارش dump",
  "ccm.transtask.label.reports": "گزارش dump",
  "ccm.transtask.label.contactrcd": "dump سابقه تماس",
  "ccm.transtask.label.chatrecordinf": "تنظیمات dump داده های چت چند رسانه ای",
  "ccm.transtask.label.chatrecordday": "مدت زمان dump داده چت (روز)",
  "ccm.transtask.label.datacondition": "معیارهای فیلتر داده",
  "ccm.transtask.label.exportdata": "فیلد صدور داده:",
  "ccm.transtask.label.contactrcddata": "فیلدهای موجود در لیست ثبت تماس",
  "ccm.transtask.label.contactchoose": "فیلدهای سفارشی سازی شده در ثبت تماس",
  "ccm.transtask.label.cancel": "لغو کنید",
  "ccm.transtask.label.save": "خوب",
  "ccm.transtask.label.day": "روز",
  "ccm.transtask.label.week": "هفته",
  "ccm.transtask.label.month": "ماه",
  "ccm.transtask.label.vdnReport": "گزارش ترافيک وي دي ان",
  "ccm.transtask.label.vdnAccessCodeReport": "گزارش ترافیک VDN توسط کد دسترسی",
  "ccm.transtask.label.ivrReport": "گزارش ترافیک IVR",
  "ccm.transtask.label.ivrAccessCodeReport": "گزارش دادۀ IVR بر اساس کد دسترسی",
  "ccm.transtask.label.skillReport": "گزارش ترافیک صف مهارت",
  "ccm.transtask.label.skillAccessCodeReport": "گزارش ترافیک صف مهارت توسط کد دسترسی",
  "ccm.transtask.label.agentReport": "گزارش عملکرد نماینده",
  "ccm.transtask.label.agentOutBoundReport": "گزارش نماینده خارج از محدوده تماس",
  "ccm.transtask.label.agentOperationReport": "گزارش عملیات ارتباط نماینده",
  "ccm.transtask.label.abandonedRingReport": "تماس های رها شده (در حین زنگ) گزارش خلاصه",
  "ccm.transtask.label.monday": "دوشنبه",
  "ccm.transtask.label.tuesday": "سه شنبه",
  "ccm.transtask.label.wednesday": "چهار شنبه",
  "ccm.transtask.label.thurs": "پنج شنبه",
  "ccm.transtask.label.friday": "جمعه",
  "ccm.transtask.label.saturday": "شنبه",
  "ccm.transtask.label.sunday": "یکشنبه",
  "ccm.transtask.label.taskfail": "ایجاد کار ناموفق بود زیرا حداکثر {0} کار از نوع سابقه تماس مجاز است.",
  "ccm.transtask.label.tasknameexits": "ایجاد کار انجام نشد زیرا نام کار از قبل وجود دارد.",
  "ccm.transtask.config.pathvalidate": "مسیر فایل dump نمی تواند حاوی \"./\" باشد.",
  "ccm.transtask.label.skillSummaryReport": "گزارش خلاصه ترافیک",
  "ccm.transtask.label.task.uniqueness": "فقط یک کار داده گزارش را می توان در همان بازه تخلیه برای یک نوع داده dump ایجاد کرد.",
  "ccm.transtask.label.task.otheruniquene": "فقط یک فایل صوتی، داده های بازرسی هوشمند، یا نوع داده های نوع داده را می توان برای یک نوع دادهٔ زباله ایجاد کرد.",
  "ccm.transtask.label.updateexsit": "عملیات انجام نشد زیرا نام سرور از قبل وجود دارد.",
  "ccm.transserver.label.css.edit": "پیکربندی سرور جستجوی ابری",
  "ccm.transserver.label.css.serveraddr": "آدرس CSS",
  "ccm.transserverparam.label.cssClusterPass": "اسم رمز",
  "ccm.transserver.label.css": "سرور CSS",
  "ccm.transserver.label.cloudsearchserver.css": "پیکربندی CSS",
  "ccm.transserver.label.cssaddr": "آدرس سرویس CSS",
  "ccm.transserver.label.cssServerPort": "پورت سرویس CSS",
  "ccm.transserverparam.label.login.cssClusterUser": "نام کاربری ورود به سیستم خوشه",
  "ccm.transtask.label.isOpenCssServer": "مشخص می کند که آیا CSS فعال شود یا خیر.",
  "ccm.transtask.label.certTip.proxyCert": "لطفاً یک گواهی سرور پروکسی انتخاب کنید.",
  "ccm.transtask.label.certTip.cssCert": "لطفاً یک گواهی CSS انتخاب کنید.",
  "ccm.transserver.delete.css.confirm": "آیا مطمئن هستید که می خواهید پیکربندی CSS را حذف کنید؟",
  "ccm.transserver.delete.css.success": "پیکربندی CSS با موفقیت حذف شد.",
  "ccm.transserver.delete.css.fail": "اطلاعات پیکربندی CSS حذف نشد.",
  "ccm.agent.ten.timezone": "منطقه زمانی",
  "ccm.agent.ten.timezoneOffset": "افست منطقه زمانی",
  "ccm.agent.ten.daylightSavingTime": "DST",
  "ccm.agent.ten.DSTOffset": "افست DST (دقیقه)",
  "ccm.agent.ten.DSTStartDate": "تاریخ شروع DST",
  "ccm.agent.ten.DSTStartTime": "زمان شروع DST",
  "ccm.agent.ten.DSTEndDate": "تاریخ پایان DST",
  "ccm.agent.ten.DSTEndTime": "زمان پایان DST",
  "ccm.agent.ten.systemdefault": "منطقه زمانی پیش‌فرض",
  "ccm.agent.ten.dstOn": "فعال شد",
  "ccm.agent.ten.dstOff": "غیر فعال شد",
  "ccmanagement.tenantspace.option.month_1": "ژانویه",
  "ccmanagement.tenantspace.option.month_2": "فوریه",
  "ccmanagement.tenantspace.option.month_3": "مارس",
  "ccmanagement.tenantspace.option.month_4": "آوریل",
  "ccmanagement.tenantspace.option.month_5": "می",
  "ccmanagement.tenantspace.option.month_6": "ژوئن",
  "ccmanagement.tenantspace.option.month_7": "جولای",
  "ccmanagement.tenantspace.option.month_8": "مرداد",
  "ccmanagement.tenantspace.option.month_9": "سپتامبر",
  "ccmanagement.tenantspace.option.month_10": "اکتبر",
  "ccmanagement.tenantspace.option.month_11": "نوامبر",
  "ccmanagement.tenantspace.option.month_12": "دسامبر",
  "ccmanagement.tenantspace.option.weeklist_fir": "اولین",
  "ccmanagement.tenantspace.option.weeklist_sec": "دومین",
  "ccmanagement.tenantspace.option.weeklist_tid": "سوم",
  "ccmanagement.tenantspace.option.weeklist_fth": "چهارم",
  "ccmanagement.tenantspace.option.weeklist_fthtolast": "آخرین اما سه",
  "ccmanagement.tenantspace.option.weeklist_tidtolast": "آخرین اما دو",
  "ccmanagement.tenantspace.option.weeklist_sectolast": "آخرین اما یکی",
  "ccmanagement.tenantspace.option.weeklist_firtolast": "آخر",
  "ccmanagement.tenantspace.option.weekday_sun": "یکشنبه",
  "ccmanagement.tenantspace.option.weekday_mon": "دوشنبه",
  "ccmanagement.tenantspace.option.weekday_tue": "سه شنبه",
  "ccmanagement.tenantspace.option.weekday_wed": "چهار شنبه",
  "ccmanagement.tenantspace.option.weekday_thu": "پنج شنبه",
  "ccmanagement.tenantspace.option.weekday_fri": "جمعه",
  "ccmanagement.tenantspace.option.weekday_sat": "شنبه",
  "ccm.taskrecord.label.executeBeginTime": "زمان شروع",
  "ccm.taskrecord.label.executeEndTime": "زمان پایان",
  "ccm.taskrecord.label.executeStatus": "وضعیت وظیفه",
  "ccm.taskrecord.label.executeSuccess": "موفقیت آمیز",
  "ccm.taskrecord.label.executeFail": "ناموفق",
  "ccm.taskrecord.label.executing": "در حال اجرا",
  "ccm.taskrecord.label.reexecute": "دوباره اجرا کنید",
  "ccm.taskrecord.label.choosetask": "وظیفه را انتخاب کنید",
  "ccm.taskrecord.message.reexecutetranstasksuccess": "اجرای مجدد با موفقیت انجام شد.",
  "ccm.taskrecord.message.reexecutetranstaskerror": "اجرای مجدد ناموفق بود.",
  "ccm.transserver.delete.confirm": "آیا مطمئن هستید که می خواهید سرور را حذف کنید؟",
  "ccm.transtask.delete.confirm": "آیا مطمئن هستید که می‌خواهید وظیفه dump و سوابق وظیفه تاریخی را حذف کنید؟",
  "ccm.agent.message.existDualchannelrecAgent": "تشخیص هوشمند و ضبط دو آهنگ را نمی توان به طور همزمان فعال کرد\، در حالی که ضبط دو آهنگ برای برخی از نمایندگان فعال شده است تا به صورت دسته ای اصلاح شوند.",
  "ccm.agent.message.existAIAgent": "تشخیص هوشمند و ضبط دو آهنگ را نمی توان همزمان فعال کرد\، در حالی که تشخیص هوشمند برای برخی از نمایندگان فعال شده است تا به صورت دسته ای اصلاح شوند.",
  "ccm.agent.contact.preemptionCallout": "تماس خروجی از پیش تعیین شده",
  "ccm.agent.sysparamconfig.emailMaxRecipientsNoCheck": "حداکثر تعداد گیرندگانی که توسط نمایندگان پست ارسال می شوند باید بین 1 تا 50 باشد",
  "ccm.systemparam.success.save": "پارامتر با موفقیت ذخیره شد.",
  "ccm.systemparam.error.save": "پارامترها ذخیره نشدند.",
  "ccm.systemparam.success.reset": "پارامتر با موفقیت بازنشانی شد.",
  "ccm.systemparam.error.reset": "تنظیم مجدد پارامترها انجام نشد.",
  "ccm.systemparam.success.refresh": "پارامتر با موفقیت به روز شد.",
  "ccm.systemparam.error.refresh": "بازخوانی پارامترها انجام نشد.",
  "ccm.systemparam.error.notmatchrule": "این پارامتر با قانون تأیید مطابقت ندارد.",
  "ccm.systemparam.error.noparamselected": "لطفا ابتدا یک پارامتر را انتخاب کنید.",
  "ccm.sysparam.config.search": "جستجو",
  "ccm.sysparam.config.reset": "تنظیم مجدد",
  "ccm.sysparam.config.resetAbb": "تنظیم مجدد",
  "ccm.sysparam.config.refresh": "بازخوانی",
  "ccm.sysparam.config.itemname": "نام پارامتر",
  "ccm.sysparam.config.value": "مقدار پارامتر",
  "ccm.sysparam.config.itemdesc": "شرح",
  "ccm.sysparam.config.opterate": "عمل",
  "ccm.sysparam.config.save": "صرفه جویی",
  "ccm.sysparam.config.mod": "ویرایش کنید",
  "ccm.sysparam.config.checkmessage": "شامل کاراکترهای خاص (" < "\، " > "\، " / "، و غیره)",
  "ccm.sysparam.config.itemname.tips": "نام پارامتر را وارد کنید.",
  "ccm.sysparam.config.select.prompt": "نکات",
  "ccm.sysparam.config.select.null": "لطفاً یک پارامتر انتخاب کنید.",
  "ccm.sysparam.config.select.count": "حداکثر 10 پارامتر را می توان انتخاب کرد.",
  "ccm.sysparam.config.item.detail": "اطلاعات پارامتر",
  "ccm.sysparam.config.item.catalog": "نوع پارامتر",
  "ccm.sysparam.config.title": "پارامترهای محیط کاری tenant",
  "ccm.sysparam.success.save": "موفقیت",
  "ccm.sysparam.error.save": "خطاها",
  "ccm.sysparam.config.reset.multiple.error": "پارامترهای زیر قابل تنظیم مجدد نیستند:",
  "ccm.sysparam.config.agent.title": "پارامترهای نماینده",
  "ccm.sysparam.error.validate.inner": "پارامتر اعتبارسنجی ناموفق بود",
  "ccm.sysparam.error.validate.ruleinvalid": "قانون تأیید نامعتبر است",
  "ccm.sysparam.error.validate.ruleregexinvalid": "خطای نحوی قوانین را تأیید کنید",
  "ccm.sysparam.error.validate.notmatchrule": "پارامتر ورودی با قانون تأیید مطابقت ندارد",
  "ccm.sysparam.error.validate.notmatchruleregex": "پارامتر ورودی با بیان منظم قانون تأیید مطابقت ندارد",
  "ccm.sysparam.error.validate.watermarkinfo.notmatchruleregex": "این پارامتر فقط به 0 تا 20 کاراکتر انگلیسی حاوی حروف بزرگ و کوچک\، اعداد یا -_/@ اجازه می دهد.",
  "ccm.sysparam.error.validate.notmatchrulealpha": "لطفا حروف اعداد یا زیرخط ها را وارد کنید و فقط با حروف یا زیرخط شروع کنید",
  "ccm.sysparam.error.validate.notmatchruledigits": "لطفا یک عدد صحیح مثبت معتبر وارد کنید",
  "ccm.sysparam.error.validate.notmatchruleemail": "لطفا یک آدرس ایمیل معتبر وارد کنید",
  "ccm.sysparam.error.validate.notmatchruleip": "لطفاً یک آدرس IPV4 یا IPV6 معتبر وارد کنید",
  "ccm.sysparam.error.validate.notmatchrulemaxlength": "پارامتر ورودی از حداکثر طول بیشتر است",
  "ccm.sysparam.error.validate.notmatchrulemax": "پارامتر ورودی از حداکثر بیشتر است",
  "ccm.sysparam.error.validate.notmatchruleminlength": "پارامتر ورودی کمتر از حداقل طول است",
  "ccm.sysparam.error.validate.notmatchrulemin": "پارامتر ورودی کمتر از حداقل است",
  "ccm.sysparam.error.validate.notmatchrulenumber": "لطفا یک عدد وارد کنید",
  "ccm.sysparam.error.validate.notmatchruleoption": "پارامتر ورودی در محدوده شمارش نیست",
  "ccm.sysparam.error.validate.notmatchrulerangelength": "پارامتر ورودی از حداکثر طول بیشتر است یا کمتر از حداقل طول است",
  "ccm.sysparam.error.validate.notmatchrulerange": "پارامتر ورودی از حداکثر مقدار بیشتر است یا کمتر از مقدار حداقل است",
  "ccm.sysparam.error.validate.notmatchrulerequired": "موارد مورد نیاز",
  "ccm.sysparam.error.validate.notmatchruleurl": "لطفاً یک آدرس URL معتبر وارد کنید",
  "ccm.sysparam.error.validate.ccme.alarmThreshold": "شدت هشدار در متناقض با آستانه هشدار است. آستانه هشدار سخت پایین باید کوچکتر از درگاه هشدار زیاد باشد.",
  "ccm.sysparam.error.validate.ccme.daysAlarmOverSync": "پارامتر ورودی باید کمتر از زمان پیکربندی شده) ۷ روز به طور پیش فرض (برای جا به کار کاربران برون خط به جدول تاریخچه باشد",
  "ccm.calledconfig.message.overmaxnum": "حداکثر مقدار {0} رسیده است.",
  "ccm.calledconfig.message.samename": "همین نام قبلاً وجود دارد.",
  "ccm.calledconfig.message.nullparent": "گره والد وجود ندارد.",
  "ccm.ivrflow.placeholder.ivrflowdesc": "لطفاً شرح جریان تلفن گویا را وارد کنید.",
  "ccm.transserver.label.obsnotinwhite": "عملیات شکست خورد. برای افزودن آدرس سرویس OBS به لیست اعتماد سرور OBS dump با مدیر سیستم تماس بگیرید.",
  "ccm.transserver.label.proxynotinwhite": "عملیات شکست خورد. برای افزودن آدرس سرور پراکسی به لیست اعتماد پروکسی سرور OBS با مدیر سیستم تماس بگیرید.",
  "ccm.urlconfig.message.talkurlnotinwhite": "عملیات شکست خورد. برای افزودن URL تماس بازگشتی به لیست اعتماد تماس بازگشتی، با مدیر سیستم سیستم تماس بگیرید.",
  "ccm.urlconfig.message.relurlnotinwhite": "عملیات شکست خورد. با مدیر سیستم سیستم تماس بگیرید تا URL تماس بازگشتی دستی را به لیست اعتماد بازگشت به تماس اضافه کنید.",
  "ccm.urlconfig.message.urlnotinwhite": "عملیات شکست خورد. برای افزودن URL به لیست اعتماد، با مدیر سیستم سیستم تماس بگیرید.",
  "ccm.speciallist.title.specialList": "لیست ویژه",
  "ccm.speciallist.label.expirationDate": "زمان انقضا",
  "ccm.speciallist.label.reason": "دلیل عضویت",
  "ccm.speciallist.label.operTime": "زمان ایجاد",
  "ccm.speciallist.label.operator": "اپراتور",
  "ccm.speciallist.label.blocklist": "لیست بلاک شده",
  "ccm.speciallist.label.redlist": "لیست قرمز",
  "ccm.speciallist.label.line": "خط",
  "ccm.speciallist.msg.userNo": "شماره مشترک",
  "ccm.speciallist.msg.sltype": "نوع لیست ویژه",
  "ccm.speciallist.msg.success": "موفقیت آمیز",
  "ccm.speciallist.msg.transferToHistory": "انتقال به تاریخچه",
  "ccm.speciallist.msg.modify": "تغییر",
  "ccm.speciallist.msg.delete": "حذف",
  "ccm.speciallist.msg.restores": "بازیابی می کند",
  "ccm.speciallist.msg.successNum": "{limit} موفقیت آمیز بود",
  "ccm.speciallist.msg.operationsuccess": "عملیات با موفقیت انجام شد",
  "ccm.speciallist.msg.error": "خطاها",
  "ccm.speciallist.msg.fail": "ناموفق",
  "ccm.speciallist.msg.errorreason": "علت شکست",
  "ccm.speciallist.msg.errorNum": "{limit} ناموفق بود",
  "ccm.speciallist.msg.crateerror": "افزودن ناموفق بود",
  "ccm.speciallist.msg.modifyerror": "اصلاح نشد",
  "ccm.speciallist.msg.reviewerror": "تأیید ناموفق بود",
  "ccm.speciallist.msg.operationdoing": "سایر داوران تأیید می کنند",
  "ccm.speciallist.msg.deleteyerror": "لیست ویژه حذف نشد",
  "ccm.speciallist.msg.crateerrorreason": "شماره مشترک از قبل وجود دارد",
  "ccm.speciallist.msg.transfererror": "انتقال به تاریخچه انجام نشد",
  "ccm.speciallist.msg.transferout": "تعداد سوابق تاریخی به {0} رسیده است",
  "ccm.speciallist.msg.willdelete": "آیا مطمئن هستید که می خواهید این {limit} مورد را حذف کنید؟",
  "ccm.common.msg.willdelete": "مطمئن هستید که می خواهید رکوردهای {limit} برگزیده را حذف کنید ؟",
  "ccm.speciallist.msg.confirmdelete": "آیا مطمئن هستید که می خواهید لیست ویژه انتخاب شده را حذف کنید؟",
  "ccm.speciallist.msg.confirmdeletehis": "آیا مطمئن هستید که می خواهید تاریخچه لیست ویژه انتخاب شده را حذف کنید؟",
  "ccm.speciallist.msg.selectspeciallist": "لطفاً یک لیست ویژه انتخاب کنید",
  "ccm.speciallist.msg.selectspeciallisthis": "لطفاً ، تاریخچۀ فهرست ویژه ای را برگزینید",
  "ccm.speciallist.msg.endtime": "تاریخ انقضا نمی تواند دیرتر از 19 ژانویه 2038 باشد",
  "ccm.speciallist.msg.endtimeerror": "زمان انقضا باید دیرتر از زمان فعلی باشد",
  "ccm.speciallist.msg.tip": "توجه: هر بار حداکثر 1000 سابقه قابل وارد کردن است",
  "ccm.speciallist.msg.warning": "هشدار",
  "ccm.speciallist.msg.selectonly": "فقط یک لیست خاص را می توان برای اصلاح انتخاب کرد",
  "ccm.speciallist.msg.besureexport": "آیا مطمئن هستید که می خواهید همه داده ها را صادر کنید؟",
  "ccm.speciallist.msg.restoreout": "یک فضای محیط کاری tenant می تواند حداکثر 10000 سابقه لیست ویژه را ذخیره کند",
  "ccm.speciallist.msg.exists": "کاربر انتخاب شده قبلاً در لیست ویژه گنجانده شده است",
  "ccm.speciallist.msg.restoreerror": "بازیابی لیست ویژه انجام نشد",
  "ccm.speciallist.msg.restoresuccess": "مرمت موفقیت آمیز بود",
  "ccm.speciallist.msg.download": "دانلود الگوی ورودی لیست ویژه",
  "ccm.speciallist.msg.import.template": "قالب واردات فهرست ویژه",
  "ccm.speciallist.msg.limit": "تعداد محتویات وارد شده بیش از 1000 است",
  "ccm.speciallist.msg.maxlimit": "حداکثر تعداد سابقه ها از {limit} بیشتر است",
  "ccm.speciallist.msg.size": "حجم فایلی که باید وارد شود بیش از 10 مگابایت است",
  "ccm.speciallist.msg.norecord": "هیچ سابقه ای نمی تواند صادر شود",
  "ccm.speciallist.msg.operationerror": "عملیات شکست خورد",
  "ccm.speciallist.msg.suffix": "پسوند نام فایل نادرست است",
  "ccm.speciallist.msg.maxCountQuery": "حجم داده فعلی خیلی زیاد است. سیستم فقط آخرین سوابق داده {0} را نمایش می دهد.",
  "ccm.speciallist.button.cancel": "لغو کنید",
  "ccm.speciallist.button.finish": "پایان",
  "ccm.speciallist.button.history": "انتقال به تاریخچه",
  "ccm.speciallist.button.modify": "تغییر",
  "ccm.levelMessage.title.modify": "تغییر مدیریت سطح",
  "ccm.speciallist.title.modify": "تغییر فهرست ویژه",
  "ccm.speciallistreminder.button.modify": "تغییر یادآوری فهرست ویژه",
  "ccm.speciallist.button.import": "وارد كردن",
  "ccm.speciallist.title.import": "واردات فهرست ویژه",
  "ccm.speciallist.button.add": "اضافه کردن",
  "ccm.levelMessage.title.add": "ایجاد مدیریت سطح",
  "ccm.speciallist.title.add": "ایجاد فهرست ویژه",
  "ccm.speciallistreminder.button.add": "ایجاد یادآوری فهرست ویژه",
  "ccm.speciallisthis.operlog.downloadData": "صدور تاریخچه فهرست ویژه",
  "ccm.speciallist.operlog.downloadData": "صادر کردن داده های فهرست ویژه",
  "ccm.speciallist.operlog.downloadtemplate": "دانلود الگو",
  "ccm.datatask.checkNum": "مقدار یک عدد صحیح مثبت است.",
  "ccm.agent.ten.cobrowseMaxNumber": "حداکثر تعداد اتصالات همکاری در صفحه وب",
  "ccm.nms.satisfactionservice.buttonNumber": "تعداد کلیدهای سنجش رضایت از 5 عدد بیشتر و از 3 عدد کمتر نیست.",
  "ccm.satisfactionservice.noevaluation": "ارزیابی نشده است",
  "ccm.satisfactionservice.evaluationerror": "ارزیابی ناموفق بود",
  "ccm.asr.create.case": "ایجاد مورد",
  "ccm.asr.create.case.info": "می توانید روی ایجاد پرونده فقط در حین تماس یا پس از پایان تماس کلیک کنید.",
  "ccm.asr.create.case.chat.info": "می توانید روی ایجاد پرونده فقط در حین چت یا پس از پایان چت کلیک کنید.",
  "ccm.asr.create.case.text.info": "ایجاد مورد با یک کلیک پشتیبانی نمی شود زیرا محتوای متنی وجود ندارد.",
  "ccm.ucconfig.message.userId": "شناسه دایرکتوری (محیط کاری tenant).",
  "ccm.ucconfig.message.clientId ": "شناسه برنامه (مشتری).",
  "ccm.ucconfig.message.clientSecret ": "رمز عبور برنامه (مشتری).",
  "ccm.ucconfig.message.title ": "ثبت برنامه ها با پلتفرم Microsoft Identity",
  "ccm.ucconfig.message.integration ": "ادغام تیم های مایکروسافت",
  "ccm.ucconfig.message.info.alert": "لطفاً ، نشانی زیر را برای ثبت نام کاربرد به عنوان پذیرش URI در مایکروسافت رونوشت کنید. نسخه ثبت شده را رونوشت کنید و اطلاعات ثبت زیر را در سیستم پر کنید.",
  "ccm.ucconfig.message.copy": "رونوشت",
  "ccm.ucconfig.message.register": "در حال ثبت اطلاعات کاربرد",
  "ccm.ucconfig.message.required.fields": "حوزه های مورد نیاز",
  "ccm.ucconfig.message.required.limit": "طول نمی تواند ۶۴ نویسه باشد.",
  "ccm.ucconfig.message.urlTitle ": "هدایت URI پیکربندی شده به پلتفرم مایکروسافت برای پذیرش پاسخ های احراز هویت:",
  "ccm.ucconfig.message.success ": "موفقیت",
  "ccm.ucconfig.message.failed ": "شکست",
  "ccm.ucconfig.message.updatesuccess ": "پیکربندی ادغام UC با موفقیت ذخیره شد.",
  "ccm.ucconfig.message.updatefailed ": "پیکربندی ادغام UC ذخیره نشد.",
  "ccm.agent.label.authType": "حالت تأیید اعتبار",
  "ccm.agent.label.authtype.uap": "احراز هویت UAP",
  "ccm.agent.label.authtype.unified": "احراز هویت یکپارچه",
  "ccm.agent.message.synToCcpFailed": "همگام سازی حالت احراز هویت نماینده با cc-provision انجام نشد.",
  "ccm.agent.message.redisFailed": "به دست آوردن قفل Redis ناموفق بود.",
  "ccm.agent.message.synToUapFailed": "همگام سازی حالت احراز هویت نماینده با UAP انجام نشد.",
  "ccm.skillcreate.repeat.fail": "نام صف مهارت از قبل وجود دارد.",
  "ccm.create.case.confirm": "نوع پرونده خالی است. هنگام ایجاد پرونده\، نوع پرونده باید به صورت دستی انتخاب شود. آیا مطمئن هستید که می خواهید ادامه دهید؟",
  "ccm.case.title.selectWorkOrder": "انتخاب دسته دستور کار",
  "ccm.case.label.recommendedWorkOrder0": "TT توصیه شده رده 1",
  "ccm.case.label.recommendedWorkOrder1": "TT توصیه شده رده 2",
  "ccm.case.label.recommendedWorkOrder2": "TT توصیه شده رده 3",
  "ccm.recognition.label.policy.information": "اطلاعات خط مشی",
  "ccm.recognition.label.editPolicy.information": "ویرایش اطلاعات سیاست",
  "ccm.agent.label.callDispatchModel": "حالت تخصیص تماس",
  "ccm.agent.label.restQueueRule": "خط مشی صف استراحت",
  "ccm.agent.label.ifReleaseNoAnswerCall": "تماس‌هایی که برای مدت طولانی پاسخ داده نمی‌شوند را آزاد کنید",
  "ccm.agent.label.ifBusyNoAnswerAgent": "نمایندهانی را تنظیم کنید که برای مدت طولانی به حالت مشغول پاسخ نمی دهند",
  "ccm.agent.label.asyncUserDispatchMode": "شرط بازیابی پیام برون خط",
  "ccm.agent.label.asyncUserDispatchMode.zero": "بر اساس شمارش دسته",
  "ccm.agent.label.asyncUserDispatchMode.one": "با شمارش دسته ها و مهارت",
  "ccm.agent.label.asyncUserDispatchMode.two": "بر اساس شمارش دسته و وضعیت برخط",
  "ccm.agent.label.asyncUserDispatchMode.three": "بر اساس شمارش، مهارت و وضعیت برخط",
  "ccm.agent.label.asyncUserDispatchMode.four": "بر اساس شمارش دسته ها، مامور، مهارت و وضعیت آنلاین",
  "ccm.agent.label.callDispatchModel.best": "بهینه",
  "ccm.agent.label.callDispatchModel.incomingCall": "تخصیص مهارت تماس ورودی قبل از تخصیص مهارت تماس خروجی",
  "ccm.agent.label.callDispatchModel.average": "میانگین",
  "ccm.agent.label.callDispatchModel.manySkills": "نماینده با بیشترین مهارت",
  "ccm.agent.label.restQueueRule.applyFirst": "نماینده ای که ابتدا برای استراحت درخواست می کند",
  "ccm.agent.label.restQueueRule.lessRest": "نماینده با کمترین استراحت در روز جاری",
  "ccm.agent.label.maxTalkTime": "حداکثر مدت زمان تماس",
  "ccm.agent.label.maxTalkTimeRange": "مقدار [1-86400]",
  "ccm.agent.label.busyRate": "استفاده از صف (%)",
  "ccm.agent.label.busyRateRange": "مقدار [0-100]",
  "ccm.agent.label.conditionOfBusyTransfer": "حمل و نقل در شرایط مشغول بودن",
  "ccm.agent.label.deviceUsage": "استفاده از دستگاه (%)",
  "ccm.agent.label.waitTime": "مدت زمان انتظار",
  "ccm.agent.label.deviceUsageThreshold": "آستانه استفاده از دستگاه (%)",
  "ccm.agent.label.deviceUsageThresholdRange": "مقدار [0-100]",
  "ccm.agent.label.waitTimeRange": "مقدار [0-9999]",
  "ccm.agent.label.calcOfMaxCallNum": "روش محاسبه حداکثر داده های صف تماس",
  "ccm.agent.label.fixedValue": "ارزش ثابت",
  "ccm.agent.label.signedinAgentsPercentage": "درصد نمایندگانی که وارد سیستم شده اند",
  "ccm.agent.label.queuingCallsMaxNum": "حداکثر تعداد تماس های صف",
  "ccm.agent.label.scaleFactor": "ضریب مقیاس",
  "ccm.agent.label.queuingCallsMaxNumRange": "مقدار [0-10000]",
  "ccm.agent.label.scaleFactorRange": "مقدار [0-10000]",
  "ccm.agent.label.queuingCallsMaxNumRangeTwo": "مقدار [1-10000]",
  "ccm.agent.label.scaleFactorRangeTwo": "مقدار [1-10000]",
  "ccm.policy.label.updatesucess": "اطلاعات خط مشی با موفقیت به روز شد.",
  "ccm.policy.label.updateVerificationFailed": "تأیید پارامتر درخواست ناموفق است.",
  "ccm.policy.label.updateFailed": "اطلاعات خط‌مشی به‌روزرسانی نشد.",
  "ccm.agent.conditionOfBusyTransfer.error": "پارامترهای شرط CFB را پیکربندی کنید.",
  "ccm.agent.calcOfMaxCallNum.error": "حداکثر تعداد تماس های صف را پیکربندی کنید.",
  "ccm.contact.detail.message.sending.record": "تاریخچه ارسال پیام",
  "ccm.contact.detail.sendmessage": "پیام فرستادن",
  "ccm.contact.detail.receiver": "گیرنده",
  "ccm.contact.detail.sending.time": "زمان ارسال",
  "ccm.contact.detail.view.message.content": "مشاهده محتوای پیام",
  "ccm.contact.detail.message.content": "محتوای پیام",
  "ccm.agent.export.agentinfo.tips": "داده های صادر شده حاوی داده های شخصی است که باید به درستی استفاده و محافظت شود.",
  "ccm.message.center.nocitiction.sending.records.status": "ارسال وضعیت",
  "title.contact.agent.name": "نام حساب سرویس",
  "ccm.contact.tip.not.get.workname": "نام حساب مربوط به نماینده به دست نمی آید\uFF01",
  "ccm.contact.tip.no.workname": "نماینده مربوطه بدست نیامده\uFF01",
  "ccm.common.ajax.searchErr": "خطای جستجو",
  "ccm.dual.call.record.normal.end": "پایان عادی",
  "ccm.dual.call.record.unexpected.end": "پایان غیر منتظره",
  "ccm.dual.call.record.caller": "شماره تماس گیرنده",
  "ccm.dual.call.record.called": "شماره تماس گرفته شده ",
  "ccm.dual.call.record.startTime": "زمان شروع تماس",
  "ccm.dual.call.record.endTime": "زمان پایان تماس",
  "ccm.dual.call.record.agentTalkingTime": "زمان پاسخگویی طرف تماس",
  "ccm.dual.call.record.talkingTime": "به نام زمان پاسخ",
  "ccm.dual.call.record.talkDuration": "مدت زمان تماس",
  "ccm.dual.call.record.reasonCode": "دلیل آزاد کردن",
  "ccm.dual.call.record.option": "عمل",
  "ccm.operlog.dualcallexport.download": "دانلود سوابق تماس دوگانه",
  "ccm.dual.call.record.callCompletionRate": "نرخ تکمیل تماس",
  "ccm.dual.call.record.noMatchingDualCallRecordExists": "هیچ سابقه تماس دوگانه منطبقی وجود ندارد.",
  "ccm.dual.call.record.dualCallType": "نوع تماس دوگانه",
  "ccm.dual.call.record.statistics": "آمار",
  "ccm.sysparam.url.safe.tip": "HTTP ناامن است و ممکن است خطرات امنیتی ایجاد کند.",
  "ccm.agent.contact.multimedia.web": "چند رسانه ای-وب",
  "ccm.agent.contact.multimedia.whatsapp": "چند رسانه ای - Whatsapp",
  "ccm.agent.contact.multimedia.line": "چند رسانه ای-Line",
  "ccm.agent.contact.multimedia.wechat": "چند رسانه ای-WeChat",
  "ccm.agent.contact.multimedia.facebook": "چند رسانه ای-Facebook",
  "ccm.agent.contact.multimedia.twitter": "چند رسانه ای-X (Twitter)",
  "ccm.agent.contact.multimedia.fiveG": "چند رسانه ای-5G RCS",
  "ccm.agent.contact.multimedia.email": "چند رسانه ای-ایمیل",
  "ccm.agent.contact.multimedia.instagram": "چند رسانه ای-Instagram",
  "ccm.agent.contact.multimedia.telegram": "چند رسانه ای-Telegram",
  "ccm.agent.label.answerType4": "شناسه کارمند و صداهای سفارشی شده را گزارش دهید",
  "ccm.agent.label.preVoiceSelect": "صدا قبل از شناسه کارمند",
  "ccm.agent.label.afterVoiceSelect": "شناسه صدا بعد از کارمند",
  "ccm.agent.resetskillrecord.adjustbusinessaccount": "تنظیم حساب خدمات",
  "ccm.agent.resetskillrecord.adjustedbusinessaccount": "حساب خدمات تنظیم شده",
  "ccm.agent.resetskillrecord.adjustagentworkno": "تنظیم شناسۀ مامور",
  "ccm.agent.resetskillrecord.adjustedagentworkno": "شناسۀ مأمور تنظیم شده",
  "ccm.agent.resetskillrecord.adjusttime": "تنظیم زمان",
  "ccm.agent.resetskillrecord.skillbeforeadjust": "صف مهارت قبل از تنظیم",
  "ccm.agent.resetskillrecord.skillafteradjust": "صف مهارت پس از تنظیم",
  "ccm.agent.resetskillrecord.adjustresult": "نتیجه را تنظیم کنید",
  "ccm.callreason.label.createrelatecase": "موارد مرتبط",
  "SM.ORGSTAFF.LABEL.ORGANIZATION": "واحد سازمان",
  "ccm.idauth.title.idauthconfig": "فرآیند احراز هویت",
  "ccm.idauth.title.name": "نام حالت احراز هویت",
  "ccm.idauth.title.ivr": "فرآیند احراز هویت",
  "ccm.idauth.title.create": "افزودن فرآیند احراز هویت",
  "ccm.idauth.title.edit": "اصلاح فرآیند احراز هویت",
  "ccm.idauth.create.error.name": "حالت احراز هویت وجود دارد.",
  "ccm.idauth.create.error.limit": "حداکثر پنج فرآیند احراز هویت را می توان اضافه کرد.",
  "ccm.idauth.title.code": "کد حالت احراز هویت",
  "ccm.idauth.create.error.format": "کد حالت احراز هویت می تواند فقط شامل اعداد، حروف و زیرخط (_) باشد.",
  "ccm.idauth.create.error.length": "طول کد حالت احراز هویت از حد بالایی بیشتر است.",
  "ccmanagement.satisfactionconfig.acceptdate.timeerror.laterThanEndTime": "تاریخ شروع باید زودتر از تاریخ پایان باشد.",
  "ccmanagement.satisfactionconfig.calltime.timeerror.laterThanEndTime": "حداقل مدت تماس باید کمتر از حداکثر مدت زمان تماس باشد.",
  "ccmanagement.satisfactionconfig.acceptdata.timeVerification": "تاریخ را دیرتر از زمان فعلی و زودتر از 19-01-2038 انتخاب کنید.",
  "ccmanagement.satisfaction.strategy.fail.unknown": "ذخیره نشد.",
  "ccmanagement.satisfaction.strategy.fail.calltime.inputnull": "حداقل یا حداکثر مدت تماس نمی تواند خالی باشد.",
  "ccmanagement.satisfaction.strategy.fail.calltime.inputlength": "حداقل یا حداکثر مدت تماس می تواند حداکثر 12 رقم داشته باشد.",
  "ccmanagement.satisfaction.strategy.fail.calltime.inputnumber": "حداقل یا حداکثر مدت تماس باید یک عدد باشد.",
  "ccmanagement.satisfaction.strategy.fail.calltime.inputnagetive": "حداقل یا حداکثر مدت تماس نمی تواند کمتر از 0 باشد.",
  "ccmanagement.satisfaction.strategy.fail.calltime.startafterend": "حداقل مدت تماس باید کمتر از حداکثر مدت زمان تماس باشد.",
  "ccmanagement.satisfaction.strategy.fail.calltime.overlimit": "حداکثر 100 خط مشی مدت تماس را می توان ذخیره کرد.",
  "ccmanagement.satisfaction.strategy.fail.accepttime.inputnull": "زمان شروع یا پایان نمی تواند خالی باشد.",
  "ccmanagement.satisfaction.strategy.fail.accepttime.inputlength": "طول زمان شروع یا پایان نمی تواند از حد بالایی تجاوز کند.",
  "ccmanagement.satisfaction.strategy.fail.accepttime.inputnumber": "زمان شروع یا پایان باید یک عدد باشد.",
  "ccmanagement.satisfaction.strategy.fail.accepttime.inputparse": "زمان شروع یا پایان در الگو نادرستی است.",
  "ccmanagement.satisfaction.strategy.fail.accepttime.startafterend": "زمان شروع باید زودتر از زمان پایان باشد.",
  "ccmanagement.satisfaction.strategy.fail.accepttime.overlimit": "حداکثر 100 خط مشی زمان رسیدگی را می توان ذخیره کرد.",
  "ccmanagement.satisfaction.strategy.fail.acceptdate.inputnull": "تاریخ شروع یا پایان نمی تواند خالی باشد.",
  "ccmanagement.satisfaction.strategy.fail.acceptdate.inputnumber": "تاریخ شروع یا پایان در الگو نادرست است.",
  "ccmanagement.satisfaction.strategy.fail.acceptdate.inputparse": "تاریخ شروع یا پایان در الگو نادرست است.",
  "ccmanagement.satisfaction.strategy.fail.acceptdate.startafterend": "تاریخ شروع باید زودتر از تاریخ پایان باشد.",
  "ccmanagement.satisfaction.strategy.fail.acceptdate.overlimit": "حداکثر 100 خط مشی تاریخ رسیدگی را می توان ذخیره کرد.",
  "ccmanagement.satisfaction.strategy.calltime.minvalue": "حداقل مدت زمان تماس",
  "ccmanagement.satisfaction.strategy.calltime.maxvalue": "حداکثر مدت زمان تماس",
  "ccmanagement.satisfaction.strategy.save": "صرفه جویی",
  "ccmanagement.satisfaction.strategy.delete": "حذف",
  "ccmanagement.satisfaction.strategy.calltime.interval": "محدوده مدت تماس",
  "ccmanagement.satisfaction.strategy.operation": "عمل",
  "ccmanagement.satisfaction.strategy.calltime.lengthlimit": "حداقل یا حداکثر مدت تماس می تواند حداکثر 12 رقم داشته باشد.",
  "ccmanagement.satisfaction.strategy.deleteselect": "آیا مطمئن هستید که می خواهید خط مشی های انتخاب شده را حذف کنید؟",
  "ccmanagement.satisfaction.strategy.calltime.second": "s",
  "ccmanagement.satisfaction.strategy.addsuccess": "صرفه جویی با موفقیت انجام شد.",
  "ccmanagement.satisfaction.strategy.success": "موفقیت",
  "ccmanagement.satisfaction.strategy.fail": "شکست",
  "ccmanagement.satisfaction.strategy.saveerror": "شکست",
  "ccmanagement.satisfaction.strategy.deletesuccess": "حذف با موفقیت انجام شد.",
  "ccmanagement.satisfaction.strategy.deletefail": "حذف نشد.",
  "ccmanagement.satisfaction.strategy.accepttime.timeStart": "زمان شروع",
  "ccmanagement.satisfaction.strategy.accepttime.accepttime": "مدیریت زمان",
  "ccmanagement.satisfaction.strategy.accepttime.hour": "ساعت",
  "ccmanagement.satisfaction.strategy.accepttime.minute": "دقیقه",
  "ccmanagement.satisfaction.strategy.accepttime.timeEnd": "زمان پایان",
  "ccmanagement.satisfaction.strategy.hourformatvalidate": "ساعت در الگو نادرست است.",
  "ccmanagement.satisfaction.strategy.hourvalidate": "ساعت نمی تواند بیشتر از 24 باشد.",
  "ccmanagement.satisfaction.strategy.acceptdate.dateStart": "تاریخ شروع",
  "ccmanagement.satisfaction.strategy.acceptdate.dateEnd": "تاریخ پایان",
  "ccmanagement.satisfaction.strategy.acceptdate.startdate": "تاریخ شروع",
  "ccmanagement.satisfaction.strategy.acceptdate.enddate": "تاریخ پایان",
  "ccmanagement.satisfaction.strategy.minutevalidate": "فرمت صورتجلسه نادرست است.",
  "ccm.satisfactionconfig.message.duplicateconfig": "اولویت هر خط مشی برای یک کانال باید منحصر به فرد باشد.",
  "ccm.satisfactionconfig.message.numberlimit": "حداکثر 100 پیکربندی کانال نظرسنجی رضایت در یک فضای مستاجر مجاز است.",
  "ccm.satisfactionconfig.message.channelused": "گروه خط مشی توسط یک کانال ارجاع داده می شود و نمی توان آن را حذف کرد.",
  "ccm.satisfactionconfig.message.smstitle": "پیامک",
  "ccm.satisfactionconfig.message.smscloud": "پیامک ابری هواوی",
  "ccm.satisfactionconfig.message.smsgateway": "دروازه پیامک",
  "ccm.satisfactionconfig.message.smschannel": "کانال Push پیامک",
  "ccm.satisfactionconfig.message.smstemplate": "الگو پیامک",
  "ccm.satisfactionconfig.message.validtime": "دوره پاسخ معتبر (دقیقه)",
  "ccm.satisfactionconfig.message.strategygroup": "گروه خط مشی نظرسنجی رضایتمندی",
  "ccm.satisfactionconfig.message.strategy": "خط مشی نظرسنجی رضایتمندی",
  "ccm.satisfactionconfig.message.groupcreate": "برای اضافه کردن",
  "ccm.satisfactionconfig.message.groupbatchdelete": "حذف دسته ای",
  "ccm.satisfactionconfig.message.strategygroupsrv": "پیکربندی کانال خط مشی نظرسنجی رضایتمندی",
  "ccm.satisfactionconfig.message.strategygroupid": "شناسه گروه خط مشی",
  "ccm.satisfactionconfig.message.strategygroupname": "نام گروه خط مشی",
  "ccm.satisfactionconfig.title.strategygroup": "ایجاد یک گروه سیاست",
  "ccm.satisfactionconfig.message.strategygroupdesc": "توضیحات گروه خط مشی",
  "ccm.satisfactionconfig.message.strategygroupmember": "خط مشی",
  "ccm.satisfactionconfig.message.strategygroupmemconfig": "پیکربندی خط مشی",
  "ccm.satisfactionconfig.message.strategygroupmemchoose": "سیاست ها را انتخاب کنید.",
  "ccm.satisfactionconfig.message.strategychanneltitle": "سیاست کانال را پیکربندی کنید",
  "ccm.satisfactionconfig.add.strategychanneltitle": "ایجاد یک سیاست رضایت کانال",
  "ccm.satisfactionconfig.update.strategychanneltitle": "ویرایش استراتژی رضایت مجرا",
  "ccm.satisfactionconfig.message.strategychannelid": "شناسه خط مشی کانال",
  "ccm.satisfactionconfig.message.channelstrategyconfig": "خط مشی نظرسنجی رضایت کانال",
  "ccm.satisfactionconfig.message.channeltype": "نوع کانال",
  "ccm.satisfactionconfig.message.channelname": "کانال",
  "ccm.satisfactionconfig.message.grouppriority": "اولویت",
  "ccm.satisfactionconfig.message.surveytype": "نوع نظرسنجی",
  "ccm.satisfactionconfig.message.surveystrategygroup": "سیاست نظرسنجی",
  "ccm.satisfactionconfig.message.audiochannel": "کانال صوتی یا تصویری",
  "ccm.satisfactionconfig.message.webchannel": "کانال چند رسانه ای وب",
  "ccm.satisfactionconfig.message.audiofeedbacktype": "صدای تلفن گویا",
  "ccm.satisfactionconfig.message.smsfeedbacktype": "پیامک",
  "ccm.satisfactionconfig.message.webfeedbacktype": "وب",
  "ccm.satisfactionconfig.message.nofeedbacktype": "بدون نظرسنجی",
  "ccm.satisfactionconfig.message.operate": "عمل",
  "ccm.satisfactionconfig.message.delete": "حذف",
  "ccm.satisfactionconfig.message.update": "ویرایش کنید",
  "ccm.satisfactionconfig.message.comfirmtitle": "تایید",
  "ccm.satisfactionconfig.message.comfirm": "آیا مطمئن هستید که می خواهید گروه خط مشی را حذف کنید؟",
  "ccm.satisfactionconfig.message.choosestrategy": "*استراتژی نظرسنجی رضایتمندی را انتخاب کنید",
  "ccm.satisfactionconfig.message.validatename": "نام گروه خط مشی نمی تواند خالی یا تکراری باشد.",
  "ccm.satisfactionconfig.message.calltypeout": "تماس خروجی",
  "ccm.satisfactionconfig.message.calltypein": "تماس ورودی",
  "ccm.satisfactionconfig.message.prioritytip": "*مقدار کوچکتر نشان دهنده اولویت بالاتر است. خط مشی با اولویت بالاتر ترجیحاً برای اجرای نظرسنجی مطابقت دارد.",
  "ccm.satisfactionconfig.message.accepttime": "زمان رسیدگی",
  "ccm.satisfactionconfig.message.acceptdate": "تاریخ رسیدگی",
  "ccm.satisfactionconfig.message.acceptagent": "نماینده رسیدگی کننده",
  "ccm.satisfactionconfig.message.callskill": "صف مهارت",
  "ccm.satisfactionconfig.message.calltype": "نوع تماس",
  "ccm.satisfactionconfig.message.calltime": "مدت زمان تماس",
  "ccm.satisfactionconfig.message.creategroup": "گروه خط مشی را اضافه کنید",
  "ccm.satisfactionconfig.message.configstrategydata": "پیکربندی داده های خط مشی",
  "ccm.satisfactionconfig.message.memberstrategychoose": "خط مشی های موجود",
  "ccm.satisfactionconfig.message.strategychoosed": "خط مشی های منتخب",
  "ccm.satisfactionconfig.message.memberstrategyconfig": "پیکربندی اعضای گروه استراتژی",
  "ccm.satisfactionconfig.message.validatemaxnumber": "حداکثر 100 گروه خط مشی را می توان ایجاد کرد.",
  "ccm.note.config.agent.ring.setUp": "پیکربندی اطلاعات سریع",
  "ccm.note.config.agent.page.ring": "آهنگ های زنگ",
  "ccm.note.config.agent.page.ring.audition": "آزمون",
  "ccm.note.config.agent.page.ring.choose": "یک آهنگ زنگ را انتخاب کنید.",
  "ccm.note.config.agent.page.ring.title": "این پیکربندی فقط زمانی اعمال می‌شود که صفحه پنهان باشد\، مانند زمانی که مرورگر کوچک شده باشد\، یا زمانی که صفحه در پس‌زمینه باشد.",
  "ccm.note.config.agent.page.ring.tipTitle": "اگر کادر محاوره‌ای پیکربندی شده باشد، کادر محاوره‌ای فقط پس از پیکربندی دستی نماینده معتبر است.",
  "ccm.note.config.agent.page.ring.warn": "به شما توصیه می شود برای جلوگیری از همپوشانی آهنگ زنگ با آهنگ زنگ پیکربندی شده روی تلفن، یک تست تماس انجام دهید.",
  "ccm.note.config.agent.page.windowTip": "یادآوری کادر گفتگوی پاپ آپ",
  "ccm.note.config.agent.page.ring.select": "آهنگ زنگ را انتخاب کنید",
  "ccm.note.config.agent.page.ring.setUp": "پیکربندی اعلان پیام صفحه",
  "ccm.note.config.agent.page.ring.voice": "اعلان تماس ورودی صوتی و تصویری",
  "ccm.note.config.agent.page.ring.media": "اعلان تماس ورودی چند رسانه ای",
  "ccm.note.config.agent.page.ring.newMedia": "اعلان پیام چندرسانه ای جدید",
  "ccm.contact.customer.config.label": "حوزه اطلاعات تماس مشتری ",
  "ccm.contact.customer.config.type": "حوزه داده های اطلاعات تماس مشتری  ",
  "ccm.contact.customer.config.visible": "قابل رویت",
  "ccm.contact.customer.config.writeBack": "تاریخچه تماس را بنویسید",
  "ccm.contact.customer.config.style": "سبک نمایش",
  "ccm.contact.customer.config.key": "کلید داده",
  "ccm.contact.customer.config.value": "مقدار تاریخ",
  "ccm.contact.customer.config.foregroundColor": "رنگ پیش زمینه",
  "ccm.contact.customer.config.backgroundColor": "رنگ پس زمینه",
  "ccm.contact.customer.config.fonts": "فونت",
  "ccm.contact.customer.config.regular": "معمولی",
  "ccm.contact.customer.config.bold": "پررنگ",
  "ccm.contact.customer.config.italic": "کج",
  "ccm.contact.customer.config.underline": "زیر خط کشیده شده",
  "ccm.contact.customer.config.preview": "پیش نمایش",
  "ccm.contact.customer.config.datasourceType": "نوع منبع داده",
  "ccm.contact.customer.config.datasource.callData": "داده های مرتبط با تماس",
  "ccm.contact.customer.config.datasource.msgDataset": "مجموعه داده اطلاعاتی",
  "ccm.contact.customer.config.datasource.customData": "تاریخ محلی",
  "ccm.contact.customer.config.datasource.objId": "Data Source Object",
  "ccm.contact.customer.config.datasource.objName": "فیلد نگاشت Data Source Object ",
  "ccm.contact.customer.config.writeBackField": "زمینه بازنویسی سابقه تماس گسترده",
  "ccm.contact.customer.config.writeBackFieldError": "زمینه بازنویسی سابقه تماس گسترده قابل استفاده نیست.",
  "ccm.contact.customer.config.dataItemCodeError": "شناسه فقره داده نادرست است.",
  "ccm.contact.customer.config.dataItemError": "نام یا شناسه data item باید منحصر به فرد باشد.",
  "ccm.contact.customer.config.referenced": "data item توسط حوزه اطلاعات تماس با مشتری ارجاع داده شده است.",
  "ccm.contact.record.data.referenced": "آيتم داده ها با آيتم داده هاي ضبط تماس ارجاع داده شده است.",
  "ccm.verifyidentity.noMoreThan30Days": "محدوده زمانی نمی تواند بیش از 30 روز باشد.",
  "ccm.verifyidentity.loginId": "حساب تجاری",
  "ccm.verifyidentity.workNo": "شناسه نماینده",
  "ccm.verifyidentity.callId": "SN تماس",
  "ccm.verifyidentity.userId": "شماره مشتری",
  "ccm.verifyidentity.verifyName": "حالت تأیید اعتبار",
  "ccm.verifyidentity.verifyTime": "زمان احراز هویت",
  "ccm.verifyidentity.verifyResult": "نتیجه احراز هویت",
  "ccm.verifyidentity.NOTRETURN": "برگردانده نشده است",
  "ccm.verifyidentity.SUCCESS": "قبول شدن",
  "ccm.verifyidentity.FAIL": "شکست",
  "ccm.verifyidentity.ERROR": "خطا",
  "ccm.verifyidentity.fail.unknown": "جستجو ناموفق بود",
  "ccm.verifyidentity.fail.WORKNOPARSEERROR": "شناسه نماینده باید یک عدد باشد.",
  "ccm.verifyidentity.fail.CALLIDPARSEERROR": "SN تماس باید یک عدد باشد.",
  "ccm.verifyidentity.fail.USERIDPARSEERROR": "شماره مشتری نمی تواند شامل کاراکترهای خاص باشد.",
  "ccm.verifyidentity.fail.VERIFYRESULTPARSEERROR": "پارامتر نتیجه احراز هویت نادرست است.",
  "ccm.verifyidentity.fail.TIMEPARAMNULLERROR": "محدوده زمانی جستجو نمی تواند خالی باشد.",
  "ccm.verifyidentity.fail.INPUTNULLERROR": "پارامترهای جستجو نمی توانند خالی باشند.",
  "ccm.verifyidentity.fail.VERIFYTIMERANGEERROR": "محدوده زمانی جستجو نادرست است.",
  "ccm.verifyidentity.fail.LIMITOVERLIMIT": "مقدار پارامتر جستجو صفحه بندی از حد بالایی بیشتر است.",
  "ccm.verifyidentity.label": "احراز هویت",
  "ccm.transserver.label.cssnotinwhite": "عملیات شکست خورد. برای افزودن آدرس سرویس CSS \"به لیست اعتماد سرور CSS، با مدیر سیستم تماس بگیرید.",
  "ccm.transserver.label.cssproxynotinwhite": "عملیات شکست خورد. برای افزودن سرور پراکسی \"آدرس به لیست اعتماد پراکسی سرور CSS، با مدیر سیستم تماس بگیرید.",
  "ccm.whatsapp.template.message.id": "الگوی شناسه پیام ",
  "ccm.whatsapp.template.message.name": "نام پیام",
  "ccm.whatsapp.whatsApp.channel": "کانال WhatsApp",
  "ccm.whatsapp.whatsApp.message.template": "الگو پیام WhatsApp",
  "ccm.whatsapp.select.whatsApp.message.template": "لطفاً یک الگو پیام WhatsApp را انتخاب کنید.",
  "ccm.whatsapp.select.whatsApp.channel": "لطفا یک کانال WhatsApp را انتخاب کنید.",
  "ccm.agent.mobileagent.syserror": "شناسایی هوشمند فعال نشد. برای بررسی تنظیمات محیط با مدیر سیستم تماس بگیرید.",
  "ccm.agent.contact.info": "اطلاعات سند",
  "ccm.agent.contact.name": "نام سند",
  "ccm.agent.contact.status": "وضعیت سند",
  "ccm.agent.contact.signaturedate": "تاریخ امضا",
  "ccm.agent.contact.updatedate": "تاریخ به روز رسانی",
  "ccm.agent.contact.invalid": "باطل شدن",
  "ccm.agent.contact.preview": "پیش نمایش",
  "ccm.contact.label.unsigned": "بدون امضا",
  "ccm.contact.label.signed": "امضاء شده",
  "ccm.contact.label.inactive": "منقضی شده",
  "ccm.contact.msg.inactivesuccess": "ابطال با موفقیت انجام شد.",
  "ccm.contact.msg.dataverification": "تأیید پارامتر درخواست انجام نشد.",
  "ccm.contact.msg.dataEmpty": "اطلاعات عملیات در AICC وجود ندارد.",
  "ccm.operlog.contactfile.download": "دریافت فایل",
  "ccm.contract.management.confirm": "سند حاوی داده های حساس است. آیا مطمئن هستید که میخواهید ادامه دهید؟",
  "ccm.contract.management.confirmtitle": "تأیید عمل",
  "ccm.contract.management.invalid.confirm": "آیا مطمئن هستید که می خواهید این سند را باطل کنید؟",
  "ccm.agent.label.notreply ": "نمی توان پاسخ داد",
  "ccm.agent.contact.itacall": "تماس دو طرفه ITA",
  "ccm.chatbot.pop.title": "انتخاب ربات",
  "ccm.chatbot.pop.table.botname": "نام ربات",
  "ccm.chatbot.label.assist": "دستیار ربات",
  "ccm.chatbot.invalid.empty": "دستیار ربات نمی تواند خالی باشد.",
  "ccm.chatbot.invalid.bot": "این ربات نامعتبر است",
  "ccm.chatbot.query.name.fail": "نام ربات به دست نیامد.",
  "ccm.chatbot.query.list.fail": "دریافت لیست ربات ناموفق بود.",
  "ccm.transtask.label.expirationDuration": "دوره نگهداری فایل Dump",
  "ccm.agent.speciallist.validityPeriod": "دوره کارایی",
  "ccm.agent.speciallist.begintime": "زمان موثر",
  "ccm.agent.speciallist.endtime": "زمان انقضا",
  "ccm.agent.speciallist.levelName": "سطح لیست ویژه",
  "ccm.speciallist.label.startDate": "زمان موثر",
  "ccm.speciallist.msg.necessary": "شماره کاربری و نوع لیست ویژه الزامی است.",
  "ccm.agent.speciallist.addList": "به سوابق آزار و اذیت بپیوندید",
  "ccm.speciallist.msg.levelName": "مرحله",
  "ccm.speciallevel.speciallist.use": "در دسترس",
  "ccm.speciallevel.speciallist.unuse": "غیر قابل دسترسی",
  "ccm.speciallevel.speciallist.restrictMode": "حالت محدودیت",
  "ccm.speciallevel.speciallist.restrictHours": "مدت زمان محدودیت (ساعت)",
  "ccm.speciallevel.speciallist.remark": "تذکر ",
  "ccm.speciallevel.speciallist.levelName": "نام سطح",
  "ccm.speciallevel.speciallist.levelMessage": "مدیریت سطح",
  "ccm.speciallevel.speciallist.level": "مرحله",
  "ccm.speciallevel.tip.start": "آیا مطمئن هستید که می خواهید آن را فعال کنید؟",
  "ccm.speciallevel.tip.stop": "آیا مطمئن هستید که می خواهید آن را غیرفعال کنید؟",
  "ccm.speciallist.msg.userNum": "شماره کاربر",
  "ccm.speciallist.msg.userno.error": "فرمت شماره مشترک نادرست است.",
  "ccm.speciallist.msg.dateerror": "باید زمان موثر و زمان انقضا را وارد کنید.",
  "ccm.agent.title.specialListReminder": "یادآوری لیست ویژه",
  "ccm.speciallist.msg.level": "سطح لیست ویژه",
  "ccm.speciallist.msg.typeName": "نوع لیست خاص",
  "ccm.speciallist.label.msgContent": "پیام یادآوری",
  "ccm.speciallist.msg.empty": "پیام یادآوری نمی تواند پوچ باشد.",
  "ccm.speciallist.msg.overlimit": "طول پیام یادآوری بیش از 256 کاراکتر است.",
  "ccm.speciallist.msg.selectslreminder": "یک یادآور لیست ویژه را انتخاب کنید.",
  "ccm.speciallist.msg.selectonlyslreminder": "می تواند تنها یک یادآور لیست ویژه را تغییر دهد.",
  "ccm.speciallist.msg.deleteerror": "یادآوری لیست ویژه حذف نشد.",
  "ccm.speciallist.msg.typeidempty": "نوع لیست خاص نمی تواند خالی باشد.",
  "ccm.speciallist.msg.specialchar": "پیام یادآوری نمی تواند حاوی نویسه های خاص باشد.",
  "ccm.speciallist.msg.alreadyexist": "قبلاً یک یادآور لیست ویژه از همان نوع و سطح دارد.",
  "ccm.agent.message.selectMaxAgents": "شما می توانید حداکثر 20 را در یک زمان پردازش کنید.",
  "ccm.speciallist.msg.approve": "تصویب",
  "ccm.speciallist.label.approve": "توضیحات تأیید",
  "ccm.speciallist.poptitle.approve": "در حال تایید اسناد درز و فشار",
  "ccm.speciallist.poptitle.view": "مشاهده ی سوابق وی",
  "ccm.speciallist.title.approve": "اطلاعات تأیید شده",
  "ccm.speciallist.msg.view": "چشم انداز",
  "ccm.speciallist.msg.applyTimeFrom": "زمان آغاز کاربرد",
  "ccm.speciallist.msg.applyTimeTo": "زمان پایان کاربرد",
  "ccm.speciallist.msg.applyTime": "زمان درخواست",
  "ccm.botherlist.label.contactrecorddetail": "اطلاعات تماس",
  "ccm.botherlist.label.comment": "نظرات",
  "ccm.botherlist.label.approveStatus.toApprove": "مورد تایید قرار گیرد",
  "ccm.botherlist.label.approveStatus.approved": "تایید شده",
  "ccm.botherlist.label.approveStatus.rejected": "رد شد",
  "ccm.botherlist.label.approveComment.toApprove": "موافقت کرد",
  "ccm.botherlist.label.approveComment.approved": "رد کردن",
  "ccm.agent.addbother.success": "موفق به ایجاد رکورد مزاحمت شد.",
  "ccm.agent.addbother.fail": "افزودن ناموفق بود.",
  "ccm.agent.contact.joinreason": "دلیل عضویت",
  "ccm.agent.contact.callserialno": "شماره سریال تماس",
  "ccm.agent.contact.contactinfo": "اطلاعات تماس",
  "ccm.agent.contact.createbother": "ایجاد Bother Record",
  "ccm.speciallist.tip.timeVerification": "زمانی را دیرتر از زمان فعلی و زودتر از 19-01-2038 انتخاب کنید.",
  "ccm.speciallist.msg.datecompareerror": "زمان انقضا نمی تواند دیرتر از زمان موثر باشد.",
  "ccm.speciallevel.msg.selectspecialLevel": "لطفا سطح خاصی را انتخاب کنید",
  "ccm.botherlist.label.approveComment.agree": "موافقت کردن",
  "ccm.botherlist.label.approveComment.reject": "رد کردن",
  "ccm.agent.contact.callinandcallout": "تماس های ورودی و خروجی",
  "ccm.speciallevel.msg.deleteyerror": "سطح فهرست ویژه حذف نشد.",
  "ccm.agent.addbother.msg.emailOrPhoneValidate": "قالب نادرست.",
  "ccm.ccagent.management.advanced.search": "جستجوی پیشرفته",
  "ccm.ccagent.management.batch.operation": "عملیات دسته ای",
  "ccm.ccagent.management.additions": "اضافات",
  "ccm.ccagent.management.default.options": "گزینه های پیش فرض",
  "ccm.ccagent.management.cannot.exceed.25": "این تعداد نباید از 25 کاراکتر تجاوز کند.",
  "ccm.ivrvoice.play.error": "در حال بارگیری استثنا منبع\، لطفاً پیکربندی مربوطه را بررسی کنید.",
  "ccm.contact.details.tab.title.audio": "صدا",
  "ccm.contact.details.tab.title.video": "ویدیو",
  "ccm.agent.contact.transfercontent": "محتوای ASR",
  "ccm.contact.placeholder.searchCustomerNo": "شمارۀ آشنا",
  "ccm.agent.label.oneday": "1 روز",
  "ccm.agent.label.threedays": "3 روز",
  "ccm.agent.label.sevendays": "7 روز",
  "ccm.agent.label.thirtydays": "30 روز",
  "ccm.contactitemconfig.message.noBaseData": "هیچ داده اساسی در دسترس نیست",
  "ccm.label.downloadAttachment.confirm": "آیا مطمئن هستید که می خواهید پیوست انتخاب شده را دانلود کنید؟",
  "ccm.contact.detail.talk.content.seachtips": "نتایج جستجو: {number}",
  "ccm.contact.detail.talk.content.not.support": "متن جستجو نمی تواند حاوی\"/() باشد",
  "ccm.contact.detail.talk.content.seach.remark": "فقط محتوای چت متنی نمایش داده شده در این صفحه قابل جستجو است",
  "ccm.contact.detail.talk.content.seach.remark.emaile": "فقط پیام هایی که در صفحه جستجو نمایش داده شده اند قابل جستجو هستند. پس از تکمیل جستجو، فقط پیام‌های منطبق نمایش داده می‌شوند.",
  "ccm.download.passwd.validator.specialStr": "فقط نویسه‌های ویژه زیر مجاز هستند: _{'@'}{'%'}",
  "ccm.agent.label.skillType": "نوع مهارت",
  "ccm.agent.label.selectSkillGroup": "برگزیدن گروه مهارت",
  "ccm.skill.label.defaultskill": "صف مهارت پیش فرض",
  "ccm.agent.leavemessage.overtime": "زمان پردازش تموم شد. لطفا پنجره ی بالا رو دوباره باز کن",
  "ccm.skillgroup.message.nameValidateFail": "گروه مهارتی نمی تواند حاوی نویسه های ویژه زیر باشد: [<>\"()]&=;,",
  "ccm.skillgroup.message.namePrefixSuffix": "لا يمكن للمجموعة المختصة أن تبدأ أو تنتهي بمسافة",
  "ccm.skillgroup.message.nameLimit": "نام گروه مهارت بسیار طولانی",
  "ccm.skillgroup.message.nameExistence": "نام گروه مهارت از قبل وجود دارد",
  "ccm.notifition.sendmessage.smsconfignull":"پیکربندی پیام SMS خالی است. انتخاب پیکربندی مرکز دستیابی ۳۲ پیکربندی SMS برای پیکربندی اطلاعات SMS.",

  "iolp.label.basicInfo": "المعلومات الأساسية",
  "iolp.label.workflow": "سير العمل",
  "iolp.workflow.node.title": "تم تعيين خصائص العقدة بنجاح.",
  "iolp.workflow.line.title": "تم تعيين خصائص الاتصال بنجاح.",
  "iolp.workflow.node.label.name": "الاسم",
  "iolp.workflow.node.label.nodeType": "نوع العقدة",
  "iolp.workflow.node.label.nodeId": "معرف العقدة",
  "iolp.workflow.node.label.executeType": "نوع التنفيذ",
  "iolp.workflow.node.label.autoNodeInfo": "الرقم المطابق لنتيجة العقدة التلقائية",
  "iolp.workflow.node.label.executeContent": "محتويات التنفيذ",
  "iolp.workflow.node.label.explain": "عيار الأعمال",
  "iolp.workflow.node.label.associateKbs": "المعرفة المرتبطة",
  "iolp.workflow.node.label.associateBusiness": "خدمت انجمن پیوند را",
  "iolp.workflow.node.label.hintTextInfo": "الوصف",
  "iolp.workflow.node.label.sms": "قالب الرسائل القصيرة",
  "iolp.workflow.node.label.associateJs": "ميزات js ذات الصلة",
  "iolp.workflow.nodetype.andsign": "عقدة المهمة",
  "iolp.workflow.nodetype.fork": "عقدة القرار",
  "iolp.workflow.nodetype.andjoin": "مزيج",
  "iolp.workflow.executetype.common": "نوع عادي",
  "iolp.workflow.executetype.SMS": "إرسال الرسائل القصيرة",
  "iolp.workflow.executetype.email": "إرسال البريد",
  "iolp.workflow.executetype.IVR": "التحويل إلى تدفق IVR التلقائي",
  "iolp.workflow.executetype.auto": "المهام التلقائية",
  "iolp.workflow.executetype.subFlow": "التحويل إلى عملية فرعية أخرى",
  "iolp.workflow.executetype.case": "إنشاء تذكرة",
  "iolp.configur.service.label.basicInfo": "المعلومات الأساسية",
  "iolp.configur.service.label.workflow": "فرآیند هدایت شده",
  "iolp.configur.service.label.name": "الاسم",
  "iolp.configur.service.label.workflow.name": "اسم العملية",
  "iolp.configur.service.label.directory.name": "اسم الفئة",
  "iolp.configur.service.label.serviceCode": "رمز الخدمة",
  "iolp.configur.service.label.upperLevelServiceCode": "رمز خدمة المستوى الأعلى",
  "iolp.configur.service.label.imageUrl": "عنوان الصورة",
  "iolp.configur.service.label.customizedPageUrl": "تخصيص عنوان الصفحة",
  "iolp.configur.service.label.customizedPageContainerId": "معرّف حاوية الصفحة المخصصة",
  "iolp.configur.servicelabel.shortcutButtonPage": "عنوان صفحة زر الاختصار",
  "iolp.configur.service.label.shortcutButtonContainerId": "معرّف حاوية صفحة زر الاختصار",
  "iolp.configur.service.label.search.tips": "الرجاء إدخال اسم",
  "iolp.configur.service.add": "جديد",
  "iolp.configur.service.operate.edit": "تحرير",
  "iolp.configur.service.operate.delete": "حذف",
  "iolp.configur.service.tips.customizedPageUrl": "مثال: CustomerInfoDemo.html",
  "iolp.configur.service.add.select.title": "اسم الفئة المحددة",
  "iolp.configur.service.add.parent.title": "اسم الفئة الأصلية",
  "iolp.configur.service.dialog.root.directory.title": "لا توجد فئة أصلية",
  "iolp.configur.service.add.operate.title": "نوع جديد",
  "iolp.configur.service.dialog.add.directory.title": "إنشاء فئة إرشادات الخدمة",
  "iolp.configur.service.dialog.modify.directory.title": "تحرير فئة إرشادات الخدمة",
  "iolp.configur.service.dialog.add.workflow.title": "إنشاء عملية توجيه الخدمة",
  "iolp.configur.service.dialog.modify.workflow.title": "تحرير عملية توجيه الخدمة",
  "iolp.configur.servicelabel.label.serialNumber": "الرقم التسلسلي",
  "iolp.configur.servicelabel.label.status": "الحالة",
  "iolp.configur.servicelabel.label.description": "الوصف",
  "iolp.configur.servicelabel.button.saveDraft": "حفظ كمسودة",
  "iolp.configur.servicelabel.status.draft": "مسودة",
  "iolp.configur.servicelabel.button.publish": "تم الافراج عن",
  "iolp.configur.servicelabel.common.tips.error": "خطأ",
  "iolp.configur.servicelabel.common.tips.success": "النجاح",
  "iolp.tree.tips": "لا يمكنك إضافة أي عقدة تحت العقدة الطرفية.",
  "iolp.configur.servicelabel.save.successful": "تم الحفظ بنجاح",
  "iolp.configur.servicelabel.release.successful": "تم النشر بنجاح.",
  "iolp.configur.servicelabel.save.failed": "فشل الحفظ",
  "iolp.configur.servicelabel.release.failed": "منتشر نشد.",
  "iolp.configur.servicelabel.contact.administrator": "فشل إنشاء رمز الخدمة. الرجاء الاتصال بالمسؤول.",
  "iolp.configur.servicelabel.select.node": "الرجاء تحديد عقدة",
  "iolp.configur.servicelabel.confirm.delete": "حذف",
  "iolp.configur.servicelabel.confirm.deleteAll": "هل أنت متأكد من رغبتك في حذف العقدة الحالية وعُقدها الفرعية؟",
  "iolp.configur.servicelabel.confirm.deleteCurrent": "هل أنت متأكد من رغبتك في حذف العقدة الحالية؟",
  "iolp.configur.servicelabel.delete.successful": "تم الحذف بنجاح",
  "iolp.configur.servicelabel.delete.failed": "فشل الحذف",
  "iolp.configur.servicelabel.tips.info": "نصائح",
  "iolp.workflow.label.graphZoomOut": "بزرگ نمایی",
  "iolp.workflow.label.graphZoomIn": "بزرگ نمایی",
  "iolp.workflow.label.previous": "لغو",
  "iolp.workflow.label.next": "بازیابی",
  "iolp.workflow.label.cleanGraph": "پاک کردن پرده",
  "iolp.workflow.label.taskElement": "عنصر نمودار تابع",
  "iolp.workflow.label.decisionElement": "عنصر نمودار شاخه ای",
  "iolp.workflow.label.operation": "العملية",
  "iolp.workflow.label.information": "معلومات الارتباط",
  "iolp.workflow.label.unmatchedFlow": "خدمت متناظر را نمی توان تطبیق داد",
  "iolp.workflow.button.restart": "إعادة تشغيل العملية",
  "iolp.workflow.button.hideFlowChart": "إخفاء المخطط الانسيابي",
  "iolp.workflow.button.showFlowChart": "عرض المخطط الانسيابي",
  "iolp.workflow.label.currentNodeName": "اسم العقدة الحالية",
  "iolp.workflow.label.customizeInfo": "المعلومات المخصصة",
  "iolp.workflow.label.flowInfo": "معلومات العمليات التجارية",
  "iolp.workflow.button.kbs": "قاعدة المعارف",
  "iolp.workflow.button.service": "معالجة الأعمال",
  "iolp.workflow.title.name": "فرآیند تجاری",
  "iolp.configur.service.label.service.title": "فئة الخدمة",
  "iolp.run.service.label.customizedPage": "اطلاعات سفارشی",
  "iolp.run.service.label.overview": "خلاصه فرایندها",
  "iolp.run.service.label.nodeName": "نام پرونده:",
  "iolp.process.utilization.efficiency.analysis":"تحلیل کارایی کارایی فرایند",
  "iolp.flow.number.of.contacts":"تعداد تماسها) قطعه ها (",
  "iolp.flow.first.solution.rate":"نرخ راه حل (%)",
  "iolp.flow.please.select.time.before.today":"لطفاً ، قبل از امروز ، زمانی برگزینید",
  "iolp.flow.the.interval.validate":"فاصله زماني يک روز يا سه تا سي روز است",
  "iolp.flow.analysis.date.time":"تاریخ/ زمان",

  "ccm.transfer.number.pop.create.title": "در حال ایجاد یک عدد پیش سو به خارجی",
  "ccm.transfer.number.pop.modify.title": "ویرایش شمارۀ ارجاعی به خارجی",
  "ccm.transfer.number.pop.delete.title": "حذف",
  "ccm.transfer.number.pop.delete.message": "مطمئن هستید که می خواهید شمارۀ خارجی برگزیده را حذف کنید ؟",
  "ccm.transfer.number.search.number": "لطفاً ، شماره را وارد کنيد",
  "ccm.transfer.number.search.name": "لطفاً ، یک نام وارد کن",
  "ccm.transfer.number.label.index": "نمایه",
  "ccm.transfer.number.label.number": "شماره",
  "ccm.transfer.number.label.name": "نام",
  "ccm.transfer.number.label.flag": "نشانۀ جلوۀ پرچم",
  "ccm.transfer.number.label.flag.yes": "بله.",
  "ccm.transfer.number.label.flag.no": "نه.",

  "ccm.sendwhatsapp.content.too.long": "محتواي قالب خيلي طولانيه.",
  "ccm.sendwhatsapp.body.too.long": "محتواي بدن خيلي طولانيه",
  "agentconsole.ccnotification.recipient.validate.error": "هر عدد تنها می تواند دارای ارقام و بیشینه ۲۰ عدد باشد.",
  "ccm.notifition.sendmessage.largerthanlimit":"تعداد دريافت کننده ها از حداکثر {n} بيشتره",
  "ccm.agent.callreason.must": "دلیل تماس مورد نیاز است.",
  "iolp.workflow.efficiency.analysis":"تحلیل بازدهی",
  "ccm.agent.message.searchSkillQueueName": "نام صف مهارت",
  "ccm.empty.template": "قالب وجود ندارد. قالب اطلاعیه را بررسی کنید.",
  "ccm.empty.variables": "متغیر قالب وجود ندارد. قالب اطلاعیه را بررسی کنید.",
  "ccm.no.meeting.variables": "متغیر قالب حاوی متغیر URL ملاقات نیست. قالب اخطار را بررسی کنید.",
  "ccm.agent.meeting.failed": "مامور کنفرانسی ارائه نمی کنه",
  "ccm.agent.link.failed": "عامل موفق تولید پیوند SMS نمی شود.",
  "ccm.agent.meeting.receiver.check.error": "تعداد گیرنده ها نمی تواند بیش از ۱ شود.",
  "ccm.agent.contact.timeouthungup": "خاموش زمان",
  "ccm.agent.contact.skilltimeouthungup": "انتقال زمان زیادی به خط مهارت",
  "ccm.agent.leavemessage.opinionempty": "اظهار نظرهاي داد و بيدادي اجباريه.",
  "ccm.sms.meeting.link": "پیامک پیوند کنفرانس",
  "ccm.agent.sms.meeting.gateway.commonerror": "کنفرانس ایجاد نشد. کد خطا: {n}",
  "ccm.agent.sms.meeting.gateway.error": "کنفرانس ایجاد نشد. کد خطا: {n}. کنفرانس را در حالت بی صدا، نگه داشتن یا چند حزبی ایجاد نکنید.",
  "ccm.contactRecordDetail.notSetTraceLogError": "شیار فرآیند یافت نشد. لطفاً ، قبل از مشاهده شیار فرآیند ثبت تماس ، تنظیمات ردیابی را اضافه کنید",
  "ccm.agent.callreason.modify.exceeds.limit": "خرابی در تغییر دلیل تماس. فاصله از زمان اعتبار دلیل تماس بیشتره.",
  "ccm.label.appcube.switch": "Page int\u00E9gr\u00E9e \u00E0 AppCube",
  "ccm.label.appcube.app": "S\u00E9lectionner une application",
  "ccm.label.appcube.menu": "S\u00E9lectionner le menu",
  "ccm.contactitemconfig.label.data.item.sequence.number.seq": "ترتیب حوزه",
  "ccm.contactitemconfig.label.data.item.add": "ایجاد حوزۀ ضبط تماس",
  "ccm.contactitemconfig.label.data.item.edit": "ویرایش حوزۀ ضبط تماس",
  "ccm.contactitemconfig.label.data.item.tips": "رابط مبدأ منبع باید روی صفحه مدیریت واسط کاربری پیکربندی شود. با سرپرست عملیات سیستم تماس بگیرید.",
  "ccm.contactitemconfig.label.data.item.basic.item": "حوزۀ بسط یافته پایه ای",
  "ccm.contactitemconfig.label.data.item.custom.item": "حوزۀ بسط تعریف شدۀ کاربر",
  "ccm.contactitemconfig.label.dataInfo.item.add": "ایجاد فقرۀ داده",
  "ccm.contactitemconfig.label.dataInfo.item.edit": "ویرایش فقرۀ داده",
  "ccm.transtask.label.intelligentInspectionConfig":"تنظیمات جدا کردن دادۀ بازرسی AI",
  "ccm.validate.portLengthError":"محدوده پورت باید 1-65535 باشد",
  "ccm.resource.dump.server.add":"ایجاد یک کارساز تخلیه منبع",
  "ccm.resource.dump.server.edit":"ویرایش کارساز تخلیه منبع",
  "ccm.resource.dump.server.view":"مشاهدۀ کارساز تخلیه منبع",
  "ccm.resource.dump.css.add":"یک سرور جستجوی ابری ایجاد کنید",
  "ccm.resource.dump.css.edit":"سرور جستجوی ابری را ویرایش کنید",
  "ccm.resource.dump.css.view":"سرور جستجوی ابری را مشاهده کنید",
  "ccm.resource.dump.task.add":"وظیفه دامپ منابع را ایجاد کنید",
  "ccm.resource.dump.task.edit":"یک وظیفه تخلیه منابع را ویرایش کنید",
  "ccm.resource.dump.task.view":"مشاهده یک کار تخلیه منابع",
  "ccm.transtask.label.intelligentInspectionDay":"مدت زمان تخلیه داده های بازرسی هوش مصنوعی",
  "ccm.transtask.css.server.num":"تعداد کارساز جستجوی ابر نمی تواند از ۱ تجاوز کند.",
  "ccm.contact.customer.config.num":"تعداد ستون اطلاعات مشتری نمی تواند از ۱۰ تجاوز کند.",
  "ccm.export.password.error.tips": "لطفا رمز عبور استفاده شده برای تولید فایل فشرده را وارد کنید. رمز عبور فشرده باید شامل حروف، اعداد، کاراکترهای خاص باشد {\'@\'}#$%^&*+{\'|\'}=~!?,.:;-_\'\"(){\'{\'}{\'}\'}[]/><",
  "ccm.export.password.error.message": "فقط نویسه‌های ویژه زیر مجاز هستند: {\'@\'}#$%^&*+{\'|\'}=~!?,.:;-_\'\"(){\'{\'}{\'}\'}[]/><",
  "ccm.export.table.taskTime": "زمان تکلیف",
  "ccm.export.table.taskStatus": "وضعیت تکلیف",
  "ccm.agent.contact.multimedia.sms":"چند پیام کوتاه",
  "ccm.agent.label.skill":"مهارت",
  "ccm.agent.button.randomRestSoftPhonePwd":"بازنشانی اسم رمز تلفن نرم",
  "ccm.agent.label.skillGroup":"Skill Queue Group",
  "ccm.agent.message.searchSkillGroupName":"نام گروه صف مهارت",
  "ccm.skill.label.skillGroupName":"نام گروه صف مهارت",
  "ccm.agent.button.restSoftPhonePwd.remark":"نکته: اگر گذرواژه های نرم تلفن عامل را به اسم رمزهای تصادفی در دسته های\ t\ تنظیم کنید ، کارگزارها باید اسم رمزهای تلفن نرم خودشان را قبل از استفاده از تابع تلفن نرم (نشانی) تنظیم کنند. سرپرست همچنین می تواند گذرواژه ی نرمت را در لیست فشار دهد تا به صورت دستی اسم رمز را برای مامور تغییر دهد.",
  "ccm.agent.management.exporting.agent.information":"صادرات اطلاعات مامور",
  "ccm.agent.clear.message.clearcommonerror":"خرابی در لغو پیکربندی. سرویس در دسترس نیست.",
  "ccm.contactitemconfig.label.dataInfo.item.name.exist": "نام فقره داده از قبل وجود دارد.",
  "ccm.agent.button.downloadvrc": "بارگیری VRC",
  "ccm.dual.call.callback.url.config":"پیکربندی نشانی وب فراخوانی بازگشتی",
  "ccm.dual.call.callback.url.auth":"تأیید هویت نشانی وب",
  "ccm.dual.call.callback.url.authType":"فراخوانی حالت احراز هویت نشانی وب",
  "ccm.dual.call.callback.url.ita":"پیکربندی پارامتری TA",
  "ccm.dual.call.callback.url.xNumberType":"حالت تخصیص عدد X",
  "ccm.agent.management.select.agent":"انتخاب مامورها با شناسه ی کارمند",
  'ccm.password.length.limit.min': 'رمزش خیلی کوتاهه وارد کردن ۸- ۱۲ نویسه',
  'ccm.password.length.limit.max': 'رمزش خيلي طولانيه وارد کردن ۸- ۱۲ نویسه',
  "ccm.agent.operation.SCREENMONITOR": "مانیتورینگ صفحه نمایش",
  'ccm.mobile.agent.front.add': 'ایجاد یک عامل موبایل',
  'ccm.mobile.agent.front.edit': 'ویرایش عامل تلفن همراه',
  'ccm.mobile.agent.front.checkNum': 'لطفاً ، حداقل یک عدد را برگزینید.',
  'ccm.mobile.agent.front.maxNum': 'عدد نميتونه از 15 بيشتر بشه',
  'ccm.contactitemconfig.label.contactData.add': 'ایجاد یک دادۀ ضبط آشنا',
  'ccm.contactitemconfig.label.contactData.edit': 'ویرایش دادۀ ضبط آشنا',
  'ccm.agent.button.downloading':'در حال بارگیری...',
  "ccm.callcenterinstanceslist.vcallcenterDeatil.specialStrValidateFailed": "نام مرکز فراخوانی نمی تواند حاوی نویسه های ویژه باشد",
  'ccm.common.validation.numbers': 'لطفاً ، فقط ارقام را وارد کنيد',
  'ccm.common.validation.click.number': 'لطفاً روی شماره کلیک کنید',
  'ccm.ccagent.agent.weight.tips': 'مقدار بزرگتر، اولويت بالاتري رو نشون ميده وقتی چندین عامل بیکار وجود دارد سیستم ترجیحا تماس های دریافتی را به عامل با وزن بالاتر تخصیص می دهد.',
  'ccm.ccagent.skill.weight.tips': 'مقدار بزرگتر، اولویت بالاتر را نشان می دهد. وقتی تماس هایی در صف های متعددی وجود دارد، سیستم تماس ها را در صف با وزن بالاتر به عامل بیکار اختصاص می دهد.',
  "ccm.speciallist.tip.time.max": "2038-01-19لطفاً ، زمان زودتر از انقضای پروژه را برگزینید",
  "ccm.speciallist.tip.startTime.min": "زمان موثر باید دیرتر از زمان فعلی باشد.",
  'ccm.ccagent.batch.modify.special.character.verification': 'نمی توان آن را نگه داشته’',
  'ccm.resource.dump.obs.cert.warn.tip': 'Veuillez contacter le personnel d\'exploitation et d\'entretien pour importer le certificat de confiance OBS dans la bibliothèque de certificats.',
  "ccm.audiovideoworkbench.mood" : "احساسات",
  "ccm.audiovideoworkbench.intention" : "قصد",
  "ccm.audiovideoworkbench.complain" : "تمایل به شکایت",
  "ccm.audiovideoworkbench.aiswitch" : "مدل زبان بزرگ",
  "ccm.audiovideoworkbench.airecommendation" : "توصیه های هوشمند",
}