import robot from '@oifde/router/modules/robot'
import flow from '@oifde/router/modules/flow'
import resourceMgr from '@oifde/router/modules/resourceMgr'
import sysMgr from '@oifde/router/modules/sysMgr'
import sysAdmin from '@oifde/router/modules/sysAdmin'
import {objDeepCopy} from '@oifde/utils/common'
import i18n from '@/lang/index'

const $t = i18n.global.t

export const allRouterMap = [
  ...robot,
  ...flow,
  ...resourceMgr,
  ...sysMgr,
  ...sysAdmin
]

// 动态路由在进入oifde后才能处理，因此需要先给出所有path
// 否则路由初始为空时，在oifde之外直接跳转子路由会无法加载
const oifdeRoutes = {
  pageWithBackHeader: [
    {
      path: '/oifdeNodeDetail', // 节点详情
      name: 'oifdeNodeDetail',
      component: () => import('@/views/oifde/views/ivrJourney/nodeDetail/nodeDetail.vue')
    },
    {
      path: '/subFlowAnalysis', // 子流程分析
      name: 'subFlowAnalysis',
      component: () => import('@/views/oifde/views/ivrJourney/flowAnalysis/subFlowAnalysis.vue')
    },
    {
      path: '/oifdeFlowAlarm', // 流程告警
      name: 'oifdeFlowAlarm',
      component: () => import('@/views/oifde/views/ivrJourney/ivrOverview/flowAlarm.vue')
    },
  ],
  pageWithSideNav: [
    {
      path: '/oifdeAudioVideoResource', // 音视频资源
      name: '8008001',
      title: $t('oifde.route.audioVideoResource'),
      component: () => import('@/views/oifde/views/audioVideoResource/audioVideoResource.vue')
    },
  ],
  default: [
    {
      path: '/aiccOifde', // IVR流程配置 & IVR流程管理
      name: 'oifde',
      component: () => import(/* webpackChunkName: "aicc-oifde" */'@oifde/App.vue'),
      children: objDeepCopy(allRouterMap)
    },
    {
      path: '/aiccTuc', // 语义理解服务 & 语义理解管理
      name: 'tuc',
      component: () => import('@/views/oifde/views/tucPage/tucPage.vue'),
    },
    {
      path: '/oifdeTransferRecord', // 流程转接记录
      name: '6018002',
      title: $t('oifde.route.transferRecord'),
      component: () => import('@/views/oifde/views/transferRecord/transferRecord.vue')
    },
    {
      path: '/oifdeDiagram', // 流程画布
      name: 'oifdeDiagram',
      component: () => import('@/views/oifde/views/diagram/diagram.vue')
    },
    {
      path: '/oifdeIvrOverview', // IVR概览
      name: '8014101',
      title: $t('oifde.route.ivrOverview'),
      component: () => import('@/views/oifde/views/ivrJourney/ivrOverview/ivrOverview.vue')
    },
    {
      path: '/oifdeFlowAnalysis', // 流程分析
      name: '8014102',
      title: $t('oifde.route.flowAnalysis'),
      component: () => import('@/views/oifde/views/ivrJourney/flowAnalysis/flowAnalysis.vue')
    },
    {
      path: '/oifdeIndicatorAnalysis', // 指标分析
      name: '8014103',
      title: $t('oifde.route.indicatorAnalysis'),
      component: () => import('@/views/oifde/views/ivrJourney/indicatorAnalysis/indicatorAnalysis.vue')
    },
    {
      path: '/oifdeVersionComparisonAndAnalysis', // 版本对比分析
      name: '8014104',
      title: $t('oifde.route.versionComparisonAndAnalysis'),
      component: () => import('@/views/oifde/views/ivrJourney/versionComparisonAndAnalysis/versionComparisonAndAnalysis.vue')
    },
    {
      path: '/oifdeOptimizationSuggestions', // 业务优化建议
      name: '8014105',
      title: $t('oifde.route.optimizationSuggestions'),
      component: () => import('@/views/oifde/views/ivrJourney/optimizationSuggestions/optimizationSuggestions.vue')
    },
    {
      path: '/oifdeAlarmEvent', // 告警事件
      name: '8014106',
      title: $t('oifde.route.alarmEvent'),
      component: () => import('@/views/oifde/views/ivrJourney/alarmEvent/alarmEvent.vue')
    },
    {
      path: '/oifdeAlarmConfig', // 业务预警条件配置
      name: '8014107',
      title: $t('oifde.route.alarmEvent'),
      component: () => import('@/views/oifde/views/ivrJourney/alarmConfig/alarmConfig.vue')
    },
    {
      path: '/oifdeSysAudio',
      name: '6002005',
      title: $t('oifde.route.sysAudio'),
      component: () => import('@/views/oifde/views/audioVideoResource/audioVideoReview.vue')
    },
    {
      path: '/aiccOifde/voiceTemplate/edit2dDigitalHumanImg', // 编辑2D数字人形象
      name: 'oifdeDigitalHumanImg',
      title: $t('oifde.route.2dDigitalHumanImg'),
      component: () => import('@oifde/views/digitalHumanImg/editDigitalHumanImg.vue')
    }
  ],
  pageWithSelfTab: []
}

export default oifdeRoutes
