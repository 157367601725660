import cookieUtils from "@/utils/cookie"
import {setNotEmptyParam} from "@oifde/utils/common";
import odfsRequest from "@oifde/utils/odfsRequest";

export function querySessionLog(query) {
    const data = {
        tenantId: cookieUtils.getCookie('tenant_space_id'),
    }
    setNotEmptyParam(query, data);
    return odfsRequest({
        url: '/oifde/rest/querysessionlogs',
        method: 'post',
        data: data
    })
}

export function queryTraceLog(query) {
    const data = {
        tenantId: cookieUtils.getCookie('tenant_space_id'),
    }
    setNotEmptyParam(query, data);
    return odfsRequest({
        url: '/oifde/rest/querytracelogs',
        method: 'post',
        data: data
    })
}