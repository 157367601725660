<template>
    <div>
        <sweet-dialog v-model="dialogVisible" :title="$t('isales.data.title.importData')" width="616px" class="aicc-dialog-limit-height">
            <sweet-form :model="form" ref="form" label-width="auto" :label-position="labelPosition" :rules="formRules">
                <!--导入方式-->
                <sweet-form-item :label="$t('isales.management.message.importType')" required>
                    <sweet-radio-group v-model="form.importType">
                        <sweet-radio v-for="item in IMPORT_TYPE" v-bind:key="item.value"
                                     :label=item.value :value="item.value" @change="handleRadioChange()">
                            {{ item.label }}
                        </sweet-radio>
                    </sweet-radio-group>
                </sweet-form-item>
                <!--导入模式-->
                <sweet-form-item :label="$t('isales.management.message.importMode')" required v-if="marketingMode !=3" style="margin-bottom: 6px">
                    <sweet-radio-group v-model="form.importMode">
                        <sweet-radio v-for="item in IMPORT_MODE" v-bind:key="item.value"
                                     :label=item.value :value="item.value">
                            {{ item.label }}
                        </sweet-radio>
                    </sweet-radio-group>
                </sweet-form-item>
                <sweet-form-item label=" " style="margin-top: -10px" v-if="marketingMode !=3">
                    <span style="font-size: 12px;color: #707070;">{{ $t('isales.import.coverMode.tips') }}</span>
                </sweet-form-item>
                <!--服务器-->
                <sweet-form-item :label="$t('isales.management.message.import.server')" v-if="this.form.importType===2" prop="serverIp" required>
                    <sweet-select v-model="form.serverIp" @change="selectServerIp" :fit-input-width="true" :show-resize="false">
                        <sweet-option v-for="item in serverIpList" :key="item.key" :label="item.value" :value="item.key"/>
                    </sweet-select>
                </sweet-form-item>
                <!--删除导入OBS服务器上数据文件-->
                <sweet-form-item v-if="this.selectServerType===2" >
                    <template #label>
                        <sweet-tooltip class="box-item" effect="light" :content="$t('isales.file.message.isDeleteFileTips')" :placement="topPlacement">
                            <div class="aicc-align--absolute-left">
                                <span>{{ $t('isales.file.message.isDeleteFile') }}</span>
                                <sweet-icon><warning style="transform: rotateX(180deg)" /></sweet-icon>
                            </div>
                        </sweet-tooltip>
                    </template>
                    <sweet-select v-model="form.needDelete" :fit-input-width="true" :show-resize="false">
                        <sweet-option v-for="item in NEED_DELETE_ITEMS" :key="item.key" :label="item.value"
                                      :value="item.key"/>
                    </sweet-select>
                </sweet-form-item>
                <!--文件地址-->
                <sweet-form-item v-if="this.form.importType===2 && this.selectServerType!=2" prop="filePath">
                    <template #label>
                        <sweet-tooltip class="box-item" effect="light" :content="$t('isales.file.message.FilePathTips')" :placement="topPlacement">
                            <div class="aicc-align--absolute-left">
                                <span>{{ $t('isales.management.message.import.filePath') }}</span>
                                <sweet-icon><warning style="transform: rotateX(180deg)" /></sweet-icon>
                            </div>
                        </sweet-tooltip>
                    </template>
                    <sweet-input v-model="form.filePath" autocomplete="off" />
                </sweet-form-item>
                <!--文件地址-->
                <sweet-form-item :label="$t('isales.management.message.import.filePath')" v-if="this.form.importType===2 && this.selectServerType==2" prop="filePath" required>
                    <sweet-input v-model="form.filePath" autocomplete="off"/>
                </sweet-form-item>
                <!--文件名-->
                <sweet-form-item :label="$t('isales.management.message.import.fileName')" v-if="form.importType===2" prop="fileName" required>
                    <sweet-input v-model="form.fileName" autocomplete="off" />
                </sweet-form-item>
                <!--数据是否过滤-->
                <sweet-form-item :label="$t('isales.management.message.filter')" required v-if="marketingMode === 1">
                    <sweet-select v-model="form.dataFilter" @change="dataFilterChange()" :fit-input-width="true" :show-resize="false">
                        <sweet-option v-for="item in DATA_FILTER" :key="item.key" :label="item.value"
                                      :value="item.key" />
                    </sweet-select>
                    <sweet-button text type="primary" @click="filterRuleDialogVisible = true" v-if="this.form.dataFilter===1">{{
                        $t('isales.import.button.configProp') }}
                    </sweet-button>
                </sweet-form-item>
                <!--数据导入顺序-->
                <sweet-form-item :label="$t('isales.management.message.importOrder')" v-if="form.importType===1 && marketingMode === 1" required>
                    <sweet-select v-model="form.dataOrder" :fit-input-width="true" :show-resize="false">
                        <sweet-option v-for="item in DATA_ORDER" :key="item.key" :label="item.value" :value="item.key"/>
                    </sweet-select>
                </sweet-form-item>
                <!--解析方式-->
                <sweet-form-item :label="$t('isales.management.message.import.analyseMode')" required>
                    <sweet-radio-group v-model="form.analyseMode">
                        <sweet-radio v-for="item in ANALYSE_MODE" v-bind:key="item.value"
                                     :label=item.value :value="item.value" @change="analyseModeChange()" v-if="marketingMode !=3">
                            {{ item.label }}
                        </sweet-radio>
                        <!--黑名单导入-->
                        <sweet-radio v-bind:key="ANALYSE_MODE[0].value" :label=ANALYSE_MODE[0].value :value="ANALYSE_MODE[0].value" @change="analyseModeChange()" v-if="marketingMode ==3">
                            {{ ANALYSE_MODE[0].label }}
                        </sweet-radio>
                    </sweet-radio-group>
                </sweet-form-item>
                <!--映射规则-->
                <sweet-form-item v-if="form.analyseMode===2" :label="$t('isales.management.message.import.mapRule')" prop="mapRule" required>
                    <sweet-select v-model="form.mapRule" :fit-input-width="true" :show-resize="false">
                        <sweet-option v-for="item in this.mapRuleList" :key="item.key" :label="item.value" :value="item.key"/>
                    </sweet-select>
                </sweet-form-item>
                <!--下载外呼数据模板-->
                <div v-if="form.analyseMode===1" style="background: #FBFBFB;padding: 12px;margin: 1rem 0">
                  <sweet-form-item style="margin-bottom: 0">
                    <template #label>
                      <sweet-tooltip :content="$t('isales.import.message.template.show')" placement="top">
                        <div class="aicc-align--absolute-left">
                          <span style="margin-right: 5px">{{ $t('isales.taskmanagement.title.importData.template') }}</span>
                          <sweet-icon><warning style="transform: rotateX(180deg);" /></sweet-icon>
                        </div>
                      </sweet-tooltip>
                    </template>
                      <div @click="download()" >
                        <div class="aicc-align--absolute-center">
                          <i class="sweetui-icon-excel-f" style="font-size: 16px;color:#5ACC9B;margin-right: 5px"></i>
                          <span v-if="marketingMode === 1" class="download-template-file-button">{{ $t('isales.management.message.download') }}</span>
                          <span v-if="marketingMode === 2" class="download-template-file-button">{{ $t('isales.management.message.notification.download') }}</span>
                          <span v-if="marketingMode === 3" class="download-template-file-button">{{ $t('isales.management.message.black.download') }}</span>
                          <i class="sweetui-icon-download-l" style="font-size: 14px;margin-left: 5px"></i>
                        </div>
                      </div>
                  </sweet-form-item>
                </div>
                <!--上传-->
                <sweet-form-item v-if="form.importType===1" :label="$t('isales.taskmanagement.title.importData.upload')" required>
                    <sweet-upload style="width: 100%;margin-bottom: -18px"
                            ref="uploadForm"
                            v-loading="fileFlag"
                            action=""
                            :file-list="fileList"
                            :auto-upload="false"
                            accept=".csv"
                            :show-file-list="true"
                            :on-change="fileUploadChange"
                            :multiple="false">
                        <template #trigger>
                            <sweet-button style="height: 32px;background: #F5F5F5;border: none">
                              <div class="aicc-align--absolute-center">
                                <sweet-icon class="avatar-uploader-icon"><Plus/></sweet-icon>
                                <span>{{$t('isales.taskmanagement.title.importData.selectFile')}}</span>
                              </div>
                            </sweet-button>
                        </template>
                    </sweet-upload>
                    <span style="font-size: 12px;color: #707070;">{{ dataNumLimit }}</span>
                </sweet-form-item>
                <sweet-form-item v-if="form.importType===2" label=" " style="margin-top: -10px">
                    <span style="font-size: 12px;color: #707070;">{{ dataNumLimit }}</span>
                </sweet-form-item>
            </sweet-form>
            <template #footer>
                <span class="dialog-footer">
                    <sweet-button @click="dialogVisible = false">{{ $t('isales.common.title.cancel') }}</sweet-button>
                    <sweet-button v-if = "form.importType===1" type="primary" @click="submitFile">{{ $t('isales.common.title.confirm') }}</sweet-button>
                    <sweet-button v-if = "form.importType===2" type="primary" @click="submitServer">{{ $t('isales.common.title.confirm') }}</sweet-button>
                </span>
            </template>
        </sweet-dialog>
    </div>
    <filterRule v-if="filterRuleDialogVisible" v-model:show="filterRuleDialogVisible" v-model:filter-rule-string="filterRuleString" @filterRuleConfirm="filterRuleConfirm"></filterRule>
</template>

<script>
    import {
        DATA_FILTER,
        IMPORT_TYPE,
        IMPORT_MODE,
        DATA_ORDER,
        ANALYSE_MODE,
        NEED_DELETE_ITEMS
    } from '@/views/isales/views/taskManagement/enum';
    import {
        downloadTemplateUrl, importServerData,
        importTaskData,
        queryCmsEnvironment,
        queryDataByTaskId,
        queryFileServerList,
        queryMapRuleList,
        queryTaskInfo
    } from '@/views/isales/api/importCallData';
    import {aiccDownload, aiccSetImportParam} from "@/utils/aiccUploadAndDownload";
    import filterRule from '@/views/isales/components/OutboundCallData/filterRule.vue'
    import AiccElMessage from "@/utils/el-message";

    export default {
        name: 'ImportCallData',
        components: {
            filterRule
        },
        props: {
            show: {
                type: Boolean,
                default: false
            },
            marketingMode : {
                type: Number,
                default: 1
            },
            taskId : {
                type: String,
                default: '',
            },
            taskType : {
                type: String,
                default: '',
            },
            policyType : {
                type: String,
                default: '',
            },
          mediaType : {
            type: String,
            default: '',
          },
        },
        data() {
            return {
              topPlacement: window.isRTL ? 'top-end' : 'top-start',
              labelPosition: window.isRTL ? 'right' : 'left',
                DATA_FILTER,
                IMPORT_TYPE,
                IMPORT_MODE,
                DATA_ORDER,
                ANALYSE_MODE,
                NEED_DELETE_ITEMS,
                filterRuleDialogVisible:false,
                form: {
                    importType: 1,
                    importMode: 1,
                    dataFilter: 2,
                    dataOrder: 1,
                    analyseMode: 1,
                    mapRule:'',
                    serverIp:'',
                },
                dataNumLimit:$t('isales.import.message.template.dataNum', [50000]),
                mapRuleList:[],
                serverIpList:[],
                cmsEnvironment:'',
                // 防止重复提交
                repeatClick: false,
                extPropEnable:'',
                selectServerType:1,
                fileList: [],
                filterRuleString:'',
                formRules:{
                    serverIp:[{required: true, message: this.$t('isales.business.result.message.must'), trigger: ['blur', 'change']}],
                    filePath:[{validator: this.filePathValidate, trigger: ['blur', 'change']}],
                    fileName:[{required: true, message: this.$t('isales.business.result.message.must'), trigger: ['blur', 'change']},
                        {validator: this.fileNameValidate, trigger: ['blur', 'change']}],
                    mapRule:[{required: true, message: this.$t('isales.business.result.message.must'), trigger: ['blur', 'change']}],
                },
            }
        },
        computed: {
            dialogVisible: {
                get() {
                    return this.show
                },
                set(val) {
                    this.$emit('update:show', val)
                }
            },
        },
        created() {
            this.initMapRuleList();
            this.initFileServerList();
            this.initCmsEnvironment();
            if(this.marketingMode === 1){
                this.initTaskInfo();
            }
        },
        methods: {
            dataFilterChange(){
                console.log("dataFilterChange");
                if(this.form.dataFilter == 2){
                    this.filterRuleString = '';
                }
            },
            analyseModeChange(){
                console.log("analyseModeChange");
                if(this.form.analyseMode==2){
                    if(this.mapRuleList && this.mapRuleList.length>0) {
                        this.form.mapRule = this.mapRuleList[0].key;
                    }
                }else{
                    this.form.mapRule = '';
                }
            },
            fileNameValidate(rule, value, callback){
                var fileName = this.form.fileName;
                var myReg = /^[\u4e00-\u9fa5a-zA-Z0-9_]+$/;
                if (!myReg.test(fileName.substring(0, fileName.lastIndexOf(".")))) {
                    callback(new Error(this.$t('isales.import.message.uploadedfileNameformatvalidate')));
                }
                var fileNameFormat = fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length);
                if (fileNameFormat != "csv") {
                    callback(new Error(this.$t('isales.import.message.uploadedfileformatvalidate')));
                }else{
                    callback();
                }
            },
            filePathValidate(rule, value, callback){
                var myReg =  /^(\/\w+)+$/;
                if (this.form.filePath && !myReg.test(this.form.filePath)) {
                    callback(new Error(this.$t('isales.file.title.rightpath')));
                }else{
                    callback();
                }
            },
            fileValidate(){
                if (this.fileList.length === 0) {
                    AiccElMessage({
                        type: 'error',
                        duration: 10000,
                        offset: 56,
                        'show-close': true,
                        message: this.$t('isales.import.message.pleaseuploadfile')
                    });
                    return false;
                }
                var file = this.fileList[0].raw;
                var fileSize = file.size;
                var fileName = file.name;
                var myReg = /^[\u4e00-\u9fa5a-zA-Z0-9_]+$/;
                if (!myReg.test(fileName.substring(0, fileName.lastIndexOf(".")))) {
                    AiccElMessage({
                        type: 'error',
                        duration: 10000,
                        offset: 56,
                        'show-close': true,
                        message: this.$t('isales.import.message.uploadedfileNameformatvalidate')
                    });
                    return false;
                }
                var fileNameFormat = fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length);
                if (fileNameFormat != "csv") {
                    AiccElMessage({
                        type: 'error',
                        duration: 10000,
                        offset: 56,
                        'show-close': true,
                        message: this.$t('isales.import.message.uploadedfileformatvalidate')
                    });
                    return false;
                }
                if (fileSize > 1024 * 1024 * 20) {
                    AiccElMessage({
                        type: 'error',
                        duration: 10000,
                        offset: 56,
                        'show-close': true,
                        message: this.$t('isales.import.message.uploadedfiletoolarge')
                    });
                    return false;
                }
                return true;
            },
            download() {
                let params = {"marketingMode": this.marketingMode};
                if (this.marketingMode === 1) {
                    params["taskType"] = this.taskType;
                    params["policyType"] = this.policyType;
                } else if (this.marketingMode === 2) {
                    let notificationParams = {
                        "mediaType": this.mediaType,
                        "notificationId": this.taskId
                    }
                    params["notificationParams"] = notificationParams;
                }
                aiccDownload(params, downloadTemplateUrl);
            },
            filterRuleConfirm(data) {
                if (data) {
                    this.filterRuleString = JSON.stringify(data);
                }
            },
            handleRadioChange(){
                console.log("handleRadioChange");
                if(this.form.importType === 2){
                    if (this.serverIpList.length > 0) {
                        this.form.serverIp = this.serverIpList[0].key;
                    }
                    this.dataNumLimit = $t('isales.import.message.template.dataNum', [500000]);
                }else{
                    this.dataNumLimit = $t('isales.import.message.template.dataNum', [50000]);
                    this.form.serverIp = '';
                }
            },
            initMapRuleList(){
                console.log("queryMapRuleList");
                let params = {
                    "marketingMode": this.marketingMode
                }
                queryMapRuleList(params).then(res => {
                    for (let i = 0; i < res.length; i++) {
                        let ruleEnum = {};
                        ruleEnum["key"] = res[i]["ruleId"];
                        ruleEnum["value"] = res[i]["ruleName"];
                        this.mapRuleList.push(ruleEnum);
                    }
                });

            },
            initFileServerList() {
                console.log("initFileServerList");
                queryFileServerList().then(result => {
                    for (let i = 0; i < result.length; i++) {
                        let serverEnum = {};
                        serverEnum["key"] = result[i]["serverId"];
                        serverEnum["serverType"] = result[i]["serverType"];
                        if (result[i]["serverType"] === 1) {
                            serverEnum["value"] = result[i]["ip"] + ":" + result[i]["port"];
                        } else {
                            serverEnum["value"] = result[i]["endPoint"] + ":" + result[i]["bucket"];
                        }
                        this.serverIpList.push(serverEnum);
                    }
                    if (this.serverIpList.length > 0) {
                        this.selectServerType = this.serverIpList[0].serverType;
                        if (this.selectServerType == 2) {
                            this.form.needDelete = 2;
                        }
                    }
                })
            },
            initCmsEnvironment(){
                console.log("initCmsEnvironment");
                queryCmsEnvironment().then(res => {
                    this.cmsEnvironment = res.cmsEnvironment;
                });
            },
            initTaskInfo(){
                console.log("initTaskInfo");
                let searchObject = {};
                if (this.taskId) {
                    searchObject["taskId"] = this.taskId;
                }
                let params = {
                    "dataObject": searchObject,
                    "limit": 10,
                    "offset": 0,
                }
                queryTaskInfo(params).then(result => {
                    if (result && result.length > 0 ){
                        this.extPropEnable = result[0].extPropEnable;
                    }
                });
            },
            fileUploadChange(files, fileList) {
                if (fileList.length > 0) {
                    const aa = fileList[fileList.length - 1]
                    this.fileList = []
                    this.fileList.push(aa)
                }
            },
            selectServerIp(){
                console.log("selectServerIp");
                let selectItem = this.serverIpList.find(item=> item.key==this.form.serverIp);
                this.selectServerType = selectItem.serverType;
                if (this.selectServerType == 2) {
                    this.form.needDelete = 2;
                }
            },
            async submitFile(){
                console.log("submitFile");
                this.$refs['form'].validate(valid => {
                    if(!valid){
                        return;
                    }
                    if(!this.fileValidate()){
                        return;
                    };
                    if(this.repeatClick){
                        return;
                    }
                    this.repeatClick = true;
                    if (this.form.analyseMode == 1) {
                        if (this.extPropEnable == 1) {
                            const params = {
                                "limit": 10,
                                 "offset": 0,
                                "dataDO": {"taskId": this.taskId}
                            };
                            queryDataByTaskId(params).then(response => {
                                if (response && response.datas && response.datas.length > 0 ){
                                    this.repeatClick = false;
                                    AiccElMessage({
                                        type: 'error',
                                        duration: 10000,
                                        offset: 56,
                                        'show-close': true,
                                        message: this.$t('isales.import.message.ruleExtProp')
                                    });
                                    return;
                                }
                                this.repeatClick = false;
                                AiccElMessage({
                                    type: 'error',
                                    offset: 56,
                                    duration: 10000,
                                    'show-close': true,
                                    message: this.$t('isales.import.message.closeExtProp')
                                });
                                return;
                            });
                            return;
                        }
                        this.form.mapRule = "";
                    }
                    if(this.form.dataFilter && this.form.dataFilter == 1 && !this.filterRuleString){
                        this.repeatClick = false;
                        AiccElMessage({
                            type: 'error',
                            duration: 10000,
                            offset: 56,
                            'show-close': true,
                            message: this.$t('isales.import.filter.message.selectRuleTips')
                        });
                        return;
                    }
                    const params = {"importInfo":{"taskId": this.taskId,
                            "isFilter": this.form.dataFilter,
                            "orderType": this.form.dataOrder,
                            "importType": this.form.importType,
                            "importMode": this.form.importMode,
                            "serverId": this.form.serverIp,
                            "filePath": this.form.filePath,
                            "fileName": this.form.fileName,
                            "analyseMode": this.form.analyseMode,
                            "mapId": this.form.mapRule,
                            "cmsEnvironment": this.cmsEnvironment,
                            "filterRule" : this.filterRuleString,
                            "marketingMode":this.marketingMode
                        }};
                    if(this.marketingMode==3){
                        delete params.importInfo['taskId'];
                        delete params.importInfo['isFilter'];
                        delete params.importInfo['orderType'];
                    }
                    let reqData = aiccSetImportParam(params, this.fileList[0].raw);
                    importTaskData(reqData).then(res => {
                        this.repeatClick = false;
                        if (res.returnCode == "0") {
                            AiccElMessage({
                                type: 'success',
                                duration: 5000,
                                offset: 56,
                                message: res.description
                            });
                          this.$emit('importSuccess')
                          this.dialogVisible = false;
                        } else if (res.returnCode === '04000018') {
                          this.$message({
                            type: 'error',
                            duration: 10000,
                            offset: 56,
                            'show-close': true,
                            message: this.$t('isales.data.lock')
                          })
                        } else if (res.returnCode == "4") {
                            AiccElMessage({
                                type: 'error',
                                duration: 10000,
                                offset: 56,
                                'show-close': true,
                                message: res.description
                            });
                        } else {
                            AiccElMessage({
                                type: 'error',
                                offset: 56,
                                duration: 10000,
                                'show-close': true,
                                message: res.description
                            });
                        }
                    });
                });
            },
            async submitServer(){
                console.log("submitServer");
                this.$refs['form'].validate(valid => {
                    if(!valid){
                        return;
                    }
                    if(this.repeatClick){
                        return;
                    }
                    this.repeatClick = true;
                    if (this.form.analyseMode == 1) {
                        if (this.extPropEnable == 1) {
                            const params = {
                                "limit": 10,
                                "offset": 0, "dataDO": {"taskId": this.taskId}
                            };
                            queryDataByTaskId(params).then(response => {
                                if (response && response.datas && response.datas.length > 0 ){
                                    this.repeatClick = false;
                                    AiccElMessage({
                                        type: 'error',
                                        duration: 10000,
                                        offset: 56,
                                        'show-close': true,
                                        message: this.$t('isales.import.message.ruleExtProp')
                                    });
                                    return;
                                }
                                this.repeatClick = false;
                                AiccElMessage({
                                    type: 'error',
                                    duration: 10000,
                                    offset: 56,
                                    'show-close': true,
                                    message: this.$t('isales.import.message.closeExtProp')
                                });
                                return;
                            });
                            return
                        }
                        this.form.mapRule = "";
                    }
                    if(this.form.dataFilter && this.form.dataFilter === 1 && !this.filterRuleString){
                        this.repeatClick = false;
                        AiccElMessage({
                            type: 'error',
                            duration: 10000,
                            offset: 56,
                            'show-close': true,
                            message: this.$t('isales.import.filter.message.selectRuleTips')
                        });
                        return;
                    }

                    let params = {"taskId": this.taskId,
                        "importType": this.form.importType,
                        "importMode": this.form.importMode,
                        "serverId": this.form.serverIp,
                        "filePath": this.form.filePath,
                        "fileName": this.form.fileName,
                        "analyseMode": this.form.analyseMode,
                        "mapId": this.form.mapRule,
                        "cmsEnvironment": this.cmsEnvironment,
                        "serverType": this.selectServerType,
                        "needDelete" :this.form.needDelete,
                        "isFilter": this.form.dataFilter,
                        "filterRule" : this.filterRuleString,
                        "marketingMode":this.marketingMode
                    };
                    if (this.selectServerType == 2) {
                        params.filePath = this.form.obsFilePath;
                    }
                    importServerData(params).then(res => {
                        this.repeatClick = false;
                        if (res.returnCode == "0") {
                            AiccElMessage({
                              type: 'success',
                              duration: 5000,
                              offset: 56,
                              message: res.description
                            });
                            this.$emit('importSuccess')
                            this.dialogVisible = false;
                        } else if (res.returnCode === '04000018') {
                          this.$message({
                            type: 'error',
                            duration: 10000,
                            offset: 56,
                            'show-close': true,
                            message: this.$t('isales.data.lock')
                          })
                        } else if (res.returnCode == "4") {
                            AiccElMessage({
                                type: 'error',
                                duration: 10000,
                                offset: 56,
                                'show-close': true,
                                message: res.description
                            });
                        } else {
                            AiccElMessage({
                                type: 'error',
                                duration: 10000,
                                offset: 56,
                                'show-close': true,
                                message: res.description
                            });
                        }
                    });
                });
            }
        }
    }
</script>

<style lang="less" scoped>
.download-template-file-button {
  color: var(--text-color-highlighting);
  cursor: pointer;
}
</style>