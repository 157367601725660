import i18n from '@/lang'

const $t = i18n.global.t
const ccmessagingRoutes = {
  default: [
    {
      path: 'online-chat-workbench',
      name: '6005',
      title: '在线交谈工作台',
      component: () => import('@/views/ccmessaging/views/online-chat-workbench/index.vue')
    },
    {
      path: 'social-media-operation',
      name: '6022',
      title: '社交媒体',
      component: () => import('@/views/ccmessaging/views/social-media-operation/index.vue'),
      children: []
    },
    {
      path: 'social-post-index',
      name: '6023',
      title: '企业帐号运营',
      component: () => import('@/views/ccmessaging/views/social-post-operation/index.vue'),
      children: []
    },
    {
      path: 'email-message-management',
      name: '6018004',
      title: '邮件消息管理',
      component: () => import('@/views/ccmessaging/views/email-message-management/index.vue'),
      children: []
    },
    {
      path: 'async-message-statistics',
      name: '6016008',
      title: '离线消息',
      component: () => import('@/views/ccmessaging/views/async-message-statistics/index.vue'),
      children: []
    },
    {
      path: 'cobrowse-session-record',
      name: '6018005',
      title: '网页协同会话记录',
      component: () => import('@/views/ccmessaging/views/cobrowse-session-record/index.vue'),
      children: []
    }
  ],
  pageWithSideNav: [
    {
      path: '/multimedia-library',
      name: '8008002',
      title: '多媒体库',
      component: () => import('@ccme/views/multimedia-library/index.vue')
    },
    {
      path: '/whatsApp-template-management',
      name: '8008008',
      title: 'WhatsApp模板',
      component: () => import('@ccme/views/multimedia-library/whatsApp-template/index.vue')
    },
    {
      path: '/channel-config',
      name: '8002003',
      title: '渠道配置',
      component: () => import('@/views/ccmessaging/views/channel-config/index.vue')
    },
    {
      path: '/chat-service-time',
      name: '8003006',
      title: '在线交谈服务时间',
      component: () => import('@/views/ccmessaging/views/chat-service-time/index.vue')
    },
    {
      path: '/channel-special-list',
      name: '8008006',
      title: '渠道特殊名单',
      component: () => import('@/views/ccmessaging/views/channel-special-list/index.vue')
    },
    {
      path: '/email-special-list',
      name: '8008004',
      title: '邮件特殊名单',
      component: () => import('@/views/ccmessaging/views/email-special-list/index.vue')
    },
    {
      path: '/sms-special-list',
      name: '8008005',
      title: '短信特殊名单',
      component: () => import('@/views/ccmessaging/views/sms-special-list/index.vue')
    },
    {
      path: '/audio-silent-agent',
      name: '8002008',
      title: '语音静默座席配置',
      component: () => import('@ccme/views/audio-silent-agent/index.vue')
    },
    {
      path: '/message-flow-control',
      name: '8011008',
      title: '消息流控',
      component: () => import('@/views/ccmessaging/views/message-flow-control/index.vue')
    },
    {
      path: 'chat-phraseservice',
      name: '8003008',
      title: '个性化常用语',
      component: () => import('@/views/ccmessaging/views/chat-phraseservice/index.vue')
    },
    {
      path: '/web-collaborative-configuration',
      name: '8010001',
      title: '网页协同配置',
      component: () => import('@/views/ccmessaging/views/web-collaborative-configuration/index.vue')
    }
  ],
  pageWithBackHeader: [
    {
      path: '/social-post-info-edit',
      name: 'social-post-info-edit',
      title: $t('ccmessaging.socialPostOperation.ceratePageTitle'),
      component: () =>
        import('@/views/ccmessaging/views/social-post-operation/SocailPostInfoEdit.vue')
    },
    {
      path: '/social-post-info-approve',
      name: 'social-post-info-approve',
      title: $t('ccmessaging.socialPostOperation.ceratePageTitle'),
      component: () =>
        import('@/views/ccmessaging/views/social-post-operation/SocailPostInfoApprove.vue')
    },
    {
      path: '/channel-config-edit',
      name: 'channel-config-edit',
      title: '新建渠道',
      component: () => import('@/views/ccmessaging/views/channel-config/ChannelConfigEdit.vue')
    },
    {
      path: '/facebook-page-management',
      name: 'facebook-page-management',
      title: 'Facebook主页配置',
      component: () => import('@/views/ccmessaging/views/channel-config/FacebookPageManagement.vue')
    },
    {
      path: '/whatsApp-template-message',
      name: 'whatsApp-template-message',
      title: `${$t('ccmessaging.chat.template.card')}}`,
      component: () => import('@ccme/views/multimedia-library/WhatsAppTemplateMessageAddModify.vue')
    },
    {
      path: '/whatsApp-template',
      name: 'whatsApp-template',
      title: `WhatsApp模板新增/修改`,
      component: () =>
        import('@ccme/views/multimedia-library/whatsApp-template/WhatsAppTemplateAddModify.vue')
    }
  ],
  pageWithSelfTab: [
    {
      path: '/whatsApp-template-add',
      name: 'whatsApp-template-add',
      title: '新增WhatsApp模板',
      component: () => import('@/views/ccmessaging/views/multimedia-library/whatsApp-template/WhatsAppTemplateAdd.vue')
    },
    {
      path: '/channel-config-add',
      name: 'channel-config-add',
      title: '新增渠道',
      component: () => import('@/views/ccmessaging/views/channel-config/ChannelConfigAdd.vue')
    },
    {
      path: '/whatsApp-template-message-add',
      name: 'whatsApp-template-message-add',
      title: `${$t('ccmessaging.chat.template.card')}}`,
      component: () => import('@ccme/views/multimedia-library/WhatsAppTemplateMessageAdd.vue')
    },
    {
      path: '/social-post-info-add',
      name: 'social-post-info-add',
      title: '发帖',
      component: () => import('@/views/ccmessaging/views/social-post-operation/SocialPostInfoAdd.vue'),
    },
    {
      path: '/quality-check-workbench',
      name: 'quality-check-workbench',
      title: '质检工作台',
      component: () => import('@/views/ccmessaging/views/online-chat-workbench/QualityCheckIndex.vue')
    },
  ]
}
export default ccmessagingRoutes
