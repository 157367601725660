export default {
  'aicc.loading': 'Carregando',
  'aicc.select': 'Selecione ',
  'aicc.numberOnly': 'Apenas números',
  'aicc.unorganized': 'Não-organizado',
  'aicc.organized': 'Instituições organizadas',
  'aicc.columnConfig': 'Configuração da Coluna',
  'aicc.selectAll': 'Todas as Colunas',
  'aicc.selectedColumns': 'Colunas selecionadas',
  'aicc.selectedColumnsTip': '(arrastáveis)',
  'aicc.columnSettings': 'Configuração do Cabeçalho',
  'aicc.selectedInfo': '{n} itens selecionados',
  'aicc.advancedSearch': 'Pesquisa Avançada',
  'aicc.lengthLimit': 'O comprimento não pode exceder {limit} caracteres',
  'aicc.ok': 'OK',
  'aicc.error': 'Erro',
  'aicc.search': 'Pesquisar',
  'aicc.reset': 'Redefinir',
  'aicc.skip': 'Saltar',
  'aicc.goto': 'Ir para check-out',
  'aicc.more': 'Mais',
  'aicc.intRangeLimit': 'Insira um número inteiro variando de {min} a {max}',
  'aicc.MaxLength': 'Introduz uma cadeia de caracteres que consiste num máximo de 0 caracteres',
  'aicc.previous': 'Anterior',
  'aicc.next': 'Próxima',
  'aicc.noData': 'Não há dados disponíveis',
  'aicc.lengthMinLimit': 'Deve conter pelo menos {0} caracteres',
  'aicc.traceId.error':
    'TraceId:0,Por favor, envie o TraceId de volta para a equipe de operações para localizar',
  'aicc.apibuilder.dev': 'Desenvolvimento de Interface',
  'aicc.timeout.desc': 'Expiração da Sessão',
  'aicc.timeout.solution': 'O seu login expirou. Você precisa fazer login novamente.',
  'aicc.input': 'Favor entrar',
}
