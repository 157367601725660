import request from '@/utils/request'
import cookieUtils from "@/utils/cookie";
import odfsRequest from "@oifde/utils/odfsRequest";

/**
 * 查询导出结果
 * @param query
 * @returns {*}
 */
export function queryExportResultList(query) {
  const data = {
    tenantId: cookieUtils.getCookie('tenant_space_id'),
    userId:  cookieUtils.getCookie('user_id'),
  }
  for (let prop in query) {
    if (typeof query[prop] === undefined) {
      continue
    }
    if (typeof query[prop] === 'string' && query[prop] === '') {
      continue
    }
    data[prop] = query[prop]
  }
  return request({
    url: '/oifde/rest/queryExportResultList',
    method: 'post',
    data
  })
}

/**
 * 下载导出文件
 * @param query
 * @returns {*}
 */
export function downloadFile(query) {
  const data = {
    tenantId: cookieUtils.getCookie('tenant_space_id'),
    userId:  cookieUtils.getCookie('user_id'),
  }
  for (let prop in query) {
    if (typeof query[prop] === undefined) {
      continue
    }
    if (typeof query[prop] === 'string' && query[prop] === '') {
      continue
    }
    data[prop] = query[prop]
  }
  return odfsRequest({
    url: '/oifde/rest/downloadFile',
    method: 'post',
    data,
    responseType: 'blob',
  })
}