
<!-- 系统通知-公告 -->
<template>
  <div class = "body">
    <sweet-radio-group v-if="auth=='admin' && !editType" v-model="notiScope" @change="notiScopeChange" class="notice-scope">
      <sweet-radio-button label="system">{{ $t('ccnotification.notifition.sendmessage.sysmessage') }}</sweet-radio-button>
      <sweet-radio-button label="internal">{{ $t('ccnotification.notifition.sendmessage.internalmessage') }}</sweet-radio-button>
    </sweet-radio-group>
  
    <sweet-form ref="formRef" label-width="auto" label-position="left" :model="formData" class="form" :rules="formRules">
      <sweet-form-item v-if="!editType" :label="$t('ccivr.ivr.ivrflow.type')">
        <sweet-radio-group v-model="notiType" @change="notiTypeChange">
          <sweet-radio label="bulletin" v-if="supportBulletin">{{ $t('ccnotification.notifition.sendmessage.bulletin') }}</sweet-radio>
          <sweet-radio label="internalMesage" v-if="notiScope == 'internal'">{{ $t('ccnotification.notifition.sendmessage.internal') }}</sweet-radio>
          <sweet-radio label="shortMessage" v-if="auth != 'agent'">{{ $t('message.template.mediaType.shortmessage') }}</sweet-radio>
          <sweet-radio label="email" v-if="auth != 'agent'">{{ $t('message.template.mediaType.email') }}</sweet-radio>
        </sweet-radio-group>
      </sweet-form-item>
  
      <sweet-form-item :label="$t('ccnotification.notifition.sendmessage.recipient')">
        <sweet-input :placeholder="$t('ccnotification.notifition.sendmessage.enterrecipient')" v-model="recipient" @click="selectUserRecipient()" readonly>
          <template #suffix>
            <img src="../../assets/img/icon-user.png">
          </template>
        </sweet-input>
      </sweet-form-item>
  
      <sweet-form-item :label="$t('ccnotification.notifition.sendmessage.recipientGroup')">
        <sweet-input
          :placeholder="$t('ccnotification.notifition.sendmessage.enterRecipientGroup')"
          v-model="notifitionRecipientGroup" 
          @click="selectTenantRecipientGroup()" 
          readonly
          class="aicc-input-dialog"></sweet-input>
      </sweet-form-item>

      <sweet-form-item :label="$t('ccnotification.notifition.sendmessage.messagetitle')" prop="notificationTitle">
        <sweet-input :placeholder="$t('ccnotification.notifition.sendmessage.notifitiontitle')" v-model="formData.notificationTitle"></sweet-input>
      </sweet-form-item>

      <sweet-form-item :label="$t('ccnotification.notifition.sendmessage.bulletinType')" prop="bulletinTypeName">
        <sweet-input
          :placeholder="$t('ccnotification.notifition.sendmessage.enterBulletinType')" 
          v-model="bulletinType.name" @click="selectBulletinType" 
          class="wrap-input aicc-input-dialog" 
          readonly />
      </sweet-form-item>

      <sweet-form-item :label="$t('ccnotification.notifition.sendmessage.priority')">
        <sweet-radio-group v-model="selectedPriority">
          <sweet-radio v-for="item in priorityItems" :label="item.priorityNameKey" :key="item.priorityNameKey">{{ item.priorityName }}</sweet-radio>
        </sweet-radio-group>
      </sweet-form-item>

      <sweet-form-item :label="$t('ccnotification.bulletinType.message.releaseTime')">
        <div  class="release-time-picker">
          <sweet-date-picker v-model="releaseTime" type="datetime" :placeholder="$t('aicc.select')"/>
        </div>
        <sweet-checkbox v-model="isSendRegularly" :label="$t('ccnotification.bulletinType.message.periodicallySend')" />
      </sweet-form-item>

      <sweet-form-item v-if="isSendRegularly" :label="$t('ccnotification.bulletinType.message.sendingFrequency')">
        <sweet-radio-group v-model="sendFrequency">
          <sweet-radio label="0">{{ $t('ccnotification.bulletinType.message.everyDay') }}</sweet-radio>
          <sweet-radio label="1">{{ $t('ccnotification.bulletinType.message.weekly') }}</sweet-radio>
          <sweet-radio label="2">{{ $t('ccnotification.bulletinType.message.perMonth') }}</sweet-radio>
        </sweet-radio-group>
      </sweet-form-item>

      <sweet-form-item v-if="isSendRegularly && sendFrequency == '1'" :label="$t('ccnotification.bulletinType.message.weekly')">
        <div class="wrap-input">
          <sweet-select v-model="dayOfWeek">
            <sweet-option :label="$t('ccnotification.bulletinType.message.Monday')" value="1" key="1"></sweet-option>
            <sweet-option :label="$t('ccnotification.bulletinType.message.Tuesday')" value="2" key="2"></sweet-option>
            <sweet-option :label="$t('ccnotification.bulletinType.message.Wednesday')" value="3" key="3"></sweet-option>
            <sweet-option :label="$t('ccnotification.bulletinType.message.Thursday')" value="4" key="4"></sweet-option>
            <sweet-option :label="$t('ccnotification.bulletinType.message.Friday')" value="5" key="5"></sweet-option>
            <sweet-option :label="$t('ccnotification.bulletinType.message.Saturday')" value="6" key="6"></sweet-option>
            <sweet-option :label="$t('ccnotification.bulletinType.message.Sunday')" value="7" key="7"></sweet-option>
          </sweet-select>
        </div>
      </sweet-form-item>

      <sweet-form-item v-if="isSendRegularly && sendFrequency == '2'" :label="$t('ccnotification.bulletinType.message.perMonth')">
        <div class="wrap-input">
          <sweet-select v-model="dayOfMonth">
            <sweet-option v-for="n in 31" :key="n" :value="n" :label="$t('ccnotification.bulletinType.message.daysOfMonth').replace('0', n)"></sweet-option>
          </sweet-select>
        </div>
      </sweet-form-item>

      <sweet-form-item :label="$t('ccnotification.notifition.sendmessage.expireTime')">
        <div class="wrap-input">
          <sweet-date-picker v-model="bulletinExpireTime" type="datetime" :placeholder="$t('aicc.select')"/>
        </div>
      </sweet-form-item>
    </sweet-form>

    <div class="editor-wrap"><textarea id="sysBulletinRichEditor" name="content" type="text/plain"></textarea></div>

    <select-user-dialog 
      :selectedUsers="selectUsers"
      :allSelected="isSelectAllUsers"
      :title="$t('message.template.message.recipient')"
      v-if="showUserDialog"
      @onClose="onUserDialogClose"
      @selectUser="onUserSelected"/>

    <select-group-dialog :propSelectedData="selectGroups" v-if="showGroupDialog" @onClose="onGroupDialogClose" @groupSelected="onGroupSelected" />

    <select-bulletin-type-dialog v-if="showTypeDialog" :title="$t('ccnotification.notifition.sendmessage.enterBulletinType')"
      @onClose="onTypeDialogClose" @selectBulletinType="onTypeSelected" />
  </div>
  
  <div class="footer">
    <sweet-button @click="send(1)">{{ $t("message.template.message.save") }}</sweet-button>
    <sweet-button type="primary" @click="send(2)" :v-loading="isSending">{{ $t("ccnotification.notifition.sendmessage.send") }}</sweet-button>
  </div>
</template>

<script>
import tinymce from 'tinymce'
import { getTinymceLangs } from "@/utils"
import cookie from "@/utils/cookie"
import timeUtils from '@/utils/timeUtils'
import { ArrowDown } from '@element-plus/icons-vue'
import selectUserDialog from './dialog/select-user-dialog.vue'
import selectGroupDialog from './dialog/select-group-dialog.vue'
import selectBulletinTypeDialog from './dialog/select-bulletin-type-dialog.vue'
import { ElMessageBox } from 'element-plus'
import AiccElMessage from '@/utils/el-message'
import { sendAgentBulletin, sendSysBulletin, sendTenantBulletin } from '../../api/index'
import { handleSendResult } from './common'
import { getTenantGroup } from '@/views/base/api'

export default {
  components: {
    selectUserDialog,
    selectGroupDialog,
    selectBulletinTypeDialog
  },

  data() {
    return {
      ArrowDown,
      notiScope: 'internal', // 系统通知，内部通知
      notiType: 'bulletin', // 公告，站内信，短信，邮件
      templateSwitchOn: false,
      priorityItems: [
        {
          'priorityNameKey': '1',
          'priorityName': $t('ccnotification.notifition.sendmessage.priorityGeneral')
        },
        {
          'priorityNameKey': '2',
          'priorityName': $t('ccnotification.notifition.sendmessage.priorityUrgent')
        },
        {
          'priorityNameKey': '3',
          'priorityName': $t('ccnotification.notifition.sendmessage.priorityExtraUrgent')
        },
        {
          'priorityNameKey': '4',
          'priorityName': $t('ccnotification.notifition.sendmessage.priorityFault')
        }
      ],
      formRules: {
        notificationTitle: [{
          min: 0,
          max: 200,
          message: this.$t('SM.COMMON.MESSAGE.VALIDATE_MAXLENGTHB').replace("0", 200),
          trigger: ['change', 'blur']
        }],
      },
      formData: {
        notificationTitle: '',
        bulletinTypeName: ''
      },
      selectedPriority: '1',
      releaseTime: '',
      isSendRegularly: false,
      sendFrequency: '0',
      dayOfWeek: '1',
      dayOfMonth: 1,
      bulletinType: {
        name: '',
        id: '',
        invalidSect: ''
      },
      bulletinExpireTime: '',
      recipient: '',
      showTypeDialog: false,
      showUserDialog: false,
      notifitionRecipientGroup: '',
      showGroupDialog: false,
      selectUsers: [],
      selectGroups: [],
      selectGroupIds: '',
      isSending: false,
      isSelectAllUsers: false,
      tenantGroups: []
    }
  },
  methods: {
    notiTypeChange(type) {
      this.$emit('notiTypeChange', type)
    },
    notiScopeChange(type) {
      this.$emit('notiScopeChange', type)
    },
    selectTenantRecipientGroup() {
      this.showGroupDialog = true
    },
    onGroupDialogClose() {
      this.showGroupDialog = false
    },
    onGroupSelected(val) {
      this.selectGroups = val
      this.notifitionRecipientGroup = ''
      this.selectGroupIds = ''
      for (let i = 0; i < val.length; i++) {
        this.notifitionRecipientGroup += val[i].groupName + ";"
        this.selectGroupIds += val[i].groupId + ";"
      }
    },
    selectUserRecipient() {
      this.showUserDialog = true
    },
    onUserDialogClose() {
      this.showUserDialog = false
    },
    onUserSelected(val, isAll) {
      this.isSelectAllUsers = isAll
      this.selectUsers = val
      this.recipient = ''
      for (let i = 0; i < val.length; i++) {
        this.recipient += val[i].userName + ";"
      }
    },
    selectBulletinType() {
      this.showTypeDialog = true
    },
    onTypeDialogClose() {
      this.showTypeDialog = false
    },
    onTypeSelected(val) {
      this.bulletinType = val
      this.formData.bulletinTypeName = this.bulletinType.name
      const invalidSect = val.invalidSect.split(";", 4)
      let invalidDate = invalidSect[this.selectedPriority - 1]
      this.bulletinExpireTime = timeUtils.preDays(invalidDate, 'D')
    },
    async send(status) {
      let valid = await this.$refs.formRef.validate().catch(e => {return false})
      if (!valid) {
        return
      }
      
      if (status != 1 && !this.recipient && !this.notifitionRecipientGroup) {
        AiccElMessage.info($t('ccnotification.notifition.sendmessage.selectGroupOrRecipient'))
        return
      }
      if (status != 1 && !this.bulletinType.id) {
        AiccElMessage.info($t('ccnotification.notifition.sendmessage.enterBulletinType'))
        return
      }
      if (status != 1 && this.releaseTime) {
        if (new Date(this.releaseTime).getTime() < new Date().getTime()) {
          AiccElMessage.warning($t('ccnotification.bulletinType.message.sendTimeParamsError1'))
          return
        }
        if (new Date(this.releaseTime).getTime() > new Date(this.bulletinExpireTime).getTime()) {
          AiccElMessage.warning($t('ccnotification.bulletinType.message.sendTimeParamsError2'))
          return
        }
        if (this.isSendRegularly && new Date(this.releaseTime).getTime() < timeUtils.preDaysToUTCTimestamp(5, 'Mi')) {
          AiccElMessage.warning($t('ccnotification.notifition.sendmessage.sendtimebeforenow'))
          return
        }
        if (new Date(this.releaseTime).getTime() >= new Date('2038/1/19').getTime()) {
          AiccElMessage.warning($t('ccnotification.notifition.sendmessage.limitdate'))
          return
        }
      }
      const content = tinymce.get('sysBulletinRichEditor').getBody().textContent
      if (content == null || content.trim() == '') {
        AiccElMessage.warning($t('ccnotification.common.message.emptyContent'))
        return
      }
      if (status != 1 && content.length >= 4096) {
        AiccElMessage.warning($t('ccnotification.common.message.maxLength'))
        return
      }
      
      this.isSending = true
      let requestParams = {
        title: this.formData.notificationTitle,
        messageType: 1, //内部通知
        mediaType: 3, //公告
        grade: this.selectedPriority,
        status,
        bulletinTypeId: this.bulletinType.id,
        expireTime: new Date(this.bulletinExpireTime).getTime(),
        content: tinymce.get('sysBulletinRichEditor').getContent()
      }

      if (this.releaseTime) {
        requestParams.sendTime = new Date(this.releaseTime).getTime()
      }
      if (this.selectGroupIds) {
        requestParams.groupIds = this.selectGroupIds
      }
      requestParams.receiverIds = this.selectUsers?.map(ele => {
        return ele ? typeof ele == "string" ? ele : ele.userId : ""
      }).filter(e => e != '')
      if(!requestParams.receiverIds) {
        requestParams.receiverIds = []
      }


      if (this.isSendRegularly) {
        requestParams.sendFrequency = this.sendFrequency
        if (this.sendFrequency == 1) {
          requestParams.dayOfWeek = this.dayOfWeek
        } else if (this.sendFrequency == 2) {
          requestParams.dayOfMonth = this.dayOfMonth
        }
      }

      if (this.editType === 'edit') {
        requestParams.id = this.messageInfo.id
      }

      let request
      if (this.auth == 'admin') {
        request = sendSysBulletin(requestParams)
      } else if (this.auth == 'tenant') {
        request = sendTenantBulletin(requestParams)
      } else {
        request = sendAgentBulletin(requestParams)
      }
      request.then(res => {
        handleSendResult(res, status == 1)
        this.isSending = false
      }).catch(e => {
        AiccElMessage.error($t('ccnotification.notifition.sendmessage.sendexception'), $t('ccnotification.common.title.fail'), {
          type: 'error'
        })
        this.isSending = false
      })
    },
    initTinyMCE() {
      let language = cookie.getSystemLanguage() || 'zh_CN'
      tinymce.init({
        selector: '#sysBulletinRichEditor',
        language,
        language_url: getTinymceLangs(language),
        width: '100%',
        plugins: 'image table',
        menubar: false,
        toolbar: 'undo redo | fontfamily | fontsize | bold italic underline strikethrough removeformat | data time | alignleft aligncenter alignright alignjustify | table',
        font_size_formats: '10px 11px 12px 14px 16px 18px 20px 24px 36px',
        font_family_formats: $t('ccmessaging.chat.tinyMCE.yaHei') + '=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;' + $t('ccmessaging.chat.tinyMCE.simsun') + '=simsun,serif;' + $t('ccmessaging.chat.tinyMCE.FangSong') + '=FangSong,serif;' + $t('ccmessaging.chat.tinyMCE.SimHei') + '=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Comic Sans MS=comic sans ms,sans-serif;Impact=impact,chicago;Times New Roman=times new roman,times;',
        readonly: false,
        branding: false,
        resize: false,
        statusbar: false,
        object_resizing: false,
        content_style: 'body{font-family:Microsoft YaHei}'
      }).then(() => {
        if (this.messageInfo && this.editType === 'edit') {
          tinymce.get('sysBulletinRichEditor').setContent(this.messageInfo.content)
        }
      })
    },
    /**
     * 组装接收群组显示名称
     * @param { Object } groupInfo 群组信息
     */
    setGroupNames(groupInfo) {
      let result = []
      groupInfo.forEach((item) => {
        result.push(
          this.tenantGroups.find((group) => {
            return group.groupId === item.groupId
          }).groupName
        )
      })
      return result.join(';')
    }
  },
  mounted() {
    this.$nextTick(() => {
      getTenantGroup(this.cookieUtils.getCookie('tenant_space_id')).then((res) => {
        if (res.groups) {
          this.tenantGroups = res.groups
        }
        if(this.messageInfo && this.editType === 'edit') {
          if(this.messageInfo.groupInfo.length != 0) {
            this.selectGroups = this.messageInfo.groupInfo
            this.notifitionRecipientGroup = this.setGroupNames(this.messageInfo.groupInfo)
            for(let i=0; i<this.notifitionRecipientGroup.split(";").length; i++) {
              this.selectGroups[i].groupName = this.notifitionRecipientGroup.split(";")[i]
            }
          }
          this.formData.notificationTitle = this.messageInfo.title
          this.recipient = this.messageInfo.receiverNames
          const receiverNames = this.messageInfo.receiverNames?.split(";").filter(item => item != "")
          this.selectUsers = this.messageInfo.receiverIds?.split(";").filter(item => item != "").map((id, index) => {
            return {
              userId: id,
              userName: receiverNames[index]
            }
          })
          this.selectedPriority = this.messageInfo.grade
          if(this.messageInfo.BulletinType?.id) {
            this.onTypeSelected(this.messageInfo.BulletinType)
          }
        }
        this.initTinyMCE()
      })
    })
  },
  beforeUnmount() {
    tinymce.remove('#sysBulletinRichEditor')
  },
  activated() {
    if (!tinymce.get('sysBulletinRichEditor')) {
      this.initTinyMCE() 
    }
  },
  deactivated() {
    if (tinymce.get('sysBulletinRichEditor')) {
      tinymce.get('sysBulletinRichEditor').remove()
    }
  },
  props: {
    auth: String, // admin/tenant/agent 系统管理员/租户管理员/座席
    supportBulletin: {
      type: Boolean,
      default: true
    },
    messageInfo: {
      type: Object,
      default: null
    },
    editType: {
      type: String
    }
  }
}
</script>

<style scoped>
.body {
  flex-grow: 1;
  margin: 20px;
  background: white;
  border-radius: 8px;
  padding: 16px;
  padding-inline-end: 40px;
  overflow-y: scroll;
}

.footer {
  background: white;
  width: 100%;
  padding: 20px;
  padding-inline-end: 32px;
  display: flex;
  justify-content: flex-end;
}

.notice-scope {
  margin-inline-start: 20px;
  margin-top: 10px;
  margin-bottom: 18px;
}

.form {
  margin-inline-start: 10px;
  margin-top: 10px;
}

.template-select {
  width: 400px;
  margin-inline-start: 20px;
}

.release-time-picker {
  width: 400px;
  margin-inline-end: 20px;
}

.wrap-input {
  width: 400px;
}

.editor-wrap {
  margin-inline-start: 20px;
}
</style>