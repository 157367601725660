export default {
  "agentconsole.menu.title": "Espace de travail audio & vidéo",
  "casemanagement.casequery.button.create": "Créer un cas",
  "agentconsole.callreason.tips.saved": "Raison d'appel enregistrée.",
  "iolp.workflow.title.name": "Processus d'affaires",
  "agentconsole.title.onlycustomer": "Réservé au client",
  "agentconsole.title.allchat": "Tout le contenu",
  "agentconsole.title.realtimetransfer": "Transfert en temps réel",
  "agentconsole.title.intelligentcase": "Remplissage intelligent des tickets",
  'agentconsole.management.message.filterYes': 'Oui',
  'agentconsole.management.message.filterNo': 'Non',
  "agentconsole.dashboard.enumeration.aweek": "La semaine dernière",
  "SM.LOGIN.BUTTON.CHANGEPWD_CONFIRM": "OK",
  "agentconsole.Intelligent.details": "Détails",
  "agentconsole.workbench.label.intelligentrecommendation": "Assistance de l'agent",
  "agentconsole.dashboard.title.case": "Vue d'ensemble du cas",
  "agentconsole.header.label.searchtask": "Rechercher dans la tâche",
  "SM.LOGIN.TITLE.NOTICE_EXP_TIME": "Temps d'expiration",
  "SM.LOGIN.LABEL.LOGIN_CONFIRM_SUFFIX": "), confirment:",
  "SM.LOGIN.LABEL.NOTICE_CREATE_TIME_TO": "À",
  "SM.LOGIN.LABEL.OPER_PHONE": "Numéro de portable:",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_NOT_OPEN_ES": "Le système d'urgence n'est pas démarré. Veuillez accéder au menu de production pour effectuer des opérations commerciales.",
  "agentconsole.workbench.label.callinforeasons": "raison de premier niveau / raison de deuxième niveau / raison de troisième niveau / raison de quatre niveaux",
  "agentconsole.dashboard.title.averageevalution": "Satisfaction moyenne",
  "agentconsole.header.title.send.sms.notification": "Notification SMS sortante",
  "agentconsole.index.label.caseconsole": "Case Workbench",
  "agentconsole.login.label.empty": "Le nom d'utilisateur ou le mot de passe est vide.",
  "agentconsole.authmanage.label.authtype.menu": "Autorisation du menu",
  "SM.LOGIN.LABEL.FORGETPWD_ACCOUNT_TIPS": "Entrez un compte de connexion.",
  "SM.LOGIN.LABEL.CHANGEPWD_OLDPWD": "Ancien mot de passe",
  "agentconsole.callmessage.setHandledNumber": "Définir le numéro manipulé",
  "agentconsole.login.label.password": "Mot de passe",
  "agentconsole.header.title.send.whatsapp.notification": "Envoi de messages de notification WhatsApp",
  "agentconsole.login.label.signin": "Se connecter",
  "agentconsole.index.label.chatconsole": "Workbench de chat",
  "SM.ORGSTAFF.LABEL.PWD_UNMATCH_RULE": "Le mot de passe n'est pas conforme aux règles.",
  "agentconsole.menumanage.message.paramvalidatefailed": "Impossible de créer le menu. Vérifiez les paramètres.",
  "SM.LOGIN.LABEL.OPER_REGION": "Domaine d'accueil:",
  "SM.LOGIN.TIPS.CHANGEPWD_COMPARE": "Le nouveau mot de passe doit être le même que le mot de passe de confirmation.",
  "SM.LOGIN.TITLE.UPDATE_PWD_VERIFYCODE_EMPTY": "Le code de vérification est vide.",
  "SM.LOGIN.LABEL.NOTICE_INCLUDE_SUB_ORG": "Inclure l'enfant OU",
  "agentconsole.common.button.modify": "Modifier",
  "agentconsole.msg.noData": "Pas de données",
  "SM.LOGIN.TITLE.NOTICE": "Bulletin",
  "SM.LOGIN.TIPS.LOGINSUCCESS": "Connexion réussie.",
  "SM.LOGIN.TITLE.PORTAL_REGION_TITLE": "Région",
  "agentconsole.quickentrance.classname": "Veuillez donner au catalogue un nom de collation. Exemple : Gestion des ressources des locataires",
  "SM.LOGIN.TITLE.PORTAL_TITLE": "Portail BES",
  "SM.LOGIN.MESSAGE.LOGOUT": "Êtes-vous sûr de vouloir sortir ?",
  "SM.LOGIN.LABEL.LOGIN_CONFIRM_SWITCH": "Accéder au système domestique",
  "agentconsole.dashboard.title.taskunassigned": "Sans affectation",
  "SM.LOGIN.TITLE.UPDATE_PWD_NEWPWD_EMPTY": "Le nouveau mot de passe est vide.",
  "SM.LOGIN.BUTTON.RESET": "Réinitialiser",
  "agentconsole.ccnotification.inputRecipient": "Veuillez saisir les destinataires, les destinataires multiples sont séparés par des virgules",
  "agentconsole.ccnotification.inputOneRecipient": "Veuillez entrer le destinataire",
  "agentconsole.ccnotification.recipient.validate.limit.one": "Le nombre de destinataires ne peut dépasser 1.",
  "agentconsole.callmessage.callerNo": "Appelant",
  "SM.LOGIN.LABEL.SUM": "Gestion des utilisateurs du système",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_SWITCH_ES": "Les affaires ont été passées à l'état d'urgence. L'entreprise ne peut être traitée qu'après la récupération du système.",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_VERIFY_CODE_NOT_EXIST": "Le code de vérification n'existe pas.",
  "agentconsole.dashboard.evaluation.medium": "3 à 4 points",
  "agentconsole.header.title.logout": "Se déconnecter",
  "agentconsole.index.label.casedec": "Vous pouvez créer un cas pour chaque demande de service de votre client et suivre le cycle de vie complet du traitement de la demande de service client à travers le cas, en veillant à ce que chaque demande client soit clôturée et en améliorant la satisfaction du client.",
  "agentconsole.common.tips.error": "Erreur",
  "agentconsole.menumanage.title.selectauth": "Veuillez sélectionner une autorisation",
  "SM.LOGIN.BUTTON.FORGETPWD_CANCEL": "Annuler",
  "agentconsole.Intelligent.noRecord": "Le service n'est pas géré ou le résultat de la requête est nul.",
  "agentconsole.dashboard.evaluation.low": "1 à 2 points",
  "SM.LOGIN.TITLE.UPDATE_PWD_NEWPWD_RULE": "Le nouveau mot de passe ne répond pas à la règle du mot de passe.",
  "SM.LOGIN.MESSAGE.SENDSMS_FAILED": "Impossible d'envoyer le message SMS. Vérifiez le journal du backend.",
  "agentconsole.header.title.notificationcenter": "Afficher les notifications",
  "agentconsole.login.indexforipcc.help": "Aide",
  "agentconsole.contacthistory.title": "Historique des contacts",
  "agentconsole.authmanage.field.auth_type": "Type d'autorisation",
  "SM.PORTAL.LABEL.INPUT_OLD_PWD": "Entrez l'ancien mot de passe.",
  "SM.LOGIN.BUTTON.FORGETPWD_CONFIRM": "OK",
  "SM.LOGIN.LABEL.FORGETPWD_EMAIL_TIPS": "Entrez l'adresse e-mail.",
  "SM.AUC.MESSAGE.PWDRULE_NO": "Non",
  "agentconsole.callmessage.faq.spread": "Développer",
  "agentconsole.querycustominfo.msg.inputHandleNumber": "Entrez un numéro traité",
  "agentconsole.login.indexforipcc.difbutton": "Différents boutons de commande de fonctionnement peuvent être affichés en fonction de l'autorité du personnel du service à la clientèle afin d'éviter un mauvais fonctionnement et des interférences inutiles, améliorant ainsi l'efficacité du service du personnel du service à la clientèle.",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_LOGIN_ID_NULL": "Le compte de connexion ne peut pas être vide.",
  "agentconsole.common.button.ok": "OK",
  "agentconsole.login.indexforipcc.agentcall": "Contrôle des appels de l'agent, comme répondre aux appels, le renvoi d'appel, les appels à trois, demander de l'aide, etc.",
  "agentconsole.contacthistory.oneMin": "il y a 1 minute",
  "SM.LOGIN.TITLE.PASSWORD_HASEXPIRED": "Votre mot de passe a expiré. Veuillez contacter l'administrateur pour réinitialiser le mot de passe.",
  "SM.LOGIN.LABEL.FRAMEWORK_CALCULATOR": "Calculateur",
  "SM.LOGIN.LABEL.NOTICE_GRADE_NORMAL": "Normal",
  "SM.LOGIN.LABEL.CUS_LOGIN": "Connexion client",
  "SM.SERVLET.BATCH.RESET.PASSWORD.EMPLOYEE": "Réinitialisation des mots de passe de l'utilisateur de location par lots",
  "agentconsole.sessionmanagement.label.clientIp": "Ip client",
  "agentconsole.header.label.searchbase": "Rechercher dans le Centre de connaissances",
  "agentconsole.login.resetpwd.empty": "Le nom d'utilisateur ou l'adresse e-mail est vide.",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED": "Impossible d'ouvrir le menu.",
  "SM.LOGIN.TITLE.LOGIN_TITLE": "Page de connexion au portail BES",
  "agentconsole.login.indexforipcc.cccbar": "La bande de siège dans le cadre d'agent fournit les fonctions principales de traitement des appels vocaux et de commande d'état d'agent.",
  "SM.LOGIN.TIPS.UPDATE_PWD": "Changer le mot de passe",
  "SM.LOGIN.TITLE.CURRENT_BE": "Ville actuelle",
  "SM.ORGSTAFF.LABEL.TEAM_ACCOUNT": "Compte",
  "agentconsole.workbench.queNum": "Nombre de clients actuels en file d'attente:",
  "agentconsole.callmessage.faq.folded": "Réduire",
  "SM.LOGIN.TITLE.PROJECT_CREATER": "Créateur :",
  "SM.LOGIN.MESSAGE.LOGIN.LABEL.EMPTY": "Le nom d'utilisateur ou le mot de passe est vide.",
  "agentconsole.common.expiry.phone": "Hotline de service",
  "agentconsole.menumanage.message.urlvalidatefailed": "Impossible de créer le menu. Contactez l'administrateur système pour vérifier la liste de confiance des adresses IP.",
  "SM.LOGIN.TITLE.PASSWORD_INIT_SHOULD": "Vous utilisez le mot de passe initial. Changez le mot de passe immédiatement.",
  "SM.LOGIN.LABEL.RESETPWD.FAIL": "La réinitialisation du mot de passe a échoué.",
  "agentconsole.dashboard.title.caseunhandle": "Non-traité",
  "agentconsole.login.indexforipcc.connectcontrol": "Contrôle de l'état de connexion",
  "SM.LOGIN.TIPS.MODIFY_SUCCESS": "Modifié avec succès.",
  "agentconsole.dashboard.title.casecreate": "Nombre de cas créés",
  "SM.ORGSTAFF.TIPS.REGION_DST_SWITCHINTERVAL_TIPS": "Unité : deuxième. Portée: 1 à 86400. Si ce paramètre est laissé vide, la valeur par défaut 3600 est utilisée.",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_LOGINID_NO_BIND_MSISDN": "L'utilisateur n'est lié à aucun numéro de mobile.",
  "SM.PORTAL.LABEL.INPUT_NEW_PWD": "Entrez un nouveau mot de passe.",
  "agentconsole.menumanage.message.renamenode": "Dupliquer le nom du menu",
  "agentconsole.contacthistory.accept": "Numéro manipulé",
  "SM.LOGIN.LABEL.REMEMBER": "Mémoriser le nom d'utilisateur",
  "agentconsole.login.indexforipcc.signinsuccess": "Après l'enregistrement avec succès de l'agent, l'agent peut répondre à l'appel du client et s'occuper de lui. Le système attribue les appels client en fonction des agents qui sont enregistrés. Le responsable du personnel effectue la gestion des présences en fonction de l'heure d'arrivée et de départ de l'agent.",
  "agentconsole.login.indexforipcc.usenow": "utiliser immédiatement",
  "SM.LOGIN.TITLE.UPDATE_PWD_CONFIRMPWD": "Confirmer le mot de passe:",
  "agentconsole.authmanage.label.authtype.interface": "Autorisation d'interface",
  "SM.LOGIN.LABEL.RESETPWD": "Cliquez sur Réinitialiser. Le nouveau mot de passe sera envoyé à la boîte aux lettres.",
  "agentconsole.login.indexforipcc.callcontrol": "Contrôle des appels vocaux",
  "agentconsole.header.title.send.notification": "Envoyer une notification",
  "agentconsole.login.indexforipcc.difauth": "Gérez l'organisation, les rôles, les autorisations et les employés du locataire. Après que le personnel du service client se soit connecté en utilisant le compte de l'employé, les boutons de commande d'appel et les menus de page qui peuvent être consultés sont différents selon les différents droits. Ce n'est que lorsque l'utilisateur se connecte mais qu'il n'a pas été vérifié que les opérations liées à l'appel ne peuvent pas être effectuées.",
  "SM.LOGIN.MESSAGE.LOGOUT_REASON.IP_CHECK_FAILED": "Échec de la vérification de l'adresse IP. L'adresse IP actuelle est différente de celle de la première connexion.",
  "agentconsole.menumanage.message.menuname": "Le nombre d'octets doit être inférieur ou égal à 50",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_OPEN_BE_ES": "La zone actuelle est passée à l'état d'urgence. Connectez-vous au système d'urgence pour gérer l'entreprise.",
  "agentconsole.login.indexforipcc.doublecontrol": "Le cloud de service client intelligent comporte deux couches de contrôle d'authentification pour le système de connexion de l'agent :",
  "SM.LOGIN.MESSAGE.SUGGESTION": "Inviter",
  "SM.LOGIN.MESSAGE.SEARCH_HISTORY_CLEAR": "Effacer l'historique",
  "SM.LOGIN.TITLE.ICPCODE": "Numéro d'enregistrement du PIC du Jiangsu: {0}-{1}",
  "agentconsole.common.expiry.prefix": "Votre locataire expirera le",
  "SM.LOGIN.LABEL.OPER_LOGINID": "Compte de connexion:",
  "SM.LOGIN.LABEL.NOTICE_ORG": "OU",
  "agentconsole.quickentrance.classdesc": "Veuillez écrire une note pour ce répertoire. Exemple : Gérer les ressources locataires. Vous pouvez consulter la maintenance des ressources locataires ici.",
  "SM.LOGIN.LABEL.CHANGEPWD_ACCOUNT": "Compte",
  "SM.LOGIN.TIPS.LOGINRESULT": "Résultat de la connexion:",
  "SM.LOGIN.TITLE.CHANGEPWD_ERROR": "Erreur",
  "SM.LOGIN.LABEL.AUTH_FAILED_ES": "La ville actuelle est entrée dans l'état d'urgence. Veuillez vous connecter au système d'urgence.",
  "agentconsole.custinfo.label.amount": "Montant du paiement",
  "agentconsole.sessionmanagement.label.startime": "Heure de début",
  "agentconsole.callmessage.setNumerError": "Impossible de définir le numéro de traitement.",
  "agentconsole.callmessage.query.external.custom.info": "Impossible d'interroger les informations personnalisées externes. Communiquez avec le personnel d'O&M pour consulter les journaux.",
  "SM.LOGIN.LABEL.FRAMEWORK_ONLINE_HELP": "Aide en ligne",
  "agentconsole.sessionmanagement.label.accountNumber": "Compte",
  "SM.INDEX.LABEL.TASK": "Tâche",
  "SM.LOGIN.LABEL.VCODE_INVALID": "Le code de vérification est incorrect.",
  "agentconsole.dashboard.evaluation.unit": "Quantité",
  "SM.LOGIN.LABEL.ERRORPAGE_ERRORNODETAIL_CLOSE": "Détails>",
  "agentconsole.authmanage.message.renamenode": "Renommez l'autorisation.",
  "SM.LOGIN.LABEL.SMS_RECONFIRM_GETCODE": "Obtenir le code de vérification",
  "agentconsole.index.label.querycase": "Cas de requête",
  "agentconsole.workbench.talkNum": "L'appel d'aujourd'hui compte:",
  "agentconsole.login.label.verifycode": "Code de vérification",
  "agentconsole.custinfo.label.dueDate": "Date d'échéance",
  "SM.INDEX.LABEL.CHATDEC": "Lors d'un chat multimédia en ligne avec votre client, vous pouvez afficher l'historique complet des coordonnées du client pour maximiser l'efficacité de votre service et fournir une expérience de service omnicanal à votre client.",
  "SM.SERVLET.BATCH.RESET.PASSWORD.EMPFAIL": "Nombre d'employés ayant échoué:",
  "SM.LOGIN.BUTTON.LOGIN_CONFIRM_MSG": "Le système quittera si vous souhaitez accéder au système du BE. Vous devez entrer le nom d'utilisateur et le mot de passe pour vous connecter. Êtes-vous sûr de vouloir continuer ?",
  "agentconsole.dashboard.title.taskunhandle": "Non-traité",
  "SM.LOGIN.MESSAGE.CHANGEPWD_FAILED": "Impossible de modifier le mot de passe.",
  "agentconsole.sessionmanagement.table.deleteConfimMessage": "Êtes-vous sûr de vouloir supprimer cette session?",
  "agentconsole.wordreminder.norecommend": "Aucun script recommandé disponible.",
  "agentconsole.login.indexforipcc.agent": "Certification de l'agent",
  "agentconsole.login.indexforipcc.transfer": "Transfert d'appel",
  "agentconsole.custinfo.label.custName": "Nom du client",
  "SM.COMMON.MESSAGE.VALIDATE_MINLENGTHB": "Saisissez au moins {0} caractères.",
  "agentconsole.index.label.taskconsole": "Établi de travail sur les tâches",
  "SM.LOGIN.MESSAGE.SENDMAIL_FAILED": "Impossible d'envoyer l'e-mail.",
  "SM.LOGIN.LABEL.OPER_BE": "BE:",
  "agentconsole.authmanage.message.authlimit": "Si l'exception est due à une autorisation insuffisante ou à d'autres raisons, contactez le support technique de Huawei.",
  "agentconsole.index.label.taskdec": "Pendant le traitement des cas, vous pouvez répartir les tâches pour travailler avec plusieurs départements et personnel. Plusieurs tâches peuvent être créées pour un cas.",
  "agentconsole.dashboard.title.casepriority": "Liste des choses à faire",
  "agentconsole.menumanage.message.notdelnoleafmenu": "Ne pas autoriser la suppression du menu avec les sous-menus.",
  "agentconsole.header.title.messageCenter": "Gestion des messages internes",
  "SM.LOGIN.TITLE.PORTAL_SWITCH_PROJECT": "Changer de projet",
  "SM.ORGSTAFF.LABEL.ADDR_PLEASE_INPUT": "S'il vous plaît entrer le nom",
  "agentconsole.login.label.email": "Courriel",
  "SM.LOGIN.BUTTON.SMS_RECONFIRM_CANCEL": "Annuler",
  "SM.LOGIN.LABEL.FORGETPWD_SENDTYPE": "Mode de récupération",
  "agentconsole.authmanage.message.authtype": "Impossible d'entrer le nom de l'autorité. Les caractères spéciaux ne sont pas vides et le nombre d'octets doit être inférieur ou égal à 50.",
  "SM.LOGIN.TITLE.PROJECT_DESC": "Description du projet:",
  "agentconsole.dashboard.title.caseperday": "Statut du traitement quotidien",
  "agentconsole.menumanage.message.createsubnodefail": "Le niveau du menu ne peut pas dépasser 3 niveaux.",
  "SM.LOGIN.TIPS.WELCOME": "Bienvenue à utiliser le BES!",
  "SM.LOGIN.TITLE.LOGIN": "Se connecter",
  "agentconsole.workbench.signInCallNum": "Nombre d'appels de connexion actuels :",
  "SM.LOGIN.LABEL.SMS_RECONFIRM_VCODE": "Code de vérification",
  "SM.LOGIN.BUTTON.UPDATE_PWD_MODIFY": "Modifier",
  "SM.LOGIN.LABEL.OPER_EMAIL": "Courriel :",
  "SM.LOGIN.MESSAGE.SUM": "Total :",
  "SM.LOGIN.LABEL.VCODE": "Code de vérification",
  "agentconsole.login.indexforipcc.recordcontrol": "Contrôle d'enregistrement et de lecture",
  "SM.LOGIN.MESSAGE.MODIFY_PWD_SUCCESS": "Mot de passe changé avec succès.",
  "SM.LOGIN.TITLE.PROJECT_CREATE_TIME": "Temps de création:",
  "agentconsole.header.label.searchcase": "Rechercher dans le cas",
  "SM.LOGIN.TITLE.NOTICE_EFF_TIME": "Temps effectif",
  "SM.LOGIN.SSO.IS.INVALID": "La page a expiré, cliquez pour actualiser la page.",
  "agentconsole.history.lastloginfailattempts": "Dernières tentatives de connexion échouées",
  "SM.LOGIN.LABEL.ERRORPAGE_RETURNBTN": "Retour à la page de connexion",
  "agentconsole.menu.extended.apps": "Applications étendues",
  "SM.LOGIN.MESSAGE.SWITCH": "Voulez-vous vraiment passer au système ESOP ?",
  "SM.LOGIN.RESETPWD.FAIL": "Impossible de traiter la demande de réinitialisation du mot de passe. Vérifiez le compte de connexion et l'adresse e-mail.",
  "agentconsole.history.loginip": "IP de connexion",
  "agentconsole.login.indexforipcc.call": "Contrôle des appels vocaux",
  "agentconsole.index.label.task": "Tâche",
  "agentconsole.quickentrance.checkcharacterlength": "Veuillez ne pas entrer plus de {0} caractères.",
  "agentconsole.index.label.entry": "Entrée rapide",
  "agentconsole.quickentrance.information": "Informations",
  "SM.LOGIN.LABEL.FRAME_SEARCH_EMPLOYEE": "Requête des employés",
  "SM.LOGIN.LABEL.FORGETPWD_ACCOUNT": "Compte de connexion",
  "SM.LOGIN.TITLE.UPDATE_PWD_OLDPWD_EMPTY": "L'ancien mot de passe est vide.",
  "agentconsole.modifycustominfo.modifyfailed": "Échec de la modification.",
  "agentconsole.modifycustominfo.modifyfailed.validate": "Échec de la modification,N'est pas conforme à la règle de chaîne de caractères ou à la longueur.",
  "agentconsole.modifycustominfo.modifyfailed.validate.num": "Échec modifié,Ne répond pas aux règles de nombre ou de longueur.",
  "agentconsole.modifycustominfo.modifyfailed.validate.date":"Échec de la modification,Le format de la date est incorrect.",
  "agentconsole.modifycustominfo.modifyfailed.validate.time":"La modification a échoué,Le format de date et d'heure est incorrect.",
  "agentconsole.querycustominfo.msg.specialStrValidateFailed": "Seuls les caractères spéciaux suivants sont pris en charge : {'@'}.-",
  "provision.menuurl.protocol.check": "Observe que o protocolo de rede usado pelo endereço de menu atual não é um protocolo seguro. Se você optar por usar o protocolo de rede, podem existir riscos de segurança.",
  "agentconsole.dashboard.title.taskpriority": "Liste des choses à faire",
  "agentconsole.authmanage.field.auth_name": "Nom de l'autorisation",
  "agentconsole.inputplaceholder.enter_keyword": "Saisissez un mot-clé.",
  "agentconsole.login.indexforipcc.gateway": "Passerelle ouverte",
  "SM.LOGIN.TITLE.UNIFIED_CASHER": "Caissier unifié",
  "SM.LOGIN.TITLE.SHOPPING_CART": "Panier d'achat",
  "SM.LOGIN.LABEL.NOTICE_CREATE_TIME": "Temps de création",
  "SM.LOGIN.RESETPWD.EMPTY": "Le compte de connexion ou l'adresse e-mail est vide.",
  "agentconsole.header.label.searchhelp": "Rechercher dans le centre d'aide",
  "SM.LOGIN.LABEL.NOTICE_CHAN_TYPE": "Type de canal",
  "agentconsole.login.label.service_cloud_title": "Service à la clientèle Cloud",
  "agentconsole.custinfo.title.custinfo": "Informations sur les clients",
  "SM.LOGIN.TITLE.OPERATOR": "Opérateur actuel",
  "SM.LOGIN.TITLE.SEARCH_CONDITION": "Condition de requête",
  "agentconsole.history.passwordremainingdays": "Mot de passe Jours restants",
  "SM.LOGIN.TIPS.REQUIRED": "Ce paramètre est obligatoire",
  "SM.LOGIN.LABEL.FORGETPWD_EMAIL": "Courriel :",
  "SM.LOGIN.LABEL.ADD_FAST_MENU": "Ajouter un menu raccourci",
  "agentconsole.menumanage.message.homepagesubnode": "Impossible d'ajouter un sous-menu à la page d'accueil.",
  "agentconsole.login.restpwd.success": "Réinitialisation du mot de passe avec succès.",
  "SM.LOGIN.TITLE.CHOOSE_SELECT_ORG": "Sélectionner OU",
  "SM.LOGIN.LABEL.HOME": "Page d'accueil",
  "SM.LOGIN.TIPS.MODIFY": "Modifier",
  "agentconsole.modifycustominfo.modifyexternalfailed": "Actuellement, les données de tiers ne peuvent pas être modifiées.",
  "agentconsole.authmanage.paneltitle.authmanage": "Gestion des autorisations",
  "agentconsole.menumanage.label.newmenu": "Nouveau menu",
  "SM.LOGIN.MESSAGE.HAVE_LOCK_USER": "L'utilisateur a été verrouillé. Déverrouillez l'utilisateur ou attendez que le système déverrouille automatiquement l'utilisateur.",
  "SM.LOGIN.LABEL.RECHARGE": "Rechargement",
  "SM.LOGIN.MESSAGE.UNLOCK": "Êtes-vous sûr de vouloir verrouiller le système ?",
  "agentconsole.querycustominfo.msg.noData": "Pas de données",
  "agentconsole.callmessage.setNumerSuccess": "La définition du numéro de manipulation réussie.",
  "agentconsole.dashboard.title.taskdone": "Nombre de tâches gérées",
  "agentconsole.dashboard.title.web": "Page Web",
  "agentconsole.workbench.talkavg": "Durée(s) moyenne(s) d'appel:",
  "SM.LOGIN.TITLE.UPDATE_PWD_NEWPWD": "Nouveau mot de passe:",
  "SM.LOGIN.TIPS.ENTER_PROJECT_NAME": "Saisissez le nom du projet.",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_VERIFY_CODE_NULL": "Le code de vérification est vide.",
  "agentconsole.index.label.querycasenew": "Établi de cas de requête (Nouveau)",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_SWITCH_ES_PREFIX": "L'activité actuelle a été passée à l'état d'urgence. S'il vous plaît visitez",
  "agentconsole.login.indexforipcc.thirdpartysystem": "Les systèmes tiers peuvent être mis en œuvre grâce à une interface intégrée :",
  "SM.LOGIN.TITLE.REGISTERCODE": "Numéro d'enregistrement du site Web de la sécurité publique du Jiangsu: {0}",
  "SM.LOGIN.LABEL.LOCK": "Verrouiller l'écran",
  "agentconsole.workbench.label.callreason": "Raison d'appel",
  "SM.LOGIN.TITLE.UPDATE_PWD_OLDPWD_NEWPWD": "Le nouveau mot de passe ne peut pas être le même que l'ancien mot de passe.",
  "SM.LOGIN.TITLE.NOTICE_MORE": "Plus",
  "SM.LOGIN.MESSAGE.CHANGE_PWD_RULE": "Impossible de changer le mot de passe parce que le nouveau mot de passe ne correspond pas au groupe de règles de mot de passe suivant :",
  "SM.LOGIN.RESETPWD.SUCCESS": "La demande de réinitialisation du mot de passe a été traitée avec succès. Veuillez vérifier l'e-mail.",
  "SM.LOGIN.LABEL.FRAMEWORK_NOTEPAD": "Bloc-notes",
  "agentconsole.menumanage.message.notdelbasemenu": "Ne pas autoriser la suppression du menu public.",
  "agentconsole.quickentrance.newpop": "Ajouter une entrée",
  "agentconsole.login.label.account": "Compte",
  "SM.LOGIN.LABEL.NOTICE_TITLE": "Titre du bulletin",
  "SM.LOGIN.TITLE.FORGETPWD_FINDEMAILBYPWD": "Récupérer les mots de passe par e-mails",
  "agentconsole.login.indexforipcc.advantage": "Avantages du produit",
  "agentconsole.login.indexforipcc.characteristics": "Caractéristiques de l'entreprise",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_BE_SWITCH_ES_PREFIX": "La zone actuelle est passée à l'état d'urgence. Connectez-vous au système d'urgence et accédez au",
  "agentconsole.Intelligent.robot": "Robot intelligent",
  "agentconsole.header.label.servicecloud.home": "Accueil",
  "agentconsole.header.title.news": "Actualités",
  "SM.LOGIN.TIPS.FIRSTLOGINSYSTEM": "Se connecter au système pour la première fois",
  "SM.LOGIN.MESSAGE.LOGOUT_REASON.SESSION_INVALID": "La session en cours a expiré. Veuillez vous connecter à nouveau.",
  "agentconsole.sessionmanagement.label.operation": "Opération",
  "SM.LOGIN.TITLE.UPDATE_PWD": "Changer le mot de passe",
  "agentconsole.workbench.label.callsummary": "Résumé après l'appel",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_VERIFY_CODE_EXPIRE": "Impossible de vérifier le code de vérification.",
  "agentconsole.login.label.remember": "Mémoriser le nom d'utilisateur",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_SEND_SMS_SUCCESS": "Le code de vérification a été envoyé. Veuillez vérifier.",
  "agentconsole.workbench.label.usermenu": "Menu utilisateur",
  "agentconsole.quickentrance.addentrance": "Ajouter une entrée",
  "agentconsole.history.lastloginsuccess": "Dernière connexion réussie",
  "agentconsole.login.indexforipcc.description": "En tant qu'interface essentielle entre les entreprises et les clients, l'IPCC (pour IP Contact Center) fournit des appels vocaux, un service client en ligne et d'autres canaux pour fournir aux clients avec des interrogations, des conseils commerciaux et des services après-vente. Basé sur un environnement réseau moderne à haut débit et haute bande passante, il prend également en charge de nouvelles fonctionnalités telles que les agents en vidéo et la navigation par la voix. Tout en offrant une nouvelle expérience de service aux clients, il explore de nouveaux modèles marketing basés sur la vidéo et l'intelligence artificielle.",
  "SM.LOGIN.LABEL.OPER_ORG": "OU:",
  "SM.LOGIN.LABEL.USERNAME": "Compte",
  "agentconsole.login.label.resetpwd": "Cliquez sur Réinitialiser. Le nouveau mot de passe sera envoyé à la boîte aux lettres.",
  "agentconsole.contacthistory.contactRecord": "Enregistrement de contact",
  "agentconsole.wordreminder.greetings": "Salutations",
  "agentconsole.common.expiry.email": "Adresse e-mail",
  "agentconsole.modifycustominfo.modifysuccess": "Modifié avec succès.",
  "SM.LOGIN.LABEL.FORGETPWD_FINDPWD": "Récupérer le mot de passe",
  "agentconsole.common.label.delete": "Supprimer",
  "agentconsole.login.label.verifycode.error": "Le code de vérification est incorrect.",
  "agentconsole.icontitle.delete": "Supprimer",
  "SM.LOGIN.LABEL.LOGIN_CONFIRM_PREFIX": "Le système détecte que l'adresse système actuelle n'est pas l'adresse système BE domestique (",
  "SM.LOGIN.BUTTON.UPDATE_PWD_RULE": "Règle de mot de passe",
  "SM.LOGIN.TITLE.NOTICE_NUMBER": "Non.",
  "agentconsole.icontitle.create_child": "Ajouter un sous-niveau",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_NOT_SWITCH_ES": "Le système n'est pas commuté à l'état d'urgence. Accédez au menu de production pour effectuer des opérations commerciales.",
  "agentconsole.login.label.refresh_verify_code": "Pas clair, changez-en un autre.",
  "SM.LOGIN.TITLE.OPERATION_FAILED": "L'opération a échoué",
  "agentconsole.login.indexforipcc.difstatus": "Le statut d'agent fait référence à divers états dans lesquels le représentant du service client peut se trouver après s'être connecté,ceci comprenant l'\"état disponible\", l'\"état en conversation\", l'\"état de finition\", l'\"état occupé\" et l'\"état de repos\". En exécutant une tâche comme répondre à un appel ou raccrocher, le représent du service client change de status d'agent et passe ainsi d'un état à l'autre. Sur la base de l'état de l'agent, le système détermine s'il est possible d'attribuer un appel entrant au représentant du service client.",
  "SM.LOGIN.TITLE.PORTAL_PROJECT_TITLE": "Sélectionner un projet",
  "SM.LOGIN.LABEL.FORGETPWD_NUMBER": "Numéro de téléphone portable",
  "agentconsole.modifycustominfo.nomodifyauth": "L'opérateur actuel n'a pas la permission de modifier des données personnalisées.",
  "SM.LOGIN.MESSAGE.SWITCHERROR": "Échec de la commutation.",
  "SM.LOGIN.LABEL.RESETPWD.SUCCESS": "Réinitialisation du mot de passe avec succès.",
  "agentconsole.authmanage.message.createsubnodefail": "Le niveau d'autorisation ne peut pas dépasser 3 niveaux.",
  "agentconsole.login.indexforipcc.qualitycheck": "D:\\Java\\kuaiguidemo\\servicecloud\\agentconsole\\web\\src\\main\\resources\\conf\\servicecloud.agentconsole.i18n_en_US.properties",
  "agentconsole.authmanage.tab.basic_information": "Informations de base",
  "SM.LOGIN.LABEL.NOTICE_ROLE": "Rôle du bénéficiaire",
  "agentconsole.Intelligent.labelname": "Enregistrement vocal intelligent",
  "agentconsole.iolp.labelname": "Demande d'affaires",
  "agentconsole.iolp.entrancename": "Conseils de service",
  "agentconsole.header.label.changelog": "Journal des changements",
  "SM.LOGIN.BUTTON.CHANGEPWD_CANCEL": "Annuler",
  "SM.LOGIN.LABEL.LANG": "Langue",
  "agentconsole.login.indexforipcc.idle": "Le système ne peut attribuer des appels entrants qu'à des agents en \"état d'arrêt\".",
  "SM.LOGIN.LABEL.ACTIVITY": "Activité de marketing",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_VERIFY_FAILED": "La vérification a échoué. Réessayez plus tard.",
  "SM.LOGIN.LABEL.NOTICE_NOTE_TYPE": "Type de bulletin",
  "SM.LOGIN.TITLE.SELECTBE": "Sélectionner BE",
  "agentconsole.dashboard.enumeration.otherday": "Autre date",
  "SM.LOGIN.TITLE.SERACH_MENU": "Entrez le nom du menu.",
  "agentconsole.dashboard.evaluation.high": "5 points",
  "SM.LOGIN.TITLE.CHOOSE_ORG_LIST": "Veuillez sélectionner une organisation.",
  "agentconsole.login.indexforipcc.mute": "muet",
  "SM.LOGIN.LABEL.CUS_LOGIN_REDIRECT": "Connectez-vous et accédez à",
  "SM.LOGIN.TITLE.SELECT_ACCOUNT_TYPE": "Sélectionnez un type de compte.",
  "SM.LOGIN.TITLE.PRINT_FREE_FILLING": "Imprimer ACK Slip",
  "agentconsole.sessionmanagement.label.search": "Requête",
  "agentconsole.menumanage.message.menu_url": "L'adresse du menu ne peut pas être en chinois.",
  "SM.LOGIN.LABEL.NOTICE_CREATOR": "Créateur",
  "SM.LOGIN.LABEL.CHANGEPWD_COMFIRMPWD": "Confirmer le mot de passe",
  "agentconsole.index.label.chat": "Service à la clientèle en ligne",
  "agentconsole.authmanage.rule.tips": "Veuillez sélectionner une autorisation que vous avez personnalisée. L'autorisation prédéfinie par le système ne peut pas être sélectionnée.",
  "agentconsole.login.indexforipcc.title": "Centre d'appels cloud",
  "agentconsole.dashboard.title.casedone": "Nombre de cas traités",
  "SM.SERVLET.BATCH.RESET.PASSWORD.EMPLISTEND": "]",
  "SM.LOGIN.BUTTON.FORGETPWD_EMAIL": "Courriel",
  "agentconsole.login.indexforipcc.lightweight": "Léger",
  "agentconsole.Intelligent.close": "Fermer",
  "agentconsole.header.label.searchcommunity": "Rechercher dans la communauté",
  "agentconsole.dashboard.title.interactioncount": "Quantité totale de contact le jour en cours :",
  "agentconsole.modifycustominfo.noqueryauth": "L'opérateur actuel n'a pas la permission d'interroger des données personnalisées.",
  "agentconsole.common.tips.warning": "Avertissement",
  "SM.LOGIN.TITLE.HELPCENTER": "Centre d'aide",
  "agentconsole.contacthistory.talking": "Session vocale",
  "agentconsole.login.indexforipcc.hold": "Mise en attente des appels",
  "agentconsole.menumanage.tab.basic_information": "Informations de base",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_VERIFY_CODE_MINTIME_ERROR": "Le code de vérification a été généré.",
  "agentconsole.contacthistory.readMore": "Voir plus",
  "agentconsole.authmanage.message.deleteauthfail": "Impossible de supprimer l'autorisation. S'il vous plaît vérifier si la permission a bean référencé par un menu ou donné un rôle.",
  "agentconsole.quickentrance.deleteinfo": "Êtes-vous sûr de vouloir supprimer ce répertoire",
  "SM.LOGIN.RESETPWD.EMAILPATTERN_ERROR": "Adresse e-mail non valide.",
  "SM.LOGIN.TITLE.NONLOCAL_USER_CHANEPWD_ERROR": "Un utilisateur non local ne peut pas modifier le mot de passe.",
  "agentconsole.login.indexforipcc.onepoint": "Le personnel du service à la clientèle peut vérifier dans la plate-forme, répondre aux appels entrants, transférer des appels, mettre en sourdine, changer d'état ou aider.",
  "agentconsole.login.indexforipcc.loginportal": "Connectez-vous au Customer Service Cloud Portal.",
  "SM.LOGIN.BUTTON.UPDATE_PWD_CLOSE": "Fermer",
  "agentconsole.common.button.cancel": "Annuler",
  "SM.LOGIN.BUTTON.LOGIN_CONFIRM_CONTINUE": "Continuer",
  "SM.LOGIN.TITLE.COPYRIGHT": "Copyright {0} {1} Huawei Software Technologies Co., Ltd. Tous les droits sont réservés.",
  "agentconsole.menumanage.message.createfailures": "Échec de la création du menu",
  "SM.INDEX.LABEL.CASEDEC": "Vous pouvez créer un cas pour chaque demande de service de votre client et suivre le cycle de vie complet du traitement de la demande de service client à travers le cas, en veillant à ce que chaque demande client soit clôturée et en améliorant la satisfaction du client.",
  "agentconsole.menumanage.message.sysadmincannotaddmenu": "L'administrateur système actuel ne peut pas créer de menu.",
  "SM.LOGIN.MESSAGE.UNLOCK_FAILED": "Impossible de déverrouiller le compte. Entrez le mot de passe correct.",
  "agentconsole.authmanage.label.newauth": "Nouvelle autorisation",
  "agentconsole.sessionmanagement.input.validatorMessage": "Les caractères spéciaux sauf les traits de soulignement (_), les points (.), et les signes ({'@'}) ne sont pas autorisés.",
  "agentconsole.callmessage.calledNo": "Appelé",
  "agentconsole.login.indexforipcc.difplat": "Facile à intégrer dans différentes plates-formes sans occuper les pages principales du portail et de l'établi",
  "agentconsole.login.indexforipcc.auth": "Contrôle d'accès",
  "agentconsole.index.label.case": "Cas",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_BE_SWITCH_ES": "La zone actuelle est passée à l'état d'urgence. L'entreprise ne peut être traitée qu'après la récupération du système. Connectez-vous au système d'urgence pour gérer d'autres entreprises.",
  "agentconsole.header.title.send": "Envoyer un message interne",
  "agentconsole.dashboard.priority.medium": "Milieu prioritaire",
  "agentconsole.ccnotification.not.currently.on.call": "Pas actuellement en appel",
  "SM.LOGIN.BUTTON.LOGIN_CONFIRM_CANCEL": "Annuler",
  "agentconsole.authmanage.message.selectanauth": "Veuillez sélectionner une autorisation.",
  "SM.LOGIN.BUTTON.SMS_RECONFIRM_CONFIRM": "OK",
  "agentconsole.workbench.label.remarks": "Remarques",
  "SM.LOGIN.TITLE.INFO": "Inviter",
  "SM.LOGIN.LABEL.LOGIN_CONFIRM_CONTINUE": "Continuer à accéder au système actuel",
  "agentconsole.sessionmanagement.table.fail.delete": "Échec de la suppression",
  "SM.LOGIN.BUTTON.CANCEL": "Annuler",
  "SM.LOGIN.TITLE.CHANGEPWD_RULE": "Le nouveau mot de passe doit respecter les règles suivantes :",
  "SM.LOGIN.BUTTON.FORGETPWD_SMS": "SMS",
  "SM.LOGIN.MESSAGE.LOGOUT_REASON.FORCE_CHANGE_PASSWORD": "Modifiez le mot de passe après la connexion.",
  "SM.LOGIN.TITLE.CURRENT_SELECTED_PROJECT": "Sélectionné:",
  "SM.LOGIN.LABEL.FRAMEWORK_AD_DEMAND": "Annonce à la demande",
  "SM.LOGIN.LABEL.NOTICE_GRADE_URGENT": "Rush",
  "SM.LOGIN.LABEL.CHANGEPWD_NEWPWD": "Nouveau mot de passe",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_BIND_INFO_NULL": "Le compte de connexion ou les informations de liaison sont vides.",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_SEND_SMS_FAILED": "Impossible d'envoyer le message SMS. Vérifiez le journal du backend.",
  "SM.LOGIN.TITLE.UPDATE_PWD_NEWPWD_CONFIRMPWD": "Le nouveau mot de passe doit être le même que le mot de passe de confirmation.",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_VERIFY_CODE_DATE_INVALID": "Impossible de vérifier le code de vérification.",
  "SM.LOGIN.MESSAGE.REPEAT_SUBMITED": "La demande a été envoyée. Veuillez patienter...",
  "agentconsole.dashboard.title.unassigned": "Sans affectation",
  "SM.LOGIN.TIPS.SAVE": "Enregistrer",
  "SM.LOGIN.MESSAGE.NONLOCAL_USER_RETRIEVEPASSWORD_FAILED": "Impossible de récupérer le mot de passe. Les utilisateurs non locaux ne peuvent pas utiliser la fonction de récupération de mot de passe.",
  "SM.LOGIN.TITLE.PASSWORD_INIT": "Vous utilisez le mot de passe initial. Changez le mot de passe immédiatement.",
  "SM.LOGIN.BUTTON.UPDATE_PWD_MODIFY_SUCCESS": "Modifié avec succès.",
  "agentconsole.dashboard.title.interactionperday": "Quantité de contact quotidien",
  "SM.PORTAL.LABEL.INPUT_CFM_PWD": "Confirmez le nouveau mot de passe.",
  "SM.LOGIN.TITLE.CHANGEPWD_SUCCESS": "Inviter",
  "SM.LOGIN.LABEL.COPYRIGHT": "Droits d'auteur © Huawei Technologies Co., Ltd. 2018.",
  "SM.LOGIN.TITLE.PASSWORD_WILLEXPIRED": "Votre mot de passe est sur le point d'expirer. Veuillez changer le mot de passe.",
  "agentconsole.dashboard.title.taskcreate": "Nombre de tâches créées",
  "agentconsole.dashboard.title.task": "Aperçu de la tâche",
  "SM.LOGIN.BUTTON.QUERY": "Requête",
  "agentconsole.login.restpwd.fail": "La réinitialisation du mot de passe a échoué.",
  "SM.LOGIN.LABEL.AUTH_FAILED": "Compte ou mot de passe incorrect.",
  "agentconsole.login.label.reset": "Réinitialiser",
  "SM.LOGIN.TIPS.LASTLOGINTIME": "Dernière heure de connexion:",
  "SM.LOGIN.LABEL.SYSTEM_ERROR": "Erreur système.",
  "agentconsole.dashboard.priority.high": "Priorité élevée",
  "SM.LOGIN.TITLE.NOTICE_LIST": "Liste des bulletins",
  "agentconsole.login.label.username": "Nom d'utilisateur",
  "agentconsole.index.label.chatdec": "Lors d'un chat multimédia en ligne avec votre client, vous pouvez afficher l'historique complet des coordonnées du client pour maximiser l'efficacité de votre service et fournir une expérience de service omnicanal à votre client.",
  "agentconsole.ccnotification.has.been.sent": "A été envoyé",
  "SM.LOGIN.TITLE.UPDATE_PWD_MODIFY_FAILED": "Échec de la modification",
  "SM.LOGIN.TITLE.BE_CATALOG": "Catalogue BE:",
  "agentconsole.index.label.casedraft": "Mes cas brouillons",
  "agentconsole.login.indexforipcc.opengateway": "Passerelle ouverte",
  "agentconsole.login.label.forgot": "Mot de passe oublié ?",
  "agentconsole.sessionmanagement.table.success.delete": "Succès de suppression",
  "agentconsole.callmessage.title": "Information de l’appel entrant ",
  "SM.LOGIN.TITLE.SMS_RECONFIRM": "Reconfirmation SMS",
  "agentconsole.quickentrance.deleteclass": "Supprimer ce répertoire",
  "agentconsole.login.indexforipcc.thirdparty": "Appel à trois voies",
  "agentconsole.sessionmanagement.table.deleteconfim": "Confirmer la suppression",
  "agentconsole.login.indexforipcc.sequence": "Requête d'informations en file d'attente",
  "SM.LOGIN.TITLE.UPDATE_PWD_LOGINID": "Compte:",
  "SM.LOGIN.LABEL.FAST_ENTER": "Entrée rapide",
  "agentconsole.menumanage.field.url": "URL du menu",
  "agentconsole.login.indexforipcc.statecontrol": "Contrôle de l'état de connexion",
  "agentconsole.common.expiry.suffix": "Attention : si vous souhaitez continuer à l'utiliser, contactez le personnel d'exploitation.",
  "agentconsole.login.indexforipcc.signin": "Le personnel du service à la clientèle qui doit traiter les appels vocaux s'enregistre sur la plate-forme CTI.",
  "agentconsole.login.indexforipcc.answer": "Répondez à l'appel",
  "agentconsole.authmanage.message.notdelnoleafauth": "Ne pas autoriser la suppression de l'autorisation avec les sous-autorisations.",
  "agentconsole.quickentrance.managemententrance": "Gestion des entrées",
  "SM.LOGIN.TITLE.APPEND_MENU_SEARCH": "Critères de recherche :",
  "agentconsole.authmanage.label.authtype.operation": "Autorisation d'opération",
  "SM.LOGIN.TITLE.APPEND_MENU_TITLE": "Ajouter un menu raccourci",
  "agentconsole.dashboard.title.todo": "Liste des choses à faire",
  "SM.CHANGELANGAUGE.REFRESH.MESSAGE": "La page entière sera rechargée après avoir changé de langue. Êtes-vous sûr de vouloir continuer ?",
  "agentconsole.sessionmanagement.tip.error": "Erreur",
  "agentconsole.callmessage.callskill": "Skill Queue",
  "agentconsole.faqwordreminder.norecommend": "Pas de FAQ recommandée disponible",
  "SM.LOGIN.TITLE.UPDATE_PWD_OLDPWD": "Ancien mot de passe:",
  "SM.LOGIN.MESSAGE.SELECT_PROJECT": "Sélectionnez un projet.",
  "agentconsole.icontitle.create_sibling": "Ajouter un niveau pair",
  "SM.LOGIN.LABEL.PASSWORD": "Mot de passe",
  "agentconsole.callmessage.faq.reminder": "FAQ recommandée",
  "SM.SERVLET.BATCH.RESET.PASSWORD.EMPLIST": "Réinitialisation des mots de passe de l'utilisateur de location par lots : [liste des employés :",
  "SM.LOGIN.MESSAGE.CHANGEPWD_SUCCESS": "Le mot de passe est changé avec succès.",
  "agentconsole.login.indexforipcc.opengatewayde": "L'Open Gateway fournit une interface standard basée sur le protocole RESTful pour la fonction d'appel vocal du système tiers cloud de service client intelligent intégré. Le système tiers peut mettre en œuvre le centre d'appels dans son propre système en achetant le service sans acheter son propre équipement de centre d'appels.",
  "SM.LOGIN.LABEL.SETTINGS": "Paramètres",
  "SM.LOGIN.MESSAGE.MENU_ACCESS_TIME_NOT_MATCH": "L'heure actuelle n'est pas dans le temps d'accès autorisé par le menu. Le temps d'accès au menu est le suivant :",
  "agentconsole.menumanage.field.menu_name": "Nom du menu",
  "agentconsole.common.button.save": "Enregistrer",
  "agentconsole.login.restpwd.errorinfo": "Le nom d'utilisateur ou l'adresse e-mail est incorrect.",
  "SM.LOGIN.BUTTON.OK": "OK",
  "SM.LOGIN.TIPS.LOCK": "Verrouiller l'écran",
  "SM.LOGIN.LABEL.NOTICE_GRADE_EXTRA_URGENT": "Urgent",
  "agentconsole.common.tips.information": "Conseils",
  "SM.INDEX.LABEL.TASKDEC": "Pendant le traitement des cas, vous pouvez répartir les tâches pour travailler avec plusieurs départements et personnel. Plusieurs tâches peuvent être créées pour un cas.",
  "agentconsole.dashboard.title.taskperday": "Statut du traitement quotidien",
  "agentconsole.authmanage.message.notdelbaseauth": "Ne pas autoriser la suppression de l'autorisation publique.",
  "agentconsole.ccnotification.not.currently.on.three": "Le processus ne peut pas être lancé dans l'état actuel de l'appel",
  "agentconsole.custinfo.label.custId": "Identifiant client",
  "agentconsole.header.label.home": "Page d'accueil",
  "agentconsole.menumanage.message.deletemenufail": "Impossible de supprimer le menu.",
  "SM.LOGIN.LABEL.ERRORPAGE_ERRORSTACK": "Erreurs:",
  "SM.LOGIN.LABEL.ERRORPAGE_ERRORNODETAIL_OPEN": "Détails<",
  "agentconsole.dashboard.enumeration.today": "Aujourd'hui",
  "agentconsole.header.title.search": "Rechercher",
  "agentconsole.wordreminder.recommend": "Script recommandé",
  "agentconsole.wordreminder.ai.recommend": "Script recommandé par IA",
  "agentconsole.dashboard.priority.low": "Priorité faible",
  "agentconsole.quickentrance.complete": "Complète",
  "SM.LOGIN.TIPS.LOGINIP": "Adresse IP:",
  "SM.LOGIN.LABEL.FINISH": "Finition",
  "SM.LOGIN.MESSAGE.GRAFIC.VERIFY.CODE.REFRESH": "Pas clair, changez-en un autre.",
  "SM.LOGIN.LABEL.RESET": "Réinitialiser",
  "agentconsole.authmanage.message.deleteconfirm": "Êtes-vous sûr de vouloir supprimer l'autorisation?",
  "SM.LOGIN.LABEL.LOGOUT": "Se déconnecter",
  "agentconsole.contacthistory.oneMinAgo": "Quelques minutes avant",
  "SM.LOGIN.TITLE.UPDATE_PWD_CONFIRMPWD_EMPTY": "Le mot de passe de confirmation est vide.",
  "agentconsole.contacthistory.hourAgo": "Il y a des heures",
  "agentconsole.login.indexforipcc.useage": "Le cloud de service client intelligent fournit un cadre de siège qui prend en charge les capacités de multi-location, permettant l'ouverture des agents et combinant les plates-formes CTI et UAP de Huawei pour fournir aux clients des prestations complètes de service voix/vidéo dans le cloud.",
  "SM.LOGIN.TITLE.UPDATE_PWD_OLDPWD_ERROR": "L'ancien mot de passe est incorrect.",
  "agentconsole.dashboard.title.caseunassigned": "Sans affectation",
  "SM.LOGIN.TITLE.PORTAL_SWITCH_CONTEXTDATA": "Commutateur BE",
  "SM.ORGSTAFF.LABEL.ORG_FORBID_TIPS": "Êtes-vous sûr de vouloir désactiver l'organisation ?",
  "agentconsole.ccnotification.max.recipients": "Pas plus de 5 destinataires",
  "SM.LOGIN.LABEL.NOTICE_STATUS": "Statut",
  "SM.LOGIN.BUTTON.UPDATE_PWD_CANCEL": "Annuler",
  "SM.ORGSTAFF.TITLE.ASSIGNE_MANAGER": "Assigner le gestionnaire",
  "agentconsole.login.label.errorinfo": "Le nom d'utilisateur ou le mot de passe est incorrect.",
  "agentconsole.ccnotification.recipient.cannot.empty": "le destinataire ne peut pas être vide",
  "agentconsole.dashboard.title.mobile": "Terminal mobile",
  "SM.LOGIN.LABEL.LOCKED": "Verrouillé",
  "agentconsole.authmanage.message.sysauthsubnode": "Impossible d'ajouter des autorisations secondaires à la gestion du système.",
  "SM.LOGIN.TITLE.SWITCH_ESOP": "Basculer en un clic vers ESOP",
  "agentconsole.quickentrance.all": "Tous",
  "agentconsole.workbench.label.userinfo": "Informations utilisateur",
  "agentconsole.dashboard.enumeration.thressdays": "Les 3 derniers jours",
  "agentconsole.modifycustominfo.nodatamodify": "Aucune donnée n'est modifiée.",
  "agentconsole.login.label.service_cloud_desc": "Une UX claire pour un service d'excellence",
  "SM.LOGIN.LABEL.CANCEL": "Annuler",
  "SM.AUC.MESSAGE.PWDRULE_YES": "Oui",
  "SM.LOGIN.TITLE.NOTICE_CONTENT": "Contenu",
  "agentconsole.callmessage.setNumerLengthError": "Le numéro de manipulation ne peut pas dépasser 24 caractères.",
  "agentconsole.contacthistory.dayAgo": "Quelques jours avant",
  "agentconsole.login.indexforipcc.agentstate": "Contrôle du statut de l'agent, tel que l'agent occupé, disponible, le repos, etc.",
  "SM.LOGIN.TIPS.LOGOUT": "Signé hors du système.",
  "SM.LOGIN.LABEL.OPER_NAME": "Nom d'utilisateur:",
  "SM.LOGIN.MESSAGE.CHANGEPWD_OLDNEW": "Le nouveau mot de passe doit être différent de l'ancien mot de passe.",
  "SM.LOGIN.LABEL.UPDATE_PWD": "Changer le mot de passe",
  "agentconsole.quickentrance.title.toomanymenus": "Le nombre de menus raccourcis est dépassé. Veuillez réduire le nombre de menus raccourcis et sauvegarder à nouveau les paramètres.",
  "SM.ORGSTAFF.MESSAGE.STAFF_DELETE_CONFIRM": "Après la suppression d'un employé, le compte correspondant de l'employé ne peut pas être restauré et peut seulement être recréé. supprimer ou pas?",
  "agentconsole.header.title.case": "Créer un cas",
  "SM.LOGIN.LABEL.NOTICE_GRADE": "Priorité",
  "SM.ORGSTAFF.TITLE.OPERATION": "Opération",
  "SM.LOGIN.MESSAGE.SENDMAIL_SUCCESS": "Le nouveau mot de passe a été envoyé à votre adresse e-mail. Veuillez utiliser le nouveau mot de passe pour vous connecter.",
  "SM.LOGIN.TIPS.LOGINFAILED": "La connexion a échoué.",
  "agentconsole.menumanage.message.deleteconfirm": "Êtes-vous sûr de vouloir supprimer le menu?",
  "agentconsole.dashboard.enumeration.thirtydays": "30 derniers jours",
  "agentconsole.login.indexforipcc.easyuse": "Facile à utiliser",
  "agentconsole.dashboard.title.total": "Total",
  "SM.LOGIN.TIPS.WELCOME_LABEL": "Salutations :",
  "SM.LOGIN.LABEL.EMERGENCY_NOTICE": "Avis d'urgence",
  "agentconsole.login.indexforipcc.deal": "Le service personnel peut traiter l'appel du client comme suit",
  "agentconsole.login.label.cancel": "Annuler",
  "agentconsole.menu.config.center": "Centre de configuration",
  "SM.LOGIN.MESSAGE.SENDSMS_SUCCESS": "Le nouveau mot de passe a été envoyé sur votre téléphone portable. Veuillez utiliser le nouveau mot de passe pour vous connecter.",
  "agentconsole.login.restpwd.emailpattern_error": "Adresse e-mail non valide.",
  "message.center.message.messageManage": "Gestion des messages internes",
  "agentconsole.index.label.dashboard": "Tableau de bord",
  "agentconsole.menumanage.field.auth": "Autorisation du menu",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_SWITCH_ES_SUFFIX": "pour continuer à gérer les affaires.",
  "agentconsole.login.indexforipcc.release": "libération",
  "agentconsole.dashboard.title.chat": "Aperçu du chat en ligne",
  "agentconsole.index.label.querytask": "Tâche de requête",
  "agentconsole.sessionmanagement.tip.success": "Succès",
  "SM.LOGIN.TITLE.NOTICE_CRUM": "Gestion du système > Maintenance du système > Requête de bulletins",
  "agentconsole.header.title.help": "Aide",
  "agentconsole.header.title.send.email.notification": "Message de notification de courrier sortant",
  "agentconsole.login.indexforipcc.calldata": "Demande de données d'appel",
  "agentconsole.dashboard.enumeration.yesterday": "Hier",
  "SM.LOGIN.LABEL.FRAMEWORK_CALENDAR": "Calendrier",
  "agentconsole.contacthistory.nocontactinfo": "Pas d'historique d'interaction",
  "SM.LOGIN.BUTTON.LOGIN": "Se connecter",
  "SM.LOGIN.LABEL.FORGOTPASSWORD": "Mot de passe oublié?",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_BE_SWITCH_ES_SUFFIX": "menu pour gérer les affaires.",
  "SM.LOGIN.MESSAGE.CONFIRM": "Confirmer",
  "SM.LOGIN.TITLE.COMMONTOOLS": "Outils communs",
  "SM.LOGIN.MESSAGE.LOGOUT_REASON.NO_LOGIN": "Vous ne vous êtes pas connecté. Veuillez vous connecter et réessayer.",
  "SM.LOGIN.LABEL.NOTICE_INCLUDE_SUB_ORG_Y": "Oui",
  "agentconsole.dashboard.title.wechat": "WeChat",
  "agentconsole.login.label.verifycode.empty": "Le code de vérification est vide.",
  "SM.LOGIN.LABEL.VCODE_EMPTY": "Le code de vérification est vide.",
  "SM.LOGIN.TITLE.DROPLIST_MENU": "Le menu",
  "agentconsole.contact.customerInfo": "Informations sur les clients",
  "agentconsole.history.lastloginfail": "Dernière connexion échouée",
  "SM.LOGIN.LABEL.NOTICE_INCLUDE_SUB_ORG_N": "Non",
  "agentconsole.history.logintime": "Date",
  "agentconsole.menumanage.paneltitle.menumanage": "Gestion des menus",
  "SM.LOGIN.RESETPWD.ERRORINFO": "Le compte de connexion ou l'adresse e-mail est incorrect.",
  "agentconsole.dashboard.title.evaluation": "Satisfaction de la clientèle",
  "agentconsole.must.be.a.number":"doit être un nombre",
  "WEB.VALIDATOR.RANGE":"Entrez une valeur entre {0} et {1}.",
  "agentconsole.voicetransfer.user" : "Clientèle",
  "agentconsole.voicetransfer.agent" : "Agent",
  "agentconsole.voicetransfer.chatbot" : "ChatBot",
  "agentconsole.title.transcription" : "Transcription",
  "agentconsole.title.agent.overview" : "Présentation de l'agent",
  "agentconsole.agent.assistant.recomend.history" : "Historique des recommandations",
  "agentconsole.agent.assistant.faq.history" : "FAQ Historique",
  "agentconsole.agent.assistant.auto.create.case" : "Créer automatiquement un cas",
  "agentconsole.agent.assistant.recomend.action" : "Action recommandée",
  "agentconsole.agent.assistant.summary" : "Générer un résumé",
  "agentconsole.agent.assistant.case" : "Générer un cas",
  "agentconsole.agent.refine.simple" : "Sencillo",
  "agentconsole.agent.refine.formal" : "Formelle",
  "agentconsole.agent.refine.friendly" : "Amicalement",
  "agentconsole.agent.refine.humorous" : "Humoureux",
  "agentconsole.agent.refine" : "Affiner",
  "agentconsole.agent.please.notice" : "S'il vous plaît, faites attention à la rhétorique",
  "agentconsole.agent.knowledge.recommend" : "Connaissance recommandée",
  "agentconsole.agent.knowledge.map":"carte",
  "agentconsole.agent.knowledge.product.recommend" : "Produits Recommandés",
  "agentconsole.agent.knowledge.product.popular" : "Produits Populaires",
  "agentconsole.agent.knowledge.product.link" : "Découvrir Des Réductions",
  "agentconsole.agent.complaint.risk" : "Risque de plainte",
  "agentconsole.agent.call.context" : "Contexte de l'appel",
  "agentconsole.agent.customer.info" : "Informations sur les clients",
  "agentconsole.agent.session.monitor" : "Moniteur de session",
  "agentconsole.agent.basic.info" : "Informations de base",
  "agentconsole.agent.customer.name" : "Nom du client",
  "agentconsole.agent.phone.number" : "Numéro de téléphone",
  "agentconsole.agent.service.duration" : "Durée d'accès au réseau",
  "agentconsole.agent.customer.brand" : "Marque",
  "agentconsole.agent.customer.status" : "Statut de l'utilisateur",
  "agentconsole.agent.customer.address" : "Lieu d'origine",
  "agentconsole.agent.bussiness.info" : "Informations commerciales",
  "agentconsole.agent.order.status" : "État de l'abonnement",
  "agentconsole.agent.business.usage" : "Utilisation du service",
  "agentconsole.agent.terminal.profile" : "Informations sur le terminal",
  "agentconsole.agent.terminal.vendor" : "Fournisseur de terminal",
  "agentconsole.agent.terminal.model" : "Modèle de terminal",
  "agentconsole.agent.network.standard" : "standard de réseau",
  "agentconsole.agent.generate.summary": "Générer un résumé",
  "agentconsole.agent.summary": "Résumé",
  "agentconsole.agent.submit.success": "Soumettre le succès",
  "agentconsole.agent.intelligent.fill": "Remplissage intelligent TT",
  "agentconsole.agent.intelligent.click": "Remplissage de tickets en un clic",
}