export default {
  "isales.basicinfo.title.chooseCallNo": "برگزیدن شمارۀ تماس",
  "isales.common.title.cancel": "لغو",
  "isales.common.title.close": "بستن",
  "isales.common.title.confirm": "خيلي خب",
  "isales.common.title.dataReset": "بازنشانی",
  "isales.common.title.dataSearch": "جستجو",
  "isales.common.title.fail": "خرابی در",
  "isales.common.title.save": "ذخیره",
  "isales.common.title.ready": "آماده",
  "isales.common.title.view": "نما",
  "isales.common.title.success": "موفقیت آمیز",
  "isales.common.title.operation": "عملیات",
  "isales.common.title.tipsInfo": "اطلاعات",
  "isales.data.import.task.status.complete": "انجام شد",
  "isales.data.import.task.status.error": "خطا",
  "isales.data.import.task.status.executing": "واردات",
  "isales.data.import.task.status.ready": "آماده سازی",
  "isales.data.title.create": "جدید",
  "isales.data.title.edit": "ویرایش",
  "isales.data.title.undoEdit": "لغو ویرایش",
  "isales.data.title.importData": "واردات",
  "isales.data.title.importResult": "واردات پیام",
  "isales.exportdata.message.failinfo": "خرابی در صدور داده",
  "isales.exportdata.message.nodata": "داده ای که با شرایط برخورد داشته باشد را نمی توان صادر کرد!",
  "isales.exportdata.message.successinfo": "صدور داده... جزئیات نما در صدور نتایج",
  "isales.exportdata.title.exportresult": "صدور نتایج",
  "isales.exportdata.title.fail": "خرابی در",
  "isales.exportdata.title.success": "موفقیت آمیز",
  "isales.import.message.template.show": "افزودن دادۀ مشتری بر اساس سطر و افزودن شمارۀ تماسهای چندگانه بر ستون",
  "isales.import.message.begintime": "زمان آغاز",
  "isales.import.message.endtime": "زمان پایان",
  "isales.import.message.fail": "واردات داده ها شکست خورد",
  "isales.import.message.numberTooMany": "حداکثر ۵۰۰۰۰ عدد می توانند بارگذاری شوند",
  "isales.import.message.failnum": "تعداد رکوردهای شکست خورد",
  "isales.import.message.pleaseuploadfile": "برگزیدن یک پرونده",
  "isales.import.message.repeatnum": "تعداد ضبطهای تکراری",
  "isales.import.message.result.tip": "خرابی در صدور نتایج انتخاب تفاوت",
  "isales.import.message.status": "وضعیت",
  "isales.import.message.success": "واردات داده... لطفاً ، برای به دست آوردن آخرین داده ، بازآوری را فشار دهید.",
  "isales.import.message.succnum": "تعداد رکوردهای موفق",
  "isales.import.message.taskstatuswrong": "داده را نمی توان برای تکلیف فراخوانی وارد کرد ، زیرا در پیش نویس یا معلق در حالت نیست",
  "isales.import.message.uploadedfileformatvalidate": "الگو پروندۀ نادرست. برگزیدن یک پروندۀ CSV",
  "isales.import.message.uploadedfileNameformatvalidate": "نام پروندۀ نادرست. برگزیدن پرونده ای که فقط نام آن حاوی نویسه های چینی\ ، حروف کوچک\ ، ارقام\ ، یا زیر نظر (_) باشد",
  "isales.import.message.uploadedfiletoolarge": "برگزیدن پرونده ای کمتر از ۲۰ مگابایت",
  "isales.import.title.result": "نمایش خروجی",
  "isales.import.title.statusComplete": "انجام شد",
  "isales.import.title.statusExecuting": "اجرا",
  "isales.import.title.statusReady": "براي اعدام",
  "isales.repeatfile.export.repeatline": "خط تکرار",
  "isales.repeatfile.export.repeatCalledNo": "تکرار فراخوانی شده",
  "isales.failfile.export.failline": "failLine",
  "isales.failfile.export.failCalledNo": "خیر تماس نا پذیر",
  "isales.failfile.export.failReason": "دلیل شکست",
  "isales.input.message.large": "مقدار ورودی بسیار بزرگ است",
  "isales.input.message.small": "مقدار ورودی بسیار کوچک است",
  "isales.management.message.sessionId": "شمارۀ نشست",
  "isales.management.message.calledNo": "شماره تماس گرفته شده",
  "isales.management.message.calledNoEmpty": "شمارۀ تماس گرفته شده خالی است",
  "isales.management.message.calledNoExists": "شمارۀ داده شده از قبل وجود دارد",
  "isales.management.message.calledNoFormatIncorrect": "شمارۀ تماس بیرونی فقط می تواند حاوی ارقام باشد",
  "isales.management.message.sessionIdFormatIncorrect": "اطلاعات نشست تنها می تواند حاوی ارقام باشد",
  "isales.management.message.calledNoLengthError": "شماره ی داده شده بیش از حد است",
  "isales.management.message.calledNoOne": "اولین شماره تماس گرفته شد",
  "isales.management.message.calledNoOneCategory": "اولین تماس در دسته شماره",
  "isales.management.message.calledNoOneInterval": "اولین فاصله عدد تماس گرفته",
  "isales.management.message.calledNoTwo": "شمارۀ فراخوانی دوم",
  "isales.management.message.calledNoTwoCategory": "دومین دسته عدد فراخوانی شده",
  "isales.management.message.calledNoTwoInterval": "فاصله ی عدد فراخوانی دوم",
  "isales.management.message.createFailed": "ایجاد دادۀ تماس خارج از حد ممکن شکست خورد",
  "isales.management.message.createSuccess": "دادۀ ورودی تماس با موفقیت ایجاد شد",
  "isales.management.message.createTaskstatusError": "داده را برای تکلیف فراخوانی خارج نمی توان ایجاد کرد ، زیرا در پیش نویس یا معلق نیست",
  "isales.management.message.deleteConfirm": "مطمئن هستید که می خواهید دادۀ تماس برگزیده بیرونی را حذف کنید ؟",
  "isales.management.message.deleteCount": "حداکثر 100 تماس خارج از خارج شده را می توان در یک لحظه حذف کرد",
  "isales.management.message.deletedataIdsEmpty": "پرچم داده های تماس که باید حذف شود خالی است",
  "isales.management.message.deletedataIdsError": "پرچم نادرست دادۀ تماس ورودی باید حذف شود",
  "isales.management.message.deleteFailed": "حذف دادۀ تماس ورودی شکست خورد",
  "isales.management.message.deleteSuccess": "دادۀ تماس خروجی با موفقیت حذف شد",
  "isales.management.message.deleteTaskstatusError": "تکلیف تماس ورودی را نمی توان حذف کرد ، زیرا در حالت پیش نویس یا وضعیت مکث نیست",
  "isales.management.message.download": "بارگیری الگو دادۀ فراخوانی خروجی",
  "isales.management.message.filter": "دادۀ پالایه",
  "isales.management.message.filterNo": "نه",
  "isales.management.message.filterYes": "بله",
  "isales.management.message.import": "واردات داده های پیوست شده",
  "isales.management.message.importOrder": "واردات دنباله",
  "isales.management.message.orderReverse": "ترتیب نزولی",
  "isales.management.message.orderSequence": "ترتیب صعودی",
  "isales.management.message.taskIdEmpty": "پرچم تکلیف فراخوانی خارج از اینجا خالی است",
  "isales.management.message.upload": "بارگذاری",
  "isales.management.message.uploadSelect": "برگزیدن یک پرونده",
  "isales.management.placeholder.calledNo": "وارد کردن عدد نام گذاری شده",
  "isales.management.placeholder.ivrName": "وارد کردن نام فرایند ربات هوشمند",
  "isales.management.placeholder.commonIvrName": "أدخل اسم تدفق IVR العادي",
  "isales.management.placeholder.commonIvrFlowAccessCode": "وارد کردن یک کد دستیابی جریان مشترک",
  "isales.management.placeholder.skillName": "وارد کردن یک نام صف مهارتی",
  "isales.management.title.calledNo": "شماره ی مشتری",
  "isales.management.title.createNew": "ایجاد دادۀ فراخوانی خروجی",
  "isales.management.title.deleteInfo": "برگزیدن دادۀ تماس خروجی",
  "isales.management.title.details": "جزئیات",
  "isales.management.title.editPolicy": "تغییر سیاست",
  "isales.management.title.edit": "تغییر",
  "isales.management.placeholder.surveyName": "وارد کردن نام پرسشنامه",
  "isales.management.title.extendedField": "حوزۀ گسترش یافته",
  "isales.management.title.import": "واردات دادۀ فراخوانی خروجی",
  "isales.management.title.warning": "اخطار",
  "isales.management.title.callbot": "راه خروجی از گپ",
  "isales.management.title.refresh": "بازآوری",
  "isales.management.title.busiresult": "درجۀ تجاری",
  "isales.management.title.toblocklist": "افزودن به فهرست بلوک",
  "isales.outboundtime.field.hour": "ساعت",
  "isales.outboundtime.field.minute": "دقیقه",
  "isales.outboundtime.message.crossover": "قطع زمانی تماس خارج از محدوده با هم تداخل داره",
  "isales.outboundtime.message.empty": "زمان تماس خارج از محدوده تنظیم شده نیست",
  "isales.outboundtime.title.beginTime": "زمان آغاز",
  "isales.outboundtime.title.clear": "پاک کردن",
  "isales.outboundtime.title.endTime": "زمان پایان",
  "isales.outboundtime.title.period": "قطعه زمان تماس خروجی",
  "isales.outboundtime.title.set": "تنظیم زمان تماس خروجی",
  "isales.outboundtime.title.timePeriod": "قطعه زمان تماس خروجی",
  "isales.specialday.field.beginDate": "تاریخ آغاز",
  "isales.specialday.field.byDate": "بر اساس تاریخ",
  "isales.specialday.field.byWeek": "بر اساس هفته",
  "isales.specialday.field.byYear": "تکرار در سال",
  "isales.specialday.field.endDate": "تاریخ پایان",
  "isales.specialday.field.Friday": "جمعه",
  "isales.specialday.field.Monday": "دوشنبه",
  "isales.specialday.field.Saturday": "شنبه",
  "isales.specialday.field.Sunday": "یکشنبه",
  "isales.specialday.field.Thursday": "پنج شنبه",
  "isales.specialday.field.Tuesday": "سه شنبه",
  "isales.specialday.field.Wednesday": "چهارشنبه",
  "isales.specialday.message.confirmDelete": "مطمئنید که می خواهید داده ها را حذف کنید ؟",
  "isales.specialday.message.createFail": "خرابی در ایجاد تاریخ ویژه",
  "isales.specialday.message.createSuccess": "تاریخ ویژه با موفقیت ایجاد می شود",
  "isales.specialday.message.crossover": "بخش زمانی تماس گرفته شده در تاریخ های خاص هم پوشانی شده است",
  "isales.specialday.message.dateEmpty": "تاریخ ویژه خالی است",
  "isales.specialday.message.dateFormatError": "الگو تاریخ مشخص نادرست",
  "isales.specialday.message.dateParseError": "خطا در تبدیل نوع زمان تاریخ ویژه",
  "isales.specialday.message.deleteFail": "خرابی در حذف تاریخ ویژه",
  "isales.specialday.message.deletePartiallyFail": "برخی تاریخهای خاص حذف شدند",
  "isales.specialday.message.deleteSpecialday": "یک تاریخ ویژه انتخاب کنید",
  "isales.specialday.message.deleteSuccess": "تاریخ خاص با موفقیت حذف می شود",
  "isales.specialday.message.deleteTips": "تاریخ ویژه را نمی توان حذف کرد ، زیرا با یک تکلیف تماس بیرونی پیوند داده می شود",
  "isales.specialday.message.idEmpty": "پرچم تاریخ ویژه خالی است",
  "isales.specialday.message.idError": "پرچم تاریخ مشخص نادرست",
  "isales.specialday.message.isEmpty": "زمان تماس خارج از خارج از تاریخ ویژه تنظیم نیست",
  "isales.specialday.message.nameError": "نام تاریخ ویژه الگو نادرست است",
  "isales.specialday.message.nameExists": "نام تاریخ ویژه از قبل وجود دارد",
  "isales.specialday.message.typeError": "نوع تاریخ نادرست",
  "isales.specialday.message.updateFail": "خرابی در تغییر تاریخ خاص",
  "isales.specialday.message.updateSuccess": "تاریخ خاص با موفقیت تغییر داده می شود",
  "isales.specialday.message.weeklyEmpty": "دوره تاریخ ویژه خالی است",
  "isales.specialday.message.weeklyError": "دورۀ تاریخ مشخص نادرست",
  "isales.specialday.message.weeklyFormatError": "الگو تاریخ ویژه نادرست است",
  "isales.specialday.message.weeklyRepeat": "تکرار دورۀ تاریخ ویژه",
  "isales.specialday.placeholder.searchname": "وارد کردن یک نام تاریخ ویژه",
  "isales.specialday.title.add": "افزودن تاریخ ویژۀ ویژه",
  "isales.specialday.title.addSpecialTime": "افزودن تاریخ ویژۀ ویژه",
  "isales.specialday.title.choose": "برگزیدن تاریخ ویژه",
  "isales.specialday.title.date": "تاریخ ویژه",
  "isales.specialday.title.chooseDate": "برگزیدن تاریخ",
  "isales.specialday.title.create": "ایجاد",
  "isales.specialday.title.createspecialday": "ایجاد تاریخ ویژه",
  "isales.specialday.title.delete": "حذف",
  "isales.specialday.title.scoreDetail": "جزئیات امتیاز",
  "isales.specialday.title.deleteError": "خطا",
  "isales.specialday.title.deleteSuccess": "موفقیت آمیز",
  "isales.specialday.title.edit": "ویرایش",
  "isales.specialday.title.editSpecialday": "ویرایش تاریخ ویژه",
  "isales.specialday.title.holiday": "تاریخ ویژه",
  "isales.specialday.title.holidayName": "نام تاریخ ویژه",
  "isales.specialday.title.holidayType": "نوع تاریخ ویژه",
  "isales.specialday.title.reset": "بازنشانی",
  "isales.specialday.title.search": "پرس وجو",
  "isales.specialday.title.timeSet": "تنظیم زمان تماس خروجی ویژه خروجی تماس",
  "isales.specialday.title.timePeriod": "زمان تماس ویژه اتمام تماس",
  "isales.specialday.title.warning": "اخطار",
  "isales.task.field.callFail": "خرابی در",
  "isales.task.field.callFlagCompletion": "انجام شد",
  "isales.task.field.callFlagExecuted": "براي اعدام",
  "isales.task.field.callFlagProgress": "اجرا",
  "isales.task.field.callSuccess": "موفقیت آمیز",
  "isales.task.message.completionRate": "تکمیل تکلیف",
  "isales.task.message.download": "ضبط بارگیری",
  "isales.task.message.numberCalled": "تماس گرفته شده: {0}",
  "isales.task.message.numberFailed": "سوابق ناموفق",
  "isales.task.message.numberNotcalled": "رکوردهای ناموفق: {0}",
  "isales.task.message.numbersTotal": "مجموع: {0}",
  "isales.task.message.numberSuccessful": "ضبطهای موفقیت آمیز",
  "isales.task.message.pauseFail": "خرابی در معلق کردن تکلیف تماس خارجی",
  "isales.task.message.pauseSuccess": "تکلیف تماس خارج از محدوده با موفقیت معلق می شود",
  "isales.task.message.play": "پخش",
  "isales.task.message.saveFail": "پیکربندی تکلیف فراخوانی خارج از کار شکست خورد",
  "isales.task.message.saveSuccess": "تکلیف تماس خارجه با موفقیت پیکربندی می شود",
  "isales.taskpolicy.message.saveSuccess": "سیاست تماس خارجی با موفقیت پیکربندی می شود",
  "isales.taskpolicy.message.saveFail": "پیکربندی سیاست تماس خارجی شکست خورد",
  "isales.task.message.specialdayEmpty": "تاریخ خاصی انتخاب نشده است",
  "isales.task.message.startFail": "خرابی در آغاز تکلیف تماس خارج از اینجا",
  "isales.task.message.startSuccess": "تکلیف تماس خارجی با موفقیت آغاز می شود",
  "isales.task.message.startWarning": "صدور داده... نمی توانید تکلیف تماس خارجی را آغاز کنید",
  "isales.task.message.statistics": "آمار",
  "isales.task.message.successRate": "موفقیت",
  "isales.task.message.timeIncompletes": "قطعه زمانی یک تماس خارجی کامل نشده است",
  "isales.task.title.basicInfo": "اطلاعات پایه ای",
  "isales.task.title.taskSample": "مدیریت شیء",
  "isales.task.title.callBack": "رد",
  "isales.task.title.callpolicy": "سیاست خروجی تماس",
  "isales.task.title.callTime": "دور بندی تماس خروجی",
  "isales.task.title.complete": "پایان",
  "isales.task.title.nextStep": "بعدی",
  "isales.task.title.preStep": "قبلی",
  "isales.taskinfo.field.beginDate": "زمان آغاز",
  "isales.taskinfo.field.callNo": "شمارۀ تماس",
  "isales.taskinfo.field.endDate": "زمان پایان",
  "isales.taskinfo.field.flowName": "جریان IVR",
  "isales.taskinfo.field.skillName": "صف مهارت",
  "isales.taskinfo.field.taskType": "نوع تکلیف",
  "isales.taskinfo.field.smsTemplate": "الگو پیام کوتاه برای تماس خرابی در خروجی",
  "isales.taskinfo.field.description": "توصیف تکلیف",
  "isales.taskinfo.field.surveyAnswerNum": "تعداد پاسخهای نظرسنجی",
  "isales.taskinfo.field.questionNumber": "تعداد پرسش",
  "isales.taskinfo.field.surveyScore": "بررسی امتیاز کل",
  "isales.taskinfo.message.beginTimeError": "زمان آغاز نمی تواند دیرتر از زمان پایان باشد",
  "isales.taskinfo.message.endTimeError": "زمان پایان باید دیرتر از زمان جاری باشد",
  "isales.taskinfo.message.input": "نویسه های نامعتبر",
  "isales.taskinfo.message.callintervalrange": "لطفاً ، یک عدد صحیح از ۱ تا ۳۶۰۰ وارد کنید",
  "isales.taskinfo.message.nameExists": "نام وظیفه ی فراخوانی خارج از محدوده باید منحصر به فرد باشد",
  "isales.taskinfo.message.require": "تنظیم همۀ حوزه های مورد نیاز",
  "isales.taskinfo.placeholder.choose": "...انتخاب کن",
  "isales.taskinfo.placeholder.input": "...وارد کن",
  "isales.taskinfo.title.chooseIvrFlow": "برگزیدن روند IVR",
  "isales.taskinfo.title.chooseCommonIvrFlow": "اختر IVR عادي",
  "isales.taskinfo.title.chooseSkill": "برگزیدن صف مهارت",
  "isales.taskinfo.title.ivrFlow": "روبات باهوش",
  "isales.taskinfo.title.commonIvrFlow": "IVR مشترك",
  "isales.taskinfo.title.skillName": "صف مهارت",
  "isales.taskinfo.title.chooseSmsTemplateQuery": "برگزیدن الگو پیام کوتاه",
  "isales.taskinfo.title.smsTemplate": "الگو پیام کوتاه",
  "isales.taskmanagement.field.intelligence": "تماس هوشمند خارج از محدوده",
  "isales.taskmanagement.field.Automatic": "تماس خودکار خارج از خروجی",
  "isales.taskmanagement.field.outboundType": "نوع تماس",
  "isales.taskmanagement.field.prediction": "تماس پیش بینی شده",
  "isales.taskmanagement.field.taskName": "نام تکلیف",
  "isales.taskmanagement.field.taskTime": "مدت زمان",
  "isales.taskinfo.title.chooseSurvey": "برگزیدن پرسشنامه",
  "isales.taskmanagement.field.taskStatus": "وضعیت تکلیف",
  "isales.taskmanagement.message.deleteConfirm": "آیا مطمئنید که می خواهید آن را حذف کنید؟",
  "isales.taskmanagement.message.queryDateError": "تاریخ نادرست. گزینش بعدی",
  "isales.taskmanagement.message.queryFailed": "خرابی در پرس وجو",
  "isales.taskmanagement.message.queryLimit": "تعداد ثبت های روی هر صفحه باید عدد صحیح مثبت کمتر از ۱۰۱۱ باشد.",
  "isales.taskinfo.title.surveyName": "نام پرسشنامه",
  "isales.taskinfo.title.versionNo": "نسخۀ پرسشنامه",
  "isales.taskmanagement.message.queryTime": "وارد کردن یک زمان کامل",
  "isales.taskmanagement.message.timeerror": "زمان آغاز نمی تواند دیرتر از زمان پایان باشد",
  "isales.taskmanagement.title.addAuto": "ایجاد تماس خودکار سیستم",
  "isales.task.message.startTips": "اين وظيفه تمام شده است نمی توان تکلیف تماس خروجی را آغاز کرد",
  "isales.taskmanagement.title.chooseBegintime": "زمان آغاز",
  "isales.taskmanagement.title.begintime": "زمان آغاز",
  "isales.taskmanagement.title.chooseEndtime": "زمان پایان",
  "isales.taskmanagement.title.endtime": "زمان پایان",
  "isales.taskmanagement.title.configData": "دادۀ فراخوانی خارج از خروجی",
  "isales.taskmanagement.title.createTask": "ایجاد",
  "isales.taskmanagement.title.deleteFailed": "خرابی در حذف تکلیف",
  "isales.common.message.choose": "لطفاً ، داده را برگزینید.",
  "isales.taskmanagement.title.deleteSuccess": "تکلیف با موفقیت حذف شد",
  "isales.taskmanagement.title.edit": "ویرایش",
  "isales.taskmanagement.title.editPolicy": "ویرایش سیاست",
  "isales.taskmanagement.title.inputTaskName": "وارد کردن یک نام تکلیف",
  "isales.taskmanagement.title.outbound": "راه خروجی از گپ",
  "isales.taskmanagement.title.pause": "معلق",
  "isales.taskmanagement.title.query": "پرس وجو",
  "isales.taskmanagement.title.queryTimeTip": "اطلاعات",
  "isales.taskmanagement.title.reset": "بازنشانی",
  "isales.taskmanagement.title.start": "آغاز",
  "isales.taskmanagement.title.status": "وضعیت",
  "isales.taskmanagement.title.viewTaskResult": "نتیجه تکلیف",
  "isales.taskmanagment.title.viewstatistics": "مشاهدۀ آمار",
  "isales.taskpolicy.field.connRate": "اتصال خروجی",
  "isales.taskpolicy.field.connRateMethod": "الگوریتم برای اتصال خروجی تماس",
  "isales.taskpolicy.field.experienceMethod": "الگوریتم برای پیشبينی تجربی",
  "isales.taskpolicy.field.failInterval": "فاصله  تماس خروجی",
  "isales.taskpolicy.field.lossRate": "حداقل نرخ از دست دادن تماس",
  "isales.taskpolicy.field.maxAvgCallTime": "حداکثر میانگین مدت زمان تماس",
  "isales.taskpolicy.field.minAvgCallTime": "حداقل میانگین مدت زمان تماس",
  "isales.taskpolicy.field.minChangeTime": "حداقل مدت زمان اقامت",
  "isales.taskpolicy.field.multiSkill": "صف مهارت ترکیبی",
  "isales.taskpolicy.field.notSupportMultiSkill": "نمایندگان دورگه برای ورود و تماس های خارج از مرز پشتیبانی نمی شوند",
  "isales.taskpolicy.field.obCallCount": "تعداد تماس های خروجی به نوبت",
  "isales.taskpolicy.field.obOverTime": "حداکثر مدت زمان پاسخ ",
  "isales.taskpolicy.field.pickupRateMethod": "الگوریتم برای سرعت آف هوک",
  "isales.taskpolicy.field.predictMethod": "الگوریتم برای تماسهای پیش بینی شده",
  "isales.taskpolicy.field.supportMultiSkill": "نمایندگان دورگه برای ورود و تماس های خارج از محدوده پشتیبانی می شوند",
  "isales.taskpolicy.field.talkingRate": "سرعت تماس خارج از خروجی",
  "isales.taskpolicy.field.utilRate": "کاربرد نماینده",
  "isales.taskpolicy.field.utilRateCalculateMethod": "روش محاسبه ی کاربرد نماینده",
  "isales.taskpolicy.field.utilRateCalculateOne": "استفاده از نماینده = مدت زمان کل تماس / مدت زمان کل ورود به سیستم",
  "isales.taskpolicy.field.utilRateCalculateTwo": "استفاده از نماینده = (مدت زمان کل تماس + مدت زمان حالت کاری) / مدت زمان کل ورود به سیستم",
  "isales.taskpolicy.field.utilRateMethod": "الگوریتم برای استفاده از نماینده",
  "isales.taskpolicy.field.waitTime": "زمان  انتظار طرف تماس",
  "isales.taskpolicy.message.input.maxAvgCallTime": "تعداد متوسط تماس نميتونه کمتر از يک مين باشه مدت فراخوانی متوسط",
  "isales.taskpolicy.message.input.minAvgCallTime": "ميانگين، مدت تماس نميتونه بيشتر از مکس باشه مدت فراخوانی متوسط",
  "isales.taskresult.field.failedtooccupytheagent": "اشغال یک نماینده شکست خورد",
  "isales.taskresult.field.failedtopause": "نمی توان آن را معوق کرد ، زیرا آن در حالت معلق است",
  "isales.taskresult.field.failedtoresume": "نمی توان تکلیف را بازیابی کرد ، زیرا تکلیف در حالت معلق نیست",
  "isales.taskresult.field.failsAcdtimeout": "زمان ACD تمام می شود",
  "isales.taskresult.field.failsAgentdevicetimesout": "زمان اشغال نماینده تموم شد",
  "isales.taskresult.field.failsAgentfailswithuser": "برپا کردن یک مجرای صوتی بین یک نماینده و مشترک شکست خورد",
  "isales.taskresult.field.failsAns1": "تلفن متنی (ANS1)",
  "isales.taskresult.field.failsAns2": "تلفن متنی (ANS2)",
  "isales.taskresult.field.failsAns3": "تلفن متنی (ANS3)",
  "isales.taskresult.field.failsAns4": "تلفن متنی (ANS4)",
  "isales.taskresult.field.failsAns5": "تلفن متنی (ANS5)",
  "isales.taskresult.field.failsAutomaticansweringmachine": "طرف فراخوان یک منشی تلفنی خودکار است",
  "isales.taskresult.field.failsAutomaticdevicedetected": "دستگاه های خودکار شناسایی می شوند",
  "isales.taskresult.field.failsBeingchanged": "که بهش میگن طرف تغییر میکنه و تماس گرفته میشه",
  "isales.taskresult.field.failsBusytone": "بوق اشغال",
  "isales.taskresult.field.failsCallattemptfails": "خرابی در فراخوانی واسط تماس خارجه",
  "isales.taskresult.field.failsCalldatatimeout": "تماس زمان خروج داده ها",
  "isales.taskresult.field.failsCalledmodem": "طرف تماس مودم است",
  "isales.taskresult.field.failsCallednumberincorrect": "شمارۀ فراخوانی نادرست در پیش نمایش",
  "isales.taskresult.field.failsCalledpartybusy": "به نام طرف تماس مشغول است",
  "isales.taskresult.field.failsCallqueueoverflow": "صف تماس پر شده",
  "isales.taskresult.field.failsCallrejected": "تماس رد شد",
  "isales.taskresult.field.failsCallwaiting": "انتظار فراخوانی",
  "isales.taskresult.field.failsCpp": 'از "هوکینگ" تماس گرفته',
  "isales.taskresult.field.failsDestinationunreachable": "مقصد در دسترس نیست",
  "isales.taskresult.field.failsDisconnection": "خرابی فراخوانی",
  "isales.taskresult.field.failsDnd": "تماس موقتا رد می شود",
  "isales.taskresult.field.failsDtmf": "تلفن متنی (DTMF)",
  "isales.taskresult.field.failsEdt": "تلفن متنی (EDT)",
  "isales.taskresult.field.failsEmptyorwrong": "شماره غیر قابل دسترس یا نادرست",
  "isales.taskresult.field.failsFak": "به اين ميگن پارتي يه فکس",
  "isales.taskresult.field.failsFaxdetected": "دورنگار آشکار می شود",
  "isales.taskresult.field.failsFaxormodem": "نام پارتی ، یک فکس یا مودم است",
  "isales.taskresult.field.failsFsk": "تلفن متنی (FSK)",
  "isales.taskresult.field.failsHangsupbeforeanswering": "قبل از اينکه تماس گرفته بشه قطع مي کنه",
  "isales.taskresult.field.failsHangsupwhencall": "هنگامی که یک نماینده به تماس با کنترل میله نماینده\ پاسخ می دهد ، تلفن نماینده\ قطع می شود",
  "isales.taskresult.field.failsInsufficientresources": "منابع ناکافی",
  "isales.taskresult.field.failsInvalidstatus": "وضعیت DTE نامعتبر",
  "isales.taskresult.field.failsModemdetected": "یک مودم آشکار می شود",
  "isales.taskresult.field.failsModemorfax": "مودم یا دورنگار آشکار می شود",
  "isales.taskresult.field.failsMusicdetected": "موسیقی آشکار می شود",
  "isales.taskresult.field.failsNoacdresource": "منبعی در ACD موجود نیست",
  "isales.taskresult.field.failsNoagentavailable": "وقتی OBS به حالت آماده باش\ تغییر کند، اگر نمایندهی برای خروج امن یا تماس های خروجی پیش فرض\ در دسترس نباشد، رکوردهای خراب می شوند",
  "isales.taskresult.field.failsNoanswer": "گروه تماس گرفته شد جواب تماس رو نميده",
  "isales.taskresult.field.failsNoreason": "انتقال غیر قید و شرط DTE",
  "isales.taskresult.field.failsNoreply": "گروه تماس گرفته شد جواب تماس رو نميده",
  "isales.taskresult.field.failsNoresource": "منبعی موجود نیست",
  "isales.taskresult.field.failsNoresponse": "طرف تماس جواب نميده",
  "isales.taskresult.field.failsNotintheservicearea": "تلفن هم خاموشه",
  "isales.taskresult.field.failsNumberchange": "شماره تغییر کرده است",
  "isales.taskresult.field.failsNumberreacheslimit": "تعداد تماسهای OBS به حد بالایی می رسد",
  "isales.taskresult.field.failsObsexits": "هنگامی که OBS\ خارج شود، ضبطهای بدون پاسخ لغو و دوباره نوشته می شوند",
  "isales.taskresult.field.failsPhonenumberisnull": "عدد ورودی خالی است",
  "isales.taskresult.field.failsPlayingvoices": "علامت ها نشون ميدن که کليد جفت داره صدا ها رو پخش ميکنه",
  "isales.taskresult.field.failsPoweredoff": "تلفن خاموشه",
  "isales.taskresult.field.failsQueuingtimesout": "زمان خاموش شدن صف و انتشار تماس را پخش می کند",
  "isales.taskresult.field.failsRecordcanceled": "هنگامی که OBS به حالت آماده باش\ تغییر دهد، ضبطهای در حال اجرا لغو می شوند",
  "isales.taskresult.field.failsRecordswrittenback": "هنگامی که OBS خارج می شود (یا یک کار حذف می شود) سوابق ناموفق بازنویسی می شوند.",
  "isales.taskresult.field.failsReleasesbeforecall": "قبل از اینکه مجرای صوتی\ t یک نماینده با استفاده از کنترل میله نماینده، تماسی را آزاد می کند",
  "isales.taskresult.field.failsReleaseswhencall": "هنگامی که یک کانال صوتی در حالت\ برقرار می شود، نمایندهی با استفاده از کنترل میله نماینده تماسی را آزاد می کند",
  "isales.taskresult.field.failsResultnotobtained": "داده های خروجی تماس وقتی نوشته می شود که بعد از زمان تماس هیچ نتیجه ای به دست نمی رسد",
  "isales.taskresult.field.failsResulttimesoutafterpicksup": "نتیجه آشکارسازی تماس پس از زمان خروج ACD",
  "isales.taskresult.field.failsRingbacktone": "نوای تماس",
  "isales.taskresult.field.failsRingingtimesout": "زمان زنگ به صدا دراومده و سکو صداي تماس رو پخش ميکنه",
  "isales.taskresult.field.failsSittone": "رنگ اطلاعات ویژه",
  "isales.taskresult.field.failsSsit": "تون برای ارسال اطلاعات اختصاصی",
  "isales.taskresult.field.failsSubscriberbusy": "طرف تماس مشغول است",
  "isales.taskresult.field.failsSubscriberhangsup": "طرف تماس قطع می کند",
  "isales.taskresult.field.failsSwitchingdevicebusy": "کلید اشغال است",
  "isales.taskresult.field.failsTasknotexist": "هیچ تکلیف تماس خارج از دسترس نیست",
  "isales.taskresult.field.failsTransferbeingalerted": "تماس در حين زنگ زدن گرفته مي شود",
  "isales.taskresult.field.failsTransfernoreply": "تماس پاسخ داده نمی شود",
  "isales.taskresult.field.failsTransferonbusy": "تماس با اشغال به دست ميرسد",
  "isales.taskresult.field.failsTransferunconditionally": "تماس بي قيد و شرط گرفته شده",
  "isales.taskresult.field.failsUnallocatednumber": "عدد اختصاص نشده",
  "isales.taskresult.field.failsUndefinedsignaltone": "تون سیگنال تعریف نشده",
  "isales.taskresult.field.failsUnreachable": "مشترک تلفن همراه غیرقابل دسترس",
  "isales.taskresult.field.failsUserhangsupinthequeue": "همۀ نمایندهها مشغول\ ، و کاربر به صورت فعالی تلفنی را قطع می کنه",
  "isales.taskresult.field.failsUserrejection": "تماس رد شد",
  "isales.taskresult.field.failsWaitresulttimesout": "در انتظار نتايج تماس ها روي سکو",
  "isales.taskresult.field.failsWithoutringing": "تماس بلافاصله قبل از زنگ زدن گرفته می شود",
  "isales.taskresult.field.failsCallRouting": "مسیریابی تماس شکست خورد",
  "isales.taskresult.field.failsAgentHangsUp": "نماینده تلفن رو قطع کرد",
  "isales.taskresult.field.failsAgentCancelingCall": "نماینده تماس رو لغو کرد",
  "isales.taskresult.field.failsAgentDisconnection": "نماینده ارتباط قطع شده",
  "isales.taskresult.field.failsCallTimeout": "زمان تماس خارج شد",
  "isales.taskresult.field.failsResourceOverload": "منابع ناکافی",
  "isales.taskresult.field.failsUnallocatedOrIncorrectNumber": "شماره غیر قابل دسترس یا نادرست",
  "isales.taskresult.field.failsTimeout": "وقت سیستم تمام شد",
  "isales.taskresult.field.failsInvalidUser": "کاربر نامعتبر",
  "isales.taskresult.field.failsInvalidDevice": "دستگاه نامعتبر",
  "isales.taskresult.field.failsNoAnsweringDeviceAvailable": "دستگاه پاسخی موجود نیست",
  "isales.taskresult.field.failsAbnormal": "خطا",
  "isales.taskresult.field.failsInvalidRoutingDevice": "دستگاه مسیریابی نامعتبر",
  "isales.taskresult.field.failsSwitchRestart": "سوئيچ شروع شد",
  "isales.taskresult.field.failsUnknownError": "خطای ناشناخته",
  "isales.taskresult.field.failsCallResourcesInsufficient": "منبع تماس ناکافی",
  "isales.taskresult.field.failsNoValidVDN": "هیچ VDN معتبری موجود نیست",
  "isales.taskresult.field.failsCallFailure": "تماس شکست خورد",
  "isales.taskresult.field.failsFailedRouteAgent": "خرابی در مسیر تماس به نماینده",
  "isales.taskresult.field.failsInvalidSkillQueue": "صف مهارت نامعتبر",
  "isales.taskresult.field.failsFailedRouteSkillQueue": "مسیریابی در صف مهارت شکست خورد",
  "isales.taskresult.field.failsFailedRouteCallToAttendant": "انتقال تماس به کارگزار شکست خورد",
  "isales.taskresult.field.failsFailedAgentIsBusy": "نماینده در حالت بيکاري نيست",
  "isales.taskresult.field.failsCalledOnHook": "نماینده به يه تماس گير افتاده",
  "isales.taskresult.field.success": "موفقیت آمیز",
  "isales.taskresult.field.no": "شماره",
  "isales.taskresult.message.details": "جزئیات",
  "isales.taskresult.message.queryTime": "وارد کردن یک زمان کامل",
  "isales.taskresult.title.busiresultupdate": "پیوستن بيسر سول",
  "isales.taskresult.title.beginTime": "زمان آغاز",
  "isales.taskresult.title.chooseBeginTime": "لطفاً ، زمان آغاز را برگزینید",
  "isales.taskresult.title.calledNo": "عدد خروجی",
  "isales.taskresult.title.callFlag": "وضعیت",
  "isales.taskresult.title.chooseCallFlag": "لطفاً ، وضعیت را برگزینید",
  "isales.taskresult.title.callResult": "نتیجه",
  "isales.taskresult.title.endTime": "زمان پایان",
  "isales.taskresult.title.chooseEndTime": "لطفاً ، زمان پایان را برگزینید",
  "isales.taskresult.title.export": "صدور",
  "isales.taskresult.title.exportQuestion": "صدور پرسش",
  "isales.taskresult.title.trainingQuestion": "پرسشهای آزمون آزمون",
  "isales.taskresult.title.exportResult": "نمایش نتایج",
  "isales.taskresult.title.failCode": "علت",
  "isales.taskresult.title.inputCallerNo": "وارد کردن یک عدد خارج شونده",
  "isales.taskresult.title.queryTime": "اطلاعات",
  "isales.taskresult.title.result": "نتیجه ی تماس",
  "isales.taskresult.title.busisubstatus": "تجارت SubStatus",
  "isales.taskresult.title.busidesc": "توصیف تجاری",
  "isales.taskresult.title.chooseResult": "لطفاً ، نتیجه تماس را برگزینید",
  "isales.taskresult.title.choosebusisubstatus": "لطفاً ، پیش از برگزیدن زیر وضعیت ، نتیجۀ تجاری را برگزینید",
  "isales.taskresult.title.choosebusiresult": "لطفاً ، نتایج کسب و کار را برگزینید",
  "isales.taskresult.title.resultDetail": "جزئیات تماس",
  "isales.taskresultexport.message.begintime": "زمان آغاز",
  "isales.taskresultexport.message.downloadConfirmInfo": "آیا مطمئنید که می خواهید پرونده را بارگیری کنید؟",
  "isales.taskresultexport.message.downloadExportdatafailinfo": "تأیید ناموفق بود. داده های فایل صادر شده را نمی توان پیدا کرد",
  "isales.taskresultexport.message.downloadFail": "خرابی در بارگیری",
  "isales.taskresultexport.message.downloadFilesuffixfailinfo": "خرابی در بارگیری. اطلاعات پروندۀ صادر شده حاوی پسوند نام پرونده نیست",
  "isales.taskresultexport.message.downloadRedischeckfailinfo": "فقط زمانی که بارگیری قبلی کامل شده باشد، یک پرونده را دوباره می توان بارگیری کرد",
  "isales.taskresultexport.message.endtime": "زمان پایان",
  "isales.taskresultexport.message.result": "نتیجه",
  "isales.taskresultexport.title.download": "بارگیری",
  "isales.taskresultexport.title.downloadConfirm": "خيلي خب",
  "isales.taskresultexport.title.exported": "برای صدور",
  "isales.taskresultexport.title.fail": "خرابی در",
  "isales.taskresultexport.title.progress": "صدور",
  "isales.taskresultexport.title.success": "موفقیت آمیز",
  "isales.taskstatistics.field.calling": "در حال تماس",
  "isales.taskstatistics.field.completedcalls": "تماس گرفته شده",
  "isales.taskstatistics.field.notcompletedcalls": "در حال انتظار",
  "isales.taskstatistics.placeholder.called": "تماس گرفته شده: {0}",
  "isales.taskstatistics.placeholder.calling": "تماس: {0}",
  "isales.taskstatistics.placeholder.notcalled": "در انتظار: {0}",
  "isales.taskstatistics.placeholder.statistics": "آمار",
  "isales.taskstatus.field.all": "همه",
  "isales.taskstatus.field.draft": "پیش نویس",
  "isales.taskstatus.field.execute": "اجرا",
  "isales.taskstatus.field.finish": "انقضا داده شده",
  "isales.taskstatus.field.suspend": "معلق",
  "isales.tasktype.field.intelligence": "تماس هوشمند خارج از محدوده",
  "isales.tasktype.field.prediction": "تماس پیش بینی شده",
  "isales.tasktype.field.automatic": "تماس خودکار خارج از خروجی",
  "isales.validate.message.formatError": "وارد کردن یک تاریخ به الگو MM/DD",
  "isales.validate.message.holidayDateComparisonError": "تاریخ پایان نمی تواند زودتر از تاریخ آغاز باشد",
  "isales.validate.message.notSpecial": "نام نمی تواند حاوی نویسه های ویژه باشد",
  "isales.operlog.message.data.import": "وارد کردن داده برای کار {0}",
  "isales.operlog.message.data.downloadtemplate": "بارگیری الگو واردات داده",
  "isales.operlog.message.data.downloadrepeatfile": "بارگیری پروندۀ دادۀ تکراری",
  "isales.operlog.message.data.downloadfailfile": "بارگیری پروندۀ دادۀ شکست خورده",
  "isales.operlog.message.data.downloadexportfile": "دانلود فایل نتیجه کار {0}",
  "isales.statistics.field.addHis": "مجموعه داده های بایگانی شده",
  "isales.statistics.field.addHisYes": "بله.",
  "isales.taskresult.title.queryArchivedData": "دادۀ بایگانی شده پرس وجو",
  "isales.taskresult.field.yes": "بله.",
  "isales.taskmanagement.message.afterNow": "زمان آغاز نمی تواند دیرتر از زمان جاری باشد",
  "isales.taskresult.title.failedDetail": "نام شکست",
  "isales.taskmanagement.field.dailyStatistics": "آمار روزانه",
  "isales.taskmanagement.field.statisticsDate": "تاریخ جمع آوری آمار",
  "isales.taskmanagement.field.statisticsType": "نوع آمار",
  "isales.blacklist.title.calledNo": "شماره تماس گرفته شده",
  "isales.blacklist.title.remark": "ملاحظات",
  "isales.blacklist.placeholder.searchcalledno": "شماره",
  "isales.blacklist.title.createblacklist": "افزودن شمارۀ بلوک",
  "isales.blacklist.title.name": "نام",
  "isales.tasklogic.field.retryDay": "بیشینه ، روزها برای تماس قبل از اتصال",
  "isales.tasklogic.field.retryTimes": "بیشینه ، تعداد تلاشهای تماس روزانه",
  "isales.tasklogic.title.addPolicy": "افزودن سیاست",
  "isales.tasklogic.field.result": "نتیجه",
  "isales.tasklogic.field.retryNumber": "سعی مجدد زمانها",
  "isales.tasklogic.field.callInterval": "فاصله فراخوانی (s)",
  "isales.task.title.calllogic": "منطق تماس خروجی",
  "isales.blacklist.title.restrictTimes": "تعداد بیشینۀ تماسها",
  "isales.blacklist.placeholder.restrictTimes": "وارد کردن تعداد بیشینه تماسها",
  "isales.blacklist.title.restrictPeriod": "دوره محدود",
  "isales.blacklist.title.hour": "هر ساعت",
  "isales.blacklist.title.day": "هر روز",
  "isales.blacklist.message.Description": "تعداد بیشینۀ تماسها در هر ساعت یا روز در دوره ی معتبر",
  "isales.blacklist.message.periodAndTime": "دوره محدودیت و بیشینه تماس ها باید در یک زمان خالی یا خالی رها شوند",
  "isales.blacklist.placeholder.searchname": "نام",
  "isales.blacklist.placeholder.searchremark": "ملاحظات",
  "isales.blacklist.message.requestEmpty": "اعتبارسنجی پارامتر برای افزودن شمارۀ به فهرست بلوک شکست خورد",
  "isales.blacklist.message.createSuccess": "شمارۀ با موفقیت به فهرست بلاک اضافه می شود",
  "isales.blacklist.message.createFail": "خرابی در افزودن شمارۀ بلوکی",
  "isales.blacklist.message.error": "زمانی که شماره به فهرست بلاک اضافه می شود خطایی رخ می دهد",
  "isales.blacklist.message.confirmDelete": "مطمئن هستید که می خواهید اعداد برگزیده را از فهرست بلوک حذف کنید ؟",
  "isales.blacklist.message.deleteBlacklist": "برگزیدن شمارۀ بلوکه ای که باید حذف شود",
  "isales.blacklist.title.deleteError": "خطا",
  "isales.blacklist.message.paramEmpty": "برگزیدن یک عدد بلوکه شده",
  "isales.blacklist.message.deletePartiallyFail": "حذف برخی اعداد از فهرست بلاک شکست خورد",
  "isales.blacklist.title.deleteSuccess": "موفقیت",
  "isales.blacklist.message.deleteSuccess": "شمارۀ با موفقیت از فهرست بلاک حذف شده است",
  "isales.calledNo.message.input": "فقط ارقام مجاز هستند",
  "isales.blacklist.message.calledno.empty": "شماره ای وارد نشده است",
  "isales.blacklist.message.name.reg.empty": "قانون معمولی نام خالی است. تماس با سرپرست برای اضافه کردن آن",
  "isales.blacklist.message.calledno.error": "عدد حاوی نویسه های نامعتبر است",
  "isales.blacklist.message.name.error": "نام حاوی نویسه های نامعتبر است",
  "isales.blacklist.message.remark.error": "نشان ها حاوی نویسه های نامعتبر است",
  "isales.blacklist.message.calledno.exist": "شماره در فهرست بلوک موجود است",
  "isales.blacklist.message.customer.notincall": "مشتری در تماس نیست",
  "isales.blacklist.message.customer.empty": "هیچ مشتری در تماس نیست",
  "isales.taskcalllogic.message.choosePolicy": "لطفاً ، حداقل یک استراتژی پردازش نتیجه را برگزینید.",
  "isales.taskcalllogic.title.resultPolicySet": "استراتژی پردازش اطلاعات",
  "isales.taskcalllogic.title.retryPolicySet": "سياست تماس دادگاه",
  "isales.taskcalllogic.title.resultSetPolicy": "پیکربندی سیاست بر اساس نتایج",
  "isales.taskcalllogic.message.resultTips": "فقط یک استراتژی برای یک نتیجه وجود داره",
  "isales.taskcalllogic.field.customerbusy": "مشتری مشغوله",
  "isales.taskcalllogic.field.customerMissed": "مشتري نتونست",
  "isales.taskcalllogic.field.customerRefused": "مشتری رد کرد",
  "isales.taskcalllogic.field.emptyNumber": "عدد خارج از سرویس",
  "isales.taskcalllogic.field.shutdown": "مشتری تعطیل شد",
  "isales.taskcalllogic.field.notServiceArea": "مشتري در محل خدمت نيست",
  "isales.taskcalllogic.field.fax": "دورنگار\ و غیره",
  "isales.taskcalllogic.field.callTimeout": "اتمام وقت تماس",
  "isales.taskcalllogic.field.callFailed": "خرابی در تماس",
  "isales.taskcalllogic.field.other": "دیگر",
  "isales.management.message.blackCalledNo": "شماره ی فراخوانی در فهرست بلوک است و نمی توان اضافه کرد",
  "isales.taskstatistics.field.failCode": "خرابی علت",
  "isales.taskstatistics.field.failCount": "تعداد تماسهای شکست خورد",
  "isales.appointment.title.appointTime": "زمان تماس ذخیره شده",
  "isales.appointment.title.task": "هم کار کردن تکلیف تماس خروجی",
  "isales.appointment.title.failCode": "خرابی علت",
  "isales.appointment.title.remark": "ملاحظات",
  "isales.appointment.title.call": "تماس",
  "isales.appointment.title.createAppoint": "ایجاد فراخوانی ذخیره شده خارج از خروجی",
  "isales.appointment.title.editAppoint": "تغییر فراخوانی دارای دورۀ پایانی",
  "isales.appointment.placeholder.appointTime": "برگزیدن زمان تماس ذخیره شده",
  "isales.appointment.placeholder.remark": "لطفاً ، اظهار نظرها را وارد کنید",
  "isales.appointment.message.afterNow": "زمان ذخیره شده نمی تواند زودتر از زمان جاری باشد",
  "isales.appointment.message.createSuccess": "تماس ذخیره شده خارج با موفقیت ایجاد می شود",
  "isales.appointment.message.updateSuccess": "فراخوان رزرو شده خارج از خارج با موفقیت تغییر می شود",
  "isales.appointment.message.createFail": "خرابی در ایجاد تماس ذخیره شده خارج",
  "isales.appointment.message.updateFail": "خرابی در تغییر تماس ذخیره شده برای خارج",
  "isales.manual.message.query.agentstatus.error": "هنگام پرس وجوی وضعیت نماینده خطایی رخ داد",
  "isales.manual.message.agentstatus.isnofree": "نماینده فعلی در حالت بیکار نیست",
  "isales.manual.message.task.isnorunning": "تکلیف تماس خروجی فعلی در حالت اجرایی نیست",
  "isales.manual.message.update.fail": "به روزرسانی وضعیت دادۀ فراخوانی شکست خورد",
  "isales.manual.message.data.query.error": "هنگام پرس وجو دادۀ تماس ، خطایی رخ داد",
  "isales.manual.message.data.checklock.error": "داده های تماس خروجی فعلی قفل نشده یا توسط یک نماینده دیگر قفل شده است",
  "isales.manual.message.data.checklock.inblack": "شماره تماس خارج شده در فهرست بلوک است",
  "isales.manual.message.precall.success": "یک تماس خارج از اینجا آغاز شد",
  "isales.manual.title.fail.reason": "خرابی علت",
  "isales.manual.title.result": "نتیجه",
  "isales.manual.title.status": "وضعیت",
  "isales.manual.title.calledNo": "عدد خروجی",
  "isales.manual.title.inputfailcode": "وارد کردن یک علت شکست",
  "isales.manual.title.inputresult": "وارد کردن نتیجه ی فراخوانی بیرونی",
  "isales.manual.title.inputstatus": "وارد کردن وضعیت یک فراخوانی بیرونی",
  "isales.manual.field.callflag.inprocess": "در حال تماس",
  "isales.manual.field.callflag.finish": "انجام شد",
  "isales.manual.field.callflag.return": "عقب",
  "isales.manual.field.result.fail": "خرابی در",
  "isales.manual.field.result.success": "موفقیت آمیز",
  "isales.manual.message.data.isalready.called": "مشتري تماس گرفته و نمي تونه ديگه تماس گرفته باشه",
  "isales.manutask.title.viewdata": "دادۀ فراخوانی خارج از خروجی",
  "isales.manutask.title.viewresult": "نتیجۀ تماس خروجی",
  "isales.manual.message.call.success": "تماس خارج از اینجا با موفقیت انجام شد",
  "isales.manual.message.not.call": "هيچ تماسي گرفته نميشه",
  "isales.manual.message.call.failed": "خرابی در ایجاد تماس خارج از اینجا",
  "isales.manual.message.call.update.error": "به روزرسانی نتایج فراخوانی شکست خورد",
  "isales.manual.message.call.error": "هنگام پردازش نتایج فراخوانی خارجه ، خطایی رخ داد",
  "isales.manualdata.title.lock": "قفل",
  "isales.manualdata.title.release": "انتشار",
  "isales.manualdata.title.order": "حفظ تماس خارج از محدوده",
  "isales.manualdata.title.call": "تماس",
  "isales.manual.title.lock": "قفل",
  "isales.manual.message.confirmLockData": "مطمئنید می خواهید داده های تماس خارج از محدوده را قفل کنید؟",
  "isales.manual.message.empty": "{0} خالی است",
  "isales.manual.message.lock.failed": "قفل کردن دادۀ تماس خروج شکست خورد",
  "isales.manual.message.lock.success": "دادۀ ورودی تماس با موفقیت قفل شدند",
  "isales.manual.message.error": "هنگام به روز سازی داده ، خطایی رخ داد",
  "isales.manual.message.confirmReleaseData": "آیا مطمئنید که می خواهید داده های تماس خارج از محدوده را منتشر کنید؟",
  "isales.manual.message.release.success": "دادۀ ورودی تماس با موفقیت منتشر شدند",
  "isales.manual.message.release.failed": "خرابی در انتشار دادۀ تماس ورودی",
  "isales.manual.message.releaseData": "برگزیدن دادۀ تماس خارج از خارج برای انتشار",
  "isales.manual.message.lockData": "برگزیدن دادۀ تماس بیرونی که قرار است قفل شود",
  "isales.manual.message.taskstatus.error": "تماس اون بيرون در حالت اجرا نيست",
  "isales.appointment.message.callNotAllowed": "نمی توان تماس خارج از آن را انجام دهد، چون نماینده در حالت بیکار یا در حال اشغال نیست",
  "isales.appointment.message.confirmDelete": "آیا مطمئنید که می خواهید تماس های ذخیره شده خارج شده را حذف کنید؟",
  "isales.appointment.message.deleteSuccess": "تماس های ذخیره شده خارج شوند با موفقیت حذف می شوند",
  "isales.appointment.message.deleteFail": "خرابی در حذف تماس های ذخیره شده ی بیرونی",
  "isales.appointment.message.agentNotCalling": "نمی توان یک تماس ذخیره شده را اضافه کرد زیرا نماینده در تماس نیست",
  "isales.taskmanagement.title.viewAppointList": "رزرو",
  "isales.taskmanagement.title.viewAppointListDetail": "جزئیات بازجو",
  "isales.taskmanagement.title.appoint": "حفظ تماس خارج از محدوده",
  "isales.appointment.title.userName": "آغاز شده توسط",
  "isales.appointment.title.callTime": "تماس بر قطعه زمان",
  "isales.manualdata.title.updateCalledNo": "تغییر شمارۀ خروجی",
  "isales.appointment.message.callSuccess": "موفقیت آمیز",
  "isales.appointment.message.callFail": "خرابی در",
  "isales.appointment.message.timeout": "\ t تماس های تعیین شده خارج از زمان قرار قبلی ، لطفاً ، تماسها را در زمان انجام دهید",
  "isales.appointment.message.soontimeout": "%0 تماس های خارج از مرز در ۵ دقیقه آینده می رسند ، لطفاً آماده ی تماس های",
  "isales.taskmanagement.field.manual": "نماینده خارج از محدوده تماس",
  "isales.taskresult.title.userAccount": "آغاز شده توسط",
  "isales.taskresult.title.chooseUserAccount": "لطفاً آغاز شده توسط",
  "isales.taskmanagement.title.addManual": "ایجاد نماینده خارج از تماس",
  "isales.taskinfo.field.noSpecifiedAgent": "تمام صف مهارت",
  "isales.taskinfo.field.specifiedAgent": "نماینده مشخص شده",
  "isales.taskinfo.title.chooseAgent": "برگزیدن نماینده",
  "isales.manual.message.afterNow": "زمان پایان نمی تواند زودتر از زمان جاری باشد",
  "isales.appointment.title.add": "افزودن",
  "isales.agent.addblocklist.numbertype": "نوع عدد",
  "isales.agent.addblocklist.calldatatype": "نوع مشتری",
  "isales.agent.toblocklist.addType": "افزودن نوع فهرست بلوک",
  "isales.data.message.confirmaddblock.calldatatype": "نوع جاری ، دادۀ تماس است. لطفاً ، دوباره تأیید کنید",
  "isales.taskmanagement.title.export": "صدور ضبط تماس",
  "isales.taskmanagement.field.zip.password": "رمز عبور بسته فشرده",
  "isales.taskmanagement.field.zip.input.password": "حداقل هشت نویسه و بیشینه ای از ۱۲ نویسه ای که حاوی رقم\ ، حرف\ و نویسه های ویژه هستند را وارد کنید ، اما حاوی /\\-#(){\'{\'}{\'}\'};&{\'|\'}<>{\'$\'}`\\\\!\\n\\s\\t\\r نیستند",
  "isales.export.message.input.error": "رمز عبور در الگو صحیح نیست",
  "isales.taskmanagement.message.period.seven": "فاصله نميتونه بيشتر از 7 روز طول بکشه",
  "isales.taskmanagement.title.view.callrecord": "ضبط تماس",
  "isales.taskmanagement.field.calledNo": "شماره تماس گرفته شده",
  "isales.taskmanagement.field.calltime": "آغاز در",
  "isales.taskmanagement.field.usersaying": "درخواست متن",
  "isales.taskmanagement.field.answer": "پاسخ متن",
  "isales.taskmanagement.title.callRecord": "ضبط تماس خارج از حد",
  "isales.taskmanagement.title.out.callresult": "نتیجۀ تماس خروجی",
  "isales.taskmanagement.field.intention": "با نيت شناخته شده",
  "isales.taskinfo.message.agentsEmpty": "هیچ نمایندهی انتخاب نشده است",
  "isales.appointment.message.queryTimeError": "زمان آغاز باید زودتر از زمان پایان باشد",
  "isales.taskinfo.field.audioAgentType": "صوتی",
  "isales.taskinfo.field.videoAgentType": "ویدئو",
  "isales.taskinfo.field.mediaAgentType": "چندرسانه ای",
  "isales.taskinfo.field.versatileAgentType": "همه کاره",
  "isales.appointment.message.tasknotrun": "تکلیف خروج تماس فعلی آغاز نشده است. تماس ممنوعه",
  "isales.appointment.message.notInWhiteList": "شماره در فهرست امانت نیست و نمی توان فراخوانی کرد",
  "isales.taskresult.field.failsCallednumbernotinwhitelist": "شماره در فهرست اعتماد خارج از محدوده نیست",
  "isales.taskresult.title.retryTime": "تماس با تلاش",
  "isales.appointment.message.callTimeTooEarly": "زمان تماس زودتر از زمان رزرو شده است. ! تماس ممنوعه",
  "isales.management.message.remarkLengthError": "بیشینه ۱۰۰۰ نویسه مجاز است",
  "isales.management.message.callNoInBlackList": "نمی توان با شماره تماس گرفت ، چون فهرست بلوک است",
  "isales.taskmanagement.field.zip.makesure.password": "وارد کردن مجدد رمز عبور بسته فشرده",
  "isales.taskmanagement.message.password.not.same": "دو رمز عبور متناقض هستند",
  "isales.taskresultexport.message.type": "نوع صدور",
  "isales.taskpolicy.message.input.digits": "وارد کردن یک عدد صحیح",
  "isales.tasktype.field.intelligent": "ربات باهوش از محدوده خارج شد",
  "isales.tasktype.field.predict": "تماس پیش بینی شده",
  "isales.tasktype.field.auto": "مكالمة IVR صادرة عادية",
  "isales.callNo.field.type": "نوع عدد",
  "isales.callNo.field.delayTime": "زمان(های) تاخیر عدم موفقیت تماس",
  "isales.callNo.field.add": "افزودن",
  "isales.callno.message.delayTime": "تماس با شکست زمان تأخیر: فاصله بین شمارۀ جاری و شمارۀ قبلی",
  "isales.data.message.addNumberUpperLimit": "یک بیشینه از ۱۰ عدد می تواند اضافه شود",
  "isales.data.message.duplicateExist": "اعداد تکراری وجود دارند",
  "isales.tasklogic.field.policyType": "PolicyType",
  "isales.tasklogic.field.addCallnoLogic": "افزودن سیاست آشنا",
  "isales.tasklogic.field.dataType": "نوع داده",
  "isales.tasklogic.field.isResultCallnoLogic": "این که آیا بر اساس نتیجه پیکربندی شود یا نه",
  "isales.taskcalllogic.field.globalPolicy": "سیاست سراسری",
  "isales.taskcalllogic.field.callnoPolicy": "سیاست آشنا",
  "isales.tasklogic.title.addCallnoPolicy": "افزودن سیاست نتیجۀ نتیجۀ",
  "isales.tasklogic.field.addDataType": "افزودن DataType",
  "isales.tasklogic.field.existingDataType": "برگزیدن نوع دادۀ موجود",
  "isales.taskcalllogic.message.alreadyLastPolicy": "قبلا آخرین سیاست",
  "isales.data.filed.noResult": "نه.",
  "isales.data.filed.isResult": "بله.",
  "isales.taskcalllogic.message.dataTypeExist": "DataType از قبل وجود دارد",
  "isales.busi.result.title.result": "نتیجه",
  "isales.busi.result.title.description": "توصیف",
  "isales.busi.result.item.unset": "تنظیم نشده",
  "isales.busi.result.item.succ": "موفقیت",
  "isales.busi.result.item.uncertain": "نامشخص",
  "isales.busi.result.item.fail": "خرابی",
  "isales.busi.result.message.recordsucc": "نتیجه ی کسب و کار با موفقیت ضبط شد",
  "isales.busi.result.message.recordfail": "نتیجه ی کسب و کار شکست خورد",
  "isales.busi.result.message.lastrecord": "لطفا نتايج تجاري رو اول تنظيم کنيد",
  "isales.busi.result.message.lastappointrecord": "لطفاً اول نتيجه ي آخرين تماس با بيرون رزرو شده رو تنظيم کنيد",
  "isales.busi.result.message.nodatarecord": "هیچ اطلاعات تماسی یافت نشد. لطفاً ، ضبط تماس خارج از محدوده را که باید نتایج کسب و کار آن تنظیم شود ، انتخاب کنید",
  "isales.data.message.confirmSubmit": "توالی شماره فعلی، توالی تماس است. لطفاً ، داده ها را دوباره بررسی کنید",
  "isales.data.title.tableTitle": "فهرست شماره",
  "isales.callNo.title.no": "نه.",
  "isales.callpolicy.title.add": "افزودن",
  "isales.management.message.updateSuccess": "دادۀ ورودی تماس با موفقیت تغییر یافته است",
  "isales.management.message.updateFail": "خرابی در تغییر دادۀ تماس خروجی",
  "isales.blacklist.title.validityTime": "زمان اعتبار (ساعت)",
  "isales.blacklist.title.expirationTime": "زمان انقضا",
  "isales.blacklist.title.operateAgent": "نماینده اضافه شده",
  "isales.management.title.updateData": "تغییر دادۀ تماس خروجی",
  "isales.management.title.detailData": "جزئیات داده های خارجی تماس",
  "isales.common.message.checknonnegativeinteger": "وارد کردن یک عدد صحیح غیر منفی در الگو درست",
  "isales.common.message.checkpositiveinteger": "وارد کردن یک عدد صحیح مثبت در الگو صحیح",
  "isales.taskcalllogic.message.mostPolicys": "حداکثر 10 سیاست نتیجه را می توان برای یک عدد پیکربندی کرد",
  "isales.tasklogic.field.callresultpolicy": "سیاست نتیجه تماس خروجی",
  "isales.tasklogic.field.busiresultpolicy": "سیاست کسب و کار",
  "isales.tasklogic.field.enablebusiresultpolicy": "فعال سازی سیاست نتیجه تجاری",
  "isales.tasklogic.field.connectsameagent": "اتصال به یک کارگزار",
  "isales.tasklogic.field.addbusiresultpolicy": "افزودن سیاست رفع تجاری",
  "isales.tasklogic.field.substate": "زیرمد",
  "isales.tasklogic.field.policy": "سیاست",
  "isales.tasklogic.field.interval": "فاصله (ثانیه)",
  "isales.common.title.edit": "ویرایش",
  "isales.taskcalllogic.busiresultpolicy.stopcall": "تماس رو متوقف کن",
  "isales.taskcalllogic.busiresultpolicy.callnext": "تماس با شماره ی بعدی",
  "isales.taskcalllogic.busiresultpolicy.callcurrent": "تماس با شمارۀ جاری",
  "isales.tasklogic.message.busiresultpolicyIncomplete": "اطلاعات سیاست نتیجه تجاری ناقص است",
  "isales.tasklogic.message.busiresultpolicyNoadded": "سیاست نتیجه تجاری اضافه نمی شود",
  "isales.tasklogic.message.busiresultpolicyNotsaved": "برخی از سیاست های نتیجه ی کسب و کار ذخیره نمی شوند",
  "isales.tasklogic.message.busiresultpolicySame": "نتايج کسب و کار و چندين کشور از سياست هاي کسب و کار يکي هستند",
  "isales.tasklogic.message.busiresultpolicyExists": "یک سیاست نتیجه تجاری با همان نتیجه کسب و کار و زیر ایالت از قبل وجود دارد.",
  "isales.business.result.searchname": "وارد کردن نام نتیجۀ تجاری",
  "isales.business.result.name": "نام نتیجه کسب و کار",
  "isales.business.result.code": "کد نتیجه کسب و کار",
  "isales.business.result.description": "شرح نتیجه کسب و کار",
  "isales.business.result.create": "ایجادنۀ تجاری",
  "isales.business.result.edit": "تغییر پیش فرض تجاری",
  "isales.business.result.view": "نمایش پیش نمایش پیش نمایشی",
  "isales.business.result.children": "زیرسطوط",
  "isales.business.name": "نام",
  "isales.business.code": "کد",
  "isales.business.description": "توصیف",
  "isales.business.result.addchildren": "ایجاد فرعی",
  "isales.business.result.deletetips": "آیا مطمئنید که می خواهید نتایج کسب و کار را حذف کنید؟",
  "isales.business.result.delete.error": "نتیجه ی کسب و کار انتخاب نشده است",
  "isales.business.result.addchildrentips": "حداکثر 50 کسب و کار مجازه",
  "isales.business.result.message.specialStr": "غير مدعوم",
  "isales.business.result.message.checkCode": "فقط ارقام\ ، حروف\ و خط فاصله ( مجاز هستند",
  "isales.business.result.delete.data.error": "حداکثر 100 رکورد را مي توان در يک لحظه حذف کرد",
  "isales.tasklogic.message.busiresultpolicyNotSaved": "اول سیاست نتیجه تجاری موجود را ذخیره کنید!",
  "isales.taskpolicy.field.previewTimeoutInterval": "پیش نمایش فاصله زمانی (های)",
  "isales.tasktype.field.preview": "پیش نمایش تماس خروجی",
  "isales.manual.field.previewinformation": "اطلاعات پیش نمایش",
  "isales.manual.field.handlednumber": "شمارۀ نشست",
  "isales.manual.field.callednumber": "شماره تماس گرفته شده",
  "isales.manual.field.callinginformation": "اطلاعات تماس",
  "isales.manual.field.callingtask": "تماس با تکلیف",
  "isales.taskresult.title.userName": "نام کاربر",
  "isales.taskresult.title.operateBeginTime": "زمان آغاز",
  "isales.taskresult.title.operateEndTime": "زمان پایان",
  "isales.taskresult.title.operationTime": "زمان عمل",
  "isales.taskresult.title.busiresultLog": "ثبت پاسخ تجاری",
  "isales.manual.message.release.DataNotWaitExists": "دادۀ تماس خروجی که فراخوانی یا کامل می شود وجود دارد",
  "isales.manual.message.dataId.Empty": "داده نمی تواند خالی باشد",
  "isales.busi.result.message.agentstatus.fail": "خرابی در پرس وجو وضعیت نماینده",
  "isales.busi.result.message.systemparams.fail": "خرابی در پرس وجوی پارامترهای سیستم",
  "isales.business.result.message.add.tips": "نتیجه کسب و کار تعریف نشده است",
  "isales.business.result.message.must": "این پارامتر الزامی است",
  "isales.blacklist.title.modifyRemark": "تغییر نشان",
  "isales.blacklist.message.updateSuccess": "تغییر نشان موفقیت بزرگ",
  "isales.taskresult.field.userisunleashed": "تماس به کارساز رسانه ای که نماینده به آن وارد می شود، آزاد می شود",
  "isales.taskresult.field.invalidCall": "تماس نامعتبر",
  "isales.taskresult.field.notCheckIn": "نماینده ثبت نشده",
  "isales.manual.title.viewManualData": "نماینده خارج از محدوده تماس",
  "isales.manual.title.viewManualResult": "نتیجه ی نماینده خارج از محدوده تماس",
  "isales.manual.title.viewPreviewResult": "پیش نمایش خروجی فراخوانی شده",
  "isales.manual.title.viewPredictionResult": "نتیجه تماس پیش بینی شده",
  "isales.taskresult.field.workNo": "WorkNo",
  "isales.taskresult.field.skillqueue": "صف مهارت",
  "isales.taskresult.field.operation": "عملیات",
  "isales.agent.field.skill": "SkillName",
  "isales.agent.message.pwdRepeat": "رمز عبور ارسال شده مانند رمز عبور تغییر شده اخیر است",
  "isales.data.prop.publish": "انتشار",
  "isales.data.prop.status.published": "منتشر شد",
  "isales.taskresult.field.other": "دیگر",
  "isales.taskmanagement.title.speclistmanage.reason": "علت",
  "isales.speclistmanage.title.calledNo": "شماره",
  "isales.speclistmanage.title.reason": "دليل",
  "isales.speclistmanage.title.operation": "عملیات",
  "isales.specreason.field.all": "لطفا انتخاب کنید",
  "isales.specialday.title.confirm": "تأیید",
  "isales.tasklogic.field.addOrderPolicy": "افزودن Prop",
  "isales.tasklogic.field.prop": "توت",
  "isales.skillqueue.agentrole.administrator": "مدیر اتاق",
  "isales.skillqueue.agentrole.qc": "کیفیت بازرس",
  "isales.data.prop.orderType": "نوع ترتیب",
  "isales.data.orderType.asc": "sغویه",
  "isales.data.orderType.desc": "رو به",
  "isales.speclist.blacklist.message.moveSuccess": "شمارۀ انتقال داده شده به فهرست سیاه با موفقیت",
  "isales.speclist.blacklist.message.movePartiallyFail": "خرابی در انتقال عدد به فهرست سیاه",
  "isales.taskinfo.field.callTimes": "تماس با تلاش",
  "isales.taskmanagement.title.batchStartTask": "آغاز دسته",
  "isales.taskmanagement.title.batchPauseTask": "معلق دسته",
  "isales.taskmanagement.title.batchEdit": "تغییر دسته",
  "isales.taskmanagement.title.batchEditTaskInfo": "تغییر اطلاعات پایه ای دسته",
  "isales.taskmanagement.title.batchEditCallTime": "زمان تماس دسته",
  "isales.taskmanagement.title.batchEditCallPolicy": "تغییر سیاست خروجی تماس دسته",
  "isales.taskmanagement.title.batchEditCallLogic": "تغییر دادن خروجی تماس دسته ای",
  "isales.taskmanagement.title.tipsBatchStartTask": "برگزیدن تکلیفی که آغاز می شود",
  "isales.taskmanagement.title.batchStart.finishedStatus": "تکلیف های انجام شده را نمی توان آغاز کرد",
  "isales.taskmanagement.title.batchPause.statusError": "تکلیف های در پیش نویس یا منقضی شده را نمی توان معلق کرد",
  "isales.taskmanagement.title.startCount": "حداکثر 30 وظيفه ي تماس را مي توان در زمان شروع کرد",
  "isales.taskmanagement.title.startConfirm": "مطمئن هستید که می خواهید تکلیف تماس برگزیده خارج از مرز را آغاز کنید ؟",
  "isales.taskmanagement.title.tipsBatchPauseTask": "برگزیدن تکلیفی که باید معلق شود",
  "isales.taskmanagement.title.pauseCount": "حداکثر 30 نمایندهيت تماس در يک لحظه معلق ميشه",
  "isales.taskmanagement.title.pauseConfirm": "مطمئن هستید که می خواهید تکلیف تماس برگزیده خارج از مرز را به تعویق کنید؟",
  "isales.taskmanagement.title.batchEditTaskInfo.errStatus": "برگزیدن تکلیفی در پیش نویس\ ، معلق شده ، یا در حال اجرا",
  "isales.taskmanagement.title.batchEditCallPolicy.sameTaskType": "سیاست های خروجی تماس را می توان در بسته ها فقط برای تکالیف از نوع مشابه تغییر داد",
  "isales.taskmanagement.title.batchEditCallTime.manualError": "زمان تماس دستی را نمی توان تغییر داد",
  "isales.taskmanagement.title.batchEditTaskInfo.manual": "تماس خروجی خودکار و تماس خروجی دستی را نمی توان در یک زمان تغییر داد",
  "isales.taskmanagement.title.batchEditCallPolicy.manualError": "سیاستهای تماس خارج از دستی نمی توان تغییر کرد",
  "isales.task.message.NoStartTask": "هیچ کاری که شرایط را ملاقات کند موجود نیست",
  "isales.task.message.NoPauseTask": "هیچ کاری که شرایط را ملاقات کند موجود نیست",
  "isales.taskinfo.field.priority": "اولویت",
  "isales.taskmanagement.title.editInfo": "تغییر اطلاعات",
  "isales.taskmanagement.title.editSample": "مدیریت شیء",
  "isales.taskmanagement.title.editTime": "تغییر زمان",
  "isales.taskmanagement.title.editLogic": "تغییر & ثبت",
  "isales.taskpolicy.field.calculationFactor": "محاسبه",
  "isales.taskpolicy.field.pickupRate": "_ارزخ",
  "isales.taskresult.title.inputSessionId": "أدخل رقم جلسة العمل",
  "isales.taskstatus.field.waiting": "در حال انتظار",
  "isales.taskstatus.field.callfinish": "انجام شد",
  "isales.taskInfo.message.saveSuccess": "اطلاعات با موفقیت اصلاح شده است",
  "isales.taskInfo.message.saveFail": "خرابی در تغییر اطلاعات",
  "isales.tasklogic.field.callpolicy": "تماس با سیاست",
  "isales.tasklogic.field.callmethod": "حالت فراخوانی",
  "isales.tasklogic.field.callbyorder": "در دنباله",
  "isales.tasklogic.field.callbyturn": "بر اساس گرد",
  "isales.tasklogic.field.callturntype": "حالت گرد",
  "isales.tasklogic.field.callturntype.numbertype": "بر اساس نوع عدد",
  "isales.tasklogic.field.callturntype.numberorder": "بر اساس شمارۀ دنباله",
  "isales.tasklogic.field.addturn": "افزودن دور",
  "isales.tasklogic.field.turn": "گرد",
  "isales.tasklogic.field.numberorder": "شمارۀ دنباله",
  "isales.tasklogic.field.numbertype": "نوع عدد",
  "isales.tasklogic.field.add.numbertype": "نوع عدد جدید",
  "isales.tasklogic.field.exist.numbertype": "نوع عدد موجود",
  "isales.tasklogic.field.default": "پیش فرض",
  "isales.tasklogic.message.no.numbertype": "توالي شماره صفره",
  "isales.tasklogic.message.exist.orderNo": "دنباله عدد از قبل وجود دارد. یک دنباله دیگر را وارد کنید",
  "isales.tasklogic.message.need.turns": "افزودن دادۀ حداقل یک دور",
  "isales.taskpolicy.field.previewAutoCallTime": "مدت زمان تماس خروجی پیش‌نمایش‌شده",
  "isales.taskinfo.message.autoCallTimeValueError": "مدت تماس خروجی پیش نمایش شده باید کوتاه تر از مدت زمان اتمام پیش نمایش باشد",
  "isales.taskinfo.message.batchUpdate.paramsNull": "ویرایش پارامتری که باید تغییر داده شود",
  "isales.agent.contact.Voice": "صدا",
  "isales.agent.contact.multimedia": "چندرسانه ای",
  "isales.agent.contact.vidio": "ویدئو",
  "isales.agent.contact.audioclicktodial": "فشار صدا به شماره",
  "isales.agent.contact.videoclicktodial": "کلیک ویدئویی به شماره گیر",
  "isales.agent.message.searchSkillName": "وارد کردن نام صف مهارت",
  "isales.agent.button.agent.association": "پیکربندی نماینده",
  "isales.agent.title.skillCreate": "ایجاد صف مهارت",
  "isales.agent.message.updateCtiAndDbSuccess": "صف مهارت با موفقیت به روز می شود",
  "isales.agent.message.deleteSkillAndAgentFail": "حذف انجمن صف مهارت شکست خورد",
  "isales.agent.message.addSkillFail": "خرابی در افزودن صف مهارت",
  "isales.agent.message.modifySkillFail": "به روزرسانی صف مهارت شکست خورد",
  "isales.agent.message.queryCallCenterFail": "خرابی در پرس وجو دادۀ مرکز تماس",
  "isales.agent.message.refreshSkillException": "هنگام به روزرسانی صف مهارت خطایی رخ داد",
  "isales.agent.message.deleteSkillConfFail": "حذف اطلاعات صف مهارت شکست خورد",
  "isales.agent.message.agentMsgException": "تغییر رمز عبور آغازین نماینده و همگام سازی رمز عبور به نماینده",
  "isales.agent.label.skillName": "نام صف مهارت",
  "isales.agent.label.maxWaitTime": "مکس ، زمان صف کردن",
  "isales.agent.label.agentList": "کارت شناسايي نماینده",
  "ccivr.ivr.ivrflow.type": "نوع",
  "isales.agent.label.adjustTime": "Wrap-up Duration (s)",
  "isales.agent.title.skillModify": "تغییر صف مهارت",
  "isales.agent.message.deleteSkill": "حذف صف مهارت",
  "isales.agent.message.willDeleteSkills": "این عمل ممکن است باعث شود تماس تکلیف خارج از کار همراه با صف مهارت خراب شود. لطفا با احتياط عمل کنيد لازم است که پاکش کنید؟",
  "isales.agent.message.deleteSkillConfSuccess": "صف مهارتی با موفقیت حذف می شود",
  "isales.agent.message.idIsEmpty": "شناسۀ صف مهارت نمی تواند خالی باشد",
  "isales.agent.label.theAgent": "نماینده",
  "isales.agent.label.useTheSkill": "حذف رابطه ی پیوند بین کارگزار و صف مهارت در پیکربندی نماینده",
  "isales.agent.label.skillQueue": "صف مهارت:",
  "isales.agent.label.bindTheSkill": "پیکربندی طرف تماس محدود شده است. اول بازش کن",
  "isales.agent.label.deleteCtiFailed": "حذف داده ها شکست خورد",
  "isales.agent.message.skillConfFail": "خرابی در پیکربندی صف مهارت",
  "isales.agent.message.selectSkills": "یک صف مهارت انتخاب کنید",
  "isales.agent.label.skills": "صف مهارت",
  "isales.agent.label.release": "انتشار",
  "isales.agent.label.transfer": "انتقال",
  "isales.agent.label.playVoice": "پخش تن انتظار",
  "isales.agent.label.maxWaitNum": "مکس، در صف تماس می خواند",
  "isales.agent.label.skillpara": "تنظیمات پارامتر",
  "isales.agent.label.timeOut": "اتمام وقت صف بندی",
  "isales.agent.label.transferType": "حالت عملیاتی",
  "isales.agent.Transfer.devicetype": "نوع دستگاه هدف",
  "isales.agent.label.ivrChoice": "IVR",
  "isales.agent.label.deviceType": "نوع دستگاه",
  "isales.agent.label.skillChoice": "صف مهارت",
  "isales.agent.label.noAgents": "عدم حضور نماینده در وظيفه",
  "isales.agent.label.waitVoice": "انتظار تون",
  "isales.agent.label.parameterType": "حالت صف کردن",
  "isales.agent.label.answerMode": "حالت پاسخگو",
  "isales.agent.label.busy": "صف اشغال یا سرریز",
  "isales.agent.label.answerType": "حالت پاسخگو",
  "isales.agent.label.answerType0": "گزارش کارت شناسايي نماینده",
  "isales.agent.label.answerType2": "صدا، شناسه نماینده را گزارش نمی دهد",
  "isales.agent.message.name.limit": "نام صف مهارتی می تواند حاوی حداکثر ۲۰ نویسه باشد",
  "isales.agent.calledroute.require": "تنظیم همۀ حوزه های الزامی",
  "isales.agent.message.createSkillConfSuccess": "صف مهارتی با موفقیت ایجاد می شود",
  "isales.agent.calledroute.gateway.fail": "همگام سازی داده ها در CTI شکست خورد. علامت زدن پیکربندی دروازه",
  "isales.skillcreate.maxnumber.failed": "تعداد صف های مهارت محیط کاری tenant نمی تواند از حد بالا تجاوز کند",
  "isales.agent.message.createSkillConfFail": "خرابی در ایجاد صف مهارت",
  "isales.agent.accesscode.description.limit": "شرح کد دسترسی می تواند حاوی حداکثر ۵۰ حرف باشد",
  "isales.agent.message.modifySkillConfSuccess": "صف مهارتی با موفقیت اصلاح می شود",
  "isales.agent.message.modifySkillConfFail": "خرابی در تغییر صف مهارت",
  "isales.agent.label.maxWaitTimeSpan": "مقدار از ۰ تا ۶۰۰۰۰ درجه است",
  "isales.agent.label.maxAgentWrapupTime": "محدوده مقدار ۰ تا ۳۶۰۰",
  "isales.agent.contact.mediaType": "نوع رسانه",
  "isales.calledroute.title.selectivrflow": "يه ربات هوشمند انتخاب کن",
  "isales.management.message.SelectNumber": "برگزیدن یک عدد",
  "isales.taskmanagement.title.tip.batchEditTask": "برگزیدن تکلیفی که باید تغییر داده شود",
  "isales.taskmanagement.title.editCountOutOfBounds": "حداکثر ۳۰ تکلیف تماس ورودی را می توان در یک لحظه تغییر داد.",
  "isales.taskinfo.message.batchEditCallLogic.differentPolicyTypeExist": "منطق فراخوانی خروجی نمی تواند در دسته هایی با انواع سیاست متفاوت تغییر کند",
  "isales.management.message.batchEditCallLogic.notSupportBusiresult": "سیاستهای نتیجه تجاری نمی توانند برای تماس های خودکار یا هوشمند پیکربندی شوند. آیا مطمئنید که می خواهید این اصلاح دسته را انجام دهید؟",
  "isales.management.message.batchEditCallLogic.notSupportBusiresultInCMS": "سیاست های نتیجه تجاری نمی توانند برای تماس های خارج خودکار پیکربندی شوند. آیا مطمئنید که می خواهید این اصلاح دسته را انجام دهید؟",
  "isales.management.message.batchEditCallLogic.statusError": "منطق فراخوانی خروجی را می توان در بسته هایی که در پیش نویس\ ، اجرای وضعیت\ ، یا معلق شدۀ موقت تغییر داد",
  "isales.tasklogic.message.confirmBatchUpdate": "تغییرات دسته فقط در نواحی برگزیده اعمال می شود. مطمئني که مي خواي ادامه بدي؟",
  "isales.taskinfo.message.batchEditCallLogic.manualExist": "منطق تماس خروجی به دستی را نمی توان تغییر داد",
  "isales.taskinfo.message.batchEditCallLogic.selectEmpty": "عملیات نامعتبر است ، زیرا هیچ ناحیه ای برگزیده نمی شود. یک منطقه انتخاب کنید",
  "isales.taskinfo.message.batchEditCallLogic.differentCallModeExist": "منطق فراخوانی خروجی نمی تواند در بسته هایی با حالت تماس متفاوت تغییر کند",
  "isales.taskinfo.message.batchEditCallLogic.differentRoundTypeExist": "منطق تماس خروجی را نمی توان در بسته هایی با حالتهای دور تماس متفاوت تغییر داد",
  "isales.taskinfo.message.batchEditCallLogic.CallPolicyNotification": "دورهای موجود بدون تغییر باقی می مانند\ ، و دورهای جدید کنار گلوله های موجود اضافه می شوند",
  "isales.taskinfo.message.batchEditCallLogic.CallResultNotification": "سیاست های موجود را می توان\ تغییر داد ، و سیاست های جدید کنار سیاست های موجود اضافه می شوند.",
  "isales.taskinfo.message.batchEditCallLogic.BusiResultNotification": "سیاست های جدید روی سیاست های موجود جای نوشت می کنند",
  "isales.taskcalllogic.message.typeConflictWhenDataTypeSame": "سیاستی که نوع شماره اش با نوع سیاست موجود برابر است\ اما سیاست های مختلف بر اساس نتیجه پیکربندی می شوند.",
  "isales.taskcalllogic.message.callNoPolicyOutOfRangeAfetUpdate": "پس از تعداد سیاستهای عدد پیش فرض در سیاست نتیجه ی فراخوانی خارجی تغییر می کند\ ، بیشینه پنج بیشینه است",
  "isales.taskcalllogic.message.roundPolicyLengthTooLong": "بعد از تعداد دورها در سیاست تماس\ ، طول بیشینه بیشینه است",
  "isales.taskcalllogic.message.dataTypeDefaultNoCreate": "نوع عدد پیش فرض",
  "isales.taskcalllogic.message.callNoPolicyEmpty": "اضافه کردن حداقل یک سیاست عدد",
  "isales.result.search.displayResult": "وارد کردن علت خرابی تماس خارج از دسترس",
  "isales.result.resultCode": "علت کد",
  "isales.result.displayResult": "شکست خارج از محدوده تماس",
  "isales.result.view": "خرابی در خروجی تماس",
  "isales.result.edit": "تغییر علت خروجی تماس",
  "isales.result.delete.error": "برگزیدن خرابی در خروجی تماس",
  "isales.result.deletetips": "آیا مطمئنید که می خواهید علت خرابی تماس های خارج شده را حذف کنید؟",
  "isales.result.create": "ایجاد شکست خروجی تماس",
  "isales.result.outgoingCallResult": "نتیجۀ تماس خروجی",
  "isales.result.addOutgoingCallResult": "افزودن بهنهایت",
  "isales.result.outgoingCall.failCode": "کد نتیجه",
  "isales.result.outgoingCall.Description": "توصیف",
  "isales.result.addResultMaptips": "حداکثر 50 تماس خارج از دسترس، می توان اضافه کرد",
  "isales.result.addAgentTips": "حداکثر 20000 نماینده را می توان اضافه کرد",
  "isales.result.outgoingCall.input.description": "وارد کردن کد نتیجۀ فراخوانی یا توضیحات",
  "isales.result.obResultIsNull": "نتیجه ی تماس بیرونی علت شکست نمی تواند خالی باشد",
  "isales.calledroute.title.selectskill": "برگزیدن صف مهارت",
  "isales.calledroute.title.selectagent": "برگزیدن نماینده",
  "isales.agent.placeholder.agentdesc": "نام نماینده را وارد کنید",
  "isales.agent.label.workNo": "کارت شناسايي نماینده",
  "isales.agent.label.accountName": "نام نماینده",
  "isales.skillqueue.button.add": "افزودن صف مهارت",
  "isales.specialday.message.enterworkno": "یک شناسه نماینده وارد کنید",
  "isales.taskresult.field.sipAccount": "شماره تلفن نرم",
  "isales.taskresult.field.agentRole": "نام نقش نماینده ",
  "isales.taskInfo.message.skillAlreadyExists": "صف مهارتی از قبل وجود دارد",
  "isales.agent.message.saveSuccess": "پیکربندی نماینده با موفقیت ذخیره می شود",
  "isales.agent.field.skillWeight": "وزن مهارت",
  "isales.agent.label.agentWeight": "نماینده و وزن",
  "isales.agent.message.chooseAnAgent": "یک نماینده انتخاب کنید",
  "isales.agent.message.batchSaveSuccess": "نمایندگان در دسته هایی با موفقیت اصلاح شدند",
  "isales.agent.message.batchSaveFail": "خرابی در تغییر نماینده ها در دسته ها",
  "isales.agent.message.configAgent": "پیکربندی یا همگام سازی یک نماینده",
  "isales.tasktemplate.title.saveAsTemplate": "ذخیره به عنوان الگو",
  "isales.taskmanagement.title.createDirectly": "ایجاد مستقیم",
  "isales.taskmanagement.title.newFromTemplate": "ایجاد الگو با استفاده از الگو",
  "isales.taskmanagement.title.selectTemplate": "برگزیدن الگو",
  "isales.tasktemplate.title.inputTemplateName": "وارد کردن یک نام الگو",
  "isales.tasktemplate.title.addTemplate": "ایجاد الگو فراخوانی خروجی",
  "isales.tasktemplate.title.editTemplate": "تغییر الگو فراخوانی خروجی",
  "isales.tasktemplate.title.viewTemplate": "الگو فراخوانی خروجی",
  "isales.tasktemplate.title.autoTemplate": "الگو فراخوانی خروجی خودکار",
  "isales.tasktemplate.title.manualTemplate": "الگو فراخوانی خارج از دستی",
  "isales.tasktemplate.title.addAutoTemplate": "ایجاد الگو تماس خروجی خودکار",
  "isales.tasktemplate.title.addManualTemplate": "ایجاد الگو فراخوانی خروجی دستی",
  "isales.tasktemplate.field.templateName": "نام الگو",
  "isales.tasktemplate.field.release": "انتشار",
  "isales.tasktemplate.field.released": "منتشر شد",
  "isales.tasktemplate.message.deleteConfirm": "مطمئنید که می خواهید الگو تماس خارجی برگزیده را حذف کنید ؟",
  "isales.tasktemplate.message.releasesuccess": "انتشار موفقیت آمیز",
  "isales.tasktemplate.message.releasefailed": "خرابی در انتشار",
  "isales.tasktemplate.message.pauseFail": "خرابی در معلق",
  "isales.tasktemplate.message.pauseSuccess": "تعلل موفقیت آمیز بود",
  "isales.tasktemplate.message.saveFail": "خرابی در پیکربندی الگو فراخوانی خارج",
  "isales.tasktemplate.message.saveSuccess": "الگو فراخوانی بیرونی با موفقیت پیکربندی می شود",
  "isales.tasktemplate.message.nameDuplicated": "الگو فراخوانی خارج با همان نام از قبل وجود دارد",
  "isales.data.prop.propName": "نام خصیصه",
  "isales.data.prop.propCode": "کد خصیصه",
  "isales.data.prop.propType": "نوع خصیصه",
  "isales.data.prop.isPushAgent": "فشار بده به سمت نماینده",
  "isales.data.prop.mandatory": "اجباری",
  "isales.data.prop.anonym": "بی نام",
  "isales.data.prop.status": "وضعیت",
  "isales.data.prop.stringType": "رشته",
  "isales.data.prop.numberType": "رقم",
  "isales.data.prop.anonymType": "رمزنگاری",
  "isales.data.prop.status.unpublished": "منتشر نشده",
  "isales.data.prop.editDataProp": "تغییر خصیصه",
  "isales.data.prop.createDataProp": "ایجاد خصیصه",
  "isales.data.prop.inputPropName": "وارد کردن یک نام خصیصه",
  "isales.data.prop.inputPropCode": "یک کد خصیصه وارد کنید",
  "isales.data.prop.editDataPropSuccess": "خصیصه با موفقیت اصلاح می شود",
  "isales.data.prop.createDataPropSuccess": "ویژگی با موفقیت ایجاد می شود",
  "isales.data.prop.editDataPropFail": "خرابی در تغییر خصیصه",
  "isales.data.prop.createDataPropFail": "خرابی در ایجاد خصیصه",
  "isales.data.prop.propParamsError": "خرابی در وارسی پارامتر خصیصه",
  "isales.data.prop.propCodeExists": "کد خصیصه از قبل وجود دارد",
  "isales.data.prop.propNameExists": "نام خصیصه از قبل وجود دارد",
  "isales.data.prop.message.confirmDelete": "مطمئن هستید که می خواهید خصیصه ی برگزیده را حذف کنید ؟",
  "isales.data.prop.stringType.countError": "حداکثر 30 کاراکتر از ویژگی های رشته ای را می توان تعریف کرد",
  "isales.data.prop.numberType.countError": "حداکثر 10 رقمی را می توان تعریف کرد",
  "isales.data.prop.isPushAgent.countError": "حداکثر 15 ویژگی را می توان به یک نماینده هل داد",
  "isales.data.prop.anonymType.countError": "حداکثر 10 نوع رمزگذاری را می توان تعریف کرد",
  "isales.data.prop.isPushAgent.anonymTypeError": "ویژگی های نوع رمزنگاری را نمی توان به یک نماینده هل داد",
  "isales.data.propConfig.button.configProp": "تنظیم ویژگی ها",
  "isales.data.propConfig.title.isPushAgent": "فشار بده به سمت نماینده",
  "isales.data.propConfig.message.isUseProp": "فعال سازی خصیصۀ سفارشی",
  "isales.data.propConfig.title.prop": "خصیصه",
  "isales.file.title.ip": "نشانی اینترنتی",
  "isales.file.title.port": "درگاه",
  "isales.file.title.username": "نام کاربری",
  "isales.file.title.filepath": "مسیر پیش فرض",
  "isales.file.title.password": "رمز عبور",
  "isales.file.title.ip.placeholder": "وارد کردن نشانی اینترنتی کارساز",
  "isales.file.title.port.placeholder": "شمارۀ درگاه کارساز را وارد کنید",
  "isales.file.title.username.placeholder": "نام کاربر را برای ورود به کارگزار وارد کنید",
  "isales.file.title.filepath.placeholder": "وارد کردن یک مسیر پرونده\ ، برای مثال / خانه",
  "isales.file.title.password.placeholder": "وارد کردن رمز عبور برای ورود به کارگزار",
  "isales.file.title.addserver": "افزودن کارساز",
  "isales.file.title.server.info": "اطلاعات کارساز با موفقیت تغییر یافته است",
  "isales.file.title.server.fail": "خرابی در تغییر اطلاعات کارساز",
  "isales.file.title.delete.fail": "حذف اطلاعات کارساز شکست خورد",
  "isales.file.title.editserver": "تغییر کارساز",
  "isales.file.title.rightpath": "وارد کردن یک مسیر معتبر ، برای مثال ، / ایزاس/ بارگذاری",
  "isales.file.title.rule.basic": "اطلاعات پایه ای",
  "isales.file.title.rule.name": "نام قانون",
  "isales.file.title.rule.desc": "توصیف",
  "isales.file.title.addrule": "افزودن قاعدۀ",
  "isales.file.title.editrule": "تغییر قاعده",
  "isales.file.title.rule.name.placeHolder": "وارد کردن یک نام قاعده",
  "isales.file.title.rule.desc.placeHolder": "وارد کردن یک توصیف قاعده",
  "isales.file.title.rule.data": "دادۀ عدد",
  "isales.file.title.rule.number": "ستون شمار",
  "isales.file.title.rule.type": "ستون نوع",
  "isales.file.title.rule.interval": "تماس ستون فاصله",
  "isales.file.title.rule.property": "خصیصۀ سرویس",
  "isales.file.title.rule.property.row": "ستون خصیصه",
  "isales.file.title.rule.validator.row": "وارد کردن یک عدد دنباله از ستون A به EZ",
  "isales.file.title.rule.validator.maxdata": "حداکثر 40 رکورد پیکربندی عددی را می توان اضافه کرد",
  "isales.file.title.rule.validator.ruleId": "قاعده ی نگاشت وجود ندارد",
  "isales.file.title.rule.validator.taskId": "اطلاعات تکلیف وجود ندارد",
  "isales.file.title.import.file.invalid": "خرابی در وارسی پرونده",
  "isales.file.title.rule.validator.prop": "خصیصۀ تعریف کاربر از تکلیف متناقض با خصیصۀ پیکربندی شده در قاعده می باشد",
  "isales.file.title.rule.validator.server": "پیکربندی کارساز نادرست است",
  "isales.management.message.fileImport": "با استفاده از پرونده",
  "isales.management.message.serverImport": "استفاده از کارساز",
  "isales.management.message.appendMode": "افزودن",
  "isales.management.message.coverMode": "جای نوشت",
  "isales.management.message.templateAnalyse": "بر اساس الگو",
  "isales.management.message.ruleAnalyse": "بر اساس قانون",
  "isales.management.message.importType": "روش وارد کردن",
  "isales.management.message.importMode": "حالت واردات",
  "isales.management.message.import.server": "کارساز",
  "isales.management.message.import.filePath": "نشانی پرونده",
  "isales.management.message.import.fileName": "نام پرونده",
  "isales.management.message.import.analyseMode": "حالت تجزیۀ",
  "isales.management.message.import.mapRule": "نقشۀ نگاشت",
  "isales.data.title.timeImport": "واردات زمان بندی شده",
  "isales.management.title.timeImport": "پیکربندی واردات برنامه ریزی شده",
  "isales.management.title.timeImport.execTime": "اجرا شده",
  "isales.timeImport.message.success": "پیکربندی واردات برنامه ریزی شده با موفقیت اضافه می شود",
  "isales.timeImport.message.fail": "خرابی در افزودن پیکربندی زمان بندی شده واردات",
  "isales.agent.field.curcountpassword": "رمز عبور جاری",
  "isales.agent.button.batchmodify": "تغییر دسته",
  "isales.agent.button.restAgentPwd": "تغییر رمز عبور نماینده",
  "isales.agent.title.agentEdit": "تغییر اطلاعات نماینده",
  "isales.agent.field.newpassword": "رمز عبور جدید",
  "isales.agent.field.newpasswordconf": "تأیید رمز عبور",
  "isales.agent.message.passValidateFailed": "مقدار باید حداقل سه نوع نویسه های زیر را داشته باشد: حروف بزرگ\ ، حروف کوچک\ ، ارقام\ ، نویسه های مشخص (`-!{\'@\'}#{\'$\'}^&*()-_+{\'|\'}[{\'{\'}{\'}\'}]<.>/?) و نویسه های ویژه (`-!{\'@\'}#{\'$\'}^&*()-_+{\'|\'}[{\'{\'}{\'}\'}]<.>/?)",
  "isales.agent.message.passLengthValidateFailed": "رمز عبور باید حاوی ۸ نویسه باشد",
  "isales.agent.message.modifyPwdSuccess": "رمز عبور نماینده با موفقیت تغییر کرده است",
  "isales.agent.message.syncPwdFailed": "همگام سازی رمز عبور نماینده شکست خورد",
  "isales.agent.message.modifyPwdFailed": "خرابی در تغییر رمز عبور نماینده",
  "isales.file.message.rule.dataIsNull": "داده های عدد نمی تواند خالی باشد",
  "isales.timeImport.message.rulePropsError": "خصیصۀ خدمت قاعده زمان بندی شده باید با خصیصه تعریف شده در تکلیف جاری سازگار باشد",
  "isales.import.message.selectServer.tips": "تعریف کارساز پروندۀ فراخوانی خارج",
  "isales.import.message.selectRule.tips": "تعریف قاعده ای که باید وارد شود",
  "isales.taskmanagement.title.speclistmanage.calledno": "عدد خروجی",
  "isales.specialday.title.speclistmanage.moveblacklists": "انتقال به فهرست بلوک",
  "isales.specialday.title.speclistmanage.misjudgment": "بد قضاوت کن",
  "isales.specreason.field.emptynumber": "عدد بدون اختصاص است",
  "isales.specreason.field.systemtone": "تون اعلان سیستم",
  "isales.specist.message.speclistmanage": "برگزیدن شمارۀ فهرست ویژه ای که باید اجرا شود",
  "isales.blacklist.message.confirmOpt": "مطمئن هستید که می خواهید عملیات را در فهرست ویژه ی برگزیده انجام دهید ؟",
  "isales.specist.title.misjudgment": "بد قضاوت کن",
  "isales.specist.message.updateFail": "خرابی در تغییر",
  "isales.taskcalllogic.message.maxPolicyNo": "بیشینه ۵ نوع عدد غیر پیش فرض مجاز است",
  "isales.import.message.upload.fail": "خرابی در بارگذاری پرونده",
  "isales.taskLogic.message.saveSuccess": "منطق تماس خارج از خارج با موفقیت اصلاح شد",
  "isales.taskLogic.message.saveFail": "خرابی در تغییر منطق تماس راه دور",
  "isales.taskTime.message.saveSuccess": "زمان تماس خارج از مسیر با موفقیت تغییر کرده است",
  "isales.taskTime.message.saveFail": "خرابی در تغییر زمان تماس ورودی",
  "isales.tasklogic.field.callfilter": "پالایش تماس",
  "isales.tasklogic.field.addCondition": "افزودن شرط",
  "isales.tasklogic.field.addSubCondition": "افزودن زیر شرط",
  "isales.tasklogic.field.logic": "اپراتور",
  "isales.tasklogic.field.value": "مقدار",
  "isales.tasklogic.field.selectProp": "افزودن خصیصه",
  "isales.management.title.selectProp": "برگزیدن خصیصه",
  "isales.data.prop.quantity": "کمیت",
  "isales.tasklogic.field.and": "و",
  "isales.tasklogic.field.or": "يا",
  "isales.tasklogic.field.eq": "=",
  "isales.tasklogic.field.neq": "!=",
  "isales.tasklogic.field.gt": ">",
  "isales.tasklogic.field.egt": ">=",
  "isales.tasklogic.field.lt": "<",
  "isales.tasklogic.field.elt": "<=",
  "isales.tasklogic.field.like": "شامل",
  "isales.tasklogic.field.orderPolicy": "مرتب سازی تماس",
  "isales.tasklogic.field.moveUp": "بالا",
  "isales.tasklogic.field.moveDown": "پایین",
  "isales.tasklogic.message.repeatProp": "خصیصه های تکراری را نمی توان انتخاب کرد",
  "isales.tasklogic.label.primaryCondition": "شرط اصلی",
  "isales.tasklogic.label.subCondition": "زیر شرط",
  "isales.agent.label.skillId": "شناسۀ صف مهارت",
  "isales.management.title.editTime": "تغییر زمان",
  "isales.management.title.editLogic": "تغییر منطق",
  "isales.agent.button.search": "پرس وجو",
  "isales.agent.label.information": "اطلاعات",
  "isales.agent.label.description": "توصیف",
  "isales.agent.label.operate": "عملیات",
  "isales.agent.label.cti": "cti",
  "isales.agent.message.warning": "اخطار",
  "isales.agent.button.edit": "ویرایش",
  "isales.agent.button.createComplete": "پایان",
  "isales.agent.button.cancel": "لغو",
  "isales.agent.pageurls.errorsave": "خطا",
  "isales.agent.pageurls.success": "موفقیت",
  "isales.agent.calledroute.fail": "خرابی",
  "isales.agent.label.ivr": "IVR",
  "isales.skill.label.skillname": "نام صف مهارت",
  "isales.skill.label.skilldesc": "توصیف",
  "isales.skill.label.maxwaittime": "مکس ، زمان صف کردن",
  "isales.skill.label.maxwaitnum": "مکس، در صف تماس می خواند",
  "isales.skill.placeholder.skilldesc": "وارد کردن نام صف مهارت",
  "isales.calledroute.button.cancel": "لغو",
  "isales.calledroute.button.finish": "پایان",
  "isales.file.title.create": "افزودن",
  "isales.file.title.rule.index": "شمارۀ دنباله",
  "isales.file.title.rule.property.name": "نام خصیصه",
  "isales.agent.label.ccRoleName": "نام نقش",
  "isales.taskstatistics.field.callstatistics": "تماس آماری",
  "isales.taskstatistics.field.totalNumberOfUsers": "تعداد کل کاربران",
  "isales.taskstatistics.field.numberOfConnectedCalls": "تماسهای متصل",
  "isales.taskstatistics.field.numberOfUnconnectedCalls": "تماسهای متصل نشده",
  "isales.taskstatistics.field.touchRate": "خط لمسی",
  "isales.taskstatistics.field.callCompletionRate": "میزان تکمیل تماس",
  "isales.taskstatistics.field.abandonRate": "رها کردن ریه",
  "isales.business.result.delete": "حذف",
  "isales.business.result.search": "جستجو",
  "isales.business.result.deleteChildrenTips": "مطمئنید که می خواهید نتیجه ی زیر تجارت را حذف کنید؟",
  "isales.business.result.searchChildrenName": "وارد کردن نام نتیجۀ فرعی",
  "isales.taskmanagment.title.callMonitor": "پایشگر تماس",
  "isales.taskstatistics.field.callResultStatistics": "تماس آمارهای پیام",
  "isales.file.message.deletefiletips": "مطمئنید که می خواهید کارساز پروندۀ خارج از خارج را حذف کنید ؟",
  "isales.file.message.deleteruletips": "مطمئنید که می خواهید قاعده ی واردات را حذف کنید ؟",
  "isales.taskmanagment.callquality.maxConnectedRate": "میزان اتصال بیشینه",
  "isales.taskmanagment.callquality.minConnectedRate": "میزان اتصال کمینه",
  "isales.work.title.serviceAccount": "حساب سرویس",
  "isales.work.title.surveyAnswerNum": "تعداد پاسخها",
  "isales.work.title.reservedCalls": "تماس های رزرو شده",
  "isales.work.title.totalOutgoingCalls": "تماس های خارج از مرز",
  "isales.work.title.successNum": "تماسهای خروجی موفق",
  "isales.work.title.completeNum": "نظرسنجی های تکمیل شده",
  "isales.work.title.calledNum": "تماس های پردازش",
  "isales.work.title.callTimeTotal": "کل مدت زمان تماس",
  "isales.work.title.callTimeAvg": "میانگین مدت زمان تماس",
  "isales.work.title.busiSuccess": "تجارت موفق",
  "isales.work.title.busiFail": "خرابی در کسب و کار",
  "isales.work.title.outboundCallTask": "تکلیف تماس خروجی",
  "isales.work.title.multimediaManage": "مدیریت چند رسانه ای",
  "isales.work.message.dategt7days": "محدوده ي زمان نمي تونه از هفت روز بگذره",
  "isales.work.message.agentOrTaskChange": "برگزیدن حداقل یک تکلیف و نماینده",
  "isales.import.message.closeExtProp": "خصیصه های سفارشی باید برای واردات با الگو غیرفعال شوند. دکمۀ خصیصۀ پیکربندی بعد از واردات برای غیرفعال سازی و فعال سازی خصیصه های سفارشی ، فشار دهید",
  "isales.file.message.endPoint.placeholder": "یک نشانی وارد کنید",
  "isales.file.title.bucket": "سطل",
  "isales.file.message.bucket.placeholder": "یک سطل وارد کنید",
  "isales.file.title.AK": "AK",
  "isales.file.title.SK": "اسکا",
  "isales.file.message.AK.placeholder": "وارد کردن یک AK",
  "isales.file.message.SK.placeholder": "وارد کردن یک SK",
  "isales.file.title.serverInformation": "اطلاعات کارساز",
  "isales.file.title.userNameOrAK": "نام کاربر/ AK",
  "isales.file.message.isDeleteFile": "حذف پروندۀ متن",
  "isales.data.message.preview.addNumberUpperLimit": "یک بیشینه ی شمارش ۱۸۹ عدد را می توان برای فراخوانی های پیش نمایشی خارج از پیش نمایش، اضافه کرد",
  "isales.management.title.adjustment": "تنظیم",
  "isales.agent.query.error": "نماینده هدف نمی تواند مانند جلیۀ ثبت برگزیده باشد",
  "isales.management.title.adjustment.log": "تنظیم ضبط",
  "isales.management.title.adjustment.changeTime": "تنظیم شده بر روی",
  "isales.management.title.adjustment.orgAccountName": "جلاد قدیمی",
  "isales.management.title.adjustment.destAccountName": "جلاد جدید",
  "isales.management.title.adjustment.modifier": "تنظیم شده بر اساس",
  "isales.management.title.adjustment.remark": "ملاحظات",
  "isales.appointment.message.adjustmentSuccess": "تماس ذخیره شده خارج با موفقیت تنظیم می شود",
  "isales.appointment.message.adjustmentFail": "خرابی در تنظیم تماس ذخیره شده ی خارج",
  "isales.appointment.message.adjustmentEmpty": "دادۀ تماس رزرو شدۀ معتبر انتخاب نمی شود",
  "isales.tasksample.message.agentEmpty": "هیچ شیء آموزشی انتخاب نشده است",
  "isales.work.title.selectTask": "برگزیدن تکلیف تماس خروجی",
  "isales.import.button.configProp": "پیکربندی قواعد پالایه",
  "isales.import.filter.button.lineRepeat": "پالایش دادۀ تکراری در سطر",
  "isales.import.filter.button.noRepeat": "پالایش دادۀ تکراری در دستۀ فهرست",
  "isales.import.filter.button.recentCall": "پالایش دادۀ تماس های تکمیل شده اخیراً",
  "isales.import.filter.button.callResult": "پالایش دادۀ نتایج کسب و کار",
  "isales.import.filter.button.readyCall": "پالایش دادۀ تماس های آغاز نشده",
  "isales.import.filter.button.calling": "پالایش دادۀ تماس های ناتمام",
  "isales.import.filter.title.popup": "قواعد پالایه",
  "isales.import.filter.message.selectRuleTips": "برگزیدن حداقل یک قاعده پالایه",
  "isales.taskmanagment.title.callMonitor.tips": "به روزرسانی خودکار هر ۱۵ دقیقه",
  "isales.taskmanagement.field.basicTaskInfo": "اطلاعات پایه ای",
  "isales.taskmanagement.field.pickUpRule": "قاعدۀ استخراج خودکار",
  "isales.taskmanagement.field.isAutoPickUp": "فعال سازی قاعده استخراج خودکار",
  "isales.taskmanagement.field.pickUpRuleLimit": "محدودیت ها",
  "isales.taskmanagement.field.pickUpRuleBusiResult": "درجۀ تجاری",
  "isales.taskmanagement.field.quotas": "حد بالایی",
  "isales.taskmanagement.field.callCompletedNum": "تماسهای انجام شده",
  "isales.taskmanagement.field.pickUpNum": "هر دفعه تماس ها استخراج شده",
  "isales.taskmanagement.field.pickUpCondition": "شرط ها",
  "isales.manualdata.title.pickUp": "استخراج",
  "isales.manualData.message.pickUpSuccess": "استخراج موفقیت آمیز بود",
  "isales.manualData.message.reachQuatos": "دیگر نمی‌توان داده‌های تماس خروجی را استخراج کرد زیرا تعداد تماس‌های موجود در کار به حد بالایی استخراج رسیده است.",
  "isales.manualData.message.reachMax": "دیگر نمی‌توان داده‌های تماس خروجی را استخراج کرد زیرا به حداکثر تعداد تماس‌ها در کار رسیده است",
  "isales.manualData.message.pickUpFailed": "داده ای برای استخراج نیست",
  "isales.taskstatistics.message.no.busiResult": "هیچ نتیجه ی کاری مشخص نیست",
  "isales.appointmanagement.field.remark": "ملاحظات",
  "isales.appointmanagement.title.inputRemark": "وارد کردن اظهارات",
  "isales.agent.query.emptyError": "دادۀ نماینده معتبری انتخاب نشده است",
  "isales.statistics.field.touchRateDescription": "نرخ رسیدن",
  "isales.statistics.field.callCompletionRateDescription": "نرخ اتصال",
  "isales.statistics.field.abandonmentRateDescription": "نرخ رها کردن",
  "isales.taskstatistics.message.Unit.Piece": "واحد: شماره",
  "isales.skillqueue.agentrole.agent": "نماینده",
  "isales.statistics.field.addHisDetail": "شماره: داده های {0} ماه جمع آوری شده است. بله: داده های {1} ماه جمع آوری شده است",
  "isales.import.filter.message.busiResultIsNull": "نتیجۀ تجاری نمی تواند خالی باشد",
  "isales.import.filter.message.busiSubStatusSame": "نتايج کسب و کار يکساني رو نمي تونه داشته باشه",
  "isales.file.server.message.checkObsPath": "فقط اعداد\، حروف\، خط فاصله (-)\ و نقطه (.) مجاز هستند",
  "isales.file.server.message.notChinese": "نویسه های چینی مجاز نیستند",
  "isales.import.filter.title.callDay": "فعالیت اخیر (روزها)",
  "isales.manual.title.sendEmail": "ارسال ایمیل",
  "isales.manual.button.send": "ارسال",
  "isales.manual.title.recipient": "دریافت",
  "isales.manual.title.title": "عنوان",
  "isales.file.message.isDeleteFileTips": "حذف پرونده ها از OBS",
  "isales.file.message.rule.success": "قاعده با موفقیت اصلاح می شود",
  "isales.file.message.rule.fail": "خرابی در تغییر قاعده",
  "isales.file.message.rule.columnRepeat": "ستون شناسۀ فراخوانی بیرونی\ ، ستون برای\ ، ستون دسته\ ، ستون تماس\ ، ستون تکلیف\ ، و ستون خصیصه نمی تواند یکی باشد",
  "isales.manual.message.recipient": "یک گیرنده وارد کنید",
  "isales.manual.message.title": "وارد کردن یک عنوان",
  "isales.operlog.message.downloadAgentWorkExportFile": "بارگیری پروندۀ صدور کار نماینده",
  "isales.operlog.message.downloadSurveyExportFile": "صدور پرسشهای آموزشی",
  "isales.taskinfo.field.chooseOrInputCallNo": "می توانید تماس نه را همزمان وارد یا برگزینید",
  "isales.agent.message.curcountpasswordWrong": "رمز عبور حساب جاری نادرست است",
  "isales.taskinfo.callno.repeat": "شماره تماس ، تکرار می شود",
  "isales.file.title.password.again": "تأیید رمز عبور",
  "isales.file.title.password.same": "دو تا پسورد باهم فرق دارن",
  "isales.file.title.sk.same": "دو تا اس با هم فرق دارن",
  "isales.file.title.SK.again": "تأیید SK",
  "isales.import.message.template.dataNum": "حداکثر {0} رکورد داده را می توان در یک زمان وارد کرد",
  "isales.import.message.allow.task.statue": "، فقط زمانی یک تکلیف را می توان وارد کرد که در حالت پیش نویس یا مکث باشد",
  "isales.taskstatistics.field.callRatePie": "نرخ تکمیل تماسها",
  "isales.taskstatistics.field.connectedCallsPie": "تعداد تماس های مرتبط",
  "isales.taskstatistics.field.Completed": "تماسهای کامل مشتریان",
  "isales.taskstatistics.field.inProgress": "مشتري ها تماس گرفتن",
  "isales.taskstatistics.field.incomplete": "مشتری ها در حال انتظار تماس",
  "isales.taskresultexport.message.downloadFilenopemission": "برای صدور پرونده ها مجوز ندارید",
  "isales.callrecordexport.message.downloadFilenopemission": "شما حق صدور ثبت تماس های خروجی هوشمند را دارید",
  "isales.taskmanagment.title.callquality.viewstatistics": "آمار کیفیت فراخوانی",
  "isales.taskmanagment.message.chartDataError": "خرابی در بارگذاری نمودار",
  "isales.taskmanagment.title.open.agentMonitor": "نماینده نظارت",
  "isales.taskmanagment.title.open.callMonitor": "پایشگر تماس",
  "isales.callquality.statistics.message.afterNow": "نمی توانید پیش از زمان آغاز تکلیف یا دیرتر از روز جاری ، زمان آغاز تکلیف را برگزینید!",
  "isales.task.promotion.tempalteError": "الگو اطلاع بازاریابی با نوع الگو تطبیق ندارد",
  "isales.task.promotion.gatewwayError": "پیکربندی دروازه با نوع رسانه تطبیق ندارد",
  "isales.task.promotion.baseInfo": "اطلاعات پایه ای",
  "isales.appointment.title.timeout": "زمان رزرو تموم شده",
  "isales.appointment.title.soontimeout": "زمان رزرو داره ميرسه",
  "isales.file.message.rule.receiver.columnRepeat": "ستون گیرنده نمی تواند با ستون خصیصه یکسان باشد",
  "isales.taskinfo.field.mutiCallNo": "شماره تماس را وارد کنید",
  "isales.taskinfo.callno.length": "طول یک عدد تماس از ۳۲ نویسه تجاوز می کند",
  "isales.workbench.execution": "اجرای تماس خروجی",
  "isales.workbench.task.management": "مدیریت تماس خارج از حد",
  "isales.workbench.appointment.task": "تکلیف حفاظت",
  "isales.workbench.number.execution": "اجرا",
  "isales.notification.receiver.address": "نشانی دریافت",
  "isales.notification.receiver.number": "شمارۀ دریافت",
  "isales.notification.receiver.address.placeholder": "نشانی گیرنده را وارد کنید",
  "isales.notification.receiver.number.placeholder": "شمارۀ گیرنده را وارد کنید",
  "isales.notification.configVariable": "تنظیم متغیر",
  "isales.notification.receiver.tips": "گیرنده باید منحصر به فرد باشد",
  "isales.marketing.mode": "حالت بازار گذاری",
  "isales.marketing.OutgoingCall": "بازار گذاری از تلفن",
  "isales.marketing.notification": "بازاریابی چند رسانه ای",
  "isales.notification.receiver": "دریافت",
  "isales.notification.receiver.info": "اطلاعات دریافت",
  "isales.import.message.errMsg": "علت استثنا",
  "isales.notification.variable.name": "نام متغیر",
  "isales.notification.propo.name": "نام خصیصه",
  "isales.notification.variable.value": "مقدار متغیر",
  "isales.notification.import.button": "واردات داده",
  "isales.task.promotion.pauseSuccess": "کار بازاریابی چند رسانه ای با موفقیت معلق می شود",
  "isales.task.promotion.pauseFaied": "معلق کردن تکلیف بازاریابی چند رسانه ای شکست خورد",
  "isales.task.promotion.startSuccess": "فعالیت بازاریابی چند رسانه ای با موفقیت منتشر شد",
  "isales.task.promotion.startFailed": "انتشار کار بازاریابی چند رسانه ای شکست خورد",
  "isales.task.promotion.isExp": "کار چند رسانه ای منقضی شده است",
  "isales.task.promotion.saveSuccess": "تکلیف بازاریابی چندرسانه ای با موفقیت ذخیره می شود",
  "isales.task.promotion.nameRepeat": "نام کاری چندرسانه ای از قبل وجود دارد",
  "isales.task.promotion.saveFailed": "خرابی در ذخیرۀ تکلیف بازاریابی چند رسانه ای",
  "isales.task.promotion.deleteSuccess": "تکلیف بازاریابی چند رسانه ای با موفقیت حذف می شود",
  "isales.task.promotion.statusStart": "کار بازاریابی چندرسانه ای در حال اجراست",
  "isales.task.promotion.deleteFailed": "خرابی در حذف تکلیف بازاریابی چند رسانه ای",
  "isales.task.promotion.updateSuccess": "فعالیت بازاریابی چند رسانه ای با موفقیت تغییر می کند",
  "isales.task.promotion.updateFailed": "تغییر تکلیف بازاریابی چند رسانه ای شکست خورد",
  "isales.task.promotion.messageTemplate": "الگو اخطار",
  "isales.task.promotion.promotionName": "در حال بازار گذاری تکلیف",
  "isales.task.promotion.inputPromotionName": "الرجاء إدخال اسم التسويق",
  "isales.task.promotion.basicInfo": "اطلاعات پایه ای",
  "isales.task.promotion.promotionType": "نوع رسانه",
  "isales.task.promotion.promotionEffTime": "زمان اجرا",
  "isales.task.promotion.promotionExpTime": "زمان انقضا",
  "isales.task.promotion.promotionPolicy": "سیاست بازاریابی",
  "isales.task.promotion.templateType": "نوع الگو",
  "isales.task.promotion.sendTemplate": "الگو اخطار",
  "isales.task.promotion.selectSendTemplate": "برگزیدن یک الگو",
  "isales.task.promotion.prefix": "کد کشور",
  "isales.task.promotion.sendGateway": "دروازه",
  "isales.task.promotion.selectSendGateway": "یک دروازه انتخاب کنید",
  "isales.task.promotion.sender": "فرستنده",
  "isales.task.promotion.sendLimit": "حد ارسال روزانه",
  "isales.task.promotion.isRetry": "تلاش مجدد با شکست",
  "isales.task.promotion.retryTimes": "سعی مجدد زمانها",
  "isales.task.promotion.isSendNow": "ارسال سریع",
  "isales.task.promotion.expectedTime": "زمان ارسال مورد انتظار",
  "isales.task.promotion.dndTime": "دوره اش را به هم نزدید.",
  "isales.task.promotion.sms": "پیام کوتاه",
  "isales.task.promotion.email": "ایمیل",
  "isales.task.promotion.hwsms": "HUAWEI CLOUD SMS",
  "isales.task.promotion.smsgw": "دروۀ پیام کوتاه",
  "isales.task.promotion.promotionStatus": "وضعیت بازار گذاری",
  "isales.task.promotion.tobesent": "پیام هایی که باید فرستاده شود",
  "isales.task.promotion.invalidation": "نامعتبر",
  "isales.task.promotion.all": "همه",
  "isales.task.promotion.create": "ایجاد تکلیف بازاری چند رسانه ای",
  "isales.task.promotion.edit": "تغییر تکلیف بازاری چند رسانه ای",
  "isales.task.promotion.view": "مشاهدۀ تکلیف بازاری چند رسانه ای",
  "isales.task.promotion.message.variableIsNull": "فهرست پیکربندی خصیصه خالی است",
  "isales.task.promotion.message.variableNotConfig": "برخی متغیرها پیکربندی نمی شوند",
  "isales.task.promotion.message.limitGtTenantLimit": "حد ارسال روزانه بیش از حد مجاز برای محیط کاری tenant است",
  "isales.task.promotion.message.dndEndTimeltBeginTime": "پایان دوره نه آشوب زودتر از آغاز است",
  "isales.task.promotion.message.dndTimeltCrossExpectedTime": "زمان ارسال مورد انتظار نمی تواند در دوره ی نباید مزاحم باشد",
  "isales.notification.viewResult.button": "مشاهدۀ پاسخ بازاری",
  "isales.task.promotion.message.dndTimeError": 'دوره ی "نجوش" کامل نیست',
  "isales.task.promotion.message.selectPromotionType": "برگزیدن یک نوع رسانه",
  "isales.import.filter.button.notification.noisPush": "پالایۀ داده های بیرون فشار داده نشده است",
  "isales.import.filter.button.notification.isPushCom": "پالایش داده هایی که هل داده می شود",
  "isales.import.filter.title.notification.isPushDay": "روزهای فشرده",
  "isales.notification.configVariableRel": "پیکربندی رابطه بین متغیر و خصیصه",
  "isales.notification.configVariableValue": "تنظیم مقدار متغیر",
  "isales.notification.prop.tips": "خصیصه باید منحصر به فرد باشد",
  "isales.notification.receiver.delete.error": "داده ای انتخاب نشده است",
  "isales.notification.receiver.delete.variable": "پیکربندی خصیصه",
  "isales.import.filter.button.notification.isPush": "پالایۀ داده هایی که اخیراً فشار داده شده",
  "isales.task.promotion.templateCode": "کد الگو",
  "isales.task.promotion.gateway": "در حال ارسال دروازه",
  "isales.task.promotion.sendStatus": "وضعیت ارسال",
  "isales.task.promotion.beginTime": "زمان آغاز",
  "isales.task.promotion.endTime": "زمان پایان",
  "isales.task.promotion.receiver": "دریافت",
  "isales.task.promotion.sendTime": "ارسال شده",
  "isales.sendStatus.field.prepare": "آماده سازی",
  "isales.sendStatus.field.sending": "ارسال",
  "isales.sendStatus.field.success": "موفقیت آمیز",
  "isales.sendStatus.field.fail": "خرابی در",
  "isales.sendStatus.field.all": "همه",
  "isales.task.promotion.message.selectTemplateType": "برگزیدن یک نوع الگو",
  "isales.task.promotion.remark": "خرابی علت",
  "isales.task.promotion.createTime": "ایجادشده در",
  "isales.task.promotion.message.monthGtThree": "فقط نتایج سه ماه قابل بررسی است",
  "isales.notification.configVariableTe": "پیکربندی متغیر الگو",
  "isales.notification.config": "پیکربندی",
  "isales.notification.receiver.number.tips": "مقدار باید شامل ارقام باشد",
  "isales.notification.variable.defvalue": "مقدار پیش فرض",
  "isales.task.promotion.message.noprop": "افزودن خصیصه ای با استفاده از گزینگان خصیصۀ دادۀ خروجی فراخوانی",
  "isales.task.promotion.message.variableSelected": "برگزیدن یک خصیصه",
  "isales.notification.receiver.deletetips": "مطمئن هستید که می خواهید دادۀ برگزیده را حذف کنید ؟",
  "isales.management.message.notification.download": "بارگیری الگو دادۀ بازار",
  "isales.management.title.notification.import": "واردات دادۀ بازاری",
  "isales.taskpolicy.message.obOverTime": "ابتدا مدت اتمام وقت پیش نمایش را وارد کنید",
  "isales.taskpolicy.message.rangeAutoCallTime": "مقدار باید از {0} تا {1} باشد",
  "isales.taskpolicy.message.obOverTimeRange": "مدت زمان پیش‌نمایش خارج از محدوده است: 9 تا 120. لطفاً ابتدا مدت زمان وقفه پیش‌نمایش را تغییر دهید",
  "isales.taskmanagement.message.beforeNow": "زمان آغاز نمی تواند زودتر از زمان جاری باشد",
  "isales.operation.success": "عملیات موفقیت آميز بود",
  "isales.operation.training.success": "عمليات موفقيت آميز بود تمرين چند ساعت طول ميکشه برای دیدن پیشرفت آموزش به لودا وارد میشوی",
  "isales.operation.failed": "خرابی در عملیات",
  "isales.taskmanagement.field.taskId": "شناسۀ تکلیف",
  "isales.taskmanagement.title.inputTaskId": "یک شناسۀ تکلیف وارد کنید",
  "isales.taskinfo.field.callBackUrl": "آدرس تماس برگشتی",
  "isales.taskinfo.message.callBackUrl": "یک آدرس تماس برگشتی را وارد کنید",
  "isales.tasktype.field.preemption": "تماس خروجی از پیش تعیین شده",
  "isales.taskmanagement.title.toBeCallCount": "تماس های معلق",
  "isales.manual.field.canBeCallCount": "تماس های موجود",
  "isales.manual.field.noDataCanbeCallTips": "در حال حاضر هیچ تماسی امکان پذیر نیست. بررسی کنید که آیا پیکربندی ویژگی با معیارهای فیلتر مطابقت دارد یا خیر",
  "isales.tasklogic.field.callOrderType.noOrder": "تماس بی ترتیب",
  "isales.tasklogic.field.callOrderType.isOrder": "تماس ترتیب ",
  "isales.tasklogic.field.callOrderMethod": "قاعدتاً",
  "isales.tasklogic.field.callOrderMethod.byOrderNo": "توسط شماره",
  "isales.tasklogic.field.callOrderMethod.bySelf": "بر اساس قانون سفارشی",
  "isales.tasklogic.message.need.orderPolicy": "افزودن حداقل یک قاعده سفارشی",
  "isales.taskmanagement.title.clicktorefresh": "فشار برای بازآوری",
  "isales.audio.content.calltype": "تماس خارج از صدا",
  "isales.audio.message.errortip1": "خرابی در پرس وجوی اطلاعات ضبط. تماس با سرپرست",
  "isales.audio.title.audiorate": "نرخ پخش",
  "isales.audio.title.callId": "SN ضبط شده",
  "isales.audio.title.audiotime": "Duration(HH:mm:ss)",
  "isales.audio.title.starttime": "زمان آغاز",
  "isales.audio.title.endtime": "زمان پایان",
  "isales.audio.title.calltype": "نوع تماس",
  "isales.audio.title.recording": "پیش نمایش ضبط",
  "isales.datasourcemanagement.field.vdnId": "شناسه ی VDN",
  "isales.datasourcemanagement.title.inputVdnId": "وارد کردن یک شناسۀ VDN",
  "isales.datasourcemanagement.field.dbId": "DataSource Id",
  "isales.datasourcemanagement.title.inputDbId": "شناسه منبع را وارد کنید",
  "isales.datasourcemanagement.field.dbschema": "نام DataSource",
  "isales.datasourcemanagement.title.inputDbschema": "وارد کردن نام DataSource",
  "isales.datasourcemanagement.field.dbType": "نوع DataSource",
  "isales.datasourcemanagement.field.dbdriver": "DataSource Driver",
  "isales.datasourcemanagement.field.dsType.all": "همه",
  "isales.datasourcemanagement.field.dsType.mysql": "MYSQL",
  "isales.datasourcemanagement.field.dsType.oracle": "ORACLE",
  "isales.datasourcemanagement.field.dsType.gauss": "GAUSS",
  "isales.datasourcemanagement.field.dsType.dm": "DM",
  "isales.datasourcemanagement.field.add": "افزودن DataSource",
  "isales.datasourcemanagement.field.sync": "همگام سازی DataSource",
  "isales.datasourcemanagement.field.backDbdriver": "نام DataSource",
  "isales.datasourcemanagement.field.addDataSource": "افزودن DataSource",
  "isales.datasourcemanagement.field.dbIp": "IP یا نام مستعار منبع داده",
  "isales.datasourcemanagement.field.dbPort": "DataSource Port",
  "isales.datasourcemanagement.field.dbUserName": "DataSource Name",
  "isales.datasourcemanagement.field.dbPass": "DataSource PassWord",
  "isales.datasourcemanagement.field.dbPass2": "Confirm DataSource PassWord",
  "isales.datasourcemanagement.field.dbCnUrl": "DataSource url",
  "isales.data.propConfig.message.isExistBackDb": "Back DataSource",
  "isales.datasourcemanagement.field.backdbId": "Back DataSource Id",
  "isales.datasourcemanagement.field.backdbUserName": "Back DataSource UserName",
  "isales.datasourcemanagement.field.backdbType": "Back DataSource Type",
  "isales.datasourcemanagement.field.backdbIp": "IP یا نام مستعار پایگاه داده آماده به کار",
  "isales.datasourcemanagement.field.backdbport": "Back DataSource Port",
  "isales.datasourcemanagement.field.backDbCnUrl": "Back DataSource url",
  "isales.datasourcemanagement.field.backdbpass": "Back DataSource PassWord",
  "isales.datasourcemanagement.field.backdbpass2": "تأیید PassWord",
  "isales.datasourcemanagement.field.backdbSchema": "Back DataSource Schema",
  "isales.datasourcemanagement.field.backdbdriver": "Back DataSource Driver",
  "isales.datasourcemanagement.field.editDataSource": "ویرایش DataSource",
  "isales.datasourcemanagement.message.dbIp": "فرمت آدرس IP پایگاه داده نادرست است",
  "isales.datasourcemanagement.message.dbpass": "لطفاً ، یک رمز عبور را وارد کنید",
  "isales.datasourcemanagement.message.addDataSourceFail": "مشکل در ایجاد DataSource",
  "isales.datasourcemanagement.message.addDataSourceSuccess": "ایجاد موفق DataSource",
  "isales.datasourcemanagement.message.alreadyExistDs": "منبع داده VDN از قبل وجود دارد",
  "isales.datasourcemanagement.message.confirDelete": "حذف داده ها یک عملیات پر خطر است. تمرین احتیاط هنگام اجرای این عمل",
  "isales.datasourcemanagement.message.deleteDataSourceFail": "مشکل در حذف DataSource",
  "isales.datasourcemanagement.message.deleteDataSourceSuccess": "حذف موفق DataSource",
  "isales.datasourcemanagement.field.viewDataSource": "مشاهدۀ DataSource",
  "isales.datasourcemanagement.message.frequentOperation": "عملیات مکرر لطفا صبر کنید",
  "isales.datasourcemanagement.message.noObsINFO": "فراخوان بیرونی DataSource پیکربندی نشده یا نادرست پیکربندی شده است",
  "isales.datasourcemanagement.message.passValidateFailed": "مقدار باید حداقل شامل سه نوع از نویسه‌های زیر باشد: حروف بزرگ\، حروف کوچک\، اعداد\ و کاراکترهای خاص (`-!{\'@\'}#{\'$\'}^&*()-_+{\'|\'}[{\'{\'}{\'}\'}]<.> /؟). ",
  "isales.datasourcemanagement.message.syncSuccess": "همگام سازی موفقیت DataSource",
  "isales.tasklogic.field.noRetryLogic": "افزودن کد non-retry result ",
  "isales.management.title.selectResultCode": "برگزیدن کد پیام",
  "isales.management.title.setResultCode": "لطفاً ، کد نتیجه را تنظیم کن",
  "isales.manualData.message.noPickCondition": "لطفاً ، شرطها را تنظیم کنید",
  "isales.management.message.addPropTips": "داده های شخصی یا داده های حساس باید رمزگذاری، ذخیره و پوشش داده شوند.",
  "isales.enum.workSpace": "نماینده خارج از تکلیف تماس",
  "isales.management.title.black.import": "واردات فهرست بلوک",
  "isales.black.filed.number": "شماره (اجباری)",
  "isales.black.filed.expTime": "مدت اعتبار (اختیاری، بر حسب ساعت)",
  "isales.black.filed.remark": "نکات (اختیاری)",
  "isales.survey.statistic": "آمار پاسخگو",
  "isales.survey.querydetail": "نتیجه نظرسنجی",
  "isales.manual.task.survey.questiontotal": "مجموع {0} سؤال",
  "isales.manual.task.survey.unanswered": "بی پاسخ",
  "isales.manual.task.survey.answered": "پاسخ داده شد",
  "isales.manual.task.survey.currentprogress": "پیشرفت جاری",
  "isales.manual.task.survey.previousquestion": "پرسش قبلی",
  "isales.manual.task.survey.nextquestion": "سوال بعدي",
  "isales.manual.task.survey.submit": "ارائه",
  "isales.taskinfo.field.survey": "پرسشنامه",
  "isales.manual.task.survey.noAnswer": "پاسخی مورد نیاز نیست",
  "isales.manual.task.survey.answerSuccess": "با موفقيت جواب داده شد",
  "isales.agent.calledroute.search.devicedesc": "لطفاً ، نام دستگاهی را وارد کنید",
  "isales.agent.calledroute.create": "پیکربندی نامیده شده را اضافه کنید",
  "isales.agent.calledroute.device": "لطفاً ، دستگاهی برگزینید",
  "isales.agent.calledroute.devicetype": "نوع دستگاه",
  "isales.agent.calledroute.extcode": "گسترش کد",
  "isales.agent.calledroute.ivr": "لطفا تلفن گویا را انتخاب کنید",
  "isales.agent.calledroute.placeholder.ivr": "لطفا تلفن گویا را انتخاب کنید",
  "isales.agent.calledroute.skill": "صف مهارت",
  "isales.agent.calledroute.placeholder.skill": "لطفاً ، صف مهارتی برگزینید",
  "isales.agent.calledroute.accesscode": "کد دسترسی",
  "isales.agent.calledroute.placeholder.accesscode": "لطفاً ، یک کد دستیابی برگزینید",
  "isales.agent.calledroute.placeholder.extcode": "لطفاً ، کد پسوند را وارد کن",
  "isales.calledroute.title.selectaccesscode": "یک کد دستیابی انتخاب کنید",
  "isales.agent.calledroute.extcodewarn": "کدهای پسوند فقط می توانند حاوی اعداد باشند",
  "isales.agent.calledroute.extlengthcodewarn": "طول کد پسوند نمی تواند از ۱۲ تجاوز کند",
  "isales.agent.calledroute.editsuccess": "ویرایش با موفقیت",
  "isales.agent.calledroute.exception": "لطفاً ، وضعیت قرمز یا پیکربندی دروازه ی CC را بررسی کنید",
  "isales.agent.calledroute.editfail": "خرابی در ویرایش داده های محلی",
  "isales.agent.calledroute.accesscodewarn": "کدهای دسترسی فقط می توانند حاوی اعداد باشند",
  "isales.agent.calledroute.createsuccess": "با موفقیت افزوده شد",
  "isales.agent.calledroute.createfail": "خرابی در افزودن دادۀ محلی",
  "isales.agent.calledroute.create.accesscode.none": "کد دسترسی سیستم پیکربندی شده توسط طرف تماس خوانده شده انتساب نمی شود",
  "isales.agent.calledroute.create.accesscode.repeat": "ترکیب کد دسترسی سیستم و کد پسوند پیکربندی شده توسط طرف تماس، نمی تواند تکرار شود",
  "isales.agent.calledroute.create.accesscode.noexist": "کد دسترسی ارجاع شده توسط پیکربندی خوانده شده وجود ندارد",
  "isales.agent.calledroute.create.skill.none": "صف مهارت ارجاع شده با پیکربندی خوانده نمی شود",
  "isales.agent.calledroute.create.ivr.limit": "نوع دستگاه IVR است و نوع رسانه کد دسترسی فقط می تواند درخواست فراخوانی، یا تماس عادی باشد",
  "isales.agent.calledroute.create.gateway.none": "دست نوشتۀ مسیریابی ارجاع شده توسط پیکربندی خوانده می شود وجود ندارد",
  "isales.agent.calledroute.create.media.match": "کد دسترسی سیستم پیکربندی شده توسط طرف فراخوانی شده با نوع رسانه ای که توسط طرف تماس نامیده می شود متناقض است",
  "isales.agent.calledroute.recordexist": "کد دسترسی و پسوند از قبل محدود شده اند ، لطفاً ، یک کد پسوند جدید وارد کنید",
  "isales.agent.calledroute.nmsdata.error": "با شکست در هماهنگی ODFS، و داده های پیکربندی ODFS به نام پارتی متناقض با داده های محلی CCM می باشد.",
  "isales.agent.calledroute.ccivrdata.error": "خرابی در همگام سازی ODFS ، دادۀ فرایند CC- IVR اشتباه است",
  "isales.agent.calledroute.nmsconnect.fail": "خرابی در اتصال به ODFS",
  "isales.management.placeholder.accesscodeDesc": "لطفاً ، یک توصیف کد دسترسی وارد کنید",
  "isales.taskinfo.title.accesscode": "کد دسترسی",
  "isales.taskinfo.title.accesscodedesc": "توصیف کد دسترسی",
  "isales.agent.contact.voiceVidio": "صوتی و ویدئو",
  "isales.agent.contact.clicktodial": "کلیک برای تماس",
  "isales.note.config.success": "موفقیت",
  "isales.agent.calledroute.refreshsuccess": "بازآوری موفق شد",
  "isales.agent.calledroute.updatecalledroute": "ویرایش",
  "isales.agent.calledroute.confdelete": "حذف تأیید",
  "isales.agent.calledroute.isconfdelete": "مطمئني که پاکش ميکني؟",
  "isales.agent.calledroute.success": "موفقیت",
  "isales.agent.calledroute.delsuccess": "با موفقیت حذف شد",
  "isales.agent.message.redislocknull": "به دست آوردن قفل ردیس شکست خورد",
  "isales.calledroute.message.selectcalledroute": "لطفاً ، پیکربندی خوانده شده را برگزینید",
  "isales.agent.calledroute.delfail": "خرابی در حذف داده های محلی",
  "isales.label.appraise.call.quality.agent": "نماینده",
  "isales.label.appraise.call.quality.customer": "مشتری",
  "isales.label.appraise.call.quality.customerInfo": "اطلاعات مشتری",
  "isales.data.message.empty": "شماره تماس نمی تواند خالی باشد!",
  "isales.export.message.noSelectData": "لطفاً ، داده ای که باید صادر شود را برگزینید",
  "isales.export.message.noSelectTrainingData": "لطفاً ، داده ای که باید آموزش داده شود را برگزینید",
  "isales.export.message.selectToomach": "یک بیشینه از ۱۰ رکورد می تواند انتخاب شود",
  "isales.training.scoreDetails": "جزئیات امتیاز",
  "isales.training.startTraining": "آغاز آموزش",
  "isales.training.queryQuestionnaire": "پرسش و پاسخ",
  "isales.training.Questionnaire": "پرسشنامه آموزشی",
  "isales.training.MaxAnswerNumber": "تعداد بیشینۀ پاسخهای پرسشنامه",
  "isales.training.forwardNumber": "شماره ارسال شده",
  "isales.training.AssociatedSurvey": "پرسشنامه مرتبط",
  "isales.training.resultAnalysis": "آنالیزهای انجام شده",
  "isales.training.surveyDetail": "جزئیات پرسش و پاسخ",
  "isales.training.questionNumber": "تعداد پرسش ها",
  "isales.training.surveyScore": "امتیاز کل نظرسنجی",
  "isales.training.callTime": "قطع زمان تماس",
  "isales.training.callTimeSegment": "قطع زمان تماس",
  "isales.training.specialday.callTime": "بخش زمان تماس تماس ویژه",
  "isales.training.startTraining.exists": "آموزش در حال انجام لطفا بعد از اتمام آموزش دوباره تلاش کنید",
  "isales.taskstatistics.field.feedBackStatistics": "آمار بازخورد",
  "isales.task.message.feedBackRate": "میزان بازخورد",
  "isales.task.message.numberEffFeedback": "تعداد تماسهای بازخورد معتبر",
  "isales.task.message.numberInvalFeedback": "تعداد فراخوانیهای بازخورد نامعتبر",
  "isales.survey.details": "جزئیات پرسش و پاسخ",
  "isales.survey.createTrainingTask": "ایجاد تکلیف آموزشی",
  "isales.taskstatistics.field.specialCall": "فهرست ویژه",
  "isales.taskmanagment.title.open.obsMonitor": "نمایشگر دادۀ OBS",
  "isales.training.taskdetails": "جزئیات تکلیف",
  "isales.training.sampleTotal": "کل اندازۀ نمونه",
  "isales.training.completeSamples": "اندازۀ کاملۀ نمونه",
  "isales.training.remainingSamples": "کمیت باقی مانده",
  "isales.training.trainingRate": "سرعت آموزش",
  "isales.training.surveydetails": "جزئیات آمار پرسشنار",
  "isales.training.averageScore": "امتیاز متوسط",
  "isales.training.voiceAverageScore": "امتیاز متوسط صوت",
  "isales.training.questionScoreDetails": "جزئیات امتیاز",
  "isales.training.answerNumber": "تعداد پاسخها",
  "isales.training.fullScoreNumber": "تعداد امتیازهای کامل",
  "isales.training.averageDuration": "مدت متوسط",
  "isales.training.tip1": "تعداد پاسخها به حد بالایی رسیده است",
  "isales.training.answeringRecord": "در حال پاسخ دادن به ضبط",
  "isales.training.tip2": "اين سوال آموزش ديده است زیاد فشار نکنید",
  "isales.management.message.black.download": "بارگیری الگو بلوک",
  "isales.taskmanagment.title.obs.viewstatistics": "پایش اطلاعات به دست آمده توسط OBS",
  "isales.task.statistics.obs.startTime": "زمان شروع نمي تونه زودتر از هفت روز قبل باشه",
  "isales.task.statistics.obs.endTime": "تفاوت بین زمان آغاز و پایان نمی تواند از نیم ساعت بیشتر شود",
  "isales.warning.data.export": "دادۀ صادر شده حاوی اطلاعات شخصی است. تمرین احتیاط هنگام اجرای این عمل",
  "isales.statistics.business.taskId": "تکلیف",
  "isales.statistics.business.reportType": "نوع گزارش",
  "isales.statistics.business.beginDate": "تاریخ آغاز",
  "isales.statistics.business.endDate": "تاریخ پایان",
  "isales.statistics.business.startYear": "سال شروع",
  "isales.statistics.business.startWeek": "شروع هفته",
  "isales.statistics.business.startMonth": "ماه شروع",
  "isales.statistics.business.endYear": "پایان سال",
  "isales.statistics.business.endWeek": "پایان هفته",
  "isales.statistics.business.endMonth": "پایان ماه",
  "isales.statistics.business.search": "جستجو",
  "isales.statistics.business.reset": "بازنشانی",
  "isales.statistics.business.date": "تاریخ",
  "isales.statistics.business.week": "هفته",
  "isales.statistics.business.month": "ماه",
  "isales.statistics.business.byDay": "گزارش روزانه",
  "isales.statistics.business.byWeek": "گزارش هفتگی",
  "isales.statistics.business.byMonth": "گزارش ماهانه",
  "isales.statistics.business.title": "گزارش تجاری تماس خارج از محدوده",
  "isales.statistics.business.trend.title": "گزارش روند کاري تماس خارج شد",
  "isales.statistics.business.nomorethanerror": "تاریخ انتخاب شده نمی تواند زودتر از ۰ باشند",
  "isales.statistics.business.currentDateCheck": "تاریخ برگزیده باید زودتر از روز جاری باشد",
  "isales.statistics.business.dategt31days": "محدوده تاريخ نميتونه بيشتر از 31 روز طول بکشه",
  "isales.statistics.business.dategt12weeks": "اين هفته نميتونه از 12 هفته بيشتر بشه",
  "isales.statistics.business.dategt6month": "تعداد ماه بيشتر از 6 ماه نمي شود",
  "isales.statistics.business.weekLessThan": "آخر هفته نمي تونه از هفته ي آغازي کمتر باشه",
  "isales.statistics.business.monthLessThan": "ماه آخر نمي تواند زودتر از ماه آغاز باشد",
  "isales.statistics.business.yearLessThan": "پايان سال نمي تواند زودتر از سال آغاز باشد",
  "isales.statistics.business.noData": "داده ای موجود نیست",
  "isales.statistics.business.noMoreThanEight": "تعداد فقره های برگزیده نمی تواند بیش از ۸ باشد",
  "isales.statistics.business.noMoreThanFive": "تعداد فقره های برگزیده نمی تواند بیش از ۵",
  "isales.statistics.business.busiResult": "نتایج کسب و کار",
  "isales.statistics.business.subBusiResult": "نتیجه فرعی تجارت",
  "isales.manual.title.viewPreemptionResult": "نتیجه تماس قبلی",
  "isales.statistics.business.filteringIndicator": "شاخصهای پالایش",
  "isales.statistics.business.indicatorName": "نام شاخص",
  "isales.statistics.business.description": "توصیف",
  "isales.statistics.business.cancel": "لغو",
  "isales.statistics.business.confirm": "تصدیق",
  "isales.statistics.business.saveError": "خطاها",
  "isales.statistics.business.errorInfo": "خرابی در ذخیرۀ. برگزیدن حداقل یک شاخص",
  "isales.statistics.business.error": "خطاها",
  "isales.statistics.business.warningFilterMax": "یک بیشینه از ۸ درصد شاخص را می توان انتخاب کرد",
  "isales.agent.message.description.limit": "توصیف صف مهارت نمی تواند بیش از ۵۰ نویسه باشد",
  "isales.agent.message.updateCtiAndDbFail": "بازآوری خراب شد",
  "isales.operlog.message.exportSurveyData": "صدور پرسشهای آموزشی",
  "isales.taskinfo.message.agent": "اطلاعات نمایندها غير طبيعي هستن بررسی این که آیا نمایندهها به جز صف مهارت جاری هستند یا خیر",
  "isales.appointment.message.plaintext": "کاربر مجوز متن آشکار را ندارد و نمی تواند تماس ها را آغاز کند. لطفاً ، مجوز متن آشکار را فعال کنید",
  "isales.language.label.language": "لطفاً ، یک زبان انتخاب کنید",
  "isales.language.label.zh_CN": "چینی",
  "isales.language.label.en_US": "انگلیسی",
  "isales.language.label.es_ES": "اسپانیایی",
  "isales.taskmanagement.title.batchOpt": "عمل دسته ای",
  "isales.audio.title.beginTime": "زمان آغاز ضبط",
  "isales.audio.title.endTime": "زمان پایان ضبط",
  "isales.audio.title.asrTransResult": "از این روال های رونویسی گفتار",
  "isales.audio.title.confirmedResult": "در این صورت ، ناصحیح تن زنگ",
  "isales.audio.title.confirmStatus": "فراخوانی تجاری",
  "isales.taskstatus.field.confirming": "تائيد شود",
  "isales.taskstatus.field.confirmed": "تائيد شد",
  "isales.rbtresult.field.other": "دیگر",
  "isales.rbtresult.field.emptynumber": "شمارۀ خالی",
  "isales.rbtresult.field.cusclose": "کاربر تماس تلفن را خاموش می کند",
  "isales.rbtresult.field.interbusy": "شبکه اشغال است",
  "isales.rbtresult.field.callershutdown": "تعلیق فراخوانی / یک طرفه است",
  "isales.rbtresult.field.calledshutdown": "تماس گرفت تا بايستد",
  "isales.rbtresult.field.calledbusy": "طرف تماس گرفته شده اشغال است و طرف تماس، تماس تابع انتظار تماس را ثبت کرده است",
  "isales.rbtresult.field.calledbusycalling": "طرف تماس اشغال است و طرف تماس نام تابع انتظار تماس را ثبت نکرده است",
  "isales.rbtresult.field.calledoutarea": "طرف تماس خوانده شده در ناحیه خدمت نیست",
  "isales.rbtresult.field.transfer": "پیش سو کردن تماس",
  "isales.rbtresult.field.tollcaller": "تماس هاي بين المللي ممنوع",
  "isales.rbtresult.field.longtimemissed": "خيلي وقت بود تماسي گرفته نشده بود",
  "isales.rbtresult.field.calledrestriction": "کاربر نام محدودیت های ورودی تماس را تنظیم کرده است",
  "isales.rbtresult.field.linefaulty": "خرابی خط در جهت دیگر عملگرها",
  "isales.rbtresult.field.calledunconnected": "طرف فراخوانی در دسترس نیست، و یادآوری ورودی فعال می شود",
  "isales.rbtresult.emptyparam.message": "زمان آغاز و پایان نمی تواند خالی باشد",
  "isales.asrtransresult.field.undefine": "تعریف نشده",
  "isales.taskmanagement.message.crossmonth": "زمان آغاز و پایان نمی تواند ماهها طول بکشه",
  "isales.taskinfo.url.not.whitelist": "نشانی در فهرست امانت نیست",
  "isales.whitepopuppageurl.protocol.check": "پروتکل های ناامن به کار می روند ، که خطرات امنیتی را شکل می دهد",
  "isales.import.message.ruleExtProp": "ویژگی های سفارشی فعال شدند. ابتدا قاعده ی نگاشت\ را پیکربندی ، و سپس فشار واردات بر اساس قواعد",
  "isales.datasourcemanagement.field.load": "بار گذاری",
  "isales.datasourcemanagement.message.loadSuccess": "متن دادۀ با موفقیت بارگذاری شد",
  "isales.datasourcemanagement.message.loadfail": "بار کردن متن داده شکست خورد",
  "isales.datasourcemanagement.message.confirLoad": "بارگذاری پیکربندی منبع داده ، یک عمل پرخطر بالا است ، لطفاً ، با احتیاط پیش بروید",
  "isales.task.promotion.whatsApp": "WhatsApp",
  "isales.task.promotion.whatsAppChannel": "کانال WhatsApp",
  "isales.task.promotion.whatsApp.message.template": "پیام الگو",
  "isales.task.promotion.whatsApp.message.template.select": "برگزیدن یک پیام الگو WhatsApp",
  "isales.task.promotion.whatsApp.channel.select": "برگزیدن یک مجرای WhatsApp",
  "isales.task.promotion.whatsApp.message.id": "شناسۀ پیام الگو",
  "isales.task.promotion.whatsApp.message.name": "نام پیام الگو",
  "isales.task.promotion.whatsApp.variable.description": "توصیف پارامتر",
  "isales.task.promotion.whatsApp.variable.content": "محتویات پارامتر",
  "isales.management.message.SelectNumberMax": "برگزیدن ۱۰۰ شماره تماس در یک زمان",
  "isales.basicinfo.title.addCallNo": "افزودن شمارۀ تماس",
  "isales.taskmanagement.title.deleteFailExitOne": "پیکربندی حداقل یک شماره تماس برای تکلیف تماس خارج از محدوده",
  "isales.failInterval.check": "ممکن است ریسک وجود داشته باشد وقتی فاصله کمتر از ۱۰ باشد",
  "isales.taskmanagement.title.agentStatistics": "آمار کار نماینده",
  "isales.work.message.daterequest": "زمان آغاز و پایان باید در یک زمان بگذره",
  "isales.work.title.selectAgent": "برگزیدن نماینده",
  "isales.work.title.agent.workno": "کارت شناسايي نماینده",
  "isales.message.promptForAgent": "لطفاً ، شناسۀ نماینده را وارد کنید",
  "isales.button.search": "پرس و پرس و پرس",
  "isales.button.reset": "بازنشانی",
  "isales.title.agent.workno": "کارت شناسايي نماینده",
  "isales.label.agent.accountcode": "حساب خدمات",
  "isales.agent.work.statistics.AgentName": "نام نماینده",
  "isales.button.cancel": "لغو",
  "isales.button.confirm": "تأیید شد",
  "isales.file.message.FilePathTips": "این پارامتر اختیاری است. اگر پروندۀ فراخوانی خارج شوند در مسیر پیش فرض\ ذخیره شود ، این پارامتر اختیاری است. اگر پروندۀ فراخوانی خارج شونده در پوشه در مسیر پیش فرض\ ذخیره شود ، این پارامتر باید تنظیم شود",
  "isales.blacklist.message.timeserror": "زمانهای حد نادرست",
  "isales.blacklist.message.perioderror": "دوره محدودیت نادرست",
  "isales.datasourcemanagement.message.updateDataSourceSuccess": "متن داده با موفقیت اصلاح شده است",
  "isales.datasourcemanagement.message.updateDataSourceFail": "خرابی در تغییر متن داده",
  "isales.task.status.running": "در پیشرفت",
  "isales.task.status.pause": "مکث",
  "isales.task.finished": "انجام شد",
  "isales.task.notFinished": "ناقص",
  "isales.task.info": "اطلاعات تکلیف",
  "isales.task.benginEndTime": "آغاز و پایان زمان",
  "isales.task.detail": "جزئیات تکلیف خروجی",
  "isales.taskmanagement.startTask": "آغاز تکلیف",
  "isales.taskInfo.title.taskTemplate": "الگو تکلیف",
  "isales.taskmanagement.title.taskTime": "زمان تکلیف",
  "isales.taskmanagement.title.setAgent": "انتساب نماینده",
  "isales.taskInfo.title.quotas": "به خاطر عمل کردن",
  "isales.taskInfo.title.quotasTips": "هنگامی که تعداد تماس هایی که توسط نماینده تعیین می شود از مقدار این پارامتر بیشتر می شود، سیستم به طور خودکار تماس ها را بازیابی نمی کند",
  "isales.taskInfo.title.callCompletedNum": "تماسهای انجام شده",
  "isales.taskInfo.title.callCompletedNumTips": "هنگامی که تعداد تماس های کامل از مقدار این پارامتر بیشتر شد، سیستم به طور خودکار تماس ها را بازیابی نمی کند",
  "isales.taskInfo.title.pickUpNum": "کمیت تک زمان درخواست شد",
  "isales.taskInfo.title.pickUpCondition": "پاک کردن داده ها",
  "isales.step.taskinfo": "اطلاعات تکلیف",
  "isales.step.callpolicy": "استراتژی خروجی",
  "isales.step.outboundcalldata": "دادۀ تکلیف",
  "isales.calltime.title.default.datetime": "دورۀ زمانی منظم تاریخ",
  "isales.calltime.title.spec.date": "تاریخ ویژه",
  "isales.calltime.title.add.date": "تاریخ ویژه اضافه کنید",
  "isales.calltime.title.add.time.period": "افزودن دور زمان",
  "isales.calltime.title.holidayName": "نام تاریخ ویژه",
  "isales.calltime.title.holidayType": "نوع",
  "isales.calltime.title.holiday": "وقت ویژه",
  "isales.calltime.title.spec.datetime.period": "دوره زمانی ویژه",
  "isales.calltime.task.template": "الگو تکلیف",
  "isales.calltime.task.template.select.placeholder": "گزینه برای ایجاد تکالیف خارج از الگوها",
  "isales.calltime.task.callerno.select.placeholder": "لطفاً ، شمارۀ تماس را برگزینید",
  "isales.calltime.task.begin.end.date": "زمان آغاز و پایان تکلیف",
  "isales.calldata.prop.filter": "پالایش خصیصۀ دادۀ خروجی فراخوانی",
  "isales.taskinfo.header.policy": "استراتژی تکلیف",
  "isales.taskinfo.call.header.policy": "اسمش رو استراتژي بگو",
  "isales.taskmanagement.title.sysAuto": "سیستم به طور خودکار فراخوانی می راد",
  "isales.work.message.dateRangeRequest": "لطفاً ، گسترۀ زمان پرس وجو را برگزینید",
  "isales.work.message.taskNameLengthLimit": "نام تکلیف نمی تواند از ۵۰ نویسه تجاوز کند",
  "isales.work.message.workNoLengthLimit": "شمارۀ کار نمی تواند از ۵ نویسه تجاوز کند",
  "isales.work.message.workNoTypeLimit": "مقدار باید ۰ یا یک عدد صحیح مثبت باشد",
  "isales.taskInfo.title.filterCondition": "شرط",
  "isales.label.agent.agentType": "نوع نماینده",
  "isales.label.agent.role": "نقش",
  "isales.calleeNo.length.max32": "عدد مشتری می تواند بیشینه ۳۲ نویسه باشد",
  "isales.label.calldata.order": "دنبالهٔ تماس",
  "isales.tasklogic.field.callOrderMethod.byData": "بر اساس خصیصۀ داده",
  "isales.tasklogic.field.callOrderMethod.orderType": "مرتب سازی بر اساس",
  "isales.label.multiple.callno.round": "چرخه تماس های مشتریان چند شماره ای",
  "isales.task.result.export.query": "رفتن به نما",
  "isales.task.result.export.skip": "پرش",
  "isales.task.result.export.message": "فهرست تکلیف صدور ایجاد شده است. می خواهید آن را در فهرست صدور مشاهده کنید ؟",
  "isales.rbtrecognition.remark.length": "رشته می تواند بیشینه ۲۰۰ نویسه داشته باشد",
  "isales.task.field.callFlag0": "براي اعدام",
  "isales.task.field.callFlag1": "اجرا",
  "isales.task.field.callFlag2": "موفقیت آمیز",
  "isales.taskinfo.field.agentType4": "صوتی",
  "isales.taskinfo.field.agentType11": "ویدئو",
  "isales.taskinfo.field.agentType5": "چندرسانه ای",
  "isales.taskinfo.field.agentType99": "عمودی",
  "isales.appointment.title.appointState": "وضعیت رزرو",
  "isales.task.field.appointState1": "رزرو شده است",
  "isales.task.field.appointState2": "آماده برای تماس",
  "isales.task.field.appointState3": "فراخوانی",
  "isales.task.field.appointState4": "انجام شد",
  "isales.taskinfo.title.adjustAgent": "تنظیم نماینده ها",
  "isales.taskinfo.check.empty": "پارامتر نمی تواند خالی باشد",
  "isales.tasktype.field.robot.create": "تماس رباطي جديد از اينجا",
  "isales.tasktype.field.auto.info": "IVR مشترك",
  "isales.tasktype.field.intelligent.info": "ربات هوشمند",
  "isales.taskmanagement.title.batchEditTaskInfo.errTaskType": "تکلیف های انواع مختلف نمی توانند در دسته هایی تغییر شوند",
  "isales.taskmanagement.title.pauseTask.askConfirm": "مطمئنيد که مي خواهيد نمایندهيت خارج از محدوده را به تعویق بیافتید؟ اگر تکلیف به زور مکث شود، تماس های در حال انجام ممکن است تحت تاثیر قرار بگیرد.",
  "isales.task.result.callrecord.conversation": "ضبط گفتگو",
  "isales.tasktype.field.auto.robot.create": "إنشاء IVR عادي",
  "isales.tasktype.field.intelligent.robot.create": "ساخت یک ربات هوشمند جدید",
  "isales.management.title.callbot.auto": "پیکربندی دکمه برای فراخوانی ربات",
  "isales.taskmanagement.title.taskDataVolume": "حجم داده های تکلیف",
  "isales.taskmanagement.title.callCompletionRate": "میزان تکمیل تماس",
  "isales.taskmanagement.title.importData.template": "الگو",
  "isales.taskmanagement.title.importData.upload": "بارگذاری",
  "isales.taskmanagement.title.importData.selectFile": "برگزیدن پرونده",
  "isales.taskmanagement.pauseTask": "مکث تکلیف",
  "isales.taskInfo.spec.date.title.quotasTips": "این پیکربندی ، دور تاریخ و زمان ویژه ای را که به تماسهای خارجه نیاز دارد ، نشان می دهد",
  "isales.calltime.title.spec.date.empty.warn": "تاریخ ویژه پیکربندی نشد!",
  "isales.tasklogic.message.empty.orderNo": "یک شماره سریال خالی وجود دارد، لطفاً دوباره وارد کنید",
  "isales.taskmanagement.exportResult": "صدور نتایج تکلیف",
  "isales.outboundBusiness.statistic.di": "هفته",
  "isales.outboundBusiness.statistic.week": " ",
  "isales.rbtrecognition.remark.tip": "حاوی نویسه های ویژه نمی باشد:",
  "isales.business.callInterval.message.must": "فاصله مورد نیاز است",
  "isales.tasktype.field.robot": "روبات خارج شده",
  "isales.taskmanagement.title.sysAuto.template": "الگو تماس خارج از سیستم",
  'isales.taskmanagement.title.sysAuto.templateAdd': 'قالب مكالمة بوت جديد',
  "isales.tasktype.field.robot.template": "الگو تماس ربات خارج از محدوده",
  'isales.tasktype.field.robot.templateAdd': 'إنشاء قالب مكالمة صادرة تلقائية للنظام',
  "isales.template.title.addManual": "الگو تماس خارج شونده دستی",
  "isales.manual.field.callingRecord": "ضبط تماس خارج از مرز",
  "isales.call.count": "تعداد تماسها",
  "isales.task.promotion.createBeginTime": "ایجاد زمان آغاز",
  "isales.task.promotion.createEndTime": "زمان پایان ایجاد",
  "isales.task.promotion.timeerror": "زمان موثر باید زودتر از زمان انقضا باشد",
  "isales.busiresult.description.length": "طول نویسه از گسترۀ نویسه بیش از گسترۀ مقدار است. طول باید کمتر یا ۵۰۰ نویسه باشد",
  "isales.import.coverMode.tips": "جای نوشت واردات ، همۀ داده هایی که باید خوانده شوند را حذف می کند ، که زمان زیادی می برد. تمرین احتیاط هنگام اجرای این عمل",
  "isales.taskmanagement.title.taskContent": "محتوای تکلیف",
  "isales.taskmanagement.title.saveAndImportData": "ذخیره و واردات داده",
  "isales.task.promotion.numberOnly": "فقط اعداد غیر منفی مجاز هستند",
  "isales.data.lock": "برخی داده های تماس پردازش نمی شوند. به عنوان مثال شما واردات ، اضافه کردن ، یا حذف داده های تماس خارج شونده هستید. لطفاً بعداً سعی کنید",
  "isales.survey.type": "نوع پرسشنامه",
  "ccsurvey.label.scenarioType.manual": "پرسشنامه دستی",
  "ccsurvey.label.scenarioType.manualIvr": "پرسشنامه دستی در مقابل تلفن گویا",
  "isales.tasktype.field.manualIvr.info": "پخش فرآیند پرسشنامه",
  "ccsurvey.info.tip36": "پرسش لازم است",
  "ccsurvey.transfer.tip36": "معلق به نوبت به پرسشنامه پاسخ از سر",
  "isales.taskinfo.message.callintervalrangeMax": "لطفاً ، یک عدد صحیح مثبت بین ۱-۸۶۰۰ وارد کن.",
  "isales.notification.receiver.whatsapp.placeholder": "لطفاً ، شمارۀ گیرنده را با کد منطقه ی بین المللی وارد کنید",
  "isales.data.title.more": "بيشتر",
  "isales.manual.exit.preview": "اجرای یک تماس خروجی از پیش نمایش",
  "isales.manual.exit.preview.tips": "اگر زمانهای پیش نمایش خارج یا پنجره بسته باشد، سیستم به طور خودکار با شما تماس می گیرد.",
  'isales.agent.message.modifyPwdVdnFailed': 'تغییر رمز عبور مدیریت پیکربندی مجاز نیست!',
  'isales.business.result.message.checkCodePoint': 'فقط اعداد، حروف، -،. را می توان وارد کرد.',
  'isales.import.calldata.selecttime': 'برگزیدن زمان اجرا.',
  'isales.statistics.message.chooseCondition': 'لطفاً ابتدا شرایط پرس و جو را انتخاب کنید',
  'isales.taskpolicy.field.time': '(دومین)',
  'isales.taskInfo.title.filterSubCondition': 'شرایط فرعی را فیلتر کنید',
  'isales.agent.work.statistics.tableTips': 'الرجاء تحديد المهمة أو الموظف الذي تريد عرضه',
  'isales.file.message.rule.add.success': 'تم إنشاء القاعدة الجديدة بنجاح',
  'isales.file.title.server.add.success': 'نجح إنشاء معلومات الخادم',
  'isales.management.title.selectResult': 'تحديد نتيجة المكالمة الصادرة',
  'isales.specialday.placeholder.year.beginTime': 'التنسيق هو: MM-DD ، على سبيل المثال ، 12-01 ،',
  'isales.management.message.appointTime': 'زمان تماس ذخیره شده نمی تواند زودتر از زمان جاری سیستم باشد',
  'isales.management.message.intelligence.taskName': 'طول نام وظیفه رباتی که در خارج از محدوده تماس رباتی قرار دارد نمی تواند بیش از ۳۶ بایت باشد. یک حرف یا شماره یک بایت را اشغال می کند و یک کاراکتر چینی سه بایت دارد.',
}