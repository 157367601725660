<template>
  <sweet-popover v-if="isShowPop" trigger="click" :title='i18n("title.contact.agent.name")' :placement="position" width="250px" popper-class="agent-name-popover" :content="agentName">
    <template #reference>
      <sweet-link :underline="false" type="primary" @click="showCallerWorkName">
        {{ workNumber || '' }}
      </sweet-link>
    </template>
  </sweet-popover>
  <span v-else>{{ workNumber || '' }}</span>
</template>

<script setup lang="ts">
import { getCurrentInstance, ref, toRefs, computed } from "vue";
import { queryAccountNameByWorkNos } from "@/views/ccmanagement/ccma-api/index.js";

// 国际化封装
const { appContext: { config: { globalProperties } } } = getCurrentInstance() as any;
const i18n = (param: string, ...args: Array<any>): string => {
  return globalProperties.$t(param, args);
};

const props = defineProps({
  workNumber: [Number, String], // 座席工号
  isShowPop: Boolean // 判断是否展示气泡卡片
});
const {workNumber , isShowPop } = toRefs(props);
const agentName = ref<String>();
const loading = ref<boolean>(false);
const position = computed(() => window.isRTL ? 'left' : 'right')

const showCallerWorkName = () => {
  return new Promise(() => {
    if (!workNumber?.value) {
      agentName.value = i18n("ccm.contact.tip.not.get.workname");
      return;
    }
    const workName = sessionStorage.getItem(`caller_${workNumber.value}`);
    if (workName != null) {
      agentName.value = workName || i18n("ccm.contact.tip.not.get.workname");
      return;
    }
    loading.value =true;
    const params = { workNos: [workNumber.value.toString()] };
    queryAccountNameByWorkNos(params).then((resp: any) => {
      if (resp.returnCode == "0") {
        const map = resp.result;
        Object.keys(map).forEach((key) => {
          sessionStorage.setItem(`caller_${key}`, map[key]);
        });
      }
      agentName.value = sessionStorage.getItem(`caller_${workNumber.value}`) || i18n("ccm.contact.tip.not.get.workname");
    }).finally(() => loading.value = false);
  })
}
</script>

<style scoped lang="less">
.hlds-downline {
  border-bottom: 1px solid #ccccccad;
  padding-bottom: 10px;
}

.agent-name-popover {
  padding: 0;
  max-width: 250px;
  height: 180px;
  word-wrap: break-word;

  .agent-name-popover--content {
    max-width: 250px;
    width: fit-content(250px);
  }

  .hlds-div-show {
    width: 250px;
    height: 150px;
    left: 15%;
    top: 45%;
    padding: 15px 0;
    transform: translate(-50%,-50%);
    background: inherit;
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(229, 229, 229, 1);
    border-radius: 8px;
    -moz-box-shadow: 5px 5px 5px rgba(204, 204, 204, 0.349019607843137);
    -webkit-box-shadow: 5px 5px 5px rgba(204, 204, 204, 0.349019607843137);
    box-shadow: 5px 5px 5px rgba(204, 204, 204, 0.349019607843137);
    z-index: 10;
  }
}

</style>