<template>
    <div>
        <sweet-form class="isales-task-info-form" :model="showModel" :rules="sysAutoPolicyRules"
                    :label-position="labelPosition"
                    label-width="auto" ref="sysAutoPolicyForm"
                    :disabled="isView || isViewTemplate || (isBatchEdit && showModel.callPolicyDisabled)">
            <!--任务策略-->
            <sweet-card style="margin: 20px 0 16px">
                <div class="card-header" style="margin: 0px 0px 10px 0px;">
                    {{$t('isales.taskinfo.header.policy')}}
                    <sweet-icon v-show="isBatchEdit" class="aicc-m-left--small"
                                @click="showModel.callPolicyDisabled = !showModel.callPolicyDisabled">
                        <i v-show="showModel.callPolicyDisabled" class="sweetui-icon-edit-l"
                           :title="$t('isales.data.title.edit')"></i>
                        <i v-show="!showModel.callPolicyDisabled" class="sweetui-icon-undo"
                           :title="$t('isales.data.title.undoEdit')"></i>
                    </sweet-icon>
                </div>
                <TaskBasicPolicy :showModel="showModel" ref="taskBasicPolicy"></TaskBasicPolicy>
            </sweet-card>
        </sweet-form>
        <sweet-form class="isales-task-info-form" :model="showModel" :rules="callPolicyRules"
                    :label-position="labelPosition"
                    label-width="auto" ref="callPolicyForm"
                    :disabled="isView || isViewTemplate || (isBatchEdit && showModel.callRetryPolicyDisabled)">
            <!--呼叫策略-->
            <sweet-card>
                <div class="card-header" style="margin: 0px 0px 10px 0px;">
                    {{$t('isales.taskinfo.call.header.policy')}}
                    <sweet-icon v-show="isBatchEdit" class="aicc-m-left--small"
                                @click="openBatchCallPolicy">
                        <i v-show="showModel.callRetryPolicyDisabled" class="sweetui-icon-edit-l"
                           :title="$t('isales.data.title.edit')"></i>
                        <i v-show="!showModel.callRetryPolicyDisabled" class="sweetui-icon-undo"
                           :title="$t('isales.data.title.undoEdit')"></i>
                    </sweet-icon>
                </div>
                <CallPolicy :showModel="showModel" ref="callPolicyRef"></CallPolicy>
            </sweet-card>
        </sweet-form>
    </div>
</template>

<script>
    import TaskBasicPolicy from "@/views/isales/views/systemAutoCallTask/TaskBasicPolicy.vue";
    import CallPolicy from "@/views/isales/views/systemAutoCallTask/CallPolicy.vue";
    import {queryBatchTaskParams, queryCallPolicy} from "@/views/isales/api/systemAutoCallTask";
    import {useIsalesTaskManagementStore} from "@/views/isales/stores/taskManagement";
    export default {
        name: "TaskCallPolicy",
        setup() {
            const taskManagementStore = useIsalesTaskManagementStore()
            return { taskManagementStore }
        },
        components: {
            TaskBasicPolicy,
            CallPolicy
        },
        props: {
            showModel: {
                type: Object,
                default() {
                    return {}
                }
            },
        },
        data() {
            return {
                labelPosition: window.isRTL ? 'right' : 'left',
                queryType: '',
                queryTaskType:'',
                active: 1,
                stepsList: [
                    {title: this.$t('isales.step.taskinfo')},
                    {title: this.$t('isales.step.callpolicy')},
                    {title: this.$t('isales.step.outboundcalldata')}
                ]
            }
        },
        methods: {
            async initTaskCallPolicy(taskId) {
                await queryCallPolicy({"taskId": taskId}).then(res => {
                    if (res) {
                        if (res.callOrder) {
                            this.showModel.callPolicy.callOrder = res.callOrder;
                        } else {
                            this.showModel.callPolicy.callOrder = 0;
                        }
                        let orderPolicy = res.orderPolicy;
                        if (orderPolicy) {
                            this.showModel.callPolicy.orderPolicy = JSON.parse(orderPolicy);
                            this.showModel.callPolicy.orderPolicy.forEach(item => {
                                if (item['orderType'] && item['orderType'] !== '') {
                                    item['typeId'] = item['orderType'];
                                }
                                item['typeId']=item['typeId'].toString();
                            });
                        }
                        let filterPolicy = res.filterPolicy;
                        if (filterPolicy) {
                            let filterPolicyCondition = JSON.parse(filterPolicy)
                            // 没有subConditions的为主条件
                            let primaryCondition = filterPolicyCondition.conditions.filter(condition => !condition.subConditions)
                            // 有subConditions的为子条件
                            let subConditions = filterPolicyCondition.conditions.filter(condition => !!condition.subConditions)
                            let operator = filterPolicyCondition.operator
                            this.showModel.filterPolicy = {
                                primaryCondition: primaryCondition,
                                subConditions: subConditions,
                                operator: operator
                            }
                        } else {
                            this.showModel.filterPolicy = {
                                primaryCondition: [],
                                subConditions: [],
                            }
                        }
                        this.$nextTick(() => {
                            this.$refs.taskBasicPolicy.setSysAutoSelectedAttributes(filterPolicy ? true : false);
                        });
                        if (res.callMode === 1) {
                            this.showModel.callPolicy.turns = JSON.parse(res.roundPolicy);
                            let callOrderTurn;
                            if (this.showModel.callPolicy.turns[0].turnType) {
                                this.showModel.callPolicy.callType = 1;
                                this.showModel.callPolicy.chooseType = 0;
                                callOrderTurn = '1';
                            } else {
                                this.showModel.callPolicy.callType = 1;
                                this.showModel.callPolicy.chooseType = 1;
                                callOrderTurn = '2';
                            }
                            this.$refs.callPolicyRef.setCallOrderTurn(callOrderTurn);
                        }

                    }
                });
                await this.$refs.callPolicyRef.setRetryPolicy(taskId, this.queryTaskType ? this.queryTaskType : this.showModel.taskInfo.taskType);
            },
            async policyValidate() {
                let businessPolicy = await this.$refs.callPolicyRef.checkBusinessPolicy();
                if(businessPolicy!==true){
                    return false;
                }
                let sysAutoResult = await this.$refs.sysAutoPolicyForm.validate().catch(err => err);
                if (sysAutoResult !== true) {
                    if (sysAutoResult['filterPolicy.primaryCondition.-1.value']) {
                        delete sysAutoResult['filterPolicy.primaryCondition.-1.value']
                    }
                    if (sysAutoResult['filterPolicy.subConditions.-1.subConditions.-1.value']) {
                        delete sysAutoResult['filterPolicy.subConditions.-1.subConditions.-1.value']
                    }
                }
                let keysSysAutoResult = Object.keys(sysAutoResult)
                if (keysSysAutoResult.length) {
                    return false;
                }
                let policyResult = await this.$refs.callPolicyForm.validate().catch(err => err);
                if (policyResult !== true) {
                  // 会有一个index为-1的rule，原因未知，暂时规避
                    if (this.showModel.retryPolicy.busiResultPolicyList && this.showModel.retryPolicy.busiResultPolicyList.length > 0){
                        if (policyResult['retryPolicy.busiResultPolicyList.-1.callInterval']) {
                            delete policyResult['retryPolicy.busiResultPolicyList.-1.callInterval']
                        }
                    }
                    if (this.showModel.callPolicy.turns && this.showModel.callPolicy.turns.length > 0){
                        if (policyResult['callPolicy.turns.-1.turnOrder']) {
                            delete policyResult['callPolicy.turns.-1.turnOrder']
                        }
                    }
                  if (this.showModel.retryPolicy.globalPolicyList && this.showModel.retryPolicy.globalPolicyList.length > 0){
                    if (policyResult['retryPolicy.globalPolicyList.-1.result']) {
                      delete policyResult['retryPolicy.globalPolicyList.-1.result']
                    }
                    if (policyResult['retryPolicy.globalPolicyList.-1.retryNumber']) {
                      delete policyResult['retryPolicy.globalPolicyList.-1.retryNumber']
                    }
                    if (policyResult['retryPolicy.globalPolicyList.-1.callInterval']) {
                      delete policyResult['retryPolicy.globalPolicyList.-1.callInterval']
                    }
                  }
                  if (this.showModel.retryPolicy.callNoPolicyList && this.showModel.retryPolicy.callNoPolicyList.length > 0) {
                    for (let i = 0; i< this.showModel.retryPolicy.callNoPolicyList.length; i++) {
                      const result = 'retryPolicy.callNoPolicyList.' + i + '.policy.-1.result'
                      if (policyResult[result]) {
                        delete policyResult[result]
                      }
                      const retryNumber = 'retryPolicy.callNoPolicyList.' + i + '.policy.-1.retryNumber'
                      if (policyResult[retryNumber]) {
                        delete policyResult[retryNumber]
                      }
                      const callInterval = 'retryPolicy.callNoPolicyList.' + i + '.policy.-1.callInterval'
                      if (policyResult[callInterval]) {
                        delete policyResult[callInterval]
                      }
                    }
                  }
                }
                let keys = Object.keys(policyResult)
                if (keys.length) {
                  return false;
                }
                return true;
            },
            async openBatchCallPolicy() {
                // 查询
                let params = {"taskIdArr": this.taskManagementStore.batchEditTaskIds, "batchType": "batchEditCallLogic"};
                let queryBatchTaskResult = await queryBatchTaskParams(params);
                if (queryBatchTaskResult && queryBatchTaskResult["taskStatusList"] && queryBatchTaskResult["taskTypeList"] && queryBatchTaskResult["policyTypeList"]) {
                    // 判断任务状态
                    let taskStatusList = queryBatchTaskResult["taskStatusList"];
                    if (taskStatusList.indexOf("0") >= 0 || taskStatusList.indexOf("4") >= 0 || taskStatusList.indexOf("5") >= 0 || taskStatusList.indexOf("6") >= 0) {
                        this.$message({
                            type: 'error',
                          duration: 10000,
                          offset: 56,
                          'show-close': true,
                            message: this.$t('isales.taskmanagement.title.batchEditTaskInfo.errStatus')
                        });
                        return;
                    }
                    let taskTypeList = queryBatchTaskResult["taskTypeList"];
                    // 人工外呼没有外呼逻辑配置页面
                    if (taskTypeList.indexOf("4") >= 0) {
                        this.$message({
                            type: 'error',
                          duration: 10000,
                          offset: 56,
                          'show-close': true,
                            message: this.$t('isales.taskinfo.message.batchEditCallLogic.manualExist')
                        });
                        return;
                    }

                    // 呼叫方式必须一致（按顺序、按轮次）
                    let callModes = queryBatchTaskResult["callModes"];
                    if (callModes && callModes.length > 1) {
                        this.$message({
                            type: 'error',
                          duration: 10000,
                          offset: 56,
                          'show-close': true,
                            message: this.$t('isales.taskinfo.message.batchEditCallLogic.differentCallModeExist')
                        });
                        return;
                    }

                    if (callModes) {
                        this.showModel.callPolicy.callMode = callModes[0];
                    }

                    // 轮次形式必须一致（按号码分类、按号码序号）
                    let callTurnTypes = queryBatchTaskResult["callTurnTypes"];
                    if (callTurnTypes && callTurnTypes.length > 1) {
                        this.$message({
                            type: 'error',
                          duration: 10000,
                          offset: 56,
                          'show-close': true,
                            message: this.$t('isales.taskinfo.message.batchEditCallLogic.differentRoundTypeExist')
                        });
                        return;
                    }

                    if (callTurnTypes) {
                        this.showModel.callPolicy.roundCallMode = callTurnTypes[0]
                    }
                    if (this.showModel.callPolicy.callMode === 1) {
                        let callOrderTurn;
                        if (this.showModel.callPolicy.roundCallMode && this.showModel.callPolicy.roundCallMode==='0') {
                            this.showModel.callPolicy.callType = 1;
                            this.showModel.callPolicy.chooseType = 0;
                            callOrderTurn = '1';
                        } else {
                            this.showModel.callPolicy.callType = 1;
                            this.showModel.callPolicy.chooseType = 1;
                            callOrderTurn = '2';
                        }
                        this.$refs.callPolicyRef.setCallOrderTurn(callOrderTurn);
                    }

                    // 判断任务策略类型
                    let policyTypeList = queryBatchTaskResult["policyTypeList"];
                    if (policyTypeList.length > 1) {
                        this.$message({
                            type: 'error',
                          duration: 10000,
                          offset: 56,
                          'show-close': true,
                            message: this.$t('isales.taskinfo.message.batchEditCallLogic.differentPolicyTypeExist')
                        });
                        return;
                    }
                    let policyType = policyTypeList[0];
                    this.showModel.retryPolicy.policyType = policyType;
                }
                this.showModel.callRetryPolicyDisabled = !this.showModel.callRetryPolicyDisabled
            },
            callTimesValidator (rule, value, callback) {
                if ( Number(value) < rule.min || Number(value) > rule.max) {
                    callback($t('isales.taskpolicy.message.rangeAutoCallTime', [rule.min, rule.max]))
                } else {
                    callback()
                }
            },
        },
        computed: {
            isView() {
                return this.queryType === 'view'
            },
            isEdit() {
                return this.queryType === 'edit'
            },
            isCreate() {
                return this.queryType === 'create'
            },
            isBatchEdit() {
                return this.queryType === 'batchEdit'
            },
            isCreateTemplate() {// 新建外呼任务模型
                return this.queryType === 'createTemplate'
            },
            isEditTemplate() {// 编辑外呼任务模型
                return this.queryType === 'editTemplate'
            },
            isViewTemplate() {// 编辑外呼任务模型
                return this.queryType === 'viewTemplate'
            },
            // 系统外呼校验
            sysAutoPolicyRules() {
                const rangeAutoCallTime = (rule, value, callback) => {
                    let obOverTime = this.showModel.policy.obOverTime;
                    let validateFlag = true;
                    if (value && (parseInt(value) < 5 || parseInt(obOverTime) - parseInt(value) < 4)) {
                        validateFlag = false;
                    }
                    let parameters = [5, parseInt(obOverTime) - 4];
                    if (!validateFlag) {
                        callback($t('isales.taskpolicy.message.rangeAutoCallTime', parameters));
                    } else {
                        callback()
                    }
                }
                return {
                    policy: {
                        predictMethod: {
                            required: true,
                            message: this.$t('isales.business.result.message.must')
                        },
                        utilRateMethod: {
                            required: true,
                            message: this.$t('isales.business.result.message.must')
                        },
                        autoCallTime: [
                            {required: true, message: this.$t('isales.business.result.message.must')},
                            {validator: rangeAutoCallTime},
                            { min: 5, max: 120, validator: this.callTimesValidator, trigger: ['blur', 'change'] }
                        ],
                    },
                }

            },
            callPolicyRules(){
                return {
                    retryPolicy: {
                        policyType: [{required: true, message: this.$t('isales.business.result.message.must'), trigger: ['change', 'blur']}],
                        retryDay: [{required: true, message: this.$t('isales.business.result.message.must'), trigger: ['change', 'blur']}],
                        retryTimes: [{required: true, message: this.$t('isales.business.result.message.must'), trigger: ['change', 'blur']}],
                        callInterval: [
                            {required: true, message: this.$t('isales.business.result.message.must'), trigger: ['change', 'blur']},
                            {validator: this.$Validate.checkDefaultIntervalRang, trigger: ['blur', 'change']}],
                    },
                }
            }
        },
        async created() {
            this.queryType = this.$route.query.type;
            this.queryTaskType = this.$route.query.taskType;
        }
    }
</script>

<style scoped>
    .card-header {
        font-size: var(--16px-to-rem);
        color: #1C1C1C;
        font-weight: bold;
    }
</style>