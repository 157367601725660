import i18n from '@/lang/index'

const $t = i18n.global.t

export const sentPeriodList = [
    {value: 1, label: $t("cms.reportsubscribe.field.monday")},
    {value: 2, label: $t("cms.reportsubscribe.field.tuesday")},
    {value: 3, label: $t("cms.reportsubscribe.field.wednesday")},
    {value: 4, label: $t("cms.reportsubscribe.field.thursday")},
    {value: 5, label: $t("cms.reportsubscribe.field.friday")},
    {value: 6, label: $t("cms.reportsubscribe.field.saturday")},
    {value: 7, label: $t("cms.reportsubscribe.field.sunday")}
];

export const typeItems = [
    {
        "value": "1",
        "label": $t('cms.report.field.byDay')
    },
    {
        "value": "2",
        "label": $t('cms.report.field.byWeek')
    },
    {
        "value": "3",
        "label": $t('cms.report.field.byMonth')
    }
];

export const reportNameList = [
    "callCenterPerformanceReport",
    "callCenterPerformanceReportByAccess",
    "ivrTrafficReport",
    "ivrTrafficReportByAccess",
    "skillQueuePerformanceReport",
    "skillQueuePerformanceReportByAccess",
    "skillQueueSummaryReport",
    "agentSummaryReport",
    "agentOutboundSummaryReport",
    "agentConnectionOperationReport",
    "abandonedCallsSummaryReport"
];

export const reportMonitorNameList = [
    {value: "callCenterPerformanceReport", label: $t('cms.report.title.callCenterReport')},
    {value: "callCenterPerformanceReportByAccess", label: $t('cms.report.title.callCenterAccessReport')},
    {value: "ivrTrafficReport", label: $t('cms.report.title.IVRReport')},
    {value: "ivrTrafficReportByAccess", label: $t('cms.report.title.IVRAccessReport')},
    {value: "skillQueuePerformanceReport", label: $t('cms.report.title.skillReport')},
    {value: "skillQueuePerformanceReportByAccess", label: $t('cms.report.title.skillAccessReport')},
    {value: "skillQueueSummaryReport", label: $t('cms.report.title.skillAllReport')},
    {value: "agentSummaryReport", label: $t('cms.report.title.agentReport')},
    {value: "agentOutboundSummaryReport", label: $t('cms.report.title.agentOutCallReport')},
    {value: "agentConnectionOperationReport", label: $t('cms.report.title.agentOperationReport')},
    {value: "abandonedCallsSummaryReport", label: $t('cms.report.title.abandonRingReport')}
];

export const reportFilterFields = {
    callCenterPerformanceReport: [
        {value: "SYSTEMINBOUND", name: "systemInBound"}, {
            value: "SYSTEMINSUCC",
            name: "systemInSucc"
        }, {value: "SYSSUCCRATE", name: "sysSuccRate"},
        {value: "SYSLOSTCALLS", name: "sysLostCalls"}, {value: "IVRINBOUND", name: "IVRInBound"}, {
            value: "IVRINSUCC",
            name: "IVRInSucc"
        },
        {value: "IVRSUCCRATE", name: "IVRSuccRate"}, {
            value: "ONLYIVRSERVICE",
            name: "onlyIVRService"
        }, {value: "IVRSERVICERATE", name: "IVRServiceRate"},
        {value: "AGENTOFFERED", name: "agentOffered"}, {
            value: "AGENTSERVICE",
            name: "agentService"
        }, {value: "AWARNESSRATE", name: "awarnessRate"},
        {value: "AGENTSERVICERATE", name: "agentServiceRate"},
        {value: "AGENTLOGINNUM", name: "agentLoginNum"}, {value: "PERAGENTNUM", name: "perAgentNum"}
    ],
    callCenterPerformanceReportByAccess: [
        {value: "SYSTEMINBOUND", name: "systemInBound"}, {
            value: "SYSTEMINSUCC",
            name: "systemInSucc"
        }, {value: "SYSSUCCRATE", name: "sysSuccRate"},
        {value: "SYSLOSTCALLS", name: "sysLostCalls"}, {value: "IVRINBOUND", name: "IVRInBound"}, {
            value: "IVRINSUCC",
            name: "IVRInSucc"
        },
        {value: "IVRSUCCRATE", name: "IVRSuccRate"}, {
            value: "ONLYIVRSERVICE",
            name: "onlyIVRService"
        }, {value: "IVRSERVICERATE", name: "IVRServiceRate"},
        {value: "AGENTOFFERED", name: "agentOffered"}, {
            value: "AGENTSERVICE",
            name: "agentService"
        }, {value: "AWARNESSRATE", name: "awarnessRate"},
        {value: "AGENTSERVICERATE", name: "agentServiceRate"},
        {value: "AGENTLOGINNUM", name: "agentLoginNum"}, {value: "PERAGENTNUM", name: "perAgentNum"}
    ],
    ivrTrafficReport: [
        {value: "IVRINBOUND", name: "IVRInBound"}, {value: "IVRSUCCESS", name: "IVRInSucc"},
        {value: "IVRSUCCRATE", name: "IVRSuccRate"}, {
            value: "ONLYIVRSERVICE",
            name: "onlyIVRService"
        }, {value: "IVRSERVICERATE", name: "IVRServiceRate"},
        {value: "IVRSHORTCALLS", name: "IVRShortCalls"}, {
            value: "TOTALIVRSERVICE",
            name: "totalLIVRService"
        }, {value: "AVGIVRSERVICE", name: "avgIVRService"}
    ],
    ivrTrafficReportByAccess: [
        {value: "IVRINBOUND", name: "IVRInBound"}, {value: "IVRSUCCESS", name: "IVRInSucc"},
        {value: "IVRSUCCRATE", name: "IVRSuccRate"}, {
            value: "ONLYIVRSERVICE",
            name: "onlyIVRService"
        }, {value: "IVRSERVICERATE", name: "IVRServiceRate"},
        {value: "IVRSHORTCALLS", name: "IVRShortCalls"}, {
            value: "TOTALIVRSERVICE",
            name: "totalLIVRService"
        }, {value: "AVGIVRSERVICE", name: "avgIVRService"}
    ],
    skillQueuePerformanceReport: [
        {value: "INOCCUPYNUM", name: "inOccupyNum"}, {
            value: "INCALLSUCCNUM",
            name: "inCallSuccNum"
        }, {value: "CALLSUCCRATE", name: "callSuccRate"},
        {value: "INCALLFAILNUM", name: "inCallFailNum"}, {value: "CALL3RATE", name: "call3Rate"}, {
            value: "CALL4RATE",
            name: "call4Rate"
        },
        {value: "CALL6RATE", name: "call6Rate"}, {
            value: "AVGSUCCWAITANSTIME",
            name: "succWaitAnsRate"
        }, {value: "AVGFAILQUEUEWAITTIME", name: "failQueueWaitRate"},
        {value: "AVGINCALLTIME", name: "inCallRate"}, {
            value: "AVGWAITANSTIME",
            name: "waitAnsRate"
        }, {value: "ABORTQUEUEWAITNUM", name: "abortQueueWaitNum"},
        {value: "NOACKNUM", name: "noAckNum"}
    ],
    skillQueuePerformanceReportByAccess: [
        {value: "INOCCUPYNUM", name: "inOccupyNum"}, {
            value: "INCALLSUCCNUM",
            name: "inCallSuccNum"
        }, {value: "CALLSUCCRATE", name: "callSuccRate"},
        {value: "INCALLFAILNUM", name: "inCallFailNum"}, {value: "CALL3RATE", name: "call3Rate"}, {
            value: "CALL4RATE",
            name: "call4Rate"
        },
        {value: "CALL6RATE", name: "call6Rate"}, {
            value: "AVGSUCCWAITANSTIME",
            name: "succWaitAnsRate"
        }, {value: "AVGFAILQUEUEWAITTIME", name: "failQueueWaitRate"},
        {value: "AVGINCALLTIME", name: "inCallRate"}, {
            value: "AVGWAITANSTIME",
            name: "waitAnsRate"
        }, {value: "ABORTQUEUEWAITNUM", name: "abortQueueWaitNum"},
        {value: "NOACKNUM", name: "noAckNum"}
    ],
    skillQueueSummaryReport: [
        {value: "INOCCUPYNUM", name: "inOccupyNum"}, {
            value: "INCALLSUCCNUM",
            name: "inCallSuccNum"
        }, {value: "SUCCANSRATE", name: "succAnsRate"},
        {value: "AVGANSTIME", name: "avgAnsTime"}, {value: "ANS20SRATE", name: "ans20s"}, {
            value: "AVGCALLINTIME",
            name: "avgCallInTime"
        },
        {value: "AVGINRINGTIME", name: "avgInRingTime"}, {
            value: "INCALLFAILNUM",
            name: "inCallFailNum"
        }, {value: "FAILANSRATE", name: "failAnsRate"},
        {value: "FAILAVGTIME", name: "failAvgTime"}, {
            value: "MAXQUEUEWAITTIME",
            name: "maxQueueWaitTime"
        }, {value: "OUTOCCUPYNUM", name: "outOccupyNum"},
        {value: "OUTCALLSUCCNUM", name: "outCallSuccNum"}, {
            value: "OUTSUCCANSRATE",
            name: "outSuccAnsRate"
        }, {value: "OUTFAILNUM", name: "outFailNum"},
        {value: "OUTAVGCALLTIME", name: "outAvgCallTime"}, {
            value: "OUTAVGANSTIME",
            name: "outAvgAnsTime"
        }, {value: "ACDCALLS", name: "acdCalls"},
        {value: "AVGPERHNUM", name: "avgPerHNum"},
        {value: "WORKRATE", name: "workRate"}, {value: "ONLINERATE", name: "onlineRate"}, {
            value: "WORKTIME",
            name: "workTime"
        },
        {value: "ACDTIME", name: "acdTime"}, {value: "ACDTIMERATE", name: "acdTimeRate"}, {
            value: "AVGACDTIME",
            name: "avgAcdTime"
        },
        {value: "ACWTIME", name: "acwTime"}, {value: "ACWTIMERATE", name: "acwTimeRate"}, {
            value: "AVGACWTIME",
            name: "avgAcwTime"
        },
        {value: "AVGHANDLETIME", name: "avgHandleTime"}, {value: "AUXTIME", name: "auxTime"}, {
            value: "AUXTIMERATE",
            name: "auxTimeRate"
        },
        {value: "BUSYTIME", name: "busyTime"}, {value: "BUSYTIMERATE", name: "busyTimeRate"}, {
            value: "OTHERTIME",
            name: "otherTime"
        },
        {value: "OTHERTIMERATE", name: "otherTimeRate"}, {value: "RINGTIME", name: "ringTime"}, {
            value: "AVAILTIME",
            name: "availTime"
        },
        {value: "AVAILTIMERATE", name: "availTimeRate"}, {value: "HOLDTIME", name: "holdTime"}, {
            value: "AVGHOLDTIME",
            name: "avgHoldTime"
        }
    ],
    agentSummaryReport: [
        {value: "INWAITANSNUM", name: "inWaitAnsNum"}, {
            value: "INCALLSUCCNUM",
            name: "inCallSuccNum"
        }, {value: "INANSWERRATE", name: "inAnswerRate"},
        {value: "CALLABANDONEDRING", name: "callAbandonedRing"}, {
            value: "INAVGRINGTIME",
            name: "inAvgRingTime"
        }, {value: "INAVGCALLTIME", name: "inAvgCallTime"},
        {value: "OUTOCCUPYNUM", name: "outWaitAnsNum"}, {
            value: "OUTCALLSUCCNUM",
            name: "outCallSuccNum"
        }, {value: "OUTCALLSUCCNUMRATE", name: "outAnswerRate"},
        {value: "OUTAVGRINGTIME", name: "outAvgRingTime"}, {
            value: "OUTAVGCALLTIME",
            name: "outAvgCallTime"
        }, {value: "ACDCALL", name: "acdCall"},
        {value: "AVGCALLPERHOUR", name: "avgCallPerHour"}, {
            value: "OCCUPANCYRATE",
            name: "occupancyRate"
        }, {value: "UTILIZATIONRATE", name: "utilizationRate"},
        {value: "WORKTIME", name: "workTime"}, {value: "ACDTIME", name: "acdTime"}, {
            value: "ACDTIMERATE",
            name: "talkTimeRate"
        },
        {value: "AVGACDTIME", name: "avgAcdTime"}, {value: "ACWTIME", name: "acwTime"}, {
            value: "ACWRATE",
            name: "acwRate"
        },
        {value: "AVGACWTIME", name: "avgAcwTime"}, {value: "AVGHANDLETIME", name: "avgHandleTime"}, {
            value: "AUXTIME",
            name: "auxTime"
        },
        {value: "AUXTIMERATE", name: "auxTimeRate"}, {value: "BUSYTIME", name: "busyTime"}, {
            value: "BUSYTIMERATE",
            name: "busyTimeRate"
        },
        {value: "RINGTIME", name: "ringTime"}, {value: "AVAILTIME", name: "availTime"}, {
            value: "AVAILTIMERATE",
            name: "availTimeRate"
        },
        {value: "HOLDTIME", name: "holdTime"}, {value: "AVGHOLDTIME", name: "avgHoldTime"}, {
            value: "TOTALTALKTIME",
            name: "totalTalkTime"
        }
    ],
    agentOutboundSummaryReport: [
        {value: "OUTOCCUPYNUM", name: "outOccupyNum"}, {
            value: "OUTCALLSUCCNUM",
            name: "outCallSuccNum"
        }, {value: "OUTCALLRATE", name: "outCallRate"},
        {value: "OUTABOUNDRING", name: "outAboundRing"}, {
            value: "OUTINVALIDCALL",
            name: "outInvalidCall"
        }, {value: "OUTFAILNUM", name: "outFailNum"},
        {value: "OUTCALLTIME", name: "outCallTime"}, {
            value: "OUTAVGCALLTIME",
            name: "outAvgCallTime"
        }, {value: "OUTWAITANSTIME", name: "outWaitAnsTime"},
        {value: "OUTAVGANSTIME", name: "outAvgAnsTime"}
    ],
    agentConnectionOperationReport: [
        {value: "WORKTIME", name: "workTime"}, {value: "ACWCOUNT", name: "acwCount"}, {
            value: "ACWTIME",
            name: "acwTime"
        },
        {value: "ACWOUTCOUNT", name: "acwOutCount"}, {value: "ACWOUTTIME", name: "acwOutTime"}, {
            value: "ACWINCOUNT",
            name: "acwInCount"
        },
        {value: "ACWINTIME", name: "acwInTime"}, {value: "AUXCOUNT", name: "auxCount"}, {
            value: "AUXTIME",
            name: "auxTime"
        },
        {value: "HOLDCOUNT", name: "holdCount"}, {value: "HOLDTIME", name: "holdTime"}, {
            value: "MUTECOUNT",
            name: "muteCount"
        },
        {value: "MUTETIME", name: "muteTime"}, {value: "ASSISTSCOUNT", name: "assistsCount"}, {
            value: "ASSISTTIME",
            name: "assistTime"
        },
        {value: "BUSYCOUNT", name: "busyCount"}, {value: "BUSYTIME", name: "busyTime"}, {
            value: "PLAYVOICECOUNT",
            name: "playVoiceCount"
        },
        {value: "PLAYVOICETIME", name: "playVoiceTime"}, {value: "OTHERTIME", name: "otherTime"},
        {value: "LOGINCOUNT", name: "loginCount"}, {
            value: "LOGOUTCOUNT",
            name: "logoutCount"
        }, {value: "THREEPARTYCALLCOUNT", name: "threePartyCallCount"},
        {value: "INTERNALTRANSFERCOUNT", name: "internalTransferCount"}, {
            value: "TRANSFEROUTCOUNT",
            name: "transferOutCount"
        }, {value: "TRANSFERIVRCOUNT", name: "transferIvrCount"},
        {value: "INTERNALCALLCOUNT", name: "internalCallCount"}, {
            value: "INTERNALCALLTIME",
            name: "internalCallTime"
        }, {value: "MANTOSKILLNUM", name: "manToSkillNum"},
        {value: "AGENTHANGNUMCTI", name: "agentHangNumCti"}, {
            value: "CUSTHANGNUMCTI",
            name: "custHangNumCti"
        }, {value: "OTHERHANGNUMCTI", name: "otherHangNumCti"},
        {value: "MANTOIVRNUMCTI", name: "manToIvrNumCti"}, {value: "MANTRANSFEROUTNUMCTI", name: "manTransferOutNumCti"}
    ],
    abandonedCallsSummaryReport: [
        {value: "ABANDONEDRING", name: "callAbandonedInRing"}
    ]
};

//构造筛选字段数组
export function buildFilterFieldList(resultList, filterFields) {
    for (var i = 0; i < filterFields.length; i++) {
        var field = filterFields[i];
        if (field.value && field.name) {
            var fieldObj = {
                id: field.value + "_FIELDFILTRATION",
                name: $t('cms.report.field.' + field.name + "Field"),
                value: field.value,
                describtion: $t('cms.report.message.' + field.name + "Desc")
            }
            resultList.push(fieldObj);
        }
    }
}

//增加时间，默认1天
export function addDate(dateStr, days) {
    if (!days) {
        days = 1;
    }
    var str = dateStr.replace(/-/g, '/');
    var nDate = new Date(str);
    nDate.setDate(nDate.getDate() + days);
    return formatDate(nDate);
}

//时间类型转换
export function formatDate(date) {
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    return y + '-' + m + '-' + d;
}

//获取周数
export function getYearWeek(date) {
    if (!date) {
        date = new Date(formatDate(new Date()));
    }
    var firstDay = new Date(date.getFullYear(), 0, 1);
    var date2 = new Date(formatDate(firstDay));
    var day1 = date.getDay();
    if (day1 == 0) {
        day1 = 7;
    }
    var day2 = date2.getDay();
    if (day2 == 0) {
        day2 = 7;
    }
    var diff = Math.round((date.getTime() - date2.getTime() + (day2 - day1) * (24 * 60 * 60 * 1000)) / 86400000);
    var weekNum = Math.ceil(diff / 7) + 1;
    return weekNum;
}

//周数、月数转2位字符串，小于10在前面补零
export function numToStr(number) {
    if (number > 0 && number < 10) {
        return "0" + number.toString();
    } else {
        return number.toString();
    }
}
