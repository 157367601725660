export default {
  "ccm.agent.label.promptParameters": "Ton rapide",
  "ccm.agent.label.queueParameters": "Mise en file d'attente",
  "ccm.agent.label.queueTransferType": "Transféré-au type d'objet",
  "ccm.agent.label.queueTransferObj": "Transféré-au type d'objet",
  "ccm.agent.label.busyRatePrefix": "Lorsque l'utilisation de l'appareil dépasse",
  "ccm.agent.label.busyRateSuffix": "Aucun appel ne peut être transféré à la file d'attente.",
  "ccm.agent.label.busyJudging": "Transfert d'appel en cas d'occupation ou de débordement",
  "ccm.agent.label.deviceUsageThresholdPrefix": "Lorsque l'utilisation de l'appareil est supérieure ou égale à",
  "ccm.agent.label.deviceUsageThresholdSuffix": "Cependant la file d'attente est occupée.",
  "ccm.agent.label.waitTimePrefix": "Lorsque la durée d'attente prévue dépasse",
  "ccm.agent.label.waitTimeSuffix": "Cependant la file d'attente est occupée.",
  "ccm.agent.label.queuingCallsMaxNumPrefix": "Lorsque le nombre d'appels en file d'attente dépasse",
  "ccm.agent.label.queuingCallsMaxNumSuffix": "Débordement de la file d'attente.",
  "ccm.agent.label.scaleFactorPrefix": "Lorsque le nombre d'appels en file d'attente dépasse (",
  "ccm.agent.label.scaleFactorSuffix": "x Nombre d'agents connectés), la file d'attente déborde.",
  "ccm.agent.button.coloumedit":"Configuration du champ",

  "ccm.satisfactionconfig.group.createsuccess":"Le groupe de stratégies est ajouté avec succès",
  "ccm.satisfactionconfig.group.deletesuccess":"Le groupe de stratégies est supprimé avec succès",
  "ccm.satisfactionconfig.strategy.createsuccess":"Réussi à ajouter la politique de satisfaction du canal",
  "ccm.satisfactionconfig.strategy.updateuccess":"Réussir à modifier la politique de satisfaction de canal",
  "ccm.satisfactionconfig.strategy.deletesuccess":"Réussi à supprimer la politique de satisfaction du canal",
  "ccm.satisfactionconfig.strategy.surveytyperequired":"Le type d'enquête ne peut pas être vide",

  "ccm.transfer.number.pop.create.title": "Création d'un numéro transféré vers un numéro externe",
  "ccm.transfer.number.pop.modify.title": "Modifier le numéro transféré vers un numéro externe",
  "ccm.transfer.number.pop.delete.title": "Supprimer",
  "ccm.transfer.number.pop.delete.message": "Êtes-vous sûr de vouloir supprimer le numéro externe sélectionné?",
  "ccm.transfer.number.search.number": "Veuillez saisir le numéro",
  "ccm.transfer.number.search.name": "Veuillez entrer un nom",
  "ccm.transfer.number.label.index": "Index",
  "ccm.agent.message.addSkill": "Ajout d'une file d'attente de compétences",
  "ccm.satisfactiontemplate.message.confirm":"Confirmez s'il faut mettre à jour le contenu du modèle en fonction du niveau de satisfaction.",
  "ccm.satisfactionconfig.msg.templateenpty": "Sélectionnez un modèle de SMS.",
  "ccm.satisfactionconfig.msg.timelimitenpty": "Le temps de réponse valide est nul.",
  "ccm.satisfactiontemplate.message.queryfail":"Impossible d'interroger le modèle d'enquête de satisfaction.",
  "ccm.satisfactiontemplate.tipscontent":"Le modèle de message d'enquête de satisfaction est utilisé pour envoyer des messages d'enquête de satisfaction aux clients sur les canaux Instagram, Telegram, WhatsApp, Line, Web Chat, Face Book, X (Twitter), 5G RCS et SMS.",
  "ccm.satisfaction.changetips":"Remarque : La configuration du niveau de satisfaction est modifiée. Modifier le modèle du sondage de satisfaction en conséquence.",
  "ccm.satisfactionconfig.message.question":"Le modèle de message d'enquête de satisfaction est utilisé pour envoyer des messages d'enquête de satisfaction aux clients sur les canaux Instagram, Telegram, WhatsApp, Line, Web Chat, Face Book, X (Twitter), 5G RCS et SMS.",
  "ccm.satisfactionlevel.satislevel.config":"Configuration du niveau de satisfaction",
  "ccm.satisfactionlevel.satislevel.button":"Boutons",
  "ccm.satisfactionlevel.satislevel.description":"Descriptif",
  "ccm.satisfactionlevel.satislevel.operat":"Opération",
  "ccm.satisfactionlevel.satislevel.moveup":"Vers le haut",
  "ccm.satisfactionlevel.satislevel.movedown":"En bas",
  "ccm.transfer.number.label.number": "Numéro",
  "ccm.transfer.number.label.name": "Nom",
  "ccm.transfer.number.label.flag": "Transfert du numéro d'utilisateur",
  "ccm.transfer.number.label.flag.yes": "Oui",
  "ccm.transfer.number.label.flag.no": "Non",
  "ccm.pageinterface.custTableConfig": "Configuration de base de la table",
  "ccm.transtask.label.friday": "Vendredi",
  "iolp.configur.service.label.none.desc": "Aucune configuration de catégorie de service n'est disponible. Créez d'abord une catégorie de service.",
  "ccm.agent.contact.preview": "Aperçu",
  "ccm.callreason.case.check.error": "Le type d'ordre de travail {0} n'existe pas.",
  "ccm.ccagent.management.additions":"Ajouts",
  "ccm.agent.message.name.grouplimit": "Le nom du groupe de compétences ne peut pas dépasser 64",
  "ccm.skillgroup.message.skillgroup": "Saisissez un nom de groupe de compétences.",
  "ccm.agent.label.waitVoiceSelect": "Veuillez sélectionner une tonalité d'attente",
  "ccm.agent.label.noAgents": "Traitement des appels sur Nobody On-Duty",
  "ccm.custdata.operlog.refresh": "Actualiser",
  "ccm.agent.label.waitTimeRange": "Valeur [0-9999]",
  "ccm.agent.message.weight.limit": "[1-100]",
  "ccm.agent.message.agentweight.limit": "[1-300]",
  "ccm.agent.contact.isExistTalkReason": "Raison d'appel définie ou non",
  "ccm.agent.contact.QUERU_SFTP_FAIL_CODE": "Les informations SFTP sont vides.",
  "ccm.note.config.notename": "Nom et prénom",
  "ccm.certificate.management.update.certfile": "Mettre à jour le fichier de certificat",
  "ccm.certificate.management.add.title": "Ajouter un certificat",
  "ccm.certificate.management.update.title": "Mise à jour des certificats",
  "ccm.transtask.label.reportData": "Rapporter les données",
  "ccm.agent.sysparamconfig.emailMaxRecipientsNoCheck": "Le nombre maximum de destinataires transmis par les agents de courrier doit être compris entre 1 et 50",
  "ccm.urlconfig.label.oauth.callback.alerttip": "Si une URL de rappel est transférée lorsque l'API de création d'appel bidirectionnel est appelée et que les informations d'authentification suivantes sont configurées, ces informations sont utilisées pendant le rappel de l'URL lors de la libération d'appel bidirectionnel. Dans le cas contraire, aucune authentification n'est effectuée pendant le callback.",
  "ccm.certificate.management.certificate.crlfile.certhasexpired": "Le certificat a expiré.",
  "ccm.ivr.placeholder.ivrdesc": "Veuillez entrer la description du processus.",
  "ccm.calledconfig.message.nonumbers": "Veuillez choisir les numéros",
  "ccm.callout.lable.default": "Valeur par défaut",
  "ccm.verifyidentity.fail.USERIDPARSEERROR": "Le numéro de client ne peut pas contenir de caractères spéciaux.",
  "ccm.pageinterface.saveSuccess": "L'ensemble d'interface de page est enregistré avec succès.",
  "ccm.speciallist.msg.selectonly": "Une seule liste spéciale peut être sélectionnée pour modification",
  "ccm.custdata.button.exportTemplate": "Exporter le modèle",
  "ccm.chatbot.query.list.fail": "Impossible d'obtenir la liste des robots.",
  "ccm.ivr.button.traceInfo": "Cliquez sur pour afficher le journal de trace actuel.",
  "ccm.verifyidentity.verifyName": "Mode d'authentification",
  "ccm.verifyidentity.callId": "Appel SN",
  "ccm.contactitemconfig.label.data.item.identification": "ID de l'élément de données",
  "ccm.chatbot.query.name.fail": "Impossible d'obtenir le nom du robot.",
  "ccm.agent.message.pageLayoutFail": "La mise en page ou le nombre de lignes n'est pas sélectionné.",
  "ccm.speciallist.msg.suffix": "Le suffixe du nom du fichier à importer est incorrect.",
  "ccm.agent.leavemessage.downloadLeaveMessage": "Télécharger le message",
  "ccm.custdata.operlog.exportEndTime": "Exporter l'heure de fin",
  "ccm.agent.restrecord.rest.currentstatus": "Statut actuel",
  "ccm.agent.ccagentinfo.unregister.success": "Annuler l'inscription Succès",
  "ccm.speciallist.msg.tip": "Un maximum de 1000 enregistrements peuvent être importés à chaque fois",
  "ccm.agent.message.createAgentAndSkillFail": "Impossible de créer l'association de la file d'attente des compétences de l'agent.",
  "ccm.recognition.label.openBreakDown": "Voix de rupture",
  "ccm.contact.detail.receiver": "Destinataire",
  "ccm.agent.button.close": "Fermer",
  "ccm.certificate.management.type": "Type de certificat",
  "ccm.speciallist.msg.confirmdeletehis": "Êtes-vous sûr de vouloir supprimer l'historique des listes spéciales sélectionnées?",
  "ccm.sysparam.success.save": "Succès",
  "ccm.satisfactionconfig.message.accepttime": "Temps de traitement",
  "ccm.agent.label.callDispatchModel.incomingCall": "Attribution des compétences des appels entrants avant l'attribution des compétences des appels sortants",
  "ccm.custdata.label.status": "Statut",
  "ccm.recognition.label.breakDownVoice": "Voix de rupture",
  "ccm.calledroute.title.selectskill": "Sélectionner la file d'attente de compétences",
  "ccmanagement.satisfaction.strategy.accepttime.accepttime": "Temps de traitement",
  "ccm.recognition.label.openIvrAgentSuccess": "L'assistant d'agent intelligent est activé avec succès.",
  "ccm.transtask.label.dataname": "Nom du serveur de vidange",
  "ccm.agent.message.longCharacters": "Le nombre peut contenir un maximum de 24 caractères.",
  "ccm.agent.message.longCharacters.form": "Le nombre peut contenir un maximum de 24 caractères",
  "ccm.speciallist.msg.partexport": "Êtes-vous sûr de vouloir exporter les enregistrements sélectionnés ?",
  "ccm.sysparam.config.itemname.tips": "Saisissez un nom de paramètre.",
  "ccm.satisfactionconfig.message.chooseivrflow": "Choisissez le flux IVR",
  "ccm.accesscode.label.accesscodedesc": "Description du code d'accès",
  "ccm.accesscode.title.accesscodedesc": "Modifier la description du code d'accès",
  "ccm.custdata.label.endTime": "Heure de fin",
  "ccm.agent.calledroute.success": "Succès",
  "ccm.botherlist.label.approveComment.toApprove": "D'accord",
  "ccm.agent.contact.assistantCallid": "ID d'appel collaboratif",
  "ccm.contact.customer.config.referenced": "L'élément de données a été référencé par la zone d'information client de contact.",
  "ccm.contact.record.data.referenced": "L'élément de données a été référencé par l'élément de données d'enregistrement de contact.",
  "ccm.agent.label.description": "Descriptif",
  "ccm.agent.label.endworkno": "ID de l'employé final",
  "ccm.contactitemconfig.label.data.extended.dataset.source.interface": "Interface de source de données",
  "ccm.transtask.label.day": "Jour",
  "ccm.agent.message.updateCtiAndDbFail": "La mise à jour cti et Db a échoué.",
  "ccm.transserver.label.obsnotinwhite": "L'opération a échoué. Contactez l'administrateur système pour ajouter l'adresse du service OBS à la liste d'approbation du serveur OBS de vidange de ressources.",
  "ccm.agent.recall.param.callid.empty": "Aucun numéro de rappel n'est disponible.",
  "ccm.label.contactexport.customfield": "Champ personnalisé",
  "ccm.agent.calledroute.placeholder.accesscode": "Veuillez sélectionner un code d'accès.",
  "ccm.systemparam.success.refresh": "Paramètre mis à jour avec succès.",
  "ccm.speciallist.msg.errorreason": "Cause d'échec",
  "ccm.contactitemconfig.label.data.item.sequence.number": "Non.",
  "ccm.agent.pageurls.accessaddr": "Adresse d'accès",
  "ccm.custdata.operlog.confirmMessage": "Êtes-vous sûr de créer une nouvelle tâche d'exportation ?",
  "ccm.recognition.label.orderfailpd": "L'abonnement a échoué. Réessayez plus tard.",
  "ccm.verifyidentity.NOTRETURN": "Non retourné",
  "ccm.urlconfig.label.xNumber": "Numéro X",
  "ccm.satisfactionconfig.message.cancel": "Annuler",
  "ccm.agent.calledroute.editsuccess": "Modifié avec succès.",
  "ccm.speciallist.msg.transfererror": "Impossible de transférer vers l'historique",
  "ccm.agent.leavemessage.status.unprocessed": "Non-traité",
  "ccm.satisfactionconfig.message.comfirm": "Êtes-vous sûr de vouloir supprimer le groupe de stratégies ?",
  "ccm.agent.skill.skillExist": "existe déjà. Saisissez un autre nom.",
  "ccm.agent.contact.audio-visual": "Audio/Vidéo",
  "ccm.transtask.label.taskfail": "Impossible de créer la tâche parce qu'un maximum de {0} tâches du type d'enregistrement de contact sont autorisées.",
  "ccm.ivr.label.processid": "ID de processus",
  "ccm.agent.label.parameter": "Paramètre 1",
  "ccm.agent.title.agentEdit": "Configuration des informations de l'agent",
  "ccm.speciallist.msg.modifyerror": "Échec de la modification",
  "ccm.label.contactexport.selectdatafield": "Champ d'exportation",
  "ccm.agent.contact.preCallout": "Aperçu de l'appel sortant",
  "ccm.agent.pageurls.pagetype": "Type de page",
  "ccm.agent.calledroute.create.accesscode.none": "Le code d'accès au système configuré pour la partie appelée n'est pas attribué.",
  "ccm.transtask.label.vdnReport": "Rapport de trafic VDN",
  "ccm.agent.tips.inputpassword": "Entrez le mot de passe du compte de connexion actuel.",
  "ccm.basicdata.msg.nodata": "Pas de données",
  "ccm.contact.customer.config.writeBackFieldError": "Le champ étendu de réécriture d'enregistrement de contact ne peut pas être utilisé.",
  "ccm.contact.customer.config.dataItemCodeError": "L'ID de l'élément de données est incorrect.",
  "ccm.agent.restReason.restCauseTime.formate": "La durée de repos doit être au format HH:MM:SS.",
  "ccm.agent.Transfer.theAgent": "Agent",
  "ccm.transtask.label.updateexsit": "L'opération a échoué car le nom du serveur existe déjà.",
  "ccm.speciallist.msg.endtimeerror": "Le délai d'expiration doit être supérieur à l'heure actuelle",
  "ccm.custdata.button.moveDown": "En bas",
  "ccm.custdata.option.isMaskDisplay.yes": "Oui",
  "ccm.contactitemconfig.label.data.extended.dictionary.value": "Valeur du dictionnaire de données",
  "ccm.verifyidentity.fail.WORKNOPARSEERROR": "L'ID de l'agent doit être un numéro.",
  "ccm.transtask.label.monday": "Lundi",
  "ccm.satisfactionconfig.message.validatename": "Le nom du groupe de stratégies ne peut pas être vide ou dupliqué.",
  "ccm.agent.ten.videoIvrchannels": "Quantité vidéo de canal d'IVR",
  "ccm.satisfactionconfig.message.calltime": "Durée de l'appel",
  "ccm.calledconfig.message.savemobileagentsuccess": "La configuration de l'agent mobile est enregistrée avec succès",
  "ccm.agent.contact.callin": "Appel entrant",
  "ccm.custdata.msg.updatedItemNotExist": "Le champ à mettre à jour n'existe pas. Actualisez la page et réessayez.",
  "ccm.contactitemconfig.label.data.item.contact.list.visibility.no": "Non",
  "ccm.agent.contact.vidio": "Vidéo",
  "ccm.satisfactionconfig.message.failed": "Échec",
  "ccm.transtask.label.skillAccessCodeReport": "Rapport de trafic de file d'attente de compétences par code d'accès",
  "ccm.agent.contact.callid": "ID d'appel",
  "ccm.agent.contact.contactdetail": "Détails du contact",
  "ccm.custdata.operlog.exportTaskStatus": "Statut",
  "ccm.verifyidentity.ERROR": "Erreur",
  "ccm.agent.label.positiveEmotionNumber": "Positif",
  "ccm.speciallist.msg.view": "Voir",
  "ccm.satisfactionconfig.message.smscloud": "HUAWEI CLOUD SMS",
  "ccm.agent.label.waitVoiceCustom": "Personnalisation du ton d'attente",
  "ccm.agent.button.edit": "Modifier",
  "ccm.agent.accesscode.description.limit": "La description du code d'accès ne peut pas contenir plus de 50 caractères.",
  "ccm.agent.calledroute.isconfdelete": "Êtes-vous sûr de vouloir supprimer l'appelé sélectionné ?",
  "ccm.custdata.operlog.exportBeginTime": "Exporter l'heure de début",
  "ccm.custdata.option.maskStyle.retainLast4": "Réserver les quatre derniers chiffres",
  "ccm.urlconfig.label.input.appKey": "AppKey",
  "ccm.agent.pageurls.updateinfo": "Mis à jour avec succès.",
  "ccm.agent.moveup.error": "Le déplacement vers le haut a échoué.",
  "ccm.agent.moveup.success": "Déplacer vers le haut avec succès.",
  "ccm.agent.movedown.error": "Le déplacement vers le bas a échoué.",
  "ccm.agent.movedown.success": "Déplacer vers le bas avec succès.",
  "ccm.custdata.label.tableName": "Nom de la table",
  "ccm.custdata.title.edit": "Modifier les données",
  "ccm.custdata.title.coloum.add": "Création d'une colonne de champ",
  "ccm.custdata.title.coloum.edit": "Modifier la colonne de champ",
  "ccm.custdata.title.coloum.detail": "Affichage des détails du champ",
  "ccm.satisfactionconfig.message.numberlimit": "Un maximum de 100 configurations de canaux d'enquête de satisfaction sont autorisées dans un espace locataire.",
  "ccm.contactitemconfig.label.data.extended.Type": "Type de données",
  "ccm.speciallist.button.modify": "Modifier",
  "ccm.levelMessage.title.modify": "Modifier la gestion des niveaux",
  "ccm.speciallist.title.modify": "Modifier la liste spéciale",
  "ccm.speciallistreminder.button.modify": "Modifier le rappel de la liste spéciale",
  "ccm.pageinterface.interfaceType.externalExplain": "Les interfaces externes sont configurées sur la page Gestion de l'API. Contactez l'administrateur système.",
  "ccm.agent.contact.multimedia.web": "Multimédia-Web",
  "ccm.pageurls.select.embedding": "Intégrer",
  "ccm.speciallist.msg.reviewerror": "Échec de l'approbation",
  "ccm.agent.contact.talkcontent": "Contenu conversationnel",
  "ccm.agent.callreason.modify.failed": "La modification de la raison de l'appel a échoué.",
  "ccm.agent.callreason.delete.confirm": "Êtes-vous sûr de vouloir supprimer la raison de l'appel?",
  "ccm.satisfactiontemplate.message.resetfail": "Impossible de réinitialiser le contenu du modèle d'enquête de satisfaction.",
  "ccm.speciallist.msg.sltype.illegal": "Type de liste spéciale non valide.",
  "ccm.agent.contact.play": "Jouer",
  "ccm.custdata.msg.selectItem": "Sélectionnez un élément avant de trier.",
  "ccm.speciallevel.msg.deleteyerror": "Echec de la suppression du niveau de la liste spéciale.",
  "ccm.speciallist.button.finish": "Finition",
  "ccm.agent.message.allExport": "Tout Exporter",
  "ccm.agent.updateSoftPhone.modifyLocalPwd": "Impossible de modifier le mot de passe du softphone local.",
  "ccm.custdata.msg.dictionaryValidateFailed": "Format incorrect de la plage de valeurs du dictionnaire de données",
  "ccm.contactitemconfig.label.data.item.basic": "Données de base",
  "ccm.contract.management.invalid.confirm": "Êtes-vous sûr de vouloir invalider ce document ?",
  "ccm.create.case.confirm": "Le type de cas est vide. Lors de la création de cas, le type de cas doit être sélectionné manuellement. Êtes-vous sûr de vouloir continuer ?",
  "ccm.agent.message.deleteSkillAndAgentFail": "Échec de la suppression de la compétence et de l'agent.",
  "ccm.agent.label.audio": "Agent vocal",
  "ccm.agent.label.on": "Allumer",
  "ccm.agent.login.ID": "Séquence d'opération",
  "ccm.satisfactionconfig.web.satisfaction.survey": "Sondage sur la satisfaction des canaux Web",
  "ccm.agent.calledroute.require": "Entrez tous les champs obligatoires.",
  "casemanagement.casedraft.label.selectcasetype": "Sélectionner le type de cas",
  "ccm.agent.label.afterVoiceSelect": "Voix après l'identification de l'employé",
  "ccm.agent.message.delete": "Supprimer",
  "ccm.agent.message.pageInterface": "Interface de page",
  "ccm.recognition.resetSK.failed": "Impossible de réinitialiser le SK.",
  "ccm.agent.ten.anonymousSipInfo": "Adresse IP et numéro de port du serveur d'appel anonyme",
  "ccm.agent.contact.endtime": "Heure de fin",
  "ccm.custdata.msg.duplicateTableName": "Dupliquer le nom de la table. Renommez-le.",
  "ccm.calledconfig.message.callreasontips": "Remarque : Le TT associé au niveau actuel n'est pas disponible. Sélectionnez une raison d'appel de sous-ensemble pour définir le TT associé.",
  "ccm.agent.tips.confFail": "Échec de la configuration",
  "ccm.agent.pageurls.configpage": "Page de configuration",
  "ccm.agent.contact.called": "Numéro appelé",
  "ccm.speciallist.operlog.downloadData": "Exportation de données de liste spéciale",
  "ccm.satisfactionconfig.message.webchannel": "Chaîne multimédia Web",
  "ccm.systemparam.error.noparamselected": "Veuillez d'abord sélectionner un paramètre.",
  "ccm.agent.leavemessage.auditdate": "Temps de mise à jour",
  "ccm.agent.message.videoagentzero": "Le nombre d'agents vidéo est 0. Impossible de configurer la file d'attente de compétences de clic pour numéroter la vidéo.",
  "ccmanagement.satisfaction.strategy.accepttime.timeEnd": "Heure de fin",
  "ccm.calledconfig.table.devtype": "Type de dispositif de but",
  "ccm.agent.label.deviceUsageThresholdRange": "Valeur [0-100]",
  "ccm.agent.message.cancelconfig": "Annuler la configuration",
  "ccm.agent.contact.preemptionCallout": "Appel sortant préempté",
  "ccm.urlconfig.message.urlnotinwhite": "L'opération a échoué. Contactez l'administrateur système pour ajouter l'URL à la liste de confiance.",
  "ccm.agent.title.querysippwd": "Vérifier le mot de passe du téléphone logiciel",
  "ccm.policy.label.updateVerificationFailed": "La vérification des paramètres de la demande échoue.",
  "ccm.agent.label.scaleFactorRangeTwo": "Valeur [1-10000]",
  "ccm.custdata.operlog.zippwd": "Mot de passe compressé",
  "ccm.dual.call.record.talkingTime": "Heure de réponse appelée",
  "ccm.note.config.agent.ring.setUp": "Configuration de l'information d'invite",
  "ccm.dual.call.record.dualCallType": "Double type d'appel",
  "ccm.agent.leavemessage.processsuccess": "Le message est traité avec succès.",
  "ccm.agent.contact.caller": "Numéro de l'appelant",
  "ccm.agent.calledroute.delete.skill.default": "La configuration de la partie appelée est référencée par la file d'attente privée de compétences par défaut et ne peut pas être supprimée.",
  "ccm.agent.label.transferType": "Méthode de processus",
  "ccm.agent.restrecord.reststatus": "État de repos",
  "ccm.contact.detail.view.message.content": "Voir",
  "ccmanagement.tenantspace.option.weeklist_firtolast": "Dernier",
  "ccm.agent.message.updateagentandskillfail": "Impossible de mettre à jour l'association entre l'agent et la file d'attente de compétences.",
  "ccm.agent.pageurls.delfail": "La suppression a échoué.",
  "ccm.agent.resetskillrecord.adjusttime": "Ajuster le temps",
  "ccm.contact.customer.config.value": "Valeur des données",
  "ccm.satisfactionconfig.message.comfirmtitle": "Confirmer",
  "ccm.agent.operation.CONFERENCE": "Conférence tripartite",
  "ccm.calledroute.button.cancel": "Annuler",
  "ccm.urlconfig.label.input.domainName": "Nom de domaine ITA",
  "ccm.agent.label.transfer": "Transfert",
  "ccm.taskrecord.message.reexecutetranstaskerror": "La ré-exécution a échoué.",
  "ccm.agent.message.allExportAgent": "Êtes-vous sûr de vouloir exporter toutes les informations de l'agent ?",
  "ccm.custdata.operlog.inputzippwd": "Entrez le mot de passe de compression",
  "ccm.satisfactionlevel.message.samedesc": "La description en double existe. Veuillez le modifier.",
  "ccm.ucconfig.message.clientSecret": "Mot de passe de l'application (client)",
  "ccm.agent.operation.countid": "Numéro de compte",
  "ccm.note.config.agent.page.ring.select": "Sélectionnez la sonnerie",
  "ccm.agent.restReason.updateFailed": "Échec de la modification.",
  "ccm.agent.label.qualityChecker": "Contrôleur de qualité",
  "ccm.satisfactionconfig.message.switchtip": "Une fois la fonction activée, le système affiche automatiquement la page d'enquête de satisfaction après la fin du service de canal Web. Une fois les autres services de canal multimédia terminés, le système pousse automatiquement le contenu du modèle de satisfaction.",
  "ccm.agent.contact.playRecording": "lecture d'un enregistrement",
  "ccm.agent.label.callDispatchModel.manySkills": "Agent avec la plupart des compétences",
  "ccm.custdata.msg.sortNoChange": "Le tri ne change pas.",
  "ccm.agent.contact.downloadFail": "Le téléchargement a échoué.",
  "ccm.policy.label.updateFailed": "Impossible de mettre à jour les informations de stratégie.",
  "casemanagement.user.button.ok": "OK",
  "ccm.agent.button.search": "Requête",
  "ccm.agent.label.serviceAccountName": "Nom du compte Service Cloud",
  "ccm.contactitemconfig.label.contact.extended.information.dataset": "Ensemble de données d'informations de contact étendues",
  "ccm.agent.export.agentinfo.tips": "Les données exportées contiennent des données personnelles, qui doivent être correctement utilisées et protégées.",
  "ccm.speciallist.msg.userNum": "Numéro d'abonné",
  "ccm.transserver.delete.css.success": "Configuration CSS supprimée avec succès.",
  "ccm.agent.contact.calledfailed": "Échec de l'appel sortant",
  "ccm.agent.updateSoftPhone.updateOpertionSuccess": "Avertissement",
  "ccm.idauth.title.edit": "Modifier le processus d'authentification de l'identité",
  "ccmanagement.satisfaction.strategy.fail.calltime.inputnumber": "La valeur doit être un entier positif.",
  "ccm.urlconfig.message.deletesuccess": "Réinitialisation des urls réussie",
  "ccm.ivr.label.processaccesscode": "Code de flux",
  "ccm.agent.pageurlparam.channelassociateddata": "Données associées au canal",
  "ccm.agent.operation.AllOpType": "Tous les types d'opérations",
  "ccm.agent.Transfer.tranfermode": "Mode de transfert",
  "ccm.calledconfig.table.selectnumbers": "Sélectionner des numéros",
  "ccm.agent.protocol.check": "Notez que le protocole réseau utilisé par l'adresse url n'est pas un protocole sécurisé. Si vous décidez d'utiliser ce protocole, des risques de sécurité peuvent exister.",
  "ccm.common.label.success": "Succès",
  "ccm.certificate.management.certificatefile.notupload": "S'il vous plaît télécharger le fichier de certificat.",
  "ccm.speciallist.msg.selectspeciallist": "Veuillez sélectionner une liste spéciale",
  "ccm.speciallist.msg.selectspeciallisthis": "Veuillez sélectionner l'historique d'une liste spéciale",
  "ccm.agent.ten.companyName": "Entreprise",
  "ccm.agent.label.calcOfMaxCallNum": "Méthode maximale de calcul des données de mise en file d'attente d'appel",
  "ccm.speciallevel.speciallist.level": "Niveaux",
  "ccm.agent.label.serverAddress": "Adresse du serveur d'enregistrement",
  "ccm.verifyidentity.fail.CALLIDPARSEERROR": "Le SN d'appel doit être un numéro.",
  "ccm.agent.pageurls.openMode": "Mode Ouvrir",
  "ccm.agent.operation.noMoreThan1Days": "L'intervalle de temps ne peut pas dépasser 1 jour.",
  "ccm.note.config.agent.page.ring.warn": "Il est conseillé d'effectuer un test d'appel pour éviter que la sonnerie ne se chevauche avec la sonnerie configurée sur le téléphone.",
  "ccm.pageinterface.selectedAll": "Sélectionner tout",
  "ccm.calledconfig.message.deletemobileagentsuccess": "La configuration de l'agent mobile est supprimée avec succès",
  "ccm.calledconfig.label.selectcalled": "Sélectionnez Appelé",
  "ccmanagement.satisfaction.strategy.addsuccess": "Sauvegarde réussie.",
  "ccm.transtask.label.vdnAccessCodeReport": "Rapport de trafic VDN par code d'accès",
  "ccm.calledroute.title.selectaccesscode": "Sélectionnez le code d'accès",
  "ccm.calledroute.tip.selectaccesscode": "Veuillez d'abord sélectionner un code d'accès.",
  "ccm.botherlist.label.contactrecorddetail": "Détails de contact",
  "ccm.transserver.delete.fail": "Impossible de supprimer le serveur de vidange.",
  "ccm.message.center.nocitiction.sending.records.status": "statut d'envoi",
  "ccm.agent.label.startworkno": "Commencer l'ID de l'employé",
  "ccm.agent.updateSoftPhone.queryNodeFailed": "Impossible d'interroger les informations du nœud locataire.",
  "ccm.agent.message.pageConfSuccess": "Opération réussie.",
  "ccm.dual.call.record.caller": "Numéro de l'appelant",
  "ccm.agent.password.wrong": "Impossible de vérifier le mot de passe.",
  "ccm.satisfactionconfig.message.validtime": "Période de réponse valide (min)",
  "ccm.agent.label.negativeEmotionNumber": "Négatif",
  "ccm.custdata.option.isMandatory.no": "Non",
  "ccm.speciallist.button.add": "Ajouter",
  "ccm.levelMessage.title.add": "Créer une gestion de niveau",
  "ccm.speciallist.title.add": "Créer une liste spéciale",
  "ccm.speciallistreminder.button.add": "Créer un rappel de liste spéciale",
  "ccm.certificate.management.updatetime": "Temps de mise à jour",
  "ccm.agent.calledroute.ivr": "Veuillez choisir IVR",
  "title.contact.agent.name": "Nom du compte de service",
  "ccm.agent.title.IVRTrace": "Trace IVR",
  "ccm.agent.videoDownload.RSETimeout": "Demande de service RSE expiré",
  "ccm.agent.label.cti": "cti:",
  "ccm.custdata.option.columnType.string": "Chaîne",
  "ccm.speciallist.msg.crateerrorreason": "Le numéro d'abonné existe déjà",
  "ccm.agent.contact.multimedia.instagram": "Multimédia-Instagram",
  "ccm.agent.message.agentConfSuccess": "Les informations de l'agent sont configurées avec succès.",
  "ccm.speciallevel.tip.stop": "Voulez-vous vraiment désactiver ?",
  "ccm.agent.label.skillChoice": "Sélectionner la compétence",
  "ccm.agent.message.agentnumreachmax": "Nombre de type d'agent cible déjà atteint maximum",
  "ccm.agent.operation.ANSWER": "Réponse",
  "ccm.speciallist.msg.overlimit": "La longueur du message de rappel dépasse 256 caractères.",
  "ccm.transtask.label.abandonedRingReport": "Rapport sommaire sur les appels abandonnés pendant la sonnerie",
  "ccm.contact.detail.message.sending.record": "Enregistrement d'envoi de message",
  "ccm.chatbot.invalid.empty": "L'assistant robot est obligatoire.",
  "ccm.idauth.title.code": "Code de mode d'authentification",
  "ccm.custdata.msg.reachUpperTableNum": "Le nombre de tables personnalisées a atteint la limite supérieure.",
  "ccm.agent.page.cancel.release": "Inédit",
  "ccm.recognition.label.cancelorderfail": "Échec de la désinscription.",
  "ccm.agent.calledroute.extcode": "Code d'extension",
  "ccm.callback.url.auth.tip": "Mode d'authentification de l'URL de rappel",
  "ccm.agent.pageurls.maxcallingnum": "Assurez-vous que le nombre d'écrans pop-up ne dépasse pas 5.",
  "ccm.sysparam.error.validate.notmatchruleoption": "Le paramètre d'entrée n'est pas dans la plage d'énumération",
  "ccm.agent.calledroute.device": "Sélectionnez un appareil.",
  "ccm.agent.operation.WHISPER": "Whisper",
  "ccm.agent.contact.predictiveCall": "Appel sortant prévu",
  "ccm.taskrecord.label.reexecute": "Ré-exécuter",
  "ccm.agent.contact.others-channels": "Autres canaux",
  "ccm.agent.contact.skillhungup": "Transfert vers Skill Queue",
  "ccm.callout.lable.caller": "Callout Caller",
  "ccm.agent.sysparamconfig.positiveinteger": "Veuillez entrer un entier positif!",
  "ccm.certificate.management.scenename": "Description du certificat",
  "ccm.taskrecord.label.choosetask": "Sélectionner une tâche",
  "ccm.agent.restrecord.reststatus.Abnormal.endrest": "Le repos s'est terminé anormalement",
  "ccm.speciallist.msg.restores": "Restaurations",
  "ccm.satisfactionconfig.message.timelimittip": "Si le temps de réponse du client dépasse le temps de réponse valide, la réponse n'est plus valide.",
  "ccm.agent.calledroute.devicedesc": "Nom de l'appareil",
  "ccm.transtask.label.isOpenCssServer": "Spécifie s'il faut activer le CSS.",
  "ccm.agent.recall.fail": "Impossible de rappeler le client.",
  "ccm.agent.mobileagent.label.deletehint": "Êtes-vous sûr de supprimer?",
  "ccm.agent.label.isaireconition": "Vrai",
  "ccm.calledconfig.message.nullparent": "Le nœud parent n'existe pas.",
  "ccm.speciallist.msg.applyTimeTo": "Heure de fin d'application",
  "ccm.ucconfig.message.updatesuccess": "Configuration de l'intégration UC enregistrée avec succès.",
  "ccm.agent.restReason.isusing.warning": "Les codes de raison de repos sont en vigueur. Cela peut entraîner l'affichage incorrect de la raison de repos dans l'enregistrement de repos.",
  "ccm.agent.contact.callerPresent": "Numéro de l'utilisateur",
  "ccm.custdata.label.maskStyle": "Style de masque",
  "ccm.agent.button.restSoftPhonePwdError": "Liez l'agent.",
  "ccm.speciallist.msg.transferToHistory": "Transfert vers l'historique",
  "ccm.dual.call.record.normal.end": "Fin normale",
  "ccm.note.config.agent.page.ring.media": "Notification d'appel entrant multimédia",
  "ccmanagement.tenantspace.option.month_9": "Septembre",
  "ccm.speciallist.msg.deleteerror": "Impossible de supprimer le rappel de la liste spéciale.",
  "ccm.agent.restReason.restCauseDesc.limit": "La description du reste peut contenir un maximum de 100 caractères.",
  "ccm.agent.restReason.restCauseDesc.limit.form": "La description du reste peut contenir un maximum de 100 caractères",
  "ccmanagement.tenantspace.option.month_7": "Juillet",
  "ccm.agent.pageurls.successinfo": "Créé avec succès.",
  "ccm.agent.pageurls.successinfo.noPoint": "Créé avec succès",
  "ccmanagement.tenantspace.option.month_8": "Août",
  "ccmanagement.tenantspace.option.month_5": "mai",
  "ccmanagement.tenantspace.option.month_6": "Juin",
  "ccmanagement.tenantspace.option.month_3": "Mars",
  "ccmanagement.tenantspace.option.month_4": "Avril",
  "ccmanagement.tenantspace.option.month_1": "Janvier",
  "ccm.agent.ten.versatileagents": "Agents polyvalents",
  "ccmanagement.tenantspace.option.month_2": "Février",
  "ccm.satisfactionconfig.message.calltypein": "Appel entrant",
  "ccm.contact.customer.config.type": "Contacter les données de la zone d'information client",
  "ccm.transtask.label.voiceFile": "Fichier vocal",
  "ccm.anonymous.message.fail.paranull": "Le code d'accès dans le paramètre est vide",
  "ccm.certificate.management.certificatefile.certcodeerror": "Le code de cert est une erreur.",
  "ccm.agent.label.maxWaitNum": "Max. Appels en file d'attente",
  "ccm.satisfactionconfig.message.enterflowname": "Veuillez entrer le nom du processus",
  "ccm.systemparam.error.refresh": "Impossible d'actualiser les paramètres.",
  "ccm.note.config.agent.page.ring.newMedia": "Nouvelle notification de message multimédia",
  "ccm.agent.message.pageConfFail": "L'opération a échoué",
  "ccm.transtask.label.certTip.proxyCert": "Veuillez sélectionner un serveur proxy Cert.",
  "ccm.agent.pageurls.integrationType": "Type d'intégration",
  "ccm.urlconfig.message.overlimit.appSecret": "La clé secrète configurée pour les appels bidirectionnels ITA ne peut pas dépasser 512 caractères.",
  "ccm.agent.message.sysParamCofig.openVersatileAgentFail": "Modifiez le type d'agent sur la page Gestion des agents. Le nombre d'agents polyvalents et d'agents vidéo ne peut pas être supérieur au nombre maximum d'agents vidéo auxquels un locataire peut s'abonner.",
  "ccm.recognition.label.updatesucess": "Mettre à jour locataire fautl succès.",
  "ccm.agent.calledroute.delfail": "Impossible de supprimer les données locales.",
  "ccm.custdata.button.importRecord": "Importer la vue des résultats",
  "ccm.custdata.msg.confirmWithdraw": "Êtes-vous sûr de vouloir le retirer ?",
  "ccm.satisfactionconfig.message.operate": "Opération",
  "ccm.agent.message.validatormsg": "Les entrées liées au mot de passe doivent être vides ou pas vides du tout",
  "ccm.agent.message.skillConfFail": "Impossible de configurer les informations de la file d'attente de compétences.",
  "ccm.recognition.label.sucess": "Succès",
  "ccm.agent.calledroute.exception": "Vérifiez le statut Redis ou la configuration CC-Gateway.",
  "ccm.satisfactionconfig.message.strategygroupmember": "Politique",
  "ccm.speciallisthis.operlog.downloadData": "Exporter l'historique de la liste spéciale",
  "ccm.agent.message.skillHasbeenUsedByAgent": "La file d'attente de compétences a été occupée par un agent. Supprimez d'abord l'agent.",
  "ccm.agent.leavemessage.notLoginIn": "L'agent n'a pas signé.",
  "ccm.custdata.label.columnType": "Type de champ",
  "ccm.agent.label.workNo": "ID d'employé",
  "ccm.satisfactionconfig.message.strategy": "Politique de sondage de satisfaction",
  "ccm.urlconfig.pageurls.urlvalidate": "Les urls doivent commencer par http:// ou https://",
  "ccm.callout.lable.number": "Valeur de paramètre",
  "ccm.agent.sysparamconfig.hasSelectAgentWorkRepeat": "Il y a duplication dans la section sélectionnée!",
  "ccm.contact.msg.dataEmpty": "Les données d'opération n'existent pas dans l'AICC.",
  "ccm.contact.msg.dataverification": "Impossible de vérifier le paramètre de la requête.",
  "ccm.speciallist.msg.enable.fail": "Échec de l'activation",
  "ccm.agent.message.updateCtiAndDbSuccess": "Synchronisation réussie.",
  "ccmanagement.satisfaction.strategy.fail.accepttime.inputnumber": "L'heure de début ou de fin doit être un nombre.",
  "ccm.agent.message.batchExport": "Exportation par lots",
  "ccm.agent.contact.clicktodial": "Cliquez pour composer",
  "ccm.custdata.label.dataImportRecord": "Enregistrement d'importation de données",
  "ccm.custdata.option.maskStyle.maskFirst4": "Masque les quatre premiers chiffres",
  "ccm.label.contactexport.exceedlimit2": "Affinez la plage de temps des données ou ajoutez des critères de filtre de données.",
  "ccm.transtask.label.exportdata": "Exporter le champ de données :",
  "ccm.transserver.label.edit": "Serveur de vidange des ressources",
  "ccm.agent.accesscode.index": "Non.",
  "ccm.certificate.message.tip.warning": "L'algorithme de signature et la longueur de clé du certificat ne répondent pas aux exigences, ce qui est risqué. Êtes-vous sûr de vouloir continuer ?",
  "ccm.satisfactionconfig.message.save": "Enregistrer",
  "ccm.certificate.management.certcode": "Nom du certificat",
  "ccm.agent.message.ctipasswdsimple": "Le mot de passe ne répond pas aux exigences de complexité de la plateforme. Contactez le personnel de l'O M pour vérifier le mot de passe.",
  "ccm.agent.calledroute.fail": "Échec",
  "ccm.agent.message.agentNotFoundFail": "Impossible de trouver les informations de l'agent.",
  "ccm.custdata.label.isEncrypt": "Chiffré",
  "ccm.agent.message.existDualchannelrecAgent": "La reconnaissance intelligente et l'enregistrement bipiste ne peuvent pas être activés en même temps, tandis que l'enregistrement bipiste a été activé pour certains des agents à modifier par lots.",
  "ccm.satisfactionconfig.message.grouppriority": "Priorité",
  "ccm.transtask.label.contactchoose": "Champs personnalisés dans l'enregistrement de contact",
  "ccm.agent.label.sipPwd": "Mot de passe du téléphone logiciel",
  "ccm.recognition.label.modifymobileagentsuccess": "Modifié avec succès le statut de l'agent mobile!",
  "ccm.agent.operation.RECORDPLAY": "Lire l'enregistrement",
  "ccm.label.contactexport.curpage": "Page courante",
  "ccm.transserver.label.servertype": "Type",
  "ccm.label.contactexport.exceedlimit1": "Un maximum de {0} enregistrements de données peuvent être exportés",
  "ccm.agent.contact.pictures": "photos",
  "ccm.agent.operation.CANCELINSERT": "Annuler l'insertion",
  "ccm.agent.label.waitSkillWeight": "Entrez un poids de compétence.",
  "ccm.satisfactionconfig.audio.and.video": "Sondage sur la satisfaction des canaux vocaux ou vidéo",
  "ccm.agent.page.release": "libération",
  "ccm.agent.pageurls.isconfreset": "Êtes-vous sûr de vouloir le réinitialiser ?",
  "ccm.agent.page.pageParameter": "Paramètres de page",
  "ccm.agent.operation.optype": "Type d'opération",
  "ccm.agent.label.skillWeight": "Compétence de l'agent Poids",
  "ccm.satisfactionlevel.title.templateupdate": "Mise à jour par modèle",
  "ccm.case.label.recommendedWorkOrder0": "Recommandé TT Catégorie 1",
  "ccm.botherlist.label.approveStatus.approved": "Approuvé",
  "ccm.agent.Transfer.devicetype": "Type de périphérique de destination",
  "ccm.agent.leavemessage.QUERU_LEAVEMESSAGE_FAIL_CODE": "Vider les informations SFTP pour interroger les messages.",
  "ccm.agent.pageurls.confreset": "Confirmer la réinitialisation",
  "ccm.agent.operation.noMoreThan3Days": "L'intervalle de temps ne peut pas dépasser 3 jours.",
  "ccm.case.label.recommendedWorkOrder2": "Recommandé TT Catégorie 3",
  "ccmanagement.satisfaction.strategy.calltime.second": "S",
  "ccm.case.label.recommendedWorkOrder1": "Recommandé TT Catégorie 2",
  "ccm.agent.resetskillrecord.adjustresult": "Ajuster le résultat",
  "ccm.agent.sysparamconfig.outboundtimecheck": "La limite de temps pour l'aperçu automatique des appels sortants doit aller de 1s à 3600s.",
  "ccm.speciallist.msg.restoreout": "Un espace locataire peut stocker un maximum de {0} enregistrements de liste spéciale",
  "ccm.custdata.option.importDoing": "Traitement",
  "ccm.agent.contact.CONNECT_FAIL_CODE": "Impossible de se connecter au serveur SFTP.",
  "ccm.basicdata.msg.modifysuccess": "Les données de base sont modifiées avec succès.",
  "ccm.sysparam.error.validate.notmatchruleminlength": "Le paramètre d'entrée est inférieur à la longueur minimale",
  "ccm.contactitemconfig.message.itemValidate": "La première lettre doit être une lettre, un (_), de soulignement ou un signe dollar ($). Les autres lettres peuvent être un soulignement (_), un signe dollar, des lettres ou des chiffres.",
  "ccm.urlconfig.modify.callnotify.ak.tooshort": "La clé doit contenir au moins 16 caractères.",
  "ccm.agent.contact.callednook": "Sortant appelé aucune réponse",
  "ccm.recognition.label.appSecret.reset": "réinitialiser SK",
  "ccm.recognition.msg.unsubscribeConfirm": "Si la reconnaissance intelligente est désactivée, l'assistant de l'agent intelligent est également désactivé.",
  "ccm.agent.contact.acceptno": "Numéro manipulé",
  "ccm.satisfactionconfig.message.updatefailed": "Échec de la mise à jour de la configuration de l'enquête de satisfaction",
  "ccm.speciallist.msg.applyTimeFrom": "Heure de début de l'application",
  "ccm.agent.contact.resetAll": "Toutes les réinitialisations",
  "ccm.transtask.label.cancel": "Annuler",
  "ccm.idauth.create.error.length": "La longueur du code de mode d'authentification dépasse la limite supérieure.",
  "ccm.anonymous.message.fail.open": "Impossible d'ouvrir le drapeau. Vérifier la configuration CTI",
  "ccm.asr.create.case.chat.info": "Vous pouvez cliquer sur Créer un cas uniquement pendant une discussion ou après la fin d'une discussion.",
  "ccm.agent.label.subtype": "Sous-type",
  "ccm.agent.message.aiagentnummore": "Le nombre d'agents intelligents activés ne peut pas dépasser le nombre d'agents alloués lorsque le locataire est activé.",
  "ccm.taskrecord.label.executeFail": "Échec",
  "casemanagement.user.title.account": "Compte",
  "ccm.agent.message.modifymobileagentsuccess": "Modification réussie des informations sur le numéro de softphone de l'agent mobile.",
  "ccm.certificate.management.certificatefile.suffixerror.pre": "les certificats de type ne peuvent contenir que des fichiers avec l'extension",
  "ccm.transtask.label.task.uniqueness": "Une seule tâche de données de rapport peut être créée au même intervalle de vidage pour le même type de données de vidage.",
  "ccm.operlog.contactfile.download": "Télécharger le fichier",
  "ccm.agent.message.agentnumcannotmuchthanmax": "Nombre de type d'agent cible ne peut pas beaucoup que le maximum",
  "ccm.callout.lable.index": "Index",
  "ccmanagement.satisfaction.strategy.minutevalidate": "La minute est dans un format incorrect.",
  "ccm.satisfactionconfig.message.failtogetivrflow": "Veuillez activer l'enquête de satisfaction",
  "ccm.agent.restReason.selectRestReasons": "Sélectionnez une raison de repos.",
  "ccm.agent.clear.message.agentnotexist": "Impossible d'annuler la configuration car l'agent n'existe pas.",
  "ccmanagement.satisfaction.strategy.deletesuccess": "Suppression réussie.",
  "ccm.callreason.label.selectedreason": "Raisons choisies",
  "ccm.certificate.management.certificatefile.certtypeerror": "Le type de cert est une erreur.",
  "ccm.agent.message.searchAccount": "Entrez un ID d'agent.",
  "ccmanagement.satisfaction.strategy.fail.accepttime.inputnull": "L'heure de début ou de fin ne peut pas être vide.",
  "ccm.agent.contact.callinandcallout": "Appels entrants et sortants",
  "ccm.speciallist.msg.restoreerror": "Impossible de restaurer la liste spéciale",
  "ccm.urlconfig.message.missing.appKeyOrDomainName": "La clé d'application et le nom de domaine dans la configuration d'appel bidirectionnel ITA doivent être définis.",
  "ccmanagement.satisfaction.strategy.calltime.lengthlimit": "La durée minimale ou maximale de l'appel peut contenir un maximum de 12 chiffres.",
  "ccm.agent.calledroute.create.ivr.limit": "Si le type de périphérique est IVR, le type de média du code d'accès ne peut être qu'un clic pour composer, une demande de rappel ou un appel commun.",
  "ccm.agent.contact.playcomfirm": "Confirmer le jeu",
  "ccm.sysparam.error.validate.notmatchrulerangelength": "Le paramètre d'entrée dépasse la longueur maximale ou est inférieur à la longueur minimale",
  "ccm.contactitemconfig.label.data.extended.dataset.mapping.field": "Champ de mappage des jeux de données",
  "ccm.accesscode.label.index": "Non.",
  "ccmanagement.satisfaction.strategy.fail.acceptdate.inputnumber": "La date de début ou de fin est dans un format incorrect.",
  "ccm.transserverparam.label.proxyUserName": "Nom d'utilisateur",
  "ccm.common.ajax.searchErr": "Erreur de recherche.",
  "ccm.agent.label.weight": "Poids",
  "ccm.vdn.label.defaultvoice": "Par défaut",
  "ccm.transtask.label.dumpinterval": "Durée du vidage",
  "ccm.contact.customer.config.foregroundColor": "Couleur de premier plan",
  "ccm.agent.operation.INSERT": "Insérer un contrôle de qualité",
  "ccm.satisfactionconfig.message.strategychannelid": "ID de stratégie de canal",
  "ccm.agent.label.batchselectway": "Mode de sélection par lots",
  "ccm.calledroute.title.selectivrflow": "Sélectionner IVR",
  "ccm.agent.Transfer.deviceAddr": "Adresse de l'appareil de destination",
  "ccm.agent.label.agentWeightTip": "Les appels sont attribués de préférence aux agents ayant le poids le plus élevé.",
  "ccm.contact.customer.config.datasource.objName": "Champ de mappage d'objet de source de données",
  "ccm.note.config.choosenote": "Veuillez sélectionner un fichier de notes",
  "ccm.agent.softPhoneStatus.Registered": "enregistré",
  "ccm.satisfactionlevel.message.satislevel": "Niveau de satisfaction",
  "ccm.note.config.agent.ring.back.tone": "Configuration de la tonalité d'appel de l'agent",
  "ccm.pageinterface.addObject": "Ajouter un objet",
  "ccm.transtask.label.discarded": "Écarté",
  "ccm.agent.label.inputstartworkno": "Veuillez entrer l'ID de l'employé de début",
  "ccm.sysparam.error.validate.notmatchruleemail": "Veuillez entrer une adresse email valide",
  "ccm.satisfactionconfig.message.audio": "Sondage de satisfaction",
  "ccm.agent.ten.DSTOffset": "Décalage DST (min)",
  "ccm.agent.button.export": "Exporter",
  "ccm.contactitemconfig.label.data.extended.Type.String": "Chaîne",
  "ccm.contact.msg.inactivesuccess": "Invalidation effectuée avec succès.",
  "ccm.agent.contact.calltype": "Type d'appel",
  "ccm.agent.message.createfailed": "Échec de la création de l'agent",
  "ccm.dual.call.record.noMatchingDualCallRecordExists": "Aucun enregistrement d'appel double correspondant n'existe.",
  "ccm.urlconfig.message.overlimit.xNumber": "Le numéro X configuré pour les appels bidirectionnels ITA ne peut pas dépasser 20 caractères.",
  "ccm.agent.calledroute.updatecalledroute": "Modifier",
  "ccm.agent.calledroute.title.create": "Création d'une configuration de partie appelée",
  "ccm.agent.calledroute.title.updatecalledroute": "Modifier la configuration de la partie appelée",
  "ccm.transtask.label.cdrData": "Données CDR",
  "ccm.agent.leavemessage.auditcomments": "Commentaires de la vérification",
  "ccmanagement.satisfaction.strategy.operation": "Opération",
  "ccm.speciallist.msg.typeName": "Type de liste spéciale",
  "ccm.satisfactionconfig.message.strategychanneltitle": "Configurer la stratégie de canal",
  "ccm.satisfactionconfig.add.strategychanneltitle": "Création d'une politique de satisfaction des canaux",
  "ccm.satisfactionconfig.update.strategychanneltitle": "Modifier la stratégie de satisfaction du canal",
  "ccm.transtask.label.contactrcd": "Dump d'enregistrement de contact",
  "ccm.agent.calledroute.accesscodewarn": "Le code d'accès ne peut contenir que des chiffres !",
  "ccm.whatsapp.template.message.name": "Nom du message",
  "ccm.speciallist.msg.delete.fail": "Échec de la suppression",
  "ccm.agent.contact.clicktocall": "Cliquer pour composer",
  "ccm.agent.ten.asrchannelnums": "Quantité ASR",
  "ccm.agent.label.scaleFactor": "Facteur d'échelle",
  "ccm.custdata.msg.confirmDelete": "Êtes-vous sûr de vouloir le supprimer ?",
  "ccm.speciallist.msg.errorNum": "{limit} a échoué",
  "ccmanagement.satisfaction.strategy.fail.calltime.inputnull": "La durée minimale ou maximale de l'appel ne peut pas être vide.",
  "ccm.certificate.management.passwd": "Certificat Mot de passe",
  "ccm.transtask.label.dumpintervalInitial": "Durée du vidage Début",
  "ccm.agent.message.willDeleteSkills": "Êtes-vous sûr de vouloir supprimer les files d'attente de compétences {0} ?",
  "ccm.agent.message.willDeleteSkillGroups": "Êtes-vous sûr de vouloir supprimer les groupes de compétences sélectionnés",
  "ccm.custdata.operlog.confirm": "Confirmer",
  "ccm.agent.contact.multi-media": "Multimédia",
  "ccm.contactitemconfig.message.itemValidatefirst": "La première lettre doit être une lettre, un (_), de soulignement ou un ($) de signe dollar",
  "ccm.pageinterface.pleaseSelectAttribute": "Sélectionnez un attribut d'objet.",
  "ccm.urlconfig.message.httpurl.safetip": "(Pas sûr)",
  "ccm.agent.message.agentConfFail": "Impossible de configurer les informations de l'agent.",
  "ccm.satisfactionconfig.message.flowname": "Nom du processus",
  "ccm.custdata.label.importantTips": "Remarque : Les données personnelles ou les données sensibles doivent être cryptées pour être stockées et masquées pour être affichées.",
  "ccm.recognition.label.appSecret.download.querySK.error": "Impossible d'interroger le SK.",
  "ccm.agent.contact.weChat": "WeChat",
  "ccm.agent.contact.oircall": "Numéro d'appel d'origine",
  "ccm.agent.label.scaleFactorRange": "Valeur [0-10000]",
  "ccm.agent.restrecord.reststatus.timeout.Forcible.endrest": "Repos forcé à la fin",
  "ccm.urlconfig.modify.authbothnull": "Les informations d'autorisation ne peuvent pas être vides.",
  "ccmanagement.agent.batchmodify.agentnum": "Par ID d'employé",
  "ccm.speciallist.msg.necessary": "Le numéro d'abonné est obligatoire.",
  "ccm.recognition.label.closeanonymoussuccess": "Désactivation réussie de l'indicateur d'appel sortant anonyme",
  "ccm.transserver.label.servername": "Nom du serveur",
  "ccm.agent.message.numInRowFail": "La valeur doit être un nombre entier compris entre 2 et 4.",
  "ccm.transtask.label.status": "Statut",
  "ccm.agent.label.callDispatchModel": "Mode d'allocation d'appel",
  "ccm.agent.contact.QUERU_RECORDINFO_FAIL_CODE": "Les informations d'enregistrement sont vides.",
  "ccm.recognition.label.cancelordersucess": "L'abonnement est annulé avec succès.",
  "ccm.agent.leavemessage.statusFailed": "Impossible de modifier l'état de l'enregistrement du message.",
  "ccm.agent.message.sysParamCofig.closeVersatileAgentFail": "Modifiez le type d'agent sur la page Gestion des agents. Un agent polyvalent ne peut pas être associé à une file d'attente de compétences de clic-pour-composer vidéo ou vidéo.",
  "ccm.skillcreate.repeat.fail": "Le nom de la file d'attente des compétences existe déjà.",
  "ccm.speciallist.msg.selectonlyslreminder": "Peut modifier un seul rappel de liste spéciale.",
  "ccm.skill.label.skillname": "Nom de la compétence",
  "ccm.agent.title.skillCreate": "Nouvelle file d'attente de compétences",
  "ccm.custdata.option.maskStyle.maskEmail": "Masque d'email",
  "ccm.agent.contact.QUERY_SERVER_FAIL_CODE": "Impossible d'appeler le service de requête.",
  "ccm.satisfactionconfig.message.strategygroupname": "Nom du groupe de stratégie",
  "ccm.satisfactionconfig.title.strategygroup": "Création d'un groupe de stratégies",
  "ccm.sysparam.config.itemdesc": "Descriptif",
  "ccm.agent.label.keepVoiceCustom": "Personnalisation de la tonalité de maintien",
  "ccm.agent.label.numInRow": "Entrez un nombre entier allant de 2 à 4.",
  "ccm.transserver.label.view": "voir",
  "ccm.custdata.label.columnNo": "Non",
  "ccm.urlconfig.label.oauth.oauth2loginurl": "URL de connexion d'authentification OAuth 2.0",
  "ccm.custdata.label.isMaskDisplay": "Masqué",
  "ccm.agent.button.batchconfig": "Configurer le lot",
  "ccm.agent.pageurls.errorsave": "Erreur",
  "ccm.certificate.management.update.cancel.certfile": "Êtes-vous sûr de vouloir annuler la mise à jour du certificat ?",
  "ccm.common.label.error": "Erreur",
  "ccm.agent.operation.noMoreThan31Days": "L'intervalle de temps ne peut excéder 31 jours.",
  "ccm.agent.label.validTime": "Période de validité du mot de passe (jours)",
  "ccm.agent.operation.UNHOLD": "Unhold",
  "ccm.speciallist.msg.endtime": "La date d'expiration ne peut être postérieure au 19 janvier 2038",
  "ccm.speciallist.label.line": "LIGNE",
  "ccm.agent.operation.CANCELREST": "Annuler le reste",
  "ccm.custdata.option.importDone": "Terminé",
  "ccm.certificate.management.expiretime": "Heure d'expiration",
  "ccm.label.contactexport.exception": "Une erreur s'est produite lors de la génération de la tâche d'exportation. Vérifie les journaux.",
  "ccm.satisfactionconfig.message.validatemaxnumber": "Un maximum de 100 groupes de politiques peuvent être créés.",
  "ccm.certificate.management.certificatecrlfile": "Fichier CRL",
  "ccm.pageinterface.objectList": "Liste d'objets",
  "ccm.agent.label.information": "Conseils",
  "ccm.agent.label.selectagent": "Agents sélectionnés",
  "SM.ORGSTAFF.LABEL.INPUT_SEARCH_KEYWORD": "Mot-clé",
  "ccm.satisfactionconfig.message.surveytype": "Type d'enquête",
  "ccm.verifyidentity.FAIL": "Échec",
  "ccm.basicdata.error.datatypenotmatch": "Le format des données est incompatible avec le format de définition du champ",
  "ccm.agent.ten.timezone": "Fuseau horaire",
  "ccm.agent.recall": "Rappel",
  "ccm.urlconfig.message.tip.warning": "Assurez-vous que vous avez acheté des services liés à l'ITA. Sinon, les appels seront affectés.",
  "ccm.pageinterface.interfaceType.external": "Interface externe",
  "ccm.dual.call.record.talkDuration": "Durée (s) de l'appel",
  "ccm.custdata.label.columnLength": "Longueur du champ",
  "ccm.agent.title.selectskill": "Sélectionner la file d'attente de compétences",
  "ccm.pageurls.select.externalInterface": "Page externe",
  "ccm.agent.contact.itacall": "Appel bidirectionnel ITA",
  "ccm.agent.message.specialCharacters": "Le nombre contient des caractères spéciaux et des lettres.",
  "ccm.custdata.msg.completeSort": "Terminez d'abord le tri.",
  "ccm.transserver.delete.css.confirm": "Êtes-vous sûr de vouloir supprimer la configuration CSS ?",
  "ccm.agent.restReason.delete": "Supprimer",
  "ccm.agent.contact.accountname": "Nom du compte",
  "ccm.contact.customer.config.datasource.msgDataset": "Ensemble de données d'information",
  "ccm.speciallist.msg.operationerror": "L'opération a échoué",
  "ccm.agent.message.ctipasswdrepeat": "Le nouveau mot de passe ne peut pas être le même que les cinq derniers anciens mots de passe.",
  "ccm.agent.ten.dstOn": "Activé",
  "ccmanagement.tenantspace.option.weekday_mon": "Lundi",
  "ccm.agent.contact.relCallBackFlag": "Drapeau de libération",
  "ccm.agent.label.batchselect": "Sélectionner des agents par lots",
  "ccm.ucconfig.message.title": "Enregistrement des applications avec la plate-forme Microsoft Identity",
  "ccm.callreason.label.enable": "Activer",
  "ccm.agent.message.agenttypeinvalid": "Le type d'agent n'est pas valide.",
  "ccm.contactitemconfig.label.data.extended.set": "Source Dataset",
  "ccm.agent.message.worknonoexit": "L'ID de l'employé n'existe pas.",
  "ccm.agent.message.worknull": "L'ID de l'employé est vide.",
  "ccm.satisfactionconfig.message.update": "Modifier",
  "ccm.agent.label.busy": "Compétence Occupé",
  "ccm.speciallist.msg.exists": "L'utilisateur sélectionné est déjà inclus dans la liste spéciale",
  "ccm.satisfactionconfig.message.surveystrategygroup": "Politique d'enquête",
  "ccm.recognition.label.modifymobileagentflowidnull": "L'ID de flux est vide !",
  "ccm.agent.message.configvalidatefailed": "Échec de la validation de l'élément de configuration de la passerelle",
  "ccm.agent.label.agentList": "Compte de service",
  "ccm.sysparam.error.validate.notmatchrulerange": "Le paramètre d'entrée dépasse la valeur maximale ou est inférieur à la valeur minimale",
  "ccm.calledconfig.message.willdeleteassociation": "Êtes-vous sûr de vouloir supprimer les informations d'association de raison d'appel",
  "ccm.urlconfig.label.numberAllocationMode1": "Automatique",
  "ccm.urlconfig.label.numberAllocationMode2": "Spécifié",
  "ccm.agent.label.ninetydays": "90 Jours",
  "ccm.agent.url.params.tips": "Notez que la façon dont le paramètre URL GET REQUEST est créé expose vos paramètres à une adresse ouverte.",
  "ccm.contact.customer.config.visible": "Visible",
  "ccm.agent.updateSoftPhone.accountPasswordFailed": "Impossible de vérifier le mot de passe du compte.",
  "ccm.contact.customer.config.preview": "Aperçu",
  "ccm.agent.message.sysParamCofig": "Impossible de configurer les paramètres système.",
  "ccm.custdata.operlog.success": "Succès",
  "ccm.agent.leavemessage.inputauditcomments": "Saisissez des suggestions.",
  "ccm.agent.mobileagent.label.nodata": "Aucune donnée correspondante n'a été trouvée!",
  "ccm.asr.create.case.text.info": "La création de cas en un clic n'est pas prise en charge car il n'y a pas de contenu texte.",
  "ccm.agent.operation.SAYBUSY": "Occupé",
  "ccm.note.config.agent.page.ring": "Sonneries",
  "ccm.agent.pageurls.moveup": "Vers le haut",
  "ccm.custdata.operlog.downloadTemplate": "Téléchargement du modèle de tableau de base",
  "ccm.agent.leavemessage.notAgent": "Le compte n'est pas lié à un agent.",
  "ccm.agent.pageurls.confdelete": "Confirmer la suppression",
  "ccm.agent.password.agentNull": "Les informations de l'agent sont vides.",
  "ccm.agent.operation.TWOSTAGEDIALING": "Numérotation en deux étapes",
  "ccm.label.contactexport.nocount": "Aucun dossier de contact ne répond aux conditions.",
  "ccm.label.dualcallexport.nocount": "Il n'y a pas d'enregistrements d'appels doubles éligibles.",
  "ccm.recognition.label.processaccesscode": "Entrez un code d'accès de flux.",
  "ccm.transtask.label.saturday": "Samedi",
  "ccm.custdata.label.columnNum": "Champs",
  "ccm.agent.leavemessage.workNo": "Agent",
  "ccm.agent.contact.callinandout": "Double appel",
  "ccm.agent.contact.user.recall": "L'utilisateur a retiré le message",
  "ccm.agent.mobileagent.label.deletecomplete": "Supprimer l'agent terminé!",
  "ccm.agent.calledroute.refresh": "Actualiser",
  "ccm.verifyidentity.fail.TIMEPARAMNULLERROR": "La plage de temps de requête ne peut pas être vide.",
  "ccm.contactitemconfig.label.data.extended.dataset.name": "Nom du jeu de données",
  "ccm.agent.label.adjustTime": "Durée(s) en État d'organisation",
  "ccm.agent.contact.gateway.exception": "Exception interne de la passerelle. Veuillez vérifier la configuration de la passerelle.",
  "ccm.speciallist.msg.success": "Succès",
  "ccm.contact.detail.message.content": "Contenu du message",
  "ccm.custdata.msg.fileSizeValidateFail": "Sélectionnez un fichier de moins de 20 Mo.",
  "ccm.speciallist.label.reason": "Raison de l'adhésion",
  "ccm.callout.message.editfail": "Échec de la modification de l'appelant de la légende",
  "ccm.note.config.agent.page.ring.voice": "Notification d'appels entrants audio et vidéo",
  "ccm.agent.restrecord.rest.beginTime": "Heure de début du repos",
  "ccm.agent.speciallist.validityPeriod": "Période d'entrée en vigueur",
  "ccm.agent.speciallist.begintime": "Temps effectif",
  "ccm.transserverparam.label.bucket": "Bucket de données",
  "ccm.agent.calledroute.editfail": "Impossible de modifier les données locales.",
  "ccm.satisfactionconfig.message.creategroup": "Ajouter un groupe de stratégie",
  "ccm.agent.contact.resourcefail": "Une erreur se produit lors du chargement de l'enregistrement.",
  "ccm.agent.contact.calledhangup": "Fin de l'appel, appelé raccrocher",
  "ccm.agent.calledroute.skill": "File d'attente de compétences",
  "ccm.sysparam.config.checkmessage": "Contient des caractères spéciaux. (\"<\", \">\", \"/\", etc.)",
  "ccm.contact.customer.config.regular": "Régulier",
  "ccm.satisfactionconfig.sms.satisfaction.survey": "Sondage de satisfaction par SMS",
  "ccm.basicpage.not.sk.download": "Aucune autorisation de téléchargement SK n'est disponible.",
  "ccm.agent.label.restQueueRule.lessRest": "Agent avec le moins de repos le jour en cours",
  "ccm.recognition.label.interconnection.parameters": "Paramètres d'interconnexion",
  "ccm.agent.label.transparentDisplayFlag": "Indicateur d'anonymisation du numéro de l'agent",
  "ccm.calledconfig.message.numbersinvalid": "Plus de 15 numéros ou moins de 1 numéros",
  "ccm.agent.operation.noMoreThan7Days": "L'intervalle de temps ne peut excéder 7 jours.",
  "ccm.agent.callreason.modify.success": "Modification de la raison de l'appel avec succès.",
  "ccm.speciallist.msg.specialchar": "Le message de rappel ne peut pas contenir de caractères spéciaux.",
  "ccm.speciallevel.speciallist.use": "Disponible",
  "ccm.custdata.option.status.draft": "Ébauche",
  "ccm.urlconfig.message.warning": "Assurez-vous que l'URL push du CDR a été configurée sur l'ITA. Sinon, la CTI ne peut pas pousser les CDR à la CCE. Un exemple d'URL push est le suivant :",
  "ccm.agent.contact.transferRecord": "Transfert d'enregistrement",
  "ccm.pageinterface.viewAttribute": "Afficher l'attribut d'objet",
  "ccm.urlconfig.message.addfail": "Impossible d'ajouter les urls",
  "ccm.skill.message.skillpara": "Impossible de configurer la compétence elle-même",
  "ccm.idauth.title.name": "Nom du mode d'authentification",
  "ccm.agent.label.callDispatchModel.average": "Moyenne",
  "ccm.agent.label.serviceAccountCode": "Compte",
  "ccm.page.label.pageInput": "Entrez un nom de page",
  "ccm.agent.label.thirtyonedays": "31 Jours",
  "ccm.recognition.label.appSecret.download.appId.empty.error": "Le champ appId ne peut pas être vide.",
  "ccm.agent.contact.line": "LINE",
  "ccm.satisfactiontemplate.message.savefail": "Impossible d'enregistrer le modèle d'enquête de satisfaction.",
  "ccm.transtask.label.delete": "La tâche de vidage est supprimée avec succès.",
  "ccm.satisfactiontemplate.tips": "Note : Le contenu du modèle sera généré dans la langue par défaut du locataire.",
  "ccm.certificate.management.certificatefile.certpwderror": "Le mot de passe du certificat est une erreur.",
  "ccm.agent.label.sensitiveWord": "Les mots interdits sont détectés. Attention à votre formulation.",
  "ccm.idauth.title.create": "Ajouter un processus d'authentification d'identité",
  "ccm.speciallist.msg.typeidempty": "type de liste spéciale ne peut pas être vide.",
  "ccm.agent.label.deviceType": "Type d'appareil",
  "ccm.agent.label.ivr": "IVR",
  "ccm.agent.leavemessage.assign": "Attribuer",
  "ccm.agent.leavemessage.title.assign": "Attribution de messages",
  "ccm.custdata.operlog.popwindowmessage": "Veuillez saisir le mot de passe utilisé pour générer le fichier compressé. Le mot de passe compressé doit contenir des lettres, des chiffres, des caractères spéciaux _ {'@'} {'%'}",
  "ccm.agent.label.bindTheSkill": "Avant d'utiliser la file d'attente de compétences, dégroupez d'abord la route appelée.",
  "ccm.agent.label.groupUseTheSkill": ". Veuillez d'abord supprimer la configuration de la file d'attente de compétences dans le groupe de compétences.",
  "ccm.agent.label.groupBind": "La file d'attente de compétences a été liée au groupe de compétences ",
  "ccm.transtask.label.month": "Mois",
  "ccm.agent.contact.gateway.fail": "Demande à la passerelle Exception. Veuillez vérifier la configuration de la passerelle.",
  "ccm.recognition.label.mobileagent": "Agent mobile / Appel bidirectionnel en un clic",
  "ccm.urlconfig.label.relmethod": "Méthode de requête HTTP",
  "ccm.agent.contact.robothungup": "Robot",
  "ccm.contact.customer.config.underline": "Souligné",
  "ccm.agent.pageurls.maxcalling": "Le nombre maximum d'écrans pop-up d'appel entrant est {limit}.",
  "ccm.contactitemconfig.message.confOnlyDataItemCode": "L'opération a échoué car l'ID de l'élément de données existe déjà.",
  "ccm.agent.leavemessage.channel": "Canal",
  "ccm.vdn.label.index": "mois",
  "ccm.agent.message.updateAgentAndSkillFail": "Impossible de mettre à jour l'association de la file d'attente des compétences de l'agent.",
  "ccm.pageinterface.selectedAttribute": "Attribut sélectionné",
  "ccm.agent.calledroute.devicetype": "Type d'appareil",
  "ccm.verifyidentity.SUCCESS": "Passer",
  "ccm.contact.customer.config.backgroundColor": "Couleur de fond",
  "ccm.transserverparam.label.login.cssClusterUser": "Nom d'utilisateur de connexion au cluster",
  "ccm.agent.label.parameterType": "Méthode de mise en file d'attente",
  "ccm.transtask.label.thurs": "Jeudi",
  "ccm.agent.contact.talkRemark": "Remarques d'appel",
  "ccm.pageinterface.cancelAll": "Annuler tout",
  "SM.ORGSTAFF.LABEL.ORGANIZATION": "Unité d'organisation",
  "ccm.agent.message.searchSkillName": "Saisissez le nom de la file d'attente des compétences.",
  "ccm.recognition.label.intellagent": "Assistant d'agent intelligent",
  "ccm.label.pageName": "Nom de la page",
  "ccm.agent.ccagentinfo.unregister.fail": "Annuler l'enregistrement Échec",
  "ccm.agent.operation.CALLOUT": "CallOut",
  "ccm.sysparam.error.validate.notmatchruledigits": "Veuillez entrer un entier positif valide",
  "ccm.agent.calledroute.nmsfail": "Échec de la synchronisation avec le NMS.",
  "ccm.agent.message.agentCTIFail": "Les agents de requête modifiés dans la passerelle ont échoué.Veuillez vérifier la configuration de la passerelle.",
  "ccm.transtask.label.notEnabled": "Désactivé",
  "ccm.agent.button.cancel": "Annuler",
  "ccm.custdata.label.description": "Descriptif",
  "ccm.agent.addbother.msg.emailOrPhoneValidate": "Format incorrect.",
  "ccm.basicdata.error.firstlinenotmatch": "La première ligne du fichier importé est incompatible avec celle du modèle",
  "ccm.agent.ten.dstOff": "Désactivé",
  "ccm.agent.ten.systemdefault": "Fuseau horaire par défaut",
  "ccm.agent.message.dataIntegrityFail": "Données incomplètes configurées pour la mise en page ou l'interface définie. L'opération a échoué.",
  "ccm.contract.management.confirm": "Le document contient des données sensibles. Êtes-vous sûr de vouloir continuer ?",
  "ccm.contract.management.confirmtitle": "Confirmer l'exploitation",
  "ccm.speciallist.msg.restoresuccess": "Restauration réussie",
  "ccm.agent.callreason.need": "Veuillez sélectionner au moins une raison d'appel.",
  "ccm.pageinterface.title": "Interface de page",
  "ccmanagement.satisfaction.strategy.fail.calltime.inputlength": "La durée minimale ou maximale de l'appel peut contenir un maximum de 12 chiffres.",
  "ccm.satisfactionconfig.message.version": "Numéro de version",
  "ccm.agent.sysparamconfig.resttimenumberwarn": "La durée du repos doit aller de 1 à 1440 (ne contient pas 1440)",
  "ccm.agent.pageurls.edit": "Modifier",
  "ccm.speciallist.tip.time.max": "Veuillez sélectionner une date antérieure à l'expiration de l'article (2038-01-19)",
  "ccm.agent.label.singlePhoneFlag": "Agent par téléphone uniquement",
  "ccm.agent.message.addAIRecogFail": "Le nombre d'agents intelligents activés ne peut pas dépasser le nombre d'agents alloués lorsque le locataire est activé.",
  "ccm.transtask.label.enable": "Activer",
  "ccm.agent.message.searchSipAccount": "Entrez un numéro de téléphone logiciel SIP.",
  "ccm.agent.restrecord.reststatus.timeout.endrest": "Temps de repos expiré",
  "ccm.agent.operation.FORCEIDEL": "Forcer le ralenti",
  "ccm.agent.button.refresh": "Actualiser",
  "ccm.agent.updateSoftPhone.newPasswordFailed": "Impossible de vérifier le nouveau mot de passe.",
  "ccm.agent.message.omsAgentTypeCheck": "Le rôle sélectionné ne correspond pas au type d'agent d'un agent en ligne.",
  "ccm.agent.restReason.restCauseDesc": "Descriptif",
  "ccm.agent.message.videoNotEnough": "Le nombre de ressources vidéo est insuffisant car les agents vidéo et les agents configurés avec des files d'attente de compétences vidéo occupent les ressources vidéo. Allouez les ressources vidéo correctement.",
  "ccm.satisfactionconfig.message.smstitle": "SMS",
  "ccm.agent.ten.resourceinfor": "Informations sur les ressources",
  "ccm.agent.label.deviceUsage": "Utilisation de l'appareil (%)",
  "ccm.speciallist.msg.warning": "Avertissement",
  "ccm.custdata.option.maskStyle.maskBankAccountNum": "Masque de compte bancaire",
  "ccm.basicdata.msg.queryfail": "Impossible d'interroger les données de base.",
  "ccm.agent.message.agenttypebothempty": "Un type d'agent n'est pas configuré pour l'agent sélectionné. Vous devez configurer le type d'agent.",
  "ccm.agent.contact.multimedia": "Multimédia",
  "ccm.agent.message.querycenterfailed": "Impossible d'interroger l'agent intelligent du centre d'appels.",
  "ccm.ucconfig.message.integration": "Intégration Microsoft Teams",
  "ccm.ucconfig.message.info.alert": "Veuillez copier l'adresse suivante à Microsoft pour l'enregistrement de l'application en tant qu'URI Accepter l'authentification de redirection. Copiez le résultat de l'inscription et remplissez les informations suivantes de la demande d'inscription.",
  "ccm.ucconfig.message.copy": "Copier",
  "ccm.ucconfig.message.register": "Enregistrement des informations de la demande",
  "ccm.ucconfig.message.required.fields": "Champs obligatoires",
  "ccm.ucconfig.message.required.limit": "La longueur ne peut pas dépasser 64 caractères.",
  "ccm.verifyidentity.userId": "Numéro de client",
  "ccm.agent.pageurlparam.calldata": "Données d'appel",
  "ccm.custdata.option.status.abandon": "Rejeter",
  "ccm.agent.label.release": "libération",
  "ccm.agent.message.synToCcpFailed": "Impossible de synchroniser le mode d'authentification de l'agent avec la cc-provision.",
  "ccm.satisfactionconfig.message.textmsg": "Message texte",
  "ccm.agent.pageurls.adressval": "L'adresse d'accès doit commencer par \"https://'\" ou \"http://'\".",
  "ccm.agent.pageurls.adressval.noPoint": "L'adresse d'accès doit commencer par \"https://'\" ou \"http://'\"",
  "ccmanagement.satisfaction.strategy.acceptdate.dateStart": "Date de début",
  "ccm.calledconfig.label.querysoftnum": "Voir les numéros de téléphone logiciel",
  "ccm.callreason.label.createrelatecase": "Associer des cas",
  "ccm.agent.ten.tenantName": "Nom du locataire",
  "ccmanagement.satisfaction.strategy.fail.accepttime.startafterend": "L'heure de début doit être antérieure à l'heure de fin.",
  "ccm.agent.updateSoftPhone.updateSuccess": "Mot de passe changé avec succès.",
  "ccm.agent.ten.DSTEndTime": "Heure de fin DST",
  "ccm.agent.input.userNumber": "Numéro d'utilisateur",
  "ccmanagement.satisfaction.strategy.fail.calltime.startafterend": "La durée minimale d'appel doit être inférieure à la durée maximale d'appel.",
  "ccm.skill.placeholder.skilldesc": "Veuillez saisir un nom de file d'attente de compétences.",
  "ccm.sysparam.error.validate.notmatchruleip": "Veuillez entrer une adresse IPV4 ou IPV6 valide",
  "ccm.contact.label.signed": "Signé",
  "ccm.ucconfig.message.failed": "Échec",
  "ccm.pageurls.select.internalInterface": "Page interne",
  "ccm.agent.calledroute.refreshsuccess": "Refresh réussi.",
  "ccm.transtask.label.ivrAccessCodeReport": "Rapport statistique de données IVR par code d'accès",
  "ccm.contact.customer.config.writeBackField": "Champ étendu d'écriture d'enregistrement de contact",
  "ccm.verifyidentity.label": "Authentification d'identité",
  "ccm.agent.calledroute.ccivrdata.error": "Impossible de synchroniser les données avec le NMS. Les données du processus CC-IVR sont incorrectes.",
  "ccm.certificate.management.search.certcode": "Veuillez saisir le code du certificat",
  "ccm.systemparam.error.reset": "Impossible de réinitialiser les paramètres.",
  "ccm.sysparam.config.item.detail": "Informations sur les paramètres",
  "ccm.agent.contact.multimedia.facebook": "Multimédia-Facebook",
  "ccm.certificate.management.certificate.tip": "Conseils:",
  "ccm.agent.pageurls.sernum": "Non.",
  "ccm.whatsapp.whatsApp.message.template": "Modèle WhatsApp",
  "ccm.agent.message.aiagentclosed": "La fonction d'identification intelligente du locataire est désactivée. Contactez l'administrateur système.",
  "ccm.agent.callreason.exceedmaxnum": "Un maximum de cinq raisons d'appel sont autorisées.",
  "ccm.agent.label.configAdmin": "Administrateur de configuration",
  "ccm.satisfactionconfig.message.satisreport": "Rapport d'enquête sur la satisfaction",
  "ccm.agent.label.maxTalkTimeRange": "Valeur [1-86400]",
  "ccm.certificate.management.certificatefile.typeerror": "Type de fichier incorrect.",
  "ccm.agent.restReason.restCauseCode": "Code de raison de repos",
  "ccm.agent.button.save": "Enregistrer",
  "ccm.agent.message.agentandskillnotmatch": "Le type de compétence doit correspondre au type d'agent",
  "ccm.custdata.operlog.addExportTaskSuccess": "Nouvelle tâche d'exportation réussie",
  "ccm.label.page.delete.confirm": "Êtes-vous sûr de vouloir le supprimer ?",
  "ccm.calledconfig.message.overmaxnum": "La quantité maximale de {0} a été atteinte.",
  "ccm.custdata.option.maskStyle.maskIdCardNum": "Masque de numéro de carte d'identité",
  "ccmanagement.tenantspace.option.weeklist_fir": "D'abord",
  "ccm.systemparam.success.reset": "Paramètre réinitialisé avec succès.",
  "ccm.agent.updateSoftPhone.unFoundInfo": "Le centre d'appels n'est pas synchronisé.",
  "ccm.sysparam.error.validate.notmatchrulerequired": "Obligatoire",
  "ccm.whatsapp.select.whatsApp.channel": "Veuillez sélectionner un canal WhatsApp.",
  "ccm.custdata.operlog.uploadDataFile": "Téléchargement du fichier de données de la table de base",
  "ccm.transserver.label.proxynotinwhite": "L'opération a échoué. Contactez l'administrateur système pour ajouter l'adresse du serveur proxy à la liste d'approbation du serveur proxy du serveur OBS.",
  "ccm.agent.leavemessage.cannotBeModified": "Impossible de modifier le message dans l'état actuel.",
  "ccm.dual.call.record.endTime": "Heure de fin d'appel",
  "ccm.transtask.label.tasknameexits": "Impossible de créer la tâche car le nom de la tâche existe déjà.",
  "ccm.recognition.label.anonymous": "Drapeau d'appel sortant anonyme",
  "ccm.custdata.option.status.release": "libération",
  "ccm.contact.customer.config.key": "Clé de données",
  "ccm.custdata.msg.tableExistInstData": "La table personnalisée contient des données d'instance et ne peut pas être retirée pour modification.",
  "ccm.transtask.config.pathvalidate": "Le chemin du fichier de vidage ne peut pas contenir \"./\".",
  "ccm.custdata.operlog.downloadData": "Téléchargement des données de base de la table",
  "ccm.agent.label.queuingCallsMaxNumRangeTwo": "Valeur [1-10000]",
  "ccm.speciallist.msg.sltype": "Type de liste spéciale",
  "ccm.satisfactionlevel.message.resetsuccess": "Le niveau de satisfaction et le modèle d'enquête de satisfaction ont été réinitialisés avec succès.",
  "ccm.speciallist.msg.userno.error": "Le format du numéro d'abonné est incorrect.",
  "ccm.satisfactionconfig.message.choosestrategy": "*Sélectionnez la stratégie d'enquête de satisfaction",
  "ccm.systemparam.error.notmatchrule": "Le paramètre ne répond pas à la règle de vérification.",
  "ccm.agent.leavemessage.process": "Processus",
  "ccm.agent.label.skillQueue": "File d'attente de compétences :",
  "ccm.certificate.management.certificatefile.maxrecord": "Le nombre maximum de certificats est de {0}.",
  "ccm.contact.detail.sending.time": "Heure d'envoi",
  "ccm.agent.message.searchAgentServiceNo": "Veuillez entrer le numéro de service de l'agent",
  "ccm.agent.calledroute.create.gateway.none": "Le script de routage référencé par la configuration de l'appelé n'existe pas.",
  "ccm.agent.label.sipAccount": "Numéro de téléphone logiciel",
  "ccm.agent.updateSoftPhone.updateGatewayFailed": "Impossible de modifier le mot de passe de la passerelle.",
  "ccm.recognition.label.openVoiceNotificationSuccess": "La fonction de notification vocale est activée avec succès.",
  "ccm.agent.sysparamconfig.verifyTemplateIdLength": "L'ID du modèle de message interne doit être un nombre entier et ne peut pas être un nombre négatif.",
  "ccm.agent.message.validatordigit": "Configuration de l'agent téléphonique unique Le mot de passe doit être numérique seulement",
  "ccm.agent.leavemessage.messageprocess": "Message de processus",
  "ccm.agent.label.agentType": "Type d'agent",
  "ccm.recognition.label.voiceNotification": "Drapeau de notification vocale",
  "ccmanagement.tenantspace.option.weeklist_sec": "Deuxième",
  "ccm.agent.message.deleteSkill": "Supprimer la file d'attente de compétences",
  "ccm.agent.label.notselectcallreason": "Veuillez sélectionner le motif de l'appel",
  "ccm.recognition.label.orderfailwhilte": "L'abonnement a échoué. Impossible de synchroniser la liste d'approbation.",
  "ccm.urlconfig.label.relurl": "URL de raccrocher",
  "ccm.agent.message.modifySkillFail": "Échec de la mise à jour de la compétence.",
  "ccm.transtask.label.contactRecord": "Enregistrement de contact",
  "ccm.transserver.discard.confirm": "Êtes-vous sûr de vouloir abandonner la tâche ?",
  "ccm.satisfactionconfig.message.media": "Chaînes multimédia",
  "ccm.satisfactionconfig.message.groupbatchdelete": "Suppression par lot",
  "ccm.label.page.modifyPage": "Modifier la page",
  "ccm.speciallist.msg.limit": "Le nombre de contenus importés dépasse 1000",
  "ccm.custdata.title.modify": "Modification d'une table de base",
  "ccm.satisfactionconfig.web.switch.remark": "Une fois ce commutateur activé, l'enquête de satisfaction sera envoyée au client une fois le service terminé.",
  "ccm.agent.label.authtype.uap": "Authentification UAP",
  "ccm.idauth.create.error.limit": "Un maximum de cinq processus d'authentification d'identité peuvent être ajoutés.",
  "ccm.whatsapp.template.message.id": "ID de message de modèle",
  "ccm.agent.contact.talkReason": "Raison d'appel",
  "ccm.satisfactionconfig.message.createsuccess": "Ajout réussi de la configuration de l'enquête de satisfaction",
  "ccm.satisfactionservice.evaluationerror": "Échec de l'évaluation",
  "ccmanagement.satisfactionconfig.calltime.timeerror.laterThanEndTime": "La durée minimale d'appel doit être inférieure à la durée maximale d'appel.",
  "ccm.transtask.label.restores": "Restaurer",
  "ccm.pageinterface.inputInterfaceName": "Entrer",
  "ccm.agent.contact.startcallfailed": "Échec du démarrage de l'appel",
  "ccm.agent.message.deleteSkillConfSuccess": "Les informations de la file d'attente de compétences sont supprimées avec succès.",
  "ccm.transtask.label.taskname": "Nom de la tâche",
  "ccm.custdata.operlog.conditions": "Les conditions",
  "ccm.label.contactexport.selectdata": "Sélectionner les données",
  "ccm.agent.message.agenttypecannotbenull": "Sélectionnez un type d'agent si la file d'attente de compétences est sélectionnée.",
  "ccm.agent.pageurlparam.relSysParamType": "Type de paramètre de données du système associé",
  "ccm.agent.label.inputendworkno": "Veuillez saisir l'identifiant de l'employé final",
  "ccm.agent.message.pageNameValidateFail": "Les caractères spéciaux ne sont pas autorisés",
  "ccm.agent.message.itemNameValidateFail": "Les caractères spéciaux suivants ne sont pas autorisés &<>\";%/[]=+{'@'}",
  "ccm.calledconfig.message.willdeletemobileagent": "Êtes-vous sûr de vouloir supprimer l'agent mobile sélectionné ?",
  "ccm.label.page.createPage": "Nouvelle page",
  "ccm.agent.sysparamconfig.refreshAllSysParamSuccess": "Refresh réussi.",
  "ccm.recognition.label.openmobileagentsuccess": "A permis avec succès l'agent mobile.!",
  "ccm.callreasonconfig.message.deletecallreason.failed": "La suppression a échoué.",
  "ccm.speciallist.msg.dateerror": "Saisissez l'heure d'entrée en vigueur et l'heure d'expiration.",
  "ccm.agent.contact.assistant": "Appel collaboratif",
  "ccm.custdata.operlog.authFailed": "L'authentification a échoué",
  "ccm.agent.label.useTheSkill": "La file d'attente de compétences {0} a été liée à l'ID de l'agent {1}. Supprimez d'abord la configuration de la file d'attente de compétences sous l'agent.",
  "ccm.agent.sysparamconfig.worknowarn": "Utilisez des (,) de virgules pour séparer les identifiants d'agent.",
  "ccm.agent.title.agentinfo": "Informations sur l'agent",
  "ccm.agent.ten.agentSipInfo": "Adresse IP et numéro de port du serveur d'enregistrement de l'agent",
  "ccmanagement.satisfaction.strategy.acceptdate.startdate": "Date de début",
  "ccm.satisfactionconfig.message.calltype": "Type d'appel",
  "ccm.dual.call.record.option": "Opération",
  "ccm.botherlist.label.comment": "Avis",
  "ccm.agent.contact.playVideo": "lecture d'une vidéo",
  "casemanagement.casedraft.label.casetype": "Type de cas",
  "ccm.callout.message.confreset": "Réinitialiser Confirmer",
  "ccm.custdata.label.isMandatory": "Obligatoire",
  "ccm.note.config.agent.page.ring.title": "Cette configuration s'applique uniquement lorsque la page est masquée, par exemple lorsque le navigateur est minimisé, ou lorsque la page est tablée en arrière-plan.",
  "ccm.calledconfig.table.skillormumbers": "Dispositif de but",
  "ccm.agent.restrecord.reststatus.normal.endrest": "Le repos s'est terminé normalement",
  "ccm.agent.label.playVoice": "Jouer le ton d'attente",
  "ccm.agent.calledroute.confdelete": "Confirmer la suppression",
  "ccm.contact.label.unsigned": "Non signé",
  "ccm.satisfactionlevel.message.savefail": "Impossible d'enregistrer le niveau de satisfaction.",
  "ccm.agent.label.createWorkOrdeType": "Type de cas associé",
  "ccm.agent.restrecord.reststatus.timeout.Forcible.endres": "Repos forcé à la fin",
  "ccm.transtask.label.datacondition": "Critères de filtre de données",
  "ccm.transtask.label.agentOutBoundReport": "Rapport sommaire des appels sortants de l'agent",
  "ccm.agent.message.warning": "Avertissement",
  "ccm.agent.sysparamconfig.multimediawarn": "Le nombre de messages multimédias simultanés doit varier de 1 à 60.",
  "ccm.systemparam.success.save": "Paramètre enregistré avec succès.",
  "ccm.custdata.operlog.exportiong": "Exportation",
  "ccm.agent.calledroute.errorsave": "Erreur",
  "ccm.agent.message.searchsoftphone": "Entrez le numéro de téléphone logiciel.",
  "ccm.agent.contact.calltime": "Durée (s) de l'appel",
  "ccm.agent.button.downloadvideoterminal": "Télécharger OpenEye",
  "ccm.calledconfig.label.numbers": "Numéros",
  "ccm.custdata.label.isIndex": "Utilisé comme index",
  "ccm.agent.message.synToUapFailed": "Impossible de synchroniser le mode d'authentification de l'agent avec l'UAP.",
  "ccm.sysparam.error.validate.notmatchrulemaxlength": "Longueur maximale du paramètre",
  "ccm.urlconfig.label.talkmethod": "Méthode de rappel de connexion",
  "ccm.custdata.label.columnName": "Nom du champ",
  "ccm.agent.message.nocomments": "Pas de commentaires",
  "ccm.agent.message.willDeleteAgents": "Êtes-vous sûr de vouloir supprimer l&apos;agent sélectionné ?",
  "ccm.custdata.msg.columnNoMatch": "Le champ de table est incompatible avec celui de la base de données. Actualisez la table et réessayez.",
  "ccm.transserverparam.label.info": "Informations de base",
  "ccm.agent.contact.recordingfiles": "Fichier d'enregistrement",
  "ccm.agent.message.selectMaxAgents": "Vous pouvez traiter un maximum de 20 à la fois.",
  "ccmanagement.satisfaction.strategy.fail": "Échec",
  "ccm.agent.message.existAIAgent": "La reconnaissance intelligente et l'enregistrement bipiste ne peuvent pas être activés en même temps, alors que la reconnaissance intelligente a été activée pour certains des agents à modifier par lots.",
  "ccm.agent.label.fixedValue": "Valeur fixe",
  "ccm.satisfactionconfig.message.delete": "Supprimer",
  "ccm.agent.operation.FORCELOGOUT": "Forcer la sortie",
  "ccm.agent.mobileagent.notice": "Avis",
  "ccm.agent.operation.optime": "Temps d'opération",
  "ccm.certificate.management.certificate.crlfile.sizeerror": "Le fichier CRL ne peut pas dépasser 2048 Ko.",
  "ccm.agent.title.specialListReminder": "Rappel de liste spéciale",
  "ccm.recognition.label.fail": "Échec",
  "ccm.certificate.management.certificatefile.certsceneerror": "La scène de cert est une erreur.",
  "ccm.agent.calledroute.pagename": "Nom de la page",
  "ccm.agent.leavemessage.channelType": "Type de canal",
  "ccm.satisfactionconfig.message.strategygroupsrv": "Configuration du canal de stratégie d'enquête de satisfaction",
  "ccm.transserverparam.label.agentport": "Port",
  "ccm.transserverparam.label.agentip": "Adresse du serveur proxy",
  "ccm.agent.label.dualChannelRecFlag": "Enregistrement double piste",
  "ccm.custdata.operlog.operation": "Opérer",
  "ccmanagement.satisfaction.strategy.fail.acceptdate.overlimit": "Un maximum de 100 stratégies de date de traitement peuvent être sauvegardées.",
  "ccm.agent.operation.HOLD": "Maintenir",
  "ccm.satisfactionconfig.message.duplicateconfig": "La priorité de chaque politique pour le même canal doit être unique.",
  "ccm.custdata.option.columnType.time": "Date et heure",
  "ccm.botherlist.label.approveComment.approved": "Rejeter",
  "ccm.satisfactionconfig.message.callskill": "File d'attente de compétences",
  "ccm.callreason.label.setcallreason": "Configuration de la raison de l'appel",
  "ccm.satisfactionconfig.message.audiofeedbacktype": "Voix IVR",
  "ccm.callout.placeholder.caller": "Veuillez saisir le numéro d'appel de l'appel sortant",
  "ccm.speciallist.msg.level": "Niveau de liste spéciale",
  "ccm.basicdata.error.syserror": "Erreur système. Contactez le personnel de maintenance",
  "ccm.certificate.management.certificatefile.uploadcertcrlfile": "Télécharger le fichier CRL",
  "ccm.satisfactionconfig.message.acceptagent": "Agent de manipulation",
  "ccm.satisfactionconfig.message.channelstrategyconfig": "Politique de sondage sur la satisfaction des canaux",
  "ccm.agent.resetskillrecord.adjustagentworkno": "Ajustement de l'ID de l'agent",
  "ccm.speciallist.msg.alreadyexist": "À déjà une notification de liste spéciale du même type et niveau.",
  "ccm.contactitemconfig.label.data.item.basicext": "Données étendues de base",
  "ccm.callout.title.success": "Succès",
  "ccmanagement.tenantspace.option.weeklist_fth": "Quatrième",
  "ccm.querycustdata.msg.selectTable": "Sélectionnez un nom de table",
  "ccm.custdata.label.template": "_Modèle",
  "ccm.agent.label.registrationMessage": "Infos d'inscription",
  "ccm.transserver.label.serveraddr": "Adresse",
  "ccm.agent.button.submit": "Envoyer",
  "ccm.agent.calledroute.create.accesscode.noexist": "Le code d'accès référencé par la configuration de la partie appelée n'existe pas.",
  "ccm.custdata.option.isMandatory.yes": "Obligatoire",
  "ccm.note.config.filepath": "Sélectionner le fichier de notes",
  "ccm.agent.contact.multimedia.telegram": "Télégramme multimédia",
  "ccm.verifyidentity.verifyTime": "Temps d'authentification",
  "ccm.agent.contact.playLeaveMessage": "Le message suivant est diffusé :",
  "ccm.datatask.checkNum": "La valeur est un entier positif.",
  "ccm.agent.Transfer.Intercept": "intercepter",
  "ccm.satisfactionconfig.message.smschannel": "SMS Push Channel",
  "ccmanagement.satisfaction.strategy.calltime.maxvalue": "Durée(s) maximale(s) d'appel",
  "ccm.agent.resetskillrecord.skillbeforeadjust": "File d'attente de compétences avant l'ajustement",
  "ccm.agent.conditionOfBusyTransfer.error": "Configurez les paramètres de condition CFB.",
  "ccm.agent.label.mobileagententer": "Entrer",
  "ccm.agent.label.expired": "Expiré",
  "ccm.agent.label.preVoiceSelect": "Voix avant l'identification de l'employé",
  "ccm.agent.pageurls.createcall": "Nouvel écran d'appel entrant Pop-up",
  "ccm.agent.pageurls.config.screen.pop.up": "Écran de configuration Pop-up",
  "ccm.agent.pageurls.unsaved.data.exists":"Les données non sauvegardées existent. S'il vous plaît, enregistrez-le d'abord.",
  "ccm.accesscode.label.accesscode": "Code d'accès",
  "ccm.speciallevel.speciallist.levelMessage": "Gestion de niveau",
  "casemanagement.user.title.name": "Nom d'utilisateur",
  "ccm.note.config.agent.page.ring.tipTitle": "Si une boîte de dialogue est configurée, la boîte de dialogue n'est valide qu'une fois que l'agent a configuré manuellement Autoriser ce site Web à utiliser des fenêtres pop-up sur le navigateur pendant l'intégration de la connexion.",
  "ccm.satisfactionconfig.message.confirm": "Confirmer",
  "ccm.speciallist.tip.startTime.min": "L'heure effective doit être postérieure à l'heure actuelle.",
  "ccm.agent.label.usedMode": "Type",
  "ccm.agent.calledroute.operation": "Opération",
  "ccm.agent.title.batchagentEdit": "Configuration des informations de l'agent de lot",
  "ccm.contactitemconfig.label.data.item.category": "Type de données",
  "ccm.agent.contact.allcalltype": "Tous les types d'appels",
  "ccm.basicdata.msg.nodatamodify": "Aucune donnée n'a besoin d'être modifiée.",
  "ccm.basicdata.msg.modifyfail": "Impossible de modifier les données de base.",
  "ccm.speciallist.msg.approve": "Approbation",
  "ccm.speciallist.label.approve": "Commentaires d'approbation",
  "ccm.speciallist.poptitle.approve": "Approbation des dossiers de harcèlement",
  "ccm.speciallist.poptitle.view": "Affichage des dossiers de harcèlement",
  "ccm.speciallist.title.approve": "Informations sur l'approbation",
  "ccm.basicdata.error.databaseerror": "Les données d'index sont dupliquées. En conséquence, les données ne peuvent pas être importées dans la base de données par lots",
  "ccm.agent.button.clear": "Réinitialiser",
  "ccm.recognition.label.openanonymoussuccess": "Réussi à activer le drapeau d'appel sortant anonyme. Veuillez sélectionner un code d'accès clic-pour-appeler configuré pour la route appelée.",
  "ccm.satisfactionconfig.message.channeltype": "Type de canal",
  "ccm.sysparam.error.validate.notmatchrulemin": "Le paramètre d'entrée est inférieur au minimum",
  "ccm.satisfactionconfig.param.faile": "Paramètre incorrect.",
  "ccm.agent.updateSoftPhone.updateFailed": "Impossible de modifier le mot de passe",
  "ccm.contact.tip.not.get.workname": "Le nom de compte correspondant à l'agent n'est pas obtenu!",
  "ccm.agent.calcOfMaxCallNum.error": "Configurez le nombre maximum d'appels en file d'attente.",
  "ccm.contactitemconfig.message.DatasetName.inuse": "Le jeu de données ne peut pas être supprimé car il est déjà configuré dans l'élément de données d'enregistrement de contact.",
  "ccm.sysparam.url.safe.tip": "HTTP n'est pas sécurisé et peut entraîner des risques de sécurité.",
  "ccm.agent.label.keepWaitType": "Méthode de conservation",
  "ccm.transtask.label.tasktype": "Type",
  "ccm.agent.label.Account": "Compte",
  "casemanagement.common.label.nodata": "Pas de données.",
  "ccm.custdata.option.columnType.date": "Date",
  "ccm.callout.lable.type": "Type de paramètre",
  "ccm.agent.calledroute.create.media.match": "Le code d'accès système configuré pour la partie appelée est différent du type de support configuré pour la partie appelée.",
  "ccm.agent.label.noaireconition": "Faux",
  "ccm.agent.label.maxWaitTime": "Max. Temps d'attente (s)",
  "ccm.transserver.label.cssnotinwhite": "L'opération a échoué. Contactez l'administrateur système pour ajouter l'adresse du service CSS à la liste d'approbation du serveur CSS de vidange de ressources.",
  "ccm.chatbot.label.assist": "Assistant robotique",
  "ccm.verifyidentity.loginId": "Compte d'entreprise",
  "ccm.custdata.button.moveTop": "haut de page",
  "ccm.agent.message.agentUpdateFail": "Échec du journal de mise à jour.",
  "ccm.transtask.label.task.chatrecordall.uniqueness": "Vous ne pouvez pas ajouter tous les types de canaux car des tâches existent pour certains types de canaux.",
  "ccm.satisfactionconfig.message.strategygroupdesc": "Description du groupe de politiques",
  "ccm.agent.message.clear": "Tout effacer",
  "ccm.agent.restrecord.rest.reason": "Raison du repos",
  "ccm.skill.label.skilldesc": "Descriptif",
  "ccm.pageinterface.interfaceType.internal": "Interface interne",
  "ccm.calledroute.button.finish": "Complète",
  "ccm.agent.label.operate": "Opération",
  "ccm.transtask.label.ivrReport": "Rapport de trafic IVR",
  "ccm.agent.contact.contactno": "Numéro de contact",
  "ccm.agent.resetskillrecord.adjustedbusinessaccount": "Compte de service ajusté",
  "ccm.agent.label.selfPhoneNotNull": "Lorsque la fonction d'anonymisation du numéro d'agent est activée, le numéro de téléphone fixe ou le numéro de téléphone mobile d'agent ne peut pas être vide.",
  "ccm.agent.title.agentpassword": "Mot de passe du compte",
  "ccmanagement.tenantspace.option.weeklist_sectolast": "Avant-dernier",
  "ccm.agent.title.serviceAccount": "Compte Service Cloud associé",
  "ccm.urlconfig.label.oauth.ak": "AK",
  "ccm.agent.button.delete": "Supprimer",
  "ccm.vcallcenter.config.popup.authentication": "Authentication",
  "ccm.note.config.searchnotename": "S'il vous plaît entrer le nom",
  "ccm.custdata.operlog.addExportTaskError": "La nouvelle tâche d'exportation a échoué, veuillez vérifier le journal",
  "ccm.sysparam.error.validate.notmatchruleurl": "Veuillez entrer une adresse URL valide",
  "ccm.agent.restReason.create": "Ajouter",
  "ccm.agent.softPhoneStatus": "statut du numéro de softphone",
  "ccm.agent.ten.DSTStartDate": "Date de début de l'heure d'été",
  "ccm.anonymous.message.fail.save": "Impossible d'enregistrer la route appelée. Veuillez vérifier la configuration CTI",
  "ccm.agent.label.video": "Agent vidéo",
  "ccm.sysparam.error.validate.notmatchrulemax": "Le paramètre d'entrée dépasse le maximum",
  "ccmanagement.tenantspace.option.weeklist_fthtolast": "Dernier avant trois",
  "ccm.agent.title.specialListHis": "Historique des listes spéciales",
  "ccm.custdata.operlog.specCharValidate": "Impossible de contenir des caractères spéciaux sauf _ @ %",
  "ccm.urlconfig.message.overlimit.appId": "La clé d'accès configurée pour les appels bidirectionnels ITA ne peut pas dépasser 128 caractères.",
  "ccm.agent.message.selectAgents": "Veuillez sélectionner un agent.",
  "ccm.agent.contact.joinreason": "Raison de l'adhésion",
  "ccm.agent.contact.SFTP_DOWNLOAD_FAIL_CODE": "Impossible de télécharger le fichier d'enregistrement à partir du serveur SFTP.",
  "ccm.sysparam.error.validate.inner": "Échec de la validation du paramètre",
  "ccm.agent.message.redislocknull": "Impossible d'obtenir le verrou Redis.",
  "ccm.sysparam.config.reset": "Réinitialiser",
  "ccm.urlconfig.message.deletefail": "Impossible de réinitialiser les urls",
  "ccm.agent.label.waitAgentWeight": "Entrez un poids d'agent.",
  "ccm.agent.contact.info": "Informations sur le document",
  "ccm.calledroute.message.selectcalledroute": "Veuillez sélectionner la configuration de la partie appelée.",
  "ccm.agent.login.LOGIN": "Se connecter",
  "ccm.custdata.msg.operationSuccess": "L'opération a réussi.",
  "ccm.agent.contact.recordInfo": "Détails du fichier d'enregistrement",
  "ccm.recognition.label.modifyVoiceNotificationClose": "La fonction de notification vocale n'est pas activée pour l'espace locataire.",
  "ccm.agent.operation.CANCELLISTEN": "Annuler l'écoute",
  "ccm.agent.mobileagent.success": "Réussir",
  "ccm.agent.contact.SFTP_INCORRECT_FILE_CODE": "Les informations du serveur sftp sont incorrectes",
  "ccm.agent.pageurls.whitelisturl": "Veuillez contacter l'administrateur système pour ajouter l'url à la liste de confiance.",
  "ccm.agent.ten.cobrowseMaxNumber": "Nombre maximum de connexions de collaboration de pages Web",
  "ccm.idauth.title.ivr": "Processus d'authentification",
  "ccm.speciallist.msg.selectslreminder": "Sélectionnez un rappel de liste spécial.",
  "ccm.agent.contact.email": "Courriel",
  "ccm.agent.contact.Email": "Email",
  "ccm.pageurls.select.webApi": "API Web",
  "ccm.callreason.label.name": "Nom et prénom",
  "ccm.agent.calledroute.createcalledroute": "Nouveau",
  "ccm.taskrecord.label.executing": "Exécution",
  "ccm.contactitemconfig.label.data.item.contact.list.visibility.yes": "Oui",
  "ccm.skill.label.maxwaittime": "Max. Durée de mise en file d'attente",
  "ccm.recognition.label.appSecret.download.SK.not.exist": "Les informations SK n'existent pas.",
  "ccm.agent.label.skillpara": "Configuration des paramètres de compétence",
  "ccm.agent.contact.subtype.none": "Aucun",
  "ccm.agent.leavemessage.lengthcodewarn": "Un message peut contenir un maximum de 300 caractères.",
  "ccm.agent.mobileagent.confirm": "Confirmer",
  "ccm.recognition.label.save": "Enregistrer",
  "ccm.agent.updateSoftPhone.unErrorInfo": "Exception système.",
  "ccm.basicdata.msg.error": "Erreur",
  "ccm.custdata.operlog.passValidateFailed": "Doit contenir des lettres, des chiffres, des caractères spéciaux _ {'@'} {'%'}",
  "ccm.urlconfig.modify.oauth.ak.tooshort": "L'AK doit contenir au moins 16 caractères.",
  "ccm.dual.call.record.statistics": "Statistiques",
  "ccm.agent.message.createSkillConfSuccess": "Les informations de la file d'attente de compétences sont créées avec succès.",
  "ccm.agent.ccagent.qcmodify.fail": "Sélectionnez un type d'agent audio.",
  "ccm.agent.contact.norecording": "Pas d'enregistrement.",
  "ccm.speciallist.msg.delete": "Supprimer",
  "ccm.satisfactionconfig.message.strategychoosed": "Politiques sélectionnées",
  "ccm.pageinterface.selectAttributeTitle": "Sélectionner l'attribut d'objet",
  "ccm.agent.pageurls.movedown": "En bas",
  "ccm.agent.label.maxWaitTimeSpan": "Valeur [1–60000]",
  "ccm.custdata.msg.dictionaryInputFormat": "Format: Clé 1:Valeur 1, Clé 2:Valeur 2",
  "ccm.speciallist.msg.empty": "Le message de rappel ne peut pas être nul.",
  "ccm.contactitemconfig.message.confOnlyDataItemName": "L'opération a échoué car le nom de l'élément de données existe déjà.",
  "ccm.agent.pageurls.confnaming": "Assurez-vous que le nom n'existe pas dans le système.",
  "ccm.agent.login.FORCELOGIN": "Forcer la connexion",
  "ccm.transtask.label.pause": "Pause",
  "ccm.agent.pageurlparam.urlParamName": "Paramètre d'URL pop-up de l'écran",
  "ccm.ucconfig.message.success": "Succès",
  "ccm.agent.accesscode.n400number": "Numéro de service",
  "ccm.custdata.button.withdraw": "Retirer",
  "ccm.satisfactionconfig.satisfaction.switch": "Commuter",
  "ccm.agent.label.off": "Fermeture",
  "ccm.calledconfig.message.casetype": "Type de cas",
  "ccm.satisfactionconfig.message.configstrategydata": "Configurer les données de stratégie",
  "ccm.speciallist.label.blocklist": "Liste de blocage",
  "ccm.agent.contact.customername": "Nom du client",
  "CCM.AGENT.CONTACT.ORG": "Organisation de l'agent",
  "ccm.agent.contact.agent.recall": "L'agent a retiré le message",
  "ccmanagement.satisfaction.strategy.fail.acceptdate.inputparse": "La date de début ou de fin est dans un format incorrect.",
  "ccm.agent.calledroute.placeholder.ivr": "Veuillez sélectionner un RVI.",
  "ccm.agent.operation.ID": "Opération No.",
  "ccm.agent.operation.HELP": "Consulter",
  "ccm.speciallist.button.cancel": "Annuler",
  "ccm.agent.leavemessage.status.inProcess": "Traitement",
  "ccm.certificate.management.certificate.crlfile.crlfileinvalid": "Format de fichier CRL incorrect.",
  "ccmanagement.satisfaction.strategy.success": "Succès",
  "ccm.agent.button.ok": "OK",
  "ccm.custdata.button.release": "libération",
  "ccm.agent.message.samePageNameFail": "Dupliquer le nom de la page.",
  "ccm.agent.ten.daylightSavingTime": "DST",
  "ccm.satisfactionlevel.message.savesuccess": "Le niveau de satisfaction et le modèle d'enquête de satisfaction ont été sauvegardés avec succès.",
  "ccm.custdata.option.importFail": "Échec",
  "ccm.satisfactionconfig.message.staistip1": "Après que cet interrupteur est activé, le client sera transféré à l'enquête de satisfaction après que l'agent raccroche.",
  "ccmanagement.tenantspace.option.weekday_wed": "Mercredi",
  "ccm.agent.mobileagent.fail": "Échec",
  "ccm.label.contactexport.parainnull": "Sélectionnez les données à exporter.",
  "ccm.agent.restReason.operation": "Opération",
  "ccm.agent.videoDownload.noPermission": "L'agent n'a pas la permission de télécharger",
  "ccm.custdata.option.isEncrypt.no": "Non",
  "ccm.satisfactionconfig.message.success": "Succès",
  "ccm.satisfactionconfig.message.strategygroupmemchoose": "Sélectionnez les politiques.",
  "ccm.speciallist.operlog.downloadtemplate": "Télécharger le modèle",
  "ccm.custdata.msg.indexColumnValidateFailed": "Un champ utilisé comme index est obligatoire et ne peut pas être chiffré.",
  "ccm.custdata.operlog.viewExportTask": "Exporter la vue des tâches",
  "ccm.satisfactionconfig.message.surveyconfig": "Configuration de l'enquête",
  "ccm.agent.message.modifymobileagentfail": "Impossible de modifier les informations sur le numéro de softphone de l'agent mobile.",
  "ccm.ivr.label.processfilename": "Nom du fichier",
  "ccm.custdata.msg.tableBeUsed": "La table personnalisée est référencée par un objet de page de configuration. Assurez-vous que la table n'est pas référencée avant d'effectuer cette opération.",
  "ccm.agent.contact.agenthungup": "Agent",
  "ccm.agent.message.typenosame": "Les types de file d'attente de compétences doivent être les mêmes.",
  "ccm.agent.contact.contactinfo": "Informations de contact",
  "ccm.agent.leavemessage.paramisempty": "Paramètre vide.",
  "ccm.agent.message.queryctiagentmessage": "Impossible de trouver les informations de l'agent dans CTI.",
  "ccm.agent.calledroute.delsuccess": "Supprimé avec succès.",
  "ccm.satisfactionconfig.message.webfeedbacktype": "Web",
  "ccm.satisfactionconfig.message.smsgateway": "Passerelle SMS",
  "ccm.agent.operation.SAYFREE": "Idle",
  "ccm.callout.message.choosecaller": "Veuillez choisir un appelant d'appel",
  "ccm.contactitemconfig.message.init.basicdata.success": "Les données de base sont initialisées avec succès.",
  "ccm.agent.ccagent.modify.fail": "La reconnaissance intelligente et l'enregistrement bipiste ne peuvent pas être activés en même temps.",
  "ccm.agent.label.mediaagent": "Agent multimédia",
  "ccm.anonymous.message.fail.cti": "Impossible d'interroger le flux d'appels sortants anonymes sur CTI",
  "ccm.custdata.label.relaBaseColumn": "Champ dans la table sous-jacente",
  "ccm.agent.contact.invalid": "Invalidation",
  "casemanagement.user.title.select": "Sélectionner",
  "ccm.custdata.msg.notAllowOperation": "Cette opération n'est pas autorisée. Actualisez la page et réessayez.",
  "ccm.basicdata.error.suffix": ".",
  "ccm.agent.label.paramsConfig": "Configuration des paramètres du système",
  "ccm.agent.label.answerType": "Type de réponse",
  "ccm.agent.operation.noMoreThan90Days": "L'intervalle de temps ne peut excéder 90 jours.",
  "ccm.agent.contact.workno": "ID d'employé",
  "ccm.agent.verify.fail": "La vérification a échoué.",
  "ccm.note.config": "Configuration de la tonalité Ring Back",
  "ccm.note.select": "Sélectionner un ton Ring Back",
  "ccm.agent.restReason.restCauseTime.limit": "La durée du repos doit être supérieure à 0 seconde et inférieure à 24 heures.",
  "ccm.taskrecord.label.executeStatus": "Statut de la tâche",
  "ccm.skillcreate.maxnumber.failed": "Le nombre de files d'attente de compétences locataires ne peut pas dépasser la limite supérieure :",
  "ccm.pageinterface.interfaceName": "Nom de l'interface",
  "ccm.verifyidentity.fail.VERIFYTIMERANGEERROR": "Plage de temps de requête incorrecte.",
  "ccm.page.label.status": "Statut de la page",
  "ccm.agent.operation.UNMUTE": "Désactiver le son",
  "ccm.sysparam.error.save": "Erreurs",
  "ccm.ivrflow.placeholder.ivrflowdesc": "Veuillez entrer une description de flux ivr.",
  "ccm.agent.message.redisFailed": "Impossible d'obtenir le verrou Redis.",
  "ccm.satisfaction.message.resetconfirm": "Voulez-vous vraiment réinitialiser le niveau de satisfaction et le contenu du modèle d'enquête de satisfaction? Tous les canaux doivent utiliser le niveau de satisfaction et le contenu du modèle après la réinitialisation.",
  "ccm.calledconfig.message.deletemobileagentfail": "Impossible de supprimer l'association de la configuration de l'agent mobile",
  "ccm.recognition.label.clicktocallaccesscode": "Code d'accès click-to-call",
  "ccm.whatsapp.select.whatsApp.message.template": "Veuillez sélectionner un modèle de message WhatsApp.",
  "ccm.sysparam.error.validate.notmatchrulenumber": "Veuillez entrer un numéro",
  "ccmanagement.satisfaction.strategy.save": "Enregistrer",
  "ccmanagement.tenantspace.option.weekday_sat": "Samedi",
  "ccm.agent.pageurls.errorinfo": "La création a échoué.",
  "ccm.agent.operation.SWITCH": "commutateur",
  "ccm.label.contactexport.selectrange": "Sélectionner la gamme",
  "ccm.transserver.delete.time.fail": "Supprimez la tâche dix minutes après son rejet.",
  "ccm.transserver.label.css": "Serveur CSS",
  "ccm.agent.label.serviceAccountId": "ID de compte Service Cloud associé",
  "ccm.agent.message.deleteAgentSkillFail": "Impossible de supprimer l'association de la file d'attente des compétences de l'agent.",
  "ccmanagement.satisfaction.strategy.hourformatvalidate": "L'heure est dans un format incorrect.",
  "ccm.agent.message.isornoairecognition": "Statut intelligent",
  "ccm.page.select.tileLayout": "Disposition de grille",
  "ccm.agent.ten.phonenumber": "Numéro de téléphone portable",
  "ccm.agent.label.sensitiveWordNumber": "Mots interdits",
  "ccm.satisfactionconfig.message.strategygroupid": "ID de groupe de stratégie",
  "ccm.agent.sysparamconfig.worknorangewarn": "L'identification de l'agent doit aller de 101 à 50000.",
  "ccm.recognition.label.closeVoiceNotificationSuccess": "La fonction de notification vocale est désactivée avec succès.",
  "ccm.satisfactionconfig.ivr.voice.satisfaction.survey": "Sondage sur la satisfaction de la voix IVR",
  "ccm.operlog.contactexport.download": "Télécharger les fiches de contact",
  "ccm.agent.ten.recordtime": "Période de conservation de l'enregistrement",
  "ccm.agent.operation.FORCEDROPCALL": "Libération forcée",
  "ccm.agent.agentcontact.nosearch": "Ne peut pas être interrogé dans le traitement non professionnel.",
  "ccm.agent.recall.agent.calltype.invalid": "Le type d'appel actuel ne prend pas en charge l'opération de rappel.",
  "ccm.agent.ten.vdnid": "ID VDN",
  "ccm.agent.sysparamconfig.hasSelectAgentWorkLimit": "Pas plus de cinq sièges sont autorisés.",
  "ccm.contactitemconfig.message.itemValidateall": "Saisissez un trait de soulignement (_), un signe dollar ($), des lettres ou des chiffres",
  "ccm.note.config.policy": "Ton de rappel de l'agent",
  "ccmanagement.tenantspace.option.weeklist_tid": "Troisième",
  "ccm.speciallist.msg.disable.fail": "Échec de la désactivation",
  "ccm.agent.label.ordinaryAgent": "Agent commun",
  "ccm.agent.label.paramValue": "Valeur",
  "ccm.transserverparam.label.cssClusterPass": "Mot de passe",
  "ccm.agent.label.agentWeight": "Poids de l'agent",
  "ccm.satisfactionlevel.message.resetfail": "Impossible de réinitialiser le niveau de satisfaction.",
  "ccm.agent.calledroute.recordexist": "Le code d'accès est lié au code d'extension. Veuillez entrer un nouveau code d'extension.",
  "ccm.agent.contact.authHandle": "Communiquez avec le personnel de gestion connexe.",
  "ccm.custdata.button.return": "Retour",
  "ccm.agent.label.busyRate": "Utilisation de la file d'attente (%)",
  "ccm.urlconfig.message.talkurlnotinwhite": "L'opération a échoué. Contactez l'administrateur système pour ajouter l'URL de rappel de connexion à la liste d'approbation de rappel.",
  "ccm.agent.calledroute.edit": "Modifier",
  "ccm.satisfactionconfig.message.prioritytip": "*Une valeur plus petite indique une priorité plus élevée. La politique avec une priorité plus élevée est de préférence appariée pour exécuter l'enquête.",
  "casemanagement.user.button.cancel": "Annuler",
  "ccm.custdata.option.isEncrypt.yes": "Oui",
  "ccm.speciallist.msg.deleteyerror": "Impossible de supprimer la liste spéciale",
  "ccm.custdata.label.failNum": "Nombre d'enregistrements ayant échoué",
  "ccm.custdata.label.dualCall.sucNum": "Nombre de réussites",
  "ccm.custdata.label.dualCall.failNum": "Nombre d'échecs",
  "ccm.contact.customer.config.italic": "Italique",
  "ccm.agent.calledroute.delete.skill.use": "La configuration de la partie appelée est référencée par une file d'attente de compétences et ne peut pas être supprimée.",
  "ccm.transtask.label.sunday": "Dimanche",
  "ccm.agent.label.waitVoiceDefault": "Ton d'attente par défaut",
  "ccmanagement.satisfaction.strategy.calltime.interval": "Plage de durée d'appel",
  "ccm.urlconfig.message.relurlnotinwhite": "L'opération a échoué. Contactez l'administrateur système pour ajouter l'URL de callback à la liste d'approbation de callback.",
  "ccm.speciallevel.speciallist.restrictHours": "Durée Limitée (Heure)",
  "ccm.agent.contact.multimedia.twitter": "Multimédia-X (Twitter)",
  "ccm.satisfactionconfig.select.ivr.process": "S'il vous plaît sélectionner une enquête de satisfaction flux de RVI.",
  "ccm.urlconfig.label.oauth.sk": "SK",
  "ccm.agent.message.sysparamservicerr": "Erreur de service interne. Veuillez vérifier le journal.",
  "ccm.agent.message.download.err": "Le téléchargement a échoué. Contactez les ingénieurs O&M du système.",
  "ccm.custdata.option.isIndex.no": "Non",
  "ccm.agent.accesscode.description.containspatial": "La description du code d'accès ne peut pas contenir de caractères spéciaux % _ ^ & * < > $ /",
  "ccm.custdata.msg.confirmAbandon": "Êtes-vous sûr de vouloir le jeter ?",
  "ccm.agent.calledroute.delete": "Supprimer",
  "ccm.agent.restReason.restCauseTime": "Durée du repos",
  "ccmanagement.satisfactionconfig.acceptdata.timeVerification": "Sélectionnez une date postérieure à l'heure actuelle et antérieure à 2038-01-19.",
  "ccm.transserverparam.label.sk": "SK",
  "ccm.urlconfig.message.notNumber.xNumber": "Le numéro X configuré pour les appels bidirectionnels ITA n'est pas numéro.",
  "ccm.vcallcenter.config.popup.current.user.locked.error": "L'utilisateur actuel est verrouillé et sera déverrouillé dix minutes plus tard.",
  "ccm.certificate.management.certificatefile": "Fichier de certificat",
  "ccm.sysparam.config.select.null": "Veuillez sélectionner un paramètre.",
  "ccm.note.config.configsuccess": "Configurer le succès de la note.",
  "ccm.note.config.agent.page.ring.audition": "Audition",
  "ccm.certificate.management.warn.selectcerttype": "Veuillez sélectionner le type de certificat.",
  "ccm.transtask.label.reports": "Rapport de vidange",
  "ccm.agent.calledroute.nmsconnect.fail": "la connexion NMS a échoué.",
  "ccm.chatbot.pop.title": "Sélectionner un robot",
  "ccm.recognition.label.calledroutesuccess": "Configuration réussie La route appelée",
  "ccm.agent.contact.web": "Web",
  "ccm.agent.message.omsAgentTypeBatchCheck": "Le rôle ou le type d'agent sélectionné ne correspond pas lorsque l'agent (ID de l'employé [{0}]) est activé en ligne.",
  "ccm.speciallist.label.msgContent": "Message de rappel",
  "ccm.agent.message.willExportAgent": "\u0020messages. Etes-vous sûr d'exporter?",
  "ccm.calledconfig.message.deletemobileagent": "Supprimer l'agent mobile",
  "ccm.agent.label.deviceUsageThreshold": "Seuil d'utilisation de l'appareil (%)",
  "ccm.agent.button.restSoftPhonePwd.tips": "La réinitialisation du mot de passe du softphone affectera le softphone en cours d'utilisation, ce qui obligera l'agent à se reconnecter. Êtes-vous sûr de vouloir réinitialiser le mot de passe du softphone ?",
  "ccm.transtask.label.tuesday": "Mardi",
  "ccm.custdata.msg.importDataFail": "Impossible d'importer les données.",
  "ccm.agent.operation.MUTE": "muet",
  "ccm.agent.mobileagent.label.choosehint": "Veuillez choisir les agents avant de les supprimer !",
  "ccm.skill.label.maxwaitnum": "Max. Appels en file d'attente",
  "ccm.recognition.label.closemobileagentsuccess": "Désactivation réussie de l'agent mobile.!",
  "ccm.anonymous.message.fail.delete": "Impossible de supprimer la route appelée. Veuillez vérifier la configuration CTI",
  "ccm.verifyidentity.fail.unknown": "La requête a échoué",
  "ccm.sysparam.config.save": "Enregistrer",
  "ccm.transserver.label.css.serveraddr": "Adresse CSS",
  "ccm.agent.restrecord.rest.duration": "Durée du repos",
  "ccm.agent.operation.vdnNotEnabled": "Le VDN n'est pas activé.",
  "ccm.sysparam.config.opterate": "Opération",
  "ccm.callout.title.fail": "Échec",
  "ccm.satisfactionconfig.message.surveymethod": "Méthode d'enquête",
  "ccm.recognition.queryAK.error": "Une erreur s'est produite lors de l'interrogation des informations de clé APP.",
  "ccmanagement.tenantspace.option.weekday_fri": "Vendredi",
  "ccm.agent.label.theAgent": "Agent :",
  "ccm.operlog.dualcallexport.download": "Télécharger les enregistrements d'appels doubles",
  "ccm.callout.message.nocallers": "Aucun appelant n'a été choisi",
  "ccm.agent.callacceptno.set": "Définir",
  "ccm.agent.leavemessage.status.solved": "Traité",
  "ccm.recognition.queryAK.not.exist": "L'espace locataire actuel n'a pas de clé d'application.",
  "ccm.agent.login.LOGOUT": "Se déconnecter",
  "ccm.contactitemconfig.message.confOnlyDatasetName": "L'opération a échoué car le nom du jeu de données existe déjà.",
  "ccm.transserver.delete.success": "Le serveur de vidange est supprimé avec succès.",
  "ccm.agent.addbother.fail": "Echec de l'ajout.",
  "ccm.verifyidentity.workNo": "ID de l'agent",
  "ccm.agent.contact.resourceloading": "Chargement des ressources...",
  "ccm.agent.message.searchTimeRemaining": "Expiration du mot de passe",
  "ccm.transtask.label.cantDelete": "Le serveur ne peut pas être supprimé car il est déjà référencé par une tâche de vidage.",
  "ccm.agent.contact.reset": "Réinitialiser",
  "ccm.agent.operation.failCode": "Code de raison d'échec",
  "ccm.custdata.option.isIndex.yes": "Oui",
  "ccm.agent.label.versatileagent": "Agent polyvalent",
  "ccm.speciallevel.tip.start": "Êtes-vous sûr de l'activer ?",
  "ccm.ucconfig.message.urlTitle": "Redirection URI configuré vers la plate-forme Microsoft pour accepter les réponses d'authentification :",
  "ccm.calledconfig.table.addnumbers": "Ajouter des numéros",
  "ccm.agent.message.inSpecialStrFail": "Le nom de la page ne peut pas contenir de caractères spéciaux.",
  "ccm.agent.ten.maxVideoAgents": "Quantité d'agent vidéo",
  "ccm.agent.message.ctipasswdless": "La longueur du mot de passe ne peut pas être inférieure à 8.",
  "ccmanagement.satisfaction.strategy.fail.calltime.inputnagetive": "La durée minimale ou maximale de l'appel ne peut pas être inférieure à 0.",
  "ccm.chatbot.invalid.bot": "Le robot n'est pas valide.",
  "ccm.urlconfig.label.relurl.voicemsg.alerttip": "Si aucune URL de rappel n'est transférée lorsque l'API de création de notification vocale est appelée, cette URL est rappelée lors de l'achèvement de la notification vocale. Sinon, l'URL transférée est rappelée.",
  "ccm.agent.contact.signaturedate": "Date de signature",
  "ccm.speciallist.msg.size": "La taille du fichier à importer dépasse 10 Mo",
  "ccm.sysparam.config.mod": "Modifier",
  "ccm.recognition.label.appSecret.download.SK.only.once.error": "Si vous oubliez la clé, réinitialisez-la et téléchargez-la à nouveau.",
  "ccm.recognition.label.closeIvrAgentSuccess": "L'assistant d'agent intelligent est désactivé avec succès.",
  "ccm.transserver.label.css.edit": "Configuration du serveur de recherche Cloud",
  "ccm.agent.speciallist.addList": "Rejoindre les enregistrements de harcèlement",
  "ccm.speciallist.label.expirationDate": "Temps d'expiration",
  "ccm.agent.label.queuingCallsMaxNum": "Nombre maximum d'appels en file d'attente",
  "ccm.transtask.label.datapath": "Chemin du fichier de vidange",
  "ccm.transtask.label.reportedir": "Rapport de vidange",
  "ccm.agent.label.timeOut": "Délai d'expiration des compétences",
  "ccm.sysparam.config.item.catalog": "Type de paramètre",
  "ccm.agent.resetskillrecord.adjustedagentworkno": "ID de l'agent ajusté",
  "ccm.agent.leavemessage.contactNumber": "Numéro de contact",
  "ccm.agent.contact.twitter": "X (Twitter)",
  "ccm.agent.operation.searchworkno": "Veuillez entrer l'identifiant de l'employé",
  "ccm.agent.ten.sipInfo": "Adresse et port SIP",
  "ccm.certificate.management.certificatefile.parseerror": "Impossible d'analyser le certificat. Vérifiez si le format du certificat ou le mot de passe est correct.",
  "ccm.agent.login.logIP": "Adresse IP de connexion",
  "ccm.transtask.label.task.otheruniquene": "Un seul fichier vocal, des données d'inspection intelligentes ou une tâche de type de données CDR peuvent être créés pour le même type de données de vidage.",
  "ccm.contactitemconfig.label.data.item.contact.list.visibility": "Enregistrement de contacts visibles",
  "ccm.botherlist.label.approveComment.agree": "D'accord",
  "ccmanagement.satisfaction.strategy.acceptdate.dateEnd": "Date de fin",
  "ccm.recognition.label.updatefail": "La mise à jour du locataire fautl a échoué.",
  "ccm.custdata.msg.confirmRelease": "Êtes-vous sûr de vouloir le sortir ?",
  "ccm.agent.contact.audioclicktodial": "Cliquer pour composer la voix",
  "ccm.agent.ten.maxVideoCalls": "Max. Appels vidéo simultanés",
  "ccm.callout.message.editsuccess": "Succès de modification de l'appelant d'appel",
  "ccm.agent.contact.customizes": "Personnalisation",
  "ccm.satisfactionconfig.message.strategygroup": "Groupe des politiques sur l'enquête sur la satisfaction",
  "ccmanagement.satisfaction.strategy.calltime.minvalue": "Durée(s) minimale(s) d'appel",
  "ccmanagement.satisfaction.strategy.delete": "Supprimer",
  "ccm.note.config.failed": "Échec de la configuration de la note",
  "ccm.taskrecord.label.executeEndTime": "Heure de fin",
  "ccm.agent.contact.status": "Statut du document",
  "ccm.speciallevel.speciallist.unuse": "Indisponible",
  "ccm.custdata.msg.fileNameValidateFail": "Le nom du fichier ne peut contenir que des caractères chinois, des lettres minuscules, des chiffres ou des traits de soulignement (_)",
  "ccm.callreason.label.callreason": "Raison d'appel",
  "ccm.callreason.title.callreason": "Raison d'appel associée",
  "ccm.callreason.label.parentcallreason": "Raison de l'appel parent",
  "ccm.callReason.title.createCallReason": "Créer un motif d'appel",
  "ccm.callReason.title.editCallReason": "Modifier la raison de l'appel",
  "ccm.agent.label.authtype.unified": "Authentification unifiée",
  "ccm.agent.calledroute.dnid": "ID de configuration",
  "ccm.agent.label.answerType4": "Signaler l'identité de l'employé et les voix personnalisées",
  "ccm.agent.restReason.partDeleteFailed": "La suppression partielle a échoué.",
  "ccm.agent.contact.multimedia.whatsapp": "Multimédia-Whatsapp",
  "ccm.agent.contact.videoclicktodial": "Vidéo Cliquez pour composer",
  "ccm.satisfactionconfig.message.createfailed": "Échec de la nouvelle configuration de l'enquête de satisfaction",
  "ccm.agent.mobileagent.syserror": "Impossible d'activer la reconnaissance intelligente. Contactez l'administrateur système pour vérifier les configurations de l'environnement.",
  "ccm.agent.label.waitVoiceConfig": "Configuration de la file d'attente et de l'attente",
  "ccm.agent.operation.opresult": "Résultat",
  "ccm.agent.pageurlparam.paramIsConfiged": "Le paramètre URL contextuel de l'écran ou le paramètre de données système associé existe déjà.",
  "ccm.agent.label.answerType2": "Signaler aucune voix",
  "ccm.satisfactionconfig.message.flowaccesscode": "Code d'accès au processus",
  "ccm.agent.label.answerType0": "Signaler l'ID de l'employé",
  "ccm.agent.label.skills": "File d'attente de compétences",
  "ccm.agent.label.serviceCode": "Type de service",
  "ccm.agent.label.duration": "Durée",
  "ccm.agent.label.queueTime": "Durée de la file d'attente",
  "ccm.agent.label.availabilityTime": "Durée disponible",
  "ccm.agent.label.mutetime": "Durée du silence",
  "ccm.agent.label.calltime": "Aucune durée d'appel muet",
  "ccm.agent.label.ringingTime": "Duración del timbre",
  "ccm.agent.label.serviceLevel": "Temps d'attente ( %)",
  "ccm.agent.label.transferInformation": "Transférer ou non",
  "ccm.agent.label.transfer": "Temps de transfert",
  "ccm.agent.label.ivrUsage": "Temps de transfert IVR",
  "ccm.agent.label.originId": "ID de travail du cédant",
  "ccm.agent.label.queueName": "File d'attente de compétences de transfert",
  "ccm.agent.label.wrapUpCode": "état global",
  "ccm.agent.label.uuiInfo": "Informations UUI",
  "ccm.agent.label.overallState": "État général",
  "ccm.note.config.agent.page.ring.setUp": "Configuration de notification de message de page",
  "ccm.agent.ten.maxAgents": "Agents vocaux",
  "ccm.agent.operation.FORCEBUSY": "Force Occupé",
  "ccm.note.config.success": "Succès",
  "ccm.agent.message.audioagentzero": "Le nombre d'agents audio est 0. Impossible de configurer la file d'attente de compétences de clic-pour-numéroter audio.",
  "ccm.agent.contact.callserialno": "Appel série No",
  "ccm.agent.ten.ivrchannels": "Quantité audio de canal d'IVR",
  "ccm.anonymous.message.fail.feature": "La fonctionnalité auth de l'appel sortant anonyme est anormale",
  "ccm.label.contactexport.selectdatarange": "Portée des données",
  "ccm.agent.pageurls.pagename": "Nom de la page",
  "ccm.agent.sysparamconfig.paramwarn": "Veuillez sélectionner une valeur de paramètre valide.",
  "ccm.policy.label.updatesucess": "Les informations de politique sont mises à jour avec succès.",
  "ccm.agent.label.notreply": "Impossible de répondre",
  "ccm.callreasonconfig.message.deletecallreason.used": "La suppression a échoué, la raison de l'appel a été utilisée.",
  "ccmanagement.satisfaction.strategy.fail.acceptdate.startafterend": "La date de début doit être antérieure à la date de fin.",
  "ccm.custdata.button.importData": "Importer des données",
  "ccm.callreason.input.specialStrValidateFailed": "Le nom de raison de l'appel ne peut pas contenir de caractères spéciaux.",
  "ccm.chatbot.pop.table.botname": "Nom du robot",
  "ccm.agent.calledroute.placeholder.extcode": "Veuillez entrer un code d'extension.",
  "ccm.asr.create.case.info": "Vous pouvez cliquer sur Créer un cas uniquement pendant un appel ou après la fin d'un appel.",
  "ccm.agent.speciallist.endtime": "Temps de révocation",
  "ccm.agent.leavemessage.status.allocationFailed": "Échec de l'allocation",
  "ccm.worknoaftervoice.config.choosenote": "Sélectionnez une valeur pour Voice After Employee ID.",
  "ccm.custdata.option.all": "Tous",
  "ccm.agent.pageurls.create": "Nouveau",
  "ccm.agent.label.modifymobileagent": "Modifier le numéro du softphone",
  "ccm.transserver.label.cssaddr": "Adresse du service CSS",
  "ccm.recognition.label.api.fabric.id": "ID de tissu API",
  "ccm.agent.calledroute.extcodewarn": "Le code d'extension ne peut contenir que des chiffres.",
  "ccm.agent.login.FORCELOGOUT": "Forcer la déconnexion",
  "ccm.agent.label.deletecallreason": "Raison actuelle Contient raison enfant Ne peut pas être supprimé",
  "ccm.agent.label.signedinAgentsPercentage": "Pourcentage d'agents inscrits",
  "ccm.recognition.label.ordersucess": "Abonnement réussi.",
  "ccmanagement.satisfaction.strategy.hourvalidate": "L'heure ne peut pas être supérieure à 24.",
  "ccm.sysparam.config.select.count": "Un maximum de 10 paramètres peut être sélectionné.",
  "ccm.recognition.resetSK.success": "SK réinitialisé avec succès.",
  "ccm.custdata.option.maskStyle.maskAll": "Masquer tous les chiffres",
  "ccm.transserver.delete.confirm": "Êtes-vous sûr de vouloir supprimer le serveur ?",
  "ccm.pageinterface.selectedAttributeNum": "Attributs d'objets sélectionnés",
  "ccm.agent.pageurls.typenosupported": "Le multimédia ne prend pas en charge la configuration interne des pages.",
  "ccm.calledconfig.message.nocalled": "Veuillez choisir appelé",
  "ccm.agent.message.idIsEmpty": "L'ID de la file d'attente de compétences est vide.",
  "ccm.agent.pageurlparam.paramCountLimit": "Un maximum de paramètres d'URL pop-up {limit} peut être configuré.",
  "ccm.agent.message.webRTCVerification": "Ce paramètre ne peut pas être configuré lorsque la fonction WebRTC est activée.",
  "ccm.agent.contact.callserno": "Appel S/N",
  "ccm.agent.contact.minCallDuration": "Début de la durée de l'appel",
  "ccm.agent.contact.maxCallDuration": "Fim da duração da chamada",
  "ccm.agent.contact.lessThan": "inférieur à",
  "ccm.agent.contact.callDurationError":"L'heure de début de durée d'appel est plus courte que l'heure de fin de durée d'appel",
  "ccm.agent.contact.talkDurationError":"La durée de l'appel ne peut pas dépasser 31 jours.",
  "ccm.agent.contact.fuzzyCallNoError":"La plage de numéros de contact (recherche floue) ne peut pas dépasser 31 jours.",
  "ccm.agent.contact.fuzzyCallNo": "Numéro de contact (recherche floue)",
  "ccm.agent.leavemessage.status": "Statut",
  "ccm.speciallist.tip.endTime.min": "La date d'expiration doit être postérieure à l'heure actuelle.",
  "ccm.agent.sysparamconfig.resttimewarn": "La valeur doit être un entier.",
  "ccm.speciallist.msg.levelName": "Niveaux",
  "ccm.callreason.label.selectedcallreason": "Raison d'appel sélectionnée",
  "ccm.custdata.button.moveUp": "Vers le haut",
  "ccm.agent.label.querymobileagent": "Interroger le numéro de softphone",
  "ccm.agent.label.addChildReason": "Les paramètres de cause ne doivent pas dépasser le niveau 6",
  "ccm.ucconfig.message.updatefailed": "Impossible d'enregistrer la configuration d'intégration UC.",
  "ccm.pageinterface.custTableName": "Nom de la table sous-jacente",
  "ccmanagement.satisfaction.strategy.fail.accepttime.overlimit": "Un maximum de 100 politiques de temps de traitement peuvent être sauvegardées.",
  "ccm.satisfactiontemplate.title": "Configuration du contenu du modèle d'enquête de satisfaction",
  "ccm.agent.pageurls.updatecall": "Modifier la ècran pop-up des appels entrants",
  "ccmanagement.satisfaction.strategy.fail.unknown": "L'enregistrement a échoué.",
  "ccm.agent.ten.DSTStartTime": "Heure de début DST",
  "ccm.agent.ten.contactInfor": "Méthode de contact",
  "ccm.verifyidentity.fail.LIMITOVERLIMIT": "La valeur du paramètre de requête de pagination dépasse la limite supérieure.",
  "ccm.speciallist.msg.operationdoing": "Les autres évaluateurs approuvent",
  "ccm.agent.contact.otherexception": "Autre exception",
  "ccm.satisfactionservice.noevaluation": "Non évalué",
  "ccm.contactitemconfig.label.data.item.name": "Nom de l'élément de données",
  "ccm.certificate.management.certificatefile.certcodeexist": "Le code du certificat existe déjà.",
  "ccm.certificate.management.search.status": "Veuillez sélectionner l'état du certificat",
  "ccm.certificate.management.status": "Statut du certificat",
  "ccm.certificate.management.status.value.inuse": "En cours d'utilisation",
  "ccm.certificate.management.status.value.discard": "Abandonnée",
  "ccm.certificate.management.status.oper.reuse": "Réutilisation",
  "ccm.certificate.management.status.oper.discard": "Abandonnée",
  "ccm.certificate.management.status.confirm.reuse": "Confirmation de réutilisation",
  "ccm.certificate.management.status.confirm.discard": "Confirmation d'abandon",
  "ccm.certificate.management.status.confirmReuse": "Voulez-vous vraiment continuer à utiliser ce certificat?",
  "ccm.certificate.management.status.confirmDiscard": "Vérifiez si le certificat n'est pas utilisé dans un scénario de service et révoquez-le.",
  "ccm.certificate.management.status.update.success": "Le statut du certificat a été modifié avec succès.",
  "ccm.certificate.management.status.update.failed": "Impossible de modifier l'état du certificat",
  "ccm.certificate.management.certificatefile.status.error": "Le certificat est obsolète et ne peut pas être modifié",
  "ccm.callout.message.resetsuccess": "Réussi à réinitialiser le paramètre.",
  "ccm.agent.message.deleteSkillConfFail": "Impossible de supprimer les informations de la file d'attente de compétences.",
  "ccm.agent.message.deleteSkillConfFailID": "Impossible de supprimer {0}.",
  "ccm.agent.message.modifySkillConfSuccess": "Les informations de la file d'attente de compétences sont modifiées avec succès.",
  "ccmanagement.tenantspace.option.weekday_sun": "Dimanche",
  "ccm.agent.calledroute.placeholder.skill": "Veuillez sélectionner une file d'attente de compétences.",
  "ccm.pageurls.select.independently": "Ouvert indépendamment",
  "ccm.custdata.label.failreaon": "Cause d'échec",
  "ccm.agent.label.agentTel": "Numéro de mobile/ligne fixe de l'agent",
  "ccm.transtask.label.taskedit": "Tâche de vidage des ressources",
  "ccm.custdata.msg.confirmReturn": "Le tri n'est pas terminé. Êtes-vous sûr de vouloir revenir à la page pour afficher le tableau personnalisé?",
  "ccm.querycustdata.msg.inputIndexData": "S'il vous plaît entrer",
  "ccm.agent.calledroute.extlengthcodewarn": "La longueur du code d'extension ne peut pas être supérieure à 12",
  "ccm.agent.contact.numhungup": "Transfert vers un numéro externe",
  "ccm.agent.contact.multimedia.fiveG": "Multimedia-5G RCS",
  "ccm.agent.label.restQueueRule": "Politique Rest Queuing",
  "ccm.transtask.label.chatrecordinf": "Paramètres de vidage de données de chat multimédia",
  "ccm.agent.leavemessage.allStatus": "Tous les statuts des messages",
  "ccm.satisfactionlevel.message.nopara": "La description ne peut pas être vide.",
  "ccm.speciallist.label.startDate": "Date d'entrée en vigueur",
  "ccm.transserver.label.cssproxynotinwhite": "L'opération a échoué. Contactez l'administrateur système pour ajouter l'adresse du serveur proxy à la liste de confiance du serveur CSS.",
  "ccm.agent.restReason.pleaseRefresh": "Synchroniser et réessayer.",
  "ccm.whatsapp.whatsApp.channel": "Chaîne WhatsApp",
  "ccm.callreason.label.input": "s'il vous plaît entrer",
  "ccm.agent.ten.DSTEndDate": "Date de fin DST",
  "ccm.transtask.label.saveexit": "Impossible d'ajouter un serveur de vidange parce que le nom du serveur existe déjà.",
  "ccm.custdata.msg.typeColumnNumValidateFailed": "Le nombre de champs dépasse la limite supérieure : Un seul champ d'index, et un maximum de 30 champs non chiffrés et 20 champs chiffrés sont autorisés.",
  "ccm.agent.contact.reasonCode": "Code de raison",
  "ccm.satisfactionconfig.message.groupcreate": "Ajouter",
  "ccm.urlconfig.label.oauth.voicemsg.alerttip": "Si une URL de rappel est transférée lorsque l'API de création de notification vocale est appelée et que les informations d'authentification suivantes sont configurées, ces informations sont utilisées pendant le rappel de l'URL lors de l'achèvement de la notification vocale. Dans le cas contraire, aucune authentification n'est effectuée pendant le callback.",
  "ccmanagement.satisfaction.strategy.accepttime.timeStart": "Heure de début",
  "ccm.sysparam.config.search": "interrogation",
  "ccm.certificate.management.certificatecrlfile.suffixerror": "Le fichier Liste de révocation de certificats doit être au format.crl.",
  "ccm.agent.ten.createDate": "Temps de création",
  "ccm.custdata.button.abandon": "Rejeter",
  "ccm.label.contactexport.parainvalid": "Impossible de vérifier les conditions d'exportation.",
  "ccm.agent.restrecord.reststatus.resting": "Repos",
  "ccm.note.config.agent.page.ring.choose": "Sélectionnez une sonnerie.",
  "ccm.agent.contact.playfail": "La lecture a échoué.",
  "ccm.transtask.label.start": "Démarrer",
  "ccm.agent.ten.allowedTTSAndASRCallNum": "Nombre de canaux IVR intelligents",
  "ccm.agent.contact.sameContactRecord": "Enregistrement de contact du même appel",
  "ccm.agent.calledroute.nmsdata.error": "Impossible de synchroniser les données avec le NMS. Les données de configuration de partie appelée du NMS sont incompatibles avec les données locales du CCM.",
  "ccm.agent.sysparamconfig.worknonumwarn": "Le nombre d'identifiants d'agent ne peut pas dépasser 10.",
  "ccm.agent.operation.LISTEN": "Vérification de la qualité de l'écoute",
  "ccm.speciallevel.speciallist.remark": "Remarque",
  "ccm.speciallist.msg.willdelete": "Êtes-vous sûr de vouloir supprimer ces éléments {limit} ?",
  "ccm.common.msg.willdelete": "Êtes-vous sûr de vouloir supprimer les {limit} enregistrements sélectionnés?",
  "ccm.calledconfig.title.numbersadded": "Numéros ajoutés",
  "ccm.ivr.label.processdesc": "Description du processus",
  "ccm.custdata.operlog.failed": "Échec",
  "ccm.agent.message.willClearAgents": "Êtes-vous sûr de vouloir effacer les agents sélectionnés ?",
  "ccm.pageurls.select.urlParameter": "URL GET REQUEST Paramètre",
  "ccm.note.config.error": "Erreur",
  "ccm.sysparam.error.validate.notmatchruleregex": "Le paramètre d'entrée ne correspond pas à l'expression rationnelle de la règle de vérification",
  "ccm.agent.operation.mediaswitch": "Commutateur audio/vidéo",
  "ccm.contact.customer.config.fonts": "Police",
  "ccm.sysparam.config.agent.title": "Paramètres de l'agent",
  "ccm.accesscode.placeholder.accessdesc": "Veuillez entrer une description du code d'accès.",
  "ccm.agent.ten.expiryDate": "Date d'expiration",
  "ccm.agent.sysparamconfig.refreshAllSysParamFail": "Erreur système, échec du rafraîchissement.",
  "ccm.transserverparam.label.ak": "AK",
  "ccm.agent.softPhoneStatusNode1": "Nœud 1",
  "ccm.agent.softPhoneStatusNode2": "Nœud 2",
  "ccm.speciallevel.speciallist.restrictMode": "Mode de restriction",
  "ccm.agent.contact.nohaveevent": "Aucun événement de ce genre",
  "ccm.agent.label.authType": "Mode d'authentification",
  "ccm.speciallist.msg.modify": "Modifier",
  "ccm.common.label.no": "Non",
  "ccm.satisfactionconfig.message.sms": "SMS",
  "ccm.taskrecord.message.reexecutetranstasksuccess": "Ré-exécution réussie.",
  "ccm.agent.leavemessage.processfail": "Impossible de traiter le message.",
  "ccm.agent.button.unregister": "Se désinscrire",
  "ccm.agent.contact.talkCallbackFlag": "Discuter drapeau",
  "ccm.agent.contact.begintime": "Heure de début",
  "ccm.agent.calledroute.createsuccess": "Ajouté avec succès.",
  "ccm.agent.button.cancelconfig": "Annuler la configuration",
  "ccm.agent.pageurls.errorupdate": "La mise à jour a échoué.",
  "ccm.custdata.msg.operationFailed": "L'opération a échoué.",
  "ccm.satisfactiontemplate.icontitle": "Mettre à jour le contenu du modèle en fonction du niveau de satisfaction.",
  "ccm.satisfactionconfig.message.smstemplate": "Modèle SMS",
  "ccm.agent.restReason.createReason": "Ajouter une raison de repos",
  "ccm.urlconfig.message.modifyfail": "Impossible de modifier les urls",
  "ccm.urlconfig.message.overlimit.appKey": "La clé d'application dans la configuration d'appel bidirectionnel ITA ne peut pas dépasser 128 caractères.",
  "ccm.agent.leavemessage.assignSuccess": "Le message est attribué avec succès",
  "ccm.agent.contact.businesshungup": "Transfert à l'agent",
  "ccm.agent.label.param.index": "Non.",
  "ccm.callout.message.isconfreset": "Êtes-vous sûr de vouloir réinitialiser l'appelant d'appel ?",
  "ccm.verifyidentity.fail.VERIFYRESULTPARSEERROR": "Paramètre de résultat d'authentification incorrect.",
  "ccm.agent.calledroute.create.skill.none": "La file d'attente de compétences référencée par la configuration de la partie appelée n'existe pas.",
  "ccm.agent.button.create": "Nouveau",
  "ccm.transtask.label.certTip.cssCert": "Veuillez sélectionner un certificat CSS.",
  "ccm.agent.label.keepVoiceSelect": "Veuillez sélectionner une tonalité de maintien",
  "ccm.transtask.label.wednesday": "Mercredi",
  "ccm.agent.message.pageLayout": "Mise en page",
  "ccm.agent.message.name.limit": "Le nom est trop long.",
  "ccm.certificate.management.certificatefile.certfile.sizeerror": "La taille du fichier de certificat ne peut pas dépasser 20 Ko.",
  "ccm.agent.message.agentCreateFail": "Impossible de créer l'agent dans CTI.",
  "ccm.agent.ten.maxCalls": "Max. Appels vocaux simultanés",
  "ccm.agent.contact.whatsapp": "WhatsApp",
  "ccm.pageconfig.label.layoutType": "Type de mise en page",
  "ccm.satisfactionlevel.message.leastcalltype": "Veuillez sélectionner au moins un type d'appel.",
  "ccm.agent.contact.agentPresent": "Numéro de l'agent",
  "ccm.calledconfig.message.duplicatecalled": "Dupliquer appelé",
  "ccm.label.chatRecord.channeltypeinnull": "Sélectionnez les types de canaux.",
  "ccm.satisfactionconfig.satisfaction.process": "Flux de l'enquête de satisfaction",
  "ccm.calledroute.tip.selectivrexception": "Impossible d'interroger les flux IVR dans CTI.",
  "ccm.agent.softPhoneStatus.UnRegistered": "Non Enregistré",
  "ccm.agent.accesscode.description.notempty": "La description du code d'accès ne peut pas être vide.",
  "ccm.agent.contact.sms": "SMS",
  "ccm.agent.contact.Instagram": "Instagram",
  "ccm.agent.contact.Telegram": "Telegram",
  "ccm.transtask.label.chatRecord": "Données de chat multimédia",
  "ccm.transtask.label.intelligentInspection": "Données d'inspection AI",
  "ccm.satisfactionconfig.message.acceptdate": "Date de traitement",
  "ccm.urlconfig.modify.authtype2": "OAuth 2.0",
  "ccm.agent.pageurlparam.relSysParamName": "Paramètre de données système associé",
  "ccm.urlconfig.modify.authtype3": "Aucun",
  "ccm.urlconfig.modify.authtype1": "Clé partagée",
  "ccm.agent.message.agentnummaxoperation": "Sélectionnez jusqu'à 100 agents.",
  "ccm.agent.label.basicInfo": "Informations de base",
  "ccm.speciallist.msg.datecompareerror": "La date d'expiration doit être postérieure à la date d'entrée en vigueur.",
  "ccm.transtask.label.task.chatrecord.uniqueness": "Des tâches existent pour certains types de canaux sélectionnés.",
  "ccm.basicdata.msg.deletefail": "Impossible de supprimer les données de base.",
  "ccm.satisfactionconfig.audio.and.video.remark": "Lorsque les commutateurs de l'enquête de satisfaction de la voix et du SMS de l'IVR sont activés et que l'agent raccroche en premier, aucun sondage de satisfaction de SMS ne sera mené si un sondage de satisfaction de la voix de l'IVR a été mené",
  "ccm.sysparam.config.value": "Valeur de paramètre",
  "ccm.agent.contact.voiceVidio": "Audio/Vidéo",
  "ccm.custdata.option.maskStyle.maskDate": "Masque de date",
  "ccm.recognition.label.appSecret.download": "télécharger SK",
  "ccm.agent.operation.REST": "Demande de repos",
  "ccm.calledconfig.message.savemobileagentfail": "Impossible d'enregistrer l'association de la configuration de l'agent mobile",
  "ccm.verifyidentity.verifyResult": "Résultat de l'authentification",
  "ccm.certificate.management.certificatefile.uploadcertfile": "Télécharger le fichier de certificat",
  "ccm.contact.customer.config.label": "Contacter la zone d'information client",
  "ccm.urlconfig.message.modifysuccess": "Succès de modification des urls",
  "ccm.callreason.label.mark": "Remarques",
  "ccm.satisfactionconfig.message.channelused": "Le groupe de stratégies est référencé par un canal et ne peut pas être supprimé.",
  "ccm.transtask.label.datapath.tips": "Chemin OBS",
  "ccm.custdata.msg.importDataSuccess": "Exportation des données...Voir les détails dans Importer le résultat.",
  "ccm.agent.label.keepVoiceDefault": "Maintien du ton par défaut",
  "ccm.agent.label.selectSkill": "Sélectionner la file d'attente de compétences",
  "ccm.transserver.label.cssServerPort": "Port de service CSS",
  "ccm.urlconfig.label.noramalOrITA.input": "Configuration des paramètres ITA",
  "ccm.agent.label.keepVoiceConfig": "Configuration de maintien et d'attente",
  "ccm.transtask.label.agentOperationReport": "Rapport d'opération de connexion de l'agent",
  "ccm.agent.contact.createbother": "Créer un enregistrement Bother",
  "ccm.custdata.option.columnType.dictionary": "Dictionnaire",
  "ccmanagement.satisfaction.strategy.accepttime.minute": "Minute",
  "ccm.agent.calledroute.search.devicedesc": "Veuillez entrer une description de l'appareil.",
  "ccm.agent.pageurls.isconfdelete": "Êtes-vous sûr de vouloir le supprimer ?",
  "ccm.agent.contact.updatedate": "Date de mise à jour",
  "ccm.agent.log.downloadVideoTerminal": "Téléchargez le terminal vidéo OpenEye",
  "ccm.contact.detail.sendmessage": "Envoyer",
  "ccm.agent.skill.nameInfo": "Nom de file d'attente de compétences :",
  "ccm.satisfactionconfig.message.strategygroupmemconfig": "Configuration de la stratégie",
  "ccm.agent.Transfer.CallForwarding": "Transfert d'appel",
  "ccm.pageurls.select.postParameter": "Paramètre URL POST REQUEST",
  "ccm.agent.message.modifySkillConfFail": "Impossible de modifier les informations de la file d'attente de compétences.",
  "ccm.agent.calledroute.gateway.fail": "Impossible de synchroniser les informations de la plate-forme CTI. Veuillez vérifier la configuration de la passerelle.",
  "ccm.urlconfig.label.oauth.label": "Informations d'autorisation OAuth 2.0",
  "ccm.agent.restrecord.rest.endTime": "Heure de fin de repos",
  "ccm.pageinterface.optionalAttribute": "Attribut facultatif",
  "ccm.contact.customer.config.datasource.customData": "Données locales",
  "ccmanagement.satisfaction.strategy.deleteselect": "Êtes-vous sûr de vouloir supprimer les stratégies sélectionnées ?",
  "ccm.agent.contact.callerfailed": "Échec de l'appelant sortant",
  "ccm.agent.resetskillrecord.skillafteradjust": "File d'attente de compétences après ajustement",
  "ccm.agent.message.selectSkills": "Veuillez sélectionner une file d'attente de compétences.",
  "ccm.sysparam.config.resetAbb": "Reset",
  "ccm.agent.label.airecog": "Reconnaissance intelligente",
  "ccm.certificate.management.certificatefile.suffixerror": "Seuls les fichiers au format jks,cer,crt,pem,pfx,p12 peuvent être téléchargés.",
  "ccm.agent.message.inputWeight": "Entrez une valeur de poids.",
  "ccm.agent.leavemessage.assignFailed": "Impossible d'allouer le message.",
  "ccm.agent.restReason.selectRestReasons.limit": "Un maximum de 10 raisons de repos peuvent être supprimées par lots.",
  "ccmanagement.satisfaction.strategy.deletefail": "La suppression a échoué.",
  "ccm.vcallcenter.config.popup.authentication.failed": "L'authentification a échoué.",
  "ccm.satisfactionconfig.message.smsfeedbacktype": "SMS",
  "ccm.agent.contact.voiceBot": "Voix",
  "ccm.custdata.msg.msgValidateFailed": "Les caractères spéciaux ne sont pas autorisés.",
  "ccm.agent.label.deleteAgentFailed": "Une file d'attente de compétences a été liée à un compte professionnel. Supprimez d'abord la configuration de la file d'attente de compétences de l'agent.",
  "ccm.transtask.label.discard": "Rejeter",
  "ccm.agent.Transfer.deviceDesc": "Périphérique de destination",
  "ccm.dual.call.record.callCompletionRate": "Taux d'achèvement des appels",
  "ccm.basicdata.error.capacitylimit": "La capacité de données a atteint la limite supérieure {0}",
  "ccm.agent.ten.ttschannelnums": "Quantité TTS",
  "ccm.speciallist.button.import": "Importer",
  "ccm.speciallist.title.import": "Importer une liste spéciale",
  "ccm.nms.satisfactionservice.buttonNumber": "Le nombre de clés de l'enquête de satisfaction n'est pas supérieur à 5 et pas inférieur à 3.",
  "ccm.recognition.label.modifymobileagentclose": "Les fonctions d'agent mobile et d'appel bidirectionnel en un clic ne sont pas activées pour l'espace locataire !",
  "ccm.contact.customer.config.style": "Style d'affichage",
  "ccm.dual.call.record.unexpected.end": "Fin inattendue",
  "ccm.agent.contact.playdefault": "La durée d'enregistrement est vide. Voulez-vous utiliser la durée par défaut 60 minutes ?",
  "ccm.satisfactionconfig.sms.switch.remark": "Après l'activation de ce commutateur, un message SMS d'enquête de satisfaction sera envoyé au client après la fin de l'appel",
  "ccm.satisfactionconfig.satisfaction.sending.channel": "Canal d'envoi de SMS",
  "ccm.agent.message.refreshSkillException": "Actualiser l'exception de compétence.",
  "ccm.recognition.label.policy.information": "Informations sur la politique",
  "ccm.recognition.label.editPolicy.information": "Modifier les informations de stratégie",
  "ccm.agent.leavemessage.status.toBeAssigned": "À allouer",
  "ccm.agent.leavemessage.status.toBeProcessed": "À traiter",
  "ccm.speciallist.msg.applyTime": "Durée de l'application",
  "ccm.agent.contact.callershutdown": "Fin de l'appel, arrêt de l'appelant",
  "ccm.satisfactionlevel.message.satistip": "Veiller à ce que les niveaux de satisfaction utilisés dans le sondage sur la satisfaction de chaque canal soient les mêmes. tels que l'enquête de satisfaction vocale, l'enquête de satisfaction par SMS, et l'enquête de satisfaction sur le Web.",
  "ccm.urlconfig.label.noramalOrITA": "Sélectionnez le type d'appel bidirectionnel CEC ou d'appel bidirectionnel ITA.",
  "ccm.anonymous.message.fail.close": "Impossible de fermer le drapeau. vérifier la configuration CTI",
  "ccm.taskrecord.label.executeBeginTime": "Heure de début",
  "ccm.agent.label.usedMode.sipAccount": "Compte Sip",
  "ccm.callback.url.callnotify": "Configuration du rappel de notification vocale",
  "ccm.callreason.label.allreason": "Toutes les raisons",
  "ccmanagement.satisfactionconfig.acceptdate.timeerror.laterThanEndTime": "La date de début doit être antérieure à la date de fin.",
  "ccm.agent.ten.mediaagents": "Nombre d'agents multimédia",
  "ccm.agent.contact.callernook": "Appelant sortant sans réponse",
  "ccm.satisfactionconfig.message.nofeedbacktype": "Pas d'enquête",
  "ccm.contact.customer.config.datasource.callData": "Données associées aux appels",
  "ccm.custdata.button.detail": "Détails",
  "ccm.speciallist.msg.confirmdelete": "Êtes-vous sûr de supprimer la liste spéciale sélectionnée ?",
  "ccm.label.contactexport.currentfield": "Champ dans la liste actuelle",
  "ccmanagement.tenantspace.option.month_12": "Décembre",
  "ccmanagement.tenantspace.option.month_11": "Novembre",
  "ccmanagement.tenantspace.option.month_10": "Octobre",
  "ccm.agent.ten.tenantId": "ID de l'espace locataire",
  "ccmanagement.satisfaction.strategy.saveerror": "Échec",
  "ccm.custdata.msg.inputTableName": "Saisissez un nom de table.",
  "ccm.agent.label.ifBusyNoAnswerAgent": "Définir les agents qui ne répondent pas pendant une longue période à l'état occupé",
  "ccm.agent.label.asyncUserDispatchMode": "Condition de récupération de message hors ligne",
  "ccm.agent.label.asyncUserDispatchMode.zero": "par nombre de lots",
  "ccm.agent.label.asyncUserDispatchMode.one": "par le nombre de lots et la compétence",
  "ccm.agent.label.asyncUserDispatchMode.two": "par nombre de lots et statut en ligne",
  "ccm.agent.label.asyncUserDispatchMode.three": "par le nombre de lots, la compétence et le statut en ligne",
  "ccm.agent.label.asyncUserDispatchMode.four": "par le nombre de lots, l'agent, la compétence et le statut en ligne",
  "ccm.transserverparam.label.password": "Mot de passe",
  "ccm.agent.videoDownload.disabled": "La fonction de téléchargement vidéo est désactivée",
  "ccm.common.label.yes": "Oui",
  "ccm.basicdata.msg.deletesuccess": "Les données de base sont supprimées avec succès.",
  "ccm.callreason.label.status": "Statut",
  "ccm.contact.customer.config.writeBack": "Rédaction de l'enregistrement de contact",
  "ccm.agent.label.ccRoleId": "Rôle de la plateforme",
  "ccm.case.title.selectWorkOrder": "Sélection d'une catégorie d'ordre de travail",
  "ccm.agent.title.skillModify": "Modifier la file d'attente de compétences",
  "ccm.pageinterface.pleaseSelectInterfaceType": "Sélectionnez un type d'interface.",
  "ccm.speciallist.msg.withoutauth": "Vous n'avez pas l'autorisation d'opération.",
  "ccm.agent.label.ifReleaseNoAnswerCall": "Relâcher les appels non répondus pendant une longue période",
  "ccm.speciallist.msg.fail": "Échec",
  "ccm.agent.pageurl.httptip": "(Pas sûr)",
  "ccm.certificate.management.update.cancel.crlfile": "Êtes-vous sûr de vouloir annuler la mise à jour du fichier ?",
  "ccm.agent.label.withinSevenDays": "Dans les 7 jours",
  "ccm.speciallist.label.operator": "Opérateur",
  "ccm.agent.label.waitTime": "Durée(s) d'attente",
  "ccm.agent.contact.userhungup": "Utilisateur",
  "ccm.satisfactionconfig.message.memberstrategyconfig": "Configuration des membres du groupe de stratégie",
  "ccm.agent.label.ivrChoice": "Sélectionner IVR",
  "ccm.agent.message.noReleaseFail": "Seules les données non publiées peuvent être supprimées.",
  "ccm.calledconfig.label.add": "Ajouter",
  "ccm.sysparam.error.validate.notmatchrule": "Le paramètre d'entrée ne correspond pas à la règle de vérification",
  "ccm.custdata.msg.missRequiredColumn": "Une table personnalisée ne peut être libérée que si elle contient au moins un champ d'index et d'autres champs d'information.",
  "ccm.agent.operation.RELEASE": "libération",
  "ccm.sysparam.error.validate.watermarkinfo.notmatchruleregex": "Le paramètre permet seulement de 0 à 20 caractères anglais contenant des lettres majuscules et minuscules, des chiffres ou -_/@.",
  "ccm.systemparam.error.save": "Impossible d'enregistrer les paramètres.",
  "ccm.agent.ten.timezoneOffset": "Décalage du fuseau horaire",
  "ccm.dual.call.record.called": "Numéro appelé",
  "ccm.agent.addbother.success": "Réussit à créer l'enregistrement de dérangement.",
  "ccm.transserver.label.obsaddr": "Adresse OBS",
  "ccm.callreasonconfig.message.deletecallreason.success": "Supprimé avec succès.",
  "ccmanagement.tenantspace.option.weeklist_tidtolast": "Dernier avant deux",
  "ccm.agent.operation.CALLINNER": "Appel interne",
  "ccm.agent.pageurlparam.paramLengthLimit": "La longueur du paramètre ne peut pas dépasser 64 caractères",
  "ccm.agent.contact.multimedia.wechat": "Multimédia-WeChat",
  "ccm.recognition.label.orderfail": "L'abonnement a échoué.",
  "ccm.transtask.label.savefail": "Impossible d'ajouter un serveur de vidange car un maximum de serveurs de vidange {0} sont autorisés.",
  "ccm.custdata.label.valueRange": "Gamme de valeurs",
  "ccm.agent.operation.ADJUSTSKILL": "Ajuster la file d'attente de compétences",
  "ccm.agent.leavemessage.view": "Voir les messages",
  "ccm.speciallist.msg.norecord": "Aucun enregistrement ne peut être exporté",
  "ccm.urlconfig.message.addsuccess": "Succès d'ajouter les urls",
  "ccm.recognition.label.appSecret.download.SK.io.error": "Impossible de télécharger le SK. Exception d'E/S.",
  "ccm.contactitemconfig.label.contact.recorddata": "Article de données d'enregistrement de contact",
  "ccm.speciallist.msg.error": "Erreurs",
  "ccm.agent.pageurls.config": "Config",
  "ccm.custdata.button.moveBottom": "bas",
  "ccm.custdata.msg.deletedItemNotExist": "L'élément de suppression n'existe pas. Actualisez la page et réessayez.",
  "ccm.agent.calledroute.create.accesscode.repeat": "La combinaison du code d'accès au système et du code d'extension pour la partie appelée doit être unique.",
  "ccm.custdata.option.isMaskDisplay.no": "Non",
  "ccm.transtask.label.taskupload": "Serveur de téléchargement",
  "ccm.transtask.label.expirationDuration": "Période de conservation des fichiers de vidange",
  "ccm.agent.calledroute.accesscode": "Code d'accès",
  "ccm.transtask.label.View": "voir",
  "ccm.dual.call.record.startTime": "Heure à laquelle un appel est lancé",
  "ccm.contact.tip.no.workname": "L'agent correspondant n'est pas obtenu!",
  "ccm.ucconfig.message.userId": "ID de l'annuaire (locataire)",
  "ccm.speciallist.msg.transferout": "Le nombre d'enregistrements historiques a atteint {0}",
  "ccm.satisfactionconfig.message.channelname": "Canal",
  "ccm.urlconfig.label.input.appSecret": "SecretKey",
  "ccm.agent.updateSoftPhone.reason": "la raison",
  "ccm.agent.contact.download.frequently": "Vous avez essayé de le télécharger aussi mai fois. Veuillez réessayer plus tard.",
  "ccm.recognition.label.modifymobileagentfailed": "Impossible de modifier le statut de l'agent mobile.!",
  "ccmanagement.satisfaction.strategy.fail.calltime.overlimit": "Un maximum de 100 stratégies de durée d'appel peuvent être sauvegardées.",
  "ccm.agent.contact.hungup": "Soirée Hang-up",
  "ccm.agent.label.skillId": "ID de file d'attente de compétence",
  "ccm.note.config.save": "Enregistrer",
  "ccm.agent.pageurlparam.checkSpecialChar": "Entrez des chiffres, des lettres, des (_), de soulignement et des traits d'union (-). La (.) de point doit être au milieu.",
  "ccm.agent.pageurlparam.checkSpecialChar.noPoint": "Entrez des chiffres, des lettres, des (_), de soulignement et des traits d'union (-). La (.) de point doit être au milieu",
  "ccm.agent.pageurlparam.checkSpecialChar.name": "Entrez des chiffres, des lettres, des (_), de soulignement et des traits d'union (-)",
  "ccm.agent.contact.otherhungup": "Autres",
  "ccm.agent.contact.multimedia.line": "Ligne multimédia",
  "ccm.sysparam.error.validate.ruleregexinvalid": "Valider une erreur de syntaxe de règle",
  "ccm.speciallist.button.history": "Transfert vers l'historique",
  "ccm.agent.pageurls.confonlynaming": "Assurez-vous que le nom est unique.",
  "ccm.agent.ten.email": "Courriel",
  "ccm.agent.message.description.limit": "La description de la file d'attente de compétences est trop longue",
  "ccm.page.select.blockLayout": "Disposition des blocs",
  "ccm.agent.sysparamconfig.resetAllParamsFail": "Le fichier de configuration des paramètres est manquant. Veuillez vérifier.",
  "ccm.agent.accesscode.description.require": "La description du code d'accès ne peut pas être vide.",
  "ccm.sysparam.url.unsafe": "(non sécuritaire)",
  "ccm.agent.sysparamconfig.checkWebhookValidityDays": "La certification Webhook doit être valide entre 7 et 365 jours",
  "ccm.asr.create.case": "Créer un cas",
  "ccm.transserver.delete.css.fail": "Impossible de supprimer les informations de configuration CSS.",
  "ccm.speciallist.msg.besureexport": "Êtes-vous sûr de vouloir exporter les données ?",
  "ccm.speciallevel.msg.selectspecialLevel": "Veuillez sélectionner un niveau spécial",
  "ccm.agent.label.skillName": "Nom de la compétence",
  "ccm.agent.contact.name": "Nom du document",
  "ccm.agent.contact.searchName": "Nom du document",
  "ccm.agent.agentcontact.error": "Échec de la requête",
  "ccm.agent.label.positiveEmotion": "Bon travail, continue comme ça.",
  "ccm.agent.restReason.edit": "Modifier une raison de repos",
  "ccm.urlconfig.message.missing.appIdOrAppSecret": "La clé d'accès ou la clé secrète ne sont pas définies dans la configuration d'appel bidirectionnel ITA.",
  "ccm.urlconfig.label.oauth.oauth2loginurl.illegal": "URL de connexion non valide.",
  "ccm.satisfactionconfig.message.memberstrategychoose": "Politiques disponibles",
  "ccm.worknobeforevoice.config.choosenote": "Sélectionnez une valeur pour Voice Before Employee ID.",
  "ccm.agent.label.deleteCtiFailed": "La suppression des données de la plate-forme Cti a échoué.",
  "ccm.agent.button.restSoftPhonePwd": "Réinitialiser le mot de passe du téléphone logiciel",
  "ccm.agent.message.export": "Exporter",
  "ccm.agent.message.export.skill": "Exporter la file d'attente de compétences",
  "ccm.agent.info.message.export": "Exportation des informations de l'agent",
  "ccm.verifyidentity.noMoreThan30Days": "L'intervalle de temps ne peut excéder 30 jours.",
  "ccm.speciallist.msg.download": "Téléchargement du modèle d'importation de liste spéciale",
  "ccm.speciallist.msg.import.template": "Modèle d'importation de liste spéciale",
  "ccm.agent.button.config": "Configurer",
  "ccm.custdata.operlog.displayMask": "Masque d'affichage",
  "ccm.agent.message.agentnotexist": "L'agent n'existe pas. Actualisez la page Gestion des agents et réessayez.",
  "ccm.certificate.management.search.scenename": "S'il vous plaît entrer Scène de certificat",
  "ccm.agent.message.createSkillConfFail": "Impossible de créer les informations de la file d'attente de compétences.",
  "ccm.satisfactionconfig.message.audiochannel": "Voix ou canal vidéo",
  "ccm.agent.leavemessage.auditAccount": "Compte d'audit",
  "ccm.satisfactionconfig.message.calltypeout": "Appel sortant",
  "ccm.pageinterface.interfaceType": "Type d'interface",
  "ccm.agent.contact.ivrhungup": "Transférer à IVR",
  "ccmanagement.tenantspace.option.weekday_thu": "Jeudi",
  "ccm.agent.operation.CANCELWHISPER": "Annuler Whisper",
  "ccm.contact.customer.config.datasource.objId": "Objet de source de données",
  "ccm.recognition.label.intellrecogn": "Reconnaissance intelligente",
  "ccm.verifyidentity.fail.INPUTNULLERROR": "Les paramètres de la requête ne peuvent pas être vides.",
  "ccm.contact.label.inactive": "Expiré",
  "ccm.agent.label.negativeEmotion": "Mauvaise formulation. Utilisez un script approprié.",
  "ccm.sysparam.error.validate.notmatchrulealpha": "Veuillez entrer des caractères alphanumériques ou des traits de soulignement, et ne commencer que par des lettres ou des traits de soulignement",
  "ccm.urlconfig.label.radio.radioType2": "Appel bidirectionnel ITA",
  "ccm.agent.label.CommomAgent": "Agent commun",
  "ccm.callout.lable.reset": "Réinitialiser",
  "ccm.agent.label.timeRemaining": "Expiration du mot de passe (jour)",
  "ccm.transtask.label.week": "Semaine",
  "ccm.speciallist.msg.maxCountQuery": "Le volume de données actuel est trop important. Le système affiche uniquement les derniers enregistrements de données {0}.",
  "ccm.agent.contact.downloadvideo": "Télécharger la vidéo",
  "ccm.agent.pageurls.delete": "Supprimer",
  "ccm.speciallist.msg.operationsuccess": "Opération réussie",
  "ccm.urlconfig.label.radio.radioType1": "Appel bidirectionnel CEC",
  "ccm.recognition.label.characteristics": "Fonctionnalité",
  "ccm.satisfactionconfig.message.updatesuccess": "Mise à jour réussie de la configuration de l'enquête de satisfaction",
  "ccm.botherlist.label.approveStatus.rejected": "Rejeté",
  "ccm.urlconfig.modify.oauth.sk.tooshort": "Le SK doit contenir au moins 16 caractères.",
  "ccm.certificate.management.certificatefile.suffixerror.suffix": "ou extension vide.",
  "ccm.pageinterface.objectName": "Nom de l'objet",
  "ccm.agent.sysparamconfig.refreshSuccess": "La réinitialisation en un clic a réussi.",
  "ccm.urlconfig.label.talkurl": "Discuter Url",
  "ccm.agent.label.fixedLine": "Ligne fixe/numéro mobile",
  "ccm.agent.operation.ADJUSTVIDEOMODE": "Ajustement de la taille de la fenêtre vidéo",
  "ccm.calledconfig.message.samename": "Le même nom existe déjà.",
  "ccm.satisfactionconfig.message.satis": "Sondage de satisfaction",
  "ccm.agent.pageurls.independentlynosupported": "La configuration de page interne n'est pas prise en charge pour une ouverture indépendante.",
  "ccm.querycustdata.msg.noData": "Pas de données",
  "ccm.agent.message.airecognull": "Le drapeau de reconnaissance intelligente est vide.",
  "ccm.note.config.policy.systemdefault": "Système par défaut",
  "ccm.custdata.label.sucNum": "Nombre d'enregistrements réussis",
  "ccm.transtask.label.cantsave": "Le serveur ne peut pas être modifié car il a été référencé par une tâche dump.",
  "ccm.agent.contact.noAuth": "Pas d'autorisation.",
  "ccm.agent.contact.download": "Téléchargement",
  "ccm.agent.leavemessage.serviceContactNumber": "Numéro de contact",
  "ccm.agent.label.usedMode.phone": "Numéro de téléphone",
  "ccm.agent.message.addSkillFail": "L'ajout d'une compétence a échoué.",
  "ccm.agent.message.addSkillSuccess": "File d'attente de compétences ajoutée avec succès.",
  "ccm.agent.message.maxSkillFail": "La politique de file d'attente de compétences peut contenir jusqu'à 100 éléments.",
  "ccm.agent.ten.tenantInfor": "Informations sur les locataires",
  "ccm.agent.restReason.confOnlyCauseCode": "L'opération a échoué. Le code de raison existe déjà.",
  "ccm.agent.message.skillConfSuccess": "Les informations de file d'attente de compétences sont configurées avec succès.",
  "ccm.taskrecord.label.executeSuccess": "Succès",
  "ccm.speciallist.msg.crateerror": "L'ajout a échoué",
  "ccm.urlconfig.label.relurl.callback.alerttip": "Si aucune URL de rappel n'est transférée lorsque l'API de création d'appel bidirectionnel est appelée, cette URL est rappelée lors de la publication d'un appel bidirectionnel. Sinon, l'URL transférée est rappelée.",
  "ccm.agent.contact.recordtime": "Durée(s) d'enregistrement",
  "ccm.transtask.label.skillReport": "Rapport de trafic de la file d'attente des compétences",
  "ccm.custdata.operlog.addExportTask": "Nouvelle tâche d'exportation",
  "ccm.transtask.label.skillSummaryReport": "Rapport de synthèse de trafic",
  "ccm.agent.contact.QUERU_VCCINFO_FAIL_CODE": "Les informations de centre d'appel virtuel sont vides.",
  "ccm.label.contactexport.selectall": "Tous",
  "ccm.speciallist.label.redlist": "Liste rouge",
  "ccm.agent.pageurls.success": "Succès",
  "ccm.transserver.label.obs": "Serveur OBS",
  "ccm.agent.label.queuingCallsMaxNumRange": "Valeur [0-10000]",
  "ccm.note.config.policy.custom": "Personnalisation",
  "ccm.transtask.label.agentReport": "Rapport sommaire des performances de l'agent",
  "ccm.vcallcenter.config.popup.currentaccount.pwd": "Mot de passe du compte actuel",
  "ccm.transtask.label.contactrcddata": "Champs dans la liste des enregistrements de contact",
  "ccm.urlconfig.message.oauth.tip.warning": "Aucune authentification d'URL de rappel ne peut entraîner des risques de sécurité. Voulez -vous vraiment utiliser ce mode d&apos;authentification & #160;?",
  "ccm.agent.accesscode.description": "Description du code d'accès",
  "ccm.idauth.title.idauthconfig": "Processus d'authentification d'identité",
  "ccm.agent.label.callDispatchModel.best": "Optimal",
  "ccm.agent.label.restQueueRule.applyFirst": "Agent qui demande d'abord un repos",
  "ccm.custdata.msg.duplicateColumnName": "Dupliquer le nom du champ. Renommez-le.",
  "ccm.contactitemconfig.label.data.extended.Type.DataDictionary": "Dictionnaire de données",
  "ccm.agent.calledroute.create": "Nouveau",
  "ccm.agent.operation.searchcountid": "Compte de service",
  "ccm.callreason.label.disable": "Désactiver",
  "ccm.transserver.label.objobs": "OBS",
  "ccm.urlconfig.label.appsecret": "Clé partagée",
  "ccm.urlconfig.label.key": "Clé secrète",
  "ccm.agent.resetskillrecord.adjustbusinessaccount": "Ajuster le compte de service",
  "ccm.transtask.label.save": "OK",
  "ccm.callout.message.resetfail": "Impossible de réinitialiser les paramètres.",
  "ccm.speciallist.msg.successNum": "{limit} succès",
  "ccm.agent.message.pageNumFail": "Un maximum de {0} pages est autorisé.",
  "ccm.agent.recall.agent.status.invalid": "L'agent n'est pas dans l'état inactif ou occupé et ne peut pas rappeler.",
  "ccm.callback.url.callback": "Configuration du rappel d'appel bidirectionnel",
  "ccm.transtask.label.taskid": "ID de la tâche",
  "ccm.contact.customer.config.dataItemError": "Le nom ou l'ID de l'élément de données doit être unique.",
  "ccm.agent.button.batchmodify": "Modification par lots",
  "ccm.urlconfig.label.input.appId": "AccessKey",
  "ccm.custdata.operlog.importdata": "Importer des données",
  "ccm.agent.button.restores": "Restaurations",
  "ccm.agent.label.skillWeightTip": "Lorsqu'un agent entre dans plusieurs files d'attente, les appels dans la file d'attente de compétences avec le poids le plus élevé sont traités en premier.",
  "ccm.agent.restReason.createFailed": "L'ajout a échoué.",
  "ccm.urlconfig.message.overlimit.domainName": "Le nom de domaine dans la configuration d'appel bidirectionnel ITA ne peut pas dépasser 128 caractères.",
  "ccm.agent.label.maxTalkTime": "Durée(s) maximale(s) d'appel",
  "ccm.custdata.option.maskStyle.retainFirst4": "Réserver les quatre premiers chiffres",
  "ccm.transserverparam.label.isagent": "Activer le proxy",
  "ccmanagement.satisfaction.strategy.fail.accepttime.inputlength": "La durée de l'heure de début ou de fin ne peut pas dépasser la limite supérieure.",
  "ccm.agent.tips.confSuccess": "Configuration réussie.",
  "ccm.agent.tips.noagent": "Aucun agent n'existe dans le segment de nombre sélectionné.",
  "ccm.speciallist.label.operTime": "Temps de création",
  "ccm.agent.operation.noMoreThan30Days": "L'intervalle de temps ne peut excéder 30 jours.",
  "ccm.agent.contact.fiveg": "5G RCS",
  "ccm.satisfaction.leveltips": "Note : Tous les canaux utilisent des configurations de satisfaction unifiées.",
  "ccm.sysparam.config.reset.multiple.error": "Les paramètres suivants ne peuvent pas être réinitialisés :",
  "ccm.agent.message.quotingDataOperationFail": "Les données sont référencées par l'écran contextuel. L'opération a échoué.",
  "ccm.agent.contact.refreshAll": "Actualiser",
  "ccm.agent.pageurls.fail": "Erreur",
  "ccm.custdata.operlog.download": "Téléchargement",
  "ccm.recognition.label.cancelorderfailpd": "Impossible d'annuler l'abonnement. Réessayez plus tard.",
  "ccmanagement.satisfaction.strategy.accepttime.hour": "Heure",
  "ccm.transserver.label.cloudsearchserver.css": "Configuration CSS",
  "ccm.agent.message.queryCallCenterFail": "Échec du centre d'appels de requête.",
  "ccm.speciallevel.speciallist.levelName": "Nom du niveau",
  "ccm.agent.calledroute.createfail": "Impossible d'ajouter des données locales.",
  "ccm.speciallist.tip.timeVerification": "Sélectionnez une heure postérieure à l'heure actuelle et antérieure à 2038-01-19.",
  "ccm.sysparam.error.validate.ruleinvalid": "La règle de vérification n'est pas valide",
  "ccm.sysparam.error.validate.ccme.alarmThreshold": "La gravité de l'alarme n'est pas conforme au seuil d'alarme. Le seuil d'alarme de gravité faible doit être inférieur (égal à) le seuil d'alarme de gravité élevée",
  "ccm.sysparam.error.validate.ccme.daysAlarmOverSync": "Le paramètre d'entrée doit être inférieur au temps configuré (7 jours par défaut) pour la migration des utilisateurs hors ligne vers la table d'historique",
  "ccm.agent.message.nulagentincti": "L'agent actuellement sélectionné n'est pas créé dans CTI,Vous devez sélectionner les informations de rôle.",
  "ccm.agent.contact.satiscomment": "Évaluation de la satisfaction",
  "ccm.note.config.agent.page.windowTip": "Rappel de boîte de dialogue pop-up",
  "ccm.agent.label.maxAgentWrapupTime": "Valeur [0–3600]",
  "ccmanagement.agent.batchmodify.agentlis": "Par segment",
  "ccm.agent.message.deletefailed": "Échec de la suppression de l'agent",
  "ccm.sysparam.config.select.prompt": "Astuces",
  "ccm.agent.contact.Voice": "Voix",
  "ccm.agent.accesscode.accesscode": "Code d'accès",
  "ccm.custdata.msg.fileSuffixValidateFail": "Format de fichier incorrect. Sélectionnez un fichier CSV.",
  "ccm.agent.message.accRepeatFail": "Le paramètre Account est en double.",
  "ccm.dual.call.record.agentTalkingTime": "Heure de réponse de la partie appelante",
  "ccm.transtask.delete.confirm": "Êtes-vous sûr de vouloir supprimer la tâche de vidage et les enregistrements de tâches historiques ?",
  "ccm.sysparam.config.itemname": "Nom du paramètre",
  "ccm.anonymous.message.fail.notmatch": "L'indicateur de fonctionnalité ne correspond pas au statut actuel",
  "ccm.agent.label.conditionOfBusyTransfer": "Transfert en situation d'occupation",
  "ccm.speciallist.title.specialList": "Liste spéciale",
  "ccm.transtask.label.chatrecordday": "Durée du vidage des données de chat (jour)",
  "ccm.agent.label.ccRole": "Rôle",
  "ccm.agent.message.samepassword": "Le nouveau mot de passe ne peut pas être le même que le mot de passe actuel",
  "ccm.agent.label.withinFifteenDays": "Dans les 15 jours",
  "ccm.agent.contact.downloadrecord": "Télécharger l'enregistrement",
  "ccm.agent.message.willcancelconfig": "Si vous annulez la configuration, elle ne sera pas disponible pour les agents qui utilisent la configuration. Êtes-vous sûr de vouloir annuler la configuration ?",
  "ccm.contact.customer.config.bold": "Gras",
  "ccm.botherlist.label.approveStatus.toApprove": "En attente d'approbation",
  "ccm.dual.call.record.reasonCode": "Raison de la libération",
  "ccm.speciallist.msg.userNo": "Numéro d'abonné",
  "ccm.agent.message.selected": "Vous avez sélectionné\u0020",
  "ccm.speciallist.msg.maxlimit": "Le nombre maximum d'enregistrements dépasse {limit}",
  "ccm.agent.pageurls.isenable": "Activer",
  "ccm.agent.button.createComplete": "Complète",
  "ccm.agent.pageurls.delsuccess": "Supprimé avec succès.",
  "ccm.sysparam.config.refresh": "Actualiser",
  "ccm.contact.customer.config.datasourceType": "Type de source de données",
  "ccm.transtask.label.datatype": "Type de données de vidage",
  "ccm.custdata.option.maskStyle.maskLast4": "Masque les quatre derniers chiffres",
  "ccmanagement.satisfaction.strategy.fail.acceptdate.inputnull": "La date de début ou de fin ne peut pas être vide.",
  "ccm.certificate.management.certificatefile.injectionerror": "Le dossier comporte des risques d'injection.",
  "ccm.calledconfig.message.noskill": "Veuillez choisir la file d'attente des compétences.",
  "ccm.pageconfig.label.numInRow": "Nombre de lignes",
  "ccm.agent.label.CallOutAgent": "Agent de callout",
  "ccm.satisfactionconfig.message.number": "Numéro",
  "ccm.agent.message.searchAccountName": "Entrez un compte.",
  "ccmanagement.satisfaction.strategy.fail.accepttime.inputparse": "L'heure de début ou de fin est dans un format incorrect.",
  "ccm.agentparam.message.editsuccess": "Modifié avec succès.",
  "ccm.calledconfig.message.selectcasetype": "Sélectionnez un type de cas",
  "ccm.agent.calledroute.delete.work.use": "La configuration de la partie appelée est référencée par l'agent et ne peut pas être supprimée.",
  "ccm.contactitemconfig.label.data.extended.writeback.extended.field": "Champ de réécriture étendu",
  "ccm.ucconfig.message.clientId": "ID de l'application (client)",
  "ccm.agent.speciallist.levelName": "Niveau de liste spéciale",
  "ccm.certificate.management.update.crlfile": "Mettre à jour le fichier CRL",
  "ccm.certificate.management.certificatefile.fileempty": "Le fichier est vide.",
  "ccm.custdata.title.create": "Création d'une table de base",
  "ccm.agent.contact.multimedia.email": "Multimédia-Email",
  "ccm.idauth.create.error.format": "Le code de mode d'authentification ne peut contenir que des chiffres, des lettres et des traits de soulignement (_).",
  "ccmanagement.satisfaction.strategy.acceptdate.enddate": "Date de fin",
  "ccm.custdata.option.columnType.number": "Numéro",
  "ccm.idauth.create.error.name": "Le mode d'authentification existe.",
  "ccm.agentparam.message.editfail": "Échec de l'édition.",
  "ccm.agent.label.mobileagentremark": "Remarques",
  "ccm.agent.operation.INTERCEPT": "intercepter",
  "ccm.agent.contact.faceBook": "Facebook",
  "ccmanagement.tenantspace.option.weekday_tue": "Mardi",
  "ccm.agent.message.worknostartbig": "L'ID d'employé de début ne peut pas être supérieur à l'ID d'employé de fin.",
  "ccm.agent.label.busyRateRange": "Valeur [0-100]",
  "ccm.agent.pageurls.operation": "Opération",
  "ccm.contactitemconfig.label.data.item.extended": "Données étendues",
  "ccm.sysparam.config.title": "Paramètres du locataire",
  "ccm.custdata.msg.uploadSelect": "Sélectionnez un fichier",
  "ccm.custdata.label.beginTime": "Heure de début",
  "ccm.agent.contact.callout": "Appel sortant",
  "ccm.agent.contact.mediaType": "Type de média",
  "ccm.ccagent.management.default.options": "Options par défaut",
  "ccm.botherlist.label.approveComment.reject": "Rejeter",
  "ccm.ivrvoice.play.error":"Chargement de l'exception de ressource, veuillez v\u00E9rifier la configuration associ\u00E9e.",
  "ccm.contact.details.tab.title.audio":'Voix',
  "ccm.contact.details.tab.title.video":'Vidéo',
  'ccm.contact.placeholder.searchCustomerNo': 'Numéro de contact',
  "ccm.agent.contact.transfercontent": 'Contenu ASR',
  "ccm.agent.label.oneday": '1 Jour',
  "ccm.agent.label.threedays": '3 Jours',
  "ccm.agent.label.sevendays": '7 Jours',
  "ccm.agent.label.thirtydays": '30 Jours',
  "ccm.contactitemconfig.message.noBaseData":"Aucune donnée de base n'est disponible",
  "ccm.label.downloadAttachment.confirm":"Êtes-vous sûr de vouloir télécharger la pièce jointe sélectionnée ?",
  "ccm.contact.detail.talk.content.seachtips":"Résultats de la recherche: {num}",
  "ccm.contact.detail.talk.content.not.support":"Le texte de recherche ne peut pas contenir\"/()",
  "ccm.contact.detail.talk.content.seach.remark":"Seul le contenu du chat texte affiché sur cette page peut être recherché",
  "ccm.contact.detail.talk.content.seach.remark.emaile":"Seuls les messages qui ont été affichés sur la page de recherche peuvent être recherchés. Une fois la recherche terminée, seuls les messages correspondants sont affichés.",
  "ccm.download.passwd.validator.specialStr": "Seuls les caractères spéciaux suivants sont autorisés: _ {'@'} {'%'}",
  "ccm.login.record.noMatchingLoginRecordExists": "Aucun journal d'enregistrement n'est disponible.",
  "ccm.agent.message.searchSkillQueueName": "Nom de file d'attente de compétence",
  "ccm.agent.button.skill.synchronize": "Synchroniser",
  "ccm.skillgroup.label.groupid": "ID de groupe de compétences",
  "ccm.skillgroup.label.groupname": "Nom",
  "ccm.skillgroup.label.orgname": "Organisation",
  "ccm.agent.label.skillgroup": "Groupe de compétences",
  "ccm.skillgroup.label.searchname": "Compétences Groupe Nom",
  "ccm.skillgroup.label.groupskills": "Associer des files d'attente de compétences",
  "ccm.skillgroup.title.add": "Ajouter un groupe de compétences",
  "ccm.skillgroup.title.update": "Mettre à jour le groupe de compétences",
  "ccm.agent.message.skillweight.invalid": "Le poids de l'habileté doit aller de 1 à 100.",
  "ccm.agent.message.agentweight.invalid": "Le poids de l'agent doit être compris entre 1 et 300.",
  "ccm.agent.message.skillweight.outlimit": "La valeur de poids doit être comprise entre 1 et 100.",
  "ccm.skillgroup.message.groupBind": "Le type d'agent lié au groupe de compétences ne peut pas être défini sur la compétence courante",
  "ccm.skillgroup.message.noskill": "La liste des compétences du groupe de compétences ne peut pas être vide.",
  "ccm.skillgroup.message.nameValidateFail": "Le groupe de compétences ne peut pas contenir les caractères spéciaux suivants: [<>\"()]&=;,",
  "ccm.skillgroup.message.namePrefixSuffix": "Le groupe de compétences ne peut pas commencer ou se terminer avec un espace",
  "ccm.skillgroup.message.nameLimit": "Le nom du groupe de compétences est trop long",
  "ccm.skillgroup.message.nameExistence": "Le nom du groupe de compétences existe déjà",
  "ccm.skillgroup.msg.deleteerror": "Veuillez sélectionner le groupe de compétences à supprimer",
  "ccm.skillgroup.create.fail": "Impossible de créer le groupe de compétences.",
  "ccm.skillgroup.update.fail": "Impossible de mettre à jour le groupe de compétences",
  "ccm.skillgroup.query.fail": "Impossible d'obtenir la configuration du groupe de compétences.",
  "ccm.skillgroup.update.failtip": "Impossible de mettre à jour le groupe de compétences. Synchronisez d'abord la file d'attente de compétences et les données du groupe de compétences.",
  "ccm.agent.download.forbidden": "Aucune autorisation de téléchargement.",
  "ccm.title.org.all": "Veuillez sélectionner une organisation",
  'ccm.title.template.select.email': "Sélectionner un modèle d'email",
  'ccm.title.template.select.sms': 'Sélectionner un modèle SMS',
  "ccm.skillgroup.create.limit": "Un maximum de 2000 groupes de compétences peut être créé.",
  "ccm.skillgroup.msg.deleteTips": "Le groupe de compétences actuel a été associé à l'agent {0}. Supprimez l'association, puis supprimez-la.",
  "ccm.skillgroup.create.failtip": "Impossible de créer un groupe de compétences. Synchronisez d'abord les informations du groupe de compétences et de compétences.",
  "ccm.sendmsg.whatsapp.withoutTemplate": "Pas de contenu. Sélectionnez d'abord un modèle.",
  "ccm.skillgroup.msg.deleteFailed": "Impossible de supprimer le groupe de compétences",
  "ccm.agent.label.skillType":"Type de compétence",
  "ccm.agent.label.selectSkillGroup":"Sélectionner un groupe de compétences",
  "ccm.skill.label.defaultskill":"File d'attente de compétences par défaut",
  "ccm.agent.leavemessage.overtime":"Délai de traitement expiré. Veuillez ouvrir à nouveau la fenêtre pop-up.",
  "ccm.notifition.sendmessage.smsconfignull":"La configuration du message SMS est vide. Choisissez Centre de configuration > Configuration de l'accès > Configuration SMS pour configurer les informations SMS.",

  "iolp.label.basicInfo":"Informations de base",
  "iolp.label.workflow":"workflow",
  "iolp.workflow.node.title":"L'attribut du nœud a été défini avec succès.",
  "iolp.workflow.line.title":"L'attribut de connexion a été défini avec succès.",
  "iolp.workflow.node.label.name":"Nom",
  "iolp.workflow.node.label.nodeType":"Type de nœud",
  "iolp.workflow.node.label.nodeId":"ID de nœud",
  "iolp.workflow.node.label.executeType":"Type d'exécution",
  "iolp.workflow.node.label.autoNodeInfo":"Numéro de résultat du noeud automatique",
  "iolp.workflow.node.label.executeContent":"Exécuter le contenu",
  "iolp.workflow.node.label.explain":"Clause de service",
  "iolp.workflow.node.label.associateKbs":"Connaissances associées",
  "iolp.workflow.node.label.associateBusiness":"Lien de gestion du service associé",
  "iolp.workflow.node.label.hintTextInfo":"Description",
  "iolp.workflow.node.label.sms":"Modèle SMS",
  "iolp.workflow.node.label.associateJs":"Fonctions JavaScript associées",
  "iolp.workflow.nodetype.andsign":"Nœud de tâche",
  "iolp.workflow.nodetype.fork":"Nœud de décision",
  "iolp.workflow.nodetype.andjoin":"Combinaison",
  "iolp.workflow.executetype.common":"type commun",
  "iolp.workflow.executetype.SMS":"Envoi de SMS",
  "iolp.workflow.executetype.email":"Envoyer un e-mail",
  "iolp.workflow.executetype.IVR":"Transfert vers le flux IVR automatique",
  "iolp.workflow.executetype.auto":"Tâches automatiques",
  "iolp.workflow.executetype.subFlow":"Transfert vers d'autres sous-flux de service",
  "iolp.workflow.executetype.case":"Créer un ticket",
  "iolp.configur.service.label.basicInfo":"Informations de base",
  "iolp.configur.service.label.workflow":"Processus guidé",
  "iolp.configur.service.label.name":"Nom",
  "iolp.configur.service.label.workflow.name": "Nom du processus",
  "iolp.configur.service.label.directory.name": "Nom de la catégorie",
  "iolp.configur.service.label.serviceCode":"Code de service",
  "iolp.configur.service.label.upperLevelServiceCode":"code de service de niveau supérieur",
  "iolp.configur.service.label.imageUrl":"Adresse de l'image",
  "iolp.configur.service.label.customizedPageUrl":"URL de la page personnalisée",
  "iolp.configur.service.label.customizedPageContainerId":"ID du conteneur de page personnalisé",
  "iolp.configur.servicelabel.shortcutButtonPage":"Adresse de la page du bouton de raccourci",
  "iolp.configur.service.label.shortcutButtonContainerId":"ID du conteneur de la page du bouton de raccourci",
  "iolp.configur.service.label.search.tips": "Veuillez saisir un nom",
  "iolp.configur.service.add": "Nouveau",
  "iolp.configur.service.operate.edit": "Éditeur",
  "iolp.configur.service.operate.delete": "Suppression",
  "iolp.configur.service.tips.customizedPageUrl": "exemple :CustomerInfoDemo.html",
  "iolp.configur.service.add.select.title": "Sélectionner le nom de la catégorie",
  "iolp.configur.service.add.parent.title": "Nom de la catégorie parent",
  "iolp.configur.service.dialog.root.directory.title": "Aucune catégorie parente",
  "iolp.configur.service.add.operate.title": "Ajouter un type",
  "iolp.configur.service.dialog.add.directory.title": "Création d'une catégorie d'orientation commerciale",
  "iolp.configur.service.dialog.modify.directory.title": "Modifier la catégorie de guidage d'entreprise",
  "iolp.configur.service.dialog.add.workflow.title": "Création d'un processus d'orientation de service",
  "iolp.configur.service.dialog.modify.workflow.title": "Modifier le processus d'orientation de l'entreprise",
  "iolp.configur.servicelabel.label.serialNumber":"Numéro de séquence de tri",
  "iolp.configur.servicelabel.label.status":"Statut",
  "iolp.configur.servicelabel.label.description":"Description",
  "iolp.configur.servicelabel.button.saveDraft":"Enregistrer comme brouillon",
  "iolp.configur.servicelabel.status.draft":"Brouillon",
  "iolp.configur.servicelabel.button.publish":"Publié(e)(s)",
  "iolp.configur.servicelabel.common.tips.error":"Erreur",
  "iolp.configur.servicelabel.common.tips.success":"Succès",
  "iolp.tree.tips":"Vous ne pouvez pas ajouter de noeuds sous un noeud feuille.",
  "iolp.configur.servicelabel.save.successful":"Sauvegarde réussie.",
  "iolp.configur.servicelabel.release.successful": "Publication réussie.",
  "iolp.configur.servicelabel.save.failed":"Échec de l'enregistrement.",
  "iolp.configur.servicelabel.release.failed": "Échec de la publication",
  "iolp.configur.servicelabel.contact.administrator":"Échec de la génération du code de service. Veuillez contacter l'administrateur.",
  "iolp.configur.servicelabel.select.node":"Veuillez sélectionner un nœud.",
  "iolp.configur.servicelabel.confirm.delete":"Supprimer",
  "iolp.configur.servicelabel.confirm.deleteAll":"Êtes-vous sûr de supprimer le noeud courant et ses sous-noeuds ?",
  "iolp.configur.servicelabel.confirm.deleteCurrent":"Êtes-vous sûr de supprimer le noeud actuel ?",
  "iolp.configur.servicelabel.delete.successful":"Suppression réussie.",
  "iolp.configur.servicelabel.delete.failed":"Échec de la suppression",
  "iolp.configur.servicelabel.tips.info":"Conseils",
  "iolp.workflow.label.graphZoomOut": "Zoom Out",
  "iolp.workflow.label.graphZoomIn": "Zoom avant",
  "iolp.workflow.label.previous": "Annuler",
  "iolp.workflow.label.next": "Recovery",
  "iolp.workflow.label.cleanGraph": "Effacer la toile",
  "iolp.workflow.label.taskElement": "Élément de diagramme de fonction",
  "iolp.workflow.label.decisionElement": "Élément de diagramme de branche",
  "iolp.workflow.label.operation":"Opération",
  "iolp.workflow.label.information":"Informations associées",
  "iolp.workflow.label.unmatchedFlow":"Le service correspondant ne peut pas être comparé",
  "iolp.workflow.button.restart":"Redémarrer le processus",
  "iolp.workflow.button.hideFlowChart":"Masquer l'organigramme",
  "iolp.workflow.button.showFlowChart":"Afficher l'organigramme",
  "iolp.workflow.label.currentNodeName":"Nom du nœud actuel",
  "iolp.workflow.label.customizeInfo":"Informations personnalisées",
  "iolp.workflow.label.flowInfo":"Informations sur le processus de service",
  "iolp.workflow.button.kbs":"Base de connaissances",
  "iolp.workflow.button.service":"Gestion d'entreprise",
  "iolp.workflow.title.name":"Processus d'affaires",
  "iolp.configur.service.label.service.title": "Catégorie d'entreprise",
  "iolp.run.service.label.customizedPage": "Informations de personnalisation",
  "iolp.run.service.label.overview": "Aperçu du processus",
  "iolp.run.service.label.nodeName": "Nom du nœud & #160;:",
  "agentconsole.ccnotification.recipient.validate.error": "Chaque numéro ne peut contenir que des chiffres et un maximum de 20 chiffres.",
  "ccm.sendwhatsapp.content.too.long": "Le contenu du modèle est trop long.",
  "ccm.sendwhatsapp.body.too.long": "Le contenu du corps est trop long.",
  "ccm.notifition.sendmessage.largerthanlimit":"Le nombre de destinataires dépasse le maximum {n}.",
  "ccm.ccagent.management.batch.operation":"Opération par lots",
  "ccm.ccagent.management.advanced.search": "Recherche avancée",
  "ccm.ccagent.management.cannot.exceed.25":"Le nombre ne peut pas dépasser 25 caractères.",
  "ccm.agent.callreason.must": "Le motif de l'appel entrant est obligatoire.",
  "iolp.workflow.efficiency.analysis":"Analyse de l'efficacité",
  "iolp.configur.servicelabel.label.version": "Version de publication",
  "iolp.configur.servicelabel.label.version.number": "Nombre de versions",
  "iolp.configur.servicelabel.status.abandon": " Invalide",
  "iolp.configur.servicelabel.status.publish": " Release",
  "iolp.configur.servicelabel.status.unpublish": "Non publié",
  "iolp.configur.service.operate.copy": "Réplication",
  "iolp.configur.service.operate.publish": " Release ",
  "iolp.configur.service.operate.cancel": "Annuler la publication",
  "iolp.configur.servicelabel.publish.successful": " publié avec succès",
  "iolp.configur.servicelabel.publish.failed": "Echec de publication",
  "iolp.configur.servicelabel.cancel.successful": " publication annulée avec succès.",
  "iolp.configur.servicelabel.cancel.failed": "Echec d'annulation",
  "iolp.configur.servicelabel.copy.successful": " copié avec succès.",
  "iolp.configur.servicelabel.copy.failed": "Échec de la réplication",
  "iolp.configur.servicelabel.confirm.delete.flow": "Êtes-vous sûr de vouloir supprimer la version actuelle ? ",
  "iolp.process.utilization.efficiency.analysis":"Analyse de l'efficacité de l'utilisation des processus",
  "iolp.flow.number.of.contacts":"Nombre de contacts (pièces)",
  "iolp.flow.first.solution.rate":"Taux de première solution (%)",
  "iolp.flow.please.select.time.before.today":"Veuillez sélectionner une heure avant aujourd'hui",
  "iolp.flow.the.interval.validate":"L'intervalle est de 1 jour ou de 3 à 30 jours.",
  "iolp.flow.analysis.date.time":"Date/Heure",
  "ccm.empty.template": "Le modèle n'existe pas. Vérifiez le modèle de notification.",
  "ccm.empty.variables": "La variable template n'existe pas. Vérifiez le modèle de notification.",
  "ccm.no.meeting.variables": "La variable template ne contient pas la variable Meeting_URL. Vérifiez le modèle de notification.",
  "ccm.agent.meeting.failed": "El agente no puede crear una conferencia.",
  "ccm.agent.link.failed": "L'agent ne parvient pas à générer le lien SMS.",
  "ccm.agent.meeting.receiver.check.error": "El número de destinatarios no puede exceder de 1.",
  "ccm.agent.contact.timeouthungup": "Délai d'arrêt",
  "ccm.agent.contact.skilltimeouthungup": "Transfert vers la file d'attente de compétences en cas de délai d'attente",
  "ccm.agent.leavemessage.opinionempty": "Les commentaires de la vérification sont obligatoires.",
  "ccm.sms.meeting.link": "lien de conférence SMS",
  "ccm.agent.sms.meeting.gateway.commonerror": "Impossible de créer la conférence. Code d'erreur : {n}",
  "ccm.agent.sms.meeting.gateway.error": "Impossible de créer la conférence. Code d'erreur : {n}. Ne créez pas la conférence en mode muet, attente ou multipartite.",
  "ccm.contactRecordDetail.notSetTraceLogError": "Aucune piste de processus n'est trouvée. Ajoutez des paramètres de suivi et vérifiez à nouveau la piste de processus de l'enregistrement de contact correspondant",
  "ccm.agent.callreason.modify.exceeds.limit": "Impossible de modifier la raison de l'appel. L'intervalle dépasse la période de validité de la raison de l'appel.",
  "ccm.label.appcube.switch": "Page int\u00E9gr\u00E9e \u00E0 AppCube",
  "ccm.label.appcube.app": "S\u00E9lectionner une application",
  "ccm.label.appcube.menu": "S\u00E9lectionner le menu",
  "ccm.contactitemconfig.label.data.item.sequence.number.seq": "Ordre de champ",
  "ccm.contactitemconfig.label.data.item.add": "Créer un champ d'enregistrement de contact",
  "ccm.contactitemconfig.label.data.item.edit": "Modifier le champ d'enregistrement de contact",
  "ccm.contactitemconfig.label.data.item.tips": "L'interface source du jeu de données doit être configurée sur la page de gestion de l'interface. Contactez l'administrateur d'exploitation du système.",
  "ccm.contactitemconfig.label.data.item.basic.item": "Champ étendu de base",
  "ccm.contactitemconfig.label.data.item.custom.item": "Champ étendu défini par l'utilisateur",
  "ccm.contactitemconfig.label.dataInfo.item.add": "Créer un élément de données",
  "ccm.contactitemconfig.label.dataInfo.item.edit": "Modifier l'élément de données",
  "ccm.custdata.label.data.table.template": "Modèle de table de données",
  "ccm.custdata.msg.uploadType": "Méthode d'importation de données en double",
  "ccm.custdata.msg.uploadTypeInfo": "S'il y a des données en double\, si vous choisissez d'écraser les données d'origine\, l'enregistrement de données sera mis à jour\, si vous choisissez de ne pas écraser les données d'origine\, elles seront considérées comme des données en échec",
  "ccm.custdata.msg.uploadType.cover": "écraser les données d'origine",
  "ccm.custdata.msg.uploadType.notcover": "Ne pas écraser les données d'origine",
  "ccm.pageconfig.label.page": "Page",
  "ccm.pageconfig.label.publishpage": "Publier la page",
  "ccm.transtask.label.intelligentInspectionConfig":"Paramètres de vidage de données d\\'inspection AI",
  "ccm.validate.portLengthError":"Port portée devrait être 1-65535",
  "ccm.resource.dump.server.add":"Créer un serveur de vidange de ressources",
  "ccm.resource.dump.server.edit":"Modifier le serveur de vidange des ressources",
  "ccm.resource.dump.server.view":"Afficher le serveur de vidange des ressources",
  "ccm.resource.dump.css.add":"Créer un serveur de recherche Cloud",
  "ccm.resource.dump.css.edit":"Modifier le serveur de recherche Cloud",
  "ccm.resource.dump.css.view":"Voir le serveur de recherche Cloud",
  "ccm.resource.dump.task.add":"Créer une tâche de vidage de ressources",
  "ccm.resource.dump.task.edit":"Modifier une tâche de vidage de ressources",
  "ccm.resource.dump.task.view":"Afficher une tâche de vidage de ressources",
  "ccm.transtask.label.intelligentInspectionDay":"Durée du vidage des données d\\'inspection AI",
  "ccm.transtask.css.server.num":"Le nombre de Cloud Search Server ne peut pas dépasser 1.",
  "ccm.contact.customer.config.num":"Le nombre de personnes-ressources dans la colonne des renseignements sur le client ne peut pas dépasser 10.",
  "ccm.export.password.error.tips": "Le mot de passe du fichier compressé contient de 8 à 12 caractères, y compris des lettres, des chiffres et des caractères spéciaux {\'@\'}#$%^&*+{\'|\'}=~!?,.:;-_\'\"(){\'{\'}{\'}\'}[]/><",
  "ccm.export.password.error.message": "Doit contenir des lettres, des chiffres et des caractères spéciaux {\'@\'}#$%^&*+{\'|\'}=~!?,.:;-_\'\"(){\'{\'}{\'}\'}[]/><",
  "ccm.agent.ccagentinfo.modifyselfphone.success":"Modification du firmware / numéro de téléphone avec succès",
  "ccm.agent.ccagentinfo.modifyselfphone.fail":"La modification du firmware / numéro de téléphone a échoué",
  "ccm.export.table.taskTime": "Temps de la tâche",
  "ccm.export.table.taskStatus": "Statut de la tâche",
  "ccm.agent.contact.multimedia.sms":"Multim\u00E9dia-SMS",
  "ccm.agent.label.skill":"Comp\u00E9tences",
  "ccm.agent.button.randomRestSoftPhonePwd":"Réinitialiser le mot de passe du softphone",
  "ccm.agent.label.skillGroup":"Groupe de file d'attente de comp\u00E9tences",
  "ccm.agent.message.searchSkillGroupName":"Nom",
  "ccm.skill.label.skillGroupName":"Nom du groupe de file d'attente de comp\u00E9tences",
  "ccm.agent.button.restSoftPhonePwd.remark":"Si vous r\u00E9initialisez les mots de passe de softphone d'agent en mots de passe al\u00E9atoires par lots\, les agents doivent r\u00E9initialiser leurs propres mots de passe de softphone avant d'utiliser la fonction softphone. L'administrateur peut \u00E9galement cliquer sur R\u00E9initialiser le mot de passe du t\u00E9l\u00E9phone logiciel dans la liste pour modifier manuellement le mot de passe de l'agent.",
  "ccm.agent.management.exporting.agent.information":"Exportation des informations de l'agent",
  "ccm.agent.clear.message.clearcommonerror":"Impossible d'annuler la configuration. Le service est indisponible.",
  "ccm.contactitemconfig.label.dataInfo.item.name.exist": "Le nom de l'élément de données existe déjà.",
  "ccm.agent.button.downloadvrc": "Télécharger VRC",
  "ccm.dual.call.callback.url.config":"Configuration de l'URL de rappel",
  "ccm.dual.call.callback.url.auth":"Interface Appel de l'authentification d'URL",
  "ccm.dual.call.callback.url.authType":"Mode d'authentification de l'URL de rappel",
  "ccm.dual.call.callback.url.ita":"Configuration des paramètres ITA",
  "ccm.dual.call.callback.url.xNumberType":"Mode d'attribution du nombre X",
  "ccm.agent.management.select.agent":"Sélection des agents par ID d'employé",
  'ccm.password.length.limit.min': 'Le mot de passe est trop court. Entrez {min}-{max} caractères',
  'ccm.password.length.limit.max': 'Le mot de passe est trop court. Entrez {min}-{max} caractères',
  "ccm.agent.operation.SCREENMONITOR": "Surveillance d'écran",
  'ccm.mobile.agent.front.add': 'Créer un agent mobile',
  'ccm.mobile.agent.front.edit': 'Modifier l\'agent mobile',
  'ccm.mobile.agent.front.checkNum': 'Veuillez sélectionner au moins un numéro.',
  'ccm.mobile.agent.front.maxNum': 'Ce nombre ne doit pas dépasser 15.',
  'ccm.contactitemconfig.label.contactData.add': 'Création d\'un ensemble de données d\'enregistrement de contact',
  'ccm.contactitemconfig.label.contactData.edit': 'Modification d\'un ensemble de données d\'enregistrement de contact',
  'ccm.agent.button.downloading':'Téléchargement en cours...',
  'ccm.label.operationexport.nodata': 'Aucun dossier d\'opération ne répond aux conditions.',
  'ccm.label.transferexport.nodata': 'Aucun dossier de transmission ne répond aux conditions.',
  'ccm.label.leaveexport.nodata': 'Aucun dossier de repos ne répond aux exigences.',
  'ccm.label.send.whatsapp': 'Envoyer WhatsApp',
  "ccm.callcenterinstanceslist.vcallcenterDeatil.specialStrValidateFailed": "Le nom du centre d'appel ne peut pas contenir de caractères spéciaux",
  'ccm.common.validation.numbers': 'Veuillez n\'entrer que des chiffres',
  'ccm.common.validation.click.number': 'Veuillez cliquer sur le numéro',
  'ccm.ccagent.agent.weight.tips': 'Une valeur plus grande indique une priorité plus élevée. Lorsqu\'il y a plusieurs agents inactifs\, le système attribue de préférence des appels entrants à l\'agent avec un poids plus élevé.',
  'ccm.ccagent.skill.weight.tips': 'Une valeur plus grande indique une priorité plus élevée. Lorsqu\'il y a des appels dans plusieurs files d\'attente\, le système attribue les appels dans la file d\'attente avec un poids plus élevé à l\'agent inactif.',
  'ccm.ccagent.batch.modify.special.character.verification': 'ne peut contenir’',
  'ccm.resource.dump.obs.cert.warn.tip': 'Veuillez contacter le personnel d\'exploitation et d\'entretien pour importer le certificat de confiance OBS dans la bibliothèque de certificats.',
  "ccm.audiovideoworkbench.mood" : "Humeur",
  "ccm.audiovideoworkbench.intention" : "Intention",
  "ccm.audiovideoworkbench.complain" : "Propension à se plaindre",
  "ccm.audiovideoworkbench.aiswitch" : "Modèle de grand langage",
  "ccm.audiovideoworkbench.airecommendation" : "Recommandation intelligente",
};