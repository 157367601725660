<!--自动外呼弹窗-->
<template>
    <div>
        <sweet-dialog v-model="dialogVisible" :title="$t('isales.taskinfo.title.chooseCommonIvrFlow')" width="616" class="aicc-dialog-limit-height"
                      :close-on-click-modal="false">
            <aicc-advanced-search v-if="cmsEnvironment==='aicc'"
                    :templateMetaData="templateMetaData"
                    @handleSearch='searchPageFirst'
                    @handleCommand="handleCommand">
            </aicc-advanced-search>
            <aicc-table v-if="cmsEnvironment==='aicc'"
                    :tableData="ivrList"
                    :tableColumns="tableColumns"
                    :tableTools="{ showPagination: true, columnConfig: false }"
                    :paginationConfig="paginationConfig"
                    @handlePagination="handlePagination"
                    ref='refAiccTable' :height="430">
            </aicc-table>
            <aicc-table v-if="cmsEnvironment==='cms'"
                    :tableData="ivrList"
                    :tableColumns="tableColumnsOp"
                    :tableTools="{ showPagination: false, columnConfig: false }"
                    :paginationConfig="paginationConfig"
                    @handlePagination="handlePagination" :height="430"
                    ref='refAiccTable'>
            </aicc-table>
            <template #footer>
      <span class="dialog-footer">
        <sweet-button @click="dialogVisible = false">{{ $t('isales.common.title.cancel') }}</sweet-button>
        <sweet-button type="primary" @click="confirm">{{ $t('isales.common.title.confirm') }}</sweet-button>
      </span>
            </template>
        </sweet-dialog>
    </div>
</template>

<script>
    import {queryCmsEnvironment, queryCommonIvr} from "@/views/isales/api/systemAutoCallTask";

    export default {
        name: "isalesAutoIvrSelect",
        props: {
            show: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                cmsEnvironment: '',
                ivrList: [],
                tableColumns: [
                    {
                        type: 'radio',
                        radioKey: 'flowAccessCode', // 单选绑定的字段名
                        width: '50px',
                        'show-overflow-tooltip': false
                    },
                    {
                        label: this.$t('isales.taskinfo.title.commonIvrFlow'),
                        prop: 'flowname',
                    }
                ],
                tableColumnsOp: [
                    {
                        type: 'radio',
                        radioKey: 'flowAccessCode', // 单选绑定的字段名
                        width: '50px',
                        'show-overflow-tooltip': false
                    },
                    {
                        label: this.$t('isales.taskinfo.title.commonIvrFlow'),
                        prop: 'flowAccessCode',
                    }
                ],
                paginationConfig: {
                    PageSize: 10, // 每页条数
                    total: 0, //总条数
                    layout: "total, prev, pager, next" // 分页组件布局配置
                },
                templateMetaData: {
                    basicConfig: { // 基础搜索配置
                        basicSearch: [
                            {
                                searchType: 'primary', // primary: 有搜索图标的输入框；select: 下拉框; input: 搜索框；date: 日期选择器
                                modelValue: 'flowname', // v-model绑定的key值
                                modelDefault: '', // v-model绑定的默认值
                                placeholderValue: this.$t('isales.management.placeholder.commonIvrName'), // placeholder绑定的值 国际化之后的值
                                rules: [],
                                colConfig: {
                                    xl: 12,
                                    lg: 12
                                }
                            }
                        ],
                    }
                },
                limit: 10,
                offset: 0,
            }
        },
        computed: {
            dialogVisible: {
                get() {
                    return this.show
                },
                set(val) {
                    this.$emit('update:show', val)
                }
            }
        },
        methods: {
            handleCommand(data) { // 触发事件处理，调用对应方法，方法和eventName同名
                const {eventName, params} = data
                this[eventName](params)
            },
          searchPageFirst(params){
            this.offset = 0;
            this.$refs.refAiccTable.setCurrentPage(1);
            this.queryIvrListAndCount(params);
          },
            async queryIvrListAndCount(params) {
                let queryCondition = {};
                if (params !== 'undefined' && params && params.params && params.params.flowname) {
                    if(this.cmsEnvironment==='aicc'){
                        queryCondition['flowName'] = params.params.flowname;
                    }else {
                        queryCondition['flowAccessCode'] = params.params.flowname;
                    }
                }
                queryCondition['limit'] = this.limit;
                queryCondition['offset'] = this.offset;
                queryCommonIvr(queryCondition).then(res => {
                    if (res) {
                        this.ivrList = res.flow;
                        this.paginationConfig.total = parseInt(res.total);
                    }
                })
            },
            handlePagination(params) {
                this.limit = params.limit;
                this.offset = params.offset;
                this.queryIvrListAndCount(params);
            },
            confirm() {
                this.dialogVisible = false
                if($.isEmptyObject(this.$refs.refAiccTable.getRadioCurrentRow())){
                  this.$message({
                    type: 'error',
                    duration: 10000,
                    showClose: true,
                    offset: 56,
                    message: this.$t('isales.agent.calledroute.ivr')
                  });
                }
                this.$emit('isalesAutoIvrSelectConfirm', this.$refs.refAiccTable.getRadioCurrentRow())
            },
            create() {
                this.dialogVisible = false
                window.showTab({ id: '8005002', name: 'oifde', title: this.$t('oifde.headerTitle.flowConfiguration') })
            },
            async queryCmsEnvironment() {
                let result = await queryCmsEnvironment();
                this.cmsEnvironment = result.cmsEnvironment;
            },
        },
        async created() {
            await this.queryCmsEnvironment();
            if (this.cmsEnvironment === 'aicc') {
                this.templateMetaData.basicConfig.otherOperation = [];
                this.templateMetaData.basicConfig.otherOperation.push({
                    basicButton: true, // 基础按钮
                    buttonStyle: 'primary', // 按钮风格 primary; success; info; warning; danger
                    buttonName: this.$t('isales.tasktype.field.auto.robot.create'), // 按钮名称
                    eventName: 'create' // 按钮事件名称
                })
            }
            await this.queryIvrListAndCount();
        },
    }
</script>

<style scoped>

</style>