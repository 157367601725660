import request from '@/utils/request'

export function queryLanguage(data) {
  return request({
    url: '/oifde/rest/cc-ivr/v1/languageservice/queryLanguage',
    method: 'post',
    data: data
  })
}

export function queryAllIvrVoice(data) {
  return request({
    url: '/oifde/rest/cc-ivr/v1/voicemanageservice/queryAllIvrVoice',
    method: 'post',
    data
  })
}

export function queryIvrVoiceById(data) {
  return request({
    url: '/oifde/rest/cc-ivr/v1/voicemanageservice/queryIvrVoiceById',
    method: 'post',
    data
  })
}

export function queryIvrVoice(data) {
  return request({
    url: '/oifde/rest/cc-ivr/v1/voicemanageservice/queryIvrVoice',
    method: 'post',
    data
  })
}

export function queryCCIvrCount(data) {
  return request({
    url: '/oifde/rest/cc-ivr/v1/voicemanageservice/queryCCIvrCount',
    method: 'post',
    data
  })
}

export function queryReferenceIvrInfoById(data) {
  return request({
    url: '/oifde/rest/cc-ivr/v1/voicemanageservice/queryReferenceIvrInfoById',
    method: 'post',
    data
  })
}

export function queryAllCCIvrCount(data) {
  return request({
    url: '/oifde/rest/cc-ivr/v1/voicemanageservice/queryAllCCIvrCount',
    method: 'post',
    data
  })
}

export function deleteUpdateIvrFile(id) {
  return request({
    url: '/oifde/rest/cc-ivr/v1/voicemanageservice/deleteUpdateIvrFile?id=' + id,
    method: 'post',
  })
}

export function deleteVoiceFile(data) {
  return request({
    url: '/oifde/rest/cc-ivr/v1/voicemanageservice/deleteVoiceFile',
    method: 'post',
    data
  })
}

export function deleteIvrVoice(data) {
  return request({
    url: '/oifde/rest/cc-ivr/v1/voicemanageservice/deleteIvrVoice',
    method: 'post',
    data
  })
}

export function queryUpdateIvrInfo(id) {
  return request({
    url: '/oifde/rest/cc-ivr/v1/voicemanageservice/queryUpdateIvrInfo',
    method: 'get',
    data: {id:id}
  })
}

export function updateIvrVoiceStatus(data){
  return request({
    url: '/oifde/rest/cc-ivr/v1/voicemanageservice/updateIvrVoiceStatus',
    method: 'post',
    data
  })
}

export function queryDataDict(){
  return request({
    url: '/oifde/rest/cc-ivr/v1/ttsVoicename/queryDataDict',
    method: 'post',
  })
}

export function upload(file, query, url) {
  const param = new FormData()
  param.append('file', file)
  for (var prop in query) {
    if (typeof query[prop] === undefined) {
      continue
    }
    if (typeof query[prop] === 'string' && query[prop] === '') {
      continue
    }
    param.append(prop, query[prop])
  }
  return request({
    url: url,
    method: 'post',
    headers: {'Content-Type': 'multipart/form-data'},
    data: param
  })
}

export function getTenantLanguage(){
  return request({
    url: '/oifde/rest/cc-ivr/v1/languageservice/getTenantLanguage',
    method: 'post',
  })
}

export function addLanguage(data){
  return request({
    url: '/oifde/rest/cc-ivr/v1/languageservice/addLanguage',
    method: 'post',
    data
  })
}

export function operatLanguage(data) {
  return request({
    url: '/oifde/rest/cc-ivr/v1/languageservice/operatLanguage',
    method: 'post',
    data
  })
}

export function addIvrVoiceText(data) {
  return request({
    url: '/oifde/rest/cc-ivr/v1/voicemanageservice/addIvrVoiceText',
    method: 'post',
    data
  })
}

export function sysUpdateIvrVoice(data) {
  return request({
    url: '/oifde/rest/cc-ivr/v1/voicemanageservice/sysUpdateIvrVoice',
    method: 'post',
    data
  })
}

export function queryFeatureByCode(data) {
  return request({
    url: '/oifde/rest/cc-ivr/v1/voicemanageservice/queryFeatureByCode',
    method: 'post',
    data
  })
}