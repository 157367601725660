<template>
  <sweet-dialog v-model="whatsappDialogVisible" :close-on-click-modal="false"
    draggable :before-close="handleClose" width="616px">
    <template #header>
      <div class="cust_header">
        <i class="sweetui-icon-left return_icon" v-if="!showForm" @click="closeOtherNav" style="cursor: pointer"></i>
        <span class="dialog_title" v-if="showForm">{{ $t('ccm.label.send.whatsapp') }}</span>
        <span class="dialog_title dialog_title_sub" v-else-if="showSelectTemplate">{{ $t('ccm.whatsapp.whatsApp.message.template') }}</span>
        <span class="dialog_title dialog_title_sub" v-else-if="showFile">{{ $t('ccmessaging.chat.templateMsg.chooseMultimedia') }}</span>
      </div>
    </template>
    <div v-if="showForm" class="content_nav">
      <sweet-form ref="ruleFormRef" :model="whatsappForm" :label-position="direction" label-width="auto" :rules="rules">
        <sweet-form-item class="whatsappForm_recipient" prop="recipient" :label="$t('isales.manual.title.recipient')">
          <sweet-input v-model="whatsappForm.recipient" style="width: 400px !important">
            <template #prepend>
              <aicc-select v-model="whatsappForm.region" filterable
                            :options="regions || []"
                            defaultLabelKey="label"
                            defaultValueKey="value" />
            </template>
          </sweet-input>
        </sweet-form-item>
        <sweet-form-item prop="channelId" :label="$t('isales.task.promotion.whatsAppChannel')">
          <aicc-select v-model="whatsappForm.channelId" :placeholder="$t('aicc.select')" @change="clearHistoryData"
                        :options="whatsAppChannels || []"
                        defaultLabelKey="codeName"
                        defaultValueKey="id" style="width: 400px !important"/>
        </sweet-form-item>
        <sweet-form-item prop="name" :label="$t('ccmessaging.chat.multimedia.whatsAppTemplate')">
          <sweet-select
            style="width: 400px !important"
            v-model="whatsappForm.name"
            :placeholder="$t('isales.task.promotion.selectSendTemplate')"
            readonly
            :suffix-icon="SelectMore"
            @click="selectWhatsappTemplate()"
          />
        </sweet-form-item>
        <div v-if="whatsappForm.id">
          <sweet-form-item v-if="whatsappForm.headerType == '0' || whatsappForm.headerType == '4'" :label="$t('ccmessaging.chat.whatsapp.header')">
            <sweet-input v-model="whatsappForm.headerContent" type="textarea" style="width: 400px !important" disabled/>
          </sweet-form-item>
          <sweet-form-item v-if="whatsappForm.headerType != '0' && whatsappForm.headerType != '4'" :label="$t('ccmessaging.chat.whatsapp.header')">
            <sweet-input v-model="whatsappForm.mediaFileName" style="width: 400px !important" readonly @click="selectMediaFile()"></sweet-input>
          </sweet-form-item>
          <sweet-form-item :label="$t('ccmessaging.chat.whatsapp.body')">
            <sweet-input v-model="whatsappForm.bodyContent" type="textarea" style="width: 400px !important" disabled/>
          </sweet-form-item>
          <div class="templateParameters_nav">
            <sweet-table :data="whatsappForm.bodyParameters" border style="width: 100%">
              <sweet-table-column prop="no" :label="$t('ccmessaging.chat.template.table.no')" width="60px"/>
              <sweet-table-column prop="parameterDescription" :label="$t('ccmessaging.chat.template.table.parameterDescription')"/>
              <sweet-table-column :label="$t('ccmessaging.chat.template.table.parameterContent')">
                <template v-slot="scope">
                  <div class="input-with-tips">
                    <sweet-input v-model="scope.row.parameterContent" :id="'paramNo' + scope.row.no " maxlength="500"
                      @keyup="paramsValueChange(scope.row)" clearable @clear="paramsValueChange(scope.row)"/>
                    <span v-if="!scope.row.validateValue" class="error-text">{{ scope.row.error }}</span>
                  </div>
                </template>
              </sweet-table-column>
            </sweet-table>
          </div>
          <sweet-form-item :label="$t('ccmessaging.chat.whatsapp.footer')">
            <sweet-input v-model="whatsappForm.footer" type="textarea" style="width: 400px !important" disabled/>
          </sweet-form-item>
          <sweet-form-item :label="$t('ccmessaging.chat.whatsapp.buttons')"></sweet-form-item>
          <div class="templateButtons_nav">
            <sweet-table :data="whatsappForm.buttons" border style="width: 100%">
              <sweet-table-column prop="no" :label="$t('ccmessaging.chat.template.table.no')" width="60px"/>
              <sweet-table-column prop="buttonName" :label="$t('ccmessaging.chat.whatsapp.templateButtonName')"> </sweet-table-column>
              <sweet-table-column :label="$t('ccmessaging.chat.whatsapp.templateButtonContent')">
                <template v-slot="scope">
                  <div class="input-with-tips">
                    <sweet-input :disabled="whatsappForm.buttonsType != '0'" :id="'btnNo' + scope.row.no " maxlength="500" v-model="scope.row.buttonValue"
                      @keyup="btnValueChange(scope.row)" clearable @clear="btnValueChange(scope.row)"/>
                    <span v-if="!scope.row.validateValue" class="error-text">{{ scope.row.error }}</span>
                  </div>
                </template>
              </sweet-table-column>
            </sweet-table>
          </div>
        </div>
        <div v-else class="templateData_nav">
          <span>{{ $t('ccm.sendmsg.whatsapp.withoutTemplate') }}</span>
        </div>
      </sweet-form>
    </div>
    <div v-else-if="showFile">
      <SelectMediaFile @selectFile="selectFile" :selectedFileId="whatsappForm.headerContent" :mediaType="whatsappForm.headerType"></SelectMediaFile>
    </div>
    <div v-else-if="showSelectTemplate">
      <aicc-advanced-search ref='templateSearchRef' :templateMetaData="templateMetaData"
        @handleSearch="handleSearch" @orgTreeSelectChange="handleSearch" @clearName="handleSearch">
      </aicc-advanced-search>
      <aicc-table
        :tableData="templateList"
        :tableColumns="tableColumns"
        :tableTools="{ showPagination: true, columnConfig: false }"
        @radio-change="handleSelect"
        :paginationConfig="paginationConfig"
        @handlePagination="handlePagination"
        ref='selectTemplateRef'>
      </aicc-table>
    </div>
    <template #footer>
      <span class="dialog-footer" v-if="showForm">
        <sweet-button @click="handleClose">{{ $t('ccm.agent.button.cancel') }}</sweet-button>
        <sweet-button type="primary" @click="sendMessage(ruleFormRef)" :disabled="repeatClick"> {{ $t('ccd.cccbar.label.send') }} </sweet-button>
      </span>
      <span class="dialog-footer" v-else-if="showSelectTemplate">
        <sweet-button @click="closeOtherNav">{{ $t('ccm.agent.button.cancel') }}</sweet-button>
        <sweet-button type="primary" @click="submitSelectTemplate"> {{ $t('aicc.ok') }} </sweet-button>
      </span>
      <span class="dialog-footer" v-else-if="showFile">
        <sweet-button @click="closeOtherNav">{{ $t('ccm.agent.button.cancel') }}</sweet-button>
        <sweet-button type="primary" @click="submitFile"> {{ $t('ccm.agent.button.save') }} </sweet-button>
      </span>
    </template>
  </sweet-dialog>
</template>
<script setup>
import { computed, reactive, ref, toRefs, onMounted, watch, nextTick  } from "vue";
import { querySysparamInterCode, audioVideoSendWhatsAppMsg } from '@/views/ccmanagement/ccma-api/index.js';
import { 
  queryDataDictionary, 
  queryWhatsAppChannelList, 
  ccmeQueryMultiMediaLibraryById } from '@/views/ccmanagement/ccma-api/otherProjectsRequest.js';
import SelectMediaFile from '@/views/ccmanagement/pages/notification/SelectMediaFile.vue';
import { getWhatsAppTemplateMsgByChannelId } from '@/views/ccmanagement/ccma-api/otherProjectsRequest.js';
import { getUserAccess } from "@/utils/organization";
import validate from "@/utils/validate";
import AiccElMessage from '@/utils/el-message';
import SelectMore from '@/views/ccmanagement/components/select-more/SelectMore.vue';

const props = defineProps(['whatsappDialogVisible', 'audioVideoCaller', 'audioVideoCallId'])
const {whatsappDialogVisible, audioVideoCaller, audioVideoCallId} = toRefs(props);
const emit = defineEmits([ 'handleWhatsappClose'])

const showForm = ref(true);
const showTemplate = ref(false);

const phoneValidator = (rule, value, callback) => {
  let phoneReg = /^[0-9]*$/;
  if (phoneReg.test(value)) {
    callback();
  } else {
    callback(new Error($t('ccm.sysparam.error.validate.notmatchrulenumber')));
  }
}

const rules = reactive({
  recipient:[{
    required: true,
    message: $t('SM.LOGIN.TIPS.REQUIRED'),
    trigger: ['blur']
  }, {
    validator: phoneValidator,
    trigger: ['blur', 'change']
  },{maxLength: 20, validator: validate.maxLengthValidator, trigger: ['blur', 'change']},],
  channelId: [{
    required: true,
    message: $t('SM.LOGIN.TIPS.REQUIRED'),
    trigger: ['blur', 'change']
  }],
  name: [{
    required: true,
    message: $t('SM.LOGIN.TIPS.REQUIRED'),
    trigger: 'blur'
  }]
})

const queryParam = reactive({})

const ruleFormRef = ref();
const whatsappForm = reactive({
  recipient: '',
  region: '',
  bodyContent: '',
  bodyParameters: '',
  bodyParametersDes: '',
  buttons: '',
  buttonsType: '',
  channelId: '',
  description: '',
  footer: '',
  headerContent: '',
  headerType: '',
  id: '',
  language: '',
  name: '',
  orgId: '',
  templateId: '',
  whatAppTemplateDailyLimit: 0,
  whatAppTemplateName: '',
  mediaFileName: ''
});
const paginationConfig = reactive({
  pageSizes: [10, 20, 50, 100], // 每页条数列表
  pageSize: 10, // 每页条数
  total: 0, //总条数
  layout: "sizes, total, prev, pager, next" // 分页组件布局配置
})
const regions = ref([]);
const whatsAppChannels = ref([]);
const selectTemplateObj = reactive({});
const selectFileObj = reactive({});
const callId = ref('');
const callSerialNo = ref('');
const repeatClick = ref(false);
const direction = computed(() => window.isRTL ? 'right' : 'left');
const templateList = ref([]);
const showSelectTemplate = ref(true);
const showFile  = ref(false);
const templateSearchRef = ref(null);
const selectTemplateRef = ref();
const orgTreeList = ref([]) // 组织机构树形数据
const defaultProps = reactive({
  id: 'id',
  children: 'children',
  label: 'label'
})
const curPageSize = ref(10);
const showOrg = ref(false);
const errorCodeMap = reactive({
  '1': $t('ccnotification.notifition.sendmessage.sendfailed'),
  '11': $t('ccm.sendwhatsapp.content.too.long'),
  '12': $t('ccm.sendwhatsapp.body.too.long'),
  '13': $t('ccd.agent.message.loginInvalidPhone'),
  '14': $t('ccnotification.notifition.sendmessage.sendfailed')
})
const hasSearched = ref(false);

const templateMetaData = reactive({
  basicConfig: {
    basicSearch: [
    {
      searchType: 'orgTreeSelect', // 组织选择树
      modelValue: 'orgIds',
      treeOptions: orgTreeList,
      placeholderValue: $t("SM.ORGSTAFF.LABEL.ORGANIZATION"),
      multiple: true
    }, {
      searchType: 'primary',
      modelValue: 'searchContent',
      modelDefault: '',
      placeholderValue: $t('ccm.whatsapp.template.message.name'),
      rules: [],
      clearEvent: 'clearName',
      colConfig: {
        lg: 11,
        xl: 11
      }
    }]
  }
})

const tableColumns = reactive([
  {
    type: 'radio',
    radioKey: 'id', // 单选绑定的字段名
    width: '50px',
    'show-overflow-tooltip': false
  },
  {
    label: $t('ccm.whatsapp.template.message.id'),
    prop: 'id',
  },
  {
    label: $t('ccm.whatsapp.template.message.name'),
    prop: 'name',
  }
])

onMounted(() => {
  init();
});

const paramsValueChange = (row) => {
  const parentDiv = document.querySelector('#paramNo' + row.no).parentNode;
  if (!row.parameterContent || row.parameterContent == '') {
    row.validateValue = false;
    row.error = $t('SM.LOGIN.TIPS.REQUIRED');
    parentDiv.style.boxShadow = 'rgb(223, 52, 55) 0px 0px 0px 1px inset';
  } else {
    row.validateValue = true;
    parentDiv.style.removeProperty('box-shadow');
  }
}

const btnValueChange = (row) => {
  const parentDiv = document.querySelector('#btnNo' + row.no).parentNode;
  if (!row.buttonValue || row.buttonValue == '') {
    row.validateValue = false;
    row.error = $t('SM.LOGIN.TIPS.REQUIRED');
    parentDiv.style.boxShadow = 'rgb(223, 52, 55) 0px 0px 0px 1px inset';
  } else {
    row.validateValue = true;
    parentDiv.style.removeProperty('box-shadow');
  }
}

const queryTemplateByCondition = () => {
  if (templateSearchRef.value && templateSearchRef.value.getFormatParams()) {
    queryParam.value.name = templateSearchRef.value.getFormatParams().searchContent;
  }
  queryParam.value.channelId = whatsappForm.channelId;
  buildOrgData();
  queryTemplate(queryParam.value);
}

const handleSearch = () => {
  queryParam.value = {
    limit: curPageSize.value,
    offset: 0
  };
  hasSearched.value = true;
  queryTemplateByCondition();
}

const queryTemplate = (param) => {
  templateList.value = [];
  getWhatsAppTemplateMsgByChannelId(param).then(result => {
    if (!result) {
        return;
    }
    templateList.value = result["result"] || [];
    let item = templateList.value.find(item => item.id == whatsappForm.id);
    if (item) {
      selectTemplateRef.value.setRadioCurrentValue(item.id);
    }
    paginationConfig.total = result["total"];
  });
}

const queryOrgInfo = () => {
  getUserAccess().then((data) => {
    orgTreeList.value = data;
  })
}

const buildOrgData = () => {
  if (!templateSearchRef.value || !templateSearchRef.value.formData) {
    return;
  }
  let basicSearchParams = templateSearchRef.value.formData;
  let orgIdsSearch = basicSearchParams.orgIds;
  // 如果searchContent有值
  if (orgIdsSearch) {
    // 过滤掉"无组织机构"的数据
    queryParam.value["orgIdList"] = orgIdsSearch.filter(function (item) {
      return item != '0' && item != '1'
    });
    // 标记是否勾选"无组织机构"
    queryParam.value["noOrgFlag"] = (orgIdsSearch.indexOf('0') != -1);
    queryParam.value["orgFlag"] = (orgIdsSearch.indexOf('1') != -1);
  }
}

const handleSelect = () => {
  let template = selectTemplateRef.value.getRadioCurrentRow();
}
const handlePagination = (page) => {
  queryParam.value = {
    limit: page.limit,
    offset: page.offset,
    channelId: whatsappForm.channelId
  };
  curPageSize.value = page.limit;
  if (hasSearched.value) {
    queryTemplateByCondition();
    return;
  }
  queryTemplate(queryParam.value);
}
const handleFilterNode = (value, data) => {
  if (!value) {
    return true;
  }
  return data.label.indexOf(value) !== -1;
}

const init = () => {
  whatsappForm.recipient = audioVideoCaller.value == '6' ? '' : audioVideoCaller.value;
  callId.value = audioVideoCallId.value;
  if (audioVideoCallId.value && audioVideoCallId.value.indexOf(";") > -1){
    const tempItem = audioVideoCallId.value.split(";");
    callId.value = tempItem[0];
    callSerialNo.value = tempItem[1];
  }
  queryRegion();
  queryCountryCode();
  queryWhatsappChannel();
  queryOrgInfo();
}

const queryRegion = () => {
  const params = {"dictCode": 'CCN.COUNTRY_CODE'};
  queryDataDictionary(params).then(result => {
    for(let key in result){
      regions.value.push({'label': result[key], 'value': key})
    }
  })
}

const queryCountryCode = () => {
  querySysparamInterCode().then(result => {
    whatsappForm.region = '+' + result.internationalAreaCode;
  })
}

const queryWhatsappChannel = () => {
  queryWhatsAppChannelList().then(result => {
    if (!result || result.returnCode != 0) {
      return;
    }
    whatsAppChannels.value = result.returnData;
  });
}

const handleClose = () => {
  if (showForm.value) {
    hasSearched.value = false;
    whatsappDialogVisible.value = false;
    emit('handleWhatsappClose');
  } else {
    closeOtherNav();
  }
}

const sendMessage = ruleForm => {
  if (!ruleForm) {
    return;
  }
  if (whatsappForm.bodyParameters && whatsappForm.bodyParameters.length > 0) {
    let idx = whatsappForm.bodyParameters.findIndex(item => !item.parameterContent || item.parameterContent == '');
    if (idx > -1) {
      return;
    }
  }
  if (whatsappForm.buttons && whatsappForm.buttons.length > 0) {
    let idx = whatsappForm.buttons.findIndex(item => !item.buttonValue || item.buttonValue == '');
    if (idx > -1) {
      return;
    }
  }
  ruleForm.validate(valid => {
    if (valid) {
      buildWhasappMsgParam();
    } else {
      return false;
    }
  });
}

const buildWhasappMsgParam = () => {
  repeatClick.value = true;
  let buttons = whatsappForm.buttons;
  let buttonList = [];
  let btnArr = [];
  for (let index = 0; index < buttons.length; index++) {
    let btn = buttons[index];
    buttonList.push({
      key: btn.buttonName,
      value: btn.buttonValue
    });
    if (whatsappForm.buttonsType == "0") {
      btnArr.push({ "quickReplyPayload": btn.buttonValue });
    }
  }
  let bodyContent = whatsappForm.bodyContent;
  let placeholders = [];
  let params = whatsappForm.bodyParameters;
  if (params && params.length > 0) {
    for (let index = 0; index < params.length; index++) {
      let parameter = params[index].parameterContent;
      bodyContent = bodyContent.replace(/\{\{\d+\}\}/, parameter);
      placeholders.push(parameter);
    }
  }

  let contentJO = {};
  // header
  contentJO.header = whatsappForm.headerContent;

  // body
  let bodyJO = {};
  bodyJO.placeholders = placeholders;
  contentJO.body = bodyJO;

  // buttons
  contentJO.buttons = btnArr;

  // language
  contentJO.language = whatsappForm.language;
  contentJO.bodyContent = bodyContent;
  sendWhatsappMsg(buttonList, contentJO);
}

const sendWhatsappMsg = (buttonList, contentJO) => {
  let templateJO = {
      name: whatsappForm.whatAppTemplateName,
      headerType: whatsappForm.headerType,
      footer: whatsappForm.footer,
      buttonsType: whatsappForm.buttonsType,
      actionButtons: buttonList,
  };
  let messageJO = {
      content: contentJO,
      template: templateJO
  };

  let backMsgModel = {};
  backMsgModel.from = whatsappForm.channelId;
  backMsgModel.to = whatsappForm.recipient;
  backMsgModel.content = JSON.stringify(messageJO);
  backMsgModel.callId = callId.value;
  backMsgModel.region = whatsappForm.region.substring(1);
  backMsgModel.callSerialNo = callSerialNo.value;
  audioVideoSendWhatsAppMsg(backMsgModel).then(result => {
    repeatClick.value = false;
    if (result.returnCode == 0) {
      // 发送成功；
      AiccElMessage.success($t('agentconsole.ccnotification.has.been.sent'));
      handleClose();
      return;
    } else {
      // 发送失败
      AiccElMessage.error(errorCodeMap[result.returnCode]);
    }
  })
}

const selectWhatsappTemplate = () => {
  showForm.value = false;
  showSelectTemplate.value = true;
  queryParam.value = {
    limit: paginationConfig.pageSize,
    offset: 0
  };
  queryParam.value.noOrgFlag = false
  queryParam.value.orgIdList = [];
  queryTemplateByCondition();
}

const closeOtherNav = () => {
  if (showOrg.value) {
    showSelectTemplate.value = true;
    showOrg.value = false;
  } else {
    showForm.value = true;
    showSelectTemplate.value = false;
    showOrg.value = false;
    showFile.value = false;
    hasSearched.value = false;
  }
}

const submitSelectTemplate = () => {
  selectTemplateObj.value = selectTemplateRef.value.getRadioCurrentRow();
  if (!selectTemplateObj.value || !selectTemplateObj.value.id) {
    AiccElMessage.info($t('ccnotification.message.choose'));
    return;
  }
  buildTemplateContent();
  closeOtherNav();
}

const selectMediaFile = () => {
  showForm.value = false;
  showSelectTemplate.value = false;
  showFile.value = true;
}

const selectTemplate = (item) => {
  selectTemplateObj.value = item;
}

const selectFile = (item) => {
  selectFileObj.value = item;
}

const submitFile = () => {
  whatsappForm.headerContent = selectFileObj.value.id;
  whatsappForm.mediaFileName = selectFileObj.value.name;
  closeOtherNav();
}

const buildTemplateContent = () => {
  copyValue();
  // headerType为0时不需要查询
  if (whatsappForm.headerType != 0) {
    let param = { id: whatsappForm.headerContent};
    ccmeQueryMultiMediaLibraryById(param).then(result => {
      if (!result || result.length < 1) {
        return;
      }
      whatsappForm.mediaFileName = result[0].name;
    });
  }
}

const copyValue = () => {
  if (!selectTemplateObj.value.templateId) {
    return;
  }
  whatsappForm.bodyContent = selectTemplateObj.value.bodyContent;
  whatsappForm.bodyParameters = dealBodyPar(selectTemplateObj.value.bodyParameters);
  whatsappForm.bodyParametersDes = selectTemplateObj.value.bodyParametersDes;
  whatsappForm.buttons = dealButtonsPar(selectTemplateObj.value.buttons);
  whatsappForm.buttonsType = selectTemplateObj.value.buttonsType;
  whatsappForm.description = selectTemplateObj.value.description;
  whatsappForm.footer = selectTemplateObj.value.footer;
  whatsappForm.headerContent = selectTemplateObj.value.headerContent;
  whatsappForm.headerType = selectTemplateObj.value.headerType;
  whatsappForm.id = selectTemplateObj.value.id;
  whatsappForm.language = selectTemplateObj.value.language;
  whatsappForm.name = selectTemplateObj.value.name;
  whatsappForm.orgId = selectTemplateObj.value.orgId;
  whatsappForm.templateId = selectTemplateObj.value.templateId;
  whatsappForm.whatAppTemplateDailyLimit = selectTemplateObj.value.whatAppTemplateDailyLimit;
  whatsappForm.whatAppTemplateName = selectTemplateObj.value.whatAppTemplateName;
}

const dealBodyPar = (str) => {
  if (!str || str == "null") {
    return [];
  }
  let bodyParameters = [];
  let parametersList = str.split(",");
  for (let i = 0; i < parametersList.length; i++) {
    let item = {
      "no": i + 1,
      "parameterDescription": parametersList[i],
      "parameterContent": parametersList[i]
    };
    bodyParameters.push(item);
  }
  return bodyParameters;
}

const dealButtonsPar = (arr) => {
  if (!arr || arr == "null") {
    return [];
  }
  let buttons = [];
  let buttonList = JSON.parse(arr);
  for (let i = 0; i < buttonList.length; i++) {
    for (let key in buttonList[i]) {
      if (Object.prototype.hasOwnProperty.call(buttonList[i], key)) {
        let item = {};
        item['no'] = i + 1;
        item['buttonName'] = key;
        item['buttonValue'] = buttonList[i][key];
        buttons.push(item)
      }
    }
  }
  return buttons;
}

const clearHistoryData = () => {
  selectTemplateObj.value = {};
  copyValue();
}
</script>
<style scoped lang="less">
.form-item-label {
  width: 200px;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.input-with-tips {
  display:flex;
  flex-direction: column
}
.error-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-bottom: 2px;
  display: inline-block;
  max-width: 100%;
  color: var(--el-color-danger);
  font-size: 12px;
  line-height: 1;
  padding-top: 2px
}
.return_icon {
  line-height: 24px;
}
.whatsappForm_recipient {
  :deep(.el-input-group--prepend) {
    .el-input__wrapper {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
  :deep(.el-input-group__prepend) {
    padding: 0 !important;
    .el-input__wrapper {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-top-left-radius: 4px !important;
      border-bottom-left-radius: 4px !important;
    }
  }
}
.templateData_nav {
  width: 100%;
  height: 200px;
  color: var(--swt-color-gray5);
  border-radius: 4px;
  text-align: center;
  line-height: 100px;
}
.dialog_title {
  line-height: var(--swt-font-line-height-normal1);
  font-weight: var(--swt-font-weight-bold);
  font-size: var(--swt-font-size-normal1);
  color: var(--swt-color-gray10);
}
.dialog_title_sub {
  margin-inline-start: 10px;
}
.templateParameters_nav {
  margin: 20px 25px 20px 10px;
}
.templateButtons_nav {
  margin: 0 25px 0 10px;
}
.cust_header {
  --swt-color-gray10: var(--color-text--h1);
  display: flex;
  justify-content: flex-start;
}
.inner-pattern-footer {
  display: flex;
  justify-content: space-between;
}
.dialog-footer :deep(.el-button) {
  margin-inline-start: var(--el-dialog-padding-primary);
}
.inner-text {
  margin: auto 0;
}
</style>
<style lang="less" scoped>
body.RTL {
  .return_icon {
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
    line-height: 24px;
  }
  .whatsappForm_recipient {
    :deep(.el-input-group--prepend) {
      .el-input__wrapper {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-top-left-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
      }
    }
    :deep(.el-input-group__prepend) {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-right: 1.5px solid var(--swt-color-gray5);
      .el-input__wrapper {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        border-top-right-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
        border-left: 0 !important;
      }
    }
  }
}
</style>