const ccnRouter = {
  pageWithSideNav: [
    {
      path: '/notification-route',
      name: '8002007',
      title: '通知路由',
      component: () => import('./components/notification-route/notification-route-main.vue')
    },
    {
      path: '/email-signature',
      name: '8003005',
      title: '邮件签名',
      component: () => import('./components/email-signature/email-signature.vue')
    },
    {
      path: '/notification-variable',
      name: '8003010',
      title: '通知变量',
      component: () => import('./components/notification-variable/ccn-variable.vue')
    },
    {
      path: '/notification-type',
      name: '8003011',
      title: '通知类型',
      component: () => import('./components/notification-type/bulletin-type.vue')
    },
    {
      path: '/gateway-monitor',
      name: '8011014',
      title: '网关监控',
      component: () => import('./components/gateway-monitor/index.vue')
    },
    {
      path: '/message-template-sys',
      name: '8003009',
      title: '系统管理员通知模板',
      component: () => import('./components/message-template/message-template-sys.vue')
    },
    {
      path: '/message-template-tenant',
      name: '8003012',
      title: '租间管理员通知模板',
      component: () => import('./components/message-template/message-template-tenant.vue')
    },
    {
      path: '/gateway-config',
      name: '8002005',
      title: '网关配置',
      component: () => import('./components/gateway-config/index.vue')
    },
    {
      path: '/sys-config',
      name: '8002006',
      title: '短信配置',
      component: () => import('./components/sms-config/index.vue')
    },
  ],
  pageWithBackHeader: [
    {
      path: '/message-template/message-template-info',
      name: 'edit-message-template-info',
      title: '修改通知模板',
      component: () => import('./components/message-template/edit-message-template-info.vue')
    },
    {
      path: '/gateway-view',
      name: 'gateway-view',
      title: '网关配置',
      component: () => import('./components/gateway-config/gateway-config-view.vue')
    }
  ],
  pageWithSelfTab: [
    {
      path: '/message-template/add-message-template-info',
      name: 'add-message-template-info',
      title: '新建通知模板',
      component: () => import('./components/message-template/add-message-template-info.vue')
    },
    {
      path: '/gateway-add',
      name: 'gateway-add',
      title: '网关配置',
      component: () => import('./components/gateway-config/gateway-config-detail.vue')
    }
  ],
  default: [
    {
      path: '/message-center',
      name: 'message-center',
      title: '通知查看',
      component: () => import('./components/message-center/index.vue')
    },
    {
      path: '/send-tenant-message',
      name: 'send-tenant-message',
      title: '租间管理员通知发送',
      component: () => import('./components/send-message/send-tenant-message.vue')
    },
    {
      path: '/send-message',
      name: 'send-message',
      title: '系统管理员通知发送',
      component: () => import('./components/send-message/send-message.vue')
    },
    {
      path: '/send-agent-message',
      name: 'send-agent-message',
      title: '坐席通知发送',
      component: () => import('./components/send-message/send-agent-message.vue')
    },
    {
      path: '/edit-draft',
      name: 'edit-draft',
      title: '编辑草稿',
      component: () => import('./components/message-center/edit-draft.vue')
    },
    {
      path: '/reply-message',
      name: 'reply-message',
      title: '站内信回复',
      component: () => import('./components/message-center/reply-message.vue')
    }
  ]
}

export default ccnRouter