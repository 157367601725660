import dyEnUS from './dy_en_US'
import dyZhCN from './dy_zh_CN'
import dyEsEs from './dy_es_ES'
import dyThTH from './dy_th_TH'
import dyPtBr from './dy_pt_BR'
import dyFrFr from './dy_fr_FR'
import dyArSa from './dy_ar_SA'
import dyDeDE from "./dy_de_DE";

const dy = {
  en_US: {
    ...dyEnUS,
  },
  zh_CN: {
    ...dyZhCN,
  },
  es_ES: {
    ...dyEsEs,
  },
  th_TH: {
    ...dyThTH,
  },
  pt_BR: {
    ...dyPtBr,
  },
  fr_FR: {
    ...dyFrFr
  },
  ar_SA: {
    ...dyArSa
  },
  de_DE: {
    ...dyDeDE
  },
}

export default dy