import i18n from '@/lang/index'

const $t = i18n.global.t

const regEnSpecialChar = /[`~!@#$%^&*()+=|{}":;\[\]/?.<>]/
const regCnSpecialChar = /[~！@#￥%……&*（）——+|{}【】‘；：”“’,、？]/
const regUrlChar = /^((https)|(http)):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]$/
const regCheckCalleeNo = /^\d+$/
const regRemarkChar = /[`~!@#$%^&*()+=|{}":;\[\]/?.~！@#￥%……&*（）——+|{}【】‘；：”“’,、？\\]/
const regCheckInterval = /(^[1-9]\d*$)/
const regCheckNumAndAbc = /(^[a-zA-Z0-9\\-]+$)/
const regCheckNumAndAbcAndPoint = /(^[a-zA-Z0-9.\\-]+$)/
const regCheckOrderNo = /(^[A-E]?[A-Z]$)/
const regCheckIP =
  /^((25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))\.){3}(25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))$/
const regSpecialString = /[<>!@#$%^&*?;\"~\\\/]/

function trim(str) {
  return str.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, "")
}

function hasSpecialChar(value) {
  return regEnSpecialChar.test(value) || regCnSpecialChar.test(value)
}

function hasRegUrlCharChar(value) {
  return regUrlChar.test(value)
}

function numberOnly(value) {
  return regCheckCalleeNo.test(value)
}

function existSpecialChar(value) {
  return regRemarkChar.test(value)
}

const checkSpecialChar = (rule, value, callback) => {
  if (hasSpecialChar(value)) {
    callback($t('ccm.agent.message.pageNameValidateFail'))
  } else {
    callback()
  }
}

const checkSpecialString = (rule, value, callback) => {
  if (regSpecialString.test(value)) {
    callback($t('oifde.common.validation.nameRange'))
  } else {
    callback()
  }
}

const checkCallNoTypeSpecialChar = (rule, value, callback) => {
  if (hasSpecialChar(value)) {
    callback(
      $t('ccm.agent.message.pageNameValidateFail') +
      '`~!@#$%^&*()+=|{}\'":;\\[\\]/?.<>~！@#￥%……&*（）——+|{}【】‘；：”“’,、？'
    )
  } else {
    callback()
  }
}
const checkUrlChar = (rule, value, callback) => {
  if (!value) {
    callback()
  } else if (!hasRegUrlCharChar(value)) {
    callback($t('isales.taskinfo.message.callBackUrl'))
  } else {
    callback()
  }
}

const checkCalledNo = (rule, value, callback) => {
  if (value == '' || null == value) {
    callback()
  }
  if (numberOnly(value)) {
    callback()
  } else {
    callback($t('isales.management.message.calledNoFormatIncorrect'))
  }
}

const checkRetryNo = (rule, value, callback) => {
  if (null == value || value === '') {
    callback($t('cms.export.message.required'))
  }
  if (!numberOnly(value)) {
    callback($t('cms.report.field.number'))
  } else {
    // 重试次数范围限定0-3
    if ((value >= 0 && value <= 3)) {
      callback()
    }
    callback($t('ccnotification.notification.notificationRoute.retryNumLimitRule'))
  }
}

const checkReceiveAddr = (rule, value, callback) => {
  if (null == value || value === '') {
    callback($t('cms.export.message.required'))
  }
  let tmpStr = String(value);
  let parts = tmpStr.split(';');
  if (tmpStr.length > 1024) {
    callback($t('ccnotification.notification.notificationRoute.receiveAddrLen'))
  }
  for (let i = 0; i < parts.length; i++) {
    if (!numberOnly(parts[i])) {
      callback($t('ccd.agent.message.InvalidNumber'))
    }
  }
  callback();
}

const validateRemark = (rule, value, callback) => {
  if (existSpecialChar(value)) {
    callback($t('ccm.agent.message.pageNameValidateFail'))
  } else {
    callback()
  }
}

const numberOnlyValidator = (rule, value, callback) => {
  if (!value) {
    callback()
  }
  if (numberOnly(value)) {
    callback()
  } else {
    callback($t('aicc.numberOnly'))
  }
}

// 用例：{ maxLength: 30, validator: this.$Validate.maxLengthValidator, trigger: ['blur', 'change']}
const maxLengthValidator = (rule, value, callback) => {
  if (value && value.length > rule.maxLength) {
    callback($t('aicc.lengthLimit', { limit: rule.maxLength }))
  } else {
    callback()
  }
}

// 用例：{ maxLength: 30, validator: this.$Validate.maxByteLengthValidator, trigger: ['blur', 'change']}
const maxByteLengthValidator = (rule, value, callback) => {
  if (value) {
    const encoder = new TextEncoder();
    const utf8Array = encoder.encode(value);
    const length = utf8Array.length;
    if (length > rule.maxLength) {
      callback($t('aicc.lengthLimit', {limit: rule.maxLength}))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

// 用例：{ maxLength: 30, validator: this.$Validate.maxLengthValidator, trigger: ['blur', 'change']}
const minLengthValidator = (rule, value, callback) => {
  if (value && value.length < rule.minLength) {
    callback($t('aicc.lengthMinLimit', [rule.minLength]))
  } else {
    callback()
  }
}

const checkDefaultIntervalRang = (rule, value, callback) => {
  if (!regCheckInterval.test(value)) {
    callback($t('isales.taskinfo.message.callintervalrangeMax'))
  }

  if (parseInt(value) > 86400 || parseInt(value) < 1) {
    callback($t('isales.taskinfo.message.callintervalrangeMax'))
  }
  callback()
}

const checkDefaultIntervalRangAndEmpty = (rule, value, callback) => {
  if (value === null || value === '' || value == undefined) {
    callback()
    return
  }
  if (!regCheckInterval.test(value)) {
    callback($t('isales.taskinfo.message.callintervalrange'))
    return
  }

  if (parseInt(value) > 3600 || parseInt(value) < 1) {
    callback($t('isales.taskinfo.message.callintervalrange'))
    return
  }
  callback()
}

const checkNotEmptyAndSpecialChar = (value) => {
  if (value === '' || value === undefined) {
    return $t('isales.business.result.message.must')
  } else if (hasSpecialChar(value)) {
    return $t('ccm.agent.message.pageNameValidateFail')
  } else {
    return true
  }
}

const checkRegCheckNumAndAbc = (rule, value, callback) => {
  if (!regCheckNumAndAbc.test(value)) {
    callback($t('isales.business.result.message.checkCode'))
  } else {
    callback()
  }
}
const checkRegCheckNumAndAbcNull = (rule, value, callback) => {
  if (value && !regCheckNumAndAbc.test(value)) {
    callback($t('isales.business.result.message.checkCode'))
  } else {
    callback()
  }
}
const checkRegCheckNumAndAbcPointNull = (rule, value, callback) => {
  if (value && !regCheckNumAndAbcAndPoint.test(value)) {
    callback($t('isales.business.result.message.checkCodePoint'))
  } else {
    callback()
  }
}

const checkOrderNo = (rule, value, callback) => {
  if (value && !regCheckOrderNo.test(value)) {
    callback($t('isales.file.title.rule.validator.row'))
  } else {
    callback()
  }
}

const checkIP = (rule, value, callback) => {
  if (value && !regCheckIP.test(value)) {
    callback($t('isales.management.message.dbIp'))
  } else {
    callback()
  }
}
const isalesCheckSpecialChar = (rule, value, callback) => {
  if (hasSpecialChar(value)) {
    callback(
      $t('ccm.agent.message.pageNameValidateFail') +
      '`~!@#$%^&*()+=|{}\'":;[]/?.<>~！@#￥%……&*（）——+|{}【】‘；：”“’,、？'
    )
  } else {
    callback()
  }
}

const isalesCheckPwdChar = (rule, value, callback) => {
  if (value == '********') {
    callback()
  }
  var regUpper = /[A-Z]/
  var regLower = /[a-z]/
  var regNum = /[0-9]/
  var regSpecial = /[`~!@#$^&*()\-_\+|{}<.>/?\[\]]/
  var complex = 0
  if (regLower.test(value)) {
    ++complex
  }
  if (regUpper.test(value)) {
    ++complex
  }
  if (regNum.test(value)) {
    ++complex
  }
  if (regSpecial.test(value)) {
    ++complex
  }
  if (complex < 3) {
    callback($t('isales.agent.message.passValidateFailed'))
  } else {
    callback()
  }
}
const email = (rule, value, callback) => {
  const eamilTest =
    /^((([A-Za-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([A-Za-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([A-Za-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([A-Za-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([A-Za-z]|\d|-|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([A-Za-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([A-Za-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([A-Za-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([A-Za-z]|\d|-|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([A-Za-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i
  if (!eamilTest.test(value)) {
    callback($t('SM.LOGIN.RESETPWD.EMAILPATTERN_ERROR'))
  }
  callback()
}

const range = (rule, value, callback) => {
  if (value < rule.min || value > rule.max) {
    callback($t('WEB.VALIDATOR.RANGE', [rule.min, rule.max]))
  }
  callback()
}

const digits = (rule, value, callback) => {
  if (!value) {
    callback()
  }
  const reg = /^\d+$/
  if (!reg.test(value)) {
    callback($t('oifde.common.validation.digits'))
  }
  callback()
}

const onlyHasNumWordLine = (rule, value, callback) => {
  const regx = /^[0-9a-zA-Z_]{1,}$/;
  if (!regx.test(value)) {
    callback($t('ccm.idauth.create.error.format'))
  }
  callback()
}

export default {
  hasSpecialChar,
  numberOnly,
  existSpecialChar,
  checkSpecialChar,
  checkCalledNo,
  checkRetryNo,
  checkReceiveAddr,
  validateRemark,
  numberOnlyValidator,
  checkUrlChar,
  maxLengthValidator,
  maxByteLengthValidator,
  checkDefaultIntervalRang,
  checkNotEmptyAndSpecialChar,
  checkDefaultIntervalRangAndEmpty,
  checkCallNoTypeSpecialChar,
  checkRegCheckNumAndAbc,
  checkOrderNo,
  checkRegCheckNumAndAbcNull,
  checkIP,
  minLengthValidator,
  isalesCheckSpecialChar,
  isalesCheckPwdChar,
  checkSpecialString,
  checkRegCheckNumAndAbcPointNull,
  email,
  range,
  digits,
  onlyHasNumWordLine
}
