<template>
    <div>
        <sweet-dialog v-model="dialogVisible" :title="$t('isales.calltime.title.add.date')" width="616px" class="aicc-dialog-limit-height" :close-on-click-modal="false">
            <aicc-table
                    :tableData="specialDayList"
                    :tableColumns="tableColumns"
                    :tableTools="{ showPagination: true, columnConfig: false }"
                    :paginationConfig="paginationConfig"
                    @handlePagination="handlePagination"
                    :row-key="getKey"
                    ref='refAiccTable'
                    :max-height="430">
            </aicc-table>
            <template #footer>
      <span class="dialog-footer">
        <sweet-button @click="dialogVisible = false">{{ $t('isales.common.title.cancel') }}</sweet-button>
        <sweet-button type="primary" @click="confirm">{{ $t('isales.common.title.confirm') }}</sweet-button>
      </span>
            </template>
        </sweet-dialog>
    </div>
</template>

<script>
    import {SPECIALDAY_WEEKLY_ENUM} from "@/views/isales/views/systemAutoCallTask/enum.js";
    import {queryHoliday, queryHolidayCount} from "@/views/isales/api/systemAutoCallTask.js";

    export default {
        name: "isalesSpecialDate",
        props: {
            show: {
                type: Boolean,
                default: false
            },
          selectedAttributes: {
            type: Array,
            default() {
              return []
            }
          }
        },
        data() {
            return {
                specialDayList: [],
                specialDayWeeklyItem: SPECIALDAY_WEEKLY_ENUM,
                tableColumns: [
                    {
                        type: 'selection',
                        width: '50px',
                      'show-overflow-tooltip': false,
                      'reserve-selection': true
                    },
                    {
                        label: this.$t('isales.specialday.title.holidayName'),
                        prop: 'holidayName',
                    },
                    {
                        label: this.$t('isales.specialday.title.holiday'),
                        prop: 'holiday',
                    }
                ],
                paginationConfig: {
                    PageSize: 10, // 每页条数
                    total: 0, //总条数
                    layout: "total, prev, pager, next" // 分页组件布局配置
                },
                limit: 10,
                offset: 0
            }
        },
        computed: {
            dialogVisible: {
                get() {
                    return this.show
                },
                set(val) {
                    this.$emit('update:show', val)
                }
            }
        },
        methods: {
            async queryHoliday() {
                let queryCondition = {};
                queryCondition['specialdayDO'] = {};
                queryCondition['isSelectQuery'] = true;
                queryCondition['limit'] = this.limit;
                queryCondition['offset'] = this.offset;
                await queryHoliday(queryCondition).then(res => {
                    if (res) {
                        this.specialDayList = res;
                        if (this.specialDayList && this.specialDayList.length > 0) {
                            let holidayOnWeekly = "";
                            this.specialDayList.forEach(item => {
                                if (item.holidayType === "1") {
                                    if (item.holiday.length > 0) {
                                        let holidayArray = item.holiday.split(",");
                                        let holidayArrayLength = holidayArray.length;
                                        for (let j = 0; j < holidayArrayLength - 1; j++) {
                                            holidayOnWeekly = holidayOnWeekly + this.specialDayWeeklyItem[holidayArray[j]] + ",";
                                        }
                                        holidayOnWeekly = holidayOnWeekly + this.specialDayWeeklyItem[holidayArray[holidayArrayLength - 1]];
                                        item.holiday = holidayOnWeekly;
                                    }
                                } else if (item.holidayType === "2") {
                                    item.holiday = this.$TimeUtils.transDate(item.beginDate)
                                        + "~" + this.$TimeUtils.transDate(item.endDate);
                                } else {
                                    item.holiday = item.beginDate + "~" + item.endDate
                                }
                                holidayOnWeekly = "";
                            });
                        }
                    }
                });
                await queryHolidayCount(queryCondition).then(res => {
                    if (res) {
                        this.paginationConfig.total = res;
                    } else {
                        this.paginationConfig.total = 0;
                    }
                });
            },
            handlePagination(params) {
                this.limit = params.limit;
                this.offset = params.offset;
                this.queryHoliday(params);
            },
            confirm() {
                this.dialogVisible = false
                this.$emit('isalesSpecialDateConfirm', this.$refs.refAiccTable.table.getSelectionRows())
            },
          getKey(row){
            return row.holidayId
          },
        },
        async created() {
            await this.queryHoliday();
        },
    }
</script>

<style scoped>

</style>