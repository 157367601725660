import enLocale from './en_US'
import zhLocale from './zh_CN'
import esLocale from './es_ES'
import thLocale from './th_TH'
import ptBRLocale from './pt_BR'
import frLocale from './fr_FR'
import saLocale from './ar_SA'
import deLocale from './de_DE'


const messages = {
  en_US: {
    base: {
      ...enLocale
    }
  },
  zh_CN: {
    base: {
      ...zhLocale
    }
  },
  es_ES: {
    base: {
      ...esLocale
    }
  },
  th_TH: {
    base: {
      ...thLocale
    }
  },
  pt_BR: {
    base: {
      ...ptBRLocale
    }
  },
  fr_FR: {
    base: {
      ...frLocale
    }
  },
  ar_SA: {
    base: {
      ...saLocale
    }
  },
  de_DE: {
    base: {
      ...deLocale
    }
  },
}

export default messages
