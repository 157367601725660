const ccmanagementRoutes = {
  default: [
    {
      path: '/ccmacontactquery',
      name: '6018001',
      title: '接触记录列表',
      component: () => import('@/views/ccmanagement/pages/contact-query/contact-query.vue'),
    },
    {
      path: '/ccmaagentcalldtails',
      name: '6018008',
      title: '坐席呼叫明细',
      component: () => import('@/views/ccmanagement/pages/contact-query/agent-call-dtails.vue'),
    },
    {
      path: '/ccmacontractquerycasehis',
      name: 'ccmacontractquerycasehis',
      title: '接触记录列表-工单工作台跳转',
      component: () => import('@/views/ccmanagement/pages/contact-history/contact-case-history.vue'),
    },
    {
      path: '/ccmacontractqueryagenthis',
      name: 'ccmacontractqueryagenthis',
      title: '接触记录列表-音视频/chat工作台跳转',
      component: () => import('@/views/ccmanagement/pages/contact-history/contact-agent-history.vue'),
    },
    {
      path: '/ccmacontactdetail',
      name: 'ccmaContactDetailNewTab',
      title: '接触记录详情-新标签打开页',
      component: () => import('@/views/ccmanagement/pages/contact-detail/contactDetailNewTab.vue')
    },
    {
      path: '/ccmdualcallrecord',
      name: '6018003',
      title: '双呼记录',
      component: () => import('@/views/ccmanagement/pages/dual-call-record/DualCallRecord.vue')
    },
    {
      path: '/ccmaoperaterecord',
      name: '6019001',
      title: '操作记录',
      component: () => import('@/views/ccmanagement/pages/operation-record/OperationRecord.vue')
    },
    {
      path: '/ccmaloginrecord',
      name: '6019002',
      title: '签入日志',
      component: () => import('@/views/ccmanagement/pages/login-record/LoginRecord.vue')
    },
    {
      path: '/ccmatransferRecord',
      name: '6019003',
      title: '转接记录',
      component: () => import('@/views/ccmanagement/pages/transfer-record/TransferRecord.vue')
    },
    {
      path: '/ccmaleaverecord',
      name: '6019004',
      title: '座席休息记录',
      component: () => import('@/views/ccmanagement/pages/leave-record/LeaveRecord.vue'),
    },
    {
      path: '/skillResetRecord',
      name: '6019005',
      title: '技能重设记录',
      component: () => import('@/views/ccmanagement/pages/skill-reset-record/SkillResetRecord.vue'),
    },
    {
      path: '/verifyIdentityRecord',
      name: '6019006',
      title: '身份认证记录',
      component: () => import('@/views/ccmanagement/pages/verify-identity-record/VerifyIdentityRecord.vue'),
    },
    {
      path: '/contactManagement',
      name: '6018006',
      title: '文档管理',
      component: () => import('@/views/ccmanagement/pages/contact-management/ContactManagement.vue'),
    },
    {
      path: '/messageManagement',
      name: '6009',
      title: '留言管理',
      component: () => import('@/views/ccmanagement/pages/message-management/MessageManagement.vue'),
    },
    {
      path: '/ccmaSendEmailMsg',
      name: 'SendEmailMsg',
      title: '发送邮件消息',
      component: () => import('@/views/ccmanagement/pages/send-email-msg/SendEmailMsg.vue')
    },
    {
      path: '/IolpDiagram', // 可视化流程画布
      name: 'IolpDiagram',
      component: () => import('@/views/ccmanagement/pages/iolp-diagram/IolpDiagram.vue')
    },
    {
      path: '/IolpServiceMain', // 可视化流程运行界面
      name: 'IolpServiceMain',
      component: () => import('@/views/ccmanagement/pages/iolp-service/IolpServiceMain.vue')
    },
    {
      path: '/IolpServiceSubFlow', // 可视化流程子流程运行界面
      name: 'IolpServiceSubFlow',
      component: () => import('@/views/ccmanagement/pages/iolp-service/IolpServiceSubFlow.vue')
    },
  ],
  pageWithSideNav: [
    {
      path: '/ccmaskillmanage',
      name: '8001004',
      title: '技能',
      component: () => import('@/views/ccmanagement/pages/ccskill-manage/SkillQuery.vue')
    },
    {
      path: '/ccmaIolpConfigureService',
      name: '8003013',
      title: '业务引导配置',
      component: () => import('@/views/ccmanagement/pages/iolp-configure-service/IolpConfigureService.vue')
    },
    {
      path: '/ccmaCallReason',
      name: '8003001',
      title: '来电原因',
      component: () => import('@/views/ccmanagement/pages/configuration-center/workbench-configuration/call-reason/CallReason.vue')
    },
    {
      path: '/ccmaPromptInformation',
      name: '8003003',
      title: '提示音配置',
      component: () => import('@/views/ccmanagement/pages/configuration-center/workbench-configuration/prompt-information/PromptInformation.vue')
    },
    {
      path: '/restReason',
      name: '8003004',
      title: '休息原因', 
      component: () => import('@/views/ccmanagement/pages/configuration-center/workbench-configuration/rest-reason/RestReasonConfig.vue')
    },
    {
      path: '/externalNumber',
      name: '8008009',
      title: '转接外部号码配置',
      component: () => import('@/views/ccmanagement/pages/configuration-center/resource-management/external-number/ExternalNumber.vue')
    },
    {
      path: '/harassmentRecord',
      name: '8008007',
      title: '骚扰记录管理',
      component: () => import('@/views/ccmanagement/pages/configuration-center/resource-management/harassment-record/HarassmentRecord.vue')
    },
    {
      path: '/resourceDumpTask',
      name: '8011002',
      title: '租户基本信息',
      component: () => import('@/views/ccmanagement/pages/configuration-center/system-management/tenant-information/TenantInformation.vue')
    },
    {
      path: '/resourceDumpTask',
      name: '8011011',
      title: '资源转储任务记录',
      component: () => import('@/views/ccmanagement/pages/configuration-center/system-management/resource-dump-task/ResourceDumpTask.vue')
    },
    {
      path: '/calledParty',
      name: '8002002',
      title: '被叫配置',
      component: () => import('@/views/ccmanagement/pages/configuration-center/access-configuration/called-party/CalledRoute.vue')
    },
    {
      path: '/ccmaAccessCode',
      name: '8002001',
      title: '接入码',
      component: () => import('@/views/ccmanagement/pages/configuration-center/access-configuration/access-code/AccessCode.vue')
    },
    {
      path: '/mobileAgent',
      name: '8002004',
      title: '移动客服配置',
      component: () => import('@/views/ccmanagement/pages/configuration-center/access-configuration/mobile-agent/MobileAgent.vue')
    },
    {
      path: '/ccmaContactDataItem',
      name: '8010007',
      title: '接触记录数据项',
      component: () => import('@/views/ccmanagement/pages/configuration-center/expansion-and-integration/contact-data-item/ContactDataItem.vue')
    },
    {
      path: '/resourceDump',
      name: '8011010',
      title: '资源转储配置',
      component: () => import('@/views/ccmanagement/pages/configuration-center/system-management/resource-dump/ResourceDump.vue')
    },
    {
      path: '/ccmaVoiceNotification',
      name: '8010009',
      title: '语音通知',
      component: () => import('@/views/ccmanagement/pages/configuration-center/expansion-and-integration/voice-notification/VoiceNotificationConfig.vue')
    },
    {
      path: '/dualcall',
      name: '8010004',
      title: '双呼',
      component: () => import('@/views/ccmanagement/pages/configuration-center/expansion-and-integration/dual-call/DualCallConfig.vue')
    },
    {
      path: '/idauthconfig',
      name: '8010010',
      title: '身份验证',
      component: () => import('@/views/ccmanagement/pages/configuration-center/expansion-and-integration/authenticate/IdentityAuthConfig.vue')
    },
    {
      path: '/ccmaUCIntefration',
      name: '8010008',
      title: 'UC集成',
      component: () => import('@/views/ccmanagement/pages/configuration-center/expansion-and-integration/uc-integration/UCIntegration.vue')
    },
    {
      path: '/certificateManagement',
      name: '8011006',
      title: '证书管理',
      component: () => import('@/views/ccmanagement/pages/configuration-center/system-management/certificate/CertificateManagement.vue')
    },
    {
      path: '/basicTableQuery',
      name: '8010011',
      title: '数据表查询',
      component: () => import('@/views/ccmanagement/pages/configuration-center/expansion-and-integration/basic-table-query/BasicTableQuery.vue')
    },
    {
      path: '/tenantParamConfig',
      name: '8011003',
      title: '租户参数配置',
      component: () => import('@/views/ccmanagement/pages/tenant-param-config/tenant-param-config.vue')
    },
    {
      path: '/basicTableConfig',
      name: '8010005',
      title: '基础表配置',
      component: () => import('@/views/ccmanagement/pages/basictable-config/basictable-config.vue')
    },
    {
      path: '/popUpScreen',
      name: '8010003',
      title: '弹屏配置',
      component: () => import('@/views/ccmanagement/pages/configuration-center/expansion-and-integration/pop-up-screen/PopUpScreen.vue')
    },
    {
      path: '/specialList',
      name: '8008003',
      title: '特殊名单管理',
      component: () => import('@/views/ccmanagement/pages/configuration-center/resource-management/special-list/SpecialListManage.vue')
    },
    {
      path: '/pageConfig',
      name: '8010006',
      title: '页面配置',
      component: () => import('@/views/ccmanagement/pages/configuration-center/expansion-and-integration/page-config/PageConfig.vue')
    },
    {
      path: '/satisfactionConfig',
      name: '8003002',
      title: '满意度调查设置',
      component: () => import('@/views/ccmanagement/pages/satisfaction-config/satisfaction-config.vue')
    },
    {
      path: '/agentManagement',
      name: '8001005',
      title: '座席管理',
      component: () => import('@/views/ccmanagement/pages/configuration-center/employee-center/agent-management/CCAgentQuery.vue')
    }
  ],
  pageWithBackHeader: [
    {
      path: '/ccmacontactdetailagentsink',
      name: 'ccmaContactDetailAgentWorkbenchSink',
      title: '接触记录详情下沉页-音视频/chat工作台入口',
      component: () => import('@/views/ccmanagement/pages/contact-detail/contactDetailAgentWorkbenchSink.vue'),
    },
    {
      path: '/ccmacontactdetailcasesink',
      name: 'ccmaContactDetailCaseWorkbenchSink',
      title: '接触记录详情下沉页-工单工作台入口',
      component: () => import('@/views/ccmanagement/pages/contact-detail/contactDetailCaseWorkbenchSink.vue'),
    },
    {
      path: '/ccmacontactdetailsidemenusink',
      name: 'ccmaContactDetailSideMenuSink',
      title: '接触记录详情下沉页-侧边菜单入口',
      component: () => import('@/views/ccmanagement/pages/contact-detail/contactDetailSideMenuSink.vue')
    },
    {
      path: '/skill-queue/modify',
      name: 'modifySkillQueue',
      title: '修改技能队列-下沉页',
      component: () => import('@/views/ccmanagement/pages/ccskill-manage/skill-queue/SkillQueueModify.vue')
    },
    {
      path: '/skill-group/modify',
      name: 'modifySkillGroup',
      title: '修改技能组-下沉页',
      component: () => import('@/views/ccmanagement/pages/ccskill-manage/skill-group/SkillGroupModify.vue')
    },
    {
      path: '/satisfaction/strategyconfig',
      name: 'satisfactionStrategyConfig',
      title: '满意度策略配置',
      component: () => import('@/views/ccmanagement/pages/satisfaction-config/satisfaction-strategyconfig.vue')
    },
    {
      path: '/configAgent',
      name: 'CCAgentModify',
      title: '配置座席-下沉页',
      component: () => import('@/views/ccmanagement/pages/configuration-center/employee-center/agent-management/CCAgentModify.vue')
    },
    {
      path: '/batchConfigAgent',
      name: 'CCAgentBatchModify',
      title: '批量配置座席-下沉页',
      component: () => import('@/views/ccmanagement/pages/configuration-center/employee-center/agent-management/CCAgentBatchModify.vue')
    }
  ],
  pageWithSelfTab: [
    {
      path: '/skill-queue/add',
      name: 'addSkillQueue',
      title: '新增技能队列',
      component: () => import('@/views/ccmanagement/pages/ccskill-manage/skill-queue/SkillQueueAdd.vue')
    },
    {
      path: '/skill-group/add',
      name: 'addSkillGroup',
      title: '新增技能组',
      component: () => import('@/views/ccmanagement/pages/ccskill-manage/skill-group/SkillGroupAdd.vue')
    },
    {
      path: '/basicTableConfig/config',
      name: 'basicTableColumnConfig',
      title: '基础表列配置',
      component: () => import('@/views/ccmanagement/pages/basictable-config/basictablecolumn-config.vue')
    }
  ]
}
export default ccmanagementRoutes
