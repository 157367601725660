export default {
  "ikbs.constableTmp.config" : "Configurar",
  "ikbs.constableTmp.status.draft" : "Borrador",
  "ikbs.cntTmp.import.rowNumOver" : "Se puede importar un mÃ¡ximo de 10 registros.",
  "ikbs.column.unlock.message.warning" : "InformaciÃ³n de contacto",
  "ikbs.knowledge.label.abstract" : "Abstracto",
  "ikbs.properties.add.information" : "AÃ±adir atributo extendido",
  "ikbs.contTmp.exception.maxCountPerTenant" : "Hasta 500 plantillas para un solo tenant",
  "ikbs.knowledge.properties.operation.upMove" : "Arriba",
  "ikbs.column.delete.selectNoColumnKngToDelete" : "No se selecciona ningÃºn conocimiento de categorÃ­a.",
  "ikbs.tips.canNotAddColumnKngOnHistoryColumn" : "No se puede agregar conocimiento a la categorÃ­a de historial.",
  "ikbs.knowledge.properties.label.inputWay" : "Tipo de entrada",
  "ikbs.contentTmp.exception.tmpContentAndEditStandard" : "El contenido de la plantilla y las especificaciones de ediciÃ³n no pueden estar vacÃ­os al mismo tiempo",
  "ikbs.knowledge.label.uploadSingleFile" : "Solo se puede cargar un archivo adjunto a la vez.",
  "ikbs.knowledge.tip.operateSuccess" : "OperaciÃ³n exitosa.",
  "ikbs.constableTmp.operator.lt" : "menos de",
  "ikbs.enter.search.condition" : "Por favor, introduzca la condiciÃ³n de bÃºsqueda",
  "ikbs.search.label.sortByDefault" : "OrdenaciÃ³n por defecto",
  "ikbs.knowledge.recycle.coluKngType" : "Tipo",
  "ikbs.consult.table" : "Formulario de consulta",
  "ikbs.knowledge.label.path" : "Ruta",
  "ikbs.column.release.message.fail" : "Error de publicaciÃ³n.",
  "ikbs.column.knowledge.columnInformation" : "AÃ±adir categorÃ­a",
  "ikbs.constableTmp.operator.le" : "inferior o igual a",
  "ikbs.knowledge.service.common" : "Frecuentes",
  "ikbs.knowledge.recycle.select.knowledgeType" : "Tipo de conocimiento",
  "ikbs.tips.propertyDictValueAtLeastOne.warning" : "El atributo extendido debe tener al menos un valor de diccionario.",
  "ikbs.column.displayflag.hide" : "No",
  "ikbs.column.knowledge.button.add" : "Nuevo",
  "ikbs.column.knowledge.modify.properties" : "Atributos de modificaciÃ³n por lotes",
  "ikbs.knowledge.release.public" : "Publicar directamente",
  "ikbs.knowledge.label.selectProperty" : "Elegir",
  "ikbs.contentTmp.status.draft" : "Borrador",
  "ikbs.knowledge.label.serviceStatus" : "Estado del negocio",
  "ikbs.knowledge.service.update" : "Negocio actualizado",
  "ikbs.column.serviceStatus.newService" : "Nuevos negocios",
  "ikbs.knowledge.label.answer" : "Respuestas",
  "ikbs.constableTmp.reset" : "Restablecer",
  "ikbs.knowledge.label.modifyTime" : "Ãltima actualizaciÃ³n el",
  "ikbs.column.type.historyMark" : "Historia",
  "ikbs.kngCnt.cntTmp.icon.tip" : "Plantilla de contenido",
  "ikbs.knowledge.label.currentVersion" : "VersiÃ³n actual",
  "ikbs.columntype.column" : "CategorÃ­a",
  "ikbs.enter.search.keywords" : "Introduce una palabra clave de bÃºsqueda",
  "ikbs.contentTmp.delete.fail" : "Error en la eliminaciÃ³n",
  "ikbs.column.label.coluKngMaintaining" : "grupo de mantenimiento",
  "ikbs.knowledge.label.kngAttachmentDownload" : "Descargar archivo adjunto de categorÃ­a de conocimiento",
  "ikbs.search.label.updateEndTime" : "Hora de finalizaciÃ³n de la actualizaciÃ³n",
  "ikbs.knowledgeaudit.label.pass" : "Aprobar",
  "ikbs.knowledge.tip.selectColumn" : "Seleccione una categorÃ­a.",
  "ikbs.constableTmp.searchFlag" : "Ya sea para buscar las condiciones",
  "ikbs.properties.add.cancel" : "Cancelar",
  "ikbs.column.needAudit.yes" : "SÃ­",
  "ikbs.knowledge.properties.operation.enable" : "Activar",
  "ikbs.column.type.normal" : "CategorÃ­a normal",
  "ikbs.knowledge.label.passProcecWay" : "Al expirar",
  "ikbs.column.knowledge.MoveType" : "UbicaciÃ³n de ajuste",
  "ikbs.confirm.delete.by.select" : "Â¿EstÃ¡ seguro de que desea eliminar el registro seleccionado",
  "ikbs.knowledge.label.relaKngs" : "AsociaciÃ³n del Conocimiento",
  "ikbs.constableTmp.fieldName" : "Nombre del campo",
  "ikbs.column.copy.message.warning" : "InformaciÃ³n de contacto",
  "ikbs.column.deletePermanently.message.comfirm" : "Confirmar",
  "ikbs.column.moveto.front" : "Frente de",
  "ikbs.kbshome.button.orderByTime" : "Tiempo",
  "ikbs.constableTmp.moveDown" : "Desplazarse hacia abajo",
  "ikbs.tips.canNotAddKngOnRootColumn" : "No se puede agregar conocimiento a la categorÃ­a raÃ­z.",
  "ikbs.column.knowledge.button.up" : "Arriba",
  "ikbs.constableTmp.modify" : "Modificar",
  "ikbs.constableTmp.operator.gt" : "mayor",
  "ikbs.constableTmp.operator.include" : "incluir",
  "ikbs.knghisverman.label.revert" : "Restaurar",
  "ikbs.constableTmp.plsSelectModifyBeginTime" : "Seleccione la hora de inicio Modificar",
  "ikbs.knowledgeaudit.label.submitter" : "Presentado por",
  "ikbs.column.label.kngExtended" : "Atributo extendido",
  "ikbs.constableTmp.operator.ge" : "mayor o igual a",
  "ikbs.knowledge.label.listProp" : "Lista desplegable",
  "ikbs.knowledgeaudit.title.auditor" : "Revisado por",
  "ikbs.knowledge.recycle.pleaseSelect" : "- Seleccionar-",
  "ikbs.constableTmp.tab.addTitle" : "AdiciÃ³n de plantilla",
  "ikbs.knghisverman.tab.title.confirm" : "Confirmar",
  "ikbs.column.knowledge.button.move" : "Desplazarse",
  "ikbs.favorite.msg.plsSelectDir" : "Seleccione el directorio que desea eliminar.",
  "ikbs.knowledge.tip.fileNameError" : "Error al verificar el nombre del archivo.",
  "ikbs.knowledge.message.uploadImgSizeError" : "El tamaÃ±o de la imagen no puede superar los 10 MB.",
  "ikbs.knowledge.display.no" : "No",
  "ikbs.properties.inputway.select" : "Lista desplegable",
  "ikbs.favorite.title.afterModifiedKngName" : "DespuÃ©s del cambio",
  "ikbs.contentTmp.status" : "Estado de la plantilla",
  "ikbs.column.topMove.message.warning" : "InformaciÃ³n de contacto",
  "ikbs.constableManage.fieldOprator" : "Operador",
  "ikbs.constableTmp.modifyEndTime" : "Modificar la hora de finalizaciÃ³n",
  "ikbs.cntTmp.import.noData" : "El archivo cargado no contiene datos que se van a importar.",
  "ikbs.addtype.label.knowledge" : "AÃ±adir Conocimiento",
  "ikbs.contentTemp.export.tempname" : "Nombre de la plantilla",
  "ikbs.constableTmp.operator.eq" : "igual a",
  "ikbs.knowledge.tip.success" : "Ãxito",
  "ikbs.knowledge.label.radioProp" : "Caja de radio",
  "ikbs.intelligent.search" : "BÃºsqueda inteligente",
  "ikbs.contentTmp.contentName" : "Nombre de plantilla",
  "ikbs.kbshome.tab.home" : "PÃ¡gina principal de la base de conocimientos",
  "ikbs.column.passProcessWay.mark" : "Marcar",
  "ikbs.column.modify.modifyValidTimeCheckError" : "La hora de inicio del período de validez no puede ser posterior a la hora de finalización del período de validez",
  "ikbs.constableTmp.delete.selectTmp.confirm" : "Â¿EstÃ¡ seguro de que desea eliminar estas plantillas?",
  "ikbs.knowledge.recycle.beyondColuPath" : "Directorio",
  "ikbs.favorite.msg.canNotSelectRoot" : "No se puede eliminar el directorio raÃ­z. Selecciona otro.",
  "ikbs.column.knowledge.button.modify" : "Modificar",
  "ikbs.knowledgeaudit.message.plsSelectBeginTime" : "Seleccione la hora de inicio de la tarea.",
  "ikbs.contentTmp.tab.addTitle" : "AdiciÃ³n de plantilla",
  "ikbs.column.clearRecycle.comfirm" : "Â¿EstÃ¡ seguro de que desea vaciar la papelera de reciclaje?",
  "ikbs.knowledgeaudit.message.plsSelectEndTime" : "Seleccione la hora de finalizaciÃ³n de la tarea.",
  "ikbs.contTmp.importTmp.popup.title" : "ImportaciÃ³n de plantillas",
  "ikbs.knowledge.label.uploadFileTypeError" : "El archivo adjunto solo puede estar en formato DOC, DOCX, PPT, PPTX, XLS, XLSX, PDF, PNG, JPG o GIF.",
  "ikbs.column.modify.selectModifyColumnKngNumError" : "SÃ³lo se puede seleccionar un elemento de conocimiento para su modificaciÃ³n.",
  "ikbs.column.knowledge.create" : "Nuevo",
  "ikbs.confirm.delete" : "Â¿EstÃ¡ seguro de que desea eliminar este registro",
  "ikbs.column.operation.message.warning" : "InformaciÃ³n de contacto",
  "ikbs.contentTmp.copy" : "Copiar",
  "ikbs.contentTmp.modifyEndTime" : "Final modificado",
  "ikbs.column.label.passProcessWay" : "Al expirar",
  "ikbs.knowledgeaudit.label.auditStatus" : "Estado",
  "ikbs.favorite.tab.message.isDeleteFavoriteKng" : "Â¿EstÃ¡ seguro de que desea eliminar el elemento de conocimiento de los favoritos?",
  "ikbs.select.consultation.form" : "Por favor seleccione un formulario de consulta",
  "ikbs.knowledge.label.sendEmail" : "Enviar correo electrÃ³nico",
  "ikbs.tips.operation.success" : "OperaciÃ³n exitosa.",
  "ikbs.column.knowledge.KngStatus" : "Estado",
  "ikbs.contentTmp.add" : "AÃ±adir",
  "ikbs.properties.edit.information" : "Editar atributo extendido",
  "ikbs.column.knowledge.title.moveTo" : "Ajustar a",
  "ikbs.column.knowledge.button.more" : "MÃ¡s informaciÃ³n",
  "ikbs.knowledge.properties.operation.downMove" : "Abajo",
  "ikbs.kbshome.label.kngBase" : "Base de Conocimientos",
  "ikbs.confirm.delete.by.condition" : "Â¿EstÃ¡ seguro de que desea eliminar registros en funciÃ³n de las condiciones",
  "ikbs.column.serviceStatus.normal" : "Frecuentes",
  "ikbs.constableTmp.comfirm" : "Confirmar",
  "ikbs.tips.moveColumnKnowledgeOperation.warning" : "No se ha seleccionado ninguna categorÃ­a.",
  "ikbs.column.serviceStatus.updateService" : "Negocio actualizado",
  "ikbs.knowledge.tip.cannotCompare" : "Solo se pueden seleccionar dos conocimientos generales para la comparación",
  "ikbs.cntTmp.import.importSuccess" : "ImportaciÃ³n exitosa.",
  "ikbs.column.label.coluType" : "Tipo de categorÃ­a",
  "ikbs.knowledge.label.submit" : "Enviar",
  "ikbs.knowledge.message.uploadFileSizeError" : "El tamaÃ±o del archivo no puede superar los 10 MB.",
  "ikbs.knowledge.label.deleteProblem" : "Eliminar preguntas y respuestas",
  "ikbs.knowledge.recycle.select.columnType" : "Tipo de categoría",
  "ikbs.column.delete.property.comfirm" : "Â¿EstÃ¡ seguro de que desea eliminar el atributo?",
  "ikbs.knowledge.placeholder.keyword" : "Introducir palabras clave separadas por punto y coma (;).",
  "ikbs.kngCnt.cntTmp.popup.title" : "Seleccionar plantilla de contenido",
  "ikbs.knowledge.properties.operation.delete" : "Borrar",
  "ikbs.column.knowledge.compareAdd" : "Nuevo",
  "ikbs.knowledgeaudit.label.waitForAudit" : "RevisiÃ³n pendiente",
  "ikbs.constableTmp.cnstTblTmpDesc" : "DescripciÃ³n de la plantilla",
  "ikbs.column.label.colukngname" : "Nombre de la categorÃ­a",
  "ikbs.knowledgeaudit.label.batchAuditReject" : "Rechazo por lotes",
  "ikbs.constableTmp.display.no" : "no",
  "ikbs.title.tips" : "InformaciÃ³n de contacto",
  "ikbs.column.delete.selectNoColumn" : "No se ha seleccionado ninguna categorÃ­a.",
  "ikbs.column.knowledge.title.move" : "Desplazarse",
  "ikbs.column.isInheritProp.no" : "No",
  "ikbs.column.message.plsSelectModifyEndTime" : "Seleccione una hora de finalizaciÃ³n.",
  "ikbs.column.label.servicesVaildStartTime" : "Inicio del perÃ­odo de validez del negocio",
  "ikbs.knowledge.properties.operation.edit" : "Editar",
  "ikbs.knowledge.pass.mark" : "Marcar",
  "ikbs.column.modify.modifyServiceTimeCheckError" : "El inicio del perÃ­odo de validez comercial no puede ser anterior a su finalizaciÃ³n.",
  "ikbs.column.modify.properties.batch.message.warning" : "InformaciÃ³n de contacto",
  "ikbs.cntTmp.import.error.oversize" : "El tamaÃ±o del archivo no puede exceder los 20 MB.",
  "ikbs.knowledgeaudit.label.cancel" : "Cancelar",
  "ikbs.search.label.keyword" : "Palabra clave",
  "ikbs.knghisverman.tab.message.deleteSuccess" : "La eliminaciÃ³n se realizÃ³ correctamente.",
  "ikbs.search.label.sortByClick" : "Clics",
  "ikbs.knowledge.tip.fileInjectionError" : "Error al verificar el contenido del archivo.",
  "ikbs.knowledge.label.correlative" : "Afiliado",
  "ikbs.column.needAudit.no" : "No",
  "ikbs.column.unlock.message.success" : "Desbloquear correctamente.",
  "ikbs.konwledgetype.question" : "Conocimiento de Q&A",
  "ikbs.knowledge.service.timeout" : "Caducado",
  "ikbs.column.topMove.message.success" : "Se ha anclado correctamente.",
  "ikbs.contentTmp.Modifier" : "Modificado por",
  "ikbs.knowledgeaudit.label.error" : "Error",
  "ikbs.knowledge.exception.AnswerNum.error" : "Una pregunta no puede tener más de 10 respuestas.",
  "ikbs.knowledgeaudit.title.submitTime" : "Presentado el",
  "ikbs.column.label.colukngpath" : "Ruta de categorÃ­a",
  "ikbs.knowledge.label.saveAndNext" : "Guardar y NextStep",
  "ikbs.constableTmp.save" : "Guardar",
  "ikbs.knghisverman.label.operation" : "OperaciÃ³n",
  "ikbs.column.label.summaryInfo" : "ResÃºmenes",
  "ikbs.knowledge.recycle.button.reset" : "Restablecer",
  "ikbs.knowledge.recycle.button.delete" : "Borrar",
  "ikbs.constableTmp.status.invalid" : "InvÃ¡lido",
  "ikbs.cntTmp.import.contTmpNameEditStandEmpty" : "El contenido de la plantilla y las especificaciones de ediciÃ³n no pueden estar vacÃ­os al mismo tiempo.",
  "ikbs.column.knowledge.button.copy" : "Copiar",
  "ikbs.knowledge.properties.properties.inputWay" : "Tipo de entrada",
  "ikbs.column.knowledge.compareModified" : "Diferentes",
  "ikbs.constableTmp.innerFieldFlag" : "Campo interior",
  "ikbs.column.passProcessWay.move" : "Moverse a la historia",
  "ikbs.knowledge.msg.fileUpload" : "Subir archivo al conocimiento",
  "ikbs.column.unlock.message.failed" : "Error de desbloqueo.",
  "ikbs.cntTmp.import.editStandardOversize" : "Las especificaciones de ediciÃ³n no pueden superar los 400 caracteres.",
  "ikbs.contentTmp.save" : "Ahorra",
  "ikbs.knowledge.check.kngName" : "El nombre de conocimiento o el nombre de columna no pueden contener las siguientes characters: /\\:*?<>| especiales",
  "ikbs.contentTmp.tab.copyTitle" : "DuplicaciÃ³n",
  "ikbs.column.downMove.message.fail" : "No se pudo mover hacia abajo.",
  "ikbs.column.knowledge.compareDel" : "No disponible",
  "ikbs.knowledge.service.hot" : "Caliente",
  "ikbs.contentTmp.modify.fail" : "Error de modificaciÃ³n",
  "ikbs.knowledge.label.servicesStartTime" : "Inicio del perÃ­odo de validez del negocio",
  "ikbs.constableTmp.modifyTime" : "Tiempo modificado",
  "ikbs.knowledge.index.no" : "No",
  "ikbs.kbshome.label.ClickList" : "La mayorÃ­a de los clics",
  "ikbs.knowledge.properties.properties.status" : "Estado",
  "ikbs.knowledge.properties.dictValue" : "Valor del diccionario",
  "ikbs.column.delete.message.warning" : "InformaciÃ³n de contacto",
  "ikbs.column.delete.message.fail" : "Error en la eliminaciÃ³n.",
  "ikbs.constableTmp.cnstTblName" : "Consultar tabla",
  "ikbs.cntTmp.import.error.importType" : "Seleccione un modo de importaciÃ³n.",
  "ikbs.contTmp.import.popup.title" : "Importar plantillas",
  "ikbs.column.title.selectUser" : "Seleccionar usuario",
  "ikbs.column.recycle.noSelectRecycles" : "Seleccione un registro.",
  "ikbs.knowledge.label.servicesEndTime" : "Fin del PerÃ­odo de Validez del Negocio",
  "ikbs.knghisverman.label.modifier" : "Modificado por",
  "ikbs.cntTmp.import.result" : "Se ha completado la importaciÃ³n de plantillas de contenido. NÃºmero total {0}, nÃºmero de Ã©xitos {1}, nÃºmero de errores {2}.",
  "ikbs.cntTmp.export.crosspage.info" : "No se admite la exportaciÃ³n entre pÃ¡ginas. Solo se pueden exportar los datos seleccionados en la pÃ¡gina actual.",
  "ikbs.column.knowledge.expired" : "Caducado",
  "ikbs.knowledgeaudit.title.auditTime" : "Revisado el",
  "ikbs.knowledge.tip.uploadFailed" : "Error al cargar.",
  "ikbs.column.knowledge.CurVersion" : "VersiÃ³n de origen",
  "ikbs.content.upload.file.Frequent" : "Demasiadas solicitudes de API, por favor intente de nuevo más tarde.",
  "ikbs.knowledge.check.keyword" : "Las palabras clave no pueden contener caracteres especiales.",
  "ikbs.knowledge.tip.fileTypeError" : "Error al verificar el tipo de archivo.",
  "ikbs.column.upMove.message.warning" : "InformaciÃ³n de contacto",
  "ikbs.contentTmp.modifyStartTime" : "Inicio modificado",
  "ikbs.knowledgeaudit.label.taskEndTime" : "Hora de finalizaciÃ³n de tareas",
  "ikbs.constableTmp.modifyBeginTime" : "Modificar la hora de inicio",
  "ikbs.knowledge.lable.copy" : "Duplicado",
  "ikbs.knowledgeaudit.label.batchAuditPass" : "Aprobar por lotes",
  "ikbs.contentTmp.delete" : "Borrar",
  "ikbs.knowledge.tab.title" : "CompilaciÃ³n de conocimientos",
  "ikbs.knowledge.recycle.label.knowledgeType" : "Tipo",
  "ikbs.column.knowledge.Type" : "Tipo",
  "ikbs.kbshome.button.thirtyDayList" : "30 dÃ­as",
  "ikbs.cntTmp.import.queryContTempErr" : "Error al consultar la plantilla de contenido.",
  "ikbs.favorite.table.delete" : "Borrar",
  "ikbs.column.move.message.warning" : "InformaciÃ³n de contacto",
  "ikbs.knowledge.title.propTitle" : "Nombre",
  "ikbs.constableTmp.modify.selectedOne" : "Solo se puede seleccionar una plantilla",
  "ikbs.knowledge.label.uploadAttachment" : "Cargar archivo adjunto",
  "ikbs.knowledge.pass.move" : "Moverse a la historia",
  "ikbs.favorite.title.warning" : "InformaciÃ³n de contacto",
  "ikbs.kbshome.label.favorite" : "Atajos",
  "ikbs.knowledge.label.deleteProperty" : "Borrar",
  "ikbs.column.knowledge.button.publish" : "PublicaciÃ³n",
  "ikbs.column.resumeRecycle.comfirm" : "Â¿EstÃ¡ seguro de que desea restaurarlo?",
  "ikbs.constableTmp.status.valid" : "VÃ¡lido",
  "ikbs.knowledgeaudit.label.reject" : "Rechazar",
  "ikbs.contentTmp.export" : "ExportaciÃ³n",
  "ikbs.properties.operation.message.fail" : "Fallo",
  "ikbs.knowledge.label.validEndTime" : "Fin del PerÃ­odo de Validez",
  "ikbs.knowledge.label.preStep" : "Paso anterior",
  "ikbs.properties.add.save" : "OK",
  "ikbs.knowledgeaudit.label.query" : "Consultar",
  "ikbs.knowledge.tip.keywordCountError" : "Se admite un mÃ¡ximo de {count} palabras clave.",
  "ikbs.favorite.table.edit" : "Editar",
  "ikbs.knowledge.label.addKngPoint" : "AÃ±adir pregunta",
  "ikbs.knowledge.label.creatorName" : "Preparado por",
  "ikbs.knowledge.tip.operateFailed" : "Error en la operaciÃ³n.",
  "ikbs.knghisverman.tab.message.isDeleteHisVer" : "Â¿EstÃ¡ seguro de que desea eliminar esta versiÃ³n del historial?",
  "ikbs.favorite.title.addDirectory" : "AÃ±adir directorio",
  "ikbs.knowledge.label.type" : "Tipo",
  "ikbs.column.Move.message.success" : "MuÃ©vete con Ã©xito.",
  "ikbs.column.knowledge.update" : "Actualizado",
  "ikbs.search.label.sortByTime" : "Tiempo",
  "ikbs.knowledge.label.summaryInfo" : "ResÃºmenes",
  "ikbs.properties.detail.information" : "Detalles de atributos extendidos",
  "ikbs.column.release.message.success" : "PublicaciÃ³n exitosa.",
  "ikbs.knowledge.label.ok" : "OK",
  "ikbs.knowledge.label.upload" : "Cargar",
  "ikbs.topMove.selectTopMoveColumnKngNumError" : "No se selecciona ningÃºn conocimiento de categorÃ­a.",
  "ikbs.constableTmp.display.yes" : "Sí",
  "ikbs.column.modify.properties.batch.message.error" : "No se selecciona ningÃºn conocimiento de categorÃ­a.",
  "ikbs.cntTmp.import.failReason" : "Error al cargar el registro {0}; causa de error: {1}",
  "ikbs.knowledge.properties.properties.title" : "Nombre",
  "ikbs.knowledgeaudit.label.auditor" : "Revisado por",
  "ikbs.cntTmp.export.exportNum.blank" : "Seleccione las plantillas de contenido que desea exportar.",
  "ikbs.contentTmp.status.valid" : "VÃ¡lido",
  "ikbs.column.modify.selectModifyColumnKngNumZERO" : "No se selecciona ningÃºn conocimiento de categorÃ­a.",
  "ikbs.column.tip.success" : "InformaciÃ³n de contacto",
  "ikbs.knowledge.tip.relaKngCountError" : "Se admite un mÃ¡ximo de {count} elementos de conocimiento asociados.",
  "ikbs.contentTemp.export.tempcontent" : "Contenido de la plantilla",
  "ikbs.contentTmp.query" : "Consultar",
  "ikbs.properties.status.draft" : "Borrador",
  "ikbs.column.knowledge.ModifyTime" : "Modificado en",
  "ikbs.knowledgeaudit.title.status" : "Estado",
  "ikbs.column.knowledge.compare" : "Comparar",
  "ikbs.knowledge.label.uploadFileSizeError" : "El archivo adjunto no puede exceder los 10 MB.",
  "ikbs.cntTmp.import.invalidData" : "Datos no vÃ¡lidos.",
  "ikbs.column.delete.message.success" : "La eliminaciÃ³n se realizÃ³ correctamente.",
  "ikbs.favorite.tree.favoriteRootName" : "Favoritos",
  "ikbs.column.moveTo.selectColumnKngNumError" : "No se selecciona ningÃºn conocimiento de categorÃ­a.",
  "ikbs.cntTmp.export.error.syserr" : "Error al exportar el archivo de plantilla de contenido.",
  "ikbs.column.button.confirm" : "Enviar",
  "ikbs.constableTmp.delete.noSelectTmp" : "No se ha seleccionado ninguna plantilla",
  "ikbs.content.transfer.html.success" : "El contenido se transfiere correctamente a HTML",
  "ikbs.column.modify.modifyValidServiceEndTimeCheckError" : "El final del perÃ­odo de validez comercial no puede ser posterior al final del perÃ­odo de validez.",
  "ikbs.knowledge.properties.label.propertiesTitle" : "Nombre",
  "ikbs.knowledge.label.addAnswer" : "AÃ±adir respuesta",
  "ikbs.column.delete.message.comfirm" : "Confirmar",
  "ikbs.column.button.search" : "Consultar",
  "ikbs.knghisverman.label.modifyTime" : "Modificado en",
  "ikbs.select.record.to.delete" : "Seleccione el registro que desea eliminar",
  "ikbs.knowledgeaudit.label.submitterOrAuditor" : "Enviado por/Revisado por",
  "ikbs.constableManage.fieldValue" : "Valor",
  "ikbs.column.history.down.empty" : "No se puede abrir la categoría porque la categoría no contiene ninguna categoría o conocimiento",
  "ikbs.constableTmp.name" : "Nombre de plantilla",
  "ikbs.knowledgeaudit.label.pleaseSelect" : "- Seleccionar-",
  "ikbs.cntTmp.import.syserr" : "Error al importar la plantilla de contenido.",
  "ikbs.column.copy.selectCopyColumnKngNumError" : "SÃ³lo se puede seleccionar un elemento de conocimiento para copiarlo.",
  "ikbs.contentTmp.status.invalid" : "InvÃ¡lido",
  "ikbs.column.message.ids.max.size" : "El número de elementos para la operación por lotes no puede superar los 100.",
  "ikbs.knowledge.properties.label.query" : "Consultar",
  "ikbs.kngCnt.cntTmp.importType.new" : "Crear directamente",
  "ikbs.column.upMove.message.success" : "Se moviÃ³ hacia arriba con Ã©xito.",
  "ikbs.content.transfer.html.fail" : "Error al convertir el contenido a HTML",
  "ikbs.knghisverman.title.kngHisVerMan" : "Gestionar el historial de conocimientos",
  "ikbs.favorite.field.directoryName" : "Nombre del directorio",
  "ikbs.column.Move.message.fail" : "El movimiento fallÃ³.",
  "ikbs.column.deletePermanently.message.fail" : "Error de eliminaciÃ³n permanente.",
  "ikbs.columntype.knowledge" : "Conocimiento",
  "ikbs.knowledge.type.common" : "Conocimientos comunes",
  "ikbs.knowledgeaudit.label.ok" : "OK",
  "ikbs.contentTmp.exception.contTmpName" : "Entrada ilegal",
  "ikbs.kngCnt.cntTmp.use.cntReplaceConfirm" : "Â¿EstÃ¡ seguro de que desea reemplazar el contenido de conocimiento actual?",
  "ikbs.search.button.search" : "Buscar",
  "ikbs.constableTmp.delete.success" : "SupresiÃ³n correcta",
  "ikbs.knowledge.recycle.coluKngName" : "Nombre",
  "ikbs.constableTmp.add.success" : "AÃ±adido con Ã©xito",
  "ikbs.kbshome.button.orderByName" : "Por nombre",
  "ikbs.knowledge.tip.uploadSuccess" : "Cargar correctamente.",
  "ikbs.constableManage.incorrect" : "La condiciÃ³n es incorrecta",
  "ikbs.column.modify.properties.batch.message.success" : "Los atributos se modifican en lotes correctamente.",
  "ikbs.properties.inputway.text" : "Entrada de texto",
  "ikbs.column.move.selectMoveColumnKngNumError" : "No se selecciona ningÃºn conocimiento de categorÃ­a.",
  "ikbs.knowledgeaudit.title.auditRemark" : "Comentarios",
  "ikbs.knowledge.label.indexNeedUpdate" : "Actualizar Ã­ndice de bÃºsqueda",
  "ikbs.knowledge.recycle.status" : "Estado",
  "ikbs.kbshome.button.orderByClicks" : "Clics",
  "ikbs.favorite.table.name" : "Nombre",
  "ikbs.column.knowledge.RelVersion" : "VersiÃ³n de destino",
  "ikbs.kbshome.button.dayList" : "1 DÃ­a",
  "ikbs.column.modify.modifyValidTimeServiceTimeSame" : "Cambiar tanto el inicio como el final del perÃ­odo de validez como los del perÃ­odo de validez del negocio.",
  "ikbs.column.label.servicesVaildEndTime" : "Fin del PerÃ­odo de Validez del Negocio",
  "ikbs.knowledgeaudit.label.submitAudit" : "Pendiente",
  "ikbs.column.release.selectreleaseColumnKngTypeError" : "Solo el conocimiento puede ser publicado.",
  "ikbs.knowledge.type.points" : "Conocimiento de Q&A",
  "ikbs.column.knowledge.modifyStartTime" : "Inicio modificado",
  "ikbs.favorite.title.preModifiedKngName" : "Antes del cambio",
  "ikbs.column.deleteRecycle.comfirm" : "Â¿EstÃ¡ seguro de que desea eliminarlo?",
  "ikbs.recycle.title" : "Papelera de reciclaje",
  "ikbs.column.tree.rootName" : "CategorÃ­a Conocimiento",
  "ikbs.column.downMove.message.warning" : "InformaciÃ³n de contacto",
  "ikbs.knghisverman.tab.title.kngBaseInfo" : "InformaciÃ3n BÃÂ¡sica",
  "ikbs.addtype.label.column" : "AÃ±adir categorÃ­a",
  "ikbs.knowledge.msg.updateKngPath" : "Estatizar los detalles del conocimiento",
  "ikbs.kbshome.label.recentViews" : "Mi historia",
  "ikbs.column.knowledge.publish" : "Publicado",
  "ikbs.contentTemp.export.tempdesc" : "DescripciÃ³n de la plantilla",
  "ikbs.column..topMove.message.fail" : "Error al anclar a la parte superior.",
  "ikbs.knowledge.recycle.button.clearAll" : "Papelera de reciclaje vacÃ­a",
  "ikbs.constableTmp.status" : "Estado de la plantilla",
  "ikbs.contentTmp.contentDesc" : "DescripciÃ³n de la plantilla",
  "ikbs.knghisverman.label.version" : "VersiÃ³n",
  "ikbs.column.knowledge.button.unLock" : "Desbloquear",
  "ikbs.knowledge.error.property" : "Seleccione el valor del atributo [{}].",
  "ikbs.knowledgeaudit.label.mySubmitAudit" : "Mi solicitud",
  "ikbs.column.label.kngReleaseWay" : "Revisar y publicar conocimientos en la categorÃ­a",
  "ikbs.contentTmp.add.fail" : "Error de adiciÃ³n",
  "ikbs.constableTmp.moveUp" : "Moverse hacia arriba",
  "ikbs.constableManage.condition.limit" : "El nÃºmero de condiciones no puede exceder de 10",
  "ikbs.column.downMove.selectDowmMoveColumnKngNumError" : "No se selecciona ningÃºn conocimiento de categorÃ­a.",
  "ikbs.column.modify.modifyzeropropertieserror" : "No se modifica ningÃºn atributo.",
  "ikbs.column.label.isInheritProp" : "Heredar atributo de categorÃ­a principal",
  "ikbs.column.knowledge.reject" : "Rechazado",
  "ikbs.column.deletePermanently.columnkng" : "No se selecciona ningÃºn conocimiento de categorÃ­a.",
  "ikbs.constableManage.modifyConstable" : "ModificaciÃ³n de datos a la tabla de consulta",
  "ikbs.contTmp.importTmp.title" : "Descargar plantilla de importaciÃ³n",
  "ikbs.constableTmp.fieldViewName" : "Nombre para mostrar",
  "ikbs.cntTmp.import.contTmpDesc" : "DescripciÃ³n incorrecta de la plantilla.",
  "ikbs.constableManage.addConstable" : "AdiciÃ³n de datos a la tabla de consulta",
  "ikbs.contentTmp.operate" : "Operar",
  "ikbs.column.upMove.message.fail" : "No se pudo mover hacia arriba.",
  "ikbs.constableTmp.query" : "Consultar",
  "ikbs.column.recycle.message.comfirm" : "Confirmar",
  "ikbs.kbshome.button.advancedSearch" : "BÃºsqueda avanzada",
  "ikbs.constableTmp.modify.success" : "modificado con Ã©xito",
  "ikbs.kbshome.tip.refresh" : "Refrescar",
  "ikbs.constableTmp.delete.fail" : "Error en la eliminaciÃ³n",
  "ikbs.properties.operation.message.success" : "Ãxito",
  "ikbs.column.knowledge.clear" : "Limpiar",
  "ikbs.knowledge.label.auditor" : "Revisado por",
  "ikbs.column.tip.error" : "Error",
  "ikbs.column.deletePermanently.columnkng.comfirm" : "Â¿EstÃ¡ seguro de que desea eliminar el conocimiento de la categorÃ­a de forma permanente?",
  "ikbs.favorite.tab.message.isDeleteFavoriteDir" : "Â¿EstÃ¡ seguro de que desea eliminar el directorio de favoritos y el conocimiento en el directorio?",
  "ikbs.column.tips.modifyColumnOperation.error" : "Error al modificar el conocimiento de categorÃ­a.",
  "ikbs.column.button.reset" : "Restablecer",
  "ikbs.knowledgeaudit.message.auditSuccess" : "La aplicaciÃ³n se aprueba correctamente.",
  "ikbs.column.knowledge.Modifier" : "Modificado por",
  "ikbs.cntTmp.import.error.contTmpNameExist" : "El nombre de la plantilla existe.",
  "ikbs.cntTmp.export.info.success" : "Ãxito de exportaciÃ³n.",
  "ikbs.knowledge.label.kngAttachmentUpload" : "Cargar archivo adjunto de categorÃ­a de conocimiento",
  "ikbs.knowledge.tip.propCountLimited" : "Se puede asociar un mÃ¡ximo de 20 atributos.",
  "ikbs.column.knowledge.button.deletePermanently" : "Eliminar permanentemente",
  "ikbs.knowledge.recycle.label.operateTimeEnd" : "Hora de finalizaciÃ³n de eliminaciÃ³n",
  "ikbs.knowledge.label.display" : "Mostrar en la portada",
  "ikbs.cntTmp.export.result" : "Se ha completado la exportaciÃ³n de plantillas de contenido. NÃºmero total {0}, nÃºmero de Ã©xitos {1}, nÃºmero de errores {2}.",
  "ikbs.knowledge.label.deleteAnswer" : "Eliminar respuesta",
  "ikbs.knowledge.properties.operation.detail" : "Detalles",
  "ikbs.column.knowledge.modifyEndTime" : "Final modificado",
  "ikbs.knowledgeaudit.title.name" : "Nombre",
  "ikbs.knowledge.label.modifierName" : "Ãltima actualizaciÃ³n por",
  "ikbs.column.knowledge.button.moveTo" : "Ajustar a",
  "ikbs.knowledge.title.knowledgeContent" : "Contenido",
  "ikbs.knowledge.tip.fileSizeError" : "Error al verificar el tamaño del archivo.",
  "ikbs.constableTmp.delete" : "Borrar",
  "ikbs.tips.copy.onlyKngCanCopy" : "Solo el conocimiento puede ser copiado.",
  "ikbs.column.passProcessWay.delete" : "Borrar",
  "ikbs.column.label.serviceStatus" : "Estado del negocio",
  "ikbs.column.button.cancel" : "Cerrar",
  "ikbs.knowledge.label.clickNum" : "Vistas",
  "ikbs.Column.MoveTo.message.success" : "OperaciÃ³n exitosa.",
  "ikbs.knowledge.index.yes" : "SÃ­",
  "ikbs.knowledge.tip.fileIOError" : "Error al cargar el archivo.",
  "ikbs.column.modify.properties.message.warning" : "InformaciÃ³n de contacto",
  "ikbs.constableTmp.operate" : "Operar",
  "ikbs.knowledgeaudit.title.path" : "Ruta",
  "ikbs.column.serviceStatus.expired" : "Caducado",
  "ikbs.column.knowledge.audit" : "ReseÃ±ado",
  "ikbs.contTmp.import.tip" : "1. Solo se puede cargar un archivo.xlsx. 2. El tamaÃ±o mÃ¡ximo del archivo es de 20 MB. 3. Se puede importar un mÃ¡ximo de 10 registros.",
  "ikbs.knowledge.label.save" : "Guardar",
  "ikbs.knowledge.title.knowledgeExtended" : "InformaciÃ³n ampliada",
  "ikbs.column.knowledge.button.modifyPropBatch" : "Modificar atributo",
  "ikbs.column.type.history" : "Historia",
  "ikbs.cntTmp.import.contTmpNameError" : "Nombre de plantilla incorrecto.",
  "ikbs.knowledge.tip.error" : "Error",
  "ikbs.contentTmp.delete.success" : "SupresiÃ³n correcta",
  "ikbs.knowledgeaudit.label.success" : "Ãxito",
  "ikbs.contentTmp.editStandard" : "EdiciÃ³n de especificaciones",
  "ikbs.favorite.title.editFavoriteKngName" : "Cambiar nombre",
  "ikbs.column.knowledge.button.down" : "Abajo",
  "ikbs.column.modify.message.error" : "Error en la modificaciÃ³n.",
  "ikbs.column.knowledge.compareKng" : "Comparar conocimientos",
  "ikbs.knowledge.check.inputText" : "No se permiten caracteres especiales.",
  "ikbs.column.knowledge.button.delete" : "Borrar",
  "ikbs.knowledge.service.new" : "Nuevos negocios",
  "ikbs.knowledge.properties.operation.disable" : "Desactivar",
  "ikbs.column.serviceStatus.hot" : "Caliente",
  "ikbs.contentTmp.add.success" : "Ãxito de la adiciÃ³n",
  "ikbs.favorite.msg.modifyNodeNameSuccess" : "OperaciÃ³n exitosa.",
  "ikbs.column.deletePermanently.message.warning" : "InformaciÃ³n de contacto",
  "ikbs.contentTmp.modify" : "Modificar",
  "ikbs.kbshome.button.sevenDayList" : "7 DÃ­as",
  "ikbs.column.knowledge.button.turnToHistory" : "Moverse a la historia",
  "ikbs.constableTmp.cancle" : "Cancelar",
  "ikbs.knowledge.recycle.button.resume" : "Restaurar",
  "ikbs.knowledgeaudit.label.auditing" : "En examen",
  "ikbs.knowledge.release.audit" : "Revisar y publicar",
  "ikbs.constableTmp.yes" : "SÃ­",
  "ikbs.column.label.displayFlag" : "Mostrar en la portada",
  "ikbs.column.moveto.back" : "Al lado de",
  "ikbs.column.modify.message.warning" : "InformaciÃ³n de contacto",
  "ikbs.knowledge.label.nextStep" : "Siguiente paso",
  "ikbs.column.moveTo.message.warning" : "InformaciÃ³n de contacto",
  "ikbs.column.isInheritProp.yes" : "SÃ­",
  "ikbs.knowledge.title.knowledgeBase" : "InformaciÃ3n BÃÂ¡sica",
  "ikbs.knowledge.label.keyword" : "Palabras clave",
  "ikbs.knowledgeaudit.label.reset" : "Restablecer",
  "ikbs.knowledge.message.uploadImgTypeError" : "El formato de imagen es incorrecto.",
  "ikbs.knowledge.label.moveUp" : "Arriba",
  "ikbs.properties.inputway.checkbox" : "Casilla de verificaciÃ³n",
  "ikbs.knowledgeaudit.label.auditReject" : "Rechazado",
  "ikbs.contentTmp.ModifyTime" : "Modificado en",
  "ikbs.knowledge.label.checkboxProp" : "Casilla de verificaciÃ³n",
  "ikbs.knowledge.label.kngMsg" : "InformaciÃ³n de Conocimiento",
  "ikbs.column.knowledge.compareResult" : "Resultados de la comparaciÃ³n",
  "ikbs.cntTmp.import.invalidFile" : "Archivo no vÃ¡lido.",
  "ikbs.kbshome.button.orderByOrderId" : "Por No.",
  "ikbs.column.knowledge.modifyColumnInformation" : "Modificar categorÃ­a",
  "ikbs.knowledgeaudit.placeholder.pleaseSelect" : "- Seleccionar-",
  "ikbs.constableTmp.tab.modifyTitle" : "Modificar plantilla",
  "ikbs.knowledge.label.modifyRemark" : "Comentarios",
  "ikbs.column.release.selectreleaseColumnKngNumError" : "No se selecciona ningÃºn conocimiento.",
  "ikbs.column.knowledge.button.order" : "Ordenar",
  "ikbs.contentTmp.import" : "ImportaciÃ³n",
  "ikbs.column.label.validEndTime" : "Fin del PerÃ­odo de Validez",
  "ikbs.properties.status.enable" : "Activar",
  "ikbs.column.modify.modifyValidServiceStartTimeCheckError" : "El inicio del perÃ­odo de validez comercial no puede ser anterior al inicio del perÃ­odo de validez.",
  "ikbs.knowledge.error.contentEmpty" : "El contenido del conocimiento no puede estar vacÃ­o.",
  "ikbs.column.upMove.selectUpMoveColumnKngNumError" : "No se selecciona ningÃºn conocimiento de categorÃ­a.",
  "ikbs.constableTmp.add" : "AÃ±adir",
  "ikbs.constableTmp.operator.exclude" : "excluir",
  "ikbs.column.knowledge.LockedFlat" : "Bloqueado por",
  "ikbs.knowledge.label.cancelCollection" : "Cancelar favorito",
  "ikbs.contentTmp.reset" : "Restablecer",
  "ikbs.knowledge.label.textProp" : "Entrada de texto",
  "ikbs.knowledge.title.propType" : "Tipo de entrada",
  "ikbs.cntTmp.export.exportNum.oversize" : "Se puede exportar un mÃ¡ximo de 10 plantillas de contenido.",
  "ikbs.properties.status.disable" : "Desactivar",
  "ikbs.knowledgeaudit.label.knowledgeName" : "Nombre del conocimiento",
  "ikbs.constableManage.fieldName" : "Nombre de columna",
  "ikbs.contentTmp.close" : "Acerca de",
  "ikbs.knowledge.properties.label.add" : "Nuevo",
  "ikbs.knowledgeaudit.label.auditPass" : "Aprobado",
  "ikbs.common.search" : "BÃºsqueda comÃºn",
  "ikbs.contentTmp.modify.success" : "Ãxito de la modificaciÃ³n",
  "ikbs.column.downMove.message.success" : "Se ha movido hacia abajo con Ã©xito.",
  "ikbs.knowledge.label.draft" : "Borrador",
  "ikbs.knowledge.label.creationTime" : "Creado en",
  "ikbs.column.modify.properties.batch.message.fail" : "Error al modificar los atributos en lotes.",
  "ikbs.column.message.plsSelectModifyStartTime" : "Seleccione una hora de inicio.",
  "ikbs.knowledge.label.problem" : "Preguntas",
  "ikbs.constableTmp.displayFlag" : "Si se debe mostrar",
  "ikbs.Column.MoveTo.message.fail" : "Error en la operaciÃ³n.",
  "ikbs.column.knowledge.columnKnowledgeName" : "Nombre del conocimiento de la categorÃ­a",
  "ikbs.column.knowledge.button.top" : "Alfiler",
  "ikbs.tips.addColumnOperation.error" : "Error al agregar la categorÃ­a.",
  "ikbs.search.label.sortByName" : "Por nombre",
  "ikbs.column.delete.selectdeletecolumn.comfirm" : "Â¿EstÃ¡ seguro de que desea eliminar la categorÃ­a y todas sus categorÃ­as secundarias y conocimientos?",
  "ikbs.constableTmp.modifierName" : "Modificado por",
  "ikbs.knowledge.label.attachment" : "Archivos adjuntos",
  "ikbs.column.unlock.selectUnlockColumnKngNumError" : "No se selecciona ningÃºn conocimiento de categorÃ­a.",
  "ikbs.contentTmp.tmpContent" : "Contenido de la plantilla",
  "ikbs.column.label.coluKngBrowsing" : "grupo de exploración",
  "ikbs.kbshome.button.totalList" : "Todo el tiempo",
  "ikbs.search.label.resultDes" : "Sin datos",
  "ikbs.knowledge.recycle.button.query" : "Consultar",
  "ikbs.knowledge.properties.label.reset" : "Restablecer",
  "ikbs.constableTmp.warning" : "InformaciÃ³n de contacto",
  "ikbs.knowledge.label.kngReleaseWay" : "Modo de publicaciÃ³n",
  "ikbs.properties.inputway.radio" : "Caja de radio",
  "ikbs.knowledge.recycle.operationTime" : "Eliminado el",
  "ikbs.constableTmp.plsSelectModifyEndTime" : "Seleccione la hora de finalizaciÃ³n Modificar",
  "ikbs.column.knowledge.button.turnToNormal" : "Mover a Normal",
  "ikbs.knowledge.label.validStartTime" : "Inicio del perÃ­odo de validez",
  "ikbs.knowledge.pass.delete" : "Borrar",
  "ikbs.column.displayflag.show" : "SÃ­",
  "ikbs.knowledge.recycle.select.knowledgeStatus" : "Estado del conocimiento",
  "ikbs.column.deletePermanently.message.success" : "La eliminaciÃ³n permanente se realizÃ³ correctamente.",
  "ikbs.time.lessThan.currentTime" : "El final no puede ser anterior a la hora actual.",
  "ikbs.knowledge.recycle.label.operateTimeStart" : "Hora de inicio de eliminaciÃ³n",
  "ikbs.knowledge.recycle.label.knowledgeName" : "Nombre del conocimiento de la categorÃ­a",
  "ikbs.knghisverman.label.delete" : "Borrar",
  "ikbs.column.knowledge.Name" : "Nombre",
  "ikbs.knowledgeaudit.placeholder.pleaseInput" : "Escriba un nombre.",
  "ikbs.column.label.validStartTime" : "Inicio del perÃ­odo de validez",
  "ikbs.knowledge.display.yes" : "SÃ­",
  "ikbs.knowledge.label.servicesTime" : "PerÃ­odo de validez",
  "ikbs.search.label.updateStartTime" : "Actualizar hora de inicio",
  "ikbs.favorite.msg.addDirectorySuccess" : "El directorio se ha aÃ±adido correctamente.",
  "ikbs.search.label.result" : "Resultados de la bÃºsqueda",
  "ikbs.constableTmp.modify.fail" : "Error al modificar",
  "ikbs.constableTmp.add.fail" : "AÃ±adido error",
  "ikbs.column.knowledge.modifierId" : "Modificado por",
  "ikbs.tips.propertyDictValueNumExceedMaxValue.warning" : "El nÃºmero de valores de diccionario de atributo excede el mÃ¡ximo.",
  "ikbs.constableTmp.no" : "No",
  "ikbs.knowledge.label.close" : "Cerrar",
  "ikbs.favorite.table.operate" : "OperaciÃ³n",
  "ikbs.kngCnt.cntTmp.editStandard.title" : "EdiciÃ³n de especificaciones",
  "ikbs.constableTmp.anonymizationFlag" : "Ya sea para anonimizar",
  "ikbs.knowledge.tip.fileCountError" : "Se admite un mÃ¡ximo de {count} archivos adjuntos.",
  "ikbs.column.copy.selectCopyColumnKngNumZERO" : "No se selecciona ningÃºn conocimiento para copiar.",
  "ikbs.constableTmp.delete.byCondition" : "Eliminar por condiciÃ³n",
  "ikbs.column.delete.selectdeletecolumnkng.comfirm" : "Â¿EstÃ¡ seguro de que desea eliminar las categorÃ­as o conocimientos?",
  "ikbs.knowledge.label.name" : "Nombre del conocimiento",
  "ikbs.knowledge.check.textArea" : "Los siguientes caracteres especiales no estÃ¡n permitidos:<>/",
  "ikbs.knowledge.label.addToCollection" : "AÃ±adir a favoritos",
  "ikbs.knowledge.recycle.operator" : "Operador",
  "ikbs.konwledgetype.knowledge" : "Conocimientos comunes",
  "ikbs.knowledgeaudit.label.taskBeginTime" : "Hora de inicio de la tarea",
  "ikbs.kngCnt.cntTmp.importType.sameNameCover" : "Sobrescribir plantillas con los mismos nombres",
  "ikbs.column.knowledge.Path" : "Ruta",
  "ikbs.kbshome.label.plsInputKeyWord" : "Introduce una palabra clave.",
  "ikbs.knowledge.label.addProperty" : "AÃ±adir",
  "ikbs.knowledgeaudit.title.submitter" : "Presentado por",
  "ikbs.knowledge.properties.properties.operation" : "OperaciÃ³n",
  "ikbs.knowledge.label.moveDown" : "Abajo"
}