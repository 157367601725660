<template>
    <div>
        <sweet-form-item prop="taskInfo.taskName" v-if="!isBatchEdit" required>
            <!--使用label插槽时不能直接设置required属性和required校验（要用validator），否则显示异常-->
            <template #label>
                <sweet-tooltip class="box-item" effect="light" :placement="topPlacement">
                    <template #content>
                        <strong>
                            {{$t('isales.rbtrecognition.remark.tip')}}<br/>`~!@#$%^&*()+=|{}'":;[]/?.&#60;&#62;<br/>~！@#￥%……&*（）——+|{}【】‘；：”“’,、？\
                        </strong>
                    </template>
                    <div class="aicc-align--absolute-left">
                        <span class="icon-class">{{ isCreateTemplate || isEditTemplate || isViewTemplate? $t('isales.tasktemplate.field.templateName'): $t('isales.taskmanagement.field.taskName') }}</span>
                        <sweet-icon><warning style="transform: rotateX(180deg)" /></sweet-icon>
                    </div>
                </sweet-tooltip>
            </template>
            <sweet-input class="width-400px" v-model="showModel.taskInfo.taskName"/>
        </sweet-form-item>
        <sweet-form-item :label="$t('isales.taskinfo.field.taskType')" required>
            <sweet-radio-group v-model="showModel.taskInfo.taskType" :disabled="!isCreate && !isCreateTemplate" @change="clearIvr">
                <!--系统自动外呼-->
                <div v-if="this.showModel.taskInfo.taskType===this.taskTypeItem.PREDICT
                || this.showModel.taskInfo.taskType===this.taskTypeItem.PREVIEW
                || this.showModel.taskInfo.taskType===this.taskTypeItem.PREEMPTION">
                    <sweet-radio :label=this.taskTypeItem.PREVIEW>
                        {{this.taskTypeMapItem[this.taskTypeItem.PREVIEW]}}
                    </sweet-radio>
                    <sweet-radio :label=this.taskTypeItem.PREDICT>
                        {{this.taskTypeMapItem[this.taskTypeItem.PREDICT]}}
                    </sweet-radio>
                    <sweet-radio :label=this.taskTypeItem.PREEMPTION>
                        {{this.taskTypeMapItem[this.taskTypeItem.PREEMPTION]}}
                    </sweet-radio>
                </div>
                <!--机器人外呼-->
                <div v-if="this.showModel.taskInfo.taskType===this.taskTypeItem.INTELLIGENT
                || this.showModel.taskInfo.taskType===this.taskTypeItem.AUTO">
                    <sweet-radio :label=this.taskTypeItem.INTELLIGENT v-if="cmsEnvironment==='aicc'">
                        {{this.taskTypeMapItem[this.taskTypeItem.INTELLIGENT]}}
                    </sweet-radio>
                    <sweet-radio :label=this.taskTypeItem.AUTO>
                        {{this.taskTypeMapItem[this.taskTypeItem.AUTO]}}
                    </sweet-radio>
                </div>
            </sweet-radio-group>
        </sweet-form-item>
        <!--模板-->
        <sweet-form-item v-if="isCreate" :label="$t('isales.calltime.task.template')">
            <sweet-input v-model="templateObj.taskName" @click="selectTemplateDialogVisible=true"
                         readonly="true" clearable
                         class="width-400px"
                         :placeholder="$t('isales.calltime.task.template.select.placeholder')"/>
        </sweet-form-item>
        <!--主叫号码-->
        <sweet-form-item :label="$t('isales.taskinfo.field.callNo')" :required="!isBatchEdit" prop="taskInfo.queryCallerNos">
            <sweet-input v-model="showModel.taskInfo.queryCallerNos"
                         @click="isView || isViewTemplate?selectCallerNosDialogVisible=false:selectCallerNosDialogVisible=true"
                         readonly="true" clearable
                         class="width-400px"
                         :placeholder="$t('isales.calltime.task.callerno.select.placeholder')"/>
        </sweet-form-item>
        <!--任务日期-->
        <sweet-form-item v-if="!isCreateTemplate && !isEditTemplate && !isViewTemplate" :label="$t('isales.calltime.task.begin.end.date')" :required="!isBatchEdit" prop="taskInfo.date">
          <div class="width-400px">
            <sweet-date-picker
                v-model="showModel.taskInfo.date"
                type="daterange"
                :start-placeholder="this.$t('isales.taskinfo.field.beginDate')"
                :end-placeholder="this.$t('isales.taskinfo.field.endDate')"
                format="YYYY-MM-DD"
                @change="changeTaskTime"
                :disabled-date="disabledDate"
                :prefix-icon="isView ? this.customPrefix: ''"/>
          </div>
        </sweet-form-item>
        <!--技能队列 & IVR-->
        <div v-if="this.showModel.taskInfo.taskType===this.taskTypeItem.PREDICT
                || this.showModel.taskInfo.taskType===this.taskTypeItem.PREVIEW
                || this.showModel.taskInfo.taskType===this.taskTypeItem.PREEMPTION">
            <sweet-form-item :label="$t('isales.taskinfo.title.skillName')" :required="!isBatchEdit" prop="taskInfo.skillName">
                <sweet-input v-model="showModel.taskInfo.skillName" @click="selectSkillDialogVisible=true"
                             readonly="true"
                             class="width-400px"
                             :placeholder="$t('isales.taskinfo.placeholder.choose')"/>
            </sweet-form-item>
        </div>
        <div v-if="this.showModel.taskInfo.taskType===this.taskTypeItem.AUTO">
            <sweet-form-item :label="$t('isales.tasktype.field.auto.info')" :required="!isBatchEdit" prop="taskInfo.flowName">
                <sweet-input v-model="showModel.taskInfo.flowName" @click="selectAutoIvrDialogVisible=true"
                             readonly="true"
                             class="width-400px"
                             :placeholder="$t('isales.taskinfo.placeholder.choose')"/>
            </sweet-form-item>
        </div>
        <div v-if="this.showModel.taskInfo.taskType===this.taskTypeItem.INTELLIGENT">
            <sweet-form-item :label="$t('isales.tasktype.field.intelligent.info')" :required="!isBatchEdit" prop="taskInfo.flowName">
                <sweet-input v-model="showModel.taskInfo.flowName"
                             @click="selectIntelligentIvrDialogVisible=true"
                             readonly="true"
                             class="width-400px"
                             :placeholder="$t('isales.taskinfo.placeholder.choose')"/>
            </sweet-form-item>
        </div>
        <!--优先级-->
        <sweet-form-item :label="$t('isales.taskinfo.field.priority')" :required="!isBatchEdit && !isCreateTemplate && !isEditTemplate &&!isViewTemplate" prop="taskInfo.priority">
          <div class="width-400px">
            <sweet-select v-model="showModel.taskInfo.priority"
                          :placeholder="$t('isales.taskinfo.placeholder.choose')" :fit-input-width="true" :show-resize="false">
              <sweet-option v-for="(item, index) in this.selectTaskInfoPriorityItem" :key="item.value"
                            :label="item.label"
                            :value="item.value"/>
            </sweet-select>
          </div>
        </sweet-form-item>
        <!--问卷-->
        <div v-if="cmsEnvironment==='aicc' && this.showModel.taskInfo.taskType!==this.taskTypeItem.INTELLIGENT">
            <sweet-form-item :label="$t('isales.taskinfo.field.survey')">
                <sweet-input v-model="showModel.taskInfo.surveyName" @click="selectSurveyDialogVisible=true"
                             class="width-400px"
                             clearable
                             @clear="clearSurvey"
                             @change="changeSurvey"
                             :placeholder="$t('isales.taskinfo.placeholder.choose')"/>
            </sweet-form-item>
        </div>
        <!--播放问卷流程-->
        <div v-if="this.showModel.taskInfo.taskType!==this.taskTypeItem.AUTO && this.showModel.taskInfo.scenarioType==='4'">
            <sweet-form-item :label="$t('isales.tasktype.field.manualIvr.info')">
                <sweet-input v-model="showModel.taskInfo.flowName" @click="selectAutoIvrDialogVisible=true"
                             readonly="true"
                             class="width-400px"
                             :placeholder="$t('isales.taskinfo.placeholder.choose')"/>
            </sweet-form-item>
        </div>
        <!--短信模板-->
        <div v-if="cmsEnvironment==='aicc'">
            <sweet-form-item :label="$t('isales.taskinfo.field.smsTemplate')">
                <sweet-input v-model="showModel.taskInfo.smsTemplateName" @click="selectSmsDialogVisible=true"
                             class="width-400px"
                             clearable
                             @clear="clearSmsTemplate"
                             @change="changeSmsTemplate"
                             :placeholder="$t('isales.taskinfo.placeholder.choose')"/>
            </sweet-form-item>
        </div>
        <!--回调地址-->
        <sweet-form-item :label="$t('isales.taskinfo.field.callBackUrl')" prop="taskInfo.callBackUrl">
            <sweet-input class="width-400px" v-model="showModel.taskInfo.callBackUrl"/>
            <span style="font-size: 11px; padding: 10px; color: #e6b679"
                  v-if="showModel.taskInfo.callBackUrl && showModel.taskInfo.callBackUrl.startsWith('http://')">{{$t("isales.whitepopuppageurl.protocol.check")}}</span>
        </sweet-form-item>
        <!--任务描述-->
        <sweet-form-item v-if="!isBatchEdit" prop="taskInfo.description">
            <!--使用label插槽时不能直接设置required属性和required校验（要用validator），否则aa显示异常-->
            <template #label>
                <sweet-tooltip class="box-item" effect="light" :placement="topPlacement">
                    <template #content>
                        <strong>
                            {{$t('isales.rbtrecognition.remark.tip')}}<br/>`~!@#$%^&*()+=|{}'":;[]/?.<br/>~！@#￥%……&*（）——+|{}【】‘；：”“’,、？\
                        </strong>
                    </template>
                    <div class="aicc-align--absolute-left">
                        <span class="icon-class">{{ $t('isales.taskinfo.field.description') }}</span>
                        <sweet-icon><warning style="transform: rotateX(180deg)" /></sweet-icon>
                    </div>
                </sweet-tooltip>
            </template>
            <sweet-input class="width-400px" v-model="showModel.taskInfo.description"/>
        </sweet-form-item>
    </div>
    <isalesTemplateSelect v-model:show="selectTemplateDialogVisible" v-if="selectTemplateDialogVisible"
                          @isalesTemplateSelectConfirm="isalesTemplateSelectConfirm"></isalesTemplateSelect>
    <isalesCallerNoSelect v-model:show="selectCallerNosDialogVisible" v-if="selectCallerNosDialogVisible"
                          @isalesCallerNoSelectConfirm="isalesCallerNoSelectConfirm" :type="queryType"
                          :taskId="queryTaskId" :cmsEnvironment="cmsEnvironment"
                          :tasktype="showModel.taskInfo.taskType"
                          :callernolist="showModel.taskInfo.callerNo"></isalesCallerNoSelect>
    <isalesSkillSelect v-model:show="selectSkillDialogVisible" v-if="selectSkillDialogVisible"
                       @isalesSkillSelectConfirm="isalesSkillSelectConfirm"
                       :cmsEnvironment="cmsEnvironment"></isalesSkillSelect>
    <isalesSurveySelect v-model:show="selectSurveyDialogVisible" v-if="selectSurveyDialogVisible"
                        @isalesSurveySelectConfirm="isalesSurveySelectConfirm"
                        :taskType="showModel.taskInfo.taskType"></isalesSurveySelect>
    <isalesSmsSelect v-model:show="selectSmsDialogVisible" v-if="selectSmsDialogVisible"
                     @isalesSmsSelectConfirm="isalesSmsSelectConfirm"></isalesSmsSelect>
    <isalesAutoIvrSelect v-model:show="selectAutoIvrDialogVisible" v-if="selectAutoIvrDialogVisible"
                         @isalesAutoIvrSelectConfirm="isalesAutoIvrSelectConfirm"></isalesAutoIvrSelect>
    <isalesIntelligentIvrSelect v-model:show="selectIntelligentIvrDialogVisible" v-if="selectIntelligentIvrDialogVisible"
                                @isalesIntelligentIvrSelectConfirm="isalesIntelligentIvrSelectConfirm"></isalesIntelligentIvrSelect>
</template>

<script>
    import {TASK_PRIORITY_TYPES_ENUM} from "@/views/isales/views/taskManagement/enum.js";
    import isalesTemplateSelect from "@/views/isales/views/systemAutoCallTask/isalesTemplateSelect.vue";
    import isalesCallerNoSelect from "@/views/isales/views/systemAutoCallTask/isalesCallerNoSelect.vue";
    import isalesSkillSelect from "@/views/isales/views/systemAutoCallTask/isalesSkillSelect.vue";
    import isalesSurveySelect from "@/views/isales/views/systemAutoCallTask/isalesSurveySelect.vue";
    import isalesSmsSelect from "@/views/isales/views/systemAutoCallTask/isalesSmsSelect.vue";
    import isalesAutoIvrSelect from "@/views/isales/views/systemAutoCallTask/isalesAutoIvrSelect.vue";
    import isalesIntelligentIvrSelect from "@/views/isales/views/systemAutoCallTask/isalesIntelligentIvrSelect.vue";
    import {TASK_TYPE, TASK_TYPE_MAP} from "@/views/isales/views/taskManagement/enum";
    import {queryCmsEnvironment} from "@/views/isales/api/systemAutoCallTask";
    import { h, shallowRef } from 'vue'
    import {useBaseStore} from "@/views/base/stores";
    export default {
        name: "SystemAutoTaskBasicInfo",
        components: {
            isalesTemplateSelect,
            isalesCallerNoSelect,
            isalesSkillSelect,
            isalesSurveySelect,
            isalesSmsSelect,
            isalesAutoIvrSelect,
            isalesIntelligentIvrSelect
        },
        props: {
            showModel: {
                type: Object,
                default() {
                    return {}
                },
            },
            type: {
                type: String,
                default: 'create'
            },
        },
        data() {
            return {
                topPlacement: window.isRTL ? 'top-end' : 'top-start',
                customPrefix : shallowRef({
                    render() {
                        return h('p', '')
                    },
                }),
                queryType:'',
                queryTaskId:'',
                cmsEnvironment: '',
                selectTemplateDialogVisible: false,
                selectCallerNosDialogVisible: false,
                selectSkillDialogVisible: false,
                selectSurveyDialogVisible: false,
                selectSmsDialogVisible: false,
                selectAutoIvrDialogVisible: false,
                selectIntelligentIvrDialogVisible: false,
                templateObj: { //任务模板对象
                    taskId: '',
                    taskName: '',
                },
                selectTaskInfoPriorityItem: TASK_PRIORITY_TYPES_ENUM,
                taskTypeItem: TASK_TYPE,
                taskTypeMapItem: TASK_TYPE_MAP,
                tasktype: '',
            }
        },
        methods: {
            isalesTemplateSelectConfirm(data) {
                if (data && data.taskId) {
                    this.templateObj = data;
                    this.$emit('templateSysSelectConfirm', this.templateObj.taskId, 'template');
                }
            },
            isalesCallerNoSelectConfirm(data) {
                if ((this.isCreate || this.isBatchEdit || this.isCreateTemplate) && data) {
                    this.showModel.taskInfo.callerNo = data;
                    this.showModel.taskInfo.queryCallerNos = [];
                    this.showModel.taskInfo.callerNo.forEach(item => {
                        this.showModel.taskInfo.queryCallerNos.push(item.accessCode);
                    })
                } else if(this.isEditTemplate){
                    this.$emit('templateSysSelectConfirm', this.showModel.taskInfo.taskId, 'template');
                }else {
                    this.$emit('templateSysSelectConfirm', this.showModel.taskInfo.taskId, null);
                }
            },
            isalesSkillSelectConfirm(data) {
                if (data) {
                    this.showModel.taskInfo.skillId = data.skillId;
                    this.showModel.taskInfo.skillName = data.name;
                }else {
                  window.showTab({ id: '8001010', name: '8001010', title: this.$t('isales.taskinfo.field.skillName') })
                }
            },
            isalesAutoIvrSelectConfirm(data) {
                if (data) {
                    this.showModel.taskInfo.ivrAccessCode = data.flowAccessCode;
                    if(this.cmsEnvironment==='cms'){
                        this.showModel.taskInfo.flowName = data.flowAccessCode;
                    }else {
                        this.showModel.taskInfo.flowName = data.flowname;
                    }
                }
            },
            isalesIntelligentIvrSelectConfirm(data) {
                if (data) {
                    this.showModel.taskInfo.ivrFlowCode = data.flowCode;
                    this.showModel.taskInfo.flowId = data.flowId;
                    this.showModel.taskInfo.flowName = data.flowName;
                }
            },
          clearSurvey() {
            this.showModel.taskInfo.surveyId = "";
            this.showModel.taskInfo.surveyName = "";
            this.showModel.taskInfo.scenarioType = "";
          },
          changeSurvey() {
            if (!this.showModel.taskInfo.surveyName) {
              this.showModel.taskInfo.surveyId = "";
              this.showModel.taskInfo.scenarioType = "";
            }
          },
          isalesSurveySelectConfirm(data) {
                if (data) {
                    this.showModel.taskInfo.surveyId = data.scriptId;
                    this.showModel.taskInfo.surveyName = data.scriptName;
                    this.showModel.taskInfo.scenarioType = data.scenarioType;
                }
            },
          clearSmsTemplate() {
            this.showModel.taskInfo.smsTemplateId = "";
            this.showModel.taskInfo.smsTemplateName = "";
          },
          changeSmsTemplate() {
            if (!this.showModel.taskInfo.smsTemplateName) {
              this.showModel.taskInfo.smsTemplateId = "";
            }
          },
            isalesSmsSelectConfirm(data) {
                if (data) {
                    this.showModel.taskInfo.smsTemplateId = data.id;
                    this.showModel.taskInfo.smsTemplateName = data.smsName;
                }
            },
            changeTaskTime(data) {
                this.showModel.taskInfo.beginDate = this.$TimeUtils.getUTCTimestamp(data[0]);
                this.showModel.taskInfo.endDate = this.$TimeUtils.getUTCTimestamp(data[1]);
            },
            disabledDate(date, type) {
                const currentDate = new Date();
                let today = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
                return date.getTime() < today.getTime();
            },
            async queryCmsEnvironment() {
                let result = await queryCmsEnvironment();
                this.cmsEnvironment = result.cmsEnvironment;
            },
            clearIvr() {
                if (this.showModel.taskInfo.taskType === this.taskTypeItem.INTELLIGENT ||
                    this.showModel.taskInfo.taskType === this.taskTypeItem.AUTO) {
                    this.showModel.taskInfo.ivrFlowCode = '';
                    this.showModel.taskInfo.flowId = '';
                    this.showModel.taskInfo.flowName = '';
                    this.showModel.taskInfo.ivrAccessCode = '';
                }
            }
        },
        computed: {
            isView() {
                return this.queryType === 'view'
            },
            isEdit() {
                return this.queryType === 'edit'
            },
            isCreate() {
                return this.queryType === 'create'
            },
            isBatchEdit() {
                return this.queryType === 'batchEdit'
            },
            isCreateTemplate(){// 新建外呼任务模型
                return this.queryType === 'createTemplate'
            },
            isEditTemplate(){// 编辑外呼任务模型
                return this.queryType === 'editTemplate'
            },
            isViewTemplate(){// 编辑外呼任务模型
                return this.queryType === 'viewTemplate'
            },
        },
        async created() {
            this.queryType = this.$route.query.type;
            this.queryTaskId = this.$route.query.taskId;
            await this.queryCmsEnvironment();
        }
    }
</script>

<style lang="less" scoped>
    .width-400px {
      width: 400px;
      border-radius: 4px;
    }
    .icon-class{
        margin-right: 9px;
    }
    body.RTL{
        .icon-class{
            margin-left: 9px;
            margin-right: unset;
        }
    }
</style>