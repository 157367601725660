export default {
  "isales.specialday.title.speclistmanage.moveblacklists" : "التحويل إلى Blocklist",
  "isales.manual.task.survey.questiontotal" : "مجموع {0} أسئلة",
  "isales.agent.button.search" : "استعلام",
  "isales.taskresult.title.chooseBeginTime" : "الرجاء تحديد وقت البدء",
  "isales.busi.result.title.description" : "الوصف",
  "isales.manual.title.inputresult" : "أدخل نتيجة مكالمة صادرة",
  "isales.taskresult.title.retryTime" : "محاولات الاتصال",
  "isales.import.filter.message.busiResultIsNull" : "لا يمكن أن تكون نتيجة العمل فارغة",
  "isales.appointment.title.task" : "إقران مهمة مكالمة صادرة",
  "isales.training.forwardNumber" : "رقم إعادة التوجيه",
  "isales.statistics.field.abandonmentRateDescription" : "التخلي عن معدل",
  "isales.file.message.SK.placeholder" : "أدخل SK",
  "isales.tasktemplate.field.templateName" : "اسم القالب",
  "isales.taskstatistics.placeholder.calling" : "الاتصال: {0}",
  "isales.manualdata.title.call" : "مكالمة",
  "isales.manual.title.result" : "النتيجة",
  "isales.taskmanagement.title.createTask" : "إنشاء",
  "isales.agent.pageurls.success" : "النجاح",
  "isales.management.placeholder.calledNo" : "أدخل رقماً مطلوباً",
  "isales.datasourcemanagement.message.dbIp" : "تنسيق عنوان IP لقاعدة البيانات غير صحيح",
  "isales.data.title.tableTitle" : "قائمة الأرقام",
  "isales.data.prop.anonymType.countError" : "يمكن تعريف 10 سمات من نوع التشفير كحد أقصى",
  "isales.blacklist.title.validityTime" : "مدة الصلاحية (ساعة)",
  "isales.task.promotion.updateSuccess" : "تم تعديل مهمة تسويق الوسائط المتعددة بنجاح",
  "isales.file.title.rule.property.row" : "عمود السمة",
  "isales.tasklogic.field.callturntype" : "وضع الجولة",
  "isales.specialday.title.holidayName" : "اسم التاريخ الخاص",
  "isales.agent.label.maxWaitTimeSpan" : "تتراوح القيمة من 0 إلى 60000",
  "isales.appointment.message.adjustmentEmpty" : "لم يتم تحديد أية بيانات مكالمات صادرة محجوزة صالحة",
  "isales.tasklogic.field.callInterval" : "الفاصل الزمني للاتصال بالمغادرة (ثانية)",
  "isales.taskmanagement.field.answer" : "نص الاستجابة",
  "isales.import.message.failnum" : "عدد السجلات الفاشلة",
  "isales.task.message.startSuccess" : "تم بدء مهمة المكالمة الصادرة بنجاح",
  "isales.template.title.addManual" : "قالب مكالمة صادرة يدويًا",
  "isales.appointment.title.createAppoint" : "إنشاء مكالمة صادرة محجوزة",
  "isales.agent.message.agentMsgException" : "قم بتغيير كلمة المرور الأولية للموظف وقم بمزامنة كلمة المرور مع الموظف",
  "isales.file.message.rule.success" : "تم تعديل القاعدة بنجاح",
  "isales.file.title.username" : "اسم المستخدم",
  "isales.specialday.message.nameError" : "اسم التاريخ الخاص بتنسيق غير صحيح",
  "isales.taskmanagement.title.batchStartTask" : "بدء الدفعات",
  "isales.taskmanagement.field.quotas" : "الحد الأعلى",
  "isales.taskmanagement.field.Automatic" : "مكالمة صادرة تلقائية",
  "isales.survey.querydetail" : "نتيجة الاستطلاع",
  "isales.import.message.repeatnum" : "عدد السجلات المكررة",
  "isales.manual.field.callingRecord" : "سجل المكالمات الصادرة",
  "isales.file.title.delete.fail" : "فشل حذف معلومات الخادم",
  "isales.task.field.appointState4" : "اكتملت",
  "isales.agent.message.skillConfFail" : "فشل تكوين قائمة انتظار المهارة",
  "isales.task.field.appointState3" : "رد الاتصال",
  "isales.task.field.appointState2" : "على استعداد لمعاودة الاتصال",
  "isales.manual.field.callingtask" : "الاتصال بالمهمة",
  "isales.task.field.appointState1" : "محجوز",
  "isales.task.promotion.promotionType" : "نوع الوسائط",
  "isales.datasourcemanagement.field.backdbpass" : "كلمة مرور مصدر البيانات البديل",
  "isales.taskresult.title.choosebusiresult" : "يُرجى تحديد نتيجة الأعمال",
  "isales.common.message.checkpositiveinteger" : "أدخل عددًا صحيحًا موجبًا بالتنسيق الصحيح",
  "isales.task.promotion.whatsApp" : "WhatsApp",
  "isales.taskresult.field.notCheckIn" : "لم يقم الموظف بتسجيل الدخول",
  "isales.data.prop.isPushAgent.countError" : "يمكن تعريف 15 سمة كحد أقصى ليتم دفعها إلى وكيل",
  "isales.manual.message.lock.success" : "تم قفل بيانات المكالمة الصادرة بنجاح",
  "isales.file.title.serverInformation" : "معلومات الخادم",
  "isales.taskresult.field.failsFailedRouteSkillQueue" : "فشل في توجيه قائمة انتظار المهارة",
  "isales.work.message.dategt7days" : "لا يمكن أن يتجاوز النطاق الزمني سبعة أيام",
  "isales.taskinfo.title.accesscodedesc" : "وصف رمز الوصول",
  "isales.management.message.taskIdEmpty" : "إشارة مهمة المكالمة الصادرة فارغة",
  "isales.tasktype.field.robot.template" : "روبوت قالب المكالمة الصادرة",
  'isales.tasktype.field.robot.templateAdd': 'قالب مكالمة بوت جديد',
  "isales.tasktype.field.manualIvr.info" : "تشغيل عملية الاستبيان",
  "isales.file.message.AK.placeholder" : "أدخل AK",
  "isales.management.message.templateAnalyse" : "حسب القالب",
  "isales.agent.title.skillModify" : "تعديل قائمة انتظار المهارة",
  "isales.taskinfo.message.batchUpdate.paramsNull" : "تحرير المعلمة المراد تعديلها",
  "isales.specialday.field.Wednesday" : "الأربعاء",
  "isales.taskmanagement.field.taskName" : "اسم المهمة",
  "isales.taskmanagment.title.obs.viewstatistics" : "مراقبة البيانات التي يحصل عليها OBS",
  "isales.notification.receiver.whatsapp.placeholder" : "يرجى إدخال رقم المستلم مع رمز المنطقة الدولية",
  "isales.business.result.searchChildrenName" : "أدخل اسم نتيجة عمل فرعي",
  "isales.task.message.NoPauseTask" : "لا تتوفر أي مهمة تستوفي الشروط",
  "isales.blacklist.placeholder.restrictTimes" : "أدخل الحد الأقصى لعدد المكالمات",
  "isales.agent.field.newpassword" : "كلمة مرور جديدة",
  "isales.agent.message.refreshSkillException" : "حدث خطأ عند تحديث قائمة انتظار المهارة",
  "isales.task.promotion.sendLimit" : "حد الإرسال اليومي",
  "isales.taskstatistics.message.Unit.Piece" : "الوحدة: رقم",
  "isales.taskInfo.title.callCompletedNumTips" : "عندما يتجاوز عدد المكالمات المكتملة قيمة هذه المعلمة، لا يقوم النظام باسترداد المكالمات تلقائيًا",
  "isales.task.promotion.deleteSuccess" : "تم حذف مهمة تسويق الوسائط المتعددة بنجاح",
  "isales.taskresult.title.userAccount" : "تم البدء بواسطة",
  "isales.specialday.message.weeklyRepeat" : "فترة التاريخ الخاصة المكررة",
  "isales.taskinfo.message.callintervalrangeMax" : "الرجاء إدخال عدد صحيح موجب بين 1-86400.",
  "isales.taskstatistics.field.feedBackStatistics" : "إحصائيات التغذية الراجعة",
  "isales.file.title.bucket" : "دلو",
  "isales.management.title.setResultCode" : "يرجى تعيين رمز النتيجة",
  "isales.taskinfo.message.input" : "أحرف غير صالحة",
  "isales.agent.contact.clicktodial" : "انقر للاتصال",
  "isales.specialday.field.byDate" : "حسب التاريخ",
  "isales.rbtresult.field.longtimemissed" : "وقت طويل لا مكالمة",
  "isales.operlog.message.exportSurveyData" : "تصدير أسئلة التدريب",
  "isales.taskinfo.field.audioAgentType" : "الصوت",
  "isales.taskresult.field.failsNotintheservicearea" : "الهاتف خارج الخدمة",
  "isales.file.title.editserver" : "تعديل الملقم",
  "isales.agent.label.information" : "معلومات",
  "isales.import.message.template.dataNum" : "يمكن استيراد {0} سجلات بيانات كحد أقصى في المرة الواحدة",
  "isales.file.title.SK" : "SK",
  "isales.taskstatistics.field.notcompletedcalls" : "معلق",
  "isales.specialday.field.Sunday" : "الأحد",
  "isales.calleeNo.length.max32" : "يمكن أن يحتوي رقم العميل على 32 حرفًا كحد أقصى",
  "isales.taskcalllogic.message.typeConflictWhenDataTypeSame" : "يوجد نهج يكون نوع رقمه مماثلاً لنوع النهج الموجود\، ولكن يتم تكوين نُهج مختلفة استنادًا إلى النتيجة",
  "isales.training.startTraining.exists" : "التدريب في التقدم. يرجى المحاولة مرة أخرى بعد اكتمال التدريب",
  "isales.result.displayResult" : "سبب فشل المكالمة الصادرة",
  "isales.tasklogic.field.isResultCallnoLogic" : "هل سيتم التكوين استنادًا إلى النتيجة",
  "isales.statistics.business.noMoreThanEight" : "لا يمكن أن يتجاوز عدد العناصر المحددة 8 عناصر",
  "isales.training.callTimeSegment" : "مقطع وقت المكالمة",
  "isales.tasklogic.field.numberorder" : "الرقم التسلسلي",
  "isales.taskinfo.title.chooseSkill" : "تحديد قائمة انتظار المهارات",
  "isales.work.title.reservedCalls" : "المكالمات المحجوزة",
  "isales.task.promotion.promotionPolicy" : "سياسة التسويق",
  "isales.manual.message.lock.failed" : "فشل تأمين بيانات المكالمة الصادرة",
  "isales.result.outgoingCallResult" : "نتيجة مكالمة صادرة",
  "isales.task.promotion.whatsApp.message.name" : "اسم رسالة القالب",
  "isales.import.filter.message.busiSubStatusSame" : "نتائج الأعمال الفرعية تحت نفس نتيجة الأعمال لا يمكن أن تكون هي نفسها",
  "isales.calltime.task.begin.end.date" : "بدء المهمة ووقت الانتهاء",
  "isales.agent.calledroute.fail" : "فشل",
  "isales.task.title.callpolicy" : "سياسة المكالمات الصادرة",
  "isales.statistics.business.startYear" : "بداية السنة",
  "isales.tasktype.field.predict" : "مكالمة صادرة متوقعة",
  "isales.blacklist.message.customer.notincall" : "العميل ليس في مكالمة",
  "isales.data.title.edit" : "تحرير",
  "isales.skillqueue.agentrole.qc" : "مفتش الجودة",
  "isales.taskmanagement.title.batchPauseTask" : "دفعة تعليق",
  "isales.taskmanagement.title.editLogic" : "تغيير المنطق",
  "isales.operlog.message.data.downloadfailfile" : "تنزيل ملف البيانات الفاشلة",
  "isales.taskresult.field.failsCalledpartybusy" : "الطرف المطلوب مشغول",
  "isales.taskmanagement.field.pickUpRuleLimit" : "القيود",
  "isales.taskresult.field.success" : "ناجح",
  "isales.management.message.orderSequence" : "ترتيب تصاعدي",
  "isales.taskstatistics.field.callCompletionRate" : "معدل اكتمال المكالمة",
  "isales.outboundtime.title.set" : "تعيين وقت المكالمة الصادرة",
  "isales.tasktype.field.robot.create" : "مكالمة روبوت جديدة صادرة",
  "isales.workbench.execution" : "تنفيذ مكالمة صادرة",
  "isales.management.title.adjustment.changeTime" : "تم التعديل على",
  "isales.data.orderType.asc" : "تصاعدي",
  "isales.statistics.business.indicatorName" : "اسم المؤشر",
  "isales.file.message.rule.columnRepeat" : "لا يمكن أن يكون عمود معرف المكالمة الصادرة\، وعمود الأرقام\، وعمود الفئة\، وعمود الفاصل الزمني للمكالمة\، وعمود السمة متماثلين",
  "isales.repeatfile.export.repeatline" : "خط متكرر",
  "isales.agent.contact.Voice" : "صوت",
  "isales.taskresult.title.failCode" : "السبب",
  "isales.datasourcemanagement.field.backdbpass2" : "تأكيد كلمة مرور مصدر البيانات البديل",
  "isales.taskinfo.message.callBackUrl" : "أدخل عنوان رد الاتصال",
  "isales.manual.message.call.success" : "تم إجراء المكالمة الصادرة بنجاح",
  "isales.task.message.numberNotcalled" : "السجلات الفاشلة: {0}",
  "isales.agent.label.busy" : "قائمة انتظار مشغولة أو تجاوز",
  "isales.task.promotion.basicInfo" : "معلومات أساسية",
  "isales.taskmanagement.message.crossmonth" : "لا يمكن لوقت البدء ووقت الانتهاء أن يمتدا لأشهر",
  "isales.taskresult.field.failsCallrejected" : "تم رفض المكالمة",
  "isales.agent.label.release" : "الافراج",
  "isales.task.promotion.sendGateway" : "بوابة",
  "isales.file.title.addserver" : "إضافة ملقم",
  "isales.taskmanagement.field.calledNo" : "الرقم المطلوب",
  "isales.import.message.result.tip" : "فشل تصدير نتيجة التحديد التفاضلي",
  "isales.work.message.taskNameLengthLimit" : "لا يمكن أن يتجاوز اسم المهمة 50 حرفًا",
  "isales.task.promotion.isExp" : "انتهت صلاحية مهمة تسويق الوسائط المتعددة",
  "isales.taskresult.field.failsInvalidUser" : "مستخدم غير صالح",
  "isales.file.title.addrule" : "إضافة قاعدة",
  "isales.agent.label.skillpara" : "إعدادات المعلمة",
  "isales.task.promotion.invalidation" : "غير صالح",
  "isales.taskmanagment.message.chartDataError" : "فشل تحميل المخطط",
  "isales.calledroute.title.selectivrflow" : "اختر روبوتًا ذكيًا",
  "isales.taskmanagement.message.timeerror" : "لا يمكن أن يكون وقت البدء بعد وقت الانتهاء",
  "isales.rbtresult.field.cusclose" : "يقوم المستخدم المتصل بإيقاف تشغيل الهاتف",
  "isales.statistics.business.beginDate" : "تاريخ البدء",
  "isales.taskcalllogic.field.notServiceArea" : "العميل ليس في منطقة الخدمة",
  "isales.specialday.title.createspecialday" : "إنشاء تاريخ خاص",
  "isales.taskmanagement.message.queryTime" : "إدخال وقت كامل",
  "isales.taskmanagement.title.batchEditCallPolicy" : "سياسة تعديل المكالمات الصادرة على دفعة",
  "isales.task.detail" : "تفاصيل مهمة المكالمة الصادرة",
  "isales.manual.title.viewPreemptionResult" : "نتيجة مكالمة صادرة مسبقة-",
  "isales.file.title.import.file.invalid" : "فشل التحقق من الملف",
  "isales.management.message.dbIp" : "تنسيق IP غير صحيح",
  "isales.label.agent.role" : "الدور",
  "isales.import.message.numberTooMany" : "يمكن تحميل 50000 رقم كحد أقصى",
  "isales.specialday.message.enterworkno" : "أدخل معرف الموظف",
  "isales.management.placeholder.commonIvrFlowAccessCode" : "أدخل رمز وصول لتدفق IVR عام",
  "isales.taskmanagement.title.sysAuto.template" : "قالب مكالمة صادرة تلقائية للنظام",
  'isales.taskmanagement.title.sysAuto.templateAdd': 'إنشاء قالب مكالمة صادرة تلقائية للنظام',
  "isales.training.surveyScore" : "مجموع نتائج الاستطلاع",
  "isales.step.taskinfo" : "معلومات المهمة",
  "isales.tasklogic.field.callturntype.numbertype" : "حسب نوع الرقم",
  "isales.statistics.business.dategt12weeks" : "لا يمكن أن يتجاوز نطاق الأسبوع 12 أسبوعًا",
  "isales.taskresult.field.failsModemorfax" : "تم الكشف عن مودم أو فاكس",
  "isales.taskresult.field.failsResulttimesoutafterpicksup" : "نتيجة اكتشاف المكالمة الصادرة بعد انتهاء مهلة إيقاف تشغيل ACD",
  "isales.notification.receiver.number" : "رقم المستلم",
  "isales.data.prop.mandatory" : "إلزامي",
  "isales.common.title.dataReset" : "إعادة الضبط",
  "isales.taskinfo.title.chooseAgent" : "تحديد موظف",
  "isales.busiresult.description.length" : "يتجاوز طول سلسلة الأحرف نطاق القيمة. يجب أن يكون الطول أقل من أو يساوي 500 حرف",
  "isales.manualData.message.noPickCondition" : "يُرجى تعيين الشروط",
  "isales.taskstatistics.field.failCount" : "عدد المكالمات الفاشلة",
  "isales.work.title.serviceAccount" : "حساب الخدمة",
  "isales.data.title.importResult" : "نتيجة الاستيراد",
  "isales.management.message.filter" : "تصفية البيانات",
  "isales.work.title.callTimeTotal" : "إجمالي مدة المكالمة (بالثانية)",
  "isales.agent.calledroute.extcodewarn" : "يمكن أن تحتوي رموز الملحقات على أرقام فقط",
  "isales.taskresult.field.no" : "لا",
  "isales.blacklist.message.confirmOpt" : "هل تريد بالتأكيد إجراء عمليات على رقم القائمة الخاصة المحدد؟",
  "isales.task.promotion.all" : "الكل",
  "isales.taskpolicy.field.minChangeTime" : "الحد الأدنى لمدة الإقامة",
  "isales.task.result.export.message" : "تم إنشاء مهمة التصدير بنجاح. هل تريد الانتقال إلى قائمة التصدير لعرضها؟",
  "isales.management.title.adjustment.destAccountName" : "منفذ جديد",
  "isales.manutask.title.viewresult" : "نتيجة مكالمة صادرة",
  "isales.common.title.edit" : "تحرير",
  "isales.taskcalllogic.message.maxPolicyNo" : "يُسمح بخمسة أنواع من الأرقام غير الافتراضية كحد أقصى",
  "isales.task.status.pause" : "إيقاف مؤقت",
  "isales.common.title.save" : "حفظ",
  "isales.management.title.editLogic" : "تغيير المنطق",
  "isales.taskresult.field.failsSsit" : "نغمة لإرسال معلومات مخصصة",
  "isales.manualdata.title.order" : "حجز مكالمة صادرة",
  "isales.taskmanagement.title.endtime" : "وقت الانتهاء",
  "isales.data.prop.isPushAgent.anonymTypeError" : "لا يمكن دفع سمات نوع التشفير إلى وكيل",
  "isales.task.field.callFlagProgress" : "التنفيذ",
  "isales.training.Questionnaire" : "استبيان التدريب",
  "isales.appointment.message.notInWhiteList" : "الرقم غير موجود في قائمة الثقة ولا يمكن استدعاءه",
  "isales.statistics.business.byDay" : "التقرير اليومي",
  "isales.file.title.rule.basic" : "معلومات أساسية",
  "isales.agent.calledroute.success" : "نجاح",
  "isales.work.title.outboundCallTask" : "مهمة مكالمة صادرة",
  "isales.taskinfo.message.batchEditCallLogic.differentCallModeExist" : "لا يمكن تعديل منطق المكالمات الصادرة على دفعات للمهام ذات أوضاع استدعاء مختلفة",
  "isales.manual.message.task.isnorunning" : "مهمة المكالمة الصادرة الحالية ليست في الحالة تنفيذ",
  "isales.management.message.blackCalledNo" : "الرقم المطلوب موجود في قائمة الحظر ولا يمكن إضافته",
  "isales.business.result.children" : "الحالة الفرعية",
  "isales.taskresult.field.failsUnknownError" : "خطأ غير معروف",
  "isales.statistics.business.confirm" : "شكر وتقدير",
  "isales.step.outboundcalldata" : "بيانات المهمة",
  "isales.marketing.notification" : "تسويق الوسائط المتعددة",
  "isales.agent.contact.audioclicktodial" : "صوت النقر للطلب",
  "isales.statistics.business.search" : "البحث",
  "isales.outboundtime.title.endTime" : "وقت الانتهاء",
  "isales.taskresult.field.failsFailedAgentIsBusy" : "الموظف ليس في الحالة خامل",
  "isales.file.title.rule.data" : "رقم البيانات",
  "isales.taskpolicy.field.connRateMethod" : "خوارزمية لمعدل اتصال المكالمات الصادرة",
  "isales.work.title.completeNum" : "الاستطلاعات المكتملة",
  "isales.busi.result.message.lastrecord" : "تعيين Pleanse نتيجة الأعمال من آخر الصادرة أولا",
  "isales.manual.title.viewPredictionResult" : "نتيجة مكالمة صادرة متوقعة-",
  "isales.calledroute.button.finish" : "إنهاء",
  "isales.taskmanagement.title.deleteSuccess" : "تم حذف المهمة بنجاح",
  "isales.management.message.sessionIdFormatIncorrect" : "يمكن أن يحتوي sessionId على أرقام فقط",
  "isales.statistics.business.filteringIndicator" : "مؤشرات التصفية",
  "isales.agent.label.operate" : "العملية",
  "isales.manual.field.canBeCallCount" : "المكالمات المتاحة",
  "isales.taskInfo.title.pickUpCondition" : "شروط استخراج البيانات",
  "isales.language.label.language" : "يرجى اختيار اللغة",
  "isales.task.title.complete" : "إنهاء",
  "isales.enum.workSpace" : "مهمة مكالمة الموظف الصادرة",
  "isales.file.title.port.placeholder" : "أدخل رقم منفذ الخادم",
  "isales.manual.message.data.checklock.error" : "بيانات المكالمة الصادرة الحالية غير مقفلة أو تم قفلها بواسطة موظف آخر",
  "isales.task.promotion.isSendNow" : "إرسال فورا",
  "isales.management.message.createSuccess" : "تم إنشاء بيانات المكالمة الصادرة بنجاح",
  "isales.taskresult.field.failsSittone" : "نغمة معلومات خاصة",
  "isales.blacklist.title.deleteSuccess" : "النجاح",
  "isales.rbtresult.field.calledoutarea" : "الطرف المطلوب غير موجود في منطقة الخدمة",
  "isales.taskresult.field.failsUserrejection" : "تم رفض المكالمة",
  "isales.management.message.filterNo" : "لا",
  "isales.task.promotion.endTime" : "وقت الانتهاء",
  "isales.notification.receiver.address.placeholder" : "أدخل عنوان المستلم",
  "isales.manual.message.releaseData" : "تحديد بيانات المكالمات الصادرة المراد تحريرها",
  "isales.audio.title.audiotime" : "Duration(HH:mm:ss)",
  "isales.taskinfo.message.batchEditCallLogic.differentPolicyTypeExist" : "لا يمكن تعديل منطق المكالمات الصادرة على دفعات للمهام ذات أنواع النهج المختلفة",
  "isales.statistics.business.date" : "التاريخ",
  "isales.agent.field.curcountpassword" : "كلمة المرور الحالية",
  "isales.sendStatus.field.success" : "ناجح",
  "isales.task.promotion.receiver" : "المتلقي",
  "isales.agent.label.skillQueue" : "قائمة انتظار المهارات:",
  "isales.agent.label.accountName" : "اسم الموظف",
  "isales.taskInfo.title.pickUpNum" : "الكمية المطالب بها لمرة واحدة",
  "isales.taskresult.message.details" : "التفاصيل",
  "isales.notification.receiver.delete.variable" : "تكوين السمة",
  "isales.work.message.workNoLengthLimit" : "لا يمكن أن يتجاوز رقم العمل 5 أحرف",
  "isales.training.sampleTotal" : "إجمالي حجم العينة",
  "isales.agent.calledroute.create.media.match" : "رمز الوصول إلى النظام الذي تم تكوينه بواسطة الطرف المطلوب غير متناسق مع نوع وسائط الجهاز الذي تم تكوينه بواسطة الطرف المطلوب",
  "isales.manual.field.callednumber" : "الرقم المطلوب",
  "isales.task.promotion.expectedTime" : "وقت الإرسال المتوقع",
  "isales.skill.label.skilldesc" : "الوصف",
  "isales.agent.label.agentWeight" : "وكيل الوزن",
  "isales.taskmanagement.field.intention" : "النية المعترف بها",
  "isales.blacklist.message.customer.empty" : "لا يوجد عميل في مكالمة",
  "isales.agent.calledroute.skill" : "قائمة انتظار المهارات",
  "isales.taskcalllogic.message.dataTypeExist" : "DataType موجودة بالفعل",
  "isales.agent.message.syncPwdFailed" : "فشل في مزامنة كلمة مرور الموظف",
  "isales.statistics.business.description" : "الوصف",
  "isales.taskresult.field.failsFailedRouteCallToAttendant" : "فشل تحويل المكالمة إلى الموظف",
  "isales.tasksample.message.agentEmpty" : "لم يتم تحديد أي كائن تدريب",
  "isales.agent.label.playVoice" : "تشغيل نغمة الانتظار",
  "isales.tasklogic.field.turn" : "جولة",
  "isales.button.reset" : "إعادة الضبط",
  "isales.datasourcemanagement.field.backDbdriver" : "برنامج تشغيل مصدر البيانات البديل",
  "isales.taskInfo.spec.date.title.quotasTips" : "يشير هذا التكوين إلى التاريخ الخاص والفترة الزمنية التي تتطلب مكالمات صادرة",
  "isales.datasourcemanagement.field.backdbType" : "نوع مصدر البيانات البديل",
  "isales.agent.message.saveSuccess" : "تم حفظ تكوين الموظف بنجاح",
  "isales.task.result.export.skip" : "تخطي",
  "isales.file.title.rule.desc.placeHolder" : "أدخل وصف قاعدة",
  "isales.management.message.createTaskstatusError" : "لا يمكن إنشاء بيانات لمهمة المكالمة الصادرة لأنها ليست في حالة المسودة أو الإيقاف المؤقت",
  "isales.data.prop.propCodeExists" : "رمز السمة موجود بالفعل",
  "isales.tasklogic.message.busiresultpolicyIncomplete" : "معلومات سياسة نتائج الأعمال غير مكتملة",
  "isales.taskmanagement.title.sysAuto" : "يقوم النظام تلقائيًا بالاستدعاء",
  "isales.management.title.import" : "استيراد بيانات المكالمة الصادرة",
  "isales.business.result.delete.error" : "لم يتم تحديد أي نتيجة أعمال",
  "isales.file.title.rule.validator.server" : "تكوين الخادم غير صحيح",
  "isales.specialday.field.Friday" : "الجمعة",
  "isales.manual.message.title" : "أدخل عنوانًا",
  "isales.data.prop.status.published" : "تم النشر",
  "isales.speclist.blacklist.message.moveSuccess" : "تم نقل الرقم إلى القائمة السوداء بنجاح",
  "isales.taskresult.field.failsRecordcanceled" : "عندما يتغير OBS إلى حالة الاستعداد\، يتم إلغاء السجلات الجارية",
  "isales.datasourcemanagement.message.frequentOperation" : "عمليات متكررة. الرجاء الانتظار",
  "isales.management.message.notification.download" : "تنزيل قالب بيانات التسويق",
  "isales.datasourcemanagement.field.backdbUserName" : "اسم مستخدم مصدر البيانات البديل",
  "isales.task.promotion.baseInfo" : "معلومات أساسية",
  "isales.business.result.search" : "البحث",
  "isales.speclistmanage.title.calledNo" : "عدد",
  "isales.taskpolicy.message.saveSuccess" : "تم تكوين نهج المكالمة الصادرة بنجاح",
  "isales.import.message.ruleExtProp" : "تم تمكين السمات المخصصة. قم بتكوين قاعدة تعيين الاستيراد أولاً\، ثم انقر فوق استيراد حسب القاعدة",
  "isales.busi.result.item.unset" : "لم يتم تعيينه",
  "isales.import.message.upload.fail" : "فشل إيداع الملف",
  "isales.work.title.callTimeAvg" : "متوسط مدة المكالمة (بالثانية)",
  "isales.data.prop.status" : "الحالة",
  "isales.manual.message.call.update.error" : "فشل تحديث نتيجة المكالمة الصادرة",
  "isales.common.title.operation" : "العملية",
  "isales.statistics.business.warningFilterMax" : "يمكن اختيار {0} كحد أقصى للمؤشرات",
  "isales.statistics.business.errorInfo" : "فشل الحفظ. حدد مؤشراً واحداً على الأقل",
  "isales.business.result.edit" : "تعديل نتائج الأعمال",
  "isales.specialday.title.editSpecialday" : "تحرير التاريخ الخاص",
  "isales.agent.label.waitVoice" : "نغمة الانتظار",
  "isales.speclistmanage.title.operation" : "التشغيل",
  "isales.appointment.message.callFail" : "فشل",
  "isales.taskInfo.title.callCompletedNum" : "المكالمات المكتملة",
  "isales.statistics.business.title" : "تقرير عمل المكالمات الصادرة",
  "isales.taskresult.field.failsAgentdevicetimesout" : "انتهت مهلة شغل الوكيل",
  "isales.task.message.feedBackRate" : "معدل التغذية الراجعة",
  "isales.management.message.calledNoOneCategory" : "فئة الرقم المطلوب الأول",
  "isales.taskresult.field.failsNoanswer" : "لا يرد الطرف المطلوب على المكالمة",
  "isales.rbtrecognition.remark.tip" : "لا يمكن احتواء أحرف خاصة:",
  "isales.data.message.addNumberUpperLimit" : "يمكن إضافة 10 أرقام كحد أقصى",
  "isales.marketing.OutgoingCall" : "التسويق عبر الهاتف",
  "isales.file.title.rule.name" : "اسم القاعدة",
  "isales.audio.title.confirmedResult" : "نتائج التعرف على نغمة Ringback",
  "isales.management.title.callbot.auto" : "قم بتكوين الزر لاستدعاء الروبوت",
  "isales.tasktemplate.message.saveFail" : "فشل تكوين قالب المكالمة الصادرة",
  "isales.data.prop.propType" : "نوع السمة",
  "isales.taskresult.title.inputSessionId" : "أدخل معرف المكالمة الصادرة",
  "isales.task.promotion.pauseFaied" : "فشل تعليق مهمة تسويق الوسائط المتعددة",
  "isales.tasktype.field.auto.info" : "زر روبوت",
  "isales.calledroute.button.cancel" : "إلغاء",
  "isales.tasktype.field.intelligence" : "مكالمة خارجية ذكية",
  "isales.business.result.code" : "رمز نتيجة العمل",
  "isales.taskresultexport.message.downloadExportdatafailinfo" : "فشل التحقق. لا يمكن العثور على بيانات الملف المصدر",
  "isales.task.notFinished" : "غير مكتمل",
  "isales.taskmanagement.title.start" : "ابدأ",
  "isales.data.prop.createDataPropFail" : "فشل إنشاء السمة",
  "isales.datasourcemanagement.field.addDataSource" : "إضافة مصدر بيانات",
  "isales.taskstatistics.field.incomplete" : "العملاء الذين ينتظرون المكالمات",
  "isales.management.title.adjustment.orgAccountName" : "منفذ قديم",
  "isales.management.message.callNoInBlackList" : "لا يمكن الاتصال بالرقم لأنه مدرج في قائمة الحظر",
  "isales.callNo.field.type" : "نوع الرقم",
  "isales.tasktype.field.auto" : "زر روبوت مكالمة صادرة",
  "isales.task.promotion.messageTemplate" : "قالب الإعلام",
  "isales.tasklogic.field.substate" : "Substate",
  "isales.specialday.title.deleteSuccess" : "ناجح",
  "isales.agent.message.deleteSkillConfFail" : "فشل حذف معلومات قائمة انتظار المهارة",
  "isales.statistics.business.busiResult" : "نتائج الأعمال",
  "isales.operlog.message.data.downloadtemplate" : "تنزيل قالب استيراد البيانات",
  "isales.agent.button.batchmodify" : "تغيير الحزمة",
  "isales.management.message.deleteTaskstatusError" : "لا يمكن حذف مهمة المكالمة الصادرة لأنها ليست في حالة المسودة أو حالة الإيقاف المؤقت",
  "isales.task.promotion.selectSendTemplate" : "تحديد قالب",
  "isales.taskresult.field.failsCpp" : "يسمى إعادة التوجيه عن طريق ربط",
  "isales.taskresult.field.failsAns5" : "هاتف نصي (ANS5)",
  "isales.taskresult.field.failsAns4" : "هاتف نصي (ANS4)",
  "isales.taskresult.field.failsAns3" : "هاتف نصي (ANS3)",
  "isales.statistics.business.noMoreThanFive" : "لا يمكن أن يتجاوز عدد العناصر المحددة 5 عناصر",
  "isales.taskresult.field.failsAns2" : "نص الهاتف (ANS2)",
  "isales.taskresult.field.failsAns1" : "هاتف نصي (ANS1)",
  "isales.operlog.message.downloadSurveyExportFile" : "تصدير أسئلة التدريب",
  "isales.taskinfo.message.batchEditCallLogic.selectEmpty" : "العملية غير صالحة بسبب عدم تحديد أي منطقة. حدد منطقة",
  "isales.taskinfo.field.description" : "وصف المهمة",
  "isales.work.message.workNoTypeLimit" : "يجب أن تكون القيمة 0 أو عدد صحيح موجب",
  "isales.task.promotion.startSuccess" : "تم نشر مهمة تسويق الوسائط المتعددة بنجاح",
  "isales.datasourcemanagement.field.vdnId" : "VDNID",
  "isales.agent.message.modifyPwdFailed" : "فشل تغيير كلمة مرور الموظف",
  "isales.agent.message.queryCallCenterFail" : "فشل الاستعلام عن بيانات مركز المكالمات",
  "isales.taskmanagement.title.clicktorefresh" : "انقر لتحديث",
  "isales.taskstatistics.message.no.busiResult" : "لم يتم وضع علامة على أي نتيجة أعمال",
  "isales.blacklist.message.updateSuccess" : "تم تعديل الملاحظة بنجاح",
  "isales.data.filed.noResult" : "لا",
  "isales.management.title.callbot" : "Chatbot مكالمة صادرة",
  "isales.blacklist.message.paramEmpty" : "حدد رقمًا مدرجًا في قائمة الحظر",
  "isales.task.statistics.obs.endTime" : "لا يمكن أن يتجاوز الفرق بين وقت البدء ووقت الانتهاء نصف ساعة",
  "isales.taskmanagement.title.pauseCount" : "يمكن تعليق 30 مهمة مكالمة صادرة كحد أقصى في المرة الواحدة",
  "isales.task.promotion.saveFailed" : "فشل حفظ مهمة تسويق الوسائط المتعددة",
  "isales.taskpolicy.message.input.minAvgCallTime" : "لا يمكن أن يكون الحد الأدنى لمتوسط مدة المكالمة أكثر من الحد الأقصى. متوسط مدة المكالمة",
  "isales.manual.field.callflag.return" : "للخلف",
  "isales.operlog.message.data.downloadrepeatfile" : "تنزيل ملف بيانات مكررة",
  "isales.manual.message.release.failed" : "فشل تحرير بيانات المكالمة الصادرة",
  "isales.taskmanagement.field.zip.input.password" : "أدخل ثمانية أحرف على الأقل واثني عشر حرفًا كحد أقصى تحتوي على أرقام وأحرف وأحرف خاصة ولكنها لا تحتوي على /\\-#(){\'{\'}{\'}\'};&{\'|\'}<>{\'$\'}`\\\\!\\n\\s\\t\\r",
  "isales.management.message.black.download" : "تنزيل قالب قائمة الحظر",
  "isales.file.title.SK.again" : "تأكيد SK",
  "isales.taskInfo.message.skillAlreadyExists" : "قائمة انتظار المهارة موجودة مسبقاً",
  "isales.file.title.AK" : "AK",
  "isales.agent.label.maxAgentWrapupTime" : "تتراوح القيمة من 0 إلى 3600",
  "isales.notification.receiver.tips" : "يجب أن يكون المتلقي فريدًا",
  "isales.agent.addblocklist.calldatatype" : "نوع العميل",
  "isales.audio.title.asrTransResult" : "نتائج نسخ الكلام",
  "isales.taskpolicy.message.rangeAutoCallTime" : "يجب أن تتراوح القيمة من {0} إلى {1}",
  "isales.manual.field.result.success" : "ناجح",
  "isales.taskinfo.message.endTimeError" : "يجب أن يكون وقت الانتهاء بعد الوقت الحالي",
  "isales.work.message.agentOrTaskChange" : "تحديد مهمة اتصال وموظف واحد على الأقل",
  "isales.statistics.business.monthLessThan" : "لا يمكن أن يكون شهر نهاية السنة قبل شهر بداية السنة",
  "isales.task.promotion.selectSendGateway" : "تحديد بوابة",
  "isales.import.filter.button.readyCall" : "تصفية بيانات المكالمات التي لم تبدأ",
  "isales.taskmanagement.title.callCompletionRate" : "معدل اكتمال المكالمة",
  "isales.taskresult.field.failsAgentDisconnection" : "تم قطع اتصال الموظف",
  "isales.taskresult.field.failsInvalidDevice" : "جهاز غير صالح",
  "isales.taskmanagment.title.callMonitor.tips" : "تحديث تلقائي كل 15 دقيقة",
  "isales.repeatfile.export.repeatCalledNo" : "تكرارتم الاتصالNo",
  "isales.statistics.business.dategt6month" : "لا يمكن أن يتجاوز نطاق الشهر 6 أشهر",
  "isales.calldata.prop.filter" : "تصفية سمة بيانات المكالمة الصادرة",
  "isales.calltime.title.spec.datetime.period" : "فترة زمنية خاصة",
  "isales.button.confirm" : "تأكيد",
  "isales.callquality.statistics.message.afterNow" : "لا يمكنك تحديد وقت قبل وقت بدء المهمة أو بعد اليوم الحالي!",
  "isales.taskinfo.title.ivrFlow" : "Chatbot ذكي",
  "isales.appointment.title.appointTime" : "وقت المكالمة المحجوز",
  "isales.task.promotion.whatsApp.message.template.select" : "تحديد رسالة قالب WhatsApp",
  "isales.manual.message.release.success" : "تم إصدار بيانات المكالمة الصادرة بنجاح",
  "isales.taskcalllogic.message.choosePolicy" : "يرجى تحديد استراتيجية واحدة على الأقل لمعالجة النتائج",
  "isales.taskinfo.field.mediaAgentType" : "الوسائط المتعددة",
  "isales.business.result.message.add.tips" : "نتيجة العمل غير معرّفة",
  "isales.taskresult.field.failsCalledOnHook" : "خطافات الموظف على مكالمة",
  "isales.taskresult.field.sipAccount" : "رقم الهاتف",
  "isales.task.result.export.query" : "اذهب إلى عرض",
  "isales.data.prop.editDataPropFail" : "فشل تعديل السمة",
  "isales.taskinfo.field.callNo" : "رقم المتصل",
  "isales.notification.receiver" : "المتلقي",
  "isales.agent.button.restAgentPwd" : "تغيير كلمة مرور الموظف",
  "isales.work.title.totalOutgoingCalls" : "المكالمات الصادرة",
  "isales.management.message.SelectNumber" : "تحديد رقم",
  "isales.datasourcemanagement.message.noObsINFO" : "لم يتم تكوين مصدر بيانات المكالمة الصادرة أو تم تكوينه بشكل غير صحيح",
  "isales.datasourcemanagement.field.dsType.dm" : "DM",
  "isales.taskresult.field.failsFsk" : "الهاتف النصي (FSK)",
  "isales.blacklist.message.deleteSuccess" : "تم حذف الرقم بنجاح من قائمة الحظر",
  "isales.manual.task.survey.previousquestion" : "السؤال السابق",
  "isales.taskinfo.message.agent" : "معلومات الموظف غير طبيعية. التحقق مما إذا كان هناك عملاء غير قائمة انتظار المهارة الحالية",
  "isales.agent.label.answerType" : "وضع الرد على الموظف",
  "isales.management.title.adjustment.remark" : "ملاحظات",
  "isales.import.message.closeExtProp" : "يجب تعطيل السمات المخصصة للاستيراد حسب القالب. انقر فوق الزر \"تكوين السمة\" الموجود بجوار \"استيراد\" لتعطيل وتمكين السمات المخصصة",
  "isales.taskresult.field.failsModemdetected" : "تم الكشف عن مودم",
  "isales.taskresult.field.failedtopause" : "لا يمكن إيقاف المهمة مؤقتًا لأنها بالفعل في حالة الإيقاف المؤقت",
  "isales.task.message.startFail" : "فشل بدء مهمة المكالمة الصادرة",
  "isales.tasktemplate.message.releasesuccess" : "تم النشر بنجاح",
  "isales.taskstatistics.field.totalNumberOfUsers" : "إجمالي عدد المستخدمين",
  "isales.tasklogic.field.default" : "افتراضي",
  "isales.taskmanagement.field.outboundType" : "نوع الاتصال",
  "isales.agent.placeholder.agentdesc" : "أدخل اسم موظف",
  "isales.audio.content.calltype" : "مكالمة صوتية صادرة",
  "isales.result.outgoingCall.failCode" : "رمز النتيجة",
  "isales.training.callTime" : "مقطع وقت المكالمة",
  "isales.taskmanagement.title.startCount" : "يمكن بدء 30 مهمة مكالمات صادرة كحد أقصى في المرة الواحدة",
  "isales.import.filter.button.notification.isPushCom" : "تصفية البيانات التي يتم دفعها",
  "isales.task.promotion.remark" : "سبب الفشل",
  "isales.agent.calledroute.createsuccess" : "تمت الإضافة بنجاح",
  "isales.taskcalllogic.busiresultpolicy.callnext" : "اتصل بالرقم التالي",
  "isales.agent.message.warning" : "تحذير",
  "isales.import.title.statusExecuting" : "التنفيذ",
  "isales.agent.message.modifySkillConfSuccess" : "تم تعديل قائمة انتظار المهارة بنجاح",
  "isales.taskcalllogic.field.other" : "أخرى",
  "isales.taskresultexport.title.downloadConfirm" : "حسناً",
  "isales.task.title.basicInfo" : "معلومات أساسية",
  "isales.rbtresult.field.emptynumber" : "رقم فارغ",
  "isales.task.field.callFail" : "فشل",
  "isales.blacklist.title.restrictTimes" : "الحد الأقصى لعدد المكالمات",
  "isales.taskresult.title.chooseCallFlag" : "يرجى اختيار الحالة",
  "isales.agent.message.redislocknull" : "فشل الحصول على قفل Redis",
  "isales.file.message.isDeleteFile" : "حذف ملف المصدر",
  "isales.taskstatistics.field.calling" : "الاتصال",
  "isales.calledroute.title.selectskill" : "تحديد قائمة انتظار المهارات",
  "isales.rbtresult.field.tollcaller" : "قيود المكالمات الدولية",
  "isales.manual.title.fail.reason" : "سبب الفشل",
  "isales.specialday.field.byYear" : "كرر حسب السنة",
  "isales.taskresult.field.failsDisconnection" : "فشل المكالمة",
  "isales.management.message.calledNoTwoCategory" : "فئة الرقم المطلوب الثاني",
  "isales.task.promotion.promotionStatus" : "حالة التسويق",
  "isales.taskcalllogic.field.shutdown" : "إيقاف العميل",
  "isales.specialday.title.holidayType" : "نوع التاريخ الخاص",
  "isales.taskcalllogic.busiresultpolicy.stopcall" : "إيقاف المكالمة",
  "isales.taskmanagement.message.password.not.same" : "كلمات المرور غير متناسقة",
  "isales.failfile.export.failReason" : "سبب الفشل",
  "isales.tasktemplate.message.pauseFail" : "فشل التعليق",
  "isales.task.promotion.retryTimes" : "مرات إعادة المحاولة",
  "isales.taskmanagement.title.batchEditCallLogic" : "تعديل دفعة منطق المكالمات الصادرة",
  "isales.management.message.import.fileName" : "اسم الملف",
  "isales.file.message.deleteruletips" : "هل تريد بالتأكيد حذف قاعدة تعيين الاستيراد؟",
  "isales.tasklogic.field.callOrderMethod.orderType" : "فرز حسب",
  "isales.failInterval.check" : "قد تكون هناك مخاطر عندما يكون الفاصل الزمني أقل من 10S",
  "isales.appointment.message.tasknotrun" : "لم تبدأ مهمة المكالمة الصادرة الحالية. المكالمة ممنوعة",
  "isales.taskpolicy.field.utilRateCalculateTwo" : "استخدام الموظف = (إجمالي مدة المكالمة في حالة العمل)/إجمالي مدة تسجيل الدخول إلى العميل",
  "isales.timeImport.message.fail" : "فشل إضافة تكوين الاستيراد المجدول",
  "isales.agent.message.deleteSkill" : "حذف قائمة انتظار المهارة",
  "isales.management.title.notification.import" : "استيراد بيانات التسويق",
  "isales.statistics.business.subBusiResult" : "نتيجة الأعمال الفرعية",
  "isales.specialday.field.beginDate" : "تاريخ البدء",
  "isales.task.message.timeIncompletes" : "لم يكتمل مقطع الوقت لمكالمة صادرة واحدة",
  "isales.taskresult.field.failsDtmf" : "هاتف نصي (DTMF)",
  "isales.taskresultexport.message.endtime" : "وقت الانتهاء",
  "isales.taskcalllogic.message.resultTips" : "لا يمكن أن يكون هناك سوى استراتيجية واحدة لنتيجة واحدة",
  "isales.tasklogic.field.callbyorder" : "في تسلسل",
  "isales.agent.message.updateCtiAndDbFail" : "فشل التحديث",
  "isales.datasourcemanagement.field.dbdriver" : "مصدر البيانات مدفوعة",
  "isales.taskresult.field.failsUserhangsupinthequeue" : "كافة الوكلاء مشغولون، ويقوم المستخدم بإيقاف التشغيل بشكل استباقي",
  "isales.taskmanagement.message.afterNow" : "لا يمكن أن يكون وقت البدء بعد الوقت الحالي",
  "isales.data.prop.message.confirmDelete" : "هل تريد بالتأكيد حذف السمة المحددة؟",
  "isales.task.finished" : "اكتملت",
  "isales.taskpolicy.field.multiSkill" : "قائمة انتظار المهارات المختلطة",
  "isales.taskresult.field.failsWaitresulttimesout" : "انتهت مهلة انتظار نتائج المكالمات الصادرة على النظام الأساسي",
  "isales.training.surveydetails" : "تفاصيل إحصائيات الاستبيان",
  "isales.taskmanagement.title.edit" : "تحرير",
  "isales.specialday.title.search" : "استعلام",
  "isales.task.status.running" : "قيد التقدم",
  "isales.tasklogic.field.callOrderMethod.byData" : "حسب سمة البيانات",
  "isales.taskresult.field.failsSubscriberbusy" : "الطرف المطلوب مشغول",
  "isales.survey.createTrainingTask" : "إنشاء مهمة تدريبية",
  "isales.task.promotion.view" : "عرض مهمة تسويق الوسائط المتعددة",
  "isales.taskcalllogic.field.callFailed" : "فشل الاتصال",
  "isales.management.message.orderReverse" : "ترتيب تنازلي",
  "isales.file.message.FilePathTips" : "هذه المعلمة اختيارية. إذا تم تخزين ملف المكالمة الصادرة في المسار الافتراضي\، تكون هذه المعلمة اختيارية. في حالة تخزين ملف المكالمات الصادرة في المجلد في المسار الافتراضي\، يجب تعيين هذه المعلمة",
  "isales.specialday.placeholder.searchname" : "أدخل اسم تاريخ خاص",
  "isales.taskresult.field.yes" : "نعم",
  "isales.outboundtime.title.period" : "مقطع وقت المكالمة الصادرة",
  "isales.outboundBusiness.statistic.di" : "أسبوع",
  "isales.taskmanagment.title.callMonitor" : "مراقبة المكالمات",
  "isales.training.answeringRecord" : "سجل الرد",
  "isales.blacklist.message.requestEmpty" : "فشل التحقق من صحة المعلمة الخاصة بإضافة الرقم إلى قائمة الحظر",
  "isales.taskmanagment.callquality.maxConnectedRate" : "الحد الأقصى لمعدل الاتصال",
  "isales.task.message.pauseFail" : "فشل تعليق مهمة المكالمة الصادرة",
  "isales.taskresultexport.title.download" : "تحميل",
  "isales.task.field.callFlagExecuted" : "ليتم إعدامه",
  "isales.data.prop.createDataPropSuccess" : "تم إنشاء السمة بنجاح",
  "isales.file.title.rule.number" : "عمود الأرقام",
  "isales.taskresult.field.failsAcdtimeout" : "انتهت مهلة ACD",
  "isales.taskinfo.title.skillName" : "قائمة انتظار المهارات",
  "isales.label.appraise.call.quality.agent" : "وكيل",
  "isales.specialday.message.deleteSuccess" : "تم حذف التاريخ الخاص بنجاح",
  "isales.manualdata.title.release" : "الافراج",
  "isales.work.title.surveyAnswerNum" : "عدد الإجابات",
  "isales.blacklist.title.day" : "كل يوم",
  "isales.statistics.business.cancel" : "إلغاء",
  "isales.audio.title.beginTime" : "وقت بدء التسجيل",
  "isales.agent.calledroute.nmsdata.error" : "فشل مزامنة ODFS، وبيانات التكوين للطرف المطلوب ODFS غير متناسقة مع البيانات المحلية لـ CCM",
  "isales.taskstatus.field.waiting" : "الإنتظار",
  "isales.task.message.saveFail" : "فشل تكوين مهمة المكالمة الصادرة",
  "isales.taskmanagement.exportResult" : "تصدير نتيجة المهمة",
  "isales.exportdata.title.fail" : "فشل",
  "isales.tasklogic.field.enablebusiresultpolicy" : "تمكين سياسة نتائج الأعمال",
  "isales.taskresult.field.failsNoresponse" : "الطرف المطلوب لا يستجيب",
  "isales.statistics.field.addHisYes" : "نعم",
  "ccsurvey.label.scenarioType.manual" : "الاستبيان اليدوي",
  "isales.file.title.editrule" : "تعديل القاعدة",
  "isales.language.label.es_ES" : "الأسبانية",
  "isales.failfile.export.failline" : "خط الفشل",
  "isales.taskinfo.message.agentsEmpty" : "لم يتم تحديد أي موظف",
  "isales.blacklist.title.remark" : "ملاحظات",
  "isales.manual.message.error" : "حدث خطأ عند تحديث البيانات",
  "isales.label.calldata.order" : "تسلسل المكالمة",
  "isales.tasklogic.title.addCallnoPolicy" : "إضافة نهج النتيجة",
  "isales.management.message.deleteFailed" : "فشل حذف بيانات المكالمة الصادرة",
  "isales.manual.title.viewPreviewResult" : "معاينة نتيجة المكالمة الصادرة-",
  "isales.basicinfo.title.chooseCallNo" : "تحديد الرقم المتصل",
  "isales.common.title.cancel" : "إلغاء",
  "isales.speclistmanage.title.reason" : "السبب",
  "isales.calledroute.title.selectagent" : "تحديد موظف",
  "isales.agent.label.description" : "الوصف",
  "isales.business.description" : "الوصف",
  "isales.management.title.editPolicy" : "تعديل النهج",
  "isales.tasklogic.message.busiresultpolicyNoadded" : "لم تتم إضافة سياسة نتائج الأعمال",
  "isales.notification.configVariableTe" : "تكوين متغير القالب",
  "isales.taskmanagement.title.addManual" : "إنشاء مكالمة موظف صادرة",
  "isales.taskresult.field.failsUnallocatedOrIncorrectNumber" : "رقم غير قابل للوصول أو غير صحيح",
  "isales.manual.field.callflag.finish" : "اكتملت",
  "isales.taskmanagement.title.batchEditTaskInfo" : "دفعة تعديل المعلومات الأساسية",
  "isales.taskcalllogic.field.callTimeout" : "انتهت مهلة المكالمة",
  "isales.data.title.importData" : "استيراد",
  "isales.tasklogic.field.busiresultpolicy" : "سياسة نتائج الأعمال",
  "isales.statistics.business.error" : "أخطاء",
  "isales.management.message.deleteCount" : "يمكن حذف 100 سجل مكالمات صادرة كحد أقصى في المرة الواحدة",
  "isales.callNo.field.add" : "إضافة",
  "isales.skillcreate.maxnumber.failed" : "لا يمكن أن يتجاوز عدد قوائم انتظار المهارات المستأجرة الحد الأعلى",
  "isales.business.result.deleteChildrenTips" : "هل تريد بالتأكيد حذف نتيجة العمل الفرعي؟",
  "isales.busi.result.item.uncertain" : "غير مؤكد",
  "isales.task.title.nextStep" : "التالي",
  "isales.task.promotion.sender" : "المرسل",
  "isales.agent.label.parameterType" : "وضع قائمة انتظار",
  "isales.blacklist.message.name.reg.empty" : "القاعدة العادية للاسم فارغة. اتصل بالمسؤول لإضافته",
  "isales.taskmanagement.field.taskTime" : "المدة",
  "isales.file.title.password.again" : "تأكيد كلمة المرور",
  "isales.file.title.rightpath" : "أدخل مسارًا صالحًا، على سبيل المثال، /isales/upload",
  "isales.training.tip2" : "يتم تدريب السؤال. لا تنقر بشكل متكرر",
  "isales.training.tip1" : "وصل عدد الإجابات إلى الحد الأعلى",
  "isales.management.placeholder.accesscodeDesc" : "يُرجى إدخال وصف رمز الوصول",
  "isales.taskresult.field.userisunleashed" : "تم تحرير المكالمة على خادم الوسائط الذي يقوم الموظف بتسجيل الدخول إليه",
  "isales.rbtresult.field.callershutdown" : "تعليق متأخرات المتصل / تعليق في اتجاه واحد",
  "isales.agent.addblocklist.numbertype" : "نوع الرقم",
  "isales.agent.calledroute.search.devicedesc" : "الرجاء إدخال اسم الجهاز",
  "isales.file.server.message.notChinese" : "الحروف الصينية غير مسموح بها",
  "isales.taskresult.field.failsNoreason" : "نقل DTE غير مشروط",
  "isales.training.queryQuestionnaire" : "استعلام عن الاستبيان",
  "isales.calledroute.message.selectcalledroute" : "الرجاء تحديد التكوين المطلوب",
  "isales.management.title.selectProp" : "تحديد سمة",
  "isales.taskmanagement.title.pauseTask.askConfirm" : "هل تريد بالتأكيد تعليق مهمة المكالمة الصادرة؟ إذا تم إيقاف المهمة مؤقتًا إجباريًا، فقد تتأثر المكالمات الجارية",
  "isales.manualData.message.pickUpFailed" : "لا توجد بيانات لاستخراجها",
  "isales.specialday.title.speclistmanage.misjudgment" : "خطأ في الحكم",
  "isales.taskcalllogic.message.callNoPolicyOutOfRangeAfetUpdate" : "بعد تغيير عدد نُهج الأرقام غير الافتراضية في نهج نتيجة المكالمة الصادرة\، يتم تجاوز الحد الأقصى البالغ خمسة نُهج",
  "isales.taskresult.field.failsUnreachable" : "مشترك الهاتف المحمول لا يمكن الوصول إليه",
  "isales.taskmanagement.title.callRecord" : "سجل المكالمات الصادرة",
  "isales.outboundtime.field.hour" : "ساعة",
  "isales.tasktype.field.automatic" : "مكالمة صادرة تلقائية",
  "isales.taskinfo.message.batchEditCallLogic.BusiResultNotification" : "ستحل السياسات الجديدة محل السياسات القائمة",
  "isales.taskpolicy.field.failInterval" : "الفاصل الزمني  للمكالمة الصادرة",
  "isales.agent.calledroute.createfail" : "فشل إضافة البيانات المحلية",
  "isales.taskcalllogic.field.globalPolicy" : "السياسة العالمية",
  "isales.taskmanagement.title.tipsBatchStartTask" : "تحديد المهمة المراد بدء تشغيلها",
  "isales.notification.config" : "التكوين",
  "isales.specialday.title.edit" : "تحرير",
  "isales.taskresultexport.message.downloadFail" : "فشل التنزيل",
  "isales.taskInfo.title.quotasTips" : "عندما يتجاوز عدد المكالمات التي تم وضع علامة عليها من قبل الموظف كنتيجة للخدمة السابقة قيمة هذه المعلمة، لا يقوم النظام باسترداد المكالمات تلقائيًا",
  "isales.file.message.isDeleteFileTips" : "حذف الملفات من OBS",
  "isales.agent.label.agentList" : "معرف الموظف",
  "isales.taskcalllogic.busiresultpolicy.callcurrent" : "اتصل بالرقم الحالي",
  "isales.task.promotion.createTime" : "تم الإنشاء في",
  "isales.result.create" : "سبب فشل إنشاء مكالمة صادرة",
  "isales.label.appraise.call.quality.customerInfo" : "معلومات العميل",
  "isales.appointment.message.adjustmentSuccess" : "تم ضبط المكالمة الصادرة المحجوزة بنجاح",
  "isales.note.config.success" : "نجاح",
  "isales.taskInfo.title.filterCondition" : "شرط",
  "isales.manual.task.survey.answered" : "تم الرد",
  "isales.management.message.deleteSuccess" : "تم حذف بيانات المكالمة الصادرة بنجاح",
  "isales.agent.calledroute.accesscodewarn" : "يمكن أن تحتوي رموز الوصول على أرقام فقط",
  "isales.management.message.calledNo" : "الرقم المطلوب",
  "isales.management.message.sessionId" : "رقم الجلسة",
  "isales.agent.query.emptyError" : "لم يتم تحديد بيانات موظف صالحة",
  "isales.management.title.editTime" : "تغيير الوقت",
  "isales.skill.label.maxwaitnum" : "الحد الأقصى للمكالمات في قائمة الانتظار",
  "isales.data.propConfig.message.isExistBackDb" : "مصدر بيانات بديل",
  "isales.callNo.field.delayTime" : "وقت (وقتات) تأخير فشل المكالمة",
  "isales.common.title.fail" : "فشل",
  "isales.specialday.title.timePeriod" : "تاريخ خاص وقت المكالمة الصادرة",
  "isales.taskresult.field.failsCallwaiting" : "انتظار المكالمات",
  "isales.training.voiceAverageScore" : "متوسط نقاط الصوت",
  "isales.blacklist.placeholder.searchname" : "الاسم",
  "isales.blacklist.title.hour" : "كل ساعة",
  "isales.tasktemplate.title.saveAsTemplate" : "حفظ كقالب",
  "isales.tasktemplate.title.addAutoTemplate" : "إنشاء قالب مكالمة صادرة تلقائيًا",
  "isales.taskmanagement.title.inputTaskId" : "أدخل معرف مهمة",
  "isales.result.deletetips" : "هل تريد بالتأكيد حذف سبب فشل المكالمة الصادرة؟",
  "isales.taskmanagement.title.selectTemplate" : "تحديد قالب",
  "isales.blacklist.message.calledno.error" : "يحتوي الرقم على أحرف غير صالحة",
  "isales.management.message.upload" : "تحميل",
  "isales.taskmanagement.field.prediction" : "مكالمة صادرة متوقعة",
  "isales.operlog.message.downloadAgentWorkExportFile" : "تنزيل ملف تصدير حمل عمل الموظف",
  "isales.taskresultexport.message.downloadFilesuffixfailinfo" : "فشل التنزيل. لا تحتوي معلومات الملف المصدر على ملحق اسم الملف",
  "isales.data.prop.propCode" : "رمز السمة",
  "isales.skillqueue.button.add" : "إضافة قائمة انتظار مهارة",
  "isales.specialday.message.typeError" : "نوع تاريخ خاص غير صحيح",
  "isales.data.prop.stringType.countError" : "يمكن تعريف سمات من نوع السلسلة بحد أقصى 30 حرفًا",
  "isales.statistics.business.endDate" : "تاريخ الانتهاء",
  "isales.taskresult.title.endTime" : "وقت الانتهاء",
  "isales.specialday.message.updateFail" : "فشل تعديل التاريخ الخاص",
  "isales.manual.task.survey.nextquestion" : "السؤال التالي",
  "isales.task.promotion.templateType" : "نوع القالب",
  "isales.tasklogic.message.need.orderPolicy" : "إضافة قاعدة ترتيب مخصصة واحدة على الأقل",
  "isales.taskmanagment.title.open.callMonitor" : "مراقبة المكالمات",
  "isales.manual.message.call.error" : "حدث خطأ أثناء معالجة نتيجة المكالمة الصادرة",
  "isales.taskinfo.field.agentType99" : "متعددة الاستخدامات",
  "isales.taskinfo.field.versatileAgentType" : "متعددة الاستخدامات",
  "isales.taskresult.field.failsCallednumbernotinwhitelist" : "الرقم غير موجود في قائمة موثوقية المكالمات الصادرة",
  "isales.specialday.title.reset" : "إعادة الضبط",
  "isales.taskstatus.field.confirming" : "سيتم تأكيده",
  "isales.specialday.message.dateParseError" : "خطأ في تحويل نوع الوقت من التاريخ الخاص",
  "isales.manual.task.survey.answerSuccess" : "تم الرد بنجاح!",
  "isales.management.message.calledNoEmpty" : "الرقم المطلوب فارغ",
  "isales.common.title.close" : "إغلاق",
  "isales.import.title.statusReady" : "ليتم إعدامه",
  "isales.taskresult.field.failsNoacdresource" : "لا يوجد مورد متاح على ACD",
  "isales.management.message.createFailed" : "فشل إنشاء بيانات مكالمة صادرة",
  "isales.busi.result.message.recordsucc" : "تم تسجيل نتيجة العمل بنجاح",
  "isales.file.title.create" : "إضافة",
  "isales.taskmanagement.title.deleteFailExitOne" : "تكوين رقم اتصال واحد على الأقل لمهمة المكالمة الصادرة",
  "isales.blacklist.title.restrictPeriod" : "فترة التقييد",
  "isales.agent.message.modifyPwdSuccess" : "تم تغيير كلمة مرور الموظف بنجاح",
  "isales.taskpolicy.field.pickupRateMethod" : "خوارزمية لمعدل Offhook",
  "isales.datasourcemanagement.message.syncSuccess" : "تمت مزامنة مصدر البيانات بنجاح",
  "isales.import.message.uploadedfileNameformatvalidate" : "اسم الملف غير صحيح. حدد ملفًا يحتوي اسمه على أحرف صينية فقط\، أو أحرف صغيرة\، أو أرقام\، أو تسطير سفلي (_)",
  "isales.agent.calledroute.isconfdelete" : "هل أنت متأكد من رغبتك في الحذف؟",
  "isales.taskmanagement.title.batchEditTaskInfo.manual" : "لا يمكن تعديل المكالمة الصادرة التلقائية والمكالمة الصادرة اليدوية في نفس الوقت",
  "isales.taskresult.title.busidesc" : "وصف الأعمال",
  "isales.agent.calledroute.placeholder.skill" : "الرجاء تحديد قائمة انتظار مهارة",
  "isales.taskpolicy.field.obOverTime" : "ماكس. مدة الاستجابة ",
  "isales.tasktype.field.intelligent" : "روبوت ذكي مكالمة صادرة",
  "isales.task.promotion.numberOnly" : "يُسمح فقط بالأعداد الصحيحة غير السالبة",
  "isales.management.message.import.analyseMode" : "وضع التحليل",
  "isales.agent.calledroute.gateway.fail" : "فشلت مزامنة البيانات مع النظام الأساسي CTI. التحقق من تكوين البوابة",
  "isales.management.message.ruleAnalyse" : "حسب القاعدة",
  "isales.taskmanagement.field.statisticsDate" : "تاريخ جمع الإحصائيات",
  "isales.taskinfo.title.chooseSurvey" : "تحديد استبيان",
  "isales.file.message.rule.dataIsNull" : "لا يمكن أن تكون بيانات الرقم فارغة",
  "isales.statistics.business.trend.title" : "تقرير اتجاهات الأعمال للمكالمة الصادرة",
  "isales.management.message.addPropTips" : "يجب تشفير البيانات الشخصية أو البيانات الحساسة وتخزينها وإخفاءها",
  "isales.appointment.message.agentNotCalling" : "لا يمكن إضافة مكالمة صادرة محجوزة لأن الموظف ليس في مكالمة",
  "isales.taskmanagement.title.tipsBatchPauseTask" : "تحديد المهمة المراد تعليقها",
  "isales.manual.message.empty" : "{0} فارغ",
  "isales.training.MaxAnswerNumber" : "الحد الأقصى لعدد الإجابات على الاستبيان",
  "isales.specialday.message.dateFormatError" : "تنسيق تاريخ خاص غير صحيح",
  "isales.file.message.endPoint.placeholder" : "أدخل عنواناً",
  "isales.appointment.title.failCode" : "سبب الفشل",
  "isales.datasourcemanagement.field.dsType.gauss" : "GAUSS",
  "isales.taskresult.field.failsCalldatatimeout" : "انتهت مهلة بيانات المكالمة",
  "isales.taskpolicy.field.utilRateMethod" : "خوارزمية لاستخدام الوكيل",
  "isales.taskpolicy.field.experienceMethod" : "خوارزمية التنبؤ التجريبي",
  "isales.agent.calledroute.create.gateway.none" : "البرنامج النصي للتوجيه المشار إليه بواسطة التكوين المطلوب غير موجود",
  "isales.taskinfo.field.questionNumber" : "عدد الأسئلة",
  "isales.task.info" : "معلومات المهمة",
  "isales.blacklist.title.calledNo" : "الرقم المطلوب",
  "isales.business.result.addchildrentips" : "يُسمح بحد أقصى 50 نتيجة أعمال فرعية في نتيجة أعمال",
  "isales.specialday.title.timeSet" : "تعيين وقت المكالمة الصادرة بتاريخ خاص",
  "isales.sendStatus.field.all" : "الكل",
  "isales.taskstatus.field.callfinish" : "اكتملت",
  "isales.taskmanagment.title.viewstatistics" : "عرض الإحصائيات",
  "isales.audio.title.endtime" : "وقت الانتهاء",
  "isales.appointment.message.callTimeTooEarly" : "وقت المكالمة سابق للوقت المحجوز. المكالمة ممنوعة!",
  "isales.agent.message.deleteSkillConfSuccess" : "تم حذف قائمة انتظار المهارة بنجاح",
  "isales.agent.label.answerMode" : "وضع الرد على الموظف",
  "isales.import.message.pleaseuploadfile" : "تحديد ملف",
  "isales.tasklogic.message.busiresultpolicyExists" : "توجد بالفعل سياسة نتائج الأعمال بنفس نتائج الأعمال والحالة الفرعية",
  "isales.management.message.filterYes" : "نعم",
  "isales.taskresult.field.agentRole" : "اسم دور الموظف",
  "isales.blacklist.message.name.error" : "يحتوي الاسم على أحرف غير صالحة",
  "isales.taskmanagement.title.editTime" : "تغيير الوقت",
  "isales.taskresult.field.other" : "أخرى",
  "isales.taskresult.title.export" : "تصدير",
  "isales.import.filter.button.notification.noisPush" : "تصفية البيانات لم يتم دفعها",
  "isales.taskstatus.field.confirmed" : "تم التأكيد",
  "isales.black.filed.expTime" : "مدة الصلاحية (اختياري، بالساعات)",
  "isales.task.promotion.message.dndTimeError" : "فترة عدم الإزعاج غير مكتملة",
  "isales.manualdata.title.lock" : "قفل",
  "isales.data.message.empty" : "لا يمكن أن يكون الرقم المطلوب فارغًا!",
  "isales.datasourcemanagement.field.backDbCnUrl" : "اتصال مصدر بيانات بديل",
  "isales.business.code" : "الكود",
  "isales.specialday.title.scoreDetail" : "تفاصيل النتيجة",
  "isales.workbench.appointment.task" : "مهمة الحجز",
  "isales.manualdata.title.pickUp" : "استخراج",
  "isales.taskinfo.message.batchEditCallLogic.differentRoundTypeExist" : "لا يمكن تعديل منطق المكالمات الصادرة على دفعات للمهام ذات أوضاع جولة مكالمة مختلفة",
  "isales.management.title.updateData" : "تعديل بيانات المكالمة الصادرة",
  "isales.taskresult.field.operation" : "العملية",
  "isales.import.title.statusComplete" : "اكتملت",
  "isales.tasklogic.field.callOrderMethod" : "قاعدة النظام",
  "isales.result.edit" : "تعديل سبب فشل المكالمة الصادرة",
  "isales.taskmanagement.field.zip.password" : "كلمة مرور الحزمة المضغوطة",
  "isales.tasktemplate.title.viewTemplate" : "عرض قالب مكالمة صادرة",
  "isales.statistics.field.callCompletionRateDescription" : "معدل الاتصال",
  "isales.specialday.title.warning" : "تحذير",
  "isales.taskcalllogic.message.roundPolicyLengthTooLong" : "بعد تغيير عدد الجولات في نهج الاتصال\، يتم تجاوز الحد الأقصى للطول",
  "isales.task.promotion.whatsApp.message.template" : "رسالة القالب",
  "isales.appointment.message.updateFail" : "فشل تعديل المكالمة الصادرة المحجوزة",
  "isales.specialday.title.chooseDate" : "تحديد التاريخ",
  "isales.statistics.business.startWeek" : "أسبوع البدء",
  "isales.taskmanagement.title.pause" : "تعليق",
  "isales.blacklist.title.createblacklist" : "إضافة رقم إلى قائمة الحظر",
  "isales.taskresult.field.failsFak" : "الطرف المطلوب هو فاكس",
  "isales.import.filter.button.notification.isPush" : "تصفية البيانات التي تم دفعها مؤخرًا",
  "isales.notification.configVariable" : "تعيين متغير",
  "isales.taskresult.field.failsRecordswrittenback" : "تتم إعادة كتابة السجلات الفاشلة عند خروج OBS (أو عند حذف مهمة)",
  "isales.tasktype.field.preview" : "معاينة مكالمة صادرة",
  "isales.taskcalllogic.field.customerRefused" : "رفض العميل",
  "isales.import.message.success" : "جاري استيراد البيانات...الرجاء النقر فوق \"تحديث\" لاحقاً للحصول على أحدث البيانات.",
  "isales.management.placeholder.commonIvrName" : "أدخل اسم عملية keybot",
  "isales.manual.task.survey.submit" : "إرسال",
  "isales.taskinfo.field.priority" : "الأولوية",
  "isales.agent.message.addSkillFail" : "فشل في إضافة قائمة انتظار المهارة",
  "isales.manual.task.survey.unanswered" : "بلا إجابة",
  "isales.exportdata.title.success" : "ناجح",
  "isales.tasktemplate.field.released" : "تم النشر",
  "isales.basicinfo.title.addCallNo" : "إضافة رقم الطالب",
  "isales.taskmanagement.field.pickUpCondition" : "الشروط",
  "isales.notification.receiver.number.tips" : "يجب أن تتكون القيمة من أرقام",
  "isales.blacklist.message.calledno.empty" : "لم يتم إدخال أي رقم",
  "isales.data.prop.inputPropName" : "أدخل اسم سمة",
  "isales.agent.label.maxWaitTime" : "ماكس. وقت الانتظار",
  "isales.business.result.message.specialStr" : "غير مدعوم",
  "isales.management.message.calledNoOneInterval" : "الفاصل الزمني الأول للرقم المطلوب",
  "isales.file.title.filepath.placeholder" : "أدخل مسار ملف\, على سبيل المثال\, /home",
  "isales.management.message.importOrder" : "تسلسل الاستيراد",
  "isales.tasktemplate.message.deleteConfirm" : "هل تريد بالتأكيد حذف قالب المكالمة الصادرة المحدد؟",
  "isales.taskresult.field.failsAutomaticansweringmachine" : "الطرف المطلوب هو جهاز الرد التلقائي",
  "isales.result.obResultIsNull" : "لا يمكن أن تكون نتيجة المكالمة الصادرة لسبب الفشل فارغة",
  "isales.taskresult.field.failsTasknotexist" : "لا توجد مهمة مكالمة صادرة متاحة",
  "isales.taskresult.field.failsTransfernoreply" : "تتم إعادة توجيه المكالمة في حالة عدم الرد",
  "isales.blacklist.message.createFail" : "فشل إضافة الرقم إلى قائمة الحظر",
  "isales.statistics.business.taskId" : "معرّف المهمة",
  "isales.workbench.number.execution" : "تنفيذ",
  "isales.taskInfo.message.saveSuccess" : "تم تعديل المعلومات بنجاح",
  "isales.tasklogic.field.addDataType" : "إضافة DataType",
  "isales.agent.message.createSkillConfSuccess" : "تم إنشاء قائمة انتظار المهارة بنجاح",
  "isales.outboundtime.message.empty" : "لم يتم تعيين وقت المكالمة الصادرة",
  "isales.tasklogic.message.busiresultpolicySame" : "نتائج الأعمال والأوضاع الفرعية لسياسات نتائج الأعمال المتعددة هي نفسها",
  "isales.management.message.uploadSelect" : "تحديد ملف",
  "isales.task.message.numberSuccessful" : "سجلات النجاح",
  "isales.blacklist.message.timeserror" : "أوقات الحد غير صحيحة",
  "isales.blacklist.message.perioderror" : "فترة تقييد غير صحيحة",
  "isales.taskinfo.call.header.policy" : "استراتيجية الاتصال",
  "isales.file.title.rule.type" : "نوع العمود",
  "isales.common.title.confirm" : "حسناً",
  "isales.appointment.message.createSuccess" : "تم إنشاء المكالمة الصادرة المحجوزة بنجاح",
  "isales.taskinfo.field.agentType11" : "فيديو",
  "isales.taskmanagement.title.createDirectly" : "إنشاء مباشرة",
  "isales.tasktype.field.intelligent.robot.create" : "إنشاء روبوت ذكي جديد",
  "isales.taskresult.field.failsPhonenumberisnull" : "الرقم الصادر فارغ",
  "isales.calltime.title.default.datetime" : "الفترة الزمنية العادية للتاريخ",
  "isales.taskmanagement.field.usersaying" : "نص الطلب",
  "isales.task.promotion.nameRepeat" : "اسم مهمة تسويق الوسائط المتعددة موجود بالفعل",
  "isales.agent.label.transferType" : "وضع المعالجة",
  "isales.task.promotion.sendTime" : "تم الإرسال",
  "isales.agent.work.statistics.AgentName" : "اسم الموظف",
  "isales.manual.message.release.DataNotWaitExists" : "بيانات المكالمة الصادرة التي يتم استدعاؤها أو اكتمالها موجودة",
  "isales.task.promotion.whatsApp.message.id" : "معرف رسالة القالب",
  "isales.taskpolicy.field.pickupRate" : "Offhook معدل",
  "isales.business.result.message.checkCode" : "يُسمح فقط بالأرقام \، والحروف \، والواصلات (-)",
  "isales.task.promotion.sms" : "SMS",
  "isales.skill.label.skillname" : "اسم قائمة انتظار المهارة",
  "isales.task.promotion.createEndTime" : "وقت انتهاء الإنشاء",
  "isales.taskresult.field.failsInsufficientresources" : "عدم كفاية الموارد",
  "isales.appointmanagement.title.inputRemark" : "أدخل الملاحظات",
  "isales.result.search.displayResult" : "أدخل سبب فشل المكالمة الصادرة",
  "isales.appointment.title.timeout" : "وقت الحجز قد انقضى",
  "isales.statistics.business.noData" : "لا توجد بيانات متاحة",
  "isales.tasklogic.field.exist.numbertype" : "نوع الرقم الموجود",
  "isales.import.filter.message.selectRuleTips" : "تحديد قاعدة مرشح واحدة على الأقل",
  "isales.manual.title.recipient" : "المتلقي",
  "isales.tasklogic.field.or" : "أو",
  "isales.blacklist.title.operateAgent" : "تمت إضافة وكيل",
  "isales.datasourcemanagement.message.deleteDataSourceSuccess" : "تم حذف مصدر البيانات بنجاح",
  "isales.notification.receiver.info" : "معلومات المستلم",
  "isales.rbtresult.field.interbusy" : "الشبكة مشغولة",
  "isales.taskstatistics.field.callResultStatistics" : "إحصائيات نتيجة المكالمة",
  "isales.taskresult.field.failsNumberreacheslimit" : "يصل عدد مكالمات OBS إلى الحد الأعلى",
  "isales.specialday.title.holiday" : "تاريخ خاص",
  "isales.manutask.title.viewdata" : "بيانات المكالمة الصادرة",
  "isales.blacklist.message.remark.error" : "تحتوي الملاحظات على أحرف غير صالحة",
  "isales.management.message.importType" : "طريقة الاستيراد",
  "isales.taskcalllogic.title.resultSetPolicy" : "تكوين النهج استنادًا إلى النتائج",
  "isales.taskInfo.message.saveFail" : "فشل تعديل المعلومات",
  "isales.taskresult.title.calledNo" : "رقم الصادر",
  "isales.agent.label.transfer" : "نقل",
  "isales.tasklogic.field.addturn" : "إضافة جولة",
  "isales.taskstatus.field.suspend" : "موقوف",
  "isales.datasourcemanagement.field.dbPort" : "منفذ مصدر البيانات",
  "isales.taskmanagement.field.taskId" : "معرف المهمة",
  "isales.task.promotion.message.limitGtTenantLimit" : "يتجاوز حد الإرسال اليومي الحد الأقصى المسموح به للمستأجر",
  "isales.appointment.message.adjustmentFail" : "فشل ضبط المكالمة الصادرة المحجوزة",
  "isales.manual.title.inputfailcode" : "إدخال سبب فشل",
  "isales.data.message.confirmSubmit" : "تسلسل الأرقام الحالي هو تسلسل المكالمة. يرجى التحقق من البيانات مرة أخرى",
  "isales.tasklogic.field.connectsameagent" : "الاتصال بالوكيل نفسه",
  "isales.taskinfo.message.beginTimeError" : "لا يمكن أن يكون وقت البدء بعد وقت الانتهاء",
  "isales.taskresult.field.workNo" : "WorkNo",
  "isales.manualData.message.reachMax" : "لا يمكن استخراج المزيد من بيانات المكالمات الصادرة لأنه تم الوصول إلى الحد الأقصى لعدد المكالمات في المهمة",
  "isales.training.scoreDetails" : "تفاصيل النتيجة",
  "isales.taskinfo.field.survey" : "استبيان",
  "isales.agent.title.agentEdit" : "تعديل معلومات الموظف",
  "isales.tasktemplate.title.addTemplate" : "إنشاء قالب مكالمة صادرة",
  "isales.task.field.callSuccess" : "ناجح",
  "isales.taskinfo.field.surveyAnswerNum" : "عدد الردود على الاستقصاء",
  "isales.taskinfo.title.accesscode" : "رمز الوصول",
  "isales.agent.contact.voiceVidio" : "الصوت والفيديو",
  "isales.taskresult.field.failsPoweredoff" : "تم إيقاف تشغيل الهاتف",
  "isales.agent.message.idIsEmpty" : "لا يمكن أن يكون معرف قائمة انتظار المهارة فارغًا",
  "isales.taskresult.field.failsCalledmodem" : "الطرف المطلوب هو مودم",
  "isales.import.message.taskstatuswrong" : "لا يمكن استيراد البيانات إلى مهمة المكالمة الصادرة لأنها ليست في حالة المسودة أو الإيقاف المؤقت",
  "isales.file.title.filepath" : "المسار الافتراضي",
  "isales.taskcalllogic.message.alreadyLastPolicy" : "السياسة الأخيرة بالفعل",
  "isales.appointment.title.remark" : "ملاحظات",
  "isales.work.title.successNum" : "مكالمات صادرة ناجحة",
  "isales.taskresult.field.failsCallednumberincorrect" : "الرقم المطلوب غير صحيح في المعاينة",
  "isales.appointment.title.add" : "إضافة",
  "isales.taskcalllogic.field.customerMissed" : "العميل غاب",
  "isales.agent.calledroute.create.accesscode.noexist" : "رمز الوصول المشار إليه بواسطة التكوين المطلوب غير موجود",
  "isales.notification.propo.name" : "اسم السمة",
  "isales.tasklogic.field.lt" : "<",
  "isales.agent.message.selectSkills" : "تحديد قائمة انتظار مهارة",
  "isales.specialday.title.choose" : "تحديد تاريخ خاص",
  "isales.result.outgoingCall.Description" : "الوصف",
  "isales.data.orderType.desc" : "تنازلي",
  "isales.rbtresult.field.calledshutdown" : "تم استدعاؤه للتوقف",
  "isales.taskcalllogic.title.resultPolicySet" : "استراتيجية معالجة النتائج",
  "isales.specialday.field.Tuesday" : "الثلاثاء",
  "isales.tasklogic.field.result" : "النتيجة",
  "isales.taskpolicy.message.input.digits" : "أدخل عددًا صحيحًا",
  "isales.taskinfo.message.batchEditCallLogic.manualExist" : "لا يمكن تعديل منطق المكالمات الصادرة اليدوي",
  "isales.manual.message.data.query.error" : "حدث خطأ عند الاستعلام عن بيانات مكالمة صادرة",
  "isales.specreason.field.all" : "من فضلك اختر",
  "isales.taskpolicy.field.notSupportMultiSkill" : "لا يتم دعم العملاء الهجينين للمكالمات الواردة والصادرة",
  "isales.rbtresult.emptyparam.message" : "لا يمكن أن يكون وقت البدء والانتهاء فارغًا",
  "isales.task.promotion.message.variableNotConfig" : "لم يتم تكوين بعض المتغيرات",
  "isales.management.message.fileImport" : "استخدام ملف",
  "isales.management.placeholder.ivrName" : "أدخل اسم عملية الروبوت الذكي",
  "isales.taskmanagement.message.queryDateError" : "تاريخ غير صحيح. حدد تاريخًا آخر",
  "isales.tasklogic.field.moveUp" : "لأعلى",
  "isales.taskinfo.title.versionNo" : "إصدار الاستبيان",
  "isales.task.field.callFlagCompletion" : "اكتملت",
  "isales.tasklogic.field.numbertype" : "نوع الرقم",
  "isales.whitepopuppageurl.protocol.check" : "يتم استخدام بروتوكولات غير آمنة\، مما يشكل مخاطر أمنية",
  "isales.taskresult.title.callFlag" : "الحالة",
  "isales.label.multiple.callno.round" : "جولات مكالمات العملاء متعددة الأرقام",
  "isales.taskstatus.field.all" : "الكل",
  "isales.taskmanagement.title.batchEditCallPolicy.sameTaskType" : "يمكن تعديل نُهج المكالمات الصادرة على دفعات فقط للمهام من نفس النوع",
  "isales.taskinfo.field.surveyScore" : "مجموع نتائج الاستطلاع",
  "isales.call.count" : "عدد المكالمات",
  "isales.data.prop.status.unpublished" : "لم ينشر",
  "isales.taskmanagement.field.isAutoPickUp" : "تمكين قاعدة الاستخراج التلقائي",
  "isales.taskmanagement.message.deleteConfirm" : "هل تريد بالتأكيد حذفه؟",
  "isales.management.message.batchEditCallLogic.statusError" : "يمكن تعديل منطق المكالمات الصادرة على دفعات فقط للمهام في حالة المسودة\، أو التنفيذ\، أو الإيقاف المؤقت",
  "isales.task.promotion.message.selectTemplateType" : "تحديد نوع قالب",
  "isales.calltime.title.spec.date.empty.warn" : "لم يتم تكوين التاريخ الخاص!",
  "isales.calltime.title.holiday" : "وقت خاص",
  "isales.warning.data.export" : "تحتوي البيانات المصدرة على معلومات شخصية. توخي الحذر عند تنفيذ هذه العملية",
  "isales.taskmanagement.title.agentStatistics" : "إحصائيات عمل الموظف",
  "isales.busi.result.message.nodatarecord" : "لم يتم العثور على معلومات مكالمة. يُرجى تحديد سجل المكالمات الصادرة التي تحتاج نتائج أعمالها إلى تعيينها",
  "isales.datasourcemanagement.field.dbCnUrl" : "اتصال مصدر البيانات",
  "isales.taskresult.field.failsMusicdetected" : "تم الكشف عن الموسيقى",
  "isales.datasourcemanagement.title.inputDbschema" : "الرجاء إدخال اسم مصدر البيانات",
  "isales.task.promotion.beginTime" : "وقت البدء",
  "isales.tasktemplate.message.saveSuccess" : "تم تكوين قالب المكالمة الصادرة بنجاح",
  "isales.label.agent.agentType" : "نوع الوكيل",
  "isales.timeImport.message.success" : "تمت إضافة تكوين الاستيراد المجدول بنجاح",
  "isales.taskmanagement.title.chooseEndtime" : "وقت الانتهاء",
  "isales.task.promotion.templateCode" : "رمز القالب",
  "isales.manual.field.previewinformation" : "معاينة المعلومات",
  "isales.tasklogic.field.noRetryLogic" : "إضافة رمز نتيجة عدم إعادة المحاولة",
  "isales.tasklogic.field.retryNumber" : "مرات إعادة المحاولة",
  "isales.task.field.callFlag2" : "ناجح",
  "isales.task.field.callFlag1" : "التنفيذ",
  "isales.task.field.callFlag0" : "ليتم إعدامه",
  "isales.data.propConfig.title.prop" : "السمة",
  "isales.operlog.message.data.downloadexportfile" : "تنزيل المهمة {0} ملف نتيجة التصدير",
  "isales.taskmanagement.title.newFromTemplate" : "إنشاء باستخدام قالب",
  "isales.taskstatistics.field.callstatistics" : "إحصائيات المكالمات",
  "isales.agent.contact.multimedia" : "الوسائط المتعددة",
  "isales.taskresult.title.beginTime" : "وقت البدء",
  "isales.appointment.message.timeout" : "{0} المكالمات الصادرة المعينة قد اجتازت وقت الموعد\، يرجى إجراء المكالمات في الوقت المناسب",
  "isales.specialday.message.deleteTips" : "لا يمكن حذف التاريخ الخاص لأنه مقترن بمهمة مكالمة صادرة",
  "isales.taskInfo.title.taskTemplate" : "قالب المهام",
  "isales.data.title.timeImport" : "استيراد مجدول",
  "isales.management.title.deleteInfo" : "تحديد بيانات المكالمة الصادرة",
  "isales.taskstatistics.field.inProgress" : "العملاء الاتصال",
  "isales.tasklogic.field.callpolicy" : "سياسة الاتصال",
  "isales.manual.button.send" : "إرسال",
  "isales.appointment.title.callTime" : "مقطع وقت المكالمة",
  "isales.training.resultAnalysis" : "تحليل النتائج",
  "isales.agent.message.batchSaveSuccess" : "تم تعديل الموظفين على دفعات بنجاح",
  "isales.taskmanagement.title.appoint" : "حجز مكالمة صادرة",
  "isales.task.message.statistics" : "إحصائيات",
  "isales.skill.label.maxwaittime" : "ماكس. وقت الانتظار",
  "isales.appointment.message.confirmDelete" : "هل تريد بالتأكيد حذف المكالمات الصادرة المحجوزة؟",
  "isales.export.message.noSelectData" : "الرجاء تحديد البيانات المراد تصديرها",
  "isales.manual.title.title" : "العنوان",
  "isales.taskresult.title.inputCallerNo" : "أدخل رقم صادر",
  "isales.taskmanagement.title.speclistmanage.reason" : "السبب",
  "isales.taskmanagement.title.saveAndImportData" : "حفظ واستيراد البيانات",
  "isales.taskmanagement.title.editPolicy" : "تحرير السياسة",
  "isales.management.message.remarkLengthError" : "يُسمح بحد أقصى 1000 حرف",
  "isales.taskinfo.check.empty" : "لا يمكن أن تكون المعلمة فارغة",
  "isales.appointment.placeholder.remark" : "الرجاء ادخال الملاحظات",
  "isales.taskresult.field.failsSwitchingdevicebusy" : "المحول مشغول",
  "isales.task.promotion.message.selectPromotionType" : "تحديد نوع الوسائط",
  "isales.management.message.deletedataIdsError" : "إشارة غير صحيحة لبيانات المكالمة الصادرة المراد حذفها",
  "isales.manual.message.query.agentstatus.error" : "حدث خطأ عند الاستعلام عن حالة الموظف",
  "isales.taskstatistics.placeholder.notcalled" : "معلق: {0}",
  "isales.taskresult.title.busiresultLog" : "سجل نتائج الأعمال",
  "isales.taskmanagement.field.manual" : "مكالمة صادرة عن الموظف",
  "isales.file.title.rule.property" : "سمة الخدمة",
  "isales.tasklogic.field.gt" : ">",
  "isales.agent.label.adjustTime" : "مدة الالتفاف (ثانية)",
  "isales.export.message.noSelectTrainingData" : "يرجى اختيار البيانات المراد تدريبها",
  "isales.tasktype.field.preemption" : "مكالمة صادرة مُسبقة",
  "isales.taskmanagement.title.editCountOutOfBounds" : "يمكن تعديل 30 مهمة مكالمة صادرة كحد أقصى في المرة الواحدة",
  "isales.management.title.black.import" : "استيراد قائمة الحظر",
  "isales.taskinfo.field.videoAgentType" : "فيديو",
  "isales.training.questionScoreDetails" : "تفاصيل نقاط المشكلة",
  "isales.taskresult.field.failsNoValidVDN" : "لا يتوفر VDN صالح",
  "isales.agent.message.updateCtiAndDbSuccess" : "تم تحديث قائمة انتظار المهارة بنجاح",
  "isales.import.title.result" : "عرض نتيجة الاستيراد",
  "isales.callpolicy.title.add" : "إضافة",
  "isales.manualData.message.reachQuatos" : "لا يمكن استخراج المزيد من بيانات المكالمات الصادرة لأن عدد المكالمات في المهمة قد وصل إلى الحد الأعلى للاستخراج",
  "isales.audio.title.callId" : "تسجيل SN",
  "isales.taskresult.title.operateEndTime" : "endTime",
  "isales.agent.label.timeOut" : "انتهاء مهلة قائمة الانتظار",
  "isales.management.message.download" : "تنزيل قالب بيانات المكالمات الصادرة",
  "isales.task.message.specialdayEmpty" : "لم يتم تحديد تاريخ خاص",
  "isales.agent.calledroute.create.skill.none" : "قائمة انتظار المهارة المشار إليها بواسطة التكوين المطلوب غير موجودة",
  "isales.blacklist.placeholder.searchremark" : "ملاحظات",
  "isales.taskresult.title.chooseUserAccount" : "يُرجى التحديد تم البدء من قبل",
  "isales.taskresult.title.resultDetail" : "تفاصيل نتيجة المكالمة",
  "isales.agent.message.description.limit" : "لا يمكن أن يتجاوز وصف قائمة انتظار المهارة 50 حرفًا",
  "isales.operation.success" : "تمت العملية بنجاح",
  "isales.taskresult.field.failsQueuingtimesout" : "انتهت مهلة قائمة الانتظار ويقوم النظام الأساسي بتحرير المكالمة",
  "isales.task.promotion.message.dndEndTimeltBeginTime" : "نهاية فترة عدم الإزعاج قبل البداية",
  "isales.skillqueue.agentrole.agent" : "الوكيل",
  "isales.tasktemplate.title.addManualTemplate" : "إنشاء قالب مكالمة صادرة يدويًا",
  "isales.taskresult.field.failsFailedRouteAgent" : "فشل توجيه المكالمة إلى الموظف",
  "isales.taskpolicy.field.predictMethod" : "خوارزمية للمكالمات الصادرة المتوقعة",
  "isales.manual.title.viewManualData" : "بيانات مكالمة الموظف الصادرة-",
  "isales.manual.message.agentstatus.isnofree" : "الموظف الحالي ليس في الحالة خامل",
  "isales.statistics.field.addHis" : "جمع البيانات المؤرشفة",
  "isales.outboundtime.field.minute" : "دقيقة",
  "isales.agent.label.skillChoice" : "قائمة انتظار المهارات",
  "isales.manual.message.lockData" : "تحديد بيانات المكالمات الصادرة المراد قفلها",
  "isales.specialday.message.updateSuccess" : "تم تعديل التاريخ الخاص بنجاح",
  "isales.taskmanagement.title.importData.template" : "قالب",
  "isales.specialday.title.confirm" : "تأكيد",
  "isales.task.message.saveSuccess" : "تم تكوين مهمة المكالمة الصادرة بنجاح",
  "isales.sendStatus.field.prepare" : "التحضير",
  "isales.taskpolicy.field.utilRate" : "استخدام الوكيل",
  "isales.tasktype.field.robot" : "روبوت الصادر",
  "isales.manual.title.inputstatus" : "أدخل حالة مكالمة صادرة",
  "isales.task.promotion.gatewwayError" : "تكوين العبّارة لا يتطابق مع نوع الوسائط",
  "isales.data.title.undoEdit" : "تراجع عن تحرير",
  "isales.task.promotion.deleteFailed" : "فشل حذف مهمة تسويق الوسائط المتعددة",
  "isales.task.message.play" : "الصوت",
  "isales.tasklogic.field.eq" : "=",
  "isales.task.promotion.email" : "البريد الإلكتروني",
  "isales.taskmanagement.message.queryLimit" : "يجب أن يكون عدد السجلات في كل صفحة عددًا صحيحًا موجبًا أقل من 101",
  "isales.taskresult.title.exportResult" : "عرض نتائج التصدير",
  "isales.taskresult.field.failsFaxdetected" : "تم الكشف عن رسالة فاكس",
  "isales.taskmanagement.title.viewAppointList" : "حجز",
  "isales.datasourcemanagement.field.dbType" : "نوع مصدر البيانات",
  "isales.manual.title.status" : "الحالة",
  "isales.import.coverMode.tips" : "سيؤدي استيراد الكتابة فوقه إلى حذف جميع البيانات التي سيتم استدعاؤها ، والتي تستغرق وقتًا طويلاً. توخي الحذر عند تنفيذ هذه العملية",
  "isales.taskresult.field.failsRingingtimesout" : "انتهت أوقات الرنين وتصدر المنصة المكالمة",
  "isales.task.result.callrecord.conversation" : "سجل المحادثة",
  "isales.taskmanagment.callquality.minConnectedRate" : "الحد الأدنى لمعدل الاتصال",
  "isales.taskpolicy.field.connRate" : "معدل اتصال المكالمة الصادرة",
  "isales.agent.message.willDeleteSkills" : "قد تتسبب هذه العملية في فشل استدعاء المهام الصادرة المقترن بقائمة انتظار المهارات. يرجى العمل بحذر. هل تحتاج إلى حذفها؟",
  "isales.survey.type" : "نوع الاستبيان",
  "isales.statistics.business.endYear" : "نهاية السنة",
  "isales.task.promotion.promotionEffTime" : "الوقت الفعال",
  "isales.taskpolicy.field.calculationFactor" : "عامل الحساب",
  "isales.appointment.message.callNotAllowed" : "لا يمكن إجراء مكالمة صادرة محجوزة لأن الموظف ليس في حالة خامل أو مشغول",
  "isales.management.message.import" : "استيراد البيانات المرفقة",
  "isales.agent.calledroute.refreshsuccess" : "تم التحديث بنجاح",
  "isales.taskinfo.message.require" : "تعيين كافة الحقول المطلوبة",
  "isales.training.specialday.callTime" : "التاريخ الخاص المقطع الزمني للمكالمة",
  "isales.tasklogic.field.addOrderPolicy" : "إضافة دعامة",
  "isales.agent.calledroute.create.accesscode.none" : "لم يتم تعيين رمز الوصول إلى النظام الذي تم تكوينه بواسطة الطرف المطلوب",
  "isales.taskmanagement.title.outbound" : "Chatbot مكالمة صادرة",
  "isales.management.title.details" : "التفاصيل",
  "isales.data.propConfig.message.isUseProp" : "تمكين سمة مخصصة",
  "isales.file.title.rule.name.placeHolder" : "أدخل اسم قاعدة",
  "isales.file.title.server.info" : "تم تعديل معلومات الخادم بنجاح",
  "isales.task.title.calllogic" : "منطق المكالمات الصادرة",
  "isales.tasklogic.field.callturntype.numberorder" : "حسب الرقم التسلسلي",
  "isales.agent.label.deviceType" : "نوع الجهاز",
  "isales.tasktemplate.message.pauseSuccess" : "تم التعليق بنجاح",
  "isales.taskresult.title.chooseResult" : "الرجاء تحديد نتيجة المكالمة",
  "isales.import.message.succnum" : "عدد السجلات الناجحة",
  "isales.file.title.rule.validator.maxdata" : "يمكن إضافة 40 سجل تكوين رقم كحد أقصى",
  "isales.taskmanagement.title.status" : "الحالة",
  "isales.taskresult.field.failsUndefinedsignaltone" : "نغمة إشارة غير محددة",
  "isales.tasklogic.field.addbusiresultpolicy" : "إضافة سياسة نتيجة الأعمال",
  "isales.management.title.detailData" : "تفاصيل بيانات المكالمة الصادرة",
  "isales.taskresult.title.userName" : "اسم المستخدم",
  "isales.taskcalllogic.field.customerbusy" : "العميل مشغول",
  "isales.import.message.selectServer.tips" : "تعريف خادم ملفات المكالمات الصادرة",
  "isales.management.title.refresh" : "تحديث",
  "isales.taskinfo.field.endDate" : "وقت الانتهاء",
  "isales.management.title.calledNo" : "رقم العميل",
  "isales.tasklogic.field.like" : "تشمل",
  "isales.tasklogic.field.callOrderMethod.bySelf" : "حسب القاعدة المخصصة",
  "isales.taskmanagement.title.configData" : "بيانات المكالمة الصادرة",
  "isales.exportdata.message.successinfo" : "جاري تصدير البيانات...عرض التفاصيل في نتيجة التصدير",
  "isales.task.message.numberInvalFeedback" : "عدد مكالمات الملاحظات غير الصالحة",
  "isales.data.prop.numberType.countError" : "يمكن تعريف 10 سمات من نوع الأرقام كحد أقصى",
  "isales.taskinfo.header.policy" : "استراتيجية المهام",
  "isales.specialday.message.deletePartiallyFail" : "فشل حذف بعض التواريخ الخاصة",
  "isales.tasklogic.message.empty.orderNo" : "يوجد رقم تسلسلي فارغ، الرجاء إعادة الإدخال",
  "isales.task.promotion.message.noprop" : "إضافة سمة باستخدام قائمة تعريف سمة بيانات المكالمة الصادرة",
  "isales.taskresult.field.failsResourceOverload" : "موارد غير كافية",
  "isales.import.message.errMsg" : "سبب الاستثناء",
  "isales.statistics.business.startMonth" : "بداية الشهر",
  "isales.statistics.business.dategt31days" : "لا يمكن أن يتجاوز نطاق التاريخ 31 يومًا",
  "isales.tasktemplate.message.releasefailed" : "فشل النشر",
  "isales.agent.message.passLengthValidateFailed" : "يجب أن تحتوي كلمة المرور على 8 أحرف",
  "isales.tasklogic.label.subCondition" : "شرط فرعي",
  "isales.agent.calledroute.placeholder.ivr" : "الرجاء تحديد IVR",
  "isales.manual.message.update.fail" : "فشل تحديث حالة بيانات المكالمة الصادرة",
  "isales.agent.message.searchSkillName" : "أدخل اسم قائمة انتظار المهارة",
  "isales.import.filter.button.noRepeat" : "تصفية البيانات المكررة في الدفعة",
  "isales.file.title.sk.same" : "SKs مختلفة",
  "isales.taskresult.field.failsAgentHangsUp" : "يقوم الوكيل بإنهاء المكالمة",
  "isales.taskinfo.field.chooseOrInputCallNo" : "يمكنك إدخال أو تحديد المكالمةلا في نفس الوقت",
  "isales.management.message.calledNoLengthError" : "يتجاوز طول الرقم المطلوب الحد",
  "isales.blacklist.title.name" : "الاسم",
  "isales.tasklogic.field.callfilter" : "تصفية المكالمات",
  "isales.data.filed.isResult" : "نعم",
  "isales.taskmanagement.title.editInfo" : "تغيير المعلومات",
  "isales.agent.message.passValidateFailed" : "يجب أن تحتوي القيمة على ثلاثة أنواع على الأقل من الأحرف التالية: أحرف كبيرة\، أحرف صغيرة\، أرقام\، أحرف خاصة (`-!@#$^&*()-_+|[{}]<.>/?)",
  "isales.management.message.updateSuccess" : "تم تعديل بيانات المكالمة الصادرة بنجاح",
  "isales.taskpolicy.field.supportMultiSkill" : "دعم العملاء الهجينين للمكالمات الواردة والصادرة",
  "isales.tasklogic.field.addSubCondition" : "إضافة شرط فرعي",
  "isales.data.prop.createDataProp" : "إنشاء سمة",
  "isales.taskpolicy.message.obOverTime" : "أدخل مدة مهلة المعاينة أولاً",
  "isales.survey.details" : "تفاصيل الاستبيان",
  "isales.work.title.selectTask" : "تحديد مهمة مكالمة صادرة",
  "isales.task.message.download" : "تنزيل التسجيل",
  "isales.appointment.title.userName" : "تم البدء بواسطة",
  "isales.statistics.business.weekLessThan" : "لا يمكن أن يكون أسبوع نهاية السنة أقل من أسبوع بداية السنة",
  "isales.result.search.displayResultOrCode" : "يرجى إدخال الرمز أو الوصف",
  "isales.asrtransresult.field.undefine" : "غير معرّف",
  "isales.taskresult.field.failsReleaseswhencall" : "عند إعداد قناة صوتية، يقوم العامل بتحرير مكالمة باستخدام عنصر تحكم شريط العامل",
  "isales.taskinfo.field.agentType5" : "الوسائط المتعددة",
  "isales.taskresult.title.queryArchivedData" : "الاستعلام عن البيانات المؤرشفة",
  "isales.taskinfo.field.agentType4" : "الصوت",
  "isales.data.lock" : "لا تتم معالجة بعض بيانات المكالمات الصادرة. على سبيل المثال، تقوم باستيراد بيانات المكالمات الصادرة أو إضافتها أو حذفها. يُرجى المحاولة مرة أخرى لاحقًا",
  "isales.agent.calledroute.editsuccess" : "تم التحرير بنجاح",
  "isales.work.message.daterequest" : "يجب تمرير وقت البدء والانتهاء في نفس الوقت",
  "isales.taskmanagement.field.pickUpRule" : "قاعدة الاستخراج التلقائي",
  "isales.agent.message.createSkillConfFail" : "فشل إنشاء قائمة انتظار المهارات",
  "isales.tasklogic.field.callmethod" : "وضع الاتصال",
  "isales.taskresult.title.failedDetail" : "تفاصيل فشل المكالمة",
  "isales.data.prop.propNameExists" : "اسم السمة موجود بالفعل",
  "isales.notification.configVariableValue" : "تعيين قيمة المتغير",
  "isales.management.message.deletedataIdsEmpty" : "علامة بيانات المكالمة الصادرة المراد حذفها فارغة",
  "isales.outboundtime.message.crossover" : "تتداخل مقاطع وقت المكالمة الصادرة",
  "isales.specialday.field.byWeek" : "حسب الأسبوع",
  "isales.appointment.message.queryTimeError" : "يجب أن يكون وقت البدء قبل وقت الانتهاء",
  "isales.taskresult.field.failsRingbacktone" : "نغمة Ringback",
  "isales.agent.field.skill" : "SkillName",
  "isales.agent.message.chooseAnAgent" : "تحديد موظف",
  "isales.audio.title.starttime" : "وقت البدء",
  "isales.task.message.numberFailed" : "السجلات الفاشلة",
  "isales.taskmanagement.field.dailyStatistics" : "إحصائيات يومية",
  "isales.import.message.template.show" : "إضافة بيانات العميل حسب الصف وإضافة أرقام مكالمات متعددة حسب العمود",
  "isales.tasklogic.title.addPolicy" : "إضافة سياسة",
  "isales.taskmanagement.title.chooseBegintime" : "وقت البدء",
  "isales.tasklogic.field.policyType" : "PolicyType",
  "isales.tasklogic.field.addCondition" : "إضافة شرط",
  "isales.notification.variable.value" : "قيمة متغيرة",
  "isales.taskinfo.field.beginDate" : "وقت البدء",
  "isales.outboundtime.title.beginTime" : "وقت البدء",
  "isales.taskmanagement.title.importData.upload" : "تحميل",
  "isales.management.message.importMode" : "وضع الاستيراد",
  "isales.tasklogic.message.exist.orderNo" : "تسلسل الأرقام موجود مسبقاً. أدخل تسلسلاً آخر",
  "isales.agent.calledroute.placeholder.extcode" : "الرجاء إدخال رمز الامتداد",
  "isales.taskresultexport.message.type" : "نوع التصدير",
  "isales.agent.contact.vidio" : "فيديو",
  "isales.agent.calledroute.accesscode" : "رمز الوصول",
  "isales.data.prop.editDataPropSuccess" : "تم تعديل السمة بنجاح",
  "isales.operation.training.success" : "نجحت العملية. يستغرق التدريب حوالي عدة ساعات. قم بتسجيل الدخول إلى Lodas لعرض تقدم التدريب",
  "isales.taskresult.field.failsHangsupwhencall" : "عندما يرد موظف على مكالمة باستخدام عنصر تحكم شريط الموظف\، يتم قطع اتصال هاتف الموظف",
  "isales.blacklist.message.periodAndTime" : "يجب تعيين فترة التقييد والحد الأقصى لعدد المكالمات أو تركها فارغة في نفس الوقت",
  "ccsurvey.info.tip36" : "السؤال مطلوب",
  "isales.agent.calledroute.create.ivr.limit" : "نوع الجهاز هو IVR، ونوع الوسائط لرمز الوصول يمكن أن يكون فقط النقر للاتصال أو طلب معاودة الاتصال أو مكالمة عادية",
  "isales.data.message.preview.addNumberUpperLimit" : "يمكن إضافة عدد {count} كحد أقصى للمكالمات الصادرة التي تمت معاينتها",
  "isales.audio.title.confirmStatus" : "نتائج الأعمال",
  "isales.operation.failed" : "فشلت العملية",
  "isales.taskinfo.title.surveyName" : "اسم الاستبيان",
  "isales.tasklogic.field.callOrderType.isOrder" : "مكالمة مرتبة",
  "isales.taskinfo.callno.repeat" : "يتم تكرار رقم المتصل",
  "isales.data.propConfig.button.configProp" : "تعيين السمات",
  "isales.agent.accesscode.description.limit" : "يمكن أن يحتوي وصف رمز الوصول على 50 حرفًا كحد أقصى",
  "isales.message.promptForAgent" : "يرجى إدخال معرف الموظف",
  "isales.taskinfo.field.mutiCallNo" : "أدخل الرقم الطالب",
  "isales.work.message.dateRangeRequest" : "الرجاء تحديد نطاق وقت الاستعلام",
  "isales.taskresultexport.message.begintime" : "وقت البدء",
  "isales.taskresult.field.failsCallResourcesInsufficient" : "موارد مكالمات غير كافية",
  "isales.training.averageDuration" : "متوسط المدة",
  "isales.management.message.calledNoOne" : "أول رقم تم الاتصال به",
  "isales.taskresult.field.failsNoAnsweringDeviceAvailable" : "لا يتوفر جهاز للإجابة",
  "isales.tasktype.field.prediction" : "مكالمة صادرة متوقعة",
  "isales.data.prop.publish" : "نشر",
  "isales.import.filter.title.popup" : "قواعد الفلتر",
  "isales.callNo.title.no" : "لا",
  "isales.management.message.import.server" : "الخادم",
  "isales.datasourcemanagement.field.sync" : "مزامنة",
  "isales.datasourcemanagement.message.updateDataSourceSuccess" : "تم تعديل مصدر البيانات بنجاح",
  "isales.specialday.title.create" : "إنشاء",
  "isales.file.title.password.same" : "كلمتا المرور مختلفتان",
  "isales.task.promotion.timeerror" : "يجب أن يكون وقت النفاذ قبل وقت انتهاء الصلاحية",
  "isales.language.label.en_US" : "الإنجليزية",
  "isales.taskinfo.title.smsTemplate" : "قالب SMS",
  "isales.work.title.agent.workno" : "معرف الموظف",
  "isales.management.placeholder.skillName" : "أدخل اسم قائمة انتظار مهارة",
  "isales.datasourcemanagement.field.backdbport" : "منفذ مصدر البيانات الاحتياطي",
  "isales.blacklist.message.Description" : "الحد الأقصى لعدد المكالمات كل ساعة أو يوم خلال فترة الصلاحية",
  "isales.taskpolicy.field.talkingRate" : "سرعة المكالمة الصادرة",
  "isales.manual.message.precall.success" : "جارٍ بدء مكالمة صادرة",
  "isales.taskmanagement.title.inputTaskName" : "أدخل اسم مهمة",
  "isales.agent.message.modifySkillConfFail" : "فشل تعديل قائمة انتظار المهارة",
  "isales.data.import.task.status.executing" : "جارٍ الاستيراد",
  "isales.tasktemplate.title.inputTemplateName" : "أدخل اسم قالب",
  "isales.management.title.edit" : "تعديل",
  "isales.file.message.rule.fail" : "فشل تعديل القاعدة",
  "isales.file.message.deletefiletips" : "هل تريد بالتأكيد حذف خادم الملفات الصادرة؟",
  "isales.taskLogic.message.saveSuccess" : "تم تعديل منطق المكالمة الصادرة بنجاح",
  "isales.statistics.business.yearLessThan" : "لا يمكن أن تكون السنة النهائية قبل سنة البدء",
  "isales.datasourcemanagement.field.add" : "مصدر بيانات جديد",
  "isales.agent.contact.mediaType" : "نوع الوسائط",
  "isales.appointment.placeholder.appointTime" : "حدد وقت المكالمة المحجوز",
  "isales.busi.result.item.succ" : "النجاح",
  "isales.file.title.rule.validator.row" : "أدخل رقم تسلسلي من العمود A إلى EZ",
  "isales.taskmanagement.title.export" : "تصدير سجل المكالمات",
  "isales.calltime.title.holidayName" : "اسم تاريخ خاص",
  "isales.datasourcemanagement.title.inputDbId" : "إدخال معرف مصدر البيانات",
  "isales.business.result.delete" : "حذف",
  "isales.specialday.message.weeklyError" : "فترة التاريخ الخاص غير صحيحة",
  "isales.file.message.bucket.placeholder" : "أدخل دلو",
  "isales.taskmanagement.title.batchEditCallTime.manualError" : "لا يمكن تغيير وقت المكالمة الصادرة يدويًا",
  "isales.callrecordexport.message.downloadFilenopemission" : "ليس لديك الحق في تصدير سجلات اتصالات المكالمات الصادرة الذكية",
  "isales.taskresult.field.failsAgentCancelingCall" : "يقوم الموظف بإلغاء المكالمة",
  "isales.data.message.duplicateExist" : "الأرقام المكررة موجودة",
  "isales.agent.label.answerType2" : "لا يقوم الصوت بالإبلاغ عن معرف الموظف",
  "isales.training.questionNumber" : "عدد الأسئلة",
  "isales.file.title.password" : "كلمة المرور",
  "isales.agent.label.answerType0" : "معرف موظف التقرير",
  "isales.import.message.allow.task.statue" : "،يمكن استيراد المهمة فقط عندما تكون في حالة مسودة أو إيقاف مؤقت",
  "isales.taskresult.field.failedtooccupytheagent" : "فشل شغل موظف",
  "isales.agent.message.batchSaveFail" : "فشل تعديل الموظفين في دفعات",
  "isales.training.surveyDetail" : "تفاصيل الاستبيان",
  "isales.validate.message.holidayDateComparisonError" : "لا يمكن أن يكون تاريخ الانتهاء قبل تاريخ البدء",
  "isales.management.title.timeImport.execTime" : "تم التنفيذ",
  "isales.statistics.business.reportType" : "نوع التقرير",
  "isales.taskresultexport.title.fail" : "فشل",
  "isales.taskmanagement.title.queryTimeTip" : "معلومات",
  "isales.title.agent.workno" : "معرف الموظف",
  "isales.agent.label.workNo" : "معرف الموظف",
  "isales.common.title.view" : "عرض",
  "isales.tasklogic.field.existingDataType" : "تحديد نوع بيانات موجود",
  "isales.taskmanagement.title.batchEditTaskInfo.errTaskType" : "لا يمكن تعديل المهام من أنواع مختلفة على دفعات",
  "isales.management.message.appendMode" : "إضافة",
  "isales.file.title.rule.index" : "الرقم التسلسلي",
  "isales.tasklogic.field.callbyturn" : "عن طريق الجولة",
  "isales.import.filter.button.calling" : "تصفية بيانات المكالمات غير المكتملة",
  "isales.import.message.fail" : "فشل استيراد البيانات",
  "isales.busi.result.message.recordfail" : "فشل تسجيل نتيجة العمل",
  "isales.taskmanagement.title.addAuto" : "إنشاء مكالمة صادرة تلقائية للنظام",
  "isales.file.title.rule.validator.taskId" : "معلومات المهمة غير موجودة",
  "isales.blacklist.message.deletePartiallyFail" : "فشل حذف بعض الأرقام من قائمة الحظر",
  "isales.taskresult.field.failedtoresume" : "لا يمكن استرداد المهمة لأن المهمة ليست في حالة الإيقاف المؤقت",
  "isales.taskpolicy.field.previewAutoCallTime" : "مدة  المكالمة الصادرة التي تمت معاينتها",
  "isales.taskLogic.message.saveFail" : "فشل تعديل منطق المكالمة الصادرة",
  "isales.agent.calledroute.device" : "يُرجى تحديد جهاز",
  "isales.blacklist.title.modifyRemark" : "تعديل الملاحظة",
  "isales.taskstatistics.placeholder.statistics" : "إحصائيات",
  "isales.import.button.configProp" : "تكوين قاعدة عامل التصفية",
  "isales.management.title.createNew" : "إنشاء بيانات مكالمة صادرة",
  "isales.taskpolicy.field.minAvgCallTime" : "الحد الأدنى لمتوسط مدة المكالمة ",
  "isales.specist.message.speclistmanage" : "حدد رقم القائمة الخاص الذي سيتم تشغيله",
  "isales.busi.result.message.lastappointrecord" : "تعيين Pleanse نتيجة الأعمال من آخر مكالمة صادرة محجوزة أولا",
  "isales.file.title.password.placeholder" : "أدخل كلمة المرور لتسجيل الدخول إلى الخادم",
  "isales.skillqueue.agentrole.administrator" : "مدير الغرف",
  "isales.manual.message.call.failed" : "فشل إجراء مكالمة صادرة",
  "isales.taskresult.field.failsHangsupbeforeanswering" : "يقوم الطرف المطلوب بإنهاء المكالمة قبل الرد على المكالمة",
  "isales.step.callpolicy" : "الاستراتيجية الصادرة",
  "isales.manual.field.handlednumber" : "رقم الجلسة",
  "isales.tasklogic.message.repeatProp" : "يتعذر تحديد السمات المكررة",
  "isales.management.title.selectResultCode" : "تحديد رمز النتيجة",
  "isales.tasklogic.field.interval" : "الفاصل الزمني (الثاني)",
  "isales.taskmanagement.field.calltime" : "تم البدء في",
  "isales.agent.contact.videoclicktodial" : "النقر لطلب الفيديو",
  "isales.taskresult.message.queryTime" : "إدخال وقت كامل",
  "isales.taskmanagement.title.query" : "استعلام",
  "isales.agent.label.cti" : "cti",
  "isales.import.filter.title.notification.isPushDay" : "أيام مضغوطة",
  "isales.data.prop.propParamsError" : "فشل التحقق من معلمة السمة",
  "isales.file.title.rule.validator.prop" : "السمة المعرّفة من قبل المستخدم للمهمة غير متناسقة مع السمة التي تم تكوينها في القاعدة",
  "isales.label.agent.accountcode" : "حساب الخدمة",
  "isales.taskmanagement.field.statisticsType" : "نوع الإحصائيات",
  "isales.specialday.message.idError" : "علامة تاريخ خاص غير صحيحة",
  "isales.validate.message.formatError" : "أدخل تاريخًا بتنسيق MM/DD",
  "isales.taskresult.title.choosebusisubstatus" : "يُرجى تحديد نتيجة العمل قبل تحديد الحالة الفرعية",
  "isales.tasklogic.field.moveDown" : "إلى الأسفل",
  "isales.taskresult.field.failsEdt" : "نص الهاتف (EDT)",
  "isales.tasklogic.field.elt" : "<=",
  "isales.specialday.title.addSpecialTime" : "إضافة تاريخ خاص",
  "isales.business.result.message.must" : "هذه المعلمة إلزامية",
  "isales.taskinfo.message.autoCallTimeValueError" : "يجب أن تكون مدة المكالمة الصادرة التي تمت معاينتها أقصر من مدة مهلة المعاينة",
  "isales.agent.calledroute.confdelete" : "حذف التأكيد",
  "isales.business.result.deletetips" : "هل تريد بالتأكيد حذف نتيجة العمل؟",
  "isales.manual.field.result.fail" : "فشل",
  "isales.taskstatus.field.draft" : "مشروع",
  "isales.data.prop.editDataProp" : "تعديل السمة",
  "isales.black.filed.number" : "الرقم (إلزامي)",
  "isales.blacklist.placeholder.searchcalledno" : "أدخل الرقم المطلوب",
  "isales.timeImport.message.rulePropsError" : "يجب أن تكون سمة الخدمة لقاعدة الاستيراد المجدول متوافقة مع السمة المعرفة في المهمة الحالية",
  "isales.survey.statistic" : "إحصائيات المستجيبين",
  "isales.taskpolicy.message.input.maxAvgCallTime" : "لا يمكن أن يكون الحد الأقصى لمتوسط مدة المكالمة أقل من الحد الأدنى. متوسط مدة المكالمة",
  "isales.taskcalllogic.field.emptyNumber" : "رقم خارج الخدمة",
  "isales.business.result.searchname" : "أدخل اسم نتيجة العمل",
  "isales.calledNo.message.input" : "يُسمح فقط بالأرقام",
  "isales.calltime.title.add.time.period" : "إضافة فترة زمنية",
  "isales.taskmanagement.title.begintime" : "وقت البدء",
  "isales.callno.message.delayTime" : "وقت تأخير فشل المكالمة: الفاصل الزمني بين الرقم الحالي والرقم السابق",
  "isales.manual.message.data.isalready.called" : "قام العميل بإجراء مكالمة ولا يمكنه إجراء مكالمة أخرى",
  "isales.data.import.task.status.complete" : "اكتملت",
  "isales.calltime.task.template" : "قالب المهمة",
  "isales.agent.calledroute.delsuccess" : "تم الحذف بنجاح",
  "isales.task.promotion.hwsms" : "HUAWEI CLOUD SMS",
  "isales.taskresult.field.failsSubscriberhangsup" : "يقوم الطرف المطلوب بإنهاء المكالمة",
  "isales.agent.field.skillWeight" : "وزن المهارة",
  "isales.specialday.message.deleteSpecialday" : "اختر تاريخاً خاصاً",
  "isales.outboundtime.title.timePeriod" : "مقطع وقت المكالمة الصادرة",
  "isales.management.title.extendedField" : "حقل موسع",
  "isales.blacklist.title.expirationTime" : "وقت انتهاء الصلاحية",
  "isales.skill.placeholder.skilldesc" : "أدخل اسم قائمة انتظار المهارة",
  "isales.input.message.small" : "قيمة الإدخال صغيرة جدًا",
  "isales.sendStatus.field.sending" : "إرسال",
  "isales.datasourcemanagement.field.dbIp" : "البيانات IP أو الأسماء المستعارة",
  "isales.management.message.calledNoFormatIncorrect" : "يمكن أن يحتوي رقم المكالمة الصادرة على أرقام فقط",
  "isales.taskcalllogic.message.dataTypeDefaultNoCreate" : "نوع الرقم الافتراضي",
  "isales.button.cancel" : "إلغاء",
  "isales.task.promotion.edit" : "تعديل مهمة تسويق الوسائط المتعددة",
  "isales.outboundtime.title.clear" : "مسح",
  "isales.management.title.toblocklist" : "إضافة إلى قائمة الحظر",
  "isales.result.delete.error" : "تحديد سبب فشل مكالمة صادرة",
  "isales.agent.title.skillCreate" : "إنشاء قائمة انتظار المهارات",
  "isales.datasourcemanagement.field.dbId" : "رقم مصدر البيانات",
  "isales.specialday.field.endDate" : "تاريخ الانتهاء",
  "isales.management.message.batchEditCallLogic.notSupportBusiresult" : "لا يمكن تكوين نُهج نتائج الأعمال للمكالمات الصادرة التلقائية أو الذكية. هل تريد بالتأكيد إجراء تعديل الحزمة؟",
  "isales.rbtrecognition.remark.length" : "يمكن أن تحتوي السلسلة على 200 حرف كحد أقصى",
  "isales.agent.label.useTheSkill" : "حذف علاقة الربط بين الموظف وقائمة انتظار المهارة في عامل التكوين",
  "isales.taskinfo.title.adjustAgent" : "ضبط الوكلاء",
  "isales.taskmanagement.title.speclistmanage.calledno" : "رقم الصادر",
  "isales.taskmanagement.title.editSample" : "إدارة الكائن",
  "isales.datasourcemanagement.message.dbpass" : "الرجاء إدخال نفس كلمة المرور",
  "isales.import.filter.button.recentCall" : "تصفية بيانات المكالمات التي اكتملت مؤخرًا",
  "isales.file.title.server.fail" : "فشل تعديل معلومات الخادم",
  "isales.taskresult.field.failsNoresource" : "لا يوجد مورد متاح",
  "isales.statistics.business.endMonth" : "نهاية الشهر",
  "isales.taskresult.field.failsObsexits" : "عند خروج OBS، يتم إلغاء السجلات الجارية التي لم يتم الرد عليها وإعادة كتابتها",
  "isales.manual.exit.preview.tips" : "إذا انتهت مهلة المعاينة أو تم إغلاق النافذة ، فسيتصل بك النظام تلقائيًا.",
  "isales.agent.calledroute.create.accesscode.repeat" : "لا يمكن تكرار الجمع بين رمز الوصول إلى النظام ورمز الامتداد الذي تم تكوينه بواسطة الطرف المطلوب",
  "isales.training.startTraining" : "بدء التدريب",
  "isales.task.promotion.create" : "إنشاء مهمة تسويق الوسائط المتعددة",
  "isales.management.title.warning" : "تحذير",
  "isales.training.taskdetails" : "تفاصيل المهمة",
  "isales.taskmanagement.title.batchOpt" : "عملية الدفعات",
  "isales.statistics.business.week" : "أسبوع",
  "isales.data.import.task.status.error" : "خطأ",
  "isales.specialday.message.nameExists" : "اسم التاريخ الخاص موجود بالفعل",
  "isales.taskinfo.field.skillName" : "قائمة انتظار المهارات",
  "isales.taskresult.field.failsReleasesbeforecall" : "قبل إعداد قناة صوت، يقوم العامل بتحرير مكالمة باستخدام عنصر تحكم شريط العامل",
  "isales.specialday.message.weeklyEmpty" : "الفترة الزمنية الخاصة فارغة",
  "isales.taskmanagement.message.queryFailed" : "فشل الاستعلام",
  "isales.audio.message.errortip1" : "فشل الاستعلام عن معلومات التسجيل. اتصل بالمسؤول",
  "isales.agent.field.newpasswordconf" : "تأكيد كلمة المرور",
  "isales.datasourcemanagement.message.loadSuccess" : "تم تحميل مصدر البيانات بنجاح",
  "isales.taskresult.field.failsInvalidstatus" : "حالة DTE غير صالحة",
  "isales.task.title.preStep" : "السابق",
  "isales.calltime.task.callerno.select.placeholder" : "يُرجى تحديد الرقم المتصل",
  "isales.appointmanagement.field.remark" : "ملاحظات",
  "isales.agent.label.ccRoleName" : "اسم الدور",
  "isales.taskinfo.callno.length" : "يتجاوز طول الرقم الطالب الفردي 32 حرفًا",
  "isales.blacklist.message.createSuccess" : "تمت إضافة الرقم بنجاح إلى قائمة الحظر",
  "isales.taskmanagement.title.batchStart.finishedStatus" : "لا يمكن بدء المهام المكتملة",
  "isales.business.result.view" : "عرض نتائج الأعمال",
  "isales.taskresultexport.message.downloadFilenopemission" : "ليس لديك إذن بتصدير الملفات",
  "isales.datasourcemanagement.message.loadfail" : "فشل تحميل مصدر البيانات",
  "isales.notification.viewResult.button" : "عرض نتائج التسويق",
  "isales.taskmanagment.title.callquality.viewstatistics" : "إحصائيات جودة المكالمات",
  "isales.tasklogic.field.dataType" : "نوع البيانات",
  "isales.data.prop.stringType" : "سلسلة",
  "isales.task.statistics.obs.startTime" : "لا يمكن أن يكون وقت البدء قبل سبعة أيام",
  "isales.datasourcemanagement.message.alreadyExistDs" : "مصدر البيانات الخاص بـ VDN موجود بالفعل",
  "isales.tasktype.field.auto.robot.create" : "روبوت زر جديد",
  "isales.agent.button.edit" : "تحرير",
  "isales.taskresult.field.failsNoreply" : "لا يرد الطرف المطلوب على المكالمة",
  "isales.appointment.message.plaintext" : "ليس لدى المستخدم إذن النص العادي ولا يمكنه بدء المكالمات. الرجاء تمكين إذن النص العادي",
  "isales.taskresult.field.failsNumberchange" : "تم تغيير الرقم",
  "isales.common.title.tipsInfo" : "معلومات",
  "isales.tasklogic.field.egt" : ">=",
  "isales.tasklogic.field.retryDay" : "الحد الأقصى لأيام لمحاولات الاتصال قبل الاتصال",
  "isales.task.promotion.message.variableSelected" : "تحديد سمة",
  "isales.taskresult.title.operateBeginTime" : "StartTime",
  "isales.rbtresult.field.calledunconnected" : "الطرف المطلوب غير متوفر، وتم تمكين تذكير المكالمة الواردة",
  "isales.notification.receiver.address" : "عنوان المستلم",
  "isales.taskresultexport.message.downloadConfirmInfo" : "هل تريد بالتأكيد تنزيل الملف؟",
  "isales.taskresult.title.busisubstatus" : "الدولة الفرعية",
  "isales.task.promotion.promotionName" : "مهمة التسويق",
  "isales.training.remainingSamples" : "الكمية المتبقية",
  "isales.notification.prop.tips" : "يجب أن تكون السمة فريدة",
  "isales.task.message.numbersTotal" : "الإجمالي: {0}",
  "isales.taskstatistics.field.touchRate" : "معدل اللمس",
  "isales.specialday.title.delete" : "حذف",
  "isales.data.message.confirmaddblock.calldatatype" : "النوع الحالي هو بيانات الاتصال. الرجاء التأكيد مرة أخرى",
  "isales.specialday.title.deleteError" : "خطأ",
  "isales.taskpolicy.field.utilRateCalculateMethod" : "طريقة لحساب استخدام الوكيل",
  "isales.agent.label.ivrChoice" : "IVR",
  "isales.datasourcemanagement.field.dsType.all" : "الكل",
  "isales.taskresult.title.result" : "نتيجة المكالمة",
  "isales.statistics.business.currentDateCheck" : "يجب أن يكون التاريخ المحدد قبل اليوم الحالي",
  "isales.specreason.field.emptynumber" : "الرقم غير مخصص",
  "isales.taskinfo.field.callBackUrl" : "عنوان معاودة الاتصال",
  "isales.taskcalllogic.message.mostPolicys" : "يمكن تكوين 10 سياسات نتيجة كحد أقصى لرقم",
  "isales.manualdata.title.updateCalledNo" : "تغيير رقم الصادر",
  "isales.taskstatus.field.execute" : "التنفيذ",
  "isales.taskresult.title.busiresultupdate" : "إلحاق Busiresult",
  "isales.taskresult.field.failsFaxormodem" : "الطرف المطلوب هو فاكس أو مودم",
  "isales.datasourcemanagement.field.dbschema" : "اسم مصدر البيانات",
  "isales.task.title.callTime" : "مدة المكالمة الصادرة",
  "isales.busi.result.title.result" : "النتيجة",
  "isales.taskresult.field.failsUnallocatednumber" : "رقم غير مخصص",
  "isales.taskpolicy.field.waitTime" : "وقت  انتظار الطرف المتصل به",
  "isales.taskmanagement.message.beforeNow" : "لا يمكن أن يكون وقت البدء قبل الوقت الحالي",
  "isales.specialday.message.idEmpty" : "علامة التاريخ الخاصة فارغة",
  "isales.specialday.message.isEmpty" : "لم يتم تعيين وقت المكالمة الصادرة للتاريخ الخاص",
  "isales.taskmanagement.title.viewAppointListDetail" : "تفاصيل الحجز",
  "isales.taskmanagement.title.batchEdit" : "تعديل الحزمة",
  "isales.business.result.name" : "اسم نتيجة العمل",
  "isales.taskresult.field.failsTransferbeingalerted" : "تتم إعادة توجيه المكالمة أثناء الرنين",
  "isales.datasourcemanagement.message.updateDataSourceFail" : "فشل تعديل مصدر البيانات",
  "isales.taskinfo.url.not.whitelist" : "العنوان غير موجود في قائمة الثقة",
  "isales.export.message.input.error" : "كلمة المرور ليست بتنسيق صحيح",
  "isales.manual.message.data.checklock.inblack" : "رقم المكالمة الصادرة موجود في قائمة الحظر",
  "isales.taskresult.field.failsInvalidSkillQueue" : "قائمة انتظار مهارة غير صالحة",
  "isales.file.title.port" : "ميناء",
  "isales.task.message.numberCalled" : "يسمى: {0}",
  "isales.taskresult.field.failsAgentfailswithuser" : "فشل إعداد قناة صوتية بين موظف ومشترك",
  "isales.taskmanagement.title.pauseConfirm" : "هل تريد بالتأكيد تعليق مهمة المكالمة الصادرة المحددة؟",
  "isales.agent.message.configAgent" : "تكوين عامل أو مزامنته",
  "isales.taskresult.field.failsCallRouting" : "فشل توجيه المكالمة",
  "isales.notification.configVariableRel" : "تكوين العلاقة بين المتغير والسمة",
  "isales.failfile.export.failCalledNo" : "فشلتم الاتصاللا",
  "isales.manual.field.noDataCanbeCallTips" : "لا يمكن إجراء أي مكالمات حاليًا. تحقق مما إذا كان تكوين السمة يفي بمعايير عامل التصفية",
  "isales.black.filed.remark" : "ملاحظات (اختياري)",
  "isales.task.promotion.sendTemplate" : "قالب الإعلام",
  "isales.taskresult.field.failsNoagentavailable" : "عندما يتغير OBS إلى حالة الاستعداد\، في حالة عدم توفر عامل للخروج الآمن أو المكالمات الصادرة المشغولة\، يتم إلغاء السجلات الفاشلة",
  "isales.tasklogic.field.add.numbertype" : "نوع رقم جديد",
  "isales.taskresult.field.failsDestinationunreachable" : "لا يمكن الوصول إلى الوجهة",
  "isales.appointment.message.afterNow" : "لا يمكن أن يكون الوقت المحجوز قبل الوقت الحالي",
  "isales.specialday.title.add" : "إضافة تاريخ خاص",
  "isales.taskresult.field.failsAbnormal" : "خطأ",
  "isales.specreason.field.systemtone" : "نغمة مطالبة النظام",
  "isales.taskresult.field.invalidCall" : "مكالمة غير صالحة",
  "isales.agent.label.skillId" : "معرف قائمة انتظار المهارات",
  "isales.taskresult.field.failsPlayingvoices" : "يكتشف إرسال الإشارات أن المحول النظير يقوم بتشغيل الأصوات",
  "isales.training.fullScoreNumber" : "عدد الدرجات الكاملة",
  "isales.task.promotion.promotionExpTime" : "وقت انتهاء الصلاحية",
  "isales.agent.label.ivr" : "IVR",
  "isales.appointment.title.appointState" : "حالة الحجز",
  "isales.taskinfo.field.noSpecifiedAgent" : "قائمة انتظار المهارات بالكامل",
  "isales.management.message.import.filePath" : "عنوان الملف",
  "isales.appointment.message.soontimeout" : "{0} المكالمات الصادرة المعينة ستصل في الدقائق الخمس التالية\، يرجى الاستعداد لإجراء المكالمات",
  "isales.taskresult.field.failsEmptyorwrong" : "رقم غير قابل للوصول أو غير صحيح",
  "isales.specialday.message.createSuccess" : "تم إنشاء التاريخ الخاص بنجاح",
  "isales.taskinfo.message.callintervalrange" : "الرجاء إدخال عدد صحيح يتراوح من 1 إلى 3600",
  "isales.management.title.busiresult" : "نتائج الأعمال",
  "isales.import.filter.button.callResult" : "تصفية البيانات من نتائج الأعمال",
  "isales.management.message.calledNoExists" : "الرقم المطلوب موجود بالفعل",
  "isales.workbench.task.management" : "إدارة المكالمات الصادرة",
  "isales.management.message.calledNoTwoInterval" : "الفاصل الزمني للرقم المطلوب الثاني",
  "isales.taskmanagement.field.zip.makesure.password" : "أعد إدخال كلمة مرور الحزمة المضغوطة",
  "isales.statistics.business.saveError" : "أخطاء",
  "isales.tasklogic.field.retryTimes" : "الحد الأقصى لعدد محاولات الاتصال اليومية",
  "isales.data.prop.quantity" : "الكمية",
  "isales.taskmanagement.field.pickUpNum" : "مكالمات مستخرجة في كل مرة",
  "isales.calltime.title.add.date" : "إضافة تاريخ خاص",
  "isales.taskmanagement.title.view.callrecord" : "سجل المكالمات",
  "isales.data.prop.orderType" : "نوع الطلب",
  "isales.data.prop.anonym" : "مجهولة الهوية",
  "isales.file.title.ip" : "عنوان IP",
  "isales.tasklogic.field.callOrderMethod.byOrderNo" : "بواسطة لا",
  "isales.specialday.message.crossover" : "تتداخل مقاطع الوقت للمكالمات الصادرة في تواريخ خاصة",
  "isales.datasourcemanagement.field.load" : "تحميل",
  "isales.import.filter.button.lineRepeat" : "تصفية البيانات المكررة في الصف",
  "isales.taskresult.field.failsCallFailure" : "فشلت المكالمة",
  "isales.taskstatistics.field.completedcalls" : "تم الاتصال",
  "isales.import.message.selectRule.tips" : "تعريف قاعدة التعيين المراد استيرادها",
  "isales.task.promotion.pauseSuccess" : "تم تعليق مهمة تسويق الوسائط المتعددة بنجاح",
  "isales.taskstatistics.field.abandonRate" : "التخلي عن معدل",
  "isales.taskmanagement.field.pickUpRuleBusiResult" : "نتائج الأعمال",
  "isales.import.message.uploadedfiletoolarge" : "تحديد ملف أقل من 20 ميغابايت",
  "isales.calltime.task.template.select.placeholder" : "خيار لإنشاء مهام صادرة من القوالب",
  "isales.statistics.business.reset" : "إعادة الضبط",
  "isales.blacklist.message.deleteBlacklist" : "حدد الرقم المدرج في قائمة الحظر المراد حذفه",
  "isales.agent.message.name.limit" : "يتجاوز اسم قائمة انتظار المهارة الحد الأقصى للطول",
  "isales.common.title.success" : "ناجح",
  "isales.rbtresult.field.other" : "أخرى",
  "isales.task.promotion.prefix" : "رمز البلد",
  "isales.taskresult.field.failsWithoutringing" : "تتم إعادة توجيه المكالمة عند الاستجابة الفورية قبل الرنين",
  "isales.task.title.taskSample" : "إدارة الكائن",
  "isales.notification.import.button" : "استيراد البيانات",
  "isales.work.title.busiSuccess" : "الأعمال الناجحة",
  "isales.taskinfo.field.flowName" : "تدفق IVR",
  "isales.taskresult.field.skillqueue" : "قائمة انتظار المهارات",
  "isales.task.promotion.startFailed" : "فشل نشر مهمة تسويق الوسائط المتعددة",
  "isales.rbtresult.field.calledrestriction" : "قام المستخدم الذي تم الاتصال به بتعيين قيود المكالمات الواردة",
  "isales.taskstatistics.field.callRatePie" : "معدل إتمام المكالمة",
  "isales.audio.title.endTime" : "وقت انتهاء التسجيل",
  "isales.datasourcemanagement.message.confirLoad" : "تحميل تكوين مصدر البيانات عملية عالية المخاطر \, يرجى المتابعة بحذر",
  "isales.exportdata.message.failinfo" : "فشل تصدير البيانات",
  "isales.tasktemplate.title.editTemplate" : "تعديل قالب مكالمة صادرة",
  "isales.taskresult.field.failsTransferonbusy" : "تتم إعادة توجيه المكالمة في حالة مشغول",
  "isales.datasourcemanagement.message.deleteDataSourceFail" : "فشل حذف مصدر البيانات",
  "isales.business.callInterval.message.must" : "الفاصل الزمني مطلوب",
  "isales.taskstatistics.field.Completed" : "مكالمات العملاء المكتملة",
  "isales.taskresult.field.failsCallqueueoverflow" : "تجاوز قائمة انتظار المكالمات",
  "isales.taskmanagement.startTask" : "بدء المهمة",
  "isales.taskmanagement.title.out.callresult" : "نتيجة مكالمة صادرة",
  "isales.manual.message.confirmLockData" : "هل تريد بالتأكيد تأمين بيانات المكالمة الصادرة؟",
  "isales.manual.task.survey.currentprogress" : "التقدم الحالي",
  "isales.taskmanagement.title.toBeCallCount" : "مكالمات معلقة",
  "isales.task.promotion.smsgw" : "بوابة SMS",
  "isales.speclist.blacklist.message.movePartiallyFail" : "فشل نقل الرقم إلى القائمة السوداء",
  "isales.task.promotion.inputPromotionName" : "أدخل اسم مهمة التسويق",
  "isales.tasktype.field.intelligent.info" : "روبوت ذكي",
  "isales.operlog.message.data.import" : "استيراد البيانات للمهمة {0}",
  "isales.tasklogic.message.need.turns" : "إضافة بيانات جولة واحدة على الأقل",
  "isales.taskmanagement.title.setAgent" : "تعيين الوكلاء",
  "isales.taskcalllogic.field.fax" : "فاكس\، لوحة مفاتيح\، إلخ",
  "isales.file.title.rule.validator.ruleId" : "قاعدة التعيين غير موجودة",
  "isales.agent.message.deleteSkillAndAgentFail" : "فشل حذف اقتران قائمة انتظار المهارات",
  "isales.task.promotion.whatsApp.variable.description" : "وصف المعلمة",
  "isales.blacklist.title.deleteError" : "خطأ",
  "isales.task.promotion.gateway" : "إرسال العبّارة",
  "isales.training.trainingRate" : "معدل التدريب",
  "isales.taskresult.field.failsCallattemptfails" : "فشل استدعاء واجهة المكالمة الصادرة",
  "isales.blacklist.message.confirmDelete" : "هل تريد بالتأكيد حذف الأرقام المحددة من القائمة المحظورة؟",
  "isales.tasktemplate.title.manualTemplate" : "قالب مكالمة صادرة يدويًا",
  "isales.management.title.adjustment.log" : "سجل التعديل",
  "isales.business.result.create" : "إنشاء نتيجة الأعمال",
  "isales.datasourcemanagement.message.confirDelete" : "يعد حذف مصدر البيانات عملية محفوفة بالمخاطر. توخي الحذر عند القيام بذلك.",
  "isales.datasourcemanagement.field.backdbIp" : "قاعدة البيانات الاحتياطية IP أو الاسم المستعار",
  "isales.tasklogic.message.confirmBatchUpdate" : "ينطبق تعديل الحزمة على المناطق المحددة فقط. هل أنت متأكد من رغبتك في المتابعة؟",
  "isales.statistics.business.month" : "الشهر",
  "isales.taskpolicy.message.obOverTimeRange" : "مدة مهلة المعاينة خارج النطاق: من 9 إلى 120. يُرجى تغيير مدة مهلة المعاينة أولاً",
  "isales.task.promotion.tobesent" : "الرسائل التي سيتم إرسالها",
  "isales.work.title.busiFail" : "فشل الأعمال",
  "isales.tasktemplate.message.nameDuplicated" : "يوجد بالفعل قالب مكالمة صادرة بنفس الاسم",
  "isales.taskresult.title.trainingQuestion" : "أسئلة اختبار التدريب",
  "isales.tasklogic.message.busiresultpolicyNotsaved" : "لا يتم حفظ بعض سياسات نتائج الأعمال",
  "isales.statistics.field.addHisDetail" : "لا: يتم جمع بيانات {0} أشهر. نعم: يتم جمع بيانات {1} أشهر",
  "isales.datasourcemanagement.field.backdbId" : "رقم مصدر البيانات البديل",
  "isales.file.title.username.placeholder" : "أدخل اسم المستخدم لتسجيل الدخول إلى الخادم",
  "isales.management.title.timeImport" : "تكوين استيراد مجدول",
  "isales.taskinfo.message.nameExists" : "يجب أن يكون اسم مهمة المكالمة الصادرة فريدًا",
  "isales.task.promotion.message.monthGtThree" : "يمكن الاستعلام عن نتائج ثلاثة أشهر فقط",
  "isales.common.message.choose" : "يرجى اختيار البيانات.",
  "isales.import.filter.title.callDay" : "النشاط الأخير (أيام)",
  "isales.agent.label.deleteCtiFailed" : "فشل حذف البيانات",
  "isales.manual.field.callflag.inprocess" : "الاتصال",
  "isales.exportdata.title.exportresult" : "نتيجة التصدير",
  "isales.result.resultCode" : "رمز السبب",
  "isales.language.label.zh_CN" : "الصينية",
  "isales.file.title.ip.placeholder" : "أدخل عنوان IP الخاص بالخادم",
  "isales.button.search" : "الاستفسارات",
  "isales.taskmanagment.title.open.obsMonitor" : "مراقبة بيانات OBS",
  "isales.tasktemplate.field.release" : "نشر",
  "isales.file.title.userNameOrAK" : "اسم المستخدم/AK",
  "isales.tasklogic.field.callOrderType.noOrder" : "مكالمة غير مرتبة",
  "isales.notification.receiver.delete.error" : "لم يتم تحديد أية بيانات",
  "isales.data.prop.inputPropCode" : "أدخل رمز سمة",
  "isales.datasourcemanagement.field.dbPass2" : "تأكيد كلمة مرور مصدر البيانات",
  "isales.datasourcemanagement.field.dbPass" : "كلمة مرور مصدر البيانات",
  "isales.task.promotion.sendStatus" : "حالة الإرسال",
  "isales.statistics.business.byWeek" : "التقرير الأسبوعي",
  "ccsurvey.transfer.tip36" : "تعليق الدوران إلى استبيان تشغيل IVR",
  "isales.taskpolicy.message.saveFail" : "فشل تكوين سياسة المكالمات الصادرة",
  "ccsurvey.label.scenarioType.manualIvr" : "دليل مقابل. استبيان IVR",
  "isales.business.result.addchildren" : "إنشاء حالة فرعية",
  "isales.agent.button.agent.association" : "تكوين الموظف",
  "ccivr.ivr.ivrflow.type" : "النوع",
  "isales.tasklogic.label.primaryCondition" : "الشرط الرئيسي",
  "isales.tasklogic.field.policy" : "سياسة",
  "isales.task.message.startTips" : "انتهت المهمة. لا يمكن بدء مهمة المكالمة الصادرة",
  "isales.taskresultexport.message.result" : "النتيجة",
  "isales.task.promotion.whatsApp.channel.select" : "تحديد قناة WhatsApp",
  "isales.specist.message.updateFail" : "فشل التعديل",
  "isales.specialday.message.dateEmpty" : "التاريخ الخاص فارغ",
  "isales.agent.calledroute.exception" : "يرجى التحقق من حالة Redis أو تكوين CC-Gateway",
  "isales.file.title.rule.interval" : "عمود الفاصل الزمني للمكالمة",
  "isales.taskmanagement.title.viewTaskResult" : "نتيجة المهمة",
  "isales.taskinfo.message.batchEditCallLogic.CallPolicyNotification" : "تبقى الجولات الحالية دون تغيير، وستتم إضافة الجولات الجديدة بجوار الجولات الحالية",
  "isales.task.promotion.whatsAppChannel" : "قناة WhatsApp",
  "isales.task.promotion.dndTime" : "فترة عدم الإزعاج",
  "isales.agent.calledroute.editfail" : "فشل تحرير البيانات المحلية",
  "isales.taskresult.field.failsBeingchanged" : "يتغير الطرف المطلوب ويتم إعادة توجيه المكالمة",
  "isales.taskcalllogic.title.retryPolicySet" : "سياسة المكالمات التجريبية",
  "isales.manualData.message.pickUpSuccess" : "تم الاستخراج بنجاح",
  "isales.agent.message.curcountpasswordWrong" : "كلمة مرور الحساب الحالي غير صحيحة",
  "isales.specist.title.misjudgment" : "خطأ في الحكم",
  "isales.agent.button.cancel" : "إلغاء",
  "isales.busi.result.message.systemparams.fail" : "فشل الاستعلام عن معلمات النظام",
  "isales.agent.calledroute.require" : "تعيين كافة الحقول الإلزامية",
  "isales.taskmanagement.pauseTask" : "إيقاف المهمة مؤقتًا",
  "isales.tasklogic.field.selectProp" : "إضافة سمة",
  "isales.specialday.title.date" : "تاريخ خاص",
  "isales.data.import.task.status.ready" : "التحضير",
  "isales.statistics.business.nomorethanerror" : "لا يمكن أن يكون التاريخ المحدد قبل {0}",
  "isales.rbtresult.field.calledbusy" : "الطرف المطلوب مشغول، وقام الطرف المطلوب بتسجيل وظيفة انتظار المكالمات",
  "isales.taskresult.field.failsInvalidRoutingDevice" : "جهاز توجيه غير صالح",
  "isales.taskresult.title.chooseEndTime" : "الرجاء تحديد وقت الانتهاء",
  "isales.agent.label.maxWaitNum" : "الحد الأقصى للمكالمات في قائمة الانتظار",
  "isales.manual.message.not.call" : "لم يتم إجراء مكالمة صادرة",
  "isales.taskpolicy.field.maxAvgCallTime" : "الحد الأقصى لمدة المكالمة ",
  "isales.management.message.SelectNumberMax" : "حدد ما يصل إلى 100 رقم اتصال في وقت واحد",
  "isales.tasklogic.field.value" : "القيمة",
  "isales.taskmanagement.title.taskDataVolume" : "حجم بيانات المهمة",
  "isales.agent.calledroute.nmsconnect.fail" : "فشل الاتصال بـ ODFS",
  "isales.datasourcemanagement.field.dsType.mysql" : "MYSQL",
  "isales.label.appraise.call.quality.customer" : "العميل",
  "isales.exportdata.message.nodata" : "لا يمكن تصدير أي بيانات تستوفي الشروط!",
  "isales.management.message.batchEditCallLogic.notSupportBusiresultInCMS" : "لا يمكن تكوين نُهج نتائج الأعمال للمكالمات الصادرة التلقائية. هل تريد بالتأكيد إجراء تعديل الحزمة؟",
  "isales.taskTime.message.saveSuccess" : "تم تغيير وقت المكالمة الصادرة بنجاح",
  "isales.taskresult.field.failsAutomaticdevicedetected" : "تم الكشف عن الأجهزة التلقائية",
  "isales.taskinfo.title.chooseSmsTemplateQuery" : "تحديد قالب SMS",
  "isales.agent.calledroute.ccivrdata.error" : "فشل مزامنة ODFS، بيانات عملية CC-IVR غير صحيحة",
  "isales.agent.pageurls.errorsave" : "خطأ",
  "isales.agent.calledroute.placeholder.accesscode" : "يُرجى تحديد رمز الوصول",
  "isales.datasourcemanagement.message.addDataSourceFail" : "فشل إنشاء مصدر بيانات جديد",
  "isales.file.title.rule.desc" : "الوصف",
  "isales.taskinfo.field.callTimes" : "محاولات الاتصال",
  "isales.tasklogic.field.orderPolicy" : "فرز المكالمات",
  "isales.management.title.adjustment.modifier" : "تم ضبطه بواسطة",
  "isales.specialday.message.confirmDelete" : "هل تريد بالتأكيد حذف البيانات؟",
  "isales.datasourcemanagement.field.dsType.oracle" : "أوراكل",
  "isales.taskmanagement.title.startConfirm" : "هل تريد بالتأكيد بدء مهمة المكالمة الصادرة المحددة؟",
  "isales.common.title.dataSearch" : "البحث",
  "isales.task.benginEndTime" : "وقت البدء والانتهاء",
  "isales.appointment.title.soontimeout" : "وقت الحجز على وشك الوصول",
  "isales.manual.title.sendEmail" : "إرسال بريد إلكتروني",
  "isales.manual.message.taskstatus.error" : "المكالمة الصادرة ليست في الحالة تنفيذ",
  "isales.rbtresult.field.transfer" : "إعادة توجيه المكالمات",
  "isales.tasklogic.message.no.numbertype" : "الرقم التسلسلي للرقم خالٍ.",
  "isales.business.result.delete.data.error" : "يمكن حذف 100 سجل كحد أقصى في المرة الواحدة",
  "isales.taskmanagement.title.batchEditCallPolicy.manualError" : "لا يمكن تعديل نُهج المكالمات الصادرة يدويًا",
  "isales.taskmanagement.message.period.seven" : "لا يمكن أن يكون الفاصل الزمني أطول من 7 أيام",
  "isales.appointment.title.editAppoint" : "تعديل مكالمة صادرة محجوزة",
  "isales.result.outgoingCall.input.description" : "أدخل رمز نتيجة المكالمة الصادرة أو وصفها",
  "isales.taskmanagement.title.taskTime" : "وقت المهمة",
  "isales.taskmanagement.title.batchEditTaskInfo.errStatus" : "تحديد مهمة في حالة المسودة\، أو الإيقاف\، أو التنفيذ",
  "isales.validate.message.notSpecial" : "لا يمكن أن يحتوي الاسم على أحرف خاصة",
  "isales.taskmanagement.field.basicTaskInfo" : "معلومات أساسية",
  "isales.taskinfo.field.smsTemplate" : "قالب SMS لمكالمة صادرة فاشلة",
  "isales.taskmanagement.title.batchEditCallTime" : "تغيير دفعة وقت المكالمة الصادرة",
  "isales.task.promotion.message.variableIsNull" : "قائمة تكوين السمات فارغة",
  "isales.taskresult.title.callResult" : "النتيجة",
  "isales.specialday.field.Monday" : "الاثنين",
  "isales.taskstatistics.field.specialCall" : "قائمة خاصة",
  "isales.task.message.startWarning" : "جاري تصدير البيانات...لا يمكن بدء تشغيل مهمة المكالمة الصادرة",
  "isales.taskmanagment.title.open.agentMonitor" : "مراقبة الموظف",
  "isales.taskmanagement.field.taskStatus" : "حالة المهمة",
  "isales.notification.variable.name" : "اسم المتغير",
  "isales.taskresult.title.operationTime" : "تشغيل الوقت",
  "isales.appointment.message.callSuccess" : "ناجح",
  "isales.agent.toblocklist.addType" : "إضافة نوع blockList",
  "isales.agent.label.bindTheSkill" : "تم ربط تكوين الطرف المطلوب. قم بإلغاء ربطه أولاً",
  "isales.taskresultexport.message.downloadRedischeckfailinfo" : "يمكن تنزيل الملف مرة أخرى فقط عند اكتمال التنزيل السابق",
  "isales.task.message.pauseSuccess" : "تم تعليق مهمة المكالمة الصادرة بنجاح",
  "isales.calledroute.title.selectaccesscode" : "اختر رمز وصول",
  "isales.agent.label.theAgent" : "الوكيل",
  "isales.task.promotion.isRetry" : "إعادة المحاولة عند الفشل",
  "isales.rbtresult.field.linefaulty" : "فشل الخط في اتجاه المشغلين الآخرين",
  "isales.manual.message.confirmReleaseData" : "هل تريد بالتأكيد تحرير بيانات المكالمة الصادرة؟",
  "isales.manual.exit.preview" : "تنفيذ معاينة مكالمة صادرة",
  "isales.taskresultexport.title.success" : "ناجح",
  "isales.common.message.checknonnegativeinteger" : "أدخل عددًا صحيحًا غير سالب بالتنسيق الصحيح",
  "isales.taskmanagement.title.deleteFailed" : "فشل حذف المهمة",
  "isales.blacklist.message.error" : "يحدث خطأ عند إضافة الرقم إلى قائمة الحظر",
  "isales.agent.message.modifySkillFail" : "فشل تحديث قائمة انتظار المهارات",
  "isales.manual.task.survey.noAnswer" : "لا توجد إجابة مطلوبة",
  "isales.statistics.business.byMonth" : "التقرير الشهري",
  "isales.task.promotion.statusStart" : "مهمة تسويق الوسائط المتعددة قيد التشغيل",
  "isales.calltime.title.holidayType" : "نوع",
  "isales.agent.calledroute.devicetype" : "نوع المعدات",
  "isales.manual.field.callinginformation" : "معلومات المكالمة",
  "isales.training.completeSamples" : "حجم العينة المكتملة",
  "isales.taskInfo.title.quotas" : "تحقيق النتيجة",
  "isales.task.promotion.createBeginTime" : "وقت بدء الإنشاء",
  "isales.appointment.message.updateSuccess" : "تم تعديل المكالمة الصادرة المحجوزة بنجاح",
  "isales.taskresult.field.failsCallTimeout" : "انتهت مهلة المكالمة",
  "isales.taskresult.field.failsDnd" : "تم رفض المكالمة مؤقتًا",
  "isales.taskmanagement.title.batchPause.statusError" : "لا يمكن تعليق المهام في حالة المسودة أو منتهية الصلاحية",
  "isales.agent.calledroute.extlengthcodewarn" : "لا يمكن أن يتجاوز طول رمز الإضافة 12",
  "isales.specialday.message.deleteFail" : "فشل حذف التاريخ الخاص",
  "isales.taskstatistics.field.connectedCallsPie" : "عدد المكالمات المتصلة",
  "isales.datasourcemanagement.field.editDataSource" : "تحرير مصدر البيانات",
  "isales.input.message.large" : "قيمة الإدخال كبيرة جدًا",
  "isales.taskresult.field.failsSwitchRestart" : "تمت إعادة تشغيل المحول",
  "isales.datasourcemanagement.message.addDataSourceSuccess" : "تم إنشاء مصدر البيانات بنجاح",
  "isales.marketing.mode" : "وضع التسويق",
  "isales.manual.title.viewManualResult" : "نتيجة مكالمة صادرة عن الموظف-",
  "isales.data.title.more" : "المزيد",
  "isales.blacklist.message.calledno.exist" : "الرقم موجود في قائمة الحظر",
  "isales.taskinfo.title.chooseCommonIvrFlow" : "تحديد زر الروبوت",
  "isales.taskresult.field.failsTransferunconditionally" : "تتم إعادة توجيه المكالمة دون قيد أو شرط",
  "isales.taskinfo.placeholder.choose" : "--اختر--",
  "isales.notification.variable.defvalue" : "القيمة الافتراضية",
  "isales.taskresult.field.failsTimeout" : "انتهت مهلة النظام",
  "isales.taskmanagement.field.intelligence" : "مكالمة خارجية ذكية",
  "isales.datasourcemanagement.title.inputVdnId" : "أدخل VDNID",
  "isales.import.message.endtime" : "وقت الانتهاء",
  "isales.taskpolicy.field.utilRateCalculateOne" : "استخدام الوكيل = إجمالي مدة المكالمة/إجمالي مدة تسجيل الدخول",
  "isales.specialday.message.createFail" : "فشل إنشاء التاريخ الخاص",
  "isales.import.message.begintime" : "وقت البدء",
  "isales.task.message.numberEffFeedback" : "عدد مكالمات الملاحظات الصالحة",
  "isales.agent.label.skills" : "قائمة انتظار المهارات",
  "isales.datasourcemanagement.field.viewDataSource" : "عرض مصدر البيانات",
  "isales.specialday.field.Saturday" : "السبت",
  "isales.taskpolicy.field.obCallCount" : "عدد المكالمات الصادرة في المرة الواحدة",
  "isales.tasklogic.field.prop" : "الدعامة",
  "isales.agent.label.noAgents" : "غياب وكيل في الخدمة",
  "isales.taskmanagement.title.reset" : "إعادة الضبط",
  "isales.task.promotion.tempalteError" : "قالب إعلام التسويق لا يتطابق مع نوع القالب",
  "isales.data.prop.numberType" : "أرقام",
  "isales.taskmanagement.field.callCompletedNum" : "المكالمات المكتملة",
  "isales.management.message.coverMode" : "الكتابة فوق",
  "isales.data.prop.propName" : "اسم السمة",
  "isales.management.placeholder.surveyName" : "أدخل اسم الاستبيان",
  "isales.taskstatistics.field.numberOfUnconnectedCalls" : "مكالمات غير متصلة",
  "isales.tasklogic.field.and" : "و",
  "isales.work.title.selectAgent" : "تحديد موظف",
  "isales.management.title.adjustment" : "ضبط",
  "isales.export.message.selectToomach" : "يمكن اختيار 10 سجلات كحد أقصى",
  "isales.agent.calledroute.create" : "إضافة التكوين المطلوب",
  "isales.taskresult.field.failsBusytone" : "نغمة مشغول",
  "isales.file.server.message.checkObsPath" : "يُسمح فقط بالأرقام \، والحروف \، والواصلات (-) \، والنقاط (.)",
  "isales.training.AssociatedSurvey" : "استبيان مرتبط",
  "isales.data.prop.isPushAgent" : "ادفع إلى الوكيل",
  "isales.statistics.business.endWeek" : "نهاية الأسبوع",
  "isales.management.message.import.mapRule" : "قاعدة التعيين",
  "isales.business.result.description" : "وصف نتيجة الأعمال",
  "isales.agent.Transfer.devicetype" : "نوع الجهاز المستهدف",
  "isales.training.answerNumber" : "عدد الإجابات",
  "isales.appointment.message.deleteSuccess" : "تم حذف المكالمات الصادرة المحجوزة بنجاح",
  "isales.manual.title.lock" : "قفل",
  "isales.data.prop.anonymType" : "التشفير",
  "isales.task.promotion.message.dndTimeltCrossExpectedTime" : "لا يمكن أن يكون وقت الإرسال المتوقع في فترة عدم الإزعاج",
  "isales.file.message.rule.receiver.columnRepeat" : "لا يمكن أن يكون عمود المستلم هو نفسه عمود السمة",
  "isales.management.message.updateFail" : "فشل تعديل بيانات المكالمة الصادرة",
  "isales.taskinfo.title.chooseIvrFlow" : "تحديد تدفق IVR",
  "isales.common.title.ready" : "جاهز",
  "isales.result.addOutgoingCallResult" : "إضافة نتيجة",
  "isales.task.promotion.updateFailed" : "فشل تعديل مهمة تسويق الوسائط المتعددة",
  "isales.tasklogic.field.addCallnoLogic" : "إضافة سياسة جهة الاتصال",
  "isales.calltime.title.spec.date" : "تاريخ خاص",
  "isales.notification.receiver.deletetips" : "هل تريد بالتأكيد حذف البيانات المحددة؟",
  "isales.taskTime.message.saveFail" : "فشل تغيير وقت المكالمة الصادرة",
  "isales.taskresult.title.queryTime" : "معلومات",
  "isales.manual.message.afterNow" : "لا يمكن أن يكون وقت الانتهاء قبل الوقت الحالي",
  "isales.datasourcemanagement.field.backdbSchema" : "اسم مصدر البيانات البديل",
  "isales.agent.label.skillName" : "اسم قائمة انتظار المهارة",
  "isales.sendStatus.field.fail" : "فشل",
  "isales.taskstatus.field.finish" : "منتهية الصلاحية",
  "isales.tasklogic.field.neq" : "!=",
  "isales.statistics.field.touchRateDescription" : "معدل الوصول",
  "isales.taskinfo.message.batchEditCallLogic.CallResultNotification" : "يمكن تعديل النُهج الحالية، وستتم إضافة نُهج جديدة بجوار النُهج الحالية",
  "isales.data.title.create" : "جديد",
  "isales.task.promotion.whatsApp.variable.content" : "المعلمة المحتوى",
  "isales.taskmanagement.title.taskContent" : "محتوى المهمة",
  "isales.busi.result.item.fail" : "فشل",
  "isales.file.title.rule.property.name" : "اسم السمة",
  "isales.task.promotion.saveSuccess" : "تم حفظ مهمة تسويق الوسائط المتعددة بنجاح",
  "isales.import.message.status" : "الحالة",
  "isales.taskpolicy.field.lossRate" : "الحد الأدنى لمعدل فقدان المكالمة",
  "isales.work.title.multimediaManage" : "التسويق متعدد الوسائط إدارة",
  "isales.rbtresult.field.calledbusycalling" : "الطرف المطلوب مشغول، ولم يقم الطرف المطلوب بتسجيل وظيفة انتظار المكالمات",
  "isales.taskinfo.field.taskType" : "نوع المهمة",
  "isales.taskresultexport.title.exported" : "ليتم تصديرها",
  "isales.notification.receiver.number.placeholder" : "أدخل رقم المستلم",
  "isales.taskcalllogic.message.callNoPolicyEmpty" : "إضافة سياسة رقم واحدة على الأقل",
  "isales.specialday.message.weeklyFormatError" : "تنسيق فترة التاريخ الخاص غير صحيح",
  "isales.training.averageScore" : "متوسط الدرجات",
  "isales.result.view" : "عرض سبب فشل المكالمة الصادرة",
  "isales.tasklogic.field.callresultpolicy" : "نهج نتيجة المكالمات الصادرة",
  "isales.taskpolicy.field.previewTimeoutInterval" : "الفاصل الزمني لوقت المعاينة (ثانية)",
  "isales.agent.calledroute.delfail" : "فشل حذف البيانات المحلية",
  "isales.management.message.deleteConfirm" : "هل تريد بالتأكيد حذف بيانات المكالمة الصادرة المحددة؟",
  "isales.data.propConfig.title.isPushAgent" : "ادفع إلى الوكيل",
  "isales.work.title.calledNum" : "المكالمات التي تمت معالجتها",
  "isales.datasourcemanagement.field.dbUserName" : "اسم مستخدم مصدر البيانات",
  "isales.audio.title.calltype" : "نوع الاتصال",
  "isales.agent.calledroute.extcode" : "نشر التعليمات البرمجية",
  "isales.manual.message.recipient" : "إدخال مستلم",
  "isales.taskstatistics.field.numberOfConnectedCalls" : "المكالمات المتصلة",
  "isales.appointment.message.deleteFail" : "فشل حذف المكالمات الصادرة المحجوزة",
  "isales.taskcalllogic.field.callnoPolicy" : "سياسة الإتصال",
  "isales.taskinfo.title.commonIvrFlow" : "مفتاح Chatbot",
  "isales.specialday.field.Thursday" : "الخميس",
  "isales.management.message.calledNoTwo" : "الرقم المطلوب الثاني",
  "isales.appointment.title.call" : "مكالمة",
  "isales.taskinfo.field.specifiedAgent" : "الوكيل المحدد",
  "isales.manual.title.calledNo" : "رقم الصادر",
  "isales.agent.button.createComplete" : "إنهاء",
  "isales.task.message.completionRate" : "معدل إنجاز المهمة",
  "isales.manual.message.dataId.Empty" : "لا يمكن أن يكون dataId فارغًا",
  "isales.taskresultexport.title.progress" : "تصدير",
  "isales.tasklogic.message.busiresultpolicyNotSaved" : "احفظ سياسة نتائج الأعمال الحالية أولاً!",
  "isales.audio.title.audiorate" : "معدل التشغيل",
  "isales.taskstatistics.placeholder.called" : "يسمى: {0}",
  "isales.taskresult.field.failsResultnotobtained" : "تتم إعادة كتابة بيانات المكالمة الصادرة عند عدم الحصول على أي نتيجة بعد انتهاء مهلة المكالمة",
  "isales.task.message.NoStartTask" : "لا تتوفر أي مهمة تستوفي الشروط",
  "isales.agent.calledroute.ivr" : "الرجاء تحديد IVR",
  "isales.result.addAgentTips" : "يمكن إضافة 20000 عامل كحد أقصى",
  "isales.agent.message.pwdRepeat" : "كلمة المرور المقدمة هي نفس كلمة المرور التي تم تغييرها مؤخرًا",
  "isales.agent.calledroute.recordexist" : "رمز الوصول ورمز الامتداد مرتبطان بالفعل، يرجى إدخال رمز الامتداد الجديد",
  "isales.import.message.uploadedfileformatvalidate" : "تنسيق ملف غير صحيح. تحديد ملف CSV",
  "isales.busi.result.message.agentstatus.fail" : "فشل الاستعلام عن حالة الموظف",
  "isales.taskstatistics.field.failCode" : "سبب الفشل",
  "isales.appointment.message.createFail" : "فشل إنشاء مكالمة صادرة محجوزة",
  "isales.taskinfo.placeholder.input" : "--أدخل--",
  "isales.agent.calledroute.updatecalledroute" : "تحرير",
  "isales.management.message.serverImport" : "استخدام الخادم",
  "isales.tasklogic.field.logic" : "المشغل",
  "isales.agent.query.error" : "لا يمكن أن يكون العامل المستهدف هو نفسه المنفذ للسجل المحدد",
  "isales.audio.title.recording" : "معاينة التسجيل",
  "isales.taskmanagement.title.tip.batchEditTask" : "تحديد المهمة المراد تعديلها",
  "isales.task.message.successRate" : "معدل النجاح",
  "isales.task.title.callBack" : "رفض",
  "isales.tasktemplate.title.autoTemplate" : "قالب مكالمة صادرة تلقائية",
  "isales.taskresult.title.exportQuestion" : "تصدير سؤال",
  "isales.business.name" : "الاسم",
  "isales.datasourcemanagement.message.passValidateFailed" : "يجب أن تحتوي القيمة على ثلاثة أنواع على الأقل من الأحرف التالية: أحرف كبيرة\، وأحرف صغيرة\، وأرقام\، وأحرف خاصة (`-!@#$^&*()-_+|[{}]<.>/?). يتراوح الطول من 8 إلى 128",
  "isales.result.addResultMaptips" : "يمكن إضافة 50 نتيجة مكالمة صادرة بحد أقصى",
  "isales.taskmanagement.title.importData.selectFile" : "تحديد ملف",
  'isales.business.result.message.checkCodePoint': 'يمكن إدخال الأرقام والحروف و- فقط.',
  'isales.import.calldata.selecttime': 'حدد وقت التنفيذ.',
  'isales.statistics.message.chooseCondition': 'الرجاء تحديد شروط الاستعلام أولاً',
  'isales.taskpolicy.field.time': '(ثانية)',
  'isales.taskInfo.title.filterSubCondition': 'تصفية الشروط الفرعية',
  'isales.agent.work.statistics.tableTips': 'اولین وظیفه تماس خروجی یا عاملی که می خواهید مشاهده کنید را انتخاب کنی',
  'isales.file.message.rule.add.success': 'قانون جدید با موفقیت ایجاد شد',
  'isales.file.title.server.add.success': 'اطلاعات سرور جدید موفقیت آمیز بود',
  'isales.management.title.selectResult': 'انتخاب نتایج تماس خروجی',
  'isales.specialday.placeholder.year.beginTime': 'فرمت: MM-DD، برای مثال: 12-01',
  'isales.management.message.appointTime': 'لا يمكن أن يكون وقت المكالمة المحجوز قبل وقت النظام الحالي',
  'isales.management.message.intelligence.taskName': 'لا يمكن أن يتجاوز طول اسم مهمة نوع الاتصال الصادر للروبوت الذكي 36 بايت. يشغل حرف واحد أو رقم واحد بايتًا واحدًا، ويشغل حرف صيني واحد ثلاثة بايتات.',
}