import isales_en_US from './isales_en_US'
import isales_es_ES from './isales_es_ES'
import isales_pt_BR from './isales_pt_BR'
import isales_fr_FR from './isales_fr_FR'
import isales_th_TH from './isales_th_TH'
import isales_zh_CN from './isales_zh_CN'
import Isales_fa_IR from "./isales_fa_IR"
import Isales_ar_SA from "./isales_ar_SA"
import Isales_de_DE from "./isales_de_DE";

const isales = {
    en_US: {
        ...isales_en_US
    },
    es_ES: {
        ...isales_es_ES
    },
    pt_BR: {
        ...isales_pt_BR
    },
    fr_FR: {
        ...isales_fr_FR
    },
    th_TH: {
        ...isales_th_TH
    },
    zh_CN: {
        ...isales_zh_CN
    },
    fa_IR: {
        ...Isales_fa_IR
    },
  ar_SA: {
    ...Isales_ar_SA
  },
    de_DE: {
        ...Isales_de_DE
    }
}

export default isales