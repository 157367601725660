import i18n from '@/lang/index'

const $t = i18n.global.t

export const RETURN_CODE = {
    SUCCESS: '0',
    FAIL: '1',
    FAIL_EXIT: '2',
    WARNING: '3',
    TIPS: '4',
    NOCOUNT: '5',
    DUPLICATED: '100'
}

export const STATUS = {
    DRAFT: '1',
    EXECUTE: '2',
    SUSPEND: '3',
    FINISH: '4',
    CALL_FINISH: '7',
    WAITING: '8'
}

export const TASK_TYPE_ENUM = [
    {
        'value': '4',   //当类型ID为4时，外呼类型为手动外呼
        'label': $t('isales.taskmanagement.field.manual')
    },
    {
        'value': '2',   //当类型ID为2时，外呼类型为预测外呼
        'label': $t('isales.tasktype.field.predict')
    },
    {
        'value': '5',   //当类型ID为5时，外呼类型为预览外呼
        'label': $t('isales.tasktype.field.preview')
    },
    {
        'value': '6',   //当类型ID为6时，外呼类型为预占外呼
        'label': $t('isales.tasktype.field.preemption')
    },
    {
        'value': '3',   //当类型ID为3时，外呼类型为自动外呼
        'label': $t('isales.tasktype.field.auto')
    },
    {
        'value': '1',   //当类型ID为1时，外呼类型为智能外呼
        'label': $t('isales.tasktype.field.intelligent')
    },
]

export const TASK_TYPE_ENUM_OP = [
    {
        'value': '2',   //当类型ID为2时，外呼类型为预测外呼
        'label': $t('isales.tasktype.field.predict')
    },
    {
        'value': '5',   //当类型ID为5时，外呼类型为预览外呼
        'label': $t('isales.tasktype.field.preview')
    },
    {
        'value': '6',   //当类型ID为6时，外呼类型为预占外呼
        'label': $t('isales.tasktype.field.preemption')
    },
    {
        'value': '3',   //当类型ID为3时，外呼类型为自动外呼
        'label': $t('isales.tasktype.field.auto')
    },
]

export const TASK_TYPE = {
    INTELLIGENT: '1', //智能
    PREDICT: '2', // 预测
    AUTO: '3', // 自动
    MANUAL: '4', // 人工
    PREVIEW: '5', // 预览
    PREEMPTION: '6' //预占
}

export const TASK_TYPE_MAP = {
    '1': $t('isales.tasktype.field.intelligent'),
    '2': $t('isales.tasktype.field.predict'),
    '3': $t('isales.tasktype.field.auto'),
    '4': $t('isales.taskmanagement.field.manual'),
    '5': $t('isales.tasktype.field.preview'),
    '6': $t('isales.tasktype.field.preemption')
}

export const TASK_STATUS_ENUM = [
    {
        'value': STATUS.DRAFT,   //状态为草稿
        'label': $t('isales.taskstatus.field.draft')
    },
    {
        'value': STATUS.EXECUTE,   //状态为执行中
        'label': $t('isales.taskstatus.field.execute')
    },
    {
        'value': STATUS.SUSPEND,   //状态为暂停
        'label': $t('isales.taskstatus.field.suspend')
    },
    {
        'value': STATUS.FINISH,   //状态为已完成
        'label': $t('isales.taskstatus.field.finish')
    },
    {
        'value': STATUS.CALL_FINISH,   //状态为呼叫完成：表征当天不再有外呼数据存在
        'label': $t('isales.taskstatus.field.callfinish')
    },
    {
        'value': STATUS.WAITING,   //状态为未开始： 当前任务begindate还没到
        'label': $t('isales.taskstatus.field.waiting')
    }
]

export const CALL_RESULT_ENUM = [
    {
        "value": "0000,0900",
        "label": $t('isales.taskresult.field.success')
    },
    {
        "value": "0101",
        "label": $t('isales.task.field.callFail')
    },
    {
        "value": "0601",
        "label": $t('isales.task.title.callBack')
    }
];
// 任务优先级
export const TASK_PRIORITY_TYPES_ENUM = [
    {
        "value": "1",
        "label": "1"
    },
    {
        "value": "2",
        "label": "2"
    },
    {
        "value": "3",
        "label": "3"
    },
    {
        "value": "4",
        "label": "4"
    },
    {
        "value": "5",
        "label": "5"
    }
]

export const OPERATOR = [
    {
        'value': 'eq',
        'label': $t('isales.tasklogic.field.eq')
    }, {
        'value': "neq",
        'label': $t('isales.tasklogic.field.neq')
    }, {
        'value': "gt",
        'label': $t('isales.tasklogic.field.gt')
    }, {
        'value': "ge",
        'label': $t('isales.tasklogic.field.egt')
    }, {
        'value': "lt",
        'label': $t('isales.tasklogic.field.lt')
    }, {
        'value': "le",
        'label': $t('isales.tasklogic.field.elt')
    }, {
        'value': "like",
        'label': $t('isales.tasklogic.field.like')
    }
];

export const LOGIC = [
    {
        'value': "and",
        'label': $t('isales.tasklogic.field.and')
    }, {
        'value': "or",
        'label': $t('isales.tasklogic.field.or')
    }
];

// OP营销模式
export let OP_MARKETING_MODES = new Map()
    .set(1, $t('isales.marketing.OutgoingCall'))

// saas营销模式
export const AICC_MARKETING_MODES =  OP_MARKETING_MODES
    .set(2, $t('isales.marketing.notification'))

export const DATA_IMPORT_RESULT_STATUS = new Map()
    .set('0', $t('isales.data.import.task.status.ready'))
    .set('1', $t('isales.data.import.task.status.executing'))
    .set('2', $t('isales.data.import.task.status.complete'))
    .set('3', $t('isales.data.import.task.status.error'))

export const PROP_TYPE = {
    '1': $t('isales.data.prop.stringType'),
    '2': $t('isales.data.prop.numberType'),
    '3': $t('isales.data.prop.anonymType')
}

export const yesOrNoItems = {
    '0': $t('isales.management.message.filterNo'),
    '1': $t('isales.management.message.filterYes')
}
export const DATA_FILTER = [
    {
        "key": 2,   //数据不过滤
        "value": $t('isales.management.message.filterNo')
    },
    {
        "key": 1,   //数据过滤
        "value": $t('isales.management.message.filterYes')
    },
];
export const IMPORT_TYPE = [
    {
        "value": 1,   //文件导入
        "label": $t('isales.management.message.fileImport')
    },
    {
        "value": 2,   //服务器导入
        "label": $t('isales.management.message.serverImport')
    },
];
export const IMPORT_MODE = [
    {
        "value": 1,   //追加模式
        "label": $t('isales.management.message.appendMode')
    },
    {
        "value": 2,   //覆盖模式
        "label": $t('isales.management.message.coverMode')
    },
];
export const DATA_ORDER = [
    {
        "key": 1,   //顺序导入
        "value": $t('isales.management.message.orderSequence')
    },
    {
        "key": 2,   //逆序导入
        "value": $t('isales.management.message.orderReverse')
    },
];
export const ANALYSE_MODE = [
    {
        "value": 1,   //按模板
        "label": $t('isales.management.message.templateAnalyse')
    },
    {
        "value": 2,   //按规则
        "label": $t('isales.management.message.ruleAnalyse')
    },
];
export const CALL_DAY = [
    {
        "key": "3",
        "value": "3"
    },
    {
        "key": "5",
        "value": "5"
    },
    {
        "key": "7",
        "value": "7"
    },
    {
        "key": "15",
        "value": "15"
    },
    {
        "key": "30",
        "value": "30"
    }
];
export const MINUTE_ITEMS = [
    {
        "key": "00",   //整点
        "value": "00"
    },
    {
        "key": "30",   //半点
        "value": "30"
    },
];
export const NEED_DELETE_ITEMS = [
    {
        "key": 1,   //删除
        "value": $t('isales.management.message.filterYes')
    },
    {
        "key": 2,   //不删除
        "value": $t('isales.management.message.filterNo')
    }
];

export const MANUAL_TASK_TYPE_ENUM = [
    {
        'value': '4',   //当类型ID为4时，外呼类型为手动外呼
        'label': $t('isales.taskmanagement.field.manual')
    },
    {
        'value': '2',   //当类型ID为2时，外呼类型为预测外呼
        'label': $t('isales.tasktype.field.predict')
    },
    {
        'value': '5',   //当类型ID为5时，外呼类型为预览外呼
        'label': $t('isales.tasktype.field.preview')
    },
    {
        'value': '6',   //当类型ID为6时，外呼类型为预占外呼
        'label': $t('isales.tasktype.field.preemption')
    }
]

export const TEMPLATE_STATUS_ENUM = [
    {
        'value': '0',   //状态为全部
        'label': $t('isales.taskstatus.field.all')
    },
    {
        'value': '1',   //状态为草稿
        'label': $t('isales.taskstatus.field.draft')
    },
    {
        'value': '3',   //状态为暂停
        'label': $t('isales.taskstatus.field.suspend')
    },
    {
        'value': '9',   //状态为已发布
        'label': $t('isales.tasktemplate.field.released')
    }
]

export const SURVEY_TYPE_ENUM = [
    {
        'value': '1',   //人工问卷
        'label': $t('ccsurvey.label.scenarioType.manual')
    },
    {
        'value': '4',   //人工与IVR问卷
        'label': $t('ccsurvey.label.scenarioType.manualIvr')
    }
]