export default {
  "ccd.agent.webrtc.label.talk": "กำลังต่อสาย",
  "ccd.agent.webrtc.label.sotpsendvideo": "หยุดการส่งวิดีโอ",
  "ccd.agent.webrtc.label.offline": "ไม่ได้ลงทะเบียน",
  "ccd.agent.webrtc.label.videosize": "ขนาดของวิดีโอ",
  "ccd.agent.webrtc.label.medium": "กลาง",
  "ccd.agent.webrtc.label.missmic": "ไมโครโฟนไม่สามารถใช้งานได้",
  "ccd.agent.webrtc.label.connetfailed": "ล้มเหลว",
  "ccd.agent.webrtc.label.buildfailed": "ไม่สามารถตั้งค่าการเชื่อมต่อ",
  "ccd.agent.tips.success": "สำเร็จ",
  "ccd.agent.button.threeParty": "สามสาย",
  "ccd.agent.button.mute": "ปิดเสียง",
  "ccd.agent.button.twostage": "หน้าปัด",
  "ccd.agent.webrtc.label.large": "ใหญ่",
  "ccd.agent.button.notscreenShare": "ยกเลิกการแชร์เดสก์ท็อป",
  "ccd.agent.button.transfer": "โอน",
  "ccd.agent.webrtc.label.connect": "ลงทะเบียน",
  "ccd.agent.tips.confSuccess": "การกำหนดค่าสำเร็จ",
  "ccd.agent.tips.confFail": "การกำหนดค่าล้มเหลว",
  "ccd.agent.button.rest": "พัก",
  "ccd.agent.button.callOut": "โทรออก",
  "ccd.agent.webrtc.label.localvideo": "วิดีโอระยะใกล้",
  "ccd.agent.webrtc.label.online": "ลงทะเบียนแล้ว",
  "ccd.agent.webrtc.label.remotevideo": "วิดีโอระยะไกล",
  "ccd.agent.webrtc.label.unsupportbrower": "เบราว์เซอร์ไม่รองรับฟังก์ชันนี้",
  "ccd.agent.button.cancelRest": "ยกเลิกการพัก",
  "ccd.agent.button.restSkillQueue": "รีเซ็ตทักษะ",
  "ccd.agent.label.busy": "ไม่ว่าง",
  "ccd.agent.button.hold": "รอสาย",
  "ccd.agent.webrtc.label.small": "เล็ก",
  "ccd.agent.button.mediaswitch": "สลับ Audio/Video",
  "ccd.agent.button.sayFree": "ตั้งค่าว่าง",
  "ccd.agent.label.talk": "พูดคุย",
  "ccd.agent.button.endMute": "เปิดเสียง",
  "ccd.agent.webrtc.label.hidelocalvideo": "ซ่อนวิดีโอในเครื่อง",
  "ccd.agent.label.adjust": "สรุป",
  "ccd.agent.label.offline": "ออฟไลน์",
  "ccd.agent.button.screenShare": "การแชร์เดสก์ท็อป",
  "ccd.agent.message.AgentIsEmpty": "บัญชีปัจจุบันไม่ผูกกับเจ้าหน้าที่",
  "ccd.agent.button.innerHelp": "ปรึกษา",
  "ccd.agent.button.signOut": "ลงชื่อออก",
  "ccd.agent.button.signIn": "ลงชื่อเข้าใช้",
  "ccd.agent.webrtc.label.sippwd": "รหัสผ่าน Softphone",
  "ccd.agent.button.answer": "รับสาย",
  "ccd.agent.webrtc.label.videopop": "หน้าต่างวิดีโอ",
  "ccd.agent.message.AgentIsSupend": "เจ้าหน้าที่ปัจจุบันค้าง",
  "ccd.agent.label.idle": "ว่าง",
  "ccd.agent.button.sayBusy": "ตั้งค่าไม่ว่าง",
  "ccd.agent.tips.info": "เคล็ดลับ",
  "ccd.agent.button.getHold": "ยกเลิกการรอสาย",
  "ccd.agent.button.queryStatus": "สถานะการค้นหา",
  "ccd.agent.tips.error": "ข้อผิดพลาด",
  "ccd.agent.webrtc.label.misspeaker": "ลำโพงไม่พร้อมใช้งาน",
  "ccd.agent.label.rest": "พัก",
  "ccd.agent.button.hangUp": "วางสาย",
  "ccd.agent.message.agentNoConfSkill": "เจ้าหน้าที่ไม่ได้ถูกกำหนดค่าด้วยSkill Queue",
  "ccd.agent.label.reply": "ตอบกลับ",
  "ccd.agent.tips.welcome": "ยินดีต้อนรับกลับมาและมีความสุขในการทำงาน!",
  "ccd.agent.agentevent.success": "ความสําเร็จ",
  "ccd.agent.agentevent.failed": "ล้มเหลว",
  "ccd.agent.agentevent.warning": "คําเตือน",
  "ccd.agent.agentevent.info": "คําใบ้",
  "ccd.agent.agentevent.AgentState_Force_SetNotReady": "ตัวแทน ไม่ว่างสําเร็จ",
  "ccd.agent.agentevent.AgentState_CancelNotReady_Success": "ตัวแทน ไม่ว่างแล้ว",
  "ccd.agent.agentevent.AgentState_Ready": "ตัวแทนการบ่งชี้ว่าว่างสําเร็จ",
  "ccd.agent.agentevent.AgentState_CancelWork_Success": "ตัวแทน ออกจากสถานะการทํางานได้สําเร็จ",
  "ccd.agent.agentevent.AgentState_SetRest_Success": "ตัวแทน ส่วนที่เหลือสําเร็จ",
  "ccd.agent.agentevent.AgentState_CancelRest_Success": "ตัวแทน ยกเลิกส่วนที่เหลือสําเร็จ",
  "ccd.agent.agentevent.AgentState_SetWork_Success": "เอเย่นต์ เข้าสู่สถานะการทํางาน",
  "ccd.agent.agentevent.AgentState_Work": "เอเย่นต์ เข้าสู่สถานะการห่อ",
  "ccd.agent.agentevent.AgentState_Busy": "ตัวแทน กําลังทํางาน",
  "ccd.agent.agentevent.AgentEvent_Customer_Alerting": "โทรศัพท์ของผู้ใช้ดังขึ้น",
  "ccd.agent.agentevent.AgentEvent_Ringing": "สายเรียกเข้าของตัวแทน",
  "ccd.agent.agentevent.AgentEvent_Hold": "การโทรถูกจัดขึ้นเรียบร้อยแล้ว",
  "ccd.agent.agentevent.AgentEvent_Hold.desc": "ตัวแทน ถูกระงับไว้แล้ว",
  "ccd.agent.agentevent.AgentEvent_Talking": "เอเย่นต์เข้าสู่สถานะการพูดคุย",
  "ccd.agent.agentevent.AgentEvent_MediaSwith.success": "สวิตช์เสียงและวิดีโอสําเร็จ",
  "ccd.agent.agentevent.AgentEvent_MediaSwith.failed": "การสลับเสียง/วิดีโอล้มเหลว",
  "ccd.agent.agentevent.AgentMediaEvent_Play": "เริ่มการเล่นอัดเสียง",
  "ccd.agent.agentevent.AgentMediaEvent_PausePlayDone": "การเล่นบันทึกถูกระงับลงอย่างสําเร็จ",
  "ccd.agent.agentevent.AgentMediaEvent_ResumePlayDone": "การเล่นอักษรอักษรได้ดําเนินการต่ออีกครั้งสําเร็จ",
  "ccd.agent.agentevent.AgentMediaEvent_JumpPlayDone": "การอัดอัดได้รับการส่งต่ออย่างรวดเร็วหรือย้อนกลับได้สําเร็จ",
  "ccd.agent.agentevent.AgentMediaEvent_StopPlayDone": "การเล่นบันทึกหยุด",
  "ccd.agent.agentevent.AgentEvent_Conference": "การโทรสามฝ่ายสําเร็จ",
  "ccd.agent.agentevent.AgentEvent_Call_Out_Fail": "เอเย่นต์ไม่สามารถเริ่มต้นการโทรภายนอก",
  "ccd.agent.agentevent.AgentEvent_Inside_Call_Fail": "เอเย่นต์ไม่สามารถเริ่มต้นการโทรภายใน",
  "ccd.agent.agentevent.AgentEvent_Call_Release": "เอเย่นต์ ออกจากสาย",
  "ccd.agent.agentevent.AgentConf_SelfJoinResult": "ตัวแทนที่เข้าร่วมการประชุม",
  "ccd.agent.agentevent.AgentEvent_No_Answer": "ตัวแทนไม่รับสายเป็นเวลานาน",
  "ccd.agent.agentevent.AgentEvent_Customer_Release": "ลูกค้าออกจากการโทร",
  "ccd.agent.agentevent.AgentEvent_Auto_Answer": "คําตอบโดยอัตโนมัติของตัวแทน",
  "ccd.agent.agentevent.AgentEvent_Connect_Fail": "การเชื่อมต่อล้มเหลว",
  "ccd.agent.agentevent.AgentEvent_Consult_Fail": "ล้มเหลวในการปรึกษาตัวแทน",
  "ccd.agent.agentevent.AgentEvent_ReturnFromIvr": "เอเจนท์ได้รับการส่งคืนจาก IVR สําเร็จ",
  "ccd.agent.agentevent.AgentEvent_Preview_CallOut": "การดูตัวอย่างการโทรออกสําเร็จ",
  "ccd.agent.agentevent.AgentEvent_Preview": "เอเย่นต์ได้รับการร้องขอการดูตัวอย่างสําเร็จ",
  "ccd.agent.agentevent.AgentEvent_Agent_Occupyed": "เอเย่นต์ เตรียมไว้ล่วงหน้า",
  "ccd.agent.agentevent.AgentEvent_Release_Preview": "การโทรแสดงตัวอย่างถูกปล่อยออกมาเรียบร้อยแล้ว",
  "ccd.agent.agentevent.AgentOther_ShutdownService": "เอเย่นต์ ลงชื่อออกเรียบร้อยแล้ว",
  "ccd.agent.agentevent.AgentOther_InService": "เอเย่นต์ ลงชื่อเข้าใช้สําเร็จ",
  "ccd.agent.agentevent.AgentOther_PhoneAlerting": "เอเย่นต์ กําลังเรียก",
  "ccd.agent.agentevent.AgentOther_PhoneOffhook": "เอเย่นต์ ปิดตะขอ",
  "ccd.agent.agentevent.AgentOther_PhoneRelease": "เอเย่นต์ วางสาย",
  "ccd.agent.agentevent.AgentOther_PhoneUnknow": "ไม่ทราบสถานะโทรศัพท์ของตัวแทน",
  "ccd.agent.agentevent.AgentOther_All_Agent_Busy": "ตัวแทนทุกคนไม่ว่าง",
  "ccd.agent.agentevent.AgentOther_Restskill_Queue": "การดําเนินการคิวทักษะประสบความสําเร็จ",
  "ccd.agent.agentevent.callType": "ประเภทการโทร:",
  "ccd.agent.agentevent.callId": "ID การโทร:",
  "ccd.agent.agentevent.type.main": "สายหลัก",
  "ccd.agent.agentevent.type.assistant": "ความช่วยเหลือการโทร",
  "ccd.agent.agentevent.otherPhone": "หมายเลขโทรศัพท์/หมายเลขการทํางานของฝ่ายที่เรียกเข้า:",
  "ccd.agent.agentevent.feature.caller": "หมายเลขโทร:",
  "ccd.agent.agentevent.feature.called": "หมายเลขที่โทร:",
  "ccd.agent.agentevent.feature.callin": "สายเรียกเข้าทั่วไป",
  "ccd.agent.agentevent.feature.callout": "โทรออกทั่วไป",
  "ccd.agent.agentevent.mediaAblity": "ประเภทสื่อ:",
  "ccd.agent.agentevent.mediaAblity.voice": "เสียง",
  "ccd.agent.agentevent.mediaAblity.video": "วิดีโอ",
  "ccd.agent.agentevent.mediaAblity.voiceandvideo": "เสียงและวิดีโอ",
  "ccd.agent.agentevent.mediaAblity.text": "ข้อความ",
  "ccd.agent.agentevent.AgentEvent_Conference.partner": "หมายเลขผู้โทร:",
  "ccd.agent.agentevent.AgentEvent_No_Answer.number": "หมายเลขปาร์ตี้ค้าง:",
  "ccd.agent.agentevent.AgentEvent_Preview_CallOut.controlid": "แสดงตัวอย่าง ID บล็อกควบคุมการโทรออก:",
  "ccd.agent.agentevent.AgentEvent_Preview_CallOut.dialeddigits": "ดูตัวอย่างหมายเลขที่เรียกขาออก:",
  "ccd.agent.flash.calling": "สายเข้า",
  "ccd.agent.flash.message": "ข้อความใหม่",
  "ccd.agent.openeye.deamonready": "OpenEye เริ่มทำงานแล้ว",
  "ccd.agent.openeye.deamonready.failinfo": "การเริ่ม OpenEye ล้มเหลว",
  "ccd.agent.openeye.deamonready.fail": "ล้มเหลวในการเริ่มต้น OpenEye ลงทะเบียนและเข้าสู่ระบบ OpenEye ด้วยตนเอง มิฉะนั้นฟังก์ชันการตอบรับอัตโนมัติไม่สามารถใช้ได้",
  "ccd.agent.query.sip.fail": "การสืบค้นข้อมูล SIP ล้มเหลว ลงชื่อเข้าใช้ในฐานะเอเจนต์อีกครั้งหรือล็อกอินเข้าสู่ OpenEye ด้วยตนเอง",
  "ccd.agent.openeye.sipconfig.success": "กำหนดค่า SIP สำเร็จแล้ว",
  "ccd.agent.openeye.poolsipconfig.success": "กำหนดค่า POOL สำเร็จแล้ว",
  "ccd.agent.openeye.sipconfig.fail": "การกำหนดค่า SIP ล้มเหลว ลงชื่อเข้าใช้ในฐานะเอเจนต์อีกครั้งหรือล็อกอินเข้าสู่ OpenEye ด้วยตนเอง",
  "ccd.agent.openeye.poolsipconfig.fail": "การกำหนดค่า POOL ล้มเหลว ลงชื่อเข้าใช้ในฐานะเอเจนต์อีกครั้งหรือล็อกอินเข้าสู่ OpenEye ด้วยตนเอง",
  "ccd.agent.openeye.register": "หมายเลขซอฟต์โฟนได้รับการลงทะเบียนเรียบร้อยแล้ว",
  "ccd.agent.openeye.already.register": "หมายเลข softphone ได้ลงทะเบียนแล้ว",
  "ccd.agent.openeye.register.fail": "หมายเลขซอฟต์โฟนนี้ลงทะเบียนอยู่แล้วหรือมีเหตุผลอื่น",
  "ccd.agent.openeye.deregister": "ปิดใช้งานหมายเลขซอฟต์โฟนเรียบร้อยแล้ว",
  "ccd.agent.openeye.deregister.fail": "การปิดใช้งานหมายเลขซอฟต์โฟนล้มเหลว ออกจากระบบ OpenEye ด้วยตนเอง",
  "ccd.agent.openeye.autoanswer": "จะรับสายโดยอัตโนมัติ",
  "ccd.agent.openeye.autoanswer.fail": "รับสายโดยอัตโนมัติไม่สำเร็จ",
  "ccd.agent.openeye.videowindowparam": "เริ่มต้นพารามิเตอร์วิดีโอเรียบร้อยแล้ว",
  "ccd.agent.openeye.videowindowparam.fail": "ไม่สามารถเริ่มใช้งานพารามิเตอร์วิดีโอได้ ผลก็คือ ฟังก์ชั่นวิดีโอไม่ปกติ",
  "ccd.agent.openeye.videolayoutmode.success": "การตั้งค่าโหมดผังหน้าต่างวิดีโอสําเร็จ",
  "ccd.agent.openeye.videolayoutmode.fail": "ไม่สามารถตั้งโหมดเลย์เอาท์หน้าต่างวิดีโอ",
  "ccd.agent.openeye.videodisplaymode.success": "โหมดครอปหน้าต่างวิดีโอถูกตั้งค่าเรียบร้อยแล้ว",
  "ccd.agent.openeye.videodisplaymode.fail": "ล้มเหลวในการตั้งโหมดครอปหน้าต่างวิดีโอ",
  "ccd.agent.frontpage.callreason": "เหตุผลในการโทร",
  "ccd.agent.callrelease.end": "การโทรจบลง",
  "ccd.agent.resetphonepass.inputcur": "รหัสผ่านบัญชีปัจจุบัน",
  "ccd.agent.resetphonepass.input": "รหัสผ่าน",
  "ccd.agent.resetphonepass.inputagain": "ยืนยันรหัสผ่าน",
  "ccd.agent.openeye.share": "โปรดเลือก...",
  "ccd.agent.login.fail": "การลงทะเบียนล้มเหลว",
  "ccd.agent.login.failinfo": ", ปัจจุบันฟังก์ชันเช่นการค้นหาความช่วยเหลือภายในไม่พร้อมใช้งาน",
  "ccd.agent.login.multimedia.failinfo": ", ขณะนี้ฟังก์ชันเสียง/ ิดีโอ และความช่วยเหลือภายในไม่พร้อมใช้งาน",
  "ccd.agent.reset.success": "จองที่พักสําเร็จแล้ว",
  "ccd.agent.openeye.initsuccess": "เริ่มต้น OpenEye เรียบร้อยแล้ว",
  "ccd.agent.pagination.total": "รวมทั้งหมด:",
  "ccd.agent.title.consult": "ปรึกษา",
  "ccd.agent.warning.sharescreen": "ใส่ใจกับความเป็นส่วนตัวเมื่อแชร์พื้นที่ทํางาน",
  "ccd.agentmonitor.span.listening": "การฟัง",
  "ccd.agentmonitor.span.insert": "การแทรก",
  "ccd.agentmonitor.span.whisper": "กระซิบ",
  "ccd.message.title.audioIncomingCallNotify": "แจ้งเตือนเสียง",
  "ccd.message.title.mediaIncomingCallNotify": "แจ้งเตือนมัลติมีเดีย",
  "ccd.message.title.mediaNewMsgNotify": "การแจ้งเตือนข้อความใหม่สําหรับมัลติมีเดีย",
  "ccd.agentMonitor.label.switchlistenorinsert": "ในสถานะก่อนการตรวจสอบ เจ้าหน้าที่ที่ถูกกระซิบไม่สามารถคลิกปุ่มฟังหรือแทรกได้",
  "ccd.agent.agentevent.quality.calloutconnectfail": "ไม่สามารถโทรออกได้เนื่องจาก qc อยู่ระหว่างดําเนินการ",
  "ccd.agent.agentevent.calloutconnectfail": "สมาชิกไม่ว่างหรือหมายเลขไม่มีอยู่",
  "ccd.agent.tips.maxCountQuery.info": "ข้อมูลปัจจุบันมีขนาดใหญ่เกินไป ระบบจะแสดงข้อมูลล่าสุด {0} รายการเท่านั้น",
  "ccd.agent.warning.notification.cancel": "ยกเลิก",
  "ccd.agent.warning.notification.setting": "การตั้งค่า",
  "ccd.agent.warning.notification.allowed": "ต้องอนุญาตให้เว็บไซต์แสดงการแจ้งเตือนเพื่อรักษาความปลอดภัยการแจ้งเตือนข้อความเซสชัน!",
  "ccd.agent.msg.notification.allowed": "AICC ร้องขอให้เบราว์เซอร์แสดงการแจ้งเตือน!",
  "ccd.agent.msg.notification.permission.denied": "ผู้ใช้ปฏิเสธการอนุญาต ไม่สามารถแสดงการแจ้งเตือนได้ โปรดแก้ไขการตั้งค่าเบราว์เซอร์",
  "ccd.agent.msg.queryMsg.specialListQuery": "การแจ้งเตือนรายการพิเศษ",
  "ccd.agent.message.AgentIsProhibited": "ที่พักนี้ถูกห้ามไม่ให้ใช้งาน",
  "ccd.agent.message.repeatPhonenum": "หมายเลขโทรศัพท์ที่ใช้โดยตัวแทนในการลงชื่อเข้าใช้ได้ถูกใช้โดยตัวแทนอื่นในการลงชื่อเข้าใช้ ตรวจสอบการกําหนดค่า",
  "ccd.agent.message.destinationAgentFailed": "ไม่สามารถครอบครองตัวแทนปลายทางได้",
  "ccd.agent.message.noAgent": "ไม่มีเจ้าหน้าที่ประจําการ",
  "ccd.agent.warning.waserror": "ข้อผิดพลาดของทรัพยากร WAS ไม่สามารถเข้าถึงหรือสร้างข้อผิดพลาดภายในได้",
  "ccd.agent.button.offlineSignIn": "Shift มือถือ",
  "ccd.agent.button.offlineSignOut": "ออกจากมือถือ",
  "ccd.agent.button.close": "ยกเลิก",
  "ccd.agent.button.ok": "ได้ค่ะ",
  "ccd.quality.message.cancelwhisper": "หยุดหน้าต่าง Whisper",
  "ccd.agent.videocall.stateError": "สถานะการโทรไม่ถูกต้องเมื่อปรับโหมดการแสดงผลสำหรับการโทรแบบวิดีโอคอลสามสาย",
  "ccd.cccbar.label.backspace": "Backspace",
  "ccd.agent.videomode.triangular": "สามเหลี่ยม",
  "ccd.ccaction.message.holdError": "ไม่สามารถพักสายการโทรปัจจุบันได้",
  "ccd.agent.status.hold": "พักสาย",
  "ccd.agent.message.restInvalid": "พารามิเตอร์หรือที่อยู่ที่ถ่ายโอนผ่าน URL ไม่เป็นไปตามนิยาม",
  "ccd.transfer.message.inputIvrName": "ป้อน IVR สำหรับการโอน",
  "ccd.transfer.message.microsoftTeamsLogin": "ลงชื่อเข้าใช้",
  "ccd.transfer.message.inputTeamsUser": "ใส่บัญชีของทีม",
  "ccd.agent.videomode.maximizeThreeparty": "ขยายขนาดสามสาย",
  "ccd.ccaction.message.retCode": "เกิดข้อผิดพลาดในการยกเลิกงานเมื่อคุณตั้งค่าไม่ว่าง รหัสข้อผิดพลาด:",
  "ccd.agent.videocall.paramError": "พารามิเตอร์ผิดพลาดเมื่อปรับโหมดการแสดงผลของการโทรแบบวิดีโอคอลสามสาย",
  "ccd.transfer.label.queueSize": "ปริมาณการโทรเข้าคิวในปัจจุบัน",
  "ccd.transfer.label.queueSize.ccbar": "รอเข้าแถว",
  "ccd.ccaction.message.isForceBusy": "เจ้าหน้าที่แสดงสถานะไม่ว่าง ไม่จำเป็นต้องแสดงสถานะไม่ว่างซ้ำอีกครั้ง",
  "ccd.transfer.message.NoAgentInSkill": "ไม่มีเจ้าหน้าที่ออนไลน์อยู่ในลำดับทักษะปัจจุบัน",
  "ccd.cccbar.message.syserrormessage": "ระบบผิดพลาด",
  "ccd.agent.status.help": "ปรึกษา",
  "ccd.ccaction.message.logoutfailed": "การออกจากระบบล้มเหลว กรุณาตรวจสอบสถานะเจ้าหน้าที่",
  "ccd.ccaction.message.search.restCauseDesc": "ป้อนคำอธิบายเหตุผลที่เหลือ",
  "ccm.agent.message.selectIvr": "เลือก IVR flow",
  "ccd.quality.message.insert": "แทรก",
  "ccd.outgoing.out.workbench": "Workbench ขาออก",
  "ccd.transfer.label.skill": "คิวทักษะ",
  "ccd.transfer.button.refresh": "รีเฟรช",
  "ccd.agent.status.innerThree": "ไตรภาคี",
  "ccd.agent.message.threePartyNoCall": "ไม่มีการโทรให้คุณเริ่มการโทรแบบสามสาย",
  "ccd.innerhelp.label.innerHelpToSkill": "ขอคำปรึกษาจาก Skill Queue",
  "ccd.record.message.stopPlay": "เกิดสถานะผิดพลาดเมื่อหยุดเล่นย้อนหลัง",
  "ccd.transfer.label.loggedOnAgents": "ปริมาณเจ้าหน้าที่ออนไลน์",
  "ccd.transfer.label.loggedOnAgents.ccbar": "ตัวแทนที่มีอยู่",
  "ccd.agent.callout.notrest": "กรุณายกเลิกการพักและโทรออก",
  "ccd.transfer.label.ivr": "IVR",
  "ccd.ccaction.message.abilityError": "คุณไม่สามารถปิดเสียงผู้ใช้ในฟังก์ชันสื่อปัจจุบันได้",
  "ccd.agent.message.agentNotLogin": "เจ้าหน้าที่ไม่ได้ลงชื่อเข้าใช้",
  "ccd.agent.videocall.adjustvideo": "ปรับขนาดวิดีโอ",
  "ccd.agent.status.askAnswer": "รอการตอบกลับ",
  "ccd.transfer.label.agent": "เจ้าหน้าที่",
  "ccd.cccbar.label.voice": "เสียง",
  "ccd.cccbar.label.cancel": "ยกเลิก",
  "ccd.cccbar.label.send": "ส่ง",
  "ccd.agent.message.loginInvalidPara": "การลงชื่อเข้าใช้ล้มเหลว พารามิเตอร์การลงชื่อเข้าใช้ว่างเปล่าหรือไม่ถูกต้อง",
  "ccd.innerhelp.label.innerHelpToAgent": "ขอคำปรึกษาจากเจ้าหน้าที่",
  "ccd.callinner.label.callinner": "การโทรภายใน",
  "ccd.callinner.message.inputExNumhelp": "ปป้อนหมายเลขโทรศัพท์ภายใน",
  "ccd.agent.mediaswitch.unsupportedagenttype": "เจ้าหน้าที่ปัจจุบันไม่รองรับการโทรแบบวิดีโอคอล",
  "ccd.quality.message.whisper": "การกระซิบ",
  "ccd.ccaction.message.unHold": "ไม่มีการโทรที่ต้องพักสายในสถานะปัจจุบัน",
  "ccd.agent.title.warning": "การแจ้งเตือน",
  "ccd.cccbar.label.video": "วิดีโอ",
  "ccd.ccaction.message.errorRetCode": "ข้อผิดพลาด! รหัสข้อผิดพลาด:",
  "ccd.agent.message.mediaSwitchNoCall": "ไม่มีการโทรระหว่างการสลับเสียง / วิดีโอ",
  "ccd.agent.mediaswitch.switchaudiofailed": "อยู่ในการโทรด้วยเสียงแล้ว",
  "ccd.ccaction.message.recording": "สามารถเล่นวิดีโอได้เฉพาะเมื่อเจ้าหน้าที่ยู่ในสถานะว่าง กรุณาเปลี่ยนสถานะเจ้าหน้าที่",
  "ccd.record.message.resumePlay": "เกิดสถานะผิดพลาดเมื่อการเล่นย้อนหลังดำเนินต่อ",
  "ccd.transfer.label.successTransfer": "การโอนสำเร็จ",
  "ccd.ccaction.message.confJoinFail": "เจ้าหน้าที่ไม่ได้ลงชื่อเข้าใช้และการโทรสามสายล้มเหลว",
  "ccd.agent.message.noHoldCall": "การโทรไม่ถูกพักสาย",
  "ccd.agent.message.releaseNumberNoCall": "ไม่มีหมายเลขโทรศัพท์เฉพาะที่ต้องปล่อยสาย",
  "ccd.cccbar.label.total": "รวม:",
  "ccd.quality.message.forceexit": "บังคับให้ออกจากระบบ",
  "ccd.agent.videocall.noCall": "ไม่มีข้อผิดพลาดในการโทรเกิดขึ้นเมื่อปรับโหมดการแสดงผลของการโทรแบบวิดีโอคอลสามสาย",
  "ccd.innerhelp.label.twoPartyHelp": "การปรึกษาสองฝ่าย",
  "ccd.quality.message.interceptErr": "เจ้าหน้าที่ตรวจสอบไม่ได้อยู่ในสายและไม่สามารถดักฟังได้",
  "ccd.cccbar.label.callcenter": "ศูนย์บริการ",
  "ccd.quality.message.noAgentInfo": "ไม่พบข้อมูลเจ้าหน้าที่ในระหว่างการตรวจสอบการตรวจสอบติดตาม",
  "ccd.cccbar.message.pleaseAnswer": "รับสาย",
  "ccd.transfer.label.hangUpTransfer": "โอนสายการวาง",
  "ccd.agent.message.holdCallStatusErr": "เกิดสถานะการโทรผิดพลาดเมื่อคุณพักสาย",
  "ccd.agent.message.mediaSwitchFailed": "การสลับเสียง / วิดีโอล้มเหลว",
  "ccd.record.message.jumpBack": "เกิดสถานะผิดพลาดระหว่างการกรอกลับ",
  "ccd.agent.message.endMuteNoCall": "ไม่มีการโทรเมื่อคุณยกเลิกการปิดเสียง",
  "ccd.quality.message.forcebusy": "การบังคับให้ไม่ว่าง",
  "ccd.transfer.label.externalNumber": "หมายเลขภายนอก",
  "ccd.agent.message.beginMuteNoCall": "ไม่มีการโทรให้ปิดเสียง",
  "ccd.quality.message.noProvider": "NOPROVIDER ผิดพลาด ระหว่างการตรวจสอบคุณภาพ",
  "ccd.transfer.label.statusUnknow": "Unknown",
  "ccd.ccaction.message.repeateRest": "เจ้าหน้าที่อยู่ในระหว่างการพัก ห้ามบังคับให้แสดงสถานะไม่ว่าง",
  "ccd.agent.videomode.maximizeUser": "เพิ่มผู้ใช้สูงสุด",
  "ccd.agent.message.logOutStatusErr": "การออกจากระบบล้มเหลว คุณอาจมีธุรกิจที่รอให้ดำเนินการ",
  "ccd.agent.status.mute": "ปิดเสียง",
  "ccd.agent.videomode.maximizeAgent": "เพิ่มเจ้าหน้าที่สูงสุด",
  "ccd.agent.tips.restTooLong": "หมดเวลาการพัก",
  "ccd.agent.status.occupy": "ถูกใช้งานแล้ว",
  "ccd.record.message.startRecord": "เกิดสถานะผิดพลาดเมื่อเริ่มการบันทึก",
  "ccd.agent.message.monitorSelf": "เจ้าหน้าที่ที่ถูกตรวจสอบและผู้ตรวจสอบไม่สามารถเป็นคนเดียวกันได้",
  "ccd.agent.message.hangupnocall": "ไม่สามารถวางสายได้ หากสายถูกระงับ โปรดยกเลิกการระงับก่อน",
  "ccd.agent.message.wait": "การโทรกําลังดําเนินการอยู่ โปรดลองอีกครั้งในภายหลัง",
  "ccd.agent.message.reset": "การประมวลผลการโทรล้มเหลวมากเกินไป พยายามซิงโครไนซ์สถานะเอเจนต์",
  "ccd.transfer.label.skillName": "คิวทักษะ",
  "ccd.ccaction.message.retMessEnd": ".",
  "ccd.record.message.pauseRecord": "เกิดสถานะผิดพลาดเมื่อหยุดการบันทึกชั่วคราว",
  "ccd.ccaction.message.recordFail": "เจ้าหน้าที่ไม่ได้ลงชื่อเข้าใช้และการเล่นเสียงย้อนหลังล้มเหลว",
  "ccd.agent.videocall.typeError": "สถานะการโทรไม่ถูกต้องเมื่อปรับโหมดการแสดงผลสำหรับการโทรแบบวิดีโอคอลสามสาย",
  "ccd.transfer.label.transferToSkill": "โอนไปยัง Skill Queue",
  "ccd.ccaction.message.confJoinErr": "ไม่มีสายที่ถูกพัก ไม่อนุญาตให้ใช้การโทรสามสาย",
  "ccd.agent.message.insultNoCall": "ไม่มีการโทรเพื่อขอคำปรึกษา",
  "ccd.callmessage.workbench": "พื้นที่ทำงานของเสียงและวิดีโอ",
  "ccd.agent.message.beginMuteStateErr": "เกิดสถานะการโทรผิดพลาดเมื่อคุณปิดเสียงตัวเอง",
  "ccd.agent.status.threeParty": "สามสาย",
  "ccd.agent.message.loginInvalidParam": "หมายเลขโทรศัพท์ไม่ถูกต้องหรือมีการลงชื่อเข้าใช้โดยเจ้าหน้าที่คนอื่น",
  "ccd.agent.message.checkAgentStatus": "ให้ดำเนินการนี้เมื่อผู้ตรวจสอบว่าง",
  "ccd.agent.message.loginInvalidPhone": "หมายเลขโทรศัพท์ไม่ถูกต้อง",
  "ccd.agent.message.InvalidNumber": "หมายเลขไม่ถูกต้อง",
  "ccd.quality.message.intercept": "ดักฟัง",
  "ccd.agent.mediaswitch.switchvideofailed": "อยู่ในการโทรแบบวิดีโอคอลแล้ว",
  "ccd.agent.message.mediaSwitchSuccess": "การสลับเสียง / วิดีโอสำเร็จ",
  "ccd.quality.message.searchWorkno": "กรุณากรอกรหัสเจ้าหน้าที่เพื่อค้นหา",
  "ccd.cccbar.label.yourself": "ตัวเอง",
  "ccd.transfer.label.transferToAgent": "โอนไปยังเจ้าหน้าที่",
  "ccd.cccbar.label.cccbar": "แถบการเชื่อมต่อ",
  "ccd.quality.message.qualityTitle": "การตรวจสอบคุณภาพ",
  "ccd.cccbar.label.empty": "ล้างทั้งหมด",
  "ccd.ccaction.message.error": "ข้อผิดพลาด",
  "ccd.quality.message.stopListen": "หยุดการฟัง",
  "ccd.quality.message.switch": "สลับ",
  "ccd.agent.status.innerCall": "การโทรภายใน",
  "ccd.ccaction.message.inputWorkNo": "รหัสเจ้าหน้าที่ว่างเปล่า กรุณากรอก ID ของเจ้าหน้าที่",
  "ccd.quality.message.statusErr": "สถานะผิดปกติระหว่างการตรวจสอบคุณภาพ",
  "ccd.callout.message.numberFormat": "หมายเลขโทรศัพท์สามารถมีได้เฉพาะตัวเลข, เครื่องหมายตัวเลข (#), และเครื่องหมายดอกจัน (*), เท่านั้น และมีอักขระสูงสุด 24 ตัว",
  "ccd.cccbar.message.calltip": "สวัสดี มีสายใหม่กำลังโทรเข้า!",
  "ccd.ccaction.message.statusError": "ไม่มีการโทรที่ต้องปิดเสียงในสถานะปัจจุบัน",
  "ccd.agent.message.getHoldCallStatusErr": "เกิดสถานะการโทรผิดพลาดเมื่อคุณหยุดพักสาย",
  "ccd.innerhelp.label.helpType": "ประเภทการปรึกษา",
  "ccd.cccbar.label.nocall": "ไม่มีการโทร",
  "ccd.callmessage.chatOnlineWorkbench": "พื้นที่ทำงานของการสนทนาออนไลน์",
  "ccd.cccbar.label.inputNumber": "ป้อนตัวเลข",
  "ccd.record.message.startPlay": "เกิดสถานะผิดพลาดเมื่อการเล่นย้อนหลังเริ่มต้นขึ้น",
  "ccd.innerhelp.message.inputExNumhelp": "กรอกมายเลขเพื่อขอคำปรึกษา",
  "ccd.agent.message.connectHoldNoCall": "ไม่มีสายให้พัก",
  "ccd.agent.message.restNoRight": "เจ้าหน้าที่ไม่มีสิทธิเรียกใช้อินเทอร์เฟซ เจ้าหน้าที่อาจถูกบังคับให้ลงชื่อออกจากระบบ",
  "ccd.agent.label.recordPlay": "การเล่นเสียงย้อนหลัง",
  "ccd.agentstate.notTalking": "เจ้าหน้าที่ที่เลือกไม่อยู่ในสถานะพูดคุย",
  "ccd.quality.message.resourceUnable": "มีข้อยกเว้นเกิดขึ้นระหว่างการตรวจสอบ ตรวจสอบบันทึกที่เกี่ยวข้อง",
  "ccd.agent.message.setStatErr": "ตั้งค่าสถานะเจ้าหน้าที่ไม่สำเร็จ",
  "ccd.transfer.button.sure": "ตกลง",
  "ccd.quality.message.listen": "กำลังฟัง",
  "ccd.cccbar.label.callout": "โทร",
  "ccd.agent.message.endMuteStateErr": "เกิดสถานะการโทรผิดพลาดเมื่อคุณยกเลิกการปิดเสียง",
  "ccd.transfer.label.releaseTransfer": "โอนการปล่อยสาย",
  "ccd.agent.status.talk": "กำลังสนทนา",
  "ccd.transfer.label.transferType": "ประเภทการโอน",
  "ccd.transfer.label.transferTypeColon": "ประเภทการโอน:",
  "ccd.transfer.label.transferTypeColon.ccbar": "ประเภทการโอน:",
  "ccd.agent.message.eventMethodErr": "วิธีการรับเหตุการณ์ของเจ้าหน้าที่ไม่ถูกต้อง",
  "ccd.transfer.button.cancel": "ยกเลิก",
  "ccd.agent.mediaswitch.video": "การโทรแบบวิดีโอคอล",
  "ccd.ccaction.message.retMessage": ". ข้อความผิดพลาด:",
  "ccd.agent.message.AgentAlreadyLogout": "เจ้าหน้าที่ลงชื่อออกจากระบบแล้ว",
  "ccd.ccaction.message.repeateBusy": "ขณะนี้เจ้าหน้าที่ไม่ว่าง ห้ามตั้งค่าไม่ว่างซ้ำ",
  "ccd.agent.message.invalidWasURL": "เส้นทางการเชื่อมโยง WAS ที่กำหนดค่าบนเซิร์ฟเวอร์ไม่ถูกต้อง",
  "ccd.agent.message.threePartyNoHoldCall": "ไม่มีสายที่พักอยู่เพื่อเริ่มการโทรสามสาย",
  "ccd.record.message.resumeRecord": "เกิดสถานะผิดพลาดเมื่อการบันทึกดำเนินต่อไป",
  "ccd.agent.message.getHoldNoHoldCall": "ไม่มีสายที่ต้องยกเลิกการพักสาย",
  "ccd.ccaction.message.inputIvrName": "ชื่อ IVR flow ว่างเปล่า กรุณากรอกชื่อ IVR flow",
  "ccd.agent.message.userOrPasswdInvalid": "การลงชื่อเข้าใช้ล้มเหลว บัญชีหรือรหัสผ่านไม่ถูกต้อง",
  "ccd.agent.message.misconnection": "การเรียกใช้ session persistence interface ล้มเหลว",
  "ccd.transfer.label.talkingTransfer": "การโอนสายสนทนา",
  "ccd.agent.message.accoutnLocked": "บัญชีถูกล็อก ลงชื่อเข้าใช้ในภายหลัง",
  "ccd.transfer.message.inputExNumber": "โปรดป้อนหมายเลขที่จะโอน",
  "ccd.quality.message.back": "ย้อนกลับ",
  "ccd.transfer.label.callTransfer": "โอนสาย",
  "ccd.agent.message.answerNoCall": "ไม่มีสายให้รับ",
  "ccd.record.message.jumpForw": "เกิดสถานะผิดพลาดระหว่างการส่งต่อ",
  "ccd.agent.message.releaseNumberNoNumber": "ไม่มีหมายเลขโทรศัพท์ที่รอการปล่อยสาย",
  "ccd.agent.message.AgentInnerError": "การดําเนินการล้มเหลว สาเหตุที่เป็นไปได้คือเกิดข้อผิดพลาดภายใน ไม่มีทรัพยากร หรือเอเจนต์ไม่ได้ใช้งาน",
  "ccd.agent.previewcall.invalParam": "การดูตัวอย่างพารามิเตอร์การโทรออกไม่ถูกต้อง",
  "ccd.agent.message.default": "ข้อผิดพลาดภายใน ตรวจสอบ log",
  "ccd.agent.status.innerTwo": "ปรึกษาหารือ",
  "ccd.ccaction.message.rest": "พัก",
  "ccd.ccaction.message.seconds": "วินาที",
  "ccd.ccaction.message.minutes": "นาที",
  "ccd.ccaction.message.hour": "ชั่วโมง",
  "ccd.agent.message.callOutPhoneErr": "หมายเลขโทรออกไม่ถูกต้อง",
  "ccd.innerhelp.label.threePartyHelp": "การปรึกษาสามสาย",
  "ccd.agent.message.holdNoCall": "ไม่มีสายให้พัก",
  "ccd.record.message.pausePlay": "เกิดสถานะผิดพลาดเมื่อการเล่นย้อนหลังหยุดชั่วคราว",
  "ccd.agent.message.transferNoCall": "ไม่มีสายให้โอน",
  "ccd.ccaction.message.resignin": "รหัสผ่านที่นั่งไม่ถูกต้อง กรุณาเช็คอินอีกครั้ง!",
  "ccd.agent.mediaswitch.audio": "การโทรด้วยเสียง",
  "ccd.callmessage.whiteNumberExist": "หมายเลขนี้ไม่มีอยู่ในรายการที่เชื่อถือได้",
  "ccd.transfer.message.refreshTab": "ห้ามทำการรีเฟรชหน้าซ้ำ",
  "ccd.quality.message.stopInsert": "หยุดการแทรก",
  "ccd.transfer.label.transferToExNumber": "โอนสายไปยังหมายเลขภายนอก",
  "ccd.agent.videocall.displayError": "เกิดข้อยกเว้นขึ้นเมื่อมีการปรับโหมดการนำเสนอของการโทรแบบวิดีโอคอลสามสาย",
  "ccd.agent.status.callout": "กำลังโทรออก",
  "ccd.quality.message.forcerest": "บังคับให้ว่าง",
  "ccd.innerhelp.label.help": "ปรึกษา",
  "ccd.agent.message.releaseNoCall": "ไม่มีการโทรให้วางสาย",
  "ccd.quality.message.noAuth": "ไม่มีข้อยกเว้นการอนุญาตเกิดขึ้นระหว่างการตรวจสอบคุณภาพ",
  "ccd.transfer.message.NoIvrOnline": "ไม่มี IVR flow",
  "ccd.transfer.label.threeParthTransfer": "การโอนสามฝ่าย",
  "ccd.agent.message.needLogin": "ลงชื่อเข้าใช้ในฐานะเจ้าหน้าที่แล้วลองอีกครั้ง",
  "ccd.agent.message.logOutInvalidParam": "การลงชื่อเข้าใช้ล้มเหลว พารามิเตอร์ไม่ถูกต้อง",
  "ccd.quality.message.inspectInspectorErr": "เจ้าหน้าที่ที่ถูกตรวจสอบไม่สามารถเป็นผู้ตรวจสอบได้",
  "ccd.agent.void.call": "สายออกเสียง",
  "ccd.agent.chat.mutimedia.call": "การโทรออกมัลติมีเดีย",
  "ccd.agent.chat.start": "กําลังเริ่มเซสชัน",
  "ccd.agent.chat.channle.choose": "การเลือกช่อง",
  "ccd.login.message.logoutReason.sessionInvalid": "เซสชันไม่ถูกต้อง กรุณาลงชื่อเข้าใช้อีกครั้ง",
  "ccd.ccaction.message.registerPhone": "ลงทะเบียนโทรศัพท์",
  "ccd.ccaction.message.loginagent": "กรุณาลงชื่อเข้าระบบกับเจ้าหน้าที่",
  "ccd.contact.message.timeerror": "เวลาเริ่มต้นต้องมาก่อนเวลาสิ้นสุด",
  "ccd.contact.message.timerangeerror": "ช่วงเวลาต้องไม่เกิน {0} วัน",
  "ccd.contact.message.timeisempty": "ช่วงเวลาไม่สามารถว่างได้",
  "ccd.ccaction.message.registerPhoneFailed": "การลงทะเบียน WebRTC ล้มเหลว ขณะนี้ฟังก์ชันความช่วยเหลือภายในไม่พร้อมใช้งาน",
  "ccd.agent.message.selectTeams": "เลือกบัญชีทีม",
  "ccd.skill.selectskill": "เลือกคิวทักษะอย่างน้อยหนึ่งคิว",
  "ccd.agent.message.qc.restskill": "ไม่สามารถรีเซ็ตคิวทักษะได้หากผู้ตรวจสอบไม่รับสายเรียกเข้า",
  "ccd.agentMonitor.label.selectAgentOne": "โปรดจบเอเจนต์ที่กําลังดําเนินการและเลือกการดําเนินการเอเจนต์อื่น",
  "ccd.quality.message.qualityAgentTitle": "การมอนิเตอร์เจ้าหน้าที่",
  "ccd.agentMonitor.forceRelease.noAudio": "ไม่มีการเผยแพร่เสียงหรือวิดีโอคอล",
  "ccd.agentMonitor.intercept.noAudio": "ไม่สามารถดักฟังเสียงหรือวิดีโอคอลได้",
  "ccd.transfer.label.transferNumber": "จะให้กระบวนการบริการตนเองหรือไม่",
  "ccd.agent.forcesignout.confirm": "การออกจากระบบจะมีผลกับบริการการเชื่อมต่อที่ตัวแทนกำลังดำเนินการ คุณแน่ใจหรือไม่ว่าต้องการบังคับให้ออกจากระบบ",
  "ccd.ccagent.contract.preview": "ดูตัวอย่าง",
  "ccd.label.search": "ค้นหา",
  "ccd.label.org.all": "องค์กรที่เข้าถึงได้ทั้งหมด",
  "ccd.agent.message.phone": "หมายเลขที่เรียกเข้า",
  "ccd.title.call.three": "โทรสามทาง",
  "ccd.media.tips.new.online.customer": "คุณมีลูกค้าออนไลน์รายใหม่",
  'ccd.agent.message.calloutagent.restskill': 'ตัวแทนภายนอกไม่รับข้อความ ไม่สามารถรีเซ็ตคิวทักษะได้',
  "ccd.agent.message.videoLimit": "ทรัพยากรของเอเจนต์วิดีโอไม่เพียงพอ และเอเจนต์ไม่สามารถลงชื่อเข้าใช้เซิร์ฟเวอร์สื่อวิดีโอได้",
  "ccd.agent.message.withoutskill": "คิวทักษะขาดหายไป",
  "ccdesktop.login.wertc.failed": "การลงทะเบียน webrtc softphone ล้มเหลว",
  "ccd.agent.label.skillGroupOrSkill": "กลุ่มทักษะ / คิวทักษะ",
  "ccd.agent.webrtc.label.remote.name": "พรรคอื่น",
  "ccd.agent.tips.media.agentType.no.support.callout": "ตัวแทนมัลติมีเดียไม่สามารถโทรออกได้",
  "ccd.agent.webrtc.label.busy.confirm": "ยืนยัน",
  "ccd.agent.webrtc.label.logoutregistered": "จะออกจากระบบหรือไม่",
  "ccd.agent.webrtc.label.button.no": "ไม่",
  "ccd.agent.openeye.switchshare.fail": "ไม่สามารถเปิดใช้งานฟังก์ชันการใช้พื้นที่ทํางานร่วมกันได้",
  "ccd.agent.openeye.getapplist.fail": "ไม่สามารถรับรายการใบสมัครที่เปิดไว้ได้",
  "ccd.agent.webrtc.label.accept": "ตอบกลับ",
  "ccd.agent.openeye.switchshare.success": "เปิดใช้งานฟังก์ชันการแชร์เดสก์ท็อปสําเร็จแล้ว",
  "ccd.agent.openeye.stopshare.fail": "หยุดการแชร์ไม่สําเร็จ",
  "ccd.agent.webrtc.label.sippwd.placeholder": "ป้อนรหัสผ่านซอฟต์โฟน",
  "ccd.agent.webrtc.label.busy.tip": "โทรหา",
  "ccd.ccaction.title.shareapp": "เลือกโปรแกรมที่ใช้ร่วมกัน",
  "ccd.agent.webrtc.label.passwordvalidator": "ความยาวของอักขระที่ป้อนเกินช่วงค่า ความยาวต้องน้อยกว่าหรือเท่ากับ 50 ตัวอักษร",
  "ccd.agent.webrtc.label.button.yes": "ใช่ค่ะ",
  "ccd.agent.webrtc.label.reject": "ปฏิเสธ",
  "ccd.agent.webrtc.label.busy.info": "คําใบ้",
  "ccd.agent.webrtc.label.local.name": "ตัวแทน",
  "ccd.agent.openeye.setshareapp.fail": "ไม่สามารถตั้งค่าการแชร์หน้าจอได้",
  "ccd.agent.openeye.startshare.fail": "ไม่สามารถเริ่มการแบ่งปันได้",
  "ccd.agent.webrtc.label.changeVoice": "ไม่สามารถเริ่มการแบ่งปันได้",
  "ccd.transfer.placeholder.agentName": "ป้อนชื่อตัวแทน",
  "ccd.agent.videocall.displayWarn": "สามารถปรับขนาดหน้าต่างวิดีโอได้เฉพาะเมื่อทั้งสามฝ่ายกําลังเล่นวิดีโอ",
  "ccd.ccaction.message.nosupport": "การดําเนินการนี้ไม่รองรับบนหน้าเว็บ ใช้ Open Eye เพื่อปิดเสียง",
  "ccd.ccbar.before.signin.tips":"ก่อนใช้ฟังก์ชันการเชื่อมต่อ คลิกเพื่อลงชื่อเข้าใช้",
  "ccd.ccbar.calling.number":"หมายเลขโทรศัพท์",
  "ccd.ccbar.mediaSwitch.failed":"การสลับสื่อล้มเหลว",
  "ccd.ccbar.mediaSwitch.notsupport":"ไม่อนุญาตให้มีการสลับเสียงและวิดีโอสําหรับการโทรภายในในโหมดวิดีโอบน MCU",
  "ccd.webrtc.message.bad":"ความเลว",
  "ccd.webrtc.message.poor":"ยากจน",
  "ccd.webrtc.message.good":"ดีครับ",
  "ccd.webrtc.message.recive":"รับ",
  "ccd.webrtc.message.Packetloss":"การสูญเสียแพคเก็ต",
  "ccd.webrtc.message.delay":"ล่าช้า",
  "ccd.webrtc.message.jitter":"กระวนกระวายใจ",
  "ccd.webrtc.message.send":"ส่ง",
  "ccd.webrtc.message.signal":"สัญญาณ",
  'ccd.webrtc.message.medium':'ดีมาก',
  'ccd.webrtc.login.success':'การเข้าสู่ระบบ softphone สําเร็จแล้ว',
  'ccd.webrtc.login.fail':'การเข้าสู่ระบบ Softphone ล้มเหลว',
  'ccd.webrtc.login.fail.noPwd':'ไม่มีรหัสผ่านเริ่มต้นใช้งาน รีเซ็ตรหัสผ่านซอฟต์โฟนและลองอีกครั้ง',
  'ccd.webrtc.login.fail.pop.tips':'ล้มเหลวในการเข้าสู่ระบบซอฟต์โฟนโดยอัตโนมัติ คุณไม่สามารถใช้ฟังก์ชั่นเสียงได้ เข้าสู่ระบบ softphone อีกครั้ง?',
  'ccd.webrtc.login.try':'ลองอีกครั้ง',
  'ccd.webrtc.login.ignore':'ไม่สนใจ',
  'ccd.webrtc.login.fail.top.tips':'การเข้าสู่ระบบซอฟต์โฟนผิดปกติ และไม่สามารถใช้ฟังก์ชันที่เกี่ยวข้อง เช่น เสียงได้',
  "ccd.ccbar.webrtc.network.signal.pool":"สัญญาณเครือข่ายปัจจุบันไม่ดี ซึ่งอาจส่งผลต่อการโทร",
  "ccd.agentMonitor.label.switchwhisper": "คุณไม่สามารถคลิกกระซิบสําหรับตัวแทนในสถานะก่อนฟังหรือต่อคําแทรกได้",
  'ccd.agent.webrtc.register.tip':'ลงทะเบียนโทรศัพท์แบบซอฟท์แวร์หรือไม่',
  "ccd.consult.message.two.inputExNumber": "กรุณากรอกหมายเลขเพื่อขอคําปรึกษาจากสองฝ่าย",
  "ccd.consult.message.three.inputExNumber": "โปรดป้อนหมายเลขสําหรับการให้คําปรึกษาสามฝ่าย",
  "ccd.ccbar.mediaSwitch.sfunotsupport":"การโทรปัจจุบันไม่รองรับการสลับสื่อ",
  "ccd.agent.tips.welcome.agentlogout": "ยินดีต้อนรับกลับ!",
  "ccd.agent.message.continuously.online":"บริการออนไลน์จะได้รับการดูแลหลังจากเช็คอินแล้ว โปรดเช็คเอาท์ให้ทันเวลาหลังจากทํางานเสร็จ",
  "ccd.agent.vrpserviceaddress.isempty" : "ที่อยู่เซิร์ฟเวอร์ VRP ว่างเปล่า ติดต่อผู้ดูแลระบบเพื่อตั้งค่า",
  "ccd.agent.initializeagentex.success" : "เจ้าหน้าที่ลงทะเบียนกับ VRC สําเร็จแล้ว เดสก์ท็อปของคุณจะถูกตรวจสอบและการโทรของคุณจะถูกบันทึกหน้าจอ โปรดรักษาสถานภาพการทํางานที่ดีไว้",
  "ccd.agent.initializeagentex.passworderror" : "ID ตัวแทนหรือรหัสผ่านไม่ถูกต้อง ลงทะเบียนกับ VRC ไม่สําเร็จ",
  "ccd.agent.initializeagentex.alreadyregistered" : "เจ้าหน้าที่ได้ลงทะเบียนกับ VRC แล้ว",
  "ccd.agent.vrpexe.closed" : "ซอฟต์แวร์ VRC กําลังถูกปิดตัวลง",
  "ccd.agent.startMonitorEx.uninitialize" : "การเชื่อมต่อ vrc ไม่ถูกต้อง ลงชื่อเข้าใช้เจ้าหน้าที่ปัจจุบันอีกครั้ง",
  "ccd.agent.startMonitorEx.qcnotexit" : "การเชื่อมต่อผู้เข้าร่วมกับ vrc ล้มเหลว โปรดลงชื่อเข้าใช้อีกครั้ง",
  "ccd.agent.startMonitorEx.qctimelimitexceed" : "การเชื่อมต่อผู้เข้าร่วมกับ vrc ล้มเหลว โปรดลงชื่อเข้าใช้อีกครั้ง",
  "ccd.agent.startMonitorEx.qcunsigned": "เจ้าหน้าที่ไม่ได้ลงชื่อเข้าระบบ ตรวจสอบว่าเจ้าหน้าที่ได้ลงชื่อเข้าใช้หรือต้องการลงชื่อเข้าใช้อีกครั้งหรือไม่",
  "ccd.agent.startMonitorEx.othererror": "ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้ ติดต่อเจ้าหน้าที่ซ่อมบํารุงหรือลองอีกครั้งในภายหลัง",

}