function setCookie(name, value, day) {
  let date = new Date()
  date.setDate(date.getDate() + day)
  document.cookie = `${name}=${value};expires=${date};path=/`
}

function getCookie(name) {
  let reg = RegExp(name + '=([^;]+)')
  let arr = document.cookie.match(reg)
  if (arr) {
    return arr[1]
  } else {
    return ''
  }
}

function delCookie(name) {
  setCookie(name, null, -1)
}

// 从cookie中获取系统语言
const getSystemLanguage = () => {
  const chooseLanguage = getCookie('u-locale')
  if (chooseLanguage) return chooseLanguage
}

export default {
  setCookie,
  getCookie,
  delCookie,
  getSystemLanguage,
}
