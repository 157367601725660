export default {
  'oifde.custom.displayName': 'Servicio comercial',
  'oifde.common.displayName': 'Nodo común',
  'oifde.basic.displayName': 'Servicio básico',
  'oifde.start.displayName': 'Inicio',
  'oifde.start.description': 'Inicio',
  'oifde.start.keywords': 'Inicio',
  'oifde.commonBusinessInvokeService.displayName': 'Invocación de interfaz comercial',
  'oifde.commonBusinessInvokeService.description': 'Invocación de interfaz comercial (commonBusinessInvokeService)',
  'oifde.commonBusinessInvokeService.keywords': 'Invocación de interfaz comercial',
  'oifde.jsonDataProcessService.displayName': 'Invocación de datos de JSON',
  'oifde.jsonDataProcessService.description': 'Invocación de datos de JSON (jsonDataProcessService)',
  'oifde.jsonDataProcessService.keywords': 'Invocación de datos de JSON',
  'oifde.timeSelectService.displayName': 'Seleccionar hora',
  'oifde.timeSelectService.description': 'Seleccionar hora (timeSelectService).',
  'oifde.timeSelectService.keywords': 'Seleccionar hora',
  'oifde.queryMessageService.displayName': 'Consulta de información',
  'oifde.queryMessageService.description': 'Consulta de información(queryMessageService).',
  'oifde.queryMessageService.keywords': 'Consulta de información',
  'oifde.procedureService.displayName': 'Procedimiento almacenado',
  'oifde.procedureService.description': 'Procedimiento almacenado (procedureService)',
  'oifde.procedureService.keywords': 'Procedimiento almacenado',
  'oifde.conditionService.displayName': 'Evaluación de condiciones',
  'oifde.conditionService.description': 'Evaluación de condiciones (conditionService)',
  'oifde.conditionService.keywords': 'Evaluación de condiciones',
  'oifde.callEndService.displayName': 'Finalización de llamadas',
  'oifde.callEndService.description': 'Finalización de llamadas (callEndService)',
  'oifde.callEndService.keywords': 'Finalización de llamadas',
  'oifde.sendSmsService.displayName': 'Envío de SMS',
  'oifde.sendSmsService.description': 'Envío de SMS (sendSmsService)',
  'oifde.sendSmsService.keywords': 'Envío de SMS',
  'oifde.commonVoiceService.displayName': 'Respuesta',
  'oifde.commonVoiceService.description': 'Respuesta (commonResponseService)',
  'oifde.commonVoiceService.keywords': 'Respuesta',
  'oifde.transforFlowService.displayName': 'Transferencia de flujos',
  'oifde.transforFlowService.description': 'Transferencia de flujos (transforFlowService)',
  'oifde.transforFlowService.keywords': 'Transferencia de flujos',
  'oifde.callTransferService.displayName': 'Transferencia de llamadas',
  'oifde.callTransferService.description': 'Transferencia de llamadas (callTransferService)',
  'oifde.callTransferService.keywords': 'Transferencia de llamadas',
  'oifde.speechRecognitionService.displayName': 'Reconocimiento semántico',
  'oifde.speechRecognitionService.description': 'Reconocimiento semántico (speechRecognitionService)',
  'oifde.speechRecognitionService.keywords': 'Reconocimiento semántico',
  'oifde.speechRecognitionService.confidence2TopError': 'El intervalo de confianza debe estar entre 0 y 1.',
  'oifde.keyboardRecognitionService.displayName': 'Reconocimiento de teclas',
  'oifde.keyboardRecognitionService.description': 'Reconocimiento de teclas (keyboardRecognitionService)',
  'oifde.keyboardRecognitionService.keywords': 'Reconocimiento de teclas',
  'oifde.textVariableToChatbotService.displayName': 'Texto a bot de chateo',
  'oifde.textVariableToChatbotService.description': 'Texto a bot de chateo (textVariableToChatbotService)',
  'oifde.textVariableToChatbotService.keywords': 'Texto a bot de chateo',
  'oifde.checkRegularService.displayName': 'Validación de reglas',
  'oifde.checkRegularService.description': 'Validación de reglas (checkRegularService)',
  'oifde.checkRegularService.keywords': 'Validación de reglas',
  'oifde.dateProcessService.displayName': 'Procesamiento de fechas',
  'oifde.dateProcessService.description': 'Procesamiento de fechas (dateProcessService)',
  'oifde.dateProcessService.keywords': 'Procesamiento de fechas',
  'oifde.errTimesClearService.displayName': 'Reinicio del recuento de errores',
  'oifde.errTimesClearService.description': 'Reinicio del recuento de errores (errTimesClearService)',
  'oifde.errTimesClearService.keywords': 'Reinicio del recuento de errores',
  'oifde.CircleService.displayName': 'Bucle lógico ',
  'oifde.CircleService.description': 'Bucle lógico (CircleService)',
  'oifde.CircleService.keywords': 'Bucle lógico',
  'oifde.compositeTestService.displayName': 'Muestra de elemento de diagrama de componentes',
  'oifde.compositeTestService.description': 'Muestra de elemento de diagrama de componentes (compositeTestService)',
  'oifde.compositeTestService.keywords': 'Muestra de elemento de diagrama de componentes',
  'oifde.service.condition.condgroup.desc': 'Configure los atributos de las condiciones de evaluación.',
  'oifde.service.condition.condgroup.displayName': 'Lista de condiciones de evaluación',
  'oifde.service.condition.condgroup.keyTitle': 'Condición',
  'oifde.service.condition.condgroup.valueTitle': 'Expresión de condición',
  'oifde.service.invokemethod.group.desc': 'Invocación de método',
  'oifde.service.invokemethod.group.displayName': 'Invocación de método',
  'oifde.service.invokemethod.group.value1Title': 'Objeto devuelto',
  'oifde.service.invokemethod.group.value2Title': 'Nombre del método',
  'oifde.service.invokemethod.group.value3Title': 'Parámetros del método',
  'oifde.service.invokemethod.group.value1Tips': 'Objeto de recepción de valor devuelto por método',
  'oifde.service.invokemethod.group.value2Tips': 'Seleccione un nombre de método',
  'oifde.service.invokemethod.group.value3Tips': 'Parámetro del método 1',
  'oifde.service.invokemethod.group.value4Tips': 'Parámetro del método 2',
  'oifde.service.invokemethod.group.value5Tips': 'Parámetro del método 3',
  'oifde.service.invokemethod.group.mathodType1': 'Método de operación de cadena',
  'oifde.service.invokemethod.group.mathodType2': 'Método de operación de matriz',
  'oifde.service.invokemethod.group.mathodType3': 'Métodos de operación de mapa',
  'oifde.service.invokemethod.strSplit.desc': 'Dividir una cadena de acuerdo con el separador dado',
  'oifde.service.invokemethod.strSubstring.desc': 'Extraer subcadenas',
  'oifde.service.invokemethod.strIndexOf.desc': 'Obtiene el subíndice de la primera aparición de una cadena especificada',
  'oifde.service.invokemethod.strStartsWith.desc': 'Detecta si la cadena comienza con el prefijo especificado',
  'oifde.service.invokemethod.strEndsWith.desc': 'Detecta si la cadena termina con el sufijo especificado',
  'oifde.service.invokemethod.strParseJSON.desc': 'Convertir una cadena JSON en un objeto',
  'oifde.service.invokemethod.strParseInt.desc': 'Convertir una cadena en un número entero',
  'oifde.service.invokemethod.strParseFloat.desc': 'Convertir una cadena en un número de coma flotante',
  'oifde.service.invokemethod.strLength.desc': 'Longitud de cadena',
  'oifde.service.invokemethod.strToUpperCase.desc': 'Convertir caracteres de cadena en minúsculas a mayúsculas',
  'oifde.service.invokemethod.strToLowerCase.desc': 'Convertir caracteres de cadena en mayúsculas a minúsculas',
  'oifde.service.invokemethod.arrayAdd.desc': 'Agregar un elemento a una matriz',
  'oifde.service.invokemethod.arrayAddAll.desc': 'Agregar todos los elementos de otra colección a la matriz',
  'oifde.service.invokemethod.arrayContains.desc': 'Determinar si un elemento está en una matriz',
  'oifde.service.invokemethod.arrayGet.desc': 'Obtener elementos en una matriz por valor de índice',
  'oifde.service.invokemethod.arrayRemoveIdx.desc': 'Eliminar un elemento de subíndice especificado de una matriz',
  'oifde.service.invokemethod.arrayRemoveAllIdx.desc': 'Elimina los elementos de subíndice de la matriz que existen en la colección especificada',
  'oifde.service.invokemethod.arrayClear.desc': 'Eliminar todos los elementos de la matriz',
  'oifde.service.invokemethod.arraySize.desc': 'Obtiene la longitud de una matriz',
  'oifde.service.invokemethod.arraySubList.desc': 'Interceptar y devolver una parte de un array',
  'oifde.service.invokemethod.arrayJoin.desc': 'Convertir matrices en cadenas',
  'oifde.service.invokemethod.mapPut.desc': 'Agregar un elemento al mapa',
  'oifde.service.invokemethod.mapGet.desc': 'Obtiene el valor de la asignación de claves especificada en el mapa',
  'oifde.service.invokemethod.mapRemove.desc': 'Elimina un par clave-valor especificado en un mapa',
  'oifde.service.invokemethod.mapClear.desc': 'Borrar todos los pares clave-valor de un mapa',
  'oifde.service.invokemethod.mapSize.desc': 'Obtiene el número de todas las claves del mapa',
  'oifde.service.invokemethod.strSplit.parm1.desc': 'Cadena a dividir',
  'oifde.service.invokemethod.strSplit.parm2.desc': 'separador',
  'oifde.service.invokemethod.strSubstring.parm1.desc': 'Cadena de caracteres a interceptar',
  'oifde.service.invokemethod.strSubstring.parm2.desc': 'Iniciar subíndice (entero)',
  'oifde.service.invokemethod.strSubstring.parm3.desc': 'Subíndice final (entero)',
  'oifde.service.invokemethod.strIndexOf.parm1.desc': 'Cadena de caracteres a buscar',
  'oifde.service.invokemethod.strIndexOf.parm2.desc': 'Cadena especificada',
  'oifde.service.invokemethod.strStartsWith.parm1.desc': 'Cadena de caracteres a detectar',
  'oifde.service.invokemethod.strStartsWith.parm2.desc': 'Cadena de prefijo especificada',
  'oifde.service.invokemethod.strEndsWith.parm1.desc': 'Cadena de caracteres a detectar',
  'oifde.service.invokemethod.strEndsWith.parm2.desc': 'Cadena de sufijo especificada',
  'oifde.service.invokemethod.strParseJSON.parm1.desc': 'Cadena de caracteres a convertir',
  'oifde.service.invokemethod.strParseInt.parm1.desc': 'Cadena de caracteres a convertir',
  'oifde.service.invokemethod.strParseFloat.parm1.desc': 'Cadena de caracteres a convertir',
  'oifde.service.invokemethod.strLength.parm1.desc': 'Cadena de caracteres cuya longitud se va a calcular',
  'oifde.service.invokemethod.strToUpperCase.parm1.desc': 'Cadena de caracteres a convertir',
  'oifde.service.invokemethod.strToLowerCase.parm1.desc': 'Cadena de caracteres a convertir',
  'oifde.service.invokemethod.arrayAdd.parm1.desc': 'Matriz que se va a agregar (este método modifica la matriz que se va a agregar)',
  'oifde.service.invokemethod.arrayAdd.parm2.desc': 'Posición añadida (cola predeterminada)',
  'oifde.service.invokemethod.arrayAdd.parm3.desc': 'Elementos añadidos',
  'oifde.service.invokemethod.arrayAddAll.parm1.desc': 'Matriz que se va a agregar (este método modifica la matriz que se va a agregar)',
  'oifde.service.invokemethod.arrayAddAll.parm2.desc': 'Posición añadida (cola predeterminada)',
  'oifde.service.invokemethod.arrayAddAll.parm3.desc': 'Elementos añadidos',
  'oifde.service.invokemethod.arrayContains.parm1.desc': 'Matriz a detectar',
  'oifde.service.invokemethod.arrayContains.parm2.desc': 'Elemento a juzgar',
  'oifde.service.invokemethod.arrayGet.parm1.desc': 'Matriz a utilizar',
  'oifde.service.invokemethod.arrayGet.parm2.desc': 'subíndice de matriz',
  'oifde.service.invokemethod.arrayRemoveIdx.parm1.desc': 'Matriz a utilizar',
  'oifde.service.invokemethod.arrayRemoveIdx.parm2.desc': 'Eliminar subíndice de matriz',
  'oifde.service.invokemethod.arrayRemoveAllIdx.parm1.desc': 'Matriz a utilizar',
  'oifde.service.invokemethod.arrayRemoveAllIdx.parm2.desc': 'Especifica el conjunto de subíndices de matriz',
  'oifde.service.invokemethod.arrayClear.parm1.desc': 'Matriz que se va a borrar',
  'oifde.service.invokemethod.arraySize.parm1.desc': 'Matriz de longitud a calcular',
  'oifde.service.invokemethod.arraySubList.parm1.desc': 'Matriz a interceptar',
  'oifde.service.invokemethod.arraySubList.parm2.desc': 'Posición de inicio de interceptación',
  'oifde.service.invokemethod.arraySubList.parm3.desc': 'Posición final de interceptación',
  'oifde.service.invokemethod.arrayJoin.parm1.desc': 'Matriz a convertir',
  'oifde.service.invokemethod.arrayJoin.parm2.desc': 'Cadena de conexión',
  'oifde.service.invokemethod.mapPut.parm1.desc': 'Mapa que se va a agregar (Este método modifica el mapa que se va a agregar)',
  'oifde.service.invokemethod.mapPut.parm2.desc': 'Clave (solo se admite el tipo de cadena de caracteres)',
  'oifde.service.invokemethod.mapPut.parm3.desc': 'Elementos añadidos',
  'oifde.service.invokemethod.mapGet.parm1.desc': 'Mapas pendientes',
  'oifde.service.invokemethod.mapGet.parm2.desc': 'Clave (solo se admite el tipo de cadena de caracteres)',
  'oifde.service.invokemethod.mapRemove.parm1.desc': 'Mapas pendientes',
  'oifde.service.invokemethod.mapRemove.parm2.desc': 'Clave (solo se admite el tipo de cadena de caracteres)',
  'oifde.service.invokemethod.mapClear.parm1.desc': 'Mapa que se va a borrar',
  'oifde.service.invokemethod.mapSize.parm1.desc': 'Mapa de la cantidad a calcular',
  'oifde.service.invokemethod.strSplit.resultObj.desc': 'El objeto devuelto es del tipo de matriz y almacena la matriz de cadenas divididas.',
  'oifde.service.invokemethod.strSubstring.resultObj.desc': 'El objeto devuelto es una cadena de caracteres, que es una subcadena truncada.',
  'oifde.service.invokemethod.strIndexOf.resultObj.desc': 'El objeto devuelto es un valor de subíndice, que es un entero.',
  'oifde.service.invokemethod.strStartsWith.resultObj.desc': 'El objeto devuelto es el resultado de la detección. El valor es 0/1.',
  'oifde.service.invokemethod.strEndsWith.resultObj.desc': 'El objeto devuelto es el resultado de la detección. El valor es 0/1.',
  'oifde.service.invokemethod.strParseJSON.resultObj.desc': 'El objeto devuelto es el objeto JSON convertido.',
  'oifde.service.invokemethod.strParseInt.resultObj.desc': 'El objeto devuelto es un entero convertido.',
  'oifde.service.invokemethod.strParseFloat.resultObj.desc': 'El objeto devuelto es un número de coma flotante convertido.',
  'oifde.service.invokemethod.strLength.resultObj.desc': 'Longitud de un objeto devuelto como una cadena de caracteres, que es un entero.',
  'oifde.service.invokemethod.strToUpperCase.resultObj.desc': 'El objeto devuelto es una cadena convertida.',
  'oifde.service.invokemethod.strToLowerCase.resultObj.desc': 'El objeto devuelto es una cadena convertida.',
  'oifde.service.invokemethod.arrayAdd.resultObj.desc': 'El objeto devuelto es el resultado de la adición. Si la adición se realiza correctamente, el objeto devuelto es 1; de lo contrario, el objeto devuelto es 0.',
  'oifde.service.invokemethod.arrayAddAll.resultObj.desc': 'El objeto devuelto es el resultado de la adición. Si la adición se realiza correctamente, el objeto devuelto es 1; de lo contrario, el objeto devuelto es 0.',
  'oifde.service.invokemethod.arrayContains.resultObj.desc': 'El objeto devuelto es el resultado de la determinación. El valor es un entero 0/1.',
  'oifde.service.invokemethod.arrayGet.resultObj.desc': 'Devuelve un elemento cuyo objeto es una matriz.',
  'oifde.service.invokemethod.arrayRemoveIdx.resultObj.desc': 'El objeto devuelto es el resultado de la eliminación. Si la eliminación se realiza correctamente, se devuelve el elemento del subíndice correspondiente. De lo contrario, se devuelve null.',
  'oifde.service.invokemethod.arrayRemoveAllIdx.resultObj.desc': 'El objeto devuelto es el resultado de la eliminación. Si la eliminación se realiza correctamente, el objeto devuelto es 1; de lo contrario, el objeto devuelto es 0.',
  'oifde.service.invokemethod.arrayClear.resultObj.desc': 'El objeto devuelto es nulo y el cuadro de texto está atenuado y no se puede editar.',
  'oifde.service.invokemethod.arraySize.resultObj.desc': 'El objeto devuelto es una longitud de matriz, que es un entero.',
  'oifde.service.invokemethod.arraySubList.resultObj.desc': 'El objeto devuelto es una matriz secundaria.',
  'oifde.service.invokemethod.arrayJoin.resultObj.desc': 'El objeto devuelto es una cadena de caracteres convertida.',
  'oifde.service.invokemethod.mapPut.resultObj.desc': 'Si la clave no existe, se devuelve null. Si la clave existe, se devuelve el valor reemplazado, es decir, el tipo de objeto.',
  'oifde.service.invokemethod.mapGet.resultObj.desc': 'Devuelve el valor del objeto asignado a la clave especificada.',
  'oifde.service.invokemethod.mapRemove.resultObj.desc': 'Devolver objeto: este método devuelve el valor que esta asignación estaba asociada anteriormente con la clave;Devuelve null si la asignación no contiene una asignación para la clave.',
  'oifde.service.invokemethod.mapClear.resultObj.desc': 'El objeto devuelto es nulo y el cuadro de texto está atenuado y no se puede editar.',
  'oifde.service.invokemethod.mapSize.resultObj.desc': 'El objeto devuelto es el número de claves en el mapa. El valor es un entero.',
  'oifde.service.invoke.cachegroup.desc': 'Configure los atributos de las variables correspondientes.',
  'oifde.service.invoke.cachegroup.displayName': 'Atributos de variables',
  'oifde.service.invoke.cachegroup.keyTitle': 'Variable de memoria caché',
  'oifde.service.invoke.cachegroup.valueTitle': 'Atributo de respuesta',
  'oifde.service.invoke.interfaceId': 'Interfaz invocada',
  'oifde.service.invoke.interfaceId.desc': 'Interfaz invocada',
  'oifde.service.invoke.paramgroup.desc': 'Lista de parámetros de entrada de la interfaz',
  'oifde.service.invoke.paramgroup.name': 'Lista de parámetros de entrada de la interfaz',
  'oifde.service.invoke.startTime': 'Hora de inicio',
  'oifde.service.invoke.startTime.desc': 'Formato: HH:MM:SS. Por ejemplo, 08:00:00',
  'oifde.service.invoke.endTime': 'Hora de fin',
  'oifde.service.invoke.endTime.desc': 'Formato: HH:MM:SS, por ejemplo: 23:00:00',
  'oifde.service.invoke.sendCircle': 'Intervalo de envío',
  'oifde.service.invoke.sendCircle.desc': 'Intervalo de invocación de interfaz, en días.El valor es un entero, que no puede exceder de 365.Si este parámetro se establece en -1, la interfaz no se invoca periódicamente. En este caso, se activará el elemento de diagrama de finalización en cualquier momento.',
  'oifde.service.invoke.CallTransferType': 'Tipo de transferencia',
  'oifde.service.invoke.CallTransferType.desc': 'Tipo de transferencia de diálogo. 1: transferencia al agente manual (transferencia a la cola de habilidades por código de enrutamiento). 2: transferencia al IVR; 3: transferencia al agente manual (transferencia a la cola de habilidades por nombre de habilidad). 4: transferencia al agente (transferencia a la ID del agente) 5: número de tercero; 6: asistencia digital en línea; 7: transferencia a manual (transferencia a un cliente. Actualmente, sólo se soporta multimedia.) 8: transferencia a manual (La cola de habilidades a la que pertenece el agente. Actualmente, sólo se soporta multimedia.) 31: transferencia a mensajes multimedia (transferencia a clientes familiares) 32: transferencia a mensajes multimedia (transferencia a colas de habilidades) 33: reenvío a mensajes multimedia (transferencia a agentes)',
  'oifde.service.invoke.CallTransferRouteValue': 'Valor de ruta',
  'oifde.service.invoke.CallTransferRouteValue.desc': 'Valor de ruta de transferencia de llamadas. Configure la información del código de ruta correspondiente en función del tipo de transferencia.',
  'oifde.service.invoke.CallTransferRouteTypePolicy': 'Política de enrutamiento',
  'oifde.service.invoke.CallTransferRoutePolicyType.percentage': 'Porcentaje',
  'oifde.service.invoke.CallTransferRoutePolicyType.numberSegment': 'Número de segmento',
  'oifde.service.invoke.CallTransferRouteDefaultSkillName': 'Cola de habilidades predeterminada',
  'oifde.service.invoke.CallTransferMatchingType': 'Regla de coincidencia',
  'oifde.service.invoke.CallTransferMatchingType.prefix': 'Prefijo',
  'oifde.service.invoke.CallTransferMatchingType.suffix': 'Sufijo',
  'oifde.service.invoke.CallTransferRouteTypePolicy.percentTotalError': 'El porcentaje total no es igual a 100',
  'oifde.service.invoke.CallTransferRouteTypePolicy.percentValueError': 'Ingrese un número entero positivo de 1 a 100.',
  'oifde.service.invoke.CallTransferTransData': 'Datos asociados',
  'oifde.service.invoke.CallTransferTransData.desc': 'Datos asociados que se transmiten a un representante durante la transferencia de llamadas',
  'oifde.service.invoke.CallTransferTransData.desc2': 'También puede introducir varias variables de proceso o variables globales, por ejemplo, {"data":${FLOW.XXX},"type":${GLOBAL.XXX}, "time":"2000"}.',
  'oifde.service.invoke.textVariable': 'Variable de texto',
  'oifde.service.invoke.textVariable.desc': 'Variable de texto enviada al bot de chateo. Se debe asignar un valor a la variable antes de la ejecución de este nodo.',
  'oifde.service.invoke.KeyboardInputRegExp': 'Expresión de regla',
  'oifde.service.invoke.KeyboardInputRegExp.desc': 'La expresión se utiliza para verificar si el texto ingresado por un usuario cumple la regla especificada. El valor puede ser una expresión regular. Ejemplo: ^(1([0-9]{10})|([*]|[#]))$',
  'oifde.service.invoke.voiceType': 'Tipo de respuesta',
  'oifde.service.invoke.voiceSource': 'Origen de la respuesta',
  'oifde.service.invoke.staticVoice': 'Voz estática',
  'oifde.service.invoke.TTSVoice': 'TTS',
  'oifde.service.invoke.multiVoice': 'Voz variable',
  'oifde.service.invoke.multiVoiceLanguage': 'Idioma',
  'oifde.service.invoke.multiVoiceType': 'Tipo',
  'oifde.service.invoke.voiceTemplateCode': 'Plantilla de respuesta',
  'oifde.service.invoke.modelType': 'Modo de respuesta',
  'oifde.service.invoke.modelType.desc': 'Indica si la locución se puede interrumpir.',
  'oifde.service.invoke.timeOut': 'Tiempo de espera',
  'oifde.service.invoke.timeOut.desc': 'Tiempo de espera(Unidad: segundos); es un número entero. Ejemplo: 15',
  'oifde.service.invoke.voiceVariable': 'Variable de respuesta',
  'oifde.service.invoke.voiceVariable.desc': 'Variable de voz o de texto de la respuesta. Se debe asignar un valor a la variable antes de la ejecución de este nodo.',
  'oifde.service.invoke.offsetType': 'Tipo de ajuste',
  'oifde.service.invoke.offsetType.desc': 'Ajuste en función del día o la hora',
  'oifde.service.invoke.offsetType.month': 'Ajuste en función del mes',
  'oifde.service.invoke.offsetType.hour': 'Ajuste en func de hora',
  'oifde.service.invoke.sourceVariable': 'Cadena de caracteres de origen',
  'oifde.service.invoke.sourceVariable.desc': 'Variable de la cadena de origen. Se debe configurar un valor antes de la ejecución de este nodo. En el caso del ajuste en función del mes, el formato de la variable es aaaaMM; por ejemplo, 201809. En el caso del ajuste en función de la hora, el formato de la variable es aaaaMMddHHmmss; por ejemplo, 20180808122020.',
  'oifde.service.invoke.destVariable': 'Cadena de caracteres de destino',
  'oifde.service.invoke.destVariable.desc': 'Variable de la cadena de destino, que se utiliza para guardar el resultado.',
  'oifde.service.invoke.offsetMonths': 'Cantidad de ajustes',
  'oifde.service.invoke.offsetMonths.desc': 'Cantidad de ajustes. El valor es un número entero y puede ser negativo. La cantidad de ajustes se puede ingresar según el tipo de ajuste (en función del mes o de la hora). Por ejemplo, en el caso del ajuste en función del mes, el valor –6 indica que la cadena de caracteres de origen vuelve a ser la utilizada seis meses atrás.',
  'oifde.service.invoke.keyBoardType': 'Tipo de ingreso vía teclado',
  'oifde.service.invoke.keyBoardType.desc': 'Tipo de ingreso vía teclado. El valor puede ser keyBoard_phone, checkoldpassword o puede quedar vacío.',
  'oifde.service.invoke.inputVariable': 'Variable',
  'oifde.service.invoke.inputVariable.desc': 'Variable para guardar lo ingresado por el usuario.',
  'oifde.service.invoke.inputVariableTitle': 'Asignación de variables',
  'oifde.service.invoke.inputVariableValue': 'Valor de la variable',
  'oifde.service.invoke.conditionTitle': 'Rama condicional',
  'oifde.service.invoke.branchGroup.timeout': 'Timeout',
  'oifde.service.invoke.branchGroup.nomatch': 'Nomatch',
  'oifde.service.invoke.branchGroup.error': 'Error',
  'oifde.service.invoke.branchGroup.silentAgent': 'Agente silencioso',
  'oifde.service.invoke.targetFlowCode': 'Código de flujo de destino',
  'oifde.service.invoke.targetNodeId': 'ID del nodo de destino',
  'oifde.service.invoke.voiceType.staticVoice': 'Voz estática',
  'oifde.service.invoke.voiceType.TTSVoice': 'TTS',
  'oifde.service.invoke.voiceType.video': 'Reproducción de video',
  'oifde.service.invoke.smsTemplateCode': 'Plantilla de SMS',
  'oifde.service.invoke.smsContent': 'Contenido del SMS',
  'oifde.service.invoke.smsContent.desc': 'Contenido del SMS.',
  'oifde.service.invoke.modelType.voiceBreak': 'Interrupción del reconocimiento',
  'oifde.service.invoke.modelType.noVoiceBreak': 'Solo reproducción',
  'oifde.service.invoke.modelType.keyboardBreak': 'Interrupción mediante tecla',
  'oifde.service.invoke.modelType.voiceAndKeyboard': 'Reconocimiento y pulsación de teclas',
  'oifde.service.invoke.modelType.voiceAndNobreak': 'Reconocimiento después de la reprod',
  'oifde.service.invoke.modelType.voiceAndInputAndNobreak': 'Reconocimiento y pulsaciones de teclas después de la reproducción',
  'oifde.service.invoke.KeyboardInputRegExp.MobileAll': 'Validación de formato de número móvil (con # y *)',
  'oifde.service.invoke.KeyboardInputRegExp.Mobile': 'Validación de formato de número móvil (sin # ni *)',
  'oifde.service.invoke.KeyboardInputRegExp.PasswordAll': 'Validación de formato de contraseña (con # y *)',
  'oifde.service.invoke.KeyboardInputRegExp.PasswordStar': 'Validación de formato de contraseña (con *)',
  'oifde.service.invoke.KeyboardInputRegExp.NumberAll': 'Validación de número móvil (con # y *)',
  'oifde.service.invoke.KeyboardInputRegExp.NumberStar': 'Validación de número móvil (con *)',
  'oifde.service.invoke.KeyboardInputRegExp.Month': 'Validación de mes ingresado',
  'oifde.service.invoke.context': 'Contexto',
  'oifde.service.invoke.context.desc': 'Información de contexto enviada al TUC',
  'oifde.service.invoke.vendorParam': 'Parámetro extendido',
  'oifde.service.invoke.vendorParam.desc': 'Parámetro extendido de identificación de voz enviado al IVR.',
  'oifde.service.invoke.input': 'Contenido de identificación semántica',
  'oifde.service.invoke.input.desc': 'El contenido de identificación semántica enviado al TUC puede ser una variable. Si el contenido está vacío, el contenido ingresado por el usuario se envía por defecto.',
  'oifde.service.invoke.event': 'Evento',
  'oifde.service.invoke.event.desc': 'Evento enviado al TUC.',
  'oifde.service.invoke.event.cleanup': 'Borrar caché de la sesión',
  'oifde.service.invoke.event.cleanCurrent': 'Borrar memoria caché de contexto actual',
  'oifde.service.invoke.procedureCode': 'Procedimiento almacenado',
  'oifde.service.invoke.complexcellgroupin.name': 'Lista de parámetros de entrada C',
  'oifde.service.invoke.complexcellgroupin.desc': 'Parámetros de entrada.',
  'oifde.service.invoke.complexcellgroupout.name': 'Parámetros de salida',
  'oifde.service.invoke.complexcellgroupout.desc': 'Parámetros de salida.',
  'oifde.service.invoke.targetIntentCount': 'Cantidad de intenciones',
  'oifde.service.invoke.targetIntentCount.desc': 'Cantidad máxima de intenciones que el TUC debe enviar.',
  'oifde.service.invoke.confidence2Top': 'Intervalo de confianza',
  'oifde.service.invoke.confidence2Top.desc': 'Intervalo entre la confianza de múltiples intenciones y la confianza de la primera intención cuando se deben enviar múltiples intenciones.',
  'oifde.service.invoke.index.desc': 'Contador',
  'oifde.service.invoke.index': 'Contador',
  'oifde.service.invoke.oblist.desc': 'Lista de bucles',
  'oifde.service.invoke.oblist': 'Lista de bucles',
  'oifde.service.invoke.object.desc': 'Objeto de bucle',
  'oifde.service.invoke.object': 'Objeto de bucle',
  'oifde.service.invoke.timeSelect.NonWorkTime': 'rama de tiempo no especificada',
  'oifde.service.invoke.queryMsg.MessageQueryType': 'Tipo de consulta',
  'oifde.service.invoke.queryMsg.MessageQueryType.desc': 'Escenario de aplicación de la información de cola: Sí: El flujo IVR asociado está habilitado para consultar la información de la cola cuando se transfieren a la cola de habilidades.',
  'oifde.service.invoke.queryMsg.skillMsg': 'Información de cola',
  'oifde.service.invoke.queryMsg.skillInfo': 'Información de cola',
  'oifde.service.invoke.queryMsg.specifiedSkill': 'Nombre de cola',
  'oifde.service.invoke.queryMsg.specialListQuery': 'Información de lista especial',
  'oifde.service.invoke.queryMsg.result': 'Resultado de la consulta',
  'oifde.service.invoke.queryMsg.MessageQueryQueueIndex': 'Posición de cola',
  'oifde.service.invoke.queryMsg.MessageQueryQueueNumber': 'Número de colas',
  'oifde.service.invoke.queryMsg.MessageQueryOnlineNum': 'Número de agentes en línea',
  'oifde.service.invoke.queryMsg.MessageIdleAgentNum': 'Número de agentes inactivos',
  'oifde.service.invoke.queryMsg.MessageQueryWaitingTime': 'Tiempo de espera estimado',
  'oifde.service.invoke.queryMsg.MessageQueryWaitingTime.desc': 'El tiempo de espera estimado de la cola se calcula en función del modelo de cola. El resultado tiene una cierta desviación. Utilice el tiempo de espera estimado de la cola correctamente.',
  'oifde.service.invoke.queryMsg.params': 'Parámetros de solicitud',
  'oifde.service.invoke.queryMsg.skillQueue': 'Cola de habilidades',
  'oifde.service.invoke.queryMsg.agentId': 'ID de agente',
  'oifde.service.invoke.queryMsg.specialList': 'Identificación de una lista especial',
  'oifde.service.invoke.processRecord': 'Grabación completa',
  'oifde.service.invoke.asrMRCPVendor.desc': 'Parámetro extendido del servidor ASR. Establezca este parámetro de acuerdo con los requisitos del proveedor.',
  'oifde.qarecord.inputparam.seriesId': 'Pregunta y respuesta ID',
  'oifde.service.invoke.asrNoInputTimeout': 'Intervalo de tiempo de espera de silencio del suscriptor',
  'oifde.service.invoke.recordType.video': 'Audio y vídeo',
  'oifde.service.invoke.ttsSoundSpeed.high': 'Rápido',
  'oifde.yugaoRecordService.displayName': 'Grabación de VoiceCyber',
  'oifde.service.invoke.asrNoInputTimeout.desc': 'Intervalo de tiempo de espera cuando un abonado no habla durante el reconocimiento ASR, en segundos. El valor oscila entre 0 y 32000. El valor predeterminado es 100.',
  'oifde.service.invoke.workdays.Wednesday': 'Miércoles',
  'oifde.service.invoke.asrAdvancedSetting.desc': 'Si se habilita esta configuración para especificar el reconocimiento ASR.',
  'oifde.service.invoke.asrAdvancedSetting': 'Configuración avanzada de ASR',
  'oifde.service.interfaceType.innerInterface': 'interfaz interna',
  'oifde.service.invoke.interfaceType.desc': 'Tipo de interfaz.',
  'oifde.service.invoke.workdays.Saturday': 'Sábado',
  'oifde.service.invoke.user.level': 'Nivel de usuario',
  'oifde.service.invoke.workdays.Sunday': 'El domingo',
  'oifde.service.invoke.modelType.customization': 'Valor de variable personalizado',
  'oifde.service.invoke.voiceVariable.mix.desc': 'Variable de voz o texto incluida en la respuesta. Se debe asignar un valor a esta variable antes de que se pueda ejecutar el nodo (Para un tipo de voz y texto, la variable de respuesta está en el formato de URL del archivo de grabación URL~$TTS text$~recording file.)',
  'oifde.service.invoke.maxCollectLength': 'Número de dígitos recogidos',
  'oifde.service.invoke.ttsSoundSpeed.low': 'Lento',
  'oifde.service.invoke.ttsSoundEffect.male': 'Masculino',
  'oifde.service.invoke.timeSelect.weekParameter': 'Selección de Semanas',
  'oifde.service.invoke.operationType.open': 'Iniciar grabación',
  'oifde.service.invoke.recordMode.onlyuser': 'Suscriptor de solo grabación',
  'oifde.service.invoke.user.level.reminder': 'Establezca este parámetro en un entero no negativo o una variable del tipo de carácter. El valor predeterminado es 0 y el valor máximo es 14. Las llamadas de clientes de alto nivel se procesan primero después de que la llamada se transfiere al servicio manual.',
  'oifde.service.invoke.confirmCollectFlag.yes': 'Usa la tecla libra (#)',
  'oifde.qarecord.interface.name': 'Preguntas y respuestas de registro',
  'oifde.apiToken.interface.name': 'Obtener el token APIFabric',
  'oifde.apiToken.interface.desc': 'Interfaz utilizada para obtener el token APIFabric',
  'oifde.apiToken.applyType.desc': 'Prefijo de token',
  'oifde.apiToken.accessToken.desc': 'Contenido del token',
  'oifde.apiToken.appKey.desc': 'Clave de aplicación',
  'oifde.apiToken.appSecret.desc': 'App secret',
  'oifde.service.invoke.timeSelect.specificNoWorkDateParameter.desc': 'Fecha especificada de no trabajo. Se admite un máximo de 50 registros.',
  'oifde.yugaoRecordService.description': 'Grabación de VoiceCyber (voiceCyberRecordService)',
  'oifde.service.invoke.cancelCollectFlag': 'Cancelar clave',
  'oifde.service.invoke.recordType.audio': 'Sólo audio',
  'oifde.service.invoke.asrRecognitionTimeout.desc': 'Intervalo cuando el reconocimiento ASR se agota, en segundos. El valor oscila entre 0 y 600. El valor predeterminado es 200.',
  'oifde.service.invoke.asrRecognizeType': 'Tipo de reconocimiento',
  'oifde.service.invoke.ttsSoundSpeed.desc': 'Seleccione la velocidad a la que se realiza la reproducción de voz basada en TTS.',
  'oifde.service.invoke.timeSelect.specificWorkDateParameter.title': 'Si una fecha de trabajo especificada configurada no está disponible en la selección de la semana, la fecha de servicio se considera un día laborable.',
  'oifde.service.invoke.workdays.Monday': 'Lunes',
  'oifde.service.invoke.recordMode.onlyivr': 'Grabar solo IVR',
  'oifde.service.invoke.ttsAdvancedSetting.close': 'Desactivar',
  'oifde.qarecord.inputparam.questionType': 'Tipo de pregunta',
  'oifde.service.invoke.asrMRCPVendor': 'Parámetro extendido de ASR',
  'oifde.service.invoke.processRecord.close': 'No, no',
  'oifde.service.invoke.ttsSoundEffect': 'Voz',
  'oifde.UserLevelService.displayName': 'Nivel de usuario',
  'oifde.service.invoke.timeSelect.specificWorkDateParameter.desc': 'Se admite un máximo de 50 registros.',
  'oifde.service.invoke.asrSpeechCompleteTimeout': 'Intervalo de tiempo de espera de pausa del suscriptor',
  'oifde.service.invoke.ttsSoundSpeed': 'Velocidad de voz',
  'oifde.service.interfaceType.selfDefinedInterface': 'interfaz autodefinida',
  'oifde.service.invoke.asrRecognizeType.intelligent': 'Inteligente',
  'oifde.service.invoke.asrAdvancedSetting.close': 'Desactivar',
  'oifde.service.invoke.voiceType.multi-media': 'Multimedia',
  'oifde.service.invoke.recordType': 'Tipo',
  'oifde.service.invoke.timeSelect.serviceTimeParameter': 'Periodo de servicio',
  'oifde.service.invoke.modelType.inputAndNobreak': 'Sin interrupción después de la recolección de dígitos',
  'oifde.service.invoke.ttsSoundSpeed.normal': 'Mediano',
  'oifde.service.invoke.asrRecognizeType.desc': 'Tipo de identificación ASR. Actualmente solo se admite la identificación común.',
  'oifde.service.invoke.voiceType.card': 'Tarjeta de crédito',
  'oifde.service.invoke.ttsAdvancedSetting.open': 'Activar',
  'oifde.service.invoke.cancelCollectFlag.no': 'No utilice la clave de estrella (*)',
  'oifde.qarecord.interface.desc': 'Registra el ID del cuestionario, el título de la pregunta, la intención de respuesta del usuario y la respuesta original del usuario.',
  'oifde.UserLevelService.description': 'Nivel de usuario (UserLevelService)',
  'oifde.service.invoke.workdays.Friday': 'Viernes',
  'oifde.service.invoke.ttsSoundEffect.female': 'Femenino',
  'oifde.service.invoke.cancelCollectFlag.yes': 'Utilice la tecla de estrella (*)',
  'oifde.service.invoke.voiceVariable.default.desc': 'Variable de voz o texto incluida en la respuesta. Se debe asignar un valor a esta variable antes de que se pueda ejecutar el nodo.',
  'oifde.service.invoke.voiceVariable.multiVoice.telnum.desc': 'Variable de voz o texto incluida en la respuesta. Se debe asignar un valor a esta variable antes de que se pueda ejecutar el nodo.(Para un tipo de Número de teléfono, la variable de respuesta está en el formato de texto numérico. Si la entrada es incorrecta, la reproducción de voz es anormal.)',
  'oifde.service.invoke.voiceVariable.multiVoice.number.desc': 'Variable de voz o texto incluida en la respuesta. Se debe asignar un valor a esta variable antes de que se pueda ejecutar el nodo.(Para un tipo de Números, la variable de respuesta está en el formato de texto numérico. Si la entrada es incorrecta, la reproducción de voz es anormal)',
  'oifde.service.invoke.voiceVariable.multiVoice.time.desc': 'Variable de voz o texto incluida en la respuesta. Se debe asignar un valor a esta variable antes de que se pueda ejecutar el nodo.(Para un tipo de Tiempo, la variable de respuesta está en el formato de hh:mm:ss. Si la entrada es incorrecta, la reproducción de voz es anormal)',
  'oifde.service.invoke.voiceVariable.multiVoice.date.desc': 'Variable de voz o texto incluida en la respuesta. Se debe asignar un valor a esta variable antes de que se pueda ejecutar el nodo.(Para un tipo de Fecha, la variable de respuesta está en el formato de aaaammdd. Si la entrada es incorrecta, la reproducción de voz es anormal)',
  'oifde.service.invoke.voiceVariable.multiVoice.price.desc': 'Variable de voz o texto incluida en la respuesta. Se debe asignar un valor a esta variable antes de que se pueda ejecutar el nodo.(Para un tipo de Precios, la variable de respuesta está en el formato de texto numérico. Unidad: minuto. Si la entrada es incorrecta, la reproducción de voz es anormal)',
  'oifde.service.invoke.workdays.Thursday': 'Jueves',
  'oifde.service.invoke.confirmCollectFlag.no': 'No utilice la tecla de la libra (#)',
  'oifde.service.invoke.voiceVariable.voice.desc': 'Variable de voz o texto incluida en la respuesta. Se debe asignar un valor a esta variable antes de que se pueda ejecutar el nodo (Para un tipo de voz estática, la variable de respuesta está en el formato de URL del archivo de grabación. Sólo se admite una URL.)',
  'oifde.service.invoke.ttsAdvancedSetting.desc': 'Si se habilitan estos ajustes para especificar la reproducción de voz basada en TTS.',
  'oifde.service.invoke.asrRecognizeType.normal': 'Frecuentes',
  'oifde.service.invoke.CallTransferCallingNum': 'Número de llamada',
  'oifde.service.invoke.ttsMRCPVendor': 'Parámetro extendido TTS',
  'oifde.service.invoke.timeSelect.serviceTimeParameter.desc': 'Período de servicio. Configure de 1 a 10 registros.',
  'oifde.service.invoke.ttsSoundEffect.desc': 'Seleccione si desea utilizar una voz masculina o femenina para la reproducción de voz basada en TTS.',
  'oifde.service.invoke.ttsSoundEffectCust': 'Efecto de sonido personalizado',
  'oifde.service.invoke.operationType': 'Tipo de operación',
  'oifde.service.invoke.timeSelect.specificNoWorkDateParameter': 'Fecha especificada de no servicio',
  'oifde.service.invoke.ttsMRCPVendorSetting.close': 'Desactivar',
  'oifde.service.invoke.asrRecognitionTimeout': 'Intervalo de tiempo de espera de reconocimiento',
  'oifde.service.invoke.ttsMRCPVendorSetting.open': 'Activar',
  'oifde.service.invoke.ttsMRCPVendorSetting': 'Valores predeterminados para los parámetros TTS extendidos.',
  'oifde.service.invoke.workdays.Tuesday': 'Martes',
  'oifde.service.invoke.voiceVariable.tts.desc': 'Variable de voz o texto incluida en la respuesta. Se debe asignar un valor a esta variable antes de que se pueda ejecutar el nodo (Para un tipo de texto de respuesta, la variable de respuesta está en el formato de texto TTS. Sólo se admite un texto TTS.)',
  'oifde.service.invoke.asrSpeechCompleteTimeout.desc': 'Intervalo de tiempo de espera cuando un abonado se detiene durante el reconocimiento ASR, en segundos. El valor oscila entre 300 y 2000. El valor predeterminado es 500.',
  'oifde.UserLevelService.keywords': 'Nivel de usuario',
  'oifde.qarecord.inputparam.questionTitle': 'Título de la pregunta',
  'oifde.service.invoke.ttsMRCPVendor.desc': 'Parámetros extendidos del servidor TTS. Establezca los parámetros según los requisitos del proveedor del servidor TTS. Un ejemplo es speed=GLOBAL.ttsSpeed;pitch=GLOBAL.ttsPitch;volume=GLOBAL.ttsVolume;voice_name=GLOBAL.voiceName;. Se admiten las variables GLOBAL y FLOW. Si un valor de variable no está dentro del rango estándar, se utiliza el valor predeterminado. El rango de valores de velocidad es [-500, 500] y el valor predeterminado es 0. El rango de valores de volumen es [0, 100] y el valor predeterminado es 50. El rango de valores de tono es [-500, 500], y el valor predeterminado es 0. El valor predeterminado de nombre_voz es cn_kefuman_common.',
  'oifde.service.invoke.recordMode.all': 'Suscriptor de registro y IVR',
  'oifde.service.invoke.breakMinTime': 'Duración mínima de reproducción de voz',
  'oifde.service.invoke.modelType.customization.desc': 'Interrupción indica si se puede interrumpir. Si puede interrumpirse, rellene el cuadro de entrada de tiempo mínimo de reproducción. Las variables se rellenan con true o false, true indica que se pueden reconocer y romper, y se puede pasar el tiempo de reproducción mínimo. False se puede reconocer, pero no está roto, y el valor predeterminado de false es el de rellenar los errores.',
  'oifde.service.invoke.ttsMRCPVendorSetting.desc': 'Después de activar los valores predeterminados para los parámetros TTS extendidos, las definiciones sobrescribirán el efecto de sonido y la velocidad de sonido seleccionados.',
  'oifde.service.invoke.ttsSoundSpeedCust': 'Velocidad personalizada',
  'oifde.service.invoke.operationType.close': 'Detener la grabación',
  'oifde.test.displayName': 'Servicio de muestra de prueba',
  'oifde.service.invoke.processRecord.open': 'Sí',
  'oifde.service.invoke.interfaceType': 'Tipo de interfaz',
  'oifde.qarecord.inputparam.answerIntent': 'Intención de respuesta',
  'oifde.service.invoke.confirmCollectFlag': 'Confirmar clave',
  'oifde.service.invoke.ttsAdvancedSetting': 'Configuración avanzada de TTS',
  'oifde.service.invoke.maxCollectLength.desc': 'Establezca el número máximo de dígitos recopilados.',
  'oifde.service.invoke.collectTimeout': 'Tiempo de espera de recopilación de dígitos',
  'oifde.service.invoke.collectTimeout.desc': 'Establece el intervalo de tiempo de espera de recopilación de dígitos. Se admiten las variables FLOW y GLOBAL. El valor también puede ser un número entero comprendido entre 1 y 120. Si el valor no cumple con los requisitos, se utiliza el valor predeterminado 5.',
  'oifde.service.invoke.CallTransferCallingNum.desc': 'Este valor se utiliza como número de llamada si el tipo de transferencia de llamada está establecido en 5 . (indicando que la llamada se transfiere a un tercero).El número de llamada debe ser un código de acceso asignado por el sistema o un parámetro personalizado.Ejemplo: 660001 o FLOW.accessCode.',
  'oifde.service.invoke.CallTransferCallingNum.lastAgentDesc': 'El número de llamada es el número de llamada entrante del cliente.Por ejemplo, SYS.callingNumber',
  'oifde.service.invoke.timeSelect.specificNoWorkDateParameter.title': 'Si se configura una fecha no de trabajo especificada, la fecha no de servicio se considera como un día no laborable con la prioridad más alta.',
  'oifde.service.invoke.voiceTtsMixed': 'Voz y texto',
  'oifde.qarecord.inputparam.originalAnswer': 'Respuesta original',
  'oifde.service.invoke.recordMode': 'Modo',
  'oifde.yugaoRecordService.keywords': 'Grabación de VoiceCyber',
  'oifde.service.invoke.timeSelect.specificWorkDateParameter': 'Fecha de servicio especificada',
  'oifde.service.invoke.asrAdvancedSetting.open': 'Activar',
  'oifde.service.invoke.CallTransferType.transferToSkillQueueByRoutingCode': 'Cola de habilidades (código de extensión de ruta)',
  'oifde.service.invoke.CallTransferType.transferToIVR': 'IVR',
  'oifde.service.invoke.CallTransferType.transferToSkillQueueBySkillName': 'Cola de habilidades (nombre)',
  'oifde.service.invoke.CallTransferType.transferToAgentByID': 'Agente',
  'oifde.service.invoke.CallTransferType.transferToThirdPartyNumber': 'Número de terceros',
  'oifde.service.invoke.CallTransferType.onlineDigitalAssistant': 'asistencia digital en línea',
  'oifde.service.invoke.CallTransferType.transferToLastAgent': 'Clientes multimedia',
  'oifde.service.invoke.CallTransferType.transferToSkillQueueBelongAgent': 'Cola de habilidades (agente multimedia)',
  'oifde.service.invoke.CallTransferType.transferToSkillQueueByRouteConfig': 'Transferir a la cola de habilidades (Ruta personalizada)',
  'oifde.service.invoke.CallTransferType.transferToMultimediaMessageToLastAgent': 'Mensajes de invitados conocidos',
  'oifde.service.invoke.CallTransferType.transferToMultimediaMessageToSkillQueue': 'Mensaje de la cola  habilidades (nombre o código  acceso)',
  'oifde.service.invoke.CallTransferType.transferToMultimediaMessageToAgent': 'Mensaje del agente',
  'oifde.service.invoke.CallTransferTypeGroup.multimediaMessage': 'Mensaje multimedia',
  'oifde.service.invoke.CallTransferRecordFlag': 'Activar grabación',
  'oifde.service.invoke.CallTransferRecordFlag.desc': '', // 本身就为空不展示
  'oifde.service.invoke.recordPath': 'Nombre de archivo de grabación',
  'oifde.service.invoke.recordPath.desc': 'Introduzca la variable para almacenar el nombre del archivo de grabación.',
  'oifde.service.invoke.recordServiceNodeID': 'ID de nodo de servicio de registro',
  'oifde.service.invoke.recordServiceNodeID.desc': 'Introduzca la variable que almacena el ID del nodo de servicio de grabación (utilizado en el modo de grupo de centro de llamadas).',
  'oifde.service.invoke.languageType.language0': 'Putonghua',
  'oifde.service.invoke.languageType.language1': 'Inglés',
  'oifde.service.invoke.languageType.language2': 'Chino(cantonés)',
  'oifde.service.invoke.languageType.language3': 'Checo',
  'oifde.service.invoke.languageType.language4': 'Danés',
  'oifde.service.invoke.languageType.language5': 'Holandés',
  'oifde.service.invoke.languageType.language6': 'Terminado',
  'oifde.service.invoke.languageType.language7': 'Francés',
  'oifde.service.invoke.languageType.language8': 'Alemán',
  'oifde.service.invoke.languageType.language9': 'Griego',
  'oifde.service.invoke.languageType.language10': 'Húngaro',
  'oifde.service.invoke.languageType.language11': 'Islandés',
  'oifde.service.invoke.languageType.language12': 'Italiano',
  'oifde.service.invoke.languageType.language13': 'Japonés',
  'oifde.service.invoke.languageType.language14': 'Coreano',
  'oifde.service.invoke.languageType.language15': 'Noruego',
  'oifde.service.invoke.languageType.language16': 'Polaco',
  'oifde.service.invoke.languageType.language17': 'Portugués',
  'oifde.service.invoke.languageType.language18': 'Ruso',
  'oifde.service.invoke.languageType.language19': 'Eslovaco',
  'oifde.service.invoke.languageType.language20': 'Espa?ol',
  'oifde.service.invoke.languageType.language21': 'Sueco',
  'oifde.service.invoke.languageType.language22': 'Turco',
  'oifde.service.invoke.languageType.language23': 'Uzbekistán',
  'oifde.service.invoke.languageType.language24': 'Tailandia',
  'oifde.service.invoke.languageType.language80': 'Swasiri',
  'oifde.service.invoke.languageType.language81': 'árabe',
  'oifde.service.invoke.languageType.language82': 'Indio del Norte',
  'oifde.service.invoke.languageType.language83': 'Filipino',
  'oifde.service.invoke.languageType.language84': 'Camboyano',
  'oifde.service.invoke.languageType.language85': 'Bengalí',
  'oifde.service.invoke.languageType.language86': 'Palestino',
  'oifde.service.invoke.languageType.language87': 'Vietnamita',
  'oifde.service.invoke.languageType.language88': 'Indonesio',
  'oifde.service.invoke.languageType.language89': 'Nepalés',
  'oifde.service.invoke.languageType.language90': 'Malgache',
  'oifde.service.invoke.languageType.language92': 'Etíope',
  'oifde.service.invoke.languageType.language93': 'Malasio',
  'oifde.service.invoke.variableType.type1': 'Número de teléfono',
  'oifde.service.invoke.variableType.type3': 'Número',
  'oifde.service.invoke.variableType.type4': 'time(hh:mm:ss)',
  'oifde.service.invoke.variableType.type5': 'Fecha (aaaammdd)',
  'oifde.service.invoke.variableType.type6': 'Precios',
  'oifde.ringService.displayName': 'Anillos',
  'oifde.assignValueService.displayName': 'Asignar',
  'oifde.transferService.displayName': 'Trasnferencia',
  'oifde.service.inpytOrDrag': 'introducir o arrastrar variables',
  'oifde.service.operateAsFunction': 'Operar como el método proporcionado',
  'oifde.basicOdfsSubFlowService.displayName': 'Subproceso (inteligente)',
  'oifde.basicCcivrSubFlowService.displayName': 'Subproceso (normal)',
  'oifde.yugaoRecordServiceNew.displayName': 'Colección de grabación',
  'oifde.conditionServiceNew.displayName': 'Juicio de rama',
  'oifde.service.invoke.callTranseferService.transData.wait': 'Cola en cola',
  'oifde.service.invoke.callTranseferService.transData.data': 'Transferencia de datos',
  'oifde.service.invoke.callTranseferService.transData.isSpecifyAccessCode': 'Código de acceso al canal especificado',
  'oifde.service.invoke.callTranseferService.transData.type': 'Modo de cliente conocido',
  'oifde.service.invoke.callTranseferService.transData.time': 'Rango de tiempo (minutos)',
  'oifde.service.invoke.callTranseferService.transData.timeTips': 'Ejemplo: 100: Transferir al agente que se ha contactado recientemente en 100 minutos.El valor recomendado oscila entre 1 y 10080.',
  'oifde.service.invoke.callTranseferService.transData.timeErrorTips': 'Introduzca un entero positivo o una variable',
  'oifde.service.invoke.callTranseferService.transData.starttime': 'Rango de fechas',
  'oifde.service.invoke.callTranseferService.transData.starttimeTips': 'Agente contactado recientemente en el intervalo de fechas especificado',
  'oifde.service.invoke.callTranseferService.transData.type.selectTime': 'Especificar minutos',
  'oifde.service.invoke.callTranseferService.transData.type.selectStartTime': 'Fecha especificada',
  'oifde.service.invoke.callTranseferService.transData.type.currentDay': 'El día',
  'oifde.service.invoke.callTranseferService.transData.paramValueTips': "Introduzca un valor de parámetro. ",
  'oifde.service.invoke.callTranseferService.transData.varTips': "Se pueden introducir variables. Las variables globales comienzan con GLOBAL, las variables de flujo, las variables de sistema comienzan con SYS, las variables TUC comienzan con TOC y las variables de solicitud IVR comienzan con IVRREQUEST. Utilice {'${}'} para ajustar variables, por ejemplo, {'${FLOW.variable}'}.",
  'oifde.service.invoke.ruleValidationService.name': 'Verificación de reglas V2',
  'oifde.service.invoke.ruleValidationService.variable': 'Variable a verificar',
  'oifde.service.invoke.ruleValidationService.specialEndKey': 'Finalización de teclas especiales',
  'oifde.service.invoke.ruleValidationService.specialEndKey.option1': 'Con #',
  'oifde.service.invoke.ruleValidationService.specialEndKey.option2': 'Con un asterisco (*)',
  'oifde.service.invoke.ruleValidationService.specialEndKey.option3': 'Con # o *',
  'oifde.service.invoke.ruleValidationService.specialEndKey.option4': 'Sin teclas especiales',
  'oifde.service.invoke.ruleValidationService.containsChar': 'Contiene caracteres',
  'oifde.service.invoke.ruleValidationService.containsChar.option1': 'Coincide con los números',
  'oifde.service.invoke.ruleValidationService.containsChar.option2': 'Coincide con las letras mayúsculas',
  'oifde.service.invoke.ruleValidationService.containsChar.option3': 'Coincide con las letras minúsculas',
  'oifde.service.invoke.ruleValidationService.containsChar.option4': 'Coincide con letras, dígitos y guiones bajos',
  'oifde.service.invoke.ruleValidationService.containsChar.option5': 'Carácter vacío',
  'oifde.service.invoke.ruleValidationService.validationRules': 'Regla de verificación',
  'oifde.service.invoke.ruleValidationService.validationRules.ruleParam': 'Parámetros de regla',
  'oifde.service.invoke.ruleValidationService.validationRules.ruleParamValueTips': "Puede introducir variables. Las variables globales comienzan con GLOBAL, las variables de flujo comienzan con FLOW, las variables de sistema comienzan con SYS, las variables TUC comienzan con TOC y las variables de solicitud IVR comienzan con IVRREQUEST.Por ejemplo: FLOW.variable",
  'oifde.service.invoke.ruleValidationService.validationRules.minLength': 'Longitud mínima',
  'oifde.service.invoke.ruleValidationService.validationRules.minLength.valueTips': 'Introduzca la longitud mínima.',
  'oifde.service.invoke.ruleValidationService.validationRules.maxLength': 'Longitud máxima',
  'oifde.service.invoke.ruleValidationService.validationRules.maxLength.valueTips': 'Introduzca la longitud máxima.',
  'oifde.service.invoke.ruleValidationService.validationRules.rangeLength': 'Rango de longitud',
  'oifde.service.invoke.ruleValidationService.validationRules.min': 'Mínimo',
  'oifde.service.invoke.ruleValidationService.validationRules.min.valueTips': 'Introduzca un valor mínimo.',
  'oifde.service.invoke.ruleValidationService.validationRules.max': 'Máximo',
  'oifde.service.invoke.ruleValidationService.validationRules.max.valueTips': 'Introduzca el valor máximo.',
  'oifde.service.invoke.ruleValidationService.validationRules.range': 'Rango de valores',
  'oifde.service.invoke.ruleValidationService.validationRules.email': 'Correo Electrónico',
  'oifde.service.invoke.ruleValidationService.validationRules.url': 'Dirección URL',
  'oifde.service.invoke.ruleValidationService.validationRules.dateFormat': 'Formato de tiempo',
  'oifde.service.invoke.ruleValidationService.validationRules.dateFormat.valueTips': 'Introduzca un formato de hora, como Fecha (yyyy-MM-dd HH) o Fecha y hora (yyyy-MM-dd HH:mm:ss).',
  'oifde.service.invoke.ruleValidationService.validationRules.number': 'Dígitos decimales',
  'oifde.service.invoke.ruleValidationService.validationRules.digits': 'Entero positivo',
  'oifde.service.invoke.ruleValidationService.validationRules.ip': 'Dirección IP',
  'oifde.service.invoke.ruleValidationService.validationRules.alpha': 'Subrayado alfanumérico (no comienza con un dígito)',
  'oifde.service.invoke.timeSelect.workDate': 'Especificar fecha de trabajo',
  'oifde.service.invoke.timeSelect.noWorkDate': 'Especificar fecha de no trabajo',
  'oifde.service.invokemethod.offsetMonth.desc': 'Offset por mes',
  'oifde.service.invokemethod.offsetMonth.parm1.desc': 'Variable de tiempo a desplazar, una cadena de caracteres en el formato aaaMM',
  'oifde.service.invokemethod.offsetMonth.parm2.desc': 'Desfase, que es un entero y puede ser un valor negativo.',
  'oifde.service.invokemethod.offsetMonth.resultObj.desc': 'El objeto devuelto es los datos de desplazamiento.',
  'oifde.service.invokemethod.offsetHour.desc': 'Offset por hora',
  'oifde.service.invokemethod.offsetHour.parm1.desc': 'Variable de tiempo a desplazar, una cadena de caracteres en el formato aaaMMddHHmmss',
  'oifde.service.invokemethod.offsetHour.parm2.desc': 'Desfase, que es un entero y puede ser un valor negativo.',
  'oifde.service.invokemethod.offsetHour.resultObj.desc': 'El objeto devuelto es los datos de desplazamiento.',
  'oifde.interfaceInvoke.displayName': 'Invocación de interfaz',
  'oifde.service.invoke.yugaoRecord.type': 'Tipo:',
  'oifde.service.invoke.yugaoRecord.mode': 'Modo:',
  'oifde.service.invoke.subflow.subFlowNode': 'Subproceso',
  'oifde.service.invokemethod.group.mathodType4': 'Método de operación de fecha',
  'oifde.transdata.get.example': 'Por ejemplo, si los datos asociados son {\'{\'}"data":{\'{\'}"test": "111"{\'}\'}{\'}\'}, puede introducir data.test en el valor de variable para obtener los datos "111".',
  'oifde.service.invoke.basicAvatarReplyService.name': 'Respuesta de Avatar',
  'oifde.service.invoke.basicAvatarReplyService.actionType': 'Operación',
  'oifde.service.invoke.basicAvatarReplyService.start': 'Iniciar',
  'oifde.service.invoke.basicAvatarReplyService.image': 'Imágenes',
  'oifde.service.invoke.basicAvatarReplyService.avatarImage': 'Imagen de avatar',
  'oifde.service.invoke.basicAvatarReplyService.ttsSpeaker': 'Portavoz',
  'oifde.service.invoke.basicAvatarReplyService.compositeVideoSetting': 'Configuración de vídeo compuesto',
  'oifde.service.invoke.basicAvatarReplyService.compositeVideoSetting.desc': 'Configuración de vídeo compuesto, que afecta directamente al efecto de visualización digital de la persona. Habilite esta función cuando sea necesario.',
  'oifde.service.invoke.basicAvatarReplyService.imageSetting': 'Configuración de imagen y vídeo',
  'oifde.service.invoke.basicAvatarReplyService.action': 'Actuaciones',
  'oifde.service.invoke.basicAvatarReplyService.frontImage': 'Imagen frontal',
  'oifde.service.invoke.basicAvatarReplyService.backendImage': 'Imagen de backend',
  'oifde.service.invoke.basicAvatarReplyService.frontVideo': 'Vídeo frontal',
  'oifde.service.invoke.basicAvatarReplyService.backendVideo': 'Vídeo de backend',
  'oifde.service.invoke.basicAvatarReplyService.xPosition': 'Abscisas',
  'oifde.service.invoke.basicAvatarReplyService.yPosition': 'Ordinar',
  'oifde.service.invoke.basicAvatarReplyService.scale': 'Escala',
  'oifde.service.invoke.basicAvatarReplyService.beginTime': 'Hora de inicio (ms)',
  'oifde.service.invoke.basicAvatarReplyService.duration': 'Duración de la pantalla (ms)',
  'service.invoke.virtualHumanConfig': 'Configuración humana virtual',
  'service.virtualHuman.videoParam': 'Parámetros de edición de vídeo',
  'service.virtualHuman.captions': 'Subtítulos',
  'service.invoke.asrAdvancedSetting.open': 'Activar',
  'service.invoke.asrAdvancedSetting.close': 'Desactivar',
}
