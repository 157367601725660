<template>
  <el-dialog v-model="isShow"
            :title="$t('SM.ORGSTAFF.TITLE.CONTACT_INFO')"
             width="616"
             class="aicc-dialog-limit-height"
             draggable>
    <advancedSearch ref="AiccAdvancedSearch"
                    :templateMetaData="searchConfig"
                    @handleSearch="search"
                    @handleRefresh="refresh"
    />
    <el-table :data="tableData"
              ref="multipleTable"
              tooltip-effect="light"
              height="430"
              :row-key="getRowKey">
      <el-table-column
          property="employeeName"
          :label="$t('SM.ORGSTAFF.LABEL.EMPLOYEE_EMPLOYEENAME')"
          show-overflow-tooltip="true"
          width="310"/>
      <el-table-column
          property="employeeCode"
          :label="$t('SM.ORGSTAFF.LABEL.EMPLOYEE_ACCOUNT')"
          show-overflow-tooltip="true"
          width="310"/>
    </el-table>
    <el-pagination
        v-model:page-size="pageSize"
        :page-sizes="pageSizes"
        :total="total"
        v-model:current-page="currentPage"
        :small="false"
        :disabled="false"
        :background="false"
        layout="sizes, total, prev, pager, next"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
    />
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="isShow = false">{{$t('cms.indexmonitor.field.cancel')}}</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import i18n from "@/lang";
import AiccElMessage from '@/utils/el-message'
import advancedSearch from "@/components/aicc-advancedSearch/index.vue";
import {queryOrgContactsByName,queryEmployeeList,queryOrgContacts} from '@/views/sumweb/api/index.js'
import {toRaw,reactive,ref,computed} from 'vue';
import { checkUserName} from '@/views/sumweb/utils/validator'
const $t = i18n.global.t
import cookieUtils from "@/utils/cookie"

export default {
  name: "employeeManager",
  components: {
    advancedSearch
  },
  data() {
    return {
      isShow: false,
      stateItems: [
      {
                "value": "1",
                "label": $t("SM.ORGSTAFF.LABEL.EMPLOYEE_STATUS_NORMAL")
            },
            {
                "value": "2",
                "label": $t("SM.ORGSTAFF.LABEL.EMPLOYEE_STATUS_PAUSE")
            },
            {
                "value": "3",
                "label": $t("SM.ORGSTAFF.LABEL.EMPLOYEE_STATUS_FORBID")
            }
        ],
      tableData: [],
      condition: {},
      orgId:"",
      dataShow:{data:[]},
      offset: 0,
      limit: 20,
      total: 0,
      pageSize: 20,
      currentPage: 1,
      pageSizes: [10,20,50,100],
      selected: [],
      selectedManger: [],
      defaultProps :{
        children: 'children',
        label: 'orgName',
        value:'orgId'
      },
      searchConfig: {
        basicConfig: {
          basicSearch: [
            {
              searchType: 'primary', // primary: 有搜索的输入框；select: 下拉框
              modelValue: 'userName',
              placeholderValue: $t('SM.ORGSTAFF.LABEL.EMPLOYEE_EMPLOYEENAME'),
              rules: [
                {
                  validator: checkUserName,
                }
              ],
              colConfig: {
                lg: 5,
                xl: 6
              }
            }
          ],
          iconButton: [
            {
              buttonType: 'refresh',
              eventName: 'handleRefresh',
              buttonTitle: $t('cms.export.field.refresh'),
              
            },
          ]
        },
      }
    }
  },
  methods: {
    open() {
      if(this.$refs.AiccAdvancedSearch){
        console.log(this.$refs.AiccAdvancedSearch);
        this.$refs.AiccAdvancedSearch.form.resetFields();
      }
      this.orgId = this.orgid
      this.dataShow.data = toRaw(this.orgData)
      this.selectedManger = toRaw(this.selectedmanger)
      this.condition.userName = ""
      this.offset = 0
      this.limit = 20
      this.isShow = true
      this.callBackendAgentInfo()
    },
    dealStatus(row, column) {
    var data = row[column.property];
    if(data == "1"){
        return $t("SM.ORGSTAFF.LABEL.EMPLOYEE_STATUS_NORMAL")
    }else if(data == "2"){
        return $t("SM.ORGSTAFF.LABEL.EMPLOYEE_STATUS_PAUSE")
    }else if(data == "3"){
        return $t("SM.ORGSTAFF.LABEL.EMPLOYEE_STATUS_FORBID")
    }
    },
    search({params}) {
      this.doSearch(params)
    },
    refresh({params}) {
      this.doSearch(params)
    },
    doSearch(params) {
      this.$refs.AiccAdvancedSearch.form.validate(valid => {
        if (valid) {
          this.offset = 0
          this.currentPage = 1
          if (params.userName) {
            params.userName = params.userName.trim()
          }
          this.condition = params;
          this.callBackendAgentInfo()
        }
      })
    },
    handleCurrentChange(currentPage) {
      this.offset = (currentPage - 1) * this.limit
      this.callBackendAgentInfo()
    },
    handleSizeChange(count) {
      this.limit = count
      this.offset = 0
      this.currentPage = 1
      this.callBackendAgentInfo()
    }, 
    callBackendAgentInfo() {
        if(this.condition.userName){
            let param = {
                "empname":this.condition.userName,
                "offset":this.offset,
                "limit":this.limit 
            }
            queryOrgContactsByName(cookieUtils.getCookie('tenant_space_id'),this.orgId,param).then(data => {
                this.total = data.total
                this.tableData = data.orgContacts;
            })
        }else{
            let param = {
                "offset":this.offset,
                "limit":this.limit 
            }
            queryOrgContactsByName(cookieUtils.getCookie('tenant_space_id'),this.orgId,param).then(data => {
            this.total = data.total
            this.tableData = data.orgContacts;
        })
        }
    },
    getRowKey(row) {
      return row.userId
    },
    clearSelected() {
      this.$refs.multipleTable.clearSelection()
    },
  },
  props: {
    orgData: {
      type: Object,
      default: null
    },
    selectedmanger: {
      type: Object,
      default: null
    },
    orgid:{
      type: String,
      default: ""
    },
  }
}
</script>

<style scoped >
.width-400px {
  width: 100px;
  border-radius: 4px;
}


.el-select {
    width: 50% !important;
}
</style>