export default {
  "webclient.body.message.bot.recommend": "เดาว่าคุณต้องการที่จะถาม",
  "webclient.body.message.card.url": "คัดลอกลิงก์",
  "webclient.body.message.cobrowse.agree": "ยอมรับ",
  "webclient.body.message.cobrowse.cancel": "ยกเลิก",
  "webclient.body.message.cobrowse.target": "เป้าหมายการท่องเว็บ:",
  "webclient.body.message.cobrowse.title": "เจ้าหน้าที่ร้องขอการเรียกดูร่วมกันกับคุณ",
  "webclient.body.message.file.download": "ดาวน์โหลด",
  "webclient.body.message.file.download.again": "ดาวน์โหลดอีกครั้ง",
  "webclient.body.message.locate.latitude": "ละติจูด",
  "webclient.body.message.locate.longitude": "ลองติจูด",
  "webclient.body.message.recall": "ถอนข้อความ",
  "webclient.body.name.assistant": "ผู้ช่วยอัจฉริยะ",
  "webclient.body.tip.leave.click": "คลิกที่นี่เพื่อฝากข้อความ",
  "webclient.body.tip.leave.message": "โปรดหากต้องการ",
  "webclient.body.tip.leave.message.finish": "ส่งข้อความของคุณเรียบร้อยแล้ว คุณสามารถฝากข้อความได้อีกครั้ง",
  "webclient.body.tip.privacy": "เพื่อปรับปรุงคุณภาพการบริการ เราจะเก็บประวัติการแชทของคุณกับตัวแทนฝ่ายบริการลูกค้า บอท เราจะใช้การเข้ารหัส การควบคุมการเข้าถึง และอื่นๆ เพื่อให้ข้อมูลปลอดภัย",
  "webclient.body.tip.queue.cancel": "ยกเลิกคิว",
  "webclient.body.tip.queue.cancel.done": "ยกเลิกการเข้าคิวเรียบร้อยแล้ว กรุณาส่งข้อความต่อไป",
  "webclient.body.tip.queue.cancel.robot": "ยกเลิกการเข้าคิวสําเร็จ กําลังเชื่อมต่อกับหุ่นยนต์...",
  "webclient.body.tip.queue.part1": "คุณกําลังอยู่ในอันดับที่ ",
  "webclient.body.tip.queue.part2": " บิต คาดว่าจะยังคงรอ ",
  "webclient.body.tip.queue.part3": " วินาที ",
  "webclient.body.tip.queue.timeout": "คิวหมดเวลา",
  "webclient.body.tip.session.end": "เซสชันสิ้นสุดแล้ว",
  "webclient.body.tip.session.reconnect": "เริ่มเซสชันใหม่",
  "webclient.body.tip.session.timeout": "คุณไม่ได้ตอบกลับภายในเวลาที่กําหนด เซสชันได้สิ้นสุดโดยอัตโนมัติ คุณสามารถ",
  "webclient.body.tip.token.invalid": "token ทํางานหรือลงชื่อเข้าใช้ที่อื่น",
  "webclient.body.title.call.disconnect": "ตัดการเชื่อมต่อ",
  "webclient.body.title.call.end": "สิ้นสุดการโทร",
  "webclient.body.title.call.fail": "ล้มเหลว",
  "webclient.body.title.call.queue": "เข้าแถว",
  "webclient.body.title.call.start": "กลาง",
  "webclient.body.title.call.timeout": "หมดเวลาในคิว",
  "webclient.body.title.call.transfer": "[เปิด] กําลังย้าย",
  "webclient.box.tip.click.cannot.link": "ไม่สามารถเชื่อมต่อกับเกตเวย์ WebRTC",
  "webclient.box.tip.click.check.device.fail": "ตรวจสอบว่าการสนับสนุนการคลิกเพื่อโทรล้มเหลว",
  "webclient.box.tip.click.no.audio.input": "อุปกรณ์ป้อนข้อมูลเสียงไม่พร้อมใช้งาน",
  "webclient.box.tip.click.no.audio.output": "ไม่มีอุปกรณ์ส่งออกเสียง",
  "webclient.box.tip.click.no.video.input": "ไม่สามารถใช้งานอุปกรณ์อินพุตวิดีโอได้",
  "webclient.box.tip.click.not.support.browser": "เบราว์เซอร์ปัจจุบันไม่รองรับ WebRTC",
  "webclient.box.tip.click.not.support.channel": "ช่องปัจจุบันไม่รองรับการคลิกเพื่อโทร",
  "webclient.box.tip.click.not.support.tenant": "ไม่รองรับ WebRTC ในขณะนี้",
  "webclient.box.tip.cobrowse.no.auth": "ตัวแทนไม่ได้รับอนุญาตให้ทํางานร่วมกันบนหน้าเว็บ",
  "webclient.chat.nickname.system": "ระบบ",
  "webclient.dialog.evaluate.placeholder": "โปรดป้อนคะแนนของคุณ",
  "webclient.dialog.evaluate.title": "โปรดประเมินการให้บริการของการบริการลูกค้า!",
  "webclient.dialog.leave.format.content": "ห้ามเว้นว่างเนื้อหาข้อความและต้องไม่มีอักขระพิเศษ",
  "webclient.dialog.leave.format.phone": "ห้ามเว้นว่างหมายเลขและสามารถมีได้เฉพาะตัวเลข - หรือ + เท่านั้น",
  "webclient.dialog.leave.phone": "โปรดป้อนหมายเลขโทรศัพท์",
  "webclient.dialog.leave.success": "ฝากข้อความไว้สําเร็จแล้ว",
  "webclient.dialog.leave.text": "โปรดฝากข้อความ",
  "webclient.dialog.leave.title": "ฝากข้อความ",
  "webclient.dialog.selectArea.title": "โปรดเลือกพื้นที่ที่ต้องการบัง",
  "webclient.dialog.video.default": "ค่าเริ่มต้น",
  "webclient.dialog.video.hide": "ซ่อนวิดีโอภายในเครื่อง",
  "webclient.dialog.video.large": "ใหญ่",
  "webclient.dialog.video.medium": "ปานกลาง",
  "webclient.dialog.video.pause": "ปิดวิดีโอ",
  "webclient.dialog.video.share.off": "หยุดการแชร์",
  "webclient.dialog.video.share.on": "การแชร์พื้นที่ทํางาน",
  "webclient.dialog.video.size": "ขนาดวิดีโอ",
  "webclient.dialog.video.small": "เล็ก",
  "webclient.dialog.video.start": "เริ่มวิดีโอ",
  "webclient.footer.label.send.message": "ส่งข้อความ",
  "webclient.footer.label.share.direct": "แบ่งปันโดยตรง",
  "webclient.footer.label.share.sensitive": "เลือกพื้นที่ที่ละเอียดอ่อน",
  "webclient.footer.label.stop.direct": "หยุดการแบ่งปัน",
  "webclient.footer.placeholder.richtext": "โปรดกรอกคําถามที่คุณต้องการสอบถาม",
  "webclient.footer.send.fail.content.big": "เนื้อหาที่ส่งยาวเกินความยาวสูงสุดที่กําหนด (500 อักขระ)",
  "webclient.footer.send.fail.file.format": "รูปแบบไฟล์ไม่ตรงตามข้อกําหนด",
  "webclient.footer.send.fail.file.other": "ไม่สามารถส่งไฟล์ได้",
  "webclient.footer.send.fail.file.screen": "ไม่สามารถส่งไฟล์ภาพหน้าจอได้",
  "webclient.footer.send.fail.file.size.audio": "ขนาดเสียงต้องไม่เกิน",
  "webclient.footer.send.fail.file.size.common": "ขนาดไฟล์ต้องไม่เกิน",
  "webclient.footer.send.fail.file.size.video": "ขนาดวิดีโอต้องไม่เกิน",
  "webclient.footer.send.fail.no.file": "โปรดเลือกไฟล์",
  "webclient.footer.send.fail.picture.with.text": "ไม่รองรับการส่งข้อความรูปภาพที่มีข้อความหรืออีโมติคอน",
  "webclient.footer.tip.cobrowse.share": "การดําเนินการปัจจุบันจะแชร์หน้าเบราว์เซอร์ของคุณ อาจมีข้อมูลส่วนตัวของคุณ ดําเนินการต่อหรือไม่",
  "webclient.footer.tip.connect.fail": "การเชื่อมต่อล้มเหลว โปรดลองอีกครั้งหรือติดต่อผู้ดูแลระบบ",
  "webclient.footer.tip.download": "เนื้อหาแชทที่คุณดาวน์โหลดมาในรูปแบบ HTML และเนื้อหามัลติมีเดียในแชทจะมีผลตามกาลเวลา ขอแนะนําให้แปลงเป็นไฟล์ PDF ทันทีหลังจากดาวน์โหลดแล้ว ขั้นตอนในการแปลง HTML เป็น PDF: 1. เปิดไฟล์ HTML ที่คุณต้องการแปลงในเบราว์เซอร์ของคุณ 2. กด Ctrl+P (Windows) หรือ Command+P (Mac) เพื่อเปิดกล่องโต้ตอบการพิมพ์ 3. ในกล่องโต้ตอบการพิมพ์ให้เลือก \"เครื่องพิมพ์ปลายทาง\" เป็น \"บันทึกเป็น PDF\" 4. คลิกปุ่ม \"บันทึก\" เพื่อแปลงหน้า HTML เป็นรูปแบบ PDF และบันทึกลงในคอมพิวเตอร์เครื่องของคุณ",
  "webclient.footer.tooltip.audio": "เสียง",
  "webclient.footer.tooltip.cobrowse": "แบ่งปัน",
  "webclient.footer.tooltip.download": "ดาวน์โหลดการสนทนา",
  "webclient.footer.tooltip.emotion": "อารมณ์",
  "webclient.footer.tooltip.evaluation": "ประเมินผล",
  "webclient.footer.tooltip.file": "อัปโหลดไฟล์",
  "webclient.footer.tooltip.more": "เพิ่มเติม",
  "webclient.footer.tooltip.send": "ส่ง",
  "webclient.footer.tooltip.send.way": "วิธีการส่ง",
  "webclient.footer.tooltip.video": "วิดีโอ",
  "webclient.header.title.input": "อีกฝ่ายกําลังป้อน",
  "webclient.header.title.service": "AICC ฝ่ายบริการลูกค้า",
  "webclient.header.tooltip.close": "ปิดบริการ",
  "webclient.header.tooltip.transfer.agent": "แปลงร่าง",
  "webclient.header.tooltip.transfer.robot": "โอนบอท",
}