import i18n from '@/lang/index'

const $t = i18n.global.t

export const TASK_TYPE_ITEMS = {
    '1': $t('isales.taskmanagement.field.intelligence'),
    '2': $t('isales.taskmanagement.field.prediction'),
    '3': $t('isales.tasktype.field.automatic'),
    '4': $t('isales.taskmanagement.field.manual'),
    '5': $t('isales.tasktype.field.preview'),
    '6': $t('isales.tasktype.field.preemption')
}

export const TASKRESULT_EXPORT_TYPE_ENUM = {
    '1': $t('isales.taskmanagement.title.out.callresult'),
    '2': $t('isales.taskmanagement.title.callRecord'),
    '3': $t('isales.taskmanagement.title.agentStatistics')
}

export const TASKRESULT_EXPORT_RESULT_ENUM = {
    '0': $t('isales.taskresultexport.title.exported'),
    '1': $t('isales.taskresultexport.title.progress'),
    '2': $t('isales.taskresultexport.title.success'),
    '3': $t('isales.taskresultexport.title.fail')
}
