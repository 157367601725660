<template>
  <div>
    <sweet-dialog v-model="dialogVisible" :close-on-click-modal="false" :title="$t('isales.management.title.selectResultCode')" width="616px" class="aicc-dialog-limit-height">
      <div style="width:350px; margin-bottom: 20px">
          <sweet-input v-model="searchDisplayResult" :placeholder="$t('isales.result.search.displayResult')" @change="initResultCode" clearable :prefix-icon="Search" maxlength="50"></sweet-input>
      </div>
      <aicc-table
          :tableData="resutCodeList"
          :tableColumns="tableColumns"
          :tableTools="{ showPagination: true, columnConfig: false }"
          :paginationConfig="paginationConfig"
          @handlePagination="handlePagination"
          :row-key="getKey"
          ref='refAiccTable'
          :height="430">
        <template #displayResult="scope">
          <div style="cursor: pointer" @click="resultView(scope.row)">
            <sweet-tooltip effect="light" :content="$t('isales.common.title.view')" placement="top">
              <sweet-button type="primary" text>{{ scope.row.displayResult }}</sweet-button>
            </sweet-tooltip>
          </div>
        </template>
      </aicc-table>
      <template #footer>
      <span class="dialog-footer">
        <sweet-button @click="dialogVisible = false">{{ $t('isales.common.title.cancel') }}</sweet-button>
        <sweet-button type="primary" @click="confirm">{{ $t('isales.common.title.confirm') }}</sweet-button>
      </span>
      </template>
    </sweet-dialog>
  </div>
  <!--新建特殊日期-->
  <isalesOutbountResultCreate v-model:show="createOutbountResultDialogVisible"
                              v-if="createOutbountResultDialogVisible"
                              v-model:mode="mode"
                              v-model:title="title"
                              v-model:outBoundResult="outBoundResult"
                              @isalesOutbountResultCreateConfirm="isalesOutbountResultCreateConfirm">
  </isalesOutbountResultCreate>
</template>

<script>
import {queryResultCode} from "@/views/isales/api/systemAutoCallTask.js";
import {ElMessage} from "element-plus";
import {Search} from '@element-plus/icons-vue';
import isalesOutbountResultCreate from "@/views/isales/views/outboundresult/isalesOutbountResultCreate.vue";

export default {
  name: 'isalesResultCodeSelect',
  components: {
    isalesOutbountResultCreate
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    selectResultItems:{
      type: Array,
      default: []
    }
  },
  data() {
    return {
      createOutbountResultDialogVisible: false,
      mode: '',
      title: '',
      Search,
      resutCodeList: [],
      tableSelectItem:[],
      tableColumns: [
        {
          type: 'selection',
          'reserve-selection':true
        },
        {
          label: this.$t('isales.result.resultCode'),
          prop: 'resultCode',
        },
        {
          label: this.$t('isales.result.displayResult'),
          slot: 'displayResult',
        }
      ],
      paginationConfig: {
        pageSize: 5, // 每页条数
        total: 0, //总条数
        layout: "total, prev, pager, next" // 分页组件布局配置
      },
      limit: 5,
      offset: 0,
      searchDisplayResult: '',
      outBoundResult: null
    }
  },
  created() {
    this.initResultCode()
  },
  computed: {
    dialogVisible: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      }
    }
  },
  methods: {
    async initResultCode() {
      await this.$nextTick()
      this.offset = 0;
      this.$refs.refAiccTable.setCurrentPage(1);
      await this.queryResultCode();
      this.tableSelectItem = this.selectResultItems;
      this.initSelected();
    },
    initSelected(){
      this.resutCodeList.forEach(row => {
        if (this.tableSelectItem.some(item => row.resultCode === item.resultCode)) {
          this.$nextTick(() => {
            this.$refs.refAiccTable.table.toggleRowSelection(row)
          })
        }
      })
    },
    async queryResultCode() {
      let searchObject = {};
      if (this.searchDisplayResult) {
        searchObject["displayResult"] = this.searchDisplayResult;
      }
      const params = {
        "obResultDefObject": searchObject,
        "limit": this.limit,
        "offset": this.offset
      }
      await queryResultCode(params).then(res => {
        if (res && res['resultCode'] == "0202000") {
          this.resutCodeList = res['data'];
          this.paginationConfig.total =  res['paging']['total'];
          // 如果没有自定义的结果 那么取值为默认的
          if ((this.resutCodeList == [] || this.paginationConfig.total == 0) && !this.searchDisplayResult) {
            ElMessage({
              type: 'error',
              duration: 10000,
              showClose: true,
              offset: 56,
              message:  this.$t('isales.management.title.setResultCode')
            });
          }
        } else {
          ElMessage({
            type: 'error',
            duration: 10000,
            showClose: true,
            offset: 56,
            message: res['resultDesc']
          });
        }
      })
    },
    async handlePagination(params) {
      this.limit = params.limit
      this.offset = params.offset
      this.tableSelectItem= this.$refs.refAiccTable.table.getSelectionRows()
      await this.queryResultCode();
    },
    confirm() {
      this.dialogVisible = false
      this.$emit('selectedResultCode', this.$refs.refAiccTable.table.getSelectionRows())
    },
    getKey(row){
      return row.resultCode;
    },
    resultView(row) {
      this.title = this.$t('isales.result.view');
      this.mode = 'view'
      this.createOutbountResultDialogVisible = true;
      this.outBoundResult = row;
    },
    isalesOutbountResultCreateConfirm() {
      this.initResultCode();
    }
  }
}
</script>

<style scoped>

</style>