// 系统管理员-系统管理
import Layout from '@oifde/components/OifdeLayout/OifdeLayout.vue'

export default [
  {
    path: '/aiccOifde/sysmgr',
    component: Layout,
    alwaysShow: true,
    meta: {
      title: 'oifde.route.sysMgr.sysMgr'
    },
    children: [
      {
        path: '/aiccOifde/sysmgr/trustlist', // 接口控制
        name: 'oifdeWhiteList',
        meta: {
          title: 'oifde.route.sysMgr.whiteList',
          noCache: false,
          menuId: 'oifde-white-list'
        },
        component: () => import('@/views/oifde/views/whiteList/whiteList.vue')
      },
      {
        path: '/aiccOifde/sysmgr/node', // 维护管理
        name: 'oifdeMaintain',
        meta: {
          title: 'oifde.route.tuc.ops.node',
          noCache: false,
          menuId: 'maintenance-management'
        },
        component: () => import('@/views/oifde/views/maintain/maintain.vue')
      },
      {
        path: '/aiccOifde/sysmgr/license', // 许可管理
        name: 'oifdeLicenseMgr',
        meta: {
          title: 'oifde.route.tuc.ops.license',
          noCache: false,
          menuId: 'license-management'
        },
        component: () => import('@/views/oifde/views/license/licenseMgr.vue')
      },
      {
        path: '/aiccOifde/sysmgr/server', // 服务器管理
        name: 'oifdeServerMgr',
        meta: {
          title: 'oifde.route.tuc.ops.server',
          noCache: false,
          menuId: 'server-management'
        },
        component: () => import('@/views/oifde/views/serverMgr/serverMgr.vue')
      },
      {
        path: '/aiccOifde/sysmgr/qiAsrServer', // 质检管理
        name: 'oifdeQiAsrServer',
        meta: {
          title: 'oifde.route.tuc.ops.qiAsrServer',
          noCache: false,
          menuId: 'quality-asr-server-management'
        },
        component: () => import('@/views/oifde/views/qiAsrServerMgr/qiAsrServerMgr.vue')
      },
      {
        path: '/aiccOifde/sysmgr/asr', // TTS/ASR管理
        name: 'oifdeAsrMgr',
        meta: {
          title: 'oifde.route.tuc.ops.asr',
          noCache: false,
          menuId: 'asr-management'
        },
        component: () => import('@/views/oifde/views/asrMgr/asrMgr.vue')
      },
      {
        path: '/aiccOifde/sysmgr/avatar', // 2D数字人服务管理
        name: 'avatar2DSourceMgr',
        meta: {
          title: 'oifde.route.tuc.ops.avatar',
          noCache: false,
          menuId: 'avatar-management'
        },
        component: () => import('@/views/oifde/views/2dAvatar/avatarMgr.vue')
      }
    ]
  }
]