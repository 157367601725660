export default {
  mainTitle: '客户服务云',
  subTitle: '至臻至简 极致服务',
  account: '帐号',
  password: '密码',
  signIn: '登录',
  configCenter: '配置中心',
  configFirstAgent: '座席配置',
  ConfigureFirstBot: '机器人配置',
  howToUseTheCallCenter: '用户指南',
  welcomeBack: '欢迎回来',
  totalNumberOfCalls: '总呼叫数',
  numberOfCallLosses: '呼损数',
  totalNumberOfPeopleInLine: '总排队数',
  helpCenter: '帮助中心',
  knowledgeCenter: '知识中心',
  forum: '论坛',
  virtualCallCenterTrendsOfTheDay: '虚拟呼叫中心当日趋势',
  quickEntry: '快捷入口',
  myToDo: '我的待办',
  notificationCenter: '通知中心',
  more: '更多',
  addStaff: '添加员工',
  setSkillQueue: '设置技能队列',
  agentManagement: '座席管理',
  calledSettings: '被叫设置',
  workbenchConfiguration: '工作台配置',
  outboundTask: '外呼任务',
  workOrder: '工单',
  message: '留言',
  name: '姓名',
  taskProgress: '任务进度',
  province: '省份',
  urbanArea: '市区',
  address: '地址',
  postCode: '邮编',
  homePage: '首页',
  caseHome: '工单首页',
  robotManagement: '机器人管理',
  mailMessageManagement: '邮件消息管理',
  changePassword: '修改密码',
  resetSkillQueue: '重置技能队列',
  signOut: '退出登录',
  changePasswordSuccess: '新密码已设置成功，请牢记您的新密码。页面将在 3 秒后返回。',
  passwordInvalid: '当前密码不满足密码规则，请参照规则重新设置',
  passwordIsAboutToExpire: '密码即将过期，请修改密码',
  passwordForceChange: '当前为初始密码，为安全起见，请立即修改密码',
  passwordFirstChange: '当前为初始密码，为安全起见，请立即修改密码',
  passwordExpireTitle: '密码过期提醒',
  passwordExpireMessage: '你设置的密码即将过期，过期后将不能登录系统，是否现在前往修改密码。',
  passwordFirstChangeTitle: '初始密码提醒',
  passwordFirstChangeMessage: '当前为初始密码，安全性过低，是否现在前往修改密码。',
  systemError: '系统错误',
  continueLogin: '继续登录',
  verifyCode: '验证码',
  pageInvalid: '页面失效，请重新加载页面',
  confirm: '确认',
  verifyCodeInvalid: '验证码不正确',
  passwordHasExpried: '密码已过期',
  passWordRuleLength: '密码长度为[1]-[2]个字符',
  passWordRuleMinLength: '密码长度最少为[1]个字符',
  passWordRuleMaxLength: '密码长度最多为[1]个字符',
  passwordRuleForbidContinueCharacter: '不能连续超过[1]个重复字符',
  passwordRuleForbidName: '不能包含姓名',
  passwordRuleForbidAccount: '不能包含正反序帐号',
  digit: '数字',
  letter: '字母',
  uppercaseLetters: '大写字母',
  lowercaseLetters: '小写字母',
  specialCharacters: '特殊字符',
  necessaryContains: '必须包含',
  specialCharacterCandidates: '特殊字符候选项：',
  descriptionOfInvalidandUnqualifiedPasswords: '不合法及不符合要求密码说明:',
  error: '错误',
  copyrightInfomation: '版权所有 © [1] 华为软件技术有限公司 保留一切权利.',
  accountEmpty: '请输入帐号',
  oldPasswordEmpty: '请输入密码',
  newPasswordEmpty: '请输入新密码',
  passwordNotSame: '密码不一致',
  verifyCodeEmpty: '请输入验证码',
  oldPassword: '旧密码',
  newPassword: '新密码',
  confirmPassword: '确认密码',
  ok: '是',
  cancel: '否',
  LogoutMessage: '是否确定退出？',
  keywordFilter: '请输入关键词过滤',
  noOrganization: '无归属组织',
  createCaseOrder: '创建工单',
  configMenuSearchPlaceholder: '搜索菜单关键词',
  changePasswordSuccessAndLogout: '密码修改成功！系统即将自动退出！',
  accountLocked: '修改密码失败次数过多，用户已锁定。将在3s后回到登录页面',
  'queueNumbers': '当前排队数：',
  'callsToday': '今日呼叫数：',
  'averageCallTime': '平均通话时间：',
  'entryIsEmpty': '当前快捷菜单入口为空，请前往快捷菜单设置页面定义您的常用菜单。',
  'goToSettings': '前往设置',
  'settingShortcutEntry': '设置快捷入口',
  'frontDeskWork': '前台工作',
  'selectedShortcutMenu': '已选快捷菜单',
  'pendingMessages': '待处理留言（{n}）',
  'messageManagement': '留言管理',
  'messageTime': '留言时间',
  'useCallFunction': '如何使用呼叫功能',
  'performingOutboundCallTask': '如何执行外呼任务',
  'notificationDetails': '通知详情',
  'sender': '发件人',
  'receiveGroup': '接收群组',
  'bulletinType': '公告类型',
  'internalMessage': '站内信',
  'announcements': '公告',
  'systemBulletin': '系统公告',
  'internalBulletin': '内部公告',
  'sendNotification': '发送通知',
  'notificationReply': '通知回复',
  'notificationDeleteConfirm': '您确定要删除这条通知吗？',
  'alarmnote':'质检便签',
  'alarmmessage':'警告信息',
  'alarmmessagedetail':'您有{0}条新的质检告警待查看，请点击查看详情',
  'schedule':'排班',
  'privacystatement':'AICC隐私声明',
  'id':'ID',
  'decs':'描述',
  'cause':'原因',
  'action':'修复动作',
  personalCenter:"个人中心",
  'noagent':"该账号暂未绑定座席，可联系管理员进行配置",
  'nickName':"会话昵称",
  'socailChatNum':"会话同时服务客户数",
  'avatar':"会话头像",
  'confirmClose':"确认关闭",
  'importListView': "导入任务查看",
  'listView': "任务查看",
  'minorityLanguageTips': '非常抱歉，排班系统仅支持中文和英文，请切换语言后进入。',
  'minorityLanguageTipsAgent': '非常抱歉，排班系统暂不支持葡萄牙语和泰语，请切换语言后进入。',
  "sureCloseCurrentPage": "是否确认关闭页面",
}
