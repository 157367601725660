export default {
  "ccnotification.flowcontrol.messageThreshold" : "Limite de dados di\u00E1rios para SMSs",
  "ccnotification.gatewayconfig.label.transactionTimeouttitle" : "O Intervalo de Tempo limite da Transa\u00E7\u00E3o \u00E9 um n\u00FAmero apropriado",
  "ccnotification.gatewayconfig.label.addrNpi" : "Modo de codifica\u00E7\u00E3o de endere\u00E7o ESME",
  "ccnotification.notifition.sendmessage.bulletin" : "Comunicado",
  "ccnotification.notifition.template.message.moneyLabel" : "Quantidade",
  "ccnotification.notifition.sendmessage.delaysend" : "Notifica\u00E7\u00E3o programada",
  "message.template.message.deleteTemplate" : "Excluinr um modelo de notifica\u00E7\u00E3o",
  "ccnotification.notifition.sendmessage.selectGroupOrRecipient" : "Selecione o destinat\u00E1rio ou o grupo destinat\u00E1rio.",
  "ccnotification.gatewayconfig.label.maxsmGatewayIsError" : "Um m\u00E1ximo de 10 gateways SMS pode ser configurado no mesmo espa\u00E7o de Tenant",
  "ccnotification.notifition.template.message.money" : "- O valor pode conter 1 a 20 d\u00EDgitos. Apenas d\u00EDgitos e pontos decimais que podem expressar corretamente a quantidade podem ser transferidos.",
  "ccnotification.notifition.template.label.index" : "N\u00E3o",
  "ccnotification.gatewayconfig.label.deletegatewayfailed" : "Falha ao excluir Gatewayconfig!",
  "ccnotification.notifition.sendmessage.enterCCRecipient" : "Selecione um destinat\u00E1rio Cc.",
  "message.template.message.titleLengthError" : "O t\u00EDtulo n\u00E3o pode exceder 200 caracteres ou conter caracteres especiais.",
  "message.template.button.delete" : "Excluir",
  "ccnotification.common.title.fail" : "Fracassada",
  "ccnotification.smstemplate.message.var.number" : "O n\u00FAmero da vari\u00E1vel de entrada \u00E9 diferente do n\u00FAmero de vari\u00E1veis adicionadas.",
  "ccnotification.gatewayconfig.label.authtitle" : "Se a autentica\u00E7\u00E3o deve ser usada. As op\u00E7\u00F5es s\u00E3o verdadeiro ou falso.",
  "message.center.nocitiction.modifyInternalMessage" : "Modificar mensagem interna",
  "ccnotification.notifition.sendmessage.templateselect" : "Selecionar modelo",
  "ccnotification.notification.label.hasUplaodtAttachment" : "Anexos carregados",
  "ccnotification.flowcontrol.shortMsgCode" : "N\u00FAmero da mensagem SMS",
  "ccnotification.gatewayconfig.label.receiverPoolMinCounttitle" : "Os recursos recep\u00E7\u00E3o de linhas de m\u00EDnimas s\u00E3o um n\u00FAmero\, por exemplo\, 20.",
  "message.center.nocitiction.interal" : "Boletim interno",
  "message.template.type.system" : "Notifica\u00E7\u00E3o do sistema",
  "ccnotification.gatewayconfig.label.destAddrNpi" : "Esquema de c\u00F3digo de endere\u00E7o de destino",
  "message.center.message.delete" : "Excluir",
  "ccnotification.notifition.template.message.charDigit" : "- O valor \u00E9 uma sequ\u00EAncia de 1 a 20 d\u00EDgitos e letras.",
  "ccnotification.notification.variable.publicvariable" : "Vari\u00E1vel p\u00FAblica",
  "ccnotification.notifition.template.message.textLabel" : "Texto",
  "ccnotification.gatewayconfig.label.maxMessageLengthtitle" : "O comprimento m\u00E1ximo da mensagem (byte) \u00E9 um n\u00FAmero\, que \u00E9 especificado no protocolo\, por exemplo\, 2048.",
  "ccnotification.notifition.template.message.hwsms" : "Mensagens SMS da HUAWEI CLOUD",
  "ccnotification.notifition.sendmessage.cancelSelectall" : "Cancelar todos",
  "ccnotification.gatewayconfig.label.splitMsgConcatModeIsError" : "Modo de combina\u00E7\u00E3o de mensagens curtas longas 0: cabe\u00E7alho de dados do usu\u00E1rio GSM\, 1: par\u00E2metro SMPP",
  "ccnotification.gatewayconfig.label.senderPoolMinCount" : "Min Enviando Pools de Thread",
  "ccnotification.notifition.template.message.mspsms" : "Gateway SMS",
  "ccnotification.gatewayconfig.label.bindMode" : "Modo de recebimento e envio",
  "ccnotification.notifition.sendmessage.savesuccess" : "A mensagem foi salva com sucesso.",
  "ccnotification.gatewayconfig.label.senderPoolQueueCapacitytitle" : "O comprimento da fila de pool de segmentos de envio \u00E9 um n\u00FAmero\, por exemplo\, 2000.",
  "ccnotification.notifition.sendmessage.enterRecipientGroup" : "Selecione um grupo de destinat\u00E1rios.",
  "ccnotification.gatewayconfig.label.authIsError" : "A ID de autentica\u00E7\u00E3o s\u00F3 pode ser verdadeira ou falsa",
  "ccnotification.notifition.sendmessage.selectbyskill" : "Fila de habilidades",
  "ccnotification.notifition.variable.createvariable" : "Criar",
  "ccnotification.notification.recmessagerouterconfig.placeholder.receiver" : "Insira o endere\u00E7o do destinat\u00E1rio",
  "ccnotification.gatewayconfig.label.smHost" : "Endere\u00E7o SMG",
  "ccnotification.gatewayconfig.label.addgateway" : "Criar gateway",
  "ccnotification.notification.label.upload" : "Carregar",
  "ccnotification.notifition.sendmessage.send" : "Enviar",
  "ccnotification.agent.operation.searchworkno" : "Insira a ID do funcion\u00E1rio",
  "ccnotification.gatewayconfig.label.willDeletegateway" : "Tem certeza de que deseja excluir a configura\u00E7\u00E3o de gateway selecionada?",
  "ccnotification.notifition.sendmessage.sendexception" : "Erro de envio.",
  "ccnotification.gatewayconfig.label.auth" : "Indicador de autentica\u00E7\u00E3o",
  "ccnotification.notification.recmessagerouterconfig.defaultsendemail" : "E-mail de envio padr\u00E3o",
  "ccnotification.flowcontrol.getwayset" : "Definir",
  "ccnotification.notification.recmessagerouterconfig.mediaType" : "Tipo de m\u00EDdia",
  "ccnotification.notification.recmessagerouterconfig.intelligentOrder" : "Cria\u00E7\u00E3o inteligente de casos",
  "message.template.message.selectRecipient" : "Selecione um destinat\u00E1rio.",
  "ccnotification.notifition.sendmessage.receiverPhoneIsNull" : "O n\u00FAmero do destinat\u00E1rio ou do destinat\u00E1rio est\u00E1 vazio.",
  "ccnotification.gatewayconfig.label.registeredDelivery" : "Relat\u00F3rio de Confirma\u00E7\u00E3o de Status de Devolu\u00E7\u00E3o",
  "message.center.nocitiction.sender" : "Remetente",
  "ccnotification.notifition.sendmessage.senddate" : "Data de envio",
  "ccnotification.gatewayconfig.label.sourceAddrNpititle" : "O Esquema de C\u00F3digo de Endere\u00E7o de Fonte \u00E9 um n\u00FAmero",
  "ccnotification.notifition.template.placeholder.hwtemplateid" : "inserir uma ID de modelo HUAWEI CLOUD",
  "ccnotification.notification.systemmonitor.modifyTime" : "Modificado em",
  "ccnotification.notifition.sendmessage.isSendallFail" : "Enviado (Falha)",
  "ccnotification.notifition.variable.searchVariableName" : "Insira um nome de vari\u00E1vel.",
  "ccnotification.notifition.template.message.phone" : "- O valor \u00E9 uma sequ\u00EAncia de 1 a 15 d\u00EDgitos.",
  "ccnotification.notifition.sendmessage.unSend" : "A ser enviado",
  "ccnotification.smshuawei.message.specialCharacters" : "Caracteres especiais n\u00E3o s\u00E3o permitidos.",
  "ccnotification.gatewayconfig.label.configTypeIsError" : "O tipo de configura\u00E7\u00E3o est\u00E1 incorreto. O tipo de configura\u00E7\u00E3o interna do par\u00E2metro \u00E9 inconsistente",
  "ccnotification.notifition.sendmessage.internal" : "Mensagem interna",
  "ccnotification.gatewayconfig.label.dataCodingtitle" : "0: string ASCII 4: informa\u00E7\u00F5es bin\u00E1rias 8: codifica\u00E7\u00E3o UCS2",
  "ccnotification.notifition.sendmessage.enterBulletinType" : "Selecione um Tipo de boletim.",
  "ccnotification.notifition.sendmessage.selectOutbox" : "Selecionar a caixa de sa\u00EDda.",
  "ccnotification.notification.systemmonitor.gatewayName" : "Nome do gateway",
  "ccnotification.gatewayconfig.label.errorstylenumber" : "Formato de n\u00FAmero incorreto.",
  "ccnotification.notifition.sendmessage.recipient" : "Destinat\u00E1rio",
  "ccnotification.gatewayconfig.label.dataCoding" : "Formato da mensagem",
  "ccnotification.bulletinType.message.betchDeleteBulletinTypeSuccessful" : "Os tipos de boletim s\u00E3o exclu\u00EDdos em lotes com sucesso.",
  "message.template.message.nameDuplicationError" : "Nome do modelo duplicado.",
  "ccnotification.notifition.sendmessage.bulletinTypeName" : "Tipo de boletim",
  "ccnotification.common.button.create" : "Novo",
  "ccnotification.gatewayconfig.label.certScene" : "Cen\u00E1rio de aplica\u00E7\u00E3o",
  "ccnotification.notifition.sendmessage.isSendallSuccess" : "Enviado (Sucesso)",
  "message.template.message.contentLengthError" : "O conte\uFFFDdo n\uFFFDo pode estar vazio e n\uFFFDo pode ser muito longo",
  "ccnotification.notifition.sendmessage.sendTimeFailed" : "O tempo de envio \u00E9 anterior \u00E0 hora atual.",
  "ccnotification.bulletinType.message.willDelete" : "Tem certeza de que quer excluir o tipo do comunicado selecionado?",
  "ccnotification.notifition.sendmessage.sendtimebeforenow" : "O tempo de envio programado deve ser 5 minutos mais tarde do que a hora atual.",
  "ccnotification.bulletinType.title.Urgent" : "M\u00E9dio",
  "ccnotification.gatewayconfig.label.senderPoolQueueCapacity" : "Enviando Comprimento da fila do pool de segmentos",
  "ccnotification.flowcontrol.emailThreshold" : "Limite de dados di\u00E1rios para e-mails",
  "ccnotification.bulletinType.title.editBulletinType" : "Modificar tipo de boletim",
  "message.template.message.contentError" : "O conte\u00FAdo n\u00E3o pode ficar vazio.",
  "ccnotification.smshuawei.label.appAdress" : "Endere\u00E7o de acesso de aplicativos",
  "message.center.nocitiction.inbox" : "Caixa de entrada",
  "ccnotification.gatewayconfig.label.addressRange" : "Intervalo de endere\u00E7os ESME",
  "ccnotification.gatewayconfig.label.addgatewayconfig" : "Adicionar configura\u00E7\u00E3o de gateway",
  "ccnotification.agent.pageurls.isconfreset" : "Deseja realmente redefinir?",
  "ccnotification.gatewayconfig.label.heartbeatNoRespCount" : "Tempos m\u00E1ximos de conex\u00E3o ap\u00F3s nenhuma resposta de pulsa\u00E7\u00E3o",
  "ccnotification.gatewayconfig.label.RcvAndSendMode" : "Modo TX/RX",
  "ccnotification.gatewayconfig.label.defaultsendno" : "N\u00FAmero de envio padr\u00E3o",
  "ccnotification.notification.message.noAttachement" : "Voc\u00EA n\u00E3o carregou nenhum anexo.",
  "ccnotification.template.message.delete" : "O modelo foi referenciado e n\u00E3o pode ser exclu\u00EDdo.",
  "message.center.label.search" : "Pesquisar",
  "ccnotification.notifition.sendmessage.ccRecipient" : "Cc",
  "ccnotification.gatewayconfig.label.replaceIfPresentFlagtitle" : "0: Substituir\, 1: N\u00E3o substituir\, 2-255: Reservado",
  "ccnotification.bulletinType.message.createBulletinTypeSuccessful" : "O tipo de boletim foi criado com sucesso.",
  "message.center.label.allSelect" : "Selecionar tudo",
  "ccnotification.gatewayconfig.label.certCode" : "C\u00F3digo do certificado",
  "ccnotification.gatewayconfig.label.SSLEncryptionMode" : "Modo de criptografia",
  "message.template.message.nameError" : "O nome do modelo n\u00E3o pode ficar vazio ou exceder 200 caracteres.",
  "ccnotification.notification.variable.templateselect" : "Selecione um modelo.",
  "ccnotification.notifition.sendmessage.sendfailed" : "Falha ao enviar.",
  "message.center.nocitiction.bulletin" : "Comunicado",
  "ccnotification.gatewayconfig.label.configNameIsError" : "O nome da configura\u00E7\u00E3o deve conter um m\u00E1ximo de 10 d\u00EDgitos ou letras",
  "ccnotification.flowcontrol.modifySuccess" : "A configura\u00E7\u00E3o de notifica\u00E7\u00E3o foi modificada com sucesso.",
  "ccnotification.flowcontrol.setFlowType" : "Tipo de limite de dados",
  "message.center.message.willDeleteMessages" : "Tem certeza de que deseja apagar a mensagem selecionada?",
  "ccnotification.gatewayconfig.label.lastmodifytime" : "\u00DAltima modifica\u00E7\u00E3o em",
  "message.center.label.select" : "Filtro",
  "message.template.button.view" : "Exibir",
  "ccnotification.caseserver.fail.categoryNotExist" : "Categoria de caso inexistente",
  "ccnotification.gatewayconfig.label.inputCertCode" : "Insira o c\u00F3digo do certificado.",
  "ccnotification.bulletinType.message.selectItem" : "Selecione um Tipo de boletim.",
  "message.template.message.descriptionLengthError" : "A descri\u00E7\u00E3o excede o limite.",
  "ccnotification.gatewayconfig.label.SSLEncryptionModetitle" : "Modo de criptografia\, NONE: transmiss\u00E3o de texto simples (porta padr\u00E3o: 25) TLS: transmiss\u00E3o criptografada usando TLS (porta padr\u00E3o: 465) STARTTLS: transmiss\u00E3o criptografada usando o comando STARTTLS (porta padr\u00E3o: 586)",
  "ccnotification.gatewayconfig.label.mailHost" : "Host",
  "ccnotification.flowcontrol.setThreshold" : "Definir o Limite de Dados",
  "ccnotification.gatewayconfig.label.messageFlowControlMessageDropPolicytitle" : "Atualmente\, apenas h\u00E1 suporte para Ignorar.",
  "ccnotification.notification.label.uploadAttachmentSuccess" : "O anexo foi carregado com sucesso.",
  "ccnotification.center.message.deleteAllRead" : "Tem certeza de que deseja excluir todas as mensagens internas marcadas como lidas?",
  "ccnotification.notifition.sendmessage.templateselected" : "Este aviso fez refer\u00EAncia \u00E0",
  "ccnotification.notifition.sendmessage.inputBulletinType" : "Insira o nome do tipo de comunicado.",
  "message.template.message.modifyTemplateFail" : "Falha ao modificar o modelo de notifica\u00E7\u00E3o.",
  "ccnotification.notification.variable.nameexist" : "O nome da vari\u00E1vel existe.",
  "message.template.message.inputTitle" : "Insira um t\u00EDtulo.",
  "ccnotification.gatewayconfig.label.dataCodingIsError" : "O formato da mensagem SMS deve ser 0: string ASCII\, 4: informa\u00E7\u00F5es bin\u00E1rias\, 8: c\u00F3digo UCS2",
  "ccnotification.notifition.sendmessage.preview" : "Visualiza\u00E7\u00E3o",
  "ccnotification.notification.label.uploadPermissionFailed" : "Falha ao validar a permiss\u00E3o ao carregar anexos de e-mail.",
  "message.template.mediaType.shortmessage" : "SMS",
  "ccnotification.bulletinType.message.deleteBulletinTypeSuccessful" : "O tipo de boletim foi exclu\u00EDdo com sucesso.",
  "ccnotification.common.spec.message" : "N\u00E3o s\u00E3o permitidos caracteres especiais.",
  "message.template.message.messageTemplateId" : "ID do modelo",
  "ccnotification.smstemplate.message.number" : "O d\u00EDgito m\u00E1ximo \u00E9 10.",
  "ccnotification.notification.recmessagerouterconfig.receiveAddress" : "Endere\u00E7o do destinat\u00E1rio",
  "ccnotification.gatewayconfig.label.systemId" : "Nome de login",
  "ccnotification.common.button.ok" : "OK",
  "ccnotification.common.title.confirm" : "Confirmar",
  "ccnotification.notification.systemmonitor.status" : "Status",
  "ccnotification.gatewayconfig.label.messageFlowControlMaxCapsForRecv" : "Taxa m\u00E1xima de recebimento",
  "ccnotification.notification.recmessagerouterconfig.inbox" : "Caixa de entrada",
  "ccnotification.notifition.sendmessage.notifitiontitle" : "Insira um t\u00EDtulo.",
  "ccnotification.gatewayconfig.label.smDefaultMsgId" : "ID de mensagens padr\u00E3o",
  "ccnotification.common.message.emptyContent" : "O conte\u00FAdo n\u00E3o pode ficar vazio.",
  "ccnotification.common.title.success" : "Bem-sucedido",
  "ccnotification.caseserver.fail.serviceException" : "Servi\u00E7o anormal",
  "ccnotification.notifition.variable.not.exist" : "inexistente!",
  "ccnotification.gatewayconfig.label.esmClass" : "Tipo de mensagem",
  "ccnotification.notifition.variable.selectvariable" : "Selecionar vari\u00E1vel",
  "ccnotification.smshuawei.label.signatureChannelId" : "ID do canal de assinatura",
  "ccnotification.gatewayconfig.label.defaultsendnotitle" : "Regra do prefixo do número do remetente. Por exemplo, se 130 estiver configurado, os números que começam com 130 são correspondidos. Vários números são separados por ponto-e-vírgula (;).",
  "ccnotification.gatewayconfig.label.recFlowControllertitle" : "Nome do controlador de tr\u00E1fego que controla a taxa de mensagem uplink.",
  "ccnotification.common.message.sendIntervalShort" : "Envio frequente. Intervalo de envio (s): {n}.",
  "ccnotification.gatewayconfig.label.sourceAddrTon" : "Tipo de c\u00F3digo de endere\u00E7o de origem",
  "message.center.nocitiction.modifyBulletin" : "Modificar Boletim",
  "ccnotification.notifition.sendmessage.enterrecipient" : "Selecione um destinat\u00E1rio.",
  "message.template.mediaType.email" : "Email",
  "message.center.label.senderName" : "Remetente",
  "message.template.message.mediaType" : "Modo de envio de mensagem",
  "ccnotification.flowcontrol.modifyFailed" : "Falha ao modificar a configura\u00E7\u00E3o de notifica\u00E7\u00E3o. Verifique os par\u00E2metros de entrada ou rede.",
  "message.center.nocitiction.draftBox" : "Caixa de rascunho",
  "ccnotification.gatewayconfig.label.destAddrNpititle" : "O Esquema de C\u00F3digo de Endere\u00E7o de Destino \u00E9 um n\u00FAmero",
  "ccnotification.sms.huawei" : "SMS Huawei",
  "ccnotification.notification.label.compressZipFileFailed" : "Falha ao compactar o arquivo.zip",
  "message.template.message.mediaTypeError" : "Modo de envio incorreto.",
  "ccnotification.notifition.sendmessage.sendsuccess" : "A mensagem foi enviada com sucesso.",
  "ccnotification.notification.label.nameContainSpecialChar" : "O nome do anexo de email cont\u00E9m caracteres especiais",
  "ccnotification.bulletinType.message.deleteBulletinTypeFailed" : "Falha ao excluir o tipo de boletim.",
  "ccnotification.smshuawei.message.updatefail" : "Falha ao modificar o canal SMS.",
  "ccnotification.notification.recmessagerouterconfig.selectconfig" : "Selecione uma configura\u00E7\u00E3o.",
  "ccnotification.gatewayconfig.label.bindModeIsError" : "Modo de envio e recebimento SMS 1: modo de recebimento\, 2: modo de transmiss\u00E3o\, 9: modo TX / RX",
  "ccnotification.gatewayconfig.label.smPassword" : "Senha de login",
  "ccnotification.smshuawei.message.deletefail" : "Falha ao excluir o canal SMS.",
  "ccnotification.notifition.sendmessage.invalidSect" : "Segmento de hor\u00E1rio de expira\u00E7\u00E3o",
  "ccnotification.gatewayconfig.label.hostIsError" : "O host de e-mail s\u00F3 pode ser 127.0.0.1 ou formata\u00E7\u00E3o www.mail.com",
  "ccnotification.gatewayconfig.label.mailPassword" : "Senha do usu\u00E1rio",
  "ccnotification.bulletinType.title.Rushse" : "Alto",
  "message.center.nocitiction.outbox" : "Caixa de sa\u00EDda",
  "ccnotification.gatewayconfig.label.messageFlowControlMaxCapsForSendtitle" : "A taxa m\u00E1xima de envio \u00E9 um n\u00FAmero",
  "ccnotification.notifition.receivermessage.receiverSuccess" : "Recebido",
  "ccnotification.notifition.sendmessage.internalmessage" : "Notifica\u00E7\u00E3o interna",
  "ccnotification.notifition.template.placeholder.varnumber" : "Insira o n\u00FAmero de vari\u00E1veis",
  "ccnotification.smshuawei.label.appKey" : "Chave do aplicativo",
  "ccnotification.common.warning.message" : "Selecione um registro.",
  "ccnotification.smshuawei.label.certTip.withoutProxy" : "Carregue o certificado de servi\u00E7o HUAWEI CLOUD",
  "ccnotification.smshuawei.label.certTip.withProxy" : "Carregue o certificado do servidor proxy",
  "ccnotification.notification.label.downloadAttachment" : "Deseja realizar o download do anexo?",
  "message.template.message.messageTemplateCreate" : "Criar modelo de notifica\u00E7\u00E3o",
  "ccnotification.smstemplate.message.smstemplate" : "As informa\u00E7\u00F5es do modelo HUAWEI CLOUD est\u00E3o vazias.",
  "ccnotification.notifition.template.placeholder.des" : "Descrever a vari\u00E1vel",
  "ccnotification.notification.systemmonitor.rebuilding" : "Reconectar",
  "ccnotification.notification.label.isDeleteAttachment" : "Excluir anexo",
  "ccnotification.gatewayconfig.label.positive" : "O n\u00FAmero n\u00E3o pode ser negativo.",
  "ccnotification.notifition.template.message.text" : "- O valor \u00E9 uma string de 1 a 20 caracteres e n\u00E3o pode conter 000",
  "ccnotification.notification.recmessagerouterconfig.satisfaction" : "Pesquisa de Satisfa\u00E7\u00E3o",
  "ccnotification.gatewayconfig.label.messageFlowControlMessageDropPolicy" : "Pol\u00EDtica de processamento de tr\u00E1fego extra",
  "ccnotification.gatewayconfig.label.configNameAlreadyExists" : "O nome da configura\u00E7\u00E3o j\u00E1 existe",
  "message.template.message.searchMessageTemplateName" : "Insira um nome de modelo.",
  "ccnotification.notifition.sendmessage.delaynotice" : "Este aviso ser\u00E1 enviado em",
  "ccnotification.smshuawei.label.signatureChannelId.length" : "O comprimento do n\u00FAmero do canal de assinatura est\u00E1 fora do intervalo",
  "ccnotification.smstemplate.message.signatureName" : "O nome da assinatura n\u00E3o pode estar em branco nem ser muito longo ou fora de conformidade",
  "ccnotification.smstemplate.message.receiverPhone" : "Duplica\u00E7\u00E3o n\u00E3o permitida para o destinat\u00E1rio",
  "ccnotification.agent.contact.workno" : "ID do funcion\u00E1rio",
  "message.center.nocitiction.message" : "SMS",
  "message.template.message.createTime" : "Criado em",
  "ccnotification.gatewayconfig.label.sourceAddrTontitle" : "O Tipo de C\u00F3digo de Endere\u00E7o de Origem \u00E9 um n\u00FAmero",
  "ccnotification.notifition.variable.variable" : "Vari\u00E1vel",
  "ccnotification.notifition.template.title.setting" : "Definir vari\u00E1vel",
  "ccnotification.notification.variable.templatevariable" : "Modelo vari\u00E1vel",
  "ccnotification.notification.label.incorrectAttachmentType" : "Tipo de anexo de e-mail incorreto",
  "ccnotification.gatewayconfig.label.smPort" : "Porta SMG",
  "ccnotification.flowcontrol.countryCode" : "C\u00F3digo do pa\u00EDs",
  "ccnotification.notifition.sendmessage.selectmode" : "Selecionar por",
  "ccnotification.gatewayconfig.label.smHosttitle" : "Endere\u00E7o SMG\, por exemplo\, 127.0.0.1",
  "message.template.message.noneTemplate" : "O modelo n\u00E3o existe.",
  "ccnotification.notifition.sendmessage.priorityFault" : "Cr\u00EDtico",
  "ccnotification.notifition.sendmessage.paramvalidatefailed" : "Falha na valida\u00E7\u00E3o do param.",
  "ccnotification.gatewayconfig.label.selectCert" : "Selecionar certificado",
  "message.center.nocitiction.callback.record" : "Registros de retorno de chamada",
  "ccnotification.gatewayconfig.label.splitTypeIsError" : "O modo de divis\u00E3o de mensagens SMS longas s\u00F3 pode ser definido como 0: desabilitado\, 1: n\u00E3o dividido\, 2: divis\u00E3o f\u00EDsica\, 3: divis\u00E3o ultralonga",
  "ccnotification.notifition.template.label.des" : "Descri\u00E7\u00E3o",
  "ccnotification.notifition.template.placeholder.signatureName" : "Inserir um nome de assinatura",
  "message.center.label.messageCenter" : "Centro de notifica\u00E7\u00F5es",
  "ccnotification.notification.recmessagerouterconfig.gateway" : "Gateway",
  "ccnotification.notifition.template.label.value" : "Valor padr\u00E3o",
  "message.template.message.recipientLengthError" : "O n\u00FAmero de destinat\u00E1rios excede o limite.",
  "ccnotification.gatewayconfig.label.gatewayipaddrconfig" : "Configura\u00E7\u00E3o de interconex\u00E3o de endere\u00E7os de gateway",
  "ccnotification.notifition.sendmessage.priorityUrgent" : "M\u00E9dio",
  "ccnotification.notifition.template.label.templatetype" : "Tipo de modelo",
  "ccnotification.notifition.template.label.mspcontent" : "Conte\u00FAdo do Modelo SMS",
  "ccnotification.gatewayconfig.label.transactionTimeout" : "Intervalo de tempo limite da transa\u00E7\u00E3o (ms)",
  "ccnotification.smshuawei.label.appSecret" : "Segredo do aplicativo",
  "ccnotification.notifition.sendmessage.bulletinType" : "Tipo de comunicado",
  "ccnotification.notifition.recmessagerouterconfig.index" : "\u00CDndice",
  "ccnotification.notifition.receivermessage.unread" : "n\u00E3o lido",
  "ccnotification.notification.label.discounnectedFailed" : "Falha na desconex\u00E3o",
  "ccnotification.gatewayconfig.label.serviceType" : "Tipo de servi\u00E7o",
  "ccnotification.smshuawei.message.paramisempty" : "Insira um par\u00E2metro.",
  "ccnotification.bulletinType.title.bulletintypeInvalidSect" : "Tempo de Expiração Padrão",
  "ccnotification.smstemplate.message.var" : "Tipo de vari\u00E1vel , n\u00FAmero , descri\u00E7\u00E3o ou entrada de valor padr\u00E3o n\u00E3o satisfat\u00F3rio",
  "ccnotification.agent.label.account" : "Conta",
  "ccnotification.notification.recmessagerouterconfig.defaultreceiveemail" : "E-mail de recebimento padr\u00E3o",
  "ccnotification.notifition.sendmessage.selectall" : "Selecionar tudo",
  "ccnotification.notification.variable.variablevalue" : "Valor da vari\u00E1vel",
  "ccnotification.caseserver.fail.titleAndContentNull" : "T\u00EDtulo e conte\u00FAdo vazios",
  "ccnotification.notification.label.downloadFromObsFailed" : "Falha ao baixar arquivos do OBS",
  "ccnotification.caseserver.fail.duplicateContent" : "Conte\u00FAdo duplicado",
  "ccnotification.gatewayconfig.label.splitType" : "Modo de divis\u00E3o SMS sobredimensionado",
  "ccnotification.notifition.receivermessage.all" : "Todos",
  "ccnotification.notifition.sendmessage.selectbyorg" : "Unidade da Organiza\u00E7\u00E3o",
  "ccnotification.gatewayconfig.label.maxEmailGatewayIsError" : "Um m\u00E1ximo de cinco gateways de recebimento ou envio de e-mail podem ser configurados para o mesmo espa\u00E7o do Tenant",
  "ccnotification.notifition.template.label.type" : "Tipo",
  "message.template.message.messageTemplateName" : "Nome do modelo",
  "ccnotification.notifition.variable.variableType" : "Selecione um tipo de vari\u00E1vel.",
  "message.template.message.recipientError" : "Alguns destinat\u00E1rios n\u00E3o existem. Selecione outro destinat\u00E1rio",
  "ccnotification.notification.recmessagerouterconfig.receiveaddressexist" : "O endere\u00E7o de recebimento j\u00E1 existe.",
  "message.center.nocitiction.sending.records" : "Enviando registro",
  "ccnotification.notifition.variable.been.used" : "\u00E9 referenciado por um modelo e n\u00E3o pode ser exclu\u00EDdo.",
  "ccnotification.notifition.variable.syntax.error" : "Erro de sintaxe!",
  "ccnotification.gatewayconfig.label.deletegatewaysuccess" : "Gatewayconfig excluir com sucesso!",
  "ccnotification.flowcontrol.setShortMsgCode" : "Defina o n\u00FAmero para mensagens SMS.",
  "ccnotification.notification.label.rebuildFailed" : "Falha ao reconstruir",
  "ccnotification.notification.label.rebuildContainsErrorId" : "Falha na reconstru\u00E7\u00E3o\. Clique no bot\u00E3o de consulta para atualizar a lista e tente novamente\.",
  "ccnotification.gatewayconfig.label.mailProtocolIsError" : "O tipo de protocolo de email deve ser SMTP\, POP3.",
  "ccnotification.gatewayconfig.label.smPorttitle" : "Faixa de n\u00FAmeros de porta SMG: um inteiro que varia de 0 a 65535",
  "message.center.label.sendTime" : "Enviado em",
  "ccnotification.notification.systemmonitor.disconnected" : "Desconectar",
  "ccnotification.gatewayconfig.label.serverSocketPort" : "Porta para receber mensagens upstream",
  "message.center.message.messageQueryResultIsEmpty" : "O resultado da consulta da mensagem est\u00E1 vazio.",
  "ccnotification.flowcontrol.totalThreshold" : "Limite de dados di\u00E1rios totais",
  "message.center.nocitiction.system" : "Boletim do Sistema",
  "ccnotification.notification.label.rebuildSuccess" : "Reconstruindo sucesso",
  "ccnotification.gatewayconfig.label.splitTypetitle" : "Modo de divis\u00E3o de uma mensagem SMS excessiva. As op\u00E7\u00F5es s\u00E3o as seguintes: 0: n\u00E3o dividido; 1: n\u00E3o dividido; 2: dividir fisicamente; 3: dividir mensagem SMS longo",
  "message.template.message.messageTemplateModify" : "Modificar modelo",
  "ccnotification.notifition.template.label.operation" : "Opera\u00E7\u00E3o",
  "ccnotification.bulletinType.message.invalidSectCheck" : "O tempo de expira\u00E7\u00E3o padr\u00E3o deve variar de 1 a 2000 dias.",
  "ccnotification.gatewayconfig.label.resendWaitTimetitle" : "O Tempo de Espera de Reenvio \u00E9 um n\u00FAmero adequado",
  "ccnotification.notifition.gatewaycontact.failed" : "Erro de conex\u00E3o",
  "message.center.nocitiction.callback.status" : "Status de retorno de chamada",
  "ccnotification.center.message.toIsRead" : "Tem certeza de que deseja marcar as mensagens selecionadas como lidas?",
  "ccnotification.bulletinType.title.General" : "Baixo",
  "ccnotification.common.operation.noMoreThan7Days" : "O intervalo de tempo n\u00E3o pode exceder 7 dias.",
  "ccnotification.gatewayconfig.label.paramMaxLengthIsError" : "O valor do par\u00E2metro n\u00E3o pode exceder 500 caracteres",
  "message.center.label.receiverGroup" : "Grupo de destinat\u00E1rios",
  "ccnotification.notification.label.selectAttachment" : "Selecione um anexo de e-mail.",
  "ccnotification.gatewayconfig.label.inputgatewayname" : "Inserir um nome de gateway",
  "message.template.message.save" : "Salvar",
  "ccnotification.notification.recmessagerouterconfig.serviceType" : "M\u00E9todo de implementa\u00E7\u00E3o",
  "ccnotification.gatewayconfig.label.heartbeatInterval" : "Intervalo de batimento card\u00EDaco (ms)",
  "ccnotification.notifition.template.message.dateTimeLabel" : "Data e hora",
  "ccnotification.gatewayconfig.label.defaultreceinfo" : "ID receptor padr\u00E3o",
  "ccnotification.notification.label.isUploadAttachment" : "Carregar anexo",
  "ccnotification.gatewayconfig.label.addrTon" : "C\u00F3digo tipo endere\u00E7o ESME",
  "ccnotification.gatewayconfig.label.maxContentLength" : "Comprimento m\u00E1ximo do conte\u00FAdo (bytes)",
  "ccnotification.notifition.sendmessage.bccRecipient" : "Bcc",
  "message.center.nocitiction.sending.records.smsType" : "Modo de mensagem SMS",
  "ccnotification.gatewayconfig.label.receiverPoolMaxCount" : "Pools m\u00E1ximos de recebimento de threads",
  "ccnotification.notification.label.serverIsNotobs" : "Os anexos s\u00F3 podem ser baixados do servidor de arquivos do OBS.",
  "ccnotification.notification.label.fileInjectionRiskException" : "Exce\u00E7\u00E3o de risco de inje\u00E7\u00E3o de anexos de e-mail",
  "ccnotification.notification.label.attachmentExceed10" : "Podem ser carregados at\u00E9 10 anexos.",
  "message.center.label.deleteisread" : "Remover todas as mensagens lidas",
  "message.center.button.query" : "Consulta",
  "message.template.message.recipient" : "Destinat\u00E1rio",
  "ccnotification.gatewayconfig.label.statusisnoterror" : "O status n\u00E3o \u00C9 ERRO.",
  "ccnotification.notification.recmessagerouterconfig.gatewaynotexist" : "O gateway selecionado n\u00E3o existe.",
  "ccnotification.gatewayconfig.label.viewgateway" : "Visualizar gateway",
  "ccnotification.gatewayconfig.label.mailPort" : "Porta",
  "ccnotification.gatewayconfig.label.protocol" : "Tipo de protocolo",
  "ccnotification.center.message.setIsRead" : "Marcar mensagens como lidas",
  "message.center.nocitiction.ineermessage" : "Mensagem interna",
  "ccnotification.gatewayconfig.label.configType" : "Tipo de configura\u00E7\u00E3o",
  "ccnotification.gatewayconfig.label.slipWindowSizetitle" : "O tamanho da janela deslizante \u00E9 um n\u00FAmero adequado",
  "ccnotification.gatewayconfig.label.recFlowController" : "Nome do controlador de tr\u00E1fego de uplink",
  "ccnotification.gatewayconfig.label.addgatewayfailed" : "Falha ao adicionar Gatewayconfig!",
  "ccnotification.flowcontrol.eachThreshold" : "Limite de dados di\u00E1rios por Tipo de m\u00EDdia",
  "ccnotification.notification.recmessagerouterconfig.mobilerule" : "Insira n\u00FAmeros de celular. Separe v\u00E1rios n\u00FAmeros m\u00F3veis com ponto-e-v\u00EDrgula",
  "ccnotification.notification.label.downPermissionFailed" : "Falha ao validar a permiss\u00E3o no download de anexos de e-mail.",
  "ccnotification.gatewayconfig.label.selectredisconnectedgatewayconfig" : "Selecione a configura\u00E7\u00E3o do gateway a ser desconectada",
  "message.center.nocitiction.sending.records.sended" : "Envio instant\u00E2neo",
  "ccnotification.gatewayconfig.label.resendWaitTime" : "Reenviando Tempo de Espera (ms)",
  "ccnotification.gatewayconfig.label.destAddrTon" : "Tipo de c\u00F3digo de endere\u00E7o de destino",
  "ccnotification.common.label.name" : "Nome",
  "ccnotification.common.title.errorsave" : "Erro",
  "ccnotification.bulletinType.message.createBulletinTypeFailed" : "Falha ao criar o tipo de boletim.",
  "message.center.nocitiction.callback.receivetime" : "Tempo de recebimento",
  "ccnotification.gatewayconfig.label.code" : "Modo de codifica\u00E7\u00E3o",
  "ccnotification.gatewayconfig.label.resendNumbertitle" : "Os Tempos de Reenvio \u00E9 um n\u00FAmero apropriado",
  "ccnotification.gatewayconfig.label.modifygateway" : "Modificar gateway",
  "ccnotification.gatewayconfig.label.defaultsendnoIsError" : "O formato do endere\u00E7o de email padr\u00E3o est\u00E1 incorreto",
  "ccnotification.notifition.sendmessage.isSendall" : "Enviado",
  "ccnotification.notification.recmessagerouterconfig.receivernotexist" : "O destinat\u00E1rio selecionado n\u00E3o existe.",
  "ccnotification.notifition.template.label.templateid" : "ID do modelo",
  "ccnotification.chatserver.fail.barred" : "Aloca\u00E7\u00E3o de agente bloqueada",
  "ccnotification.smstemplate.message.smstemplateid" : "O ID do modelo da HUAWEI CLOUD n\u00E3o pode estar vazio , n\u00E3o pode ser muito longo ou n\u00E3o estar em conformidade com as especifica\u00E7\u00F5es",
  "ccnotification.notifition.sendmessage.selectuser" : "Selecione um funcion\u00E1rio.",
  "ccnotification.notifition.sendmessage.enterBCCRecipient" : "Selecione um destinat\u00E1rio Bcc.",
  "ccnotification.gatewayconfig.label.messageFlowControlMaxCapsForRecvtitle" : "A Taxa de Recebimento M\u00E1xima \u00E9 um n\u00FAmero",
  "ccnotification.notification.label.attachmentIsEmpty" : "O anexo est\u00E1 vazio.",
  "ccnotification.notification.label.attachment" : "Anexo",
  "message.template.message.typeError" : "Tipo de notifica\u00E7\u00E3o incorreto.",
  "ccnotification.gatewayconfig.label.readTimeouttitle" : "O intervalo de tempo limite de recebimento \u00E9 um n\u00FAmero adequado\, 0: nunca tempo limite",
  "message.template.message.frequently" : "O n\u00FAmero de mensagens enviadas excede o limite di\u00E1rio.",
  "ccnotification.bulletinType.title.createBulletinType" : "Criar tipo de comunicado",
  "ccnotification.gatewayconfig.label.receiverPoolMinCount" : "Grupos de segmentos de recebimento m\u00EDnimos",
  "ccnotification.gatewayconfig.label.maxRcvError" : "M\u00E1ximo de pacotes de erro recebidos",
  "ccnotification.notifition.sendmessage.entertenantname" : "Insira um nome para o espa\u00E7o do Tenant.",
  "ccnotification.gatewayconfig.label.maxContentLengthtitle" : "O comprimento m\u00E1ximo de conte\u00FAdo (bytes) \u00E9 um n\u00FAmero\, que \u00E9 usado como base de divis\u00E3o correspondente\, por exemplo\, 140.",
  "ccnotification.smstemplate.message.fail" : "Falha ao criar o modelo HUAWEI CLOUD.",
  "message.template.button.edit" : "Editar",
  "message.center.label.isRead" : "Marcar mensagens como lidas",
  "ccnotification.notifition.sendmessage.limitdate" : "Selecione uma data anterior a 2038-1-19.",
  "ccnotification.notifition.sendmessage.selecttenant" : "Selecione um tenant.",
  "message.template.message.titleError" : "O t\u00EDtulo do modelo n\u00E3o pode exceder 200 caracteres.",
  "message.center.message.selectMessages" : "Selecione uma mensagem.",
  "message.template.type.internal" : "Notifica\u00E7\u00E3o interna",
  "ccnotification.notification.variable.templatetype" : "Tipo de modelo",
  "ccnotification.bulletinType.message.decimealsCheck" : "O tempo de expira\u00E7\u00E3o padr\u00E3o n\u00E3o pode conter um decimal.",
  "ccnotification.smshuawei.message.invalidappadress" : "Endere\u00E7o de acesso do aplicativo inv\u00E1lido.",
  "ccnotification.gatewayconfig.label.senderPoolMaxCounttitle" : "Os recursos de Thread de Envio M\u00E1ximo \u00E9 um n\u00FAmero\, por exemplo\, 200.",
  "ccnotification.gatewayconfig.label.systemType" : "Tipo ESME",
  "ccnotification.gatewayconfig.label.receiverPoolQueueCapacity" : "Comprimento da fila do pool de segmentos de recebimento",
  "ccnotification.gatewayconfig.label.checkConfigName" : "O valor \u00E9 uma cadeia de caracteres de menos de 10 d\u00EDgitos e letras",
  "ccnotification.gatewayconfig.label.specialStrValidate" : "Somente letras mai\u00FAsculas, min\u00FAsculas e n\u00FAmeros s\u00E3o permitidos.",
  "ccnotification.notifition.template.message.error" : "Valores de entrada inv\u00E1lidos.",
  "message.template.type.out" : "Notifica\u00E7\u00E3o externa",
  "ccnotification.gatewayconfig.label.userid" : "ID de usu\u00E1rio",
  "ccnotification.agent.message.updateCtiAndDbFail" : "Falha na atualiza\u00E7\u00E3o cti e Db.",
  "ccnotification.notifition.sendmessage.enterusername" : "Insira um nome.",
  "ccnotification.bulletinType.message.editBulletinTypeSuccessful" : "O tipo de boletim foi modificado com sucesso.",
  "ccnotification.bulletinType.title.Faults" : "Cr\u00EDtico",
  "ccnotification.notification.label.deleteAttachmentSuccess" : "O anexo foi exclu\u00EDdo com sucesso.",
  "message.template.message.agentRecipientError" : "Alguns destinat\u00E1rios n\u00E3o est\u00E3o vinculados a agentes. Selecione outro destinat\u00E1rio",
  "ccnotification.gatewayconfig.label.deviceTypeDescript" : "Descri\u00E7\u00E3o do tipo do dispositivo",
  "message.center.label.delete" : "Excluir",
  "message.template.message.inputTemplateName" : "Insira um nome de modelo.",
  "ccnotification.notifition.template.label.signatureName" : "Nome da assinatura",
  "message.template.message.queryagentbebindedfailed" : "Falha ao consultar o usu\u00E1rio vinculado ao agente",
  "ccnotification.common.title.delete" : "Excluir",
  "ccnotification.notifition.template.label.varnumber" : "N\u00FAmero de vari\u00E1veis",
  "message.template.message.cancel" : "Cancelar",
  "ccnotification.notifition.template.message.charDigitLabel" : "Algarismo e letra",
  "ccnotification.bulletinType.message.nameIsNotUnique" : "O nome do tipo de boletim j\u00E1 existe.",
  "ccnotification.gatewayconfig.label.selectrebuildgatewayconfig" : "Selecione a configura\u00E7\u00E3o de gateway a ser reconstru\u00EDda",
  "ccnotification.gatewayconfig.label.splitMsgConcatModetitle" : "Modo de combina\u00E7\u00E3o de mensagens SMS longas. 0: cabe\u00E7alho de dados do usu\u00E1rio GSM; 1: par\u00E2metro SMPP.",
  "ccnotification.gatewayconfig.label.mailHosttitle" : "Endere\u00E7o do host\, por exemplo\, 127.0.0.1 ou www.mail.com",
  "ccnotification.gatewayconfig.label.paramContainsSpecialChar" : "O par\u00E2metro cont\u00E9m caracteres especiais",
  "ccnotification.gatewayconfig.label.bindModetitle" : "Indica o modo TX / RX. 1: modo RX; 2: modo TX; 9: modo TX / RX.",
  "ccnotification.gatewayconfig.label.smppPduTypeIsError" : "O tipo de PDU SMS SMPP s\u00F3 pode ser 1:submit_sm\,2:data_sm",
  "ccnotification.notifition.template.button.down" : "Baixar",
  "ccnotification.notifition.sendmessage.priority" : "Prioridade",
  "ccnotification.gatewayconfig.label.selectredgatewayconfig" : "Monitoramento de gateway selecionado:",
  "ccnotification.gatewayconfig.label.splitMsgConcatMode" : "Modo de concatena\u00E7\u00E3o de mensagens longas",
  "ccnotification.gatewayconfig.label.smppPduTypetitle" : "1:submit_sm 2:data_sm",
  "ccnotification.gatewayconfig.label.paramIsNegative" : "O n\u00FAmero n\u00E3o pode ser negativo",
  "ccnotification.common.title.warning" : "Aviso",
  "message.template.mediaType.internal" : "Mensagem interna",
  "ccnotification.gatewayconfig.label.gatewayname" : "Nome do gateway",
  "ccnotification.smshuawei.message.deletesuccess" : "O canal SMS foi exclu\u00EDdo com sucesso.",
  "ccnotification.notifition.sendmessage.referenceTemplate" : "Modelo de refer\u00EAncia",
  "ccnotification.smshuawei.message.fail" : "Falha",
  "ccnotification.notifition.variable.templatename" : "Nome do modelo",
  "ccnotification.notification.recmessagerouterconfig.deletenum.outstripping" : "No m\u00E1ximo 100 registros podem ser exclu\u00EDdos por vez.",
  "ccnotification.agent.contact.downloadFail" : "Falha no download",
  "ccnotification.notifition.sendmessage.selectbyworkno" : "ID do funcion\u00E1rio",
  "ccnotification.notification.recmessagerouterconfig.serviceName" : "Nome do servi\u00E7o",
  "message.center.nocitiction.sending.records.status" : "Status",
  "ccnotification.notifition.template.button.addvar" : "Criar",
  "ccnotification.gatewayconfig.label.localPort" : "Porta do cliente",
  "ccnotification.notification.recmessagerouterconfig.receiver" : "Destinat\u00E1rio",
  "ccnotification.gatewayconfig.label.interfaceVersion" : "N\u00FAmero da vers\u00E3o da mensagem de login",
  "ccnotification.bulletinType.message.betchDeleteBulletinTypeFailed" : "Falha ao excluir os tipos de boletim em lotes.",
  "message.template.message.inputRecipient" : "Insira um destinat\u00E1rio.",
  "ccnotification.gatewayconfig.label.heartbeatNoRespCounttitle" : "O m\u00E1ximo de tempos de conex\u00E3o sem resposta card\u00EDaca \u00E9 um n\u00FAmero adequado",
  "message.center.button.clear" : "Redefinir",
  "message.template.message.basicInformation" : "Informa\u00E7\u00F5es b\u00E1sicas",
  "message.center.label.reply" : "Resposta",
  "message.center.label.bulletinType" : "Tipo de comunicado",
  "ccnotification.notifition.variable.searchTempdateName" : "Insira um nome de modelo.",
  "ccnotification.gatewayconfig.label.mailPorttitle" : "O n\u00FAmero da porta varia de 0 a 65535. O n\u00FAmero de porta SMTP padr\u00E3o \u00E9 25.",
  "ccnotification.notification.label.createTemFileFailed" : "Falha ao criar o arquivo tempor\u00E1rio",
  "ccnotification.center.message.var.type" : "Tipo de vari\u00E1vel:",
  "ccnotification.gatewayconfig.label.registeredDeliverytitle" : "O Relat\u00F3rio de Confirma\u00E7\u00E3o de Status de Devolu\u00E7\u00E3o \u00E9 um n\u00FAmero",
  "ccnotification.gatewayconfig.label.readTimeout" : "Intervalo de tempo limite de recebimento (ms)",
  "ccnotification.gatewayconfig.label.sourceAddrNpi" : "Esquema de c\u00F3digo de endere\u00E7o de origem",
  "ccnotification.gatewayconfig.label.modifygatewayfailed" : "Falha na modifica\u00E7\u00E3o da configura\u00E7\u00E3o do gateway!",
  "ccnotification.notifition.sendmessage.entercompanyName" : "Insira um nome de empresa.",
  "message.center.label.newMessage" : "Enviar notifica\u00E7\u00E3o",
  "ccnotification.notifition.sendmessage.sysmessage" : "Notifica\u00E7\u00E3o do sistema",
  "ccnotification.smshuawei.label.config.explain" : "Os dados de configura\u00E7\u00E3o devem ser os mesmos que os do HUAWEI CLOUD SMS.",
  "ccnotification.common.button.finish" : "Conclu\u00EDdo",
  "ccnotification.smshuawei.label.certVerifyEnabled" : "Se deseja habilitar a verifica\u00E7\u00E3o do certificado",
  "ccnotification.smshuawei.message.securityInfo" : "Se a verifica\u00E7\u00E3o do certificado estiver desativada\, haver\u00E1 risco de falsifica\u00E7\u00E3o de identidade de mesmo n\u00EDvel\. Recomenda-se habilitar a verifica\u00E7\u00E3o do certificado.",
  "ccnotification.notifition.variable.variablename" : "Nome da vari\u00E1vel",
  "message.template.message.deleteSuccess" : "O modelo de notifica\u00E7\u00E3o foi exclu\u00EDdo com sucesso.",
  "ccnotification.gatewayconfig.label.paramIsNotInRange" : "O intervalo de valores do par\u00E2metro est\u00E1 incorreto",
  "ccnotification.notifition.receivermessage.isread" : "Leitura",
  "message.center.nocitiction.email" : "Email",
  "message.center.nocitiction.callback.attachment" : "Anexo",
  "ccnotification.notifition.sendmessage.priorityExtraUrgent" : "Alto",
  "ccnotification.notification.recmessagerouterconfig.selectgateway" : "Selecionar gateway",
  "ccnotification.flowcontrol.title" : "Definir configura\u00E7\u00E3o de notifica\u00E7\u00E3o",
  "ccnotification.sms.gateway" : "SMS de gateway",
  "ccnotification.gatewayconfig.label.defaultreceinfotitle" : "Regra de prefixo numérico aceito: Por exemplo, se 130 estiver configurado, os números que começam com 130 serão correspondidos. Vários números são separados por ponto-e-vírgula (;).",
  "message.template.message.ccOrBccRecipientError" : "A mensagem foi enviada com sucesso. Um destinat\u00E1rio Cc ou Bcc n\u00E3o existe.",
  "ccnotification.gatewayconfig.label.receiverPoolMaxCounttitle" : "Os recursos de Thread de Recep\u00E7\u00E3o M\u00E1ximo s\u00E3o um n\u00FAmero\, por exemplo\, 200.",
  "ccnotification.gatewayconfig.label.smppPduType" : "Tipo de PDU SMPP",
  "ccnotification.notifition.sendmessage.recipientGroup" : "Grupo de destinat\u00E1rios",
  "message.template.message.agentbebinded" : "O destinat\u00E1rio selecionado n\u00E3o est\u00E1 vinculado a um agente",
  "ccnotification.bulletinType.title.bulletinTypeName" : "Nome do tipo de comunicado",
  "ccnotification.gatewayconfig.label.requestParamIsNull" : "O par\u00E2metro de solicita\u00E7\u00E3o est\u00E1 vazio",
  "ccnotification.title.flowcontrol.receiveSmsFlag" : "Receber mensagens MO SMS",
  "ccnotification.gatewayconfig.label.replaceIfPresentFlag" : "Sinalizador de substitui\u00E7\u00E3o",
  "ccnotification.notifition.variable.insertvariable" : "Inserir vari\u00E1vel",
  "ccnotification.notification.recmessagerouterconfig.selectitem" : "Selecione um registro.",
  "ccnotification.notifition.template.label.hwtemplateid" : "ID do modelo da HUAWEI Cloud",
  "ccnotification.chatserver.fail.assign" : "Falha na aloca\u00E7\u00E3o do agente",
  "ccnotification.common.message.maxLength" : "O conte\u00FAdo do texto pode conter um m\u00E1ximo de 4096 caracteres.",
  "ccnotification.gatewayconfig.label.messageprotocol" : "Par\u00E2metros de protocolo de mensagem",
  "message.template.message.createTemplateFail" : "Falha ao criar o modelo de notifica\u00E7\u00E3o.",
  "ccnotification.gatewayconfig.label.slipWindowSize" : "Tamanho da janela deslizante",
  "ccnotification.common.title.confreset" : "Confirmar reinicializa\u00E7\u00E3o",
  "ccnotification.notifition.sendmessage.smsconfignull" : "A configura\u00E7\u00E3o da mensagem SMS est\u00E1 vazia. Escolha Configura\u00E7\u00F5es > Configura\u00E7\u00E3o SMS para configurar informa\u00E7\u00F5es SMS.",
  "message.center.nocitiction.sending.records.unsend" : "Envio agendado",
  "ccnotification.gatewayconfig.label.modifygatewaysuccess" : "Modificar Gatewayconfig com sucesso!",
  "ccnotification.gatewayconfig.label.maxMessageLength" : "Comprimento m\u00E1ximo da mensagem (bytes)",
  "message.template.message.createSuccess" : "O modelo de notifica\u00E7\u00E3o foi criado com sucesso.",
  "ccnotification.gatewayconfig.label.defaultsendname" : "Nome de usu\u00E1rio padr\u00E3o",
  "ccnotification.gatewayconfig.label.defaultemailaddr" : "Endere\u00E7o de e-mail padr\u00E3o",
  "ccnotification.notification.recmessagerouterconfig.willdeleteselectconfig" : "Tem certeza de que deseja excluir a configura\u00E7\u00E3o selecionada?",
  "message.template.message.type" : "Tipo de notifica\u00E7\u00E3o",
  "ccnotification.notifition.variable.close.syntax.error2" : "Verifique se ${} est\u00E1 fechado.",
  "ccnotification.notifition.variable.close.syntax.error1" : "O conte\u00FAdo do modelo cont\u00E9m erros de sintaxe perto de",
  "ccnotification.gatewayconfig.label.selectdeletegatewayconfig" : "Selecione a configura\u00E7\u00E3o do gateway a ser exclu\u00EDda!",
  "ccnotification.notification.label.attachmentSizeError" : "O tamanho do anexo de email excede 10 MB",
  "message.center.label.expireTime" : "Expirou em",
  "ccnotification.gatewayconfig.label.maxRcvErrortitle" : "Os pacotes de erro m\u00E1ximo recebidos \u00E9 um n\u00FAmero adequado",
  "ccnotification.gatewayconfig.label.senderPoolMinCounttitle" : "Os recursos Envido de Thread M\u00EDnimo s\u00E3o um n\u00FAmero\, por exemplo\, 20.",
  "ccnotification.gatewayconfig.label.certFile" : "Arquivo do certificado",
  "message.template.message.generate" : "Gerar modelo",
  "ccnotification.gatewayconfig.label.destAddrTontitle" : "O Tipo de C\u00F3digo de Endere\u00E7o de Destino \u00E9 um n\u00FAmero",
  "ccnotification.urlconfig.pageurls.urlvalidate" : "As urls devem come\u00E7ar com https://",
  "ccnotification.common.button.new" : "Novo",
  "ccnotification.gatewayconfig.label.paramIsNotNumber" : "O valor do par\u00E2metro pode conter apenas d\u00EDgitos",
  "ccnotification.notification.label.deleteAttachmentFailed" : "Falha ao excluir o anexo.",
  "ccnotification.gatewayconfig.label.certType" : "Tipo de certificado",
  "ccnotification.gatewayconfig.label.heartbeatIntervaltitle" : "O intervalo de batimento card\u00EDaco \u00E9 um n\u00FAmero apropriado",
  "ccnotification.notification.label.discounnectedSuccess" : "Desconectado com sucesso",
  "message.template.message.operate" : "Opera\u00E7\u00E3o",
  "message.center.label.refresh" : "Atualizar",
  "ccnotification.notifition.sendmessage.expireTime" : "Expirou em",
  "ccnotification.notifition.gatewaycontact.success" : "Conex\u00E3o bem-sucedida",
  "ccnotification.notifition.template.message.dateTime" : "- Data: AAAA-MM-DD; Hora: hh:mm:ss",
  "ccnotification.gatewayconfig.label.interfaceVersiontitle" : "O n\u00FAmero da vers\u00E3o da mensagem de login deve ser um n\u00FAmero.",
  "ccnotification.notification.variable.template" : "Template",
  "ccnotification.gatewayconfig.label.INIT" : "Inicializa\u00E7\u00E3o",
  "message.template.message.sendEmailError" : "O endere\u00E7o de e-mail do remetente est\u00E1 vazio.",
  "ccnotification.notifition.template.button.up" : "Mover para cima",
  "ccnotification.notifition.sendmessage.priorityGeneral" : "Baixo",
  "ccnotification.gatewayconfig.label.deviceDescript" : "Descri\u00E7\u00E3o do dispositivo",
  "ccnotification.gatewayconfig.label.sslencryptionmodeIsError" : "O modo de criptografia de e-mail s\u00F3 pode ser NENHUMA\, STARTTLS ou TLS",
  "message.template.message.notificationContent" : "Conte\u00FAdo da notifica\u00E7\u00E3o",
  "ccnotification.center.message.var.value" : "Valor da vari\u00E1vel:",
  "message.center.label.download" : "Baixar",
  "ccnotification.notification.recmessagerouterconfig.emailOnlineChat" : "Chat Online Atrav\u00E9s do Canal de E-mail",
  "ccnotification.notifition.gatewaycontact.stop" : "Conex\u00E3o interrompida",
  "message.template.message.phoneError" : "O n\u00FAmero de telefone n\u00E3o existe.",
  "message.template.message.nameLengthError" : "O nome n\u00E3o pode estar em branco , n\u00E3o pode ter mais de 200 caracteres ou conter caracteres especiais",
  "ccnotification.notifition.template.message.phoneLabel" : "N\u00FAmero de telefone",
  "ccnotification.notifition.sendmessage.sendtime" : "Hora de envio",
  "ccnotification.notification.label.isDownloadAttachment": "Se o anexo que você está baixando estiver em formato de documento do Office, por favor, tome as precauções de segurança necessárias.",
  "ccnotification.gatewayconfig.label.addgatewaysuccess" : "Adicionar Gatewayconfig com sucesso!",
  "ccnotification.center.message.var.des" : "Descri\u00E7\u00E3o da vari\u00E1vel:",
  "ccnotification.notification.recmessagerouterconfig.retryTimes" : "Tempos de nova tentativa",
  "ccnotification.common.title.tips" : "Dicas",
  "ccnotification.notification.label.checkAttachNameLegnth" : "O nome do anexo de email n\u00E3o pode exceder 100 caracteres",
  "ccnotification.gatewayconfig.label.resendNumber" : "Tempos de Reenvio",
  "ccnotification.notification.variable.editfailed" : "Falha na modifica\u00E7\u00E3o.",
  "ccnotification.notifition.template.placeholder.var" : "Informe valores que atendam aos requisitos.",
  "message.template.message.deleteTemplateFail" : "Falha ao excluir o modelo de notifica\u00E7\u00E3o.",
  "message.center.label.modify" : "Modificar",
  "message.center.label.receiver" : "Destinat\u00E1rio",
  "message.template.message.willDeleteTemplate" : "A exclus\u00E3o do modelo pode fazer com que a fun\u00E7\u00E3o referenciada fique indispon\u00EDvel. Tem certeza de que deseja continuar?",
  "ccnotification.notifition.variable.namerule" : "O nome da vari\u00E1vel s\u00F3 pode conter alfabetos d\u00EDgitos _ e.",
  "ccnotification.common.message.maxExpireTime" : "Selecione um hor\u00E1rio de expira\u00E7\u00E3o anterior \u00E0 {0}.",
  "ccnotification.notification.systemmonitor.mspId" : "MspId",
  "ccnotification.notification.label.queryAttachmentFailed" : "Falha ao consultar o anexo",
  "ccnotification.notifition.sendmessage.messagetitle" : "T\u00EDtulo",
  "ccnotification.bulletinType.title.bulletinTypeDesc" : "Descri\u00E7\u00E3o",
  "ccnotification.bulletinType.message.editBulletinTypeFailed" : "Falha ao modificar o tipo de boletim.",
  "ccnotification.notification.fileUploadRiskWarning" : "O arquivo que voc\u00EA carregou tem um risco de seguran\u00E7a\, confirme se deseja continuar o upload?",
  "ccnotification.notification.label.uploadAttachmentFailed" : "Falha ao carregar o anexo.",
  "ccnotification.notification.label.fileUploadFileNameError" : "O nome do arquivo que voc\u00EA carregou n\u00E3o atende aos requisitos",
  "ccnotification.notification.label.fileUploadFileTypeError" : "O tipo de arquivo que voc\u00EA carregou n\u00E3o atende aos requisitos",
  "ccnotification.notification.label.fileUploadFileSizeError" : "O tamanho do arquivo que voc\u00EA carregou excede o limite",
  "ccnotification.notification.label.fileUploadFileIOError" : "Ocorreu um erro de an\u00E1lise com o arquivo que voc\u00EA carregou",
  "ccnotification.notifition.sendmessage.enteraccount" : "Insira uma conta comercial.",
  "ccnotification.notifition.sendmessage.maxselectcount" : "Um m\u00E1ximo de 100 funcion\u00E1rios podem ser selecionados.",
  "ccnotification.notification.recmessagerouterconfig.caseserver" : "Chamada de servi\u00E7o",
  "ccnotification.gatewayconfig.label.senderPoolMaxCount" : "M\u00E1ximo de Envio de Pools de Thread",
  "ccnotification.gatewayconfig.label.receiverPoolQueueCapacitytitle" : "O comprimento da fila da cole\u00E7\u00E3o de recursos de segmentos de recebimento \u00E9 um n\u00FAmero\, por exemplo\, 20.",
  "ccnotification.bulletinType.message.nameContainSpecialChar" : "O nome n\u00E3o pode conter caracteres especiais.",
  "ccnotification.bulletinType.message.searchPlaceHolder" : "Nome do tipo de comunicado",
  "ccnotification.smshuawei.message.updatesuccess" : "O canal SMS foi modificado com sucesso.",
  "ccnotification.flowcontrol.getFailed" : "Falha ao obter a configura\u00E7\u00E3o de notifica\u00E7\u00E3o. Verifique a rede.",
  "ccnotification.common.button.delete" : "Excluir",
  "ccnotification.gatewayconfig.label.protocolId" : "ID do protocolo",
  "ccnotification.gatewayconfig.message.securityInfo" : "NONE indica que os dados s\u00E3o transmitidos em texto simples. Portanto\, os dados podem vazar. STARTTLS \u00E9 um protocolo inseguro. O uso de protocolos inseguros representa riscos de seguran\u00E7a. O protocolo TLS \u00E9 recomendado.",
  "ccnotification.gatewayconfig.label.messageFlowControlMaxCapsForSend" : "Taxa m\u00E1xima de envio",
  "ccn.smsnoconfig.table.label" : "N\u00FAmero de SMS",
  "ccn.smshuawei.message.table.label" : "HUAWEI CLOUD SMS",
  "ccnotification.notifition.gatewaycontact.connecting" : "Conex\u00E3o",
  "ccn.smsnoconfig.table.header.countrycode" : "C\u00F3digo do pa\u00EDs remetente",
  "ccn.smsnoconfig.table.header.countrycode.desc" : "País",
  "ccn.smsnoconfig.pop.title.edittitle" : "Editar n\u00FAmero de SMS",
  "ccn.smsnoconfig.pop.title.createtitle" : "Adicionar n\u00FAmero de SMS",
  "ccnotification.notifition.sendmessage.sender" : "N\u00FAmero do remetente",
  "ccn.smsnoconfig.pop.search.reminder" : "Insira um n\u00FAmero.",
  "ccnotification.flowcontrol.recipient.countrycode" : "C\u00F3digo do pa\u00EDs destinat\u00E1rio",
  "ccnotification.notifition.sendmessage.entersender" : "Selecione um n\u00FAmero de remetente.",
  "ccn.smsnoconfig.pop.check.selectwarning" : "Selecione o registro que deseja excluir.",
  "ccn.smsnoconfig.pop.check.deletewarning" : "Tem certeza de que deseja excluir os dados selecionados?",
  "ccn.smsnoconfig.pop.check.duplicatecode" : "O n\u00FAmero j\u00E1 existe.",
  "ccn.smsnoconfig.pop.check.deletesuccess" : "Exclus\u00E3o bem-sucedida.",
  "ccn.smsnoconfig.pop.check.deletefailed" : "Falha na exclus\u00E3o.",
  "ccnotification.notification.emailsignature.placeholder.name" : "Insira um nome de assinatura",
  "ccnotification.notification.emailsignature.content" : "Conte\u00FAdo de Assinatura",
  "ccnotification.notification.emailsignature.description" : "Descri\u00E7\u00E3o da Assinatura",
  "ccnotification.notification.emailsignature.name" : "Nome da assinatura",
  "ccnotification.notification.emailsignature.noitemselect" : "N\u00E3o seleciona nenhum dado.",
  "ccnotification.notification.emailsignature.nameexist" : "O nome da assinatura j\u00E1 existe",
  "ccnotification.notification.emailsignature.reachmaxcount" : "O n\u00FAmero de assinaturas de mensagens excede o m\u00E1ximo.",
  "ccnotification.notification.emailsignature.selection.username" : "Nome do usu\u00E1rio",
  "ccnotification.notification.emailsignature.willdeleteselectitem" : "Tem certeza de que deseja excluir os dados selecionados?",
  "ccnotification.notification.special.charactor.check" : "O conte\u00FAdo inserido n\u00E3o pode conter  < = > / \\ \" eval e exec.",
  "ccnotification.notification.emailsignature.content.length.exceed" : "O comprimento do conte\u00FAdo da assinatura n\u00E3o pode exceder 4096.",
  "ccnotification.notification.label.uploadAttachmentTips" : "Os anexos de e-mail podem estar no seguinte formato: doc, docx, xls, xlsx, txt, png, jpg, xml, json, csv, bmp, zip, ppt, pptx, pdf, O tamanho do arquivo n\u00E3o pode exceder 10 MB.Os arquivos no pacote compactado também devem estar em conformidade com o formato anterior.",
  "message.template.message.modifySuccess" : "O modelo de notifica\u00E7\u00E3o foi modificado com sucesso.",
  "ccnotification.notification.recmessagerouterconfig.updatenum.outstripping" : "Um m\u00E1ximo de 100 registros podem ser atualizados por vez.",
  "ccnotification.notification.uploadfail.duplicateFileName" : "Nome do arquivo Repetir.",
  "ccnotification.bulletinType.message.releaseTime" : "tempo de lan\u00E7amento",
  "ccnotification.bulletinType.message.periodicallySend" : "enviar periodicamente",
  "ccnotification.bulletinType.message.sendingFrequency" : "frequ\u00EAncia de envio",
  "ccnotification.bulletinType.message.everyDay" : "todo dia",
  "ccnotification.bulletinType.message.weekly" : "semanalmente",
  "ccnotification.bulletinType.message.perMonth" : "por m\u00EAs",
  "ccnotification.bulletinType.message.Tuesday" : "Ter\u00E7a\-feira",
  "ccnotification.bulletinType.message.Monday" : "Segunda\-feira",
  "ccnotification.bulletinType.message.Wednesday" : "Quarta\-feira",
  "ccnotification.bulletinType.message.Friday" : "Sexta\-feira",
  "ccnotification.bulletinType.message.Saturday" : "S\u00E1bado",
  "ccnotification.bulletinType.message.Thursday" : "Quinta\-feira",
  "ccnotification.bulletinType.message.daysOfMonth" : "Dia 0",
  "ccnotification.bulletinType.message.sendTimeParamsError2" : "O tempo de libera\u00E7\u00E3o n\u00E3o pode ser posterior ao tempo de expira\u00E7\u00E3o",
  "ccnotification.sms.rcvAndSendMode.receiveSms" : "Receber mensagens SMS",
  "ccnotification.sms.rcvAndSendMode.sendSms" : "Enviar mensagens SMS",
  "ccnotification.bulletinType.message.sendTimeParamsError1" : "A hora de publica\u00E7\u00E3o n\u00E3o pode ser anterior \u00E0 hora atual",
  "ccnotification.gatewayconfig.message.authInfo" : "false tem riscos de seguran\u00E7a. \u00C9 aconselh\u00E1vel definir true para habilitar a autentica\u00E7\u00E3o por senha.",
  "ccnotification.sms.rcvAndSendMode.receiveAndSendSms" : "Enviar e receber mensagens SMS",
  "ccnotification.sms.smppPduTye.data.sm" : "data_sm",
  "ccnotification.sms.smppPduTye.submit.sm" : "submit_sm",
  "ccnotification.bulletinType.message.Sunday" : "Domingo",
  "ccnotification.sms.smpp.parameters" : "Par\u00E2metros de SMPP",
  "ccnotification.sms.smpp.replaceIfPresentFlag.replace" : "Substituir",
  "ccnotification.sms.smpp.stow.away" : "Stow afastado",
  "ccnotification.sms.smpp.replaceIfPresentFlag.not.replace" : "N\u00E3o substituir",
  "ccnotification.sms.gms.user.data.head" : "Cabe\u00E7alho de dados do usu\u00E1rio GSM",
  "ccnotification.sms.smpp.ESME.Connection.Configuration" : "Configura\u00E7\u00E3o da Conex\u00E3o ESME",
  "ccnotification.gatewayconfig.label.splitType.physical" : "divis\u00E3o f\u00EDsica",
  "ccnotification.gatewayconfig.label.dataCoding.ASCII" : "string ASCII",
  "ccnotification.gatewayconfig.label.splitType.ultraLong" : "divis\u00E3o ultralonga",
  "ccnotification.gatewayconfig.label.dataCoding.Binary" : "informa\u00E7\u00F5es bin\u00E1rias",
  "ccnotification.gatewayconfig.label.splitType.noSplit" : "n\u00E3o dividido",
  "ccnotification.gatewayconfig.label.dataCoding.UCS2" : "codifica\u00E7\u00E3o UCS2",
  "ccnotification.gatewayconfig.label.advancedconfig" : "Configura\u00E7\u00F5es avan\u00E7adas",
  "ccnotification.gatewayconfig.label.basic.configuration" : "Configura\u00E7\u00E3o b\u00E1sica",
  "ccnotification.gatewayconfig.label.splitType.disabled" : "desabilitado",
  "ccnotification.notification.recmessagerouterconfig.defaultsendnumber" : "N\u00FAmero de envio padr\u00E3o.",
  "ccnotification.notification.recmessagerouterconfig.defaultreceivenumber" : "N\u00FAmero de recebimento padr\u00E3o.",
  "ccnotification.template.message.organizationStructure" : "Organização",
  "ccnotification.notifition.template.label.signatureChannelId": "Insira um ID de canal de assinatura",
  "ccnotification.template.message.alignment" : "O número de opções de cartão não pode ultrapassar",
  "ccnotification.email.signature.message.uploadImgTypeError" : "Somente imagens em formato jpg jpeg e png s\u00E3o suportadas",
  "ccnotification.email.signature.knowledge.message.uploadImgSizeError" : "O tamanho da imagem enviada n\u00E3o pode exceder {}",
  "ccnotification.email.signature.knowledge.message.uploadError" : "A assinatura do e-mail cont\u00E9m imagens de tamanho ou formato inadequados. Por favor, exclua-as novamente",
  "ccn.flow.grayrule.editsuccess" : "Modificado com sucesso.",
  "ccn.voiceedit.addsuccess" : "Adicionado com sucesso",
  "ccn.voiceedit.addfail" : "Falha ao adicionar.",
  "ccnotification.notification.emailsignature.selection.userEmail" : "Caixa de correio do usu\u00E1rio",
  "ccnotification.notification.emailsignature.selection.userPosition" : "Posi\u00E7\u00E3o do usu\u00E1rio",
  "ccn.voiceedit.deletesuccess" : "Exclu\u00EDdo com sucesso",
  "ccnotification.notifition.template.create" : "Criando um Modelo de Notificação",
  "ccnotification.template.message.channelType" : "Tipo de canal",
  "pop.org.window.org" : "Organização Doméstica",
  "ccnotification.template.message.add.variable": "Adicionar",
  "ccnotification.template.message.content": "Conteúdo do modelo",
  "ccnotification.template.message.enterDescription": "Por favor, insira uma descrição.",
  "ccnotification.notification.systemmonitor.batchrebuild":"Reconstrução em lote",
  "ccnotification.notification.systemmonitor.batchdisconnect":"desconexão em massa",
  "ccnotification.gatewayconfig.label.send.prefix":"Prefixo do número do remetente",
  "ccnotification.gatewayconfig.label.receive.prefix":"Prefixo de número aceito",
  "ccnotification.signature.add":"Criar assinatura de e-mail",
  "ccnotification.signature.update":"Modificar assinatura de e-mail",
  "ccnotification.signature.view":"Exibir assinatura de e-mail",
  "ccnotification.gatewayconfig.label.select.sendNumber.title":"Selecionando o número do remetente",
  "ccnotification.gatewayconfig.label.add.sendNumber.title":"Adicionando um número de remetente",
  "ccn.whitelist.urlvalidatefailed": "Insira o nome de IP ou dom\u00EDnio",
  "ccnotification.notification.notificationRoute.recipientAddress" : "Endereço de recepção",
  "ccnotification.notification.notificationRoute.implementationMode" : "Modo de implementação",
  "ccnotification.notification.notificationRoute.edit" : "Editar",
  "ccnotification.notification.notificationRoute.newNotificationRoute" : "Nova rota de notificação",
  "ccnotification.notification.notificationRoute.editNotificationRoute" : "Editar Roteamento de Notificação",
  "ccnotification.notification.notificationRoute.inbox" : "Caixa de entrada",
  "ccnotification.notification.notificationRoute.serviceInvoking" : "Chamada de serviço",
  "ccnotification.notification.notificationRoute.selectconfig" : "Selecione uma configura\u00E7\u00E3o.",
  "ccnotification.notification.notificationRoute.willdeleteselectconfig" : "Tem certeza de que deseja excluir a configura\u00E7\u00E3o selecionada?",
  "ccnotification.notification.notificationRoute.deletenum.outstripping" : "No m\u00E1ximo 100 registros podem ser exclu\u00EDdos por vez.",
  "ccnotification.notification.notificationRoute.delSuccess" : "Exclu\u00EDdo com sucesso",
  "ccnotification.notification.notificationRoute.delFail" : "Falha na exclusão.",
  "ccnotification.notification.notificationRoute.gateway" : "Gateway",
  "ccnotification.notification.notificationRoute.gatewaynotexist" : "O gateway selecionado não existe",
  "ccnotification.notification.notificationRoute.receivernotexist" : "O destinatário selecionado não existe",
  "ccnotification.notification.notificationRoute.receiveaddressexist" : "O endereço de recepção já existe",
  "ccnotification.notification.notificationRoute.intelligentOrder" : "Criação inteligente de casos",
  "ccnotification.notification.notificationRoute.mobilerule" : "Insira números de celular. Separe vários números móveis com ponto-e-vírgula",
  "ccnotification.notification.notificationRoute.inputPhoneNum" : "Introduza o número de telemóvel",
  "ccnotification.notification.notificationRoute.receiveAddrLen" : "O comprimento não pode exceder 1024",
  "ccnotification.notification.notificationRoute.retryNumLimitRule" : "O valor de entrada está fora do intervalo:0-3",
  "ccn.variable.willdeleteselectvariable": "Tem certeza de que deseja excluir as variáveis selecionadas?",
  "ccn.variable.deleteselectvariable": "Excluir variável",
  "ccn.variable.variableisreferenced": "As variáveis são referenciadas pelo fluxo.",
  "ccn.variable.view.references": "Exibir referências",
  "ccn.variable.view.references.none": "A variável não é referenciada no momento",
  "ccn.variable.selectvariable": "Selecione um parâmetro variável.",
  "ccn.variable.field": "Variáveis de notificação",
  "ccn.unit.bytes": "Bytes",
  'ccn.unit.millisecond': 'milissegundos',
  "ccn.message.fullscreen": "Maximizar",
  'ccn.sms.config.batch.delete': 'Exclusão em massa',
  'ccn.sms.config.receiver.uplinkMess': 'Receber SMS upstream',
  "ccn.sms.config.create.smsConfig": "Nova configuração de SMS",
  "ccn.sms.config.modify.smsConfig": "Modificando configurações de SMS",
  "ccnotification.smstemplate.message.edit.fail": "Falha ao modificar o modelo de SMS da HUAWEI CLOUD.",
  "ccnotification.message.choose": "Selecione os dados.",
  "ccnotification.message.add.attachement": "Adicionar anexo",
  "ccnotification.message.defaultsenderid.error": "O número de remetente padrão não está no intervalo permitido. Por favor, selecione um número correto.",
  "ccnotification.template.message.emailAddressEmpty": "E-mail do destinatário não configurado:",
  "ccn.countrycode.type": "Tipo de Prefixo de Código de País",
  "ccn.countrycode.type.with.plus": "Prefixado com +",
  "ccn.countrycode.type.without.plus": "Não prefixado com +"
}