<script>
import {queryUnbindedAgent} from '@/views/sumweb/api'
import AiccElMessage from '@/utils/el-message'

export default {
  name: 'unbind-agent-dialog',
  data() {
    return {
      display: false,
      tableData: [],
      limit: 10,
      offset: 0,
      total: 0,
      pageSize: 10,
      currentPage: 1,
      pageSizes: [10, 20, 50, 100],
      currentRow: null,
      agentType: '',
      ccRoleId: '',
      workNo: '',
      tableRadio: ''
    }
  },
  watch: {
    id() {
      if (this.id) {
        this.handleRadioChange(this.id)
      }
    }
  },
  methods: {
    handleOpen() {
      this.offset = 0
      this.limit = 10
      this.currentPage = 1
      this.handleRefresh()
    },
    handleRefresh(data) {
      if (data) {
        this.agentType = data.params.agentType
        this.ccRoleId = data.params.ccRoleId
        this.workNo = data.params.workNo
      }
      queryUnbindedAgent({
        limit: this.limit,
        offset: this.offset,
        workNo: this.workNo,
        agentType: this.agentType,
        ccRoleId: this.ccRoleId
      })
        .then((res) => {
          if (res && res.agents) {
            this.tableData = res.agents
            this.total = res.total
          }
        })
        .catch(() => {
          AiccElMessage.error($t('SM.COMMON.MESSAGE.OPERATE_FAIL'))
        })
    },
    handleCurrentChange(currentPage) {
      this.offset = (currentPage - 1) * this.limit
      this.handleRefresh()
    },
    handleSizeChange(count) {
      this.limit = count
      this.offset = 0
      this.currentPage = 1
      this.handleRefresh()
    },
    handleConfirm() {
      this.$emit('getAgent', this.currentRow)
      this.display = false
    },
    getRowKey(row) {
      return row.id
    },
    handleCurrentRow(row) {
      this.currentRow = row
    },
    agentTypeFormatter(row, column) {
      let data = row[column.property]
      if (data) {
        return this.agentTypes[data]
      }
    },
    roleFormatter(row, column) {
      let data = row[column.property]
      if (data) {
        for (const role of this.roleItems) {
          if (role.ccRoleId === data) {
            return role.roleName
          }
        }
      }
    },
    handleRadioChange(id) {
      this.$refs?.singleTableRef?.setCurrentRow(id)
      this.tableRadio = id
    }
  },
  computed: {
    agentTypes() {
      return {
        4: $t('ccm.agent.label.audio'),
        11: $t('ccm.agent.label.video'),
        5: $t('ccm.agent.label.mediaagent'),
        99: $t('ccm.agent.label.versatileagent')
      }
    },
    templateMetaData() {
      return {
        // 基础搜索配置
        basicConfig: {
          // 基础搜索
          basicSearch: [
            {
              searchType: 'primary',
              modelValue: 'workNo',
              modelDefault: '',
              placeholderValue: this.$t('ccm.agent.label.workNo')
            }
          ],
          iconButton: [
            {
              buttonType: 'refresh',
              eventName: 'handleSearch'
            }
          ]
        },
        advancedSearch: {
          outwardMargin: '20px',
          searchList: [
            {
              searchType: 'select',
              modelValue: 'agentType',
              placeholderValue: this.$t('ccm.agent.label.agentType'),
              selectOptions: [
                { value: '4', label: this.$t('ccm.agent.label.audio') },
                { value: '11', label: this.$t('ccm.agent.label.video') },
                {
                  value: '5',
                  label: this.$t('ccm.agent.label.mediaagent')
                },
                {
                  value: '99',
                  label: this.$t('ccm.agent.label.versatileagent')
                }
              ],
              clearable: true,
              colConfig: {
                xs: 12,
                sm: 12,
                sm: 12,
                lg: 12,
                xl: 12
              }
            },
            {
              searchType: 'select',
              modelValue: 'ccRoleId',
              placeholderValue: this.$t('ccm.agent.label.ccRoleId'),
              selectOptions: this.roleItems.map((item) => {
                return {
                  value: item.ccRoleId,
                  label: item.roleName
                }
              }),
              clearable: true,
              colConfig: {
                xs: 12,
                sm: 12,
                sm: 12,
                lg: 12,
                xl: 12
              }
            }
          ]
        }
      }
    }
  },
  props: ['roleItems', 'id']
}
</script>

<template>
  <el-dialog
    v-model="display"
    :title="$t('cms.report.field.selectAgent')"
    @open="handleOpen"
    :close-on-click-modal="false"
    width="616"
    class="aicc-dialog-limit-height"
  >
    <aicc-advanced-search
      :templateMetaData="templateMetaData"
      @handleSearch="handleRefresh"
      @handleReset="handleReset"
      @handleRefresh="handleRefresh"
      ref="advancedSearchRef"
    >
    </aicc-advanced-search>
    <el-table
      :data="tableData"
      ref="singleTableRef"
      highlight-current-row
      :row-key="getRowKey"
      height="430"
      @current-change="handleCurrentRow"
      tooltip-effect="light"
    >
      <el-table-column
        property="workNo"
        :show-overflow-tooltip="true"
        width="40"
      >
        <template #default="scope">
          <sweet-radio
            v-model="tableRadio"
            :label="scope.row.id"
            @click.prevent="handleRadioChange(scope.row.id)"
            >{{ '' }}
          </sweet-radio>
        </template>
      </el-table-column>
      <el-table-column
        property="workNo"
        :show-overflow-tooltip="true"
        :label="$t('ccm.agent.label.workNo')"
      />
      <el-table-column
        property="sipAccount"
        :show-overflow-tooltip="true"
        :label="$t('ccm.agent.label.sipAccount')"
      />
      <el-table-column
        property="agentType"
        :formatter="agentTypeFormatter"
        :show-overflow-tooltip="true"
        :label="$t('ccm.agent.label.agentType')"
      />
      <el-table-column
        property="ccRoleId"
        :show-overflow-tooltip="true"
        :formatter="roleFormatter"
        :label="$t('ccm.agent.label.ccRoleId')"
      />
    </el-table>
    <el-pagination
      v-model:page-size="pageSize"
      :page-sizes="pageSizes"
      :total="total"
      v-model:current-page="currentPage"
      :small="false"
      :disabled="false"
      :background="false"
      layout="sizes, total, prev, pager, next"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="display = false">{{ $t('cms.indexmonitor.field.cancel') }}</el-button>
        <el-button type="primary" @click="handleConfirm">
          {{ $t('cms.indexmonitor.field.confirm') }}
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<style scoped></style>
