export default {
  "ccmessaging.chat.fiveGCard.add": "เพิ่มการ์ด 5G ใหม่",
  "ccmessaging.chat.fiveGCard.edit": "แก้ไขการ์ด 5G",
  "ccmessaging.chat.fiveGCarouselCard.add": "เพิ่มบัตร 5G Carousel",
  "ccmessaging.chat.fiveGCarouselCard.edit": "แก้ไขบัตร 5G Carousel",
  "ccmessaging.chat.webCard.add": "เพิ่มการ์ดเว็บใหม่",
  "ccmessaging.chat.webCard.edit": "แก้ไขบัตรเว็บ",
  "ccmessaging.chat.coBrowseSite.add": "เพิ่มที่อยู่ที่ใช้ร่วมกันของเว็บเพจใหม่",
  "ccmessaging.chat.coBrowseSite.edit": "แก้ไขที่อยู่ที่ใช้ร่วมกันของเว็บเพจ",
  "ccmessaging.chat.richTextPage.add": "เพิ่ม Rich Text",
  "ccmessaging.chat.richTextPage.edit": "แก้ไขข้อความที่อุดมไปด้วย",
  "ccmessaging.chat.chatPhrase.add": "เพิ่มข้อความที่ใช้บ่อย",
  "ccmessaging.chat.chatPhrase.edit": "แก้ไขข้อความที่ใช้บ่อย",
  "ccmessaging.chat.audioLibrary.add": "เพิ่มเสียงพูดใหม่",
  "ccmessaging.chat.audioLibrary.edit": "แก้ไขเสียงพูด",
  "ccmessaging.chat.videoLibrary.add": "เพิ่มวิดีโอใหม่",
  "ccmessaging.chat.videoLibrary.edit": "แก้ไขวิดีโอ",
  "ccmessaging.chat.imgLibrary.add": "เพิ่มรูปภาพใหม่",
  "ccmessaging.chat.imgLibrary.edit": "แก้ไขรูปภาพ",
  "ccmessaging.chat.emoLibrary.add": "เพิ่มอีโมติคอนในแบบของคุณ",
  "ccmessaging.chat.emoLibrary.edit": "แก้ไขอีโมติคอนในแบบของคุณ",
  "ccmessaging.chat.docLibrary.add": "เพิ่มเอกสารใหม่",
  "ccmessaging.chat.docLibrary.edit": "แก้ไขเอกสาร",
  "ccmessaging.chat.locate.add": "เพิ่มที่อยู่ใหม่",
  "ccmessaging.chat.locate.edit": "แก้ไขที่อยู่",
  "ccmesaging.chat.agentconsole.commom.label": "แท็ก",
  "ccmesaging.chat.agentconsole.label.callReason": "เหตุผลในการโทร",
  "ccmesaging.chat.agentconsole.label.commonreply": "การตอบกลับทั่วไป",
  "ccmesaging.chat.agentconsole.label.expression": "สัญรูปอารมณ์",
  "ccmesaging.chat.agentconsole.label.fontstyle": "รูปแบบตัวอักษร",
  "ccmesaging.chat.agentconsole.label.historicalcontact": "ประวัติการติดต่อ",
  "ccmesaging.chat.agentconsole.label.incallmessage": "ข้อความเสียง",
  "ccmesaging.chat.agentconsole.label.Realtimetransfer": "การแปลตามเวลาจริง",
  "ccmesaging.chat.agentconsole.label.robotChat": "โต้ตอบกับ Chatbot",
  "ccmesaging.chat.agentconsole.label.screenshot": "ภาพหน้าจอ",
  "ccmesaging.chat.agentconsole.label.seeMore": "ดูเพิ่มเติม",
  "ccmesaging.chat.agentconsole.label.service": "บริการ",
  "ccmesaging.chat.agentconsole.label.uploadattachment": "อัปโหลดไฟล์แนบ",
  "ccmesaging.chat.agentconsole.label.uploadimage": "อัปโหลดรูปภาพ",
  "ccmesaging.chat.agentconsole.title.release": "เผยแพร่",
  "ccmesaging.chat.cobrowse.accept": "ยอมรับ",
  "ccmesaging.chat.cobrowse.agentMax": "ตัวแทนกำลังทำงานร่วมกันบนหน้าเว็บ โปรดลองอีกครั้ง",
  "ccmesaging.chat.cobrowse.isAcceptWeb": "คุณต้องการยอมรับคําเชิญร่วมงานทางเว็บหรือไม่?",
  "ccmesaging.chat.cobrowse.isAcceptWebMax": "เกินจํานวนสูงสุดของการเชื่อมต่อการทํางานร่วมกันบนหน้าเว็บที่ผู้เช่าปัจจุบันอนุญาตแล้ว",
  "ccmesaging.chat.cobrowse.noWebSiteAuth": "ตัวแทนไม่ได้รับอนุญาตให้ทํางานร่วมกันบนหน้าเว็บ",
  "ccmesaging.chat.cobrowse.refused": "ปฏิเสธ",
  "ccmesaging.chat.cobrowse.title": "คําเชิญร่วมงานกับเว็บเพจ",
  "ccmesaging.chat.cobrowse.webSite": "URL การทํางานร่วมกันของเว็บ:",
  "ccmesaging.chat.cobrowse.webSiteButton": "การทํางานร่วมกันของหน้าเว็บ",
  "ccmesaging.chat.cobrowse.webSitePlaceholder": "โปรดป้อน URL ที่ใช้ร่วมกัน",
  "ccmessaging.address.protocol.check": "โปรดทราบว่าโปรโตคอลเครือข่ายที่ใช้โดยที่อยู่ไม่ใช่โปรโตคอลที่ปลอดภัย หากคุณตัดสินใจใช้โปรโตคอลนี้ ความเสี่ยงด้านความปลอดภัยอาจมีอยู่",
  "ccmessaging.channelconfig.algorithm": "ปรับใช้อัลกอริทึมการเข้ารหัส",
  "ccmessaging.channelconfig.email.freeTimeText": "ตอบกลับอัตโนมัติเมื่อไม่ได้ทํางาน",
  "ccmessaging.channelconfig.email.freeTimeText.tips": "เปิดเวลาทํางานเพื่อตอบกลับข้อความของลูกค้าที่ไม่ได้รับหลังจากเปิดใช้",
  "ccmessaging.channelconfig.email.reply.each.eamil": "ตอบกลับอัตโนมัติต่อข้อความ",
  "ccmessaging.channelconfig.email.selectAutoReply": "โปรดเลือกเทมเพลตจดหมายตอบกลับอัตโนมัติ",
  "ccmessaging.channelconfig.email.selectFreeTimeText": "โปรดเลือกเทมเพลตจดหมายตอบกลับสําหรับวันที่ไม่ใช่วันทําการ",
  "ccmessaging.channelconfig.email.worktime.autoReply": "ตอบกลับอัตโนมัติเวลาทํางาน",
  "ccmessaging.channelconfig.email.worktime.autoReply.tips": "เปิดใช้งานเวลาทํางานเพื่อตอบกลับข้อความของลูกค้า",
  "ccmessaging.channelconfig.sms.autoReply": "ตอบกลับอัตโนมัติ",
  "ccmessaging.channelconfig.sms.autoReply.tips": "การตอบกลับอัตโนมัติจะถูกส่งหลังจากตัวแทนเชื่อมต่อสําเร็จแล้ว",
  "ccmessaging.channelconfig.sms.freeTimeText": "การตอบกลับแบบไม่เป็นวันทําการ",
  "ccmessaging.channelconfig.sms.selectAutoReply": "เทมเพลตการตอบกลับอัตโนมัติ SMS",
  "ccmessaging.channelconfig.sms.selectFreeTimeText": "เทมเพลตการตอบกลับ SMS ที่ไม่ใช่วันทํางาน",
  "ccmessaging.char.agentstatus.after.callends": "ลําดับความสําคัญของการกําหนดค่าสถานะเอเจนต์จะมีผลหลังจากสิ้นสุดการโทรดังนี้: ระดับองค์กร > ระดับผู้เช่า หากพารามิเตอร์นี้ว่างอยู่ การกําหนดค่าจะไม่มีผล และจะใช้การกําหนดค่าระดับผู้เช่า",
  "ccmessaging.char.call.Idle.state": "สถานะไม่ได้ใช้งาน",
  "ccmessaging.char.call.work.state": "สถานะการทํางาน",
  "ccmessaging.char.channelconfig.duplicateCodeName": "มีชื่อช่องอยู่แล้ว",
  "ccmessaging.chat.agent.organizational.structure": "โครงสร้างองค์กร",
  "ccmessaging.chat.agent.refreshFail": "อย่ารีเฟรชหน้าเว็บหลายครั้งง",
  "ccmessaging.chat.agent.transferSessionFail": "การถ่ายโอนเซสชันล้มเหลว",
  "ccmessaging.chat.agentconsole.accesschannel": "ช่องทางการเข้าถึง",
  "ccmessaging.chat.agentconsole.agentStatus.error": "เจ้าหน้าที่ไม่ได้อยู่ในสถานะออนไลน์และการโทร ไม่สามารถเริ่มเซสชั่นได้",
  "ccmessaging.chat.agentconsole.allResults": "ผลลัพธ์ทั้งหมด",
  "ccmessaging.chat.agentconsole.asyncTimeBeyond": "การส่งล้มเหลว เกินช่วงเวลาที่ไม่อนุญาตให้ตอบกลับ",
  "ccmessaging.chat.agentconsole.beyondLimitNum": "ปริมาณการส่งข้อความในปัจจุบันเกินขีดจํากัด!",
  "ccmessaging.chat.agentconsole.browserecord": "ประวัติการค้นหา",
  "ccmessaging.chat.agentconsole.chat.close": "",
  "ccmessaging.chat.agentconsole.chat.userId": "การระบุผู้ใช้",
  "ccmessaging.chat.agentconsole.chat.userSettings": "ตั้งค่าผู้ใช้",
  "ccmessaging.chat.agentconsole.chatPhrase": "คำ",
  "ccmessaging.chat.agentconsole.cobrowse": "เว็บเพจ",
  "ccmessaging.chat.agentconsole.contract.pdfUploadMaxSizeTip": "ขนาดของสัญญาต้องไม่เกิน {n}M.",
  "ccmessaging.chat.agentconsole.contract.statisticSigns": "สถิติลายเซ็น",
  "ccmessaging.chat.agentconsole.contract.statisticTotalSigns": "สถิติ",
  "ccmessaging.chat.agentconsole.contract.statisticTotalSigns.failTip": "ไม่สามารถรวบรวมสถิติเกี่ยวกับรายการลายเซ็นในสัญญาได้",
  "ccmessaging.chat.agentconsole.contract.statisticTotalSigns.noSigns.failTip": "ไม่พบไอคอนตําแหน่งลายเซ็น",
  "ccmessaging.chat.agentconsole.contract.statisticTotalSigns.noStaticSigns.failTip": "โปรดนับสัญญาที่มีลายเซ็นก่อน",
  "ccmessaging.chat.agentconsole.contract.upload.failTip": "ไม่สามารถอัปโหลดสัญญาได้",
  "ccmessaging.chat.agentconsole.contract.upload.noSelectSigns.failTip": "รายการลายเซ็นไม่ได้ถูกเลือกสําหรับสัญญา",
  "ccmessaging.chat.agentconsole.contract.upload.sameContract.failTip": "ผู้ใช้บริการมีสัญญาที่ยังไม่หมดอายุ",
  "ccmessaging.chat.agentconsole.current": "กระแส",
  "ccmessaging.chat.agentconsole.customerinformation": "ข้อมูลลูกค้า",
  "ccmessaging.chat.agentconsole.defaultOrgName": "องค์กรที่ไม่เกี่ยวข้อง",
  "ccmessaging.chat.agentconsole.document": "เอกสาร",
  "ccmessaging.chat.agentconsole.emailNotSupport": "ช่องอีเมลไม่รองรับข้อความแอป",
  "ccmessaging.chat.agentconsole.enter.total.number": "กรุณากรอกจำนวนลายเซ็นทั้งหมด",
  "ccmessaging.chat.agentconsole.filterByCondition": "ตัวกรองตามเงื่อนไข",
  "ccmessaging.chat.agentconsole.fivegNotSupport": "ช่อง 5G ไม่รองรับการส่งข้อความมัลติมีเดีย",
  "ccmessaging.chat.agentconsole.hisconversation": "ประวัติการสนทนาย้อนหลัง",
  "ccmessaging.chat.agentconsole.history": "ประวัติความเป็นมา",
  "ccmessaging.chat.agentconsole.image": "ภาพ",
  "ccmessaging.chat.agentconsole.information": "ข้อมูลทั่วไป",
  "ccmessaging.chat.agentconsole.intention": "ความตั้งใจ",
  "ccmessaging.chat.agentconsole.IpAddress": "ที่อยู่ IP",
  "ccmessaging.chat.agentconsole.label.defaultwelcome": "ยินดีต้อนรับสู่ระบบบริการลูกค้าออนไลน์!",
  "ccmessaging.chat.agentconsole.label.endsession": "ว่าจะจบเซสชั่นไหม?",
  "ccmessaging.chat.agentconsole.label.filesFacebookNotsupport": "ปัจจุบัน Facebook Messenger ไม่รองรับการรับข้อมูลสถานที่",
  "ccmessaging.chat.agentconsole.label.filesTwitterNotsupport": "Twitter ไม่รองรับเสียงค้นหาหรือวิดีโอที่มีความยาวเกิน 30 วินาที!",
  "ccmessaging.chat.agentconsole.label.filesWechatNotsupport": "WeChat ไม่รองรับเสียงที่นานกว่า 60 วินาที",
  "ccmessaging.chat.agentconsole.label.messageRejected": "ข้อความถูกปฏิเสธโดย Twitter เนื่องจากดูเหมือนว่าเป็นสแปมโปรดใช้คำพ้องความหมายหรือลองอีกครั้งในภายหลัง:",
  "ccmessaging.chat.agentconsole.label.NoAudioDuration": "ไม่สามารถรับระยะเวลาของเสียงได้ กรุณาอัพโหลดมันอีกครั้ง",
  "ccmessaging.chat.agentconsole.label.NoVideoDuration": "ไม่สามารถนำเข้าวิดีโอได้ กรุณาอัปโหลดอีกครั้ง",
  "ccmessaging.chat.agentconsole.label.ongoingsession": "ตอนนี้ไม่มีเซสชั่นต่อเนื่อง!",
  "ccmessaging.chat.agentconsole.label.ongoingwx": "ช่องปัจจุบันไม่สามารถส่งข้อมูลตำแหน่งได้!",
  "ccmessaging.chat.agentconsole.label.skillNoAgent": "ตัวแทนในคิวทักษะออฟไลน์",
  "ccmessaging.chat.agentconsole.loading": "โหลดเพิ่มเติม",
  "ccmessaging.chat.agentconsole.locate": "สถานที่",
  "ccmessaging.chat.agentconsole.mailDraft": "ร่างจดหมาย",
  "ccmessaging.chat.agentconsole.message.notworktime": "เจ้าหน้าที่ไม่ได้อยู่ในเวลาทํางาน!",
  "ccmessaging.chat.agentconsole.messageloading": "กำลังโหลด",
  "ccmessaging.chat.agentconsole.multimedia": "แอป",
  "ccmessaging.chat.agentconsole.multimediaid": "ID แอป",
  "ccmessaging.chat.agentconsole.multimedianame": "ชื่อแอป",
  "ccmessaging.chat.agentconsole.myconversation": "บทสนทนาของฉัน",
  "ccmessaging.chat.agentconsole.nocontact": "ไม่มีผู้ติดต่อในขณะนี้",
  "ccmessaging.chat.agentconsole.noHiscontact": "ไม่มีเซสชันในอดีต",
  "ccmessaging.chat.agentconsole.notSupport": "ช่องนี้ไม่รองรับข้อความมัลติมีเดียประเภทนี้",
  "ccmessaging.chat.agentconsole.noUserArrive": "ไม่มีผู้ใช้งานในขณะนี้!",
  "ccmessaging.chat.agentconsole.novoEmail": "อีเมลใหม่",
  "ccmessaging.chat.agentconsole.nowconversation": "การสนทนาปัจจุบัน",
  "ccmessaging.chat.agentconsole.only.pdf.supported": "รองรับเฉพาะไฟล์ PDF เท่านั้น",
  "ccmessaging.chat.agentconsole.photo": "รูปถ่าย",
  "ccmessaging.chat.agentconsole.promptConetnt.cannot.rollback": "ข้อความไม่สามารถถอนได้",
  "ccmessaging.chat.agentconsole.promptConetnt.ContentLengthExceeds": "ไม่อนุญาตให้มีเนื้อหาข้อความยาวเกินขีด จำกัด !",
  "ccmessaging.chat.agentconsole.promptConetnt.ContentNull": "ไม่อนุญาตให้ใช้ EmptyContent!",
  "ccmessaging.chat.agentconsole.promptConetnt.delectMessageRecord": "คุณได้ทำการลบบันทึก",
  "ccmessaging.chat.agentconsole.promptConetnt.onlyFiveG": "5G ข้อความการ์ดรองรับช่องทาง 5G เท่านั้น!",
  "ccmessaging.chat.agentconsole.promptConetnt.onlyWebCard": "Web ข้อความการ์ดรองรับช่องทาง Web เท่านั้น!",
  "ccmessaging.chat.agentconsole.promptConetnt.operateFail": "ไม่สามารถดำเนินการกับข้อความที่ส่งไม่สำเร็จได้",
  "ccmessaging.chat.agentconsole.promptConetnt.operateFailWithChannel": "สามารถดำเนินการได้เฉพาะข้อความทางเว็บเท่านั้น",
  "ccmessaging.chat.agentconsole.promptConetnt.operateRollBackFail": "ข้อความที่ส่งเกินสองนาทีไม่สามารถยกเลิกได้",
  "ccmessaging.chat.agentconsole.promptConetnt.rollBackMessage": "คุณได้ถอนข้อความ",
  "ccmessaging.chat.agentconsole.promptConetnt.userAlreadyInSession": "การส่งล้มเหลว ผู้ใช้กําลังโทร",
  "ccmessaging.chat.agentconsole.promptConetnt.userBusy": "เดี๋ยวผมจัดการเอง ตอนว่าง",
  "ccmessaging.chat.agentconsole.promptConetnt.userInfoNull": "ข้อมูลผู้ใช้ ได้รับไม่ถูกต้องเซสชันจะถูกตัดการเชื่อมต่อ!",
  "ccmessaging.chat.agentconsole.promptConetnt.userIsTalking.saveDraftFailed": "การส่งข้อความล้มเหลว ผู้ใช้กําลังโทร ส่งข้อความแบบร่างล้มเหลว",
  "ccmessaging.chat.agentconsole.promptConetnt.userIsTalking.saveDraftSuccess": "การส่งข้อความล้มเหลว ผู้ใช้กําลังโทร ข้อความปัจจุบันได้ถูกส่งไปยังฉบับร่างแล้ว",
  "ccmessaging.chat.agentconsole.promptConetnt.userNoArrive": "ไม่มีผู้ใช้เชื่อมต่อหรือไม่มีการเลือกผู้ใช้!",
  "ccmessaging.chat.agentconsole.promptConetnt.userOffline": "ผู้ใช้ปัจจุบันออฟไลน์!",
  "ccmessaging.chat.agentconsole.queuing": "คิว",
  "ccmessaging.chat.agentconsole.quoteReply": "อ้างคำตอบนี้",
  "ccmessaging.chat.agentconsole.recommendedCaseType": "ประเภทคําสั่งงานที่แนะนํา",
  "ccmessaging.chat.agentconsole.replyEmail": "ตอบกลับอีเมล",
  "ccmessaging.chat.agentconsole.robotAssistant": "ผู้ช่วยหุ่นยนต์",
  "ccmessaging.chat.agentconsole.select.contract.uploaded": "โปรดเลือกเอกสารที่จะอัปโหลด",
  "ccmessaging.chat.agentconsole.select.mandatory.signature": "โปรดเลือกลายเซ็นบังคับ",
  "ccmessaging.chat.agentconsole.selectCaseType": "การเลือกประเภทคําสั่งงาน",
  "ccmessaging.chat.agentconsole.send.contract": "ส่งเอกสาร",
  "ccmessaging.chat.agentconsole.send.repeat": "อย่าคลิกซ้ํา! ",
  "ccmessaging.chat.agentconsole.sendEmail": "ส่งอีเมล",
  "ccmessaging.chat.agentconsole.sendLocalFile": "ส่งไฟล์ในเครื่อง",
  "ccmessaging.chat.agentconsole.sendmessage": "ส่งข้อความ",
  "ccmessaging.chat.agentconsole.setHandledNumber": "ตั้งค่าหมายเลขที่จับได้",
  "ccmessaging.chat.agentconsole.signature.variable": "ลายเซ็น{n}",
  "ccmessaging.chat.agentconsole.signInCallNum": "จํานวนสายที่ลงชื่อเข้าใช้ปัจจุบัน:",
  "ccmessaging.chat.agentconsole.smsTemplate": "เทมเพลต SMS",
  "ccmessaging.chat.agentconsole.software": "สภาพแวดล้อมซอฟต์แวร์",
  "ccmessaging.chat.agentconsole.spellcheck.correcting.not.finished": "ข้อความผิดของคุณยังไม่ได้รับการแก้ไขทั้งหมด ปิดการตรวจสอบหรือไม่",
  "ccmessaging.chat.agentconsole.spellcheck.correcting.cancel.confirmation": "เนื้อหาที่ได้รับการแก้ไขจะไม่ถูกบันทึกหลังการยกเลิก คุณต้องการยกเลิกการตรวจสอบหรือไม่?",
  "ccmessaging.chat.agentconsole.spellcheck.correctly.writing": "คําที่ถูกต้อง",
  "ccmessaging.chat.agentconsole.spellcheck.failed": "การตรวจสอบคําสะกดล้มเหลว",
  "ccmessaging.chat.agentconsole.spellcheck.language": "ภาษา",
  "ccmessaging.chat.agentconsole.spellcheck.next": " ถัดไป",
  "ccmessaging.chat.agentconsole.spellcheck.previous": "ก่อนหน้า",
  "ccmessaging.chat.agentconsole.spellcheck.replace": "แทนที่",
  "ccmessaging.chat.agentconsole.spellcheck.replaceall": "แทนที่ทั้งหมด",
  "ccmessaging.chat.agentconsole.spellcheck.sentence.length.failed": "ความยาวข้อความเกินขีดจํากัด 1024",
  "ccmessaging.chat.agentconsole.spellcheck.sentence.length.failed.front": "ความยาวข้อความเกินขีดจํากัด 1024",
  "ccmessaging.chat.agentconsole.spellcheck.sentence.no.context": "กล่องป้อนข้อมูลปัจจุบันไม่มีเนื้อหาเอกสาร ไม่จําเป็นต้องตรวจคําสะกด",
  "ccmessaging.chat.agentconsole.spellcheck.sentence.no.mistake": "ไม่มีข้อผิดพลาดทางการสะกด",
  "ccmessaging.chat.agentconsole.spellcheck.title": "ตรวจคําสะกด",
  "ccmessaging.chat.agentconsole.spellcheck.wrongly.writing": "อักษรผิด",
  "ccmessaging.chat.agentconsole.talkavg": "ระยะเวลาการโทรโดยเฉลี่ย(วินาที):",
  "ccmessaging.chat.agentconsole.talkNum": "จำนวนการโทรของวันนี้:",
  "ccmessaging.chat.agentconsole.thumbnail": "ภาพขนาดย่อ",
  "ccmessaging.chat.agentconsole.total.number.signatures": "โปรดเลือกสัญญาที่จะอัปโหลด",
  "ccmessaging.chat.agentconsole.upload": "อัปโหลดไฟล์",
  "ccmessaging.chat.agentconsole.uploadfail": "การอัพโหลดไฟล์ล้มเหลว",
  "ccmessaging.chat.agentconsole.userInputStatus": "ดูตัวอย่างสถานะการป้อนข้อมูลของผู้ใช้",
  "ccmessaging.chat.agentconsole.video": "วิดีโอ",
  "ccmessaging.chat.agentconsole.voice": "เสียง",
  "ccmessaging.chat.agentconsole.waittime": "ระยะเวลาการรอ",
  "ccmessaging.chat.async.asyncMsgNum": "จํานวนข้อความออฟไลน์",
  "ccmessaging.chat.async.asyncMsgTrending": "แนวโน้มข้อความออฟไลน์",
  "ccmessaging.chat.async.asyncUserNum": "จํานวนผู้ใช้ออฟไลน์",
  "ccmessaging.chat.async.asyncUserTrending": "แนวโน้มผู้ใช้ออฟไลน์",
  "ccmessaging.chat.async.channelSelect": "การกรองช่อง",
  "ccmessaging.chat.async.requestError": "คําขอล้มเหลว",
  "ccmessaging.chat.async.statistics": "การตรวจสอบข้อความแบบออฟไลน์",
  "ccmessaging.chat.async.statistics.asynChannelTotal": "จํานวนช่องทางออฟไลน์ทั้งหมด",
  "ccmessaging.chat.async.statistics.asynMsgTotal": "จํานวนข้อความออฟไลน์ทั้งหมด",
  "ccmessaging.chat.async.statistics.asynUserTotal": "จํานวนผู้ใช้ออฟไลน์ทั้งหมด",
  "ccmessaging.chat.async.statistics.updateTime": "เวลาอัปเดตข้อมูล",
  "ccmessaging.chat.call.end.state": "สถานะสิ้นสุดการโทร",
  "ccmessaging.chat.card": "บัตร",
  "ccmessaging.chat.edit.card": "แก้ไข บัตร",
  "ccmessaging.chat.multimedia.selectTemplate": "เลือกแม่แบบ",
  "ccmessaging.chat.card.template": "แม่แบบ",
  "ccmessaging.chat.channelconfig.accessToken": "การเข้าถึงโทเค็น",
  "ccmessaging.chat.channelconfig.accessTokenSecret": "การเข้าถึงคีย์โทเค็น",
  "ccmessaging.chat.channelconfig.accountKeyRepeat": "AccountKey ซ้ําในแชนแนล WhatsApp",
  "ccmessaging.chat.channelconfig.add": "ใหม่",
  "ccmessaging.chat.channelconfig.addressValidate": "ค่าต้องอยู่ในรูปแบบ sip:หมายเลข{'@'}botplatform.rcs.chinamobile.com และต้องมีอักขระน้อยกว่า 100 ตัว",
  "ccmessaging.chat.channelconfig.afterhalf": "สามารถคลิกเพื่อลบเบราว์เซอร์เพื่อสกัดกั้น)",
  "ccmessaging.chat.channelconfig.agentTimeout": "กําหนดช่วงเวลาหมดเวลาสําหรับไม่มีการตอบกลับของตัวแทนและพร้อมท์สําหรับการมอบหมายตัวแทนใหม่",
  "ccmessaging.chat.channelconfig.agentTimeout.emailTimeOutTips": "ปรับแต่งช่วงเวลาหมดเวลาสําหรับไม่มีการตอบกลับของตัวแทน",
  "ccmessaging.chat.channelconfig.agentWorkTime": "เวลาทำงานของเจ้าหน้าที่",
  "ccmessaging.chat.channelconfig.alphavalidateFailed": "มีอักขระที่ผิดกฎหมายซึ่งค่าป้อนจะประกอบด้วยตัวอักษรภาษาอังกฤษ ตัวเลข หรือขีดขีดขีดเส้นใต้เท่านั้น (สามารถมีอักขระภาษาอังกฤษหรือขีดเส้นใต้ได้เท่านั้น)",
  "ccmessaging.chat.channelconfig.apifabric.ak": "คีย์ APP",
  "ccmessaging.chat.channelconfig.apifabric.sk": "แอปลับ",
  "ccmessaging.chat.channelconfig.apiKey": "คีย์ API",
  "ccmessaging.chat.channelconfig.apiKeySecret": "ความลับของคีย์ API",
  "ccmessaging.chat.channelconfig.appId": "App Id",
  "ccmessaging.chat.channelconfig.appSecret": "คีย์ App",
  "ccmessaging.chat.channelconfig.assertionSigningKey": "Assertionคีย์ลายเซ็น",
  "ccmessaging.chat.channelconfig.assistantAccessCode": "รหัสการเข้าถึงผู้ช่วย",
  "ccmessaging.chat.channelconfig.assistantSet": "การกำหนดค่าผู้ช่วยหุ่นยนต์",
  "ccmessaging.chat.channelconfig.asyncFlag": "ข้อความแบบออฟไลน์",
  "ccmessaging.chat.channelconfig.asyncTip": "หากฟังก์ชันนี้ถูกปิดใช้งาน ข้อความออฟไลน์ที่มีอยู่จะหายไป",
  "ccmessaging.chat.channelconfig.asyncTime": "จัดสรรช่วงเวลาในวินาที",
  "ccmessaging.chat.channelconfig.auth.duplicateAuth": "การอนุญาตหมายเลขสาธารณะมีอยู่แล้วโดยไม่ต้องกําหนดค่าอีกครั้ง",
  "ccmessaging.chat.channelconfig.auth.relation.timeout": "การอนุญาตอาจล้มเหลว โปรดใช้ WeChat สแกนรหัสอีกครั้ง",
  "ccmessaging.chat.channelconfig.beforhalf": `คุณสามารถคลิกปุ่ม "ลองใช้" เพื่อดูเอฟเฟกต์การผสานรวม (หาก URL ถูกต้องและค่าสถานะการสกัดกั้นปรากฏที่มุมขวาบนของเบราว์เซอร์`,
  "ccmessaging.chat.channelconfig.bsp": "ผู้ให้บริการโซลูชันธุรกิจของ",
  "ccmessaging.chat.channelconfig.cert.empty": "ใบรับรองไม่สามารถเว้นว่างไว้ได้เมื่อที่อยู่พุชเป็น HTTPS",
  "ccmessaging.chat.channelconfig.Certification": "วิธีการตรวจสอบ",
  "ccmessaging.chat.channelconfig.changeLogo": "เปลี่ยน Avatar",
  "ccmessaging.chat.channelconfig.channelID": "ช่องทาง ID",
  "ccmessaging.chat.channelconfig.channelSecret": "คีย์ช่อง",
  "ccmessaging.chat.channelconfig.chatbotAddress": "ที่อยู่แชทบอท",
  "ccmessaging.chat.channelconfig.checkAgentTimeOutInterval": "เจ้าหน้าที่หมดเวลาไม่ตอบกลับ เวลาโอนไม่ควรเกินเวลาแจ้งเตือนที่ลูกค้าไม่ได้ตอบกลับ",
  "ccmessaging.chat.channelconfig.checkEncodingAESKey": "ความยาวคีย์ได้ 43 ตัวและมีได้เฉพาะ A-Z, a-z, 0-9 เท่านั้น",
  "ccmessaging.chat.channelconfig.checkmessage": `ไม่สามารถมีอักขระพิเศษ <>'"/\\`,
  "ccmessaging.chat.channelconfig.checkNickName": `ไม่สามารถมีอักขระพิเศษ +~!{'@'}#$%&*()/=+{'{'}{'}'};:'\\",<.>?`,
  "ccmessaging.chat.channelconfig.checkName": "ชื่อไม่สามารถเป็นระบบคำได้ ฯลฯ",
  "ccmessaging.chat.channelconfig.checkNumber": "Invalid Integer",
  "ccmessaging.chat.channelconfig.checkSocialChatNum": "ต้องไม่มีทศนิยม",
  "ccmessaging.chat.channelconfig.checkWhatsAppBaseUrl": "URL ไม่ถูกต้อง",
  "ccmessaging.chat.channelconfig.checkWhatsAppPhoneNum": "หมายเลขโทรศัพท์มือถือไม่ถูกต้อง",
  "ccmessaging.chat.channelconfig.clicktocall.empty": "ลำดับทักษะหรือรหัสการเข้าถึง IVR ต้องไม่ว่างเปล่า!",
  "ccmessaging.chat.channelconfig.clickToCallSkillList": "ลำดับทักษะ คลิกเพื่อโทร",
  "ccmessaging.chat.channelconfig.close": "ปิด",
  "ccmessaging.chat.channelconfig.codeName": "รหัสการเข้าถึงช่องสัญญาณ",
  "ccmessaging.chat.channelconfig.defaultReply": "การตอบกลับเริ่มต้น",
  "ccmessaging.chat.channelconfig.delete": "ลบ",
  "ccmessaging.chat.channelconfig.delete.exist.asyncMsg": "การลบแชนแนล [{n}] ล้มเหลว ขอแนะนําให้คุณตั้งค่าข้อความแบบออฟไลน์แล้วลองอีกครั้ง",
  "ccmessaging.chat.channelconfig.delete.exist.asyncUser": "การลบแชนแนล [{n}] ล้มเหลว ขอแนะนําให้ผู้ใช้ออฟไลน์กําหนดแชนแนลให้ลองอีกครั้ง",
  "ccmessaging.chat.channelconfig.delete.exist.session": "การลบช่อง [{n}] ล้มเหลว แนะนําให้ลองอีกครั้งหลังจากสิ้นสุดเซสชั่น",
  "ccmessaging.chat.channelconfig.edit": "การดำเนินการ",
  "ccmessaging.chat.channelconfig.email.bccLimit": "จํานวนสูงสุดของที่อยู่อีเมลการส่งลับ",
  "ccmessaging.chat.channelconfig.email.ccLimit": "จํานวนสูงสุดของที่อยู่อีเมลผู้รับสําเนาคาร์บอน",
  "ccmessaging.chat.channelconfig.email.number": "การกําหนดค่าจํานวนที่อยู่อีเมล",
  "ccmessaging.chat.channelconfig.email.personal": "ตอบด้วยอีเมลส่วนตัว",
  "ccmessaging.chat.channelconfig.email.placeholder": "1 - 100, ตัวเลือก",
  "ccmessaging.chat.channelconfig.email.range": "ใส่จํานวนเต็มบวกตั้งแต่ 1 ถึง 100",
  "ccmessaging.chat.channelconfig.email.address.limit": "หนึ่งใน",
  "ccmessaging.chat.channelconfig.email.receiverLimit": "จํานวนสูงสุดของที่อยู่อีเมลผู้รับ",
  "ccmessaging.chat.channelconfig.email.selectSignature": "โปรดเลือกลายเซ็นทางอีเมล",
  "ccmessaging.chat.channelconfig.email.signature": "ลายเซ็นอีเมล",
  "ccmessaging.chat.channelconfig.email.signature.desc": "คําอธิบายลายเซ็นอีเมล",
  "ccmessaging.chat.channelconfig.email.signature.name": "ชื่อลายเซ็นอีเมล",
  "ccmessaging.chat.channelconfig.email.totalEmailNumber": "จํานวนรวมของผู้รับ ผู้รับสําเนาคาร์บอน และที่อยู่อีเมลลับของผู้รับต้องไม่เกิน 100",
  "ccmessaging.chat.channelconfig.emailRepeate": "มีการใช้เกตเวย์การรับอีเมล ไปที่หน้าการกำหนดค่าการกำหนดเส้นทางข้อความเพื่อดูเกตเวย์การรับอีเมล",
  "ccmessaging.chat.channelconfig.endchat": "ว่าจะจบเซสชั่นไหม?",
  "ccmessaging.chat.channelconfig.endOfSession": "สิ้นสุดการโต้ตอบ",
  "ccmessaging.chat.channelconfig.envName": "ป้ายกำกับสภาพแวดล้อม Dev",
  "ccmessaging.chat.channelconfig.envNameValidate": "ค่าต้องเป็นสตริงที่น้อยกว่าหรือเท่ากับ 24 อักขระและมีได้เฉพาะตัวอักษรและตัวเลขเท่านั้น",
  "ccmessaging.chat.channelconfig.facebookBackground": "Facebook",
  "ccmessaging.chat.channelconfig.fiveGBackground": "CSP ข้อความ 5G",
  "ccmessaging.chat.channelconfig.gender": "เพศ",
  "ccmessaging.chat.channelconfig.guestTimeout": "ตั้งค่าการสิ้นสุดเซสชั่น หากลูกค้าไม่ได้ตอบกลับ และหากไม่ได้เปิดการใช้งาน ระยะหมดเวลาตั้งต้นคือ 20 นาที",
  "ccmessaging.chat.channelconfig.guestTimeout.timeOutTips": "ผู้เยี่ยมชมที่กําหนดเองไม่ได้ตอบกลับเวลาหมดเวลา หากไม่เปิดใช้งาน ค่าเริ่มต้นจะหมดเวลา {n} นาที",
  "ccmessaging.chat.channelconfig.hostingMethod": "วิธีการโฮสต์",
  "ccmessaging.chat.channelconfig.hour": "ชั่วโมง",
  "ccmessaging.chat.channelconfig.identityCertificate": "ใบรับรองประจําตัว",
  "ccmessaging.chat.channelconfig.importAvatarSize": "ขนาดของรูปประจำตัวมีขนาดเกิน 6 MB",
  "ccmessaging.chat.channelconfig.importAvatartype": "ประเภทภาพสัญลักษณ์ม่ถูกต้อง",
  "ccmessaging.chat.channelconfig.infobipPassword": "รหัสผ่าน",
  "ccmessaging.chat.channelconfig.infobipUserName": "ชื่อผู้ใช้",
  "ccmessaging.chat.channelconfig.infoConfiguration": "การตั้งค่าข้อมูล",
  "ccmessaging.chat.channelconfig.keyEscrow": "สำคัญ",
  "ccmessaging.chat.channelconfig.KeyValidate": "ตัวอักษรตัวเลข และอักษรพิเศษเท่านั้น_~!{'@'}#$%^&*- องค์ประกอบใด ๆ",
  "ccmessaging.chat.channelconfig.whatsappNameValidate": "ตัวอักษรตัวเลข และอักษรพิเศษเท่านั้น_~!{'@'}#$%^&*-. องค์ประกอบใด ๆ",
  "ccmessaging.chat.channelconfig.lastagent.time": "ช่วงเวลา",
  "ccmessaging.chat.channelconfig.lastModifyTime": "เวลาแก้ไขล่าสุด",
  "ccmessaging.chat.channelconfig.logo": "สัญลักษณ์",
  "ccmessaging.chat.channelconfig.messagePushUrl.not.configure.trustList": "ที่อยู่ไม่น่าเชื่อถือ โปรดติดต่อผู้ดูแลระบบ",
  "ccmessaging.chat.channelconfig.hrefUrl.not.configure.trustList": "ที่อยู่ไฮเปอร์ลิงก์ที่ไม่น่าเชื่อถือ โปรดติดต่อผู้ดูแลระบบ",
  "ccmessaging.chat.channelconfig.minute": "นาที",
  "ccmessaging.chat.channelconfig.name": "ชื่อ",
  "ccmessaging.chat.channelconfig.nochatonline": "ตอนนี้ไม่มีเซสชั่นต่อเนื่อง!",
  "ccmessaging.chat.channelconfig.noLimit": "ซึ่งไม่จำกัด",
  "ccmessaging.chat.channelconfig.NonworkingTimeReminder": "การแจ้งเตือนเวลาไม่ทำงาน",
  "ccmessaging.chat.channelconfig.nonWorkTimeDay": "นอกวันทำงาน",
  "ccmessaging.chat.channelconfig.noReplyIntervalTime": "ช่วงเวลา",
  "ccmessaging.chat.channelconfig.noReplyIntervalTimeValidate": "ช่วงเวลาสําหรับการตอบกลับของลูกค้าไม่สามารถมากกว่าช่วงเวลาสําหรับการแจ้งเตือนว่าไม่มีการตอบกลับของลูกค้า",
  "ccmessaging.chat.channelconfig.noReplyTime": "ไม่อนุญาตให้ตอบกลับช่วงเวลาที่สอง",
  "ccmessaging.chat.channelconfig.offBeginTime": "เวลาเริ่มต้น",
  "ccmessaging.chat.channelconfig.offLastAgentType": "ประเภทตัวแทนล่าสุด",
  "ccmessaging.chat.channelconfig.offTimeRange": "ช่วงเวลา",
  "ccmessaging.chat.channelconfig.offToday": "วันนี้",
  "ccmessaging.chat.channelconfig.onlyContainSubType": "แสดงเฉพาะคิวทักษะของประเภทแชนเนลเมื่อเอเจนต์โอนเซสชัน",
  "ccmessaging.chat.channelconfig.orgConfiguration": "การกําหนดค่าองค์กร",
  "ccmessaging.chat.channelconfig.pageId": "รหัสหน้า",
  "ccmessaging.chat.channelconfig.personalSetting": "การตั้งค่าส่วนบุคคล",
  "ccmessaging.chat.channelconfig.phoneNumber": "หมายเลขโทรศัพท์",
  "ccmessaging.chat.channelconfig.pleaseSelect": "โปรดเลือก...",
  "ccmessaging.chat.channelconfig.PreferredLastAgent": "โหมดตัวแทนล่าสุด",
  "ccmessaging.chat.channelconfig.Previous": "ก่อนหน้านี้",
  "ccmessaging.chat.channelconfig.privateKeyValidate": "รูปแบบ JSON ที่จะให้ค่าของปุ่มใน J สันจะได้รับเฉพาะตัวอักษรตัวเลข และตัวอักษรพิเศษ 11 บิต_~!{'@'}#$%^&*- องค์ประกอบใด ๆ",
  "ccmessaging.chat.channelconfig.publicConfiguration": "การกำหนดค่าทั่วไป",
  "ccmessaging.chat.channelconfig.pushMessageUrlValid": "ไม่สามารถบันทึกการกําหนดค่าช่องสัญญาณได้ ตรวจสอบว่าที่อยู่พุชข้อความหรือใบรับรองถูกต้องหรือไม่",
  "ccmessaging.chat.channelconfig.qryaddressconfigfailed": "ไม่สามารถค้นหากฎการยืนยัน chatbotAddress จากช่องทาง 5G ได้ ไม่ได้ตั้งค่ากฎการยืนยัน",
  "ccmessaging.chat.channelconfig.queryType": "โหมดการเลือก",
  "ccmessaging.chat.channelconfig.queueReminder": "การแจ้งเตือนลำดับ",
  "ccmessaging.chat.channelconfig.queueReminderContent": "แถวข้อมูลแจ้งเตือน",
  "ccmessaging.chat.channelconfig.queueReminderInterval": "ช่วงเวลาเตือนลำดับ",
  "ccmessaging.chat.channelconfig.reCreateScenario": "สร้าง",
  "ccmessaging.chat.channelconfig.refreshAccessToken": "ล้มเหลวในการรีเฟรช",
  "ccmessaging.chat.channelconfig.robotAccessCode": "รหัสการเข้าถึง Chatbot",
  "ccmessaging.chat.channelconfig.robotApplicationKey": "รหัสแอปพลิเคชัน Chatbot",
  "ccmessaging.chat.channelconfig.robotSet": "การกำหนดค่า Chatbot",
  "ccmessaging.chat.channelconfig.routeType": "ประเภทของเส้นทาง",
  "ccmessaging.chat.channelconfig.routeTypeSkill": "คิวทักษะ",
  "ccmessaging.chat.channelconfig.saveAndToNext": "บันทึกและดำเนินการในขั้นตอนต่อไป",
  "ccmessaging.chat.channelconfig.savechannelconfigfailed": "บันทึกการตั้งค่าช่องล้มเหลว!",
  "ccmessaging.chat.channelconfig.scanCodeHosting": "คิวอาร์โค้ด",
  "ccmessaging.chat.channelconfig.scenario.status.existent": "คีย์สถานการณ์:YES",
  "ccmessaging.chat.channelconfig.scenario.status.noExistent": "คีย์สถานการณ์:NO",
  "ccmessaging.chat.channelconfig.select.algorithm": "โปรดเลือกอัลกอริทึมการเข้ารหัส",
  "ccmessaging.chat.channelconfig.select.algorithm.configtips": "แจ้งเตือนให้ตรวจสอบว่าอัลกอริทึมการเข้ารหัสที่ปรับแต่งช่องทางตรงกับแพลตฟอร์ม",
  "ccmessaging.chat.channelconfig.select.algorithm.safetips": "อัลกอริทึมนี้มีความเข้มข้นไม่เพียงพอ หากแพลตฟอร์มสนับสนุนแนะนําให้เลือกอัลกอริทึมการเข้ารหัสที่มีความเข้มข้นมากขึ้น",
  "ccmessaging.chat.channelconfig.selectAccessChannel": "เลือกช่องทางการเข้าถึง",
  "ccmessaging.chat.channelconfig.selectConfigurationMethod": "เลือกวิธีการกำหนดค่า",
  "ccmessaging.chat.channelconfig.selectRobot": "เลือกหุ่นยนต์",
  "ccmessaging.chat.channelconfig.selectRobotAssistant": "เลือกผู้ช่วยหุ่นยนต์",
  "ccmessaging.chat.channelconfig.sessionTransferConfig": "การถ่ายโอนเซสชัน",
  "ccmessaging.chat.channelconfig.silentAgentDelete": "แน่ใจไหมว่าต้องการลบการกำหนดค่าเจ้าหน้าที่ปิดเสียงที่เลือก",
  "ccmessaging.chat.channelconfig.silentAgentSelect": "โปรดเลือกการกำหนดค่าเจ้าหน้าที่ที่ปิดเสียงที่จะลบ",
  "ccmessaging.chat.channelconfig.silentAgentSkillList": "คิวทักษะตัวแทนเงียบ",
  "ccmessaging.chat.channelconfig.skill": "รายการทักษะ (accesscode + extcode)",
  "ccmessaging.chat.channelconfig.skillList": "ลำดับทักษะ",
  "ccmessaging.chat.channelconfig.smsRepeate": "เกตเวย์ sms ถูกใช้งานแล้ว ไปที่หน้าการกําหนดค่าเส้นทางข้อความเพื่อดูเกตเวย์ sms",
  "ccmessaging.chat.channelconfig.startTime": "เวลาเริ่มต้น",
  "ccmessaging.chat.channelconfig.sub": "คัดลอกและวางรหัสต่อไปนี้ลงในป้ายกำกับเว็บไซต์ของคุณ บันทึกและเผยแพร่ คุณสามารถเข้าถึง Huawei ServiceCloud ได้อย่างรวดเร็ว หลังจากการเปิดตัวพอร์ทัล Huawei Service Cloud จะปรากฏที่มุมล่างขวาของเว็บไซต์ของคุณ คลิกเพื่อไปที่บริการลูกค้า การสนทนาตามเวลาจริง",
  "ccmessaging.chat.channelconfig.submit": "sumbit",
  "ccmessaging.chat.channelconfig.submitVerification": "ขั้นตอนการรวม",
  "ccmessaging.chat.channelconfig.successfulAccess": "การเข้าถึงสำเร็จ",
  "ccmessaging.chat.channelconfig.thirdPartySecretKey": "คีย์การตรวจสอบสิทธิ์ของบุคคลที่สาม",
  "ccmessaging.chat.channelconfig.time": "ช่วงเวลา %28นาที%29",
  "ccmessaging.chat.channelconfig.timeoutReply": "หมดเวลาตอบกลับ",
  "ccmessaging.chat.channelconfig.timerange": "ช่วงเวลา",
  "ccmessaging.chat.channelconfig.timeZone": "Time zone",
  "ccmessaging.chat.channelconfig.today": "วันปัจจุบัน",
  "ccmessaging.chat.channelconfig.triggerText": "ทูลเกล้าฯถวาย",
  "ccmessaging.chat.channelconfig.trustCertificate": "ใบรับรองที่เชื่อถือได้",
  "ccmessaging.chat.channelconfig.trybutton": "ลอง",
  "ccmessaging.chat.channelconfig.turnAI": "เชื่อมต่อบอทอัจฉริยะหรือไม่",
  "ccmessaging.chat.channelconfig.turnArtificial": "คีย์เวิร์ดส่งถึงเจ้าหน้าที่",
  "ccmessaging.chat.channelconfig.turnArtificial.tip": "คั่นด้วย ;",
  "ccmessaging.chat.channelconfig.turnAssistant": "เชื่อมต่อกับ Assistant",
  "ccmessaging.chat.channelconfig.turnManualPrompt": "แจ้งโอนไปยังเจ้าหน้าที่",
  "ccmessaging.chat.channelconfig.twitterBackground": "เซิร์ฟเวอร์ Twitter",
  "ccmessaging.chat.channelconfig.twittertips": "คุณสามารถเข้าถึงเว็บไซต์ต่อไปนี้ คลิกส่งเพื่อตรวจสอบความถูกต้องของ Twitter",
  "ccmessaging.chat.channelconfig.update": "แก้ไข",
  "ccmessaging.chat.channelconfig.updatecontactfailed": "อัปเดตข้อมูลผู้ติดต่อไม่สำเร็จ!",
  "ccmessaging.chat.channelconfig.voiceAccess": "การกำหนดค่าเสียง IVR",
  "ccmessaging.chat.channelconfig.voiceAgentSkill": "ลำดับทักษะเจ้าหน้าที่",
  "ccmessaging.chat.channelconfig.webhookConfirmPassword": "ยืนยันรหัสผ่าน Webhook",
  "ccmessaging.chat.channelconfig.webHookException": "ล้มเหลวในการรับเวลาที่ถูกต้องสําหรับรหัสผ่าน webHook",
  "ccmessaging.chat.channelconfig.webhookPassword": "รหัสผ่าน Webhook",
  "ccmessaging.chat.channelconfig.webhookPassword.newPassword": "รหัสผ่านใหม่ Webhook",
  "ccmessaging.chat.channelconfig.webhookPassword.noValidPassword": "ไม่มีรหัสผ่าน",
  "ccmessaging.chat.channelconfig.webhookPassword.oldPassword": "รหัสผ่านช่วงเวลาล็อค Webhook",
  "ccmessaging.chat.channelconfig.webhookPassword.validPeriod": "ใช้ได้จนถึง:",
  "ccmessaging.chat.channelconfig.webhookPassword.validPeriod.fifthDayTip": "ระยะเวลาใช้งานน้อยกว่า 15 วัน",
  "ccmessaging.chat.channelconfig.webhookPassword.validPeriod.oneDayTip": "ระยะเวลาใช้งานน้อยกว่าหนึ่งวัน กรุณาแก้ไข",
  "ccmessaging.chat.channelconfig.wechat.encryption.tip": "โปรดทราบว่าการปิดใช้งานการเข้ารหัสข้อความอาจก่อให้เกิดความเสี่ยงด้านความปลอดภัย ตรวจสอบให้แน่ใจว่าการตั้งค่านั้นเหมือนกับการตั้งค่าบนแพลตฟอร์มสาธารณะของ WeChat",
  "ccmessaging.chat.channelconfig.wechat.qr.label": "คลิกเพื่อเข้าถึงการอนุญาตการสแกนรหัส WeChat",
  "ccmessaging.chat.channelconfig.wechat.qr.label2": "การอนุญาตอีกครั้ง",
  "ccmessaging.chat.channelconfig.wechat.qr.label3": "การอนุญาตสําเร็จ",
  "ccmessaging.chat.channelconfig.weChatBackground": "เซิร์ฟเวอร์ WeChat",
  "ccmessaging.chat.channelconfig.weChatMessageEncryptionSwitch": "เปลี่ยนเข้ารหัสข้อความ WeChat",
  "ccmessaging.chat.channelconfig.welcomeMessage": "คำอวยพร",
  "ccmessaging.chat.channelconfig.whatsAppBackground": "เซิร์ฟเวอร์ WhatsApp",
  "ccmessaging.chat.channelconfig.workTimeDay": "วันทำงาน",
  "ccmessaging.chat.channelconfig.worktimeerror": "กรุณาเข้าสู่ช่วงเวลาปกติ!",
  "ccmessaging.chat.chatcard.audio": "เสียง",
  "ccmessaging.chat.chatcard.button.addtabs": "เพิ่มตัวเลือกการ์ด",
  "ccmessaging.chat.chatcard.cancel": "ยกเลิก",
  "ccmessaging.chat.chatcard.cardId": "ID เทมเพลต",
  "ccmessaging.chat.chatcard.cardName": "ชื่อ",
  "ccmessaging.chat.chatcard.cardNamePlaceholder": "ป้อนชื่อการ์ดเทมเพลต",
  "ccmessaging.chat.chatcard.cardTitle": "หัวข้อ",
  "ccmessaging.chat.chatcard.cardTitlePlaceholder": "ป้อนชื่อการ์ด",
  "ccmessaging.chat.chatcard.create": "บันทึก",
  "ccmessaging.chat.chatcard.delete": "ลบ",
  "ccmessaging.chat.chatcard.deleteFail": "ลบล้มเหลว",
  "ccmessaging.chat.chatcard.deleteLimit": "เทมเพลตถูกอ้างอิงและไม่สามารถลบได้",
  "ccmessaging.chat.chatcard.deleteSuccess": "ลบสำเร็จ",
  "ccmessaging.chat.chatcard.deleteTips": "การตรวจสอบพารามิเตอร์ล้มเหลว",
  "ccmessaging.chat.chatcard.deleteWhatsAppTemplate.Fail": "ไม่สามารถลบได้เนื่องจากมีการอ้างอิงแม่แบบด้วยข้อความแม่แบบของ WhatsApp แล้ว",
  "ccmessaging.chat.chatcard.description": "คำอธิบาย",
  "ccmessaging.chat.chatcard.descriptionPlaceholder": "ป้อนคำอธิบายการ์ด",
  "ccmessaging.chat.chatcard.details": "รายละเอียดบัตร",
  "ccmessaging.chat.chatcard.dial": "โทร",
  "ccmessaging.chat.chatcard.down": "ย้ายลง",
  "ccmessaging.chat.chatcard.edit": "แก้ไข",
  "ccmessaging.chat.chatcard.fail": "ล้มเหลว",
  "ccmessaging.chat.chatcard.failTip": "การเพิ่มล้มเหลวจำนวนตัวเลือกที่เพิ่มเข้ามาถึงจำนวนสูงสุด",
  "ccmessaging.chat.chatcard.folded": "folded",
  "ccmessaging.chat.chatcard.id": "ป้อนรหัส ID เทมเพลต",
  "ccmessaging.chat.chatcard.image": "ภาพ",
  "ccmessaging.chat.chatcard.input": "ป้อนชื่อเทมเพลต",
  "ccmessaging.chat.chatcard.invalidUrl": "ที่อยู่ลิงก์ไม่ได้ขึ้นต้นด้วย http://,https://",
  "ccmessaging.chat.chatcard.linking": "การเชื่อมโยง",
  "ccmessaging.chat.chatcard.mediaFileType": "ประเภทแฟ้มสื่อ",
  "ccmessaging.chat.chatcard.mediaHeight": "ความสูงของสื่อ",
  "ccmessaging.chat.chatcard.mediaType": "ประเภทไฟล์",
  "ccmessaging.chat.chatcard.mediumHeight": "ความสูงระดับปานกลาง",
  "ccmessaging.chat.chatcard.messageTemplateCreate": "การสร้างเทมเพลตการ์ด",
  "ccmessaging.chat.chatcard.messageTemplateDelete": "การลบเทมเพลต",
  "ccmessaging.chat.chatcard.messageTemplateModify": "การแก้ไขเทมเพลต",
  "ccmessaging.chat.chatcard.multiMediaId": "แหล่งที่มาสื่อ",
  "ccmessaging.chat.chatcard.multimediaid": "มัลติมีเดีย ID",
  "ccmessaging.chat.chatcard.multimedianame": "แฟ้มมัลติมีเดียName",
  "ccmessaging.chat.chatcard.name": "ใส่ชื่อ.",
  "ccmessaging.chat.chatcard.NoneTitle": "ไม่มีชื่อ",
  "ccmessaging.chat.chatcard.operate": "การดำเนินการ",
  "ccmessaging.chat.chatcard.queryTemplateFail": "ล้มเหลวในการค้นหาแม่แบบ",
  "ccmessaging.chat.chatcard.replay": "เล่นซ้ำ",
  "ccmessaging.chat.chatcard.saveFail": "บันทึกล้มเหลว",
  "ccmessaging.chat.chatcard.saveSuccess": "บันทึกความสำเร็จ",
  "ccmessaging.chat.chatcard.selectMultiMediaId": "เลือกทรัพยากรแอปที่เกี่ยวข้อง",
  "ccmessaging.chat.chatcard.selectMultiMediaTips": "โปรดเลือกแหล่งข้อมูลแอป",
  "ccmessaging.chat.chatcard.shortHeight": "ความสูงต่ำ",
  "ccmessaging.chat.chatcard.strcontentTip": "คำขอ HTTP มีความเสี่ยงด้านความปลอดภัย",
  "ccmessaging.chat.chatcard.success": "สำเร็จ",
  "ccmessaging.chat.chatcard.tabs": "ตัวเลือกการ์ด",
  "ccmessaging.chat.chatcard.tabs.displaytext": "ข้อความแสดงการ์ด",
  "ccmessaging.chat.chatcard.tabs.selectcontent": "เลือกเนื้อหา",
  "ccmessaging.chat.chatcard.tabs.selectType": "ประเภทตัวเลือก",
  "ccmessaging.chat.chatcard.tabsname": "ชื่อตัวเลือกการ์ด",
  "ccmessaging.chat.chatcard.tallHeight": "ความสูง",
  "ccmessaging.chat.chatcard.up": "ขยับขึ้น",
  "ccmessaging.chat.chatcard.video": "วิดีโอ",
  "ccmessaging.chat.chatcard.viewTips": "เทมเพลตการ์ดถูกลบและไม่สามารถดูตัวอย่างได้",
  "ccmessaging.chat.chatcard.whatsappTemplateCreate": "สร้างแม่แบบ WhatsApp",
  "ccmessaging.chat.chatcard.willdeletechatcard": "แน่ใจไหมว่าต้องการลบการ์ดนี้?",
  "ccmessaging.chat.chatcard.willdeletechatTemplate": "แน่ใจหรือไม่ว่าต้องการลบแม่แบบ WhatsApp",
  "ccmessaging.chat.chatcard.nameExist": "ชื่อนี้มีอยู่แล้ว โปรดป้อนรายการอื่น",
  "ccmessaging.chat.chatconfig.add": "ใหม่",
  "ccmessaging.chat.chatconfig.channelAccessCode": "รหัสการเข้าถึง",
  "ccmessaging.chat.chatconfig.channelOccupied": "ช่องถูกครอบครองแล้ว",
  "ccmessaging.chat.chatconfig.channelType": "ประเภทช่อง",
  "ccmessaging.chat.chatconfig.configurationId": "ID การกำหนดค่า",
  "ccmessaging.chat.chatconfig.delete": "ลบ",
  "ccmessaging.chat.chatconfig.deletechannelAccessCode": "ลบการกำหนดค่าช่อง",
  "ccmessaging.chat.chatconfig.deletefail": "การลบล้มเหลว",
  "ccmessaging.chat.chatconfig.deletesuccess": "ลบเรียบร้อยแล้ว",
  "ccmessaging.chat.chatconfig.idisnull": "ID ที่ระบุว่างเปล่า",
  "ccmessaging.chat.chatconfig.inquire": "ค้นหา",
  "ccmessaging.chat.chatconfig.modify": "ปรับเปลี่ยน",
  "ccmessaging.chat.chatconfig.operate": "การดำเนินการ",
  "ccmessaging.chat.chatconfig.refresh": "รีเฟรช",
  "ccmessaging.chat.chatconfig.reset": "รีเซ็ต",
  "ccmessaging.chat.chatconfig.rollBack": "ถอนกลับ",
  "ccmessaging.chat.chatconfig.selectchannelAccessCode": "เลือกการตั้งค่าช่อง",
  "ccmessaging.chat.chatconfig.skillId": "ผูกลำดับทักษะ",
  "ccmessaging.chat.chatconfig.willdeletechannelAccessCode": "แน่ใจไหมว่าต้องการลบการกำหนดค่าช่องที่เลือก",
  "ccmessaging.chat.chatemail.cancelhandled": "ยกเลิกการประมวลผล",
  "ccmessaging.chat.chatemail.delete": "ลบ",
  "ccmessaging.chat.chatemail.details": "ดู",
  "ccmessaging.chat.chatemail.forwarded": "ส่งต่อแล้ว",
  "ccmessaging.chat.chatemail.forwardedTime": "ส่งต่อแล้วที่",
  "ccmessaging.chat.chatemail.handled": "การจัดการ",
  "ccmessaging.chat.chatemail.maxNumberImages": "จํานวนภาพภายในเครื่องที่อัปโหลดถึงขีดจํากัดสูงสุด 20 ภาพ",
  "ccmessaging.chat.chatemail.saveDraft": "บันทึกแบบร่าง",
  "ccmessaging.chat.chatemail.sender": "ผู้ส่ง:",
  "ccmessaging.chat.chatholiday.calendar.preview": "ดูตัวอย่างปฏิทิน",
  "ccmessaging.chat.chatholiday.checkHolidaySize": "มีจำนวนรายการมากเกินไปโปรดลบรายการเพิ่มเติมก่อน",
  "ccmessaging.chat.chatholiday.date": "วันที่",
  "ccmessaging.chat.chatholiday.dateConfiguration": "การกำหนดค่าวันที่",
  "ccmessaging.chat.chatholiday.delete": "ลบ",
  "ccmessaging.chat.chatholiday.description": "คำอธิบาย",
  "ccmessaging.chat.chatholiday.designation.alert": "วันที่สิ้นสุดต้องอยู่หลังวันที่เริ่มต้น",
  "ccmessaging.chat.chatholiday.designation.cancel": "ยกเลิก",
  "ccmessaging.chat.chatholiday.designation.complete": "เสร็จสมบูรณ์",
  "ccmessaging.chat.chatholiday.designation.days": "หลายวัน",
  "ccmessaging.chat.chatholiday.designation.end": "วันที่สิ้นสุด",
  "ccmessaging.chat.chatholiday.designation.norepeating": "ไม่ซ้ำ",
  "ccmessaging.chat.chatholiday.designation.oneday": "1 วัน",
  "ccmessaging.chat.chatholiday.designation.repeating": "ซ้ำทุกปี",
  "ccmessaging.chat.chatholiday.designation.selectrule": "วันที่ที่เลือกต้องไม่ตรงกับวันที่เลือก",
  "ccmessaging.chat.chatholiday.designation.show": "ทุกปี",
  "ccmessaging.chat.chatholiday.designation.start": "วันที่เริ่มต้น",
  "ccmessaging.chat.chatholiday.designation.type": "ประเภทวันที่",
  "ccmessaging.chat.chatholiday.friday": "วันศุกร์",
  "ccmessaging.chat.chatholiday.holidays.explanation": "คุณสามารถระบุบางวันเป็นวันที่ไม่ใช่วันทำงานได้เช่น 13 กันยายน 2019 (วันศุกร์เทศกาลไหว้พระจันทร์ของจีน)",
  "ccmessaging.chat.chatholiday.isDelete.otherdays": "แน่ใจไหมว่าต้องการลบวันที่ยกเว้นนี้",
  "ccmessaging.chat.chatholiday.isDelete.specified": "แน่ใจไหมว่าต้องการลบวันที่ระบุนี้",
  "ccmessaging.chat.chatholiday.monday": "วันจันทร์",
  "ccmessaging.chat.chatholiday.new": "ใหม่",
  "ccmessaging.chat.chatholiday.nonWorkbench": "การกำหนดค่าที่ไม่ใช่วันทำงาน",
  "ccmessaging.chat.chatholiday.noworking": "นอกวันทำงาน",
  "ccmessaging.chat.chatholiday.operate": "ดำเนินการ",
  "ccmessaging.chat.chatholiday.other.prompt": "วันที่ที่เลือกจะตรงกับวันที่ที่มีอยู่",
  "ccmessaging.chat.chatholiday.otherdays.exception": "วันที่ยกเว้น",
  "ccmessaging.chat.chatholiday.otherdays.explanation": "คุณสามารถเลือกบางวันเป็นวันทำงานได้ ช่วงเวลาสูงสุดคือหนึ่งปี ตัวอย่างเช่นคุณสามารถเลือกวันที่ 13 กรกฎาคม 2019 (วันเสาร์ต้องทำงานล่วงเวลาชั่วคราว) เป็นวันทำงาน",
  "ccmessaging.chat.chatholiday.repeat": "กฎการทำซ้ำ",
  "ccmessaging.chat.chatholiday.saturday": "วันเสาร์",
  "ccmessaging.chat.chatholiday.savechatholidayfailed": "การบันทึกการกำหนดค่าวันหยุดล้มเหลว",
  "ccmessaging.chat.chatholiday.specified.date": "วันที่ระบุ",
  "ccmessaging.chat.chatholiday.sunday": "วันอาทิตย์",
  "ccmessaging.chat.chatholiday.thursday": "วันพฤหัสบดี",
  "ccmessaging.chat.chatholiday.tuesday": "วันอังคาร",
  "ccmessaging.chat.chatholiday.wednesday": "วันพุธ",
  "ccmessaging.chat.chatholiday.weekend.explanation": "สามารถตั้งค่าวันปิดทำการได้ เช่น วันเสาร์และวันอาทิตย์",
  "ccmessaging.chat.chatholiday.weekly": "รายสัปดาห์",
  "ccmessaging.chat.chatholiday.workbench": "การกำหนดค่า Workbench",
  "ccmessaging.chat.chatholiday.working.day": "วันทำงาน",
  "ccmessaging.chat.chatholiday.tip.choosedate": "โปรดเลือกวันที่",
  "ccmessaging.chat.chatholiday.tip.descinvalid": "คําอธิบายต้องมีอักขระมากกว่า 20 ตัว",
  "ccmessaging.chat.chatmanage.agentIntervalTime": "ช่วงเวลาหมดเวลาสําหรับไม่มีการตอบกลับของตัวแทน",
  "ccmessaging.chat.chatmanage.agentSessionEndText": "พร้อมสําหรับการมอบหมายตัวแทนใหม่",
  "ccmessaging.chat.chatmanage.autoEndSession": "ถ้าคุณไม่ตอบกลับภายในเวลาที่กำหนดกล่องข้อความจะถูกยกเลิกโดยอัตโนมัติ",
  "ccmessaging.chat.chatmanage.autoEndSessionMorethan": "คำอธิบายของช่วงเวลาที่ลูกค้าไม่มีการตอบกลับต้องไม่เกิน 100 อักขระ",
  "ccmessaging.chat.chatmanage.autoEndSessionnotnull": "ลูกค้าไม่มีเวลาตอบกลับ รายละเอียดช่วงเวลาต้องไม่ว่าง",
  "ccmessaging.chat.chatmanage.autoEndSessionNum": "ลูกค้าไม่ตอบกลับช่วงการแจ้งเตือนต้องเป็นตัวเลขธรรมดา",
  "ccmessaging.chat.chatmanage.autoEndSessionRange": "ลูกค้าไม่ตอบกลับ ช่วงตัวเลขต้องอยู่ระหว่าง 0 ถึง 60",
  "ccmessaging.chat.chatmanage.cancel": "ยกเลิก",
  "ccmessaging.chat.chatmanage.edit": "แก้ไข",
  "ccmessaging.chat.chatmanage.endTime": "เวลาสิ้นสุด",
  "ccmessaging.chat.chatmanage.endTimeNum": "เวลาสิ้นสุดต้องเป็นตัวเลขธรรมดา",
  "ccmessaging.chat.chatmanage.endTimeRange": "ช่วงตัวเลขของเวลาสิ้นสุดต้องอยู่ระหว่าง 0 ถึง 24",
  "ccmessaging.chat.chatmanage.fail": "ความล้มเหลว",
  "ccmessaging.chat.chatmanage.freeTimeText": "การแจ้งเตือนเวลาไม่ทำงาน",
  "ccmessaging.chat.chatmanage.freeTimeTextMorethan": "คำอธิบายของการแจ้งเตือนเวลาไม่ทำงานต้องมีอักขระไม่เกิน 100 ตัว",
  "ccmessaging.chat.chatmanage.freeTimeTextnotnull": "ไม่สามารถให้คําอธิบายแจ้งเตือนเวลาทํางานว่างได้",
  "ccmessaging.chat.chatmanage.intervalTime": "ช่วงเวลาพร้อมไม่มีการตอบกลับ",
  "ccmessaging.chat.chatmanage.save": "บันทึก",
  "ccmessaging.chat.chatmanage.savefailed": "การบันทึกล้มเหลว",
  "ccmessaging.chat.chatmanage.savesuccess": "บันทึกเรียบร้อยแล้ว.",
  "ccmessaging.chat.chatmanage.sessionEndText": "สรุป",
  "ccmessaging.chat.chatmanage.sessionEndTextMorethan": "คำอธิบายข้อสรุปของกล่องโต้ตอบต้องมีอักขระไม่เกิน 100 ตัว",
  "ccmessaging.chat.chatmanage.sessionEndTextnotnull": "รายละเอียดคําสิ้นสุดวาระการประชุมต้องไม่ว่าง",
  "ccmessaging.chat.chatmanage.specialCharacters": "มีอักขระพิเศษ โปรดแก้ไข",
  "ccmessaging.chat.chatmanage.startTime": "เวลาเริ่มต้น",
  "ccmessaging.chat.chatmanage.startTimeNum": "เวลาเริ่มต้นต้องเป็นตัวเลขธรรมดา",
  "ccmessaging.chat.chatmanage.startTimeRange": "ช่วงหมายเลขเวลาเริ่มต้นต้องอยู่ระหว่าง 0 ถึง 24",
  "ccmessaging.chat.chatmanage.success": "สำเร็จ",
  "ccmessaging.chat.chatmanage.useRobot": "เชื่อมต่อกับ Chatbot",
  "ccmessaging.chat.chatmanage.workTime": "เวลาทำงานของเจ้าหน้าที่ (ในรูปแบบ 24 ชั่วโมง)",
  "ccmessaging.chat.chatmanage.workTimeMorethan": "คำอธิบายเวลาทำงานต้องมีอักขระไม่เกิน 100 ตัว",
  "ccmessaging.chat.chatpersonalization.add": "เพิ่ม",
  "ccmessaging.chat.chatpersonalization.avatarerror": "เลือกภาพ.!",
  "ccmessaging.chat.chatpersonalization.cancel": "ยกเลิก",
  "ccmessaging.chat.chatpersonalization.changeAvatar": "เปลี่ยน Avatar",
  "ccmessaging.chat.chatpersonalization.concurrentAgentsNumber": "จํานวนไคลเอนต์มัลติมีเดียที่ให้บริการพร้อมกันโดยตัวแทน",
  "ccmessaging.chat.chatpersonalization.concurrentNumberPriority": "ลําดับความสําคัญของการกําหนดค่าจํานวนไคลเอนต์มัลติมีเดียพร้อมกันสําหรับบริการตัวแทนมีดังนี้: ระดับตัวแทน > ระดับองค์กร > ระดับผู้เช่า หากค่าเป็น 0 การกําหนดค่าจะไม่มีผล ระดับอื่น ๆ ถูกนํามาใช้",
  "ccmessaging.chat.chatpersonalization.delete": "ลบ",
  "ccmessaging.chat.chatpersonalization.edit": "แก้ไข",
  "ccmessaging.chat.chatpersonalization.hour": "ชั่วโมง",
  "ccmessaging.chat.chatpersonalization.MaximumConcurrent": "บริการลูกค้าพร้อมกันสูงสุด",
  "ccmessaging.chat.chatpersonalization.nickname": "ชื่อเล่น",
  "ccmessaging.chat.chatpersonalization.operating": "การดำเนินการ",
  "ccmessaging.chat.chatpersonalization.prompt": "พร้อมท์",
  "ccmessaging.chat.chatpersonalization.save": "บันทึก",
  "ccmessaging.chat.chatpersonalization.savefailed": "บันทึกล้มเหลว",
  "ccmessaging.chat.chatpersonalization.saveSuccess": "บันทึกเรียบร้อยแล้ว.",
  "ccmessaging.chat.chatpersonalization.specialStrValidateFailed": "ค่าต้องไม่มีอักขระพิเศษ",
  "ccmessaging.chat.chatpersonalization.tips1": "ไม่ได้ยืนยันการกำหนดค่าที่ส่งมา!",
  "ccmessaging.chat.chatpersonalization.welcome": "ทักทาย",
  "ccmessaging.chat.chatpersonalization.welcomeLimit": "สามารถกําหนดค่าการทักทายส่วนบุคคลได้สูงสุดสี่ครั้ง",
  "ccmessaging.chat.chatpersonalization.welcomeText": "คำทักทายส่วนบุคคล",
  "ccmessaging.chat.chatpersonalization.welcometime": "ช่วงเวลา",
  "ccmessaging.chat.chatpersonalization.welcometimeerror": "เข้าสู่ช่วงเวลาปกติ!",
  "ccmessaging.chat.chatpersonalization.welcometimeerror.nolist": "กรุณาตั้งค่าคำทักทายส่วนบุคคล!",
  "ccmessaging.chat.chatpersonalization.welcometimeerror.startGreaterThanEnd": "เวลาเริ่มต้นของการทักทายส่วนบุคคลต้องไม่มากกว่าหรือเท่ากับเวลาสิ้นสุด!",
  "ccmessaging.chat.chatpersonalization.welcometimeerror.timeOverlap": "ช่วงเวลาการทักทายส่วนบุคคลไม่สามารถทับซ้อนกันได้!",
  "ccmessaging.chat.chinese.separator": ":",
  "ccmessaging.chat.clickCallClient.denial": "ลิงก์หมดอายุแล้ว หากจําเป็นให้ติดต่อตัวแทนอีกครั้งเพื่อส่งลิงก์",
  "ccmessaging.chat.close": "เวลาสองวินาทีหลังจากปิดหน้าโดยอัตโนมัติ",
  "ccmessaging.chat.connnect.offlineuser.failed": "การโทรแบบออฟไลน์ล้มเหลว",
  "ccmessaging.chat.connnect.offlineuser.userId.beyondMaxLength": "UserId มากกว่า 128",
  "ccmessaging.chat.contact.callin": "โทรเข้า",
  "ccmessaging.chat.contact.callinandcallout": "กรีดร้องและกรีดร้อง",
  "ccmessaging.chat.contact.callout": "โทรออก",
  "ccmessaging.chat.email.AgentAccountName": "ชื่อบัญชีตัวแทน",
  "ccmessaging.chat.email.agentNumber": "หมายเลขตัวแทน",
  "ccmessaging.chat.email.autosave": "การแก้ไขของคุณถูกบันทึกโดยอัตโนมัติเพื่อร่าง",
  "ccmessaging.chat.email.button.advancedSearch": "การค้นหาขั้นสูง",
  "ccmessaging.chat.email.button.batchAssignment": "การกําหนดกลุ่ม",
  "ccmessaging.chat.email.button.bulkMove": "กลุ่มมือถือ",
  "ccmessaging.chat.email.card.reply": "ตอบ",
  "ccmessaging.chat.email.channel.attendeeError": "จำนวนผู้รับสำเนาเกินขีด จำกัด",
  "ccmessaging.chat.email.channel.BccSenderError": "จำนวนผู้รับ BCC เกินขีด จำกัด",
  "ccmessaging.chat.email.channel.ccandbcc.containchannelemail": "คัดลอกและส่งข้อมูลลับไม่สามารถเป็นที่อยู่อีเมลสาธารณะของช่องได้",
  "ccmessaging.chat.email.channel.confdeletedraft": "ยืนยันการลบหรือไม่",
  "ccmessaging.chat.email.channel.confdmodifydraft": "ยืนยันว่าได้รับการประมวลผลข้อความหรือไม่",
  "ccmessaging.chat.email.channel.deletedraft": "ลบยืนยัน",
  "ccmessaging.chat.email.channel.deletedraftfail": "การลบจดหมายฉบับร่างล้มเหลว",
  "ccmessaging.chat.email.channel.deletedraftsuccess": "การลบจดหมายฉบับร่างสําเร็จ",
  "ccmessaging.chat.email.channel.draftOverMax": "จํานวนจดหมายฉบับร่างเกินขีดจํากัดสูงสุด!",
  "ccmessaging.chat.email.channel.emailaddressinvalid": "ที่อยู่อีเมลไม่ถูกต้อง",
  "ccmessaging.chat.email.channel.modifydraft": "แก้ไขการยืนยัน",
  "ccmessaging.chat.email.channel.modifydraftfail": "การแก้ไขล้มเหลว",
  "ccmessaging.chat.email.channel.modifydraftsuccess": "แก้ไขความสําเร็จ",
  "ccmessaging.chat.email.channel.paramisempty": "พารามิเตอร์ว่างเปล่า",
  "ccmessaging.chat.email.channel.paramiserror": "ข้อผิดพลาดพารามิเตอร์",
  "ccmessaging.chat.email.channel.receiveGateway": "เกตเวย์การรับอีเมล",
  "ccmessaging.chat.email.channel.receivererror": "ผู้รับไม่มีผู้ใช้ที่เลือก",
  "ccmessaging.chat.email.channel.savedraftfail": "ล้มเหลวในการบันทึกจดหมายฉบับร่าง",
  "ccmessaging.chat.email.channel.savedraftsuccess": "บันทึกร่างสําเร็จ",
  "ccmessaging.chat.email.channel.sendGateway": "เกตเวย์การส่งอีเมล",
  "ccmessaging.chat.email.channel.title.reply": "ตอบกลับ:",
  "ccmessaging.chat.email.check.containGatewayEmail": "ไม่สามารถส่งอีเมลไปยังที่อยู่ของช่องรับอีเมล",
  "ccmessaging.chat.email.check.contentOverMax": "เนื้อหาข้อความเกินขนาดที่กําหนด",
  "ccmessaging.chat.email.check.localImageNumberOverMax": "จํานวนภาพในเครื่องที่อัปโหลดไปยังเนื้อหาอีเมลเกินช่วง",
  "ccmessaging.chat.email.check.localImageOverMax": "ขนาดของภาพท้องถิ่นที่อัปโหลดในเนื้อหาอีเมลเกินช่วง",
  "ccmessaging.chat.email.check.localImageType": "รูปแบบภาพที่จะอัปโหลดไม่ได้รับการสนับสนุนในขณะนี้",
  "ccmessaging.chat.email.check.uploadATTACHMENTPermissionAndFeature": "คุณไม่มีสิทธิ์ในการอัปโหลดสิ่งที่แนบมาหรือฟังก์ชันถูกปิดใช้งาน",
  "ccmessaging.chat.email.check.uploadImagePermissionAndFeature": "คุณไม่มีสิทธิ์ในการอัปโหลดภาพภายในเครื่องหรือฟังก์ชันถูกปิดใช้งาน",
  "ccmessaging.chat.email.forward": "ส่งต่อ",
  "ccmessaging.chat.email.forward.prefix": "ส่งต่อ:",
  "ccmessaging.chat.email.localImage.confirm": "ยืนยัน",
  "ccmessaging.chat.email.localImage.height": "สูง",
  "ccmessaging.chat.email.localImage.lable": "แฟ้ม",
  "ccmessaging.chat.email.localImage.width": "กว้าง",
  "ccmessaging.chat.email.param.empty": "ชื่ออีเมลหรือเนื้อหาต้องไม่ว่างเปล่า",
  "ccmessaging.chat.email.reply": "ตอบ",
  "ccmessaging.chat.email.reply.separator": ":",
  "ccmessaging.chat.email.tips.addFailDuplicateName": "เพิ่มล้มเหลว: ชื่อซ้ํา",
  "ccmessaging.chat.email.tips.addFailOtherErrors": "ล้มเหลวในการเพิ่ม: ข้อผิดพลาดอื่น ๆ",
  "ccmessaging.chat.email.tips.addMaximumLimit": "ไดเรกทอรีย่อยชั้นสูงสุด 20 ไดเรกทอรี",
  "ccmessaging.chat.email.tips.addSuccess": "เพิ่มความสําเร็จ",
  "ccmessaging.chat.email.tips.amendFailDuplicateName": "การแก้ไขล้มเหลว: ชื่อซ้ํา",
  "ccmessaging.chat.email.tips.amendFailOtherErrors": "การแก้ไขล้มเหลว: ข้อผิดพลาดอื่น ๆ",
  "ccmessaging.chat.email.tips.amendSuccess": "แก้ไขความสําเร็จ",
  "ccmessaging.chat.email.tips.deleteFailExistEmail": "การลบล้มเหลว: จดหมายที่มีอยู่ในไดเรกทอรีปัจจุบัน",
  "ccmessaging.chat.email.tips.deleteFailExistSub": "การลบล้มเหลว: ไดเรกทอรีปัจจุบันมีไดเรกทอรีย่อย",
  "ccmessaging.chat.email.tips.deleteFailOtherErrors": "การลบล้มเหลว: ข้อผิดพลาดอื่น ๆ",
  "ccmessaging.chat.email.tips.deleteSuccess": "ลบสําเร็จ",
  "ccmessaging.chat.email.tips.isDeleteDirectory": "ลบไดเรกทอรีปัจจุบันหรือไม่",
  "ccmessaging.chat.emailmessage.abandoned": "ถูกทอดทิ้ง",
  "ccmessaging.chat.emailmessage.agent": "ตัวแทน",
  "ccmessaging.chat.emailmessage.all": "ทั้งหมด",
  "ccmessaging.chat.emailmessage.assigned": "ที่ได้รับมอบหมาย",
  "ccmessaging.chat.emailmessage.assignEmail.inputAgentName": "ป้อนชื่อบัญชีตัวแทน",
  "ccmessaging.chat.emailmessage.assignEmail.queryAgentName": "กรอกบัญชีธุรกิจ",
  "ccmessaging.chat.emailmessage.assignEmailFailed": "ล้มเหลวในการมอบหมายจดหมาย",
  "ccmessaging.chat.emailmessage.assignEmailPartialSuccess": "ไม่สามารถมอบหมายอีเมลบางฉบับได้",
  "ccmessaging.chat.emailmessage.assignEmailSuccess": "อีเมลที่ได้รับมอบหมายเรียบร้อยแล้ว",
  "ccmessaging.chat.emailmessage.assigning": "การมอบหมายงาน",
  "ccmessaging.chat.emailmessage.assignOtherEmailFailed": "จดหมายของผู้ส่งกําลังถูกประมวลผลและไม่สามารถกําหนดได้",
  "ccmessaging.chat.emailmessage.assignStatus": "สถานะการมอบหมาย",
  "ccmessaging.chat.emailmessage.channel": "ช่องตอบกลับอัตโนมัติ",
  "ccmessaging.chat.emailmessage.channelAccessCode": "รหัสการเข้าถึงช่องทางอีเมล",
  "ccmessaging.chat.emailmessage.disAssigned": "ยังไม่ได้กําหนด",
  "ccmessaging.chat.emailmessage.fail.assign.AbandonedEmail.assigning.tip": "ไม่สามารถกําหนดข้อความที่ทิ้งได้",
  "ccmessaging.chat.emailmessage.fail.assign.AbandonedEmail.moving.tip": "ไม่สามารถย้ายข้อความที่ตกหล่นได้",
  "ccmessaging.chat.emailmessage.fail.assign.UnAssignedEmail.tip": "ไม่สามารถกําหนดอีเมลที่ไม่ได้มอบหมายได้",
  "ccmessaging.chat.emailmessage.handler": "จัดการโดย",
  "ccmessaging.chat.emailmessage.handlerType": "ประเภทจัดการ",
  "ccmessaging.chat.emailmessage.handleStatus": "สถานะการประมวลผล",
  "ccmessaging.chat.emailmessage.moving": "เคลื่อนไหว",
  "ccmessaging.chat.emailmessage.operation.noMoreThanOneDay": "ช่วงเวลาแบบสอบถามต้องไม่เกินหนึ่งวัน",
  "ccmessaging.chat.emailmessage.operation.noMoreThanSevenDay": "ช่วงเวลาการสืบค้นต้องไม่เกิน 7 วัน",
  "ccmessaging.chat.emailmessage.receiver": "ผู้รับ",
  "ccmessaging.chat.emailmessage.robot": "หุ่นยนต์",
  "ccmessaging.chat.emailmessage.sender": "จาก",
  'ccmessaging.chat.emailmessage.sendStatus': 'กําลังส่งผลลัพธ์',
  "ccmessaging.chat.emailmessage.sendTime": "เวลาส่ง",
  "ccmessaging.chat.emailmessage.skillQueue": "คิวทักษะ",
  "ccmessaging.chat.emailmessage.title": "Email Title",
  "ccmessaging.chat.emailmessage.unassigned": "ไม่ได้กําหนด",
  "ccmessaging.chat.EMOTICONS.beyondFileSize": "จจํานวนของประเภทไฟล์มัลติมีเดียที่อัปโหลดมีข้อผิดพลาดหรือเกินค่าสูงสุด : {n} KB !",
  "ccmessaging.chat.facebookpageconfig.add": "เพิ่มหน้าแรกของ Facebook",
  "ccmessaging.chat.facebookpageconfig.config": "การกำหนดค่า",
  "ccmessaging.chat.facebookpageconfig.deleteError": "การลบการตั้งค่าหน้าแรกของ Facebook ไม่สำเร็จ",
  "ccmessaging.chat.facebookpageconfig.deleteSuccess": "ลบการตั้งค่าหน้าแรกของ Facebook เรียบร้อยแล้ว",
  "ccmessaging.chat.facebookpageconfig.facebookconfig": "การตั้งค่าหน้าแรกของ Facebook",
  "ccmessaging.chat.facebookpageconfig.idNotExist": "ไม่มีข้อมูล",
  "ccmessaging.chat.facebookpageconfig.isDeleteFacebookPage": "แน่ใจไหมว่าต้องการลบการตั้งค่าหน้าแรกของ Facebook?",
  "ccmessaging.chat.facebookpageconfig.modify": "แก้ไขเพจ Facebook",
  "ccmessaging.chat.facebookpageconfig.modifyError": "แก้ไขหน้าแรกของ Facebook ไม่สำเร็จ",
  "ccmessaging.chat.facebookpageconfig.modifySuccess": "แก้ไขหน้าแรกของ Facebook เรียบร้อยแล้ว",
  "ccmessaging.chat.facebookpageconfig.numoversize": "จำนวนหน้าแรกของ Facebook เกินขีด จำกัด สูงสุดสำหรับช่องนี้",
  "ccmessaging.chat.facebookpageconfig.pageId": "ID หน้า",
  "ccmessaging.chat.facebookpageconfig.pageidexist": "มี ID หน้าแรกของ Facebook อยู่แล้ว",
  "ccmessaging.chat.facebookpageconfig.saveError": "ไม่สามารถเพิ่มหน้าแรกของ Facebook",
  "ccmessaging.chat.facebookpageconfig.saveSuccess": "เพิ่มหน้าแรกของ Facebook เรียบร้อยแล้ว",
  "ccmessaging.chat.facebookpageconfig.tokenSecret": "คีย์โทเค็น",
  "ccmessaging.chat.fivegCard": "บัตร 5G",
  "ccmessaging.chat.ifame.param": "พารามิเตอร์",
  "ccmessaging.chat.iframeagentconsole.mail": "การส่งไปรษณีย์",
  "ccmessaging.chat.iframeagentconsole.mailCollapse": "ไลซ์",
  "ccmessaging.chat.iframeagentconsole.mailExpand": "รายละเอียด",
  "ccmessaging.chat.label.accessCode": "รหัสเข้าใช้งาน",
  "ccmessaging.chat.label.extCode": "รหัสส่วนขยาย",
  "ccmessaging.chat.label.skill": "คิวทักษะ",
  "ccmessaging.chat.localFile.authFail": "ไม่อนุญาตให้ส่งไฟล์ในเครื่อง",
  "ccmessaging.chat.multi.card": "บัตรหมุนเวียน 5G",
  "ccmessaging.chat.multi.card.add": "เลือกการ์ด",
  "ccmessaging.chat.multi.card.id": "Card ID",
  "ccmessaging.chat.multi.card.num.error": "เพิ่มไพ่ใบเดียว 1 ถึง 5 ใบ",
  "ccmessaging.chat.multi.chatcard.alignment": "โหมดการจัดตำแหน่งภาพ",
  "ccmessaging.chat.multi.chatcard.alignment.left": "การจัดตำแหน่งด้านซ้าย",
  "ccmessaging.chat.multi.chatcard.alignment.right": "การจัดตำแหน่งด้านขวา",
  "ccmessaging.chat.multi.chatcard.choose": "การเลือกการ์ด",
  "ccmessaging.chat.multi.chatcard.delete": "แม่แบบนี้ถูกอ้างอิงและไม่สามารถลบได้ บัตร NVOD อ้างอิงคือ:",
  "ccmessaging.chat.multi.chatcard.sort": "ทิศทางการจัดเรียงการ์ด",
  "ccmessaging.chat.multi.chatcard.sort.flow": "ลอย",
  "ccmessaging.chat.multi.chatcard.sort.horizontal": "การปรับระดับ",
  "ccmessaging.chat.multi.chatcard.sort.vertical": "ตั้งฉาก",
  "ccmessaging.chat.multi.chatcard.width": "ความกว้างของการ์ด",
  "ccmessaging.chat.multi.chatcard.width.medium": "ความกว้างปานกลาง",
  "ccmessaging.chat.multi.chatcard.width.small": "ความกว้างที่เล็กกว่า",
  "ccmessaging.chat.multi.media.resource.invalid": "การ์ดอ้างอิงทรัพยากรสื่อที่ไม่ถูกต้อง ชื่อการ์ด:",
  "ccmessaging.chat.multimedia.addCategory": "เพิ่มหมวดหมู่",
  "ccmessaging.chat.multimedia.audioManagement": "คลังเสียง",
  "ccmessaging.chat.multimedia.audioUploadTipFormat": "ไฟล์ภาพที่อัปโหลดในปัจจุบันรองรับเฉพาะ j, png, รูปแบบ jpeg, m4a เท่านั้นสนับสนุน LINE และ LINE ใช้ m4a เท่านั้น และWhatsApp ใช้ aac เท่aac support Web,5G RCS,WhatsApp และขนาดไฟล์ไม่ควรเกิน {n}M",
  "ccmessaging.chat.multimedia.beyondFileSize": "อัปโหลดไฟล์มัลติมีเดียเกินขีดจํากัดสูงสุด : {n} MB !",
  "ccmessaging.chat.multimedia.cancel": "ยกเลิก",
  "ccmessaging.chat.multimedia.cardManagement": "เทมเพลตการ์ด",
  "ccmessaging.chat.multimedia.categoryName": "ชื่อหมวดหมู่",
  "ccmessaging.chat.multimedia.chatPhraseManagement": "คลังข้อความ",
  "ccmessaging.chat.multimedia.cobrowseSite": "ที่อยู่การทํางานร่วมกันของหน้าเว็บ",
  "ccmessaging.chat.multimedia.createRichText": "สร้างข้อความอุดมสมบูรณ์",
  "ccmessaging.chat.multimedia.delete": "ลบ",
  "ccmessaging.chat.multimedia.deleteLimit": "ทรัพยากรถูกอ้างอิงโดยแม่แบบการ์ด {0} และไม่สามารถลบได้",
  "ccmessaging.chat.multimedia.deleteLimit.usedbyODFS": "ทรัพยากรนี้ถูกอ้างอิงโดย IVR อัจฉริยะและไม่อนุญาตให้ลบ",
  "ccmessaging.chat.multimedia.deleteLimit.whatsapp.templatemsg": "ไม่สามารถลบทรัพยากรได้ เนื่องจากมีการอ้างอิงด้วยข้อความแม่แบบของ WhatsApp แล้ว ข้อความแม่แบบ:",
  "ccmessaging.chat.multimedia.deleteMultiMediaInfo": "ลบข้อมูลแอปนี้",
  "ccmessaging.chat.multimedia.deleteMultiMediaInfoError": "ลบข้อมูลแอปไม่สำเร็จ!",
  "ccmessaging.chat.multimedia.deleteMultiMediaInfoSuccess": "ลบข้อมูลแอปเรียบร้อยแล้ว!",
  "ccmessaging.chat.multimedia.deleteMultiMediaType": "ลบหมวดหมู่แอปเดียนี้",
  "ccmessaging.chat.multimedia.deleteMultiMediaTypeError": "ลบหมวดหมู่ไลบรารีแอปไม่สำเร็จ!",
  "ccmessaging.chat.multimedia.deleteMultiMediaTypeError.usedbyODFS": "การลบหมวดหมู่ไลบรารีมัลติมีเดียล้มเหลว! มีทรัพยากรอ้างอิงจาก IVR อัจฉริยะอยู่!",
  "ccmessaging.chat.multimedia.deleteMultiMediaTypeError.referenced.byCard": "ไม่สามารถลบหมวดหมู่มัลติมีเดียได้ เนื่องจากมีการอ้างอิงทรัพยากรบางรายการด้วยการ์ดแล้ว",
  "ccmessaging.chat.multimedia.deleteMultiMediaTypeError.referenced.byWhatsAppTemplateMsg": "ไม่สามารถลบหมวดหมู่มัลติมีเดียได้ เนื่องจากมีการอ้างอิงทรัพยากรบางรายการด้วยข้อความแม่แบบของ WhatsApp แล้ว",
  "ccmessaging.chat.multimedia.deleteMultiMediaTypeSuccess": "ลบหมวดหมู่แอปเรียบร้อยแล้ว!",
  "ccmessaging.chat.multimedia.description": "คำอธิบาย",
  "ccmessaging.chat.multimedia.descriptionContainsSpecialCharacters": "คําอธิบายมีอักขระพิเศษ",
  "ccmessaging.chat.multimedia.descriptionSuperLong": "คําอธิบายเกินความยาวสูงสุด",
  "ccmessaging.chat.multimedia.documentManagement": "คลังเอกสาร",
  "ccmessaging.chat.multimedia.documentUploadTipFormat": "อัพโหลดเอกสารสนับสนุน word,pdf,ppt,excel,zip รองรับ word,pdf,ppt,excel,ขนาดไฟล์ไม่เกิน {n}MB",
  "ccmessaging.chat.multimedia.download": "ดาวน์โหลด",
  "ccmessaging.chat.multimedia.edit": "แก้ไข",
  "ccmessaging.chat.multimedia.emoticonsManagement": "อีโมติคอน",
  "ccmessaging.chat.multimedia.emoticonsUploadTipFormat": "ไฟล์อิโมติคอนส่วนตัวที่อัปโหลดสามารถอยู่ในรูปแบบ JPG, JPEG, PNG หรือ GIF และขนาดไฟล์ต้องไม่เกิน {n} KB",
  "ccmessaging.chat.multimedia.exceedFileSize": "จจํานวนของประเภทไฟล์มัลติมีเดียที่อัปโหลดมีข้อผิดพลาดหรือเกินค่าสูงสุด : {n}!",
  "ccmessaging.chat.multimedia.fileName": "ชื่อแฟ้ม",
  "ccmessaging.chat.multimedia.fileSize": "ขนาดแฟ้ม",
  "ccmessaging.chat.multiMedia.fileUploadError": "ไฟล์ที่อัปโหลดมีขนาดหรือรูปแบบที่ไม่ถูกต้อง",
  "ccmessaging.chat.multiMedia.fileUploadInjectionRisk": "การอัปโหลดนี้มีความเสี่ยงต่อการฉีด โปรดตรวจสอบเนื้อหาแล้วลองอีกครั้ง",
  "ccmessaging.chat.multimedia.fileUploadLabel": "โปรดเลือกไฟล์ที่จะอัปโหลด",
  "ccmessaging.chat.multiMedia.fileUploadNameError": "รูปแบบการตั้งชื่อแฟ้มไม่ถูกต้อง",
  "ccmessaging.chat.multiMedia.fileUploadTypeError": "เกิดข้อผิดพลาดกับรูปแบบแฟ้มที่อัปโหลด",
  "ccmessaging.chat.multimedia.finish": "เสร็จสิ้น",
  "ccmessaging.chat.multimedia.getRichTextInfoError": "การรับข้อมูลแบบสมบูรณ์ล้มเหลว",
  "ccmessaging.chat.multimedia.id": "ID",
  "ccmessaging.chat.multimedia.imageManagement": "คลังรูปภาพ",
  "ccmessaging.chat.multimedia.imageUploadTipFormat": "ขณะนี้ไฟล์รูปภาพที่อัปโหลดรองรับเฉพาะรูปแบบ jpg, png, jpeg และขนาดไฟล์ไม่ควรเกิน {n}M",
  "ccmessaging.chat.multimedia.isDeleteMultiMediaInfo": "แน่ใจไหมว่าต้องการลบข้อมูลแอปนี้",
  "ccmessaging.chat.multimedia.isDeleteMultiMediaType": "แน่ใจไหมว่าต้องการลบหมวดหมู่แอปนี้",
  "ccmessaging.chat.multimedia.latitude": "ละติจูด",
  "ccmessaging.chat.multimedia.latitudeValidateFailed": "ส่วนจำนวนเต็มละติจูดคือ -90 ~ 90 และส่วนทศนิยมคือ 0 ถึง 6 หลัก!",
  "ccmessaging.chat.multimedia.locationManagement": "คลังที่อยู่",
  "ccmessaging.chat.multimedia.longitude": "ลองจิจูด",
  "ccmessaging.chat.multimedia.longitudeValidateFailed": "ส่วนจำนวนเต็มลองจิจูดคือ -180 ~ 180 และส่วนทศนิยมคือ 0 ถึง 6 หลัก!",
  "ccmessaging.chat.multimedia.mediaFileType": "ประเภทไฟล์มัลติมีเดีย",
  "ccmessaging.chat.multimedia.modifyCategory": "แก้ไขหมวดหมู่",
  "ccmessaging.chat.multimedia.modifyMultiMediaInfo": "แก้ไขข้อมูลแอปเรียบร้อยแล้ว!",
  "ccmessaging.chat.multimedia.modifyMultiMediaInfoError": "แก้ไขข้อมูลแอปไม่สำเร็จ!",
  "ccmessaging.chat.multimedia.modifyMultiMediaType": "แก้ไขหมวดแอปเรียบร้อยแล้ว!",
  "ccmessaging.chat.multimedia.modifyMultiMediaTypeError": "ไม่สามารถแก้ไขหมวดหมู่แอปไลบรารีได้!",
  "ccmessaging.chat.multimedia.modifyRichText": "แก้ไขข้อความรวย",
  "ccmessaging.chat.multimedia.multiMediaInfoInputEmpty": "ข้อมูลเข้าแอปว่างเปล่า!",
  "ccmessaging.chat.multimedia.multiMediaInfoInputInvalid": "ข้อมูลเข้าแอปไม่ถูกต้อง!",
  "ccmessaging.chat.multimedia.multiMediaManagement": "การตั้งค่าไลบรารีแอป",
  "ccmessaging.chat.multimedia.multiMediaNameExist": "ชื่อข้อมูลแอปมีอยู่แล้วในหมวดหมู่นี้!",
  "ccmessaging.chat.multimedia.multiMediaTypeIdNotExist": "ไม่มีหมวดหมู่ไลบรารีแอปที่เลือกโปรดเลือกหมวดหมู่ไลบรารีแอป!",
  "ccmessaging.chat.multimedia.multiMediaTypeInputEmpty": "ข้อมูลเข้าหมวดหมู่แอปว่างเปล่า!",
  "ccmessaging.chat.multimedia.multiMediaTypeInputInvalid": "ข้อมูลเข้าหมวดแอปไม่ถูกต้อง!",
  "ccmessaging.chat.multimedia.multiMediaTypeNameExist": "มีชื่อหมวดหมู่แอปแล้ว!",
  "ccmessaging.chat.multimedia.name": "ชื่อ",
  "ccmessaging.chat.multimedia.nameContainsSpecialCharacters": "มีอักขระพิเศษอยู่",
  "ccmessaging.chat.multimedia.nameSuperLong": "ชื่อเกินความยาวสูงสุด",
  "ccmessaging.chat.multimedia.operating": "ปฏิบัติการ",
  "ccmessaging.chat.multimedia.play": "เล่น",
  "ccmessaging.chat.multimedia.preview": "ตัวอย่าง",
  "ccmessaging.chat.multimedia.richTextContentEmpty": "เนื้อหาสมบูรณ์ว่างเปล่า",
  "ccmessaging.chat.multimedia.richTextContentLongLimit": "เนื้อหาข้อความที่ร่ํารวยไม่สามารถเกิน 5000 ตัวอักษร",
  "ccmessaging.chat.multimedia.richTextContentSuperLong": "เนื้อหาที่อุดมสมบูรณ์เกินความยาวสูงสุด 5000",
  "ccmessaging.chat.multimedia.richTextManagement": "ข้อความรวย",
  "ccmessaging.chat.multimedia.richTextTypeEmpty": "หมวดหมู่ข้อความรวยไม่มีอยู่",
  "ccmessaging.chat.multimedia.categorizeEmpty": "เลือกหรือสร้างหมวดหมู่ก่อน",
  "ccmessaging.chat.multimedia.saveMultiMediaInfo": "บันทึกข้อมูลแอปเรียบร้อยแล้ว!",
  "ccmessaging.chat.multimedia.saveMultiMediaInfoError": "บันทึกข้อมูลแอปไม่สำเร็จ!",
  "ccmessaging.chat.multimedia.saveMultiMediaType": "บันทึกหมวดแอปเรียบร้อยแล้ว!",
  "ccmessaging.chat.multimedia.saveMultiMediaTypeError": "บันทึกหมวดหมู่ไลบรารีแอปไม่สำเร็จ!",
  "ccmessaging.chat.multimedia.selectFile": "เลือกไฟล์",
  "ccmessaging.chat.multimedia.selectImage": "เลือกรูปภาพ",
  "ccmessaging.chat.multimedia.upload": "อัพโหลด",
  "ccmessaging.chat.multimedia.uploadTime": "เวลาอัปโหลด",
  "ccmessaging.chat.multimedia.videoManagement": "คลังวิดีโอ",
  "ccmessaging.chat.multimedia.videoUploadTipFormat": "ขณะนี้ไฟล์วิดีโอที่อัปโหลดรองรับเฉพาะรูปแบบ mp4 และขนาดไฟล์ไม่ควรเกิน {n}M",
  "ccmessaging.chat.multimedia.viewRichText": "ดูข้อความรวย",
  "ccmessaging.chat.multimedia.whatsAppTemplate": "แม่แบบ WhatsApp",
  "ccmessaging.chat.multimediaasccociation.approvalstatus": "ตรวจสอบสถานะ",
  "ccmessaging.chat.multimediaasccociation.checkmediainfofailed": "ข้อมูลแอปยังไม่ได้รับการเผยแพร่ไปยังช่องสัญญาณ 5G ที่เกี่ยวข้องหรือไม่ได้รับการอนุมัติ!",
  "ccmessaging.chat.multimediaasccociation.deleteassociation": "ลบผลการเผยแพร่",
  "ccmessaging.chat.multimediaasccociation.deleteassociationinfo": "คุณแน่ใจหรือไม่ว่าต้องการลบผลการเผยแพร่สำหรับข้อมูลแอปและช่องสัญญาณ 5G ที่เกี่ยวข้อง",
  "ccmessaging.chat.multimediaasccociation.deleteError": "ลบผลการเผยแพร่ไม่สำเร็จ!",
  "ccmessaging.chat.multimediaasccociation.deleteSuccess": "ลบผลการเปิดตัวเรียบร้อยแล้ว!",
  "ccmessaging.chat.multimediaasccociation.examinationfailed": "ล้มเหลว",
  "ccmessaging.chat.multimediaasccociation.examinationpassed": "ได้รับการอนุมัติ",
  "ccmessaging.chat.multimediaasccociation.expirationtime": "หมดอายุเมื่อ",
  "ccmessaging.chat.multimediaasccociation.isrelease": "แน่ใจไหมว่าต้องการปล่อยข้อมูลแอปไปยังช่อง 5G",
  "ccmessaging.chat.multimediaasccociation.refresh": "รีเฟรช",
  "ccmessaging.chat.multimediaasccociation.refreshfailed": "รีเฟรชข้อมูลไม่สำเร็จ!",
  "ccmessaging.chat.multimediaasccociation.refreshsuccess": "รีเฟรชข้อมูลสำเร็จแล้ว!",
  "ccmessaging.chat.multimediaasccociation.release": "ปล่อย",
  "ccmessaging.chat.multimediaasccociation.releaseerror": "เผยแพร่ข้อมูลแอปไม่สำเร็จ!",
  "ccmessaging.chat.multimediaasccociation.releaseresult": "ผลการเปิดตัว",
  "ccmessaging.chat.multimediaasccociation.releasesuccess": "เผยแพร่ข้อมูลแอปเรียบร้อยแล้ว!",
  "ccmessaging.chat.multimediaasccociation.releasetitle": "เผยแพร่ข้อมูลแอป",
  "ccmessaging.chat.multimediaasccociation.unpublishconfigempty": "ไม่ได้กำหนดค่าช่องสัญญาณ 5G ที่จะปล่อยข้อมูลแอป",
  "ccmessaging.chat.multimediaasccociation.unreviewed": "ไม่ได้รับการตรวจสอบ",
  "ccmessaging.chat.offlineCall.channelType": "ประเภทช่อง",
  "ccmessaging.chat.offlineCall.select.noChannel": "ไม่มีช่องทางนี้!",
  "ccmessaging.chat.offlineCall.userId": "ID ผู้ใช้",
  "ccmessaging.chat.offlineSwitch.closed": "การส่งล้มเหลว สวิตช์ข้อความออฟไลน์ปิด",
  "ccmessaging.chat.phrase.add": "เพิ่ม",
  "ccmessaging.chat.phrase.addPhraseError": "สร้างข้อความสามัญไม่สําเร็จ",
  "ccmessaging.chat.phrase.addPhraseSuccess": "สร้างวลีสามัญสําเร็จแล้ว",
  "ccmessaging.chat.phrase.addType": "เพิ่มประเภท",
  "ccmessaging.chat.phrase.AllCount": "จำนวนคำทั้งหมด",
  "ccmessaging.chat.phrase.cancel": "ยกเลิก",
  "ccmessaging.chat.phrase.currentCount": "จำนวนคำปัจจุบัน",
  "ccmessaging.chat.phrase.delete": "ลบ",
  "ccmessaging.chat.phrase.deleteType": "ลบประเภท",
  "ccmessaging.chat.phrase.delPhraseError": "ลบข้อความสามัญไม่สําเร็จ",
  "ccmessaging.chat.phrase.delPhraseSucess": "ลบข้อความสามัญสําเร็จแล้ว",
  "ccmessaging.chat.phrase.delPhraseTypeError": "ลบประเภทข้อความสามัญไม่สําเร็จ",
  "ccmessaging.chat.phrase.delPhraseTypeSuccess": "ลบประเภทข้อความสามัญสําเร็จแล้ว",
  "ccmessaging.chat.phrase.edit": "แก้ไข",
  "ccmessaging.chat.phrase.editType": "แก้ไขประเภท",
  "ccmessaging.chat.phrase.enter_keyword": "กรุณากรอกคีย์เวิร์ด",
  "ccmessaging.chat.phrase.enterPhrase": "โปรดป้อนข้อความสามัญ",
  "ccmessaging.chat.phrase.enterPhraseType": "โปรดป้อนประเภทข้อความสามัญ",
  "ccmessaging.chat.phrase.error": "ข้อผิดพลาด",
  "ccmessaging.chat.phrase.isDeletePhrase": "คุณแน่ใจหรือไม่ว่าต้องการลบวลีนี้",
  "ccmessaging.chat.phrase.isDeletePhraseType": "แน่ใจไหมว่าต้องการลบประเภทภาษานี้",
  "ccmessaging.chat.phrase.isSavePhrase": "แน่ใจไหมว่าต้องการบันทึกคำ",
  "ccmessaging.chat.phrase.isSavePhraseType": "แน่ใจไหมว่าต้องการบันทึกประเภทคำ",
  "ccmessaging.chat.phrase.phrase": "คำ",
  "ccmessaging.chat.phrase.phraseList": "รายการข้อความ",
  "ccmessaging.chat.phrase.phraseRepeat": "ชื่อสามัญซ้ำกัน",
  "ccmessaging.chat.phrase.phraseType": "ประเภทคำ",
  "ccmessaging.chat.phrase.phraseTypeEmpty": "ไม่มีประเภทข้อความสามัญที่จะลบอยู่ โปรดเลือกประเภทข้อความสามัญ",
  "ccmessaging.chat.phrase.phraseTypeRepeat": "ประเภทวลีสามัญซ้ํากัน",
  "ccmessaging.chat.phrase.phraseTypeLimit": "จํานวนประเภทวลีทั่วไปถึงขีด จํากัด บน 100",
  "ccmessaging.chat.phrase.prompt": "พร้อมท์",
  "ccmessaging.chat.phrase.save": "บันทึก",
  "ccmessaging.chat.phrase.saveError": "บันทึกล้มเหลว",
  "ccmessaging.chat.phrase.saveType": "บันทึกประเภท",
  "ccmessaging.chat.phrase.saveTypeSuccess": "บันทึกหมวดหมู่ภาษาเรียบร้อยแล้ว",
  "ccmessaging.chat.phrase.shortcut": "ปุ่มลัด",
  "ccmessaging.chat.phrase.success": "สำเร็จ",
  "ccmessaging.chat.phrase.updatePhraseTypeError": "ล้มเหลวในการปรับปรุงประเภทวลีทั่วไป",
  "ccmessaging.chat.phrase.updatePhraseTypeSucess": "ประสบความสําเร็จในการอัปเดตประเภทวลีทั่วไป",
  "ccmessaging.chat.phrase.updateError": "อัปเดตข้อผิดพลาด",
  "ccmessaging.chat.phrase.updatePhraseError": "อัปเดตภาษาล้มเหลว",
  "ccmessaging.chat.phrase.updatePhraseSucess": "อัปเดตภาษาเรียบร้อยแล้ว",
  "ccmessaging.chat.phrase.updateSuccess": "อัปเดตความสำเร็จ",
  "ccmessaging.chat.portraitchange.customize": "กำหนดเอง",
  "ccmessaging.chat.portraitchange.systemAvatar": "ระบบ avatar",
  "ccmessaging.chat.querymessage": "การสืบค้นประวัติการสืบค้นข้อความมัลติมีเดีย",
  "ccmessaging.chat.refresh.fail": "การรีเฟรชล้มเหลว",
  "ccmessaging.chat.repeatClick": "อย่าคลิกซ้ํา!",
  "ccmessaging.chat.richText.add": "เพิ่ม",
  "ccmessaging.chat.richText.cancel": "ยกเลิก",
  "ccmessaging.chat.richText.idInput": "โปรดป้อน id",
  "ccmessaging.chat.richText.nameInput": "โปรดป้อนชื่อ",
  "ccmessaging.chat.richText.openInNewWindow": "เปิดในหน้าต่างใหม่",
  "ccmessaging.chat.richText.save": "บันทึก",
  "ccmessaging.chat.selectchannel.vaildate1": "รหัสการเข้าถึงช่องต้องไม่ซ้ำกัน",
  "ccmessaging.chat.sessiontransfer.tips": "เฉพาะเจ้าหน้าที่ไม่ได้ใช้งานและเจ้าหน้าที่ที่มีการโทรพร้อมกันไม่ถึงขีดจำกัด สูงสุดเท่านั้นที่สามารถโอนได้",
  "ccmessaging.chat.silentagent.aceesscode.empty": "รหัสการเข้าถึงต้องไม่เป็นค่าว่าง!",
  "ccmessaging.chat.silentagent.config.accesscode.same": "การตั้งค่ารหัสการเข้าถึงเสียงของเจ้าหน้าที่มีอยู่แล้วในสัญญาเช่าปัจจุบัน",
  "ccmessaging.chat.silentagent.config.del.error": "ไม่สามารถลบการตั้งค่าเสียงเจ้าหน้าที่แบบไม่โต้ตอบได้",
  "ccmessaging.chat.silentagent.config.del.success": "การลบการตั้งค่าเสียงเจ้าหน้าที่สำเร็จ",
  "ccmessaging.chat.silentagent.config.willdel": "คุณแน่ใจหรือไม่ว่าต้องการลบการตั้งค่าเสียงเจ้าหน้าที่ที่เลือก?",
  "ccmessaging.chat.silentagent.DeleteFail": "ไม่สามารถลบการตั้งค่าพนักงานแบบไม่โต้ตอบได้",
  "ccmessaging.chat.silentagent.DeleteSuccess": "การลบการตั้งค่าเจ้าหน้าที่สำเร็จแล้ว",
  "ccmessaging.chat.silentagent.sendContent.noOIAPNode": "เจ้าหน้าที่ไม่สามารถรับข้อมูลของโหนดปัจจุบันได้!",
  "ccmessaging.chat.silentagent.sendContent.supervise": "เจ้าหน้าที่ไม่สามารถส่งข้อความการทำงานได้!",
  "ccmessaging.chat.silentagent.silent.skill.empty": "ลำดับทักษะของเจ้าหน้าที่ต้องไม่ว่าง",
  "ccmessaging.chat.silentAgent.workMode.changeWorkMode.fail": "โหมดการเปลี่ยนแปลงเจ้าหน้าที่ ล้มเหลว",
  "ccmessaging.chat.silentAgent.workMode.changeWorkMode.prompt": "เคล็ดลับ",
  "ccmessaging.chat.silentAgent.workMode.changeWorkMode.success": "ความสำเร็จของโหมดการเปลี่ยนแปลง silentAgent!",
  "ccmessaging.chat.silentAgent.workMode.common": "สาธารณะ",
  "ccmessaging.chat.silentAgent.workMode.insert": "แทรก",
  "ccmessaging.chat.silentAgent.workMode.supervise": "กำกับดูแล",
  "ccmessaging.chat.silentAgent.workMode.transfer": "โอน",
  "ccmessaging.chat.single.media.resource.invalid": "การ์ดอ้างอิงทรัพยากรสื่อที่ไม่ถูกต้อง",
  "ccmessaging.chat.sms.channel.gateway": "เกตเวย์ SMS",
  "ccmessaging.chat.social.media.enterprise.default.username": "ผู้ใช้ที่ไม่ระบุชื่อ",
  "ccmessaging.chat.social.media.enterprise.manage": "การประมวลผลข้อความของบัญชีองค์กร",
  "ccmessaging.chat.social.media.enterprise.post.add.failed": "การเพิ่มล้มเหลว",
  "ccmessaging.chat.social.media.enterprise.post.add.success": "เพิ่มเรียบร้อยแล้ว",
  "ccmessaging.chat.social.media.enterprise.post.all.reply": "ตอบกลับทั้งหมด",
  "ccmessaging.chat.social.media.enterprise.post.batch.reply": "ตอบกลับเป็นจํานวนมาก",
  "ccmessaging.chat.social.media.enterprise.post.cancel": "ยกเลิก",
  "ccmessaging.chat.social.media.enterprise.post.comment": "ความคิดเห็น",
  "ccmessaging.chat.social.media.enterprise.post.comment.tips": "เพิ่มความคิดเห็น...",
  "ccmessaging.chat.social.media.enterprise.post.reply": "ตอบกลับ",
  "ccmessaging.chat.social.media.enterprise.post.reply.cancel": "ยกเลิก",
  "ccmessaging.chat.social.media.enterprise.post.reply.tips": "เพิ่มการตอบกลับ...",
  "ccmessaging.chat.social.media.enterprise.post.title": "ชื่อโพสต์",
  "ccmessaging.chat.social.media.enterprise.post.topcomment.cancel": "ยกเลิกความคิดเห็น",
  "ccmessaging.chat.social.media.enterprise.tips.close": "พับ",
  "ccmessaging.chat.social.media.enterprise.tips.imgnum": "เพิ่มรูปภาพได้ถึง 1 ภาพ",
  "ccmessaging.chat.social.media.enterprise.tips.open": "ขยาย",
  "ccmessaging.chat.social.media.multimedia.manage": "การประมวลผลข้อความมัลติมีเดีย",
  "ccmessaging.chat.speciallist.channels": "ช่องที่ถูกจํากัด",
  "ccmessaging.chat.speciallist.channelsType": "ประเภทช่องที่ถูกจํากัด",
  "ccmessaging.chat.speciallist.choosechannel": "เลือกช่อง",
  "ccmessaging.chat.speciallist.confirmdelete": "แน่ใจว่าจะลบ?",
  "ccmessaging.chat.speciallist.delete": "ถูกทอดทิ้งแล้ว",
  "ccmessaging.chat.speciallist.deletelevel": "(ถูกทอดทิ้งแล้ว)",
  "ccmessaging.chat.speciallist.emailchannels": "อีเมล์",
  "ccmessaging.chat.speciallist.haverestrictall": "ช่องทั้งหมดของช่องประเภทนี้ถูกจํากัด ไม่จําเป็นต้องจํากัดช่องนี้",
  "ccmessaging.chat.speciallist.haverestrictchannel": "ช่องนี้ถูกจํากัด ไม่จําเป็นต้องจํากัดมันอีก",
  "ccmessaging.chat.speciallist.level": "ระดับรายการพิเศษ",
  "ccmessaging.chat.speciallist.leveName": "ชื่อประเภทรายการพิเศษ",
  "ccmessaging.chat.speciallist.msgchoosechannel": "โปรดเลือกประเภทของช่องก่อน",
  "ccmessaging.chat.speciallist.remark": "คําบรรยายภาพ",
  "ccmessaging.chat.speciallist.repeatlevel": "ระดับรายการพิเศษมีอยู่แล้ว อย่าสร้างมันอีก",
  "ccmessaging.chat.speciallist.restrictHours": "ระยะเวลาที่จํากัด",
  "ccmessaging.chat.speciallist.restrictUnit": "ชั่วโมง",
  "ccmessaging.chat.speciallist.restrictMode": "โหมด จํากัด",
  "ccmessaging.chat.speciallist.restrictStyle": "โหมดโปรแกรมประยุกต์",
  "ccmessaging.chat.speciallist.selectchannel": "ช่องทางที่กําหนด",
  "ccmessaging.chat.speciallist.smschannels": "ข้อความ",
  "ccmessaging.chat.speciallist.unuse": "ไม่มีให้บริการ",
  "ccmessaging.chat.speciallist.unuselevel": "(ไม่มีให้บริการ)",
  "ccmessaging.chat.speciallist.use": "ใช้ได้",
  "ccmessaging.chat.successaccess": "การเข้าถึงสำเร็จ",
  "ccmessaging.chat.sumbitverification.Appld": "IDแอป",
  "ccmessaging.chat.sumbitverification.AppSecret": "สำคัญ",
  "ccmessaging.chat.sumbitverification.facebook.tips": "ไปที่หน้าผู้พัฒนา Facebook Messenger เพื่อกำหนดค่าเว็บไซต์และรหัสยืนยัน",
  "ccmessaging.chat.sumbitverification.fiveG.tips": "โปรดไปที่หน้าการกำหนดค่าแพลตฟอร์มข้อความ 5G เพื่อกำหนดค่าที่อยู่เว็บไซต์",
  "ccmessaging.chat.sumbitverification.line.tips": "ตั้งค่า Webhook URL ใน Official Account Manager ของ LINE",
  "ccmessaging.chat.sumbitverification.tips": "โปรดไปที่ข้อมูลการกำหนดค่าอินเทอร์เฟซแพลตฟอร์มสาธารณะของ WeChat สำหรับ URL การกำหนดค่าและรหัสยืนยัน",
  "ccmessaging.chat.sumbitverification.tips.qr": "ไม่มีการกําหนดค่าเพิ่มเติมในโหมดการอนุญาตนี้โปรดดําเนินการต่อในขั้นตอนถัดไป",
  "ccmessaging.chat.sumbitverification.Url": "URL",
  "ccmessaging.chat.sumbitverification.Url.infobipMessAgeUrl": "URL สําหรับประมวลผลข้อความที่ส่งโดย WhatsApp",
  "ccmessaging.chat.sumbitverification.Url.infobipReportUrl": "รายงานการส่ง URL ของ WhatsApp",
  "ccmessaging.chat.sumbitverification.VerityCode": "รหัสยืนยัน",
  "ccmessaging.chat.sumbitverification.whatsApp.fail": "สร้างล้มเหลว",
  "ccmessaging.chat.sumbitverification.whatsApp.prompt": "คําใบ้",
  "ccmessaging.chat.sumbitverification.whatsApp.success": "สร้างความสําเร็จ",
  "ccmessaging.chat.sumbitverification.whatsApp.tips": "URL ที่ใช้โดยผู้ให้บริการข้อมูล WhatsApp เพื่อประมวลผลข้อความ",
  "ccmessaging.chat.system.error": "ระบบไม่ว่าง กรุณาลองใหม่อีกครั้งในภายหลัง.",
  "ccmessaging.chat.template.buttonSend": "ส่ง",
  "ccmessaging.chat.template.butttonName": "เลือก",
  "ccmessaging.chat.template.card": "ข้อความแม่แบบ WhatsApp",
  "ccmessaging.chat.template.Description": "คำอธิบาย",
  "ccmessaging.chat.template.languageCode": "ภาษา",
  "ccmessaging.chat.template.name": "ชื่อข้อความของแม่แบบ",
  "ccmessaging.chat.template.namePlaceholder": "กรอกชื่อข้อความ",
  "ccmessaging.chat.template.table.button": "ปุ่ม",
  "ccmessaging.chat.template.table.buttonPlaceholder": "การตอบกลับด่วนของปุ่ม",
  "ccmessaging.chat.template.table.no": "หมายเลข",
  "ccmessaging.chat.template.table.parameterContent": "เนื้อหาพารามิเตอร์",
  "ccmessaging.chat.template.table.parameterDescription": "คำอธิบายพารามิเตอร์",
  "ccmessaging.chat.template.table.parameterPlaceholder": "กรอกพารามิเตอร์",
  "ccmessaging.chat.template.whatsAppChannel": "ช่อง",
  "ccmessaging.chat.template.whatsAppTemplateName": "ชื่อแม่แบบ",
  "ccmessaging.chat.templateMsg.chooseMultimedia": "เลือกทรัพยากรมัลติมีเดีย",
  "ccmessaging.chat.templateMsg.sendTemplateMsg": "ส่ง",
  "ccmessaging.chat.templateMsg.sendTemplateMsgTips": "ส่งข้อความแม่แบบ",
  "ccmessaging.chat.tinymce.button.clear": "ล้าง",
  "ccmessaging.chat.tinyMCE.FangSong": "FangSong",
  "ccmessaging.chat.tinyMCE.SimHei": "SimHei",
  "ccmessaging.chat.tinyMCE.simsun": "ซิมซัน",
  "ccmessaging.chat.tinyMCE.yaHei": "Microsoft YaHei",
  "ccmessaging.chat.uploadLocalFile": "อัพโหลดไฟล์ภายในเครื่อง",
  "ccmessaging.chat.warning.sharescreen": "การป้องกันความเป็นส่วนตัวส่วนบุคคลเมื่อใช้งานร่วมกันกับพื้นที่ทํางาน",
  "ccmessaging.chat.web.agent.assist": "ผู้ช่วยตัวแทน",
  "ccmessaging.chat.web.agent.prohibitedmess": "ห้ามพูด! โปรดระวังคําพูดด้วย",
  "ccmessaging.chat.web.agent.wrogmess": "พูดไม่ถูก! โปรดระวังการใช้ศัลยกรรมที่เหมาะสม",
  "ccmessaging.chat.web.channel.called.config": "คลิกเพื่อปรับแต่งการโทร",
  "ccmessaging.chat.web.channel.clickivr": "คลิกเพื่อรับรหัสการเข้าถึง IVR",
  "ccmessaging.chat.webCard": "บัตร Web",
  "ccmessaging.chat.webCard.copyUrl": "คัดลอกลิงก์",
  "ccmessaging.chat.webCard.copyUrl.success": "คัดลอกลิงก์สําเร็จแล้ว",
  "ccmessaging.chat.webcard.pop.confirm.outside": "ว่าจะข้ามไปยังลิงก์ของบุคคลที่สามหรือไม่?",
  "ccmessaging.chat.webcard.pop.confirm.prefix": "ข้อความจะถูกข้ามไปยังที่อยู่นอกสถานี โดเมน",
  "ccmessaging.chat.webcard.pop.confirm.suffix": "โปรดตรวจสอบว่ากระโดดหรือไม่",
  "ccmessaging.chat.webcard.pop.error.prefix": "ข้ามโดเมนปัจจุบัน",
  "ccmessaging.chat.webcard.pop.error.suffix": "ไม่อยู่ในบัญชีขาวของที่อยู่ โปรดติดต่อผู้ปฏิบัติงานเพื่อเพิ่ม",
  "ccmessaging.chat.webclient.chatapi.evaluation": "กรุณาให้คะแนนบริการนี้ ขอบคุณคะ!",
  "ccmessaging.chat.webclient.chatapi.failTransToAgent": "การเปลี่ยนแปลงเจ้าหน้าที่ล้มเหลวโปรดลองอีกครั้งหรือติดต่อผู้ดูแลระบบ",
  "ccmessaging.chat.webclient.chathtml.customer": "ลูกค้า",
  "ccmessaging.chat.webclient.chatjs.inpuStatus": "อีกฝ่ายกำลังพิมพ์",
  "ccmessaging.chat.whatsapp.body": "พื้นที่เนื้อหา",
  "ccmessaging.chat.whatsapp.buttons": "พื้นที่ปุ่ม",
  "ccmessaging.chat.whatsapp.footer": "พื้นที่ส่วนท้าย",
  "ccmessaging.chat.whatsapp.header": "พื้นที่หัวเรื่อง",
  "ccmessaging.chat.whatsapp.languageAF": "ดัตช์ แอฟริกาใต้",
  "ccmessaging.chat.whatsapp.languageAR": "อาหรับ",
  "ccmessaging.chat.whatsapp.languageAZ": "อาเซอร์ไบจาน",
  "ccmessaging.chat.whatsapp.languageBG": "บัลแกเรีย",
  "ccmessaging.chat.whatsapp.languageBN": "เบงกอล",
  "ccmessaging.chat.whatsapp.languageCA": "คาตาลัน",
  "ccmessaging.chat.whatsapp.languageCS": "เช็ก",
  "ccmessaging.chat.whatsapp.languageDA": "เดนมาร์ก",
  "ccmessaging.chat.whatsapp.languageDE": "เยอรมัน",
  "ccmessaging.chat.whatsapp.languageEL": "กรีก",
  "ccmessaging.chat.whatsapp.languageEN": "อังกฤษ",
  "ccmessaging.chat.whatsapp.languageENGB": "อังกฤษ (สหราชอาณาจักร)",
  "ccmessaging.chat.whatsapp.languageES": "สเปน",
  "ccmessaging.chat.whatsapp.languageESAR": "สเปน (อาร์เจนตินา)",
  "ccmessaging.chat.whatsapp.languageESES": "สเปน (สเปน)",
  "ccmessaging.chat.whatsapp.languageESMX": "สเปน (เม็กซิโก)",
  "ccmessaging.chat.whatsapp.languageET": "เอสโตเนีย",
  "ccmessaging.chat.whatsapp.languageFA": "เปอร์เซีย",
  "ccmessaging.chat.whatsapp.languageFI": "ฟินแลนด์",
  "ccmessaging.chat.whatsapp.languageFIL": "ฟิลิปปินส์",
  "ccmessaging.chat.whatsapp.languageFR": "ฝรั่งเศส",
  "ccmessaging.chat.whatsapp.languageGA": "ไอร์แลนด์",
  "ccmessaging.chat.whatsapp.languageGU": "คุชราต",
  "ccmessaging.chat.whatsapp.languageHA": "เฮาซา",
  "ccmessaging.chat.whatsapp.languageHE": "ฮีบรู",
  "ccmessaging.chat.whatsapp.languageHI": "ฮินดู",
  "ccmessaging.chat.whatsapp.languageHR": "โครเอเชีย",
  "ccmessaging.chat.whatsapp.languageHU": "ฮังการี",
  "ccmessaging.chat.whatsapp.languageID": "อินโดนีเซีย",
  "ccmessaging.chat.whatsapp.languageIT": "อิตาลี",
  "ccmessaging.chat.whatsapp.languageJA": "ญี่ปุ่น",
  "ccmessaging.chat.whatsapp.languageKK": "คาซัค",
  "ccmessaging.chat.whatsapp.languageKN": "กันนาดา",
  "ccmessaging.chat.whatsapp.languageKO": "เกาหลี",
  "ccmessaging.chat.whatsapp.languageLO": "ลาว",
  "ccmessaging.chat.whatsapp.languageLT": "ลิทัวเนีย",
  "ccmessaging.chat.whatsapp.languageLV": "ลัตเวีย",
  "ccmessaging.chat.whatsapp.languageMK": "มาซิโดเนียเหนือ",
  "ccmessaging.chat.whatsapp.languageML": "มาลายาลัม",
  "ccmessaging.chat.whatsapp.languageMR": "มาราฐี",
  "ccmessaging.chat.whatsapp.languageMS": "มาเลย์",
  "ccmessaging.chat.whatsapp.languageNB": "นอร์เวย์",
  "ccmessaging.chat.whatsapp.languageNL": "ดัตช์",
  "ccmessaging.chat.whatsapp.languagePA": "ปัญจาบ",
  "ccmessaging.chat.whatsapp.languagePL": "โปแลนด์",
  "ccmessaging.chat.whatsapp.languagePTBR": "โปรตุเกส (บราซิล)",
  "ccmessaging.chat.whatsapp.languagePTPT": "โปรตุเกส (โปรตุเกส)",
  "ccmessaging.chat.whatsapp.languageRO": "ภาษาโรมาเนีย",
  "ccmessaging.chat.whatsapp.languageRU": "รัสเซีย",
  "ccmessaging.chat.whatsapp.languageSK": "สโลวาเกีย",
  "ccmessaging.chat.whatsapp.languageSL": "สโลวีเนีย",
  "ccmessaging.chat.whatsapp.languageSQ": "แอลเบเนีย",
  "ccmessaging.chat.whatsapp.languageSR": "เซอร์เบีย",
  "ccmessaging.chat.whatsapp.languageSV": "สวีเดน",
  "ccmessaging.chat.whatsapp.languageSW": "สวาฮีลี",
  "ccmessaging.chat.whatsapp.languageTA": "ทมิฬ",
  "ccmessaging.chat.whatsapp.languageTE": "เตลูกู",
  "ccmessaging.chat.whatsapp.languageTH": "ไทย",
  "ccmessaging.chat.whatsapp.languageTR": "ตุรกี",
  "ccmessaging.chat.whatsapp.languageUK": "ยูเครน",
  "ccmessaging.chat.whatsapp.languageUR": "อูรดู",
  "ccmessaging.chat.whatsapp.languageUS": "อังกฤษ (สหรัฐอเมริกา)",
  "ccmessaging.chat.whatsapp.languageUZ": "อุซเบกิสถาน",
  "ccmessaging.chat.whatsapp.languageVI": "เวียดนาม",
  "ccmessaging.chat.whatsapp.languageZHCN": "จีนประยุกต์",
  "ccmessaging.chat.whatsapp.languageZHHK": "จีนดั้งเดิม (ฮ่องกงของจีน)",
  "ccmessaging.chat.whatsapp.languageZHTW": "จีนดั้งเดิม (ไต้หวันของจีน)",
  "ccmessaging.chat.whatsapp.languageZU": "ซูลู",
  "ccmessaging.chat.whatsapp.only.send.template": "Whatsapp ผู้ใช้ใหม่หรือช่วงเวลาในการติดต่อนานกว่า 24 ชั่วโมงเท่านั้นที่สามารถส่งข้อความเทมเพลตได้",
  "ccmessaging.chat.whatsapp.selectTemplateOrgPlaceHolder": "เลือกองค์กร",
  "ccmessaging.chat.whatsapp.template.dailyLimit": "เทมเพลตที่มีอยู่ต่อวัน",
  "ccmessaging.chat.whatsapp.template.dailyLimitDescription": "โปรดป้อนจํานวนครั้งของต้นแบบที่ใช้ได้ต่อวัน",
  "ccmessaging.chat.whatsapp.template.no": "หมายเลข",
  "ccmessaging.chat.whatsapp.template.updateTime": "อัปเดตแล้ว",
  "ccmessaging.chat.whatsapp.templateAddButton": "สร้าง",
  "ccmessaging.chat.whatsapp.templateBodyParamDesc": "คำอธิบายพารามิเตอร์",
  "ccmessaging.chat.whatsapp.templateBodyParamDescPlaceholder": "กรอกคำอธิบายพารามิเตอร์",
  "ccmessaging.chat.whatsapp.templateBodyText": "เนื้อหาข้อความเนื้อความ",
  "ccmessaging.chat.whatsapp.templateBodyTextPlaceholder": "กรอกเนื้อหาเนื้อความ,เนื้อหาใน {'{{}}'} แก้ไขได้ในร่างกายแม่แบบ",
  "ccmessaging.chat.whatsapp.templateButton.clicktoAction": "การดำเนินการ",
  "ccmessaging.chat.whatsapp.templateButton.none": "ไม่มี",
  "ccmessaging.chat.whatsapp.templateButton.qickReply": "การตอบกลับด่วน",
  "ccmessaging.chat.whatsapp.templateButtonContent": "เนื้อหาปุ่มของแม่แบบ",
  "ccmessaging.chat.whatsapp.templateButtonContentPlaceholder": "กรอกเนื้อหาปุ่ม",
  "ccmessaging.chat.whatsapp.templateButtonName": "ชื่อปุ่มของแม่แบบ",
  "ccmessaging.chat.whatsapp.templateButtonNamePlaceholder": "กรอกชื่อปุ่ม",
  "ccmessaging.chat.whatsapp.templateButtonType": "ประเภทปุ่มของแม่แบบ",
  "ccmessaging.chat.whatsapp.templateChannel": "ช่องของแม่แบบ",
  "ccmessaging.chat.whatsapp.templateContent": "เนื้อหาแม่แบบ",
  "ccmessaging.chat.whatsapp.templateCreate": "สร้างแม่แบบ WhatsApp",
  "ccmessaging.chat.whatsapp.templateDescription": "คำอธิบายแม่แบบ",
  "ccmessaging.chat.whatsapp.templateDescriptionPlaceholder": "กรอกคำอธิบายแม่แบบ",
  "ccmessaging.chat.whatsapp.templateFooterText": "เนื้อหาข้อความส่วนท้าย",
  "ccmessaging.chat.whatsapp.templateFooterTextPlaceholder": "กรอกเนื้อหาข้อความส่วนท้าย",
  "ccmessaging.chat.whatsapp.templateHeaderText": "เนื้อหาข้อความส่วนหัว",
  "ccmessaging.chat.whatsapp.templateHeaderTextPlaceholder": "กรอกเนื้อหาข้อความส่วนหัว",
  "ccmessaging.chat.whatsapp.templateHeaderType": "ส่วนหัว",
  "ccmessaging.chat.whatsapp.templateHeaderType.image": "รูปภาพ",
  "ccmessaging.chat.whatsapp.templateHeaderType.locate": "สถานที่",
  "ccmessaging.chat.whatsapp.templateHeaderType.none": "ไม่มี",
  "ccmessaging.chat.whatsapp.templateHeaderType.text": "ข้อความ",
  "ccmessaging.chat.whatsapp.templateHeaderType.video": "วิดีโอ",
  "ccmessaging.chat.whatsapp.templateLanguage": "ภาษา",
  "ccmessaging.chat.whatsapp.templateName": "ชื่อแม่แบบ",
  "ccmessaging.chat.whatsapp.templateNamePlaceholder": "กรอกชื่อแม่แบบ",
  "ccmessaging.chat.whatsAppTemplateMsg.selectWhatsAppTemplateMsg": "เลือกเทมเพลต WhatsApp ที่เกี่ยวข้อง",
  "ccmessaging.chat.whatsAppTemplateMsg.selectWhatsAppTemplateMsgId": "ID ข้อความแม่แบบ WhatsApp",
  "ccmessaging.chat.whatsAppTemplateMsg.selectWhatsAppTemplateMsgName": "ชื่อข้อความแม่แบบ WhatsApp",
  "ccmessaging.chat.workbench.body.iolp": "คู่มือธุรกิจ",
  "ccmessaging.chat.workbench.body.transfer": "การเปิดรับข้อมูล",
  "ccmessaging.chat.workbench.transfer.agent": "ตัวแทน",
  "ccmessaging.chat.workbench.transfer.noagents": "ไม่มีตัวแทนออนไลน์ในคิวทักษะปัจจุบัน",
  "ccmessaging.chat.workbench.transfer.skill": "คิวทักษะ",
  "ccmessaging.chat.workbench.transfer.skilllogin": "จํานวนตัวแทนที่ลงชื่อเข้าใช้",
  "ccmessaging.chat.workbench.transfer.skillname": "ชื่อคิวทักษะ",
  "ccmessaging.chat.workbench.transfer.skillqueue": "จํานวนคนเข้าคิวในปัจจุบัน",
  "ccmessaging.chat.workbench.transfer.skillsearch": "โปรดป้อนชื่อคิวทักษะ",
  "ccmessaging.chatbot.button.avatarImport": "อัพโหลด",
  "ccmessaging.chatbot.button.avatarImportLimit": "ขนาดของไฟล์ที่จะอัปโหลดต้องไม่เกิน 6 MB และไฟล์สามารถอยู่ในรูปแบบ JPG หรือ PNG",
  "ccmessaging.chatbot.button.cancel": "ยกเลิก",
  "ccmessaging.chatbot.button.edit": "แก้ไข",
  "ccmessaging.chatbot.label.avatar": "สัญลักษณ์",
  "ccmessaging.chatbot.label.avatarImport": "อัปโหลด Avatar",
  "ccmessaging.chatbot.label.avatarImportFailed": "อัปโหลดภาพไม่สำเร็จ!",
  "ccmessaging.chatbot.label.avatarImportSuccess": "อัปโหลดภาพสำเร็จ!",
  "ccmessaging.chatbot.label.avatarModifing": "เปลี่ยนสัญลักษณ์",
  "ccmessaging.chatbot.label.basicInformation": "ข้อมูลพื้นฐาน",
  "ccmessaging.chatbot.label.commonLanguageSetting": "การกำหนดค่าคำ",
  "ccmessaging.chatbot.label.defaultReply": "การตอบกลับเริ่มต้น",
  "ccmessaging.chatbot.label.gender": "เพศ",
  "ccmessaging.chatbot.label.name": "ชื่อเล่น",
  "ccmessaging.chatbot.label.save": "บันทึก",
  "ccmessaging.chatbot.label.timeoutReply": "หมดเวลาตอบกลับ",
  "ccmessaging.chatbot.label.turnManualPrompt": "แจ้งโอนไปยังเจ้าหน้าที่",
  "ccmessaging.chatbot.label.welcomeMessage": "คำอวยพร",
  "ccmessaging.chatbot.message.error": "ล้มเหลว",
  "ccmessaging.chatbot.message.modifyFailure": "การแก้ไขล้มเหลว",
  "ccmessaging.chatbot.message.modifySuccess": "แก้ไขเรียบร้อยแล้ว",
  "ccmessaging.chatbot.message.specialStrValidateFailed": "เนื้อหาที่ป้อนต้องไม่มีอักขระต่อไปนี้: & <> /.",
  "ccmessaging.chatbot.message.success": "สำเร็จ",
  "ccmessaging.chatofflineCall.channelName": "ชื่อช่อง",
  "ccmessaging.chatofflineCall.invalidUserIdTip": "ระบุผู้ใช้รายนี้ผิดกฎหมาย",
  "ccmessaging.chatofflineCall.noSelectChanelTip": "ไม่มีแชนแนลที่เลือก",
  "ccmessaging.chatofflineCall.noSupportText": "เปิดสวิตช์สําหรับคนแปลกหน้า!",
  "ccmessaging.chatofflineCall.strangeOfflineTip": "ไม่ได้สืบค้นเซสชันประวัติศาสตร์",
  "ccmessaging.cobrowsesite.protocol.check": "การใช้โปรโตคอลที่ไม่ปลอดภัย มีความเสี่ยงด้านความปลอดภัย",
  "ccmessaging.cobrowsesite.mediaurl.format.error": "รูปแบบที่อยู่เว็บที่ใช้ร่วมกันไม่ถูกต้อง",
  "ccmessaging.email.attachment.fileType.tip1": "ไฟล์แนบอีเมลสนับสนุนรูปแบบไฟล์ต่อไปนี้:",
  "ccmessaging.email.attachment.fileType.tip2": ",",
  "ccmessaging.email.attachment.fileType.tip3": "ขนาดไฟล์ไม่เกิน",
  "ccmessaging.email.attachment.fileType.tip4": "เอ็ม.",
  "ccmessaging.emailcatalog.add": "เพิ่มไดเรกทอรี",
  "ccmessaging.emailcatalog.canNotAdd": "ไดเรกทอรีสูงสุดสองชั้น",
  "ccmessaging.emailcatalog.deleteTopCatalog": "ไดเรกทอรีเริ่มต้นไม่สามารถลบได้",
  "ccmessaging.emailcatalog.duplicateName": "ชื่อนี้มีอยู่แล้วโปรดใช้ชื่ออื่น",
  "ccmessaging.emailcatalog.existEmail": "จดหมายที่มีอยู่ในไดเรกทอรี",
  "ccmessaging.emailcatalog.existSubCatalog": "ไดเรกทอรีย่อยที่มีอยู่ในไดเรกทอรี",
  "ccmessaging.emailcatalog.modify": "แก้ไขไดเรกทอรี",
  "ccmessaging.emailcatalog.modifyTopCatalog": "ไดเรกทอรีเริ่มต้นไม่สามารถแก้ไขได้",
  "ccmessaging.emailcatalog.moveCatalogFailed": "การย้ายไดเรกทอรีล้มเหลว",
  "ccmessaging.emailcatalog.moveCatalogPartialSuccess": "การย้ายจดหมายบางส่วนล้มเหลว",
  "ccmessaging.emailcatalog.moveCatalogSuccess": "ย้ายไดเรกทอรีสําเร็จ",
  "ccmessaging.emailcatalog.moveEmailQuantityExceedsMaxValue": "จย้ายข้อความเกิน 30 หรืออาร์กิวเมนต์เกินความยาวสูงสุด",
  "ccmessaging.emailcatalog.moveOtherCatalogFailed": "ไม่สามารถย้ายข้อความจากตัวแทนอื่นได้",
  "ccmessaging.emailcatalog.catalogname": "ชื่อแค็ตตาล็อก",
  "ccmessaging.emailcatalog.parentName": "ชื่อไดเรกทอรีแม่",
  "ccmessaging.emailcatalog.pleaseSelectCatalog": "โปรดเลือกไดเรกทอรีก่อน",
  "ccmessaging.emailcatalog.selectcatalog": "เลือกไดเรกทอรี",
  "ccmessaging.emailMessageManage.forward": "บันทึกการส่งต่อ",
  "ccmessaging.flowControl.addFail": "การปรับแต่งการสตรีมล้มเหลว",
  "ccmessaging.flowControl.addSuccess": "การปรับตั้งค่าการสตรีมมิ่งใหม่สําเร็จ",
  "ccmessaging.flowControl.alarmInterval": "ช่วงเวลาการแจ้งเตือน",
  "ccmessaging.flowControl.alarmInterval.unit": "นาที",
  "ccmessaging.flowControl.alarmThreshold": "เกณฑ์การแจ้งเตือน",
  "ccmessaging.flowControl.channelType": "ประเภทช่อง",
  "ccmessaging.flowControl.count.beyondMaxLimit": "กฎการควบคุมระดับล่างของห้องเช่ามีมากกว่าจํานวนสูงสุด!",
  "ccmessaging.flowControl.createTime": "สร้างเวลา",
  "ccmessaging.flowControl.deleteFail": "การลบโพรไฟล์ล้มเหลว",
  "ccmessaging.flowControl.deleteSelect": "ตกลงที่จะลบกฎที่เลือกหรือไม่",
  "ccmessaging.flowControl.deleteSuccess": "การลบการตั้งค่าการสตรีมเรียบร้อยแล้ว",
  "ccmessaging.flowControl.description": "คําอธิบาย",
  "ccmessaging.flowControl.enable": "เปิดใช้งานหรือไม่",
  "ccmessaging.flowControl.limitNum": "ขีดจํากัดข้อความ",
  "ccmessaging.flowControl.name": "ชื่อของตัวควบคุม",
  "ccmessaging.flowControl.operate": "การดําเนินการ",
  "ccmessaging.flowControl.periodType": "ช่วงระยะเวลา",
  "ccmessaging.flowControl.periodType.day": "ทุกวัน",
  "ccmessaging.flowControl.periodType.month": "รายเดือน",
  "ccmessaging.flowControl.periodType.time": "ปรับแต่งเอง",
  "ccmessaging.flowControl.periodType.week": "ทุกสัปดาห์",
  "ccmessaging.flowControl.periodValue": "ช่วงเวลา",
  "ccmessaging.flowControl.period.select": "การเลือกรอบ",
  "ccmessaging.flowControl.selectStop": "โปรดปิดการปรับแต่งการสตรีมก่อน",
  "ccmessaging.flowControl.selectRule": "โปรดเลือกการกําหนดค่าการสตรีม",
  "ccmessaging.flowControl.selectStartTime": "โปรดเลือกเวลาเริ่มต้น",
  "ccmessaging.flowcontrol.target.deleted": "วัตถุการสตรีมได้ลบ โปรดลบกฎการสตรีมที่เกี่ยวข้อง",
  "ccmessaging.flowControl.targetId": "วัตถุควบคุมการไหล",
  "ccmessaging.flowControl.tipText": "ข้อความแจ้ง",
  "ccmessaging.flowControl.tipTextInterval": "รอบคิว",
  "ccmessaging.flowControl.updateFail": "การปรับตั้งค่าการสตรีมล้มเหลว",
  "ccmessaging.flowControl.updateSuccess": "การปรับตั้งค่าการสตรีมมิ่งเรียบร้อยแล้ว",
  "ccmessaging.flowControl.usage": "ปริมาณ",
  "ccmessaging.flowControl.title.create": "สร้างการควบคุมการสตรีมข้อความใหม่",
  "ccmessaging.flowControl.title.modify": "แก้ไขการควบคุมการสตรีมข้อความ",
  "ccmessaging.flowControl.title.view": "ดูการควบคุมการไหลของข้อความ",
  "ccmessaging.webCollaboration.ruleName": "ชื่อกฎ",
  "ccmessaging.webCollaboration.pageMatchType": "ประเภทการจับคู่หน้าเว็บ",
  "ccmessaging.webCollaboration.pageMatchConfiguration": "ปรับแต่งหน้าเว็บ",
  "ccmessaging.webCollaboration.pageElementSelector": "เครื่องมือเลือกองค์ประกอบหน้าเว็บ",
  "ccmessaging.webCollaboration.createTime": "สร้างเวลา",
  "ccmessaging.webCollaboration.operation": "ปฏิบัติการ",
  "cobrowse.sensitiveinfo.pagematchtype.commonmatch": "จับคู่ทั่วไป",
  "cobrowse.sensitiveinfo.pagematchtype.urlmatch": "เข้าคู่กับพาธ URL",
  "cobrowse.sensitiveinfo.pagematchtype.pageidmatch": "จับคู่ตาม ID ของหน้าเว็บ",
  "ccmessaging.message.location.latitude": "ละติจูด:",
  "ccmessaging.message.location.longitude": "ลองติจูด:",
  "ccmessaging.message.selectEmail.count": "สามารถเลือกอีเมลได้สูงสุด30อีเมลในแต่ละครั้ง",
  "ccmessaging.message.selectEmail.null": "โปรดเลือกอีเมล",
  "ccmessaging.message.sendstatus.deliverable": "ส่งแล้ว",
  "ccmessaging.message.sendstatus.pending": "กำลังรอ",
  "ccmessaging.message.sendstatus.read": "อ่าน",
  "ccmessaging.message.sendstatus.undeliverable": "ล้มเหลว",
  "ccmessaging.message.sendstatus.unread": "ยังไม่ได้อ่าน",
  "ccmessaging.message.timeerror.laterThanCurTime": "เวลาเริ่มต้นไม่สามารถช้ากว่าเวลาปัจจุบัน",
  "ccmessaging.messagecontroll.timeerror.beyondMaxLimit": "เวลาสิ้นสุดต้องไม่เกิน 90 วันก่อน!",
  "ccmessaging.multimedialibrary.cobrowsesite.card.target": "เป้าหมายการเรียกดู:",
  "ccmessaging.multimedialibrary.cobrowsesite.card.title": "เจ้าหน้าที่ร้องขอการเรียกดูร่วมกันกับผู้ใช้",
  "ccmessaging.multimedialibrary.tips": "ที่อยู่นี้ถูกใช้เพื่อส่งเจ้าหน้าที่ไปยังผู้ใช้เว็บเพื่อใช้งานร่วมกัน โปรดกําหนดค่าอย่างเหมาะสม",
  "ccmessaging.operlogs.emailAttachment.download": "ดาวน์โหลดเอกสารแนบอีเมล",
  "ccmessaging.operlogs.multimedialibrary.create.richtext": "เพิ่มข้อความรวย",
  "ccmessaging.operlogs.multimedialibrary.modify.richtext": "แก้ไขข้อความแบบสมบูรณ์",
  "ccmessaging.operlogs.multimedialibrary.save": "บันทึกการตั้งค่าข้อมูลไลบรารีแอป",
  "ccmessaging.operlogs.multimedialibrary.save.audio": "บันทึกการกำหนดค่าข้อมูลในไลบรารีแอปและอัปโหลดข้อมูลไฟล์ประเภทเสียง",
  "ccmessaging.operlogs.multimedialibrary.save.emoticons": "บันทึกการกำหนดค่าข้อมูลในไลบรารีแอปและอัปโหลดข้อมูลไฟล์ประเภทสัญรูปอารมณ์",
  "ccmessaging.operlogs.multimedialibrary.save.image": "บันทึกการกำหนดค่าข้อมูลในไลบรารีแอปและอัปโหลดข้อมูลไฟล์ประเภทรูปภาพ",
  "ccmessaging.operlogs.multimedialibrary.save.video": "บันทึกการกำหนดค่าข้อมูลในไลบรารีแอปและอัปโหลดข้อมูลไฟล์ประเภทวิดีโอ",
  "ccmessaging.operlogs.preview.contract": "สัญญาตัวอย่าง, ผู้ใช้:",
  "ccmessaging.operlogs.signature.submit": "ส่งสัญญาลายเซ็น",
  "ccmessaging.operlogs.upload.contract": "อัปโหลดสัญญา",
  "ccmessaging.pop.org.window.noorg": "โดยไม่มีองค์กร",
  "ccmessaging.pop.org.window.org": "กับองค์กร",
  "ccmessaging.ruleconfig.add": "เพิ่ม",
  "ccmessaging.ruleconfig.all": "ทุกช่อง",
  "ccmessaging.ruleconfig.applicationChannel": "ช่องแอปพลิเคชัน",
  "ccmessaging.ruleconfig.content": "เนื้อหา",
  "ccmessaging.ruleconfig.contentNotNull": "เนื้อหาไม่สามารถว่างเปล่า!",
  "ccmessaging.ruleconfig.createdTime": "เวลาสร้าง",
  "ccmessaging.ruleconfig.dateEnd": "เวลาสิ้นสุด",
  "ccmessaging.ruleconfig.dateStart": "เวลาเริ่มต้น",
  "ccmessaging.ruleconfig.deleteFail": "การลบกฎล้มเหลว!",
  "ccmessaging.ruleconfig.deleteSelectRule": "ตกลงที่จะลบกฎที่เลือกหรือไม่",
  "ccmessaging.ruleconfig.deleteSuccess": "กฎการลบสําเร็จ!",
  "ccmessaging.ruleconfig.duplicateName": "ชื่อกฎซ้ํากัน",
  "ccmessaging.ruleconfig.effective": "การบังคับใช้",
  "ccmessaging.ruleconfig.emailaddress.error": "ที่อยู่ต่อไปนี้ถูกห้ามไม่ให้ส่ง:",
  "ccmessaging.ruleconfig.emailAddressExists": "ที่อยู่อีเมลที่มีอยู่แล้ว",
  "ccmessaging.ruleconfig.emailAdress": "ที่อยู่อีเมล",
  "ccmessaging.ruleconfig.emailChannel": "ช่องจดหมาย",
  "ccmessaging.ruleconfig.ID": "หมายเลขซีเรียล",
  "ccmessaging.ruleconfig.invalidation": "ล้มเหลว",
  "ccmessaging.ruleconfig.invalidEmailAddress": "ที่อยู่อีเมลไม่ถูกต้อง",
  "ccmessaging.ruleconfig.invalidSMSNumber": "หมายเลขโทรศัพท์มือถือไม่ถูกต้อง",
  "ccmessaging.ruleconfig.maxEmailAddressCount": "คุณสามารถป้อนที่อยู่อีเมลได้สูงสุด 10 ที่อยู่",
  "ccmessaging.ruleconfig.maxSMSNumberCount": "สามารถใส่เบอร์มือถือในคอนเทนต์ได้สูงสุด 10 เบอร์",
  "ccmessaging.ruleconfig.maxValidityPeriod": "ระยะเวลาการใช้งานถูกตั้งค่าเป็นค่าสูงสุด",
  "ccmessaging.ruleconfig.ruleContentKeyword": "คีย์เวิร์ดเนื้อหาของกฎ",
  "ccmessaging.ruleconfig.ruleDescribe": "คําอธิบายกฎ",
  "ccmessaging.ruleconfig.ruleName": "ชื่อกฎ",
  "ccmessaging.ruleconfig.selectRule": "โปรดเลือกกฎ",
  "ccmessaging.ruleconfig.SMSChannel": "ช่อง SMS",
  "ccmessaging.ruleconfig.SMSNumber": "หมายเลขโทรศัพท์",
  "ccmessaging.ruleconfig.SMSNumberExists": "หมายเลขโทรศัพท์มือถือมีอยู่แล้ว",
  "ccmessaging.ruleconfig.SMStips1": `คุณสามารถป้อนอักขระตัวแทนในหมายเลขโทรศัพท์มือถือ."\\*"ระบุว่ามีอักขระ0ตัวหรือมากกว่าที่ตรงกัน"\\?"ระบุว่ามีการจับคู่อักขระ0ถึง1ตัว`,
  "ccmessaging.ruleconfig.SMStips2": `ตัวอย่างเช่น"\\*9988"ระบุว่าข้อความSMSทั้งหมดที่ส่งจากหมายเลขโทรศัพท์มือถือที่ลงท้ายด้วย"9988"จะถูกปฏิเสธ`,
  "ccmessaging.ruleconfig.SMStips3": `ตัวอย่างเช่น"132\\*"ระบุว่าข้อความSMSทั้งหมดที่ส่งจากหมายเลขโทรศัพท์มือถือที่ขึ้นต้นด้วย"132"จะถูกปฏิเสธ`,
  "ccmessaging.ruleconfig.SMStips4": `"\\*"จะบล็อกข้อความSMSทั้งหมดที่ส่งด้วยหมายเลขโทรศัพท์มือถือโปรดใช้ความระมัดระวังเมื่อใช้คําสั่งนี้`,
  "ccmessaging.ruleconfig.timeerror.dateValidateFailed": "โปรดเลือกวันที่มากกว่าปัจจุบันและน้อยกว่า 2038-1-19",
  "ccmessaging.ruleconfig.timeerror.empty": "* เวลาเริ่มต้นและเวลาสิ้นสุดไม่สามารถว่างเปล่าได้ทั้งหมด",
  "ccmessaging.ruleconfig.timeerror.endTime": "* เวลาสิ้นสุดไม่ควรเร็วกว่าเวลาปัจจุบัน",
  "ccmessaging.ruleconfig.timeerror.laterThanEndTime": "* เวลาเริ่มต้นไม่สามารถมากกว่าเวลาสิ้นสุด",
  "ccmessaging.ruleconfig.timeerror.laterThanEndTime2": "เวลาเริ่มต้นไม่สามารถช้ากว่าเวลาสิ้นสุด",
  "ccmessaging.ruleconfig.timeerror.startTime": "* เวลาเริ่มต้นไม่ควรเร็วกว่าเวลาปัจจุบัน",
  "ccmessaging.ruleconfig.tips1": `ที่อยู่อีเมลสามารถป้อนรหัสได้"\\*"แสดงถึงการเข้าคู่ระหว่าง 0 ถึงมากกว่าอักขระ"\\?"แสดงถึงอักขระที่เข้าคู่กัน 0 ถึง 1 ตัว`,
  "ccmessaging.ruleconfig.tips2": `ตัวอย่างเช่น"\\*{'@'}huawei.com"แสดงการปฏิเสธที่จะรับโดเมนทั้งหมดคือ"huawei.com"ข้อความ`,
  "ccmessaging.ruleconfig.tips3": `ยกเว้นกล่องใส่เนื้อหา"\\*" "\\?"ต้องปฏิบัติตามกฎของที่อยู่อีเมล`,
  "ccmessaging.ruleconfig.tips4": `"\\*{'@'}\\*.com"โดเมนทั้งหมดจะถูกบล็อก".com"ข้อความโปรดใช้อย่างระมัดระวัง`,
  "ccmessaging.ruleconfig.validityPeriod": "ระยะเวลาใช้งาน",
  "ccmessaging.ruleconfig.validityPeriodDay": "Access Token หมดอายุ (วัน)",
  "ccmessaging.ruleconfig.duplicateEmailAddress": "ที่อยู่อีเมลซ้ํา",
  "ccmessaging.ruleconfig.duplicateSmsAddress": "หมายเลขโทรศัพท์ซ้ํา",
  "ccmessaging.socialPostOperation.button.approve": "อนุมัติ",
  "ccmessaging.socialPostOperation.button.batchDelete": "ลบเป็นจํานวนมาก",
  "ccmessaging.socialPostOperation.button.cancel": "ถอน",
  "ccmessaging.socialPostOperation.button.channelConfig": "การตั้งค่าช่อง",
  "ccmessaging.socialPostOperation.button.creatPost": "โพสต์",
  "ccmessaging.socialPostOperation.button.edit": "แก้ไข",
  "ccmessaging.socialPostOperation.button.delete": "ลบ",
  "ccmessaging.socialPostOperation.button.saveDraft": "บันทึกเป็นแบบร่าง",
  "ccmessaging.socialPostOperation.button.upload": "อัปโหลด",
  "ccmessaging.socialPostOperation.ceratePageTitle": "การดําเนินการบัญชี/โพสต์",
  "ccmessaging.socialPostOperation.edit.approveInformation": "ข้อมูลการอนุมัติ",
  "ccmessaging.socialPostOperation.edit.basicInformation": "ข้อมูลพื้นฐาน",
  "ccmessaging.socialPostOperation.edit.must": "ต้องมี",
  "ccmessaging.chat.channelconfig.checkConfirmTokenEqualToToken": "ต้องใส่ค่าที่เหมือนกัน",
  "ccmessaging.socialPostOperation.edit.placeholder": "โปรดป้อน",
  "ccmessaging.socialPostOperation.edit.publishInformation": "เผยแพร่ข้อมูล",
  "ccmessaging.socialPostOperation.facebook.publishAt.tips": "การเผยแพร่ตามเวลาต้องเผยแพร่ระหว่าง 20 นาทีถึง 75 วันหลังจากการสร้าง",
  "ccmessaging.socialPostOperation.facebook.tips.emptyPost": "Facebook ไม่สามารถโพสต์สติกเกอร์ว่างได้ โปรดเลือกอย่างน้อยหนึ่งรายการ (เนื้อหา, รูปภาพ, วิดีโอ)",
  "ccmessaging.socialPostOperation.field.applicationType": "ประเภทการสมัคร",
  "ccmessaging.socialPostOperation.field.applicationType.create": "โพสต์",
  "ccmessaging.socialPostOperation.field.approveComment": "อนุมัติความคิดเห็น",
  "ccmessaging.socialPostOperation.field.approverName": "ผู้อนุมัติ",
  "ccmessaging.socialPostOperation.field.approveStatus": "ผลการอนุมัติ",
  "ccmessaging.socialPostOperation.field.approveStatus.pass": "ผ่าน",
  "ccmessaging.socialPostOperation.field.approveStatus.rejected": "ปฏิเสธ",
  "ccmessaging.socialPostOperation.field.channelName": "ชื่อช่อง",
  "ccmessaging.socialPostOperation.field.content": "เนื้อหา",
  "ccmessaging.socialPostOperation.field.createUserName": "ผู้สมัคร",
  "ccmessaging.socialPostOperation.field.image": "รูปภาพ",
  "ccmessaging.socialPostOperation.field.operation": "การดําเนินการ",
  "ccmessaging.socialPostOperation.field.postConfigInfo": "ข้อมูลช่อง",
  "ccmessaging.socialPostOperation.field.postType": "ช่องเผยแพร่",
  "ccmessaging.socialPostOperation.field.publishAt": "เวลาเผยแพร่",
  "ccmessaging.socialPostOperation.field.publishChannel": "เผยแพร่ช่อง",
  "ccmessaging.socialPostOperation.field.publishStatus": "สถานะเผยแพร่",
  "ccmessaging.socialPostOperation.field.publishStatus.published": "เผยแพร่แล้ว",
  "ccmessaging.socialPostOperation.field.publishStatus.unpublished": "ยังไม่ได้เผยแพร่",
  "ccmessaging.socialPostOperation.field.publishTime": "เวลาที่คาดว่าจะเผยแพร่",
  "ccmessaging.socialPostOperation.field.publishTimeSet": "การตั้งค่าเวลาเผยแพร่",
  "ccmessaging.socialPostOperation.field.publishTyle.now": "เผยแพร่ตามเวลาจริง",
  "ccmessaging.socialPostOperation.field.publishTyle.time": "ปล่อยตามเวลา",
  "ccmessaging.socialPostOperation.field.publishType": "ประเภทการเผยแพร่",
  "ccmessaging.socialPostOperation.field.remark": "หมายเหตุ",
  "ccmessaging.socialPostOperation.field.status": "สถานะ",
  "ccmessaging.socialPostOperation.field.status.approved": "เสร็จสิ้น",
  "ccmessaging.socialPostOperation.field.status.draft": "ร่าง",
  "ccmessaging.socialPostOperation.field.status.toApproved": "รอการอนุมัติ",
  "ccmessaging.socialPostOperation.field.title": "ธีม",
  "ccmessaging.socialPostOperation.field.video": "วิดีโอ",
  "ccmessaging.socialPostOperation.res.recall": "ถอนความสําเร็จ",
  "ccmessaging.socialPostOperation.search.searchTitle": "ชุดตกแต่ง",
  "ccmessaging.socialPostOperation.select.pleaseChoose": "โปรดเลือก",
  "ccmessaging.socialPostOperation.tab.history": "ประวัติศาสตร์การอนุมัติ",
  "ccmessaging.socialPostOperation.tips.approveSet": "โปรดตรวจสอบรายการการกําหนดค่าผู้อนุมัติ",
  "ccmessaging.socialPostOperation.tips.approveSuccess": "อนุมัติเรียบร้อยแล้ว",
  "ccmessaging.socialPostOperation.tips.approvingDelete": "ข้อมูลสถานะที่รอการตรวจสอบไม่สามารถลบได้",
  "ccmessaging.socialPostOperation.tips.cancelError": "ไม่สามารถเรียกคืนข้อมูลสถานะไม่ได้",
  "ccmessaging.socialPostOperation.tips.cannotDel": "ไม่สามารถลบโพสต์ที่รออนุมัติได้",
  "ccmessaging.socialPostOperation.tips.delConfirm": "ยืนยันการลบข้อมูลที่เลือกไว้หรือไม่",
  "ccmessaging.socialPostOperation.tips.deleteApproveSuccess": "ส่งการลบสําเร็จ รอการตรวจสอบ",
  "ccmessaging.socialPostOperation.tips.deleteNoApprove": "ผู้ยืนยันสิทธิ์ว่างเปล่า จะถูกลบหลังจากส่งแล้ว โปรดยืนยันการส่งหรือไม่",
  "ccmessaging.socialPostOperation.tips.fileTip": "ขนาดไฟล์เกินขีดจํากัด สูงสุด {n}MB",
  "ccmessaging.socialPostOperation.tips.imageNumTip": "รองรับได้ถึง 9",
  "ccmessaging.socialPostOperation.tips.imageType": "รองรับ png, jpg, gif, bmp, tiff",
  "ccmessaging.socialPostOperation.tips.imgAndVideoChoose": "รูปภาพและวิดีโอไม่รองรับการอัปโหลดพร้อมกัน",
  "ccmessaging.socialPostOperation.tips.postTypesChoose": "เลือกอย่างน้อยหนึ่งช่องทาง",
  "ccmessaging.socialPostOperation.tips.publishFailed": "ล้มเหลวในการโพสต์",
  "ccmessaging.socialPostOperation.tips.saveApproveSuccess": "ส่งสําเร็จ รอการอนุมัติ",
  "ccmessaging.socialPostOperation.tips.submit": "ผู้ยืนยันสิทธิ์ว่างเปล่า จะถูกโพสต์โดยตรงหลังจากส่งแล้ว ยืนยันการส่งหรือไม่",
  "ccmessaging.socialPostOperation.tips.submitSuccess": "ส่งสําเร็จ",
  "ccmessaging.socialPostOperation.tips.titleError": "ไม่สามารถรวม <และ>",
  "ccmessaging.socialPostOperation.tips.uploadVideo": "อัปโหลดไฟล์วิดีโอ",
  "ccmessaging.socialPostOperation.tips.videoType": "รองรับรูปแบบ mp4",
  "ccmessaging.socialPostOperation.tips.youTubeFileChoose": "ช่อง Youtube รองรับเฉพาะการอัปโหลดวิดีโอ",
  "ccmessaging.socialPostOperation.youTube.addLabel": "เพิ่มป้ายกํากับ",
  "ccmessaging.socialPostOperation.youTube.audience": "ผู้ชม",
  "ccmessaging.socialPostOperation.youTube.audienceRule": "ตามข้อกําหนดทางกฎหมาย ไม่ว่าคุณจะอยู่ที่ไหนก็ตาม กฎหมายคุ้มครองความเป็นส่วนตัวออนไลน์สําหรับเด็ก (COPPA) และ/หรือกฎหมายอื่น ๆ จะต้องปฏิบัติตาม คุณต้องระบุวิดีโอของคุณว่าเป็นเนื้อหาสําหรับเด็กหรือไม่",
  "ccmessaging.socialPostOperation.youTube.category": "หมวดหมู่",
  "ccmessaging.socialPostOperation.youTube.category.car": "ยานยนต์และพาหนะ",
  "ccmessaging.socialPostOperation.youTube.category.Comedy": "ตลก",
  "ccmessaging.socialPostOperation.youTube.category.education": "การศึกษา",
  "ccmessaging.socialPostOperation.youTube.category.entertainment": "บันเทิง",
  "ccmessaging.socialPostOperation.youTube.category.film": "ภาพยนตร์และแอนิเมชัน",
  "ccmessaging.socialPostOperation.youTube.category.gaming": "เกม",
  "ccmessaging.socialPostOperation.youTube.category.howto": "ฮาวทูและสไตล์",
  "ccmessaging.socialPostOperation.youTube.category.music": "เพลง",
  "ccmessaging.socialPostOperation.youTube.category.news": "ข่าวและการเมือง",
  "ccmessaging.socialPostOperation.youTube.category.nonprofits": "ไม่แสวงหากำไรและนักเคลื่อนไหว",
  "ccmessaging.socialPostOperation.youTube.category.people": "บุคคลและบล็อก",
  "ccmessaging.socialPostOperation.youTube.category.pets": "สัตว์เลี้ยงและสัตว์",
  "ccmessaging.socialPostOperation.youTube.category.science": "วิทยาศาสตร์และเทคโนโลยี",
  "ccmessaging.socialPostOperation.youTube.category.shortMovies": "ภาพยนตร์สั้น",
  "ccmessaging.socialPostOperation.youTube.category.sport": "กีฬา",
  "ccmessaging.socialPostOperation.youTube.category.travel": "ท่องเที่ยวและกิจกรรม",
  "ccmessaging.socialPostOperation.youTube.categoryDetail": "เพิ่มการมองเห็นของคุณลงในหมวดหมู่ที่ช่วยให้ผู้ดูพบได้",
  "ccmessaging.socialPostOperation.youTube.disclosureScope": "ขอบเขตสาธารณะ",
  "ccmessaging.socialPostOperation.youTube.disclosureScopeDetail": "เลือกคนที่สามารถดูวิดีโอของคุณ",
  "ccmessaging.socialPostOperation.youTube.embedding": "อนุญาตให้ฝัง",
  "ccmessaging.socialPostOperation.youTube.embedding.tips": "อนุญาตให้ผู้อื่นฝังวิดีโอของคุณบนเว็บไซต์",
  "ccmessaging.socialPostOperation.youTube.knowledgePermission": "การแบ่งปันความรู้-นามสกุล",
  "ccmessaging.socialPostOperation.youTube.labels": "ป้ายกํากับ",
  "ccmessaging.socialPostOperation.youTube.labelsDetail": "ถ้ามีคําศัพท์ที่สะกดผิดได้ง่ายในวิดีโอ แท็กจะช่วยให้ผู้ดูค้นหาวิดีโอของคุณได้ นอกจากนั้น ป้ายกํากับยังใช้ไม่ได้กับการค้นหาวิดีโอมากนัก",
  "ccmessaging.socialPostOperation.youTube.noKids": "ไม่ใช่สําหรับเด็ก",
  "ccmessaging.socialPostOperation.youTube.normalPermission": "ใบอนุญาตมาตรฐาน YouTube",
  "ccmessaging.socialPostOperation.youTube.permissions": "ใบอนุญาต",
  "ccmessaging.socialPostOperation.youTube.permissionsKnow": "เข้าใจชนิดของใบอนุญาต",
  "ccmessaging.socialPostOperation.youTube.scopePrivate": "ส่วนตัว",
  "ccmessaging.socialPostOperation.youTube.scopePublic": "สาธารณะ",
  "ccmessaging.socialPostOperation.youTube.scopeUnlisted": "ไม่เปิดเผย",
  "ccmessaging.socialPostOperation.youTube.tags.tips": "ป้อนจุลภาคหลังจากแต่ละแท็บ",
  "ccmessaging.socialPostOperation.youTube.videoToKids": "วิดีโอนี้เป็นเนื้อหาสําหรับเด็กหรือไม่",
  "ccmessaging.socialPostOperation.youTube.view.commentNum": "14 ความคิดเห็น",
  "ccmessaging.socialPostOperation.youTube.view.dataResolve": "การวิเคราะห์ข้อมูล",
  "ccmessaging.socialPostOperation.youTube.view.editVideo": "แก้ไขวิดีโอ",
  "ccmessaging.socialPostOperation.youTube.view.lookNum": "38 ครั้งดู 2 วันที่ผ่านมา",
  "ccmessaging.socialPostOperation.youTube.view.orderType": "เรียงลําดับ",
  "ccmessaging.socialPostOperation.youTube.view.share": "แบ่งปัน",
  "ccmessaging.socialPostOperation.youTube.view.subscriber": "สมาชิก 1 คน",
  "ccmessaging.socialPostOperation.youTube.view.unfolding": "ขยาย",
  "ccmessaging.socialPostOperation.youTube.yesKids": "ใช่ เนื้อหาสําหรับเด็ก",
  "ccmessaging.socialPostOperation.youTube.publishAtTips": "ตั้งค่านี้จะทําเฉพาะเมื่อเปิดใช้โหมดส่วนตัวเท่านั้น",
  "ccmessaging.socialPostOperation.facebook.threeDay": "3 วัน",
  "ccmessaging.socialPostOperation.facebook.awful": "ชื่นชม",
  "ccmessaging.socialPostOperation.facebook.comment": "ความคิดเห็น",
  "ccmessgaing.chat.web.channel.Agent": "เจ้าหน้าที่เสียงและวิดีโอ",
  "ccmessgaing.chat.web.channel.ivr": "IVR",
  "ccmessgaing.chat.web.channel.webrtc.share": "การแชร์เดสก์ท็อปเกี่ยวข้องกับการแสดงความเป็นส่วนตัว โปรดยืนยัน",
  "ccmessgaing.chatbot.message.female": "หญิง",
  "ccmessgaing.chatbot.message.male": "ชาย",
  "ccmessgaing.postChannelConfig.accessToken": "สิทธิ์การเข้าถึง",
  "ccmessgaing.postChannelConfig.add": "เพิ่มแชนแนล",
  "ccmessgaing.postChannelConfig.apiFabricAK": "ApiFabric การแสดงตัว",
  "ccmessgaing.postChannelConfig.apiFabricSK": "ApiFabric กุญแจลับ",
  "ccmessgaing.postChannelConfig.apiKey": "กุญแจลับ API",
  "ccmessgaing.postChannelConfig.appId": "การระบุตัวไคลเอ็นต์",
  "ccmessgaing.postChannelConfig.appKey": "กุญแจลับไคลเอ็นต์",
  "ccmessgaing.postChannelConfig.cancel": "ยกเลิก",
  "ccmessgaing.postChannelConfig.channelConfig": "ปรับแต่งช่อง",
  "ccmessgaing.postChannelConfig.channelCreate": "สร้างช่องทาง",
  "ccmessgaing.postChannelConfig.channelEdit": "แก้ไขการปรับแต่งช่องทาง",
  "ccmessgaing.postChannelConfig.channelType": "ประเภทช่องทาง",
  "ccmessgaing.postChannelConfig.confirm": "ยืนยัน",
  "ccmessgaing.postChannelConfig.addUsers": "เพิ่มใหม่",
  "ccmessgaing.postChannelConfig.createFailed": "การสร้างล้มเหลว โปรดตรวจสอบพารามิเตอร์ของฟอร์มและการเชื่อมต่อเครือข่าย",
  "ccmessgaing.postChannelConfig.createSuccess": "สร้างข้อมูลการกําหนดค่าช่องทางมัลติมีเดียสังคมเรียบร้อยแล้ว",
  "ccmessgaing.postChannelConfig.delete": "ลบ",
  "ccmessgaing.postChannelConfig.deleteFailed": "ล้มเหลวในการลบข้อมูลปรับแต่งช่องทางมัลติมีเดียทางสังคม",
  "ccmessgaing.postChannelConfig.deleteFailed.db": "การลบล้มเหลว ฐานข้อมูลการดําเนินการล้มเหลว",
  "ccmessgaing.postChannelConfig.deleteFailed.permission": "การลบล้มเหลว ยังไม่ได้ตรวจสอบสิทธิ์",
  "ccmessgaing.postChannelConfig.deleteFailed.inUse": "การลบล้มเหลว มีช่องข้อมูลการโพสต์อยู่",
  "ccmessgaing.postChannelConfig.deleteSuccess": "ลบข้อมูลการกําหนดค่าช่องทางมัลติมีเดียสังคมเรียบร้อยแล้ว",
  "ccmessgaing.postChannelConfig.edit": "แก้ไข",
  "ccmessgaing.postChannelConfig.editFailed": "การอัปเดตล้มเหลว โปรดตรวจสอบพารามิเตอร์แบบฟอร์มและการเชื่อมต่อเครือข่าย",
  "ccmessgaing.postChannelConfig.editSuccess": "อัปเดตข้อมูลการกําหนดค่าช่องทางมัลติมีเดียสังคมเรียบร้อยแล้ว",
  "ccmessgaing.postChannelConfig.failed": "ล้มเหลว",
  "ccmessgaing.postChannelConfig.maxLength": "ความยาวสูงสุดคือ {n} อักขระ",
  "ccmessgaing.postChannelConfig.operation": "ปฏิบัติการ",
  "ccmessgaing.postChannelConfig.operation.person": "เจ้าหน้าที่ปฏิบัติงาน",
  "ccmessgaing.postChannelConfig.placeSelectChannelType": "โปรดเลือกชนิดของช่อง",
  "ccmessgaing.postChannelConfig.postChannelName": "ชื่อช่อง",
  "ccmessgaing.postChannelConfig.refreshToken": "รีเฟรชใบรับรอง",
  "ccmessgaing.postChannelConfig.repeatedly": "มีช่องนี้อยู่แล้ว",
  "ccmessgaing.postChannelConfig.users.overSize": "จํานวนพนักงานปฏิบัติงานมากกว่า 10 คน",
  "ccmessgaing.postChannelConfig.selectByChannelName": "ชื่อช่อง",
  "ccmessgaing.postChannelConfig.subChannelId": "แสดงตัวช่อง",
  "ccmessgaing.postChannelConfig.success": "สําเร็จ",
  "ccmessgaing.postChannelConfig.unauthorized": "การดําเนินการยังไม่ได้รับการยืนยัน",
  "chat.agent.chatOnlineWorkbench": "Workbench แชทออนไลน์",
  "chat.agent.label.balance": "รายได้และค่าใช้จ่าย",
  "chat.agent.label.billDetail": "รายละเอียดบิล",
  "chat.agent.label.chatBot": "Chatbot",
  "chat.agent.label.consumption": "การบริโภคของฉัน",
  "chat.agent.label.customerSource": "แหล่งที่มาของลูกค้า: Mobile App - Personal Center",
  "chat.agent.label.downagainfile": "ดาวน์โหลดอีกครั้ง",
  "chat.agent.label.goAway": "ห่างออกไป",
  "chat.agent.label.historicalBill": "บิลย้อนหลัง",
  "chat.agent.label.money": "จำนวน",
  "chat.agent.label.mybill": "ตั๋วเงินของฉัน",
  "chat.agent.label.offline": "ออฟไลน์",
  "chat.agent.label.online": "ออนไลน์",
  "chat.agent.label.recivefile": "รับ",
  "chat.agent.label.refusefile": "ปฏิเสธ",
  "chat.agent.label.send": "ส่ง",
  "chat.agent.label.total": "รวม",
  "chat.agent.label.waiting": "รอ",
  "chat.agent.message.changeFailed": "เปลี่ยนสถานะเจ้าหน้าที่ไม่สำเร็จ",
  "chat.agent.title.changeWorkMode": "เปลี่ยนโหมดการทำงาน",
  "chat.agent.title.close": "ปิด",
  "chat.agent.title.createCase": "สร้างเคส",
  "chat.agent.title.createSkill": "สร้างทักษะ",
  "chat.agent.title.enterText": "กรุณากรอก",
  "chat.agent.title.OfflineCall": "สายออฟไลน์",
  "chat.agent.title.transfer": "โอนไปที่",
  "chat.agent.title.userInformation": "ข้อมูลลูกค้า",
  "chat.agent.title.userMenu": "เมนูลูกค้า",
  "chat.agent.title.workMode": "โหมดงาน",
  "chat.agentConsole.message.content.audio": "[ข้อความเสียง]",
  "chat.agentConsole.message.content.connectMessage": "ข้อความใหม่!",
  "chat.agentConsole.message.content.image": "[ข้อความรูปภาพ]",
  "chat.agentConsole.message.content.locate": "[ข้อความตำแหน่ง]",
  "chat.agentConsole.message.content.video": "[ข้อความวิดีโอ]",
  "chat.agentConsole.message.error.emptyMessage": "[ข้อความไม่มีเนื้อหาที่สามารถแสดงได้]",
  "chat.agentConsole.message.error.notification": "ไม่สามารถสร้างการแจ้งเตือนข้อความใหม่ได้ โปรดตรวจสอบการกำหนดค่าเบราว์เซอร์!",
  "chat.client.alert.message.invildtoken": "กล่องโต้ตอบของคุณหมดเวลาหรือคุณได้ลงชื่อเข้าใช้ที่อื่น กรุณาปิดหน้าต่างแชทและลองอีกครั้ง ขอขอบคุณ!",
  "chat.client.button.submit": "ส่ง",
  "chat.client.evaluate.message": "โปรดแสดงความคิดเห็นเกี่ยวกับบริการของฉัน ขอขอบคุณ!",
  "chat.client.greeting.afternoon": "สวัสดีตอนบ่าย!",
  "chat.client.greeting.dear": "ที่รัก",
  "chat.client.greeting.evening": "สวัสดีตอนเย็น!",
  "chat.client.greeting.info": "ฉันจะทำอะไรให้คุณได้บ้าง?",
  "chat.client.greeting.morning": "สวัสดีตอนเช้า!",
  "chat.client.label.accountNumber": "ชื่อบัญชี",
  "chat.client.label.bill": "บิลของฉัน",
  "chat.client.label.chatTitle": "ลูกค้า",
  "chat.client.label.download": "ดาวน์โหลด",
  "chat.client.label.evaluation": "แบบสำรวจความพึงพอใจ",
  "chat.client.label.forgotPassword": "ลืมรหัสผ่าน",
  "chat.client.label.header": "Telco +",
  "chat.client.label.input": "กรุณากรอก",
  "chat.client.label.like": "คุณอาจชอบ",
  "chat.client.label.login": "เข้าสู่ระบบ",
  "chat.client.label.loginTitle": "เข้าสู่ระบบ",
  "chat.client.label.more": "มากกว่า",
  "chat.client.label.no": "ไม่",
  "chat.client.label.order": "คำสั่งซื้อของฉัน",
  "chat.client.label.password": "รหัสผ่าน",
  "chat.client.label.privacyHint": "คำเตือนสำหรับการคุ้มครองความเป็นส่วนตัว",
  "chat.client.label.privacyHintContent": "ระบบจะบันทึกการสนทนาของคุณเพื่อปรับปรุงคุณภาพการบริการ แน่ใจไหมว่าต้องการคุยต่อ",
  "chat.client.label.promotions": "พร้อมท์",
  "chat.client.label.rateService": "บริการคะแนน",
  "chat.client.label.system": "ระบบไม่ว่าง โปรดลองอีกครั้งในภายหลัง",
  "chat.client.label.yes": "ใช่",
  "chat.client.message.evaluationFail": "การสำรวจความพึงพอใจล้มเหลว",
  "chat.client.message.evaluationSuccess": "ผลสำรวจความพึงพอใจประสบความสำเร็จ",
  "chat.client.message.gladtoServe": "ฉันจะทำอะไรให้คุณได้บ้าง?",
  "chat.client.message.hiMyWorkno": "สวัสดี! นี่คือ",
  "chat.client.message.noAgent": "ขออภัยเจ้าหน้าที่ไม่ว่าง โปรดลองอีกครั้งในภายหลัง",
  "chat.client.transfer.link": "แน่ใจไหมว่าต้องการโอนสายไปยังเจ้าหน้าที่",
  "chat.common.message.cancel": "ยกเลิก",
  "chat.common.message.create": "เพิ่ม",
  "chat.common.message.dateEnd": "เวลาสิ้นสุด",
  "chat.common.message.dateStart": "เวลาเริ่มต้น",
  "chat.common.message.delete": "ลบ",
  "chat.common.message.error": "ข้อผิดพลาด",
  "chat.common.message.fail": "ล้มเหลว",
  "chat.common.message.finish": "เสร็จสิ้น",
  "chat.common.message.modify": "แก้ไข",
  "chat.common.message.promotions": "เคล็ดลับ",
  "chat.common.message.success": "สำเร็จ",
  "chat.common.message.warning": "คำเตือน",
  "chat.confirm.no": "ไม่",
  "chat.confirm.ok": "ยืนยัน",
  "chat.confirm.yes": "ใช่",
  "chat.session.channel.all": "ทั้งหมด",
  "chat.session.channel.facebook": "Facebook",
  "chat.session.channel.mobile": "เทอร์มินัลมือถือ",
  "chat.session.channel.twitter": "Twitter",
  "chat.session.channel.web": "เว็บไซต์",
  "chat.session.channel.wechat": "WeChat",
  "chat.session.endType.agentEnd": "ปิดท้ายโดยเจ้าหน้าที่",
  "chat.session.endType.customerEnd": "จบโดยลูกค้า",
  "chat.session.endType.overtimeEnd": "สิ้นสุดเนื่องจากหมดเวลา",
  "chat.session.label.alias": "ชื่อลูกค้า",
  "chat.session.label.allChannel": "ประเภทช่องทั้งหมด",
  "chat.session.label.allEndType": "ประเภทสิ้นสุดทั้งหมด",
  "chat.session.label.channel": "ช่องโต้ตอบการเข้าถึง",
  "chat.session.label.created_endTime": "เวลาสิ้นสุด",
  "chat.session.label.created_startTime": "เวลาเริ่มต้น",
  "chat.session.label.createdTime": "เวลาในการสร้างไดอะล็อก",
  "chat.session.label.endReason": "สาเหตุของการสิ้นสุดของกล่องโต้ตอบ",
  "chat.session.label.endTime": "เวลาสิ้นสุดของกล่องโต้ตอบ",
  "chat.session.label.endType": "ประเภทสิ้นสุดของกล่องโต้ตอบ",
  "chat.session.label.enterQueueTime": "ลำดับเวลาเข้า",
  "chat.session.label.evaluation": "การประเมินการโต้ตอบ",
  "chat.session.label.evaluationTime": "เวลาประเมินไดอะล็อก",
  "chat.session.label.exitQueueTime": "เวลาออกลำดับ",
  "chat.session.label.remark": "แสดงความคิดเห็น",
  "chat.session.label.reset": "รีเซ็ต",
  "chat.session.label.search": "ค้นหา",
  "chat.session.label.servedAgent": "เจ้าหน้าที่บริการ",
  "chat.session.label.sessionId": "ID กล่องโต้ตอบ",
  "chat.session.label.skillQueueId": "Skill Queue ID",
  "chat.session.label.skillQueueName": "ชื่อ Skill Queue",
  "chat.session.label.subChannel": "ช่องทางการเข้าถึงย่อย",
  "chat.session.title.basic": "ข้อมูลไดอะล็อกพื้นฐาน",
  "chat.session.title.detail": "รายละเอียดกล่องโต้ตอบ",
  "chat.session.title.history": "บันทึกการสนทนาทางประวัติศาสตร์",
  "chat.skill.label.account": "ชื่อบัญชี",
  "chat.skill.label.agents": "เจ้าหน้าที่",
  "chat.skill.label.cancel": "ยกเลิก",
  "chat.skill.label.createSkill": "สร้าง",
  "chat.skill.label.createSkillTab": "สร้างทักษะ",
  "chat.skill.label.delete": "ลบ",
  "chat.skill.label.deleteSkills": "ลบ",
  "chat.skill.label.description": "คำอธิบาย",
  "chat.skill.label.edit": "แก้ไข",
  "chat.skill.label.inputName": "ชื่อ",
  "chat.skill.label.maxQueueNumber": "สูงสุด หมายเลขลำดับ",
  "chat.skill.label.maxQueueTime": "สูงสุด ลำดับเวลา",
  "chat.skill.label.maxQueueTimeInTable": "สูงสุด เวลาลำดับ",
  "chat.skill.label.name": "ชื่อผู้ใช้",
  "chat.skill.label.ok": "ตกลง",
  "chat.skill.label.priority": "ลำดับความสำคัญ",
  "chat.skill.label.reset": "รีเซ็ต",
  "chat.skill.label.save": "บันทึก",
  "chat.skill.label.skill": "ทักษะ -",
  "chat.skill.label.skillDetail": "รายละเอียดทักษะ",
  "chat.skill.label.skillId": "ไม่",
  "chat.skill.label.skillName": "ชื่อ",
  "chat.skill.label.submit": "ส่ง",
  "chat.skill.message.completeInputs": "กรุณากรอกข้อมูลทั้งหมด",
  "chat.skill.message.createFail": "สร้างทักษะไม่สำเร็จ",
  "chat.skill.message.createSuccess": "สร้างทักษะสำเร็จ",
  "chat.skill.message.delete": "ลบ",
  "chat.skill.message.error": "ข้อผิดพลาด",
  "chat.skill.message.failDeleteSkill": "ลบทักษะไม่สำเร็จ",
  "chat.skill.message.isExisted": "ชื่อทักษะมีอยู่แล้ว",
  "chat.skill.message.priorityLength": "ตรวจสอบให้แน่ใจว่าลำดับความสำคัญน้อยกว่าหรือเท่ากับห้าหลัก",
  "chat.skill.message.saveFail": "บันทึกทักษะไม่สำเร็จ",
  "chat.skill.message.saveSuccess": "บันทึกสกิลสำเร็จ",
  "chat.skill.message.selectSkills": "เลือกทักษะ",
  "chat.skill.message.success": "สำเร็จ",
  "chat.skill.message.warning": "คำเตือน",
  "chat.skill.message.willDeleteAllSkills": "แน่ใจไหมว่าต้องการลบทักษะทั้งหมด",
  "chat.skill.message.willDeleteSkill": "แน่ใจไหมว่าต้องการลบสกิล",
  "chat.skill.message.willDeleteSkills": "แน่ใจหรือไม่ว่าต้องการลบทักษะที่เลือก?",
  "chat.thirdportal.chatWindow.button.startChat": "เริ่มแชท",
  "chat.thirdportal.chatWindow.label.userEmail": "อีเมล",
  "chat.thirdportal.chatWindow.label.userName": "ชื่อ",
  "chat.thirdportal.chatWindow.label.userPhone": "จำนวน",
  "chat.thirdportal.title": "เว็บพอร์ทัลของลูกค้า",
  "chat.timerange.label.alldays": "เวลาสร้างทั้งหมด",
  "chat.timerange.label.confirmSocailChatNum": "จำนวนลูกค้าบริการพร้อมกันสูงสุดคือ 0 จะใช้การกำหนดค่าส่วนกลาง",
  "chat.timerange.label.customizes": "กำหนดเอง",
  "chat.timerange.label.ok": "ตกลง",
  "chat.timerange.label.oneday": "วันสุดท้าย",
  "chat.timerange.label.sevendays": "7 วันที่ผ่านมา",
  "chat.timerange.label.thirtydays": "30 วันที่ผ่านมา",
  "chat.timerange.label.threedays": "3 วันที่ผ่านมา",
  "chat.timerange.message.timeempty": "เวลาต้องไม่ว่าง",
  "chat.timerange.message.timeerror": "รูปแบบของเวลาไม่ถูกต้อง",
  "chat.usefulexpressions.label.createExpressionTab": "สร้างคำ",
  "chat.usefulexpressions.label.expression": "คำทั่วไป",
  "chat.usefulexpressions.label.expressionDetail": "รายละเอียดคำ",
  "chat.usefulexpressions.message.contentLength": "ตรวจสอบว่าความยาวของคำทั่วไปน้อยกว่าหรือเท่ากับ 1,000 อักขระ",
  "chat.usefulexpressions.message.contentNotNull": "คำต้องไม่ว่างเปล่า",
  "chat.usefulexpressions.message.createFail": "สร้างคำไม่สำเร็จ",
  "chat.usefulexpressions.message.createSuccess": "สร้างคำสำเร็จ",
  "chat.usefulexpressions.message.failDeleteExpression": "ลบคำไม่สำเร็จ",
  "chat.usefulexpressions.message.isExisted": "มีชื่อคำอยู่แล้ว",
  "chat.usefulexpressions.message.saveFail": "บันทึกคำไม่สำเร็จ",
  "chat.usefulexpressions.message.saveSuccess": "บันทึกข้อความเผยแพร่เรียบร้อยแล้ว",
  "chat.usefulexpressions.message.selectExpressions": "เลือกคำ",
  "chat.usefulexpressions.message.willDeleteExpression": "คุณแน่ใจไหมว่าที่จะต้องการลบข้อความ?",
  "chat.usefulexpressions.message.willDeleteExpressions": "แน่ใจไหมว่าต้องการลบคำที่เลือก",
  "chat.web.evaluation.star1": "ไม่พอใจมาก",
  "chat.web.evaluation.star2": "ไม่พึงพอใจ",
  "chat.web.evaluation.star3": "ท่านนายพล",
  "chat.web.evaluation.star4": "ความพึงพอใจ",
  "chat.web.evaluation.star5": "พอใจมาก",
  "chat.webconfig.button.save": "บันทึก",
  "chat.webconfig.label.bandingskill": "ผูกลำดับทักษะ",
  "chat.webconfig.label.basesettings": "การกำหนดค่าพื้นฐาน",
  "chat.webconfig.label.functionalsettings": "การตั้งค่าฟังก์ชัน",
  "chat.webconfig.label.greeting": "คำอวยพร",
  "chat.webconfig.label.greeting.placeholder": "โปรดป้อนเนื้อหาของคำทักทายที่แสดงในหน้าต่างแชทของไคลเอนต์",
  "chat.webconfig.label.intergratedcode": "รหัสรวม",
  "chat.webconfig.label.intergratemsg": "รวมแชทสดเข้ากับเว็บไซต์ของคุณ",
  "chat.webconfig.label.language": "ภาษาเริ่มต้น",
  "chat.webconfig.label.language.en": "ภาษาอังกฤษ",
  "chat.webconfig.label.language.zh": "ภาษาจีน",
  "chat.webconfig.label.language.pt": "โปรตุเกส",
  "chat.webconfig.label.transHuman": "โอนไปยังเจ้าหน้าที่",
  "chat.webconfig.label.windowTitle": "ชื่อหน้าต่างแชท",
  "chat.webconfig.label.windowTitle.placeholder": "โปรดป้อนชื่อที่แสดงในหน้าต่างแชทบนไคลเอนต์",
  "chat.webconfig.message.configdirective": "คัดลอกโค้ดต่อไปนี้และวางไว้หน้า </body> เว็บไซต์ของคุณหลังจากเผยแพร่ สิทธิในการเข้า Huawei Service Cloud จะแสดงที่มุมล่างขวาของเว็บไซต์ของคุณคุณสามารถคลิกเพื่อพูดคุยกับฝ่ายบริการลูกค้าได้แบบเรียลไทม์บันทึกและเผยแพร่เพื่อเข้าใช้งาน Huawei Service Cloud ได้อย่างรวดเร็ว",
  "chat.webconfig.message.demodirective": "คุณสามารถเปิดที่อยู่ต่อไปนี้ในหน้าต่างที่ซ่อนอยู่ของเบราว์เซอร์ Chrome เพื่อเข้าถึงหน้าการนำเสนอที่ระบบจัดเตรียมไว้ให้:",
  "chat.webconfig.message.saveFail": "บันทึกการตั้งค่าไม่สำเร็จ",
  "chat.webconfig.message.saveSuccess": "บันทึกการตั้งค่าเรียบร้อยแล้ว",
  "chat.webconfig.title": "เปิดใช้งานช่องทางเว็บ",
  "chat.weChatConfig.label.appId": "ID ผู้พัฒนา",
  "chat.weChatConfig.label.AppSecret": "รหัสผ่านของนักพัฒนา",
  "chat.weChatConfig.label.bindSkill": "ผูกลำดับทักษะ",
  "chat.weChatConfig.label.codeName": "รหัสการเข้าถึง",
  "chat.weChatConfig.label.configId": "ID การตั้งค่า",
  "chat.weChatConfig.label.create": "ใหม่",
  "chat.weChatConfig.label.delete": "ลบ",
  "chat.weChatConfig.label.Domain": "ที่อยู่เซิฟเวอร์",
  "chat.weChatConfig.label.facebook": "Facebook",
  "chat.weChatConfig.label.nextStep": "ต่อไป",
  "chat.weChatConfig.label.qcode": "ลำดับอาร์โค้ด",
  "chat.weChatConfig.label.save": "บันทึก",
  "chat.weChatConfig.label.search": "ค้นหา",
  "chat.weChatConfig.label.secret": "สำคัญ",
  "chat.weChatConfig.label.state": "สถานะ",
  "chat.weChatConfig.label.tenantId": "ID ผู้เช่า",
  "chat.weChatConfig.label.verify": "การยืนยัน",
  "chat.weChatConfig.label.verifycode": "โทเค็น",
  "chat.weChatConfig.label.verifying": "กำลังตรวจสอบ",
  "chat.weChatConfig.label.wechat": "WeChat",
  "chat.weChatConfig.link.wechat": "เซิร์ฟเวอร์ WeChat",
  "chat.weChatConfig.message.appIdExisted": "ID นักพัฒนาบัญชีสาธารณะที่ป้อนมีอยู่แล้ว",
  "chat.weChatConfig.message.codeNameExisted": "มีรหัสการเข้าถึงช่องอยู่แล้ว",
  "chat.weChatConfig.message.deleteError": "ลบรายการกำหนดค่าไม่สำเร็จ",
  "chat.weChatConfig.message.emptyDeleteArray": "เลือกรายการการกำหนดค่าที่จะลบ",
  "chat.weChatConfig.message.ensureDelete": "แน่ใจไหมว่าต้องการลบรายการการกำหนดค่าที่เลือก",
  "chat.weChatConfig.message.error": "ข้อผิดพลาด",
  "chat.weChatConfig.message.savesuccess": "เข้าถึงได้สำเร็จ",
  "chat.weChatConfig.message.skillExisted": "ลำดับทักษะที่เลือกถูกผูกไว้กับบัญชีสาธารณะอื่น",
  "chat.weChatConfig.message.unknown": "ไม่ทราบสถานะการยืนยัน",
  "chat.weChatConfig.stepname.selectchannel": "เลือกช่องทางการเข้าถึง",
  "chat.weChatConfig.stepname.selectmode": "เลือกวิธีการกำหนดค่า",
  "chat.weChatConfig.stepname.success": "เข้าถึงสำเร็จ",
  "chat.weChatConfig.stepname.verify": "ส่งเพื่อยืนยัน",
  "chat.weChatConfig.title": "โซเชียลมีเดียเปิดใช้งานการตั้งค่า",
  "chat.weChatConfig.title.deleteTitle": "ลบรายการกำหนดค่า WeChat",
  "cmessaging.chat.answerUser.error": "ล้มเหลวในการสร้างข้อความตอบกลับของผู้ใช้",
  "cmessaging.chat.answerUser.Tips": "สร้างข้อความตอบกลับของผู้ใช้",
  "cmessaging.chat.channelconfig.isPushWebMessage": "ผลักดันข้อความ",
  "cmessaging.chat.channelconfig.pushWebMessageKey": "คีย์",
  "cmessaging.chat.channelconfig.pushWebMessageUrl": "ที่อยู่พุชของข้อความ",
  "cmessaging.chat.channelconfig.pushWebCertTypeSignature": "การตรวจสอบสิทธิ์ลายเซ็น",
  "cmessaging.chat.channelconfig.pushWebCertTypeOAuth": "การรับรอง OAuth",
  "cmessaging.chat.channelconfig.setAgentTimeout": "การโอนเงินหมดเวลาตัวแทน",
  "cmessaging.chat.multimedia.numOverMaxSum": "ปริมาณข้อมูลแอปที่คุณบันทึกไว้เกินขีด จำกัด ",
  "cmessaging.chat.multimediatype.numOverMaxSum": "จํานวนหมวดหมู่ที่คุณบันทึกไว้เกินขีดจํากัด ",
  "ccmessaging.socialPostOperation.search.searchContent": "โปรดกรอกข้อมูล",
  "ccmessaging.socialPostOperation.link": "เยี่ยมชมต้นฉบับ",
  "ccmessaging.chat.social.media.enterprise.post.load.failed": "ไม่สามารถโหลดสิ่งที่แนบมาด้วยได้",
  "ccmessaging.chat.channelconfig.searchChannelName": "ชื่อช่องค้นหา",
  "ccmessaging.chat.channelconfig.createChannel": "ช่องใหม่",
  "ccmessaging.chat.channelconfig.modifyChannel": "แก้ไขช่อง",
  "ccmessaging.chat.channelconfig.channelIntegrate": "การรวมช่องทาง",
  "ccmessaging.chat.channelconfig.baseConfig": "การปรับตั้งค่าพื้นฐาน",
  "ccmessaging.chat.channelconfig.robotConfig": "การปรับตั้งค่าหุ่นยนต์",
  "ccmessaging.chat.channelconfig.chooseChannel": "การเลือกช่อง",
  "ccmessaging.chat.channelconfig.range.placeholder": "{0}-{1}",
  "ccmessaging.chat.channelconfig.advanceConfig": "เพิ่มเติม",
  "ccmessaging.chat.channelconfig.web.clickToCall": "แตะที่",
  "ccmessaging.chat.channelconfig.time.timeNoRange": "ช่วงเวลา {n} เวลาเริ่มต้นควรน้อยกว่าเวลาสิ้นสุด",
  "ccmessaging.chat.channelconfig.time.timeCross": "ช่วงเวลา {0} และช่วงเวลา {1} ที่มีการเชื่อมต่อ",
  "ccmessaging.chat.channelconfig.time.timePeriod0": "โปรดกรอกช่วงเวลา {n}",
  "ccmessaging.chat.channelconfig.time.timePeriod": "โปรดกรอกหรือลบช่วงเวลา {n}",
  "ccmessaging.silentagent.button.create": "สร้างใหม่",
  "ccmessaging.silentagent.dialog.title.create": "สร้างตัวแทนเงียบเสียงใหม่",
  "ccmessaging.silentagent.dialog.title.edit": "แก้ไขเจ้าหน้าที่เงียบเสียง",
  "ccmessaging.silentagent.dialog.title.view": "ดูเจ้าหน้าที่เงียบเสียง",
  "ccmessaging.silentagent.delete.selectone": "โปรดเลือกบันทึกอย่างน้อยหนึ่งรายการ",
  "ccmessaging.silentagent.delete.select.morethan.100": "ไม่สามารถลบข้อมูลได้มากกว่า 100 ครั้งในครั้งเดียว",
  "ccmessaging.silentagent.placeholder.selectaudioskill": "โปรดเลือกคิวทักษะเสียง",
  "ccmessaging.silentagent.btn.add.period": "เพิ่มช่วงเวลา",
  'ccmessaging.silentagent.agent.workday.worktime.error': 'โพรเซสมีข้อผิดพลาดในการกําหนดค่าเวลาทํางานวันทํางาน ช่วงเวลานี้ถูกตัดออกไป!',
  'ccmessaging.silentagent.agent.holiday.worktime.error': 'เจ้าหน้าที่ไม่ได้กําหนดค่าเวลาทําการวันทํางานไม่ถูกต้อง ช่วงเวลานี้ถูกตัดออกไป!',
  'ccmessaging.silentagent.agent.workday.worktime.empty': 'การปรับแต่งที่ว่างเปล่าสําหรับวันทําการของเจ้าหน้าที่',
  'ccmessaging.silentagent.agent.holiday.worktime.empty': 'โพรเซสว่างสําหรับตัวแทนที่ไม่ได้ใช้วันทําการ',
  "ccmessaging.silentagent.voiceaccess.empty": "การปรับแต่งเสียง IVR ไม่สามารถเว้นว่างไว้ได้",
  "ccmessaging.chat.channelconfig.searchCertCode": "ค้นหารหัสใบรับรอง",
  "ccmessaging.chat.channelconfig.cloudApi.accessToken.tips": "การเข้าถึง access token ของ cloud api ที่สร้างขึ้นสําหรับแพลตฟอร์มนักพัฒนา ให้กรอกข้อมูลที่ถูกต้องเป็นเวลา 2 วันตามความเป็นจริง เช่น 60 วันจริง ขอแนะนําให้กรอกข้อมูล 58 วัน สําหรับ Sky token อย่างถาวร การรั่วไหลง่ายโปรดใช้อย่างระมัดระวัง",
  "ccmessaging.chat.channelconfig.cloudApi.bsp.tips": "ขณะนี้ Cloud API เชื่อมต่อเฉพาะข้อความเท่านั้น",
  "ccmessaging.chat.channelconfig.checkWhatsAppAccessTokenDuration": "access token เวลาที่ถูกต้องควรเป็นจํานวนเต็มระหว่าง 1 ถึง 60",
  "ccmessaging.chat.channelconfig.copy": "คัดลอก",
  "ccmessaging.chat.channelconfig.copySuccess": "คัดลอกเรียบร้อยแล้ว",
  "ccmessaging.chat.sumbitverification.whatsApp.cloudapi.tips": "โปรดตั้งค่า URL และรหัสยืนยันในแอพพลิเคชั่น สําหรับนักพัฒนา Facebook",
  "ccmessaging.chatbot.label.useIntelligentRobot": "เชื่อมต่อหุ่นยนต์อัจฉริยะ",
  "ccmessaging.chatbot.label.useRobotAssistants": "เชื่อมต่อผู้ช่วยหุ่นยนต์",
  "ccmessaging.chatbot.label.update": "อัปเดต",
  "ccmessaging.chatbot.label.pleaseSelect": "โปรดเลือก",
  "ccmessaging.chatbot.label.pleaseInput": "โปรดป้อน",
  "ccmessaging.chatbot.label.intelligentRobotAccessCode": "รหัสการเข้าถึงหุ่นยนต์อัจฉริยะ",
  "ccmessaging.chatbot.label.selectIntelligentRobot": "เลือกหุ่นยนต์อัจฉริยะ",
  "ccmessaging.chatbot.label.selectRobotAssistants": "เลือกผู้ช่วยหุ่นยนต์",
  "ccmessaging.chatbot.check.systeam": "ระบบ",
  "ccmessaging.chatbot.label.robotName": "ชื่อหุ่นยนต์",
  "ccmessaging.chatbot.label.selectRobotAvatar": "เลือกอวตารหุ่นยนต์",
  "ccmessaging.fiveG.label.chatRobotAddress": "ที่อยู่แชทบอท",
  "ccmessaging.fiveG.label.appId": "ระบุแอป",
  "ccmessaging.fiveG.label.appSecret": "แอปลับ",
  "ccmessaging.fiveG.label.certCode": "แฟ้มใบรับรอง",
  "ccmessaging.email.label.selectGateway": "เลือกเกตเวย์",
  "ccmessaging.email.label.gatewayName": "ชื่อเกตเวย์",
  "ccmessaging.email.label.InputGatewayName": "โปรดป้อนชื่อเกตเวย์",
  "ccmessaging.email.label.defaultReceiveGateway": "กล่องรับจดหมายเริ่มต้น",
  "ccmessaging.email.label.defaultSendGateway": "กล่องจดหมายเริ่มต้น",
  "ccmessaging.sms.label.defaultSendGateway": "หมายเลขส่งเริ่มต้น",
  "ccmessaging.sms.label.defaultReceiveGateway": "รับหมายเลขเริ่มต้น",
  "ccmessaging.email.valid.signatureSearch": "อนุญาตเฉพาะตัวอักษรหรือตัวเลขไม่เกิน 10 หลักเท่านั้น",
  "ccmessaging.service.time.label": "การกําหนดค่าปฏิทินงาน",
  "ccmessaging.service.time.choose.time": "เลือกวันที่ไม่ได้ทํางาน",
  "ccmessaging.service.time.select.holiday": "เลือกวันที่พัก",
  "ccmessaging.service.time.select.weekday": "เลือกวันทํางาน",
  "ccmessaging.multimedia.tip.not.exist": "ไม่มีทรัพยากรมัลติมีเดียอยู่",
  "ccmessaging.multimedia.tip.not.modified": "ทรัพยากรมัลติมีเดียถูกแก้ไข โปรดส่งอีกครั้ง",
  "ccmessaging.service.time.close.alert": "การปิดจะล้างข้อมูลทั้งหมดในแบบฟอร์มนี้ ยืนยันการปิด",
  "ccmessaging.chat.phrase.create": "สร้างวลีที่ใช้บ่อย",
  "ccmessaging.chat.phrase.update": "แก้ไขวลีที่ใช้บ่อย",
  "ccmessaging.chat.specialChannel.create": "สร้างกฎพิเศษของรายการ",
  "ccmessaging.chat.specialChannel.update": "แก้ไขกฎรายการพิเศษ",
  "ccmessaging.emailAdress.contentNotNull": "ที่อยู่อีเมลไม่สามารถเว้นว่างไว้ได้",
  "ccmessaging.smsNumber.contentNotNull": "หมายเลขโทรศัพท์ไม่สามารถว่างได้",
  "ccmessaging.callcenterinstanceslist.vcallcenterDeatil.passValidateFailed": "รหัสผ่านต้องมีอักขระอย่างน้อยสามประเภทดังต่อไปนี้: ตัวอักษรพิมพ์ใหญ่ ตัวอักษรพิมพ์เล็ก ตัวเลข หรืออักขระพิเศษดังต่อไปนี้: {n}",
  "ccmessaging.chat.shortcut.shortcutRepeat": "ใช้ทางลัดแล้ว",
  "ccmessaging.chat.multimedia.agentHangupSessionResult": "สิ้นสุดเซสชันเรียบร้อยแล้ว โปรดปิดกริยา CRM",
  "ccmessaging.chat.multimedia.agentAutoTransferResult": "การย้ายระบบของสวิตช์สําเร็จ โปรดปิดการใช้งานคําสั่ง CRM",
  "ccmessaging.chat.multimedia.agentTimeOutTransferResult": "คิวทักษะการถ่ายโอนเซสชันหมดเวลา โปรดปิดคําสั่ง CRM",
  "ccmessaging.chat.multimedia.userHangupSessionResult": "ผู้ใช้จะวางเซสชันในเชิงรุก โปรดปิดคําสั่ง CRM",
  "ccmessaging.chat.multimedia.userTimeOutHangupResult": "เซสชันหมดเวลา โปรดปิดคําสั่ง CRM",
  "ccmessaging.chat.multimedia.agentLogoutHangupResult": "เจ้าหน้าที่บังคับใช้การลงชื่อเข้าใช้เสร็จสิ้นเซสชั่นเรียบร้อยแล้ว โปรดปิดคําสั่ง CRM",
  "ccmessaging.chat.dy.reconnect": "เชื่อมต่ออีกครั้ง",
  "ccmessaging.chat.dy.uploadLocalFile": 'แฟ้มภายใน',
  "ccmessaging.chat.dy.call.end": 'สิ้นสุดเซสชัน',
  "ccmessaging.chat.richtext.httpTip": "ลิงก์ที่แทรกเข้ามาใช้โปรโตคอลที่ไม่ปลอดภัยซึ่งมีความเสี่ยงด้านความปลอดภัย ส่งหรือไม่?",
  "ccmessaging.chat.riskTip": "การแจ้งเตือนความเสี่ยง",
  "ccmessaging.chat.chatcard.location": "ที่อยู่",
  "ccmessaging.chat.chatcard.alignment": "รูปแบบการแสดงการ์ด",
  "ccmessaging.chat.chatcard.templateType": "ประเภทเทมเพลต",
  "ccmessaging.chat.chatcard.templateTypeList0": "ประเภททั่วไป",
  "ccmessaging.chat.chatcard.templateTypeList1": "ตัวเลือกการ์ด",
  "ccmessaging.chat.chatcard.tabMediaResource": "เนื้อหาสื่อรูปภาพ",
  "ccmessaging.chat.voice.record.send": "ส่งเสียง",
  "ccmessaging.chat.voice.record.start": "แตะเพื่อบันทึก",
  "ccmessaging.chat.voice.record.tip": "เวลาบันทึกไม่เกิน 10 นาที",
  "ccmessaging.chat.voice.record.stop": "หยุดการบันทึก",
  "ccmessaging.chat.selectorgs.maxlimit.warn": "จํานวนองค์กรที่เลือกต้องไม่เกิน 100 องค์กร",
  "ccmessaging.chat.chatconfig.deleteFacebookPage": "ลบหน้าแรกของ Facebook",
  "ccmessaging.chat.search.name": "ชื่อการค้นหา",
  "ccmessaging.chat.search.phraseName": "ค้นหาวลีทั่วไป",
  "ccmessaging.chat.chatcard.tabnamevalidate": "เปลี่ยนเส้นทางUrlเป็นค่าเริ่มต้นของระบบและไม่ได้รับอนุญาตให้นำมาใช้",
  "ccmessaging.chat.chatcard.transverse": "แนวนอน",
  "ccmessaging.chat.chatcard.longitudinal": "ภาพเหมือน",
  "ccmessaging.chat.channelconfig.asyncTime.tip": "ในสถานการณ์ออฟไลน์ ข้อความออฟไลน์ที่ลูกค้าส่งจะไม่ถูกส่งไปยังเจ้าหน้าที่อีกต่อไป และจัดเก็บเป็นข้อความออฟไลน์โดยตรงภายในระยะเวลาที่กําหนด",
  "ccmessaging.chat.channelconfig.noReplyTime.tip": "หลังจากลูกค้าติดต่อเจ้าหน้าที่เมื่อเร็ว ๆ นี้ ช่วงเวลาเกินกําหนดที่กําหนดไว้ เจ้าหน้าที่จะไม่อนุญาตให้มีการแอคทีฟใดๆ เกิดขึ้น",
  "ccmessaging.chat.channelconfig.noReplyIntervalTime.tip": "ในสถานการณ์อื่น ๆ การตอบกลับของลูกค้าเกินระยะเวลาที่กําหนดจะสิ้นสุดเซสชันโดยอัตโนมัติ",
  "ccmessaging.chat.email.attachment.tips.maxOnceUpload": "จํานวนไฟล์แนบต้องไม่เกิน\u00205\u0020ไฟล์",
  "ccmessaging.chat.email.attachment.tips.maxTotalUpload": "จํานวนไฟล์แนบที่อัปโหลดเกิน 10 ไฟล์",
  "ccmessaging.chat.voice.record": "บันทึก",
  "ccmessaging.chat.offline.userIsInService": "มีผู้ใช้งานประจําอยู่แล้ว",
  "ccmessaging.chat.channelconfig.search.by.robotNameKeyword": "ค้นหาชื่อหุ่นยนต์หรือรหัสการเข้าถึง",
  "ccmessaging.chat.channelconfig.searchSkillQueueName": "ค้นหาชื่อคิวทักษะ",
  "ccmessaging.chat.user.online": "ผู้ใช้ออนไลน์แล้ว",
  "ccmessaging.chat.async.echarts.user": "จํานวนคน (ครั้ง)",
  "ccmessaging.chat.async.echarts.message": "ข้อความ(ข้อความ)",
  "cobrowse.sessionrecord.roomId": "หมายเลขห้อง",
  "cobrowse.sessionrecord.searchroomId": "กรุณากรอกหมายเลขห้อง",
  "cobrowse.sessionrecord.workNo": "หมายเลขตัวแทน",
  "cobrowse.sessionrecord.searchworkNo": "กรุณากรอกหมายเลขตัวแทน",
  "cobrowse.sessionrecord.caller": "ข้อมูลผู้โทร",
  "cobrowse.sessionrecord.searchcaller": "กรุณาใส่ข้อมูลการโทร",
  "cobrowse.sessionrecord.startAt": "เวลาเริ่มต้น",
  "cobrowse.sessionrecord.endAt": "เวลาสิ้นสุด",
  "cobrowse.sessionrecord.duration": "ระยะเวลา",
  "cobrowse.sessionrecord.positiveInteger.checkmsg": "ค่าต้องเป็นจํานวนเต็มที่เป็นบวก",
  "cobrowse.sensitiveinfo.pageMatchValue": "การกำหนดค่าการจับคู่หน้า",
  "cobrowse.sensitiveinfo.rulenamecheck": "ชื่อกฎต้องประกอบด้วยตัวอักษรตัวเลข _. และมีความยาวไม่เกิน 128 หลักเท่านั้น",
  "cobrowse.sensitiveinfo.url.check": "เส้นทาง URL ที่กําหนดค่าไว้สําหรับการจับคู่หน้าเว็บอยู่ในรูปแบบที่ไม่ถูกต้อง",
  "cobrowse.sensitiveinfo.cssselectorcheck": "ไวยากรณ์ตัวเลือก CSS ผิดพลาด",
  "cobrowse.sensitiveinfo.pageIdCheck": "ID หน้ามีได้เฉพาะตัวอักษร ตัวเลข _-: เท่านั้น และอักขระสูงสุด 1024 ตัว",
  "cobrowse.sensitiveinfo.create": "สร้างกฎข้อมูลที่ละเอียดอ่อนใหม่",
  "cobrowse.sensitiveinfo.edit": "แก้ไขกฎข้อมูลที่ละเอียดอ่อนใหม่",
  "cobrowse.sensitiveinfo.createnameexist": "มีชื่ออยู่แล้ว",
  "cobrowse.sensitiveinfo.exceedmaxcount": "สามารถกำหนดค่ากฎที่ละเอียดอ่อนได้มากถึง 100 กฎต่อผู้เช่าหนึ่งราย",
  "cobrowse.sensitiveinfo.delete": "กฎการลบข้อมูลที่ละเอียดอ่อน",
  "cobrowse.sensitiveinfo.willdeletetips": "คุณแน่ใจหรือไม่ว่าต้องการลบกฎข้อมูลที่ละเอียดอ่อนที่เลือก",
  "cobrowse.sensitiveinfo.deletenum.outstripping": "สามารถลบบันทึกได้มากที่สุด 100 บันทึกในแต่ละครั้ง",
  "cobrowse.common.tips.selectempty": "กรุณาเลือกข้อมูลเพื่อดำเนินการก่อน",
  "cobrowse.sessionrecord.workNo.rules": "ต้องอยู่ในช่วง 1 - 65535",
  "ccmessaging.chat.chatconfig.batch.delete": "การลบจํานวนมาก",
  "ccmessaging.chat.chatconfig.create": "ใหม่",
  "ccmessaging.channelconfig.export.datascope": "ขอบเขตข้อมูล",
  "ccmessaging.channelconfig.export.allscope": "ทั้งหมด",
  "ccmessaging.channelconfig.export.curPage": "หน้าปัจจุบัน",
  "ccmessaging.channelconfig.export.customField": "สนามที่กําหนดเอง",
  "ccmessaging.channelconfig.export.selectedField": "ส่งออกสนาม",
  "ccmessaging.channelconfig.export.selectAll": "ทั้งหมด",
  "ccmessaging.channelconfig.export.zipPwd": "รหัสผ่านที่บีบอัด",
  "ccmessaging.channelconfig.export.zipPwdHint": "โปรดป้อนรหัสผ่านการบีบอัด",
  "ccmessaging.channelconfig.export.pwdMsg": "โปรดป้อนรหัสผ่านที่ใช้สร้างแฟ้มบีบอัด รหัสผ่านที่บีบอัดควรประกอบด้วย 8-12 อักขระ, ประกอบด้วยตัวอักษร, ตัวเลข, อักขระพิเศษ , อักขระพิเศษไม่รวม {'|;&$-<>/'}{'\\\\'}{'`!#(){}'} ช่องว่าง, อักขระและแท็บบรรทัดใหม่",
  'ccmessaging.channelconfig.export.pwdMsgError': "8-12 อักขระ, ซึ่งต้องมีตัวอักษร , ตัวเลข , อักขระพิเศษ , อักขระพิเศษไม่รวม {'|;&$-<>/'}{'\\\\'}{'`!#(){}'} ช่องว่าง, อักขระและแท็บบรรทัดใหม่",
  "ccmessaging.channelconfig.export": "ส่งออก",
  "ccmessaging.channelconfig.viewExportTask": "ส่งออกมุมมองงาน",
  "ccmessaging.channelconfig.export.title": "ส่งออกการกําหนดค่าช่องสัญญาณ",
  'ccmessaging.channelconfig.export.exportAll': 'คุณยังไม่ได้เลือกเร็กคอร์ดที่ต้องการ ส่งออกบันทึกทั้งหมดหรือไม่? คุณยังสามารถยกเลิกการดําเนินการและเลือกเร็กคอร์ดที่ต้องการส่งออกได้',
  'ccmessaging.channelconfig.export.exportAllTitle': "ส่งออกการตั้งค่าทั้งหมด",
  "ccmessaging.channelconfig.export.allExportConfirm": "ส่งออกทั้งหมด",
  'ccmessaging.channelconfig.export.exportSelect': "คุณได้เลือก {0} เร็กคอร์ด คุณแน่ใจหรือว่าต้องการดําเนินการต่อ?",
  "ccmessaging.channelconfig.export.exportBeginTime": "เวลาเริ่มต้นการส่งออก",
  "ccmessaging.channelconfig.export.exportEndTime": "ส่งออกเวลาสิ้นสุด",
  "ccmessaging.channelconfig.export.exportTaskStatus": "สถานะ",
  "ccmessaging.channelconfig.export.exporting": "การส่งออก",
  "ccmessaging.channelconfig.export.unprocessed": "ยังไม่ได้ดำเนินการ",
  'ccmessaging.channelconfig.export.resultMsg': 'สร้างงานส่งออกสําเร็จแล้ว คุณต้องการดูรายการส่งออกหรือไม่?',
  "ccmessaging.channelconfig.export.resultSuccess": "สร้างสำเร็จ",
  "ccmessaging.channelconfig.export.exception": "เกิดข้อผิดพลาดขณะสร้างงานส่งออก ตรวจสอบบันทึก",
  "ccmessaging.channelconfig.export.error": "งานส่งออกใหม่ล้มเหลวกรุณาตรวจสอบการบันทึก",
  "ccmessaging.channelconfig.export.paramInvalid": "ยืนยันเงื่อนไขการส่งออกไม่สำเร็จ",
  "ccmessaging.channelconfig.export.exceedlimit": "สามารถส่งออกบันทึกข้อมูลได้สูงสุด {0} รายการ",
  "ccmessaging.channelconfig.export.nodata": "ไม่มีการกําหนดค่าช่องสัญญาณที่ตรงตามเงื่อนไข",
  "ccmessaging.chat.holiday.deleteWorkDay": "ลบวันที่ที่ระบุ",
  "ccmessaging.chat.holiday.deleteAllWorkDay": "ลบวันที่ทํางานที่ระบุทั้งหมด",
  "ccmessaging.chat.holiday.deleteHoliday": "ลบวันพักที่ระบุ",
  "ccmessaging.chat.holiday.deleteAllHoliday": "ลบวันพักที่ระบุทั้งหมด",
  "ccmessaging.multimedia.leftMenu.category": 'หมวดหมู่',
  "ccmessaging.multimedia.leftMenu.createCategory": 'สร้างหมวดหมู่',
  "ccmessaging.multimedia.leftMenu.editCategory": 'แก้ไขหมวดหมู่',
  "ccmessaging.multimedia.audio.create": 'สร้างเสียง',
  "ccmessaging.multimedia.audio.edit": 'แก้ไขเสียง',
  "ccmessaging.multimedia.video.create": 'สร้างวิดีโอ',
  "ccmessaging.multimedia.video.edit": 'แก้ไขวิดีโอ',
  "ccmessaging.multimedia.image.create": 'สร้างรูปภาพ',
  "ccmessaging.multimedia.image.edit": 'แก้ไขรูปภาพ',
  "ccmessaging.multimedia.emoticon.create": 'สร้างอีโมติคอน',
  "ccmessaging.multimedia.emoticon.edit": 'แก้ไขอีโมติคอน',
  "ccmessaging.multimedia.document.create": 'แก้ไขอีโมติคอน',
  "ccmessaging.multimedia.document.edit": 'แก้ไขเอกสาร',
  "ccmessaging.multimedia.richText.create": 'สร้าง Rich Text',
  "ccmessaging.multimedia.richText.edit": 'แก้ไข Rich Text',
  "ccmessaging.multimedia.local.create": 'สร้างที่อยู่',
  "ccmessaging.multimedia.local.edit": 'แก้ไขที่อยู่',
  'ccmessaging.multimedia.whatsAppTemplate.create': 'สร้างแม่แบบ WhatsApp',
  'ccmessaging.multimedia.whatsAppTemplate.edit': 'แก้ไขแม่แบบ WhatsApp',
  "ccmessaging.multimedia.siteAddr.create": 'การสร้างการทํางานร่วมกันบนเว็บเพจ',
  "ccmessaging.multimedia.siteAddr.edit": 'แก้ไขการทํางานร่วมกันบนเว็บเพจ',
  "ccmessaging.multimedia.cardFiveG.create": 'สร้างการ์ด 5G',
  "ccmessaging.multimedia.cardFiveG.edit": 'แก้ไขการ์ด 5G',
  "ccmessaging.multimedia.carouselCardFiveG.create": 'สร้างการ์ดม้าหมุน 5G',
  "ccmessaging.multimedia.carouselCardFiveG.edit": 'แก้ไขการ์ดม้าหมุน 5G',
  'ccmessaging.multimedia.whatsAppTemplateMsg.create': 'สร้างข้อความแม่แบบ WhatsApp',
  'ccmessaging.multimedia.whatsAppTemplateMsg.edit': 'แก้ไขข้อความแม่แบบ WhatsApp',
  "ccmessaging.multimedia.cardWeb.create": 'สร้างเว็บการ์ด',
  "ccmessaging.multimedia.cardWeb.edit": 'แก้ไขเว็บการ์ด',
  "chat.agent.qualityCheckWorkbench.title": "การตรวจสอบคุณภาพ",
  "chat.agent.qualityCheckWorkbench.currentAgent": "ที่นั่งตรวจสอบปัจจุบัน",
  "chat.agent.qualityCheckWorkbench.subscribeSessionFailed": "การสมัครรับข้อมูลวาระงานพร็อกซีล้มเหลว",
  "chat.agent.qualityCheckWorkbench.cancelSubscribeSessionFailed": "การยกเลิกการเป็นสมาชิกวาระงานพร็อกซีล้มเหลว",
  "chat.agent.qualityCheckWorkbench.pollMsgFailed": "การรับข้อมูลที่นั่งล้มเหลว",
  "chat.agent.qualityCheckWorkbench.closeConfirm": "โปรดยืนยันว่าการตรวจสอบคุณภาพการสนทนามัลติมีเดียในที่นั่งปัจจุบันสิ้นสุดลงแล้วใช่หรือไม่",
  "chat.agent.qualityCheckWorkbench.listen": "ยกเลิกการแทรก",
  "chat.agent.qualityCheckWorkbench.insert": "การแทรก",
  "chat.agent.qualityCheckWorkbench.intercept": "การสกัดกั้น",
  "chat.agent.qualityCheckWorkbench.changeQualityStatus.fail": "การแก้ไขสถานะการตรวจสอบคุณภาพล้มเหลว",
  "chat.agent.msg.redis.connection.exception.description": "ขณะนี้ระบบขัดข้อง โปรดงดเว้น",
  "ccmessaging.chat.phrase.editPhraseTypeFail": "ล้มเหลวในการแก้ไขประเภทวลี",
  "ccmessaging.chat.phrase.editPhraseTypeSucess": "แก้ไขประเภทวลีสําเร็จแล้ว",
  "ccmessaging.chat.phrase.editPhraseFail": "แก้ไขข้อความสามัญไม่สําเร็จ",
  "ccmessaging.chat.phrase.editPhraseSucess": "แก้ไขข้อความสามัญสําเร็จแล้ว",
  "ccmessaging.chat.multimedia.editMultiMediaInfo": "แก้ไขข้อมูลมัลติมีเดียสําเร็จแล้ว",
  "ccmessaging.chat.multimedia.editMultiMediaInfoError": "การแก้ไขข้อมูลมัลติมีเดียล้มเหลว",
  "ccmessaging.chat.multimedia.editMultiMediaType": "แก้ไขประเภทมัลติมีเดียสําเร็จแล้ว",
  "ccmessaging.chat.multimedia.editMultiMediaTypeError": "การแก้ไขประเภทมัลติมีเดียล้มเหลว",
  "ccmessaging.chat.multimedia.createMultiMediaInfo": "สร้างข้อมูลมัลติมีเดียสําเร็จ",
  "ccmessaging.chat.multimedia.createMultiMediaInfoError": "สร้างข้อมูลมัลติมีเดียไม่สําเร็จ",
  "ccmessaging.chat.multimedia.createMultiMediaType": "สร้างประเภทมัลติมีเดียสําเร็จ",
  "ccmessaging.chat.multimedia.createMultiMediaTypeError": "สร้างชนิดมัลติมีเดียไม่สําเร็จ",
  "ccmessaging.chat.phrase.createTypeSuccess": "สร้างประเภทข้อความสามัญสําเร็จแล้ว",

  "ccmessaging.chat.multimedia.createVideoInfo": "สร้างข้อมูลวิดีโอสําเร็จแล้ว",
  "ccmessaging.chat.multimedia.createVideoInfoError": "สร้างข้อมูลวิดีโอไม่สําเร็จ",
  "ccmessaging.chat.multimedia.createVideoType": "สร้างประเภทวิดีโอสําเร็จแล้ว",
  "ccmessaging.chat.multimedia.createVideoTypeError": "สร้างชนิดวิดีโอไม่สําเร็จ",
  "ccmessaging.chat.multimedia.editVideoInfo": "แก้ไขข้อมูลวิดีโอสําเร็จแล้ว",
  "ccmessaging.chat.multimedia.editVideoInfoError": "การแก้ไขข้อมูลวิดีโอล้มเหลว",
  "ccmessaging.chat.multimedia.editVideoType": "แก้ไขประเภทวิดีโอสําเร็จแล้ว",
  "ccmessaging.chat.multimedia.editVideoTypeError": "การแก้ไขประเภทวิดีโอล้มเหลว",
  "ccmessaging.chat.multimedia.deleteVideoInfo": "ลบข้อมูลวิดีโอสําเร็จแล้ว",
  "ccmessaging.chat.multimedia.deleteVideoInfoError": "ลบข้อมูลวิดีโอไม่สําเร็จ",
  "ccmessaging.chat.multimedia.deleteVideoType": "ลบประเภทวิดีโอสําเร็จแล้ว",
  "ccmessaging.chat.multimedia.deleteVideoTypeError": "ลบชนิดวิดีโอไม่สําเร็จ",
  "ccmessaging.chat.multimedia.deleteVideoTypeError.usedbyODFS": "ลบประเภทวิดีโอไม่สําเร็จ มีทรัพยากรที่ IVR อัจฉริยะอ้างอิงอยู่",
  "ccmessaging.chat.multimedia.deleteVideoTypeError.referenced.byCard": "ลบประเภทวิดีโอไม่สําเร็จ เนื่องจากการ์ดบางตัวมีการอ้างอิงทรัพยากรอยู่แล้ว",
  "ccmessaging.chat.multimedia.deleteVideoTypeError.referenced.byWhatsAppTemplateMsg": "ลบชนิดวิดีโอไม่สําเร็จ เนื่องจากข้อความแม่แบบ WhatsApp อ้างอิงทรัพยากรบางรายการอยู่แล้ว",
  "ccmessaging.chat.multimedia.createAudioInfo": "สร้างข้อมูลเสียงสําเร็จ",
  "ccmessaging.chat.multimedia.createAudioInfoError": "สร้างข้อมูลเสียงไม่สําเร็จ",
  "ccmessaging.chat.multimedia.createAudioType": "สร้างประเภทเสียงสําเร็จ",
  "ccmessaging.chat.multimedia.createAudioTypeError": "สร้างชนิดเสียงไม่สําเร็จ",
  "ccmessaging.chat.multimedia.editAudioInfo": "แก้ไขข้อมูลเสียงสําเร็จแล้ว",
  "ccmessaging.chat.multimedia.editAudioInfoError": "การแก้ไขข้อมูลเสียงล้มเหลว",
  "ccmessaging.chat.multimedia.editAudioType": "แก้ไขประเภทเสียงสําเร็จแล้ว",
  "ccmessaging.chat.multimedia.editAudioTypeError": "การแก้ไขประเภทเสียงล้มเหลว",
  "ccmessaging.chat.multimedia.deleteAudioInfo": "ลบข้อมูลเสียงสําเร็จแล้ว",
  "ccmessaging.chat.multimedia.deleteAudioInfoError": "ลบข้อมูลเสียงไม่สําเร็จ",
  "ccmessaging.chat.multimedia.deleteAudioType": "ลบประเภทเสียงสําเร็จแล้ว",
  "ccmessaging.chat.multimedia.deleteAudioTypeError": "ลบชนิดเสียงไม่สําเร็จ",
  "ccmessaging.chat.multimedia.deleteAudioTypeError.usedbyODFS": "ลบประเภทเสียงไม่สําเร็จ มีทรัพยากรที่ IVR อัจฉริยะอ้างอิงอยู่",
  "ccmessaging.chat.multimedia.deleteAudioTypeError.referenced.byCard": "ลบประเภทเสียงไม่สําเร็จ เนื่องจากการ์ดบางตัวอ้างอิงทรัพยากรอยู่แล้ว",
  "ccmessaging.chat.multimedia.deleteAudioTypeError.referenced.byWhatsAppTemplateMsg": "ลบชนิดเสียงไม่สําเร็จ เนื่องจากข้อความแม่แบบ WhatsApp มีการอ้างอิงทรัพยากรบางรายการอยู่แล้ว",
  "ccmessaging.chat.multimedia.createImageInfo": "สร้างข้อมูลภาพสําเร็จแล้ว",
  "ccmessaging.chat.multimedia.createImageInfoError": "สร้างข้อมูลภาพไม่สําเร็จ",
  "ccmessaging.chat.multimedia.createImageType": "สร้างประเภทของภาพสําเร็จแล้ว",
  "ccmessaging.chat.multimedia.createImageTypeError": "สร้างชนิดภาพไม่สําเร็จ",
  "ccmessaging.chat.multimedia.editImageInfo": "แก้ไขข้อมูลภาพสําเร็จแล้ว",
  "ccmessaging.chat.multimedia.editImageInfoError": "การแก้ไขข้อมูลภาพล้มเหลว",
  "ccmessaging.chat.multimedia.editImageType": "แก้ไขประเภทของภาพสําเร็จแล้ว",
  "ccmessaging.chat.multimedia.editImageTypeError": "การแก้ไขชนิดของภาพล้มเหลว",
  "ccmessaging.chat.multimedia.deleteImageInfo": "ลบข้อมูลภาพสําเร็จแล้ว",
  "ccmessaging.chat.multimedia.deleteImageInfoError": "ลบข้อมูลภาพไม่สําเร็จ",
  "ccmessaging.chat.multimedia.deleteImageType": "ลบประเภทภาพสําเร็จแล้ว",
  "ccmessaging.chat.multimedia.deleteImageTypeError": "ล้มเหลวในการลบประเภทภาพ",
  "ccmessaging.chat.multimedia.deleteImageTypeError.usedbyODFS": "ลบประเภทภาพไม่สําเร็จ มีทรัพยากรที่ IVR อัจฉริยะอ้างอิงอยู่",
  "ccmessaging.chat.multimedia.deleteImageTypeError.referenced.byCard": "ลบประเภทภาพไม่สําเร็จ เนื่องจากการ์ดบางตัวอ้างอิงทรัพยากรอยู่แล้ว",
  "ccmessaging.chat.multimedia.deleteImageTypeError.referenced.byWhatsAppTemplateMsg": "ลบชนิดภาพไม่สําเร็จ เนื่องจากข้อความแม่แบบ WhatsApp อ้างอิงทรัพยากรบางรายการอยู่แล้ว",
  "ccmessaging.chat.multimedia.createDocumentInfo": "สร้างข้อมูลเอกสารสําเร็จแล้ว",
  "ccmessaging.chat.multimedia.createDocumentInfoError": "สร้างข้อมูลเอกสารไม่สําเร็จ",
  "ccmessaging.chat.multimedia.createDocumentType": "สร้างประเภทเอกสารสําเร็จ",
  "ccmessaging.chat.multimedia.createDocumentTypeError": "สร้างชนิดเอกสารไม่สําเร็จ",
  "ccmessaging.chat.multimedia.editDocumentInfo": "แก้ไขข้อมูลเอกสารสําเร็จแล้ว",
  "ccmessaging.chat.multimedia.editDocumentInfoError": "การแก้ไขข้อมูลเอกสารล้มเหลว",
  "ccmessaging.chat.multimedia.editDocumentType": "แก้ไขประเภทเอกสารสําเร็จแล้ว",
  "ccmessaging.chat.multimedia.editDocumentTypeError": "การแก้ไขประเภทเอกสารล้มเหลว",
  "ccmessaging.chat.multimedia.deleteDocumentInfo": "ลบข้อมูลเอกสารสําเร็จแล้ว",
  "ccmessaging.chat.multimedia.deleteDocumentInfoError": "ลบข้อมูลเอกสารไม่สําเร็จ",
  "ccmessaging.chat.multimedia.deleteDocumentType": "ลบประเภทเอกสารสําเร็จแล้ว",
  "ccmessaging.chat.multimedia.deleteDocumentTypeError": "ลบประเภทเอกสารไม่สําเร็จ",
  "ccmessaging.chat.multimedia.deleteDocumentTypeError.usedbyODFS": "ลบประเภทเอกสารไม่สําเร็จ มีทรัพยากรที่ IVR อัจฉริยะอ้างอิงอยู่",
  "ccmessaging.chat.multimedia.deleteDocumentTypeError.referenced.byCard": "ลบประเภทเอกสารไม่สําเร็จ เนื่องจากการ์ดบางตัวอ้างอิงทรัพยากรอยู่แล้ว",
  "ccmessaging.chat.multimedia.deleteDocumentTypeError.referenced.byWhatsAppTemplateMsg": "ลบประเภทเอกสารไม่สําเร็จ เนื่องจากข้อความแม่แบบ WhatsApp มีการอ้างอิงทรัพยากรบางรายการอยู่แล้ว",
  "ccmessaging.chat.multimedia.createLocateInfo": "สร้างข้อมูลตําแหน่งสําเร็จแล้ว",
  "ccmessaging.chat.multimedia.createLocateInfoError": "สร้างข้อมูลตําแหน่งไม่สําเร็จ",
  "ccmessaging.chat.multimedia.createLocateType": "สร้างประเภทสถานที่สําเร็จแล้ว",
  "ccmessaging.chat.multimedia.createLocateTypeError": "สร้างประเภทสถานที่ไม่สําเร็จ",
  "ccmessaging.chat.multimedia.editLocateInfo": "แก้ไขข้อมูลตําแหน่งสําเร็จแล้ว",
  "ccmessaging.chat.multimedia.editLocateInfoError": "การแก้ไขข้อมูลตําแหน่งที่ตั้งล้มเหลว",
  "ccmessaging.chat.multimedia.editLocateType": "แก้ไขประเภทสถานที่สําเร็จแล้ว",
  "ccmessaging.chat.multimedia.editLocateTypeError": "การแก้ไขประเภทตําแหน่งที่ตั้งล้มเหลว",
  "ccmessaging.chat.multimedia.deleteLocateInfo": "ลบข้อมูลตําแหน่งที่ตั้งสําเร็จแล้ว",
  "ccmessaging.chat.multimedia.deleteLocateInfoError": "ลบข้อมูลตําแหน่งที่ตั้งไม่สําเร็จ",
  "ccmessaging.chat.multimedia.deleteLocateType": "ลบประเภทสถานที่สําเร็จแล้ว",
  "ccmessaging.chat.multimedia.deleteLocateTypeError": "ลบประเภทสถานที่ไม่สําเร็จ",
  "ccmessaging.chat.multimedia.deleteLocateTypeError.usedbyODFS": "ลบประเภทสถานที่ไม่สําเร็จ มีทรัพยากรที่ IVR อัจฉริยะอ้างอิงอยู่",
  "ccmessaging.chat.multimedia.deleteLocateTypeError.referenced.byCard": "ลบประเภทตําแหน่งที่ตั้งไม่สําเร็จ เนื่องจากมีการ์ดอ้างอิงทรัพยากรบางรายการอยู่แล้ว",
  "ccmessaging.chat.multimedia.deleteLocateTypeError.referenced.byWhatsAppTemplateMsg": "ลบประเภทตําแหน่งที่ตั้งไม่สําเร็จ เนื่องจากข้อความแม่แบบ WhatsApp มีการอ้างอิงทรัพยากรบางรายการอยู่แล้ว",
  "ccmessaging.chat.multimedia.createEmotionInfo": "สร้างข้อมูลอีโมติคอนสําเร็จ",
  "ccmessaging.chat.multimedia.createEmotionInfoError": "สร้างข้อมูลอีโมติคอนไม่สําเร็จ",
  "ccmessaging.chat.multimedia.createEmotionType": "สร้างประเภทอีโมติคอนสําเร็จแล้ว",
  "ccmessaging.chat.multimedia.createEmotionTypeError": "สร้างประเภทอิโมติคอนไม่สําเร็จ",
  "ccmessaging.chat.multimedia.editEmotionInfo": "แก้ไขข้อมูลอีโมติคอนสําเร็จแล้ว",
  "ccmessaging.chat.multimedia.editEmotionInfoError": "การแก้ไขข้อมูลอิโมติคอนล้มเหลว",
  "ccmessaging.chat.multimedia.editEmotionType": "แก้ไขประเภทอีโมติคอนสําเร็จแล้ว",
  "ccmessaging.chat.multimedia.editEmotionTypeError": "การแก้ไขประเภทอิโมติคอนล้มเหลว",
  "ccmessaging.chat.multimedia.deleteEmotionInfo": "ลบข้อมูลอิโมติคอนเรียบร้อยแล้ว",
  "ccmessaging.chat.multimedia.deleteEmotionInfoError": "ลบข้อมูลอิโมติคอนไม่สําเร็จ",
  "ccmessaging.chat.multimedia.deleteEmotionType": "ลบประเภทอิโมติคอนเรียบร้อยแล้ว",
  "ccmessaging.chat.multimedia.deleteEmotionTypeError": "ลบประเภทอิโมติคอนไม่สําเร็จ",
  "ccmessaging.chat.multimedia.deleteEmotionTypeError.usedbyODFS": "ลบประเภทอิโมติคอนไม่สําเร็จ มีทรัพยากรที่ IVR อัจฉริยะอ้างอิงอยู่",
  "ccmessaging.chat.multimedia.deleteEmotionTypeError.referenced.byCard": "ลบประเภทอิโมติคอนไม่สําเร็จ เนื่องจากการ์ดบางตัวมีการอ้างอิงทรัพยากรอยู่แล้ว",
  "ccmessaging.chat.multimedia.deleteEmotionTypeError.referenced.byWhatsAppTemplateMsg": "ลบประเภทอิโมติคอนไม่สําเร็จ เนื่องจากข้อความแม่แบบ WhatsApp อ้างอิงทรัพยากรบางรายการอยู่แล้ว",
  "ccmessaging.chat.multimedia.createRichTextInfo": "สร้างข้อมูล Rich Text สําเร็จ",
  "ccmessaging.chat.multimedia.createRichTextInfoError": "สร้างข้อมูล Rich Text ไม่สําเร็จ",
  "ccmessaging.chat.multimedia.createRichTextType": "สร้างประเภท Rich Text สําเร็จแล้ว",
  "ccmessaging.chat.multimedia.createRichTextTypeError": "สร้างชนิด Rich Text ไม่สําเร็จ",
  "ccmessaging.chat.multimedia.editRichTextInfo": "แก้ไขข้อมูล Rich Text สําเร็จแล้ว",
  "ccmessaging.chat.multimedia.editRichTextInfoError": "การแก้ไขข้อมูล Rich Text ล้มเหลว",
  "ccmessaging.chat.multimedia.editRichTextType": "แก้ไขประเภท Rich Text สําเร็จแล้ว",
  "ccmessaging.chat.multimedia.editRichTextTypeError": "การแก้ไขประเภท Rich Text ล้มเหลว",
  "ccmessaging.chat.multimedia.deleteRichTextInfo": "ลบข้อมูล Rich Text เรียบร้อยแล้ว",
  "ccmessaging.chat.multimedia.deleteRichTextInfoError": "ลบข้อมูล Rich Text ไม่สําเร็จ",
  "ccmessaging.chat.multimedia.deleteRichTextType": "ลบประเภท Rich Text เรียบร้อยแล้ว",
  "ccmessaging.chat.multimedia.deleteRichTextTypeError": "ลบประเภท Rich Text ไม่สําเร็จ",
  "ccmessaging.chat.multimedia.deleteRichTextTypeError.usedbyODFS": "ลบประเภท Rich Text ไม่สําเร็จ มีทรัพยากรที่ IVR อัจฉริยะอ้างอิงอยู่",
  "ccmessaging.chat.multimedia.deleteRichTextTypeError.referenced.byCard": "ลบประเภท Rich Text ไม่สําเร็จ เนื่องจากการ์ดบางตัวอ้างอิงทรัพยากรอยู่แล้ว",
  "ccmessaging.chat.multimedia.deleteRichTextTypeError.referenced.byWhatsAppTemplateMsg": "ลบประเภท Rich Text ไม่สําเร็จ เนื่องจากข้อความแม่แบบ WhatsApp มีการอ้างอิงทรัพยากรบางรายการอยู่แล้ว",
  "ccmessaging.chat.multimedia.createSiteAddrInfo": "สร้างข้อมูลที่อยู่การทํางานร่วมกันบนเว็บเพจสําเร็จแล้ว",
  "ccmessaging.chat.multimedia.createSiteAddrInfoError": "สร้างข้อมูลที่อยู่การทํางานร่วมกันบนเว็บเพจไม่สําเร็จ",
  "ccmessaging.chat.multimedia.createSiteAddrType": "สร้างประเภทที่อยู่สําหรับการทํางานร่วมกันบนเว็บเพจสําเร็จแล้ว",
  "ccmessaging.chat.multimedia.createSiteAddrTypeError": "สร้างชนิดที่อยู่สําหรับการทํางานร่วมกันบนเว็บเพจไม่สําเร็จ",
  "ccmessaging.chat.multimedia.editSiteAddrInfo": "แก้ไขข้อมูลที่อยู่การทํางานร่วมกันบนเว็บเพจสําเร็จแล้ว",
  "ccmessaging.chat.multimedia.editSiteAddrInfoError": "การแก้ไขข้อมูลที่อยู่การทํางานร่วมกันบนเว็บเพจล้มเหลว",
  "ccmessaging.chat.multimedia.editSiteAddrType": "แก้ไขประเภทที่อยู่สําหรับการทํางานร่วมกันบนเว็บเพจสําเร็จแล้ว",
  "ccmessaging.chat.multimedia.editSiteAddrTypeError": "การแก้ไขชนิดที่อยู่สําหรับการทํางานร่วมกันของเว็บเพจล้มเหลว",
  "ccmessaging.chat.multimedia.deleteSiteAddrInfo": "ลบข้อมูลที่อยู่การทํางานร่วมกันบนเว็บเพจสําเร็จแล้ว",
  "ccmessaging.chat.multimedia.deleteSiteAddrInfoError": "ลบข้อมูลที่อยู่การทํางานร่วมกันบนเว็บเพจไม่สําเร็จ",
  "ccmessaging.chat.multimedia.deleteSiteAddrType": "ลบประเภทที่อยู่สําหรับการทํางานร่วมกันบนเว็บเพจสําเร็จแล้ว",
  "ccmessaging.chat.multimedia.deleteSiteAddrTypeError": "ลบประเภทที่อยู่สําหรับการทํางานร่วมกันบนเว็บเพจสําเร็จแล้ว",
  "ccmessaging.chat.multimedia.deleteSiteAddrTypeError.usedbyODFS": "ลบชนิดที่อยู่การทํางานร่วมกันบนเว็บเพจไม่สําเร็จ มีทรัพยากรที่ IVR อัจฉริยะอ้างอิงอยู่",
  "ccmessaging.chat.multimedia.deleteSiteAddrTypeError.referenced.byCard": "ลบประเภทที่อยู่การทํางานร่วมกันบนเว็บเพจไม่สําเร็จ เนื่องจากการ์ดมีการอ้างอิงทรัพยากรบางรายการอยู่แล้ว",
  "ccmessaging.chat.multimedia.deleteSiteAddrTypeError.referenced.byWhatsAppTemplateMsg": "ลบชนิดที่อยู่การทํางานร่วมกันของเว็บเพจไม่สําเร็จ เนื่องจากข้อความแม่แบบ WhatsApp มีการอ้างอิงทรัพยากรบางรายการอยู่แล้ว",
  "ccmessaging.chat.chatcard.createFiveGCardFail": "สร้างการ์ด 5G ไม่สําเร็จ",
  "ccmessaging.chat.chatcard.createFiveGCardSuccess": "สร้างการ์ด 5G สําเร็จแล้ว",
  "ccmessaging.chat.chatcard.editFiveGCardFail": "การแก้ไขการ์ด 5G ล้มเหลว",
  "ccmessaging.chat.chatcard.editFiveGCardSuccess": "แก้ไขการ์ด 5G เรียบร้อยแล้ว",
  "ccmessaging.chat.chatcard.deleteFiveGCardFail": "ลบการ์ด 5G ไม่สําเร็จ",
  "ccmessaging.chat.chatcard.deleteFiveGCardSuccess": "ลบการ์ด 5G เรียบร้อยแล้ว",
  "ccmessaging.chat.chatcard.createFiveGCarouselFail": "สร้าง Carouse 5G ไม่สําเร็จ",
  "ccmessaging.chat.chatcard.createFiveGCarouselSuccess": "สร้าง Carouse 5G สําเร็จแล้ว",
  "ccmessaging.chat.chatcard.editFiveGCarouselFail": "การแก้ไข Carouse 5G ล้มเหลว",
  "ccmessaging.chat.chatcard.editFiveGCarouselSuccess": "แก้ไข Carouse 5G สําเร็จแล้ว",
  "ccmessaging.chat.chatcard.deleteFiveGCarouselFail": "ลบ Carouse 5G ไม่สําเร็จ",
  "ccmessaging.chat.chatcard.deleteFiveGCarouselSuccess": "ลบ Carouse 5G สําเร็จแล้ว",
  "ccmessaging.chat.chatcard.createWhatAppTemplateMeaasgeFail": "การสร้างแม่แบบ WhatsApp Meaasge ล้มเหลว",
  "ccmessaging.chat.chatcard.createWhatAppTemplateMeaasgeSuccess": "สร้างแม่แบบ WhatsApp Meaasge สําเร็จ",
  "ccmessaging.chat.chatcard.editWhatAppTemplateMeaasgeFail": "การแก้ไข Meaasge แม่แบบ WhatsApp ล้มเหลว",
  "ccmessaging.chat.chatcard.editWhatAppTemplateMeaasgeSuccess": "แก้ไข Meaasge แม่แบบ WhatsApp สําเร็จแล้ว",
  "ccmessaging.chat.chatcard.deleteWhatAppTemplateMeaasgeFail": "การลบแม่แบบ WhatsApp Meaasge ล้มเหลว",
  "ccmessaging.chat.chatcard.deleteWhatAppTemplateMeaasgeSuccess": "ลบ Meaasge แม่แบบ WhatsApp สําเร็จแล้ว",
  "ccmessaging.chat.chatcard.createWebCardFail": "สร้าง WebCard ไม่สําเร็จ",
  "ccmessaging.chat.chatcard.createWebCardSuccess": "สร้าง WebCard สําเร็จแล้ว",
  "ccmessaging.chat.chatcard.editWebCardFail": "การแก้ไข WebCard ล้มเหลว",
  "ccmessaging.chat.chatcard.editWebCardSuccess": "แก้ไข WebCard เรียบร้อยแล้ว",
  "ccmessaging.chat.chatcard.deleteWebCardFail": "ลบ WebCard ไม่สําเร็จ",
  "ccmessaging.chat.chatcard.deleteWebCardSuccess": "ลบ WebCard เรียบร้อยแล้ว",
  "ccmessaging.chat.WhatsApp.createWhatsAppTemplateSuccess": "สร้างแม่แบบ WhatsApp สําเร็จ",
  "ccmessaging.chat.WhatsApp.createWhatsAppTemplateFail": "สร้างแม่แบบ WhatsApp ไม่สําเร็จ",
  "ccmessaging.chat.WhatsApp.editWhatsAppTemplateSuccess": "แก้ไขแม่แบบ WhatsApp สําเร็จแล้ว",
  "ccmessaging.chat.WhatsApp.editWhatsAppTemplateFail": "การแก้ไขแม่แบบ WhatsApp ล้มเหลว",
  "ccmessaging.chat.WhatsApp.deleteWhatsAppTemplateSuccess": "ลบแม่แบบ WhatsApp สําเร็จแล้ว",
  "ccmessaging.chat.WhatsApp.deleteWhatsAppTemplateFail": "ลบแม่แบบ WhatsApp ไม่สําเร็จ",
  "ccmessaging.chat.WhatsApp.deleteWhatsAppTemplateFailMsg": "ลบแม่แบบ WhatsApp ไม่สําเร็จ แม่แบบจะถูกอ้างอิงโดยข้อความของแม่แบบ",
  "ccmessaging.multimedia.download.office.risktips":"คุณกําลังจะดาวน์โหลดเอกสาร Office ซึ่งมีความเสี่ยงด้านความปลอดภัย ขอแนะนําให้คุณทําการป้องกันไวรัสเมื่อเปิดใช้งาน การตั้งค่าความปลอดภัยที่เกี่ยวข้องกับซอฟต์แวร์ Office จะถูกปรับเป็นเปิด",
  "ccmessaging.chat.multimedia.documentUploadRiskTips":"เอกสารนี้ใช้สําหรับเจ้าหน้าที่เพื่อส่งให้ผู้ใช้ อย่าอัปโหลดเอกสารที่มีความเสี่ยงด้านความปลอดภัย",
  "ccmessaging.config.names.channels.web": "Web",
  "ccmessaging.config.names.channels.instagram": "Instagram",
  "ccmessaging.config.names.channels.telegram": "Telegram",
  "ccmessaging.config.names.channels.whatsapp": "WhatsApp",
  "ccmessaging.config.names.channels.line": "LINE",
  "ccmessaging.config.names.channels.wechat": "WeChat",
  "ccmessaging.config.names.channels.facebook": "Facebook",
  "ccmessaging.config.names.channels.twitter": "X (Twitter)",
  "ccmessaging.config.names.channels.rcs": "5G RCS",
  "ccmessaging.config.names.channels.email": "Email",
  "ccmessaging.config.names.channels.sms": "SMS",
  "ccmessaging.chat.multimedia.whatsApp.interactiveMsg":"ข้อความโต้ตอบแบบ WhatsApp",
  "ccmessaging.chat.whatsapp.interactiveMsgNamePlaceholder":"โปรดใส่ชื่อข้อความแบบโต้ตอบ",
  "ccmessaging.chat.whatsapp.interactiveMsgName":"ชื่อข้อความ",
  "ccmessaging.chat.whatsapp.interactiveMsgTitle":"คํานําหน้าชื่อ",
  "ccmessaging.chat.whatsapp.interactiveMsgDescription":"คําอธิบายข้อความ",
  "ccmessaging.chat.whatsapp.interactiveMsgDescriptionPlaceholder":"ใส่คําอธิบายข้อความโต้ตอบ",
  "ccmessaging.chat.whatsapp.interactiveMsgType":"ประเภทของข้อความ",
  "ccmessaging.chat.whatsapp.orgName":"ชื่อองค์กร",
  "ccmessaging.chat.chatcard.queryInteractiveMsg":"การสอบถามข้อความแบบโต้ตอบล้มเหลว",
  "ccmessaging.chat.whatsapp.interactiveType.buttons":"ข้อความปุ่ม",
  "ccmessaging.chat.whatsapp.interactiveType.list":"ข้อความรายการ",
  "ccmessaging.chat.whatsapp.delete.interactiveMsg":"ลบข้อความโต้ตอบ",
  "ccmessaging.chat.whatsapp.willdeleteinteractiveMsg":"คุณแน่ใจว่าต้องการลบข้อความโต้ตอบนี้",
  "ccmessaging.chat.whatsapp.interactiveTypes":"ชนิดข้อความโต้ตอบ",
  "ccmessaging.chat.whatsapp.interactiveMsgTitlePlaceholder":"โปรดใส่เนื้อหาหัวเรื่องแบบโต้ตอบ",
  "ccmessaging.chat.whatsapp.interactiveMsg.bodyContent":"เนื้อหา",
  "ccmessaging.chat.whatsapp.interactiveMsg.bodyContentPlaceholder":"โปรดใส่เนื้อหา",
  "ccmessaging.chat.whatsapp.interactiveMsg.footerContent":"ส่วนท้าย",
  "ccmessaging.chat.whatsapp.interactiveMsg.footerContentPlaceholder":"โปรดใส่ส่วนท้าย",
  "ccmessaging.chat.whatsapp.interactiveMsg.pleaseChoose":"โปรดเลือก",
  "ccmessaging.chat.whatsapp.interactiveMsg.attachments":"เอกสารแนบ",
  "ccmessaging.chat.multimedia.whatsApp.interactiveMsg.create":"ข้อความแบบโต้ตอบใหม่",
  "ccmessaging.chat.multimedia.whatsApp.interactiveMsg.update":"แก้ไขข้อความโต้ตอบ",
  "ccmessaging.chat.whatsapp.interactiveType.list.title":"รายการ",
  "ccmessaging.chat.whatsapp.interactiveType.list.titlePlaceholder":"โปรดใส่ชื่อรายการ",
  "ccmessaging.chat.whatsapp.interactiveMsg.openRow.info":"ชื่อกลุ่มว่างเปล่า ไม่สามารถตั้งค่าตัวเลือกได้",
  "ccmessaging.chat.whatsapp.interactiveType.section.titlePlaceholder":"โปรดใส่ชื่อกลุ่ม",
  "ccmessaging.chat.whatsapp.interactiveType.row.titlePlaceholder":"โปรดใส่ชื่อตัวเลือก",
  "ccmessaging.chat.whatsapp.interactiveType.row.descPlaceholder":"โปรดใส่คําอธิบายตัวเลือก",
  "ccmessaging.chat.whatsapp.interactiveMsg.sectionTitle":"โปรดใส่ชื่อรายการ",
  "ccmessaging.chat.whatsapp.interactiveMsg.queryInteractiveError":"สอบถามข้อความแบบโต้ตอบไม่สําเร็จ",
  "ccmessaging.chat.whatsapp.interactiveMsg.createInteractiveError":"การบันทึกข้อความแบบโต้ตอบล้มเหลว ตัวเลือกการจัดกลุ่มไม่สามารถเว้นว่างไว้หลังจากตั้งค่ากลุ่มรายการแล้ว",
  "ccmessaging.chat.whatsapp.interactiveMsg.saveFail.duplicateName":"การบันทึกล้มเหลว มีชื่อข้อความโต้ตอบนี้อยู่แล้ว",
  "ccmessaging.chat.whatsapp.interactiveMsg.noHeader":"ไม่มี",
  "ccmessaging.chat.whatsapp.interactiveMsg.headerContent":"เนื้อหาของส่วนหัว",
  "ccmessaging.chat.whatsapp.interactiveMsg.no.buttons":"การบันทึกข้อความแบบโต้ตอบล้มเหลว ไม่มีปุ่มการตั้งค่า",
  "ccmessaging.chat.whatsapp.interactiveMsg.no.lists":"การบันทึกข้อความแบบโต้ตอบล้มเหลว ไม่มีรายการการตั้งค่า",
  "ccmessaging.chat.multimedia.deleteLimit.whatsapp.interactiveMsg":"ทรัพยากรนี้ถูกอ้างอิงโดย whatsApp และไม่สามารถลบได้ ชื่อข้อความโต้ตอบ:",
  "ccmessaging.chat.whatsapp.interactiveMsg.saveFail.multiMedia.notExist":"การบันทึกล้มเหลว ไม่มีทรัพยากรมัลติมีเดีย",
  "ccmessaging.chat.whatsapp.interactiveMsg.createInteractiveError.repeateButtonContent":"การบันทึกล้มเหลว เนื้อหาของปุ่มจะต้องไม่ซ้ํากัน",
  "ccmessaging.chat.case.title":"Case Title",
  "ccmessaging.chat.case.type":"Case Type",
  "ccmessaging.chat.case.callReason":"Call Reason",
  "ccmessaging.chat.case.puzzled":"puzzled",
  "ccmessaging.chat.case.calm":"calm",
  "ccmessaging.chat.case.happy":"happy",
  "ccmessaging.chat.case.upset":"upset",
  "ccmessaging.chat.case.surprise":"surprise",
  "ccmessaging.chat.case.pleasant":"pleasant",
  "ccmessaging.chat.case.angry":"angry",
  "ccmessaging.chat.case.little.angry":"a little anger",
  "ccmessaging.chat.case.very.angry":"very mad",
  "ccmessaging.chat.case.very.knowledgeId":"1228840300949016576",
  "ccmessaging.chat.case.complaint.yes":"yes",
  "ccmessaging.chat.case.complaint.shi":"yes",
  "ccmessaging.chat.degree.view":"ลูกค้า 360 องศา",
}
