export default {
  "oifde.service.invoke.recordMode.onlyivr" : "تسجيل IVR فقط",
  "oifde.service.invokemethod.strParseInt.resultObj.desc" : "الكائن الذي تم إرجاعه هو عدد صحيح بعد التحويل.",
  "oifde.service.invoke.event.desc" : "تم إرسال الحدث إلى TUC.",
  "oifde.service.invoke.voiceVariable.multiVoice.time.desc" : "متغير الصوت أو النص الذي يتم تضمينه في الاستجابة. يجب تعيين قيمة لهذا المتغير قبل تنفيذ العقدة (بالنسبة لنوع الوقت ، يكون متغير الاستجابة: hh:mm: ss ، إذا كان الإدخال غير صحيح ، يكون تشغيل الصوت غير طبيعي.)",
  "oifde.service.invokemethod.mapGet.parm2.desc" : "مفتاح (يتم دعم نوع السلسلة فقط)",
  "oifde.service.invoke.queryMsg.agentId" : "معرف الموظف",
  "oifde.service.invoke.ttsMRCPVendor" : "معلمة TTS الموسعة",
  "oifde.service.invokemethod.arrayClear.desc" : "حذف كافة العناصر في صفيف",
  "oifde.service.invoke.timeSelect.weekParameter" : "اختيار الأسبوع",
  "oifde.service.invoke.multiVoiceType" : "النوع",
  "oifde.service.invokemethod.arraySubList.desc" : "يقتطع ويعيد جزءًا من صفيف",
  "oifde.common.displayName" : "عقدة مشتركة",
  "oifde.service.invoke.CallTransferRecordFlag" : "تمكين التسجيل",
  "oifde.service.invoke.sourceVariable" : "سلسلة أحرف المصدر",
  "oifde.service.interfaceType.selfDefinedInterface" : "واجهة محددة ذاتيا",
  "oifde.service.invokemethod.arrayAddAll.parm2.desc" : "الموضع المضاف (المقطورة الافتراضية)",
  "oifde.service.invoke.CallTransferRouteValue" : "قيمة مسار تحويل المكالمات",
  "oifde.service.invokemethod.strToLowerCase.desc" : "تحويل أحرف الحروف الكبيرة للسلسلة إلى أحرف صغيرة",
  "oifde.service.invoke.CallTransferType.transferToThirdPartyNumber" : "رقم الطرف الثالث",
  "oifde.service.invoke.voiceType.multi-media" : "الوسائط المتعددة",
  "oifde.service.invoke.textVariable.desc" : "تم إرسال متغير النص إلى chatbot. يجب تعيين قيمة لهذا المتغير قبل تنفيذ العقدة.",
  "oifde.service.invoke.ttsAdvancedSetting.open" : "تمكين",
  "oifde.service.invoke.interfaceId.desc" : "واجهة تم استدعاءها.",
  "oifde.service.invoke.languageType.language9" : "اليونانية",
  "oifde.service.invoke.languageType.language8" : "الألمانية",
  "oifde.service.invoke.languageType.language7" : "الفرنسية",
  "oifde.service.invokemethod.group.value1Title" : "إرجاع كائن",
  "oifde.service.invoke.languageType.language6" : "الفنلندية",
  "oifde.service.invoke.languageType.language5" : "الهولندية",
  "oifde.service.invoke.languageType.language4" : "الدنماركية",
  "oifde.service.invoke.input.desc" : "تم نقل محتوى التعرف الدلالي إلى TUC. يمكن أن يكون المحتوى متغيرًا. إذا كان هذا الحقل فارغًا، فسيتم نقل المحتوى الذي أدخله المستخدم إلى TUC.",
  "oifde.service.invoke.languageType.language3" : "التشيكية",
  "oifde.service.invoke.languageType.language2" : "الصينية (الكانتونية)",
  "oifde.service.invoke.languageType.language1" : "الإنجليزية",
  "oifde.service.invokemethod.mapPut.desc" : "إضافة عنصر إلى خريطة",
  "oifde.service.invoke.languageType.language0" : "بوتونغهوا",
  "oifde.service.operateAsFunction" : "Operat كما الطريقة المقدمة",
  "oifde.service.invoke.asrRecognizeType.normal" : "مشترك",
  "oifde.service.invokemethod.strEndsWith.parm1.desc" : "سلسلة الأحرف المراد التحقق منها",
  "oifde.service.invokemethod.arrayAdd.desc" : "إضافة عنصر إلى صفيف",
  "oifde.service.invokemethod.arrayJoin.resultObj.desc" : "الكائن الذي تم إرجاعه هو سلسلة الأحرف المحولة.",
  "oifde.service.invokemethod.arrayGet.parm2.desc" : "صفيف سفلي",
  "oifde.service.invoke.queryMsg.MessageQueryQueueNumber" : "عدد قوائم الانتظار",
  "oifde.service.invoke.asrRecognitionTimeout" : "الفاصل الزمني لمهلة التعرف",
  "oifde.service.invoke.confidence2Top" : "الفاصل الزمني للثقة",
  "oifde.transforFlowService.displayName" : "نقل التدفق",
  "oifde.service.invoke.timeSelect.serviceTimeParameter.desc" : "فترة الخدمة. قم بتكوين 1 إلى 10 سجلات.",
  "oifde.CircleService.keywords" : "حلقة المنطق",
  "oifde.service.invoke.oblist" : "الحلقات",
  "oifde.service.invoke.voiceVariable.multiVoice.price.desc" : "متغير الصوت أو النص الذي يتم تضمينه في الاستجابة. يجب تعيين قيمة لهذا المتغير قبل تنفيذ العقدة (بالنسبة لنوع السعر ، يكون متغير الاستجابة في تنسيق المحتوى الفعلي لمتغير الاستجابة هو النص الرقمي. الوحدة: دقيقة. إذا كان الإدخال غير صحيح ، يكون تشغيل الصوت غير طبيعي.)",
  "oifde.service.invoke.index.desc" : "عداد.",
  "oifde.service.invoke.queryMsg.MessageQueryType.desc" : "سيناريو تطبيق معلومات قائمة الانتظار: عند تحويل مكالمة إلى قائمة انتظار مهارة، يتم تمكين عملية IVR للاستعلام عن معلومات قائمة الانتظار.",
  "oifde.callTransferService.description" : "تحويل المكالمات (callTransferService)",
  "oifde.service.invokemethod.arrayRemoveAllIdx.desc" : "حذف العناصر المدرجة في الصفيف الموجود في المجموعة المحددة",
  "oifde.service.invoke.cachegroup.keyTitle" : "ذاكرة التخزين المؤقت متغير",
  "oifde.service.invokemethod.mapSize.desc" : "يحصل على عدد جميع مفاتيح الخريطة",
  "oifde.service.invoke.timeSelect.specificWorkDateParameter.desc" : "تاريخ الخدمة المحدد. يتم دعم 50 سجلًا كحد أقصى.",
  "oifde.conditionService.displayName" : "حكم الشرط",
  "oifde.timeSelectService.keywords" : "تحديد الوقت",
  "oifde.service.invoke.ttsAdvancedSetting.close" : "تعطيل",
  "oifde.service.invoke.CallTransferType.transferToMultimediaMessageToSkillQueue" : "رسالة قائمة انتظار المهارات (تحديد الاسم أو رمز الوصول)",
  "oifde.service.invokemethod.mapPut.parm1.desc" : "الخريطة المراد إضافتها (هذه الطريقة تعدل الخريطة المراد إضافتها)",
  "oifde.test.displayName" : "اختبار عينة الخدمة",
  "oifde.service.invoke.voiceVariable.multiVoice.date.desc" : "متغير الصوت أو النص الذي يتم تضمينه في الاستجابة. يجب تعيين قيمة لهذا المتغير قبل تنفيذ العقدة (بالنسبة لنوع التاريخ ، يكون متغير الاستجابة: yyyymmdd ، إذا كان الإدخال غير صحيح ، يكون تشغيل الصوت غير طبيعي.)",
  "oifde.qarecord.inputparam.answerIntent" : "نية الإجابة",
  "oifde.qarecord.inputparam.seriesId" : "معرف السؤال والجواب",
  "oifde.service.invoke.asrAdvancedSetting" : "إعدادات ASR المتقدمة",
  "oifde.service.invoke.CallTransferRouteValue.desc" : "قيمة مسار تحويل المكالمة. قم بتعيين هذه المعلمة استنادًا إلى نوع تحويل المكالمة.",
  'oifde.service.invoke.CallTransferRouteTypePolicy': 'سياسة التوجيه',
  'oifde.service.invoke.CallTransferRoutePolicyType.percentage': 'النسبة المئوية' ,
  'oifde.service.invoke.CallTransferRoutePolicyType.numberSegment': 'مقطع الرقم' ,
  'oifde.service.invoke.CallTransferRouteDefaultSkillName': 'قائمة انتظار المهارات الافتراضية',
  'oifde.service.invoke.CallTransferMatchingType': 'مطابقة القاعدة',
  'oifde.service.invoke. CallTransferMatchingType.prefix': 'البادئة' ,
  'oifde.service.invoke.CallTransferMatchingType.suffix': 'لاحقة',
  'oifde.service.invoke.CallTransferRouteTypePolicy.percentTotalError': 'النسبة المئوية الإجمالية لا تساوي 100' ,
  'oifde.service.invoke.CallTransferRouteTypePolicy.percentValueError':' أدخل عددًا صحيحًا موجبًا من 1 إلى 100' ,
  "oifde.service.invoke.queryMsg.MessageQueryWaitingTime.desc" : "يتم حساب وقت الانتظار المتوقع لقائمة الانتظار استنادًا إلى طراز قائمة الانتظار. والنتيجة مختلفة. يرجى استخدامها بشكل صحيح.",
  "oifde.service.invoke.interfaceType.desc" : "نوع الواجهة.",
  "oifde.service.invoke.voiceVariable" : "متغير الاستجابة",
  "oifde.service.invokemethod.arrayGet.resultObj.desc" : "إرجاع عنصر كائنه هو صفيف.",
  "oifde.service.invoke.recordMode" : "وضع التسجيل",
  "oifde.service.condition.condgroup.valueTitle" : "تعبير الشرط",
  "oifde.service.invoke.queryMsg.specialListQuery" : "معلومات قائمة خاصة",
  "oifde.service.invoke.modelType.desc" : "ما إذا كان يمكن مقاطعة الحوار.",
  "oifde.service.invokemethod.mapGet.parm1.desc" : "الخريطة المراد معالجتها",
  "oifde.service.invoke.CallTransferType.transferToMultimediaMessageToAgent" : "رسالة الوكيل",
  "oifde.errTimesClearService.keywords" : "خلوص عدد الأخطاء",
  "oifde.service.invoke.modelType.voiceAndKeyboard" : "التعرف والضغط على المفاتيح",
  "oifde.service.invoke.maxCollectLength.desc" : "قم بتعيين الحد الأقصى لعدد الأرقام المجمعة.",
  "oifde.service.invoke.voiceVariable.tts.desc" : "متغير الصوت أو النص الذي يتم تضمينه في الاستجابة. يجب تعيين قيمة لهذا المتغير قبل تنفيذ العقدة (بالنسبة لنوع نص الرد، يكون متغير الاستجابة في تنسيق نص TTS. يتم دعم نص TTS واحد فقط.)",
  "oifde.service.invoke.voiceVariable.mix.desc" : "متغير الصوت أو النص الذي يتم تضمينه في الاستجابة. يجب تعيين قيمة لهذا المتغير قبل تنفيذ العقدة (بالنسبة لنوع الصوت والنص ، يكون متغير الاستجابة في عنوان URL لملف التسجيل ~ $ TTS text$ ~ تنسيق عنوان URL لملف التسجيل.)",
  "oifde.commonVoiceService.description" : "الاستجابة (commonResponseService)",
  "oifde.service.invoke.ttsAdvancedSetting" : "إعدادات TTS المتقدمة",
  "oifde.procedureService.displayName" : "الإجراء المخزن",
  "oifde.service.invoke.voiceVariable.voice.desc" : "متغير الصوت أو النص الذي يتم تضمينه في الاستجابة. يجب تعيين قيمة لهذا المتغير قبل تنفيذ العقدة (بالنسبة لنوع الصوت الثابت ، يكون متغير الاستجابة في تنسيق URL لملف التسجيل. يتم دعم عنوان URL واحد فقط.)",
  "oifde.speechRecognitionService.displayName" : "الاعتراف الدلالي",
  "oifde.service.invokemethod.arrayAddAll.parm1.desc" : "الصفيف المراد إضافته (هذه الطريقة تعدل الصفيف المراد إضافته)",
  "oifde.service.condition.condgroup.displayName" : "شروط الحكم",
  "oifde.service.invoke.context.desc" : "يتم نقل معلومات السياق إلى TUC.",
  "oifde.service.invokemethod.arrayAdd.parm3.desc" : "العناصر المضافة",
  "oifde.service.invokemethod.arrayContains.desc" : "تحديد ما إذا كان العنصر موجودًا في صفيف.",
  "oifde.service.invoke.endTime.desc" : "وقت الانتهاء لإرسال رسائل SMS الرضا. التنسيق هو HH:MM:SS. مثال: 23:00:00.",
  "oifde.service.invokemethod.mapGet.resultObj.desc" : "إرجاع القيمة التي تم تعيين الكائن لها للمفتاح المحدد.",
  "oifde.service.invokemethod.strParseFloat.desc" : "تحويل سلسلة إلى رقم فاصلة عائمة",
  "oifde.service.invoke.queryMsg.skillInfo" : "معلومات المهارة",
  "oifde.service.invoke.targetIntentCount.desc" : "أقصى عدد من الأهداف التي يتم إرجاعها بواسطة TUC.",
  "oifde.service.invoke.ttsMRCPVendorSetting.desc" : "بعد تمكين القيم الافتراضية لمعلمات TTS الموسعة، ستقوم التعريفات بالكتابة فوق تأثير الصوت المحدد وسرعة الصوت.",
  "oifde.checkRegularService.keywords" : "التحقق من صحة القاعدة",
  "oifde.service.invokemethod.strLength.resultObj.desc" : "كائن الإرجاع هو طول سلسلة الأحرف. القيمة عدد صحيح.",
  "oifde.speechRecognitionService.keywords" : "الاعتراف الدلالي",
  "oifde.service.invoke.asrAdvancedSetting.close" : "تعطيل",
  "oifde.service.invokemethod.strSubstring.desc" : "استخراج سلاسل فرعية",
  "oifde.start.keywords" : "ابدأ",
  "oifde.service.invoke.offsetType.desc" : "إزاحة حسب اليوم أو الساعة",
  "oifde.service.invoke.modelType.customization.desc" : "يشير المقاطعة إلى ما إذا كان يمكن مقاطعته. إذا كان من الممكن مقاطعته، فقم بملء مربع إدخال الحد الأدنى لوقت التشغيل. تمتلئ المتغيرات مع صواب أو خطأ، صحيح يشير إلى أنه يمكن التعرف عليها وكسرها، ويمكن تمرير الحد الأدنى من وقت التشغيل. يمكن التعرف على خطأ ولكن لا يمكن كسره، وملء الأخطاء هو القيمة الافتراضية لخطأ.",
  "oifde.service.invokemethod.arrayGet.parm1.desc" : "صفيف ليتم استخدامها",
  "oifde.service.invoke.voiceTtsMixed" : "الصوت والنص",
  "oifde.service.invokemethod.strParseFloat.parm1.desc" : "سلسلة الأحرف المراد تحويلها",
  "oifde.service.invoke.offsetType.hour" : "بالساعة",
  "oifde.service.invoke.queryMsg.MessageQueryOnlineNum" : "عدد الوكلاء عبر الإنترنت",
  "oifde.checkRegularService.description" : "التحقق من صحة القواعد (checkRegularService)",
  "oifde.service.invoke.recordType.video" : "الصوت والفيديو",
  "oifde.service.invoke.confirmCollectFlag" : "تأكيد المفتاح",
  "oifde.keyboardRecognitionService.description" : "التعرف على المفاتيح (لوحة المفاتيحRecognitionService)",
  "oifde.service.invoke.recordType" : "نوع التسجيل",
  "oifde.UserLevelService.keywords" : "مستوى المستخدم",
  "oifde.service.invoke.asrRecognitionTimeout.desc" : "الفاصل الزمني عند انتهاء مهلة التعرف على ASR، بالثواني. تتراوح القيمة من 0 إلى 600. القيمة الافتراضية هي 200.",
  "oifde.service.invokemethod.strSubstring.parm3.desc" : "نهاية النص السفلي (عدد صحيح)",
  "oifde.basic.displayName" : "الخدمة الأساسية",
  "oifde.service.invoke.asrMRCPVendor.desc" : "المعلمة الموسعة لخادم ASR. قم بتعيين هذه المعلمة وفقًا لمتطلبات البائع.",
  "oifde.service.invoke.keyBoardType" : "نوع إدخال لوحة المفاتيح",
  "oifde.service.invoke.confirmCollectFlag.yes" : "استخدام مفتاح الجنيه (#)",
  "oifde.service.invokemethod.arrayGet.desc" : "الحصول على عناصر في صفيف حسب قيمة الفهرس",
  "oifde.textVariableToChatbotService.description" : "نص إلى chatbot (textVariableToChatbotService)",
  "oifde.service.invoke.queryMsg.specifiedSkill" : "اسم قائمة الانتظار",
  "oifde.service.invokemethod.strParseJSON.desc" : "تحويل سلسلة أحرف JSON إلى كائن",
  "oifde.service.invokemethod.arraySize.resultObj.desc" : "الكائن الذي تم إرجاعه هو طول صفيف. القيمة عدد صحيح.",
  "oifde.transferService.displayName" : "نقل",
  "oifde.service.invokemethod.arrayRemoveAllIdx.parm2.desc" : "تعيين مجموعة البرنامج النصي للصفيف",
  "oifde.commonBusinessInvokeService.description" : "استدعاء واجهة الأعمال (commonBusinessInvokeService)",
  "oifde.service.invoke.textVariable" : "متغير النص",
  "oifde.service.invoke.ttsSoundEffect.male" : "ذكر",
  "oifde.assignValueService.displayName" : "تعيين",
  "oifde.service.invokemethod.arrayContains.resultObj.desc" : "الكائن الذي تم إرجاعه هو نتيجة الحكم. القيمة هي عدد صحيح من 0 أو 1.",
  "oifde.callTransferService.displayName" : "تحويل المكالمات",
  "oifde.service.invokemethod.arraySubList.resultObj.desc" : "الكائن الذي تم إرجاعه هو subarray.",
  "oifde.errTimesClearService.displayName" : "خلوص عدد الأخطاء",
  "oifde.service.invoke.CallTransferType.transferToSkillQueueByRoutingCode" : "قائمة انتظار المهارة (رمز المسار الموسع)",
  "oifde.service.invoke.operationType.open" : "بدء التسجيل",
  "oifde.service.invoke.CallTransferType.transferToAgentByID" : "الوكيل",
  "oifde.service.invoke.CallTransferCallingNum" : "الرقم المتصل",
  "oifde.service.invokemethod.strSplit.desc" : "تقسيم سلسلة استنادًا إلى المحدد المعطى",
  "oifde.service.invoke.workdays.Saturday" : "السبت",
  "oifde.service.invoke.vendorParam.desc" : "تم إرجاع معلمة التعرف على الصوت الموسعة إلى IVR.",
  "oifde.service.invoke.queryMsg.params" : "طلب معلمة",
  "oifde.service.invoke.voiceSource" : "مصدر الاستجابة",
  "oifde.service.invokemethod.arrayAdd.parm2.desc" : "الموضع المضاف (المقطورة الافتراضية)",
  "oifde.qarecord.inputparam.questionType" : "نوع السؤال",
  "oifde.service.invoke.event" : "الحدث",
  "oifde.service.invoke.destVariable.desc" : "يستخدم متغير سلسلة الهدف لحفظ النتيجة.",
  "oifde.queryMessageService.keywords" : "الاستعلام عن المعلومات",
  "oifde.service.invoke.asrRecognizeType.desc" : "نوع تعريف ASR. يتم دعم التعريف العام فقط حاليًا.",
  "oifde.service.invoke.KeyboardInputRegExp.NumberStar" : "التحقق من صحة رقم الجوال (مع *)",
  "oifde.service.invokemethod.mapRemove.desc" : "حذف زوج محدد من قيم المفاتيح من الخريطة",
  "oifde.service.invokemethod.strParseInt.parm1.desc" : "سلسلة الأحرف المراد تحويلها",
  "oifde.custom.displayName" : "خدمات الأعمال",
  "oifde.service.invoke.interfaceId" : "واجهة تم استدعاءها",
  "oifde.service.invoke.offsetMonths.desc" : "عدد الإزاحة. القيمة هي عدد صحيح موجب أو سالب. قم بتعيين هذه المعلمة استنادًا إلى فترة الإزاحة (الإزاحة حسب الشهر أو الساعة). على سبيل المثال، إذا تم انتقاء الإزاحة حسب الشهر، فإن القيمة -6 تشير إلى أن سلسلة الأحرف المصدر قد تم إرجاعها إلى تلك المستخدمة منذ ستة أشهر.",
  "oifde.service.invokemethod.group.desc" : "طريقة الاستدعاء",
  "oifde.service.invoke.offsetMonths" : "عدد الإزاحة",
  "oifde.service.invoke.operationType" : "نوع العملية",
  "oifde.service.invoke.CallTransferType.transferToIVR" : "نقل إلى IVR",
  "oifde.service.invoke.offsetType.month" : "حسب الشهر",
  "oifde.service.condition.condgroup.keyTitle" : "شرط",
  "oifde.service.invoke.sendCircle.desc" : "الفاصل الزمني لإرسال رسائل SMS الارتياح، في أيام. القيمة عدد صحيح ولا يمكن أن تتجاوز 365.إذا تم تعيين هذه المعلمة على -1، فلن يتم إرسال الرسالة بشكل دوري. يتم تشغيل الرسالة في أي وقت عند إرسال الرسالة إلى النهاية.",
  "oifde.service.invokemethod.arrayClear.resultObj.desc" : "الكائن الذي تم إرجاعه فارغ، ومربع النص باهت ولا يمكن تحريره.",
  "oifde.service.invokemethod.group.mathodType3" : "خريطة طريقة التشغيل",
  "oifde.service.invokemethod.group.mathodType2" : "طريقة تشغيل الصفيف",
  "oifde.service.invokemethod.group.mathodType1" : "طريقة التلاعب سلسلة",
  "oifde.compositeTestService.displayName" : "عينة عنصر الرسم البياني المركب",
  "oifde.service.invoke.voiceType.staticVoice" : "صوت ثابت",
  "oifde.service.invoke.inputVariable.desc" : "متغير لحفظ إدخال لوحة مفاتيح المستخدم.",
  "oifde.UserLevelService.description" : "مستوى المستخدم (UserLevelService)",
  "oifde.service.invoke.workdays.Sunday" : "الأحد",
  "oifde.service.invoke.keyBoardType.desc" : "نوع إدخال لوحة المفاتيح. يمكن أن تكون القيمة keyBoard_phone أو checkoldpassword أو تترك فارغة.",
  "oifde.service.invoke.index" : "عداد",
  "oifde.sendSmsService.keywords" : "إرسال الرسائل القصيرة",
  "oifde.service.invokemethod.strSubstring.parm2.desc" : "بدء النص السفلي (عدد صحيح)",
  "oifde.service.invoke.workdays.Wednesday" : "الأربعاء",
  "oifde.service.invokemethod.strSplit.parm2.desc" : "فاصل",
  "oifde.service.invoke.CallTransferType.transferToSkillQueueBySkillName" : "التحويل إلى الخدمة اليدوية (إلى قائمة انتظار مهارة حسب اسم المهارة)",
  "oifde.service.invokemethod.strIndexOf.parm2.desc" : "سلسلة محددة",
  "oifde.service.invoke.ttsSoundSpeedCust" : "سرعة مخصصة",
  "oifde.service.invoke.asrSpeechCompleteTimeout.desc" : "الفاصل الزمني للمهلة عند توقف المشترك مؤقتًا أثناء التعرف على ASR، بالثواني. تتراوح القيمة من 300 إلى 2000. القيمة الافتراضية هي 500.",
  "oifde.service.invoke.modelType.keyboardBreak" : "انقطاع بواسطة الضغط على المفاتيح",
  "oifde.service.invoke.CallTransferCallingNum.desc" : "يتم استخدام هذه القيمة كرقم الطالب إذا تم تعيين نوع تحويل المكالمة على 5 (تشير إلى أن المكالمة يتم تحويلها إلى طرف ثالث) يجب أن يكون الرقم الطالب رمز وصول مخصص بواسطة النظام أو معلمة مخصصة. مثال: 660001 أو FLOW.accessCode",
  "oifde.checkRegularService.displayName" : "التحقق من صحة القاعدة",
  "oifde.CircleService.description" : "حلقة المنطق (CircleService)",
  "oifde.service.invoke.processRecord.close" : "لا",
  "oifde.service.invoke.CallTransferType.onlineDigitalAssistant" : "مساعد رقمي عبر الإنترنت",
  "oifde.service.invoke.KeyboardInputRegExp.PasswordAll" : "التحقق من صحة تنسيق كلمة المرور (مع # و *)",
  "oifde.service.invoke.voiceType" : "نوع الاستجابة",
  "oifde.service.invoke.CallTransferRecordFlag.desc" : "'', // 本身就为空不展示",
  "oifde.service.invokemethod.mapRemove.parm2.desc" : "مفتاح (يتم دعم نوع السلسلة فقط)",
  "oifde.service.invoke.languageType.language93" : "الماليزية",
  "oifde.service.invoke.languageType.language92" : "الاثيوبيه",
  "oifde.service.invokemethod.arrayRemoveAllIdx.parm1.desc" : "صفيف ليتم استخدامها",
  "oifde.service.invoke.languageType.language90" : "الملغاشية",
  "oifde.service.invoke.complexcellgroupout.name" : "معلمات الإخراج",
  "oifde.callEndService.description" : "إنهاء المكالمة (callEndService)",
  "oifde.dateProcessService.displayName" : "معالجة التاريخ",
  "oifde.service.invoke.recordType.audio" : "الصوت فقط",
  "oifde.service.invoke.object" : "كائن حلقة",
  "oifde.start.description" : "ابدأ",
  "oifde.yugaoRecordService.description" : "تسجيل VoiceCyber (VoiceCyberRecordService)",
  "oifde.service.invoke.cachegroup.valueTitle" : "سمة الاستجابة",
  "oifde.service.invoke.context" : "السياق",
  "oifde.service.invoke.ttsMRCPVendorSetting" : "القيم الافتراضية لمعلمات TTS الموسعة.",
  "oifde.service.invoke.ttsMRCPVendorSetting.close" : "تعطيل",
  "oifde.service.invoke.event.cleanCurrent" : "مسح ذاكرة التخزين المؤقت للسياق الحالي",
  "oifde.service.invokemethod.arrayContains.parm2.desc" : "عناصر يجب الحكم عليها",
  "oifde.service.invokemethod.mapClear.resultObj.desc" : "الكائن الذي تم إرجاعه فارغ، ومربع النص باهت ولا يمكن تحريره.",
  "oifde.service.invoke.cancelCollectFlag.yes" : "استخدم مفتاح النجمة (*)",
  "oifde.service.invokemethod.group.displayName" : "طريقة الاستدعاء",
  "oifde.service.invoke.ttsSoundSpeed" : "سرعة الصوت",
  "oifde.textVariableToChatbotService.displayName" : "نص إلى chatbot",
  "oifde.service.invoke.queryMsg.skillMsg" : "معلومات الانتظار",
  "oifde.service.invoke.languageType.language89" : "النيبالية",
  "oifde.service.invoke.confirmCollectFlag.no" : "لا تستخدم مفتاح الجنيه (#)",
  "oifde.service.invoke.languageType.language88" : "الإندونيسية",
  "oifde.service.invoke.languageType.language87" : "الفيتنامية",
  "oifde.service.invoke.languageType.language86" : "الفلسطينيّة",
  "oifde.service.invoke.targetFlowCode" : "رمز التدفق المستهدف",
  "oifde.service.invoke.languageType.language85" : "البنغالية",
  "oifde.service.invoke.languageType.language84" : "كمبودي",
  "oifde.service.invoke.timeSelect.NonWorkTime" : "فرع الوقت غير محدد",
  "oifde.service.invoke.languageType.language83" : "الفلبينية",
  "oifde.queryMessageService.description" : "الاستعلام عن المعلومات (queryMessageService).",
  "oifde.service.invoke.languageType.language82" : "الهندية",
  "oifde.timeSelectService.displayName" : "تحديد الوقت",
  "oifde.service.invoke.languageType.language81" : "العربية",
  "oifde.service.invoke.languageType.language80" : "السواحيلية",
  "oifde.service.invokemethod.arrayAdd.parm1.desc" : "الصفيف المراد إضافته (هذه الطريقة تعدل الصفيف المراد إضافته)",
  "oifde.callEndService.keywords" : "إنهاء المكالمة",
  "oifde.qarecord.interface.name" : "سجل سؤال وجواب",
  'oifde.apiToken.interface.name':'الحصول على رمز APIFabric',
  'oifde.apiToken.interface.desc': 'الواجهة المستخدمة للحصول على رمز API Fabric Token',
  'oifde.apiToken.applyType.desc': 'بادئة الرمز المميز',
  'oifde.apiToken.accessToken.desc': 'محتوى الرمز المميز',
  'oifde.apiToken.appKey.desc': 'مفتاح التطبيق',
  'oifde.apiToken.appSecret.desc': 'سر التطبيق',
  "oifde.service.invoke.timeSelect.specificNoWorkDateParameter.desc" : "تاريخ غير محدد للخدمة. يتم دعم 50 سجلًا كحد أقصى.",
  "oifde.service.invoke.modelType.voiceAndInputAndNobreak" : "التعرف والضغط على المفتاح بعد التشغيل",
  "oifde.service.invokemethod.arraySize.parm1.desc" : "صفيف من أطوال ليتم حسابها",
  "oifde.service.invokemethod.mapSize.parm1.desc" : "خريطة الكمية المراد حسابها",
  "oifde.service.invokemethod.arrayRemoveAllIdx.resultObj.desc" : "الكائن الذي تم إرجاعه هو نتيجة الحذف. إذا تم الحذف بنجاح، يتم إرجاع 1. خلاف ذلك ، يتم إرجاع 0.",
  "oifde.service.invoke.ttsSoundSpeed.low" : "بطيئة",
  "oifde.service.invoke.startTime.desc" : "وقت البدء لإرسال رسائل SMS الرضا. التنسيق هو HH:MM:SS. مثال: 08:00:00.",
  "oifde.service.invoke.asrNoInputTimeout" : "الفاصل الزمني لمهلة صمت المشترك",
  "oifde.service.invoke.asrAdvancedSetting.open" : "تمكين",
  "oifde.service.invoke.interfaceType" : "نوع الواجهة",
  "oifde.service.invoke.vendorParam" : "معلمة موسعة",
  "oifde.compositeTestService.keywords" : "عينة عنصر الرسم البياني المركب",
  "oifde.service.invoke.workdays.Tuesday" : "الثلاثاء",
  "oifde.service.invoke.modelType.inputAndNobreak" : "لا انقطاع بعد جمع الأرقام",
  "oifde.service.invoke.complexcellgroupin.name" : "معلمات الإدخال",
  "oifde.service.invoke.modelType.voiceAndNobreak" : "الاعتراف بعد التشغيل",
  "oifde.service.invoke.recordPath" : "مسار السجل",
  "oifde.service.interfaceType.innerInterface" : "واجهة داخلية",
  "oifde.service.invoke.ttsAdvancedSetting.desc" : "ما إذا كان سيتم تمكين هذه الإعدادات لتحديد تشغيل الصوت المستند إلى TTS.",
  "oifde.commonBusinessInvokeService.keywords" : "استدعاء واجهة الأعمال",
  "oifde.service.invokemethod.strSubstring.parm1.desc" : "سلسلة الأحرف المراد اقتطاعها",
  "oifde.service.invokemethod.strSplit.parm1.desc" : "سلسلة الأحرف المراد تقسيمها",
  "oifde.service.invokemethod.strIndexOf.parm1.desc" : "سلسلة ليتم البحث عنها",
  "oifde.service.invokemethod.strParseInt.desc" : "تحويل سلسلة إلى عدد صحيح",
  "oifde.service.invoke.smsTemplateCode" : "نموذج SMS",
  "oifde.service.invoke.oblist.desc" : "قائمة الحلقات.",
  "oifde.service.invoke.confidence2Top.desc" : "الفاصل الزمني بين ثقة النوايا المتعددة والنية الأولى عند الحاجة إلى إرجاع النوايا المتعددة.",
  "oifde.service.invoke.event.cleanup" : "مسح ذاكرة التخزين المؤقت للجلسة",
  "oifde.service.invokemethod.mapRemove.parm1.desc" : "الخريطة المراد معالجتها",
  "oifde.service.invokemethod.group.value5Tips" : "معلمات الطريقة3",
  "oifde.service.invoke.ttsSoundEffect.desc" : "حدد ما إذا كنت تريد استخدام صوت ذكر أو أنثى لتشغيل الصوت المستند إلى TTS.",
  "oifde.service.invoke.cancelCollectFlag.no" : "لا تستخدم مفتاح النجمة (*)",
  "oifde.service.invokemethod.strIndexOf.resultObj.desc" : "الكائن الذي تم إرجاعه هو قيمة اشتراكية. القيمة عدد صحيح.",
  "oifde.service.invoke.cancelCollectFlag" : "مفتاح الإلغاء",
  "oifde.service.invoke.cachegroup.desc" : "قم بتعيين سمات المتغير المطابقة.",
  "oifde.service.invokemethod.arraySubList.parm3.desc" : "اعتراض موقف نهاية",
  "oifde.service.invokemethod.arrayContains.parm1.desc" : "الصفيف المراد اكتشافه",
  "oifde.service.invoke.voiceType.video" : "فيديو",
  "oifde.service.invoke.complexcellgroupout.desc" : "معلمات الإخراج.",
  "oifde.service.invokemethod.arrayJoin.parm2.desc" : "سلسلة الاتصال",
  "oifde.service.invoke.collectTimeout" : "مهلة جمع الأرقام",
  "oifde.service.invokemethod.mapClear.desc" : "مسح جميع أزواج القيمة الرئيسية للخريطة",
  "oifde.service.invokemethod.arrayAddAll.desc" : "إضافة كافة عناصر مجموعة أخرى إلى صفيف",
  "oifde.qarecord.inputparam.questionTitle" : "عنوان السؤال",
  "oifde.service.invoke.destVariable" : "سلسلة الحرف الهدف",
  "oifde.service.invoke.CallTransferType" : "نوع تحويل المكالمة",
  "oifde.service.invoke.CallTransferTransData.desc" : "البيانات المرتبطة المنقولة أثناء تحويل المكالمة إلى موظف.",
  "oifde.service.invoke.object.desc" : "كائن حلقة.",
  "oifde.jsonDataProcessService.description" : "استدعاء بيانات JSON (jsonDataProcessService).",
  "oifde.qarecord.interface.desc" : "تسجيل معرّف الاستبيان وعنوان السؤال ونية رد المستخدم ورد المستخدم الأصلي.",
  "oifde.conditionService.description" : "حكم الشرط (الشرطخدمة)",
  "oifde.service.invokemethod.mapRemove.resultObj.desc" : "إرجاع كائن: ترجع هذه الطريقة القيمة المقترنة مسبقًا بهذا المفتاح لهذا التعيين؛ ترجع خاليًا إذا كان التعيين لا يحتوي على تعيين للمفتاح.",
  "oifde.service.invoke.recordServiceNodeID" : "معرّف عقدة خدمة السجل",
  "oifde.service.invokemethod.group.value4Tips" : "معلمات الطريقة2",
  "oifde.service.invoke.paramgroup.name" : "معلمات إدخال الواجهة",
  "oifde.service.invoke.workdays.Friday" : "الجمعة",
  "oifde.service.invoke.operationType.close" : "إيقاف التسجيل",
  "oifde.sendSmsService.description" : "إرسال الرسائل القصيرة (SendSmsService)",
  "oifde.yugaoRecordService.keywords" : "تسجيل VoiceCyber",
  "oifde.service.invokemethod.arrayRemoveIdx.parm2.desc" : "حذف الاشتراك في الصفيف",
  "oifde.ringService.displayName" : "الرنين",
  "oifde.service.invoke.workdays.Monday" : "الاثنين",
  "oifde.commonVoiceService.displayName" : "الرد",
  "oifde.service.invokemethod.arraySize.desc" : "يحصل على طول صفيف",
  "oifde.service.invoke.cachegroup.displayName" : "سمات متغيرة",
  "oifde.service.invoke.queryMsg.MessageQueryWaitingTime" : "وقت الانتظار المقدر",
  "oifde.service.invoke.queryMsg.MessageQueryQueueIndex" : "موقف قائمة الانتظار",
  "oifde.service.invoke.complexcellgroupin.desc" : "معلمات الإدخال.",
  "oifde.service.invokemethod.strToLowerCase.parm1.desc" : "سلسلة الأحرف المراد تحويلها",
  "oifde.conditionService.keywords" : "حكم الشرط",
  "oifde.service.invoke.voiceType.TTSVoice" : "TTS",
  "oifde.service.invoke.modelType" : "وضع الاستجابة",
  "oifde.procedureService.description" : "الإجراء المخزن (procedureService)",
  "oifde.service.invoke.asrMRCPVendor" : "معلمة ASR الموسعة",
  "oifde.speechRecognitionService.description" : "التعرف الدلالي (speechRecognitionService)",
  "oifde.service.invoke.ttsSoundEffect.female" : "أنثى",
  "oifde.service.invokemethod.strParseJSON.parm1.desc" : "سلسلة الأحرف المراد تحويلها",
  "oifde.service.invoke.KeyboardInputRegExp.desc" : "يتحقق التعبير مما إذا كان النص الذي أدخله المستخدم يفي بالقاعدة المحددة. يمكن أن تكون القيمة تعبيرًا عاديًا. مثال: ^(1([0-9]{10})|([*]|[#]))$.",
  "oifde.service.invoke.startTime" : "وقت البدء",
  "oifde.service.invoke.queryMsg.skillQueue" : "قائمة انتظار المهارات",
  "oifde.service.invoke.collectTimeout.desc" : "تعيين الفاصل الزمني لمهلة مجموعة الأرقام. يتم دعم المتغيرات FLOW و GLOBAL. يمكن أن تكون القيمة أيضًا عددًا صحيحًا يتراوح من 1 إلى 120. إذا كانت القيمة لا تفي بالمتطلبات، يتم استخدام القيمة الافتراضية 5.",
  "oifde.service.invokemethod.group.value3Tips" : "معلمات الطريقة1",
  "oifde.service.invoke.languageType.language24" : "تايلاند",
  "oifde.service.invoke.languageType.language23" : "أوزبكستان",
  "oifde.service.invokemethod.strToLowerCase.resultObj.desc" : "الكائن الذي تم إرجاعه عبارة عن سلسلة أحرف بعد التحويل.",
  "oifde.service.invoke.KeyboardInputRegExp.Month" : "التحقق من إدخال الشهر",
  "oifde.service.invoke.languageType.language22" : "التركية",
  "oifde.service.invoke.queryMsg.specialList" : "المعرف في القائمة الخاصة",
  "oifde.service.invoke.languageType.language21" : "السويدية",
  "oifde.service.invoke.multiVoice" : "صوت متغير",
  "oifde.service.invoke.languageType.language20" : "الأسبانية",
  "oifde.service.invoke.CallTransferType.transferToSkillQueueBelongAgent" : "قائمة انتظار المهارات (وكلاء الوسائط المتعددة)",
  "oifde.service.invoke.modelType.customization" : "قيمة متغيرة مخصصة",
  "oifde.commonVoiceService.keywords" : "الرد",
  "oifde.service.invoke.input" : "محتوى التعرف الدلالي",
  "oifde.service.invoke.timeSelect.serviceTimeParameter" : "فترة الخدمة",
  "oifde.service.invoke.ttsSoundSpeed.normal" : "متوسط",
  "oifde.service.invokemethod.arrayClear.parm1.desc" : "صفيف ليتم تطهيرها",
  "oifde.service.invoke.smsContent" : "محتوى الرسائل القصيرة",
  "oifde.service.invoke.modelType.noVoiceBreak" : "تشغيل فقط",
  "oifde.service.invoke.ttsMRCPVendorSetting.open" : "تمكين",
  "oifde.service.invoke.KeyboardInputRegExp.NumberAll" : "التحقق من صحة رقم الجوال (مع # و *)",
  "oifde.service.invokemethod.arrayRemoveIdx.desc" : "حذف العنصر الملحق المحدد من الصفيف",
  "oifde.service.invokemethod.arraySubList.parm2.desc" : "التقاط موضع البدء",
  "oifde.keyboardRecognitionService.displayName" : "التعرف على المفاتيح",
  "oifde.service.invoke.timeSelect.specificNoWorkDateParameter.title" : "في حالة تكوين تاريخ غير خدمة محدد، يُعتبر تاريخ غير خدمة غير يوم عمل ذي أولوية قصوى.",
  "oifde.service.invoke.timeOut.desc" : "الفاصل الزمني للمهلة (الوحدة: ثواني). القيمة عدد صحيح. مثال: 15.",
  "oifde.service.invokemethod.strStartsWith.desc" : "التحقق مما إذا كانت السلسلة تبدأ بالبادئة المحددة",
  "oifde.service.invoke.languageType.language19" : "السلوفاكية",
  "oifde.service.invokemethod.strIndexOf.desc" : "يحصل على الحد الأدنى للحدوث الأول لسلسلة محددة",
  "oifde.service.invoke.languageType.language18" : "الروسية",
  "oifde.service.invokemethod.arrayJoin.parm1.desc" : "صفيف ليتم تحويلها",
  "oifde.service.invoke.languageType.language17" : "البرتغالية",
  "oifde.service.invoke.languageType.language16" : "البولندية",
  "oifde.service.invoke.languageType.language15" : "النرويجية",
  "oifde.service.invoke.languageType.language14" : "الكورية",
  "oifde.service.invoke.languageType.language13" : "اليابانية",
  "oifde.service.invoke.languageType.language12" : "الإيطالية",
  "oifde.service.condition.condgroup.desc" : "تعيين سمات شرط الحكم.",
  "oifde.service.invoke.languageType.language11" : "الآيسلندية",
  "oifde.service.invoke.languageType.language10" : "الهنغارية",
  "oifde.service.invoke.recordMode.all" : "سجل المشترك و IVR",
  "oifde.service.invoke.CallTransferTransData.desc2" : "بدلاً من ذلك، يمكنك إدخال متغيرات تدفق أو عمومية متعددة، على سبيل المثال، {\"data\":${FLOW.XXX},\"type\":${GLOBAL.XXX}, \"time\":\"2000\"}.",
  "oifde.service.invoke.voiceVariable.multiVoice.telnum.desc" : "متغير الصوت أو النص الذي يتم تضمينه في الاستجابة. يجب تعيين قيمة لهذا المتغير قبل تنفيذ العقدة (بالنسبة لنوع رقم الهاتف ، يكون متغير الاستجابة في تنسيق المحتوى الفعلي لمتغير الاستجابة هو نص رقمي. إذا كان الإدخال غير صحيح ، يكون تشغيل الصوت غير طبيعي.)",
  "oifde.service.invokemethod.strEndsWith.desc" : "التحقق مما إذا كانت السلسلة تنتهي بلاحقة محددة",
  "oifde.service.invokemethod.group.value3Title" : "معلمات الطريقة",
  "oifde.service.invoke.maxCollectLength" : "عدد الأرقام التي تم جمعها",
  "oifde.service.invoke.voiceVariable.default.desc" : "متغير الصوت أو النص الذي يتم تضمينه في الاستجابة. يجب تعيين قيمة لهذا المتغير قبل تنفيذ العقدة.",
  "oifde.service.invokemethod.strLength.parm1.desc" : "سلسلة الحرف من طول ليتم حسابها",
  "oifde.service.invokemethod.mapGet.desc" : "الحصول على قيمة تعيين المفتاح المحدد في الخريطة",
  "oifde.service.invokemethod.mapClear.parm1.desc" : "الخريطة المراد مسحها",
  "oifde.service.invoke.breakMinTime" : "الحد الأدنى لمدة تشغيل الصوت",
  "oifde.commonBusinessInvokeService.displayName" : "استدعاء واجهة الأعمال",
  "oifde.service.invokemethod.group.value2Tips" : "حدد اسم أسلوب.",
  "oifde.service.invokemethod.strStartsWith.parm2.desc" : "سلسلة البادئة المحددة",
  "oifde.service.invokemethod.strParseJSON.resultObj.desc" : "الكائن الذي تم إرجاعه هو كائن JSON المحول.",
  "oifde.service.invoke.KeyboardInputRegExp.PasswordStar" : "التحقق من صحة تنسيق كلمة المرور (باستخدام *)",
  "oifde.service.invoke.offsetType" : "فترة الإزاحة",
  "oifde.service.invoke.recordServiceNodeID.desc" : "أدخل المتغير الذي يخزن معرف عقدة خدمة التسجيل (المستخدم في وضع تجمع مركز الاتصال).",
  "oifde.service.invokemethod.strToUpperCase.resultObj.desc" : "الكائن الذي تم إرجاعه عبارة عن سلسلة أحرف بعد التحويل.",
  "oifde.service.invoke.targetIntentCount" : "عدد النوايا",
  "oifde.service.invokemethod.arrayAdd.resultObj.desc" : "الكائن الذي تم إرجاعه هو نتيجة الإضافة. إذا تمت الإضافة بنجاح، يتم إرجاع 1. خلاف ذلك ، يتم إرجاع 0.",
  "oifde.service.invoke.ttsSoundEffectCust" : "تأثير الصوت المخصص",
  "oifde.service.invokemethod.arrayRemoveIdx.parm1.desc" : "صفيف ليتم استخدامها",
  "oifde.service.invoke.paramgroup.desc" : "قائمة معلمات إدخال الواجهة.",
  "oifde.service.invoke.procedureCode" : "الإجراء المخزن",
  "oifde.jsonDataProcessService.displayName" : "استدعاء بيانات JSON",
  "oifde.service.invokemethod.arrayRemoveIdx.resultObj.desc" : "الكائن الذي تم إرجاعه هو نتيجة الحذف. إذا تم الحذف بنجاح، يتم إرجاع العنصر الملحق المقابل. خلاف ذلك، يتم إرجاع null.",
  "oifde.errTimesClearService.description" : "إزالة عدد الأخطاء (errTimesClearService)",
  "oifde.service.invoke.CallTransferType.transferToMultimediaMessageToLastAgent" : "رسالة من ضيف مألوف",
  "oifde.service.invoke.queryMsg.MessageQueryType" : "نوع الاستعلام",
  "oifde.service.invoke.KeyboardInputRegExp.Mobile" : "التحقق من صحة تنسيق رقم الجوال (بدون # أو *)",
  "oifde.service.invoke.endTime" : "وقت الانتهاء",
  "oifde.jsonDataProcessService.keywords" : "استدعاء بيانات JSON",
  "oifde.service.invokemethod.strToUpperCase.parm1.desc" : "سلسلة الأحرف المراد تحويلها",
  "oifde.service.invoke.voiceVariable.multiVoice.number.desc" : "متغير الصوت أو النص الذي يتم تضمينه في الاستجابة. يجب تعيين قيمة لهذا المتغير قبل تنفيذ العقدة (بالنسبة لنوع الرقم ، يكون متغير الاستجابة في تنسيق المحتوى الفعلي لمتغير الاستجابة هو نص رقمي. إذا كان الإدخال غير صحيح ، يكون تشغيل الصوت غير طبيعي.)",
  "oifde.sendSmsService.displayName" : "إرسال الرسائل القصيرة",
  "oifde.service.invoke.processRecord.open" : "نعم",
  "oifde.service.invoke.KeyboardInputRegExp.MobileAll" : "التحقق من صحة تنسيق رقم الجوال (مع # و *)",
  "oifde.service.invokemethod.strParseFloat.resultObj.desc" : "الكائن الذي تم إرجاعه هو رقم النقطة العائمة المحولة.",
  "oifde.service.invokemethod.mapPut.resultObj.desc" : "منطق الكائن الذي تم إرجاعه هو كما يلي: إذا لم يكن المفتاح موجودًا، يتم إرجاع null. في حالة وجود المفتاح، يتم إرجاع القيمة المستبدلة ونوع الكائن.",
  "oifde.service.invokemethod.group.value1Tips" : "كائن استلام قيمة إرجاع الأسلوب",
  "oifde.service.invokemethod.mapPut.parm3.desc" : "العناصر المضافة",
  "oifde.service.invoke.TTSVoice" : "TTS",
  "oifde.service.invoke.CallTransferTransData" : "البيانات المرتبطة",
  "oifde.service.invoke.recordMode.onlyuser" : "تسجيل المشترك فقط",
  "oifde.service.invoke.variableType.type6" : "السعر",
  "oifde.service.invoke.variableType.type5" : "التاريخ (yyyymmdd)",
  "oifde.service.invokemethod.strStartsWith.resultObj.desc" : "الكائن الذي تم إرجاعه هو نتيجة الاكتشاف. القيمة هي عدد صحيح من 0 أو 1.",
  "oifde.service.invoke.variableType.type4" : "time(hh:mm:ss)",
  "oifde.service.invoke.variableType.type3" : "عدد",
  "oifde.UserLevelService.displayName" : "مستوى المستخدم",
  "oifde.service.invoke.variableType.type1" : "رقم الهاتف",
  "oifde.service.invoke.inputVariable" : "متغير",
  "oifde.service.invoke.voiceType.card" : "بطاقة",
  "oifde.service.invokemethod.arraySubList.parm1.desc" : "صفيف ليتم اعتراضها",
  "oifde.service.invoke.CallTransferType.transferToLastAgent" : "ضيف الوسائط المتعددة المألوفة",
  "oifde.service.invoke.staticVoice" : "صوت ثابت",
  "oifde.compositeTestService.description" : "عينة عناصر المخطط المركب (compositeTestService)",
  "oifde.keyboardRecognitionService.keywords" : "التعرف على المفاتيح",
  "oifde.transforFlowService.keywords" : "نقل التدفق",
  "oifde.service.invoke.timeOut" : "الفاصل الزمني للمهلة",
  "oifde.service.invoke.timeSelect.specificNoWorkDateParameter" : "تاريخ عدم الخدمة المحدد",
  "oifde.service.invoke.targetNodeId" : "معرف عقدة الهدف",
  "oifde.service.invoke.smsContent.desc" : "محتوى الرسائل القصيرة.",
  "oifde.service.invokemethod.arrayAddAll.parm3.desc" : "العناصر المضافة",
  "oifde.service.invoke.ttsSoundSpeed.desc" : "حدد السرعة التي يتم بها تشغيل الصوت المستند إلى TTS.",
  "oifde.service.invoke.ttsSoundEffect" : "صوت",
  "oifde.service.invoke.modelType.voiceBreak" : "الاعتراف بالانقطاع",
  "oifde.service.invokemethod.mapSize.resultObj.desc" : "الكائن الذي تم إرجاعه هو عدد المفاتيح في الخريطة. القيمة عدد صحيح.",
  "oifde.service.invoke.queryMsg.result" : "نتيجة الاستعلام",
  "oifde.CircleService.displayName" : "حلقة المنطق",
  "oifde.transforFlowService.description" : "نقل التدفق (TransforFlowService)",
  "oifde.service.invokemethod.group.value2Title" : "اسم الطريقة",
  "oifde.service.invokemethod.arrayAddAll.resultObj.desc" : "الكائن الذي تم إرجاعه هو نتيجة الإضافة. إذا تمت الإضافة بنجاح، يتم إرجاع 1. خلاف ذلك ، يتم إرجاع 0.",
  "oifde.service.invoke.sourceVariable.desc" : "متغير سلسلة المصدر. يكون التنسيق yyMM إذا كان الإزاحة بالشهر، ويكون yyyyMMddHmmss إذا كان الإزاحة بالساعة. على سبيل المثال: 202103 أو 2021030812202020. يجب تعيين قيمة لهذا المتغير قبل تنفيذ العقدة.",
  "oifde.service.invoke.asrNoInputTimeout.desc" : "الفاصل الزمني للمهلة عندما لا يتكلم المشترك أثناء التعرف على ASR، بالثواني. تتراوح القيمة من 0 إلى 3200. القيمة الافتراضية هي 100.",
  "oifde.service.invoke.processRecord" : "تسجيل كامل",
  "oifde.service.invoke.asrSpeechCompleteTimeout" : "الفاصل الزمني لمهلة الإيقاف المؤقت للمشترك",
  "oifde.callTransferService.keywords" : "تحويل المكالمات",
  "oifde.service.invokemethod.strStartsWith.parm1.desc" : "سلسلة الأحرف المراد التحقق منها",
  "oifde.callEndService.displayName" : "إنهاء المكالمة",
  "oifde.start.displayName" : "ابدأ",
  "oifde.service.invokemethod.strEndsWith.parm2.desc" : "سلسلة لاحقة محددة",
  "oifde.yugaoRecordService.displayName" : "تسجيل VoiceCyber",
  "oifde.service.invokemethod.strLength.desc" : "طول سلسلة الأحرف",
  "oifde.service.invokemethod.strEndsWith.resultObj.desc" : "الكائن الذي تم إرجاعه هو نتيجة الاكتشاف. القيمة هي عدد صحيح من 0 أو 1.",
  "oifde.service.invokemethod.arrayJoin.desc" : "تحويل صفيف إلى سلسلة",
  "oifde.service.invokemethod.strToUpperCase.desc" : "تحويل أحرف الحروف الصغيرة للسلسلة إلى أحرف كبيرة",
  "oifde.service.invoke.recordPath.desc" : "أدخل المتغير لتخزين اسم ملف التسجيل.",
  "oifde.service.invoke.queryMsg.MessageIdleAgentNum" : "عدد الموظفين الخاملين",
  "oifde.service.invoke.user.level.reminder" : "قم بتعيين هذه المعلمة إلى عدد صحيح غير سالب أو متغير من نوع الحرف. القيمة الافتراضية هي 0 والقيمة القصوى هي 14. تتم معالجة مكالمات العملاء رفيعي المستوى أولاً بعد تحويل المكالمة إلى الخدمة اليدوية.",
  "oifde.qarecord.inputparam.originalAnswer" : "الإجابة الأصلية",
  "oifde.service.inpytOrDrag" : "إدخال أو سحب المتغيرات",
  "oifde.dateProcessService.description" : "معالجة التاريخ (dateProcessService)",
  "oifde.queryMessageService.displayName" : "الاستعلام عن المعلومات",
  "oifde.service.invoke.asrRecognizeType.intelligent" : "ذكي",
  "oifde.service.invoke.timeSelect.specificWorkDateParameter": "تاريخ الخدمة المحدد",
  "oifde.service.invokemethod.strSplit.resultObj.desc": "الكائن الذي تم إرجاعه هو من نوع الصفيف ويقوم بتخزين صفيف السلسلة بعد التقسيم.",
  "oifde.service.invokemethod.strSubstring.resultObj.desc": "الكائن الذي تم إرجاعه عبارة عن سلسلة أحرف، وهي سلسلة فرعية مقطوعة.",
  "oifde.service.invoke.KeyboardInputRegExp": "تعبير القاعدة",
  "oifde.service.invoke.user.level": "مستوى المستخدم",
  "oifde.dateProcessService.keywords": "معالجة التاريخ",
  "oifde.service.invoke.timeSelect.specificWorkDateParameter.title": "في حالة عدم توفر تاريخ خدمة محدد تم تكوينه في اختيار الأسبوع، يتم اعتبار تاريخ الخدمة يوم عمل.",
  "oifde.service.invoke.workdays.Thursday": "الخميس",
  "oifde.timeSelectService.description": "تحديد الوقت (timeSelectService).",
  "oifde.service.invoke.sendCircle": "الفاصل الزمني للإرسال",
  "oifde.procedureService.keywords": "الإجراء المخزن",
  "oifde.service.invoke.ttsMRCPVendor.desc": "معلمات خادم TTS الموسعة. قم بتعيين المعلمات استنادًا إلى متطلبات بائع خادم TTS. مثال على ذلك: speed=GLOBAL.ttsSpeed;pitch=GLOBAL.ttsPitch;volume=GLOBAL.ttsVolume;voice_name=GLOBAL.voiceName;. يتم دعم المتغيرات GLOBAL وFLOW. إذا لم تكن قيمة المتغير ضمن النطاق القياسي، يتم استخدام القيمة الافتراضية. نطاق قيمة السرعة هو [-500، 500]، والقيمة الافتراضية هي 0. نطاق قيمة وحدة التخزين هو [0, 100]، والقيمة الافتراضية هي 50. نطاق قيمة الدرجة هو [-500، 500]، والقيمة الافتراضية هي 0. القيمة الافتراضية لـ Voice_name هي cn_kefuman_common.",
  "oifde.service.invoke.voiceTemplateCode": "قالب الاستجابة",
  "oifde.service.invoke.CallTransferType.desc": "نوع نقل الحوار. الخيارات هي كما يلي: 1: نقل إلى الخدمة اليدوية (إلى قائمة انتظار المهارات عن طريق رمز التوجيه)؛ 2: نقل إلى IVR؛ 3: التحويل إلى الخدمة اليدوية (إلى قائمة انتظار المهارات حسب اسم المهارة) ؛ 4: التحويل إلى الخدمة اليدوية (إلى موظف حسب المعرف) ؛ 5: التحويل إلى رقم جهة خارجية ؛ 6: مساعد رقمي عبر الإنترنت ؛ 7: التحويل إلى الخدمة اليدوية (إلى آخر موظف ، يتم دعم الوسائط المتعددة فقط حاليًا) ؛ 8: النقل إلى الخدمة اليدوية (إلى قائمة انتظار المهارة التي ينتمي إليها العامل، يتم دعم الوسائط المتعددة فقط حاليًا) 31: النقل إلى رسالة وسائط متعددة (إلى آخر وكيل)؛ 32: النقل إلى رسالة وسائط متعددة (إلى قائمة انتظار المهارات)؛ 33: النقل إلى رسالة وسائط متعددة (إلى وكيل).",
  "oifde.service.invoke.asrAdvancedSetting.desc": "ما إذا كان سيتم تمكين هذه الإعدادات لتحديد التعرف على ASR.",
  "oifde.textVariableToChatbotService.keywords": "نص إلى chatbot",
  "oifde.service.invoke.asrRecognizeType": "نوع الاعتراف",
  "oifde.service.invokemethod.mapPut.parm2.desc": "مفتاح (يتم دعم نوع السلسلة فقط)",
  "oifde.service.invoke.multiVoiceLanguage": "اللغة",
  "oifde.service.invoke.ttsSoundSpeed.high": "سريع",
  'oifde.transdata.get.example': 'على سبيل المثال، إذا كانت البيانات المقترنة هي {\'{\'}البيانات:{\'{\'}الاختبار: 111{\'}\'}{\'}\'}, يمكنك إدخال data.test في قيمة المتغير للحصول على البيانات 111.',
  'oifde.service.invoke.basicAvatarReplyService.name': 'رد الصورة الرمزية',
  'oifde.service.invoke.basicAvatarReplyService.actionType': 'العملية',
  'oifde.service.invoke.basicAvatarReplyService.start': 'بدء',
  'oifde.service.invoke.basicAvatarReplyService.image': 'صورة',
  'oifde.service.invoke.basicAvatarReplyService.avatarImage': 'الصورة الرمزية',
  'oifde.service.invoke.basicAvatarReplyService.ttsSpeaker': 'مكبر الصوت',
  'oifde.service.invoke.basicAvatarReplyService.compositeVideoSetting': 'تكوين الفيديو المركب',
  'oifde.service.invoke.basicAvatarReplyService.compositeVideoSetting.desc': 'تكوين الفيديو المركب، والذي يؤثر بشكل مباشر على تأثير عرض الشخص الرقمي. قم بتمكين هذه الوظيفة عند الحاجة.',
  'oifde.service.invoke.basicAvatarReplyService.imageSetting': 'إعدادات الصورة والفيديو',
  'oifde.service.invoke.basicAvatarReplyService.action':'الإجراءات',
  'oifde.service.invoke.basicAvatarReplyService.frontImage': 'الصورة الأمامية',
  'oifde.service.invoke.basicAvatarReplyService.backendImage': 'الصورة الخلفية',
  'oifde.service.invoke.basicAvatarReplyService.frontVideo': 'الفيديو الأمامي',
  'oifde.service.invoke.basicAvatarReplyService.backendVideo': 'الفيديو الخلفي',
  'oifde.service.invoke.basicAvatarReplyService.xPosition': 'أبسيسسا',
  'oifde.service.invoke.basicAvatarReplyService.yPosition': 'ترتيب',
  'oifde.service.invoke.basicAvatarReplyService.scale': 'مقياس',
  'oifde.service.invoke.basicAvatarReplyService.beginTime': 'وقت البدء (مللي ثانية)',
  'oifde.service.invoke.basicAvatarReplyService.duration': 'مدة العرض (مللي ثانية)',
  'service.invoke.virtualHumanConfig': 'التكوين البشري الظاهري',
  'service.virtualHuman.videoParam': 'معلمات تحرير الفيديو',
  'service.virtualHuman.captions': 'ترجمات',
  'service.invoke.asrAdvancedSetting.open': 'تمكين',
  'service.invoke.asrAdvancedSetting.close': 'تعطيل',
}
