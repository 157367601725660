export default {
  "cms.recorddrive.label.IPAddress_add": "IP地址",
  "cms.recorddrive.label.button_add": "新增",
  "cms.recorddrive.label.button_cacel": "取消",
  "cms.recorddrive.label.button_complete": "完成",
  "cms.recorddrive.label.button_edit": "编辑",
  "cms.recorddrive.field.number": "编号",
  "cms.agentMonitor.label.Idle_error": "质检员未处于示闲状态",
  "cms.agentMonitor.label.batchSelectAgent": "该操作只能选择一个座席",
  "cms.agentMonitor.laber.hasbeenCheck": "该座席已经被质检",
  "cms.agentMonitor.laber.hasbeenMediaCheck": "该座席已经被多媒体会话监控",
  "cms.agentMonitor.laber.stopMediaMonitor": "已停止多媒体会话监控",
  "cms.agentMonitor.laber.notInTalking": "座席当前未在通话中，请刷新状态后重试",
  "cms.agentMonitor.label.beinbusy_error": "被质检座席处于忙碌态",
  "cms.agentMonitor.label.beinidle_error": "被质检座席处于空闲态",
  "cms.agentMonitor.label.beinrest_error": "被质检座席处于休息态",
  "cms.agentMonitor.label.details": "详情",
  "cms.agentMonitor.label.error": "错误",
  "cms.agentMonitor.label.forceidle_repeat": "座席已示闲，请勿重复示闲",
  "cms.agentMonitor.label.forcebusy_repeat": "座席已示忙，请勿重复示忙",
  "cms.agentMonitor.label.forcebusy_stateerror": "座席正在休息，请勿示忙",
  "cms.agentMonitor.label.forcebusy_talking": "座席正在通话中，请勿示忙",
  "cms.agentMonitor.label.forceidle_talking": "座席正在通话中，请勿示闲",
  "cms.agentMonitor.label.forcebusy_error": "强制示忙失败",
  "cms.agentMonitor.label.forcebusy_success": "强制示忙成功",
  "cms.agentMonitor.label.forcebusy_success_calling": "强制示忙成功，待通话结束后座席会进入示忙状态",
  "cms.agentMonitor.label.forceidle_success_calling": "强制示闲成功，待通话结束后座席会进入示闲状态",
  "cms.agentMonitor.label.forceidle_error": "强制示闲失败",
  "cms.agentMonitor.label.forceidle_success": "强制示闲成功",
  "cms.agentMonitor.label.forcerelease_error": "强制释放失败",
  "cms.agentMonitor.label.forcerelease_success": "强制释放成功",
  "cms.agentMonitor.label.forcesignout_error": "签出失败",
  "cms.agentMonitor.label.forcesignout_success": "签出成功",
  "cms.agentMonitor.label.gateway_error": "调用gateway接口失败",
  "cms.agentMonitor.label.insets_error": "插入失败",
  "cms.agentMonitor.label.insets_success": "插入成功",
  "cms.agentMonitor.label.whisper_error": "耳语失败",
  "cms.agentMonitor.label.whisper_success": "耳语成功",
  "cms.agentMonitor.label.cancelwhisper_error": "取消耳语失败",
  "cms.agentMonitor.label.cancelwhisper_success": "取消耳语成功",
  "cms.agentMonitor.label.switch_error": "切换失败",
  "cms.agentMonitor.label.switch_success": "切换成功",
  "cms.agentMonitor.message.operaFailed": "操作失败，正在质检或通话中",
  "cms.agentMonitor.message.operaFailedAgent": "操作失败，正在质检座席",
  "cms.agentMonitor.label.intercept_error": "拦截失败",
  "cms.agentMonitor.label.intercept_success": " 拦截成功",
  "cms.agentMonitor.label.listen_error": "监听失败     ",
  "cms.agentMonitor.label.listen_success": "监听成功   ",
  "cms.agentMonitor.label.cancelInsert_error": "取消插入失败",
  "cms.agentMonitor.label.cancelInsert_success": "取消插入成功",
  "cms.agentMonitor.label.cancelListening_error": "取消监听失败",
  "cms.agentMonitor.label.cancelListening_success": "取消监听成功",
  "cms.agentMonitor.label.been_inspected": "当前坐席正在被质检，请稍后再试",
  "cms.agentmonitor.label.qcstate_error": "取消失败，当前未对选中座席执行质检操作",
  "cms.agentMonitor.label.nosignin_error": "被质检座席未签入",
  "cms.agentMonitor.label.switchNotice": "请在对当前座席进行监听或插入状态下使用此功能",
  "cms.agentMonitor.label.interceptNotice": "请在对当前座席进行监听、插入或耳语状态下使用此功能",
  "cms.agentMonitor.label.qcinspector_error": "非质检员，无法进行操作",
  "cms.agentMonitor.label.permission_error": "质检操作时发生没有权限异常",
  "cms.agentMonitor.label.reset": "重置",
  "cms.agentMonitor.label.search": "查询",
  "cms.agentMonitor.label.selectAgent": "请选择座席",
  "cms.agentMonitor.label.success": "成功",
  "cms.agentMonitor.label.talk_error": "选择的座席不在通话态",
  "cms.agentMonitor.label.talkingerror": "所选座席非通话状态或处于多媒体通话中",
  "cms.agentMonitor.label.talking_error": "被质检座席处于通话态",
  "cms.agentMonitor.label.warning": "警告",
  "cms.agentmonitor.label.account": "业务帐号",
  "cms.agentmonitor.label.terminalStatus": "终端注册状态",
  "cms.agentmonitor.label.terminalStatusRefresh": "状态刷新",
  "cms.agentmonitor.label.terminalStatusFix": "状态修复",
  "cms.agentmonitor.label.accountCode": "请输入业务帐号",
  "cms.agentmonitor.label.agent": "座席",
  "cms.agentmonitor.label.agentId": "工号",
  "cms.agentmonitor.label.agentName": "座席名称",
  "cms.agentmonitor.label.agentStatus": "座席状态",
  "cms.agentmonitor.label.answering": "应答",
  "cms.agentmonitor.label.busy": "强制示忙",
  "cms.agentmonitor.label.busyState": "忙碌",
  "cms.agentmonitor.label.enterID": "请输入座席工号",
  "cms.agentmonitor.label.forceSignOut": "强制签出",
  "cms.agentmonitor.label.header": "设置表头",
  "cms.agentmonitor.label.idle": "强制示闲",
  "cms.agentmonitor.label.idleState": "空闲",
  "cms.agentmonitor.label.whisper": "耳语",
  "cms.agentmonitor.label.cancelwhisper": "取消耳语",
  "cms.agentmonitor.label.intercept": "拦截",
  "cms.agentmonitor.label.switch": "切换",
  "cms.agentmonitor.label.insets": "插入",
  "cms.agentmonitor.label.adjust": "整理",
  "cms.agentmonitor.label.learnState": "学习",
  "cms.agentmonitor.label.listening": "监听",
  "cms.agentmonitor.label.occupationState": "占用",
  "cms.agentmonitor.label.offline": "离线",
  "cms.agentmonitor.label.operation": "操作",
  "cms.agentmonitor.label.possessingSkills": "签入队列",
  "cms.agentmonitor.label.preoccupationState": "预占用",
  "cms.agentmonitor.label.qualityInspection": "可质检",
  "cms.agentmonitor.label.queueAdjustment": "队列调整",
  "cms.agentmonitor.label.readjustmentState": "调整中",
  "cms.agentmonitor.label.release": "强制释放",
  "cms.agentmonitor.label.restState": "休息",
  "cms.agentmonitor.label.sendNotes": "发送便签",
  "cms.agentmonitor.label.signOut": "未签入",
  "cms.agentmonitor.label.statusDuration": "座席状态持续时长",
  "cms.agentmonitor.label.alarmState": "监控告警",
  "cms.agentmonitor.label.talkingState": "通话中",
  "cms.agentmonitor.label.syncScreenMonitor": "同屏监控",
  "cms.agentmonitor.label.total": "座席总计",
  "cms.agentMonitor.label.queryNum": "设置签入号码",
  "cms.agentMonitor.label.queryNumSuccess": "设置签入号码成功",
  "cms.agentMonitor.label.queryNumFailed": "设置签入号码失败",
  "cms.agentMonitor.label.qcSipNum": "设置当前用户签入号码",
  "cms.agentMonitor.label.updateSip_error": "号码书写不规范",
  "cms.agentMonitor.label.updateSip_repeat": "该号码已分配",
  "cms.agentMonitor.label.querynum_error": "签入号码更新失败",
  "cms.agentMonitor.label.querynum_success": "号码为:",
  "cms.agentmonitor.label.monitorState": "质检状态",
  "cms.agentmonitor.label.all": "全部",
  "cms.agentmonitor.label.noListenedAndInserted": "未被质检",
  "cms.agentmonitor.label.listenedAndInserted": "正在被质检",
  "cms.agentmonitor.label.noAlarm": "无告警",
  "cms.agentmonitor.label.haveAlarm": "有告警",
  "cms.agentmonitor.label.alarmDetail": "告警详情",
  "cms.agentmonitor.label.mutilMediaMonitor": "多媒体会话监控",
  "cms.agentmonitor.label.stopMutilMediaMonitor": "停止会话监控",
  "cms.agentmonitor.label.showAlarmDetail": "查看告警详情",
  "cms.agentmonitor.label.showAlarmCallId": "呼叫标识",
  "cms.agentmonitor.label.showAlarmAgentNo": "座席工号",
  "cms.agentmonitor.label.showAlarmTime": "时间",
  "cms.agentmonitor.label.showAlarmContent": "对话内容",
  "cms.agentmonitor.label.showAlarmSensitive": "敏感词",
  "cms.agentmonitor.label.showAlarmEmotionType": "情绪类型",
  "cms.agentmonitor.label.dayStatistic": "今日统计",
  "cms.agentmonitor.label.countAndTime": "次数/时长",
  "cms.agentmonitor.label.idleStatistic": "示闲",
  "cms.agentmonitor.label.Performance": "绩效",
  "cms.agentmonitor.label.signInDetail": "签入详情",
  "cms.agentmonitor.syncScreenMonitor.getVrpIpError": "被质检座席未签入VRC客户端",
  "cms.agentmonitor.syncScreenMonitor.agentStatus": "请先签入，再进行监控",
  "cms.sendnote.field.cancel": "取消",
  "cms.sendnote.message.checkInput": "非法字符",
  "cms.sendnote.field.notecontent": "便签信息",
  "cms.sendnote.field.ok": "确定",
  "cms.sendnote.title.selectAgents": "已选座席",
  "cms.sendnote.title.tips": "提示",
  "cms.sendnote.message.tipsError": "便签发送失败",
  "cms.sendnote.message.tipsNoteContent": "请输入便签信息！",
  "cms.sendnote.message.tipsSuccess": "便签发送成功",
  "cms.skilladjustment.field.inputskill": "请输入队列名称",
  "cms.skilladjustment.field.seach": "搜索",
  "cms.skilladjustment.field.selectskill": "选择队列",
  "cms.skilladjustment.field.skillid": "技能队列ID",
  "cms.skilladjustment.field.skillname": "技能队列名称",
  "cms.skilladjustment.field.skilltype": "技能队列类型",
  "cms.skilladjustment.title.tips": "提示",
  "cms.skilladjustment.message.tipsError": "技能队列调整失败",
  "cms.skilladjustment.message.tipsOutSide": "单次技能队列调整的坐席数最多10个",
  "cms.skilladjustment.message.tipsNotMonitor": "非质检员，技能队列调整失败",
  "cms.skilladjustment.message.notMatchAgent": "调整后的技能队列类型与座席类型不匹配",
  "cms.skilladjustment.message.tipsSelectSkill": "请选择需要调整的技能队列！",
  "cms.skilladjustment.message.tipsSuccess": "技能队列调整成功",
  "cms.agentmonitor.label.detail": "座席监控详情",
  "cms.qualitycheck.label.create": "创建",
  "cms.qualitycheck.label.delete": "删除",
  "cms.qualitycheck.label.edit": "编辑",
  "cms.qualitycheck.label.editMember": "编辑组成员",
  "cms.qualitycheck.label.view": "预览",
  "cms.qualitycheck.field.selected": "已选择",
  "cms.qualitycheck.field.unselected": "未选择",
  "cms.qualitycheck.title.delete": "删除失败",
  "cms.qualitycheck.message.deleteinfo": "删除失败",
  "cms.qualitycheck.message.error_errorinfo": "保存失败",
  "cms.qualitycheck.title.saveError": "错误",
  "cms.qualitycheck.label.cancel": "取消",
  "cms.qualitycheck.label.configQcInformation": "配置质检信息",
  "cms.qualitycheck.label.createusergroup": "创建用户组",
  "cms.qualitycheck.label.editusergroup": "编辑用户组",
  "cms.qualitycheck.label.editusergroupmember": "编辑用户组成员",
  "cms.qualitycheck.label.groupname": "用户组",
  "cms.qualitycheck.label.input": "请输入",
  "cms.qualitycheck.label.save": "保存",
  "cms.qualitycheck.label.viewusergroup": "查看用户组",
  "cms.qualitycheck.title.deleteSuccess": "删除成功",
  "cms.qualitycheck.message.deleteSuccess": "删除成功",
  "cms.qualitycheck.title.saveSuccess": "成功",
  "cms.qualitycheck.message.saveSuccess": "保存成功",
  "cms.qualitycheck.label.userGroup": "用户组",
  "cms.qualitycheck.label.userGroupMemberConfig": "用户组成员配置",
  "cms.qualitycheck.label.qcRelationConfig": "质检关系",
  "cms.qualitycheck.label.queryQcRelation": "质检关系查询",
  "cms.qualitycheck.label.unselectedGroup": "未选择用户组",
  "cms.qualitycheck.label.selectedGroup": "已选择用户组",
  "cms.qualitycheck.field.groupName": "用户组名称",
  "cms.qualitycheck.placeholder.userGroupName": "请输入用户组名称",
  "cms.qualitycheck.label.trafficGroup": "话务组",
  "cms.qualitycheck.label.qualityInspectionGroup": "质检组",
  "cms.recorddrive.label.recorddrivename": "盘符名称",
  "cms.recorddrive.title.recorddrivenameconfig": "盘符配置",
  "cms.recorddrive.field.configSftpAccount": "SFTP 帐号",
  "cms.recorddrive.field.configSftpAccountConfirmPwd": "重复SFTP Server密码",
  "cms.recorddrive.field.configSftpAccountPwd": "SFTP Server密码",
  "cms.recorddrive.field.configSftpPath": "文件存储路径",
  "cms.recorddrive.field.configSftpPathError": "文件存储路径不能包含‘./’",
  "cms.recorddrive.title.sftpIdentityCheck": "身份校验",
  "cms.recorddrive.message.sftpIdentityChecktips": "检测到您对SFTP Server的帐号或密码进行了修改。为了维护信息安全，请先输入您当前使用的帐号密码",
  "cms.recorddrive.placeholder.sftpIdentityChecktips": "请输入您的当前帐号密码",
  "cms.recorddrive.field.sftpIpadress": "SFTP IP地址",
  "cms.recorddrive.label.sftpPort": "SFTP 端口号",
  "cms.recorddrive.field.sftpPort": "端口号",
  "cms.recorddrive.title.sftpServerConfig": "SFTP Server配置",
  "cms.recorddrive.field.storageLimit": "最低门限值(M)",
  "cms.vcallecenter.field.adminworkno": "管理员工号",
  "cms.vcallecenter.title.authentication": "身份验证",
  "cms.vcallecenter.field.confirmnewpassword": "重复新密码",
  "cms.vcallecenter.field.confirmwaspassword": "重复密码",
  "cms.vcallecenter.field.curcountpassword": "当前帐号密码",
  "cms.vcallecenter.field.enternewpassword": "请输入新密码",
  "cms.vcallecenter.field.enterwaspassword": "请输入密码",
  "cms.vcallecenter.title.modifyinfo": "密码修改",
  "cms.vcallecenter.title.modifyinfo2": "密码配置",
  "cms.vcallecenter.title.modifyadministratorpassword": "CTI管理员密码",
  "cms.vcallecenter.title.modifybmspassword": "修改BMS接口密码",
  "cms.callecenter.field.setbmspwd": "CCBMS接口密码",
  "cms.callecenter.field.confirmbmspwd": "确认CCBMS接口密码",
  "cms.vcallecenter.title.modifyconfigurationinterfacepassword": "VDN配置接口密码(CCBCS)",
  "cms.vcallecenter.message.configurationinterfacenopassword": "配置接口密码未配置",
  "cms.vcallecenter.message.syncagentfail": "同步座席失败",
  "cms.vcallecenter.message.syncrolefail": "同步角色失败",
  "cms.vcallecenter.message.syncskillfail": "同步技能失败",
  "cms.vcallecenter.message.modifyadminpasswordfail": "修改密码失败",
  "cms.vcallecenter.message.currentacountpasderror": "当前帐号密码输入有误",
  "cms.vcallecenter.message.modifyadminpasswordsuccess": "修改密码成功",
  "cms.voiceinspection.field.batchDownload": "批量下载",
  "cms.voiceinspection.field.reset": "重置",
  "cms.voiceinspection.field.search": "查询",
  "cms.voiceinspection.message.downloadFailed": "下载失败",
  "cms.voiceinspection.message.downloadSucceed": "下载成功",
  "cms.record-playback.voice.playing": "播放",
  "cms.record-playback.voice.pause": "暂停",
  "cms.voiceinspection.field.audioPlay": "录音试听",
  "cms.voiceinspection.field.videoPlay": "视频播放",
  "cms.voiceinspection.field.download": "下载",
  "cms.voiceinspection.field.agentId": "座席",
  "cms.voiceinspection.field.beginTime": "录制开始时间",
  "cms.voiceinspection.field.queryBeginTime": "开始时间",
  "cms.voiceinspection.field.rate": "播放速度",
  "cms.voiceinspection.field.callId": "呼叫流水号",
  "cms.voiceinspection.field.callType": "呼叫类型",
  "cms.voiceinspection.field.calleeNo": "被叫号码",
  "cms.voiceinspection.field.callerNo": "主叫号码",
  "cms.voiceinspection.field.currentSkillId": "技能队列",
  "cms.voiceinspection.field.endTime": "录制结束时间",
  "cms.voiceinspection.field.queryEndTime": "结束时间",
  "cms.voiceinspection.field.operat": "操作",
  "cms.voiceinspection.field.serviceId": "业务类型",
  "cms.record-playback.callType0": "语音呼入",
  "cms.record-playback.callType1": "语音呼出",
  "cms.record-playback.callType2": "IVR呼出",
  "cms.record-playback.callType3": "OBS外呼",
  "cms.voiceinspection.field.textChat": "文字交谈",
  "cms.voiceinspection.field.clickToCall": "语音点击通话",
  "cms.voiceinspection.field.videoClickToCall": "视频点击通话",
  "cms.voiceinspection.field.callbackRequest": "回呼请求",
  "cms.voiceinspection.field.ordinaryVoiceCall": "语音",
  "cms.voiceinspection.field.predictOutbound": "预测呼出",
  "cms.voiceinspection.field.preViewOutbound": "预浏览呼出",
  "cms.voiceinspection.field.messageCall": "消息呼叫",
  "cms.voiceinspection.field.webphoneVideo": "视频点击通话",
  "cms.voiceinspection.field.phoneVideo": "视频普通呼叫",
  "cms.voiceinspection.field.socialChat": "多媒体",
  "cms.voiceinspection.field.nonRealTimeCall": "非实时呼叫",
  "cms.voiceinspection.field.video": "视频",
  "cms.voiceinspection.title.tips": "提示",
  "cms.voiceinspection.video.tolong.info": "当前录制的视频时长较长，请下载后播放",
  "cms.voiceinspection.message.selectVoice": "请选择需要下载的录音！",
  "cms.voiceinspection.title.error": "错误",
  "cms.voiceinspection.message.errorContent": "加载资源异常，请检查相关配置",
  "cms.voiceinspection.message.playsuccess": "播放成功",
  "cms.voiceinspection.message.playfailure": "播放失败",
  "cms.voiceinspection.message.errorTime": "日期查询范围不能跨月！",
  "cms.voiceinspection.message.frequentOperat": "操作过于频繁，请稍后再试",
  "cms.voiceinspection.message.frequentDownload": "下载过于频繁，请稍后再试",
  "cms.voiceinspection.message.frequentPlay": "试听过于频繁，请稍后再试",
  "cms.voiceinspection.message.frequentExport": "导出过于频繁，请稍后再试",
  "cms.voiceinspection.message.invalidParam": "入参错误",
  "cms.voiceinspection.message.invalidQcRelation": "质检关系不匹配",
  "cms.voiceinspection.message.noRecordFound": "找不到匹配的录音记录",
  "cms.voiceinspection.message.noSftpFound": "SFTP服务器连接信息查询失败",
  "cms.voiceinspection.message.downloadVoiceRecordFailed": "从SFTP服务器下载录音文件失败",
  "cms.agent.message.duplicatepwd": "新密码和原密码重复",
  "cms.agent.message.modifypasswordfailed": "密码修改失败",
  "cms.agent.message.modifypasswordhintdetail": "请填写密码字段信息",
  "cms.agent.message.modifypasswordsuccess": "密码修改成功",
  "cms.agent.title.modifypassword": "签入密码修改",
  "cms.callcenter.message.configcallcenter": "请配置呼叫中心！",
  "cms.callcenter.field.deletefailure": "删除失败",
  "cms.callcenter.message.deletefailed": "删除失败",
  "cms.callcenter.message.createfailed": "创建失败",
  "cms.callcenter.message.updatefailed": "更新失败",
  "cms.callcenter.message.sameletterovermaxnum": "相同盘符最多只允许添加8条数据",
  "cms.callcenter.field.savefailed": "失败",
  "cms.callcenter.field.cancel": "取消",
  "cms.callcenter.field.finish": "完成",
  "cms.callcenter.field.reset": "重置",
  "cms.callcenter.message.selectcallcent": "请选择呼叫中心！",
  "cms.callcenter.message.selectccgatewayservers": "请选择CC-Gateway服务器！",
  "cms.callcenter.field.deletesuccess": "删除成功",
  "cms.callcenter.message.deletesuccess": "删除成功",
  "cms.callcenter.message.updatesuccess": "更新成功",
  "cms.callcenter.field.savesuccess": "成功",
  "cms.callcenter.message.createsuccess": "创建成功",
  "cms.callcenterinstanceslist.title.callcenter": "呼叫中心",
  "cms.callcenterinstanceslist.message.ccgatewayServerBatchdeleteconfim": "是否确定删除所有已选择CC-Gateway服务器？",
  "cms.callcenterinstanceslist.message.ccgatewayServerSingledeleteconfim": "是否确定删除已选择CC-Gateway服务器？",
  "cms.callcenterinstanceslist.field.ccGatewayIp": "CC-Gateway IP地址",
  "cms.callcenterinstanceslist.field.ccGatewayPort": "CC-Gateway 端口号",
  "cms.callcenterinstanceslist.message.adminWorkNoError": "管理员工号起始号码必须在55001到59999范围内",
  "cms.callcenterinstanceslist.message.alreadyInuse": "工号号段被占用",
  "cms.callcenterinstanceslist.message.ccIdError": "呼叫中心编号已被使用",
  "cms.callcenterinstanceslist.message.ccIdUpdateError": "编辑呼叫中心时，呼叫中心编号禁止修改",
  "cms.callcenterinstanceslist.message.ccNameUpdateError": "编辑呼叫中心时，呼叫中心名称禁止修改",
  "cms.callcenterinstanceslist.message.ccNameUsed": "呼叫中心名称已被使用",
  "cms.callcenterinstanceslist.message.createSuccess": "呼叫中心创建成功",
  "cms.callcenterinstanceslist.message.createFailed": "呼叫中心创建失败",
  "cms.callcenterinstanceslist.message.error": "错误",
  "cms.callcenterinstanceslist.field.interfacePassword": "配置接口密码(CCBCS)",
  "cms.callcenterinstanceslist.message.ivrNumsError": "IVR通道数必须大于TTS通道数与ASR通道数之和",
  "cms.callcenterinstanceslist.label.nextStep": "下一步",
  "cms.callcenterinstanceslist.message.nodeIdCheckError": "请确保输入的CTI节点信息id、uapId与名称不重复",
  "cms.callcenterinstanceslist.message.odfConfigError": "ODFS服务配置信息错误",
  "cms.callcenterinstanceslist.field.passwordConfirm": "密码确认",
  "cms.callcenterinstanceslist.message.resourceError": "修改的资源信息小于呼叫中心实际分配数",
  "cms.callcenterinstanceslist.title.success": "成功",
  "cms.callcenterinstanceslist.field.sysAdminPassword": "CTI系统管理员密码",
  "cms.callcenterinstanceslist.field.systemAdmin": "CTI系统管理员工号",
  "cms.callcenterinstanceslist.title.callCenter": "呼叫中心",
  "cms.callcenterinstanceslist.message.updateFailed": "呼叫中心修改失败",
  "cms.callcenterinstanceslist.message.updateSuccess": "呼叫中心修改成功",
  "cms.callcenterinstanceslist.message.videoCallNumsError": "最大视频呼叫并发数必须大于等于视频座席数",
  "cms.callcenterinstanceslist.message.voiceCallNumsError": "最大语音呼叫并发数必须大于等于语音座席数与IVR通道数之和",
  "cms.callcenterinstanceslist.title.warning": "警告",
  "cms.callcenterinstanceslist.message.workNoCheckError": "工号起始号码必须小于结束号码",
  "cms.callcenterinstanceslist.message.workNoError": "工号起始号码必须在101到50000范围内",
  "cms.callcenterinstanceslist.label.callCenterRecordDrive": "呼叫中心录音盘符",
  "cms.callcenterinstanceslist.label.recordDrive": "录音盘符",
  "cms.callcenterinstanceslist.label.batchAdd": "批量分配号码",
  "cms.callcenterinstanceslist.field.tenantName": "租户名称",
  "cms.callcenterinstanceslist.title.baseInfo": "基本信息",
  "cms.callcenterinstanceslist.field.ccBcsIp": "CCBCS负载均衡地址",
  "cms.callcenterinstanceslist.field.ccBcsPort": "CCBCS负载均衡端口",
  "cms.callcenterinstanceslist.field.ccBmsIp": "CCBMS负载均衡地址",
  "cms.callcenterinstanceslist.field.ccBmsPort": "CCBMS负载均衡端口",
  "cms.callcenterinstanceslist.field.ccId": "呼叫中心编号",
  "cms.callcenterinstanceslist.field.ccName": "呼叫中心节点",
  "cms.callcenterinstanceslist.field.cmsIp": "CMS负载均衡地址",
  "cms.callcenterinstanceslist.field.cmsPort": "CMS负载均衡端口",
  "cms.callcenterinstanceslist.label.create": "新增",
  "cms.callcenterinstanceslist.label.delete": "删除",
  "cms.callcenterinstanceslist.message.deleteConfirmMessage": "是否确定删除已选择呼叫中心及相关配置？",
  "cms.callcenterinstanceslist.title.deleteconfim": "删除确认",
  "cms.callcenterinstanceslist.field.gwIp": "GATEWAY服务器地址",
  "cms.callcenterinstanceslist.field.gwPort": "GATEWAY服务器端口号",
  "cms.callcenterinstanceslist.field.operate": "操作",
  "cms.callcenterinstanceslist.label.refresh": "刷新",
  "cms.callcenterinstanceslist.label.search": "查询",
  "cms.callcenterinstanceslist.label.synchronize": "同步",
  "cms.callcenterinstanceslist.field.ctiversion": "CTI版本",
  "cms.callcenterinstanceslist.field.adminCount": "CTI管理员工号",
  "cms.callcenterinstanceslist.field.tenantSpaceName": "管理员帐号",
  "cms.callcenterinstanceslist.label.vCallCenter": "虚拟呼叫中心",
  "cms.callcenterinstanceslist.field.vccId": "虚拟呼叫中心编号",
  "cms.callcenterinstanceslist.field.vdnName": "虚拟呼叫中心名称",
  "cms.callcenterinstanceslist.message.passLengthValidateFailed": "密码的长度为8个字符",
  "cms.callcenterinstanceslist.message.passValidateFailed": "应包含大写字母，小写字母，数字，特殊字符{'~!@#$%^&*()-_=+|[{}];:\",<.>/?'}'中的任意三种",
  "cms.callcenterinstanceslist.message.passValidateContinuityFailed": "密码不能包含连续三个相同的字符",
  "cms.callcenterinstanceslist.message.syncPassValidateFailed": "应包含大写字母，小写字母，数字，特殊符号{'`~!@#$%^&*()-_=+|[{}];:'\",<.>/?'}中的任意三种",
  "cms.callcenterinstanceslist.label.save": "保存",
  "cms.callcenterinstanceslist.message.specialStrValidateFailed": "不允许包含特殊字符",
  "cms.callcenterinstanceslist.message.wasPsdLengthRangeValidateFailed": "密码长度应在16和32之间",
  "cms.callcenterinstanceslist.message.uapPsdLengthRangeValidateFailed": "密码长度应在6和32之间",
  "cms.callcenterinstanceslist.message.passWordLengthValidateFailed": "输入的密码长度必须在8到20之间",
  "cms.callcenterinstanceslist.label.assignDeveloper": "关联开发者",
  "cms.callcenterinstanceslist.field.developer": "关联开发者",
  "cms.callcenterinstanceslist.message.selectdeveloper": "请选择开发者",
  "cms.callcenterinstanceslist.title.nodemodeselect": "选择模式",
  "cms.callcenterinstanceslist.field.nodemodesingle": "Single模式",
  "cms.callcenterinstanceslist.field.nodemodepool": "Pool模式",
  "cms.callcenterinstanceslist.field.open": "开启",
  "cms.callcenterinstanceslist.field.close": "关闭",
  "cms.callcenterinstanceslist.title.chooseFeature": "特性选择",
  "cms.callcenterinstanceslist.field.ctiIndexMonitor": "CTI系统指标监控",
  "cms.callcenterinstanceslist.field.uapIndexMonitor": "UAP系统指标监控",
  "cms.callcenterinstanceslist.field.kafkaPushSwitch": "KAFKA数据推送",
  "cms.callcenterinstanceslist.field.ccStatus": "呼叫中心状态",
  "cms.callcenterinstanceslist.field.backupcc": "容灾呼叫中心",
  "cms.callcenterinstanceslist.field.normalStatus": "正常",
  "cms.callcenterinstanceslist.field.faultStatus": "故障",
  "cms.callcenterinstanceslist.message.maxNumber": "呼叫中心最多只允许添加10个",
  "cms.failurecall.field.accessCode": "接入码",
  "cms.failurecall.field.callSequenceNumber": "通话流水号",
  "cms.failurecall.field.queueName": "队列名称",
  "cms.failurecall.field.reset": "重置",
  "cms.failurecall.field.search": "查询",
  "cms.failurecall.field.userPhone": "用户号码",
  "cms.failurecall.field.waitTime": "等待时长",
  "cms.failurecall.message.currentFailureCall": "排队累计放弃：",
  "cms.failurecall.message.person": "人次",
  "cms.failurecall.placeholder.accessCodeInput": "请输入接入码",
  "cms.failurecall.placeholder.userPhoneInput": "请输入用户号码",
  "cms.vcallecenter.title.success": "成功",
  "cms.vcallecenter.message.syncSuccess": "同步成功",
  "cms.vcallecenter.message.syncLoading": "操作进行中，请稍后再试",
  "cms.vcallecenter.title.failed": "错误",
  "cms.vcallecenter.message.syncFailed": "同步失败",
  "cms.monitordetail.field.account": "帐号",
  "cms.monitordetail.field.adjustCount": "整理次数",
  "cms.monitordetail.field.adjustDuration": "整理时长（s）",
  "cms.monitordetail.field.agentReleaseCount": "主动收线次数",
  "cms.monitordetail.field.answerCount": "应答次数",
  "cms.monitordetail.field.audio": "音视频",
  "cms.monitordetail.field.avgTalkingDuration": "平均通话时长（s）",
  "cms.monitordetail.field.busy": "忙碌",
  "cms.monitordetail.field.busyCount": "示忙次数",
  "cms.monitordetail.field.busyDuration": "示忙时长（s）",
  "cms.monitordetail.field.busydurationavg": "示忙均长（s）",
  "cms.monitordetail.field.calling": "通话中",
  "cms.monitordetail.field.conferencecount": "三方通话次数",
  "cms.monitordetail.field.holdcount": "保持次数",
  "cms.monitordetail.field.holdduration": "保持时长（s）",
  "cms.monitordetail.field.holddurationavg": "保持均长（s）",
  "cms.monitordetail.field.idle": "空闲",
  "cms.monitordetail.field.idlecount": "示闲次数",
  "cms.monitordetail.field.innerforward": "内部转移次数",
  "cms.monitordetail.field.intercallcount": "内部呼叫次数",
  "cms.monitordetail.field.media": "多媒体",
  "cms.monitordetail.field.mediatype": "呼叫媒体类型",
  "cms.monitordetail.field.noanswercount": "久不应答次数",
  "cms.monitordetail.field.outboundcount": "成功呼出次数",
  "cms.monitordetail.field.outerforward": "转出次数",
  "cms.monitordetail.field.redirectcount": "重定向的次数",
  "cms.monitordetail.field.rest": "休息",
  "cms.monitordetail.field.restcount": "休息次数",
  "cms.monitordetail.field.restduration": "休息时长（s）",
  "cms.monitordetail.field.restoutcount": "休息超时次数",
  "cms.monitordetail.field.restoutduration": "休息超时时长（s）",
  "cms.monitordetail.field.role": "座席角色",
  "cms.monitordetail.field.signinskill": "签入队列",
  "cms.monitordetail.field.sipNumber": "签入电话",
  "cms.monitordetail.field.skill": "所属队列",
  "cms.monitordetail.field.totaltalkingcount": "总的通话次数",
  "cms.monitordetail.field.totaltalkingduration": "总的通话时长（s）",
  "cms.monitordetail.title.statistics": "统计信息",
  "cms.monitordetail.field.detail": "座席详细信息",
  "cms.monitordetail.field.information": "基本信息",
  "cms.monitordetail.field.duration": "时长统计信息",
  "cms.monitordetail.field.count": "次数统计信息",
  "cms.agentmonitor.label.autoRefresh": "自动刷新",
  "cms.agentmonitor.label.pageRefreshTime": "数据更新时间",
  "cms.qcrelationconf.field.accountCode": "业务帐号",
  "cms.qcrelationconf.field.accountName": "座席姓名",
  "cms.qcrelationconf.field.addGroupMember": "添加组成员",
  "cms.qcrelationconf.field.agentGroup": "话务组",
  "cms.qcrelationconf.field.cancel": "取消",
  "cms.qcrelationconf.field.delete": "删除",
  "cms.qcrelationconf.field.deleteGroupMember": "删除组成员",
  "cms.qcrelationconf.field.group": "用户组",
  "cms.qcrelationconf.field.groupInfo": "用户组基本信息",
  "cms.qcrelationconf.field.groupMemberInfo": "用户组成员信息",
  "cms.qcrelationconf.field.groupName": "用户组名称",
  "cms.qcrelationconf.field.groupType": "用户组类型",
  "cms.qcrelationconf.placeholder.groupType": "请选择",
  "cms.qcrelationconf.title.confirm": "确定",
  "cms.qcrelationconf.field.ok": "确定",
  "cms.qcrelationconf.field.operation": "操作",
  "cms.qcrelationconf.field.operator": "话务员",
  "cms.qcrelationconf.field.outgoingAgent": "外呼座席",
  "cms.qcrelationconf.field.configAdmin": "配置管理员",
  "cms.qcrelationconf.field.qcGroup": "质检组",
  "cms.qcrelationconf.field.qualityInspection": "质检员",
  "cms.qcrelationconf.field.remark": "备注",
  "cms.qcrelationconf.field.reset": "重置",
  "cms.qcrelationconf.field.role": "角色",
  "cms.qcrelationconf.field.search": "查询",
  "cms.qcrelationconf.field.workNo": "座席工号",
  "cms.qcrelationconf.message.addSuccess": "新增成功",
  "cms.qcrelationconf.message.deleteSuccess": "删除成功",
  "cms.qcrelationconf.message.isDeleteGroupInfo": "请确认是否删除该用户组",
  "cms.qcrelationconf.message.isDeleteGroupMember": "请确认是否删除组成员",
  "cms.qcrelationconf.message.paramOutOfRange": "输入不能超过100个字符",
  "cms.qcrelationconf.message.queryFailed": "查询失败",
  "cms.qcrelationconf.message.queryFailedLimit": "每页记录数必须大于0，并且小于101",
  "cms.qcrelationconf.message.queryParamNull": "座席工号和用户组名称不能同时为空",
  "cms.qcrelationconf.message.selectAgent": "请选择需要删除的座席",
  "cms.qcrelationconf.message.selectGroup": "请选择需要删除的用户组",
  "cms.qcrelationconf.message.selectGroupType": "请先选择用户组类型",
  "cms.qcrelationconf.message.specialChar": "非法字符",
  "cms.qcrelationconf.message.updateSuccess": "更新成功",
  "cms.qcrelationconf.placeholder.pleaseInput": "请输入",
  "cms.qcrelationconf.placeholder.workNo": "请输入座席工号",
  "cms.qcrelationconf.placeholder.groupName": "用户组",
  "cms.qcrelationconf.title.error": "错误",
  "cms.qcrelationconf.title.success": "成功",
  "cms.qcrelationconf.title.warning": "提示",
  "cms.qcrelationconf.placeholder.workName": "请输入座席姓名",
  "cms.qcrelationconf.placeholder.accountCode": "请输入业务帐号",
  "cms.qcrelationconf.field.qcGroupName": "质检方组名称",
  "cms.qcrelationconf.field.qcWorkNo": "质检人工号",
  "cms.qcrelationconf.field.qcAccountName": "质检人姓名",
  "cms.qcrelationconf.field.qcCcRoleName": "质检人角色",
  "cms.qcrelationconf.field.agentGroupName": "被质检组",
  "cms.qcrelationconf.field.agentWorkNo": "被质检人工号",
  "cms.qcrelationconf.field.agentAccountName": "被质检人姓名",
  "cms.qcrelationconf.field.agentCcRoleName": "被质检人角色",
  "cms.recorddrive.placeholder.deleteRecorddrive": "请选择要删除的录音盘符",
  "cms.recorddrive.placeholder.batchdeleteconfim": "是否确定删除所有已选择的录音盘符？",
  "cms.recorddrive.placeholder.singledeleteconfim": "是否确定删除已选择的录音盘符？",
  "cms.tenant.message.adminpassword": "密码",
  "cms.tenant.message.adminpasswordagain": "再次输入密码",
  "cms.tenant.message.pwdCheckmsg": "两次输入的密码不一致",
  "cms.tenant.message.tenantspaceOk": "确定",
  "cms.indexmonitor.title.callCenterDayMonitor": "呼叫中心当日指标统计",
  "cms.indexmonitor.title.callCenterDayTrend": "呼叫中心当日趋势",
  "cms.indexmonitor.title.vdnNowMonitor": "虚拟呼叫中心运行实时监控",
  "cms.indexmonitor.title.vdnDayMonitor": "虚拟呼叫中心当日指标统计",
  "cms.indexmonitor.title.vdnDayTrend": "虚拟呼叫中心当日趋势",
  "cms.indexmonitor.title.filteringIndicator": "筛选指标",
  "cms.indexmonitor.title.error": "错误",
  "cms.indexmonitor.title.success": "成功",
  "cms.indexmonitor.field.confirm": "确定",
  "cms.indexmonitor.field.cancel": "取消",
  "cms.indexmonitor.message.warningFiltering": "请选择大于等于1个指标",
  "cms.indexmonitor.message.updateSuccess": "更新成功",
  "cms.indexmonitor.message.updateFailed": "更新失败",
  "cms.indexmonitor.field.chooseAll": "选择所有",
  "cms.indexmonitor.field.totalCallNum": "总呼叫数",
  "cms.indexmonitor.message.totalCallNum": "呼入数+呼出数",
  "cms.indexmonitor.field.totalQueueCallNum": "总排队数",
  "cms.indexmonitor.message.totalQueueCallNum": "在技能队列中排队，等待人工座席服务的通话数",
  "cms.indexmonitor.field.totalConnectCallNum": "总通话数",
  "cms.indexmonitor.field.totalAgentConnectCallNum": "通话态次数",
  "cms.indexmonitor.field.totalAgentConnectCallTime": "通话态时长",
  "cms.indexmonitor.message.totalAgentCallNumDesc": "座席接通呼叫的总次数（统计周期为当次签入）",
  "cms.indexmonitor.message.totalConnectCallNum": "呼入接通数+呼出接通数",
  "cms.indexmonitor.field.totalAbandonedCallNum": "总呼损数",
  "cms.indexmonitor.message.totalAbandonedCallNum": "总呼叫数-总通话数",
  "cms.indexmonitor.field.inboundCallNum": "呼入数",
  "cms.indexmonitor.message.inboundCallNum": "人工呼入数+IVR呼入数",
  "cms.indexmonitor.field.inboundConnectNum": "呼入接通数",
  "cms.indexmonitor.message.inboundConnectNum": "人工呼入接通数+IVR呼入接通数",
  "cms.indexmonitor.field.outboundCallNum": "呼出数",
  "cms.indexmonitor.message.outboundCallNum": "人工呼出数+IVR呼出数",
  "cms.indexmonitor.field.outboundConnectNum": "呼出接通数",
  "cms.report.field.outOccupyNumDesc": "座席呼出呼叫接通的次数",
  "cms.report.field.threePartyCallCountDesc": "座席三方通话的通话次数",
  "cms.indexmonitor.message.outboundConnectNum": "人工呼出接通数+IVR呼出接通数",
  "cms.indexmonitor.field.agentInboundNum": "人工呼入数",
  "cms.indexmonitor.message.agentInboundNum": "呼叫被分配给技能队列或者座席的呼入个数",
  "cms.indexmonitor.field.agentInboundConnectNum": "人工呼入接通数",
  "cms.indexmonitor.message.agentInboundConnectNum": "人工呼入数中被座席接通的次数",
  "cms.indexmonitor.field.agentOutboundNum": "人工呼出数",
  "cms.indexmonitor.message.agentOutboundNum": "人工呼出的次数",
  "cms.indexmonitor.field.agentOutboundConnectNum": "人工呼出接通数",
  "cms.indexmonitor.message.agentOutboundConnectNum": "人工呼出时被叫方应答的次数",
  "cms.indexmonitor.field.inboundConnectRate": "呼入接通率",
  "cms.indexmonitor.message.inboundConnectRate": "呼入接通数占呼入数的百分比",
  "cms.indexmonitor.field.inboundAbandonRate": "呼入放弃率",
  "cms.indexmonitor.message.inboundAbandonRate": "未被接通的呼入呼叫数占呼入呼叫数的百分比，若呼入呼叫数为0，该指标值为0",
  "cms.indexmonitor.field.outboundConnectRate": "呼出接通率",
  "cms.indexmonitor.field.outboundAbandonRate": "呼出放弃率",
  "cms.indexmonitor.message.outboundConnectRate": "呼出接通数占呼出数的百分比",
  "cms.indexmonitor.message.outboundAbandonRate": "未被接通的呼出呼叫数占呼出呼叫数的百分比，若呼出呼叫数为0，该指标值为0",
  "cms.indexmonitor.field.agentBoundConnectRate": "人工呼入接通率",
  "cms.indexmonitor.message.agentBoundConnectRate": "人工接通数占人工呼入数的百分比",
  "cms.indexmonitor.field.agentBoundAbandonRate": "人工呼入放弃率",
  "cms.indexmonitor.message.agentBoundAbandonRate": "100-人工呼入接通率",
  "cms.indexmonitor.field.abandonInThree": "3秒内放弃率",
  "cms.indexmonitor.message.abandonInThree": "3秒内（包括3秒）放弃的呼叫占总放弃数的百分比",
  "cms.indexmonitor.field.abandonInFive": "5秒内放弃率",
  "cms.indexmonitor.message.abandonInFive": "5秒内（包括5秒）放弃的呼叫占总放弃数的百分比",
  "cms.indexmonitor.field.abandonInTen": "10秒内放弃率",
  "cms.indexmonitor.message.abandonInTen": "10秒内（包括10秒）放弃的呼叫占总放弃数的百分比",
  "cms.indexmonitor.field.abandonInThirty": "30秒内放弃率",
  "cms.indexmonitor.message.abandonInThirty": "30秒内（包括30秒）放弃的呼叫占总放弃数的百分比",
  "cms.indexmonitor.field.abandonInSixty": "60秒内放弃率",
  "cms.indexmonitor.message.abandonInSixty": "60秒内（包括60秒）放弃的呼叫占总放弃数的百分比",
  "cms.indexmonitor.field.agentConnectInThree": "3秒内人工接通率",
  "cms.indexmonitor.message.agentConnectInThree": "3秒内（包括3秒）的人工接通数占人工接通总数的百分比",
  "cms.indexmonitor.field.agentConnectInFive": "5秒内人工接通率",
  "cms.indexmonitor.message.agentConnectInFive": "5秒内（包括5秒）的人工接通数占人工接通总数的百分比",
  "cms.indexmonitor.field.agentConnectInTen": "10秒内人工接通率",
  "cms.indexmonitor.message.agentConnectInTen": "10秒内（包括10秒）的人工接通数占人工接通总数的百分比",
  "cms.indexmonitor.field.agentConnectInThirty": "30秒内人工接通率",
  "cms.indexmonitor.message.agentConnectInThirty": "30秒内（包括30秒）的人工接通数占人工接通总数的百分比",
  "cms.indexmonitor.field.agentConnectInSixty": "60秒内人工接通率",
  "cms.indexmonitor.message.agentConnectInSixty": "60秒内（包括60秒）的人工接通数占人工接通总数的百分比",
  "cms.indexmonitor.field.agentConnectAfterInTen": "10秒外人工接通率",
  "cms.indexmonitor.message.agentConnectAfterInTen": "超过10秒的人工接通数占人工接通总数的百分比",
  "cms.indexmonitor.field.agentConnectAfterInSixty": "60秒外人工接通率",
  "cms.indexmonitor.message.agentConnectAfterInSixty": "超过60秒的人工接通数占人工接通总数的百分比",
  "cms.indexmonitor.field.durationAvgVdnInbound": "呼入占用VDN均长",
  "cms.indexmonitor.message.durationAvgVdnInbound": "呼入占用VDN总时长/总呼入数",
  "cms.indexmonitor.field.durationAvgVdnOutbound": "呼出占用VDN均长",
  "cms.indexmonitor.message.durationAvgVdnOutbound": "呼出VDN时长/总呼出数",
  "cms.indexmonitor.field.durationAvgConnect": "通话均长",
  "cms.indexmonitor.message.durationAvgConnect": "总通话时长/总通话数",
  "cms.indexmonitor.field.durationAvgWait": "呼入等待均长",
  "cms.indexmonitor.message.durationAvgWait": "呼入通话的平均等待时长",
  "cms.indexmonitor.field.totalAgentNum": "总座席数",
  "cms.indexmonitor.message.totalAgentNum": "当前所有座席总数",
  "cms.indexmonitor.field.totalTalkingNum": "通话中座席数",
  "cms.indexmonitor.message.totalTalkingNum": "当前正在处理呼叫操作的座席数",
  "cms.indexmonitor.field.totalLoginNum": "签入座席数",
  "cms.indexmonitor.message.totalLoginNum": "当前签入呼叫中心的座席数",
  "cms.indexmonitor.message.totalLoginNumDesc": "当前签入呼叫中心的座席数(只包含接听呼入的座席)",
  "cms.indexmonitor.field.totalLogoutNum": "签出座席数",
  "cms.indexmonitor.message.totalLogoutNum": "当前未签入呼叫中心，处于离线态的座席数",
  "cms.indexmonitor.field.totalBusyNum": "示忙座席数",
  "cms.indexmonitor.message.totalBusyNum": "当前处于示忙态的座席数",
  "cms.indexmonitor.field.totalIdeaNum": "示闲座席数",
  "cms.indexmonitor.message.totalIdeaNum": "当前处于空闲状态，可以立即处理呼叫操作的座席数",
  "cms.indexmonitor.field.totalLearningNum": "学习座席数",
  "cms.indexmonitor.message.totalLearningNum": "当前处于学习状态的座席数",
  "cms.indexmonitor.field.totalAwcNum": "整理座席数",
  "cms.indexmonitor.message.totalAwcNum": "座席受理完业务，处于整理状态的座席数",
  "cms.indexmonitor.field.totalAnsweringNum": "应答座席数",
  "cms.indexmonitor.message.totalAnsweringNum": "电话处于振铃状态的座席数",
  "cms.indexmonitor.field.totalRestNum": "休息座席数",
  "cms.indexmonitor.message.totalRestNum": "当前处于休息状态的座席数",
  "cms.indexmonitor.field.callNum": "呼叫数",
  "cms.indexmonitor.field.realTimeCalls": "实时呼叫数",
  "cms.indexmonitor.field.callsOfTheDay": "当日呼叫数",
  "cms.indexmonitor.field.callRate": "呼叫率",
  "cms.indexmonitor.field.duration": "时长",
  "cms.indexmonitor.field.agentNum": "座席数",
  "cms.indexmonitor.field.agentName": "租户名称",
  "cms.indexmonitor.field.pageDataUpdateTime": "数据更新时间",
  "cms.indexmonitor.field.ccName": "呼叫中心节点",
  "cms.indexmonitor.title.selectCcName": "选择呼叫中心",
  "cms.indexmonitor.title.selectAgentName": "选择租户",
  "cms.indexmonitor.field.all": "全部",
  "cms.indexmonitor.field.status": "状态",
  "cms.indexmonitor.label.reset": "重置",
  "cms.indexmonitor.label.search": "查询",
  "cms.indexmonitor.label.previous": "上一步",
  "cms.indexmonitor.label.next": "下一步",
  "cms.indexmonitor.field.indicatorName": "指标名称",
  "cms.indexmonitor.field.description": "描述",
  "cms.indexmonitor.label.CommercialUse": "商用",
  "cms.indexmonitor.label.noncommercial": "试商用",
  "cms.indexmonitor.label.callCenterNode": "呼叫中心节点",
  "cms.indexmonitor.field.ivrCallNum": "IVR呼入请求数",
  "cms.indexmonitor.message.ivrCallNum": "当前由IVR设备提供自动服务的呼叫数",
  "cms.indexmonitor.field.ivrTotalCallNum": "全IVR呼入数",
  "cms.indexmonitor.message.ivrTotalCallNum": "呼叫被分配给IVR，完全由IVR为客户服务的次数",
  "cms.indexmonitor.field.ivrInboundNum": "IVR呼入接通数",
  "cms.indexmonitor.message.ivrInboundNum": "IVR呼入请求数中通话时长大于0的次数",
  "cms.indexmonitor.field.ivrOutboundNum": "IVR呼出接通数",
  "cms.indexmonitor.message.ivrOutboundNum": "IVR呼出时被叫方应答的次数",
  "cms.indexmonitor.field.ivrInboundFlowOut": "IVR呼入流出数",
  "cms.indexmonitor.message.ivrInboundFlowOut": "IVR放音后呼叫转移到另外一个设备的次数",
  "cms.indexmonitor.field.ivrInboundUserAbort": "IVR呼入用户释放数",
  "cms.indexmonitor.message.ivrInboundUserAbort": "用户在IVR中主动挂断的次数",
  "cms.indexmonitor.field.ivrInboundConnectRate": "IVR呼入接通率",
  "cms.indexmonitor.message.ivrInboundConnectRate": "IVR呼入接通数在IVR呼入请求数中所占的百分比",
  "cms.indexmonitor.field.ivrDurationAvgConnect": "IVR通话均长",
  "cms.indexmonitor.message.ivrDurationAvgConnect": "IVR通话总时长/IVR通话数",
  "cms.indexmonitor.title.ivrDayMonitor": "IVR当日指标统计",
  "cms.indexmonitor.title.ivrNowMonitor": "IVR运行实时监控",
  "cms.indexmonitor.label.callOutIVR": "IVR在线呼出数",
  "cms.indexmonitor.label.callInIVR": "IVR在线呼入数",
  "cms.indexmonitor.field.callOutIVR": "IVR在线呼出数",
  "cms.indexmonitor.field.callInIVR": "IVR在线呼入数",
  "cms.indexmonitor.title.ivrDayTrend": "IVR当日趋势",
  "cms.indexmonitor.message.mediaTypeMetion": "媒体类型筛选项仅对当日指标和当日趋势有效",
  "cms.developerlist.label.create": "新增",
  "cms.developerlist.label.delete": "删除",
  "cms.developerlist.label.refreshsk": "更新SK",
  "cms.developerlist.field.ak": "开发者AK",
  "cms.developerlist.field.description": "开发者描述",
  "cms.developerlist.field.operate": "操作",
  "cms.developerlist.title.create": "新增开发者",
  "cms.developerlist.title.deleteconfim": "删除确认",
  "cms.developerlist.message.deleteConfirmMessage": "是否确定删除已选开发者及相关配置？",
  "cms.developerlist.title.success": "成功",
  "cms.developerlist.message.deletesuccess": "删除成功",
  "cms.developerlist.message.error": "错误",
  "cms.developerlist.message.deletefailed": "删除失败",
  "cms.developerlist.title.refreshsk": "更新SK",
  "cms.developerlist.message.refreshConfirmMessage": "是否确定要刷新开发者的SK信息？",
  "cms.developerlist.message.refreshfailed": "更新失败",
  "cms.developerlist.message.createfailed": "创建失败",
  "cms.developerlist.message.checkInput": "输入内容不能包含特殊字符",
  "cms.developer.message.addDeveloper": "新增开发者信息",
  "cms.developer.message.modifyDeveloper": "更新开发者信息",
  "cms.developerlist.field.updateTime": "修改时间",
  "cms.developerlist.field.createTime": "创建时间",
  "cms.developerlist.field.search": "查询",
  "cms.callcenterinstanceslist.field.developerDesc": "开发者描述",
  "cms.indexmonitor.field.skillRequest": "请求服务数",
  "cms.indexmonitor.field.skillConnected": "请求接通数",
  "cms.indexmonitor.field.skillAbandon": "请求呼损数",
  "cms.indexmonitor.field.skillUserAbandon": "用户放弃数",
  "cms.indexmonitor.field.skillAgentAbandon": "座席挂机数",
  "cms.indexmonitor.field.skillQueueDisconnected": "排队拆线数",
  "cms.indexmonitor.field.skillSystemLost": "平台中断数",
  "cms.indexmonitor.field.skillLostRing": "应答无通话数",
  "cms.indexmonitor.field.skillTimeoutQueue": "超时转队列数",
  "cms.indexmonitor.field.skillTimeoutAgent": "超时转座席数",
  "cms.indexmonitor.field.skillOverQueue": "溢出转队列数",
  "cms.indexmonitor.field.skillOverAgent": "溢出转座席数",
  "cms.indexmonitor.field.skillConnectedIn3": "3秒内接通数",
  "cms.indexmonitor.field.skillConnectedIn5": "5秒内接通数",
  "cms.indexmonitor.field.skillConnectedIn10": "10秒内接通数",
  "cms.indexmonitor.field.skillConnectedIn20": "20秒内接通数",
  "cms.indexmonitor.field.skillConnectedIn30": "30秒内接通数",
  "cms.indexmonitor.field.skillConnectedIn60": "60秒内接通数",
  "cms.indexmonitor.field.skillAbandonIn3": "3秒内放弃数",
  "cms.indexmonitor.field.skillAbandonIn5": "5秒内放弃数",
  "cms.indexmonitor.field.skillAbandonIn10": "10秒内放弃数",
  "cms.indexmonitor.field.skillAbandonIn20": "20秒内放弃数",
  "cms.indexmonitor.field.skillAbandonIn30": "30秒内放弃数",
  "cms.indexmonitor.field.skillAbandonIn60": "60秒内放弃数",
  "cms.indexmonitor.field.skillConnectedAfter60": "60秒外接通数",
  "cms.indexmonitor.field.skillConnectedAfter10": "10秒外接通数",
  "cms.indexmonitor.field.skillAbandonAfter60": "60秒外放弃数",
  "cms.indexmonitor.field.skillAbandonAfter10": "10秒外放弃数",
  "cms.indexmonitor.field.skillConnectedRate": "请求接通率",
  "cms.indexmonitor.field.SkillAbandonRate": "请求呼损率",
  "cms.indexmonitor.field.skillConnectedIn3Rate": "3秒内接通率",
  "cms.indexmonitor.field.skillConnectedIn5Rate": "5秒内接通率",
  "cms.indexmonitor.field.skillConnectedIn10Rate": "10秒内接通率",
  "cms.indexmonitor.field.skillConnectedIn20Rate": "20秒内接通率",
  "cms.indexmonitor.field.skillConnectedIn30Rate": "30秒内接通率",
  "cms.indexmonitor.field.skillConnectedIn60Rate": "60秒内接通率",
  "cms.indexmonitor.field.skillAbandonIn3Rate": "3秒内放弃率",
  "cms.indexmonitor.field.skillAbandonIn5Rate": "5秒内放弃率",
  "cms.indexmonitor.field.skillAbandonIn10Rate": "10秒内放弃率",
  "cms.indexmonitor.field.skillAbandonIn20Rate": "20秒内放弃率",
  "cms.indexmonitor.field.skillAbandonIn30Rate": "30秒内放弃率",
  "cms.indexmonitor.field.skillAbandonIn60Rate": "60秒内放弃率",
  "cms.indexmonitor.field.skillAvgConnected": "通话均长",
  "cms.indexmonitor.field.skillAvgQueueAck": "等待均长",
  "cms.indexmonitor.field.skillAvgConnectedAck": "通话应答均长",
  "cms.indexmonitor.field.skillAvgAbandonAck": "呼损应答均长",
  "cms.indexmonitor.field.skillAvgConnectedQueue": "通话排队均长",
  "cms.indexmonitor.field.skillAvgAbandonQueue": "呼损排队均长",
  "cms.indexmonitor.field.skillMaxQueueAck": "最大等待时长",
  "cms.indexmonitor.field.skillMinQueueAck": "最小等待时长",
  "cms.indexmonitor.field.skillConnectedQueue": "通话排队总时长",
  "cms.indexmonitor.field.skillAbandonQueue": "呼损排队总时长",
  "cms.indexmonitor.title.skillNowMonitor": "技能队列运行实时监控",
  "cms.indexmonitor.title.skillDayMonitor": "技能队列当日指标统计",
  "cms.indexmonitor.title.skillDayTrend": "技能队列当日趋势",
  "cms.indexmonitor.title.skillQueueStatistics": "技能队列当日统计",
  "cms.indexmonitor.title.agentNowMonitor": "座席运行实时监控",
  "cms.indexmonitor.title.agentDayMonitor": "座席当日指标统计",
  "cms.indexmonitor.title.agentDayTrend": "座席当日趋势",
  "cms.indexmonitor.message.skillRequestDesc": "来话请求人工服务的次数",
  "cms.indexmonitor.message.skillConnectedDesc": "由此技能队列的座席接通的次数",
  "cms.indexmonitor.message.skillAbandonDesc": "人工呼入请求呼损数 = 人工呼入请求服务数 - 人工呼入请求接通数",
  "cms.indexmonitor.message.skillUserAbandonDesc": "用户在排队和振铃时主动放弃呼叫的次数",
  "cms.indexmonitor.message.skillAgentAbandonDesc": "应答时座席主动挂断呼叫的次数",
  "cms.indexmonitor.message.skillQueueDisconnectedDesc": "来话在排队时，平台拆线的次数",
  "cms.indexmonitor.message.skillSystemLostDesc": "座席应答超时等原因引起的平台中断次数",
  "cms.indexmonitor.message.skillLostRingDesc": "呼叫分配给座席后无通话的次数",
  "cms.indexmonitor.message.skillTimeoutQueueDesc": "由于在队列中排队超时从原有的技能队列转移至其它技能队列的呼叫数",
  "cms.indexmonitor.message.skillTimeoutAgentDesc": "由于在队列中排队超时从原有的技能队列转移至其它座席的呼叫数",
  "cms.indexmonitor.message.skillOverQueueDesc": "由于队列满从原有的技能队列溢出，转移至其它队列的次数",
  "cms.indexmonitor.message.skillOverAgentDesc": "由于队列满从原有的技能队列溢出，转移至其它座席的次数",
  "cms.indexmonitor.message.skillConnectedInNDesc": "N秒内（包括N秒）人工接通的请求数。当前支持3秒、5秒、10秒、20秒、30秒、60秒",
  "cms.indexmonitor.message.skillAbandonInNDesc": "N秒内（包括N秒）人工放弃的请求数。当前支持3秒、5秒、10秒、20秒、30秒、60秒",
  "cms.indexmonitor.message.skillConnectedAfterNDesc": "超过N秒人工接通的请求数。当前支持10秒、60秒",
  "cms.indexmonitor.message.skillAbandonAfterNDesc": "超过N秒人工放弃的请求数。当前支持10秒、60秒",
  "cms.indexmonitor.message.skillConnectedRateDesc": "人工呼入请求接通数占人工呼入请求服务数的百分比",
  "cms.indexmonitor.message.SkillAbandonRateDesc": "呼损率(%) = 100(%) - 接通率(%)",
  "cms.indexmonitor.message.skillConnectedInNRate": "N秒内（包括N秒）人工接通的请求数占请求人工数的百分比",
  "cms.indexmonitor.message.skillAbandonInNRateDesc": "N秒内（包括N秒）人工放弃的请求数占请求人工数的百分比",
  "cms.indexmonitor.message.skillAvgConnectedDesc": "通话的平均时长。人工呼入通话均长 = 人工呼入通话时长 / 人工呼入通话数",
  "cms.indexmonitor.message.skillAvgQueueAckDesc": "通话的平均等待时长。所有呼叫在队列设备上排队和座席应答的平均等待时长",
  "cms.indexmonitor.message.skillAvgConnectedAckDesc": "所有被接通的呼叫在队列中的平均应答时长",
  "cms.indexmonitor.message.skillAvgAbandonAckDesc": "所有未被接通的呼叫平均应答时长",
  "cms.indexmonitor.message.skillAvgConnectedQueueDesc": "所有被接通的呼叫在队列中的平均排队时长",
  "cms.indexmonitor.message.skillAvgAbandonQueueDesc": "所有未被接通的呼叫在队列中的平均排队时长",
  "cms.indexmonitor.message.skillMaxQueueAckDesc": "所有呼叫在队列设备上的排队和座席应答的最大等待时长",
  "cms.indexmonitor.message.skillMinQueueAckDesc": "所有呼叫在队列设备上的排队和座席应答的最小等待时长",
  "cms.indexmonitor.message.skillConnectedQueue": "所有被接通的呼叫在队列中的排队总时长",
  "cms.indexmonitor.message.skillAbandonQueue": "所有未被接通的呼叫在队列中的总时长",
  "cms.indexmonitor.message.errorInfo": "请至少选择一个技能队列",
  "cms.indexmonitor.message.errorAgentInfo": "请至少选择一个座席",
  "cms.indexmonitor.message.errorOrgInfo": "请先选择组织机构",
  "cms.indexmonitor.message.skillIdMaxSize": "最多只能选择5个技能队列",
  "cms.qualitycheck.message.adjustskill": "您的技能队列已被临时调整为{skillList}，重新签入后将恢复原有技能",
  "cms.agentmonitor.label.cancelInsert": "取消插入",
  "cms.agentmonitor.label.cancellistening": "取消监听",
  "cms.indexmonitor.field.currentSelectedSkill": "当前选择的技能队列：",
  "cms.indexmonitor.message.queryCallCenterError": "当前选择呼叫中心查询异常",
  "cms.indexmonitor.field.agentInCallCount": "座席在线应答数",
  "cms.indexmonitor.field.agentOutCallCount": "座席在线呼出数",
  "cms.indexmonitor.field.queueInCallCount": "在线呼入排队数",
  "cms.indexmonitor.field.queueOutCallCount": "在线呼出排队数",
  "cms.indexmonitor.field.totalInCallCount": "在线呼入总数",
  "cms.indexmonitor.message.agentInCallCount": "呼入呼叫接通的次数（不包含多媒体会话）",
  "cms.indexmonitor.message.InCallAgentCount": "呼入呼叫接通的次数",
  "cms.indexmonitor.message.totalCallNumDesc": "座席接通呼叫的总次数",
  "cms.indexmonitor.message.callOutSuccNum": "座席呼出呼叫接通的次数",
  "cms.indexmonitor.message.agentConnectedIn3NDesc": "应答时长短于3秒（包括3秒）的总通话数",
  "cms.indexmonitor.message.agentConnectedIn5NDesc": "应答时长短于5秒（包括5秒）的总通话数",
  "cms.indexmonitor.message.agentConnectedIn10NDesc": "应答时长短于10秒（包括10秒）的总通话数",
  "cms.indexmonitor.message.agentConnectedIn30NDesc": "应答时长短于30秒（包括30秒）的总通话数",
  "cms.indexmonitor.message.agentConnectedIn60NDesc": "应答时长短于60秒（包括60秒）的总通话数",
  "cms.indexmonitor.message.agentAbandonIn3NDesc": "应答时长短于3秒（包括3秒）的总放弃数",
  "cms.indexmonitor.message.agentAbandonIn5NDesc": "应答时长短于5秒（包括5秒）的总放弃数",
  "cms.indexmonitor.message.agentAbandonIn10NDesc": "应答时长短于10秒（包括10秒）的总放弃数",
  "cms.indexmonitor.message.agentAbandonIn30NDesc": "应答时长短于30秒（包括30秒）的总放弃数",
  "cms.indexmonitor.message.agentAbandonIn60NDesc": "应答时长短于60秒（包括60秒）的总放弃数",
  "cms.indexmonitor.message.agentConnectedOut10NDesc": "应答时长大于10秒的总接通数",
  "cms.indexmonitor.message.agentConnectedOut60NDesc": "应答时长大于60秒的总接通数",
  "cms.indexmonitor.message.agentAbandonOut10NDesc": "应答时长大于10秒的总放弃数",
  "cms.indexmonitor.message.agentAbandonOut60NDesc": "应答时长大于60秒的总放弃数",
  "cms.indexmonitor.message.agentConnectIn3Rate": "3秒内（包括3秒）人工接通数占总通话数的百分比",
  "cms.indexmonitor.message.agentConnectIn5Rate": "5秒内（包括5秒）人工接通数占总通话数的百分比",
  "cms.indexmonitor.message.agentConnectIn10Rate": "10秒内（包括10秒）人工接通数占总通话数的百分比",
  "cms.indexmonitor.message.agentConnectIn30Rate": "30秒内（包括30秒）人工接通数占总通话数的百分比",
  "cms.indexmonitor.message.agentConnectIn60Rate": "60秒内（包括60秒）人工接通数占总通话数的百分比",
  "cms.indexmonitor.message.agentAbandonIn3Rate": "3秒内（包括3秒）放弃的呼叫数占总放弃数的百分比",
  "cms.indexmonitor.message.agentAbandonIn5Rate": "5秒内（包括5秒）放弃的呼叫数占总放弃数的百分比",
  "cms.indexmonitor.message.agentAbandonIn10Rate": "10秒内（包括10秒）放弃的呼叫数占总放弃数的百分比",
  "cms.indexmonitor.message.agentAbandonIn30Rate": "30秒内（包括30秒）放弃的呼叫数占总放弃数的百分比",
  "cms.indexmonitor.message.agentAbandonIn60Rate": "60秒内（包括60秒）放弃的呼叫数占总放弃数的百分比",
  "cms.indexmonitor.message.agentOutCallCount": "当前座席呼出呼叫的个数（不包含多媒体会话）",
  "cms.indexmonitor.message.queueInCallCount": "当前在技能队列排队的呼入呼叫个数（不包含多媒体会话）",
  "cms.indexmonitor.message.queueOutCallCount": "当前在技能队列排队的呼出呼叫个数（不包含多媒体会话）",
  "cms.indexmonitor.message.totalInCallCount": "当前呼入呼叫的总个数（不包含多媒体会话）",
  "cms.indexmonitor.message.ivrInCallCount": "当前服务设备为IVR的呼入呼叫个数",
  "cms.indexmonitor.message.ivrOutCallCount": "当前服务设备为IVR的呼出呼叫个数",
  "cms.indexmonitor.field.skillOnlineInbondCall": "在线呼叫数",
  "cms.indexmonitor.field.skillOnlineWaitCall": "在线排队数",
  "cms.indexmonitor.field.skillOnlineConnected": "5分钟人工应答数",
  "cms.indexmonitor.message.skillOnlineInbondCallDesc": "正在处理的呼叫数 + 当前正在排队的呼叫数",
  "cms.indexmonitor.message.skillOnlineWaitCallDesc": "当前正在排队的呼叫数",
  "cms.indexmonitor.message.skillOnlineConnectedDesc": "当前五分钟时间段内人工应答数。五分钟时间段划分为0:00至0:05、0:05至00:10，以此类推",
  "cms.indexmonitor.message.skillLimit": "请选择1-10个技能队列",
  "cms.indexmonitor.field.allType": "所有类型",
  "cms.report.title.error": "错误",
  "cms.report.label.reset": "重置",
  "cms.report.label.search": "查询",
  "cms.report.field.startTime": "开始日期",
  "cms.report.field.endTime": "结束日期",
  "cms.report.field.skillId": "技能队列编号",
  "cms.report.field.skillName": "技能队列名称",
  "cms.report.field.agentId": "座席工号",
  "cms.report.field.date": "日期",
  "cms.report.field.inOccupyNum": "请求服务数",
  "cms.report.field.inCallSuccNum": "人工呼入接通数",
  "cms.report.field.callSuccRate": "请求接通率",
  "cms.report.field.inCallFailNum": "请求呼损数",
  "cms.report.field.call3Rate": "15秒内人工呼入接通率",
  "cms.report.field.call4Rate": "20秒内人工呼入接通率",
  "cms.report.field.call6Rate": "30秒内人工呼入接通率",
  "cms.report.field.succWaitAnsRate": "通话等待均长",
  "cms.report.field.failQueueWaitRate": "呼损应答均长",
  "cms.report.field.inCallRate": "通话均长",
  "cms.report.field.waitAnsRate": "人工呼入应答均长",
  "cms.report.field.abortQueueWaitNum": "用户排队放弃数",
  "cms.report.field.noAckNum": "应答无通话数",
  "cms.report.field.inWaitAnsNum": "人工呼入数",
  "cms.report.field.inAnswerRate": "呼入接通率",
  "cms.report.field.callAbandonedRing": "人工呼入呼损数",
  "cms.report.field.inAvgRingTime": "人工呼入应答均长",
  "cms.report.field.inAvgCallTime": "通话均长",
  "cms.report.field.outWaitAnsNum": "人工呼出数",
  "cms.report.field.outCallSuccNum": "人工呼出接通数",
  "cms.report.field.outAnswerRate": "呼出接通率",
  "cms.report.field.outAvgRingTime": "人工呼出应答均长",
  "cms.report.field.outAvgCallTime": "人工呼出通话均长",
  "cms.report.field.acdCall": "总通话数",
  "cms.report.field.avgCallPerHour": "人均小时应答数",
  "cms.report.field.occupancyRate": "工作时长占比",
  "cms.report.field.utilizationRate": "工作态时长占比",
  "cms.report.field.workTime": "总工作时长",
  "cms.report.field.acdTime": "人工通话时长",
  "cms.report.field.acdTimeRate": "人工通话时长占比",
  "cms.report.field.avgAcdTime": "人工通话均长",
  "cms.report.field.acwTime": "总整理时长",
  "cms.report.field.acwTimeRate": "整理时长占比",
  "cms.report.field.avgAcwTime": "整理均长",
  "cms.report.field.avgHandleTime": "通话处理均长",
  "cms.report.field.auxTime": "总休息时长",
  "cms.report.field.auxTimeRate": "休息时长占比",
  "cms.report.field.busyTime": "总示忙时长",
  "cms.report.field.busyTimeRate": "示忙时长占比",
  "cms.report.field.ringTime": "总应答时长",
  "cms.report.field.availTime": "示闲时长",
  "cms.report.field.availTimeRate": "空闲率",
  "cms.report.field.holdTime": "保持时长",
  "cms.report.field.avgHoldTime": "保持均长",
  "cms.report.field.outOccupyNum": "呼出数",
  "cms.report.field.outCallRate": "呼出接通率",
  "cms.report.field.outAboundRing": "呼出无应答数",
  "cms.report.field.outInvalidCall": "呼出无效号码数",
  "cms.report.field.outFailNum": "呼出失败总次数",
  "cms.report.field.outCallTime": "呼出通话总时长",
  "cms.report.field.outWaitAnsTime": "呼出应答总时长",
  "cms.report.field.outAvgAnsTime": "呼出应答均长",
  "cms.report.field.acwCount": "整理次数",
  "cms.report.field.acwOutTime": "呼出整理时长",
  "cms.report.field.acwOutCount": "呼出整理次数",
  "cms.report.field.acwInCount": "呼入整理次数",
  "cms.report.field.acwInTime": "呼入整理时长",
  "cms.report.field.auxCount": "休息次数",
  "cms.report.field.holdCount": "保持次数",
  "cms.report.field.muteCount": "静音次数",
  "cms.report.field.muteTime": "静音总时长",
  "cms.report.field.assistsCount": "内部求助次数",
  "cms.report.field.assistTime": "内部求助时长",
  "cms.report.field.busyCount": "示忙次数",
  "cms.report.field.playVoiceCount": "放音次数",
  "cms.report.field.playVoiceTime": "放音时长",
  "cms.report.field.otherTime": "总其他时长",
  "cms.report.field.loginCount": "签入次数",
  "cms.report.field.logoutCount": "签出次数",
  "cms.report.field.threePartyCallCount": "三方通话次数",
  "cms.report.field.internalTransferCount": "内部转移次数",
  "cms.report.field.transferOutCount": "人工转出次数",
  "cms.report.field.transferIvrCount": "人工转到IVR次数",
  "cms.report.field.internalCallCount": "内部呼叫次数",
  "cms.report.field.internalCallTime": "内部呼叫时长",
  "cms.report.field.manToSkillNum": "人工转技能队列数",
  "cms.report.field.agentHangNumCti": "座席挂机数",
  "cms.report.field.custHangNumCti": "客户挂机数",
  "cms.report.field.otherHangNumCti": "系统挂机数",
  "cms.report.field.manToIvrNumCti": "座席挂起转IVR次数",
  "cms.report.field.manTransferOutNumCti": "人工转IVR再转出数",
  "cms.report.field.calleeNo": "接入码",
  "cms.report.field.systemInBound": "系统呼入总数",
  "cms.report.field.systemInSucc": "系统呼入成功数",
  "cms.report.field.sysSuccRate": "系统呼入成功率",
  "cms.report.field.sysLostCalls": "系统呼入失败数",
  "cms.report.field.IVRInBound": "IVR呼入总数",
  "cms.report.field.IVRInSucc": "IVR呼入接通数",
  "cms.report.field.IVRSuccRate": "IVR呼入接通率",
  "cms.report.field.onlyIVRService": "全IVR呼入成功数",
  "cms.report.field.IVRServiceRate": "全IVR呼入成功率",
  "cms.report.field.agentOffered": "人工呼入数",
  "cms.report.field.agentService": "座席成功应答次数",
  "cms.report.field.awarnessRate": "人工呼入中成功率",
  "cms.report.field.agentServiceRate": "呼入总数中人工成功率",
  "cms.report.field.succAnsRate": "请求接通率",
  "cms.report.field.avgAnsTime": "通话排队均长",
  "cms.report.field.ans20s": "20秒内人工接通率",
  "cms.report.field.avgCallInTime": "通话均长",
  "cms.report.field.avgInRingTime": "终端呼入均长",
  "cms.report.field.failAnsRate": "请求呼损率",
  "cms.report.field.failAvgTime": "呼损应答均长",
  "cms.report.field.maxQueueWaitTime": "最大等待时长",
  "cms.report.field.outSuccAnsRate": "呼出接通率",
  "cms.report.field.acdCalls": "总通话数",
  "cms.report.field.avgPerHNum": "每小时平均成功呼叫次数",
  "cms.report.field.agentLoginNum": "呼入座席数",
  "cms.report.field.perAgentNum": "座席呼叫数",
  "cms.report.field.workRate": "工作时长占比",
  "cms.report.field.onlineRate": "在线率",
  "cms.report.field.otherTimeRate": "其他时长率",
  "cms.report.field.inCallNum": "呼入数",
  "cms.report.field.inCallSuccNumber": "呼入接通数",
  "cms.report.field.agentInBound": "人工呼入数",
  "cms.report.field.agentInSucc": "人工呼入接通数",
  "cms.report.field.outCallNum": "呼出数",
  "cms.report.field.outCallSuccNumber": "呼出接通数",
  "cms.report.field.IVROutCallNum": "全IVR呼出数",
  "cms.report.field.IVROutSuccNum": "全IVR呼出接通数",
  "cms.report.field.agentOutBound": "人工呼出数",
  "cms.report.field.agentOutSucc": "人工呼出接通数",
  "cms.report.field.inCallSuccRate": "呼入接通率",
  "cms.report.field.IVRInSuccRate": "IVR呼入接通率",
  "cms.report.field.agentInSuccRate": "人工呼入接通率",
  "cms.report.field.outCallSuccRate": "呼出接通率",
  "cms.report.field.IVROutSuccRate": "全IVR呼出接通率",
  "cms.report.field.agentOutSuccRate": "人工呼出接通率",
  "cms.report.field.inVdnAvgTime": "呼入占用VDN均长",
  "cms.report.field.outVdnAvgTime": "呼出占用VDN均长",
  "cms.report.field.IVRCallOutNum": "IVR呼出数",
  "cms.report.field.IVRCallOutSuccNum": "IVR呼出接通数",
  "cms.report.field.IVRCallOutSuccRate": "IVR呼出接通率",
  "cms.report.field.userBusyFailNum": "用户忙线呼损数",
  "cms.report.field.userRefusedFailNum": "用户拒接呼损数",
  "cms.report.field.userNoAnswerNum": "用户振铃超时呼损数",
  "cms.report.field.callLostOtherNum": "其它呼损数",
  "cms.report.field.minAgentInCount": "座席呼入最小并发数",
  "cms.report.field.maxAgentInCount": "座席呼入最大并发数",
  "cms.report.field.avgAgentInCount": "座席呼入平均并发数",
  "cms.report.field.minAgentOutCount": "座席呼出最小并发数",
  "cms.report.field.maxAgentOutCount": "座席呼出最大并发数",
  "cms.report.field.avgAgentOutCount": "座席呼出平均并发数",
  "cms.report.field.minAgentCount": "座席呼叫最小并发数",
  "cms.report.field.maxAgentCount": "座席呼叫最大并发数",
  "cms.report.field.avgAgentCount": "座席呼叫平均并发数",
  "cms.report.field.minIVRInCount": "IVR呼入最小并发数",
  "cms.report.field.maxIVRInCount": "IVR呼入最大并发数",
  "cms.report.field.avgIVRInCount": "IVR呼入平均并发数",
  "cms.report.field.minIVROutCount": "IVR呼出最小并发数",
  "cms.report.field.maxIVROutCount": "IVR呼出最大并发数",
  "cms.report.field.avgIVROutCount": "IVR呼出平均并发数",
  "cms.report.field.minIVRCount": "IVR呼叫最小并发数",
  "cms.report.field.maxIVRCount": "IVR呼叫最大并发数",
  "cms.report.field.avgIVRCount": "IVR呼叫平均并发数",
  "cms.report.field.minQueueInCount": "队列呼入最小并发数",
  "cms.report.field.maxQueueInCount": "队列呼入最大并发数",
  "cms.report.field.avgQueueInCount": "队列呼入平均并发数",
  "cms.report.field.minQueueOutCount": "队列呼出最小并发数",
  "cms.report.field.maxQueueOutCount": "队列呼出最大并发数",
  "cms.report.field.avgQueueOutCount": "队列呼出平均并发数",
  "cms.report.field.minQueueCount": "队列呼叫最小并发数",
  "cms.report.field.maxQueueCount": "队列呼叫最大并发数",
  "cms.report.field.avgQueueCount": "队列呼叫平均并发数",
  "cms.report.field.voiceCallType": "语音通话类型",
  "cms.report.field.oneClickDualCall": "一键双呼",
  "cms.report.field.voiceNotification": "语音通知",
  "cms.report.field.intelligentOutCall": "智能外呼",
  "cms.report.field.reportPeriod": "统计步长",
  "cms.report.field.fifteenminutes": "15 分钟",
  "cms.report.field.thirtyminutes": "30 分钟",
  "cms.report.field.onehour": "1 小时",
  "cms.report.message.queryTimeCheck": "选择时间不能早于{0}",
  "cms.report.message.nowTimeCheck": "超过当前时间",
  "cms.report.message.timeRangeCheck": "查询时间间隔不允许超过1天",
  "cms.report.message.timeRangeCheckSaas": "查询时间间隔不允许超过5天",
  "cms.report.message.monthRangeCheck": "开始时间和结束时间必须在同一月份",
  "cms.report.message.maxGarpMonth": "无法查询12个月以上的数据",
  "cms.report.field.statInterval": "时间段",
  "cms.report.field.queryBeginTime": "开始时间",
  "cms.report.field.queryEndTime": "结束时间",
  "cms.report.title.selectReportTime": "选择时间段起始时间",
  "cms.report.field.hourandminute": "选择时间",
  "cms.report.message.timeCheckError": "查询时间间隔不允许超过7天",
  "cms.report.message.currentDateCheck": "选择时间必须小于当天",
  "cms.report.label.header": "设置表头",
  "cms.report.title.filteringIndicator": "筛选指标",
  "cms.report.field.indicatorName": "指标名称",
  "cms.report.field.description": "描述",
  "cms.report.field.confirm": "确定",
  "cms.report.field.cancel": "取消",
  "cms.report.title.saveError": "错误",
  "cms.report.message.errorInfo": "保存失败，至少选择一个指标",
  "cms.report.message.queryErrorInfo": "查询失败",
  "cms.report.message.calleeNoLength": "请输入长度不小于3位的接入码",
  "cms.report.field.reportType": "报表类型",
  "cms.report.field.byDay": "日报",
  "cms.report.field.byWeek": "周报",
  "cms.report.field.byMonth": "月报",
  "cms.report.field.week": "周数",
  "cms.report.field.month": "月份",
  "cms.report.field.year": "年份",
  "cms.report.field.startWeek": "开始周数",
  "cms.report.field.startMonth": "开始月份",
  "cms.report.field.startYear": "开始年份",
  "cms.report.field.endWeek": "结束周数",
  "cms.report.field.endMonth": "结束月份",
  "cms.report.field.endYear": "结束年份",
  "cms.report.field.querySkill": "技能队列",
  "cms.report.field.startAgentId": "起始座席工号",
  "cms.report.field.endAgentId": "结束座席工号",
  "cms.report.field.filetype": "导出文件格式",
  "cms.report.field.userLevel": "用户等级",
  "cms.report.field.unspecifiedUserLevel": "未指定",
  "cms.report.field.unspecifiedSkillId": "未指定",
  "cms.report.message.lessThanError": "结束座席工号不能小于起始座席工号",
  "cms.report.message.dategt31days": "查询天数不能超过31天",
  "cms.report.message.dategt12weeks": "周范围不能超过12周",
  "cms.report.message.dategt1year": "月份范围不能超过12个月",
  "cms.report.message.weekLessThan": "结束年份周数不能小于开始年份周数",
  "cms.report.message.monthLessThan": "结束年份月份不能小于开始年份月份",
  "cms.report.field.ivrtrafficreport.statdate": "日期",
  "cms.report.field.ivrtrafficreport.ivrinbound": "IVR呼入请求次数",
  "cms.report.field.ivrtrafficreport.ivrshortcalls": "IVR通话时长3秒内通话数",
  "cms.report.field.ivrtrafficreport.totalivrservice": "IVR呼入通话时长",
  "cms.report.field.ivrtrafficreport.avgivrservice": "IVR呼入通话均长",
  "cms.fullscreen.message.enterMessage": "请输入",
  "cms.fullscreen.agent.auth": "无权限查看当前组织机构，请重新选择",
  "cms.fullscreen.message.selectMessage": "请选择",
  "cms.fullscreen.field.chartName": "图表名称",
  "cms.fullscreen.field.indexDimension": "监控对象",
  "cms.fullscreen.field.skillQueue": "技能队列",
  "cms.fullscreen.field.indexdMode": "监控方式",
  "cms.fullscreen.field.dayMonitor": "当日指标统计",
  "cms.fullscreen.field.nowMonitor": "运行实时监控",
  "cms.fullscreen.field.dayTrend": "当日趋势",
  "cms.fullscreen.field.monitorType": "指标类型",
  "cms.fullscreen.field.refreshPeriod": "刷新周期",
  "cms.fullscreen.field.statisticPeriod": "统计周期",
  "cms.fullscreen.field.chartType": "图表类型",
  "cms.fullscreen.field.fifteenMin": "每隔15分钟",
  "cms.fullscreen.field.halfHour": "每隔30分钟",
  "cms.fullscreen.field.oneHour": "每隔1小时",
  "cms.fullscreen.field.fiveSec": "每隔5秒",
  "cms.fullscreen.field.oneMin": "每隔1分钟",
  "cms.fullscreen.field.fiveMin": "每隔5分钟",
  "cms.fullscreen.field.bar": "柱状图",
  "cms.fullscreen.field.line": "折线图",
  "cms.fullscreen.field.pie": "饼状图",
  "cms.fullscreen.field.table": "表格",
  "cms.fullscreen.field.richtext": "富文本",
  "cms.fullscreen.field.text": "文本",
  "cms.fullscreen.field.add": "创建",
  "cms.fullscreen.field.edit": "编辑",
  "cms.fullscreen.field.delete": "删除",
  "cms.fullscreen.field.movedown": "下移",
  "cms.fullscreen.field.moveup": "上移",
  "cms.fullscreen.field.addCharts": "添加图表",
  "cms.fullscreen.field.saveTheme": "保存布局",
  "cms.fullscreen.title.newChart": "添加图表",
  "cms.fullscreen.title.editChart": "编辑图表",
  "cms.fullscreen.title.newTheme": "新建主题",
  "cms.fullscreen.title.editTheme": "编辑主题",
  "cms.fullscreen.style.dark": "深色",
  "cms.fullscreen.style.light": "浅色",
  "cms.fullscreen.style.title": "风格",
  "cms.fullscreen.message.errorMonitorMethod": "请选择监控方式",
  "cms.fullscreen.title.deleteconfim": "删除确认",
  "cms.fullscreen.message.deleteconfim": "是否确认删除该主题",
  "cms.fullscreen.message.deletechart": "是否确认删除该图表",
  "cms.fullscreen.field.virtualcallcenter": "虚拟呼叫中心",
  "cms.fullscreen.field.screenreSolution": "分辨率",
  "cms.fullscreen.field.custom": "自定义：",
  "cms.fullscreen.field.preview": "预览",
  "cms.fullscreen.message.themeDuplicate": "名称重复",
  "cms.fullscreen.message.selectAgent": "组织机构和座席不能为空",
  "cms.fullscreen.message.selectAgentMax": "最多选择5个座席",
  "cms.fullscreen.field.themeName": "主题名称",
  "cms.fullscreen.message.screenreSolution": "大屏分辨率不能为空",
  "cms.fullscreen.message.numberFirst": "最大值是7680",
  "cms.fullscreen.message.numberSecond": "最大值是4320",
  "cms.fullscreen.message.maxThemeNum": "最多添加20个私有主题和20个公共主题",
  "cms.fullscreen.message.numberFirstMin": "最小值是1280",
  "cms.fullscreen.message.numberSecondMin": "最小值是720",
  "cms.fullscreen.message.chartDataError": "加载图表失败",
  "cms.fullscreen.field.fullscreen": "全屏",
  "cms.fullscreen.field.fonts": "字体",
  "cms.fullscreen.field.fontSize": "字号",
  "cms.fullscreen.field.weighted": "粗细",
  "cms.fullscreen.field.color": "颜色",
  "cms.fullscreen.field.normalBold": "正常",
  "cms.fullscreen.field.bold": "加粗",
  "cms.fullscreen.field.editRichText": "编辑富文本",
  "cms.fullscreen.field.indicatorData": "指标数据",
  "cms.fullscreen.field.yaHei": "微软雅黑",
  "cms.fullscreen.field.simsun": "宋体",
  "cms.fullscreen.field.FangSong": "仿宋体",
  "cms.fullscreen.field.SimHei": "黑体",
  "cms.fullscreen.field.play": "播放",
  "cms.fullscreen.field.second": "(秒)",
  "cms.fullscreen.field.percentage": "(%)",
  "cms.fullscreen.message.addTheme": "请添加一个主题",
  "cms.fullscreen.message.addChart": "请添加一个图表",
  "cms.fullscreen.message.chooseTheme": "布局未保存，是否要切换主题",
  "cms.fullscreen.message.addThemeError": "布局未保存，是否要继续添加主题",
  "cms.fullscreen.message.saveLayouts": "布局未保存，请先保存",
  "cms.fullscreen.message.addLayout": "画布已添加满，无法添加",
  "cms.fullscreen.message.addVdnMost": "租户选择最多10个",
  "cms.fullscreen.message.addVdnLeast": "请选择至少一个租户",
  "cms.fullscreen.message.playTheme": "该主题已播放",
  "cms.fullscreen.field.report0": "日报（相对时间）",
  "cms.fullscreen.field.report1": "日报（固定时间）",
  "cms.fullscreen.field.reportType": "报表类型",
  "cms.fullscreen.field.reportTime": "选择时间",
  "cms.fullscreen.field.hisMonitor": "历史指标统计",
  "cms.fullscreen.field.reportDate": "往前第{0}天",
  "cms.fullscreen.field.noanswerCountDesc": "呼叫分配给座席后，座席没有及时应答的次数",
  "cms.fullscreen.field.totalCallTime": "总通话时长",
  "cms.fullscreen.field.totalCallTimeDesc": "座席接通呼叫的总通话时长",
  "cms.fullscreen.field.avgCallTime": "平均通话时长",
  "cms.fullscreen.field.avgCallTimeDesc": "座席接通呼叫的平均通话时长",
  "cms.fullscreen.field.acwCountTimeDesc": "座席处于整理状态次数",
  "cms.fullscreen.field.auxCountTimeDesc": "座席处于休息状态次数",
  "cms.fullscreen.field.busyCountTimeDesc": "座席处于示忙状态次数",
  "cms.fullscreen.field.idleCountTimeDesc": "座席处于示闲状态次数",
  "cms.fullscreen.field.holdCountTimeDesc": "座席处于保持状态次数",
  "cms.fullscreen.field.avgCallInTime": "人工呼入通话均长",
  "cms.voiceinspection.field.callTime": "录制时长",
  "cms.report.field.inOccupyNumField": "请求服务数",
  "cms.report.field.inCallSuccNumField": "人工呼入接通数",
  "cms.report.field.callSuccRateField": "请求接通率",
  "cms.report.field.inCallFailNumField": "请求呼损数",
  "cms.report.field.call3RateField": "15秒内人工呼入接通率",
  "cms.report.field.call4RateField": "20秒内人工呼入接通率",
  "cms.report.field.call6RateField": "30秒内人工呼入接通率",
  "cms.report.field.succWaitAnsRateField": "通话等待均长",
  "cms.report.field.failQueueWaitRateField": "呼损应答均长",
  "cms.report.field.inCallRateField": "通话均长",
  "cms.report.field.waitAnsRateField": "人工呼入应答均长",
  "cms.report.field.abortQueueWaitNumField": "用户排队放弃数",
  "cms.report.field.noAckNumField": "应答无通话数",
  "cms.report.field.inWaitAnsNumField": "人工呼入数",
  "cms.report.field.inAnswerRateField": "呼入接通率",
  "cms.report.field.callAbandonedRingField": "人工呼入呼损数",
  "cms.report.field.inAvgRingTimeField": "人工呼入应答均长",
  "cms.report.field.inAvgCallTimeField": "通话均长",
  "cms.report.field.outWaitAnsNumField": "人工呼出数",
  "cms.report.field.outCallSuccNumField": "人工呼出接通数",
  "cms.report.field.outAnswerRateField": "呼出接通率",
  "cms.report.field.outAvgRingTimeField": "人工呼出应答均长",
  "cms.report.field.outAvgCallTimeField": "人工呼出通话均长",
  "cms.report.field.acdCallField": "总通话数",
  "cms.report.field.avgCallPerHourField": "人均小时应答数",
  "cms.report.field.occupancyRateField": "工作时长占比",
  "cms.report.field.utilizationRateField": "工作态时长占比",
  "cms.report.field.workTimeField": "总工作时长",
  "cms.report.field.acdTimeField": "人工通话时长",
  "cms.report.field.acdTimeRateField": "人工通话时长占比",
  "cms.report.field.avgAcdTimeField": "人工通话均长",
  "cms.report.field.acwTimeField": "总整理时长",
  "cms.report.field.acwTimeRateField": "整理时长占比",
  "cms.report.field.avgAcwTimeField": "整理均长",
  "cms.report.field.avgHandleTimeField": "通话处理均长",
  "cms.report.field.auxTimeField": "总休息时长",
  "cms.report.field.auxTimeRateField": "休息时长占比",
  "cms.report.field.busyTimeField": "总示忙时长",
  "cms.report.field.busyTimeRateField": "示忙时长占比",
  "cms.report.field.ringTimeField": "总应答时长",
  "cms.report.field.availTimeField": "空闲时长",
  "cms.report.field.availTimeRateField": "空闲率",
  "cms.report.field.holdTimeField": "保持时长",
  "cms.report.field.avgHoldTimeField": "保持均长",
  "cms.report.field.outOccupyNumField": "呼出数",
  "cms.report.field.outCallRateField": "呼出接通率",
  "cms.report.field.outAboundRingField": "呼出无应答数",
  "cms.report.field.outInvalidCallField": "呼出无效号码数",
  "cms.report.field.outFailNumField": "呼出失败总次数",
  "cms.report.field.outCallTimeField": "呼出通话总时长",
  "cms.report.field.outWaitAnsTimeField": "呼出应答总时长",
  "cms.report.field.outAvgAnsTimeField": "呼出应答均长",
  "cms.report.field.acwCountField": "整理次数",
  "cms.report.field.acwOutTimeField": "呼出整理时长",
  "cms.report.field.acwOutCountField": "呼出整理次数",
  "cms.report.field.acwInCountField": "呼入整理次数",
  "cms.report.field.acwInTimeField": "呼入整理时长",
  "cms.report.field.auxCountField": "休息次数",
  "cms.report.field.holdCountField": "保持次数",
  "cms.report.field.muteCountField": "静音次数",
  "cms.report.field.muteTimeField": "静音总时长",
  "cms.report.field.assistsCountField": "内部求助次数",
  "cms.report.field.assistTimeField": "内部求助时长",
  "cms.report.field.busyCountField": "示忙次数",
  "cms.report.field.playVoiceCountField": "放音次数",
  "cms.report.field.playVoiceTimeField": "放音时长",
  "cms.report.field.otherTimeField": "总其他时长",
  "cms.report.field.loginCountField": "签入次数",
  "cms.report.field.logoutCountField": "签出次数",
  "cms.report.field.threePartyCallCountField": "三方通话次数",
  "cms.report.field.internalTransferCountField": "内部转移次数",
  "cms.report.field.transferOutCountField": "人工转出次数",
  "cms.report.field.transferIvrCountField": "人工转到IVR次数",
  "cms.report.field.internalCallCountField": "内部呼叫次数",
  "cms.report.field.internalCallTimeField": "内部呼叫时长",
  "cms.report.field.manToSkillNumField": "人工转技能队列数",
  "cms.report.field.agentHangNumCtiField": "座席挂机数",
  "cms.report.field.custHangNumCtiField": "客户挂机数",
  "cms.report.field.otherHangNumCtiField": "系统挂机数",
  "cms.report.field.manToIvrNumCtiField": "座席挂起转IVR次数",
  "cms.report.field.manTransferOutNumCtiField": "人工转IVR再转出数",
  "cms.report.field.systemInBoundField": "系统呼入总数",
  "cms.report.field.systemInSuccField": "系统呼入成功数",
  "cms.report.field.sysSuccRateField": "系统呼入成功率",
  "cms.report.field.sysLostCallsField": "系统呼入失败数",
  "cms.report.field.IVRInBoundField": "IVR呼入总数",
  "cms.report.field.IVRInSuccField": "IVR呼入接通数",
  "cms.report.field.IVRSuccRateField": "IVR呼入接通率",
  "cms.report.field.onlyIVRServiceField": "全IVR呼入成功数",
  "cms.report.field.IVRServiceRateField": "全IVR呼入成功率",
  "cms.report.field.agentOfferedField": "人工呼入数",
  "cms.report.field.agentServiceField": "座席成功应答次数",
  "cms.report.field.awarnessRateField": "人工呼入中成功率",
  "cms.report.field.agentServiceRateField": "呼入总数中人工成功率",
  "cms.report.field.avgAnsTimeField": "通话排队均长",
  "cms.report.field.ans20sField": "20秒内人工接通率",
  "cms.report.field.avgCallInTimeField": "通话均长",
  "cms.report.field.avgInRingTimeField": "终端呼入均长",
  "cms.report.field.failAnsRateField": "请求呼损率",
  "cms.report.field.failAvgTimeField": "呼损应答均长",
  "cms.report.field.maxQueueWaitTimeField": "最大等待时长",
  "cms.report.field.outSuccAnsRateField": "呼出接通率",
  "cms.report.field.acdCallsField": "总通话数",
  "cms.report.field.avgPerHNumField": "每小时平均成功呼叫次数",
  "cms.report.field.agentLoginNumField": "呼入座席数",
  "cms.report.field.perAgentNumField": "座席呼叫数",
  "cms.report.field.workRateField": "工作时长占比",
  "cms.report.field.onlineRateField": "在线率",
  "cms.report.field.otherTimeRateField": "其他时长率",
  "cms.report.field.inCallNumField": "呼入数",
  "cms.report.field.inCallSuccNumberField": "呼入接通数",
  "cms.report.field.agentInBoundField": "人工呼入数",
  "cms.report.field.agentInSuccField": "人工呼入接通数",
  "cms.report.field.outCallNumField": "呼出数",
  "cms.report.field.outCallSuccNumberField": "呼出接通数",
  "cms.report.field.IVROutCallNumField": "全IVR呼出数",
  "cms.report.field.IVROutSuccNumField": "全IVR呼出接通数",
  "cms.report.field.agentOutBoundField": "人工呼出数",
  "cms.report.field.agentOutSuccField": "人工呼出接通数",
  "cms.report.field.inCallSuccRateField": "呼入接通率",
  "cms.report.field.IVRInSuccRateField": "IVR呼入接通率",
  "cms.report.field.agentInSuccRateField": "人工呼入接通率",
  "cms.report.field.outCallSuccRateField": "呼出接通率",
  "cms.report.field.IVROutSuccRateField": "全IVR呼出接通率",
  "cms.report.field.agentOutSuccRateField": "人工呼出接通率",
  "cms.report.field.inVdnAvgTimeField": "呼入占用VDN均长",
  "cms.report.field.outVdnAvgTimeField": "呼出占用VDN均长",
  "cms.report.field.IVRCallOutNumField": "IVR呼出数",
  "cms.report.field.IVRCallOutSuccNumField": "IVR呼出接通数",
  "cms.report.field.IVRCallOutSuccRateField": "IVR呼出接通率",
  "cms.report.field.userBusyFailNumField": "用户忙线呼损数",
  "cms.report.field.userRefusedFailNumField": "用户拒接呼损数",
  "cms.report.field.userNoAnswerNumField": "用户振铃超时呼损数",
  "cms.report.field.callLostOtherNumField": "其它呼损数",
  "cms.report.field.minAgentInCountField": "座席呼入最小并发数",
  "cms.report.field.maxAgentInCountField": "座席呼入最大并发数",
  "cms.report.field.avgAgentInCountField": "座席呼入平均并发数",
  "cms.report.field.minAgentOutCountField": "座席呼出最小并发数",
  "cms.report.field.maxAgentOutCountField": "座席呼出最大并发数",
  "cms.report.field.avgAgentOutCountField": "座席呼出平均并发数",
  "cms.report.field.minAgentCountField": "座席呼叫最小并发数",
  "cms.report.field.maxAgentCountField": "座席呼叫最大并发数",
  "cms.report.field.avgAgentCountField": "座席呼叫平均并发数",
  "cms.report.field.minIVRInCountField": "IVR呼入最小并发数",
  "cms.report.field.maxIVRInCountField": "IVR呼入最大并发数",
  "cms.report.field.avgIVRInCountField": "IVR呼入平均并发数",
  "cms.report.field.minIVROutCountField": "IVR呼出最小并发数",
  "cms.report.field.maxIVROutCountField": "IVR呼出最大并发数",
  "cms.report.field.avgIVROutCountField": "IVR呼出平均并发数",
  "cms.report.field.minIVRCountField": "IVR呼叫最小并发数",
  "cms.report.field.maxIVRCountField": "IVR呼叫最大并发数",
  "cms.report.field.avgIVRCountField": "IVR呼叫平均并发数",
  "cms.report.field.minQueueInCountField": "队列呼入最小并发数",
  "cms.report.field.maxQueueInCountField": "队列呼入最大并发数",
  "cms.report.field.avgQueueInCountField": "队列呼入平均并发数",
  "cms.report.field.minQueueOutCountField": "队列呼出最小并发数",
  "cms.report.field.maxQueueOutCountField": "队列呼出最大并发数",
  "cms.report.field.avgQueueOutCountField": "队列呼出平均并发数",
  "cms.report.field.minQueueCountField": "队列呼叫最小并发数",
  "cms.report.field.maxQueueCountField": "队列呼叫最大并发数",
  "cms.report.field.avgQueueCountField": "队列呼叫平均并发数",
  "cms.report.message.inOccupyNumDesc": "来话请求人工服务的次数",
  "cms.report.message.inCallSuccNumDesc": "座席接通的呼入次数",
  "cms.report.message.callSuccRateDesc": "人工呼入请求接通数占人工呼入请求服务数的百分比",
  "cms.report.message.inCallFailNumDesc": "人工呼入请求呼损数 = 人工呼入请求服务数 - 人工呼入请求接通数",
  "cms.report.message.call3RateDesc": "15秒内(包括15秒)人工接通的请求数占请求人工数的百分比",
  "cms.report.message.call4RateDesc": "20秒内(包括20秒)人工接通的请求数占请求人工数的百分比",
  "cms.report.message.call6RateDesc": "30秒内(包括30秒)人工接通的请求数占请求人工数的百分比",
  "cms.report.message.succWaitAnsRateDesc": "所有被接通的呼叫在队列中的平均等待时长",
  "cms.report.message.failQueueWaitRateDesc": "所有未被接通的呼叫平均应答时长",
  "cms.report.message.inCallRateDesc": "通话的平均时长。人工呼入通话均长 = 人工呼入通话时长/人工呼入通话数",
  "cms.report.message.waitAnsRateDesc": "座席终端振铃平均时长。人工呼入应答总时长/应答次数",
  "cms.report.message.abortQueueWaitNumDesc": "用户在排队和振铃时主动放弃呼叫的次数",
  "cms.report.message.noAckNumDesc": "呼叫分配给座席后无通话的次数",
  "cms.report.message.inWaitAnsNumDesc": "来话请求人工服务的次数",
  "cms.report.message.inAnswerRateDesc": "人工呼入接通数占人工呼入数的百分比",
  "cms.report.message.callAbandonedRingDesc": "人工呼入呼损数 = 人工呼入数 - 人工呼入接通数",
  "cms.report.message.inAvgRingTimeDesc": "座席终端振铃平均时长。人工呼入应答总时长/应答次数",
  "cms.report.message.inAvgCallTimeDesc": "通话的平均时长。人工呼入通话均长 = 人工呼入通话时长/人工呼入通话数",
  "cms.report.message.outWaitAnsNumDesc": "人工呼出数，包括人工主动呼出和IVR自动转人工的数量",
  "cms.report.message.outCallSuccNumDesc": "人工呼出接通数",
  "cms.report.message.outAnswerRateDesc": "呼出接通数占呼出数的百分比",
  "cms.report.message.outAvgRingTimeDesc": "座席外呼客户时，座席等待客户应答的平均时间",
  "cms.report.message.outAvgCallTimeDesc": "座席外呼客户时，座席与客户通话的平均时长",
  "cms.report.message.acdCallDesc": "呼入接通数+呼出接通数",
  "cms.report.message.avgCallPerHourDesc": "在所选的工作时间段内，座席每小时平均的应答次数",
  "cms.report.message.occupancyRateDesc": "(总工作时长 - 休息时长)/总工作时长 * 100",
  "cms.report.message.utilizationRateDesc": "(总通话时长 + 总整理时长)/(总工作时长 - 休息时长) * 100",
  "cms.report.message.overNumReason": "多媒体座席及全能型座席可在同一时刻处理多通呼叫",
  "cms.report.message.workTimeDesc": "座席签入到签出的总时长",
  "cms.report.message.acdTimeDesc": "座席处于通话的总时长(不包含文字交谈等时长)",
  "cms.report.message.acdTimeRateDesc": "人工通话时长占比，人工通话时长/总工作时长 * 100",
  "cms.report.message.avgAcdTimeDesc": "座席通话的平均时间，人工通话时长/人工成功通话次数",
  "cms.report.message.acwTimeDesc": "座席处于整理状态的总时长",
  "cms.report.message.acwTimeRateDesc": "(总整理时长/总工作时长) * 100",
  "cms.report.message.avgAcwTimeDesc": "整理态的平均时长，总整理时长/总整理次数 ",
  "cms.report.message.avgHandleTimeDesc": "(人工通话时长 + 总整理时长)/人工成功通话次数",
  "cms.report.message.auxTimeDesc": "座席离开总时长(座席休息状态的时长)",
  "cms.report.message.auxTimeRateDesc": "(总休息时间/总工作时长) * 100",
  "cms.report.message.busyTimeDesc": "座席处于示忙状态的总时长",
  "cms.report.message.busyTimeRateDesc": "(总示忙时长/总工作时长) * 100",
  "cms.report.message.ringTimeDesc": "座席振铃总时间，不包含通话时长",
  "cms.report.message.availTimeDesc": "座席处于空闲状态的总时间",
  "cms.report.message.availTimeRateDesc": "座席处于空闲状态的总时间占总工作时长的百分比",
  "cms.report.message.holdTimeDesc": "座席处于呼叫保持状态的总时间。座席通话时间包含呼叫保持时间",
  "cms.report.message.avgHoldTimeDesc": "保持平均时长，保持时长/保持次数",
  "cms.report.message.outOccupyNumDesc": "座席呼出到客户的呼叫次数",
  "cms.report.message.outCallRateDesc": "呼出接通数占呼出数的百分比",
  "cms.report.message.outAboundRingDesc": "客户拒接或振铃无应答的外呼次数",
  "cms.report.message.outInvalidCallDesc": "由于无效号码、关机等原因，导致外呼不能到达客户的次数",
  "cms.report.message.outFailNumDesc": "外呼失败总次数",
  "cms.report.message.outCallTimeDesc": "座席外呼客户时，座席与客户通话的总时长",
  "cms.report.message.outWaitAnsTimeDesc": "外呼应答总时长",
  "cms.report.message.outAvgAnsTimeDesc": "人工呼出应答总时长/呼出应答数",
  "cms.report.message.acwCountDesc": "整理次数",
  "cms.report.message.acwOutTimeDesc": "外呼通话结束之后整理时长",
  "cms.report.message.acwOutCountDesc": "外呼通话结束之后整理次数",
  "cms.report.message.acwInCountDesc": "呼入通话结束之后整理次数",
  "cms.report.message.acwInTimeDesc": "呼入通话结束之后整理时长",
  "cms.report.message.auxCountDesc": "休息次数",
  "cms.report.message.holdCountDesc": "保持次数",
  "cms.report.message.muteCountDesc": "静音次数",
  "cms.report.message.muteTimeDesc": "静音时间",
  "cms.report.message.assistsCountDesc": "内部求助次数",
  "cms.report.message.assistTimeDesc": "内部求助时长",
  "cms.report.message.busyCountDesc": "示忙次数",
  "cms.report.message.playVoiceCountDesc": "放音次数",
  "cms.report.message.playVoiceTimeDesc": "放音时长",
  "cms.report.message.otherTimeDesc": "其他时间(多技能情况下的其他技能的通话时长)",
  "cms.report.message.loginCountDesc": "签入次数",
  "cms.report.message.logoutCountDesc": "签出次数",
  "cms.report.message.threePartyCallCountDesc": "三方通话次数",
  "cms.report.message.internalTransferCountDesc": "内部转移次数",
  "cms.report.message.transferOutCountDesc": "人工转出次数",
  "cms.report.message.transferIvrCountDesc": "人工转到IVR次数",
  "cms.report.message.internalCallCountDesc": "内部呼叫次数",
  "cms.report.message.internalCallTimeDesc": "内部呼叫时长",
  "cms.report.message.manToSkillNumDesc": "人工转技能队列数",
  "cms.report.message.agentHangNumCtiDesc": "座席挂机数",
  "cms.report.message.custHangNumCtiDesc": "客户挂机数",
  "cms.report.message.otherHangNumCtiDesc": "系统挂机数",
  "cms.report.message.manToIvrNumCtiDesc": "接通呼叫后，座席挂起转IVR的次数",
  "cms.report.message.manTransferOutNumCtiDesc": "从座席转IVR再转出的次数",
  "cms.report.message.systemInBoundDesc": "系统呼入总数(不含内部呼入)",
  "cms.report.message.systemInSuccDesc": "系统呼入成功数，包括IVR放音、排队等待、座席终端振铃、座席应答等(不含内部呼入)",
  "cms.report.message.sysSuccRateDesc": "系统呼入成功数在系统呼入总数中所占的百分比",
  "cms.report.message.sysLostCallsDesc": "系统呼入失败数 = 系统呼入总数 - 系统呼入成功数",
  "cms.report.message.IVRInBoundDesc": "接入IVR的总数",
  "cms.report.message.IVRInSuccDesc": "IVR呼入请求数中通话时长大于0的次数",
  "cms.report.message.IVRSuccRateDesc": "IVR呼入接通数在IVR呼入请求数中所占的百分比",
  "cms.report.message.onlyIVRServiceDesc": "只通过IVR呼入成功数，即没有通过任何座席提供服务的呼入成功数",
  "cms.report.message.IVRServiceRateDesc": "只通过IVR处理的呼叫数占呼入总数的百分比。全IVR呼入成功数/(全IVR呼入成功数 + 座席成功应答次数) * 100",
  "cms.report.message.agentOfferedDesc": "请求座席的呼入总数",
  "cms.report.message.agentServiceDesc": "座席成功应答次数",
  "cms.report.message.awarnessRateDesc": "座席成功应答次数在人工呼入数中所占的百分比",
  "cms.report.message.agentServiceRateDesc": "座席成功应答次数在呼入总数中所占的百分比。座席成功应答次数/(IVR呼入成功数 + 座席成功应答次数) * 100",
  "cms.report.message.avgAnsTimeDesc": "所有被接通的呼叫在队列中的平均排队时长",
  "cms.report.message.ans20sDesc": "20秒内(包括20秒)的人工接通数占人工接通总数的百分比",
  "cms.report.message.avgCallInTimeDesc": "通话的平均时长。人工呼入通话均长 = 人工呼入通话时长/人工呼入通话数",
  "cms.report.message.avgInRingTimeDesc": "座席终端呼入平均时长",
  "cms.report.message.failAnsRateDesc": "呼损率(%) = 100(%) - 接通率(%)",
  "cms.report.message.failAvgTimeDesc": "所有未被接通的呼叫平均应答时长",
  "cms.report.message.maxQueueWaitTimeDesc": "所有呼叫在队列设备上的排队和座席应答的最大等待时长",
  "cms.report.message.outSuccAnsRateDesc": "呼出接通数占呼出数的百分比",
  "cms.report.message.acdCallsDesc": "呼入接通数+呼出接通数",
  "cms.report.message.avgPerHNumDesc": "在所选的工作时间段内，每小时平均的成功呼叫次数",
  "cms.report.message.agentLoginNumDesc": "收到呼入呼叫的座席数",
  "cms.report.message.perAgentNumDesc": "在所选时间段内，分配到每个座席分配到的呼入次数",
  "cms.report.message.workRateDesc": "(工作时长 - 休息时间)/工作时长 * 100",
  "cms.report.message.onlineRateDesc": "(人工通话时长 + 总整理时长 + 通话整理其他技能时间)/(总工作时长 - 休息时长) * 100",
  "cms.report.message.otherTimeRateDesc": "(总其他时长/总工作时长) * 100",
  "cms.report.message.callAbandonedInRingDesc": "座席在振铃时被放弃的呼叫数",
  "cms.report.field.succAnsRateField": "请求接通率",
  "cms.report.message.succAnsRateDesc": "人工呼入请求接通数占人工呼入请求服务数的百分比",
  "cms.report.field.acwRate": "整理时长占比",
  "cms.report.field.IVRShortCallsField": "IVR通话时长3秒内通话数",
  "cms.report.field.totalLIVRServiceField": "IVR呼入通话时长",
  "cms.report.field.avgIVRServiceField": "IVR呼入通话均长",
  "cms.report.message.IVRShortCallsDesc": "IVR持续时间大于0且不大于3秒的调用数",
  "cms.report.message.totalLIVRServiceDesc": "IVR通话总时长，如果一次呼叫多次通过IVR，则IVR服务时长为这些IVR时长的总和",
  "cms.report.message.avgIVRServiceDesc": "IVR呼入通话均长，IVR呼入通话时长/IVR呼入接通数",
  "cms.report.field.acwRateField": "整理时长占比",
  "cms.report.message.acwRateDesc": "(总整理时长/总工作时长) * 100",
  "cms.report.message.inCallNumDesc": "呼叫进入VDN的总次数。呼入数 = 人工呼入数 + IVR唯一呼入数",
  "cms.report.message.inCallSuccNumberDesc": "呼入数中被接通的次数。呼入接通数 = 人工呼入接通数 + IVR唯一呼入接通数",
  "cms.report.message.agentInBoundDesc": "呼叫被分配给技能队列或者座席的呼叫个数",
  "cms.report.message.agentInSuccDesc": "人工呼入数中被座席接通的次数，即通话时长大于0的次数",
  "cms.report.message.outCallNumDesc": "VDN中人工和自动呼出的次数。呼出数 = 人工呼出数 + IVR唯一呼出数",
  "cms.report.message.outCallSuccNumberDesc": "自动或人工呼出时被叫方应答的次数。呼出接通数 = 人工呼出接通数 + IVR唯一呼出接通数",
  "cms.report.message.IVROutCallNumDesc": "完全由IVR为客户服务的呼出次数",
  "cms.report.message.IVROutSuccNumDesc": "全IVR呼出数中占用IVR设备时长大于0的通话次数",
  "cms.report.message.agentOutBoundDesc": "人工座席为客户服务的呼出次数",
  "cms.report.message.agentOutSuccDesc": "人工呼出数中被用户接通的次数，即通话时长大于0的次数",
  "cms.report.message.inCallSuccRateDesc": "呼入接通数占呼入数的百分比",
  "cms.report.message.IVRInSuccRateDesc": "IVR呼入接通数占IVR呼入总数的百分比",
  "cms.report.message.agentInSuccRateDesc": "人工呼入接通数占人工呼入数中的百分比",
  "cms.report.message.outCallSuccRateDesc": "呼出接通数占呼出数的百分比",
  "cms.report.message.IVROutSuccRateDesc": "全IVR呼出接通数占全IVR呼出数的百分比",
  "cms.report.message.agentOutSuccRateDesc": "人工呼出接通数占人工呼出数的百分比",
  "cms.report.message.inVdnAvgTimeDesc": "呼入的呼叫占用VDN的平均时长。呼入占用VDN均长 = 呼入占用VDN总时长/呼入数",
  "cms.report.message.outVdnAvgTimeDesc": "呼出的呼叫占用VDN的平均时长。呼出占用VDN均长 = 呼出占用VDN总时长/呼出数",
  "cms.report.message.IVRCallOutNumDesc": "IVR呼出总数",
  "cms.report.message.IVRCallOutSuccNumDesc": "IVR呼出请求数中通话时长大于0的次数",
  "cms.report.message.IVRCallOutSuccRateDesc": "IVR呼出接通数在IVR呼出请求数中所占的百分比",
  "cms.report.message.userBusyFailNumDesc": "用户忙线导致呼叫未被接听的次数",
  "cms.report.message.userRefusedFailNumDesc": "用户拒接导致呼叫未被接听的次数",
  "cms.report.message.userNoAnswerNumDesc": "用户振铃超时导致呼叫未被接听的次数",
  "cms.report.message.callLostOtherNumDesc": "除用户忙线、用户拒接、用户振铃超时场景外的未被接听的次数",
  "cms.report.message.minAgentInCountDesc": "服务设备为座席的呼入呼叫最小并发数",
  "cms.report.message.maxAgentInCountDesc": "服务设备为座席的呼入呼叫最大并发数",
  "cms.report.message.avgAgentInCountDesc": "服务设备为座席的呼入呼叫平均并发数",
  "cms.report.message.minAgentOutCountDesc": "服务设备为座席的呼出呼叫最小并发数",
  "cms.report.message.maxAgentOutCountDesc": "服务设备为座席的呼出呼叫最大并发数",
  "cms.report.message.avgAgentOutCountDesc": "服务设备为座席的呼出呼叫平均并发数",
  "cms.report.message.minAgentCountDesc": "服务设备为座席的呼叫最小并发数",
  "cms.report.message.maxAgentCountDesc": "服务设备为座席的呼叫最大并发数",
  "cms.report.message.avgAgentCountDesc": "服务设备为座席的呼叫平均并发数",
  "cms.report.message.minIVRInCountDesc": "服务设备为IVR的呼入呼叫最小并发数",
  "cms.report.message.maxIVRInCountDesc": "服务设备为IVR的呼入呼叫最大并发数",
  "cms.report.message.avgIVRInCountDesc": "服务设备为IVR的呼入呼叫平均并发数",
  "cms.report.message.minIVROutCountDesc": "服务设备为IVR的呼出呼叫最小并发数",
  "cms.report.message.maxIVROutCountDesc": "服务设备为IVR的呼出呼叫最大并发数",
  "cms.report.message.avgIVROutCountDesc": "服务设备为IVR的呼出呼叫平均并发数",
  "cms.report.message.minIVRCountDesc": "服务设备为IVR的呼叫最小并发数",
  "cms.report.message.maxIVRCountDesc": "服务设备为IVR的呼叫最大并发数",
  "cms.report.message.avgIVRCountDesc": "服务设备为IVR的呼叫平均并发数",
  "cms.report.message.minQueueInCountDesc": "服务设备为技能队列的呼入呼叫最小并发数",
  "cms.report.message.maxQueueInCountDesc": "服务设备为技能队列的呼入呼叫最大并发数",
  "cms.report.message.avgQueueInCountDesc": "服务设备为技能队列的呼入呼叫平均并发数",
  "cms.report.message.minQueueOutCountDesc": "服务设备为技能队列的呼出呼叫最小并发数",
  "cms.report.message.maxQueueOutCountDesc": "服务设备为技能队列的呼出呼叫最大并发数",
  "cms.report.message.avgQueueOutCountDesc": "服务设备为技能队列的呼出呼叫平均并发数",
  "cms.report.message.minQueueCountDesc": "服务设备为技能队列的呼叫最小并发数",
  "cms.report.message.maxQueueCountDesc": "服务设备为技能队列的呼叫最大并发数",
  "cms.report.message.avgQueueCountDesc": "服务设备为技能队列的呼叫平均并发数",
  "cms.report.label.export": "导出",
  "cms.indexmonitor.field.accesscodeCalloutNum": "呼出数",
  "cms.indexmonitor.message.accesscodeCalloutNum": "一小时内主叫号码呼出拨打量",
  "cms.report.message.exportSucceed": "导出成功",
  "cms.report.message.exportFailed": "导出失败",
  "cms.report.message.nomorethanerror": "选择的日期不得早于 {0}",
  "cms.report.field.callAbandonedInRingField": "应答过程中被放弃的呼叫次数",
  "cms.synchronize.message.notConfigured": "未配置",
  "cms.synchronize.message.configured": "已配置",
  "cms.synchronize.message.incorrectPassword": "密码错误",
  "cms.synchronize.message.invalidAdminPwd": "密码错误，请修改管理员密码",
  "cms.synchronize.message.noConfAdminPwd": "密码未配置，请配置管理员密码",
  "cms.synchronize.message.invalidConfPwd": "密码错误，请修改配置接口密码",
  "cms.synchronize.message.noConfConfPwd": "密码未配置，请配置接口密码",
  "cms.synchronize.message.adminPwdStatus": "CTI管理员密码状态",
  "cms.synchronize.message.confPwdStatus": "CCBCS接口密码状态",
  "cms.synchronize.message.syncStatus": "同步状态",
  "cms.synchronize.message.syncStatusZero": "待同步",
  "cms.synchronize.message.syncStatusOne": "同步中",
  "cms.synchronize.message.syncStatusTwo": "同步成功",
  "cms.synchronize.message.syncStatusThree": "同步失败",
  "cms.report.message.noData": "无数据",
  "cms.report.field.accessNo": "接入码：",
  "cms.report.field.skillQueue": "技能队列：",
  "cms.report.field.agentNum": "座席工号",
  "cms.indexmonitor.message.warningFilterMax": "最多选择{0}个指标！",
  "cms.report.title.callCenterMonitor": "虚拟呼叫中心性能监控",
  "cms.report.title.callCenterTrend": "虚拟呼叫中心性能趋势",
  "cms.report.title.callCenterAccessMonitor": "虚拟呼叫中心性能按接入码统计监控",
  "cms.report.title.callCenterAccessTrend": "虚拟呼叫中心性能按接入码统计趋势",
  "cms.report.title.IVRMonitor": "IVR流量监控",
  "cms.report.title.IVRTrend": "IVR流量趋势",
  "cms.report.title.IVRAccessMonitor": "IVR流量按接入码统计监控",
  "cms.report.title.IVRAccessTrend": "IVR流量按接入码统计趋势",
  "cms.report.title.skillMonitor": "技能队列性能监控",
  "cms.report.title.skillTrend": "技能队列性能趋势",
  "cms.report.title.skillAccessMonitor": "技能队列性能按接入码统计监控",
  "cms.report.title.skillAccessTrend": "技能队列性能按接入码统计趋势",
  "cms.report.title.skillAllMonitor": "技能队列汇总监控",
  "cms.report.title.skillAllTrend": "技能队列汇总趋势",
  "cms.report.title.agentMonitor": "座席性能汇总监控",
  "cms.report.title.agentTrend": "座席性能汇总趋势",
  "cms.report.title.agentOutCallMonitor": "座席外呼汇总监控",
  "cms.report.title.agentOutCallTrend": "座席外呼汇总趋势",
  "cms.report.title.agentOperationMonitor": "座席接续操作详情监控",
  "cms.report.title.agentOperationTrend": "座席接续操作详情趋势",
  "cms.report.title.abandonRingMonitor": "应答过程中被放弃的呼叫汇总监控",
  "cms.report.title.abandonRingTrend": "应答过程中被放弃的呼叫汇总趋势",
  "cms.report.title.vdnTrafficPeriodMonitor": "虚拟呼叫中心话务量间隔监控",
  "cms.report.title.vdnTrafficPeriodTrend": "虚拟呼叫中心话务量间隔趋势",
  "cms.report.title.ivrOutboundMonitor": "IVR外呼监控",
  "cms.report.title.ivrOutboundTrend": "IVR外呼趋势",
  "cms.report.title.vdnSystemPerformanceMonitor": "系统性能监控",
  "cms.report.title.vdnSystemPerformanceTrend": "系统性能趋势",
  "cms.report.title.changeTable": "切换表格",
  "cms.report.title.changeChart": "切换图表",
  "cms.callcenterinstanceslist.message.updateCtiVersion": "CTI数据是否完成割接，请谨慎操作!",
  "cms.callcenterinstanceslist.message.updateCtiVersion2": "CTI数据是否完成割接，请谨慎操作（确认后将关闭自动同步）!",
  "cms.callcenterinstanceslist.message.jobSwitch": "开启自动同步",

  "cms.reportsubscribe.field.subscriptionList": "订阅列表",
  "cms.reportsubscribe.field.subscribeContent": "订阅内容",
  "cms.reportsubscribe.field.subscribeName": "订阅名称",
  "cms.reportsubscribe.field.templateName": "邮件模板名称",
  "cms.reportsubscribe.field.sentTime": "发送时间",
  "cms.reportsubscribe.field.sentPeriod": "发送周期 ",
  "cms.reportsubscribe.field.reportName": "报表名称",
  "cms.reportsubscribe.field.status": "状态",
  "cms.reportsubscribe.field.publish": "发布",
  "cms.reportsubscribe.field.prohibit": "禁用",
  "cms.reportsubscribe.field.sentReport": "发送报表",
  "cms.reportsubscribe.field.selectedReport": "已选择报表列表",
  "cms.reportsubscribe.field.basicInfo": "基本信息",
  "cms.reportsubscribe.field.enable": "已发布",
  "cms.reportsubscribe.field.suspended": "未发布",
  "cms.reportsubscribe.field.monday": "周一",
  "cms.reportsubscribe.field.tuesday": "周二",
  "cms.reportsubscribe.field.wednesday": "周三",
  "cms.reportsubscribe.field.thursday": "周四",
  "cms.reportsubscribe.field.friday": "周五",
  "cms.reportsubscribe.field.saturday": "周六",
  "cms.reportsubscribe.field.sunday": "周日",
  "cms.reportsubscribe.title.modifyConfim": "修改确认",
  "cms.reportsubscribe.title.tips": "提示",
  "cms.reportsubscribe.message.searchSubscribeName": "请输入订阅名称",
  "cms.reportsubscribe.message.searchTemplateName": "请输入模板名称",
  "cms.reportsubscribe.message.deleteConfim": "确认删除吗",
  "cms.reportsubscribe.message.modifyStatus": "确定修改状态吗",
  "cms.reportsubscribe.message.reportNumberError": "已添加所有报表",
  "cms.report.title.callCenterReport": "VDN话务报表",
  "cms.report.title.callCenterAccessReport": "VDN接入码话务报表",
  "cms.report.title.IVRReport": "IVR流量报表",
  "cms.report.title.IVRAccessReport": "IVR接入码流量报表",
  "cms.report.title.skillReport": "技能话务报表",
  "cms.report.title.skillAccessReport": "技能接入码话务报表",
  "cms.report.title.skillAllReport": "话务汇总报表",
  "cms.report.title.agentReport": "座席汇总报表",
  "cms.report.title.agentOutCallReport": "座席外呼报表",
  "cms.report.title.agentOperationReport": "座席接续报表",
  "cms.report.title.abandonRingReport": "应答放弃呼叫报表",
  "cms.report.title.vdnTrafficPeriodReport": "VDN话务间隔报表",
  "cms.report.title.ivrOutboundReport": "IVR外呼报表",
  "cms.report.title.vdnSystemPerformanceReport": "系统性能监控报表",
  "cms.report.message.invalidParam": "参数不合法",
  "cms.report.message.tooManyExport": "短时间内导出{0}太多",
  "cms.report.message.queryFailed": "查询{0}失败",
  "cms.report.message.dataOverError": "查询{0}数据超过10000",
  "cms.report.message.validateFailed": "报表导出校验失败",
  "cms.report.message.voicedataOverError": "导出超过最大限制0，请重新选择查询条件",
  "cms.calllink.field.skillQueue": "技能队列",
  "cms.calllink.field.businessRepresentative": "业务代表",
  "cms.calllink.field.ivrServiceProcess": "IVR业务流程",
  "cms.calllink.field.call": "呼叫",
  "cms.calllink.field.routingTemporaryDevice": "路由临时设备",
  "cms.calllink.field.virtualDevice": "虚设备",
  "cms.calllink.field.cdnDevice": "CDN设备",
  "cms.calllink.field.MELCASDevice": "MELCAS设备",
  "cms.calllink.field.PGR": "PGR（指由SCAI控制的CDN缺省路由）",
  "cms.calllink.field.teleconference": "电话会议",
  "cms.calllink.field.commonAccessTransfer": "普通接入/转移",
  "cms.calllink.field.overflowing": "呼叫排队溢出转",
  "cms.calllink.field.timeoutTransfer": "呼叫排队超时转",
  "cms.calllink.field.noOnDutyTransfer": "无人上班转",
  "cms.calllink.field.cancelQueuingTransfer": "用户取消排队转移",
  "cms.calllink.field.noAnswerTransfer": "座席久不应答转移",
  "cms.calllink.field.suspendedAndResumed": "坐席侧挂起恢复",
  "cms.calllink.field.connectionHold": "坐席侧连接保持",
  "cms.calllink.field.threePartyHelp": "三方求助",
  "cms.calllink.field.unhold": "坐席取保持",
  "cms.calllink.field.threePartyCall": "三方通话",
  "cms.calllink.field.helperRelease": "坐席内部求助，求助方释放",
  "cms.calllink.field.intercepts": "质检员拦截",
  "cms.calllink.field.pickup": "质检员代答",
  "cms.calllink.field.analysisNotConfig": "呼叫的被叫分析未配置",
  "cms.calllink.field.activeRelease": "用户主动释放",
  "cms.calllink.field.agentRelease": "座席释放",
  "cms.calllink.field.transferOut": "坐席转出",
  "cms.calllink.field.hold": "坐席保持",
  "cms.calllink.field.internalError": "CTI内部处理错误",
  "cms.calllink.field.calledBusy": "用户/坐席被叫忙",
  "cms.calllink.field.powerOff": "用户手机关机",
  "cms.calllink.field.outOfService": "用户手机不在服务区",
  "cms.calllink.field.unavailableNumber": "被叫号码空号",
  "cms.calllink.field.noResources": "排队机无资源",
  "cms.calllink.field.doNotAnswer": "用户不应答",
  "cms.calllink.field.callRejected": "用户/坐席被叫拒绝",
  "cms.calllink.field.leaveReason28": "成功转队列",
  "cms.calllink.field.leaveReason29": "三方转队列",
  "cms.calllink.field.leaveReason30": "成功转队列超时",
  "cms.calllink.field.leaveReason31": "三方转队列超时",
  "cms.calllink.field.leaveReason34": "超时/溢出/遇忙网呼失败排队",
  "cms.calllink.field.leaveReason35": "网络呼叫返回原呼叫中心排队",
  "cms.calllink.field.leaveReason36": "排队的呼叫重路由",
  "cms.calllink.field.leaveReason37": "由呼入路由进入CDN",
  "cms.calllink.field.leaveReason38": "由座席咨询进入CDN",
  "cms.calllink.field.leaveReason39": "CDN正常返回路由结果",
  "cms.calllink.field.leaveReason40": "CDN路由超时",
  "cms.calllink.field.leaveReason41": "咨询时座席取消或路由失败",
  "cms.calllink.field.leaveReason42": "由CDN路由超时进入PGR",
  "cms.calllink.field.leaveReason43": "由CDN正常返回路由进入PGR",
  "cms.calllink.field.leaveReason44": "由呼入路由进入PGR",
  "cms.calllink.field.leaveReason45": "由座席咨询进入PGR",
  "cms.calllink.field.leaveReason46": "座席不可达，CDN重路由",
  "cms.calllink.field.leaveReason47": "呼叫在多个队列排队",
  "cms.calllink.field.leaveReason48": "在从队列中接听主队列的呼叫",
  "cms.calllink.field.leaveReason50": "座席拒接呼叫",
  "cms.calllink.field.leaveReason51": "用户呼叫进入电话会议",
  "cms.calllink.field.leaveReason52": "会议主席主动释放会议，或CCS异常导致会议释放",
  "cms.calllink.field.leaveReason53": "与会者被请出会议",
  "cms.calllink.field.leaveReason54": "接通率低于阈值转接",
  "cms.calllink.field.leaveReason88": "座席与用户接通后用户挂机",
  "cms.calllink.field.leaveReason89": "座席调用应答接口后，还未与用户接通，用户挂机",
  "cms.mediaType1": "文字交谈",
  "cms.mediaType2": "点击通话",
  "cms.mediaType3": "护航浏览、表单共享",
  "cms.mediaType4": "回呼请求呼叫",
  "cms.mediaType5": "普通语音电话",
  "cms.mediaType6": "电子邮件呼叫",
  "cms.mediaType7": "传真呼叫",
  "cms.mediaType8": "IP视频呼叫(H.323)",
  "cms.mediaType9": "电子白板",
  "cms.mediaType10": "应用程序共享",
  "cms.mediaType11": "文件传输",
  "cms.mediaType12": "2B+D ISDN视频呼叫",
  "cms.mediaType13": "6B+D ISDN视频呼叫",
  "cms.mediaType14": "OPS呼叫",
  "cms.mediaType15": "预测呼出",
  "cms.mediaType16": "预浏览呼出",
  "cms.mediaType17": "消息媒体",
  "cms.mediaType18": "视频点击通话",
  "cms.mediaType19": "视频普通呼叫",
  "cms.mediaType20": "非实时呼叫",
  "cms.mediaType53": "多媒体",
  "cms.calllink.field.callType33": "普通呼入",
  "cms.calllink.field.callType32": "长途呼入呼叫",
  "cms.calllink.field.callType31": "内部呼叫",
  "cms.calllink.field.callType30": "普通呼出",
  "cms.calllink.field.callType29": "一般指人工呼出",
  "cms.calllink.field.callType28": "座席呼出类型",
  "cms.calllink.field.callType27": "PRI呼出",
  "cms.calllink.field.callType26": "IVR呼出",
  "cms.calllink.field.callType0": "电话座席的自身呼出",
  "cms.calllink.field.callType1": "IVR PRI呼出",
  "cms.calllink.field.callType2": "网络呼叫中心的呼入",
  "cms.calllink.field.callType3": "网络呼叫中心的呼出",
  "cms.calllink.field.callType4": "网络呼叫中心的质检呼入",
  "cms.calllink.field.callType5": "内部呼叫虚座席的网络呼入",
  "cms.calllink.field.callType6": "内部呼叫虚座席的网络呼出",
  "cms.calllink.field.callType7": "辅助呼叫",
  "cms.calllink.field.callType8": "来话呼叫(国际话务员)",
  "cms.calllink.field.callType9": "递延呼叫",
  "cms.calllink.field.callType10": "去话呼叫(国际话务员)",
  "cms.calllink.field.callType11": "预约呼出",
  "cms.calllink.field.callType12": "预占用呼出",
  "cms.calllink.field.callType13": "预连接呼出",
  "cms.calllink.field.callType14": "预测呼出",
  "cms.calllink.field.callType15": "预览呼出",
  "cms.calllink.field.callType16": "回呼请求",
  "cms.calllink.field.callType17": "国际长途来话",
  "cms.calllink.field.callType18": "彩铃业务呼叫",
  "cms.calllink.field.callType19": "两方求助呼叫",
  "cms.calllink.field.callType20": "三方求助呼叫",
  "cms.calllink.field.callType21": "预占用PRI呼出",
  "cms.calllink.field.callType22": "预连接PRI呼出",
  "cms.calllink.field.callType23": "虚呼入PRI呼出",
  "cms.calllink.field.callType24": "预览PRI呼出",
  "cms.calllink.field.callType25": "PRI回呼请求",
  "cms.calllink.title.callId": "呼叫标识",
  "cms.calllink.title.callType": "呼叫类型",
  "cms.calllink.title.caller": "主叫号码",
  "cms.calllink.title.callee": "被叫号码",
  "cms.calllink.title.mediaType": "媒体类型",
  "cms.calllink.title.deviceType": "设备类型",
  "cms.calllink.title.deviceNo": "设备号",
  "cms.calllink.title.deviceDesc": "设备描述",
  "cms.calllink.title.leaveReason": "离开设备原因",
  "cms.calllink.title.callTrace": "呼叫链路",
  "cms.calllink.title.beginTime": "统计开始时间",
  "cms.calllink.title.endTime": "统计结束时间",
  "cms.calllink.title.logDate": "统计时间",
  "cms.calllink.title.callBeginTime": "通话开始时间",
  "cms.calllink.title.callEndTime": "通话结束时间",
  "cms.calllink.title.agentId": "接通座席工号",
  "cms.calllink.title.skillQueue": "接通技能队列",
  "cms.calllink.msg.numberInvalid": "号码格式不正确",
  "cms.calldetail.msg.callIdInvalid": "格式不正确",
  "cms.calllink.msg.callerPlaceHolder": "请输入主叫号码",
  "cms.calllink.msg.calleePlaceHolder": "请输入被叫号码",
  "cms.calllink.msg.dateTooLate": "选择的日期不得晚于 {0}",
  "cms.calldetail.msg.timeTooLate": "选择的时间不得晚于 {0}",
  "cms.calllink.title.callTrack": "通话轨迹",
  "cms.calllind.field.deviceQueue": "技能队列",
  "cms.calllind.field.deviceAgent": "业务代表",
  "cms.calllind.field.deviceRoute": "路由临时设备",
  "cms.calllind.field.deviceVnr": "虚设备",
  "cms.uapmonitor.field.callSip": "终端设备标识",
  "cms.uapmonitor.field.callSize": "终端类型",
  "cms.uapmonitor.field.callUapIp": "UAP IP",
  "cms.uapmonitor.field.callStatus": "注册状态",
  "cms.uapmonitor.field.callSocket": "SOCKET状态",
  "cms.uapmonitor.field.callIpPort": "IP和端口",
  "cms.uapmonitor.field.callIpSize": "IP类型",
  "cms.uapmonitor.field.callOperator": "操作",
  "cms.uapmonitor.field.Logout": "注销",
  "cms.uapmonitor.field.Search": "查询",
  "cms.uapmonitor.field.registered": "已注册",
  "cms.uapmonitor.field.unregister": "未注册",
  "cms.uapmonitor.field.socketFault": "故障",
  "cms.uapmonitor.field.socketNormal": "正常",
  "cms.uapmonitor.field.socketConnecting": "连接中",
  "cms.uapmonitor.field.socketUninstall": "未配置",
  "cms.uapmonitor.label.error": "错误",
  "cms.uapmonitor.label.registererror": "注销失败",
  "cms.uapmonitor.title.regist": "终端注册状态",
  "cms.uapmonitor.title.deregis": "注销确认",
  "cms.uapmonitor.field.deregister": "请确认是否注销该设备",
  "cms.interfaceperflog.message.dvSwitch": "开启数据上报",
  "cms.interfaceperflog.message.modifySuccess": "修改成功",
  "cms.interfaceperflog.message.modifyFail": "修改失败",
  "cms.fullscreen.field.themeType": "主题类型",
  "cms.fullscreen.field.themeTypeY": "公共主题",
  "cms.fullscreen.field.themeTypeN": "私有主题",
  "cms.fullscreen.message.publicTheme": "(公共)",
  "cms.fullscreen.field.setIndicatorThreshold": "设置指标阈值",
  "cms.fullscreen.field.minThreshold": "最小阈值",
  "cms.fullscreen.field.maxThreshold": "最大阈值",
  "cms.fullscreen.field.no": "否",
  "cms.fullscreen.field.yes": "是",
  "cms.fullscreen.field.IVR": "IVR",
  "cms.fullscreen.message.numberCheck": "输入值必须为0或正整数或2位以内正小数",
  "cms.fullscreen.message.digitsCheck": "输入值必须为正整数",
  "cms.fullscreen.message.thresholdLessThan": "需大于最小值",
  "cms.fullscreen.message.warningSelectOne": "设置阈值只能选择1个指标",
  "cms.export.field.addExportTask": "新增导出任务",
  "cms.export.field.selectFileType": "选择导出文件格式",
  "cms.export.field.viewExportTask": "导出列表",
  "cms.export.field.refresh": "刷新",
  "cms.export.field.download": "下载",
  "cms.export.field.exportBeginTime": "开始时间",
  "cms.export.field.exportEndTime": "结束时间",
  "cms.export.field.exportTaskStatus": "任务状态",
  "cms.export.field.operation": "操作",
  "cms.export.message.addExportTaskSuccess": "新增导出任务成功",
  "cms.export.message.addExportTaskError": "新增导出任务失败,请检查日志",
  "cms.export.field.unprocessed": "未处理",
  "cms.export.field.exportiong": "导出中",
  "cms.export.field.success": "成功",
  "cms.export.field.failed": "失败",
  "cms.export.message.passValidateFailed": "8-12位,需包含字母,数字,特殊字符,特殊字符不包含|;&$-<>/\\`!#(){}空格、换行符、制表符",
  "cms.export.field.zippwd": "压缩密码",
  "cms.export.message.popwindowmessage": "请输入用于生成压缩文件的密码,8-12位,压缩密码应包含字母,数字,特殊字符,特殊字符不包含{'|;&$-<>/'}{'\\\\'}{'`!#(){}'}空格、换行符、制表符",
  "cms.custdata.operlog.popwindowmessage": "请输入用于生成压缩文件的密码,压缩密码应包含字母,数字,特殊字符 _ {'@'} %",
  "cms.export.message.exception": "生成导出任务遇到异常，请检查日志",
  "cms.export.message.parainvalid": "导出条件校验失败",
  "cms.export.message.exceedlimit1": "最多可以导出0条数据，请缩小数据时间范围或增加数据过滤条件",
  "cms.export.message.exceedlimit2": "，请缩小数据时间范围或增加数据过滤条件",
  "cms.export.message.exceedNumberLimit": "数据量过大，请缩小数据时间范围或增加数据过滤条件",
  "cms.export.message.nocount": "没有符合条件的数据",
  "cms.export.title.voiceDownload": "录音质检",
  "cms.export.message.downloadSucceed": "报表下载成功",
  "cms.export.message.downloadFailed": "报表下载失败",
  "cms.title.select": "选择",

  "cms.vcallecenter.title.resetpwd": "重置密码",
  "cms.vcallecenter.label.resetpwd": "重置管理员帐号密码",
  "cms.vcallecenter.label.tenantaccount": "管理员帐号",
  "cms.vcallecenter.label.inputnewpwd": "请输入新密码",
  "cms.vcallecenter.label.renewpwd": "重复新密码",
  "cms.vcallecenter.title.tips": "提示",
  "cms.vcallecenter.message.validatefailed": "密码校验失败",
  "cms.vcallecenter.resetpwd.message.fail": "密码重置失败",
  "cms.agentmonitor.label.cancellisteningOrInsert": "取消监听或插入",
  "cms.agentmonitor.label.cancelsuccess": "取消成功",
  "cms.agentmonitor.label.cancelfail": "取消失败",
  "cms.agentmonitor.message.header": "保存失败，至少选择一个表头",

  "cms.report.field.mediatype.vedio": "视频",
  "cms.report.field.mediatype.H5Vedio": "H5视频",
  "cms.report.field.mediatype.clickvediocall": "视频点击通话",
  "cms.report.field.submediatype.web": "Web",
  "cms.report.field.submediatype.whatsapp": "WhatsApp",
  "cms.report.field.submediatype.line": "LINE",
  "cms.report.field.submediatype.wechat": "微信",
  "cms.report.field.submediatype.facebook": "Facebook",
  "cms.report.field.submediatype.twitter": "X (Twitter)",
  "cms.report.field.submediatype.5gmessage": "5G消息",
  "cms.report.field.submediatype.email": "邮件",
  "cms.report.field.submediatype.sms": "短信",
  "cms.report.field.submediatype.instagram": "Instagram",
  "cms.report.field.submediatype.telegram": "Telegram",
  
  "cms.report.field.selectAgent": "选择座席",
  "cms.report.field.selectOrg": "选择组织机构",
  "cms.report.placeholder.workName": "请输入座席姓名",
  "cms.report.message.selectAgent": "至少选择一个座席工号",
  "cms.report.message.selectAgentMax": "最多选择100个座席工号",
  "cms.systemmonitor.label.search": "查询",
  "cms.systemmonitor.label.reset": "重置",
  "cms.systemmonitor.field.selectCcName": "选择呼叫中心",
  "cms.systemmonitor.field.selectAgentName": "选择租户",
  "cms.systemmonitor.message.dategt31days": "查询天数不能超过31天",
  "cms.systemmonitor.message.addVdnMost": "租户选择最多100个",
  "cms.systemmonitor.field.cancel": "取消",
  "cms.systemmonitor.field.confirm": "确定",
  "cms.systemmonitor.field.time": "统计时间",
  "cms.systemmonitor.field.ccName": "呼叫中心节点",
  "cms.systemmonitor.field.vdnName": "租户",
  "cms.systemmonitor.field.onlineAgentNums": "在线座席数",
  "cms.systemmonitor.field.ideaAgentNum": "空闲座席数",
  "cms.systemmonitor.field.talkingNums": "通话座席数",
  "cms.systemmonitor.field.busyNums": "示忙座席数",
  "cms.systemmonitor.field.incallNums": "系统呼入量",
  "cms.systemmonitor.field.rateNum": "系统接通量",
  "cms.systemmonitor.field.callRate": "系统接通率",
  "cms.systemmonitor.field.uapIP": "UAP IP",
  "cms.systemmonitor.field.ofcNo": "入局编号",
  "cms.systemmonitor.field.systemInBound": "系统呼入量",
  "cms.systemmonitor.field.systemInSucc": "系统接通量",
  "cms.systemmonitor.field.systemCallRate": "系统接通率",
  "cms.systemmonitor.field.agentOnlineCount": "软终端注册数",
  "cms.systemmonitor.field.queryTime": "查询时间",
  "cms.systemmonitor.label.thirtyminutes": "最近30分钟",
  "cms.systemmonitor.label.onehour": "最近1小时",
  "cms.systemmonitor.label.threehours": "最近3小时",
  "cms.systemmonitor.label.thirtyonedays": "最近31天",
  "cms.systemmonitor.label.customizes": "自定义",
  "cms.systemmonitor.message.timeempty": "*选择时间不能为空",
  "cms.systemmonitor.message.timeerror": "*起始时间不能大于终止时间",
  "cms.systemmonitor.message.timelarge": "*所选择的时间范围不能超过31天",
  "cms.systemmonitor.field.ok": "确定",
  "cms.systemmonitor.title.tips": "提示",
  "cms.systemmonitor.title.error": "错误",
  "cms.systemmonitor.message.queryErrorInfo": "查询失败",
  "cms.systemmonitor.label.CommercialUse": "商用",
  "cms.systemmonitor.label.noncommercial": "试商用",
  "cms.vdnconfiguration.label.configure": "配置",
  "cms.vdnconfiguration.field.defaultCallCenter": "VDN运行的呼叫中心",
  "cms.vdnconfiguration.title.vdnConfigure": "配置VDN运行的呼叫中心",
  "cms.vdnconfiguration.field.cancel": "取消",
  "cms.vdnconfiguration.field.ok": "确定",
  "cms.vdnconfiguration.title.success": "成功",
  "cms.vdnconfiguration.title.error": "失败",
  "cms.vdnconfiguration.message.updatesuccess": "更新成功",
  "cms.vdnconfiguration.message.updatefailed": "更新失败",
  "cms.callcenterselect.title.selectCcName": "选择呼叫中心",

  "cms.callcenterinstanceslist.title.chooseMode": "选择模式",
  "cms.callcenterinstanceslist.field.cmsAuthName": "CCDIS鉴权帐号",
  "cms.callcenterinstanceslist.field.cmsAuthPassword": "CCDIS鉴权密码",
  "cms.callcenterinstanceslist.field.disPwdConfirm": "确认CCDIS鉴权密码",
  "cms.callcenterinstanceslist.field.single": "Single模式",
  "cms.callcenterinstanceslist.field.pool": "Pool模式",
  "cms.callcenterinstanceslist.field.dvDataReport": "DV数据上报",
  "cms.callcenterinstanceslist.message.cleanUap": "UAP节点配置清理",
  "cms.callcenterinstanceslist.message.cleanKafka": "KAFKA节点配置清理",
  "cms.callcenterinstanceslist.message.clearUapConfig": "是否清理UAP节点配置信息",
  "cms.callcenterinstanceslist.message.clearKafkaConfig": "是否清理KAFKA节点配置信息",
  "cms.callcenterinstanceslist.message.uapNodeInfo": "UAP节点配置",
  "cms.callcenterinstanceslist.message.uapNode1Info": "UAP节点一配置",
  "cms.callcenterinstanceslist.message.uapNode2Info": "UAP节点二配置",
  "cms.callcenterinstanceslist.field.uapIp": "UAP NMU IP",
  "cms.callcenterinstanceslist.field.uapCmdPort": "UAP MML指令端口",
  "cms.callcenterinstanceslist.field.uapPerfPort": "UAP性能监控端口",
  "cms.callcenterinstanceslist.field.uapUserName": "UAP CDE用户名",
  "cms.callcenterinstanceslist.field.uapPassword": "UAP CDE用户密码",
  "cms.callcenterinstanceslist.field.uapPasswordConfirm": "UAP CDE密码确认",

  "cms.callcenterinstanceslist.message.kafkaNodeInfo": "KAFKA节点配置",
  "cms.callcenterinstanceslist.message.needKafkaSSL": "启用SSL",
  "cms.callcenterinstanceslist.field.kafkaIp": "KAFKA对接IP",
  "cms.callcenterinstanceslist.field.kafkaPort": "KAFKA对接端口",
  "cms.callcenterinstanceslist.field.kafkaUserName": "KAFKA对接用户名",
  "cms.callcenterinstanceslist.field.kafkaPassword": "KAFKA对接密码",
  "cms.callcenterinstanceslist.field.kafkaPasswordConfirm": "KAFKA对接密码确认",

  "cms.callcenterinstanceslist.message.datasourceInfo": "请完善数据源配置",
  "cms.callcenterinstanceslist.message.dsmodifyconfirm": "数据源配置发生变更，需要重启环境才能生效，请确认是否修改？",

  "cms.datasource.label.create": "新增",
  "cms.datasource.label.delete": "删除",
  "cms.datasource.label.edit": "编辑",
  "cms.datasource.message.dbNameInput": "请输入数据源名称",
  "cms.datasource.field.dbName": "数据源名称",
  "cms.datasource.field.dbServiceType": "业务类型",
  "cms.datasource.field.dbType": "数据源类型",
  "cms.datasource.field.dbUsername": "用户名称",
  "cms.datasource.field.isBackup": "是否为备份库",
  "cms.datasource.field.backupDbName": "备份数据源",
  "cms.datasource.field.operation": "操作",
  "cms.datasource.title.addDatasource": "新增数据源",
  "cms.datasource.field.dbUrl": "数据库连接字符串",
  "cms.datasource.field.dbPwd": "密码",
  "cms.datasource.field.remark": "用途描述",
  "cms.datasource.field.test": "测试连接",
  "cms.datasource.field.cancel": "取消",
  "cms.datasource.field.ok": "确定",
  "cms.datasource.field.cmsDatabase": "CMS报表库",
  "cms.datasource.field.uidbDatabase": "UIDB数据库",
  "cms.datasource.field.no": "否",
  "cms.datasource.field.yes": "是",
  "cms.datasource.field.passwordConfirm": "密码确认",
  "cms.datasource.title.modifyDatasource": "更新数据源",
  "cms.datasource.message.isDeleteDatasource": "请确认是否删除数据源",
  "cms.datasource.message.addsuccess": "新增成功",
  "cms.datasource.message.addfailed": "新增失败",
  "cms.datasource.message.updatesuccess": "更新成功",
  "cms.datasource.message.updatefailed": "更新失败",
  "cms.datasource.message.deletesuccess": "删除成功",
  "cms.datasource.message.deletefailed": "删除失败",
  "cms.callcenterinstanceslist.title.sourceconfiguration": "数据源配置",
  "cms.datasource.message.mysql5UrlTip": "数据库连接字符串:&#8220jdbc:mysql://ip:port/schema&#8220&#10;ip(ip地址) port(端口) schema(模式)",
  "cms.datasource.message.mysql8UrlTip": "数据库连接字符串:&#8220jdbc:mysql://ip:port/schema&#8220&#10;ip(ip地址) port(端口) schema(模式)",
  "cms.datasource.message.oracleUrlTip": "数据库连接字符串:&#8220jdbc:oracle:thin:@ip:port:dbservicename&#8220&#10;ip(ip地址) port(端口) dbservicename(数据库名称)",
  "cms.datasource.message.gaussUrlTip": "数据库连接字符串:&#8220jdbc:zenith:@ip:port&#8220&#10;ip(ip地址) port(端口)",
  "cms.datasource.message.dmUrlTip": "数据库连接字符串:&#8220jdbc:dm://ip:port/dbservicename&#8220&#10;ip(ip地址) port(端口) dbservicename(数据库名称)",
  "cms.datasource.message.passValidateFailed": "输入的内容不能包含<=>/\"eval和exec",
  "cms.datasource.message.already": "数据源名称已存在",
  "cms.datasource.message.testsuccess": "测试连接成功",
  "cms.datasource.message.testfailed": "测试连接失败",
  "cms.datasource.message.ccDsRelationExist": "数据源在呼叫中心已引用",
  "cms.datasource.message.masterSalveRelationExist": "数据源已作为备份数据源",
  "cms.datasource.message.dsmodifyconfirm": "数据源在呼叫中心已引用，需要重启环境才能生效，请确认是否修改？",

  "cms.systemparam.success.save": "参数保存成功",
  "cms.systemparam.error.save": "参数保存失败",
  "cms.systemparam.success.reset": "参数重置成功",
  "cms.systemparam.error.reset": "参数重置失败",
  "cms.systemparam.success.refresh": "参数刷新成功",
  "cms.systemparam.error.refresh": "参数刷新失败",
  "cms.systemparam.error.notmatchrule": "参数不满足校验规则",
  "cms.systemparam.error.noparamselected": "请先选择参数",

  "cms.sysparam.config.search": "查询",
  "cms.sysparam.config.reset": "重置",
  "cms.sysparam.config.refresh": "刷新",
  "cms.sysparam.config.itemname": "参数名称",
  "cms.sysparam.config.value": "参数值",
  "cms.sysparam.config.itemdesc": "描述",
  "cms.sysparam.config.opterate": "操作",
  "cms.sysparam.config.save": "保存",
  "cms.sysparam.config.mod": "编辑",
  "cms.sysparam.config.checkmessage": "含有特殊字符(\"<\",\">\",\"/\"等)",
  "cms.sysparam.config.itemname.tips": "请输入参数名称",
  "cms.sysparam.config.select.prompt": "提示",
  "cms.sysparam.config.select.null": "请选择参数!",
  "cms.sysparam.config.select.count": "最多选择\u002010\u0020条参数！",
  "cms.sysparam.config.item.detail": "参数信息",
  "cms.sysparam.config.item.catalog": "参数类别",
  "cms.sysparam.config.title": "系统参数",
  "cms.sysparam.config.reset.multiple.error": "以下参数不允许被重置：",
  "cms.systemparam.error.validate.inner": "校验参数失败",
  "cms.systemparam.error.validate.ruleinvalid": "校验规则不合法",
  "cms.systemparam.error.validate.ruleregexinvalid": "校验规则语法错误",
  "cms.systemparam.error.validate.notmatchrule": "输入参数与校验规则不匹配",
  "cms.systemparam.error.validate.notmatchruleregex": "输入参数与校验规则的正则表达式不匹配",
  "cms.systemparam.error.validate.notmatchrulealpha": "请输入字母数字或下划线，且只能以字母或下划线开头",
  "cms.systemparam.error.validate.notmatchruledigits": "请输入合法的正整数",
  "cms.systemparam.error.validate.notmatchruleemail": "请输入合法的邮箱地址",
  "cms.systemparam.error.validate.notmatchruleip": "请输入合法的IPV4或IPV6地址",
  "cms.systemparam.error.validate.notmatchrulemaxlength": "输入参数超出最大长度0",
  "cms.systemparam.error.validate.notmatchrulemax": "输入参数超出最大值",
  "cms.systemparam.error.validate.notmatchruleminlength": "输入参数低于最小长度",
  "cms.systemparam.error.validate.notmatchrulemin": "输入参数低于最小值",
  "cms.systemparam.error.validate.notmatchrulenumber": "请输入数字",
  "cms.systemparam.error.validate.notmatchruleoption": "输入参数不在枚举范围内",
  "cms.systemparam.error.validate.notmatchrulerangelength": "输入参数超出最大长度或低于最小长度",
  "cms.systemparam.error.validate.notmatchrulerange": "输入参数超出最大值或低于最小值",
  "cms.systemparam.error.validate.notmatchrulerequired": "必填项",
  "cms.systemparam.error.validate.notmatchruleurl": "请输入合法的URL地址",

  "cms.uapmonitor.labe.interval": "监控周期",
  "cms.uapmonitor.labe.eid": "终端设备号段",
  "cms.uapmonitor.labe.qcworkno": "质检员工号",
  "cms.uapmonitor.labe.inspectorcheckinnum": "质检员签入号码",
  "cms.uapmonitor.labe.inspectorcheckinpwd": "质检员签入密码",
  "cms.uapmonitor.labe.openmonitor": "开启监控",
  "cms.uapmonitor.labe.uapconfing": "终端状态监控与恢复配置",
  "cms.uapmonitor.labe.eidmention": "可以输入最多10个号段，以逗号分隔。每个号段规则：*和%匹配0个或多个字符。如6666*和6666%，匹配所有以6666开头的字符串。?仅匹配1个任意字符。如6666?，仅匹配以6666开头、长度为5的字符串。支持通配符*、?、%的组合，如%3？，匹配所有倒数第二个字符为3的字符串。【注意】：建议每个通配符匹配的结果小于100，否则会影响UAP侧正常业务",
  "cms.uapmonitor.message.intervalvalidate": "请输入值为300-86400的数字",
  "cms.uapmonitor.message.eidvalidate": "请按照提示输入参数",
  "cms.uapmonitor.labe.mentiontitle": "注：",
  "cms.uapmonitor.labe.mention": "1.开启监控后，将定时对终端设备注册状态和座席签入状态不一致的座席进行强制签出操作；",
  "cms.uapmonitor.labe.mentionnotice": "2.请确保虚拟呼叫中心包含质检员，且质检员配置了软电话号码和签入密码，状态异常的座席才会强制签出",
  "cms.uapmonitor.labe.uapoverload": "终端设备号段查询的号码数量超过系统最大限制：",
  "cms.uapmonitor.labe.eidoverlength": "终端设备号段数量超过系统最大限制：10",
  "cms.errormessage.inputspecialchar": "输入值包含非法特殊字符",

  "cms.calldetail.label.inputcallid": "请输入呼叫流水号",
  "cms.report.field.callIdField": "呼叫流水号",
  "cms.report.field.callIdNumField": "话单序号",
  "cms.report.field.callerNoField": "主叫号码",
  "cms.report.field.calleeNoField": "被叫号码",
  "cms.report.field.waitBeginField": "等待开始时间",
  "cms.report.field.ackBeginField": "应答开始时间",
  "cms.report.field.callBeginField": "通话开始时间",
  "cms.report.field.callEndField": "通话结束时间",
  "cms.report.field.deviceTypeField": "设备类型",
  "cms.report.field.deviceNoField": "设备号",
  "cms.report.field.callTypeField": "呼叫类型",
  "cms.report.field.mediaTypeField": "媒体类型",
  "cms.report.field.enterReasonField": "呼叫进入设备原因",
  "cms.report.field.billInfo1Field": "语音通话类型",
  "cms.report.field.obsServiceIdField": "外呼活动标识",
  "cms.report.field.currentSkillIdField": "技能队列编号",
  "cms.report.field.subMediaTypeField": "子媒体类型",
  "cms.report.field.waitEndField": "等待结束时间",
  "cms.report.field.ackEndField": "应答结束时间",
  "cms.report.field.waitCauseField": "等待原因",
  "cms.report.field.releaseCauseField": "释放原因",
  "cms.report.field.uvidField": "用户访问标识",
  "cms.report.field.userLevelField": "客户级别",
  "cms.report.field.callInCauseField": "呼叫进入原因",
  "cms.report.field.leaveReasonField": "呼叫离开设备原因",
  "cms.report.field.preDeviceTypeField": "上一个设备类型",
  "cms.report.field.preDeviceNoField": "上一个设备的设备标号",
  "cms.report.field.skillIdField": "呼叫所属技能队列ID",
  "cms.report.field.locationIdField": "分布式节点编号",
  "cms.report.field.billInfo2Field": "双呼唯一标识",
  "cms.report.field.uapIdField": "UAP编号",
  "cms.report.field.netEntIdField": "媒体服务器网元编号",
  "cms.report.message.callIdDesc": "呼叫流水号，一通通话的唯一标识",
  "cms.report.message.callIdNumDesc": "对一个呼叫生成的多条话单的序号标识",
  "cms.report.message.callerNoDesc": "呼叫的主叫号码",
  "cms.report.message.calleeNoDesc": "呼叫的被叫号码",
  "cms.report.message.waitBeginDesc": "呼叫等待开始时间",
  "cms.report.message.ackBeginDesc": "呼叫应答开始时间",
  "cms.report.message.callBeginDesc": "呼叫通话开始时间",
  "cms.report.message.callEndDesc": "呼叫通话结束时间",
  "cms.report.message.deviceTypeDesc": "处理呼叫的设备类型",
  "cms.report.message.deviceNoDesc": "呼叫当前处理设备的设备号",
  "cms.report.message.callTypeDesc": "呼叫类型",
  "cms.report.message.mediaTypeDesc": "呼叫媒体类型",
  "cms.report.message.enterReasonDesc": "呼叫进入设备原因",
  "cms.report.message.billInfo1Desc": "语音通话类型",
  "cms.report.message.obsServiceIdDesc": "外呼活动的标识ID",
  "cms.report.message.currentSkillIdDesc": "当前处理呼叫的技能队列的编号",
  "cms.report.message.subMediaTypeDesc": "子媒体类型",
  "cms.report.message.waitEndDesc": "呼叫等待结束时间,指呼叫在当前设备上的等待结束时间",
  "cms.report.message.ackEndDesc": "呼叫应答结束时间,指呼叫在当前设备上的应答结束时间",
  "cms.report.message.waitCauseDesc": "呼叫在当前设备上产生等待的原因",
  "cms.report.message.releaseCauseDesc": "呼叫的释放原因",
  "cms.report.message.uvidDesc": "CCS为每个访问呼叫中心的用户分配的全局唯一标识",
  "cms.report.message.userLevelDesc": "用户在呼叫中心所处的客户级别",
  "cms.report.message.callInCauseDesc": "呼叫进入本呼叫中心的原因（针对语音呼叫）",
  "cms.report.message.leaveReasonDesc": "呼叫离开设备原因",
  "cms.report.message.preDeviceTypeDesc": "呼叫经历的上一个设备的设备类型",
  "cms.report.message.preDeviceNoDesc": "呼叫经历的上一个设备的设备标号",
  "cms.report.message.skillIdDesc": "呼叫所属技能队列的ID",
  "cms.report.message.locationIdDesc": "呼叫所属的分布式节点编号",
  "cms.report.message.billInfo2Desc": "双呼唯一标识",
  "cms.report.message.uapIdDesc": "将呼叫接入到呼叫中心平台的UAP的编号",
  "cms.report.message.netEntIdDesc": "产生当前话单记录的媒体服务器的网元编号",
  "cms.report.title.calldetailoriginal": "呼叫内容明细",
  "cms.vcallcenter.message.unknowcause": "未知原因",
  "cms.vcallcenter.message.unknowdesc": "未知异常",
  "cms.vcallcenter.message.unknowsolution": "请联系华为工程师进行异常分析处理",

  "cms.report.message.startOrEndTimeNoExist": "若选择时间段查询，则开始结束时间需同时存在",
  "cms.report.message.startTimeLessThanEndTime": "开始时间不能大于结束时间",
  "cms.report.field.stateField": "是否接通",
  "cms.report.field.connected": "已接通",
  "cms.report.field.notConnected": "未接通",
  "cms.report.field.hangUpModeField": "挂断方式",
  "cms.report.field.startTimeField": "呼叫启动时间",
  "cms.report.field.endTimeField": "呼叫结束时间",
  "cms.report.field.accessCodeField": "接入码",
  "cms.report.field.callNoPlaceholder": "主叫或被叫号码",
  "cms.report.field.callStartTimeField": "呼叫启动时间",
  "cms.report.field.callEndTimeField": "呼叫结束时间",
  "cms.report.field.alertingTimeField": "被叫振铃时间",
  "cms.report.field.talkingTimeField": "通话开始时间",
  "cms.report.field.talkDurationField": "通话时长",
  "cms.report.field.agentReleaseTimeField": "通话结束时间",
  "cms.report.field.agentAlertingTimeField": "主叫振铃时间",
  "cms.report.field.agentTalkingTimeField": "主叫接通时间",
  "cms.report.field.agentTalkDurationField": "座席通话时长",
  "cms.report.field.createTimeField": "通话创建时间",
  "cms.report.field.agentAlertingDurationField": "主叫振铃时长",
  "cms.report.field.alertingDurationField": "被叫振铃时长",
  "cms.report.field.callerHangUp": "主叫挂断",
  "cms.report.field.calledHangUp": "被叫挂断",
  "cms.report.field.exceptionHangUp": "异常挂断",
  "cms.report.field.dualCallType": "一键双呼",
  "cms.report.title.filteringHeader": "筛选表头",
  "cms.report.field.tableHeader": "表头名称",
  "cms.report.title.callNo": "呼叫号码",
  "cms.report.title.dualCallDetail": "双呼详情",
  "cms.report.message.stateDesc": "接通状态,包含已接通和未接通",
  "cms.report.message.hangUpModeDesc": "挂断方式",
  "cms.report.message.accessCodeDesc": "接入码",
  "cms.report.message.callStartTimeDesc": "呼叫开始时间",
  "cms.report.message.callEndTimeDesc": "呼叫结束时间",
  "cms.report.message.alertingTimeDesc": "被叫振铃时间",
  "cms.report.message.talkingTimeDesc": "通话开始时间",
  "cms.report.message.talkDurationDesc": "通话时长,单位秒",
  "cms.report.message.agentReleaseTimeDesc": "通话结束时间",
  "cms.report.message.agentAlertingTimeDesc": "主叫振铃时间",
  "cms.report.message.agentTalkingTimeDesc": "主叫接通时间",
  "cms.report.message.agentTalkDurationDesc": "座席通话时长,单位秒",
  "cms.report.message.createTimeDesc": "通话创建时间",
  "cms.report.message.agentAlertingDurationDesc": "主叫振铃时长,单位秒",
  "cms.report.message.alertingDurationDesc": "被叫振铃时长,单位秒",
  "cms.report.message.inputZipPassword": "请输入压缩密码",
  "cms.report.task.sucess.export": "已成功创建导出任务，是否前往导出列表进行查看？",
  "cms.report.task.export.records": "导出接触记录",
  "cms.report.field.length.limit": "必须为数字且长度不能超过0",
  "cms.report.field.number": "无效的数字",
  "cms.report.field.view.export.task": "导出任务查看",
  "cms.report.field.enter.callin": "请输入呼入流水号",
  "cms.agent.lable.alarm": "告警",
  "cms.agent.label.agentlist": "座席列表",
  "cms.agent.label.agentdetailInfo": "座席详情",
  "cms.agent.label.enterdetail": "查看详情",
  "cms.agent.label.statetime": "状态持续时长",
  "cms.agent.label.duracount": "时长统计",
  "cms.agent.label.numcount": "次数统计",
  "cms.agent.label.agentname": "座席名称",
  "cms.agent.label.logintime": "在线时长",
  "cms.agent.label.idlenum": "示闲次数",
  "cms.agent.label.arrangetime": "整理时长",
  "cms.agent.label.logouttime": "离线时长",
  "cms.agent.label.alarmstate": "告警状态",
  "cms.agent.label.agentDetail": "座席详情",
  "cms.input.format.invalid": "输入格式不正确",
  "cms.normal.text.validate": "只允许输入数字、字母以及-._{'@'}",
  "cms.calllind.field.deviceUnkown": "未知设备",
  "cms.agent.label.signinphone": "签入号码",
  "cms.agent.label.currentagentstate": "座席当前状态",
  "cms.agent.label.currentstatetime": "当前状态时长",
  "cms.agent.label.choosestatetime": "选择状态最短时长",
  "cms.agent.label.busyitem": "示忙",
  "cms.agent.label.talkitem": "通话",
  "cms.agent.label.online": "在线",
  "cms.agent.label.offline": "离线",
  "cms.agent.label.callinrate": "呼入接通率",
  "cms.agent.label.callintime": "呼入通话时长",
  "cms.agent.label.avgcallintime": "呼入平均通话时长",
  "cms.agent.label.avgwaittime": "呼入平均等待时长",
  "cms.agent.label.maxwaittime": "呼入最大等待时长",
  "cms.agent.label.calloutsuccrate": "呼出接通率",
  "cms.agent.label.callouttime": "呼出通话时长",
  "cms.agent.label.avgcallouttime": "呼出平均通话时长",
  "cms.agent.label.choosesybmediatype": "选择渠道类型",
  "cms.agent.label.busytime": "示忙时长",
  "cms.agent.label.avgbusytime": "示忙均长",
  "cms.agent.label.acgtalktime": "平均通话时长",
  "cms.agent.label.outresttime": "休息超时时长",
  "cms.agent.label.totaltalktime": "总通话时长",
  "cms.agent.label.redirtnum": "重定向次数",
  "cms.agent.label.totaltalknums": "总通话次数",
  "cms.agent.tips.selectstatetime": "选择状态时长，需选择当前状态",
  "cms.agent.label.selectstatetime": ">=状态时长(分钟)",
  "cms.agent.tips.sipaccountcheck": "请选择1-32位正整数作为签入号码",
  "cms.indexmonitor.title.agentDate": "座席数据",
  "cms.agent.tips.notassined": "未分配",
  "cms.indexmonitor.set.limitA": "请选择",
  "cms.indexmonitor.set.limitB": "个指标进行展示",
  "cms.indexmonitor.titile.indicatorSetting": '指标设置',
  "cms.indexmonitor.realTime.indicator": "实时指标",
  "cms.indexmonitor.curDay.indicator": "当日指标",
  "cms.incoming.record.not.sameday": "不支持跨天查询",
  "cms.requiredcallback.message.allowTimeRange": "最大支持查询7天内数据",
  "cms.incoming.record.field.releasecause": "呼叫释放原因",
  "cms.incoming.record.field.releasecause.input": "请输入呼叫释放原因",
  "cms.incoming.record.field.releasecause.id": "请输入呼叫释放原因码",
  "cms.incoming.record.field.releasecause.causecode": "请输入原因映射码",
  "cms.incoming.record.field.ifReqManual": "是否请求过人工",
  "cms.incoming.record.field.isReqManual": "已请求人工",
  "cms.incoming.record.field.notReqManual": "未请求人工",
  "cms.incoming.record.field.ifAnswerCall": "人工是否接听",
  "cms.incoming.record.field.isAnswerCall": "人工已接听",
  "cms.incoming.record.field.notAnswerCall": "人工未接听",
  "cms.incoming.record.field.releasecause.choose": "选择呼叫释放原因",
  "cms.incoming.record.field.release.id": "呼叫释放原因码",
  "cms.incoming.record.field.release.causecode": "原因映射码",
  "cms.incoming.releasecause.causecode.tip": "原因映射码超出范围（0~45）",
  "cms.incoming.record.report.beginTime": "查询起始时间",
  "cms.incoming.record.report.endTime": "查询结束时间",
  "cms.requiredcallback.message.timeRangeCheck": "时间范围为同一天",
  "cms.failurecall.field.waitTime.export": "等待时长(s)",
  "cms.failurecall.field.waitBegin": "呼叫等待开始时间",
  "cms.failurecall.field.ackBegin": "呼叫应答开始时间",
  "cms.failurecall.field.waitAnsNum": "等待应答次数",
  "cms.failurecall.field.waitAnsTime": "等待应答时长(秒)",
  "cms.failurecall.field.succWaitAnsTime": "成功通话应答时长(秒)",
  "cms.failurecall.field.noAckNum": "应答无通话次数",
  "cms.failurecall.field.succQueueWaitTime": "成功通话排队时长(秒)",
  "cms.failurecall.field.succQueueWaitNum": "成功通话排队次数",
  "cms.failurecall.field.failQueueWaitNum": "呼损等待时长(秒)",
  "cms.failurecall.field.inOccupyNum": "人工请求次数",
  "cms.failurecall.field.inCallSuccNum": "人工通话次数",
  "cms.failurecall.field.inCallTime": "人工通话时长(秒)",
  "cms.failurecall.field.queOverToIvrNum": "队列转IVR挂机数",
  "cms.failurecall.field.queOverToIvrTime": "队列转IVR挂机总时间(秒)",
  "cms.failurecall.field.hangUpNum": "人工转IVR次数",
  "cms.failurecall.field.hangUpTime": "人工转IVR总时间",
  "cms.failurecall.field.hangUpReleaseNum": "人工转IVR挂机数",
  "cms.indexmonitor.index.multimediaCalls": "多媒体",
  "cms.indexmonitor.index.callTrends": "会话趋势",
  "cms.indexmonitor.index.skillQueueServiceLevel": "技能队列服务水平概览",
  "cms.indexmonitor.index.met": "达成",
  "cms.indexmonitor.index.missed": "未达成",
  "cms.indexmonitor.index.noTrafficData": "无话务数据",
  "cms.indexmonitor.index.SkillQueueTips": "技能队列服务水平采用20秒内接通率进行衡量，缺省预期服务水平为80%，租户管理员可在租户参数配置中进行修改：租户参数 > 呼叫中心监控 > 指标监控 > 技能队列预期服务水平",
  "cms.indexmonitor.index.SkillQueueTips2": "技能队列服务水平采用20秒内接通率进行衡量，缺省预期服务水平为80%，系统管理员可在参数配置中进行修改：系统参数 > 呼叫中心监控 > 指标监控 > 技能队列预期服务水平",
  "cms.indexmonitor.index.loadFail": "加载失败",
  "cms.failurecall.queue.abandon": "排队放弃查询",
  "cms.failurecall.callback": "需回呼记录查询",
  "cms.faliurecall.incomingcall": "来话记录查询",
  "cms.vcallecenter.message.checkpasswordfailed": "签入密码规则校验失败",
  "cms.agent.field.admin": "管理员",
  "cms.agent.field.commonagent": "话务员",
  "cms.agent.field.qualitychecker": "质检员",
  "cms.failure.callerNo.maxLength.error": "最多只能输入64个字符",

  "cms.report.title.submediaType": "多媒体类型",
  "cms.multimedia.monitor.field.callNum": "IVR呼叫数",
  "cms.multimedia.monitor.field.transNum": "IVR转人工数",
  "cms.multimedia.monitor.field.transRate": "IVR转人工率",
  "cms.multimedia.monitor.field.connectedNum": "IVR接通数",
  "cms.multimedia.monitor.field.connectedRate": "IVR接通率",
  "cms.multimedia.monitor.field.durationAverage": "IVR通话均长",
  "cms.multimedia.monitor.field.callNum.desc": "当前由IVR设备提供自动服务的呼叫数",
  "cms.multimedia.monitor.field.transNum.desc": "IVR转人工的呼叫数",
  "cms.multimedia.monitor.field.transRate.desc": "IVR转人工数在IVR呼叫数中所占的百分比",
  "cms.multimedia.monitor.field.connectedNum.desc": "IVR呼叫数中通话时长大于0的次数",
  "cms.multimedia.monitor.field.connectedRate.desc": "IVR呼叫接通数在IVR呼叫数中所占的百分比",
  "cms.multimedia.monitor.field.durationAverage.desc": "IVR通话总时长/IVR接通数",
  "cms.qcrelationconf.field.attendantGroupName": "话务组名称",
  "cms.report.field.selectTrafficGroup": "选择话务组",
  "cms.report.placeholder.attendantGroupName": "请输入话务组名称",
  "cms.qcrelationconf.field.attendantGroup": "话务组",
  "cms.report.message.selectTrafficGroupMax": "最多选择1000个话务组",
  "cms.agentMonitor.message.operatorChecked": "操作失败，被质检对象为质检员且正在执行质检操作",
  "cms.export.message.required": "必填项",
  "cms.field.length.limit": "长度不能超过0",
  "cms.sysparameter.batchreset": "批量重置",
  "cms.sysparameter.batchrefresh": "批量同步",
  "cms.report.subscribe.add": "新建报表订阅",
  "cms.report.subscribe.edit": "编辑报表订阅",
  "cms.qcrelationconf.tab.member": "成员",
  "cms.qcrelationconf.add.attendantGroup": "添加被质检话务组",
  "cms.qcrelationconf.message.isDeleteTrafficGroup": "请确认是否删除该话务组",
  "cms.report.subscribe.checkreportnum": "至少选择一张报表",
  "cms.sysparameter.edit.basic.information": "编辑基本信息",
  "cms.sysparameter.edit.character": "编辑特性",
  "cms.sysparameter.edit.validate.ip": "请输入正确的ip地址",
  "cms.message.choose": "请选择数据",
  "cms.subscribe.template.isused": "邮件模板已被使用",
  "cms.subscribe.template.statuserror": "邮件模板状态错误",
  "cms.qcrelationconf.message.unauthorized": "保存数据中存在非法座席",
  "cms.report.subscribe.editreport":"编辑报表",
  "cms.report.subscribe.addreport":"新增报表",
  "cms.agentMonitor.label.changestate_error":"质检状态切换失败，操作有误",
  "cms.agent.label.systempara":"参数配置",
}