<template>
  <sweet-dialog v-model="isShow" :title="title" width="616px" class="aicc-dialog-limit-height" :close-on-click-modal="false"  @close="onDialogClose">
    <aicc-advanced-search
      ref="advancedSearch"
      :templateMetaData="templateMetaData"
      @handleSearch="handleSearch"
      @handleRefresh="handleSearch"
      @inputClear="handleSearch"
      @orgTreeSelectChange="handleSearch"
    />
    
    <aicc-table row-key="id" @handlePagination="handlePagination" :tableData="messageTemplateList" :tableColumns="tableColumns" :tableTools="{ columnConfig: false, showPagination: true }" :paginationConfig="paginationConfig" ref="refAiccTable" height="430" />

    <template #footer>
      <span class="dialog-footer">
        <sweet-button @click="close">{{ $t("message.template.message.cancel") }}</sweet-button>
        <sweet-button type="primary" @click="confirmSelect">{{ $t("ccnotification.common.button.finish") }}</sweet-button>
      </span>
    </template>
  </sweet-dialog>
</template>

<script>
import cookie from "@/utils/cookie"
import { getUserAccess } from "@/utils/organization.js"
import { querySysMessageTemplate, queryMessageTemplate, getOrgListByUserId } from '../../../api/index'

export default {

  data() {
    return {
      isShow: true,
      searchObject: {},
      isShowOrgPop: true,
      orgId: '',
      noOrgFlag: true,
      orgName: $t('pop.access.org.window.allAccessOrgs'),
      offset: 0,
      limit: 10,
      messageTemplateList: [],
      total: 0,
      orgData: [],
      accessOrgCache: [],
      paginationConfig: {
        pageSizes: [10, 20, 50, 100],
        pageSize: 10,
        total: 0,
        layout: "sizes, total, prev, pager, next"
      },
      tableColumns: [
        {
          type: 'radio',
          'reserve-selection': true,
          radioKey: 'id',
          width: '50px'
        },
        {
          label: $t('ccnotification.notifition.variable.templatename'),
          prop: 'name'
        },
        {
          label: $t('ccnotification.notifition.sendmessage.messagetitle'),
          prop: 'title'
        }
      ]
    }
  },
  methods: {
    onDialogClose() {
      this.$emit('onClose')
    },
    async initData() {
      this.searchObject.type = this.type
      this.searchObject.mediaType = this.mediatype
      this.searchObject.smsType = this.smstype

      if ('defaultSpace' == cookie.getCookie("tenant_space_id")) {
        this.isShowOrgPop = false;
      }
      if (this.isShowOrgPop) {
        const orgId = cookie.getCookie("org_id")
        this.accessOrgCache.push(orgId)
        
        let orgs = await getOrgListByUserId(cookie.getCookie("user_id"))
        if (orgs) {
          orgs.forEach(element => {
            if (this.accessOrgCache.indexOf(element.orgId) == -1) {
              this.accessOrgCache.push(element.orgId)
            }
          });
        }
        this.orgId = orgId
        this.searchObject.orgIdList = this.accessOrgCache
        this.searchObject.noOrgFlag = this.noOrgFlag

        getUserAccess().then(orgnationTreedata => {
          if (orgnationTreedata && orgnationTreedata.length > 0) {
            orgnationTreedata.forEach(org => this.checkDisable(org))
            this.orgData = orgnationTreedata
          }
        })
      }
      this.searchTemplates()
    },
    checkDisable(data) {
      if (!data.isAccess) {
        data.disabled = true
      }
      if (data.children && data.children.length > 0) {
        data.children.forEach(child => this.checkDisable(child))
      }
    },
    handleSearch({ params }) {
      if (this.isShowOrgPop) {
        const selectedOrgIds = this.$refs.advancedSearch.getFormatParams().orgIds
        if (selectedOrgIds && selectedOrgIds.length > 0 && selectedOrgIds.indexOf('0') == -1) {
          this.searchObject.noOrgFlag = false
        } else {
          this.searchObject.noOrgFlag = true
        }

        //默认情况下，或选择了有组织机构，查询所有可访问组织的模板
        if ((!selectedOrgIds || selectedOrgIds.length == 0) || selectedOrgIds.indexOf('1') > -1) {
          this.searchObject.orgIdList = this.accessOrgCache
        } else {
          const orgIdList = selectedOrgIds?.filter(id => id != 0)
          if (orgIdList && orgIdList.length > 0) {
            this.searchObject.orgIdList = orgIdList
          } else {
            this.searchObject.orgIdList = []
          }
        }
      }

      if (params.name) {
        this.searchObject.name = params.name
      } else {
        delete this.searchObject.name
      }
      this.offset = 0
      this.$refs.refAiccTable.setCurrentPage(1)
      this.searchTemplates()
    },
    async searchTemplates() {
      this.searchObject.offset = this.offset
      this.searchObject.limit = this.limit
      
      if (this.mediatype != '1') {
        delete this.searchObject['smsType']
      }
      let result
      if (this.auth == 'tenant') {
        result = await queryMessageTemplate(this.searchObject)
      } else if (this.auth == 'admin') {
        result = await querySysMessageTemplate(this.searchObject)
      } else {
        return
      }
      if (!result) {
        this.messageTemplateList = []
        this.total = 0
        return
      }
      this.messageTemplateList = result.result
      this.paginationConfig.total = result.total
    },
    handlePagination(val) {
      this.limit = val.limit
      this.offset = val.offset
      this.searchTemplates()
    },
    confirmSelect() {
      const currentRow = this.$refs.refAiccTable.getRadioCurrentRow()
      if (currentRow.id) {
        this.$emit('selectTemplate', currentRow)
        this.close()
      }
    },
    close() {
      this.isShow = false
    }
  },
  mounted() {
    this.initData()
  },
  computed: {
    templateMetaData() {
      let data = {
        basicConfig: {
          basicSearch: [{
            searchType: 'primary',
            filterable: false,
            modelValue: 'name',
            clearable: true,
            placeholderValue: this.$t('ccnotification.notifition.variable.templatename'),
            colConfig: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12
            },
          }],
          iconButton: [{
            buttonType: 'refresh',
          }],
          iconAreaColConfig: {
            xs: 3,
            sm: 3,
            md: 3,
            lg: 3,
            xl: 3
          },
        }
      }
      if (this.isShowOrgPop) {
        data.basicConfig.basicSearch.push({
          searchType: 'orgTreeSelect',
          modelValue: 'orgIds',
          treeOptions: this.orgData,
          multiple: true,
          colConfig: {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12
          },
        })
      }
      return data
    }
  },
  props: {
    title: String,
    type: String,
    auth: String,
    mediatype: String,
    smstype: String 
  }
}
</script>