import webclientArSA from './webclient_ar_SA'
import webclientEnUS from './webclient_en_US'
import webclientEsES from './webclient_es_ES'
import webclientFrFR from './webclient_fr_FR'
import webclientPtBR from './webclient_pt_BR'
import webclientThTH from './webclient_th_TH'
import webclientZhCN from './webclient_zh_CN'
import webclientDeDE from './webclient_de_DE'


const webclient = {
  ar_SA: {
    ...webclientArSA
  },
  en_US: {
    ...webclientEnUS
  },
  es_ES: {
    ...webclientEsES
  },
  fr_FR: {
    ...webclientFrFR
  },
  pt_BR: {
    ...webclientPtBR
  },
  th_TH: {
    ...webclientThTH
  },
  zh_CN: {
    ...webclientZhCN
  },
  de_DE: {
    ...webclientDeDE
  },
}

export default webclient
