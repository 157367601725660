export default {
  "dy.agent.audio.tab.outgoingcall" : "สายโทรออก",
  "dy.agent.audio.tab.internalcall" : "สายภายใน",
  "dy.chat.multimedia.update" : "อัพเดต",
  "dy.agent.tab.over.number.hints" : "แท็บแท็บแท็บเปิดได้สูงสุด 8 รายการ คุณไม่สามารถเปิดแท็บอื่นได้ โปรดปิดแท็บอื่นก่อน",
  "dy.chat.agent.logout.hints" : "ยังไม่ได้ลงชื่อเข้าใช้ โปรดลงชื่อเข้าใช้เพื่อเริ่มงานของคุณ",
  "dy.chat.label.contacts" : "ติดต่อ",
  "dy.chat.label.call" : "โทร",
  "dy.chat.label.multimedia" : "มัลติมีเดีย",
  "dy.chat.label.history" : "ประวัติ",
  "dy.chat.label.monitor" : "ตรวจสอบ",
  "dy.agent.label.id" : "รหัส:",
  "dy.agent.label.enter.exnum" : "ป้อนหมายเลขภายนอก",
  "dy.chat.multimedia.required" : "ต้องระบุ",
  "dy.chat.history.disconnect.tip" : "สายถูกตัดการเชื่อมต่อคุณสามารถส่งข้อความออฟไลน์เพื่อเชื่อมต่อกับลูกค้าอีกครั้ง",
  "dy.agent.voice.extnumber.isnull" : "ตัวเลขภายนอกเป็นโมฆะ",
  "dy.agent.voice.msg.noskill" : "ไม่มีคิวสกิล",
  'dy.agent.session.list.timeout': 'หมดเวลา',
  'dy.session.transfer.button': 'เข้าใจแล้ว',
  'dy.agent.transfer.failed': 'ไม่สามารถถ่ายโอนไปยังตัวแทนปลายทางได้',
  'dy.agent.twoinnerhelp.failed': 'ไม่สามารถปรึกษาตัวแทนปลายทางได้',
  'dy.agent.threeinnerhelp.failed': 'ความล้มเหลวของตัวแทนปลายทางสามฝ่าย',
  'dy.agent.message.tipsNotMonitor': 'คุณไม่ใช่นักสืบ คุณไม่สามารถทําการตรวจสอบได้',
  'dy.agent.message.selectInfo': 'คลิกที่ตัวแทนก่อนเพื่อตรวจสอบตัวแทน',
  'dy.agent.attachment.notPreview': 'ประเภทไฟล์ไม่รองรับการแสดงตัวอย่างโปรดดาวน์โหลดไฟล์เพื่อดูเนื้อหา',
  'dy.chat.agent.novoEmail': 'อีเมลใหม่',
  "dy.chat.sendmessage.recipient" : "ต่อไป",
  "dy.chat.chatcard.folded": "พับ",
  "dy.chat.status.drafts": "แบบร่าง",
  "dy.agent.call.selectextnumber": "โปรดเลือกหมายเลขภายนอก",
  "dy.agent.call.inputextnumber": "โปรดป้อนหมายเลขภายนอก"
}