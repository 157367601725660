export default {
  "oifde.custom.displayName":"Unternehmensservice",
  "oifde.common.displayName":"Gemeinsamer Knoten",
  "oifde.basic.displayName":"Basisservice",
  "oifde.test.displayName":"Testbeispieldienst",
  "oifde.start.displayName":"Starten",
  "oifde.start.description":"Starten",
  "oifde.start.keywords":"Starten",
  "oifde.commonBusinessInvokeService.displayName":"Aufruf der Geschäftsschnittstelle",
  "oifde.commonBusinessInvokeService.description":"Aufruf der Geschäftsschnittstelle (commonBusinessInvokeService)",
  "oifde.commonBusinessInvokeService.keywords":"Aufruf der Geschäftsschnittstelle",
  "oifde.procedureService.displayName":"Gespeicherte Prozedur",
  "oifde.procedureService.description":"Gespeicherte Prozedur (procedureService)",
  "oifde.procedureService.keywords":"Gespeicherte Prozedur",
  "oifde.conditionService.displayName":"Zustandsurteil",
  "oifde.conditionService.description":"Bedingungsbeurteilung (conditionService)",
  "oifde.conditionService.keywords":"Zustandsurteil",
  "oifde.callEndService.displayName":"Anruf wird beendet",
  "oifde.callEndService.description":"Anruf wird beendet (callEndService)",
  "oifde.callEndService.keywords":"Anruf wird beendet",
  "oifde.sendSmsService.displayName":"SMS wird gesendet",
  "oifde.sendSmsService.description":"SMS wird gesendet (sendSmsService)",
  "oifde.sendSmsService.keywords":"SMS wird gesendet",
  "oifde.commonVoiceService.displayName":"Antwort",
  "oifde.commonVoiceService.description":"Antwort (commonResponseService)",
  "oifde.commonVoiceService.keywords":"Antwort",
  "oifde.transforFlowService.displayName":"Flusstransfer",
  "oifde.transforFlowService.description":"Flussübertragung (transforFlowService)",
  "oifde.transforFlowService.keywords":"Flusstransfer",
  "oifde.callTransferService.displayName":"Anrufweiterleitung",
  "oifde.callTransferService.description":"Anrufweiterleitung (callTransferService)",
  "oifde.callTransferService.keywords":"Anrufweiterleitung",
  "oifde.speechRecognitionService.displayName":"Semantische Erkennung",
  "oifde.speechRecognitionService.description":"Semantische Erkennung (speechRecognitionService)",
  "oifde.speechRecognitionService.keywords":"Semantische Erkennung",
  "oifde.speechRecognitionService.confidence2TopError":"Das Konfidenzintervall muss zwischen 0 und 1 liegen.",
  "oifde.keyboardRecognitionService.displayName":"Schlüsselerkennung",
  "oifde.keyboardRecognitionService.description":"Tastenerkennung (keyboardRecognitionService)",
  "oifde.keyboardRecognitionService.keywords":"Schlüsselerkennung",
  "oifde.textVariableToChatbotService.displayName":"Text an Chatbot",
  "oifde.textVariableToChatbotService.description":"Text an Chatbot (textVariableToChatbotService)",
  "oifde.textVariableToChatbotService.keywords":"Text an Chatbot",
  "oifde.checkRegularService.displayName":"Regelvalidierung",
  "oifde.checkRegularService.description":"Regelvalidierung (checkRegularService)",
  "oifde.checkRegularService.keywords":"Regelvalidierung",
  "oifde.dateProcessService.displayName":"Datumsverarbeitung",
  "oifde.dateProcessService.description":"Datumsverarbeitung (dateProcessService)",
  "oifde.dateProcessService.keywords":"Datumsverarbeitung",
  "oifde.errTimesClearService.displayName":"Freigabe der Fehleranzahl",
  "oifde.errTimesClearService.description":"Freigabe der Fehleranzahl (errTimesClearService)",
  "oifde.errTimesClearService.keywords":"Freigabe der Fehleranzahl",
  "oifde.CircleService.displayName":"Logikschleife",
  "oifde.CircleService.description":"Logische Schleife (CircleService),",
  "oifde.CircleService.keywords":"Logikschleife",
  "oifde.service.invoke.user.level":"Benutzerebene",
  "oifde.UserLevelService.displayName":"Benutzerebene",
  "oifde.UserLevelService.keywords":"Benutzerebene",
  "oifde.UserLevelService.description":"UserLevelService (Benutzerebene)",
  "oifde.service.invoke.user.level.reminder":"Stellen Sie diesen Parameter auf eine nicht negative Ganzzahl oder eine Zeichenvariable ein. Die minimale Benutzerebene ist 0 und die maximale Benutzerebene ist 14. Nachdem der Anruf an den manuellen Service weitergeleitet wurde, wird der Anruf eines hochrangigen Kunden zuerst bearbeitet. Wenn der Anruf nicht im Bereich von 0-14 liegt, wird der Anruf basierend auf dem Grenzwert verarbeitet.,",
  "oifde.compositeTestService.displayName":"Elementbeispiel für ein zusammengesetztes Diagramm",
  "oifde.compositeTestService.description":"Elementbeispiel für zusammengesetztes Diagramm (compositeTestService)",
  "oifde.compositeTestService.keywords":"Elementbeispiel für ein zusammengesetztes Diagramm",
  "oifde.service.condition.condgroup.desc":"Beurteilungsbedingungsattribute festlegen.",
  "oifde.service.condition.condgroup.displayName":"Gerichtsbedingungen",
  "oifde.service.condition.condgroup.keyTitle":"Zustand",
  "oifde.service.condition.condgroup.valueTitle":"Bedingungsausdruck",
  "oifde.service.invokemethod.group.desc":"Methodenaufruf",
  "oifde.service.invokemethod.group.displayName":"Methodenaufruf",
  "oifde.service.invokemethod.group.value1Title":"Objekt zurückgeben",
  "oifde.service.invokemethod.group.value2Title":"Methodenname",
  "oifde.service.invokemethod.group.value3Title":"Methodenparameter",
  "oifde.service.invokemethod.group.value1Tips":"Methode gibt Wert empfangendes Objekt zurück",
  "oifde.service.invokemethod.group.value2Tips":"Wählen Sie einen Methodennamen aus.",
  "oifde.service.invokemethod.group.value3Tips":"Methodenparameter1",
  "oifde.service.invokemethod.group.value4Tips":"Methodenparameter2",
  "oifde.service.invokemethod.group.value5Tips":"Methodenparameter3",
  "oifde.service.invokemethod.group.mathodType1":"Zeichenfolgenmanipulationsmethode",
  "oifde.service.invokemethod.group.mathodType2":"Array-Betriebsmethode",
  "oifde.service.invokemethod.group.mathodType3":"Kartenbetriebsmethode",
  "oifde.service.invokemethod.strSplit.desc":"Teilt eine Zeichenfolge basierend auf dem angegebenen Trennzeichen",
  "oifde.service.invokemethod.strSubstring.desc":"Extrahieren von Teilzeichenfolgen",
  "oifde.service.invokemethod.strIndexOf.desc":"ermittelt den tiefgestellten Wert des ersten Vorkommens einer angegebenen Zeichenfolge",
  "oifde.service.invokemethod.strStartsWith.desc":"Überprüft, ob die Zeichenfolge mit dem angegebenen Präfix beginnt",
  "oifde.service.invokemethod.strEndsWith.desc":"Überprüft, ob eine Zeichenfolge mit einem angegebenen Suffix endet",
  "oifde.service.invokemethod.strParseJSON.desc":"Wandelt eine JSON-Zeichenfolge in ein Objekt um",
  "oifde.service.invokemethod.strParseInt.desc":"Wandelt eine Zeichenfolge in eine Ganzzahl um",
  "oifde.service.invokemethod.strParseFloat.desc":"Wandelt eine Zeichenfolge in eine Gleitkommazahl um",
  "oifde.service.invokemethod.strLength.desc":"Länge der Zeichenkette",
  "oifde.service.invokemethod.strToUpperCase.desc":"Konvertiert Kleinbuchstaben von Zeichenfolgen in Großbuchstaben",
  "oifde.service.invokemethod.strToLowerCase.desc":"Konvertiert Großbuchstaben von Zeichenfolgen in Kleinbuchstaben",
  "oifde.service.invokemethod.arrayAdd.desc":"Ein Element zu einem Array hinzufügen",
  "oifde.service.invokemethod.arrayAddAll.desc":"Fügt alle Elemente einer anderen Sammlung zu einem Array hinzu",
  "oifde.service.invokemethod.arrayContains.desc":"Legt fest, ob sich ein Element in einem Array befindet.",
  "oifde.service.invokemethod.arrayGet.desc":"Ruft Elemente in einem Array nach Indexwert ab",
  "oifde.service.invokemethod.arrayRemoveIdx.desc":"Löscht das angegebene tiefgestellte Element aus dem Array",
  "oifde.service.invokemethod.arrayRemoveAllIdx.desc":"”Löscht tiefgestellte Elemente im Array, die in der angegebenen Sammlung vorhanden sind”,",
  "oifde.service.invokemethod.arrayClear.desc":"Löscht alle Elemente in einem Array",
  "oifde.service.invokemethod.arraySize.desc":"ermittelt die Länge eines Arrays",
  "oifde.service.invokemethod.arraySubList.desc":"Bricht einen Teil eines Arrays ab und gibt ihn zurück",
  "oifde.service.invokemethod.arrayJoin.desc":"Ein Array in eine Zeichenfolge konvertieren",
  "oifde.service.invokemethod.mapPut.desc":"Ein Element zu einer Karte hinzufügen",
  "oifde.service.invokemethod.mapGet.desc":"erhält den Wert der angegebenen Tastenzuordnung in der Zuordnung",
  "oifde.service.invokemethod.mapRemove.desc":"Löscht ein angegebenes Schlüssel-Wert-Paar aus der Karte",
  "oifde.service.invokemethod.mapClear.desc":"Alle Schlüssel-Wert-Paare der Karte löschen",
  "oifde.service.invokemethod.mapSize.desc":"ermittelt die Anzahl aller Tasten der Karte",
  "oifde.service.invokemethod.strSplit.parm1.desc":"Zeichenkette, die aufgeteilt werden soll",
  "oifde.service.invokemethod.strSplit.parm2.desc":"Trennzeichen",
  "oifde.service.invokemethod.strSubstring.parm1.desc":"Zeichenfolge, die abgeschnitten werden soll",
  "oifde.service.invokemethod.strSubstring.parm2.desc":"Tiefgestellt beginnen (Ganzzahl)",
  "oifde.service.invokemethod.strSubstring.parm3.desc":"Endtiefstellung (Ganzzahl)",
  "oifde.service.invokemethod.strIndexOf.parm1.desc":"Zeichenfolge, nach der gesucht werden soll",
  "oifde.service.invokemethod.strIndexOf.parm2.desc":"Angegebene Zeichenfolge",
  "oifde.service.invokemethod.strStartsWith.parm1.desc":"Zu überprüfende Zeichenkette",
  "oifde.service.invokemethod.strStartsWith.parm2.desc":"Angegebene Präfixzeichenfolge",
  "oifde.service.invokemethod.strEndsWith.parm1.desc":"Zu überprüfende Zeichenkette",
  "oifde.service.invokemethod.strEndsWith.parm2.desc":"Angegebene Suffixzeichenfolge",
  "oifde.service.invokemethod.strParseJSON.parm1.desc":"Zu konvertierende Zeichenfolge",
  "oifde.service.invokemethod.strParseInt.parm1.desc":"Zu konvertierende Zeichenfolge",
  "oifde.service.invokemethod.strParseFloat.parm1.desc":"Zu konvertierende Zeichenfolge",
  "oifde.service.invokemethod.strLength.parm1.desc":"Zeichenkette mit zu berechnender Länge",
  "oifde.service.invokemethod.strToUpperCase.parm1.desc":"Zu konvertierende Zeichenfolge",
  "oifde.service.invokemethod.strToLowerCase.parm1.desc":"Zu konvertierende Zeichenfolge",
  "oifde.service.invokemethod.arrayAdd.parm1.desc":"Hinzufügendes Array (diese Methode ändert das hinzuzufügende Array)“",
  "oifde.service.invokemethod.arrayAdd.parm2.desc":"Position hinzugefügt (Standardanhänger)",
  "oifde.service.invokemethod.arrayAdd.parm3.desc":"Elemente hinzugefügt",
  "oifde.service.invokemethod.arrayAddAll.parm1.desc":"Hinzufügendes Array (diese Methode ändert das hinzuzufügende Array)“",
  "oifde.service.invokemethod.arrayAddAll.parm2.desc":"Position hinzugefügt (Standardanhänger)",
  "oifde.service.invokemethod.arrayAddAll.parm3.desc":"Elemente hinzugefügt",
  "oifde.service.invokemethod.arrayContains.parm1.desc":"Array, das erkannt werden soll",
  "oifde.service.invokemethod.arrayContains.parm2.desc":"Zu beurteilende Elemente",
  "oifde.service.invokemethod.arrayGet.parm1.desc":"Zu verwendendes Array",
  "oifde.service.invokemethod.arrayGet.parm2.desc":"Array-Tiefstellung",
  "oifde.service.invokemethod.arrayRemoveIdx.parm1.desc":"Zu verwendendes Array",
  "oifde.service.invokemethod.arrayRemoveIdx.parm2.desc":"Array-Tiefstellung löschen",
  "oifde.service.invokemethod.arrayRemoveAllIdx.parm1.desc":"Zu verwendendes Array",
  "oifde.service.invokemethod.arrayRemoveAllIdx.parm2.desc":"Gibt den Array-Tiefstellungssatz an",
  "oifde.service.invokemethod.arrayClear.parm1.desc":"Zu löschendes Array",
  "oifde.service.invokemethod.arraySize.parm1.desc":"Array der zu berechnenden Längen",
  "oifde.service.invokemethod.arraySubList.parm1.desc":"Abzufangendes Array",
  "oifde.service.invokemethod.arraySubList.parm2.desc":"Erfassungsstartposition",
  "oifde.service.invokemethod.arraySubList.parm3.desc":"Endposition des Abfangs",
  "oifde.service.invokemethod.arrayJoin.parm1.desc":"Zu konvertierendes Array",
  "oifde.service.invokemethod.arrayJoin.parm2.desc":"Verbindungszeichenfolge",
  "oifde.service.invokemethod.mapPut.parm1.desc":"Zuzufügende Karte (diese Methode ändert die hinzuzufügende Karte)",
  "oifde.service.invokemethod.mapPut.parm2.desc":"Schlüssel (nur der Zeichenfolgentyp wird unterstützt)",
  "oifde.service.invokemethod.mapPut.parm3.desc":"Elemente hinzugefügt",
  "oifde.service.invokemethod.mapGet.parm1.desc":"Zu bearbeitende Karte",
  "oifde.service.invokemethod.mapGet.parm2.desc":"Schlüssel (nur der Zeichenfolgentyp wird unterstützt)",
  "oifde.service.invokemethod.mapRemove.parm1.desc":"Zu bearbeitende Karte",
  "oifde.service.invokemethod.mapRemove.parm2.desc":"Schlüssel (nur der Zeichenfolgentyp wird unterstützt)",
  "oifde.service.invokemethod.mapClear.parm1.desc":"Zu bereinigende Karte",
  "oifde.service.invokemethod.mapSize.parm1.desc":"Karte der zu berechnenden Menge",
  "oifde.service.invokemethod.strSplit.resultObj.desc":"Das zurückgegebene Objekt ist vom Array-Typ und speichert das String-Array nach der Aufteilung.",
  "oifde.service.invokemethod.strSubstring.resultObj.desc":"Das zurückgegebene Objekt ist eine Zeichenfolge, die eine abgeschnittene Teilzeichenfolge ist.",
  "oifde.service.invokemethod.strIndexOf.resultObj.desc":"Das zurückgegebene Objekt ist ein tiefgestellter Wert. Der Wert ist eine Ganzzahl.",
  "oifde.service.invokemethod.strStartsWith.resultObj.desc":"Das zurückgegebene Objekt ist das Erkennungsergebnis. Der Wert ist eine Ganzzahl von 0 oder 1.",
  "oifde.service.invokemethod.strEndsWith.resultObj.desc":"Das zurückgegebene Objekt ist das Erkennungsergebnis. Der Wert ist eine Ganzzahl von 0 oder 1.",
  "oifde.service.invokemethod.strParseJSON.resultObj.desc":"Das zurückgegebene Objekt ist das konvertierte JSON-Objekt.",
  "oifde.service.invokemethod.strParseInt.resultObj.desc":"Das zurückgegebene Objekt ist nach der Konvertierung eine Ganzzahl.",
  "oifde.service.invokemethod.strParseFloat.resultObj.desc":"Das zurückgegebene Objekt ist eine konvertierte Gleitkommazahl.",
  "oifde.service.invokemethod.strLength.resultObj.desc":"Das Rückgabeobjekt ist die Länge einer Zeichenfolge. Der Wert ist eine Ganzzahl.",
  "oifde.service.invokemethod.strToUpperCase.resultObj.desc":"Das zurückgegebene Objekt ist nach der Konvertierung eine Zeichenfolge.",
  "oifde.service.invokemethod.strToLowerCase.resultObj.desc":"Das zurückgegebene Objekt ist nach der Konvertierung eine Zeichenfolge.",
  "oifde.service.invokemethod.arrayAdd.resultObj.desc":"Das zurückgegebene Objekt ist das Addierergebnis. Wenn das Hinzufügen erfolgreich ist, wird 1 zurückgegeben. Andernfalls wird 0 zurückgegeben.",
  "oifde.service.invokemethod.arrayAddAll.resultObj.desc":"Das zurückgegebene Objekt ist das Addierergebnis. Wenn das Hinzufügen erfolgreich ist, wird 1 zurückgegeben. Andernfalls wird 0 zurückgegeben.",
  "oifde.service.invokemethod.arrayContains.resultObj.desc":"Das zurückgegebene Objekt ist das Urteilsergebnis. Der Wert ist eine Ganzzahl von 0 oder 1.",
  "oifde.service.invokemethod.arrayGet.resultObj.desc":"Gibt ein Element zurück, dessen Objekt ein Array ist.",
  "oifde.service.invokemethod.arrayRemoveIdx.resultObj.desc":"Das zurückgegebene Objekt ist das Löschergebnis. Wenn der Löschvorgang erfolgreich ist, wird das entsprechende tiefgestellte Element zurückgegeben. Andernfalls wird Null zurückgegeben.",
  "oifde.service.invokemethod.arrayRemoveAllIdx.resultObj.desc":"Das zurückgegebene Objekt ist das Löschergebnis. Wenn der Löschvorgang erfolgreich ist, wird 1 zurückgegeben. Andernfalls wird 0 zurückgegeben.",
  "oifde.service.invokemethod.arrayClear.resultObj.desc":"Das zurückgegebene Objekt ist leer, und das Textfeld ist abgeblendet und kann nicht bearbeitet werden.",
  "oifde.service.invokemethod.arraySize.resultObj.desc":"Das zurückgegebene Objekt hat die Länge eines Arrays. Der Wert ist eine Ganzzahl.",
  "oifde.service.invokemethod.arraySubList.resultObj.desc":"Das zurückgegebene Objekt ist ein Subarray.",
  "oifde.service.invokemethod.arrayJoin.resultObj.desc":"Das zurückgegebene Objekt ist die konvertierte Zeichenfolge.",
  "oifde.service.invokemethod.mapPut.resultObj.desc":"Die Logik des zurückgegebenen Objekts ist wie folgt: Wenn der Schlüssel nicht existiert, wird Null zurückgegeben. Wenn der Schlüssel vorhanden ist, werden der ersetzte Wert und der Objekttyp zurückgegeben.,",
  "oifde.service.invokemethod.mapGet.resultObj.desc":"Gibt den Wert zurück, für den das Objekt dem angegebenen Schlüssel zugeordnet wird.",
  "oifde.service.invokemethod.mapRemove.resultObj.desc":"Objekt zurückgeben: Diese Methode gibt den Wert zurück, der diesem Schlüssel für diese Zuordnung zuvor zugeordnet war. Gibt Null zurück, wenn die Zuordnung keine Zuordnung für den Schlüssel enthält.",
  "oifde.service.invokemethod.mapClear.resultObj.desc":"Das zurückgegebene Objekt ist leer, und das Textfeld ist abgeblendet und kann nicht bearbeitet werden.",
  "oifde.service.invokemethod.mapSize.resultObj.desc":"Das zurückgegebene Objekt ist die Anzahl der Tasten in der Karte. Der Wert ist eine Ganzzahl.",
  "oifde.service.invoke.cachegroup.desc":"Legen Sie die entsprechenden Variablenattribute fest.",
  "oifde.service.invoke.cachegroup.displayName":"Variable Attribute",
  "oifde.service.invoke.cachegroup.keyTitle":"Cache-Variable",
  "oifde.service.invoke.cachegroup.valueTitle":"Antwortattribut",
  "oifde.service.invoke.interfaceType":"Schnittstellentyp",
  "oifde.service.invoke.interfaceType.desc":"Schnittstellentyp.",
  "oifde.service.invoke.interfaceId":"Aufgerufene Schnittstelle",
  "oifde.service.invoke.interfaceId.desc":"Schnittstelle aufgerufen.",
  "oifde.service.invoke.paramgroup.desc":"Liste der Schnittstellen-Eingabeparameter.",
  "oifde.service.invoke.paramgroup.name":"Schnittstelleneingabeparameter",
  "oifde.service.invoke.startTime":"Startzeit",
  "oifde.service.invoke.startTime.desc":"Das Format ist HH:MM:SS. Beispiel: 08:00:00.",
  "oifde.service.invoke.endTime":"Endzeit",
  "oifde.service.invoke.endTime.desc":"Das Format ist HH:MM:SS. Beispiel: 23:00:00.",
  "oifde.service.invoke.sendCircle":"Sendeintervall",
  "oifde.service.invoke.sendCircle.desc":"Intervall für das Aufrufen einer Schnittstelle in Tagen. Ganzzahl, nicht mehr als 365. Wenn dieser Parameter auf 1 gesetzt ist, wird das Diagrammelement nicht periodisch aufgerufen. Das Diagrammelement wird jederzeit ausgelöst, wenn das Diagrammelement aufgerufen wird.",
  "oifde.service.invoke.CallTransferCallingNum":"Anrufnummer",
  "oifde.service.invoke.CallTransferCallingNum.desc":"Dieser Wert wird als Anrufnummer verwendet, wenn der Anrufweiterleitungstyp auf 5 eingestellt ist.“ (Hinweis darauf, dass der Anruf an einen Dritten weitergeleitet wird) Die Anrufnummer muss ein vom System zugewiesener Zugangscode oder ein benutzerdefinierter Parameter sein. Beispiel: 660001 oder FLOW.accessCode",
  "oifde.service.invoke.CallTransferCallingNum.lastAgentDesc":"Die Anrufnummer ist die Anrufnummer des Kunden. Beispiel: SYS.Anrufnummer.",
  "oifde.service.invoke.CallTransferType":"Übertragungstyp",
  "oifde.service.invoke.CallTransferType.desc":"Dialog-Übertragungstyp. Die Optionen lauten wie folgt: 1: Weiterleitung an den manuellen Dienst (an eine Skill-Warteschlange per Routing-Code); 2: Weiterleitung an ein IVR; 3: Weiterleitung an den manuellen Dienst (an eine Skill-Warteschlange nach Skill-Name); 4: Weiterleitung an den manuellen Dienst (an einen Agenten nach ID); 5: Weiterleitung an eine Drittanbieter-Nummer; 6: digitaler Online-Assistent; 7: Weiterleitung an den manuellen Dienst (an den letzten Agenten, derzeit werden nur Multimedia unterstützt); 8: Weiterleitung an den manuellen Dienst (der Skill-Warteschlange, zu der ein Agent gehört, wird derzeit nur Multimedia unterstützt) ; 31: Weiterleiten zu einer Multimedianachricht (an den letzten Agenten); 32: Weiterleiten zu einer Multimedianachricht (an eine Skill-Warteschlange); 33: Weiterleiten zu einer Multimedianachricht (an einen Agenten).",
  "oifde.service.invoke.CallTransferRouteValue":"Routenwert",
  "oifde.service.invoke.CallTransferRouteValue.desc":"Anrufweiterleitungsroutenwert. Stellen Sie diesen Parameter basierend auf dem Anrufweiterleitungstyp ein.,",
  "oifde.service.invoke.CallTransferRouteTypePolicy":"Routingrichtlinie",
  "oifde.service.invoke.CallTransferRoutePolicyType.percentage":"Prozentsatz",
  "oifde.service.invoke.CallTransferRoutePolicyType.numberSegment":"Nummernsegment",
  "oifde.service.invoke.CallTransferRouteDefaultSkillName":"Standard-Skill-Warteschlange",
  "oifde.service.invoke.CallTransferMatchingType":"Übereinstimmungsregel",
  "oifde.service.invoke.CallTransferMatchingType.prefix":"Präfix",
  "oifde.service.invoke.CallTransferMatchingType.suffix":"Suffix",
  "oifde.service.invoke.CallTransferRouteTypePolicy.percentTotalError":"Der Gesamtprozentsatz ist nicht gleich 100",
  "oifde.service.invoke.CallTransferRouteTypePolicy.percentValueError":"Geben Sie eine positive Ganzzahl zwischen 1 und 100 ein.",
  "oifde.service.invoke.CallTransferTransData":"Zugehörige Daten",
  "oifde.service.invoke.CallTransferTransData.desc":"Zugehörige Daten, die während der Anrufweiterleitung an einen Agenten übertragen werden.",
  "oifde.service.invoke.CallTransferTransData.desc2":"Alternativ können Sie mehrere Flow- oder globale Variablen eingeben, z. B. {data:${FLOW.XXX},type:${GLOBAL.XXX}, zeit:2000}.",
  "oifde.service.invoke.textVariable":"Textvariable",
  "oifde.service.invoke.textVariable.desc":"Textvariable an den Chatbot gesendet. Dieser Variablen muss ein Wert zugewiesen werden, bevor der Knoten ausgeführt werden kann.,",
  "oifde.service.invoke.KeyboardInputRegExp":"Regelausdruck",
  "oifde.service.invoke.KeyboardInputRegExp.desc":"Der Ausdruck prüft, ob der vom Benutzer eingegebene Text der angegebenen Regel entspricht. Der Wert kann ein regulärer Ausdruck sein. Beispiel:^(1([0-9]{10})|([*]|[#]))$.",
  "oifde.service.invoke.voiceType":"Reaktionstyp",
  "oifde.service.invoke.voiceSource":"Antwortquelle",
  "oifde.service.invoke.staticVoice":"Statische Stimme",
  "oifde.service.invoke.TTSVoice":"TTS",
  "oifde.service.invoke.multiVoice":"Variable Stimme",
  "oifde.service.invoke.multiVoiceLanguage":"Sprache",
  "oifde.service.invoke.multiVoiceType":"Typ",
  "oifde.service.invoke.voiceTemplateCode":"Antwortvorlage",
  "oifde.service.invoke.modelType":"Reaktionsmodus",
  "oifde.service.invoke.modelType.desc":"Ob der Dialog unterbrochen werden kann.",
  "oifde.service.invoke.timeOut":"Zeitüberschreitungsintervall",
  "oifde.service.invoke.timeOut.desc":"Timeout-Intervall (Einheit: Sekunden). Der Wert ist eine Ganzzahl. Beispiel: 15 Zoll,",
  "oifde.service.invoke.voiceVariable":"Reaktionsvariable",
  "oifde.service.invoke.voiceVariable.mix.desc":"Stimme oder Textvariable, die in der Antwort enthalten ist. Dieser Variablen muss ein Wert zugewiesen werden, bevor der Knoten ausgeführt werden kann. (Bei einer Stimme und einem Texttyp befindet sich die Antwortvariable im URL-Format der Aufnahmedatei im Format der Aufnahmedatei-URL~$TTS text$~Aufnahmedatei-URL.)",
  "oifde.service.invoke.voiceVariable.tts.desc":"Stimme oder Textvariable, die in der Antwort enthalten ist. Dieser Variablen muss ein Wert zugewiesen werden, bevor der Knoten ausgeführt werden kann. (Bei einem Antworttexttyp hat die Antwortvariable das TTS-Textformat. Es wird nur ein TTS-Text unterstützt.)",
  "oifde.service.invoke.voiceVariable.voice.desc":"Stimme oder Textvariable, die in der Antwort enthalten ist. Dieser Variablen muss ein Wert zugewiesen werden, bevor der Knoten ausgeführt werden kann. (Bei einer statischen Stimme hat die Antwortvariable das URL-Format der Aufnahmedatei. Es wird nur eine URL unterstützt.)",
  "oifde.service.invoke.voiceVariable.default.desc":"Stimme oder Textvariable, die in der Antwort enthalten ist. Dieser Variablen muss ein Wert zugewiesen werden, bevor der Knoten ausgeführt werden kann.",
  "oifde.service.invoke.voiceVariable.multiVoice.telnum.desc":"Stimme oder Textvariable, die in der Antwort enthalten ist. Dieser Variablen muss ein Wert zugewiesen werden, bevor der Knoten ausgeführt werden kann. (Bei einem Telefonnummerntyp hat die Antwortvariable das tatsächliche Inhaltsformat der Antwortvariablen ist numerischer Text. Wenn die Eingabe falsch ist, ist die Sprachwiedergabe anormal.)",
  "oifde.service.invoke.voiceVariable.multiVoice.number.desc":"Stimme oder Textvariable, die in der Antwort enthalten ist. Dieser Variablen muss ein Wert zugewiesen werden, bevor der Knoten ausgeführt werden kann. (Bei einem Zahlentyp hat die Antwortvariable das tatsächliche Inhaltsformat der Antwortvariablen ist numerischer Text. Wenn die Eingabe falsch ist, ist die Sprachwiedergabe anormal.)",
  "oifde.service.invoke.voiceVariable.multiVoice.time.desc":"Stimme oder Textvariable, die in der Antwort enthalten ist. Dieser Variablen muss ein Wert zugewiesen werden, bevor der Knoten ausgeführt werden kann. (Für einen Zeittyp lautet die Antwortvariable:hh:mm:ss. Wenn die Eingabe falsch ist, ist die Sprachwiedergabe anormal.)",
  "oifde.service.invoke.voiceVariable.multiVoice.date.desc":"Stimme oder Textvariable, die in der Antwort enthalten ist. Dieser Variablen muss ein Wert zugewiesen werden, bevor der Knoten ausgeführt werden kann. (Für einen Datumstyp lautet die Antwortvariable:JJJJMMTT. Wenn die Eingabe falsch ist, ist die Sprachwiedergabe anormal.)",
  "oifde.service.invoke.voiceVariable.multiVoice.price.desc":"Stimme oder Textvariable, die in der Antwort enthalten ist. Dieser Variablen muss ein Wert zugewiesen werden, bevor der Knoten ausgeführt werden kann. (Bei einem Preistyp ist die Antwortvariable im tatsächlichen Inhaltsformat der Antwortvariablen numerischer Text.Einheit: Minute. Wenn die Eingabe falsch ist, ist die Sprachwiedergabe anormal.)",
  "oifde.service.invoke.offsetType":"Zeitraum versetzen",
  "oifde.service.invoke.offsetType.desc":"Nach Tag oder Stunde versetzt",
  "oifde.service.invoke.offsetType.month":"Nach Monat",
  "oifde.service.invoke.offsetType.hour":"Jede Stunde",
  "oifde.service.invoke.sourceVariable":"Quellzeichenfolge",
  "oifde.service.invoke.sourceVariable.desc":"Quellzeichenfolgenvariable. Das Format ist JJJJMM, wenn der Versatz nach Monat angegeben wird, und JJJJMMttHHmmss, wenn der Versatz nach Stunde angegeben wird. Beispiel: 202103 oder 20210308122020. Dieser Variablen muss ein Wert zugewiesen werden, bevor der Knoten ausgeführt werden kann.,",
  "oifde.service.invoke.destVariable":"Zielzeichenfolge",
  "oifde.service.invoke.destVariable.desc":"Die Zielzeichenfolgenvariable wird zum Speichern des Ergebnisses verwendet.",
  "oifde.service.invoke.offsetMonths":"Anzahl der Versätze",
  "oifde.service.invoke.offsetMonths.desc":"Anzahl der Versätze. Der Wert ist eine positive oder negative ganze Zahl. Stellen Sie diesen Parameter basierend auf dem Offset-Zeitraum (Offset nach Monat oder Stunde) ein. Wenn beispielsweise Versatz nach Monat ausgewählt ist, gibt der Wert -6 an, dass die Quellzeichenfolge auf die vor sechs Monaten verwendete Zeichenfolge zurückgesetzt wird.",
  "oifde.service.invoke.keyBoardType":"Tastatur ein",
  "oifde.service.invoke.keyBoardType.desc":"Tastatureingabetyp. Der Wert kann keyBoard_phone oder checkoldpassword sein oder leer bleiben.",
  "oifde.service.invoke.inputVariable":"Variable",
  "oifde.service.invoke.inputVariable.desc":"Variable zum Speichern von Benutzertastatureingaben.",
  "oifde.service.invoke.inputVariableTitle":"Variablenzuweisung",
  "oifde.service.invoke.inputVariableValue":"Variabler Wert",
  "oifde.service.invoke.conditionTitle":"Bedingungszweig",
  "oifde.service.invoke.branchGroup.timeout":"Zeitüberschreitung",
  "oifde.service.invoke.branchGroup.nomatch":"Keine Übereinstimmung",
  "oifde.service.invoke.branchGroup.error":"Fehler",
  "oifde.service.invoke.branchGroup.silentAgent":"Stummer Agent",
  "oifde.service.invoke.targetFlowCode":"Zielflusscode",
  "oifde.service.invoke.targetNodeId":"Zielknoten-ID",
  "oifde.service.invoke.voiceType.staticVoice":"Statische Stimme",
  "oifde.service.invoke.voiceType.TTSVoice":"TTS",
  "oifde.service.invoke.voiceType.video":"Video",
  "oifde.service.invoke.smsTemplateCode":"SMS-Vorlage",
  "oifde.service.invoke.smsContent":"SMS-Inhalt",
  "oifde.service.invoke.smsContent.desc":"SMS-Inhalt.",
  "oifde.service.invoke.modelType.voiceBreak":"Unterbrechungserkennung",
  "oifde.service.invoke.modelType.noVoiceBreak":"Nur Wiedergabe",
  "oifde.service.invoke.modelType.keyboardBreak":"Unterbrechung durch Tastendruck",
  "oifde.service.invoke.modelType.voiceAndKeyboard":"Erkennung und Tastendruck",
  "oifde.service.invoke.modelType.voiceAndNobreak":"Erkennung nach der Wiedergabe",
  "oifde.service.invoke.modelType.voiceAndInputAndNobreak":"Erkennung und Tastendruck nach der Wiedergabe",
  "oifde.service.invoke.KeyboardInputRegExp.MobileAll":"Mobiltelefonnummernformat überprüfen (mit # und *)",
  "oifde.service.invoke.KeyboardInputRegExp.Mobile":"Mobiltelefonnummernformat überprüfen (ohne # bzw. *)",
  "oifde.service.invoke.KeyboardInputRegExp.PasswordAll":"Passwortüberprüfungsformat (mit # und *)",
  "oifde.service.invoke.KeyboardInputRegExp.PasswordStar":"Passwortüberprüfungsformat (mit *)",
  "oifde.service.invoke.KeyboardInputRegExp.NumberAll":"Mobiltelefonnummer bestätigen (mit # und *)",
  "oifde.service.invoke.KeyboardInputRegExp.NumberStar":"Mobiltelefonnummer überprüfen (mit *)",
  "oifde.service.invoke.KeyboardInputRegExp.Month":"Eingabe für Validierungsmonat",
  "oifde.service.invoke.context":"Kontext",
  "oifde.service.invoke.context.desc":"An den TUC übertragene Kontextinformationen.",
  "oifde.service.invoke.vendorParam":"Erweiterter Parameter",
  "oifde.service.invoke.vendorParam.desc":"Der erweiterte Spracherkennungsparameter wurde an das IVR zurückgegeben.",
  "oifde.service.invoke.input":"Inhalt der semantischen Erkennung",
  "oifde.service.invoke.input.desc":"Die semantischen Erkennungsinhalte wurden an den TUC übertragen. Der Inhalt kann eine Variable sein. Wenn dieses Feld leer ist, werden vom Benutzer eingegebene Inhalte an den TUC übertragen.",
  "oifde.service.invoke.event":"Ereignis",
  "oifde.service.invoke.event.desc":"Veranstaltung an den TUC gesendet.",
  "oifde.service.invoke.event.cleanup":"Sitzungscache löschen",
  "oifde.service.invoke.event.cleanCurrent":"Aktuellen Kontextcache löschen",
  "oifde.service.invoke.procedureCode":"Gespeicherte Prozedur",
  "oifde.service.invoke.complexcellgroupin.name":"Eingabeparameter",
  "oifde.service.invoke.complexcellgroupin.desc":"Eingabeparameter.",
  "oifde.service.invoke.complexcellgroupout.name":"Ausgabeparameter",
  "oifde.service.invoke.complexcellgroupout.desc":"Ausgabeparameter.",
  "oifde.service.invoke.targetIntentCount":"Anzahl der Absichten",
  "oifde.service.invoke.targetIntentCount.desc":"Maximale Anzahl von Absichten, die vom TUC zurückgegeben werden.",
  "oifde.service.invoke.confidence2Top":"Konfidenzintervall",
  "oifde.service.invoke.confidence2Top.desc":"Intervall zwischen dem Vertrauen mehrerer Absichten und dem der ersten Absicht, wenn mehrere Absichten zurückgegeben werden müssen.",
  "oifde.service.invoke.index.desc":"Zähler.",
  "oifde.service.invoke.index":"Zähler",
  "oifde.service.invoke.oblist.desc":"Liste der Schleifen.",
  "oifde.service.invoke.oblist":"Schleifen",
  "oifde.service.invoke.object.desc":"Schleifenobjekt.",
  "oifde.service.invoke.object":"Schleifenobjekt",
  "oifde.service.invoke.voiceType.multi-media":"Multimedia",
  "oifde.service.invoke.modelType.inputAndNobreak":"Keine Unterbrechung nach Ziffernerfassung",
  "oifde.service.invoke.maxCollectLength":"Anzahl der erfassten Ziffern",
  "oifde.service.invoke.maxCollectLength.desc":"Legen Sie die maximale Anzahl der gesammelten Ziffern fest.",
  "oifde.service.invoke.collectTimeout":"Zeitüberschreitung bei der Ziffernerfassung",
  "oifde.service.invoke.collectTimeout.desc":"Stellt das Timeout-Intervall für die Ziffernerfassung ein. Die Variablen FLOW und GLOBAL werden unterstützt. Der Wert kann auch eine ganze Zahl zwischen 1 und 120 sein. Entspricht der Wert nicht den Anforderungen, wird der Standardwert 5 verwendet.",
  "oifde.service.invoke.cancelCollectFlag":"Taste abbrechen",
  "oifde.service.invoke.cancelCollectFlag.yes":"Verwenden Sie die Sterntaste (*)",
  "oifde.service.invoke.cancelCollectFlag.no":"Verwenden Sie nicht die Sterntaste (*)",
  "oifde.service.invoke.confirmCollectFlag":"Schlüssel bestätigen",
  "oifde.service.invoke.confirmCollectFlag.yes":"Verwenden Sie die Rautetaste (#)",
  "oifde.service.invoke.confirmCollectFlag.no":"Verwenden Sie nicht die Rautetaste (#)",
  "oifde.service.invoke.ttsAdvancedSetting":"Erweiterte TTS-Einstellungen",
  "oifde.service.invoke.ttsAdvancedSetting.desc":"Ob diese Einstellungen aktiviert werden sollen, um die TTS-basierte Sprachwiedergabe festzulegen.",
  "oifde.service.invoke.ttsAdvancedSetting.open":"Aktivieren",
  "oifde.service.invoke.ttsAdvancedSetting.close":"Deaktivieren",
  "oifde.service.invoke.ttsSoundEffect":"Stimme",
  "oifde.service.invoke.ttsSoundEffect.desc":"Wählen Sie aus, ob eine männliche oder weibliche Stimme für die TTS-basierte Sprachwiedergabe verwendet werden soll.",
  "oifde.service.invoke.ttsSoundEffect.male":"Männlich",
  "oifde.service.invoke.ttsSoundEffect.female":"Weiblich",
  "oifde.service.invoke.ttsSoundSpeed":"Sprachgeschwindigkeit",
  "oifde.service.invoke.ttsSoundSpeed.desc":"Wählen Sie die Geschwindigkeit aus, mit der die TTS-basierte Sprachwiedergabe ausgeführt wird.",
  "oifde.service.invoke.ttsSoundSpeed.low":"Langsam",
  "oifde.service.invoke.ttsSoundSpeed.normal":"Mittel",
  "oifde.service.invoke.ttsSoundSpeed.high":"Schnell",
  "oifde.service.invoke.ttsMRCPVendorSetting.open":"Aktivieren",
  "oifde.service.invoke.ttsMRCPVendorSetting.close":"Deaktivieren",
  "oifde.service.invoke.ttsMRCPVendorSetting":"Standardwerte für erweiterte TTS-Parameter.",
  "oifde.service.invoke.ttsMRCPVendorSetting.desc":"Nach der Aktivierung der Standardwerte für die erweiterten TTS-Parameter überschreiben die Definitionen den ausgewählten Soundeffekt und die Klanggeschwindigkeit.",
  "oifde.service.invoke.ttsMRCPVendor":"Erweiterter TTS-Parameter",
  "oifde.service.invoke.ttsMRCPVendor.desc":"Erweiterte TTS-Serverparameter. Legen Sie die Parameter basierend auf den Anforderungen des TTS-Server-Anbieters fest. Ein Beispiel ist speed=GLOBAL.ttsSpeed;pitch=GLOBAL.ttsPitch;volume=GLOBAL.ttsVolume;voice_name=GLOBAL.voiceName;. Die Variablen GLOBAL und FLOW werden unterstützt. Wenn ein Variablenwert nicht innerhalb des Standardbereichs liegt, wird der Standardwert verwendet. Der Wertebereich für die Geschwindigkeit ist [-500, 500], und der Standardwert ist 0. Der Wertebereich für die Lautstärke ist [0, 100], und der Standardwert ist 50. Der Wertebereich für die Tonhöhe ist [-500, 500], und der Standardwert ist 0. Der Standardwert von stimme_name ist cn_kefuman_common.",
  "oifde.service.invoke.asrAdvancedSetting":"Erweiterte ASR-Einstellungen",
  "oifde.service.invoke.asrAdvancedSetting.desc":"Ob diese Einstellungen aktiviert werden sollen, um die ASR-Erkennung festzulegen.",
  "oifde.service.invoke.asrAdvancedSetting.open":"Aktivieren",
  "oifde.service.invoke.asrAdvancedSetting.close":"Deaktivieren",
  "oifde.service.invoke.asrRecognizeType":"Erkennungstyp",
  "oifde.service.invoke.asrRecognizeType.desc":"ASR-Identifizierungstyp. Derzeit wird nur die gemeinsame Identifizierung unterstützt.",
  "oifde.service.invoke.asrRecognizeType.normal":"Allgemein",
  "oifde.service.invoke.asrRecognizeType.intelligent":"Intelligent",
  "oifde.service.invoke.asrNoInputTimeout":"Timeout-Intervall für Abonnentenstille",
  "oifde.service.invoke.asrNoInputTimeout.desc":"Timeout-Intervall, wenn ein Teilnehmer während der ASR-Erkennung nicht spricht, in Sekunden. Der Wert liegt zwischen 0 und 3200. Der Standardwert ist 100.",
  "oifde.service.invoke.asrRecognitionTimeout":"Erkennungs-Timeout-Intervall",
  "oifde.service.invoke.asrRecognitionTimeout.desc":"Intervall, in dem die ASR-Erkennung das Zeitlimit überschreitet, in Sekunden. Der Wert liegt zwischen 0 und 600. Der Standardwert ist 200.",
  "oifde.service.invoke.asrSpeechCompleteTimeout":"Abonnenten-Pause-Timeout-Intervall",
  "oifde.service.invoke.asrSpeechCompleteTimeout.desc":"Timeout-Intervall, wenn ein Abonnent während der ASR-Erkennung pausiert, in Sekunden. Der Wert liegt zwischen 300 und 2000. Der Standardwert ist 500.,",
  "oifde.service.invoke.asrMRCPVendor":"Erweiterter ASR-Parameter",
  "oifde.service.invoke.asrMRCPVendor.desc":"Erweiterter Parameter des ASR-Servers. Stellen Sie diesen Parameter entsprechend den Anforderungen des Anbieters ein.",
  "oifde.service.invoke.voiceType.card":"Karte",
  "oifde.service.invoke.operationType":"Vorgangsart",
  "oifde.service.invoke.operationType.open":"Aufzeichnung starten",
  "oifde.service.invoke.operationType.close":"Aufzeichnung stoppen",
  "oifde.service.invoke.processRecord":"Vollständige Aufnahme",
  "oifde.service.invoke.processRecord.open":"Ja",
  "oifde.service.invoke.processRecord.close":"Nein",
  "oifde.service.invoke.recordType":"Typ",
  "oifde.service.invoke.recordType.audio":"Nur Audio",
  "oifde.service.invoke.recordType.video":"Audio und Video",
  "oifde.yugaoRecordService.displayName":"Aufzeichnung von VoiceCyber",
  "oifde.yugaoRecordService.description":"Aufzeichnung von VoiceCyber (voiceCyberRecordService)",
  "oifde.yugaoRecordService.keywords":"Aufzeichnung von VoiceCyber",
  "oifde.jsonDataProcessService.displayName":"JSON-Daten werden aufgerufen",
  "oifde.jsonDataProcessService.description":"JSON-Daten werden aufgerufen (jsonDataProcessService).",
  "oifde.jsonDataProcessService.keywords":"JSON-Daten werden aufgerufen",
  "oifde.timeSelectService.displayName":"Zeitauswahl",
  "oifde.timeSelectService.description":"Zeitauswahl (timeSelectService).",
  "oifde.timeSelectService.keywords":"Zeitauswahl",
  "oifde.queryMessageService.displayName":"Informationsabfrage",
  "oifde.queryMessageService.description":"Informationsabfrage(queryMessageService).",
  "oifde.queryMessageService.keywords":"Informationsabfrage",
  "oifde.service.invoke.recordMode":"Modus",
  "oifde.service.invoke.recordMode.onlyuser":"Nur Abonnenten aufzeichnen",
  "oifde.service.invoke.recordMode.onlyivr":"Nur IVR aufzeichnen",
  "oifde.service.invoke.recordMode.all":"Abonnenten und IVR aufzeichnen",
  "oifde.service.invoke.timeSelect.NonWorkTime":"Unbestimmter Zeitzweig",
  "oifde.service.invoke.timeSelect.weekParameter":"Wochenauswahl",
  "oifde.service.invoke.timeSelect.serviceTimeParameter":"Servicedauer",
  "oifde.service.invoke.timeSelect.specificWorkDateParameter":"Angegebenes Servicedatum",
  "oifde.service.invoke.timeSelect.specificNoWorkDateParameter":"Angegebenes Datum der Nichtdienstleistung",
  "oifde.service.invoke.timeSelect.serviceTimeParameter.desc":"Servicedauer. Konfigurieren Sie 1 bis 10 Datensätze.",
  "oifde.service.invoke.timeSelect.specificWorkDateParameter.desc":"Es werden maximal 50 Datensätze unterstützt.",
  "oifde.service.invoke.timeSelect.specificNoWorkDateParameter.desc":"Angegebenes arbeitsfreies Datum. Es werden maximal 50 Datensätze unterstützt.,",
  "oifde.service.invoke.timeSelect.specificWorkDateParameter.title":"Wenn ein konfiguriertes angegebenes Arbeitsdatum in der Wochenauswahl nicht verfügbar ist, wird das Servicedatum als Arbeitstag betrachtet.",
  "oifde.service.invoke.timeSelect.specificNoWorkDateParameter.title":"Wenn ein bestimmtes arbeitsfreies Datum konfiguriert ist, wird das arbeitsfreie Datum als arbeitsfreier Tag mit der höchsten Priorität betrachtet.",
  "oifde.service.invoke.workdays.Monday":"Montag",
  "oifde.service.invoke.workdays.Tuesday":"Dienstag",
  "oifde.service.invoke.workdays.Wednesday":"Mittwoch",
  "oifde.service.invoke.workdays.Thursday":"Donnerstag",
  "oifde.service.invoke.workdays.Friday":"Freitag",
  "oifde.service.invoke.workdays.Saturday":"Samstag",
  "oifde.service.invoke.workdays.Sunday":"Sonntag",
  "oifde.service.interfaceType.selfDefinedInterface":"Selbstdefinierte Schnittstelle",
  "oifde.service.interfaceType.innerInterface":"innere Schnittstelle",
  "oifde.qarecord.interface.name":"Q&A aufzeichnen",
  "oifde.qarecord.interface.desc":"Zeichnet die Fragebogen-ID, den Fragentitel, die Absicht der Benutzerantwort und die ursprüngliche Benutzerantwort auf.",
  "oifde.apiToken.interface.name":"Erwerber des Tokens APIFabric",
  "oifde.apiToken.interface.desc":"Interfaz utilizada para obtener el token API-Fabric",
  "oifde.apiToken.applyType.desc":"Tokenpräfix",
  "oifde.apiToken.accessToken.desc":"Inhalt des Tokens",
  "oifde.apiToken.appKey.desc":"App-Schlüssel",
  "oifde.apiToken.appSecret.desc":"App-Geheimnis",
  "oifde.qarecord.inputparam.seriesId":"Fragen- und Antwort-ID",
  "oifde.qarecord.inputparam.questionTitle":"Fragenbezeichnung",
  "oifde.qarecord.inputparam.questionType":"Fragentyp",
  "oifde.qarecord.inputparam.answerIntent":"Absicht der Antwort",
  "oifde.qarecord.inputparam.originalAnswer":"Originalantwort",
  "oifde.service.invoke.queryMsg.MessageQueryType":"Abfragetyp",
  "oifde.service.invoke.queryMsg.MessageQueryType.desc":"Anwendungsszenario für Warteschlangeninformationen: Wenn ein Anruf an eine Fertigkeitswarteschleife weitergeleitet wird, wird der IVR-Prozess aktiviert, um Warteschlangeninformationen abzufragen.",
  "oifde.service.invoke.queryMsg.skillMsg":"Warteschlangeninformationen",
  "oifde.service.invoke.queryMsg.skillInfo":"Informationen zu Fähigkeiten",
  "oifde.service.invoke.queryMsg.specifiedSkill":"Warteschlangenname",
  "oifde.service.invoke.queryMsg.specialListQuery":"Besondere Listeninformationen",
  "oifde.service.invoke.queryMsg.result":"Abfrageergebnis",
  "oifde.service.invoke.queryMsg.MessageQueryQueueIndex":"Warteschlangenposition",
  "oifde.service.invoke.queryMsg.MessageQueryQueueNumber":"Anzahl der Warteschlangen",
  "oifde.service.invoke.queryMsg.MessageQueryOnlineNum":"Anzahl der Online-Agenten",
  "oifde.service.invoke.queryMsg.MessageIdleAgentNum":"Anzahl der Agenten im Leerlauf",
  "oifde.service.invoke.queryMsg.MessageQueryWaitingTime":"Geschätzte Wartezeit",
  "oifde.service.invoke.queryMsg.MessageQueryWaitingTime.desc":"Die erwartete Wartezeit beim Einreihen in die Warteschlange wird basierend auf dem Warteschlangenmodell berechnet. Das Ergebnis ist anders. Bitte verwenden Sie es ordnungsgemäß.",
  "oifde.service.invoke.queryMsg.params":"Anforderungsparameter",
  "oifde.service.invoke.queryMsg.skillQueue":"Skill-Warteschlange",
  "oifde.service.invoke.queryMsg.agentId":"Agenten-ID",
  "oifde.service.invoke.queryMsg.specialList":"Kennung in der Sonderliste",
  "oifde.service.invoke.breakMinTime":"Mindestdauer der Sprachwiedergabe",
  "oifde.service.invoke.modelType.customization":"Benutzerdefinierter Variablenwert",
  "oifde.service.invoke.voiceTtsMixed":"Stimme und Text",
  "oifde.service.invoke.ttsSoundEffectCust":"Benutzerdefinierter Soundeffekt",
  "oifde.service.invoke.ttsSoundSpeedCust":"Benutzerdefinierte Geschwindigkeit",
  "oifde.service.invoke.modelType.customization.desc":"Interrupt gibt an, ob es unterbrochen werden kann. Wenn sie unterbrochen werden kann, füllen Sie das Eingabefeld für die minimale Wiedergabezeit aus. Variablen werden mit true oder false gefüllt. true gibt an, dass sie erkannt und unterbrochen werden können, und die Mindestwiedergabezeit kann überschritten werden. False kann erkannt, aber nicht beschädigt werden, und das Ausfüllen von Fehlern ist der Standardwert von false.",
  "oifde.service.invoke.CallTransferType.transferToSkillQueueByRoutingCode":"Skill-Warteschlange (Route-Erweiterungscode)",
  "oifde.service.invoke.CallTransferType.transferToIVR":"IVR",
  "oifde.service.invoke.CallTransferType.transferToSkillQueueBySkillName":"Skill-Warteschlange (Name)",
  "oifde.service.invoke.CallTransferType.transferToAgentByID":"Agent",
  "oifde.service.invoke.CallTransferType.transferToThirdPartyNumber":"Drittanbieternummer",
  "oifde.service.invoke.CallTransferType.onlineDigitalAssistant":"Digitaler Online-Assistent",
  "oifde.service.invoke.CallTransferType.transferToLastAgent":"Multimedia-bekannter Kunde",
  "oifde.service.invoke.CallTransferType.transferToSkillQueueBelongAgent":"Skill-Warteschlange (MAgent)",
  "oifde.service.invoke.CallTransferType.transferToSkillQueueByRouteConfig":"Weiterleiten an Kompetenzwarteschlange (angepasste Route)",
  "oifde.service.invoke.CallTransferType.transferToMultimediaMessageToLastAgent":"Botschaft an vertraute Kunden",
  "oifde.service.invoke.CallTransferType.transferToMultimediaMessageToSkillQueue":"Skill-Warteschlangennachricht (Name oder Zugriffscode)",
  "oifde.service.invoke.CallTransferType.transferToMultimediaMessageToAgent":"Nachricht an einen Agenten",
  "oifde.service.invoke.CallTransferTypeGroup.multimediaMessage":"Multimedianachricht",
  "oifde.service.invoke.CallTransferRecordFlag":"Aufzeichnung aktivieren",
  "oifde.service.invoke.CallTransferRecordFlag.desc":"“,: 本身就为空不展示",
  "oifde.service.invoke.recordPath":"Datensatzpfad",
  "oifde.service.invoke.recordPath.desc":"Geben Sie die Variable zum Speichern des Namens der Aufzeichnungsdatei ein.",
  "oifde.service.invoke.recordServiceNodeID":"Dienstknoten-ID aufzeichnen",
  "oifde.service.invoke.recordServiceNodeID.desc":"Geben Sie die Variable ein, die die ID des Aufzeichnungsdienstknotens speichert (wird im Callcenter-Pool-Modus verwendet).",
  "oifde.service.invoke.languageType.language0":"Putonghua",
  "oifde.service.invoke.languageType.language1":"Englisch",
  "oifde.service.invoke.languageType.language2":"Chinesisch (Kantonesisch)",
  "oifde.service.invoke.languageType.language3":"Tschechisch",
  "oifde.service.invoke.languageType.language4":"Dänisch",
  "oifde.service.invoke.languageType.language5":"Niederländisch",
  "oifde.service.invoke.languageType.language6":"Finnisch",
  "oifde.service.invoke.languageType.language7":"Französisch",
  "oifde.service.invoke.languageType.language8":"Deutsch",
  "oifde.service.invoke.languageType.language9":"Griechisch",
  "oifde.service.invoke.languageType.language10":"Ungarisch",
  "oifde.service.invoke.languageType.language11":"Isländisch",
  "oifde.service.invoke.languageType.language12":"Italienisch",
  "oifde.service.invoke.languageType.language13":"Japanisch",
  "oifde.service.invoke.languageType.language14":"Koreanisch",
  "oifde.service.invoke.languageType.language15":"Norwegisch",
  "oifde.service.invoke.languageType.language16":"Polnisch",
  "oifde.service.invoke.languageType.language17":"Portugiesisch",
  "oifde.service.invoke.languageType.language18":"Russisch",
  "oifde.service.invoke.languageType.language19":"Slowakisch",
  "oifde.service.invoke.languageType.language20":"Spanisch",
  "oifde.service.invoke.languageType.language21":"Schwedisch",
  "oifde.service.invoke.languageType.language22":"Türkisch",
  "oifde.service.invoke.languageType.language23":"Usbekistan",
  "oifde.service.invoke.languageType.language24":"Thailand",
  "oifde.service.invoke.languageType.language80":"Kiswahili",
  "oifde.service.invoke.languageType.language81":"Arabisch",
  "oifde.service.invoke.languageType.language82":"Hindi",
  "oifde.service.invoke.languageType.language83":"Filipino",
  "oifde.service.invoke.languageType.language84":"Kambodschanisch",
  "oifde.service.invoke.languageType.language85":"Bengalisch",
  "oifde.service.invoke.languageType.language86":"Palästinensisch",
  "oifde.service.invoke.languageType.language87":"Vietnamesisch",
  "oifde.service.invoke.languageType.language88":"Indonesisch",
  "oifde.service.invoke.languageType.language89":"Nepali",
  "oifde.service.invoke.languageType.language90":"Malagassisch",
  "oifde.service.invoke.languageType.language92":"Äthiopisch",
  "oifde.service.invoke.languageType.language93":"Malaysisch",
  "oifde.service.invoke.variableType.type1":"Telefonnummer",
  "oifde.service.invoke.variableType.type3":"Zahl",
  "oifde.service.invoke.variableType.type4":"Zeit(hh:mm:ss)",
  "oifde.service.invoke.variableType.type5":"Datum(JJJJMMTT)",
  "oifde.service.invoke.variableType.type6":"Preis",
  "oifde.ringService.displayName":"Klingeln",
  "oifde.assignValueService.displayName":"Zuweisen",
  "oifde.transferService.displayName":"Übertragung",
  "oifde.service.inpytOrDrag":"Variablen eingeben oder ziehen",
  "oifde.service.operateAsFunction":"funktionieren wie die vorgesehene Methode",
  "oifde.basicOdfsSubFlowService.displayName":"Unterfluss (intelligent)",
  "oifde.basicCcivrSubFlowService.displayName":"Unterfluss (Allgemein)",
  "oifde.yugaoRecordServiceNew.displayName":"Aufzeichnen und Sammeln",
  "oifde.conditionServiceNew.displayName":"Zweiggericht",
  "oifde.service.invoke.callTranseferService.transData.wait":"Warteschlangen",
  "oifde.service.invoke.callTranseferService.transData.data":"Daten übertragen",
  "oifde.service.invoke.callTranseferService.transData.isSpecifyAccessCode":"Angegebener Kanalzugangscode",
  "oifde.service.invoke.callTranseferService.transData.type":"Modus für vertraute Kunden",
  "oifde.service.invoke.callTranseferService.transData.time":"Zeitbereich (Minuten)",
  "oifde.service.invoke.callTranseferService.transData.timeTips":"Zum Beispiel bedeutet 100, dass der Anruf an den Agenten weitergeleitet wird, der kürzlich innerhalb von 100 Minuten kontaktiert wurde. Der empfohlene Wert beträgt 1 bis 10080.",
  "oifde.service.invoke.callTranseferService.transData.timeErrorTips":"Geben Sie eine positive Ganzzahl oder Variable ein.",
  "oifde.service.invoke.callTranseferService.transData.starttime":"Datumsbereich",
  "oifde.service.invoke.callTranseferService.transData.starttimeTips":"Mitarbeiter, die in einem bestimmten Zeitraum zuletzt kontaktiert wurden",
  "oifde.service.invoke.callTranseferService.transData.type.selectTime":"Angegebene Minuten",
  "oifde.service.invoke.callTranseferService.transData.type.selectStartTime":"Benanntes Datum",
  "oifde.service.invoke.callTranseferService.transData.paramValueTips":"Geben Sie einen Parameterwert ein ",
  "oifde.service.invoke.callTranseferService.transData.varTips":"Variablen können eingegeben werden. Globale Variablen beginnen mit GLOBAL, Flussvariablen, Systemvariablen mit SYS, TUC-Variablen mit TOC und IVR-Anforderungsvariablen mit IVRREQUEST. Verwenden Sie {'${}'} zum Umschließen von Variablen, z. B. {'${FLOW.variable}'}.",
  "oifde.service.invoke.callTranseferService.transData.type.currentDay":"Am selben Tag",
  "oifde.service.invoke.ruleValidationService.name":"Regelvalidierung V2",
  "oifde.service.invoke.ruleValidationService.variable":"Zu überprüfende Variable",
  "oifde.service.invoke.ruleValidationService.specialEndKey":"Ende des Sonderschlüssels",
  "oifde.service.invoke.ruleValidationService.specialEndKey.option1":"Mit #",
  "oifde.service.invoke.ruleValidationService.specialEndKey.option2":"Mit *",
  "oifde.service.invoke.ruleValidationService.specialEndKey.option3":"Mit # bzw. *",
  "oifde.service.invoke.ruleValidationService.specialEndKey.option4":"Ohne Sondertasten",
  "oifde.service.invoke.ruleValidationService.containsChar":"Enthält Zeichen",
  "oifde.service.invoke.ruleValidationService.containsChar.option1":"Entspricht Zahlen",
  "oifde.service.invoke.ruleValidationService.containsChar.option2":"Entspricht Großbuchstaben",
  "oifde.service.invoke.ruleValidationService.containsChar.option3":"Entspricht Kleinbuchstaben",
  "oifde.service.invoke.ruleValidationService.containsChar.option4":"Entspricht Buchstaben, Ziffern und Unterstrichen.",
  "oifde.service.invoke.ruleValidationService.containsChar.option5":"Leeres Zeichen",
  "oifde.service.invoke.ruleValidationService.validationRules":"Überprüfungsregeln",
  "oifde.service.invoke.ruleValidationService.validationRules.ruleParam":"Regelparameter",
  "oifde.service.invoke.ruleValidationService.validationRules.ruleParamValueTips":"Variablen können eingegeben werden. Globale Variablen beginnen mit GLOBAL, Flussvariablen, Systemvariablen mit SYS, TUC-Variablen mit TOC und IVR-Anforderungsvariablen mit IVRREQUEST. Beispiel: FLUSS.variable",
  "oifde.service.invoke.ruleValidationService.validationRules.minLength":"Mindestlänge",
  "oifde.service.invoke.ruleValidationService.validationRules.minLength.valueTips":"Bitte geben Sie die Mindestlänge ein.",
  "oifde.service.invoke.ruleValidationService.validationRules.maxLength":"Maximale Länge",
  "oifde.service.invoke.ruleValidationService.validationRules.maxLength.valueTips":"Bitte geben Sie die maximale Länge ein.",
  "oifde.service.invoke.ruleValidationService.validationRules.rangeLength":"Längenbereich",
  "oifde.service.invoke.ruleValidationService.validationRules.min":"Min.",
  "oifde.service.invoke.ruleValidationService.validationRules.min.valueTips":"Bitte geben Sie einen Mindestwert ein.",
  "oifde.service.invoke.ruleValidationService.validationRules.max":"Max.",
  "oifde.service.invoke.ruleValidationService.validationRules.max.valueTips":"Bitte geben Sie den maximalen Wert ein.",
  "oifde.service.invoke.ruleValidationService.validationRules.range":"Wertebereich",
  "oifde.service.invoke.ruleValidationService.validationRules.email":"E-Mail-Adresse",
  "oifde.service.invoke.ruleValidationService.validationRules.url":"URL",
  "oifde.service.invoke.ruleValidationService.validationRules.dateFormat":"Zeitformat",
  "oifde.service.invoke.ruleValidationService.validationRules.dateFormat.valueTips":"Bitte geben Sie ein Zeitformat ein, z. B. Datum (JJJJ-MM-TT) oder Datum/Uhrzeit (JJJJ-MM-TT HH:mm:ss).",
  "oifde.service.invoke.ruleValidationService.validationRules.number":"Dezimalzahl",
  "oifde.service.invoke.ruleValidationService.validationRules.digits":"Positive Ganzzahl",
  "oifde.service.invoke.ruleValidationService.validationRules.ip":"IP-Adresse",
  "oifde.service.invoke.ruleValidationService.validationRules.alpha":"Alphanumerischer Unterstrich (beginnt nicht mit einer Zahl)",
  "oifde.service.invoke.timeSelect.workDate":"Geben Sie das Arbeitsdatum an",
  "oifde.service.invoke.timeSelect.noWorkDate":"Arbeitsfreies Datum angeben",
  "oifde.service.invokemethod.offsetMonth.desc":"monatlich versetzt",
  "oifde.service.invokemethod.offsetMonth.parm1.desc":"Zeitvariable, die verrechnet werden soll. Zeichenfolgentyp. Format: JJJJMM",
  "oifde.service.invokemethod.offsetMonth.parm2.desc":"Offset-Nummer, die eine ganze Zahl ist und negativ sein kann.",
  "oifde.service.invokemethod.offsetMonth.resultObj.desc":"Das zurückgegebene Objekt sind die Versatzdaten.",
  "oifde.service.invokemethod.offsetHour.desc":"Um Stunden versetzt",
  "oifde.service.invokemethod.offsetHour.parm1.desc":"Zeitvariable, die verrechnet werden soll. Zeichenfolgentyp. Format: jjjjMMttHHmmss.",
  "oifde.service.invokemethod.offsetHour.parm2.desc":"Offset-Nummer, die eine ganze Zahl ist und negativ sein kann.",
  "oifde.service.invokemethod.offsetHour.resultObj.desc":"Das zurückgegebene Objekt sind die Versatzdaten.",
  "oifde.interfaceInvoke.displayName":"Schnittstellenaufruf",
  "oifde.service.invoke.yugaoRecord.type":"Typ:",
  "oifde.service.invoke.yugaoRecord.mode":"Modus:",
  "oifde.service.invoke.subflow.subFlowNode":"Unterfluss",
  "oifde.service.invokemethod.group.mathodType4":"Datumsbetriebsmethode",
// 'oifde.transdata.get.example':"'Wenn die verknüpften Daten z. B. {\'{\'}data:{\'{\'}„test“: sind, können Sie in den Variablenwert data.test eingeben, um die Daten '111' zu erhalten.',",
  "oifde.service.invoke.basicAvatarReplyService.name":"Avatar-Antwort",
  "oifde.service.invoke.basicAvatarReplyService.actionType":"Betrieb",
  "oifde.service.invoke.basicAvatarReplyService.start":"Initiieren",
  "oifde.service.invoke.basicAvatarReplyService.image":"Bild",
  "oifde.service.invoke.basicAvatarReplyService.avatarImage":"Avatar-Bild",
  "oifde.service.invoke.basicAvatarReplyService.ttsSpeaker":"Lautsprecher",
  "oifde.service.invoke.basicAvatarReplyService.compositeVideoSetting":"Composite-Videokonfiguration",
  "oifde.service.invoke.basicAvatarReplyService.compositeVideoSetting.desc":"Composite-Videokonfiguration, die sich direkt auf den digitalen Personenanzeigeeffekt auswirkt. Aktivieren Sie diese Funktion bei Bedarf.",
  "oifde.service.invoke.basicAvatarReplyService.imageSetting":"Bild- und Videoeinstellungen",
  "oifde.service.invoke.basicAvatarReplyService.action":"Aktionen",
  "oifde.service.invoke.basicAvatarReplyService.frontImage":"Vorderseite",
  "oifde.service.invoke.basicAvatarReplyService.backendImage":"Hintergrundbild",
  "oifde.service.invoke.basicAvatarReplyService.frontVideo":"Video vorne",
  "oifde.service.invoke.basicAvatarReplyService.backendVideo":"Backend-Video",
  "oifde.service.invoke.basicAvatarReplyService.xPosition":"Abszisse",
  "oifde.service.invoke.basicAvatarReplyService.yPosition":"Ordinate",
  "oifde.service.invoke.basicAvatarReplyService.scale":"Skalieren",
  "oifde.service.invoke.basicAvatarReplyService.beginTime":"Startzeit (ms)",
  "oifde.service.invoke.basicAvatarReplyService.duration":"Anzeigedauer (ms)",

}
