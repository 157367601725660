<template>
  <div id="isales-data-import-result">
    <sweet-dialog v-model="dialogVisible" :title="$t('isales.data.title.importResult')" width="1248px" class="aicc-dialog-limit-height">
      <div class="aicc-align--absolute-right aicc-m-bottom--medium">
        <sweet-button class="aicc-button-icon" :title="$t('ccmessaging.chat.chatconfig.refresh')"
                      @click="handleRefresh">
          <i class="sweetui-icon-refresh-l"></i>
        </sweet-button>
      </div>
      <aicc-table ref="aiccTable"
                  :tableData="tableData"
                  :tableColumns="tableColumns"
                  :tableTools="{ showPagination: true, columnConfig: false }"
                  :paginationConfig="paginationConfig"
                  :height="430"
                  @handlePagination="handlePagination">
        <template #marketingMode="scope">{{ marketingModesMap.get(scope.row.marketingMode) }}</template>
        <template #beginTime="scope">{{ $TimeUtils.transTime(scope.row.beginTime) }}</template>
        <template #endTime="scope">{{ $TimeUtils.transTime(scope.row.endTime) }}</template>
        <template #status="scope">{{ DATA_IMPORT_RESULT_STATUS.get(scope.row.status) }}</template>
        <template #succNum="scope">{{ scope.row.succNum || 0 }}</template>
        <template #repeatNum="scope">
          <sweet-button v-if="scope.row.repeatNum" type="primary" text @click="downloadRepeat(scope.row)">
            {{ scope.row.repeatNum }}
          </sweet-button>
          <div v-else>0</div>
        </template>
        <template #failNum="scope">
          <sweet-button v-if="scope.row.failNum" type="primary" text @click="downloadFail(scope.row)">
            {{ scope.row.failNum }}
          </sweet-button>
          <div v-else>0</div>
        </template>
      </aicc-table>
    </sweet-dialog>
  </div>
</template>

<script>

import { queryDataImportTask, downloadDataImportFileUrl, downloadFailDataImportFileUrl } from '@/views/isales/api/taskManagement'
import { OP_MARKETING_MODES, AICC_MARKETING_MODES, DATA_IMPORT_RESULT_STATUS } from '@/views/isales/views/taskManagement/enum'
import {aiccDownload} from '@/utils/aiccUploadAndDownload'
import {downloadContactRecordUrl} from '@/views/ccmanagement/ccma-api'
import appConfig from '@/config'

export default {
  name: 'ConfigProp',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    marketingMode: Number,
    taskId: String
  },
  data() {
    return {
      OP_MARKETING_MODES,
      AICC_MARKETING_MODES,
      DATA_IMPORT_RESULT_STATUS,
      tableData: [],
      marketingModeColumn: [
        {
          label: this.$t('isales.marketing.mode'), // 营销模式
          slot: 'marketingMode',
        }
      ],
      tableColumns: [
        {
          label: this.$t('isales.import.message.begintime'), // 开始时间
          slot: 'beginTime',
          width: '180px'
        },
        {
          label: this.$t('isales.import.message.endtime'), // 结束时间
          slot: 'endTime',
          width: '180px'
        },
        {
          label: this.$t('isales.import.message.status'), // 状态
          slot: 'status',
          width: '120px'
        },
        {
          label: this.$t('isales.import.message.succnum'), // 成功记录数
          slot: 'succNum',
        },
        {
          label: this.$t('isales.import.message.repeatnum'), // 重复记录数
          slot: 'repeatNum',
        },
        {
          label: this.$t('isales.import.message.failnum'), // 失败记录数
          slot: 'failNum',
        },
        {
          label: this.$t('isales.import.message.errMsg'), // 异常原因
          prop: 'errMsg',
          'show-overflow-tooltip': true
        }
      ],
      paginationConfig: {
        offset: 0,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100], // 每页条数列表
        limit: 10, // 每页条数
        total: 0, //总条数
        layout: appConfig.pagination.LAYOUT.ADVANCED
      },
      marketingModesMap: {},
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      }
    },
  },
  created() {
    if (this.marketingMode !== 3) {
      // 添加到第一列
      this.tableColumns.unshift(...this.marketingModeColumn)
    }
    // false 集成; true OP
    this.marketingModesMap = localStorage.getItem('isIndependentDeployment') === 'true' ? OP_MARKETING_MODES : AICC_MARKETING_MODES
    this.queryResultList()
  },
  methods: {
    handleRefresh() {
      this.$refs.aiccTable.handleInitChange()
    },
    handlePagination(data) {
      Object.assign(this.paginationConfig, data)
      this.queryResultList()
    },
    async queryResultList() {
      const { offset, limit } = this.paginationConfig
      let params = { offset, limit, marketingMode : this.marketingMode }
      if (this.marketingMode !== 3){
        params.taskId = this.taskId
      }
      let result = await queryDataImportTask(params)
      this.tableData = result.result
      this.paginationConfig.total =  result.count
    },
    downloadRepeat(row) {
      if (!row.repeatNum) return
      const { tenantId, fileKey, filePath, ...downloadInfo } = row
      aiccDownload({ downloadInfo, cmsEnvironment: localStorage.getItem('cmsEnvironment') }, downloadDataImportFileUrl)
        .then(() => {}).catch(() => {})
    },
    downloadFail(row) {
      if (!row.failNum) return
      const { tenantId, fileKey, filePath, __customize__, ...downloadInfo } = row
      aiccDownload({ downloadInfo, cmsEnvironment: localStorage.getItem('cmsEnvironment') }, downloadFailDataImportFileUrl)
        .then(() => {}).catch(() => {})
    },
  }
}
</script>

<style lang="less" scoped>
</style>