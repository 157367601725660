<template>
    <div>
        <!--常规日期时间段-->
        <sweet-row class="isales-background-color">
            <sweet-col :span="14">
                <sweet-form-item :label="$t('isales.calltime.title.default.datetime')" required>
                    <div v-for="(item,index) in sweetTimeList" class="aicc-calltime-align--absolute-left" style="margin-left: 32px">
                        <div class="width-400px">
                          <sweet-time-picker v-model="sweetTimeList[index]" is-range
                                             range-separator="-"
                                             :start-placeholder="this.$t('isales.taskinfo.field.beginDate')"
                                             :end-placeholder="this.$t('isales.taskinfo.field.endDate')"
                                             class="aicc-m-vertical--xx-small" @change="onChangeSweetTimeList(index)"
                                             format="HH:mm" :default-value="defaultValue"
                                             :prefix-icon="isView ? this.customPrefix: ''"/>
                        </div>&nbsp;&nbsp;&nbsp;
                        <sweet-form-item id="closeDefaultTimeId" v-if="index===0" style="width:20px">
                        </sweet-form-item>
                        <sweet-form-item id="closeDefaultTimeId" v-if="index!=0">
                            <sweet-button class="task-icon" :icon="Delete" type="icon"
                                          @click="closeDefaultTime(index)"/>
                        </sweet-form-item>
                    </div>
                    <div v-if="sweetTimeList.length<3 && ((!isView && !isViewTemplate) || (isBatchEdit && !showModel.basicInfoDisabled))"
                         class="isales-task-info-add aicc-calltime-align--absolute-left" style="margin-left: 32px">
                        <sweet-button class="task-icon" style="margin-right: 12px" :icon="CirclePlus" type="icon" @click="addDefaultTime">
                            <span class="create-button">{{ $t('isales.calltime.title.add.time.period') }}</span>
                        </sweet-button>
                    </div>
                    <div v-if="sweetTimeList.length>=3 &&((!isView && !isViewTemplate) || (isBatchEdit && !showModel.basicInfoDisabled))"
                         class="isales-task-info-add aicc-calltime-align--absolute-left"
                         style="color: #91989e;margin-left: 32px">
                        <sweet-button class="task-icon" style="margin-right: 12px;color: #91989e" :icon="CirclePlus" type="icon" @click="addDefaultTime">
                            <span class="create-button"  style="color: #91989e;">{{ $t('isales.calltime.title.add.time.period') }}</span>
                        </sweet-button>
                    </div>
                </sweet-form-item>
            </sweet-col>
        </sweet-row>
        <!--特殊日期-->
        <sweet-form-item style="margin-top:20px">
            <template #label>
                <sweet-tooltip class="box-item" effect="light"
                               :content="$t('isales.taskInfo.spec.date.title.quotasTips')" :placement="topPlacement">
                    <div class="aicc-align--absolute-left">
                        <span class="required-label icon-class">{{ $t('isales.calltime.title.spec.date') }}</span>
                        <sweet-icon>
                            <warning style="transform: rotateX(180deg)"/>
                        </sweet-icon>
                    </div>
                </sweet-tooltip>
            </template>
            <sweet-switch v-model="isOpenSpecDate"/>
        </sweet-form-item>
        <sweet-row v-if="isOpenSpecDate" class="isales-background-color">
            <sweet-col :span="12" style="margin-left:165px">
                <div style="width: 750px">
                    <aicc-table :tableData="showModel.taskHoliday"
                                :tableColumns="conditionTableColumns"
                                :tableTools="{ columnConfig: false }">
                        <template #operate="scope">
                            <sweet-button class="task-icon" :icon="Delete" type="icon"
                                          @click="closeSpecDate(scope.row)"/>
                        </template>
                    </aicc-table>
                    <div v-if="!isView && !isViewTemplate" class="addInfo">
                        <sweet-button class="task-icon" style="margin-right: 12px" :icon="CirclePlus" type="icon" @click="addSpecDateDialogVisible=true">
                            <span class="create-button">{{ $t('isales.calltime.title.add.date')}}</span>
                        </sweet-button>
                    </div>
                </div>
            </sweet-col>
        </sweet-row>
        <!--特殊日期时间段-->
        <sweet-row v-if="isOpenSpecDate" style="margin-top:20px" class="isales-background-color">
            <sweet-col :span="12">
                <sweet-form-item :label="$t('isales.calltime.title.spec.datetime.period')">
                    <div v-for="(item,index) in specTimeList" class="aicc-calltime-align--absolute-left" style="margin-left: 32px">
                        <sweet-time-picker v-model="specTimeList[index]" is-range
                                           range-separator="-"
                                           :start-placeholder="this.$t('isales.taskinfo.field.beginDate')"
                                           :end-placeholder="this.$t('isales.taskinfo.field.endDate')"
                                           class="aicc-m-vertical--xx-small" @change="onChangeSpecTimeList()"
                                           format="HH:mm"
                                           :default-value="defaultValue"
                                           :prefix-icon="isView ? this.customPrefix: ''"/>&nbsp;&nbsp;&nbsp;
                        <sweet-form-item id="closeSpecTimeId">
                            <sweet-button class="task-icon" :icon="Delete" type="icon"
                                          @click="closeSpecTime(index)"/>
                        </sweet-form-item>
                    </div>
                    <div v-if="specTimeList.length<3 && !isView && !isViewTemplate"
                         class="isales-task-info-add aicc-calltime-align--absolute-left" style="margin-left: 32px">
                        <sweet-button class="task-icon" style="margin-right: 12px" :icon="CirclePlus" type="icon" @click="addSpecTime">
                            <span class="create-button">{{ $t('isales.calltime.title.add.time.period') }}</span>
                        </sweet-button>
                    </div>
                    <div v-if="specTimeList.length>=3 && !isView && !isViewTemplate"
                         class="isales-task-info-add aicc-calltime-align--absolute-left" style="color: #91989e;margin-left: 32px">
                        <sweet-button class="task-icon" style="margin-right: 12px;color: #91989e" :icon="CirclePlus" type="icon" @click="addSpecTime">
                            <span style="color: #91989e" class="create-button">{{ $t('isales.calltime.title.add.time.period') }}</span>
                        </sweet-button>
                    </div>
                </sweet-form-item>
            </sweet-col>
        </sweet-row>
    </div>
    <isalesSpecialDate v-model:show="addSpecDateDialogVisible" v-if="addSpecDateDialogVisible"
                       @isalesSpecialDateConfirm="isalesSpecialDateConfirm"
                       :selectedAttributes=selectedAttributes></isalesSpecialDate>
</template>
<script>
    import {Delete, CirclePlus} from '@element-plus/icons-vue';
    import isalesSpecialDate from "@/views/isales/views/systemAutoCallTask/isalesSpecialDate.vue";
    import {TASK_HOLIDAY_TYPES} from "@/views/isales/views/systemAutoCallTask/enum.js";
    import {getPolicyAndCallTimesByTaskId, queryTaskHoliday} from "@/views/isales/api/systemAutoCallTask";
    import {SPECIALDAY_WEEKLY_ENUM} from "@/views/isales/views/systemAutoCallTask/enum";
    import {h, shallowRef} from "vue";

    export default {
        name: "CallTime",
        components: {
            isalesSpecialDate
        },
        props: {
            showModel: {
                type: Object,
                default() {
                    return {}
                },
            }
        },
        data() {
            return {
              selectedAttributes: [], // 特殊日期选择器
              topPlacement: window.isRTL ? 'top-end' : 'top-start',
              customPrefix : shallowRef({
                    render() {
                        return h('p', '')
                    },
                }),
                queryType:'',
                Delete,
                CirclePlus,
                isOpenSpecDate: false,
                defaultValue: [this.getTrueTimeString('00', '00'), this.getTrueTimeString('23', '59')],
                sweetTimeList: [
                    []
                ],
                specTimeList: [],
                taskHolidayTypesItem: TASK_HOLIDAY_TYPES,
                specialDayWeeklyItem: SPECIALDAY_WEEKLY_ENUM,
                addSpecDateDialogVisible: false,
                conditionTableColumns: [
                    {
                        label: this.$t('isales.calltime.title.holidayName'),
                        prop: 'holidayName'
                    },
                    {
                        label: this.$t('isales.calltime.title.holidayType'),
                        prop: 'holidayTypeDesc'
                    },
                    {
                        label: this.$t('isales.calltime.title.holiday'),
                        prop: 'holiday'
                    },
                    {
                        label: this.$t('isales.common.title.operation'), // 操作
                        slot: 'operate',
                        fixed: 'right',
                    }
                ],
            }
        },
        methods: {
            closeDefaultTime(index) {
                this.sweetTimeList.splice(index, 1);
                this.onChangeSweetTimeList();
            },
            addDefaultTime() {
                if (this.sweetTimeList.length >= 3) {
                    return;
                }
                this.sweetTimeList.push([]);
            },
            closeSpecDate(row) {
                let index = this.showModel.taskHoliday.findIndex(item => item === row);
                this.showModel.taskHoliday.splice(index, 1);
              if (this.selectedAttributes && this.selectedAttributes.length > 0) {
                let index = this.selectedAttributes.findIndex(item => item == row.holidayId);
                this.selectedAttributes.splice(index, 1);
              }
            },
            closeSpecTime(index) {
                this.specTimeList.splice(index, 1);
                this.onChangeSpecTimeList();
            },
            addSpecTime() {
                if (this.specTimeList.length >= 3) {
                    return;
                }
                this.specTimeList.push([]);
            },
            isalesSpecialDateConfirm(datas) {
                if (datas) {
                  this.selectedAttributes=[];
                  this.showModel.taskHoliday=datas;
                  datas.forEach(item => {
                    item['holidayTypeDesc']=this.taskHolidayTypesItem.filter(holiday => holiday.value === item.holidayType)[0].label;
                    this.selectedAttributes.push(item.holidayId);
                  });
                }
            },
            onChangeSweetTimeList(index = null) {
                this.showModel.policy.oBeginTime1 = '';
                this.showModel.policy.oEndTime1 = '';
                this.showModel.policy.oBeginTime2 = '';
                this.showModel.policy.oEndTime2 = '';
                this.showModel.policy.oBeginTime3 = '';
                this.showModel.policy.oEndTime3 = '';
                if (!this.sweetTimeList || this.sweetTimeList.length <= 0) {
                    return;
                }
                for (let i = 0; i < this.sweetTimeList.length; i++) {
                    if (i === 0 && this.sweetTimeList[i][0] && this.sweetTimeList[i][0] !== '') {
                        this.showModel.policy.oBeginTime1 = this.$TimeUtils.transTimeHourMinu(this.sweetTimeList[i][0]);
                        this.showModel.policy.oEndTime1 = this.$TimeUtils.transTimeHourMinu(this.sweetTimeList[i][1]);
                    }
                    if (i === 1 && this.sweetTimeList[i][0] && this.sweetTimeList[i][0] !== '') {
                        this.showModel.policy.oBeginTime2 = this.$TimeUtils.transTimeHourMinu(this.sweetTimeList[i][0]);
                        this.showModel.policy.oEndTime2 = this.$TimeUtils.transTimeHourMinu(this.sweetTimeList[i][1]);
                    }
                    if (i === 2 && this.sweetTimeList[i][0] && this.sweetTimeList[i][0] !== '') {
                        this.showModel.policy.oBeginTime3 = this.$TimeUtils.transTimeHourMinu(this.sweetTimeList[i][0]);
                        this.showModel.policy.oEndTime3 = this.$TimeUtils.transTimeHourMinu(this.sweetTimeList[i][1]);
                    }
                }
            },
            checkCallTimeOnConfirm() {
                for (let i = 0; i < this.sweetTimeList.length; i++) {
                    if (i === 0 && this.sweetTimeList[i][0] && this.sweetTimeList[i][0] !== '') {
                        if (this.sweetTimeList.length === 2 && (this.checkTimeCrossover(this.sweetTimeList[i][0], this.sweetTimeList[1][0], this.sweetTimeList[1][1]))) {
                            return false;
                        }
                        if (this.sweetTimeList.length === 3 && (this.checkTimeCrossover(this.sweetTimeList[i][0], this.sweetTimeList[1][0], this.sweetTimeList[1][1])
                            || this.checkTimeCrossover(this.sweetTimeList[i][0], this.sweetTimeList[2][0], this.sweetTimeList[2][1]))) {
                            return false;
                        }
                    }
                    if (i === 1 && this.sweetTimeList[i][0] && this.sweetTimeList[i][0] !== '') {
                        if (this.sweetTimeList.length === 2 && (this.checkTimeCrossover(this.sweetTimeList[i][0], this.sweetTimeList[0][0], this.sweetTimeList[0][1]))) {
                            return false;
                        }
                        if (this.sweetTimeList.length === 3 && (this.checkTimeCrossover(this.sweetTimeList[i][0], this.sweetTimeList[0][0], this.sweetTimeList[0][1])
                            || this.checkTimeCrossover(this.sweetTimeList[i][0], this.sweetTimeList[2][0], this.sweetTimeList[2][1]))) {
                            return false;
                        }
                    }
                    if (i === 2 && this.sweetTimeList[i][0] && this.sweetTimeList[i][0] !== '') {
                        if (this.sweetTimeList.length === 3 && (this.checkTimeCrossover(this.sweetTimeList[i][0], this.sweetTimeList[0][0], this.sweetTimeList[0][1])
                            || this.checkTimeCrossover(this.sweetTimeList[i][0], this.sweetTimeList[1][0], this.sweetTimeList[1][1]))) {
                            return false;
                        }
                    }
                }
                return true;
            },
            checkTimeCrossover(currentDate, refBegin, refEnd) { //校验时间不存在交叉
                if(!currentDate || !refBegin || !refEnd){
                  return false;
                }
                // 存在交叉
                if (currentDate.getTime() >= refBegin.getTime() && currentDate.getTime() <= refEnd.getTime()) {
                    return true;
                }
                return false;
            },
            onChangeSpecTimeList() {
                this.showModel.policy.hBeginTime1 = '';
                this.showModel.policy.hEndTime1 = '';
                this.showModel.policy.hBeginTime2 = '';
                this.showModel.policy.hEndTime2 = '';
                this.showModel.policy.hBeginTime3 = '';
                this.showModel.policy.hEndTime3 = '';
                if (!this.specTimeList || this.specTimeList.length <= 0) {
                    return;
                }
                for (let i = 0; i < this.specTimeList.length; i++) {
                    if (i === 0 && this.specTimeList[i][0] && this.specTimeList[i][0] !== '') {
                        this.showModel.policy.hBeginTime1 = this.$TimeUtils.transTimeHourMinu(this.specTimeList[i][0]);
                        this.showModel.policy.hEndTime1 = this.$TimeUtils.transTimeHourMinu(this.specTimeList[i][1]);
                    }
                    if (i === 1 && this.specTimeList[i][0] && this.specTimeList[i][0] !== '') {
                        this.showModel.policy.hBeginTime2 = this.$TimeUtils.transTimeHourMinu(this.specTimeList[i][0]);
                        this.showModel.policy.hEndTime2 = this.$TimeUtils.transTimeHourMinu(this.specTimeList[i][1]);
                    }
                    if (i === 2 && this.specTimeList[i][0] && this.specTimeList[i][0] !== '') {
                        this.showModel.policy.hBeginTime3 = this.$TimeUtils.transTimeHourMinu(this.specTimeList[i][0]);
                        this.showModel.policy.hEndTime3 = this.$TimeUtils.transTimeHourMinu(this.specTimeList[i][1]);
                    }
                }
            },
            checkSpecTimeOnConfirm() {
                for (let i = 0; i < this.specTimeList.length; i++) {
                    if (i === 0 && this.specTimeList[i][0] && this.specTimeList[i][0] !== '') {
                        if (this.specTimeList.length === 2 && (this.checkTimeCrossover(this.specTimeList[i][0], this.specTimeList[1][0], this.specTimeList[1][1]))) {
                            return false;
                        }
                        if (this.specTimeList.length === 3 &&
                            (this.checkTimeCrossover(this.specTimeList[i][0], this.specTimeList[1][0], this.specTimeList[1][1])
                                || this.checkTimeCrossover(this.specTimeList[i][0], this.specTimeList[2][0], this.specTimeList[2][1]))) {
                            return false;
                        }
                    }
                    if (i === 1 && this.specTimeList[i][0] && this.specTimeList[i][0] !== '') {
                        if (this.specTimeList.length === 2 && (this.checkTimeCrossover(this.specTimeList[i][0], this.specTimeList[0][0], this.specTimeList[0][1]))) {
                            return false;
                        }
                        if (this.specTimeList.length === 3 &&
                            (this.checkTimeCrossover(this.specTimeList[i][0], this.specTimeList[0][0], this.specTimeList[0][1])
                                || this.checkTimeCrossover(this.specTimeList[i][0], this.specTimeList[2][0], this.specTimeList[2][1]))) {
                            return false;
                        }
                    }
                    if (i === 2 && this.specTimeList[i][0] && this.specTimeList[i][0] !== '') {
                        if (this.specTimeList.length === 3 &&
                            (this.checkTimeCrossover(this.specTimeList[i][0], this.specTimeList[0][0], this.specTimeList[0][1])
                                || this.checkTimeCrossover(this.specTimeList[i][0], this.specTimeList[1][0], this.specTimeList[1][1]))) {
                            return false;
                        }
                    }
                }
                return true;
            },
            getTrueTimeString(beginTime, endTime) {
                if (this.isTimeWorthless(beginTime) && this.isTimeWorthless(endTime)) {
                    return "";
                }
                var begin = beginTime.length == 1 ? "0" + beginTime : beginTime;
                var end = endTime.length == 1 ? "0" + endTime : endTime;
                return this.$TimeUtils.transDate(new Date()) + ' ' + begin + ":" + end;
            },
            isTimeWorthless(time) {
                if (time == null || time == undefined || time == "") {
                    return true;
                }
                return false;
            },
            async initCallTime(taskId) {
                this.sweetTimeList = [];
                this.specTimeList = [];
                // 补充特殊日期
                const tempObj = {"dataObject": {"taskId": taskId}, "limit": 10, "offset": 0}
                let holidayOld = await queryTaskHoliday(tempObj);
                if (holidayOld) {
                    this.showModel.taskHoliday = holidayOld;
                    this.showModel.taskHoliday.forEach(item => {
                        this.selectedAttributes.push(item.holidayId);
                        item['holidayTypeDesc'] = this.taskHolidayTypesItem.filter(holiday => holiday.value === item.holidayType)[0].label;
                        let holidayOnWeekly = "";
                        if (item.holidayType === "1") {
                            if (item.holiday.length > 0) {
                                let holidayArray = item.holiday.split(",");
                                let holidayArrayLength = holidayArray.length;
                                for (let j = 0; j < holidayArrayLength - 1; j++) {
                                    holidayOnWeekly = holidayOnWeekly + this.specialDayWeeklyItem[holidayArray[j]] + ",";
                                }
                                holidayOnWeekly = holidayOnWeekly + this.specialDayWeeklyItem[holidayArray[holidayArrayLength - 1]];
                                item.holiday = holidayOnWeekly;
                            }
                        } else if (item.holidayType === "2") {
                            item.holiday = this.$TimeUtils.transDate(item.beginDate)
                                + "~" + this.$TimeUtils.transDate(item.endDate);
                        } else {
                            item.holiday = item.beginDate + "~" + item.endDate
                        }
                    });
                }
                let result = await getPolicyAndCallTimesByTaskId({taskId: taskId})
                if (!result) {
                    return;
                }
                // 置换时间段
                this.showModel.policy = result.policy;
                if (this.showModel.policy) {
                    this.showModel.policy.oBeginTime1 = this.showModel.policy.obeginTime1 !== '' && this.showModel.policy.obeginTime1 !== ':' ? this.showModel.policy.obeginTime1 : '';
                    this.showModel.policy.oEndTime1 = this.showModel.policy.oendTime1 != '' && this.showModel.policy.oendTime1 !== ':' ? this.showModel.policy.oendTime1 : '';
                    this.showModel.policy.oBeginTime2 = this.showModel.policy.obeginTime2 != '' && this.showModel.policy.obeginTime2 !== ':' ? this.showModel.policy.obeginTime2 : '';
                    this.showModel.policy.oEndTime2 = this.showModel.policy.oendTime2 != '' && this.showModel.policy.oendTime2 !== ':' ? this.showModel.policy.oendTime2 : '';
                    this.showModel.policy.oBeginTime3 = this.showModel.policy.obeginTime3 != '' && this.showModel.policy.obeginTime3 !== ':' ? this.showModel.policy.obeginTime3 : '';
                    this.showModel.policy.oEndTime3 = this.showModel.policy.oendTime3 != '' && this.showModel.policy.oendTime3 !== ':' ? this.showModel.policy.oendTime3 : '';

                    this.showModel.policy.hBeginTime1 = this.showModel.policy.hbeginTime1 != '' && this.showModel.policy.hbeginTime1 !== ':' ? this.showModel.policy.hbeginTime1 : '';
                    this.showModel.policy.hEndTime1 = this.showModel.policy.hendTime1 != '' && this.showModel.policy.hendTime1 !== ':' ? this.showModel.policy.hendTime1 : '';
                    this.showModel.policy.hBeginTime2 = this.showModel.policy.hbeginTime2 != '' && this.showModel.policy.hbeginTime2 !== ':' ? this.showModel.policy.hbeginTime2 : '';
                    this.showModel.policy.hEndTime2 = this.showModel.policy.hendTime2 != '' && this.showModel.policy.hendTime2 !== ':' ? this.showModel.policy.hendTime2 : '';
                    this.showModel.policy.hBeginTime3 = this.showModel.policy.hbeginTime3 != '' && this.showModel.policy.hbeginTime3 !== ':' ? this.showModel.policy.hbeginTime3 : '';
                    this.showModel.policy.hEndTime3 = this.showModel.policy.hendTime3 != '' && this.showModel.policy.hendTime3 !== ':' ? this.showModel.policy.hendTime3 : '';
                }
                // 补充常规时间和特殊时间段
                var oTimeModels = result.oTimeModels;
                for (var i = 0; i < oTimeModels.length; i++) {
                    let arr = [];
                    if (oTimeModels[i].beginTime1 && oTimeModels[i].beginTime1 !== '') {
                        const oBeginTime1 = this.getTrueTimeString(oTimeModels[i].beginTime1, oTimeModels[i].beginTime2);
                        const oEndTime1 = this.getTrueTimeString(oTimeModels[i].endTime1, oTimeModels[i].endTime2);
                        arr.push(new Date(oBeginTime1));
                        arr.push(new Date(oEndTime1));
                        this.sweetTimeList.push(arr);
                    }
                }
                if (this.showModel.taskHoliday && this.showModel.taskHoliday.length > 0) {
                    this.isOpenSpecDate = true;
                    var sTimeModels = result.sTimeModels;
                    for (var i = 0; i < sTimeModels.length; i++) {
                        let arr = [];
                        const hBeginTime1 = this.getTrueTimeString(sTimeModels[i].beginTime1, sTimeModels[i].beginTime2);
                        const hEndTime1 = this.getTrueTimeString(sTimeModels[i].endTime1, sTimeModels[i].endTime2);
                        if (hBeginTime1 && hBeginTime1 !== "") {
                            arr.push(new Date(hBeginTime1));
                            arr.push(new Date(hEndTime1));
                            this.specTimeList.push(arr);
                        }
                    }
                }
            },
            checkOpenSpecDate(){
                if (this.isOpenSpecDate && (!this.showModel.taskHoliday || this.showModel.taskHoliday.length === 0)) {
                   return false;
                }
                return true;
            }
        },
        computed: {
            isView() {
                return this.queryType === 'view'
            },
            isEdit() {
                return this.queryType === 'edit'
            },
            isCreate() {
                return this.queryType === 'create'
            },
            isBatchEdit() {
                return this.queryType === 'batchEdit'
            },
            isCreateTemplate(){// 新建外呼任务模型
                return this.queryType === 'createTemplate'
            },
            isEditTemplate(){// 编辑外呼任务模型
                return this.queryType === 'editTemplate'
            },
            isViewTemplate(){// 编辑外呼任务模型
                return this.queryType === 'viewTemplate'
            },
        },
        async created() {
            this.queryType = this.$route.query.type;
        },
        watch: {
            isOpenSpecDate(value) {
                if (!value) {
                    this.showModel.taskHoliday = [];
                    this.selectedAttributes = [];
                    this.specTimeList = [];
                    this.onChangeSpecTimeList();
                }
            },
        },
    }
</script>


<style lang="less" scoped>
    .card-header {
        padding-bottom: 30px;
        font-size: var(--16px-to-rem);
        color: #1C1C1C;
        font-weight: bold;
    }

    .isales-task-info-add {
        margin-top: 10px;
        font-size: 14px;
        color: #366BFC;
        font-weight: bold;
    }

    .aicc-calltime-align--absolute-left {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
        justify-content: flex-start;
        -ms-flex-line-pack: center;
        align-content: center;
        -ms-flex-align: center;
        align-items: center;
        width: 100%
    }

    .width-180px-to-rem {
        width: var(--180px-to-rem) !important;
    }

    .icon-delete-condition {
        font-size: 20px !important;
    }
    .task-icon {
        font-size: 18px !important;
        color: #366bfc;
    }

    .isales-background-color {
        padding: 20px 10px;
        background-color: #FBFBFB;
    }
    .width-400px {
      width: 400px;
      border-radius: 4px;
    }
    .icon-class{
        margin-right: 9px;
    }
    body.RTL{
        .icon-class{
            margin-left: 9px;
            margin-right: unset;
        }
    }

    .addInfo {
        width: 100%;
        height: 45px;
        border: 1px solid rgba(238, 238, 238, 1);
        border-top: 0;
        display: flex;
        padding-inline-start: 1rem;
        font-size: 14px;
        color: #366BFC;
        font-weight: bold;
        align-items: center;
        &-wrap {
            display: flex;
            gap: 10px;
        }
    }
    .create-button {
        font-size: 14px;
        font-weight: bold;
    }
</style>