export default {
  "dy.agent.audio.tab.outgoingcall" : "Appel sortant",
  "dy.agent.audio.tab.internalcall" : "Appel interne",
  "dy.chat.multimedia.update" : "Mettre à jour",
  "dy.agent.tab.over.number.hints" : "Un maximum de huit onglets peuvent être ouverts. Vous ne pouvez pas ouvrir d'autres onglets. Fermez d'abord les autres onglets",
  "dy.chat.agent.logout.hints" : "Non enregistré, veuillez vous connecter pour commencer votre travail",
  "dy.chat.label.contacts" : "Contacts",
  "dy.chat.label.call" : "Appel",
  "dy.chat.label.multimedia" : "Multimédia",
  "dy.chat.label.history" : "Histoire",
  "dy.chat.label.monitor" : "Moniteur",
  "dy.agent.label.id" : "ID:",
  "dy.agent.label.enter.exnum" : "Entrez le numéro externe",
  "dy.chat.multimedia.required" : "Obligatoire",
  "dy.chat.history.disconnect.tip" : "L'appel est déconnecté, vous pouvez envoyer un message hors ligne pour vous reconnecter au client",
  "dy.agent.voice.extnumber.isnull" : "Le numéro externe est nul.",
  "dy.agent.voice.msg.noskill" : "Aucune file d'attente de compétences n'est disponible",
  'dy.agent.session.list.timeout': 'Délai d\'expiration',
  'dy.session.transfer.button': 'Je l\'ai',
  'dy.agent.transfer.failed': 'Impossible de transférer à l\'agent de destination',
  'dy.agent.twoinnerhelp.failed': 'Impossible de consulter l\'agent de destination',
  'dy.agent.threeinnerhelp.failed': 'Défaillance à trois parties de l\'agent de destination',
  'dy.agent.message.tipsNotMonitor': 'Vous n\'êtes pas inspecteur. Vous ne pouvez pas effectuer l\'inspection.',
  'dy.agent.message.selectInfo': 'Cliquez d\'abord sur l\'agent pour l\'inspecter.',
  'dy.agent.attachment.notPreview': 'Le type de fichier ne prend pas en charge l\'aperçu, téléchargez svp le fichier pour afficher le contenu.',
  'dy.chat.agent.novoEmail': 'Nouvel e-mail',
  "dy.chat.sendmessage.recipient" : "À",
  "dy.chat.chatcard.folded": "Plié",
  "dy.chat.status.drafts": "Ébauches",
  "dy.agent.call.selectextnumber": "Veuillez sélectionner un numéro externe.",
  "dy.agent.call.inputextnumber": "Veuillez saisir un numéro externe."
}