export default {
    "cms.report.message.maxQueueCountDesc" : "Número máximo de llamadas simultáneas cuyo dispositivo de servicio es una cola de habilidades.",
    "cms.report.message.deviceTypeDesc" : "Tipo de dispositivo que maneja la llamada",
    "cms.report.message.agentTalkingTimeDesc" : "Tiempo de conversación de llamada.",
    "cms.report.field.maxAgentOutCount" : "Número máximo de llamadas salientes de agentes simultáneos",
    "cms.report.field.inCallSuccNum" : "Número de llamadas manuales conectadas",
    "cms.agentMonitor.label.whisper_success" : "Susurrante éxito",
    "cms.report.message.maxQueueInCountDesc" : "Número máximo de llamadas entrantes simultáneas cuyo dispositivo de servicio es una cola de habilidades.",
    "cms.report.field.agentReleaseTimeField" : "Hora de liberación de llamada",
    "cms.calllink.field.leaveReason39" : "La CDN devuelve el resultado de enrutamiento.",
    "cms.indexmonitor.message.InCallAgentCount" : "Número de llamadas entrantes conectadas",
    "cms.calllink.field.suspendedAndResumed" : "Se reanuda una llamada suspendida.",
    "cms.calllink.field.leaveReason38" : "Inicie sesión en la CDN a través de la consulta del agente.",
    "cms.fullscreen.field.line" : "Gráfico de líneas",
    "cms.calllink.field.leaveReason37" : "La llamada se encamina a la CDN.",
    "cms.calllink.field.leaveReason36" : "Reencaminamiento de llamadas en cola.",
    "cms.report.message.busyTimeRateDesc" : "Tasa de duración ocupada = (Duración total ocupada/Duración total del trabajo) x 100",
    "cms.calllink.field.leaveReason35" : "La llamada de red se devuelve al centro de llamadas original y se pone en cola.",
    "cms.calllink.field.leaveReason34" : "Tiempo de espera/desbordamiento/fallo de llamada de red en cola en ocupado.",
    "cms.report.field.agentNum" : "ID de agente",
    "cms.calllink.field.leaveReason31" : "El reenvío de terceros a la cola se agota.",
    "cms.calllink.field.leaveReason30" : "El éxito de la transferencia a la cola se agota.",
    "cms.qcrelationconf.field.groupMemberInfo" : "Información del miembro del grupo de usuarios",
    "cms.report.field.maxQueueCountField" : "Número máximo de llamadas simultáneas en una cola",
    "cms.report.field.skillIdField" : "ID de la cola de habilidades a la que pertenece la llamada",
    "cms.agentMonitor.label.selectAgent" : "Seleccione un agente.",
    "cms.report.message.IVRInBoundDesc" : "Número total de llamadas entrantes realizadas con el IVR.",
    "cms.fullscreen.field.yaHei" : "YaHei de Microsoft",
    "cms.report.field.releaseCauseField" : "Motivo de liberación",
    "cms.qualitycheck.message.deleteSuccess" : "Se ha eliminado correctamente.",
    "cms.developerlist.field.description" : "Descripcion",
    "cms.fullscreen.title.newChart" : "Crear gráfico",
    "cms.indexmonitor.field.ivrTotalCallNum" : "Total de llamadas entrantes de IVR",
    "cms.report.field.agentTalkDurationField" : "Llamador que habla Duración",
    "cms.recorddrive.field.configSftpAccountPwd" : "Contraseña del servidor SFTP",
    "cms.calllink.field.leaveReason29" : "Transferencia de terceros a la cola.",
    "cms.report.message.outCallRateDesc" : "Porcentaje del número de llamadas salientes conectadas en el número de llamadas salientes.",
    "cms.calllink.field.leaveReason28" : "Se ha transferido correctamente a la cola.",
    "cms.recorddrive.field.configSftpPathError" : "La ruta de almacenamiento de archivos no puede contener './'",
    "cms.qualitycheck.label.selectedGroup" : "Grupos de usuarios seleccionados",
    "cms.indexmonitor.field.skillAbandonIn60Rate" : "Llamadas abandonadas en 60 años",
    "cms.voiceinspection.field.socialChat" : "Multimedia",
    "cms.systemparam.error.validate.ruleinvalid" : "La regla de verificación no es válida",
    "cms.tenant.message.pwdCheckmsg" : "Las dos contraseñas son diferentes.",
    "cms.calllink.title.skillQueue" : "Cola de habilidades conectadas",
    "cms.fullscreen.field.fiveSec" : "5 Segundos",
    "cms.calllink.msg.numberInvalid" : "Formato de número incorrecto.",
    "cms.fullscreen.field.indicatorData" : "Datos del indicador",
    "cms.report.message.ackBeginDesc" : "Hora de inicio de la respuesta de llamada",
    "cms.fullscreen.field.themeName" : "Nombre del sujeto",
    "cms.report.field.callerHangUp" : "Cuelga el llamador",
    "cms.report.field.waitEndField" : "Hora de finalización de la espera",
    "cms.report.message.sysSuccRateDesc" : "Porcentaje del número de llamadas entrantes del sistema con éxito en el número total de llamadas entrantes del sistema.",
    "cms.agentMonitor.label.qcinspector_error" : "No se puede realizar la operación porque no es inspector.",
    "cms.callcenterinstanceslist.message.ccNameUsed" : "Se ha utilizado el nombre del centro de llamadas.",
    "cms.report.field.outAnswerRate" : "Tasa de conexión de llamadas salientes",
    "cms.indexmonitor.field.description" : "Descripcion",
    "cms.callcenterinstanceslist.message.createSuccess" : "El centro de llamadas se crea correctamente.",
    "cms.indexmonitor.field.totalCallNum" : "Total de llamadas",
    "cms.voiceinspection.message.selectVoice" : "Seleccione una grabación.",
    "cms.report.field.IVRCallOutSuccNum" : "Llamadas salientes de IVR conectadas",
    "cms.report.message.timeRangeCheckSaas" : "El intervalo de consulta no puede ser superior a 5 días",
    "cms.report.title.skillMonitor" : "Supervisión del rendimiento de la cola de habilidades",
    "cms.vdnconfiguration.message.updatesuccess" : "La actualización se ha realizado correctamente.",
    "cms.report.field.avgInRingTime" : "Duración promedio de las llamadas entrantes de los terminales",
    "cms.calllink.field.powerOff" : "El teléfono está apagado.",
    "cms.report.message.inAvgRingTimeDesc" : "Duración media del timbre del terminal de un agente. Duración media del timbre de las llamadas entrantes manuales = Duración total del timbre de las llamadas entrantes manuales/Número de tiempos de respuesta.",
    "cms.report.field.availTimeRate" : "Tasa de inactividad",
    "cms.developerlist.title.success" : "Éxito",
    "cms.report.field.avgAcwTime" : "Duración promedio de cierre",
    "cms.recorddrive.placeholder.sftpIdentityChecktips" : "Ingrese su cuenta actual y contraseña.",
    "cms.fullscreen.field.fullscreen" : "Pantalla completa",
    "cms.report.title.agentMonitor" : "Supervisión de resumen del rendimiento del agente",
    "cms.systemparam.error.validate.notmatchrulemin" : "El parámetro de entrada es menor que el mínimo",
    "cms.datasource.label.delete" : "Borrar",
    "cms.fullscreen.field.dayMonitor" : "Estadísticas de Indicadores de Hoy",
    "cms.report.field.call6Rate" : "Tasa de conexión manual de llamadas entrantes en 30s",
    "cms.report.message.workTimeDesc" : "Duración total desde el momento en que un agente inicia sesión hasta el momento en que el agente cierra la sesión.",
    "cms.indexmonitor.title.callCenterDayMonitor" : "Estadísticas de Hoy sobre Indicadores de Call Center",
    "cms.sysparam.config.item.catalog" : "Tipo de parámetro",
    "cms.report.field.systemInSucc" : "Llamadas entrantes exitosas",
    "cms.systemmonitor.message.timeempty" : "* La hora de inicio o la hora de finalización no están permitidas para estar vacías.",
    "cms.report.message.systemInSuccDesc" : "Número de llamadas entrantes del sistema exitosas, incluidas las llamadas durante la reproducción de voz IVR, la cola, el timbre en los terminales del agente y la respuesta del agente (excluyendo las llamadas entrantes internas).",
    "cms.callcenterinstanceslist.title.chooseMode" : "Seleccionar modo",
    "cms.indexmonitor.message.totalAwcNum" : "Número de agentes en estado de cierre después de la gestión del negocio",
    "cms.recorddrive.label.sftpPort" : "Puerto de servidor SFTP",
    "cms.report.field.agentLoginNumField" : "Agentes llamados",
    "cms.indexmonitor.message.totalTalkingNum" : "Número de agentes que procesan llamadas",
    "cms.agentMonitor.label.switch_success" : "El cambio es exitoso.",
    "cms.report.message.maxGarpMonth" : "Los datos de más de 12 meses no se pueden consultar",
    "cms.agentmonitor.label.showAlarmTime" : "Tiempo",
    "cms.report.title.vdnTrafficPeriodReport" : "Informe de tráfico de VDN basado en intervalos",
    "cms.indexmonitor.field.totalInCallCount" : "Llamadas entrantes en línea",
    "cms.callcenterinstanceslist.field.ccName" : "Nombre del centro de llamadas",
    "cms.fullscreen.field.SimHei" : "SimHei",
    "cms.callcenterinstanceslist.field.kafkaUserName" : "Nombre de usuario KAFKA",
    "cms.report.field.queryEndTime" : "Hora de finalización",
    "cms.indexmonitor.field.skillAgentAbandon" : "Llamadas colgadas por agentes",
    "cms.report.field.ringTime" : "Duración total del anillo",
    "cms.report.field.minAgentCount" : "Número mínimo de llamadas de agente simultáneas",
    "cms.report.field.acwTimeRateField" : "Tasa de duración de cierre",
    "cms.skilladjustment.field.seach" : "Buscar",
    "cms.datasource.message.oracleUrlTip" : "Cadena de conexión a base de datos: &#8220jdbc:oracle:thin:@ip:port:dbservicename&#8220&#10;ip (dirección IP) puerto (número de puerto) dbservicename (nombre de base de datos)",
    "cms.uapmonitor.labe.mentionnotice" : "2. Asegúrese de que el centro de llamadas virtual contenga inspectores y que los inspectores hayan configurado el número de softphone y la contraseña de inicio de sesión. Solo los agentes en estado anormal pueden cerrarse por la fuerza.",
    "cms.agentMonitor.label.search" : "Buscar",
    "cms.report.title.IVRMonitor" : "Monitoreo de datos IVR",
    "cms.indexmonitor.message.errorInfo" : "Seleccione al menos una cola de habilidades.",
    "cms.qualitycheck.field.selected" : "Seleccionado",
    "cms.recorddrive.field.storageLimit" : "Límite de almacenamiento más bajo (MB)",
    "cms.datasource.message.dbNameInput" : "Escriba un nombre de origen de datos.",
    "cms.indexmonitor.field.ivrCallNum" : "Solicitudes de llamada entrante de IVR",
    "cms.qcrelationconf.field.search" : "Consultar",
    "cms.report.field.playVoiceCount" : "Tiempos de reproducción de voz",
    "cms.sysparam.config.reset" : "Restablecer",
    "cms.report.field.threePartyCallCountField" : "Llamadas a tres partes",
    "cms.report.field.maxIVRInCountField" : "Número máximo de llamadas entrantes IVR simultáneas",
    "cms.skilladjustment.field.skillid" : "ID de cola de habilidades",
    "cms.report.title.agentTrend" : "Tendencia de resumen de rendimiento del agente",
    "cms.fullscreen.field.busyCountTimeDesc" : "Número de veces que los agentes están en estado ocupado.",
    "cms.report.label.export" : "Exportación",
    "cms.export.message.exceedlimit2" : ". Reduzca el intervalo de tiempo de los datos o añada criterios de filtro de datos.",
    "cms.report.message.minAgentOutCountDesc" : "Número mínimo de llamadas salientes simultáneas cuyo dispositivo de servicio es un agente.",
    "cms.export.message.exceedlimit1" : "Se puede exportar un máximo de 0 registros de datos. Reduzca el intervalo de tiempo de los datos o añada criterios de filtro de datos.",
    "cms.voiceinspection.field.videoPlay" : "Reproducción de vídeo",
    "cms.agentmonitor.label.talkingState" : "Hablar",
    "cms.calllink.field.connectionHold" : "Una llamada retenida está conectada.",
    "cms.report.field.loginCountField" : "Tiempos de inicio de sesión",
    "cms.report.field.manToIvrNumCtiField" : "Transferencias manuales a IVR",
    "cms.report.field.minAgentInCountField" : "Número mínimo de llamadas entrantes concurrentes del agente",
    "cms.callcenter.field.reset" : "Restablecer",
    "cms.uapmonitor.labe.interval" : "Período de supervisión",
    "cms.report.field.intelligentOutCall" : "Llamada saliente inteligente",
    "cms.report.field.inWaitAnsNumField" : "Llamadas entrantes manuales",
    "cms.indexmonitor.field.totalIdeaNum" : "Agentes en estado inactivo",
    "cms.callcenterinstanceslist.field.gwPort" : "Puerto de pasarela CC",
    "cms.report.field.playVoiceCountField" : "Tiempos de reproducción de voz",
    "cms.report.field.transferOutCountField" : "Tiempos de transferencia manual",
    "cms.uapmonitor.label.error" : "Error",
    "cms.systemparam.error.validate.notmatchrulealpha" : "Ingrese alfanuméricos o guiones bajos y comience solo con letras o guiones bajos",
    "cms.recorddrive.message.sftpIdentityChecktips" : "Se cambia la cuenta o la contraseña del servidor SFTP. Para mantener la seguridad de la información, ingrese su cuenta y contraseña actuales.",
    "cms.report.field.otherTimeRateField" : "Otra tasa de duración",
    "cms.monitordetail.field.signinskill" : "Cola de inicio de sesión",
    "cms.report.message.maxAgentInCountDesc" : "Número máximo de llamadas entrantes simultáneas cuyo dispositivo de servicio es un agente.",
    "cms.report.message.netEntIdDesc" : "ID de NE del servidor de medios que genera el CDR actual",
    "cms.callcenter.message.updatesuccess" : "Actualizado correctamente.",
    "cms.report.field.IVRCallOutNum" : "Llamadas salientes de IVR",
    "cms.report.message.minIVROutCountDesc" : "Número mínimo de llamadas salientes simultáneas cuyo dispositivo de servicio es el IVR.",
    "cms.qcrelationconf.title.error" : "Error",
    "cms.failurecall.message.currentFailureCall" : "Hay",
    "cms.report.field.avgCallInTimeField" : "Duración media de la llamada",
    "cms.indexmonitor.title.vdnNowMonitor" : "Estadísticas de monitoreo en tiempo real en el VDN durante la ejecución",
    "cms.agentMonitor.label.interceptNotice" : "Utilice esta función cuando el agente actual es escuchado, interrumpido o susurrado.",
    "cms.callcenterinstanceslist.field.ccBcsPort" : "Puerto CCBCS LB",
    "cms.report.message.callerNoDesc" : "Número de llamada",
    "cms.report.field.failAvgTimeField" : "Duración media del timbre de las llamadas perdidas",
    "cms.report.field.avgAgentOutCountField" : "Número medio de llamadas salientes concurrentes del agente",
    "cms.report.field.callSuccRate" : "Tasa de conexión de solicitud",
    "cms.report.message.succWaitAnsRateDesc" : "Duración de espera promedio de todas las llamadas conectadas en una cola.",
    "cms.report.field.onlineRate" : "Tarifa en línea",
    "cms.callcenter.field.deletefailure" : "Error de eliminación",
    "cms.report.field.stateField" : "estado de conexión",
    "cms.report.message.nomorethanerror" : "La fecha seleccionada no puede ser anterior a {0}.",
    "cms.report.field.avgHoldTimeField" : "Duración media de retención",
    "cms.qualitycheck.title.deleteSuccess" : "Supresión correcta",
    "cms.calllink.field.cancelQueuingTransfer" : "Se transfiere una llamada cuando se cancela la cola.",
    "cms.systemmonitor.title.error" : "Error",
    "cms.report.field.acwOutCount" : "Tiempos de recapitulación de llamadas salientes",
    "cms.indexmonitor.message.agentAbandonIn10NDesc" : "Número total de abandonos con una duración de respuesta superior o igual a 10 segundos",
    "cms.report.field.preDeviceNoField" : "ID de dispositivo del dispositivo anterior",
    "cms.systemmonitor.field.incallNums" : "Llamadas entrantes del sistema",
    "cms.systemmonitor.message.queryErrorInfo" : "Error de consulta.",
    "cms.datasource.title.modifyDatasource" : "Actualizar origen de datos",
    "cms.indexmonitor.field.inboundConnectRate" : "Llamadas entrantes conectadas",
    "cms.report.title.abandonRingReport" : "Llamada abandonada en reporte de tono",
    "cms.uapmonitor.field.registered" : "inscritos",
    "cms.reportsubscribe.field.suspended" : "No publicado",
    "cms.indexmonitor.message.skillAvgAbandonQueueDesc" : "Duración media de la cola de todas las llamadas sin respuesta en una cola",
    "cms.indexmonitor.field.callInIVR" : "IVR Online Llamadas entrantes",
    "cms.fullscreen.field.holdCountTimeDesc" : "Número de veces que los agentes están en estado de espera.",
    "cms.calllink.field.analysisNotConfig" : "El análisis del número llamado no está configurado para una llamada.",
    "cms.voiceinspection.video.tolong.info" : "Descarga el video grabado para reproducirlo porque es largo.",
    "cms.developerlist.title.refreshsk" : "Actualizar SK",
    "cms.calllink.title.caller" : "Número de llamada",
    "cms.report.field.onlineRateField" : "Tarifa en línea",
    "cms.indexmonitor.message.agentConnectedIn10NDesc" : "Número total de llamadas cuya duración de respuesta es mayor o igual a 10 segundos",
    "cms.reportsubscribe.field.sunday" : "Domingo",
    "cms.export.message.exception" : "Error al generar la tarea de exportación. Revisa los registros.",
    "cms.report.title.vdnSystemPerformanceReport" : "Informe de supervisión del rendimiento del sistema",
    "cms.calllink.title.callee" : "Número Llamado",
    "cms.callcenterinstanceslist.message.dsmodifyconfirm" : "Se ha cambiado la configuración del origen de datos. Reinicie el entorno para que la modificación surta efecto. ¿Estás seguro de que quieres modificarlo?",
    "cms.callcenter.message.deletesuccess" : "Se ha eliminado correctamente.",
    "cms.report.field.dualCallType" : "Un clic Dos llamadas",
    "cms.callcenter.message.configcallcenter" : "Configurar el centro de llamadas.",
    "cms.report.title.selectReportTime" : "Establecer la hora de inicio",
    "cms.report.field.manToSkillNumField" : "Transferencias de colas manuales a habilidades",
    "cms.report.message.playVoiceCountDesc" : "Número de tiempos de reproducción de voz.",
    "cms.indexmonitor.label.search" : "Buscar",
    "cms.systemmonitor.field.selectCcName" : "Seleccionar centro de llamadas",
    "cms.systemparam.error.validate.notmatchrule" : "El parámetro de entrada no coincide con la regla de verificación",
    "cms.report.message.agentInBoundDesc" : "Número de llamadas entrantes asignadas a colas de habilidades o agentes.",
    "cms.callcenterinstanceslist.message.clearKafkaConfig" : "¿Está seguro de que desea eliminar la configuración de nodos KAFKA?",
    "cms.report.field.auxTimeRate" : "Duración del descanso",
    "cms.developerlist.message.refreshfailed" : "Error al actualizar el SK.",
    "cms.fullscreen.message.numberSecondMin" : "El valor mínimo es 720",
    "cms.indexmonitor.field.totalLoginNum" : "Agentes de inicio de sesión",
    "cms.agentMonitor.label.beinidle_error" : "El agente inspeccionado está inactivo.",
    "cms.export.field.success" : "Éxito",
    "cms.report.message.agentReleaseTimeDesc" : "Hora de publicación de llamada.",
    "cms.report.field.agentInSuccRateField" : "Tasa de conexión manual de llamadas entrantes",
    "cms.qualitycheck.label.editMember" : "Editar miembro del grupo",
    "cms.report.field.fifteenminutes" : "15 minutos",
    "cms.fullscreen.message.errorMonitorMethod" : "Seleccione un modo de monitorización.",
    "cms.qualitycheck.label.createusergroup" : "Crear UserGroup",
    "cms.record-playback.callType3" : "Llamada saliente de OBS",
    "cms.report.message.abortQueueWaitNumDesc" : "Número de llamadas abandonadas proactivamente por los suscriptores durante la cola y el timbre.",
    "cms.report.field.avgQueueOutCount" : "Número promedio de llamadas salientes simultáneas en una cola",
    "cms.systemparam.error.validate.notmatchrulemax" : "El parámetro de entrada excede el máximo",
    "cms.indexmonitor.field.cancel" : "Cancelar",
    "cms.indexmonitor.field.outboundConnectRate" : "Llamadas salientes conectadas",
    "cms.indexmonitor.field.outboundAbandonRate": "Llamadas salientes abandonadas",
    "cms.callcenterinstanceslist.field.vdnName" : "Nombre de VDN",
    "cms.uapmonitor.field.socketNormal" : "normal",
    "cms.report.field.avgCallInTime" : "Duración promedio de llamadas",
    "cms.synchronize.message.noConfConfPwd" : "La contraseña no está configurada. Configure la contraseña de la interfaz.",
    "cms.report.field.startAgentId" : "Iniciar ID de agente",
    "cms.report.field.avgQueueCountField" : "Número promedio de llamadas simultáneas en una cola",
    "cms.report.field.auxCount" : "Tiempos de descanso",
    "cms.uapmonitor.labe.inspectorcheckinnum" : "Número de inicio de sesión del inspector de control de calidad",
    "cms.report.field.avgQueueInCount" : "Número promedio de llamadas entrantes simultáneas en una cola",
    "cms.systemparam.error.validate.notmatchrulerange" : "El parámetro de entrada excede el valor máximo o está por debajo del valor mínimo",
    "cms.report.message.transferIvrCountDesc" : "Número de tiempos de transferencia manual a IVR.",
    "cms.callcenterinstanceslist.message.wasPsdLengthRangeValidateFailed" : "La longitud de la contraseña debe estar entre 16 y 32",
    "cms.indexmonitor.field.skillAbandonIn10Rate" : "Llamadas abandonadas en 10s",
    "cms.indexmonitor.field.skillAbandonIn20Rate" : "Llamadas abandonadas en 20s",
    "cms.indexmonitor.message.skillAvgConnectedDesc" : "Duración promedio de la llamada. Duración promedio de la llamada entrante manual = Duartion manual de la llamada entrante/Número de llamadas entrantes conectadas manualmente.",
    "cms.monitordetail.field.mediatype" : "Tipo de medio de llamada",
    "cms.agentMonitor.label.beinbusy_error" : "El agente inspeccionado está ocupado.",
    "cms.qualitycheck.title.saveSuccess" : "Éxito",
    "cms.sysparam.config.itemdesc" : "Descripcion",
    "cms.report.message.manTransferOutNumCtiDesc" : "Número de veces que el agente transfiere al IVR y luego transfiere hacia fuera.",
    "cms.developerlist.field.ak" : "Desarrollador AK",
    "cms.fullscreen.message.numberFirstMin" : "El valor mínimo es 1280",
    "cms.datasource.title.addDatasource" : "Agregar origen de datos",
    "cms.vcallecenter.message.modifyadminpasswordsuccess" : "Modifique la contraseña correctamente.",
    "cms.qcrelationconf.placeholder.groupName" : "Nombre del grupo",
    "cms.calllink.title.agentId" : "ID de agente conectado",
    "cms.indexmonitor.field.outboundConnectNum" : "Llamadas salientes conectadas",
    "cms.reportsubscribe.field.saturday" : "Sábado",
    "cms.indexmonitor.message.inboundConnectRate" : "Porcentaje del número de llamadas entrantes conectadas en el número de llamadas entrantes.",
    "cms.agentmonitor.label.qualityInspection" : "Inspección de calidad",
    "cms.indexmonitor.field.skillConnectedIn60Rate" : "Llamadas conectadas en 60s",
    "cms.qcrelationconf.message.addSuccess" : "Añadir correctamente",
    "cms.failurecall.field.callSequenceNumber" : "Llamada S/N",
    "cms.report.field.inCallFailNum" : "Solicitudes de llamada perdida",
    "cms.report.field.submediatype.email" : "Email",
    "cms.report.field.IVRCallOutSuccRate" : "Tasa de conexión de llamadas salientes IVR",
    "cms.qcrelationconf.field.qcCcRoleName" : "Función de inspector",
    "cms.indexmonitor.field.chooseAll" : "Seleccionar todo",
    "cms.report.message.outSuccAnsRateDesc" : "Porcentaje del número de llamadas salientes conectadas en el número de llamadas salientes.",
    "cms.export.message.addExportTaskError" : "Error en la nueva tarea de exportación, compruebe el registro",
    "cms.agentmonitor.label.haveAlarm" : "Alarma",
    "cms.calllink.title.callBeginTime" : "Hora de inicio de la llamada",
    "cms.sysparam.config.opterate" : "Operación",
    "cms.agentmonitor.label.idle" : "Fuerza en vacío",
    "cms.report.field.ivrtrafficreport.ivrinbound" : "Número de ocupación IVR",
    "cms.report.field.perAgentNum" : "Llamadas de agente",
    "cms.report.message.auxCountDesc" : "Número de veces que un agente entra en el estado de reposo.",
    "cms.indexmonitor.field.callOutIVR" : "Llamadas salientes en línea de IVR",
    "cms.agentmonitor.label.preoccupationState" : "preocupados",
    "cms.calllink.field.threePartyCall" : "La llamada es una llamada de tres partes.",
    "cms.report.message.talkingTimeDesc" : "Tiempo de conversación de llamada.",
    "cms.systemmonitor.field.time" : "Estadísticas Recopiladas",
    "cms.report.field.acdTimeField" : "Duración (es) de la llamada manual",
    "cms.report.title.skillAccessReport" : "Reporte de tráfico de colas de espera de llamadas por cód de acceso",
    "cms.callcenter.field.cancel" : "Cancelar",
    "cms.uapmonitor.labe.qcworkno" : "ID de empleado de control de calidad",
    "cms.report.field.threePartyCallCountDesc" : "Número de llamadas de tres partes de un agente.",
    "cms.voiceinspection.message.noRecordFound" : "No se encuentra ninguna grabación coincidente.",
    "cms.report.field.muteTimeField" : "Duración total del silencio",
    "cms.report.message.timeCheckError" : "El intervalo de tiempo no puede exceder de siete días.",
    "cms.indexmonitor.field.skillAvgConnectedQueue" : "Duración media de la cola de las llamadas conectadas",
    "cms.report.message.billInfo2Desc" : "ID único de llamada dual",
    "cms.failurecall.field.reset" : "Restablecer",
    "cms.indexmonitor.field.ivrInboundConnectRate" : "Llamadas entrantes de IVR conectadas",
    "cms.report.message.availTimeDesc" : "Duración total cuando un agente está en estado inactivo.",
    "cms.voiceinspection.field.operat" : "Operación",
    "cms.voiceinspection.field.predictOutbound" : "Salida predictiva",
    "cms.fullscreen.field.totalCallTime" : "Duración total de la llamada",
    "cms.callcenterinstanceslist.field.ctiIndexMonitor" : "Monitorización del indicador del sistema CTI",
    "cms.indexmonitor.message.totalInCallCount" : "Número total de llamadas entrantes.",
    "cms.fullscreen.field.monitorType" : "Tipo de indicador",
    "cms.indexmonitor.field.skillAbandonIn5Rate" : "Llamadas abandonadas en 5s",
    "cms.sendnote.title.tips" : "Información de contacto",
    "cms.indexmonitor.field.callRate" : "Tarifa de llamadas",
    "cms.sysparam.config.item.detail" : "Información de parámetros",
    "cms.skilladjustment.message.notMatchAgent" : "El tipo de cola de habilidades ajustado no coincide con el tipo de agente.",
    "cms.sendnote.field.notecontent" : "Contenido",
    "cms.report.message.dategt12weeks" : "El intervalo de semanas no puede exceder las 12 semanas.",
    "cms.qcrelationconf.field.agentCcRoleName" : "Papel de fiesta inspeccionado",
    "cms.agentMonitor.label.cancelInsert_error" : "Error al cancelar la inserción.",
    "cms.indexmonitor.message.ivrCallNum" : "Número de llamadas para las que los dispositivos IVR proporcionan servicios automáticos",
    "cms.report.message.otherTimeDesc" : "Duración total cuando un agente está en otro estado, o duración de llamada de otras habilidades cuando existen múltiples habilidades.",
    "cms.report.field.outCallTime" : "Duración total de la llamada saliente",
    "cms.callcenterinstanceslist.field.pool" : "Modo de piscina",
    "cms.developerlist.field.search" : "Buscar",
    "cms.systemparam.error.validate.notmatchruleemail" : "Por favor, introduzca una dirección de correo electrónico válida",
    "cms.indexmonitor.message.skillIdMaxSize" : "Se puede seleccionar un máximo de cinco colas de habilidades.",
    "cms.report.message.dategt1year" : "El rango de meses no puede exceder los 12 meses.",
    "cms.report.field.minAgentOutCountField" : "Número mínimo de llamadas salientes concurrentes del agente",
    "cms.report.field.callSuccRateField" : "Tasa de solicitud de conexión",
    "cms.uapmonitor.field.Logout" : "Inicio de sesión",
    "cms.indexmonitor.message.agentInboundConnectNum" : "Número de llamadas entrantes manuales contestadas por los agentes",
    "cms.agentmonitor.label.noAlarm" : "Sin alarma",
    "cms.indexmonitor.message.agentConnectInThirty" : "Porcentaje del número de llamadas conectadas manualmente dentro de 30 segundos en el número total de llamadas conectadas manualmente.",
    "cms.qcrelationconf.field.agentGroup" : "Grupo de Agente",
    "cms.indexmonitor.field.skillAbandonAfter60" : "Llamadas abandonadas después de los 60",
    "cms.export.field.refresh" : "Refrescar",
    "cms.report.field.avgIVRInCountField" : "Número medio de llamadas entrantes IVR simultáneas",
    "cms.datasource.field.backupDbName" : "Origen de datos de copia de seguridad",
    "cms.indexmonitor.message.outboundConnectNum" : "Número de llamadas salientes manuales conectadas + Número de llamadas salientes IVR conectadas",
    "cms.report.message.minIVRCountDesc" : "Número mínimo de llamadas simultáneas cuyo dispositivo de servicio es el IVR.",
    "cms.report.field.busyTimeField" : "Duración total de la actividad",
    "cms.fullscreen.field.bold" : "Negrita",
    "cms.vcallecenter.title.authentication" : "Verificar identidad",
    "cms.recorddrive.field.configSftpAccountConfirmPwd" : "Confirmar contraseña del servidor SFTP",
    "cms.indexmonitor.label.reset" : "Restablecer",
    "cms.callcenterinstanceslist.message.updateCtiVersion2" : "Compruebe si se ha completado la migración de datos de CTI. Tenga cuidado al realizar esta operación. (Después de confirmar la operación, la función de sincronización automática está deshabilitada.)",
    "cms.skilladjustment.field.inputskill" : "Introduzca un nombre de cola de habilidades.",
    "cms.report.title.callCenterReport" : "Reporte de tráfico VDN",
    "cms.qcrelationconf.field.operator" : "Operador",
    "cms.report.field.noAckNumField" : "Llamadas sin respuesta",
    "cms.agentmonitor.label.showAlarmEmotionType" : "Tipo Emocional",
    "cms.report.message.inAvgCallTimeDesc" : "Duración promedio de la llamada. Duración promedio de las llamadas entrantes manuales conectadas = Duración de las llamadas entrantes manuales conectadas/Número de llamadas entrantes manuales conectadas.",
    "cms.report.field.agentAlertingTimeField" : "Hora de alerta de llamada",
    "cms.agentmonitor.label.answering" : "Contestando",
    "cms.qcrelationconf.field.role" : "Rol",
    "cms.voiceinspection.field.webphoneVideo" : "Videollamada de clic para marcar",
    "cms.report.field.muteTime" : "Duración total del silencio",
    "cms.report.field.outAvgRingTimeField" : "Duración promedio del timbre de las llamadas salientes manuales",
    "cms.report.field.avgHandleTime" : "Duración promedio de procesamiento",
    "cms.report.field.maxAgentOutCountField" : "Número máximo de llamadas salientes concurrentes del agente",
    "cms.indexmonitor.message.totalAnsweringNum" : "Número de agentes cuyos teléfonos están sonando",
    "cms.fullscreen.message.playTheme" : "Este asunto ha sido reproducido.",
    "cms.fullscreen.field.yes" : "Sí",
    "cms.indexmonitor.message.skillTimeoutQueueDesc" : "Número de llamadas transferidas desde la cola de habilidades original a otras colas de habilidades debido al tiempo de espera de la cola",
    "cms.report.field.mediatype.clickvediocall" : "Video clic para marcar",
    "cms.sysparam.config.itemname.tips" : "Introduzca un nombre de parámetro.",
    "cms.qualitycheck.label.view" : "Vista",
    "cms.agentMonitor.label.error" : "Error",
    "cms.vcallecenter.field.adminworkno" : "ID de administrador",
    "cms.reportsubscribe.field.subscribeContent" : "Suscribir contenido",
    "cms.voiceinspection.field.callType" : "Tipo de llamada",
    "cms.agentmonitor.label.accountCode" : "Ingrese una cuenta de servicio",
    "cms.indexmonitor.message.agentOutCallCount" : "Número de llamadas salientes realizadas por el agente actual.",
    "cms.developerlist.message.deletefailed" : "Error en la eliminación.",
    "cms.reportsubscribe.field.thursday" : "Jueves",
    "cms.report.field.acwTimeField" : "Duración total (es)",
    "cms.report.field.callerNoField" : "Número de llamada",
    "cms.indexmonitor.field.skillOverQueue" : "Llamadas de desbordamiento a colas",
    "cms.report.field.availTimeField" : "Duración (es) de inactividad",
    "cms.indexmonitor.message.mediaTypeMetion" : "El elemento de filtro de tipo de medio sólo es válido para los KPI del día actual y la tendencia del día actual.",
    "cms.agentmonitor.label.occupationState" : "Ocupado",
    "cms.report.field.minIVRInCount" : "Número mínimo de llamadas entrantes de IVR simultáneas",
    "cms.synchronize.message.invalidAdminPwd" : "Contraseña incorrecta. Cambie la contraseña del administrador.",
    "cms.vcallecenter.title.success" : "Exitoso",
    "cms.agentMonitor.label.forceidle_success" : "Un agente se forzó al estado inactivo con éxito.",
    "cms.report.message.currentDateCheck" : "La hora seleccionada debe ser anterior al día actual.",
    "cms.vcallcenter.message.unknowsolution" : "Póngase en contacto con los ingenieros de Huawei para el análisis y la solución de problemas",
    "cms.vcallecenter.message.modifyadminpasswordfail" : "Error al cambiar la contraseña.",
    "cms.report.field.onlyIVRServiceField" : "Llamadas entrantes de pura IVR exitosas",
    "cms.monitordetail.field.account" : "Cuenta",
    "cms.report.field.agentInBound" : "Llamadas entrantes manuales",
    "cms.report.field.assistsCount" : "Solicitudes de ayuda interna",
    "cms.callcenterinstanceslist.field.cmsIp" : "Dirección IP de CMS LB",
    "cms.systemmonitor.field.agentOnlineCount" : "Números de Softphone Registrados",
    "cms.datasource.field.dbServiceType" : "Tipo de Negocio",
    "cms.indexmonitor.message.agentConnectedOut60NDesc" : "Número total de llamadas conectadas cuya duración de respuesta es superior a 60 segundos.",
    "cms.report.field.avgPerHNum" : "Promedio de llamadas exitosas por hora",
    "cms.indexmonitor.field.inboundCallNum" : "Llamadas entrantes",
    "cms.report.field.statInterval" : "Periodo de tiempo",
    "cms.indexmonitor.message.totalCallNum" : "Número de llamadas entrantes + Número de llamadas salientes",
    "cms.uapmonitor.field.socketFault" : "fallo",
    "cms.agentMonitor.label.insets_success" : "La inserción se realizó correctamente.",
    "cms.vcallecenter.message.currentacountpasderror" : "La contraseña de la cuenta actual es incorrecta.",
    "cms.indexmonitor.field.skillAvgQueueAck" : "Duración promedio en espera",
    "cms.report.message.transferOutCountDesc" : "Número de tiempos de transferencia manual.",
    "cms.qcrelationconf.field.group" : "Grupo",
    "cms.qcrelationconf.message.queryParamNull" : "El id del agente y el nombre del grupo de usuarios no pueden estar vacíos al mismo tiempo",
    "cms.agentMonitor.label.switch_error" : "El switchover ha fallado.",
    "cms.report.message.agentServiceRateDesc" : "Porcentaje del número de llamadas contestadas por el agente en el número total de llamadas entrantes. Tasa de éxito manual en el total de llamadas entrantes = Número de llamadas contestadas por el agente/ (Número de llamadas entrantes IVR exitosas + Número de llamadas contestadas por el agente) x 100",
    "cms.report.field.auxTimeField" : "Duración total del descanso",
    "cms.reportsubscribe.field.subscribeName" : "Nombre de suscripción",
    "cms.report.field.outAvgAnsTimeField" : "Duración  promedio del timbre de las llamadas salientes",
    "cms.export.field.exportBeginTime" : "Exportar hora de inicio",
    "cms.callcenterinstanceslist.field.faultStatus" : "Defectuosa",
    "cms.developerlist.message.error" : "Error",
    "cms.report.message.IVRServiceRateDesc" : "Porcentaje del número de llamadas procesadas solo por el IVR en el número total de llamadas entrantes. Tasa de éxito de llamadas entrantes de Pure-IVR = llamadas entrantes de Pure-IVR exitosas/ (llamadas entrantes de IVR puras exitosas + Número de llamadas contestadas por el agente) x 100.",
    "cms.agentMonitor.label.cancelwhisper_error" : "Error al cancelar el susurro.",
    "cms.qualitycheck.label.cancel" : "Cancelar",
    "cms.systemmonitor.label.search" : "Buscar",
    "cms.indexmonitor.title.filteringIndicator" : "Indicador de filtro",
    "cms.report.field.byMonth" : "Informe mensual",
    "cms.report.message.availTimeRateDesc" : "Porcentaje de la duración total de inactividad de un agente en la duración total del trabajo.",
    "cms.recorddrive.title.recorddrivenameconfig" : "Configuración de volumen",
    "cms.report.field.acwOutTime" : "Duración de resumen de llamadas salientes",
    "cms.report.title.callCenterTrend" : "Tendencia de rendimiento de VDN",
    "cms.qcrelationconf.field.qcGroup" : "Grupo de comprobación de calidad",
    "cms.datasource.field.yes" : "Sí",
    "cms.indexmonitor.message.updateSuccess" : "Actualización exitosa",
    "cms.report.message.timeRangeCheck" : "El intervalo de tiempo no puede exceder de 1 día.",
    "cms.report.field.call3Rate" : "Tasa de conexión manual de llamadas entrantes en 15s",
    "cms.callcenterinstanceslist.message.kafkaNodeInfo" : "Nodo KAFKA",
    "cms.fullscreen.message.addThemeError" : "El tema no se guardó. ¿Está seguro de que desea añadir un asunto?",
    "cms.agentMonitor.label.been_inspected" : "El agente está siendo inspeccionado. Inténtalo de nuevo más tarde.",
    "cms.agentmonitor.label.cancelfail" : "Error en la cancelación.",
    "cms.report.message.noData" : "No hay datos disponibles.",
    "cms.indexmonitor.field.skillAbandonAfter10" : "Llamadas abandonadas después de 10 segundos",
    "cms.monitordetail.field.role" : "Función de agente",
    "cms.systemmonitor.field.cancel" : "Cancelar",
    "cms.datasource.label.create" : "Añadir",
    "cms.indexmonitor.message.skillAbandonAfterNDesc" : "Número de solicitudes de llamada abandonadas manualmente después de Ns. Actualmente, los Ns pueden ser 10s o 60s.",
    "cms.voiceinspection.field.search" : "Consultar",
    "cms.uapmonitor.label.registererror" : "Error de cierre de sesión",
    "cms.report.field.systemInSuccField" : "Llamadas entrantes del sistema exitosas",
    "cms.indexmonitor.field.indicatorName" : "Indicador",
    "cms.voiceinspection.field.callTime" : "Duración (es) de la grabación",
    "cms.callcenterinstanceslist.field.ccBmsIp" : "Dirección IP de CCBMS LB",
    "cms.report.field.auxCountField" : "Tiempos de descanso",
    "cms.indexmonitor.message.outboundCallNum" : "Número de llamadas salientes manuales + Número de llamadas salientes IVR",
    "cms.vcallecenter.message.syncagentfail" : "Error al sincronizar el agente.",
    "cms.developerlist.field.updateTime" : "Modificado en",
    "cms.callcenterinstanceslist.field.sysAdminPassword" : "Contraseña del administrador de CTI",
    "cms.report.field.outVdnAvgTimeField" : "Duración promedio de las llamadas salientes en VDN",
    "cms.calllink.msg.dateTooLate" : "La fecha seleccionada no puede ser posterior a {0}.",
    "cms.indexmonitor.message.skillConnectedDesc" : "Número de llamadas conectadas a agentes en la cola de habilidades.",
    "cms.callcenterinstanceslist.label.search" : "Consultar",
    "cms.uapmonitor.field.socketUninstall" : "desinstalar",
    "cms.report.field.callLostOtherNum" : "Otras llamadas perdidas",
    "cms.report.message.threePartyCallCountDesc" : "Número de llamadas de tres",
    "cms.voiceinspection.field.queryEndTime" : "Hora de finalización",
    "cms.callcenterinstanceslist.field.ccBcsIp" : "Dirección IP de CCBCS LB",
    "cms.report.field.inAvgCallTime" : "Duración promedio de llamadas",
    "cms.report.field.outOccupyNumField" : "Llamadas salientes",
    "cms.systemparam.error.validate.notmatchrulerequired" : "Requerido",
    "cms.agentmonitor.label.agentId" : "ID de agente",
    "cms.uapmonitor.message.eidvalidate" : "Introduzca los parámetros según se le solicite.",
    "cms.voiceinspection.field.serviceId" : "Tipo de Negocio",
    "cms.fullscreen.field.second" : "(s)",
    "cms.report.field.netEntIdField" : "ID de NE del servidor de medios",
    "cms.report.field.description" : "Descripción",
    "cms.reportsubscribe.message.searchSubscribeName" : "Por favor, introduzca el nombre de suscripción",
    "cms.report.title.ivrOutboundMonitor" : "Monitoreo de llamadas salientes IVR",
    "cms.qualitycheck.message.deleteinfo" : "Error en la eliminación",
    "cms.report.message.obsServiceIdDesc" : "ID de una campaña de llamadas salientes",
    "cms.agentMonitor.label.forcerelease_success" : "Una llamada es liberada a la fuerza con éxito.",
    "cms.indexmonitor.message.skillOnlineWaitCallDesc" : "Número de llamadas que están en cola.",
    "cms.report.message.outFailNumDesc" : "Número total de llamadas salientes fallidas.",
    "cms.qcrelationconf.field.agentAccountName" : "Nombre del partido inspeccionado",
    "cms.report.field.failAnsRateField" : "Tasa de pérdida de solicitudes",
    "cms.indexmonitor.field.skillConnectedIn10Rate" : "Llamadas conectadas en 10s",
    "cms.indexmonitor.field.skillConnectedIn20Rate" : "Llamadas conectadas en 20s",
    "cms.calllink.field.pickup" : "Una llamada es captada por otros.",
    "cms.report.field.acwInCountField" : "Tiempos de recapitulación de llamadas entrantes",
    "cms.agentMonitor.label.talking_error" : "El agente inspeccionado está en el estado de conversación.",
    "cms.sysparam.config.reset.multiple.error" : "No se pueden restablecer los siguientes parámetros:",
    "cms.callcenterinstanceslist.field.dvDataReport" : "Informes de datos de DigitalView",
    "cms.indexmonitor.message.inboundConnectNum" : "Número de llamadas entrantes manuales conectadas + Número de llamadas entrantes IVR conectadas",
    "cms.calllink.title.beginTime" : "Hora de inicio de las estadísticas",
    "cms.indexmonitor.label.callCenterNode" : "Nodo del centro de llamadas",
    "cms.agentmonitor.label.adjust" : "Recapitulación",
    "cms.report.field.endWeek" : "EndWeek",
    "cms.qcrelationconf.field.remark" : "Comentario",
    "cms.report.message.custHangNumCtiDesc" : "Número de clientes pendientes.",
    "cms.reportsubscribe.field.friday" : "Viernes",
    "cms.indexmonitor.field.ivrOutboundNum" : "Llamadas salientes de IVR conectadas",
    "cms.report.field.avgAgentInCountField" : "Número medio de llamadas entrantes de agentes simultáneos",
    "cms.report.field.assistTimeField" : "Duración (es) de la solicitud de ayuda interna",
    "cms.voiceinspection.title.tips" : "Información",
    "cms.callcenterinstanceslist.label.nextStep" : "Siguiente",
    "cms.indexmonitor.message.ivrDurationAvgConnect" : "Duración total de las llamadas IVR/Número de llamadas IVR",
    "cms.report.title.vdnTrafficPeriodTrend" : "Tendencia del período de tráfico de VDN",
    "cms.developerlist.message.createfailed" : "Error al crear el programador.",
    "cms.report.field.outCallSuccRate" : "Tasa de conexión de llamadas salientes",
    "cms.report.title.callCenterMonitor" : "Monitoreo del rendimiento de VDN",
    "cms.datasource.message.dmUrlTip" : "Cadena de conexión a base de datos: &#8220jdbc:dm://ip:port/dbservicename&#8220&#10;ip (dirección IP) puerto (puerto) dbservicename (nombre de la base de datos)",
    "cms.callcenterinstanceslist.message.ivrNumsError" : "El número de canales IVR debe ser mayor que la suma del número de canales TTS y el número de canales ASR.",
    "cms.qcrelationconf.field.delete" : "Borrar",
    "cms.reportsubscribe.message.reportNumberError" : "Todos los informes han sido añadidos",
    "cms.report.message.maxAgentCountDesc" : "Número máximo de llamadas simultáneas cuyo dispositivo de servicio es un agente.",
    "cms.report.field.selectAgent" : "Seleccionar agente",
    "cms.vdnconfiguration.message.updatefailed" : "Error al actualizar.",
    "cms.agentmonitor.label.pageRefreshTime" : "Datos actualizados",
    "cms.fullscreen.field.report1" : "Informe diario (hora fijada)",
    "cms.indexmonitor.field.confirm" : "Confirmar",
    "cms.report.message.occupancyRateDesc" : "Tasa de duración del trabajo = (Duración total del trabajo – Duración del descanso)/Duración total del trabajo x 100",
    "cms.fullscreen.field.report0" : "Informe diario (tiempo relativo)",
    "cms.fullscreen.message.publicTheme" : "(Público)",
    "cms.report.message.avgInRingTimeDesc" : "Duración media de la llamada entrante a través de terminales de agente.",
    "cms.report.field.outCallSuccNum" : "Llamadas salientes manuales conectadas",
    "cms.sendnote.field.ok" : "OK",
    "cms.report.field.minIVROutCountField" : "Número mínimo de llamadas salientes IVR simultáneas",
    "cms.synchronize.message.syncStatusOne" : "Sincronizando",
    "cms.datasource.message.isDeleteDatasource" : "¿Está seguro de que desea eliminar el origen de datos?",
    "cms.fullscreen.message.selectMessage" : "- Seleccionar-",
    "cms.report.field.systemInBound" : "Llamadas entrantes",
    "cms.record-playback.callType0" : "Llamada de voz entrante",
    "cms.fullscreen.field.moveup" : "Arriba",
    "cms.failurecall.placeholder.accessCodeInput" : "Introduzca un código de acceso",
    "cms.developerlist.title.deleteconfim" : "Confirmar",
    "cms.callcenterinstanceslist.field.developerDesc" : "Descripción",
    "cms.voiceinspection.field.callId" : "Grabación SN",
    "cms.agentMonitor.label.qcSipNum" : "Establecer el número de inicio de sesión del usuario actual",
    "cms.reportsubscribe.title.modifyConfim" : "Modificar confirmación",
    "cms.calllink.field.virtualDevice" : "Dispositivo virtual",
    "cms.report.field.outAvgCallTimeField" : "Duración promedio de llamadas salientes manuales",
    "cms.report.field.workRateField" : "Tasa de duración del trabajo",
    "cms.report.field.avgAcdTimeField" : "Duración promedio de las llamadas manuales",
    "cms.monitordetail.field.conferencecount" : "Llamadas de tres partes",
    "cms.report.field.callInCauseField" : "Motivo de entrada de llamada",
    "cms.tenant.message.tenantspaceOk" : "OK",
    "cms.report.message.callStartTimeDesc" : "Hora de inicio de llamada.",
    "cms.calllink.field.intercepts" : "Una llamada es interceptada.",
    "cms.agentMonitor.label.permission_error" : "Usted no tiene la autoridad.",
    "cms.qcrelationconf.field.reset" : "Restablecer",
    "cms.datasource.message.gaussUrlTip" : "Cadena de conexión a base de datos: &#8220jdbc:zenith:@ip:port&#8220&#10;ip (dirección IP) puerto (número de puerto)",
    "cms.fullscreen.field.no" : "No",
    "cms.indexmonitor.message.SkillAbandonRateDesc" : "Tasa de llamadas perdidas = 100 Tasa de llamadas conectadas –",
    "cms.report.message.callIdDesc" : "Número de secuencia de llamada, que identifica de forma única una llamada",
    "cms.monitordetail.field.rest" : "Descanso",
    "cms.report.field.outInvalidCallField" : "Llamadas salientes no válidas",
    "cms.fullscreen.field.richtext" : "Texto enriquecido",
    "cms.monitordetail.field.totaltalkingcount" : "Número total de llamadas",
    "cms.report.message.callEndDesc" : "Hora de finalización de la llamada",
    "cms.synchronize.message.configured" : "Configurado",
    "cms.report.field.transferOutCount" : "Tiempos de transferencia manual",
    "cms.agentMonitor.label.updateSip_repeat" : "El número ha sido asignado.",
    "cms.agentmonitor.label.terminalStatusRefresh" : "Estado de actualización",
    "cms.report.message.waitAnsRateDesc" : "Duración promedio del timbre del terminal de un agente. Duración promedio del timbre de las llamadas entrantes manuales = Duración total del timbre de las llamadas entrantes manuales/Número de tiempos de respuesta.",
    "cms.recorddrive.field.configSftpAccount" : "Cuenta SFTP",
    "cms.indexmonitor.title.skillDayMonitor" : "Estadísticas de hoy sobre indicadores de la cola de habilidades",
    "cms.indexmonitor.title.skillQueueStatistics" : "Estadísticas de la cola de habilidades en el día actual",
    "cms.report.message.agentInSuccRateDesc" : "Porcentaje de llamadas entrantes manuales conectadas al total de llamadas entrantes manuales.",
    "cms.report.field.inOccupyNum" : "Solicitudes de servicio",
    "cms.callcenterinstanceslist.title.chooseFeature" : "Selección de funciones",
    "cms.report.field.otherTimeField" : "Duración total de otros estados",
    "cms.voiceinspection.message.downloadVoiceRecordFailed" : "Error al descargar el archivo de grabación del servidor SFTP.",
    "cms.agent.message.duplicatepwd" : "La nueva contraseña es la misma que la actual.",
    "cms.sysparam.config.select.null" : "Seleccione un parámetro.",
    "cms.vcallcenter.message.unknowdesc" : "Excepción desconocida",
    "cms.agentmonitor.label.showAlarmContent" : "Contenido del cuadro de diálogo",
    "cms.report.message.utilizationRateDesc" : "Tasa de duración del estado del trabajo = (Duración total de la llamada + Duración total del cierre) / (Duración total del trabajo – Duración del descanso) x 100",
    "cms.report.field.outFailNumField" : "Total de llamadas salientes fallidas",
    "cms.report.field.agentService" : "Llamadas contestadas por el agente",
    "cms.report.message.maxQueueWaitTimeDesc" : "Duración máxima de espera (en cola y duración de timbre) de todas las llamadas.",
    "cms.report.message.calleeNoLength" : "Introduzca el código de acceso al menos tres dígitos.",
    "cms.report.field.ivrtrafficreport.totalivrservice" : "Tiempo total de servicio de IVR",
    "cms.report.field.workRate" : "Tasa de duración del trabajo",
    "cms.indexmonitor.message.agentConnectedIn3NDesc" : "Número total de llamadas cuya duración de respuesta es mayor o igual a 3 segundos",
    "cms.report.title.skillAccessMonitor" : "Monitoreo de estadísticas sobre el rendimiento de la cola de habilidades por código de acceso",
    "cms.indexmonitor.field.totalAnsweringNum" : "Agentes en estado de respuesta",
    "cms.vdnconfiguration.title.error" : "Error",
    "cms.systemmonitor.field.confirm" : "Confirmar",
    "cms.systemmonitor.field.ofcNo" : "No de entrada.",
    "cms.skilladjustment.field.skillname" : "Nombre de la cola de habilidades",
    "cms.fullscreen.field.themeType" : "Tipo de tema",
    "cms.fullscreen.field.delete" : "Borrar",
    "cms.indexmonitor.field.skillAbandonIn30Rate" : "Llamadas abandonadas en 30 segundos",
    "cms.report.field.maxAgentInCountField" : "Número máximo de llamadas entrantes concurrentes del agente",
    "cms.indexmonitor.field.abandonInTen" : "Llamadas abandonadas en 10s",
    "cms.report.field.submediatype.line" : "LINE",
    "cms.fullscreen.field.halfHour" : "30 minutos",
    "cms.monitordetail.field.outboundcount" : "Llamadas salientes exitosas",
    "cms.datasource.message.updatefailed" : "Error al actualizar.",
    "cms.report.message.call3RateDesc" : "Porcentaje del número de solicitudes de llamada conectadas manualmente en 15 segundos en el número total de llamadas que solicitan servicios manuales.",
    "cms.indexmonitor.field.status" : "Estado",
    "cms.record-playback.voice.playing" : "Reproducir",
    "cms.record-playback.voice.pause" : "Suspensión",
    "cms.report.message.monthLessThan" : "El mes de fin de año no puede ser anterior al mes del año de inicio.",
    "cms.agentMonitor.label.listen_success" : "La escucha es exitosa.",
    "cms.indexmonitor.field.totalRestNum" : "Agentes en estado de descanso",
    "cms.fullscreen.field.nowMonitor" : "Monitorización en tiempo real durante la ejecución",
    "cms.indexmonitor.field.agentOutboundNum" : "Llamadas salientes manuales",
    "cms.indexmonitor.message.callOutSuccNum" : "Número de veces que un agente conecta llamadas salientes.",
    "cms.title.select" : "Seleccionar",
    "cms.report.field.avgAcwTimeField" : "Duración promedio",
    "cms.report.field.outWaitAnsTimeField" : "Duración total del timbre de las llamadas salientes",
    "cms.callcenterinstanceslist.message.nodeIdCheckError" : "Asegúrese de que el ID de información de nodo CTI introducido, uapId y nombre son únicos.",
    "cms.export.field.selectFileType" : "Selección de un formato de archivo de exportación",
    "cms.fullscreen.field.reportDates" : "Hace {0} días",
    "cms.agentmonitor.label.autoRefresh" : "Actualización automática",
    "cms.export.message.popwindowmessage" : "Contraseña para generar un archivo comprimido. El valor es una cadena de 8 a 12 caracteres y debe contener letras, dígitos y caracteres especiales (excluyendo espacios de {'|;&$-<>/'}{'\\\\'}{'`!#(){}'}, y caracteres de tabulación).",
    "cms.report.field.minAgentOutCount" : "Número mínimo de llamadas salientes de agentes simultáneos",
    "cms.indexmonitor.field.agentConnectInTen" : "Llamadas conectadas manualmente en 10s",
    "cms.report.field.callEndField" : "Hora de finalización de la llamada",
    "cms.monitordetail.field.noanswercount" : "Llamadas no contestadas durante un largo período",
    "cms.qcrelationconf.message.selectAgent" : "Seleccione el agente que desea eliminar",
  "cms.qcrelationconf.message.selectGroup": "Seleccione el grupo de usuarios que desea eliminar",
    "cms.report.message.uvidDesc" : "ID único global asignado por el CCS a cada usuario que accede al centro de llamadas",
    "cms.systemmonitor.field.selectAgentName" : "Seleccionar Tenant",
    "cms.report.message.muteTimeDesc" : "Duración del silencio.",
    "cms.report.field.userLevel" : "Nivel de usuario",
    "cms.report.title.vdnSystemPerformanceMonitor" : "Monitoreo del rendimiento del sistema",
    "cms.indexmonitor.label.noncommercial" : "Uso comercial de prueba",
    "cms.indexmonitor.field.totalAbandonedCallNum" : "Total de llamadas perdidas",
    "cms.agent.message.modifypasswordfailed" : "Error al cambiar la contraseña.",
    "cms.agentmonitor.label.agentStatus" : "Estado del agente",
    "cms.report.message.outCallSuccNumDesc" : "Número de llamadas salientes manuales conectadas.",
    "cms.uapmonitor.field.callIpPort" : "IP y puerto",
    "cms.report.field.acdCalls" : "Total de llamadas conectadas",
    "cms.calllink.field.ivrServiceProcess" : "Flujo de negocio de IVR",
    "cms.report.field.perAgentNumField" : "Llamadas de agente",
    "cms.indexmonitor.message.ivrInboundFlowOut" : "Número de llamadas transferidas a otro dispositivo después de que el IVR reproduzca un anuncio",
    "cms.calllink.field.routingTemporaryDevice" : "Dispositivo de enrutamiento temporal",
    "cms.sysparam.config.mod" : "Editar",
    "cms.report.field.exceptionHangUp" : "Excepción cuelgue",
    "cms.export.field.zippwd" : "Contraseña comprimida",
    "cms.qualitycheck.message.error_errorinfo" : "Error al guardar",
    "cms.synchronize.message.confPwdStatus" : "Estado de la contraseña de la interfaz CCBCS",
    "cms.vcallecenter.title.modifyconfigurationinterfacepassword" : "Establecer la contraseña de la interfaz CCBCS en VDN",
    "cms.report.title.skillAllReport" : "Reporte de resumen de tráfico",
    "cms.report.field.outOccupyNumDesc" : "Número de veces que un agente conecta llamadas salientes.",
    "cms.report.field.agentServiceRateField" : "Tasa de éxito manual en el total de llamadas entrantes",
    "cms.report.title.IVRAccessTrend" : "Tendencia de los datos IVR por código de acceso",
    "cms.indexmonitor.message.ivrInCallCount" : "Número de llamadas entrantes cuyo dispositivo de servicio actual es un IVR.",
    "cms.report.field.avgAgentOutCount" : "Número promedio de llamadas salientes de agentes simultáneos",
    "cms.indexmonitor.field.allType" : "Todos los tipos",
    "cms.monitordetail.field.restduration" : "Duración (es) de descanso",
    "cms.indexmonitor.message.skillAgentAbandonDesc" : "Número de llamadas colgadas proactivamente por los agentes durante el timbre",
    "cms.report.field.minAgentCountField" : "Número mínimo de llamadas de agente simultáneas",
    "cms.report.field.outWaitAnsTime" : "Duración total del timbre de llamadas salientes",
    "cms.indexmonitor.field.skillAvgConnectedAck" : "Duración media del timbre de las llamadas conectadas",
    "cms.report.field.talkingTimeField" : "Tiempo de conversación de llamada",
    "cms.indexmonitor.message.skillSystemLostDesc" : "Número de interrupciones de la plataforma causadas por razones como el tiempo de espera de la respuesta del agente",
    "cms.agentMonitor.label.updateSip_error" : "El número está escrito incorrectamente.",
    "cms.report.field.avgAnsTime" : "Duración promedio de colas de llamadas conectadas",
    "cms.monitordetail.field.information" : "Informacion basica",
    "cms.voiceinspection.message.frequentExport" : "Exportar con demasiada frecuencia. Por favor, inténtelo de nuevo más tarde.",
    "cms.report.field.logoutCountField" : "Horarios de cierre de sesión",
    "cms.export.message.exceedNumberLimit" : "Excesivo volumen de datos. Reduzca el intervalo de tiempo de los datos o añada criterios de filtro de datos.",
    "cms.report.field.logoutCount" : "Horarios de cierre de sesión",
    "cms.report.field.userRefusedFailNumField" : "Llamadas perdidas debido al rechazo de llamadas",
    "cms.vcallecenter.message.syncSuccess" : "Los centros de llamadas virtuales se sincronizan con éxito.",
    "cms.report.message.callSuccRateDesc" : "Porcentaje del número de solicitudes de llamadas entrantes manuales conectadas en el número de llamadas entrantes manuales que solicitan servicios.",
    "cms.report.field.agentServiceField" : "El agente contestó llamadas",
    "cms.report.field.inCallNumField" : "Llamadas entrantes",
    "cms.callcenter.message.sameletterovermaxnum" : "Se puede agregar un máximo de ocho registros a la misma letra de unidad.",
    "cms.monitordetail.field.detail" : "Detalles del agente",
    "cms.report.field.sysLostCallsField" : "Llamadas entrantes del sistema fallidas",
    "cms.qualitycheck.label.viewusergroup" : "Ver Grupo de usuario",
    "cms.report.field.avgAgentCount" : "Número promedio de llamadas de agente simultáneas",
    "cms.monitordetail.field.holdduration" : "Duración (es) mantenida",
    "cms.indexmonitor.field.skillTimeoutAgent" : "Tiempo de espera de llamadas a agentes",
    "cms.report.message.acwInCountDesc" : "Número de veces que un agente entra en el estado de cierre después de finalizar las llamadas entrantes.",
    "cms.qcrelationconf.message.isDeleteGroupInfo" : "Confirme si desea eliminar el grupo de usuarios",
    "cms.calllink.title.logDate" : "Tiempo de Estadísticas",
    "cms.voiceinspection.field.ordinaryVoiceCall" : "Voz",
    "cms.calllink.msg.callerPlaceHolder" : "Ingrese el número de llamada",
    "cms.fullscreen.field.avgCallInTime" : "Duración (es) promedio de las llamadas entrantes manuales.",
    "cms.report.title.IVRAccessReport" : "Reporte de tráfico IVR por cód de acceso",
    "cms.datasource.field.test" : "Conexión de prueba",
    "cms.developerlist.message.checkInput" : "El valor no puede contener caracteres especiales.",
    "cms.calllink.field.skillQueue" : "Cola de habilidades",
    "cms.callcenterinstanceslist.message.createFailed" : "Error al crear el centro de llamadas.",
    "cms.failurecall.field.queueName" : "Nombre de cola",
    "cms.report.message.workRateDesc" : "Tasa de duración del trabajo = (Duración total del trabajo – Duración del descanso)/Duración total del trabajo x 100",
    "cms.fullscreen.field.setIndicatorThreshold" : "Establecer umbrales de indicador",
    "cms.calldetail.msg.timeTooLate" : "La hora seleccionada no debe ser posterior a {0}",
    "cms.report.message.selectAgent" : "Seleccione al menos un ID de agente.",
    "cms.report.field.startYear" : "Año de inicio",
    "cms.report.field.userRefusedFailNum" : "Llamadas perdidas debido a rechazo de llamadas",
    "cms.indexmonitor.title.agentDayMonitor" : "Estadísticas de hoy sobre indicadores de agentes",
    "cms.vcallecenter.message.validatefailed" : "Error al verificar la contraseña.",
    "cms.recorddrive.label.button_add" : "Nuevo",
    "cms.agentMonitor.label.forceidle_repeat" : "El agente se muestra inactivo. No vuelva a mostrar inactivo",
    "cms.indexmonitor.field.skillSystemLost" : "Interrupciones de la plataforma",
    "cms.callcenter.field.savefailed" : "Error",
    "cms.fullscreen.field.auxCountTimeDesc" : "Número de veces que los agentes están en estado de reposo.",
    "cms.callcenter.message.selectccgatewayservers" : "Seleccione un servidor CC-Gateway.",
    "cms.report.message.acdTimeRateDesc" : "Tasa de duración de la llamada manual = duración de la llamada manual/duración total del trabajo x 100",
    "cms.qcrelationconf.title.confirm" : "Confirmar",
    "cms.report.message.startTimeLessThanEndTime" : "La hora de inicio no puede ser posterior a la hora de finalización.",
    "cms.synchronize.message.invalidConfPwd" : "Contraseña incorrecta. Cambie la contraseña de la interfaz de configuración.",
    "cms.report.message.inCallNumDesc" : "Número total de llamadas entrantes en el VDN. El valor es la suma de las llamadas entrantes manuales y las llamadas entrantes realizadas automáticamente mediante el IVR.",
    "cms.mediaType9" : "Pizarra electrónica",
    "cms.mediaType8" : "Videollamada IP (H.323)",
    "cms.fullscreen.message.addTheme" : "Añadir un asunto",
    "cms.mediaType7" : "Llamada de fax",
    "cms.mediaType6" : "Llamada por correo electrónico",
    "cms.indexmonitor.message.skillAbandonDesc" : "Número de solicitudes de llamada entrante manuales perdidas = Número de solicitudes de llamada entrante manuales – Número de solicitudes de llamada entrante manuales conectadas",
    "cms.callcenterinstanceslist.field.uapUserName" : "UAP CDE Nombre de usuario",
    "cms.datasource.message.mysql5UrlTip" : "Cadena de conexión a base de datos: &#8220jdbc:mysql://ip:port/schema&#8220&#10;ip (dirección IP) puerto (número de puerto) esquema (modo)",
    "cms.mediaType5" : "Llamada de voz común",
    "cms.sysparam.config.select.prompt" : "Consejos",
    "cms.mediaType4" : "Llamada de solicitud de devolución de llamada",
    "cms.mediaType3" : "Navegación y uso compartido de formularios acompañados",
    "cms.indexmonitor.field.inboundConnectNum" : "Llamadas entrantes conectadas",
    "cms.mediaType2" : "Hacer clic para marcar",
    "cms.indexmonitor.field.skillQueueDisconnected" : "Cola de llamadas desconectadas",
    "cms.mediaType1" : "Chat de texto",
    "cms.monitordetail.field.busydurationavg" : "Duración Promedio ocupado",
    "cms.interfaceperflog.message.modifyFail" : "Error en la modificación.",
    "cms.report.field.otherTimeRate" : "Otra tasa de duración",
    "cms.vcallecenter.field.enternewpassword" : "Ingrese una nueva contraseña",
    "cms.report.field.unspecifiedSkillId" : "No especificado",
    "cms.report.field.callLostOtherNumField" : "Otras llamadas perdidas",
    "cms.calllind.field.deviceQueue" : "Cola de habilidades",
    "cms.report.field.IVRCallOutSuccNumField" : "Llamadas salientes de IVR conectadas",
    "cms.report.field.abortQueueWaitNum" : "Llamadas abandonadas",
    "cms.report.field.busyTime" : "Duración Total Ocupado",
    "cms.callcenterinstanceslist.field.gwIp" : "IP de puerta de enlace CC",
    "cms.indexmonitor.message.warningFilterMax" : "Se puede seleccionar un máximo de {0} indicadores.",
    "cms.callcenterinstanceslist.field.kafkaPasswordConfirm" : "Confirmar contraseña de KAFKA",
    "cms.report.field.IVROutSuccRate" : "Tasa de conexión de llamadas salientes Pure-IVR",
    "cms.callcenterinstanceslist.message.specialStrValidateFailed" : "El nombre del centro de llamadas no puede contener caracteres especiales.",
    "cms.callcenterinstanceslist.message.jobSwitch" : "Activar sincronización automática",
    "cms.report.message.outCallSuccRateDesc" : "Porcentaje de llamadas salientes conectadas respecto al total de llamadas salientes.",
    "cms.indexmonitor.field.durationAvgVdnInbound" : "Duración promedio de las llamadas entrantes",
    "cms.developer.message.modifyDeveloper" : "Actualizar la información del desarrollador",
    "cms.reportsubscribe.field.sentPeriod" : "Periodo enviado",
    "cms.report.message.IVRInSuccRateDesc" : "Porcentaje de llamadas entrantes de IVR conectadas al total de llamadas entrantes de IVR.",
    "cms.report.field.minAgentInCount" : "Número mínimo de llamadas entrantes de agente simultáneas",
    "cms.report.field.avgIVRServiceField" : "Duración promedio de llamadas entrantes de IVR",
    "cms.systemmonitor.field.systemInSucc" : "Llamadas conectadas al sistema",
    "cms.record-playback.callType1" : "Llamada de voz saliente",
    "cms.report.field.inCallSuccNumberField" : "Llamadas entrantes conectadas",
    "cms.indexmonitor.field.skillConnected" : "Solicitudes de llamada conectadas",
    "cms.report.message.noAckNumDesc" : "Número de llamadas sin respuesta después de haber sido asignadas a los agentes.",
    "cms.fullscreen.title.editChart" : "Modificar gráfico",
    "cms.report.field.auxTime" : "Duración total del descanso",
    "cms.monitordetail.field.outerforward" : "Llamadas transferidas externamente",
    "cms.reportsubscribe.field.reportName" : "Nombre del informe",
    "cms.report.field.tableHeader" : "Encabezado de tabla",
    "cms.indexmonitor.message.queryCallCenterError" : "Se produjo una excepción al consultar el centro de llamadas seleccionado.",
    "cms.systemparam.error.validate.inner" : "Error al validar el parámetro",
    "cms.fullscreen.field.IVR" : "IVR",
    "cms.report.field.IVRCallOutSuccRateField" : "Tasa de conexión de llamadas salientes IVR",
    "cms.report.message.busyTimeDesc" : "Duración total cuando un agente está en estado ocupado.",
    "cms.datasource.message.addfailed" : "Error al agregar.",
    "cms.indexmonitor.message.outboundConnectRate" : "Porcentaje del número de llamadas salientes abandonadas en el número de llamadas salientes.",
    "cms.indexmonitor.message.outboundAbandonRate": "El porcentaje del número de llamadas salientes que no están conectadas al número de llamadas salientes. Si el número de llamadas salientes es 0, el valor del indicador es 0.",
    "cms.indexmonitor.message.skillQueueDisconnectedDesc" : "Número de veces que las llamadas son desconectadas por la plataforma CTI cuando las llamadas están en cola",
    "cms.report.message.otherHangNumCtiDesc" : "Número de cuelgues del sistema.",
    "cms.report.field.avgIVROutCountField" : "Número medio de llamadas salientes IVR simultáneas",
    "cms.reportsubscribe.field.selectedReport" : "Lista de informes seleccionados",
    "cms.report.message.lessThanError" : "El ID del agente final no puede ser menor que el ID del agente de inicio.",
    "cms.report.message.holdTimeDesc" : "Duración total cuando un agente está en estado de espera de llamada. La duración de la llamada de un agente incluye el tiempo de espera de la llamada.",
    "cms.report.field.agentOffered" : "Llamadas entrantes manuales",
    "cms.voiceinspection.field.beginTime" : "Hora de inicio de grabación",
    "cms.report.title.skillReport" : "Reporte de tráfico de cola de espera de llamadas",
    "cms.report.field.occupancyRateField" : "Tasa de duración del trabajo",
    "cms.indexmonitor.message.agentAbandonIn30NDesc" : "Número total de abandonos con una duración de respuesta superior o igual a 30 segundos",
    "cms.export.message.downloadFailed" : "Error al descargar el informe.",
    "cms.report.message.overNumReason" : "Los agentes multimedia y demas agentes pueden procesar llamadas multicanal al mismo tiempo.",
    "cms.recorddrive.field.sftpPort" : "Puerto No.",
    "cms.callcenterinstanceslist.message.odfConfigError" : "La información de configuración del servicio ODFS es incorrecta.",
    "cms.uapmonitor.labe.mentiontitle" : "Nota:",
    "cms.callcenterinstanceslist.message.syncPassValidateFailed" : "El valor debe contener letras mayúsculas, minúsculas, dígitos y caracteres especiales. `~!@#$%^&*()-_=+|[{}];: ', <",
    "cms.callcenterinstanceslist.field.developer" : "Desarrollador Asociado",
    "cms.callcenterinstanceslist.message.passValidateContinuityFailed" : "La contraseña no puede contener tres caracteres idénticos consecutivos.",
    "cms.report.field.acwInCount" : "Tiempos de recapitulación de llamadas entrantes",
    "cms.qualitycheck.label.qcRelationConfig" : "Relaciones de inspección",
    "cms.calllink.field.timeoutTransfer" : "Una llamada se transfiere debido al tiempo de espera.",
    "cms.systemparam.success.reset" : "El restablecimiento del parámetro se ha realizado correctamente",
    "cms.indexmonitor.message.skillAvgConnectedAckDesc" : "Duración de timbre promedio de todas las llamadas conectadas en una cola",
    "cms.skilladjustment.message.tipsSuccess" : "La cola de habilidades se ajusta correctamente.",
    "cms.report.title.agentOperationReport" : "Reporte de operaciones de agente",
    "cms.callcenterinstanceslist.field.kafkaPort" : "Puerto de KAFKA",
    "cms.agentmonitor.label.cancellisteningOrInsert" : "Cancelar escucha o inserción",
    "cms.report.field.acdTime" : "Duración de llamada manual",
    "cms.report.message.stateDesc" : "estado de conexión, incluye conectado y no conectado.",
    "cms.indexmonitor.message.agentConnectedIn30NDesc" : "Número total de llamadas cuya duración de respuesta es mayor o igual a 30 segundos",
    "cms.fullscreen.field.fontSize" : "Talla",
    "cms.indexmonitor.field.skillOnlineConnected" : "Número de llamadas contestadas por los agentes en el bloque actual de 5 minutos",
    "cms.agentmonitor.label.readjustmentState" : "Ajustando",
    "cms.indexmonitor.field.agentBoundAbandonRate" : "Llamadas entrantes manuales abandonadas",
    "cms.report.field.ans20sField" : "Tasa de conexión de llamada manual en 20s",
    "cms.indexmonitor.field.durationAvgConnect" : "Duración promedio de la llamada",
    "cms.agentMonitor.label.talkingerror" : "El agente seleccionado no está en estado de llamada o está en una llamada multimedia",
    "cms.callcenter.field.finish" : "Completo",
    "cms.report.field.inWaitAnsNum" : "Llamadas entrantes manuales",
    "cms.report.field.outCallSuccNumField" : "Llamadas salientes manuales conectadas",
    "cms.report.message.outWaitAnsTimeDesc" : "Duración total del timbre de las llamadas salientes.",
    "cms.report.field.userNoAnswerNumField" : "Llamadas perdidas debido al tiempo de espera de timbre",
    "cms.report.field.outCallSuccNumberField" : "Llamadas salientes conectadas",
    "cms.report.field.sysLostCalls" : "Llamadas entrantes fallidas",
    "cms.qcrelationconf.placeholder.groupType" : "por favor seleccione",
    "cms.report.field.hangUpModeField" : "Hang Up (método)",
    "cms.report.field.otherTime" : "Duración total de otros Estados",
    "cms.indexmonitor.message.updateFailed" : "Error al actualizar",
    "cms.systemmonitor.label.CommercialUse" : "Uso comercial",
    "cms.report.field.acwInTime" : "Duración de resumen de llamadas entrantes",
    "cms.fullscreen.message.warningSelectOne" : "Seleccione sólo un indicador para la configuración del umbral.",
    "cms.callcenterinstanceslist.message.uapNodeInfo" : "Nodo UAP",
    "cms.calllink.field.threePartyHelp" : "La llamada se utiliza para solicitar ayuda de tres partes.",
    "cms.voiceinspection.field.agentId" : "Agente",
    "cms.calllink.field.MELCASDevice" : "Dispositivo MELCAS",
    "cms.calllink.field.businessRepresentative" : "Agente",
    "cms.report.field.availTime" : "Duración inactiva",
    "cms.report.message.deviceNoDesc" : "Número de dispositivo del dispositivo de procesamiento actual de la llamada",
    "cms.calllink.title.callId" : "ID de llamada",
    "cms.fullscreen.field.reportDate" : "Hace 1 día",
    "cms.report.title.agentOperationTrend" : "Detalles de operación de conexión de agente Tendencia",
    "cms.indexmonitor.field.skillConnectedIn30Rate" : "Llamadas conectadas en 30s",
    "cms.agentmonitor.label.agent" : "Agente",
    "cms.vcallecenter.field.enterwaspassword" : "Contraseña",
    "cms.indexmonitor.message.agentOutboundNum" : "Número de llamadas salientes manuales.",
    "cms.report.message.uapIdDesc" : "ID del UAP que conecta la llamada a la plataforma del centro de llamadas",
    "cms.agentMonitor.label.cancelwhisper_success" : "Ruido cancelado con éxito.",
    "cms.report.field.outCallSuccNumber" : "Llamadas salientes conectadas",
    "cms.reportsubscribe.field.publish" : "Publicación",
    "cms.fullscreen.field.play" : "Reproducir",
    "cms.report.field.date" : "Fecha",
    "cms.report.message.holdCountDesc" : "Número de tiempos de espera.",
    "cms.calldetail.msg.callIdInvalid" : "El formato es incorrecto.",
    "cms.skilladjustment.message.tipsNotMonitor" : "No es un especialista en control de calidad. Error al ajustar la cola de habilidades.",
    "cms.report.message.avgQueueOutCountDesc" : "Número promedio de llamadas salientes simultáneas cuyo dispositivo de servicio es una cola de habilidades.",
    "cms.report.field.succAnsRateField" : "Tasa de solicitud de conexión",
    "cms.tenant.message.adminpassword" : "Contraseña",
    "cms.voiceinspection.field.rate" : "Tasa de reproducción",
    "cms.agentmonitor.label.agentName" : "Nombre del agente",
    "cms.fullscreen.field.oneHour" : "1 hora",
    "cms.report.field.occupancyRate" : "Tasa de duración del trabajo",
    "cms.report.field.totalLIVRServiceField" : "Tiempo total de servicio de IVR",
    "cms.report.message.acwOutTimeDesc" : "Duración de recapitulación después de finalizar las llamadas salientes.",
    "cms.report.field.avgIVRCount" : "Número promedio de llamadas IVR simultáneas",
    "cms.report.field.custHangNumCti" : "Suspensiones de clientes",
    "cms.callcenterinstanceslist.field.nodemodepool" : "Modo pool",
    "cms.report.field.IVRSuccRate" : "Tasa de conexión de llamadas entrantes IVR",
    "cms.report.field.inCallFailNumField" : "Solicitudes de llamada perdidas",
    "cms.report.field.month" : "Meses",
    "cms.indexmonitor.message.skillMinQueueAckDesc" : "Duración mínima de espera (en cola y duración del timbre) de todas las llamadas",
    "cms.agentMonitor.message.operaFailed" : "Operación fallida. Una inspección o llamada está en curso.",
    "cms.report.field.outCallRateField" : "Tasa de conexión de llamadas salientes",
    "cms.indexmonitor.message.skillOverQueueDesc" : "Número de llamadas transferidas desde la cola de habilidades original a otras colas de habilidades debido a un sobreflujo",
    "cms.report.message.IVROutSuccNumDesc" : "Número de llamadas salientes de IVR conectadas cuya duración de ocupación del dispositivo IVR es mayor que 0.",
    "cms.indexmonitor.message.abandonInSixty" : "Porcentaje del número de llamadas abandonadas dentro de los 60s en el número total de llamadas abandonadas.",
    "cms.indexmonitor.field.ivrInboundFlowOut" : "Llamadas entrantes de IVR transferidas",
    "cms.report.message.call6RateDesc" : "Porcentaje del número de solicitudes de llamada conectadas manualmente en 30 segundos en el número total de llamadas que solicitan servicios manuales.",
    "cms.agentMonitor.label.intercept_success" : "Intecepcion exitosa",
    "cms.indexmonitor.message.ivrOutCallCount" : "Número de llamadas salientes cuyo dispositivo de servicio actual es un IVR.",
    "cms.report.title.skillAllTrend" : "Tendencia de resumen de la cola de habilidades",
    "cms.callcenterinstanceslist.message.ccNameUpdateError" : "No cambie el nombre del centro de llamadas al editar el centro de llamadas",
    "cms.report.field.callAbandonedInRingField" : "Llamadas abandonadas en el anillo",
    "cms.report.message.IVRSuccRateDesc" : "Porcentaje del número de llamadas entrantes de IVR conectadas en el número de solicitudes de llamadas entrantes de IVR.",
    "cms.report.title.agentReport" : "Informe de resumen del agente",
    "cms.callcenterinstanceslist.field.ccId" : "ID del centro de llamadas",
    "cms.report.message.failAvgTimeDesc" : "Duración promedio del timbre de todas las llamadas sin respuesta.",
    "cms.export.field.operation" : "Operar",
    "cms.indexmonitor.field.agentInCallCount" : "Número de llamadas entrantes del agente en línea",
    "cms.indexmonitor.message.durationAvgConnect" : "Duración total de llamadas conectadas/Número total de llamadas conectadas",
    "cms.fullscreen.field.bar" : "Gráfico de barras",
    "cms.agentmonitor.label.switch" : "interruptor",
    "cms.qualitycheck.field.groupName" : "Grupo de usuarios",
    "cms.agentMonitor.label.nosignin_error" : "El agente inspeccionado no ha iniciado sesión.",
    "cms.report.field.calledHangUp" : "Llamado Hang Up",
    "cms.report.message.otherTimeRateDesc" : "Otra tasa de duración = (Duración total de otros estados/Duración total del trabajo) x 100",
    "cms.recorddrive.placeholder.batchdeleteconfim" : "¿Está seguro de eliminar todas las letras de unidad seleccionadas?",
    "cms.callcenterinstanceslist.title.callcenter" : "Centro de llamadas",
    "cms.report.message.acwCountDesc" : "Número de veces que un agente entra en el estado de resumen.",
    "cms.callcenterinstanceslist.label.callCenterRecordDrive" : "Carta de unidad de grabación del centro de llamadas",
    "cms.qualitycheck.placeholder.userGroupName" : "Introduzca un grupo de usuarios.",
    "cms.synchronize.message.syncStatusThree" : "Error de sincronización",
    "cms.datasource.message.masterSalveRelationExist" : "El origen de datos se ha utilizado como origen de datos de copia de seguridad.",
    "cms.indexmonitor.message.totalLoginNum" : "Número de agentes que han iniciado sesión en el centro de llamadas",
    "cms.fullscreen.field.maxThreshold" : "Umbral máximo",
    "cms.report.field.inAnswerRateField" : "Tasa de conexión de llamadas entrantes",
    "cms.qcrelationconf.field.agentGroupName" : "Equipo inspeccionado",
    "cms.reportsubscribe.message.deleteConfim" : "¿Está seguro de borrar?",
    "cms.report.message.outVdnAvgTimeDesc" : "Duración promedio de la llamada saliente en el VDN. El valor es la duración total de la llamada saliente dividida por el número de llamadas salientes.",
    "cms.indexmonitor.message.abandonInThree" : "Porcentaje del número de llamadas abandonadas dentro de 3 segundos en el número total de llamadas abandonadas.",
    "cms.report.field.IVRInSuccField" : "Llamadas entrantes de IVR conectadas",
    "cms.systemmonitor.field.vdnName" : "Tenant",
    "cms.report.field.reportType" : "Tipo de informe",
    "cms.agentmonitor.label.noListenedAndInserted" : "No inspeccionado",
    "cms.fullscreen.message.enterMessage" : "Entrar",
    "cms.report.field.acwOutTimeField" : "Duración del cierre de la llamada saliente",
    "cms.qcrelationconf.field.qualityInspection" : "Inspector de calidad",
    "cms.indexmonitor.message.agentConnectIn3Rate" : "Porcentaje del número de llamadas conectadas manualmente en 3 segundos (incluidos 3 segundos) respecto al número total de llamadas.",
    "cms.report.field.avgIVROutCount" : "Número promedio de llamadas salientes de IVR simultáneas",
    "cms.voiceinspection.field.video" : "Video",
    "cms.voiceinspection.field.reset" : "Restablecer",
    "cms.report.field.internalTransferCountField" : "Transferencias internas",
    "cms.fullscreen.field.table" : "Tabla",
    "cms.report.message.acwTimeDesc" : "Duración total cuando un agente está en estado de cierre.",
    "cms.report.field.outCallNum" : "Llamadas salientes",
    "cms.report.field.failAvgTime" : "Duración promedio del timbre de llamadas perdidas",
    "cms.report.message.preDeviceTypeDesc" : "Tipo de dispositivo del último dispositivo que experimentó la llamada",
    "cms.report.field.muteCountField" : "Tiempos de silencio",
    "cms.report.field.ivrtrafficreport.statdate" : "Fecha",
    "cms.sysparam.config.title" : "Parámetros del sistema",
    "cms.reportsubscribe.field.wednesday" : "Miércoles",
    "cms.indexmonitor.field.skillAvgAbandonAck" : "Duración media del timbre de las llamadas perdidas",
    "cms.report.message.agentHangNumCtiDesc" : "Número de cuelgues del agente.",
    "cms.sysparam.config.itemname" : "Nombre del parámetro",
    "cms.tenant.message.adminpasswordagain" : "Confirmar contraseña",
    "cms.systemmonitor.label.customizes" : "Personalización",
    "cms.systemparam.success.save" : "Parámetro guardado correctamente",
    "cms.callcenterinstanceslist.title.baseInfo" : "Información básica",
    "cms.voiceinspection.field.clickToCall" : "Haga clic por voz para marcar",
    "cms.report.field.deviceTypeField" : "Tipo de dispositivo",
    "cms.indexmonitor.field.agentInboundConnectNum" : "Llamadas entrantes manuales conectadas",
    "cms.reportsubscribe.field.basicInfo" : "Información básica",
    "cms.uapmonitor.field.socketConnecting" : "conectando",
    "cms.callcenterinstanceslist.title.sourceconfiguration" : "Configuración del origen de datos",
    "cms.report.message.callLostOtherNumDesc" : "Número de llamadas que no se contestan debido a razones distintas de las líneas ocupadas\\, el rechazo de llamadas\\, y el tiempo de espera de timbre.",
    "cms.interfaceperflog.message.dvSwitch" : "Habilitar la generación de informes de datos",
    "cms.report.message.acdTimeDesc" : "Duración total de la llamada de un agente, excluyendo la duración del chat de texto.",
    "cms.calllink.field.call" : "Llamar",
    "cms.qcrelationconf.field.groupName" : "Nombre del grupo de usuarios",
    "cms.systemmonitor.field.uapIP" : "Dirección IP UAP",
    "cms.agentmonitor.label.terminalStatusFix" : "Restaurar estado",
    "cms.indexmonitor.message.agentConnectedIn5NDesc" : "Número total de llamadas cuya duración de respuesta es mayor o igual a 5 segundos",
    "cms.indexmonitor.message.skillTimeoutAgentDesc" : "Número de llamadas transferidas desde la cola de habilidades original a otros agentes debido al tiempo de espera de la cola",
    "cms.reportsubscribe.message.searchTemplateName" : "Por favor, introduzca el nombre de la plantilla",
    "cms.report.message.tooManyExport" : "Demasiadas exportaciones {0} en poco tiempo.",
    "cms.report.field.maxIVRCount" : "Número máximo de llamadas IVR simultáneas",
    "cms.report.field.IVRServiceRate" : "Tasa de éxito de llamadas entrantes Pure-IVR",
    "cms.fullscreen.field.virtualcallcenter" : "VDN",
    "cms.systemparam.error.save" : "Error al guardar parámetros",
    "cms.callcenterinstanceslist.message.uapNode1Info" : "Nodo 1 de UAP",
    "cms.indexmonitor.message.abandonInTen" : "Porcentaje del número de llamadas abandonadas dentro de 10 segundos en el número total de llamadas abandonadas.",
    "cms.systemparam.error.validate.notmatchruleurl" : "Por favor, introduzca una dirección URL válida",
    "cms.callcenterinstanceslist.message.datasourceInfo" : "Complete la configuración del origen de datos.",
    "cms.report.field.workTime" : "Duración total del trabajo",
    "cms.vcallecenter.title.modifyadministratorpassword" : "Contraseña del administrador de CTI",
    "cms.callcenterinstanceslist.message.alreadyInuse" : "Se ha utilizado el segmento de ID de empleado.",
    "cms.calllink.field.callType9" : "Llamada retransmitida",
    "cms.report.message.maxQueueOutCountDesc" : "Número máximo de llamadas salientes simultáneas cuyo dispositivo de servicio es una cola de habilidades.",
    "cms.indexmonitor.field.skillLostRing" : "Llamadas sin respuesta",
    "cms.calllink.field.callType8" : "Llamada entrante (agente internacional)",
    "cms.calllink.field.callType7" : "Llamada auxiliar",
    "cms.report.title.vdnTrafficPeriodMonitor" : "Monitoreo del período de tráfico de VDN",
    "cms.calllink.field.callType6" : "Llamada de red saliente interna a un agente virtual",
    "cms.report.field.otherHangNumCtiField" : "Cuelgues del sistema",
    "cms.calllink.field.callType5" : "Llamada interna de red entrante a un agente virtual",
    "cms.calllink.field.callType4" : "Llamada de inspección entrante del centro de llamadas de la red",
    "cms.report.message.inWaitAnsNumDesc" : "Número de solicitudes de llamadas entrantes para servicio manual.",
    "cms.calllink.field.callType3" : "Llamada saliente del centro de llamadas de la red",
    "cms.indexmonitor.message.totalRestNum" : "Número de agentes en estado de reposo",
    "cms.calllink.field.callType2" : "Llamada entrante del centro de llamadas de la red",
    "cms.calllink.field.callType1" : "Llamada saliente IVR PRI",
    "cms.calllink.field.callType0" : "Llamada saliente de un agente telefónico",
    "cms.monitordetail.field.innerforward" : "Llamadas transferidas internamente",
    "cms.indexmonitor.title.vdnDayTrend" : "Tendencia actual del VDN",
    "cms.indexmonitor.field.queueOutCallCount" : "Número de llamadas salientes en cola en línea",
    "cms.indexmonitor.title.selectAgentName" : "Seleccionar tenant",
    "cms.datasource.field.ok" : "OK",
    "cms.calllink.field.transferOut" : "Una llamada se esta transfiriendo",
    "cms.fullscreen.field.avgCallTime" : "Duración media de la llamada",
    "cms.report.message.agentAlertingDurationDesc" : "Duración de alertas de llamada.",
    "cms.monitordetail.field.busyDuration" : "Duración (es) de Ocupado",
    "cms.report.field.submediatype.twitter" : "X (Twitter)",
    "cms.calllind.field.deviceVnr" : "Dispositivo virtual",
    "cms.indexmonitor.message.warningFiltering" : "Seleccionar al menos un indicador",
    "cms.monitordetail.title.statistics" : "Estadísticas",
    "cms.qcrelationconf.field.outgoingAgent" : "Agente saliente",
    "cms.report.title.abandonRingTrend" : "Tendencia de llamadas abandonadas durante el timbre",
    "cms.indexmonitor.message.agentConnectAfterInTen" : "Porcentaje del número de llamadas conectadas manualmente después de 10 s en el número total de llamadas conectadas manualmente.",
    "cms.monitordetail.field.totaltalkingduration" : "Duración total de la llamada",
    "cms.report.message.skillIdDesc" : "ID de la cola de habilidades a la que pertenece una llamada",
    "cms.monitordetail.field.audio" : "Audio/Video",
    "cms.fullscreen.message.themeDuplicate" : "Nombre duplicado.",
    "cms.sysparam.config.refresh" : "Actualizar",
    "cms.report.field.transferIvrCount" : "Tiempos de transferencia manual a IVR",
    "cms.report.field.outAvgCallTime" : "Duración promedio de llamadas salientes manuales",
    "cms.datasource.field.dbUsername" : "Nombre de usuario",
    "cms.indexmonitor.title.ivrNowMonitor" : "Monitoreo de ejecución de IVR en tiempo real",
    "cms.indexmonitor.field.skillRequest" : "Solicitudes de servicio",
    "cms.datasource.field.no" : "No",
    "cms.calllink.title.endTime" : "Hora de finalización de las estadísticas",
    "cms.calllink.title.deviceType" : "Tipo de dispositivo",
    "cms.callcenterinstanceslist.field.tenantSpaceName" : "Cuenta de administrador",
    "cms.agentMonitor.label.forcebusy_repeat" : "El agente está ocupado. No vuelva a mostrar ocupado",
    "cms.callcenterinstanceslist.field.vccId" : "ID de centro de llamadas virtual",
    "cms.indexmonitor.message.agentAbandonIn60Rate" : "Porcentaje de llamadas abandonadas en 60 segundos respecto al total de llamadas abandonadas",
    "cms.agentmonitor.label.cancelwhisper" : "Cancelar Susurro",
    "cms.indexmonitor.message.skillConnectedInNDesc" : "Número de solicitudes de llamada conectadas manualmente en Ns. Actualmente, los Ns pueden ser 3s, 5s, 10s, 20s, 30s o 60s.",
    "cms.indexmonitor.field.skillConnectedIn5Rate" : "Llamadas conectadas en 5s",
    "cms.report.field.startTime" : "Fecha de inicio",
    "cms.report.field.acwRate" : "Tasa de duración de cierre",
    "cms.indexmonitor.field.currentSelectedSkill" : "Cola de habilidades seleccionadas:",
    "cms.agentMonitor.label.querynum_error" : "Error al actualizar el número de inicio de sesión.",
    "cms.report.message.queryErrorInfo" : "Error de consulta.",
    "cms.report.field.skillName" : "Nombre de la cola de habilidades",
    "cms.report.field.outCallSuccRateField" : "Tasa de conexión de llamadas salientes",
    "cms.report.field.loginCount" : "Tiempos de inicio de sesión",
    "cms.callcenterinstanceslist.field.uapCmdPort" : "Puerto de comando UAP MML",
    "cms.indexmonitor.field.skillMinQueueAck" : "Duración mínima de espera",
    "cms.report.field.enterReasonField" : "Causa del dispositivo de entrada de llamada",
    "cms.reportsubscribe.field.subscriptionList" : "Lista de suscripciones",
    "cms.report.field.callIdField" : "Número de secuencia de llamada",
    "cms.report.field.IVRSuccRateField" : "Tasa de conexión de llamadas entrantes IVR",
    "cms.qcrelationconf.message.paramOutOfRange" : "La entrada no puede exceder los 100 caracteres",
    "cms.monitordetail.field.busy" : "Ocupado",
    "cms.report.message.releaseCauseDesc" : "Causa de liberación de llamada",
    "cms.fullscreen.message.maxThemeNum" : "Se puede añadir un máximo de 20 temas privados y 20 públicos.",
    "cms.report.message.perAgentNumDesc" : "Número de llamadas entrantes asignadas a cada agente durante el período de tiempo seleccionado.",
    "cms.indexmonitor.message.abandonInThirty" : "Porcentaje del número de llamadas abandonadas dentro de 30s en el número total de llamadas abandonadas.",
    "cms.qualitycheck.field.unselected" : "Sin seleccionar",
    "cms.report.title.ivrOutboundTrend" : "Tendencia de llamadas salientes de IVR",
    "cms.monitordetail.field.holdcount" : "Llamadas en curso",
    "cms.report.field.failQueueWaitRateField" : "Duración media del timbre de las llamadas perdidas",
    "cms.monitordetail.field.duration" : "Estadísticas sobre la duración",
    "cms.indexmonitor.field.skillConnectedAfter60" : "Llamadas conectadas después de los 60",
    "cms.qualitycheck.label.trafficGroup" : "Grupo de tráfico",
    "cms.report.message.avgIVRServiceDesc" : "Duración media de la llamada entrante de IVR = duración de la llamada entrante de IVR/Número de llamadas entrantes de IVR conectadas.",
    "cms.report.field.IVRCallOutNumField" : "Llamadas salientes de IVR",
    "cms.indexmonitor.message.totalCallNumDesc" : "Número total de llamadas conectadas por un agente.",
    "cms.uapmonitor.labe.inspectorcheckinpwd" : "Contraseña de inicio de sesión del inspector de control de calidad",
    "cms.fullscreen.title.deleteconfim" : "Confirmar",
    "cms.indexmonitor.field.totalBusyNum" : "Agentes en estado ocupado",
    "cms.recorddrive.title.sftpServerConfig" : "Configuración del servidor SFTP",
    "cms.uapmonitor.labe.eid" : "Segmento de número de terminal",
    "cms.report.field.manToSkillNum" : "Transferencias de colas manuales a habilidades",
    "cms.systemparam.success.refresh" : "Parámetro actualizado correctamente",
    "cms.report.field.maxQueueInCount" : "Número máximo de llamadas entrantes simultáneas en una cola",
    "cms.report.message.agentOutSuccDesc" : "Número de llamadas salientes manuales conectadas a los usuarios, que es el número de llamadas salientes manuales cuya duración de llamada es mayor que 0.",
    "cms.callcenterinstanceslist.field.operate" : "Operación",
    "cms.indexmonitor.message.agentConnectInTen" : "Porcentaje del número de llamadas conectadas manualmente dentro de 10 segundos en el número total de llamadas conectadas manualmente.",
    "cms.report.field.call4Rate" : "Tasa de conexión manual de llamadas entrantes en 20s",
    "cms.report.message.enterReasonDesc" : "Causa del dispositivo de entrada de llamada",
    "cms.report.message.avgHandleTimeDesc" : "Duración promedio de procesamiento = (Duración de la llamada manual + Duración total del cierre) /Número de llamadas manuales exitosas",
    "cms.agentmonitor.label.total" : "Total de agentes",
  "cms.agentmonitor.label.syncScreenMonitor": "Pantalla de monitor",
    "cms.indexmonitor.field.inboundAbandonRate" : "Llamadas entrantes abandonadas",
    "cms.report.title.IVRReport" : "Reporte de tráfico IVR",
    "cms.report.field.outWaitAnsNum" : "Llamadas salientes manuales",
    "cms.agentMonitor.label.Idle_error" : "El inspector no está en estado inactivo.",
    "cms.report.field.preDeviceTypeField" : "Tipo de dispositivo anterior",
    "cms.recorddrive.placeholder.singledeleteconfim" : "¿Está seguro de eliminar la letra de unidad seleccionada?",
    "cms.vcallecenter.title.modifyinfo2" : "Configurar contraseña",
    "cms.export.field.download" : "Descargar",
    "cms.report.field.selectOrg" : "Seleccionar OU",
    "cms.report.field.maxQueueWaitTimeField" : "Duración máxima de espera",
    "cms.voiceinspection.message.invalidQcRelation" : "La relación de inspección no coincide.",
    "cms.qualitycheck.label.input" : "Por favor ingrese",
    "cms.report.field.acwOutCountField" : "Tiempos de recapitulación de llamadas salientes",
    "cms.report.message.outInvalidCallDesc" : "Número de llamadas salientes que no pueden llegar a los clientes debido a números no válidos o apagado.",
    "cms.report.field.userNoAnswerNum" : "Llamadas perdidas debido al tiempo de espera del timbre",
    "cms.report.field.agentOfferedField" : "Llamadas entrantes manuales",
    "cms.monitordetail.field.intercallcount" : "Llamadas internas",
    "cms.datasource.field.dbPwd" : "Contraseña",
    "cms.report.field.holdCountField" : "Tiempos de espera",
    "cms.indexmonitor.message.agentBoundConnectRate" : "Porcentaje del número de llamadas conectadas manualmente en el número de llamadas entrantes manuales.",
    "cms.indexmonitor.field.skillConnectedIn60" : "Llamadas conectadas en 60s",
    "cms.indexmonitor.field.ivrInboundNum" : "Llamadas entrantes de IVR conectadas",
    "cms.uapmonitor.field.deregister" : "¿Está seguro de que desea cancelar el registro del dispositivo?",
    "cms.indexmonitor.field.totalTalkingNum" : "Agentes de llamada",
    "cms.callcenterinstanceslist.title.deleteconfim" : "Confirmar Borrar",
    "cms.agentmonitor.label.forceSignOut" : "Forzar la salida",
    "cms.callcenterinstanceslist.message.updateSuccess" : "El centro de llamadas se modificó con éxito.",
    "cms.report.message.locationIdDesc" : "ID del nodo distribuido al que pertenece la llamada",
    "cms.systemmonitor.message.dategt31days" : "El intervalo de fechas no puede exceder los 31 días.",
    "cms.agentMonitor.label.forceidle_error" : "Error al establecer a la fuerza un agente en el estado inactivo.",
    "cms.report.message.outCallNumDesc" : "Número de llamadas salientes manuales y automáticas en el VDN. El valor es la suma de las llamadas salientes manuales y las llamadas salientes realizadas automáticamente mediante el IVR.",
    "cms.indexmonitor.title.selectCcName" : "Seleccionar centro de llamadas",
    "cms.qcrelationconf.field.accountName" : "Nombre del agente",
    "cms.report.field.minQueueCount" : "Número mínimo de llamadas simultáneas en una cola",
    "cms.indexmonitor.message.agentAbandonIn5Rate" : "Porcentaje de llamadas abandonadas en 5 segundos respecto al total de llamadas abandonadas",
    "cms.report.title.agentOutCallReport" : "Reporte de llamada saliente de agente",
    "cms.report.field.submediatype.5gmessage" : "5G RCS",
    "cms.report.field.inCallSuccNumber" : "Llamadas entrantes conectadas",
    "cms.voiceinspection.message.frequentPlay" : "La reproducción es demasiado frecuente. Inténtalo de nuevo más tarde.",
    "cms.calllink.field.outOfService" : "El teléfono está fuera de servicio.",
    "cms.report.field.byWeek" : "Informe semanal",
    "cms.indexmonitor.field.abandonInFive" : "Llamadas abandonadas en 5s",
    "cms.report.field.IVRInSuccRate" : "Tasa de conexión de llamadas entrantes IVR",
    "cms.report.message.callBeginDesc" : "Hora de inicio de la llamada",
    "cms.callcenterinstanceslist.field.backupcc" : "Centro de llamadas DR",
    "cms.report.message.failAnsRateDesc" : "Tasa de pérdida de solicitudes = 100 Tasa de conexión de llamadas –",
    "cms.indexmonitor.message.agentInCallCount" : "Número de llamadas entrantes conectadas.",
    "cms.vcallecenter.field.curcountpassword" : "Contraseña de cuenta actual",
    "cms.report.field.alertingDurationField" : "Duración de alertas llamadas",
    "cms.report.message.waitCauseDesc" : "Causa de la llamada en espera en el dispositivo actual",
    "cms.agentmonitor.label.showAlarmCallId" : "ID de llamada",
    "cms.qcrelationconf.placeholder.workName" : "Introduzca un nombre de agente",
    "cms.fullscreen.field.themeTypeY" : "Temas públicos",
    "cms.report.message.currentSkillIdDesc" : "ID de la cola de habilidades que procesa la llamada",
    "cms.report.message.startOrEndTimeNoExist" : "Si selecciona un segmento de tiempo para la consulta, la hora de inicio y la hora de finalización deben existir al mismo tiempo.",
    "cms.callcenterinstanceslist.field.uapIp" : "Dirección IP UAP NMU",
    "cms.systemmonitor.field.systemCallRate" : "Tasa de conexión de llamadas al sistema",
    "cms.qualitycheck.label.delete" : "Borrar",
    "cms.report.field.talkDurationField" : "Duración de la conversación",
    "cms.indexmonitor.field.skillAvgAbandonQueue" : "Duración promedio de cola de llamadas perdidas",
    "cms.indexmonitor.message.inboundAbandonRate" : "El porcentaje del número de llamadas entrantes que no se conectaron al número de llamadas entrantes. Si el número de llamadas entrantes es 0, el valor del indicador es 0.",
    "cms.callcenterinstanceslist.message.ccgatewayServerBatchdeleteconfim" : "¿Está seguro de que desea eliminar todos los servidores CC-Gateway seleccionados?",
    "cms.fullscreen.field.themeTypeN" : "Temas privados",
    "cms.systemmonitor.title.tips" : "Información",
    "cms.fullscreen.message.deletechart" : "¿Quieres confirmar la eliminación de este gráfico?",
    "cms.report.field.maxQueueOutCount" : "Número máximo de llamadas salientes simultáneas en una cola",
    "cms.report.title.IVRTrend" : "Tendencia de datos de IVR",
    "cms.agentMonitor.label.forcebusy_error" : "Error al establecer por la fuerza un agente en el estado ocupado.",
    "cms.report.message.createTimeDesc" : "Crear hora.",
    "cms.systemparam.error.validate.notmatchruleregex" : "El parámetro de entrada no coincide con la expresión regular de la regla de verificación",
    "cms.report.title.changeChart" : "Cambiar a gráfico",
    "cms.indexmonitor.message.totalIdeaNum" : "Número de agentes que están en estado inactivo y pueden procesar llamadas inmediatamente",
    "cms.calllink.field.helperRelease" : "Una llamada es desconectada por la parte que envía la solicitud de ayuda interna.",
    "cms.report.message.minQueueOutCountDesc" : "Número mínimo de llamadas salientes simultáneas cuyo dispositivo de servicio es una cola de habilidades.",
    "cms.voiceinspection.field.phoneVideo" : "Videollamada común",
    "cms.report.field.callNoPlaceholder" : "Llamar o número llamado",
    "cms.indexmonitor.title.skillNowMonitor" : "Estadísticas de supervisión en tiempo real en la cola de habilidades durante la ejecución",
    "cms.indexmonitor.field.skillConnectedAfter10" : "Llamadas conectadas después de 10s",
    "cms.report.message.dategt31days" : "El intervalo de fechas no puede exceder los 31 días.",
    "cms.developerlist.label.refreshsk" : "Actualizar SK",
    "cms.callcenterinstanceslist.label.recordDrive" : "Grabación de letra de unidad",
    "cms.fullscreen.title.newTheme" : "Crear asunto",
    "cms.fullscreen.field.edit" : "Modificar",
  "cms.fullscreen.style.dark": "Oscuro",
  "cms.fullscreen.style.light": "Color claro",
  "cms.fullscreen.style.title": "Estilo",
  "cms.systemmonitor.label.threehours" : "3 Horas",
    "cms.export.field.viewExportTask" : "Exportar vista de tareas",
    "cms.indexmonitor.field.skillConnectedIn30" : "Llamadas conectadas en 30s",
    "cms.fullscreen.message.chooseTheme" : "El diseño no se guardó. ¿Estás seguro de que quieres cambiar de tema?",
    "cms.report.field.minQueueCountField" : "Número mínimo de llamadas simultáneas en una cola",
    "cms.callcenterinstanceslist.message.ccIdUpdateError" : "No cambie el ID del centro de llamadas al editar el centro de llamadas",
    "cms.report.field.agentInSucc" : "Llamadas entrantes manuales conectadas",
    "cms.report.field.waitAnsRateField" : "Duración media del timbre de las llamadas entrantes manuales",
    "cms.report.field.outVdnAvgTime" : "Duración promedio de llamadas salientes en VDN",
    "cms.report.field.inAvgRingTime" : "Duración promedio del timbre de las llamadas entrantes manuales",
    "cms.indexmonitor.field.skillMaxQueueAck" : "Duración máxima de espera",
    "cms.indexmonitor.title.vdnDayMonitor" : "Estadísticas de hoy sobre indicadores VDN",
    "cms.report.field.maxAgentCount" : "Número máximo de llamadas de agente simultáneas",
    "cms.report.field.currentSkillIdField" : "ID de cola de habilidades",
    "cms.report.field.ivrtrafficreport.ivrshortcalls" : "Número de llamadas cortas de IVR (dentro de 3s)",
    "cms.skilladjustment.field.skilltype" : "Tipo de cola de habilidades",
    "cms.export.field.exportiong" : "Exportando",
    "cms.fullscreen.message.selectAgent" : "La unidad organizativa y el agente no pueden estar vacíos.",
    "cms.export.message.parainvalid" : "Error al verificar las condiciones de exportación.",
    "cms.report.field.submediatype.web" : "Web",
    "cms.systemmonitor.field.rateNum" : "Llamadas conectadas al sistema",
    "cms.fullscreen.field.screenreSolution" : "Resolución",
    "cms.report.message.weekLessThan" : "La semana de fin de año no puede ser menor que la semana del año de inicio.",
    "cms.synchronize.message.adminPwdStatus" : "Estado de la contraseña del administrador de CTI",
    "cms.fullscreen.message.numberFirst" : "El valor máximo es 7680.",
    "cms.qcrelationconf.message.isDeleteGroupMember" : "Confirme si desea eliminar al miembro del grupo",
    "cms.indexmonitor.message.durationAvgVdnOutbound" : "Duración total de las llamadas salientes que ocupan el número VDN/total de llamadas salientes",
    "cms.report.message.agentInSuccDesc" : "Número de llamadas entrantes manuales conectadas a los agentes, que es el número de llamadas entrantes manuales cuya duración de llamada es mayor que 0.",
    "cms.agentMonitor.label.cancelListening_error" : "Error al cancelar la escucha.",
    "cms.indexmonitor.message.ivrInboundUserAbort" : "Número de llamadas colgadas proactivamente por un usuario en el IVR",
    "cms.reportsubscribe.title.tips" : "Información de contacto",
    "cms.fullscreen.field.FangSong" : "FangSong",
    "cms.datasource.message.deletesuccess" : "La eliminación se realizó correctamente.",
    "cms.systemmonitor.label.reset" : "Restablecer",
    "cms.report.message.minQueueCountDesc" : "Número mínimo de llamadas simultáneas cuyo dispositivo de servicio es una cola de habilidades.",
    "cms.agentMonitor.label.beinrest_error" : "El agente inspeccionado está en estado de reposo.",
    "cms.systemparam.error.refresh" : "Error al actualizar el parámetro",
    "cms.report.field.IVRInBound" : "Llamadas entrantes de IVR",
    "cms.indexmonitor.field.skillAbandonIn60" : "Llamadas abandonadas en los años 60",
    "cms.report.message.auxTimeRateDesc" : "Tasa de duración del descanso = (Duración total del descanso/Duración total del trabajo) x 100",
    "cms.indexmonitor.message.totalLogoutNum" : "Número de agentes que no han iniciado sesión en el centro de llamadas y están en estado sin conexión",
    "cms.indexmonitor.field.skillConnectedIn10" : "Llamadas conectadas en 10s",
    "cms.agent.message.modifypasswordsuccess" : "La contraseña se ha cambiado correctamente.",
    "cms.report.field.busyTimeRate" : "Tasa de duración ocupada",
    "cms.callcenterinstanceslist.title.callCenter" : "Centro de llamadas",
    "cms.indexmonitor.field.totalAgentNum" : "Total de agentes",
    "cms.report.field.acwInTimeField" : "Duración de finalizacion de la llamada entrante",
    "cms.report.message.inAnswerRateDesc" : "Porcentaje del número de llamadas entrantes manuales conectadas al número de llamadas entrantes manuales.",
    "cms.indexmonitor.message.skillConnectedRateDesc" : "Porcentaje del número de solicitudes de llamada entrante manuales conectadas en el número de solicitudes de llamada entrante manuales",
    "cms.callcenterinstanceslist.message.workNoCheckError" : "El número inicial del ID de trabajo debe ser menor que el número final.",
    "cms.qcrelationconf.field.qcGroupName" : "Grupo de Inspectores",
    "cms.report.message.avgPerHNumDesc" : "Número promedio de llamadas exitosas por hora en el segmento de tiempo de trabajo seleccionado.",
    "cms.fullscreen.field.text" : "texto",
    "cms.report.field.leaveReasonField" : "Causa de la salida de la llamada del dispositivo",
    "cms.calllink.field.hold" : "Una llamada esta retenida.",
    "cms.uapmonitor.field.callSize" : "Tipo de terminal",
    "cms.report.message.voicedataOverError" : "El número de archivos exportados es superior a 0. Vuelva a seleccionar los criterios de búsqueda.",
    "cms.agentMonitor.label.forcesignout_success" : "Un agente cerró la sesión con éxito.",
    "cms.report.title.skillTrend" : "Tendencia de rendimiento de la cola de habilidades",
    "cms.callcenterinstanceslist.field.tenantName" : "Nombre del tenant",
    "cms.report.message.exportSucceed" : "Exportación exitosa.",
    "cms.report.title.calldetailoriginal" : "Detalles del contenido de la llamada",
    "cms.report.field.skillQueue" : "Cola de habilidades",
    "cms.report.message.IVRShortCallsDesc" : "Número de llamadas cuya duración del IVR es mayor que 0 y menor o igual a 3 segundos.",
    "cms.agentmonitor.label.cancellistening" : "Cancelar Escucha",
    "cms.indexmonitor.field.skillAbandonIn5" : "Llamadas abandonadas en 5s",
    "cms.systemparam.error.validate.notmatchruledigits" : "Introduzca un entero positivo válido",
    "cms.fullscreen.field.minThreshold" : "Umbral mínimo",
    "cms.indexmonitor.field.skillAbandonIn3" : "Llamadas abandonadas en 3s",
    "cms.monitordetail.field.answerCount" : "Llamadas contestadas",
    "cms.report.message.selectAgentMax" : "Se puede seleccionar un máximo de 100 ID de agente.",
    "cms.qcrelationconf.field.ok" : "OK",
    "cms.monitordetail.field.restoutcount" : "Restos de tiempo de espera",
    "cms.indexmonitor.message.agentAbandonIn10Rate" : "Porcentaje de llamadas abandonadas en 10 segundos respecto al total de llamadas abandonadas",
    "cms.qcrelationconf.field.configAdmin" : "Administrador de configuración",
    "cms.report.field.endMonth" : "Fin de Mes",
    "cms.report.field.call3RateField" : "Tasa de conexión manual de llamadas entrantes en 15 segundos",
    "cms.report.message.maxIVROutCountDesc" : "Número máximo de llamadas salientes simultáneas cuyo dispositivo de servicio es el IVR.",
    "cms.qualitycheck.label.userGroup" : "Grupo de usuarios",
    "cms.indexmonitor.message.ivrTotalCallNum" : "Número de llamadas que son asignadas al IVR y servidas por el IVR completamente",
    "cms.callcenterinstanceslist.field.uapPassword" : "Contraseña del CDE UAP",
    "cms.fullscreen.field.refreshPeriod" : "Periodo de actualización",
    "cms.systemmonitor.field.callRate" : "Tasa de conexión de llamadas al sistema",
    "cms.report.field.billInfo2Field" : "ID único de llamada dual",
    "cms.voiceinspection.field.preViewOutbound" : "vista previa saliente",
    "cms.report.field.uapIdField" : "Número de UAP",
    "cms.vcallecenter.resetpwd.message.fail" : "Error al restablecer la contraseña.",
    "cms.report.message.mediaTypeDesc" : "Tipo de medio de llamada",
    "cms.report.message.avgQueueCountDesc" : "Número promedio de llamadas simultáneas cuyo dispositivo de servicio es una cola de habilidades.",
    "cms.report.message.invalidParam" : "Parametro no válido.",
    "cms.report.field.deviceNoField" : "Número de dispositivo",
    "cms.callcenterinstanceslist.message.maxNumber" : "Se puede añadir un máximo de 10 centros de llamadas.",
    "cms.report.field.avgAgentInCount" : "Número promedio de llamadas entrantes de agente simultáneas",
    "cms.agentMonitor.laber.notInTalking" : "El agente no está en una llamada. Actualice el estado e inténtelo de nuevo",
    "cms.report.field.maxQueueWaitTime" : "Duración máxima de espera",
    "cms.callcenter.message.deletefailed" : "Error en la eliminación.",
    "cms.report.field.minIVRInCountField" : "Número mínimo de llamadas entrantes IVR simultáneas",
    "cms.developerlist.message.deleteConfirmMessage" : "¿Está seguro de que desea eliminar los desarrolladores seleccionados y las configuraciones relacionadas?",
    "cms.report.message.outAboundRingDesc" : "Número de llamadas salientes que son rechazadas por los clientes o que no son contestadas después de sonar.",
    "cms.report.title.filteringHeader" : "Encabezado de tabla de filtro",
    "cms.export.field.unprocessed" : "Sin procesar",
    "cms.vcallecenter.message.syncLoading" : "Operación en curso. Por favor, inténtelo de nuevo más tarde.",
    "cms.report.field.agentAlertingDurationField" : "Duración de alertas de llamada",
    "cms.report.message.acdCallDesc" : "Número de llamadas entrantes y salientes conectadas",
    "cms.indexmonitor.field.skillAbandonIn30" : "Llamadas abandonadas en 30 segundos",
    "cms.report.message.outCallSuccNumberDesc" : "Número de llamadas salientes automáticas y manuales que son contestadas por las partes llamadas. El valor es la suma de las llamadas salientes manuales y las llamadas salientes conectadas automáticamente mediante el IVR.",
    "cms.fullscreen.field.custom" : "Personalización:",
    "cms.report.message.agentServiceDesc" : "Número de llamadas contestadas con éxito por los agentes.",
    "cms.calllink.field.calledBusy" : "La parte llamada está ocupada.",
    "cms.sysparam.config.save" : "Guardar",
    "cms.report.field.outCallNumField" : "Llamadas salientes",
    "cms.report.field.holdTime" : "Duración de espera",
    "cms.fullscreen.field.saveTheme" : "Guardar diseño",
    "cms.calllink.field.unavailableNumber" : "El número no está asignado.",
    "cms.recorddrive.label.button_edit" : "Editar",
    "cms.indexmonitor.field.agentOutboundConnectNum" : "Llamadas salientes manuales conectadas",
    "cms.indexmonitor.field.totalQueueCallNum" : "Total de llamadas en cola",
    "cms.fullscreen.field.chartName" : "Nombre del gráfico",
    "cms.report.message.totalLIVRServiceDesc" : "Duración total de la llamada IVR. Si una llamada está conectada al IVR varias veces, la duración del servicio IVR es la duración total de la llamada IVR.",
    "cms.sendnote.title.selectAgents" : "Agente seleccionado",
    "cms.report.message.IVRCallOutSuccNumDesc" : "Número de llamadas salientes de IVR cuya duración de llamada es mayor que 0.",
    "cms.synchronize.message.syncStatusTwo" : "Sincronización correcta.",
    "cms.report.field.holdCount" : "Tiempos de espera",
    "cms.vcallecenter.label.inputnewpwd" : "Contraseña nueva",
    "cms.indexmonitor.field.agentNum" : "Número de Agentes",
    "cms.systemmonitor.field.talkingNums" : "Agentes que hablan",
    "cms.indexmonitor.field.totalLogoutNum" : "Agentes de cierre de sesión",
    "cms.vcallecenter.title.resetpwd" : "Restablecer contraseña",
    "cms.recorddrive.label.button_cacel" : "Cancelar",
    "cms.indexmonitor.message.agentAbandonIn3NDesc" : "Número total de abandonos con una duración de respuesta superior o igual a 3 segundos",
    "cms.indexmonitor.field.ivrInboundUserAbort" : "Llamadas entrantes IVR liberadas",
    "cms.indexmonitor.field.agentConnectInThirty" : "Llamadas conectadas manualmente en 30s",
    "cms.indexmonitor.field.skillAbandonIn10" : "Llamadas abandonadas en 10s",
    "cms.indexmonitor.field.skillAbandonIn20" : "Llamadas abandonadas en 20s",
    "cms.report.field.submediatype.facebook" : "Facebook",
    "cms.indexmonitor.field.skillAbandonIn3Rate" : "Llamadas abandonadas en 3s",
    "cms.calllink.field.doNotAnswer" : "El suscriptor no contesta una llamada.",
    "cms.datasource.message.mysql8UrlTip" : "Cadena de conexión a base de datos: &#8220jdbc:mysql://ip:port/schema&#8220&#10;ip (dirección IP) puerto (número de puerto) esquema (modo)",
    "cms.failurecall.message.person" : "llamadas canceladas en cola durante",
    "cms.callcenterinstanceslist.message.deleteConfirmMessage" : "¿Está seguro de que desea eliminar el centro de llamadas seleccionado y la configuración relacionada?",
    "cms.monitordetail.field.restcount" : "Descanso",
    "cms.callcenterinstanceslist.field.ccGatewayIp" : "IP de puerta de enlace CC",
    "cms.vcallecenter.message.syncrolefail" : "Error al sincronizar el rol.",
    "cms.callcenterinstanceslist.field.interfacePassword" : "Configurar password de interface (CCBCS)",
    "cms.fullscreen.field.idleCountTimeDesc" : "Número de veces que los agentes están en estado inactivo.",
    "cms.report.field.IVRInSuccRateField" : "Tasa de conexión de llamadas entrantes IVR",
    "cms.report.field.internalCallTimeField" : "Duración (es) de la llamada interna",
    "cms.uapmonitor.labe.mention" : "1. Una vez habilitada la función de monitorización, los agentes cuyo estado de registro de terminal es incompatible con el estado de inicio de sesión del agente se cierran por la fuerza periódicamente,Solo los agentes que han iniciado sesión en las colas de habilidades de voz y vídeo pueden ser forzados a cerrar la sesión.",
    "cms.report.field.filetype" : "Formato de archivo de exportación",
    "cms.report.field.outSuccAnsRateField" : "Tasa de conexión de llamadas salientes",
    "cms.vcallecenter.label.tenantaccount" : "Cuenta de administrador",
    "cms.report.field.IVROutCallNum" : "Llamadas salientes Pure-IVR",
    "cms.report.field.endAgentId" : "ID de agente final",
    "cms.calllink.title.callTrack" : "Pista de llamadas",
    "cms.qualitycheck.label.editusergroupmember" : "Editar miembro del grupo",
    "cms.callcenter.message.createsuccess" : "Creado correctamente.",
    "cms.agentmonitor.label.alarmDetail" : "Detalle",
    "cms.agentmonitor.label.mutilMediaMonitor": "Monitoreo de sesiones multimedia",
    "cms.agentmonitor.label.stopMutilMediaMonitor": "Detener la supervisión de la sesión",
    "cms.reportsubscribe.field.tuesday" : "Martes",
    "cms.callcenterinstanceslist.message.voiceCallNumsError" : "El número máximo de llamadas de voz simultáneas debe ser mayor o igual que la suma del número de asientos de voz y el número de canales IVR.",
    "cms.calllink.field.noAnswerTransfer" : "Una llamada se transfiere porque no se recibe respuesta de un agente durante mucho tiempo.",
    "cms.report.field.utilizationRate" : "Tasa de duración del estado del trabajo",
    "cms.calllink.title.callTrace" : "Enlace de llamada",
    "cms.report.message.monthRangeCheck" : "La hora de inicio y la hora de finalización deben ser del mismo mes",
    "cms.agentmonitor.label.cancelsuccess" : "Cancelación exitosa.",
    "cms.uapmonitor.message.intervalvalidate" : "Introduzca un número que va de 300 a 86400.",
    "cms.qualitycheck.label.edit" : "Editar",
    "cms.agentMonitor.label.forcesignout_error" : "Error al cerrar la sesión como agente.",
    "cms.report.field.auxTimeRateField" : "Tasa de duración de descanso",
    "cms.qualitycheck.label.save" : "Guardar",
    "cms.qcrelationconf.field.qcAccountName" : "Nombre del inspector",
    "cms.report.field.year" : "Año",
    "cms.systemparam.error.validate.ruleregexinvalid" : "Error de sintaxis de regla de validación",
    "cms.indexmonitor.field.skillConnectedRate" : "Solicitudes de llamada conectadas",
    "cms.fullscreen.field.avgCallTimeDesc" : "Duración media de las llamadas contestadas por los agentes.",
    "cms.report.field.minIVRCountField" : "Número mínimo de llamadas IVR simultáneas",
    "cms.report.message.ans20sDesc" : "Porcentaje del número de llamadas conectadas manualmente en 20 segundos en el número total de llamadas conectadas manualmente.",
    "cms.failurecall.field.waitTime" : "Duración de espera",
    "cms.export.message.downloadSucceed" : "El informe se descargo correctamente.",
    "cms.report.field.callEndTimeField" : "Hora de finalización de llamada",
    "cms.qcrelationconf.field.addGroupMember" : "Agregar miembro de grupo",
    "cms.report.message.assistTimeDesc" : "Duración de las solicitudes de ayuda interna.",
    "cms.indexmonitor.field.agentName" : "Nombre del inquilino",
    "cms.systemmonitor.label.thirtyminutes" : "30 minutos",
    "cms.synchronize.message.syncStatus" : "Estado de sincronización",
    "cms.report.field.hourandminute" : "Tiempo",
    "cms.report.field.acwRateField" : "Tasa de duración de cierre",
    "cms.agentMonitor.label.querynum_success" : "Número de teléfono:",
    "cms.callcenterinstanceslist.message.passWordLengthValidateFailed" : "La contraseña debe contener entre 8 y 20 caracteres.",
    "cms.report.message.IVRInSuccDesc" : "Número de llamadas entrantes de IVR cuya duración de llamada es mayor que 0.",
    "cms.indexmonitor.message.skillAvgQueueAckDesc" : "Duración media de espera de llamadas Duración media de espera (en cola y duración del timbre) de todas las llamadas.",
    "cms.report.message.minQueueInCountDesc" : "Número mínimo de llamadas entrantes simultáneas cuyo dispositivo de servicio es una cola de habilidades.",
    "cms.indexmonitor.message.agentAbandonIn60NDesc" : "Número total de abandonos con una duración de respuesta superior o igual a 60 segundos",
    "cms.indexmonitor.field.skillOverAgent" : "Llamadas de desbordamiento a agentes",
    "cms.report.label.header" : "Establecer encabezado de tabla",
    "cms.report.field.outWaitAnsNumField" : "Llamadas salientes manuales",
    "cms.report.field.accessNo" : "Código de acceso:",
    "cms.report.field.maxIVRCountField" : "Número máximo de llamadas IVR simultáneas",
    "cms.agentmonitor.label.enterID" : "Ingrese un ID de agente",
    "cms.agentmonitor.label.insets" : "Inserción",
    "cms.report.title.saveError" : "Error",
    "cms.report.field.internalCallCountField" : "Llamadas internas",
    "cms.qcrelationconf.field.workNo" : "ID de agente",
    "cms.report.field.acwTime" : "Duración total del cierre",
    "cms.report.field.maxIVROutCountField" : "Número máximo de llamadas salientes IVR simultáneas",
    "cms.report.field.inOccupyNumField" : "Solicitudes de servicio",
    "cms.report.field.waitBeginField" : "Hora de inicio de espera",
    "cms.callcenter.message.createfailed" : "Error de creación.",
    "cms.report.field.call4RateField" : "Tasa de conexión manual de llamadas entrantes en 20 segundos",
    "cms.agentmonitor.label.intercept" : "interceptación",
    "cms.report.message.hangUpModeDesc" : "Método colgado.",
    "cms.sendnote.message.tipsError" : "Error al enviar una nota.",
    "cms.indexmonitor.message.agentConnectedIn60NDesc" : "Número total de llamadas cuya duración de respuesta es mayor o igual a 60 segundos",
    "cms.reportsubscribe.field.templateName" : "Nombre de la plantilla de correo electrónico",
    "cms.report.field.succWaitAnsRate" : "Duración promedio de colas de llamadas conectadas",
    "cms.agentMonitor.label.cancelInsert_success" : "La inserción se cancela correctamente.",
    "cms.agentmonitor.label.header" : "Establecer encabezado de tabla",
    "cms.report.message.validateFailed" : "Error al comprobar la exportación del informe.",
    "cms.report.message.outAvgAnsTimeDesc" : "Duración media del timbre de las llamadas salientes = Duración total del timbre de las llamadas salientes/Número de tiempos de respuesta",
    "cms.indexmonitor.message.totalBusyNum" : "Número de agentes en estado ocupado",
    "cms.report.field.agentOutSucc" : "Llamadas salientes manuales conectadas",
    "cms.reportsubscribe.message.modifyStatus" : "¿Está seguro de modificar el estado?",
    "cms.report.message.queryTimeCheck" : "La hora no puede ser anterior a {0}.",
    "cms.report.field.maxAgentInCount" : "Número máximo de llamadas entrantes de agente simultáneas",
    "cms.systemparam.error.validate.notmatchrulemaxlength" : "excede la longitud máxima 0",
    "cms.agentMonitor.label.intercept_error" : "La interceptación falló.",
    "cms.report.field.notConnected" : "Desconectado",
    "cms.indexmonitor.message.queueOutCallCount" : "Número de llamadas salientes que se ponen en cola en las colas de habilidades.",
    "cms.report.field.ackBeginField" : "Hora de inicio de la respuesta",
    "cms.indexmonitor.field.ccName" : "Nombre del centro de llamadas",
    "cms.indexmonitor.field.queueInCallCount" : "Número de llamadas entrantes en cola en línea",
    "cms.systemmonitor.field.ok" : "OK",
    "cms.agentMonitor.label.success" : "Éxito",
    "cms.report.field.busyCountField" : "Tiempos ocupados",
    "cms.agentMonitor.label.switchNotice" : "Utilice esta función cuando se escucha o se interrumpe el agente actual.",
    "cms.report.field.endTimeField" : "call Hora de finalización",
    "cms.fullscreen.message.numberCheck" : "El valor debe ser 0, un entero positivo o un decimal positivo con un máximo de dos decimales.",
    "cms.report.field.sysSuccRateField" : "Tasa de éxito de llamadas entrantes del sistema",
    "cms.recorddrive.field.configSftpPath" : "Ruta de archivo",
    "cms.callcenterinstanceslist.field.open" : "Activar",
    "cms.report.title.abandonRingMonitor" : "Resumen de las llamadas abandonadas durante el timbre",
    "cms.agentmonitor.label.busy" : "Forzar Ocupado",
    "cms.report.field.inCallSuccRateField" : "Tasa de conexión de llamadas entrantes",
    "cms.skilladjustment.message.tipsOutSide" : "Se puede ajustar un máximo de 10 agentes a la vez.",
    "cms.report.label.reset" : "Restablecer",
    "cms.systemmonitor.field.ideaAgentNum" : "Agentes inactivos",
    "cms.callcenterinstanceslist.message.updateFailed" : "Error al modificar el centro de llamadas.",
    "cms.agentmonitor.label.terminalStatus" : "Estado de registro de terminal",
    "cms.report.field.outInvalidCall" : "Llamadas salientes no válidas",
    "cms.qcrelationconf.placeholder.pleaseInput" : "Por favor ingrese",
    "cms.callcenter.field.savesuccess" : "Éxito",
    "cms.monitordetail.field.calling" : "Llamar",
    "cms.vdnconfiguration.field.ok" : "OK",
    "cms.agentMonitor.label.forcebusy_success_calling" : "Un agente se establece a la fuerza en el estado ocupado con éxito. Una vez finalizada la llamada, el agente entra en el estado ocupado.",
    "cms.report.field.startMonth" : "mes de inicio",
    "cms.indexmonitor.message.skillAvgAbandonAckDesc" : "Duración media del timbre de todas las llamadas sin respuesta",
    "cms.report.label.search" : "Buscar",
    "cms.report.field.succAnsRate" : "Tasa de conexión de solicitud",
    "cms.systemmonitor.field.onlineAgentNums" : "Agentes en línea",
    "cms.report.field.internalTransferCount" : "Transferencias Internas",
    "cms.report.field.ivrtrafficreport.avgivrservice" : "Duración promedio de las llamadas entrantes de IVR",
    "cms.report.field.inVdnAvgTimeField" : "Duración media de las llamadas entrantes en VDN",
    "cms.recorddrive.label.button_complete" : "Completo",
    "cms.indexmonitor.message.agentConnectedOut10NDesc" : "Número total de llamadas conectadas cuya duración de respuesta es superior a 10 segundos.",
    "cms.qualitycheck.message.adjustskill" : "La cola de habilidades se ha cambiado temporalmente a {skillList}. Después de iniciar sesión de nuevo, se restaurará la cola de habilidades.",
    "cms.report.field.callAbandonedRing" : "Número de llamadas entrantes manuales perdidas",
    "cms.report.field.acdCallField" : "Total de llamadas conectadas",
    "cms.report.field.succWaitAnsRateField" : "Duración media de espera",
    "cms.recorddrive.title.sftpIdentityCheck" : "Verificación de identidad",
    "cms.report.field.transferIvrCountField" : "Tiempos de transferencia manual a IVR",
    "cms.report.field.callIdNumField" : "Número de secuencia de CDR",
    "cms.report.message.call4RateDesc" : "Porcentaje del número de solicitudes de llamada conectadas manualmente en 20 segundos en el número total de llamadas que solicitan servicios manuales.",
    "cms.report.message.agentOutSuccRateDesc" : "Porcentaje de llamadas salientes manuales conectadas al total de llamadas salientes manuales.",
    "cms.indexmonitor.title.ivrDayTrend" : "Tendencia actual del IVR",
    "cms.indexmonitor.message.ivrInboundConnectRate" : "Porcentaje del número de llamadas entrantes de IVR conectadas en el número de solicitudes de llamadas entrantes de IVR",
    "cms.indexmonitor.field.totalAgentConnectCallNum" : "Tiempos de conversación",
    "cms.callcenterinstanceslist.field.nodemodesingle" : "Modo único",
    "cms.indexmonitor.field.durationAvgWait" : "Duración media de espera de llamadas entrantes",
    "cms.indexmonitor.label.previous" : "Anterior",
    "cms.indexmonitor.field.agentConnectAfterInSixty" : "Llamadas conectadas manualmente después de 60s",
    "cms.callcenterselect.title.selectCcName" : "Seleccionar centro de llamadas",
    "cms.report.field.awarnessRate" : "Tasa de éxito de llamadas entrantes manuales",
    "cms.vcallecenter.message.syncskillfail" : "Error al sincronizar las habilidades.",
    "cms.indexmonitor.message.accesscodeCalloutNum" : "Número de llamadas salientes realizadas por números de llamada dentro de una hora",
    "cms.indexmonitor.field.skillOnlineWaitCall" : "Llamadas en cola en línea",
    "cms.agentmonitor.label.qcstate_error" : "Error al cancelar la operación. La operación de control de calidad no se realiza actualmente en el agente seleccionado",
    "cms.datasource.message.ccDsRelationExist" : "El origen de datos ha sido referenciado en el centro de llamadas.",
    "cms.report.title.callCenterAccessReport" : "Reporte de tráfico VDN por cód de acceso",
    "cms.report.field.alertingTimeField" : "Hora de alertas de llamada",
    "cms.report.message.avgIVROutCountDesc" : "Número medio de llamadas salientes simultáneas cuyo dispositivo de servicio es el IVR.",
    "cms.report.field.onehour" : "1 hora",
    "cms.indexmonitor.label.next" : "Siguiente",
    "cms.callcenterinstanceslist.field.cmsAuthName" : "Cuenta de autenticación CCDIS",
    "cms.agent.message.modifypasswordhintdetail" : "Ingrese la contraseña.",
    "cms.report.field.minIVRCount" : "Número mínimo de llamadas IVR simultáneas",
    "cms.report.message.inCallSuccNumDesc" : "Llamadas entrantes conectadas al agente.",
    "cms.agentMonitor.label.reset" : "Restablecer",
    "cms.report.message.sysLostCallsDesc" : "Número de llamadas entrantes del sistema fallidas = Número total de llamadas entrantes del sistema – Número de llamadas entrantes del sistema correctas.",
    "cms.indexmonitor.message.skillOverAgentDesc" : "Número de llamadas transferidas desde la cola de habilidades original a otros agentes debido a un desbordamiento",
    "cms.report.field.agentId" : "ID de agente",
    "cms.report.message.logoutCountDesc" : "Número de horas de cierre de sesión.",
    "cms.report.field.sysSuccRate" : "Tasa de éxito de llamadas entrantes",
    "cms.reportsubscribe.field.enable" : "Publicado",
    "cms.systemparam.error.validate.notmatchruleip" : "Ingrese una dirección IPV4 o IPV6 válida",
    "cms.report.field.IVROutSuccRateField" : "Tasa de conexión de llamadas salientes Pure-IVR",
    "cms.qualitycheck.label.groupname" : "Nombre del grupo",
    "cms.report.field.submediatype.wechat" : "WeChat",
    "cms.report.field.acwCount" : "Tiempos de Recapitulación",
    "cms.report.field.uvidField" : "ID de acceso de usuario",
    "cms.indexmonitor.field.agentInboundNum" : "Llamadas entrantes manuales",
    "cms.indexmonitor.field.skillAbandon" : "Solicitudes de llamada perdidas",
    "cms.report.field.mediatype.H5Vedio" : "Vídeo H5",
    "cms.qualitycheck.title.saveError" : "Error",
    "cms.indexmonitor.message.skillAbandonInNRateDesc" : "Porcentaje del número de solicitudes de llamada abandonadas manualmente en Ns en el número total de servicios de usuario que solicitan llamadas",
    "cms.voiceinspection.field.download" : "Descargar",
    "cms.monitordetail.field.holddurationavg" : "Duración media de la tenencia",
    "cms.report.field.busyCount" : "Tiempos ocupados",
    "cms.indexmonitor.message.errorAgentInfo" : "Seleccione al menos un agente.",
    "cms.fullscreen.field.oneMin" : "1 Minuto",
    "cms.report.message.avgIVRCountDesc" : "Número promedio de llamadas simultáneas cuyo dispositivo de servicio es el IVR.",
    "cms.report.field.agentOutBoundField" : "Llamadas salientes manuales",
    "cms.systemmonitor.field.queryTime" : "Tiempo de consulta",
    "cms.calllink.field.cdnDevice" : "Dispositivo CDN",
    "cms.report.field.byDay" : "Informe diario",
    "cms.report.message.userNoAnswerNumDesc" : "Número de llamadas que no se contestan debido al tiempo de espera de timbre.",
    "cms.report.message.acwTimeRateDesc" : "Tasa de duración del cierre = (Duración total del cierre/Duración total del trabajo) x 100",
    "cms.agentMonitor.label.details" : "Detalles",
    "cms.voiceinspection.message.downloadFailed" : "Error al descargar la grabación.",
    "cms.sendnote.message.checkInput" : "Caracteres no válidos.",
    "cms.report.field.acwCountField" : "Tiempos de recapitulación",
    "cms.calllink.msg.calleePlaceHolder" : "Ingrese el número llamado",
    "cms.systemmonitor.label.noncommercial" : "Uso comercial de prueba",
    "cms.report.message.dataOverError" : "Consultar {0} tamaño de datos superior a 10000.",
    "cms.uapmonitor.field.callOperator" : "Operación",
    "cms.report.field.calleeNo" : "Código de acceso",
    "cms.recorddrive.placeholder.deleteRecorddrive" : "Seleccione la letra de unidad que desea eliminar",
    "cms.indexmonitor.field.skillAvgConnected" : "Duración media de la llamada",
    "cms.datasource.field.dbName" : "Nombre de origen de datos",
    "cms.indexmonitor.field.totalConnectCallNum" : "Total de llamadas conectadas",
    "cms.qualitycheck.label.qualityInspectionGroup" : "Grupo de inspección de calidad",
    "cms.vcallecenter.title.tips" : "Información de contacto",
    "cms.report.message.muteCountDesc" : "Número de veces de silencio.",
    "cms.callcenterinstanceslist.message.resourceError" : "La información de recursos modificada es menor que el número real de centros de llamadas.",
    "cms.report.message.minAgentInCountDesc" : "Número mínimo de llamadas entrantes simultáneas cuyo dispositivo de servicio es un agente.",
    "cms.indexmonitor.field.SkillAbandonRate" : "Solicitudes de llamadas perdidas",
    "cms.voiceinspection.field.callbackRequest" : "Solicitud de devolución de llamada",
    "cms.qualitycheck.label.create" : "Crear",
    "cms.report.field.agentHangNumCti" : "Suspensión de Agentes",
    "cms.agentmonitor.label.all" : "Todo",
    "cms.qcrelationconf.field.groupType" : "Tipo de grupo de usuarios",
    "cms.report.field.startWeek" : "Comienza la semana",
    "cms.indexmonitor.title.skillDayTrend" : "Tendencia de hoy de la cola de habilidades",
    "cms.indexmonitor.message.skillMaxQueueAckDesc" : "Duración máxima de espera (en cola y duración del timbre) de todas las llamadas",
    "cms.report.title.dualCallDetail" : "Detalle de llamada dual",
    "cms.report.message.systemInBoundDesc" : "Número total de llamadas entrantes del sistema (excluyendo las llamadas entrantes internas).",
    "cms.report.field.ackEndField" : "Hora de finalización de la respuesta",
    "cms.report.message.agentLoginNumDesc" : "Número de agentes que reciben llamadas entrantes.",
    "cms.report.title.callCenterAccessTrend" : "Tendencia de rendimiento de VDN por código de acceso",
    "cms.report.field.minIVROutCount" : "Número mínimo de llamadas salientes de IVR simultáneas",
    "cms.fullscreen.message.saveLayouts" : "El diseño no se guarda. Guárdalo primero.",
    "cms.developerlist.message.deletesuccess" : "Eliminar correctamente.",
    "cms.indexmonitor.message.agentConnectIn60Rate" : "Porcentaje del número de llamadas conectadas manualmente en 60 segundos (incluidos 60 segundos) con respecto al número total de llamadas.",
    "cms.report.message.leaveReasonDesc" : "Causa de la salida de la llamada del dispositivo",
    "cms.vcallecenter.label.renewpwd" : "Confirmar contraseña",
    "cms.report.field.avgQueueInCountField" : "Número medio de llamadas entrantes simultáneas en una cola",
    "cms.qcrelationconf.message.specialChar" : "Carácter no válido",
    "cms.fullscreen.field.add" : "Crear",
    "cms.voiceinspection.field.textChat" : "Chat de texto",
    "cms.report.field.avgAnsTimeField" : "Duración media de la cola de las llamadas conectadas",
    "cms.uapmonitor.field.callStatus" : "Estado de registro",
    "cms.fullscreen.message.selectAgentMax" : "Se puede seleccionar un máximo de 5 agentes.",
    "cms.vcallecenter.label.resetpwd" : "Restablecer contraseña de administrador",
    "cms.voiceinspection.message.downloadSucceed" : "La grabación se descarga correctamente.",
    "cms.report.message.alertingTimeDesc" : "Hora de alerta de llamada.",
    "cms.callcenterinstanceslist.field.single" : "Modo único",
    "cms.agentmonitor.label.release" : "Fuerza de liberación",
    "cms.report.field.submediatype.sms" : "SMS",
    "cms.qualitycheck.label.configQcInformation" : "Información de control de calidad de configuración",
    "cms.callcenterinstanceslist.label.delete" : "Borrar",
    "cms.qcrelationconf.title.success" : "Éxito",
    "cms.agentmonitor.label.showAlarmAgentNo" : "ID de agente",
    "cms.agentmonitor.label.offline" : "Desconectado",
    "cms.report.message.alertingDurationDesc" : "Duración de alerta llamada.",
    "cms.fullscreen.field.reportType" : "Tipo de informe",
    "cms.voiceinspection.message.errorContent" : "Error al cargar el recurso. Verifique la configuración.",
    "cms.report.field.confirm" : "OK",
    "cms.callcenterinstanceslist.message.ccIdError" : "Se ha utilizado el número del centro de llamadas.",
    "cms.report.message.IVRCallOutSuccRateDesc" : "Porcentaje de llamadas salientes de IVR conectadas al total de solicitudes de llamadas salientes de IVR.",
    "cms.report.field.waitCauseField" : "Causa de espera",
    "cms.report.message.failQueueWaitRateDesc" : "Duración media del timbre de todas las llamadas sin respuesta.",
    "cms.indexmonitor.message.agentAbandonOut60NDesc" : "Número total de llamadas abandonadas cuya duración de respuesta es superior a 60 segundos.",
    "cms.indexmonitor.title.error" : "Error",
    "cms.sysparam.config.search" : "Buscar",
    "cms.datasource.message.already" : "El nombre del origen de datos ya existe.",
    "cms.agentMonitor.laber.hasbeenCheck" : "El agente ha sido inspeccionado.",
    "cms.agentMonitor.laber.hasbeenMediaCheck": "El agente ha sido supervisado por una sesión multimedia",
    "cms.agentMonitor.laber.stopMediaMonitor": "Monitoreo de sesión multimedia detenido",
    "cms.callcenterinstanceslist.field.uapPasswordConfirm" : "Confirmar contraseña UAP CDE",
    "cms.agentmonitor.label.showAlarmDetail" : "Visualización de detalles de alarma",
    "cms.report.field.utilizationRateField" : "Tasa de duración del estado de trabajo",
    "cms.report.field.calleeNoField" : "Número llamado",
    "cms.calllink.field.agentRelease" : "El agente desconecta una llamada.",
    "cms.agentMonitor.label.talk_error" : "El agente seleccionado no está en el estado de conversación.",
    "cms.callcenterinstanceslist.field.normalStatus" : "Normales",
    "cms.indexmonitor.message.totalAgentCallNumDesc" : "Número total de llamadas conectadas (período estadístico: inicio de sesión actual)",
    "cms.agentmonitor.label.alarmState" : "Monitor de alarma",
    "cms.report.message.acwRateDesc" : "Tasa de duración del cierre = (Duración total del cierre/Duración total del trabajo) x 100",
    "cms.synchronize.message.notConfigured" : "Sin configurar",
    "cms.report.message.internalCallTimeDesc" : "Duración interna de la llamada.",
    "cms.developer.message.addDeveloper" : "Añadir información del desarrollador",
    "cms.agentmonitor.label.monitorState" : "Estado de la inspección",
    "cms.indexmonitor.field.agentConnectInSixty" : "Llamadas conectadas manualmente en 60s",
    "cms.report.message.onlyIVRServiceDesc" : "Número de llamadas entrantes exitosas procesadas solo por el IVR, es decir, número de llamadas entrantes exitosas que no son procesadas por ningún agente.",
    "cms.callcenterinstanceslist.field.close" : "Desactivar",
    "cms.callcenterinstanceslist.title.success" : "Éxito",
    "cms.callcenterinstanceslist.message.uapNode2Info" : "Nodo UAP 2",
    "cms.voiceinspection.field.endTime" : "Hora de finalización de grabación",
    "cms.report.message.outOccupyNumDesc" : "Número de llamadas realizadas por un agente a los clientes.",
    "cms.report.field.assistsCountField" : "Solicitudes de ayuda interna",
    "cms.export.message.addExportTaskSuccess" : "La nueva tarea de exportación se ha realizado correctamente",
    "cms.report.field.inVdnAvgTime" : "Duración promedio de llamadas entrantes en VDN",
    "cms.export.message.passValidateFailed" : "El valor es una cadena de 8 a 12 caracteres y debe contener letras, dígitos y caracteres especiales (excluyendo espacios de |;&$-<>/`!#(){}, y caracteres de tabulación).",
    "cms.report.field.subMediaTypeField" : "Tipo de submedios",
    "cms.vdnconfiguration.field.defaultCallCenter" : "Centro de llamadas predeterminado",
    "cms.indexmonitor.message.agentAbandonIn30Rate" : "Porcentaje de llamadas abandonadas en 30 segundos respecto al total de llamadas abandonadas",
    "cms.uapmonitor.title.regist" : "Estado de registro de terminal",
    "cms.agentMonitor.label.insets_error" : "Error en la inserción.",
    "cms.indexmonitor.field.ivrDurationAvgConnect" : "Duración media de las llamadas IVR",
    "cms.indexmonitor.title.agentDayTrend" : "La Tendencia de Hoy del Agente",
    "cms.datasource.field.passwordConfirm" : "Confirmar contraseña",
    "cms.qcrelationconf.placeholder.accountCode" : "Ingrese una cuenta de empresa.",
    "cms.report.field.connected" : "Conectado",
    "cms.export.field.addExportTask" : "Nueva tarea de exportación",
    "cms.agentmonitor.label.listenedAndInserted" : "Inspeccionado",
    "cms.report.message.callTypeDesc" : "Tipo de llamada",
    "cms.calllink.field.noOnDutyTransfer" : "Una llamada se transfiere porque nadie está de servicio.",
    "cms.indexmonitor.field.totalAwcNum" : "Agentes en estado de recapitulación",
    "cms.indexmonitor.field.agentConnectAfterInTen" : "Llamadas conectadas manualmente después de 10s",
    "cms.report.field.ringTimeField" : "Duración total del anillo",
    "cms.reportsubscribe.field.status" : "Estado",
    "cms.vdnconfiguration.field.cancel" : "Cancelar",
    "cms.uapmonitor.labe.openmonitor" : "Monitoreo",
    "cms.report.field.agentOutSuccRateField" : "Tasa de conexión manual de llamadas salientes",
    "cms.indexmonitor.field.agentConnectInThree" : "Llamadas conectadas manualmente en 3s",
    "cms.sendnote.field.cancel" : "Cancelar",
    "cms.callcenterinstanceslist.message.cleanKafka" : "Borrar configuración de nodo KAFKA",
    "cms.report.message.outCallTimeDesc" : "Duración total de la llamada saliente entre un agente y los clientes.",
    "cms.export.message.nocount" : "No se encuentran datos que cumplan las condiciones.",
    "cms.report.message.succAnsRateDesc" : "Porcentaje del número de solicitudes de llamadas entrantes manuales conectadas en el número de llamadas entrantes manuales que solicitan servicios.",
    "cms.report.message.internalTransferCountDesc" : "Número de tiempos de transferencia interna.",
    "cms.fullscreen.message.addVdnMost" : "Se puede seleccionar un máximo de 10 inquilinos.",
    "cms.indexmonitor.message.totalLoginNumDesc" : "Número de agentes que han iniciado sesión en el centro de llamadas y manejan solo las llamadas entrantes",
    "cms.vcallcenter.message.unknowcause" : "Causa desconocida",
    "cms.systemmonitor.field.ccName" : "Nombre del centro de llamadas",
    "cms.callcenterinstanceslist.field.cmsPort" : "Puerto CMS LB",
    "cms.agentmonitor.label.queueAdjustment" : "Ajustar colas",
    "cms.qualitycheck.title.delete" : "Error de eliminación",
    "cms.report.field.unspecifiedUserLevel" : "No especificado",
    "cms.qualitycheck.label.userGroupMemberConfig" : "Configurar miembros del grupo de usuarios",
    "cms.report.message.avgQueueInCountDesc" : "Número promedio de llamadas entrantes simultáneas cuyo dispositivo de servicio es una cola de habilidades.",
    "cms.voiceinspection.field.nonRealTimeCall" : "Llamada no en tiempo real",
    "cms.report.field.mediatype.vedio" : "Vídeos",
    "cms.monitordetail.field.sipNumber" : "Llamada de inicio de sesión",
    "cms.calllink.title.mediaType" : "Tipo de medios",
    "cms.report.field.avgInRingTimeField" : "Duración media de las llamadas entrantes de los terminales",
    "cms.indexmonitor.message.queueInCallCount" : "Número de llamadas entrantes que se ponen en cola en las colas de habilidades.",
    "cms.qualitycheck.label.queryQcRelation" : "Consultar relaciones de inspección",
    "cms.fullscreen.field.reportTime" : "Periodo de Recopilación",
    "cms.report.field.maxQueueInCountField" : "Número máximo de llamadas entrantes simultáneas en una cola",
    "cms.report.field.avgIVRInCount" : "Número promedio de llamadas entrantes IVR simultáneas",
    "cms.fullscreen.field.color" : "Colores",
    "cms.agentmonitor.label.possessingSkills" : "Cola de inicio de sesión",
    "cms.agentMonitor.label.queryNum" : "Establecer el número de inicio de sesión",
    "cms.agentMonitor.label.queryNumSuccess": "Establecer el número de inicio de sesión correctamente",
    "cms.agentMonitor.label.queryNumFailed": "Error al establecer el número de inicio de sesión",
    "cms.calllink.field.callRejected" : "La parte llamada rechaza una llamada.",
    "cms.report.title.vdnSystemPerformanceTrend" : "Tendencia de rendimiento del sistema",
    "cms.report.message.maxIVRCountDesc" : "Número máximo de llamadas simultáneas cuyo dispositivo de servicio es el IVR.",
    "cms.datasource.field.cmsDatabase" : "Base de datos de informes CMS",
    "cms.report.field.createTimeField" : "Crear hora",
    "cms.indexmonitor.field.abandonInSixty" : "Llamadas abandonadas en 60 años",
    "cms.vdnconfiguration.label.configure" : "Conjunto",
    "cms.callcenterinstanceslist.label.assignDeveloper" : "Desarrollador Asociado",
    "cms.qualitycheck.label.unselectedGroup" : "Grupos de usuarios no seleccionados",
    "cms.report.title.filteringIndicator" : "Indicador de filtro",
    "cms.callcenterinstanceslist.field.kafkaPushSwitch" : "KAFKA Empuje de datos",
    "cms.datasource.label.edit" : "Editar",
    "cms.skilladjustment.title.tips" : "Información de contacto",
    "cms.report.message.calleeNoDesc" : "Número llamado",
    "cms.datasource.field.isBackup" : "Base de datos de copia de seguridad",
    "cms.agentMonitor.label.batchSelectAgent" : "Solo puede seleccionar un agente.",
    "cms.report.message.avgCallInTimeDesc" : "Duración promedio de la llamada. Duración media de las llamadas entrantes manuales conectadas = Duración de las llamadas entrantes manuales conectadas/Número de llamadas entrantes manuales conectadas.",
    "cms.indexmonitor.message.agentAbandonIn5NDesc" : "Número total de abandonos con una duración de respuesta superior o igual a 5 segundos",
    "cms.report.field.agentHangNumCtiField" : "Cuelgues de agentes",
    "cms.report.field.ans20s" : "Tasa de conexión de llamada manual en 20s",
    "cms.uapmonitor.field.unregister" : "no registrarse",
    "cms.report.field.startTimeField" : "call Hora de inicio",
    "cms.fullscreen.message.digitsCheck" : "El valor debe ser un entero positivo",
    "cms.report.title.ivrOutboundReport" : "Informe de llamada saliente de IVR",
    "cms.report.message.loginCountDesc" : "Número de veces de inicio de sesión.",
    "cms.callcenterinstanceslist.field.ccStatus" : "Estado del centro de llamadas",
    "cms.voiceinspection.message.errorTime" : "La consulta de fecha no puede cruzar meses.",
    "cms.report.field.internalCallCount" : "Llamadas internas",
    "cms.callcenterinstanceslist.message.workNoError" : "El ID de empleado inicial debe estar en el rango de 101 a 50000.",
    "cms.agentmonitor.label.cancelInsert" : "Cancelar Inserción",
    "cms.indexmonitor.field.abandonInThree" : "Llamadas abandonadas en 3s",
    "cms.export.field.exportEndTime" : "Hora de finalización de exportación",
    "cms.report.field.inCallNum" : "Llamadas entrantes",
    "cms.report.message.acwInTimeDesc" : "Duración de recapitulación después de finalizar las llamadas entrantes.",
    "cms.datasource.message.passValidateFailed" : "El valor no puede contener \"eval\", \"exec\" y los siguientes caracteres especiales:<=>/\"",
    "cms.report.field.inCallRateField" : "Duración media de la llamada",
    "cms.vcallecenter.field.confirmnewpassword" : "Confirmar contraseña",
    "cms.report.message.auxTimeDesc" : "Duración total de la licencia de un agente o duración cuando el agente está en estado de reposo.",
    "cms.report.message.agentOutBoundDesc" : "Número de llamadas salientes manuales realizadas por los agentes.",
    "cms.sysparam.config.select.count" : "Se puede seleccionar un máximo de 10 parámetros.",
    "cms.report.field.muteCount" : "Tiempos de silencio",
    "cms.callcenterinstanceslist.message.needKafkaSSL" : "Activar SSL",
    "cms.report.field.agentTalkingTimeField" : "Tiempo de conversación de llamada",
    "cms.callcenterinstanceslist.label.synchronize" : "Sincronización",
    "cms.report.message.internalCallCountDesc" : "Número de llamadas internas.",
    "cms.indexmonitor.message.skillAvgConnectedQueueDesc" : "Duración media de la cola de todas las llamadas conectadas en una cola",
    "cms.report.field.outAvgRingTime" : "Duración promedio del timbre de las llamadas salientes manuales",
    "cms.report.message.assistsCountDesc" : "Número de solicitudes de ayuda interna.",
    "cms.callcenterinstanceslist.message.clearUapConfig" : "¿Está seguro de que desea eliminar la configuración de nodos UAP?",
    "cms.report.field.acwTimeRate" : "Tasa de duración de cierre",
    "cms.report.message.IVRCallOutNumDesc" : "Número total de llamadas salientes de IVR.",
    "cms.fullscreen.field.acwCountTimeDesc" : "Número de veces que los agentes están en estado de cierre.",
    "cms.report.field.agentInBoundField" : "Llamadas entrantes manuales",
    "cms.report.field.call6RateField" : "Tasa de conexión manual de llamadas entrantes en 30 segundos",
    "cms.report.message.userBusyFailNumDesc" : "Número de llamadas que no se contestan debido a las líneas ocupadas.",
    "cms.indexmonitor.message.totalQueueCallNum" : "Número de llamadas en cola en las colas de habilidades y en espera de los servicios del agente",
    "cms.callcenterinstanceslist.field.uapIndexMonitor" : "Monitorización del indicador del sistema UAP",
    "cms.report.field.inCallSuccNumField" : "Número de llamadas manuales conectadas",
    "cms.report.field.avgQueueCount" : "Número promedio de llamadas simultáneas en una cola",
    "cms.callcenterinstanceslist.label.refresh" : "Refrescar",
    "cms.report.message.talkDurationDesc" : "Duración de la conversación.",
    "cms.report.field.avgAcdTime" : "Duración promedio de la llamada manual",
    "cms.callcenter.message.selectcallcent" : "Seleccione un centro de llamadas.",
    "cms.synchronize.message.noConfAdminPwd" : "La contraseña no está configurada. Configure la contraseña del administrador.",
    "cms.monitordetail.field.adjustDuration" : "Duración (es) de la organización",
    "cms.agentMonitor.label.forcebusy_stateerror" : "El agente está descansando. No indique ocupado",
    "cms.agentMonitor.label.forcebusy_talking": "El agente está en una llamada. No indique ocupado",
    "cms.agentMonitor.label.forceidle_talking": "El agente está en una llamada. No muestre inactivo",
    "cms.synchronize.message.incorrectPassword" : "Incorrecto",
    "cms.indexmonitor.message.skillOnlineInbondCallDesc" : "Número de llamadas que se están procesando + Número de llamadas que están en cola",
    "cms.report.message.minAgentCountDesc" : "Número mínimo de llamadas simultáneas cuyo dispositivo de servicio es un agente.",
    "cms.systemmonitor.field.busyNums" : "Agentes ocupados",
    "cms.agentmonitor.label.whisper" : "Susurro",
    "cms.report.field.acdTimeRate" : "Tasa de duración de llamada manual",
    "cms.recorddrive.field.sftpIpadress" : "Dirección IP SFTP",
    "cms.report.message.minIVRInCountDesc" : "Número mínimo de llamadas entrantes simultáneas cuyo dispositivo de servicio es el IVR.",
    "cms.recorddrive.label.IPAddress_add" : "Dirección IP",
    "cms.report.message.accessCodeDesc" : "Código de acceso.",
    "cms.indexmonitor.field.callNum" : "Número de llamadas",
    "cms.indexmonitor.field.realTimeCalls": "llamadas en tiempo real",
    "cms.indexmonitor.field.callsOfTheDay": "llamadas del dia",
    "cms.indexmonitor.field.pageDataUpdateTime" : "Datos actualizados:",
    "cms.report.message.callInCauseDesc" : "Razón por la que una llamada entra en este centro de llamadas (para llamadas de voz)",
    "cms.developerlist.field.createTime" : "Creado en",
    "cms.indexmonitor.field.skillOnlineInbondCall" : "Número de llamadas en línea",
    "cms.report.message.inOccupyNumDesc" : "Número de llamadas entrantes que solicitan servicios manuales.",
    "cms.qcrelationconf.message.selectGroupType" : "Seleccione primero el tipo de grupo de usuarios",
    "cms.report.field.availTimeRateField" : "Tasa de inactividad",
    "cms.recorddrive.label.recorddrivename" : "Nombre de letra de unidad",
    "cms.indexmonitor.label.callInIVR" : "Número de llamadas entrantes en línea de IVR",
    "cms.fullscreen.field.editRichText" : "Modificar texto enriquecido",
    "cms.callcenterinstanceslist.message.cleanUap" : "Borrar configuración de nodo UAP",
    "cms.callcenterinstanceslist.field.disPwdConfirm" : "Confirmar Contraseña CCDIS",
    "cms.report.field.avgPerHNumField" : "Promedio de llamadas exitosas por hora",
    "cms.calllind.field.deviceAgent" : "Agente",
    "cms.agentmonitor.label.listening" : "Escuchar",
    "cms.report.field.mediaTypeField" : "Tipos de medios",
    "cms.report.field.playVoiceTimeField" : "Duración (es) de reproducción de voz",
    "cms.report.field.avgHoldTime" : "Duración promedio de retención",
    "cms.callcenterinstanceslist.field.adminCount" : "ID de administrador de CTI",
    "cms.agentMonitor.label.cancelListening_success" : "La escucha se cancela correctamente.",
    "cms.report.message.exportFailed" : "Error de exportación.",
    "cms.voiceinspection.field.batchDownload" : "Descargar por lotes",
    "cms.voiceinspection.field.callerNo" : "Número de llamada",
    "cms.report.field.maxAgentCountField" : "Número máximo de llamadas de agente simultáneas",
    "cms.indexmonitor.message.agentConnectIn10Rate" : "Porcentaje del número de llamadas conectadas manualmente en 10 segundos (incluidos 10 segundos) respecto al número total de llamadas.",
    "cms.agentMonitor.message.operaFailedAgent" : "Error en la operación. El agente está inspeccionando",
    "cms.indexmonitor.message.errorOrgInfo" : "Seleccione primero una organización.",
    "cms.report.field.agentOutSuccRate" : "Tasa de conexión manual de llamadas salientes",
    "cms.report.field.acdCall" : "Total de llamadas conectadas",
    "cms.report.message.outAvgCallTimeDesc" : "Duración media de las llamadas salientes entre un agente y los clientes.",
    "cms.report.field.oneClickDualCall" : "Llamada bidireccional con un solo clic",
    "cms.report.field.IVRInSucc" : "Llamadas entrantes de IVR conectadas",
    "cms.report.field.agentOutBound" : "Llamadas salientes manuales",
    "cms.voiceinspection.field.messageCall" : "Llamada de mensaje",
    "cms.callcenterinstanceslist.message.uapPsdLengthRangeValidateFailed" : "La longitud de la contraseña debe estar entre 6 y 32",
    "cms.developerlist.field.operate" : "Operación",
    "cms.sysparam.config.checkmessage" : "Contiene caracteres especiales. (\"<\", \">\", \"/\", etc.)",
    "cms.qcrelationconf.field.qcWorkNo" : "ID del inspector",
    "cms.agentmonitor.label.statusDuration" : "Duración del estado del agente",
    "cms.indexmonitor.field.accesscodeCalloutNum" : "Número de llamadas salientes",
    "cms.vcallecenter.title.failed" : "Error",
    "cms.report.message.avgAgentCountDesc" : "Número promedio de llamadas simultáneas cuyo dispositivo de servicio es un agente.",
    "cms.agentmonitor.label.sendNotes" : "Enviar notas",
    "cms.indexmonitor.field.all" : "Todo",
    "cms.indexmonitor.field.outboundConnectNumDesc" : "cms.report.field.outOccupyNumDesc=Número de veces que un agente conecta llamadas salientes.",
    "cms.report.message.avgAgentInCountDesc" : "Número promedio de llamadas entrantes simultáneas cuyo dispositivo de servicio es un agente.",
    "cms.report.field.onlyIVRService" : "Llamadas entrantes Pure-IVR exitosas",
    "cms.qcrelationconf.placeholder.workNo" : "Ingrese el id del agente",
    "cms.report.message.avgIVRInCountDesc" : "Número promedio de llamadas entrantes simultáneas cuyo dispositivo de servicio es el IVR.",
    "cms.calllink.field.noResources" : "No hay recursos disponibles en el ACD.",
    "cms.indexmonitor.message.ivrInboundNum" : "Número de solicitudes de llamada entrante de IVR cuya duración de llamada es mayor que 0",
    "cms.report.field.minQueueOutCount" : "Número mínimo de llamadas salientes simultáneas en una cola",
    "cms.report.message.avgAnsTimeDesc" : "Duración media de la cola de todas las llamadas conectadas en una cola.",
    "cms.developerlist.label.delete" : "Borrar",
    "cms.indexmonitor.message.totalAbandonedCallNum" : "Número total de llamadas – Número total de llamadas conectadas",
    "cms.fullscreen.field.preview" : "Vista previa",
    "cms.systemparam.error.validate.notmatchrulerangelength" : "El parámetro de entrada excede la longitud máxima o está por debajo de la longitud mínima",
    "cms.report.field.callStartTimeField" : "Hora de inicio de llamada",
    "cms.systemparam.error.notmatchrule" : "El parámetro no cumple con la regla de comprobación",
    "cms.report.title.agentOutCallMonitor" : "Supervisión de resumen de llamadas salientes del agente",
    "cms.report.field.custHangNumCtiField" : "Cuelgues de clientes",
    "cms.report.field.thirtyminutes" : "30 minutos",
    "cms.datasource.message.updatesuccess" : "La actualización se ha realizado correctamente.",
    "cms.report.field.workTimeField" : "Duración total del trabajo",
    "cms.fullscreen.message.chartDataError" : "Error al cargar el gráfico.",
    "cms.indexmonitor.field.durationAvgVdnOutbound" : "Duración media de las llamadas salientes",
    "cms.export.field.exportTaskStatus" : "Estado",
    "cms.report.field.callBeginField" : "Hora de inicio de la llamada",
    "cms.report.message.inCallRateDesc" : "Duración promedio de la llamada. Duración media de las llamadas entrantes manuales conectadas = Duración de las llamadas entrantes manuales conectadas/Número de llamadas entrantes manuales conectadas.",
    "cms.report.field.busyTimeRateField" : "Tasa de duración ocupada",
    "cms.vcallecenter.field.confirmwaspassword" : "Confirmar contraseña",
    "cms.skilladjustment.field.selectskill" : "Seleccionar una cola",
    "cms.indexmonitor.message.abandonInFive" : "Porcentaje del número de llamadas abandonadas dentro de 5 segundos en el número total de llamadas abandonadas.",
    "cms.export.field.failed" : "Error",
    "cms.report.message.inCallSuccRateDesc" : "Porcentaje de llamadas entrantes conectadas al total de llamadas entrantes.",
    "cms.vcallecenter.message.syncFailed" : "Error de sincronización",
    "cms.fullscreen.field.fifteenMin" : "15 minutos",
    "cms.report.message.subMediaTypeDesc" : "Tipo de submedios",
    "cms.systemparam.error.validate.notmatchrulenumber" : "Por favor, introduzca un número",
    "cms.systemmonitor.message.addVdnMost" : "Se puede seleccionar un máximo de 100 inquilinos.",
    "cms.systemparam.error.validate.notmatchruleoption" : "El parámetro de entrada no está en el rango de enumeración",
    "cms.qcrelationconf.field.deleteGroupMember" : "eliminar miembro del grupo",
    "cms.report.field.avgHandleTimeField" : "Duración media del procesamiento",
    "cms.report.field.minQueueInCountField" : "Número mínimo de llamadas entrantes simultáneas en una cola",
    "cms.report.title.agentOutCallTrend" : "Tendencia del resumen de llamadas salientes del agente",
    "cms.calllind.field.deviceRoute" : "Dispositivo de enrutamiento temporal",
    "cms.report.field.outAvgAnsTime" : "Duración media del timbre de las llamadas salientes",
    "cms.report.message.awarnessRateDesc" : "Porcentaje del número de llamadas contestadas por el agente en el número de llamadas entrantes manuales.",
    "cms.agent.title.modifypassword" : "Cambiar contraseña de inicio de sesión",
    "cms.report.message.avgAcwTimeDesc" : "Duración media cuando un agente está en estado de cierre. Duración promedio de cierre = Duración total de cierre/Número de tiempos de cierre",
    "cms.report.message.inCallSuccNumberDesc" : "Número de llamadas entrantes conectadas. El valor es la suma de las llamadas entrantes manuales y las llamadas entrantes conectadas automáticamente mediante el IVR.",
    "cms.fullscreen.message.addChart" : "Agregar un gráfico.",
    "cms.voiceinspection.message.playsuccess" : "El anuncio se reproduce con éxito.",
    "cms.agentMonitor.label.warning" : "Advertencia",
    "cms.indexmonitor.message.agentOutboundConnectNum" : "Número de llamadas salientes manuales que son contestadas por las partes llamadas.",
    "cms.report.message.manToSkillNumDesc" : "Número de transferencias de colas manuales a habilidades.",
    "cms.agentmonitor.label.showAlarmSensitive" : "Palabra Sensible",
    "cms.calllink.field.commonAccessTransfer" : "Acceso común o transferencia.",
    "cms.export.title.voiceDownload" : "Inspección de grabación",
    "cms.calllink.field.overflowing" : "Una llamada se transfiere debido al desbordamiento.",
    "cms.datasource.message.testsuccess" : "La conexión se prueba correctamente.",
    "cms.report.message.avgAcdTimeDesc" : "Duración promedio de la llamada de un agente. Duración promedio de la llamada manual = Duración de la llamada manual/Número de llamadas manuales exitosas",
    "cms.indexmonitor.field.skillUserAbandon" : "Llamadas abandonadas",
    "cms.report.message.userLevelDesc" : "Nivel de cliente del suscriptor en el centro de llamadas",
    "cms.report.message.acwOutCountDesc" : "Número de veces que un agente entra en el estado de recapitulación después de finalizar las llamadas salientes.",
    "cms.fullscreen.message.deleteconfim" : "¿Quieres confirmar la eliminación de este tema?",
    "cms.fullscreen.field.chartType" : "Tipo de gráfico",
    "cms.fullscreen.field.addCharts" : "Crear gráfico",
    "cms.monitordetail.field.count" : "Estadísticas sobre el número de veces",
    "cms.report.field.IVROutCallNumField" : "Llamadas salientes de Pure-IVR",
    "cms.fullscreen.field.skillQueue" : "Cola de habilidades",
    "cms.calllink.title.leaveReason" : "Razón para dejar el dispositivo",
    "cms.indexmonitor.message.inboundCallNum" : "Número de llamadas entrantes manuales + Número de llamadas entrantes IVR",
    "cms.callcenterinstanceslist.field.kafkaIp" : "Dirección IP de KAFKA",
    "cms.report.field.submediatype.whatsapp" : "WhatsApp",
    "cms.calllink.field.PGR" : "PGR (ruta CDN predeterminada controlada por el SCAI)",
    "cms.report.field.voiceNotification" : "Notificación por voz",
    "cms.agentmonitor.message.header" : "Error al guardar. Seleccione al menos un encabezado de tabla.",
    "cms.report.field.querySkill" : "Cola de habilidades",
    "cms.indexmonitor.field.totalLearningNum" : "Agentes en estado de aprendizaje",
    "cms.report.field.agentInSuccRate" : "Tasa de conexión manual de llamadas entrantes",
    "cms.uapmonitor.labe.uapoverload" : "El número de números consultados por el segmento de número de terminal excede el límite superior:",
    "cms.report.field.outAboundRingField" : "Llamadas salientes sin respuesta",
    "cms.callcenterinstanceslist.message.adminWorkNoError" : "El número inicial del ID de empleado del administrador debe oscilar entre 55001 y 59999.",
    "cms.report.field.holdTimeField" : "Duración (es) de retención",
    "cms.monitordetail.field.skill" : "Cola de habilidades",
    "cms.report.field.manTransferOutNumCtiField" : "Número de transferencias manuales a IVR y retransferencias hacia fuera",
    "cms.agentMonitor.label.listen_error" : "Escuchar falla.",
    "cms.calllink.field.unhold" : "Una llamada no es retenida.",
    "cms.failurecall.field.accessCode" : "Código de acceso",
    "cms.report.message.preDeviceNoDesc" : "ID de dispositivo del último dispositivo desde el que se realizó la llamada",
    "cms.agentmonitor.label.learnState" : "Aprendizaje",
    "cms.indexmonitor.message.durationAvgVdnInbound" : "Duración total que las llamadas entrantes ocupan el VDN/número total de llamadas entrantes",
    "cms.report.message.userRefusedFailNumDesc" : "Número de llamadas que no se contestan debido al rechazo de llamada.",
    "cms.datasource.field.cancel" : "Cancelar",
    "cms.indexmonitor.message.totalAgentNum" : "Número total de agentes actuales",
    "cms.report.field.outFailNum" : "Total de llamadas salientes fallidas",
    "cms.fullscreen.field.fiveMin" : "5 Minutos",
    "cms.report.title.callNo" : "número de llamada",
    "cms.fullscreen.title.editTheme" : "Modificar asunto",
    "cms.calllink.field.teleconference" : "Llamada de conferencia",
    "cms.voiceinspection.field.calleeNo" : "Número Llamado",
    "cms.report.field.IVRShortCallsField" : "Número de llamadas cortas de IVR (dentro de 3s)",
    "cms.report.field.internalCallTime" : "Duración interna de la llamada",
    "cms.uapmonitor.labe.eidmention" : "Puede introducir un máximo de 10 segmentos numéricos, que están separados por comas Regla (,). para cada segmento numérico: * y % no coinciden con ningún carácter o con varios caracteres. Por ejemplo, 6666* y 6666% coinciden con todas las cadenas de caracteres que comienzan con 6666. El signo de interrogación (?) coincide con un solo carácter. Por ejemplo, ¿6666? coincide solo con la cadena de cinco caracteres que comienza con 6666. Se admite la combinación de comodines (*?%). Por ejemplo, %3? coincide con todas las cadenas de caracteres cuyo último carácter es 3. Nota: Se recomienda que se coincida con un máximo de 100 resultados para cada comodín. De lo contrario, las empresas en el UAP se ven afectadas.",
    "cms.report.field.inAvgRingTimeField" : "Duración media del timbre de las llamadas entrantes manuales",
    "cms.datasource.field.remark" : "Descripción de uso",
    "cms.calllink.title.callType" : "Tipo de llamada",
    "cms.report.message.ackEndDesc" : "Hora de finalización de la respuesta de llamada\\, que indica la hora de finalización de la respuesta de llamada en el dispositivo actual",
    "cms.report.field.obsServiceIdField" : "ID de actividad de llamada saliente",
    "cms.record-playback.callType2" : "Llamada saliente IVR",
    "cms.qcrelationconf.message.queryFailedLimit" : "El número de registros por página debe ser mayor que 0 y menor que 101.",
    "cms.failurecall.placeholder.userPhoneInput" : "Introduzca un número de servicio",
    "cms.report.field.outSuccAnsRate" : "Tasa de conexión de llamadas salientes",
    "cms.callcenterinstanceslist.field.cmsAuthPassword" : "Contraseña CCDIS",
    "cms.report.field.skillId" : "ID de cola de habilidades",
    "cms.uapmonitor.field.callUapIp" : "UAP IP",
    "cms.report.field.endYear" : "FinAño",
    "cms.report.field.agentInSuccField" : "Llamadas entrantes manuales conectadas",
    "cms.callcenterinstanceslist.label.save" : "Guardar",
    "cms.uapmonitor.title.deregis" : "Confirmación de baja de registro",
    "cms.indexmonitor.message.skillConnectedAfterNDesc" : "Número de solicitudes de llamada conectadas manualmente después de Ns. Actualmente, los Ns pueden ser 10s o 60s.",
    "cms.report.message.billInfo1Desc" : "Tipo de llamada de voz",
    "cms.report.message.outWaitAnsNumDesc" : "Número de llamadas salientes manuales, incluidas las llamadas salientes iniciadas por los agentes y las transferidas desde el IVR a los servicios manuales.",
    "cms.fullscreen.field.normalBold" : "Normales",
    "cms.agentMonitor.label.forcerelease_error" : "Error al liberar por la fuerza una llamada.",
    "cms.report.message.waitBeginDesc" : "Hora de inicio de la llamada en espera",
    "cms.indexmonitor.field.skillTimeoutQueue" : "Llamadas de tiempo de espera a las colas",
    "cms.indexmonitor.field.agentConnectInFive" : "Llamadas conectadas manualmente en 5s",
    "cms.callcenterinstanceslist.message.videoCallNumsError" : "El número máximo de videollamadas simultáneas debe ser mayor o igual que el número de asientos de video.",
    "cms.report.title.agentOperationMonitor" : "Supervisión de detalles de operación de conexión de agente",
    "cms.vcallecenter.title.modifybmspassword" : "Modificar contraseña de BMS",
    "cms.indexmonitor.title.agentNowMonitor" : "Estadísticas de supervisión en tiempo real del agente durante la ejecución",
    "cms.callcenterinstanceslist.label.create" : "Nuevo",
    "cms.indexmonitor.title.callCenterDayTrend" : "Tendencia actual del centro de llamadas",
    "cms.report.field.indicatorName" : "Indicador",
    "cms.voiceinspection.field.currentSkillId" : "Cola de habilidades",
    "cms.agentMonitor.label.gateway_error" : "Error al invocar la interfaz de puerta de enlace.",
    "cms.fullscreen.field.percentage" : "(%)",
    "cms.indexmonitor.message.agentAbandonOut10NDesc" : "Número total de llamadas abandonadas cuya duración de respuesta es superior a 10 segundos.",
    "cms.calllink.field.internalError" : "Error interno.",
    "cms.report.field.otherHangNumCti" : "Suspensiones del sistema",
    "cms.indexmonitor.message.skillAbandonInNDesc" : "Número de solicitudes de llamada abandonadas manualmente en Ns. Actualmente, los Ns pueden ser 3s, 5s, 10s, 20, 30s o 60s.",
    "cms.uapmonitor.field.callSip" : "Identificador de dispositivo terminal",
    "cms.report.message.callAbandonedInRingDesc" : "Número de llamadas abandonadas por la persona que llama mientras suena sin ser contestada.",
    "cms.report.field.awarnessRateField" : "Tasa de éxito de llamadas entrantes manuales",
    "cms.callcenterinstanceslist.message.selectdeveloper" : "Seleccione un desarrollador.",
    "cms.callcenterinstanceslist.message.error" : "Error",
    "cms.report.title.changeTable" : "Cambiar a tabla",
    "cms.callcenterinstanceslist.field.ccBmsPort" : "Puerto CCBMS LB",
    "cms.indexmonitor.message.agentBoundAbandonRate" : "100 – Tasa de llamadas entrantes manuales conectadas",
    "cms.systemparam.error.validate.notmatchruleminlength" : "El parámetro de entrada es menor que la longitud mínima",
    "cms.callecenter.field.confirmbmspwd" : "Confirmar Contraseña CCBMS",
    "cms.indexmonitor.message.skillRequestDesc" : "Número de llamadas entrantes que solicitan servicios de usuario",
    "cms.callcenterinstanceslist.message.passLengthValidateFailed" : "Una contraseña contiene un mínimo de ocho caracteres.",
    "cms.voiceinspection.field.videoClickToCall" : "Haga clic en video para marcar",
    "cms.agentmonitor.label.busyState" : "Contáctenos",
    "cms.monitordetail.field.avgTalkingDuration" : "Duración promedio de llamadas",
    "cms.qcrelationconf.message.queryFailed" : "Error de consulta",
    "cms.datasource.field.uidbDatabase" : "UIDB",
    "cms.interfaceperflog.message.modifySuccess" : "La modificación se realizó correctamente.",
    "cms.indexmonitor.message.ivrOutboundNum" : "Número de llamadas salientes de IVR contestadas por la parte llamada",
    "cms.qcrelationconf.title.warning" : "Advertencia",
    "cms.report.field.agentServiceRate" : "Tasa de éxito manual en el total de llamadas entrantes",
    "cms.report.field.maxIVROutCount" : "Número máximo de llamadas salientes IVR simultáneas",
    "cms.fullscreen.message.numberSecond" : "El valor máximo es 4320.",
    "cms.uapmonitor.field.callSocket" : "Estado del zócalo",
    "cms.reportsubscribe.field.prohibit" : "Prohibir",
    "cms.report.message.callEndTimeDesc" : "Hora de finalización de llamada.",
    "cms.voiceinspection.message.frequentOperat" : "La operación es demasiado frecuente. Inténtelo de nuevo más tarde",
    "cms.voiceinspection.message.frequentDownload" : "La descarga es demasiado frecuente. Inténtalo de nuevo más tarde.",
    "cms.report.field.agentLoginNum" : "Agentes Llamados",
    "cms.vcallecenter.title.modifyinfo" : "Modificar contraseña",
    "cms.indexmonitor.message.skillConnectedInNRate" : "Porcentaje del número de solicitudes de llamada conectadas manualmente en Ns en el número total de llamadas que solicitan servicios de usuario",
    "cms.callcenterinstanceslist.field.passwordConfirm" : "Confirmar contraseña",
    "cms.callcenterinstanceslist.message.ccgatewayServerSingledeleteconfim" : "¿Está seguro de que desea eliminar el servidor CC-Gateway seleccionado?",
    "cms.report.field.avgAgentCountField" : "Número promedio de llamadas de agente simultáneas",
    "cms.report.field.minQueueInCount" : "Número mínimo de llamadas entrantes simultáneas en una cola",
    "cms.report.title.error" : "Error",
    "cms.systemmonitor.message.timelarge" : "* El rango de tiempo no puede ser superior a 31 días.",
    "cms.fullscreen.field.fonts" : "Tipo de letra",
    "cms.qcrelationconf.message.updateSuccess" : "Actualizar correctamente",
    "cms.datasource.message.deletefailed" : "Error en la eliminación.",
    "cms.agentmonitor.label.detail" : "Detalles de supervisión del agente.",
    "cms.sendnote.message.tipsNoteContent" : "Introduzca la información de la nota.",
    "cms.indexmonitor.message.skillUserAbandonDesc" : "Número de llamadas abandonadas proactivamente por los usuarios durante la cola y el timbre",
    "cms.callcenterinstanceslist.message.updateCtiVersion" : "Compruebe si se ha completado la migración de datos de CTI. Tenga cuidado al realizar esta operación.",
    "cms.calllink.title.callEndTime" : "Hora de finalización de llamadas",
    "cms.fullscreen.field.indexdMode" : "Modo de monitorización",
    "cms.calllink.title.deviceDesc" : "Descripción del dispositivo",
    "cms.report.field.failQueueWaitRate" : "Duración promedio del timbre de llamadas perdidas",
    "cms.callcenterinstanceslist.title.warning" : "Advertencia",
    "cms.uapmonitor.field.Search" : "Buscar",
    "cms.developerlist.title.create" : "Añadir Desarrollador",
    "cms.voiceinspection.message.invalidParam" : "Parámetro de entrada no válido.",
    "cms.callcenterinstanceslist.field.ctiversion" : "VERSIÓN CTI",
    "cms.synchronize.message.syncStatusZero" : "Para ser sincronizado",
    "cms.indexmonitor.message.agentConnectInFive" : "Porcentaje del número de llamadas conectadas manualmente dentro de 5 segundos en el número total de llamadas conectadas manualmente.",
    "cms.report.title.skillAllMonitor" : "Supervisión de resumen de la cola de habilidades",
    "cms.indexmonitor.message.agentConnectAfterInSixty" : "Porcentaje del número de llamadas conectadas manualmente después de 60 años en el número total de llamadas conectadas manualmente.",
    "cms.report.message.acdCallsDesc" : "Número de llamadas entrantes y salientes conectadas.",
    "cms.indexmonitor.message.durationAvgWait" : "Duración media de espera de las llamadas entrantes",
    "cms.report.message.agentAlertingTimeDesc" : "Hora de alerta de llamada.",
    "cms.indexmonitor.field.skillConnectedIn3Rate" : "Llamadas conectadas en 3s",
    "cms.indexmonitor.title.ivrDayMonitor" : "Estadísticas de hoy sobre indicadores IVR",
    "cms.agentMonitor.label.whisper_error" : "El susurro falló",
    "cms.report.field.locationIdField" : "ID de nodo distribuido",
    "cms.report.field.avgQueueOutCountField" : "Número medio de llamadas salientes simultáneas en una cola",
    "cms.report.field.IVROutSuccNum" : "Llamadas salientes de Pure-IVR conectadas",
    "cms.report.field.IVROutSuccNumField" : "Llamadas salientes de IVR puro conectadas",
    "cms.report.field.assistTime" : "Duración de la solicitud de ayuda interna",
    "cms.systemmonitor.label.onehour" : "1 hora",
    "cms.voiceinspection.field.audioPlay" : "Audición de grabación",
    "cms.callcenterinstanceslist.title.nodemodeselect" : "Seleccionar modo",
    "cms.vdnconfiguration.title.vdnConfigure" : "Configurar el centro de llamadas donde se ejecuta VDN",
    "cms.calldetail.label.inputcallid" : "Introduzca el número de serie de la llamada",
    "cms.fullscreen.field.statisticPeriod" : "Periodo estadístico",
    "cms.report.field.acdTimeRateField" : "Tasa de duración de la llamada manual",
    "cms.report.title.skillAccessTrend" : "Tendencia del rendimiento de la cola de habilidades por código de acceso",
    "cms.skilladjustment.message.tipsError" : "Error al ajustar la cola de habilidades.",
    "cms.report.field.threePartyCallCount" : "Llamadas a tres partes",
    "cms.report.message.queryFailed" : "Error en la consulta {0}.",
    "cms.vcallecenter.message.configurationinterfacenopassword" : "La contraseña de configuración no está configurada.",
    "cms.datasource.message.addsuccess" : "Adición exitosa.",
    "cms.qcrelationconf.field.accountCode" : "Código de cuenta",
    "cms.indexmonitor.message.agentInboundNum" : "Número de llamadas entrantes que se asignan a colas de habilidades o agentes",
    "cms.report.field.accessCodeField" : "Código de acceso",
    "cms.report.field.inAnswerRate" : "Tasa de conexión de llamadas entrantes",
    "cms.indexmonitor.field.abandonInThirty" : "Llamadas abandonadas en 30 años",
    "cms.report.field.outAboundRing" : "Llamadas salientes sin respuesta",
    "cms.report.field.avgIVRCountField" : "Número medio de llamadas IVR simultáneas",
    "cms.agentmonitor.label.restState" : "Descanso",
    "cms.indexmonitor.message.totalConnectCallNum" : "Número de llamadas entrantes conectadas + Número de llamadas salientes conectadas",
    "cms.qcrelationconf.field.operation" : "Acción",
    "cms.fullscreen.message.screenreSolution" : "La resolución de pantalla no puede estar vacía.",
    "cms.agentMonitor.label.forceidle_success_calling" : "Un agente se establece a la fuerza en el estado inactivo con éxito. Una vez finalizada la llamada, el agente entra en el estado inactivo.",
    "cms.qcrelationconf.message.deleteSuccess" : "Eliminar correctamente",
    "cms.monitordetail.field.redirectcount" : "Llamadas redirigidas",
    "cms.qcrelationconf.field.cancel" : "Cancelar",
    "cms.agentmonitor.label.signOut" : "Desconectado",
    "cms.report.field.waitAnsRate" : "Duración promedio del timbre de las llamadas entrantes manuales",
    "cms.fullscreen.field.movedown" : "Abajo",
    "cms.report.field.voiceCallType" : "Tipo de llamada de voz",
    "cms.indexmonitor.field.duration" : "Duración",
    "cms.report.message.avgAgentOutCountDesc" : "Número medio de llamadas salientes simultáneas cuyo dispositivo de servicio es un agente.",
    "cms.uapmonitor.labe.uapconfing" : "Monitoreo del estado del terminal y configuración de restauración",
    "cms.report.field.manTransferOutNumCti" : "Número de transferencias manuales a IVR y retransferencias hacia fuera",
    "cms.report.message.onlineRateDesc" : "Tarifa en línea = (Duración manual de la llamada + Duración total del cierre + Duración total de otros estados) / (Duración total del trabajo – Duración del descanso) x 100",
    "cms.callcenterinstanceslist.label.vCallCenter" : "Centro de llamadas virtual",
    "cms.agentmonitor.label.operation" : "Operación",
    "cms.fullscreen.message.addVdnLeast" : "Seleccione al menos un inquilino.",
    "cms.report.field.userBusyFailNum" : "Llamadas perdidas debido a líneas ocupadas",
    "cms.monitordetail.field.idlecount" : "El agente entra en el estado inactivo",
    "cms.datasource.field.dbType" : "Tipo de origen de datos",
    "cms.qcrelationconf.field.groupInfo" : "Información básica del grupo de usuarios",
    "cms.report.field.failAnsRate" : "Tasa de pérdida de solicitud",
    "cms.systemparam.error.reset" : "Error al restablecimiento del parámetro",
    "cms.report.field.avgCallPerHour" : "Promedio de llamadas entrantes manuales por hora",
    "cms.datasource.field.operation" : "Operación",
    "cms.report.field.noAckNum" : "Llamadas sin respuesta",
    "cms.callcenterinstanceslist.label.batchAdd" : "Añadir por lotes",
    "cms.voiceinspection.title.error" : "Error",
    "cms.report.field.userLevelField" : "Nivel de cliente",
    "cms.report.field.inAvgCallTimeField" : "Duración media de la llamada",
    "cms.report.message.maxIVRInCountDesc" : "Número máximo de llamadas entrantes simultáneas cuyo dispositivo de servicio es el IVR.",
    "cms.report.message.errorInfo" : "Error al guardar los datos. Seleccione al menos un indicador.",
    "cms.report.field.callAbandonedRingField" : "Número de llamadas entrantes manuales perdidas",
    "cms.qualitycheck.label.editusergroup" : "Editar UserGroup",
    "cms.report.message.callAbandonedRingDesc" : "Número de llamadas entrantes manuales perdidas = Número de llamadas entrantes manuales – Número de llamadas entrantes manuales conectadas",
    "cms.voiceinspection.message.noSftpFound" : "Error al consultar la información de conexión al servidor SFTP.",
    "cms.report.field.IVRServiceRateField" : "Tasa de éxito de llamadas entrantes Pure-IVR",
    "cms.systemparam.error.noparamselected" : "Seleccione un parámetro primero",
    "cms.report.message.callIdNumDesc" : "ID de número de secuencia de múltiples CDR generados para una llamada",
    "cms.report.message.avgHoldTimeDesc" : "Duración promedio de espera de llamada. Duración promedio de retención = Duración de retención/Número de tiempos de retención",
    "cms.indexmonitor.message.totalLearningNum" : "Número de agentes en estado de aprendizaje",
    "cms.fullscreen.field.totalCallTimeDesc" : "Duración total de las llamadas contestadas por los agentes.",
    "cms.report.field.queryBeginTime" : "Hora de inicio",
    "cms.fullscreen.field.pie" : "Gráfico circular",
    "cms.indexmonitor.message.agentAbandonIn3Rate" : "Porcentaje de llamadas abandonadas en 3 segundos respecto al total de llamadas abandonadas",
    "cms.callcenter.message.updatefailed" : "Error al actualizar",
    "cms.indexmonitor.message.agentConnectIn30Rate" : "Porcentaje del número de llamadas conectadas manualmente en 30 segundos (incluidos 30 segundos) respecto al número total de llamadas.",
    "cms.agentmonitor.label.account" : "Cuenta de servicio",
    "cms.report.message.IVROutSuccRateDesc" : "Porcentaje de llamadas salientes de puro IVR conectadas con respecto al total de llamadas salientes de Pure IVR.",
    "cms.report.message.manToIvrNumCtiDesc" : "Número de transferencias manuales a IVR.",
    "cms.report.field.cancel" : "Cancelar",
    "cms.report.message.inCallFailNumDesc" : "Número de solicitudes de llamada perdidas = Número de solicitudes de servicio – Número de solicitudes de llamada conectadas",
    "cms.report.field.manToIvrNumCti" : "Transferencias manuales a IVR",
    "cms.callcenter.field.deletesuccess" : "Supresión correcta",
    "cms.calllink.title.deviceNo" : "ID de dispositivo",
    "cms.fullscreen.field.dayTrend" : "Tendencia de hoy",
    "cms.voiceinspection.message.playfailure" : "Error al reproducir el anuncio.",
    "cms.report.message.outAvgRingTimeDesc" : "Tiempo promedio para que un agente espere a que los clientes respondan las llamadas salientes.",
    "cms.report.field.outCallTimeField" : "Duración total de las llamadas salientes",
    "cms.indexmonitor.message.agentConnectInSixty" : "Porcentaje del número de llamadas conectadas manualmente dentro de 60s en el número total de llamadas conectadas manualmente.",
    "cms.callcenterinstanceslist.field.uapPerfPort" : "Puerto de monitoreo de rendimiento UAP",
    "cms.datasource.message.testfailed" : "Error al probar la conexión.",
    "cms.callecenter.field.setbmspwd" : "Contraseña CCBMS",
    "cms.report.field.maxIVRInCount" : "Número máximo de llamadas entrantes IVR simultáneas",
    "cms.calllink.field.leaveReason89" : "Después de que un agente invoca la interfaz de respuesta, el agente no se conecta al usuario y éste cuelga.",
    "cms.monitordetail.field.idle" : "Disponible",
    "cms.calllink.field.leaveReason88" : "El usuario cuelga después de que el agente se conecte con el usuario.",
    "cms.reportsubscribe.field.monday" : "Lunes",
    "cms.systemmonitor.label.thirtyonedays" : "31 Días",
    "cms.report.field.acdCallsField" : "Total de llamadas conectadas",
    "cms.datasource.message.dsmodifyconfirm" : "El origen de datos se ha referenciado en el centro de llamadas y solo tiene efecto después de reiniciar el entorno. ¿Estás seguro de que quieres modificarlo?",
    "cms.reportsubscribe.field.sentTime" : "Tiempo de envío",
    "cms.callcenterinstanceslist.field.ccGatewayPort" : "Puerto de pasarela CC",
    "cms.errormessage.inputspecialchar" : "El valor contiene caracteres especiales no válidos.",
    "cms.systemmonitor.message.timeerror" : "* La hora de inicio debe ser anterior a la hora de finalización.",
    "cms.indexmonitor.field.outboundCallNum" : "Llamadas salientes",
    "cms.failurecall.field.search" : "Buscar",
    "cms.fullscreen.message.thresholdLessThan" : "Debe ser mayor que el valor mínimo",
    "cms.report.message.ringTimeDesc" : "Duración total del timbre de un agente, excluyendo la duración de la llamada.",
    "cms.calllink.field.callType33" : "Llamada entrante común",
    "cms.fullscreen.field.hisMonitor" : "Estadísticas de indicadores históricos",
    "cms.calllink.field.callType32" : "Llamada de peaje entrante",
    "cms.calllink.field.callType31" : "Llamada interna",
    "cms.systemmonitor.field.systemInBound" : "Llamadas entrantes del sistema",
    "cms.calllink.field.callType30" : "Llamada saliente común",
    "cms.report.message.IVROutCallNumDesc" : "Número de llamadas salientes realizadas solo por el IVR.",
    "cms.agentmonitor.label.idleState" : "Disponible",
    "cms.vdnconfiguration.title.success" : "Éxito",
    "cms.monitordetail.field.agentReleaseCount" : "El agente libera llamadas",
    "cms.developerlist.message.refreshConfirmMessage" : "¿Está seguro de que desea actualizar el SK del desarrollador?",
    "cms.report.placeholder.workName" : "Introduzca un nombre de agente.",
    "cms.report.message.avgCallPerHourDesc" : "Número medio de llamadas contestadas por un agente por hora en el segmento de tiempo de trabajo seleccionado.",
    "cms.indexmonitor.message.agentConnectInThree" : "Porcentaje del número de llamadas conectadas manualmente dentro de 3 segundos en el número total de llamadas conectadas manualmente.",
    "cms.report.field.avgCallPerHourField" : "Promedio de llamadas entrantes manuales por hora",
    "cms.indexmonitor.message.skillLostRingDesc" : "Número de llamadas sin respuesta después de haber sido asignadas a los agentes",
    "cms.report.field.userBusyFailNumField" : "Llamadas perdidas debido a líneas ocupadas",
    "cms.report.message.outAnswerRateDesc" : "Porcentaje del número de llamadas salientes conectadas en el número de llamadas salientes.",
    "cms.report.field.inCallSuccRate" : "Tasa de conexión de llamadas entrantes",
    "cms.calllink.field.callType29" : "Llamada saliente manual",
    "cms.calllink.field.callType28" : "Tipo de llamada saliente del agente",
    "cms.indexmonitor.message.agentConnectIn5Rate" : "Porcentaje del número de llamadas conectadas manualmente en 5 segundos (incluidos 5 segundos) respecto al número total de llamadas.",
    "cms.calllink.field.callType27" : "Llamada saliente PRI",
    "cms.indexmonitor.field.skillConnectedIn5" : "Llamadas conectadas en 5s",
    "cms.calllink.field.callType26" : "Llamada saliente de IVR",
    "cms.calllink.field.callType25" : "Solicitud de devolución de llamada PRI",
    "cms.report.field.systemInBoundField" : "Total de llamadas entrantes del sistema",
    "cms.failurecall.field.userPhone" : "Número de servicio",
    "cms.indexmonitor.field.skillConnectedIn3" : "Llamadas conectadas en 3s",
    "cms.report.message.waitEndDesc" : "Hora de finalización de la llamada en espera\\, que indica la hora de finalización de la llamada en el dispositivo actual",
    "cms.calllink.field.callType24" : "Vista previa de la llamada saliente PRI",
    "cms.fullscreen.message.addLayout" : "Error al agregar el lienzo porque el lienzo está lleno.",
    "cms.calllink.field.callType23" : "Llamadas entrantes y salientes del PRI virtual",
    "cms.report.field.week" : "Semana",
    "cms.report.field.outAnswerRateField" : "Tasa de conexión de llamadas salientes",
    "cms.calllink.field.callType22" : "Llamada saliente PRI preconectada",
    "cms.calllink.field.callType21" : "Llamada saliente del PRI preocupado",
    "cms.calllink.field.callType20" : "Llamada de ayuda de tres partes",
    "cms.datasource.field.dbUrl" : "Cadena de conexión a base de datos",
    "cms.callcenterinstanceslist.field.kafkaPassword" : "KAFKA Contraseña",
    "cms.report.field.playVoiceTime" : "Duración de reproducción de voz",
    "cms.report.field.inCallRate" : "Duración promedio de llamadas",
    "cms.developerlist.label.create" : "Añadir",
    "cms.report.message.maxAgentOutCountDesc" : "Número máximo de llamadas salientes simultáneas cuyo dispositivo de servicio es un agente.",
    "cms.report.title.IVRAccessMonitor" : "Estadísticas de monitoreo de datos IVR por código de acceso",
    "cms.report.message.nowTimeCheck" : "El tiempo excede el tiempo actual.",
    "cms.qcrelationconf.field.agentWorkNo" : "Identificación de la parte inspeccionada",
    "cms.fullscreen.field.weighted" : "Peso de la fuente",
    "cms.report.message.agentTalkDurationDesc" : "Duración de la conversación del llamador.",
    "cms.callcenterinstanceslist.field.systemAdmin" : "ID de administrador de CTI",
    "cms.report.field.minQueueOutCountField" : "Número mínimo de llamadas salientes simultáneas en una cola",
    "cms.report.field.billInfo1Field" : "Tipo de llamada de voz",
    "cms.indexmonitor.title.success" : "Éxito",
    "cms.calllink.field.callType19" : "Llamada de ayuda de dos partes",
    "cms.indexmonitor.field.agentBoundConnectRate" : "Llamadas entrantes manuales conectadas",
    "cms.indexmonitor.field.agentOutCallCount" : "Número de llamadas salientes del agente en línea",
    "cms.calllink.field.callType18" : "Llamada de negocios RBT",
    "cms.calllink.field.callType17" : "Llamada de peaje internacional entrante",
    "cms.calllink.field.callType16" : "Solicitud de devolución de llamada",
    "cms.report.field.IVRInBoundField" : "Llamadas entrantes de IVR",
    "cms.calllink.field.callType15" : "Vista previa de la llamada saliente",
    "cms.report.field.agentOutSuccField" : "Llamadas salientes manuales conectadas",
    "cms.calllink.field.callType14" : "Llamada saliente predicha",
    "cms.calllink.field.callType13" : "Llamada saliente preconectada",
    "cms.mediaType20" : "Llamada no en tiempo real",
    "cms.mediaType53": "Multimedia",
    "cms.report.message.playVoiceTimeDesc" : "Duración de reproducción de voz.",
    "cms.calllink.field.callType12" : "Llamada saliente preocupada",
    "cms.report.message.agentOfferedDesc" : "Número total de llamadas entrantes que solicitan servicios de agente.",
    "cms.calllink.field.callType11" : "Llamada saliente reservada",
    "cms.callcenterinstanceslist.message.passValidateFailed" : "La contraseña debe contener al menos tres tipos de los siguientes: letras mayúsculas, minúsculas, dígitos y characters: {'~! @ # $ % ^ & * () -?'}",
    "cms.calllink.field.callType10" : "Llamada saliente (agente internacional)",
    "cms.skilladjustment.message.tipsSelectSkill" : "Seleccione una cola de habilidades.",
    "cms.agentMonitor.label.forcebusy_success" : "Un agente se establece a la fuerza en el estado ocupado con éxito.",
    "cms.report.field.outCallRate" : "Tasa de conexión de llamadas salientes",
    "cms.report.field.maxQueueCount" : "Número máximo de llamadas simultáneas en una cola",
    "cms.report.title.callCenterAccessMonitor" : "Monitoreo del rendimiento de VDN por código de acceso",
    "cms.calllink.field.leaveReason54" : "La tasa de finalización de llamadas es menor que el umbral.",
    "cms.report.field.outOccupyNum" : "Llamadas salientes",
    "cms.calllink.field.leaveReason53" : "El participante se retira de la conferencia.",
    "cms.report.message.inVdnAvgTimeDesc" : "Duración media de la llamada entrante en el VDN. El valor es la duración total de la llamada entrante dividida por el número de llamadas entrantes.",
    "cms.calllink.field.leaveReason52" : "El presidente libera proactivamente la conferencia, o la conferencia se libera debido a una excepción de CCS.",
    "cms.sendnote.message.tipsSuccess" : "La nota se envía correctamente.",
    "cms.calllink.field.leaveReason51" : "Un usuario llama para ingresar una conferencia telefónica.",
    "cms.calllink.field.leaveReason50" : "El agente rechaza una llamada",
    "cms.voiceinspection.field.queryBeginTime" : "Hora de inicio",
    "cms.report.field.reportPeriod" : "Paso estadístico",
    "cms.recorddrive.field.number" : "No",
    "cms.monitordetail.field.media" : "Multimedia",
    "cms.indexmonitor.label.CommercialUse" : "Uso comercial",
    "cms.mediaType19" : "Videollamada común",
    "cms.uapmonitor.field.callIpSize" : "Tipo de IP",
    "cms.mediaType18" : "Videollamada de clic para marcar",
    "cms.mediaType17" : "Medios de mensaje",
    "cms.mediaType16" : "Vista previa de la llamada saliente",
    "cms.indexmonitor.label.callOutIVR" : "Número de llamadas salientes en línea de IVR",
    "cms.indexmonitor.message.skillOnlineConnectedDesc" : "Número de llamadas contestadas por los agentes en el bloque actual de 5 minutos. Bloques de 5 minutos pasan de 00:00 a 00:05, 00:05 a 00:10, y así sucesivamente.",
    "cms.indexmonitor.message.skillLimit": "Selecciona de 1 a 10 colas de habilidades.",
    "cms.monitordetail.field.adjustCount" : "El agente entra en el estado de organización",
    "cms.mediaType15" : "Llamada saliente predicha",
    "cms.reportsubscribe.field.sentReport" : "Informe enviado",
    "cms.mediaType14" : "Llamada OPS",
    "cms.report.field.maxQueueOutCountField" : "Número máximo de llamadas salientes simultáneas en una cola",
    "cms.mediaType13" : "Videollamada RDSI 6B+D",
    "cms.mediaType12" : "Videollamada ISDN 2B+D",
    "cms.fullscreen.field.simsun" : "SimSun",
    "cms.mediaType11" : "Transferencia de archivos",
    "cms.mediaType10" : "Uso compartido de aplicaciones",
    "cms.sysparam.config.value" : "Valor del parámetro",
    "cms.uapmonitor.labe.eidoverlength" : "El número de segmentos de número de terminal excede el límite superior: 10.",
    "cms.report.message.busyCountDesc" : "Número de veces que un agente entra en el estado ocupado.",
    "cms.calllink.field.leaveReason48" : "Responder a una llamada en la cola principal en la cola secundaria.",
    "cms.monitordetail.field.busyCount" : "El agente entra en el estado ocupado",
    "cms.calllink.field.leaveReason47" : "Llamada en cola en varias colas.",
    "cms.calllink.field.leaveReason46" : "Agente inalcanzable, redireccionamiento de CDN",
    "cms.calllink.field.leaveReason45" : "Entrando en PGR desde la consulta del agente.",
    "cms.fullscreen.field.indexDimension" : "Objeto supervisado",
    "cms.calllink.field.leaveReason44" : "Enrutar las llamadas entrantes a PGR.",
    "cms.calllink.field.leaveReason43" : "La CDN devuelve una ruta normal al PGR.",
    "cms.calllink.field.leaveReason42" : "El tiempo de espera de la ruta CDN y entra en el PGR.",
    "cms.calllink.field.leaveReason41" : "Cancelación del agente o fallo de enrutamiento durante la consulta.",
    "cms.calllink.field.leaveReason40" : "Tiempo de espera de enrutamiento de CDN.",
    "cms.report.field.endTime" : "Fecha de finalización",
    "cms.qualitycheck.message.saveSuccess" : "Guardar correctamente.",
    "cms.report.field.callTypeField" : "Tipo de llamada",
    "cms.calllink.field.activeRelease" : "El abonado desconecta una llamada.",
    "cms.monitordetail.field.restoutduration" : "Duración (es) del tiempo de espera",
    "cms.fullscreen.field.noanswerCountDesc" : "Número de respuestas retardadas por los agentes después de la asignación de llamadas.",
    "cms.report.field.abortQueueWaitNumField" : "Llamadas de cola abandonadas",
    "cms.report.message.inputZipPassword": "Por favor, introduzca la contraseña de compresión",
    "cms.report.task.sucess.export": "Se ha creado una tarea de exportación. ¿Está seguro de que desea verlo en la lista de exportación?",
    "cms.report.task.export.records": "Exportación de registros de contacto",
    "cms.report.field.length.limit": "El valor debe ser un número con un máximo de 0 caracteres.",
    "cms.report.field.number": "Número no válido",
    "cms.report.field.view.export.task": "Ver tareas de exportación",
    "cms.report.field.enter.callin": "Introduzca el número de serie de la llamada entrante",
    "cms.agent.lable.alarm"    : "Alarmas",
    "cms.agent.label.agentlist" : "Lista de agentes",
    "cms.agent.label.more"   : "Más información",
    "cms.agent.label.agentdetailInfo" : "Detalles del agente",
    "cms.agent.label.enterdetail"  : "Ver detalles",
    "cms.agent.label.statetime" : "Duración del estado",
    "cms.agent.label.duracount" : "Estadísticas de duración",
    "cms.agent.label.numcount" : "Estadísticas de tiempos",
    "cms.agent.label.agentname" : "Nombre del agente",
    "cms.agent.label.logintime"  : "Duración en línea",
    "cms.agent.label.idlenum"  : "Tiempos inactivos",
    "cms.agent.label.arrangetime" : "Duración de la clasificación",
    "cms.agent.label.logouttime" : "Duración sin conexión",
    "cms.agent.label.alarmstate" : "Estado de alarma",
    "cms.agent.label.watchdetail" : "Ver detalles",
    "cms.input.format.invalid": "Formato de entrada incorrecto",
    "cms.normal.text.validate": "Solo se permiten dígitos, letras y -._{'@'}",
    "cms.calllind.field.deviceUnkown" :"Dispositivo desconocido",
    "cms.agent.label.signinphone": "Número de inicio de sesión",
    "cms.agent.label.currentagentstate" : "Estado actual del agente",
    "cms.agent.label.currentstatetime": "Duración del estado actual",
    "cms.agent.label.choosestatetime": "Selección de la duración mínima del estado",
    "cms.agent.label.busyitem":"Mostrar ocupado",
    "cms.agent.label.talkitem":"Llamada",
    "cms.agent.label.online":"en línea",
    "cms.agent.label.offline":"Desconectado",
    "cms.agent.label.callinrate": "Tasa de finalización de llamadas entrantes",
    "cms.agent.label.callintime": "Duración de la llamada entrante",
    "cms.agent.label.avgcallintime":"Duración media de la llamada entrante",
    "cms.agent.label.avgwaittime": "Duración media de espera de llamadas entrantes",
    "cms.agent.label.maxwaittime":"Duración máxima de espera de llamadas entrantes",
    "cms.agent.label.calloutsuccrate":"Tasa de finalización de llamadas salientes",
    "cms.agent.label.callouttime": "Duración de la llamada saliente",
    "cms.agent.label.avgcallouttime":"Duración media de la llamada saliente",
    "cms.agent.label.choosesybmediatype":"Seleccione el tipo de canal",
    "cms.agent.label.busytime":"Duración de la indicación de ocupado",
    "cms.agent.label.avgbusytime":"Longitud media de la indicación de ocupado",
    "cms.agent.label.acgtalktime":"Duración media de la llamada",
    "cms.agent.label.outresttime":"Duración del tiempo de espera del descanso",
    "cms.agent.label.totaltalktime":"Duración total de la llamada",
    "cms.agent.label.redirtnum":"Redireccionamiento",
    "cms.agent.label.totaltalknums":"Número total de llamadas",
    "cms.agentmonitor.label.dayStatistic": "Estadísticas de hoy",
    "cms.agentmonitor.label.countAndTime": "Tiempos/Duración",
    "cms.agentmonitor.label.Performance": "Rendimiento",
    "cms.agentmonitor.label.signInDetail": "Detalles de check-in",
  "cms.agentmonitor.syncScreenMonitor.getVrpIpError": "El agente inspeccionado no ha iniciado sesión en el cliente VRC",
  "cms.agentmonitor.syncScreenMonitor.agentStatus": "Inicie sesión antes de monitorear",
    "cms.agentmonitor.label.idleStatistic": "Mostrar inactivo",
    "cms.agent.tips.selectstatetime": "Seleccione la duración del estado. Debe seleccionar el estado actual",
    "cms.agent.label.selectstatetime":">= Duración del estado (minutos)",
    "cms.agent.tips.sipaccountcheck":"Seleccione un entero positivo de 1 a 32 dígitos como número de inicio de sesión",
    "cms.indexmonitor.field.totalAgentConnectCallTime": "Duración del estado de conversación",
    "cms.indexmonitor.title.agentDate":"Datos del agente",
    "cms.agent.tips.notassined":"No asignado",
    "cms.indexmonitor.set.limitA": "Seleccione",
    "cms.indexmonitor.set.limitB": "indicadores para la visualización.",
    "cms.indexmonitor.titile.indicatorSetting": 'Indicator Setting',
    "cms.indexmonitor.realTime.indicator": "Métricas en tiempo real",
    "cms.indexmonitor.index.multimediaCalls": "Multimedia",
    "cms.indexmonitor.index.callTrends": "Tendencias de llamadas",
    "cms.indexmonitor.index.skillQueueServiceLevel": "Descripción general del nivel de servicio de la cola de habilidades",
    "cms.indexmonitor.index.met": "Conseguido",
    "cms.indexmonitor.index.missed": "No alcanzado",
    "cms.incoming.record.not.sameday": "No se admite la consulta entre días",
    "cms.requiredcallback.message.allowTimeRange": "Los datos se pueden consultar en un máximo de siete días",
    "cms.incoming.record.field.releasecause": "Causa de liberación de llamada",
    "cms.incoming.record.field.releasecause.input":"Ingrese el motivo de liberación de llamada",
    "cms.incoming.record.field.releasecause.id":"Ingrese el código de causa de liberación de llamada",
    "cms.incoming.record.field.releasecause.causecode": "Ingrese el código de asignación de causa",
    "cms.incoming.record.field.ifReqManual": "¿Alguna vez se solicitó la mano de obra?",
    "cms.incoming.record.field.isReqManual": "Manual solicitado",
    "cms.incoming.record.field.notReqManual": "No se solicitó mano de obra",
    "cms.incoming.record.field.ifAnswerCall": "Contesta manualmente la llamada",
    "cms.incoming.record.field.isAnswerCall": "Manual contestado",
    "cms.incoming.record.field.notAnswerCall": "El hombre no responde",
    "cms.incoming.record.field.releasecause.choose": "Seleccione el motivo de liberación de llamada",
    "cms.incoming.record.field.release.id": "Código de motivo de liberación de llamada",
    "cms.incoming.record.field.release.causecode": "Código de asignación de causa",
    "cms.incoming.releasecause.causecode.tip": "El código de asignación de causa está fuera del rango (0 ~ 45) ",
    "cms.incoming.record.report.beginTime": "Hora de inicio de la consulta",
    "cms.incoming.record.report.endTime": "Hora de finalización de la consulta",
    "cms.requiredcallback.message.timeRangeCheck": "El intervalo de tiempo es el mismo día",
    "cms.failurecall.field.waitTime.export": "Duración de espera (s) ",
    "cms.failurecall.field.waitBegin": "Hora de inicio de llamada en espera",
    "cms.failurecall.field.ackBegin": "Hora de inicio de respuesta de llamada",
    "cms.failurecall.field.waitAnsNum": "Tiempos de espera de respuesta",
    "cms.failurecall.field.waitAnsTime": "Duración de espera de respuesta (segundos) ",
    "cms.failurecall.field.succWaitAnsTime": "Duración de respuesta de llamada exitosa (segundos) ",
    "cms.failurecall.field.noAckNum": "Número de llamadas contestadas sin llamadas",
    "cms.failurecall.field.succQueueWaitTime": "Duración de cola de llamadas exitosas (segundos) ",
    "cms.failurecall.field.succQueueWaitNum": "Tiempos de cola de llamadas exitosas",
    "cms.failurecall.field.failQueueWaitNum": "Duración de espera de pérdida de llamada (segundos) ",
    "cms.failurecall.field.inOccupyNum": "Número de solicitudes manuales",
    "cms.failurecall.field.inCallSuccNum": "Número de llamadas manuales",
    "cms.failurecall.field.inCallTime": "Duración de la llamada manual (segundos) ",
    "cms.failurecall.field.queOverToIvrNum": "Número de llamadas colgadas en la cola a IVR",
    "cms.failurecall.field.queOverToIvrTime": "Tiempo total de enganche de la cola a IVR (segundos) ",
    "cms.failurecall.field.hangUpNum": "Número de transmisiones manuales a IVR",
    "cms.failurecall.field.hangUpTime": "Tiempo total de transferencia manual a IVR",
    "cms.failurecall.field.hangUpReleaseNum": "Número de llamadas transferidas manualmente a IVR y colgadas",
    "cms.indexmonitor.index.noTrafficData": "Sin datos de tráfico",
    "cms.indexmonitor.index.SkillQueueTips": "El nivel de servicio de una cola de habilidades se mide en función de la tasa de finalización de llamadas dentro de 20 segundos. El nivel de servicio esperado predeterminado es del 80%. El administrador del inquilino puede modificar el nivel de servicio en la configuración de parámetros del inquilino: Parámetros del inquilino > Monitoreo del centro de llamadas > Monitoreo del indicador > Nivel de servicio esperado de la cola de habilidades.",
    "cms.indexmonitor.index.SkillQueueTips2": "El nivel de servicio de una cola de habilidades se mide en función de la tasa de finalización de llamadas en 20 segundos. El nivel de servicio esperado predeterminado es del 80%. El administrador del sistema puede cambiar el nivel de servicio en Parámetros del sistema > Monitoreo del centro de llamadas > Monitoreo del indicador > Nivel de servicio esperado de la cola de habilidades.",
    "cms.indexmonitor.index.loadFail": "Error de carga",
    "cms.failurecall.queue.abandon": "Llamada abandonada",
    "cms.failurecall.callback": "Llamada que requiere devolución",
    "cms.faliurecall.incomingcall": "Llamada entrante",
    "cms.vcallecenter.message.checkpasswordfailed": "Error al verificar la regla de contraseña de inicio de sesión",
    "cms.agent.field.admin":"Administradores",
    "cms.agent.field.commonagent":"Operador",
    "cms.agent.field.qualitychecker":"Inspector de calidad",
    "cms.failure.callerNo.maxLength.error":"Se permite un máximo de 64 caracteres.",
    "cms.indexmonitor.curDay.indicator":"Indicador del día actual",
    "cms.indexmonitor.field.skillConnectedIn20":"Número de llamadas conectadas en 20 segundos",
    "cms.agent.label.agentDetail":"Detalles del agente",
    "cms.custdata.operlog.popwindowmessage":"Introduzca la contraseña utilizada para generar el archivo comprimido. La contraseña comprimida debe contener letras, dígitos y caracteres especiales _ {'@'} %",

  "cms.report.title.submediaType" : "Tipo de Multimedia",
  "cms.multimedia.monitor.field.callNum":"Número de llamadas IVR",
  "cms.multimedia.monitor.field.transNum":"Número de llamadas IVR a manuales",
  "cms.multimedia.monitor.field.transRate":"Tarifa IVR a manual",
  "cms.multimedia.monitor.field.connectedNum":"Número de llamadas de IVR conectadas",
  "cms.multimedia.monitor.field.connectedRate":"Tasa de finalización de llamadas IVR",
  "cms.multimedia.monitor.field.durationAverage":"Duración media de la llamada IVR",
  "cms.multimedia.monitor.field.callNum.desc":"Número de llamadas que se atienden automáticamente actualmente por el dispositivo IVR",
  "cms.multimedia.monitor.field.transNum.desc":"Número de llamadas IVR a manuales",
  "cms.multimedia.monitor.field.transRate.desc":"Porcentaje de llamadas de IVR a manuales a llamadas de IVR",
  "cms.multimedia.monitor.field.connectedNum.desc":"Número de llamadas IVR cuya duración de llamada es mayor que 0",
  "cms.multimedia.monitor.field.connectedRate.desc":"Porcentaje del número de llamadas IVR conectadas al número de llamadas IVR",
  "cms.multimedia.monitor.field.durationAverage.desc":"Duración total de la llamada IVR/Número de llamadas IVR conectadas",
  "cms.report.field.submediatype.instagram": "Instagram",
  "cms.report.field.submediatype.telegram": "Telegram",
  "cms.qcrelationconf.field.attendantGroupName": "Nombre del grupo de asistentes",
  "cms.report.field.selectTrafficGroup": "Seleccionar grupo de asistentes",
  "cms.report.placeholder.attendantGroupName": "Ingrese el nombre del grupo asistente.",
  "cms.qcrelationconf.field.attendantGroup": "Grupo de asistentes",
  "cms.report.message.selectTrafficGroupMax": "Se puede seleccionar un máximo de 1000 grupos asistentes.",
  "cms.agentMonitor.message.operatorChecked": "Error en la operación. El objeto inspeccionado es un inspector y se está realizando la operación de inspección",
  "cms.export.message.required": "Campo obligatorio",
  "cms.field.length.limit": "La longitud no puede exceder de 0",
  "cms.sysparameter.batchreset": "Restablecimiento por lotes",
  "cms.sysparameter.batchrefresh": "Sincronización por lotes",
  "cms.report.subscribe.add": "Nueva suscripción de informe",
  "cms.report.subscribe.edit": "Editar suscripción a informes",
  "cms.qcrelationconf.tab.member": "Miembros",
  "cms.qcrelationconf.add.attendantGroup": "Agregar grupo de tráfico inspeccionado",
  "cms.qcrelationconf.message.isDeleteTrafficGroup": "¿Está seguro de que desea eliminar el grupo de tráfico",
  "cms.report.subscribe.checkreportnum": "Seleccione al menos un informe",
  "cms.sysparameter.edit.basic.information": "Editar información básica",
  "cms.sysparameter.edit.character": "Editar propiedades",
  "cms.sysparameter.edit.validate.ip": "Introduzca una dirección IP correcta.",
  "cms.message.choose": "Por favor, seleccione los datos.",
  "cms.subscribe.template.isused": "La plantilla de correo electrónico ya está en uso",
  "cms.qcrelationconf.message.unauthorized": "Los datos guardados contienen agentes no válidos",
  "cms.subscribe.template.statuserror": "El estado de la plantilla de correo electrónico es incorrecto.",
  "cms.report.subscribe.editreport":"Editar informe",
  "cms.report.subscribe.addreport":"Agregar un informe",
  "cms.fullscreen.agent.auth": "No tiene permiso para ver la organización actual. Seleccione otra",
  "cms.agentMonitor.label.changestate_error":"Error al cambiar el estado de inspección, Error de operación.",
  "cms.agent.label.systempara":"Configuración de parámetros",
  "cms.indexmonitor.message.skillConnectedQueue": "Duración total de la cola de todas las llamadas conectadas en la cola",
  "cms.indexmonitor.message.skillAbandonQueue": "Duración total de todas las llamadas no conectadas en la cola",
   "cms.indexmonitor.field.skillConnectedQueue": "Duración total de la cola de llamadas",
  "cms.indexmonitor.field.skillAbandonQueue": "Duración total de la cola de pérdida de llamada",
}