<template>
  <div>
    <sweet-dialog v-model="dialogVisible" :title="dialogTitle" width="616px" class="aicc-dialog-limit-height">
      <sweet-form label-width="auto" :label-position="labelPosition" :rules="rules" ref="ruleFormRef" :model="queryForm">
        <sweet-form-item  :inline-message="true" :showErrorTips="true" :label="$t('oifde.export.password')" :required='true' prop="password">
          <sweet-input v-model="queryForm.password"
           type="password" @paste.native.capture.prevent="handlePaste"
           :placeholder="$t('oifde.export.password.placeholder')"
           style="width: 400px"/>
        </sweet-form-item>
        <sweet-form-item prop="tips" :label="' '" class="footer--padding">
          <span class="footer--tips">{{ $t('oifde.export.password.error.tips') }}</span>
        </sweet-form-item>
      </sweet-form>
      <template #footer>
        <sweet-button class="aicc-form-button-cancel" @click="cancel()"  >{{$t('oifde.common.dialog.confirm.buttonCancel')}}</sweet-button>
        <sweet-button class="aicc-form-button-confirm" type="primary" @click="submit()" >{{$t('oifde.common.dialog.confirm.buttonSure')}}</sweet-button>
      </template>
    </sweet-dialog>
  </div>
</template>

<script>
import i18n from "@/lang";

export default {
  name: "oifdeExportDialog",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    passExportResultParam: {
      type: Object
    },
    dialogTitle: {
      type: String
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      }
    }
  },
  data() {
    return {
      labelPosition: window.isRTL ? 'right' : 'left',
      queryForm: {
        password: '',
      },
      rules: {
        password: [{
          required: true,
          validator: this.validatorPassword,
          trigger: ['blur', 'change']
        }],
      },
      id: '',
      taskId: '',
      valid: true,
      // 导出列表弹出框
      viewExportListVisible: false,
    };
  },
  methods: {
    handlePaste() {
      return;
    },
    // 校验导出密码
    validatorPassword(rule, value, callback) {
      if (!this.queryForm.password) {
        return callback(new Error(i18n.global.t('SM.LOGIN.TIPS.REQUIRED')))
      }
      if (value.length < 8) {
        return callback(new Error(i18n.global.t('aicc.lengthMinLimit', [8])))
      }
      if (value.length > 12) {
        return callback(new Error(i18n.global.t('aicc.lengthLimit', { limit: 12 })))
      }
      const invalie_reg = new RegExp(/[/\-#(){};&|<>$`\\!\n\s\t\r]/);
      const regEn = new RegExp(/(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[\W_]).{8,12}/);
      if (invalie_reg.test(this.queryForm.password)) {
        callback(new Error(this.$t('oifde.export.password.error.message')));
      }
      if (!regEn.test(this.queryForm.password)) {
        callback(new Error(this.$t('oifde.export.password.error.message')));
      }
      callback();
    },
    cancel() {
      this.dialogVisible = false;
    },
    async submit() {
      await this.$refs['ruleFormRef'].validate(valid => {
        if (valid) {
          this.valid = true;
        } else {
          this.valid = false;
        }
      });
      if (!this.valid) {
        return;
      }
      let param = this.passExportResultParam;
      param['password'] = this.queryForm.password;
      this.$emit('emitToExportList',  param);
    },
  }
};
</script>

<style scoped>
.footer--tips {
  color: #707070;
  line-height: 16px;
  font-weight: 400;
  width: 400px;
  word-break: break-word;
}
.footer--padding {
  padding-top: 0;
}
</style>