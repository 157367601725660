import {createRouter, createWebHashHistory} from 'vue-router'
import baseRoutes from '@/views/base/router.js'
import ccmessagingRoutes from '@/views/ccmessaging/router/index.js'
import agentconsoleRoutes from '@/views/agentconsole/router.js'
import cssRoutes from '@/views/css/router.js'
import ccmanagementRoutes from '@/views/ccmanagement/router.js'
import oifdeRoutes from '@oifde/router/index'
import cmsRouter from '@/views/cms/router'
import isalesRoutes from '@/views/isales/router'
import sumRouter from '@/views/sumweb/router'
import ccprovisionRoutes from '@/views/ccprovision/router/index.js'
import ccnRouter from '@/views/ccnotification/router'

const defaultRoutes = [
  ...ccmanagementRoutes.default,
  ...cmsRouter.default,
  ...isalesRoutes.default,
  ...oifdeRoutes.default,
  ...ccmessagingRoutes.default,
  ...ccprovisionRoutes.default,
  ...ccnRouter.default
]
const pageWithSideNav = [
  ...ccmanagementRoutes.pageWithSideNav,
  ...isalesRoutes.pageWithSideNav,
  ...ccmessagingRoutes.pageWithSideNav,
  ...sumRouter.pageWithSideNav,
  ...oifdeRoutes.pageWithSideNav,
  ...ccprovisionRoutes.pageWithSideNav,
  ...ccnRouter.pageWithSideNav,
  ...cmsRouter.pageWithSideNav
]
const pageWithBackHeader = [
  ...ccmanagementRoutes.pageWithBackHeader,
  ...oifdeRoutes.pageWithBackHeader,
  ...isalesRoutes.pageWithBackHeader,
  ...cmsRouter.pageWithBackHeader,
  ...ccmessagingRoutes.pageWithBackHeader,
  ...sumRouter.pageWithBackHeader,
  ...ccprovisionRoutes.pageWithBackHeader,
  ...ccnRouter.pageWithBackHeader
]
const pageWithSelfTab = [
  ...ccmanagementRoutes.pageWithSelfTab,
  ...oifdeRoutes.pageWithSelfTab,
  ...isalesRoutes.pageWithSelfTab,
  ...cmsRouter.pageWithSelfTab,
  ...ccmessagingRoutes.pageWithSelfTab,
  ...sumRouter.pageWithSelfTab,
  ...ccprovisionRoutes.pageWithSelfTab,
  ...ccnRouter.pageWithSelfTab
]
baseRoutes[1].pageWithSideNav = pageWithSideNav
baseRoutes[1].pageWithBackHeader = pageWithBackHeader
baseRoutes[1].pageWithSelfTab = pageWithSelfTab

const moduleRoutes = [
  ...baseRoutes,
  ...pageWithSideNav,
  ...pageWithBackHeader,
  ...pageWithSelfTab,
  ...defaultRoutes,
  ...agentconsoleRoutes,
  ...cssRoutes
]

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "base" */ '@/views/base/login/index.vue')
  },
  {
    path: '/',
    name: 'redirect',
    component: () => import(/* webpackChunkName: "base" */ '@/views/base/frame/redirect.vue')
  },
  {
    path: '/frame',
    name: 'frame',
    component: () => import(/* webpackChunkName: "base" */ '@/views/base/frame/index.vue'),
    children: moduleRoutes
  },
  {
    path: '/fullscreen-monitor',
    name: 'fullscreen-monitor',
    component: () => import('@/views/cms/components/fullscreen-configuration/fullscreen-monitor.vue'),
  }
]

const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior() {
    // 始终滚动到顶部
    return {top: 0}
  }
})

export {router, moduleRoutes}
