// 机器人管理
import Layout from '@oifde/components/OifdeLayout/OifdeLayout.vue'

export default [
  {
    path: '/aiccOifde/keyPressRobot',
    component: Layout,
    redirect: '/aiccOifde/keyPressRobot/keyPressRobot',
    children: [
      {
        path: '/aiccOifde/keyPressRobot/keyPressRobot', // 普通IVR
        name: 'oifdeKeyPressRobot',
        meta: {
          title: 'oifde.route.keyPressRobot',
          noCache: true,
          menuId: 'oifde-key-press-robot'
        },
        component: () => import('@/views/oifde/views/keyPressRobot/keyPressRobot.vue')
      }
    ]
  },
  {
    path: '/aiccOifde/robot',
    component: Layout,
    redirect: '/aiccOifde/robot/accessCode',
    children: [
      {
        path: '/aiccOifde/robot/accessCode', // 智能机器人
        name: 'oifdeAccessCode',
        meta: {
          title: 'oifde.route.RobotManage',
          noCache: true,
          menuId: 'oifde-access-codes'
        },
        component: () => import('@/views/oifde/views/accessCode/accessCode.vue')
      }
    ]
  }
]