<template>
    <div class="aicc-tabs" :class="{ 'rtl-adaptation': isMirrorLanguage }">
        <!-- 导航栏区域 -->
        <div class="aicc-tab-nav" :class="[{ 'borderBottom': borderBottom, 'padding-left-right': paddingLeftRight }, { 'size-large': size === 'large' }]"
            ref="tabNavRef">
            <div class="more-button" ref="moreButtonRef" v-if="isShowMoreButton">
                <sweet-dropdown trigger="click" @command="handleClickTab">
                    <div class="more-button-text">
                        <span>{{ $t('chat.client.label.more') }}</span>
                        <sweet-icon><arrow-down /></sweet-icon>
                    </div>
                    <template #dropdown>
                        <sweet-dropdown-menu>
                            <sweet-dropdown-item v-for="item in moreDropdownList" :key="item.name" :command="item.name">
                                {{ item.label }}
                            </sweet-dropdown-item>
                        </sweet-dropdown-menu>
                    </template>
                </sweet-dropdown>
            </div>
            <div ref="tabWrapRef" class="aicc-tab-nav__wrap" :style="tabWrapStyle">
                <div class="tab-nav-item" v-for="item in tabNavList" :key="item.name"
                    :class="{ active: item.name === currentValue }" @click="handleClickTab(item.name)">
                    <sweet-tooltip :open-delay="800" :content="item.label" placement="top">
                        {{ item.label }}
                    </sweet-tooltip>
                </div>
            </div>
        </div>
        <!-- 内容区域 -->
        <div class="tab-content-wrap">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'aicc-tabs'
}
</script >
<script setup>
import { toRefs, ref, watch, useSlots, onMounted, provide, computed, nextTick } from 'vue'
const props = defineProps({
    tabValue: {
        type: String,
        default: ''
    },
    showAll: {
        type: Boolean,
        default: true
    },
    borderBottom: {
        type: Boolean,
        default: true
    },
    paddingLeftRight: {
        type: Boolean,
        default: false,
    },
    size: {
        type: String,
        default: 'default'
    }
})
const emit = defineEmits(['tab-change'])
const { tabValue, showAll, borderBottom, paddingLeftRight, size } = toRefs(props)

const slots = useSlots()

// 当前选中的标签页值
const currentValue = ref(tabValue.value)
provide('currentTab', currentValue)

// 标签页数组
const tabNavList = ref([])


const handleInitTabInstances = () => {
    if (slots.default) {
        const paneSlots = []
        const slotsList = slots.default()
        const slotsListLen = slotsList.length
        // 兼容嵌套写法和遍历写法
        for (let i = 0; i < slotsListLen; i++) {
            if (slotsList[i].type) {
                if (['AiccTabPane', 'aicc-tab-pane'].includes(slotsList[i].type.name)) {
                    paneSlots.push(slotsList[i])
                } else if (Array.isArray(slotsList[i].children)) {
                    const children = slotsList[i].children.filter(subNode => subNode.type && ['AiccTabPane', 'aicc-tab-pane'].includes(subNode.type.name))
                    paneSlots.push(...children)
                    // 兼容template标签
                    slotsList[i].children.forEach(subItem => {
                        if (Array.isArray(subItem.children)) {
                            const subChildren = subItem.children.filter(subNode => subNode.type && ['AiccTabPane', 'aicc-tab-pane'].includes(subNode.type.name))
                            paneSlots.push(...subChildren)
                        }
                    })
                }
            }
        }
        tabNavList.value = paneSlots.map(({ props }) => {
            if (props) {
                return {
                    name: props.name,
                    label: props.label
                }
            }
        })
    }

    if (!tabValue.value) {
        if (tabNavList.value.length > 0) {
            currentValue.value = tabNavList.value[0].name
        }
    }
    setScrollWidth(currentValue.value)

}

// 标签页点击事件处理方法
const handleClickTab = (name) => {
    currentValue.value = name
    setScrollWidth(name)
}

const tabNavRef = ref()
const tabWrapRef = ref()

const isShowMoreButton = ref(false)
// tabsNav中的元素是否超出需要滚动
const isScroll = () => {
    const tabNavWidth = tabNavRef.value?.clientWidth
    const tabWrapWidth = tabWrapRef.value?.clientWidth
    isShowMoreButton.value = tabWrapWidth > tabNavWidth
}

// 动态偏移样式
const tabWrapStyle = computed(() => {
    return { transform: `translateX(${tabLineX.value}px)` }
})

// 判断从第几个元素开始溢出
const overflowIndex = (overflow) => {
    const moreButton = moreButtonRef.value?.offsetLeft
    const childrenNav = tabWrapRef.value?.children
    let overDomIndex = -1
    if (overflow) {
        // 溢出的时候与 更多 按钮对比
        for (let i = 0; i < childrenNav.length; i++) {
            if (childrenNav[i].offsetLeft + childrenNav[i].clientWidth > moreButton) {
                overDomIndex = i
                return overDomIndex
            }
        }
    }
    return overDomIndex
}

const moreDropdownList = ref([])
// 更多下拉按钮列表
const moreDropdown = (index) => {

    // 展示超出的元素
    if (index > -1 && !showAll.value) {
        moreDropdownList.value = tabNavList.value.slice(index)
    } else {
        // 展示全部
        moreDropdownList.value = tabNavList.value.slice(0)
    }
}

// 判断是否为镜像语言
const isMirrorLanguage = computed(() => {
    return window.isRTL
})
const tabLineX = ref(0)
const moreButtonRef = ref()
// 设置偏移距离
const setScrollWidth = (name) => {
    if (!isShowMoreButton.value) {
        return
    }
    const index = tabNavList.value.findIndex(item => item.name === name)
    const currentTab = tabWrapRef.value?.children[index]
    if (!isMirrorLanguage.value) {
        const moreButton = moreButtonRef.value?.offsetLeft
        const scrollDistance = currentTab.offsetLeft + currentTab.clientWidth - moreButton
        // 偏移与复位
        if (index == 0 || index == 1 || index == tabNavList.value.length - 1) {
            tabLineX.value = scrollDistance > 0 ? -scrollDistance : 0
        } else {
            tabLineX.value = scrollDistance > 0 ? -scrollDistance - 50 : -50
        }
    } else {
        const tabNavWidth = tabNavRef.value?.clientWidth
        const tabWrapWidth = tabWrapRef.value?.clientWidth
        const moreButtonWidth = moreButtonRef.value?.clientWidth
        const scrollDistance = tabWrapWidth - tabNavWidth - currentTab.offsetLeft
        // 偏移与复位
        if (index == 0 || index == 1 || index == tabNavList.value.length - 1) {
            tabLineX.value = scrollDistance > 0 ? scrollDistance + moreButtonWidth : 0
        } else {
            tabLineX.value = scrollDistance > 0 ? scrollDistance + moreButtonWidth - 50 : -50
        }

    }
}

onMounted(() => {
    handleInitTabInstances()
})

watch(slots.default, () => {
    handleInitTabInstances()
}, { deep: true })

watch(tabNavList, () => {
    nextTick(() => {
        isScroll()
        const index = overflowIndex(isShowMoreButton.value)
        moreDropdown(index)
    })
})

watch(isShowMoreButton, (newVal) => {
    if (newVal) {
        nextTick(() => {
            const index = overflowIndex(isShowMoreButton.value)
            moreDropdown(index)
        })
    }
})

watch(tabValue, (newVal) => {
    currentValue.value = newVal
})

watch(currentValue, (newVal) => {
    const params = tabNavList.value.find(item => item.name === newVal)
    emit('tab-change', params)
})

// 监听浏览器宽度，变化时进行重置
let screenWidth = ref(document.body.clientWidth)
window.onresize = () => {
    return (() => {
        window.screenWidth = document.body.clientWidth
        screenWidth.value = window.screenWidth
    })()
}
let timer = false
watch(screenWidth, () => {
    // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
    if (!timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        timer = true
        setTimeout(() => {
            isScroll()
            const index = overflowIndex(isShowMoreButton.value)
            moreDropdown(index)
            timer = false
        }, 400)
    }
})


defineExpose({ handleInitTabInstances })

</script>

<style lang="less" scoped>
@base-color: #366BFC;

.aicc-tabs {
    display: flex;
    flex-direction: column;
    height: 100%;

    .aicc-tab-nav {
        overflow: hidden;
        white-space: nowrap;
        position: relative;

        .aicc-tab-nav__wrap {
            display: inline-block;
            transition: all 0.5s;

            .tab-nav-item {
                display: inline-block;
                font-size: var(--14px-to-rem);
                color: #707070;
                line-height: 1.5rem;
                font-weight: 400;
                transition: color 0.5s;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: center;
                cursor: pointer;

                &:hover {
                    color: @base-color;
                }

                &.active {
                    color: @base-color;
                    border-bottom: 2px solid @base-color;
                    font-weight: 500;
                }
            }
        }

        .more-button {
            position: absolute;
            right: 0;
            z-index: 9;
            background: #fff;
            box-shadow: -5px 0 5px -5px rgba(0, 0, 0, 0.4);

            :deep(.el-dropdown) {
                max-height: calc(1.5rem + 2px);
            }

            .more-button-text {
                font-size: var(--14px-to-rem);
                color: #707070;
                font-weight: 400;
                cursor: pointer;
                display: flex;
                align-items: center;

                &:hover {
                    color: @base-color;
                }
            }
        }

        .tab-nav-item+.tab-nav-item {
            margin-left: 1rem;
        }

    }

    .aicc-tab-nav.borderBottom {
        border-bottom: 1px solid rgba(238, 238, 238, 1);
    }

    .aicc-tab-nav.padding-left-right {
        padding: 0 1rem;
    }

    .aicc-tab-nav.size-large {
        line-height: 1.5;

        .tab-nav-item+.tab-nav-item {
            margin-left: 2rem;
        }
    }

    .tab-content-wrap {
        flex: 1;
        overflow: hidden;
    }

    &.rtl-adaptation {
        .aicc-tab-nav {
            .tab-nav-item+.tab-nav-item {
                margin-left: 0;
                margin-right: 1rem;
            }

            .more-button {
                left: 0;
                right: auto;
            }
        }

        .aicc-tab-nav.size-large {

            .tab-nav-item+.tab-nav-item {
                margin-left: 0;
                margin-right: 2rem;
            }
        }
    }
}
:deep(.el-dropdown-menu__item) {
  color: black !important;
}
</style>