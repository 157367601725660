<template>
  <div class="controlLine">
    <sweet-row v-if="isServiceConfig">
      <sweet-col
          :span="12"
          v-for="elementInfo in basicElements"
          :key="elementInfo.code"
          draggable="true"
          :data-shape="elementInfo.shape"
          @click="addItem(elementInfo.shape)"
      >
        <sweet-tooltip :content="elementInfo.name">
          <div class="block elementInfo" :data-code="elementInfo.code">
            <img :data-shape="elementInfo.shape"
                 :src="elementInfo.imgFile">
          </div>
        </sweet-tooltip>
      </sweet-col>
    </sweet-row>
    <sweet-divider direction="vertical" v-if="isServiceConfig"/>

    <div style="width: 100px">
      <sweet-select class="zoomClass" v-model="zoomValue" placeholder=" " size="small" @change="zoomTo">
        <sweet-option v-for="item in zooms" :key="item.value" :label="item.label" :value="item.value"/>
      </sweet-select>
    </div>
    <sweet-tooltip :content="$t('iolp.workflow.label.graphZoomIn')">
      <img src="@/views/oifde/assets/img/tools/ic_enlarge.png" @click="graphZoomIn"/>
    </sweet-tooltip>
    <sweet-tooltip :content="$t('iolp.workflow.label.graphZoomOut')">
      <img src="@/views/oifde/assets/img/tools/ic_narrow.png" @click="graphZoomOut"/>
    </sweet-tooltip>
    <sweet-tooltip :content="$t('oifde.mxgraph.resetView')" v-if="isServiceConfig">
      <img src="@/views/oifde/assets/img/tools/ic_arrangement.png" @click="resetView"/>
    </sweet-tooltip>
    <sweet-tooltip :content="$t('oifde.navbar.screenfull')" v-if="!isFullscreen">
      <img src="@/views/oifde/assets/img/tools/ic_fullScreen.png" @click="screenfull"/>
    </sweet-tooltip>
    <sweet-tooltip :content="$t('oifde.screenfull.cancel')" v-else>
      <img style="width: 16px;height: 16px" src="@/views/oifde/assets/img/tools/ic_CancelFullScreen@3x.png"
           @click="screenfull"/>
    </sweet-tooltip>
    <sweet-divider direction="vertical" v-if="isServiceConfig"/>

    <sweet-tooltip :content="$t('iolp.workflow.label.previous')" v-if="isServiceConfig">
      <img :class="{ disabled: !canUndo }" src="@/views/oifde/assets/img/tools/ic_PreviousStep.png"
           @click="undo"/>
    </sweet-tooltip>
    <sweet-tooltip :content="$t('iolp.workflow.label.next')" v-if="isServiceConfig">
      <img :class="{ disabled: !canRedo }" src="@/views/oifde/assets/img/tools/nextStep.png"
           @click="redo"/>
    </sweet-tooltip>
    <sweet-tooltip :content="$t('oifde.mxgraph.delete')" v-if="isServiceConfig">
      <img src="@/views/oifde/assets/img/tools/ic_delete.png" @click="removeNode"/>
    </sweet-tooltip>
  </div>
</template>
<script>
import {defineComponent, isRef} from 'vue';
import {IOLP_BASIC_ELEMENTS} from "@/views/ccmanagement/pages/iolp-diagram/iolpElementInfo/IolpElementInfo";

export default defineComponent({
  name: 'IolpTopTools',
  inject: ['getGraphAPI', 'canRedo', 'canUndo', 'scaleFactor'],
  props: {
    isServiceConfig: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      basicElements: [],
      zoomValue: 1,
      zooms: [
        {
          label: "25%",
          value: 0.25
        },
        {
          label: "50%",
          value: 0.50
        },
        {
          label: "75%",
          value: 0.75
        },
        {
          label: "100%",
          value: 1
        },
        {
          label: "125%",
          value: 1.25
        },
        {
          label: "150%",
          value: 1.50
        },
        {
          label: "200%",
          value: 2
        },
        {
          label: "300%",
          value: 3
        },
        {
          label: "400%",
          value: 4
        }
      ],
      isFullscreen: false,
    }
  },
  watch: {
    scaleFactor: {
      handler(newVal, oldVal) {
        this.zoomValue = Math.round(newVal * 100) + "%";
      },
      deep: true,
      immediate: true
    },
  },
  mounted() {
    this.graphApi = this.getGraphAPI();
    this.initElements();
    document.addEventListener('fullscreenchange', (event) => {
      if (document.fullscreenElement) {
        this.isFullscreen = true;
      } else {
        this.isFullscreen = false;
        const body = document.querySelector("body");
        body.classList.remove("iolpGraphFullScreen");
      }
    })
  },
  methods: {
    zoomTo(val) {
      this.graphApi.zoomTo(val);
    },
    undo() {
      this.graphApi.undo();
    },
    redo() {
      this.graphApi.redo();
    },
    removeNode() {
      this.graphApi.removeNode();
    },
    // 画布缩放-放大
    graphZoomIn() {
      this.graphApi.setZoom(0.2);
    },
    // 画布缩放-缩小
    graphZoomOut() {
      this.graphApi.setZoom(-0.2);
    },
    initElements() {
      this.basicElements = IOLP_BASIC_ELEMENTS;
    },
    screenfull() {
      if (this.isFullscreen) {
        document.exitFullscreen();
        const body = document.querySelector("body");
        body.classList.remove("iolpGraphFullScreen");
      } else {
        const divs = document.getElementsByClassName("tabs-content");
        const body = document.querySelector("body");
        body.classList.add("iolpGraphFullScreen");
        this.graphApi.setFullScreen(divs[0]);
      }
    },
    resetView() {
      this.graphApi.resetView({
        type: 'center',
      });
    }
  }
})
</script>

<style lang="less" scoped>
.controlLine {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #aaa;
  border-bottom: 1px solid #dfdfdf;
  z-index: 99;
  height: 40px;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  position: absolute;
  margin-inline-start: calc(30vw);
  margin-top: 10px;
}

.controlLine img {
  margin: 0 10px;
  cursor: pointer;
}

.controlLine img.disabled {
  cursor: not-allowed;
}

:deep(.el-select .el-input__wrapper) {
  border: none !important;
  box-shadow: 0 0 0 0px !important;
}
</style>
