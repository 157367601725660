export default {
  "dy.agent.audio.tab.outgoingcall" : "Llamada saliente",
  "dy.agent.audio.tab.internalcall" : "Llamada interna",
  "dy.agent.tab.over.number.hints" : "Se puede abrir un máximo de ocho pestañas. No puede abrir más pestañas. Cierre otras pestañas primero",
  "dy.chat.multimedia.update" : "Actualizar",
  "dy.chat.agent.logout.hints" : "No se ha registrado, por favor inicie sesión para comenzar su trabajo",
  "dy.chat.label.contacts" : "Contactos",
  "dy.chat.label.call" : "llamar",
  "dy.chat.label.multimedia" : "Multimedia",
  "dy.chat.label.history" : "History",
  "dy.chat.label.monitor" : "Monitor",
  "dy.agent.label.id" : "Identificación:",
  "dy.agent.label.enter.exnum" : "Ingrese el número externo",
  "dy.chat.multimedia.required" : "Obligatorio",
  "dy.chat.history.disconnect.tip" : "La llamada está desconectada y puede enviar un mensaje fuera de línea para reconectarse con el cliente",
  "dy.agent.voice.extnumber.isnull" : "El número externo es nulo.",
  "dy.agent.voice.msg.noskill" : "No hay cola de habilidades disponible",
  'dy.agent.session.list.timeout': 'Tiempo de espera',
  'dy.session.transfer.button': 'Entendido',
  'dy.agent.transfer.failed': 'Error al transferir al agente de destino',
  'dy.agent.twoinnerhelp.failed': 'Error al consultar el agente de destino',
  'dy.agent.threeinnerhelp.failed': 'Fallo de tres partes del agente de destino',
  'dy.agent.message.tipsNotMonitor': 'Usted no es un inspector. No puede realizar la inspección.',
  'dy.agent.message.selectInfo': 'Haga clic en el agente primero para inspeccionar el agente.',
  'dy.agent.attachment.notPreview': 'El tipo de archivo no es compatible con la vista previa, descargue el archivo para ver el contenido.',
  'dy.chat.agent.novoEmail': 'Nuevo correo electrónico',
  "dy.chat.sendmessage.recipient" : "En",
  "dy.chat.chatcard.folded": "Doblado",
  "dy.chat.status.drafts": "Borradores",
  "dy.agent.call.selectextnumber": "Seleccione un número externo.",
  "dy.agent.call.inputextnumber": "Introduzca un número externo."
}