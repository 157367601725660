<template>
  <div class="ccm-mock-header">
    <div class="header-title">
      {{ headerTitle }}
    </div>
    <div :id="'mock-expanded-title-' + routeName" class="mock-expanded-title"></div>
    <div :id="'mock-right-content-' + routeName" class="mock-right-content"></div>
  </div>
</template>

<script lang="ts" setup>

import { toRefs } from "vue";

const props = defineProps({
  headerTitle: {
    type: [String,Number],
    required: true
  },
  routeName: [String,Symbol]
});
const {headerTitle} = toRefs(props);
</script>


<style scoped lang="less">
.ccm-mock-header {
  display: flex;
  align-items: center;
  height: 56px;
  width: 100%;
  padding: 0 20px;
  background-color: #FFFFFF;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1);
  position: relative;
  z-index: 1;

  .header-title {
    font-size: var(--20px-to-rem);
    font-weight: 500;
    margin-inline-start: 18.5px;
  }

  .mock-expanded-title {
    display: flex;
    align-items: center;
    margin-inline-start: 24px;
  }

  .mock-right-content {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
  }
}
</style>