export default {
  "ccmessaging.chat.chat.closeDialog": "Are you sure you want to close it?",
  "ccmessaging.chat.silentagent.config.add.error": "Failed to create the voice silent agent configuration.",
  "ccmessaging.chat.silentagent.config.add.success": "Succeeded in configuring the voice silent agent.",
  "ccmessaging.chat.fiveGCard.add": "Create 5G card",
  "ccmessaging.chat.fiveGCard.edit": "Edit 5G Card",
  "ccmessaging.chat.fiveGCarouselCard.add": "Create a 5G Carousel Card",
  "ccmessaging.chat.fiveGCarouselCard.edit": "Edit a 5G Carousel Card",
  "ccmessaging.chat.webCard.add": "Create a Web Card",
  "ccmessaging.chat.webCard.edit": "Edit a Web Card",
  "ccmessaging.chat.coBrowseSite.add": "Create a Web Page Collaboration Address",
  "ccmessaging.chat.coBrowseSite.edit": "Edit a Web page collaboration address",
  "ccmessaging.chat.richTextPage.add": "Create Rich Text",
  "ccmessaging.chat.richTextPage.edit": "Edit Rich Text",
  "ccmessaging.chat.chatPhrase.add": "Create Common Phrases",
  "ccmessaging.chat.chatPhrase.edit": "Eidt Common Phrases",
  "ccmessaging.chat.audioLibrary.add": "Create a voice",
  "ccmessaging.chat.audioLibrary.edit": "Edit a voice",
  "ccmessaging.chat.videoLibrary.add": "Create a video",
  "ccmessaging.chat.videoLibrary.edit": "Edit a video",
  "ccmessaging.chat.imgLibrary.add": "Create a picture",
  "ccmessaging.chat.imgLibrary.edit": "Edit a picture",
  "ccmessaging.chat.emoLibrary.add": "Create personalized emoticons",
  "ccmessaging.chat.emoLibrary.edit": "Edit personalized emoticons",
  "ccmessaging.chat.docLibrary.add": "Create a document",
  "ccmessaging.chat.docLibrary.edit": "Edit a document",
  "ccmessaging.chat.locate.add": "Add Address",
  "ccmessaging.chat.locate.edit": "Edit Address",
  "ccmesaging.chat.agentconsole.commom.label": "Tag",
  "ccmesaging.chat.agentconsole.label.callReason": "Call Reason",
  "ccmesaging.chat.agentconsole.label.commonreply": "Common Replies",
  "ccmesaging.chat.agentconsole.label.expression": "Emoji",
  "ccmesaging.chat.agentconsole.label.fontstyle": "Font Style",
  "ccmesaging.chat.agentconsole.label.historicalcontact": "Historical Contact",
  "ccmesaging.chat.agentconsole.label.incallmessage": "Incoming Call Message",
  "ccmesaging.chat.agentconsole.label.Realtimetransfer": "Real-Time Translation",
  "ccmesaging.chat.agentconsole.label.robotChat": "Dialog with Chatbot",
  "ccmesaging.chat.agentconsole.label.screenshot": "Screenshots",
  "ccmesaging.chat.agentconsole.label.seeMore": "View More",
  "ccmesaging.chat.agentconsole.label.service": "Service",
  "ccmesaging.chat.agentconsole.label.uploadattachment": "Upload Attachment",
  "ccmesaging.chat.agentconsole.label.uploadimage": "Upload Image",
  "ccmesaging.chat.agentconsole.title.release": "Release",
  "ccmesaging.chat.cobrowse.accept": "Accept",
  "ccmesaging.chat.cobrowse.agentMax": "The agent is currently collaborating on the webpage, please try again",
  "ccmesaging.chat.cobrowse.isAcceptWeb": "Are you sure you want to accept the co-browsing invitation?",
  "ccmesaging.chat.cobrowse.isAcceptWebMax": "The maximum number of Co-browsing connections allowed by the current tenant has been exceeded.",
  "ccmesaging.chat.cobrowse.noWebSiteAuth": "The agent does not have the Co-browsing permission.",
  "ccmesaging.chat.cobrowse.refused": "Refuse",
  "ccmesaging.chat.cobrowse.title": "Co-browsing Invitation",
  "ccmesaging.chat.cobrowse.webSite": "Co-browsing URL:",
  "ccmesaging.chat.cobrowse.webSiteButton": "Co-browsing",
  "ccmesaging.chat.cobrowse.webSitePlaceholder": "Enter the sharing URL.",
  "ccmessaging.address.protocol.check": "The network protocol used by the address is not secure. Using this protocol may bring security risks.",
  "ccmessaging.channelconfig.algorithm": "Interconnection Encryption Algorithm",
  "ccmessaging.channelconfig.email.freeTimeText": "Automatic reply during non working hours",
  "ccmessaging.channelconfig.email.freeTimeText.tips": "Reply to the received customer email during non working hours after opening",
  "ccmessaging.channelconfig.email.reply.each.eamil": "Automatically reply to each email",
  "ccmessaging.channelconfig.email.selectAutoReply": "Email Template",
  "ccmessaging.channelconfig.email.selectFreeTimeText": "Non-Working Day Email Reply Template",
  "ccmessaging.channelconfig.email.worktime.autoReply": "Automatic reply during working hours",
  "ccmessaging.channelconfig.email.worktime.autoReply.tips": "Reply to customer email received during working hours after opening",
  "ccmessaging.channelconfig.sms.autoReply": "Auto Reply",
  "ccmessaging.channelconfig.sms.autoReply.tips": "An auto reply is sent after the agent is connected successfully.",
  "ccmessaging.channelconfig.sms.freeTimeText": "Non-Working Day Reply",
  "ccmessaging.channelconfig.sms.selectAutoReply": "SMS Auto Reply Template",
  "ccmessaging.channelconfig.sms.selectFreeTimeText": "Non-Working Day SMS Reply Template",
  "ccmessaging.char.agentstatus.after.callends": "The priority of the agent status configuration takes effect after the call ends is as follows: organization level > tenant level. If this parameter is left blank, the configuration does not take effect and the tenant-level configuration is used.",
  "ccmessaging.char.call.Idle.state": "Idle state",
  "ccmessaging.char.call.work.state": "Wrap-up state",
  "ccmessaging.char.channelconfig.duplicateCodeName": "The channel name already exists.",
  "ccmessaging.chat.agent.organizational.structure": "Organizational Structure",
  "ccmessaging.chat.agent.refreshFail": "Do not repeatedly refresh the page",
  "ccmessaging.chat.agent.transferSessionFail": "Failed to transfer the session",
  "ccmessaging.chat.agentconsole.accesschannel": "Access channel",
  "ccmessaging.chat.agentconsole.agentStatus.error": "A call cannot be initiated because the agent is not online or talking.",
  "ccmessaging.chat.agentconsole.allResults": " All results",
  "ccmessaging.chat.agentconsole.asyncTimeBeyond": " Send failed because the disallowed reply interval has been exceeded.",
  "ccmessaging.chat.agentconsole.beyondLimitNum": "The number of sent messages exceeds the upper limit.",
  "ccmessaging.chat.agentconsole.browserecord": "Browsing History",
  "ccmessaging.chat.agentconsole.chat.close": "The current session ended",
  "ccmessaging.chat.agentconsole.chat.userId": "User ID",
  "ccmessaging.chat.agentconsole.chat.userSettings": "User Settings",
  "ccmessaging.chat.agentconsole.chatPhrase": "Common Phrases",
  "ccmessaging.chat.agentconsole.cobrowse": "Co-browsing",
  "ccmessaging.chat.agentconsole.contract.pdfUploadMaxSizeTip": "Contract size cannot exceed {n}M.",
  "ccmessaging.chat.agentconsole.contract.statisticSigns": "Signature statistics",
  "ccmessaging.chat.agentconsole.contract.statisticTotalSigns": "Statistic",
  "ccmessaging.chat.agentconsole.contract.statisticTotalSigns.failTip": "Failed to collect statistics on the signature items in the contract.",
  "ccmessaging.chat.agentconsole.contract.statisticTotalSigns.noSigns.failTip": "Signature location icon not found.",
  "ccmessaging.chat.agentconsole.contract.statisticTotalSigns.noStaticSigns.failTip": "Please count contracts with signatures first.",
  "ccmessaging.chat.agentconsole.contract.upload.failTip": "Failed to upload the contract.",
  "ccmessaging.chat.agentconsole.contract.upload.noSelectSigns.failTip": "The signature item is not selected for the contract.",
  "ccmessaging.chat.agentconsole.contract.upload.sameContract.failTip": "The subscriber has an unexpired contract.",
  "ccmessaging.chat.agentconsole.current": "Current",
  "ccmessaging.chat.agentconsole.customerinformation": "Customer Info",
  "ccmessaging.chat.agentconsole.defaultOrgName": "Unaffiliated Organization",
  "ccmessaging.chat.agentconsole.document": " Document",
  "ccmessaging.chat.agentconsole.emailNotSupport": "The email channel does not support multimedia messages.",
  "ccmessaging.chat.agentconsole.enter.total.number": "Please enter the total number of signatures",
  "ccmessaging.chat.agentconsole.filterByCondition": "Filter Criteria",
  "ccmessaging.chat.agentconsole.fivegNotSupport": "5G channels do not support multimedia messaging",
  "ccmessaging.chat.agentconsole.hisconversation": "Historical conversation",
  "ccmessaging.chat.agentconsole.history": "History",
  "ccmessaging.chat.agentconsole.image": " Image",
  "ccmessaging.chat.agentconsole.information": "Information",
  "ccmessaging.chat.agentconsole.intention": "intention",
  "ccmessaging.chat.agentconsole.IpAddress": "IP Address",
  "ccmessaging.chat.agentconsole.label.defaultwelcome": "Welcome to the online customer service system!",
  "ccmessaging.chat.agentconsole.label.endsession": "Whether to end the session?",
  "ccmessaging.chat.agentconsole.label.filesFacebookNotsupport": "Facebook messenger currently does not support receiving location information",
  "ccmessaging.chat.agentconsole.label.filesTwitterNotsupport": "Twitter does not support audio,locate,or video longer than 30 seconds!",
  "ccmessaging.chat.agentconsole.label.filesWechatNotsupport": "WeChat does not support audio that lasts longer than 60 seconds.",
  "ccmessaging.chat.agentconsole.label.messageRejected": "The message was rejected by Twitter because it looks like spam,please use synonym or try again later:",
  "ccmessaging.chat.agentconsole.label.NoAudioDuration": "Failed to obtain the audio duration. Please upload it again.",
  "ccmessaging.chat.agentconsole.label.NoVideoDuration": "Failed to obtain the video duration,Please upload again!",
  "ccmessaging.chat.agentconsole.label.ongoingsession": "There is currently no ongoing session!",
  "ccmessaging.chat.agentconsole.label.ongoingwx": "The current channel cannot send location information!",
  "ccmessaging.chat.agentconsole.label.skillNoAgent": "The agent in the skill queue is offline.",
  "ccmessaging.chat.agentconsole.loading": "load more",
  "ccmessaging.chat.agentconsole.locate": " Location",
  "ccmessaging.chat.agentconsole.mailDraft": "Draft Email",
  "ccmessaging.chat.agentconsole.message.notworktime": "The agent is not in workTime.",
  "ccmessaging.chat.agentconsole.messageloading": "loading",
  "ccmessaging.chat.agentconsole.multimedia": "Multimedia",
  "ccmessaging.chat.agentconsole.multimediaid": "Multimedia ID",
  "ccmessaging.chat.agentconsole.multimedianame": "Multimedia Name",
  "ccmessaging.chat.agentconsole.myconversation": "My conversation",
  "ccmessaging.chat.agentconsole.nocontact": "No contacts currently",
  "ccmessaging.chat.agentconsole.noHiscontact": "No historical session",
  "ccmessaging.chat.agentconsole.notSupport": "The channel does not support this type of multimedia message.",
  "ccmessaging.chat.agentconsole.noUserArrive": "No user is available now!",
  "ccmessaging.chat.agentconsole.novoEmail": "New email",
  "ccmessaging.chat.agentconsole.nowconversation": "Current conversation",
  "ccmessaging.chat.agentconsole.only.pdf.supported": "Only PDF files are supported",
  "ccmessaging.chat.agentconsole.photo": "Photo",
  "ccmessaging.chat.agentconsole.promptConetnt.cannot.rollback": "The message cannot be withdrawn",
  "ccmessaging.chat.agentconsole.promptConetnt.ContentLengthExceeds": "Text content longer than the limit is not allowed!",
  "ccmessaging.chat.agentconsole.promptConetnt.ContentNull": "EmptyContent is Not allowed!",
  "ccmessaging.chat.agentconsole.promptConetnt.delectMessageRecord": "You have deleted a record",
  "ccmessaging.chat.agentconsole.promptConetnt.onlyFiveG": "The 5G card messages support only 5G channels!",
  "ccmessaging.chat.agentconsole.promptConetnt.onlyWebCard": "The web card messages support only web channels!",
  "ccmessaging.chat.agentconsole.promptConetnt.operateFail": "Failed to perform operations on messages that are not sent successfully",
  "ccmessaging.chat.agentconsole.promptConetnt.operateFailWithChannel": "Only web messages can be operated",
  "ccmessaging.chat.agentconsole.promptConetnt.operateRollBackFail": "Messages that have been sent for more than two minutes cannot be withdrawn",
  "ccmessaging.chat.agentconsole.promptConetnt.rollBackMessage": "You have withdrawn a message",
  "ccmessaging.chat.agentconsole.promptConetnt.userAlreadyInSession": "Sending failed because the subscriber is in a call.",
  "ccmessaging.chat.agentconsole.promptConetnt.userBusy": "Wait until the agent is idle.",
  "ccmessaging.chat.agentconsole.promptConetnt.userInfoNull": "UserInfomation obtained not correct,session will be disconnected!",
  "ccmessaging.chat.agentconsole.promptConetnt.userIsTalking.saveDraftFailed": "Failed to send the email because the subscriber is in a call. The email fails to be saved as a draft.",
  "ccmessaging.chat.agentconsole.promptConetnt.userIsTalking.saveDraftSuccess": "Failed to send the email because the subscriber is in a call. The email is saved as a draft.",
  "ccmessaging.chat.agentconsole.promptConetnt.userNoArrive": "There is no user connected,or no user has been selected!",
  "ccmessaging.chat.agentconsole.promptConetnt.userOffline": "Current user is Offline!",
  "ccmessaging.chat.agentconsole.queuing": "Queuing",
  "ccmessaging.chat.agentconsole.quoteReply": " Quote this reply",
  "ccmessaging.chat.agentconsole.recommendedCaseType": "Recommended work order category",
  "ccmessaging.chat.agentconsole.replyEmail": "Reply Email",
  "ccmessaging.chat.agentconsole.robotAssistant": " Robot Assistant",
  "ccmessaging.chat.agentconsole.select.contract.uploaded": "Please select the document to be uploaded",
  "ccmessaging.chat.agentconsole.select.mandatory.signature": "Please select a mandatory signature",
  "ccmessaging.chat.agentconsole.selectCaseType": "Selecting a work order category",
  "ccmessaging.chat.agentconsole.send.contract": "Send Contract",
  "ccmessaging.chat.agentconsole.send.repeat": "Do not click repeatedly!",
  "ccmessaging.chat.agentconsole.sendEmail": "Send Email",
  "ccmessaging.chat.agentconsole.sendLocalFile": "Send local files",
  "ccmessaging.chat.agentconsole.sendmessage": "Send Message",
  "ccmessaging.chat.agentconsole.setHandledNumber": "Set Handled Number",
  "ccmessaging.chat.agentconsole.signature.variable": "Signature{n}",
  "ccmessaging.chat.agentconsole.signInCallNum": "Calls after sign-in:",
  "ccmessaging.chat.agentconsole.smsTemplate": "SMS Template",
  "ccmessaging.chat.agentconsole.software": "Software Environment",
  "ccmessaging.chat.agentconsole.spellcheck.correcting.not.finished": "Not all your wrongly written have been corrected. End the check?",
  "ccmessaging.chat.agentconsole.spellcheck.correcting.cancel.confirmation": "The corrected content will be discarded. Are you sure you want to cancel the check?",
  "ccmessaging.chat.agentconsole.spellcheck.correctly.writing": "Correctly Written",
  "ccmessaging.chat.agentconsole.spellcheck.failed": "Spell check failed.",
  "ccmessaging.chat.agentconsole.spellcheck.language": "Language",
  "ccmessaging.chat.agentconsole.spellcheck.next": "Next",
  "ccmessaging.chat.agentconsole.spellcheck.previous": "Previous",
  "ccmessaging.chat.agentconsole.spellcheck.replace": "Replace",
  "ccmessaging.chat.agentconsole.spellcheck.replaceall": "Replace All",
  "ccmessaging.chat.agentconsole.spellcheck.sentence.length.failed": "The length of the text to be checked exceeds 1024.",
  "ccmessaging.chat.agentconsole.spellcheck.sentence.length.failed.front": "The length of the text to be checked exceeds 1024.",
  "ccmessaging.chat.agentconsole.spellcheck.sentence.no.context": "There is no text in the text box. You do not need to check the spelling.",
  "ccmessaging.chat.agentconsole.spellcheck.sentence.no.mistake": "No spelling errors",
  "ccmessaging.chat.agentconsole.spellcheck.title": "Spell Test",
  "ccmessaging.chat.agentconsole.spellcheck.wrongly.writing": "Wrongly Written",
  "ccmessaging.chat.agentconsole.talkavg": " Average call duration (s): ",
  "ccmessaging.chat.agentconsole.talkNum": " Today's call counts: ",
  "ccmessaging.chat.agentconsole.thumbnail": "Thumbnail",
  "ccmessaging.chat.agentconsole.total.number.signatures": "Total number of signatures",
  "ccmessaging.chat.agentconsole.upload": "Upload a file",
  "ccmessaging.chat.agentconsole.uploadfail": "File upload failed",
  "ccmessaging.chat.agentconsole.userInputStatus": "Preview the user input state.",
  "ccmessaging.chat.agentconsole.video": " Video",
  "ccmessaging.chat.agentconsole.voice": " Voice",
  "ccmessaging.chat.agentconsole.waittime": "Waiting time",
  "ccmessaging.chat.async.asyncMsgNum": "Number of offline messages",
  "ccmessaging.chat.async.asyncMsgTrending": "Offline Message Trend",
  "ccmessaging.chat.async.asyncUserNum": "Number of Offline Users",
  "ccmessaging.chat.async.asyncUserTrending": "Offline User Trend",
  "ccmessaging.chat.async.channelSelect": "Channel Filtering",
  "ccmessaging.chat.async.requestError": "Request failed.",
  "ccmessaging.chat.async.statistics": "Offline Message Monitoring",
  "ccmessaging.chat.async.statistics.asynChannelTotal": "Total number of offline channels",
  "ccmessaging.chat.async.statistics.asynMsgTotal": "Total number of offline messages",
  "ccmessaging.chat.async.statistics.asynUserTotal": "Total number of offline users",
  "ccmessaging.chat.async.statistics.updateTime": "Data update time",
  "ccmessaging.chat.call.end.state": "Call End Status",
  "ccmessaging.chat.card": "Card",
  "ccmessaging.chat.edit.card": "Edit Card",
  "ccmessaging.chat.multimedia.selectTemplate": "Select Template",
  "ccmessaging.chat.card.template": "WhatsApp Template",
  "ccmessaging.chat.channelconfig.accessToken": "Access Token",
  "ccmessaging.chat.channelconfig.accessTokenSecret": "Access Token Secret",
  "ccmessaging.chat.channelconfig.accountKeyRepeat": "The accountKey is repeated in the channel of WhatsApp",
  "ccmessaging.chat.channelconfig.add": "New",
  "ccmessaging.chat.channelconfig.addressValidate": "The value must be in the format of sip:Number{'@'}botplatform.rcs.chinamobile.com and must contain less than 100 characters.",
  "ccmessaging.chat.channelconfig.afterhalf": ", can be clicked to remove the browser to intercept)",
  "ccmessaging.chat.channelconfig.agentTimeout": "Customize Timeout Interval for No Agent Reply and Prompt for Reassigning Agent",
  "ccmessaging.chat.channelconfig.agentTimeout.emailTimeOutTips": "Customize Timeout Interval for No Agent Reply",
  "ccmessaging.chat.channelconfig.agentWorkTime": "Agent Work Time",
  "ccmessaging.chat.channelconfig.alphavalidateFailed": "Invalid characters. The value can contain only letters, digits, and underscores (_). The first character must be a letter or underscore.",
  "ccmessaging.chat.channelconfig.apifabric.ak": "APP Key",
  "ccmessaging.chat.channelconfig.apifabric.sk": "APP Secret",
  "ccmessaging.chat.channelconfig.apiKey": "API key",
  "ccmessaging.chat.channelconfig.apiKeySecret": "API Key Secret",
  "ccmessaging.chat.channelconfig.appId": "App Id",
  "ccmessaging.chat.channelconfig.appSecret": "App Secret",
  "ccmessaging.chat.channelconfig.assertionSigningKey": "Assertion Signing Key",
  "ccmessaging.chat.channelconfig.assistantAccessCode": "Assistant Access Code",
  "ccmessaging.chat.channelconfig.assistantSet": "Robot Assistant Configuration",
  "ccmessaging.chat.channelconfig.asyncFlag": "Offline Messages",
  "ccmessaging.chat.channelconfig.asyncTip": "If this function is disabled, existing offline messages will be lost.",
  "ccmessaging.chat.channelconfig.asyncTime": "Offline Message Routing Time Limit",
  "ccmessaging.chat.channelconfig.asyncTime.tip": "If the period after the offline scenario is triggered exceeds this value, offline customer messages are directly stored and no longer routed to agents.",
  "ccmessaging.chat.channelconfig.auth.duplicateAuth": "The official account has been authorized and does not need to be configured again.",
  "ccmessaging.chat.channelconfig.auth.relation.timeout": "The authorization may have expired. Scan the WeChat QR code for reauthorization.",
  "ccmessaging.chat.channelconfig.beforhalf": `You can click the "Try it" button to see the integration effect. (If the URL is correct and an interception flag appears in the top right corner of the browser`,
  "ccmessaging.chat.channelconfig.bsp": "Business Solution Provider",
  "ccmessaging.chat.channelconfig.cert.empty": "The certificate cannot be empty when the push address uses the HTTPS protocol.",
  "ccmessaging.chat.channelconfig.Certification": "Verification Method",
  "ccmessaging.chat.channelconfig.changeLogo": "Change Avatar",
  "ccmessaging.chat.channelconfig.channelID": "Channel ID",
  "ccmessaging.chat.channelconfig.channelSecret": "Channel secret",
  "ccmessaging.chat.channelconfig.chatbotAddress": "Chatbot Address",
  "ccmessaging.chat.channelconfig.checkAgentTimeOutInterval": "The timeout interval for no agent reply cannot be longer than the prompt interval for no reply.",
  "ccmessaging.chat.channelconfig.checkEncodingAESKey": "key length is 43 and can only contain A-Z,a-z,0-9",
  "ccmessaging.chat.channelconfig.checkmessage": `The following special characters are not allowed <>'"/\\`,
  "ccmessaging.chat.channelconfig.checkNickName": `The following special characters are not allowed +~!{'@'}#$%&*()/=+{'{'}{'}'};:'\\",<.>?`,
  "ccmessaging.chat.channelconfig.checkName": "The name cannot be the words system, etc",
  "ccmessaging.chat.channelconfig.checkNumber": "The value is invalid.",
  "ccmessaging.chat.channelconfig.checkSocialChatNum": "Cannot contain decimals",
  "ccmessaging.chat.channelconfig.checkWhatsAppBaseUrl": "The URL is invalid!",
  "ccmessaging.chat.channelconfig.checkWhatsAppPhoneNum": "Invalid mobile number",
  "ccmessaging.chat.channelconfig.clicktocall.empty": "The skill queue or IVR access code cannot be empty!",
  "ccmessaging.chat.channelconfig.clickToCallSkillList": "Click-to-Call Skill Queue",
  "ccmessaging.chat.channelconfig.close": "close",
  "ccmessaging.chat.channelconfig.codeName": "Channel access code.",
  "ccmessaging.chat.channelconfig.defaultReply": "Default reply",
  "ccmessaging.chat.channelconfig.delete": "Delete",
  "ccmessaging.chat.channelconfig.delete.exist.asyncMsg": "Failed to delete the [{n}] channel. Try again after offline messages are assigned.",
  "ccmessaging.chat.channelconfig.delete.exist.asyncUser": "Failed to delete the [{n}] channel. Try again after offline users are assigned.",
  "ccmessaging.chat.channelconfig.delete.exist.session": "Failed to delete the [{n}] channel. Try again after the session ends.",
  "ccmessaging.chat.channelconfig.edit": "Operation",
  "ccmessaging.chat.channelconfig.email.bccLimit": "Max. Bcc Recipients",
  "ccmessaging.chat.channelconfig.email.ccLimit": "Max. Cc Recipients",
  "ccmessaging.chat.channelconfig.email.number": "Email Address Quantity Configuration",
  "ccmessaging.chat.channelconfig.email.personal": "Reply with Personal Email",
  "ccmessaging.chat.channelconfig.email.placeholder": "1 - 100, optional",
  "ccmessaging.chat.channelconfig.email.range": "Enter a positive integer ranging from 1 to 100",
  "ccmessaging.chat.channelconfig.email.address.limit": "Numbers",
  "ccmessaging.chat.channelconfig.email.receiverLimit": "Max. Recipients",
  "ccmessaging.chat.channelconfig.email.selectSignature": "Email Signature",
  "ccmessaging.chat.channelconfig.email.signature": "Email Signature",
  "ccmessaging.chat.channelconfig.email.signature.desc": "Description",
  "ccmessaging.chat.channelconfig.email.signature.name": "Email Signature Name",
  "ccmessaging.chat.channelconfig.email.totalEmailNumber": "The total number of recipient, carbon copy recipient, and secret recipient email addresses cannot exceed 100",
  "ccmessaging.chat.channelconfig.emailRepeate": "The email receiving gateway has been used. Go to the message routing configuration page to view the email receiving gateway.",
  "ccmessaging.chat.channelconfig.endchat": "Whether to end the session?",
  "ccmessaging.chat.channelconfig.endOfSession": "Session End Due to No Customer Reply",
  "ccmessaging.chat.channelconfig.envName": "Dev environment label",
  "ccmessaging.chat.channelconfig.envNameValidate": "The value must be a string of less than or equal to 24 characters and can contain only letters and digits.",
  "ccmessaging.chat.channelconfig.facebookBackground": "Facebook",
  "ccmessaging.chat.channelconfig.fiveGBackground": "5G Message CSP",
  "ccmessaging.chat.channelconfig.gender": "Gender",
  "ccmessaging.chat.channelconfig.guestTimeout": "Customized timeout period for no customer reply and session end message. If this function is disabled, the default timeout period is 20 minutes.",
  "ccmessaging.chat.channelconfig.guestTimeout.timeOutTips": "Customize Guest Non-reply Timeout Time. If Not Enabled, The Default Timeout Is {n} Minutes.",
  "ccmessaging.chat.channelconfig.hostingMethod": "Hosting Method",
  "ccmessaging.chat.channelconfig.hour": "Hour",
  "ccmessaging.chat.channelconfig.identityCertificate": "Identity certificate",
  "ccmessaging.chat.channelconfig.importAvatarSize": "The size of the avatar image exceeds 6 MB.",
  "ccmessaging.chat.channelconfig.importAvatartype": "Incorrect avatar image type.",
  "ccmessaging.chat.channelconfig.infobipPassword": "Password",
  "ccmessaging.chat.channelconfig.infobipUserName": "UserName",
  "ccmessaging.chat.channelconfig.infoConfiguration": "Info Configuration",
  "ccmessaging.chat.channelconfig.keyEscrow": "Key",
  "ccmessaging.chat.channelconfig.KeyValidate": "The value can contain only letters, digits, and the following special characters:_~!{'@'}#$%^&*- Any composition",
  "ccmessaging.chat.channelconfig.whatsappNameValidate": "The value can contain only letters, digits, and the following special characters:_~!{'@'}#$%^&*-. Any composition",
  "ccmessaging.chat.channelconfig.lastagent.time": "Time Range",
  "ccmessaging.chat.channelconfig.lastModifyTime": "Last Modify At",
  "ccmessaging.chat.channelconfig.logo": "Avatar",
  "ccmessaging.chat.channelconfig.messagePushUrl.not.configure.trustList": "The address is not trustlisted. Please contact the system administrator.",
  "ccmessaging.chat.channelconfig.hrefUrl.not.configure.trustList": "The hyperlink address is not trustlisted. Please contact the system administrator.",
  "ccmessaging.chat.channelconfig.minute": "Minute",
  "ccmessaging.chat.channelconfig.name": "Name",
  "ccmessaging.chat.channelconfig.nochatonline": "There is currently no ongoing session!",
  "ccmessaging.chat.channelconfig.noLimit": "Not specified",
  "ccmessaging.chat.channelconfig.NonworkingTimeReminder": "Non-Working Time Notification",
  "ccmessaging.chat.channelconfig.nonWorkTimeDay": "Non-workday",
  "ccmessaging.chat.channelconfig.noReplyIntervalTime": "Called Customer Reply Timeout Period",
  "ccmessaging.chat.channelconfig.noReplyIntervalTime.tip": "In the outbound call scenario, if a customer does not reply within this period, the session automatically ends.",
  "ccmessaging.chat.channelconfig.noReplyIntervalTimeValidate": "The Interval for Customer No Reply cannot be greater than the Interval for Customer No Reply Notification.",
  "ccmessaging.chat.channelconfig.noReplyTime": "Agent Outbound Call Time Limit",
  "ccmessaging.chat.channelconfig.noReplyTime.tip": "If the period after the last time when a customer contacts an agent exceeds this value, the agent cannot make an outbound call to the customer.",
  "ccmessaging.chat.channelconfig.offBeginTime": "From the start time to the current time",
  "ccmessaging.chat.channelconfig.offLastAgentType": "Last Agent Type",
  "ccmessaging.chat.channelconfig.offTimeRange": "Within the time range",
  "ccmessaging.chat.channelconfig.offToday": "On the current day",
  "ccmessaging.chat.channelconfig.onlyContainSubType": "Display Only Skill Queues of the Channel Type When an Agent Transfers a Session.",
  "ccmessaging.chat.channelconfig.orgConfiguration": "OU Configuration",
  "ccmessaging.chat.channelconfig.pageId": "Page ID",
  "ccmessaging.chat.channelconfig.personalSetting": "Personalized Settings",
  "ccmessaging.chat.channelconfig.phoneNumber": "Phone Number",
  "ccmessaging.chat.channelconfig.pleaseSelect": "Please select...",
  "ccmessaging.chat.channelconfig.PreferredLastAgent": "Last Agent Mode",
  "ccmessaging.chat.channelconfig.Previous": "Previous",
  "ccmessaging.chat.channelconfig.privateKeyValidate": "The value must be in JSON format. The key value in JSON can contain only letters, digits, and the following special characters: _~!{'@'}#$%^&*- Any composition",
  "ccmessaging.chat.channelconfig.publicConfiguration": "Common Configuration",
  "ccmessaging.chat.channelconfig.pushMessageUrlValid": "Failed to save the channel configuration. Verify that the message push address or certificate is valid.",
  "ccmessaging.chat.channelconfig.qryaddressconfigfailed": "Failed to query the chatbotAddress verification rule of the 5G channel. The verification rule is not configured.",
  "ccmessaging.chat.channelconfig.queryType": "Selection Mode",
  "ccmessaging.chat.channelconfig.queueReminder": "Queue reminder",
  "ccmessaging.chat.channelconfig.queueReminderContent": "Queue reminder content",
  "ccmessaging.chat.channelconfig.queueReminderInterval": "Queue reminder interval",
  "ccmessaging.chat.channelconfig.reCreateScenario": "Generate",
  "ccmessaging.chat.channelconfig.refreshAccessToken": " Refresh accessToken failed.",
  "ccmessaging.chat.channelconfig.robotAccessCode": "Chatbot Access Code",
  "ccmessaging.chat.channelconfig.robotApplicationKey": "Chatbot Application Key",
  "ccmessaging.chat.channelconfig.robotSet": "Chatbot Configuration",
  "ccmessaging.chat.channelconfig.routeType": "Routing Mode",
  "ccmessaging.chat.channelconfig.routeTypeSkill": "Skill Queue Routing",
  "ccmessaging.chat.channelconfig.saveAndToNext": "Save and proceed to the next step",
  "ccmessaging.chat.channelconfig.savechannelconfigfailed": "Save channel configuration failed!",
  "ccmessaging.chat.channelconfig.scanCodeHosting": "QR code",
  "ccmessaging.chat.channelconfig.scenario.status.existent": "scenario key:Yes",
  "ccmessaging.chat.channelconfig.scenario.status.noExistent": "scenario key:NO",
  "ccmessaging.chat.channelconfig.select.algorithm": "Select an Encryption Algorithm",
  "ccmessaging.chat.channelconfig.select.algorithm.configtips": "Configure the supported encryption algorithm based on the requirements of the channel open platform.",
  "ccmessaging.chat.channelconfig.select.algorithm.safetips": "This algorithm is not strong enough. If the platform supports this algorithm, you are advised to use a higher encryption algorithm.",
  "ccmessaging.chat.channelconfig.selectAccessChannel": "Select access channel",
  "ccmessaging.chat.channelconfig.selectConfigurationMethod": "Choose configuration method",
  "ccmessaging.chat.channelconfig.selectRobot": "Choosing a robot",
  "ccmessaging.chat.channelconfig.selectRobotAssistant": "Select robot assistant",
  "ccmessaging.chat.channelconfig.sessionTransferConfig": "Session Transfer",
  "ccmessaging.chat.channelconfig.silentAgentDelete": "Are you sure you want to delete the selected voice mute agent configuration?",
  "ccmessaging.chat.channelconfig.silentAgentSelect": "Please select the voice mute agent configuration to be deleted.",
  "ccmessaging.chat.channelconfig.silentAgentSkillList": "Silent Agent Skill Queue",
  "ccmessaging.chat.channelconfig.skill": "Skilllist (accesscode+extcode)",
  "ccmessaging.chat.channelconfig.skillList": "Skill Queue",
  "ccmessaging.chat.channelconfig.smsRepeate": "The SMS gateway has been used. Go to the message route configuration page to view the SMS gateway.",
  "ccmessaging.chat.channelconfig.startTime": "Start time",
  "ccmessaging.chat.channelconfig.sub": "Copy and paste the following code into your website label, save and publish, you can complete the quick access of Huawei ServiceCloud. After the release, the Huawei Service Cloud portal will appear in the lower right corner of your website. Click to go to the customer service workbench. Real-time conversation.",
  "ccmessaging.chat.channelconfig.submit": "sumbit",
  "ccmessaging.chat.channelconfig.submitVerification": "Integration instructions",
  "ccmessaging.chat.channelconfig.successfulAccess": "Successful access",
  "ccmessaging.chat.channelconfig.thirdPartySecretKey": "Third-party authentication key",
  "ccmessaging.chat.channelconfig.time": "Time Range (min)",
  "ccmessaging.chat.channelconfig.timeoutReply": "Timeout reply",
  "ccmessaging.chat.channelconfig.timerange": "Time range",
  "ccmessaging.chat.channelconfig.timeZone": "Time Zone",
  "ccmessaging.chat.channelconfig.today": "Current day",
  "ccmessaging.chat.channelconfig.triggerText": "Offline Message Prompt",
  "ccmessaging.chat.channelconfig.trustCertificate": "Trust certificate",
  "ccmessaging.chat.channelconfig.trybutton": "Try",
  "ccmessaging.chat.channelconfig.turnAI": "Connect to Chatbot",
  "ccmessaging.chat.channelconfig.turnArtificial": "Keyword for Transfer to Agent",
  "ccmessaging.chat.channelconfig.turnArtificial.tip": "Separated by Chinese or English semicolons",
  "ccmessaging.chat.channelconfig.turnAssistant": "Connect to Assistant",
  "ccmessaging.chat.channelconfig.turnManualPrompt": "Prompt for transfer to agent",
  "ccmessaging.chat.channelconfig.twitterBackground": "Twitter Server",
  "ccmessaging.chat.channelconfig.twittertips": "You can access the following websites. Click Submit for Twitter authentication.",
  "ccmessaging.chat.channelconfig.update": "Update",
  "ccmessaging.chat.channelconfig.updatecontactfailed": "Update contact failed!",
  "ccmessaging.chat.channelconfig.voiceAccess": "Called IVR",
  "ccmessaging.chat.channelconfig.voiceAgentSkill": "Agent Skill Queue",
  "ccmessaging.chat.channelconfig.webhookConfirmPassword": "Webhook Confirm Password",
  "ccmessaging.chat.channelconfig.webHookException": "Failed to get valid time for webHook password",
  "ccmessaging.chat.channelconfig.webhookPassword": "Webhook Password",
  "ccmessaging.chat.channelconfig.webhookPassword.newPassword": "New Webhook password",
  "ccmessaging.chat.channelconfig.webhookPassword.noValidPassword": "No password available",
  "ccmessaging.chat.channelconfig.webhookPassword.oldPassword": "Lock period Webhook password",
  "ccmessaging.chat.channelconfig.webhookPassword.validPeriod": "Valid until:",
  "ccmessaging.chat.channelconfig.webhookPassword.validPeriod.fifthDayTip": "Valid for less than 15 days",
  "ccmessaging.chat.channelconfig.webhookPassword.validPeriod.oneDayTip": "Valid for less than one day. please modify!",
  "ccmessaging.chat.channelconfig.wechat.encryption.tip": "Disabling message encryption may cause security risks. The setting must be the consistent with that on the WeChat Official Accounts Platform.",
  "ccmessaging.chat.channelconfig.wechat.qr.label": "Scan QR Code",
  "ccmessaging.chat.channelconfig.wechat.qr.label2": "Reauthorize",
  "ccmessaging.chat.channelconfig.wechat.qr.label3": "Authorization Success",
  "ccmessaging.chat.channelconfig.weChatBackground": "WeChat Server",
  "ccmessaging.chat.channelconfig.weChatMessageEncryptionSwitch": "WeChat message encryption switch",
  "ccmessaging.chat.channelconfig.welcomeMessage": "Greeting",
  "ccmessaging.chat.channelconfig.whatsAppBackground": "WhatsApp Server",
  "ccmessaging.chat.channelconfig.workTimeDay": "Workday",
  "ccmessaging.chat.channelconfig.worktimeerror": "Please enter the normal time period!",
  "ccmessaging.chat.chatcard.audio": "AUDIO",
  "ccmessaging.chat.chatcard.button.addtabs": "Add Card Options",
  "ccmessaging.chat.chatcard.cancel": "Cancel",
  "ccmessaging.chat.chatcard.cardId": "Template ID",
  "ccmessaging.chat.chatcard.cardName": "name",
  "ccmessaging.chat.chatcard.cardNamePlaceholder": "Enter  card template name.",
  "ccmessaging.chat.chatcard.cardTitle": "title",
  "ccmessaging.chat.chatcard.cardTitlePlaceholder": "Enter card title.",
  "ccmessaging.chat.chatcard.create": "Save",
  "ccmessaging.chat.chatcard.delete": "Delete",
  "ccmessaging.chat.chatcard.deleteFail": "Delete Fail",
  "ccmessaging.chat.chatcard.deleteLimit": "The template has been referenced and cannot be deleted.",
  "ccmessaging.chat.chatcard.deleteSuccess": "Delete Success",
  "ccmessaging.chat.chatcard.deleteTips": "Parameter verification failed.",
  "ccmessaging.chat.chatcard.deleteWhatsAppTemplate.Fail": "Deletion failed because the template is already referenced by a WhatsApp template message.",
  "ccmessaging.chat.chatcard.description": "Description",
  "ccmessaging.chat.chatcard.descriptionPlaceholder": "Enter card description.",
  "ccmessaging.chat.chatcard.details": "Card Details",
  "ccmessaging.chat.chatcard.dial": "dial",
  "ccmessaging.chat.chatcard.down": "Move Down",
  "ccmessaging.chat.chatcard.edit": "Edit",
  "ccmessaging.chat.chatcard.fail": "Failure",
  "ccmessaging.chat.chatcard.failTip": "Adding failed The number of added options reaches the maximum.",
  "ccmessaging.chat.chatcard.folded": "folded",
  "ccmessaging.chat.chatcard.id": "Enter a template ID.",
  "ccmessaging.chat.chatcard.image": " IMAGE",
  "ccmessaging.chat.chatcard.input": "Enter a template name.",
  "ccmessaging.chat.chatcard.invalidUrl": "The URL does not start with http://,https://",
  "ccmessaging.chat.chatcard.linking": "linking",
  "ccmessaging.chat.chatcard.mediaFileType": " Media file type",
  "ccmessaging.chat.chatcard.mediaHeight": "Media height",
  "ccmessaging.chat.chatcard.mediaType": "Media Type",
  "ccmessaging.chat.chatcard.mediumHeight": " Medium Height",
  "ccmessaging.chat.chatcard.messageTemplateCreate": "Creating a Card Template",
  "ccmessaging.chat.chatcard.messageTemplateDelete": "Deleting a template",
  "ccmessaging.chat.chatcard.messageTemplateModify": "Modifying a template",
  "ccmessaging.chat.chatcard.multiMediaId": "Multimedia Resource",
  "ccmessaging.chat.chatcard.multimediaid": "Multimedia ID",
  "ccmessaging.chat.chatcard.multimedianame": "Multimedia File Name",
  "ccmessaging.chat.chatcard.name": "Enter name.",
  "ccmessaging.chat.chatcard.NoneTitle": "untitled",
  "ccmessaging.chat.chatcard.operate": "Operation",
  "ccmessaging.chat.chatcard.queryTemplateFail": "Query Template Fail",
  "ccmessaging.chat.chatcard.replay": "replay",
  "ccmessaging.chat.chatcard.saveFail": "Save Fail",
  "ccmessaging.chat.chatcard.saveSuccess": "Save Success",
  "ccmessaging.chat.chatcard.selectMultiMediaId": "Select the multimedia resource.",
  "ccmessaging.chat.chatcard.selectMultiMediaTips": "Please select multimedia resources.",
  "ccmessaging.chat.chatcard.closeDialog": "Are you sure to close this dialog?",
  "ccmessaging.chat.chatcard.shortHeight": " Short Height",
  "ccmessaging.chat.chatcard.strcontentTip": " HTTP requests have security risks.",
  "ccmessaging.chat.chatcard.success": "Success",
  "ccmessaging.chat.chatcard.tabs": "Card Options",
  "ccmessaging.chat.chatcard.tabs.displaytext": "Card display text",
  "ccmessaging.chat.chatcard.tabs.selectcontent": "select content",
  "ccmessaging.chat.chatcard.tabs.selectType": "Option Type",
  "ccmessaging.chat.chatcard.tabsname": "Card Option Name",
  "ccmessaging.chat.chatcard.tallHeight": " Tall Height",
  "ccmessaging.chat.chatcard.up": "Move Up",
  "ccmessaging.chat.chatcard.video": "VIDEO",
  "ccmessaging.chat.chatcard.viewTips": "The card template has been deleted and cannot be previewed.",
  "ccmessaging.chat.chatcard.whatsappTemplateCreate": "Create WhatsApp Template",
  "ccmessaging.chat.chatcard.willdeletechatcard": "Are you sure you want to delete this card?",
  "ccmessaging.chat.chatcard.willdeletechatTemplate": "Are you sure you want to delete the WhatsApp template?",
  "ccmessaging.chat.chatcard.nameExist": "The name already exists. Please enter another one.",
  "ccmessaging.chat.chatconfig.add": "New",
  "ccmessaging.chat.chatconfig.channelAccessCode": "Channel Access Code",
  "ccmessaging.chat.chatconfig.channelOccupied": "Channel is already occupied.",
  "ccmessaging.chat.chatconfig.channelType": "Channel Type",
  "ccmessaging.chat.chatconfig.configurationId": "Configuration ID",
  "ccmessaging.chat.chatconfig.delete": "Delete",
  "ccmessaging.chat.chatconfig.deletechannelAccessCode": "Delete Channel Configuration",
  "ccmessaging.chat.chatconfig.deletefail": "Deletion failed.",
  "ccmessaging.chat.chatconfig.deletesuccess": "Deleted successfully.",
  "ccmessaging.chat.chatconfig.idisnull": "The ID is empty.",
  "ccmessaging.chat.chatconfig.inquire": "Search",
  "ccmessaging.chat.chatconfig.modify": "Modify",
  "ccmessaging.chat.chatconfig.operate": "Operation",
  "ccmessaging.chat.chatconfig.refresh": "Refresh",
  "ccmessaging.chat.chatconfig.reset": "Reset",
  "ccmessaging.chat.chatconfig.rollBack": "Retract",
  "ccmessaging.chat.chatconfig.selectchannelAccessCode": "Select channel configuration.",
  "ccmessaging.chat.chatconfig.skillId": "Skill Queue",
  "ccmessaging.chat.chatconfig.willdeletechannelAccessCode": "Are you sure you want to delete the selected channel configuration?",
  "ccmessaging.chat.chatemail.cancelhandled": "Cancellation Processed",
  "ccmessaging.chat.chatemail.delete": "Delete",
  "ccmessaging.chat.chatemail.details": "View",
  "ccmessaging.chat.chatemail.forwarded": "Forwarded",
  "ccmessaging.chat.chatemail.forwardedTime": "Forwarded at",
  "ccmessaging.chat.chatemail.handled": "Processed",
  "ccmessaging.chat.chatemail.maxNumberImages": "The number of uploaded local images has reached the maximum of 20.",
  "ccmessaging.chat.chatemail.saveDraft": "Save as Draft",
  "ccmessaging.chat.chatemail.sender": "Sender:",
  "ccmessaging.chat.chatholiday.calendar.preview": "Calendar preview",
  "ccmessaging.chat.chatholiday.checkHolidaySize": "There are too many entries, Please delete the extra entries first",
  "ccmessaging.chat.chatholiday.date": "Date",
  "ccmessaging.chat.chatholiday.dateConfiguration": "Date configuration",
  "ccmessaging.chat.chatholiday.delete": "delete",
  "ccmessaging.chat.chatholiday.description": "Description",
  "ccmessaging.chat.chatholiday.designation.alert": "The end date must be later than the start date",
  "ccmessaging.chat.chatholiday.designation.cancel": "Cancel",
  "ccmessaging.chat.chatholiday.designation.complete": "Complete",
  "ccmessaging.chat.chatholiday.designation.days": "Multiple days",
  "ccmessaging.chat.chatholiday.designation.end": "End date",
  "ccmessaging.chat.chatholiday.designation.norepeating": "Not repeated",
  "ccmessaging.chat.chatholiday.designation.oneday": "1 Day",
  "ccmessaging.chat.chatholiday.designation.repeating": "Repeated every year",
  "ccmessaging.chat.chatholiday.designation.selectrule": "The selected date cannot be the same as the selected date",
  "ccmessaging.chat.chatholiday.designation.show": "Every year",
  "ccmessaging.chat.chatholiday.designation.start": "Start date",
  "ccmessaging.chat.chatholiday.designation.type": "Date type",
  "ccmessaging.chat.chatholiday.friday": "Friday",
  "ccmessaging.chat.chatholiday.holidays.explanation": "You can specify certain days as non-workdays, for example, September 13, 2019 (Friday, Chinese Mid-autumn Festival).",
  "ccmessaging.chat.chatholiday.isDelete.otherdays": "Are you sure you want to delete this Exception date?",
  "ccmessaging.chat.chatholiday.isDelete.specified": "Are you sure you want to delete this Specified date?",
  "ccmessaging.chat.chatholiday.monday": "Monday",
  "ccmessaging.chat.chatholiday.new": "New",
  "ccmessaging.chat.chatholiday.nonWorkbench": "Non-workday configuration",
  "ccmessaging.chat.chatholiday.noworking": "Non-workday",
  "ccmessaging.chat.chatholiday.operate": "operate",
  "ccmessaging.chat.chatholiday.other.prompt": "The selected date is the same as an existing date",
  "ccmessaging.chat.chatholiday.otherdays.exception": "Exception date",
  "ccmessaging.chat.chatholiday.otherdays.explanation": "You can select certain days as workdays. The maximum time range is one year. For example:you can select July 13, 2019 (Saturday, temporary overtime required) as a workday.",
  "ccmessaging.chat.chatholiday.repeat": "Repeating rules",
  "ccmessaging.chat.chatholiday.saturday": "Saturday",
  "ccmessaging.chat.chatholiday.savechatholidayfailed": "Saving holiday configuration failed",
  "ccmessaging.chat.chatholiday.specified.date": "Specified date",
  "ccmessaging.chat.chatholiday.sunday": "Sunday",
  "ccmessaging.chat.chatholiday.thursday": "Thursday",
  "ccmessaging.chat.chatholiday.tuesday": "Tuesday",
  "ccmessaging.chat.chatholiday.wednesday": "Wednesday",
  "ccmessaging.chat.chatholiday.weekend.explanation": "You can set certain days in a week as non-workdays,for example, Saturday and Sunday.",
  "ccmessaging.chat.chatholiday.weekly": "Weekly",
  "ccmessaging.chat.chatholiday.workbench": "Workbench configuration",
  "ccmessaging.chat.chatholiday.working.day": "Workday",
  "ccmessaging.chat.chatholiday.tip.choosedate": "Please select a date",
  "ccmessaging.chat.chatholiday.tip.descinvalid": "The description cannot exceed 20 characters",
  "ccmessaging.chat.chatmanage.agentIntervalTime": "Agent Reply Timeout Period",
  "ccmessaging.chat.chatmanage.agentSessionEndText": "Prompt for Agent Reassignment",
  "ccmessaging.chat.chatmanage.autoEndSession": "If the guest does not reply within the specified time, the dialog is terminated automatically.",
  "ccmessaging.chat.chatmanage.autoEndSessionMorethan": "The description of the interval in which the customer has no reply cannot exceed 100 characters.",
  "ccmessaging.chat.chatmanage.autoEndSessionnotnull": "Customer non-response interval description cannot be empty.",
  "ccmessaging.chat.chatmanage.autoEndSessionNum": "The customer does not reply to the notification interval must be a number.",
  "ccmessaging.chat.chatmanage.autoEndSessionRange": "Customer No Reply Reminder Interval Number range must be between 0 and 60",
  "ccmessaging.chat.chatmanage.cancel": "Cancel",
  "ccmessaging.chat.chatmanage.edit": "Edit",
  "ccmessaging.chat.chatmanage.endTime": "End Time",
  "ccmessaging.chat.chatmanage.endTimeNum": "The end time must be a number.",
  "ccmessaging.chat.chatmanage.endTimeRange": "The end time number must range from 0 to 24.",
  "ccmessaging.chat.chatmanage.fail": "Failure",
  "ccmessaging.chat.chatmanage.freeTimeText": "Non-Working Time Notification",
  "ccmessaging.chat.chatmanage.freeTimeTextMorethan": "The description of the non-working time notification cannot contain more than 100 characters.",
  "ccmessaging.chat.chatmanage.freeTimeTextnotnull": "The description of the non-working time reminder cannot be empty.",
  "ccmessaging.chat.chatmanage.intervalTime": "Customer Reply Timeout Period",
  "ccmessaging.chat.chatmanage.save": "Save",
  "ccmessaging.chat.chatmanage.savefailed": "Saving failed.",
  "ccmessaging.chat.chatmanage.savesuccess": "Saved successfully.",
  "ccmessaging.chat.chatmanage.sessionEndText": "Conclusion",
  "ccmessaging.chat.chatmanage.sessionEndTextMorethan": "The dialog conclusion description cannot contain more than 100 characters.",
  "ccmessaging.chat.chatmanage.sessionEndTextnotnull": "The session end description cannot be empty.",
  "ccmessaging.chat.chatmanage.specialCharacters": "The value contains special characters. Please modify it.",
  "ccmessaging.chat.chatmanage.startTime": "Start Time",
  "ccmessaging.chat.chatmanage.startTimeNum": "The start time must be a number.",
  "ccmessaging.chat.chatmanage.startTimeRange": "Start time number must range from 0 to 24",
  "ccmessaging.chat.chatmanage.success": "Success",
  "ccmessaging.chat.chatmanage.useRobot": "Connect to Chatbot",
  "ccmessaging.chat.chatmanage.workTime": "Agent Working Time (in 24-hour format)",
  "ccmessaging.chat.chatmanage.workTimeMorethan": "The working time description cannot contain more than 100 characters.",
  "ccmessaging.chat.chatpersonalization.add": "Add",
  "ccmessaging.chat.chatpersonalization.avatarerror": "Select an image.!",
  "ccmessaging.chat.chatpersonalization.cancel": "Cancel",
  "ccmessaging.chat.chatpersonalization.changeAvatar": "Change Avatar",
  "ccmessaging.chat.chatpersonalization.concurrentAgentsNumber": "Number of multimedia clients concurrently served by agents",
  "ccmessaging.chat.chatpersonalization.concurrentNumberPriority": "The priority of configuring the number of multimedia clients concurrently served by agents is as follows: agent level>organization level>tenant level. The value 0 indicates that the configuration of this level does not take effect and the configuration of other levels is used.",
  "ccmessaging.chat.chatpersonalization.delete": "Delete",
  "ccmessaging.chat.chatpersonalization.edit": "Edit",
  "ccmessaging.chat.chatpersonalization.hour": "Hour",
  "ccmessaging.chat.chatpersonalization.MaximumConcurrent": "Maximum concurrent service customers",
  "ccmessaging.chat.chatpersonalization.nickname": "Nickname",
  "ccmessaging.chat.chatpersonalization.operating": "Operation",
  "ccmessaging.chat.chatpersonalization.prompt": "Prompt",
  "ccmessaging.chat.chatpersonalization.save": "Save",
  "ccmessaging.chat.chatpersonalization.savefailed": "Saving failed.",
  "ccmessaging.chat.chatpersonalization.saveSuccess": "Saved successfully.",
  "ccmessaging.chat.chatpersonalization.specialStrValidateFailed": "The value cannot contain special characters.",
  "ccmessaging.chat.chatpersonalization.tips1": "The submitted configuration was not verified!",
  "ccmessaging.chat.chatpersonalization.welcome": "Greetings",
  "ccmessaging.chat.chatpersonalization.welcomeLimit": "A maximum of four personalized greetings can be configured.",
  "ccmessaging.chat.chatpersonalization.welcomeText": "Personalized Greetings",
  "ccmessaging.chat.chatpersonalization.welcometime": "Time range",
  "ccmessaging.chat.chatpersonalization.welcometimeerror": "Enter a normal time segment!",
  "ccmessaging.chat.chatpersonalization.welcometimeerror.nolist": "Please set a personalized greeting!",
  "ccmessaging.chat.chatpersonalization.welcometimeerror.startGreaterThanEnd": "The start time of personalized greeting cannot be greater than or equal to the end time!",
  "ccmessaging.chat.chatpersonalization.welcometimeerror.timeOverlap": "The time period of personalized greetings cannot overlap!",
  "ccmessaging.chat.chinese.separator": ":",
  "ccmessaging.chat.clickCallClient.denial": " The link has expired. If necessary contact the agent again to send the link.",
  "ccmessaging.chat.close": "seconds after close the page automatically",
  "ccmessaging.chat.connnect.offlineuser.failed": "Failed to initiate an offline call as an agent.",
  "ccmessaging.chat.connnect.offlineuser.userId.beyondMaxLength": "UserId is over 128",
  "ccmessaging.chat.contact.callin": "Call Inbound",
  "ccmessaging.chat.contact.callinandcallout": "Call Inbound and Outbound",
  "ccmessaging.chat.contact.callout": "Call Outbound",
  "ccmessaging.chat.email.AgentAccountName": "Agent Account",
  "ccmessaging.chat.email.agentNumber": "Agent ID",
  "ccmessaging.chat.email.autosave": "Your edits have been automatically saved to a draft.",
  "ccmessaging.chat.email.button.advancedSearch": "Advanced Search",
  "ccmessaging.chat.email.button.batchAssignment": "Batch Assignment",
  "ccmessaging.chat.email.button.bulkMove": "Bulk Move",
  "ccmessaging.chat.email.card.reply": "Reply",
  "ccmessaging.chat.email.channel.attendeeError": "The number of Cc recipients exceeds the limit.",
  "ccmessaging.chat.email.channel.BccSenderError": "The number of BCC recipients exceeds the limit.",
  "ccmessaging.chat.email.channel.ccandbcc.containchannelemail": "The Cc and Bcc recipients cannot be the public email addresses of channel partners.",
  "ccmessaging.chat.email.channel.confdeletedraft": "Are you sure you want to delete the draft email?",
  "ccmessaging.chat.email.channel.confdmodifydraft": "Are you sure you have processed the email?",
  "ccmessaging.chat.email.channel.deletedraft": "Confirm",
  "ccmessaging.chat.email.channel.deletedraftfail": "Failed to delete the draft email.",
  "ccmessaging.chat.email.channel.deletedraftsuccess": "The draft email is deleted successfully.",
  "ccmessaging.chat.email.channel.draftOverMax": "The number of email drafts exceeds the maximum.",
  "ccmessaging.chat.email.channel.emailaddressinvalid": "The email address is invalid.",
  "ccmessaging.chat.email.channel.modifydraft": "Confirm",
  "ccmessaging.chat.email.channel.modifydraftfail": "Modification failed.",
  "ccmessaging.chat.email.channel.modifydraftsuccess": "Modification successful.",
  "ccmessaging.chat.email.channel.paramisempty": "The parameter cannot be empty.",
  "ccmessaging.chat.email.channel.paramiserror": "The parameter is invalid.",
  "ccmessaging.chat.email.channel.receiveGateway": "Email receiving gateway",
  "ccmessaging.chat.email.channel.receivererror": "The recipient does not contain the selected user.",
  "ccmessaging.chat.email.channel.savedraftfail": "Failed to save the email as a draft.",
  "ccmessaging.chat.email.channel.savedraftsuccess": "The email is saved as a draft successfully.",
  "ccmessaging.chat.email.channel.sendGateway": "Email sending gateway",
  "ccmessaging.chat.email.channel.title.reply": "Reply:",
  "ccmessaging.chat.email.check.containGatewayEmail": "Do not send emails to channels or receive email addresses.",
  "ccmessaging.chat.email.check.contentOverMax": "The message body size exceeds the limit.",
  "ccmessaging.chat.email.check.localImageNumberOverMax": "The number of local images uploaded in the email body exceeds the maximum.",
  "ccmessaging.chat.email.check.localImageOverMax": "The size of local images uploaded in the email body exceeds the maximum.",
  "ccmessaging.chat.email.check.localImageType": "Image format not supported.",
  "ccmessaging.chat.email.check.uploadATTACHMENTPermissionAndFeature": "No permission to upload attachments, or this function is not enabled.",
  "ccmessaging.chat.email.check.uploadImagePermissionAndFeature": "No permission to upload local images, or this function is not enabled.",
  "ccmessaging.chat.email.forward": "Forward",
  "ccmessaging.chat.email.forward.prefix": "Forward:",
  "ccmessaging.chat.email.localImage.confirm": "Confirm",
  "ccmessaging.chat.email.localImage.height": "Height",
  "ccmessaging.chat.email.localImage.lable": "File",
  "ccmessaging.chat.email.localImage.width": "Width",
  "ccmessaging.chat.email.param.empty": "The email title or content cannot be empty.",
  "ccmessaging.chat.email.reply": "Reply",
  "ccmessaging.chat.email.reply.separator": ":",
  "ccmessaging.chat.email.tips.addFailDuplicateName": "Adding failed: Duplicate name.",
  "ccmessaging.chat.email.tips.addFailOtherErrors": "Adding failed: Other errors.",
  "ccmessaging.chat.email.tips.addMaximumLimit": "A maximum of 20 subdirectories at the same level.",
  "ccmessaging.chat.email.tips.addSuccess": "Adding Succeeded.",
  "ccmessaging.chat.email.tips.amendFailDuplicateName": "Modification failed: Duplicate name.",
  "ccmessaging.chat.email.tips.amendFailOtherErrors": "Modification failed: Other errors.",
  "ccmessaging.chat.email.tips.amendSuccess": "Modification succeeded.",
  "ccmessaging.chat.email.tips.deleteFailExistEmail": "Deletion failed: Mails exist in the current directory.",
  "ccmessaging.chat.email.tips.deleteFailExistSub": "Deletion failed: The current directory has subdirectories.",
  "ccmessaging.chat.email.tips.deleteFailOtherErrors": "Deletion failed: Other errors.",
  "ccmessaging.chat.email.tips.deleteSuccess": "Deleted Successfully.",
  "ccmessaging.chat.email.tips.isDeleteDirectory": "Do you want to delete the current directory?",
  "ccmessaging.chat.emailmessage.abandoned": "Abandoned",
  "ccmessaging.chat.emailmessage.agent": "Agent",
  "ccmessaging.chat.emailmessage.all": "All",
  "ccmessaging.chat.emailmessage.assigned": "Assigned",
  "ccmessaging.chat.emailmessage.assignEmail.inputAgentName": "Enter the agent account name.",
  "ccmessaging.chat.emailmessage.assignEmail.queryAgentName": "Enter a business account.",
  "ccmessaging.chat.emailmessage.assignEmailFailed": "Failed to assign the email.",
  "ccmessaging.chat.emailmessage.assignEmailPartialSuccess": "Failed to assign some emails.",
  "ccmessaging.chat.emailmessage.assignEmailSuccess": "The email is assigned successfully.",
  "ccmessaging.chat.emailmessage.assigning": "Assign",
  "ccmessaging.chat.emailmessage.assignOtherEmailFailed": "The email from the sender cannot be assigned because it is being handled.",
  "ccmessaging.chat.emailmessage.assignStatus": "Assignment Status",
  "ccmessaging.chat.emailmessage.channel": "Channel auto-response",
  "ccmessaging.chat.emailmessage.channelAccessCode": "Email Channel Access Code",
  "ccmessaging.chat.emailmessage.disAssigned": "Not assigned",
  "ccmessaging.chat.emailmessage.fail.assign.AbandonedEmail.assigning.tip": "Discarded messages cannot be assigned.",
  "ccmessaging.chat.emailmessage.fail.assign.AbandonedEmail.moving.tip": "Dropped messages cannot be moved.",
  "ccmessaging.chat.emailmessage.fail.assign.UnAssignedEmail.tip": "Unassigned mails cannot be assigned.",
  "ccmessaging.chat.emailmessage.handler": "Handler",
  "ccmessaging.chat.emailmessage.handlerType": "Handler Type",
  "ccmessaging.chat.emailmessage.handleStatus": "Handling Status",
  "ccmessaging.chat.emailmessage.moving": "Move",
  "ccmessaging.chat.emailmessage.operation.noMoreThanOneDay": "The query time range cannot exceed one day.",
  "ccmessaging.chat.emailmessage.operation.noMoreThanSevenDay": "The query time range cannot exceed seven days.",
  "ccmessaging.chat.emailmessage.receiver": "Recipient",
  "ccmessaging.chat.emailmessage.robot": "Robot",
  "ccmessaging.chat.emailmessage.sender": "Sender",
  "ccmessaging.chat.emailmessage.sendStatus": "Sending Result",
  "ccmessaging.chat.emailmessage.sendTime": "Sent On",
  "ccmessaging.chat.emailmessage.skillQueue": "Skill queue",
  "ccmessaging.chat.emailmessage.title": "Email Title",
  "ccmessaging.chat.emailmessage.unassigned": "To be assigned",
  "ccmessaging.chat.EMOTICONS.beyondFileSize": "The number of uploaded multimedia files type is error or exceeds the maximum : {n} KB!",
  "ccmessaging.chat.facebookpageconfig.add": "Add Facebook Homepage",
  "ccmessaging.chat.facebookpageconfig.config": "Configuration",
  "ccmessaging.chat.facebookpageconfig.deleteError": "Failed to delete the Facebook homepage settings.",
  "ccmessaging.chat.facebookpageconfig.deleteSuccess": "The Facebook homepage settings are deleted successfully.",
  "ccmessaging.chat.facebookpageconfig.facebookconfig": "Facebook Homepage Settings",
  "ccmessaging.chat.facebookpageconfig.idNotExist": "The data does not exist.",
  "ccmessaging.chat.facebookpageconfig.isDeleteFacebookPage": "Are you sure you want to delete the Facebook homepage settings?",
  "ccmessaging.chat.facebookpageconfig.modify": "Edit Facebook Homepage",
  "ccmessaging.chat.facebookpageconfig.modifyError": "Failed to modify the Facebook homepage.",
  "ccmessaging.chat.facebookpageconfig.modifySuccess": "The Facebook homepage is modified successfully.",
  "ccmessaging.chat.facebookpageconfig.numoversize": "The number of Facebook homepages exceeds the upper limit for this channel.",
  "ccmessaging.chat.facebookpageconfig.pageId": "Page ID",
  "ccmessaging.chat.facebookpageconfig.pageidexist": "The Facebook homepage ID already exists.",
  "ccmessaging.chat.facebookpageconfig.saveError": "Failed to add the Facebook homepage.",
  "ccmessaging.chat.facebookpageconfig.saveSuccess": "The Facebook homepage is added successfully.",
  "ccmessaging.chat.facebookpageconfig.tokenSecret": "Token Secret",
  "ccmessaging.chat.fivegCard": "5G Card",
  "ccmessaging.chat.ifame.param": "Parameter",
  "ccmessaging.chat.iframeagentconsole.mail": "email",
  "ccmessaging.chat.iframeagentconsole.mailCollapse": "tucked",
  "ccmessaging.chat.iframeagentconsole.mailExpand": "Details",
  "ccmessaging.chat.label.accessCode": "Access Code",
  "ccmessaging.chat.label.extCode": "Extension Code",
  "ccmessaging.chat.label.skill": "Skill Queue",
  "ccmessaging.chat.localFile.authFail": "No local file sending permission",
  "ccmessaging.chat.multi.card": "5G Rotating Card",
  "ccmessaging.chat.multi.card.add": "Select Card",
  "ccmessaging.chat.multi.card.id": "Card ID",
  "ccmessaging.chat.multi.card.num.error": "Add 1 to 5 single cards.",
  "ccmessaging.chat.multi.chatcard.alignment": "Image Alignment Mode",
  "ccmessaging.chat.multi.chatcard.alignment.left": "Left Alignment",
  "ccmessaging.chat.multi.chatcard.alignment.right": "Right Alignment",
  "ccmessaging.chat.multi.chatcard.choose": "Card Selection",
  "ccmessaging.chat.multi.chatcard.delete": "The template has been referenced and cannot be deleted. The referenced NVOD card is:",
  "ccmessaging.chat.multi.chatcard.sort": "Card Arrangement Direction",
  "ccmessaging.chat.multi.chatcard.sort.flow": "floats",
  "ccmessaging.chat.multi.chatcard.sort.horizontal": "leveling",
  "ccmessaging.chat.multi.chatcard.sort.vertical": "perpendicular",
  "ccmessaging.chat.multi.chatcard.width": "Card Width",
  "ccmessaging.chat.multi.chatcard.width.medium": "Medium Width",
  "ccmessaging.chat.multi.chatcard.width.small": "Smaller width",
  "ccmessaging.chat.multi.media.resource.invalid": "Cards referencing invalid media resources. Card name:",
  "ccmessaging.chat.multimedia.addCategory": "Add Category",
  "ccmessaging.chat.multimedia.audioManagement": "Audio Library",
  "ccmessaging.chat.multimedia.audioUploadTipFormat": "The audio file to be uploaded must be in MP3, AAC, or M4A format and the file size cannot exceed {n} MB.",
  "ccmessaging.chat.multimedia.beyondFileSize": "The size of uploaded multimedia file exceeds the maximum : {n} MB!",
  "ccmessaging.chat.multimedia.cancel": "Cancel",
  "ccmessaging.chat.multimedia.cardManagement": "Card Template",
  "ccmessaging.chat.multimedia.categoryName": "Category Name",
  "ccmessaging.chat.multimedia.chatPhraseManagement": "ChatPhrase Library",
  "ccmessaging.chat.multimedia.cobrowseSite": "Cobrowse Site",
  "ccmessaging.chat.multimedia.createRichText": "Create Rich Text",
  "ccmessaging.chat.multimedia.delete": "Delete",
  "ccmessaging.chat.multimedia.deleteLimit": "The resource is referenced by card template {0} and cannot be deleted.",
  "ccmessaging.chat.multimedia.deleteLimit.usedbyODFS": "The resource has been referenced by the intelligent IVR and cannot be deleted.",
  "ccmessaging.chat.multimedia.deleteLimit.whatsapp.templatemsg": "The resource cannot be deleted because it is already referenced by a WhatsApp template message. Template message:",
  "ccmessaging.chat.multimedia.deleteMultiMediaInfo": "Delete this multimedia data",
  "ccmessaging.chat.multimedia.deleteMultiMediaInfoError": "Failed to delete multimedia data!",
  "ccmessaging.chat.multimedia.deleteMultiMediaInfoSuccess": "Successfully delete multimedia data!",
  "ccmessaging.chat.multimedia.deleteMultiMediaType": "Delete this multimedia category",
  "ccmessaging.chat.multimedia.deleteMultiMediaTypeError": "Failed to delete multimedia library category!",
  "ccmessaging.chat.multimedia.deleteMultiMediaTypeError.usedbyODFS": "Failed to delete the multimedia library type. The resources referenced by the intelligent IVR exist.",
  "ccmessaging.chat.multimedia.deleteMultiMediaTypeError.referenced.byCard": "Failed to delete the multimedia category because some resources are already referenced by cards.",
  "ccmessaging.chat.multimedia.deleteMultiMediaTypeError.referenced.byWhatsAppTemplateMsg": "Failed to delete the multimedia category because some resources are already referenced by a WhatsApp template message.",
  "ccmessaging.chat.multimedia.deleteMultiMediaTypeSuccess": "Successfully delete multimedia category!",
  "ccmessaging.chat.multimedia.description": "Description",
  "ccmessaging.chat.multimedia.descriptionContainsSpecialCharacters": "The description contains special characters.",
  "ccmessaging.chat.multimedia.descriptionSuperLong": "The description length exceeds the maximum.",
  "ccmessaging.chat.multimedia.documentManagement": "Document Library",
  "ccmessaging.chat.multimedia.documentUploadTipFormat": "Files can be uploaded in word,pdf,ppt,excel or zip format. The zip file supports word,pdf,ppt and excel formats. The file size cannot exceed {n} MB.",
  "ccmessaging.chat.multimedia.download": "Download",
  "ccmessaging.chat.multimedia.edit": "Edit",
  "ccmessaging.chat.multimedia.emoticonsManagement": "Emoticon",
  "ccmessaging.chat.multimedia.emoticonsUploadTipFormat": "The uploaded personalized emoticon file can be in JPG, JPEG, PNG, or GIF format and the file size cannot exceed {n} KB.",
  "ccmessaging.chat.multimedia.exceedFileSize": "The number of uploaded multimedia files type is error or exceeds the maximum : {n}!",
  "ccmessaging.chat.multimedia.fileName": "File Name",
  "ccmessaging.chat.multimedia.fileSize": "File Size",
  "ccmessaging.chat.multiMedia.fileUploadError": "The uploaded file has the wrong format",
  "ccmessaging.chat.multiMedia.fileUploadInjectionRisk": "This upload has injection risks. Check the content and try again.",
  "ccmessaging.chat.multimedia.fileUploadLabel": "Please select a file to upload",
  "ccmessaging.chat.multiMedia.fileUploadNameError": "The name format of the uploaded file is incorrect.",
  "ccmessaging.chat.multiMedia.fileUploadTypeError": "The format of the uploaded file type is incorrect.",
  "ccmessaging.chat.multimedia.finish": "Finish",
  "ccmessaging.chat.multimedia.getRichTextInfoError": "Failed to obtain rich text data.",
  "ccmessaging.chat.multimedia.id": "ID",
  "ccmessaging.chat.multimedia.imageManagement": "Picture Library",
  "ccmessaging.chat.multimedia.imageUploadTipFormat": "The uploaded image file currently only supports jpg, png, jpeg formats, and the file size should not exceed {n}M.",
  "ccmessaging.chat.multimedia.isDeleteMultiMediaInfo": "Are you sure you want to delete this multimedia data?",
  "ccmessaging.chat.multimedia.isDeleteMultiMediaType": "Are you sure you want to delete the multimedia type and its multimedia data?",
  "ccmessaging.chat.multimedia.latitude": "Latitude",
  "ccmessaging.chat.multimedia.latitudeValidateFailed": "The latitude integer part is -90~90, and the decimal part is 0 to 6 digits!",
  "ccmessaging.chat.multimedia.locationManagement": "Address Library",
  "ccmessaging.chat.multimedia.longitude": "Longitude",
  "ccmessaging.chat.multimedia.longitudeValidateFailed": "The longitude integer part is -180~180, and the decimal part is 0 to 6 digits!",
  "ccmessaging.chat.multimedia.mediaFileType": "Media FileType",
  "ccmessaging.chat.multimedia.modifyCategory": "Modify Category",
  "ccmessaging.chat.multimedia.modifyMultiMediaInfo": "Successfully modify multimedia data!",
  "ccmessaging.chat.multimedia.modifyMultiMediaInfoError": "Failed to modify multimedia data!",
  "ccmessaging.chat.multimedia.modifyMultiMediaType": "Successfully modify multimedia category!",
  "ccmessaging.chat.multimedia.modifyMultiMediaTypeError": "Failed to modify multimedia library category!",
  "ccmessaging.chat.multimedia.modifyRichText": "Edit Rich Text",
  "ccmessaging.chat.multimedia.multiMediaInfoInputEmpty": "The multimedia input data is empty!",
  "ccmessaging.chat.multimedia.multiMediaInfoInputInvalid": "The multimedia input data is invalid!",
  "ccmessaging.chat.multimedia.multiMediaManagement": "Multimedia Library Settings",
  "ccmessaging.chat.multimedia.multiMediaNameExist": "The multimedia data name already exists in this category!",
  "ccmessaging.chat.multimedia.multiMediaTypeIdNotExist": "The selected multimedia library category does not exist,please select the multimedia library category!",
  "ccmessaging.chat.multimedia.multiMediaTypeInputEmpty": "The multimedia category input data is empty!",
  "ccmessaging.chat.multimedia.multiMediaTypeInputInvalid": "The multimedia category input data is invalid!",
  "ccmessaging.chat.multimedia.multiMediaTypeNameExist": "The multimedia category name already exists!",
  "ccmessaging.chat.multimedia.name": "Name",
  "ccmessaging.chat.multimedia.nameContainsSpecialCharacters": "The name contains special characters.",
  "ccmessaging.chat.multimedia.nameSuperLong": "The name length exceeds the maximum.",
  "ccmessaging.chat.multimedia.operating": "Operating",
  "ccmessaging.chat.multimedia.play": "play",
  "ccmessaging.chat.multimedia.preview": "Preview",
  "ccmessaging.chat.multimedia.richTextContentEmpty": "The rich text content is empty.",
  "ccmessaging.chat.multimedia.richTextContentLongLimit": "The rich text content can contain a maximum of 5000 characters.",
  "ccmessaging.chat.multimedia.richTextContentSuperLong": "The rich text can contain a maximum of 5000 characters.",
  "ccmessaging.chat.multimedia.richTextManagement": "Rich Text",
  "ccmessaging.chat.multimedia.richTextTypeEmpty": "The rich text category does not exist.",
  "ccmessaging.chat.multimedia.categorizeEmpty": "Select or create a category first.",
  "ccmessaging.chat.multimedia.saveMultiMediaInfo": "Successfully saved multimedia data!",
  "ccmessaging.chat.multimedia.saveMultiMediaInfoError": "Failed to save multimedia data!",
  "ccmessaging.chat.multimedia.saveMultiMediaType": "Successfully saved multimedia category!",
  "ccmessaging.chat.multimedia.saveMultiMediaTypeError": "Failed to save multimedia library category!",
  "ccmessaging.chat.multimedia.selectFile": "Select File",
  "ccmessaging.chat.multimedia.selectImage": "Select Image",
  "ccmessaging.chat.multimedia.upload": "Upload",
  "ccmessaging.chat.multimedia.uploadTime": "Upload Time",
  "ccmessaging.chat.multimedia.videoManagement": "Video Library",
  "ccmessaging.chat.multimedia.videoUploadTipFormat": "The uploaded video file currently only supports mp4 format, and the file size should not exceed {n}M.",
  "ccmessaging.chat.multimedia.viewRichText": "View Rich Text",
  "ccmessaging.chat.multimedia.whatsAppTemplate": "WhatsApp Template",
  "ccmessaging.chat.multimediaasccociation.approvalstatus": "Review Status",
  "ccmessaging.chat.multimediaasccociation.checkmediainfofailed": "The multimedia data has not been released to the associated 5G channel or has not been approved!",
  "ccmessaging.chat.multimediaasccociation.deleteassociation": "Delete Release Result",
  "ccmessaging.chat.multimediaasccociation.deleteassociationinfo": "Are you sure you want to delete the release result for the multimedia data and the associated 5G channel?",
  "ccmessaging.chat.multimediaasccociation.deleteError": "Failed to delete the release result!",
  "ccmessaging.chat.multimediaasccociation.deleteSuccess": "The release result is deleted successfully!",
  "ccmessaging.chat.multimediaasccociation.examinationfailed": "Failed",
  "ccmessaging.chat.multimediaasccociation.examinationpassed": "Approved",
  "ccmessaging.chat.multimediaasccociation.expirationtime": "Expired At",
  "ccmessaging.chat.multimediaasccociation.isrelease": "Are you sure you want to release the multimedia data to the 5G channel?",
  "ccmessaging.chat.multimediaasccociation.refresh": "Refresh",
  "ccmessaging.chat.multimediaasccociation.refreshfailed": "Failed to refresh the data!",
  "ccmessaging.chat.multimediaasccociation.refreshsuccess": "The data is refreshed successfully!",
  "ccmessaging.chat.multimediaasccociation.release": "Release",
  "ccmessaging.chat.multimediaasccociation.releaseerror": "Failed to release the multimedia data!",
  "ccmessaging.chat.multimediaasccociation.releaseresult": "Release Result",
  "ccmessaging.chat.multimediaasccociation.releasesuccess": "The multimedia data is released successfully!",
  "ccmessaging.chat.multimediaasccociation.releasetitle": "Release Multimedia Data",
  "ccmessaging.chat.multimediaasccociation.unpublishconfigempty": "The 5G channel where the multimedia data is to be released is not configured.",
  "ccmessaging.chat.multimediaasccociation.unreviewed": "Not reviewed",
  "ccmessaging.chat.offlineCall.channelType": "Channel Type",
  "ccmessaging.chat.offlineCall.select.noChannel": "The channel does not exist.",
  "ccmessaging.chat.offlineCall.userId": "User ID",
  "ccmessaging.chat.offlineSwitch.closed": "Sending failed because the offline message function is disabled for the channel.",
  "ccmessaging.chat.phrase.add": "Add",
  "ccmessaging.chat.phrase.addPhraseError": "Create common phrase failed",
  "ccmessaging.chat.phrase.addPhraseSuccess": "Create common phrase successfully",
  "ccmessaging.chat.phrase.addType": "New Category",
  "ccmessaging.chat.phrase.AllCount": "Total Words",
  "ccmessaging.chat.phrase.cancel": "Cancel",
  "ccmessaging.chat.phrase.currentCount": "Current word count",
  "ccmessaging.chat.phrase.delete": "Delete",
  "ccmessaging.chat.phrase.deleteType": "Delete Type",
  "ccmessaging.chat.phrase.delPhraseError": "Delete common phrase failed",
  "ccmessaging.chat.phrase.delPhraseSucess": "Delete common phrase successfully",
  "ccmessaging.chat.phrase.delPhraseTypeError": "Delete common phrase type failed",
  "ccmessaging.chat.phrase.delPhraseTypeSuccess": "Delete common phrase type successfully",
  "ccmessaging.chat.phrase.edit": "Edit",
  "ccmessaging.chat.phrase.editType": "Edit Type",
  "ccmessaging.chat.phrase.enter_keyword": "Enter a keyword.",
  "ccmessaging.chat.phrase.enterPhrase": "Please enter a common phrase",
  "ccmessaging.chat.phrase.enterPhraseType": "Please enter a common phrase type",
  "ccmessaging.chat.phrase.error": "Error",
  "ccmessaging.chat.phrase.isDeletePhrase": "Are you sure you want to delete this phrase",
  "ccmessaging.chat.phrase.isDeletePhraseType": "Are you sure you want to delete the common phrase type and its common phrases?",
  "ccmessaging.chat.phrase.isSavePhrase": "Are you sure you want to save the common phrase?",
  "ccmessaging.chat.phrase.isSavePhraseType": "Are you sure you want to save the common phrase type?",
  "ccmessaging.chat.phrase.phrase": "Phrase",
  "ccmessaging.chat.phrase.phraseList": "List of phrase",
  "ccmessaging.chat.phrase.phraseRepeat": "Duplicate common name",
  "ccmessaging.chat.phrase.phraseType": "Phrase Type",
  "ccmessaging.chat.phrase.phraseTypeEmpty": "The common phrase type to be deleted does not exist. Please select a common phrase type.",
  "ccmessaging.chat.phrase.phraseTypeRepeat": "The common phrase category is duplicated",
  "ccmessaging.chat.phrase.phraseTypeLimit": "The number of common phrase types reaches the upper limit 100.",
  "ccmessaging.chat.phrase.prompt": "Prompt",
  "ccmessaging.chat.phrase.save": "Save",
  "ccmessaging.chat.phrase.saveError": "Save failed",
  "ccmessaging.chat.phrase.saveType": "Save Type",
  "ccmessaging.chat.phrase.saveTypeSuccess": "Save common language category successfully",
  "ccmessaging.chat.phrase.shortcut": "ShortCut",
  "ccmessaging.chat.phrase.success": "Success",
  "ccmessaging.chat.phrase.updatePhraseTypeError": "Failed to update the common phrase type.",
  "ccmessaging.chat.phrase.updatePhraseTypeSucess": "Succeeded in updating the common phrase type.",
  "ccmessaging.chat.phrase.updateError": "Update Error",
  "ccmessaging.chat.phrase.updatePhraseError": "Update common language failed",
  "ccmessaging.chat.phrase.updatePhraseSucess": "Update common language successfully",
  "ccmessaging.chat.phrase.updateSuccess": "Update Success",
  "ccmessaging.chat.portraitchange.customize": "Custom",
  "ccmessaging.chat.portraitchange.systemAvatar": "System avatar",
  "ccmessaging.chat.querymessage": "Query media history message",
  "ccmessaging.chat.refresh.fail": "Refresh failed",
  "ccmessaging.chat.repeatClick": "Do not click repeatedly!",
  "ccmessaging.chat.richText.add": "Create",
  "ccmessaging.chat.richText.cancel": "Cancel",
  "ccmessaging.chat.richText.idInput": "Enter an ID.",
  "ccmessaging.chat.richText.nameInput": "Enter a name.",
  "ccmessaging.chat.richText.openInNewWindow": "Open in a new window",
  "ccmessaging.chat.richText.save": "Save",
  "ccmessaging.chat.selectchannel.vaildate1": "The channel access code must be unique.",
  "ccmessaging.chat.sessiontransfer.tips": "Only idle agents and agents whose concurrent calls do not reach the upper limit can be transferred.",
  "ccmessaging.chat.silentagent.aceesscode.empty": "The access code cannot be null!",
  "ccmessaging.chat.silentagent.config.accesscode.same": "The silent agent configuration of the voice IVR called route already exists in the current lease.",
  "ccmessaging.chat.silentagent.config.del.error": "Failed to delete the voice silent agent configuration.",
  "ccmessaging.chat.silentagent.config.del.success": "Deleting the voice silent agent configuration succeeded.",
  "ccmessaging.chat.silentagent.config.willdel": "Are you sure you want to delete the selected voice silent agent configuration?",
  "ccmessaging.chat.silentagent.DeleteFail": "Failed to delete the silent agent configuration.",
  "ccmessaging.chat.silentagent.DeleteSuccess": "Deleting silent agent configuration succeeded.",
  "ccmessaging.chat.silentagent.sendContent.noOIAPNode": "Silent Agent can not get information of current node!",
  "ccmessaging.chat.silentagent.sendContent.supervise": "Silent Agent can not send message int the supervise workMode!",
  "ccmessaging.chat.silentagent.silent.skill.empty": "The skill queue of the silent agent cannot be empty",
  "ccmessaging.chat.silentAgent.workMode.changeWorkMode.fail": "The silentAgent change workMode fail!",
  "ccmessaging.chat.silentAgent.workMode.changeWorkMode.prompt": "Tip",
  "ccmessaging.chat.silentAgent.workMode.changeWorkMode.success": "The silentAgent change workMode success!",
  "ccmessaging.chat.silentAgent.workMode.common": "common",
  "ccmessaging.chat.silentAgent.workMode.insert": "insert",
  "ccmessaging.chat.silentAgent.workMode.supervise": "supervise",
  "ccmessaging.chat.silentAgent.workMode.transfer": "transfer",
  "ccmessaging.chat.single.media.resource.invalid": "Cards referencing invalid media resources.",
  "ccmessaging.chat.sms.channel.gateway": "SMS gateway",
  "ccmessaging.chat.social.media.enterprise.default.username": " Anonymous User",
  "ccmessaging.chat.social.media.enterprise.manage": "Enterprise Account Message Processing",
  "ccmessaging.chat.social.media.enterprise.post.add.failed": "Adding failed.",
  "ccmessaging.chat.social.media.enterprise.post.add.success": "Adding succeeded.",
  "ccmessaging.chat.social.media.enterprise.post.all.reply": "All Reply",
  "ccmessaging.chat.social.media.enterprise.post.batch.reply": "Batch Reply",
  "ccmessaging.chat.social.media.enterprise.post.cancel": "Cancel",
  "ccmessaging.chat.social.media.enterprise.post.comment": "Comment on",
  "ccmessaging.chat.social.media.enterprise.post.comment.tips": "Add a comment...",
  "ccmessaging.chat.social.media.enterprise.post.reply": "Reply",
  "ccmessaging.chat.social.media.enterprise.post.reply.cancel": "Cancel",
  "ccmessaging.chat.social.media.enterprise.post.reply.tips": "Add Reply...",
  "ccmessaging.chat.social.media.enterprise.post.title": "Post Name",
  "ccmessaging.chat.social.media.enterprise.post.topcomment.cancel": "Cancel comment",
  "ccmessaging.chat.social.media.enterprise.tips.close": "Fold",
  "ccmessaging.chat.social.media.enterprise.tips.imgnum": "A maximum of one image can be added.",
  "ccmessaging.chat.social.media.enterprise.tips.open": "Unfold",
  "ccmessaging.chat.social.media.multimedia.manage": "Multimedia message processing",
  "ccmessaging.chat.speciallist.channels": "Restricted Channels",
  "ccmessaging.chat.speciallist.channelsType": "Restricted Channel Type",
  "ccmessaging.chat.speciallist.choosechannel": "Select Channel",
  "ccmessaging.chat.speciallist.confirmdelete": "Are you sure to delete?",
  "ccmessaging.chat.speciallist.delete": "Abandoned",
  "ccmessaging.chat.speciallist.deletelevel": "(Abandoned)",
  "ccmessaging.chat.speciallist.emailchannels": "Email",
  "ccmessaging.chat.speciallist.haverestrictall": "All channels of this channel type are restricted. No need to restrict this channel.",
  "ccmessaging.chat.speciallist.haverestrictchannel": "This channel is restricted. No need to restrict it again.",
  "ccmessaging.chat.speciallist.level": "Special List Level",
  "ccmessaging.chat.speciallist.leveName": "Special List Type Name",
  "ccmessaging.chat.speciallist.msgchoosechannel": "Please select a channel type first.",
  "ccmessaging.chat.speciallist.remark": "Description",
  "ccmessaging.chat.speciallist.repeatlevel": "The special list level already exists. Do not create it again.",
  "ccmessaging.chat.speciallist.restrictHours": "Restricted duration",
  "ccmessaging.chat.speciallist.restrictUnit": "hour",
  "ccmessaging.chat.speciallist.restrictMode": "Restriction Mode",
  "ccmessaging.chat.speciallist.restrictStyle": "Application Mode",
  "ccmessaging.chat.speciallist.selectchannel": "Designated Channel",
  "ccmessaging.chat.speciallist.smschannels": "SMS",
  "ccmessaging.chat.speciallist.unuse": "Not Available",
  "ccmessaging.chat.speciallist.unuselevel": "(Not Available)",
  "ccmessaging.chat.speciallist.use": "Available",
  "ccmessaging.chat.successaccess": "Successful access",
  "ccmessaging.chat.sumbitverification.Appld": "App ID",
  "ccmessaging.chat.sumbitverification.AppSecret": "Key",
  "ccmessaging.chat.sumbitverification.facebook.tips": "Go to the Facebook Messenger developer page to configure the website and verification code.",
  "ccmessaging.chat.sumbitverification.fiveG.tips": "Please go to the 5G Message Platform Configuration page to configure the website address.",
  "ccmessaging.chat.sumbitverification.line.tips": "Configure the Webhook URL in the Official Account Manager of the LINE.",
  "ccmessaging.chat.sumbitverification.tips": "Please go to WeChat public platform interface configuration information for configuration URL and verification code",
  "ccmessaging.chat.sumbitverification.tips.qr": "In this authorization mode, no more configuration is required. Go to the next step.",
  "ccmessaging.chat.sumbitverification.Url": "URL",
  "ccmessaging.chat.sumbitverification.Url.infobipMessAgeUrl": "url used to send message to agent",
  "ccmessaging.chat.sumbitverification.Url.infobipReportUrl": "url used to send report to agent",
  "ccmessaging.chat.sumbitverification.VerityCode": "Verification Code",
  "ccmessaging.chat.sumbitverification.whatsApp.fail": "Generate Fail",
  "ccmessaging.chat.sumbitverification.whatsApp.prompt": "Tip",
  "ccmessaging.chat.sumbitverification.whatsApp.success": "Generate Success",
  "ccmessaging.chat.sumbitverification.whatsApp.tips": "URL used by the WhatsApp service provider to process messages",
  "ccmessaging.chat.system.error": "The system is busy. Please try again later.",
  "ccmessaging.chat.template.buttonSend": "Send",
  "ccmessaging.chat.template.butttonName": "Select",
  "ccmessaging.chat.template.card": "WhatsApp Template Message",
  "ccmessaging.chat.template.Description": "Description",
  "ccmessaging.chat.template.languageCode": "Language",
  "ccmessaging.chat.template.name": "Template Message Name",
  "ccmessaging.chat.template.namePlaceholder": "Enter a message name.",
  "ccmessaging.chat.template.table.button": "Button",
  "ccmessaging.chat.template.table.buttonPlaceholder": "Quick reply of the button.",
  "ccmessaging.chat.template.table.no": "No.",
  "ccmessaging.chat.template.table.parameterContent": "Parameter Content",
  "ccmessaging.chat.template.table.parameterDescription": "Parameter Description",
  "ccmessaging.chat.template.table.parameterPlaceholder": "Enter a parameter.",
  "ccmessaging.chat.template.whatsAppChannel": "Channel",
  "ccmessaging.chat.template.whatsAppTemplateName": "Template Name",
  "ccmessaging.chat.templateMsg.chooseMultimedia": "Select a multimedia resource.",
  "ccmessaging.chat.templateMsg.sendTemplateMsg": "Send",
  "ccmessaging.chat.templateMsg.sendTemplateMsgTips": "Send Template Message",
  "ccmessaging.chat.tinymce.button.clear": "clear",
  "ccmessaging.chat.tinyMCE.FangSong": " FangSong",
  "ccmessaging.chat.tinyMCE.SimHei": " SimHei",
  "ccmessaging.chat.tinyMCE.simsun": " simsun",
  "ccmessaging.chat.tinyMCE.yaHei": " Microsoft YaHei",
  "ccmessaging.chat.uploadLocalFile": "Cargar archivo local",
  "ccmessaging.chat.warning.sharescreen": "Pay attention to personal privacy when sharing the desktop",
  "ccmessaging.chat.web.agent.assist": "Agent Assistant",
  "ccmessaging.chat.web.agent.prohibitedmess": "Prohibited language! Please pay attention to the words.",
  "ccmessaging.chat.web.agent.wrogmess": "Inappropriately worded! Please be careful to use appropriate words.",
  "ccmessaging.chat.web.channel.called.config": "CTD Called Party Configuration",
  "ccmessaging.chat.web.channel.clickivr": "Click to obtain the IVR access code.",
  "ccmessaging.chat.webCard": "WebCard",
  "ccmessaging.chat.webCard.copyUrl": "copy link",
  "ccmessaging.chat.webCard.copyUrl.success": "copy link success",
  "ccmessaging.chat.webcard.pop.confirm.outside": "Whether to jump to third-party links?",
  "ccmessaging.chat.webcard.pop.confirm.prefix": "The message will be redirected to the external address,website domain is",
  "ccmessaging.chat.webcard.pop.confirm.suffix": "Are you sure you want to continue?",
  "ccmessaging.chat.webcard.pop.error.prefix": "The website domain",
  "ccmessaging.chat.webcard.pop.error.suffix": "is not in the address whitelist. Contact operations personnel to add it to the whitelist.",
  "ccmessaging.chat.webclient.chatapi.evaluation": "Please rate this service, thank you!",
  "ccmessaging.chat.webclient.chatapi.failTransToAgent": "Transform to agent failed, please try again or contact the administrator",
  "ccmessaging.chat.webclient.chathtml.customer": "Customer",
  "ccmessaging.chat.webclient.chatjs.inpuStatus": "The other party is typing. ",
  "ccmessaging.chat.whatsapp.body": "body",
  "ccmessaging.chat.whatsapp.buttons": "buttons",
  "ccmessaging.chat.whatsapp.footer": "footer",
  "ccmessaging.chat.whatsapp.header": "Header",
  "ccmessaging.chat.whatsapp.languageAF": "Dutch, South Africa",
  "ccmessaging.chat.whatsapp.languageAR": "Arabic",
  "ccmessaging.chat.whatsapp.languageAZ": "Azerbaijani",
  "ccmessaging.chat.whatsapp.languageBG": "Bulgarian",
  "ccmessaging.chat.whatsapp.languageBN": "Bengali",
  "ccmessaging.chat.whatsapp.languageCA": "Catalonian",
  "ccmessaging.chat.whatsapp.languageCS": "Czech",
  "ccmessaging.chat.whatsapp.languageDA": "Danish",
  "ccmessaging.chat.whatsapp.languageDE": "German",
  "ccmessaging.chat.whatsapp.languageEL": "Greek",
  "ccmessaging.chat.whatsapp.languageEN": "English",
  "ccmessaging.chat.whatsapp.languageENGB": "English (United Kingdom)",
  "ccmessaging.chat.whatsapp.languageES": "Spanish",
  "ccmessaging.chat.whatsapp.languageESAR": "Spanish (Argentina)",
  "ccmessaging.chat.whatsapp.languageESES": "Spanish (Spain)",
  "ccmessaging.chat.whatsapp.languageESMX": "Spanish (Mexico)",
  "ccmessaging.chat.whatsapp.languageET": "Estonian",
  "ccmessaging.chat.whatsapp.languageFA": "Persian",
  "ccmessaging.chat.whatsapp.languageFI": "Finnish",
  "ccmessaging.chat.whatsapp.languageFIL": "Philippine",
  "ccmessaging.chat.whatsapp.languageFR": "French",
  "ccmessaging.chat.whatsapp.languageGA": "Irish",
  "ccmessaging.chat.whatsapp.languageGU": "Gujarat",
  "ccmessaging.chat.whatsapp.languageHA": "Hausa",
  "ccmessaging.chat.whatsapp.languageHE": "Hebrew",
  "ccmessaging.chat.whatsapp.languageHI": "Hindi",
  "ccmessaging.chat.whatsapp.languageHR": "Croatian",
  "ccmessaging.chat.whatsapp.languageHU": "Hungarian",
  "ccmessaging.chat.whatsapp.languageID": "Indonesian",
  "ccmessaging.chat.whatsapp.languageIT": "Italian",
  "ccmessaging.chat.whatsapp.languageJA": "Japanese",
  "ccmessaging.chat.whatsapp.languageKK": "Kazakh",
  "ccmessaging.chat.whatsapp.languageKN": "Kanadawen",
  "ccmessaging.chat.whatsapp.languageKO": "Korean",
  "ccmessaging.chat.whatsapp.languageLO": "Laotian",
  "ccmessaging.chat.whatsapp.languageLT": "Lithuanian",
  "ccmessaging.chat.whatsapp.languageLV": "Latvian",
  "ccmessaging.chat.whatsapp.languageMK": "Northern Macedonian",
  "ccmessaging.chat.whatsapp.languageML": "Malayalam",
  "ccmessaging.chat.whatsapp.languageMR": "Marathi",
  "ccmessaging.chat.whatsapp.languageMS": "Malay",
  "ccmessaging.chat.whatsapp.languageNB": "Norwegian",
  "ccmessaging.chat.whatsapp.languageNL": "Dutch",
  "ccmessaging.chat.whatsapp.languagePA": "Punjabi",
  "ccmessaging.chat.whatsapp.languagePL": "Polish",
  "ccmessaging.chat.whatsapp.languagePTBR": "Portuguese (Brazil)",
  "ccmessaging.chat.whatsapp.languagePTPT": "Portuguese (Portugal)",
  "ccmessaging.chat.whatsapp.languageRO": "Romanian",
  "ccmessaging.chat.whatsapp.languageRU": "Russian",
  "ccmessaging.chat.whatsapp.languageSK": "Slovakian",
  "ccmessaging.chat.whatsapp.languageSL": "Slovenian",
  "ccmessaging.chat.whatsapp.languageSQ": "Albanian",
  "ccmessaging.chat.whatsapp.languageSR": "Serbian",
  "ccmessaging.chat.whatsapp.languageSV": "Swedish",
  "ccmessaging.chat.whatsapp.languageSW": "Swahili",
  "ccmessaging.chat.whatsapp.languageTA": "Tamil",
  "ccmessaging.chat.whatsapp.languageTE": "Telugu",
  "ccmessaging.chat.whatsapp.languageTH": "Thai",
  "ccmessaging.chat.whatsapp.languageTR": "Turkish",
  "ccmessaging.chat.whatsapp.languageUK": "Ukrainian",
  "ccmessaging.chat.whatsapp.languageUR": "Urdu",
  "ccmessaging.chat.whatsapp.languageUS": "English (United States)",
  "ccmessaging.chat.whatsapp.languageUZ": "Uzbek",
  "ccmessaging.chat.whatsapp.languageVI": "Vietnamese",
  "ccmessaging.chat.whatsapp.languageZHCN": "Simplified Chinese",
  "ccmessaging.chat.whatsapp.languageZHHK": "Traditional Chinese (Hong Kong, China)",
  "ccmessaging.chat.whatsapp.languageZHTW": "Traditional Chinese (Taiwan, China)",
  "ccmessaging.chat.whatsapp.languageZU": "Zulu",
  "ccmessaging.chat.whatsapp.only.send.template": "Only template messages can be sent when a new WhatsApp user or the contact interval exceeds 24 hours.",
  "ccmessaging.chat.whatsapp.selectTemplateOrgPlaceHolder": "Select Organization",
  "ccmessaging.chat.whatsapp.template.dailyLimit": "Template availability times per day",
  "ccmessaging.chat.whatsapp.template.dailyLimitDescription": "Please enter the number of times the template is available per day.",
  "ccmessaging.chat.whatsapp.template.no": "No.",
  "ccmessaging.chat.whatsapp.template.updateTime": "Updated",
  "ccmessaging.chat.whatsapp.templateAddButton": "Create",
  "ccmessaging.chat.whatsapp.templateBodyParamDesc": "Parameter Description",
  "ccmessaging.chat.whatsapp.templateBodyParamDescPlaceholder": "Enter the parameter description.",
  "ccmessaging.chat.whatsapp.templateBodyText": "Body Text Content",
  "ccmessaging.chat.whatsapp.templateBodyTextPlaceholder": "Enter the body text content,the content in {'{{}}'} is editable in the template body.",
  "ccmessaging.chat.whatsapp.templateButton.clicktoAction": "Action",
  "ccmessaging.chat.whatsapp.templateButton.none": "N/A",
  "ccmessaging.chat.whatsapp.templateButton.qickReply": "Quick reply",
  "ccmessaging.chat.whatsapp.templateButtonContent": "Template Button Content",
  "ccmessaging.chat.whatsapp.templateButtonContentPlaceholder": "Enter the button content.",
  "ccmessaging.chat.whatsapp.templateButtonName": "Template Button Name",
  "ccmessaging.chat.whatsapp.templateButtonNamePlaceholder": "Enter a button name.",
  "ccmessaging.chat.whatsapp.templateButtonType": "Template Button Type",
  "ccmessaging.chat.whatsapp.templateChannel": "Template Channel",
  "ccmessaging.chat.whatsapp.templateContent": "Template Content",
  "ccmessaging.chat.whatsapp.templateCreate": "Create WhatsApp Template",
  "ccmessaging.chat.whatsapp.templateDescription": "Template Description",
  "ccmessaging.chat.whatsapp.templateDescriptionPlaceholder": "Enter the template description.",
  "ccmessaging.chat.whatsapp.templateFooterText": "Footer Text Content",
  "ccmessaging.chat.whatsapp.templateFooterTextPlaceholder": "Enter footer text content.",
  "ccmessaging.chat.whatsapp.templateHeaderText": "Header Text Content",
  "ccmessaging.chat.whatsapp.templateHeaderTextPlaceholder": "Enter the header text content.",
  "ccmessaging.chat.whatsapp.templateHeaderType": "Header",
  "ccmessaging.chat.whatsapp.templateHeaderType.image": "Image",
  "ccmessaging.chat.whatsapp.templateHeaderType.locate": "Location",
  "ccmessaging.chat.whatsapp.templateHeaderType.none": "N/A",
  "ccmessaging.chat.whatsapp.templateHeaderType.text": "Text",
  "ccmessaging.chat.whatsapp.templateHeaderType.video": "Video",
  "ccmessaging.chat.whatsapp.templateLanguage": "Language",
  "ccmessaging.chat.whatsapp.templateName": "Template Name",
  "ccmessaging.chat.whatsapp.templateNamePlaceholder": "Enter a template name.",
  "ccmessaging.chat.whatsAppTemplateMsg.selectWhatsAppTemplateMsg": "Select the corresponding WhatsApp template message.",
  "ccmessaging.chat.whatsAppTemplateMsg.selectWhatsAppTemplateMsgId": "WhatsApp template message ID",
  "ccmessaging.chat.whatsAppTemplateMsg.selectWhatsAppTemplateMsgName": "WhatsApp Template Message Name",
  "ccmessaging.chat.workbench.body.iolp": "Service Guidance",
  "ccmessaging.chat.workbench.body.transfer": "Transfer Call",
  "ccmessaging.chat.workbench.transfer.agent": "Agent",
  "ccmessaging.chat.workbench.transfer.noagents": "No online agent exists in the current skill queue.",
  "ccmessaging.chat.workbench.transfer.skill": "Skill Queue",
  "ccmessaging.chat.workbench.transfer.skilllogin": "Available Agent",
  "ccmessaging.chat.workbench.transfer.skillname": "Skill Queue Name",
  "ccmessaging.chat.workbench.transfer.skillqueue": "Wait In Queue",
  "ccmessaging.chat.workbench.transfer.skillsearch": "Please enter a skill queue name",
  "ccmessaging.chatbot.button.avatarImport": " Upload",
  "ccmessaging.chatbot.button.avatarImportLimit": " The size of the file to be uploaded cannot exceed 6 MB and the file can be in JPG or PNG format.",
  "ccmessaging.chatbot.button.cancel": " Cancel",
  "ccmessaging.chatbot.button.edit": " Edit",
  "ccmessaging.chatbot.label.avatar": "Avatar",
  "ccmessaging.chatbot.label.avatarImport": " Upload Avatar",
  "ccmessaging.chatbot.label.avatarImportFailed": " Fail to upload the image!",
  "ccmessaging.chatbot.label.avatarImportSuccess": " Successfully uploaded the image!",
  "ccmessaging.chatbot.label.avatarModifing": "Change Avatar",
  "ccmessaging.chatbot.label.basicInformation": "Basic Information",
  "ccmessaging.chatbot.label.commonLanguageSetting": "Common Phrase Configuration",
  "ccmessaging.chatbot.label.defaultReply": "Default reply",
  "ccmessaging.chatbot.label.gender": "Gender",
  "ccmessaging.chatbot.label.name": "Nickname",
  "ccmessaging.chatbot.label.save": "Save",
  "ccmessaging.chatbot.label.timeoutReply": "Timeout reply",
  "ccmessaging.chatbot.label.turnManualPrompt": "Prompt for transfer to agent",
  "ccmessaging.chatbot.label.welcomeMessage": "Greeting",
  "ccmessaging.chatbot.message.error": "Failure",
  "ccmessaging.chatbot.message.modifyFailure": "Modification failed.",
  "ccmessaging.chatbot.message.modifySuccess": "Modified successfully.",
  "ccmessaging.chatbot.message.specialStrValidateFailed": "The entered content cannot contain the following characters: & < > /.",
  "ccmessaging.chatbot.message.success": "Success",
  "ccmessaging.chatofflineCall.channelName": "Channel Selection",
  "ccmessaging.chatofflineCall.invalidUserIdTip": "The subscriber ID is invalid.",
  "ccmessaging.chatofflineCall.noSelectChanelTip": "No channel is selected.",
  "ccmessaging.chatofflineCall.noSupportText": "This call is a stranger call!",
  "ccmessaging.chatofflineCall.strangeOfflineTip": "No historical session is found.",
  "ccmessaging.cobrowsesite.protocol.check": "Insecure protocols are used\, which poses security risks",
  "ccmessaging.cobrowsesite.mediaurl.format.error": "The format of the cobrowse site is incorrect.",
  "ccmessaging.email.attachment.fileType.tip1": "Email attachments support the following file formats:",
  "ccmessaging.email.attachment.fileType.tip2": ",",
  "ccmessaging.email.attachment.fileType.tip3": "The file size cannot exceed",
  "ccmessaging.email.attachment.fileType.tip4": "M.",
  "ccmessaging.emailcatalog.add": "Create Directory",
  "ccmessaging.emailcatalog.canNotAdd": "A maximum of two levels of directories are allowed.",
  "ccmessaging.emailcatalog.deleteTopCatalog": "The default directory cannot be deleted.",
  "ccmessaging.emailcatalog.duplicateName": "The name already exists. Use another name.",
  "ccmessaging.emailcatalog.existEmail": "Emails exist in the directory.",
  "ccmessaging.emailcatalog.existSubCatalog": "The directory contains subdirectories.",
  "ccmessaging.emailcatalog.modify": "Modify Directory",
  "ccmessaging.emailcatalog.modifyTopCatalog": "The default directory cannot be modified.",
  "ccmessaging.emailcatalog.moveCatalogFailed": "Failed to move a directory.",
  "ccmessaging.emailcatalog.moveCatalogPartialSuccess": "Failed to move some email directories.",
  "ccmessaging.emailcatalog.moveCatalogSuccess": "The directory is moved successfully.",
  "ccmessaging.emailcatalog.moveEmailQuantityExceedsMaxValue": "The number of moved mails exceeds 30 or the parameter length exceeds the maximum.",
  "ccmessaging.emailcatalog.moveOtherCatalogFailed": "The emails of other agents cannot be moved.",
  "ccmessaging.emailcatalog.catalogname": "Catalog Name",
  "ccmessaging.emailcatalog.parentName": "Parent Directory Name",
  "ccmessaging.emailcatalog.pleaseSelectCatalog": "Select a directory first.",
  "ccmessaging.emailcatalog.selectcatalog": "Select Directory",
  "ccmessaging.emailMessageManage.forward": "Forwarding Record",
  "ccmessaging.flowControl.addFail": "Failed to add the flow control configuration.",
  "ccmessaging.flowControl.addSuccess": "The flow control configuration is added successfully.",
  "ccmessaging.flowControl.alarmInterval": "Alarm Interval",
  "ccmessaging.flowControl.alarmInterval.unit": "min",
  "ccmessaging.flowControl.alarmThreshold": "Alarm Threshold",
  "ccmessaging.flowControl.channelType": "Channel Type",
  "ccmessaging.flowControl.count.beyondMaxLimit": "The number of flow control rules in the tenant space exceeds the upper limit.",
  "ccmessaging.flowControl.createTime": "Created On",
  "ccmessaging.flowControl.deleteFail": "Failed to delete the flow control configuration.",
  "ccmessaging.flowControl.deleteSelect": "Are you sure you want to delete the selected rule?",
  "ccmessaging.flowControl.deleteSuccess": "The flow control configuration is deleted successfully.",
  "ccmessaging.flowControl.description": "Description",
  "ccmessaging.flowControl.enable": "Enable",
  "ccmessaging.flowControl.limitNum": "Max. Messages",
  "ccmessaging.flowControl.name": "Flow Control Name",
  "ccmessaging.flowControl.operate": "Operation",
  "ccmessaging.flowControl.periodType": "Period Range",
  "ccmessaging.flowControl.periodType.day": "Daily",
  "ccmessaging.flowControl.periodType.month": "Monthly",
  "ccmessaging.flowControl.periodType.time": "Customization",
  "ccmessaging.flowControl.periodType.week": "Weekly",
  "ccmessaging.flowControl.periodValue": "Day",
  "ccmessaging.flowControl.period.select": "Period Selection",
  "ccmessaging.flowControl.selectStop": "Disable the flow control configuration first.",
  "ccmessaging.flowControl.selectRule": "Select the flow control configuration.",
  "ccmessaging.flowControl.selectStartTime": "Select the start time.",
  "ccmessaging.flowcontrol.target.deleted": "The flow control object has been deleted. Delete the corresponding flow control rule.",
  "ccmessaging.flowControl.targetId": "FC Object",
  "ccmessaging.flowControl.tipText": "Prompt Message",
  "ccmessaging.flowControl.tipTextInterval": "Prompt Interval",
  "ccmessaging.flowControl.updateFail": "Failed to update the flow control configuration.",
  "ccmessaging.flowControl.updateSuccess": "The flow control configuration is updated successfully.",
  "ccmessaging.flowControl.usage": "Usage",
  "ccmessaging.flowControl.title.create": "Create Flow Control",
  "ccmessaging.flowControl.title.modify": "Edit Flow Control",
  "ccmessaging.flowControl.title.view": "View Flow Control",
  "ccmessaging.webCollaboration.ruleName": "Rule name",
  "ccmessaging.webCollaboration.pageMatchType": "Page Match Type",
  "ccmessaging.webCollaboration.pageMatchConfiguration": "Page Match Config",
  "ccmessaging.webCollaboration.pageElementSelector": "Page Element Selector",
  "ccmessaging.webCollaboration.createTime": "Creation time",
  "ccmessaging.webCollaboration.operation": "Operation",
  "cobrowse.sensitiveinfo.pagematchtype.commonmatch": "Generic Matching",
  "cobrowse.sensitiveinfo.pagematchtype.urlmatch": "Match by URL path",
  "cobrowse.sensitiveinfo.pagematchtype.pageidmatch": "Match by page ID",
  "ccmessaging.message.location.latitude": "Latitude:",
  "ccmessaging.message.location.longitude": "Longitude:",
  "ccmessaging.message.selectEmail.count": "A maximum of 30 emails can be selected at a time.",
  "ccmessaging.message.selectEmail.null": "Select an email.",
  "ccmessaging.message.sendstatus.deliverable": "Sent",
  "ccmessaging.message.sendstatus.pending": "Waiting",
  "ccmessaging.message.sendstatus.read": "Read",
  "ccmessaging.message.sendstatus.undeliverable": "Failed",
  "ccmessaging.message.sendstatus.unread": "Unread",
  "ccmessaging.message.timeerror.laterThanCurTime": "The start time cannot be later than the current time.",
  "ccmessaging.messagecontroll.timeerror.beyondMaxLimit": "The end time cannot be later than the start time 90 days!",
  "ccmessaging.multimedialibrary.cobrowsesite.card.target": "Browse Target:",
  "ccmessaging.multimedialibrary.cobrowsesite.card.title": "The Agent Requests Collaborative Browsing With The User.",
  "ccmessaging.multimedialibrary.tips": "This address is used by the agent to send to the user for web page collaboration. Configure it properly.",
  "ccmessaging.operlogs.emailAttachment.download": "Download email attachments",
  "ccmessaging.operlogs.multimedialibrary.create.richtext": "Add Rich Text",
  "ccmessaging.operlogs.multimedialibrary.modify.richtext": "Modify Rich Text",
  "ccmessaging.operlogs.multimedialibrary.save": "Save multimedia library data configuration",
  "ccmessaging.operlogs.multimedialibrary.save.audio": "Save the data configuration in the multimedia library and upload the audio-type file data.",
  "ccmessaging.operlogs.multimedialibrary.save.emoticons": "Save the data configuration in the multimedia library and upload the emoticon-type file data.",
  "ccmessaging.operlogs.multimedialibrary.save.image": "Save the data configuration in the multimedia library and upload the image-type file data.",
  "ccmessaging.operlogs.multimedialibrary.save.video": "Save the data configuration in the multimedia library and upload the video-type file data.",
  "ccmessaging.operlogs.preview.contract": "Preview Contract, User:",
  "ccmessaging.operlogs.signature.submit": "Submit a signed contract",
  "ccmessaging.operlogs.upload.contract": "Upload Contract ",
  "ccmessaging.pop.org.window.noorg": "Without an OU",
  "ccmessaging.pop.org.window.org": "With an OU",
  "ccmessaging.ruleconfig.add": "Add",
  "ccmessaging.ruleconfig.all": "All channels",
  "ccmessaging.ruleconfig.applicationChannel": "Channel",
  "ccmessaging.ruleconfig.content": "Content",
  "ccmessaging.ruleconfig.contentNotNull": "The content cannot be empty.",
  "ccmessaging.ruleconfig.createdTime": "Creation Time",
  "ccmessaging.ruleconfig.dateEnd": "End Time",
  "ccmessaging.ruleconfig.dateStart": "Start Time",
  "ccmessaging.ruleconfig.deleteFail": "Failed to delete the rule.",
  "ccmessaging.ruleconfig.deleteSelectRule": "Are you sure you want to delete the selected rule?",
  "ccmessaging.ruleconfig.deleteSuccess": "Rule deleted successfully.",
  "ccmessaging.ruleconfig.duplicateName": "The rule name is duplicate.",
  "ccmessaging.ruleconfig.effective": "Effective",
  "ccmessaging.ruleconfig.emailaddress.error": "The following addresses are prohibited from sending:",
  "ccmessaging.ruleconfig.emailAddressExists": "The email address already exists",
  "ccmessaging.ruleconfig.emailAdress": "Email Address",
  "ccmessaging.ruleconfig.emailChannel": "Email Channel",
  "ccmessaging.ruleconfig.ID": "ID",
  "ccmessaging.ruleconfig.invalidation": "Invalidation",
  "ccmessaging.ruleconfig.invalidEmailAddress": "Invalid email address",
  "ccmessaging.ruleconfig.invalidSMSNumber": "Invalid mobile number.",
  "ccmessaging.ruleconfig.maxEmailAddressCount": "A maximum of 10 email addresses can be entered in the content",
  "ccmessaging.ruleconfig.maxSMSNumberCount": "A maximum of 10 mobile numbers can be added.",
  "ccmessaging.ruleconfig.maxValidityPeriod": "The validity period is set to the maximum.",
  "ccmessaging.ruleconfig.ruleContentKeyword": "Rule Content Keyword",
  "ccmessaging.ruleconfig.ruleDescribe": "Rule Description",
  "ccmessaging.ruleconfig.ruleName": "Rule Name",
  "ccmessaging.ruleconfig.selectRule": "Please select a rule",
  "ccmessaging.ruleconfig.SMSChannel": "SMS Channel",
  "ccmessaging.ruleconfig.SMSNumber": "Mobile Number",
  "ccmessaging.ruleconfig.SMSNumberExists": "The mobile number exists.",
  "ccmessaging.ruleconfig.SMStips1": `The mobile number can contain wildcards. "\\*" indicates that zero to multiple characters are matched. "\\?" indicates that zero or one character is matched.`,
  "ccmessaging.ruleconfig.SMStips2": `For example, the value "\\*9988" indicates that SMS messages sent from all mobile numbers ending with "9988" are rejected.`,
  "ccmessaging.ruleconfig.SMStips3": `For example, the value "132\\*" indicates that SMS messages sent from all mobile numbers starting with "132" are rejected.`,
  "ccmessaging.ruleconfig.SMStips4": `The value "\\*" indicates that SMS messages sent from all mobile numbers are blocked. Exercise caution when using this value.`,
  "ccmessaging.ruleconfig.timeerror.dateValidateFailed": "Please select a date later than the current time and earlier than 2038-1-19",
  "ccmessaging.ruleconfig.timeerror.empty": "* The start time and end time cannot be empty.",
  "ccmessaging.ruleconfig.timeerror.endTime": "* The end time cannot be earlier than the current time.",
  "ccmessaging.ruleconfig.timeerror.laterThanEndTime": "* The start time cannot be later than the end time.",
  "ccmessaging.ruleconfig.timeerror.laterThanEndTime2": "The start time cannot be later than the end time.",
  "ccmessaging.ruleconfig.timeerror.startTime": "* The start time cannot be earlier than the current time.",
  "ccmessaging.ruleconfig.tips1": `You can enter wildcards in the email address. "\\*" indicates that 0 or more characters are matched. "\\?" Indicates that 0 to 1 characters are matched.`,
  "ccmessaging.ruleconfig.tips2": `For example. "\\*{'@'}huawei.com" indicates that all emails whose domain name is "huawei.com" are rejected.`,
  "ccmessaging.ruleconfig.tips3": `Content text box except "\\*" "\\?" The email address rules must be met.`,
  "ccmessaging.ruleconfig.tips4": `"\\*{'@'}\\*.com" will block all mails with the domain name ".com". Exercise caution when using this command.`,
  "ccmessaging.ruleconfig.validityPeriod": "Validity Period",
  "ccmessaging.ruleconfig.validityPeriodDay": "Access Token Validity Period (Days)",
  "ccmessaging.ruleconfig.duplicateEmailAddress": "Duplicate email address.",
  "ccmessaging.ruleconfig.duplicateSmsAddress": "Duplicate mobile number.",
  "ccmessaging.socialPostOperation.button.approve": "Approval",
  "ccmessaging.socialPostOperation.button.batchDelete": "Batch Delete",
  "ccmessaging.socialPostOperation.button.cancel": "Withdrawal",
  "ccmessaging.socialPostOperation.button.channelConfig": "Channel Settings",
  "ccmessaging.socialPostOperation.button.creatPost": "Post",
  "ccmessaging.socialPostOperation.button.edit": "Edit",
  "ccmessaging.socialPostOperation.button.delete": "Delete",
  "ccmessaging.socialPostOperation.button.saveDraft": "Save as Draft",
  "ccmessaging.socialPostOperation.button.upload": "Upload",
  "ccmessaging.socialPostOperation.ceratePageTitle": "account operation/posting",
  "ccmessaging.socialPostOperation.edit.approveInformation": "Approval Info",
  "ccmessaging.socialPostOperation.edit.basicInformation": "Basic Information",
  "ccmessaging.socialPostOperation.edit.must": "Required",
  "ccmessaging.chat.channelconfig.checkConfirmTokenEqualToToken": "You must input the same value",
  "ccmessaging.socialPostOperation.edit.placeholder": "Please enter",
  "ccmessaging.socialPostOperation.edit.publishInformation": "Release Information",
  "ccmessaging.socialPostOperation.facebook.publishAt.tips": "must be published within 20 minutes to 75 days after being created.",
  "ccmessaging.socialPostOperation.facebook.tips.emptyPost": "Facebook cannot post blank posts. Please select at least one item (content, picture, or video).",
  "ccmessaging.socialPostOperation.field.applicationType": "Application Type",
  "ccmessaging.socialPostOperation.field.applicationType.create": "Post",
  "ccmessaging.socialPostOperation.field.approveComment": "Approval Comments",
  "ccmessaging.socialPostOperation.field.approverName": "Approver",
  "ccmessaging.socialPostOperation.field.approveStatus": "Approval Result",
  "ccmessaging.socialPostOperation.field.approveStatus.pass": "Passed",
  "ccmessaging.socialPostOperation.field.approveStatus.rejected": "Reject",
  "ccmessaging.socialPostOperation.field.channelName": "Channel Name",
  "ccmessaging.socialPostOperation.field.content": "Contents",
  "ccmessaging.socialPostOperation.field.createUserName": "Applicant",
  "ccmessaging.socialPostOperation.field.image": " Image",
  "ccmessaging.socialPostOperation.field.operation": "Operation",
  "ccmessaging.socialPostOperation.field.postConfigInfo": "Channel Information",
  "ccmessaging.socialPostOperation.field.postType": "Release Channel",
  "ccmessaging.socialPostOperation.field.publishAt": "Release Time",
  "ccmessaging.socialPostOperation.field.publishChannel": "Release a channel",
  "ccmessaging.socialPostOperation.field.publishStatus": "Release Status",
  "ccmessaging.socialPostOperation.field.publishStatus.published": "published",
  "ccmessaging.socialPostOperation.field.publishStatus.unpublished": "Unpublished",
  "ccmessaging.socialPostOperation.field.publishTime": "Estimated Release Time",
  "ccmessaging.socialPostOperation.field.publishTimeSet": "Release Time Settings",
  "ccmessaging.socialPostOperation.field.publishTyle.now": "Real-Time Release",
  "ccmessaging.socialPostOperation.field.publishTyle.time": "Scheduled Release",
  "ccmessaging.socialPostOperation.field.publishType": "Publication Type",
  "ccmessaging.socialPostOperation.field.remark": "Remarks",
  "ccmessaging.socialPostOperation.field.status": "Status",
  "ccmessaging.socialPostOperation.field.status.approved": "Completed",
  "ccmessaging.socialPostOperation.field.status.draft": "Draft",
  "ccmessaging.socialPostOperation.field.status.toApproved": "to be approved",
  "ccmessaging.socialPostOperation.field.title": "Theme",
  "ccmessaging.socialPostOperation.field.video": " Video",
  "ccmessaging.socialPostOperation.res.recall": "Withdrawal succeeded",
  "ccmessaging.socialPostOperation.search.searchTitle": "Subject",
  "ccmessaging.socialPostOperation.select.pleaseChoose": "Please select",
  "ccmessaging.socialPostOperation.tab.history": "Approval History",
  "ccmessaging.socialPostOperation.tips.approveSet": "Please check the approver configuration item.",
  "ccmessaging.socialPostOperation.tips.approveSuccess": "Approval succeeded",
  "ccmessaging.socialPostOperation.tips.approvingDelete": "Data in the To-Be-Approved state cannot be deleted",
  "ccmessaging.socialPostOperation.tips.cancelError": "Non-to-Approval Data Cannot Be Revoked",
  "ccmessaging.socialPostOperation.tips.cannotDel": "Posts to be approved cannot be deleted.",
  "ccmessaging.socialPostOperation.tips.delConfirm": "Are you sure you want to delete the selected data?",
  "ccmessaging.socialPostOperation.tips.deleteApproveSuccess": "Deletion submitted successfully. Waiting for approval.",
  "ccmessaging.socialPostOperation.tips.deleteNoApprove": "approver is empty and will be deleted directly after submission. Are you sure you want to continue?",
  "ccmessaging.socialPostOperation.tips.fileTip": "The file size exceeds the upper limit. The maximum size is {n} MB.",
  "ccmessaging.socialPostOperation.tips.imageNumTip": "A maximum of 9 images can be uploaded.",
  "ccmessaging.socialPostOperation.tips.imageType": "The supports the PNG, JPG, GIF, BMP, and TIFF formats.",
  "ccmessaging.socialPostOperation.tips.imgAndVideoChoose": "images and videos cannot be uploaded at the same time.",
  "ccmessaging.socialPostOperation.tips.postTypesChoose": "Select at least one release channel",
  "ccmessaging.socialPostOperation.tips.publishFailed": "Failed to publish.",
  "ccmessaging.socialPostOperation.tips.saveApproveSuccess": "Submitted successfully. Waiting for approval.",
  "ccmessaging.socialPostOperation.tips.submit": "The approver is empty. It will be published directly after submission. Are you sure you want to continue?",
  "ccmessaging.socialPostOperation.tips.submitSuccess": "Submit success",
  "ccmessaging.socialPostOperation.tips.titleError": "cannot contain < and >",
  "ccmessaging.socialPostOperation.tips.uploadVideo": "Please upload a video file",
  "ccmessaging.socialPostOperation.tips.videoType": "The supports the MP4 format.",
  "ccmessaging.socialPostOperation.tips.youTubeFileChoose": "Only videos can be uploaded through the Youtube channel.",
  "ccmessaging.socialPostOperation.youTube.addLabel": " Add Tag",
  "ccmessaging.socialPostOperation.youTube.audience": " Audience",
  "ccmessaging.socialPostOperation.youTube.audienceRule": " is required by law to comply with the Children's Online Privacy Protection Act (COPPA) and/or other laws wherever you are. You must indicate if your video is intended for children.",
  "ccmessaging.socialPostOperation.youTube.category": " Category",
  "ccmessaging.socialPostOperation.youTube.category.car": "Autos & Vehicle",
  "ccmessaging.socialPostOperation.youTube.category.Comedy": "Comedy",
  "ccmessaging.socialPostOperation.youTube.category.education": "Education",
  "ccmessaging.socialPostOperation.youTube.category.entertainment": "Entertainment",
  "ccmessaging.socialPostOperation.youTube.category.film": "Film & Animation",
  "ccmessaging.socialPostOperation.youTube.category.gaming": "Gaming",
  "ccmessaging.socialPostOperation.youTube.category.howto": "Howto & Style",
  "ccmessaging.socialPostOperation.youTube.category.music": "Music",
  "ccmessaging.socialPostOperation.youTube.category.news": "News & Politics",
  "ccmessaging.socialPostOperation.youTube.category.nonprofits": "Nonprofits & Activism",
  "ccmessaging.socialPostOperation.youTube.category.people": "People & Blogs",
  "ccmessaging.socialPostOperation.youTube.category.pets": "Pets & Animal",
  "ccmessaging.socialPostOperation.youTube.category.science": "Science & Technology",
  "ccmessaging.socialPostOperation.youTube.category.shortMovies": "Short Movies",
  "ccmessaging.socialPostOperation.youTube.category.sport": "Sports",
  "ccmessaging.socialPostOperation.youTube.category.travel": "Travel & Events",
  "ccmessaging.socialPostOperation.youTube.categoryDetail": " Add your VOD to a category so the viewer can find it",
  "ccmessaging.socialPostOperation.youTube.disclosureScope": " Public Scope",
  "ccmessaging.socialPostOperation.youTube.disclosureScopeDetail": " Select who can see your video",
  "ccmessaging.socialPostOperation.youTube.embedding": " Allow embedding",
  "ccmessaging.socialPostOperation.youTube.embedding.tips": "allows others to embed your videos in their websites.",
  "ccmessaging.socialPostOperation.youTube.knowledgePermission": " Creative Commons - Attribution",
  "ccmessaging.socialPostOperation.youTube.labels": " Label",
  "ccmessaging.socialPostOperation.youTube.labelsDetail": " Tags can help viewers find your video if there are easily misspelled words in the video content. Other than that, tags are not very useful for finding videos.",
  "ccmessaging.socialPostOperation.youTube.noKids": " Not for children",
  "ccmessaging.socialPostOperation.youTube.normalPermission": " Standard YouTube License",
  "ccmessaging.socialPostOperation.youTube.permissions": " License",
  "ccmessaging.socialPostOperation.youTube.permissionsKnow": " Understand license types.",
  "ccmessaging.socialPostOperation.youTube.scopePrivate": " Private",
  "ccmessaging.socialPostOperation.youTube.scopePublic": " Public",
  "ccmessaging.socialPostOperation.youTube.scopeUnlisted": " Not publicly listed",
  "ccmessaging.socialPostOperation.youTube.tags.tips": "Enter a comma after each label",
  "ccmessaging.socialPostOperation.youTube.videoToKids": " Is this video for children?",
  "ccmessaging.socialPostOperation.youTube.view.commentNum": "14 comments",
  "ccmessaging.socialPostOperation.youTube.view.dataResolve": "Data Analysis",
  "ccmessaging.socialPostOperation.youTube.view.editVideo": "Edit Video",
  "ccmessaging.socialPostOperation.youTube.view.lookNum": "38 views 2 days ago",
  "ccmessaging.socialPostOperation.youTube.view.orderType": "Sorting Mode",
  "ccmessaging.socialPostOperation.youTube.view.share": "Sharing",
  "ccmessaging.socialPostOperation.youTube.view.subscriber": "1 Subscribers",
  "ccmessaging.socialPostOperation.youTube.view.unfolding": "Expand",
  "ccmessaging.socialPostOperation.youTube.yesKids": " Yes, the content is for children",
  "ccmessaging.socialPostOperation.youTube.publishAtTips": "This setting is only possible if the public scope is set to Private",
  "ccmessaging.socialPostOperation.facebook.threeDay": "3 days",
  "ccmessaging.socialPostOperation.facebook.awful": "Awful",
  "ccmessaging.socialPostOperation.facebook.comment": "Comment",
  "ccmessgaing.chat.web.channel.Agent": "Audio and video agent",
  "ccmessgaing.chat.web.channel.ivr": "IVR",
  "ccmessgaing.chat.web.channel.webrtc.share": "Desktop sharing involves privacy display. Please confirm.",
  "ccmessgaing.chatbot.message.female": "Female",
  "ccmessgaing.chatbot.message.male": "Male",
  "ccmessgaing.postChannelConfig.accessToken": "accessToken",
  "ccmessgaing.postChannelConfig.add": "Adding a channel",
  "ccmessgaing.postChannelConfig.apiFabricAK": "ApiFabricAK",
  "ccmessgaing.postChannelConfig.apiFabricSK": "ApiFabricSK",
  "ccmessgaing.postChannelConfig.apiKey": "apiKey",
  "ccmessgaing.postChannelConfig.appId": "Client ID",
  "ccmessgaing.postChannelConfig.appKey": "appKey",
  "ccmessgaing.postChannelConfig.cancel": "Cancel",
  "ccmessgaing.postChannelConfig.channelConfig": "Configure Channel",
  "ccmessgaing.postChannelConfig.channelCreate": "Creating channel",
  "ccmessgaing.postChannelConfig.channelEdit": "Edit Channel Configuration",
  "ccmessgaing.postChannelConfig.channelType": "Channel Configuration",
  "ccmessgaing.postChannelConfig.confirm": "OK",
  "ccmessgaing.postChannelConfig.addUsers": "add",
  "ccmessgaing.postChannelConfig.createFailed": "Failed to create the configuration information about the social multimedia channel.",
  "ccmessgaing.postChannelConfig.createSuccess": "Succeeded in creating the configuration information of the social multimedia channel.",
  "ccmessgaing.postChannelConfig.delete": "delete",
  "ccmessgaing.postChannelConfig.deleteFailed": "Failed to delete the configuration information about the social multimedia channel.",
  "ccmessgaing.postChannelConfig.deleteFailed.db": "Deletion failed. Database operation failed.",
  "ccmessgaing.postChannelConfig.deleteFailed.permission": "Deletion failed. Permission check failed.",
  "ccmessgaing.postChannelConfig.deleteFailed.inUse": "Deletion failed. The channel has release information.",
  "ccmessgaing.postChannelConfig.deleteSuccess": "Succeeded in delete the configuration information of the social multimedia channel.",
  "ccmessgaing.postChannelConfig.edit": "edit",
  "ccmessgaing.postChannelConfig.editFailed": "Update failed. Check the form parameters and network connection.",
  "ccmessgaing.postChannelConfig.editSuccess": "Succeeded in updating the configuration information of the social multimedia channel.",
  "ccmessgaing.postChannelConfig.failed": "Failed",
  "ccmessgaing.postChannelConfig.maxLength": "The maximum length is {n} characters.",
  "ccmessgaing.postChannelConfig.operation": "Operation",
  "ccmessgaing.postChannelConfig.operation.person": "Operation personnel",
  "ccmessgaing.postChannelConfig.placeSelectChannelType": "Select a channel type",
  "ccmessgaing.postChannelConfig.postChannelName": "Channel Name",
  "ccmessgaing.postChannelConfig.refreshToken": "refreshToken",
  "ccmessgaing.postChannelConfig.users.overSize": "The number of operation personnel exceeds 10.",
  "ccmessgaing.postChannelConfig.repeatedly": "The channel already exists.",
  "ccmessgaing.postChannelConfig.selectByChannelName": "Channel Name",
  "ccmessgaing.postChannelConfig.subChannelId": "Channel ID",
  "ccmessgaing.postChannelConfig.success": "Success",
  "ccmessgaing.postChannelConfig.unauthorized": "The operation is not authenticated.",
  "chat.agent.chatOnlineWorkbench": "Online Chat Workbench",
  "chat.agent.label.balance": "Income and Expense",
  "chat.agent.label.billDetail": "Bill Details",
  "chat.agent.label.chatBot": "Chatbot",
  "chat.agent.label.consumption": "My Consumption",
  "chat.agent.label.customerSource": "Customer source: Mobile App - Personal Center",
  "chat.agent.label.downagainfile": "Download Again",
  "chat.agent.label.goAway": "Away",
  "chat.agent.label.historicalBill": "Historical Bill",
  "chat.agent.label.money": "Amount",
  "chat.agent.label.mybill": "My Bills",
  "chat.agent.label.offline": "Offline",
  "chat.agent.label.online": "Online",
  "chat.agent.label.recivefile": "Receive",
  "chat.agent.label.refusefile": "Refuse",
  "chat.agent.label.send": "Send",
  "chat.agent.label.total": "Total",
  "chat.agent.label.waiting": "Wait",
  "chat.agent.message.changeFailed": "Failed to change the agent status.",
  "chat.agent.title.changeWorkMode": "change workmode",
  "chat.agent.title.close": "Close",
  "chat.agent.title.createCase": "Create Case",
  "chat.agent.title.createSkill": "Create Skill",
  "chat.agent.title.enterText": "Please enter",
  "chat.agent.title.OfflineCall": "Offline call",
  "chat.agent.title.transfer": "Transfer To",
  "chat.agent.title.userInformation": "Customer Info",
  "chat.agent.title.userMenu": "Customer Menu",
  "chat.agent.title.workMode": "workmode",
  "chat.agentConsole.message.content.audio": "[Voice Message]",
  "chat.agentConsole.message.content.connectMessage": "A new message!",
  "chat.agentConsole.message.content.image": "[Image Message]",
  "chat.agentConsole.message.content.locate": "[Location Message]",
  "chat.agentConsole.message.content.video": "[Video Message]",
  "chat.agentConsole.message.error.emptyMessage": "[The message contains no content that can be displayed.]",
  "chat.agentConsole.message.error.notification": "The new message notification cannot be created. Please check the browser configuration!",
  "chat.client.alert.message.invildtoken": "Your dialog timed out or you have signed in elsewhere. Please close the chat window and try again. Thank you!",
  "chat.client.button.submit": "Submit",
  "chat.client.evaluate.message": "Please comment on my service. Thank you!",
  "chat.client.greeting.afternoon": "Good afternoon!",
  "chat.client.greeting.dear": "Dear",
  "chat.client.greeting.evening": "Good evening!",
  "chat.client.greeting.info": "What can I do for you?",
  "chat.client.greeting.morning": "Good morning!",
  "chat.client.label.accountNumber": "Account",
  "chat.client.label.bill": "My Bills",
  "chat.client.label.chatTitle": "Client",
  "chat.client.label.download": "Download",
  "chat.client.label.evaluation": "Satisfaction Survey",
  "chat.client.label.forgotPassword": "Forgot Password",
  "chat.client.label.header": "Telco+",
  "chat.client.label.input": "Please enter",
  "chat.client.label.like": "You May Like",
  "chat.client.label.login": "Sign In",
  "chat.client.label.loginTitle": "Sign In",
  "chat.client.label.more": "More",
  "chat.client.label.no": "No",
  "chat.client.label.order": "My Orders",
  "chat.client.label.password": "Password",
  "chat.client.label.privacyHint": "Warning for Privacy Protection",
  "chat.client.label.privacyHintContent": "To improve service quality, your conversation will be recorded by the system. Are you sure you want to continue talking?",
  "chat.client.label.promotions": "Prompt",
  "chat.client.label.rateService": "Score Service",
  "chat.client.label.system": "The system is busy. Please try again later.",
  "chat.client.label.yes": "Yes",
  "chat.client.message.evaluationFail": "The satisfaction survey fails.",
  "chat.client.message.evaluationSuccess": "The satisfaction survey is successful.",
  "chat.client.message.gladtoServe": ". What can I do for you?",
  "chat.client.message.hiMyWorkno": "Hello! This is ",
  "chat.client.message.noAgent": "Sorry, the agent is busy. Try again later.",
  "chat.client.transfer.link": "Are you sure you want to transfer the call to an agent?",
  "chat.common.message.cancel": "Cancel",
  "chat.common.message.create": "Create",
  "chat.common.message.dateEnd": "End Time",
  "chat.common.message.dateStart": "Start Time",
  "chat.common.message.delete": "Delete",
  "chat.common.message.error": "Error",
  "chat.common.message.fail": "Failure",
  "chat.common.message.finish": "OK",
  "chat.common.message.modify": "Modify",
  "chat.common.message.promotions": "Information",
  "chat.common.message.success": "Success",
  "chat.common.message.warning": "Warning",
  "chat.confirm.no": "No",
  "chat.confirm.ok": "OK",
  "chat.confirm.yes": "Yes",
  "chat.session.channel.all": "All",
  "chat.session.channel.facebook": "Facebook",
  "chat.session.channel.mobile": "Mobile terminal",
  "chat.session.channel.twitter": "Twitter",
  "chat.session.channel.web": "Web",
  "chat.session.channel.wechat": "WeChat",
  "chat.session.endType.agentEnd": "End by agent",
  "chat.session.endType.customerEnd": "End by customer",
  "chat.session.endType.overtimeEnd": "End due to timeout",
  "chat.session.label.alias": "Customer Name",
  "chat.session.label.allChannel": "All Channel Types",
  "chat.session.label.allEndType": "All End Types",
  "chat.session.label.channel": "Dialog Access Channel",
  "chat.session.label.created_endTime": "End Time",
  "chat.session.label.created_startTime": "Start Time",
  "chat.session.label.createdTime": "Dialog Creation Time",
  "chat.session.label.endReason": "Dialog End Cause",
  "chat.session.label.endTime": "Dialog End Time",
  "chat.session.label.endType": "Dialog End Type",
  "chat.session.label.enterQueueTime": "Queue-in Time",
  "chat.session.label.evaluation": "Dialog Evaluation",
  "chat.session.label.evaluationTime": "Dialog Evaluation Time",
  "chat.session.label.exitQueueTime": "Queue-out Time",
  "chat.session.label.remark": "Comment",
  "chat.session.label.reset": "Reset",
  "chat.session.label.search": "Search",
  "chat.session.label.servedAgent": "Service Agent",
  "chat.session.label.sessionId": "Dialog ID",
  "chat.session.label.skillQueueId": "Skill Queue ID",
  "chat.session.label.skillQueueName": "Skill Queue Name",
  "chat.session.label.subChannel": "Sub Access Channel",
  "chat.session.title.basic": "Basic Dialog Info",
  "chat.session.title.detail": "Dialog Details",
  "chat.session.title.history": "Historical Chat Records",
  "chat.skill.label.account": "Account",
  "chat.skill.label.agents": "Agent",
  "chat.skill.label.cancel": "Cancel",
  "chat.skill.label.createSkill": "Create",
  "chat.skill.label.createSkillTab": "Create Skill",
  "chat.skill.label.delete": "Delete",
  "chat.skill.label.deleteSkills": "Delete",
  "chat.skill.label.description": "Description",
  "chat.skill.label.edit": "Edit",
  "chat.skill.label.inputName": "Name",
  "chat.skill.label.maxQueueNumber": "Max. Queue Number",
  "chat.skill.label.maxQueueTime": "Max. Queue Time (s)",
  "chat.skill.label.maxQueueTimeInTable": "Max. Queue Time",
  "chat.skill.label.name": "User Name",
  "chat.skill.label.ok": "OK",
  "chat.skill.label.priority": "Priority",
  "chat.skill.label.reset": "Reset",
  "chat.skill.label.save": "Save",
  "chat.skill.label.skill": "Skill-",
  "chat.skill.label.skillDetail": "Skill Details",
  "chat.skill.label.skillId": "No.",
  "chat.skill.label.skillName": "Name",
  "chat.skill.label.submit": "Submit",
  "chat.skill.message.completeInputs": "Please complete all input.",
  "chat.skill.message.createFail": "Failed to create the skill.",
  "chat.skill.message.createSuccess": "Created the skill successfully.",
  "chat.skill.message.delete": "Delete",
  "chat.skill.message.error": "Error",
  "chat.skill.message.failDeleteSkill": "Failed to delete the skill.",
  "chat.skill.message.isExisted": "The Skill Queue already exists.",
  "chat.skill.message.priorityLength": "Ensure that the priority is less than or equal to five digits.",
  "chat.skill.message.saveFail": "Failed to save the skill.",
  "chat.skill.message.saveSuccess": "Saved the skill successfully.",
  "chat.skill.message.selectSkills": "Select a skill.",
  "chat.skill.message.success": "Success",
  "chat.skill.message.warning": "Warning",
  "chat.skill.message.willDeleteAllSkills": "Are you sure you want to delete all skills?",
  "chat.skill.message.willDeleteSkill": "Are you sure you want to delete the skill?",
  "chat.skill.message.willDeleteSkills": "Are you sure you want to delete the selected skills?",
  "chat.thirdportal.chatWindow.button.startChat": "Start Chat",
  "chat.thirdportal.chatWindow.label.userEmail": "Email",
  "chat.thirdportal.chatWindow.label.userName": "Name",
  "chat.thirdportal.chatWindow.label.userPhone": "Number",
  "chat.thirdportal.title": "Customer Web Portal",
  "chat.timerange.label.alldays": "All Creation Time",
  "chat.timerange.label.confirmSocailChatNum": "The maximum number of concurrent service customers is 0 will use the global configuration",
  "chat.timerange.label.customizes": "Customized",
  "chat.timerange.label.ok": "OK",
  "chat.timerange.label.oneday": "Last day",
  "chat.timerange.label.sevendays": "Last 7 days",
  "chat.timerange.label.thirtydays": "Last 30 days",
  "chat.timerange.label.threedays": "Last 3 days",
  "chat.timerange.message.timeempty": "The time cannot be empty.",
  "chat.timerange.message.timeerror": "The format of the time is incorrect.",
  "chat.usefulexpressions.label.createExpressionTab": "Create Common Phrase",
  "chat.usefulexpressions.label.expression": "Common Phrases",
  "chat.usefulexpressions.label.expressionDetail": "Common Phrase Details",
  "chat.usefulexpressions.message.contentLength": "Ensure that the length of the common phrase is less than or equal to 1000 characters.",
  "chat.usefulexpressions.message.contentNotNull": "The common phrase cannot be empty.",
  "chat.usefulexpressions.message.createFail": "Failed to create the common phrase.",
  "chat.usefulexpressions.message.createSuccess": "Created the common phrase successfully.",
  "chat.usefulexpressions.message.failDeleteExpression": "Failed to delete the common phrase.",
  "chat.usefulexpressions.message.isExisted": "The common phrase name already exists.",
  "chat.usefulexpressions.message.saveFail": "Failed to save the common phrase.",
  "chat.usefulexpressions.message.saveSuccess": "Saved the common phrase successfully.",
  "chat.usefulexpressions.message.selectExpressions": "Select a common phrase.",
  "chat.usefulexpressions.message.willDeleteExpression": "Are you sure you want to delete the common phrase?",
  "chat.usefulexpressions.message.willDeleteExpressions": "Are you sure you want to delete the selected common phrase?",
  "chat.web.evaluation.star1": "Very dissatisfied",
  "chat.web.evaluation.star2": "Dissatisfied",
  "chat.web.evaluation.star3": "General",
  "chat.web.evaluation.star4": "Satisfied",
  "chat.web.evaluation.star5": "Very satisfied",
  "chat.webconfig.button.save": "Save",
  "chat.webconfig.label.bandingskill": "Bind Skill Queue",
  "chat.webconfig.label.basesettings": "Basic Configuration",
  "chat.webconfig.label.functionalsettings": "Function Settings",
  "chat.webconfig.label.greeting": "Greeting",
  "chat.webconfig.label.greeting.placeholder": "Please enter the content of the greeting that is displayed in the chat window of the client.",
  "chat.webconfig.label.intergratedcode": "Integrated Code",
  "chat.webconfig.label.intergratemsg": "Integrate Live Chat to Your Website",
  "chat.webconfig.label.language": "Default Language",
  "chat.webconfig.label.language.en": "English",
  "chat.webconfig.label.language.zh": "Chinese",
  "chat.webconfig.label.language.pt": "Portuguese",
  "chat.webconfig.label.transHuman": "Transfer to Agent",
  "chat.webconfig.label.windowTitle": "Chat Window Title",
  "chat.webconfig.label.windowTitle.placeholder": "Please enter the title that is displayed in the chat window on the client.",
  "chat.webconfig.message.configdirective": "Copy the following code and paste it before your website label </body>. Save and release the code to complete the quick access to Huawei Service Cloud. After the release, the Huawei Service Cloud entry is displayed in the lower right corner of your website. You can click it to talk with the customer service workbench in real time.",
  "chat.webconfig.message.demodirective": "You can also open the following address in the hidden window of the Chrome browser to access the presentation page provided by the system:",
  "chat.webconfig.message.saveFail": "Failed to save the configuration.",
  "chat.webconfig.message.saveSuccess": "Saved the configuration successfully.",
  "chat.webconfig.title": "Enable Web Channel",
  "chat.weChatConfig.label.appId": "Developer ID",
  "chat.weChatConfig.label.AppSecret": "Developer Password",
  "chat.weChatConfig.label.bindSkill": "Bind Skill Queue",
  "chat.weChatConfig.label.codeName": "Channel Access Code",
  "chat.weChatConfig.label.configId": "Configuration ID",
  "chat.weChatConfig.label.create": "New",
  "chat.weChatConfig.label.delete": "Delete",
  "chat.weChatConfig.label.Domain": "Server Address",
  "chat.weChatConfig.label.facebook": "Facebook",
  "chat.weChatConfig.label.nextStep": "Next",
  "chat.weChatConfig.label.qcode": "QR code",
  "chat.weChatConfig.label.save": "Save",
  "chat.weChatConfig.label.search": "Search",
  "chat.weChatConfig.label.secret": "Key",
  "chat.weChatConfig.label.state": "Status",
  "chat.weChatConfig.label.tenantId": "Tenant ID",
  "chat.weChatConfig.label.verify": "Verification",
  "chat.weChatConfig.label.verifycode": "Token",
  "chat.weChatConfig.label.verifying": "Verifying",
  "chat.weChatConfig.label.wechat": "WeChat",
  "chat.weChatConfig.link.wechat": "WeChat Server",
  "chat.weChatConfig.message.appIdExisted": "The entered public account developer ID already exists.",
  "chat.weChatConfig.message.codeNameExisted": "The channel access code already exists.",
  "chat.weChatConfig.message.deleteError": "Failed to delete the configuration item.",
  "chat.weChatConfig.message.emptyDeleteArray": "Select the configuration item to be deleted.",
  "chat.weChatConfig.message.ensureDelete": "Are you sure you want to delete the selected configuration item?",
  "chat.weChatConfig.message.error": "Error",
  "chat.weChatConfig.message.savesuccess": "Accessed successfully.",
  "chat.weChatConfig.message.skillExisted": "The selected skill queue has been bound to another public account.",
  "chat.weChatConfig.message.unknown": "The verification status is unknown.",
  "chat.weChatConfig.stepname.selectchannel": "Select Access Channel",
  "chat.weChatConfig.stepname.selectmode": "Select Configuration Method",
  "chat.weChatConfig.stepname.success": "Access Success",
  "chat.weChatConfig.stepname.verify": "Submit for Verification",
  "chat.weChatConfig.title": "Social Media Enabling Configuration",
  "chat.weChatConfig.title.deleteTitle": "Delete WeChat Configuration Items",
  "cmessaging.chat.answerUser.error": "Failed to create a user session.",
  "cmessaging.chat.answerUser.Tips": "Creating a User Session",
  "cmessaging.chat.channelconfig.isPushWebMessage": "Message Push",
  "cmessaging.chat.channelconfig.pushWebMessageKey": "Key",
  "cmessaging.chat.channelconfig.pushWebMessageUrl": "Message Push Address",
  "cmessaging.chat.channelconfig.pushWebCertTypeSignature": "Signature Authentication",
  "cmessaging.chat.channelconfig.pushWebCertTypeOAuth": "OAuth Authentication",
  "cmessaging.chat.channelconfig.setAgentTimeout": "Session Transfer Due to No Agent Reply",
  "cmessaging.chat.multimedia.numOverMaxSum": "The amount of multimedia data you saved exceeds the limit ",
  "cmessaging.chat.multimediatype.numOverMaxSum": "The amount of multimedia categories you saved exceeds the limit ",
  "ccmessaging.socialPostOperation.search.searchContent": "Please enter the content.",
  "ccmessaging.socialPostOperation.link": "Visit the original text",
  "ccmessaging.chat.social.media.enterprise.post.load.failed": "Failed to load the attachment.",
  "ccmessaging.chat.multimedia.imageUploadChannelFormat": "The WhatsApp, web, Telegram, LINE, WeChat, Facebook, and Twitter channels support the JPG, PNG, and JPEG formats.",
  "ccmessaging.chat.multimedia.audioUploadChannelFormat": "The WhatsApp channel supports the ACC format.\nThe web channel supports the MP3 and AAC formats.\nThe Instagram and LINE channels support the M4A format.\nThe WeChat and Facebook channels support the MP3 format.\nThe Telegram channel supports the MP3 and M4A formats.",
  "ccmessaging.chat.multimedia.documentUploadChannelFormat": "The WhatsApp channel supports the DOC, DOCX, PDF, PPT, PPTX, XLSX, and XLS formats.\nThe web channel supports the DOC, DOCX, PDF, and ZIP formats.\nThe Telegram channel supports the PDF, and ZIP formats.",
  "ccmessaging.chat.channelconfig.searchChannelName": " Search Channel Name",
  "ccmessaging.chat.channelconfig.createChannel": " Create Channel",
  "ccmessaging.chat.channelconfig.modifyChannel": " Modify Channel",
  "ccmessaging.chat.channelconfig.channelIntegrate": " Channel Integration",
  "ccmessaging.chat.channelconfig.baseConfig": " Basic Configuration",
  "ccmessaging.chat.channelconfig.robotConfig": " Robot Configuration",
  "ccmessaging.chat.channelconfig.chooseChannel": " Channel Selection",
  "ccmessaging.chat.channelconfig.range.placeholder": "{0}-{1}",
  "ccmessaging.chat.channelconfig.advanceConfig": "More",
  "ccmessaging.chat.channelconfig.web.clickToCall": "Click To Call",
  "ccmessaging.chat.channelconfig.time.timeNoRange": "In Period {n}, the start time must be earlier than the end time.",
  "ccmessaging.chat.channelconfig.time.timeCross": "Period {0} overlaps with Period {1}.",
  "ccmessaging.chat.channelconfig.time.timePeriod0": "Please fill in Period {n}",
  "ccmessaging.chat.channelconfig.time.timePeriod": "Please fill in or delete Period {n}",
  "ccmessaging.silentagent.button.create": "New",
  "ccmessaging.silentagent.dialog.title.create": "New Voice Silent Agent",
  "ccmessaging.silentagent.dialog.title.edit": "Edit Voice Silent Agent",
  "ccmessaging.silentagent.dialog.title.view": "View Voice Silent Agent",
  "ccmessaging.silentagent.delete.selectone": "Please select at least one record.",
  "ccmessaging.silentagent.delete.select.morethan.100": "A maximum of 100 data records can be deleted at a time.",
  "ccmessaging.silentagent.placeholder.selectaudioskill": "Select a voice skill queue.",
  'ccmessaging.silentagent.btn.add.period': 'add time period',
  'ccmessaging.silentagent.agent.workday.worktime.error': 'The working time configuration of the agent is incorrect. The time segments overlap.',
  'ccmessaging.silentagent.agent.holiday.worktime.error': 'The non-working time configuration of the agent is incorrect. The time segments overlap.',
  'ccmessaging.silentagent.agent.workday.worktime.empty': 'The working time configuration of the agent has empty configuration.',
  'ccmessaging.silentagent.agent.holiday.worktime.empty': 'The non-working time configuration of the agent has empty configuration.',
  "ccmessaging.silentagent.voiceaccess.empty": "The voice IVR called route configuration cannot be empty",
  "ccmessaging.chat.channelconfig.searchCertCode": "Search Certificate Code",
  "ccmessaging.chat.channelconfig.cloudApi.accessToken.tips": "Validity period of the token generated by Meta for Developers for accessing the Cloud API. Enter a validity period two days less than the actual validity period. For example\n, if the actual validity period is 60 days\n, you are advised to set the validity period to 58 days. If this parameter is left blank\n, the token never expires\n, which is prone to leakage. Exercise caution when leaving this parameter blank.",
  "ccmessaging.chat.channelconfig.cloudApi.bsp.tips": "Currently, the WhatsApp Cloud API can be connected to handle only text messages.",
  "ccmessaging.chat.channelconfig.checkWhatsAppAccessTokenDuration": "The value must be an integer ranging from 1 to 60.",
  "ccmessaging.chat.channelconfig.copy": "Copy",
  "ccmessaging.chat.channelconfig.copySuccess": "Copying succeeded.",
  "ccmessaging.chat.sumbitverification.whatsApp.cloudapi.tips": "Go to the WhatsApp page at Meta for Developers to configure the URL and verification code.",
  "ccmessaging.chatbot.label.useIntelligentRobot": "Connecting to the Intelligent Robot",
  "ccmessaging.chatbot.label.useRobotAssistants": "Access Robot Assistant",
  "ccmessaging.chatbot.label.update": "Update",
  "ccmessaging.chatbot.label.pleaseSelect": "Please select",
  "ccmessaging.chatbot.label.pleaseInput": "Please enter",
  "ccmessaging.chatbot.label.intelligentRobotAccessCode": "robot access code",
  "ccmessaging.chatbot.label.selectIntelligentRobot": "Select Intelligent Robot",
  "ccmessaging.chatbot.label.selectRobotAssistants": "Select Robot Assistant",
  "ccmessaging.chatbot.check.systeam": "system",
  "ccmessaging.chatbot.label.robotName": "robot name",
  "ccmessaging.chatbot.label.selectRobotAvatar": "Select robot avatar",
  "ccmessaging.fiveG.label.chatRobotAddress": "Chatbot Address",
  "ccmessaging.fiveG.label.appId": "App ID",
  "ccmessaging.fiveG.label.appSecret": "App Secret",
  "ccmessaging.fiveG.label.certCode": "Certificate File",
  "ccmessaging.email.label.selectGateway": "Select Gateway",
  "ccmessaging.email.label.gatewayName": "Gateway Name",
  "ccmessaging.email.label.InputGatewayName": "Please enter a gateway name",
  "ccmessaging.email.label.defaultReceiveGateway": "Default Email Address",
  "ccmessaging.email.label.defaultSendGateway": "Default Email Address",
  "ccmessaging.sms.label.defaultSendGateway": "Default Sender Number",
  "ccmessaging.sms.label.defaultReceiveGateway": "Default Recipient Number",
  "ccmessaging.email.valid.signatureSearch": "can contain up to 10 letters or numbers.",
  "ccmessaging.service.time.label": "Work Calendar Configuration",
  "ccmessaging.service.time.choose.time": "Select non-working days",
  "ccmessaging.service.time.select.holiday": "Select Rest Dates",
  "ccmessaging.service.time.select.weekday": "Select Work Dates",
  "ccmessaging.multimedia.tip.not.exist": "The multimedia resource does not exist",
  "ccmessaging.multimedia.tip.modified": "The multimedia resource has been modified. Please resend it.",
  "ccmessaging.service.time.close.alert": "Close will erase all data in the table, confirm the closure",
  "ccmessaging.chat.phrase.create": "Create Common Phrases",
  "ccmessaging.chat.phrase.update": "Eidt Common Phrases",
  "ccmessaging.chat.specialChannel.create": "Creating a Special List Rule",
  "ccmessaging.chat.specialChannel.update": "Editing a Special List Rule",
  "ccmessaging.emailAdress.contentNotNull": "The email address cannot be empty.",
  "ccmessaging.smsNumber.contentNotNull": "The mobile number cannot be empty.",
  "ccmessaging.callcenterinstanceslist.vcallcenterDeatil.passValidateFailed": "The password must contain at least three types of the following: uppercase letters, lowercase letters, digits, and following special characters: {n}",
  "ccmessaging.chat.shortcut.shortcutRepeat": "The shortcut key has been used.",
  "ccmessaging.chat.multimedia.agentHangupSessionResult": "Session ended successfully. Please close the CRM work order.",
  "ccmessaging.chat.multimedia.agentAutoTransferResult": "The call is transferred successfully. Please close the CRM work order.",
  "ccmessaging.chat.multimedia.agentTimeOutTransferResult": "The session times out and is transferred to the skill queue. Close the CRM work order.",
  "ccmessaging.chat.multimedia.userHangupSessionResult": "The user proactively hangs up the session. Please close the CRM work order.",
  "ccmessaging.chat.multimedia.userTimeOutHangupResult": "The session times out. Close the CRM work order.",
  "ccmessaging.chat.multimedia.agentLogoutHangupResult": "The agent forcibly logs out. The session ends successfully. Please close the CRM work order.",
  "ccmessaging.chat.dy.reconnect": "Reconnect",
  "ccmessaging.chat.dy.uploadLocalFile": 'Local files',
  "ccmessaging.chat.dy.call.end": 'The session ended.',
  "ccmessaging.chat.richtext.httpTip": "The inserted link uses an insecure protocol, which has security risks. Are you sure you want to submit it?",
  "ccmessaging.chat.riskTip": "Risk warning",
  "ccmessaging.chat.chatcard.location": "Address",
  "ccmessaging.chat.chatcard.alignment": "Card display style",
  "ccmessaging.chat.chatcard.templateType": "template type",
  "ccmessaging.chat.chatcard.templateTypeList0": "Common type",
  "ccmessaging.chat.chatcard.templateTypeList1": "Card options",
  "ccmessaging.chat.chatcard.tabMediaResource": "Image property",
  "ccmessaging.chat.voice.record.send": "Send voice",
  "ccmessaging.chat.voice.record.start": "Click Record",
  "ccmessaging.chat.voice.record.tip": "The recording duration does not exceed 10 min",
  "ccmessaging.chat.voice.record.stop": "Stop recording",
  "ccmessaging.chat.selectorgs.maxlimit.warn": "The number of selected organizations cannot exceed 100.",
  "ccmessaging.chat.chatconfig.deleteFacebookPage": "Delete Facebook Home Page",
  "ccmessaging.chat.search.name": "Search Name",
  "ccmessaging.chat.search.phraseName": "Common Phrases",
  "ccmessaging.chat.chatcard.tabnamevalidate": "redirectUrl is the system default and is not allowed",
  "ccmessaging.chat.chatcard.transverse": "transverse",
  "ccmessaging.chat.chatcard.longitudinal": "longitudinal",
  "ccmessaging.chat.email.attachment.tips.maxOnceUpload": "A maximum of five attachments can be uploaded.",
  "ccmessaging.chat.email.attachment.tips.maxTotalUpload": "The number of uploaded attachments exceeds 10.",
  "ccmessaging.chat.voice.record": "Voice Input",
  "ccmessaging.chat.offline.userIsInService": "Existing agent service users",
  "ccmessaging.chat.channelconfig.search.by.robotNameKeyword": "Buscar el nombre del bot o el código de acceso",
  "ccmessaging.chat.channelconfig.searchSkillQueueName": "Nombre de la cola de habilidades de búsqueda",
  "ccmessaging.chat.user.online": "The user is online.",
  "ccmessaging.chat.async.echarts.user": "Number of persons (times)",
  "ccmessaging.chat.async.echarts.message": "Message (Number)",

  "cobrowse.sessionrecord.roomId": "Room Number",
  "cobrowse.sessionrecord.searchroomId": "Please enter room number",
  "cobrowse.sessionrecord.workNo": "Agent Number",
  "cobrowse.sessionrecord.searchworkNo": "Please enter the agent number",
  "cobrowse.sessionrecord.caller": "Caller",
  "cobrowse.sessionrecord.searchcaller": "Please enter caller information",
  "cobrowse.sessionrecord.startAt": "Starting time",
  "cobrowse.sessionrecord.endAt": "End Time",
  "cobrowse.sessionrecord.duration": "Duration",
  "cobrowse.sessionrecord.positiveInteger.checkmsg": "Enter a positive integer",
  "cobrowse.sensitiveinfo.pageMatchValue": "Page Match Config",

  "cobrowse.sensitiveinfo.rulenamecheck": "The rule name can contain only alphanumeric _. and a maximum of 128 characters",
  "cobrowse.sensitiveinfo.url.check": "The URL path configured for page matching is in incorrect format",
  "cobrowse.sensitiveinfo.cssselectorcheck": "CSS selector syntax error",
  "cobrowse.sensitiveinfo.pageIdCheck": "The page ID can contain only letters, digits, _-:. and a maximum of 1024 characters",
  "cobrowse.sensitiveinfo.create": "Create a new sensitive information rule",
  "cobrowse.sensitiveinfo.edit": "Edit a new sensitive information rule",
  "cobrowse.sensitiveinfo.createnameexist": "name already exists",
  "cobrowse.sensitiveinfo.exceedmaxcount": "Up to 100 sensitive rules can be configured per tenant",
  "cobrowse.sensitiveinfo.delete": "Delete sensitive information rule",
  "cobrowse.sensitiveinfo.willdeletetips": "Are you sure you want to delete the selected sensitive information rule?",
  "cobrowse.sensitiveinfo.deletenum.outstripping": "A maximum of 100 records can be deleted at a time",
  "cobrowse.common.tips.selectempty": "Please select the data to operate first",
  "cobrowse.sessionrecord.workNo.rules": "Needs to be in the range of 1 - 65535",
  "ccmessaging.chat.chatconfig.batch.delete": "Batch Delete",
  "ccmessaging.chat.chatconfig.create": "Newly created",
  "ccmessaging.channelconfig.export.datascope": "Data Scope",
  "ccmessaging.channelconfig.export.allscope": "All",
  "ccmessaging.channelconfig.export.curPage": "Current page",
  "ccmessaging.channelconfig.export.customField": "Custom field",
  "ccmessaging.channelconfig.export.selectedField": "Export Field",
  "ccmessaging.channelconfig.export.selectAll": "All",
  "ccmessaging.channelconfig.export.zipPwd": "Compressed password",
  "ccmessaging.channelconfig.export.zipPwdHint": "Please enter the compression password",
  "ccmessaging.channelconfig.export.pwdMsg": "Password for generating a compressed file. The value is a string of 8 to 12 characters and must contain letters, digits, and special characters ( excluding {'|;&$-<>/'}{'\\\\'}{'`!#(){}'}, spaces, newline character, and tab characters ).",
  'ccmessaging.channelconfig.export.pwdMsgError': "The value is a string of 8 to 12 characters and must contain letters, digits, and special characters (excluding {'|;&$-<>/'}{'\\\\'}{'`!#(){}'}, spaces, newline character, and tab characters).",
  "ccmessaging.channelconfig.export": "Export",
  "ccmessaging.channelconfig.viewExportTask": "Export task view",
  "ccmessaging.channelconfig.export.title": "Export Channel Configuration",
  'ccmessaging.channelconfig.export.exportAll': 'You have not selected specific records. Export all records? You can also cancel the operation and select a specific record to export.',
  'ccmessaging.channelconfig.export.exportAllTitle': "Export All Configurations",
  "ccmessaging.channelconfig.export.allExportConfirm": "All Export",
  'ccmessaging.channelconfig.export.exportSelect': "You have selected {0} records. Are you sure you want to continue?",
  "ccmessaging.channelconfig.export.exportBeginTime": "Export start time",
  "ccmessaging.channelconfig.export.exportEndTime": "Export end time",
  "ccmessaging.channelconfig.export.exportTaskStatus": "Status",
  "ccmessaging.channelconfig.export.exporting": "Exporting",
  "ccmessaging.channelconfig.export.unprocessed": "Unprocessed ",
  'ccmessaging.channelconfig.export.resultMsg': 'An export task has been created. Are you sure you want to view the export list?',
  "ccmessaging.channelconfig.export.resultSuccess": "Created successfully.",
  "ccmessaging.channelconfig.export.exception": "An error occurred when generating the export task. Check the logs.",
  "ccmessaging.channelconfig.export.error": "New export task failed, please check the log",
  "ccmessaging.channelconfig.export.paramInvalid": "Failed to verify the export conditions.",
  "ccmessaging.channelconfig.export.exceedlimit": "A maximum of {0} data records can be exported",
  "ccmessaging.channelconfig.export.nodata": "No channel configuration meets the conditions.",
  "ccmessaging.chat.holiday.deleteWorkDay": "Delete a specified work date",
  "ccmessaging.chat.holiday.deleteAllWorkDay": "Delete all specified work dates",
  "ccmessaging.chat.holiday.deleteHoliday": "Deleting a specified rest date",
  "ccmessaging.chat.holiday.deleteAllHoliday": "Delete all specified rest dates",
  "ccmessaging.multimedia.leftMenu.category": 'Category',
  "ccmessaging.multimedia.leftMenu.createCategory": 'Create Category',
  "ccmessaging.multimedia.leftMenu.editCategory": 'Edit Category',
  "ccmessaging.multimedia.audio.create": 'Create Voice',
  "ccmessaging.multimedia.audio.edit": 'Edit Voice',
  "ccmessaging.multimedia.video.create": 'Create Video',
  "ccmessaging.multimedia.video.edit": 'Edit Video',
  "ccmessaging.multimedia.image.create": 'Create Picture',
  "ccmessaging.multimedia.image.edit": 'Edit Picture',
  "ccmessaging.multimedia.emoticon.create": 'Create Emoticon',
  "ccmessaging.multimedia.emoticon.edit": 'Edit Emoticon',
  "ccmessaging.multimedia.document.create": 'Create Document',
  "ccmessaging.multimedia.document.edit": 'Edit Document',
  "ccmessaging.multimedia.richText.create": 'Create Rich Text',
  "ccmessaging.multimedia.richText.edit": 'Edit Rich Text',
  "ccmessaging.multimedia.local.create": 'Create Address',
  "ccmessaging.multimedia.local.edit": 'Edit Address',
  'ccmessaging.multimedia.whatsAppTemplate.create': 'Create WhatsApp Template',
  'ccmessaging.multimedia.whatsAppTemplate.edit': 'Edit WhatsApp Template',
  "ccmessaging.multimedia.siteAddr.create": 'Create Cobrowse Site',
  "ccmessaging.multimedia.siteAddr.edit": 'Edit Cobrowse Site',
  "ccmessaging.multimedia.cardFiveG.create": 'Create 5G Card',
  "ccmessaging.multimedia.cardFiveG.edit": 'Edit 5G Card',
  "ccmessaging.multimedia.carouselCardFiveG.create": 'Create 5G Carousel Card',
  "ccmessaging.multimedia.carouselCardFiveG.edit": 'Edit 5G Carousel Card',
  'ccmessaging.multimedia.whatsAppTemplateMsg.create': 'Create WhatsApp Template Message',
  'ccmessaging.multimedia.whatsAppTemplateMsg.edit': 'Edit WhatsApp Template Message',
  "ccmessaging.multimedia.cardWeb.create": 'Create Web Card',
  "ccmessaging.multimedia.cardWeb.edit": 'Edit Web Card',
  "chat.agent.qualityCheckWorkbench.title": "Inspection Workbench",
  "chat.agent.qualityCheckWorkbench.currentAgent": "Inspected agent:",
  "chat.agent.qualityCheckWorkbench.subscribeSessionFailed": "Failed to subscribe to the agent session.",
  "chat.agent.qualityCheckWorkbench.cancelSubscribeSessionFailed": "Failed to unsubscribe from the agent session.",
  "chat.agent.qualityCheckWorkbench.pollMsgFailed": "Failed to obtain the agent message.",
  "chat.agent.qualityCheckWorkbench.listen": "Cancel Insertion",
  "chat.agent.qualityCheckWorkbench.insert": "Insert",
  "chat.agent.qualityCheckWorkbench.intercept": "Intercept",
  "chat.agent.qualityCheckWorkbench.changeQualityStatus.fail": "Failed to change the inspection status.",
  "chat.agent.msg.redis.connection.exception.description": "The current system is faulty. Please wait.",
  "ccmessaging.chat.phrase.editPhraseTypeFail": "Edit common phrase type failed.",
  "ccmessaging.chat.phrase.editPhraseTypeSucess": "Edit common phrase type successfully.",
  "ccmessaging.chat.phrase.editPhraseFail": "Edit common phrase failed",
  "ccmessaging.chat.phrase.editPhraseSucess": "Edit common phrase successfully",
  "ccmessaging.chat.multimedia.editMultiMediaInfo": "Edit multimedia data successfully",
  "ccmessaging.chat.multimedia.editMultiMediaInfoError": "Edit multimedia data failed",
  "ccmessaging.chat.multimedia.editMultiMediaType": "Edit multimedia type successfully",
  "ccmessaging.chat.multimedia.editMultiMediaTypeError": "Edit multimedia type failed",
  "ccmessaging.chat.multimedia.createMultiMediaInfo": "Create multimedia data successfully",
  "ccmessaging.chat.multimedia.createMultiMediaInfoError": "Create multimedia data failed",
  "ccmessaging.chat.multimedia.createMultiMediaType": "Create multimedia type successfully",
  "ccmessaging.chat.multimedia.createMultiMediaTypeError": "Create multimedia type failed",
  "ccmessaging.chat.phrase.createTypeSuccess": "Create common phrase type successfully",

  "ccmessaging.chat.multimedia.createVideoInfo": "Create video data successfully",
  "ccmessaging.chat.multimedia.createVideoInfoError": "Create video data failed",
  "ccmessaging.chat.multimedia.createVideoType": "Create video type successfully",
  "ccmessaging.chat.multimedia.createVideoTypeError": "Create video type failed",
  "ccmessaging.chat.multimedia.editVideoInfo": "Edit video data successfully",
  "ccmessaging.chat.multimedia.editVideoInfoError": "Edit video data failed",
  "ccmessaging.chat.multimedia.editVideoType": "Edit video type successfully",
  "ccmessaging.chat.multimedia.editVideoTypeError": "Edit video type failed",
  "ccmessaging.chat.multimedia.deleteVideoInfo": "Delete video data successfully",
  "ccmessaging.chat.multimedia.deleteVideoInfoError": "Delete video data failed",
  "ccmessaging.chat.multimedia.deleteVideoType": "Delete video type successfully",
  "ccmessaging.chat.multimedia.deleteVideoTypeError": "Delete video type failed",
  "ccmessaging.chat.multimedia.deleteVideoTypeError.usedbyODFS": "Delete video type failed. The resources referenced by the intelligent IVR exist.",
  "ccmessaging.chat.multimedia.deleteVideoTypeError.referenced.byCard": "Delete video type failed, because some resources are already referenced by cards.",
  "ccmessaging.chat.multimedia.deleteVideoTypeError.referenced.byWhatsAppTemplateMsg": "Delete video type failed, because some resources are already referenced by a WhatsApp template message.",
  "ccmessaging.chat.multimedia.createAudioInfo": "Create audio data successfully",
  "ccmessaging.chat.multimedia.createAudioInfoError": "Create audio data failed",
  "ccmessaging.chat.multimedia.createAudioType": "Create audio type successfully",
  "ccmessaging.chat.multimedia.createAudioTypeError": "Create audio type failed",
  "ccmessaging.chat.multimedia.editAudioInfo": "Edit audio data successfully",
  "ccmessaging.chat.multimedia.editAudioInfoError": "Edit audio data failed",
  "ccmessaging.chat.multimedia.editAudioType": "Edit audio type successfully",
  "ccmessaging.chat.multimedia.editAudioTypeError": "Edit audio type failed",
  "ccmessaging.chat.multimedia.deleteAudioInfo": "Delete audio data successfully",
  "ccmessaging.chat.multimedia.deleteAudioInfoError": "Delete audio data failed",
  "ccmessaging.chat.multimedia.deleteAudioType": "Delete audio type successfully",
  "ccmessaging.chat.multimedia.deleteAudioTypeError": "Delete audio type failed",
  "ccmessaging.chat.multimedia.deleteAudioTypeError.usedbyODFS": "Delete audio type failed. The resources referenced by the intelligent IVR exist.",
  "ccmessaging.chat.multimedia.deleteAudioTypeError.referenced.byCard": "Delete audio type failed, because some resources are already referenced by cards.",
  "ccmessaging.chat.multimedia.deleteAudioTypeError.referenced.byWhatsAppTemplateMsg": "Delete audio type failed, because some resources are already referenced by a WhatsApp template message.",
  "ccmessaging.chat.multimedia.createImageInfo": "Create image data successfully",
  "ccmessaging.chat.multimedia.createImageInfoError": "Create image data failed",
  "ccmessaging.chat.multimedia.createImageType": "Create image type successfully",
  "ccmessaging.chat.multimedia.createImageTypeError": "Create image type failed",
  "ccmessaging.chat.multimedia.editImageInfo": "Edit image data successfully",
  "ccmessaging.chat.multimedia.editImageInfoError": "Edit image data failed",
  "ccmessaging.chat.multimedia.editImageType": "Edit image type successfully",
  "ccmessaging.chat.multimedia.editImageTypeError": "Edit image type failed",
  "ccmessaging.chat.multimedia.deleteImageInfo": "Delete image data successfully",
  "ccmessaging.chat.multimedia.deleteImageInfoError": "Delete image data failed",
  "ccmessaging.chat.multimedia.deleteImageType": "Delete image type successfully",
  "ccmessaging.chat.multimedia.deleteImageTypeError": "Delete image type failed",
  "ccmessaging.chat.multimedia.deleteImageTypeError.usedbyODFS": "Delete image type failed. The resources referenced by the intelligent IVR exist.",
  "ccmessaging.chat.multimedia.deleteImageTypeError.referenced.byCard": "Delete image type failed, because some resources are already referenced by cards.",
  "ccmessaging.chat.multimedia.deleteImageTypeError.referenced.byWhatsAppTemplateMsg": "Delete image type failed, because some resources are already referenced by a WhatsApp template message.",
  "ccmessaging.chat.multimedia.createDocumentInfo": "Create document data successfully",
  "ccmessaging.chat.multimedia.createDocumentInfoError": "Create document data failed",
  "ccmessaging.chat.multimedia.createDocumentType": "Create document type successfully",
  "ccmessaging.chat.multimedia.createDocumentTypeError": "Create document type failed",
  "ccmessaging.chat.multimedia.editDocumentInfo": "Edit document data successfully",
  "ccmessaging.chat.multimedia.editDocumentInfoError": "Edit document data failed",
  "ccmessaging.chat.multimedia.editDocumentType": "Edit document type successfully",
  "ccmessaging.chat.multimedia.editDocumentTypeError": "Edit document type failed",
  "ccmessaging.chat.multimedia.deleteDocumentInfo": "Delete document data successfully",
  "ccmessaging.chat.multimedia.deleteDocumentInfoError": "Delete document data failed",
  "ccmessaging.chat.multimedia.deleteDocumentType": "Delete document type successfully",
  "ccmessaging.chat.multimedia.deleteDocumentTypeError": "Delete document type failed",
  "ccmessaging.chat.multimedia.deleteDocumentTypeError.usedbyODFS": "Delete document type failed. The resources referenced by the intelligent IVR exist.",
  "ccmessaging.chat.multimedia.deleteDocumentTypeError.referenced.byCard": "Delete document type failed, because some resources are already referenced by cards.",
  "ccmessaging.chat.multimedia.deleteDocumentTypeError.referenced.byWhatsAppTemplateMsg": "Delete document type failed, because some resources are already referenced by a WhatsApp template message.",
  "ccmessaging.chat.multimedia.createLocateInfo": "Create location data successfully",
  "ccmessaging.chat.multimedia.createLocateInfoError": "Create location data failed",
  "ccmessaging.chat.multimedia.createLocateType": "Create location type successfully",
  "ccmessaging.chat.multimedia.createLocateTypeError": "Create location type failed",
  "ccmessaging.chat.multimedia.editLocateInfo": "Edit location data successfully",
  "ccmessaging.chat.multimedia.editLocateInfoError": "Edit location data failed",
  "ccmessaging.chat.multimedia.editLocateType": "Edit location type successfully",
  "ccmessaging.chat.multimedia.editLocateTypeError": "Edit location type failed",
  "ccmessaging.chat.multimedia.deleteLocateInfo": "Delete location data successfully",
  "ccmessaging.chat.multimedia.deleteLocateInfoError": "Delete location data failed",
  "ccmessaging.chat.multimedia.deleteLocateType": "Delete location type successfully",
  "ccmessaging.chat.multimedia.deleteLocateTypeError": "Delete location type failed",
  "ccmessaging.chat.multimedia.deleteLocateTypeError.usedbyODFS": "Delete location type failed. The resources referenced by the intelligent IVR exist.",
  "ccmessaging.chat.multimedia.deleteLocateTypeError.referenced.byCard": "Delete location type failed, because some resources are already referenced by cards.",
  "ccmessaging.chat.multimedia.deleteLocateTypeError.referenced.byWhatsAppTemplateMsg": "Delete location type failed, because some resources are already referenced by a WhatsApp template message.",
  "ccmessaging.chat.multimedia.createEmotionInfo": "Create emoticon data successfully",
  "ccmessaging.chat.multimedia.createEmotionInfoError": "Create emoticon data failed",
  "ccmessaging.chat.multimedia.createEmotionType": "Create emoticon type successfully",
  "ccmessaging.chat.multimedia.createEmotionTypeError": "Create emoticon type failed",
  "ccmessaging.chat.multimedia.editEmotionInfo": "Edit emoticon data successfully",
  "ccmessaging.chat.multimedia.editEmotionInfoError": "Edit emoticon data failed",
  "ccmessaging.chat.multimedia.editEmotionType": "Edit emoticon type successfully",
  "ccmessaging.chat.multimedia.editEmotionTypeError": "Edit emoticon type failed",
  "ccmessaging.chat.multimedia.deleteEmotionInfo": "Delete emoticon data successfully",
  "ccmessaging.chat.multimedia.deleteEmotionInfoError": "Delete emoticon data failed",
  "ccmessaging.chat.multimedia.deleteEmotionType": "Delete emoticon type successfully",
  "ccmessaging.chat.multimedia.deleteEmotionTypeError": "Delete emoticon type failed",
  "ccmessaging.chat.multimedia.deleteEmotionTypeError.usedbyODFS": "Delete emoticon type failed. The resources referenced by the intelligent IVR exist.",
  "ccmessaging.chat.multimedia.deleteEmotionTypeError.referenced.byCard": "Delete emoticon type failed, because some resources are already referenced by cards.",
  "ccmessaging.chat.multimedia.deleteEmotionTypeError.referenced.byWhatsAppTemplateMsg": "Delete emoticon type failed, because some resources are already referenced by a WhatsApp template message.",
  "ccmessaging.chat.multimedia.createRichTextInfo": "Create Rich Text data successfully",
  "ccmessaging.chat.multimedia.createRichTextInfoError": "Create Rich Text data failed",
  "ccmessaging.chat.multimedia.createRichTextType": "Create Rich Text type successfully",
  "ccmessaging.chat.multimedia.createRichTextTypeError": "Create Rich Text type failed",
  "ccmessaging.chat.multimedia.editRichTextInfo": "Edit Rich Text data successfully",
  "ccmessaging.chat.multimedia.editRichTextInfoError": "Edit Rich Text data failed",
  "ccmessaging.chat.multimedia.editRichTextType": "Edit Rich Text type successfully",
  "ccmessaging.chat.multimedia.editRichTextTypeError": "Edit Rich Text type failed",
  "ccmessaging.chat.multimedia.deleteRichTextInfo": "Delete Rich Text data successfully",
  "ccmessaging.chat.multimedia.deleteRichTextInfoError": "Delete Rich Text data failed",
  "ccmessaging.chat.multimedia.deleteRichTextType": "Delete Rich Text type successfully",
  "ccmessaging.chat.multimedia.deleteRichTextTypeError": "Delete Rich Text type failed",
  "ccmessaging.chat.multimedia.deleteRichTextTypeError.usedbyODFS": "Delete Rich Text type failed. The resources referenced by the intelligent IVR exist.",
  "ccmessaging.chat.multimedia.deleteRichTextTypeError.referenced.byCard": "Delete Rich Text type failed, because some resources are already referenced by cards.",
  "ccmessaging.chat.multimedia.deleteRichTextTypeError.referenced.byWhatsAppTemplateMsg": "Delete Rich Text type failed, because some resources are already referenced by a WhatsApp template message.",
  "ccmessaging.chat.multimedia.createSiteAddrInfo": "Create Web Page Collaboration Address data successfully",
  "ccmessaging.chat.multimedia.createSiteAddrInfoError": "Create Web Page Collaboration Address data failed",
  "ccmessaging.chat.multimedia.createSiteAddrType": "Create Web Page Collaboration Address type successfully",
  "ccmessaging.chat.multimedia.createSiteAddrTypeError": "Create Web Page Collaboration Address type failed",
  "ccmessaging.chat.multimedia.editSiteAddrInfo": "Edit Web Page Collaboration Address data successfully",
  "ccmessaging.chat.multimedia.editSiteAddrInfoError": "Edit Web Page Collaboration Address data failed",
  "ccmessaging.chat.multimedia.editSiteAddrType": "Edit Web Page Collaboration Address type successfully",
  "ccmessaging.chat.multimedia.editSiteAddrTypeError": "Edit Web Page Collaboration Address type failed",
  "ccmessaging.chat.multimedia.deleteSiteAddrInfo": "Delete Web Page Collaboration Address data successfully",
  "ccmessaging.chat.multimedia.deleteSiteAddrInfoError": "Delete Web Page Collaboration Address data failed",
  "ccmessaging.chat.multimedia.deleteSiteAddrType": "Delete Web Page Collaboration Address type successfully",
  "ccmessaging.chat.multimedia.deleteSiteAddrTypeError": "Delete Web Page Collaboration Address type successfully",
  "ccmessaging.chat.multimedia.deleteSiteAddrTypeError.usedbyODFS": "Delete Web Page Collaboration Address type failed. The resources referenced by the intelligent IVR exist.",
  "ccmessaging.chat.multimedia.deleteSiteAddrTypeError.referenced.byCard": "Delete Web Page Collaboration Address type failed, because some resources are already referenced by cards.",
  "ccmessaging.chat.multimedia.deleteSiteAddrTypeError.referenced.byWhatsAppTemplateMsg": "Delete Web Page Collaboration Address type failed, because some resources are already referenced by a WhatsApp template message.",
  "ccmessaging.chat.chatcard.createFiveGCardFail": "Create 5G Card failed",
  "ccmessaging.chat.chatcard.createFiveGCardSuccess": "Create 5G Card successfully",
  "ccmessaging.chat.chatcard.editFiveGCardFail": "Edit 5G Card failed",
  "ccmessaging.chat.chatcard.editFiveGCardSuccess": "Edit 5G Card successfully",
  "ccmessaging.chat.chatcard.deleteFiveGCardFail": "Delete 5G Card failed",
  "ccmessaging.chat.chatcard.deleteFiveGCardSuccess": "Delete 5G Card successfully",
  "ccmessaging.chat.chatcard.createFiveGCarouselFail": "Create 5G Carouse failed",
  "ccmessaging.chat.chatcard.createFiveGCarouselSuccess": "Create 5G Carouse successfully",
  "ccmessaging.chat.chatcard.editFiveGCarouselFail": "Edit 5G Carouse failed",
  "ccmessaging.chat.chatcard.editFiveGCarouselSuccess": "Edit 5G Carouse successfully",
  "ccmessaging.chat.chatcard.deleteFiveGCarouselFail": "Delete 5G Carouse failed",
  "ccmessaging.chat.chatcard.deleteFiveGCarouselSuccess": "Delete 5G Carouse successfully",
  "ccmessaging.chat.chatcard.createWhatAppTemplateMeaasgeFail": "Create WhatsApp Template Meaasge failed",
  "ccmessaging.chat.chatcard.createWhatAppTemplateMeaasgeSuccess": "Create WhatsApp Template Meaasge successfully",
  "ccmessaging.chat.chatcard.editWhatAppTemplateMeaasgeFail": "Edit WhatsApp Template Meaasge failed",
  "ccmessaging.chat.chatcard.editWhatAppTemplateMeaasgeSuccess": "Edit WhatsApp Template Meaasge successfully",
  "ccmessaging.chat.chatcard.deleteWhatAppTemplateMeaasgeFail": "Delete WhatsApp Template Meaasge failed",
  "ccmessaging.chat.chatcard.deleteWhatAppTemplateMeaasgeSuccess": "Delete WhatsApp Template Meaasge successfully",
  "ccmessaging.chat.chatcard.createWebCardFail": "Create WebCard failed",
  "ccmessaging.chat.chatcard.createWebCardSuccess": "Create WebCard successfully",
  "ccmessaging.chat.chatcard.editWebCardFail": "Edit WebCard failed",
  "ccmessaging.chat.chatcard.editWebCardSuccess": "Edit WebCard successfully",
  "ccmessaging.chat.chatcard.deleteWebCardFail": "Delete WebCard failed",
  "ccmessaging.chat.chatcard.deleteWebCardSuccess": "Delete WebCard successfully",
  "ccmessaging.chat.WhatsApp.createWhatsAppTemplateSuccess": "Create WhatsApp Template successfully",
  "ccmessaging.chat.WhatsApp.createWhatsAppTemplateFail": "Create WhatsApp Template failed",
  "ccmessaging.chat.WhatsApp.editWhatsAppTemplateSuccess": "Edit WhatsApp Template successfully",
  "ccmessaging.chat.WhatsApp.editWhatsAppTemplateFail": "Edit WhatsApp Template failed",
  "ccmessaging.chat.WhatsApp.deleteWhatsAppTemplateSuccess": "Delete WhatsApp Template successfully",
  "ccmessaging.chat.WhatsApp.deleteWhatsAppTemplateFail": "Delete WhatsApp Template failed",
  "ccmessaging.chat.WhatsApp.deleteWhatsAppTemplateFailMsg": "Delete WhatsApp Template failed. The template is referenced by a template message.",
  "ccmessaging.multimedia.download.office.risktips":"You are about to download Office documents, which may pose security risks. It is recommended that you perform antivirus operations and enable the security settings of the Office software.",
  "ccmessaging.chat.multimedia.documentUploadRiskTips":"This document is sent to users by agents. Do not upload files that contain security risks.",
  "ccmessaging.config.names.channels.web": "Web",
  "ccmessaging.config.names.channels.instagram": "Instagram",
  "ccmessaging.config.names.channels.telegram": "Telegram",
  "ccmessaging.config.names.channels.whatsapp": "WhatsApp",
  "ccmessaging.config.names.channels.line": "LINE",
  "ccmessaging.config.names.channels.wechat": "WeChat",
  "ccmessaging.config.names.channels.facebook": "Facebook",
  "ccmessaging.config.names.channels.twitter": "X (Twitter)",
  "ccmessaging.config.names.channels.rcs": "5G RCS",
  "ccmessaging.config.names.channels.email": "Email",
  "ccmessaging.config.names.channels.sms": "SMS",
  "ccmessaging.chat.multimedia.whatsApp.interactiveMsg":"WhatsApp Interactive Messages",
  "ccmessaging.chat.whatsapp.interactiveMsgNamePlaceholder":"Please enter an interactive message name",
  "ccmessaging.chat.whatsapp.interactiveMsgName":"Message Name",
  "ccmessaging.chat.whatsapp.interactiveMsgTitle":"Title",
  "ccmessaging.chat.whatsapp.interactiveMsgDescription":"Message Description",
  "ccmessaging.chat.whatsapp.interactiveMsgDescriptionPlaceholder":"Please enter interactive message description",
  "ccmessaging.chat.whatsapp.interactiveMsgType":"Message Type",
  "ccmessaging.chat.whatsapp.orgName":"Organization Name",
  "ccmessaging.chat.chatcard.queryInteractiveMsg":"Failed to Query Interactive Messages",
  "ccmessaging.chat.whatsapp.interactiveType.buttons":"Button Message",
  "ccmessaging.chat.whatsapp.interactiveType.list":"List Message",
  "ccmessaging.chat.whatsapp.delete.interactiveMsg":"Deleting Interactive Messages",
  "ccmessaging.chat.whatsapp.willdeleteinteractiveMsg":"Are you sure you want to delete this interactive message?",
  "ccmessaging.chat.whatsapp.interactiveTypes":"Interactive Message Type",
  "ccmessaging.chat.whatsapp.interactiveMsgTitlePlaceholder":"Please enter interactive title content",
  "ccmessaging.chat.whatsapp.interactiveMsg.bodyContent":"Body Content",
  "ccmessaging.chat.whatsapp.interactiveMsg.bodyContentPlaceholder":"Please enter the body content.",
  "ccmessaging.chat.whatsapp.interactiveMsg.footerContent":"Footer Content",
  "ccmessaging.chat.whatsapp.interactiveMsg.footerContentPlaceholder":"Please enter the footer content.",
  "ccmessaging.chat.whatsapp.interactiveMsg.pleaseChoose":"Please select",
  "ccmessaging.chat.whatsapp.interactiveMsg.attachments":"Attachments",
  "ccmessaging.chat.multimedia.whatsApp.interactiveMsg.create":"New Interactive Message",
  "ccmessaging.chat.multimedia.whatsApp.interactiveMsg.update":"Modifying an Interactive Message",
  "ccmessaging.chat.whatsapp.interactiveType.list.title":"List",
  "ccmessaging.chat.whatsapp.interactiveType.list.titlePlaceholder":"Please enter the title of the list.",
  "ccmessaging.chat.whatsapp.interactiveMsg.openRow.info":"Group title is empty. Cannot set the option.",
  "ccmessaging.chat.whatsapp.interactiveType.section.titlePlaceholder":"Please enter the group title.",
  "ccmessaging.chat.whatsapp.interactiveType.row.titlePlaceholder":"Enter an option title.",
  "ccmessaging.chat.whatsapp.interactiveType.row.descPlaceholder":"Enter an option description.",
  "ccmessaging.chat.whatsapp.interactiveMsg.sectionTitle":"Please enter the title of the list.",
  "ccmessaging.chat.whatsapp.interactiveMsg.queryInteractiveError":"Failed to Query Interactive Messages",
  "ccmessaging.chat.whatsapp.interactiveMsg.createInteractiveError":"Failed to save the interactive message. After the list is grouped, the grouping options cannot be null.",
  "ccmessaging.chat.whatsapp.interactiveMsg.saveFail.duplicateName":"Saving failed. The interactive message name already exists.",
  "ccmessaging.chat.whatsapp.interactiveMsg.noHeader":"None",
  "ccmessaging.chat.whatsapp.interactiveMsg.headerContent":"Header Content",
  "ccmessaging.chat.whatsapp.interactiveMsg.no.buttons":"Failed to save the interactive message. There is no setting button.",
  "ccmessaging.chat.whatsapp.interactiveMsg.no.lists":"Saving interactive message failed, no setting list.",
  "ccmessaging.chat.multimedia.deleteLimit.whatsapp.interactiveMsg":"The resource has been referenced by WhatsApp interactive messages and cannot be deleted. Interactive Message Name:",
  "ccmessaging.chat.whatsapp.interactiveMsg.saveFail.multiMedia.notExist":"Saving failed. The multimedia resource does not exist.",
  "ccmessaging.chat.whatsapp.interactiveMsg.createInteractiveError.repeateButtonContent":"Saving failed. The button content must be unique.",
  "ccmessaging.chat.case.title":"Case Title",
  "ccmessaging.chat.case.type":"Case Type",
  "ccmessaging.chat.case.callReason":"Call Reason",
  "ccmessaging.chat.case.puzzled":"puzzled",
  "ccmessaging.chat.case.calm":"calm",
  "ccmessaging.chat.case.happy":"happy",
  "ccmessaging.chat.case.upset":"upset",
  "ccmessaging.chat.case.surprise":"surprise",
  "ccmessaging.chat.case.pleasant":"pleasant",
  "ccmessaging.chat.case.angry":"angry",
  "ccmessaging.chat.case.little.angry":"a little anger",
  "ccmessaging.chat.case.very.angry":"very mad",
  "ccmessaging.chat.case.very.knowledgeId":"1228840300949016576",
  "ccmessaging.chat.case.complaint.yes":"yes",
  "ccmessaging.chat.case.complaint.shi":"yes",
  "ccmessaging.chat.degree.view":"Customer 360 Degree",
}
