const baseRoutes = [
  {
    path: '/home',
    name: '6001',
    component: () => import(/* webpackChunkName: "base" */ '@/views/base/home/index.vue')
  },
  {
    path: '/config-center',
    name: 'ConfigCenter',
    component: () => import(/* webpackChunkName: "base" */ '@/views/base/config-center/index.vue')
  },
  {
    path: '/iframes',
    name: 'iframes',
    component: () => import(/* webpackChunkName: "base" */ '@/views/base/frame/iframes.vue')
  },
  {
    path: '/personal-info',
    name: 'personal-info',
    component: () => import(/* webpackChunkName: "base" */ '@/views/base/personal-info/index.vue')
  }
]
export default baseRoutes
