/**
 * * 不要直接引入，从 index 引入 appConfig
 */

const PAGE_SIZES = [10, 20, 50, 100]
const PAGE_SIZE = {
  REGULAR: 20,
  DIALOG: 10
}
const PAGER_COUNT = 5
const TOTAL = 0

const layoutGroup = {
  navigation: ['prev', 'pager', 'next'],
  sizes: ['sizes'],
  total: ['total'],
  jumper: ['jumper']
}

const { navigation, sizes, total, jumper } = layoutGroup

const LAYOUT = {
  BASIC: generateLayoutString(navigation),
  COMPACT: generateLayoutString(total, navigation),
  ADVANCED: generateLayoutString(sizes, total, navigation),
  FULL: generateLayoutString(sizes, total, navigation, jumper)
}

const CURRENT_PAGE = 1

const regularSettings = {
  pageSizes: PAGE_SIZES,
  pageSize: PAGE_SIZE.REGULAR,
  pagerCount: PAGER_COUNT,
  total: TOTAL,
  layout: LAYOUT.FULL,
  currentPage: CURRENT_PAGE
}

const dialogSettings = {
  pageSizes: PAGE_SIZES,
  pageSize: PAGE_SIZE.DIALOG,
  pagerCount: PAGER_COUNT,
  total: TOTAL,
  layout: LAYOUT.COMPACT,
  currentPage: CURRENT_PAGE
}

function generateLayoutString(...groups: string[][]): string {
  return groups.flat().join(', ')
}

export default {
  regular: regularSettings,
  dialog: dialogSettings,
  LAYOUT
}
