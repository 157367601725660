import request from '@/utils/request'

export function queryUserMenusFromSum(data) {
  return request({
    url: '/service-cloud/rest/sum/v1/user/menus',
    method: 'get',
    data
  })
}

export function queryMenusFromPub(data) {
  return request({
    url: '/service-cloud/rest/service-pub/v1/navMenu/list',
    method: 'post',
    data
  })
}

export function queryMenusFromCms(data) {
  return request({
    url: '/service-cloud//rest/cmsapp/v1/navMenu/list',
    method: 'post',
    data
  })
}

export function accountLogin(data) {
  return request({
    url: '/service-cloud/u-route/SMAuthenticateAction/authenticatelogin',
    method: 'post',
    data
  })
}

export function checkTimeout(data) {
  return request({
    url: '/service-cloud/u-route/service/checktimeout',
    method: 'get',
    data
  })
}

export function queryUserIndicator(data) {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/indexmonitor/queryuserindicator',
    method: 'post',
    data
  })
}

export function queryUserDayTrend(data) {
  return request({
    url: '/service-cloud/rest/cmsapp/v1/indexmonitor/queryuserdaytrend',
    method: 'post',
    data
  })
}
export function getMiitAddr(data) {
  return request({
    url: '/service-cloud/u-route/SMLoginAction/getMiitAddr',
    method: 'post',
    data
  })
}

export function logout(data) {
  return request({
    url: '/service-cloud/u-route/SMLogoutAction/logout',
    method: 'post',
    data
  })
}

export function getUserInfo(data) {
  const url = `/service-cloud/rest/sum/v2/tenantSpaces/${data.tenantSpaces}/users/${data.userId}/information`
  return request({
    url,
    method: 'get',
    data: {}
  })
}

export function getInitparams() {
  const url = '/service-cloud/rest/sum/v1/SMLoginAction/getInitParams'
  return request({
    url,
    method: 'post'
  })
}

export function checksession() {
  const url = '/service-cloud/u-route/service/checksession'
  return request({
    url,
    method: 'get'
  })
}

export function logoutCas() {
  const url = 'https://' + window.location.host + '/cas/logout'
  return request({
    url,
    method: 'get'
  })
}

export function getSamlRequest() {
  const url = '/service-cloud/sm/login/portal.html'
  return request({
    url,
    method: 'get'
  })
}

export function forceChangePwd(data) {
  const url = '/service-cloud/u-route/pwdOperation/forceChangePwd'
  return request({
    url,
    method: 'post',
    data
  })
}

export function verifyCode() {
  const url = '/service-cloud/verifycode?r=' + Math.random()
  return request({
    url,
    method: 'get'
  })
}

export function getPwdRule() {
  const url = '/service-cloud/rest/sum/v1/PasswordAction/initParams'
  return request({
    url,
    method: 'post'
  })
}

export function checkSamlRequest() {
  const url = '/service-cloud/u-route/SMLoginAction/isPageValid'
  return request({
    url,
    method: 'post'
  })
}

export function changePassword(data) {
  const url = '/service-cloud/u-route/pwdOperation/changePassword'
  return request({
    url,
    method: 'post',
    data
  })
}

//座席 今日呼叫数、平均时长
export function queryAgentStatistics(data) {
  const url = `/service-cloud/rest/cmsapp/v1/homepage/queryAgentStatistics`
  return request({
    url,
    method: 'get',
    data: {}
  })
}

//座席 座席等待人数
export function queryAgentSkillWaitingNum(data) {
  const url = `/service-cloud/rest/cmsapp/v1/homepage/queryAgentSkillWaitingNum`
  return request({
    url,
    method: 'get',
    data: {}
  })
}

//查询是否为租户管理员
export function queryHasAdminRole(data) {
  const url = `/service-cloud/u-route/authOperation/hasAdminRole`
  return request({
    url,
    method: 'get',
    data: {}
  })
}

//快捷入口 查询快捷入口数据
export function queryUserDeskTopConfig(data) {
  const url = `/service-cloud/rest/cc-management/v1/UserDeskTopConfig/queryUserDeskTopConfig`
  return request({
    url,
    method: 'post',
    data: data
  })
}

//快捷入口   创建快捷入口数据
export function createUserDeskTopConfig(data) {
  const url = `/service-cloud/rest/cc-management/v2/UserDeskTopConfig/createUserDeskTopConfig`
  return request({
    url,
    method: 'post',
    data: data
  })
}

//快捷入口   更新快捷入口数据
export function updateUserDeskTopConfig(data) {
  const url = `/service-cloud/rest/cc-management/v2/UserDeskTopConfig/updateUserDeskTopConfig`
  return request({
    url,
    method: 'post',
    data: data
  })
}

//我的待办 留言
export function queryLeaveMessage(data) {
  const url = `/service-cloud/rest/cc-management/v1/leavemessage/queryLeaveMessage`
  return request({
    url,
    method: 'post',
    data: data
  })
}

//通知中心
export function queryMessageInfoForHome(data) {
  const url = `/service-cloud/rest/ccnotification/v1/receivermessage/queryMessageInfoForHome`
  return request({
    url,
    method: 'post',
    data: data
  })
}

//通知中心
export function queryMessageInfoById(data) {
  const url = `/service-cloud/rest/ccnotification/v1/receivermessage/queryMessageInfoById`
  return request({
    url,
    method: 'post',
    data: data
  })
}

// 判断是否需要开启sso
export function initParams() {
  const url = '/service-cloud/initparams'
  return request({
    url,
    method: 'post'
  })
}

// 查询租间已开启特性
export function queryTenantFeatures() {
  const url = '/service-cloud/rest/cc-management/v1/tenantInfo/queryTenantFeaturesForWeb'
  return request({
    url,
    method: 'post'
  })
}

// 获取租间信息
export function getTenantSpaceInfo(tenantSpaceId) {
  const url = `/service-cloud/rest/sum/v3/tenantSpaces/${tenantSpaceId}`
  return request({
    url,
    method: 'get'
  })
}

// 选择组织机构
export const queryOrgList = (data) => {
  return request({
    url: '/service-cloud/u-route/orgOperation/queryOrgList',
    method: 'post',
    data,
    requestHeaders: [
      {
        'content-type': 'application/json'
      }
    ]
  })
}

// 查询未读公告数量
export function queryUnreadMessage() {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/receivermessage/queryUnreadMessage',
    method: 'get'
  })
}

// 查询未读质检告警数量
export function queryUnreadQcAlarmMessage() {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/querymessage/queryQcAlarmMessage',
    method: 'get'
  })
}

// 首页 我的待办 租户管理外呼列表
export function queryTaskStatistics(data) {
  const url = `/service-cloud/rest/isales/v1/task/queryTaskStatistics`
  return request({
    url,
    method: 'post',
    data: data
  })
}

//我的待办 座席外呼任务列表
export function queryAgentTaskList(data) {
  const url = `/service-cloud/rest/isales/v1/manual/queryAgentTaskList`
  return request({
    url,
    method: 'post',
    data: data
  })
}

// 首页 通知中心 更新已读
export function uploadReadStatus(data) {
  const url = `/service-cloud/rest/ccnotification/v1/receivermessage/uploadReadStatus`
  return request({
    url,
    method: 'put',
    data: data
  })
}

// 首页 通知中心 删除通知信息
export function deleteReceiverMessage(data) {
  const url = `/service-cloud/rest/ccnotification/v1/receivermessage/deleteReceiverMessage`
  return request({
    url,
    method: 'post',
    data: data
  })
}

// 首页 呼叫总览 总呼叫 呼损数
export function queryuserdaymonitor(data) {
  const url = `/service-cloud/rest/cmsapp/v1/indexmonitor/queryuserdaymonitor`
  return request({
    url,
    method: 'post',
    data: data
  })
}

// 首页 帮助中心跳转地址
export function querySysparamaForWeb(data) {
  const url = `/service-cloud/rest/ipccprovision/v1/sysparam/querySysparamaForWeb`
  return request({
    url,
    method: 'post',
    data: data
  })
}

// 首页 呼叫总览 排队数
export function queryVdnWaitingNum() {
  const url = `/service-cloud/rest/cmsapp/v1/homepage/queryVdnWaitingNum`
  return request({
    url,
    method: 'get'
  })
}

// 对智能IVR流程编辑页状态进行处理
export function editIVRFlow(key) {
  return request({
    url: '/service-cloud/rest/cc-ivr/v1/ivrFlow/editIVRFlow',
    method: 'post',
    data: {
      status: -2,
      nmsflowId: key
    }
  })
}

// 获取租间群组
export function getTenantGroup(tenantSpaceId) {
  const url = `/service-cloud/rest/sum/v2/tenantSpaces/${tenantSpaceId}/groups`
  return request({
    url,
    method: 'get'
  })
}

// 查询用户特定权限
export function queryUserAuth() {
  return request({
    url: '/service-cloud/u-route/authresource/queryuserauth',
    method: 'post'
  })
}

// 查询用户所有权限
export function queryUserAuthIds() {
  return request({
    url: '/service-cloud/u-route/authresource/queryuserauthids',
    method: 'post'
  })
}

// 查询上次登录信息摘要
export function queryLoginSummary(data) {
  return request({
    url: `/service-cloud/rest/sum/v1/tenantSpaces/${data.tenantSpaceId}/users/${data.userId}/auth-summary`,
    method: 'get'
  })
}

// 切换appCube语言
export function changeAppcubeLanguage() {
  return request({
    url: '/service-cloud/u-route/changeAppcubeLanguage/changeLanguage',
    method: 'post'
  })
}

// 查询座席的配置信息
export function queryCCAgentByUserIdForAgentInfo() {
  return request({
    url: `/service-cloud/rest/cc-management/v1/ccagent/queryCCAgentByUserIdForAgentInfo`,
    method: 'post'
  })
}

// 查询软电话号码状态
export function querySoftPhoneStatus() {
  return request({
    url: `/service-cloud/rest/cc-management/v1/ccagent/querySoftPhoneStatus`,
    method: 'post'
  })
}

// 查询注册服务器地址
export function queryAllVirtualCallCenter() {
  return request({
    url: `/service-cloud/rest/cc-management/v1/virtualcallcenter/queryAllVirtualCallCenter`,
    method: 'post'
  })
}

// 查询座席参数
export function querySysparam(data) {
  return request({
    url: `/service-cloud/rest/cc-management/v1/sysparam/agent/querySysparam`,
    method: 'post',
    data: data
  })
}

// 保存座席参数
export function updateSysparam(data) {
  return request({
    url: `/service-cloud/rest/cc-management/v1/sysparam/agent/updateSysparam`,
    method: 'post',
    data: data
  })
}

// 重置座席参数
export function resetSysparam(data) {
  return request({
    url: `/service-cloud/rest/cc-management/v1/sysparam/agent/resetSysparam`,
    method: 'post',
    data: data
  })
}

// 刷新座席参数
export function refreshSysparam(data) {
  return request({
    url: `/service-cloud/rest/cc-management/v1/sysparam/agent/refreshSysparam`,
    method: 'post',
    data: data
  })
}

// 查看openEye是否可以下载
export function isDownloadable() {
  return request({
    url: `/service-cloud/u-route/ccmanagement/videoterminal/isDownloadable`,
    method: 'get',
  })
}

//查看vrc客户端是否可以下载
export function isDownloadVRCable() {
  return request({
    url: `/service-cloud/rest/cc-management/v1/virtualcallcenter/VRCClient/check`,
    method: 'get',
  })
}

// 注销座席页面已注册软电话信息
export function unRegisterSoftPhone() {
  return request({
    url: `/service-cloud/rest/cc-management/v1/ccagent/unRegisterSoftPhone`,
    method: 'post',
  })
}

// 查看修改手机号码权限
export function modifySelfPhoneAuth() {
  return request({
    url: `/service-cloud/rest/cc-management/v1/ccagent/check/modifySelfPhoneAuth`,
    method: 'get',
  })
}

// 修改手机号码
export function modifySelfPhone(data) {
  return request({
    url: `/service-cloud/rest/cc-management/v1/ccagent/modifySelfPhone`,
    method: 'post',
    data: data
  })
}

// 修改软电话号码
export function modifySoftPhonePassword(data) {
  return request({
    url: `/service-cloud/rest/cc-management/v1/ccagent/modifyPwdById`,
    method: 'post',
    data: data
  })
}

// 查询员工
export function queryEmployeeInfo() {
  return request({
    url: `/service-cloud/u-route/employeeOperation/queryEmployeeInfo`,
    method: 'get',
  })
}

//查询用户权限
export function queryUserPermCodes() {
  return request({
    url: `/service-cloud/u-route/permissionaction/queryUserPermCodes`,
    method: 'get',
  })
}