<template>
  <sweet-dialog :modelValue="dialog.isShow" :title="title" width="616px" class="aicc-dialog-limit-height"
                :close-on-click-modal="false">
    <sweet-row>
      <sweet-col class="button-area">
        <sweet-button class="aicc-button-icon" @click="handleRefresh">
          <aicc-tooltip :open-delay="800" :content="i18n('ccmessaging.chat.chatconfig.refresh')" placement="top">
            <img class="sweetui-icon-download-l refresh-icon-img" src="@/assets/img/search/search_refresh.png" />
          </aicc-tooltip>
        </sweet-button>
      </sweet-col>
    </sweet-row>
    <sweet-row>
      <sweet-col :span="24">
        <aicc-table
          class="aicc-table contact-export-view-table"
          :tableData="tableData"
          :tableColumns="tableColumns"
          :tableTools="{ showPagination: false, columnConfig: false }"
          :paginationConfig="paginationConfig"
          @handlePagination="handlePagination"
          ref="refAiccTable"
          v-loading="loading"
        >
          <template #test1="scope">
            <sweet-button
              text
              @click="download(scope.row.id)"
              v-if="scope.row.result == 2"
              type="primary"
              >{{ i18n('ccm.custdata.operlog.download') }}</sweet-button
            >
          </template>
        </aicc-table>
      </sweet-col>
    </sweet-row>
    <sweet-pagination
      v-model:current-page="paginationConfig.currentPage"
      v-model:page-size="paginationConfig.pageSize"
      :page-sizes="paginationConfig.pageSizes"
      :layout="paginationConfig.layout"
      :total="paginationConfig.total"
      @size-change="handleSizeChange"
      @current-change="handlePagination"
    />
  </sweet-dialog>
</template>
<script setup lang="ts">
import {defineProps, ref, onMounted, getCurrentInstance, reactive, toRefs, computed} from 'vue'
import { queryExportDataTask } from '@/views/ccmanagement/ccma-api/index.js'
import { aiccDownload } from '@/utils/aiccUploadAndDownload.js'

// 国际化封装
const {
  appContext: {
    config: { globalProperties }
  }
} = getCurrentInstance() as any
const i18n = (param: string, ...args: Array<any>): string => {
  return globalProperties.$t(param, args)
}
const loading = ref<boolean>(false)
const props = defineProps({
  title: String,
  isShow: Boolean
})
const refAiccTable = ref()
const emit = defineEmits(['change'])

const dialog = toRefs(props)

const change = () => {
  emit('change', dialog.isShow)
}

const tableColumns = reactive([
  {
    label: i18n('ccm.export.table.taskTime'),
    prop: 'beginTime',
    display: true,
    configFixed: true,
    description: '',
    width: "192px"
  },
  {
    label: i18n('ccm.export.table.taskStatus'),
    prop: 'resultText',
    display: true,
    configFixed: true,
    description: '',
    width: "192px"
  },
  {
    label: i18n('ccm.custdata.operlog.operation'),
    prop: 'result',
    display: true,
    configFixed: true,
    description: '',
    slot: 'test1',
    width: "192px"
  }
])

const statusMap: Record<string, String> = {
  '0': i18n('ccm.agent.leavemessage.status.unprocessed'),
  '1': i18n('ccm.custdata.operlog.exportiong'),
  '2': i18n('ccm.custdata.operlog.success'),
  '3': i18n('ccm.custdata.operlog.failed')
}

const tableData = ref<Array<any>>([]) // 表数据

const paginationConfig = reactive({
  pageSizes: [10, 20, 50, 100], // 每页条数列表
  pageSize: 10, // 每页条数
  total: 0, //总条数
  currentPage: 1,
  layout: 'sizes, total, prev, pager, next' // 分页组件布局配置
})

const handlePagination = () => {
  queryExportTask(
    paginationConfig.pageSize,
    (paginationConfig.currentPage - 1) * paginationConfig.pageSize
  )
}

const handleSizeChange = () => {
  paginationConfig.currentPage = 1
  handlePagination()
}
const handleRefresh = () => {
  refAiccTable.value.handleInitChange()
}

const queryExportTask = (limit: number, offset: number) => {
  loading.value = true
  queryExportDataTask({ limit, offset }).then((resp: any) => {
    if (resp) {
      tableData.value = changeStatusWord(resp['tasks'])
      tableData.value = changeDateFormat(tableData.value)
      paginationConfig.total = resp['total']
    }
  }).finally(() => loading.value = false)
}

onMounted(() => {
  queryExportTask(paginationConfig.pageSize, 0)
})

const changeStatusWord = (param: any) => {
  for (let i = 0; i < param.length; i++) {
    param[i]['resultText'] = statusMap[param[i]['result']]
  }
  return param
}

const changeDateFormat = (param: any) => {
  for (let i = 0; i < param.length; i++) {
    if (param[i]['beginTime']) {
      param[i]['beginTime'] = globalProperties.$TimeUtils.transTime(param[i]['beginTime'])
    } else {
      param[i]['beginTime'] = '-'
    }
    if (param[i]['endTime']) {
      param[i]['endTime'] = globalProperties.$TimeUtils.transTime(param[i]['endTime'])
    } else {
      param[i]['endTime'] = '-'
    }
  }
  return param
}

const download = (id: any) => {
  const param = reactive({ id: id })
  const url = '/service-cloud/u-route/ccmanagement/contactrecord/downloadContactRecord'
  aiccDownload(param, url)
  return
}
</script>

<style lang="less" scoped>
.button-area {
  display: flex;
  flex-direction: column;
}
.aicc-button-icon {
  position: relative;
  align-self: flex-end;

  img {
    width: 16px;
    position: relative;
    top: 1px;
  }
}

.contact-export-view-table {
  margin-top: 10px;
}

div button {
  height: 32px;
}
</style>

