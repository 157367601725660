
export default {
  "sm.assign.employee": "分配员工",
  "common.baseui.LABEL.HEADING_BASEINFO": "基本信息",
  "SM.USERGROUP.SHOW.LIST": "用户群组",
  "SM.USERGROUP.LABEL.NAME": "用户群组名称",
  "common.bremui.label.description": "描述",
  "SM.SERVLET.EMPLOYEE": "员工",
  "common.statemachineui.button.add": "添加",
  "sm.add.usergroup": "新建用户群组",
  "SM.ORGSTAFF.BUTTON.COMMON_OK": "确定",
  "SM.COMMON.LABEL.SYSMENU_SUSPENDED": "下线",
  "SM.ORGSTAFF.MESSAGE.OPERATESUCCESS": "操作成功",
  "SM.ORGSTAFF.MESSAGE.STAFF_AUTHCOPY_SOURCENO_CONTBEADMIN_ERR": "系统管理员帐号不能作为源帐号。",
  "SM.ORGSTAFF.MESSAGE.BE_DELETESUCCESS": "删除成功。",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_DELETE_FAILED": "删除用户失败",
  "SM.ORGSTAFF.TIPS.INVALID_IP_ADDRESS": "无效的IP地址",
  "SM.COMMON.BUTTON.BANKCARD_SEARCH": "查询",
  "SM.ORGSTAFF.LABEL.ACCESS.ADD_FAIL": "添加失败",
  "SM.ORGSTAFF.TITLE.CONTACT_DESC": "描述",
  "SM.COMMON.LABEL.AREALIST": "地区列表",
  "SM.COMMON.LABEL.CURRENCY_DECIMALSYMBOL": "小数点符号",
  "SM.COMMON.TIPS.CHARGECODE_CODEINPUT": "只能输入字母和数字。",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_SECONDMENT": "员工借调",
  "SM.COMMON.LABEL.CURRENCY_STANDARD_MEASUREMENT": "标准货币单位",
  "SM.COMMON.LABEL.HOLIDAY_THURSDAY": "四",
  "SM.COMMON.LABEL.SYSMENU_AUTHID": "菜单权限编号",
  "SM.ORGSTAFF.MESSAGE.REGION_CONFIRM_USEPARENT": "如果使用父节点配置，该地区已有的时区配置将被删除，是否确定继续？",
  "SM.ORGSTAFF.TITLE.CONTACT_TYPE": "联系人类型",
  "SM.ORGSTAFF.TITLE.BE_PRECISION": "货币精度",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_SET_ROLE": "设置角色",
  "SM.ORGSTAFF.MESSAGE.EMPLOYEE_SECONDMENT_CONFIRM": "是否确定终止?",
  "SM.ORGSTAFF.LABEL.ORG_BASICINFO": "基本信息",
  "SM.COMMON.LABEL.MEASUREMENT_UNIT_OPERARION": "操作",
  "SM.ORGSTAFF.LABEL.BANK_TYPE": "银行类型",
  "SM.COMMON.BUTTON.SURVEY_DELETE_ANSWER_OPTIONS": "删除题目选项",
  "SM.COMMON.MESSAGE.SYSMENU_DEACTIVATED_NOT_SUPPORT": "状态为废弃的菜单不支持权限管理。",
  "SM.ORGSTAFF.TIPS.RESTRICTIP": "请输入xx.*或xx.xx.*或xx.xx.xx.*格式的IP地址，结尾用*代替。",
  "SM.ORGSTAFF.TITLE.CONTACT_EMAIL": "邮箱",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_SECONDMENT_ENDDATE": "结束日期",
  "SM.COMMON.LABEL.ATTACHMENT_MGR_SEARCH_CRITERIA_BE": "BE",
  "SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_VIEWFILE": "查看",
  "SM.ORGSTAFF.MESSAGE.ORG_PARENT_FORBID": "父组织已被挂起。",
  "SM.COMMON.LABEL.ATTR_LANGUAGE": "语言",
  "SM.ORGSTAFF.LABEL.TEAM_ASSIGN": "最多可分配2000个员工。",
  "SM.COMMON.LABEL.ATTACHMENT_DEL_ACCESSORY": "删除",
  "SM.ORGSTAFF.LABEL.SEARCH_ALL": "搜索所有用户",
  "SM.ORGSTAFF.LABEL.ADDR_DISPLAY_ORDER": "地址的显示顺序配置错误。",
  "SM.COMMON.LABEL.MEASUREMENT_DESTMEAUNIT": "目标度量单位",
  "SM.ORGSTAFF.MESSAGE.CHOOSE_SELECTED_OU_FIRST": "请先选择组织关系树中的一个节点。",
  "SM.ORGSTAFF.MESSAGE.STAFF_AUTHCOPY_SOURCENO_EQUALTOTARGET_ERROR": "源帐号不可以包含在目标帐号内。",
  "SM.ORGSTAFF.TITLE.ROLE_BASIC": "基本角色",
  "SM.ORGSTAFF.LABEL.STAFF_SESSION_VALIDITY_PERIOD": "会话有效时间（分钟）",
  "SM.COMMON.LABEL.CURRENCY_MEASUREMENT": "货币度量",
  "SM.PROJECT.TITLE.EXPORT_CURRENT_ORG_EMPLOYEE": "导出全部",
  "SM.ORGSTAFF.LABEL.DEPTMACRESTRICT": "MAC地址绑定",
  "SM.ORGSTAFF.LABEL.REGION_DAYLIGHTSAVINGTIME": "夏令时",
  "SM.ORGSTAFF.MESSAGE.BE_CANNOTDELETECURRENCY": "无法删除默认货币。",
  "SM.ORGSTAFF.TITLE.CONTACT_FULL_NAME": "姓名",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_VST": "会话超时类型",
  "SM.COMMON.LABEL.CURRENCY_MEASUREMENT_LIST": "货币度量列表",
  "SM.COMMON.LABEL.MEASUREMENT_CONV_LIST": "度量转换列表",
  "SM.COMMON.MESSAGE.VALIDATE_MINLENGTHB": "请至少输入0个字符。",
  "SM.ORGSTAFF.LABEL.REGION_DESCRIPTION": "描述",
  "SM.COMMON.LABEL.BANKCARD_ACCT_LENG_PARAM": "卡号长度",
  "SM.COMMON.LABEL.SURVEY_CLASS_SORT": "分类顺序",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_IP_TYPE": "IP类型",
  "SM.COMMON.LABEL.CURRENCY_DISPFORMAT_DISPLAYTYPE": "呈现类型",
  "SM.COMMON.LABEL.SYSMENU_CONFIGURESYSMENU": "配置系统菜单",
  "SM.ORGSTAFF.LABEL.TEAM_ORG_EMPLOYEE": "组织员工",
  "SM.ORGSTAFF.LABEL.REGION_DST_ENDDATE": "夏令时结束日期",
  "SM.ORGSTAFF.LABEL.ORG_STOP_TIPS": "停用后，组织或组织和其下属组织将无法使用，确认停用？",
  "SM.ORGSTAFF.TITLE.OPPAUTH_TITLE_MUTEXAUTH_INFOLIST": "互斥权限信息列表",
  "SM.COMMON.LABEL.ATTACHMENT_DELETE_INFO": "是否确定删除文件？",
  "SM.ORGSTAFF.OPTION.STAFF_REMOVE_PERMISSION": "删除目标操作员权限",
  "SM.COMMON.LABEL.MEASUREMENT_EXPORT_SUCCESS": "成功",
  "SM.ORGSTAFF.TITLE.CONTACT_TITLE": "联系人称谓",
  "SM.ORGSTAFF.BUTTON.EMPLOYEE_CANCLE": "取消",
  "SM.ORGSTAFF.LABEL.REGION_DST_SWITCHINTERVAL": "夏令时跳转间隔",
  "SM.COMMON.LABEL.SYSMENU_AFFECTED_MENU": "受影响的菜单",
  "SM.ORGSTAFF.MESSAGE.ENDEARLIERSTART": "开始日期不能晚于结束日期。",
  "SM.COMMON.LABEL.MEASUREMENT_UPLOAD_FILE": "上传文件",
  "SM.COMMON.LABEL.SYSMENU_USEPARENT": "使用父数据",
  "SM.COMMON.LABEL.HOLIDAY_FRIDAY": "五",
  "SM.COMMON.LABEL.EDIT_CURRENCY_EXCHANGE_RATE": "修改汇率",
  "SM.COMMON.LABEL.CURRENCY_TYPE_SYMBOL": "货币类型符号",
  "SM.COMMON.LABEL.SYSMENU_MENUNAME": "菜单项名称",
  "SM.COMMON.LABEL.ATTACHMENT_MGR_UPLOAD_SIZE_MAX": "上传文件大小超过最大限制（最大0 KB）。",
  "SM.COMMON.LABEL.SURVEY_ITEM_NAME": "项目名称",
  "SM.COMMON.TIPS.POPUP_INFO_TITLE": "提示",
  "SM.COMMON.MESSAGE.ERATE_NUMBER_INVALID": "无效的数字",
  "SM.COMMON.LABEL.CHARGECODE_STATUS": "状态",
  "SM.COMMOM.MESSAGE.SYSMENU_MENU_STATUS_NOT_CONSISTENT": "菜单状态不一致。",
  "SM.COMMON.LABEL.SURVEY_QUESTIONNAIRE_HEAD": "问卷卷头",
  "SM.COMMON.LABEL.MODULE_SHORTNAME": "简称",
  "SM.ORGSTAFF.LABEL.REGION_DST_MONTHDAY": "月/日",
  "SM.TENANTSPACE.AUTHTYPE.TITLE": "认证类型",
  "SM.ORGSTAFF.MESSAGE.DSTOFFSET.OUTRANAGE": "夏令时跳转间隔不在1到86400范围内。",
  "SM.ORGSTAFF.LABEL.WORKGROUP_CONTENTS": "工作组路径目录",
  "SM.ORGSTAFF.MESSAGE.SELECT_EMPLOYEE": "请先选择员工",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_NEW_PASSWORD": "新密码",
  "SM.COMMON.MESSAGE.EXPORT_VIEW": "，请到“系统管理》配置工程化》工程”菜单查看导出结果。",
  "SM.ORGSTAFF.LABEL.TEAM_TEAMLEVER": "团队级别",
  "SM.ORGSTAFF.LABEL.ROLE_TYPE_PUB": "公共角色",
  "SM.ORGSTAFF.TITLE.BE_CURRENCY": "货币",
  "SM.ORGSTAFF.TITLE.EMPLOYEE_QUERY_LIST": "员工列表",
  "SM.COMMON.BUTTON.MEASUREMENT_ADD": "新建",
  "SM.COMMON.LABEL.SEARCH_NAME": "请输入名称",
  "SM.COMMON.LABEL.HOLIDAY_HOLIDAYDAY": "节假日日期",
  "SM.ORGSTAFF.MESSAGE.COMMON_TIPS_STAFF_SELECT_NO_PERIOD": "请先选择人员",
  "SM.ORGSTAFF.TITLE.ASSIGNED_MANAGER": "已分配的主管",
  "SM.TENANTSPACE.LDAP_RDNPREFIX": "RDN前缀",
  "SM.ORGSTAFF.MESSAGE.EXPORT_LIMIT": "导出数量超过一万条不建议导出。",
  "SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_BE": "BE",
  "SM.COMMON.LABEL.OPERLOG_ENDTIME": "结束时间",
  "SM.COMMON.LABEL.CURRENCY_TYPE_CODE": "货币类型编码",
  "SM.ORGSTAFF.BUTTON.DELETE": "删除",
  "SM.COMMON.BUTTON.OK": "确定",
  "SM.ORGSTAFF.BUTTON.ADDR_ADD": "增加",
  "SM.COMMON.LABEL.CHARGECODE_CODE": "编码",
  "SM.ORGSTAFF.BUTTON.ADDR_CREATE": "新建",
  "SM.COMMON.TITLE.SYSMENU_ERROR": "错误",
  "SM.COMMON.LABEL.SYSMENU_DEACTIVATED": "废弃",
  "SM.COMMON.LABEL.SURVEY_APPLICATION_SCOPE": "应用范围",
  "SM.COMMON.LABEL.SURVEY_OPTION_NAME": "选项名称",
  "SM.ORGSTAFF.LABEL.ORG_SHORT_NAME": "组织简称",
  "SM.ORGSTAFF.BUTTON.EMPLOYEE_CLEAR": "清空",
  "SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE_CREATE": "新建调查问卷",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_DESC": "描述",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_ROAM_VALID": "有效",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_EMAIL_VALIDATOR": "无效的电子邮件",
  "SM.COMMON.LABEL.CHARGECODE_TEST": "测试",
  "SM.COMMON.LABEL.SURVEY_CREATED_TIME": "创建时间",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_ACCOUNTRANGE": "帐号范围",
  "SM.ORGSTAFF.LABEL.REGION_DST_MONTHWEEKDAY": "月/周/日",
  "SM.COMMON.LABEL.ATTACHMENT_MGR_UPLOAD_EXT_TITLE": "上传失败。允许上传的文件类型：0；不允许上传的文件类型：{1}。",
  "SM.ORGSTAFF.LABEL.TEAM_STATUS": "状态",
  "SM.ORGSTAFF.MESSAGE.ERROR_QUERY": "查询失败。",
  "SM.COMMON.LABEL.BANKCARD_BANKTYPE_PARAM": "发卡行类型",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_USERNAME_VALIDATOR": "用户名包含了非法特殊字符",
  "SM.COMMON.LABEL.CHARGECODE": "账目类型",
  "SM.ORGSTAFF.TITLE.ORG_ASSIGNED_EMP": "已分配员工",
  "SM.ORGSTAFF.MESSAGE.SAVE_SUGGESTION": "提示",
  "SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE_EDIT": "修改调查问卷",
  "SM.COMMON.MESSAGE.CHARGECODE_PERFORM_DELETION": "是否确定要删除？",
  "SM.COMMON.BUTTON.CHARGECODE_ADD": "增加",
  "SM.COMMON.MESSAGE.ERATE_CODE_COMPARE": "源货币编码和目标货币编码不能相同。",
  "SM.ORGSTAFF.LABEL.BANK_LEVEL": "银行级别",
  "SM.ORGSTAFF.LABEL.RESTRICTTYPE": "登录限制类型",
  "SM.COMMON.LABEL.HOLIDAY_WEDNESDAY": "三",
  "SM.ORGSTAFF.TITLE.CONTACT_PHONE": "联系电话",
  "SM.ORGSTAFF.LABEL.BE_ADDRESS_FORMAT": "地址格式模板",
  "SM.ORGSTAFF.LABEL.ORG_DISPLAYORDER": "显示顺序",
  "SM.ORGSTAFF.LABEL.PWD_UNMATCH_RULE": "密码不符合密码规则。",
  "SM.COMMON.LABEL.SYSMENU_LABEL": "系统菜单",
  "SM.COMMON.MESSAGE.AREA_DELETE_INFO": "是否确定删除?",
  "SM.ORGSTAFF.TITLE.STAFF_ROLE_ASSIGN": "角色分配",
  "SM.ORGSTAFF.OPTION.WEEKDAY_FRI": "星期五",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_RESETPWD": "密码重置",
  "SM.COMMON.LABEL.MODULE_MODULE": "模块",
  "SM.ORGSTAFF.TITLE.STAFF_LOGIN_RESTRICT": "登录限制",
  "SM.COMMON.LABEL.MODULE_ADDRESS": "URL地址",
  "SM.ORGSTAFF.TITLE.CURRENCY_DISPLAY_FORMAT": "货币呈现格式",
  "SM.COMMON.LABEL.MEASUREMENT_IMPORT_RESULT": "导入结果",
  "SM.ORGSTAFF.OPTION.STAFF_IP_SEGMENT": "IP段",
  "SM.COMMON.TITLE.ACCESS.SELECT_STAFF": "选择访问员工",
  "SM.COMMON.MESSAGE.SYSMENU_ACCESS_TIME_ERROR": "菜单访问结束时间必须大于开始时间。",
  "SM.ORGSTAFF.MESSAGE.CONTAINS_SUB_ORGS": "该部门包含子部门。",
  "SM.ORGSTAFF.TITLE.ORG_ACCESS_EMP": "已分配访问员工",
  "SM.COMMON.LABEL.BANKCARD_ACCT_LENG": "卡号长度",
  "SM.COMMON.LABEL.SYSMENU_VISITCOUNT": "访问量",
  "SM.ORGSTAFF.BUTTON.OURELATIONSHIP_RELOAD": "刷新",
  "SM.COMMON.LABEL.OPERLOG_ACCOUNT": "帐号",
  "SM.COMMON.LABEL.BANKCARD_SEARCH_CONDITION": "查询条件",
  "SM.COMMON.LABEL.ATTACHMENT_ADD_ACCESSORY": "新建",
  "SM.COMMON.MESSAGE.CHARGECODE_TO_TEST": "是否确定切换到测试状态？",
  "SM.COMMON.LABEL.SYSMENU_MENUURL": "菜单项链接地址",
  "SM.ORGSTAFF.AUTHTYPE.LABEL": "认证类型",
  "SM.COMMON.LABEL.ERATE_TODATE": "至",
  "SM.COMMON.TIPS.HOME_PHONE": "请输入正确的电话号码，格式如：(+)(0123-)1234567890(-0123)",
  "SM.ORGSTAFF.TITLE.EMPLOYEE_TEAMINFO": "团队信息",
  "SM.COMMON.LABEL.CURRENCY_MEASUREMENT_TITLE": "货币度量单位",
  "SM.COMMON.LABEL.SURVEY_CLASS_Code": "分类编码",
  "SM.COMMON.LABEL.SEARCHCONDITION": "查询条件",
  "SM.ORGSTAFF.MESSAGE.SAVESUCCESS": "保存成功。",
  "SM.TENANTSPACE.LDAP_DOMAIN": "LDAP域",
  "SM.ORGSTAFF.LABEL.ORGANIZATION": "组织机构",
  "SM.COMMON.BUTTON.ADD_MENU_ACCESS_TIME": "增加菜单访问时间，不配置则菜单任何时间都可以访问。",
  "SM.AUC.LABEL.BANK_NAME_INPUT": "输入银行名称",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_RESET_SUCCESS": "员工密码修改成功，以下修改失败：",
  "SM.ORGSTAFF.LABEL.ORGIMPORT_TIP": "请确认运营商信息已经导入系统，并且当前操作员是管理员。",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_ACCOUNT_VALIDATOR_ADD": "只允许输入包含数字、字母、下划线、短横线、点以及{'@'}",
  "SM.COMMON.LABEL.MENU_INFO_TITLE": "菜单基本信息",
  "SM.COMMON.LABEL.HOLIDAY_YEAR": "年",
  "SM.COMMON.MESSAGE.DELETE_CONFIRM": "是否确定删除？",
  "SM.COMMON.LABEL.SYSMENU_ACTIVE_A": "上线",
  "SM.COMMON.LABEL.HOLIDAY_SATURDAY": "六",
  "SM.COMMON.BUTTON.SURVEY_ENABLE": "启用",
  "SM.ORGSTAFF.TITLE.OPERATION": "操作",
  "SM.ORGSTAFF.MESSAGE.FORBID_EXSIT_EMPLOYEE": "永久禁用失败，该部门下存在员工。",
  "SM.ORGSTAFF.LABEL.AUTHVIEW_AUTH_BENAME": "权限归属BE",
  "SM.COMMON.TIPS.MENU_REBUILD": "提示",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_SUSPEND": "挂起",
  "SM.ORGSTAFF.LABEL.REGION_SHORTNAME": "区域简称",
  "SM.ORGSTAFF.LABEL.STAFF_ADD_ROLE": "添加关联角色",
  "SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_ATTACHMENTID": "文件标识",
  "SM.ORGSTAFF.TITLE.EMPLOYEE_NOT_ALLOW_4A": "是否允许通过系统直接登录",
  "SM.COMMON.LABEL.CURRENCY_TYPE_NAME": "货币类型名称",
  "SM.ORGSTAFF.OPTION.STAFF_RESTRICTIONTYPE_ALLOWED": "允许",
  "SM.COMMON.LABEL.OPERLOG_RESET": "重置",
  "SM.COMMON.LABEL.CHARGECODE_NAME": "名称",
  "SM.ORGSTAFF.LABEL.STAFF_DESC": "描述",
  "SM.ORGSTAFF.LABEL.TEAM_DESCRIPTION": "描述",
  "SM.COMMON.MESSAGE.DATADICT_DELETE_FAILED": "删除失败。",
  "SM.COMMON.LABEL.BANKCARD_NAME": "卡名",
  "SM.COMMON.BUTTON.CANCEL": "取消",
  "SM.COMMON.LABEL.ATTACHMENT_DEL_SUCCESS": "删除文件成功。",
  "SM.ORGSTAFF.LABEL.TO_BE_ASSIGNED_ROLE": "待分配角色",
  "SM.COMMON.LABEL.SYSMENU_STATUS": "状态",
  "SM.ORGSTAFF.OPTION.STAFF_RETAIN_PERMISSION": "保留目标操作员权限",
  "SM.COMMON.LABEL.MEASUREMENT_ENTITY_ID": "度量标识",
  "SM.COMMON.LABEL.MEASUREMENT_UNIT_SUNIT": "标准单位",
  "SM.COMMON.MESSAGE.PROPERTY_SUCCESS_CREATE_INFO": "保存成功。",
  "SM.ORGSTAFF.BUTTON.TEAM_OK": "确定",
  "SM.COMMON.MESSAGE.CHARGECODE_DELETE_DATA": "是否确定删除账目类型？",
  "SM.COMMON.MESSAGE.VALIDATE_RANGELENGTHB": "输入值字符长度范围0到1。",
  "SM.COMMON.LABEL.OPERLOG_OBJECT_TYPE": "对象类型",
  "SM.COMMON.BUTTON.BANKCARD_CANCEL": "取消",
  "SM.ORGSTAFF.TITLE.BE_ADDRESSSETTING": "地址",
  "SM.ORGSTAFF.LABEL.ORG_EMPLOYEE_QUERY_INFO": "查询条件",
  "SM.COMMON.LABEL.MEASUREMENT_EXPORT_MEA": "度量",
  "SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_UPLODATPERSON": "上传人",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_SEARCH": "查询",
  "SM.COMMON.BUTTON.MENUVSEARCH": "重建菜单索引库",
  "SM.ORGSTAFF.TITLE.CONTACT_HOME_FAX": "家庭传真",
  "SM.COMMON.LABEL.ERATE_DCURRENCY_CODE": "目标货币编码",
  "SM.COMMON.LABEL.CREATE_OPER": "创建人",
  "SM.COMMON.LABEL.CURRENCY_DISPLAY_POSTFIX": "展示后缀",
  "SM.ORGSTAFF.LABEL.STAFF_ROLE_DESC": "描述",
  "SM.ORGSTAFF.TITLE.ADDRESS_DISPLAY_FORMAT": "地址展示格式",
  "SM.ORGSTAFF.TITLE.ADDR_NAME": "名称",
  "SM.COMMON.MESSAGE.SYSMENU_DELSUCCESS": "删除成功。",
  "SM.COMMON.LABEL.MEASUREMENT_ENTITY_CODE": "实体编码",
  "SM.ORGSTAFF.LABEL.INPUT_ROLE_NAME": "请输入角色名",
  "SM.ORGSTAFF.TIPS.DESC": "请勿输入特殊字符<>",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD": "员工密码校验失败",
  "SM.ORGSTAFF.TITLE.CONTACT_OFFICE_FAX": "办公传真",
  "SM.COMMON.LABEL.CURRENCY_FORMAT_DISPLAY": "格式预览",
  "SM.ORGSTAFF.AUTHTYPE.LDAP": "LDAP",
  "SM.PROJECT.TITLE.IMPORT_ORG_PAR": "导入Par包",
  "SM.ORGSTAFF.MESSAGE.DSTFLAG_WITHOUT_TIMEZONE": "没有时区不能打开夏令时。",
  "SM.COMMON.LABEL.MEASUREMENT_CONV_EDIT": "修改度量转换",
  "SM.ORGSTAFF.BUTTON.BE_COPYPARENT": "复制上级节点数据",
  "SM.ORGSTAFF.LABEL.ORG_FORBID": "禁用",
  "SM.ORGSTAFF.LABEL.STAFF_ROLE_TYPE": "角色类型",
  "SM.COMMON.LABEL.CURRENCY_MEASUREMENT_ID": "货币度量单位标识",
  "SM.COMMON.LABEL.OPERLOG_STARTTIME": "开始时间",
  "SM.ORGSTAFF.MESSAGE.REGION_VALIDATE_TIMESAME": "起始时间和结束时间不能相同。",
  "SM.COMMON.LABEL.HOLIDAY_SUNDAY": "日",
  "SM.COMMON.TIPS.ERATE_ERATE_TIPS": "请输入整数位不超过13位，小数位不超过6位且小数位最后一位不为0的数字。",
  "SM.ORGSTAFF.LABEL.REGION_CODE": "区域编码",
  "SM.ORGSTAFF.TITLE.REGION_ADMINREGION": "行政区域",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_NATIONALITY": "国籍",
  "SM.ORGSTAFF.TITLE.EMPLOYEE_VALUE_TYPE": "值类型",
  "SM.COMMON.MESSAGE.OPERATESUCCESS": "操作成功",
  "SM.ORGSTAFF.TITLE.SUCCESS": "提示",
  "SM.COMMON.LABEL.MEASUREMENT_UNIT_RATIO": "比率",
  "SM.COMMON.TITLE.SURVEY_QUESTION_NAME_INFO": "题目名称信息",
  "SM.COMMON.BUTTON.RESET": "重置",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_ACCOUNT_PASSWORD": "当前帐号密码",
  "SM.COMMON.BUTTON.ADD_ADD": "增加",
  "SM.COMMON.LABEL.ATTACHMENT_SAVE_SUCCESS": "增加附件成功。",
  "SM.ORGSTAFF.TITLE.TEAM_NAMEEXIST": "团队名称已存在。",
  "SM.ORGSTAFF.LABEL.TEAM_ACCOUNTRANGE": "帐号范围",
  "SM.COMMON.BUTTON.CHARGECODE_SAVE": "保存",
  "SM.COMMON.LABEL.OPERLOG_EMPLOYEE_NAME_NORMAL": "员工姓名",
  "SM.COMMON.LABEL.BANKCARD_BANKCODE": "发卡行编码",
  "SM.COMMON.LABEL.SURVEY_INTERLOCUTION": "问答  ",
  "SM.ORGSTAFF.LABEL.ORG_IMPORT": "导入",
  "SM.COMMON.BUTTON.MEASUREMENT_DELETE": "删除",
  "SM.COMMON.MESSAGE.CHARGECODE_TO_RELEASE": "是否确定切换到发布状态？",
  "SM.COMMON.LABEL.CHARGECODE_FORBIDEN_BE": "禁止访问的BE",
  "SM.COMMON.MESSAGE.OPERATE_FAIL": "操作失败",
  "SM.COMMON.MESSAGE.CURRENCY_MEASUREMNET_CONFIRMCOVERMSG": "当前货币类型已存在标准货币单位或最小货币单位，是否确定继续？",
  "SM.ORGSTAFF.TITLE.RELATION_CONFIRM_RECOVER": "恢复后，该组织关系和其下级关系将可用，确认恢复？",
  "SM.ORGSTAFF.LABEL.ACCESS.USER_REQUIRED": "请选择员工",
  "SM.ORGSTAFF.TITLE.REGION_IPRANGE": "IP地址段",
  "SM.COMMON.LABEL.SURVEY_CLASS_SORT_DESCRIPTION": "输入范围为1-100",
  "SM.ORGSTAFF.BUTTON.REMOVE_OUT": "删除",
  "SM.ORGSTAFF.TITLE.ADDR_DESCRIPTION": "描述",
  "SM.ORGSTAFF.TIPS.LOGINID": "不符合要求的特殊字符或者汉字",
  "SM.ORGSTAFF.TITLE.COMMON_QUERYCONDITION_TITLE": "查询条件",
  "SM.ORGSTAFF.LABEL.ORG_SELECTREGION": "选择区域",
  "SM.ORGSTAFF.MESSAGE.ERROR.SAVE": "错误",
  "SM.ORGSTAFF.MESSAGE.BE_SELECTREGION": "请选择一个区域",
  "SM.ORGSTAFF.MESSAGE.BE_MAKE_SURE_DELETE": "是否确定删除？",
  "SM.ORGSTAFF.MESSAGE.ADDR_DELETE_CONFIRM": "是否确定删除？",
  "SM.ORGSTAFF.MESSAGE.REGION_VALIDATE_IPEXIST": "IP地址重复。",
  "SM.COMMON.TITLE.SUCCESS": "提示",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_IS_LOCKED": "员工被锁定。",
  "SM.ORGSTAFF.TITLE.ASSIGNE_EMPLOYEE": "分配员工",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PWDRULEGROUP": "帐号密码规则组",
  "SM.COMMON.BUTTON.MEASUREMENT_EXPORT": "导出",
  "SM.ORGSTAFF.BUTTON.EMPLOYEE_SELECTORG": "选择组织",
  "SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_ATTACHMENTDESC": "描述",
  "SM.ORGSTAFF.TITLE.IMPORT_MAC_TITLE": "导入登录限制",
  "SM.ORGSTAFF.OPTION.WEEKLIST_FTH": "第四个",
  "SM.COMMON.MESSAGE.SAVESUCCESS": "保存成功。",
  "SM.ORGSTAFF.LABEL.TEAM_EMPLOYEE": "员工",
  "SM.ORGSTAFF.BUTTON.FORBID_FOREVER": "永久禁用",
  "SM.COMMON.LABEL.CURRENCY_GROUPSIZE": "分组大小",
  "SM.COMMON.LABEL.OPERLOG_OBJECT_ID": "对象标识",
  "SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE_TEMP": "调查问卷",
  "SM.COMMON.LABEL.OPERLOG_RESULT": "操作结果",
  "SM.COMMON.MESSAGE.OPERATEFAIL": "操作失败",
  "SM.ORGSTAFF.MESSAGE.BE_SUBBE": "当前BE已配置下级BE、组织、团队或角色。",
  "SM.ORGSTAFF.MESSAGE.STAFF_INFOAUTH_CONFIRM": "请确认所录入的员工信息，已得到本人的相关使用授权。",
  "SM.ORGSTAFF.TITLE.RELATION_CONFIRM_SUSPEND": "停用后，该组织关系和其下级关系将无法使用，确认停用？",
  "SM.COMMON.BUTTON.SURVEY_DELETE_QUESTION": "删除题目",
  "SM.ORGSTAFF.LABEL.STAFF_LOCALE": "默认语言",
  "SM.COMMON.LABEL.SURVEY_QUESTIONNAIRE_STATUS": "问卷状态",
  "SM.ORGSTAFF.MESSAGE.SRC_ACCOUNT": "源账户不存在。",
  "SM.ORGSTAFF.MESSAGE.CONTACT_ACCOUNT": "帐号",
  "SM.COMMON.LABEL.ACCESS_RELATION": "可访问组织",
  "SM.COMMON.TIPS.SPECIAL_CHAR_AVAILABLE": "输入值应为数字，字母或下划线。",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_CREATE_FAILD": "创建员工失败，请确认帐号信息",
  "SM.ORGSTAFF.OPTION.WEEKDAY_TUE": "星期二",
  "SM.ORGSTAFF.BUTTON.CANCEL": "取消",
  "SM.COMMON.BUTTON.BANKCARD_SAVE": "保存",
  "SM.COMMON.TITLE.ERROR": "错误",
  "SM.ORGSTAFF.LABEL.REGION_DST_STARTDATE": "夏令时开始日期",
  "SM.COMMON.LABEL.AREANAME": "地区名称",
  "SM.ORGSTAFF.LABEL.TEAM_TEAMCODE": "团队编码",
  "SM.COMMON.LABEL.MEASUREMENT_ENTITY_NAME": "实体名称",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_ROAM_ORG": "漫游单位",
  "SM.COMMON.LABEL.BANKCARD_BANKCODE_PARAM": "发卡行编码",
  "SM.ORGSTAFF.TITLE.CONTACT_MOBILE_PHONE": "移动电话",
  "SM.ORGSTAFF.LABEL.ORG_CHANNEL_CLASS": "渠道分类",
  "SM.COMMON.BUTTON.TOP": "置顶",
  "SM.AUC.LABEL.BANK_LIST": "银行列表",
  "SM.COMMON.TITLE.MEASUREMENT_STDLIST": "度量制式列表",
  "SM.COMMON.LABEL.ERATE_RANGEDATE": "生效时间范围",
  "SM.COMMON.LABEL.BANKCARD_OPERATION": "操作",
  "SM.ORGSTAFF.TITLE.BE_INFORMATION": "信息",
  "SM.ORGSTAFF.OPTION.MONTH_12": "12月",
  "SM.ORGSTAFF.OPTION.MONTH_11": "11月",
  "SM.ORGSTAFF.MESSAGE.TIMEZONE_TIME_ERROR": "起始时间不能大于等于结束时间。",
  "SM.ORGSTAFF.LABEL.REGION_LAYER": "区域层级",
  "SM.ORGSTAFF.OPTION.MONTH_10": "10月",
  "SM.ORGSTAFF.OPTION.WEEKDAY_SAT": "星期六",
  "SM.ORGSTAFF.LABEL.REGION_NAME": "区域名称",
  "SM.COMMON.MESSAGE.SYSMENU_CONFIRM_MODIFY_MENU_STATUS_TO": "确认将菜单状态修改为:",
  "SM.COMMON.LABEL.BANKCARD_BANKSIGN_LENG_PARAM": "发卡行标识长度",
  "SM.COMMON.BUTTON.CHARGECODE_EDIT": "修改",
  "SM.ORGSTAFF.LABEL.BE_ADDRESS_DISPLAYFORMAT": "地址展现样式模板",
  "SM.COMMON.LABEL.HOLIDAY": "节假日",
  "SM.ORGSTAFF.LABEL.ORG_CODE": "组织自定义编码",
  "SM.ORGSTAFF.LABEL.USER_COUNT": "用户数量",
  "SM.COMMON.MESSAGE.MEASUREMENT_STANDARD_ERROR": "请选择正确的标准单位。",
  "SM.COMMON.TIPS.DIGITS_ONLY": "请输入数字",
  "SM.ORGSTAFF.TITLE.ADDR_LINE_NUMBER": "展现行",
  "SM.COMMON.LABEL.BANKCARD_BANKNAME": "发卡行名称",
  "SM.ORGSTAFF.MESSAGE.ADDR_DELETE_ERROR": "删除错误。",
  "SM.ORGSTAFF.TITLE.REGION_TIMEZONE": "时区",
  "SM.COMMON.LABEL.OPERLOG_FAILUER": "失败",
  "SM.COMMON.LABEL.HIGHRISKLOG": "高危操作监控",
  "SM.COMMON.LABEL.CURRENCY_MEASUREMENT_NAME": "货币度量单位名称",
  "SM.ORGSTAFF.TITLE.CONTACT": "联系人",
  "SM.ORGSTAFF.LABEL.BE_CODE": "运营单位编码",
  "SM.COMMON.LABEL.SURVEY_SINGAL_CHOOSE": "单选",
  "SM.ORGSTAFF.LABEL.BE_SHORT_NAME": "运营单位简称",
  "SM.ORGSTAFF.TITLE.CONTACT_ID": "联系人标识",
  "SM.ORGSTAFF.LABEL.TEAM_CODE": "组织编码",
  "SM.COMMON.MESSAGE.MODULE_MODULEADDRESSNOTMATCH": "模块地址不匹配。",
  "SM.COMMON.LABEL.CHARGECODE_RELEASE": "发布",
  "SM.COMMON.TITLE.SYSMENU_MENUNAME": "菜单语言信息",
  "SM.COMMON.LABEL.SYSMENU_SELECTORGANIZATION": "选择权限",
  "SM.ORGSTAFF.LABEL.TEAM_SEARCH": "查询",
  "SM.ORGSTAFF.TITLE.CHARGE_CODE": "账目类型",
  "SM.ORGSTAFF.LABEL.STAFF_ROLE_EFFECTIVE_OU_INPUT": "请输入组织名称",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_ACCOUNT": "帐号",
  "SM.PROJECT.TITLE.EXPORT_CURRENT_ORG_AND_SUB": "导出所选项",
  "SM.COMMON.BUTTON.SURVEY_NEW_QUESTION_TYPE": "新建题目分类",
  "SM.ORGSTAFF.LABEL.BE_LANGUAGE": "语言",
  "SM.COMMON.LABEL.SYSMENU_SEARCH_RESULT": "菜单列表",
  "SM.COMMON.LABEL.HEADING_SEARCHCRITERIA": "查询条件",
  "SM.ORGSTAFF.TITLE.EMPLOYEE_TIMERANGEERROR": "登录结束时间须晚于登录开始时间。",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_RESET_FAILD": "员工密码修改失败",
  "SM.ORGSTAFF.TITLE.STAFF_INFO_ADD": "新建员工",
  "SM.ORGSTAFF.OPTION.EMPLOYEE_DELETE_ROLE": "删除角色",
  "SM.ORGSTAFF.TITLE.ADDRESS_FORMAT": "地址格式",
  "SM.ORGSTAFF.LABEL.FUNCAUTH_OPPAUTHNAME": "互斥权限名称",
  "SM.COMMON.LABEL.CURRENCY_DECIMALPOSITIONS": "小数位数",
  "SM.ORGSTAFF.BUTTON.INCREASE": "新增",
  "SM.ORGSTAFF.LABEL.TEAM_PARENTORG": "上级组织",
  "SM.ORGSTAFF.TITLE.NEW_ORG": "新建组织",
  "SM.ORGSTAFF.TITLE.EDIT_ORG": "编辑组织",
  "SM.ORGSTAFF.MESSAGE.INPUT_ORG_NAME": "请输入组织名称",
  "SM.ORGSTAFF.TITLE.NEW_ACCESS_USER": "添加访问员工",
  "SM.ORGSTAFF.TITLE.NEW_MANAGER": "添加主管",
  "SM.ORGSTAFF.TITLE.USER_ACCOUNT": "员工帐号",
  "SM.ORGSTAFF.MESSAGE.SEARCH_USERNAME": "搜索员工姓名",
  "SM.ORGSTAFF.TITLE.SELECT_ROLE": "选择角色",
  "SM.ORGSTAFF.MESSAGE.SEARCH_ROLE_NAME": "搜索角色名称",
  "SM.ORGSTAFF.TIP.ACCESS_USER_DESC": "不属于本组织，但允许访问及使用本组织资源的员工。",
  "SM.ORGSTAFF.MESSAGE.FOLLOW_TENANT": "跟随租户配置",
  "SM.COMMON.BUTTON.CHARGECODE_SUCCESS": "提示",
  "SM.ORGSTAFF.LABEL.TEAM_MANAGER": "主管",
  "SM.COMMON.LABEL.MEASUREMENT_EXPORT_COUNT": "导出数量",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_BIRTH": "生日",
  "SM.COMMON.LABEL.CREATE_TIME": "创建时间",
  "SM.ORGSTAFF.TITLE.COMMON_CONTACT_INFORMATION": "联系信息",
  "SM.ORGSTAFF.MESSAGE.STAFF_AUTHCOPY_PATTERN": "复制模式",
  "SM.ORGSTAFF.TITLE.EMPLOYEE_IP": "IP地址",
  "SM.COMMON.LABEL.MEASUREMENT_NO": "否",
  "SM.COMMON.TITLE.BANKCARD_ADD": "银行卡-新建",
  "SM.ORGSTAFF.MESSAGE.STAFF_RESUME_CONFIRM": "是否确定恢复员工帐号？",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_ONLINE_NAME": "姓名",
  "SM.ORGSTAFF.MESSAGE.SELECT_REGION": "选择区域",
  "SM.ORGSTAFF.LABEL.DEPTIPRESTRICT": "IP地址绑定",
  "SM.ORGSTAFF.LABEL.INPUT_REGION_NAME": "请输入区域名称",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_MIDDLENAME": "中间名",
  "SM.ORGSTAFF.TITLE.TEAM_OPERSUCCESS": "操作成功",
  "SM.COMMON.BUTTON.IMPORT": "导入",
  "SM.ORGSTAFF.LABEL.TEAM_TEAMNAME": "团队名称",
  "SM.ORGSTAFF.MESSAGE.BE_COPYCURRENCY": "是否确定复制父节点？",
  "SM.ORGSTAFF.TITLE.REGION_ADDRESS": "地址格式",
  "SM.ORGSTAFF.LABEL.STAFFAUTH_AUTHSOURCE": "权限来源",
  "SM.ORGSTAFF.LABEL.BE_DESCRIPTION": "描述",
  "SM.ORGSTAFF.LABEL.ORG_DELETE_CONFIRM": "组织机构被删除后，该组织不可恢复只能重新创建。是否删除？",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_NAME_EXIST": "创建员工失败，系统已存在该帐号",
  "SM.COMMON.LABEL.BANKCARD_BANKSIGN": "发卡行标识",
  "SM.COMMON.BUTTON.SURVEY_DELETE_QUESTION_TYPE": "删除题目分类",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_RESET": "重置",
  "SM.COMMON.LABEL.NEW_CURRENCY_EXCHANGE_RATE": "新建汇率",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_TO": "至",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_SAVE": "保存",
  "SM.COMMON.LABEL.OPERLOG_SUCCESS": "成功",
  "SM.COMMON.MESSAGE.DATADICT_DELETE_SUCCESSFULLY": "删除成功。",
  "SM.ORGSTAFF.LABEL.ORG_FORBID_TIPS": "确认禁用组织？",
  "SM.COMMON.TIPS.AREA_DELETE_SUCCESS": "删除成功。",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_FIRSTNAME": "名字",
  "SM.ORGSTAFF.TITLE.ERROR": "错误",
  "SM.ORGSTAFF.TITLE.REGION_ENDIP": "结束地址",
  "SM.ORGSTAFF.LABEL.ORG_NAME": "组织名称",
  "SM.ORGSTAFF.LABEL.ORG_IMPORTDATA": "导入数据",
  "SM.COMMON.LABEL.SURVEY_DRAFT": "草稿",
  "SM.ORGSTAFF.MESSAGE.REGION_CONFIRM_NOTUSEPARENT": "如果取消则不再使用父节点配置，是否确定继续？",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_ORG": "归属组织",
  "SM.ORGSTAFF.LABEL.BANK_CODE": "银行编码",
  "SM.ORGSTAFF.TITLE.CONTACT_OFFICE_PHONE": "办公电话",
  "SM.COMMON.BUTTON.ADD": "新建",
  "SM.COMMON.LABEL.THE_MENU": "?",
  "SM.ORGSTAFF.TIPS.UNLOCK.USER": "请输入当前操作员密码进行二次确认：",
  "SM.COMMON.MESSAGE.CURRENCY_DECIMALNOZEROFLAG": "小数去零",
  "SM.COMMON.LABEL.ERATE_EFFDATE": "生效时间",
  "SM.COMMON.LABEL.HOLIDAY_IMPORTDATA": "导入数据",
  "SM.ORGSTAFF.LABEL.ONLINEOPERATORNUM": "在线操作员总数",
  "SM.ORGSTAFF.LABEL.ORG_PARENTORG": "父组织",
  "SM.COMMON.LABEL.BANKCARD_NAME_PARAM": "卡名",
  "SM.COMMON.LABEL.CHARGECODE_DRAFT": "草稿",
  "SM.COMMON.LABEL.OPERLOG_OPER_TYPE": "操作类型",
  "SM.COMMON.TITLE.MEASUREMENT_INFO": "基本信息",
  "SM.ORGSTAFF.TITLE.REGION_STARTIP": "起始地址",
  "SM.ORGSTAFF.LABEL.IMPORT_TEMPLATE": "导入文件模板",
  "SM.COMMON.LABEL.SURVEY_TITLE_NAME": "题目名称",
  "SM.COMMON.LABEL.HOLIDAY_MONTH_9": "九月",
  "SM.COMMON.LABEL.HOLIDAY_IMPORT": "导入节假日",
  "SM.TENANTSPACE.LDAP_BASICDN": "基本DN",
  "SM.TENANTSPACE.LDAP_TITLE": "LDAP认证",
  "SM.COMMON.LABEL.HOLIDAY_MONTH_8": "八月",
  "SM.COMMON.LABEL.HOLIDAY_MONTH_7": "七月",
  "SM.ORGSTAFF.LABEL.BE_NAME": "运营单位名称",
  "SM.COMMON.LABEL.HOLIDAY_MONTH_6": "六月",
  "SM.COMMON.LABEL.CHARGECODE_SEARCH_CRITERIA": "查询条件",
  "SM.COMMON.LABEL.HOLIDAY_MONTH_5": "五月",
  "SM.COMMON.LABEL.HOLIDAY_MONTH_4": "四月",
  "SM.ORGSTAFF.OPTION.WEEKLIST_SEC": "第二个",
  "SM.ORGSTAFF.LABEL.WORKGROUP_NAME": "工作组名称",
  "SM.COMMON.LABEL.HOLIDAY_MONTH_3": "三月",
  "SM.COMMON.LABEL.HOLIDAY_MONTH_2": "二月",
  "SM.COMMON.LABEL.HOLIDAY_MONTH_1": "一月",
  "SM.ORGSTAFF.LABEL.ORG_STOP": "停用",
  "SM.COMMON.TIPS.CURRENCY_GROUPSIZE_TIPS": "表示金额分组显示时，每组的数字个数。0表示不分组。",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_STATUS_NORMAL": "正常",
  "SM.COMMON.LABEL.BANKCARD_BANKNAME_PARAM": "发卡行名称",
  "SM.ORGSTAFF.TITLE.REGION_EX_ATTR": "扩展属性",
  "SM.COMMON.TITLE.SUCCESS_SUCCESS": "操作成功。",
  "SM.COMMON.LABEL.MEASUREMENT_CONV_CREATE": "新建度量转换",
  "SM.ORGSTAFF.LABEL.TEAM_TEAMID": "团队标识",
  "SM.ORGSTAFF.BUTTON.ORG_EDIT": "修改",
  "SM.ORGSTAFF.TITLE.TEAM_ASSIGNEDEMPL": "已分配员工（最多绑定2000个员工）",
  "SM.ORGSTAFF.TITLE.STAFF_INFO_EXT": "扩展信息",
  "SM.ORGSTAFF.TITLE.SELECT_ORG": "选择工作组",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_BIRTHPLACE": "出生地",
  "SM.ORGSTAFF.MESSAGE.REFRESH_SUCCESS": "刷新成功。",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_ACCOUNT_VALIDATOR": "只允许输入包含数字、字母、汉字、下划线、短横线、点以及@",
  "SM.ORGSTAFF.TITLE.EMPLOYEE_IP_ADDR": "IP地址",
  "SM.ORGSTAFF.TITLE.TEAM_DELETEFSUCCESS": "删除成功。",
  "SM.ORGSTAFF.LABEL.ORG_INFO": "组织信息",
  "SM.COMMON.TIPS.CHARGECODE_NAME": "输入值不能包含特殊字符。",
  "SM.COMMON.MESSAGE.SYSMENU_AUTH_ASSIGNED_MUTIL_MENUS": "该菜单的权限分配给了多个菜单，确认要废弃菜单同时回收菜单权限吗?",
  "SM.COMMON.LABEL.MEASUREMENT_IGNORE_IMPORT": "批量导入时，若有文件导入失败，后续文件继续导入。",
  "SM.COMMON.TIPS.POPUP_LABEL_DELETE": "确认删除该数据？",
  "SM.ORGSTAFF.AUTHTYPE.DEFAULT": "默认",
  "SM.ORGSTAFF.LABEL.BE_BIZCODE": "运营单位业务编码",
  "SM.COMMON.LABEL.SYSMENU_DIS_POSTION": "展示位置",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_STATUS_FORBID": "禁用",
  "SM.ORGSTAFF.LABEL.CREATESOURCE": "创建来源",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_FORBIDFOREVER": "永久禁用",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_ONLINE_CLIENT": "客户端IP",
  "SM.COMMON.TITLE.MEASUREMENT_UNIT": "度量单位",
  "SM.ORGSTAFF.TIPS.STAFF_LOCALE": "员工默认登录语言，当登录时未选择语言时使用该语言。",
  "SM.COMMON.LABEL.ATTACHMENT_MGR_UPLOAD_SIZE_EXT_TITLE": "可上传文件大小：最大0KB，最小{1}KB；上传文件类型限制：允许（{2}），不允许（{3}）。",
  "SM.SM.COMMON.TITLE.NO_CHARGECODE": "账目类型为空。",
  "SM.COMMON.BUTTON.ATTACHMENT_MGR_SEARCH_CRITERIA_QUERY": "查询",
  "SM.ORGSTAFF.OPTION.WEEKLIST_FIV": "第五个",
  "SM.ORGSTAFF.LABEL.ATTR_ATTRSETTING": "扩展属性",
  "SM.COMMON.LABEL.HIGHRISKLOG_SERVICENAME": "服务名称",
  "SM.ORGSTAFF.MESSAGE.AUTHENTICATION": "身份验证",
  "SM.COMMON.MESSAGE.DATADICT_SURE_DELETE": "是否确定删除？",
  "SM.ORGSTAFF.OPTION.WEEKLIST_FIR": "第一个",
  "SM.COMMON.BUTTON.COMMON_ADD": "增加",
  "SM.COMMON.LABEL.SYS_MEASUREMENT_METRIC": "公制",
  "SM.ORGSTAFF.TIPS.BE_LOGO": "请选择格式为png/gif/jpg、大小不超过50KB且尺寸不超过145*50像素的图片。",
  "SM.COMMON.BUTTON.CHARGECODE_ADDMEMBER": "增加账目类型",
  "SM.COMMON.LABEL.SURVEY_QUESTIONNAIRE_TAIL": "问卷卷尾",
  "SM.ORGSTAFF.TITLE.EMPLOYEE_MATCH_VALUE": "匹配值",
  "SM.ORGSTAFF.LABEL.ORG_SUBTYPE": "子组织类型",
  "SM.ORGSTAFF.MESSAGE.SELECT_ORG": "选择一个组织。",
  "SM.COMMON.LABEL.ATTACHMENT_MGR_SEARCH_CRITERIA": "查询条件",
  "SM.COMMON.LABEL.SURVEY_ALLOW_INPUT": "可输入",
  "SM.ORGSTAFF.LABEL.BE_ID": "运营单位标识",
  "SM.ORGSTAFF.LABEL.ROLE_TYPE_DUTY": "岗位角色",
  "SM.COMMON.LABEL.SURVEY_MODIFIED_ACCOUNT": "修改人",
  "SM.ORGSTAFF.LABEL.TEAM_TDISPLAYORDER": "显示顺序",
  "SM.COMMON.LABEL.SYSMENU_LANGUAGE": "语言",
  "SM.COMMON.LABEL.MENU_ACCESS_TIME": "菜单访问时间",
  "SM.COMMON.LABEL.ERATE_INFO_LIST": "货币汇率列表",
  "SM.ORGSTAFF.BUTTON.IMPORT": "导入",
  "SM.COMMON.LABEL.MODULE_ID": "标识",
  "SM.COMMON.LABEL.OPERLOG_OPER_TIME": "操作时间",
  "SM.COMMOM.MESSAGE.BESEXCEPTION": "当前操作未能完成，请联系管理员",
  "SM.COMMON.LABEL.SURVEY_PROVINCE": "全省",
  "SM.ORGSTAFF.LABEL.STAFF_ACCOUNT": "帐号",
  "SM.ORGSTAFF.BUTTON.RESET_TEAM": "重置",
  "SM.ORGSTAFF.MESSAGE.USER_OWN_DUTY_ROLE_INVALID2": "下的岗位角色个数超过最大个数限制，最大个数限制为：",
  "SM.ORGSTAFF.MESSAGE.USER_OWN_DUTY_ROLE_INVALID1": "员工在组织：",
  "SM.COMMON.LABEL.ATTACHMENT_MGR_SEARCH_CRITERIA_ATTACHMENTCODE": "附件类型",
  "SM.ORGSTAFF.LABEL.ORG_FORCEDELETE": "永久删除",
  "SM.ORGSTAFF.TITLE.CURRENCY_TYPE": "货币类型",
  "SM.COMMON.LABEL.HIGHRISKLOGList": "高危操作监控列表",
  "SM.COMMON.LABEL.SURVEY_TITLE_SORT": "题目顺序",
  "SM.ORGSTAFF.LABEL.BANK_NAME": "银行名称",
  "SM.COMMON.LABEL.CURRENCY_CREATE_TITLE": "货币类型基本信息",
  "SM.ORGSTAFF.TIPS.REGION_DST_SWITCHINTERVAL_TIPS": "单位：秒，范围：1-86400，为空则默认为3600秒。",
  "SM.COMMON.LABEL.SURVEY_MUTI_CHOOSE": "多选",
  "SM.ORGSTAFF.OPTION.WEEKLIST_LST": "最后一个",
  "SM.ORGSTAFF.TITLE.CURRENCY_EXCHANGE_RATE": "货币汇率",
  "SM.COMMON.LABEL.BANKCARD_BANKSIGN_LENG": "发卡行标识长度",
  "SM.COMMON.LABEL.CHARGE_CODE_GROUP": "账目类型组",
  "SM.COMMON.LABEL.CHARGECODE_GROUPTYPE": "账目组分类",
  "SM.COMMON.BUTTON.PROPERTY_CREATE_GROUP": "新建扩展属性组",
  "SM.COMMON.MESSAGE.DATADICT_SAVE_SUCCESSFULLY": "保存成功。",
  "SM.ORGSTAFF.TITLE.ADDR_CFG_LIST": "地址格式模板列表",
  "SM.ORGSTAFF.OPTION.WEEKDAY_THU": "星期四",
  "SM.COMMON.LABEL.HOLIDAY_MONDAY": "一",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_ONLINE_SIGNTIME": "登录时间",
  "SM.COMMON.LABEL.OPERLOG_MODULE": "模块名称",
  "SM.COMMON.LABEL.SURVEY_OPTION_TYPE": "选项类型",
  "SM.ORGSTAFF.LABEL.ADDR_ADDRESS_NAME": "地址名称",
  "SM.ORGSTAFF.TITLE.EMPLOYEE_RESTRICT_MODE": "限制方式",
  "SM.ORGSTAFF.TITLE.EMPLOYEE_TEAM": "工作组列表",
  "SM.ORGSTAFF.TITLE.CONTACT_INFO": "主管",
  "SM.COMMON.LABEL.OPERLOG_OBJECT_TYP_NAME": "对象类型名",
  "SM.COMMON.LABEL.SYSMENU_SELECT_MENUS": "已选菜单",
  "SM.COMMON.BUTTON.SURVEY_NEW_QUESTION": "新建题目",
  "SM.COMMON.LABEL.MENU_ACCESS_END_TIME": "结束时间",
  "SM.ORGSTAFF.LABEL.AFFILIATED_BE": "所属运营单位",
  "SM.COMMON.LABEL.AREAINFO_MAINTAIN": "区号信息管理",
  "SM.ORGSTAFF.LABEL.REGION_LEVEL": "区域级别",
  "SM.ORGSTAFF.MESSAGE.REGION_VALIDATE_IPFORMATERROR": "无效的IP地址",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_GENDER": "性别",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_RESET_ALLSUCCESS": "员工密码修改成功",
  "SM.ORGSTAFF.TIPS.ENTER_ORGNAME": "输入工作组名称",
  "SM.COMMON.LABEL.SYSMENU_STATUSDATE": "状态时间",
  "SM.COMMON.LABEL.CURRENCY_MEASUREMENT_RATE": "货币度量单位比率",
  "SM.ORGSTAFF.TITLE.CONFIRM_DEL": "确认",
  "SM.COMMON.LABEL.COLON": "：",
  "SM.COMMON.BUTTON.ACCESS_RELATION": "添加访问组织",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_MARITALSTATUS": "婚否",
  "SM.COMMON.BUTTON.PROPERTY_CREATE": "新建",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_STATUS_PAUSE": "停用",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_SECONDMENT_STARTDATE": "开始日期",
  "SM.COMMON.BUTTON.ATTACHMENT_ADDACCESSORY": "保存",
  "SM.COMMON.LABEL.MEASUREMENT_SOUMEASYS": "源度量制式",
  "SM.COMMON.LABEL.ERATE_EXPDATE": "失效时间",
  "SM.ORGSTAFF.MESSAGE.DELETE_TITLE": "确认",
  "SM.COMMON.TITLE.PRJ_EXPORT": "已开始执行导出",
  "SM.ORGSTAFF.LABEL.STAFF_AUTHCOPY_TARGETNO": "目标帐号",
  "SM.ORGSTAFF.TITLE.ERROR_TITLE": "错误",
  "SM.COMMON.MESSAGE.ERATE_TIME_COMPARE": "结束时间必须晚于开始时间。",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_RESET": "员工密码修改",
  "SM.ORGSTAFF.LABEL.ADDR_DISP_DETAIL_LIST": "格式详细信息列表",
  "SM.ORGSTAFF.MESSAGE.TEAM_BLANK_VIEW": "请在组织结构树中对团队进行管理。",
  "SM.COMMON.LABEL.BANKCARD_CREATETIME": "添加时间",
  "SM.ORGSTAFF.OPTION.EMPLOYEE_ADD_ROLE": "增加角色",
  "SM.ORGSTAFF.LABEL.STAFF_INFO_BASIC": "基本信息",
  "SM.ORGSTAFF.OPTION.STAFF_IP_SINGLE": "单个IP",
  "SM.COMMON.TITLE.CONFIRM_DEL": "确认",
  "SM.ORGSTAFF.OPTION.MONTH_9": "9月",
  "SM.ORGSTAFF.OPTION.MONTH_8": "8月",
  "SM.COMMON.TITLE.SURVEY_CLASS_NAME_INFO": "分类名称信息",
  "SM.ORGSTAFF.OPTION.MONTH_7": "7月",
  "SM.COMMON.MESSAGE.SYSMENU_AUTHIDREQUIRE": "请输入菜单权限编号。",
  "SM.ORGSTAFF.OPTION.MONTH_6": "6月",
  "SM.ORGSTAFF.OPTION.MONTH_5": "5月",
  "SM.ORGSTAFF.OPTION.MONTH_4": "4月",
  "SM.ORGSTAFF.TITLE.MEASUREMENT": "度量单位",
  "SM.ORGSTAFF.MESSAGE.ORG_NOT_EXSIT": "组织不存在。",
  "SM.ORGSTAFF.OPTION.MONTH_3": "3月",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_EMPLOYEENAME": "用户名",
  "SM.ORGSTAFF.OPTION.MONTH_2": "2月",
  "SM.COMMON.LABEL.CURRENCY_TYPE_ID": "货币类型标识",
  "SM.ORGSTAFF.OPTION.MONTH_1": "1月",
  "SM.COMMON.TIPS.BANKCARD_LENGTH": "请输入长度不超过两位的整数。",
  "SM.ORGSTAFF.TITLE.TEAM_SAVESUCCESS": "保存成功。",
  "SM.ORGSTAFF.LABEL.BE_LOGO": "Logo",
  "SM.ORGSTAFF.MESSAGE.MAX_TREE_LEVEL": "您最多只能创建6个级别的子组织。",
  "SM.PROJECT.TITLE.EXPORT_SELECTED_EMPLOYEE": "导出所选项",
  "SM.ORGSTAFF.LABEL.TEAM_INCLUDESUB": "包含子部门",
  "SM.ORGSTAFF.LABEL.ORG_LEVEL": "组织级别",
  "SM.ORGSTAFF.TITLE.EMPTY": "邮箱和家庭号码不能都为空",
  "SM.ORGSTAFF.TITLE.COMMON_STAFF_INFO": "员工帐号列表",
  "SM.COMMON.TITLE.ACCESS.SELECT_ALL_STAFF": "选择该组织所有员工",
  "SM.COMMON.LABEL.SYSMENU_IDEL": "发布",
  "SM.COMMON.LABEL.ATTACHMENT_MGR_SEARCH_CRITERIA_ATTACHMENTNAME": "附件名称",
  "SM.COMMON.LABEL.SYSMENU_MENUID": "菜单项ID",
  "SM.ORGSTAFF.LABEL.ROLETMPL_PERMISSION_NAME": "功能权限名称",
  "SM.COMMON.LABEL.CURRENCY_TYPE_INPUT_TIPS": "请输入货币类型名称",
  "SM.ORGSTAFF.LABEL.ADDR_ORDER": "显示顺序",
  "SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE_TEMP_LIST": "调查问卷列表",
  "SM.ORGSTAFF.TITLE.EMPLOYEE_RESTRICTIONTYPE": "处理方式",
  "SM.COMMON.LABEL.MEASUREMENT_SOUMEAUNIT": "源度量单位",
  "SM.ORGSTAFF.MESSAGE.ERROR.SAVEFAILED": "保存失败",
  "SM.ORGSTAFF.LABEL.TEAM_ACCESS_EMPLOYEE": "访问员工",
  "SM.ORGSTAFF.LABEL.NO": "序号",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_TERMINATE_SECONDMENT": "终止借调",
  "SM.COMMON.LABEL.HOLIDAY_TUESDAY": "二",
  "SM.COMMON.LABEL.CHARGECODE_DESC": "描述",
  "SM.ORGSTAFF.BUTTON.EMPLOYEE_EXPORTALL": "导出",
  "SM.ORGSTAFF.OPTION.QUERYSUBDEPT": "查询下级部门",
  "SM.COMMON.LABEL.MEASUREMENT_DESTMEASYS": "目标度量制式 ",
  "SM.ORGSTAFF.MESSAGE.SELECTEDOU_CANNOT_SELECTED_AGAIN": "已选择的组织不能再次选择。",
  "SM.ORGSTAFF.MESSAGE.STAFF_DELETE_CONFIRM": "员工被删除后，该员工对应的帐号不可恢复只能重新创建。是否删除？",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_ONLINE_BE": "运营单位",
  "SM.ORGSTAFF.LABEL.REGION_DST_ENDTIME": "夏令时结束时间",
  "SM.TENANTSPACE.LDAP_SERVERURL": "LDAP服务器URL",
  "SM.ORGSTAFF.TITLE.BE_CURRENCYCODE": "货币编码",
  "SM.ORGSTAFF.TITLE.ATTR_ATTRSETTING": "扩展属性",
  "SM.COMMON.LABEL.BANKCARD_TYPE": "卡种",
  "SM.COMMON.MESSAGE.DELETE_SUCCESS": "删除成功",
  "SM.COMMON.TIPS.NEED_TO_NEW_STD_MIN_CURRENCY_UNIT": "货币需要配置标准单位和最小单位。",
  "SM.COMMON.LABEL.OPERLOG_OPER_LIST": "操作日志列表 ",
  "SM.ORGSTAFF.LABEL.INH_BY_SUB": "是否允许下级组织继承",
  "SM.COMMON.LABEL.MEASUREMENT_ERROR_DESCRIPTION": "错误描述",
  "SM.COMMON.LABEL.SYSMENU_SUSPENDED_S": "下线",
  "SM.COMMON.LABEL.ERATE_DESCRIPTION": "描述",
  "SM.ORGSTAFF.TITLE.CHARGE_CODE_GROUP": "账目类型组",
  "SM.COMMON.TIPS.CURRENCY_DECIMALPOSITIONS_TIPS": "取值为-1时表示不限制，按货币存储精度显示。",
  "SM.COMMON.LABEL.OPERLOG_CONTENT": "操作内容",
  "SM.ORGSTAFF.LABEL.TEAM_MANAGERINFO": "主管信息",
  "SM.COMMON.MESSAGE.MODIFY_AREAID_TYPE": "存在相同的信息，请修改地区编码或地区类型。",
  "SM.COMMON.LABEL.SYSMENU_ACTIVE": "上线",
  "SM.ORGSTAFF.OPTION.YES": "是",
  "SM.ORGSTAFF.LABEL.ORG_REGION": "区域",
  "SM.ORGSTAFF.BUTTON.ADD_IN": "添加",
  "SM.COMMON.LABEL.SYS_MEASUREMENT_DEFAULT": "默认制式",
  "SM.ORGSTAFF.LABEL.STAFF_PASSWORD": "密码",
  "SM.ORGSTAFF.LABEL.TEAM_ORGANIZATION": "组织",
  "SM.COMMON.MESSAGE.CHARGECODE_TO_RETIRE": "是否确定切换到撤销状态？",
  "SM.ORGSTAFF.LABEL.ACCESS.ORG_REQUIRED": "请选择组织",
  "SM.ORGSTAFF.TITLE.REGION_USEPARENT": "继承上级行政区域",
  "SM.ORGSTAFF.TITLE.CONTACT_MIDDLE_NAME": "联系人中间名",
  "SM.ORGSTAFF.LABEL.STAFF_PASSWORDAGAIN": "再次输入密码",
  "SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE": "菜单",
  "SM.COMMON.LABEL.HIGHRISKLOG_PERATOR": "操作员",
  "SM.ORGSTAFF.TITLE.ASSIGNE_MANAGER": "分配主管",
  "SM.COMMON.BUTTON.MEASUREMENT_IMPORT": "导入",
  "SM.COMMON.LABEL.ATTACHMENT_ADD_TITLE": "新建附件",
  "SM.ORGSTAFF.MESSAGE.PWD.CHECKMSG": "两次输入的密码不一致",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_FORCE_DELETE": "强制删除",
  "SM.ORGSTAFF.BUTTON.TEAM_CANCEL": "取消",
  "SM.ORGSTAFF.MESSAGE.COMMON_TIPS_STAFF_SELECT": "请先选择人员。",
  "SM.COMMON.LABEL.SYSMENU_DEACTIVATED_D": "废弃",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_RESUME": "恢复",
  "SM.ORGSTAFF.MESSAGE.STAFF_PWD_RESET_CONFIRM": "是否重置密码？",
  "SM.COMMON.LABEL.HOLIDAY_HOLIDAYDESCRIPTION": "节假日描述",
  "SM.ORGSTAFF.LABEL.STAFF_ROLE_EFFECTIVE_OU_ADD": "选择组织",
  "SM.COMMON.LABEL.SYSMENU_CREATEDATE": "创建时间",
  "SM.COMMOM.BUTTON.IMPORT": "导入",
  "SM.ORGSTAFF.TIPS.ENTER_EMPNAME": "输入用户名",
  "SM.ORGSTAFF.LABEL.ORG_ORGID": "组织ID",
  "SM.ORGSTAFF.MESSAGE.ADDR_USE_DELETE": "删除失败，地址已被引用。",
  "SM.ORGSTAFF.MESSAGE.BLANK_VIEW": "请在组织结构树中对部门进行管理。",
  "SM.ORGSTAFF.TITLE.CONTACT_EMPLOYEE_CODE": "联系员工",
  "SM.COMMON.MESSAGE.PROJECT_CODE": "工程编码为：",
  "SM.COMMON.LABEL.AREAID": "地区标识",
  "SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_UPLOADTIME": "上传时间",
  "SM.ORGSTAFF.LABEL.CHANNEL_ID": "渠道标识",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_ROAM_INVALID": "无效",
  "SM.ORGSTAFF.LABEL.INPUT_SEARCH_KEYWORD": "请输入关键字",
  "SM.COMMON.LABEL.MEASUREMENT_EXPORT_FILE_FORMAT": "导出文件格式",
  "SM.COMMON.LABEL.MEASUREMENT_EXPORT_FAILURE": "失败",
  "SM.ORGSTAFF.MESSAGE.ORG_ROLE_DELETE": "如果该角色已经赋给员工，该删除操作会同时将员工和该角色的关联关系删除，是否继续？",
  "SM.COMMON.LABEL.SURVEY_DESC": "描述 ",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PVT": "密码有效期类型",
  "SM.COMMON.LABEL.CHARGECODE_OPERATION": "操作",
  "SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_ATTACHMENTCODE": "附件类型",
  "SM.COMMON.LABEL.ERATE_SEARCH_CRITERIA": "查询条件",
  "SM.COMMON.LABEL.MODULE_MODULETYPE": "类别",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_ROAM_CREATEDATE": "创建日期",
  "SM.COMMON.LABEL.OPERLOG_OBJECT_NAME": "对象名称",
  "SM.ORGSTAFF.TITLE.EMPLOYEE_LOGIONRESTRICTION": "登录限制",
  "SM.COMMON.LABEL.SYSMENU_PARENT": "父菜单项ID",
  "SM.COMMON.TIPS.PLZ_SAVE_CURRENCY_MEASUREMENT_UNIT": "请保存货币度量单位。",
  "SM.COMMON.LABEL.CURRENCY_FORMAT_TITLE": "货币显示格式",
  "SM.COMMON.LABEL.SYSMENU_GRANT_MENU_AUTH": "菜单权限分配",
  "SM.COMMON.LABEL.ATTACHMENT_MGR_LIST": "附件列表",
  "SM.ORGSTAFF.TITLE.EMPLOYEE_MAC_ADDR": "MAC地址",
  "SM.COMMON.LABEL.MEASUREMENT_YES": "是",
  "SM.ORGSTAFF.LABEL.ADDR_REGION_LAYER": "行政区域层级",
  "SM.COMMON.TITLE.ACCESS.FILTER": "请输入关键词过滤",
  "SM.ORGSTAFF.TITLE.TEAM_SUCCESS": "提示",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_TITLE": "职位",
  "SM.ORGSTAFF.OPTION.WEEKLIST_TID": "第三个",
  "SM.ORGSTAFF.LABEL.STAFF_ROLE_EFFECTIVE_OU_DETAIL": "详情",
  "SM.ORGSTAFF.OPTION.WEEKDAY_WED": "星期三",
  "SM.ORGSTAFF.LABEL.ORG_RECOVER": "恢复",
  "SM.ORGSTAFF.MESSAGE.DELETE_MSG": "是否确定删除？",
  "SM.ORGSTAFF.TITLE.BE_CURRENCYNAME": "货币名称",
  "SM.COMMON.LABEL.CURRENCY_DISPLAY_PREFIX": "展示前缀",
  "SM.COMMON.LABEL.ADD_EXCHANGE_RATE": "增加/调整关联货币汇率",
  "SM.COMMON.BUTTON.SAVE": "保存",
  "SM.ORGSTAFF.LABEL.TEAM_REFRESH": "刷新",
  "SM.COMMON.TITLE.ERATE_ERROR": "错误",
  "SM.ORGSTAFF.LABEL.STAFF_ROLE_NAME": "角色名称",
  "SM.ORGSTAFF.LABEL.TEAM_EMPLOYEENAME": "员工姓名",
  "SM.COMMON.LABEL.SURVEY_TYPE_NAME": "分类名称",
  "SM.ORGSTAFF.LABEL.REGION_CONFIG_TIMEZONE": "区域缺少时区信息，请为区域或者上级区域配置时区。",
  "SM.ORGSTAFF.TITLE.IMPORT_EMPLOYEE_TITLE": "导入员工",
  "SM.COMMON.LABEL.HIGHRISKLOG_PARAMS": "参数",
  "SM.ORGSTAFF.LABEL.TEAM_ACCOUNT": "帐号",
  "SM.ORGSTAFF.OPTION.STAFF_RESTRICTIONTYPE_DISALLOWED": "拒绝",
  "SM.ORGSTAFF.MESSAGE.BE_SELECT_DEFAULT_CURRENCY": "请选择默认货币。",
  "SM.COMMON.BUTTON.CHARGECODE_CONFIRM": "提示",
  "SM.ORGSTAFF.TITLE.CONTACT_FIRST_NAME": "联系人名称",
  "SM.COMMOM.LABEL.SURVEY_INFO": "问卷信息",
  "SM.ORGSTAFF.LABEL.BANK_CHILEBEUSED": "是否允许下级BE使用",
  "SM.COMMON.LABEL.HEADING_BASEINFO": "基本信息",
  "SM.ORGSTAFF.LABEL.TEAM_ADD": "添加",
  "SM.ORGSTAFF.TITLE.TEAM_AVAILABLEEMPL": "待分配员工",
  "SM.ORGSTAFF.LABEL.STAFF_INFO": "员工信息",
  "SM.ORGSTAFF.LABEL.STAFF_ROLE_ID": "角色编号",
  "SM.COMMON.LABEL.SYSMENU_VERSION": "版本",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_LASTNAME": "姓氏",
  "SM.ORGSTAFF.LABEL.TEAM_BUSINESSENTITY": "运营单位",
  "SM.COMMON.LABEL.SYSMENU_CURRENCYLANGUAGE": "当前语言",
  "SM.ORGSTAFF.TITLE.CONTACT_HOME_PHONE": "家庭电话",
  "SM.ORGSTAFF.LABEL.CHANNEL_NAME": "渠道名称",
  "SM.COMMON.MESSAGE.CHARGECODE_DELETE_GROUP": "是否确定删除账目类型组？",
  "SM.COMMON.LABEL.AREATYPE": "地区类型",
  "SM.COMMON.LABEL.SYSMENU_IDEL_I": "发布",
  "SM.COMMON.MESSAGE.CURRENCY_UNIT_TIPS": "请输入数字",
  "SM.COMMON.LABEL.MEASUREMENT_ENTITY_TYPE": "实体类型",
  "SM.ORGSTAFF.LABEL.ORG_IMPORTSUCCESS": "导入数据成功。",
  "SM.COMMON.TIPS.MENU_REBUILD_INFO": "重建菜单索引信息已通知到MQ，请稍等。",
  "SM.ORGSTAFF.LABEL.SELECTED_ROLE": "已选择角色",
  "SM.COMMON.LABEL.MEASUREMENT_CONV_RATIO": "度量单位比率",
  "SM.COMMON.LABEL.OPERLOG_SEARCH": "查询",
  "SM.COMMON.LABEL.SYSMENU_GRANT_AUTH": "权限管理",
  "SM.ORGSTAFF.LABEL.BREGION_NAME": "区域",
  "SM.ORGSTAFF.TITLE.BE_DEFAULT": "默认货币",
  "SM.COMMON.TITLE.MEASUREMENT_UNITLIST": "度量单位列表",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_BE": "运营单位",
  "SM.COMMON.LABEL.HIGHRISKLOG_OPERATIONNAME": "操作名称",
  "SM.COMMON.MESSAGE.DELETE_CURRENCY_STD_MIN_MEASUREMENT": "货币需要使用标准单位和最小单位，确认要删除？",
  "SM.ORGSTAFF.LABEL.STAFF_ROLE_PATH": "路径",
  "SM.ORGSTAFF.TITLE.MSGBOX_INFO": "提示",
  "SM.ORGSTAFF.OPTION.WEEKDAY_MON": "星期一",
  "SM.COMMON.LABEL.CURRENCY_TYPE_FLAG": "虚拟货币",
  "SM.COMMON.LABEL.OPERLOG_LOGIN_IP": "登录IP",
  "SM.COMMON.LABEL.CHARGECODE_GROUPMEMBER": "账目类型列表",
  "SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE_NAME_LANG": "问卷名称语种",
  "SM.COMMON.LABEL.CURRENCY_ROUNDMODE": "四舍五入模式",
  "SM.ORGSTAFF.LABEL.ACCESS.ADD_SUCCESS": "添加成功",
  "SM.COMMON.LABEL.SYSMENU_MENU_AUTH_ROLE_LIST": "已赋角色列表",
  "SM.COMMON.TITLE.BANKCARD_CONFIRM": "提示",
  "SM.COMMON.LABEL.HOLIDAY_MONTH_12": "十二月",
  "SM.COMMON.LABEL.HOLIDAY_MONTH_11": "十一月",
  "SM.COMMON.LABEL.HOLIDAY_MONTH_10": "十月",
  "SM.COMMON.LABEL.CHARGECODE_DATAGIRD_CATALOG": "目录",
  "SM.COMMON.TIPS.ONLYONE_UNDERLINE_FORBIDDEN": "输入值不能包含特殊字符。",
  "SM.ORGSTAFF.LABEL.ADDR_PLEASE_INPUT": "请输入名称",
  "SM.COMMOM.BUTTON.EDIT": "修改",
  "SM.ORGSTAFF.MESSAGE.SUCCESS.SAVE": "成功",
  "SM.COMMON.LABEL.MEASUREMENT_NOTIFICATION_NAME": "提醒名称",
  "SM.COMMON.LABEL.BANKCARD_BANKTYPE": "发卡行类型",
  "SM.ORGSTAFF.BUTTON.ADDR_LINE": "行",
  "SM.ORGSTAFF.TITLE.TEAM_DELETE_CONFIRM": "确认",
  "SM.COMMON.LABEL.CHARGECODE_RETIRE": "撤销",
  "SM.COMMON.TIPS.SPECIAL_CHAR_CANNOT_START_NUMBER": "不允许以数字开始",
  "SM.COMMON.LABEL.CURRENCY_DISPLAY_FORMATE": "呈现格式",
  "SM.ORGSTAFF.LABEL.TEAM_CONTACTINFO": "联系信息",
  "SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_ATTACHMENTNAME": "附件名称",
  "SM.ORGSTAFF.LABEL.ADDR_ADDERSS": "地址",
  "SM.ORGSTAFF.MESSAGE.SURE_TO_DO": "是否确定操作？",
  "SM.ORGSTAFF.LABEL.TEAM_TEAMSHORTNAME": "团队简称",
  "SM.ORGSTAFF.TITLE.BANK_TITLE": "银行",
  "SM.COMMON.MESSAGE.CHARGECODE_OPERATION_SUCCESS": "操作成功。",
  "SM.ORGSTAFF.MESSAGE.USERRESTRICT_CONFIRM_SAVE": "登录时间段限制只能保留一条记录，增加时会删除其他登录时间段限制。",
  "SM.COMMON.LABEL.BANKCARD_BANKSIGN_PARAM": "发卡行标识",
  "SM.COMMON.BUTTON.SURVEY_NEW_ANSWER_OPTIONS": "新建题目选项",
  "SM.ORGSTAFF.TIPS.MAC_FORMAT_ERROR": "MAC地址格式错误",
  "SM.COMMON.LABEL.MODULE_BASICINFO": "基础信息",
  "SM.ORGSTAFF.LABEL.REGION_DST_MODE": "夏令时模式",
  "SM.COMMON.BUTTON.EDIT": "修改",
  "SM.COMMON.MESSAGE.ERATE_VLUEW_RANGE": "取值范围在0和{1}之间。",
  "SM.COMMON.LABEL.PROVINCE": "省份",
  "SM.ORGSTAFF.LABEL.STAFF_OPRATION": "操作",
  "SM.COMMON.LABEL.ATTACHMENT_LIMIT_INFO": "无此限制",
  "SM.COMMON.MESSAGE.BANKCARD_DELETE_CONFIRM": "是否确定删除?",
  "SM.COMMON.LABEL.SYSMENU_DESCRIPTION": "菜单项说明",
  "SM.COMMON.MESSAGE.CURRENCY_UNIT_EXCHAGE_TIPS": "请输入整数",
  "SM.ORGSTAFF.MESSAGE.CHOOSE_BE_FIRST": "请先选择运营单位",
  "SM.COMMON.LABEL.MODULE_NAME": "名称",
  "SM.ORGSTAFF.TITLE.MEASUREMENT_CONV": "度量转换",
  "SM.COMMON.LABEL.SURVEY_REMARKS": "备注",
  "SM.ORGSTAFF.OPTION.ROLE_OPTION_MODE": "操作类型",
  "SM.ORGSTAFF.LABEL.OU_HIERARCHY": "组织层级关系",
  "SM.COMMON.BUTTON.VIEW": "查看",
  "SM.COMMON.MESSAGE.CHARGECODE_CREATE_DATA": "是否确定添加账目类型？",
  "SM.ORGSTAFF.BUTTON.ADD": "新建",
  "SM.ORGSTAFF.MESSAGE.STAFF_SUSPEND_CONFIRM": "是否确定要挂起员工帐号？",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_SVD": "会话超时时长",
  "SM.ORGSTAFF.MESSAGE.BANK_STATUS_ERROR": "保存失败，银行信息已经失效。",
  "SM.COMMON.LABEL.HOLIDAY_BASICINFORMATION": "基本信息",
  "SM.ORGSTAFF.LABEL.ORG_ADDITIONINFO": "附加信息",
  "SM.ORGSTAFF.TITLE.BE_ADDRESS_CFG": "地址格式",
  "SM.ORGSTAFF.TITLE.STAFF_INFO_MDY": "修改员工",
  "SM.COMMON.TITLE.ACCESS.SELECT_ORG": "选择访问组织",
  "SM.ORGSTAFF.MESSAGE.STAFF_FORBID_CONFIRM": "员工被永久禁用后，该员工对应的帐号只有删除后才能重复创建。是否永久禁用？",
  "SM.ORGSTAFF.MESSAGE.ERROR.SAVEACCESSLIMIT": "可访问员工每批最多添加100个",
  "SM.COMMON.LABEL.BANKCARD_LIST": "银行卡列表",
  "SM.COMMON.LABEL.OU_HIERARCHY_NAME": "组织关系名称",
  "SM.COMMON.MESSAGE.SYSMENU_MENUNAMEREQUIRE": "请输入菜单项名称。",
  "SM.COMMON.MESSAGE.MEASUREMENT_SAVE_CONFIRM": "保存标准度量单位时会替换已存在的标准单位，同时修改其他度量单位的标准单位。",
  "SM.COMMON.LABEL.SYSMENU_SEARCH_MENU": "菜单查询",
  "SM.ORGSTAFF.TITLE.ADDR_TEMPATE_ID": "模板ID",
  "SM.ORGSTAFF.TITLE.ADDR_DISP_FORMAT": "展现格式",
  "SM.ORGSTAFF.MESSAGE.STARTANDTEND_ISNULL": "开始日期与结束日期不能同时为空。",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_TIMERANGE": "时间范围",
  "SM.ORGSTAFF.LABEL.SELECT_EXCEL": "请选择一个Excel文件",
  "SM.ORGSTAFF.TITLE.EMP_BELONG_DEPT": "所属部门",
  "SM.ORGSTAFF.LABEL.BUSINESSENTITY_DISPLAY_NO": "显示顺序",
  "SM.COMMON.BUTTON.CHARGECODE_CANCEL": "取消",
  "SM.ORGSTAFF.TITLE.EMPLOYEE_ADDR": "地址",
  "SM.ORGSTAFF.LABEL.TEAM_TEAMTYPE": "团队类型",
  "SM.COMMON.LABEL.SYSMENU_MENUCRUMB": "菜单目录",
  "SM.COMMON.LABEL.SURVEY_QUESTIONNAIRE_NAME": "问卷名称",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_NEW_PASSWORD_CONFIRM": "确认新密码",
  "SM.COMMON.LABEL.CURRENCY_TYPE": "货币类型",
  "SM.COMMON.BUTTON.ATTACHMENT_MGR_SEARCH_CRITERIA_RESET": "重置",
  "SM.COMMON.LABEL.MENU_ATTR_TITLE": "扩展属性",
  "SM.ORGSTAFF.LABEL.TEAM_REMOVE": "移除",
  "SM.ORGSTAFF.BUTTON.SAVE": "保存",
  "SM.COMMON.LABEL.CHOOSE_OU_ADD": "选择组织",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_COPYPERMISSION": "复制权限",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD41": "限定的时间段连续登录失败锁定(分钟)",
  "SM.ORGSTAFF.MESSAGE.REGION_VALIDATE_IPRANGEERROR": "IP地址段配置错误。",
  "SM.ORGSTAFF.MESSAGE.ERROR.MAXLIMIT": "租户下的机构数量不能超过999",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD40": "自定义规则",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_ONLINE_ACCOUNT": "帐号",
  "SM.COMMON.LABEL.CREATE_AREAINFO_MAINTAIN": "新建区号信息",
  "SM.COMMON.TITLE.BANKCARD_MDF": "银行卡-修改",
  "SM.ORGSTAFF.TIPS.RESTRICTMAC": "请输入正确的Mac地址。",
  "SM.ORGSTAFF.TITLE.QUERYFAIL_TITLE": "查询失败。",
  "SM.COMMON.BUTTON.CREATE": "新建",
  "SM.COMMON.MESSAGE.VALIDATE_MAXLENGTHB": "最多只能输入0个字符。",
  "SM.COMMON.LABEL.HOLIDAY_HOLIDAYTYPE": "节假日类型",
  "SM.COMMON.LABEL.SURVEY_MODIFIED_TIME": "修改时间",
  "SM.COMMON.LABEL.OPERLOG_SUCCESS_MEW": "成功",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD39": "密码只能包含数字",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD38": "密码不能包含的字符",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD37": "密码不能和帐号或帐号的子集相同",
  "SM.COMMON.MESSAGE.SYSMENU_MAX_EDIT_LIMIT": "超过最大编辑上限。",
  "SM.COMMON.MESSAGE.CHARGECODE_TO_DRAFT": "是否确定切换到草稿状态？",
  "SM.COMMON.BUTTON.BANKCARD_RESET": "重置",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD36": "不允许的密码格式列表，如ABCABC,ABCDEF等",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD35": "默认密码",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD34": "密码最短有效期(小时)",
  "SM.COMMON.LABEL.MENU_ACCESS_STRART_TIME": "开始时间",
  "SM.COMMON.LABEL.MEASUREMENT_RESULT_LIST": "结果列表",
  "SM.ORGSTAFF.MESSAGE.ADDR_DELETE_SUCCESS": "删除成功。",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD33": "密码不允许为连续字符或数字，例如：AB,123,AA,abc等",
  "SM.COMMON.LABEL.ATTACHMENT_MGR_UPLOAD_SIZE_MIN": "上传文件大小小于最小限制（最小0 KB）。",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD32": "归属于密码中必须包含的每种字符类型的最少字符数",
  "SM.ORGSTAFF.LABEL.ORG_TYPE": "组织类型",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD31": "不符合帐号最小长度",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD30": "不符合帐号最大长度",
  "SM.COMMON.LABEL.OPERLOG_SERRCH_CRITERIA": "查询条件",
  "SM.COMMON.MESSAGE.OPERLOG_TIME_LOG2": "天",
  "SM.COMMON.MESSAGE.OPERLOG_TIME_LOG1": "时间范围必须小于或者等于",
  "SM.COMMON.LABEL.CURRENCY_BASIC_INFO": "基本信息",
  "SM.ORGSTAFF.TITLE.RELATION_CONFIRM_DELETE": "禁用后，该组织关系和其下级关系将被删除，确认禁用？",
  "SM.COMMON.LABEL.ERATE_SCURRENCY_CODE": "源货币编码",
  "SM.COMMON.LABEL.SELECTED_OUS": "已选组织",
  "SM.ORGSTAFF.LABEL.REGION_DST_STARTTIME": "夏令时开始时间",
  "SM.ORGSTAFF.TITLE.POPUP_NEW_PROPERTY": "新建属性",
  "SM.COMMON.LABEL.SURVEY_TITLE_TYPE": "题目类型",
  "SM.ORGSTAFF.MESSAGE.DELETE_CONFIRM": "是否确定删除？",
  "SM.ORGSTAFF.OPTION.NO": "否",
  "SM.ORGSTAFF.LABEL.BE_TYPE": "运营单位类型",
  "SM.COMMON.LABEL.OPERLOG_SESSION_ID": "会话标识",
  "SM.COMMON.MESSAGE.MEASUREMENT_STANDARD_MUSTEXIST": "度量制式必须要有标准单位。",
  "SM.ORGSTAFF.OPTION.WEEKDAY_SUN": "星期日",
  "SM.ORGSTAFF.LABEL.STAFF_ROLE_EFFECTIVE_OU": "生效组织",
  "SM.ORGSTAFF.VALIDATOR.AFTER_TODAY": "输入小于等于今天的时间。",
  "SM.SM.COMMON.TITLE.GROUP_NAME_EXIST": "群组名称已存在",
  "SM.ORGSTAFF.LABEL.ORG_RECOVER_TIPS": "恢复后，组织或组织和其下属组织将可用。确认恢复？",
  "SM.ORGSTAFF.TITLE.EMPLOYEE_LOGINTIMERANGE": "登录时间段",
  "SM.COMMON.LABEL.CURRENCY_MINIMUM_MEASUREMENT": "最小货币单位",
  "SM.ORGSTAFF.TITLE.BE_ADDRESS_INFO": "地址信息",
  "SM.ORGSTAFF.TIPS.ENTER_OUNAME": "请输入组织名称",
  "SM.COMMON.LABEL.SURVEY_SECTION": "地区",
  "SM.COMMON.TIPS.SPECIAL_CHAR_FORBIDDEN": "输入值不能包含特殊字符。",
  "SM.SM.COMMON.TITLE.NO_CHINAWORD": "不允许出现特殊字符",
  "SM.COMMON.LABEL.SYS_MEASUREMENT_NAME": "名称",
  "SM.COMMON.LABEL.SURVEY_CREATED_ACCOUNT": "创建人",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD19": "用户首次登录系统或者重置密码后强制修改密码",
  "SM.COMMON.LABEL.MODULE_MODULELIST": "模块列表",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD18": "密码失效提前几天提醒用户修改密码",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_SECONDMENT_HISTORY": "借调历史",
  "SM.COMMON.TITLE.SURVEY_ITEM_NAME_INFO": "项目名称信息",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD15": "密码最大重试次数",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD14": "密码最长有效期(天)",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD13": "密码不能包含姓名",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD12": "密码不能包含帐号和帐号的逆序",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD11": "不合法的密码",
  "SM.COMMON.LABEL.SURVEY_NOT_INPUT": "不可自行输入",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD10": "不符合有效的特殊字符",
  "SM.COMMON.MESSAGE.SYSMENU_SAVESUCCESS": "操作成功。",
  "SM.ORGSTAFF.TITLE.CONTACT_LAST_NAME": "联系人姓氏",
  "SM.COMMON.LABEL.MEASUREMENT_CONV": "度量转换",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_STATUS": "状态",
  "SM.COMMON.LABEL.MEASUREMENT_UNIT_STANDARD": "标准度量",
  "SM.ORGSTAFF.LABEL.REGION_DISPLAYORDER": "显示顺序",
  "SM.ORGSTAFF.LABEL.TEAM_TO": "至",
  "SM.COMMON.LABEL.SURVEY_CLASS_NAME": "分类名称",
  "SM.COMMON.LABEL.BANKCARD_TYPE_PARAM": "卡种",
  "SM.ORGSTAFF.LABEL.STAFF_AUTHCOPY_SOURCENO": "源帐号",
  "SM.COMMON.LABEL.CURRENCY_GROUPSYMBOL": "分组符号",
  "SM.ORGSTAFF.TITLE.STAFF_ROLE_BASIC": "角色",
  "SM.ORGSTAFF.TITLE.ADDR_DISP_LIST": "地址展现样式列表",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD09": "密码最大连续重复字符个数",
  "SM.COMMON.LABEL.DATA_OPRATION": "操作",
  "SM.ORGSTAFF.LABEL.TEAM_EXTENDPRO": "扩展属性",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD08": "密码不能和最近几次密码相同",
  "SM.COMMON.LABEL.SURVEY_QUESTIONNAIRE_ID": "问卷编号",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD07": "密码必须包含特殊字符",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD06": "密码必须包含小写字母",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD05": "密码必须包含大写字母",
  "SM.COMMON.LABEL.MEASUREMENT_UNIT_CODE": "编码",
  "SM.ORGSTAFF.MESSAGE.ADDR_LEVELS_HIGH": "地址不连续。",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD04": "密码必须包含字母",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD03": "密码必须包含数字",
  "SM.ORGSTAFF.LABEL.PARENT_BE": "上级运营单位",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD02": "不符合密码最小长度",
  "SM.ORGSTAFF.MESSAGE.SELECT_WG_INFO": "请选择工作组。",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD01": "不符合密码最大长度",
  "SM.COMMON.BUTTON.PROPERTY_CONFIRM": "确定",
  "SM.COMMON.LABEL.EDIT_AREAINFO_MAINTAIN": "修改区号信息",
  "SM.COMMON.TITLE.PROPERTY_SUCCESS": "提示",
  "SM.ORGSTAFF.LABEL.ORG_SELECTORG": "选择组织",
  "SM.COMMON.TITLE.ITEM_NOTE": "查看图片",
  "SM.COMMON.BUTTON.SEARCH": "查询",
  "SM.COMMON.LABEL.ERATE_EXCHANGE_RATE": "兑换汇率",
  "SM.ORGSTAFF.BUTTON.EMPLOYEE_DELETE": "删除",
  "SM.COMMON.BUTTON.DELETE": "删除",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_MATCH_MODE": "匹配方式",
  "SM.ORGSTAFF.TITLE.WORKGROUP": "工作组",
  "SM.COMMON.LABEL.PROPERTY_CREATE_PROPERTY": "新建属性",
  "SM.ORGSTAFF.MESSAGE.RECOVERORG": "请恢复组织。",
  "sm.edit.usergroup": "编辑用户群组",
  "SM.AUC.LABEL.LOGINLOG_EMPLOYEE_NAME": "员工姓名",
  "SM.AUC.LABEL.LOGINLOG_LOGIN_IP_ADDRESS": "登录IP地址",
  "SM.AUC.LABEL.LOGINLOG_OPER_CONTENT": "操作内容",
  "SM.AUC.LABEL.LOGINLOG_OPER_TIME": "操作日期",
  "SM.ORGSTAFF.TIP.ACCESS_EMPLOYEE": "不属于本组织，但允许访问及使用本组织资源的员工。",
  "common.bremui.title.operation": "操作",
  "SM.ORGSTAFF.HINT.PLEASE_INPUT": "请输入",
  "SM.MENU.SEARCH.INPUT": "搜索菜单名称",
  "SM.MENU.ADD.CUSTOM.MENU": "添加菜单",
  "SM.MENU.ADD.CUSTOM.PERM_REPEAT": "权限名称重复",
  "SM.AUC.MESSAGE.PWDR_VALIDATE": "校验规则",
  "SM.AUC.MESSAGE.PWDR_MANAGE": "管理规则",
  "SM.AUC.MESSAGE.RULEGROUP": "规则组",
  "SM.AUC.MESSAGE.RULEGROUPNAME": "规则组名称",
  "SM.AUC.MESSAGE.RULEGROUPList": "规则列表",
  "SM.AUC.MESSAGE.RULEGROUPListEDIT": "编辑规则组",
  "SM.AUC.MESSAGE.RULEGROUPListADD": "新建规则组",
  "SM.AUC.MESSAGE.LOGIN.IP": "登录IP",
  "SM.AUC.MESSAGE.PWDRULE_ACCOUNT": "帐号规则",
  "SM.AUC.LABEL.PWD_PWDRULE": "密码规则",
  "SM.AUC.LABEL.PWD_CUSTOMIZEDRULE": "自定义",
  "SM.AUC.LABEL.KIND": "分类",
  "SM.AUC.LABEL.VALUE": "值",
  "SM.MENU.DELETE.CUSTOM.ROLE.FAIL": "菜单删除成功，菜单关联权限删除失败，请检查该权限是否仍被其它菜单或角色引用",
  "SM.AUC.ACCOUNT.LOCK.SEARCH.ACCOUNT": "搜索帐号",
  "SM.AUC.ACCOUNT.LOCK.USER.NOT.EXIT": "用户不存在",
  "SM.AUC.LABEL.LOGINSESSION_SESSION": "会话状态",
  "SM.AUC.LABEL.LOGINSESSION_LOGINSTATUS": "登录状态",
  "SM.AUC.LABEL.USERLOGUI_LOGINIDACCOUNT": "帐号",
  "SM.AUC.MESSAGE.OPERLOG_TIPS_LOGOUT": "强制退出",
  "SM.AUC.LABEL.LOGINSESSION_LOCKSTATUS": "锁定状态",
  "SM.AUC.LABEL.LOGINSESSION_LOCKTIME": "锁定时间",
  "SM.AUC.LABEL.LOGINSESSION_LOCK": "锁定",
  "SM.AUC.LABEL.LOGINSESSION_UNLOCK": "解锁",
  "SM.AUC.LABEL.LOGINSESSION_SURE_LOCK": "是否确认锁定该用户？",
  "SM.AUC.LABEL.LOGINSESSION_LOCKFLAG": "锁定标记",
  "SM.AUC.LABEL.LOGINSESSION_LOCKTYPE": "锁定类型",
  "SM.AUC.LABEL.LOGINSESSION_LOCK_EMPLOYEE": "锁定人",
  "SM.AUC.LABEL.LOGINSESSION_LOCKBYHAND": "手工锁定",
  "SM.AUC.LABEL.LOGINSESSION_UNLOCKBYHAND": "手工解锁",
  "SM.AUC.LABEL.LOGINSESSION_AUTHSTATUS": "认证状态",
  "SM.AUC.LABEL.LOGINSESSION_NEARESTPAWDAUTHTYPE": "最近密码认证状态",
  "SM.AUC.LABEL.LOGINSESSION_NEARESTPWDAUTHTIME": "最近密码认证时间",
  "SM.AUC.LABEL.LOGINSESSION_PWDAUTHFAILTIME": "密码认证失败次数",
  "SM.AUC.MESSAGE.LOGINSESSION_LOGININ_STATUS": "已登录",
  "SM.AUC.MESSAGE.LOGINSESSION_LOGOUT_STATUS": "未登录",
  "SM.AUC.MESSAGE.LOGINSESSION_LOCKSTATUS": "已锁定",
  "SM.AUC.MESSAGE.LOGINSESSION_UNLOCKSTATUS": "未锁定",
  "SM.AUC.LABEL.LOGINSESSION_PWDAUTHFAIL": "密码认证失败",
  "SM.USERGROUP.DELETEG_GROUP.TITLE": "删除所选用户组？",
  "SM.USERGROUP.DELETEG_GROUP.CONTENT": "用户组被删除后，只能重新创建。",
  "SM.USERGROUP.DELETEG_EMPLOYEE.TITLE": "从当前用户组中删除所选员工？",
  "SM.USERGROUP.DELETEG_EMPLOYEE.CONTENT": "从用户组删除员工后，若需恢复，可重新将员工添加至用户组。",
  "SM.AUTH.MESSAGE.FUNCAUTH_MENU": "菜单",
  "SM.AUTH.LABEL.ROLETMPL_OPERTIME": "操作时间",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DESC_FIELD_INPUT": "NAME字段名称：",
  "SM.AUTH.TITLE.ROLE_FUNCAUTH_FAIL": "保存部分功能权限失败，这部分权限存在互斥关系或您不具备可赋权限。",
  "SM.AUTH.MESSAGE.CHANGE_SCOPE_SUGGESTION": "如果父节点修改了角色使用范围，那么子节点的使用范围需要和父节点保持一致。",
  "SM.AUTH.TITLE.ROLE_DISALLOWED_FUNCTION_PERMISSION": "反向功能权限",
  "SM.AUTH.LABEL.OPPAUTH_SOURCE_TYPE_INPUT": "权限来源：",
  "SM.FEDERATION.COMMON.DELETE.FAIL": "删除失败",
  "SM.AUTH.TITLE.ERROR": "错误",
  "SM.AUTH.TITLE.SELECT_OPEN_LOGIN": "选择登录自动打开菜单",
  "SM.AUTH.LABEL.OPERATOR_ACCOUNT_INPUT": "操作员帐号：",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_OPER_TYPE_LIST_INPUT": "操作类型列表定义：",
  "SM.AUTH.LABEL.MODULE_INPUT": "模块名称：",
  "SM.AUTH.MESSAGE.ROLE_RELATIONSHIP_EXISTS_NOT_BEINHER": "互斥关系已存在，不能被继承。",
  "SM.AUTH.OPTION.ROLEAUTHVIEW_STAUTS_NORMAL": "正常",
  "SM.AUTH.LABEL.ROLE_AUTH_DATA": "数据权限",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_PARENT_NODE_FIELD": "PARENT_ID字段名称",
  "SM.AUTH.TITLE.ROLE_GRANTABLE_DATA_PERMISSION": "可赋数据权限",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_OPERATION": "操作名称",
  "SM.AUTH.LABEL.AUTH_CONF_DATA": "数据权限配置",
  "SM.AUTH.LABEL.ROLE_GENERALROLELIST": "通用角色列表",
  "SM.FEDERATION.FEDERATIONAPP.PHONE": "联系电话",
  "SM.AUTH.BUTTON.ADD": "新建",
  "SM.AUTH.MESSAGE.ROLETMPL_SELECT_ONE_ROLE": "请至少选择一个角色。",
  "SM.AUTH.MESSAGE.TIME_LOG2": "天",
  "SM.AUTH.MESSAGE.TIME_LOG1": "时间范围必须小于或者等于",
  "SM.AUTH.TITLE.USERAUTHVIEW_USERHAVAEROLES": "用户拥有的角色",
  "SM.AUTH.LABEL.ROLE_GRANTED_AUTH_TYPE": "授予类型",
  "SM.AUTH.TITLE.INPUT_MENU_NAME": "输入菜单名称",
  "SM.AUTH.LABEL.FUNCAUTH_STATUS": "状态",
  "SM.AUTH.TITLE.USERAUTHVIEW_MENU_AUTHID": "权限标识",
  "SM.AUTH.MESSAGE.ROLE_RELATIONSHIP_EXISTS": "互斥关系已存在。",
  "SM.AUTH.TITLE.ROLE_OPPAUTHNAME": "互斥权限名称",
  "SM.AUTH.LABEL.ROLETMPL_ROLE_LIST": "角色列表",
  "SM.AUTH.MESSAGE.BIZ_OBJ_POLICY_DUPLICATE": "该角色已存在相同的范围。",
  "SM.FEDERATION.FEDERATIONAPP.CLIENTNAME": "第3方认证系统名称",
  "SM.AUTH.MESSAGE.ROLETMPL_CAN_NOT_DELETE": "删除失败，当前节点存在子节点。",
  "SM.AUTH.LABEL.ROLE_NORMALROLE_BENAME": "普通角色归属BE",
  "SM.AUTH.TITLE.MENU_NAME": "菜单名称",
  "SM.AUTH.TIPS.ROLETMPL_HAS_OPP_AUTH": "保存失败，存在互斥权限。",
  "SM.AUTH.TITLE.ROLE_LOGIN_MENU_UPON": "登录自动打开菜单",
  "SM.AUTH.TIPS.FUNCAUTH_NO": "否",
  "SM.AUTH.LABEL.ROLETMPL_ROLETMPL_CONFIGURATE": "配置角色模板",
  "SM.AUTH.LABEL.OPERATOR_ACCOUNT": "操作员帐号",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_SERVICE_INPUT": "服务名称：",
  "SM.FEDERATION.IMPORT.FILESIZEVALIDATEFAIL": "请选择一个小于10mb的文件。",
  "SM.AUTH.LABEL.STAFFROLELOG_OPER_TYPE_INPUT": "操作类型：",
  "SM.AUTH.LABEL.ROLE_PARENTROLE_NAME": "通用角色名称",
  "SM.AUTH.LABEL.ROLETMPL_OPERATOR": "操作员",
  "SM.AUTH.MESSAGE.ROLE_ERROR_BEEN_EXTENDED": "删除失败，当前角色已被其他角色继承或继承了其他角色。",
  "SM.AUTH.TITLE.USERAUTHVIEW_ATTR_DEPARTMENT": "部门",
  "SM.AUTH.MESSAGE.ROLE_THE_ROLES_PERMISSION_OPPED": "保存失败，基本功能权限存在互斥关系。",
  "SM.AUTH.TITLE.PERMISSION_RECOVERY": "权限回收",
  "SM.AUTH.LABEL.THIRD_DATASOURCE_NAME": "数据源名称",
  "SM.AUTH.BUTTON.EXPORT_CURRENT_ROLE": "导出所选项",
  "SM.FEDERATION.COMMON.REFRESH": "刷新 ",
  "SM.AUTH.LABEL.FUNCAUTH_ROLE_UNAVAILABLE": "不能使用该权限的角色",
  "SM.AUTH.TITLE.USERAUTHVIEW_FUNCTIONLIST": "功能权限列表",
  "SM.AUTH.TITLE.USERAUTHVIEW_AUTHVIEW": "权限视图",
  "SM.AUTH.MESSAGE.PERM_RECOVERY_SUCCESS": "权限回收成功。",
  "SM.AUTH.LABEL.FUNCAUTH_AUTHID": "权限编号",
  "SM.AUTH.LABEL.ROLE_EMPLOYEE_ACCOUNT": "帐号",
  "SM.AUTH.MESSAGE.ROLETMPL_INFORMATION": "详细信息",
  "SM.AUTH.LABEL.STAFFROLELOG_OPER_TYPE": "操作类型",
  "SM.AUTH.TITLE.EMPOLYEE_ROLESCOPE": "员工",
  "SM.AUTH.BUTTON.CLEAR": "清理",
  "SM.AUTH.LABEL.ROLE_CODE_INPUT": "角色编码：",
  "SM.AUTH.LABEL.BIZ_OBJ_FIELD_PRIVILEGE": "字段访问权限",
  "SM.AUTH.LABEL.FUNCAUTH_AUTHTYPE": "权限类型",
  "SM.FEDERATION.COMMON.DETAIL": "详情",
  "SM.FEDERATION.COMMON.NEW": "新建",
  "SM.AUTH.LABEL.MENU_AUTHID_INPUT": "权限标识：",
  "SM.AUTH.TITLE.USER_PERMISSION_COMPARE": "人员权限对比",
  "SM.AUTH.TIPS.DATA_AUTH_PARAM_INVALID": "表名、字段名只能包含字母、数字、下划线，且必须是字母开头",
  "SM.AUTH.TITLE.ROLE_GRANTABLE_FUNCTION_PERMISSION": "可赋功能权限",
  "SM.AUTH.TITLE.ROLE_PERMISSION_COMPARE": "角色权限对比",
  "SM.AUTH.TITLE.ROLE_BUSINESS_ENTITY": "运营单位",
  "SM.AUTH.LABEL.ROLE_DERIVE_PARENTROLE": "继承的通用角色",
  "SM.AUTH.MESSAGE.ROLE_ADD_SUCCESSFUL": "添加成功。",
  "SM.AUTH.LABEL.ROLE_EMPLOYEE_NAME": "用户名",
  "SM.AUTH.MESSAGE.FUNCAUTH_INTERFACE": "接口",
  "SM.AUTH.BUTTON.ROLETMPL_RESET": "重置",
  "SM.AUTH.TITLE.ROLE_PERMISSION_OBJECT_TYPE": "权限对象分类",
  "SM.AUTH.TITLE.INPUT_AUTH_OBJ_NAME": "输入权限对象名称",
  "SM.FEDERATION.MAPPINGRULE.LOCAL": "本地用户信息",
  "SM.AUTH.LABEL.CLEAR_TIMEOUT_SELECT_MONTH_FRONT": "要清理数据的月数（一个月按30天计算）",
  "SM.AUTH.LABEL.STAFFROLELOG_ROLE_NAME": "角色名称",
  "SM.AUTH.LABEL.ROLE_ID_B_INPUT": "角色标识B：",
  "SM.AUTH.LABEL.PERMISSION_CLASS": "权限分类",
  "SM.AUTH.BUTTON.OK": "确定",
  "SM.AUTH.MESSAGE.ROLE_ERROR_HAS_EXCLUDED": "删除失败，当前角色与其他角色互斥。",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DYNA_CONF": "可动态配置",
  "SM.AUTH.OPTION.ROLEAUTHVIEW_PERMISSION_FUNCTION": "功能",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DATA_FILTER_CONDITION": "数据过滤条件",
  "SM.FEDERATION.COMMON.UPDATE.FAIL": "修改失败",
  "SM.FEDERATION.FEDERATIONAPP.VALDATEHTTP": "不符合url格式",
  "SM.AUTH.LABEL.ROLETMPL_START_TIME": "开始时间",
  "SM.AUTH.LABEL.DATA_DICTABLE_ID": "数据字典编码",
  "SM.AUTH.LABEL.DATAAUTHMGR_TITLE_AUTHNAME_CONF_INPUT": "权限名称：",
  "SM.AUTH.LABEL.ROLE_EXCLUDINGROLE": "互斥角色",
  "SM.AUTH.MESSAGE.OPERATE_SUCCESS": "操作成功。",
  "SM.AUTH.LABEL.FUNCAUTH_PARENTAUTH": "上级编号",
  "SM.AUTH.LABEL.PERMISSION_INPUT": "权限：",
  "SM.AUTH.OPTION.ROLEAUTHVIEW_PERMISSION_MENU": "菜单",
  "SM.AUTH.MESSAGE.ROLETMPL_QUERY_FAILED": "查询失败。",
  "SM.AUTH.LABEL.ROLE_NAME_INPUT": "角色名称：",
  "SM.AUTH.OPTION.ROLEAUTHVIEW_PUT_FUNCTION": "可赋功能权限",
  "SM.AUTH.LABEL.ROLE_NORMALROLE": "普通角色",
  "SM.AUTH.OPTION.DISALLOWED_FUNCTION_PERM": "反向功能权限",
  "SM.AUTH.MESSAGE.ROLETMPL_ROLETMPL_EXISTS": "角色模板名称已存在。",
  "SM.AUTH.MESSAGE.DATAPER_NO_PERM": "无操作权限。",
  "SM.AUTH.LABEL.ROLE_ROLE_ID": "角色标识",
  "SM.AUTH.LABEL.OPERATION_TYPE": "操作类型",
  "SM.AUTH.MESSAGE.FUNCAUTH_UPLOADINFO": "请选择附件格式：",
  "SM.AUTH.LABEL.DATA": "数据权限",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DISPLAYNO_FIELD": "排序字段名称",
  "SM.AUTH.TITLE.ORG_SELECTION": "选择组织机构",
  "SM.AUTH.LABEL.ROLE": "角色",
  "SM.AUTH.OPTION.ROLEAUTHVIEW_STAUTS_PAUSE": "停用",
  "SM.AUTH.TITLE.USERAUTHVIEW_ROLEVIEW": "角色视图",
  "SM.AUTH.LABEL.FUNCAUTH_ROLE_GRANTABLE": "可授权该权限的角色",
  "SM.AUTH.LABEL.DATAPROVIDER_TYPE_INPUT": "数据值来源类型：",
  "SM.AUTH.TITLE.USERAUTHVIEW_ATTR_BE": "运营单位",
  "SM.AUTH.TITLE.USERAUTHVIEW_ROLE_TYPE": "角色类型",
  "SM.FEDERATION.IMPORT.USER": "导入联邦用户 ",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DISPLAYNO_FIELD_INPUT": "排序字段名称：",
  "SM.AUTH.LABEL.CLEAR_TIMEOUT_SELECT_MONTH_END": "月（一个月按30 天计算）数据",
  "SM.AUTH.TITLE.STAFFROLELOG_LIST": "员工角色日志列表",
  "SM.AUTH.LABEL.BIZ_OBJ_PRIVACY_LEVEL_SECRET": "秘密",
  "SM.AUTH.MESSAGE.ROLE_ERROR_HAS_USERS": "删除失败，当前角色已配置给员工。",
  "SM.FEDERATION.IMPORT.FAIL": "失败",
  "SM.AUTH.LABEL.ROLE_PARENTROLE_BENAME": "通用角色归属BE",
  "SM.AUTH.TITLE.ROLE_HORTCUT_MENU": "快捷菜单",
  "SM.AUTH.LABEL.FUNCAUTH_STATUS_INPUT": "状态：",
  "SM.AUTH.LABEL.CLEAR_POPUP_TITLE": "清理超时数据",
  "SM.AUTH.LABEL.MODULE": "模块名称",
  "SM.AUTH.LABEL.ROLETMPL_ASSINGED_PERMISSION": "为角色赋权",
  "SM.AUTH.TITLE.INPUT_ROLE_NAME": "输入角色名称",
  "SM.AUTH.LABEL.ROLE_NO_INFO": "请在左侧角色树中管理角色。",
  "SM.AUTH.LABEL.BIZ_OBJ_LIST": "业务对象列表",
  "SM.AUTH.TITLE.ROLEAUTHVIEW_USERVIEW": "员工视图",
  "SM.AUTH.LABEL.ROLE_SUPERID": "父角色标识",
  "SM.AUTH.LABEL.ROLE_CONFIG_INFO": "基本信息",
  "SM.AUTH.LABEL.THIRD_DATASOURCE": "第三方数据源",
  "SM.AUTH.LABEL.STAFFROLELOG_OPER_TIME": "操作时间",
  "SM.AUTH.BUTTON.ADDAUTH": "添加",
  "SM.AUTH.TIPS.FUNCAUTH_YES": "是",
  "SM.AUTH.OPTION.ROLEAUTHVIEW_STAUTS_DISCARDED": "删除",
  "SM.AUTH.MESSAGE.FUNCAUTH_UPLOADSIZE": "附件大小最大限制（单位：字节）：",
  "SM.FEDERATION.COMMON.UPDATE.SUCCESS": "修改成功",
  "SM.AUTH.MESSAGE.DATAPER_OBJ_TREE_ROOT": "权限对象树",
  "SM.AUTH.TITLE.ROLEAUTHVIEW_AUTHVIEW": "权限视图",
  "SM.AUTH.LABEL.BIZ_OBJ_PRIVACY_LEVEL_CONFIDENTIAL": "机密",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DATA_FILTER_CONDITION_INPUT": "数据过滤条件：",
  "SM.AUTH.LABEL.ROLEBUSINESS_ENTITY_ID": "角色BE标识",
  "SM.AUTH.MESSAGE.FUNCAUTH_DISABLE": "不可用",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_OPER_TYPE_LIST": "操作类型列表定义",
  "SM.AUTH.BUTTON.IMPORT_ROLE": "导入角色",
  "SM.FEDERATION.COMMON.RESET": "重置",
  "SM.FEDERATION.FEDERATIONAPP.MAPPINGRULE": "联邦用户映射规则",
  "SM.AUTH.TITLE.USERAUTHVIEW_DATA_OBJECT_TREE": "权限对象树",
  "SM.AUTH.BUTTON.ROLE_COPY_ROLE_AND_CREATE": "复制功能权限与数据权限并新建",
  "SM.AUTH.LABEL.ROLETMPL_SEARCH_CRITERIA": "查询条件",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_PARENT_NODE_FIELD_INPUT": "PARENT_ID字段名称：",
  "SM.AUTH.LABEL.ROLE_EXCLUDEDROLE": "被互斥角色",
  "SM.AUTH.TITLE.ROLEAUTHVIEW_PARENT_ROLETYPE": "通用角色",
  "SM.FEDERATION.FEDERATIONAPP.EMAIL": "邮箱",
  "SM.AUTH.LABEL.ROLE_COPY_MODE_INPUT": "复制模式：",
  "SM.FEDERATION.COMMON.OPERATION": "操作",
  "SM.AUTH.LABEL.ROLEPERMISSION_LOGL_IST": "角色权限日志列表",
  "SM.AUTH.TITLE.ROLE_ASSIGNMENT_TIME": "赋予时间",
  "SM.AUTH.LABEL.FUNCAUTH_DEPENDENT": "权限依赖",
  "SM.AUTH.LABEL.STAFFROLELOG_ACCOUNT_INPUT": "帐号：",
  "SM.AUTH.LABEL.FUNCAUTH_LOADMORE": "加载更多",
  "SM.FEDERATION.IMPORT.FILENAMEVALIDATEFAIL": "文件名错误，请选择一个只包含中文、字母、数字或下划线作为文件名的文件。",
  "SM.AUTH.TITLE.ROLE_NOT_HOLD": "不可赋信息列表",
  "SM.AUTH.TITLE.ROLE_EXTENDED_PROPERTY": "扩展属性",
  "SM.AUTH.TITLE.USER_PERM_COMPARE": "用户权限对比",
  "SM.AUTH.LABEL.BIZ_OBJ_SCOPE": "范围",
  "SM.FEDERATION.IMPORT.DOWLOADIMPORTRESULT": "下载",
  "SM.FEDERATION.IMPORT.UPLOADSELECT": "请选择要上传的文件",
  "SM.AUTH.TITLE.USERAUTHVIEW_OPERTYPE": "操作类型",
  "SM.AUTH.LABEL.ROLE_TMPL_LIST": "角色模板列表",
  "SM.AUTH.MESSAGE.DATAPER_CONFIRM_DEL": "是否确定删除？",
  "SM.AUTH.LABEL.FUNCAUTH_AUTHNAME": "权限名称",
  "SM.AUTH.LABEL.ROLE_COPY_TARGETROLE": "目标角色",
  "SM.AUTH.BUTTON.ROLE_DESELECT_ALL": "取消全选",
  "SM.AUTH.BUTTON.ROLETMPL_SEARCH": "查询",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_TYPE_TABLE_INPUT": "表名称：",
  "SM.AUTH.OPTION.ROLEAUTHVIEW_BASE_FUNCTION": "基本功能权限",
  "SM.AUTH.LABEL.ROLE_STAFF_LIST": "已赋员工列表",
  "SM.AUTH.TITLE.USERAUTHVIEW_ROLE_ID": "角色标识",
  "SM.AUTH.MESSAGE.ROLETMPL_CANNOT_SELECT_MODULE": "不能选择模块。",
  "SM.AUTH.MESSAGE.ROLETMPL_TIME_CONDITION": "请输入时间大于开始时间。",
  "SM.FEDERATION.IMPORT.IPLODATIP": "数据导入中，请通过“联邦用户导入结果”查看详细信息",
  "SM.AUTH.TITLE.ROLE_PERMISSION_OBJECT_NAME": "权限对象名称",
  "SM.AUTH.TITLE.CONFIRM_PERM_RECOVERY": "确认",
  "SM.AUTH.TITLE.USERAUTHVIEW_TITLE_AUTHNAME_CONF": "权限名称",
  "SM.AUTH.TITLE.SELECT_SHORT_CUT": "选择快捷菜单",
  "SM.AUTH.TITLE.ROLE_SHORTCUT_MENU_TOOLBAR": "快捷菜单",
  "SM.AUTH.LABEL.BIZ_OBJ_FIELD_PRIVACY": "字段可见级别",
  "SM.FEDERATION.FEDERATIONAPP.EDIT": "修改联邦APP",
  "SM.AUTH.LABEL.ROLE_ID_B": "角色标识B",
  "SM.AUTH.LABEL.ROLE_ID_A": "角色标识A",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_IS_BE_FILTER_INPUT": "BE输入过滤：",
  "SM.AUTH.TITLE.USERAUTHVIEW_LOG_OBJ_NAME": "对象名称",
  "oms.operationparam.msg.unsafeinfo": "请注意，当前输入的地址所使用的网络协议不是安全协议，如确定使用，可能存在安全风险。",
  "SM.AUTH.LABEL.ROLE_TYPE_INPUT": "角色类型：",
  "SM.AUTH.LABEL.ROLE_COPY_SOURCEROLE_INPUT": "源角色：",
  "SM.AUTH.MESSAGE.PASSWORD_RULE_CONFIRM_MESSAGE_41": "该时间段设置过短将影响密码安全程度，是否确认修改？",
  "SM.AUTH.LABEL.DATA_DICTABLE_ID_INPUT": "数据字典编码：",
  "SM.AUTH.TITLE.USERAUTHVIEW_USER_INFORMATION": "员工信息",
  "SM.FEDERATION.IMPORT.STARTTIME": "开始时间 ",
  "SM.AUTH.LABEL.FUNCAUTH_DELETE_CHECK2": "个角色的授权信息：",
  "SM.AUTH.TIPS.ROLE_INPUTROLENAME": "输入角色名称",
  "SM.AUTH.MESSAGE.ROLETMPL_CANT_COPY_PERMISSION": "某些权限不能拷贝，是否确定继续？",
  "SM.AUTH.MESSAGE.FUNCAUTH_LANGUAGE": "语言",
  "SM.AUTH.LABEL.FUNCAUTH_DELETE_CHECK1": "删除该权限会同时清理相关员工及下述",
  "SM.AUTH.MESSAGE.PASSWORD_LENTH_LESS_THAN_8": "密码长度过短会存在一定的安全风险。请问您是否要继续修改？",
  "SM.FEDERATION.FEDERATIONAPP.ERROR.INVALIDFIELD": "字段非法",
  "SM.FEDERATION.FEDERATIONAPP.ERROR.PARSEFAIL": "解析外部用户信息失败",
  "SM.AUTH.LABEL.ROLE_COPY_MODE": "复制模式",
  "SM.AUTH.LABEL.FUNCAUTH_ROLE_GRANTABLE_DETAIL": "可授权该权限的角色",
  "SM.AUTH.LABEL.OPERATION_TYPE_INPUT": "操作类型：",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_TREE_INFO_CONF_INPUT": "树形附加配置信息：",
  "SM.AUTH.LABEL.ROLE_TYPE": "角色类型",
  "SM.FEDERATION.IMPORT.FILESUFFIXVALIDATEFAIL": "文件格式错误，请选择一个xlsx文件。",
  "SM.AUTH.LABEL.ROLETMPL_END_TIME_INPUT": "结束时间：",
  "SM.FEDERATION.FEDERATIONAPP.NAME": "帐号",
  "SM.FEDERATION.COMMON.DELETE.SUCCESS": "删除成功",
  "SM.AUTH.MESSAGE.PASSWORD_RULE_CONFIRM_MESSAGE_39": "开启该规则将影响密码安全程度，请确认是否继续开启？",
  "SM.AUTH.TITLE.INPUT_ROLE_TMPL_NAME": "输入模板名称",
  "SM.AUTH.TIPS.ROLETMPL_CREATE_AND_ASSIGN": "新建角色并赋权。",
  "SM.AUTH.LABEL.ROLETMPL_ROLE_ASSOCIATION_LOG": "关联角色日志",
  "SM.AUTH.TITLE.INPUT_USER_LOGINID": "输入用户帐号",
  "SM.FEDERATION.FEDERATIONAPP.ERROR.EXISTS": "第3方认证系统名称或URL或者映射规则名称已经存在",
  "SM.AUTH.LABEL.EMPLOYEEINCLUDED": "包含下级机构人员",
  "SM.AUTH.LABEL.FUNCAUTH_AUTHTYPE_INPUT": "权限类型：",
  "SM.AUTH.BUTTON.SAVE": "保存",
  "SM.AUTH.TITLE.PERMISSION_SELECTION": "选择权限",
  "SM.AUTH.LABEL.STAFFROLELOG_OPER_ACCOUNT_INPUT": "操作用户帐号：",
  "SM.AUTH.LABEL.ROLETMPL_PERMISSION_NAME_INPUT": "功能权限名称：",
  "SM.AUTH.LABEL.FUNCAUTH_MODULE_INPUT": "子系统ID：",
  "SM.AUTH.TITLE.ROLE_PERMISSION_TIPS": "如需对权限树进行批量操作，您可以在权限节点上单击批量选择按钮。",
  "SM.FEDERATION.IMPORT.FAILCOUNT": "失败记录数 ",
  "SM.AUTH.LABEL.ROLE_ID_INPUT": "角色标识：",
  "SM.AUTH.LABEL.FUNCAUTH_BASICINFO": "基本信息",
  "SM.AUTH.MESSAGE.ROLE_ERROR_BEED_EXCLUDED": "删除失败，当前角色同其他角色存在互斥关系。",
  "SM.AUTH.TITLE.USERAUTHVIEW_ROLE_NAME": "角色名称",
  "SM.AUTH.TITLE.ROLEAUTHVIEW_DATAAUTHLIST": "权限对象列表",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DESC_FIELD": "NAME字段名称",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_KEY_FIELD": "ID字段名称",
  "SM.AUTH.OPTION.ROLE_AUTH_REMOVE": "删除",
  "SM.AUTH.TITLE.ROLE_PERMISSION_OBJECT_ID": "权限对象标识",
  "SM.AUTH.MESSAGE.ROLE_DELETE_SUCCESSFUL": "删除成功。",
  "SM.AUTH.LABEL.PERMISSION": "权限",
  "SM.AUTH.LABEL.ROLE_PARENTROLE_ID": "通用角色编码",
  "SM.AUTH.TITLE.USERAUTHVIEW_AUTH": "功能权限",
  "SM.AUTH.LABEL.FUNCAUTH_ATTRIBUTE": "扩展属性",
  "SM.AUTH.BUTTON.USERAUTHVIEW_RESET": "重置",
  "SM.AUTH.TITLE.ASSIGN_EMPLOYEE": "分配员工",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_TYPE_TABLE": "表名称",
  "SM.AUTH.MESSAGE.DATAPER_DEL_SUCCESS": "删除成功。",
  "SM.AUTH.TITLE.USERAUTHVIEW_ACCOUNTLOGIN": "帐号",
  "SM.FEDERATION.COMMON.CONFIRM": "确认",
  "SM.AUTH.TIPS.ROLETMPL_VIEW_DETAIL_PERMISSION": "查看角色模板中不能被拷贝的权限。",
  "SM.AUTH.TIPS.ROLETMPL_ASSIGN_PERMISSON": "赋权",
  "SM.AUTH.TITLE.ROLE_BASIC_FUNCTION_PERMISSION": "基础功能权限",
  "SM.AUTH.HEADVALUE.AUTHUSERVIEW_AUTH_DATA": "请选择...",
  "SM.AUTH.MESSAGE.OPERATEFAIL": "保存失败。",
  "SM.FEDERATION.FEDERATIONAPP.ERROR.PARAMNULL": "请求参数为空",
  "SM.AUTH.LABEL.ROLETMPL_TEMPLATENAME_INPUT": "角色模板名称：",
  "SM.AUTH.TITLE.CUSTOMER_ROLESCOPE_PERMISSION": "使用范围不是员工时，不能为员工赋角色",
  "SM.FEDERATION.COMMON.SUCCESS": "成功",
  "SM.AUTH.MESSAGE.PASSWORD_RULE_CONFIRM_MESSAGE_12": "关闭该规则将影响密码安全程度，请确认是否继续关闭？",
  "SM.AUTH.LABEL.OPPAUTH_SOURCE_TYPE": "权限来源",
  "SM.AUTH.MESSAGE.PASSWORD_RULE_CONFIRM_MESSAGE_10": "有效的特殊字符将影响密码安全程度，请确认是否合理？",
  "SM.AUTH.LABEL.ROLETMPL_PERMISSION_ID": "功能权限编号",
  "SM.AUTH.LABEL.FUNCAUTH_ROLE_AVAILABLE_DETAIL": "可使用该权限的角色",
  "SM.AUTH.TITLE.ROLE_DELEVER_DATA_PERMISSION": "分配数据权限",
  "SM.FEDERATION.IMPORT.UNDO": "未处理",
  "SM.AUTH.LABEL.ROLE_PARENTS_TITLE": "角色继承信息",
  "SM.FEDERATION.FEDERATIONAPP.ACCOUNT": "帐号",
  "SM.AUTH.LABEL.ROLE_COPY_TARGETROLE_INPUT": "目标角色：",
  "SM.AUTH.MESSAGE.SELECTA_ROLE": "请选择一个角色。",
  "SM.AUTH.BUTTON.USERAUTHVIEW_SEARCH": "查询",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_SERVICE": "服务名称",
  "SM.AUTH.BUTTON.PERM_RECOVERY": "回收",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_IS_BE_FILTER": "BE输入过滤",
  "SM.AUTH.MESSAGE.ROLE_SURE_TO_DELETE": "是否确定删除？",
  "SM.AUTH.LABEL.ROLE_ID_A_INPUT": "角色标识A：",
  "SM.AUTH.LABEL.DATAAUTHMGR_TAB_AUTH_ROLE_VIEW": "数据权限关联角色",
  "SM.AUTH.LABEL.ROLE_DERIVE_NORMALROLE": "派生的普通角色",
  "SM.AUTH.LABEL.ROLE_ASSIGN_STAFF": "已赋员工",
  "SM.AUTH.TITLE.ROLE_TMPLATE_ID": "角色模板标识",
  "SM.AUTH.LABEL.ATTR_LANGUAGE": "语言",
  "SM.AUTH.LABEL.BIZ_OBJ_FIELD_PRIVILEGE_SETTING": "设置",
  "SM.AUTH.BUTTON.EXPORT": "导出",
  "SM.AUTH.MESSAGE.OPERATE_WITH_ROLES": "权限创建成功，并作为可赋权限赋给当前操作员和以下角色：",
  "SM.AUTH.MESSAGE.ROLETMPL_DELETE_SUCCESS": "删除成功。",
  "SM.AUTH.LABEL.STAFFROLELOG_ROLE_BE_ID_INPUT": "角色BE：",
  "SM.AUTH.TIPS.FUNCAUTH_STOPUSE": "停止使用",
  "SM.AUTH.MESSAGE.OPERATE_WITHOUT_ROLES": "权限创建成功，并作为可赋权限赋给当前操作员。",
  "SM.AUTH.MESSAGE.ROLE_OPP_WITH_PARENT": "要继承的角色和已经继承的角色互斥，互斥角色为：",
  "SM.AUTH.LABEL.STAFFROLELOG_ROLE_NAME_INPUT": "角色名称：",
  "SM.AUTH.TIPS.ENTER_AUTHNAME": "输入权限名称",
  "SM.AUTH.TITLE.TO_CHOOSE_ROLE_TMPL_INPUT": "待分配角色模板：",
  "SM.AUTH.TITLE.USERAUTHVIEW_AUTH_MODULE": "模块",
  "SM.AUTH.MESSAGE.FUNCAUTH_PAUTHNAME": "权限名称",
  "SM.AUTH.LABEL.LOGINLOG_RESET": "重置",
  "SM.AUTH.LABEL.FUNCAUTH_AUTHID_INPUT": "权限编号：",
  "SM.AUTH.TITLE.ROLE_BASIC_DATA_PERMISSION": "基础数据权限",
  "SM.AUTH.MESSAGE.ROLE_CAN_NOT_ADD_ITSELF": "不能继承自身角色。",
  "SM.FEDERATION.MAPPINGRULE.NAME": "映射规则名称",
  "SM.AUTH.MESSAGE.SAVE_DEPENDENT_SELF": "权限不允许依赖本身",
  "SM.FEDERATION.IMPORT.STATUS": "状态 ",
  "SM.AUTH.LABEL.FUNCAUTH_ROLE_UNAVAILABLE_DETAIL": "不能使用该权限的角色",
  "SM.AUTH.LABEL.ROLETMPL_HOME_ENTITY": "权限归属BE",
  "SM.AUTH.TITLE.USERAUTHVIEW_AUTH_DATA": "数据权限",
  "SM.AUTH.LABEL.BIZ_OBJ_FIELD_IS_WRITEABLE": "是否可编辑",
  "SM.AUTH.MESSAGE.FUNCAUTH_NORMAL": "正常",
  "SM.AUTH.MESSAGE.ROLE_NAME_SAME": "角色名称已存在。",
  "SM.AUTH.LABEL.USERAUTHVIEW_SEARCH": "查询",
  "SM.AUTH.LABEL.DATAPROVIDER_TYPE": "数据值来源类型",
  "SM.AUTH.BUTTON.ROLE_COPY_ROLE": "复制其他角色的信息（仅复制功能权限与数据权限）",
  "SM.AUTH.TITLE.USERAUTHVIEW_USER_LIST": "用户列表",
  "SM.AUTH.LABEL.USER_ACCOUNTB": "帐号B",
  "SM.AUTH.LABEL.ROLETMPL_PERMISSION_NAME": "功能权限名称",
  "SM.AUTH.LABEL.USER_ACCOUNTA": "帐号A",
  "SM.FEDERATION.COMMON.DOWLOADTEM": "下载模板",
  "SM.AUTH.LABEL.ROLE_NAME": "角色名称",
  "SM.AUTH.LABEL.ROLETMPL_ROLE_NAME": "角色名称",
  "SM.AUTH.LABEL.ORGANIZATION_INPUT": "组织机构：",
  "SM.AUTH.LABEL.ROLE_SCOPE": "角色使用范围",
  "SM.AUTH.MESSAGE.ROLE_NOT_EXSIT": "角色不存在。",
  "SM.AUTH.LABEL.AUTHVIEW_ROLE_BE_ID": "角色归属BE标识",
  "SM.AUTH.MESSAGE.DATAPER_TREE_ROOT": "数据权限树",
  "SM.AUTH.TITLE.SUCCESS": "提示",
  "SM.AUTH.BUTTON.AUTH_EDIT": "修改",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_OPERATION_INPUT": "操作名称：",
  "SM.AUTH.NO.SELECT": "未选中任何权限",
  "SM.AUTH.MESSAGE.AUTH_DATA_OBJECT_TREE": "权限对象树",
  "SM.FEDERATION.COMMON.CREATE.FAIL": "创建失败",
  "SM.FEDERATION.FEDERATIONAPP.NEW": "新建联邦APP",
  "SM.AUTH.BUTTON.ROLE_SELECT_ALL": "全选",
  "SM.AUTH.LABEL.FUNCAUTH_PARENTAUTH_INPUT": "上级编号：",
  "SM.AUTH.LABEL.USER_ACCOUNTB_INPUT": "帐号B：",
  "SM.FEDERATION.COMMON.QUERY.CONDITION": "查询条件",
  "SM.AUTH.BUTTON.CANCEL": "取消",
  "SM.AUTH.TITLE.USERAUTHVIEW_LOG_OBJ_ID": "对象标识",
  "SM.AUTH.TITLE.USERAUTHVIEW_QUERYCONDITION_TITLE": "查询条件",
  "SM.AUTH.LABEL.ROLE_ID": "角色标识",
  "SM.AUTH.LABEL.DATAAUTHMGR_ATTRIBUTE": "扩展属性",
  "SM.FEDERATION.DOWNLOAD.RESULT": "下载联邦用户导入结果 ",
  "SM.FEDERATION.FEDERATIONAPP.CLIENTURL": "第3方认证系统URL",
  "SM.AUTH.LABEL.ROLETMPL_END_TIME": "结束时间",
  "SM.AUTH.LABEL.STAFFROLELOG_ROLE_BE_ID": "角色BE",
  "SM.AUTH.TITLE.ASSIGNED_EMPLOYEE": "已分配员工",
  "SM.AUTH.LABEL.ROLETMPL_BASIC_FUNCTION_PERMISSION": "基础功能信息",
  "SM.AUTH.LABEL.ROLETMPL_DESCRITPION": "描述",
  "SM.AUTH.TITLE.ADD_ROLE_FROM_ROLE_TMPL": "选择角色模板",
  "SM.AUTH.TITLE.USERAUTHVIEW_QUERY_AUTHTYPE": "权限类型",
  "SM.FEDERATION.IMPORT.SUCCESSCOUNT": "成功记录数 ",
  "SM.AUTH.LABEL.ROLE_CODE": "角色编码",
  "SM.AUTH.TIPS.ROLETMPL_WARNING": "警告",
  "SM.AUTH.OPTION.ROLEAUTHVIEW_BASE_DAUTH": "基本数据权限",
  "SM.AUTH.TITLE.CUSTOMER_ROLESCOPE": "客户",
  "SM.AUTH.LABEL.STAFFROLELOG_ROLE_ID": "角色编号",
  "SM.AUTH.LABEL.STAFFROLELOG_ACCOUNT": "帐号",
  "SM.AUTH.LABEL.ROLE_DERIVE": "角色继承",
  "SM.AUTH.LABEL.BIZ_OBJ_FULL_NAME": "编码",
  "SM.AUTH.LABEL.ROLE_COPY_SOURCEROLE": "源角色",
  "SM.AUTH.MESSAGE.ROLE_SELECT_A_ROLE": "请选择一个角色。",
  "SM.AUTH.LABEL.DETAIL_INFO": "请在左侧导航树中选中一个权限节点进行查看。",
  "SM.FEDERATION.FEDERATIONAPP.ERROR.ISUSED": "该数据已被租户使用，不能删除或修改。",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_TREE_INFO_CONF": "树形附加配置信息",
  "SM.AUTH.LABEL.FUNCAUTH_ISDY": "是否动态配置",
  "SM.AUTH.LABEL.ROLETMPL_TEMPLATENAME": "角色模板名称",
  "SM.AUTH.BUTTON.CONFIRM_DELETE": "确认删除",
  "SM.AUTH.TITLE.USERAUTHVIEW_AUTHVIEW_DATAAUTHLIST": "权限对象列表",
  "SM.AUTH.TITLE.TO_CHOOSE_ROLE_TMPL": "待分配角色模板",
  "SM.AUTH.LABEL.CLEAR_CONFIRM_CLEAR_DATA": "确定清理近期不可用权限？",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_ID_TREE_INPUT": "树形：",
  "SM.AUTH.LABEL.BIZ_OBJ_PRIVACY_LEVEL_PUBLIC": "公共",
  "SM.FEDERATION.FEDERATIONAPP.REMOTETIP": "填入值为外部用户对应的映射字段",
  "SM.AUTH.LABEL.ROLE_AUTH_DATA_INPUT": "数据权限：",
  "SM.AUTH.LABEL.PERMISSION_CLASS_INPUT": "权限分类：",
  "SM.FEDERATION.MAPPINGRULE.DESC": "映射规则描述",
  "SM.AUTH.LABEL.ROLE_SUPERCODE_INPUT": "父角色编码：",
  "SM.FEDERATION.IMPORT.RESULT": "联邦用户导入结果 ",
  "SM.AUTH.TITLE.ROLEAUTHVIEW_COMMON_ROLETYPE": "普通角色",
  "SM.AUTH.LABEL.ROLE_SUPERCODE": "父角色编码",
  "SM.AUTH.LABEL.ROLETMPL_ROLE_ID": "角色编号",
  "SM.AUTH.LABEL.CONFIRM_DELETE_DEPEND_AUTH": "确认删除依赖权限？",
  "SM.AUTH.MESSAGE.ROLETMPL_SHOWNAME": "角色模板",
  "SM.AUTH.TITLE.ROLE_MUTUALLY_EXCLUSIVE_PERMISSION": "互斥信息列表",
  "SM.AUTH.LABEL.COMPARE_RESULT": "对比结果",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_CODE_INPUT": "权限编码：",
  "SM.FEDERATION.FEDERATIONAPP.DELETETIP": "确认要删除该条数据吗",
  "SM.AUTH.TITLE.USERAUTHVIEW_AUTH_STATUS": "状态",
  "SM.AUTH.TITLE.BELONG_ROLE_TMPL": "所属模板",
  "SM.AUTH.LABEL.FUNCAUTH_ROLE_AVAILABLE": "可使用该权限的角色",
  "SM.AUTH.LABEL.USER_ACCOUNTA_INPUT": "帐号A：",
  "SM.FEDERATION.COMMON.FAIL": "错误",
  "SM.FEDERATION.IMPORT.IMPORTRESULT": "导入结果",
  "SM.AUTH.OPTION.ROLE_REMOVE_INFO_OF_TARGET_ROLE": "删除目标角色原有信息",
  "SM.FEDERATION.IMPORT.UPLOADFILE": "请选择要上传的文件 ",
  "SM.AUTH.TITLE.USERAUTHVIEW_AUTHSOURCE": "权限来源",
  "SM.AUTH.TITLE.AUTHNAMEINFO": "权限名称信息",
  "SM.AUTH.LABEL.LOGINLOG_SEARCH": "查询",
  "SM.AUTH.TIPS.ROLE_PARENTROLE": "角色名称",
  "SM.AUTH.LABEL.ROLE_DESC": "备注",
  "SM.FEDERATION.IMPORT.ENDTIME": "结束时间 ",
  "SM.AUTH.LABEL.FUNCAUTH_OPPAUTHNAME": "互斥权限",
  "SM.FEDERATION.COMMON.OPERATION.DELETE": "删除",
  "SM.FEDERATION.FEDERATIONAPP.LIST": "联邦APP列表",
  "SM.AUTH.OPTION.ROLEAUTHVIEW_PUT_DAUTH": "可赋数据权限",
  "SM.AUTH.LABEL.ROLE_NORMALROLE_ID": "普通角色编码",
  "SM.AUTH.TITLE.ROLE_TMPL_ROLE_LIST": "角色列表",
  "SM.FEDERATION.COMMON.OPERATION.EDIT": "修改",
  "SM.AUTH.LABEL.ROLE_ROLE_NAME": "角色名称",
  "SM.AUTH.MESSAGE.ROLETMPL_SELECT_NO_ENTITY": "请至少选择一个角色或者一个业务实体。",
  "SM.AUTH.BUTTON.CLEAR_ALL_TIPS": "清理不可用权限",
  "SM.AUTH.MESSAGE.OK": "确定",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_CODE": "权限编号",
  "SM.AUTH.TITLE.CONFIRM_DEL": "确认",
  "SM.AUTH.OPTION.ROLEAUTHVIEW_OPP_FUNCTION": "反向功能权限",
  "SM.AUTH.LABEL.FUNCAUTH_MODULE": "子系统ID",
  "SM.AUTH.LABEL.ROLE_INFO": "角色信息",
  "SM.AUTH.LABEL.DATAAUTHMGR_TITLE_AUTHNAME_CONF": "权限名称",
  "SM.AUTH.MESSAGE.ROLETMPL_SELECT_NODE_INFO": "请在左侧导航树中选择父节点，并在角色模板树中管理角色模板。",
  "SM.AUTH.LABEL.MENU_AUTHID": "权限标识",
  "SM.AUTH.TITLE.INFO": "提示",
  "SM.AUTH.MESSAGE.FUNCAUTH_READONLY": "不可分配",
  "SM.AUTH.LABEL.ROLETMPL_START_TIME_INPUT": "开始时间：",
  "SM.FEDERATION.IMPORT.FAILFILE": "失败文件 ",
  "SM.AUTH.LABEL.FUNCAUTH_DESC_INPUT": "描述：",
  "SM.AUTH.LABEL.BIZ_OBJ_NAME": "名称",
  "SM.AUTH.LABEL.ORGANIZATION": "组织机构",
  "SM.AUTH.BUTTON.DELETE": "删除",
  "SM.AUTH.BUTTON.CLOSE": "关闭",
  "SM.AUTH.MESSAGE.PERM_RECOVERY": "单击“确定”，回收下列全部角色中的权限。单击“取消”不回收任何权限。",
  "SM.AUTH.MESSAGE.ROLE_RELATIONSHIP_EXISTS_NOT_BEEXCLUDING": "不能和继承的角色建立互斥关系。",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_KEY_FIELD_INPUT": "ID字段名称：",
  "SM.FEDERATION.IMPORT.FAILREASON": "失败原因 ",
  "SM.AUTH.LABEL.FUNCAUTH_UPLOAD": "上传附件",
  "SM.AUTH.LABEL.BIZ_OBJ_FIELD": "字段",
  "SM.AUTH.BUTTON.ROLE_DELETE_FOREVER": "永久删除",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DYNA_CONF_INPUT": "可动态配置：",
  "SM.FEDERATION.COMMON.CREATE.SUCCESS": "创建成功",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DESC_INPUT": "描述：",
  "SM.AUTH.LABEL.BE_BASIC_INFORMATION": "基本信息",
  "SM.AUTH.TITLE.USERAUTHVIEW_NAME": "姓名",
  "SM.AUTH.MESSAGE.SAVE_SUCCESS": "保存成功",
  "SM.AUTH.LABEL.ROLE_DESC_INPUT": "备注：",
  "SM.AUTH.TITLE.USERAUTHVIEW_STAFF_BE_NAME": "员工归属BE",
  "SM.AUTH.LABEL.ROLETMPL_ROLETMPL_INFO": "角色模板信息",
  "SM.AUTH.TITLE.TO_ASSIGNED_EMPLOYEE": "待分配员工",
  "SM.AUTH.LABEL.ROLE_NORMALROLE_NAME": "普通角色名称",
  "SM.AUTH.OPTION.GEANTABLE_FUNCTION_PERM": "可赋功能权限",
  "SM.AUTH.LABEL.BIZ_OBJ_ACCESS_RULE": "访问规则",
  "SM.AUTH.LABEL.ROLETMPL_DESCRITPION_INPUT": "描述：",
  "SM.AUTH.TITLE.USERAUTHVIEW_ROLE_PARENTS_TITLE": "角色继承信息",
  "SM.AUTH.TIPS.ENTER_ORGNAME": "输入组织机构名称",
  "SM.FEDERATION.MAPPINGRULE.REMOTE": "外部用户信息",
  "SM.AUTH.MESSAGE.FUNCAUTH_FUNCTION": "功能",
  "SM.FEDERATION.COMMON.QUERY": "查询",
  "SM.AUTH.LABEL.ROLETMPL_FUNCTION_PERMISSION": "功能权限",
  "SM.AUTH.LABEL.STAFFROLELOG_OPER_ACCOUNT": "操作用户帐号",
  "SM.AUTH.MESSAGE.ROLE_ERROR_SUBROLES": "当前角色有子角色，不能删除。",
  "SM.AUTH.LABEL.CLEAR_TIMEOUT_SUCCESS_MSG": "清理时间段内不可用权限成功。",
  "SM.AUTH.TITLE.USERAUTHVIEW_USER_LOGINID": "帐号",
  "SM.AUTH.LABEL.AUTHVIEW_ROLE_BE_NAME": "角色归属BE",
  "SM.AUTH.LABEL.BIZ_OBJ_IS_OWNER": "是否是Owner",
  "SM.AUTH.OPTION.ROLE_RETAIN_INFO_OF_TARGET_ROLE": "保留目标角色原有信息",
  "SM.AUTH.LABEL.BIZ_OBJ_ACCESS_RULE_ROLE_POLICY": "角色访问策略",
  "SM.AUTH.LABEL.PERMRECOVERY_ROLE_NAME": "含有该权限的角色信息",
  "SM.AUTH.LABEL.FUNCAUTH_DESC": "描述",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DESC": "描述",
  "SM.AUTH.LABEL.DETAIL": "详情",
  "SM.AUTH.TITLE.INPUT_AUTHID_AUTHNAME": "输入权限编号或权限名称",
  "SM.AUTH.LABEL.TITLE_CLEAR_SUCCESS": "提示",
  "SM.AUTH.LABEL.STAFFROLELOG_ROLE_ID_INPUT": "角色编号：",
  "SM.AUTH.LABEL.FUNCAUTH_AUTHNAME_INPUT": "权限名称：",
  "SM.AUTH.LABEL.ROLE_OPERTYPE": "操作类型",
  "SM.AUTH.LABEL.ROLETMPL_ROLE_NAME_INPUT": "角色名称：",
  "SM.AUTH.TITLE.USERAUTHVIEW_DETAIL_INFO": "请选择一个具有url的权限节点",
  "SM.FEDERATION.FEDERATIONAPP.VALDATEJSON": "配置项必须为JSON格式!",
  "SM.AUTH.LABEL.FUNCAUTH_INFO": "基本信息",
  "SM.AUTH.TITLE.INPUT_PERMISSION_NAME": "输入权限名称",
  "SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_ID_TREE": "树形",
  "SM.AUTH.MESSAGE.DELETE_CONFIRM": "是否确定删除？",
  "SM.AUTH.TITLE.THIRD_DATASOURCE": "保存成功（当前未选择任何数据源，第三方数据源无法开启。）。",
  "SM.AUTH.TITLE.ADD_ROLE_FROM_AUTH_TREE": "选择功能权限",
  "SM.FEDERATION.COMMON.CANEL": "取消",
  "SM.FEDERATION.COMMON.SUBMIT": "提交",
  "SM.AUTH.OPTION.ROLE_AUTH_ADD": "添加",
  "SM.AUTH.MESSAGE.ROLE_INHERITANCE_RELATIONSHIP_EXISTS": "继承关系已存在。",
  "SM.AUTH.LABEL.ASSIGNEMPLOYEE": "分配员工",
  "SM.AUTH.LABEL.ASSIGNRIGHTS": "可赋权限",
  "SM.AUTH.LABEL.OWNEDRIGHTS": "自有权限",
  "SM.ORGSTAFF.LABEL.ALLORG": "所有组织",
  "SM.FEDERATION.USER.TEMPLATE": "下载联邦用户导入模板",
  "common.baseui.MESSAGE.VALIDATE_MAXLENGTHB": "最多只能输入0个字符",
  "SM.GADGET.VALUE.MENUPERM": "菜单权限",
  "SM.GADGET.VALUE.FUNCPERM": "操作权限",
  "SM.GADGET.VALUE.INTFPERM": "接口权限",
  "SM.ROLE.PARENT.ROLE": "上级角色",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_BATCH_SET_ROLE": "批量添加角色",
  "SM.ORGSTAFF.LABEL.EMPLOYEE_BATCH_DELETE_ROLE": "批量删除角色",
  "SM.USERPWDGROUP.RANGE.VALIDATE": "输入范围为%-%",
  "SM.ROLE.SELECT.BATCH": "请选择角色",
  "SM.ROLE.SELECT.ERRORMSG": "异常描述：",
  "SM.ROLE.SELECT.ERRORCAUSE": "异常原因：",
  "SM.ROLE.SELECT.ERRORSLOUTION": "处理建议：",
  "SM.ROLE.MESSAGE.DELETE_CONFIRM": "角色被删除后，只能重新创建。是否删除？",
  "SM.ORG.DISABLE.FAILED.PARENT": "请先禁用该组织下所有下级组织，再禁用本组织",
  "SM.ORG.DISABLE.FAILED.EMPLOYEE": "请先删除该组织下所有员工，再禁用组织",
  "SM.EMPLOYEE.SELECTED.EMPLOYEE": "已选员工",
  "SM.EMPLOYEE.ALLOCATE.WORKNO": "分配工号",
  "SM.EMPLOYEE.SELECTED.WORKNO.NOT.ENOUGH": "当前选择座席类型可分配工号数量不足",
  "SM.EMPLOYEE.CHECKED-IN.PASSWORD": "签入密码",
  "SM.EMPLOYEE.SOFTCONSOLE.GROUP": "归属话务组",
  "SM.EMPLOYEE.INSPECTION.GROUP": "归属质检组",
  "SM.EMPLOYEE.PLATFORM.CONFIG": "平台配置",
  "SM.EMPLOYEE.CONFIG.EMPLOYEE": "配置员工",
  "SM.EMPLOYEE.CANNOT.CHOOSE.WORKNO": "不能选择已分配工号的员工",
  "SM.EMPLOYEE.CANNOT.CHOOSE.STATUS": "不能选择禁用状态下的员工",
  "SM.EMPLOYEE.CANCEL.CONFIG.CONFIRM": "是否确认取消配置？",
  "SM.EMPLOYEE.OPERATE.MAX.HUNDRED": "一次最多操作100个员工",
  "SM.EMPLOYEE.SKILL.INFO": "技能信息",
  "SM.EMPLOYEE.FEATURE.CONFLICT": "智能识别与双轨道录音不能同时开启，开启后将取消选择另外一个",
  "SM.EMPLOYEE.BATCH.CONFIG": "批量配置",
  "SM.TENANT.LABEL": "租户",
  "SM.TENANT.INFO": "租户信息",
  "SM.TENANT.VIEW": "浏览租户",
  "SM.TENANT.EDIT": "编辑租户",
  "SM.TENANT.CREATE": "新建租户",
  "SM.TENANT.TENANTSPACENAME": "租户环境名称",
  "SM.TENANT.TENANTNAME": "租户名称",
  "SM.TENANT.ADMINACCOUNT": "管理员帐号",
  "SM.TENANT.PHONE": "联系方式",
  "SM.TENANT.CREATETIME": "创建时间",
  "SM.TENANT.DELETE_CONFIRM": "租户被删除后不可恢复。是否删除？",
  "SM.PWDRULE.PLEASE.INPUT": "请输入规则组名称",
  "SM.EMPLOYEE.ROLE.EFFECT": "，员工的角色信息将在[]分钟后生效",
  "SM.ROLE.AUTH.EFFECT": "，角色的权限信息将在[]分钟后生效",
  "SM.ROLE.TEMPLATE": "角色模版",
  "SM.LABEL.APPCUBE.SWITCH": "Appcube集成页面",
  "SM.LABEL.APPCUBE.APP": "选择应用",
  "SM.LABEL.APPCUBE.MENU": "选择菜单",
  "SM.MENU.NAME.SPECIAL.CHAR": "不能输入特殊字符: !#{'$'}%&()￥+:\"{'{'}{'}'},\\/'[]=-^<>",
  "SM.COMMON.MESSAGE.DUTY.FORBIDDEN": "所选员工中含有已经被禁用的员工。",
  "SM.LABEL.ACCOUNT": "请输入账号",
  "SM.LABEL.USERNAME": "请输入用户名",
  "SM.LABEL.EMAIL": "请输入邮箱",
  "SM.LABEL.PHONE": "请输入联系电话",
  "SM.LABEL.PASSWORD": "请输入密码",
  "SM.LABEL.DESCRIPTION": "请输入描述",
  "SM.ORGSTAFF.BUTTON.SaveAndConfigure": "保存并配置",
  "SM.ROLE.BUTTON.DELETE": "是否确定删除所选员工？",
  "SM.ROLE.ADD": "新建角色",
  "SM.ROLE.EDIT": "编辑角色",
  "SM.ROLE.ADD.NAME": "请输入角色名称",
  "SM.ROLE.ADD.DECRI": "请输入备注",
  "sumweb.export.confirm.exportall": "你当前未选择具体记录，是否全部导出？您也可以取消后点击具体记录进行导出操作。",
  "sumweb.export.export.select": "你已选择 {0} 条记录，是否继续导出。",
  "sumweb.export.org": "导出组织信息",
  "sumweb.export.employee": "导出员工信息",
  "sumweb.view.import.list": "导入列表",
  "sumweb.import.success": "已成功创建导入任务，是否前往导入列表进行查看？",
  "SM.AUTH.BUTTON.CONFIRM.CLOSE": "确认关闭",
  "sumweb.agent.config": "配置座席",
  "sumweb.employee.export.nodata": "没有符合条件的员工数据",
  "sumweb.employee.view.config": "查看配置"
}