export const emojiCn = [
    '开心',
    '色',
    '忧郁',
    '呆',
    '呲牙',
    '飞吻',
    '媚眼',
    '气愤',
    '囧',
    '尴尬',
    '大哭',
    '调皮',
    '愤怒',
    '累',
    '鄙视',
    '高兴',
    '口罩',
    '亲亲',
    '擦汗',
    '哭笑',
    '可爱',
    '难过',
    '鬼脸',
    '我晕',
    '紧张',
    '抓狂',
    '奸笑',
    '害怕',
    '冷汗',
    '困惑',
    '笑',
    '恶魔',
    '鬼',
    '圣诞老人',
    '小狗',
    '猪',
    '猫',
    '厉害',
    '你不行',
    '拳头',
    '握拳',
    '手势',
    '强壮',
    '鼓掌',
    '左边',
    '上边',
    '右边',
    '下边',
    '好的',
    '爱心',
    '心碎',
    '太阳',
    '月亮',
    '星星',
    '闪电',
    '云',
    '亲一下',
    '玫瑰花',
    '咖啡',
    '蛋糕',
    '钟',
    '啤酒',
    '放大镜',
    '手机',
    '楼房',
    '车',
    '礼物',
    '足球',
    '炸弹',
    '钻石',
    '外星人',
    '满分',
    '美元',
    '游戏柄',
    '便便',
    '求救',
    '困了',
    '麦霸',
    '雨伞',
    '看书',
    '阿弥陀佛',
    '火箭',
    '绿豆汤',
    '西瓜',
    '微笑',
    '撇嘴',
    '色',
    '发呆',
    '酷',
    '伤心',
    '害羞',
    '闭嘴',
    '困',
    '委屈',
    '尴尬',
    '愤怒',
    '调皮',
    '咧嘴笑',
    '惊讶',
    '悲伤',
    '脸红',
    '抓狂',
    '呕吐',
    '捂嘴笑',
    '可爱',
    '白眼',
    '傲慢',
    '被掏空',
    '惊恐',
    '汗',
    '憨笑',
    '绿帽',
    '奋斗',
    '破口大骂',
    '疑问',
    '嘘',
    '眩晕',
    '雷',
    '骷髅',
    '敲打',
    '再见',
    '擦汗',
    '抠鼻',
    '鼓掌',
    '坏笑',
    '左嘘嘘',
    '右嘘嘘',
    '哈欠',
    '鄙视',
    '很委屈',
    '委屈到变形',
    '嘿嘿',
    '么么哒',
    '可怜',
    '菜刀',
    '西瓜',
    '啤酒',
    '咖啡',
    '小猪猪',
    '比心',
    '心碎',
    '红唇',
    '爱你',
    '分手',
    '蛋糕',
    '地雷',
    '大便',
    '月亮',
    '太阳',
    '拥抱',
    '牛',
    '垃圾',
    '握手',
    '胜利',
    '抱拳了',
    '勾引',
    '加油',
    '好的',
    '跳跳',
    '发抖',
    '怄火',
    '转圈',
    '笑脸',
    '生病',
    '笑哭',
    '吐舌头',
    '脸红红',
    '恐惧',
    '失望',
    '无语',
    '嘿哈',
    '捂脸',
    '滑稽',
    '就是你了',
    '皱眉',
    '耶耶耶',
    '幽灵',
    '祈祷',
    '强壮',
    '庆祝',
    '生日礼物',
    '红包'
];

export const emojiUs = [
    'Happy',
    'Lecherous',
    'Melancholy',
    'Dull',
    'Fangya',
    'Blow kiss',
    'Wink',
    'Furious',
    'Oops',
    'Embarrassed',
    'Weep',
    'Naughty',
    'Anger',
    'Tired',
    'Despise',
    'Glad',
    'Mask',
    'KissKiss',
    'Giddy',
    'CryLaught',
    'Cute',
    'Sad',
    'Wince',
    'Faint',
    'Tension',
    'Crazy',
    'Sinister smile',
    'Afraid',
    'Coldsweat',
    'Confused',
    'Laughing',
    'Demon',
    'Specter',
    'Santa Claus',
    'Dog',
    'Swine',
    'Cat',
    'Awesome',
    'Bad',
    'Boxing',
    'Make a fist',
    'Gesture',
    'Powerful',
    'Applaud',
    'Left',
    'Up',
    'Right',
    'Down',
    'So-so',
    'Love',
    'Heartbreak',
    'The sun',
    'The moon',
    'Star',
    'Lighting',
    'Cloud',
    'Peck',
    'Rosebush',
    'Drink coffee',
    'Coat',
    'Clock',
    'Liquor',
    'Magnifier',
    'Cellphone',
    'Building',
    'Car',
    'Birthday gift',
    'Football',
    'Bombshell',
    'Duanibd',
    'Alien',
    'Full marks',
    'Dollar',
    'Game handle',
    'Stern',
    'SOS',
    'Sleepy',
    'Microphone',
    'Umbrella',
    'Reading',
    'Amitabha',
    'Rocket',
    'Soup',
    'The watermelon',
    'Beam',
    'Grimace',
    'Drool',
    'Scowl',
    'CoolGuy',
    'Sob',
    'Shy',
    'Slient',
    'Sleep',
    'Cry',
    'Awkward',
    'Angry',
    'Tongue',
    'Grin',
    'Surprise',
    'Frown',
    'Blush',
    'Scream',
    'Puke',
    'Chuckle',
    'Joyful',
    'Slight',
    'Smug',
    'Drowsy',
    'Panic',
    'Sweat',
    'Laugh',
    'Commando',
    'Determined',
    'Scold',
    'Shocked',
    'Shhh',
    'Dizzy',
    'Toasted',
    'Skull',
    'Hammer',
    'Bye',
    'Speechless',
    'Nosepick',
    'Clap',
    'Trick',
    'Bah！L',
    'Bah！R',
    'Yawn',
    'Pooh-pooh',
    'Shrunken',
    'TearingUp',
    'Sly',
    'Kiss',
    'Whimper',
    'Cleaver',
    'Watermelon',
    'Beer',
    'Coffee',
    'Pig',
    'Rose',
    'Wilt',
    'Lips',
    'Heart',
    'BrokenHeart',
    'Cake',
    'Bomb',
    'Poop',
    'Moon',
    'Sun',
    'Hug',
    'ThumbsUp',
    'ThumbsDown',
    'Shake',
    'Peace',
    'Salute',
    'Beckon',
    'Fist',
    'OK',
    'Waddle',
    'Tremble',
    'Aaagh!',
    'Twirl',
    'Laugh',
    'Sick',
    'Lol',
    'Tongue',
    'Blush',
    'Terror',
    'Let Down',
    'Speechless',
    'Hey',
    'Facepalm',
    'Smirk',
    'Smart',
    'Concerned',
    'Yeah!',
    'Ghost',
    'Worship',
    'Strong',
    'Party',
    'Gift',
    'Packet'
];