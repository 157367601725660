import i18n from '@/lang/index'
import ic_node from "@/views/ccmanagement/assets/image/diagram-icon/ic_node.png";
import ic_branch from "@/views/ccmanagement/assets/image/diagram-icon/ic_branch.png";

const $t = i18n.global.t

// 控制画布左侧的图元图标加载

// 基础图元
export const IOLP_BASIC_ELEMENTS = [
  {
    code: "taskElement",
    name: $t('iolp.workflow.label.taskElement'),
    imgFile: ic_node,
    shape: "taskElement",
  },
  {
    code: "decisionElement",
    name: $t('iolp.workflow.label.decisionElement'),
    imgFile: ic_branch,
    shape: "decisionElement"
  },
];

