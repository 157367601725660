<template>
  <sweet-row class="aicc-p-top--medium">
    <sweet-col>
      <aicc-table
        class="aicc-table table-height"
        :tableData="tableData"
        :tableColumns="tableColumns"
        :tableTools="{ showPagination: true, columnConfig: false }"
        ref="refAiccTable"
        max-height='900'
        :paginationConfig="paginationConfig"
        @handlePagination="handlePagination"
      >
        <template #test1="scope">
          <div class="button-butween">
            <sweet-button
                text
                @click="download(scope.row.id)"
                type="primary"
                v-show="downloadAuth && scope.row.obs_key" class="download_btn"
            >{{ i18n('ccm.agent.contact.download') }}</sweet-button
            >
            <sweet-button
                text
                @click="previewContractInfo(scope.row.id)"
                type="primary"
                v-show="previewAuth && scope.row.obs_key"
            >{{ i18n('ccm.agent.contact.preview') }}</sweet-button>
          </div>
        </template>
      </aicc-table>
    </sweet-col>
  </sweet-row>
  <sweet-dialog
    v-model="showContract"
    :title="i18n('ccd.ccagent.contract.preview')"
    width="1200px"
    class="aicc-dialog-contract" draggable
  >
    <iframe :src="previewSrc" class="aicc-dialog" id="aicc-frame-contract"></iframe>
  </sweet-dialog>
</template>

<script setup lang="ts">
import { aiccDownload } from '@/utils/aiccUploadAndDownload.js'
import { defineProps, ref, onMounted, getCurrentInstance, reactive, toRefs } from 'vue'
import { ElMessageBox } from 'element-plus'
import {
  getContractOperationColumnWidth
} from "@/views/ccmanagement/common/constants";

// 国际化封装
const {
  appContext: {
    config: { globalProperties }
  }
} = getCurrentInstance() as any
const i18n = (param: string, ...args: Array<any>): string => {
  return globalProperties.$t(param, args)
}

const props = defineProps({
  contactInfo: {
    type: Array<any>,
    default: [],
  },
  downloadAuth: Boolean,
  previewAuth: Boolean
})

const { contactInfo, downloadAuth, previewAuth } = toRefs(props)

const contractId = ref<String>()
const previewSrc = ref<String>()
const showContract = ref<Boolean>(false)
const paginationConfig = reactive({
  pageSizes: [10],
  pageSize: 10,
  layout: "total, prev, pager, next",
  total: 0,
})

/**
 * 分页处理
 * @param params aicc-table传入的分页事件参数
 */
const handlePagination = (params: any) => {
  paginationConfig.pageSize = params.pageSize
  fillContactInfoToTable(params.offset)
}

/**
 * 填充数据到表格
 * @param offset 偏移量
 * @param limit 每页条数
 */
const fillContactInfoToTable = (offset: number, limit: number = paginationConfig.pageSize) => {
  tableData.value = contactInfo?.value?.slice(offset, offset + limit) || [];
}

/**
 * 数据初始化
 */
const initData = () => {
  tableData.value = [];
  showContract.value = false;
  previewSrc.value = void 0;
  paginationConfig.total = contactInfo?.value?.length || 0;
  transformStatus();
  fillContactInfoToTable(0);
}

onMounted(() => {
  initData()
})

const statusMap: ReadonlyMap<String, String> = new Map<String,String>([
  ['0', i18n('ccm.contact.label.unsigned')],
  ['1', i18n('ccm.contact.label.signed')],
  ['2', i18n('ccm.contact.label.inactive')]
]);

const transformStatus = () => {
  for (let i = 0; i < contactInfo.value.length; i++) {
    contactInfo.value[i]['status'] = statusMap.get(String(contactInfo.value[i]['status']))
  }
}

const download = (id: any) => {
  ElMessageBox.confirm(i18n('ccm.contract.management.confirm'), i18n('ccm.agent.button.ok'), {
    closeOnClickModal: false,
    type: 'warning'
  }).then(
    () => {
      const param = reactive({ id: id })
      const url = '/service-cloud/u-route/ccmanagement/contractmanagement/downloadContractFile'
      aiccDownload(param, url)
      return
    }
  )
}

const previewContractInfo = (id: any) => {
  contractId.value = id
  previewSrc.value = '/service-cloud/u-route/ccmessaging/previewContractForWeb?contractId=' + id
  showContract.value = true
}

const tableData = ref<Array<any>>([]) // 表数据

const tableColumns = reactive([
  {
    label: i18n('ccm.agent.contact.caller'),
    prop: 'caller',
    display: true,
    configFixed: true,
    description: ''
  },
  {
    label: i18n('ccm.agent.contact.called'),
    prop: 'called',
    display: true,
    configFixed: true,
    description: ''
  },
  {
    label: i18n('ccm.agent.contact.name'),
    prop: 'contract_name',
    display: true,
    configFixed: true,
    description: ''
  },
  {
    label: i18n('ccm.agent.contact.status'),
    prop: 'status',
    display: true,
    configFixed: true,
    description: ''
  },
  {
    label: i18n('ccm.agent.contact.signaturedate'),
    prop: 'create_time',
    display: true,
    configFixed: true,
    description: ''
  },
  {
    label: i18n('ccm.agent.contact.updatedate'),
    prop: 'update_time',
    display: true,
    configFixed: true,
    description: ''
  },
  {
    label: i18n('ccm.agent.label.operate'),
    prop: 'obs_key',
    display: true,
    configFixed: true,
    description: '',
    slot: 'test1',
    width: getContractOperationColumnWidth()
  }
])
</script>
<style lang="less" scoped>
.aicc-button {
  padding-right: 10px;
}

.aicc-dialog {
  width: 1200px;
  height: 840px;
  margin: 0px;
}

.button-butween {
  justify-content: space-between
}
</style>

<style lang="less">
.aicc-dialog-contract > .el-dialog__body {
  padding: 0 !important;
}
.download_btn {
  margin-inline-end: 10px;
}
</style>
