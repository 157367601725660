export default {
  'ccivr.nms.leavemessageservice.choose.agent': 'ID do agente especificado',
  'ccivr.ivr.ivrflow.templateName': 'Nome do modelo',
  'ccivr.ivr.tracelog.calledNumbererror': 'Falha ao verificar o número chamador!',
  'ccivr.ivr.voice.scene': 'Cenário de uso',
  'ccivr.nms.process.saveerror.noexistend': 'Falha ao salvar. Existe pelo menos um elemento de diagrama final.',
  'ccivr.ivr.error.paramlimit': 'O comprimento total do parâmetro não pode exceder 4000 bytes.',
  'ccivr.nms.tansferservice.chooseSkill': 'Selecionar fila de habilidades',
  'ccivr.nms.shortmessageservice.countryCode': 'Código do país',
  'ccivr.nms.playset.open': 'Ativado',
  'ccivr.ivr.bussinessinvoke.errormessag': 'Selecione a interface a ser excluída.',
  'ccivr.ivrflow.grayrule.beginnum': 'Número inicial',
  'ccivr.ivr.voiceedit.editfail': 'Falha de edição',
  'ccivr.nms.playcollectservice.videofile': 'Arquivo de vídeo',
  'ccivr.nms.timeselectservice.monday': 'Segunda',
  'ccivr.ivr.ivrflow.name': 'Nome',
  'ccivr.ivr.flowAccessCode.repeat': 'O código de acesso já existe!',
  'ccivr.ivr.voiceupload.sizewrong': 'O tamanho do arquivo excede 2 MB. Importe o arquivo novamente.',
  'ccivr.ivr.voiceupload.referenceFlow': 'Informações de fluxo de referência',
  'ccivr.ivr.voiceupload.ivrVoiceUsage': 'Informações de referência',
  'ccivr.nms.conditionService.processLayout': 'Orquestração do processo',
  'ccivr.ivrflow.unlock.confirm': 'Tem certeza de que deseja desbloquear este fluxo da edição?',
  'ccivr.nms.playservice.multivoice.type.number': 'Número',
  'ccivr.nms.businessinvokeservice.value': 'Valor',
  'ccivr.nms.tansferservice.transferLabor': 'Transferir para a fila de habilidades',
  'ccivr.ivrflow.cancelrelease.referenced': 'O fluxo foi referenciado por um código de acesso.',
  'ccivr.ivrflow.cancelrelease.referenced.by.satisfaction.survey': 'O fluxo foi referenciado pela configuração da pesquisa de satisfação e não pode ser cancelada!',
  'ccivr.ivrflow.cancelrelease.referenced.by.idauth.survey': 'O processo foi referenciado por uma configuração de autenticação e não pode ser despublicado!',
  'ccivr.ivr.bussinessinvoke.interfacename': 'Nome da interface',
  'ccivr.nms.playservice.multivoice.language24': 'Tailândia',
  'ccivr.nms.playservice.multivoice.language81': 'Árabe',
  'ccivr.nms.playservice.multivoice.language23': 'Usbequistão',
  'ccivr.flowtest.hashangup': 'O teste termina e a chamada é liberada.',
  'ccivr.nms.playservice.multivoice.language22': 'Turco',
  'ccivr.nms.timeselectservice.saturday': 'Sábado',
  'ccivr.nms.playservice.multivoice.language21': 'Sueco',
  'ccivr.nms.playset.malevoice': 'Voz masculina',
  'ccivr.nms.playservice.multivoice.language20': 'Espanhol',
  'ccivr.ivr.voicereview.thailand': 'Tailândia',
  'ccivr.nms.timeselectservice.selectworktime': 'Dia da semana',
  'ccivr.ivr.voiceupload.deletefail': 'Falha na exclusão.',
  'ccivr.ivr.voiceedit.choosefile': 'Selecionar arquivo',
  'ccivr.nms.tansferservice.transferLastAgent.type.currentday.info': 'A chamada é transferida para o agente que é contatado no dia atual.',
  'ccivr.nms.tansferservice.transferLastAgent.type.none': 'Padrão',
  'ccivr.nms.satisfactionservice.isNull': 'Parâmetro de satisfação está vazio',
  'ccivr.ivr.ivrflow.operate.release.fail': 'Falha de liberação.',
  'ccivr.ivr.voiceupload.languagemanage': 'Gerenciamento de idiomas',
  'ccivr.nms.playservice.multivoice.language19': 'Eslovaco',
  'ccivr.nms.playservice.multivoice.language18': 'Russo',
  'ccivr.nms.playservice.multivoice.language17': 'Português',
  'ccivr.nms.playservice.multivoice.language16': 'Polonês',
  'ccivr.flowtest.client': 'Cliente',
  'ccivr.nms.playservice.multivoice.language15': 'Norueguês',
  'ccivr.nms.playservice.multivoice.language14': 'Coreano',
  'ccivr.ivr.voicereview.portuguese': 'Português',
  'ccivr.nms.playservice.multivoice.language13': 'Japonês',
  'ccivr.nms.playservice.multivoice.language12': 'Italiano',
  'ccivr.nms.leavemessageservice.playbi': 'Reproduzir o tom de bipe',
  'ccivr.nms.playservice.multivoice.language11': 'Islandês',
  'ccivr.nms.tansferservice.transferLastAgent.type.startTime.info': 'A chamada é transferida para o agente que é contatado a partir da hora inicial.',
  'ccivr.nms.playservice.multivoice.language10': 'Húngaro',
  'ccivr.ivr.voiceupload.vedioformatwrong': 'O formato do arquivo está incorreto. Importe um arquivo 3GP.',
  'ccivr.ivr.voiceedit.inputtext': 'Inserir texto',
  'ccivr.ivr.ivrflow.unreleased': 'Não publicado',
  'ccivr.nms.SubFlowService.processjump': 'Pular para',
  'ccivr.nms.businessinvokeservice.opertaion': 'Operação',
  'ccivr.nms.playcollectservice.voiceprompts': 'Solicitação de voz',
  'ccivr.ivr.voiceupload.checkcallcenterparamfailed': 'Falha ao validar os dados da central de atendimento.',
  'ccivr.ivr.tracelog.errorInfo': 'Mensagem de erro',
  'ccivr.ivr.voiceedit.sftpinfoisnull': 'As informações SFTP do call center são nulas.',
  'ccivr.nms.timeselectservice.Monday': 'Segunda',
  'ccivr.nms.queryservice.queueInfo': 'As informações da fila são consultadas antes de uma chamada ser transferida para uma fila de habilidades.',
  'ccivr.nms.queryservice.queueSpecialInfo': 'As informações da lista especial são usadas para consultar as informações da lista especial às quais o número chamador pertence.',
  'ccivr.ivr.voiceupload.audioformat': 'Formato de arquivo de áudio: ALAW\, 8 bits\, 8000 Hz\, 64 kbps\, mono.',
  'ccivr.nms.interface.urltip': 'Use a URL começando por HTTP ou HTTPS.',
  'ccivr.ivr.voiceupload.selectLangNull': 'Nenhum idioma selecionado. Selecione um idioma.',
  'ccivr.ivr.voiceupload.pleaserecording': 'Carregar o arquivo após a gravação.',
  'ccivr.ivrvoice.play.error': 'Carregando exceção de recurso\, verifique a configuração relacionada.',
  'ccivr.ivr.ivrflow.operate.ensurecancelrelease.info': 'Tem certeza de que deseja cancelar a liberação?',
  'ccivr.ivr.voiceupload.recording': 'registro',
  'ccivr.nms.playcollectservice.advancedconfig': 'Configurações avançadas',
  'ccivr.ivrflow.operate.ensurereplace.info': 'Tem certeza de que deseja substituir o fluxo publicado?',
  'ccivr.ivr.voiceupload.playrecord': 'Reproduzir',
  'ccivr.ivr.voiceupload.playtype': 'Modo de reprodução',
  'ccivr.nms.playcollectservice.collectnumtimeout': 'Tempo limite da coleta de dígitos.',
  'ccivr.ivr.voice.recordupload': 'Gravação on-line',
  'ccivr.ivr.voiceupload.deleteodfsivrfail': 'Falha na exclusão. O ivr está ocupado',
  'ccivr.ivr.voiceupload.deletereferenceivrfail': 'A exclus?o da voz falhou. A voz foi referenciada.',
  'ccivr.nms.assignservice.assignmentParameters': 'Preparar parâmetros de atribuição',
  'ccivr.nms.conditionService.conditionService': 'Lógica condicional',
  'ccivr.ivr.voiceedit.idisnull': 'A ID está vazia.',
  'ccivr.ivr.tracelog.maxNumberValidate': 'O número de números de chamada excede o limite superior!',
  'ccivr.ivrflow.grayrule.rule': 'Regra cinza',
  'ccivr.nms.playset.status': 'Configurações avançadas TTS',
  'ccivr.nms.timeselectservice.tuesday': 'Terça-feira',
  'ccivr.ivr.tracelog.result.transfailed': 'Falha',
  'ccivr.ivr.tracelog.result': 'Resultados do fluxo',
  'ccivr.ivr.bussinessinvoke.updateinterface': 'Editar interface',
  'ccivr.ivr.voiceedit.statusisnull': 'o parâmetro de status é nulo.',
  'ccivr.ivr.tracelog.createTime': 'Inserindo tempo de fluxo',
  'ccivr.nms.leavemessageservice.max.recordtime': 'Duração máxima da gravação',
  'ccivr.ivr.voicereview.text': 'Texto',
  'ccivr.nms.timeselectservice.Wednesday': 'Quarta',
  'ccivr.nms.queryservice.type': 'Selecionar tipo',
  'ccivr.nms.playservice.multivoice.language': 'Idioma',
  'ccivr.nms.menuservice.moveup': 'Mover para cima',
  'ccivr.ivr.ivrflow.deleteconfim': 'Tem certeza de que deseja excluir o fluxo de IVR?',
  'ccivr.nms.tansferservice.callForwarding': 'Encaminhamento de chamadas',
  'ccivr.nms.assignservice.setTransferData': 'Definir TransferData',
  'ccivr.ivr.voiceedit.pathisnull': 'Falha ao consultar as informações de caminho SFTP do call center.',
  'ccivr.flowtest.hangup': 'Desligar',
  'ccivr.nms.menuservice.tip': 'Tempo limite ou chave incorreta',
  'ccivr.ivr.ivrflow.batchdeleteconfim': 'Tem certeza de que deseja excluir todos os fluxos de IVR selecionados? Certifique-se de que o fluxo IVR não seja liberado.',
  'ccivr.ivrflow.updateivrinfo.editing': 'O fluxo está sendo editado e não pode ser modificado.',
  'ccivr.nms.process.saveerror.alreadyreleased': 'O estado do fluxo está anormal\, por favor reabra o canvas',
  'ccivr.nms.leavemessageservice.localtionID': 'ID de localização',
  'ccivr.ivr.voiceupload.tipvideoformat': 'Atualmente\, apenas vídeos 3GP são compatíveis. O tamanho do arquivo não pode exceder 5 MB.',
  'ccivr.ivr.voiceupload.audiosampleratefailed': 'A taxa de amostragem do arquivo de áudio não é 8000HZ.',
  'ccivr.ivr.voicereview.reviewsuggestion': 'Comentários',
  'ccivr.ivr.ivrflow.operate.cancelrelease': 'Cancelar liberação',
  'ccivr.nms.process.paramname': 'Nome',
  'ccivr.nms.playservice.playfile': 'Arquivo de voz',
  'ccivr.nms.queryservice.audio': 'Áudio',
  'ccivr.ivr.voicereview.edit': 'Editar',
  'ccivr.ivr.tracelog.callInfo': 'Informações da chamada',
  'ccivr.nms.playservice.multivoice.type': 'Tipo',
  'ccivr.ivr.success': 'Sucesso',
  'ccivr.nms.tansferservice.chooseZNIvr': 'Selecionar IVR Inteligente',
  'ccivr.ivrflow.release.grayrule': 'Liberação Cinza',
  'ccivr.nms.process.saveerror.dataerror': 'Falha ao verificar os dados do elemento de diagrama.',
  'ccivr.nms.timeselectservice.min': 'Minuto',
  'ccivr.nms.timeselectservice.timeSelectType':'Modo de configuração',
  'ccivr.nms.timeselectservice.customConfig':'Personalizar',
  'ccivr.nms.timeselectservice.workDayType':'Modelo',
  'ccivr.nms.timeselectservice.workDay':'Calendário de Trabalho',
  'ccivr.nms.delete': 'Excluir',
  'ccivr.ivr.variable.errorspecial': 'O tipo não existe.',
  'ccivr.ivr.tracelog.deleteFailed': 'Falha ao excluir!',
  'ccivr.ivr.voiceupload.tmpformaterror': 'Tipo de arquivo temporário inválido.',
  'ccivr.nms.queryservice.getDataAssign': 'Resultado da consulta',
  'ccivr.ivr.voiceupload.checkuploadparmfailed': 'Falha na verificação do parm de upload de arquivo.',
  'ccivr.ivrflow.grayrule.inputrulename': 'Insira o nome da regra.',
  'ccivr.ivr.tracelog.addFailed': 'Falha ao adicionar',
  'ccivr.ivr.variable.addvariable': 'Adicionar variável',
  'ccivr.nms.tansferservice.onlineagent': 'Número de agentes online',
  'ccivr.ivrflow.updateivrinfo.fail': 'Falha ao modificar os atributos de fluxo IVR.',
  'ccivr.ivr.voiceedit.addsuccess': 'Adicionado com sucesso',
  'ccivr.ivr.variable.defaultlimit': 'O valor padrão não pode conter mais de 120 caracteres.',
  'ccivr.whitelist.deletetip': 'Marque os itens que deseja excluir',
  'ccivr.ivr.ivrflow.operate.clone.fail': 'Falha ao atualizar o fluxo.',
  'ccivr.nms.conditionService.maxroute': 'O número máximo de ramos condicionais é 20.',
  'ccivr.nms.businessinvokeservice.servicename': 'Nome da interface',
  'ccivr.nms.process.saveerror.menukeynoconnection': 'Falha ao salvar. Verifique se cada chave na configuração do menu tem uma linha.',
  'ccivr.ivr.voiceedit.deletesuccess': 'Excluído com sucesso',
  'ccivr.ivr.voicereview.operat': 'Operação',
  'ccivr.nms.playservice.chosetts': 'Selecione o conteúdo a ser reproduzido a partir do texto TTS carregado. recursos TTS são necessários.',
  'ccivr.nms.shortmessageservice.setParameter': 'Definir variável',
  'ccivr.ivr.voice.scene.note': 'Tom',
  'ccivr.ivr.bussinessinvoke.validate': 'Falha de verificação',
  'ccivr.flowtest.begin.tip': 'Insira o número chamador para iniciar o teste.',
  'ccivr.nms.process.description': 'Descrição',
  'ccivr.ivr.voiceedit.filenamecheckfail': 'Falha na validação do nome do arquivo.',
  'ccivr.nms.leavemessageservice.choose.recordfilename': 'Salvar nome do arquivo',
  'ccivr.nms.queryservice.skillInfo': 'Informações da fila',
  'ccivr.ivr.ivrflow.ivrname': 'Nome',
  'ccivr.ivr.ivrusage.ivrname': 'Cena de referência',
  'ccivr.ivr.ivrusage.skillName': 'Nome da fila de habilidades',
  'ccivr.ivr.voicereview.search': 'Pesquisar',
  'ccivr.nms.playcollectservice.collectnumerror': 'Falha na coleta de dígitos.',
  'ccivr.nms.businessinvokeservice.title': 'Interface Chamada',
  'ccivr.nms.businessinvokeservice.inputflowValidate': 'Entrada incorreta\, modifique',
  'ccivr.ivr.error.sftp': 'As informações SFTP estão incorretas.',
  'ccivr.nms.playcollectservice.times': 'Tempos',
  'ccivr.nms.assignservice.othersDataAssign': 'Atribuir outros dados',
  'ccivr.nms.SubFlowService.jumpnode': 'Pular nó',
  'ccivr.nms.playcollectservice.maxfailtimes': 'Tempos máximos de falha',
  'ccivr.nms.tansferservice.selectPath': 'Selecione um caminho',
  'ccivr.flowtest.operatefail': 'Falha',
  'ccivr.ivr.bussinessinvoke.string': 'String de caracteres',
  'ccivr.nms.varibalesselect.sysvariables': 'Variável de sistema',
  'ccivr.ivrflow.grayrule.name': 'Nome da regra',
  'ccivr.nms.process.saveerror.noselectType': 'Selecione um tipo de consulta.',
  'ccivr.whitelist.urlvalidatefailed': 'Insira o nome de IP ou domínio',
  'ccivr.ivrflow.grayrule.bindingflow': 'Fluxo vinculado:',
  'ccivr.ivr.ivrflow.operate.ensurerelease': 'Confirmar liberação',
  'ccivr.ivrflow.createivrflow.exceptionfail': 'O fluxo de manipulação de exceções já existe. Falha ao adicionar!',
  'ccivr.ivr.tracelog.result.default': 'Padrão',
  'ccivr.ivr.success.import': 'Adicionado com sucesso',
  'ccivr.ivrflow.grayrule.inputendnum': 'O número final deve ser maior ou igual ao número inicial.',
  'ccivr.ivr.voicereview.uploadtime': 'Tempo de carregamento',
  'ccivr.nms.playservice.placeholder': 'Reprodução de voz',
  'ccivr.ivr.bussinessinvoke.int': 'Inteiro',
  'ccivr.ivr.voicereview.language': 'Idioma',
  'ccivr.nms.tansferservice.add': 'Novo',
  'ccivr.nms.tansferservice.parameter': 'Valor',
  'ccivr.ivrflow.grayrule.numrange': 'Segmento de números',
  'ccivr.nms.playcollectservice.Failedreceivenum': 'Falha de coleta de dígitos',
  'ccivr.ivr.voiceedit.filenameformaterror': 'O formato do nome do arquivo está errado.',
  'ccivr.ivr.variable.typeisnull': 'O tipo está vazio.',
  'ccivr.nms.playcollectservice.changtovideoO': 'Vídeo',
  'ccivr.ivr.voiceupload.referenceFlowByCCIVR': 'Referência de fluxo de IVR',
  'ccivr.nms.playset.speed': 'Velocidade de fala',
  'ccivr.nms.timeselectservice.hour': 'Hora',
  'ccivr.nms.timeselectservice.Thursday': 'Quinta',
  'ccivr.nms.satisfactionservice.menubuttonconfig': 'Configuração do botão de satisfação',
  'ccivr.nms.satisfactionservice.singleproblem.menubuttonconfig': 'Configuração do Botão de Pesquisa de Problema Único',
  'ccivr.ivrflow.grayrule.info': 'Detalhes',
  'ccivr.nms.tansferservice.record': 'registro',
  'ccivr.nms.conditionService.simpleExp': 'Expressão simples',
  'ccivr.ivrflow.cancelrelease.error': 'O fluxo é referenciado por uma fila de habilidades!',
  'ccivr.nms.tansferservice.transferLastAgent.sampleForCallingnum': 'Exemplo: 660001 ou {\'$\'}{\'{\'}SYS.callingNumber{\'}\'}',
  'ccivr.nms.process.saveerror': 'Falha ao salvar. Verifique o nó de fluxo e a linha de conexão.',
  'ccivr.nms.tansferservice.transferLastAgent.type.startTime': 'Hora de início',
  'ccivr.whitelist.whiteip': 'IP da lista de confiança',
  'ccivr.nms.tansferservice.transferLastAgent.type.none.info': 'A chamada é transferida para o agente que foi contatado recentemente.',
  'ccivr.nms.SubFlowService.Rotorflow': 'Transferir para subfluxo',
  'ccivr.nms.conditionService.relationOperator': 'Operador',
  'ccivr.nms.playcollectservice.changtovoice': 'TTS',
  'ccivr.nms.tansferservice.transSkill': 'A chamada é transferida para a fila de habilidades correspondente. Suporte para parâmetros personalizados. Exemplo: {\'$\'}{\'{\'}FLOW.Parameter name{\'}\'}',
  'ccivr.nms.conditionService.complexExp': 'Expressão complexa',
  'ccivr.ivr.tracelog.errorpop': 'Falha na consulta!',
  'ccivr.nms.timeselectservice.tips3': 'Os intervalos de tempo de serviço devem estar em ordem crescente. Cada intervalo de tempo não pode ser sobreposto um com o outro.',
  'ccivr.nms.timeselectservice.tips2': 'A hora de término do serviço deve ser posterior à hora inicial.',
  'ccivr.nms.tansferservice.transferZNIvr': 'Encaminhar para IVR inteligente',
  'ccivr.nms.timeselectservice.tips1': 'A data que atende ao dia e data da semana selecionados é um dia útil.',
  'ccivr.ivr.voicereview.name': 'Nome',
  'ccivr.nms.businessinvokeservice.outputparam': 'Parâmetro de saída',
  'ccivr.ivr.tracelog.flowName': 'Nome do fluxo',
  'ccivr.ivr.voiceupload.opreationdbfailed': 'Mensagem de voz IVR de atualização do banco de dados falhou.',
  'ccivr.ivr.voiceupload.pleaseuploadfile': 'O arquivo está vazio. Importe o arquivo.',
  'ccivr.ivr.voicereview.voice': 'Voz',
  'ccivr.flowtest.callingnumber': 'Número chamador',
  'ccivr.ivr.ivrflow.alreadyreleased': 'Versão Oficial',
  'ccivr.ivr.voicereview.nosubmit': 'Não submetido',
  'ccivr.nms.menuservice.menubuttonconfig': 'Configuração da chave do menu',
  'ccivr.ivr.ivrflow.operate.release.success': 'Êxito da liberação.',
  'ccivr.whitelist.port': 'Porta',
  'ccivr.nms.tansferservice.transferLastAgent.type.time.sample': 'Exemplo: 100\, indicando que a chamada é transferida para o agente que é contatado dentro de 100 minutos.',
  'ccivr.nms.shortmessageservice.hwCloudMessage': 'Nuvem Huawei',
  'ccivr.nms.timeselectservice.servicetimeperiod': 'Faixa de tempo de serviço',
  'ccivr.nms.assignservice.transferDataAssign': 'Apanhem o TransferData',
  'ccivr.nms.tansferservice.setTransfer': 'Configuração de transferência',
  'ccivr.ivr.ivrflow.no': 'Não',
  'ccivr.flowtest.testoperate': 'Operações:',
  'ccivr.ivr.voicereview.choosevoicetype': 'Tipo',
  'ccivr.nms.process.saveerror.flownoexist': 'O fluxo não existe.',
  'ccivr.whitelist.descinfo': 'Descrição',
  'ccivr.nms.brach.duplicatebranch': 'A filial já existe. Selecione outro arquivo.',
  'ccivr.ivr.voiceupload.authvalidatefailed': 'Falha na verificação da permissão de carregamento de arquivo.',
  'ccivr.nms.tansferservice.empty': 'O parâmetro está vazio.',
  'ccivr.nms.tansferservice.processName': 'inserir um nome de fluxo.',
  'ccivr.ivr.voicereview.reviewstatus': 'Status',
  'ccivr.nms.queryservice.Dataassignment': 'Consulta de informações',
  'ccivr.nms.tansferservice.transferThirdParty': 'Transferência para terceiros',
  'ccivr.nms.tansferservice.transferThirdParty.recordingSettings': 'Configurações de gravação',
  'ccivr.nms.tansferservice.transferThirdParty.enableRecording': 'Ativar gravação.',
  'ccivr.nms.tansferservice.transferThirdParty.recordingPrompt': 'Prompt de gravação',
  'ccivr.nms.transferservice.choose.recordfilename.info': 'Quando a interface é chamada para baixar arquivos de gravação, a variável é usada para armazenar temporariamente o valor.',
  'ccivr.nms.tansferservice.sample': 'Exemplo: 28956666 ou {\'$\'}{\'{\'}FLOW.currentUserNum{\'}\'}',
  'ccivr.nms.modulename': 'Nome do elemento',
  'ccivr.nms.conditionService.logicOR': 'OU',
  'ccivr.nms.tansferservice.transferLastAgent.transCallingnum': 'Número Chamada',
  'ccivr.nms.menuservice.samenamevalidate': 'Chaves repetidas:',
  'ccivr.ivr.ivrflow.updateTime': 'Tempo de atualização',
  'ccivr.ivr.voiceupload.tipvideomodel': 'Você pode definir arquivos de voz ou vídeo de diferentes idiomas ao mesmo tempo. Durante a reprodução de vídeo\, o sistema reproduz automaticamente o arquivo de vídeo correspondente com base na linguagem selecionada pelo usuário.',
  'ccivr.ivr.voicereview.pass': 'Aprovar',
  'ccivr.ivr.voiceedit.import': 'Importação',
  'ccivr.ivr.ivrflow.exceptionHandingFlow': 'Fluxo de manipulação de exceções',
  'ccivr.nms.shortmessageservice.satisfactionMessage': 'Pesquisa de satisfação',
  'ccivr.ivr.voiceadvice.close': 'Fechar',
  'ccivr.nms.process.parameterisEncrypt': 'Criptografia',
  'ccivr.ivr.ivrflow.currentHandler': 'manipulador',
  'ccivr.nms.satisfactionservice.buttonNumber': 'O número de chaves de pesquisa de satisfação não é superior a 9 e não inferior a 3.',
  'ccivr.ivr.bussinessinvoke.URL': 'URL da solicitação',
  'ccivr.ivr.voiceupload.selectvoice': 'Selecione um arquivo de voz.',
  'ccivr.nms.tansferservice.transferLastAgent.type.startTime.sample': 'Selecione a hora inicial.',
  'ccivr.nms.tansferservice.timedPrompt': 'Você não o salvou por um longo tempo. Por favor, salve-o a tempo.',
  'ccivr.nms.tansferservice.processLayout': 'Orquestração do processo',
  'ccivr.ivr.voice.recordtimeout.fail': 'Tempo limite de gravação.',
  'ccivr.nms.shortmessageservice.messageTemplateContent': 'Modelo',
  'ccivr.ivr.bussinessinvoke.errormessage': 'Selecione a interface a ser excluída.',
  'ccivr.nms.tansferservice.transferLastAgent.type.time': 'Faixa de tempo',
  'ccivr.ivr.ivrflow.mainflow': 'Fluxo principal',
  'ccivr.nms.process.samenamevalidate': 'Nome do parâmetro duplicado:',
  'ccivr.nms.interface.nmsexception': 'Falha na comunicação com o ODFS. Verifique a configuração.',
  'ccivr.nms.interface.whitelistcheckfailed': 'O URL (endereço IP e número da porta) não está na lista de confiança. A interface só pode ser invocada depois de contactar o administrador do sistema para adicionar o URL à lista de confiança. Continuar?',
  'ccivr.nms.interface.whitelistwarn': 'O URL (endereço IP e número da porta) não está na lista de confiança. A interface pode ser invocada somente depois que você entrar em contato com o administrador do sistema para adicionar o URL à lista de confiança.',
  'ccivr.ivr.tracelog.result.timeout': 'Tempo limite',
  'ccivr.ivrflow.grayrule.rulename': 'Nome da regra',
  'ccivr.ivr.voice.scene.error': 'Erro',
  'ccivr.ivr.voice.scene.questionnaires': 'Questionário',
  'ccivr.ivr.voice.scene.beforeReportWorkNo': 'antes de informar a ID do funcionário',
  'ccivr.ivr.voice.scene.afterReportWorkNo': 'depois de relatar a ID do funcionário',
  'ccivr.nms.shortmessageservice.fiveGMessage': 'Mensagem 5G',
  'ccivr.nms.conditionService.Parameter_2': 'Parâmetro 2',
  'ccivr.ivr.voicereview.vedio': 'Vídeo',
  'ccivr.ivr.voicereview.filePath': 'Caminho do arquivo:',
  'ccivr.ivr.voicereview.textContent': 'Conteúdo do arquivo',
  'ccivr.ivr.voiceedit.filenameisnull': 'Nome do arquivo é nulo.',
  'ccivr.nms.conditionService.Parameter_1': 'Parâmetro 1',
  'ccivr.ivr.ivrflow.subflow': 'Subfluxo',
  'ccivr.nms.shortmessageservice.addressee': 'Telefone',
  'ccivr.nms.shortmessageservice.phoneNumber': 'Número de telemóvel',
  'ccivr.nms.playservice.inputtitle': 'Solicitação de voz',
  'ccivr.ivrflow.createivrflow.sameivrflowname': 'O nome do fluxo já existe!',
  'ccivr.ivr.tracelog.sameNumberValidate': 'O mesmo número de chamada já existe!',
  'ccivr.nms.playcollectservice.close': 'Fechar',
  'ccivr.nms.playcollectservice.required': 'Este campo é obrigatório.',
  'ccivr.nms.menuname.specialStrValidateFailed': 'O comprimento não pode exceder 255 caracteres',
  'ccivr.nms.process.saveerror.noexistconnection': 'Falha ao salvar. Uma linha padrão deve estar disponível para elementos de diagrama exceto o extremo e os elementos de diagrama de subfluxo.',
  'ccivr.nms.queryservice.queryType': 'Tipo de consulta',
  'ccivr.nms.playset.femalevoice': 'Voz feminina',
  'ccivr.flowtest.test': 'Teste',
  'ccivr.nms.playservice.multivoice.type.telnum': 'Número de telefone',
  'ccivr.nms.tansferservice.transNum': 'Número de encaminhamento',
  'ccivr.ivr.voicereview.filename': 'Nome do arquivo',
  'ccivr.ivr.voicereview.scene': 'Cenário de uso',
  'ccivr.nms.tansferservice.transAppointWorkNo': 'As chamadas de entrada são encaminhadas para um agente especificado.',
  'ccivr.ivr.variable.descvalueerror': 'A descrição contém caracteres especiais. Por favor\, modifique-o.',
  'ccivr.nms.satisfactionservice.type': 'Tipos de Pesquisa de Satisfação',
  'ccivr.nms.satisfactionservice.title': 'Configuração de Satisfação',
  'ccivr.nms.singleproblem.title': 'Pesquisa de Pergunta Única',
  'ccivr.ivr.tracelog.title': 'TraceLog',
  'ccivr.nms.timeselectservice.selectdate': 'Data',
  'ccivr.nms.menuservice.contentvalidate': 'Após a conclusão da edição\, adicione a configuração do botão.',
  'ccivr.nms.process.initialvalue': 'Valor inicial',
  'ccivr.whitelist.imordomainname': 'IP ou nome de domínio',
  'ccivr.ivr.tracelog.addAccessCode': 'Adicionar código de acesso',
  'ccivr.nms.playcollectservice.ttsvoice': 'TTS',
  'ccivr.nms.playcollectservice.entercollectwaittime': 'Informe o tempo de espera para coleta de dígitos.',
  'ccivr.nms.playcollectservice.collectTimeout': 'Tempo limite para a recolha de dígitos',
  'ccivr.nms.ChangeMediaService.label': 'mídia',
  'ccivr.nms.playservice.contentisnull': 'O conteúdo do áudio não pode estar vazio',
  'ccivr.nms.playcollectservice.collectTimeout.error': 'Tempo limite de coleta de dígitos inválido (1-120)',
  'ccivr.nms.leavemessageservice.agent': 'Especificar agente para processamento',
  'ccivr.nms.timeselectservice.Tuesday': 'Terça',
  'ccivr.ivr.variable.willdeleteselectvariable': 'Tem certeza de que deseja excluir as variáveis selecionadas?',
  'ccivr.ivr.tracelog.transitionOn': 'Ramo de exportação',
  'ccivr.ivr.name.notnull': 'O nome não pode ser deixado vazio.',
  'ccivr.nms.callendservice.checkbox': 'A interface precisa ser chamada quando uma chamada termina.',
  'ccivr.ivr.voiceupload.formatwrong': 'O formato do arquivo está incorreto. Importe um arquivo WAV.',
  'ccivr.ivr.voiceupload.fileheaderwrong': 'O formato original do arquivo de voz n�o � wav ou mp3 , por favor , substitua outro arquivo de voz para fazer o upload',
  'ccivr.ivr.voiceupload.vedioheaderwrong': 'O formato original do arquivo de v�deo n�o � 3GP , por favor , substitua outro arquivo de v�deo para enviar',
  'ccivr.nms.process.basicinformation': 'Informações básicas',
  'ccivr.nms.playcollectservice.choosefile': 'Voz',
  'ccivr.ivr.voice.filechoose': 'Selecione um arquivo de voz',
  'ccivr.ivr.voiceedit.editsuccess': 'Editado com sucesso.',
  'ccivr.ivr.voicereview.refresh': 'Atualizar',
  'ccivr.ivr.voiceedit.addfail': 'Falha ao adicionar.',
  'ccivr.nms.tansferservice.estimateTime': 'Tempo estimado',
  'ccivr.nms.queryservice.estimateTime.prompte': 'O tempo de espera estimado de enfileiramento é calculado com base no modelo de fila. O resultado tem um certo desvio. Por favor. Use corretamente',
  'ccivr.nms.tansferservice.transNumType': 'O número de encaminhamento pode ser um número de celular\, número de telefone de linha fixa ou parâmetro personalizado. Exemplo: {\'$\'}{\'{\'}FLOW.Parameter name{\'}\'}',
  'ccivr.nms.queryservice.paramName': 'Parâmetro de solicitação',
  'ccivr.ivr.tracelog.callingNumberplaceHolder': 'Insira o número chamador',
  'ccivr.ivr.tracelog.nodeName': 'Nome do elemento',
  'ccivr.whitelist.desc': 'Descrição',
  'ccivr.ivr.voice.fileupload': 'Carregar arquivo',
  'ccivr.ivr.tracelog.addcallingnumber': 'Adicionar número de chamador',
  'ccivr.nms.playcollectservice.mulen': 'Número máximo de dígitos recebidos',
  'ccivr.ivr.voicereview.english': 'Inglês',
  'ccivr.nms.playset.lowspeed': 'Baixo',
  'ccivr.ivr.bussinessinvoke.cancel': 'Cancelar',
  'ccivr.nms.process.parameterconfiguration': 'Configuração de parâmetros',
  'ccivr.nms.playcollectservice.onenumbers': 'Coleção de vários dígitos',
  'ccivr.ivr.tracelog.selecttip': 'Selecione o número de chamada!',
  'ccivr.ivr.voicereview.language1': 'Inglês',
  'ccivr.nms.playservice.multivoice.type.time': 'time(hh:mm:ss)',
  'ccivr.ivr.voicereview.language0': 'Chinês',
  'ccivr.flowtest.stoptest': 'Parar',
  'ccivr.ivr.ivrflow.operate.cancelrelease.fail': 'Falha ao cancelar a liberação.',
  'ccivr.ivr.bussinessinvoke.tip': 'Insira um valor ou variável',
  'ccivr.ivr.bussinessinvoke.createinterface': 'Criar interface',
  'ccivr.nms.playset.sound': 'Efeito sonoro',
  'ccivr.ivrflow.unlock': 'Desbloquear',
  'ccivr.nms.tansferservice.mustbeaccesscode': 'O número chamador deve ser um código de acesso alocado pelo sistema ou parâmetro personalizado. Exemplo: {\'$\'}{\'{\'}FLOW.Nome do parâmetro{\'}\'}',
  'ccivr.nms.tansferservice.transferLastAgent.type.currentday': 'Dia atual',
  'ccivr.flowtest.flowtest': 'Teste de fluxo',
  'ccivr.ivr.voiceupload.stoprecording': 'Parar',
  'ccivr.ivrflow.release.formal': 'Versão Oficial',
  'ccivr.nms.assignservice.ietype': 'Tipo IE',
  'ccivr.ivr.voiceedit.updatefail': 'Falha na atualização.',
  'ccivr.ivr.tracelog.deleteSuccess': 'Eliminado com sucesso!',
  'ccivr.nms.leavemessageservice.placeholder': 'Deixar mensagem',
  'ccivr.flowtest.success': 'Sucesso',
  'ccivr.nms.shortmessageservice.period': 'Período de resposta válido (minutos)',
  'ccivr.ivr.ivrflow.operate.ensurerelease.info': 'Tem certeza de que deseja liberar o fluxo?',
  'ccivr.ivr.ivrflow.type': 'Tipo',
  'ccivr.ivr.voiceedit.tip': 'Solicitar',
  'ccivr.ivr.voiceupload.referenceFlowByNMS': 'Referência do modelo de recurso',
  'ccivr.ivr.voiceupload.voiceUsageByTenant': 'Referência de cenário de locatário',
  'ccivr.ivr.voiceupload.voiceUsageBySkill': 'Referência da fila de habilidades',
  'ccivr.ivr.bussinessinvoke.interfacedesc': 'Descrição da interface',
  'ccivr.ivr.ivrflow.operate.release.cancelexecptionhanding': 'Cancele o status de liberação de outros processos sob o Tenant.',
  'ccivr.nms.LanguageService.placeholder': 'Selecionar idioma',
  'ccivr.nms.menuservice.voicenotice': 'Solicitação de voz',
  'ccivr.ivr.bussinessinvoke.saveserror': 'Falha ao salvar.',
  'ccivr.nms.interface.warn': 'O protocolo de rede usado pela interface não é seguro e pode trazer riscos de segurança',
  'ccivr.ivr.bussinessinvoke.interfacetype': 'Tipo de interface',
  'ccivr.ivr.ivrflow.filtercondition': 'Critérios de filtro',
  'ccivr.ivr.voiceedit.querycallcenterfailed': 'Falha ao consultar as informações SFTP do call center.',
  'ccivr.ivrflow.error.deleteivrflow.association': 'O fluxo está associado a outro fluxo e não pode ser excluído.',
  'ccivr.ivr.voiceedit.statuserror': 'o parâmetro de status está incorreto.',
  'ccivr.ivr.voicereview.notpass': 'Rejeitado',
  'ccivr.nms.playset.highspeed': 'Alto',
  'ccivr.nms.playservice.multivoice.content': 'Conteúdo',
  'ccivr.ivr.bussinessinvoke.savesuccess': 'Salvado com sucesso.',
  'ccivr.nms.varibalesselect.globalvariables': 'Variável global',
  'ccivr.ivrflow.grayrule.inputendlength': 'O comprimento do número inicial deve ser o mesmo que o número final.',
  'ccivr.ivr.tracelog.calledNumber': 'Número chamado',
  'ccivr.nms.timeselectservice.friday': 'Sexta-feira',
  'ccivr.ivr.voicereview.passed': 'Aprovado',
  'ccivr.ivr.voiceupload.audiofilereadfailed': 'Falha na leitura das propriedades do arquivo de áudio.',
  'ccivr.ivr.voiceupload.fileinjectionerror': 'Ocorre um erro ao validar os riscos de injeção de arquivo.',
  'ccivr.nms.process.customparameter': 'Parâmetros de fluxo',
  'ccivr.nms.assignservice.ieDataAssign': 'Atribuir valor aos dados do IE',
  'ccivr.ivr.voicereview.language24': 'Tailândia',
  'ccivr.ivr.voiceupload.uploadfiletosftpfailed': 'Falha ao carregar arquivo para sftp.',
  'ccivr.ivrflow.operate.ensurereplace': 'Confirmar',
  'ccivr.nms.shortmessageservice.mspMessage': 'Gateway SMS',
  'ccivr.ivr.variable.string': 'Cadeia de caracteres',
  'ccivr.ivr.voiceedit.filenamenull': 'O conteúdo do nome do arquivo é nulo.',
  'ccivr.nms.playset.intermediatespeed': 'Médio',
  'ccivr.ivr.tracelog.deleteconfirminfo': 'Se deseja excluir o número chamador atual:',
  'ccivr.ivr.fail': 'Falha',
  'ccivr.ivr.voiceupload.delete': 'Excluir',
  'ccivr.ivr.ivrflow.filter': 'Filtro',
  'ccivr.ivr.voicereview.french': 'Francês',
  'ccivr.ivr.voicereview.arabic': 'Árabe',
  'ccivr.ivr.voicereview.german': 'Alemão',
  'ccivr.ivr.voicereview.language17': 'Português',
  'ccivr.ivr.voicereview.language7': 'Francês',
  'ccivr.ivr.voicereview.language81': 'Árabe',
  'ccivr.ivr.voicereview.language8': 'Alemão',
  'ccivr.ivr.voicereview.spanish': 'Espanhola',
  'ccivr.ivr.voicereview.language20': 'Espanhola',
  'ccivr.nms.tansferservice.transferLastAgent.type': 'Modo Cliente Familiar',
  'ccivr.nms.playcollectservice.failtitle': 'Reprodução de voz para falha de coleta de dígitos.',
  'ccivr.ivr.bussinessinvoke.basicconfig': 'Configuração básica',
  'ccivr.nms.timeselectservice.wednesday': 'Quarta',
  'ccivr.ivr.tracelog.result.recordend': 'Gravação de sucesso',
  'ccivr.ivrflow.variable.settings': 'Configurações de variáveis',
  'ccivr.ivr.tracelog.deleteconfirminfocode': 'Se deseja excluir o código de acesso atual:',
  'ccivr.nms.tansferservice.transferLastAgent': 'Áudio e Vídeo',
  'ccivr.flowtest.flowname': 'Nome',
  'ccivr.ivr.ivrflow.operate.gateway.pulishrepeat': 'O nome do processo é duplicado ou uma versão foi lançada.',
  'ccivr.ivr.ivrflow.version': 'Número da versão',
  'ccivr.ivr.voiceupload.english': 'Inglês',
  'ccivr.ivr.voiceupload.fileovermax': 'O número de vozes IVR a serem gerenciadas excede o máximo.',
  'ccivr.nms.add': 'Novo',
  'ccivr.nms.playservice.chosemultivoice': 'Defina o conteúdo a ser reproduzido das seguintes seleções',
  'ccivr.ivr.tracelog.addSuccess': 'Adicionado com sucesso!',
  'ccivr.ivr.tracelogprompt': 'Essa operação afetará o desempenho do sistema. A configuração será automaticamente excluída {0} horas depois. Tem certeza de que deseja continuar?',
  'ccivr.flowtest.presskey': 'Botões',
  'ccivr.ivr.tracelog.selecttipcode': 'Selecione o código de acesso!',
  'ccivr.ivrflow.ivrflowname.specialStrValidateFailed128': 'O valor não pode conter caracteres especiais e o comprimento não pode exceder 128 caracteres.',
  'ccivr.nms.timeselectservice.Friday': 'Sexta',
  'ccivr.ivr.voice.exception.recordfailmessage': 'Erro de gravação. Verifique se você tem a permissão do microfone.',
  'ccivr.ivrflow.updateivrinfo.success': 'Modificados os atributos do fluxo IVR com sucesso.',
  'ccivr.nms.businessinvokeservice.second': 'Segundo',
  'ccivr.ivr.tracelog.index': 'Índice',
  'ccivr.nms.tansferservice.operat': 'Operação',
  'ccivr.ivr.voice.recordtimeout.failmes': 'A duração máxima da gravação é de 2 minutos. Grave novamente.',
  'ccivr.nms.tansferservice.transferZNIvrCode': 'Transferência para IVR Inteligente',
  'ccivr.nms.process.saveerror.noresult': 'Não é Entidade.',
  'ccivr.nms.leavemessageservice.saveleavemessage': 'Registrar informações de mensagem.',
  'ccivr.nms.playservice.multivoice.language9': 'Grego',
  'ccivr.flowtest.platform': 'Plataforma',
  'ccivr.ivrflow.grayrule.grayinfo': 'Se um número corresponder a uma regra cinza\, o fluxo desta versão será executado.<br /> Para números que não correspondem às regras de cinza\, o fluxo liberado oficialmente é executado.<br /> Se não houver fluxo liberado oficialmente\, a execução falha.',
  'ccivr.nms.playservice.multivoice.language8': 'Alemão',
  'ccivr.nms.playservice.multivoice.language7': 'Francês',
  'ccivr.nms.playservice.multivoice.language6': 'Finlandês',
  'ccivr.nms.playservice.multivoice.language5': 'Holandês',
  'ccivr.nms.playservice.multivoice.language4': 'Dinamarquês',
  'ccivr.nms.playservice.multivoice.language3': 'Tcheco',
  'ccivr.nms.playservice.multivoice.language2': 'Chinês (cantonês)',
  'ccivr.nms.playservice.multivoice.language1': 'Inglês',
  'ccivr.nms.playservice.multivoice.language0': 'Putonghua',
  'ccivr.nms.playcollectservice.interrupt': 'Pressione qualquer tecla para interromper a reprodução.',
  'ccivr.ivr.description.limit': 'A descrição do texto não pode conter mais de 1024 bytes.',
  'ccivr.ivr.voice.vediochoose': 'Selecione um arquivo de vídeo',
  'ccivr.ivr.variable.name': 'Variável',
  'ccivr.nms.tansferservice.delete': 'Excluir',
  'ccivr.nms.timeselectservice.Saturday': 'Sábado',
  'ccivr.ivr.changtovoice': 'TTS',
  'ccivr.ivr.voiceupload.isconfdelete': 'Tem certeza de que deseja excluir?',
  'ccivr.ivr.ivrflow.clone': 'Actualização',
  'ccivr.nms.callendservice.title': 'Finalizar',
  'ccivr.ivr.variable.nameisnull': 'O nome está vazio.',
  'ccivr.nms.playcollectservice.chooseparam': 'Salvar entrada do usuário',
  'ccivr.nms.leavemessageservice.choose.agent.sample': 'Exemplo: 101 ou {\'$\'}{\'{\'}FLOW.workNo{\'}\'}',
  'ccivr.nms.process.parametertype': 'Tipo de parâmetro',
  'ccivr.ivrflow.createivrflow.fail': 'Falha ao adicionar.',
  'ccivr.nms.process.isParameterisEncrypt': 'Criptografado ou não',
  'ccivr.nms.playcollectservice.entermaxfailtimes': 'Informe o número máximo de falhas.',
  'ccivr.nms.businessinvokeservice.paramname': 'Nome',
  'ccivr.ivr.tracelog.callingNumber': 'Número de chamada',
  'ccivr.nms.playset.close': 'Desabilitado',
  'ccivr.nms.tansferservice.transferLastAgent.type.time.info': 'A chamada é transferida para o agente que é contatado dentro de um intervalo de tempo (unidade: minuto).',
  'ccivr.nms.queryservice.queryParam': 'Parâmetro de solicitação',
  'ccivr.nms.varibalesselect.processvaribales': 'Variável de fluxo',
  'ccivr.nms.conditionService.moduleDescription': 'Descrição do módulo',
  'ccivr.ivr.voicereview.reset': 'Redefinir',
  'ccivr.ivr.voicereview.choosetenantspace': 'Selecione um tenant.',
  'ccivr.nms.queryservice.video': 'Vídeo',
  'ccivr.nms.process.String': 'Em série',
  'ccivr.nms.tansferservice.skillId': 'ID de habilidade',
  'ccivr.ivr.textcontent.notnull': 'As informações de texto não podem ficar vazias.',
  'ccivr.nms.varibalesselect.selectvariables': 'Selecionar variável',
  'ccivr.nms.queryservice.querySkillMessage': 'Consulta de informações',
  'ccivr.ivr.variable.idisnull': 'A ID está vazia.',
  'ccivr.ivrflow.createivrflow.mainfail': 'O fluxo principal já existe. Falha ao adicionar.',
  'ccivr.ivrflow.variable.selectvariable': 'Selecione um parâmetro variável.',
  'ccivr.ivr.error.paramNumlimit': 'O número total do parâmetro não pode exceder 16.',
  'ccivr.ivr.voiceupload.fileioerror': 'Exceção de arquivo IO.',
  'ccivr.ivrflow.unlock.success': 'Status desbloqueado bem-sucedido',
  'ccivr.ivr.voiceedit.deleteodfsivrfail': 'Falha na exclusão porque os dados do IVR estão ocupados.',
  'ccivr.nms.playset.extendparam': 'Parâmetro estendido TTS',
  'ccivr.flowtest.operatehangup': 'Desligar',
  'ccivr.ivr.ivrflow.new': 'Novo',
  'ccivr.ivr.ivrflow.operate.ensurecancelrelease': 'Confirmar',
  'ccivr.ivr.voiceupload.selectLangRe': 'O idioma atual foi selecionado. Selecione outro idioma.',
  'ccivr.nms.process.saveerror.unSucctransfer': 'Falha ao salvar. O elemento de diagrama de transferência tem a linha de sucesso de transferência.',
  'ccivr.nms.process.interfaceerr': 'Erro de interface. Por favor\, verifique o registro.',
  'ccivr.nms.process.processinformation': 'Informações de fluxo',
  'ccivr.ivrflow.updateivrinfo.released': 'O fluxo foi liberado. Cancele a liberação e modifique os atributos.',
  'ccivr.nms.playcollectservice.second': 'Segundo',
  'ccivr.nms.timeselectservice.timeperiod': 'Período de tempo',
  'ccivr.ivrflow.variable.typeerror': 'Tipos de variáveis de entrada inconsistentes.',
  'ccivr.ivr.tracelog.accessCode': 'Código de acesso ao fluxo',
  'ccivr.ivr.variable.defaulttypeerror': 'O tipo de valor padrão não corresponde ao tipo de variável.',
  'ccivr.nms.conditionService.logicAND': 'E',
  'ccivr.ivr.tracelog.result.transsuccess': 'Bem-sucedido',
  'ccivr.ivr.ivrflow.property': 'Atributo',
  'ccivr.ivr.tracelog.actionParams': 'Parâmetros-chave da célula',
  'ccivr.ivr.ivrflow.ivrtype': 'Tipo',
  'ccivr.nms.queryservice.info': 'As informações de enfileiramento são consultadas usando um fluxo IVR quando uma chamada é transferida para uma fila de habilidades.',
  'ccivr.nms.questionnaireService.placeholder': 'Questionário',
  'ccivr.nms.questionnaireService.surveyname': 'Nome do questionário',
  'ccivr.nms.questionnaireService.question': 'Questões do questionário',
  'ccivr.nms.questionnaireService.info': 'O elemento do questionário é usado no cenário em que a tarefa de chamada de saída automática deve ser vinculada ao processo do questionário IVR.',
  'ccivr.nms.queryservice.specialList.flagInfo': 'O valor 0 indica que a lista especial não existe. 1 identificação existe na lista especial.',
  'ccivr.ivr.error.uncommitted': 'O administrador do sistema não pode reproduzir o arquivo que não foi enviado.',
  'ccivr.nms.timeselectservice.sunday': 'Domingo',
  'ccivr.nms.leavemessageservice.end': 'Por padrão\, a gravação é finalizada com a tecla de jogo da velha (#).',
  'ccivr.ivr.ivrflow.operate.cancelrelease.success': 'Cancelado com sucesso.',
  'ccivr.ivr.voiceedit.deleteoldfilefailed': 'Falha ao excluir arquivo antigo.',
  'ccivr.ivr.ivrflow.selectivrflow': 'Selecione um fluxo IVR.',
  'ccivr.ivr.voice.clicktorecord': 'Clique para Gravar',
  'ccivr.nms.tansferservice.transferWorkNo': 'Transferir para Agente',
  'ccivr.nms.shortmessageservice.processInformation': 'Mensagens',
  'ccivr.ivr.name.existence': 'O nome já existe. Insira outro.',
  'ccivr.nms.leavemessageservice.choose': 'Selecionar',
  'ccivr.ivr.voiceadvice.cancel': 'Cancelar',
  'ccivr.nms.menuservice.title': 'Configuração do menu',
  'ccivr.ivr.ivrflow.operate.release.needexecptionhanding': 'Configure e libere oficialmente um processo de manipulação de exceções.',
  'ccivr.ivr.tracelog.result.playover': 'Jogar sucesso',
  'ccivr.nms.tansferservice.moduleDescription': 'Descrição do módulo',
  'ccivr.nms.timeselectservice.thursday': 'Quinta-feira',
  'ccivr.nms.conditionService.parameterEmpty': 'O parâmetro 1 está vazio.',
  'ccivr.nms.queryservice.paramValue': 'Valor',
  'ccivr.ivr.voice.clicktostop': 'Clique para Parar',
  'ccivr.nms.playcollectservice.title': 'Jogar voz e coletar dígitos',
  'ccivr.nms.tansferservice.transCallingnum': 'Número do chamador',
  'ccivr.ivr.ivrflow.state': 'Status',
  'ccivr.ivr.voiceupload.defaultNoDe': 'O idioma padrão não pode ser excluído.',
  'ccivr.ivr.error.other': 'Falha no download.',
  'ccivr.ivr.tracelog.cellType': 'Tipo de elemento',
  'ccivr.ivr.voiceupload.tipformat': 'Atualmente\, apenas arquivos WAV são compatíveis. O tamanho do arquivo não pode exceder 2 MB.',
  'ccivr.ivr.voiceupload.tipformat1M': 'Atualmente\, apenas arquivos WAV são compatíveis. O tamanho do arquivo não pode exceder 1MB.',
  'ccivr.ivr.voiceupload.amountLimit5': 'Um máximo de cinco arquivos de voz podem ser adicionados neste cenário.',
  'ccivr.ivr.variable.int': 'Inteiro',
  'ccivr.ivr.voiceupload.new': 'Novo',
  'ccivr.ivrflow.grayrule.editsuccess': 'Modificado com sucesso.',
  'ccivr.nms.businessinvokeservice.inpuparam': 'Parâmetro de entrada',
  'ccivr.ivr.bussinessinvoke.bussinessinvoke': 'Configuração da interface',
  'ccivr.nms.brach.label': 'Selecionar condição',
  'ccivr.ivr.voiceedit.pathcheckfailed': 'Falha na validação das informações de caminho SFTP.',
  'ccivr.ivr.ivrflow.placeholder.ivrname': 'Insira um nome de fluxo.',
  'ccivr.ivrflow.operate.replace.fail': 'Falha ao substituir.',
  'ccivr.flowtest.enter.phone': 'inserir o número de telefone.',
  'ccivr.ivr.voicereview.filter': 'Filtro',
  'ccivr.flowtest.test.enter.callingnumber': 'Insira primeiro o número de chamada!',
  'ccivr.nms.tansferservice.sampleForCallingnum': 'Exemplo: 660001 ou {\'$\'}{\'{\'}FLOW.accessCode{\'}\'}',
  'ccivr.ivr.voiceupload.tipmodel': 'Você pode definir arquivos de voz ou texto de idiomas diferentes ao mesmo tempo (o comprimento máximo não pode exceder 1024 bytes). Durante a reprodução de voz\, o sistema reproduz automaticamente o arquivo de voz correspondente com base no idioma selecionado pelo usuário.',
  'ccivr.nms.satisfactionservice.tip': 'Configure o nível de satisfação no menu Configuração de satisfação. Se o fluxo tiver sido liberado, você poderá atualizar, editar, salvar e liberar o fluxo novamente para substituir o original para usar o nível de satisfação atualizado.',
  'ccivr.ivr.voiceedit.passwdisnull': 'Falha ao consultar as informações de passagem SFTP do call center.',
  'ccivr.common.warning.message': 'Selecione um registro.',
  'ccivr.nms.conditionService.conditionalEdit': 'Configuração de condição',
  'ccivr.ivr.ivrflow.operate.ensureclone': 'Confirmar atualização do fluxo',
  'ccivr.nms.LanguageService.label': 'Idioma',
  'ccivr.nms.tansferservice.transferLastAgent.mustbeaccesscode': 'O número chamador é o número de entrada do cliente\, por exemplo\, {\'$\'}{\'{\'}SYS.callingNumber{\'}\'}.',
  'ccivr.ivrflow.grayrule.existgrayflow': 'A versão de versão cinza já existe!',
  'ccivr.nms.satisfactionservice.descripLength': 'A chave da descrição da pesquisa de satisfação não excede 200',
  'ccivr.nms.timeselectservice.Sunday': 'Domingo',
  'ccivr.flowtest.operatesuccess': 'Sucesso',
  'ccivr.ivrflow.ivrflowname.specialStrValidateFailed': 'O valor não pode conter caracteres especiais e o comprimento não pode exceder 50 bytes.',
  'ccivr.nms.interface.outparamvalidate': 'O valor do parâmetro pode conter apenas dígitos\, letras e os seguintes caracteres especiais:._-',
  'ccivr.nms.interface.paramvalidate': 'Parâmetros devem conter apenas números\, caracteres em inglês ou _-',
  'ccivr.nms.interface.paramKeyValidate': 'No utilice el nombre del parámetro definido por la variable integrada (callId\, businessReqId\, callSorId\, currentTime). De lo contrario\, la asignación de valor no será válida.',
  'ccivr.ivr.voiceupload.texttovoice': 'O texto inserido é convertido automaticamente em uma voz.',
  'ccivr.ivr.voicereview.upload': 'Carregado',
  'ccivr.nms.shortmessageservice.sendType': 'Modo de envio de mensagem',
  'ccivr.nms.playcollectservice.receivednum': 'Dígitos recebidos',
  'ccivr.nms.brach': 'Ramificação',
  'ccivr.ivr.ivrflow.operate.queryInfo': 'Ver',
  'ccivr.nms.tansferservice.transInputWorkNo': 'Informe a ID do agente para o qual uma chamada é transferida.',
  'ccivr.ivr.ivrflow.yes': 'Sim',
  'ccivr.nms.assignservice.Dataassignment': 'Atribuição de dados',
  'ccivr.ivr.error.download': 'Falha ao baixar o arquivo do servidor SFTP.',
  'ccivr.ivr.voicereview.tenantspace': 'Tenant da casa',
  'ccivr.ivr.voiceupload.audiochannelfailed': 'O arquivo de áudio não é um arquivo mono.',
  'ccivr.ivr.error.overpower': 'O tenant não está autorizado.',
  'ccivr.ivr.voiceedit.inputmore': 'O valor não pode exceder',
  'ccivr.nms.process.success': 'O fluxo foi salvo com sucesso.',
  'ccivr.whitelist.whiteport': 'Porta da lista de confiança',
  'ccivr.nms.shortmessageservice.selectTemplate': 'Selecionar modelo',
  'ccivr.ivr.voiceadvice.setdefault': 'Definir como padrão',
  'ccivr.nms.label.selectCert': 'Selecionar certificado',
  'ccivr.ivr.variable.namevaluerule': 'O nome contém caracteres especiais. Por favor\, modifique-o.',
  'ccivr.ivr.element.namecheck': 'O nome contém caracteres especiais. Por favor\, modifique-o.',
  'ccivr.nms.leavemessageservice.recordparam': 'Parâmetro de gravação',
  'ccivr.ivrflow.grayrule.selectgrayrule': 'Selecione uma regra cinza.',
  'ccivr.nms.leavemessageservice.leavemessage': 'Deixar mensagem',
  'ccivr.nms.tansferservice.passingParameters': 'Parâmetros de transferência',
  'ccivr.ivr.voiceadvice.submit': 'Enviar',
  'ccivr.nms.interface.certFile': 'Arquivo do certificado',
  'ccivr.ivr.ivrflow.operate': 'Operação',
  'ccivr.ivrflow.grayrule.endnum': 'Número final',
  'ccivr.ivrflow.release.formalinfo': 'Após o lançamento, o fluxo desta versão é executado para todos os números.<br /> Se você precisar modificar o fluxo, precisará atualizar uma nova versão e modificá-la.',
  'ccivr.ivr.voiceupload.queryReferenceFlow': 'Exibir',
  'ccivr.ivrflow.grayrule.inputbeginnum': 'Exemplo: 12366668888',
  'ccivr.nms.conditionService.add': 'Novo',
  'ccivr.nms.conditionService.createCondition': 'Adicionar condição',
  'ccivr.ivr.ivrflow.operate.clone.success': 'O processo de atualização foi bem-sucedido.',
  'ccivr.nms.shortmessageservice.normalMessage': 'Mensagem curta',
  'ccivr.nms.leavemessageservice.choose.localtionID': 'Salvando o localtionID',
  'ccivr.ivr.voicereview.play': 'Reproduzir',
  'ccivr.flowtest.fail': 'Falha',
  'ccivr.ivr.voiceupload.vedionamewrong': 'Falha na verificação do FileNmae do upload de arquivos!',
  'ccivr.ivr.variable.management': 'Gerenciamento de variáveis',
  'ccivr.nms.playcollectservice.Receivemaxnum': 'Número máximo de dígitos recebidos',
  'ccivr.ivr.ivrflow.operate.release': 'Liberação',
  'ccivr.ivrflow.error.deleteivrflow.part.released': 'Alguns dos fluxos de IVR selecionados foram liberados. Cancelar a liberação antes de excluí-las.',
  'ccivr.ivr.name.errorspecial': 'O nome contém caracteres especiais. Por favor\, modifique-o.',
  'ccivr.ivr.tracelog.result.syserr': 'erro de sistema',
  'ccivr.nms.playservice.chose': 'Reproduzir conteúdo é selecionado a partir dos arquivos de áudio que foram carregados e revisados.',
  'ccivr.ivr.ivrflow.operate.delete': 'Excluir',
  'ccivr.ivr.bussinessinvoke.save': 'Salvar',
  'ccivr.ivr.variable.variableisreferenced': 'As variáveis são referenciadas pelo fluxo.',
  'ccivr.ivr.message.uploadimport.success': 'Adicionado com sucesso',
  'ccivr.nms.playcollectservice.tip8': 'Durante a coleta de dígitos\, o usuário insere a chave de libra (#) para terminar a coleta de dígitos. Por favor\, avise a voz.',
  'ccivr.nms.playcollectservice.tip7': 'Quando o número acumulado de vezes de falha excede o máximo\, a saída de falha geral é executada.',
  'ccivr.nms.playcollectservice.tip6': 'Se a coleta de dígitos falhar\, você não precisa reproduzir a voz. Se nenhum arquivo de voz estiver selecionado\, o sistema reproduzirá o prompt de voz novamente após a falha.',
  'ccivr.ivr.variable.textvaluelimit': 'O texto não pode conter mais de 120 caracteres.',
  'ccivr.nms.playcollectservice.tip5': 'Se ocorrer um erro durante a coleta de dígitos\, o sistema registrará uma falha\, executará automaticamente o erro de coleta de dígitos e continuará esperando pela coleta de dígitos.',
  'ccivr.whitelist.whitelistmanage': 'Gerenciamento da lista de confiança',
  'ccivr.nms.playcollectservice.tip4': 'Se o tempo em que o assinante para a entrada exceder o tempo de espera de coleta de dígitos pré-configurado\, o sistema registrará a falha. Além disso\, o sistema executa automaticamente o tempo limite de coleta de dígitos e aguarda a coleta de dígitos.',
  'ccivr.nms.playcollectservice.tip3': 'Durante a coleta de dígitos\, o usuário insere a chave de libra (#) para terminar a coleta de dígitos. Por favor\, avise a voz.(não precisa pressionar a tecla libra (#) para coletar dígitos no elemento de diagrama de configuração do menu)',
  'ccivr.nms.playcollectservice.tip2': 'Selecione o parâmetro tipo de string que você adicionou nas informações do processo.',
  'ccivr.nms.playcollectservice.tip1': 'Selecione um parâmetro para salvar a entrada do usuário.',
  'ccivr.flowtest.begintest': 'Iniciar',
  'ccivr.nms.process.paramnameNoCN': 'O paramname está errado.',
  'ccivr.ivr.ivrflow.operate.edit': 'Editar',
  'ccivr.nms.menuservice.button': 'Botões',
  'ccivr.ivr.bussinessinvoke.error': 'Erro',
  'ccivr.ivr.voiceupload.submit': 'Enviar',
  'ccivr.ivr.voiceupload.onlyOneData': 'Mantenha pelo menos um registro de idioma.',
  'ccivr.ivrflow.operate.replace.success': 'Substituído com sucesso.',
  'ccivr.ivr.voiceupload.audiosamplebitfailed': 'O número de quadro do arquivo de áudio não é 8BIT.',
  'ccivr.ivr.name.toolong': 'O nome excede o comprimento máximo permitido. Tente novamente.',
  'ccivr.nms.playservice.multivoice.type.date': 'Data (aaaammdd)',
  'ccivr.ivr.voicereview.all': 'Todos',
  'ccivr.nms.leavemessageservice.recordfile': 'Arquivo de gravação',
  'ccivr.nms.conditionService.logicOperator': 'Lógico',
  'ccivr.nms.tansferservice.queueindex': 'Posição de enfileiramento',
  'ccivr.nms.tansferservice.queuenum': 'Número de filas',
  'ccivr.ivr.tracelog.result.hangup': 'Desligar',
  'ccivr.ivr.voicereview.filetype': 'Tipo',
  'ccivr.ivr.tracelog.traceConfig': 'Configuração de rastreamento de código de acesso',
  'ccivr.ivr.variable.deleteselectvariable': 'Excluir variável',
  'ccivr.ivr.ivrflow.operate.create.accesscode.fail': 'Falha ao adicionar o código de acesso em ODFS ao liberar o fluxo IVR.',
  'ccivr.nms.playcollectservice.voicefile': 'Arquivo de voz',
  'ccivr.nms.conditionService.condition': 'Nome da Condição',
  'ccivr.ivr.ivrflow.edit': 'Efetivo',
  'ccivr.ivr.voiceupload.chinese': 'Chinês',
  'ccivr.ivr.voiceadvice.confirm': 'OK',
  'ccivr.ivr.voiceadvice.lengthError': 'O comprimento não pode exceder 100 caracteres.',
  'ccivr.nms.conditionService.operat': 'Operação',
  'ccivr.nms.process.sysparam': 'Parâmetros do sistema',
  'ccivr.grayrule.errorinfo.containspecialcha': 'O nome da regra de parâmetro contém caractere especial',
  'ccivr.nms.ChangeMediaService.placeholder': 'Alterar mídia',
  'ccivr.nms.userLevelService.placeholder': 'Definir o nível do usuário',
  'ccivr.nms.userLevelService.label': 'Nível do usuário',
  'ccivr.nms.userLevelService.example': 'Exemplo: 1-14 ou FLOW.nome da variável',
  'ccivr.nms.answerService.placeholder': 'Responder',
  'ccivr.nms.ringService.placeholder': 'Campainha',
  'ccivr.nms.ringService.videomodel': 'Modo de vídeo',
  'ccivr.ivr.error.releaseFailed': 'Falha ao publicar o script automaticamente',
  'ccivr.nms.ringService.sendonly': 'Enviar apenas',
  'ccivr.nms.ringService.recvonly': 'Receber apenas',
  'ccivr.nms.ringService.sendrecv': 'Receba e envie',
  'ccivr.ivrflow.error.deleteivrflow.released': 'O fluxo IVR selecionado foi liberado. Cancelar a liberação antes de excluí-la.',
  'ccivr.ivr.voicereview.refuse': 'Rejeitar',
  'ccivr.ivrflow.cancelrelease.accesscode.delete.failed': 'Falha ao excluir o código de acesso em ODFS ao cancelar o fluxo IVR liberado.',
  'ccivr.nms.leavemessageservice.flownamerepeat': 'O nome do arquivo de gravação e o ID local não podem usar a mesma variável.',
  'ccivr.ivr.bussinessinvoke.validatemessage': 'Já existe um parâmetro com o mesmo nome.',
  'ccivr.nms.timeselectservice.title': 'Seleção de tempo',
  'ccivr.nms.process.saveerror.connection': 'Falha ao salvar. Existem linhas inválidas.',
  'ccivr.ivr.voiceupload.selectvideo': 'Selecione um arquivo de vídeo.',
  'ccivr.nms.playcollectservice.collectwaittime': 'Tempo de espera para coleta de dígitos',
  'ccivr.nms.playcollectservice.enterCollectTimeout': 'Insira o tempo limite de coleta de dígitos',
  'ccivr.nms.playcollectservice.playset.reset': 'Redefinir',
  'ccivr.nms.conditionService.delete': 'Excluir',
  'ccivr.nms.process.saveerror.select': 'Selecione um tipo de alteração.',
  'ccivr.ivr.voiceadvice.currentdefault': 'Padrão',
  'ccivr.ivr.ivrflow.flowquote': 'É referenciado',
  'ccivr.nms.playservice.chosevideo': 'O conteúdo a ser reproduzido é selecionado a partir dos vídeos que foram carregados e revisados.',
  'ccivr.nms.queryservice.skillMsg': 'Informações de enfileiramento',
  'ccivr.nms.queryservice.specialListQuery': 'Informações da Lista Especial',
  'ccivr.ivr.voicereview.waitreview': 'Para revisar',
  'ccivr.ivr.variable.specialcharacters': 'Caracteres especiais ou chineses não são permitidos.',
  'ccivr.ivr.voice.exception.recordfail': 'Erro de gravação.',
  'ccivr.ivr.error.advicelimit': 'O comentário de revisão não pode conter mais de 100 caracteres.',
  'ccivr.nms.businessinvokeservice.selectservice': 'Selecionar interface',
  'ccivr.nms.queryservice.assignmentParameters': 'Parâmetro a ser definido',
  'ccivr.ivr.voicereview.chinese': 'Chinês',
  'ccivr.ivrflow.unlock.fail': 'Falha ao status desbloqueado',
  'ccivr.ivr.tracelog.callid': 'ID da chamada',
  'ccivr.ivr.variable.modifyvariable': 'Modificar variável',
  'ccivr.ivr.pageurls.errorsave': 'Erro',
  'ccivr.nms.shortmessageservice.smsType': 'Canal',
  'ccivr.ivr.ivrflow.operate.ensureclone.info': 'Tem certeza de que deseja atualizar o fluxo?',
  'ccivr.nms.transInputWorkNo.sample': 'Exemplo: 28956666 ou {\'$\'}{\'{\'}FLOW.currentWorkNo{\'}\'}',
  'ccivr.flowtest.enter': 'Insira o número de chamada.',
  'ccivr.nms.shortmessageservice.addresseeDesc': 'Opção, Número de celular único, se em branco, ele será enviado para o chamador',
  'ccivr.nms.businessinvokeservice.overtime': 'Intervalo de tempo limite',
  'ccivr.ivr.voiceupload.audiotypefailed': 'O formato do arquivo de áudio não é ALAW.',
  'ccivr.nms.process.number': 'Inteiro',
  'ccivr.nms.playcollectservice.changetomultivoice': 'Voz variável',
  'ccivr.ivr.error.connect': 'Falha ao conectar ao servidor SFTP.',
  'ccivr.ivr.variable.desclimit': 'A descrição não pode conter mais de 120 caracteres.',
  'ccivr.flowtest.pleasebegintest': 'O processo terminou.',
  'ccivr.ivr.tracelog.result.finshed': 'Terminado',
  'ccivr.nms.playservice.multivoice.type.price': 'Preço',
  'ccivr.nms.playcollectservice.onenumber': 'Coleção de um dígito',
  'ccivr.nms.menuservice.movedown': 'Para baixo',
  'ccivr.ivr.variable.defaultValue': 'Valor padrão',
  'ccivr.nms.process.saveerror.existnullentity': 'Existem elementos vazios no diagrama de dados.',
  'ccivr.ivrflow.operate.replace': 'Substituir',
  'ccivr.ivrflow.flowAccessCode': 'Código de acesso de fluxo',
  'ccivr.ivr.voiceedit.cancel': 'Cancelar',
  'ccivr.nms.tansferservice.samenamevalidate': 'Nome do parâmetro duplicado.',
  'ccivr.nms.businessinvokeservice.info2': 'Se o intervalo de tempo limite de chamada for excedido\, o módulo de falha de chamada será executado.',
  'ccivr.nms.businessinvokeservice.info1': 'As interfaces disponíveis precisam ser configuradas na página de configuração da interface.',
  'ccivr.ivr.variable.defaultvalueerror': 'O valor padrão contém caracteres especiais. Por favor\, modifique-o.',
  'ccivr.nms.tansferservice.transferLastAgent.type.time.label': 'min',
  'ccivr.nms.queryservice.specifiedSkill': 'Nome da fila',
  'ccivr.nms.queryservice.specifiedSkill.paramValue': 'ID do agente',
  'ccivr.nms.queryservice.specifiedSkill.paramValue.sample': 'Exemplo: 101 ou {\'$\'}{\'{\'}FLOW.workNo{\'}\'}.',
  'ccivr.nms.queryservice.specifiedSkill.result': 'Nome da Fila',
  'ccivr.nms.queryservice.specialList.flag': 'ID que existe na lista especial.',
  'ccivr.ivrflow.ivrflowtype.validateFailed': 'Tipo de fluxo incorreto.',
  'ccivr.ivrflow.error.deleteivrflow.fail': 'O fluxo não existe',
  'ccivr.ivr.languageid.repeat.fail': 'O languageId não existe.',
  'ccivr.ivr.ivrtextexception.fail': 'Falha ao adicionar o texto IVR.',
  'ccivr.ivr.illegal.fail': 'Parâmetro inválido.',
  'ccivr.ivr.type.fail': 'Tipo de upload incorreto.',
  'ccivr.ivr.delete.fail': 'Falha ao excluir o arquivo.',
  'ccivr.ivr.create.exception': 'Falha ao adicionar.',
  'ccivr.whitelist.validator.ip': 'Endereço IP inválido (IPv4 ou IPv6 ou nome de domínio)',
  'ccivr.interface.typeerror': 'Atualmente\, apenas GET e POST são suportados.',
  'ccivr.nms.process.parameterencryptips': 'Observe que parâmetros não criptografados armazenam valores de parâmetros em texto simples\, o que pode representar riscos de segurança se usado.',
  'ccivr.ivrflow.specify.accessCode': 'Especificar código de acesso do canal',
  'ccivr.ivr.transferRecord.startTime': 'Hora de início',
  'ccivr.ivr.transferRecord.endTime': 'Hora final',
  'ccivr.ivr.transferRecord.callNumber': 'Número de Chamada',
  'ccivr.ivr.transferRecord.flowAccessCode': 'Código de acesso do processo',
  'ccivr.ivr.transferRecord.transNumber': 'Número de encaminhamento',
  'ccivr.ivr.transferRecord.transResult': 'Resultado da transferência',
  'ccivr.ivr.transferRecord.recordedFile': 'Ficheiros de gravação',
  'ccivr.ivr.transferRecord.play': 'Reproduzir',
  'ccivr.ivr.transferRecord.download': 'Baixar',
  'ccivr.ivr.transferRecord.success': 'Sucesso',
  'ccivr.ivr.transferRecord.fail': 'Falhou',
  'ccivr.ivr.transferRecord.noMoreThan90Days': 'O intervalo não pode exceder 90 dias',
  'ccivr.transferrecord.download.record': 'Para baixar a gravação de transferência do processo:',
  'ccivr.transferrecord.play.timeCompare': 'A hora de início não pode ser maior que a hora de término',
  'ccivr.ivr.transferRecord.init': 'Inicializar',
  'ccivr.transferrecord.play.record': 'Reproduzir gravação de transferência de fluxo:',
  'ccivr.ivr.transferRecord.noright': 'O usuário atual não tem permissão para baixar a gravação.',
  'ccivr.ivr.transferRecord.query.failed': 'Falha ao consultar a gravação de encaminhamento',
  'ccivr.ivr.transferRecord.connect.uap.failed': 'Falha ao conectar-se ao servidor de arquivos',
  'ccivr.ivr.transferRecord.connect.file.notexist': 'Falha ao baixar o arquivo de gravação. Verifique se o arquivo existe.',
  'ccivr.nms.tansferservice.idleAgentCount': 'Número de agentes ociosos',
  'ccivr.nms.process.saveerror.queuing': 'Preencha pelo menos uma posição na fila\, número de agentes on-line e tempo de espera estimado.',
  'ccivr.nms.process.saveerror.queues': 'Preencha pelo menos o local da fila\, o número de agentes on-line\, o número de agentes ociosos e o tempo de espera estimado.',
  'ccivr.ivr.voicepop.inputname': 'Digite o nome da voz IVR.',
  'ccivr.ivr.voicereview.reviewmethod': 'Método de Revisão de Recursos de Voz',
  'ccivr.ivr.voicereview.manualreview': 'Auditoria Manual',
  'ccivr.ivr.voicereview.autopass': 'Aprovação automática',
  'ccivr.ivr.voiceupload.exceedLimit': 'O número de arquivos IVR excede o limite.',
  'ccivr.ivr.voiceupload.file.content': 'Conteúdo',
  'ccivr.ivr.voiceupload.file.update.content': 'Atualizar conteúdo',
  'ccivr.ivr.voiceupload.file.update.time': 'Tempo de atualização',
  'ccivr.ivr.voiceupload.file.update.status': 'Status',
  'ccivr.ivr.voiceupload.file.update.comments': 'Comentários',
  'ccivr.ivr.voiceupload.file.update.operation': 'Operação',
  'ccivr.ivr.voiceupload.file.update.delete': 'Excluir',
  'ccivr.ivr.voiceupload.file.update.submit': 'Enviar',
  'ccivr.ivr.voiceupload.file.update': 'Atualizar',
  'ccivr.ivr.voiceupload.textcontent': 'Texto',
  'ccivr.ivr.bussinessinvoke.long': 'Inteiro longo',
  'ccivr.ivr.voiceupload.fileformatwrong': 'Tipo incorreto.',
  'ccivr.nms.playservice.TTS': 'Reprodução de voz TTS',
  'ccivr.ivr.voiceupload.inputlanguageerror': 'A linguagem não existe!',
  'ccivr.ivr.voiceupload.choosestatus': 'Selecione o status da revisão.',
  'ccivr.ivr.bussinessinvoke.float': 'Flutuante',
  'ccivr.nms.playservice.interrupt': 'Pressione qualquer tecla para interromper a reprodução.',
  'ccivr.flowtest.test.error.tip': 'fluxo não configurado ou aplicado',
  'ccivr.nms.label.certType': 'Tipo de certificado',
  'ccivr.nms.label.inputCertCode': 'Insira o código do certificado',
  'ccivr.nms.label.certCode': 'Codificação do certificado',
  'ccivr.nms.label.certScene': 'Cenários de utilização do certificado',
  'ccivr.ivr.error.releaseFailed.tips': 'Falha na publicação automática do questionário. Configure e publique um processo de tratamento de exceções e publique manualmente o questionário.',
  'ccivr.ivr.ivrflow.operate.add': 'Adicionar',
  'ccivr.nms.playservice.chose.survey': 'Reproduzir conteúdo Selecionar ou adicionar voz a partir de áudios carregados e revisados',
  'ccivr.nms.playservice.chosetts.survey': 'Reproduzir conteúdo Selecionar ou adicionar texto a partir de textos TTS carregados e revisados\, recursos TTS necessários',
  'ccivr.nms.satisfactionservice.buttonNumber.Number': 'O número de teclas não deve ser superior a 9, nem inferior a 1.',
  'ccivr.nms.lightweight.tips': 'Por favor\, faça isso na versão leve do questionário',
  'ccivr.conditionService.param.tips': 'Quando o parâmetro 2 é definido como uma constante de string, é necessário adicionar aspas para usá-lo corretamente.',
  'ccivr.nms.conditionService.sample': 'Insira uma expressão de condição. A expressão pode conter o nome do parâmetro\, o valor e a operação complexa. Um parâmetro é referenciado no formato de nome FLOW.Parameter. Exemplo: SYS.currentNumber==FLOW.RechargeNumber&& (FLOW.AccountBalance - 20)>=FLOW.BillAmount',
  'ccivr.TimeSelectService.branch.wrongtime': 'Intervalo de tempo não especificado',
  'ccivr.ivr.voiceupload.VoiceNotificationTextDes': 'O conteúdo do modelo não pode começar com uma variável. O comprimento de uma única variável não deve exceder 32 bytes. Se o comprimento de uma única variável exceder 32 bytes, é recomendável dividir a variável em várias variáveis.',
  'ccivr.playservice.branch.query': 'Erro de consulta',
  'ccivr.businessInvokeService.err': 'Falha de Interface de Chamada',
  'ccivr.playservice.branch.ring': 'A campainha falhou',
  'ccivr.playservice.branch.userLevel': 'Falha na configuração',
  'ccivr.tansferservice.branch.transferErr': 'Erro de transferência',
  'ccivr.nms.transferservice.choose.recordfilename.remark': 'usada no modo de pool de call center',
  'ccivr.playservice.branch.change': 'Erro de troca',
  'ccivr.nms.conditionService.conditionBranch': 'Ramificação Condicional',
  'ccivr.playservice.branch.playerr': 'Erro ao reproduzir Voz',
  'ccivr.PlayCollectService.branch.err': 'Erro de reprodução de anúncio e de coleta de dígitos',
  'ccivr.nms.playcollectservice.warntype': 'Tipo de Prompt',
  'ccivr.ivr.bussinessinvoke.paramTip': 'Nome do parâmetro, por exemplo: GLOBAL.language ou FLOW.testid',
  'ccivr.ivr.description.containspecialcharacter': 'O modelo contém caracteres especiais ~',
  'ccivr.ivr.voiceupload.VoiceNotificationTextrule': 'Para mais regras de modelo de notificação de voz, consulte o símbolo i na caixa de entrada',
  'ccivr.ivr.voiceupload.referenceText': 'Mensagens de texto de notificação de voz',
  'ccivr.ivrvoice.play.voicetimelimit': 'O áudio é longo. Por favor, reduza o conteúdo do texto e tente novamente.',
  'ccivr.ivr.voice.pitch': 'Tonalidade',
  'ccivr.ivr.voice.voicename': 'Som',
  'ccivr.ivr.description.variablelimit': 'As variáveis de modelo não podem exceder 10',
  'ccivr.ivr.voiceupload.query': 'Detalhes',
  'ccivr.ivr.voice.scene.voicenotification': 'Notificação de voz',
  'ccivr.ivr.voiceupload.auditionTextlimit': 'O texto não pode exceder 300 bytes',
  'ccivr.ivr.voicereview.voiceId': 'Identificação de voz',
  'ccivr.ivr.voice.speed': 'Velocidade da fala',
  'ccivr.ivr.description.variableerror': 'Variável de modelo mal formada',
  'ccivr.ivr.voiceadvice.audition': 'Audição',
  'ccivr.ivr.connectsftp.uploadfile.exception': 'Falha na conexão com o SFTP\,Falha no upload do arquivo',
  'ccivr.ivr.description.voiceNotificationlimit': 'A descrição do texto não pode exceder 500 bytes',
  'ccivr.ivr.voice.volume': 'Volume',
  'ccivr.ivr.voiceupload.VoiceNotificationtipmodel': 'Você pode configurar o arquivo de reprodução de áudio ou o texto de reprodução de áudio em diferentes idiomas ao mesmo tempo (o comprimento máximo não pode exceder 500 bytes). Durante a reprodução de áudio, os arquivos de áudio correspondentes serão alternados automaticamente de acordo com a seleção de idioma do usuário.',
  'ccivr.ivr.voiceupload.fileformatconverterror': 'Erro na conversão do formato do arquivo ou configuração incorreta do ffmpeg.',
  'ccivr.leaveMessage.maxtime.range': 'A duração máxima da gravação varia de 10 a 180 segundos.',
  'ccivr.nms.conditionService.tips': 'Expressões condicionais podem conter variáveis, valores e operações complexas. O operador de comparação entre valores ou strings de caracteres suporta > < >= <= !=. O operador Booleano suporta && {\'|\'}{\'|\'} == !=.Exemplo: SYS.currentNumber==FLOW.RechargeNumber && (FLOW.AccountBalance-20)>=FLOW.BillAmount',
  'ccivr.ivr.voiceupload.fileName.validate': 'O nome do arquivo não pode conter os seguintes caracteres especiais:',
  'ccivr.nms.play.method': 'Método',
  'ccivr.nms.play.source': 'Recurso',
  'ccivr.ivr.ivrflow.operate.release.referencedflow': 'Falha ao cancelar a liberação! O fluxo foi referenciado.',
  'ccivr.ivr.voice.scene.2d.avatar': 'Humanos Virtuais',
  'ccivr.ivr.voice.picturechoose':'Seleção do arquivo de imagem',
  'ccivr.ivr.voiceupload.selectpicture': 'Selecione um arquivo de imagem',
  'ccivr.ivr.voiceupload.tippictureformat': 'Atualmente, apenas arquivos de imagem JPG, PNG e JPEG são suportados. A resolução do arquivo não pode exceder 720p e o tamanho do arquivo não pode exceder 2 MB.',
  'ccivr.ivr.voiceupload.tippicturemodel': 'Você pode definir arquivos de imagem em diferentes idiomas ao mesmo tempo. O sistema muda automaticamente para o ficheiro de imagem correspondente com base na língua seleccionada pelo utilizador durante a síntese digital da pessoa.',
  'ccivr.ivr.voiceupload.tip2DVideoformat': 'Atualmente, apenas arquivos de vídeo MP4 são suportados. A resolução do arquivo não pode exceder 720p e o tamanho do arquivo não pode exceder 5 MB.',
  'ccivr.ivr.voiceupload.tip2Dvideomodel': 'Você pode definir arquivos de vídeo em diferentes idiomas ao mesmo tempo. Durante a composição humana digital, o sistema muda automaticamente para o arquivo de vídeo correspondente com base no idioma selecionado pelo usuário.',
  'ccivr.ivr.voiceupload.vedioMp4formatwrong': 'Formato de arquivo incorreto. Importe um arquivo MP4.',
  'ccivr.ivr.voiceupload.tipvideo2Dformat': 'Atualmente, apenas arquivos de vídeo no formato mp4 são suportados e o tamanho do arquivo não pode exceder 5M.',
  'ccivr.ivr.voicereview.picture': 'Imagem',
  'ccivr.ivr.voicereview.2dVideo': 'Vídeo Humano Virtual',
  'ccivr.ivr.voiceupload.tipPicture2Dformat': 'Atualmente, apenas imagens em formato jpg, png e jpeg são suportadas. O tamanho do arquivo não pode exceder 2 MB.',
  'ccivr.ivr.voiceupload.pictureformatwrong': 'O formato do arquivo está incorreto. Importe um arquivo JPG, PNG ou JPEG!',
  'ccivr.ivr.voiceupload.file.updateTitle': 'Editar recursos de áudio e vídeo',
  'ccivr.ivr.voiceupload.file.createTitle': 'Novo recurso de áudio e vídeo',
  'ccivr.ivr.voiceupload.tippictureresolution': 'A resolução da imagem não pode exceder 720p',
  'ccivr.ivr.voiceupload.tipvideoresolution': 'Resolução de vídeo não superior a 720p',
  'oifde.service.invoke.businessVirtualHumanService.name': 'Resposta de Pessoa Virtual',
}
