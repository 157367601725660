export default {
  'isales.specialday.title.speclistmanage.moveblacklists': 'mover lista negra',
  'isales.agent.button.search': 'Consulta',
  'isales.taskresult.title.chooseBeginTime': 'Selecione a hora de início',
  'isales.busi.result.title.description': 'Descrição',
  'isales.manual.title.inputresult': 'Insira um resultado de chamada originada',
  'isales.taskresult.title.retryTime': 'Tentativas de chamadas',
  'isales.import.filter.message.busiResultIsNull': 'O resultado do negócio não pode estar vazio',
  'isales.appointment.title.task': 'Associar tarefa de chamada originada',
  'isales.file.message.SK.placeholder': 'Insira um SK',
  'isales.statistics.field.abandonmentRateDescription': 'Taxa de abandono',
  'isales.tasktemplate.field.templateName': 'Nome do modelo',
  'isales.taskstatistics.placeholder.calling': 'Chamando: {0}',
  'isales.manualdata.title.call': 'Chamar',
  'isales.manual.title.result': 'Resultado',
  'isales.taskmanagement.title.createTask': 'Criar',
  'isales.agent.pageurls.success': 'Sucesso',
  'isales.management.placeholder.calledNo': 'Insira um número chamado',
  'isales.data.title.tableTitle': 'Lista de números',
  'isales.data.prop.anonymType.countError': 'É possível definir no máximo 10 atributos do tipo de criptografia',
  'isales.blacklist.title.validityTime': 'Tempo de validade (hora)',
  'isales.task.promotion.updateSuccess': 'A tarefa de marketing multimídia foi modificada com sucesso',
  'isales.task.promotion.baseInfo': 'Informa\u00e7\u00f5es B\u00e1sicas',
  'isales.file.title.rule.property.row': 'Coluna Atributo',
  'isales.tasklogic.field.callturntype': 'Modo arredondado',
  'isales.specialday.title.holidayName': 'Nome da data especial',
  'isales.agent.label.maxWaitTimeSpan': 'O valor varia de 0 a 60000',
  'isales.appointment.message.adjustmentEmpty': 'Nenhum dado de chamada de saída reservada válido está selecionado',
  'isales.tasksample.message.agentEmpty': 'No se ha seleccionado ningún objeto de formación',
  'isales.tasklogic.field.callInterval': 'Intervalo(s) de chamada',
  'isales.taskmanagement.field.answer': 'Texto de resposta',
  'isales.import.message.failnum': 'Número de registros falhados',
  'isales.task.message.startSuccess': 'A tarefa de chamada originada foi iniciada com sucesso',
  'isales.appointment.title.createAppoint': 'Criar chamada originada agendada',
  'isales.agent.message.agentMsgException': 'Altere a senha inicial do agente e sincronize-a com o agente',
  'isales.file.title.username': 'Nome de usuário',
  'isales.file.message.rule.success': 'A regra foi modificada com sucesso',
  'isales.specialday.message.nameError': 'O nome da data especial está em formato incorreto',
  'isales.taskmanagement.title.batchStartTask': 'Início do lote',
  'isales.taskmanagement.field.quotas': 'Limite máximo',
  'isales.taskmanagement.field.Automatic': 'chamada originada automática',
  'isales.import.message.repeatnum': 'Número de registros duplicados',
  'isales.file.title.delete.fail': 'Falha ao excluir as informações do servidor',
  'isales.agent.message.skillConfFail': 'Falha ao configurar a fila de habilidades',
  'isales.manual.field.callingtask': 'Tarefa de chamada',
  'isales.task.promotion.promotionType': 'Tipo de mídia',
  'isales.taskresult.title.choosebusiresult': 'Selecione o resultado do negócio',
  'isales.common.message.checkpositiveinteger': 'inserir um inteiro positivo no formato correto',
  'isales.data.prop.isPushAgent.countError': 'Um máximo de 15 atributos podem ser definidos para serem enviados para um agente',
  'isales.manual.message.lock.success': 'Os dados de chamada de saída foram bloqueados com sucesso',
  'isales.file.title.serverInformation': 'Informações do servidor',
  'isales.taskresult.field.failsFailedRouteSkillQueue': 'Falha ao rotear a fila de habilidades',
  'isales.work.message.dategt7days': 'O intervalo de tempo não pode exceder sete dias',
  'isales.management.message.taskIdEmpty': 'O sinalizador de tarefa de chamada de saída está vazio',
  'isales.management.message.templateAnalyse': 'Por modelo',
  'isales.file.message.AK.placeholder': 'Insira um AK',
  'isales.agent.title.skillModify': 'Modificar fila de habilidades',
  'isales.taskinfo.message.batchUpdate.paramsNull': 'Modifiez le paramètre à modifier',
  'isales.specialday.field.Wednesday': 'Quarta',
  'isales.taskmanagement.field.taskName': 'Nome da tarefa',
  'isales.business.result.searchChildrenName': 'Insira o nome do resultado de subnegócios',
  'isales.task.message.NoPauseTask': 'Nenhuma tarefa que atenda às condições está disponível',
  'isales.agent.field.newpassword': 'Nova senha',
  'isales.agent.message.refreshSkillException': 'Ocorreu um erro ao atualizar a fila de habilidades',
  'isales.task.promotion.sendLimit': 'Limite de envio diário',
  'isales.taskstatistics.message.Unit.Piece': 'Unidade: número',
  'isales.taskresult.title.userAccount': 'Iniciado por',
  'isales.task.promotion.deleteSuccess': 'A tarefa de marketing multimídia foi excluída com sucesso',
  'isales.specialday.message.weeklyRepeat': 'O período de data especial está duplicado',
  'isales.file.title.bucket': 'Bucket',
  'isales.taskinfo.message.input': 'Caracteres inválidos',
  'isales.specialday.field.byDate': 'Por data',
  'isales.taskinfo.field.audioAgentType': 'Áudio',
  'isales.taskinfo.field.videoAgentType': 'Vídeo',
  'isales.taskinfo.field.mediaAgentType': 'Multimídia',
  'isales.taskinfo.field.versatileAgentType': 'Versátil',
  'isales.taskresult.field.failsNotintheservicearea': 'O telefone está fora de serviço',
  'isales.file.title.editserver': 'Modificar servidor',
  'isales.agent.label.information': 'Informações',
  'isales.import.message.template.dataNum': 'Um máximo de {0} registros de dados podem ser importados de cada vez',
  'isales.import.message.allow.task.statue': ',A tarefa só pode ser importada se o status da tarefa for Rascunho ou Pausado!',
  'isales.file.title.SK': 'SK',
  'isales.taskstatistics.field.notcompletedcalls': 'Pendente',
  'isales.specialday.field.Sunday': 'Domingo',
  'isales.taskcalllogic.message.typeConflictWhenDataTypeSame': 'Existe uma política cujo tipo de número é o mesmo da política existente\, mas políticas diferentes são configuradas com base no resultado',
  'isales.result.displayResult': 'Causa de falha de chamada de saída',
  'isales.tasklogic.field.isResultCallnoLogic': 'Se deseja configurar com base no resultado',
  'isales.tasklogic.field.numberorder': 'Número de sequência',
  'isales.taskinfo.title.chooseSkill': 'Selecionar fila de habilidades',
  'isales.work.title.reservedCalls': 'Chamadas reservadas',
  'isales.task.promotion.promotionPolicy': 'Política de marketing',
  'isales.manual.message.lock.failed': 'Falha ao bloquear os dados da chamada originada',
  'isales.result.outgoingCallResult': 'Resultado da chamada de saída',
  'isales.import.filter.message.busiSubStatusSame': 'Os resultados de subnegócios sob o mesmo resultado comercial não podem ser os mesmos',
  'isales.agent.calledroute.fail': 'falha',
  'isales.task.title.callpolicy': 'Política de chamadas de saída',
  'isales.tasktype.field.predict': 'chamada originada Prevista',
  'isales.blacklist.message.customer.notincall': 'O cliente não está em chamada',
  'isales.data.title.edit': 'Editar',
  'isales.data.title.undoEdit': 'Desfazer a edição',
  'isales.skillqueue.agentrole.qc': 'Inspetor de qualidade',
  'isales.taskmanagement.title.batchPauseTask': 'Suspensão do lote',
  'isales.taskmanagement.title.editLogic': 'Alterar lógica',
  'isales.operlog.message.data.downloadfailfile': 'Baixe o arquivo de dados com falha',
  'isales.taskresult.field.failsCalledpartybusy': 'A parte chamada está ocupada',
  'isales.taskmanagement.field.pickUpRuleLimit': 'Restrições',
  'isales.taskresult.field.success': 'Bem-sucedido',
  'isales.management.message.orderSequence': 'Ordem crescente',
  'isales.taskstatistics.field.callCompletionRate': 'Taxa de conclusão da chamada',
  'isales.outboundtime.title.set': 'Definir o tempo de chamada de saída',
  'isales.workbench.execution': 'Execução de chamada de saída',
  'isales.management.title.adjustment.changeTime': 'Ajustado em',
  'isales.data.orderType.asc': 'Crescente',
  'isales.file.message.rule.columnRepeat': 'A coluna ID de chamada de saída\, coluna de número\, coluna de categoria\, coluna de intervalo de chamada e coluna de atributo não podem ser as mesmas',
  'isales.repeatfile.export.repeatline': 'linha de repetição',
  'isales.agent.contact.Voice': 'Voz',
  'isales.taskresult.title.failCode': 'Causa',
  'isales.manual.message.call.success': 'A chamada de saída foi feita com sucesso',
  'isales.task.message.numberNotcalled': 'Registros falhados: {0}',
  'isales.agent.label.busy': 'Fila ocupada ou cheia',
  'isales.task.promotion.basicInfo': 'Informações básicas',
  'isales.taskresult.field.failsCallrejected': 'A chamada foi rejeitada',
  'isales.agent.label.release': 'Liberação',
  'isales.task.promotion.sendGateway': 'Gateway',
  'isales.file.title.addserver': 'Adicionar servidor',
  'isales.taskmanagement.field.calledNo': 'Número chamado',
  'isales.import.message.result.tip': 'Falha ao exportar o resultado da seleção diferencial',
  'isales.task.promotion.isExp': 'A tarefa de marketing multimídia expirou',
  'isales.taskresult.field.failsInvalidUser': 'Usuário inválido',
  'isales.file.title.addrule': 'Adicionar regra',
  'isales.agent.label.skillpara': 'Configurações de parâmetro',
  'isales.task.promotion.invalidation': 'inválido',
  'isales.calledroute.title.selectivrflow': 'Escolha um robô inteligente',
  'isales.taskmanagment.message.chartDataError': 'Falha ao carregar o gráfico',
  'isales.taskmanagement.message.timeerror': 'A hora inicial não pode ser posterior à hora final',
  'isales.taskcalllogic.field.notServiceArea': 'O cliente não está na área de serviço',
  'isales.specialday.title.createspecialday': 'Criar data especial',
  'isales.taskmanagement.message.queryTime': 'Insira uma hora de conclusão',
  'isales.taskmanagement.title.batchEditCallPolicy': 'Modificar em lote a política das chamadas efetuadas',
  'isales.file.title.import.file.invalid': 'Falha ao verificar o arquivo',
  'isales.specialday.message.enterworkno': 'Insira uma ID do agente',
  'isales.management.placeholder.commonIvrFlowAccessCode': 'Insira um Código de acesso de fluxo IVR comum',
  'isales.tasklogic.field.callturntype.numbertype': 'Por tipo de número',
  'isales.taskresult.field.failsModemorfax': 'Modem ou Fax detectado',
  'isales.taskresult.field.failsResulttimesoutafterpicksup': 'O resultado da detecção de chamada de saída após o tempo limite do desligado do ACD',
  'isales.notification.receiver.number': 'Número do destinatário',
  'isales.data.prop.mandatory': 'Obrigatório',
  'isales.common.title.dataReset': 'Redefinir',
  'isales.taskinfo.title.chooseAgent': 'Selecionar Agente',
  'isales.taskstatistics.field.failCount': 'Número de chamadas falhadas',
  'isales.work.title.serviceAccount': 'Conta comercial',
  'isales.work.title.surveyAnswerNum': 'Número de perguntas respondidas',
  'isales.data.title.importResult': 'Resultado de importação',
  'isales.management.message.filter': 'Filtrar dados',
  'isales.work.title.callTimeTotal': 'Duração total da chamada (s)',
  'isales.taskresult.field.no': 'Não',
  'isales.blacklist.message.confirmOpt': 'Tem certeza de que deseja realizar operações no número da lista especial selecionada?',
  'isales.task.promotion.all': 'Todos',
  'isales.taskpolicy.field.minChangeTime': 'Duração mínima de permanência',
  'isales.management.title.adjustment.destAccountName': 'Novo executor',
  'isales.manutask.title.viewresult': 'Resultado da chamada de saída',
  'isales.common.title.edit': 'Editar',
  'isales.taskcalllogic.message.maxPolicyNo': 'Um máximo de cinco tipos de número não padrão são permitidos',
  'isales.common.title.save': 'Salvar',
  'isales.management.title.editLogic': 'Alterar lógica',
  'isales.taskresult.field.failsSsit': 'Tom para enviar informações dedicadas',
  'isales.manualdata.title.order': 'Reservar chamada de saída',
  'isales.taskmanagement.title.endtime': 'Hora de término',
  'isales.data.prop.isPushAgent.anonymTypeError': 'Os atributos do tipo de criptografia não podem ser enviados para um agente',
  'isales.task.field.callFlagProgress': 'Em execução',
  'isales.appointment.message.notInWhiteList': 'O número não está na lista de confiança e não pode ser chamado',
  'isales.file.title.rule.basic': 'Informações básicas',
  'isales.taskinfo.message.batchEditCallLogic.differentCallModeExist': 'As lógicas de chamada de saída não podem ser modificadas em lotes para as tarefas com diferentes modos de chamada',
  'isales.work.title.outboundCallTask': 'Tarefa de chamada de saída',
  'isales.work.title.multimediaManage': 'Gestão de Marketing Multimídia',
  'isales.manual.message.task.isnorunning': 'A tarefa de chamada de saída atual não está no estado de execução',
  'isales.management.message.blackCalledNo': 'O número chamado está na lista de blocos e não pode ser adicionado',
  'isales.business.result.children': 'Substatus',
  'isales.taskresult.field.failsUnknownError': 'Erro desconhecido',
  'isales.marketing.notification': 'Marketing multimídia',
  'isales.agent.contact.audioclicktodial': 'Clique para discagem por voz',
  'isales.outboundtime.title.endTime': 'Hora de término',
  'isales.taskresult.field.failsFailedAgentIsBusy': 'O agente não está no estado disponível',
  'isales.file.title.rule.data': 'Dados de número',
  'isales.taskpolicy.field.connRateMethod': 'Algoritmo para Taxa de conexão de chamada originada',
  'isales.work.title.completeNum': 'Pesquisas concluídas',
  'isales.busi.result.message.lastrecord': 'Defina primeiro o resultado comercial da última saída',
  'isales.manual.title.viewPredictionResult': 'Resultado de chamada de saída previsto-',
  'isales.calledroute.button.finish': 'Terminar',
  'isales.taskmanagement.title.deleteSuccess': 'A tarefa foi excluída com sucesso',
  'isales.management.message.sessionIdFormatIncorrect': 'A sessionId pode conter apenas dígitos',
  'isales.agent.label.operate': 'Operação',
  'isales.task.title.complete': 'Terminar',
  'isales.file.title.port.placeholder': 'inserir o número da porta do servidor',
  'isales.manual.message.data.checklock.error': 'Os dados de chamada de saída atuais não estão bloqueados ou foram bloqueados por outro agente',
  'isales.task.promotion.isSendNow': 'Enviar imediatamente',
  'isales.management.message.createSuccess': 'Os dados de chamada de saída são criados com sucesso',
  'isales.taskresult.field.failsSittone': 'Tom de informação especial',
  'isales.blacklist.title.deleteSuccess': 'Sucesso',
  'isales.taskresult.field.failsUserrejection': 'A chamada foi rejeitada',
  'isales.management.message.filterNo': 'Não',
  'isales.task.promotion.endTime': 'Hora de fim',
  'isales.notification.receiver.address.placeholder': 'Insira o endereço do destinatário',
  'isales.manual.message.releaseData': 'Selecione os dados de chamada de saída a serem liberados',
  'isales.taskinfo.message.batchEditCallLogic.differentPolicyTypeExist': 'As lógicas de chamada de saída não podem ser modificadas em lotes para as tarefas com diferentes tipos de política',
  'isales.agent.field.curcountpassword': 'Senha atual',
  'isales.sendStatus.field.success': 'Bem-sucedido',
  'isales.task.promotion.receiver': 'Destinatário',
  'isales.agent.label.skillQueue': 'Fila de habilidades:',
  'isales.agent.label.accountName': 'Nome do agente',
  'isales.taskresult.message.details': 'Detalhes',
  'isales.notification.receiver.delete.variable': 'Configurar o atributo',
  'isales.manual.field.callednumber': 'Número chamado',
  'isales.task.promotion.expectedTime': 'Tempo de envio esperado',
  'isales.skill.label.skilldesc': 'Descrição',
  'isales.agent.label.agentWeight': 'Peso do agente',
  'isales.taskmanagement.field.intention': 'Intenção reconhecida',
  'isales.blacklist.message.customer.empty': 'Nenhum cliente de chamada',
  'isales.taskcalllogic.message.dataTypeExist': 'O tipo de dados já existe',
  'isales.agent.message.syncPwdFailed': 'Falha ao sincronizar a senha do agente',
  'isales.taskresult.field.failsFailedRouteCallToAttendant': 'Falha ao transferir a chamada para o agente',
  'isales.agent.label.playVoice': 'Reproduzir tom de espera',
  'isales.tasklogic.field.turn': 'Arredondar',
  'isales.agent.message.saveSuccess': 'A configuração do agente foi salva com sucesso',
  'isales.file.title.rule.desc.placeHolder': 'Insira uma descrição de regra',
  'isales.management.message.createTaskstatusError': 'Não é possível criar dados para a tarefa de chamada originada porque ela não está no estado de rascunho ou suspensa',
  'isales.data.prop.propCodeExists': 'O código de atributo já existe',
  'isales.tasklogic.message.busiresultpolicyIncomplete': 'As informações da política de resultado de negócios estão incompletas',
  'isales.management.title.import': 'Importar Dados de Chamada Saída',
  'isales.business.result.delete.error': 'Nenhum resultado comercial selecionado',
  'isales.file.title.rule.validator.server': 'A configuração do servidor está incorreta',
  'isales.specialday.field.Friday': 'Sexta',
  'isales.data.prop.status.published': 'Publicado',
  'isales.manual.message.title': 'Insira um título',
  'isales.speclist.blacklist.message.moveSuccess': 'Número transferido para a blacklist com sucesso',
  'isales.taskresult.field.failsRecordcanceled': 'Quando o OBS muda para o estado de espera\, os registros em curso são cancelados',
  'isales.management.message.notification.download': 'Baixar Modelo de Dados de Marketing',
  'isales.speclistmanage.title.calledNo': 'Número',
  'isales.business.result.search': 'Pesquisar',
  'isales.taskpolicy.message.saveSuccess': 'A política de chamadas originadas foi configurada com sucesso',
  'isales.busi.result.item.unset': 'Não definido',
  'isales.import.message.upload.fail': 'Falha ao carregar o arquivo',
  'isales.work.title.callTimeAvg': 'Duração média de chamada (s)',
  'isales.data.prop.status': 'Status',
  'isales.manual.message.call.update.error': 'Falha ao atualizar o resultado da chamada originada',
  'isales.common.title.operation': 'Operação',
  'isales.business.result.edit': 'Modificar resultado de negócios',
  'isales.specialday.title.editSpecialday': 'Editar data especial',
  'isales.agent.label.waitVoice': 'Tom de espera',
  'isales.speclistmanage.title.operation': 'Operação',
  'isales.appointment.message.callFail': 'Falha',
  'isales.taskresult.field.failsAgentdevicetimesout': 'A ocupação do agente expirou tempo limite',
  'isales.management.message.calledNoOneCategory': 'Categoria do primeiro número chamado',
  'isales.taskresult.field.failsNoanswer': 'A parte chamada não atende a chamada',
  'isales.data.message.addNumberUpperLimit': 'Podem ser adicionados no máximo 10 números',
  'isales.marketing.OutgoingCall': 'Telemarketing',
  'isales.file.title.rule.name': 'Nome da regra',
  'isales.tasktemplate.message.saveFail': 'Falha ao configurar o modelo de chamada de saída',
  'isales.data.prop.propType': 'Tipo de atributo',
  'isales.taskresult.title.inputSessionId': 'Insira o número da sessão',
  'isales.task.promotion.pauseFaied': 'Falha ao suspender a tarefa de marketing multimídia',
  'isales.calledroute.button.cancel': 'Cancelar',
  'isales.tasktype.field.intelligence': 'chamada originada Inteligente',
  'isales.business.result.code': 'Código do resultado comercial',
  'isales.taskresultexport.message.downloadExportdatafailinfo': 'Falha na verificação. Não é possível localizar os dados do arquivo exportados',
  'isales.taskmanagement.title.start': 'Iniciar',
  'isales.data.prop.createDataPropFail': 'Falha ao criar o atributo',
  'isales.management.title.adjustment.orgAccountName': 'Executor antigo',
  'isales.taskstatistics.field.incomplete': 'Número de Clientes Aguardando Chamadas',
  'isales.management.message.callNoInBlackList': 'O número não pode ser chamado porque ele está na lista de bloqueios',
  'isales.callNo.field.type': 'Tipo de número',
  'isales.tasktype.field.auto': 'Chamada de saída normal do IVR',
  'isales.task.promotion.messageTemplate': 'Modelo de notificação',
  'isales.tasklogic.field.substate': 'Subestado',
  'isales.specialday.title.deleteSuccess': 'Bem-sucedido',
  'isales.agent.message.deleteSkillConfFail': 'Falha ao excluir as informações da fila de habilidades',
  'isales.operlog.message.data.downloadtemplate': 'Download do modelo de importação de dados',
  'isales.agent.button.batchmodify': 'Alteração em lote',
  'isales.management.message.deleteTaskstatusError': 'A tarefa de chamada originada não pode ser excluída porque não está no estado de rascunho ou pausa',
  'isales.task.promotion.selectSendTemplate': 'Selecione um modelo',
  'isales.taskresult.field.failsCpp': 'Encaminhamento de chamada ao desligar',
  'isales.taskresult.field.failsAns5': 'Telefone Texto (ANS5)',
  'isales.taskresult.field.failsAns4': 'Telefone Texto (ANS4)',
  'isales.taskresult.field.failsAns3': 'Telefone Texto (ANS3)',
  'isales.taskresult.field.failsAns2': 'Telefone Texto (ANS2)',
  'isales.taskresult.field.failsAns1': 'Telefone Texto (ANS1)',
  'isales.taskinfo.message.batchEditCallLogic.selectEmpty': 'A operação é inválida porque nenhuma região está selecionada. Selecione uma região',
  'isales.task.promotion.startSuccess': 'A tarefa de marketing multimídia foi publicada com sucesso',
  'isales.agent.message.modifyPwdFailed': 'Falha ao alterar a senha do agente',
  'isales.agent.message.queryCallCenterFail': 'Falha ao consultar os dados do call center',
  'isales.taskstatistics.message.no.busiResult': 'Nenhum resultado comercial está marcado',
  'isales.blacklist.message.updateSuccess': 'Modificar comentário sucesso',
  'isales.data.filed.noResult': 'Não',
  'isales.management.title.callbot': 'Chatbot de chamada originada',
  'isales.blacklist.message.paramEmpty': 'Selecione um número na lista de blocos',
  'isales.taskmanagement.title.pauseCount': 'Um máximo de 30 tarefas de chamada originada podem ser suspensas de cada vez',
  'isales.task.promotion.saveFailed': 'Falha ao salvar a tarefa de marketing multimídia',
  'isales.taskpolicy.message.input.minAvgCallTime': 'A Duração Média de Chamada Mínima não pode ser superior ao Máximo. Duração média da chamada',
  'isales.manual.field.callflag.return': 'Voltar',
  'isales.operlog.message.data.downloadrepeatfile': 'Baixar arquivo de dados duplicados',
  'isales.manual.message.release.failed': 'Falha ao liberar os dados de chamada de saída',
  'isales.taskmanagement.field.zip.input.password': 'Digite pelo menos oito caracteres e um máximo de doze caracteres que contenham dígitos\, letras e caracteres especiais\, mas não contenham /\\-#(){\'{\'}{\'}\'};&{\'|\'}<>{\'$\'}`\\\\!\\n\\s\\t\\r',
  'isales.file.title.SK.again': 'Insira a SK novamente',
  'isales.taskInfo.message.skillAlreadyExists': 'A fila de habilidades já existe',
  'isales.agent.label.maxAgentWrapupTime': 'O valor varia de 0 a 3600',
  'isales.file.title.AK': 'Chave do aplicativo',
  'isales.notification.receiver.tips': 'O destinatário deve ser único',
  'isales.agent.addblocklist.calldatatype': 'Tipo de cliente',
  'isales.taskpolicy.message.rangeAutoCallTime': 'O valor deve variar de {0} a {1}',
  'isales.manual.field.result.success': 'Bem-sucedido',
  'isales.taskinfo.message.endTimeError': 'A hora final deve ser posterior ao horário atual',
  'isales.work.message.agentOrTaskChange': 'Selecione pelo menos uma tarefa de chamada de saída ou ID de agente',
  'isales.task.promotion.selectSendGateway': 'Selecione um gateway',
  'isales.import.filter.button.readyCall': 'Filtrar dados de chamadas não iniciadas',
  'isales.taskresult.field.failsAgentDisconnection': 'O agente está desligado',
  'isales.taskresult.field.failsInvalidDevice': 'Dispositivo inválido',
  'isales.repeatfile.export.repeatCalledNo': 'repetir número discado',
  'isales.taskmanagment.title.callMonitor.tips': 'Atualizado automaticamente a cada 15 minutos',
  'isales.callquality.statistics.message.afterNow': 'Você não pode selecionar um momento anterior à hora inicial da tarefa ou posterior ao dia atual!',
  'isales.taskinfo.title.ivrFlow': 'Chatbot Inteligente',
  'isales.appointment.title.appointTime': 'Tempo de chamada reservado',
  'isales.manual.message.release.success': 'Os dados de chamada de saída são liberados com sucesso',
  'isales.taskcalllogic.message.choosePolicy': 'Selecione pelo menos uma estratégia de processamento de resultados',
  'isales.business.result.message.add.tips': 'O resultado do negócio não está definido',
  'isales.taskresult.field.failsCalledOnHook': 'O agente permanece em uma chamada',
  'isales.taskresult.field.sipAccount': 'Número do Softphone',
  'isales.data.prop.editDataPropFail': 'Falha ao modificar o atributo',
  'isales.taskinfo.field.callNo': 'Número chamador',
  'isales.notification.receiver': 'Destinatário',
  'isales.agent.button.restAgentPwd': 'Alterar senha do agente',
  'isales.work.title.totalOutgoingCalls': 'Chamadas de saída',
  'isales.management.message.SelectNumber': 'Selecione um número',
  'isales.taskresult.field.failsFsk': 'Telefone Texto (FSK)',
  'isales.blacklist.message.deleteSuccess': 'O número foi excluído com sucesso da lista de blocos',
  'isales.agent.label.answerType': 'Modo de atendimento do agente',
  'isales.management.title.adjustment.remark': 'Observações',
  'isales.taskresult.field.failsModemdetected': 'Modem detectado',
  'isales.taskresult.field.failedtopause': 'A tarefa não pode ser suspensa porque já está no estado suspenso',
  'isales.import.message.closeExtProp': 'A importação por modelo deve desativar os atributos personalizados. Clique no botão Configurar atributos ao lado de Importar para desativar os atributos personalizados',
  'isales.task.message.startFail': 'Falha ao iniciar a tarefa de chamada originada',
  'isales.tasktemplate.message.releasesuccess': 'Publicação bem-sucedida',
  'isales.taskstatistics.field.totalNumberOfUsers': 'Número total de usuários',
  'isales.tasklogic.field.default': 'Padrão',
  'isales.taskmanagement.field.outboundType': 'Tipo de chamada',
  'isales.agent.placeholder.agentdesc': 'Insira um nome de agente',
  'isales.result.outgoingCall.failCode': 'Código de Resultado',
  'isales.taskmanagement.title.startCount': 'Um máximo de 30 tarefas de chamada originada podem ser iniciadas de cada vez',
  'isales.import.filter.button.notification.isPushCom': 'Filtrar dados que estão sendo empurrados',
  'isales.task.promotion.remark': 'Causa de falha',
  'isales.taskcalllogic.busiresultpolicy.callnext': 'Ligue para o próximo número',
  'isales.agent.message.warning': 'Aviso',
  'isales.import.title.statusExecuting': 'Em execução',
  'isales.agent.message.modifySkillConfSuccess': 'A fila de habilidades foi modificada com sucesso',
  'isales.taskcalllogic.field.other': 'Outros',
  'isales.taskresultexport.title.downloadConfirm': 'OK',
  'isales.task.title.basicInfo': 'Informações básicas',
  'isales.task.title.taskSample': 'Gerenciamento de Objetos',
  'isales.task.field.callFail': 'Falha',
  'isales.taskresult.title.chooseCallFlag': 'Selecione o status',
  'isales.file.message.isDeleteFile': 'Excluir arquivo de origem',
  'isales.taskstatistics.field.calling': 'Chamando',
  'isales.calledroute.title.selectskill': 'Selecionar fila de habilidades',
  'isales.manual.title.fail.reason': 'Causa de falha',
  'isales.specialday.field.byYear': 'Repetir por ano',
  'isales.taskresult.field.failsDisconnection': 'Falha na chamada',
  'isales.management.message.calledNoTwoCategory': 'Categoria do segundo número chamado',
  'isales.taskcalllogic.field.shutdown': 'Cliente desconectado',
  'isales.task.promotion.promotionStatus': 'Status de marketing',
  'isales.specialday.title.holidayType': 'Tipo de data especial',
  'isales.taskcalllogic.busiresultpolicy.stopcall': 'Parar a chamada',
  'isales.taskmanagement.message.password.not.same': 'As duas senhas são inconsistentes',
  'isales.failfile.export.failReason': 'Causa da falha',
  'isales.tasktemplate.message.pauseFail': 'Falha na suspensão',
  'isales.task.promotion.retryTimes': 'Tempos de nova tentativa',
  'isales.taskmanagement.title.batchEditCallLogic': 'Modificando Lógica de Chamada de Saída em Lotes',
  'isales.management.message.import.fileName': 'Nome do arquivo',
  'isales.file.message.deleteruletips': 'Tem certeza de que deseja excluir a regra de mapeamento de importação?',
  'isales.appointment.message.tasknotrun': 'A tarefa de chamada de saída atual não foi iniciada. A chamada é proibida',
  'isales.timeImport.message.fail': 'Falha ao adicionar a configuração de importação programada',
  'isales.agent.message.deleteSkill': 'Excluir fila de habilidades',
  'isales.management.title.notification.import': 'Importar dados de marketing',
  'isales.specialday.field.beginDate': 'Data Inicial',
  'isales.task.message.timeIncompletes': 'O segmento de tempo de uma única chamada de saída não está completo',
  'isales.taskresult.field.failsDtmf': 'Telefone Texto (DTMF)',
  'isales.taskresultexport.message.endtime': 'Hora de término',
  'isales.taskcalllogic.message.resultTips': 'Só pode haver uma estratégia para um resultado',
  'isales.tasklogic.field.callbyorder': 'Em sequência',
  'isales.taskresult.field.failsUserhangsupinthequeue': 'Todos os agentes estão ocupados\, e o usuário desliga proativamente',
  'isales.taskmanagement.message.afterNow': 'A hora inicial não pode ser posterior à hora atual',
  'isales.data.prop.message.confirmDelete': 'Tem certeza de que deseja excluir o atributo selecionado?',
  'isales.taskpolicy.field.multiSkill': 'Fila de habilidades híbridas',
  'isales.taskresult.field.failsWaitresulttimesout': 'Aguardando os resultados da chamada de saída time out na plataforma',
  'isales.taskmanagement.title.edit': 'Editar',
  'isales.specialday.title.search': 'Consulta',
  'isales.taskresult.field.failsSubscriberbusy': 'A parte chamada está ocupada',
  'isales.taskcalllogic.field.callFailed': 'Falha na chamada',
  'isales.task.promotion.view': 'Exibir tarefa de marketing multimídia',
  'isales.management.message.orderReverse': 'Ordem decrescente',
  'isales.specialday.placeholder.searchname': 'Insira um nome para a data especial',
  'isales.taskresult.field.yes': 'Sim',
  'isales.outboundtime.title.period': 'Segmento de Tempo de Chamada Saída',
  'isales.taskmanagment.title.callMonitor': 'Monitoramento de chamadas',
  'isales.blacklist.message.requestEmpty': 'Falha ao validar o parâmetro para adicionar o número à lista de blocos',
  'isales.taskmanagment.callquality.maxConnectedRate': 'Taxa máxima de conexão',
  'isales.task.message.pauseFail': 'Falha ao suspender a tarefa de chamada originada',
  'isales.taskresultexport.title.download': 'Baixar',
  'isales.task.field.callFlagExecuted': 'A ser executado',
  'isales.data.prop.createDataPropSuccess': 'O atributo foi criado com sucesso',
  'isales.file.title.rule.number': 'Coluna numerada',
  'isales.taskresult.field.failsAcdtimeout': 'O tempo limite do ACD',
  'isales.taskinfo.title.skillName': 'Fila de habilidades',
  'isales.specialday.message.deleteSuccess': 'A data especial foi excluída com sucesso',
  'isales.manualdata.title.release': 'Liberação',
  'isales.taskstatus.field.waiting': 'Aguardando',
  'isales.task.message.saveFail': 'Falha ao configurar a tarefa de chamada originada',
  'isales.exportdata.title.fail': 'Falha',
  'isales.tasklogic.field.enablebusiresultpolicy': 'Ativar a política de resultados comerciais',
  'isales.taskresult.field.failsNoresponse': 'A parte chamada não responde',
  'isales.statistics.field.addHisYes': 'Sim',
  'isales.file.title.editrule': 'Modificar regra',
  'isales.failfile.export.failline': 'Linha com falha',
  'isales.taskinfo.message.agentsEmpty': 'Nenhum agente foi selecionado',
  'isales.blacklist.title.remark': 'Observações',
  'isales.manual.message.error': 'Ocorreu um erro ao atualizar os dados',
  'isales.tasklogic.title.addCallnoPolicy': 'Adicionar política de resultados',
  'isales.management.message.deleteFailed': 'Falha ao excluir os dados da chamada originada',
  'isales.manual.title.viewPreviewResult': 'Visualizar resultado da chamada de saída-',
  'isales.basicinfo.title.chooseCallNo': 'Selecionar número originário',
  'isales.common.title.cancel': 'Cancelar',
  'isales.speclistmanage.title.reason': 'Motivo',
  'isales.calledroute.title.selectagent': 'Selecionar Agente',
  'isales.agent.label.description': 'Descrição',
  'isales.business.description': 'Descrição',
  'isales.management.title.editPolicy': 'Modificar política',
  'isales.tasklogic.message.busiresultpolicyNoadded': 'Nenhuma política de resultado de negócios foi adicionada',
  'isales.notification.configVariableTe': 'Configurar Variável de Modelo',
  'isales.notification.config': 'Configuração',
  'isales.taskmanagement.title.addManual': 'Criar chamada originada de Agente',
  'isales.taskresult.field.failsUnallocatedOrIncorrectNumber': 'O número está incorreto ou inacessível',
  'isales.manual.field.callflag.finish': 'Concluído',
  'isales.taskmanagement.title.batchEditTaskInfo': 'Modificar informações básicas em lote',
  'isales.taskcalllogic.field.callTimeout': 'A chamada atingiu o tempo limite',
  'isales.data.title.importData': 'Importação',
  'isales.tasklogic.field.busiresultpolicy': 'Política de resultados comerciais',
  'isales.management.message.deleteCount': 'Um máximo de 100 registros de chamadas originadas podem ser excluídos de cada vez',
  'isales.callNo.field.add': 'Adicionar',
  'isales.skillcreate.maxnumber.failed': 'O número de filas de habilidades de tenant não pode exceder o limite superior',
  'isales.busi.result.item.uncertain': 'Incerto',
  'isales.business.result.deleteChildrenTips': 'Tem certeza de que deseja excluir o resultado de subnegócios?',
  'isales.task.title.nextStep': 'Próximo',
  'isales.task.promotion.sender': 'Remetente',
  'isales.agent.label.parameterType': 'Modo de enfileiramento',
  'isales.blacklist.message.name.reg.empty': 'A regra regular do nome está vazia. Entre em contato com o administrador para adicioná-lo',
  'isales.taskmanagement.field.taskTime': 'Duração',
  'isales.file.title.password.again': 'Confirmar senha',
  'isales.file.title.rightpath': 'Digite um caminho válido, como /isales/upload',
  'isales.taskresult.field.userisunleashed': 'A chamada no servidor de mídia no qual o agente faz login é liberada',
  'isales.agent.addblocklist.numbertype': 'Tipo de número',
  'isales.file.server.message.notChinese': 'Caracteres chineses não são permitidos',
  'isales.taskresult.field.failsNoreason': 'Transferência DTE incondicional',
  'isales.management.title.selectProp': 'Selecionar atributo',
  'isales.manualData.message.pickUpFailed': 'Sem dados para extrair',
  'isales.specialday.title.speclistmanage.misjudgment': 'Julgamento incorreto',
  'isales.taskcalllogic.message.callNoPolicyOutOfRangeAfetUpdate': 'Após a alteração do número de políticas de número não padrão na política de resultados de chamadas efetuadas\, o máximo de cinco é excedido',
  'isales.taskresult.field.failsUnreachable': 'Assinante móvel inacessível',
  'isales.taskmanagement.title.callRecord': 'Registro de chamada originada',
  'isales.outboundtime.field.hour': 'Hora',
  'isales.tasktype.field.automatic': 'chamada originada Automática',
  'isales.taskinfo.message.batchEditCallLogic.BusiResultNotification': 'Novas políticas irão substituir as existentes',
  'isales.taskpolicy.field.failInterval': 'Intervalo de chamada de saída',
  'isales.taskcalllogic.field.globalPolicy': 'Política global',
  'isales.taskmanagement.title.tipsBatchStartTask': 'Selecione a tarefa a ser iniciada',
  'isales.specialday.title.edit': 'Editar',
  'isales.taskresultexport.message.downloadFail': 'Falha no download',
  'isales.file.message.isDeleteFileTips': 'Exclua arquivos do OBS',
  'isales.agent.label.agentList': 'ID do agente',
  'isales.taskcalllogic.busiresultpolicy.callcurrent': 'Ligue para o número atual',
  'isales.task.promotion.createTime': 'Criado em',
  'isales.result.create': 'Criar um motivo de falha na chamada efetuada',
  'isales.appointment.message.adjustmentSuccess': 'A chamada de saída reservada foi ajustada com sucesso',
  'isales.management.message.deleteSuccess': 'Os dados de chamada de saída são excluídos com sucesso',
  'isales.management.message.calledNo': 'Número chamado',
  'isales.management.message.sessionId': 'Número da sessão',
  'isales.agent.query.emptyError': 'Nenhum dado de agente válido foi selecionado',
  'isales.management.title.editTime': 'Horário alterado',
  'isales.skill.label.maxwaitnum': 'Máx. Chamadas na Fila',
  'isales.callNo.field.delayTime': 'Intervalo (s) de nova tentativa de falha de chamada',
  'isales.common.title.fail': 'Falha',
  'isales.taskresult.field.failsCallwaiting': 'Chamada em espera',
  'isales.blacklist.placeholder.searchname': 'Nome',
  'isales.tasktemplate.title.saveAsTemplate': 'Salvar como modelo',
  'isales.tasktemplate.title.addAutoTemplate': 'Criar modelo de chamada automática efetuada',
  'isales.result.deletetips': 'Tem certeza de que deseja excluir o motivo da falha na chamada efetuada?',
  'isales.taskmanagement.title.selectTemplate': 'Selecionar modelo',
  'isales.blacklist.message.calledno.error': 'O número contém caracteres inválidos',
  'isales.management.message.upload': 'Carregar',
  'isales.taskmanagement.field.prediction': 'chamada originada Prevista',
  'isales.operlog.message.downloadAgentWorkExportFile': 'Faça o download do arquivo de exportação da carga de trabalho do agente',
  'isales.operlog.message.downloadSurveyExportFile': 'Exportar perguntas de treinamento',
  'isales.taskresultexport.message.downloadFilesuffixfailinfo': 'Falha no download. As informações do arquivo exportado não contêm a extensão do nome do arquivo',
  'isales.data.prop.propCode': 'Código do atributo',
  'isales.skillqueue.button.add': 'Adicionar uma fila de habilidades',
  'isales.specialday.message.typeError': 'Tipo de data especial incorreto',
  'isales.data.prop.stringType.countError': 'Um máximo de 30 atributos de tipo string de caracteres pode ser definido',
  'isales.taskresult.title.endTime': 'Hora de término',
  'isales.specialday.message.updateFail': 'Falha ao modificar a data especial',
  'isales.task.promotion.templateType': 'Tipo de modelo',
  'isales.manual.message.call.error': 'Ocorreu um erro ao processar o resultado da chamada originada',
  'isales.taskmanagment.title.open.callMonitor': 'Monitoramento de chamadas',
  'isales.taskresult.field.failsCallednumbernotinwhitelist': 'O número não está na lista de confiança de chamadas de saída',
  'isales.specialday.title.reset': 'Redefinir',
  'isales.specialday.message.dateParseError': 'Erro na conversão do tipo de hora da data especial',
  'isales.management.message.calledNoEmpty': 'O número chamado está vazio',
  'isales.common.title.close': 'Fechar',
  'isales.import.title.statusReady': 'A ser executado',
  'isales.taskresult.field.failsNoacdresource': 'Nenhum recurso disponível no ACD',
  'isales.management.message.createFailed': 'Falha ao criar dados de chamada originada',
  'isales.busi.result.message.recordsucc': 'O resultado do negócio foi registrado com sucesso',
  'isales.file.title.create': 'Adicionar',
  'isales.agent.message.modifyPwdSuccess': 'A senha do agente foi alterada com sucesso',
  'isales.taskpolicy.field.pickupRateMethod': 'Algoritmo para Taxa de indisponibilidade',
  'isales.import.message.uploadedfileNameformatvalidate': 'Nome do arquivo incorreto. Selecione um arquivo cujo nome contém apenas caracteres chineses\, letras minúsculas\, dígitos ou sublinhados (_)',
  'isales.taskresult.title.busidesc': 'Descrição comercial',
  'isales.taskpolicy.field.obOverTime': 'Duração  máxima da resposta',
  'isales.tasktype.field.intelligent': 'Chamada de saída de robô inteligente',
  'isales.management.message.import.analyseMode': 'Modo de análise',
  'isales.agent.calledroute.gateway.fail': 'Falha ao sincronizar dados com a plataforma CTI. Verifique a configuração do gateway',
  'isales.management.message.ruleAnalyse': 'Pode usar BE como autenticação de dados',
  'isales.taskmanagement.field.statisticsDate': 'Data de coleta de estatísticas',
  'isales.file.message.rule.dataIsNull': 'Os dados de número não podem estar vazios',
  'isales.appointment.message.agentNotCalling': 'Uma chamada originada reservada não pode ser adicionada porque o agente não está em uma chamada',
  'isales.taskmanagement.title.tipsBatchPauseTask': 'Selecione a tarefa a ser suspensa',
  'isales.manual.message.empty': '{0} está vazio',
  'isales.specialday.message.dateFormatError': 'Formato de data especial incorreto',
  'isales.file.message.endPoint.placeholder': 'Insira um endereço',
  'isales.appointment.title.failCode': 'Causa de falha',
  'isales.taskresult.field.failsCalldatatimeout': 'Os dados da chamada atingiram o tempo limite',
  'isales.taskpolicy.field.utilRateMethod': 'Algoritmo para uso do Agente',
  'isales.taskpolicy.field.experienceMethod': 'Algoritmo para previsão empírica',
  'isales.blacklist.title.calledNo': 'Número chamado',
  'isales.business.result.addchildrentips': 'Um máximo de 50 resultados de subnegócios são permitidos em um resultado comercial',
  'isales.specialday.title.timeSet': 'Definir hora de chamada de saída de data especial',
  'isales.sendStatus.field.all': 'Todos',
  'isales.taskstatus.field.callfinish': 'Concluído',
  'isales.taskmanagment.title.viewstatistics': 'Visualizar estatísticas',
  'isales.appointment.message.callTimeTooEarly': 'O tempo de chamada é anterior ao tempo reservado. A chamada é proibida!',
  'isales.agent.message.deleteSkillConfSuccess': 'A fila de habilidades foi excluída com sucesso',
  'isales.agent.label.answerMode': 'Modo de atendimento do agente',
  'isales.import.message.pleaseuploadfile': 'Selecione um arquivo',
  'isales.tasklogic.message.busiresultpolicyExists': 'Já existe uma política de resultado de negócios com o mesmo resultado de negócios e sub-estado',
  'isales.management.message.filterYes': 'Sim',
  'isales.taskresult.field.agentRole': 'Nome da Função do Agente',
  'isales.blacklist.message.name.error': 'O nome contém caracteres inválidos',
  'isales.taskmanagement.title.editTime': 'Horário alterado',
  'isales.taskresult.field.other': 'Outros',
  'isales.taskresult.title.export': 'Exportar',
  'isales.import.filter.button.notification.noisPush': 'Filtrar dados não empurrados',
  'isales.task.promotion.message.dndTimeError': 'O período de não perturbar está incompleto',
  'isales.manualdata.title.lock': 'Bloqueio',
  'isales.business.code': 'Código',
  'isales.workbench.appointment.task': 'Tarefa de reserva',
  'isales.taskinfo.message.batchEditCallLogic.differentRoundTypeExist': 'As lógicas de chamada de saída não podem ser modificadas em lotes para as tarefas com diferentes modos de rodada de chamada',
  'isales.manualdata.title.pickUp': 'Extrair',
  'isales.management.title.updateData': 'Modificar Dados de chamada de saída',
  'isales.taskresult.field.operation': 'Operação',
  'isales.import.title.statusComplete': 'Concluído',
  'isales.result.edit': 'Modificar causa de falha de chamada de saída',
  'isales.taskmanagement.field.zip.password': 'Senha do pacote compactado',
  'isales.tasktemplate.title.viewTemplate': 'Exibir Modelo de Chamada de Saída',
  'isales.statistics.field.callCompletionRateDescription': 'Taxa de conexão',
  'isales.specialday.title.warning': 'Aviso',
  'isales.taskcalllogic.message.roundPolicyLengthTooLong': 'Após a alteração do número de rodadas na política de chamadas\, a duração máxima é excedida',
  'isales.appointment.message.updateFail': 'Falha ao modificar a chamada originada reservada',
  'isales.specialday.title.chooseDate': 'Selecionar data',
  'isales.taskmanagement.title.pause': 'Suspensar',
  'isales.blacklist.title.createblacklist': 'Adicionar número à lista de bloquio',
  'isales.taskresult.field.failsFak': 'A parte chamada é um fax',
  'isales.import.filter.button.notification.isPush': 'Filtrar dados empurrados ultimamente',
  'isales.notification.configVariable': 'Definir variável',
  'isales.taskresult.field.failsRecordswrittenback': 'Os registros falhados são gravados de volta quando o OBS sai (ou uma tarefa é excluída)',
  'isales.tasktype.field.preview': 'Visualizar chamada originada',
  'isales.taskcalllogic.field.customerRefused': 'Cliente rejeitado',
  'isales.import.message.success': 'Importando dados. Clique em Atualizar mais tarde para obter os dados mais recentes.',
  'isales.management.placeholder.commonIvrName': 'Insira o nome do processo IVR comum',
  'isales.taskinfo.field.priority': 'Prioridade',
  'isales.agent.message.addSkillFail': 'Falha ao adicionar a fila de habilidades',
  'isales.exportdata.title.success': 'Bem-sucedido',
  'isales.tasktemplate.field.released': 'Publicado',
  'isales.taskmanagement.field.pickUpCondition': 'Condições',
  'isales.notification.receiver.number.tips': 'O valor deve consistir em dígitos e começar com o sinal de mais (+)',
  'isales.blacklist.message.calledno.empty': 'Nenhum número foi inserido',
  'isales.data.prop.inputPropName': 'Insira um nome de atributo',
  'isales.agent.label.maxWaitTime': 'Tempo máximo de enfileiramento',
  'isales.business.result.message.specialStr': 'Não suportado',
  'isales.management.message.calledNoOneInterval': 'Intervalo do primeiro número chamado',
  'isales.file.title.filepath.placeholder': 'Insira um caminho de arquivo\, por exemplo\, /home',
  'isales.management.message.importOrder': 'Importar sequência',
  'isales.tasktemplate.message.deleteConfirm': 'Tem certeza de que deseja excluir os dados da chamada efetuada selecionada?',
  'isales.taskresult.field.failsAutomaticansweringmachine': 'A parte chamada é um atendedor automático',
  'isales.result.obResultIsNull': 'O resultado da chamada de saída da causa de falha não pode estar vazio',
  'isales.taskresult.field.failsTasknotexist': 'Nenhuma tarefa de chamada originada disponível',
  'isales.taskresult.field.failsTransfernoreply': 'A chamada está sendo encaminhada sem resposta',
  'isales.blacklist.message.createFail': 'Falha ao adicionar o número à lista de blocos',
  'isales.workbench.number.execution': 'Executando',
  'isales.taskInfo.message.saveSuccess': 'As informações foram modificadas com sucesso',
  'isales.tasklogic.field.addDataType': 'Adicionar tipo de dados',
  'isales.agent.message.createSkillConfSuccess': 'A fila de habilidades foi criada com sucesso',
  'isales.outboundtime.message.empty': 'Nenhum tempo de chamada de saída está definido',
  'isales.tasklogic.message.busiresultpolicySame': 'Os resultados de negócios e sub-estados de várias políticas de resultado são os mesmos',
  'isales.management.message.uploadSelect': 'Selecione um arquivo',
  'isales.task.message.numberSuccessful': 'Sucesso nums',
  'isales.file.title.rule.type': 'Tipo Coluna',
  'isales.common.title.confirm': 'OK',
  'isales.appointment.message.createSuccess': 'A chamada originada reservada foi criada com sucesso',
  'isales.taskmanagement.title.createDirectly': 'Criar diretamente',
  'isales.taskresult.field.failsPhonenumberisnull': 'O número de saída está vazio',
  'isales.taskmanagement.field.usersaying': 'Solicitar texto',
  'isales.task.promotion.nameRepeat': 'O nome da tarefa de marketing multimídia já existe',
  'isales.agent.label.transferType': 'Modo de manipulação',
  'isales.task.promotion.sendTime': 'Enviado em',
  'isales.manual.message.release.DataNotWaitExists': 'Existem dados de chamada de saída que estão sendo chamadas ou concluídas',
  'isales.taskpolicy.field.pickupRate': 'Taxa de deslocamento',
  'isales.business.result.message.checkCode': 'Somente dígitos\, letras e hífenes (-) são permitidos',
  'isales.task.promotion.sms': 'SMS',
  'isales.skill.label.skillname': 'Nome da fila de habilidades',
  'isales.taskresult.field.failsInsufficientresources': 'Recursos insuficientes',
  'isales.appointmanagement.title.inputRemark': 'Insira observações',
  'isales.result.search.displayResult': 'inserir a causa de falha da chamada originada',
  'isales.appointment.title.timeout': 'O horário de reserva expirou',
  'isales.tasklogic.field.exist.numbertype': 'Tipo de número existente',
  'isales.import.filter.message.selectRuleTips': 'Selecione pelo menos uma regra de filtro',
  'isales.manual.title.recipient': 'Destinatário',
  'isales.tasklogic.field.or': 'Ou',
  'isales.blacklist.title.operateAgent': 'Agente adicionado',
  'isales.notification.receiver.info': 'Informações do destinatário',
  'isales.taskstatistics.field.callResultStatistics': 'Estatística do resultado da chamada',
  'isales.taskresult.field.failsNumberreacheslimit': 'O número de chamadas OBS atinge o limite superior',
  'isales.specialday.title.holiday': 'Data especial',
  'isales.manutask.title.viewdata': 'Dados de chamada de saída',
  'isales.blacklist.message.remark.error': 'As observações contêm caracteres inválidos',
  'isales.management.message.importType': 'Método de importação',
  'isales.taskcalllogic.title.resultSetPolicy': 'Configurar política com base nos resultados',
  'isales.taskInfo.message.saveFail': 'Falha ao modificar as informações',
  'isales.taskresult.title.calledNo': 'Número de saída',
  'isales.agent.label.transfer': 'Transferência',
  'isales.tasklogic.field.addturn': 'Adicionar rodada',
  'isales.taskstatus.field.suspend': 'Suspenso',
  'isales.task.promotion.message.limitGtTenantLimit': 'O limite de envio diário excede o máximo permitido para o Tenant',
  'isales.manual.title.inputfailcode': 'Insira uma causa de falha',
  'isales.appointment.message.adjustmentFail': 'Falha ao ajustar a chamada de saída reservada',
  'isales.data.message.confirmSubmit': 'A sequência de números atual é a sequência de chamadas. Verifique os dados novamente',
  'isales.tasklogic.field.connectsameagent': 'Conecte-se ao mesmo agente',
  'isales.taskinfo.message.beginTimeError': 'A hora inicial não pode ser posterior à hora final',
  'isales.taskresult.field.workNo': 'Número de trabalho',
  'isales.manualData.message.reachMax': 'Não pode ser extraído mais dados de chamada de saída porque o número máximo de chamadas foi atingido na tarefa',
  'isales.agent.title.agentEdit': 'Modificar informações do agente',
  'isales.tasktemplate.title.addTemplate': 'Criar modelo de chamada efetuada',
  'isales.task.field.callSuccess': 'Bem-sucedido',
  'isales.taskresult.field.failsPoweredoff': 'O telefone está desligado',
  'isales.agent.message.idIsEmpty': 'A ID da fila de habilidades não pode estar vazia',
  'isales.taskresult.field.failsCalledmodem': 'A parte chamada é um modem',
  'isales.import.message.taskstatuswrong': 'Os dados não podem ser importados para a tarefa de chamada originada porque não estão no estado de rascunho ou suspenso',
  'isales.file.title.filepath': 'Caminho padrão',
  'isales.taskcalllogic.message.alreadyLastPolicy': 'Já é a última política',
  'isales.appointment.title.remark': 'Observações',
  'isales.work.title.successNum': 'Chamadas de saída bem-sucedidas',
  'isales.taskresult.field.failsCallednumberincorrect': 'Número chamado incorreto na visualização',
  'isales.appointment.title.add': 'Adicionar',
  'isales.taskcalllogic.field.customerMissed': 'Cliente perdido',
  'isales.notification.propo.name': 'Nome do atributo',
  'isales.tasklogic.field.lt': 'Menos do que',
  'isales.agent.message.selectSkills': 'Selecione uma fila de habilidades',
  'isales.specialday.title.choose': 'Selecionar data especial',
  'isales.result.outgoingCall.Description': 'Descrição',
  'isales.data.orderType.desc': 'Ordem descendente',
  'isales.taskcalllogic.title.resultPolicySet': 'Estratégia de processamento de resultados',
  'isales.specialday.field.Tuesday': 'Terça',
  'isales.tasklogic.field.result': 'Resultado',
  'isales.taskpolicy.message.input.digits': 'Insira um número inteiro',
  'isales.taskinfo.message.batchEditCallLogic.manualExist': 'As lógicas de chamada de saída manuais não podem ser modificadas',
  'isales.manual.message.data.query.error': 'Ocorreu um erro ao consultar os dados da chamada originada',
  'isales.specreason.field.all': 'escolha',
  'isales.taskpolicy.field.notSupportMultiSkill': 'Agentes híbridos para chamadas de entrada e saída não são suportados',
  'isales.task.promotion.message.variableNotConfig': 'Algumas variáveis não estão configuradas',
  'isales.management.message.fileImport': 'Usando arquivo',
  'isales.management.placeholder.ivrName': 'Insira o nome do processo do robô inteligente',
  'isales.taskmanagement.message.queryDateError': 'Data incorreta. Selecione outra',
  'isales.tasklogic.field.moveUp': 'Mover para cima',
  'isales.task.field.callFlagCompletion': 'Concluído',
  'isales.tasklogic.field.numbertype': 'Tipo de número',
  'isales.taskresult.title.callFlag': 'Status',
  'isales.taskstatus.field.all': 'Todos',
  'isales.taskmanagement.title.batchEditCallPolicy.sameTaskType': 'Políticas de chamada de saída podem ser modificadas em lotes somente para tarefas do mesmo tipo',
  'isales.data.prop.status.unpublished': 'Não publicado',
  'isales.taskmanagement.field.isAutoPickUp': 'Ativar regra de extração automática',
  'isales.taskmanagement.message.deleteConfirm': 'Tem certeza de que deseja excluir?',
  'isales.management.message.batchEditCallLogic.statusError': 'As lógicas de chamada de saída podem ser modificadas em lotes apenas para tarefas no estado rascunho\, execução ou suspenso',
  'isales.task.promotion.message.selectTemplateType': 'Selecione um tipo de modelo',
  'isales.busi.result.message.nodatarecord': 'Nenhuma informação de chamada foi encontrada. Selecione o registro de chamada de saída cujo resultado comercial precisa ser definido',
  'isales.taskresult.field.failsMusicdetected': 'Música é detectada',
  'isales.task.promotion.beginTime': 'Hora de Início',
  'isales.tasktemplate.message.saveSuccess': 'O modelo de chamada de saída foi configurado com sucesso',
  'isales.timeImport.message.success': 'A configuração de importação programada é adicionada com sucesso',
  'isales.taskmanagement.title.chooseEndtime': 'Hora de término',
  'isales.task.promotion.templateCode': 'Código do modelo',
  'isales.manual.field.previewinformation': 'Visualizar informações',
  'isales.tasklogic.field.retryNumber': 'Tempos de nova tentativa',
  'isales.data.propConfig.title.prop': 'Atributo',
  'isales.operlog.message.data.downloadexportfile': 'Baixe o arquivo de resultado da exportação para a tarefa {0}',
  'isales.taskmanagement.title.newFromTemplate': 'Criar usando modelo',
  'isales.taskstatistics.field.callstatistics': 'Estatísticas de chamadas',
  'isales.agent.contact.multimedia': 'Multimídia',
  'isales.taskresult.title.beginTime': 'Hora de início',
  'isales.appointment.message.timeout': '{0} chamadas originadas agendadas passaram do tempo de agendamento. Faça chamadas a tempo',
  'isales.specialday.message.deleteTips': 'A data especial não pode ser excluída porque está associada a uma tarefa de chamada originada',
  'isales.data.title.timeImport': 'Importação programada',
  'isales.management.title.deleteInfo': 'Selecione os dados da chamada originada',
  'isales.taskstatistics.field.inProgress': 'Número de clientes que chamam',
  'isales.tasklogic.field.callpolicy': 'Política de chamadas',
  'isales.appointment.title.callTime': 'Segmento do horário de chamada',
  'isales.manual.button.send': 'Enviar',
  'isales.agent.message.batchSaveSuccess': 'Os agentes são modificados em lotes com sucesso',
  'isales.taskmanagement.title.appoint': 'Reservar chamada de saída',
  'isales.task.message.statistics': 'Estatísticas',
  'isales.skill.label.maxwaittime': 'Tempo máximo de enfileiramento',
  'isales.appointment.message.confirmDelete': 'Tem certeza de que deseja excluir as chamadas originadas reservadas?',
  'isales.manual.title.title': 'Título',
  'isales.taskresult.title.inputCallerNo': 'Insira um número de saída',
  'isales.taskmanagement.title.speclistmanage.reason': 'a razão',
  'isales.taskmanagement.title.editPolicy': 'Editar política',
  'isales.management.message.remarkLengthError': 'Um máximo de 1000 caracteres são permitidos',
  'isales.appointment.placeholder.remark': 'Digite comentários',
  'isales.taskresult.field.failsSwitchingdevicebusy': 'O interruptor está ocupado',
  'isales.task.promotion.message.selectPromotionType': 'Selecione um tipo de mídia',
  'isales.management.message.deletedataIdsError': 'Indicador incorreto dos dados de chamada de saída a serem excluídos',
  'isales.manual.message.query.agentstatus.error': 'Ocorreu um erro ao consultar o status do agente',
  'isales.taskstatistics.placeholder.notcalled': 'Pendente: {0}',
  'isales.taskresult.title.busiresultLog': 'Registro de resultados',
  'isales.taskmanagement.field.manual': 'chamada originada de Agente',
  'isales.file.title.rule.property': 'Atributo de serviço',
  'isales.tasklogic.field.gt': 'Maior que',
  'isales.agent.label.adjustTime': 'Duração (s) de encerramento',
  'isales.taskmanagement.title.editCountOutOfBounds': 'Um máximo de 30 tarefas de chamada originada podem ser modificadas de cada vez',
  'isales.taskresult.field.failsNoValidVDN': 'Nenhum VDN válido disponível',
  'isales.agent.message.updateCtiAndDbSuccess': 'A fila de habilidades é atualizada com sucesso',
  'isales.import.title.result': 'Exibir Resultado de Importação',
  'isales.callpolicy.title.add': 'Adicionar',
  'isales.manualData.message.reachQuatos': 'Não pode ser extraído mais dados de chamada de saída porque o número de chamadas na tarefa atingiu o limite superior de extração',
  'isales.taskresult.title.operateEndTime': 'Hora de fim',
  'isales.agent.label.timeOut': 'Tempo limite de enfileiramento',
  'isales.management.message.download': 'Baixar modelo de dados de chamadas originadas',
  'isales.task.message.specialdayEmpty': 'Nenhuma data especial está selecionada',
  'isales.blacklist.placeholder.searchremark': 'Observações',
  'isales.taskresult.title.chooseUserAccount': 'Selecione iniciado por',
  'isales.taskresult.title.resultDetail': 'Detalhes do resultado da chamada',
  'isales.taskresult.field.failsQueuingtimesout': 'O timeout da fila e a plataforma libera a chamada',
  'isales.skillqueue.agentrole.agent': 'Agente',
  'isales.task.promotion.message.dndEndTimeltBeginTime': 'O fim do período de não perturbar é anterior ao início',
  'isales.tasktemplate.title.addManualTemplate': 'Criar modelo de chamada manual efetuada',
  'isales.taskresult.field.failsFailedRouteAgent': 'Falha ao rotear a chamada para o agente',
  'isales.taskpolicy.field.predictMethod': 'Algoritmo para chamadas originadas previstas',
  'isales.manual.title.viewManualData': 'Dados da chamada originada do Agente -',
  'isales.manual.message.agentstatus.isnofree': 'O agente atual não está em estado disponível',
  'isales.statistics.field.addHis': 'Coleta de dados arquivados',
  'isales.outboundtime.field.minute': 'Minuto',
  'isales.agent.label.skillChoice': 'Fila de habilidades',
  'isales.manual.message.lockData': 'Selecione os dados de chamada de saída a serem bloqueados',
  'isales.specialday.message.updateSuccess': 'A data especial foi modificada com sucesso',
  'isales.specialday.title.confirm': 'Reconhecer',
  'isales.task.message.saveSuccess': 'A tarefa de chamada originada foi configurada com sucesso',
  'isales.sendStatus.field.prepare': 'Preparando',
  'isales.taskpolicy.field.utilRate': 'Uso do Agente',
  'isales.manual.title.inputstatus': 'Informe o status de uma chamada de saída',
  'isales.task.message.play': 'Reproduzir',
  'isales.task.promotion.deleteFailed': 'Falha ao excluir a tarefa de marketing multimídia',
  'isales.task.promotion.email': 'Email',
  'isales.taskmanagement.message.queryLimit': 'O número de registros em cada página deve ser um inteiro positivo menor que 101',
  'isales.taskresult.title.exportResult': 'Exibir Resultado de Exportação',
  'isales.taskresult.field.failsFaxdetected': 'Fax detectado',
  'isales.taskmanagement.title.viewAppointList': 'Reserva',
  'isales.taskmanagement.title.viewAppointListDetail': 'Detalhes da Reserva',
  'isales.manual.title.status': 'Status',
  'isales.taskresult.field.failsRingingtimesout': 'O tempo limite do toque e a plataforma libera a chamada',
  'isales.taskmanagment.callquality.minConnectedRate': 'Taxa de conexão mínima',
  'isales.taskpolicy.field.connRate': 'Taxa de conexão de chamada de saída',
  'isales.agent.message.willDeleteSkills': 'Esta operação pode fazer com que a chamada de tarefa de saída associada à fila de habilidade falhe. Por favor\, opere com cautela. Precisa excluí-lo?',
  'isales.task.promotion.promotionEffTime': 'Tempo Efetivo',
  'isales.taskpolicy.field.calculationFactor': 'Fator de cálculo',
  'isales.appointment.message.callNotAllowed': 'Não é possível fazer uma chamada originada reservada porque o agente não está disponível nem ocupado',
  'isales.management.message.import': 'Importar dados anexados',
  'isales.taskinfo.message.require': 'Defina todos os campos obrigatórios',
  'isales.tasklogic.field.addOrderPolicy': 'Adicionar atributos',
  'isales.taskmanagement.title.outbound': 'Chatbot de chamada originada',
  'isales.management.title.details': 'Detalhes',
  'isales.data.propConfig.message.isUseProp': 'Ativar atributo personalizado',
  'isales.file.title.rule.name.placeHolder': 'Insira um nome para a regra',
  'isales.file.title.server.info': 'As informações do servidor foram modificadas com sucesso',
  'isales.task.title.calllogic': 'Lógica de chamada de saída',
  'isales.tasklogic.field.callturntype.numberorder': 'Por número sequencial',
  'isales.agent.label.deviceType': 'Tipo de dispositivo',
  'isales.tasktemplate.message.pauseSuccess': 'Suspensão bem-sucedida',
  'isales.taskresult.title.chooseResult': 'Selecione o resultado da chamada',
  'isales.import.message.succnum': 'Número de registros bem-sucedidos',
  'isales.file.title.rule.validator.maxdata': 'Um máximo de 40 registros de configuração de número pode ser adicionado',
  'isales.taskmanagement.title.status': 'Status',
  'isales.taskresult.field.failsUndefinedsignaltone': 'Tom de sinal indefinido',
  'isales.tasklogic.field.addbusiresultpolicy': 'Adicionar política de resultados comerciais',
  'isales.management.title.detailData': 'Detalhes dos Dados de Chamada Saída',
  'isales.taskresult.title.userName': 'Nome do usuário',
  'isales.taskcalllogic.field.customerbusy': 'Cliente ocupado',
  'isales.import.message.selectServer.tips': 'Defina o servidor de arquivos de chamadas efetuadas',
  'isales.management.title.refresh': 'Atualizar',
  'isales.taskinfo.field.endDate': 'Hora de término',
  'isales.management.title.calledNo': 'Número chamado',
  'isales.tasklogic.field.like': 'Incluir',
  'isales.taskmanagement.title.configData': 'Dados de chamada de saída',
  'isales.exportdata.message.successinfo': 'Exportando dados... Exibir detalhes em Exportar Resultado',
  'isales.data.prop.numberType.countError': 'Podem ser adicionado no máximo 10 atributos do tipo de dígito',
  'isales.specialday.message.deletePartiallyFail': 'Algumas datas especiais não puderam ser excluídas',
  'isales.task.promotion.message.noprop': 'Adicione um atributo usando o menu Definição de Atributo de Dados de Chamada de Saída',
  'isales.taskresult.field.failsResourceOverload': 'Recursos insuficientes',
  'isales.import.message.errMsg': 'Causa de exceção',
  'isales.tasktemplate.message.releasefailed': 'Falha na publicação',
  'isales.agent.message.passLengthValidateFailed': 'A senha deve conter 8 caracteres',
  'isales.tasklogic.label.subCondition': 'Subcondição',
  'isales.manual.message.update.fail': 'Falha ao atualizar o status dos dados da chamada originada',
  'isales.agent.message.searchSkillName': 'inserir o nome da fila de habilidades',
  'isales.import.filter.button.noRepeat': 'Filtragem de dados duplicados em lote',
  'isales.taskresult.field.failsAgentHangsUp': 'O agente desliga',
  'isales.file.title.sk.same': 'Os dois SKs são diferentes',
  'isales.taskinfo.field.chooseOrInputCallNo': 'Você pode inserir ou selecionar o número de chamada ao mesmo tempo',
  'isales.management.message.calledNoLengthError': 'O comprimento do número chamado excede o limite',
  'isales.blacklist.title.name': 'Nome',
  'isales.tasklogic.field.callfilter': 'Filtragem de chamadas',
  'isales.data.filed.isResult': 'Sim',
  'isales.taskmanagement.title.editInfo': 'Alteração de informações',
  'isales.taskmanagement.title.editSample': 'Gerenciamento de Objetos',
  'isales.agent.message.passValidateFailed': 'O valor deve conter pelo menos três tipos dos seguintes caracteres: letras maiúsculas\, letras minúsculas\, dígitos e caracteres especiais (`-!{\'@\'}#{\'$\'}^&*()-_+{\'|\'}[{\'{\'}{\'}\'}]<.>/?)',
  'isales.management.message.updateSuccess': 'Os dados da chamada originada foram modificados com sucesso',
  'isales.taskpolicy.field.supportMultiSkill': 'Agentes híbridos para chamadas de entrada e saída são suportados',
  'isales.tasklogic.field.addSubCondition': 'Adicionar subcondição',
  'isales.data.prop.createDataProp': 'Criar atributo',
  'isales.taskpolicy.message.obOverTime': 'Informe primeiro a duração do tempo limite da visualização',
  'isales.task.message.download': 'Baixar gravação',
  'isales.work.title.selectTask': 'Selecionar tarefa de chamada de saída',
  'isales.appointment.title.userName': 'Iniciado por',
  'isales.taskresult.field.failsReleaseswhencall': 'Quando um canal de voz está configurado\, um agente libera uma chamada usando o controle de barra de agente',
  'isales.taskresult.title.queryArchivedData': 'Consultar dados arquivados',
  'isales.agent.message.createSkillConfFail': 'Falha ao criar a fila de habilidades',
  'isales.taskmanagement.field.pickUpRule': 'Regra de extração automática',
  'isales.tasklogic.field.callmethod': 'Modo de chamada',
  'isales.taskresult.title.failedDetail': 'Detalhes da falha na chamada',
  'isales.data.prop.propNameExists': 'O nome do atributo já existe',
  'isales.notification.configVariableValue': 'Definir valor variável',
  'isales.management.message.deletedataIdsEmpty': 'O sinalizador dos dados de chamada de saída a serem excluídos está vazio',
  'isales.outboundtime.message.crossover': 'Os segmentos de tempo de chamada de saída sobrepõem-se',
  'isales.specialday.field.byWeek': 'Por semana',
  'isales.appointment.message.queryTimeError': 'A hora de início deve ser anterior à hora de fim',
  'isales.taskresult.field.failsRingbacktone': 'Tom de retorno de toque',
  'isales.agent.field.skill': 'Nome da habilidade',
  'isales.agent.message.chooseAnAgent': 'Selecione um agente',
  'isales.task.message.numberFailed': 'Nums com falha',
  'isales.taskmanagement.field.dailyStatistics': 'Estatísticas diárias',
  'isales.import.message.template.show': 'Adicione os dados do cliente por linha e adicione vários números de chamada por coluna',
  'isales.tasklogic.title.addPolicy': 'Adicionar política',
  'isales.taskmanagement.title.chooseBegintime': 'Hora de início',
  'isales.tasklogic.field.policyType': 'Tipo de política',
  'isales.tasklogic.field.addCondition': 'Adicionar condição',
  'isales.notification.variable.value': 'Valor da variável',
  'isales.taskinfo.field.beginDate': 'Hora de início',
  'isales.outboundtime.title.beginTime': 'Hora de início',
  'isales.management.message.importMode': 'Modo de importação',
  'isales.tasklogic.message.exist.orderNo': 'A sequência de números já existe. Introduza outro',
  'isales.taskresultexport.message.type': 'Tipo de exportação',
  'isales.agent.contact.vidio': 'Vídeo',
  'isales.data.prop.editDataPropSuccess': 'O atributo foi modificado com sucesso',
  'isales.taskresult.field.failsHangsupwhencall': 'Quando um agente atende uma chamada usando o controle de barra de agente\, o telefone do agente é desligado',
  'isales.data.message.preview.addNumberUpperLimit': 'É possível adicionar no máximo {count} números para chamadas efetuadas pré-visualizadas',
  'isales.taskinfo.callno.repeat': 'Número de chamada duplicado',
  'isales.data.propConfig.button.configProp': 'Configurar atributo',
  'isales.agent.accesscode.description.limit': 'A descrição do código de acesso pode conter no máximo 50 caracteres',
  'isales.taskinfo.field.mutiCallNo': 'Insira o número de chamada',
  'isales.taskresultexport.message.begintime': 'Hora de início',
  'isales.taskresult.field.failsCallResourcesInsufficient': 'Recursos de chamada insuficientes',
  'isales.management.message.calledNoOne': 'Primeiro número chamado',
  'isales.taskresult.field.failsNoAnsweringDeviceAvailable': 'Nenhum dispositivo de atendimento disponível',
  'isales.tasktype.field.prediction': 'chamada originada Prevista',
  'isales.data.prop.publish': 'Publicar',
  'isales.import.filter.title.popup': 'Regras de filtro',
  'isales.callNo.title.no': 'Não',
  'isales.management.message.import.server': 'Servidor',
  'isales.specialday.title.create': 'Criar',
  'isales.file.title.password.same': 'As duas senhas são diferentes',
  'isales.taskinfo.title.smsTemplate': 'Modelo SMS',
  'isales.management.placeholder.skillName': 'Insira um nome para a fila de habilidades',
  'isales.taskpolicy.field.talkingRate': 'Velocidade de chamada de saída',
  'isales.manual.message.precall.success': 'Uma chamada originada está sendo iniciada',
  'isales.taskmanagement.title.inputTaskName': 'Insira um nome para a tarefa',
  'isales.agent.message.modifySkillConfFail': 'Falha ao modificar a fila de habilidades',
  'isales.data.import.task.status.executing': 'Importando',
  'isales.tasktemplate.title.inputTemplateName': 'Insira um nome de modelo',
  'isales.management.title.edit': 'Modificar',
  'isales.file.message.rule.fail': 'Falha ao modificar a regra',
  'isales.file.message.deletefiletips': 'Tem certeza de que deseja excluir o servidor do arquivo de saída?',
  'isales.taskLogic.message.saveSuccess': 'A lógica de chamada de saída foi modificada com sucesso',
  'isales.agent.contact.mediaType': 'Tipo de mídia',
  'isales.appointment.placeholder.appointTime': 'Selecione o tempo de chamada reservado',
  'isales.busi.result.item.succ': 'Sucesso',
  'isales.file.title.rule.validator.row': 'Insira um número de sequência desde a coluna A até EZ',
  'isales.taskmanagement.title.export': 'Exportar registro de chamada',
  'isales.specialday.message.weeklyError': 'Período de data especial incorreto',
  'isales.business.result.delete': 'Excluir',
  'isales.file.message.bucket.placeholder': 'Insira um bucket',
  'isales.taskmanagement.title.batchEditCallTime.manualError': 'O tempo de chamada de saída manual não pode ser alterado',
  'isales.taskresult.field.failsAgentCancelingCall': 'O agente cancela a chamada',
  'isales.callrecordexport.message.downloadFilenopemission': 'Você não tem o direito de exportar registros inteligentes de comunicação de chamadas originadas',
  'isales.data.message.duplicateExist': 'Existem números duplicados',
  'isales.agent.label.answerType2': 'ID do agente reportador de voz não',
  'isales.file.title.password': 'Senha',
  'isales.agent.label.answerType0': 'ID do agente de relatório',
  'isales.taskresult.field.failedtooccupytheagent': 'Falha ao ocupar um agente',
  'isales.agent.message.batchSaveFail': 'Falha ao modificar os agentes em lotes',
  'isales.validate.message.holidayDateComparisonError': 'A data final não pode ser anterior à data inicial',
  'isales.management.title.timeImport.execTime': 'Executado',
  'isales.taskresultexport.title.fail': 'Falha',
  'isales.taskmanagement.title.queryTimeTip': 'Informações',
  'isales.agent.label.workNo': 'ID do agente',
  'isales.common.title.view': 'Exibir',
  'isales.tasklogic.field.existingDataType': 'Selecione um tipo de dados existente',
  'isales.management.message.appendMode': 'Adicionar',
  'isales.file.title.rule.index': 'Número de sequência',
  'isales.tasklogic.field.callbyturn': 'Não pode ser usado autenticação de dados BE',
  'isales.import.message.fail': 'Falha ao importar dados',
  'isales.import.filter.button.calling': 'Filtrar dados de chamadas não concluídas',
  'isales.busi.result.message.recordfail': 'O resultado do negócio foi registrado falhou',
  'isales.taskmanagement.title.addAuto': 'Criar chamada de saída automática do sistema',
  'isales.file.title.rule.validator.taskId': 'As informações da tarefa não existem',
  'isales.blacklist.message.deletePartiallyFail': 'Falha ao excluir alguns números da lista de blocos',
  'isales.taskresult.field.failedtoresume': 'A tarefa não pode ser recuperada porque não está no estado suspenso',
  'isales.taskpolicy.field.previewAutoCallTime': 'Duração de chamada de saída visualizada ',
  'isales.taskLogic.message.saveFail': 'Falha ao modificar a lógica de chamada de saída',
  'isales.blacklist.title.modifyRemark': 'Modificar observação',
  'isales.taskstatistics.placeholder.statistics': 'Estatísticas',
  'isales.import.button.configProp': 'Configurar regras de filtro',
  'isales.management.title.createNew': 'Criar dados de chamada originada',
  'isales.taskpolicy.field.minAvgCallTime': 'Duração média da chamada Mín',
  'isales.specist.message.speclistmanage': 'Selecione o número da lista especial a ser operado',
  'isales.busi.result.message.lastappointrecord': 'Defina primeiro o resultado comercial da última chamada de saída reservada',
  'isales.file.title.password.placeholder': 'Por favor insira a senha',
  'isales.skillqueue.agentrole.administrator': 'Gerente da sala',
  'isales.manual.message.call.failed': 'Falha ao fazer a chamada originada',
  'isales.taskresult.field.failsHangsupbeforeanswering': 'A parte chamada desliga antes da chamada ser atendida',
  'isales.manual.field.handlednumber': 'Número da sessão',
  'isales.tasklogic.message.repeatProp': 'Os atributos duplicados não podem ser selecionados',
  'isales.tasklogic.field.interval': 'Intervalo (segundo)',
  'isales.taskmanagement.field.calltime': 'Iniciado em',
  'isales.agent.contact.videoclicktodial': 'Videoclick para discagem',
  'isales.taskresult.message.queryTime': 'Insira uma hora de conclusão',
  'isales.taskmanagement.title.query': 'Consulta',
  'isales.agent.label.cti': 'cti',
  'isales.import.filter.title.notification.isPushDay': 'Dias empurrados',
  'isales.data.prop.propParamsError': 'Falha ao verificar o parâmetro de atributo',
  'isales.file.title.rule.validator.prop': 'O atributo definido pelo usuário da tarefa é inconsistente com o atributo configurado na regra',
  'isales.taskmanagement.field.statisticsType': 'Tipo de estatísticas',
  'isales.specialday.message.idError': 'Indicador de data especial incorreto',
  'isales.validate.message.formatError': 'Insira uma data no formato DD/MM',
  'isales.taskresult.title.choosebusisubstatus': 'Selecione o resultado do negócio antes de selecionar o substatus',
  'isales.tasklogic.field.moveDown': 'Baixar',
  'isales.taskresult.field.failsEdt': 'Telefone Texto (EDT)',
  'isales.specialday.title.addSpecialTime': 'Adicionar data especial',
  'isales.business.result.message.must': 'Este parâmetro é obrigatório',
  'isales.taskinfo.message.autoCallTimeValueError': 'A duração da chamada de saída visualizada deve ser menor que a duração do tempo limite da visualização',
  'isales.business.result.deletetips': 'Tem certeza de que deseja excluir o resultado de negócios?',
  'isales.manual.field.result.fail': 'Falha',
  'isales.taskstatus.field.draft': 'Rascunho',
  'isales.data.prop.editDataProp': 'Modificar atributo',
  'isales.blacklist.placeholder.searchcalledno': 'Número',
  'isales.timeImport.message.rulePropsError': 'O atributo de serviço da regra de importação programada deve ser consistente com o atributo definido na tarefa atual',
  'isales.taskpolicy.message.input.maxAvgCallTime': 'A Duração Média de Chamada Máx. não pode ser menor do que o Mín. Duração média da chamada',
  'isales.taskcalllogic.field.emptyNumber': 'Número fora de serviço',
  'isales.business.result.searchname': 'Insira o nome do resultado comercial',
  'isales.calledNo.message.input': 'Apenas dígitos são permitidos',
  'isales.taskmanagement.title.begintime': 'Hora de início',
  'isales.callno.message.delayTime': 'Intervalo para tentar novamente chamadas em caso de falha de chamada: intervalo entre o número atual e o número anterior',
  'isales.manual.message.data.isalready.called': 'O cliente fez uma chamada e não pode fazer outra chamada',
  'isales.data.import.task.status.complete': 'Concluído',
  'isales.task.promotion.hwsms': 'Mensagens SMS da HUAWEI CLOUD',
  'isales.taskresult.field.failsSubscriberhangsup': 'A parte chamada desliga',
  'isales.agent.field.skillWeight': 'Habilidade Peso',
  'isales.specialday.message.deleteSpecialday': 'Selecione uma data especial',
  'isales.outboundtime.title.timePeriod': 'Segmento de Tempo de Chamada Saída',
  'isales.management.title.extendedField': 'Campo estendido',
  'isales.blacklist.title.expirationTime': 'Horário de expiração',
  'isales.skill.placeholder.skilldesc': 'inserir o nome da fila de habilidades',
  'isales.input.message.small': 'O valor de entrada é muito pequeno',
  'isales.sendStatus.field.sending': 'Enviando',
  'isales.management.message.calledNoFormatIncorrect': 'O número de chamada de saída só pode ser composto por dígitos',
  'isales.taskcalllogic.message.dataTypeDefaultNoCreate': 'Tipo de número padrão',
  'isales.task.promotion.edit': 'Modificar tarefa de marketing multimídia',
  'isales.outboundtime.title.clear': 'Limpar',
  'isales.management.title.toblocklist': 'Adicionar à lista de bloqueios',
  'isales.result.delete.error': 'Selecione uma causa de falha de chamada de saída',
  'isales.agent.title.skillCreate': 'Criar fila de habilidades',
  'isales.specialday.field.endDate': 'Data de término',
  'isales.management.message.batchEditCallLogic.notSupportBusiresult': 'As políticas de resultados de negócios não podem ser configuradas para chamadas automáticas ou inteligentes efetuadas. Tem certeza de que deseja executar a modificação do lote?',
  'isales.agent.label.useTheSkill': 'Exclua a relação de vinculação entre o agente e a fila de habilidades na página Configuração do Agente',
  'isales.taskmanagement.title.speclistmanage.calledno': 'Número de saída',
  'isales.import.filter.button.recentCall': 'Filtrar dados de chamadas concluídas recentemente',
  'isales.file.title.server.fail': 'Falha ao modificar as informações do servidor',
  'isales.taskresult.field.failsNoresource': 'Nenhum recurso disponível',
  'isales.taskresult.field.failsObsexits': 'Quando o OBS sai\, os registros em andamento não respondidos são cancelados e escritos de volta',
  'isales.management.title.warning': 'Aviso',
  'isales.task.promotion.create': 'Criar tarefa de marketing multimídia',
  'isales.data.import.task.status.error': 'Erro',
  'isales.specialday.message.nameExists': 'O nome da data especial já existe',
  'isales.taskinfo.field.skillName': 'Fila de habilidades',
  'isales.taskresult.field.failsReleasesbeforecall': 'Antes de configurar um canal de voz\, o agente tem que liberar a chamada usando o controle da barra de agentes',
  'isales.specialday.message.weeklyEmpty': 'O período de data especial está vazio',
  'isales.taskmanagement.message.queryFailed': 'Falha na consulta',
  'isales.agent.field.newpasswordconf': 'Confirmar senha',
  'isales.taskresult.field.failsInvalidstatus': 'Status DTE inválido',
  'isales.task.title.preStep': 'Anterior',
  'isales.appointmanagement.field.remark': 'Observações',
  'isales.agent.label.ccRoleName': 'Nome da função',
  'isales.blacklist.message.createSuccess': 'O número é adicionado com sucesso à lista de blocos',
  'isales.taskinfo.callno.length': 'O comprimento de um único número chamador excede 32 caracteres',
  'isales.taskmanagement.title.batchStart.finishedStatus': 'As tarefas concluídas não podem ser iniciadas',
  'isales.business.result.view': 'Exibir Resultado de Negócios',
  'isales.taskresultexport.message.downloadFilenopemission': 'Você não tem permissão para exportar arquivos',
  'isales.notification.viewResult.button': 'Ver resultado',
  'isales.taskmanagment.title.callquality.viewstatistics': 'Estatísticas de Qualidade de Chamada',
  'isales.tasklogic.field.dataType': 'Tipo de dados',
  'isales.data.prop.stringType': 'Cadeia de caracteres',
  'isales.agent.button.edit': 'Editar',
  'isales.taskresult.field.failsNoreply': 'A parte chamada não atende a chamada',
  'isales.taskresult.field.failsNumberchange': 'O número foi alterado',
  'isales.common.title.tipsInfo': 'Informações',
  'isales.tasklogic.field.retryDay': 'Máx. Dias para tentativas de chamada antes da conexão',
  'isales.task.promotion.message.variableSelected': 'Selecione um atributo',
  'isales.taskresult.title.operateBeginTime': 'Hora de início',
  'isales.notification.receiver.address': 'Endereço do destinatário',
  'isales.taskresultexport.message.downloadConfirmInfo': 'Tem certeza de que deseja baixar o arquivo?',
  'isales.taskresult.title.busisubstatus': 'Substatus comercial',
  'isales.task.promotion.promotionName': 'Tarefa de marketing',
  'isales.notification.prop.tips': 'O atributo deve ser único',
  'isales.task.message.numbersTotal': 'Total: {0}',
  'isales.taskstatistics.field.touchRate': 'taxa de toque',
  'isales.specialday.title.delete': 'Excluir',
  'isales.specialday.title.scoreDetail': 'Detalles de la puntuación',
  'isales.data.message.confirmaddblock.calldatatype': 'O tipo atual são dados de chamada. Confirme novamente',
  'isales.specialday.title.deleteError': 'Erro',
  'isales.taskpolicy.field.utilRateCalculateMethod': 'Método para calcular o uso do agente',
  'isales.agent.label.ivrChoice': 'IVR',
  'isales.taskresult.title.result': 'resultado',
  'isales.specreason.field.emptynumber': 'O número não está alocado',
  'isales.taskcalllogic.message.mostPolicys': 'Podem ser configuradas no máximo 10 políticas de resultados para um número',
  'isales.manualdata.title.updateCalledNo': 'Alterar número de saída',
  'isales.taskstatus.field.execute': 'Em execução',
  'isales.taskresult.title.busiresultupdate': 'Anexar resultado de negócios',
  'isales.taskresult.field.failsFaxormodem': 'A parte chamada é um fax ou modem',
  'isales.task.title.callTime': 'Duração da chamada originada',
  'isales.busi.result.title.result': 'Resultado',
  'isales.taskresult.field.failsUnallocatednumber': 'Número não alocado',
  'isales.taskpolicy.field.waitTime': 'Tempo de espera da Parte chamada',
  'isales.specialday.message.idEmpty': 'O sinalizador de data especial está vazio',
  'isales.specialday.message.isEmpty': 'Nenhuma hora de chamada de saída da data especial está definida',
  'isales.business.result.name': 'Nome do resultado comercial',
  'isales.taskresult.field.failsTransferbeingalerted': 'A chamada está sendo encaminhada durante o toque',
  'isales.export.message.input.error': 'A senha não está no formato correto',
  'isales.manual.message.data.checklock.inblack': 'O número de chamada de saída está na lista de bloqueios',
  'isales.taskresult.field.failsInvalidSkillQueue': 'Fila de habilidades inválida',
  'isales.file.title.port': 'Porta',
  'isales.task.message.numberCalled': 'Chamado: {0}',
  'isales.taskresult.field.failsAgentfailswithuser': 'Falha ao configurar um canal de voz entre um agente e um assinante',
  'isales.taskmanagement.title.pauseConfirm': 'Tem certeza de que deseja suspender a tarefa de chamada efetuada selecionada?',
  'isales.agent.message.configAgent': 'Configurar ou sincronizar um agente',
  'isales.taskresult.field.failsCallRouting': 'Falha ao rotear a chamada',
  'isales.notification.configVariableRel': 'Configurar relacionamento entre variável e atributo',
  'isales.failfile.export.failCalledNo': 'falha no número chamado',
  'isales.task.promotion.sendTemplate': 'Modelo de Notificação de Marketing',
  'isales.taskresult.field.failsNoagentavailable': 'Quando o OBS muda para o estado de espera\, se nenhum agente estiver disponível para saída segura ou chamadas de saída preocupadas\, os registros falhados serão cancelados',
  'isales.tasklogic.field.add.numbertype': 'Novo tipo de número',
  'isales.taskresult.field.failsDestinationunreachable': 'O destino não está alcançável',
  'isales.appointment.message.afterNow': 'O tempo reservado não pode ser anterior à hora atual',
  'isales.specialday.title.add': 'Adicionar data especial',
  'isales.taskresult.field.failsAbnormal': 'Erro',
  'isales.specreason.field.systemtone': 'Tom de prompt do sistema',
  'isales.taskresult.field.invalidCall': 'Chamada inválida',
  'isales.agent.label.skillId': 'ID da fila de habilidades',
  'isales.taskresult.field.failsPlayingvoices': 'A sinalização detecta que o switch peer está reproduzindo vozes',
  'isales.task.promotion.promotionExpTime': 'Horário de expiração',
  'isales.agent.label.ivr': 'IVR',
  'isales.management.message.import.filePath': 'Endereço do arquivo',
  'isales.appointment.message.soontimeout': '{0} chamadas originadas marcadas chegarão nos próximos 5 minutos. Prepare-se para fazer chamadas',
  'isales.taskresult.field.failsEmptyorwrong': 'O número está incorreto ou inacessível',
  'isales.specialday.message.createSuccess': 'A data especial foi criada com sucesso',
  'isales.taskinfo.message.callintervalrange': 'Insira um número inteiro que varia de 1 a 3600',
  'isales.management.title.busiresult': 'Resultado de negócios',
  'isales.import.filter.button.callResult': 'Filtrar dados de resultados de negócios',
  'isales.management.message.calledNoExists': 'O número chamado já existe',
  'isales.workbench.task.management': 'Gerenciamento de chamadas de saída',
  'isales.management.message.calledNoTwoInterval': 'Intervalo do segundo número chamado',
  'isales.taskmanagement.field.zip.makesure.password': 'Inserir novamente a senha do pacote compactado',
  'isales.tasklogic.field.retryTimes': 'Número máximo de tentativas de chamada diária',
  'isales.data.prop.quantity': 'Quantidade',
  'isales.taskmanagement.field.pickUpNum': 'Chamadas extraídas cada vez',
  'isales.taskmanagement.title.view.callrecord': 'Registro de chamadas',
  'isales.data.prop.orderType': 'Tipo de ordem',
  'isales.data.prop.anonym': 'Anonimizado',
  'isales.file.title.ip': 'Endereço IP',
  'isales.specialday.message.crossover': 'Os segmentos de tempo das chamadas de saída em datas especiais sobrepõem-se',
  'isales.import.filter.button.lineRepeat': 'Filtrar duplicatas de linha',
  'isales.taskresult.field.failsCallFailure': 'Falha na chamada',
  'isales.taskstatistics.field.completedcalls': 'Chamado',
  'isales.import.message.selectRule.tips': 'Defina a regra de mapeamento a ser importada',
  'isales.task.promotion.pauseSuccess': 'A tarefa de marketing multimídia foi suspensa com sucesso',
  'isales.taskstatistics.field.abandonRate': 'Taxa de abandono',
  'isales.taskmanagement.field.pickUpRuleBusiResult': 'Resultado de negócios',
  'isales.import.message.uploadedfiletoolarge': 'Selecione um arquivo com menos de 20 MB',
  'isales.blacklist.message.deleteBlacklist': 'Selecione o número da lista de blocos a ser excluído',
  'isales.agent.message.name.limit': 'O nome da fila de habilidades excede o comprimento máximo.',
  'isales.common.title.success': 'Bem-sucedido',
  'isales.taskresult.field.failsWithoutringing': 'A chamada está sendo encaminhada em resposta imediata antes de tocar',
  'isales.task.promotion.prefix': 'Código do país',
  'isales.notification.import.button': 'Importar dados',
  'isales.taskinfo.field.flowName': 'Fluxo IVR',
  'isales.work.title.busiSuccess': 'Negócios bem-sucedidos',
  'isales.taskresult.field.skillqueue': 'Fila de habilidades',
  'isales.task.promotion.startFailed': 'Falha ao publicar a tarefa de marketing multimídia',
  'isales.taskstatistics.field.callRatePie': 'Taxa de conclusão da chamada',
  'isales.exportdata.message.failinfo': 'Falha ao exportar dados',
  'isales.tasktemplate.title.editTemplate': 'Modificar modelo de chamada de saída',
  'isales.taskresult.field.failsTransferonbusy': 'A chamada está sendo encaminhada quando ocupado',
  'isales.taskstatistics.field.Completed': 'Número de chamadas concluídas pelo cliente',
  'isales.taskresult.field.failsCallqueueoverflow': 'Ocorreu um estouro na fila de chamadas',
  'isales.taskmanagement.title.out.callresult': 'Resultado da chamada de saída',
  'isales.manual.message.confirmLockData': 'Tem certeza de que deseja bloquear os dados da chamada originada?',
  'isales.task.promotion.smsgw': 'Gateway SMS',
  'isales.speclist.blacklist.message.movePartiallyFail': 'Falha na transferência de número para lista negra',
  'isales.task.promotion.inputPromotionName': 'Insira um nome de marketing',
  'isales.operlog.message.data.import': 'Importar dados para a tarefa {0}',
  'isales.tasklogic.message.need.turns': 'Adicione ao menos um dado de rodada',
  'isales.taskcalllogic.field.fax': 'Fax\, quadro de comando\, etc',
  'isales.file.title.rule.validator.ruleId': 'A regra de mapeamento não existe',
  'isales.agent.message.deleteSkillAndAgentFail': 'Falha ao excluir a associação da fila de habilidades',
  'isales.blacklist.title.deleteError': 'Erro',
  'isales.task.promotion.gateway': 'Enviando gateway',
  'isales.taskresult.field.failsCallattemptfails': 'Falha ao chamar a interface de chamada de saída',
  'isales.blacklist.message.confirmDelete': 'Tem certeza de que deseja excluir os números selecionados da lista de bloqueios?',
  'isales.tasktemplate.title.manualTemplate': 'Modelo de chamada de saída manual',
  'isales.management.title.adjustment.log': 'Registro de ajuste',
  'isales.business.result.create': 'Criar resultado comercial',
  'isales.tasklogic.message.confirmBatchUpdate': 'A modificação em lote se aplica somente às regiões selecionadas. Tem certeza de que deseja continuar?',
  'isales.taskpolicy.message.obOverTimeRange': 'A duração do tempo limite da visualização está fora do intervalo: 9 a 120. Altere a duração do tempo limite da visualização primeiro',
  'isales.task.promotion.tobesent': 'Mensagens a serem enviadas',
  'isales.work.title.busiFail': 'Negócios falhados',
  'isales.tasktemplate.message.nameDuplicated': 'Já existe um modelo de chamada efetuada com o mesmo nome',
  'isales.tasklogic.message.busiresultpolicyNotsaved': 'Algumas políticas de resultado de negócios não são salvas',
  'isales.statistics.field.addHisDetail': 'Não: Dados de {0} meses são coletados. Sim: Dados de {1} meses são coletados',
  'isales.file.title.username.placeholder': 'Por favor insira o nome de usuário',
  'isales.management.title.timeImport': 'Configurar importação programada',
  'isales.taskinfo.message.nameExists': 'O nome da tarefa de chamada de saída deve ser exclusivo',
  'isales.task.promotion.message.monthGtThree': 'Apenas os resultados de três meses podem ser consultados',
  'isales.agent.label.deleteCtiFailed': 'Falha ao excluir os dados',
  'isales.import.filter.title.callDay': 'Atividade recente (dias)',
  'isales.manual.field.callflag.inprocess': 'Chamando',
  'isales.exportdata.title.exportresult': 'Exportar resultado',
  'isales.result.resultCode': 'Código da causa',
  'isales.file.title.ip.placeholder': 'inserir o endereço IP do servidor',
  'isales.tasktemplate.field.release': 'Publicar',
  'isales.file.title.userNameOrAK': 'Nome de usuário/AK',
  'isales.notification.receiver.delete.error': 'Nenhum dado selecionado',
  'isales.data.prop.inputPropCode': 'Insira um código de atributo',
  'isales.task.promotion.sendStatus': 'Status de envio',
  'isales.taskpolicy.message.saveFail': 'Falha ao configurar a política de chamada originada',
  'isales.business.result.addchildren': 'Criar substatus',
  'isales.agent.button.agent.association': 'Configurar agente',
  'ccivr.ivr.ivrflow.type': 'Tipo',
  'isales.tasklogic.label.primaryCondition': 'Condição principal',
  'isales.tasklogic.field.policy': 'Política',
  'isales.task.message.startTips': 'A tarefa está expirada. Não é possível iniciar a tarefa de chamada de saída',
  'isales.taskresultexport.message.result': 'Resultado',
  'isales.specist.message.updateFail': 'Falha na modificação',
  'isales.specialday.message.dateEmpty': 'A data especial está vazia',
  'isales.file.title.rule.interval': 'Coluna Intervalo de chamadas',
  'isales.taskmanagement.title.viewTaskResult': 'Resultado da tarefa',
  'isales.taskinfo.message.batchEditCallLogic.CallPolicyNotification': 'As rondas existentes permanecem inalteradas\, e novas rondas serão adicionadas ao lado das existentes',
  'isales.task.promotion.dndTime': 'Período de Não Perturbe',
  'isales.taskresult.field.failsBeingchanged': 'A parte chamada está mudando e a chamada é encaminhada',
  'isales.taskcalllogic.title.retryPolicySet': 'Política de chamada de teste',
  'isales.manualData.message.pickUpSuccess': 'Extração bem-sucedida',
  'isales.agent.message.curcountpasswordWrong': 'A senha da conta atual está incorreta',
  'isales.specist.title.misjudgment': 'Julgamento incorreto',
  'isales.agent.button.cancel': 'Cancelar',
  'isales.busi.result.message.systemparams.fail': 'Falha ao consultar parâmetros do sistema',
  'isales.agent.calledroute.require': 'Defina todos os campos obrigatórios',
  'isales.tasklogic.field.selectProp': 'Adicionar atributo',
  'isales.data.import.task.status.ready': 'Preparação',
  'isales.taskresult.field.failsInvalidRoutingDevice': 'Dispositivo de roteamento inválido',
  'isales.taskresult.title.chooseEndTime': 'Selecione a hora de fim',
  'isales.agent.label.maxWaitNum': 'Máx. Chamadas na Fila',
  'isales.manual.message.not.call': 'Nenhuma chamada de saída é feita',
  'isales.taskpolicy.field.maxAvgCallTime': 'Duração média da chamada Máxima',
  'isales.tasklogic.field.value': 'Valor',
  'isales.exportdata.message.nodata': 'Nenhum dado que atenda às condições pode ser exportado!',
  'isales.management.message.batchEditCallLogic.notSupportBusiresultInCMS': 'As políticas de resultados de negócios não podem ser configuradas para chamadas automáticas efetuadas. Tem certeza de que deseja executar a modificação do lote?',
  'isales.taskTime.message.saveSuccess': 'O tempo de chamada de saída foi alterado com sucesso',
  'isales.taskresult.field.failsAutomaticdevicedetected': 'Dispositivos automáticos são detectados',
  'isales.taskinfo.title.chooseSmsTemplateQuery': 'Selecionar Modelo SMS',
  'isales.agent.pageurls.errorsave': 'Erro',
  'isales.file.title.rule.desc': 'Descrição',
  'isales.taskinfo.field.callTimes': 'Tentativas de chamadas',
  'isales.tasklogic.field.orderPolicy': 'Ordenação das chamadas',
  'isales.management.title.adjustment.modifier': 'Ajustado por',
  'isales.specialday.message.confirmDelete': 'Deseja realmente excluir os dados?',
  'isales.taskmanagement.title.startConfirm': 'Tem certeza de que deseja iniciar a tarefa da chamada efetuada selecionada?',
  'isales.common.title.dataSearch': 'Pesquisar',
  'isales.appointment.title.soontimeout': 'O horário da reserva está prestes a chegar',
  'isales.manual.title.sendEmail': 'Enviar e-mail',
  'isales.manual.message.taskstatus.error': 'A chamada de saída não está no estado de execução',
  'isales.tasklogic.message.no.numbertype': 'Número de seqüência vazio',
  'isales.business.result.delete.data.error': 'No máximo 100 registros podem ser excluídos por vez',
  'isales.taskmanagement.title.batchEditCallPolicy.manualError': 'As políticas de chamada de saída manuais não podem ser modificadas',
  'isales.taskmanagement.message.period.seven': 'O intervalo não pode ser superior a 7 dias',
  'isales.appointment.title.editAppoint': 'Modificar chamada originada reservada',
  'isales.result.outgoingCall.input.description': 'inserir o código ou a descrição do resultado da chamada originada',
  'isales.taskmanagement.title.batchEditTaskInfo.errStatus': 'Selecione uma tarefa do estado rascunho\, suspenso ou em execução',
  'isales.validate.message.notSpecial': 'O nome não pode conter caracteres especiais',
  'isales.taskmanagement.field.basicTaskInfo': 'Informações básicas',
  'isales.taskinfo.field.smsTemplate': 'Modelo SMS para chamada de saída falhada',
  'isales.taskinfo.field.description': 'Descrição da Tarefa',
  'isales.taskmanagement.title.batchEditCallTime': 'Alterar em lote o horário das chamadas efetuadas',
  'isales.task.promotion.message.variableIsNull': 'A lista de configuração de atributos está vazia',
  'isales.taskresult.title.callResult': 'Resultado',
  'isales.specialday.field.Monday': 'Segunda',
  'isales.task.message.startWarning': 'Exportando dados... Não é possível iniciar a tarefa de chamada originada',
  'isales.taskmanagment.title.open.agentMonitor': 'Monitoramento do agente',
  'isales.notification.variable.name': 'Nome da variável',
  'isales.taskresult.title.operationTime': 'Tempo de operação',
  'isales.appointment.message.callSuccess': 'Bem-sucedido',
  'isales.agent.toblocklist.addType': 'Adicionar tipo de lista de blocos',
  'isales.agent.label.bindTheSkill': 'A configuração da parte chamada foi vinculada. Primeiro\, desvincula-o',
  'isales.taskresultexport.message.downloadRedischeckfailinfo': 'Um arquivo pode ser baixado novamente somente quando o download anterior for concluído',
  'isales.task.message.pauseSuccess': 'A tarefa de chamada originada foi suspensa com sucesso',
  'isales.agent.label.theAgent': 'Agente',
  'isales.task.promotion.isRetry': 'Tente novamente em caso de falha',
  'isales.manual.message.confirmReleaseData': 'Tem certeza de que deseja liberar os dados da chamada originada?',
  'isales.taskresultexport.title.success': 'Bem-sucedido',
  'isales.common.message.checknonnegativeinteger': 'Insira um número inteiro não negativo no formato correto',
  'isales.taskmanagement.title.deleteFailed': 'Falha ao excluir a tarefa',
  'isales.blacklist.message.error': 'Ocorre um erro quando o número é adicionado à lista de bloqueios',
  'isales.agent.message.modifySkillFail': 'Falha ao atualizar a fila de habilidades',
  'isales.task.promotion.statusStart': 'A tarefa de marketing multimídia está sendo executada',
  'isales.manual.field.callinginformation': 'Informações da chamada',
  'isales.appointment.message.updateSuccess': 'A chamada originada reservada foi modificada com sucesso',
  'isales.taskresult.field.failsCallTimeout': 'O tempo limite da chamada',
  'isales.taskresult.field.failsDnd': 'A chamada é temporariamente rejeitada',
  'isales.taskmanagement.title.batchPause.statusError': 'As tarefas no estado de rascunho ou expirado não podem ser suspensas',
  'isales.specialday.message.deleteFail': 'Falha ao excluir a data especial',
  'isales.taskstatistics.field.connectedCallsPie': 'Número de chamadas conectadas',
  'isales.input.message.large': 'O valor de entrada é muito grande',
  'isales.taskresult.field.failsSwitchRestart': 'O switch reiniciou',
  'isales.marketing.mode': 'Modo de marketing',
  'isales.manual.title.viewManualResult': 'Resultado da chamada originada do agente',
  'isales.blacklist.message.calledno.exist': 'O número existe na lista de blocos',
  'isales.taskinfo.title.chooseCommonIvrFlow': 'Selecionar IVR comum',
  'isales.taskresult.field.failsTransferunconditionally': 'A chamada está sendo encaminhada incondicionalmente',
  'isales.taskinfo.placeholder.choose': '--Selecionar',
  'isales.notification.variable.defvalue': 'Valor padrão',
  'isales.taskresult.field.failsTimeout': 'O tempo limite do sistema',
  'isales.taskmanagement.field.intelligence': 'chamada originada Inteligente',
  'isales.import.message.endtime': 'Hora de término',
  'isales.specialday.message.createFail': 'Falha ao criar a data especial',
  'isales.import.message.begintime': 'Hora de início',
  'isales.agent.label.skills': 'Fila de habilidades',
  'isales.specialday.field.Saturday': 'Sábado',
  'isales.taskpolicy.field.obCallCount': 'Número de chamadas de saída por vez',
  'isales.tasklogic.field.prop': 'suporte',
  'isales.agent.label.noAgents': 'Ausência de agente em serviço',
  'isales.taskmanagement.title.reset': 'Redefinir',
  'isales.data.prop.numberType': 'Algarismo',
  'isales.taskmanagement.field.callCompletedNum': 'Chamadas concluídas',
  'isales.management.message.coverMode': 'Sobrescrever',
  'isales.data.prop.propName': 'Nome do atributo',
  'isales.taskstatistics.field.numberOfUnconnectedCalls': 'Número de chamadas não conectadas',
  'isales.tasklogic.field.and': 'E',
  'isales.management.title.adjustment': 'Ajustar',
  'isales.taskresult.field.failsBusytone': 'Tom de ocupado',
  'isales.file.server.message.checkObsPath': 'Somente dígitos\, letras\, hífens (-) e pontos (.) são permitidos',
  'isales.data.prop.isPushAgent': 'Enviar para Agente',
  'isales.management.message.import.mapRule': 'Regra de mapeamento',
  'isales.business.result.description': 'Descrição do resultado comercial',
  'isales.agent.Transfer.devicetype': 'Tipo de dispositivo de destino',
  'isales.appointment.message.deleteSuccess': 'As chamadas de saída reservadas são excluídas com sucesso',
  'isales.manual.title.lock': 'Bloqueio',
  'isales.data.prop.anonymType': 'Criptografia',
  'isales.task.promotion.message.dndTimeltCrossExpectedTime': 'O tempo de envio esperado não pode estar no período de não perturbar',
  'isales.file.message.rule.receiver.columnRepeat': 'A coluna destinatária não pode ser a mesma que a coluna de atributo',
  'isales.management.message.updateFail': 'Falha ao modificar os dados da chamada originada',
  'isales.taskinfo.title.chooseIvrFlow': 'Selecionar fluxo de IVR',
  'isales.common.title.ready': 'Pronto',
  'isales.result.addOutgoingCallResult': 'Adicionar resultado',
  'isales.task.promotion.updateFailed': 'Falha ao modificar a tarefa de marketing multimídia',
  'isales.tasklogic.field.addCallnoLogic': 'Adicionar política de contato',
  'isales.notification.receiver.deletetips': 'Tem certeza de que deseja excluir os dados selecionados?',
  'isales.taskTime.message.saveFail': 'Falha ao alterar o tempo de chamada de saída',
  'isales.taskresult.title.queryTime': 'Informações',
  'isales.manual.message.afterNow': 'A hora final não pode ser anterior à hora atual',
  'isales.agent.label.skillName': 'Nome da fila de habilidades',
  'isales.sendStatus.field.fail': 'Falha',
  'isales.taskstatus.field.finish': 'Expirado',
  'isales.statistics.field.touchRateDescription': 'Taxa de alcance',
  'isales.taskinfo.message.batchEditCallLogic.CallResultNotification': 'As políticas existentes podem ser modificadas e novas políticas serão adicionadas ao lado das existentes',
  'isales.data.title.create': 'Novo',
  'isales.busi.result.item.fail': 'falha',
  'isales.file.title.rule.property.name': 'Nome do atributo',
  'isales.task.promotion.saveSuccess': 'A tarefa de marketing multimídia foi salva com sucesso',
  'isales.import.message.status': 'Status',
  'isales.taskpolicy.field.lossRate': 'Taxa mínima de perda de chamadas',
  'isales.taskinfo.field.taskType': 'Tipo de tarefa',
  'isales.taskresultexport.title.exported': 'A ser exportado',
  'isales.notification.receiver.number.placeholder': 'Informe o número do destinatário',
  'isales.taskcalllogic.message.callNoPolicyEmpty': 'Adicione ao menos uma política de números',
  'isales.specialday.message.weeklyFormatError': 'O formato do período de data especial está incorreto',
  'isales.result.view': 'Exibir causa de falha de chamada de saída',
  'isales.tasklogic.field.callresultpolicy': 'Política de resultados de chamadas de saída',
  'isales.taskpolicy.field.previewTimeoutInterval': 'Visualizar intervalo de tempo limite (s)',
  'isales.management.message.deleteConfirm': 'Tem certeza de que deseja excluir os dados da chamada originada selecionada?',
  'isales.data.propConfig.title.isPushAgent': 'Enviar para Agente',
  'isales.work.title.calledNum': 'Chamadas processadas',
  'isales.manual.message.recipient': 'Insira um destinatário',
  'isales.taskstatistics.field.numberOfConnectedCalls': 'Número de chamadas conectadas',
  'isales.appointment.message.deleteFail': 'Falha ao excluir as chamadas originadas reservadas',
  'isales.taskcalllogic.field.callnoPolicy': 'Política de contato',
  'isales.taskinfo.title.commonIvrFlow': 'IVR comum',
  'isales.specialday.field.Thursday': 'Quinta',
  'isales.management.message.calledNoTwo': 'Segundo número chamado',
  'isales.appointment.title.call': 'Chamar',
  'isales.taskinfo.field.noSpecifiedAgent': 'Fila de habilidades inteiras',
  'isales.taskinfo.field.specifiedAgent': 'Agente especificado',
  'isales.manual.title.calledNo': 'Número de saída',
  'isales.agent.button.createComplete': 'Terminar',
  'isales.task.message.completionRate': 'Taxa de conclusão da tarefa',
  'isales.manual.message.dataId.Empty': 'A ID dos dados não pode ficar em branco',
  'isales.taskresultexport.title.progress': 'Exportando',
  'isales.tasklogic.message.busiresultpolicyNotSaved': 'Salve a política de resultados de negócios existente primeiro!',
  'isales.taskstatistics.placeholder.called': 'Chamado: {0}',
  'isales.taskresult.field.failsResultnotobtained': 'Os dados de chamada de saída são gravados de volta quando nenhum resultado é obtido após o timeout da chamada',
  'isales.task.message.NoStartTask': 'Nenhuma tarefa que atenda às condições está disponível',
  'isales.agent.message.pwdRepeat': 'A senha enviada é a mesma que a senha recentemente alterada',
  'isales.import.message.uploadedfileformatvalidate': 'Formato de arquivo incorreto. Selecione um arquivo CSV',
  'isales.busi.result.message.agentstatus.fail': 'Falha ao consultar o status do agente',
  'isales.management.message.addPropTips': 'Os dados pessoais ou os dados sens��veis devem ser encriptados, armazenados e mascarados',
  'isales.taskstatistics.field.failCode': 'Causa de falha',
  'isales.appointment.message.createFail': 'Falha ao criar a chamada originada reservada',
  'isales.taskinfo.placeholder.input': '--Inserir--',
  'isales.management.message.serverImport': 'Usando o servidor',
  'isales.tasklogic.field.logic': 'Operador',
  'isales.agent.query.error': 'O agente alvo não pode ser o mesmo que o executor do registro selecionado',
  'isales.taskmanagement.title.tip.batchEditTask': 'Selecione a tarefa a ser modificada',
  'isales.task.message.successRate': 'Taxa de sucesso',
  'isales.task.title.callBack': 'Rejeitar',
  'isales.tasktemplate.title.autoTemplate': 'Modelo de chamada automática efetuada',
  'isales.business.name': 'Nome',
  'isales.result.addResultMaptips': 'Um máximo de 50 resultados de chamadas originadas podem ser adicionados',
  'isales.result.addAgentTips': 'Se pueden agregar un máximo de 20000 agentes',
  'isales.operation.success': 'Operação bem-sucedida',
  'isales.operation.training.success': 'Operação bem-sucedida. O treinamento leva em torno de horas especiais . Log in to Lodas to view the training progress ',
  'isales.operation.failed': 'Falha na operação',
  'isales.taskmanagement.title.batchEditTaskInfo.manual': 'A chamada automática de saída e a chamada manual de saída não podem ser modificadas ao mesmo tempo',
  'isales.enum.workSpace': 'Bancada de sa��da',
  'isales.management.title.black.import': 'Importar lista negra',
  'isales.black.filed.number': 'Número (obrigatório)',
  'isales.black.filed.expTime': 'Duração da validade (opcional, em horas)',
  'isales.black.filed.remark': 'Comentários (não obrigatório)',
  'isales.survey.statistic': 'Estatísticas de Respostas',
  'isales.survey.querydetail': 'Consulta de resultados do questionário',
  'isales.manual.task.survey.questiontotal': 'Total de {0} questões',
  'isales.manual.task.survey.unanswered': 'Não respondido',
  'isales.manual.task.survey.answered': 'Respondido',
  'isales.manual.task.survey.currentprogress': 'Progresso atual',
  'isales.manual.task.survey.previousquestion': 'Pergunta anterior',
  'isales.manual.task.survey.nextquestion': 'Próxima pergunta',
  'isales.manual.task.survey.submit': 'Submeter',
  'isales.taskinfo.field.survey': 'Questionário',
  'isales.manual.task.survey.noAnswer': 'Não há necessidade de responder',
  'isales.manual.task.survey.answerSuccess': 'Resposta bem sucedida!',
  'isales.agent.calledroute.search.devicedesc': 'Insira um nome de dispositivo',
  'isales.agent.calledroute.create': 'Adicionar configuração chamada',
  'isales.agent.calledroute.device': 'Selecione um dispositivo',
  'isales.agent.calledroute.devicetype': 'Tipo de equipamento',
  'isales.agent.calledroute.extcode': 'espalhando código',
  'isales.agent.calledroute.ivr': 'Por favor, selecione IVR',
  'isales.agent.calledroute.placeholder.ivr': 'Por favor, selecione IVR',
  'isales.agent.calledroute.skill': 'fila de habilidades',
  'isales.agent.calledroute.placeholder.skill': 'Selecione uma fila de habilidades',
  'isales.agent.calledroute.accesscode': 'Código de acesso',
  'isales.agent.calledroute.placeholder.accesscode': 'Selecione um código de acesso',
  'isales.agent.calledroute.placeholder.extcode': 'Insira o código de extensão',
  'isales.calledroute.title.selectaccesscode': 'Escolha um código de acesso',
  'isales.agent.calledroute.extcodewarn': 'Os códigos de extensão podem conter apenas números',
  'isales.agent.calledroute.extlengthcodewarn': 'O comprimento do código de extensão não pode exceder 12',
  'isales.agent.calledroute.editsuccess': 'Editado com sucesso',
  'isales.agent.calledroute.exception': 'Verifique o status do Redis ou a configuração do CC-Gateway',
  'isales.agent.calledroute.editfail': 'Falha na edição de dados locais',
  'isales.agent.calledroute.accesscodewarn': 'Os códigos de acesso só podem conter números',
  'isales.agent.calledroute.createsuccess': 'adicionado com sucesso',
  'isales.agent.calledroute.createfail': 'Falha ao adicionar dados locais',
  'isales.agent.calledroute.create.accesscode.none': 'O código de acesso ao sistema configurado pela parte chamada não está atribuído',
  'isales.agent.calledroute.create.accesscode.repeat': 'A combinação de código de acesso ao sistema e código de ramal configurado pela parte chamada não pode ser repetida',
  'isales.agent.calledroute.create.accesscode.noexist': 'O código de acesso referenciado pela configuração chamada não existe',
  'isales.agent.calledroute.create.skill.none': 'A fila de habilidades referenciada pela configuração chamada não existe',
  'isales.agent.calledroute.create.ivr.limit': 'O tipo de dispositivo é IVR e o tipo de mídia do código de acesso só pode ser clique para ligar, solicitação de retorno de chamada ou chamada normal',
  'isales.agent.calledroute.create.gateway.none': 'O script de roteamento referenciado pela configuração chamada não existe',
  'isales.agent.calledroute.create.media.match': 'O código de acesso ao sistema configurado pela parte chamada é inconsistente com o tipo de mídia do dispositivo configurado pela parte chamada',
  'isales.agent.calledroute.recordexist': 'O código de acesso e o código de extensão já estão vinculados, insira um novo código de extensão',
  'isales.agent.calledroute.nmsdata.error': 'A sincronização do ODFS falha e os dados de configuração da parte chamada ODFS são inconsistentes com os dados locais do CCM',
  'isales.agent.calledroute.ccivrdata.error': 'Falha ao sincronizar o ODFS, os dados do processo CC-IVR estão errados',
  'isales.agent.calledroute.nmsconnect.fail': 'Falha ao conectar ao ODFS',
  'isales.management.placeholder.accesscodeDesc': 'Insira uma descrição do código de acesso',
  'isales.taskinfo.title.accesscode': 'Código de acesso',
  'isales.taskinfo.title.accesscodedesc': 'Descrição do código de acesso',
  'isales.agent.contact.voiceVidio': 'Áudio e vídeo',
  'isales.agent.contact.clicktodial': 'Clique para chamar',
  'isales.note.config.success': 'sucesso',
  'isales.agent.calledroute.refreshsuccess': 'A atualização foi bem-sucedida',
  'isales.agent.calledroute.updatecalledroute': 'editar',
  'isales.agent.calledroute.confdelete': 'excluir confirmação',
  'isales.agent.calledroute.isconfdelete': 'Você tem certeza que quer deletar?',
  'isales.agent.calledroute.success': 'sucesso',
  'isales.agent.calledroute.delsuccess': 'excluído com sucesso',
  'isales.agent.message.redislocknull': 'Falha ao adquirir o bloqueio Redis',
  'isales.calledroute.message.selectcalledroute': 'Selecione a configuração chamada',
  'isales.agent.calledroute.delfail': 'Falha na exclusão de dados locais',
  'isales.label.appraise.call.quality.agent': 'agente',
  'isales.label.appraise.call.quality.customer': 'cliente',
  'isales.label.appraise.call.quality.customerInfo': 'Informação ao Cliente',
  'isales.taskstatistics.field.feedBackStatistics': 'Estatísticas de feedback',
  'isales.task.message.feedBackRate': 'Taxa de feedback',
  'isales.task.message.numberEffFeedback': 'Número de chamadas de feedback ativas',
  'isales.task.message.numberInvalFeedback': 'Número de chamadas de feedback inválidas',
  'isales.survey.details': 'Detalhes da resposta',
  'isales.taskstatistics.field.specialCall': 'Listas especiais',
  'isales.taskmanagment.title.open.obsMonitor': 'Monitoramento de dados do OBS',
  'isales.management.message.black.download': 'Baixar modelo de lista negra',
  'isales.taskmanagment.title.obs.viewstatistics': 'OBS obtém dados de monitoramento',
  'isales.task.statistics.obs.startTime': 'A hora de início não pode ser anterior a sete dias atrás',
  'isales.warning.data.export': 'Os dados exportados contêm informações pessoais , por favor , tenha cuidado !',
  'isales.statistics.business.taskId': 'ID da Tarefa',
  'isales.statistics.business.reportType': 'Tipo de Relatório',
  'isales.statistics.business.beginDate': 'Data de início',
  'isales.statistics.business.endDate': 'Data Final',
  'isales.statistics.business.startYear': 'Ano de início',
  'isales.statistics.business.startWeek': 'Semanas Iniciais',
  'isales.statistics.business.startMonth': 'Mês de início',
  'isales.statistics.business.endYear': 'Fim do ano',
  'isales.statistics.business.endWeek': 'Fim da semana',
  'isales.statistics.business.endMonth': 'Mês final',
  'isales.statistics.business.search': 'Pesquisar',
  'isales.statistics.business.reset': 'Redefinir',
  'isales.statistics.business.date': 'Datas',
  'isales.statistics.business.week': 'Semanas',
  'isales.statistics.business.month': 'Mês',
  'isales.statistics.business.byDay': 'Diário',
  'isales.statistics.business.byWeek': 'Relatório semanal',
  'isales.statistics.business.byMonth': 'Relatórios Mensais',
  'isales.statistics.business.title': 'Relatório de Serviço de Chamada de Saída',
  'isales.statistics.business.trend.title': 'Relatório de Tendência de Serviço de Chamada de Saída',
  'isales.statistics.business.nomorethanerror': 'A data selecionada não pode ser anterior a {0}',
  'isales.statistics.business.currentDateCheck': 'A data selecionada deve ser anterior à data atual',
  'isales.statistics.business.dategt31days': 'O número de dias de consulta não pode exceder 31 dias',
  'isales.statistics.business.dategt12weeks': 'O intervalo semanal não pode exceder 12 semanas',
  'isales.statistics.business.dategt6month': 'O intervalo de meses não pode exceder 6 meses',
  'isales.statistics.business.weekLessThan': 'O número da semana do ano final não pode ser menor que o número da semana do ano inicial',
  'isales.statistics.business.monthLessThan': 'O mês do ano final não pode ser menor que o mês do ano inicial',
  'isales.statistics.business.yearLessThan': 'O ano final não pode ser menor que o ano inicial',
  'isales.statistics.business.noData': 'Sem dados',
  'isales.statistics.business.noMoreThanEight': 'O número de seleções não pode exceder 8',
  'isales.statistics.business.noMoreThanFive': 'O número de seleções não pode exceder 5',
  'isales.statistics.business.busiResult': 'Resultados de negócios',
  'isales.statistics.business.subBusiResult': 'Resultados do subnegócio',
  'isales.manual.title.viewPreemptionResult': 'Resultado da chamada de saída antecipada -',
  'isales.statistics.business.filteringIndicator': 'Filtrar Indicadores',
  'isales.statistics.business.indicatorName': 'Nome do indicador',
  'isales.statistics.business.description': 'Descrição',
  'isales.statistics.business.cancel': 'Cancelar',
  'isales.statistics.business.confirm': 'Confirmação',
  'isales.statistics.business.saveError': 'erro',
  'isales.statistics.business.errorInfo': 'Falha ao salvar , selecione pelo menos um indicador',
  'isales.statistics.business.error': 'erro',
  'isales.statistics.business.warningFilterMax': 'Selecione até { 0 } indicadores !',
  'isales.agent.message.description.limit': 'O comprimento da descrição da fila de habilidades não pode exceder 50',
  'isales.agent.message.updateCtiAndDbFail': 'Falha na atualização',
  'isales.taskinfo.message.agent': 'As informações do agente são anormais  Verifique se há agentes que não estejam na fila de habilidades atual',
  'isales.language.label.language': 'Selecione um idioma',
  'isales.language.label.zh_CN': 'Chinês',
  'isales.language.label.en_US': 'Inglês',
  'isales.language.label.es_ES': 'espanhol',
  'isales.taskmanagement.title.batchOpt': 'Operações em lote',
  'isales.audio.title.beginTime': 'hora de início da gravação',
  'isales.audio.title.endTime': 'hora de término da gravação',
  'isales.audio.title.asrTransResult': 'Resultados da transcrição de fala',
  'isales.audio.title.confirmedResult': 'Resultados do reconhecimento do tom de toque de chamada',
  'isales.audio.title.confirmStatus': 'resultados de negócios',
  'isales.taskstatus.field.confirming': 'a ser confirmado',
  'isales.taskstatus.field.confirmed': 'confirmado',
  'isales.rbtresult.field.other': 'outro',
  'isales.rbtresult.field.emptynumber': 'número vazio',
  'isales.rbtresult.field.cusclose': 'O usuário chamado desliga o telefone',
  'isales.rbtresult.field.interbusy': 'rede ocupada',
  'isales.rbtresult.field.callershutdown': 'Suspensão de chamadas atrasadas / suspensão unidirecional',
  'isales.rbtresult.field.calledshutdown': 'Chamado para parar',
  'isales.rbtresult.field.calledbusy': 'A parte chamada está ocupada e a parte chamada registrou a função de chamada em espera',
  'isales.rbtresult.field.calledbusycalling': 'A parte chamada está ocupada e a parte chamada não registrou a função de chamada em espera',
  'isales.rbtresult.field.calledoutarea': 'A parte chamada não está na área de serviço',
  'isales.rbtresult.field.transfer': 'Encaminhamento de chamada',
  'isales.rbtresult.field.tollcaller': 'Restrições de chamadas internacionais',
  'isales.rbtresult.field.longtimemissed': 'muito tempo sem ligar',
  'isales.rbtresult.field.calledrestriction': 'O usuário chamado definiu restrições de chamadas recebidas',
  'isales.rbtresult.field.linefaulty': 'Falha na linha na direção de outras operadoras',
  'isales.rbtresult.field.calledunconnected': 'A parte chamada não está disponível e o lembrete de chamada recebida está ativado',
  'isales.rbtresult.emptyparam.message': 'A hora de início e fim não pode estar vazia',
  'isales.asrtransresult.field.undefine': 'Indefinido',
  'isales.taskmanagement.message.crossmonth': 'A hora de início e a hora de término não podem abranger meses',
  'isales.taskinfo.url.not.whitelist': 'O endereço não está na lista branca',
  'isales.whitepopuppageurl.protocol.check': 'Uso de protocolos inseguros\, riscos de segurança',
  'isales.import.message.ruleExtProp': 'Os atributos personalizados foram ativados. Configure a regra de mapeamento de importação primeiro e\, em seguida, clique em Importar por regra',
  'isales.export.message.noSelectTrainingData': 'Selecione os dados a serem treinados',
  'isales.datasourcemanagement.field.load': 'carregar',
  'isales.datasourcemanagement.message.loadSuccess': 'Fonte de dados carregada com sucesso',
  'isales.datasourcemanagement.message.loadfail': 'Falha ao carregar fonte de dados',
  'isales.datasourcemanagement.message.confirLoad': 'O carregamento da configuração da fonte de dados é uma operação de alto risco \, prossiga com cuidado',
  'isales.task.promotion.whatsApp': 'WhatsApp',
  'isales.task.promotion.whatsAppChannel': 'canal do WhatsApp',
  'isales.task.promotion.whatsApp.message.template': 'Mensagem do modelo',
  'isales.task.promotion.whatsApp.message.template.select': 'Selecione uma mensagem de modelo do WhatsApp',
  'isales.task.promotion.whatsApp.channel.select': 'Selecione um canal do WhatsApp',
  'isales.task.promotion.whatsApp.message.id': 'emplate ID da mensagem',
  'isales.task.promotion.whatsApp.message.name': 'Nome da mensagem do modelo',
  'isales.task.promotion.whatsApp.variable.description': 'Descrição do parâmetro',
  'isales.task.promotion.whatsApp.variable.content': 'Conteúdo do parâmetro',
  'isales.management.message.SelectNumberMax': 'Selecione até 100 números de chamada por vez',
  'isales.basicinfo.title.addCallNo': 'Adicionar número de chamada',
  'isales.taskmanagement.title.deleteFailExitOne': 'Configure pelo menos um número de chamada para a tarefa de chamada de saída',
  'isales.failInterval.check': 'Pode haver riscos quando o intervalo for menor que 10s',
  'isales.taskmanagement.title.agentStatistics': 'Estatísticas de trabalho do agente',
  'isales.work.message.daterequest': 'O horário de início e término deve ser passado ao mesmo tempo',
  'isales.work.title.selectAgent': 'Selecionar agente',
  'isales.work.title.agent.workno': 'ID do agente',
  'isales.message.promptForAgent': 'Insira o ID do agente',
  'isales.button.search': 'Inquéritos',
  'isales.button.reset': 'Redefinir',
  'isales.title.agent.workno': 'ID do agente',
  'isales.label.agent.accountcode': 'Conta de serviço',
  'isales.agent.work.statistics.AgentName': 'Nome do agente',
  'isales.button.cancel': 'Cancelar',
  'isales.button.confirm': 'Confirme-se',
  'isales.file.message.FilePathTips': 'Opcional. Se o arquivo de chamada de saída estiver no caminho padrão\, não será necessário. Se o arquivo estiver no caminho padrão\, será necessário preencher o caminho do arquivo',
  'isales.blacklist.message.timeserror': 'Erro no limite de vezes',
  'isales.blacklist.message.perioderror': 'Erro no ciclo de limite',
  'isales.datasourcemanagement.message.updateDataSourceSuccess': 'Fonte de dados modificada com sucesso',
  'isales.datasourcemanagement.message.updateDataSourceFail': 'Falha ao modificar a fonte de dados',
  'isales.taskpolicy.field.utilRateCalculateOne': 'Uso do Agente = Duração total da chamada / Duração total de login',
  'isales.taskpolicy.field.utilRateCalculateTwo': 'Uso do Agente = (Duração Total da chamada + Duração do Estado de Trabalho) / Duração Total de login',
  'isales.tasklogic.field.eq': '=',
  'isales.tasklogic.field.neq': '!=',
  'isales.tasklogic.field.egt': '>=',
  'isales.tasklogic.field.elt': '<=',
  'isales.taskmanagement.title.batchEdit': 'Modificação em lote',
  'isales.button.confirm ': ' Confirmar ',
  'isales.task.status.running': ' Em andamento ',
  'isales.task.status.pause': ' Suspenso ',
  'isales.task.finished': ' Concluído ',
  'isales.task.notFinished': ' Incompleto ',
  'isales.task.info': ' Informações da tarefa ',
  'isales.task.benginEndTime': ' Hora de início e fim',
  'isales.task.detail': 'Detalhes da tarefa de chamada enviada',
  'isales.taskmanagement.startTask': ' Iniciar tarefa ',
  'isales.step.taskinfo': 'informações da tarefa',
  'isales.step.callpolicy': 'estratégia de saída',
  'isales.step.outboundcalldata': 'dados da tarefa',
  'isales.calltime.title.default.datetime': 'período de data regular',
  'isales.calltime.title.spec.date': 'data especial',
  'isales.calltime.title.add.date': 'adicionar data especial',
  'isales.calltime.title.add.time.period': 'adicionar período de tempo',
  'isales.calltime.title.holidayName': 'data especial nome',
  'isales.calltime.title.holidayType': 'tipo',
  'isales.calltime.title.holiday': 'hora especial',
  'isales.calltime.title.spec.datetime.period': 'período de tempo especial',
  'isales.calltime.task.template': 'modelo de tarefa',
  'isales.calltime.task.template.select.placeholder': 'Opção para criar tarefas de saída a partir de modelos',
  'isales.calltime.task.callerno.select.placeholder': 'Selecione o número de chamada',
  'isales.calltime.task.begin.end.date': 'Horário de início e término da tarefa',
  'isales.calldata.prop.filter': 'Filtragem de atributo de dados de chamada de saída',
  'isales.taskinfo.header.policy': 'estratégia de tarefa',
  'isales.taskinfo.call.header.policy': 'estratégia de chamada',
  'isales.taskmanagement.title.sysAuto': 'O sistema chama automaticamente',
  'isales.work.message.dateRangeRequest': 'Selecione o intervalo de tempo da consulta',
  'isales.work.message.taskNameLengthLimit': 'O comprimento do nome da tarefa não pode exceder 50',
  'isales.work.message.workNoLengthLimit': 'O comprimento do ID do agente não pode exceder 5',
  'isales.work.message.workNoTypeLimit': 'Deve ser 0 ou um inteiro positivo',
  'isales.label.agent.agentType': 'Tipo do agente',
  'isales.label.agent.role': 'Função',
  'isales.calleeNo.length.max32': 'O comprimento máximo do número de chamada de saída é 32',
  'isales.label.calldata.order': 'sequência de chamada',
  'isales.tasklogic.field.callOrderMethod.byData': 'Por atributo de dados',
  'isales.tasklogic.field.callOrderMethod.orderType': 'ordenar por',
  'isales.label.multiple.callno.round': 'Chamadas de clientes com vários números',
  'isales.task.result.export.query': 'Exibir',
  'isales.task.result.export.skip': 'Ignorar',
  'isales.task.result.export.message': 'A tarefa de exportação foi criada com sucesso. Deseja ver a lista de exportação?',
  'isales.rbtrecognition.remark.length': 'O comprimento da string está fora do intervalo, o comprimento deve ser menor ou igual a 200',
  'isales.task.field.callFlag0': 'A ser executado',
  'isales.task.field.callFlag1': 'Em execução',
  'isales.task.field.callFlag2': 'Concluído',
  'isales.taskinfo.field.agentType4': 'Áudio',
  'isales.taskinfo.field.agentType11': 'Vídeo',
  'isales.taskinfo.field.agentType5': 'Multimídia',
  'isales.taskinfo.field.agentType99': 'Versátil',
  'isales.appointment.title.appointState': 'Status da reserva',
  'isales.task.field.appointState1': 'Reservado',
  'isales.task.field.appointState2': 'Preparar para retorno de chamada',
  'isales.task.field.appointState3': 'Retorno de Chamada',
  'isales.task.field.appointState4': 'Concluído',
  'isales.taskinfo.title.adjustAgent': 'Ajustar assentos',
  'isales.taskinfo.check.empty': 'o parâmetro não pode estar vazio',
  'isales.tasktype.field.robot.create': 'Nova chamada de saída do robô',
  'isales.tasktype.field.auto.info': 'IVR comum',
  'isales.tasktype.field.intelligent.info': 'robô inteligente',
  'isales.task.result.callrecord.conversation': 'Registro de conversação',
  'isales.taskmanagement.title.pauseTask.askConfirm': 'Tem certeza de que deseja suspender a tarefa de chamada de saída? Se a tarefa for forçadamente pausada, as chamadas em andamento podem ser afetadas',
  'isales.tasktype.field.auto.robot.create': 'Novo IVR comum',
  'isales.tasktype.field.intelligent.robot.create': 'Crie um novo robô inteligente',
  'isales.management.title.callbot.auto': 'Configure o botão para chamar o robô',
  'isales.taskmanagement.title.taskDataVolume': 'Volume de Dados da Tarefa',
  'isales.taskmanagement.title.callCompletionRate': 'Taxa de Conclusão de Chamada',
  'isales.taskmanagement.title.importData.template': 'Modelo',
  'isales.taskmanagement.title.importData.upload': 'Carregar',
  'isales.taskmanagement.title.importData.selectFile': 'Selecionar arquivo',
  'isales.audio.content.calltype': 'Chamada de saída de voz',
  'isales.taskmanagement.pauseTask': 'Pausar tarefa',
  'isales.taskInfo.spec.date.title.quotasTips': 'Esta configuração indica a data especial e o período de tempo que requer chamadas de saída',
  'isales.calltime.title.spec.date.empty.warn': 'Data especial não configurada!',
  'isales.tasklogic.message.empty.orderNo': 'Há um número de série vazio, digite novamente',
  'isales.task management.exportResult': 'Exportar resultado da tarefa',
  'isales.outboundBusiness.statistic.di': 'semana',
  'isales.outboundBusiness.statistic.week': ' ',
  'isales.rbtrecognition.remark.tip': 'Caracteres especiais que não podem ser incluídos:',
  'isales.business.callInterval.message.must': 'intervalo é necessário',
  'isales.tasktype.field.robot': 'robô de saída',
  'isales.taskInfo.title.taskTemplate': 'Modelo de tarefa',
  'isales.taskmanagement.title.taskTime': 'Tempo da tarefa',
  'isales.taskmanagement.title.setAgent': 'Atribuindo Agentes',
  'isales.taskInfo.title.quotas': 'Fulfillment do resultado',
  'isales.taskInfo.title.quotasTips': 'Quando o número de chamadas marcadas pelo agente como resultado do serviço anterior excede o valor deste parâmetro, o sistema não recupera automaticamente as chamadas',
  'isales.taskInfo.title.callCompletedNumTips': 'Quando o número de chamadas concluídas excede o valor deste parâmetro, o sistema não recupera chamadas automaticamente',
  'isales.taskInfo.title.pickUpNum': 'Quantidade reivindicada de tempo único',
  'isales.taskInfo.title.pickUpCondition': 'Condições de extração de dados',
  'isales.taskInfo.title.filterCondition': 'Condição',
  'isales.taskInfo.title.callCompletedNum': 'Chamadas concluídas',
  'isales.taskmanagement.title.sysAuto.template': 'Modelo de chamada de saída automática do sistema',
  'isales.taskmanagement.title.sysAuto.templateAdd': 'Novo Modelo de Chamada de Saída Automática do Sistema',
  'isales.tasktype.field.robot.template': 'Modelo de chamada de saída do robô',
  'isales.tasktype.field.robot.templateAdd': 'Novo modelo de chamada de saída do bot',
  'isales.template.title.addManual': 'Modelo de chamada de saída manual',
  'isales.manual.field.callingRecord': 'Histórico de chamadas de saída',
  'isales.call.count': 'Número de chamadas',
  'isales.task.promotion.createBeginTime': 'Hora de inicio de la creación',
  'isales.task.promotion.createEndTime': 'Hora de finalización de la creación',
  'isales.task.promotion.timeerror': 'El tiempo efectivo debe ser anterior al tiempo de caducidad',
  'isales.busiresult.description.length': 'O comprimento da cadeia de caracteres excede o intervalo de valores. O comprimento deve ser menor ou igual a 500 caracteres',
  'isales.import.coverMode.tips': 'Substituir a importação excluirá todos os dados de chamadas pendentes. O tempo de execução é longo. Por favor, escolha com cuidado',
  'isales.taskmanagement.title.taskContent': 'Conteúdo da Tarefa',
  'isales.taskmanagement.title.saveAndImportData': 'Salvar e importar dados',
  'isales.task.promotion.numberOnly': 'Só pode ser um inteiro não negativo',
  'isales.data.lock': 'Alguns dados de chamadas de saída não são processados. Por exemplo, você está importando, adicionando ou excluindo dados de chamada de saída. Tente novamente mais tarde',
  'isales.survey.type': 'Tipo de questionário',
  'ccsurvey.label.scenarioType.manual': 'questionário manual',
  'ccsurvey.label.scenarioType.manualIvr': 'Manual vs. Questionário IVR',
  'isales.tasktype.field.manualIvr.info': 'Reproduzir processo de questionário',
  'ccsurvey.info.tip36': 'Esta questão é obrigatória',
  'ccsurvey.transfer.tip36': 'Suspender turno para questionário de reprodução IVR',
  'isales.taskmanagement.title.inputTaskId': 'Insira uma ID de tarefa',
  'isales.management.placeholder.surveyName': 'Digite o nome do questionário',
  'isales.taskmanagement.title.toBeCallCount': 'Chamadas pendentes',
  'isales.taskmanagement.field.taskId': 'ID da tarefa',
  'isales.taskinfo.message.callintervalrangeMax': 'Insira um número inteiro positivo entre 1-86400.',
  'isales.notification.receiver.whatsapp.placeholder': 'Digite o número do destinatário com o código de área internacional',
  'isales.data.title.more': 'Mais',
  'isales.manual.field.canBeCallCount': 'Volume de Chamadas',
  'isales.manual.exit.preview': 'Executar pré-visualização de chamada de saída',
  'isales.manual.exit.preview.tips': 'Se a pré-visualização expirar ou fechar a janela, o sistema ligará automaticamente para você',
  'isales.result.search.displayResultOrCode': 'Por favor insira o código ou descrição',
  'isales.management.message.dbIp': 'O formato IP está incorreto',
  'isales.agent.message.modifyPwdVdnFailed': 'Não é permitido modificar a senha do administrador de configuração!',
  'isales.training.startTraining': 'comece a treinar',
  'isales.training.taskdetails': 'detalhes da tarefa',
  'isales.training.answerNumber': 'Número de respostas',
  'isales.training.surveydetails': 'Detalhes estatísticos da folha de respostas',
  'isales.taskresult.field.notCheckIn': 'O agente não está conectado no momento',
  'isales.datasourcemanagement.title.inputDbschema': 'Insira o nome da fonte de dados',
  'isales.datasourcemanagement.field.backDbCnUrl': 'Conexão de fonte de dados alternativa',
  'isales.taskmanagement.field.taskStatus': 'Status da tarefa',
  'isales.training.remainingSamples': 'A quantidade restante',
  'isales.tasktype.field.preemption': 'Chamadas de saída preventivas',
  'isales.taskinfo.title.chooseSurvey': 'Selecione o questionário',
  'isales.task.statistics.obs.endTime': 'A hora de início e a hora de término não podem diferir em mais de meia hora',
  'isales.tasklogic.field.callOrderMethod.bySelf': 'Por regras personalizadas',
  'isales.datasourcemanagement.field.backdbSchema': 'Nome alternativo da fonte de dados',
  'isales.audio.title.callId': 'Gravando número de série',
  'isales.tasklogic.field.callOrderMethod': 'Regras de classificação',
  'isales.training.trainingRate': 'Taxa de treinamento prático',
  'isales.audio.title.audiotime': 'Duração da gravação (HH:mm:ss)',
  'isales.common.message.choose': 'Selecione os dados.',
  'isales.taskinfo.title.versionNo': 'Versão do questionário',
  'isales.training.scoreDetails': 'Detalhes da pontuação',
  'isales.datasourcemanagement.field.backdbUserName': 'Nome de usuário da fonte de dados alternativa',
  'isales.training.answeringRecord': 'Registro de resposta',
  'isales.datasourcemanagement.message.confirDelete': 'Excluir a configuração da fonte de dados é uma operação de alto risco; opere com cuidado.',
  'isales.datasourcemanagement.field.sync': 'Sincronizar',
  'isales.manual.field.noDataCanbeCallTips': 'O número atual de chamadas disponíveis é 0. Verifique se a configuração do atributo atende às condições do filtro.',
  'isales.blacklist.message.Description': 'Limite o número de chamadas para este número dentro do período válido por hora/dia',
  'isales.datasourcemanagement.message.deleteDataSourceFail': 'Falha ao excluir fonte de dados',
  'isales.training.tip1': 'O número de respostas atingiu o limite superior',
  'isales.datasourcemanagement.field.backdbpass2': 'Confirme a senha da fonte de dados alternativa',
  'isales.taskmanagement.title.addSysAuto': 'Crie um novo sistema para chamar automaticamente',
  'isales.training.tip2': 'As questões do teste estão sendo treinadas, por favor, não clique com frequência.',
  'isales.blacklist.placeholder.restrictTimes': 'Insira o número limite',
  'isales.training.completeSamples': 'Tamanho da amostra concluída',
  'isales.blacklist.title.restrictPeriod': 'Período limite',
  'isales.datasourcemanagement.message.passValidateFailed': 'Deve conter letras maiúsculas, letras minúsculas, números, caracteres especiais `~!{\'@\'}#{\'$\'}^&*()-_+{\'|\'}[{\'{\'}{\'}\'} ]<.>/? Pelo menos 3 tipos',
  'isales.datasourcemanagement.message.addDataSourceFail': 'Falha ao criar nova fonte de dados de chamadas de saída',
  'isales.datasourcemanagement.field.backdbId': 'Número da fonte de dados alternativa',
  'isales.datasourcemanagement.field.backdbIp': 'IP ou alias do banco de dados em espera',
  'isales.training.questionNumber': 'Número de perguntas',
  'isales.training.averageScore': 'pontuação média',
  'isales.datasourcemanagement.message.alreadyExistDs': 'A fonte de dados de chamadas de saída já existe neste VDN',
  'isales.datasourcemanagement.field.addDataSource': 'Adicionar fonte de dados de chamadas efetuadas',
  'isales.task.promotion.gatewwayError': 'A configuração do gateway não corresponde ao tipo de mídia',
  'isales.datasourcemanagement.message.syncSuccess': 'Fonte de dados sincronizada com sucesso',
  'isales.specialday.title.date': 'data especial',
  'isales.datasourcemanagement.field.dsType.gauss': 'Gaussiano',
  'isales.datasourcemanagement.field.dbIp': 'IP ou alias da fonte de dados',
  'isales.datasourcemanagement.field.dbId': 'Número da fonte de dados',
  'isales.datasourcemanagement.field.dsType.mysql': 'MYSQL',
  'isales.datasourcemanagement.message.dbpass': 'Por favor insira uma senha consistente',
  'isales.audio.title.starttime': 'Hora de início da gravação',
  'isales.manualData.message.noPickCondition': 'Defina as condições de extração',
  'isales.tasklogic.field.callOrderType.noOrder': 'chamada fora de ordem',
  'isales.datasourcemanagement.field.dsType.dm': 'Dameng',
  'isales.tasklogic.field.noRetryLogic': 'Adicionado código de resultado sem nova tentativa',
  'isales.export.message.noSelectData': 'Selecione os dados a serem exportados',
  'isales.datasourcemanagement.title.inputDbId': 'Insira o número da fonte de dados',
  'isales.datasourcemanagement.field.dbCnUrl': 'Conexão de fonte de dados',
  'isales.taskmanagement.title.clicktorefresh': 'Clique para atualizar',
  'isales.datasourcemanagement.field.add': 'Adicionar',
  'isales.datasourcemanagement.message.frequentOperation': 'Operação frequente, aguarde',
  'isales.training.queryQuestionnaire': 'Consulta da folha de resposta',
  'isales.audio.message.errortip1': 'Falha ao consultar informações de gravação, entre em contato com o administrador',
  'isales.datasourcemanagement.field.dbschema': 'Nome da fonte de dados',
  'isales.datasourcemanagement.field.dbPort': 'porta de origem de dados',
  'isales.datasourcemanagement.field.backdbport': 'Porta alternativa do banco de dados',
  'isales.datasourcemanagement.field.dsType.oracle': 'ORACLE',
  'isales.taskinfo.field.surveyAnswerNum': 'Número de respostas ao questionário',
  'isales.datasourcemanagement.field.backdbType': 'Tipo de banco de dados em espera',
  'isales.specialday.title.timePeriod': 'Horário de saída em data especial',
  'isales.taskmanagement.message.beforeNow': 'A hora de início não pode ser anterior à hora atual',
  'isales.datasourcemanagement.field.editDataSource': 'Editar fonte de dados de chamadas realizadas',
  'isales.operlog.message.exportSurveyData': 'Exportar perguntas do teste de treinamento prático',
  'isales.datasourcemanagement.field.backDbdriver': 'Nome alternativo do driver da fonte de dados',
  'isales.training.MaxAnswerNumber': 'O número máximo de respostas ao questionário',
  'isales.datasourcemanagement.message.deleteDataSourceSuccess': 'Fonte de dados excluída com sucesso',
  'isales.training.AssociatedSurvey': 'Questionário Relacionado',
  'isales.datasourcemanagement.title.inputVdnId': 'Insira o VDNID',
  'isales.taskresult.title.trainingQuestion': 'perguntas do teste de treinamento',
  'isales.data.propConfig.message.isExistBackDb': 'fonte de dados alternativa',
  'isales.training.forwardNumber': 'número de encaminhamento',
  'isales.taskinfo.field.surveyScore': 'Pontuação total da questão do teste',
  'isales.blacklist.message.periodAndTime': 'O período limite e os tempos limite devem ser preenchidos ou não preenchidos',
  'isales.datasourcemanagement.field.dsType.all': 'todos',
  'isales.task.promotion.tempalteError': 'O modelo de notificação de marketing não corresponde ao tipo de modelo',
  'isales.datasourcemanagement.field.vdnId': 'VDNID',
  'isales.datasourcemanagement.message.noObsINFO': 'A fonte de dados de chamadas de saída não está configurada ou está mal configurada',
  'isales.training.surveyScore': 'Pontuação total da questão do teste',
  'isales.audio.title.endtime': 'Hora de término da gravação',
  'isales.training.surveyDetail': 'Detalhes do questionário',
  'isales.audio.title.recording': 'Audição de áudio',
  'isales.datasourcemanagement.field.dbPass': 'Senha da fonte de dados',
  'isales.blacklist.title.restrictTimes': 'Limite o número de vezes',
  'isales.audio.title.audiorate': 'taxa de reprodução',
  'isales.export.message.selectToomach': 'Escolha até dez dados',
  'isales.datasourcemanagement.message.dbIp': 'O formato IP do banco de dados está incorreto',
  'isales.datasourcemanagement.field.backdbpass': 'Senha alternativa da fonte de dados',
  'isales.survey.createTrainingTask': 'Crie uma nova tarefa de treinamento',
  'isales.taskinfo.field.questionNumber': 'Número de perguntas',
  'isales.training.specialday.callTime': 'horário de chamada para data especial',
  'isales.blacklist.title.hour': 'por hora',
  'isales.data.message.empty': 'O número chamado não pode estar vazio!',
  'isales.audio.title.calltype': 'tipo de chamada',
  'isales.taskinfo.field.callBackUrl': 'endereço de retorno de chamada',
  'isales.datasourcemanagement.field.dbType': 'tipo de fonte de dados',
  'isales.training.sampleTotal': 'Tamanho da amostra',
  'isales.taskinfo.title.surveyName': 'Nome do questionário',
  'isales.datasourcemanagement.field.dbdriver': 'Orientado por fonte de dados',
  'isales.appointment.message.plaintext': 'Os usuários não podem iniciar chamadas sem permissões de texto não criptografado. Ative as permissões de texto não criptografado.',
  'isales.management.title.selectResultCode': 'Selecione o código do resultado',
  'isales.datasourcemanagement.field.dbUserName': 'Nome de usuário da fonte de dados',
  'isales.training.averageDuration': 'Tempo médio gasto',
  'isales.training.callTime': 'período de chamada',
  'isales.taskmanagement.exportResult': 'Exportar resultados de tarefas',
  'isales.tasklogic.field.callOrderMethod.byOrderNo': 'por número de série',
  'isales.management.title.setResultCode': 'Defina o código do resultado',
  'isales.blacklist.title.day': 'diariamente',
  'isales.tasklogic.field.callOrderType.isOrder': 'chamada ordenada',
  'isales.datasourcemanagement.field.viewDataSource': 'Ver origem de dados de chamadas efetuadas',
  'isales.datasourcemanagement.field.dbPass2': 'Confirme a senha da fonte de dados',
  'isales.training.callTimeSegment': 'Duração da chamada',
  'isales.training.startTraining.exists': 'O treinamento está em andamento. Tente novamente após concluí-lo.',
  'isales.training.questionScoreDetails': 'Detalhes da pontuação da pergunta',
  'isales.taskmanagement.title.batchEditTaskInfo.errTaskType': 'Diferentes tipos de tarefas não podem ser modificados em lotes',
  'isales.import.message.numberTooMany': 'Até 50.000 números podem ser carregados.',
  'isales.tasklogic.message.need.orderPolicy': 'Adicione pelo menos um agrupamento personalizado',
  'isales.taskinfo.message.callBackUrl': 'Insira o endereço de retorno de chamada correto',
  'isales.training.fullScoreNumber': 'Número de pontuações perfeitas',
  'isales.datasourcemanagement.message.addDataSourceSuccess': 'Criou com sucesso uma nova fonte de dados de chamadas de saída',
  'isales.training.voiceAverageScore': 'Pontuação média da fala',
  'isales.training.Questionnaire': 'Questionário de treinamento prático',
  'isales.training.resultAnalysis': 'Análise de resultados',
  'isales.taskresult.title.exportQuestion': 'Exportar perguntas do teste',
  'isales.business.result.message.checkCodePoint': 'Somente números, letras, -,. podem ser inseridos.',
  'isales.import.calldata.selecttime': 'Selecione o tempo de execução',
  'isales.statistics.message.chooseCondition': 'Selecione primeiro as condições de consulta',
  'isales.taskpolicy.field.time': '(Segundo)',
  'isales.taskInfo.title.filterSubCondition': 'Filtrar subcondições',
  'isales.agent.work.statistics.tableTips': 'Selecione a tarefa de chamada de saída ou o agente que você deseja ver primeiro',
  'isales.file.message.rule.add.success': 'Nova regra criada com sucesso',
  'isales.file.title.server.add.success': 'As informações do servidor foram criadas com sucesso',
  'isales.management.title.selectResult': 'Selecione o resultado da chamada de saída',
  'isales.specialday.placeholder.year.beginTime': ' O formato é:  MM-DD, por exemplo:  12-01',
  'isales.management.message.appointTime': 'A hora da chamada reservada não pode ser anterior à hora do sistema atual',
  'isales.management.message.intelligence.taskName': 'O comprimento do nome da tarefa do tipo de chamada de saída do robô inteligente não pode exceder 36 bytes. Uma letra ou número ocupa um byte e um caractere chinês ocupa três bytes',
}
