export default {
  "dy.agent.audio.tab.outgoingcall" : "Outgoing Call",
  "dy.agent.audio.tab.internalcall" : "Internal Call",
  "dy.agent.tab.over.number.hints" : "A maximum of eight tabs can be opened. You cannot open more tabs. Please close other tabs first.",
  "dy.chat.multimedia.update" : "Update",
  "dy.chat.agent.logout.hints" : "No interactons, Please Sign in to start your work.",
  "dy.chat.label.contacts" : "Contacts",
  "dy.chat.label.call" : "Call",
  "dy.chat.label.multimedia" : "Multimedia",
  "dy.chat.label.history" : "History",
  "dy.chat.label.monitor" : "Monitor",
  "dy.agent.label.id" : "ID:",
  "dy.agent.label.enter.exnum" : "Enter External Number",
  "dy.chat.multimedia.required" : "Required",
  "dy.chat.history.disconnect.tip" : "The call has been disconnected, you can send an offline message to reconnect to the customer",
  "dy.agent.voice.extnumber.isnull" : "The external number is null.",
  'dy.agent.session.list.timeout': 'Time out',
  "dy.agent.voice.msg.noskill" : "No skill queue is available",
  'dy.session.transfer.button': 'Got it',
  'dy.agent.transfer.failed': 'Failed to transfer to the destination agent',
  'dy.agent.twoinnerhelp.failed': 'Failed to consult the destination agent',
  'dy.agent.threeinnerhelp.failed': 'Three-party failure of the destination agent',
  'dy.agent.message.tipsNotMonitor': 'You are not an inspector. You cannot perform the inspection.',
  'dy.agent.message.selectInfo': 'Click the agent first to inspect the agent.',
  'dy.agent.attachment.notPreview': 'File type doesn’t support preview,please download file to view content.',
  "dy.chat.agent.novoEmail": "New Email",
  "dy.chat.sendmessage.recipient" : "To",
  "dy.chat.chatcard.folded": "Folded",
  "dy.chat.status.drafts": "Drafts",
  "dy.agent.call.selectextnumber": "Please select an external number.",
  "dy.agent.call.inputextnumber": "Please enter an external number."
}