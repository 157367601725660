export default {
  "chat.client.label.more": "\u66F4\u591A",
  "chat.client.label.bill": "\u6211\u7684\u8D26\u5355",
  "chat.client.label.order": "\u6211\u7684\u8BA2\u5355",
  "chat.client.label.input": "\u8BF7\u8F93\u5165",
  "chat.client.label.header": "Telco+",
  "chat.client.label.chatTitle": "\u5BA2\u6237\u7AEF",
  "chat.client.label.like": "\u731C\u4F60\u559C\u6B22",
  "chat.client.label.loginTitle": "\u767B\u5F55",
  "chat.client.label.promotions": "\u63D0\u793A",
  "chat.client.label.accountNumber": "\u8D26\u53F7",

  "chat.client.label.login": "\u767B\u5F55",


  "chat.client.label.password": "\u5BC6\u7801",
  "chat.agent.title.createSkill": "\u521B\u5EFA\u6280\u80FD",
  "chat.agent.title.createCase": "\u521B\u5EFACase",
  "chat.client.label.forgotPassword": "\u5FD8\u8BB0\u5BC6\u7801",
  "chat.agent.title.close": "\u5173\u95ED",
  "chat.agent.title.transfer": "\u8F6C\u7ED9",
  "chat.agent.title.enterText": "\u8BF7\u8F93\u5165",
  "chat.agent.label.waiting": "\u7B49\u5F85",
  "chat.agent.label.online": "\u5728\u7EBF",
  "chat.agent.label.goAway": "\u79BB\u5F00",
  "chat.agent.label.offline": "\u4E0B\u7EBF",
  "chat.agent.message.changeFailed": "\u53D8\u66F4\u5EA7\u5E2D\u72B6\u6001\u5931\u8D25\uFF01",
  "chat.agent.label.mybill": "\u6211\u7684\u8D26\u5355",

  "chat.agent.label.balance": "\u6536\u652F",
  "chat.agent.label.consumption": "\u6211\u7684\u6D88\u8D39",
  "chat.agent.label.total": "\u5408\u8BA1",
  "chat.agent.label.billDetail": "\u8D26\u5355\u660E\u7EC6",
  "chat.agent.label.money": "\u91D1\u989D",
  "chat.agent.label.historicalBill": "\u5386\u53F2\u8D26\u5355",
  "chat.agent.label.send": "\u53D1\u9001",

  // "# \u6A21\u62DF\u7B2C\u4E09\u65B9\u7CFB\u7EDF\u754C\u9762",
  "chat.thirdportal.title": "\u5BA2\u6237Web Portal",
  "chat.thirdportal.chatWindow.label.userPhone": "\u53F7\u7801",

  "chat.thirdportal.chatWindow.title": "\u5728\u7EBF\u5BA2\u670D",
  "chat.thirdportal.chatWindow.label.userName": "\u59D3\u540D",
  "chat.thirdportal.chatWindow.label.userEmail": "\u90AE\u7BB1",
  "chat.thirdportal.chatWindow.button.startChat": "\u5F00\u59CB\u4EA4\u8C08",

  "chat.client.label.evaluation": "\u6EE1\u610F\u5EA6\u8C03\u67E5",
  "chat.client.label.rateService": "\u8BF7\u5BF9\u670D\u52A1\u6253\u5206",
  "chat.client.button.submit": "\u63D0\u4EA4",
  "chat.client.message.evaluationFail": "\u6EE1\u610F\u5EA6\u8C03\u67E5\u5931\u8D25\uFF01",
  "chat.client.message.evaluationSuccess": "\u6EE1\u610F\u5EA6\u8C03\u67E5\u6210\u529F\uFF01",
  "chat.client.greeting.morning": "\u4E0A\u5348\u597D\uFF01",
  "chat.client.greeting.afternoon": "\u4E0B\u5348\u597D\uFF01",

  "chat.client.greeting.evening": "\u665A\u4E0A\u597D\uFF01",
  "chat.client.greeting.info": "\u6709\u4EC0\u4E48\u53EF\u4EE5\u5E2E\u52A9\u60A8\u7684\uFF1F",
  "chat.client.greeting.dear": "\u5C0A\u656C\u7684",
  "chat.client.transfer.link": "\u662F\u5426\u8F6C\u63A5\u4EBA\u5DE5\u670D\u52A1\uFF1F",
  "chat.client.evaluate.message": "\u8BF7\u5BF9\u6211\u7684\u670D\u52A1\u505A\u51FA\u8BC4\u4EF7\uFF0C\u8C22\u8C22\uFF01",
  "chat.client.alert.message.invildtoken": "\u60A8\u4F1A\u8BDD\u5DF2\u8D85\u65F6\u6216\u5DF2\u5728\u5176\u5B83\u5730\u65B9\u767B\u5F55\uFF0C\u8BF7\u5173\u95ED\u4EA4\u8C08\u7A97\u53E3\u540E\u91CD\u8BD5\uFF0C\u8C22\u8C22\uFF01",
  "chat.client.message.gladtoServe": "\u3002\u5F88\u9AD8\u5174\u4E3A\u60A8\u670D\u52A1\uFF01",

  "chat.client.message.hiMyWorkno": "\u60A8\u597D\uFF01\u6211\u7684\u5DE5\u53F7\u662F ",
  "chat.client.message.noAgent": "\u5BF9\u4E0D\u8D77\uFF0C\u5BA2\u670D\u6B63\u5FD9\uFF0C\u8BF7\u7A0D\u540E\u518D\u8BD5\uFF01",
  "chat.client.label.privacyHint": "\u9690\u79C1\u63D0\u793A",
  "chat.client.label.privacyHintContent": "\u4E3A\u4E86\u63D0\u5347\u670D\u52A1\u8D28\u91CF\uFF0C\u60A8\u7684\u4EA4\u8C08\u5185\u5BB9\u5C06\u88AB\u7CFB\u7EDF\u8BB0\u5F55\uFF0C\u662F\u5426\u540C\u610F\u5E76\u7EE7\u7EED\u4EA4\u8C08\uFF1F",
  "chat.client.label.yes": "\u662F",
  "chat.agent.label.chatBot": "\u667A\u80FD\u5BA2\u670D",

  "chat.agent.label.customerSource": "\u5BA2\u6237\u6765\u6E90\uFF1A\u624B\u673A\u5E94\u7528-\u4E2A\u4EBA\u4E2D\u5FC3",
  "chat.client.label.no": "\u5426",
  "chat.agent.title.userInformation": "\u5BA2\u6237\u4FE1\u606F",
  "chat.skill.label.createSkill": "\u521B\u5EFA",
  "chat.agent.title.userMenu": "\u5BA2\u6237\u83DC\u5355",


  "chat.skill.label.deleteSkills": "\u5220\u9664",
  "chat.skill.label.skillName": "\u540D\u79F0",
  "chat.skill.label.inputName": "\u540D\u79F0",
  "chat.skill.label.skillId": "\u7F16\u53F7",
  "chat.skill.label.description": "\u63CF\u8FF0",
  "chat.skill.label.priority": "\u4F18\u5148\u7EA7",
  "chat.skill.label.agents": "\u5EA7\u5E2D",
  "chat.skill.label.submit": "\u63D0\u4EA4",
  "chat.skill.label.cancel": "\u53D6\u6D88",
  "chat.skill.label.save": "\u4FDD\u5B58",
  "chat.skill.label.reset": "\u91CD\u7F6E",
  "chat.skill.label.delete": "\u5220\u9664",
  "chat.skill.label.name": "\u7528\u6237\u540D",
  "chat.skill.label.edit": "\u7F16\u8F91",
  "chat.skill.label.account": "\u5E10\u6237",
  "chat.skill.label.ok": "\u786E\u8BA4",
  "chat.skill.label.skillDetail": "\u6280\u80FD\u8BE6\u60C5",
  "chat.skill.label.maxQueueTime": "\u6700\u5927\u6392\u961F\u65F6\u95F4(\u79D2)",
  "chat.skill.label.maxQueueNumber": "\u6700\u5927\u6392\u961F\u4EBA\u6570",
  "chat.skill.label.maxQueueTimeInTable": "\u6700\u5927\u6392\u961F\u65F6\u95F4",

  "chat.skill.message.error": "\u9519\u8BEF",
  "chat.skill.message.success": "\u6210\u529F",
  "chat.skill.message.delete": "\u5220\u9664",
  "chat.skill.message.warning": "\u8B66\u544A",
  "chat.common.message.delete": "\u5220\u9664",

  "chat.common.message.success": "\u6210\u529F",
  "chat.common.message.warning": "\u8B66\u544A",
  "chat.common.message.error": "\u9519\u8BEF",

  "chat.skill.message.willDeleteSkill": "\u786E\u5B9A\u5220\u9664\u8BE5\u6280\u80FD\u5417\uFF1F",
  "chat.skill.message.priorityLength": "\u786E\u4FDD\u4F18\u5148\u7EA7\u4E0D\u5927\u4E8E5\u4F4D\u3002",
  "chat.skill.message.saveSuccess": "\u6280\u80FD\u4FDD\u5B58\u6210\u529F\uFF01",
  "chat.skill.message.failDeleteSkill": "\u5220\u9664\u6280\u80FD\u5931\u8D25\uFF01",
  "chat.skill.message.completeInputs": "\u8BF7\u5B8C\u6210\u6240\u6709\u8F93\u5165\u3002",
  "chat.skill.message.isExisted": "\u8BE5\u6280\u80FD\u540D\u79F0\u5DF2\u5B58\u5728\u3002",
  "chat.skill.message.saveFail": "\u6280\u80FD\u4FDD\u5B58\u5931\u8D25\uFF01",
  "chat.skill.message.createSuccess": "\u6280\u80FD\u521B\u5EFA\u6210\u529F\uFF01",
  "chat.skill.message.selectSkills": "\u8BF7\u9009\u62E9\u6280\u80FD\uFF01",
  "chat.skill.message.willDeleteSkills": "\u786E\u5B9A\u5220\u9664\u6240\u9009\u7684\u6280\u80FD\u5417\uFF1F",
  "chat.skill.message.willDeleteAllSkills": "\u786E\u5B9A\u5220\u9664\u6240\u6709\u7684\u6280\u80FD\u5417\uFF1F",

  "chat.skill.message.createFail": "\u6280\u80FD\u521B\u5EFA\u5931\u8D25\uFF01",

  "chat.skill.label.createSkillTab": "\u521B\u5EFA\u6280\u80FD",
  "chat.usefulexpressions.label.createExpressionTab": "\u521B\u5EFA\u5E38\u7528\u8BED",

  "chat.usefulexpressions.label.expression": "\u5E38\u7528\u8BED",
  "chat.usefulexpressions.label.expressionDetail": "\u5E38\u7528\u8BED\u8BE6\u60C5",
  "chat.usefulexpressions.message.willDeleteExpression": "\u786E\u5B9A\u5220\u9664\u8BE5\u5E38\u7528\u8BED\u5417\uFF1F",

  "chat.usefulexpressions.message.failDeleteExpression": "\u5220\u9664\u5E38\u7528\u8BED\u5931\u8D25\uFF01",
  "chat.usefulexpressions.message.saveFail": "\u5E38\u7528\u8BED\u4FDD\u5B58\u5931\u8D25\uFF01",
  "chat.usefulexpressions.message.saveSuccess": "\u5E38\u7528\u8BED\u4FDD\u5B58\u6210\u529F\uFF01",
  "chat.usefulexpressions.message.createSuccess": "\u5E38\u7528\u8BED\u521B\u5EFA\u6210\u529F\uFF01",
  "chat.usefulexpressions.message.isExisted": "\u8BE5\u5E38\u7528\u8BED\u540D\u79F0\u5DF2\u5B58\u5728\u3002",
  "chat.usefulexpressions.message.createFail": "\u5E38\u7528\u8BED\u521B\u5EFA\u5931\u8D25\uFF01",
  "chat.usefulexpressions.message.selectExpressions": "\u8BF7\u9009\u62E9\u5E38\u7528\u8BED\uFF01",
  "chat.usefulexpressions.message.willDeleteExpressions": "\u786E\u5B9A\u5220\u9664\u6240\u9009\u7684\u5E38\u7528\u8BED\u5417\uFF1F",

  "chat.usefulexpressions.message.contentNotNull": "\u5E38\u7528\u8BED\u4E0D\u53EF\u4E3A\u7A7A\uFF01",
  "chat.usefulexpressions.message.contentLength": "\u786E\u4FDD\u5E38\u7528\u8BED\u957F\u5EA6\u4E0D\u5927\u4E8E1000\uFF01",


  "chat.session.label.created_startTime": "\u5F00\u59CB\u65F6\u95F4",
  "chat.session.label.created_endTime": "\u7ED3\u675F\u65F6\u95F4",
  "chat.session.label.alias": "\u5BA2\u6237\u540D\u79F0",
  "chat.session.label.servedAgent": "\u670D\u52A1\u5EA7\u5E2D",

  "chat.session.label.channel": "\u4F1A\u8BDD\u63A5\u5165\u6E20\u9053",
  "chat.session.label.sessionId": "\u4F1A\u8BDDID",
  "chat.session.label.endType": "\u4F1A\u8BDD\u7ED3\u675F\u7C7B\u578B",
  "chat.session.label.endTime": "\u4F1A\u8BDD\u7ED3\u675F\u65F6\u95F4",
  "chat.session.label.createdTime": "\u4F1A\u8BDD\u521B\u5EFA\u65F6\u95F4",
  "chat.session.label.evaluationTime": "\u4F1A\u8BDD\u8BC4\u4EF7\u65F6\u95F4",
  "chat.session.label.subChannel": "\u5B50\u63A5\u5165\u6E20\u9053",
  "chat.session.label.evaluation": "\u4F1A\u8BDD\u8BC4\u4EF7",
  "chat.session.label.skillQueueId": "\u6280\u80FD\u961F\u5217ID",
  "chat.session.label.skillQueueName": "\u6280\u80FD\u961F\u5217\u540D\u79F0",
  "chat.session.label.remark": "\u8BC4\u8BBA",
  "chat.session.label.enterQueueTime": "\u5165\u961F\u5217\u65F6\u95F4",
  "chat.session.label.endReason": "\u4F1A\u8BDD\u7ED3\u675F\u539F\u56E0",
  "chat.session.label.exitQueueTime": "\u51FA\u961F\u5217\u65F6\u95F4",
  "chat.session.title.detail": "\u4F1A\u8BDD\u8BE6\u7EC6\u4FE1\u606F",
  "chat.session.title.history": "\u5386\u53F2\u804A\u5929\u8BB0\u5F55",
  "chat.session.label.search": "\u641C\u7D22",
  "chat.session.title.basic": "\u4F1A\u8BDD\u57FA\u7840\u4FE1\u606F",
  "chat.session.label.allEndType": "\u6240\u6709\u7ED3\u675F\u7C7B\u578B",
  "chat.session.label.reset": "\u91CD\u7F6E",
  "chat.session.label.allChannel": "\u6240\u6709\u63A5\u5165\u6E20\u9053",
  "chat.webconfig.title": "\u542F\u7528Web\u6E20\u9053",
  "chat.webconfig.message.configdirective": "\u5C06\u4E0B\u9762\u4E00\u6BB5\u4EE3\u7801\u590D\u5236\u5E76\u7C98\u8D34\u5230\u60A8\u7684\u7F51\u7AD9</body>\u6807\u7B7E\u4E4B\u524D\uFF0C\u4FDD\u5B58\u5E76\u53D1\u5E03\uFF0C\u5373\u53EF\u5B8C\u6210Huawei Service Cloud\u7684\u5FEB\u901F\u63A5\u5165\uFF0C\u53D1\u5E03\u540E\u60A8\u7684\u7F51\u7AD9\u53F3\u4E0B\u89D2\u5C06\u4F1A\u51FA\u73B0Huawei Service Cloud\u5165\u53E3\uFF0C\u70B9\u51FB\u53EF\u4EE5\u4E0E\u5BA2\u670D\u5DE5\u4F5C\u53F0\u8FDB\u884C\u5B9E\u65F6\u5BF9\u8BDD\u3002",
  "chat.webconfig.label.intergratemsg": "\u96C6\u6210Live Chat\u5230\u60A8\u7684Web\u7F51\u7AD9",
  "chat.webconfig.label.intergratedcode": "\u96C6\u6210\u4EE3\u7801",
  "chat.webconfig.label.functionalsettings": "\u529F\u80FD\u8BBE\u7F6E",
  "chat.webconfig.label.basesettings": "\u57FA\u672C\u914D\u7F6E",
  "chat.webconfig.label.bandingskill": "\u7ED1\u5B9A\u6280\u80FD\u961F\u5217",
  "chat.webconfig.label.language": "\u9ED8\u8BA4\u8BED\u79CD",
  "chat.webconfig.label.transHuman": "\u8F6C\u4EBA\u5DE5\u5BA2\u670D",
  "chat.webconfig.label.windowTitle.placeholder": "\u8BF7\u8F93\u5165\u7528\u4E8E\u663E\u793A\u5728\u5BA2\u6237\u7AEF\u4EA4\u8C08\u7A97\u53E3\u7684\u6807\u9898\u5185\u5BB9",
  "chat.webconfig.label.windowTitle": "\u7F51\u9875\u6807\u9898",
  "chat.webconfig.label.greeting.placeholder": "\u8BF7\u8F93\u5165\u7528\u4E8E\u663E\u793A\u5728\u5BA2\u6237\u7AEF\u4EA4\u8C08\u7A97\u53E3\u7684\u6B22\u8FCE\u8BED\u5185\u5BB9",
  "chat.webconfig.button.save": "\u4FDD\u5B58",
  "chat.webconfig.label.greeting": "\u6B22\u8FCE\u8BED",
  "chat.webconfig.message.saveSuccess": "\u914D\u7F6E\u4FDD\u5B58\u6210\u529F\uFF01",
  "chat.session.endType.customerEnd": "\u5BA2\u6237\u7ED3\u675F",
  "chat.webconfig.message.saveFail": "\u914D\u7F6E\u4FDD\u5B58\u5931\u8D25\uFF01",
  "chat.webconfig.message.demodirective": "\u60A8\u4E5F\u53EF\u4EE5\u5728Chrome\u6D4F\u89C8\u5668\u7684\u9690\u8EAB\u7A97\u53E3\u4E2D\u6253\u5F00\u5982\u4E0B\u5730\u5740\uFF0C\u8BBF\u95EE\u7CFB\u7EDF\u63D0\u4F9B\u7684\u6F14\u793A\u754C\u9762\u3002",

  "chat.session.endType.agentEnd": "\u5EA7\u5E2D\u7ED3\u675F",
  "chat.session.endType.overtimeEnd": "\u8D85\u65F6\u7ED3\u675F",
  "chat.session.channel.all": "\u5168\u90E8",
  "chat.session.channel.web": "\u7F51\u9875",

  "chat.session.channel.wechat": "\u5FAE\u4FE1",
  "chat.session.channel.mobile": "\u79FB\u52A8\u7EC8\u7AEF",
  "chat.session.channel.twitter": "\u63A8\u7279",
  "chat.session.channel.facebook": "\u8138\u4E66",
  "chat.weChatConfig.title.deleteTitle": "\u5220\u9664\u5FAE\u4FE1\u914D\u7F6E\u9879",
  "chat.weChatConfig.label.tenantId": "\u79DF\u6237ID",

  "chat.weChatConfig.label.state": "\u72B6\u6001",
  "chat.weChatConfig.label.search": "\u641C\u7D22",
  "chat.weChatConfig.label.delete": "\u5220\u9664",
  "chat.weChatConfig.message.ensureDelete": "\u786E\u8BA4\u5220\u9664\u9009\u4E2D\u7684\u914D\u7F6E\u9879\u5417\uFF1F",
  "chat.weChatConfig.label.create": "\u65B0\u589E",
  "chat.weChatConfig.message.deleteError": "\u5220\u9664\u914D\u7F6E\u9879\u5931\u8D25!",
  "chat.weChatConfig.message.emptyDeleteArray": "\u8BF7\u9009\u4E2D\u5F85\u5220\u9664\u914D\u7F6E\u9879\uFF01",
  "chat.weChatConfig.title": "\u793E\u4EA4\u5A92\u4F53\u5F00\u901A\u914D\u7F6E",
  "chat.weChatConfig.stepname.selectchannel": "\u9009\u62E9\u63A5\u5165\u6E20\u9053",
  "chat.weChatConfig.message.error": "\u9519\u8BEF",
  "chat.weChatConfig.stepname.selectmode": "\u9009\u62E9\u914D\u7F6E\u65B9\u6CD5",
  "chat.weChatConfig.stepname.success": "\u63A5\u5165\u6210\u529F",
  "chat.weChatConfig.label.codeName": "\u6E20\u9053\u63A5\u5165\u7F16\u7801",
  "chat.weChatConfig.label.secret": "\u79D8\u94A5\u6258\u7BA1",
  "chat.weChatConfig.stepname.verify": "\u63D0\u4EA4\u9A8C\u8BC1",
  "chat.weChatConfig.link.wechat": "\u5FAE\u4FE1\u540E\u53F0",
  "chat.weChatConfig.label.nextStep": "\u4E0B\u4E00\u6B65",
  "chat.weChatConfig.label.qcode": "\u626B\u7801\u6258\u7BA1",
  "chat.weChatConfig.label.appId": "\u5F00\u53D1\u8005ID",
  "chat.weChatConfig.label.AppSecret": "\u5F00\u53D1\u8005\u5BC6\u7801",
  "chat.weChatConfig.label.bindSkill": "\u7ED1\u5B9A\u6280\u80FD\u961F\u5217",
  "chat.weChatConfig.label.Domain": "\u670D\u52A1\u5668\u5730\u5740",
  "chat.weChatConfig.label.verifycode": "\u4EE4\u724C",
  "chat.weChatConfig.label.verifying": "\u6B63\u5728\u9A8C\u8BC1",
  "chat.weChatConfig.label.save": "\u4FDD\u5B58",
  "chat.weChatConfig.label.verify": "\u9A8C\u8BC1",
  "chat.weChatConfig.message.savesuccess": "\u63A5\u5165\u6210\u529F\uFF01",
  "chat.weChatConfig.message.codeNameExisted": "\u6E20\u9053\u63A5\u5165\u7F16\u7801\u5DF2\u5B58\u5728\uFF01",
  "chat.weChatConfig.label.configId": "\u914D\u7F6EID",
  "chat.weChatConfig.label.facebook": "\u8138\u4E66",
  "chat.weChatConfig.label.wechat": "\u5FAE\u4FE1",
  "chat.weChatConfig.message.unknown": "\u9A8C\u8BC1\u72B6\u6001\u672A\u77E5\uFF01",
  "chat.weChatConfig.message.skillExisted": "\u9009\u62E9\u7684\u6280\u80FD\u961F\u5217\u5DF2\u7ED1\u5B9A\u5176\u5B83\u516C\u4F17\u53F7\uFF01",
  "chat.weChatConfig.message.appIdExisted": "\u8F93\u5165\u7684\u516C\u4F17\u53F7\u5F00\u53D1\u8005ID\u5DF2\u5B58\u5728\uFF01",

  "chat.timerange.label.oneday": "\u6700\u8FD11\u5929",
  "chat.timerange.label.threedays": "\u6700\u8FD13\u5929",
  "chat.timerange.label.alldays": "\u6240\u6709\u521B\u5EFA\u65F6\u95F4",
  "chat.timerange.label.ok": "\u786E\u8BA4",
  "chat.timerange.label.sevendays": "\u6700\u8FD17\u5929",
  "chat.timerange.label.thirtydays": "\u6700\u8FD130\u5929",
  "chat.timerange.label.customizes": "\u81EA\u5B9A\u4E49",
  "chat.timerange.message.timeempty": "\u9009\u62E9\u7684\u65F6\u95F4\u4E0D\u80FD\u4E3A\u7A7A\u3002",
  "chat.timerange.message.timeerror": "\u9009\u62E9\u7684\u65F6\u95F4\u683C\u5F0F\u9519\u8BEF\u3002",
  "chat.agentConsole.message.content.audio": "[\u8BED\u97F3\u6D88\u606F]",


  "chat.agentConsole.message.content.locate": "[\u4F4D\u7F6E\u6D88\u606F]",
  "chat.agentConsole.message.error.notification": "!!!\u65E0\u6CD5\u521B\u5EFA\u65B0\u6D88\u606F\u63D0\u9192\uFF0C\u8BF7\u68C0\u67E5\u6D4F\u89C8\u5668\u914D\u7F6E!!!",
  "chat.agentConsole.message.content.image": "[\u56FE\u7247\u6D88\u606F]",
  "chat.agentConsole.message.error.emptyMessage": "\u3010\u6D88\u606F\u5305\u542B\u65E0\u53EF\u663E\u793A\u5185\u5BB9\u3011",
  "chat.agentConsole.message.content.connectMessage": "\u60A8\u6709\u4E00\u6761\u65B0\u7684\u6D88\u606F\uFF01",
  "provision.common.title.tipsInfo": "\u63D0\u793A",
  "chat.agentConsole.message.content.video": "[\u89C6\u9891\u6D88\u606F]",
  "provision.callcenterinstanceslist.table.callcenterinstanceslist": "\u547C\u53EB\u4E2D\u5FC3\u5217\u8868",
  "provision.callcenterinstanceslist.table.ccName": "\u547C\u53EB\u4E2D\u5FC3\u8282\u70B9",

  "provision.callcenterinstanceslist.table.ccId": "\u547C\u53EB\u4E2D\u5FC3\u7F16\u53F7",
  "provision.callcenterinstanceslist.table.wasport": "\u914D\u7F6E\u670D\u52A1\u5668\u7AEF\u53E3\u53F7",
  "provision.callcenterinstanceslist.table.gwIp": "CC-Gateway IP\u5730\u5740",
  "provision.callcenterinstanceslist.table.gwPort": "CC-Gateway \u7AEF\u53E3\u53F7",
  "provision.callcenterinstanceslist.table.agentMaxCapacity": "\u5EA7\u5E2D\u7CFB\u7EDF\u5BB9\u91CF",
  "provision.callcenterinstanceslist.table.curCapacityProportion": "\u5B9E\u9645\u5BB9\u91CF\u6BD4\u4F8B",
  "provision.callcenterinstanceslist.table.agentUseRate": "\u8BED\u97F3\u5EA7\u5E2D\u4F7F\u7528\u7387",
  "provision.callcenterinstanceslist.table.operate": "\u64CD\u4F5C",
  "provision.callcenterinstanceslist.table.create": "\u65B0\u589E",
  "provision.callcenterinstanceslist.table.delete": "\u5220\u9664",
  "provision.callcenterinstanceslist.table.update": "\u7F16\u8F91",

  "provision.callcenterinstanceslist.table.search": "\u67E5\u8BE2",
  "provision.callcenterinstanceslist.table.refresh": "\u5237\u65B0",
  "provision.callcenterinstanceslist.table.deleteConfimMessage": "\u662F\u5426\u786E\u5B9A\u5220\u9664\u5DF2\u9009\u62E9\u547C\u53EB\u4E2D\u5FC3\u53CA\u76F8\u5173\u914D\u7F6E\uFF1F",
  "provision.callcenterinstanceslist.table.synchronize": "\u540C\u6B65",
  "provision.callcenterinstanceslist.table.obssyncConfimMessage": "\u662F\u5426\u786E\u5B9A\u540C\u6B65OBS\u76F8\u5173\u914D\u7F6E\uFF1F",
  "provision.callcenterinstanceslist.table.updateFault": "\u662F\u5426\u786E\u5B9A\u8BBE\u7F6E\u865A\u62DF\u547C\u53EB\u4E2D\u5FC3\u6545\u969C\u63D0\u793A",
  "provision.callcenterinstanceslist.table.deleteconfim": "\u5220\u9664\u786E\u8BA4",
  "provision.callcenterinstanceslist.table.obssyncconfim": "\u540C\u6B65\u786E\u8BA4",
  "provision.callcenterinstanceslist.table.hasVcallcenterConfim": "\u5DF2\u7ECF\u914D\u7F6E\u4E86\u865A\u62DF\u547C\u53EB\u4E2D\u5FC3\u7684\u547C\u53EB\u4E2D\u5FC3\u65E0\u6CD5\u88AB\u5220\u9664",
  "provision.callcenterinstanceslist.table.nodepolicyconfirmmessage": "\u662F\u5426\u786E\u5B9A\u540C\u6B65Node Policy\u76F8\u5173\u914D\u7F6E\uFF1F",
  "provision.callcenterinstanceslist.table.hasaccesscodeAndivrConfim": "\u5220\u9664\u5931\u8D25\uFF0C\u547C\u53EB\u4E2D\u5FC3\u4E2D\u5B58\u5728\u63A5\u5165\u7801\u6216\u8005IVR\uFF0C\u9700\u624B\u52A8\u5220\u9664",
  "provision.callcenterinstanceslist.table.agentCurCapacity": "\u8BED\u97F3\u5EA7\u5E2D\u6570",
  "provision.callcenterinstanceslist.table.resourceinfo": "\u8D44\u6E90\u4FE1\u606F",
  "provision.callcenterinstanceslist.table.maxcallnums": "\u6700\u5927\u8BED\u97F3\u547C\u53EB\u5E76\u53D1\u6570",
  "provision.callcenterinstanceslist.table.videoAgentCurCapacity": "\u89C6\u9891\u5EA7\u5E2D\u6570",
  "provision.callcenterinstanceslist.table.maxVideoCallnums": "\u6700\u5927\u89C6\u9891\u547C\u53EB\u5E76\u53D1\u6570",
  "provision.callcenterinstanceslist.table.ivrchannels": "\u97F3\u9891IVR\u901A\u9053\u6570",
  "provision.callcenterinstanceslist.table.wasip": "\u914D\u7F6E\u670D\u52A1\u5668IP\u5730\u5740",
  "provision.callcenterinstanceslist.table.videoIvrChannels": "\u89C6\u9891IVR\u901A\u9053\u6570",
  "provision.callcenterinstanceslist.table.videoAgentChannels": "\u89C6\u9891\u5EA7\u5E2D\u901A\u9053\u6570",
  "provision.callcenterinstanceslist.table.ttschannelnums": "TTS\u6570",
  "provision.callcenterinstanceslist.table.baseinfo": "\u57FA\u672C\u4FE1\u606F",
  "provision.callcenterinstanceslist.table.eMediaAgentCurCapacity": "\u591A\u5A92\u4F53\u5EA7\u5E2D\u6570",
  "provision.callcenterinstanceslist.table.nodemodeselect": "\u9009\u62E9\u6A21\u5F0F",
  "provision.callcenterinstanceslist.table.ctinodefir": "CTI\u8282\u70B9\u4E00\u4FE1\u606F",
  "provision.callcenterinstanceslist.table.nodeid": "\u8282\u70B9ID",
  "provision.callcenterinstanceslist.table.asrchannelnums": "ASR\u6570",
  "provision.callcenterinstanceslist.table.nodemodesingle": "Single\u6A21\u5F0F",
  "provision.callcenterinstanceslist.table.ctinodesec": "CTI\u8282\u70B9\u4E8C\u4FE1\u606F",
  "provision.callcenterinstanceslist.table.uapId": "UAP ID",
  "provision.callcenterinstanceslist.table.nodemoderesource": "\u8D44\u6E90\u6A21\u5F0F",
  "provision.callcenterinstanceslist.table.nodemodepool": "Pool\u6A21\u5F0F",
  "provision.callcenterinstanceslist.table.nodename": "\u8282\u70B9\u540D\u79F0",
  "provision.callcenterinstanceslist.table.maxAmsCallNums": "\u6700\u5927\u533F\u540D\u547C\u53EB\u5E76\u53D1\u6570",
  "provision.callcenterinstanceslist.table.allowedTTSAndASRCallNum": "\u667A\u80FDIVR\u901A\u9053\u6570",
  "provision.callcenterinstanceslist.table.versatileAgentCurCapacity": "\u5168\u80FD\u578B\u5EA7\u5E2D\u6570",
  "provision.callcenterinstanceslist.label.maxcallnums": "\u8BED\u97F3\u5E76\u53D1\u547C\u53EB\u6570",
  "provision.callcenterinstanceslist.label.callnumUseRate": "\u8BED\u97F3\u5E76\u53D1\u547C\u53EB\u4F7F\u7528\u7387",
  "provision.callcenterinstanceslist.label.videoAgentCurCapacity": "\u89C6\u9891\u5EA7\u5E2D\u6570",
  "provision.callcenterinstanceslist.label.videoAgentUseRate": "\u89C6\u9891\u5EA7\u5E2D\u4F7F\u7528\u7387",
  "provision.callcenterinstanceslist.label.eMediaAgentUseRate": "\u591A\u5A92\u4F53\u5EA7\u5E2D\u4F7F\u7528\u7387",
  "provision.callcenterinstanceslist.label.eMediaAgentCurCapacity": "\u591A\u5A92\u4F53\u5EA7\u5E2D\u6570",
  "provision.callcenterinstanceslist.label.videoCallnumUseRate": "\u89C6\u9891\u5E76\u53D1\u547C\u53EB\u4F7F\u7528\u7387",
  "provision.callcenterinstanceslist.label.maxVideoCallnums": "\u89C6\u9891\u5E76\u53D1\u547C\u53EB\u6570",
  "provision.callcenterinstanceslist.label.ivrchannelUseRate": "\u97F3\u9891IVR\u901A\u9053\u4F7F\u7528\u7387",

  "provision.callcenterinstanceslist.label.videoIvrChannelUseRate": "\u89C6\u9891IVR\u901A\u9053\u4F7F\u7528\u7387",
  "provision.callcenterinstanceslist.label.videoAgentChannelUseRate": "\u89C6\u9891\u5EA7\u5E2D\u901A\u9053\u4F7F\u7528\u7387",
  "provision.callcenterinstanceslist.label.ttschannelUseRate": "TTS\u4F7F\u7528\u7387",
  "provision.callcenterinstanceslist.label.maxAmsCallNums": "\u533F\u540D\u5E76\u53D1\u547C\u53EB\u6570",

  "provision.callcenterinstanceslist.label.amsCallNumUseRate": "\u533F\u540D\u5E76\u53D1\u547C\u53EB\u4F7F\u7528\u7387",
  "provision.callcenterinstanceslist.config.warning": "\u8B66\u544A",
  "provision.callcenterinstanceslist.config": "\u547C\u53EB\u4E2D\u5FC3",
  "provision.callcenterinstanceslist.callcenter": "\u547C\u53EB\u4E2D\u5FC3",
  "provision.callcenterinstanceslist.config.title": "\u547C\u53EB\u4E2D\u5FC3\u914D\u7F6E",
  "provision.callcenterinstanceslist.config.serviceIP": "Nginx IP\u5730\u5740",
  "provision.callcenterinstanceslist.config.servicePort": "Nginx\u7AEF\u53E3\u53F7",
  "provision.callcenterinstanceslist.label.asrchannelUseRate": "ASR\u4F7F\u7528\u7387",
  "provision.callcenterinstanceslist.config.nmsservicePort": "ODFS\u7AEF\u53E3\u53F7",
  "provision.callcenterinstanceslist.config.odfsappkey": "ODFS\u9274\u6743\u8D26\u53F7",
  "provision.callcenterinstanceslist.config.odfsappsercret": "ODFS\u9274\u6743\u5BC6\u7801",
  "provision.callcenterinstanceslist.config.nmsserviceIP": "ODFS IP\u5730\u5740",
  "provision.callcenterinstanceslist.config.odfsvalidate": "ODFS\u670D\u52A1\u914D\u7F6E\u5E94\u5168\u90E8\u4E3A\u7A7A\u6216\u5168\u4E0D\u4E3A\u7A7A",
  "provision.callcenterinstanceslist.config.startworkno": "\u5DE5\u53F7\u8D77\u59CB\u53F7\u7801",
  "provision.callcenterinstanceslist.config.odfconfigerror": "ODFS\u670D\u52A1\u914D\u7F6E\u4FE1\u606F\u9519\u8BEF",
  "provision.callcenterinstanceslist.config.agentUsage": "\u5DF2\u5206\u914D\u7684\u5EA7\u5E2D\u6570",
  "provision.callcenterinstanceslist.config.SysAdminAccount": "\u7CFB\u7EDF\u7BA1\u7406\u5458\u8D26\u53F7",
  "provision.callcenterinstanceslist.config.wnFrom": "\u5DE5\u53F7\u8D77\u59CB\u53F7\u7801",
  "provision.callcenterinstanceslist.config.odfsconfig": "ODFS\u670D\u52A1\u914D\u7F6E",
  "provision.callcenterinstanceslist.config.adminWnFrom": "\u7BA1\u7406\u5458\u8D77\u59CB\u5DE5\u53F7",
  "provision.callcenterinstanceslist.config.adminWnTo": "\u7BA1\u7406\u5458\u7ED3\u675F\u5DE5\u53F7",
  "provision.callcenterinstanceslist.config.sysAdminpassword": "CTI\u7CFB\u7EDF\u7BA1\u7406\u5458\u5BC6\u7801",
  "provision.callcenterinstanceslist.config.interfacepassword": "CTI\u914D\u7F6E\u63A5\u53E3\u5BC6\u7801",
  "provision.callcenterinstanceslist.config.passwordConfirm": "\u5BC6\u7801\u786E\u8BA4",
  "provision.callcenterinstanceslist.config.differentPassword": "\u4E24\u6B21\u8F93\u5165\u7684\u5BC6\u7801\u4E0D\u4E00\u81F4",
  "provision.callcenterinstanceslist.config.previousStep": "\u4E0A\u4E00\u6B65",
  "provision.callcenterinstanceslist.config.saveAndnextStep": "\u4FDD\u5B58\u5E76\u4E0B\u4E00\u6B65",
  "provision.callcenterinstanceslist.config.nextStep": "\u4E0B\u4E00\u6B65",
  "provision.callcenterinstanceslist.config.systemAdmin": "CTI\u7CFB\u7EDF\u7BA1\u7406\u5458\u5DE5\u53F7",
  "provision.callcenterinstanceslist.config.createsuccess": "\u547C\u53EB\u4E2D\u5FC3\u521B\u5EFA\u6210\u529F",
  "provision.callcenterinstanceslist.config.success": "\u6210\u529F",
  "provision.callcenterinstanceslist.config.updatesuccess": "\u547C\u53EB\u4E2D\u5FC3\u4FEE\u6539\u6210\u529F",
  "provision.callcenterinstanceslist.config.error": "\u9519\u8BEF",
  "provision.callcenterinstanceslist.config.createfailed": "\u547C\u53EB\u4E2D\u5FC3\u521B\u5EFA\u5931\u8D25",
  "provision.callcenterinstanceslist.config.updatefailed": "\u547C\u53EB\u4E2D\u5FC3\u4FEE\u6539\u5931\u8D25",
  "provision.callcenterinstanceslist.config.wnFromValidate": "\u5DE5\u53F7\u8D77\u59CB\u53F7\u6BB5\u4E0E\u7CFB\u7EDF\u5B9E\u9645\u5BB9\u91CF\u7684\u548C\u4E0D\u5F97\u5927\u4E8E50000",
  "provision.callcenterinstanceslist.config.wnTo": "\u5DE5\u53F7\u7ED3\u675F\u53F7\u7801",
  "provision.callcenterinstanceslist.config.alreadyinuse": "\u5DE5\u53F7\u53F7\u6BB5\u88AB\u5360\u7528",
  "provision.callcenterinstanceslist.config.ccNameUsed": "\u547C\u53EB\u4E2D\u5FC3\u540D\u79F0\u5DF2\u88AB\u4F7F\u7528",
  "provision.callcenterinstanceslist.config.maxcallnumsValidate": "\u6700\u5927\u8BED\u97F3\u547C\u53EB\u5E76\u53D1\u6570\u5C0F\u4E8E\u547C\u53EB\u4E2D\u5FC3\u5B9E\u9645\u5DF2\u5206\u914D\u7684\u8BED\u97F3\u547C\u53EB\u6570",
  "provision.callcenterinstanceslist.config.agentCurCapacityValidate": "\u8BED\u97F3\u5EA7\u5E2D\u6570\u5C0F\u4E8E\u547C\u53EB\u4E2D\u5FC3\u5B9E\u9645\u5DF2\u5206\u914D\u7684\u8BED\u97F3\u5EA7\u5E2D\u6570",
  "provision.callcenterinstanceslist.config.videoAgentCurCapacityValidate": "\u89C6\u9891\u5EA7\u5E2D\u6570\u5C0F\u4E8E\u547C\u53EB\u4E2D\u5FC3\u5B9E\u9645\u5DF2\u5206\u914D\u7684\u89C6\u9891\u5EA7\u5E2D\u6570",
  "provision.callcenterinstanceslist.config.maxVideoCallnumsValidate": "\u6700\u5927\u89C6\u9891\u547C\u53EB\u5E76\u53D1\u6570\u5C0F\u4E8E\u547C\u53EB\u4E2D\u5FC3\u5B9E\u9645\u5DF2\u5206\u914D\u7684\u89C6\u9891\u547C\u53EB\u6570",
  "provision.callcenterinstanceslist.config.asrchannelnumsValidate": "ASR\u901A\u9053\u6570\u5C0F\u4E8E\u547C\u53EB\u4E2D\u5FC3\u5B9E\u9645\u5DF2\u5206\u914D\u901A\u9053\u6570",
  "provision.callcenterinstanceslist.config.videoAgentChannelsValidate": "\u89C6\u9891\u5EA7\u5E2D\u901A\u9053\u6570\u5C0F\u4E8E\u547C\u53EB\u4E2D\u5FC3\u5B9E\u9645\u5DF2\u5206\u914D\u901A\u9053\u6570",
  "provision.callcenterinstanceslist.config.ivrchannelsValidate": "\u97F3\u9891IVR\u901A\u9053\u6570\u5C0F\u4E8E\u547C\u53EB\u4E2D\u5FC3\u5B9E\u9645\u5DF2\u5206\u914D\u901A\u9053\u6570",
  "provision.callcenterinstanceslist.config.videoIvrchannelsValidate": "\u89C6\u9891IVR\u901A\u9053\u6570\u5C0F\u4E8E\u547C\u53EB\u4E2D\u5FC3\u5B9E\u9645\u5DF2\u5206\u914D\u901A\u9053\u6570",
  "provision.callcenterinstanceslist.config.eMediaAgentCurCapacityValidate": "\u591A\u5A92\u4F53\u5EA7\u5E2D\u6570\u5C0F\u4E8E\u547C\u53EB\u4E2D\u5FC3\u5B9E\u9645\u5DF2\u5206\u914D\u591A\u5A92\u4F53\u5EA7\u5E2D\u6570",
  "provision.callcenterinstanceslist.config.resourceError": "\u4FEE\u6539\u7684\u8D44\u6E90\u4FE1\u606F\u5C0F\u4E8E\u547C\u53EB\u4E2D\u5FC3\u5B9E\u9645\u5206\u914D\u6570",
  "provision.callcenterinstanceslist.config.ccIdError": "\u547C\u53EB\u4E2D\u5FC3\u7F16\u53F7\u5DF2\u88AB\u4F7F\u7528",
  "provision.callcenterinstanceslist.config.ttschannelnumsValidate": "TTS\u901A\u9053\u6570\u5C0F\u4E8E\u547C\u53EB\u4E2D\u5FC3\u5B9E\u9645\u5DF2\u5206\u914D\u901A\u9053\u6570",
  "provision.callcenterinstanceslist.config.videocallnumsError": "\u6700\u5927\u89C6\u9891\u547C\u53EB\u5E76\u53D1\u6570\u5FC5\u987B\u5927\u4E8E\u7B49\u4E8E\u89C6\u9891\u5EA7\u5E2D\u6570",
  "provision.callcenterinstanceslist.config.ivrnumsError": "IVR\u901A\u9053\u6570\u5FC5\u987B\u5927\u4E8ETTS\u901A\u9053\u6570\u4E0EASR\u901A\u9053\u6570\u4E4B\u548C",
  "provision.callcenterinstanceslist.config.voicecallnumsError": "\u6700\u5927\u8BED\u97F3\u547C\u53EB\u5E76\u53D1\u6570\u5FC5\u987B\u5927\u4E8E\u6216\u7B49\u4E8E\u8BED\u97F3\u5EA7\u5E2D\u6570\u4E0E\u97F3\u89C6\u9891IVR\u901A\u9053\u6570\u4E4B\u548C",
  "provision.callcenterinstanceslist.config.ccNameUpdateError": "\u7F16\u8F91\u547C\u53EB\u4E2D\u5FC3\u65F6\uFF0C\u547C\u53EB\u4E2D\u5FC3\u540D\u79F0\u7981\u6B62\u4FEE\u6539",
  "provision.callcenterinstanceslist.config.adminWorkNoError": "\u7BA1\u7406\u5458\u5DE5\u53F7\u8D77\u59CB\u53F7\u7801\u5FC5\u987B\u572855001\u523059999\u8303\u56F4\u5185",
  "provision.callcenterinstanceslist.config.workNoError": "\u5DE5\u53F7\u8D77\u59CB\u53F7\u7801\u5FC5\u987B\u5728101\u523050000\u8303\u56F4\u5185",
  "provision.callcenterinstanceslist.config.workAlreadyOccupied": "\u5DE5\u53F7\u5DF2\u5360\u7528",
  "provision.callcenterinstanceslist.config.adminWorkAlreadyOccupied": "\u7BA1\u7406\u5458\u5DE5\u53F7\u5DF2\u5360\u7528",
  "provision.callcenterinstanceslist.config.workNoCheckError": "\u5DE5\u53F7\u8D77\u59CB\u53F7\u7801\u5FC5\u987B\u5C0F\u4E8E\u7ED3\u675F\u53F7\u7801",
  "provision.callcenterinstanceslist.config.nodeIdCheckError": "\u8BF7\u786E\u4FDD\u8F93\u5165\u7684CTI\u8282\u70B9\u4FE1\u606Fid\u3001uapId\u4E0E\u540D\u79F0\u4E0D\u91CD\u590D",

  "provision.callcenterinstanceslist.config.ccIdUpdateError": "\u7F16\u8F91\u547C\u53EB\u4E2D\u5FC3\u65F6\uFF0C\u547C\u53EB\u4E2D\u5FC3\u7F16\u53F7\u7981\u6B62\u4FEE\u6539",
  "provision.callcenterinstanceslist.config.uapId": "UAP ID\u5FC5\u987B\u8981\u4E0EWAS\u5E73\u53F0\u4E0A\u7684UAP\u6807\u8BC6\u4E00\u81F4",
  "provision.callcenterinstanceslist.config.maxAmsCallNumsValidate": "\u6700\u5927\u533F\u540D\u547C\u53EB\u5E76\u53D1\u6570\u5C0F\u4E8E\u547C\u53EB\u4E2D\u5FC3\u5B9E\u9645\u5DF2\u5206\u914D\u7684\u533F\u540D\u547C\u53EB\u6570",
  "provision.callcenterinstance.config.maxcallnumsCompareValidate": "\u6700\u5927\u8BED\u97F3\u547C\u53EB\u5E76\u53D1\u6570\u5FC5\u987B\u5927\u4E8E\u6216\u7B49\u4E8E\u8BED\u97F3\u5EA7\u5E2D\u6570\u4E0E\u97F3\u89C6\u9891IVR\u901A\u9053\u6570\u4E4B\u548C",
  "provision.callcenterinstanceslist.config.confirm": "\u8BF7\u914D\u7F6EWAS\u5E73\u53F0\u76F8\u5173\u8282\u70B9\u4FE1\u606F",
  "provision.callcenterinstance.config.videoCallValidate": "\u6700\u5927\u89C6\u9891\u547C\u53EB\u5E76\u53D1\u6570\u5FC5\u987B\u5927\u4E8E\u7B49\u4E8E\u89C6\u9891\u5EA7\u5E2D\u6570",
  "provision.callcenterinstance.config.ivrchannelsValidate": "\u97F3\u9891\u4E0E\u89C6\u9891IVR\u901A\u9053\u6570\u4E4B\u548C\u5FC5\u987B\u5927\u4E8E\u7B49\u4E8ETTS\u901A\u9053\u6570\u4E0EASR\u901A\u9053\u6570\u4E4B\u548C",

  "provision.callcenterinstanceslist.vcallcenter.vccId": "\u865A\u62DF\u547C\u53EB\u4E2D\u5FC3\u7F16\u53F7",
  "provision.callcenterinstanceslist.vcallcenter.vccName": "VDN\u540D\u79F0",
  "provision.callcenterinstanceslist.vcallcenter.agentNum": "\u5DF2\u5206\u914D\u5EA7\u5E2D\u6570",
  "provision.callcenterinstanceslist.vcallcenter.accessCodeConfig": "\u63A5\u5165\u7801\u914D\u7F6E",
  "provision.callcenterinstanceslist.vcallcenter.accessCode": "\u63A5\u5165\u7801",
  "provision.callcenterinstanceslist.vcallcenter.SIPServer": "SBC\u670D\u52A1\u5668",
  "provision.callcenterinstanceslist.vcallcenter.tenantSpaceId": "\u6240\u5C5E\u79DF\u6237ID",
  "provision.callcenterinstanceslist.vcallcenter.SIPServer.title": "SBC\u670D\u52A1\u5668\u914D\u7F6E",
  "provision.callcenterinstanceslist.vcallcenter.nodepolicy": "\u547C\u51FA\u8D44\u6E90\u9009\u62E9\u8282\u70B9\u7B56\u7565\u914D\u7F6E",
  "provision.callcenterinstanceslist.vcallcenter.vcallcenter": "\u865A\u62DF\u547C\u53EB\u4E2D\u5FC3",
  "provision.callcenterinstanceslist.vcallcenter.vcallcenterDeatil": "\u865A\u62DF\u547C\u53EB\u4E2D\u5FC3\u8BE6\u60C5",
  "provision.callcenterinstanceslist.vcallcenter.OBSServer": "OBS\u5916\u547C\u670D\u52A1\u5668",

  "provision.callcenterinstanceslist.vcallcenterDeatil.vccId": "\u865A\u62DF\u547C\u53EB\u4E2D\u5FC3\u7F16\u53F7",
  "provision.callcenterinstanceslist.vcallcenterDeatil.ccInstId": "\u547C\u53EB\u4E2D\u5FC3\u7F16\u53F7",
  "provision.callcenterinstanceslist.vcallcenterDeatil.defaultCallerNum": "\u9ED8\u8BA4\u4E3B\u53EB\u53F7\u7801",
  "provision.callcenterinstanceslist.vcallcenter.ctiInfo ": " CTI\u8D44\u6E90\u7EC4ID",
  "provision.callcenterinstanceslist.vcallcenterDeatil.tenantSpaceId": "\u6240\u5C5E\u79DF\u6237ID",
  "provision.callcenterinstanceslist.vcallcenterDeatil.accessCode": "\u5206\u914D\u7ED9\u8BE5VDN\u7684\u63A5\u5165\u7801",
  "provision.callcenterinstanceslist.vcallcenterDeatil.vccName": "VDN\u540D\u79F0",
  "provision.callcenterinstanceslist.vcallcenterDeatil.vccEnabled": "VDN\u662F\u5426\u542F\u7528",
  "provision.callcenterinstanceslist.vcallcenterDeatil.agentNum": "\u5DF2\u5206\u914D\u5EA7\u5E2D\u6570",
  "provision.callcenterinstanceslist.vcallcenterDeatil.maxCalls": "\u6700\u5927\u5E76\u53D1\u547C\u53EB\u6570",
  "provision.callcenterinstanceslist.vcallcenterDeatil.configWorkNo": "\u914D\u7F6E\u7BA1\u7406\u5458\u5DE5\u53F7",
  "provision.callcenterinstanceslist.vcallcenterDeatil.password": "\u914D\u7F6E\u7BA1\u7406\u5458\u5BC6\u7801",
  "provision.callcenterinstanceslist.vcallcenterDeatil.save": "\u4FDD\u5B58",
  "provision.callcenterinstanceslist.vcallcenterDeatil.passValidateFailed": `\u5E94\u5305\u542B\u5927\u5199\u5B57\u6BCD\uFF0C\u5C0F\u5199\u5B57\u6BCD\uFF0C\u6570\u5B57\uFF0C\u7279\u6B8A\u5B57\u7B26~!{'@'}#$%^&*()-_=+{'|'}[]{'{'}{'}'};:",<.>/?\u4E2D\u7684\u4EFB\u610F\u4E09\u79CD`,
  "provision.callcenterinstanceslist.vcallcenterDeatil.saveerror": "\u4FDD\u5B58\u5931\u8D25",
  "provision.callcenterinstanceslist.vcallcenterDeatil.passLengthValidateFailed": "\u5BC6\u7801\u7684\u957F\u5EA6\u4E3A8\u4E2A\u5B57\u7B26",
  "provision.callcenterinstanceslist.vcallcenterDeatil.odfsPasswordKeyLengthValidateFailed": "\u957F\u5EA6\u4E0D\u80FD\u8D85\u8FC750\u4E2A\u5B57\u7B26",
  "provision.callcenterinstanceslist.vcallcenterDeatil.odfsStrValidateFailed": "\u53EA\u5141\u8BB8\u8F93\u5165\u82F1\u6587\u5B57\u7B26\u548C\u6570\u5B57",
  "provision.callcenterinstanceslist.vcallcenterDeatil.specialStrValidateFailed": "\u4E0D\u5141\u8BB8\u5305\u542B\u7279\u6B8A\u5B57\u7B26",
  "provision.callcenterinstanceslist.vcallcenterDeatil.sipServerIpAndPort": "SBC\u5730\u5740",
  "provision.callcenterinstanceslist.vcallcenterDeatil.whiteNumValidateFailed": "\u53EA\u5141\u8BB8\u5305\u542B\u6570\u5B57",
  "provision.callcenterinstanceslist.vcallcenterDeatil.whiteNumValidateLength": "\u767D\u540D\u5355\u53F7\u7801\u957F\u5EA6\u8303\u56F4\u4E3A1~32",
  "provision.tenantspace.commercialStatus ": " \u5546\u7528\u72B6\u6001",
  "provision.tenantspace.whiteNumber": "\u914D\u7F6E\u767D\u540D\u5355\u53F7\u7801",
  "provision.tenantspace.selectStastus.CommercialUse": "\u5546\u7528",
  "provision.tenantspace.ConversionNoncommercial": "\u8F6C\u8BD5\u5546\u7528",
  "provision.tenantspace.Conversioncommercial": "\u8F6C\u5546\u7528",
  "provision.tenantspace.selectStastus.noncommercial": "\u8BD5\u5546\u7528",
  "provision.tenantspace.config.select.commercial.status": "\u8BF7\u9009\u62E9\u5546\u7528\u72B6\u6001",
  "provision.tenantspace.config.select.default.whiteNumber": "\u9ED8\u8BA4\u767D\u540D\u5355\u53F7\u7801\u6570\u91CF\u4E0D\u80FD\u5927\u4E8E5",
  "provision.callcenterinstanceslist.whiteList.whiteNumberAdd": "\u65B0\u589E\u767D\u540D\u5355\u53F7\u7801",
  "provision.callcenterinstanceslist.whiteNumber": "\u767D\u540D\u5355\u53F7\u7801",
  "provision.tenantspace.config.select.whiteRepeate": "\u767D\u540D\u5355\u53F7\u7801\u91CD\u590D",
  "provision.tenantspace.updateTenantSpaceStatus": "\u662F\u5426\u786E\u8BA4\u4FEE\u6539\u79DF\u6237\u72B6\u6001",
  "provision.tenantspace.updateTenantSpaceStatus.confirm": "\u786E\u8BA4\u4FEE\u6539\u79DF\u6237\u72B6\u6001",
  "provision.tenantspace.deleteWhiteNumber": "\u662F\u5426\u786E\u8BA4\u5220\u9664\u767D\u540D\u5355\u53F7\u7801",
  "provision.tenantspace.chooseCommercial": "\u5546\u7528\u72B6\u6001\u9009\u62E9",
  "provision.tenantspace.deleteWhiteNumber.confirm": "\u786E\u8BA4\u5220\u9664",
  "provision.callcenterinstanceslist.softPhone.softPhoneNum": "\u8F6F\u7535\u8BDD\u53F7\u7801",
  "provision.callcenterinstanceslist.softPhone.softPhoneNum.title": "\u8F6F\u7535\u8BDD\u53F7\u7801\u914D\u7F6E",
  "provision.callcenterinstanceslist.softPhone.newpassword": "\u8F6F\u7535\u8BDD\u65B0\u5BC6\u7801",

  "provision.callcenterinstanceslist.softPhone.softPhoneAdd": "\u65B0\u589E",
  "provision.callcenterinstanceslist.softPhone.newpasswordconf": "\u91CD\u590D\u65B0\u5BC6\u7801",
  "provision.callcenterinstanceslist.softPhone.password": "\u5BC6\u7801",
  "provision.callcenterinstanceslist.softPhone.accountpassword": "\u8D26\u53F7\u5BC6\u7801",
  "provision.callcenterinstanceslist.softPhone.passwordconf": "\u91CD\u590D\u5BC6\u7801",
  "provision.callcenterinstanceslist.softPhone.modifyPassword": "\u4FEE\u6539\u5BC6\u7801",
  "provision.callcenterinstanceslist.softPhone.vcallcenterid": "\u865A\u62DF\u5316\u547C\u53EB\u4E2D\u5FC3ID",
  "provision.callcenterinstanceslist.softPhone.batchdeleteconfim": "\u662F\u5426\u786E\u5B9A\u5220\u9664\u6240\u6709\u5DF2\u9009\u62E9\u8F6F\u7535\u8BDD\u53F7\u7801\uFF1F\u8BF7\u786E\u4FDD\u8F6F\u7535\u8BDD\u53F7\u7801\u672A\u5173\u8054\u865A\u62DF\u547C\u53EB\u4E2D\u5FC3",
  "provision.callcenterinstanceslist.softPhone.tenantname": "\u79DF\u6237\u540D\u79F0",
  "provision.callcenterinstanceslist.softPhone.stautus.unoccupied": "\u672A\u5360\u7528",
  "provision.callcenterinstanceslist.softPhone.singledeleteconfim": "\u662F\u5426\u786E\u5B9A\u5220\u9664\u5DF2\u9009\u62E9\u8F6F\u7535\u8BDD\u53F7\u7801\uFF1F\u8BF7\u786E\u4FDD\u8F6F\u7535\u8BDD\u53F7\u7801\u672A\u5173\u8054\u865A\u62DF\u547C\u53EB\u4E2D\u5FC3",
  "provision.callcenterinstanceslist.softPhone.stautus.occupied": "\u5DF2\u5360\u7528",
  "provision.callcenterinstanceslist.softPhone.stautus.select": "\u8BF7\u9009\u62E9\u72B6\u6001",
  "provision.callcenterinstanceslist.softPhone.passValidateFailed": "\u5E94\u5305\u542B\u5927\u5199\u5B57\u6BCD\uFF0C\u5C0F\u5199\u5B57\u6BCD\uFF0C\u6570\u5B57\uFF0C\u7279\u6B8A\u5B57\u7B26~{'@'}^*_+.?\u4E2D\u7684\u81F3\u5C113\u79CD",
  "provision.callcenterinstanceslist.softPhone.batchadd": "\u6279\u91CF\u65B0\u589E",
  "provision.callcenterinstanceslist.softPhone.passLengthValidateFailed": "\u8F93\u5165\u7684\u5BC6\u7801\u957F\u5EA6\u5FC5\u987B\u57288\u523016\u4E4B\u95F4",
  "provision.callcenterinstanceslist.softPhone.beginNumber": "\u8D77\u59CB\u53F7\u7801",
  "provision.callcenterinstance.softPhone.validatebeginSoftPhoneNum": "\u8F6F\u7535\u8BDD\u8D77\u59CB\u53F7\u7801\u4E0D\u80FD\u5927\u4E8E\u7ED3\u675F\u53F7\u7801",
  "provision.callcenterinstance.softPhone.batchcreate.fail.repeated": "\u65B0\u589E\u53F7\u6BB5\u5185\u7684\u6240\u6709\u53F7\u7801\u5DF2\u5B58\u5728",
  "provision.callcenterinstance.softPhone.length.Inconsistent": "\u8D77\u59CB\u53F7\u7801\u548C\u7ED3\u675F\u53F7\u7801\u957F\u5EA6\u4E0D\u4E00\u81F4",

  "provision.callcenterinstanceslist.softPhone.endNumber": "\u7ED3\u675F\u53F7\u7801",
  "provision.callcenterinstanceslist.sipServer.sipServerIp": "SBC\u670D\u52A1\u5668\u5730\u5740",
  "provision.callcenterinstance.softPhone.validateSoftPhoneNumrange": "\u65B0\u589E\u53F7\u6BB5\u8303\u56F4\u4E0D\u80FD\u8D85\u8FC71000",
  "provision.callcenterinstances.agentWasPassLengthValidateFailed": "\u8F93\u5165\u7684\u5BC6\u7801\u957F\u5EA6\u5FC5\u987B\u57288\u523032\u4E4B\u95F4",
  "provision.callcenterinstanceslist.sipServer.sipServerIpAdd": "\u65B0\u589E",
  "provision.callcenterinstanceslist.sipServer.sipServerType": "SBC\u670D\u52A1\u5668\u7C7B\u578B",
  "provision.callcenterinstanceslist.sipServer.sipServerPort": "SBC\u670D\u52A1\u5668\u7AEF\u53E3\u53F7",
  "provision.callcenterinstanceslist.sipServer.maxRegAgentNum": "\u5E76\u53D1\u6570",
  "provision.callcenterinstanceslist.sipServer.idleRegAgentNum": "\u5269\u4F59\u5E76\u53D1\u6570",
  "provision.callcenterinstanceslist.sipServer.sipServerIpUpdate": "\u7F16\u8F91",
  "provision.callcenterinstanceslist.sipServer.tenantname": "\u79DF\u6237\u540D\u79F0",
  "provision.callcenterinstanceslist.sipServer.name": "\u540D\u79F0",
  "provision.callcenterinstanceslist.sipServer.batchdeleteconfim": "\u662F\u5426\u786E\u5B9A\u5220\u9664\u6240\u6709\u5DF2\u9009\u62E9SIP\u670D\u52A1\u5668\uFF1F",

  "provision.callcenterinstanceslist.sipServer.description": "\u63CF\u8FF0",
  "provision.callcenterinstanceslist.sipServer.singledeleteconfim": "\u662F\u5426\u786E\u5B9A\u5220\u9664\u5DF2\u9009\u62E9SIP\u670D\u52A1\u5668\uFF1F",
  "provision.callcenterinstanceslist.nodepolicy.tenantspacename": "VDN\u540D\u79F0",
  "provision.callcenterinstanceslist.nodepolicy.save": "\u4FDD\u5B58",
  "provision.callcenterinstanceslist.nodepolicy.VNDId": "VDN\u7F16\u53F7",
  "provision.nodepolicy.sync.success": "\u540C\u6B65\u6210\u529F",
  "provision.nodepolicy.save.success": "\u4FDD\u5B58\u6210\u529F",
  "provision.nodepolicy.sync.failed": "\u540C\u6B65\u5931\u8D25",
  "provision.callcenterinstanceslist.obsServer.obsServerIpAdd": "\u65B0\u589E",
  "provision.callcenterinstanceslist.obsServer.obsServerIpUpdate": "\u7F16\u8F91",
  "provision.nodepolicy.save.failed": "\u4FDD\u5B58\u5931\u8D25",

  "provision.callcenterinstanceslist.obsServer.obsServerIpSync": "\u540C\u6B65",
  "provision.callcenterinstanceslist.obsServer.obsName": "OBS\u540D\u79F0",
  "provision.callcenterinstanceslist.obsServer.progId": "prog ID",
  "provision.callcenterinstanceslist.obsServer.activeObsServerIp": "\u4E3B\u7528OBS IP",
  "provision.callcenterinstanceslist.obsServer.activeObsDirectHeartbeatIp": "\u4E3B\u7528OBS\u76F4\u8FDE\u5FC3\u8DF3IP",
  "provision.callcenterinstanceslist.obsServer.obsId": "OBS ID",
  "provision.callcenterinstanceslist.obsServer.standbyObsServerIp": "\u5907\u7528OBS IP",
  "provision.callcenterinstanceslist.obsServer.heartbeatPort": "\u5FC3\u8DF3\u7AEF\u53E3\u53F7",
  "provision.callcenterinstanceslist.obsServer.standbyObsDirectHeartbeatIp": "\u5907\u7528OBS\u76F4\u8FDE\u5FC3\u8DF3IP",
  "provision.callcenterinstanceslist.obsServer.config.explain": "\u9700\u4E0ECTI\u5E73\u53F0OBS\u914D\u7F6E\u4FDD\u6301\u4E00\u81F4",
  "provision.callcenterinstanceslist.obsServer.batchdeleteconfim": "\u662F\u5426\u786E\u5B9A\u5220\u9664\u6240\u6709\u5DF2\u9009\u62E9OBS\u670D\u52A1\u5668\uFF1F",
  "provision.callcenterinstanceslist.obsServer.taskRefreshPeriod": "\u4EFB\u52A1\u5237\u65B0\u5468\u671F",
  "provision.callcenterinstanceslist.obsServer.singledeleteconfim": "\u662F\u5426\u786E\u5B9A\u5220\u9664\u5DF2\u9009\u62E9OBS\u670D\u52A1\u5668\uFF1F",
  "provision.callcent.selectobsserver": "\u8BF7\u9009\u62E9OBS\u670D\u52A1\u5668",
  "provision.callcent.error.deleteobsserver.part.associationvcallcener": "\u9009\u62E9\u7684\u90E8\u5206OBS\u670D\u52A1\u5668\u5173\u8054\u79DF\u6237\uFF0C\u65E0\u6CD5\u5220\u9664",
  "provision.callcent.error.deleteobsserver.associationvcallcener": "\u9009\u62E9\u7684OBS\u670D\u52A1\u5668\u5173\u8054\u79DF\u6237\uFF0C\u65E0\u6CD5\u5220\u9664",
  "provision.callcent.error.deleteobsserverdesinfo": "\u5220\u9664\u5931\u8D25\uFF0COBS\u670D\u52A1\u5668\u4E0D\u5B58\u5728",
  "provision.callcent.error.specialStrValidateFailed": "\u65B0\u589E\u5931\u8D25\uFF0COBS\u540D\u79F0\u542B\u6709\u7279\u6B8A\u5B57\u7B26",
  "provision.callcent.error.paramValidateFailed": "\u65B0\u589E\u5931\u8D25\uFF0CobsId\uFF0CprogId\u6216heartbeatPort\u5E94\u8BE5\u5168\u4E3A\u6570\u5B57",
  "provision.callcent.error.ipValidateFailed": "ip\u5730\u5740\u4E0D\u5408\u6CD5",
  "provision.callcent.error.paramNotavailable": "\u5FC5\u9009\u53C2\u6570\u4E3A\u7A7A",
  "provision.callcent.error.paramUnique": "obsId\uFF0CprogId\uFF0CobsName\u6216obsIp\u5DF2\u5B58\u5728",
  "provision.callcent.error.portoutsiderange": "\u5FC3\u8DF3\u7AEF\u53E3\u53F7\u8D85\u51FA\u53D6\u503C\u8303\u56F4",
  "provision.callcent.error.addcallcenterdesinfo": "\u65B0\u589E\u5931\u8D25\uFF0C\u547C\u53EB\u4E2D\u5FC3\u4E0D\u5B58\u5728",
  "provision.callcent.error.portValidateFailed": "\u7F16\u8F91\u5931\u8D25\uFF0CheartbeatPort\u5E94\u8BE5\u5168\u4E3A\u6570\u5B57",
  "provision.callcent.error.modifyParamUnique": "obsIp\u5DF2\u5B58\u5728",
  "provision.callcent.error.checkparam": `\u542B\u6709\u7279\u6B8A\u5B57\u7B26("<"\,">"\,"\"\u7B49)`,
  "provision.ccworknos.table.ccworkStartNo": "\u8D77\u59CB\u5DE5\u53F7",
  "provision.ccworknos.table.ccworkEndNo": "\u7ED3\u675F\u5DE5\u53F7",
  "provision.ccworknos.table.virtualcallcenterNumber": "\u865A\u62DF\u547C\u53EB\u4E2D\u5FC3\u7F16\u53F7",

  "provision.ccworknos.table.ccworknos": "\u5DE5\u53F7",
  "provision.ccworknos.table.ccworkQuantity": "\u53F7\u6BB5\u5DE5\u53F7\u6570",
  "provision.callcentaccesscodes.table.callcentaccesscodes": "\u63A5\u5165\u7801",
  "provision.accesscode.table.accessCode": "\u63A5\u5165\u7801",
  "provision.accesscode.table.n400Number": "\u670D\u52A1\u53F7\u7801",
  "provision.accesscode.table.tenantname": "\u79DF\u6237\u540D\u79F0",
  "provision.accesscode.table.acId": "\u63A5\u5165\u7801\u7F16\u53F7",

  "provision.accesscode.table.audioAndvideo": "\u97F3\u89C6\u9891",
  "provision.accesscode.table.accessCode.selecttype": "\u8BF7\u9009\u62E9\u7C7B\u578B",
  "provision.accesscode.table.accessCode.clicktodial": "\u70B9\u51FB\u901A\u8BDD",
  "provision.accesscode.table.multimedia": "\u591A\u5A92\u4F53",
  "provision.accesscode.table.beginNumber": "\u8D77\u59CB\u63A5\u5165\u7801",

  "provision.accesscode.table.endNumber": "\u7ED3\u675F\u63A5\u5165\u7801",
  "provision.accesscode.table.whetherSupportTrialUse": "\u662F\u5426\u652F\u6301\u8BD5\u7528",
  "provision.accesscode.table.whetherSupportTrialUse_Y": "\u662F",

  "provision.accesscode.table.mediatypeId": "\u7C7B\u578B",
  "provision.callcenterinstance.accesscode.validatebeginaccesscode": "\u63A5\u5165\u7801\u8D77\u59CB\u53F7\u7801\u4E0D\u80FD\u5927\u4E8E\u7ED3\u675F\u53F7\u7801",
  "provision.accesscode.table.whetherSupportTrialUse_N": "\u5426",
  "provision.callcenterinstance.accesscode.validateaccesscoderange": "\u6279\u91CF\u65B0\u589E\u63A5\u5165\u7801\u6570\u91CF\u4E0D\u80FD\u8D85\u8FC7100",
  "provision.callcenterinstance.accesscode.batchcreate.fail.repeated": "\u65B0\u589E\u53F7\u6BB5\u5185\u7684\u6240\u6709\u63A5\u5165\u7801\u5DF2\u5B58\u5728",
  "provision.callcenterinstance.accesscode.batchcreate.fail.processingtask": "\u6B63\u5728\u5904\u7406\u6279\u91CF\u521B\u5EFA\u63A5\u5165\u7801\u4EFB\u52A1",
  "provision.callcenterinstance.accesscode.validateaccesscode": "\u8BF7\u8F93\u5165\u6B63\u786E\u7684\u53F7\u6BB5\u6570\u503C\u8303\u56F4",

  "provision.callcent.selectvcallcent": "\u8BF7\u9009\u62E9\u865A\u62DF\u547C\u53EB\u4E2D\u5FC3!",
  "provision.callcent.selectcallcent": "\u8BF7\u9009\u62E9\u547C\u53EB\u4E2D\u5FC3\uFF01",
  "provision.callcent.selectsipserver": "\u8BF7\u9009\u62E9\u5EA7\u5E2D\u6CE8\u518C\u670D\u52A1\u5668",
  "provision.callcent.selectamsserver.inconsistenttype": "\u533F\u540D\u547C\u53EB\u670D\u52A1\u5668\u7C7B\u578B\u4E0D\u4E00\u81F4",
  "provision.callcent.selectsipserver.inconsistenttype": "\u5EA7\u5E2D\u6CE8\u518C\u670D\u52A1\u5668\u7C7B\u578B\u4E0D\u4E00\u81F4",
  "provision.callcent.selectsoftnumber": "\u8BF7\u9009\u62E9\u8F6F\u7535\u8BDD\u53F7\u7801\uFF01",
  "provision.callcent.label.reset": "\u91CD\u7F6E",
  "provision.callcent.selectaccesscode": "\u8BF7\u9009\u62E9\u63A5\u5165\u7801\uFF01",
  "provision.callcent.label.close": "\u5173\u95ED",
  "provision.callcent.label.closeFaule": "\u5173\u95ED\u6545\u969C\u63D0\u793A",
  "provision.callcent.label.submit": "\u63D0\u4EA4",
  "provision.callcent.label.openFaule": "\u5F00\u542F\u6545\u969C\u63D0\u793A",
  "provision.callcent.label.finish": "\u5B8C\u6210",
  "provision.callcent.success.save": "\u6210\u529F",
  "provision.callcent.success.saveinfo": "\u521B\u5EFA\u6210\u529F",
  "provision.callcent.label.cancel": "\u53D6\u6D88",
  "provision.callcent.error.errorinfo": "\u521B\u5EFA\u5931\u8D25",
  "provision.callcent.error.errorinfo101": "ccm\u540C\u6B65\u5931\u8D25",
  "provision.ccworknos.table.creataccessCode": "\u65B0\u589E",
  "provision.ccworknos.table.updateaccessCode": "\u7F16\u8F91",
  "provision.callcent.success.updateinfo": "\u66F4\u65B0\u6210\u529F",
  "provision.callcent.error.save": "\u9519\u8BEF",
  "provision.callcent.error.updateinfo": "\u66F4\u65B0\u5931\u8D25",
  "provision.callcent.success.delete": "\u5220\u9664\u6210\u529F",
  "provision.callcent.success.setinfo": "\u8BBE\u7F6E\u6210\u529F",
  "provision.callcent.error.partSetinfo": "\u9009\u62E9\u90E8\u5206\u8BBE\u7F6E\u5931\u8D25",
  "provision.callcent.success.deleteinfo": "\u5220\u9664\u6210\u529F",
  "provision.callcent.error.delete": "\u5220\u9664\u5931\u8D25",
  "provision.callcent.error.setinfo": "\u8BBE\u7F6E\u5931\u8D25",
  "provision.callcent.error.deleteinfo": "\u5220\u9664\u5931\u8D25",
  "provision.callcent.error.partdeleteinfo": "\u5173\u8054\u865A\u62DF\u547C\u53EB\u4E2D\u5FC3\u7684\u5185\u5BB9\u65E0\u6CD5\u5220\u9664",
  "provision.callcenterccaccesscodeslist.table.batchdeleteconfim": "\u662F\u5426\u786E\u5B9A\u5220\u9664\u6240\u6709\u5DF2\u9009\u62E9\u63A5\u5165\u7801\uFF1F\u8BF7\u786E\u4FDD\u63A5\u5165\u7801\u672A\u5173\u8054\u865A\u62DF\u547C\u53EB\u4E2D\u5FC3",
  "provision.callcenterccaccesscodeslist.table.singledeleteconfim": "\u662F\u5426\u786E\u5B9A\u5220\u9664\u5DF2\u9009\u62E9\u63A5\u5165\u7801\uFF1F\u8BF7\u786E\u4FDD\u63A5\u5165\u7801\u672A\u5173\u8054\u865A\u62DF\u547C\u53EB\u4E2D\u5FC3",
  "provision.callcent.success.oj8k": "\u5B8C\u6210",
  "provision.callcent.error.saveccaccesscodeinfo": "\uFF0C\u8BF7\u786E\u8BA4\u65B0\u589E\u63A5\u5165\u7801\u662F\u5426\u5DF2\u5B58\u5728",
  "provision.callcent.error.savesipserverdesinfo.maxRegAgentNumError": "\uFF0C\u4FEE\u6539\u7684\u5E76\u53D1\u6CE8\u518C\u5EA7\u5E2D\u6570\u5C0F\u4E8E\u5B9E\u9645\u5DF2\u5206\u914D\u6CE8\u518C\u5EA7\u5E2D\u6570",
  "provision.callcent.error.savesipserverdesinfo.regAgentNum": "\uFF0C\u5E76\u53D1\u6CE8\u518C\u5EA7\u5E2D\u6570\u548C\u5269\u4F59\u6CE8\u518C\u5EA7\u5E2D\u6570\u4E0D\u4E00\u81F4",
  "provision.callcent.error.savesipserverdesinfo": "\uFF0C\u8BF7\u786E\u8BA4SBC\u670D\u52A1\u5668\u540D\u79F0\u662F\u5426\u5DF2\u5B58\u5728",
  "provision.callcent.error.savesipserverdesinfo.regAgentNumError": "\u4FEE\u6539\u7684\u5E76\u53D1\u6CE8\u518C\u5EA7\u5E2D\u6570\u5C0F\u4E8E\u5B9E\u9645\u5DF2\u5206\u914D\u6CE8\u518C\u5EA7\u5E2D\u6570",
  "provision.callcent.error.savesipserveripinfo": "\uFF0C\u8BF7\u786E\u8BA4SBC\u670D\u52A1\u5668IP\u548C\u7AEF\u53E3\u53F7\u662F\u5426\u5DF2\u5B58\u5728",
  "provision.callcent.error.repetenter": "\u53F7\u7801\u88AB\u5360\u7528\uFF0C\u8BF7\u91CD\u65B0\u8F93\u5165",
  "provision.callcent.error.savesipserverdesinfo.sipServernoexist": "\uFF0CSBC\u670D\u52A1\u5668\u4E0D\u5B58\u5728",
  "provision.callcent.error.createaccesscode.fail.onccgateway": "\uFF0C\u5728\u5E73\u53F0\u65B0\u589E\u63A5\u5165\u7801\u5931\u8D25",
  "provision.callcent.error.createaccesscode.n400Numbervalidate": "\u63A5\u5165\u7801\u548C\u670D\u52A1\u53F7\u7801\u4E0D\u80FD\u5B58\u5728\u5305\u542B\u5173\u7CFB",
  "provision.callcent.error.createaccesscode.validate.servicenumberContainServicenumber": "\u670D\u52A1\u53F7\u7801\u4E0E\u8BE5\u547C\u53EB\u4E2D\u5FC3\u4E0B\u7684\u5176\u5B83\u670D\u52A1\u53F7\u7801\u4E0D\u80FD\u5B58\u5728\u5305\u542B\u5173\u7CFB",
  "provision.callcent.error.createaccesscode.fail.ondatabase": "\uFF0C\u5728\u6570\u636E\u5E93\u521B\u5EFA\u63A5\u5165\u7801\u5931\u8D25",
  "provision.callcent.error.createaccesscode.validate.accessCodeContainAccesscode": "\u63A5\u5165\u7801\u4E0E\u8BE5\u547C\u53EB\u4E2D\u5FC3\u4E0B\u7684\u5176\u5B83\u63A5\u5165\u7801\u4E0D\u80FD\u5B58\u5728\u5305\u542B\u5173\u7CFB",
  "provision.callcent.error.updateSoftNumPasswordInfo": "\u8D26\u53F7\u4FE1\u606F\u9519\u8BEF",
  "provision.callcent.error.edit": "\u7F16\u8F91\u5931\u8D25",
  "provision.callcent.error.updateerrorinfo": "\u8BE5\u63A5\u5165\u7801\u5DF2\u5173\u8054\u865A\u62DF\u547C\u53EB\u4E2D\u5FC3\u65E0\u6CD5\u7F16\u8F91",

  "provision.callcent.error.updateaccesscode.fail.accesscodenoexist": "\uFF0C\u8BF7\u786E\u4FDD\u63A5\u5165\u7801\u662F\u5426\u5B58\u5728",
  "provision.callcent.error.updateaccesscode.fail.syncccmanagement": "\uFF0C\u540C\u6B65\u4FEE\u6539\u4FE1\u606F\u5230\u79DF\u6237\u65F6\u5931\u8D25",
  "provision.callcent.error.updateaccesscode.fail.ondatabase": "\uFF0C\u5728\u6570\u636E\u5E93\u4FEE\u6539\u63A5\u5165\u7801\u5931\u8D25",
  "provision.callcent.error.accesscode.deletefail.accesscodenoexist": "\u5220\u9664\u5931\u8D25\uFF0C\u63A5\u5165\u7801\u4E0D\u5B58\u5728",
  "provision.callcent.error.accesscode.deletefail": "\u5220\u9664\u5931\u8D25\uFF0C\u5165\u53C2\u4E3A\u7A7A",
  "provision.callcent.error.accesscode.deletefail.sqlexception": "\u5220\u9664\u5931\u8D25\uFF0Csql\u6267\u884C\u5F02\u5E38",
  "provision.callcent.error.accesscode.deletefail.ondatabase": "\u5728\u6570\u636E\u5E93\u5220\u9664\u63A5\u5165\u7801\u5931\u8D25",
  "provision.callcent.error.accesscode.deletefail.all.associationvcallcener": "\u63A5\u5165\u7801\u5173\u8054\u79DF\u6237\uFF0C\u65E0\u6CD5\u5220\u9664",
  "provision.callcent.error.accesscode.deletefail.all.onccgateway.with.associationvcallcener": "\u5168\u90E8\u5220\u9664\u5931\u8D25\uFF0C\u56E0\u4E3A\u90E8\u5206\u63A5\u5165\u7801\u5173\u8054\u79DF\u6237\uFF0C\u90E8\u5206\u63A5\u5165\u7801\u5728was\u5E73\u53F0\u5220\u9664\u5931\u8D25",
  "provision.callcent.error.accesscode.deletefail.part.associationvcallcener": "\u90E8\u5206\u63A5\u5165\u7801\u5173\u8054\u79DF\u6237\uFF0C\u65E0\u6CD5\u5220\u9664",
  "provision.callcent.error.accesscode.deletefail.all.onccgateway": "\u63A5\u5165\u7801\u5728was\u5E73\u53F0\u5220\u9664\u5931\u8D25",
  "provision.callcent.error.accesscode.deletefail.part.onccgateway": "\u90E8\u5206\u63A5\u5165\u7801\u5728was\u5E73\u53F0\u5220\u9664\u5931\u8D25",
  "provision.callcent.error.accesscode.deletefail.part.onccgateway.with.associationvcallcener": "\u90E8\u5206\u5220\u9664\u5931\u8D25\uFF0C\u56E0\u4E3A\u90E8\u5206\u63A5\u5165\u7801\u5173\u8054\u79DF\u6237\uFF0C\u90E8\u5206\u63A5\u5165\u7801\u5728was\u5E73\u53F0\u5220\u9664\u5931\u8D25",
  "provision.accesscode.error.transfer.queryinterface": "\u67E5\u8BE2\u5E73\u53F0\u6570\u636E\u5931\u8D25",
  "provision.callcent.error.deletesoftphonenumber.part.associationvcallcener": "\u9009\u62E9\u7684\u90E8\u5206\u8F6F\u7535\u8BDD\u53F7\u7801\u5173\u8054\u79DF\u6237\uFF0C\u65E0\u6CD5\u5220\u9664",
  "provision.callcent.error.deletesoftphonenumber.associationvcallcener": "\u9009\u62E9\u7684\u8F6F\u7535\u8BDD\u53F7\u7801\u5173\u8054\u79DF\u6237\uFF0C\u65E0\u6CD5\u5220\u9664",
  "provision.callcent.success.deletesoftphonenumber.processed": "\u540E\u53F0\u5220\u9664\u4E2D\uFF0C\u8BF7\u7A0D\u540E\u67E5\u8BE2",
  "provision.callcent.success.deletesoftphonenumber.unfinishtask": "\u5F53\u524D\u6709\u5220\u9664\u4EFB\u52A1\u5904\u7406\u4E2D\uFF0C\u8BF7\u7A0D\u540E\u91CD\u8BD5",
  "provision.callcent.error.updatesoftphonenumber.ondatabase": "\u5728\u6570\u636E\u5E93\u4FEE\u6539\u8F6F\u7535\u8BDD\u53F7\u7801\u5931\u8D25",
  "provision.callcent.error.updatesoftphonenumber.fail.syncccmanagement": "\u540C\u6B65\u8F6F\u7535\u8BDD\u4FEE\u6539\u4FE1\u606F\u5230\u79DF\u6237\u65F6\u5931\u8D25",
  "provision.callcent.error.updatesipserver.noexist": "SIP\u670D\u52A1\u5668\u4E0D\u5B58\u5728",
  "provision.callcent.error.updatesoftphonenumber.noexist": "\u8F6F\u7535\u8BDD\u53F7\u7801\u4E0D\u5B58\u5728",
  "provision.callcent.error.updatesipserver.ondatabase": "\u5728\u6570\u636E\u5E93\u4FEE\u6539SBC\u670D\u52A1\u5668\u5931\u8D25",
  "provision.callcent.error.updatesipserver.fail.syncccmanagement": "\u540C\u6B65SBC\u670D\u52A1\u5668\u4FEE\u6539\u4FE1\u606F\u5230\u79DF\u6237\u65F6\u5931\u8D25",
  "provision.callcent.error.updatesipserver.ipandport.alreadyexist": "SBC\u670D\u52A1\u5668IP\u548C\u7AEF\u53E3\u53F7\u5DF2\u5B58\u5728\uFF0C\u65E0\u6CD5\u4FEE\u6539",
  "provision.callcent.error.deletesipserver.part.associationvcallcener": "\u9009\u62E9\u7684\u90E8\u5206SIP\u670D\u52A1\u5668\u5173\u8054\u79DF\u6237\uFF0C\u65E0\u6CD5\u5220\u9664",
  "provision.callcent.error.deletesipserver.associationvcallcener": "\u9009\u62E9\u7684SIP\u670D\u52A1\u5668\u5173\u8054\u79DF\u6237\uFF0C\u65E0\u6CD5\u5220\u9664",
  "provision.tenantspace.table.tenantSpaceName": "\u79DF\u6237\u540D\u79F0",

  "provision.tenantspace.table.chooseStatus": "\u79DF\u6237\u72B6\u6001",
  "provision.tenantspace.table.tenantSpaceStatus": "\u72B6\u6001",
  "provision.tenantspace.search": "\u67E5\u8BE2",
  "provision.tenantspace.vitualCenterName": "\u865A\u62DF\u547C\u53EB\u4E2D\u5FC3",
  "provision.tenantspace.config.agentUsage": "\u5EA7\u5E2D\u6570",
  "provision.tenant.management.tenantname": "\u79DF\u6237\u540D\u79F0",
  "provision.tenantspace.reset": "\u91CD\u7F6E",
  "provision.tenant.management.adminaccount": "\u7BA1\u7406\u5458\u8D26\u53F7",
  "provision.tenant.management.adminpasswordagain": "\u518D\u6B21\u8F93\u5165\u5BC6\u7801",
  "provision.tenant.management.adminpassword": "\u5BC6\u7801",
  "provision.tenant.management.email": "\u90AE\u7BB1",
  "provision.tenant.management.mobile": "\u624B\u673A\u53F7",
  "provision.tenant.management.tenantspaceid": "\u6240\u5C5E\u79DF\u6237ID",
  "provision.tenant.management.partdbId": "\u6570\u636E\u5E93\u5206\u533A ID",
  "provision.tenant.management.phonenumber": "\u7535\u8BDD",
  "provision.tenant.management.agentnum": "\u5EA7\u5E2D\u6570",
  "provision.tenant.management.adminname": "\u7BA1\u7406\u5458",
  "provision.tenant.management.accesscode": "\u63A5\u5165\u7801",
  "provision.tenant.management.tenantcreate": "\u65B0\u5EFA\u79DF\u6237",
  "provision.tenant.management.administratorinfo": "\u79DF\u6237\u7BA1\u7406\u5458\u4FE1\u606F",
  "provision.tenant.management.tenantedit": "\u79DF\u6237\u4FEE\u6539",
  "provision.tenant.management.button.ok": "\u5B8C\u6210",
  "provision.tenant.management.tenantid": "\u79DF\u6237ID",
  "provision.tenant.management.admininfo": "\u79DF\u6237\u7BA1\u7406\u5458\u6CE8\u518C",
  "provision.tenant.management.select.accesscode": "\u9009\u62E9\u63A5\u5165\u7801",
  "provision.tenant.management.pwd.checkmsg": "\u4E24\u6B21\u8F93\u5165\u7684\u5BC6\u7801\u4E0D\u4E00\u81F4",
  "provision.tenant.management.tenantname.minlength": "\u79DF\u6237\u540D\u79F0\u957F\u5EA6\u4E0D\u80FD\u5C0F\u4E8E6",
  "provision.tenant.management.tenantname.alphavalidateFailed": "\u5B58\u5728\u975E\u6CD5\u5B57\u7B26\uFF0C\u8F93\u5165\u503C\u53EA\u80FD\u7531\u5B57\u6BCD\u3001\u6570\u5B57\u6216\u4E0B\u5212\u7EBF\u7EC4\u6210\u3002",
  "provision.tenant.management.tenantname.beginwithalpha": "\u5FC5\u987B\u4EE5\u5B57\u6BCD\u5F00\u5934",
  "provision.tenant.management.languagetype": "\u8BED\u8A00\u7C7B\u578B",
  "provision.tenant.management.edit.agentusage.mustinc": "\u65B0\u5EA7\u5E2D\u6570\u4E0D\u80FD\u5C0F\u4E8E\u539F\u5EA7\u5E2D\u6570",
  "provision.tenant.management.updatetenant.success": "\u79DF\u6237\u914D\u7F6E\u4FEE\u6539\u6210\u529F",
  "provision.tenant.management.updatetenant.failed": "\u79DF\u6237\u914D\u7F6E\u4FEE\u6539\u5931\u8D25",

  "provision.tenant.management.tenantcreate.emailillegal": "\u975E\u6CD5\u90AE\u7BB1",
  "provision.tenant.management.tenantcreate.mobileillegal": "\u975E\u6CD5\u624B\u673A\u53F7",
  "provision.tenant.management.tenantcreate.failed": "\u79DF\u6237\u521B\u5EFA\u5931\u8D25\uFF0C\u8BF7\u68C0\u67E5\u79DF\u6237\u540D\u79F0\u6216\u8005\u7BA1\u7406\u5458\u8D26\u53F7\u662F\u5426\u91CD\u590D",
  "provision.tenant.management.tenantcreate.success": "\u79DF\u6237\u521B\u5EFA\u6210\u529F",
  "promotion.tenant.management.tenantedit.exception": "\u670D\u52A1\u5668\u53D1\u751F\u5F02\u5E38",
  "promotion.tenant.management.tenantedit.accesscode.noavailable": "\u63A5\u5165\u7801\u4E0D\u53EF\u7528",
  "promotion.tenant.management.tenantedit.softnumber.insufficient": "\u8F6F\u7535\u8BDD\u6570\u91CF\u4E0D\u8DB3",
  "promotion.tenant.management.tenantedit.registerserver.notavailable": "\u6CE8\u518C\u670D\u52A1\u5668\u4E3A\u7A7A",
  "promotion.tenant.management.tenantedit.provisionerror": "\u66F4\u65B0\u79DF\u6237\u5931\u8D25",
  "promotion.tenant.management.tenantedit.vccupdate.failed": "\u66F4\u65B0\u865A\u62DF\u4E2D\u5FC3\u5931\u8D25",
  "promotion.tenant.management.tenantedit.vdncreate.failed": "\u521B\u5EFA\u6216\u4FEE\u6539vdn\u4FE1\u606F\u5931\u8D25",
  "promotion.tenant.management.tenantedit.vdnsync.failed": "\u540C\u6B65vdn\u5931\u8D25",
  "promotion.tenant.management.tenantedit.vcccapacity.insufficient": "\u865A\u62DF\u4E2D\u5FC3\u5BB9\u91CF\u4E0D\u8DB3",
  "promotion.tenant.management.tenantedit.vcc.notexist": "\u865A\u62DF\u4E2D\u5FC3\u4E0D\u5B58\u5728",
  "promotion.tenant.management.tenantedit.cc.null": "\u865A\u62DF\u4E2D\u5FC3\u6240\u5C5E\u547C\u53EB\u4E2D\u5FC3\u4E3A\u7A7A",

  "provision.tenantspace.table.delete": "\u5220\u9664",
  "provision.tenantspace.refresh": "\u5237\u65B0",
  "provision.tenantspace.table.update": "\u7BA1\u7406",
  "provision.tenantspace.table.deleteTenantSpace": "\u662F\u5426\u786E\u5B9A\u5220\u9664\u6307\u5B9A\u79DF\u6237",
  "provision.tenantspace.table.config": "\u914D\u7F6E",
  "provision.tenantspace.ok": "\u786E\u5B9A",
  "provision.tenantspace.create": "\u521B\u5EFA",
  "provision.tenantspace.chooseCC": "\u9009\u62E9\u547C\u53EB\u4E2D\u5FC3",
  "provision.tenantspace.selectCC": "\u8BF7\u9009\u62E9\u4E00\u4E2A\u547C\u53EB\u4E2D\u5FC3",
  "provision.tenantspace.ccName": "\u547C\u53EB\u4E2D\u5FC3",
  "provision.tenantspace.cc.current": "\u5F53\u524D\u5BB9\u91CF",
  "provision.tenantspace.cc.max": "\u6700\u5927\u5BB9\u91CF",
  "provision.tenantspace.cc.percent": "\u5BB9\u91CF\u6BD4\u4F8B",
  "provision.tenantspace.cc.currentpercent": "\u5F53\u524D\u5BB9\u91CF\u6BD4\u4F8B",
  "provision.tenantspace.cc.searchName": "\u8BF7\u8F93\u5165\u8981\u67E5\u8BE2\u7684\u547C\u53EB\u4E2D\u5FC3\u540D\u79F0",
  "provision.tenantspace.choose.accesscode": "\u9009\u62E9\u63A5\u5165\u7801",
  "provision.tenantspace.accessCode.choose": "\u8BF7\u9009\u62E9\u4E00\u7EC4\u63A5\u5165\u7801",
  "provision.tenantspace.clear": "\u6E05\u7A7A",
  "provision.tenantspace.rechoose": "\u6362\u4E00\u6279",
  "provision.tenantspace.save.success": "\u4FDD\u5B58\u6210\u529F\u3002",
  "provision.tenantspace.save.fail": "\u4FDD\u5B58\u5931\u8D25",
  "provision.tenantspace.select.status": "\u8BF7\u9009\u62E9\u72B6\u6001",
  "provision.tenantspace.select.start": "\u542F\u52A8",
  "provision.tenantspace.select.stop": "\u505C\u6B62",
  "provision.tenantspace.table.createStatus": "\u521B\u5EFA\u72B6\u6001",
  "provision.tenantspace.table.success": "\u6210\u529F",
  "provision.tenantspace.table.fail": "\u5931\u8D25",
  "provision.tenant.chooseCC.first": "\u8BF7\u5148\u9009\u62E9\u547C\u53EB\u4E2D\u5FC3",

  "provision.tenant.need.first": "\u8BF7\u5148\u9009\u62E9\u547C\u53EB\u4E2D\u5FC3\u3001\u63A5\u5165\u7801\u548C\u5EA7\u5E2D\u6570",
  "provision.tenant.chooseAC.first": "\u8BF7\u5148\u9009\u62E9\u63A5\u5165\u7801",
  "provision.tenantspace.error.deleteDDS": "\u5220\u9664DDS\u79DF\u6237\u5931\u8D25",
  "provision.tenant.no.more.agentUsage": "\u8BBE\u7F6E\u7684\u5EA7\u5E2D\u6570\u8FC7\u5927",
  "provision.tenantspace.input.searchName": "\u8BF7\u8F93\u5165\u8981\u67E5\u8BE2\u7684\u547C\u53EB\u4E2D\u5FC3\u540D\u79F0",

  "provision.tenantspace.input.agentnum": "\u8BF7\u8BBE\u7F6E\u9700\u8981\u7533\u8BF7\u7684\u5EA7\u5E2D\u6570",
  "provision.tenantspace.config.error": "\u914D\u7F6E\u5931\u8D25\uFF0C\u8BF7\u68C0\u67E5\u76F8\u5173\u914D\u7F6E\u6216\u8054\u7CFB\u7BA1\u7406\u5458",
  "provision.callcenterinstanceslist.IVRSprogress": "IVR\u8FDB\u7A0B",
  "provision.tenantspace.error.deleteSUM": "\u5220\u9664SUM\u79DF\u6237\u5931\u8D25",
  "provision.callcenterinstanceslist.IVRSprogress.Title": "IVR\u8FDB\u7A0B\u914D\u7F6E",
  "provision.callcenterinstanceslist.config.softUsage.more": "\u8F6F\u7535\u8BDD\u6570\u91CF\u4E0D\u8DB3\uFF0C\u8BF7\u68C0\u67E5\u76F8\u5173\u914D\u7F6E",
  "ccprovision.IVRSprogress.config.button.delete": "\u5220\u9664",
  "ccprovision.IVRSprogress.config.number": "\u7F16\u53F7",
  "ccprovision.IVRSprogress.config.button.edit": "\u7F16\u8F91",
  "ccprovision.IVRSprogress.config.progressNo": "\u8FDB\u7A0B\u53F7",
  "ccprovision.IVRSprogress.config.IPAddress": "IP\u5730\u5740",
  "ccprovision.IVRSprogress.config.button.async": "\u540C\u6B65",
  "ccprovision.IVRSprogress.config.leftovercurcallNo": "\u5269\u4F59\u5E76\u53D1\u547C\u53EB\u901A\u9053\u6570",
  "ccprovision.IVRSprogress.config.tenantname": "\u79DF\u6237\u540D\u79F0",
  "ccprovision.IVRSprogress.config.curcallNo": "\u5E76\u53D1\u547C\u53EB\u6570",
  "ccprovision.IVRSprogress.config.button.add": "\u65B0\u589E",
  "ccprovision.recorddrive.config.isfirstletter": "\u662F\u5426\u7B2C\u4E00\u76D8\u7B26",
  "ccprovision.IVRSprogress.config.button.cacel": "\u53D6\u6D88",
  "provision.ivr.selectivrprogress.singledeleteconfim": "\u662F\u5426\u786E\u5B9A\u5220\u9664\u5DF2\u9009\u62E9IVR\uFF1F\u8BF7\u786E\u4FDDIVR\u672A\u88AB\u79DF\u6237\u5360\u7528",
  "ccprovision.IVRSprogress.config.operate": "\u64CD\u4F5C",
  "ccprovision.IVRSprogress.config.button.complete": "\u5B8C\u6210",
  "provision.callcent.error.updateinfo.maxchnsmall": "\u4FEE\u6539\u7684\u5E76\u53D1\u547C\u53EB\u6570\u4E0D\u80FD\u5C0F\u4E8E\u5DF2\u88AB\u5360\u7528\u7684\u901A\u9053\u6570",
  "provision.ivr.selectivrprogress.batchdeleteconfim": "\u662F\u5426\u786E\u5B9A\u5220\u9664\u6240\u6709\u5DF2\u9009\u62E9IVR\uFF1F\u8BF7\u786E\u4FDDIVR\u672A\u88AB\u79DF\u6237\u5360\u7528",
  "provision.ivr.error.cannotdelete.occupyvdn": "\u914D\u7F6E\u7ED9\u79DF\u6237\u7684IVR\u65E0\u6CD5\u5220\u9664",
  "provision.ivr.selectivrprogress": "\u8BF7\u9009\u62E9\u8981\u5220\u9664\u7684IVR\u8FDB\u7A0B",
  "provision.obs.success.sync": "\u540C\u6B65\u6210\u529F\u3002",
  "provision.ivr.success.sync": "\u540C\u6B65\u6210\u529F",
  "provision.obs.error.sync": "\u540C\u6B65\u5931\u8D25\u3002",
  "provision.obs.sync.needDelete": "\u4EE5\u4E0BOBS\u5DF2\u7ED1\u5B9A\u79DF\u6237\uFF0C\u9700\u8981\u624B\u52A8\u89E3\u9664\u7ED1\u5B9A\u540E\u518D\u540C\u6B65\uFF1A",
  "ccprovision.recorddrive.config.recorddrivename": "\u76D8\u7B26\u540D\u79F0",
  "provision.ivr.error.sync": "\u540C\u6B65\u5931\u8D25",
  "provision.callcenterinstanceslist.recorddrive.Title": "\u5F55\u97F3\u76D8\u7B26",
  "provision.recorddrive.selectrecorddrive.batchdeleteconfim": "\u662F\u5426\u786E\u5B9A\u5220\u9664\u6240\u6709\u5DF2\u9009\u62E9\u7684\u5F55\u97F3\u76D8\u7B26\uFF1F\u8BF7\u786E\u4FDD\u5F55\u97F3\u76D8\u7B26\u672A\u88AB\u79DF\u6237\u5360\u7528",
  "ccprovision.recorddrive.config.storageLimit": "\u6700\u4F4E\u95E8\u9650\u503C(M)",
  "provision.recorddrive.selectneed.delete.recorddrive": "\u8BF7\u9009\u62E9\u8981\u5220\u9664\u7684\u5F55\u97F3\u76D8\u7B26",
  "provision.recorddrive.selectrecorddrive.singledeleteconfim": "\u662F\u5426\u786E\u5B9A\u5220\u9664\u5DF2\u9009\u62E9\u7684\u5F55\u97F3\u76D8\u7B26\uFF1F\u8BF7\u786E\u4FDD\u5F55\u97F3\u76D8\u7B26\u672A\u88AB\u79DF\u6237\u5360\u7528",
  "provision.recorddrive.error.firstletter.repeated": "\u7B2C\u4E00\u76D8\u7B26\u5DF2\u5B58\u5728",
  "provision.recorddrive.error.cannotdelete.occupyvdn": "\u914D\u7F6E\u7ED9\u79DF\u6237\u7684\u5F55\u97F3\u76D8\u7B26\u65E0\u6CD5\u5220\u9664",
  "provision.callcenterinstanceslist.vcallcenter.vdnName": "\u865A\u62DF\u547C\u53EB\u4E2D\u5FC3\u540D\u79F0",
  "provision.recorddrive.error.newrecord.repeated": "\u540C\u4E2A\u547C\u53EB\u4E2D\u5FC3\u4E0B\u65E0\u6CD5\u65B0\u589E\u76F8\u540C\u7684\u76D8\u7B26\u540D\u79F0",
  "provision.callcenterinstanceslist.vcallcenter.newvccId": "\u865A\u62DF\u547C\u53EB\u4E2D\u5FC3ID",
  "provision.callcenterinstanceslist.vcallcenter.tenantSpaceName": "\u79DF\u6237\u540D\u79F0",
  "provision.callcenterinstanceslist.vcallcenter.maxCalls": "\u8BED\u97F3\u5E76\u53D1\u547C\u53EB\u6570",
  "provision.callcenterinstanceslist.vcallcenter.maxVideoAgents": "\u89C6\u9891\u5EA7\u5E2D\u6570",
  "provision.callcenterinstanceslist.vcallcenter.maxVideoCalls": "\u89C6\u9891\u5E76\u53D1\u547C\u53EB\u6570",
  "provision.callcenterinstanceslist.vcallcenter.videoAgentChannels": "\u89C6\u9891\u5EA7\u5E2D\u901A\u9053\u6570",
  "provision.callcenterinstanceslist.vcallcenter.ivrchannels": "\u8BED\u97F3IVR\u901A\u9053\u6570",
  "provision.callcenterinstanceslist.vcallcenter.videoIvrchannels": "\u89C6\u9891IVR\u901A\u9053\u6570",
  "provision.callcenterinstanceslist.vcallcenter.maxAgents": "\u8BED\u97F3\u5EA7\u5E2D\u6570",
  "provision.callcenterinstanceslist.vcallcenter.ttschannelnums": "TTS\u6570",
  "provision.callcenterinstanceslist.vcallcenter.maxMediaAgents": "\u591A\u5A92\u4F53\u5EA7\u5E2D\u6570",
  "provision.callcenterinstanceslist.vcallcenter.asrchannelnums": "ASR\u6570",
  "provision.callcenterinstanceslist.vcallcenter.faultEnable": "\u6545\u969C\u63D0\u793A",
  "provision.callcenterinstanceslist.vcallcenter.open": "\u5F00\u542F",
  "provision.callcenterinstanceslist.vcallcenter.close": "\u5173\u95ED",
  "provision.callcenterinstanceslist.vcallcenter.maxAmsCalls": "\u533F\u540D\u5E76\u53D1\u547C\u53EB\u6570",
  "provision.callcenterinstanceslist.vcallcenter.maxVersatileAgents": "\u5168\u80FD\u578B\u5EA7\u5E2D\u6570",

  "provision.tenantspace.config.success": "\u79DF\u6237\u914D\u7F6E\u6210\u529F",
  "provision.tenantspace.table.maxCalls": "\u6700\u5927\u8BED\u97F3\u547C\u53EB\u5E76\u53D1\u6570",
  "provision.tenantspace.table.maxAgents": "\u8BED\u97F3\u5EA7\u5E2D\u6570",
  "provision.tenantspace.table.maxVideoAgents": "\u89C6\u9891\u5EA7\u5E2D\u6570",

  "provision.tenantspace.table.maxVideoCalls": "\u6700\u5927\u89C6\u9891\u547C\u53EB\u5E76\u53D1\u6570",
  "provision.tenantspace.table.videoAgentChannels": "\u89C6\u9891\u5EA7\u5E2D\u901A\u9053\u6570(>=2\u500D\u7684\u89C6\u9891\u5EA7\u5E2D\u6570)",
  "provision.tenantspace.table.ivrchannels": "\u8BED\u97F3IVR\u901A\u9053\u6570",
  "provision.tenantspace.table.videoivrchannels": "\u89C6\u9891IVR\u901A\u9053\u6570",
  "provision.tenantspace.table.ttschannelnums": "TTS\u6570",
  "provision.tenantspace.table.maxAmsCalls": "\u6700\u5927\u533F\u540D\u547C\u53EB\u5E76\u53D1\u6570",
  "provision.tenantspace.table.maxVersatileAgents": "\u5168\u80FD\u578B\u5EA7\u5E2D\u6570",
  "provision.tenantspace.cc.agentCurCapacity": "\u5DF2\u4F7F\u7528\u5EA7\u5E2D\u6570",
  "provision.tenantspace.cc.maxcallnums": "\u8BED\u97F3\u5E76\u53D1\u547C\u53EB\u6570",
  "provision.tenantspace.cc.callnumUsagePercent": "\u8BED\u97F3\u5E76\u53D1\u547C\u53EB\u4F7F\u7528\u7387",
  "provision.tenantspace.table.asrchannelnums": "ASR\u6570",
  "provision.tenantspace.cc.videoAgentCurCapacity": "\u89C6\u9891\u5EA7\u5E2D\u6570",
  "provision.tenantspace.cc.videoAgentUsage": "\u89C6\u9891\u5EA7\u5E2D\u4F7F\u7528\u7387",
  "provision.tenantspace.cc.maxVideoCallnums": "\u89C6\u9891\u5E76\u53D1\u547C\u53EB\u6570",
  "provision.tenantspace.cc.videoCallnumUsagePercent": "\u89C6\u9891\u5E76\u53D1\u547C\u53EB\u4F7F\u7528\u7387",
  "provision.tenantspace.cc.maxAmsCallnums": "\u533F\u540D\u5E76\u53D1\u547C\u53EB\u6570",
  "provision.tenantspace.cc.amsCallnumUsagePercent": "\u533F\u540D\u5E76\u53D1\u547C\u53EB\u4F7F\u7528\u7387",
  "provision.tenantspace.cc.videoAgentchannelUsagePercent": "\u89C6\u9891\u5EA7\u5E2D\u901A\u9053\u4F7F\u7528\u7387",
  "provision.tenantspace.cc.ivrchannels": "\u97F3\u9891IVR\u901A\u9053\u6570",
  "provision.tenantspace.cc.ivrchannelUsagePercent": "\u97F3\u9891IVR\u901A\u9053\u4F7F\u7528\u7387",
  "provision.tenantspace.cc.videoAgentchannels": "\u89C6\u9891\u5EA7\u5E2D\u901A\u9053\u6570",
  "provision.tenantspace.cc.videoIvrchannelUsagePercent": "\u89C6\u9891IVR\u901A\u9053\u4F7F\u7528\u7387",
  "provision.tenantspace.cc.videoIvrchannels": "\u89C6\u9891IVR\u901A\u9053\u6570",
  "provision.tenantspace.cc.ttschannelUsagePercent": "TTS\u4F7F\u7528\u7387",
  "provision.tenantspace.cc.ttschannelnums": "TTS\u6570",
  "provision.tenantspace.cc.asrchannelUsagePercent": "ASR\u4F7F\u7528\u7387",
  "provision.tenantspace.cc.asrchannelnums": "ASR\u6570",
  "provision.tenantspace.ivr.id": "\u7F16\u53F7",
  "provision.tenantspace.ivr.ivrServer": "IP\u5730\u5740",
  "provision.tenantspace.ivr.ivrprogid": "\u8FDB\u7A0B\u53F7",
  "provision.tenantspace.ivr.maxChnNum": "\u5E76\u53D1\u547C\u53EB\u6570",
  "provision.tenantspace.ivr.idlechnNum": "\u5269\u4F59\u5E76\u53D1\u547C\u53EB\u6570",
  "provision.tenantspace.ivr.ivrId": "IVR\u7F16\u53F7",

  "provision.tenantspace.gw.serverIP": "Gateway\u670D\u52A1\u5668IP",
  "provision.tenantspace.gw.port": "Gateway\u670D\u52A1\u5668\u7AEF\u53E3",
  "provision.tenantspace.gw.name": "Gateway\u670D\u52A1\u5668\u540D\u79F0",
  "provision.tenantspace.gw.maxRegAgentNum": "\u5E76\u53D1\u6CE8\u518C\u5EA7\u5E2D\u6570",
  "provision.tenantspace.gw.idleRegAgentNum": "\u5269\u4F59\u5E76\u53D1\u6CE8\u518C\u5EA7\u5E2D\u6570",
  "provision.tenantspace.sip.sipServerIP": "SIP\u670D\u52A1\u5668IP",


  "provision.tenantspace.sip.name": "SBC\u670D\u52A1\u5668\u540D\u79F0",
  "provision.tenantspace.ucs.name": "CCUCS\u670D\u52A1\u5668\u540D\u79F0",
  "provision.tenantspace.sip.maxRegAgentNum": "\u5E76\u53D1\u6CE8\u518C\u5EA7\u5E2D\u6570",
  "provision.tenantspace.sip.port": "SIP\u670D\u52A1\u5668\u7AEF\u53E3",
  "provision.tenantspace.sip.idleRegAgentNum": "\u5269\u4F59\u5E76\u53D1\u6CE8\u518C\u5EA7\u5E2D\u6570",
  "provision.tenant.management.tenantcreate.companyName": "\u516C\u53F8\u540D\u79F0",
  "provision.tenant.management.tenantcreate.expiryDate": "\u79DF\u6237\u622A\u6B62\u65E5\u671F",
  "provision.tenant.management.button.cancel": "\u53D6\u6D88",
  "provision.tenant.management.contacts": "\u8054\u7CFB\u4EBA",
  "provision.tenant.management.companyName": "\u516C\u53F8\u540D\u79F0",
  "provision.tenantspace.detail": "\u79DF\u6237\u8BE6\u60C5",
  "provision.tenant.config.maxAgents": "\u8BED\u97F3\u5EA7\u5E2D\u6570",
  "provision.tenantspace.config.maxCalls": "\u8BED\u97F3\u547C\u53EB\u5E76\u53D1\u6570",

  "provision.tenantspace.config.maxVideoAgents": "\u89C6\u9891\u5EA7\u5E2D\u6570",
  "provision.tenantspace.config.maxVideoCalls": "\u89C6\u9891\u547C\u53EB\u5E76\u53D1\u6570",
  "provision.tenantspace.config.ivrchannels": "IVR\u901A\u9053\u6570",
  "provision.tenantspace.config.chooseDefaultPhoneNumber": "\u9009\u62E9\u9ED8\u8BA4\u4E3B\u53EB\u53F7\u7801",
  "provision.tenantspace.config.asrchannelnums": "ASR\u6570",
  "provision.tenantspace.config.ttschannelnums": "TTS\u6570",
  "provision.tenantspace.config.chooseDrive": "\u5F55\u97F3\u76D8\u7B26",
  "provision.tenantspace.config.chooseDriveTime": "\u5F55\u97F3\u4FDD\u6301\u5468\u671F",
  "provision.tenantspace.config.chooseGatewayServer": "\u8BF7\u9009\u62E9\u4E00\u4E2AGateway\u670D\u52A1\u5668",
  "provision.tenantspace.config.chooseSIPServer": "\u8BF7\u9009\u62E9\u4E00\u4E2ASBC\u670D\u52A1\u5668",
  "provision.tenantspace.config.chooseUCSServer": "\u8BF7\u9009\u62E9\u4E00\u4E2ACCUCS\u670D\u52A1\u5668",
  "provision.tenantspace.config.configVirtualCallCenter": "\u914D\u7F6E\u865A\u62DF\u547C\u53EB\u4E2D\u5FC3",
  "provision.tenantspace.config.isCreateAccount": "\u865A\u62DF\u547C\u53EB\u4E2D\u5FC3\u662F\u5426\u81EA\u52A8\u521B\u5EFA\u4E1A\u52A1\u8D26\u53F7",
  "provision.tenantspace.selectStastus.config": "\u5F85\u914D\u7F6E",

  "provision.tenantspace.selectStastus.active": "\u5DF2\u6FC0\u6D3B",
  "provision.tenantspace.selectStastus.stop": "\u5DF2\u6682\u505C",
  "provision.tenantspace.selectStastus.initialize": "\u521D\u59CB\u5316",
  "provision.tenantspace.selectStastus.unallocated": "\u5F85\u5206\u914D",
  "provision.tenantspace.selectStastus.resource.allocation.failed": "\u8D44\u6E90\u5206\u914D\u5931\u8D25",
  "provision.tenantspace.selectStastus.invalid": "\u5931\u6548",
  "provision.tenantspace.statusRecordTime.3Month": "3\u4E2A\u6708",

  "provision.tenantspace.statusRecordTime.6Month": "6\u4E2A\u6708",
  "provision.tenantspace.statusRecordTime.12Month": "12\u4E2A\u6708",
  "provision.tenantspace.language.zh": "zh-CN",
  "provision.tenantspace.language.cn": "en-US",
  "provision.tenantspace.dataUpload.contactRecord": "\u63A5\u89E6\u8BB0\u5F55",

  "provision.tenantspace.dataUpload.cdrData": "\u8BDD\u5355\u6570\u636E",
  "provision.tenantspace.dataUpload.voiceFile": "\u8BED\u97F3\u6587\u4EF6",
  "provision.tenantspace.dataUpload.reportData": "\u62A5\u8868\u6570\u636E",
  "provision.tenantspace.dataUpload.selectType": "\u8BF7\u9009\u62E9\u8D44\u6E90\u8F6C\u50A8\u670D\u52A1\u7C7B\u578B",
  "provision.tenantspace.dataUpload.openResourceDump": "\u5F00\u542F\u8D44\u6E90\u8F6C\u50A8\u7279\u6027\uFF0C\u79DF\u6237\u652F\u6301\u8D44\u6E90\u8F6C\u50A8\u529F\u80FD\u3002\u6CE8\u610F\uFF1A\u8D44\u6E90\u8F6C\u50A8\u76EE\u6807\u5B58\u50A8\u670D\u52A1\u5F53\u524D\u53EA\u652F\u6301\u534E\u4E3A\u4E91OBS\u5BF9\u8C61\u5B58\u50A8\u670D\u52A1\u3002\u82E5\u4F7F\u7528\u8D44\u6E90\u8F6C\u50A8\u7279\u6027\uFF0C\u9700\u8981\u79DF\u6237\u8D2D\u4E70\u6B64\u670D\u52A1\u3002",
  "provision.tenantspace.dataUpload.resourceType": "\u8D44\u6E90\u8F6C\u50A8\u7C7B\u578B",
  "provision.tenantspace.dataUpload.choose": "\u8BF7\u9009\u62E9...",
  "provision.tenantspace.dataUpload.resourceDump": "\u8D44\u6E90\u8F6C\u50A8",
  "provision.tenantspace.config.giveSource": "\u5206\u914D\u8D44\u6E90",
  "provision.tenantspace.config.chooseDPN": "\u8BF7\u4ECE\u63A5\u5165\u7801\u4E2D\u9009\u62E9\u4E00\u4E2A\u4F5C\u4E3A\u9ED8\u8BA4\u4E3B\u53EB\u53F7\u7801",
  "provision.tenantspace.config.chooseIVR": "\u8BF7\u9009\u62E9IVR\u8FDB\u7A0B",
  "provision.tenantspace.config.applySource": "\u7533\u8BF7\u8D44\u6E90",
  "provision.tenantspace.config.IVR": "IVR\u8FDB\u7A0B",
  "provision.tenantspace.config.gw": "Gateway\u670D\u52A1\u5668",
  "provision.tenantspace.config.SIP": "SBC\u670D\u52A1\u5668",

  "provision.callcenterinstanceslist.vcallcenter.ServerNumber": "\u670D\u52A1\u53F7\u7801",
  "provision.tenantspace.config.vccName": "\u865A\u62DF\u547C\u53EB\u4E2D\u5FC3\u540D\u79F0",
  "provision.tenantspace.create.applyAccount": "\u8BF7\u4E3A\u79DF\u6237\u6CE8\u518C\u4E00\u4F4D\u7BA1\u7406\u5458",
  "provision.tenantspace.create.basicInfo": "\u57FA\u672C\u4FE1\u606F",
  "provision.tenantspace.create.account": "\u8D26\u6237\u4FE1\u606F",
  "provision.tenantspace.create.tenantName": "\u79DF\u6237\u540D\u79F0",
  "provision.tenantspace.create.tenantExpiryDate": "\u79DF\u6237\u5230\u671F\u65F6\u95F4",

  "provision.ccworknos.table.vCCInstId": "\u865A\u62DF\u547C\u53EB\u4E2D\u5FC3",
  "provision.tenantspace.save.fail.001": "\u8BED\u97F3\u5EA7\u5E2D\u6570 + \u89C6\u9891\u5EA7\u5E2D\u6570 + \u591A\u5A92\u4F53\u5EA7\u5E2D\u6570 + IVR\u901A\u9053\u6570\u4E0D\u80FD\u4E3A0",
  "provision.tenantspace.save.fail.003": "\u6700\u5927\u5E76\u53D1\u89C6\u9891\u547C\u53EB\u6570\u5927\u4E8E\u6216\u7B49\u4E8E\u89C6\u9891\u5EA7\u5E2D\u6570",
  "provision.tenantspace.save.fail.004": "\u63A5\u5165\u7801\u4E3A\u7A7A",
  "provision.tenantspace.save.fail.005": "\u63A5\u5165\u7801\u88AB\u5360\u7528",
  "provision.tenantspace.save.fail.006": "\u7533\u8BF7\u7684\u79DF\u6237\u7684\u5EA7\u5E2D\u6570\u4E0D\u80FD\u5927\u4E8ESBC\u670D\u52A1\u5668\u5269\u4F59\u7684\u5EA7\u5E2D\u6570",
  "provision.tenantspace.save.fail.007": "\u547C\u53EB\u4E2D\u5FC3\u4E0D\u5B58\u5728",
  "provision.tenantspace.save.fail.008": "\u672A\u914D\u7F6E\u8F6F\u7535\u8BDD\u53F7\u7801",
  "provision.tenantspace.save.fail.010": "\u6EE1\u8DB3\u5360\u7528\u7684\u53F7\u6BB5\u603B\u6570<\u7533\u8BF7\u7684\u5EA7\u5E2D\u6570\u8FD4\u56DE\u7A7A",
  "provision.tenantspace.save.fail.009": "\u53EF\u7528\u5DE5\u53F7\u6BB5\u4E0D\u8DB3",
  "provision.tenantspace.save.fail.002": "\u6700\u5927\u8BED\u97F3\u5E76\u53D1\u6570\u5927\u4E8E\u6216\u7B49\u4E8E\u5EA7\u5E2D\u6570+IVR\u901A\u9053\u6570",
  "provision.tenantspace.save.fail.013": "\u66F4\u65B0\u63A5\u5165\u7801\u6570\u636E\u5931\u8D25",
  "provision.tenantspace.save.fail.011": "\u4FDD\u5B58\u865A\u62DF\u547C\u53EB\u4E2D\u5FC3\u6570\u636E\u5931\u8D25",
  "provision.tenantspace.save.fail.014": "\u66F4\u65B0\u5DE5\u53F7\u6570\u636E\u5931\u8D25",
  "provision.tenantspace.save.fail.012": "\u66F4\u65B0\u865A\u62DF\u547C\u53EB\u4E2D\u5FC3\u6570\u636E\u5931\u8D25",
  "provision.tenantspace.save.fail.015": "\u66F4\u65B0\u8F6F\u7535\u8BDD\u53F7\u7801\u6570\u636E\u5931\u8D25",
  "provision.tenantspace.save.fail.016": "\u66F4\u65B0\u547C\u53EB\u4E2D\u5FC3\u6570\u636E\u5931\u8D25",
  "provision.tenantspace.save.fail.019": "\u547C\u53EB\u4E2D\u5FC3\u5269\u4F59\u5BB9\u91CF\u5C0F\u4E8E\u65B0\u5206\u914D\u5EA7\u5E2D",
  "provision.tenantspace.save.fail.017": "\u865A\u62DF\u547C\u53EB\u4E2D\u5FC3\u4E3A\u7A7A",
  "provision.tenantspace.save.fail.018": "\u547C\u53EB\u4E2D\u5FC3\u4E3A\u7A7A",
  "provision.tenantspace.save.fail.020": "\u5220\u9664\u865A\u62DF\u547C\u53EB\u4E2D\u5FC3\u6570\u636E\u5931\u8D25",
  "provision.tenantspace.save.fail.022": "\u8BED\u97F3\u5EA7\u5E2D\u6570\u3001\u6700\u5927\u8BED\u97F3\u5E76\u53D1\u6570\u4E0D\u80FD\u4E3A 0",
  "provision.tenantspace.save.fail.024": "\u5269\u4F59\u7684\u89C6\u9891\u5EA7\u5E2D\u6570\u4E0D\u8DB3(\u7533\u8BF7\u89C6\u9891\u5EA7\u5E2D\u6570\u5927\u4E8E\u5269\u4F59\u89C6\u9891\u5EA7\u5E2D\u6570)",
  "provision.tenantspace.save.fail.025": "\u5269\u4F59\u7684\u8BED\u97F3\u5E76\u53D1\u6570\u4E0D\u8DB3",
  "provision.tenantspace.save.fail.021": "\u66F4\u65B0\u79DF\u6237\u6570\u636E\u5931\u8D25",
  "provision.tenantspace.save.fail.026": "\u5269\u4F59\u7684\u533F\u540D\u5E76\u53D1\u6570\u4E0D\u8DB3",
  "provision.tenantspace.save.fail.023": "\u5269\u4F59\u7684\u8BED\u97F3\u5EA7\u5E2D\u6570\u4E0D\u8DB3(\u7533\u8BF7\u8BED\u97F3\u5EA7\u5E2D\u6570\u5927\u4E8E\u5269\u4F59\u8BED\u97F3\u5EA7\u5E2D\u6570)",
  "provision.tenantspace.save.fail.028": "\u5269\u4F59\u7684TTS\u901A\u9053\u6570\u4E0D\u8DB3",
  "provision.tenantspace.save.fail.029": "\u5269\u4F59\u7684ASR\u901A\u9053\u6570\u4E0D\u8DB3",
  "provision.tenantspace.save.fail.031": "\u79DF\u6237\u7F29\u5BB9\u8BF7\u5728\u5EA7\u5E2D\u4FE1\u606F\u9875\u9762\u64CD\u4F5C",
  "provision.tenantspace.save.fail.032": "\u7533\u8BF7\u7684\u5EA7\u5E2D\u6570\u4E0D\u80FD\u5927\u4E8E10000",
  "provision.tenantspace.save.fail.040": "\u8BF7\u914D\u7F6E\u591A\u5A92\u4F53\u7C7B\u578B\u7684\u63A5\u5165\u7801",
  "provision.tenantspace.save.fail.041": "\u8BED\u97F3\u5EA7\u5E2D\u6570+\u89C6\u9891\u5EA7\u5E2D\u6570+\u591A\u5A92\u4F53\u5EA7\u5E2D\u6570+\u5168\u80FD\u578B\u5EA7\u5E2D\u6570+\u97F3\u9891IVR\u901A\u9053\u6570+\u89C6\u9891IVR\u901A\u9053\u6570\u81F3\u5C11\u5927\u4E8E0",
  "provision.tenantspace.save.fail.043": "\u7533\u8BF7\u591A\u5A92\u4F53\u5EA7\u5E2D\u6570\u5927\u4E8E\u5269\u4F59\u591A\u5A92\u4F53\u5EA7\u5E2D\u6570",
  "provision.tenantspace.save.fail.044": "\u8BF7\u914D\u7F6E\u97F3\u89C6\u9891\u7C7B\u578B\u7684\u63A5\u5165\u7801",
  "provision.tenantspace.save.fail.030": "\u53EF\u7528\u8F6F\u7535\u8BDD\u53F7\u7801\u4E0D\u8DB3",
  "provision.tenantspace.save.fail.046": "\u8FDE\u63A5was\u5E73\u53F0\u5220\u9664\u5EA7\u5E2D\u5931\u8D25",
  "provision.tenantspace.save.fail.047": "CC-Gateway\u670D\u52A1\u5668\u8D44\u6E90\u4E0D\u8DB3",
  "provision.tenantspace.save.fail.049": "\u6392\u9664\u6570\u5B57\u4E0D\u7B26\u5408\u8981\u6C42",
  "provision.tenantspace.save.fail.050": "\u5DF2\u9009\u62E9\u5DE5\u53F7\u6570\u5C0F\u4E8E\u65B0\u589E\u5EA7\u5E2D\u6570",
  "provision.tenantspace.save.fail.048": "\u4EBA\u5DE5\u5206\u914D\u5DE5\u53F7\u6BB5\u4E2D\u5305\u542B\u5DF2\u5206\u914D\u5DE5\u53F7",
  "provision.tenantspace.save.fail.027": "\u5269\u4F59\u7684IVR\u901A\u9053\u6570\u4E0D\u8DB3",
  "provision.tenantspace.save.fail.051": "\u672A\u5206\u914DOBS\u6570\u636E\u6E90",
  "provision.tenantspace.save.fail.045": "IVR\u8FDB\u7A0B\u8D44\u6E90\u4E0D\u8DB3",
  "provision.tenantspace.save.fail.054": "\u4EBA\u5DE5\u5206\u914D\u8F6F\u7535\u8BDD\u53F7\u7801\u8D44\u6E90\u5931\u8D25",
  "provision.tenantspace.save.fail.052": "OBS\u670D\u52A1\u5668\u4E0D\u5B58\u5728",
  "provision.tenantspace.save.fail.057": "\u79DF\u6237\u5206\u914D\u7684\u8D44\u6E90\u5F02\u5E38",
  "provision.tenantspace.save.fail.053": "OBS\u6570\u636E\u6E90\u4E0D\u5B58\u5728",
  "provision.tenantspace.save.fail.055": "\u5F53\u524D\u6709\u672A\u5B8C\u6210\u7684\u79DF\u6237\u4EFB\u52A1",
  "provision.tenantspace.save.fail.056": "\u79DF\u6237\u57FA\u672C\u4FE1\u606F\u914D\u7F6E\u9519\u8BEF",
  "provision.tenantspace.save.fail.062": "\u914D\u7F6E\u70B9\u51FB\u901A\u8BDD\u63A5\u5165\u7801\u65F6\u9700\u914D\u7F6E\u591A\u5A92\u4F53\u63A5\u5165\u7801",
  "provision.tenantspace.save.fail.058": "\u4FDD\u5B58\u6570\u636E\u5931\u8D25",
  "provision.tenantspace.save.fail.067": "\u7B2C\u4E09\u65B9\u8BA4\u8BC1\u7CFB\u7EDF\u8BF7\u6C42\u6570\u636E\u6821\u9A8C\u5931\u8D25",
  "provision.tenantspace.save.fail.078delete": "\u914D\u7F6E\u7B2C\u4E09\u65B9\u8BA4\u8BC1\u7CFB\u7EDF\u65F6\,\u5220\u9664\u5931\u8D25",
  "provision.tenantspace.save.fail.078modify": "\u914D\u7F6E\u7B2C\u4E09\u65B9\u8BA4\u8BC1\u7CFB\u7EDF\u65F6\,\u4FEE\u6539\u5931\u8D25",
  "provision.tenantspace.save.fail.063": "\u5220\u9664IVR\u8FDB\u7A0B\u5931\u8D25",
  "provision.tenantspace.save.fail.079": "\u914D\u7F6E\u7B2C\u4E09\u65B9\u8BA4\u8BC1\u7CFB\u7EDF\u65F6\,\u672C\u5730\u7528\u6237\u4FE1\u606F\u6216\u8005\u5916\u90E8\u7528\u6237\u4FE1\u606F\u89E3\u6790\u5931\u8D25",
  "provision.tenantspace.save.fail.078add": "\u914D\u7F6E\u7B2C\u4E09\u65B9\u8BA4\u8BC1\u7CFB\u7EDF\u65F6\,\u521B\u5EFA\u5931\u8D25",

  "provision.tenantspace.save.fail.104-001": "\u672A\u5F00\u542F\u5F55\u97F3\u4E0B\u8F7D\u529F\u80FD",
  "provision.tenantspace.save.fail.104-002": "\u7528\u6237\u65E0\u8D28\u68C0\u5F55\u97F3/\u8D28\u68C0\u653E\u97F3\u6743\u9650",
  "provision.tenantspace.save.fail.104-004": "\u5F55\u97F3\u5220\u9664\u5931\u8D25\u4E4B\u6CA1\u6709\u914D\u7F6E\u6620\u5C04\u76D8\u7B26",
  "provision.tenantspace.save.fail.104-003": "\u5F55\u97F3\u5220\u9664\u5931\u8D25\u4E4B\u6587\u4EF6\u540D\u4E0D\u5408\u6CD5",
  "provision.tenantspace.save.fail.104-005": "\u5F55\u97F3\u5220\u9664\u5931\u8D25\u4E4B\u5F55\u97F3\u6587\u4EF6\u4E0D\u5B58\u5728",
  "provision.tenantspace.save.fail.104-006": "\u5F55\u97F3\u5220\u9664\u5931\u8D25\u4E4B\u7A7A\u95F2\u7A7A\u95F4\u4E0D\u8DB3",
  "provision.tenantspace.save.fail.104-008": "\u5F55\u97F3\u683C\u5F0F\u7CFB\u7EDF\u53C2\u6570\u672A\u914D\u7F6E",
  "provision.tenantspace.save.fail.101": "cc_management \u540C\u6B65\u5F02\u5E38",
  "provision.tenantspace.save.fail.104-007": "\u5F55\u97F3\u6587\u4EF6\u683C\u5F0F\u4E0D\u652F\u6301",
  "provision.tenantspace.save.fail.104-010": "\u5F55\u97F3\u8282\u70B9id\u53C2\u6570\u672A\u914D\u7F6E",
  "provision.tenantspace.save.fail.104-011": "\u5F55\u97F3\u6587\u4EF6\u5220\u9664\u5931\u8D25",
  "provision.tenantspace.save.fail.107-000": "\u7F13\u5B58\u767B\u5F55\u4FE1\u606F\u5931\u6548\uFF0C\u9700\u8981\u91CD\u65B0\u767B\u5F55\u9274\u6743",
  "provision.tenantspace.save.fail.104-009": "\u5F55\u97F3\u8F6C\u6362\u5931\u8D25",
  "provision.tenantspace.save.fail.107-002": "\u8F93\u5165\u53C2\u6570\u4E0D\u5408\u6CD5\uFF0C\u5FC5\u586B\u5B57\u6BB5\u4E3A\u7A7A",
  "provision.tenantspace.save.fail.107-003": "\u914D\u7F6E\u7BA1\u7406\u5458\u5BC6\u7801\u9519\u8BEF\uFF0C\u91CD\u8BD5\u591A\u6B21\u540E\u9501\u5B9A\u8D26\u53F7",
  "provision.tenantspace.save.fail.107-004": "\u65E0\u6548\u914D\u7F6E\u7BA1\u7406\u5458\u8D26\u53F7",
  "provision.tenantspace.save.fail.107-001": "\u8C03\u7528\u914D\u7F6E\u63A5\u53E3\u629B\u4E86\u5F02\u5E38",
  "provision.tenantspace.save.fail.107-007": "\u914D\u7F6E\u63A5\u53E3\u914D\u7F6E\u524D\u9274\u6743\u5931\u8D25",
  "provision.tenantspace.save.fail.107-010": "\u589E\u52A0\u5EA7\u5E2D\u6216\u6279\u91CF\u589E\u52A0\u5EA7\u5E2D\u9047\u5230\u5F02\u5E38",
  "provision.tenantspace.save.fail.107-011": "\u5EA7\u5E2D\u4FEE\u6539\u6216\u6279\u91CF\u5EA7\u5E2D\u4FEE\u6539\u9047\u5230\u5F02\u5E38",
  "provision.tenantspace.save.fail.107-012": "\u5EA7\u5E2D\u67E5\u8BE2\u5F02\u5E38",
  "provision.tenantspace.save.fail.107-005": "\u914D\u7F6E\u63A5\u53E3\u901A\u4FE1\u5931\u8D25",
  "provision.tenantspace.save.fail.107-013": "\u5EA7\u5E2D\u5220\u9664\u5931\u8D25\u5F02\u5E38",
  "provision.tenantspace.save.fail.107-014": "\u89D2\u8272\u589E\u52A0\u5931\u8D25\u5F02\u5E38",
  "provision.tenantspace.save.fail.107-016": "\u89D2\u8272\u67E5\u8BE2\u5931\u8D25\u5F02\u5E38",
  "provision.tenantspace.save.fail.107-017": "\u89D2\u8272\u5220\u9664\u5931\u8D25\u5F02\u5E38",
  "provision.tenantspace.save.fail.107-019": "\u88AB\u53EB\u4FEE\u6539\u5931\u8D25\u5F02\u5E38",
  "provision.tenantspace.save.fail.107-018": "\u88AB\u53EB\u589E\u52A0\u5931\u8D25\u5F02\u5E38",
  "provision.tenantspace.save.fail.107-020": "\u88AB\u53EB\u67E5\u8BE2\u5931\u8D25\u5F02\u5E38",
  "provision.tenantspace.save.fail.107-021": "\u88AB\u53EB\u5220\u9664\u5931\u8D25\u5F02\u5E38",
  "provision.tenantspace.save.fail.107-023": "VDN\u67E5\u8BE2\u5931\u8D25\u5F02\u5E38",
  "provision.tenantspace.save.fail.107-024": "IVR\u6D41\u7A0B\u67E5\u8BE2\u5931\u8D25\u5F02\u5E38",
  "provision.tenantspace.save.fail.107-025": "IVR\u6D41\u7A0B\u6587\u4EF6\u540D\u4E3A\u7A7A",
  "provision.tenantspace.save.fail.107-026": "\u8BA2\u9605\u5931\u8D25\u5F02\u5E38",
  "provision.tenantspace.save.fail.107-027": "\u8BA2\u9605\u767B\u8BB0\u5931\u8D25\u5F02\u5E38",
  "provision.tenantspace.save.fail.107-028": "\u8BA2\u9605\u8BF7\u6C42\u5931\u8D25\u5F02\u5E38",
  "provision.tenantspace.save.fail.107-030": "\u8BA2\u9605\u5F02\u5E38",
  "provision.tenantspace.save.fail.107-029": "\u8BA2\u9605\u8BF7\u6C42\u8D85\u65F6\u5F02\u5E38",
  "provision.tenantspace.save.fail.107-015": "\u89D2\u8272\u4FEE\u6539\u5931\u8D25\u5F02\u5E38",
  "provision.tenantspace.save.fail.107-032": "\u8BA2\u9605\u63E1\u624B\u5931\u8D25\u5F02\u5E38",
  "provision.tenantspace.save.fail.107-022": "VDN\u4FEE\u6539\u5931\u8D25\u5F02\u5E38",
  "provision.tenantspace.save.fail.107-031": "\u8BA2\u9605\u6CE8\u9500\u5931\u8D25\u5F02\u5E38",
  "provision.tenantspace.save.fail.107-033": "\u7CFB\u7EDF\u63A5\u5165\u7801\u589E\u52A0\u5931\u8D25\u5F02\u5E38",
  "provision.tenantspace.save.fail.107-035": "\u7CFB\u7EDF\u63A5\u5165\u7801\u67E5\u8BE2\u5931\u8D25\u5F02\u5E38",
  "provision.tenantspace.save.fail.107-036": "\u7CFB\u7EDF\u63A5\u5165\u7801\u5220\u9664\u5931\u8D25\u5F02\u5E38",
  "provision.tenantspace.save.fail.107-037": "IVR\u670D\u52A1\u8FDB\u7A0B\u589E\u52A0\u5931\u8D25\u5F02\u5E38",
  "provision.tenantspace.save.fail.107-039": "IVR\u670D\u52A1\u8FDB\u7A0B\u67E5\u8BE2\u5931\u8D25\u5F02\u5E38",
  "provision.tenantspace.save.fail.107-038": "IVR\u670D\u52A1\u8FDB\u7A0B\u4FEE\u6539\u5931\u8D25\u5F02\u5E38",
  "provision.tenantspace.save.fail.107-040": "IVR\u670D\u52A1\u8FDB\u7A0B\u5220\u9664\u5931\u8D25\u5F02\u5E38",
  "provision.tenantspace.save.fail.107-041": "\u7CFB\u7EDFVDN\u65B0\u589E\u5931\u8D25\u5F02\u5E38",
  "provision.tenantspace.save.fail.107-042": "\u7CFB\u7EDFVDN\u4FEE\u6539\u5931\u8D25\u5F02\u5E38",
  "provision.tenantspace.save.fail.107-043": "\u7CFB\u7EDFVDN\u67E5\u8BE2\u5931\u8D25\u5F02\u5E38",
  "provision.tenantspace.save.fail.107-045": "\u7CFB\u7EDFVDN\u5220\u9664VDNID\u4E0EVDNName\u4E0D\u5339\u914D\u5F02\u5E38\u7801",
  "provision.tenantspace.save.fail.107-044": "\u7CFB\u7EDFVDN\u5220\u9664\u5931\u8D25\u5F02\u5E38",
  "provision.tenantspace.save.fail.107-064": "\u7CFB\u7EDFUAP\u591A\u5A92\u4F53\u7528\u6237\u589E\u52A0\u5931\u8D25",
  "provision.tenantspace.save.fail.107-034": "\u7CFB\u7EDF\u63A5\u5165\u7801\u4FEE\u6539\u5931\u8D25\u5F02\u5E38",
  "provision.tenantspace.save.fail.107-065": "\u7CFB\u7EDFUAP\u591A\u5A92\u4F53\u7528\u6237\u67E5\u8BE2\u5931\u8D25",
  "provision.tenantspace.save.fail.107-067": "\u7CFB\u7EDFUAP\u591A\u5A92\u4F53\u7528\u6237\u4FEE\u6539\u5BC6\u7801\u5931\u8D25",
  "provision.tenantspace.save.fail.107-066": "UAP\u914D\u7F6E\u5F02\u5E38",
  "provision.tenantspace.save.fail.107-068": "\u7CFB\u7EDFUAP\u591A\u5A92\u4F53\u7528\u6237\u5220\u9664\u5931\u8D25",
  "provision.tenantspace.save.fail.107-069": "\u7CFB\u7EDFuap\u67E5\u8BE2\u6CE8\u518C\u72B6\u6001\u5931\u8D25",
  "provision.tenantspace.save.fail.107-070": "\u7CFB\u7EDFuap\u6CE8\u9500\u6CE8\u518C\u5931\u8D25",
  "provision.tenantspace.save.fail.107-071": "UAP \u7528\u6237\u53F7\u7801\u4E3Anull",
  "provision.tenantspace.save.fail.107-072": "UAP \u7528\u6237\u53F7\u7801\u4E0D\u5408\u6CD5",
  "provision.tenantspace.save.fail.107-073": "UAP IP\u5730\u5740\u4E3Anull",
  "provision.tenantspace.save.fail.107-076": "UAP \u5BC6\u7801\u4E0E\u786E\u8BA4\u5BC6\u7801\u4E0D\u4E00\u81F4",
  "provision.tenantspace.save.fail.107-074": "UAP \u5BC6\u7801\u4E3Anull",
  "provision.tenantspace.save.fail.107-075": "UAP \u5BC6\u7801\u957F\u5EA6\u4E0D\u5728\u8303\u56F4",
  "provision.tenantspace.save.fail.107-078": "UAP \u5F00\u59CB\u53F7\u7801\u548C\u7ED3\u675F\u53F7\u7801\u957F\u5EA6\u4E0D\u4E00\u81F4",
  "provision.tenantspace.save.fail.107-079": "UAP \u7ED3\u675F\u53F7\u7801\u5C0F\u4E8E\u5F00\u59CB\u53F7\u7801",
  "provision.tenantspace.save.fail.107-077": "UAP IP\u5730\u5740\u7C7B\u578B\u4E0D\u652F\u6301",
  "provision.tenantspace.save.fail.107-080": "UAP \u53F7\u7801\u6570\u91CF\u8D85\u51FA\u9650\u5236",
  "provision.tenantspace.save.fail.107-081": "UAP \u6821\u9A8Cvdn\u6743\u9650\u5931\u8D25\u3001vdn\u4E0D\u5305\u542B\u8BE5\u7528\u6237\u53F7\u7801",
  "provision.tenantspace.save.fail.107-082": "\u7B7E\u5165UAP\u5931\u8D25\u6216\u8005\u4E0EUAP\u65AD\u8FDE",
  "provision.tenantspace.save.fail.107-084": "UAP \u83B7\u53D6\u4EA7\u54C1\u7C7B\u578B\u5931\u8D25",
  "provision.tenantspace.save.fail.107-083": "UAP \u53D1\u9001mml\u547D\u4EE4\u5931\u8D25",
  "provision.tenantspace.save.fail.107-085": "UAP \u4EA7\u54C1\u7C7B\u578B\u65E0\u6CD5\u8BC6\u522B",
  "provision.tenantspace.save.fail.107-086": "UAP \u5B58\u5728\u53F7\u7801\u6CA1\u6709\u914D\u7F6E\u547C\u53EB\u5B57\u51A0",
  "provision.tenantspace.save.fail.107-087": "UAP \u6CE8\u9500\u53F7\u7801\u672A\u6CE8\u518C",
  "provision.tenantspace.save.fail.107-107": "ipccbase\u7684configproxy\u4F7F\u7528",
  "provision.tenantspace.save.fail.120000": "\u63A5\u53E3\u9519\u8BEF",
  "provision.tenantspace.save.fail.120001": "\u6279\u5904\u7406\u6700\u5927\u4E2A\u6570\u4E3A500",
  "provision.tenantspace.save.fail.120002": "\u914D\u7F6E\u5DF2\u7ECF\u5B58\u5728",
  "provision.tenantspace.save.fail.120003": "\u914D\u7F6E\u4E0D\u5B58\u5728",
  "provision.tenantspace.save.fail.120004": "\u5F00\u901A\u7684VDN\u5DF2\u8FBE\u5230\u6700\u5927\u6570\u91CF\,\u64CD\u4F5C\u5931\u8D25",
  "provision.tenantspace.save.fail.120005": "\u914D\u7F6E\u9879\u6821\u9A8C\u5931\u8D25",
  "provision.tenantspace.save.fail.120006": "\u7EBF\u7A0B\u8FBE\u5230\u6700\u5927\u4E2A\u6570",

  "provision.tenantspace.save.fail.120601": "VDN\u9ED8\u8BA4\u7BA1\u7406\u5458\u4E0D\u80FD\u4FEE\u6539\u540D\u79F0",

  "provision.tenantspace.save.fail.120603": "VDN\u9ED8\u8BA4\u7BA1\u7406\u5458\u4E0D\u80FD\u4FEE\u6539\u89D2\u8272",
  "provision.tenantspace.save.fail.120604": "\u4E1A\u52A1\u4EE3\u8868\u4E3A\u9ED8\u8BA4VDN\u7BA1\u7406\u5458,\u4E0D\u80FD\u5220\u9664",
  "provision.tenantspace.save.fail.120605": "\u4E1A\u52A1\u4EE3\u8868\u4E3A\u73ED\u7EC4\u957F,\u4E0D\u80FD\u5220\u9664",
  "provision.tenantspace.save.fail.120606": "\u4E1A\u52A1\u4EE3\u8868\u88AB\u6D41\u7A0B\u5F15\u7528,\u4E0D\u80FD\u5220\u9664",
  "provision.tenantspace.save.fail.120607": "\u4E1A\u52A1\u4EE3\u8868\u88AB\u7F3A\u7701\u79C1\u6709\u6280\u80FD\u961F\u5217\u5F15\u7528,\u4E0D\u80FD\u5220\u9664",
  "provision.tenantspace.save.fail.120608": "\u4E1A\u52A1\u4EE3\u8868\u88AB\u6280\u80FD\u961F\u5217\u5F15\u7528,\u4E0D\u80FD\u5220\u9664",
  "provision.tenantspace.save.fail.120610": "\u4E1A\u52A1\u4EE3\u8868\u5E94\u7528\u7684\u73ED\u7EC4\u4E0D\u5B58\u5728",
  "provision.tenantspace.save.fail.120609": "\u4E1A\u52A1\u4EE3\u8868\u88AB\u5176\u5B83\u4E1A\u52A1\u4EE3\u8868\u5F15\u7528,\u4E0D\u80FD\u5220\u9664",
  "provision.tenantspace.save.fail.120611": "\u4E1A\u52A1\u4EE3\u8868\u5F15\u7528\u7684\u6280\u80FD\u961F\u5217\u4E0D\u5B58\u5728",
  "provision.tenantspace.save.fail.120612": "\u4E1A\u52A1\u4EE3\u8868\u5F15\u7528\u7684\u6280\u80FD\u7EC4\u4E0D\u5B58\u5728",
  "provision.tenantspace.save.fail.120613": "\u4E1A\u52A1\u4EE3\u8868\u5F15\u7528\u7684\u4E1A\u52A1\u4EE3\u8868\u89D2\u8272\u4E0D\u5B58\u5728",
  "provision.tenantspace.save.fail.120614": "\u4E1A\u52A1\u4EE3\u8868\u5F15\u7528\u7684\u5176\u5B83\u4E1A\u52A1\u4EE3\u8868\u4E0D\u5B58\u5728",
  "provision.tenantspace.save.fail.120615": "\u4E1A\u52A1\u4EE3\u8868\u5F15\u7528\u7684\u6D41\u7A0B\u4E0D\u5B58\u5728",
  "provision.tenantspace.save.fail.120616": "\u4E1A\u52A1\u4EE3\u8868\u5F15\u7528\u7684\u88AB\u53EB\u914D\u7F6E\u4E0D\u5B58\u5728",
  "provision.tenantspace.save.fail.120602": "VDN\u9ED8\u8BA4\u7BA1\u7406\u5458\u4E0D\u80FD\u4FEE\u6539\u5BC6\u7801",
  "provision.tenantspace.save.fail.120617": "\u4E1A\u52A1\u4EE3\u8868\u5F15\u7528\u7684\u76D8\u7B26\u4E0D\u5B58\u5728",
  "provision.tenantspace.save.fail.120618": "\u4E1A\u52A1\u4EE3\u8868\u5DE5\u53F7\u4E0E\u73ED\u957F\u5DE5\u53F7\u91CD\u590D",
  "provision.tenantspace.save.fail.120620": "\u4E1A\u52A1\u4EE3\u8868\u5BC6\u7801\u957F\u5EA6\u5C0F\u4E8E\u5B89\u5168\u914D\u7F6E\u6700\u5C0F\u5BC6\u7801\u957F\u5EA6:{0}",
  "provision.tenantspace.save.fail.120621": "\u5BC6\u7801\u8981\u6C42\u5305\u542B\u5982\u4E0B\u56DB\u79CD\u5B57\u7B26\u7684\u81F3\u5C11\u4E09\u79CD\uFF1A\u82F1\u6587\u5927\u5199\u5B57\u6BCD\u3001\u82F1\u6587\u5C0F\u5199\u5B57\u6BCD\u3001\u6570\u5B57\u3001\u975E\u5B57\u6BCD\u6570\u5B57\u5B57\u7B26\uFF0C\u4E14\u4E0D\u80FD\u548C\u5E10\u53F7\u6216\u5E10\u53F7\u7684\u9006\u5E8F\u76F8\u540C",
  "provision.tenantspace.save.fail.120622": "\u4E1A\u52A1\u4EE3\u8868\u4E3A\u73ED\u7EC4\u957F,\u4E0D\u80FD\u8FDB\u884C\u6362\u7EC4\u64CD\u4F5C",
  "provision.tenantspace.save.fail.120619": "\u4E1A\u52A1\u4EE3\u8868\u5DE5\u53F7\u4E0D\u80FD\u91CD\u590D",
  "provision.tenantspace.save.fail.123051": "\u4E1A\u52A1\u4EE3\u8868\u89D2\u8272\u7684\u540D\u79F0\u91CD\u590D",
  "provision.tenantspace.save.fail.123052": "\u4E1A\u52A1\u4EE3\u8868\u89D2\u8272\u88AB\u4E1A\u52A1\u4EE3\u8868\u5F15\u7528,\u4E0D\u80FD\u5220\u9664",
  "provision.tenantspace.save.fail.123053": "\u4E1A\u52A1\u4EE3\u8868\u89D2\u8272\u4E3A\u9ED8\u8BA4\u89D2\u8272,\u4E0D\u80FD\u4FEE\u6539",

  "provision.tenantspace.save.fail.120953": "\u6280\u80FD\u961F\u5217\u7684\u6280\u80FD\u5143\u7D20\u91CD\u590D",
  "provision.tenantspace.save.fail.120954": "\u6280\u80FD\u961F\u5217\u5F15\u7528\u7684\u4E1A\u52A1\u7C7B\u578B\u4E0D\u5B58\u5728",

  "provision.tenantspace.save.fail.120955": "\u6280\u80FD\u961F\u5217\u5F15\u7528\u7684\u6280\u80FD\u961F\u5217\u4E0D\u5B58\u5728",
  "provision.tenantspace.save.fail.120957": "\u6280\u80FD\u961F\u5217\u5F15\u7528\u7684\u6D41\u7A0B\u4E0D\u5B58\u5728",
  "provision.tenantspace.save.fail.120956": "\u6280\u80FD\u961F\u5217\u5F15\u7528\u7684\u4E1A\u52A1\u4EE3\u8868\u4E0D\u5B58\u5728",
  "provision.tenantspace.save.fail.120959": "\u6280\u80FD\u961F\u5217\u88AB\u7F3A\u7701\u79C1\u6709\u6280\u80FD\u961F\u5217\u5F15\u7528,\u4E0D\u80FD\u5220\u9664",
  "provision.tenantspace.save.fail.120960": "\u6280\u80FD\u961F\u5217\u88AB\u5176\u5B83\u6280\u80FD\u961F\u5217\u5F15\u7528,\u4E0D\u80FD\u5220\u9664",
  "provision.tenantspace.save.fail.120961": "\u6280\u80FD\u961F\u5217\u88ABCDN\u5F15\u7528,\u4E0D\u80FD\u5220\u9664",
  "provision.tenantspace.save.fail.120962": "\u6280\u80FD\u961F\u5217\u88AB\u5BA2\u6237\u7FA4\u7EC4\u5F15\u7528,\u4E0D\u80FD\u5220\u9664",
  "provision.tenantspace.save.fail.120958": "\u6280\u80FD\u961F\u5217\u5F15\u7528\u7684\u88AB\u53EB\u914D\u7F6E\u4E0D\u5B58\u5728",
  "provision.tenantspace.save.fail.120965": "\u6280\u80FD\u961F\u5217\u88AB\u88AB\u53EB\u914D\u7F6E\u5F15\u7528,\u4E0D\u80FD\u5220\u9664",
  "provision.tenantspace.save.fail.120963": "\u6280\u80FD\u961F\u5217\u88AB\u4E1A\u52A1\u4EE3\u8868\u5F15\u7528,\u4E0D\u80FD\u5220\u9664",
  "provision.tenantspace.save.fail.120966": "\u6280\u80FD\u961F\u5217\u5F15\u7528\u7684\u76D8\u7B26\u4E0D\u5B58\u5728",
  "provision.tenantspace.save.fail.120967": "\u6280\u80FD\u961F\u5217\u5A92\u4F53\u7C7B\u578B\u4E3A\u4F20\u771F\u547C\u53EB,\u5FC5\u987B\u914D\u7F6E\u90AE\u7BB1,\u90AE\u7BB1\u7528\u6237",
  "provision.tenantspace.save.fail.120968": "\u6280\u80FD\u961F\u5217\u7684\u6700\u4F4E\u63A5\u901A\u7387\u4E0D\u80FD\u8D85\u8FC7\u671F\u671B\u63A5\u901A\u7387",
  "provision.tenantspace.save.fail.120969": "\u6280\u80FD\u961F\u5217\u88ABACDDN\u5F15\u7528,\u4E0D\u80FD\u5220\u9664",
  "provision.tenantspace.save.fail.120964": "\u6280\u80FD\u961F\u5217\u88AB\u6D41\u7A0B\u5F15\u7528,\u4E0D\u80FD\u5220\u9664",
  "provision.tenantspace.save.fail.120970": "\u6280\u80FD\u961F\u5217\u88AB\u5171\u4EAB\u8BBE\u5907\u5F15\u7528,\u4E0D\u80FD\u5220\u9664",
  "provision.tenantspace.save.fail.120971": "\u6280\u80FD\u961F\u5217\u6280\u80FD\u7EC4\u5F15\u7528,\u4E0D\u80FD\u5220\u9664",
  "provision.tenantspace.save.fail.120972": "\u6280\u80FD\u961F\u5217\u5F15\u7528\u7684\u5B50\u5A92\u4F53\u7C7B\u578B\u4E0D\u5B58\u5728",
  "provision.tenantspace.save.fail.123002": "\u6280\u80FD\u5143\u7D20\u4E0D\u5B58\u5728",
  "provision.tenantspace.save.fail.123001": "\u6280\u80FD\u5143\u7D20\u7684\u540D\u79F0\u91CD\u590D",
  "provision.tenantspace.save.fail.123004": "\u6280\u80FD\u5143\u7D20\u88AB\u6280\u80FD\u961F\u5217\u5F15\u7528,\u4E0D\u80FD\u4FEE\u6539\u540D\u79F0",
  "provision.tenantspace.save.fail.123003": "\u6280\u80FD\u5143\u7D20\u88AB\u6280\u80FD\u961F\u5217\u5F15\u7528,\u4E0D\u80FD\u5220\u9664",
  "provision.tenantspace.save.fail.120902": "\u4E1A\u52A1\u7C7B\u578B\u88AB\u6D41\u7A0B\u5F15\u7528,\u4E0D\u80FD\u5220\u9664",
  "provision.tenantspace.save.fail.120903": "\u4E1A\u52A1\u7C7B\u578B\u88AB\u6280\u80FD\u961F\u5217\u5F15\u7528,\u4E0D\u80FD\u5220\u9664",
  "provision.tenantspace.save.fail.120904": "\u4E1A\u52A1\u7C7B\u578B\u88AB\u5DE5\u4F5C\u6807\u51C6\u65F6\u95F4\u5F15\u7528,\u4E0D\u80FD\u5220\u9664",

  "provision.tenantspace.save.fail.120905": "\u4E1A\u52A1\u7C7B\u578B\u5F15\u7528\u7684\u76D8\u7B26\u4E0D\u5B58\u5728",
  "provision.tenantspace.save.fail.120651": "\u88AB\u53EB\u914D\u7F6E\u7684\u7CFB\u7EDF\u63A5\u5165\u7801\u6CA1\u6709\u5206\u914D",
  "provision.tenantspace.save.fail.120655": "\u88AB\u53EB\u914D\u7F6E\u5F15\u7528\u7684\u63A5\u5165\u7801\u4E0D\u5B58\u5728",
  "provision.tenantspace.save.fail.120652": "\u88AB\u53EB\u914D\u7F6E\u7684\u7CFB\u7EDF\u63A5\u5165\u7801\u548C\u540E\u7EED\u63A5\u5165\u7801\u7EC4\u5408\u4E0D\u80FD\u91CD\u590D",
  "provision.tenantspace.save.fail.120658": "\u8BBE\u5907\u7C7B\u578B\u4E3AIVR,\u63A5\u5165\u7801\u7684\u5A92\u4F53\u7C7B\u578B\u53EA\u80FD\u4E3A\u70B9\u51FB\u901A\u8BDD,\u56DE\u547C\u8BF7\u6C42,\u666E\u901A\u547C\u53EB",
  "provision.tenantspace.save.fail.120901": "\u4E1A\u52A1\u7C7B\u578B\u7684\u63CF\u8FF0\u91CD\u590D",
  "provision.tenantspace.save.fail.120656": "\u88AB\u53EB\u914D\u7F6E\u5F15\u7528\u7684\u6280\u80FD\u961F\u5217\u4E0D\u5B58\u5728",
  "provision.tenantspace.save.fail.120660": "\u88AB\u53EB\u914D\u7F6E\u7684\u7CFB\u7EDF\u63A5\u5165\u7801\u540C\u88AB\u53EB\u914D\u7F6E\u8BBE\u5907\u7684\u5A92\u4F53\u7C7B\u578B\u4E0D\u4E00\u81F4",
  "provision.tenantspace.save.fail.120661": "\u88AB\u53EB\u914D\u7F6E\u88AB\u7F3A\u7701\u79C1\u6709\u6280\u80FD\u961F\u5217\u5F15\u7528,\u4E0D\u80FD\u5220\u9664",
  "provision.tenantspace.save.fail.120662": "\u88AB\u53EB\u914D\u7F6E\u88AB\u6280\u80FD\u961F\u5217\u5F15\u7528,\u4E0D\u80FD\u5220\u9664",
  "provision.tenantspace.save.fail.120663": "\u88AB\u53EB\u914D\u7F6E\u88AB\u4E1A\u52A1\u4EE3\u8868\u5F15\u7528,\u4E0D\u80FD\u5220\u9664",

  "provision.tenantspace.save.fail.120659": "\u88AB\u53EB\u914D\u7F6E\u5F15\u7528\u7684\u8DEF\u7531\u811A\u672C\u4E0D\u5B58\u5728",
  "provision.tenantspace.save.fail.120217": "\u540C\u4E00\u4E2AIP\u5730\u5740\u7684IVR\u6700\u5927\u4E2A\u6570\u4E3A20",
  "provision.tenantspace.save.fail.120216": "IVR\u548CCCIVR\u95F4\u4E0D\u80FD\u4FEE\u6539",
  "provision.tenantspace.save.fail.120220": "IVR\u88ABVDN\u7EC4\u5F15\u7528,\u4E0D\u80FD\u5220\u9664",
  "provision.tenantspace.save.fail.120221": "IVR\u5F15\u7528\u7684\u53C2\u6570\u7EC4\u4E0D\u5B58\u5728",

  "provision.tenantspace.save.fail.120219": "IVR\u88ABVDN\u5F15\u7528,\u4E0D\u80FD\u5220\u9664",
  "provision.tenantspace.save.fail.120222": "IVR\u5F15\u7528\u7684CTI\u8282\u70B9\u4E0D\u5B58\u5728",
  "provision.tenantspace.save.fail.120502": "\u5E76\u53D1\u8BED\u97F3\u6700\u5927\u547C\u53EB\u6570\u8D85\u8FC7\u9650\u5236",
  "provision.tenantspace.save.fail.120501": "\u540C\u65F6\u7B7E\u5165\u7684\u6700\u5927\u4E1A\u52A1\u4EE3\u8868\u6570\u8D85\u8FC7\u9650\u5236",
  "provision.tenantspace.save.fail.120503": "\u5E76\u53D1Web\u6700\u5927\u547C\u53EB\u6570\u8D85\u8FC7\u9650\u5236",
  "provision.tenantspace.save.fail.120504": "\u6700\u5927\u5E76\u53D1OutBound\u547C\u51FA\u6570\u8D85\u8FC7\u9650\u5236",
  "provision.tenantspace.save.fail.120506": "\u540C\u65F6\u7B7E\u5165\u7684\u6700\u5927\u89C6\u9891\u4E1A\u52A1\u4EE3\u8868\u6570\u8D85\u8FC7\u9650\u5236",
  "provision.tenantspace.save.fail.120507": "\u9650\u5236\u4E3B\u53EB\u53F7\u7801\u4E0D\u80FD\u5B58\u5728\u5305\u542B\u5173\u7CFB",
  "provision.tenantspace.save.fail.120505": "CP\u4F1A\u573A\u65B9\u6570\u8D85\u8FC7\u9650\u5236",
  "provision.tenantspace.save.fail.120508": "\u5E94\u7528\u9650\u5236\uFF0C\u9650\u5236\u4E3B\u53EB\u53F7\u7801\u524D\u7F00\u4E0D\u80FD\u4E3A\u7A7A",
  "provision.tenantspace.save.fail.120509": "\u5E94\u7528\u9650\u5236\uFF0C\u9ED8\u8BA4\u4E3B\u53EB\u4EE5\u9650\u5236\u4E3B\u53EB\u53F7\u7801\u524D\u7F00\u5F00\u5934",
  "provision.tenantspace.save.fail.120510": "VDN\u540D\u79F0\u4E0E\u5176\u5B83VDN\u540D\u79F0\u91CD\u590D",
  "provision.tenantspace.save.fail.120511": "VDN\u540D\u79F0\u4E0EVDN\u7EC4\u540D\u79F0\u91CD\u590D",
  "provision.tenantspace.save.fail.120512": "VDN\u5DE5\u53F7\u8303\u56F4\u4E0E\u5176\u5B83VDN\u91CD\u590D",
  "provision.tenantspace.save.fail.120514": "VDN\u5F15\u7528\u7684\u5206\u5E03\u5F0F\u8D44\u6E90\u4E0D\u5B58\u5728",
  "provision.tenantspace.save.fail.120516": "VDN\u5F15\u7528\u7684IVR\u4E0D\u5B58\u5728",
  "provision.tenantspace.save.fail.120515": "VDN\u5F15\u7528\u7684\u6570\u636E\u6E90\u4E0D\u5B58\u5728",
  "provision.tenantspace.save.fail.120513": "VDN\u5F15\u7528\u7684OBS\u4E0D\u5B58\u5728",
  "provision.tenantspace.save.fail.120517": "RMS\u57FA\u672C\u4FE1\u606F\u914D\u7F6E\u4E2D\u5F55\u97F3\u7CFB\u7EDF\u4E3A\u65E0\u65F6\uFF0C\u672CVDN\u91C7\u7528\u7684\u5F55\u97F3\u7CFB\u7EDF\u53EA\u80FD\u914D\u7F6E\u4E3A\u534E\u4E3A\u6392\u961F\u673A\u8D44\u6E90\u5F55\u97F3",
  "provision.tenantspace.save.fail.120520": "VDN\u5F15\u7528\u7684\u7CFB\u7EDF\u63A5\u5165\u7801\u4E0D\u5B58\u5728",
  "provision.tenantspace.save.fail.120519": "VDN\u5F3A\u5236\u81EA\u5F55\u97F3\u4E2D\u4F7F\u7528\u7684\u5206\u5E03\u5F0F\u8282\u70B9\u5728VDN\u8BBE\u7F6E\u4E2D\u672A\u5206\u914D",
  "provision.tenantspace.save.fail.120522": "VDN\u7684\u547C\u53EB\u7C7B\u578B\u4E0D\u652F\u6301Web\uFF0C\u5F53\u524DVDN\u5206\u914D\u4E86\u8BE5\u5A92\u4F53\u7C7B\u578B\u7684\u7CFB\u7EDF\u63A5\u5165\u7801",
  "provision.tenantspace.save.fail.120521": "VDN\u7684\u547C\u53EB\u7C7B\u578B\u4E0D\u652F\u6301Email/Fax\uFF0C\u5F53\u524DVDN\u5206\u914D\u4E86\u8BE5\u5A92\u4F53\u7C7B\u578B\u7684\u7CFB\u7EDF\u63A5\u5165\u7801",
  "provision.tenantspace.save.fail.120523": "VDN\u5206\u914D\u7684\u7CFB\u7EDF\u63A5\u5165\u7801\u5DF2\u7ECF\u5206\u914D\u5230\u5176\u5B83VDN",
  "provision.tenantspace.save.fail.120525": "IVR\u6570\u636E\u6E90\u4E0D\u5728VDN\u5206\u914D\u7684VDN\u6570\u636E\u6E90\u6216\u7B2C\u4E09\u65B9\u6570\u636E\u6E90\u4E2D",
  "provision.tenantspace.save.fail.120527": "\u8BE5VDN\u88AB\u672C\u5730CCP\u5BA2\u6237\u7AEF\u5F15\u7528\uFF0C\u4E0D\u80FD\u5220\u9664",
  "provision.tenantspace.save.fail.120526": "\u9ED8\u8BA4IVR\u6570\u636E\u6E90\u4E0D\u5728VDN\u5206\u914D\u7684VDN\u6570\u636E\u6E90\u6216\u7B2C\u4E09\u65B9\u6570\u636E\u6E90\u4E2D",
  "provision.tenantspace.save.fail.120528": "\u542F\u7528\u5EA7\u5E2D\u6302\u8D77\u8F6C\uFF0C\u547C\u53EB\u8F6C\u56DE\u5EA7\u5E2D\u540E\u653E\u63D0\u793A\u97F3\uFF0C\u5FC5\u987B\u914D\u7F6E\u63D0\u793A\u97F3\u53C2\u6570",
  "provision.tenantspace.save.fail.120524": "VDN\u5206\u914D\u7684\u7CFB\u7EDF\u63A5\u5165\u7801\u5DF2\u7ECF\u5206\u914D\u5230VDN\u7EC4",
  "provision.tenantspace.save.fail.120533": "VDN\u5DE5\u53F7\u8303\u56F4\u91CD\u590D",
  "provision.tenantspace.save.fail.120529": "VDN\u7BA1\u7406\u5458\u5FC5\u987B\u5728\u5DE5\u53F7\u8303\u56F4\u5185",
  "provision.tenantspace.save.fail.120531": "VDN\u57FA\u672C\u4FE1\u606F\u7684\u63A5\u5165\u7801\u6CA1\u6709\u5206\u914D\u5F53\u524DVDN",
  "provision.tenantspace.save.fail.120530": "VDN\u5F15\u7528\u7684\u76D8\u7B26\u4E0D\u5B58\u5728",
  "provision.tenantspace.save.fail.120534": "VDN\u5F15\u7528\u7684CCS\u8D44\u6E90\u7EC4\u4E0D\u5B58\u5728",
  "provision.tenantspace.save.fail.120535": "\u5DE5\u53F7\u8303\u56F4\u5DF2\u4F7F\u7528\uFF0C\u4E0D\u80FD\u53D6\u6D88",
  "provision.tenantspace.save.fail.120536": "\u53EF\u7528VDN\u7684\u957F\u5EA6\u4E0D\u80FD\u4E3A0",
  "provision.tenantspace.save.fail.120537": "\u53EF\u7528VDN\u4E0D\u80FD\u5305\u542B\u8F6C\u79FBVDN",
  "provision.tenantspace.save.fail.120538": "\u53EF\u8F6C\u79FB\u5DE5\u53F7\u4E2A\u6570\u4E0D\u8DB3",
  "provision.tenantspace.save.fail.120540": "\u591A\u5A92\u4F53\u8BB8\u53EF\u503C\u8D85\u51FA\u8303\u56F4",
  "provision.tenantspace.save.fail.120624": "\u4FEE\u6539\u7684vdn\u5BF9\u63A5\u5BC6\u7801\u4E0E\u65E7\u5BC6\u7801\u76F8\u540C",
  "provision.tenantspace.save.fail.120539": "\u591A\u5A92\u4F53\u8BB8\u53EF\u7C7B\u578B\u65E0\u6548",

  "provision.tenantspace.save.fail.120541": "\u5EA7\u5E2D\u5E76\u53D1\u5904\u7406\u80FD\u529B1~60",
  "provision.tenantspace.save.fail.120202": "\u7CFB\u7EDF\u63A5\u5165\u7801\u4E0E\u7279\u6B8A\u8DEF\u7531\u63A5\u5165\u7801\u91CD\u590D",
  "provision.tenantspace.save.fail.120203": "\u7CFB\u7EDF\u63A5\u5165\u7801\u4E0ENIRC\u7684\u7F51\u7EDC\u547C\u53EB\u63A5\u5165\u7801\u91CD\u590D",
  "provision.tenantspace.save.fail.120204": "\u7CFB\u7EDF\u63A5\u5165\u7801\u88ABVDN\u5F15\u7528,\u4E0D\u80FD\u5220\u9664",
  "provision.tenantspace.save.fail.120201": "\u7CFB\u7EDF\u63A5\u5165\u7801\u91CD\u590D",
  "provision.tenantspace.save.fail.120205": "\u7CFB\u7EDF\u63A5\u5165\u7801\u88ABVDN\u7EC4\u5F15\u7528,\u4E0D\u80FD\u5220\u9664",
  "ccprovision.vcallecenter.config.popup.tab.administrator.modifypassword": "\u4FEE\u6539\u7BA1\u7406\u5458\u5BC6\u7801",

  "ccprovision.vcallecenter.config.popup.modifyinfo": "\u5BC6\u7801\u4FEE\u6539",
  "ccprovision.vcallecenter.config.popup.tab.configurationinterface.modifypassword": "\u4FEE\u6539\u914D\u7F6E\u63A5\u53E3\u5BC6\u7801",
  "ccprovision.vcallecenter.config.popup.adminworkno": "\u914D\u7F6E\u7BA1\u7406\u5458\u5DE5\u53F7",
  "ccprovision.vcallecenter.config.popup.adminpassword": "\u914D\u7F6E\u7BA1\u7406\u5458\u5BC6\u7801\u4FEE\u6539",
  "ccprovision.vcallecenter.config.popup.enternewpassword": "\u8BF7\u8F93\u5165\u65B0\u5BC6\u7801",
  "ccprovision.vcallecenter.config.popup.confirmnewpassword": "\u91CD\u590D\u65B0\u5BC6\u7801",
  "ccprovision.vcallecenter.config.popup.curcountpassword": "\u5F53\u524D\u5E10\u53F7\u5BC6\u7801",
  "ccprovision.vcallecenter.error.updateinfo.adminpassword": "\u4FEE\u6539\u5BC6\u7801\u5931\u8D25",
  "ccprovision.vcallecenter.error.updateinfo.currentacountpasderror": "\u5F53\u524D\u5E10\u53F7\u5BC6\u7801\u8F93\u5165\u6709\u8BEF",
  "ccprovision.vcallecenter.sucess.updateinfo.adminpassword": "\u4FEE\u6539\u5BC6\u7801\u6210\u529F",
  "ccprovision.recorddrive.config.recorddrivenameconfig": "\u76D8\u7B26\u914D\u7F6E",
  "ccprovision.recorddrive.config.sftp.ipadress": "SFTP IP\u5730\u5740",
  "ccprovision.vcallecenter.config.popup.authentication": "\u8EAB\u4EFD\u9A8C\u8BC1",
  "ccprovision.recorddrive.config.sftp.account": "SFTP \u8D26\u53F7",
  "ccprovision.recorddrive.config.sftp.serverconfig": "SFTP Server\u914D\u7F6E",
  "ccprovision.recorddrive.config.sftp.port": "\u7AEF\u53E3\u53F7",
  "ccprovision.recorddrive.config.sftp.ipadressport": "SFTP \u7AEF\u53E3\u53F7",
  "ccprovision.recorddrive.config.sftp.filestoragepath": "\u6587\u4EF6\u5B58\u50A8\u8DEF\u5F84",
  "ccprovision.recorddrive.config.sftp.filestoragepathStrValidateFailed": "\u6587\u4EF6\u5B58\u50A8\u8DEF\u5F84\u4E0D\u80FD\u5305\u542B\u2018./\u2019",
  "ccprovision.recorddrive.config.sftp.accountname": "SFTP Server\u8D26\u53F7",
  "ccprovision.recorddrive.config.sftp.accountpassword": "SFTP Server\u5BC6\u7801",
  "provision.callcenterinstanceslist.vcallcentersftp.passLengthValidateFailed": "\u8F93\u5165\u7684\u5BC6\u7801\u957F\u5EA6\u5FC5\u987B\u57288\u523020\u4E4B\u95F4",
  "ccprovision.recorddrive.config.sftp.identitycheck": "\u8EAB\u4EFD\u6821\u9A8C",
  "ccprovision.recorddrive.config.sftp.accountconfirmpasd": "\u91CD\u590DSFTP Server\u5BC6\u7801",
  "ccprovision.recorddrive.config.sftp.identitychecktips": "\u68C0\u6D4B\u5230\u60A8\u5BF9SFTP Server\u7684\u8D26\u53F7\u6216\u5BC6\u7801\u8FDB\u884C\u4E86\u4FEE\u6539\u3002\u4E3A\u4E86\u7EF4\u62A4\u4FE1\u606F\u5B89\u5168\uFF0C\u8BF7\u5148\u8F93\u5165\u60A8\u5F53\u524D\u4F7F\u7528\u7684\u8D26\u53F7\u5BC6\u7801",
  "ccprovision.recorddrive.config.sftp.identitychecktips.placeholder": "\u8BF7\u8F93\u5165\u60A8\u7684\u5F53\u524D\u8D26\u53F7\u5BC6\u7801",
  "provision.recorddrive.label.isfirstletter.yes": "\u662F",
  "provision.tenantspace.status.active.success": "\u6FC0\u6D3B\u6210\u529F",

  "provision.recorddrive.label.isfirstletter.no": "\u5426",
  "provision.tenantspace.status.active.fail": "\u6FC0\u6D3B\u5931\u8D25",
  "provision.tenantspace.status.stop.success": "\u6682\u505C\u6210\u529F",
  "provision.tenantspace.status.invalid.success": "\u5931\u6548\u6210\u529F\u3002",
  "provision.tenantspace.status.invalid.fail": "\u5931\u6548\u5931\u8D25",

  "provision.tenantspace.status.stop.fail": "\u6682\u505C\u5931\u8D25",
  "provision.tenant.management.tenantcreate.provision.failed": "\u672C\u5730\u521B\u5EFA\u79DF\u6237\u5931\u8D25",
  "provision.tenant.management.tenantcreate.sum.failed": "sum\u521B\u5EFA\u79DF\u6237\u5931\u8D25",
  "provision.tenantspace.sip.searchName": "\u8BF7\u8F93\u5165\u8981\u67E5\u8BE2\u7684SBC\u670D\u52A1\u5668\u540D\u79F0",
  "provision.tenantspace.ucs.searchName": "\u8BF7\u8F93\u5165\u8981\u67E5\u8BE2\u7684CCUCS\u670D\u52A1\u5668\u540D\u79F0",
  "provision.tenantspace.gw.searchName": "\u8BF7\u8F93\u5165\u8981\u67E5\u8BE2\u7684Gateway\u670D\u52A1\u5668\u540D\u79F0",
  "provision.tenantspace.ivr.searchName": "\u8BF7\u8F93\u5165\u8981\u67E5\u8BE2\u7684IVR\u7F16\u53F7",

  "provision.tenant.management.tenantcreate.dds.failed": "dds\u521B\u5EFA\u79DF\u6237\u5931\u8D25",
  "provision.tenantspace.error.deleteConfigTenantSpace": "\u65E0\u6CD5\u5220\u9664\u5DF2\u7ECF\u5206\u914D\u8D44\u6E90\u7684\u79DF\u6237",
  "provision.tenantspace.error.deleteProvision": "\u5220\u9664Provision\u79DF\u6237\u5931\u8D25",
  "provision.tenantspace.selectStastus.edit.invalid": "\u5931\u6548",
  "provision.tenantspace.selectStastus.edit.active": "\u6FC0\u6D3B",
  "provision.tenant.management.tenantcreate.inter.failed": "\u8C03\u7528\u5185\u90E8\u670D\u52A1\u521B\u5EFA\u79DF\u6237\u5931\u8D25",
  "provision.tenant.management.please.select": "\u8BF7\u9009\u62E9\.\.\.",
  "provision.tenant.management.tenantcreate.contact": "\u8054\u7CFB\u65B9\u5F0F",
  "provision.tenantspace.detail.apply.name": "\u7533\u8BF7\u8005\u540D\u79F0",
  "provision.tenantspace.detail.apply.accout": "\u7533\u8BF7\u8005\u8D26\u53F7",
  "provision.tenantspace.selectStastus.edit.stop": "\u6682\u505C",
  "provision.tenantspace.detail.create.time": "\u521B\u5EFA\u65F6\u95F4",
  "provision.tenantspace.detail.expiry.time": "\u5230\u671F\u65F6\u95F4",
  "provision.tenantspace.config.agent.videoagent.ivr.more.zero": "\u8BED\u97F3\u5EA7\u5E2D\u6570 + \u89C6\u9891\u5EA7\u5E2D\u6570 + \u591A\u5A92\u4F53\u5EA7\u5E2D\u6570 + \u97F3\u9891IVR\u901A\u9053\u6570 + \u89C6\u9891IVR\u901A\u9053\u6570\u4E0D\u80FD\u4E3A0",
  "provision.tenantspace.config.agent.ivr.more.zero": "\u8BED\u97F3\u5EA7\u5E2D\u6570 + \u89C6\u9891\u5EA7\u5E2D\u6570 + \u591A\u5A92\u4F53\u5EA7\u5E2D\u6570 + \u5168\u80FD\u578B\u5EA7\u5E2D\u6570 + \u97F3\u9891IVR\u901A\u9053\u6570 + \u89C6\u9891IVR\u901A\u9053\u6570\u4E0D\u80FD\u4E3A0",
  "provision.tenantspace.config.call.more.agent.ivr": "\u6700\u5927\u8BED\u97F3\u547C\u53EB\u5E76\u53D1\u6570\u5FC5\u987B\u5927\u4E8E\u6216\u7B49\u4E8E\u8BED\u97F3\u5EA7\u5E2D\u6570\u4E0E\u97F3\u89C6\u9891IVR\u901A\u9053\u6570\u4E4B\u548C",
  "provision.tenantspace.config.voicecall.more.agent.ivr.whithoutAms": "\u6700\u5927\u8BED\u97F3\u547C\u53EB\u5E76\u53D1\u6570\u5FC5\u987B\u5927\u4E8E\u6216\u7B49\u4E8E\u8BED\u97F3\u3001\u89C6\u9891\u3001\u5168\u80FD\u578B\u5EA7\u5E2D\u6570\u4E0E\u97F3\u89C6\u9891IVR\u901A\u9053\u6570\u4E4B\u548C\u3002",
  "provision.tenantspace.config.voicecall.more.agent.ivr.whithAms": "\u6700\u5927\u8BED\u97F3\u547C\u53EB\u5E76\u53D1\u6570\u5FC5\u987B\u5927\u4E8E\u6216\u7B49\u4E8E\u8BED\u97F3\u3001\u89C6\u9891\u3001\u591A\u5A92\u4F53\u3001\u5168\u80FD\u578B\u5EA7\u5E2D\u6570\u4E0E\u97F3\u89C6\u9891IVR\u901A\u9053\u6570\u4E4B\u548C\u3002",
  "provision.tenantspace.config.videocall.more.videoagent": "\u89C6\u9891\u547C\u53EB\u5E76\u53D1\u6570\u5FC5\u987B\u5927\u4E8E\u6216\u7B49\u4E8E\u89C6\u9891\u5EA7\u5E2D\u6570",
  "provision.tenantspace.config.select.default.phone": "\u8BF7\u9009\u62E9\u9ED8\u8BA4\u4E3B\u53EB\u53F7\u7801",
  "provision.tenantspace.config.select.default.phone.in.selectAccesscodes": "\u9ED8\u8BA4\u4E3B\u53EB\u53F7\u7801\u5FC5\u987B\u5728\u9009\u62E9\u7684\u63A5\u5165\u7801\u8303\u56F4\u5185",
  "provision.tenantspace.config.select.record": "\u8BF7\u9009\u62E9\u5F55\u97F3\u76D8\u7B26",
  "provision.tenantspace.config.select.record.time": "\u8BF7\u9009\u62E9\u5F55\u97F3\u4FDD\u6301\u5468\u671F",
  "provision.tenantspace.config.select.vitual.ccname": "\u8BF7\u586B\u5199\u865A\u62DF\u547C\u53EB\u4E2D\u5FC3\u540D\u79F0",


  "provision.tenantspace.config.param.error.agentCall": "\u8BF7\u8F93\u5165\u8BED\u97F3\u547C\u53EB\u5E76\u53D1\u6570",
  "provision.tenantspace.config.param.error.videoUsage": "\u8BF7\u8F93\u5165\u89C6\u9891\u5EA7\u5E2D\u6570",
  "provision.tenantspace.config.param.error.videoCall": "\u8BF7\u8F93\u5165\u89C6\u9891\u547C\u53EB\u5E76\u53D1\u6570",
  "provision.tenantspace.config.param.error.ivrNumber": "\u8BF7\u8F93\u5165IVR\u6570",
  "provision.tenantspace.config.param.error.agentUsage": "\u8BF7\u8F93\u5165\u8BED\u97F3\u5EA7\u5E2D\u6570",
  "provision.tenantspace.config.param.error.ttsNumber": "\u8BF7\u8F93\u5165TTS\u6570",
  "provision.tenantspace.table.invalidTenantSpace": "\u5931\u6548\u540E\u6570\u636E\u65E0\u6CD5\u6062\u590D\uFF0C\u8BF7\u786E\u8BA4\u662F\u5426\u9700\u8981\u5C06\u8BE5\u79DF\u6237\u5931\u6548\uFF1F",

  "provision.tenantspace.config.param.error.asrNumber": "\u8BF7\u8F93\u5165ASR\u6570",
  "provision.tenantspace.table.activeTenantSpace": "\u662F\u5426\u786E\u8BA4\u6FC0\u6D3B\u79DF\u6237",
  "provision.tenantspace.table.stopTenantSpace": "\u662F\u5426\u786E\u8BA4\u6682\u505C\u79DF\u6237",

  "provision.tenantspace.table.invalidTenantSpace.confirm": "\u5931\u6548\u786E\u8BA4",
  "provision.tenantspace.table.stopTenantSpace.confirm": "\u6682\u505C\u786E\u8BA4",
  "provision.tenantspace.table.activeTenantSpace.confirm": "\u6FC0\u6D3B\u786E\u8BA4",
  "provision.tenantspace.config.agent.zero": "\u8BED\u97F3\u5EA7\u5E2D\u6570\u4E0D\u80FD\u4E3A0",

  "provision.tenantspace.config.agentcall.zero": "\u8BED\u97F3\u547C\u53EB\u5E76\u53D1\u6570\u4E0D\u80FD\u4E3A0",
  "provision.tenantspace.detail.tenantspace.status.not.change": "\u79DF\u6237\u5904\u4E8E\u975E\u6FC0\u6D3B\u72B6\u6001\uFF0C\u65E0\u6CD5\u8FDB\u884C\u4FEE\u6539",

  "ccprovision.IVRSprogress.config.logincti.failed": "\u8FDE\u63A5\u5E73\u53F0\u5931\u8D25",
  "provision.callcent.error.abnormal": "\u5F02\u5E38",
  "provision.batchimport.message.uploaded.button": "\u4E0A\u4F20",

  "provision.tenantspace.config.agentusage.vidoeusage.ivr": "\u8BED\u97F3\u5EA7\u5E2D\u6570\u3001\u89C6\u9891\u5EA7\u5E2D\u6570\u3001\u591A\u5A92\u4F53\u5EA7\u5E2D\u6570\u3001\u8BED\u97F3IVR\u901A\u9053\u6570\u3001\u89C6\u9891IVR\u901A\u9053\u6570\u4E94\u9879\u81F3\u5C11\u586B\u4E00\u9879",
  "provision.tenantspace.table.maxcallnums": "\u6700\u5927\u8BED\u97F3\u547C\u53EB\u5E76\u53D1\u6570(>=\u8BED\u97F3\u5EA7\u5E2D\u6570\u4E0E\u97F3\u89C6\u9891IVR\u901A\u9053\u6570\u4E4B\u548C)",
  "provision.tenantspace.config.agentusage.ivr": "\u8BED\u97F3\u5EA7\u5E2D\u6570\u3001\u89C6\u9891\u5EA7\u5E2D\u6570\u3001\u591A\u5A92\u4F53\u5EA7\u5E2D\u6570\u3001\u5168\u80FD\u578B\u5EA7\u5E2D\u6570\u3001\u97F3\u9891IVR\u901A\u9053\u6570\u3001\u89C6\u9891IVR\u901A\u9053\u6570\u516D\u9879\u81F3\u5C11\u586B\u4E00\u9879",
  "provision.tenantspace.table.maxcallnumswithdesc": "\u6700\u5927\u8BED\u97F3\u547C\u53EB\u5E76\u53D1\u6570(>=\u5EA7\u5E2D\u6570\u4E0E\u97F3\u89C6\u9891IVR\u901A\u9053\u6570\u4E4B\u548C)",
  "provision.batchimport.message.import.button": "\u5BFC\u5165",
  "provision.tenantspace.table.maxVideoCallnums": "\u6700\u5927\u89C6\u9891\u547C\u53EB\u5E76\u53D1\u6570(>=\u89C6\u9891\u5EA7\u5E2D\u6570)",
  "provision.tenantspace.passValidateFailed": `\u5E94\u5305\u542B\u5927\u5199\u5B57\u6BCD\uFF0C\u5C0F\u5199\u5B57\u6BCD\uFF0C\u6570\u5B57\u548C\u7279\u6B8A\u5B57\u7B26~!{'@'}#$%&*()-_=+[{}];:""\,<.>/?`,
  "provision.tenantspace.statusRecordTime.24Month": "24\u4E2A\u6708",
  "provision.tenantspace.agentusage.plus.videousage": "\u8BED\u97F3\u5EA7\u5E2D\u6570\u548C\u89C6\u9891\u5EA7\u5E2D\u6570\u7684\u603B\u548C\u4E0D\u80FD\u8D85\u8FC710000",
  "provision.tenantspace.agentusage.plus.videousage.plus.maxVersatileAgents": "\u8BED\u97F3\u5EA7\u5E2D\u6570\uFF0C\u89C6\u9891\u5EA7\u5E2D\u6570\u548C\u5168\u80FD\u578B\u5EA7\u5E2D\u6570\u7684\u603B\u548C\u4E0D\u80FD\u8D85\u8FC710000",
  "provision.tenantspace.selectvideomodel.isnull": "\u79DF\u6237\u7533\u8BF7\u4F7F\u7528\u89C6\u9891\u8D44\u6E90\uFF0C\u8BF7\u9009\u62E9\u89C6\u9891\u5E94\u7528\u6A21\u5F0F\u3002",
  "provision.tenantspace.videoagentchannnels.limit": "\u89C6\u9891\u5EA7\u5E2D\u901A\u9053\u6570\u5FC5\u987B\u5927\u4E8E\u6216\u7B49\u4E8E2\u500D\u7684\u89C6\u9891\u5EA7\u5E2D\u6570",
  "provision.tenantspace.accesscode.searchName": "\u8BF7\u8F93\u5165\u8981\u67E5\u8BE2\u7684\u63A5\u5165\u7801",
  "provision.tenantspace.management": "\u79DF\u6237\u7BA1\u7406",
  "provision.tenantspace.create.onlineUserNumber": "\u5728\u7EBF\u5E76\u53D1\u6570",
  "provision.tenantspace.sfuprompt": "\u7CFB\u7EDF\u96C6\u6210\u63A5\u7EED\u672A\u9002\u914DSFU\u89C6\u9891\uFF0C\u5BA2\u6237\u53EF\u4E8C\u6B21\u5F00\u53D1\u9002\u914D\u4F7F\u7528\u6B64\u6A21\u5F0F\u89C6\u9891",

  "provision.tenant.management.tenantcreate.name.exist": "\u79DF\u6237\u540D\u5DF2\u5B58\u5728",
  "provision.tenant.management.tenantcreate.adminaccount.exist": "\u7BA1\u7406\u5458\u8D26\u53F7\u5DF2\u5B58\u5728",
  "provision.callcenterinstanceslist.vcallcenter.invalidVccListPromt": "AICC\u4E2D\u5B58\u5728\u5728WAS\u4E2D\u7F3A\u5931VDN\u7684\u865A\u62DF\u865A\u62DF\u547C\u53EB\u4E2D\u5FC3,VccID\u4E3A",
  "provision.callcenterinstanceslist.vcallcenter.syncVccSuccess": "\u540C\u6B65\u5B8C\u6210\u3002",
  "provision.callcenterinstanceslist.vcallcenter.invalidVdnListPromt": "WAS\u4E2D\u5B58\u5728\u5728AICC\u4E2D\u7F3A\u5931\u865A\u62DF\u547C\u53EB\u4E2D\u5FC3\u7684VDN,VdnID\u4E3A",
  "provision.callcenterinstanceslist.vcallcenter.nameDiffListPromt": "\u5B58\u5728VccName\u4E0EWAS\u4E2DVdnName\u4E0D\u4E00\u81F4\u7684\u865A\u62DF\u547C\u53EB\u4E2D\u5FC3,VccID\u4E3A",
  "provision.callcenterinstanceslist.vcallcenter.agentNumErrorListPromt": "\u5B58\u5728\u5EA7\u5E2D\u6570\u4E0EWAS\u4E2DVDN\u4E0D\u4E00\u81F4\u7684\u865A\u62DF\u547C\u53EB\u4E2D\u5FC3,VccID\u4E3A",
  "provision.callcenterinstanceslist.vcallcenter.comma": "\uFF1B",
  "provision.callcenterinstanceslist.vcallcenter.InProgress": "\u6B63\u5728\u8FDB\u884C\u4E2D\u3002",

  "provision.callcenterinstanceslist.vcallcenter.period": "\u3002",
  "provision.tenantspace.agentUsage.iszero": "\u8BF7\u5148\u914D\u7F6E\u8BED\u97F3\u5EA7\u5E2D\u548CASR\u8D44\u6E90",
  "provision.tenantspace.prompt": "\u63D0\u793A",
  "provision.tenantspace.smartAgentUsage.iszero": "\u8BF7\u5148\u914D\u7F6E\u5EA7\u5E2D",
  "provision.tenantspace.moveAgentUsage.iszero": "\u97F3\u9891IVR\u901A\u9053\u6570\u4E0D\u8DB3",
  "provision.tenantspace.chooseFeature": "\u7279\u6027\u9009\u62E9",
  "provision.tenantspace.feature.anonymous": "\u533F\u540D\u5916\u547C",
  "provision.tenantspace.common.anonymous.explain": "\u5F00\u542F\u533F\u540D\u5916\u547C\u7279\u6027\uFF0C\u79DF\u6237\u652F\u6301\u533F\u540D\u5916\u547C\u529F\u80FD",
  "provision.tenantspace.feature.agenttype": "\u5EA7\u5E2D\u7C7B\u578B",
  "provision.tenantspace.common.ivr": "\u5E38\u89C4IVR",
  "provision.tenantspace.feature.ivrtype": "IVR\u7C7B\u578B",
  "provision.tenantspace.intelligent.agent": "\u667A\u80FD\u5EA7\u5E2D",
  "provision.tenantspace.feature.moveAgents": "\u79FB\u52A8\u5EA7\u5E2D",
  "provision.tenantspace.feature.open": "\u5F00\u542F",
  "provision.tenantspace.intelligent.ivr": "\u667A\u80FDIVR",
  "provision.tenantspace.common.agent": "\u666E\u901A\u5EA7\u5E2D",
  "provision.tenantspace.feature.webrtc": "\u5F00\u542FWebRTC\u7279\u6027\u540E\uFF0C\u79DF\u6237\u652F\u6301WebRTC\u529F\u80FD",
  "provision.tenantspace.intelligent.ivr.explain": "\u667A\u80FDIVR\uFF1A\u9664\u4E86\u4F20\u7EDF\u7684IVR\u529F\u80FD\u4E4B\u5916\uFF0C\u652F\u6301\u8BED\u97F3\u8BC6\u522B\u3001\u8BED\u4E49\u7406\u89E3\u7684\u667A\u80FD\u5BFC\u822AIVR",
  "provision.tenantspace.common.ivr.explain": "\u5E38\u89C4IVR\uFF1A\u652F\u6301\u653E\u97F3\u3001\u6536\u53F7\u3001TTS\u7B49\u57FA\u672C\u529F\u80FD\uFF0CTTS\u9700\u8981\u914D\u7F6ETTS\u901A\u9053\u6570",
  "provision.tenantspace.feature.close": "\u5173\u95ED",
  "provision.tenantspace.intelligent.agent.explain": "\u667A\u80FD\u5EA7\u5E2D\uFF1A\u9664\u4E86\u57FA\u672C\u7684\u8BED\u97F3\u63A7\u5236\u529F\u80FD\u5916\uFF0C\u652F\u6301\u5B9E\u65F6\u7684\u8BED\u97F3\u8F6C\u6587\u672C\uFF0C\u4EE5\u53CA\u76F8\u5173\u7684\u667A\u80FD\u63A8\u8350\u7B49\u529F\u80FD\uFF0C\u914D\u7F6E\u667A\u80FD\u5EA7\u5E2D\u9700\u8981\u5148\u914D\u7F6E\u8BED\u97F3\u5EA7\u5E2D\u548CASR\u8D44\u6E90",
  "provision.tenantspace.intelligent.agent.description": "\u667A\u80FD\u5EA7\u5E2D\uFF1A\u9664\u57FA\u672C\u7684\u6E20\u9053\u6C9F\u901A\uFF0C\u652F\u6301\u6587\u672C\u8BED\u4E49\u5206\u6790\u3001\u4EE5\u53CA\u76F8\u5173\u7684\u667A\u80FD\u63A8\u8350\u5916\uFF0C\u914D\u7F6E\u667A\u80FD\u5EA7\u5E2D\u9700\u5148\u914D\u7F6E\u5EA7\u5E2D\u6570\uFF0C\u82E5\u652F\u6301\u8BED\u97F3\u4F1A\u8BDD\u7684\u5EA7\u5E2D\u8981\u914D\u7F6E\u4E3A\u667A\u80FD\u5EA7\u5E2D\uFF0C\u8FD8\u9700\u914D\u7F6EASR\u8D44\u6E90\u6570\uFF0C\u4EE5\u4FBF\u4F7F\u7528\u8BED\u97F3\u8F6C\u6587\u672C\u529F\u80FD\u3002",
  "provision.tenantspace.common.moveagent.explain": "\u7CFB\u7EDF\u5206\u914D\u8F6F\u7535\u8BDD\u53F7\u7801\u7ED9\u8BE5\u79DF\u6237\uFF0C\u79DF\u6237\u7684\u7CFB\u7EDF\u4F7F\u7528\u8BE5\u8F6F\u7535\u8BDD\u53F7\u7801\u6CE8\u518C\u5230AICC\u7CFB\u7EDF\u7684\u670D\u52A1\u5668\u4E0A\uFF0C\u79DF\u6237\u7CFB\u7EDF\u901A\u8FC7\u8C03\u7528AICC\u7CFB\u7EDF\u63D0\u4F9B\u7684\u5F00\u653E\u63A5\u53E3\uFF0C\u5B9E\u73B0\u53CC\u5411\u56DE\u547C\u529F\u80FD\uFF08\u53EF\u5206\u914D\u6700\u5927\u79FB\u52A8\u5EA7\u5E2D\u6570 = \u97F3\u9891IVR\u901A\u9053\u6570 / 2 \u5411\u4E0B\u53D6\u6574\uFF09\u3002",
  "provision.tenantspace.common.agent.explain": "\u666E\u901A\u5EA7\u5E2D\uFF1A\u57FA\u672C\u7684\u63A5\u542C\u6765\u8BDD\uFF0C\u97F3\u89C6\u9891\u901A\u8BDD\u63A7\u5236\u548C\u591A\u5A92\u4F53\u6D88\u606F\u6536\u53D1\u64CD\u4F5C\u3002",
  "provision.tenantspace.config.intelligent.agentNumber.not.exceed.agentNumber": "\u5206\u914D\u7ED9\u79DF\u6237\u7684\u667A\u80FD\u5EA7\u5E2D\u6570\u4E0D\u80FD\u5927\u4E8E\u603B\u5EA7\u5E2D\u6570",
  "provision.tenantspace.config.tenant.intelligent.ivr.featurevalue.not.one": "\u5206\u914D\u7ED9\u79DF\u6237\u7684\u667A\u80FDIVR\u7279\u6027\u503C\u4E0D\u4E3A1",
  "provision.tenantspace.config.tenant.featurevalue.not.digits": "\u5206\u914D\u7684\u79DF\u6237\u7279\u6027\u4E0D\u6B63\u786E",
  "provision.tenantspace.common.agent.number": "\u667A\u80FD\u5EA7\u5E2D\u6570",
  "provision.tenantspace.config.fail.savedata.tenant.feature.inDatabase": "\u6570\u636E\u5E93\u4FDD\u5B58\u79DF\u6237\u7279\u6027\u4FE1\u606F\u5931\u8D25",
  "provision.tenantspace.config.fail.modifydata.tenant.feature.inDatabase": "\u6570\u636E\u5E93\u4FEE\u6539\u79DF\u6237\u7279\u6027\u4FE1\u606F\u5931\u8D25",
  "provision.tenantspace.config.fail.modify.tenant.auth.use.sumInterface": "\u8C03\u7528SUM\u63A5\u53E3\u4FEE\u6539\u79DF\u6237\u6743\u9650\u5931\u8D25",
  "provision.tenantspace.feature.customeragenttype": "\u638C\u95E8\u4E00\u5BF9\u4E00",
  "provision.tenantspace.customerAndmobileAgent.conflict": "\u79FB\u52A8\u5EA7\u5E2D\u7279\u6027\u8DDF\u638C\u95E8\u4E00\u5BF9\u4E00\u7279\u6027\u4E0D\u80FD\u540C\u65F6\u5F00\u542F",
  "provision.callcenterinstanceslist.vcallcenter.UCSServer": "CCUCS\u591A\u5A92\u4F53\u670D\u52A1\u5668",
  "provision.callcenterinstanceslist.vcallcenter.UCSServer.title": "CCUCS Server\u914D\u7F6E",

  "provision.tenantspace.feature.moveAgentNum": "\u6700\u5927\u5EA7\u5E2D\u6570",
  "provision.callcenterinstanceslist.ucsServer.ucsServerIp": "CCUCS\u670D\u52A1\u5668IP",
  "provision.callcenterinstanceslist.ucsServer.ucsServerPort": "CCUCS\u670D\u52A1\u5668\u7AEF\u53E3\u53F7",
  "provision.callcent.selectucsserver": "\u8BF7\u9009\u62E9CCUCS\u670D\u52A1\u5668",
  "provision.callcenterinstanceslist.ucsServer.singledeleteconfim": "\u662F\u5426\u786E\u5B9A\u5220\u9664\u5DF2\u9009\u62E9CCUCS\u670D\u52A1\u5668\uFF1F",
  "provision.callcenterinstanceslist.ucsServer.ucsServerIpUpdate": "\u7F16\u8F91",
  "provision.callcenterinstanceslist.ucsServer.batchdeleteconfim": "\u662F\u5426\u786E\u5B9A\u5220\u9664\u6240\u6709\u5DF2\u9009\u62E9CCUCS\u670D\u52A1\u5668\uFF1F",
  "provision.callcent.error.deleteucsserver.associationvcallcener": "\u9009\u62E9\u7684CCUCS\u670D\u52A1\u5668\u5173\u8054\u79DF\u6237\uFF0C\u65E0\u6CD5\u5220\u9664",
  "provision.callcent.error.deleteucsserver.part.associationvcallcener": "\u9009\u62E9\u7684\u90E8\u5206CCUCS\u670D\u52A1\u5668\u5173\u8054\u79DF\u6237\uFF0C\u65E0\u6CD5\u5220\u9664",
  "provision.callcent.error.saveucsserverdesinfo.ucsServernoexist": "\uFF0CCCUCS\u670D\u52A1\u5668\u4E0D\u5B58\u5728",
  "provision.callcent.error.saveucsserverdesinfo": "\u521B\u5EFA\u5931\u8D25\uFF0C\u8BF7\u786E\u8BA4CCUCS\u670D\u52A1\u5668\u540D\u79F0\u662F\u5426\u5DF2\u5B58\u5728",
  "provision.callcent.error.updateucsserver.ondatabase": "\u5728\u6570\u636E\u5E93\u4FEE\u6539CCUCS\u670D\u52A1\u5668\u5931\u8D25",
  "provision.callcent.error.saveucsserveripinfo": "\u521B\u5EFA\u5931\u8D25\uFF0C\u8BF7\u786E\u8BA4CCUCS\u670D\u52A1\u5668IP\u548C\u7AEF\u53E3\u53F7\u662F\u5426\u5DF2\u5B58\u5728",

  "provision.callcent.error.updateucsserver.ipandport.alreadyexist": "CCUCS\u670D\u52A1\u5668IP\u548C\u7AEF\u53E3\u53F7\u5DF2\u5B58\u5728\uFF0C\u65E0\u6CD5\u4FEE\u6539",


  "provision.callcenterinstanceslist.whitelist.Title": "\u667A\u80FD\u8BC6\u522B\u56DE\u8C03\u5730\u5740",
  "provision.callcenterwhitelist.whiteList": "\u767D\u540D\u5355\u5730\u5740",
  "provision.callcent.selectwhitelist": "\u8BF7\u9009\u62E9\u767D\u540D\u5355\u5730\u5740\uFF01",
  "provision.callcenterinstanceslist.whitelist.batchdeleteconfim": "\u662F\u5426\u786E\u5B9A\u5220\u9664\u6240\u6709\u5DF2\u9009\u62E9\u767D\u540D\u5355\u5730\u5740\uFF1F",
  "provision.callcenterwhitelist.regAddr": "\u56DE\u8C03\u5730\u5740",
  "provision.callcenterinstanceslist.whiteList.IP": "\u767D\u540D\u5355\u5730\u5740",
  "provision.callcenterinstanceslist.whitelist.singledeleteconfim": "\u662F\u5426\u786E\u5B9A\u5220\u9664\u5DF2\u9009\u62E9\u767D\u540D\u5355\u5730\u5740\uFF1F",
  "provision.callcenterinstanceslist.config.save": "\u4FDD\u5B58",
  "provision.callcenterinstanceslist.whiteList.whiteListAdd": "\u65B0\u589E\u767D\u540D\u5355",
  "provision.callcent.error.regaddrlength": "\u6CE8\u518C\u5730\u5740\u957F\u5EA6\u8FC7\u957F\u3002",
  "provision.callcent.error.regaddrformat": "\u6CE8\u518C\u5730\u5740\u683C\u5F0F\u4E0D\u6B63\u786E\u3002",
  "provision.callcent.error.savewhiteaddripinfo": "\u767D\u540D\u5355\u5730\u5740\u683C\u5F0F\u4E0D\u6B63\u786E\u3002",
  "provision.callcent.error.whiteaddrduplicate": "\u767D\u540D\u5355\u5730\u5740\u91CD\u590D\u3002",
  "provision.callcent.error.redisfail": "\u5237\u65B0CC-AI Redis\u5931\u8D25\u3002",
  "provision.callcenterinstanceslist.whiteAddr.regAddrExample": "\u793A\u4F8B\uFF1Ahttps://ip:port/service-cloud/rest/cc-ai/v1/asrevent/asrresult",
  "provision.callcent.error.ccInstidInvalid": "\u65E0\u6548\u7684\u547C\u53EB\u4E2D\u5FC3\u5B9E\u4F8B\u3002",
  "provision.callcent.error.pagechangednowrefresh": "\u9875\u9762\u5185\u5BB9\u5DF2\u53D1\u751F\u53D8\u66F4\uFF0C\u9700\u8981\u8FDB\u884C\u9875\u9762\u5237\u65B0\u3002",
  "provision.callcenterinstanceslist.softPhone.used.select": "\u7528\u9014",
  "provision.callcent.error.entrynotexsit": "\u6B64\u767D\u540D\u5355\u8BB0\u5F55\u4E0D\u5B58\u5728\u3002",

  "provision.callcenterinstanceslist.softPhone.used.pleaseselect": "\u8BF7\u9009\u62E9\u7528\u9014",
  "provision.callcenterinstanceslist.softPhone.usedselect.isnotoccupied": "\u672A\u5360\u7528",
  "provision.callcenterinstanceslist.softPhone.usedselect.mobileagent": "\u5BA2\u6237\u7ECF\u7406\u53F7\u7801",
  "provision.callcenterinstanceslist.vcallcenterDeatil.DateValidateFailed": "\u8BF7\u9009\u62E9\u5927\u4E8E\u4ECA\u5929\u5E76\u5C0F\u4E8E2038-1-19\u7684\u65E5\u671F",

  "provision.callcenterinstanceslist.softPhone.usedselect.fixedagent": "\u5EA7\u5E2D\u53F7\u7801",
  "provision.tenantspace.popuppageurl.whiteListAdd": "\u65B0\u589E\u5730\u5740\u767D\u540D\u5355",
  "provision.tenantspace.popuppageurl.deleteconfim": "\u662F\u5426\u786E\u5B9A\u5220\u9664\u672C\u6761\u5730\u5740\u767D\u540D\u5355",
  "provision.tenantspace.popuppageurl.tableheadname": "\u5730\u5740\u767D\u540D\u5355",
  "provision.tenantspace.popuppageurl.modifyprompt": "\u8BF7\u5148\u4FEE\u6539\u767D\u540D\u5355\u5730\u5740",

  "provision.tenantspace.popuppageurl.whiteListStyle": "\u5730\u5740\u767D\u540D\u5355\u7C7B\u522B",

  "OPERLOGS.TENANTSPACE.SYNC": "\u540C\u6B65\u79DF\u6237",
  "provision.tenantspace.agentinfo": "\u5EA7\u5E2D\u4FE1\u606F",
  "provision.tenantspace.agentinfo.label.workNo": "\u5EA7\u5E2D\u5DE5\u53F7",
  "provision.tenantspace.agentinfo.message.searchworkno": "\u8BF7\u8F93\u5165\u5EA7\u5E2D\u5DE5\u53F7",
  "provision.tenantspace.agentinfo.message.searchSipAccount": "\u8BF7\u8F93\u5165\u8F6F\u7535\u8BDD\u53F7\u7801",

  "provision.tenantspace.agentinfo.message.selectagenttype": "\u8BF7\u9009\u62E9\u5EA7\u5E2D\u7C7B\u578B",
  "provision.tenantspace.agentinfo.agentstatus": "\u5EA7\u5E2D\u72B6\u6001",
  "provision.tenantspace.agentinfo.agentstatus.offline": "\u79BB\u7EBF",
  "provision.tenantspace.agentinfo.agentstatus.online": "\u5728\u7EBF",
  "provision.tenantspace.agentinfo.message.audio": "\u97F3\u9891",
  "provision.tenantspace.agentinfo.message.versatile": "\u5168\u80FD\u578B",
  "provision.tenantspace.agentinfo.message.media": "\u591A\u5A92\u4F53",
  "provision.tenantspace.agentinfo.message.selectagent": "\u8BF7\u9009\u62E9\u5EA7\u5E2D\uFF01",
  "provision.tenantspace.agentinfo.message.batchdeleteconfim": "\u662F\u5426\u786E\u5B9A\u5220\u9664\u6240\u6709\u5DF2\u9009\u62E9\u5EA7\u5E2D\uFF1F",
  "provision.tenantspace.agentinfo.message.singledeleteconfim": "\u662F\u5426\u786E\u5B9A\u5220\u9664\u5DF2\u9009\u62E9\u5EA7\u5E2D\uFF1F",
  "provision.tenantspace.agentinfo.message.deletefail.InProgress": "\u5F53\u524D\u79DF\u6237\u6B63\u5728\u5904\u7406\u4E1A\u52A1\uFF0C\u8BF7\u7A0D\u540E\u91CD\u8BD5\uFF01",
  "provision.tenantspace.agentinfo.message.video": "\u89C6\u9891",
  "provision.tenantspace.agentinfo.deletefail.modifyvdn.onwas": "\u8FDE\u63A5was\u5E73\u53F0\u4FEE\u6539vdn\u5931\u8D25",
  "provision.tenantspace.agentinfo.message.smartagent.iszero": "\uFF0C\u5DF2\u65E0\u97F3\u9891\u5EA7\u5E2D\uFF0C\u8BF7\u624B\u52A8\u5173\u95ED\u667A\u80FD\u5EA7\u5E2D\u7279\u6027",
  "provision.tenantspace.table.expiryDate": "\u622A\u6B62\u65E5\u671F",
  "provision.tenantspace.resetpwdsuccess": "\u5BC6\u7801\u91CD\u7F6E\u6210\u529F",
  "provision.tenantspace.modifyPassword": "\u91CD\u7F6E\u5BC6\u7801",
  "provision.tenantspace.resetpwdfaild": "\u5BC6\u7801\u91CD\u7F6E\u5931\u8D25",
  "provision.tenantspace.passwordValidatefaild": "\u5BC6\u7801\u6821\u9A8C\u5931\u8D25",

  "provision.tenantspace.tenantid": "\u5F53\u524D\u79DF\u6237ID",
  "provision.callcenterinstanceslist.vcallcenter.CcgatewayServer": "CC-Gateway \u670D\u52A1\u5668",
  "provision.callcenterinstanceslist.vcallcenter.CcgatewayServer.title": "CC-Gateway\u914D\u7F6E",
  "provision.callcenterinstanceslist.ccgatewayserver.ccGatewayPort": "CC-Gateway \u7AEF\u53E3\u53F7",
  "provision.callcenterinstanceslist.ccgateway.maxRegAgentNum": "\u6CE8\u518C\u5EA7\u5E2D\u6570",
  "provision.callcent.selectccgatewayservers": "\u8BF7\u9009\u62E9CC-Gateway\u670D\u52A1\u5668\uFF01",
  "provision.callcenterinstanceslist.ccgateway.idleRegAgentNum": "\u5269\u4F59\u6CE8\u518C\u5EA7\u5E2D\u6570",
  "provision.callcenterinstanceslist.ccgatewayServer.batchdeleteconfim": "\u662F\u5426\u786E\u5B9A\u5220\u9664\u6240\u6709\u5DF2\u9009\u62E9CC-Gateway\u670D\u52A1\u5668\uFF1F",
  "provision.callcenterinstanceslist.ccgatewayserver.ccGatewayIp": "CC-Gateway IP\u5730\u5740",
  "provision.callcenterinstanceslist.ccgatewayServer.singledeleteconfim": "\u662F\u5426\u786E\u5B9A\u5220\u9664\u5DF2\u9009\u62E9CC-Gateway\u670D\u52A1\u5668\uFF1F",
  "provision.callcent.error.deleteccgateway.associationvcallcener": "\u9009\u62E9\u7684CC-Gateway\u670D\u52A1\u5668\u5173\u8054\u79DF\u6237\uFF0C\u65E0\u6CD5\u5220\u9664",
  "provision.callcent.error.saveccgateway.nameinfo": "\u521B\u5EFA\u5931\u8D25\uFF0C\u8BF7\u786E\u8BA4CC-Gateway\u540D\u79F0\u662F\u5426\u5DF2\u5B58\u5728",
  "provision.callcent.error.saveccgateway.ipportinfo": "\u521B\u5EFA\u5931\u8D25\uFF0C\u8BF7\u786E\u8BA4CC-Gateway IP\u548C\u7AEF\u53E3\u53F7\u662F\u5426\u5DF2\u5B58\u5728",
  "provision.callcent.error.deleteccgateway.part.associationvcallcener": "\u9009\u62E9\u7684\u90E8\u5206CC-Gateway\u670D\u52A1\u5668\u5173\u8054\u79DF\u6237\uFF0C\u65E0\u6CD5\u5220\u9664",
  "provision.callcent.error.updateccgateway.regAgentNumError": "\u66F4\u65B0\u5931\u8D25\uFF0C\u4FEE\u6539\u7684\u6CE8\u518C\u5EA7\u5E2D\u6570\u5C0F\u4E8E\u5B9E\u9645\u5DF2\u5206\u914D\u5EA7\u5E2D\u6570",
  "provision.callcent.error.saveccgateway.regAgentNumError": "\u4FEE\u6539\u7684\u6CE8\u518C\u5EA7\u5E2D\u6570\u5C0F\u4E8E\u5B9E\u9645\u5DF2\u5206\u914D\u5EA7\u5E2D\u6570",
  "provision.callcent.error.updateccgateway.ccgatewayServernoexist": "\u66F4\u65B0\u5931\u8D25\uFF0CCC-Gateway\u670D\u52A1\u5668\u4E0D\u5B58\u5728",
  "provision.callcent.error.updateccgateway.ondatabase": "\u66F4\u65B0\u5931\u8D25\uFF0C\u5728\u6570\u636E\u5E93\u4FEE\u6539CC-Gateway\u670D\u52A1\u5668\u5931\u8D25",
  "provision.callcent.error.updateccgateway.ipandport.alreadyexist": "\u66F4\u65B0\u5931\u8D25\uFF0C\u8BF7\u786E\u8BA4CC-Gateway IP\u548C\u7AEF\u53E3\u53F7\u662F\u5426\u5DF2\u5B58\u5728",
  "provision.callcent.error.updateccgateway.fail.syncccmanagement": "\u66F4\u65B0\u5931\u8D25,\u540C\u6B65CC-Gateway\u670D\u52A1\u5668\u4FE1\u606F\u5230\u79DF\u6237\u65F6\u5931\u8D25",

  "provision.callcenterinstanceslist.vcallcenterDeatil.wasPsdLengthRangeValidateFailed": "\u5BC6\u7801\u957F\u5EA6\u5E94\u572816\u548C32\u4E4B\u95F4",
  "provision.callcent.selectcallcentccgateway": "\u8BF7\u9009\u62E9CC-Gateway",
  "provision.callcenterinstanceslist.vcallcenterDeatil.kafkaPwdLength": "\u5BC6\u7801\u957F\u5EA6\u5E94\u57286\u548C32\u4E4B\u95F4",
  "provision.callcenterinstanceslist.resource.associationvcallcener.updatenode.failed": "\u5DF2\u5206\u914D\u79DF\u6237\uFF0C\u65E0\u6CD5\u4FEE\u6539\u8282\u70B9\u3002",
  "provision.callcenterinstanceslist.resource.node.notexist": "\u8282\u70B9\u4FE1\u606F\u4E0D\u5B58\u5728\u3002",


  "provision.callcenterinstanceslist.whitepageurl.httptip": "\uFF08\u4E0D\u5B89\u5168\uFF09",
  "provision.tenantspace.common.case.explain": " case\u5F00\u901A\u65F6\uFF0C\u79DF\u6237\u4F1A\u5F00\u901Aservice cloud \u4E1A\u52A1\u80FD\u529B",

  "provision.tenantspace.tenantSpaceCount": "\u79DF\u6237\u603B\u6570\u91CF",
  "provision.tenantspace.common.newcase.explain": "\u5F00\u542FCase\u00202.0\u7279\u6027\uFF0C\u63D0\u4F9B\u79DF\u6237\u7684\u5DE5\u5355\u7BA1\u7406\u548C\u914D\u7F6E\u80FD\u529B\u3002",
  "provision.whiteListAddress.outboundcall.obs.fileserver": "\u5916\u547COBS\u6587\u4EF6\u670D\u52A1\u5668",
  "provision.tenantspace.ExpireTenantSpaceCount": "30\u5929\u5185\u5373\u5C06\u5230\u671F\u79DF\u6237\u6570\u91CF",
  "provision.whiteListAddress.ivr.voiceupload.callback": "IVR\u8BED\u97F3\u4E0A\u4F20\u56DE\u8C03",
  "provision.whiteListAddress.outboundcall.sftp.fileserver": "\u5916\u547CSFTP\u6587\u4EF6\u670D\u52A1\u5668",
  "provision.whiteListAddress.CustomerMenu": "\u81EA\u5B9A\u4E49\u83DC\u5355",
  "provision.whiteListAddress.mobileagenturl": "\u624B\u673A\u5BA2\u670D\u56DE\u8C03",
  "provision.whiteListAddress.PopMenu": "\u5F39\u5C4F\u9875\u9762",
  "provision.whiteListAddress.resourcedump.obs": "\u8D44\u6E90\u8F6C\u50A8OBS\u670D\u52A1\u5668",
  "provision.whiteListAddress.cardPop": "\u7F51\u9875\u5361\u7247\u6D88\u606F\u8DF3\u8F6C\u5730\u5740",
  "provision.whiteListAddress.resourcedump.obsproxy": "\u8D44\u6E90\u8F6C\u50A8OBS\u670D\u52A1\u5668\u4EE3\u7406",
  "provision.whiteListAddress.AddressExist": "\u5730\u5740\u767D\u540D\u5355\u5DF2\u5B58\u5728",
  "provision.whiteListAddress.TenantUnExist": "\u8BE5\u79DF\u6237\u4E0D\u5B58\u5728",
  "provision.tenantspace.config.isAutoAssignWorkNo": "\u865A\u62DF\u547C\u53EB\u4E2D\u5FC3\u662F\u5426\u81EA\u52A8\u5206\u914D\u5DE5\u53F7",

  "provision.tenantspace.config.chooseworkNo": "\u9009\u62E9\u5DE5\u53F7",
  "provision.tenantspace.config.chooseworkNo.tip": "\u8BF7\u6839\u636E\u7A7A\u95F2\u5DE5\u53F7\u4FE1\u606F\u8F93\u5165\u5409\u7965\u53F7\u6BB5",
  "provision.tenantspace.config.error.chooseworkNo.noagent": "\u672A\u9009\u62E9\u5EA7\u5E2D\u8D44\u6E90\uFF0C\u4E0D\u9700\u8981\u5206\u914D\u5DE5\u53F7",
  "provision.tenantspace.config.error.chooseworkNo.noaddagent": "\u672A\u65B0\u589E\u5EA7\u5E2D\u8D44\u6E90\uFF0C\u4E0D\u9700\u8981\u5206\u914D\u5DE5\u53F7",
  "provision.tenantspace.config.worknoassign.filterworkNo": "\u7B5B\u9009\u53F7\u7801\u5E93",
  "provision.tenantspace.config.error.142": "\u5F00\u542FAPP\u547C\u53EB\u4EFB\u52A1\u65F6\u5FC5\u987B\u5F00\u542FAPPcube\u7279\u6027",
  "provision.tenantspace.config.worknoassign.filternumber": "\u6392\u9664\u6570\u5B57",
  "provision.tenantspace.config.worknoassign.filternumber.explain1": "\u8F93\u5165\u591A\u4E2A\u53F7\u7801\u65F6\u8BF7\u7528\u201C\,\u201D\u5206\u9694\uFF0C\u6700\u591A\u8F93\u51655\u4E2A\u53F7\u7801\uFF0C\u4F8B\u5982\u201C4\,11\,13\u201D",
  "provision.tenantspace.config.worknoassign.chooseworkNo": "\u9009\u62E9\u53F7\u7801",
  "provision.tenantspace.config.worknoassign.filternumber.explain2": "\u7B5B\u9009\u540E\u7684\u53F7\u7801\u5E93\u5C06\u4E0D\u5305\u542B\u4EE5\u4E0A\u6570\u5B57\u7684\u53F7\u7801",
  "provision.tenantspace.config.worknoassign.filternumber.error": "\u6392\u9664\u6570\u5B57\u4E0D\u7B26\u5408\u8981\u6C42",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.explain1": "\u53F7\u7801\u5E93\u9884\u89C8",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.explain2": "\u6700\u591A\u53EF\u6DFB\u52A05\u7EC4\u53F7\u7801",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.error": "\u7ED3\u675F\u5DE5\u53F7\u4E0D\u80FD\u5C0F\u4E8E\u8D77\u59CB\u5DE5\u53F7",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.required": "\u8BF7\u5148\u586B\u5199\u5B8C\u9700\u8981\u6DFB\u52A0\u7684\u5DE5\u53F7\u6BB5\u5E76\u786E\u5B9A",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.workNocontain": "\u5DE5\u53F7\u5DF2\u7ECF\u88AB\u9009\u62E9\uFF0C\u8BF7\u91CD\u65B0\u8F93\u5165",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.paramserror": "\u53F7\u7801\u4FE1\u606F\u586B\u5199\u9519\u8BEF",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.addmaxlimit": "\u5DF2\u6DFB\u52A05\u7EC4\u53F7\u7801",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.availableWorkNo": "\u5DE5\u53F7\u6BB5\u4E2D\u6CA1\u6709\u53EF\u7528\u5DE5\u53F7",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.noempty": "\u53F7\u7801\u4FE1\u606F\u5FC5\u586B",
  "provision.tenantspace.config.worknoassign.showchoosedworkno.explain": "\u6CE8\uFF1A\u60A8\u4E00\u5171\u9700\u8981\{0}\u4E2A\u53F7\u7801\uFF0C\u5F53\u524D\u5DF2\u9009\{1}\u4E2A\uFF0C\u7CFB\u7EDF\u5C06\u6309\u987A\u5E8F\u4E3A\u60A8\u5206\u914D\u53F7\u7801\u3002",
  "provision.tenantspace.config.worknoassign.availableworknocount": "\u53EF\u7528\u53F7\u7801\u6570\u91CF",
  "provision.tenantspace.config.worknoassign.add": "\u6DFB\u52A0",
  "provision.tenantspace.config.assignedworkno": "\u5DF2\u5206\u914D\u5DE5\u53F7",
  "provision.tenantspace.config.selectedworkNo": "\u5DF2\u9009\{0}\u4E2A\u5DE5\u53F7\{1}",
  "provision.tenantspace.config.assignedworknonumber": "\u5DF2\u5206\u914D\u5DE5\u53F7\u6570",

  "provision.tenantspace.worknoValidatefaild": "\u5DE5\u53F7\u4FE1\u606F\u672A\u586B\u5199",
  "provision.tenantspace.obsServer.datasource.id": "\u6570\u636E\u6E90\u7F16\u53F7",
  "provision.tenantspace.obsServer.datasource.name": "\u6570\u636E\u6E90\u540D\u79F0",
  "provision.tenantspace.obsServer.datasource.databasename": "\u6570\u636E\u5E93\u540D\u79F0",
  "provision.tenantspace.obsServer.backup.datasource.name": "\u5907\u7528\u6570\u636E\u6E90\u540D\u79F0",
  "provision.tenantspace.obs.searchName": "\u8BF7\u8F93\u5165\u8981\u67E5\u8BE2\u7684OBS\u670D\u52A1\u5668\u540D\u79F0",
  "provision.tenantspace.config.datasource": "OBS\u6570\u636E\u6E90",
  "provision.tenantspace.obsServer.datasource.type": "\u6570\u636E\u6E90\u7C7B\u578B",
  "provision.tenantspace.config.choosedatasource": "\u8BF7\u9009\u62E9OBS\u6570\u636E\u6E90",
  "provision.tenantspace.config.chooseOBSServer": "\u8BF7\u9009\u62E9\u4E00\u4E2AOBS\u670D\u52A1\u5668",
  "provision.tenantspace.chooseobsdatasource.error1": "\u8BF7\u5148\u9009\u62E9OBS\u670D\u52A1\u5668",
  "provision.tenantspace.obs.name": "OBS\u670D\u52A1\u5668\u540D\u79F0",
  "provision.tenantspace.chooseobsdatasource.platformDbSource": "\u5E73\u53F0\u6570\u636E\u6E90",
  "provision.tenantspace.chooseobsdatasource.thirdPartyDbSource": "\u7B2C\u4E09\u65B9\u6570\u636E\u6E90",

  "provision.tenantspace.chooseobsdatasource.callCenterDbSource": "\u547C\u53EB\u4E2D\u5FC3\u4E1A\u52A1\u6570\u636E\u6E90",
  "provision.tenantspace.config.isAutoAssignSoftPhone": "\u865A\u62DF\u547C\u53EB\u4E2D\u5FC3\u662F\u5426\u81EA\u52A8\u5206\u914D\u8F6F\u7535\u8BDD\u53F7\u7801",
  "provision.tenantspace.config.softphoneassign": "\u5206\u914D\u8F6F\u7535\u8BDD\u53F7\u7801",
  "provision.tenantspace.config.softphone.Validatefaild": "\u672A\u5206\u914D\u8F6F\u7535\u8BDD\u53F7\u7801",
  "provision.tenantspace.config.choosesoftphone": "\u9009\u62E9\u8F6F\u7535\u8BDD\u53F7\u7801",
  "provision.tenantspace.config.softphoneassign.tip": "\u8BF7\u8F93\u5165\u8F6F\u7535\u8BDD\u53F7\u7801\u6BB5",
  "provision.tenantspace.config.softphone.using": "\u8F6F\u7535\u8BDD\u53F7\u7801\{0}\u5DF2\u7ECF\u88AB\u4F7F\u7528",
  "provision.tenantspace.config.softphone.noavailable": "\u65E0\u53EF\u7528\u8F6F\u7535\u8BDD\u53F7\u7801",
  "provision.tenantspace.config.error.softphone.noagentadd": "\u672A\u65B0\u589E\u97F3\u9891\u3001\u89C6\u9891\u3001\u591A\u5A92\u4F53\u3001\u5168\u80FD\u578B\u548C\u79FB\u52A8\u5EA7\u5E2D",
  "provision.tenantspace.config.softphoneassign.softphonescope.contain": "\u8F6F\u7535\u8BDD\u53F7\u7801\u5DF2\u7ECF\u88AB\u9009\u62E9\uFF0C\u8BF7\u91CD\u65B0\u8F93\u5165",
  "provision.tenantspace.config.softphoneassign.softphonescope.required": "\u8BF7\u5148\u586B\u5199\u5B8C\u9700\u8981\u6DFB\u52A0\u7684\u8F6F\u7535\u8BDD\u53F7\u6BB5\u5E76\u786E\u5B9A",
  "provision.tenantspace.config.error.softphone.noaddagent": "\u672A\u65B0\u589E\u97F3\u9891\u3001\u89C6\u9891\u3001\u591A\u5A92\u4F53\u548C\u79FB\u52A8\u5EA7\u5E2D",
  "provision.tenantspace.config.softphoneassign.softphonescope.extensiverange": "\u8D77\u59CB\u53F7\u7801\u5230\u7ED3\u675F\u53F7\u7801\u7684\u53D6\u503C\u8303\u56F4\u5E94\u4E0D\u5927\u4E8E1000",
  "provision.tenantspace.config.selectedsoftphone": "\u5DF2\u9009\{0}\u4E2A\u53F7\u7801\{1}",
  "provision.tenantspace.config.softphoneassign.chooseworknoscope.paramserror": "\u53F7\u7801\u4FE1\u606F\u586B\u5199\u9519\u8BEF",
  "provision.tenantspace.config.softphoneassign.softphonescope.inadequacy": "\u5206\u914D\u7684\u53EF\u7528\u8F6F\u7535\u8BDD\u53F7\u7801\u4E0D\u8DB3",
  "provision.callcent.platformserver.cms ": "\u8FD0\u8425\u76D1\u63A7\u670D\u52A1(CMS)",
  "provision.callcent.platformserver.isales ": "\u5916\u547C\u8425\u9500\u670D\u52A1(iSales)",
  "provision.callcent.platformserver.isqm": "\u667A\u80FD\u8D28\u68C0\u670D\u52A1(iSQM)",

  "provision.callcent.platformserver.ccbms ": "\u5E73\u53F0\u76D1\u63A7\u63A5\u53E3\u670D\u52A1(CCBMS)",
  "provision.callcent.platformserver.ccbcs ": "\u5E73\u53F0\u914D\u7F6E\u63A5\u53E3\u670D\u52A1(CCBCS)",
  "provision.callcenterinstanceslist.vcallcenter.plateformServer": "\u5BF9\u63A5\u5E73\u53F0\u670D\u52A1\u5668",
  "provision.callcent.platformserver.ccfs ": "\u547C\u53EB\u4E2D\u5FC3\u6587\u4EF6\u5B58\u50A8\u670D\u52A1(CCFS)",
  "provision.callcent.platformserver.identitychecktips": "\u68C0\u6D4B\u5230\u60A8\u5BF9\u670D\u52A1\u5668\u5BC6\u7801\u8FDB\u884C\u4E86\u4FEE\u6539\u3002\u4E3A\u4E86\u7EF4\u62A4\u4FE1\u606F\u5B89\u5168\uFF0C\u8BF7\u5148\u8F93\u5165\u60A8\u5F53\u524D\u4F7F\u7528\u7684\u8D26\u53F7\u5BC6\u7801",
  "provision.callcent.platformserver.ip": "\u670D\u52A1IP\u5730\u5740",
  "provision.callcent.platformserver.port": "\u670D\u52A1\u7AEF\u53E3\u53F7",
  "provision.tenantspace.save.fail.120532": "\u539F\u63A5\u5165\u7801\u5DF2\u88AB\u88AB\u53EB\u53F7\u7801\u5F15\u7528",

  "provision.tenantspace.table.batchcreate": "\u6279\u91CF\u65B0\u5EFA ",
  "provision.tenant.management.batchcreate": "\u6279\u91CF\u521B\u5EFA\u79DF\u6237",
  "provision.tenant.management.batchcreatenumber": "\u521B\u5EFA\u6570\u91CF",
  "provision.tenant.management.batchcreate.error.accesscodenumber": "\u9009\u62E9\u7684\u63A5\u5165\u7801\u4E0D\u80FD\u8D85\u8FC7100\u4E2A",

  "provision.tenant.management.batchcreate.error.accesscode.inadequacy": "\u63A5\u5165\u7801\u6570\u91CF\u5C11\u4E8E\u8981\u521B\u5EFA\u7684\u79DF\u6237\u6570\u91CF",
  "provision.tenant.management.batchcreate.success.createtask": "\u6279\u91CF\u79DF\u6237\u4EFB\u52A1\u521B\u5EFA\u6210\u529F",
  "provision.tenant.management.pool.ctinode2": "CTI\u8282\u70B92",
  "provision.tenant.management.pool.ctinode.name": "\u8282\u70B9\u540D\u79F0",
  "provision.tenant.management.akinfo": "AK\u4FE1\u606F",
  "provision.tenant.management.pool.ctinode.id": "\u8282\u70B9ID",
  "provision.callcenterinstanceslist.table.download": "\u4E0B\u8F7D",
  "provision.callcenterinstanceslist.table.viewing": "\u67E5\u770B",
  "provision.tenant.management.pool.ctinode1": "CTI\u8282\u70B91",
  "provision.authconfig.appkey": "APP ID",
  "provision.authconfig.updateConfim": "\u66F4\u65B0\u786E\u8BA4",
  "provision.authconfig.deleteconfim": "\u662F\u5426\u786E\u8BA4\u5220\u9664APP ID\uFF1F",
  "provision.authconfig.updateMessage": "\u662F\u5426\u786E\u8BA4\u66F4\u65B0APP SK\uFF1F",
  "provision.authconfig.connected.with.tenantspace": "\u8BE5APP ID\u5DF2\u7ED1\u5B9A\u79DF\u6237\uFF0C\u4E0D\u80FD\u5220\u9664\u3002",
  "provision.authconfig.duplicate.description.error": "\u63CF\u8FF0\u4FE1\u606F\u4E0D\u80FD\u91CD\u590D",
  "provision.authconfig.update": "\u66F4\u65B0",
  "provision.authconfig.description.toolong.error": "\u63CF\u8FF0\u4FE1\u606F\u957F\u5EA6\u4E0D\u80FD\u8D85\u8FC7200",
  "provision.authconfig.authinfomation": "\u5F00\u53D1\u8005\u4FE1\u606F",
  "provision.authconfig.description.empty.error": "\u63CF\u8FF0\u4FE1\u606F\u4E0D\u80FD\u4E3A\u7A7A",
  "provision.grayconfig.tenantSpaceName": "\u79DF\u6237\u540D\u79F0",
  "provision.authconfig.authdescription": "\u8BF7\u8F93\u5165\u5F00\u53D1\u8005ID\u7684\u63CF\u8FF0",
  "provision.grayconfig.deleteconfim": "\u662F\u5426\u786E\u8BA4\u5220\u9664\u7070\u5EA6\u914D\u7F6E\uFF1F",
  "provision.grayconfig.tenantSpaceId": "\u79DF\u6237ID",
  "provision.grayconfig.paramIsError": "\u5165\u53C2\u4E3A\u7A7A\u6216\u8005\u9519\u8BEF",
  "provision.grayconfig.tenantHasConfig": "\u79DF\u6237\u5DF2\u88AB\u914D\u7F6E",
  "provision.tenantspace.config.ivrserver.select.confirm": "\u9009\u62E9\u786E\u8BA4",
  "provision.tenantspace.config.ivrserver.select.confirm.message": "\u60A8\u9009\u62E9\u7684IVR\u8FDB\u7A0B\u90E8\u7F72\u5728\u540C\u4E00\u53F0\u670D\u52A1\u5668\u4E0A\uFF0C\u5B58\u5728\u5355\u70B9\u6545\u969C\u98CE\u9669\uFF0C\u786E\u8BA4\u662F\u5426\u7EE7\u7EED\uFF1F",


  "provision.tenantspace.resetpwdfaild.accountpassword.error": "\u5F53\u524D\u8D26\u53F7\u5BC6\u7801\u9519\u8BEF",
  "provision.tenantspace.resetpwdfaild.tenant.not.exist": "\u79DF\u6237\u4E0D\u5B58\u5728",
  "provision.tenantspace.resetpwdfaild.user.not.exist": "\u7528\u6237\u4E0D\u5B58\u5728\uFF0C\u4FEE\u6539\u5BC6\u7801\u5931\u8D25",
  "provision.tenantspace.resetpwdfaild.newpassword.not.meet.passwordrule": "\u65B0\u5BC6\u7801\u4E0D\u6EE1\u8DB3\u5BC6\u7801\u89C4\u5219",
  "provision.tenantspace.resetpwdfaild.newPwd.information.is.wrong": "\u4FEE\u6539\u5931\u8D25\uFF0C\u65B0\u5BC6\u7801\u4FE1\u606F\u9519\u8BEF",
  "provision.tenantspace.resetpwdfaild.user.is.locked": "\u7528\u6237\u88AB\u9501\u5B9A\uFF0C\u4FEE\u6539\u5BC6\u7801\u5931\u8D25",

  "provision.callcenterinstanceslist.sipServer.sip": "SIP",
  "provision.callcenterinstanceslist.sipServer.webRTC": "WebRTC",
  "provision.callcenterinstanceslist.sipServer.sipanonymousCall": "SIP\u533F\u540D\u547C\u53EB",
  "provision.callcenterinstanceslist.sipServer.webRtcanonymousCall": "WebRTC\u533F\u540D\u547C\u53EB",
  "provision.callcenterinstanceslist.vcallcenterDeatil.uapIpAndPort": "UAP\u5730\u5740",
  "provision.callcenterinstanceslist.sipServer.uapIp": "UAP IP",
  "provision.callcenterinstanceslist.sipServer.uapPort": "UAP\u7AEF\u53E3",

  "provision.tenantspace.config.choose.agent.registration.server": "\u5EA7\u5E2D\u6CE8\u518C\u670D\u52A1\u5668",
  "provision.tenantspace.config.choose.agent.registration.server.type": "\u5EA7\u5E2D\u6CE8\u518C\u670D\u52A1\u5668\u7C7B\u578B",
  "provision.tenantspace.config.choose.anonymous.call.server.type": "\u533F\u540D\u547C\u53EB\u670D\u52A1\u5668\u7C7B\u578B",
  "provision.tenantspace.config.choose.anonymous.call.server": "\u533F\u540D\u547C\u53EB\u670D\u52A1\u5668",
  "provision.tenantspace.config.chooseAgentRegistrationServer": "\u9009\u62E9\u4E00\u4E2A",
  "provision.tenantspace.config.anonymous.call.number": "\u533F\u540D\u547C\u53EB\u5E76\u53D1\u6570",
  "provision.tenantspace.config.chooseAnonymousCallServer": "\u9009\u62E9\u4E00\u4E2A",
  "provision.tenantspace.config.need.wertcsipserver": "\u5F00\u542FWebRTC\u7279\u6027\u9700\u9009\u62E9WebRTC\u7C7B\u578B\u7684\u5EA7\u5E2D\u6CE8\u518C\u670D\u52A1\u5668",
  "provision.tenantspace.config.chooseAgentRegistrationServerTitle": "\u9009\u62E9\u5EA7\u5E2D\u6CE8\u518C\u670D\u52A1\u5668",
  "provision.tenantspace.config.chooseAnonymousCallServerTitle": "\u9009\u62E9\u533F\u540D\u547C\u53EB\u670D\u52A1\u5668",
  "provision.callcent.pool.select.different.sipserver.error": "\u4E0D\u540C\u8282\u70B9\u5E94\u9009\u62E9\u76F8\u540C\u7C7B\u578B\u7684\u5EA7\u5E2D\u6CE8\u518C\u670D\u52A1\u5668",
  "provision.callcent.pool.select.different.amsservice.error": "\u4E0D\u540C\u8282\u70B9\u5E94\u9009\u62E9\u76F8\u540C\u7C7B\u578B\u7684\u533F\u540D\u547C\u53EB\u670D\u52A1\u5668",
  "provision.tenantspace.config.tenant.webrtc.featurevalue.not.one": "\u5206\u914D\u7ED9\u79DF\u6237\u7684WebRTC\u7279\u6027\u503C\u4E0D\u4E3A1",
  "provision.tenantspace.config.amsServer.idleregagentnum.insufficient.error": "\u533F\u540D\u547C\u53EB\u670D\u52A1\u5668\u5269\u4F59\u5E76\u53D1\u6570\u4E0D\u8DB3",
  "provision.tenantspace.config.amsNumber.error": "\u533F\u540D\u547C\u53EB\u5E76\u53D1\u6570\u4E0D\u80FD\u4E3A\u7A7A\uFF0C\u6216\u53EA\u80FD\u662F0\u6216\u6B63\u6574\u6570",

  "provision.callcenterinstanceslist.sipServerUpdate.failed": "IP\u6216\u7AEF\u53E3\u4E0D\u6EE1\u8DB3\u89C4\u5219",

  "provision.whitepopuppageurl.protocol.check": "\u8BF7\u6CE8\u610F\uFF0C\u5F53\u524D\u8F93\u5165\u7684\u5F39\u5C4F\u5730\u5740\u6240\u4F7F\u7528\u7684\u7F51\u7EDC\u534F\u8BAE\u4E0D\u662F\u5B89\u5168\u534F\u8BAE\uFF0C\u5982\u786E\u5B9A\u4F7F\u7528\uFF0C\u53EF\u80FD\u5B58\u5728\u5B89\u5168\u98CE\u9669\u3002",

  "provision.tenantspace.feature.intelligent.inspection": "\u667A\u80FD\u8D28\u68C0",
  "provision.tenantspace.feature.intelligent.inspection.description": "\u5F00\u542F\u667A\u80FD\u8D28\u68C0\u7279\u6027\uFF0C\u79DF\u6237\u652F\u6301\u667A\u80FD\u8D28\u68C0\u529F\u80FD\u3002",

  "provision.tenantspace.config.tenant.intelligent.inspection.featurevalue.not.one": "\u5206\u914D\u7ED9\u79DF\u6237\u7684WebRTC\u7279\u6027\u503C\u4E0D\u4E3A1",

  "provision.tenantspace.feature.speechAnalysis": "\u6587\u672C\u5206\u6790",

  "provision.tenantspace.feature.speechAnalysis.description": "\u5F00\u542F\u6587\u672C\u5206\u6790\u7279\u6027\uFF0C\u79DF\u6237\u652F\u6301\u6587\u672C\u548C\u8BED\u97F3\u5206\u6790\u529F\u80FD",
  "provision.softphone.label.issyncuap.no": "\u5426",
  "provision.softPhone.label.uapaddress": "UAP\u5730\u5740",
  "provision.softPhone.label.uapaddress.node1": "\u8282\u70B9\u4E00UAP\u5730\u5740",
  "provision.softPhone.label.uapaddress.node2": "\u8282\u70B9\u4E8CUAP\u5730\u5740",
  "provision.softphone.label.issyncuap.yes": "\u662F",
  "provision.softPhone.label.isSyncUap": "\u662F\u5426\u540C\u6B65UAP",
  "provision.softPhone.table.forcemodifyconfim.message": "\u8BE5\u64CD\u4F5C\u4F1A\u91CD\u7F6E\u8F6F\u7535\u8BDD\u5BC6\u7801\uFF0C\u5982\u9700\u4FEE\u6539\uFF0C\u5FC5\u987B\u767B\u5F55\u79DF\u6237\u64CD\u4F5C\uFF0C\u662F\u5426\u786E\u8BA4\uFF1F",

  "provision.softPhone.table.forcemodifyconfim": "\u786E\u8BA4",
  "provision.tenantspace.feature.customData": "\u81EA\u5B9A\u4E49\u6570\u636E",
  "provision.tenantspace.feature.customData.description": "\u5F00\u542F\u81EA\u5B9A\u4E49\u6570\u636E\u7279\u6027\uFF0C\u79DF\u6237\u652F\u6301\u81EA\u5B9A\u4E49\u6570\u636E\u529F\u80FD\u3002",
  "provision.tenantspace.config.tenant.custdata.featurevalue.not.one": "\u5206\u914D\u7ED9\u79DF\u6237\u7684\u81EA\u5B9A\u4E49\u6570\u636E\u7279\u6027\u503C\u4E0D\u4E3A1",

  "provision.callcenter.ivr.alert": "\u9700\u8981\u4E0E\u5B9E\u9645\u90E8\u7F72\u7684IVR\u7684\u5E76\u53D1\u80FD\u529B\u4E00\u81F4",
  "provision.callcenter.resourceinfo.alert": "\u5982\u4E0B\u8D44\u6E90\u4FE1\u606F\u9700\u8981\u4E0EUAP\u3001CTI\u7B49\u8BBE\u5907license\u4E00\u81F4",
  "provision.callcenter.gateway.agentnum.alert": "\u9700\u8981\u4E0ECC-Gateway\u96C6\u7FA4\u7684\u5B9E\u9645\u914D\u7F6E\u80FD\u529B\u4E00\u81F4",
  "provision.callcenter.ucs.ip.alert": "CCUCS\u96C6\u7FA4\u8D1F\u8F7D\u5747\u8861\u5730\u5740",
  "provision.callcenter.sip.alert": "\u9700\u8981\u4E0EUAP\u3001SBC\u7684\u5B9E\u9645\u652F\u6301\u80FD\u529B\u4E00\u81F4",
  "provision.callcenter.gateway.address.alert": "CC-Gateway\u96C6\u7FA4\u8D1F\u8F7D\u5747\u8861\u5730\u5740",

  "provision.callcenter.videoapplicationmodel.mcumodel": "MCU\u89C6\u9891",
  "provision.callcenter.videoapplicationmodel": "\u89C6\u9891\u5E94\u7528\u6A21\u5F0F",
  "provision.callcenter.videoapplicationmodel.sfumodel": "SFU\u89C6\u9891",
  "provision.callcenter.videoapplicationmodel.mcuAndsfuModel": "MCU+SFU\u89C6\u9891",
  "provision.tenantspace.feature.multiChannelSupport": "\u591A\u6E20\u9053\u652F\u6491",
  "provision.tenantspace.feature.multiChannelSupport.description": "\u5F00\u542F\u591A\u6E20\u9053\u652F\u6491\u7279\u6027\uFF0C\u79DF\u6237\u652F\u6301\u5BF9\u5EA7\u5E2D\u8FDB\u884C\u8DE8\u670D\u52A1\u6E20\u9053\u7C7B\u578B\u7684\u4E34\u65F6\u961F\u5217\u8C03\u6574\u3002\u6CE8\u610F\uFF1A\u82E5\u62E5\u6709\u8DE8\u670D\u52A1\u6E20\u9053\u6743\u9650\u7684\u5EA7\u5E2D\uFF0C\u7B7E\u5165\u65F6\u5C06\u540C\u65F6\u5360\u7528\u8BED\u97F3\u3001\u89C6\u9891\u3001\u591A\u5A92\u4F53\u8D44\u6E90\uFF0C\u79DF\u6237\u4E0B\u5EA7\u5E2D\u7B7E\u5165\u53EF\u80FD\u56E0\u62A2\u5360\u8D44\u6E90\u800C\u4E0D\u80FD\u540C\u65F6\u7B7E\u5165\u8FDB\u884C\u5BA2\u6237\u670D\u52A1\u3002",

  "provision.tenantspace.feature.outboundCallMarketingSupport": "\u667A\u80FD\u5916\u547C",

  "provision.tenantspace.feature.outboundCallMarketingSupport.description": "\u5F00\u542F\u667A\u80FD\u5916\u547C\u7279\u6027\uFF0C\u79DF\u6237\u652F\u6301\u667A\u80FD\u5916\u547C\u529F\u80FD\u3002",
  "provision.tenantspace.feature.emailMarketingSupport": "\u90AE\u4EF6\u8425\u9500",
  "provision.tenantspace.feature.smsMarketingSupport.description": "\u5F00\u542F\u77ED\u4FE1\u8425\u9500\u7279\u6027\uFF0C\u79DF\u6237\u652F\u6301\u77ED\u4FE1\u8425\u9500\u529F\u80FD\u3002",
  "provision.tenantspace.feature.emailMarketingSupport.description": "\u5F00\u542F\u90AE\u4EF6\u8425\u9500\u7279\u6027\uFF0C\u79DF\u6237\u652F\u6301\u90AE\u4EF6\u8425\u9500\u529F\u80FD\u3002",
  "provision.tenantspace.feature.smsMarketingSupport": "\u77ED\u4FE1\u8425\u9500",
  "provision.tenantspace.feature.whatsAppMarketingSupport": "WhatsApp\u8425\u9500",
  "provision.tenantspace.feature.whatsAppMarketingSupport.description": "\u5F00\u542FWhatsApp\u8425\u9500\u7279\u6027\uFF0C\u79DF\u6237\u652F\u6301WhatsApp\u8425\u9500\u529F\u80FD\u3002",
  "provision.tenantspace.feature.smsOrEmailMarketingSupport.sendMaxNumber": "\u6BCF\u5929\u53D1\u9001\u6570\u91CF\u4E0A\u9650",
  "provision.tenantspace.feature.outboundCallMarketingSupport.error": "\u8BF7\u5148\u53D6\u6D88\u77ED\u4FE1\u8425\u9500\uFF0C\u90AE\u4EF6\u8425\u9500\u548CWhatsApp\u8425\u9500\u3002",
  "provision.tenantspace.feature.smsOrEmailMarketingSupport.sendMaxNumber.alert": "\u6BCF\u5929\u53D1\u9001\u6570\u91CF\u4E0A\u9650\u4E3A1\u52301000000000\uFF0C0\u4E3A\u4E0D\u9650\u5236\u3002",
  "provision.tenantspace.feature.smsOrEmailMarketingSupport.sendMaxNumber.whatsApp.alert": "\u6BCF\u5929\u53D1\u9001\u6570\u91CF\u4E0A\u9650\u4E3A1\u5230100000\uFF0C0\u4E3A\u4E0D\u9650\u5236\u3002",
  "provision.tenantspace.feature.smsOrEmailMarketingSupport.sendMaxNumber.placeholder": "1\u52301000000000\uFF0C0\u4E3A\u4E0D\u9650\u5236",
  "provision.tenantspace.feature.smsOrEmailMarketingSupport.sendMaxNumber.whatsApp.placeholder": "1\u5230100000\uFF0C0\u4E3A\u4E0D\u9650\u5236",
  "provision.tenantspace.feature.knowledgeBaseSupport.description": "\u5F00\u542F\u77E5\u8BC6\u5E93\u7279\u6027\uFF0C\u79DF\u6237\u652F\u6301\u77E5\u8BC6\u5E93\u529F\u80FD\u3002",
  "provision.tenantspace.feature.knowledgeBaseSupport": "\u77E5\u8BC6\u5E93",
  "provision.tenantspace.feature.dualChannelRecSupport": "\u53CC\u8F68\u9053\u5F55\u97F3",

  "provision.tenantspace.feature.forceVideoSelfRecord": "\u5F55\u5C4F",
  "provision.tenantspace.feature.forceVideoSelfRecord.description": "\u5EA7\u5E2D\u5904\u7406\u547C\u53EB\u65F6\uFF0C\u662F\u5426\u9700\u8981\u81EA\u52A8\u5F55\u5236\u5176\u5C4F\u5E55\u64CD\u4F5C\u72B6\u51B5",
  "provision.tenantspace.feature.forceVideoSelfRecord.forcibleSelfRecord": "VDN\u5F3A\u5236\u81EA\u5F55\u5C4F",
  "provision.tenantspace.feature.forceVideoSelfRecord.attendant": "\u5BF9\u6240\u6709\u8BDD\u52A1\u5458\u542F\u7528",
  "provision.tenantspace.feature.forceVideoSelfRecord.percentage": "\u6309\u767E\u5206\u6BD4\u5F55\u5236",
  "provision.tenantspace.feature.forceVideoSelfRecord.percentageFactor": "\u5F55\u5C4F\u767E\u5206\u6BD4\u7CFB\u6570(%)",
  "provision.tenantspace.feature.forceVideoSelfRecord.percentageScope": "\u6700\u5C0F\u503C0\uFF0C\u6700\u5927\u503C100\uFF0C\u9ED8\u8BA4\u53D6100",
  "provision.tenantspace.feature.dualChannelRecSupport.description": "\u5F00\u542F\u53CC\u8F68\u9053\u5F55\u97F3\u7279\u6027\uFF0C\u79DF\u6237\u652F\u6301\u53CC\u8F68\u9053\u5F55\u97F3\u529F\u80FD\u3002",
  "provision.system.error": "\u7CFB\u7EDF\u7EC4\u4EF6\u5F02\u5E38\uFF0C\u8BF7\u7A0D\u540E\u91CD\u8BD5\u3002",
  "provision.tenantspace.feature.forceVideoSelfRecord.settingError": "\u5F55\u5C4F\u53C2\u6570\u914D\u7F6E\u9519\u8BEF",
  "provision.tenantspace.config.amsNumber.unequal": "\u533F\u540D\u547C\u53EB\u5E76\u53D1\u6570\u9700\u8981\u4E00\u81F4\u3002",
  "provision.tenantspace.config.amsNumber.positiveInteger": "\u533F\u540D\u547C\u53EB\u5E76\u53D1\u6570\u4E0D\u80FD\u4E3A\u7A7A\u4E14\u53EA\u80FD\u662F\u6B63\u6574\u6570\u3002",

  "provision.tenant.management.positiveInteger.checkmsg": "\u8F93\u5165\u503C\u5FC5\u987B\u4E3A\u6B63\u6574\u6570\u3002",

  "provision.tenantspace.step1": "\u7B2C1\u6B65",

  "provision.tenantspace.step2": "\u7B2C2\u6B65",
  "provision.tenantspace.step4": "\u7B2C4\u6B65",
  "provision.tenantspace.step3": "\u7B2C3\u6B65",
  "provision.callcenterinstanceslist.table.ctiversion": "CTI\u0020\u7248\u672C",
  "provision.tenantspace.step6": "\u7B2C6\u6B65",
  "provision.tenantspace.step5": "\u7B2C5\u6B65",

  "provision.tenantspace.step7": "\u7B2C7\u6B65",
  "provision.tenantspace.chooseauthenticationsystem": "\u914D\u7F6E\u7B2C\u4E09\u65B9\u8BA4\u8BC1\u7CFB\u7EDF",
  "provision.tenantspace.authenticationsystem.selectClientName": "\u9009\u62E9\u4E00\u4E2A\u7B2C\u4E09\u65B9\u8BA4\u8BC1\u7CFB\u7EDF",
  "provision.tenantspace.authenticationsystem.enter.clientName": "\u8BF7\u8F93\u5165\u8981\u67E5\u8BE2\u7684\u7B2C\u4E09\u65B9\u8BA4\u8BC1\u7CFB\u7EDF\u540D\u79F0",
  "provision.tenantspace.authenticationsystem.clientName": "\u7B2C\u4E09\u65B9\u8BA4\u8BC1\u7CFB\u7EDF",
  "provision.tenantspace.authenticationsystem.clientURL": "\u7B2C\u4E09\u65B9\u8BA4\u8BC1\u7CFB\u7EDFURL",
  "provision.tenantspace.authenticationsystem.mappingName": "\u8054\u90A6\u6620\u5C04\u89C4\u5219\u540D\u79F0",

  "provision.encryptinfo.scenario": "\u5E94\u7528\u573A\u666F",
  "provision.encryptinfo.algorithm": "\u52A0\u5BC6\u7B97\u6CD5",
  "provision.encryptinfo.strength": "\u5F3A\u5EA6",
  "provision.encryptinfo.paddingScheme": "\u586B\u5145\u6A21\u5F0F",
  "provision.encryptinfo.mode": "\u6A21\u5F0F",
  "provision.encryptinfo.encryptKey": "\u66F4\u65B0\u5BC6\u94A5",
  "provision.encryptinfo.updateTime": "\u66F4\u65B0\u65F6\u95F4",
  "provision.encryptinfo.validityPeriod": "\u6709\u6548\u671F\uFF08\u6708\uFF09",
  "provision.encryptinfo.metadata": "\u5143\u6570\u636E\u52A0\u89E3\u5BC6",
  "provision.encryptinfo.email": "\u90AE\u4EF6\u5185\u5BB9\u52A0\u89E3\u5BC6",
  "provision.encryptinfo.title.encrypted.information": "\u52A0\u5BC6\u4FE1\u606F",
  "provision.encryptinfo.version": "\u7248\u672C",
  "provision.encryptinfo.historical.version": "\u5386\u53F2\u7248\u672C",
  "provision.encryptinfo.encryptKey.length.error": "\u5BC6\u94A5\u957F\u5EA6\u5E94\u4E3A\uFF1A",
  "provision.encryptinfo.update": "\u66F4\u65B0",
  "provision.encryptinfo.modedecrease": "CBC\u6A21\u5F0F\u6BD4GCM\u6A21\u5F0F\u5B89\u5168\u6027\u4F4E\uFF0C\u8BF7\u8C28\u614E\u9009\u62E9",
  "provision.encryptinfo.strengthdecrease": "\u51CF\u5C0F\u5F3A\u5EA6\u4F1A\u964D\u4F4E\u7B97\u6CD5\u5B89\u5168\u6027\uFF0C\u8BF7\u8C28\u614E\u9009\u62E9\uFF01",
  "provision.encryptinfo.updateInterval.error": "\u5BC6\u94A5\u66F4\u65B0\u95F4\u9694\u5C0F\u4E8E30\u5929",
  "provision.tenantspace.basic.information": "\u79DF\u6237\u57FA\u672C\u4FE1\u606F",

  "provision.tenant.management.tenantcreate.timezoneInfo": "\u65F6\u533A\u4FE1\u606F",
  "provision.tenant.management.timezone": "\u65F6\u533A",
  "provision.tenant.management.timezoneOffset": "\u65F6\u533A\u504F\u79FB",
  "provision.tenant.management.daylightSavingTime": "\u590F\u4EE4\u65F6",
  "provision.tenant.management.DSTOffset": "\u590F\u4EE4\u65F6\u504F\u79FB\u91CF\uFF08\u5206\u949F\uFF09",
  "provision.tenant.management.DSTStartDate": "\u590F\u4EE4\u65F6\u5F00\u59CB\u65E5",
  "provision.tenant.management.DSTStartTime": "\u590F\u4EE4\u65F6\u5F00\u59CB\u65F6\u95F4",
  "provision.tenant.management.DSTEndTime": "\u590F\u4EE4\u65F6\u7ED3\u675F\u65F6\u95F4",
  "provision.tenant.management.DSTEndDate": "\u590F\u4EE4\u65F6\u7ED3\u675F\u65E5",

  "provision.tenantspace.option.month_1": "1\u6708",
  "provision.tenantspace.option.month_3": "3\u6708",
  "provision.tenantspace.option.month_5": "5\u6708",
  "provision.tenantspace.option.month_4": "4\u6708",
  "provision.tenantspace.option.month_7": "7\u6708",
  "provision.tenantspace.option.month_2": "2\u6708",
  "provision.tenantspace.option.month_6": "6\u6708",
  "provision.tenantspace.option.month_11": "11\u6708",
  "provision.tenantspace.option.month_8": "8\u6708",
  "provision.tenantspace.option.month_10": "10\u6708",
  "provision.tenantspace.option.month_12": "12\u6708",
  "provision.tenantspace.option.weeklist_fir": "\u7B2C1\u4E2A",
  "provision.tenantspace.option.month_9": "9\u6708",
  "provision.tenantspace.option.weeklist_sec": "\u7B2C2\u4E2A",
  "provision.tenantspace.option.weeklist_tid": "\u7B2C3\u4E2A",
  "provision.tenantspace.option.weeklist_fthtolast": "\u5012\u6570\u7B2C4\u4E2A",
  "provision.tenantspace.option.weeklist_tidtolast": "\u5012\u6570\u7B2C3\u4E2A",
  "provision.tenantspace.option.weeklist_fth": "\u7B2C4\u4E2A",
  "provision.tenantspace.option.weeklist_sectolast": "\u5012\u6570\u7B2C2\u4E2A",
  "provision.tenantspace.option.weekday_sun": "\u661F\u671F\u65E5",
  "provision.tenantspace.option.weeklist_firtolast": "\u5012\u6570\u7B2C1\u4E2A",
  "provision.tenantspace.option.weekday_mon": "\u661F\u671F\u4E00",
  "provision.tenantspace.option.weekday_tue": "\u661F\u671F\u4E8C",
  "provision.tenantspace.option.weekday_thu": "\u661F\u671F\u56DB",
  "provision.tenantspace.option.weekday_wed": "\u661F\u671F\u4E09",
  "provision.tenantspace.option.weekday_fri": "\u661F\u671F\u4E94",
  "provision.tenantspace.timezone": "\u7CFB\u7EDF\u9ED8\u8BA4\u65F6\u533A",
  "provision.tenantspace.dstEndDateVerification": "\u590F\u4EE4\u65F6\u7ED3\u675F\u65E5\u4E0D\u80FD\u5C0F\u4E8E\u7B49\u4E8E\u590F\u4EE4\u65F6\u5F00\u59CB\u65E5",
  "provision.tenantspace.option.weekday_sat": "\u661F\u671F\u516D",
  "provision.tenantspace.modifyTimeZoneVerification": "\u53EA\u6709\u5546\u7528\u548C\u8BD5\u5546\u7528\u72B6\u6001\u652F\u6301\u4FEE\u6539\u65F6\u533A\u4FE1\u606F",
  "provision.tenantspace.save.oldsuccess": "\u65F6\u533A\u4FE1\u606F\u6CA1\u6709\u4FEE\u6539",
  "provision.tenantspace.timezone.updatemessage": "\u4FEE\u6539\u65F6\u533A\u53EF\u80FD\u5F71\u54CD\u8FC7\u6E21\u65F6\u671F\u7684\u4E1A\u52A1\u5904\u7406",
  "provision.tenantspace.timezone.updateconfim": "\u786E\u8BA4",
  "provision.tenantspace.verification.fail": "\u65F6\u95F4\u683C\u5F0F\u6821\u9A8C\u5931\u8D25",
  "provision.tenantspace.timezone.save.success": "\u65F6\u533A\u4FE1\u606F\u4FDD\u5B58\u6210\u529F",
  "provision.title.select": "\u9009\u62E9",
  "provision.tenantspace.timezone.config.error": "\u65F6\u533A\u4FE1\u606F\u4FEE\u6539\u5931\u8D25",
  "provision.tenant.management.tenantcreate.timezone.failed": "\u65F6\u533A\u4FE1\u606F\u6821\u9A8C\u5931\u8D25",


  "provision.callcent.error.savevccfirst": "\u5EA7\u5E2D\u672A\u7ED1\u5B9A\u6CE8\u518C\u670D\u52A1\u5668\uFF0C\u8BF7\u5148\u91CD\u65B0\u4FDD\u5B58\u865A\u62DF\u547C\u53EB\u4E2D\u5FC3\u3002",
  "ccp.version.microservice": "\u5FAE\u670D\u52A1",
  "provision.tenantspace.config.agentnum.limit": "\u6BCF\u6B21\u8BA2\u8D2D\u5EA7\u5E2D\u6570\u4E0D\u80FD\u8D85\u8FC7{0}\u3002",
  "provision.tenantspace.config.amsobjnum.unequal": "\u8BF7\u4FDD\u6301\u533F\u540D\u547C\u53EB\u670D\u52A1\u5668\u914D\u7F6E\u4E00\u81F4\uFF0C\u540C\u65F6\u9009\u62E9\u6216\u90FD\u4E0D\u9009\u62E9\u3002",
  "ccp.version.versioncode": "\u7248\u672C",
  "ccp.version.updateTime": "\u66F4\u65B0\u65F6\u95F4",
  "provision.tenantspace.dstoffsettime.verification.fail": "\u8F93\u5165\u503C\u4E3A\u6574\u6570\,\u503C\u8303\u56F4\-1440\~1440",

  "provision.web.validator.ip": "\u65E0\u6548\u7684IP\u5730\u5740\uFF08IPv4\u6216IPv6\u6216\u57DF\u540D\uFF09",

  "provision.systemparam.success.save": "\u53C2\u6570\u4FDD\u5B58\u6210\u529F",
  "provision.systemparam.success.reset": "\u53C2\u6570\u91CD\u7F6E\u6210\u529F",
  "provision.systemparam.error.reset": "\u53C2\u6570\u91CD\u7F6E\u5931\u8D25",
  "provision.systemparam.success.refresh": "\u53C2\u6570\u5237\u65B0\u6210\u529F",
  "provision.systemparam.error.save": "\u53C2\u6570\u4FDD\u5B58\u5931\u8D25",
  "provision.systemparam.error.refresh": "\u53C2\u6570\u5237\u65B0\u5931\u8D25",
  "provision.systemparam.error.notmatchrule": "\u53C2\u6570\u4E0D\u6EE1\u8DB3\u6821\u9A8C\u89C4\u5219",

  "provision.systemparam.error.noparamselected": "\u8BF7\u5148\u9009\u62E9\u53C2\u6570",
  "provision.sysparam.config.search": "\u67E5\u8BE2",
  "provision.sysparam.config.itemname": "\u53C2\u6570\u540D\u79F0",
  "provision.sysparam.config.refresh": "\u5237\u65B0",
  "provision.sysparam.config.value": "\u53C2\u6570\u503C",
  "provision.sysparam.config.itemdesc": "\u63CF\u8FF0",
  "provision.sysparam.config.reset": "\u91CD\u7F6E",
  "provision.sysparam.config.opterate": "\u64CD\u4F5C",
  "provision.sysparam.config.mod": "\u7F16\u8F91",
  "provision.sysparam.config.save": "\u4FDD\u5B58",
  "provision.sysparam.config.checkmessage": "\u542B\u6709\u7279\u6B8A\u5B57\u7B26(" < "\," > "\," / "\u7B49)",
  "provision.sysparam.config.itemname.tips": "\u8BF7\u8F93\u5165\u53C2\u6570\u540D\u79F0",
  "provision.sysparam.config.select.null": "\u8BF7\u9009\u62E9\u53C2\u6570!",
  "provision.sysparam.config.select.count": "\u6700\u591A\u9009\u62E9\u002010\u0020\u6761\u53C2\u6570\uFF01",
  "provision.sysparam.config.select.prompt": "\u63D0\u793A",
  "provision.sysparam.config.item.detail": "\u53C2\u6570\u4FE1\u606F",
  "provision.sysparam.config.title": "\u7CFB\u7EDF\u53C2\u6570",
  "provision.sysparam.config.item.catalog": "\u53C2\u6570\u7C7B\u522B",
  "provision.sysparam.config.reset.multiple.error": "\u4EE5\u4E0B\u53C2\u6570\u4E0D\u5141\u8BB8\u88AB\u91CD\u7F6E\uFF1A",
  "provision.systemparam.error.validate.inner": "\u6821\u9A8C\u53C2\u6570\u5931\u8D25",
  "provision.systemparam.error.validate.ruleinvalid": "\u6821\u9A8C\u89C4\u5219\u4E0D\u5408\u6CD5",
  "provision.systemparam.error.validate.ruleregexinvalid": "\u6821\u9A8C\u89C4\u5219\u8BED\u6CD5\u9519\u8BEF",
  "provision.systemparam.error.validate.notmatchrule": "\u8F93\u5165\u53C2\u6570\u4E0E\u6821\u9A8C\u89C4\u5219\u4E0D\u5339\u914D",
  "provision.systemparam.error.validate.notmatchrulealpha": "\u8BF7\u8F93\u5165\u5B57\u6BCD\u6570\u5B57\u6216\u4E0B\u5212\u7EBF\uFF0C\u4E14\u53EA\u80FD\u4EE5\u5B57\u6BCD\u6216\u4E0B\u5212\u7EBF\u5F00\u5934",
  "provision.systemparam.error.validate.notmatchruleemail": "\u8BF7\u8F93\u5165\u5408\u6CD5\u7684\u90AE\u7BB1\u5730\u5740",
  "provision.systemparam.error.validate.notmatchruleregex": "\u8F93\u5165\u53C2\u6570\u4E0E\u6821\u9A8C\u89C4\u5219\u7684\u6B63\u5219\u8868\u8FBE\u5F0F\u4E0D\u5339\u914D",
  "provision.systemparam.error.validate.notmatchruledigits": "\u8BF7\u8F93\u5165\u5408\u6CD5\u7684\u6B63\u6574\u6570",
  "provision.systemparam.error.validate.notmatchruleip": "\u8BF7\u8F93\u5165\u5408\u6CD5\u7684IPV4\u6216IPV6\u5730\u5740",
  "provision.systemparam.error.validate.notmatchrulemaxlength": "\u8F93\u5165\u53C2\u6570\u8D85\u51FA\u6700\u5927\u957F\u5EA6",
  "provision.systemparam.error.validate.watermarkinfo.notmatchruleregex": "\u53C2\u6570\u53EA\u5141\u8BB8\u8F93\u5165\u5305\u542B\u5927\u5C0F\u5199\u5B57\u6BCD\u3001\u6570\u5B57\u6216-_/{'@'}\u76840\u523020\u4F4D\u5B57\u7B26\u3002",
  "provision.systemparam.error.validate.notmatchrulemax": "\u8F93\u5165\u53C2\u6570\u8D85\u51FA\u6700\u5927\u503C",
  "provision.systemparam.error.validate.notmatchruleminlength": "\u8F93\u5165\u53C2\u6570\u4F4E\u4E8E\u6700\u5C0F\u957F\u5EA6",
  "provision.systemparam.error.validate.notmatchrulemin": "\u8F93\u5165\u53C2\u6570\u4F4E\u4E8E\u6700\u5C0F\u503C",
  "provision.systemparam.error.validate.notmatchruleoption": "\u8F93\u5165\u53C2\u6570\u4E0D\u5728\u679A\u4E3E\u8303\u56F4\u5185",
  "provision.systemparam.error.validate.notmatchrulerangelength": "\u8F93\u5165\u53C2\u6570\u8D85\u51FA\u6700\u5927\u957F\u5EA6\u6216\u4F4E\u4E8E\u6700\u5C0F\u957F\u5EA6",
  "provision.systemparam.error.validate.notmatchrulerange": "\u8F93\u5165\u53C2\u6570\u8D85\u51FA\u6700\u5927\u503C\u6216\u4F4E\u4E8E\u6700\u5C0F\u503C",
  "provision.systemparam.error.validate.notmatchrulenumber": "\u8BF7\u8F93\u5165\u6570\u5B57",
  "provision.systemparam.error.validate.notmatchrulerequired": "\u8F93\u5165\u53C2\u6570\u4E3A\u5FC5\u586B\u9879",
  "provision.systemparam.error.validate.userNotExists": "\u8BF7\u8F93\u5165\u5408\u6CD5\u7684\u7528\u6237\u8D26\u53F7",
  "provision.log.downloadSK": "\u4E0B\u8F7DSK",
  "provision.systemparam.error.validate.notmatchruleurl": "\u8BF7\u8F93\u5165\u5408\u6CD5\u7684URL\u5730\u5740",
  "provision.systemparam.url.safe.tip": "\u4F7F\u7528\u4E0D\u5B89\u5168\u534F\u8BAE\uFF0C\u5B58\u5728\u5B89\u5168\u98CE\u9669",
  "provision.tenantspace.feature.operation": "\u79DF\u6237\u7279\u6027\u6570\u636E\u6B63\u5728\u540E\u53F0\u5904\u7406\u6743\u9650\u6570\u636E\uFF0C\u8BF72\u5206\u949F\u540E\u4E8E\u5F53\u9875\u67E5\u770B\u7279\u6027\u6700\u7EC8\u5904\u7406\u7ED3\u679C\u3002",
  "provision.systemparam.url.unsafe": "\uFF08\u4E0D\u5B89\u5168\uFF09",
  "provision.ccp.redis.feature.success": "\u79DF\u6237\u7279\u6027\u6570\u636E\u6267\u884C\u6210\u529F\u3002",
  "provision.ccp.redis.feature.inprogress": "\u79DF\u6237\u7279\u6027\u6570\u636E\u6B63\u5728\u6267\u884C\u4E2D\u3002",
  "provision.tenantspace.feature.agentSendLocalMediaFile": "\u5EA7\u5E2D\u53D1\u9001\u672C\u5730\u591A\u5A92\u4F53\u6587\u4EF6",
  "provision.tenantspace.feature.agentSendLocalMediaFile.description": "\u5F00\u542F\u5EA7\u5E2D\u53D1\u9001\u672C\u5730\u591A\u5A92\u4F53\u6587\u4EF6\u7279\u6027\uFF0C\u79DF\u6237\u652F\u6301\u5EA7\u5E2D\u53D1\u9001\u672C\u5730\u591A\u5A92\u4F53\u6587\u4EF6\u529F\u80FD\u3002",
  "provision.tenantspace.feature.emailAgentSendLocalAttachment": "\u90AE\u4EF6\u5EA7\u5E2D\u53D1\u9001\u672C\u5730\u9644\u4EF6",
  "provision.tenantspace.feature.emailAgentSendLocalAttachment.description": "\u5F00\u542F\u90AE\u4EF6\u5EA7\u5E2D\u53D1\u9001\u672C\u5730\u9644\u4EF6\u7279\u6027\uFF0C\u79DF\u6237\u652F\u6301\u90AE\u4EF6\u5EA7\u5E2D\u53D1\u9001\u672C\u5730\u9644\u4EF6\u529F\u80FD\u3002",
  "provision.ccp.redis.feature.failed": "\u79DF\u6237\u7279\u6027\u6570\u636E\u6267\u884C\u5931\u8D25\u3002",

  "pprovision.tenantspace.feature.emailAgentCCAndBcc.description": "\u5F00\u542F\u90AE\u4EF6\u5EA7\u5E2D\u6284\u9001\u5BC6\u9001\u7279\u6027\uFF0C\u79DF\u6237\u652F\u6301\u90AE\u4EF6\u5EA7\u5E2D\u6284\u9001\u3001\u5BC6\u9001\u90AE\u4EF6\u529F\u80FD",

  "provision.tenantspace.feature.emailAgentCCAndBcc": "\u90AE\u4EF6\u5EA7\u5E2D\u6284\u9001\u5BC6\u9001",
  "pprovision.tenantspace.feature.emailAgentForwarding.description": "\u5F00\u542F\u90AE\u4EF6\u5EA7\u5E2D\u8F6C\u53D1\u7279\u6027\u3002\u79DF\u6237\u652F\u6301\u90AE\u4EF6\u5EA7\u5E2D\u90AE\u4EF6\u8F6C\u53D1\u529F\u80FD",

  "provision.tenantspace.feature.emailAgentForwarding": "\u90AE\u4EF6\u5EA7\u5E2D\u8F6C\u53D1",
  "provision.speciallist.tip.userNoLength": "\u7528\u6237\u53F7\u7801\u957F\u5EA6\u4E0D\u80FD\u8D85\u8FC7100",
  "provision.speciallist.tip.timeVerification": "\u8BF7\u9009\u62E9\u5927\u4E8E\u4ECA\u5929\u5E76\u5C0F\u4E8E2038-01-19\u7684\u65E5\u671F",

  "provision.speciallist.tip.reasonLength": "\u52A0\u5165\u539F\u56E0\u5B57\u7B26\u957F\u5EA6\u4E0D\u80FD\u8D85\u8FC71024",
  "provision.callcent.platformserver.cobrowse": "\u7F51\u9875\u534F\u540C\u670D\u52A1(Co-Browse)",
  "provision.tenantspace.feature.cobrowse.description": "\u5F00\u542F\u7F51\u9875\u534F\u540C\u7279\u6027\uFF0C\u79DF\u6237\u652F\u6301\u7F51\u9875\u534F\u540C\u529F\u80FD\u3002",
  "provision.tenantspace.feature.cobrowse": "\u7F51\u9875\u534F\u540C",
  "provision.tenantspace.feature.cobrowse.cobrowseMaxNumber": "\u6700\u5927\u7F51\u9875\u534F\u540C\u8FDE\u63A5\u6570",
  "provision.tenantspace.feature.cobrowse.needak": "\u5F00\u901A\u7F51\u9875\u534F\u540C\u7279\u6027\u9700\u8981\u914D\u7F6EAK\u4FE1\u606F\uFF0C\u8BF7\u5148\u914D\u7F6EAK\u4FE1\u606F\u3002",
  "provision.tenantspace.feature.cobrowse.selectcobrowsevalue.invalid": "\u6700\u5927\u7F51\u9875\u534F\u540C\u8FDE\u63A5\u6570\u53D6\u503C\u8303\u56F4\u4E3A1\u523050000\u3002",
  "provision.tenantspace.configureauthentication": "\u8BA4\u8BC1\u914D\u7F6E",
  "provision.tenantspace.feature.cobrowse.cobrowseMaxNumber.placeholder": "1\u523050000",
  "provision.tenant.management.tenantmodify.cobrowse.akempty": "\u79DF\u6237\u5DF2\u5F00\u901A\u7F51\u9875\u534F\u540C\u7279\u6027\uFF0CAK\u4FE1\u606F\u4E0D\u53EF\u4EE5\u4E3A\u7A7A\u3002",
  "provision.spinfo.id": "ID",
  "provision.spinfo.issuerId": "Issuer ID",
  "provision.spinfo.serviceUrl": "Service URL",

  "provision.spinfo.creator": "\u521B\u5EFA\u8005",
  "provision.spinfo.createTime": "\u521B\u5EFA\u65F6\u95F4",
  "provision.spinfo.issuerId.search": "\u8BF7\u8F93\u5165Issuer ID",
  "provision.spinfo.logoutUrl": "Logout URL",
  "provision.spinfo.description": "\u63CF\u8FF0",
  "provision.spinfo.idpfile": "IDP\u6587\u4EF6",
  "provision.spinfo.certificate": "\u8BC1\u4E66\u5185\u5BB9",
  "provision.spinfo.validatehttp": "\u4E0D\u7B26\u5408url\u683C\u5F0F",
  "provision.spinfo.selecdeletetspinfo": "\u8BF7\u9009\u62E9\u9700\u8981\u5220\u9664\u7684SP\u4FE1\u606F!",
  "provision.spinfo.willdeletespinfo": "\u786E\u5B9A\u5220\u9664\u6240\u9009\u7684SP\u4FE1\u606F\u5417\uFF1F",
  "provision.spinfo.addspinfosuccess": "SP\u4FE1\u606F\u6DFB\u52A0\u6210\u529F!",
  "provision.spinfo.modifyspinfosuccess": "SP\u4FE1\u606F\u4FEE\u6539\u6210\u529F!",
  "provision.spinfo.modifyspinfofailed": "SP\u4FE1\u606F\u4FEE\u6539\u5931\u8D25!",
  "provision.spinfo.addspinfofailed": "SP\u4FE1\u606F\u6DFB\u52A0\u5931\u8D25!",
  "provision.spinfo.deletespinfofailed": "SP\u4FE1\u606F\u5220\u9664\u5931\u8D25!",
  "provision.spinfo.deletespinfosuccess": "SP\u4FE1\u606F\u5220\u9664\u6210\u529F!",
  "provision.spinfo.beginlettervalidate": "\u8BF7\u4EE5\u5B57\u6BCD\u5F00\u5934",
  "provision.spinfo.msg.certificateformatinfo": "\u8BC1\u4E66\u7684\u8D77\u6B62\u6807\u8BC6\u201C-----BEGIN CERTIFICATE-----\u201D\u548C\u201C-----END CERTIFICATE-----\u201D\u4E0D\u80FD\u7701\u7565",

  "provision.accesscode.message.invalid": "\u975E\u6CD5\u5B57\u7B26",
  "provision.accesscode.table.enterpriseName": "\u4F01\u4E1A\u540D\u79F0",
  "provision.accesscode.table.callType": "\u53F7\u7801\u5C5E\u6027",
  "provision.accesscode.table.call": "\u547C\u5165\u548C\u547C\u51FA",
  "provision.accesscode.table.callout": "\u547C\u51FA",
  "provision.tenantspace.feature.appCubeSupport": "AppCube",
  "provision.accesscode.table.callin": "\u547C\u5165",
  "provision.tenantspace.feature.selectAppCubeSupport.description": "\u5F00\u542FAppCube\u7279\u6027\uFF0C\u79DF\u6237\u652F\u6301\u96C6\u6210AppCube\u529F\u80FD\u3002",
  "provision.tenantspace.feature.appcube.close.error": "\u5173\u95EDAppCube\u7279\u6027\u524D\u8BF7\u5148\u786E\u4FDD{0}\u7279\u6027\u5DF2\u5173\u95ED",
  "provision.tenantspace.feature.case.open.confirmmsg": "AppCube\u7279\u6027\u662FCase\u7279\u6027\u8FD0\u884C\u57FA\u7840\uFF0C\u5C06\u4F1A\u540C\u65F6\u5F00\u542F",
  "provision.tenantspace.feature.newcase.open.customcenter.confirmmsg": "AppCube\u7279\u6027\u4EE5\u53CA\u5BA2\u6237\u4E2D\u5FC3\u7279\u6027\u662FCase\u00202.0\u7279\u6027\u8FD0\u884C\u57FA\u7840\uFF0C\u5C06\u4F1A\u540C\u65F6\u5F00\u542F",
  "provision.tenantspace.feature.appcubeIkbs.close.error": "\u5173\u95EDAppCube\u7279\u6027\u524D\u8BF7\u5148\u5173\u95ED\u77E5\u8BC6\u5E93\u7279\u6027",
  "provision.tenantspace.feature.knowledge.open.confirmmsg": "AppCube\u7279\u6027\u662F\u77E5\u8BC6\u5E93\u8FD0\u884C\u57FA\u7840\uFF0C\u5C06\u4F1A\u540C\u65F6\u5F00\u542F",


  "provision.tenantspace.feature.OneClickTwoCallSupport.description": "\u5F00\u542F\u4E00\u952E\u53CC\u547C\u7279\u6027\uFF0C\u79DF\u6237\u652F\u6301\u4E00\u952E\u53CC\u547C\u529F\u80FD\u3002",
  "provision.tenantspace.feature.OneClickTwoCallSupport": "\u4E00\u952E\u53CC\u547C",
  "provision.tenantspace.feature.VoiceNotificationSupport.description": "\u5F00\u542F\u8BED\u97F3\u901A\u77E5\u7279\u6027\uFF0C\u79DF\u6237\u652F\u6301\u8BED\u97F3\u901A\u77E5\u529F\u80FD\u3002",
  "provision.tenantspace.feature.VoiceNotificationSupport": "\u8BED\u97F3\u901A\u77E5",
  "provision.tenantspace.feature.dualCallTransparentDisplaySupport": "\u4E3B\u53EB\u900F\u663E",
  "provision.tenantspace.feature.dualCallTransparentDisplaySupport.description": "\u5F00\u542F\u4E3B\u53EB\u900F\u663E\u7279\u6027\uFF0C\u53EF\u4EE5\u81EA\u5B9A\u4E49\u6307\u5B9A\u5EA7\u5E2D\u4FA7\u548C\u7528\u6237\u4FA7\u7684\u663E\u793A\u53F7\u7801\uFF08\u4F9D\u8D56\u53F7\u7801\u7EBF\u8DEF\u80FD\u901A\uFF09\uFF0C\u5982\u679C\u63A5\u5165\u7801\u4E3A\u7A7A\u5219\u9ED8\u8BA4\u5F00\u542F\u3002",

  "provision.appversionmanagement.systemType": "\u7CFB\u7EDF\u7C7B\u578B",
  "provision.appversionmanagement.versionNumber": "\u7248\u672C\u53F7",
  "provision.appversionmanagement.batchDelete": "\u6279\u91CF\u5220\u9664",
  "provision.appversionmanagement.pleaseSelect": "\u8BF7\u9009\u62E9",
  "provision.appversionmanagement.new": "\u65B0\u5EFA",
  "provision.appversionmanagement.pleaseEnter": "\u8BF7\u8F93\u5165",
  "provision.appversionmanagement.versionName": "\u7248\u672C\u540D\u79F0",
  "provision.appversionmanagement.updateContent": "\u66F4\u65B0\u5185\u5BB9",
  "provision.appversionmanagement.downloadAddress": "\u4E0B\u8F7D\u5730\u5740",
  "provision.appversionmanagement.forceUpdate": "\u5F3A\u5236\u66F4\u65B0",
  "provision.appversionmanagement.appPackage": "\u7248\u672C\u5305",
  "provision.appversionmanagement.updateTime": "\u66F4\u65B0\u65F6\u95F4",
  "provision.appversionmanagement.iOS": "IOS",
  "provision.appversionmanagement.android": "\u5B89\u5353",
  "provision.appversionmanagement.androidH5": "\u5b89\u5353\u0020\u0048\u0035",
  "provision.appversionmanagement.iOSH5": "IOS H5",
  "provision.appversionmanagement.harmony": "\u9E3F\u8499",
  "provision.appversionmanagement.sureDeleteVersionNumber": "\u662F\u5426\u786E\u8BA4\u5220\u9664\u672C\u6761\u7248\u672C\u4FE1\u606F",
  "provision.appversionmanagement.latestVersion": "\u6700\u65B0\u7248\u672C",
  "provision.appversionmanagement.selectDataDeleted": "\u8BF7\u9009\u62E9\u9700\u8981\u5220\u9664\u7684\u6570\u636E",
  "provision.appversionmanagement.sureDeleteData": "\u786E\u8BA4\u5220\u9664\u8FD9\u4E9B\u6570\u636E\u5417?",
  "provision.appversionmanagement.queryFailed ": "\u67E5\u8BE2\u9519\u8BEF ",
  "provision.appversionmanagement.historicalVersion": "\u5386\u53F2\u7248\u672C",
  "provision.appversionmanagement.cannotEnterNumberSmaller": "\u4E0D\u80FD\u8F93\u5165\u6BD4\u6700\u5927\u7248\u672C\u53F7\u5C0F\u7684\u6570\u5B57",
  "provision.appversionmanagement.cannotContainSpecialCharacters": "\u4E0D\u80FD\u8F93\u5165\u7279\u6B8A\u5B57\u7B26[~!{'@'}#$%&*()/\\_=+{}:&#39;&quot;&lt;&gt;?[\]]",
  "provision.appsitesmanagement.siteName": "\u7AD9\u70B9\u540D\u79F0",
  "provision.appsitesmanagement.batchDelete": "\u6279\u91CF\u5220\u9664",
  "provision.appsitesmanagement.new": "\u65B0\u5EFA",
  "provision.appsitesmanagement.pleaseEnter": "\u8BF7\u8F93\u5165",
  "provision.appsitesmanagement.siteDesc ": "\u7AD9\u70B9\u63CF\u8FF0",
  "provision.appsitesmanagement.videoAvailable ": "\u662F\u5426\u90E8\u7F72\u89C6\u9891",
  "provision.appsitesmanagement.siteAddr ": "\u7AD9\u70B9\u5730\u5740",
  "provision.appsitesmanagement.appcubeAvailable ": "\u662F\u5426\u96C6\u6210AppCube",
  "provision.appsitesmanagement.creator ": "\u521B\u5EFA\u8005",
  "provision.appsitesmanagement.createTime ": "\u521B\u5EFA\u65F6\u95F4",
  "provision.appsitesmanagement.sureSelectDataDeleted": "\u8BF7\u9009\u62E9\u9700\u8981\u5220\u9664\u7684\u6570\u636E",
  "provision.appsitesmanagement.modifyTime ": "\u4FEE\u6539\u65F6\u95F4",
  "provision.appsitesmanagement.sureDeleteData": "\u5220\u9664\u8BE5\u7AD9\u70B9\u53EF\u80FD\u5F15\u8D77\u624B\u673AAPP\u4E0D\u80FD\u9009\u62E9\u767B\u5F55\uFF0C\u60A8\u786E\u8BA4\u5220\u9664\u5417\uFF1F",
  "provision.appsitesmanagement.queryFailed ": "\u67E5\u8BE2\u9519\u8BEF",
  "provision.appsitesmanagement.modifier ": "\u4FEE\u6539\u8005",
  "provision.appsitesmanagement.sureBatchDeleteData": "\u5220\u9664\u8FD9\u4E9B\u7AD9\u70B9\u53EF\u80FD\u5F15\u8D77\u624B\u673AAPP\u4E0D\u80FD\u9009\u62E9\u767B\u5F55\uFF0C\u60A8\u786E\u8BA4\u5220\u9664\u5417\uFF1F",
  "provision.appsitesmanagement.enable": "\u662F",
  "provision.appsitesmanagement.details ": "\u67E5\u770B",
  "provision.appsitesmanagement.cannotContainSpecialCharacters": "\u4E0D\u80FD\u8F93\u5165\u7279\u6B8A\u5B57\u7B26[~!{'@'}#$%&*()/\\_=+{}:&#39;&quot;&lt;&gt;?[\]]",
  "provision.appsitesmanagement.unable": "\u5426",
  "provision.appsitesmanagement.protocol.check": "\u8BF7\u6CE8\u610F\uFF0C\u5F53\u524D\u8F93\u5165\u7684\u7AD9\u70B9\u5730\u5740\u6240\u4F7F\u7528\u7684\u7F51\u7EDC\u534F\u8BAE\u4E0D\u662F\u5B89\u5168\u534F\u8BAE\uFF0C\u5982\u786E\u5B9A\u4F7F\u7528\uFF0C\u53EF\u80FD\u5B58\u5728\u5B89\u5168\u98CE\u9669",
  "provision.callcenterinstanceslist.softPhone.authtype": "\u8BA4\u8BC1\u65B9\u5F0F",
  "provision.softphone.label.authtype.uap": "UAP\u8BA4\u8BC1",
  "provision.softphone.label.authtype.unified": "\u7EDF\u4E00\u8BA4\u8BC1",
  "provision.tenantspace.feature.softphonenounifiedauth": "\u8F6F\u7535\u8BDD\u53F7\u7801\u7EDF\u4E00\u8BA4\u8BC1",
  "provision.tenantspace.feature.softphonenounifiedauth.description": "\u5F00\u542F\u8F6F\u7535\u8BDD\u53F7\u7801\u7EDF\u4E00\u8BA4\u8BC1\u7279\u6027\uFF0C\u79DF\u6237\u652F\u6301\u8F6F\u7535\u8BDD\u53F7\u7801\u7EDF\u4E00\u8BA4\u8BC1\u529F\u80FD\u3002",
  "provision.log.downloadIdpMetadtaXml": "\u4E0B\u8F7DIDP\u5143\u6570\u636EXML",
  "provision.tenantspace.save.successwithsamevccid": "\u4FDD\u5B58\u6210\u529F\uFF0C\u5B58\u5728\u865A\u62DF\u547C\u53EB\u4E2D\u5FC3\u7F16\u53F7\u4E0E\u5F53\u524D\u79DF\u6237\u76F8\u540C\u7684\u79DF\u6237\uFF0C\u8BF7\u8054\u7CFB\u7BA1\u7406\u5458\u5904\u7406\u3002",

  "provision.appversion.msg.uploadfile.data.fail": "\u4E0A\u4F20\u6587\u4EF6\u53C2\u6570\u4E0D\u6B63\u786E",
  "provision.appversion.msg.uploadfile.type.fail": "\u4E0A\u4F20\u6587\u4EF6\u7C7B\u578B\u4E0D\u7B26\u5408\u8981\u6C42",
  "provision.appversion.msg.uploadfile.2.fail": "\u4E0A\u4F20\u6587\u4EF6\u7C7B\u578B\u6821\u9A8C\u5931\u8D25",
  "provision.appversion.msg.uploadfile.1.fail": "\u4E0A\u4F20\u6587\u4EF6\u540D\u6821\u9A8C\u5931\u8D25",
  "provision.appversion.msg.uploadfile.4.fail": "\u4E0A\u4F20\u6587\u4EF6\u5B58\u5728\u6CE8\u5165\u5206\u6790",
  "provision.appversion.msg.uploadfile.3.fail": "\u4E0A\u4F20\u6587\u4EF6\u5927\u5C0F\u6821\u9A8C\u5931\u8D25\uFF0C\u8BF7\u68C0\u67E5\u8BBE\u7F6E-\u7CFB\u7EDF\u53C2\u6570\u914D\u7F6E-\u624B\u673AAPP\u5B89\u88C5\u5305\u4E0A\u4F20\u5927\u5C0F\u9650\u5236\u3002",
  "provision.appversion.msg.uploadfile.9.fail": "\u4E0A\u4F20\u6587\u4EF6\u6821\u9A8C\u5931\u8D25",
  "provision.appversion.msg.uploadfile.fail": "\u4E0A\u4F20\u6587\u4EF6\u5931\u8D25\\uFFEF\uFFFD\uFFFD\u8BF7\u91CD\u65B0\u7F16\u8F91\u4E0A\u4F20\u3002",
  "provision.appversion.msg.uploadfile.0.success": "\u4E0A\u4F20\u6587\u4EF6\u6821\u9A8C\u6210\u529F",
  "provision.appversion.msg.process.fail": "App\u7248\u672C\u4FE1\u606F\u5904\u7406\u5931\u8D25",
  "provision.appversion.msg.process.modify": "\u4FEE\u6539App\u7248\u672C\u4FE1\u606F",
  "provision.appversion.msg.process.add": "\u65B0\u589EApp\u7248\u672C\u4FE1\u606F",
  "provision.appversionmanagement.appPackageTips": "\u53EA\u652F\u6301\u4E0A\u4F20apk\u7C7B\u578B\u7684\u6587\u4EF6",
  "provision.callcent.packagefile.saveinfo": "\u521B\u5EFA\u6210\u529F\uFF0C\u7248\u672C\u5305\u6B63\u5728\u5904\u7406\u4E2D\uFF0C\u8BF7\u7B49\u5F85\u9875\u9762\u5237\u65B0\u7248\u672C\u5305\u5904\u7406\u7ED3\u679C\u3002",
  "provision.callcent.packagefile.updateinfo": "\u66F4\u65B0\u6210\u529F\uFF0C\u7248\u672C\u5305\u6B63\u5728\u5904\u7406\u4E2D\uFF0C\u8BF7\u7B49\u5F85\u9875\u9762\u5237\u65B0\u7248\u672C\u5305\u5904\u7406\u7ED3\u679C\u3002",
  "provision.appversion.msg.uploadfile.strem.fail": "\u751F\u6210\u6587\u4EF6\u6D41\u5931\u8D25",
  "provision.ccp.redis.packagefile.inprogress": "\u7248\u672C\u5305\u6B63\u5728\u4E0A\u4F20\u4E2D",
  "provision.appversion.msg.throttling.fail": "\u521B\u5EFA\u6216\u4FEE\u6539\u63A5\u53E3\u9650\u6D41\\uFFEF\uFFFD\uFFFD\u4E24\u5206\u949F\u4E00\u6B21\\uFFEF\uFFFD\uFFFD\u8BF7\u7A0D\u540E\u91CD\u8BD5\u3002",
  "provision.appversionmanagement.regVersion": "\u7248\u672C\u53F7\u7531\u5B57\u6BCD\u3001\u6570\u5B57\u548C\u70B9\u7EC4\u6210\uFF0C\u4E0D\u80FD\u4EE5\u70B9\u5F00\u5934\u6216\u7ED3\u5C3E",
  "provision.ccp.redis.packagefile.success": "\u7248\u672C\u5305\u4E0A\u4F20\u6210\u529F",
  "provision.appversionmanagement.protocol.check": "\u8BF7\u6CE8\u610F\uFF0C\u5F53\u524D\u8F93\u5165\u7684\u4E0B\u8F7D\u5730\u5740\u6240\u4F7F\u7528\u7684\u7F51\u7EDC\u534F\u8BAE\u4E0D\u662F\u5B89\u5168\u534F\u8BAE\uFF0C\u5982\u786E\u5B9A\u4F7F\u7528\uFF0C\u53EF\u80FD\u5B58\u5728\u5B89\u5168\u98CE\u9669",
  "provision.ccp.redis.packagefile.failed": "\u7248\u672C\u5305\u4E0A\u4F20\u5931\u8D25",
  "provision.whiteListAddress.channel.push": "\u591A\u5A92\u4F53\u6E20\u9053\u6D88\u606F\u63A8\u9001\u5730\u5740",
  "provision.callcent.platformserver.kafka": "\u6D88\u606F\u4E2D\u95F4\u4EF6(Kafka)",
  "provision.whiteListAddress.downlink.message.apifabric.push": "\u591A\u5A92\u4F53\u6D88\u606F\u4E0B\u884CAPI Fabric\u5730\u5740",
  "provision.callcent.platformserver.account": "\u670D\u52A1\u8D26\u53F7",
  "provision.callcent.platformserver.kafka.switch.panel": " \u662F\u5426\u542F\u7528\u670D\u52A1",
  "provision.whiteListAddress.callout.push": "\u5916\u547C\u7ED3\u679C\u63A8\u9001\u5730\u5740",
  "provision.whiteListAddress.internal.message.notification": "\u7AD9\u5185\u4FE1\u901A\u77E5\u8C03\u7528APP\u670D\u52A1\u7684IP\u7AEF\u53E3",

  "provision.callcent.platformserver.es": "\u641C\u7D22\u670D\u52A1 (ElasticSearch)",
  "provision.callcent.platformserver.certificateChoose": "\u9009\u62E9\u8BC1\u4E66",
  "provision.tenantspace.feature.OneClickTwoCallSupport.featureParams.label": "\u547C\u53EB\u53C2\u6570",
  "provision.tenantspace.feature.OneClickTwoCallSupport.featureParams.placeholder": "\u8BF7\u6839\u636E\u79DF\u6237\u914D\u7F6E\u7684IVR\u5E76\u53D1\u6570\uFF0C\u6838\u5FC3\u7F51\u5E76\u53D1\u6570\uFF0C\u53F7\u7EBF\u5E76\u53D1\u6570\u5408\u7406\u914D\u7F6E",

  "provision.tenantspace.feature.OneClickTwoCallSupport.featureParams.callPeriod.label": "\u547C\u53EB\u5468\u671F\uFF081\u79D2~60\u79D2\uFF09",
  "provision.tenantspace.feature.OneClickTwoCallSupport.featureParams.callNums.label": "\u547C\u53EB\u6570\u636E\u4E2A\u6570\uFF081\u4E2A~10\u4E2A\uFF09",
  "provision.tenantspace.feature.VoiceNotificationSupport.featureParams.placeholder": "\u8BF7\u6839\u636E\u79DF\u6237\u914D\u7F6E\u7684IVR\u5E76\u53D1\u6570\uFF0C\u6838\u5FC3\u7F51\u5E76\u53D1\u6570\uFF0C\u53F7\u7EBF\u5E76\u53D1\u6570\u5408\u7406\u914D\u7F6E",
  "provision.tenantspace.feature.VoiceNotificationSupport.featureParams.callPeriod.label": "\u547C\u53EB\u5468\u671F\uFF081\u79D2~60\u79D2\uFF09",
  "provision.tenantspace.feature.VoiceNotificationSupport.featureParams.label": "\u547C\u53EB\u53C2\u6570",

  "provision.subscribedetail.pageTitle": "\u4E00\u952E\u5F00\u901A\u660E\u7EC6\u8BE6\u60C5",
  "provision.subscribedetail.button.save": "\u4FDD\u5B58",
  "provision.subscribedetail.button.reset": "\u91CD\u7F6E",
  "provision.tenantspace.feature.VoiceNotificationSupport.featureParams.callNums.label": "\u547C\u53EB\u6570\u636E\u4E2A\u6570\uFF081\u4E2A~10\u4E2A\uFF09",
  "provision.subscribedetail.button.search": "\u67E5\u8BE2",
  "provision.subscribedetail.button.update": "\u7F16\u8F91",
  "provision.subscribedetail.button.delete": "\u5220\u9664",
  "provision.subscribedetail.label.companyName": "\u516C\u53F8\u540D\u79F0",
  "provision.subscribedetail.label.contactName": "\u8054\u7CFB\u4EBA\u59D3\u540D",
  "provision.subscribedetail.label.operate": "\u64CD\u4F5C",
  "provision.subscribedetail.label.phoneNo": "\u8054\u7CFB\u4EBA\u7535\u8BDD",
  "provision.subscribedetail.label.emailAddr": "\u8054\u7CFB\u4EBA\u90AE\u7BB1",
  "provision.subscribedetail.label.companySize": "\u516C\u53F8\u89C4\u6A21",
  "provision.subscribedetail.label.enableStatus": "\u5F00\u901A\u7ED3\u679C",
  "provision.subscribedetail.label.emailStatus": "\u90AE\u4EF6\u5DF2\u53D1\u9001",
  "provision.subscribedetail.label.createTime": "\u7533\u8BF7\u65F6\u95F4",
  "provision.subscribedetail.success.title": "\u6210\u529F",
  "provision.subscribedetail.success.save": "\u4FDD\u5B58\u6210\u529F",
  "provision.subscribedetail.success.delete": "\u5220\u9664\u6210\u529F",
  "provision.subscribedetail.delete.title": "\u5220\u9664\u786E\u8BA4",
  "provision.subscribedetail.delete.confirm": "\u662F\u5426\u786E\u5B9A\u5220\u9664\u672C\u6761\u5F00\u901A\u660E\u7EC6",
  "provision.subscribedetail.error.title": "\u5931\u8D25",
  "provision.subscribedetail.error.save": "\u4FDD\u5B58\u5931\u8D25",
  "provision.subscribedetail.error.delete": "\u5220\u9664\u5931\u8D25",
  "provision.subscribedetail.label.remark": "\u5907\u6CE8",
  "provision.subscribedetail.error.query": "\u67E5\u8BE2\u5931\u8D25",
  "provision.subscribedetail.error.query.maxDay_30": "\u67E5\u8BE2\u65F6\u95F4\u95F4\u9694\u4E0D\u80FD\u5927\u4E8E30\u5929",
  "provision.subscribedetail.enableStatus.value_0": "\u5F00\u901A\u5931\u8D25",
  "provision.subscribedetail.enableStatus.value_1": "\u5F00\u901A\u6210\u529F",
  "provision.subscribedetail.emailStatus.value_1": "\u63D0\u4EA4\u6210\u529F",
  "provision.subscribedetail.validate.beginwithalpha": "\u5FC5\u987B\u4EE5\u5B57\u6BCD\u5F00\u5934",
  "provision.subscribedetail.validate.alphavalidate": "\u5B58\u5728\u975E\u6CD5\u5B57\u7B26\uFF0C\u8F93\u5165\u503C\u53EA\u80FD\u7531\u5B57\u6BCD\u3001\u6570\u5B57\u6216\u4E0B\u5212\u7EBF\u7EC4\u6210\u3002",
  "provision.subscribedetail.emailStatus.value_0": "\u63D0\u4EA4\u5931\u8D25",
  "provision.subscribedetail.validate.laterThanEndTime": "\u8D77\u59CB\u65F6\u95F4\u4E0D\u80FD\u5927\u4E8E\u7ED3\u675F\u65F6\u95F4",
  "provision.subscribedetail.label.createSource": "\u521B\u5EFA\u6765\u6E90",
  "provision.subscribedetail.createSource.value_0": "\u534E\u4E3A\u4E91\u5B98\u7F51",

  "provision.tenantspace.feature.dualCallAPPTransparentDisplaySupport": "App\u547C\u53EB\u4EFB\u52A1",
  "provision.tenantspace.feature.dualCallAPPTransparentDisplaySupport.description": "\u5F00\u542FApp\u547C\u53EB\u4EFB\u52A1\u7279\u6027\uFF0C\u79DF\u6237\u4E0B\u7684\u5EA7\u5E2D\u53EF\u4EE5\u5728\u624B\u673AAPP\u4E0A\u5904\u7406\u547C\u53EB\u4EFB\u52A1\u3002",
  "provision.subscribedetail.createSource.value_1": "\u8F6F\u4EF6\u4E91\u5B98\u7F51",

  "provision.tenantspace.feature.onlineCustomerServiceAssets": "\u5728\u7EBF\u5BA2\u670D\u8D44\u4EA7",
  "provision.tenantspace.feature.onlineCustomerServiceAssets.description": "\u5F00\u542F\u5728\u7EBF\u5BA2\u670D\u8D44\u4EA7\u7279\u6027\u540E\uFF0C\u79DF\u6237\u53EF\u4EE5\u5C06appcube\u5F00\u53D1\u7684js chat\u5728\u7EBF\u804A\u5929\u5BA2\u6237\u7AEF\u5D4C\u5165\u5230\u7B2C\u4E09\u65B9\u9875\u9762\u4E2D\u5B9E\u73B0\u5728\u7EBF\u804A\u5929\u3002",
  "provision.tenantspace.feature.onlineCustomerServiceAssets.open.confirmmsg": "AppCube\u548C\u667A\u80FDIVR\u7279\u6027\u662F\u5728\u7EBF\u5BA2\u670D\u8D44\u4EA7\u7279\u6027\u8FD0\u884C\u57FA\u7840\uFF0C\u5C06\u4F1A\u540C\u65F6\u5F00\u542F",
  "provision.tenantspace.feature.dualCallAppSupport.open.confirmmsg": "AppCube\u7279\u6027\u662FApp\u547C\u53EB\u4EFB\u52A1\u7279\u6027\u8FD0\u884C\u57FA\u7840\uFF0C\u5C06\u4F1A\u540C\u65F6\u5F00\u542F",

  "provision.tenantspace.feature.smartivr.close.error": "\u5173\u95ED\u667A\u80FDIVR\u7279\u6027\u524D\u8BF7\u5148\u5173\u95ED\u5728\u7EBF\u5BA2\u670D\u8D44\u4EA7\u7279\u6027",
  "provision.tenant.management.tenantexport.addTask": "\u65B0\u5EFA\u5BFC\u51FA\u4EFB\u52A1",
  "provision.tenant.management.tenantexport.viewTask": "\u67E5\u8BE2\u5BFC\u51FA\u4EFB\u52A1",
  "provision.tenant.management.tenantexport.cancel": "\u53D6\u6D88",
  "provision.tenant.management.tenantexport.confirm": "\u786E\u8BA4",
  "provision.tenant.management.tenantexport.exceedlimit2": "\uFF0C\u8BF7\u7F29\u5C0F\u6570\u636E\u65F6\u95F4\u8303\u56F4\u6216\u589E\u52A0\u6570\u636E\u8FC7\u6EE4\u6761\u4EF6",
  "provision.tenant.management.tenantexport.addTaskSuccess": "\u65B0\u589E\u5BFC\u51FA\u4EFB\u52A1\u6210\u529F",
  "provision.tenant.management.tenantexport.exceedlimit1": "\u6700\u591A\u53EF\u4EE5\u5BFC\u51FA\{0}\u6761\u6570\u636E",
  "provision.tenant.management.tenantexport.addTaskError": "\u65B0\u589E\u5BFC\u51FA\u4EFB\u52A1\u5931\u8D25\,\u8BF7\u68C0\u67E5\u65E5\u5FD7",
  "provision.label.tenantexport.exception": "\u751F\u6210\u5BFC\u51FA\u4EFB\u52A1\u9047\u5230\u5F02\u5E38\uFF0C\u8BF7\u68C0\u67E5\u65E5\u5FD7",
  "provision.label.tenantexport.parainnull": "\u8BF7\u9009\u62E9\u8981\u5BFC\u51FA\u7684\u6570\u636E",
  "provision.label.tenantexport.paracomplexity": "\u5BC6\u7801\u590D\u6742\u5EA6\u4E0D\u7B26\u5408\u8981\u6C42",
  "provision.common.message.success": "\u6210\u529F",
  "provision.label.tenantexport.parainvalid": "\u5BFC\u51FA\u6761\u4EF6\u6821\u9A8C\u5931\u8D25",
  "provision.label.tenantexport.addExportTask": "\u65B0\u589E\u5BFC\u51FA\u4EFB\u52A1",
  "provision.label.tenantexport.viewExportTask": "\u5BFC\u51FA\u4EFB\u52A1\u67E5\u770B",
  "provision.common.message.error": "\u9519\u8BEF",
  "provision.label.tenantexport.confirm": "\u786E\u8BA4",
  "provision.label.information": "\u63D0\u793A",
  "provision.label.tenantexport.confirmMessage": "\u662F\u5426\u786E\u8BA4\u65B0\u5EFA\u5BFC\u51FA\u4EFB\u52A1\uFF1F",
  "provision.label.tenantexport.download": "\u4E0B\u8F7D",
  "provision.label.tenantexport.exportBeginTime": "\u5BFC\u51FA\u5F00\u59CB\u65F6\u95F4",
  "provision.label.tenantexport.refresh": "\u5237\u65B0",
  "provision.label.tenantexport.conditions": "\u67E5\u8BE2\u6761\u4EF6",
  "provision.label.tenantexport.exportEndTime": "\u5BFC\u51FA\u7ED3\u675F\u65F6\u95F4",
  "provision.label.tenantexport.exportTaskStatus": "\u5BFC\u51FA\u4EFB\u52A1\u72B6\u6001",
  "provision.label.tenantexport.addExportTaskSuccess": "\u65B0\u589E\u5BFC\u51FA\u4EFB\u52A1\u6210\u529F",
  "provision.label.tenantexport.addExportTaskError": "\u65B0\u589E\u5BFC\u51FA\u4EFB\u52A1\u5931\u8D25\,\u8BF7\u68C0\u67E5\u65E5\u5FD7",
  "provision.label.tenantexport.operation": "\u64CD\u4F5C",
  "provision.label.tenantexport.exportiong": "\u5BFC\u51FA\u4E2D",
  "provision.label.tenantexport.failed": "\u5931\u8D25",
  "provision.label.tenantexport.success": "\u6210\u529F",
  "provision.label.tenantexport.passValidateFailed": "\u9700\u5305\u542B\u5B57\u6BCD\,\u6570\u5B57\,\u7279\u6B8A\u5B57\u7B26 _ {'@'} %",
  "provision.label.tenantexport.zippwd": "\u538B\u7F29\u5BC6\u7801",
  "provision.label.tenantexport.inputzippwd": "\u8F93\u5165\u538B\u7F29\u5BC6\u7801",
  "provision.label.tenantexport.popwindowmessage": "\u8BF7\u8F93\u5165\u7528\u4E8E\u751F\u6210\u538B\u7F29\u6587\u4EF6\u7684\u5BC6\u7801\,\u538B\u7F29\u5BC6\u7801\u5E94\u5305\u542B\u5B57\u6BCD\,\u6570\u5B57\,\u7279\u6B8A\u5B57\u7B26 _ {'@'} %",
  "provision.label.tenantexport.importdata": "\u5BFC\u5165\u6570\u636E",
  "provision.tenantexport.download": "\u4E0B\u8F7D\u79DF\u6237\u4FE1\u606F",
  "provision.tenant.management.tenantcreate.param.content.rule": "\u8F93\u5165\u53C2\u6570\u7531\u5B57\u6BCD\u6570\u5B57\u4E0B\u5212\u7EBF\u7EC4\u6210\u4E14\u5FC5\u987B\u4EE5\u5B57\u6BCD\u5F00\u5934\,\u957F\u5EA6\u81F3\u5C11\u4E3A2",
  "provision.label.tenantexport.unprocessed": "\u672A\u5904\u7406",
  "provision.tenant.management.tenantcreate.param.exceed": "\u8F93\u5165\u53C2\u6570\u957F\u5EA6\u987B\u5C0F\u4E8E30",
  "provision.tenant.management.tenantcreate.param.failed": "\u8F93\u5165\u53C2\u6570\u4E0D\u5408\u6CD5",
  "provision.tenantspace.feature.transfer.onlinecheck": "\u8F6C\u50A8\u8D44\u6E90\u5728\u7EBF\u67E5\u9605",
  "provision.tenantspace.feature.transfer.onlinecheck.description": "\u5F00\u542F\u8F6C\u50A8\u8D44\u6E90\u5728\u7EBF\u67E5\u9605\u7279\u6027\u7279\u6027\uFF0C\u79DF\u6237\u652F\u6301\u5728\u7EBF\u67E5\u9605\u8F6C\u50A8\u8D44\u6E90",
  "provision.tenantspace.detail.tenantspace.cannot.open.uploadfeature": "\u662F\u5426\u540C\u65F6\u5F00\u542F\u8D44\u6E90\u8F6C\u50A8\u548C\u8F6C\u50A8\u8D44\u6E90\u5728\u7EBF\u67E5\u9605\u7279\u6027",
  "provision.tenantspace.open.uploadfeature": "\u786E\u8BA4\u5F00\u901A",
  "provision.vcallcenter.message.unknowcause": "\u672A\u77E5\u539F\u56E0",
  "provision.vcallcenter.message.unknowsolution": "\u8BF7\u8054\u7CFB\u534E\u4E3A\u5DE5\u7A0B\u5E08\u8FDB\u884C\u5F02\u5E38\u5206\u6790\u5904\u7406",
  "provision.whiteListAddress.resourcedump.css": "\u8D44\u6E90\u8F6C\u50A8CSS\u670D\u52A1\u5668",
  "provision.tenantspace.detail.tenantspace.cannot.close.uploadfeature": "\u8BF7\u5148\u5173\u95ED\u8F6C\u50A8\u8D44\u6E90\u5728\u7EBF\u67E5\u9605\u7279\u6027",

  "provision.vcallcenter.message.unknowdesc": "\u672A\u77E5\u5F02\u5E38",
  "provision.whiteListAddress.resourcedump.cssproxy": "\u8D44\u6E90\u8F6C\u50A8CSS\u670D\u52A1\u5668\u4EE3\u7406",
  "provision.systemparam.algorithm.unsafe": "\u4F7F\u7528\u4E0D\u5B89\u5168\u7B97\u6CD5\uFF0C\u5B58\u5728\u5B89\u5168\u98CE\u9669",
  "provision.systemparam.algorithm.suggest": "\u6CE8\u610F\u4E0EUAP\u4E00\u81F4",
  "provision.tenantspace.feature.contractdigitalsignature": "\u5408\u540C\u6570\u5B57\u7B7E\u540D",
  "provision.tenantspace.feature.contractdigitalsignature.description": "\u5F00\u542F\u5408\u540C\u6570\u5B57\u7B7E\u540D\u7684\u7279\u6027\uFF0C\u79DF\u6237\u652F\u6301\u63D0\u4F9B\u7535\u5B50\u5408\u540C\u7684\u6570\u5B57\u7B7E\u540D\u3002",

  "provision.callcenterinstanceslist.vcallcenter.ccdis": "CCDIS\u670D\u52A1\u5668",

  "provision.callcenterinstanceslist.ccdis.ccdisIP": "\u4E3B\u7528IP\u5730\u5740",
  "provision.callcenterinstanceslist.ccdis.ccdisbackupIP": "\u5907\u7528IP\u5730\u5740",
  "provision.callcenterinstanceslist.ccdis.progId": "ProgID",
  "provision.callcenterinstanceslist.ccdis.ccdisId": "\u7F16\u53F7",
  "provision.callcenterinstanceslist.ccdis.remark": "\u7528\u9014\u8BF4\u660E",
  "provision.callcenterinstanceslist.ccdis.poolNodeId2": "\u5907\u7528CCDIS\u6240\u5C5ECTI\u8282\u70B9",
  "provision.callcenterinstanceslist.ccdis.tenantSpaceName": "\u79DF\u6237\u540D\u79F0",
  "provision.callcenterinstanceslist.ccdis.syncconfim.msg": "\u662F\u5426\u786E\u5B9A\u540C\u6B65CCDIS\u76F8\u5173\u914D\u7F6E\uFF1F",
  "provision.callcenterinstanceslist.ccdis.ccdisUpdate": "\u7F16\u8F91",
  "provision.callcenterinstanceslist.ccdis.poolNodeId": "\u4E3B\u7528CCDIS\u6240\u5C5ECTI\u8282\u70B9",
  "provision.callcenterinstanceslist.ccdis.ccdisDeleteConfirm": "\u662F\u5426\u786E\u8BA4\u5220\u9664\u9009\u4E2D\u7684\u0020CCDIS\u0020\u670D\u52A1\u5668\uFF1F",
  "provision.callcenterinstanceslist.ccdis.ccdisNetId": "\u4E3B\u7528\u7F51\u5143ID",
  "provision.tenantspace.config.chooseCcdis": "\u8BF7\u9009\u62E9\u4E00\u4E2ACCDIS\u670D\u52A1\u5668",
  "provision.callcent.error.updateccdis.associationvcallcener": "\u9009\u62E9\u7684CCDIS\u670D\u52A1\u5668\u5173\u8054\u79DF\u6237\uFF0C\u65E0\u6CD5\u66F4\u65B0",
  "provision.callcent.error.deleteccdis.associationvcallcener": "\u9009\u62E9\u7684CCDIS\u670D\u52A1\u5668\u5173\u8054\u79DF\u6237\uFF0C\u65E0\u6CD5\u5220\u9664",

  "provision.tenantspace.list.search.date.error": "\u79DF\u6237\u622A\u6B62\u65E5\u671F\u8F93\u5165\u65E0\u6548\uFF0C\u8BF7\u8F93\u5165\u6B63\u786E\u7684\u65E5\u671F\u8303\u56F4",
  "provision.target.selectDate": "\u9009\u62E9\u65E5\u671F",
  "provision.callcent.error.deleteinfo.from.cms.failed": "CMS\u5220\u9664\u76F8\u5173\u4FE1\u606F\u5931\u8D25",

  "provision.callcent.error.deleteinfo.from.cms.bad.input": "cms\u8F93\u5165\u6821\u9A8C\u5931\u8D25",
  "provision.callcent.error.deleteinfo.from.cms.unknown.error": "CMS\u51FA\u73B0\u672A\u77E5\u9519\u8BEF",
  "UEE.WEB.VALIDATOR.RANGE_LENGTH": "\u5B57\u7B26\u4E32\u957F\u5EA6\u8303\u56F4\uFF1A{0}-{1}",

  "provision.tenantspace.feature.ivr.analysis": "IVR\u5206\u6790",
  "provision.tenantspace.feature.ivr.analysis.description": "\u5F00\u542FIVR\u5206\u6790\u7684\u7279\u6027\uFF0C\u79DF\u6237\u652F\u6301IVR\u5206\u6790\u529F\u80FD\u3002",
  "provision.tenantspace.feature.customercenter": "\u5BA2\u6237\u4E2D\u5FC3",

  "provision.tenantspace.feature.customercenter.open.confirmmsg": "Appcube\u7279\u6027\u662F\u5BA2\u6237\u4E2D\u5FC3\u7279\u6027\u8FD0\u884C\u57FA\u7840\uFF0C\u5C06\u4F1A\u540C\u65F6\u5F00\u542F",
  "provision.tenantspace.common.customercenter.explain": "\u5F00\u542F\u5BA2\u6237\u4E2D\u5FC3\u7279\u6027\uFF0C\u63D0\u4F9B\u79DF\u6237\u7684\u672C\u5730\u5BA2\u6237\u7BA1\u7406\uFF0C\u53EF\u4EE5\u8BB0\u5F55\u540C\u4E00\u4E2A\u5BA2\u6237\u7684\u4E0D\u540C\u63A5\u89E6\u6E20\u9053\u5386\u53F2\u8BB0\u5F55\u4FE1\u606F",
  "provision.tenantspace.feature.appcube.close.confirmmsg": "\u5173\u95ED\u540EAppCube\u5185\u7684\u4E1A\u52A1\u6570\u636E\uFF08\u4F8B\u5982\u5DE5\u5355\u6570\u636E\uFF0C\u8054\u7CFB\u4EBA\u6570\u636E\u7B49\uFF09\u5C06\u88AB\u6E05\u9664\uFF0C\u6570\u636E\u65E0\u6CD5\u6062\u590D\uFF0C\u8BF7\u786E\u8BA4\u662F\u5426\u5173\u95ED",

  "provision.tenantspace.feature.mobileAppH5": "MobileApp\u0020H5\u7248\u672C",
  "provision.tenantspace.feature.mobileAppH5.open.confirmmsg": "\u0020AppCube\u7279\u6027\u662FMobileApp\u7279\u6027\u8FD0\u884C\u57FA\u7840\uFF0C\u5C06\u4F1A\u540C\u65F6\u5F00\u542F",
  "provision.tenantspace.common.mobileAppH5.explain": "\u5F00\u542FMobileApp\u0020H5\u7248\u7279\u6027\uFF0C\u79DF\u6237\u53EF\u4EE5\u5728appcube\u4E0A\u81EA\u52A8\u5B89\u88C5\u79FB\u52A8\u5EA7\u5E2D\u76F8\u5173\u529F\u80FD\uFF0C\u652F\u6301\u901A\u8FC7\u624B\u673A\u8BBF\u95EE",
  "provision.tenantspace.feature.customercenter.close.error ": " \u5173\u95ED\u5BA2\u6237\u4E2D\u5FC3\u7279\u6027\u524D\u8BF7\u786E\u4FDDCase\u00202.0\u7279\u6027\u5DF2\u5173\u95ED",

  "provision.tenantspace.common.whatsapp.cloudapi.explain": "\u5F00\u542F\u7279\u6027\,\u79DF\u6237\u53EF\u4EE5\u5728\u6E20\u9053\u914D\u7F6E\u901A\u8FC7\u76F4\u63A5\u5BF9\u63A5Whatsapp Cloud API\u8FDB\u884C\u6587\u672C\u6D88\u606F\u5904\u7406\,\u76EE\u524D\u53EA\u652F\u6301\u6F14\u793A\,\u4E0D\u80FD\u5546\u7528",
  "provision.tenantspace.feature.whatsapp.cloudapi": "Whatsapp Cloud API\u5BF9\u63A5",

  "provision.tenantspace.vdn.id": "VDN ID",
  "provision.tenantspace.config.error.148": "\u8BA4\u8BC1\u7CFB\u7EDF\u5DF2\u7ECF\u5173\u8054\u5230\u8054\u90A6\u7528\u6237\uFF0C\u65E0\u6CD5\u4FEE\u6539",

  "provision.log.config.agent.track.config.title": "座席跟踪配置",
  "provision.log.config.agent.track.config.refresh": "刷新",
  "provision.log.config.agent.track.config.tenantSpaceName": "租间名称",
  "provision.log.config.agent.track.config.workNo": "座席工号",
  "provision.log.config.agent.track.config.tenantSpaceName.error.hints": "仅允许数字字母下划线",
  "provision.log.config.agent.track.config.tenantSpaceName.length.error.hints": "请输入8至30位以字母开头仅包含数字字母下划线的租间名称",
  "provision.log.config.agent.track.config.logLevel": "日志级别",
  "provision.log.config.agent.track.config.createTime": "创建时间",
  "provision.log.config.agent.track.config.endTime": "跟踪截止时间",
  "provision.log.config.agent.track.config.operation": "操作",
  "provision.log.config.agent.track.config.delete": "删除",
  "provision.log.config.agent.track.config.batch.delete": "批量删除",
  "provision.log.config.agent.track.config.add": "新增",
  "provision.log.config.agent.track.config.batchDelete.hints.pleaseSelect": "请选择座席跟踪日志配置信息",
  "provision.log.config.agent.track.config.batchDelete.hints.exceed.max.operate.num": "单次删除的数量不能超过100条",
  "provision.log.config.agent.track.config.create.title": "创建座席跟踪配置",
  "provision.log.config.agent.track.config.create.confirm": "确认",
  "provision.log.config.agent.track.config.create.cancel": "取消",
  "provision.log.config.agent.track.config.create.endTime.placeholder": "请选择跟踪结束时间",
  "provision.log.config.agent.track.config.create.must": "必填项",
  "provision.log.config.agent.track.config.create.tenantSpaceName.min.length": "租间名称不能小于8位",
  "provision.log.config.agent.track.config.create.workNol.check.hints": "请输入101-50000之间的整数",
  "provision.log.config.agent.track.config.create.workNol.check.hints.1": "请输入50000以内的整数",
  "provision.log.config.agent.track.config.create.end.check.hints": "请选择当前至未来三天以内的时间点",
  "provision.log.config.agent.track.config.success": "成功",
  "provision.log.config.agent.track.config.failed": "失败",
  "provision.log.config.agent.track.config.delete.success": "删除成功",
  "provision.log.config.agent.track.config.delete.failed.11": "删除失败",
  "provision.log.config.agent.track.config.delete.failed.10": "删除操作参数错误",
  "provision.log.config.agent.track.config.create.top.hints": "开启对应租户座席日志跟踪后，座席端进行接续操作将产生的跟踪日志收集到CCManagement服务。此操作将影响系统性能，请根据实际跟踪需求开启。",
  "provision.log.config.agent.track.config.create.success": "创建成功",
  "provision.log.config.agent.track.config.create.failed": "创建失败",
  "provision.log.config.agent.track.config.create.failed.1": "租间名称长度不合法",
  "provision.log.config.agent.track.config.create.failed.2": "租间名称包含特殊字符",
  "provision.log.config.agent.track.config.create.failed.3": "租间名称检查失败",
  "provision.log.config.agent.track.config.create.failed.4": "工号检查失败",
  "provision.log.config.agent.track.config.create.failed.5": "日志级别检查失败",
  "provision.log.config.agent.track.config.create.failed.6": "跟踪结束时间检查失败",
  "provision.log.config.agent.track.config.create.failed.7": "超过最大可配置数",
  "provision.log.config.agent.track.config.create.failed.8": "已存在对该座席的日志跟踪配置",
  "provision.log.config.agent.track.config.create.failed.9": "创建失败",
  "provision.log.config.agent.track.config.max.length": "最大长度为",

  "provision.tenant.management.tenantname.contact.blocklist.check": "存在非法字符,不允许出现特殊字符: & < > \" ; % / \\ ^ [ ] = + @",
}
