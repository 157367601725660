import type {InternalRuleItem, SyncValidateResult, Value} from "async-validator/dist-types/interface";
// @ts-ignore
import i18n from "@/lang/index.js";
import _ from "lodash";

/** 压缩密码需要匹配的特殊字符正则校验规则 */
const zipSpecialCharMarchReg: Readonly<RegExp> = /[_@%]/

/** 压缩密码需要匹配的特殊字符正则校验规则, 仅用于导出 */
const zipExportSpecialCharMarchReg: Readonly<RegExp> = /[@#$%^&*+|=~!?,.:;\-_'"(){}\[\]/><]/;

/** 字母校验正则 */
const alphabetMarchReg: Readonly<RegExp> = /[a-zA-Z]/

/** 数字校验正则 */
const numberMarchReg: Readonly<RegExp> = /\d/

/** 特殊字符校验 */
const specialCharTReg: Readonly<RegExp> = /[^a-zA-Z0-9_@%]/

const wasSpecialCharTReg: Readonly<RegExp> = /[\[<>"()\]&=;,]/

/** 特殊字符校验 */
const specialStrTReg: Readonly<RegExp> = /[`~!@#$%^&*()+=|{}'":;/?.\[\]！￥…（）—【】‘；：”“’,、？]/

/** 特殊字符校验-转储 */
const specialResourceStrTReg: Readonly<RegExp> = /[`~!@#$%^&*()+=|{}":;<>/?.\[\]！￥…（）—【】‘；：”“’,、？]/

/** url地址校验 */
const urlStrReg: Readonly<RegExp> = /^[a-zA-Z0-9-=.:/]*$/

/** ip 地址范围 */
const ipv4Range: Readonly<RegExp> = /(^((22[0-3]|2[0-1][0-9]|[0-1][0-9][0-9]|([0-9]){1,2})([.](25[0-5]|2[0-4][0-9]|[0-1][0-9][0-9]|([0-9]){1,2})){3})$)/

const ipv6Range: Readonly<RegExp> = /(^((([0-9A-Fa-f]{1,4}:){7}(([0-9A-Fa-f]{1,4}){1}|:))|(([0-9A-Fa-f]{1,4}:){6}((:[0-9A-Fa-f]{1,4}){1}|((22[0-3]|2[0-1][0-9]|[0-1][0-9][0-9]|([0-9]){1,2})([.](25[0-5]|2[0-4][0-9]|[0-1][0-9][0-9]|([0-9]){1,2})){3})|:))|(([0-9A-Fa-f]{1,4}:){5}((:[0-9A-Fa-f]{1,4}){1,2}|:((22[0-3]|2[0-1][0-9]|[0-1][0-9][0-9]|([0-9]){1,2})([.](25[0-5]|2[0-4][0-9]|[0-1][0-9][0-9]|([0-9]){1,2})){3})|:))|(([0-9A-Fa-f]{1,4}:){4}((:[0-9A-Fa-f]{1,4}){1,3}|:((22[0-3]|2[0-1][0-9]|[0-1][0-9][0-9]|([0-9]){1,2})([.](25[0-5]|2[0-4][0-9]|[0-1][0-9][0-9]|([0-9]){1,2})){3})|:))|(([0-9A-Fa-f]{1,4}:){3}((:[0-9A-Fa-f]{1,4}){1,4}|:((22[0-3]|2[0-1][0-9]|[0-1][0-9][0-9]|([0-9]){1,2})([.](25[0-5]|2[0-4][0-9]|[0-1][0-9][0-9]|([0-9]){1,2})){3})|:))|(([0-9A-Fa-f]{1,4}:){2}((:[0-9A-Fa-f]{1,4}){1,5}|:((22[0-3]|2[0-1][0-9]|[0-1][0-9][0-9]|([0-9]){1,2})([.](25[0-5]|2[0-4][0-9]|[0-1][0-9][0-9]|([0-9]){1,2})){3})|:))|(([0-9A-Fa-f]{1,4}:){1}((:[0-9A-Fa-f]{1,4}){1,6}|:((22[0-3]|2[0-1][0-9]|[0-1][0-9][0-9]|([0-9]){1,2})([.](25[0-5]|2[0-4][0-9]|[0-1][0-9][0-9]|([0-9]){1,2})){3})|:))|(:((:[0-9A-Fa-f]{1,4}){1,7}|(:[fF]{4}){0,1}:((22[0-3]|2[0-1][0-9]|[0-1][0-9][0-9]|([0-9]){1,2})([.](25[0-5]|2[0-4][0-9]|[0-1][0-9][0-9]|([0-9]){1,2})){3})|:)))$)/


/** 特殊字符校验（不含单引号） */
const specialCharNoSingleQuotes: Readonly<RegExp> = /[`~!@￥^#$%&*()\-/=+{};:",，！；‘’“”：？<.>?\[\]]/

/** 正整数校验 */
const positiveIntegerReg: Readonly<RegExp> = /^[1-9]+[0-9]*$/

/** 邮件地址校验，大小写不敏感 */
const emailReg: Readonly<RegExp> = /^[a-zA-Z0-9_-ÁáÉéxÍÍíÓóÚúÂâÊêÔôÀàÃãÕõÜüÇç@\"\s!#$%&'*+-/=?^_`{|}~]+$/

/**
 * 压缩密码校验规则，用于表单校验
 * @description 该方法的特殊字符校验规则集，仅包含_、@和%
 * 若想支持更多的特殊字符，请使用{@link validZipPwdExtendedRule}
 * @param rule 校验规则条目
 * @param value 需要校验的值，即压缩密码内容
 * @param callback 回调执行函数
 * @param minLength 最小密码长度，默认为8
 * @param maxLength 最长密码长度，默认为12
 * @return {SyncValidateResult|void}
 */
export function validZipPwdRule(rule: InternalRuleItem, value: Value, callback: (error?: string | Error) => void, minLength: number = 8, maxLength: number = 12): SyncValidateResult | void {
  const param: ZipPwdCheckParam = {
    rule,
    value,
    allowedReg: {alphabetReg: alphabetMarchReg, numberReg: numberMarchReg, specialReg: zipSpecialCharMarchReg,},
    allowedRegMsg: {allCharRegMsg: i18n.global.t('ccm.custdata.operlog.passValidateFailed')},
    minLength,
    maxLength
  }
  return zipPwdCheckRule(param, callback)
}

/**
 * 压缩密码校验规则，用于导出文件时使用。支持更多的特殊字符
 * @description 该方法相较于 {@link validZipPwdRule}, 其特殊字符校验规则做了拓展，支持全部的半角字符
 * @param rule 校验规则条目
 * @param value 需要校验的值，即压缩密码内容
 * @param callback 回调执行函数
 * @param minLength 最小密码长度，默认为8
 * @param maxLength 最长密码长度，默认为12
 * @return {SyncValidateResult|void}
 */
export function validZipPwdExtendedRule(rule: InternalRuleItem, value: Value, callback: (error?: string | Error) => void, minLength: number = 8, maxLength: number = 12): SyncValidateResult | void {
  const param: ZipPwdCheckParam = {
    rule,
    value,
    allowedReg: {alphabetReg: alphabetMarchReg, numberReg: numberMarchReg, specialReg: zipExportSpecialCharMarchReg,},
    allowedRegMsg: {allCharRegMsg: i18n.global.t('ccm.export.password.error.message')},
    minLength,
    maxLength
  }
  return zipPwdCheckRule(param, callback)
}

/** 允许的字符校验规则 */
interface AllowedReg {

  /** 字母字符校验正则 */
  readonly alphabetReg: RegExp,

  /** 数字字符校验正则 */
  readonly numberReg: RegExp,

  /** 特殊字符校验正则 */
  readonly specialReg: RegExp,
}

/** 检验失败时，错误提示信息 */
interface AllowedRegMsg {

  /** 字母字符校验，不符合时返回的错误信息，必须为其国际化后的内容 */
  readonly alphabetRegMsg?: string,

  /** 数字字符校验，不符合时返回的错误信息，必须为其国际化后的内容 */
  readonly numberRegMsg?: string,

  /** 特殊字符校验，不符合时返回的错误信息，必须为其国际化后的内容 */
  readonly specialRegMsg?: string,

  /** 全字符校验，不符合时返回的错误信息，必须为其国际化后的内容 */
  readonly allCharRegMsg: string
}

/** 压缩密码校验参数 */
interface ZipPwdCheckParam {
  rule: InternalRuleItem,
  value: Value,

  /** 允许的字符校验规则 */
  readonly allowedReg: AllowedReg

  /** 检验失败时，错误提示信息 */
  readonly allowedRegMsg: AllowedRegMsg

  /** 最短文本长度 */
  minLength?: number,

  /** 最长文本长度  */
  maxLength?: number
}

/**
 * 压缩密码校验规则
 * @param {ZipPwdCheckParam} param 压缩密码校验参数
 * @param {(error?: (string | Error)) => void} callback 回调执行函数
 */
function zipPwdCheckRule(param: ZipPwdCheckParam, callback: (error?: string | Error) => void): SyncValidateResult | void {
  if (_.isNil(param?.value) || (param.value === '')) {
    return callback(new Error(i18n.global.t('SM.LOGIN.TIPS.REQUIRED')))
  }
  const {minLength = 8, maxLength = 12, value} = param
  if (value.length < minLength) {
    return callback(new Error(i18n.global.t('ccm.password.length.limit.min', {min: minLength, max: maxLength})))
  }
  if (value.length > maxLength) {
    return callback(new Error(i18n.global.t('ccm.password.length.limit.max', {min: minLength, max: maxLength})))
  }
  const {alphabetReg, numberReg, specialReg} = param.allowedReg
  const {allCharRegMsg} = param.allowedRegMsg
  const regText = splitRegRegContent(alphabetReg) + splitRegRegContent(numberReg) + splitRegRegContent(specialReg)
  const totalReg = new RegExp(`^[${regText}]+$`)
  if (!totalReg.test(value)) {
    return callback(new Error(allCharRegMsg));
  }
  let complex = 0
  if (alphabetReg.test(value)) {
    ++complex
  }
  if (numberReg.test(value)) {
    ++complex
  }
  if (specialReg.test(value)) {
    ++complex
  }
  return complex < 3
    ? callback(new Error(allCharRegMsg))
    : callback()
}

const splitRegRegContent = (reg: RegExp): string => {
  const source = reg.source
  const start = source.startsWith("[") ? 1 : 0
  const end = source.endsWith("]") ? source.length - 1 : void 0
  return source.slice(start, end)
}

/**
 * 特殊字符校验，放开单引号
 *
 * @param rule 校验规则条目
 * @param value 需要校验的值，即压缩密码内容
 * @param callback 回调执行函数
 */
export function validSpecialCharNoSingleQuotes(rule: InternalRuleItem, value: Value, callback: (error?: string | Error) => void): SyncValidateResult | void {
  if (specialCharNoSingleQuotes.test(value)) {
    return callback(new Error(i18n.global.t('ccm.agent.message.pageNameValidateFail')));
  } else {
    return callback();
  }
}

/**
 * 特殊字符校验
 *
 * @param rule 校验规则条目
 * @param value 需要校验的值，即压缩密码内容
 * @param callback 回调执行函数
 */
export function validSpecialStr(rule: InternalRuleItem, value: Value, callback: (error?: string | Error) => void): SyncValidateResult | void {
  if (value && specialStrTReg.test(value)) {
    return callback(new Error(i18n.global.t('ccm.agent.message.pageNameValidateFail')));
  }
  return callback();
}

/**
 * 特殊字符校验 - 转储
 *
 * @param rule 校验规则条目
 * @param value 需要校验的值，即压缩密码内容
 * @param callback 回调执行函数
 */
export function validResourceSpecialStr(rule: InternalRuleItem, value: Value, callback: (error?: string | Error) => void): SyncValidateResult | void {
  if (value && specialResourceStrTReg.test(value)) {
    return callback(new Error(i18n.global.t('ccm.agent.message.pageNameValidateFail')));
  }
  return callback();
}

/**
 * url地址校验
 *
 * @param rule 校验规则条目
 * @param value 需要校验的值，即压缩密码内容
 * @param callback 回调执行函数
 */
export function validUrlStr(rule: InternalRuleItem, value: Value, callback: (error?: string | Error) => void): SyncValidateResult | void {
  if (value && !urlStrReg.test(value)) {
    return callback(new Error(i18n.global.t('ccm.agent.message.pageNameValidateFail')));
  }
  return callback();
}

/**
 * was特殊字符校验
 *
 * @param rule 校验规则条目
 * @param value 需要校验的值，即压缩密码内容
 * @param callback 回调执行函数
 */
export function validWasSpecialStr(rule: InternalRuleItem, value: Value, callback: (error?: string | Error) => void): SyncValidateResult | void {
  if (wasSpecialCharTReg.test(value)) {
    return callback(new Error(i18n.global.t('ccm.skillgroup.message.nameValidateFail')));
  }
  return callback();
}

/**
 * 校验ip地址
 *
 * @param rule 校验规则条目
 * @param value 需要校验的值，即压缩密码内容
 * @param callback 回调执行函数
 */
export function validIp(rule: InternalRuleItem, value: Value, callback: (error?: string | Error) => void): SyncValidateResult | void {
  if (value && !ipv4Range.test(value) && !ipv6Range.test(value)) {
    return callback(new Error(i18n.global.t('SM.ORGSTAFF.MESSAGE.REGION_VALIDATE_IPFORMATERROR')));
  }
  return callback();
}

/**
 * 校验端口
 *
 * @param rule 校验规则条目
 * @param value 需要校验的值，即压缩密码内容
 * @param callback 回调执行函数
 */
export function validPort(rule: InternalRuleItem, value: Value, callback: (error?: string | Error) => void): SyncValidateResult | void {
  if (!value) {
    return callback();
  }
  if (!positiveIntegerReg.test(value)) {
    return callback(new Error(i18n.global.t('ccm.validate.portLengthError')));
  }
  const tempItem = parseInt(value);
  if (tempItem < 1 || tempItem > 65535) {
    return callback(new Error(i18n.global.t('ccm.validate.portLengthError')));
  }
  return callback();
}

/**
 * 校验密码长度
 *
 * @param rule 校验规则条目
 * @param value 需要校验的值，即压缩密码内容
 * @param callback 回调执行函数
 */
export function validPasswordLength(rule: InternalRuleItem, value: Value, callback: (error?: string | Error) => void): SyncValidateResult | void {
  if (!value?.trim()) {
    return callback();
  }
  if (value.length < 8 || value.length > 20) {
    return callback(new Error(i18n.global.t('provision.callcenterinstanceslist.vcallcentersftp.passLengthValidateFailed')));
  }
  return callback()
}

/**
 * 校验正整数
 *
 * @param rule 校验规则条目
 * @param value 需要校验的值，即压缩密码内容
 * @param callback 回调执行函数
 */
export function validPositiveInteger(rule: InternalRuleItem, value: Value, callback: (error?: string | Error) => void): SyncValidateResult | void {
  if (value && !positiveIntegerReg.test(value)) {
    return callback(new Error(i18n.global.t('ccm.agent.sysparamconfig.positiveinteger')))
  } else {
    return callback()
  }
}

/**
 * 正则校验电子邮件
 * @param value 单个电子邮件地址
 * @return {boolean} true - 有效邮件地址；false - 无效电子邮件
 */
export function checkEmail(value: string): boolean {
  return emailReg.test(value)
}

/**
 * 校验电子邮件地址
 * @description 用于校验电子邮件地址是否合规，支持多个邮件地址的校验 <br/>
 * 此方法不要直接对应到表单规则的validator，校验器的第四个参数是表单数据，不是在此处定义的分隔符
 * @param rule 校验规则配置
 * @param value 需要校验的电子邮件，多个邮件地址用 {@link separator} 定义的分隔符隔开
 * @param callback 回调执行函数
 * @param separator 多个邮件地址时的分隔符。默认为半角逗号(,)
 */
export function validEmail(rule: InternalRuleItem, value: String, callback: (error?: string | Error) => void, separator: string = ',') {
  //初次检查为空，直接返回
  if (!value?.trim()) {
    return
  }
  const emailArray = reformatEmails(value, separator).split(separator)
  for (const email of emailArray) {
    // 去除首尾空格后校验
    if (!checkEmail(email.trim())) {
      return callback(new Error(i18n.global.t('SM.ORGSTAFF.LABEL.EMPLOYEE_EMAIL_VALIDATOR')));
    }
  }
  return callback()
}

/**
 * 重新格式化邮件
 * @description 移除邮件前后位空格，若最后一位是分隔符，则去掉分隔符
 * @param sourceEmail 需要格式化的邮件地址
 * @param separator 分隔符
 */
export function reformatEmails(sourceEmail: String, separator: string) {
  const trimmed = sourceEmail.trim()
  return trimmed.endsWith(separator) ? trimmed.substring(0, trimmed.length - separator.length) : trimmed
}