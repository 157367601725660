import i18n from '@/lang/index'

const $t = i18n.global.t

export const ALL_REASON_CODE_MAP = {
    "00000": $t('isales.taskresult.field.success'),
    "00003": $t('isales.taskresult.field.failsNoresource'),
    "00004": $t('isales.taskresult.field.failsBeingchanged'),
    "00005": $t('isales.taskresult.field.failsCallwaiting'),
    "00006": $t('isales.taskresult.field.failsTransferonbusy'),
    "00007": $t('isales.taskresult.field.failsTransfernoreply'),
    "00008": $t('isales.taskresult.field.failsTransferunconditionally'),
    "00009": $t('isales.taskresult.field.failsDnd'),
    "00010": $t('isales.taskresult.field.failsTransferbeingalerted'),
    "00011": $t('isales.taskresult.field.failsWithoutringing'),
    "00012": $t('isales.taskresult.field.failsUnreachable'),
    "00013": $t('isales.taskresult.field.failsInvalidstatus'),
    "00014": $t('isales.taskresult.field.failsNoreason'),
    "00015": $t('isales.taskresult.field.failsCpp'),
    "00018": $t('isales.taskresult.field.failsPlayingvoices'),
    "00129": $t('isales.taskresult.field.failsUnallocatednumber'),
    "00132": $t('isales.taskresult.field.failsSsit'),
    "00145": $t('isales.taskresult.field.failsSubscriberbusy'),
    "00146": $t('isales.taskresult.field.failsNoresponse'),

    "00147":
        $t('isales.taskresult.field.failsNoanswer')
    ,

    "00149":
        $t('isales.taskresult.field.failsUserrejection')
    ,

    "00150":
        $t('isales.taskresult.field.failsNumberchange')
    ,

    "00155":
        $t('isales.taskresult.field.failsDestinationunreachable')
    ,

    "00160":
        $t('isales.taskresult.field.failsMusicdetected')
    ,

    "00162":
        $t('isales.taskresult.field.failsAutomaticdevicedetected')
    ,

    "00180":
        $t('isales.taskresult.field.failsRingbacktone')
    ,

    "00181":
        $t('isales.taskresult.field.failsBusytone')
    ,

    "00182":
        $t('isales.taskresult.field.failsSittone')
    ,

    "00184":
        $t('isales.taskresult.field.failsModemdetected')
    ,

    "00185":
        $t('isales.taskresult.field.failsFaxdetected')
    ,

    "00186":
        $t('isales.taskresult.field.failsModemorfax')
    ,

    "00187":
        $t('isales.taskresult.field.failsUndefinedsignaltone')
    ,

    "00190":
        $t('isales.taskresult.field.failsAns1')
    ,

    "00191":
        $t('isales.taskresult.field.failsAns2')
    ,

    "00192":
        $t('isales.taskresult.field.failsAns3')
    ,

    "00193":
        $t('isales.taskresult.field.failsAns4')
    ,

    "00194":
        $t('isales.taskresult.field.failsAns5')
    ,

    "00195":
        $t('isales.taskresult.field.failsEdt')
    ,

    "00196":
        $t('isales.taskresult.field.failsFsk')
    ,

    "00197":
        $t('isales.taskresult.field.failsDtmf')
    ,

    "00259":
        $t('isales.taskresult.field.failsInsufficientresources')
    ,

    "00260":
        $t('isales.taskresult.field.failsCalledmodem')
    ,

    "00261":
        $t('isales.taskresult.field.failsFak')
    ,

    "00262":
        $t('isales.taskresult.field.failsFaxormodem')
    ,

    "00263":
        $t('isales.taskresult.field.failsAutomaticansweringmachine')
    ,

    "00531":
        $t('isales.taskresult.field.failsSubscriberhangsup')
    ,

    "00537":
        $t('isales.taskresult.field.failsCalledpartybusy')
    ,

    "00538":
        $t('isales.taskresult.field.failsEmptyorwrong')
    ,

    "00548":
        $t('isales.taskresult.field.failsPoweredoff')
    ,

    "00549":
        $t('isales.taskresult.field.failsNotintheservicearea')
    ,

    "00551":
        $t('isales.taskresult.field.failsDisconnection')
    ,

    "00601":
        $t('isales.taskresult.field.failsHangsupbeforeanswering')
    ,

    "00602":
        $t('isales.taskresult.field.failsNoacdresource')
    ,

    "00603":
        $t('isales.taskresult.field.failsNoreply')
    ,

    "00604":
        $t('isales.taskresult.field.failsCallrejected')
    ,

    "00605":
        $t('isales.taskresult.field.failsSwitchingdevicebusy')
    ,

    "00606":
        $t('isales.taskresult.field.failsAcdtimeout')
    ,

    "00701":
        $t('isales.taskresult.field.failsUserhangsupinthequeue')
    ,

    "00702":
        $t('isales.taskresult.field.failsQueuingtimesout')
    ,

    "00703":
        $t('isales.taskresult.field.failsCalldatatimeout')
    ,

    "01040":
        $t('isales.taskresult.field.failsRingingtimesout')
    ,

    "01041":
        $t('isales.taskresult.field.failsAgentdevicetimesout')
    ,

    "01043":
        $t('isales.taskresult.field.failsWaitresulttimesout')
    ,

    "01046":
        $t('isales.taskresult.field.failedtooccupytheagent')
    ,

    "01048":
        $t('isales.taskresult.field.failsNumberreacheslimit')
    ,

    "01049":
        $t('isales.taskresult.field.failsResulttimesoutafterpicksup')
    ,

    "01056":
        $t('isales.taskresult.field.failsAgentfailswithuser')
    ,

    "01281":
        $t('isales.taskresult.field.failsReleasesbeforecall')
    ,

    "01288":
        $t('isales.taskresult.field.failsReleaseswhencall')
    ,

    "01333":
        $t('isales.taskresult.field.failsHangsupwhencall')
    ,

    "23000":
        $t('isales.taskresult.field.failsCallqueueoverflow')
    ,

    "34601":
        $t('isales.taskresult.field.failsTasknotexist')
    ,

    "34602":
        $t('isales.taskresult.field.failedtopause')
    ,

    "34603":
        $t('isales.taskresult.field.failedtoresume')
    ,

    "34605":
        $t('isales.taskresult.field.failsPhonenumberisnull')
    ,

    "34606":
        $t('isales.taskresult.field.failsCallattemptfails')
    ,

    "34607":
        $t('isales.taskresult.field.failsRecordswrittenback')
    ,

    "34608":
        $t('isales.taskresult.field.failsResultnotobtained')
    ,

    "34609":
        $t('isales.taskresult.field.failsNoagentavailable')
    ,

    "34610":
        $t('isales.taskresult.field.failsRecordcanceled')
    ,

    "34611":
        $t('isales.taskresult.field.failsObsexits')
    ,

    "35027":
        $t('isales.taskresult.field.failsCallednumberincorrect')
    ,

    "99999":
        $t('isales.taskresult.field.failsCallednumbernotinwhitelist')
    ,

    "00714":
        $t('isales.taskresult.field.failsCallRouting')
    ,

    "00715":
        $t('isales.taskresult.field.failsCallTimeout')
    ,

    "01138":
        $t('isales.taskresult.field.failsCalledOnHook')
    ,

    "01153":
        $t('isales.taskresult.field.failsAgentHangsUp')
    ,

    "01154":
        $t('isales.taskresult.field.failsAgentCancelingCall')
    ,

    "01155":
        $t('isales.taskresult.field.failsTimeout')
    ,

    "01156":
        $t('isales.taskresult.field.failsAgentDisconnection')
    ,

    "01162":
        $t('isales.taskresult.field.failsResourceOverload')
    ,

    "01163":
        $t('isales.taskresult.field.failsUnallocatedOrIncorrectNumber')
    ,

    "01164":
        $t('isales.taskresult.field.failsCalledpartybusy')
    ,

    "01165":
        $t('isales.taskresult.field.failsPoweredoff')
    ,

    "01166":
        $t('isales.taskresult.field.failsNotintheservicearea')
    ,

    "01167":
        $t('isales.taskresult.field.failsInsufficientresources')
    ,

    "01168":
        $t('isales.taskresult.field.failsNoresponse')
    ,

    "01169":
        $t('isales.taskresult.field.failsUserrejection')
    ,

    "01170":
        $t('isales.taskresult.field.failsSwitchingdevicebusy')
    ,

    "01171":
        $t('isales.taskresult.field.failsTimeout')
    ,

    "20301":
        $t('isales.taskresult.field.failsInvalidUser')
    ,

    "20302":
        $t('isales.taskresult.field.failsInvalidDevice')
    ,

    "20303":
        $t('isales.taskresult.field.failsNoAnsweringDeviceAvailable')
    ,

    "20304":
        $t('isales.taskresult.field.failsAbnormal')
    ,

    "20305":
        $t('isales.taskresult.field.failsInvalidRoutingDevice')
    ,

    "20306":
        $t('isales.taskresult.field.failsEmptyorwrong')
    ,

    "20307":
        $t('isales.taskresult.field.failsCalledpartybusy')
    ,

    "20308":
        $t('isales.taskresult.field.failsSwitchRestart')
    ,

    "20309":
        $t('isales.taskresult.field.failsNoresponse')
    ,

    "20310":
        $t('isales.taskresult.field.failsUnknownError')
    ,

    "20920":
        $t('isales.taskresult.field.failsCallResourcesInsufficient')
    ,

    "20921":
        $t('isales.taskresult.field.failsNoValidVDN')
    ,

    "20922":
        $t('isales.taskresult.field.failsCallFailure')
    ,

    "20923":
        $t('isales.taskresult.field.failsFailedRouteAgent')
    ,

    "20924":
        $t('isales.taskresult.field.failsInvalidSkillQueue')
    ,

    "20925":
        $t('isales.taskresult.field.failsFailedRouteSkillQueue')
    ,

    "20926":
        $t('isales.taskresult.field.failsFailedRouteCallToAttendant')
    ,

    "01119":
        $t('isales.taskresult.field.failsFailedAgentIsBusy')
    ,

    "00857":
        $t('isales.taskresult.field.userisunleashed')
    ,

    "01103":
        $t('isales.taskresult.field.invalidCall')
}

export const ALL_REASON_CODE_LIST = [
    {
        "value": "00000",
        "label": $t('isales.taskresult.field.success')
    },
    {
        "value": "00003",
        "label": $t('isales.taskresult.field.failsNoresource')
    },
    {
        "value": "00004",
        "label": $t('isales.taskresult.field.failsBeingchanged')
    },
    {
        "value": "00005",
        "label": $t('isales.taskresult.field.failsCallwaiting')
    },
    {
        "value": "00006",
        "label": $t('isales.taskresult.field.failsTransferonbusy')
    },
    {
        "value": "00007",
        "label": $t('isales.taskresult.field.failsTransfernoreply')
    },
    {
        "value": "00008",
        "label": $t('isales.taskresult.field.failsTransferunconditionally')
    },
    {
        "value": "00009",
        "label": $t('isales.taskresult.field.failsDnd')
    },
    {
        "value": "00010",
        "label": $t('isales.taskresult.field.failsTransferbeingalerted')
    },
    {
        "value": "00011",
        "label": $t('isales.taskresult.field.failsWithoutringing')
    },
    {
        "value": "00012",
        "label": $t('isales.taskresult.field.failsUnreachable')
    },
    {
        "value": "00013",
        "label": $t('isales.taskresult.field.failsInvalidstatus')
    },
    {
        "value": "00014",
        "label": $t('isales.taskresult.field.failsNoreason')
    },
    {
        "value": "00015",
        "label": $t('isales.taskresult.field.failsCpp')
    },
    {
        "value": "00018",
        "label": $t('isales.taskresult.field.failsPlayingvoices')
    },
    {
        "value": "00129",
        "label": $t('isales.taskresult.field.failsUnallocatednumber')
    },
    {
        "value": "00132",
        "label": $t('isales.taskresult.field.failsSsit')
    },
    {
        "value": "00145",
        "label": $t('isales.taskresult.field.failsSubscriberbusy')
    },
    {
        "value": "00146",
        "label": $t('isales.taskresult.field.failsNoresponse')
    },
    {
        "value": "00147",
        "label": $t('isales.taskresult.field.failsNoanswer')
    },
    {
        "value": "00149",
        "label": $t('isales.taskresult.field.failsUserrejection')
    },
    {
        "value": "00150",
        "label": $t('isales.taskresult.field.failsNumberchange')
    },
    {
        "value": "00155",
        "label": $t('isales.taskresult.field.failsDestinationunreachable')
    },
    {
        "value": "00160",
        "label": $t('isales.taskresult.field.failsMusicdetected')
    },
    {
        "value": "00162",
        "label": $t('isales.taskresult.field.failsAutomaticdevicedetected')
    },
    {
        "value": "00180",
        "label": $t('isales.taskresult.field.failsRingbacktone')
    },
    {
        "value": "00181",
        "label": $t('isales.taskresult.field.failsBusytone')
    },
    {
        "value": "00182",
        "label": $t('isales.taskresult.field.failsSittone')
    },
    {
        "value": "00184",
        "label": $t('isales.taskresult.field.failsModemdetected')
    },
    {
        "value": "00185",
        "label": $t('isales.taskresult.field.failsFaxdetected')
    },
    {
        "value": "00186",
        "label": $t('isales.taskresult.field.failsModemorfax')
    },
    {
        "value": "00187",
        "label": $t('isales.taskresult.field.failsUndefinedsignaltone')
    },
    {
        "value": "00190",
        "label": $t('isales.taskresult.field.failsAns1')
    },
    {
        "value": "00191",
        "label": $t('isales.taskresult.field.failsAns2')
    },
    {
        "value": "00192",
        "label": $t('isales.taskresult.field.failsAns3')
    },
    {
        "value": "00193",
        "label": $t('isales.taskresult.field.failsAns4')
    },
    {
        "value": "00194",
        "label": $t('isales.taskresult.field.failsAns5')
    },
    {
        "value": "00195",
        "label": $t('isales.taskresult.field.failsEdt')
    },
    {
        "value": "00196",
        "label": $t('isales.taskresult.field.failsFsk')
    },
    {
        "value": "00197",
        "label": $t('isales.taskresult.field.failsDtmf')
    },
    {
        "value": "00259",
        "label": $t('isales.taskresult.field.failsInsufficientresources')
    },
    {
        "value": "00260",
        "label": $t('isales.taskresult.field.failsCalledmodem')
    },
    {
        "value": "00261",
        "label": $t('isales.taskresult.field.failsFak')
    },
    {
        "value": "00262",
        "label": $t('isales.taskresult.field.failsFaxormodem')
    },
    {
        "value": "00263",
        "label": $t('isales.taskresult.field.failsAutomaticansweringmachine')
    },
    {
        "value": "00531",
        "label": $t('isales.taskresult.field.failsSubscriberhangsup')
    },
    {
        "value": "00537",
        "label": $t('isales.taskresult.field.failsCalledpartybusy')
    },
    {
        "value": "00538",
        "label": $t('isales.taskresult.field.failsEmptyorwrong')
    },
    {
        "value": "00548",
        "label": $t('isales.taskresult.field.failsPoweredoff')
    },
    {
        "value": "00549",
        "label": $t('isales.taskresult.field.failsNotintheservicearea')
    },
    {
        "value": "00551",
        "label": $t('isales.taskresult.field.failsDisconnection')
    },
    {
        "value": "00601",
        "label": $t('isales.taskresult.field.failsHangsupbeforeanswering')
    },
    {
        "value": "00602",
        "label": $t('isales.taskresult.field.failsNoacdresource')
    },
    {
        "value": "00603",
        "label": $t('isales.taskresult.field.failsNoreply')
    },
    {
        "value": "00604",
        "label": $t('isales.taskresult.field.failsCallrejected')
    },
    {
        "value": "00605",
        "label": $t('isales.taskresult.field.failsSwitchingdevicebusy')
    },
    {
        "value": "00606",
        "label": $t('isales.taskresult.field.failsAcdtimeout')
    },
    {
        "value": "00701",
        "label": $t('isales.taskresult.field.failsUserhangsupinthequeue')
    },
    {
        "value": "00702",
        "label": $t('isales.taskresult.field.failsQueuingtimesout')
    },
    {
        "value": "00703",
        "label": $t('isales.taskresult.field.failsCalldatatimeout')
    },
    {
        "value": "01040",
        "label": $t('isales.taskresult.field.failsRingingtimesout')
    },
    {
        "value": "01041",
        "label": $t('isales.taskresult.field.failsAgentdevicetimesout')
    },
    {
        "value": "01043",
        "label": $t('isales.taskresult.field.failsWaitresulttimesout')
    },
    {
        "value": "01046",
        "label": $t('isales.taskresult.field.failedtooccupytheagent')
    },
    {
        "value": "01048",
        "label": $t('isales.taskresult.field.failsNumberreacheslimit')
    },
    {
        "value": "01049",
        "label": $t('isales.taskresult.field.failsResulttimesoutafterpicksup')
    },
    {
        "value": "01056",
        "label": $t('isales.taskresult.field.failsAgentfailswithuser')
    },
    {
        "value": "01281",
        "label": $t('isales.taskresult.field.failsReleasesbeforecall')
    },
    {
        "value": "01288",
        "label": $t('isales.taskresult.field.failsReleaseswhencall')
    },
    {
        "value": "01333",
        "label": $t('isales.taskresult.field.failsHangsupwhencall')
    },
    {
        "value": "23000",
        "label": $t('isales.taskresult.field.failsCallqueueoverflow')
    },
    {
        "value": "34601",
        "label": $t('isales.taskresult.field.failsTasknotexist')
    },
    {
        "value": "34602",
        "label": $t('isales.taskresult.field.failedtopause')
    },
    {
        "value": "34603",
        "label": $t('isales.taskresult.field.failedtoresume')
    },
    {
        "value": "34605",
        "label": $t('isales.taskresult.field.failsPhonenumberisnull')
    },
    {
        "value": "34606",
        "label": $t('isales.taskresult.field.failsCallattemptfails')
    },
    {
        "value": "34607",
        "label": $t('isales.taskresult.field.failsRecordswrittenback')
    },
    {
        "value": "34608",
        "label": $t('isales.taskresult.field.failsResultnotobtained')
    },
    {
        "value": "34609",
        "label": $t('isales.taskresult.field.failsNoagentavailable')
    },
    {
        "value": "34610",
        "label": $t('isales.taskresult.field.failsRecordcanceled')
    },
    {
        "value": "34611",
        "label": $t('isales.taskresult.field.failsObsexits')
    },
    {
        "value": "35027",
        "label": $t('isales.taskresult.field.failsCallednumberincorrect')
    },
    {
        "value": "99999",
        "label": $t('isales.taskresult.field.failsCallednumbernotinwhitelist')
    },
    {
        "value": "00714",
        "label": $t('isales.taskresult.field.failsCallRouting')
    },
    {
        "value": "00715",
        "label": $t('isales.taskresult.field.failsCallTimeout')
    },
    {
        "value": "01138",
        "label": $t('isales.taskresult.field.failsCalledOnHook')
    },
    {
        "value": "01153",
        "label": $t('isales.taskresult.field.failsAgentHangsUp')
    },
    {
        "value": "01154",
        "label": $t('isales.taskresult.field.failsAgentCancelingCall')
    },
    {
        "value": "01155",
        "label": $t('isales.taskresult.field.failsTimeout')
    },
    {
        "value": "01156",
        "label": $t('isales.taskresult.field.failsAgentDisconnection')
    },
    {
        "value": "01162",
        "label": $t('isales.taskresult.field.failsResourceOverload')
    },
    {
        "value": "01163",
        "label": $t('isales.taskresult.field.failsUnallocatedOrIncorrectNumber')
    },
    {
        "value": "01164",
        "label": $t('isales.taskresult.field.failsCalledpartybusy')
    },
    {
        "value": "01165",
        "label": $t('isales.taskresult.field.failsPoweredoff')
    },
    {
        "value": "01166",
        "label": $t('isales.taskresult.field.failsNotintheservicearea')
    },
    {
        "value": "01167",
        "label": $t('isales.taskresult.field.failsInsufficientresources')
    },
    {
        "value": "01168",
        "label": $t('isales.taskresult.field.failsNoresponse')
    },
    {
        "value": "01169",
        "label": $t('isales.taskresult.field.failsUserrejection')
    },
    {
        "value": "01170",
        "label": $t('isales.taskresult.field.failsSwitchingdevicebusy')
    },
    {
        "value": "01171",
        "label": $t('isales.taskresult.field.failsTimeout')
    },
    {
        "value": "20301",
        "label": $t('isales.taskresult.field.failsInvalidUser')
    },
    {
        "value": "20302",
        "label": $t('isales.taskresult.field.failsInvalidDevice')
    },
    {
        "value": "20303",
        "label": $t('isales.taskresult.field.failsNoAnsweringDeviceAvailable')
    },
    {
        "value": "20304",
        "label": $t('isales.taskresult.field.failsAbnormal')
    },
    {
        "value": "20305",
        "label": $t('isales.taskresult.field.failsInvalidRoutingDevice')
    },
    {
        "value": "20306",
        "label": $t('isales.taskresult.field.failsEmptyorwrong')
    },
    {
        "value": "20307",
        "label": $t('isales.taskresult.field.failsCalledpartybusy')
    },
    {
        "value": "20308",
        "label": $t('isales.taskresult.field.failsSwitchRestart')
    },
    {
        "value": "20309",
        "label": $t('isales.taskresult.field.failsNoresponse')
    },
    {
        "value": "20310",
        "label": $t('isales.taskresult.field.failsUnknownError')
    },
    {
        "value": "20920",
        "label": $t('isales.taskresult.field.failsCallResourcesInsufficient')
    },
    {
        "value": "20921",
        "label": $t('isales.taskresult.field.failsNoValidVDN')
    },
    {
        "value": "20922",
        "label": $t('isales.taskresult.field.failsCallFailure')
    },
    {
        "value": "20923",
        "label": $t('isales.taskresult.field.failsFailedRouteAgent')
    },
    {
        "value": "20924",
        "label": $t('isales.taskresult.field.failsInvalidSkillQueue')
    },
    {
        "value": "20925",
        "label": $t('isales.taskresult.field.failsFailedRouteSkillQueue')
    },
    {
        "value": "20926",
        "label": $t('isales.taskresult.field.failsFailedRouteCallToAttendant')
    },
    {
        "value": "01119",
        "label": $t('isales.taskresult.field.failsFailedAgentIsBusy')
    },
    {
        "value": "00857",
        "label": $t('isales.taskresult.field.userisunleashed')
    },
    {
        "value": "01103",
        "label": $t('isales.taskresult.field.invalidCall')
    }
];

// 对象深拷贝