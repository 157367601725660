<template>
    <div>
        <sweet-dialog v-model="dialogVisible" :title="$t('isales.taskinfo.title.chooseSmsTemplateQuery')" width="616" class="aicc-dialog-limit-height" :close-on-click-modal="false">
            <aicc-table
                    :tableData="smsList"
                    :tableColumns="tableColumns"
                    :tableTools="{ showPagination: true, columnConfig: false }"
                    :paginationConfig="paginationConfig"
                    @handlePagination="handlePagination"
                    ref='refAiccTable'
                    :height="430">
            </aicc-table>
            <template #footer>
      <span class="dialog-footer">
        <sweet-button @click="dialogVisible = false">{{ $t('isales.common.title.cancel') }}</sweet-button>
        <sweet-button type="primary" @click="confirm">{{ $t('isales.common.title.confirm') }}</sweet-button>
      </span>
            </template>
        </sweet-dialog>
    </div>
</template>

<script>
import {queryCmsEnvironment, querySmsListAndCount} from "@/views/isales/api/systemAutoCallTask.js";

    export default {
        name: "isalesSmsSelect",
        props: {
            show: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                smsList: [],
                tableColumns: [
                    {
                        type: 'radio',
                        radioKey: 'id', // 单选绑定的字段名
                        width: '50px',
                        'show-overflow-tooltip': false
                    },
                    {
                        label: this.$t('isales.taskinfo.title.smsTemplate'),
                        prop: 'smsName',
                    }
                ],
                paginationConfig: {
                    PageSize: 10, // 每页条数
                    total: 0, //总条数
                    layout: "total, prev, pager, next" // 分页组件布局配置
                },
                limit: 10,
                offset: 0,
                cmsEnvironment: 'aicc'
            }
        },
        computed: {
            dialogVisible: {
                get() {
                    return this.show
                },
                set(val) {
                    this.$emit('update:show', val)
                }
            },
        },
        methods: {
            async querySmsListAndCount() {
                if (this.cmsEnvironment=== 'cms') {
                    return;
                }
                let queryCondition = {};
                queryCondition['limit'] = this.limit;
                queryCondition['offset'] = this.offset;
                querySmsListAndCount(queryCondition).then(res => {
                    if (res) {
                        this.smsList = res.result;
                        this.paginationConfig.total = res.total;
                    }
                })
            },
            handlePagination(params) {
                this.limit = params.limit;
                this.offset = params.offset;
                this.querySmsListAndCount();
            },
            confirm() {
                this.dialogVisible = false
                this.$emit('isalesSmsSelectConfirm', this.$refs.refAiccTable.getRadioCurrentRow())
            }
        },
        async created() {
            let result = await queryCmsEnvironment()
            if(result && result.cmsEnvironment){
                this.cmsEnvironment = result.cmsEnvironment;
            }
            await this.querySmsListAndCount();
        },
    }
</script>

<style scoped>

</style>