<template>
  <el-tree-v2
    ref="org-tree"
    :data="sortedData"
    :node-key="aiccTreeNodeKey"
    :default-expanded-keys="keysExpandedMerged"
    :props="computedProps"
    :expand-on-click-node="false"
    :filter-method="filterMethod || filterNodeMethod"
    :item-size="itemSize"
  >
    <template v-for="(_, name) in $slots" v-slot:[name]="data">
      <slot :name="name" v-bind="data" />
    </template>
  </el-tree-v2>
</template>

<script>
export default {
  name: 'org-tree',
  props: {
    data: {
      type: Array,
      default() {
        return []
      }
    },
    sortBy: {
      type: String,
      default() {
        return 'asc'
      }
    },
    nodeKey: {
      type: String,
      default() {
        return ''
      }
    },
    defaultExpandedLevel: {
      type: Number,
      default() {
        return 1
      }
    },
    defaultExpandedKeys: {
      type: Array,
      default() {
        return undefined
      }
    },
    props: {
      type: Object,
      default() {
        return {
          children: 'children',
          label: 'label'
        }
      }
    },
    filterNodeMethod: {
      type: Object
    },
    filterMethod: {
      type: Object
    },
    itemSize: {
      type: Number,
      default: () => {
        return 32
      }
    }
  },
  data() {
    return {
      sortedData: [],
      keysToExpand: [],
      expandedLevel: 1,
      lastScrollTop: 0,
      scrollElement: null
    }
  },
  computed: {
    keysExpandedMerged() {
      if (
        (!this.defaultExpandedKeys || this.defaultExpandedKeys.length === 0) &&
        this.keysToExpand.length === 0
      ) {
        return undefined
      } else if (this.keysToExpand.length > 0) {
        return this.keysToExpand
      } else {
        return [...this.defaultExpandedKeys, ...this.keysToExpand]
      }
    },
    aiccTreeNodeKey() {
      if (this.nodeKey) {
        return this.nodeKey
      } else {
        return 'aiccTreeKey'
      }
    },
    computedProps() {
      if (!this.props.value) {
        return {
          ...this.props,
          value: this.nodeKey
        }
      } else {
        return this.props
      }
    }
  },
  watch: {
    data: {
      handler(newVal) {
        if (newVal) {
          try {
            newVal = JSON.parse(JSON.stringify(newVal))
          } catch (error) {
            console.log('parse tree data to json failed')
          }
          this.sortedData = this.traverseTreeData(newVal, 1)
          this.$nextTick(() => {
            setTimeout(() => {
              this.scrollElement = document.querySelector('.el-tree-virtual-list')
              if (this.scrollElement) {
                this.scrollElement.addEventListener('scroll', () => {
                  this.lastScrollTop = this.scrollElement.scrollTop
                })
              }
            }, 300)
          })
        }
      },
      immediate: true,
      deep: true
    },
    keysExpandedMerged: {
      handler(newVal) {
        if (newVal) {
          if (this.$refs['org-tree']) {
            this.$refs['org-tree'].setExpandedKeys(newVal)
          }
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.expandedLevel = 0 + this.defaultExpandedLevel
  },
  activated() {
    const element = document.querySelector('.el-tree-virtual-list')
    if (this.scrollElement && element && element.scrollTop === 0) {
      this.scrollElement.scrollTop = this.lastScrollTop
    }
  },
  methods: {
    // 对树进行遍历
    traverseTreeData(treeData, level) {
      this.sortTreeData(treeData)
      treeData.forEach((node, index) => {
        this.setAiccTreeExpandedKeys(node, level, index, treeData.length)
        if (node[this.props.children]) {
          this.traverseTreeData(node[this.props.children], level + 1)
        }
      })
      return treeData
    },
    // 对树同级进行排序
    sortTreeData(treeData) {
      let lessFlag
      let largerFlag
      if (this.sortBy === 'asc') {
        lessFlag = -1
        largerFlag = 1
      } else if (this.sortBy === 'desc') {
        lessFlag = 1
        largerFlag = -1
      } else {
        return
      }
      treeData.sort((a, b) => {
        if (a[this.props.label] < b[this.props.label]) {
          return lessFlag
        }
        if (a[this.props.label] > b[this.props.label]) {
          return largerFlag
        }
        return 0
      })
    },
    // 根据defaultExpandedLevel设置需要展开的项
    setAiccTreeExpandedKeys(node, level, index, curLevelCount) {
      if (this.expandedLevel < level) {
        // 当设置的默认展开层级已经比当前层级小了，不再追加当前层级的key
        return
      }
      if (this.defaultExpandedLevel === 1 && level === 1 && curLevelCount === 1) {
        // 设置的默认展开层级为1，且树的一级节点数量为1的情况下，使层级加一，展开到2级
        this.expandedLevel += 1
      }
      if (this.nodeKey) {
        this.keysToExpand.push(node[this.nodeKey])
      } else {
        const key = `aicc-tree-${level}-${index}`
        node['aiccTreeKey'] = key
        this.keysToExpand.push(key)
      }
    },
    filter(val) {
      return this.$refs['org-tree'].filter(val)
    },
    getCheckedNodes(leafOnly, includeHalfChecked) {
      return this.$refs['org-tree'].getCheckedNodes(leafOnly, includeHalfChecked)
    },
    getCheckedKeys(leafOnly) {
      return this.$refs['org-tree'].getCheckedKeys(leafOnly)
    },
    setCheckedKeys(keys, leafOnly) {
      return this.$refs['org-tree'].setCheckedKeys(keys, leafOnly)
    },
    setChecked(keyOrData, checked, deep) {
      return this.$refs['org-tree'].setChecked(keyOrData, checked, deep)
    },
    getHalfCheckedNodes() {
      return this.$refs['org-tree'].getHalfCheckedNodes()
    },
    getHalfCheckedKeys() {
      return this.$refs['org-tree'].getHalfCheckedKeys()
    },
    getCurrentKey() {
      return this.$refs['org-tree'].getCurrentKey()
    },
    getCurrentNode() {
      return this.$refs['org-tree'].getCurrentNode()
    },
    setCurrentKey(key) {
      this.$refs['org-tree'].setCurrentKey(key)
      this.$nextTick(() => {
        const div = this.$refs['org-tree'].$el.querySelector(`div[data-key="${key}"]`)
        if (div) {
          div.click()
        }
      })
    },
    getNode(data) {
      return this.$refs['org-tree'].getNode(data)
    }
  }
}
</script>
<style lang="less" scoped>
:deep(.el-virtual-scrollbar) {
  display: none !important;
}
</style>
