export default {
  "ccmessaging.chat.chat.closeDialog": "Êtes-vous sûr de fermer ?",
  "ccmessaging.chat.silentagent.config.add.error": "Échec de la création de la configuration de l'agent silencieux vocal",
  "ccmessaging.chat.silentagent.config.add.success": "La nouvelle configuration de l'agent silencieux vocal a réussi",
  "ccmessaging.chat.fiveGCard.add": "Nouvelle carte 5G",
  "ccmessaging.chat.fiveGCard.edit": "Modifier la carte 5G",
  "ccmessaging.chat.fiveGCarouselCard.add": "Ajout d'une carte carrousel 5G",
  "ccmessaging.chat.fiveGCarouselCard.edit": "Modifier une carte carrousel 5G",
  "ccmessaging.chat.webCard.add": "Ajouter une carte Web",
  "ccmessaging.chat.webCard.edit": "Modifier la carte Web",
  "ccmessaging.chat.coBrowseSite.add": "Ajouter une adresse de collaboration web",
  "ccmessaging.chat.coBrowseSite.edit": "Modifier l'adresse collaborative de la page Web",
  "ccmessaging.chat.richTextPage.add": "Ajouter du texte enrichi",
  "ccmessaging.chat.richTextPage.edit": "Modifier le texte enrichi",
  "ccmessaging.chat.chatPhrase.add": "Ajouter une phrase commune",
  "ccmessaging.chat.chatPhrase.edit": "Modifier les phrases courantes",
  "ccmessaging.chat.audioLibrary.add": "Ajouter une voix",
  "ccmessaging.chat.audioLibrary.edit": "Modifier la voix",
  "ccmessaging.chat.videoLibrary.add": "Ajouter une vidéo",
  "ccmessaging.chat.videoLibrary.edit": "Modifier la vidéo",
  "ccmessaging.chat.imgLibrary.add": "Ajouter une image",
  "ccmessaging.chat.imgLibrary.edit": "Modifier l'image",
  "ccmessaging.chat.emoLibrary.add": "Ajouter des émoticônes personnalisées",
  "ccmessaging.chat.emoLibrary.edit": "Modifier des émoticônes personnalisées",
  "ccmessaging.chat.docLibrary.add": "Ajouter un document",
  "ccmessaging.chat.docLibrary.edit": "Modifier le document",
  "ccmessaging.chat.locate.add": "Ajouter une adresse",
  "ccmessaging.chat.locate.edit": "Modifier l'adresse",
  "ccmesaging.chat.agentconsole.commom.label": "Étiquette",
  "ccmesaging.chat.agentconsole.label.callReason": "Raison d'appel",
  "ccmesaging.chat.agentconsole.label.commonreply": "Réponses communes",
  "ccmesaging.chat.agentconsole.label.expression": "Émoticônes",
  "ccmesaging.chat.agentconsole.label.fontstyle": "Style de police",
  "ccmesaging.chat.agentconsole.label.historicalcontact": "Contact historique",
  "ccmesaging.chat.agentconsole.label.incallmessage": "Information de l’appel entrant",
  "ccmesaging.chat.agentconsole.label.Realtimetransfer": "Traduction en temps réel",
  "ccmesaging.chat.agentconsole.label.robotChat": "Dialogue avec Chatbot",
  "ccmesaging.chat.agentconsole.label.screenshot": "Captures d'écran",
  "ccmesaging.chat.agentconsole.label.seeMore": "Voir plus",
  "ccmesaging.chat.agentconsole.label.service": "Le service",
  "ccmesaging.chat.agentconsole.label.uploadattachment": "Télécharger la pièce jointe",
  "ccmesaging.chat.agentconsole.label.uploadimage": "Télécharger l'image",
  "ccmesaging.chat.agentconsole.title.release": "libération",
  "ccmesaging.chat.cobrowse.accept": "Accepter",
  "ccmesaging.chat.cobrowse.agentMax": "L'agent collabore actuellement sur la page Web, veuillez réessayer",
  "ccmesaging.chat.cobrowse.isAcceptWeb": "Êtes-vous sûr de vouloir accepter l'invitation de co-navigation ?",
  "ccmesaging.chat.cobrowse.isAcceptWebMax": "Le nombre maximum de connexions de co-navigation autorisées par le locataire actuel a été dépassé.",
  "ccmesaging.chat.cobrowse.noWebSiteAuth": "L'agent n'a pas l'autorisation de co-navigation.",
  "ccmesaging.chat.cobrowse.refused": "Refuser",
  "ccmesaging.chat.cobrowse.title": "Invitation de co-navigation",
  "ccmesaging.chat.cobrowse.webSite": "URL de co-navigation :",
  "ccmesaging.chat.cobrowse.webSiteButton": "Co-navigation",
  "ccmesaging.chat.cobrowse.webSitePlaceholder": "Entrez l'URL de partage.",
  "ccmessaging.address.protocol.check": "Le protocole réseau utilisé par l'adresse n'est pas sécurisé. L'utilisation de ce protocole peut entraîner des risques pour la sécurité.",
  "ccmessaging.channelconfig.algorithm": "Algorithme de chiffrement d'interconnexion",
  "ccmessaging.channelconfig.email.freeTimeText": "Réponse automatique pendant les heures non ouvrées",
  "ccmessaging.channelconfig.email.freeTimeText.tips": "Répondre à l'e-mail client reçu pendant les heures non ouvrables après l'ouverture",
  "ccmessaging.channelconfig.email.reply.each.eamil": "Répondre automatiquement à chaque email",
  "ccmessaging.channelconfig.email.selectAutoReply": "Modèle d'e-mail",
  "ccmessaging.channelconfig.email.selectFreeTimeText": "Modèle d'e-mail",
  "ccmessaging.channelconfig.email.worktime.autoReply": "Réponse automatique pendant les heures de travail",
  "ccmessaging.channelconfig.email.worktime.autoReply.tips": "Réponse à l'email client reçu pendant les heures ouvrables après l'ouverture",
  "ccmessaging.channelconfig.sms.autoReply": "Réponse automatique",
  "ccmessaging.channelconfig.sms.autoReply.tips": "Une réponse automatique est envoyée après la connexion réussie de l'agent.",
  "ccmessaging.channelconfig.sms.freeTimeText": "Réponse de jour non ouvrable",
  "ccmessaging.channelconfig.sms.selectAutoReply": "Modèle de réponse automatique SMS",
  "ccmessaging.channelconfig.sms.selectFreeTimeText": "Modèle de réponse SMS de jour non ouvrable",
  "ccmessaging.char.agentstatus.after.callends": "La priorit\u00E9 de la configuration de l'\u00E9tat de l'agent prend effet apr\u00E8s la fin de l'appel est la suivante : niveau de l'organisation > niveau du locataire. Si ce param\u00E8tre est laiss\u00E9 vide, la configuration ne prend pas effet et la configuration au niveau du locataire est utilis\u00E9e.",
  "ccmessaging.char.call.Idle.state": "État inactif",
  "ccmessaging.char.call.work.state": "état de travail",
  "ccmessaging.char.channelconfig.duplicateCodeName": "Le nom du canal existe déjà.",
  "ccmessaging.chat.agent.organizational.structure": "Structure organisationnelle",
  "ccmessaging.chat.agent.refreshFail": "Ne pas actualiser la page à plusieurs reprises",
  "ccmessaging.chat.agent.transferSessionFail": "Impossible de transférer la session",
  "ccmessaging.chat.agentconsole.accesschannel": "Canal d'accès",
  "ccmessaging.chat.agentconsole.agentStatus.error": "Un appel ne peut pas être lancé parce que l'agent n'est pas en ligne ou ne parle pas.",
  "ccmessaging.chat.agentconsole.allResults": "Tous les résultats",
  "ccmessaging.chat.agentconsole.asyncTimeBeyond": "L'envoi a échoué car l'intervalle de réponse refusé a été dépassé.",
  "ccmessaging.chat.agentconsole.beyondLimitNum": "Le nombre de messages envoyés dépasse la limite supérieure.",
  "ccmessaging.chat.agentconsole.browserecord": "Historique de navigation",
  "ccmessaging.chat.agentconsole.chat.close": "La session en cours s'est terminée",
  "ccmessaging.chat.agentconsole.chat.userId": "Identifiant utilisateur",
  "ccmessaging.chat.agentconsole.chat.userSettings": "Paramètres de l'utilisateur",
  "ccmessaging.chat.agentconsole.chatPhrase": "Phrases courantes",
  "ccmessaging.chat.agentconsole.cobrowse": "Co-navigation",
  "ccmessaging.chat.agentconsole.contract.pdfUploadMaxSizeTip": "La taille du contrat ne doit pas dépasser {n}M.",
  "ccmessaging.chat.agentconsole.contract.statisticSigns": "Statistiques de signature",
  "ccmessaging.chat.agentconsole.contract.statisticTotalSigns": "Statistiques",
  "ccmessaging.chat.agentconsole.contract.statisticTotalSigns.failTip": "Impossible de recueillir des statistiques sur les éléments de signature dans le contrat.",
  "ccmessaging.chat.agentconsole.contract.statisticTotalSigns.noSigns.failTip": "Icône d'emplacement de signature introuvable.",
  "ccmessaging.chat.agentconsole.contract.statisticTotalSigns.noStaticSigns.failTip": "Veuillez d'abord compter les contrats avec signatures.",
  "ccmessaging.chat.agentconsole.contract.upload.failTip": "Impossible de télécharger le contrat.",
  "ccmessaging.chat.agentconsole.contract.upload.noSelectSigns.failTip": "L'élément de signature n'est pas sélectionné pour le contrat.",
  "ccmessaging.chat.agentconsole.contract.upload.sameContract.failTip": "Le souscripteur a un contrat non expiré.",
  "ccmessaging.chat.agentconsole.current": "Actuel",
  "ccmessaging.chat.agentconsole.customerinformation": "Informations client",
  "ccmessaging.chat.agentconsole.defaultOrgName": "Organisation non affiliée",
  "ccmessaging.chat.agentconsole.document": "Documents",
  "ccmessaging.chat.agentconsole.emailNotSupport": "Le canal de messagerie ne prend pas en charge les messages multimédias.",
  "ccmessaging.chat.agentconsole.enter.total.number": "Veuillez saisir le nombre total de signatures",
  "ccmessaging.chat.agentconsole.filterByCondition": "Critères de filtrage",
  "ccmessaging.chat.agentconsole.fivegNotSupport": "Les canaux 5G ne prennent pas en charge la messagerie multimédia",
  "ccmessaging.chat.agentconsole.hisconversation": "Conversation historique",
  "ccmessaging.chat.agentconsole.history": "Histoire",
  "ccmessaging.chat.agentconsole.image": "L'IMAGE",
  "ccmessaging.chat.agentconsole.information": "Informations",
  "ccmessaging.chat.agentconsole.intention": "intention",
  "ccmessaging.chat.agentconsole.IpAddress": "Adresse IP",
  "ccmessaging.chat.agentconsole.label.defaultwelcome": "Bienvenue dans le système de service à la clientèle en ligne!",
  "ccmessaging.chat.agentconsole.label.endsession": "S'il faut mettre fin à la session?",
  "ccmessaging.chat.agentconsole.label.filesFacebookNotsupport": "Facebook Messenger ne prend actuellement pas en charge la réception d'informations de localisation",
  "ccmessaging.chat.agentconsole.label.filesTwitterNotsupport": "Twitter ne prend pas en charge l'audio, la localisation, ou la vidéo plus longtemps que 30 secondes!",
  "ccmessaging.chat.agentconsole.label.filesWechatNotsupport": "WeChat ne prend pas en charge l'audio qui dure plus de 60 secondes.",
  "ccmessaging.chat.agentconsole.label.messageRejected": "Le message a été rejeté par Twitter parce qu'il ressemble à du spam, s'il vous plaît utiliser synonyme ou réessayer plus tard:",
  "ccmessaging.chat.agentconsole.label.NoAudioDuration": "Impossible d'obtenir la durée audio. S'il vous plaît télécharger à nouveau.",
  "ccmessaging.chat.agentconsole.label.NoVideoDuration": "Impossible d'obtenir la durée de la vidéo,S'il vous plaît télécharger à nouveau!",
  "ccmessaging.chat.agentconsole.label.ongoingsession": "Il n'y a actuellement aucune session en cours!",
  "ccmessaging.chat.agentconsole.label.ongoingwx": "Le canal actuel ne peut pas envoyer d'informations de localisation !",
  "ccmessaging.chat.agentconsole.label.skillNoAgent": "L'agent dans la file d'attente de compétences est hors ligne.",
  "ccmessaging.chat.agentconsole.loading": "Charger plus",
  "ccmessaging.chat.agentconsole.locate": "Localisation",
  "ccmessaging.chat.agentconsole.mailDraft": "Ébauche d'e-mail",
  "ccmessaging.chat.agentconsole.message.notworktime": "L'agent n'est pas en temps de travail.",
  "ccmessaging.chat.agentconsole.messageloading": "chargement",
  "ccmessaging.chat.agentconsole.multimedia": "Multimédia",
  "ccmessaging.chat.agentconsole.multimediaid": "ID multimédia",
  "ccmessaging.chat.agentconsole.multimedianame": "Nom multimédia",
  "ccmessaging.chat.agentconsole.myconversation": "Ma conversation",
  "ccmessaging.chat.agentconsole.nocontact": "Aucun contact actuellement",
  "ccmessaging.chat.agentconsole.noHiscontact": "Pas de session historique",
  "ccmessaging.chat.agentconsole.notSupport": "Le canal ne prend pas en charge ce type de message multimédia.",
  "ccmessaging.chat.agentconsole.noUserArrive": "Aucun utilisateur n'est disponible pour le moment !",
  "ccmessaging.chat.agentconsole.novoEmail": "Nouvel e-mail",
  "ccmessaging.chat.agentconsole.nowconversation": "Conversation actuelle",
  "ccmessaging.chat.agentconsole.only.pdf.supported": "Seuls les fichiers PDF sont pris en charge",
  "ccmessaging.chat.agentconsole.photo": "Photo",
  "ccmessaging.chat.agentconsole.promptConetnt.cannot.rollback": "Le message ne peut pas être retiré",
  "ccmessaging.chat.agentconsole.promptConetnt.ContentLengthExceeds": "Le contenu de texte plus long que la limite n'est pas autorisé!",
  "ccmessaging.chat.agentconsole.promptConetnt.ContentNull": "EmptyContent n'est pas autorisé!",
  "ccmessaging.chat.agentconsole.promptConetnt.delectMessageRecord": "Vous avez supprimé un enregistrement",
  "ccmessaging.chat.agentconsole.promptConetnt.onlyFiveG": "Les messages de la carte 5G ne prennent en charge que les canaux 5G!",
  "ccmessaging.chat.agentconsole.promptConetnt.onlyWebCard": "Les messages de la carte Web ne supportent que les canaux Web!",
  "ccmessaging.chat.agentconsole.promptConetnt.operateFail": "Impossible d'effectuer des opérations sur les messages qui ne sont pas envoyés avec succès",
  "ccmessaging.chat.agentconsole.promptConetnt.operateFailWithChannel": "Seuls les messages Web peuvent être exploités",
  "ccmessaging.chat.agentconsole.promptConetnt.operateRollBackFail": "Les messages envoyés depuis plus de deux minutes ne peuvent pas être retirés",
  "ccmessaging.chat.agentconsole.promptConetnt.rollBackMessage": "Vous avez retiré un message",
  "ccmessaging.chat.agentconsole.promptConetnt.userAlreadyInSession": "L'envoi a échoué parce que l'abonné est dans un appel.",
  "ccmessaging.chat.agentconsole.promptConetnt.userBusy": "Traiter lorsque l'agent est inactif",
  "ccmessaging.chat.agentconsole.promptConetnt.userInfoNull": "UserInfomation obtenu pas correct, la session sera déconnectée!",
  "ccmessaging.chat.agentconsole.promptConetnt.userIsTalking.saveDraftFailed": "Impossible d'envoyer l'e-mail parce que l'abonné est dans un appel. L'e-mail ne parvient pas à être enregistré en tant que brouillon.",
  "ccmessaging.chat.agentconsole.promptConetnt.userIsTalking.saveDraftSuccess": "Impossible d'envoyer l'e-mail parce que l'abonné est dans un appel. L'e-mail est enregistré sous forme de brouillon.",
  "ccmessaging.chat.agentconsole.promptConetnt.userNoArrive": "Aucun utilisateur n'est connecté ou aucun utilisateur n'a été sélectionné !",
  "ccmessaging.chat.agentconsole.promptConetnt.userOffline": "L'utilisateur actuel est hors ligne!",
  "ccmessaging.chat.agentconsole.queuing": "Mise en file d'attente",
  "ccmessaging.chat.agentconsole.quoteReply": "Citer cette réponse",
  "ccmessaging.chat.agentconsole.recommendedCaseType": "Catégorie d'ordre de travail recommandée",
  "ccmessaging.chat.agentconsole.replyEmail": "Répondre Email",
  "ccmessaging.chat.agentconsole.robotAssistant": "Assistant Robot",
  "ccmessaging.chat.agentconsole.select.contract.uploaded": "Veuillez sélectionner le document à télécharger",
  "ccmessaging.chat.agentconsole.select.mandatory.signature": "Veuillez sélectionner une signature obligatoire",
  "ccmessaging.chat.agentconsole.selectCaseType": "Sélection d'une catégorie d'ordre de travail",
  "ccmessaging.chat.agentconsole.send.contract": "Envoyer le contrat",
  "ccmessaging.chat.agentconsole.send.repeat": "Ne cliquez pas de manière répétitive !",
  "ccmessaging.chat.agentconsole.sendEmail": "Envoyer un e-mail",
  "ccmessaging.chat.agentconsole.sendLocalFile": "Envoyer des fichiers locaux",
  "ccmessaging.chat.agentconsole.sendmessage": "Envoyer un message",
  "ccmessaging.chat.agentconsole.setHandledNumber": "Définir le numéro manipulé",
  "ccmessaging.chat.agentconsole.signature.variable": "Signature{n}",
  "ccmessaging.chat.agentconsole.signInCallNum": "Nombre d'appels de connexion actuels :",
  "ccmessaging.chat.agentconsole.smsTemplate": "Modèle SMS",
  "ccmessaging.chat.agentconsole.software": "Environnement logiciel",
  "ccmessaging.chat.agentconsole.spellcheck.correcting.not.finished": "Toutes vos fautes de frappe n'ont pas été corrigées. Finir le chèque ?",
  "ccmessaging.chat.agentconsole.spellcheck.correcting.cancel.confirmation": "Le contenu rectifié sera abandonné. Voulez-vous vraiment annuler la vérification?",
  "ccmessaging.chat.agentconsole.spellcheck.correctly.writing": "Correctly Ecrit",
  "ccmessaging.chat.agentconsole.spellcheck.failed": "La vérification orthographique a échoué.",
  "ccmessaging.chat.agentconsole.spellcheck.language": "Langue",
  "ccmessaging.chat.agentconsole.spellcheck.next": "Suivant",
  "ccmessaging.chat.agentconsole.spellcheck.previous": "Précédent",
  "ccmessaging.chat.agentconsole.spellcheck.replace": "Remplacer",
  "ccmessaging.chat.agentconsole.spellcheck.replaceall": "Remplacer tout",
  "ccmessaging.chat.agentconsole.spellcheck.sentence.length.failed": "La longueur du texte à vérifier dépasse la limite de 1024.",
  "ccmessaging.chat.agentconsole.spellcheck.sentence.length.failed.front": "La longueur du texte à vérifier dépasse la limite de 1024.",
  "ccmessaging.chat.agentconsole.spellcheck.sentence.no.context": "Il n'y a pas de texte dans la zone de texte. Vous n'avez pas besoin de vérifier l'orthographe.",
  "ccmessaging.chat.agentconsole.spellcheck.sentence.no.mistake": "Pas de fautes d'orthographe",
  "ccmessaging.chat.agentconsole.spellcheck.title": "Test de l'orthographe",
  "ccmessaging.chat.agentconsole.spellcheck.wrongly.writing": "Mal écrit",
  "ccmessaging.chat.agentconsole.talkavg": "Durée(s) moyenne(s) d'appel:",
  "ccmessaging.chat.agentconsole.talkNum": "L'appel d'aujourd'hui compte:",
  "ccmessaging.chat.agentconsole.thumbnail": "Vignette",
  "ccmessaging.chat.agentconsole.total.number.signatures": "Nombre total de signatures",
  "ccmessaging.chat.agentconsole.upload": "Téléchargement de fichiers",
  "ccmessaging.chat.agentconsole.uploadfail": "Le téléchargement du fichier a échoué",
  "ccmessaging.chat.agentconsole.userInputStatus": "Prévisualisez l'état de saisie de l'utilisateur.",
  "ccmessaging.chat.agentconsole.video": "Vidéo",
  "ccmessaging.chat.agentconsole.voice": "Voix",
  "ccmessaging.chat.agentconsole.waittime": "Temps d'attente",
  "ccmessaging.chat.async.asyncMsgNum": "Nombre de messages hors ligne",
  "ccmessaging.chat.async.asyncMsgTrending": "Tendance des messages hors ligne",
  "ccmessaging.chat.async.asyncUserNum": "Nombre d'utilisateurs hors ligne",
  "ccmessaging.chat.async.asyncUserTrending": "Tendance des utilisateurs hors ligne",
  "ccmessaging.chat.async.channelSelect": "Filtrage des canaux",
  "ccmessaging.chat.async.requestError": "La demande a échoué.",
  "ccmessaging.chat.async.statistics": "Surveillance des messages hors ligne",
  "ccmessaging.chat.async.statistics.asynChannelTotal": "Nombre total de canaux hors ligne",
  "ccmessaging.chat.async.statistics.asynMsgTotal": "Nombre total de messages hors ligne",
  "ccmessaging.chat.async.statistics.asynUserTotal": "Nombre total d'utilisateurs hors ligne",
  "ccmessaging.chat.async.statistics.updateTime": "Temps de mise à jour des données",
  "ccmessaging.chat.call.end.state": "État de fin d'appel",
  "ccmessaging.chat.card": "Carte",
  "ccmessaging.chat.edit.card": "Modifier Carte",
  "ccmessaging.chat.multimedia.selectTemplate": "Sélectionner Modèle",
  "ccmessaging.chat.card.template": "Modèle WhatsApp",
  "ccmessaging.chat.channelconfig.accessToken": "Jeton d'accès",
  "ccmessaging.chat.channelconfig.accessTokenSecret": "Clé de jeton d'accès",
  "ccmessaging.chat.channelconfig.accountKeyRepeat": "Le accountKey est répété dans le canal de WhatsApp",
  "ccmessaging.chat.channelconfig.add": "Nouveau",
  "ccmessaging.chat.channelconfig.addressValidate": "La valeur doit être au format sip:Nombre{'@'}botplatform.rcs.chinamobile.com et doit contenir moins de 100 caractères.",
  "ccmessaging.chat.channelconfig.afterhalf": "Vous pouvez cliquer sur le bouton pour supprimer le navigateur pour intercepter)",
  "ccmessaging.chat.channelconfig.agentTimeout": "Personnaliser l'intervalle de délai d'attente pour aucune réponse à l'agent et l'invite pour la réaffectation de l'agent",
  "ccmessaging.chat.channelconfig.agentTimeout.emailTimeOutTips": "Personnaliser l'intervalle de délai d'expiration pour aucune réponse d'agent",
  "ccmessaging.chat.channelconfig.agentWorkTime": "Temps de travail de l'agent",
  "ccmessaging.chat.channelconfig.alphavalidateFailed": "Caractères non valides. La valeur ne peut contenir que des lettres, des chiffres et des soulignements (_) (le premier caractère ne peut être qu'une lettre ou un soulignement).",
  "ccmessaging.chat.channelconfig.apifabric.ak": "Clé d'application",
  "ccmessaging.chat.channelconfig.apifabric.sk": "Secret de l'application",
  "ccmessaging.chat.channelconfig.apiKey": "Clé API",
  "ccmessaging.chat.channelconfig.apiKeySecret": "Secret clé API",
  "ccmessaging.chat.channelconfig.appId": "App Id",
  "ccmessaging.chat.channelconfig.appSecret": "Clé App",
  "ccmessaging.chat.channelconfig.assertionSigningKey": "Assertion de la clé de signature",
  "ccmessaging.chat.channelconfig.assistantAccessCode": "Code d'accès assistant",
  "ccmessaging.chat.channelconfig.assistantSet": "Configuration de l'assistant robot",
  "ccmessaging.chat.channelconfig.asyncFlag": "Messages hors ligne",
  "ccmessaging.chat.channelconfig.asyncTip": "Si cette fonction est désactivée, les messages hors ligne existants seront perdus.",
  "ccmessaging.chat.channelconfig.asyncTime": "Délai d'acheminement de messages hors ligne",
  "ccmessaging.chat.channelconfig.auth.duplicateAuth": "Le compte officiel a été autorisé et n'a pas besoin d'être reconfiguré.",
  "ccmessaging.chat.channelconfig.auth.relation.timeout": "L'autorisation peut avoir expiré. Scannez le code QR WeChat pour une nouvelle autorisation.",
  "ccmessaging.chat.channelconfig.beforhalf": `Vous pouvez cliquer sur le bouton "Essayer" pour voir l'effet d'intégration. (Si l'URL est correcte et qu'un indicateur d'interception apparaît dans le coin supérieur droit du navigateur`,
  "ccmessaging.chat.channelconfig.bsp": "Fournisseur de solutions d'affaires",
  "ccmessaging.chat.channelconfig.cert.empty": "Le certificat ne peut pas être vide lorsque l'adresse push utilise le protocole HTTPS.",
  "ccmessaging.chat.channelconfig.Certification": "Méthode de vérification",
  "ccmessaging.chat.channelconfig.changeLogo": "Changer l'avatar",
  "ccmessaging.chat.channelconfig.channelID": "ID du canal",
  "ccmessaging.chat.channelconfig.channelSecret": "Clé de canal",
  "ccmessaging.chat.channelconfig.chatbotAddress": "Adresse du chatbot",
  "ccmessaging.chat.channelconfig.checkAgentTimeOutInterval": "L'intervalle de délai d'attente pour aucune réponse d'agent ne peut pas être plus long que l'intervalle d'invite pour aucune réponse.",
  "ccmessaging.chat.channelconfig.checkEncodingAESKey": "la longueur de la clé est 43 et ne peut contenir que A-Z, a-z, 0-9",
  "ccmessaging.chat.channelconfig.checkmessage": `Ne peut pas contenir les caractères spéciaux suivants <>'"/\\`,
  "ccmessaging.chat.channelconfig.checkNickName": `Ne peut pas contenir les caractères spéciaux suivants +~!{'@'}#$%&*()/=+{'{'}{'}'};:'\\",<.>?`,
  "ccmessaging.chat.channelconfig.checkName": "Le nom ne peut pas être les mots system, etc",
  "ccmessaging.chat.channelconfig.checkNumber": "La valeur n'est pas valide.",
  "ccmessaging.chat.channelconfig.checkSocialChatNum": "Ne peut contenir de décimales",
  "ccmessaging.chat.channelconfig.checkWhatsAppBaseUrl": "L'URL n'est pas valide !",
  "ccmessaging.chat.channelconfig.checkWhatsAppPhoneNum": "Numéro de mobile non valide",
  "ccmessaging.chat.channelconfig.clicktocall.empty": "La file d'attente de compétences ou le code d'accès IVR ne peuvent pas être vides !",
  "ccmessaging.chat.channelconfig.clickToCallSkillList": "File d'attente de compétences Click-to-Call",
  "ccmessaging.chat.channelconfig.close": "Fermer",
  "ccmessaging.chat.channelconfig.codeName": "Code d'accès au canal",
  "ccmessaging.chat.channelconfig.defaultReply": "Réponse par défaut",
  "ccmessaging.chat.channelconfig.delete": "Supprimer",
  "ccmessaging.chat.channelconfig.delete.exist.asyncMsg": "Impossible de supprimer le canal [{n}]. Réessayez après l'affectation des messages hors ligne.",
  "ccmessaging.chat.channelconfig.delete.exist.asyncUser": "Impossible de supprimer le canal [{n}]. Réessayez une fois que les utilisateurs hors ligne ont été assignés.",
  "ccmessaging.chat.channelconfig.delete.exist.session": "Impossible de supprimer le canal [{n}]. Réessayez après la fin de la session.",
  "ccmessaging.chat.channelconfig.edit": "Opération",
  "ccmessaging.chat.channelconfig.email.bccLimit": "Destinataires Cci max.",
  "ccmessaging.chat.channelconfig.email.ccLimit": "Destinataires Cc max.",
  "ccmessaging.chat.channelconfig.email.number": "Configuration de la quantité d'adresse e-mail",
  "ccmessaging.chat.channelconfig.email.personal": "Répondre par e-mail personnel",
  "ccmessaging.chat.channelconfig.email.placeholder": "1 - 100, facultatif",
  "ccmessaging.chat.channelconfig.email.range": "Entrez un nombre entier positif allant de 1 à 100",
  "ccmessaging.chat.channelconfig.email.address.limit": "qu'il (elle)",
  "ccmessaging.chat.channelconfig.email.receiverLimit": "Destinataires max.",
  "ccmessaging.chat.channelconfig.email.selectSignature": "Signature de courriel",
  "ccmessaging.chat.channelconfig.email.signature": "Signature de courriel",
  "ccmessaging.chat.channelconfig.email.signature.desc": "Descriptif",
  "ccmessaging.chat.channelconfig.email.signature.name": "Nom de la signature de l'e-mail",
  "ccmessaging.chat.channelconfig.email.totalEmailNumber": "Le nombre total de destinataires, de destinataires de copie conforme et d'adresses e-mail secrètes de destinataires ne peut pas dépasser 100",
  "ccmessaging.chat.channelconfig.emailRepeate": "La passerelle de réception d'e-mails a été utilisée. Accédez à la page de configuration du routage des messages pour afficher la passerelle de réception des e-mails.",
  "ccmessaging.chat.channelconfig.endchat": "S'il faut mettre fin à la session?",
  "ccmessaging.chat.channelconfig.endOfSession": "Fin de session en raison de l'absence de réponse du client",
  "ccmessaging.chat.channelconfig.envName": "Étiquette d'environnement Dev",
  "ccmessaging.chat.channelconfig.envNameValidate": "La valeur doit être une chaîne de caractères inférieure ou égale à 24 caractères et ne peut contenir que des lettres et des chiffres.",
  "ccmessaging.chat.channelconfig.facebookBackground": "FaceBook",
  "ccmessaging.chat.channelconfig.fiveGBackground": "Message CSP 5G",
  "ccmessaging.chat.channelconfig.gender": "Sexe",
  "ccmessaging.chat.channelconfig.guestTimeout": "Délai d'attente personnalisé pour l'absence de réponse du client et le message de fin de session. Le délai d'attente par défaut est de 20 minutes si cette fonction est désactivée.",
  "ccmessaging.chat.channelconfig.guestTimeout.timeOutTips": "Personnaliser l'intervalle de délai d'expiration pour aucune réponse de client (Si cette bascule est désactivée, l'intervalle de temps par défaut est de {n} minutes.)",
  "ccmessaging.chat.channelconfig.hostingMethod": "Méthode d'hébergement",
  "ccmessaging.chat.channelconfig.hour": "Heure",
  "ccmessaging.chat.channelconfig.identityCertificate": "Certificat d'identité",
  "ccmessaging.chat.channelconfig.importAvatarSize": "La taille de l'image de l'avatar dépasse 6 Mo.",
  "ccmessaging.chat.channelconfig.importAvatartype": "Type d'image d'avatar incorrect.",
  "ccmessaging.chat.channelconfig.infobipPassword": "Mot de passe",
  "ccmessaging.chat.channelconfig.infobipUserName": "Nom d'utilisateur",
  "ccmessaging.chat.channelconfig.infoConfiguration": "Configuration de l'information",
  "ccmessaging.chat.channelconfig.keyEscrow": "Clé",
  "ccmessaging.chat.channelconfig.KeyValidate": "Uniquement des lettres, des chiffres et 11 caractères spéciaux _~!{'@'}#$%^&*- composition arbitraire",
  "ccmessaging.chat.channelconfig.whatsappNameValidate": "Uniquement des lettres, des chiffres et 12 caractères spéciaux _~!{'@'}#$%^&*-. composition arbitraire",
  "ccmessaging.chat.channelconfig.lastagent.time": "Plage de temps",
  "ccmessaging.chat.channelconfig.lastModifyTime": "Heure de dernière modification",
  "ccmessaging.chat.channelconfig.logo": "Avatar",
  "ccmessaging.chat.channelconfig.messagePushUrl.not.configure.trustList": "L'adresse n'est pas une liste de confiance. Veuillez contacter l'administrateur système.",
  "ccmessaging.chat.channelconfig.hrefUrl.not.configure.trustList": "L'adresse du lien hypertexte n'est pas sur la liste de confiance. Veuillez contacter l'administrateur système.",
  "ccmessaging.chat.channelconfig.minute": "Minute",
  "ccmessaging.chat.channelconfig.name": "Nom et prénom",
  "ccmessaging.chat.channelconfig.nochatonline": "Il n'y a actuellement aucune session en cours!",
  "ccmessaging.chat.channelconfig.noLimit": "Non spécifié",
  "ccmessaging.chat.channelconfig.NonworkingTimeReminder": "Notification de temps de non-travail",
  "ccmessaging.chat.channelconfig.nonWorkTimeDay": "Jour non ouvrable",
  "ccmessaging.chat.channelconfig.noReplyIntervalTime": "Délai de réponse du client appelé",
  "ccmessaging.chat.channelconfig.noReplyIntervalTimeValidate": "L'intervalle pour l'absence de réponse du client ne peut pas être supérieur à l'intervalle pour l'absence de réponse du client.",
  "ccmessaging.chat.channelconfig.noReplyTime": "Délai d'appels sortants des agents",
  "ccmessaging.chat.channelconfig.offBeginTime": "De l'heure de début à l'heure actuelle",
  "ccmessaging.chat.channelconfig.offLastAgentType": "Dernier type d'agent",
  "ccmessaging.chat.channelconfig.offTimeRange": "Dans la plage de temps",
  "ccmessaging.chat.channelconfig.offToday": "Le jour en cours",
  "ccmessaging.chat.channelconfig.onlyContainSubType": "Afficher uniquement les files d'attente de compétences du type de canal lorsqu'un agent transfère une session.",
  "ccmessaging.chat.channelconfig.orgConfiguration": "Configuration de l'OU",
  "ccmessaging.chat.channelconfig.pageId": "ID de page",
  "ccmessaging.chat.channelconfig.personalSetting": "Paramètres personnalisés",
  "ccmessaging.chat.channelconfig.phoneNumber": "Numéro de téléphone",
  "ccmessaging.chat.channelconfig.pleaseSelect": "Veuillez sélectionner...",
  "ccmessaging.chat.channelconfig.PreferredLastAgent": "Mode dernier agent",
  "ccmessaging.chat.channelconfig.Previous": "Précédent",
  "ccmessaging.chat.channelconfig.privateKeyValidate": "Le format JSON est requis. Les valeurs clés dans JSON ne peuvent être composées que de lettres, de chiffres et de 11 caractères spéciaux _~!{'@'}#$%^&*- composition arbitraire",
  "ccmessaging.chat.channelconfig.publicConfiguration": "Configuration commune",
  "ccmessaging.chat.channelconfig.pushMessageUrlValid": "Impossible d'enregistrer la configuration du canal. Vérifiez que l'adresse push du message ou le certificat est valide.",
  "ccmessaging.chat.channelconfig.qryaddressconfigfailed": "Impossible d'interroger la règle de vérification chatbotAddress du canal 5G. La règle de vérification n'est pas configurée.",
  "ccmessaging.chat.channelconfig.queryType": "Mode de sélection",
  "ccmessaging.chat.channelconfig.queueReminder": "Rappel de file d'attente",
  "ccmessaging.chat.channelconfig.queueReminderContent": "Contenu du rappel de file d'attente",
  "ccmessaging.chat.channelconfig.queueReminderInterval": "Intervalle de rappel de la file d'attente",
  "ccmessaging.chat.channelconfig.reCreateScenario": "Générer",
  "ccmessaging.chat.channelconfig.refreshAccessToken": "Rafraîchir accessToken a échoué.",
  "ccmessaging.chat.channelconfig.robotAccessCode": "Code d'accès au chatbot",
  "ccmessaging.chat.channelconfig.robotApplicationKey": "Clé d'application Chatbot",
  "ccmessaging.chat.channelconfig.robotSet": "Configuration du chatbot",
  "ccmessaging.chat.channelconfig.routeType": "Mode de routage",
  "ccmessaging.chat.channelconfig.routeTypeSkill": "Routage de file d'attente de compétences",
  "ccmessaging.chat.channelconfig.saveAndToNext": "Enregistrer et passer à l'étape suivante",
  "ccmessaging.chat.channelconfig.savechannelconfigfailed": "L'enregistrement de la configuration du canal a échoué !",
  "ccmessaging.chat.channelconfig.scanCodeHosting": "Code QR",
  "ccmessaging.chat.channelconfig.scenario.status.existent": "clé de scénario:Oui",
  "ccmessaging.chat.channelconfig.scenario.status.noExistent": "clé de scénario:NON",
  "ccmessaging.chat.channelconfig.select.algorithm": "Sélectionnez un algorithme de chiffrement",
  "ccmessaging.chat.channelconfig.select.algorithm.configtips": "Configurez l'algorithme de cryptage pris en charge en fonction des exigences de la plate-forme ouverte de canal.",
  "ccmessaging.chat.channelconfig.select.algorithm.safetips": "Cet algorithme n'est pas assez fort. Si la plate-forme prend en charge cet algorithme, il est conseillé d'utiliser un algorithme de chiffrement plus élevé.",
  "ccmessaging.chat.channelconfig.selectAccessChannel": "Sélectionnez le canal d'accès",
  "ccmessaging.chat.channelconfig.selectConfigurationMethod": "Choisissez la méthode de configuration",
  "ccmessaging.chat.channelconfig.selectRobot": "Sélectionner un robot",
  "ccmessaging.chat.channelconfig.selectRobotAssistant": "Sélectionner l'assistant robotique",
  "ccmessaging.chat.channelconfig.sessionTransferConfig": "Transfert de session",
  "ccmessaging.chat.channelconfig.silentAgentDelete": "Êtes-vous sûr de vouloir supprimer la configuration de l'agent de sourdine vocale sélectionnée ?",
  "ccmessaging.chat.channelconfig.silentAgentSelect": "Veuillez sélectionner la configuration de l'agent de muet vocal à supprimer.",
  "ccmessaging.chat.channelconfig.silentAgentSkillList": "File d'attente de compétences de l'agent silencieux",
  "ccmessaging.chat.channelconfig.skill": "Liste de compétences (accesscode+extcode)",
  "ccmessaging.chat.channelconfig.skillList": "File d'attente de compétences",
  "ccmessaging.chat.channelconfig.smsRepeate": "La passerelle SMS a été utilisée. Accédez à la page de configuration de la route des messages pour afficher la passerelle SMS.",
  "ccmessaging.chat.channelconfig.startTime": "Heure de début",
  "ccmessaging.chat.channelconfig.sub": "Copiez et collez le code suivant dans l'étiquette de votre site Web, enregistrez et publiez, vous pouvez compléter l'accès rapide des ServiceCloud Huawei. Après la publication, le portail Huawei Service Cloud apparaîtra dans le coin inférieur droit de votre site Web. Cliquez pour accéder à l'établi du service à la clientèle. Conversation en temps réel.",
  "ccmessaging.chat.channelconfig.submit": "sumbit",
  "ccmessaging.chat.channelconfig.submitVerification": "Instructions d'intégration",
  "ccmessaging.chat.channelconfig.successfulAccess": "Accès réussi",
  "ccmessaging.chat.channelconfig.thirdPartySecretKey": "Clé d'authentification tierce",
  "ccmessaging.chat.channelconfig.time": "Plage de temps (min)",
  "ccmessaging.chat.channelconfig.timeoutReply": "Réponse de délai d'expiration",
  "ccmessaging.chat.channelconfig.timerange": "Plage de temps",
  "ccmessaging.chat.channelconfig.timeZone": "Fuseau horaire",
  "ccmessaging.chat.channelconfig.today": "Le jour actuel",
  "ccmessaging.chat.channelconfig.triggerText": "Invite de message hors ligne",
  "ccmessaging.chat.channelconfig.trustCertificate": "Certificat de confiance",
  "ccmessaging.chat.channelconfig.trybutton": "Essayer",
  "ccmessaging.chat.channelconfig.turnAI": "Se connecter au chatbot",
  "ccmessaging.chat.channelconfig.turnArtificial": "Mot-clé pour le transfert à l'agent",
  "ccmessaging.chat.channelconfig.turnArtificial.tip": "séparé par des points-virgules chinois ou anglais",
  "ccmessaging.chat.channelconfig.turnAssistant": "Se connecter à Assistant",
  "ccmessaging.chat.channelconfig.turnManualPrompt": "Prompt pour le transfert à l'agent",
  "ccmessaging.chat.channelconfig.twitterBackground": "Serveur Twitter",
  "ccmessaging.chat.channelconfig.twittertips": "Vous pouvez accéder aux sites Web suivants. Cliquez sur Soumettre pour l'authentification Twitter.",
  "ccmessaging.chat.channelconfig.update": "Mise à jour",
  "ccmessaging.chat.channelconfig.updatecontactfailed": "Échec du contact de mise à jour !",
  "ccmessaging.chat.channelconfig.voiceAccess": "SVI appelé",
  "ccmessaging.chat.channelconfig.voiceAgentSkill": "File d'attente de compétences de l'agent",
  "ccmessaging.chat.channelconfig.webhookConfirmPassword": "Webhook Confirmer le mot de passe",
  "ccmessaging.chat.channelconfig.webHookException": "Impossible d'obtenir l'heure valide pour le mot de passe webHook",
  "ccmessaging.chat.channelconfig.webhookPassword": "Mot de passe Webhook",
  "ccmessaging.chat.channelconfig.webhookPassword.newPassword": "Nouveau mot de passe Webhook",
  "ccmessaging.chat.channelconfig.webhookPassword.noValidPassword": "Pas de mot de passe disponible",
  "ccmessaging.chat.channelconfig.webhookPassword.oldPassword": "Verrouiller le mot de passe de la période Webhook",
  "ccmessaging.chat.channelconfig.webhookPassword.validPeriod": "Valide jusqu'à:",
  "ccmessaging.chat.channelconfig.webhookPassword.validPeriod.fifthDayTip": "Valable moins de 15 jours",
  "ccmessaging.chat.channelconfig.webhookPassword.validPeriod.oneDayTip": "Valable pour moins d'un jour. Veuillez modifier!",
  "ccmessaging.chat.channelconfig.wechat.encryption.tip": "Désactiver le cryptage des messages peut entraîner des risques de sécurité. Le paramétrage doit être conforme à celui de la Plateforme de Comptes Officiels WeChat.",
  "ccmessaging.chat.channelconfig.wechat.qr.label": "Scanner le QR code",
  "ccmessaging.chat.channelconfig.wechat.qr.label2": "Réautoriser",
  "ccmessaging.chat.channelconfig.wechat.qr.label3": "Succès de l'autorisation",
  "ccmessaging.chat.channelconfig.weChatBackground": "Serveur WeChat",
  "ccmessaging.chat.channelconfig.weChatMessageEncryptionSwitch": "Commutateur de chiffrement de message WeChat",
  "ccmessaging.chat.channelconfig.welcomeMessage": "Salutations",
  "ccmessaging.chat.channelconfig.whatsAppBackground": "Serveur WhatsApp",
  "ccmessaging.chat.channelconfig.workTimeDay": "Journée de travail",
  "ccmessaging.chat.channelconfig.worktimeerror": "S'il vous plaît entrer la période normale!",
  "ccmessaging.chat.chatcard.audio": "audio",
  "ccmessaging.chat.chatcard.button.addtabs": "Ajouter des options de carte",
  "ccmessaging.chat.chatcard.cancel": "Annuler",
  "ccmessaging.chat.chatcard.cardId": "ID de modèle",
  "ccmessaging.chat.chatcard.cardName": "Nom et prénom",
  "ccmessaging.chat.chatcard.cardNamePlaceholder": "Entrez le nom du modèle de carte.",
  "ccmessaging.chat.chatcard.cardTitle": "Titre",
  "ccmessaging.chat.chatcard.cardTitlePlaceholder": "Entrez le titre de la carte.",
  "ccmessaging.chat.chatcard.create": "Enregistrer",
  "ccmessaging.chat.chatcard.delete": "Supprimer",
  "ccmessaging.chat.chatcard.deleteFail": "Échec de la suppression",
  "ccmessaging.chat.chatcard.deleteLimit": "Le modèle a été référencé et ne peut pas être supprimé.",
  "ccmessaging.chat.chatcard.deleteSuccess": "Succès de suppression",
  "ccmessaging.chat.chatcard.deleteTips": "La vérification des paramètres a échoué.",
  "ccmessaging.chat.chatcard.deleteWhatsAppTemplate.Fail": "La suppression a échoué car le modèle est déjà référencé par un message de modèle WhatsApp.",
  "ccmessaging.chat.chatcard.description": "Descriptif",
  "ccmessaging.chat.chatcard.descriptionPlaceholder": "Entrez la description de la carte.",
  "ccmessaging.chat.chatcard.details": "Détails de la carte",
  "ccmessaging.chat.chatcard.dial": "cadran",
  "ccmessaging.chat.chatcard.down": "Déplacer bas",
  "ccmessaging.chat.chatcard.edit": "Modifier",
  "ccmessaging.chat.chatcard.fail": "Échec",
  "ccmessaging.chat.chatcard.failTip": "L'ajout a échoué Le nombre d'options ajoutées atteint le maximum.",
  "ccmessaging.chat.chatcard.folded": "plié",
  "ccmessaging.chat.chatcard.id": "Saisissez un ID de modèle.",
  "ccmessaging.chat.chatcard.image": "L'IMAGE",
  "ccmessaging.chat.chatcard.input": "Saisissez un nom de modèle.",
  "ccmessaging.chat.chatcard.invalidUrl": "L'URL ne commence pas par http://,https://",
  "ccmessaging.chat.chatcard.linking": "liaison",
  "ccmessaging.chat.chatcard.mediaFileType": "Type de fichier multimédia",
  "ccmessaging.chat.chatcard.mediaHeight": "Hauteur du média",
  "ccmessaging.chat.chatcard.mediaType": "Type de média",
  "ccmessaging.chat.chatcard.mediumHeight": "Hauteur Moyenne",
  "ccmessaging.chat.chatcard.messageTemplateCreate": "Création d'un modèle de carte",
  "ccmessaging.chat.chatcard.messageTemplateDelete": "Suppression d'un modèle",
  "ccmessaging.chat.chatcard.messageTemplateModify": "Modification d'un modèle",
  "ccmessaging.chat.chatcard.multimediaid": "ID multimédia",
  "ccmessaging.chat.chatcard.multiMediaId": "Ressource pour les médias",
  "ccmessaging.chat.chatcard.multimedianame": "Nom de fichier multimédia",
  "ccmessaging.chat.chatcard.name": "Entrez le nom.",
  "ccmessaging.chat.chatcard.NoneTitle": "sans titre",
  "ccmessaging.chat.chatcard.operate": "Opération",
  "ccmessaging.chat.chatcard.queryTemplateFail": "Échec du modèle de requête",
  "ccmessaging.chat.chatcard.replay": "rejouer",
  "ccmessaging.chat.chatcard.saveFail": "Échec de l'enregistrement",
  "ccmessaging.chat.chatcard.saveSuccess": "Sauvegarder le succès",
  "ccmessaging.chat.chatcard.selectMultiMediaId": "Sélectionnez la ressource multimédia correspondante.",
  "ccmessaging.chat.chatcard.selectMultiMediaTips": "Veuillez sélectionner des ressources multimédias.",
  "ccmessaging.chat.chatcard.closeDialog": "Êtes-vous sûr de fermer cette boîte de dialogue?",
  "ccmessaging.chat.chatcard.shortHeight": "Hauteur courte",
  "ccmessaging.chat.chatcard.strcontentTip": "Les requêtes HTTP comportent des risques de sécurité.",
  "ccmessaging.chat.chatcard.success": "Succès",
  "ccmessaging.chat.chatcard.tabs": "Options de carte",
  "ccmessaging.chat.chatcard.tabs.displaytext": "Texte d'affichage de la carte",
  "ccmessaging.chat.chatcard.tabs.selectcontent": "sélectionner le contenu",
  "ccmessaging.chat.chatcard.tabs.selectType": "Type d'option",
  "ccmessaging.chat.chatcard.tabsname": "Nom de l'option de la carte",
  "ccmessaging.chat.chatcard.tallHeight": "Hauteur élevée",
  "ccmessaging.chat.chatcard.up": "Déplacer haut",
  "ccmessaging.chat.chatcard.video": "Vidéo",
  "ccmessaging.chat.chatcard.viewTips": "Le modèle de carte a été supprimé et ne peut pas être visualisé.",
  "ccmessaging.chat.chatcard.whatsappTemplateCreate": "Créer un modèle WhatsApp",
  "ccmessaging.chat.chatcard.willdeletechatcard": "Êtes-vous sûr de vouloir supprimer cette carte ?",
  "ccmessaging.chat.chatcard.willdeletechatTemplate": "Êtes-vous sûr de vouloir supprimer le modèle WhatsApp?",
  "ccmessaging.chat.chatcard.nameExist": "Le nom existe déjà. Veuillez en saisir un autre.",
  "ccmessaging.chat.chatconfig.add": "Nouveau",
  "ccmessaging.chat.chatconfig.channelAccessCode": "Code d'accès à la chaîne",
  "ccmessaging.chat.chatconfig.channelOccupied": "Le canal est déjà occupé.",
  "ccmessaging.chat.chatconfig.channelType": "Type de canal",
  "ccmessaging.chat.chatconfig.configurationId": "ID de configuration",
  "ccmessaging.chat.chatconfig.delete": "Supprimer",
  "ccmessaging.chat.chatconfig.deletechannelAccessCode": "Supprimer la configuration du canal",
  "ccmessaging.chat.chatconfig.deletefail": "La suppression a échoué.",
  "ccmessaging.chat.chatconfig.deletesuccess": "Supprimé avec succès.",
  "ccmessaging.chat.chatconfig.idisnull": "L'ID est vide.",
  "ccmessaging.chat.chatconfig.inquire": "Rechercher",
  "ccmessaging.chat.chatconfig.modify": "Modifier",
  "ccmessaging.chat.chatconfig.operate": "Opération",
  "ccmessaging.chat.chatconfig.refresh": "Actualiser",
  "ccmessaging.chat.chatconfig.reset": "Réinitialiser",
  "ccmessaging.chat.chatconfig.rollBack": "Rétracter",
  "ccmessaging.chat.chatconfig.selectchannelAccessCode": "Sélectionnez la configuration du canal.",
  "ccmessaging.chat.chatconfig.skillId": "File d'attente de compétences de liaison",
  "ccmessaging.chat.chatconfig.willdeletechannelAccessCode": "Êtes-vous sûr de vouloir supprimer la configuration du canal sélectionné ?",
  "ccmessaging.chat.chatemail.cancelhandled": "Annulation traitée",
  "ccmessaging.chat.chatemail.delete": "Supprimer",
  "ccmessaging.chat.chatemail.details": "Voir",
  "ccmessaging.chat.chatemail.forwarded": "Transféré",
  "ccmessaging.chat.chatemail.forwardedTime": "Transmis à",
  "ccmessaging.chat.chatemail.handled": "Traitement",
  "ccmessaging.chat.chatemail.maxNumberImages": "Le nombre d'images locales téléchargées a atteint le maximum de 20.",
  "ccmessaging.chat.chatemail.saveDraft": "Enregistrer en tant que brouillon",
  "ccmessaging.chat.chatemail.sender": "Expéditeur :",
  "ccmessaging.chat.chatholiday.calendar.preview": "Aperçu du calendrier",
  "ccmessaging.chat.chatholiday.checkHolidaySize": "Il y a trop d'entrées, S'il vous plaît supprimer les entrées supplémentaires d'abord",
  "ccmessaging.chat.chatholiday.date": "Date",
  "ccmessaging.chat.chatholiday.dateConfiguration": "Configuration de la date",
  "ccmessaging.chat.chatholiday.delete": "Supprimer",
  "ccmessaging.chat.chatholiday.description": "Descriptif",
  "ccmessaging.chat.chatholiday.designation.alert": "La date de fin doit être postérieure à la date de début",
  "ccmessaging.chat.chatholiday.designation.cancel": "Annuler",
  "ccmessaging.chat.chatholiday.designation.complete": "Complète",
  "ccmessaging.chat.chatholiday.designation.days": "Plusieurs jours",
  "ccmessaging.chat.chatholiday.designation.end": "Date de fin",
  "ccmessaging.chat.chatholiday.designation.norepeating": "Non répété",
  "ccmessaging.chat.chatholiday.designation.oneday": "1 Jour",
  "ccmessaging.chat.chatholiday.designation.repeating": "Répétée chaque année",
  "ccmessaging.chat.chatholiday.designation.selectrule": "La date sélectionnée ne peut pas être la même que la date sélectionnée",
  "ccmessaging.chat.chatholiday.designation.show": "Chaque année",
  "ccmessaging.chat.chatholiday.designation.start": "Date de début",
  "ccmessaging.chat.chatholiday.designation.type": "Type de date",
  "ccmessaging.chat.chatholiday.friday": "Vendredi",
  "ccmessaging.chat.chatholiday.holidays.explanation": "Vous pouvez spécifier certains jours comme des jours non ouvrables, par exemple, le 13 septembre 2019 (vendredi, Festival chinois de la mi-automne).",
  "ccmessaging.chat.chatholiday.isDelete.otherdays": "Voulez -vous vraiment supprimer cette date d'exception?",
  "ccmessaging.chat.chatholiday.isDelete.specified": "Êtes-vous sûr de vouloir supprimer cette date spécifiée?",
  "ccmessaging.chat.chatholiday.monday": "Lundi",
  "ccmessaging.chat.chatholiday.new": "Nouveau",
  "ccmessaging.chat.chatholiday.nonWorkbench": "Configuration non ouvrable",
  "ccmessaging.chat.chatholiday.noworking": "Jour non ouvrable",
  "ccmessaging.chat.chatholiday.operate": "Opérer",
  "ccmessaging.chat.chatholiday.other.prompt": "La date sélectionnée est la même qu'une date existante",
  "ccmessaging.chat.chatholiday.otherdays.exception": "Date d'exception",
  "ccmessaging.chat.chatholiday.otherdays.explanation": "Vous pouvez sélectionner certains jours comme jours ouvrables. L'intervalle de temps maximal est d'un an. Par exemple : vous pouvez sélectionner le 13 juillet 2019 (samedi, heures supplémentaires temporaires requises) comme jour ouvrable.",
  "ccmessaging.chat.chatholiday.repeat": "Règles répétées",
  "ccmessaging.chat.chatholiday.saturday": "Samedi",
  "ccmessaging.chat.chatholiday.savechatholidayfailed": "L'enregistrement de la configuration des vacances a échoué",
  "ccmessaging.chat.chatholiday.specified.date": "Date spécifiée",
  "ccmessaging.chat.chatholiday.sunday": "Dimanche",
  "ccmessaging.chat.chatholiday.thursday": "Jeudi",
  "ccmessaging.chat.chatholiday.tuesday": "Mardi",
  "ccmessaging.chat.chatholiday.wednesday": "Mercredi",
  "ccmessaging.chat.chatholiday.weekend.explanation": "Vous pouvez définir certains jours d'une semaine comme des jours non ouvrables, par exemple, le samedi et le dimanche.",
  "ccmessaging.chat.chatholiday.weekly": "Hebdomadaire",
  "ccmessaging.chat.chatholiday.workbench": "Configuration de l'établi",
  "ccmessaging.chat.chatholiday.working.day": "Journée de travail",
  "ccmessaging.chat.chatholiday.tip.choosedate": "Veuillez sélectionner une date",
  "ccmessaging.chat.chatholiday.tip.descinvalid": "La descripción no puede superar los 20 caracteres",
  "ccmessaging.chat.chatmanage.agentIntervalTime": "Délai de réponse de l'agent",
  "ccmessaging.chat.chatmanage.agentSessionEndText": "Demande de réaffection de l'agent",
  "ccmessaging.chat.chatmanage.autoEndSession": "Si l'invité ne répond pas dans le temps spécifié, la boîte de dialogue se termine automatiquement.",
  "ccmessaging.chat.chatmanage.autoEndSessionMorethan": "La description de l'intervalle dans lequel le client n'a pas de réponse ne peut dépasser 100 caractères.",
  "ccmessaging.chat.chatmanage.autoEndSessionnotnull": "La description de l'intervalle de non-réponse du client ne peut pas être vide",
  "ccmessaging.chat.chatmanage.autoEndSessionNum": "L'intervalle de rappel sans réponse du client doit être un nombre.",
  "ccmessaging.chat.chatmanage.autoEndSessionRange": "L'intervalle de rappel sans réponse du client doit être compris entre 0 et 60",
  "ccmessaging.chat.chatmanage.cancel": "Annuler",
  "ccmessaging.chat.chatmanage.edit": "Modifier",
  "ccmessaging.chat.chatmanage.endTime": "Heure de fin",
  "ccmessaging.chat.chatmanage.endTimeNum": "L'heure de fin doit être un nombre.",
  "ccmessaging.chat.chatmanage.endTimeRange": "L'heure de fin doit être comprise entre 0 et 24.",
  "ccmessaging.chat.chatmanage.fail": "Échec",
  "ccmessaging.chat.chatmanage.freeTimeText": "Notification de temps de non-travail",
  "ccmessaging.chat.chatmanage.freeTimeTextMorethan": "La description de la notification de non-temps de travail ne peut pas contenir plus de 100 caractères.",
  "ccmessaging.chat.chatmanage.freeTimeTextnotnull": "La description du rappel en heures chômées ne peut pas être vide.",
  "ccmessaging.chat.chatmanage.intervalTime": "Délai de réponse du client",
  "ccmessaging.chat.chatmanage.save": "Enregistrer",
  "ccmessaging.chat.chatmanage.savefailed": "L'enregistrement a échoué.",
  "ccmessaging.chat.chatmanage.savesuccess": "Sauvegardé avec succès.",
  "ccmessaging.chat.chatmanage.sessionEndText": "Conclusion",
  "ccmessaging.chat.chatmanage.sessionEndTextMorethan": "La description de conclusion de la boîte de dialogue ne peut pas contenir plus de 100 caractères.",
  "ccmessaging.chat.chatmanage.sessionEndTextnotnull": "La description de la fin de la session ne peut pas être vide.",
  "ccmessaging.chat.chatmanage.specialCharacters": "Contient des caractères spéciaux. Veuillez les modifier.",
  "ccmessaging.chat.chatmanage.startTime": "Heure de début",
  "ccmessaging.chat.chatmanage.startTimeNum": "L'heure de début doit être un nombre.",
  "ccmessaging.chat.chatmanage.startTimeRange": "L'heure de début doit être comprise entre 0 et 24",
  "ccmessaging.chat.chatmanage.success": "Succès",
  "ccmessaging.chat.chatmanage.useRobot": "Se connecter au chatbot",
  "ccmessaging.chat.chatmanage.workTime": "Temps de travail de l'agent (en format 24 heures)",
  "ccmessaging.chat.chatmanage.workTimeMorethan": "La description du temps de travail ne peut pas contenir plus de 100 caractères.",
  "ccmessaging.chat.chatpersonalization.add": "Ajouter",
  "ccmessaging.chat.chatpersonalization.avatarerror": "Sélectionnez une image.!",
  "ccmessaging.chat.chatpersonalization.cancel": "Annuler",
  "ccmessaging.chat.chatpersonalization.changeAvatar": "Changer l'avatar",
  "ccmessaging.chat.chatpersonalization.concurrentAgentsNumber": "Nombre de clients multimédias desservis simultanément par des agents",
  "ccmessaging.chat.chatpersonalization.concurrentNumberPriority": "La priorité de configuration du nombre de clients multimédias simultanément servis par les agents est la suivante : niveau agent>niveau organisation>niveau locataire. La valeur 0 indique que la configuration de ce niveau ne prend pas effet et que la configuration des autres niveaux est utilisée.",
  "ccmessaging.chat.chatpersonalization.delete": "Supprimer",
  "ccmessaging.chat.chatpersonalization.edit": "Modifier",
  "ccmessaging.chat.chatpersonalization.hour": "Heure",
  "ccmessaging.chat.chatpersonalization.MaximumConcurrent": "Clients de service simultanés maximum",
  "ccmessaging.chat.chatpersonalization.nickname": "Pseudo",
  "ccmessaging.chat.chatpersonalization.operating": "Opération",
  "ccmessaging.chat.chatpersonalization.prompt": "Inviter",
  "ccmessaging.chat.chatpersonalization.save": "Enregistrer",
  "ccmessaging.chat.chatpersonalization.savefailed": "L'enregistrement a échoué.",
  "ccmessaging.chat.chatpersonalization.saveSuccess": "Sauvegardé avec succès.",
  "ccmessaging.chat.chatpersonalization.specialStrValidateFailed": "La valeur ne peut pas contenir de caractères spéciaux.",
  "ccmessaging.chat.chatpersonalization.tips1": "La configuration soumise n'a pas été vérifiée !",
  "ccmessaging.chat.chatpersonalization.welcome": "Salutations",
  "ccmessaging.chat.chatpersonalization.welcomeLimit": "Un maximum de quatre salutations personnalisées peuvent être configurées.",
  "ccmessaging.chat.chatpersonalization.welcomeText": "Salutations personnalisées",
  "ccmessaging.chat.chatpersonalization.welcometime": "Plage de temps",
  "ccmessaging.chat.chatpersonalization.welcometimeerror": "Entrez un segment de temps normal!",
  "ccmessaging.chat.chatpersonalization.welcometimeerror.nolist": "Veuillez définir une salutation personnalisée!",
  "ccmessaging.chat.chatpersonalization.welcometimeerror.startGreaterThanEnd": "L'heure de début des salutations personnalisées ne peut pas être supérieure ou égale à l'heure de fin!",
  "ccmessaging.chat.chatpersonalization.welcometimeerror.timeOverlap": "Les périodes de salutations personnalisées ne peuvent pas se chevaucher!",
  "ccmessaging.chat.chinese.separator": ":",
  "ccmessaging.chat.clickCallClient.denial": "Le lien a expiré. Si nécessaire, contactez à nouveau l'agent pour envoyer le lien.",
  "ccmessaging.chat.close": "secondes après la fermeture automatique de la page",
  "ccmessaging.chat.connnect.offlineuser.failed": "Impossible d'initier un appel hors ligne en tant qu'agent.",
  "ccmessaging.chat.connnect.offlineuser.userId.beyondMaxLength": "UserId a plus de 128 ans",
  "ccmessaging.chat.contact.callin": "Appel entrant",
  "ccmessaging.chat.contact.callinandcallout": "Appel entrant et sortant",
  "ccmessaging.chat.contact.callout": "Appel sortant",
  "ccmessaging.chat.email.AgentAccountName": "Compte de l'agent",
  "ccmessaging.chat.email.agentNumber": "ID de l'agent",
  "ccmessaging.chat.email.autosave": "Vos modifications ont été automatiquement enregistrées dans un brouillon.",
  "ccmessaging.chat.email.button.advancedSearch": "Recherche avancée",
  "ccmessaging.chat.email.button.batchAssignment": "Affectation par lots",
  "ccmessaging.chat.email.button.bulkMove": "Déplacement en vrac",
  "ccmessaging.chat.email.card.reply": "Répondre",
  "ccmessaging.chat.email.channel.attendeeError": "Le nombre de destinataires Cc dépasse la limite.",
  "ccmessaging.chat.email.channel.BccSenderError": "Le nombre de bénéficiaires de BCC dépasse la limite.",
  "ccmessaging.chat.email.channel.ccandbcc.containchannelemail": "Le destinataire de la copie et le destinataire de la copie ne peuvent pas être l'adresse e-mail publique du canal.",
  "ccmessaging.chat.email.channel.confdeletedraft": "Êtes-vous sûr de vouloir supprimer le brouillon d'e-mail?",
  "ccmessaging.chat.email.channel.confdmodifydraft": "Êtes-vous sûr d'avoir traité l'e-mail?",
  "ccmessaging.chat.email.channel.deletedraft": "Confirmer",
  "ccmessaging.chat.email.channel.deletedraftfail": "Impossible de supprimer le brouillon d'e-mail.",
  "ccmessaging.chat.email.channel.deletedraftsuccess": "Le brouillon d'e-mail est supprimé avec succès.",
  "ccmessaging.chat.email.channel.draftOverMax": "Le nombre de brouillons d'e-mails dépasse le maximum.",
  "ccmessaging.chat.email.channel.emailaddressinvalid": "L'adresse e-mail n'est pas valide.",
  "ccmessaging.chat.email.channel.modifydraft": "Confirmer",
  "ccmessaging.chat.email.channel.modifydraftfail": "Échec de la modification.",
  "ccmessaging.chat.email.channel.modifydraftsuccess": "Modification réussie.",
  "ccmessaging.chat.email.channel.paramisempty": "Le paramètre ne peut pas être vide.",
  "ccmessaging.chat.email.channel.paramiserror": "Le paramètre n'est pas valide.",
  "ccmessaging.chat.email.channel.receiveGateway": "Passerelle de réception d'e-mails",
  "ccmessaging.chat.email.channel.receivererror": "Le destinataire ne contient pas l'utilisateur sélectionné.",
  "ccmessaging.chat.email.channel.savedraftfail": "Impossible d'enregistrer l'e-mail en tant que brouillon.",
  "ccmessaging.chat.email.channel.savedraftsuccess": "L'e-mail est enregistré en tant que brouillon avec succès.",
  "ccmessaging.chat.email.channel.sendGateway": "Passerelle d'envoi d'e-mails",
  "ccmessaging.chat.email.channel.title.reply": "Réponse :",
  "ccmessaging.chat.email.check.containGatewayEmail": "N'envoyez pas d'e-mails aux canaux et ne recevez pas d'adresses e-mail.",
  "ccmessaging.chat.email.check.contentOverMax": "La taille du corps du message dépasse la limite",
  "ccmessaging.chat.email.check.localImageNumberOverMax": "Le nombre d'images locales téléchargées dans le corps de l'e-mail dépasse le maximum.",
  "ccmessaging.chat.email.check.localImageOverMax": "La taille des images locales téléchargées dans le corps de l'e-mail dépasse le maximum.",
  "ccmessaging.chat.email.check.localImageType": "Format d'image non pris en charge.",
  "ccmessaging.chat.email.check.uploadATTACHMENTPermissionAndFeature": "Aucune autorisation pour télécharger des pièces jointes, ou cette fonction n'est pas activée.",
  "ccmessaging.chat.email.check.uploadImagePermissionAndFeature": "Aucune autorisation pour télécharger des images locales, ou cette fonction n'est pas activée.",
  "ccmessaging.chat.email.forward": "En avant",
  "ccmessaging.chat.email.forward.prefix": "En avant :",
  "ccmessaging.chat.email.localImage.confirm": "Confirmer",
  "ccmessaging.chat.email.localImage.height": "Hauteur",
  "ccmessaging.chat.email.localImage.lable": "Fichier",
  "ccmessaging.chat.email.localImage.width": "Largeur",
  "ccmessaging.chat.email.param.empty": "Le titre ou le contenu de l'e-mail ne peut pas être vide.",
  "ccmessaging.chat.email.reply": "Répondre",
  "ccmessaging.chat.email.reply.separator": ":",
  "ccmessaging.chat.email.tips.addFailDuplicateName": "L'ajout a échoué : nom dupliqué.",
  "ccmessaging.chat.email.tips.addFailOtherErrors": "L'ajout a échoué : Autres erreurs.",
  "ccmessaging.chat.email.tips.addMaximumLimit": "Un maximum de 20 sous-répertoires au même niveau.",
  "ccmessaging.chat.email.tips.addSuccess": "Ajouter Succeeded.",
  "ccmessaging.chat.email.tips.amendFailDuplicateName": "Échec de la modification : nom dupliqué.",
  "ccmessaging.chat.email.tips.amendFailOtherErrors": "Échec de la modification : Autres erreurs.",
  "ccmessaging.chat.email.tips.amendSuccess": "La modification a réussi.",
  "ccmessaging.chat.email.tips.deleteFailExistEmail": "Échec de la suppression : les messages existent dans le répertoire courant.",
  "ccmessaging.chat.email.tips.deleteFailExistSub": "Échec de la suppression : le répertoire courant contient des sous-répertoires.",
  "ccmessaging.chat.email.tips.deleteFailOtherErrors": "Échec de la suppression : Autres erreurs.",
  "ccmessaging.chat.email.tips.deleteSuccess": "Supprimé avec succès.",
  "ccmessaging.chat.email.tips.isDeleteDirectory": "Voulez -vous supprimer le répertoire courant répertoire?",
  "ccmessaging.chat.emailmessage.abandoned": "Abandonné",
  "ccmessaging.chat.emailmessage.agent": "Agent",
  "ccmessaging.chat.emailmessage.all": "Tous",
  "ccmessaging.chat.emailmessage.assigned": "Attribué",
  "ccmessaging.chat.emailmessage.assignEmail.inputAgentName": "Saisissez le nom du compte de l'agent.",
  "ccmessaging.chat.emailmessage.assignEmail.queryAgentName": "Entrez un compte d'entreprise.",
  "ccmessaging.chat.emailmessage.assignEmailFailed": "Impossible d'assigner l'e-mail.",
  "ccmessaging.chat.emailmessage.assignEmailPartialSuccess": "Impossible d'assigner certains e-mails.",
  "ccmessaging.chat.emailmessage.assignEmailSuccess": "L'e-mail est attribué avec succès.",
  "ccmessaging.chat.emailmessage.assigning": "Attribuer",
  "ccmessaging.chat.emailmessage.assignOtherEmailFailed": "L'e-mail de l'expéditeur ne peut pas être attribué car il est en cours de traitement.",
  "ccmessaging.chat.emailmessage.assignStatus": "Statut de l'affectation",
  "ccmessaging.chat.emailmessage.channel": "Réponse automatique du canal",
  "ccmessaging.chat.emailmessage.channelAccessCode": "Code d'accès au canal de messagerie",
  "ccmessaging.chat.emailmessage.disAssigned": "Non assigné",
  "ccmessaging.chat.emailmessage.fail.assign.AbandonedEmail.assigning.tip": "Les messages supprimés ne peuvent pas être attribués.",
  "ccmessaging.chat.emailmessage.fail.assign.AbandonedEmail.moving.tip": "Les messages supprimés ne peuvent pas être déplacés.",
  "ccmessaging.chat.emailmessage.fail.assign.UnAssignedEmail.tip": "Les courriers non attribués ne peuvent pas être attribués.",
  "ccmessaging.chat.emailmessage.handler": "Gestionnaire",
  "ccmessaging.chat.emailmessage.handlerType": "Type de gestionnaire",
  "ccmessaging.chat.emailmessage.handleStatus": "Statut de traitement",
  "ccmessaging.chat.emailmessage.moving": "Déplacer",
  "ccmessaging.chat.emailmessage.operation.noMoreThanOneDay": "La plage de temps de requête ne peut pas dépasser un jour.",
  "ccmessaging.chat.emailmessage.operation.noMoreThanSevenDay": "L'intervalle de temps d'interrogation ne peut pas dépasser sept jours.",
  "ccmessaging.chat.emailmessage.receiver": "Destinataire",
  "ccmessaging.chat.emailmessage.robot": "Robot",
  "ccmessaging.chat.emailmessage.sender": "Expéditeur",
  "ccmessaging.chat.emailmessage.sendStatus": "Envoi du résultat",
  "ccmessaging.chat.emailmessage.sendTime": "Envoyé le",
  "ccmessaging.chat.emailmessage.skillQueue": "File d'attente de compétences",
  "ccmessaging.chat.emailmessage.title": "Email Title",
  "ccmessaging.chat.emailmessage.unassigned": "À assigner",
  "ccmessaging.chat.EMOTICONS.beyondFileSize": "Le nombre de fichiers multimédia téléchargés de type est une erreur ou dépasse le maximum : {n} KB!",
  "ccmessaging.chat.facebookpageconfig.add": "Ajouter la page d'accueil de Facebook",
  "ccmessaging.chat.facebookpageconfig.config": "Configuration",
  "ccmessaging.chat.facebookpageconfig.deleteError": "Impossible de supprimer les paramètres de la page d'accueil Facebook.",
  "ccmessaging.chat.facebookpageconfig.deleteSuccess": "Les paramètres de la page d'accueil de Facebook sont supprimés avec succès.",
  "ccmessaging.chat.facebookpageconfig.facebookconfig": "Paramètres de la page d'accueil de Facebook",
  "ccmessaging.chat.facebookpageconfig.idNotExist": "Les données n'existent pas.",
  "ccmessaging.chat.facebookpageconfig.isDeleteFacebookPage": "Êtes-vous sûr de vouloir supprimer les paramètres de la page d'accueil de Facebook?",
  "ccmessaging.chat.facebookpageconfig.modify": "Modifier la page d'accueil de Facebook",
  "ccmessaging.chat.facebookpageconfig.modifyError": "Impossible de modifier la page d'accueil de Facebook.",
  "ccmessaging.chat.facebookpageconfig.modifySuccess": "La page d'accueil de Facebook est modifiée avec succès.",
  "ccmessaging.chat.facebookpageconfig.numoversize": "Le nombre de pages d'accueil Facebook dépasse la limite supérieure de ce canal.",
  "ccmessaging.chat.facebookpageconfig.pageId": "ID de page",
  "ccmessaging.chat.facebookpageconfig.pageidexist": "L'ID de page d'accueil de Facebook existe déjà.",
  "ccmessaging.chat.facebookpageconfig.saveError": "Impossible d'ajouter la page d'accueil de Facebook.",
  "ccmessaging.chat.facebookpageconfig.saveSuccess": "La page d'accueil de Facebook a été ajoutée avec succès.",
  "ccmessaging.chat.facebookpageconfig.tokenSecret": "Clé de jeton",
  "ccmessaging.chat.fivegCard": "Carte 5G",
  "ccmessaging.chat.ifame.param": "Paramètre",
  "ccmessaging.chat.iframeagentconsole.mail": "Courriel",
  "ccmessaging.chat.iframeagentconsole.mailCollapse": "encaissé",
  "ccmessaging.chat.iframeagentconsole.mailExpand": "Détails",
  "ccmessaging.chat.label.accessCode": "Code d'accès",
  "ccmessaging.chat.label.extCode": "Code d'extension",
  "ccmessaging.chat.label.skill": "File d'attente de compétences",
  "ccmessaging.chat.localFile.authFail": "Aucune autorisation d'envoi de fichier local",
  "ccmessaging.chat.multi.card": "Carte tournante 5G",
  "ccmessaging.chat.multi.card.add": "Sélectionner la carte",
  "ccmessaging.chat.multi.card.id": "ID de carte",
  "ccmessaging.chat.multi.card.num.error": "Ajoutez 1 à 5 cartes individuelles.",
  "ccmessaging.chat.multi.chatcard.alignment": "Mode d'alignement d'image",
  "ccmessaging.chat.multi.chatcard.alignment.left": "Alignement à gauche",
  "ccmessaging.chat.multi.chatcard.alignment.right": "Alignement à droite",
  "ccmessaging.chat.multi.chatcard.choose": "Sélection de la carte",
  "ccmessaging.chat.multi.chatcard.delete": "Le modèle a été référencé et ne peut pas être supprimé. La carte NVOD référencée est :",
  "ccmessaging.chat.multi.chatcard.sort": "Direction d'arrangement de carte",
  "ccmessaging.chat.multi.chatcard.sort.flow": "flotteurs",
  "ccmessaging.chat.multi.chatcard.sort.horizontal": "nivellement",
  "ccmessaging.chat.multi.chatcard.sort.vertical": "perpendiculaire",
  "ccmessaging.chat.multi.chatcard.width": "Largeur de la carte",
  "ccmessaging.chat.multi.chatcard.width.medium": "Largeur moyenne",
  "ccmessaging.chat.multi.chatcard.width.small": "Plus petite largeur",
  "ccmessaging.chat.multi.media.resource.invalid": "Cartes faisant référence à des ressources multimédias non valides. Nom de la carte :",
  "ccmessaging.chat.multimedia.addCategory": "Ajouter une catégorie",
  "ccmessaging.chat.multimedia.audioManagement": "Bibliothèque audio",
  "ccmessaging.chat.multimedia.audioUploadTipFormat": "Le fichier audio à transférer doit être en format MP3, AAC ou M4A et la taille de fichier ne peut excéder {n} Mo.",
  "ccmessaging.chat.multimedia.beyondFileSize": "La taille du fichier multimédia téléchargé dépasse la limite supérieure : {n} MB!",
  "ccmessaging.chat.multimedia.cancel": "Annuler",
  "ccmessaging.chat.multimedia.cardManagement": "Modèle de carte",
  "ccmessaging.chat.multimedia.categoryName": "Nom de la catégorie",
  "ccmessaging.chat.multimedia.chatPhraseManagement": "Bibliothèque ChatPhrase",
  "ccmessaging.chat.multimedia.cobrowseSite": "Site de Cobrowse",
  "ccmessaging.chat.multimedia.createRichText": "Créer un texte riche",
  "ccmessaging.chat.multimedia.delete": "Supprimer",
  "ccmessaging.chat.multimedia.deleteLimit": "La ressource est référencée par le modèle de carte {0} et ne peut pas être supprimée.",
  "ccmessaging.chat.multimedia.deleteLimit.usedbyODFS": "La ressource a été référencée par l'IVR intelligente et ne peut pas être supprimée",
  "ccmessaging.chat.multimedia.deleteLimit.whatsapp.templatemsg": "La ressource ne peut pas être supprimée car elle est déjà référencée par un message de modèle WhatsApp.Message de modèle:",
  "ccmessaging.chat.multimedia.deleteMultiMediaInfo": "Supprimer ces données multimédia",
  "ccmessaging.chat.multimedia.deleteMultiMediaInfoError": "Impossible de supprimer les données multimédias !",
  "ccmessaging.chat.multimedia.deleteMultiMediaInfoSuccess": "Supprimer les données multimédia avec succès!",
  "ccmessaging.chat.multimedia.deleteMultiMediaType": "Supprimer cette catégorie multimédia",
  "ccmessaging.chat.multimedia.deleteMultiMediaTypeError": "Impossible de supprimer la catégorie de bibliothèque multimédia!",
  "ccmessaging.chat.multimedia.deleteMultiMediaTypeError.usedbyODFS": "Impossible de supprimer la catégorie de la bibliothèque multimédia. La ressource est référencée par l'IVR intelligente.",
  "ccmessaging.chat.multimedia.deleteMultiMediaTypeError.referenced.byCard": "Impossible de supprimer la catégorie multimédia car certaines ressources sont déjà référencées par les cartes.",
  "ccmessaging.chat.multimedia.deleteMultiMediaTypeError.referenced.byWhatsAppTemplateMsg": "Impossible de supprimer la catégorie multimédia car certaines ressources sont déjà référencées par un message de modèle WhatsApp.",
  "ccmessaging.chat.multimedia.deleteMultiMediaTypeSuccess": "Supprimer avec succès catégorie multimédia!",
  "ccmessaging.chat.multimedia.description": "Descriptif",
  "ccmessaging.chat.multimedia.descriptionContainsSpecialCharacters": "La description contient des caractères spéciaux.",
  "ccmessaging.chat.multimedia.descriptionSuperLong": "La longueur de la description dépasse le maximum.",
  "ccmessaging.chat.multimedia.documentManagement": "Bibliothèque de documents",
  "ccmessaging.chat.multimedia.documentUploadTipFormat": "Les fichiers peuvent être téléchargés au format word,pdf,ppt,excel ou zip. Le fichier zip prend en charge les formats word,pdf,ppt et excel. La taille du fichier ne peut pas dépasser {n} MB.",
  "ccmessaging.chat.multimedia.download": "Téléchargement",
  "ccmessaging.chat.multimedia.edit": "Modifier",
  "ccmessaging.chat.multimedia.emoticonsManagement": "Émoticône",
  "ccmessaging.chat.multimedia.emoticonsUploadTipFormat": "Le fichier d'émoticônes personnalisé téléchargé peut être au format JPG, JPEG, PNG ou GIF et la taille du fichier ne peut pas dépasser {n} KB.",
  "ccmessaging.chat.multimedia.exceedFileSize": "Le nombre de fichiers multimédia téléchargés de type est une erreur ou dépasse le maximum : {n}!",
  "ccmessaging.chat.multimedia.fileName": "Nom du fichier",
  "ccmessaging.chat.multimedia.fileSize": "Taille du fichier",
  "ccmessaging.chat.multiMedia.fileUploadError": "Le fichier téléchargé a le mauvais format",
  "ccmessaging.chat.multiMedia.fileUploadInjectionRisk": "Le téléchargement présente des risques d'ingestion. Vérifiez le contenu et réessayez.",
  "ccmessaging.chat.multimedia.fileUploadLabel": "Veuillez sélectionner un fichier à télécharger",
  "ccmessaging.chat.multiMedia.fileUploadNameError": "Le format de nommage du fichier téléchargé est incorrect.",
  "ccmessaging.chat.multiMedia.fileUploadTypeError": "Le format du fichier téléchargé est incorrect.",
  "ccmessaging.chat.multimedia.finish": "Finition",
  "ccmessaging.chat.multimedia.getRichTextInfoError": "Impossible d'obtenir des données de texte enrichi.",
  "ccmessaging.chat.multimedia.id": "ID",
  "ccmessaging.chat.multimedia.imageManagement": "Bibliothèque d'images",
  "ccmessaging.chat.multimedia.imageUploadTipFormat": "Le fichier image téléchargé ne prend actuellement en charge que les formats jpg, png, jpeg, et la taille du fichier ne doit pas dépasser {n}M.",
  "ccmessaging.chat.multimedia.isDeleteMultiMediaInfo": "Êtes-vous sûr de vouloir supprimer ces données multimédia?",
  "ccmessaging.chat.multimedia.isDeleteMultiMediaType": "Êtes-vous sûr de vouloir supprimer le type multimédia et ses données multimédia?",
  "ccmessaging.chat.multimedia.latitude": "Latitude",
  "ccmessaging.chat.multimedia.latitudeValidateFailed": "La partie entière est -90~90, et la partie décimale est de 0 à 6 chiffres !",
  "ccmessaging.chat.multimedia.locationManagement": "Bibliothèque d'adresses",
  "ccmessaging.chat.multimedia.longitude": "Longitude",
  "ccmessaging.chat.multimedia.longitudeValidateFailed": "La partie entière est -180~180, et la partie décimale est de 0 à 6 chiffres !",
  "ccmessaging.chat.multimedia.mediaFileType": "FileType des médias",
  "ccmessaging.chat.multimedia.modifyCategory": "Modifier la catégorie",
  "ccmessaging.chat.multimedia.modifyMultiMediaInfo": "Modifiez avec succès les données multimédias!",
  "ccmessaging.chat.multimedia.modifyMultiMediaInfoError": "Impossible de modifier les données multimédias !",
  "ccmessaging.chat.multimedia.modifyMultiMediaType": "Modifiez avec succès la catégorie multimédia!",
  "ccmessaging.chat.multimedia.modifyMultiMediaTypeError": "Impossible de modifier la catégorie de la bibliothèque multimédia !",
  "ccmessaging.chat.multimedia.modifyRichText": "Modifier le texte riche",
  "ccmessaging.chat.multimedia.multiMediaInfoInputEmpty": "Les données d'entrée multimédia sont vides !",
  "ccmessaging.chat.multimedia.multiMediaInfoInputInvalid": "Les données d'entrée multimédia ne sont pas valides !",
  "ccmessaging.chat.multimedia.multiMediaManagement": "Paramètres de la bibliothèque multimédia",
  "ccmessaging.chat.multimedia.multiMediaNameExist": "Le nom des données multimédia existe déjà dans cette catégorie!",
  "ccmessaging.chat.multimedia.multiMediaTypeIdNotExist": "La catégorie de bibliothèque multimédia sélectionnée n'existe pas, veuillez sélectionner la catégorie de bibliothèque multimédia!",
  "ccmessaging.chat.multimedia.multiMediaTypeInputEmpty": "Les données d'entrée de catégorie multimédia sont vides !",
  "ccmessaging.chat.multimedia.multiMediaTypeInputInvalid": "Les données d'entrée de catégorie multimédia ne sont pas valides !",
  "ccmessaging.chat.multimedia.multiMediaTypeNameExist": "Le nom de la catégorie multimédia existe déjà !",
  "ccmessaging.chat.multimedia.name": "Nom et prénom",
  "ccmessaging.chat.multimedia.nameContainsSpecialCharacters": "Le nom contient des caractères spéciaux.",
  "ccmessaging.chat.multimedia.nameSuperLong": "La longueur du nom dépasse le maximum.",
  "ccmessaging.chat.multimedia.operating": "Fonctionnement",
  "ccmessaging.chat.multimedia.play": "Jouer",
  "ccmessaging.chat.multimedia.preview": "Aperçu",
  "ccmessaging.chat.multimedia.richTextContentEmpty": "Le contenu du texte enrichi est vide.",
  "ccmessaging.chat.multimedia.richTextContentLongLimit": "Le contenu de texte riche peut contenir un maximum de 5000 caractères.",
  "ccmessaging.chat.multimedia.richTextContentSuperLong": "Le texte riche peut contenir un maximum de 5000 caractères.",
  "ccmessaging.chat.multimedia.richTextManagement": "Texte riche",
  "ccmessaging.chat.multimedia.richTextTypeEmpty": "La catégorie texte enrichi n'existe pas.",
  "ccmessaging.chat.multimedia.categorizeEmpty": "Sélectionnez ou créez d'abord une catégorie.",
  "ccmessaging.chat.multimedia.saveMultiMediaInfo": "Sauvegarde des données multimédias avec succès!",
  "ccmessaging.chat.multimedia.saveMultiMediaInfoError": "Impossible d'enregistrer les données multimédias!",
  "ccmessaging.chat.multimedia.saveMultiMediaType": "Catégorie multimédia enregistrée avec succès!",
  "ccmessaging.chat.multimedia.saveMultiMediaTypeError": "Impossible d'enregistrer la catégorie de bibliothèque multimédia!",
  "ccmessaging.chat.multimedia.selectFile": "Sélectionner un fichier",
  "ccmessaging.chat.multimedia.selectImage": "Sélectionner une image",
  "ccmessaging.chat.multimedia.upload": "Téléchargement",
  "ccmessaging.chat.multimedia.uploadTime": "Temps de téléchargement",
  "ccmessaging.chat.multimedia.videoManagement": "Vidéothèque",
  "ccmessaging.chat.multimedia.videoUploadTipFormat": "Le fichier vidéo téléchargé ne prend actuellement en charge que le format mp4, et la taille du fichier ne doit pas dépasser {n}M.",
  "ccmessaging.chat.multimedia.viewRichText": "Afficher le texte enrichi",
  "ccmessaging.chat.multimedia.whatsAppTemplate": "Modèle WhatsApp",
  "ccmessaging.chat.multimediaasccociation.approvalstatus": "Statut de l'examen",
  "ccmessaging.chat.multimediaasccociation.checkmediainfofailed": "Les données multimédia n'ont pas été diffusées sur le canal 5G associé ou n'ont pas été approuvées!",
  "ccmessaging.chat.multimediaasccociation.deleteassociation": "Supprimer le résultat de la publication",
  "ccmessaging.chat.multimediaasccociation.deleteassociationinfo": "Êtes-vous sûr de vouloir supprimer le résultat de la publication pour les données multimédia et le canal 5G associé?",
  "ccmessaging.chat.multimediaasccociation.deleteError": "Impossible de supprimer le résultat de la publication !",
  "ccmessaging.chat.multimediaasccociation.deleteSuccess": "Le résultat de la publication est supprimé avec succès!",
  "ccmessaging.chat.multimediaasccociation.examinationfailed": "Échec",
  "ccmessaging.chat.multimediaasccociation.examinationpassed": "Approuvé",
  "ccmessaging.chat.multimediaasccociation.expirationtime": "Expiré à",
  "ccmessaging.chat.multimediaasccociation.isrelease": "Êtes-vous sûr de vouloir libérer les données multimédia sur le canal 5G?",
  "ccmessaging.chat.multimediaasccociation.refresh": "Actualiser",
  "ccmessaging.chat.multimediaasccociation.refreshfailed": "Impossible d'actualiser les données !",
  "ccmessaging.chat.multimediaasccociation.refreshsuccess": "Les données sont rafraîchies avec succès!",
  "ccmessaging.chat.multimediaasccociation.release": "libération",
  "ccmessaging.chat.multimediaasccociation.releaseerror": "Impossible de libérer les données multimédias!",
  "ccmessaging.chat.multimediaasccociation.releaseresult": "Résultat de la publication",
  "ccmessaging.chat.multimediaasccociation.releasesuccess": "Les données multimédias sont publiées avec succès!",
  "ccmessaging.chat.multimediaasccociation.releasetitle": "Publication de données multimédias",
  "ccmessaging.chat.multimediaasccociation.unpublishconfigempty": "Le canal 5G où les données multimédia doivent être libérées n'est pas configuré.",
  "ccmessaging.chat.multimediaasccociation.unreviewed": "Non examiné",
  "ccmessaging.chat.offlineCall.channelType": "Type de canal",
  "ccmessaging.chat.offlineCall.select.noChannel": "Le canal n'existe pas.",
  "ccmessaging.chat.offlineCall.userId": "ID utilisateur",
  "ccmessaging.chat.offlineSwitch.closed": "L'envoi a échoué car la fonction de message hors ligne est désactivée pour le canal.",
  "ccmessaging.chat.phrase.add": "Ajouter",
  "ccmessaging.chat.phrase.addPhraseError": "Échec de la création d'une phrase commune",
  "ccmessaging.chat.phrase.addPhraseSuccess": "Créer une phrase commune avec succès",
  "ccmessaging.chat.phrase.addType": "Ajouter une catégorie",
  "ccmessaging.chat.phrase.AllCount": "Total des mots",
  "ccmessaging.chat.phrase.cancel": "Annuler",
  "ccmessaging.chat.phrase.currentCount": "Nombre de mots actuels",
  "ccmessaging.chat.phrase.delete": "Supprimer",
  "ccmessaging.chat.phrase.deleteType": "Supprimer le type",
  "ccmessaging.chat.phrase.delPhraseError": "Échec suppression phrase commune",
  "ccmessaging.chat.phrase.delPhraseSucess": "Supprimer l'expression commune avec succès",
  "ccmessaging.chat.phrase.delPhraseTypeError": "Échec de la suppression du type de phrase commun",
  "ccmessaging.chat.phrase.delPhraseTypeSuccess": "Supprimer le type de phrase commun avec succès",
  "ccmessaging.chat.phrase.edit": "Modifier",
  "ccmessaging.chat.phrase.editType": "Modifier le type",
  "ccmessaging.chat.phrase.enter_keyword": "Saisissez un mot-clé.",
  "ccmessaging.chat.phrase.enterPhrase": "Veuillez entrer une phrase commune",
  "ccmessaging.chat.phrase.enterPhraseType": "Veuillez entrer un type de phrase commun",
  "ccmessaging.chat.phrase.error": "Erreur",
  "ccmessaging.chat.phrase.isDeletePhrase": "Êtes-vous sûr de vouloir supprimer cette phrase",
  "ccmessaging.chat.phrase.isDeletePhraseType": "Êtes-vous sûr de vouloir supprimer le type de phrase commun et ses phrases communes ?",
  "ccmessaging.chat.phrase.isSavePhrase": "Êtes-vous sûr de vouloir sauvegarder l'expression courante ?",
  "ccmessaging.chat.phrase.isSavePhraseType": "Êtes-vous sûr de vouloir enregistrer le type de phrase courant ?",
  "ccmessaging.chat.phrase.phrase": "Phrase",
  "ccmessaging.chat.phrase.phraseList": "Liste des phrases",
  "ccmessaging.chat.phrase.phraseRepeat": "Dupliquer le nom commun",
  "ccmessaging.chat.phrase.phraseType": "Type de phrase",
  "ccmessaging.chat.phrase.phraseTypeEmpty": "Le type de phrase commun à supprimer n'existe pas. Veuillez sélectionner un type de phrase commun.",
  "ccmessaging.chat.phrase.phraseTypeRepeat": "La catégorie de phrase commune est dupliquée",
  "ccmessaging.chat.phrase.phraseTypeLimit": "Le nombre de types de phrases courants atteint la limite supérieure de 100.",
  "ccmessaging.chat.phrase.prompt": "Inviter",
  "ccmessaging.chat.phrase.save": "Enregistrer",
  "ccmessaging.chat.phrase.saveError": "L'enregistrement a échoué",
  "ccmessaging.chat.phrase.saveType": "Enregistrer le type",
  "ccmessaging.chat.phrase.saveTypeSuccess": "Enregistrer la catégorie de langue commune avec succès",
  "ccmessaging.chat.phrase.shortcut": "Touches de raccourci",
  "ccmessaging.chat.phrase.success": "Succès",
  "ccmessaging.chat.phrase.updatePhraseTypeError": "Impossible de mettre à jour le type de phrase commun.",
  "ccmessaging.chat.phrase.updatePhraseTypeSucess": "Réussi à mettre à jour le type de phrase commun.",
  "ccmessaging.chat.phrase.updateError": "Erreur de mise à jour",
  "ccmessaging.chat.phrase.updatePhraseError": "Échec de la mise à jour de la langue commune",
  "ccmessaging.chat.phrase.updatePhraseSucess": "Mettre à jour la langue commune avec succès",
  "ccmessaging.chat.phrase.updateSuccess": "Succès de mise à jour",
  "ccmessaging.chat.portraitchange.customize": "Personnalisé",
  "ccmessaging.chat.portraitchange.systemAvatar": "Système avatar",
  "ccmessaging.chat.querymessage": "Interroger le message d'historique du média",
  "ccmessaging.chat.refresh.fail": "Échec du rafraîchissement",
  "ccmessaging.chat.repeatClick": "Ne cliquez pas de manière répétitive !",
  "ccmessaging.chat.richText.add": "Créer",
  "ccmessaging.chat.richText.cancel": "Annuler",
  "ccmessaging.chat.richText.idInput": "Entrez un ID.",
  "ccmessaging.chat.richText.nameInput": "Entrez un nom.",
  "ccmessaging.chat.richText.openInNewWindow": "Ouvrir dans une nouvelle fenêtre",
  "ccmessaging.chat.richText.save": "Enregistrer",
  "ccmessaging.chat.selectchannel.vaildate1": "Le code d'accès au canal doit être unique.",
  "ccmessaging.chat.sessiontransfer.tips": "Seuls les agents inactifs et les agents dont les appels simultanés n'atteignent pas la limite supérieure peuvent être transférés",
  "ccmessaging.chat.silentagent.aceesscode.empty": "Le code d'accès ne peut pas être nul!",
  "ccmessaging.chat.silentagent.config.accesscode.same": "La configuration de l'agent silencieux du configuração da rota de configuration de la route audio IVR existe déjà dans le bail actuel",
  "ccmessaging.chat.silentagent.config.del.error": "Impossible de supprimer la configuration de l'agent silencieux vocal",
  "ccmessaging.chat.silentagent.config.del.success": "La suppression de la configuration de l'agent silencieux vocal a réussi",
  "ccmessaging.chat.silentagent.config.willdel": "Voulez -vous vraiment supprimer la configuration de l'agent silencieux vocal sélectionnée?",
  "ccmessaging.chat.silentagent.DeleteFail": "Impossible de supprimer la configuration de l'agent silencieux",
  "ccmessaging.chat.silentagent.DeleteSuccess": "La suppression de la configuration de l'agent silencieux a réussi",
  "ccmessaging.chat.silentagent.sendContent.noOIAPNode": "Silent Agent ne peut pas obtenir les informations du nœud actuel",
  "ccmessaging.chat.silentagent.sendContent.supervise": "Silent Agent ne peut pas envoyer de message dans le workMode de supervision",
  "ccmessaging.chat.silentagent.silent.skill.empty": "La file d'attente de compétences de l'agent silencieux ne peut pas être vide",
  "ccmessaging.chat.silentAgent.workMode.changeWorkMode.fail": "Le mode de travail du changement silencieuxAgent échoue ",
  "ccmessaging.chat.silentAgent.workMode.changeWorkMode.prompt": "Astuce",
  "ccmessaging.chat.silentAgent.workMode.changeWorkMode.success": "Le succès du mode workMode de changement silencieuxAgent",
  "ccmessaging.chat.silentAgent.workMode.common": "Fréquent",
  "ccmessaging.chat.silentAgent.workMode.insert": "Insérer",
  "ccmessaging.chat.silentAgent.workMode.supervise": "superviser",
  "ccmessaging.chat.silentAgent.workMode.transfer": "Transfert",
  "ccmessaging.chat.single.media.resource.invalid": "Cartes faisant référence à des ressources multimédias non valides",
  "ccmessaging.chat.sms.channel.gateway": "Passerelle SMS",
  "ccmessaging.chat.social.media.enterprise.default.username": "Utilisateur anonyme",
  "ccmessaging.chat.social.media.enterprise.manage": "Traitement des messages de compte d'entreprise",
  "ccmessaging.chat.social.media.enterprise.post.add.failed": "échec de l'ajout",
  "ccmessaging.chat.social.media.enterprise.post.add.success": "L'ajout a réussi.",
  "ccmessaging.chat.social.media.enterprise.post.all.reply": "Répondre à tout",
  "ccmessaging.chat.social.media.enterprise.post.batch.reply": "Réponse par lots",
  "ccmessaging.chat.social.media.enterprise.post.cancel": "Annuler",
  "ccmessaging.chat.social.media.enterprise.post.comment": "Commentaire",
  "ccmessaging.chat.social.media.enterprise.post.comment.tips": "Ajouter un commentaire...",
  "ccmessaging.chat.social.media.enterprise.post.reply": "Répondre",
  "ccmessaging.chat.social.media.enterprise.post.reply.cancel": "Annuler",
  "ccmessaging.chat.social.media.enterprise.post.reply.tips": "Ajouter une réponse...",
  "ccmessaging.chat.social.media.enterprise.post.title": "Nom du poste",
  "ccmessaging.chat.social.media.enterprise.post.topcomment.cancel": "Annuler un commentaire",
  "ccmessaging.chat.social.media.enterprise.tips.close": "pliage",
  "ccmessaging.chat.social.media.enterprise.tips.imgnum": "Un maximum d'une image peut être ajouté.",
  "ccmessaging.chat.social.media.enterprise.tips.open": "Déplier",
  "ccmessaging.chat.social.media.multimedia.manage": "Traitement des messages multimédias",
  "ccmessaging.chat.speciallist.channels": "Canaux restreints",
  "ccmessaging.chat.speciallist.channelsType": "Type de canal restreint",
  "ccmessaging.chat.speciallist.choosechannel": "Sélectionner un canal",
  "ccmessaging.chat.speciallist.confirmdelete": "Êtes-vous sûr de supprimer?",
  "ccmessaging.chat.speciallist.delete": "Abandonné",
  "ccmessaging.chat.speciallist.deletelevel": "(Abandonné)",
  "ccmessaging.chat.speciallist.emailchannels": "Courriel",
  "ccmessaging.chat.speciallist.haverestrictall": "Tous les canaux de ce type de canal sont restreints. Pas besoin de restreindre ce canal.",
  "ccmessaging.chat.speciallist.haverestrictchannel": "Ce canal est restreint. Pas besoin de le restreindre à nouveau.",
  "ccmessaging.chat.speciallist.level": "Niveau de liste spéciale",
  "ccmessaging.chat.speciallist.leveName": "Liste spéciale Type Nom",
  "ccmessaging.chat.speciallist.msgchoosechannel": "Veuillez d'abord sélectionner un type de chaîne.",
  "ccmessaging.chat.speciallist.remark": "Descriptif",
  "ccmessaging.chat.speciallist.repeatlevel": "Le niveau de liste spéciale existe déjà. Ne le créez pas à nouveau.",
  "ccmessaging.chat.speciallist.restrictHours": "Durée restreinte",
  "ccmessaging.chat.speciallist.restrictUnit": "heure",
  "ccmessaging.chat.speciallist.restrictMode": "Mode de restriction",
  "ccmessaging.chat.speciallist.restrictStyle": "Mode d'application",
  "ccmessaging.chat.speciallist.selectchannel": "Canal désigné",
  "ccmessaging.chat.speciallist.smschannels": "SMS",
  "ccmessaging.chat.speciallist.unuse": "Non disponible",
  "ccmessaging.chat.speciallist.unuselevel": "(Non disponible)",
  "ccmessaging.chat.speciallist.use": "Disponible",
  "ccmessaging.chat.successaccess": "Accès réussi",
  "ccmessaging.chat.sumbitverification.Appld": "ID de l'application",
  "ccmessaging.chat.sumbitverification.AppSecret": "Clé",
  "ccmessaging.chat.sumbitverification.facebook.tips": "Accédez à la page développeur Facebook Messenger pour configurer le site Web et le code de vérification.",
  "ccmessaging.chat.sumbitverification.fiveG.tips": "Veuillez vous rendre sur la page Configuration de la plateforme de messages 5G pour configurer l'adresse du site Web.",
  "ccmessaging.chat.sumbitverification.line.tips": "Configurez l'URL Webhook dans le gestionnaire de compte officiel de la ligne.",
  "ccmessaging.chat.sumbitverification.tips": "Veuillez aller à WeChat informations de configuration de l'interface de la plate-forme publique pour l'URL de configuration et le code de vérification",
  "ccmessaging.chat.sumbitverification.tips.qr": "Dans ce mode d'autorisation, aucune configuration supplémentaire n'est requise. Passez à l'étape suivante.",
  "ccmessaging.chat.sumbitverification.Url": "URL",
  "ccmessaging.chat.sumbitverification.Url.infobipMessAgeUrl": "url utilisée pour envoyer un message à l'agent",
  "ccmessaging.chat.sumbitverification.Url.infobipReportUrl": "url utilisée pour envoyer le rapport à l'agent",
  "ccmessaging.chat.sumbitverification.VerityCode": "Code de vérification",
  "ccmessaging.chat.sumbitverification.whatsApp.fail": "Générer un échec",
  "ccmessaging.chat.sumbitverification.whatsApp.prompt": "Astuce",
  "ccmessaging.chat.sumbitverification.whatsApp.success": "Générer le succès",
  "ccmessaging.chat.sumbitverification.whatsApp.tips": "URL utilisée par le fournisseur de services WhatsApp pour traiter les messages",
  "ccmessaging.chat.system.error": "Le système est occupé. Veuillez réessayer plus tard.",
  "ccmessaging.chat.template.buttonSend": "Envoyer",
  "ccmessaging.chat.template.butttonName": "Sélectionner",
  "ccmessaging.chat.template.card": "Message de modèle WhatsApp",
  "ccmessaging.chat.template.Description": "Descriptif",
  "ccmessaging.chat.template.languageCode": "Langue",
  "ccmessaging.chat.template.name": "Modèle Nom du message",
  "ccmessaging.chat.template.namePlaceholder": "Saisissez un nom de message.",
  "ccmessaging.chat.template.table.button": "Bouton",
  "ccmessaging.chat.template.table.buttonPlaceholder": "Réponse rapide du bouton.",
  "ccmessaging.chat.template.table.no": "Non.",
  "ccmessaging.chat.template.table.parameterContent": "Contenu des paramètres",
  "ccmessaging.chat.template.table.parameterDescription": "Description du paramètre",
  "ccmessaging.chat.template.table.parameterPlaceholder": "Entrez un paramètre.",
  "ccmessaging.chat.template.whatsAppChannel": "Canal",
  "ccmessaging.chat.template.whatsAppTemplateName": "Nom du modèle",
  "ccmessaging.chat.templateMsg.chooseMultimedia": "Sélectionnez une ressource multimédia.",
  "ccmessaging.chat.templateMsg.sendTemplateMsg": "Envoyer",
  "ccmessaging.chat.templateMsg.sendTemplateMsgTips": "Envoyer un message de modèle",
  "ccmessaging.chat.tinymce.button.clear": "Vider",
  "ccmessaging.chat.tinyMCE.FangSong": "FangSong",
  "ccmessaging.chat.tinyMCE.SimHei": "SimHei",
  "ccmessaging.chat.tinyMCE.simsun": "SimSun",
  "ccmessaging.chat.tinyMCE.yaHei": "YaHei de Microsoft",
  "ccmessaging.chat.uploadLocalFile": "Cargar archivo local",
  "ccmessaging.chat.warning.sharescreen": "Faites attention à la vie privée lorsque vous partagez le bureau",
  "ccmessaging.chat.web.agent.assist": "Assistance de l'agent",
  "ccmessaging.chat.web.agent.prohibitedmess": "Des mots interdits ! Attention aux mots, s'il vous plaît.",
  "ccmessaging.chat.web.agent.wrogmess": "Mauvaise formulation ! Veillez à utiliser des techniques de parole appropriées.",
  "ccmessaging.chat.web.channel.called.config": "Configuration de la partie appelée CTD",
  "ccmessaging.chat.web.channel.clickivr": "Cliquez pour obtenir le code d'accès IVR.",
  "ccmessaging.chat.webCard": "WebCard",
  "ccmessaging.chat.webCard.copyUrl": "copier le lien",
  "ccmessaging.chat.webCard.copyUrl.success": "succès du lien de copie",
  "ccmessaging.chat.webcard.pop.confirm.outside": "S'il faut sauter à des liens tiers?",
  "ccmessaging.chat.webcard.pop.confirm.prefix": "Le message sera redirigé vers l'adresse externe,le domaine du site web est",
  "ccmessaging.chat.webcard.pop.confirm.suffix": "Êtes-vous sûr de vouloir continuer ?",
  "ccmessaging.chat.webcard.pop.error.prefix": "Le domaine du site",
  "ccmessaging.chat.webcard.pop.error.suffix": "n'est pas dans la liste blanche des adresses. Contactez le personnel des opérations pour l'ajouter à la liste blanche.",
  "ccmessaging.chat.webclient.chatapi.evaluation": "S'il vous plaît évaluer ce service, merci!",
  "ccmessaging.chat.webclient.chatapi.failTransToAgent": "La transformation en agent a échoué, veuillez réessayer ou contacter l'administrateur",
  "ccmessaging.chat.webclient.chathtml.customer": "Clientèle",
  "ccmessaging.chat.webclient.chatjs.inpuStatus": "L'autre partie tape.",
  "ccmessaging.chat.whatsapp.body": "Zone de texte",
  "ccmessaging.chat.whatsapp.buttons": "Zone des boutons",
  "ccmessaging.chat.whatsapp.footer": "Zone de pied de page",
  "ccmessaging.chat.whatsapp.header": "Zone d'en-tête",
  "ccmessaging.chat.whatsapp.languageAF": "Néerlandais, Afrique du Sud",
  "ccmessaging.chat.whatsapp.languageAR": "Arabe",
  "ccmessaging.chat.whatsapp.languageAZ": "azerbaïdjanais",
  "ccmessaging.chat.whatsapp.languageBG": "Bulgare",
  "ccmessaging.chat.whatsapp.languageBN": "Bengali",
  "ccmessaging.chat.whatsapp.languageCA": "Catalogne",
  "ccmessaging.chat.whatsapp.languageCS": "Tchèque",
  "ccmessaging.chat.whatsapp.languageDA": "Danois",
  "ccmessaging.chat.whatsapp.languageDE": "Allemand",
  "ccmessaging.chat.whatsapp.languageEL": "Grecque",
  "ccmessaging.chat.whatsapp.languageEN": "Anglais",
  "ccmessaging.chat.whatsapp.languageENGB": "Anglais (Royaume-Uni)",
  "ccmessaging.chat.whatsapp.languageES": "Espagnol",
  "ccmessaging.chat.whatsapp.languageESAR": "Espagnol (Argentine)",
  "ccmessaging.chat.whatsapp.languageESES": "Espagnol (Espagne)",
  "ccmessaging.chat.whatsapp.languageESMX": "Espagnol (Mexique)",
  "ccmessaging.chat.whatsapp.languageET": "Estonien",
  "ccmessaging.chat.whatsapp.languageFA": "Persan",
  "ccmessaging.chat.whatsapp.languageFI": "finnois",
  "ccmessaging.chat.whatsapp.languageFIL": "Philippine",
  "ccmessaging.chat.whatsapp.languageFR": "Français",
  "ccmessaging.chat.whatsapp.languageGA": "Irlandais",
  "ccmessaging.chat.whatsapp.languageGU": "Gujarat",
  "ccmessaging.chat.whatsapp.languageHA": "Haoussa",
  "ccmessaging.chat.whatsapp.languageHE": "Hébreu",
  "ccmessaging.chat.whatsapp.languageHI": "Hindi",
  "ccmessaging.chat.whatsapp.languageHR": "Croate",
  "ccmessaging.chat.whatsapp.languageHU": "Hongrois",
  "ccmessaging.chat.whatsapp.languageID": "Indonésien",
  "ccmessaging.chat.whatsapp.languageIT": "Italien",
  "ccmessaging.chat.whatsapp.languageJA": "Japonais",
  "ccmessaging.chat.whatsapp.languageKK": "Kazakh",
  "ccmessaging.chat.whatsapp.languageKN": "Kanadawen",
  "ccmessaging.chat.whatsapp.languageKO": "Coréen",
  "ccmessaging.chat.whatsapp.languageLO": "Laotien",
  "ccmessaging.chat.whatsapp.languageLT": "Lituanien",
  "ccmessaging.chat.whatsapp.languageLV": "Letton",
  "ccmessaging.chat.whatsapp.languageMK": "Macédonien du Nord",
  "ccmessaging.chat.whatsapp.languageML": "Malayalam",
  "ccmessaging.chat.whatsapp.languageMR": "Marathi",
  "ccmessaging.chat.whatsapp.languageMS": "Malais",
  "ccmessaging.chat.whatsapp.languageNB": "Norvégien",
  "ccmessaging.chat.whatsapp.languageNL": "Néerlandais",
  "ccmessaging.chat.whatsapp.languagePA": "Punjabi",
  "ccmessaging.chat.whatsapp.languagePL": "Polonais",
  "ccmessaging.chat.whatsapp.languagePTBR": "Portugais (Brésil)",
  "ccmessaging.chat.whatsapp.languagePTPT": "Portugais (Portugal)",
  "ccmessaging.chat.whatsapp.languageRO": "Roumain",
  "ccmessaging.chat.whatsapp.languageRU": "Russe",
  "ccmessaging.chat.whatsapp.languageSK": "Slovaque",
  "ccmessaging.chat.whatsapp.languageSL": "Slovène",
  "ccmessaging.chat.whatsapp.languageSQ": "Albanais",
  "ccmessaging.chat.whatsapp.languageSR": "Serbe",
  "ccmessaging.chat.whatsapp.languageSV": "Suédois",
  "ccmessaging.chat.whatsapp.languageSW": "Swahili",
  "ccmessaging.chat.whatsapp.languageTA": "tamoul",
  "ccmessaging.chat.whatsapp.languageTE": "Telugu",
  "ccmessaging.chat.whatsapp.languageTH": "Thaïlandais",
  "ccmessaging.chat.whatsapp.languageTR": "Turque",
  "ccmessaging.chat.whatsapp.languageUK": "Ukrainien",
  "ccmessaging.chat.whatsapp.languageUR": "Urdu",
  "ccmessaging.chat.whatsapp.languageUS": "Français (États-Unis)",
  "ccmessaging.chat.whatsapp.languageUZ": "Ouzbek",
  "ccmessaging.chat.whatsapp.languageVI": "Vietnamien",
  "ccmessaging.chat.whatsapp.languageZHCN": "Chinois simplifié",
  "ccmessaging.chat.whatsapp.languageZHHK": "Chinois traditionnel (Hong Kong, Chine)",
  "ccmessaging.chat.whatsapp.languageZHTW": "Chinois traditionnel (Taïwan, Chine)",
  "ccmessaging.chat.whatsapp.languageZU": "Zoulou",
  "ccmessaging.chat.whatsapp.only.send.template": "Quand il n'y a pas d'usage de WhatsApp ou d'intervalle de contact supérieur à 24 heures, il est possible de modéliser de manière appropriée les enviadas.",
  "ccmessaging.chat.whatsapp.selectTemplateOrgPlaceHolder": "Sélectionner une organisation",
  "ccmessaging.chat.whatsapp.template.dailyLimit": "Temps de disponibilité des modèles par jour",
  "ccmessaging.chat.whatsapp.template.dailyLimitDescription": "Veuillez entrer le nombre de fois que le modèle est disponible par jour.",
  "ccmessaging.chat.whatsapp.template.no": "Non.",
  "ccmessaging.chat.whatsapp.template.updateTime": "Mise à jour",
  "ccmessaging.chat.whatsapp.templateAddButton": "Créer",
  "ccmessaging.chat.whatsapp.templateBodyParamDesc": "Description du paramètre",
  "ccmessaging.chat.whatsapp.templateBodyParamDescPlaceholder": "Saisissez la description du paramètre.",
  "ccmessaging.chat.whatsapp.templateBodyText": "Contenu du corps du texte",
  "ccmessaging.chat.whatsapp.templateBodyTextPlaceholder": "Entrez le contenu du corps du texte, le contenu dans {'{{}}'} est modifiable dans le corps du modèle.",
  "ccmessaging.chat.whatsapp.templateButton.clicktoAction": "L'action",
  "ccmessaging.chat.whatsapp.templateButton.none": "N/A",
  "ccmessaging.chat.whatsapp.templateButton.qickReply": "Réponse rapide",
  "ccmessaging.chat.whatsapp.templateButtonContent": "Contenu du bouton de modèle",
  "ccmessaging.chat.whatsapp.templateButtonContentPlaceholder": "Entrez le contenu du bouton.",
  "ccmessaging.chat.whatsapp.templateButtonName": "Nom du bouton modèle",
  "ccmessaging.chat.whatsapp.templateButtonNamePlaceholder": "Saisissez un nom de bouton.",
  "ccmessaging.chat.whatsapp.templateButtonType": "Type de bouton de modèle",
  "ccmessaging.chat.whatsapp.templateChannel": "Chaîne de modèle",
  "ccmessaging.chat.whatsapp.templateContent": "Contenu du modèle",
  "ccmessaging.chat.whatsapp.templateCreate": "Créer un modèle WhatsApp",
  "ccmessaging.chat.whatsapp.templateDescription": "Description du modèle",
  "ccmessaging.chat.whatsapp.templateDescriptionPlaceholder": "Saisissez la description du modèle.",
  "ccmessaging.chat.whatsapp.templateFooterText": "Contenu du texte de pied de page",
  "ccmessaging.chat.whatsapp.templateFooterTextPlaceholder": "Entrez le contenu du texte de pied de page.",
  "ccmessaging.chat.whatsapp.templateHeaderText": "Contenu du texte de l'en-tête",
  "ccmessaging.chat.whatsapp.templateHeaderTextPlaceholder": "Entrez le contenu du texte de l'en-tête.",
  "ccmessaging.chat.whatsapp.templateHeaderType": "En-tête",
  "ccmessaging.chat.whatsapp.templateHeaderType.image": "L'IMAGE",
  "ccmessaging.chat.whatsapp.templateHeaderType.locate": "Localisation",
  "ccmessaging.chat.whatsapp.templateHeaderType.none": "N/A",
  "ccmessaging.chat.whatsapp.templateHeaderType.text": "Texte",
  "ccmessaging.chat.whatsapp.templateHeaderType.video": "Vidéo",
  "ccmessaging.chat.whatsapp.templateLanguage": "Langue",
  "ccmessaging.chat.whatsapp.templateName": "Nom du modèle",
  "ccmessaging.chat.whatsapp.templateNamePlaceholder": "Saisissez un nom de modèle.",
  "ccmessaging.chat.whatsAppTemplateMsg.selectWhatsAppTemplateMsg": "Sélectionner le message de modèle WhatsApp",
  "ccmessaging.chat.whatsAppTemplateMsg.selectWhatsAppTemplateMsgId": "ID de message du modèle WhatsAppApp",
  "ccmessaging.chat.whatsAppTemplateMsg.selectWhatsAppTemplateMsgName": "Nom du message du modèle WhatsApp",
  "ccmessaging.chat.workbench.body.iolp": "Guide de service",
  "ccmessaging.chat.workbench.body.transfer": "Transfert d'appel",
  "ccmessaging.chat.workbench.transfer.agent": "Agent",
  "ccmessaging.chat.workbench.transfer.noagents": "Aucun agent en ligne n'existe dans la file d'attente de compétences actuelle.",
  "ccmessaging.chat.workbench.transfer.skill": "Skill queue",
  "ccmessaging.chat.workbench.transfer.skilllogin": "Agent disponible",
  "ccmessaging.chat.workbench.transfer.skillname": "Nom de file d'attente de compétence",
  "ccmessaging.chat.workbench.transfer.skillqueue": "Attendre dans la file d'attente",
  "ccmessaging.chat.workbench.transfer.skillsearch": "Veuillez entrer un nom de file d'attente de compétences",
  "ccmessaging.chatbot.button.avatarImport": "Téléchargement",
  "ccmessaging.chatbot.button.avatarImportLimit": "La taille du fichier à télécharger ne peut pas dépasser 6 Mo et le fichier peut être au format JPG ou PNG.",
  "ccmessaging.chatbot.button.cancel": "Annuler",
  "ccmessaging.chatbot.button.edit": "Modifier",
  "ccmessaging.chatbot.label.avatar": "Avatar",
  "ccmessaging.chatbot.label.avatarImport": "Télécharger Avatar",
  "ccmessaging.chatbot.label.avatarImportFailed": "Impossible de télécharger l'image!",
  "ccmessaging.chatbot.label.avatarImportSuccess": "Téléchargement réussi de l'image!",
  "ccmessaging.chatbot.label.avatarModifing": "Changer l'avatar",
  "ccmessaging.chatbot.label.basicInformation": "Informations de base",
  "ccmessaging.chatbot.label.commonLanguageSetting": "Configuration d'une phrase commune",
  "ccmessaging.chatbot.label.defaultReply": "Réponse par défaut",
  "ccmessaging.chatbot.label.gender": "Sexe",
  "ccmessaging.chatbot.label.name": "Pseudo",
  "ccmessaging.chatbot.label.save": "Enregistrer",
  "ccmessaging.chatbot.label.timeoutReply": "Réponse de délai d'expiration",
  "ccmessaging.chatbot.label.turnManualPrompt": "Prompt pour le transfert à l'agent",
  "ccmessaging.chatbot.label.welcomeMessage": "Salutations",
  "ccmessaging.chatbot.message.error": "Échec",
  "ccmessaging.chatbot.message.modifyFailure": "Échec de la modification.",
  "ccmessaging.chatbot.message.modifySuccess": "Modifié avec succès.",
  "ccmessaging.chatbot.message.specialStrValidateFailed": "Le contenu saisi ne peut pas contenir les characters: & < > /. suivants.",
  "ccmessaging.chatbot.message.success": "Succès",
  "ccmessaging.chatofflineCall.channelName": "Nom de la chaîne",
  "ccmessaging.chatofflineCall.invalidUserIdTip": "L'ID de l'abonné n'est pas valide.",
  "ccmessaging.chatofflineCall.noSelectChanelTip": "Aucun canal n'est sélectionné.",
  "ccmessaging.chatofflineCall.noSupportText": "Cet appel est un appel inconnu !",
  "ccmessaging.chatofflineCall.strangeOfflineTip": "Aucune session historique n'est trouvée.",
  "ccmessaging.cobrowsesite.protocol.check": "Des protocoles non sécurisés sont utilisés\, ce qui pose des risques pour la sécurité.",
  "ccmessaging.cobrowsesite.mediaurl.format.error": "Le format du site cobrowse est incorrect.",
  "ccmessaging.email.attachment.fileType.tip1": "Les pièces jointes aux e-mails prennent en charge les formats de fichiers suivants :",
  "ccmessaging.email.attachment.fileType.tip2": ",",
  "ccmessaging.email.attachment.fileType.tip3": "La taille du fichier ne peut pas dépasser",
  "ccmessaging.email.attachment.fileType.tip4": "M.",
  "ccmessaging.emailcatalog.add": "Créer un répertoire",
  "ccmessaging.emailcatalog.canNotAdd": "Un maximum de deux niveaux de répertoires sont autorisés.",
  "ccmessaging.emailcatalog.deleteTopCatalog": "Le répertoire par défaut ne peut pas être supprimé.",
  "ccmessaging.emailcatalog.duplicateName": "Le nom existe déjà. Utilise un autre nom.",
  "ccmessaging.emailcatalog.existEmail": "Les e-mails existent dans le répertoire.",
  "ccmessaging.emailcatalog.existSubCatalog": "Le répertoire contient des sous-répertoires.",
  "ccmessaging.emailcatalog.modify": "Modifier le répertoire",
  "ccmessaging.emailcatalog.modifyTopCatalog": "Le répertoire par défaut ne peut pas être modifié.",
  "ccmessaging.emailcatalog.moveCatalogFailed": "Impossible de déplacer un répertoire.",
  "ccmessaging.emailcatalog.moveCatalogPartialSuccess": "Impossible de déplacer certains répertoires de messagerie.",
  "ccmessaging.emailcatalog.moveCatalogSuccess": "Le répertoire est déplacé avec succès.",
  "ccmessaging.emailcatalog.moveEmailQuantityExceedsMaxValue": "Le nombre d'e-mails à déplacer dépasse 30 ou la longueur du paramètre dépasse la longueur maximale.",
  "ccmessaging.emailcatalog.moveOtherCatalogFailed": "Les e-mails des autres agents ne peuvent pas être déplacés.",
  "ccmessaging.emailcatalog.catalogname": "Nom du catalogue",
  "ccmessaging.emailcatalog.parentName": "Nom du répertoire parent",
  "ccmessaging.emailcatalog.pleaseSelectCatalog": "Sélectionnez d'abord un répertoire.",
  "ccmessaging.emailcatalog.selectcatalog": "Sélectionner un répertoire",
  "ccmessaging.emailMessageManage.forward": "Enregistrement de transmission",
  "ccmessaging.flowControl.addFail": "Impossible d'ajouter la configuration de contrôle de flux.",
  "ccmessaging.flowControl.addSuccess": "La configuration de contrôle de flux est ajoutée avec succès.",
  "ccmessaging.flowControl.alarmInterval": "Intervalle d'alarme",
  "ccmessaging.flowControl.alarmInterval.unit": "min",
  "ccmessaging.flowControl.alarmThreshold": "Seuil d'alarme",
  "ccmessaging.flowControl.channelType": "Type de canal",
  "ccmessaging.flowControl.count.beyondMaxLimit": "Le nombre de règles de contrôle de flux dans l'espace locataire dépasse la limite supérieure.",
  "ccmessaging.flowControl.createTime": "Créé le",
  "ccmessaging.flowControl.deleteFail": "Impossible de supprimer la configuration du contrôle de flux.",
  "ccmessaging.flowControl.deleteSelect": "Êtes-vous sûr de vouloir supprimer la règle sélectionnée ?",
  "ccmessaging.flowControl.deleteSuccess": "La configuration du contrôle de flux est supprimée avec succès.",
  "ccmessaging.flowControl.description": "Descriptif",
  "ccmessaging.flowControl.enable": "Activer",
  "ccmessaging.flowControl.limitNum": "Max. Messages",
  "ccmessaging.flowControl.name": "Nom du contrôle de flux",
  "ccmessaging.flowControl.operate": "Opération",
  "ccmessaging.flowControl.periodType": "Période Gamme",
  "ccmessaging.flowControl.periodType.day": "Quotidiennement",
  "ccmessaging.flowControl.periodType.month": "Mensuel",
  "ccmessaging.flowControl.periodType.time": "Personnalisation",
  "ccmessaging.flowControl.periodType.week": "Hebdomadaire",
  "ccmessaging.flowControl.periodValue": "Jour",
  "ccmessaging.flowControl.period.select": "Sélection de la période",
  "ccmessaging.flowControl.selectStop": "Désactivez d'abord la configuration du contrôle de flux.",
  "ccmessaging.flowControl.selectRule": "Sélectionnez la configuration du contrôle de flux.",
  "ccmessaging.flowControl.selectStartTime": "Sélectionnez l'heure de début.",
  "ccmessaging.flowcontrol.target.deleted": "L'objet contrôle de flux a été supprimé. Supprimer la règle de contrôle de flux correspondante.",
  "ccmessaging.flowControl.targetId": "Objet de contrôle de flux",
  "ccmessaging.flowControl.tipText": "Message d'invite",
  "ccmessaging.flowControl.tipTextInterval": "Intervalle d'invite",
  "ccmessaging.flowControl.updateFail": "Impossible de mettre à jour la configuration du contrôle de flux.",
  "ccmessaging.flowControl.updateSuccess": "La configuration du contrôle de flux est mise à jour avec succès.",
  "ccmessaging.flowControl.usage": "Utilisation",
  "ccmessaging.flowControl.title.create": "Créer un contrôle de flux",
  "ccmessaging.flowControl.title.modify": "Modifier le contrôle de flux",
  "ccmessaging.flowControl.title.view": "Afficher le contrôle de flux",
  "ccmessaging.webCollaboration.ruleName": "Nom de la règle",
  "ccmessaging.webCollaboration.pageMatchType": "Type de correspondance de page",
  "ccmessaging.webCollaboration.pageMatchConfiguration": "Configuration de la correspondance de page",
  "ccmessaging.webCollaboration.pageElementSelector": "Sélecteur d'éléments de page",
  "ccmessaging.webCollaboration.createTime": "Heure de création",
  "ccmessaging.webCollaboration.operation": "Opérations",
  "cobrowse.sensitiveinfo.pagematchtype.commonmatch": "Correspondance universelle",
  "cobrowse.sensitiveinfo.pagematchtype.urlmatch": "Correspondance en fonction du chemin d'URL",
  "cobrowse.sensitiveinfo.pagematchtype.pageidmatch": "Correspondance par ID de page",
  "ccmessaging.message.location.latitude": "Latitude :",
  "ccmessaging.message.location.longitude": "Longitude:",
  "ccmessaging.message.selectEmail.count": "Un maximum de 30 e-mails peuvent être sélectionnés à la fois.",
  "ccmessaging.message.selectEmail.null": "Sélectionnez un e-mail.",
  "ccmessaging.message.sendstatus.deliverable": "Envoyé",
  "ccmessaging.message.sendstatus.pending": "Attente",
  "ccmessaging.message.sendstatus.read": "Lire",
  "ccmessaging.message.sendstatus.undeliverable": "Échec",
  "ccmessaging.message.sendstatus.unread": "non lu",
  "ccmessaging.message.timeerror.laterThanCurTime": "L'heure de début ne peut pas être postérieure à l'heure actuelle.",
  "ccmessaging.messagecontroll.timeerror.beyondMaxLimit": "L'heure de fin ne peut pas être postérieure à l'heure de début 90 jours!",
  "ccmessaging.multimedialibrary.cobrowsesite.card.target": "Parcourir la cible :",
  "ccmessaging.multimedialibrary.cobrowsesite.card.title": "L'agent demande une navigation collaborative avec l'utilisateur.",
  "ccmessaging.multimedialibrary.tips": "Cette adresse est utilisée par l'agent pour l'envoyer à l'utilisateur pour la collaboration de page Web. Configurez-le correctement.",
  "ccmessaging.operlogs.emailAttachment.download": "Télécharger les pièces jointes des e-mails",
  "ccmessaging.operlogs.multimedialibrary.create.richtext": "Ajouter du texte enrichi",
  "ccmessaging.operlogs.multimedialibrary.modify.richtext": "Modifier le texte enrichi",
  "ccmessaging.operlogs.multimedialibrary.save": "Enregistrer la configuration des données de la bibliothèque multimédia",
  "ccmessaging.operlogs.multimedialibrary.save.audio": "Enregistrez la configuration des données dans la bibliothèque multimédia et téléchargez les données de type audio.",
  "ccmessaging.operlogs.multimedialibrary.save.emoticons": "Enregistrez la configuration des données dans la bibliothèque multimédia et téléchargez les données du fichier de type émoticône.",
  "ccmessaging.operlogs.multimedialibrary.save.image": "Enregistrez la configuration des données dans la bibliothèque multimédia et téléchargez les données du fichier de type image.",
  "ccmessaging.operlogs.multimedialibrary.save.video": "Enregistrez la configuration des données dans la bibliothèque multimédia et téléchargez les données du fichier de type vidéo.",
  "ccmessaging.operlogs.preview.contract": "Contrat de prévisualisation, Utilisateur:",
  "ccmessaging.operlogs.signature.submit": "Soumettre un contrat signé",
  "ccmessaging.operlogs.upload.contract": "Uploader le contrat",
  "ccmessaging.pop.org.window.noorg": "Sans OU",
  "ccmessaging.pop.org.window.org": "Avec une OU",
  "ccmessaging.ruleconfig.add": "Ajouter",
  "ccmessaging.ruleconfig.all": "Tous les canaux",
  "ccmessaging.ruleconfig.applicationChannel": "Canal d'application",
  "ccmessaging.ruleconfig.content": "Contenu",
  "ccmessaging.ruleconfig.contentNotNull": "Le contenu ne peut pas être vide.",
  "ccmessaging.ruleconfig.createdTime": "Temps de création",
  "ccmessaging.ruleconfig.dateEnd": "Heure de fin",
  "ccmessaging.ruleconfig.dateStart": "Heure de début",
  "ccmessaging.ruleconfig.deleteFail": "Impossible de supprimer la règle.",
  "ccmessaging.ruleconfig.deleteSelectRule": "Êtes-vous sûr de vouloir supprimer la règle sélectionnée ?",
  "ccmessaging.ruleconfig.deleteSuccess": "Règle supprimée avec succès.",
  "ccmessaging.ruleconfig.duplicateName": "Le nom de la règle est en double.",
  "ccmessaging.ruleconfig.effective": "Efficace",
  "ccmessaging.ruleconfig.emailaddress.error": "Les adresses suivantes sont interdites d'envoi :",
  "ccmessaging.ruleconfig.emailAddressExists": "L'adresse e-mail existe déjà",
  "ccmessaging.ruleconfig.emailAdress": "Adresse e-mail",
  "ccmessaging.ruleconfig.emailChannel": "Chaîne de courrier électronique",
  "ccmessaging.ruleconfig.ID": "ID",
  "ccmessaging.ruleconfig.invalidation": "Invalidation",
  "ccmessaging.ruleconfig.invalidEmailAddress": "Adresse e-mail non valide",
  "ccmessaging.ruleconfig.invalidSMSNumber": "Numéro de mobile non valide.",
  "ccmessaging.ruleconfig.maxEmailAddressCount": "Un maximum de 10 adresses e-mail peuvent être saisies dans le contenu",
  "ccmessaging.ruleconfig.maxSMSNumberCount": "Un maximum de 10 numéros mobiles peuvent être ajoutés.",
  "ccmessaging.ruleconfig.maxValidityPeriod": "La période de validité est fixée au maximum.",
  "ccmessaging.ruleconfig.ruleContentKeyword": "Mot-clé du contenu de la règle",
  "ccmessaging.ruleconfig.ruleDescribe": "Description de la règle",
  "ccmessaging.ruleconfig.ruleName": "Nom de la règle",
  "ccmessaging.ruleconfig.selectRule": "Veuillez sélectionner une règle",
  "ccmessaging.ruleconfig.SMSChannel": "Canal SMS",
  "ccmessaging.ruleconfig.SMSNumber": "Numéro de téléphone portable",
  "ccmessaging.ruleconfig.SMSNumberExists": "Le numéro de portable existe.",
  "ccmessaging.ruleconfig.SMStips1": `Le numéro de mobile peut contenir des caractères génériques. "\\*" indique que zéro à plusieurs caractères sont appariés. "\\?" indique que zéro ou un caractère correspond.`,
  "ccmessaging.ruleconfig.SMStips2": `Par exemple, la valeur "\\*9988" indique que les messages SMS envoyés à partir de tous les numéros mobiles se terminant par "9988" sont rejetés.`,
  "ccmessaging.ruleconfig.SMStips3": `Par exemple, la valeur "132\\*" indique que les messages SMS envoyés à partir de tous les numéros mobiles commençant par "132" sont rejetés.`,
  "ccmessaging.ruleconfig.SMStips4": `La valeur "\\*" indique que les messages SMS envoyés à partir de tous les numéros mobiles sont bloqués. Faites preuve de prudence lorsque vous utilisez cette valeur.`,
  "ccmessaging.ruleconfig.timeerror.dateValidateFailed": "Veuillez sélectionner une date postérieure à l'heure actuelle et antérieure à 2038-1-19",
  "ccmessaging.ruleconfig.timeerror.empty": "* L'heure de début et l'heure de fin ne peuvent pas être vides.",
  "ccmessaging.ruleconfig.timeerror.endTime": "* L'heure de fin ne peut pas être antérieure à l'heure actuelle.",
  "ccmessaging.ruleconfig.timeerror.laterThanEndTime": "* L'heure de début ne peut pas être postérieure à l'heure de fin.",
  "ccmessaging.ruleconfig.timeerror.laterThanEndTime2": "L'heure de début ne peut pas être postérieure à l'heure de fin.",
  "ccmessaging.ruleconfig.timeerror.startTime": "* L'heure de début ne peut pas être antérieure à l'heure actuelle.",
  "ccmessaging.ruleconfig.tips1": `Vous pouvez entrer des caractères génériques dans l'adresse e-mail. "\\*" indique que 0 caractères ou plus sont appariés. "\\?" Indique que 0 à 1 caractères sont appariés.`,
  "ccmessaging.ruleconfig.tips2": `Par exemple, "\\*{'@'}huawei.com" indique que tous les courriels dont le nom de domaine est "huawei.com" sont rejetés.`,
  "ccmessaging.ruleconfig.tips3": `Zone de texte du contenu sauf "\\*" "\\?" Les règles d'adresse électronique doivent être respectées.`,
  "ccmessaging.ruleconfig.tips4": `"\\*{'@'}\\*.com" bloquera tous les mails avec le nom de domaine ".com". Faites preuve de prudence lorsque vous utilisez cette commande.`,
  "ccmessaging.ruleconfig.validityPeriod": "Période de validité",
  "ccmessaging.ruleconfig.validityPeriodDay": "Access Token Période de validité(jours)",
  "ccmessaging.ruleconfig.duplicateEmailAddress": "Dupliquer l'adresse e-mail",
  "ccmessaging.ruleconfig.duplicateSmsAddress": "Dupliquer le numéro de portable",
  "ccmessaging.socialPostOperation.button.approve": "Approuver",
  "ccmessaging.socialPostOperation.button.batchDelete": "Suppression par lots",
  "ccmessaging.socialPostOperation.button.cancel": "Retirer",
  "ccmessaging.socialPostOperation.button.channelConfig": "Paramètres du canal",
  "ccmessaging.socialPostOperation.button.creatPost": "Poster",
  "ccmessaging.socialPostOperation.button.edit": "Editer",
  "ccmessaging.socialPostOperation.button.delete": "Supp.",
  "ccmessaging.socialPostOperation.button.saveDraft": "Enregistrer comme brouillon",
  "ccmessaging.socialPostOperation.button.upload": "Télécharger",
  "ccmessaging.socialPostOperation.ceratePageTitle": "Opération/Post du compte",
  "ccmessaging.socialPostOperation.edit.approveInformation": "Informations d'approbation",
  "ccmessaging.socialPostOperation.edit.basicInformation": "Informations de base",
  "ccmessaging.socialPostOperation.edit.must": "Obligatoire",
  "ccmessaging.chat.channelconfig.checkConfirmTokenEqualToToken": "Vous devez entrer la même valeur",
  "ccmessaging.socialPostOperation.edit.placeholder": "Veuillez saisir",
  "ccmessaging.socialPostOperation.edit.publishInformation": "Informations de publication",
  "ccmessaging.socialPostOperation.facebook.publishAt.tips": "La publication programmée doit être publiée entre 20 minutes et 75 jours.",
  "ccmessaging.socialPostOperation.facebook.tips.emptyPost": "Facebook ne peut pas publier de timbres vierges. Veuillez sélectionner au moins un élément (contenu, image ou vidéo)",
  "ccmessaging.socialPostOperation.field.applicationType": "Type d'application",
  "ccmessaging.socialPostOperation.field.applicationType.create": "Publier",
  "ccmessaging.socialPostOperation.field.approveComment": "Commentaire d'approbation",
  "ccmessaging.socialPostOperation.field.approverName": "Approbateur",
  "ccmessaging.socialPostOperation.field.approveStatus": "Résultat de l'approbation",
  "ccmessaging.socialPostOperation.field.approveStatus.pass": "Pass",
  "ccmessaging.socialPostOperation.field.approveStatus.rejected": "Rejeté",
  "ccmessaging.socialPostOperation.field.channelName": "Nom du canal",
  "ccmessaging.socialPostOperation.field.content": "Contenu",
  "ccmessaging.socialPostOperation.field.createUserName": "Candidat",
  "ccmessaging.socialPostOperation.field.image": "Image",
  "ccmessaging.socialPostOperation.field.operation": "Opération",
  "ccmessaging.socialPostOperation.field.postConfigInfo": "Informations sur le canal",
  "ccmessaging.socialPostOperation.field.postType": "Canal de publication",
  "ccmessaging.socialPostOperation.field.publishAt": "Heure de publication",
  "ccmessaging.socialPostOperation.field.publishChannel": "Publier un canal",
  "ccmessaging.socialPostOperation.field.publishStatus": "Statut de publication",
  "ccmessaging.socialPostOperation.field.publishStatus.published": "Publié",
  "ccmessaging.socialPostOperation.field.publishStatus.unpublished": "Non publié",
  "ccmessaging.socialPostOperation.field.publishTime": "Date de publication prévue",
  "ccmessaging.socialPostOperation.field.publishTimeSet": "Définir l'heure de publication",
  "ccmessaging.socialPostOperation.field.publishTyle.now": "Publier en temps réel",
  "ccmessaging.socialPostOperation.field.publishTyle.time": "Publication programmée",
  "ccmessaging.socialPostOperation.field.publishType": "Type de publication",
  "ccmessaging.socialPostOperation.field.remark": "Remarques",
  "ccmessaging.socialPostOperation.field.status": "Statut",
  "ccmessaging.socialPostOperation.field.status.approved": "Terminé",
  "ccmessaging.socialPostOperation.field.status.draft": "Brouillon",
  "ccmessaging.socialPostOperation.field.status.toApproved": "En attente d'approbation",
  "ccmessaging.socialPostOperation.field.title": "Thème",
  "ccmessaging.socialPostOperation.field.video": "Vidéo",
  "ccmessaging.socialPostOperation.res.recall": "Révocation réussie.",
  "ccmessaging.socialPostOperation.search.searchTitle": "Thèmes",
  "ccmessaging.socialPostOperation.select.pleaseChoose": "S'il vous plaît choisir",
  "ccmessaging.socialPostOperation.tab.history": "Historique des approbations",
  "ccmessaging.socialPostOperation.tips.approveSet": "Vérifier l'élément de configuration de l'approbateur.",
  "ccmessaging.socialPostOperation.tips.approveSuccess": "Approuvé avec succès",
  "ccmessaging.socialPostOperation.tips.approvingDelete": "Les données en attente d'approbation ne peuvent pas être supprimées.",
  "ccmessaging.socialPostOperation.tips.cancelError": "Les données qui ne sont pas en attente d'approbation ne peuvent pas être retirées.",
  "ccmessaging.socialPostOperation.tips.cannotDel": "Les messages à approuver ne peuvent pas être supprimés.",
  "ccmessaging.socialPostOperation.tips.delConfirm": "Êtes-vous sûr de supprimer les données sélectionnées ?",
  "ccmessaging.socialPostOperation.tips.deleteApproveSuccess": "La suppression a été soumise avec succès et doit être approuvée.",
  "ccmessaging.socialPostOperation.tips.deleteNoApprove": "La valeur de l'approbateur est vide. Après avoir soumis l'approbateur, l'approbateur sera supprimé directement. Voulez-vous vraiment soumettre l'approbateur ?",
  "ccmessaging.socialPostOperation.tips.fileTip": "La taille du fichier dépasse la limite supérieure {n} Mo",
  "ccmessaging.socialPostOperation.tips.imageNumTip": "Un maximum de neuf images peuvent être téléchargées.",
  "ccmessaging.socialPostOperation.tips.imageType": "Les formats png, jpg, gif, bmp et tiff sont pris en charge.",
  "ccmessaging.socialPostOperation.tips.imgAndVideoChoose": "Les images et les vidéos ne peuvent pas être téléchargées en même temps.",
  "ccmessaging.socialPostOperation.tips.postTypesChoose": "Sélectionnez au moins un canal de publication.",
  "ccmessaging.socialPostOperation.tips.publishFailed": "Échec de la publication",
  "ccmessaging.socialPostOperation.tips.saveApproveSuccess": "La soumission est réussie et doit être approuvée.",
  "ccmessaging.socialPostOperation.tips.submit": "La valeur de l'approbateur est vide. Une fois la valeur soumise, elle sera publiée directement. Voulez-vous vraiment la soumettre ?",
  "ccmessaging.socialPostOperation.tips.submitSuccess": "La soumission a réussi.",
  "ccmessaging.socialPostOperation.tips.titleError": "ne peut pas contenir < et >",
  "ccmessaging.socialPostOperation.tips.uploadVideo": "Veuillez télécharger le fichier vidéo.",
  "ccmessaging.socialPostOperation.tips.videoType": "Le format MP4 est pris en charge.",
  "ccmessaging.socialPostOperation.tips.youTubeFileChoose": "Le canal YouTube ne prend en charge que le téléchargement de vidéos",
  "ccmessaging.socialPostOperation.youTube.addLabel": "Ajouter une balise",
  "ccmessaging.socialPostOperation.youTube.audience": "Audience",
  "ccmessaging.socialPostOperation.youTube.audienceRule": "Conformément à la loi, où que vous soyez, Ils doivent se conformer à la loi sur la protection de la vie privée en ligne des enfants (COPPA) et/ou à d'autres lois. Vous devez indiquer si votre vidéo est destinée aux enfants.",
  "ccmessaging.socialPostOperation.youTube.category": "Catégorie",
  "ccmessaging.socialPostOperation.youTube.category.car": "Auto/Moto",
  "ccmessaging.socialPostOperation.youTube.category.Comedy": "Humour",
  "ccmessaging.socialPostOperation.youTube.category.education": "Éducation",
  "ccmessaging.socialPostOperation.youTube.category.entertainment": "Divertissement",
  "ccmessaging.socialPostOperation.youTube.category.film": "Films et animations",
  "ccmessaging.socialPostOperation.youTube.category.gaming": "Jeux vidéo",
  "ccmessaging.socialPostOperation.youTube.category.howto": "Vie pratique et style",
  "ccmessaging.socialPostOperation.youTube.category.music": "Musique",
  "ccmessaging.socialPostOperation.youTube.category.news": "Actualités et politique",
  "ccmessaging.socialPostOperation.youTube.category.nonprofits": "Organisations à but non lucratif",
  "ccmessaging.socialPostOperation.youTube.category.people": "People et blogs",
  "ccmessaging.socialPostOperation.youTube.category.pets": "Animaux",
  "ccmessaging.socialPostOperation.youTube.category.science": "Science et technologie",
  "ccmessaging.socialPostOperation.youTube.category.shortMovies": "Courts métrages",
  "ccmessaging.socialPostOperation.youTube.category.sport": "Sport",
  "ccmessaging.socialPostOperation.youTube.category.travel": "Voyages et événements",
  "ccmessaging.socialPostOperation.youTube.categoryDetail": "Ajouter votre appétit à une catégorie pour que les téléspectateurs puissent le trouver facilement",
  "ccmessaging.socialPostOperation.youTube.disclosureScope": "Portée publique",
  "ccmessaging.socialPostOperation.youTube.disclosureScopeDetail": "Choisissez qui peut voir votre vidéo",
  "ccmessaging.socialPostOperation.youTube.embedding": "Autoriser l'intégration",
  "ccmessaging.socialPostOperation.youTube.embedding.tips": "Autoriser d'autres personnes à intégrer votre vidéo dans leur site web.",
  "ccmessaging.socialPostOperation.youTube.knowledgePermission": "CreativeShare — Attribution",
  "ccmessaging.socialPostOperation.youTube.labels": "Label",
  "ccmessaging.socialPostOperation.youTube.labelsDetail": "S'il y a des mots mal orthographiés dans le contenu de la vidéo, les balises aident les téléspectateurs à trouver votre vidéo. En dehors de cela, les balises ne sont pas très utiles pour trouver des vidéos.",
  "ccmessaging.socialPostOperation.youTube.noKids": "Pas pour les enfants",
  "ccmessaging.socialPostOperation.youTube.normalPermission": "Licence YouTube standard",
  "ccmessaging.socialPostOperation.youTube.permissions": "Licence",
  "ccmessaging.socialPostOperation.youTube.permissionsKnow": "Comprendre le type de licence.",
  "ccmessaging.socialPostOperation.youTube.scopePrivate": "Private",
  "ccmessaging.socialPostOperation.youTube.scopePublic": "public",
  "ccmessaging.socialPostOperation.youTube.scopeUnlisted": "Non listé publiquement",
  "ccmessaging.socialPostOperation.youTube.tags.tips": "Entrez une virgule après chaque balise.",
  "ccmessaging.socialPostOperation.youTube.videoToKids": "Cette vidéo est-elle du contenu pour les enfants?",
  "ccmessaging.socialPostOperation.youTube.view.commentNum": "14 commentaires",
  "ccmessaging.socialPostOperation.youTube.view.dataResolve": "Analyse des données",
  "ccmessaging.socialPostOperation.youTube.view.editVideo": "Modifier la vidéo",
  "ccmessaging.socialPostOperation.youTube.view.lookNum": "38 Vue il y a 2 jours",
  "ccmessaging.socialPostOperation.youTube.view.orderType": "Type de tri",
  "ccmessaging.socialPostOperation.youTube.view.share": "Partager",
  "ccmessaging.socialPostOperation.youTube.view.subscriber": "1 abonné",
  "ccmessaging.socialPostOperation.youTube.view.unfolding": "Développer",
  "ccmessaging.socialPostOperation.youTube.yesKids": "Oui, le contenu est destiné aux enfants",
  "ccmessaging.socialPostOperation.youTube.publishAtTips": "Ce paramètre n'est disponible que si la portée publique est définie sur Privé",
  "ccmessaging.socialPostOperation.facebook.threeDay": "3 jours",
  "ccmessaging.socialPostOperation.facebook.awful": "louanges",
  "ccmessaging.socialPostOperation.facebook.comment": "Commentaire",
  "ccmessgaing.chat.web.channel.Agent": "Agent audio et vidéo",
  "ccmessgaing.chat.web.channel.ivr": "IVR",
  "ccmessgaing.chat.web.channel.webrtc.share": "Le partage de bureau implique l'affichage de la confidentialité. Veuillez confirmer.",
  "ccmessgaing.chatbot.message.female": "Femme",
  "ccmessgaing.chatbot.message.male": "Hommes",
  "ccmessgaing.postChannelConfig.accessToken": "Informations d'accès",
  "ccmessgaing.postChannelConfig.add": "Ajouter un canal",
  "ccmessgaing.postChannelConfig.apiFabricAK": "ApiFabric Identification",
  "ccmessgaing.postChannelConfig.apiFabricSK": "ApiFabricClé secrète",
  "ccmessgaing.postChannelConfig.apiKey": "Clé de l'API",
  "ccmessgaing.postChannelConfig.appId": "ID du client",
  "ccmessgaing.postChannelConfig.appKey": "Clé client",
  "ccmessgaing.postChannelConfig.cancel": "Annuler",
  "ccmessgaing.postChannelConfig.channelConfig": "Configure canal",
  "ccmessgaing.postChannelConfig.channelCreate": "Créer un canal",
  "ccmessgaing.postChannelConfig.channelEdit": "Modifier la configuration du canal",
  "ccmessgaing.postChannelConfig.channelType": "Type de canal",
  "ccmessgaing.postChannelConfig.confirm": "Confirmation",
  "ccmessgaing.postChannelConfig.addUsers": "Ajouté",
  "ccmessgaing.postChannelConfig.createFailed": "Échec de la création. Vérifiez les paramètres du formulaire et la connexion réseau.",
  "ccmessgaing.postChannelConfig.createSuccess": "Les informations de configuration du canal multimédia social sont créées avec succès.",
  "ccmessgaing.postChannelConfig.delete": "Suppression",
  "ccmessgaing.postChannelConfig.deleteFailed": "Impossible de supprimer les informations de configuration du canal multimédia social.",
  "ccmessgaing.postChannelConfig.deleteFailed.db": "Échec de la suppression. Échec de l'exploitation de la base de données.",
  "ccmessgaing.postChannelConfig.deleteFailed.permission": "Échec de la suppression. Échec de la vérification des permissions.",
  "ccmessgaing.postChannelConfig.deleteFailed.inUse": "Échec de la suppression. Les informations de publication existent dans le canal.",
  "ccmessgaing.postChannelConfig.deleteSuccess": "La suppression des informations de configuration du canal multimédia social a réussi.",
  "ccmessgaing.postChannelConfig.edit": "Éditeur",
  "ccmessgaing.postChannelConfig.editFailed": "Échec de la mise à jour. Veuillez vérifier les paramètres du formulaire et la connexion réseau.",
  "ccmessgaing.postChannelConfig.editSuccess": "Les informations de configuration du canal multimédia social sont mises à jour avec succès.",
  "ccmessgaing.postChannelConfig.failed": "Échecs",
  "ccmessgaing.postChannelConfig.maxLength": "La longueur maximale est de {n} caractères",
  "ccmessgaing.postChannelConfig.operation": "OPÉRATIONS",
  "ccmessgaing.postChannelConfig.operation.person": "Personnel d'exploitation",
  "ccmessgaing.postChannelConfig.placeSelectChannelType": "Veuillez sélectionner un type de canal.",
  "ccmessgaing.postChannelConfig.postChannelName": "Nom du canal",
  "ccmessgaing.postChannelConfig.refreshToken": "Actualiser les informations d'identification",
  "ccmessgaing.postChannelConfig.users.overSize": "Le nombre d'opérateurs est supérieur à 10",
  "ccmessgaing.postChannelConfig.repeatedly": "Le canal existe déjà.",
  "ccmessgaing.postChannelConfig.selectByChannelName": "Nom du canal",
  "ccmessgaing.postChannelConfig.subChannelId": "ID de canal",
  "ccmessgaing.postChannelConfig.success": "Succès",
  "ccmessgaing.postChannelConfig.unauthorized": "Opération non authentifiée",
  "chat.agent.chatOnlineWorkbench": "Workbench de chat en ligne",
  "chat.agent.label.balance": "Revenus et dépenses",
  "chat.agent.label.billDetail": "Détails de la facture",
  "chat.agent.label.chatBot": "Chatbot",
  "chat.agent.label.consumption": "Ma consommation",
  "chat.agent.label.customerSource": "Source client: Application mobile - Centre personnel",
  "chat.agent.label.downagainfile": "Télécharger à nouveau",
  "chat.agent.label.goAway": "Loin",
  "chat.agent.label.historicalBill": "Projet de loi historique",
  "chat.agent.label.money": "Montant",
  "chat.agent.label.mybill": "Mes factures",
  "chat.agent.label.offline": "Hors ligne",
  "chat.agent.label.online": "En ligne",
  "chat.agent.label.recivefile": "Recevoir",
  "chat.agent.label.refusefile": "Refuser",
  "chat.agent.label.send": "Envoyer",
  "chat.agent.label.total": "Total",
  "chat.agent.label.waiting": "Attendez",
  "chat.agent.message.changeFailed": "Impossible de modifier le statut de l'agent.",
  "chat.agent.title.changeWorkMode": "changer de mode de travail",
  "chat.agent.title.close": "Fermer",
  "chat.agent.title.createCase": "Créer un cas",
  "chat.agent.title.createSkill": "Créer une compétence",
  "chat.agent.title.enterText": "s'il vous plaît entrer",
  "chat.agent.title.OfflineCall": "Appel hors ligne",
  "chat.agent.title.transfer": "Transfert vers",
  "chat.agent.title.userInformation": "Informations client",
  "chat.agent.title.userMenu": "Menu client",
  "chat.agent.title.workMode": "mode de travail",
  "chat.agentConsole.message.content.audio": "[Message vocal]",
  "chat.agentConsole.message.content.connectMessage": "Un nouveau message!",
  "chat.agentConsole.message.content.image": "[Message d'image]",
  "chat.agentConsole.message.content.locate": "[Message de localisation]",
  "chat.agentConsole.message.content.video": "[Message vidéo]",
  "chat.agentConsole.message.error.emptyMessage": "[Le message ne contient aucun contenu pouvant être affiché.]",
  "chat.agentConsole.message.error.notification": "La nouvelle notification de message ne peut pas être créée. Veuillez vérifier la configuration du navigateur!",
  "chat.client.alert.message.invildtoken": "Votre boîte de dialogue a expiré ou vous vous êtes connecté ailleurs. S'il vous plaît fermer la fenêtre de chat et essayer à nouveau. Merci!",
  "chat.client.button.submit": "Envoyer",
  "chat.client.evaluate.message": "S'il vous plaît commenter mon service. Merci!",
  "chat.client.greeting.afternoon": "Bon après-midi !",
  "chat.client.greeting.dear": "Chère chère",
  "chat.client.greeting.evening": "Bonsoir !",
  "chat.client.greeting.info": "Que puis-je faire pour vous ?",
  "chat.client.greeting.morning": "Bonjour!",
  "chat.client.label.accountNumber": "Compte",
  "chat.client.label.bill": "Mes factures",
  "chat.client.label.chatTitle": "client",
  "chat.client.label.download": "Téléchargement",
  "chat.client.label.evaluation": "Sondage de satisfaction",
  "chat.client.label.forgotPassword": "Mot de passe oublié",
  "chat.client.label.header": "Telco+",
  "chat.client.label.input": "s'il vous plaît entrer",
  "chat.client.label.like": "Vous aimerez peut-être",
  "chat.client.label.login": "Se connecter",
  "chat.client.label.loginTitle": "Se connecter",
  "chat.client.label.more": "Plus",
  "chat.client.label.no": "Non",
  "chat.client.label.order": "Mes Commandes",
  "chat.client.label.password": "Mot de passe",
  "chat.client.label.privacyHint": "Avertissement pour la protection de la vie privée",
  "chat.client.label.privacyHintContent": "Pour améliorer la qualité du service, votre conversation sera enregistrée par le système. Êtes-vous sûr de vouloir continuer à parler ?",
  "chat.client.label.promotions": "Inviter",
  "chat.client.label.rateService": "Service de score",
  "chat.client.label.system": "Le système est occupé. Veuillez réessayer plus tard.",
  "chat.client.label.yes": "Oui",
  "chat.client.message.evaluationFail": "L'enquête de satisfaction échoue.",
  "chat.client.message.evaluationSuccess": "L'enquête de satisfaction est couronnée de succès.",
  "chat.client.message.gladtoServe": "Qu'est-ce que je peux faire pour vous?",
  "chat.client.message.hiMyWorkno": "Bonjour, c'est",
  "chat.client.message.noAgent": "Désolé, l'agent est occupé. Réessayez plus tard.",
  "chat.client.transfer.link": "Êtes-vous sûr de vouloir transférer l'appel à un agent ?",
  "chat.common.message.cancel": "Annuler",
  "chat.common.message.create": "Créer",
  "chat.common.message.dateEnd": "Heure de fin",
  "chat.common.message.dateStart": "Heure de début",
  "chat.common.message.delete": "Supprimer",
  "chat.common.message.error": "Erreur",
  "chat.common.message.fail": "Échec",
  "chat.common.message.finish": "OK",
  "chat.common.message.modify": "Modifier",
  "chat.common.message.promotions": "Informations",
  "chat.common.message.success": "Succès",
  "chat.common.message.warning": "Avertissement",
  "chat.confirm.no": "NON",
  "chat.confirm.ok": "Confirmation",
  "chat.confirm.yes": "Oui",
  "chat.session.channel.all": "Tous",
  "chat.session.channel.facebook": "FaceBook",
  "chat.session.channel.mobile": "Terminal mobile",
  "chat.session.channel.twitter": "Twitter",
  "chat.session.channel.web": "Web",
  "chat.session.channel.wechat": "WeChat",
  "chat.session.endType.agentEnd": "Fin par agent",
  "chat.session.endType.customerEnd": "Fin par le client",
  "chat.session.endType.overtimeEnd": "Fin en raison d'un délai d'expiration",
  "chat.session.label.alias": "Nom du client",
  "chat.session.label.allChannel": "Tous les types de canaux",
  "chat.session.label.allEndType": "Tous les types d'extrémité",
  "chat.session.label.channel": "Canal d'accès de boîte de dialogue",
  "chat.session.label.created_endTime": "Heure de fin",
  "chat.session.label.created_startTime": "Heure de début",
  "chat.session.label.createdTime": "Temps de création de la boîte de dialogue",
  "chat.session.label.endReason": "Cause de fin de dialogue",
  "chat.session.label.endTime": "Heure de fin de dialogue",
  "chat.session.label.endType": "Type de fin de dialogue",
  "chat.session.label.enterQueueTime": "Temps d'attente",
  "chat.session.label.evaluation": "Évaluation de la boîte de dialogue",
  "chat.session.label.evaluationTime": "Temps d'évaluation de la boîte de dialogue",
  "chat.session.label.exitQueueTime": "Temps de sortie de la file d'attente",
  "chat.session.label.remark": "Commentaire",
  "chat.session.label.reset": "Réinitialiser",
  "chat.session.label.search": "Rechercher",
  "chat.session.label.servedAgent": "Agent de service",
  "chat.session.label.sessionId": "ID de boîte de dialogue",
  "chat.session.label.skillQueueId": "ID de file d'attente de compétence",
  "chat.session.label.skillQueueName": "Nom de file d'attente de compétence",
  "chat.session.label.subChannel": "Canal d'accès secondaire",
  "chat.session.title.basic": "Informations de base sur la boîte de dialogue",
  "chat.session.title.detail": "Détails de la boîte de dialogue",
  "chat.session.title.history": "Historique des enregistrements de chat",
  "chat.skill.label.account": "Compte",
  "chat.skill.label.agents": "Agent",
  "chat.skill.label.cancel": "Annuler",
  "chat.skill.label.createSkill": "Créer",
  "chat.skill.label.createSkillTab": "Créer une compétence",
  "chat.skill.label.delete": "Supprimer",
  "chat.skill.label.deleteSkills": "Supprimer",
  "chat.skill.label.description": "Descriptif",
  "chat.skill.label.edit": "Modifier",
  "chat.skill.label.inputName": "Nom et prénom",
  "chat.skill.label.maxQueueNumber": "Nombre maximum de file d'attente",
  "chat.skill.label.maxQueueTime": "Max. Temps d'attente (s)",
  "chat.skill.label.maxQueueTimeInTable": "Max. Temps de file d'attente",
  "chat.skill.label.name": "Nom d'utilisateur",
  "chat.skill.label.ok": "OK",
  "chat.skill.label.priority": "Priorité",
  "chat.skill.label.reset": "Réinitialiser",
  "chat.skill.label.save": "Enregistrer",
  "chat.skill.label.skill": "Compétence-",
  "chat.skill.label.skillDetail": "Détails des compétences",
  "chat.skill.label.skillId": "Non.",
  "chat.skill.label.skillName": "Nom et prénom",
  "chat.skill.label.submit": "Envoyer",
  "chat.skill.message.completeInputs": "Veuillez compléter toutes les entrées.",
  "chat.skill.message.createFail": "Impossible de créer la compétence.",
  "chat.skill.message.createSuccess": "Création de la compétence avec succès.",
  "chat.skill.message.delete": "Supprimer",
  "chat.skill.message.error": "Erreur",
  "chat.skill.message.failDeleteSkill": "Impossible de supprimer la compétence.",
  "chat.skill.message.isExisted": "La file d'attente de compétences existe déjà.",
  "chat.skill.message.priorityLength": "Assurez-vous que la priorité est inférieure ou égale à cinq chiffres.",
  "chat.skill.message.saveFail": "Impossible de sauvegarder la compétence.",
  "chat.skill.message.saveSuccess": "Sauvegardé la compétence avec succès.",
  "chat.skill.message.selectSkills": "Sélectionnez une compétence.",
  "chat.skill.message.success": "Succès",
  "chat.skill.message.warning": "Avertissement",
  "chat.skill.message.willDeleteAllSkills": "Êtes-vous sûr de vouloir supprimer toutes les compétences ?",
  "chat.skill.message.willDeleteSkill": "Êtes-vous sûr de vouloir supprimer la compétence ?",
  "chat.skill.message.willDeleteSkills": "Êtes-vous sûr de vouloir supprimer les compétences sélectionnées ?",
  "chat.thirdportal.chatWindow.button.startChat": "Démarrer le chat",
  "chat.thirdportal.chatWindow.label.userEmail": "Courriel",
  "chat.thirdportal.chatWindow.label.userName": "Nom et prénom",
  "chat.thirdportal.chatWindow.label.userPhone": "Numéro",
  "chat.thirdportal.title": "Portail Web Client",
  "chat.timerange.label.alldays": "Tout le temps de création",
  "chat.timerange.label.confirmSocailChatNum": "Le nombre maximum de clients du service simultané est 0 utilisera la configuration globale",
  "chat.timerange.label.customizes": "Personnalisé",
  "chat.timerange.label.ok": "OK",
  "chat.timerange.label.oneday": "Dernier jour",
  "chat.timerange.label.sevendays": "7 derniers jours",
  "chat.timerange.label.thirtydays": "30 derniers jours",
  "chat.timerange.label.threedays": "Les 3 derniers jours",
  "chat.timerange.message.timeempty": "Le temps ne peut pas être vide.",
  "chat.timerange.message.timeerror": "Le format de l'heure est incorrect.",
  "chat.usefulexpressions.label.createExpressionTab": "Créer une expression commune",
  "chat.usefulexpressions.label.expression": "Phrases courantes",
  "chat.usefulexpressions.label.expressionDetail": "Détails de la phrase commune",
  "chat.usefulexpressions.message.contentLength": "Assurez-vous que la longueur de la phrase courante est inférieure ou égale à 1000 caractères.",
  "chat.usefulexpressions.message.contentNotNull": "La phrase courante ne peut pas être vide.",
  "chat.usefulexpressions.message.createFail": "Impossible de créer l'expression commune.",
  "chat.usefulexpressions.message.createSuccess": "Création de la phrase commune avec succès.",
  "chat.usefulexpressions.message.failDeleteExpression": "Impossible de supprimer l'expression courante.",
  "chat.usefulexpressions.message.isExisted": "Le nom de phrase commun existe déjà.",
  "chat.usefulexpressions.message.saveFail": "Impossible d'enregistrer l'expression courante.",
  "chat.usefulexpressions.message.saveSuccess": "Enregistré l'expression commune avec succès.",
  "chat.usefulexpressions.message.selectExpressions": "Sélectionnez une phrase commune.",
  "chat.usefulexpressions.message.willDeleteExpression": "Êtes-vous sûr de vouloir supprimer l'expression courante ?",
  "chat.usefulexpressions.message.willDeleteExpressions": "Êtes-vous sûr de vouloir supprimer l'expression courante sélectionnée?",
  "chat.web.evaluation.star1": "Très insatisfait",
  "chat.web.evaluation.star2": "Insatisfait",
  "chat.web.evaluation.star3": "Généralités",
  "chat.web.evaluation.star4": "Satisfait",
  "chat.web.evaluation.star5": "Très satisfait",
  "chat.webconfig.button.save": "Enregistrer",
  "chat.webconfig.label.bandingskill": "File d'attente de compétences de liaison",
  "chat.webconfig.label.basesettings": "Configuration de base",
  "chat.webconfig.label.functionalsettings": "Paramètres de fonction",
  "chat.webconfig.label.greeting": "Salutations",
  "chat.webconfig.label.greeting.placeholder": "Veuillez entrer le contenu de l'accueil qui est affiché dans la fenêtre de discussion du client.",
  "chat.webconfig.label.intergratedcode": "Code intégré",
  "chat.webconfig.label.intergratemsg": "Intégrez le chat en direct à votre site Web",
  "chat.webconfig.label.language": "Langue par défaut",
  "chat.webconfig.label.language.en": "Anglais",
  "chat.webconfig.label.language.zh": "Chinois",
  "chat.webconfig.label.language.pt": "Portugais",
  "chat.webconfig.label.transHuman": "Transfert à l'agent",
  "chat.webconfig.label.windowTitle": "Titre de la fenêtre de chat",
  "chat.webconfig.label.windowTitle.placeholder": "Veuillez saisir le titre qui est affiché dans la fenêtre de discussion sur le client.",
  "chat.webconfig.message.configdirective": "Copiez le code suivant et collez-le avant l'étiquette de votre site Web </body>. Enregistrez et libérez le code pour compléter l'accès rapide à Huawei Service Cloud. Après la publication, l'entrée de Huawei Service Cloud s'affiche dans le coin inférieur droit de votre site Web. Vous pouvez cliquer dessus pour parler avec l'établi du service client en temps réel.",
  "chat.webconfig.message.demodirective": "Vous pouvez également ouvrir l'adresse suivante dans la fenêtre cachée du navigateur Chrome pour accéder à la page de présentation fournie par le système:",
  "chat.webconfig.message.saveFail": "Impossible d'enregistrer la configuration.",
  "chat.webconfig.message.saveSuccess": "Enregistré la configuration avec succès.",
  "chat.webconfig.title": "Activer le canal Web",
  "chat.weChatConfig.label.appId": "ID du développeur",
  "chat.weChatConfig.label.AppSecret": "Mot de passe développeur",
  "chat.weChatConfig.label.bindSkill": "File d'attente de compétences de liaison",
  "chat.weChatConfig.label.codeName": "Code d'accès à la chaîne",
  "chat.weChatConfig.label.configId": "ID de configuration",
  "chat.weChatConfig.label.create": "Nouveau",
  "chat.weChatConfig.label.delete": "Supprimer",
  "chat.weChatConfig.label.Domain": "Adresse du serveur",
  "chat.weChatConfig.label.facebook": "FaceBook",
  "chat.weChatConfig.label.nextStep": "Suivant",
  "chat.weChatConfig.label.qcode": "Code QR",
  "chat.weChatConfig.label.save": "Enregistrer",
  "chat.weChatConfig.label.search": "Rechercher",
  "chat.weChatConfig.label.secret": "Clé",
  "chat.weChatConfig.label.state": "Statut",
  "chat.weChatConfig.label.tenantId": "ID du locataire",
  "chat.weChatConfig.label.verify": "Vérification",
  "chat.weChatConfig.label.verifycode": "Jeton",
  "chat.weChatConfig.label.verifying": "Vérification",
  "chat.weChatConfig.label.wechat": "WeChat",
  "chat.weChatConfig.link.wechat": "Serveur WeChat",
  "chat.weChatConfig.message.appIdExisted": "L'ID de développeur du compte public entré existe déjà.",
  "chat.weChatConfig.message.codeNameExisted": "Le code d'accès au canal existe déjà.",
  "chat.weChatConfig.message.deleteError": "Impossible de supprimer l'élément de configuration.",
  "chat.weChatConfig.message.emptyDeleteArray": "Sélectionnez l'élément de configuration à supprimer.",
  "chat.weChatConfig.message.ensureDelete": "Voulez -vous vraiment supprimer l'élément de configuration sélectionné ?",
  "chat.weChatConfig.message.error": "Erreur",
  "chat.weChatConfig.message.savesuccess": "Accès réussi.",
  "chat.weChatConfig.message.skillExisted": "La file d'attente de compétences sélectionnée a été liée à un autre compte public.",
  "chat.weChatConfig.message.unknown": "Le statut de la vérification est inconnu.",
  "chat.weChatConfig.stepname.selectchannel": "Sélectionnez le canal d'accès",
  "chat.weChatConfig.stepname.selectmode": "Sélectionnez la méthode de configuration",
  "chat.weChatConfig.stepname.success": "Succès d'accès",
  "chat.weChatConfig.stepname.verify": "Soumettre pour vérification",
  "chat.weChatConfig.title": "Activation de la configuration des médias sociaux",
  "chat.weChatConfig.title.deleteTitle": "Supprimer les éléments de configuration WeChat",
  "cmessaging.chat.answerUser.error": "Impossible de créer une session utilisateur.",
  "cmessaging.chat.answerUser.Tips": "Création d'une session utilisateur",
  "cmessaging.chat.channelconfig.isPushWebMessage": "Message Push",
  "cmessaging.chat.channelconfig.pushWebMessageKey": "Clé",
  "cmessaging.chat.channelconfig.pushWebMessageUrl": "Adresse Push du message",
  "cmessaging.chat.channelconfig.pushWebCertTypeSignature": "Authentification de la signature",
  "cmessaging.chat.channelconfig.pushWebCertTypeOAuth": "Authentification OAuth",
  "cmessaging.chat.channelconfig.setAgentTimeout": "Session de transfert en raison de l'absence de réponse de l'agent",
  "cmessaging.chat.multimedia.numOverMaxSum": "La quantité de données multimédia que vous avez sauvegardées dépasse la limite",
  "cmessaging.chat.multimediatype.numOverMaxSum": "Le nombre de catégories que vous avez sauvegardées dépasse la limite",
  "ccmessaging.socialPostOperation.search.searchContent": "Saisissez le contenu.",
  "ccmessaging.socialPostOperation.link": "Accéder à l'original",
  "ccmessaging.chat.social.media.enterprise.post.load.failed": "Impossible de charger la pièce jointe.",
  "ccmessaging.chat.multimedia.imageUploadChannelFormat": "Les canaux WhatsApp, web, Telegram, LINE, WeChat, Facebook et Twitter prennent en charge les formats JPG, PNG et JPEG.",
  "ccmessaging.chat.multimedia.audioUploadChannelFormat": "Le canal WhatsApp prend en charge le format ACC.\nLe canal web prend en charge les formats MP3 et AAC.\nLes canaux Instagram et LINE prennent en charge le format M4A.\nLes canaux WeChat et Facebook prennent en charge le format MP3.\nLe canal Telegram prend en charge les formats MP3 et M4A.",
  "ccmessaging.chat.multimedia.documentUploadChannelFormat": "Le canal WhatsApp prend en charge les formats DOC, DOCX, PDF, PPT, PPTX, XLSX et XLS.\nLe canal web prend en charge les formats DOC, DOCX, PDF et ZIP.\nLe canal Telegram prend en charge les formats PDF et ZIP.",
  "ccmessaging.chat.channelconfig.searchChannelName": "Nom du canal de recherche",
  "ccmessaging.chat.channelconfig.createChannel": "Créer un canal",
  "ccmessaging.chat.channelconfig.modifyChannel": "Modifier le canal",
  "ccmessaging.chat.channelconfig.channelIntegrate": "Intégration des canaux",
  "ccmessaging.chat.channelconfig.baseConfig": "Configuration de base",
  "ccmessaging.chat.channelconfig.robotConfig": "Configuration du robot",
  "ccmessaging.chat.channelconfig.chooseChannel": "Sélectionner un canal",
  "ccmessaging.chat.channelconfig.range.placeholder": "{0}-{1}",
  "ccmessaging.chat.channelconfig.advanceConfig": "Plus",
  "ccmessaging.chat.channelconfig.web.clickToCall": "Cliquez pour appeler",
  "ccmessaging.chat.channelconfig.time.timeNoRange": "Por favor, complete o elimine Periodo {n}",
  "ccmessaging.chat.channelconfig.time.timeCross": "La période {0} chevauche la période {1}.",
  "ccmessaging.chat.channelconfig.time.timePeriod0": "Veuillez remplir Période {n}",
  "ccmessaging.chat.channelconfig.time.timePeriod": "Veuillez remplir ou supprimer Période {n}",
  "ccmessaging.silentagent.button.create": "Nouveau",
  "ccmessaging.silentagent.dialog.title.create": "Nouveau Agt vocal silencieux",
  "ccmessaging.silentagent.dialog.title.edit": "Modifier Agt vocal silencieux",
  "ccmessaging.silentagent.dialog.title.view": "voir Agt vocal silencieux",
  "ccmessaging.silentagent.delete.selectone": "Veuillez sélectionner au moins un enregistrement.",
  "ccmessaging.silentagent.delete.select.morethan.100": "Un maximum de 100 enregistrements de données peuvent être supprimés à la fois.",
  "ccmessaging.silentagent.placeholder.selectaudioskill": "Veuillez sélectionner une file d'attente de compétences vocales.",
  'ccmessaging.silentagent.btn.add.period': 'ajouter une période',
  'ccmessaging.silentagent.agent.workday.worktime.error': "La configuration du temps de travail de l'agent est incorrecte. Les segments de temps se chevauchent.",
  'ccmessaging.silentagent.agent.holiday.worktime.error': "La configuration du temps de non-travail de l'agent est incorrecte. Les segments de temps se chevauchent.",
  'ccmessaging.silentagent.agent.workday.worktime.empty': "La configuration du temps de travail de l'agent a une configuration vide.",
  'ccmessaging.silentagent.agent.holiday.worktime.empty': "La configuration du temps de non-travail de l'agent a une configuration vide.",
  "ccmessaging.silentagent.voiceaccess.empty": "L'IVR vocal appelé configuration de route ne peut pas être vide",
  "ccmessaging.chat.channelconfig.searchCertCode": "Recherche d'un code de certificat",
  "ccmessaging.chat.channelconfig.cloudApi.accessToken.tips": "Le jeton d'accès est le jeton d'accès généré par la plate-forme du développeur pour accéder à l'API cloud. La période de validité peut être réduite de 2 jours en fonction de la situation réelle. Par exemple\n, si la période de validité est de 60 jours.",
  "ccmessaging.chat.channelconfig.cloudApi.bsp.tips": "Actuellement, l'API WhatsApp Cloud ne s'interconnecte qu'avec les messages texte.",
  "ccmessaging.chat.channelconfig.checkWhatsAppAccessTokenDuration": "La période de validité du jeton d'accès doit être un entier positif compris entre 1 et 60",
  "ccmessaging.chat.channelconfig.copy": "Copier",
  "ccmessaging.chat.channelconfig.copySuccess": "Réplication réussie",
  "ccmessaging.chat.sumbitverification.whatsApp.cloudapi.tips": "Configurez l'URL et le code de vérification dans l'application WhatsApp sur la plateforme de développement Facebook",
  "ccmessaging.chatbot.label.useIntelligentRobot": "Connecter un robot intelligent",
  "ccmessaging.chatbot.label.useRobotAssistants": "Assistant robot d'accès",
  "ccmessaging.chatbot.label.update": "Mise à jour",
  "ccmessaging.chatbot.label.pleaseSelect": "Veuillez sélectionner",
  "ccmessaging.chatbot.label.pleaseInput": "Veuillez saisir",
  "ccmessaging.chatbot.label.intelligentRobotAccessCode": "Code d'accès robot intelligent",
  "ccmessaging.chatbot.label.selectIntelligentRobot": "Sélectionner un robot intelligent",
  "ccmessaging.chatbot.label.selectRobotAssistants": "Sélectionner l'assistant robot",
  "ccmessaging.chatbot.check.systeam": "système",
  "ccmessaging.chatbot.label.robotName": "Nom du robot",
  "ccmessaging.chatbot.label.selectRobotAvatar": "Sélectionner l'avatar du robot",
  "ccmessaging.fiveG.label.chatRobotAddress": "Adresse du chatbot",
  "ccmessaging.fiveG.label.appId": "ID de l'application",
  "ccmessaging.fiveG.label.appSecret": "Secret de l'application",
  "ccmessaging.fiveG.label.certCode": "Fichier de certificat",
  "ccmessaging.email.label.selectGateway": "Sélectionner une passerelle",
  "ccmessaging.email.label.gatewayName": "Nom de la passerelle",
  "ccmessaging.email.label.InputGatewayName": "Entrez le nom de la passerelle.",
  "ccmessaging.email.label.defaultReceiveGateway": "E-mail de réception par défaut",
  "ccmessaging.email.label.defaultSendGateway": "E-mail d'envoi par défaut",
  "ccmessaging.sms.label.defaultSendGateway": "Numéro d'envoi par défaut",
  "ccmessaging.sms.label.defaultReceiveGateway": "Numéro de réception par défaut",
  "ccmessaging.email.valid.signatureSearch": "Seuls les lettres et les chiffres sont autorisés.",
  "ccmessaging.service.time.label": "Configuration du calendrier de travail",
  "ccmessaging.service.time.choose.time": "Sélectionner les jours non ouvrables",
  "ccmessaging.service.time.select.holiday": "Sélectionner les jours non ouvrables",
  "ccmessaging.service.time.select.weekday": "Sélectionner les dates de travail",
  "ccmessaging.multimedia.tip.not.exist": "La ressource multimédia n'existe pas",
  "ccmessaging.multimedia.tip.not.modified": "La ressource multimédia a été modifiée. Envoyez-la à nouveau",
  "ccmessaging.service.time.close.alert": "Fermer effacera toutes les données de la table, confirmera la fermeture",
  "ccmessaging.chat.phrase.create": "Créer Phrases Communes",
  "ccmessaging.chat.phrase.update": "Modifier Phrases Courantes",
  "ccmessaging.chat.specialChannel.create": "Creación de una regla de lista especial",
  "ccmessaging.chat.specialChannel.update": "Modificación de una regla de lista especial",
  "ccmessaging.emailAdress.contentNotNull": "L'adresse e-mail est obligatoire",
  "ccmessaging.smsNumber.contentNotNull": "Le numéro de portable est obligatoire",
  "ccmessaging.callcenterinstanceslist.vcallcenterDeatil.passValidateFailed": "Le mot de passe doit contenir au moins trois des types suivants: majuscules, minuscules, chiffres et caractères: {n}",
  "ccmessaging.chat.shortcut.shortcutRepeat": "Raccourci clavier déjà utilisé",
  "ccmessaging.chat.dy.reconnect": "Reconnexion",
  "ccmessaging.chat.dy.uploadLocalFile": 'Fichiers locaux',
  "ccmessaging.chat.dy.call.end": 'Fin de la session',
  "ccmessaging.chat.richtext.httpTip": "Le lien inséré utilise des protocoles non sécurisés, ce qui présente des risques de sécurité. Voulez-vous vraiment soumettre",
  "ccmessaging.chat.riskTip": "Avertissement de risque",
  "ccmessaging.chat.chatcard.location": "Adresse",
  "ccmessaging.chat.chatcard.alignment": "style d'affichage de la carte",
  "ccmessaging.chat.chatcard.templateType": "type de modèle",
  "ccmessaging.chat.chatcard.templateTypeList0": "Type commun",
  "ccmessaging.chat.chatcard.templateTypeList1": "Options de carte",
  "ccmessaging.chat.chatcard.tabMediaResource": "Actifs multimédias image",
  "ccmessaging.chat.chatcard.tabnamevalidate": "redirectUrl est la valeur par défaut du système et son utilisation n'est pas autorisée.",
  "ccmessaging.chat.chatcard.transverse": "Horizontal",
  "ccmessaging.chat.chatcard.longitudinal": "Portrait",
  "ccmessaging.chat.voice.record.send": "Envoyer la voix",
  "ccmessaging.chat.voice.record.start": "Cliquez pour enregistrer",
  "ccmessaging.chat.voice.record.tip": "La durée de l'enregistrement ne dépasse pas 10 minutes",
  "ccmessaging.chat.voice.record.stop": "Arrêter l'enregistrement",
  "ccmessaging.chat.selectorgs.maxlimit.warn": "Le nombre d'organisations sélectionnées ne peut pas dépasser 100.",
  "ccmessaging.chat.chatconfig.deleteFacebookPage": "Supprimer la page d'accueil Facebook",
  "ccmessaging.chat.search.name": "Recherche Nom",
  "ccmessaging.chat.search.phraseName": "Phrases courantes",
  "ccmessaging.chat.channelconfig.asyncTime.tip": "Si la délai après le déclenchement du scénario hors ligne dépasse cette valeur, les messages hors ligne des clients seront directement stockés et ne seront plus acheminnés vers les agents.",
  "ccmessaging.chat.channelconfig.noReplyTime.tip": "Si le temps après la dernière fois où un client a contacté un agent dépasse cette valeur, l'agent ne peut plus faire un appel sortant vers le client.",
  "ccmessaging.chat.channelconfig.noReplyIntervalTime.tip": "Dans le scénario d'appels sortants, si un client ne répond pas dans les limites de ce délai, la session s'arrête automatiquement.",
  "ccmessaging.chat.email.attachment.tips.maxOnceUpload": "Un maximum de cinq pièces jointes peuvent être téléchargées.",
  "ccmessaging.chat.email.attachment.tips.maxTotalUpload": "Le nombre de pièces jointes téléchargées dépasse 10.",
  "cmessaging.chat.multimedia.agentLogoutHangupResult": "L'agent se déconnecte de force et la session se termine. Veuillez fermer l'ordre de travail CRM",
  "ccmessaging.chat.voice.record": "Enregistrer",
  "ccmessaging.chat.offline.userIsInService": "Utilisateur de service d'agent existant",
  "ccmessaging.chat.channelconfig.search.by.robotNameKeyword": "Nom du robot de recherche ou code d'accès",
  "ccmessaging.chat.channelconfig.searchSkillQueueName": "Rechercher le nom de la file d'attente de compétences",
  "ccmessaging.chat.user.online": "L'utilisateur est en ligne.",
  "ccmessaging.chat.async.echarts.user": "Nombre de personnes (nombre de fois)",
  "ccmessaging.chat.async.echarts.message": "Message(s)",
  "cobrowse.sessionrecord.roomId": "Numéro de chambre",
  "cobrowse.sessionrecord.searchroomId": "Veuillez saisir le numéro de chambre",
  "cobrowse.sessionrecord.workNo": "Numéro d'agent",
  "cobrowse.sessionrecord.searchworkNo": "Veuillez entrer le numéro d'agent",
  "cobrowse.sessionrecord.caller": "Caller",
  "cobrowse.sessionrecord.searchcaller": "Veuillez entrer les informations de l'appelant",
  "cobrowse.sessionrecord.startAt": "Heure de début",
  "cobrowse.sessionrecord.endAt": "Temps de fin",
  "cobrowse.sessionrecord.duration": "Durée",
  "cobrowse.sessionrecord.positiveInteger.checkmsg": "Entrez un entier positif",
  "cobrowse.sensitiveinfo.pageMatchValue": "Configuration de la correspondance des pages",

  "cobrowse.sensitiveinfo.rulenamecheck": "Le nom de la règle ne peut contenir que des _. alphanumériques et un maximum de 128 caractères",
  "cobrowse.sensitiveinfo.url.check": "Le chemin d'URL configuré pour la correspondance des pages est dans un format incorrect",
  "cobrowse.sensitiveinfo.cssselectorcheck": "Erreur de syntaxe du sélecteur CSS",
  "cobrowse.sensitiveinfo.pageIdCheck": "L'ID de page ne peut contenir que des lettres, des chiffres, _-:. et un maximum de 1024 caractères.",
  "cobrowse.sensitiveinfo.create": "Créer une nouvelle règle d'information sensible",
  "cobrowse.sensitiveinfo.edit": "Modifier une nouvelle règle d'information sensible",
  "cobrowse.sensitiveinfo.createnameexist": "nom existe déjà",
  "cobrowse.sensitiveinfo.exceedmaxcount": "Jusqu'à 100 règles sensibles peuvent être configurées par locataire",
  "cobrowse.sensitiveinfo.delete": "Supprimer la règle d'information sensible",
  "cobrowse.sensitiveinfo.willdeletetips": "Êtes-vous sûr de vouloir supprimer la règle d'informations sensibles sélectionnée ?",
  "cobrowse.sensitiveinfo.deletenum.outstripping": "Un maximum de 100 enregistrements peuvent être supprimés à la fois",
  "cobrowse.common.tips.selectempty": "Veuillez sélectionner les données à utiliser en premier",
  "cobrowse.sessionrecord.workNo.rules": "Doit être de l'ordre de 1 - 65535",
  "ccmessaging.chat.chatconfig.batch.delete": "Suppression par lots",
  "ccmessaging.chat.chatconfig.create": "Nouveau",
  "ccmessaging.channelconfig.export.datascope": "Portée des données",
  "ccmessaging.channelconfig.export.allscope": "Tous",
  "ccmessaging.channelconfig.export.curPage": "Page courante",
  "ccmessaging.channelconfig.export.customField": "Champ personnalisé",
  "ccmessaging.channelconfig.export.selectedField": "Champ d'exportation",
  "ccmessaging.channelconfig.export.selectAll": "Tous",
  "ccmessaging.channelconfig.export.zipPwd": "Mot de passe compressé",
  "ccmessaging.channelconfig.export.zipPwdHint": "Veuillez saisir le mot de passe de compression",
  "ccmessaging.channelconfig.export.pwdMsg": "Mot de passe pour générer un fichier compressé. La valeur est une chaîne de 8 à 12 caractères et doit contenir des lettres, des chiffres et des caractères spéciaux (à l'exclusion des espaces de {'|;&$-<>/'}{'\\\\'}{'`!#(){}'}, des caractères de nouvelle ligne et des caractères de tabulation).",
  'ccmessaging.channelconfig.export.pwdMsgError': "La valeur est une chaîne de 8 à 12 caractères et doit contenir des lettres, des chiffres et des caractères spéciaux (à l'exclusion des espaces de {'|;&$-<>/'}{'\\\\'}{'`!#(){}'}, des caractères de nouvelle ligne et des caractères de tabulation).",
  "ccmessaging.channelconfig.export": "Exporter",
  "ccmessaging.channelconfig.viewExportTask": "Exporter la vue des tâches",
  "ccmessaging.channelconfig.export.title": "Configuration du canal d'exportation",
  'ccmessaging.channelconfig.export.exportAll': 'enregistrements ? Vous pouvez également annuler l\'opération et sélectionner un enregistrement spécifique à exporter.',
  'ccmessaging.channelconfig.export.exportAllTitle': "Exporter toutes les configurations",
  "ccmessaging.channelconfig.export.allExportConfirm": "Tout Exporter",
  'ccmessaging.channelconfig.export.exportSelect': "Vous avez sélectionné {0} enregistrements. Êtes-vous sûr de vouloir continuer ?",
  "ccmessaging.channelconfig.export.exportBeginTime": "Exporter l'heure de début",
  "ccmessaging.channelconfig.export.exportEndTime": "Exporter l'heure de fin",
  "ccmessaging.channelconfig.export.exportTaskStatus": "Statut",
  "ccmessaging.channelconfig.export.exporting": "Exportation",
  "ccmessaging.channelconfig.export.unprocessed": "Non-traité",
  'ccmessaging.channelconfig.export.resultMsg': 'La tâche d\'exportation a été créée avec succès. Voulez-vous la voir dans la liste d\'exportation ?',
  "ccmessaging.channelconfig.export.resultSuccess": "Créé avec succès.",
  "ccmessaging.channelconfig.export.exception": "Une erreur s'est produite lors de la génération de la tâche d'exportation. Vérifie les journaux.",
  "ccmessaging.channelconfig.export.error": "La nouvelle tâche d'exportation a échoué, veuillez vérifier le journal",
  "ccmessaging.channelconfig.export.paramInvalid": "Impossible de vérifier les conditions d'exportation.",
  "ccmessaging.channelconfig.export.exceedlimit": "Un maximum de {0} enregistrements de données peuvent être exportés",
  "ccmessaging.channelconfig.export.nodata": "Aucune configuration de canal ne répond aux conditions.",
  "ccmessaging.chat.holiday.deleteWorkDay": "Supprimer les dates de travail spécifiées",
  "ccmessaging.chat.holiday.deleteAllWorkDay": "Supprimer toutes les dates de travail spécifiées",
  "ccmessaging.chat.holiday.deleteHoliday": "Suppression d'une date de repos spécifiée",
  "ccmessaging.chat.holiday.deleteAllHoliday": "Supprimer toutes les dates de repos spécifiées",
  "ccmessaging.multimedia.leftMenu.category": 'Catégorie',
  "ccmessaging.multimedia.leftMenu.createCategory": 'Créer Catégorie',
  "ccmessaging.multimedia.leftMenu.editCategory": 'Modifier Catégorie',
  "ccmessaging.multimedia.audio.create": `Créer Voix`,
  "ccmessaging.multimedia.audio.edit": `Modifier Voix`,
  "ccmessaging.multimedia.video.create": `Créer Vidéo`,
  "ccmessaging.multimedia.video.edit": `Modifier Vidéo`,
  "ccmessaging.multimedia.image.create": `Créer Image`,
  "ccmessaging.multimedia.image.edit": `Modifier Image`,
  "ccmessaging.multimedia.emoticon.create": `Créer Émoticône`,
  "ccmessaging.multimedia.emoticon.edit": `Modifier Émoticône`,
  "ccmessaging.multimedia.document.create": `Créer Document`,
  "ccmessaging.multimedia.document.edit": `Modifier Document`,
  "ccmessaging.multimedia.richText.create": 'Créer Texte Riche',
  "ccmessaging.multimedia.richText.edit": 'Modifier Texte Riche',
  "ccmessaging.multimedia.local.create": `Créer Adresse`,
  "ccmessaging.multimedia.local.edit": `Modifier Adresse`,
  'ccmessaging.multimedia.whatsAppTemplate.create': 'Créer Modèle WhatsApp',
  'ccmessaging.multimedia.whatsAppTemplate.edit': 'Modifier Modèle WhatsApp',
  "ccmessaging.multimedia.siteAddr.create": `Créer Site Cobrowse`,
  "ccmessaging.multimedia.siteAddr.edit": `Modifier Site Cobrowse`,
  "ccmessaging.multimedia.cardFiveG.create": `Créer Carte 5G`,
  "ccmessaging.multimedia.cardFiveG.edit": `Modifier Carte 5G`,
  "ccmessaging.multimedia.carouselCardFiveG.create": `Créer Carte Carrousel 5G`,
  "ccmessaging.multimedia.carouselCardFiveG.edit": `Modifier Carte Carrousel 5G`,
  'ccmessaging.multimedia.whatsAppTemplateMsg.create': 'Créer Message Modèle WhatsApp',
  'ccmessaging.multimedia.whatsAppTemplateMsg.edit': 'Modifier Message Modèle WhatsApp',
  "ccmessaging.multimedia.cardWeb.create": `Créer Carte Web`,
  "ccmessaging.multimedia.cardWeb.edit": `Modifier Carte Web`,
  "chat.agent.qualityCheckWorkbench.title": "Contrôle qualité",
  "chat.agent.qualityCheckWorkbench.currentAgent": "Sièges de surveillance actuels",
  "chat.agent.qualityCheckWorkbench.subscribeSessionFailed": "La session d'agent d'abonnement a échoué",
  "chat.agent.qualityCheckWorkbench.cancelSubscribeSessionFailed": "La désinscription de la session proxy a échoué",
  "chat.agent.qualityCheckWorkbench.pollMsgFailed": "L'obtention des informations sur le Siège a échoué",
  "chat.agent.qualityCheckWorkbench.closeConfirm": "Veuillez confirmer que la vérification de la qualité de la session multimédia pour votre siège actuel est terminée?",
  "chat.agent.qualityCheckWorkbench.listen": "Annuler l'insertion",
  "chat.agent.qualityCheckWorkbench.insert": "Insérer",
  "chat.agent.qualityCheckWorkbench.intercept": "Interception",
  "chat.agent.qualityCheckWorkbench.changeQualityStatus.fail": "La modification de l'état du contrôle de qualité a échoué",
  "chat.agent.msg.redis.connection.exception.description": "Erreur système actuelle, veuillez patienter",
  "ccmessaging.chat.phrase.editPhraseTypeFail": "Échec de la modification du type de phrase commun",
  "ccmessaging.chat.phrase.editPhraseTypeSucess": "Modifier le type de phrase commun avec succès",
  "ccmessaging.chat.phrase.editPhraseFail": "Échec de la modification de la langue commune",
  "ccmessaging.chat.phrase.editPhraseSucess": "Modifier la phrase commune avec succès",
  "ccmessaging.chat.multimedia.editMultiMediaInfo": "Modifier les données multimédia avec succès",
  "ccmessaging.chat.multimedia.editMultiMediaInfoError": "Échec de la modification des données multimédia",
  "ccmessaging.chat.multimedia.editMultiMediaType": "Modifier le type multimédia avec succès",
  "ccmessaging.chat.multimedia.editMultiMediaTypeError": "Échec de la modification du type multimédia",
  "ccmessaging.chat.multimedia.createMultiMediaInfo": "Créer des données multimédia avec succès",
  "ccmessaging.chat.multimedia.createMultiMediaInfoError": "Échec de la création de données multimédia",
  "ccmessaging.chat.multimedia.createMultiMediaType": "Créer un type multimédia avec succès",
  "ccmessaging.chat.multimedia.createMultiMediaTypeError": "Échec de la création du type multimédia",
  "ccmessaging.chat.phrase.createTypeSuccess": "Créer un type de phrase commun avec succès",

  "ccmessaging.chat.multimedia.createVideoInfo": "Créer des données vidéo avec succès",
  "ccmessaging.chat.multimedia.createVideoInfoError": "Échec de la création de données vidéo",
  "ccmessaging.chat.multimedia.createVideoType": "Créer un type de vidéo avec succès",
  "ccmessaging.chat.multimedia.createVideoTypeError": "Échec de la création du type de vidéo",
  "ccmessaging.chat.multimedia.editVideoInfo": "Modifier les données vidéo avec succès",
  "ccmessaging.chat.multimedia.editVideoInfoError": "Échec de la modification des données vidéo",
  "ccmessaging.chat.multimedia.editVideoType": "Modifier le type de vidéo avec succès",
  "ccmessaging.chat.multimedia.editVideoTypeError": "Échec de la modification du type de vidéo",
  "ccmessaging.chat.multimedia.deleteVideoInfo": "Supprimer les données vidéo avec succès",
  "ccmessaging.chat.multimedia.deleteVideoInfoError": "Échec de la suppression des données vidéo",
  "ccmessaging.chat.multimedia.deleteVideoType": "Supprimer le type de vidéo avec succès",
  "ccmessaging.chat.multimedia.deleteVideoTypeError": "Échec de la suppression du type de vidéo",
  "ccmessaging.chat.multimedia.deleteVideoTypeError.usedbyODFS": "La suppression du type de vidéo a échoué. Les ressources référencées par le RVI intelligent existent.",
  "ccmessaging.chat.multimedia.deleteVideoTypeError.referenced.byCard": "La suppression du type de vidéo a échoué, car certaines ressources sont déjà référencées par les cartes.",
  "ccmessaging.chat.multimedia.deleteVideoTypeError.referenced.byWhatsAppTemplateMsg": "La suppression du type de vidéo a échoué, car certaines ressources sont déjà référencées par un message de modèle WhatsApp.",
  "ccmessaging.chat.multimedia.createAudioInfo": "Créer des données audio avec succès",
  "ccmessaging.chat.multimedia.createAudioInfoError": "Échec de la création de données audio",
  "ccmessaging.chat.multimedia.createAudioType": "Créer un type audio avec succès",
  "ccmessaging.chat.multimedia.createAudioTypeError": "Échec de la création du type audio",
  "ccmessaging.chat.multimedia.editAudioInfo": "Modifier les données audio avec succès",
  "ccmessaging.chat.multimedia.editAudioInfoError": "Échec de la modification des données audio",
  "ccmessaging.chat.multimedia.editAudioType": "Modifier le type audio avec succès",
  "ccmessaging.chat.multimedia.editAudioTypeError": "Échec de la modification du type audio",
  "ccmessaging.chat.multimedia.deleteAudioInfo": "Supprimer les données audio avec succès",
  "ccmessaging.chat.multimedia.deleteAudioInfoError": "Échec de la suppression des données audio",
  "ccmessaging.chat.multimedia.deleteAudioType": "Supprimer le type audio avec succès",
  "ccmessaging.chat.multimedia.deleteAudioTypeError": "Échec de la suppression du type audio",
  "ccmessaging.chat.multimedia.deleteAudioTypeError.usedbyODFS": "Échec de la suppression du type audio. Les ressources référencées par le RVI intelligent existent.",
  "ccmessaging.chat.multimedia.deleteAudioTypeError.referenced.byCard": "La suppression du type audio a échoué, car certaines ressources sont déjà référencées par les cartes.",
  "ccmessaging.chat.multimedia.deleteAudioTypeError.referenced.byWhatsAppTemplateMsg": "La suppression du type audio a échoué, car certaines ressources sont déjà référencées par un message de modèle WhatsApp.",
  "ccmessaging.chat.multimedia.createImageInfo": "Créer des données d'image avec succès",
  "ccmessaging.chat.multimedia.createImageInfoError": "Échec de la création de données d'image",
  "ccmessaging.chat.multimedia.createImageType": "Créer le type d'image avec succès",
  "ccmessaging.chat.multimedia.createImageTypeError": "Échec de la création du type d'image",
  "ccmessaging.chat.multimedia.editImageInfo": "Modifier les données de l'image avec succès",
  "ccmessaging.chat.multimedia.editImageInfoError": "Échec de la modification des données de l'image",
  "ccmessaging.chat.multimedia.editImageType": "Modifier le type d'image avec succès",
  "ccmessaging.chat.multimedia.editImageTypeError": "Échec de la modification du type d'image",
  "ccmessaging.chat.multimedia.deleteImageInfo": "Supprimer les données d'image avec succès",
  "ccmessaging.chat.multimedia.deleteImageInfoError": "Échec de la suppression des données d'image",
  "ccmessaging.chat.multimedia.deleteImageType": "Supprimer le type d'image avec succès",
  "ccmessaging.chat.multimedia.deleteImageTypeError": "Échec de la suppression du type d'image",
  "ccmessaging.chat.multimedia.deleteImageTypeError.usedbyODFS": "Échec de la suppression du type d'image. Les ressources référencées par le RVI intelligent existent.",
  "ccmessaging.chat.multimedia.deleteImageTypeError.referenced.byCard": "La suppression du type d'image a échoué, car certaines ressources sont déjà référencées par les cartes.",
  "ccmessaging.chat.multimedia.deleteImageTypeError.referenced.byWhatsAppTemplateMsg": "La suppression du type d'image a échoué, car certaines ressources sont déjà référencées par un message de modèle WhatsApp.",
  "ccmessaging.chat.multimedia.createDocumentInfo": "Créer des données de document avec succès",
  "ccmessaging.chat.multimedia.createDocumentInfoError": "Échec de la création des données du document",
  "ccmessaging.chat.multimedia.createDocumentType": "Créer le type de document avec succès",
  "ccmessaging.chat.multimedia.createDocumentTypeError": "Échec de la création du type de document",
  "ccmessaging.chat.multimedia.editDocumentInfo": "Modifier les données du document avec succès",
  "ccmessaging.chat.multimedia.editDocumentInfoError": "Échec de la modification des données du document",
  "ccmessaging.chat.multimedia.editDocumentType": "Modifier le type de document avec succès",
  "ccmessaging.chat.multimedia.editDocumentTypeError": "Échec de la modification du type de document",
  "ccmessaging.chat.multimedia.deleteDocumentInfo": "Supprimer les données du document avec succès",
  "ccmessaging.chat.multimedia.deleteDocumentInfoError": "Échec de la suppression des données du document",
  "ccmessaging.chat.multimedia.deleteDocumentType": "Supprimer le type de document avec succès",
  "ccmessaging.chat.multimedia.deleteDocumentTypeError": "Échec de la suppression du type de document",
  "ccmessaging.chat.multimedia.deleteDocumentTypeError.usedbyODFS": "Échec de la suppression du type de document. Les ressources référencées par le RVI intelligent existent.",
  "ccmessaging.chat.multimedia.deleteDocumentTypeError.referenced.byCard": "La suppression du type de document a échoué, car certaines ressources sont déjà référencées par les cartes.",
  "ccmessaging.chat.multimedia.deleteDocumentTypeError.referenced.byWhatsAppTemplateMsg": "La suppression du type de document a échoué, car certaines ressources sont déjà référencées par un message modèle WhatsApp.",
  "ccmessaging.chat.multimedia.createLocateInfo": "Créer des données de localisation avec succès",
  "ccmessaging.chat.multimedia.createLocateInfoError": "Échec de la création des données de localisation",
  "ccmessaging.chat.multimedia.createLocateType": "Créer le type d'emplacement avec succès",
  "ccmessaging.chat.multimedia.createLocateTypeError": "Échec de la création du type d'emplacement",
  "ccmessaging.chat.multimedia.editLocateInfo": "Modifier les données de localisation avec succès",
  "ccmessaging.chat.multimedia.editLocateInfoError": "Échec de la modification des données de localisation",
  "ccmessaging.chat.multimedia.editLocateType": "Modifier le type d'emplacement avec succès",
  "ccmessaging.chat.multimedia.editLocateTypeError": "Échec de la modification du type d'emplacement",
  "ccmessaging.chat.multimedia.deleteLocateInfo": "Supprimer les données de localisation avec succès",
  "ccmessaging.chat.multimedia.deleteLocateInfoError": "Échec de la suppression des données de localisation",
  "ccmessaging.chat.multimedia.deleteLocateType": "Supprimer le type d'emplacement avec succès",
  "ccmessaging.chat.multimedia.deleteLocateTypeError": "Échec de la suppression du type d'emplacement",
  "ccmessaging.chat.multimedia.deleteLocateTypeError.usedbyODFS": "Échec de la suppression du type d'emplacement. Les ressources référencées par le RVI intelligent existent.",
  "ccmessaging.chat.multimedia.deleteLocateTypeError.referenced.byCard": "La suppression du type d'emplacement a échoué, car certaines ressources sont déjà référencées par les cartes.",
  "ccmessaging.chat.multimedia.deleteLocateTypeError.referenced.byWhatsAppTemplateMsg": "La suppression du type d'emplacement a échoué, car certaines ressources sont déjà référencées par un message de modèle WhatsApp.",
  "ccmessaging.chat.multimedia.createEmotionInfo": "Créer des données d'émoticônes avec succès",
  "ccmessaging.chat.multimedia.createEmotionInfoError": "Échec de la création de données d'émoticône",
  "ccmessaging.chat.multimedia.createEmotionType": "Créer le type d'émoticône avec succès",
  "ccmessaging.chat.multimedia.createEmotionTypeError": "Échec de la création du type d'émoticône",
  "ccmessaging.chat.multimedia.editEmotionInfo": "Modifier les données de l'émoticône avec succès",
  "ccmessaging.chat.multimedia.editEmotionInfoError": "Échec de la modification des données de l'émoticône",
  "ccmessaging.chat.multimedia.editEmotionType": "Modifier le type d'émoticône avec succès",
  "ccmessaging.chat.multimedia.editEmotionTypeError": "Échec de la modification du type d'émoticône",
  "ccmessaging.chat.multimedia.deleteEmotionInfo": "Supprimer les données émoticônes avec succès",
  "ccmessaging.chat.multimedia.deleteEmotionInfoError": "Échec de la suppression des données émoticônes",
  "ccmessaging.chat.multimedia.deleteEmotionType": "Supprimer le type d'émoticône avec succès",
  "ccmessaging.chat.multimedia.deleteEmotionTypeError": "Échec de la suppression du type d'émoticône",
  "ccmessaging.chat.multimedia.deleteEmotionTypeError.usedbyODFS": "La suppression du type d'émoticône a échoué. Les ressources référencées par le RVI intelligent existent.",
  "ccmessaging.chat.multimedia.deleteEmotionTypeError.referenced.byCard": "La suppression du type d'émoticône a échoué, car certaines ressources sont déjà référencées par les cartes.",
  "ccmessaging.chat.multimedia.deleteEmotionTypeError.referenced.byWhatsAppTemplateMsg": "La suppression du type d'émoticône a échoué, car certaines ressources sont déjà référencées par un message de modèle WhatsApp.",
  "ccmessaging.chat.multimedia.createRichTextInfo": "Créer des données Rich Text avec succès",
  "ccmessaging.chat.multimedia.createRichTextInfoError": "Échec de la création de données Rich Text",
  "ccmessaging.chat.multimedia.createRichTextType": "Créer un type de texte riche avec succès",
  "ccmessaging.chat.multimedia.createRichTextTypeError": "Échec de la création d'un type de texte riche",
  "ccmessaging.chat.multimedia.editRichTextInfo": "Modifier les données Rich Text avec succès",
  "ccmessaging.chat.multimedia.editRichTextInfoError": "Échec de la modification des données Rich Text",
  "ccmessaging.chat.multimedia.editRichTextType": "Modifier le type de texte riche avec succès",
  "ccmessaging.chat.multimedia.editRichTextTypeError": "Échec de la modification du type de texte riche",
  "ccmessaging.chat.multimedia.deleteRichTextInfo": "Supprimer les données Rich Text avec succès",
  "ccmessaging.chat.multimedia.deleteRichTextInfoError": "Échec de la suppression des données Rich Text",
  "ccmessaging.chat.multimedia.deleteRichTextType": "Supprimer le type de texte riche avec succès",
  "ccmessaging.chat.multimedia.deleteRichTextTypeError": "Échec de la suppression du type de texte riche",
  "ccmessaging.chat.multimedia.deleteRichTextTypeError.usedbyODFS": "Échec de la suppression du type Texte riche. Les ressources référencées par le RVI intelligent existent.",
  "ccmessaging.chat.multimedia.deleteRichTextTypeError.referenced.byCard": "La suppression du type Texte enrichi a échoué, car certaines ressources sont déjà référencées par les cartes.",
  "ccmessaging.chat.multimedia.deleteRichTextTypeError.referenced.byWhatsAppTemplateMsg": "La suppression du type Rich Text a échoué, car certaines ressources sont déjà référencées par un message de modèle WhatsApp.",
  "ccmessaging.chat.multimedia.createSiteAddrInfo": "Créer des données d'adresse de collaboration de page Web avec succès",
  "ccmessaging.chat.multimedia.createSiteAddrInfoError": "Échec de la création des données d'adresse de collaboration de page Web",
  "ccmessaging.chat.multimedia.createSiteAddrType": "Créer une page Web Collaboration Type d'adresse avec succès",
  "ccmessaging.chat.multimedia.createSiteAddrTypeError": "Création d'une page Web Collaboration Type d'adresse échoué",
  "ccmessaging.chat.multimedia.editSiteAddrInfo": "Modifier les données d'adresse de collaboration de page Web avec succès",
  "ccmessaging.chat.multimedia.editSiteAddrInfoError": "Échec de la modification des données d'adresse de collaboration de page Web",
  "ccmessaging.chat.multimedia.editSiteAddrType": "Modifier le type d'adresse de collaboration de page Web avec succès",
  "ccmessaging.chat.multimedia.editSiteAddrTypeError": "Échec de la modification du type d'adresse de collaboration de page Web",
  "ccmessaging.chat.multimedia.deleteSiteAddrInfo": "Supprimer les données d'adresse de collaboration de page Web avec succès",
  "ccmessaging.chat.multimedia.deleteSiteAddrInfoError": "Échec de la suppression des données d'adresse de collaboration de page Web",
  "ccmessaging.chat.multimedia.deleteSiteAddrType": "Supprimer le type d'adresse de collaboration de page Web avec succès",
  "ccmessaging.chat.multimedia.deleteSiteAddrTypeError": "Supprimer le type d'adresse de collaboration de page Web avec succès",
  "ccmessaging.chat.multimedia.deleteSiteAddrTypeError.usedbyODFS": "Échec de la suppression du type d'adresse de collaboration de page Web. Les ressources référencées par le RVI intelligent existent.",
  "ccmessaging.chat.multimedia.deleteSiteAddrTypeError.referenced.byCard": "Supprimer la page Web Collaboration Le type d'adresse a échoué, car certaines ressources sont déjà référencées par des cartes.",
  "ccmessaging.chat.multimedia.deleteSiteAddrTypeError.referenced.byWhatsAppTemplateMsg": "Le type d'adresse de collaboration de page Web a échoué, car certaines ressources sont déjà référencées par un modèle de message WhatsApp.",
  "ccmessaging.chat.chatcard.createFiveGCardFail": "Échec de la création de la carte 5G",
  "ccmessaging.chat.chatcard.createFiveGCardSuccess": "Créer une carte 5G avec succès",
  "ccmessaging.chat.chatcard.editFiveGCardFail": "Échec de la modification de la carte 5G",
  "ccmessaging.chat.chatcard.editFiveGCardSuccess": "Modifier la carte 5G avec succès",
  "ccmessaging.chat.chatcard.deleteFiveGCardFail": "Échec de la suppression de la carte 5G",
  "ccmessaging.chat.chatcard.deleteFiveGCardSuccess": "Supprimer la carte 5G avec succès",
  "ccmessaging.chat.chatcard.createFiveGCarouselFail": "Échec de la création du carouse 5G",
  "ccmessaging.chat.chatcard.createFiveGCarouselSuccess": "Créer 5G Carouse avec succès",
  "ccmessaging.chat.chatcard.editFiveGCarouselFail": "Échec de la modification du carouse 5G",
  "ccmessaging.chat.chatcard.editFiveGCarouselSuccess": "Modifier 5G Carouse avec succès",
  "ccmessaging.chat.chatcard.deleteFiveGCarouselFail": "Échec de la suppression de la carouse 5G",
  "ccmessaging.chat.chatcard.deleteFiveGCarouselSuccess": "Supprimer 5G Carouse avec succès",
  "ccmessaging.chat.chatcard.createWhatAppTemplateMeaasgeFail": "Créer un modèle WhatsApp La mesure a échoué",
  "ccmessaging.chat.chatcard.createWhatAppTemplateMeaasgeSuccess": "Créer un modèle WhatsApp Mesurer avec succès",
  "ccmessaging.chat.chatcard.editWhatAppTemplateMeaasgeFail": "Échec de la modification de la mesure du modèle WhatsApp",
  "ccmessaging.chat.chatcard.editWhatAppTemplateMeaasgeSuccess": "Modifier le modèle WhatsApp Mesurer avec succès",
  "ccmessaging.chat.chatcard.deleteWhatAppTemplateMeaasgeFail": "Échec de la mesure de modèle de suppression WhatsApp",
  "ccmessaging.chat.chatcard.deleteWhatAppTemplateMeaasgeSuccess": "Supprimer le modèle WhatsApp Mesurer avec succès",
  "ccmessaging.chat.chatcard.createWebCardFail": "Échec de la création de WebCard",
  "ccmessaging.chat.chatcard.createWebCardSuccess": "Créer WebCard avec succès",
  "ccmessaging.chat.chatcard.editWebCardFail": "Échec de la modification du WebCard",
  "ccmessaging.chat.chatcard.editWebCardSuccess": "Modifier les WebCard avec succès",
  "ccmessaging.chat.chatcard.deleteWebCardFail": "Échec de la suppression des WebCard",
  "ccmessaging.chat.chatcard.deleteWebCardSuccess": "Supprimer les WebCard avec succès",
  "ccmessaging.chat.WhatsApp.createWhatsAppTemplateSuccess": "Créer un modèle WhatsApp avec succès",
  "ccmessaging.chat.WhatsApp.createWhatsAppTemplateFail": "Échec de la création du modèle WhatsApp",
  "ccmessaging.chat.WhatsApp.editWhatsAppTemplateSuccess": "Modifier le modèle WhatsApp avec succès",
  "ccmessaging.chat.WhatsApp.editWhatsAppTemplateFail": "Échec de la modification du modèle WhatsApp",
  "ccmessaging.chat.WhatsApp.deleteWhatsAppTemplateSuccess": "Supprimer le modèle WhatsApp avec succès",
  "ccmessaging.chat.WhatsApp.deleteWhatsAppTemplateFail": "Échec de la suppression du modèle WhatsApp",
  "ccmessaging.chat.WhatsApp.deleteWhatsAppTemplateFailMsg": "Échec de la suppression du modèle WhatsApp. Le modèle est référencé par un message de modèle.",
  "ccmessaging.multimedia.download.office.risktips":"Vous êtes sur le point de télécharger des documents Office, qui peuvent présenter des risques pour la sécurité. Il est recommandé d'effectuer des opérations antivirus et d'activer les paramètres de sécurité du logiciel Office.",
  "ccmessaging.chat.multimedia.documentUploadRiskTips":"Ce document est envoyé aux utilisateurs par des agents. Ne téléchargez pas de fichiers contenant des risques de sécurité.",
  "ccmessaging.config.names.channels.web": "Web",
  "ccmessaging.config.names.channels.instagram": "Instagram",
  "ccmessaging.config.names.channels.telegram": "Telegram",
  "ccmessaging.config.names.channels.whatsapp": "WhatsApp",
  "ccmessaging.config.names.channels.line": "LINE",
  "ccmessaging.config.names.channels.wechat": "WeChat",
  "ccmessaging.config.names.channels.facebook": "Facebook",
  "ccmessaging.config.names.channels.twitter": "X (Twitter)",
  "ccmessaging.config.names.channels.rcs": "5G RCS",
  "ccmessaging.config.names.channels.email": "Email",
  "ccmessaging.config.names.channels.sms": "SMS",
  "ccmessaging.chat.multimedia.whatsApp.interactiveMsg":"Message interactif WhatsApp",
  "ccmessaging.chat.whatsapp.interactiveMsgNamePlaceholder":"Veuillez saisir le nom du message interactif",
  "ccmessaging.chat.whatsapp.interactiveMsgName":"Nom du message",
  "ccmessaging.chat.whatsapp.interactiveMsgTitle":"Titre",
  "ccmessaging.chat.whatsapp.interactiveMsgDescription":"Description du message",
  "ccmessaging.chat.whatsapp.interactiveMsgDescriptionPlaceholder":"Veuillez saisir une description du message interactif",
  "ccmessaging.chat.whatsapp.interactiveMsgType":"Type de message",
  "ccmessaging.chat.whatsapp.orgName":"Nom de l'organisation",
  "ccmessaging.chat.chatcard.queryInteractiveMsg":"Échec de l'interrogation du message interactif",
  "ccmessaging.chat.whatsapp.interactiveType.buttons":"Message du bouton",
  "ccmessaging.chat.whatsapp.interactiveType.list":"Liste des messages",
  "ccmessaging.chat.whatsapp.delete.interactiveMsg":"Supprimer le message interactif",
  "ccmessaging.chat.whatsapp.willdeleteinteractiveMsg":"Êtes-vous sûr de vouloir supprimer ce message interactif ?",
  "ccmessaging.chat.whatsapp.interactiveTypes":"Type de message interactif",
  "ccmessaging.chat.whatsapp.interactiveMsgTitlePlaceholder":"Veuillez saisir le contenu du titre interactif",
  "ccmessaging.chat.whatsapp.interactiveMsg.bodyContent":"Contenu du corps",
  "ccmessaging.chat.whatsapp.interactiveMsg.bodyContentPlaceholder":"Veuillez saisir le corps du texte",
  "ccmessaging.chat.whatsapp.interactiveMsg.footerContent":"Contenu du pied de page",
  "ccmessaging.chat.whatsapp.interactiveMsg.footerContentPlaceholder":"Veuillez saisir le contenu du pied de page",
  "ccmessaging.chat.whatsapp.interactiveMsg.pleaseChoose":"Veuillez sélectionner",
  "ccmessaging.chat.whatsapp.interactiveMsg.attachments":"pièces jointes",
  "ccmessaging.chat.multimedia.whatsApp.interactiveMsg.create":"Nouveau message interactif",
  "ccmessaging.chat.multimedia.whatsApp.interactiveMsg.update":"Modifier le message interactif",
  "ccmessaging.chat.whatsapp.interactiveType.list.title":"Liste",
  "ccmessaging.chat.whatsapp.interactiveType.list.titlePlaceholder":"Veuillez saisir le titre de la liste",
  "ccmessaging.chat.whatsapp.interactiveMsg.openRow.info":"Le titre du groupe est vide et l'option ne peut pas être définie",
  "ccmessaging.chat.whatsapp.interactiveType.section.titlePlaceholder":"Veuillez saisir le titre du groupe",
  "ccmessaging.chat.whatsapp.interactiveType.row.titlePlaceholder":"Veuillez saisir le titre de l'option",
  "ccmessaging.chat.whatsapp.interactiveType.row.descPlaceholder":"Veuillez saisir une description de l'option",
  "ccmessaging.chat.whatsapp.interactiveMsg.sectionTitle":"Veuillez saisir le titre de la liste",
  "ccmessaging.chat.whatsapp.interactiveMsg.queryInteractiveError":"Échec de la requête de message interactif",
  "ccmessaging.chat.whatsapp.interactiveMsg.createInteractiveError":"Échec de l'enregistrement du message interactif. Une fois la liste groupée, l'option de groupe ne peut pas être vide",
  "ccmessaging.chat.whatsapp.interactiveMsg.saveFail.duplicateName":"Échec de l'enregistrement, le nom du message interactif existe déjà",
  "ccmessaging.chat.whatsapp.interactiveMsg.noHeader":"Aucun",
  "ccmessaging.chat.whatsapp.interactiveMsg.headerContent":"Titre Contenu",
  "ccmessaging.chat.whatsapp.interactiveMsg.no.buttons":"Échec de l'enregistrement du message interactif sans bouton de réglage",
  "ccmessaging.chat.whatsapp.interactiveMsg.no.lists":"Échec de l'enregistrement du message interactif, pas de liste de paramètres",
  "ccmessaging.chat.multimedia.deleteLimit.whatsapp.interactiveMsg":"Cette ressource est référencée par un message interactif WhatsApp et ne peut pas être supprimée. Nom du message interactif :",
  "ccmessaging.chat.whatsapp.interactiveMsg.saveFail.multiMedia.notExist":"Échec de l'enregistrement, la ressource multimédia n'existe pas",
  "ccmessaging.chat.whatsapp.interactiveMsg.createInteractiveError.repeateButtonContent":"Échec de l'enregistrement, le contenu du bouton ne peut pas être dupliqué",
  "ccmessaging.chat.case.title":"Titre de l'affaire",
  "ccmessaging.chat.case.type":"Type de cas",
  "ccmessaging.chat.case.callReason":"Raison d'appel",
  "ccmessaging.chat.case.puzzled":"perplexe",
  "ccmessaging.chat.case.calm":"calme",
  "ccmessaging.chat.case.happy":"heureux",
  "ccmessaging.chat.case.upset":"contrarié",
  "ccmessaging.chat.case.surprise":"surprise",
  "ccmessaging.chat.case.pleasant":"agréable",
  "ccmessaging.chat.case.angry":"en colère",
  "ccmessaging.chat.case.little.angry":"un peu de colère",
  "ccmessaging.chat.case.very.angry":"très fou",
  "ccmessaging.chat.case.very.knowledgeId":"1228840300949016576",
  "ccmessaging.chat.case.complaint.yes":"oui",
  "ccmessaging.chat.case.complaint.shi":"oui",
  "ccmessaging.chat.degree.view":"Client 360 degrés",
}
