<template>
  <compress-pop ref="compressPopRef" v-bind="$attrs" :title="title" :max-pwd-length="maxPwdLength" :min-pwd-length="minPwdLength" @confirm="confirm" @cancel="cancelExport"/>
</template>
<script setup lang="ts">
import { getCurrentInstance, reactive, ref } from 'vue'
import { aiccDownload } from '@/utils/aiccUploadAndDownload.js'
import { ElForm, ElMessageBox } from "element-plus";
import cookieUtils from '@/utils/cookie.js'
import CompressPop from "@/views/ccmanagement/components/compress-pop/CompressPop.vue";
import {queryExportTenantSysParam} from "@/views/ccmanagement/ccma-api";
import {SystemParamEnum} from "@/views/ccmanagement/common/constants";
import AiccElMessage from "@/utils/el-message";

// 国际化封装
const {
  appContext: {
    config: { globalProperties }
  }
} = getCurrentInstance() as any
const i18n = (param: string, ...args: Array<any>): string => {
  return globalProperties.$t(param, args)
}

const props = defineProps({
  dataInfo: Object as any,
  dataType: String,
  title: {
    type: String,
    required: true
  },
  // 密码最小长度，默认为8字符
  minPwdLength: {
    type: Number,
    default: 8
  },
  // 密码最大长度，默认为12字符
  maxPwdLength: {
    type: Number,
    default: 12
  },
  /** 是否需要设置压缩密码 */
  requiredPwd: {
    type: Boolean,
    default: () => true
  }
})

const compressPopRef = ref<InstanceType<typeof CompressPop>>()
const emits = defineEmits(['getDownloadResult'])
const isShow = ref<boolean>(false)
const downloadFileForm = ref<InstanceType<typeof ElForm>>()
const form = reactive<{zipPwd?: string}>({ })

const handleDialog = (param?: boolean) => {
  const dialogStatus = param || false
  // 关闭弹窗
  if (!dialogStatus) {
    compressPopRef.value.handleDialog(false)
    return
  }
  // 检查是否需要设置压缩密码
  queryExportTenantSysParam().then((resp: any) => {
    // 无需设置压缩密码，直接执行下载
    if (resp?.data?.value == 'false') {
      downloadRecord()
      return
    }
    compressPopRef.value.handleDialog(true)
  })
}

const closeDialog = () => {
  isShow.value = false
  form.zipPwd = ''
  downloadFileForm.value?.resetFields()
}

/**
 * 取消导出
 */
const cancelExport = () => {
  emits('getDownloadResult', false)
  closeDialog()
}

const confirm = (pwd: string) => {
  form.zipPwd = pwd
  downloadRecord()
}

/**
 * 下载
 */
const downloadRecord = () => {
  switch (props.dataType) {
    case 'contactRecord':
      contactRecordDownload()
      break
    case 'mobileRecord':
      mobileRecordDownload()
      break
    case 'leaveRecord':
      leaveRecordDownload()
      break
    case 'downloadFromOBS':
      obsFileDownload()
      break
    default:
      new TypeError(`Unknown dataType: ${props.dataType}`) // 抛出未知类型错误
  }
  emits('getDownloadResult', true)
  closeDialog()
}

const contactRecordDownload = () => {
  if ((props.dataInfo.RecordInfo && props.dataInfo.RecordInfo[0] && props.dataInfo.RecordInfo[0].mediaAbility == 2) || props.dataInfo.mediaAbility == 2) {
    const onSftpServer = props.dataInfo.RecordInfo && props.dataInfo.RecordInfo[0] ?
        props.dataInfo.RecordInfo[0].recordfileaddr.indexOf(":/") > -1 :
        props.dataInfo && props.dataInfo.recordfileaddr ?
            props.dataInfo.recordfileaddr.indexOf(":/") > -1 : false;
    if (!onSftpServer) {
      if (!window.top?.agentStatus){
        AiccElMessage.info(i18n("ccm.agent.leavemessage.notLoginIn"));
        return;
      }
      if (window.top?.agentStatus?.isLogOut()){
        AiccElMessage.info(i18n("ccm.agent.leavemessage.notLoginIn"));
        return;
      }
    }
    if (onSftpServer) {
      const url = '/service-cloud/u-route/ccmanagement/fileaction/downloadFromUAP'
      const params = {
        downloadInfo: {
          recordId: props.dataInfo!.recordId,
          zipPwd: form.zipPwd
        }
      }
      if (props.dataInfo!.recordId.indexOf("-vrc") > -1) {
        const vrcParams = {
          downloadInfo: {
            vrcRecordId: props.dataInfo!.vrcRecordId,
            recordId: props.dataInfo!.vrcRecordId,
            zipPwd: form.zipPwd
          }
        }
        handleDownload(vrcParams, url, defaultContactRecordDownloadErrHandle)
        return
      }
      handleDownload(params, url, defaultContactRecordDownloadErrHandle)
      return;
    }
    let url = '/service-cloud/u-route/ccmanagement/recordplay/downloadVideo'
    let xhr = new XMLHttpRequest()
    xhr.open('POST', url)
    xhr.responseType = 'blob'
    xhr.onload = function () {
      if (this.readyState === 4 && this.status === 200) {
        const contentDisposition = (
          this.getResponseHeader('Content-Disposition') || ''
        ).toLowerCase()
        if (contentDisposition.indexOf('filename=') != -1) {
          const fileName = contentDisposition
            .substring(contentDisposition.indexOf('filename='))
            .split('=')[1]
            .trim()
            .replace(/"/g, '')
          const blob = new Blob([this.response], { type: 'application/zip' })
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileName)
          } else {
            const blobUrl = window.URL.createObjectURL(blob)
            let a = document.createElement('a')
            a.style.display = 'none'
            a.href = blobUrl
            a.setAttribute('download', decodeURIComponent(escape(fileName))) // 解决中文乱码问题
            document.body.appendChild(a)
            a.click() //执行下载
            window.URL.revokeObjectURL(a.href)
            document.body.removeChild(a)
          }
          closeDialog()
          return
        }

        const reader = new FileReader()
        reader.readAsText(this.response, 'utf-8')
        reader.onload = function (e) {
          const retData = reader.result + ''
          if (retData == null || retData == '' || retData == 'null') {
            closeDialog()
            return
          }

          const dataJson = retData != null && retData.startsWith('{') ? JSON.parse(retData) : {}
          if (!dataJson.hasOwnProperty('returnCode')) {
            closeDialog()
            return
          }

          const returnCode = retData['returnCode']
          if ('0' == returnCode) {
            closeDialog()
          } else if ('108-001' == returnCode) {
            AiccElMessage.error(i18n('ccm.agent.videoDownload.disabled'))
          } else if ('108-002' == returnCode) {
            AiccElMessage.error(i18n('ccm.agent.videoDownload.noPermission'))
          } else if ('108-004' == returnCode) {
            AiccElMessage.error(i18n('ccm.agent.videoDownload.RSETimeout'))
          } else if ('2' == returnCode) {
            AiccElMessage.error(i18n('ccm.agent.contact.gateway.fail'))
          } else if ('000-000' == returnCode) {
            AiccElMessage.error(i18n('ccm.agent.contact.gateway.exception'))
          } else {
            AiccElMessage.error(i18n('ccm.agent.contact.downloadFail'))
          }
        }
      }
    }
    xhr.setRequestHeader('u-token', cookieUtils.getCookie('u-token'))
    xhr.setRequestHeader('locale', cookieUtils.getCookie('u-locale'))
    xhr.send(JSON.stringify({ recordId: props.dataInfo!.recordId, zipPwd: form.zipPwd }))
    return
  }
  const url = '/service-cloud/u-route/ccmanagement/fileaction/downloadFromUAP'
  const params = {
    downloadInfo: {
      recordId: props.dataInfo!.recordId,
      zipPwd: form.zipPwd
    }
  }
  handleDownload(params, url, defaultContactRecordDownloadErrHandle)
}

const mobileRecordDownload = () => {
  const url = '/service-cloud/u-route/ccmanagement/fileaction/downloadRecordExt'
  const param = {
    downloadInfo: {
      associateCall: props.dataInfo!.associateCall,
      zipPwd: form.zipPwd
    }
  }
  handleDownload(param, url)
}

const leaveRecordDownload = () => {
  const url = '/service-cloud/u-route/ccmanagement/fileaction/downloadLeaveMessage'
  const param = {
    downloadInfo: {
      tag: 'download',
      callId: props.dataInfo!.callId,
      id: props.dataInfo!.id,
      zipPwd: form.zipPwd
    }
  }
  handleDownload(param, url)
}

const obsFileDownload = () => {
  const param = {
    downloadInfo: { callSerialNo: props.dataInfo!.callSerialNo, zipPwd: form.zipPwd }
  }
  const url = '/service-cloud/u-route/ccmanagement/fileaction/downloadFromOBS'
  handleDownload(param, url)
}

/**
 * 下载方法执行函数
 * @param param 请求参数
 * @param url 请求url
 * @param errHandle 异常处理函数，可选
 */
const handleDownload = (param: any, url: string, errHandle?: (response?: any) => void | any) => {
  // 默认 响应的错误信息作为消息提示
  const defaultErrHandle = (resp: any) => AiccElMessage.error(resp.message)
  const errCallback = errHandle || defaultErrHandle
  aiccDownload(param, url).then((resp: any) => {
    if (resp.status != 200) {
      errCallback(resp)
    }
  })
}

/**
 * 接触记录页面默认错误回调函数
 * @param errResp 错误响应信息
 */
const defaultContactRecordDownloadErrHandle = (errResp: any) => {
  const message: String = errResp.message
  const code: String = message.substring(message.indexOf('!') + 1, message.indexOf(':'))
  switch (code) {
    case '80031001002003-1':
      AiccElMessage.error(i18n('ccm.agent.message.sysparamservicerr'))
      return
    case '80031001002003-2':
      AiccElMessage.warning(i18n('ccm.agent.contact.download.frequently'))
      return
    case '80031001002003-3':
      AiccElMessage.error(i18n('ccm.agent.contact.SFTP_DOWNLOAD_FAIL_CODE'))
      return
    case '80031001002003-4':
      AiccElMessage.error(i18n('ccm.agent.contact.downloadFail'))
      return
    case '80031001002003-5':
      AiccElMessage.error(i18n('ccm.agent.contact.SFTP_INCORRECT_FILE_CODE'))
      return
    case '80031001002003-6':
      AiccElMessage.error(i18n('ccm.agent.contact.QUERU_RECORDINFO_FAIL_CODE'))
      return
    default:
      AiccElMessage.error(i18n('ccm.agent.contact.downloadFail'))
      return
  }
}

defineExpose({ handleDialog })
</script>

<style lang="less" scoped>
</style>