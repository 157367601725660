export default {
  "agentconsole.menu.title": "Workbench de áudio e vídeo",
  "casemanagement.casequery.button.create": "Criar caso",
  "agentconsole.callreason.tips.saved": "Motivo da chamada salvo.",
  "iolp.workflow.title.name": "Processo de Negócio",
  "agentconsole.title.onlycustomer": "Apenas Cliente",
  "agentconsole.title.allchat": "Todos os conteúdos",
  "agentconsole.title.realtimetransfer": "Transferência em tempo real",
  "agentconsole.title.intelligentcase": "Preenchimento inteligente",
  'agentconsole.management.message.filterYes': 'Sim',
  'agentconsole.management.message.filterNo': 'Não',
  "agentconsole.dashboard.enumeration.aweek": "última semana",
  "SM.LOGIN.BUTTON.CHANGEPWD_CONFIRM": "OK",
  "agentconsole.Intelligent.details": "Detalhes",
  "agentconsole.workbench.label.intelligentrecommendation": "Assistente de agente",
  "agentconsole.dashboard.title.case": "Visão geral do caso",
  "agentconsole.header.label.searchtask": "Pesquisar em Tarefa",
  "SM.LOGIN.TITLE.NOTICE_EXP_TIME": "Horário de expiração",
  "SM.LOGIN.LABEL.LOGIN_CONFIRM_SUFFIX": "), confirmar:",
  "SM.LOGIN.LABEL.NOTICE_CREATE_TIME_TO": "Para",
  "SM.LOGIN.LABEL.OPER_PHONE": "Celular:",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_NOT_OPEN_ES": "O sistema de emergência não foi iniciado. Acesse o menu de produção para executar operações comerciais.",
  "agentconsole.workbench.label.callinforeasons": "motivo de primeiro nível/motivo de segundo nível/motivo de terceiro nível/motivo de quarto nível",
  "agentconsole.dashboard.title.averageevalution": "Satisfação média",
  "agentconsole.header.title.send.sms.notification": "Notificação de envio de SMS",
  "agentconsole.index.label.caseconsole": "Workbench de Caso",
  "agentconsole.login.label.empty": "Nome de usuário ou senha em branco.",
  "agentconsole.authmanage.label.authtype.menu": "Permissão do menu",
  "SM.LOGIN.LABEL.FORGETPWD_ACCOUNT_TIPS": "inserir uma conta de login.",
  "SM.LOGIN.LABEL.CHANGEPWD_OLDPWD": "Senha antiga",
  "agentconsole.login.label.password": "Senha",
  "agentconsole.header.title.send.whatsapp.notification": "Enviando Mensagens de Notificação do WhatsApp",
  "agentconsole.login.label.signin": "Entrar",
  "agentconsole.index.label.chatconsole": "Chat Workbench",
  "SM.ORGSTAFF.LABEL.PWD_UNMATCH_RULE": "A senha não está de acordo com as regras.",
  "agentconsole.menumanage.message.paramvalidatefailed": "Falha ao criar o menu. Verifique os parâmetros.",
  "SM.LOGIN.LABEL.OPER_REGION": "Área principal:",
  "SM.LOGIN.TIPS.CHANGEPWD_COMPARE": "A nova senha deve ser a mesma que a senha de confirmação.",
  "SM.LOGIN.TITLE.UPDATE_PWD_VERIFYCODE_EMPTY": "O código de verificação está vazio.",
  "SM.LOGIN.LABEL.NOTICE_INCLUDE_SUB_ORG": "Incluir OU filho",
  "agentconsole.common.button.modify": "Modificar",
  "agentconsole.msg.noData": "Sem dados",
  "SM.LOGIN.TITLE.NOTICE": "Comunicado",
  "SM.LOGIN.TIPS.LOGINSUCCESS": "Entrar bem-sucedido.",
  "SM.LOGIN.TITLE.PORTAL_REGION_TITLE": "Região",
  "agentconsole.quickentrance.classname": "Forneça um nome de agrupamento ao catálogo. Exemplo: Gerenciamento de recursos do Tenant",
  "SM.LOGIN.TITLE.PORTAL_TITLE": "BES Portal",
  "SM.LOGIN.MESSAGE.LOGOUT": "Deseja realmente SAIR?",
  "SM.LOGIN.LABEL.LOGIN_CONFIRM_SWITCH": "Sistema Home de acesso",
  "agentconsole.dashboard.title.taskunassigned": "Não atribuído",
  "SM.LOGIN.TITLE.UPDATE_PWD_NEWPWD_EMPTY": "A nova senha está vazia.",
  "SM.LOGIN.BUTTON.RESET": "Redefinir",
  "agentconsole.ccnotification.inputRecipient": "Insira os destinatários, vários destinatários são separados por vírgulas",
  "agentconsole.ccnotification.inputOneRecipient": "Introduza o destinatário",
  "agentconsole.ccnotification.recipient.validate.limit.one": "O número de destinatários não pode exceder 1.",
  "agentconsole.callmessage.callerNo": "Chamador",
  "SM.LOGIN.LABEL.SUM": "Gerenciamento do usuário do sistema",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_SWITCH_ES": "O negócio foi mudado para o estado de emergência. O negócio só pode ser tratado após o sistema ser recuperado.",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_VERIFY_CODE_NOT_EXIST": "O código de verificação não existe.",
  "agentconsole.dashboard.evaluation.medium": "3 a 4 pontos",
  "agentconsole.header.title.logout": "Sair",
  "agentconsole.index.label.casedec": "Você pode criar um caso para cada solicitação de serviço do cliente e monitorar o ciclo de vida completo do processamento da solicitação de serviço ao cliente através do caso, garantindo que cada solicitação do cliente seja fechada e melhorando a satisfação do cliente.",
  "agentconsole.common.tips.error": "Erro",
  "agentconsole.menumanage.title.selectauth": "Selecione uma permissão",
  "SM.LOGIN.BUTTON.FORGETPWD_CANCEL": "Cancelar",
  "agentconsole.Intelligent.noRecord": "O serviço não está sendo monitorado ou o resultado da consulta é nulo.",
  "agentconsole.dashboard.evaluation.low": "1–2 pontos",
  "SM.LOGIN.TITLE.UPDATE_PWD_NEWPWD_RULE": "A nova senha não atende à regra de senha.",
  "SM.LOGIN.MESSAGE.SENDSMS_FAILED": "Falha ao enviar a mensagem SMS. Verifique o log backend.",
  "agentconsole.header.title.notificationcenter": "Exibir Notificações",
  "agentconsole.login.indexforipcc.help": "Ajuda",
  "agentconsole.contacthistory.title": "Contatos do histórico",
  "agentconsole.authmanage.field.auth_type": "Tipo de permissão",
  "SM.PORTAL.LABEL.INPUT_OLD_PWD": "Inserir senha antiga.",
  "SM.LOGIN.BUTTON.FORGETPWD_CONFIRM": "OK",
  "SM.LOGIN.LABEL.FORGETPWD_EMAIL_TIPS": "Insira o endereço de e-mail.",
  "SM.AUC.MESSAGE.PWDRULE_NO": "Não",
  "agentconsole.callmessage.faq.spread": "Expandir",
  "agentconsole.querycustominfo.msg.inputHandleNumber": "inserir um número manipulado",
  "agentconsole.login.indexforipcc.difbutton": "Diferentes botões de controle de operação podem ser exibidos de acordo com a autoridade da equipe de atendimento ao cliente para evitar operação incorreta e interferência desnecessária, melhorando assim a eficiência do serviço da equipe de atendimento ao cliente.",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_LOGIN_ID_NULL": "A conta de login não pode estar vazia.",
  "agentconsole.common.button.ok": "OK",
  "agentconsole.login.indexforipcc.agentcall": "Agent call control, such as answering calls, call forwarding, three-way calling, asking for help, etc.",
  "agentconsole.contacthistory.oneMin": "1 minutos atrás",
  "SM.LOGIN.TITLE.PASSWORD_HASEXPIRED": "Sua senha expirou. Entre em contato com o administrador para redefinir a senha.",
  "SM.LOGIN.LABEL.FRAMEWORK_CALCULATOR": "Calculo",
  "SM.LOGIN.LABEL.NOTICE_GRADE_NORMAL": "Normal",
  "SM.LOGIN.LABEL.CUS_LOGIN": "Customer Sign-In",
  "SM.SERVLET.BATCH.RESET.PASSWORD.EMPLOYEE": "Redefinindo senhas de usuário aluguel em lotes",
  "agentconsole.sessionmanagement.label.clientIp": "IP do cliente",
  "agentconsole.header.label.searchbase": "Pesquisar na Central de Conhecimento",
  "agentconsole.login.resetpwd.empty": "O nome de usuário ou endereço de email está vazio.",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED": "Falha ao abrir o menu.",
  "SM.LOGIN.TITLE.LOGIN_TITLE": "BES Portal Sign-in Page",
  "agentconsole.login.indexforipcc.cccbar": "A faixa de assento no quadro do agente fornece as principais funções de processamento de chamada de voz e controle de status do agente.",
  "SM.LOGIN.TIPS.UPDATE_PWD": "Alterar senha",
  "SM.ORGSTAFF.LABEL.TEAM_ACCOUNT": "Conta",
  "SM.LOGIN.TITLE.CURRENT_BE": "Current City",
  "agentconsole.workbench.queNum": "Número atual de clientes na fila:",
  "agentconsole.callmessage.faq.folded": "Colapsar",
  "SM.LOGIN.TITLE.PROJECT_CREATER": "Criador:",
  "SM.LOGIN.MESSAGE.LOGIN.LABEL.EMPTY": "O nome de usuário ou senha está vazio.",
  "agentconsole.menumanage.message.urlvalidatefailed": "Falha ao criar o menu. Entre em contato com o administrador do sistema para verificar a lista de permissões do endereço IP.",
  "SM.LOGIN.TITLE.PASSWORD_INIT_SHOULD": "Você está usando a senha inicial. Altere a senha imediatamente.",
  "SM.LOGIN.LABEL.RESETPWD.FAIL": "Falha ao redefinir a senha.",
  "agentconsole.dashboard.title.caseunhandle": "Não processado",
  "agentconsole.login.indexforipcc.connectcontrol": "Connection state control",
  "SM.LOGIN.TIPS.MODIFY_SUCCESS": "Modificado com sucesso.",
  "SM.ORGSTAFF.TIPS.REGION_DST_SWITCHINTERVAL_TIPS": "Unidade: segundo. Valores: 1 a 86400. Se este parâmetro ficar vazio, o valor padrão 3600 será usado.",
  "agentconsole.dashboard.title.casecreate": "Número de casos criados",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_LOGINID_NO_BIND_MSISDN": "O usuário não está vinculado a nenhum número de celular.",
  "SM.PORTAL.LABEL.INPUT_NEW_PWD": "inserir uma nova senha.",
  "agentconsole.menumanage.message.renamenode": "Nome do menu duplicado",
  "agentconsole.contacthistory.accept": "Número manipulado",
  "SM.LOGIN.LABEL.REMEMBER": "Lembrar nome de usuário",
  "agentconsole.login.indexforipcc.signinsuccess": "After the agent has successfully checked in, the agent can answer the customer's call and handle the business. The system assigns customer calls based on the agents that are checked in. The management personnel conducts attendance management according to the check-in and check-out time of the agent.",
  "agentconsole.login.indexforipcc.usenow": "usar imediatamente",
  "SM.LOGIN.TITLE.UPDATE_PWD_CONFIRMPWD": "Confirmar senha",
  "agentconsole.authmanage.label.authtype.interface": "Permissão de interface",
  "SM.LOGIN.LABEL.RESETPWD": "Click Reset. The new password will be sent to the mailbox.",
  "agentconsole.login.indexforipcc.callcontrol": "Controle de chamada de voz",
  "agentconsole.header.title.send.notification": "Enviar Notificação",
  "agentconsole.login.indexforipcc.difauth": "Gerencie a organização do Tenant, funções, permissões e funcionários. Depois que o pessoal de atendimento ao cliente efetua login usando a conta do funcionário, os botões de controle de chamadas e menus de página que podem ser visualizados são diferentes de acordo com os diferentes direitos. Somente quando o usuário efetua login mas não fez check-in, as operações relacionadas à chamada não podem ser executadas.",
  "SM.LOGIN.MESSAGE.LOGOUT_REASON.IP_CHECK_FAILED": "Falha na verificação do endereço IP. O endereço IP atual é diferente do do primeiro login.",
  "agentconsole.menumanage.message.menuname": "O número de bytes deve ser menor ou igual a 50",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_OPEN_BE_ES": "A área atual foi mudadada para o estado de emergência. Inicie sessão no sistema de emergência para lidar com os negócios.",
  "agentconsole.login.indexforipcc.doublecontrol": "A nuvem inteligente de atendimento ao cliente possui duas camadas de controle de autenticação para o sistema de login do agente:",
  "SM.LOGIN.MESSAGE.SUGGESTION": "Solicitar",
  "SM.LOGIN.MESSAGE.SEARCH_HISTORY_CLEAR": "Limpar histórico",
  "SM.LOGIN.TITLE.ICPCODE": "Jiangsu ICP Registration Number: {0}-{1}",
  "SM.LOGIN.LABEL.OPER_LOGINID": "Conta de login:",
  "SM.LOGIN.LABEL.NOTICE_ORG": "OU",
  "agentconsole.quickentrance.classdesc": "Escreva uma nota para este diretório. Exemplo: Gerenciar recursos de Tenant. Você pode visualizar a manutenção dos recursos do Tenant aqui.",
  "SM.LOGIN.LABEL.CHANGEPWD_ACCOUNT": "Conta",
  "SM.LOGIN.TIPS.LOGINRESULT": "Resultado de sessão:",
  "SM.LOGIN.TITLE.CHANGEPWD_ERROR": "Erro",
  "SM.LOGIN.LABEL.AUTH_FAILED_ES": "A cidade atual entrou no estado de emergência. Entre no sistema de emergência.",
  "agentconsole.custinfo.label.amount": "Quantia de pagamento",
  "agentconsole.sessionmanagement.label.startime": "Hora de início",
  "SM.LOGIN.LABEL.FRAMEWORK_ONLINE_HELP": "Ajuda online",
  "agentconsole.sessionmanagement.label.accountNumber": "Conta",
  "SM.INDEX.LABEL.TASK": "Tarefa",
  "SM.LOGIN.LABEL.VCODE_INVALID": "Código de verificação incorreto",
  "agentconsole.dashboard.evaluation.unit": "Quantidade",
  "SM.LOGIN.LABEL.ERRORPAGE_ERRORNODETAIL_CLOSE": "Detalhes>",
  "agentconsole.authmanage.message.renamenode": "Renomear a permissão.",
  "SM.LOGIN.LABEL.SMS_RECONFIRM_GETCODE": "Obter Código de Verificação",
  "agentconsole.index.label.querycase": "Consulta de caso",
  "agentconsole.workbench.talkNum": "Contagem de chamadas de hoje:",
  "agentconsole.login.label.verifycode": "Código de verificação",
  "agentconsole.custinfo.label.dueDate": "Data de vencimento",
  "SM.INDEX.LABEL.CHATDEC": "Durante um bate-papo online multimídia com o cliente, você pode visualizar as informações históricas de contato completas do cliente para maximizar sua eficiência de serviço e fornecer experiência de serviço Omnichannel para seu cliente.",
  "SM.SERVLET.BATCH.RESET.PASSWORD.EMPFAIL": ", funcionários falhados:",
  "SM.LOGIN.BUTTON.LOGIN_CONFIRM_MSG": "O sistema sairá se você quiser acessar o sistema do BE. Você precisa inserir o nome de usuário e a senha para iniciar sessão. Tem certeza de que deseja continuar?",
  "agentconsole.dashboard.title.taskunhandle": "Não processado",
  "SM.LOGIN.MESSAGE.CHANGEPWD_FAILED": "Falha ao alterar a senha.",
  "agentconsole.sessionmanagement.table.deleteConfimMessage": "Tem certeza de que deseja excluir esta sessão?",
  "agentconsole.wordreminder.norecommend": "Nenhum script recomendado disponível.",
  "agentconsole.login.indexforipcc.agent": "Agent certification",
  "agentconsole.login.indexforipcc.transfer": "Call forwarding",
  "agentconsole.custinfo.label.custName": "Nome do cliente",
  "SM.COMMON.MESSAGE.VALIDATE_MINLENGTHB": "Insira pelo menos {0} caracteres.",
  "agentconsole.index.label.taskconsole": "Workbench de tarefas",
  "SM.LOGIN.MESSAGE.SENDMAIL_FAILED": "Falha ao enviar o email.",
  "SM.LOGIN.LABEL.OPER_BE": "BE:",
  "agentconsole.index.label.taskdec": "Durante o processamento de casos, você pode enviar tarefas para trabalhar com vários departamentos e funcionários. Várias tarefas podem ser criadas para um caso.",
  "agentconsole.dashboard.title.casepriority": "Lista de tarefas",
  "agentconsole.menumanage.message.notdelnoleafmenu": "Não permitir a exclusão do menu com submenus.",
  "SM.ORGSTAFF.LABEL.ADDR_PLEASE_INPUT": "Insira o nome",
  "agentconsole.header.title.messageCenter": "Gerenciamento interno de mensagens",
  "SM.LOGIN.TITLE.PORTAL_SWITCH_PROJECT": "Alternar projeto",
  "agentconsole.login.label.email": "Email",
  "SM.LOGIN.BUTTON.SMS_RECONFIRM_CANCEL": "Cancelar",
  "SM.LOGIN.LABEL.FORGETPWD_SENDTYPE": "Modo de recuperação",
  "agentconsole.authmanage.message.authtype": "O nome da autoridade não pode ser inserido. Os caracteres especiais não estão em branco e o número de bytes deve ser menor ou igual a 50.",
  "SM.LOGIN.TITLE.PROJECT_DESC": "Descrição do projeto:",
  "agentconsole.dashboard.title.caseperday": "Daily Processing Status",
  "agentconsole.menumanage.message.createsubnodefail": "O nível do menu não pode exceder 3 níveis.",
  "SM.LOGIN.TIPS.WELCOME": "Bem-vindo ao usar o BES!",
  "SM.LOGIN.TITLE.LOGIN": "Iniciar sessão",
  "agentconsole.workbench.signInCallNum": "Número de chamadas de login atuais:",
  "SM.LOGIN.LABEL.SMS_RECONFIRM_VCODE": "Código de verificação",
  "SM.LOGIN.BUTTON.UPDATE_PWD_MODIFY": "Modificar",
  "SM.LOGIN.LABEL.OPER_EMAIL": "Email:",
  "SM.LOGIN.MESSAGE.SUM": "Total:",
  "SM.LOGIN.LABEL.VCODE": "Código de verificação",
  "agentconsole.login.indexforipcc.recordcontrol": "Controle de gravação e reprodução",
  "SM.LOGIN.MESSAGE.MODIFY_PWD_SUCCESS": "Senha alterada com sucesso.",
  "SM.LOGIN.TITLE.PROJECT_CREATE_TIME": "Horário de criação:",
  "agentconsole.header.label.searchcase": "Pesquisar em Caso",
  "SM.LOGIN.TITLE.NOTICE_EFF_TIME": "Tempo Efetivo.",
  "SM.LOGIN.SSO.IS.INVALID": "A página expirou, clique para atualizar a página.",
  "agentconsole.history.lastloginfailattempts": "Últimas tentativas de login falhadas",
  "SM.LOGIN.LABEL.ERRORPAGE_RETURNBTN": "Voltar à página de login",
  "agentconsole.menu.extended.apps": "Aplicativos estendidos",
  "SM.LOGIN.MESSAGE.SWITCH": "Are you sure you want to switch to the ESOP system?",
  "SM.LOGIN.RESETPWD.FAIL": "Falha ao processar a solicitação de reinício de senha. Verifique a conta de login e o endereço de e-mail.",
  "agentconsole.history.loginip": "IP de login",
  "agentconsole.login.indexforipcc.call": "Controle de chamada de voz",
  "agentconsole.index.label.task": "Tarefa",
  "agentconsole.quickentrance.checkcharacterlength": "Insira não mais do que {0} caracteres.",
  "agentconsole.index.label.entry": "Entrada rápida",
  "agentconsole.quickentrance.information": "Informações",
  "SM.LOGIN.LABEL.FRAME_SEARCH_EMPLOYEE": "Consulta do funcionário",
  "SM.LOGIN.LABEL.FORGETPWD_ACCOUNT": "Conta de login",
  "SM.LOGIN.TITLE.UPDATE_PWD_OLDPWD_EMPTY": "A senha antiga está vazia.",
  "agentconsole.modifycustominfo.modifyfailed": "Falha na modificação.",
  "agentconsole.modifycustominfo.modifyfailed.validate": "Modificado falhou,Não está em conformidade com a regra de seqüência de caracteres ou comprimento.",
  "agentconsole.modifycustominfo.modifyfailed.validate.num": "Modificado falhou,Não atende às regras de número ou comprimento.",
  "agentconsole.modifycustominfo.modifyfailed.validate.date":"Modificado falhou,O formato da data está incorreto.",
  "agentconsole.modifycustominfo.modifyfailed.validate.time":"Modificado falhou, O formato de data e hora está incorreto.",
  "agentconsole.querycustominfo.msg.specialStrValidateFailed": "Apenas os seguintes caracteres especiais são suportados: {'@'}.-",
  "provision.menuurl.protocol.check": "Observe que o protocolo de rede usado pelo método de menu actual não é um seguro. Se você optar por usar o programa de rede, exister riscos de risco.",
  "agentconsole.dashboard.title.taskpriority": "Lista de tarefas",
  "agentconsole.authmanage.field.auth_name": "Nome da permissão",
  "agentconsole.inputplaceholder.enter_keyword": "Insira uma palavra-chave.",
  "agentconsole.login.indexforipcc.gateway": "Gateway aberto",
  "SM.LOGIN.TITLE.UNIFIED_CASHER": "Caixa Unificada",
  "SM.LOGIN.TITLE.SHOPPING_CART": "Carrinho de compras",
  "SM.LOGIN.LABEL.NOTICE_CREATE_TIME": "Horário de criação",
  "SM.LOGIN.RESETPWD.EMPTY": "A conta de login ou endereço de email está vazia.",
  "agentconsole.header.label.searchhelp": "Pesquisar na Central de Ajuda",
  "SM.LOGIN.LABEL.NOTICE_CHAN_TYPE": "Tipo de Canal",
  "agentconsole.login.label.service_cloud_title": "Atendimento ao Cliente Cloud",
  "agentconsole.custinfo.title.custinfo": "Informações do cliente",
  "SM.LOGIN.TITLE.OPERATOR": "Current Operator",
  "SM.LOGIN.TITLE.SEARCH_CONDITION": "Condição de consulta",
  "agentconsole.history.passwordremainingdays": "Dias para alteração de senha",
  "SM.LOGIN.TIPS.REQUIRED": "Este parâmetro é obrigatório",
  "SM.LOGIN.LABEL.FORGETPWD_EMAIL": "Email:",
  "SM.LOGIN.LABEL.ADD_FAST_MENU": "Adicionar menu de atalho",
  "agentconsole.menumanage.message.homepagesubnode": "Não é possível adicionar um submenu à página inicial.",
  "agentconsole.login.restpwd.success": "Redefinição de senha com sucesso.",
  "SM.LOGIN.TITLE.CHOOSE_SELECT_ORG": "Selecionar OU",
  "SM.LOGIN.LABEL.HOME": "Página inicial",
  "SM.LOGIN.TIPS.MODIFY": "Modificar",
  "agentconsole.modifycustominfo.modifyexternalfailed": "No momento, os dados de terceiros não podem ser modificados.",
  "agentconsole.authmanage.paneltitle.authmanage": "Gerenciamento de permissões",
  "agentconsole.menumanage.label.newmenu": "Novo menu",
  "SM.LOGIN.MESSAGE.HAVE_LOCK_USER": "O usuário foi bloqueado. Desbloquear o usuário ou esperar que o sistema desbloqueie automaticamente o usuário.",
  "SM.LOGIN.LABEL.RECHARGE": "Complemento",
  "SM.LOGIN.MESSAGE.UNLOCK": "Are you sure you want to lock the system?",
  "agentconsole.querycustominfo.msg.noData": "Sem dados",
  "agentconsole.dashboard.title.taskdone": "Número de Tarefas tratadas",
  "agentconsole.dashboard.title.web": "Web Page",
  "agentconsole.workbench.talkavg": "Duração média de chamada (s)",
  "SM.LOGIN.TITLE.UPDATE_PWD_NEWPWD": "Nova senha:",
  "SM.LOGIN.TIPS.ENTER_PROJECT_NAME": "inserir o nome do projeto.",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_VERIFY_CODE_NULL": "O código de verificação está vazio.",
  "agentconsole.index.label.querycasenew": "Workbench de Caso de Consulta (Novo)",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_SWITCH_ES_PREFIX": "O negócio atual foi mudado para o estado de emergência. Por favor, visite o novo estado.",
  "agentconsole.login.indexforipcc.thirdpartysystem": "Sistemas de terceiros podem ser implementados por meio de uma interface integrada:",
  "SM.LOGIN.TITLE.REGISTERCODE": "Jiangsu Public Security Website Registration Number: {0}",
  "SM.LOGIN.LABEL.LOCK": "Tela de bloqueio",
  "agentconsole.workbench.label.callreason": "Motivo da chamada",
  "SM.LOGIN.TITLE.UPDATE_PWD_OLDPWD_NEWPWD": "A nova senha não pode ser a mesma que a senha antiga.",
  "SM.LOGIN.TITLE.NOTICE_MORE": "Mais",
  "SM.LOGIN.MESSAGE.CHANGE_PWD_RULE": "Falha ao alterar a senha porque a nova senha não atende ao seguinte grupo de regras de senha:",
  "SM.LOGIN.RESETPWD.SUCCESS": "A solicitação de reinicialização de senha foi processada com sucesso. Verifique o e-mail.",
  "SM.LOGIN.LABEL.FRAMEWORK_NOTEPAD": "Bloco de notas",
  "agentconsole.menumanage.message.notdelbasemenu": "Não permitir a exclusão do menu público.",
  "agentconsole.quickentrance.newpop": "Adicionar entrada",
  "agentconsole.login.label.account": "Conta",
  "SM.LOGIN.LABEL.NOTICE_TITLE": "Título de Comunicado",
  "SM.LOGIN.TITLE.FORGETPWD_FINDEMAILBYPWD": "Recuperar senhas por e-mails",
  "agentconsole.login.indexforipcc.advantage": "Vantagens do produto",
  "agentconsole.login.indexforipcc.characteristics": "Características de Negócios",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_BE_SWITCH_ES_PREFIX": "A área atual foi mudadada para o estado de emergência. Inicie sessão no sistema de emergência e acesse-o.",
  "agentconsole.Intelligent.robot": "Robô inteligente",
  "agentconsole.header.label.servicecloud.home": "Página inicial de Casos",
  "agentconsole.header.title.news": "Notícias",
  "SM.LOGIN.TIPS.FIRSTLOGINSYSTEM": "Entrando no Sistema pela primeira vez",
  "SM.LOGIN.MESSAGE.LOGOUT_REASON.SESSION_INVALID": "A sessão atual expirou. Inscreva-se novamente.",
  "agentconsole.sessionmanagement.label.operation": "Operação",
  "SM.LOGIN.TITLE.UPDATE_PWD": "Alterar senha",
  "agentconsole.workbench.label.callsummary": "Resumo da Ligação",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_VERIFY_CODE_EXPIRE": "Falha ao verificar o código de verificação.",
  "agentconsole.login.label.remember": "Lembrar nome de usuário",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_SEND_SMS_SUCCESS": "O código de verificação foi enviado. Verifique.",
  "agentconsole.workbench.label.usermenu": "Menu do usuário",
  "agentconsole.quickentrance.addentrance": "Adicionar entrada",
  "agentconsole.history.lastloginsuccess": "Último login bem-sucedido",
  "agentconsole.login.indexforipcc.description": "Como uma ponte importante entre empresas e clientes, o IPCC (Centro de Contato IP) fornece chamada de voz, atendimento ao cliente online e outros canais para fornecer aos clientes perguntas, orientação comercial e serviços de tratamento de reclamações. Com base no ambiente atual de alta largura de banda e alta velocidade, ele também suporta novos recursos, como agentes de vídeo e navegação por voz self-service. Ao mesmo tempo que fornece uma nova experiência de serviço aos clientes, explora novos modelos de marketing baseados em vídeo e inteligência artificial.",
  "SM.LOGIN.LABEL.OPER_ORG": "OU:",
  "SM.LOGIN.LABEL.USERNAME": "Conta",
  "agentconsole.login.label.resetpwd": "Click Reset. The new password will be sent to the mailbox.",
  "agentconsole.contacthistory.contactRecord": "Registro de contato",
  "agentconsole.wordreminder.greetings": "Saudações",
  "agentconsole.modifycustominfo.modifysuccess": "Modificado com sucesso.",
  "SM.LOGIN.LABEL.FORGETPWD_FINDPWD": "Recuperar senha",
  "agentconsole.common.label.delete": "Excluir",
  "agentconsole.login.label.verifycode.error": "O código de verificação está incorreto.",
  "agentconsole.icontitle.delete": "Excluir",
  "SM.LOGIN.LABEL.LOGIN_CONFIRM_PREFIX": "O sistema detecta que o endereço do sistema atual não é o endereço do sistema BE home (",
  "SM.LOGIN.BUTTON.UPDATE_PWD_RULE": "Regra de senha",
  "SM.LOGIN.TITLE.NOTICE_NUMBER": "Não",
  "agentconsole.icontitle.create_child": "Adicionar subnível",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_NOT_SWITCH_ES": "O sistema não está comutado para o estado de emergência. Acesse o menu de produção para executar operações comerciais.",
  "SM.LOGIN.TITLE.OPERATION_FAILED": "Operação falhou",
  "agentconsole.login.label.refresh_verify_code": "Trocar imagem",
  "agentconsole.login.indexforipcc.difstatus": "O status do agente refere-se a vários estados em que o representante de atendimento ao cliente pode estar após fazer login no agente, incluindo \"estado disponível\", \"estado de conversação\", \"estado de terminação\", \"mostrando estado ocupado\" e \"estado de repouso\". Realizando representante de atendimento ao cliente para responder a chamada, saída, a operação de sequência mostrada ocupada, etc., para alcançar os estados do agente de conversão. Com base no status do agente, o sistema determina se é possível atribuir uma chamada recebida ao representante de atendimento ao cliente.",
  "SM.LOGIN.LABEL.FORGETPWD_NUMBER": "Telefone",
  "SM.LOGIN.TITLE.PORTAL_PROJECT_TITLE": "Selecionar projeto",
  "SM.LOGIN.MESSAGE.SWITCHERROR": "Falha ao alternar.",
  "agentconsole.modifycustominfo.nomodifyauth": "O operador atual não tem permissão para modificar dados personalizados.",
  "SM.LOGIN.LABEL.RESETPWD.SUCCESS": "Redefinição de senha com sucesso.",
  "agentconsole.login.indexforipcc.qualitycheck": "Funções relacionadas à verificação de qualidade em tempo real, como inserção, intercepção, monitoramento, etc.",
  "agentconsole.authmanage.message.createsubnodefail": "O nível de permissão não pode exceder 3 níveis.",
  "agentconsole.authmanage.tab.basic_information": "Informações básicas",
  "SM.LOGIN.LABEL.NOTICE_ROLE": "Função do destinatário",
  "agentconsole.header.label.changelog": "Log de alteração",
  "agentconsole.Intelligent.labelname": "Gravação de voz inteligente",
  "agentconsole.iolp.labelname": "Solicitação de negócios",
  "agentconsole.iolp.entrancename": "Orientação de serviço",
  "SM.LOGIN.BUTTON.CHANGEPWD_CANCEL": "Cancelar",
  "SM.LOGIN.LABEL.LANG": "Idioma",
  "SM.LOGIN.LABEL.ACTIVITY": "Atividade de Marketing",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_VERIFY_FAILED": "Falha na verificação. Tente novamente mais tarde.",
  "SM.LOGIN.LABEL.NOTICE_NOTE_TYPE": "Tipo de comunicado",
  "agentconsole.login.indexforipcc.idle": "O sistema só pode atribuir chamadas recebidas a agentes no \"estado disponível\".",
  "agentconsole.dashboard.enumeration.otherday": "Outra data",
  "SM.LOGIN.TITLE.SELECTBE": "Selecionar BE",
  "SM.LOGIN.TITLE.SERACH_MENU": "Insira o nome do menu.",
  "agentconsole.dashboard.evaluation.high": "5 pontos",
  "SM.LOGIN.TITLE.CHOOSE_ORG_LIST": "Selecione uma organização.",
  "agentconsole.login.indexforipcc.mute": "A senha deve conter pelo menos três tipos dos seguintes: letras maiúsculas, minúsculas, dígitos e characters: -! {'@'} # $ % ^ & * () - especiais?",
  "SM.LOGIN.LABEL.CUS_LOGIN_REDIRECT": "Inicie sessão e pule para",
  "SM.LOGIN.TITLE.SELECT_ACCOUNT_TYPE": "Selecione um tipo de conta.",
  "SM.LOGIN.TITLE.PRINT_FREE_FILLING": "Imprimir recibo ACK",
  "agentconsole.sessionmanagement.label.search": "Consulta",
  "agentconsole.menumanage.message.menu_url": "O endereço de email não pode estar em chinês.",
  "SM.LOGIN.LABEL.CHANGEPWD_COMFIRMPWD": "Confirmar senha",
  "SM.LOGIN.LABEL.NOTICE_CREATOR": "Criador",
  "agentconsole.index.label.chat": "Serviço de Atendimento ao Cliente Online",
  "agentconsole.login.indexforipcc.title": "Cloud call center",
  "agentconsole.authmanage.rule.tips": "Selecione uma permissão personalizada. A permissão predefinida pelo sistema não pode ser selecionada.",
  "agentconsole.dashboard.title.casedone": "Número de casos tratados",
  "SM.SERVLET.BATCH.RESET.PASSWORD.EMPLISTEND": "]",
  "SM.LOGIN.BUTTON.FORGETPWD_EMAIL": "Email",
  "agentconsole.login.indexforipcc.lightweight": "Leve",
  "agentconsole.Intelligent.close": "Fechar",
  "agentconsole.dashboard.title.interactioncount": "Quantidade total de contato no dia atual:",
  "agentconsole.header.label.searchcommunity": "Pesquisar na Comunidade",
  "agentconsole.modifycustominfo.noqueryauth": "O operador atual não tem permissão para consultar dados personalizados.",
  "SM.LOGIN.TITLE.HELPCENTER": "Centro de Ajuda",
  "agentconsole.common.tips.warning": "Aviso",
  "agentconsole.login.indexforipcc.hold": "Call hold",
  "agentconsole.contacthistory.talking": "Sessão de voz",
  "agentconsole.menumanage.tab.basic_information": "Informações básicas",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_VERIFY_CODE_MINTIME_ERROR": "O código de verificação foi gerado.",
  "agentconsole.contacthistory.readMore": "Ver mais",
  "SM.LOGIN.RESETPWD.EMAILPATTERN_ERROR": "Endereço de email inválido.",
  "agentconsole.quickentrance.deleteinfo": "Tem certeza de que deseja excluir este diretório?",
  "agentconsole.authmanage.message.deleteauthfail": "Falha ao excluir a permissão. Verifique se a permissão foi referenciada por qualquer menu ou dada qualquer função.",
  "SM.LOGIN.TITLE.NONLOCAL_USER_CHANEPWD_ERROR": "Um usuário não local não pode alterar a senha.",
  "agentconsole.login.indexforipcc.onepoint": "Customer service personnel can check in the platform, answer incoming calls, transfer calls, mute, status switch or help.",
  "agentconsole.login.indexforipcc.loginportal": "Faça login no Portal da nuvem de atendimento ao cliente.",
  "SM.LOGIN.BUTTON.UPDATE_PWD_CLOSE": "Fechar",
  "agentconsole.common.button.cancel": "Cancelar",
  "SM.LOGIN.BUTTON.LOGIN_CONFIRM_CONTINUE": "Continue",
  "SM.LOGIN.TITLE.COPYRIGHT": "Copyright {0} {1} Huawei Software Technologies Co., Ltd. Todos os direitos reservados.",
  "agentconsole.menumanage.message.createfailures": "Falha ao criar menu",
  "SM.INDEX.LABEL.CASEDEC": "Você pode criar um caso para cada solicitação de serviço do cliente e monitorar o ciclo de vida completo do processamento da solicitação de serviço ao cliente através do caso, garantindo que cada solicitação do cliente seja fechada e melhorando a satisfação do cliente.",
  "agentconsole.menumanage.message.sysadmincannotaddmenu": "O administrador do sistema atual não pode criar um menu.",
  "SM.LOGIN.MESSAGE.UNLOCK_FAILED": "Falha ao desbloquear a conta. Insira a senha correta.",
  "agentconsole.authmanage.label.newauth": "Permissão nova",
  "agentconsole.login.indexforipcc.difplat": "Fácil de integrar em diferentes plataformas sem ocupar as páginas principais do portal e da Workbench",
  "agentconsole.callmessage.calledNo": "Chamado",
  "agentconsole.sessionmanagement.input.validatorMessage": "Caracteres especiais, exceto sublinhados (_), pontod (.), e arrobas ({'@'}) não são permitidos.",
  "agentconsole.index.label.case": "Caso",
  "agentconsole.login.indexforipcc.auth": "Controle de acesso",
  "agentconsole.dashboard.priority.medium": "Médio prioritário",
  "agentconsole.header.title.send": "Enviar mensagem interna",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_BE_SWITCH_ES": "A área atual foi mudadada para o estado de emergência. O negócio só pode ser tratado após o sistema ser recuperado. Entre no sistema de emergência para lidar com outros negócios.",
  "agentconsole.ccnotification.not.currently.on.call": "Não está em uma chamada no momento",
  "SM.LOGIN.BUTTON.LOGIN_CONFIRM_CANCEL": "Cancelar",
  "SM.LOGIN.BUTTON.SMS_RECONFIRM_CONFIRM": "OK",
  "agentconsole.authmanage.message.selectanauth": "Selecione uma permissão.",
  "SM.LOGIN.LABEL.LOGIN_CONFIRM_CONTINUE": "Continue to Access Current System",
  "SM.LOGIN.TITLE.INFO": "Solicitar",
  "agentconsole.workbench.label.remarks": "Observações",
  "SM.LOGIN.BUTTON.CANCEL": "Cancelar",
  "agentconsole.sessionmanagement.table.fail.delete": "Falha ao excluir",
  "SM.LOGIN.TITLE.CHANGEPWD_RULE": "A nova senha deve cumprir as seguintes regras:",
  "SM.LOGIN.BUTTON.FORGETPWD_SMS": "SMS",
  "SM.LOGIN.MESSAGE.LOGOUT_REASON.FORCE_CHANGE_PASSWORD": "Change the password after sign-in.",
  "SM.LOGIN.TITLE.CURRENT_SELECTED_PROJECT": "Selecionado:",
  "SM.LOGIN.LABEL.FRAMEWORK_AD_DEMAND": "Anúncio por solicitação",
  "SM.LOGIN.LABEL.CHANGEPWD_NEWPWD": "Nova senha",
  "SM.LOGIN.LABEL.NOTICE_GRADE_URGENT": "Rush",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_BIND_INFO_NULL": "A conta de login ou informações vinculativas estão vazias.",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_SEND_SMS_FAILED": "Falha ao enviar a mensagem SMS. Verifique o log backend.",
  "SM.LOGIN.MESSAGE.REPEAT_SUBMITED": "O pedido foi enviado. Aguarde...",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_VERIFY_CODE_DATE_INVALID": "Falha ao verificar o código de verificação.",
  "SM.LOGIN.TITLE.UPDATE_PWD_NEWPWD_CONFIRMPWD": "A nova senha deve ser a mesma que a senha de confirmação.",
  "agentconsole.dashboard.title.unassigned": "Não atribuído",
  "SM.LOGIN.MESSAGE.NONLOCAL_USER_RETRIEVEPASSWORD_FAILED": "Falha ao recuperar a senha. Usuários não locais não podem usar a função de recuperação de senha.",
  "SM.LOGIN.TIPS.SAVE": "Salvar",
  "agentconsole.dashboard.title.interactionperday": "Daily Contact Quantity",
  "SM.LOGIN.BUTTON.UPDATE_PWD_MODIFY_SUCCESS": "Modificado com sucesso.",
  "SM.LOGIN.TITLE.PASSWORD_INIT": "Você está usando a senha inicial. Altere a senha imediatamente.",
  "SM.PORTAL.LABEL.INPUT_CFM_PWD": "Confirme nova senha",
  "SM.LOGIN.TITLE.CHANGEPWD_SUCCESS": "Solicitar",
  "SM.LOGIN.LABEL.COPYRIGHT": "Copyright © Huawei Technologies Co., Ltd. 2018.",
  "agentconsole.dashboard.title.taskcreate": "Número de tarefas criadas",
  "SM.LOGIN.TITLE.PASSWORD_WILLEXPIRED": "Sua senha está prestes a expirar. Altere a senha.",
  "SM.LOGIN.BUTTON.QUERY": "Consulta",
  "agentconsole.dashboard.title.task": "Visão geral da tarefa",
  "SM.LOGIN.LABEL.AUTH_FAILED": "Conta ou senha incorretas.",
  "agentconsole.login.restpwd.fail": "Falha ao redefinir a senha.",
  "agentconsole.login.label.reset": "Redefinir",
  "SM.LOGIN.TIPS.LASTLOGINTIME": "Hora do último login:",
  "SM.LOGIN.LABEL.SYSTEM_ERROR": "Erro de sistema.",
  "agentconsole.dashboard.priority.high": "Prioridade alta",
  "SM.LOGIN.TITLE.NOTICE_LIST": "Lista de Comunicado",
  "agentconsole.login.label.username": "Nome do usuário",
  "agentconsole.index.label.chatdec": "Durante um bate-papo online multimídia com o cliente, você pode visualizar as informações históricas de contato completas do cliente para maximizar sua eficiência de serviço e fornecer experiência de serviço Omnichannel para seu cliente.",
  "agentconsole.ccnotification.has.been.sent": "Foi enviado",
  "SM.LOGIN.TITLE.UPDATE_PWD_MODIFY_FAILED": "Falha na modificação",
  "SM.LOGIN.TITLE.BE_CATALOG": "BE Catálogo:",
  "agentconsole.index.label.casedraft": "Meus casos rascunhos",
  "agentconsole.login.indexforipcc.opengateway": "Gateway aberto",
  "agentconsole.login.label.forgot": "Esquecer a senha?",
  "SM.LOGIN.TITLE.SMS_RECONFIRM": "Reconfirmação SMS",
  "agentconsole.callmessage.title": "Mensagem de chamada",
  "agentconsole.sessionmanagement.table.success.delete": "Exclusão realizada com sucesso.",
  "agentconsole.quickentrance.deleteclass": "Excluir este diretório",
  "agentconsole.login.indexforipcc.thirdparty": "Chamada de três vias",
  "agentconsole.sessionmanagement.table.deleteconfim": "Confirmar exclusão",
  "SM.LOGIN.LABEL.FAST_ENTER": "Entrada rápida",
  "SM.LOGIN.TITLE.UPDATE_PWD_LOGINID": "Conta:",
  "agentconsole.login.indexforipcc.sequence": "Consulta de informações de fila",
  "agentconsole.menumanage.field.url": "URL do menu",
  "agentconsole.login.indexforipcc.statecontrol": "Connection state control",
  "agentconsole.login.indexforipcc.signin": "Customer service personnel who need to handle voice calls check in to the CTI platform.",
  "agentconsole.login.indexforipcc.answer": "Responder chamada",
  "agentconsole.authmanage.message.notdelnoleafauth": "Não permitir a exclusão da permissão com subpermissões.",
  "SM.LOGIN.TITLE.APPEND_MENU_SEARCH": "Critérios de pesquisa:",
  "agentconsole.quickentrance.managemententrance": "Gerenciar Página Inicial",
  "agentconsole.authmanage.label.authtype.operation": "Permissão de operação",
  "SM.LOGIN.TITLE.APPEND_MENU_TITLE": "Adicionar menu de atalho",
  "agentconsole.dashboard.title.todo": "Lista de tarefas",
  "SM.CHANGELANGAUGE.REFRESH.MESSAGE": "A página inteira será recarregada depois que você mudar o idioma. Tem certeza de que deseja continuar?",
  "agentconsole.callmessage.callskill": "Fila de habilidades",
  "agentconsole.sessionmanagement.tip.error": "Erro",
  "SM.LOGIN.TITLE.UPDATE_PWD_OLDPWD": "Senha antiga:",
  "agentconsole.faqwordreminder.norecommend": "Nenhuma pergunta frequente recomendada disponível",
  "SM.LOGIN.MESSAGE.SELECT_PROJECT": "Selecione um projeto.",
  "agentconsole.icontitle.create_sibling": "Adicionar nível de peer",
  "SM.LOGIN.LABEL.PASSWORD": "Senha",
  "agentconsole.callmessage.faq.reminder": "Perguntas frequentes recomendadas",
  "SM.SERVLET.BATCH.RESET.PASSWORD.EMPLIST": "Redefinindo senhas de usuário de aluguer em lotes: [lista de funcionários:",
  "SM.LOGIN.MESSAGE.CHANGEPWD_SUCCESS": "A senha foi alterada com sucesso.",
  "agentconsole.login.indexforipcc.opengatewayde": "O Open Gateway fornece uma interface padrão baseada em protocolo RESTful para a função de chamada de voz da nuvem de atendimento ao cliente inteligente integrada do terceiro sistema. O sistema de terceiros pode implementar o call center em seu próprio sistema comprando o serviço sem comprar seu próprio equipamento de call center.",
  "SM.LOGIN.MESSAGE.MENU_ACCESS_TIME_NOT_MATCH": "A hora atual não está dentro do tempo de acesso permitido pelo menu. O tempo de acesso do menu é o seguinte:",
  "SM.LOGIN.LABEL.SETTINGS": "Configurações",
  "agentconsole.menumanage.field.menu_name": "Nome do menu",
  "agentconsole.common.button.save": "Salvar",
  "agentconsole.login.restpwd.errorinfo": "O nome de usuário ou senha está incorreto.",
  "SM.LOGIN.BUTTON.OK": "OK",
  "SM.LOGIN.TIPS.LOCK": "Tela de bloqueio",
  "SM.LOGIN.LABEL.NOTICE_GRADE_EXTRA_URGENT": "urgente",
  "SM.INDEX.LABEL.TASKDEC": "Durante o processamento de casos, você pode enviar tarefas para trabalhar com vários departamentos e funcionários. Várias tarefas podem ser criadas para um caso.",
  "agentconsole.common.tips.information": "Dicas",
  "agentconsole.dashboard.title.taskperday": "Daily Processing Status",
  "agentconsole.authmanage.message.notdelbaseauth": "Não permitir a exclusão da permissão pública.",
  "agentconsole.ccnotification.not.currently.on.three": "O fluxo não pode ser iniciado no estado atual da chamada",
  "agentconsole.header.label.home": "Página inicial",
  "agentconsole.custinfo.label.custId": "ID do cliente",
  "agentconsole.menumanage.message.deletemenufail": "Falha ao excluir o menu.",
  "agentconsole.dashboard.enumeration.today": "Hoje",
  "SM.LOGIN.LABEL.ERRORPAGE_ERRORNODETAIL_OPEN": "Detalhes<",
  "SM.LOGIN.LABEL.ERRORPAGE_ERRORSTACK": "Pilha de erros:",
  "agentconsole.header.title.search": "Pesquisar",
  "agentconsole.dashboard.priority.low": "Prioridade baixa",
  "agentconsole.wordreminder.recommend": "Script recomendado",
  "agentconsole.wordreminder.ai.recommend": "Script recomendado pela AI",
  "agentconsole.quickentrance.complete": "Completar",
  "SM.LOGIN.LABEL.FINISH": "Terminar",
  "SM.LOGIN.TIPS.LOGINIP": "Endereço IP:",
  "SM.LOGIN.MESSAGE.GRAFIC.VERIFY.CODE.REFRESH": "Trocar imagem",
  "SM.LOGIN.LABEL.RESET": "Redefinir",
  "SM.LOGIN.LABEL.LOGOUT": "Fazer sessão",
  "agentconsole.authmanage.message.deleteconfirm": "Tem certeza de que deseja excluir a permissão?",
  "SM.LOGIN.TITLE.UPDATE_PWD_CONFIRMPWD_EMPTY": "A senha de confirmação está vazia.",
  "agentconsole.contacthistory.oneMinAgo": "Minutos atrás",
  "agentconsole.contacthistory.hourAgo": "Horas atrás",
  "SM.LOGIN.TITLE.UPDATE_PWD_OLDPWD_ERROR": "A senha antiga está incorreta.",
  "agentconsole.login.indexforipcc.useage": "A nuvem inteligente de atendimento ao cliente fornece uma estrutura de assento que suporta recursos de multilocação, permitindo que os agentes sejam abertos e combinando as plataformas CTI e UAP da Huawei para fornecer aos clientes recursos completos de serviço de voz/vídeo na nuvem.",
  "agentconsole.dashboard.title.caseunassigned": "Não atribuído",
  "SM.LOGIN.TITLE.PORTAL_SWITCH_CONTEXTDATA": "Alternar BE",
  "SM.ORGSTAFF.LABEL.ORG_FORBID_TIPS": "Tem certeza de que quer desabilitar a organização?",
  "agentconsole.ccnotification.max.recipients": "Não mais de 5 destinatários",
  "SM.LOGIN.LABEL.NOTICE_STATUS": "Status",
  "SM.LOGIN.BUTTON.UPDATE_PWD_CANCEL": "Cancelar",
  "SM.ORGSTAFF.TITLE.ASSIGNE_MANAGER": "Gerente de Atribuição",
  "agentconsole.login.label.errorinfo": "Nome de usuário ou senha incorretos.",
  "agentconsole.ccnotification.recipient.cannot.empty": "destinatário não pode estar vazio",
  "SM.LOGIN.LABEL.LOCKED": "Bloqueado",
  "agentconsole.dashboard.title.mobile": "Telefone Celular",
  "agentconsole.authmanage.message.sysauthsubnode": "Não é possível adicionar subpermissão ao Gerenciamento do sistema.",
  "SM.LOGIN.TITLE.SWITCH_ESOP": "Alternar com um clique para ESOP",
  "agentconsole.quickentrance.all": "Todos",
  "agentconsole.workbench.label.userinfo": "Informações dos usuários",
  "agentconsole.dashboard.enumeration.thressdays": "Últimos 3 dias",
  "SM.LOGIN.LABEL.CANCEL": "Cancelar",
  "agentconsole.login.label.service_cloud_desc": "Limpar IU para serviço final",
  "agentconsole.modifycustominfo.nodatamodify": "Nenhum dado é alterado.",
  "SM.LOGIN.TITLE.NOTICE_CONTENT": "Conteúdo",
  "SM.AUC.MESSAGE.PWDRULE_YES": "Sim",
  "agentconsole.contacthistory.dayAgo": "Dias atrás",
  "agentconsole.login.indexforipcc.agentstate": "Controle de status do agente, como agente ocupado, lazer, descanso, etc.",
  "SM.LOGIN.TIPS.LOGOUT": "Assinado for a do sistema",
  "SM.LOGIN.MESSAGE.CHANGEPWD_OLDNEW": "A nova senha deve ser diferente da senha antiga.",
  "SM.LOGIN.LABEL.OPER_NAME": "Nome do usuário:",
  "SM.LOGIN.LABEL.UPDATE_PWD": "Alterar senha",
  "SM.ORGSTAFF.MESSAGE.STAFF_DELETE_CONFIRM": "Depois de um funcionário ser excluído, a conta correspondente do funcionário não pode ser restaurada e só pode ser recriada. Excluir ou não?",
  "SM.LOGIN.LABEL.NOTICE_GRADE": "Prioridade",
  "agentconsole.header.title.case": "Criar caso",
  "SM.ORGSTAFF.TITLE.OPERATION": "Operação",
  "SM.LOGIN.MESSAGE.SENDMAIL_SUCCESS": "A nova senha foi enviada para o seu endereço de e-mail. Use a nova senha para iniciar sessão.",
  "SM.LOGIN.TIPS.LOGINFAILED": "Falha no login.",
  "agentconsole.dashboard.enumeration.thirtydays": "Últimos 30 dias",
  "agentconsole.menumanage.message.deleteconfirm": "Tem certeza de que deseja excluir o menu?",
  "agentconsole.login.indexforipcc.easyuse": "Fácil de operar",
  "SM.LOGIN.TIPS.WELCOME_LABEL": "Saudação:",
  "agentconsole.dashboard.title.total": "Total",
  "SM.LOGIN.LABEL.EMERGENCY_NOTICE": "Aviso de emergência",
  "agentconsole.login.indexforipcc.deal": "Customer service personnel can handle the customer call as follows",
  "agentconsole.login.label.cancel": "Cancelar",
  "agentconsole.menu.config.center": "Centro de configuração",
  "SM.LOGIN.MESSAGE.SENDSMS_SUCCESS": "A nova senha foi enviada para o seu telefone celular. Use a nova senha para iniciar sessão.",
  "agentconsole.login.restpwd.emailpattern_error": "Endereço de email inválido.",
  "message.center.message.messageManage": "Gerenciamento interno de mensagens",
  "agentconsole.index.label.dashboard": "Dashboard",
  "agentconsole.menumanage.field.auth": "Permissão do menu",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_SWITCH_ES_SUFFIX": "continuar a lidar com o negócio.",
  "agentconsole.login.indexforipcc.release": "release",
  "agentconsole.dashboard.title.chat": "Visão geral do Chat Online",
  "agentconsole.index.label.querytask": "Tarefa de consulta",
  "SM.LOGIN.TITLE.NOTICE_CRUM": "Gerenciamento do Sistema > Manutenção do Sistema > Consulta de boletim",
  "agentconsole.sessionmanagement.tip.success": "Successo",
  "agentconsole.header.title.help": "Ajuda",
  "agentconsole.header.title.send.email.notification": "Mensagem de notificação de e-mail de saída",
  "agentconsole.login.indexforipcc.calldata": "Call data query",
  "agentconsole.dashboard.enumeration.yesterday": "ontem",
  "SM.LOGIN.LABEL.FRAMEWORK_CALENDAR": "Calendário",
  "SM.LOGIN.LABEL.FORGOTPASSWORD": "Esqueceu a senha?",
  "SM.LOGIN.BUTTON.LOGIN": "Iniciar sessão",
  "agentconsole.contacthistory.nocontactinfo": "Sem histórico de interação",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_BE_SWITCH_ES_SUFFIX": "menu para lidar com o negócio.",
  "SM.LOGIN.MESSAGE.CONFIRM": "Confirmar",
  "SM.LOGIN.MESSAGE.LOGOUT_REASON.NO_LOGIN": "Você não está logado. Tente logar novamente",
  "SM.LOGIN.TITLE.COMMONTOOLS": "Ferramentas comuns",
  "agentconsole.login.label.verifycode.empty": "O código de verificação está vazio",
  "agentconsole.dashboard.title.wechat": "WeChat",
  "SM.LOGIN.LABEL.NOTICE_INCLUDE_SUB_ORG_Y": "Sim",
  "SM.LOGIN.LABEL.VCODE_EMPTY": "O código de verificação está vazio",
  "SM.LOGIN.TITLE.DROPLIST_MENU": "Menu",
  "agentconsole.contact.customerInfo": "Informações do Cliente",
  "agentconsole.callmessage.setHandledNumber": "Definir número manipulado",
  "agentconsole.callmessage.setNumerSuccess": "A definição do número de tratamento foi bem-sucedida.",
  "agentconsole.callmessage.setNumerError": "Falha ao definir o número de tratamento.",
  "agentconsole.callmessage.query.external.custom.info": "Falha ao consultar informações personalizadas externas. Entre em contato com o pessoal de O&M para visualizar os logs.",
  "agentconsole.callmessage.setNumerLengthError": "O número de tratamento não pode exceder 24 caracteres.",
  "SM.LOGIN.LABEL.NOTICE_INCLUDE_SUB_ORG_N": "Não",
  "agentconsole.history.lastloginfail": "Último login com falha",
  "agentconsole.history.logintime": "Data",
  "agentconsole.menumanage.paneltitle.menumanage": "Gerenciamento de menus",
  "agentconsole.dashboard.title.evaluation": "Satisfação do Cliente",
  "SM.LOGIN.RESETPWD.ERRORINFO": "Conta ou email incorretos",
  "agentconsole.must.be.a.number":"deve ser um número",
  "WEB.VALIDATOR.RANGE":"Insira um valor entre {0} e {1}.",
  "agentconsole.title.transcription" : "Transcrição",
  "agentconsole.title.agent.overview" : "Visão geral do agente",
  "agentconsole.agent.assistant.recomend.history" : "Histórico de recomendações",
  "agentconsole.agent.assistant.faq.history" : "FAQ História",
  "agentconsole.agent.assistant.auto.create.case" : "Criar Caso Automaticamente",
  "agentconsole.agent.assistant.recomend.action" : "Ação Recomendada",
  "agentconsole.agent.assistant.summary" : "Gerar resumo",
  "agentconsole.agent.assistant.case" : "Criar um caso",
  "agentconsole.agent.refine.simple" : "Simples",
  "agentconsole.agent.refine.formal" : "Formal",
  "agentconsole.agent.refine.friendly" : "Amigável",
  "agentconsole.agent.refine.humorous" : "Humorístico",
  "agentconsole.agent.refine" : "Refinar",
  "agentconsole.agent.please.notice" : "Cuidado com a linguagem",
  "agentconsole.agent.knowledge.recommend" : "Conhecimentos recomendados",
  "agentconsole.agent.knowledge.map":"mapa",
  "agentconsole.agent.knowledge.product.recommend" : "Produtos Recomendados",
  "agentconsole.agent.knowledge.product.popular" : "Produtos Populares",
  "agentconsole.agent.knowledge.product.link" : "Descobrir Descontos",
  "agentconsole.agent.complaint.risk" : "Risco de reclamação",
  "agentconsole.agent.call.context" : "Contexto da Chamada",
  "agentconsole.agent.customer.info" : "Informações do Cliente",
  "agentconsole.agent.session.monitor" : "Monitor de sessão",
  "agentconsole.agent.basic.info" : "Informações Básicas",
  "agentconsole.agent.customer.name" : "Nome do Cliente",
  "agentconsole.agent.phone.number" : "Número de telemóvel",
  "agentconsole.agent.service.duration" : "Duração do acesso à rede",
  "agentconsole.agent.customer.brand" : "Marcas de propriedade",
  "agentconsole.agent.customer.status" : "Status do usuário",
  "agentconsole.agent.customer.address": "Local de origem",
  "agentconsole.agent.bussiness.info ":" Informações comerciais ",
  "agentconsole.agent.order.status ":" Situação da subscrição ",
  "agentconsole.agent.business.usage": "Uso do serviço",
  "agentconsole.agent.terminal.profile":"Informações do terminal ",
  "agentconsole.agent.terminal.vendor": "fabricante do terminal",
  "agentconsole.agent.terminal.model":"Modelo do terminal",
  "agentconsole.agent.network.standard ":" Padrão de rede ",
  "agentconsole.agent.generate.summary": "Gerar resumo",
  "agentconsole.agent.summary": "Resumo",
  "agentconsole.agent.submit.success": "Submeter com Sucesso",
  "agentconsole.agent.intelligent.fill": "Enchimento inteligente do TT",
  "agentconsole.agent.intelligent.click": "Preenchimento de bilhetes com um clique",
}