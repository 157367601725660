<template>
  <div>
    <sweet-dialog v-model="dialogVisible" :title="$t('isales.audio.title.recording')" width="616px" class="aicc-dialog-limit-height" :close-on-click-modal="false">
      <aicc-audio style="width: 60%"
          ref="aiccAudio"
          v-if="showAiccAudio"
          class="aicc-m-bottom--medium"
          :src="audioUrl"
          autoplay="autoplay"
          :canDownLoad="false"
          :canClose="false"
          @getAudioDuration="getAudioDuration"
      />
      <sweet-form :label-position="labelPosition"  label-width="180px" :model="form">
        <sweet-form-item :label="$t('isales.audio.title.callId')">
          <sweet-input class="inputDeep" v-model="callId" readonly/>
        </sweet-form-item>
        <sweet-form-item>
          <template #label>
            <sweet-tooltip
                :content="$t('isales.audio.title.audiotime')"
                placement="bottom"
                effect="light">
              <span>{{$t('isales.audio.title.audiotime')}}</span>
            </sweet-tooltip>
          </template>
          <sweet-input class="inputDeep"  v-model="duration" readonly/>
        </sweet-form-item>
        <sweet-form-item :label="$t('isales.audio.title.starttime')">
          <sweet-input class="inputDeep"  v-model="beginTime" readonly/>
        </sweet-form-item>
        <sweet-form-item :label="$t('isales.audio.title.endtime')">
          <sweet-input class="inputDeep"  v-model="endTime" readonly/>
        </sweet-form-item>
        <sweet-form-item :label="$t('isales.audio.title.calltype')">
          <sweet-input class="inputDeep"  v-model="callType" readonly/>
        </sweet-form-item>
      </sweet-form>
    </sweet-dialog>
  </div>
</template>
<script>

import {
  recordplayUrl,
  queryRecordInfoUrl
} from "@/views/isales/api/rbtrecognition";
import {ElMessage} from "element-plus";

export default {
  name: 'isalesAudioPlay',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    passParamToAudio: {
      type: Object
    }
  },
  data() {
    return {
      labelPosition: window.isRTL ? 'right' : 'left',
      showAiccAudio: true,
      audioUrl: '',
      audioPlaying: false,
      duration : '00:00:00',
      beginTime: '',
      callId: this.passParamToAudio.callId,
      endTime: '',
      callType: this.$t('isales.audio.content.calltype'),
      tempBeginTime: '',
      tempEndTime: ''
    }
  },

  mounted() {
    // 报错时显示日期格式
    this.beginTime = this.$TimeUtils.transTime(this.passParamToAudio.beginTime)
    this.endTime = this.$TimeUtils.transTime(this.passParamToAudio.endTime)

    this.tempBeginTime = Number(this.passParamToAudio.beginTime)
    this.tempEndTime = Number(this.passParamToAudio.endTime)
    this.date = new Date(this.tempBeginTime)
    let serviceUrl = null;
    if (this.passParamToAudio.id) {
      serviceUrl = recordplayUrl + "dataId=" + this.passParamToAudio.id + "&callId=" + this.passParamToAudio.callId + "&taskType=" + this.passParamToAudio.callType +
          "&agentWorkNo=" + this.passParamToAudio.workNo + "&month=" + (this.date.getMonth() + 1);
    } else if (this.passParamToAudio.resultId) {
      serviceUrl = recordplayUrl + "resultId=" + this.passParamToAudio.resultId + "&callId=" + this.passParamToAudio.callId + "&taskType=" + this.passParamToAudio.callType +
          "&agentWorkNo=" + this.passParamToAudio.workNo + "&month=" + (this.date.getMonth() + 1);
    } else if (this.passParamToAudio.appointId) {
      serviceUrl = recordplayUrl + "appointId=" + this.passParamToAudio.appointId + "&callId=" + this.passParamToAudio.callId + "&taskType=" + this.passParamToAudio.callType +
          "&agentWorkNo=" + this.passParamToAudio.workNo + "&month=" + (this.date.getMonth() + 1);
    } else {
      serviceUrl = recordplayUrl + "rbtRecognitionId=" + this.passParamToAudio.rbtId + "&taskType=" + this.passParamToAudio.taskType + "&beginTime=" + this.tempBeginTime +
          "&endTime=" + this.tempEndTime + "&month=" + (this.date.getMonth() + 1);
    }
    this.audioUrl = serviceUrl
    this.queryRecordInfo();
  },

  methods: {
    /**
     * 播放时长
     * @param duration
     */
    getAudioDuration(duration) {
      this.duration = this.transTime(duration);
    },

    queryRecordInfo() {
      let params = {
        "paramMonth": this.date.getMonth() + 1,
        "agentWorkNo": this.passParamToAudio.workNo,
        "callId": this.passParamToAudio.callId
      };
      if(this.passParamToAudio.callType){
        params["taskType"] = this.passParamToAudio.callType;
      }
      // 请求
      queryRecordInfoUrl(params).then(res => {
        this.beginTime = this.$TimeUtils.transTime(res.BeginTime)
        this.endTime = this.$TimeUtils.transTime(res.EndTime)
        this.callId = this.passParamToAudio.callId;
        if (res.CallId) {
          this.callId = res.CallId;
        }
        if (!(res.BeginTime && res.EndTime)) {
          ElMessage({
            type: 'error',
            duration: 10000,
            'show-close': true,
            offset: 56,
            message: this.$t('isales.audio.message.errortip1')
          });
        }
      }).catch(() => {
        ElMessage({
          type: 'error',
          duration: 10000,
          offset: 56,
          'show-close': true,
          message: this.$t('isales.audio.message.errortip1')
        });
      })
    },

    /**
     * 音频播放时间换算
     * @param value
     */
    transTime(value) {
      let time = "";
      let h = parseInt((value / 3600).toString());
      value %= 3600;
      let m = parseInt((value / 60).toString());
      let s = parseInt((value % 60).toString());
      if (h > 0) {
        time = this.formatTime(h + ":" + m + ":" + s);
      } else {
        time = this.formatTime("00" + ":" + m + ":" + s);
      }
      return time;
    },

    /**
     * 格式化时间显示，补零对齐
     * eg：2:4  -->  02:04
     * @param {string} value - 形如 h:m:s 的字符串
     */
    formatTime(value) {
      let time = "";
      let s = value.split(':');
      let i = 0;
      for (; i < s.length - 1; i++) {
        time += s[i].length == 1 ? ("0" + s[i]) : s[i];
        time += ":";
      }
      time += s[i].length == 1 ? ("0" + s[i]) : s[i];
      return time;
    }
  },

  computed: {
    dialogVisible: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      }
    }
  }
}
</script>

<style scoped>
.inputDeep :deep(.el-input__wrapper){
  border: none;
  box-shadow: 0 0 0 0px
}
</style>
