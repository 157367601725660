import { defineStore } from 'pinia'

export const useMessageTemplateStore = defineStore('messageTemplate', {
  state: () => {
    return {
      createOrEditOver: false
    }
  },
  actions: {
    initCreateOrEditOver(param) {
      this.createOrEditOver = param
    }
  }
})
