export default {
  'oifde.traceCause.000001': 'O tipo de transferência não está configurado para o elemento do diagrama de transferência de chamadas.',
  'oifde.traceCause.000002': 'A interface está anormal porque o valor de entrada de inação está incorreto. {0}',
  'oifde.traceCause.000003': 'O elemento do diagrama composto não foi encontrado.',
  'oifde.traceCause.000004': 'O nó inicial não foi encontrado.',
  'oifde.traceCause.000005': 'O número de reciclagens internas excede o limite.',
  'oifde.traceCause.000006': 'O processamento do elemento do diagrama está anormal.',
  'oifde.traceCause.000007': 'A ramificação de saída está vazia.',
  'oifde.traceCause.000008': 'Erro interno do sistema.',
  'oifde.traceCause.000009': 'A ramificação não foi encontrada. {0}',
  'oifde.traceCause.000010': 'O elemento do diagrama de transferência de fluxo não pode ser transferido para o próximo nó. {0}',
  'oifde.traceCause.000011': 'O tipo de elemento do diagrama está incorreto.',
  'oifde.traceCause.000012': 'Erro de execução da expressão {0}.',
  'oifde.traceCause.000013': 'Falha ao executar a interface de terceiros.',
  'oifde.traceCause.000014': 'O modelo de resposta e a variável não estão selecionados.',
  'oifde.traceCause.000015': 'O modelo de SMS não está configurado para o elemento do diagrama de envio de SMS.',
  'oifde.traceCause.000016': 'O idioma do modelo de resposta é inconsistente com o idioma definido. {0}',
  'oifde.traceCause.000017': 'O idioma do modelo de SMS é diferente do idioma definido no elemento do diagrama de envio de SMS. {0}',
  'oifde.traceCause.000018': 'O fluxo de destino e o nó de destino não estão configurados para o elemento do diagrama de transferência de fluxo.',
  'oifde.traceCause.000019': 'Nenhum locatário foi encontrado.',
  'oifde.traceCause.000020': 'O locatário foi suspenso.',
  'oifde.traceCause.000021': 'O módulo foi inicializado mais de uma vez.',
  'oifde.traceCause.000022': 'O elemento do diagrama composto não tem um nó inicial.',
  'oifde.traceCause.000023': 'Erro interno do sistema.',
  'oifde.traceCause.000024': 'A execução do fluxo interno está anormal.',
  'oifde.traceCause.000025': 'O desligamento automático será realizado se as exceções internas ocorrerem mais de três vezes.',
  'oifde.traceCause.000026': 'O fluxo não foi inicializado.',
  'oifde.traceCause.000028': 'O fluxo foi encerrado.',
  'oifde.traceCause.000029': 'Nenhuma saída foi encontrada no elemento do diagrama atual cuja ID do nó é {0} e o nome do nó é {1}.',
  'oifde.traceCause.000030': 'Os dados do elemento do diagrama cuja ID do nó é {0} e o nome do nó é {1} é anormal.',
  'oifde.traceCause.000031': 'Erro interno do sistema.',
  'oifde.traceCause.000032': 'A execução do fluxo interno está anormal.',
  'oifde.traceCause.000033': 'As informações da instância da chamada não existem.',
  'oifde.traceCause.000034': 'O elemento do diagrama de encerramento de chamada não existe.',
  'oifde.traceCause.000035': 'Ocorreu um erro ao verificar o auth_token.',
  'oifde.traceCause.000036': 'Ocorreu um erro ao verificar o auth_token.',
  'oifde.traceCause.000037': 'Ocorreu um erro ao verificar o auth_token.',
  'oifde.traceCause.000038': 'Falha ao processar o próximo nó.',
  'oifde.traceCause.000039': 'Falha ao invocar o TUC. {0}',
  'oifde.traceCause.000040': 'O erro não foi encontrado. {0}',
  'oifde.traceCause.000041': 'Erro de execução do procedimento armazenado. {0}',
  'oifde.traceCause.000044': 'O URL (endereço IP e número da porta) não está na lista de confiança.',
  'oifde.traceAdvice.000001': 'Configure o tipo de transferência de chamada para o elemento do diagrama de transferência de chamada.',
  'oifde.traceAdvice.000002': 'Entre em contato com o administrador do sistema para modificar os parâmetros de entrada da interface.',
  'oifde.traceAdvice.000003': 'Verifique se o elemento do diagrama composto existe no sistema.',
  'oifde.traceAdvice.000004': 'Verifique se o fluxo está liberado ou se está normal.',
  'oifde.traceAdvice.000005': 'Contate o administrador para verificar o sistema.',
  'oifde.traceAdvice.000006': 'Contate o administrador do sistema para verificar se a lógica do elemento do diagrama está correto.',
  'oifde.traceAdvice.000007': 'Contate o administrador do sistema para verificar se a lógica do elemento do diagrama está correto.',
  'oifde.traceAdvice.000008': 'Contate o administrador para verificar o sistema.',
  'oifde.traceAdvice.000009': 'Verifique se a saída do elemento do diagrama atual tem ramificações não processadas. É recomendável que todos os elementos do diagrama tenham uma ramificação incondicional padrão.',
  'oifde.traceAdvice.000010': 'Verifique se o elemento do diagrama de transferência de fluxo está configurado corretamente.',
  'oifde.traceAdvice.000011': 'Contate o administrador para verificar o sistema.',
  'oifde.traceAdvice.000012': 'Verifique se a sintaxe configurada para a expressão está correta e se as variáveis na expressão têm valores.',
  'oifde.traceAdvice.000013': 'Verifique se a interface de terceiros está normal.',
  'oifde.traceAdvice.000014': 'Verifique o elemento do diagrama de resposta para garantir que pelo menos um dos modelos de resposta ou variáveis de resposta tenha um valor.',
  'oifde.traceAdvice.000015': 'Verifique se o modelo de SMS está configurado para o elemento do diagrama de envio de SMS.',
  'oifde.traceAdvice.000016': 'Verifique a configuração de conteúdo e idioma no modelo de recurso, o idioma interno na variável global e se um novo valor é atribuído ao idioma na orquestração de fluxo. O idioma usado para reprodução deve ser configurado no modelo de recurso.',
  'oifde.traceAdvice.000017': 'Verifique a configuração de conteúdo e idioma no modelo de recurso, o idioma interno na variável global e se um novo valor é atribuído ao idioma na orquestração de fluxo. O idioma usado para reprodução deve ser configurado no modelo de recurso.',
  'oifde.traceAdvice.000018': 'Verifique se o elemento do diagrama de transferência de fluxo está configurado corretamente.',
  'oifde.traceAdvice.000019': 'Verifique se o código de acesso transferido pela interface de diálogo é normal e existe no sistema.',
  'oifde.traceAdvice.000020': 'Verifique se o status do locatário está normal.',
  'oifde.traceAdvice.000021': 'Verifique se o aplicativo de terceiros ou o IVR envia repetidamente uma solicitação de inicialização com a mesma ID de chamada.',
  'oifde.traceAdvice.000022': 'Verifique se o elemento do diagrama composto está normal e se existe no sistema.',
  'oifde.traceAdvice.000023': 'Contate o administrador para verificar o sistema.',
  'oifde.traceAdvice.000024': 'Verifique as informações de erro de cada elemento do diagrama e corrija os erros de acordo.',
  'oifde.traceAdvice.000025': 'Contate o administrador para verificar o sistema.',
  'oifde.traceAdvice.000026': 'O fluxo de execução está anormal. É preciso fazer uma solicitação para invocar a interface de inicialização antes.',
  'oifde.traceAdvice.000028': 'Verifique se a o fluxo está normal.',
  'oifde.traceAdvice.000029': 'Verifique se uma saída de nó está configurada para o elemento do diagrama.',
  'oifde.traceAdvice.000030': 'Contate o administrador do sistema para verificar se a configuração do elemento do diagrama está correta.',
  'oifde.traceAdvice.000031': 'Contate o administrador para verificar o sistema.',
  'oifde.traceAdvice.000032': 'Verifique as informações de erro de cada elemento do diagrama e corrija os erros de acordo.',
  'oifde.traceAdvice.000033': 'Verifique se a interface para a solicitação de desligamento envia a ID da chamada incorreta.',
  'oifde.traceAdvice.000034': 'Verifique se o elemento do diagrama de encerramento de chamada está configurado corretamente para o fluxo.',
  'oifde.traceAdvice.000035': 'Verifique se a entrada auth_token está correta.',
  'oifde.traceAdvice.000036': 'Verifique se a entrada auth_token está correta.',
  'oifde.traceAdvice.000037': 'Verifique se a entrada auth_token está correta.',
  'oifde.traceAdvice.000038': 'Contate o administrador para verificar o sistema.',
  'oifde.traceAdvice.000039': '1. Verifique se o endereço TUC e a porta configurados no ODFS estão normais. 2. Verifique se os serviços em cada nó do TUC estão normais.',
  'oifde.traceAdvice.000040': 'Verifique se o procedimento armazenado está normal.',
  'oifde.traceAdvice.000041': 'Verifique se o procedimento armazenado está normal.',
  'oifde.traceAdvice.000044': 'Entre em contato com o administrador do sistema para adicioná-lo à lista de confiança.'
}
