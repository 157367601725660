<template>
  <sweet-dialog v-model="isShow" :title="title" width="896px" class="aicc-dialog-limit-height" :close-on-click-modal="false"  @close="onDialogClose">

    <aicc-advanced-search ref='advancedSearch'
     :templateMetaData="templateMetaData"
     @select="handleSearch"
     @handleSearch="handleSearch"
     @inputClear="handleSearch"
     @handleRefresh="handleSearch" />
     
    <aicc-table row-key="tenant_id" @handlePagination="handlePagination" :tableData="tenantList" :tableColumns="tableColumns" :tableTools="{ columnConfig: false, showPagination: true }" :paginationConfig="paginationConfig" @select="handleSelect" @select-all="handleSelectAll" ref="refAiccTable" height="430" />

    <template #footer>
      <span class="dialog-footer">
        <sweet-button @click="close">{{ $t("message.template.message.cancel") }}</sweet-button>
        <sweet-button type="primary" @click="confirmSelect">{{ $t("ccnotification.common.button.finish") }}</sweet-button>
      </span>
    </template>
  </sweet-dialog>
</template>

<script>
import { queryAllCallCenterInstance, queryTenantSpaceInformation } from '../../../api/index'

export default {
  data() {
    return {
      isShow: true,
      offset: 0,
      limit: 10,
      tenantList: [],
      selectedData: [],
      allTenants: [],
      ccInstId: '',
      tenantSpaceName: '',
      companyName: '',
      tableColumns: [
        {
          type: 'selection',
          'reserve-selection': true,
          width: '50px'
        },
        {
          label: $t('provision.callcenterinstanceslist.table.ccName'),
          prop: 'ccName'
        },
        {
          label: $t('provision.callcenterinstanceslist.sipServer.tenantname'),
          prop: 'tenantSpaceName'
        },
        {
          label: $t('provision.tenant.management.tenantcreate.companyName'),
          prop: 'companyName'
        }
      ],
      paginationConfig: {
        pageSizes: [10, 20, 50, 100],
        pageSize: 10,
        total: 0,
        layout: "sizes, total, prev, pager, next"
      },
      templateMetaData: {
        // 基础搜索配置
        basicConfig: {
          iconButton: [{
            buttonType: 'refresh',
            buttonTitle: $t('cms.callcenterinstanceslist.label.refresh')
          }], // 图标按钮

          basicSearch: [{
            searchType: 'select',
            changeEvent: 'select',
            filterable: false,
            modelValue: 'ccInstId',
            clearable: true,
            placeholderValue: $t('provision.callcenterinstanceslist.table.ccName'),
            selectOptions: [],
            colConfig: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12
            },
          },
          {
            searchType: 'primary',
            filterable: false,
            modelValue: 'tenantSpaceName',
            clearable: true,
            placeholderValue: $t('provision.callcenterinstanceslist.sipServer.tenantname'),
            colConfig: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12
            },
          },
          {
            searchType: 'primary',
            filterable: false,
            modelValue: 'companyName',
            clearable: true,
            placeholderValue: $t('provision.tenant.management.tenantcreate.companyName'),
            colConfig: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12
            },
          }],
        },
      },
    }
  },
  methods: {
    onDialogClose() {
      this.$emit('onClose')
    },
    searchTenants() {
      let data = {
        limit: this.limit,
        offset: this.offset
      }
      if (this.ccInstId) {
        data.ccInstId = this.ccInstId
      }
      if (this.tenantSpaceName) {
        data.tenantSpaceName = this.tenantSpaceName
      }
      if (this.companyName) {
        data.companyName = this.companyName
      }
      queryTenantSpaceInformation(data).then(res => {
        if (res) {
          if (res.total) {
            this.paginationConfig.total = res.total
          }
          if (res.tenantSpaceList) {
            this.tenantList = res.tenantSpaceList
          }

          this.$nextTick(() => {
            this.toggleSelectedRow()
          })
        }
      })
    },
    toggleSelectedRow() {
      this.tenantList.forEach(item => {
        for (let i = 0; i < this.selectedData.length; i++) {
          if (this.selectedData[i].tenant_id == item.tenant_id) {
            this.$refs.refAiccTable.table.toggleRowSelection(item, true)
          }
        }
      })
    },
    handlePagination(val) {
      this.limit = val.limit
      this.offset = val.offset
      this.searchTenants()
    },
    handleSearch({params}) {
      this.ccInstId = params.ccInstId
      this.tenantSpaceName = params.tenantSpaceName
      this.companyName = params.companyName
      this.offset = 0
      this.$refs.refAiccTable.setCurrentPage(1)
      this.searchTenants()
    },
    findTenant(tenantId) {
      for (let index = 0; index < this.allTenants.length; index++) {
        const element = this.allTenants[index]
        if (tenantId == element.tenant_id) {
          return element
        }
      }
    },
    initData() {
      this.queryCallCenterInstances()
      //先查询所有租间信息，将传入的租间id映射为租间信息
      queryTenantSpaceInformation({}).then(res => {
        if (res && res.tenantSpaceList) {
          this.allTenants = res.tenantSpaceList
          if (this.selectedTenants) {
            this.selectedData = this.selectedTenants.map(tenant => {
              if (typeof tenant == 'string') {
                  return this.findTenant(tenant)
                } else {
                  return tenant
                }
            })
          }
          this.searchTenants()
        }
      })
    },
    queryCallCenterInstances() {
      queryAllCallCenterInstance({}).then(res => {
        if (res) {
          this.templateMetaData.basicConfig.basicSearch[0].selectOptions = res.map(item => {
            return {label: item.ccName, value: item.id}
          })
        }
      })
    },
    confirmSelect() {
      if (this.selectedData) {
        this.$emit('teantSelected', this.selectedData)
        this.close()
      }
    },
    close() {
      this.isShow = false
    },
    handleSelect(selection, row) {
      let item = this.selectedData.find(item => item.tenant_id == row.tenant_id)
      if (item) {
        this.selectedData = this.selectedData.filter(item => item.tenant_id != row.tenant_id)
      } else {
        this.selectedData.push(row)
      }
    },
    handleSelectAll(selection) {
      //判断当前操作是选择当前页还是取消选择
      let isSelect = selection.some(item => item.tenant_id == this.tenantList[0].tenant_id)
      if (isSelect) {
        let selectedIds = this.selectedData.map(item => item.tenant_id)
        // 之前没有选择的行，添加到已选择
        this.tenantList.filter(item => {
          return undefined == selectedIds.find(id => id == item.tenant_id)
        }).forEach(item => {
          this.selectedData.push(item)
        })
      } else {
        let cancelIds = this.tenantList.map(item => item.tenant_id)
        // 表格页取消全选时，已选择数据去掉当前展示的数据
        this.selectedData = this.selectedData.filter(item => {
          return undefined == cancelIds.find(id => id == item.tenant_id)
        })
      }
    },
  },
  mounted() {
    this.initData()
  },
  props: {
    title: String,
    selectedTenants: {
      type: Array
    }
  }
}
</script>