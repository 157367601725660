export default {
  "ikbs.constableTmp.config" : "Configurar",
  "ikbs.constableTmp.status.draft" : "Ativado",
  "ikbs.cntTmp.import.rowNumOver" : "Um máximo de 10 registros podem ser importados.",
  "ikbs.column.unlock.message.warning" : "Informações",
  "ikbs.knowledge.label.abstract" : "Resumo",
  "ikbs.properties.add.information" : "Adicionar atributo estendido",
  "ikbs.contTmp.exception.maxCountPerTenant" : "Um inquilino pode ter um máximo de 500 modelos",
  "ikbs.knowledge.properties.operation.upMove" : "Mover para cima",
  "ikbs.column.delete.selectNoColumnKngToDelete" : "Nenhum conhecimento de categoria está selecionado.",
  "ikbs.tips.canNotAddColumnKngOnHistoryColumn" : "O conhecimento não pode ser adicionado à categoria de histórico.",
  "ikbs.knowledge.properties.label.inputWay" : "Tipo de entrada",
  "ikbs.contentTmp.exception.tmpContentAndEditStandard" : "O conteúdo do modelo e as especificações de edição não podem ficar vazios ao mesmo tempo",
  "ikbs.knowledge.label.uploadSingleFile" : "Apenas um anexo pode ser carregado de cada vez.",
  "ikbs.knowledge.tip.operateSuccess" : "Operação bem-sucedida.",
  "ikbs.constableTmp.operator.lt" : "menos do que",
  "ikbs.enter.search.condition" : "Insira os critérios de pesquisa",
  "ikbs.search.label.sortByDefault" : "Classificação padrão",
  "ikbs.knowledge.recycle.coluKngType" : "Tipo",
  "ikbs.consult.table" : "Formulário de consulta",
  "ikbs.knowledge.label.path" : "Caminho",
  "ikbs.column.release.message.fail" : "Falha na publicação.",
  "ikbs.column.knowledge.columnInformation" : "Adicionar categoria",
  "ikbs.constableTmp.operator.le" : "menor ou igual a",
  "ikbs.knowledge.service.common" : "Comum",
  "ikbs.knowledge.recycle.select.knowledgeType" : "Tipo de conhecimento",
  "ikbs.tips.propertyDictValueAtLeastOne.warning" : "O atributo estendido deve ter pelo menos um valor de dicionário.",
  "ikbs.column.displayflag.hide" : "Não",
  "ikbs.column.knowledge.button.add" : "Novo",
  "ikbs.column.knowledge.modify.properties" : "Modificar atributos em lote",
  "ikbs.knowledge.release.public" : "Publicar diretamente",
  "ikbs.knowledge.label.selectProperty" : "Selecionar",
  "ikbs.contentTmp.status.draft" : "Rascunho",
  "ikbs.knowledge.label.serviceStatus" : "Status comercial",
  "ikbs.knowledge.service.update" : "Negócios atualizados",
  "ikbs.column.serviceStatus.newService" : "Novos negócios",
  "ikbs.knowledge.label.answer" : "Responder",
  "ikbs.constableTmp.reset" : "Redefinir",
  "ikbs.knowledge.label.modifyTime" : "Última atualização em",
  "ikbs.column.type.historyMark" : "Histórico",
  "ikbs.kngCnt.cntTmp.icon.tip" : "Modelo de conteúdo",
  "ikbs.knowledge.label.currentVersion" : "Versão atual",
  "ikbs.columntype.column" : "Categoria",
  "ikbs.enter.search.keywords" : "Insira uma palavra-chave de pesquisa",
  "ikbs.contentTmp.delete.fail" : "Falha de Deleção",
  "ikbs.column.label.coluKngMaintaining" : "grupo de manutenção",
  "ikbs.knowledge.label.kngAttachmentDownload" : "Baixar anexo de categoria de conhecimento",
  "ikbs.search.label.updateEndTime" : "Atualizar Hora Final",
  "ikbs.knowledgeaudit.label.pass" : "Aprovar",
  "ikbs.knowledge.tip.selectColumn" : "Selecione uma categoria.",
  "ikbs.constableTmp.searchFlag" : "Critério de pesquisa ou não",
  "ikbs.properties.add.cancel" : "Cancelar",
  "ikbs.column.needAudit.yes" : "Sim",
  "ikbs.knowledge.properties.operation.enable" : "Ativar",
  "ikbs.column.type.normal" : "Categoria normal",
  "ikbs.knowledge.label.passProcecWay" : "Após a expiração",
  "ikbs.column.knowledge.MoveType" : "Local de ajuste",
  "ikbs.confirm.delete.by.select" : "Confirmar exclusão dos registros selecionados",
  "ikbs.knowledge.label.relaKngs" : "Associação de conhecimento",
  "ikbs.constableTmp.fieldName" : "Campo",
  "ikbs.column.copy.message.warning" : "Informações",
  "ikbs.column.deletePermanently.message.comfirm" : "Confirmar",
  "ikbs.column.moveto.front" : "Frente de",
  "ikbs.kbshome.button.orderByTime" : "Horário",
  "ikbs.constableTmp.moveDown" : "Para baixo",
  "ikbs.tips.canNotAddKngOnRootColumn" : "O conhecimento não pode ser adicionado à categoria raiz.",
  "ikbs.column.knowledge.button.up" : "Mover para cima",
  "ikbs.constableTmp.modify" : "Modificar",
  "ikbs.constableTmp.operator.gt" : "maior",
  "ikbs.constableTmp.operator.include" : "Incluir",
  "ikbs.knghisverman.label.revert" : "Restaurar",
  "ikbs.constableTmp.plsSelectModifyBeginTime" : "Definir a hora de início da modificação",
  "ikbs.knowledgeaudit.label.submitter" : "Enviado por",
  "ikbs.column.label.kngExtended" : "Atributo estendido",
  "ikbs.constableTmp.operator.ge" : "maior ou igual a",
  "ikbs.knowledge.label.listProp" : "Lista suspensa",
  "ikbs.knowledgeaudit.title.auditor" : "Revisado por",
  "ikbs.knowledge.recycle.pleaseSelect" : "- Selecionar",
  "ikbs.constableTmp.tab.addTitle" : "Adicionando modelo",
  "ikbs.knghisverman.tab.title.confirm" : "Confirmar",
  "ikbs.column.knowledge.button.move" : "Mover",
  "ikbs.favorite.msg.plsSelectDir" : "Selecione o diretório a ser excluído.",
  "ikbs.knowledge.tip.fileNameError" : "Falha ao verificar o nome do arquivo.",
  "ikbs.knowledge.message.uploadImgSizeError" : "O tamanho da imagem não pode exceder 10 MB.",
  "ikbs.knowledge.display.no" : "Não",
  "ikbs.properties.inputway.select" : "Lista suspensa",
  "ikbs.favorite.title.afterModifiedKngName" : "Após a alteração",
  "ikbs.contentTmp.status" : "Status do modelo",
  "ikbs.column.topMove.message.warning" : "Informações",
  "ikbs.constableManage.fieldOprator" : "Operador",
  "ikbs.constableTmp.modifyEndTime" : "Hora de término da modificação",
  "ikbs.cntTmp.import.noData" : "O arquivo carregado não contém dados a serem importados.",
  "ikbs.addtype.label.knowledge" : "Adicionar conhecimento",
  "ikbs.contentTemp.export.tempname" : "Nome do modelo",
  "ikbs.constableTmp.operator.eq" : "igual a",
  "ikbs.knowledge.tip.success" : "Sucesso",
  "ikbs.knowledge.label.radioProp" : "Caixa de rádio",
  "ikbs.intelligent.search" : "Pesquisa inteligente",
  "ikbs.contentTmp.contentName" : "Nome do modelo",
  "ikbs.kbshome.tab.home" : "Página inicial da base de conhecimento",
  "ikbs.column.passProcessWay.mark" : "Marcar",
  "ikbs.column.modify.modifyValidTimeCheckError" : "A hora de início do período de validade não pode ser posterior à hora de término do período de validade",
  "ikbs.constableTmp.delete.selectTmp.confirm" : "Tem certeza de que deseja excluir?",
  "ikbs.contentTmp.ModifyEndTime" : "Fim modificado",
  "ikbs.knowledge.recycle.beyondColuPath" : "Diretório",
  "ikbs.favorite.msg.canNotSelectRoot" : "O diretório raiz não pode ser excluído. Selecione outra.",
  "ikbs.column.knowledge.button.modify" : "Modificar",
  "ikbs.knowledgeaudit.message.plsSelectBeginTime" : "Selecione a hora de início da tarefa.",
  "ikbs.contentTmp.tab.addTitle" : "Adicionando modelo",
  "ikbs.column.clearRecycle.comfirm" : "Tem certeza de que deseja esvaziar a lixeira?",
  "ikbs.knowledgeaudit.message.plsSelectEndTime" : "Selecione a hora final da tarefa.",
  "ikbs.contTmp.importTmp.popup.title" : "Importação de modelo",
  "ikbs.knowledge.label.uploadFileTypeError" : "O anexo só pode estar em formato DOC, DOCX, PPT, PPTX, XLS, XLSX, PDF, PNG, JPG ou GIF.",
  "ikbs.column.modify.selectModifyColumnKngNumError" : "Apenas um item de conhecimento pode ser selecionado para modificação.",
  "ikbs.column.knowledge.create" : "Novo",
  "ikbs.confirm.delete" : "Confirmar exclusão deste registro",
  "ikbs.column.operation.message.warning" : "Informações",
  "ikbs.contentTmp.copy" : "Cópia",
  "ikbs.column.label.passProcessWay" : "Após a expiração",
  "ikbs.knowledgeaudit.label.auditStatus" : "Status",
  "ikbs.favorite.tab.message.isDeleteFavoriteKng" : "Tem certeza de que deseja excluir os itens de conhecimento dos favoritos?",
  "ikbs.select.consultation.form" : "Por favor, selecione o formulário de consulta",
  "ikbs.knowledge.label.sendEmail" : "Enviar e-mail",
  "ikbs.tips.operation.success" : "Operação bem-sucedida.",
  "ikbs.column.knowledge.KngStatus" : "Status",
  "ikbs.contentTmp.add" : "Adicionar",
  "ikbs.properties.edit.information" : "Editar atributo estendido",
  "ikbs.column.knowledge.title.moveTo" : "Ajustar para",
  "ikbs.column.knowledge.button.more" : "Mais",
  "ikbs.knowledge.properties.operation.downMove" : "Para baixo",
  "ikbs.kbshome.label.kngBase" : "Base de conhecimento",
  "ikbs.confirm.delete.by.condition" : "Confirmar exclusão de registros por condição",
  "ikbs.column.serviceStatus.normal" : "Comum",
  "ikbs.constableTmp.comfirm" : "Confirme-se",
  "ikbs.tips.moveColumnKnowledgeOperation.warning" : "Nenhuma categoria está selecionada.",
  "ikbs.column.serviceStatus.updateService" : "Negócios atualizados",
  "ikbs.knowledge.tip.cannotCompare" : "Apenas dois conhecimentos gerais podem ser selecionados para comparação",
  "ikbs.cntTmp.import.importSuccess" : "Sucesso de Importação.",
  "ikbs.column.label.coluType" : "Tipo de categoria",
  "ikbs.knowledge.label.submit" : "Enviar",
  "ikbs.knowledge.message.uploadFileSizeError" : "O tamanho do arquivo não pode exceder 10 MB.",
  "ikbs.knowledge.label.deleteProblem" : "Excluir perguntas e respostas",
  "ikbs.knowledge.recycle.select.columnType" : "Tipo de coluna",
  "ikbs.column.delete.property.comfirm" : "Tem certeza de que deseja excluir o atributo?",
  "ikbs.knowledge.placeholder.keyword" : "Inserir palavras-chave que são separadas por ponto e vírgula (;).",
  "ikbs.kngCnt.cntTmp.popup.title" : "Selecionar modelo de conteúdo",
  "ikbs.knowledge.properties.operation.delete" : "Excluir",
  "ikbs.column.knowledge.compareAdd" : "Novo",
  "ikbs.knowledgeaudit.label.waitForAudit" : "Revisão pendente",
  "ikbs.constableTmp.cnstTblTmpDesc" : "Descrição do modelo",
  "ikbs.column.label.colukngname" : "Nome da categoria",
  "ikbs.knowledgeaudit.label.batchAuditReject" : "Rejeição do lote",
  "ikbs.constableTmp.display.no" : "não",
  "ikbs.title.tips" : "Informações",
  "ikbs.column.delete.selectNoColumn" : "Nenhuma categoria está selecionada.",
  "ikbs.column.knowledge.title.move" : "Mover",
  "ikbs.column.isInheritProp.no" : "Não",
  "ikbs.column.message.plsSelectModifyEndTime" : "Selecione um horário de término",
  "ikbs.column.label.servicesVaildStartTime" : "Início do período de validade dos negócios",
  "ikbs.knowledge.properties.operation.edit" : "Editar",
  "ikbs.knowledge.pass.mark" : "Marcar",
  "ikbs.column.modify.modifyServiceTimeCheckError" : "O início do período de validade comercial não pode ser anterior ao seu fim.",
  "ikbs.column.modify.properties.batch.message.warning" : "Informações",
  "ikbs.cntTmp.import.error.oversize" : "O tamanho do arquivo não pode exceder 20 MB.",
  "ikbs.knowledgeaudit.label.cancel" : "Cancelar",
  "ikbs.search.label.keyword" : "Palavra-chave",
  "ikbs.knghisverman.tab.message.deleteSuccess" : "Exclusão bem-sucedida.",
  "ikbs.search.label.sortByClick" : "Cliques",
  "ikbs.knowledge.tip.fileInjectionError" : "Falha ao verificar o conteúdo do arquivo.",
  "ikbs.knowledge.label.correlative" : "Associar",
  "ikbs.column.needAudit.no" : "Não",
  "ikbs.column.unlock.message.success" : "Desbloqueio bem-sucedido.",
  "ikbs.contentTmp.ModifyStartTime" : "Início modificado",
  "ikbs.konwledgetype.question" : "Conhecimento de perguntas e respostas",
  "ikbs.knowledge.service.timeout" : "Expirado",
  "ikbs.column.topMove.message.success" : "Fixado com sucesso.",
  "ikbs.contentTmp.Modifier" : "Modificado por",
  "ikbs.knowledgeaudit.label.error" : "Erro",
  "ikbs.knowledge.exception.AnswerNum.error" : "Uma pergunta não pode ter mais de 10 respostas.",
  "ikbs.knowledgeaudit.title.submitTime" : "Enviado em",
  "ikbs.column.label.colukngpath" : "Caminho da categoria",
  "ikbs.knowledge.label.saveAndNext" : "Salvar e NextStep",
  "ikbs.constableTmp.save" : "salvar",
  "ikbs.knghisverman.label.operation" : "Operação",
  "ikbs.column.label.summaryInfo" : "Resumo",
  "ikbs.knowledge.recycle.button.reset" : "Redefinir",
  "ikbs.knowledge.recycle.button.delete" : "Excluir",
  "ikbs.constableTmp.status.invalid" : "Inválido",
  "ikbs.cntTmp.import.contTmpNameEditStandEmpty" : "O conteúdo do modelo e as especificações de edição não podem ficar vazios ao mesmo tempo.",
  "ikbs.column.knowledge.button.copy" : "Copiar",
  "ikbs.knowledge.properties.properties.inputWay" : "Tipo de entrada",
  "ikbs.column.knowledge.compareModified" : "Diferente",
  "ikbs.constableTmp.innerFieldFlag" : "Campo incorporado",
  "ikbs.column.passProcessWay.move" : "Mover para Histórico",
  "ikbs.knowledge.msg.fileUpload" : "Carregar arquivo para conhecimento",
  "ikbs.column.unlock.message.failed" : "Falha no desbloqueio.",
  "ikbs.cntTmp.import.editStandardOversize" : "As especificações de edição não podem exceder 400 caracteres.",
  "ikbs.contentTmp.save" : "Salvar",
  "ikbs.knowledge.check.kngName" : "O nome de conhecimento ou o nome da coluna não pode conter os seguintes characters: /\\:*?<>|",
  "ikbs.contentTmp.tab.copyTitle" : "Duplicação",
  "ikbs.column.downMove.message.fail" : "Falha ao mover para baixo.",
  "ikbs.column.knowledge.compareDel" : "Indisponível",
  "ikbs.knowledge.service.hot" : "Quente",
  "ikbs.contentTmp.modify.fail" : "Falha de modificação",
  "ikbs.knowledge.label.servicesStartTime" : "Início do período de validade dos negócios",
  "ikbs.constableTmp.modifyTime" : "Modificado em",
  "ikbs.knowledge.index.no" : "Não",
  "ikbs.kbshome.label.ClickList" : "A maioria dos cliques",
  "ikbs.knowledge.properties.properties.status" : "Status",
  "ikbs.knowledge.properties.dictValue" : "Valor do dicionário",
  "ikbs.column.delete.message.warning" : "Informações",
  "ikbs.column.delete.message.fail" : "Falha na exclusão.",
  "ikbs.constableTmp.cnstTblName" : "Tabela de Consultas",
  "ikbs.cntTmp.import.error.importType" : "Selecione um modo de importação.",
  "ikbs.contTmp.import.popup.title" : "Importar Modelos",
  "ikbs.column.title.selectUser" : "Selecionar usuário",
  "ikbs.column.recycle.noSelectRecycles" : "Selecione um registro.",
  "ikbs.knowledge.label.servicesEndTime" : "Término do período de validade dos negócios",
  "ikbs.knghisverman.label.modifier" : "Modificado por",
  "ikbs.cntTmp.import.result" : "A importação de modelos de conteúdo foi concluída. Número total {0}, número de sucesso {1}, número de falhas {2}.",
  "ikbs.cntTmp.export.crosspage.info" : "A exportação entre páginas não é suportada. Somente os dados selecionados na página atual podem ser exportados.",
  "ikbs.column.knowledge.expired" : "Expirado",
  "ikbs.knowledgeaudit.title.auditTime" : "Revisado em",
  "ikbs.knowledge.tip.uploadFailed" : "Falha ao carregar.",
  "ikbs.column.knowledge.CurVersion" : "Versão de origem",
  "ikbs.content.upload.file.Frequent" : "Muitas solicitações de API, por favor, tente novamente mais tarde.",
  "ikbs.knowledge.check.keyword" : "As palavras-chave não podem conter caracteres especiais.",
  "ikbs.knowledge.tip.fileTypeError" : "Falha ao verificar o tipo de arquivo.",
  "ikbs.column.upMove.message.warning" : "Informações",
  "ikbs.knowledgeaudit.label.taskEndTime" : "Hora do fim da tarefa",
  "ikbs.constableTmp.modifyBeginTime" : "Hora de início da modificação",
  "ikbs.knowledge.lable.copy" : "Duplicado",
  "ikbs.knowledgeaudit.label.batchAuditPass" : "Aprovação do lote",
  "ikbs.contentTmp.delete" : "Eliminar",
  "ikbs.knowledge.tab.title" : "Compilação de conhecimentos",
  "ikbs.knowledge.recycle.label.knowledgeType" : "Tipo",
  "ikbs.column.knowledge.Type" : "Tipo",
  "ikbs.kbshome.button.thirtyDayList" : "30 dias",
  "ikbs.cntTmp.import.queryContTempErr" : "Falha ao consultar o modelo de conteúdo.",
  "ikbs.favorite.table.delete" : "Excluir",
  "ikbs.column.move.message.warning" : "Informações",
  "ikbs.knowledge.title.propTitle" : "Nome",
  "ikbs.constableTmp.modify.selectedOne" : "Apenas um modelo pode ser selecionado",
  "ikbs.knowledge.label.uploadAttachment" : "Carregar anexo",
  "ikbs.knowledge.pass.move" : "Mover para Histórico",
  "ikbs.favorite.title.warning" : "Informações",
  "ikbs.kbshome.label.favorite" : "Atalhos",
  "ikbs.knowledge.label.deleteProperty" : "Excluir",
  "ikbs.column.knowledge.button.publish" : "Publicar",
  "ikbs.column.resumeRecycle.comfirm" : "Tem certeza de que deseja restaurar?",
  "ikbs.constableTmp.status.valid" : "Descartado",
  "ikbs.knowledgeaudit.label.reject" : "Rejeitar",
  "ikbs.contentTmp.export" : "Exportação",
  "ikbs.properties.operation.message.fail" : "Falha",
  "ikbs.knowledge.label.validEndTime" : "Fim do Período de Validade",
  "ikbs.knowledge.label.preStep" : "Voltar",
  "ikbs.properties.add.save" : "OK",
  "ikbs.knowledgeaudit.label.query" : "Consulta",
  "ikbs.knowledge.tip.keywordCountError" : "São suportadas no máximo {count} palavras-chave",
  "ikbs.favorite.table.edit" : "Editar",
  "ikbs.knowledge.label.addKngPoint" : "Adicionar pergunta",
  "ikbs.knowledge.label.creatorName" : "Preparado por",
  "ikbs.knowledge.tip.operateFailed" : "Falha na operação.",
  "ikbs.knghisverman.tab.message.isDeleteHisVer" : "Tem certeza de que deseja excluir esta versão do histórico?",
  "ikbs.favorite.title.addDirectory" : "Adicionar diretório",
  "ikbs.knowledge.label.type" : "Tipo",
  "ikbs.column.Move.message.success" : "Movimento bem-sucedido.",
  "ikbs.column.knowledge.update" : "Atualizado",
  "ikbs.search.label.sortByTime" : "Horário",
  "ikbs.knowledge.label.summaryInfo" : "Resumo",
  "ikbs.properties.detail.information" : "Detalhes do atributo estendido",
  "ikbs.column.release.message.success" : "Publicação bem-sucedida.",
  "ikbs.knowledge.label.ok" : "OK",
  "ikbs.knowledge.label.upload" : "Carregar",
  "ikbs.topMove.selectTopMoveColumnKngNumError" : "Nenhum conhecimento de categoria está selecionado.",
  "ikbs.constableTmp.display.yes" : "sim",
  "ikbs.column.modify.properties.batch.message.error" : "Nenhum conhecimento de categoria está selecionado.",
  "ikbs.cntTmp.import.failReason" : "Falha ao carregar o registro {0}; causa da falha: {1}",
  "ikbs.knowledge.properties.properties.title" : "Nome",
  "ikbs.knowledgeaudit.label.auditor" : "Revisado por",
  "ikbs.cntTmp.export.exportNum.blank" : "Selecione os modelos de conteúdo a serem exportados.",
  "ikbs.contentTmp.status.valid" : "Válido",
  "ikbs.column.modify.selectModifyColumnKngNumZERO" : "Nenhum conhecimento de categoria está selecionado.",
  "ikbs.column.tip.success" : "Informações",
  "ikbs.knowledge.tip.relaKngCountError" : "São suportados no máximo {count} itens de conhecimento associados.",
  "ikbs.contentTemp.export.tempcontent" : "Conteúdo do modelo",
  "ikbs.contentTmp.query" : "Consulta",
  "ikbs.properties.status.draft" : "Rascunho",
  "ikbs.column.knowledge.ModifyTime" : "Modificado em",
  "ikbs.knowledgeaudit.title.status" : "Status",
  "ikbs.column.knowledge.compare" : "Comparar",
  "ikbs.knowledge.label.uploadFileSizeError" : "O anexo não pode exceder 10 MB.",
  "ikbs.cntTmp.import.invalidData" : "Dados inválidos.",
  "ikbs.column.delete.message.success" : "Exclusão bem-sucedida.",
  "ikbs.favorite.tree.favoriteRootName" : "Favoritos",
  "ikbs.column.moveTo.selectColumnKngNumError" : "Nenhum conhecimento de categoria está selecionado.",
  "ikbs.cntTmp.export.error.syserr" : "Falha ao exportar o arquivo de modelo de conteúdo.",
  "ikbs.column.button.confirm" : "Enviar",
  "ikbs.constableTmp.delete.noSelectTmp" : "Nenhum modelo selecionado",
  "ikbs.content.transfer.html.success" : "Conteúdo convertido para HTML com sucesso",
  "ikbs.column.modify.modifyValidServiceEndTimeCheckError" : "O fim do período de validade comercial não pode ser posterior ao fim do período de validade.",
  "ikbs.knowledge.properties.label.propertiesTitle" : "Nome",
  "ikbs.knowledge.label.addAnswer" : "Adicionar resposta",
  "ikbs.column.delete.message.comfirm" : "Confirmar",
  "ikbs.column.button.search" : "Consulta",
  "ikbs.knghisverman.label.modifyTime" : "Modificado em",
  "ikbs.select.record.to.delete" : "Selecione o registro a ser excluído",
  "ikbs.knowledgeaudit.label.submitterOrAuditor" : "Enviado por / revisado por",
  "ikbs.constableManage.fieldValue" : "Valor",
  "ikbs.column.history.down.empty" : "Não é possível abrir esta coluna porque não existe nenhuma coluna ou conhecimento",
  "ikbs.constableTmp.name" : "Nome do modelo",
  "ikbs.knowledgeaudit.label.pleaseSelect" : "- Selecionar",
  "ikbs.cntTmp.import.syserr" : "Falha ao importar o modelo de conteúdo.",
  "ikbs.column.copy.selectCopyColumnKngNumError" : "Apenas um item de conhecimento pode ser selecionado para cópia.",
  "ikbs.contentTmp.status.invalid" : "Inválido",
  "ikbs.column.message.ids.max.size" : "O número de itens para a operação em lote não pode exceder 100.",
  "ikbs.knowledge.properties.label.query" : "Consulta",
  "ikbs.kngCnt.cntTmp.importType.new" : "Criar diretamente",
  "ikbs.column.upMove.message.success" : "Movido para cima com sucesso.",
  "ikbs.content.transfer.html.fail" : "Falha ao converter conteúdo em HTML",
  "ikbs.knghisverman.title.kngHisVerMan" : "Gerenciar histórico de conhecimento",
  "ikbs.favorite.field.directoryName" : "Nome do diretório",
  "ikbs.column.Move.message.fail" : "Falha na movimentação.",
  "ikbs.column.deletePermanently.message.fail" : "Falha na exclusão permanente.",
  "ikbs.columntype.knowledge" : "Conhecimento",
  "ikbs.knowledge.type.common" : "Conhecimento comum",
  "ikbs.knowledgeaudit.label.ok" : "OK",
  "ikbs.contentTmp.exception.contTmpName" : "Entrada ilegal",
  "ikbs.kngCnt.cntTmp.use.cntReplaceConfirm" : "Tem certeza de que deseja substituir o conteúdo de conhecimento atual?",
  "ikbs.search.button.search" : "Pesquisar",
  "ikbs.constableTmp.delete.success" : "Sucesso de exclusão",
  "ikbs.knowledge.recycle.coluKngName" : "Nome",
  "ikbs.constableTmp.add.success" : "Sucesso de Adição",
  "ikbs.kbshome.button.orderByName" : "Por nome",
  "ikbs.knowledge.tip.uploadSuccess" : "Carregar bem-sucedido.",
  "ikbs.constableManage.incorrect" : "Condição incorreta",
  "ikbs.column.modify.properties.batch.message.success" : "Os atributos são modificados em lotes com sucesso.",
  "ikbs.properties.inputway.text" : "Entrada de texto",
  "ikbs.column.move.selectMoveColumnKngNumError" : "Nenhum conhecimento de categoria está selecionado.",
  "ikbs.knowledgeaudit.title.auditRemark" : "Observações",
  "ikbs.knowledge.label.indexNeedUpdate" : "Atualizar índice de pesquisa",
  "ikbs.knowledge.recycle.status" : "Status",
  "ikbs.kbshome.button.orderByClicks" : "Cliques",
  "ikbs.favorite.table.name" : "Nome",
  "ikbs.column.knowledge.RelVersion" : "Versão alvo",
  "ikbs.kbshome.button.dayList" : "1 dia",
  "ikbs.column.modify.modifyValidTimeServiceTimeSame" : "Altere o início e o fim do período de validade e os do período de validade dos negócios.",
  "ikbs.column.label.servicesVaildEndTime" : "Término do período de validade dos negócios",
  "ikbs.knowledgeaudit.label.submitAudit" : "Pendente",
  "ikbs.column.release.selectreleaseColumnKngTypeError" : "Apenas o conhecimento pode ser publicado.",
  "ikbs.knowledge.type.points" : "Conhecimento de perguntas e respostas",
  "ikbs.column.knowledge.modifyStartTime" : "Início modificado",
  "ikbs.favorite.title.preModifiedKngName" : "Antes da mudança",
  "ikbs.column.deleteRecycle.comfirm" : "Tem certeza de que deseja excluir?",
  "ikbs.recycle.title" : "Lixeira",
  "ikbs.column.tree.rootName" : "Conhecimento da categoria",
  "ikbs.column.downMove.message.warning" : "Informações",
  "ikbs.knghisverman.tab.title.kngBaseInfo" : "Informações básicas",
  "ikbs.addtype.label.column" : "Adicionar categoria",
  "ikbs.knowledge.msg.updateKngPath" : "Estatizar detalhes de conhecimento",
  "ikbs.kbshome.label.recentViews" : "Minha História",
  "ikbs.column.knowledge.publish" : "Publicado",
  "ikbs.contentTemp.export.tempdesc" : "Descrição do modelo",
  "ikbs.column..topMove.message.fail" : "Falha ao fixar para cima.",
  "ikbs.knowledge.recycle.button.clearAll" : "Esvaziar lixeira",
  "ikbs.constableTmp.status" : "Status do modelo",
  "ikbs.contentTmp.contentDesc" : "Modelo desc",
  "ikbs.knghisverman.label.version" : "Versão",
  "ikbs.column.knowledge.button.unLock" : "Desbloquear",
  "ikbs.knowledge.error.property" : "Selecione o valor do atributo [{}].",
  "ikbs.knowledgeaudit.label.mySubmitAudit" : "Minha aplicação",
  "ikbs.column.label.kngReleaseWay" : "Revisar e publicar conhecimento na categoria",
  "ikbs.contentTmp.add.fail" : "Falha de adição",
  "ikbs.constableTmp.moveUp" : "Para cima",
  "ikbs.constableManage.condition.limit" : "O número de condições não pode exceder 10",
  "ikbs.column.downMove.selectDowmMoveColumnKngNumError" : "Nenhum conhecimento de categoria está selecionado.",
  "ikbs.column.modify.modifyzeropropertieserror" : "Nenhum atributo foi modificado.",
  "ikbs.column.label.isInheritProp" : "Herdar atributo de categoria pai",
  "ikbs.column.knowledge.reject" : "Rejeitado",
  "ikbs.column.deletePermanently.columnkng" : "Nenhum conhecimento de categoria está selecionado.",
  "ikbs.constableManage.modifyConstable" : "Modificar Dados da Tabela de Consulta",
  "ikbs.contTmp.importTmp.title" : "Baixar modelo de importação",
  "ikbs.constableTmp.fieldViewName" : "Nome de exibição",
  "ikbs.cntTmp.import.contTmpDesc" : "Descrição incorreta do modelo.",
  "ikbs.constableManage.addConstable" : "Adicionar dados da tabela de consulta",
  "ikbs.contentTmp.operate" : "Operar",
  "ikbs.column.upMove.message.fail" : "Falha ao mover-se para cima.",
  "ikbs.constableTmp.query" : "Consulta",
  "ikbs.column.recycle.message.comfirm" : "Confirmar",
  "ikbs.kbshome.button.advancedSearch" : "Pesquisa avançada",
  "ikbs.constableTmp.modify.success" : "Sucesso da modificação",
  "ikbs.kbshome.tip.refresh" : "Atualizar",
  "ikbs.constableTmp.delete.fail" : "Falha de Deleção",
  "ikbs.properties.operation.message.success" : "Sucesso",
  "ikbs.column.knowledge.clear" : "Limpar",
  "ikbs.knowledge.label.auditor" : "Revisado por",
  "ikbs.column.tip.error" : "Erro",
  "ikbs.column.deletePermanently.columnkng.comfirm" : "Tem certeza de que deseja excluir o conhecimento da categoria permanentemente?",
  "ikbs.favorite.tab.message.isDeleteFavoriteDir" : "Tem certeza de que deseja excluir o diretório de favoritos e o conhecimento no diretório?",
  "ikbs.column.tips.modifyColumnOperation.error" : "Falha ao modificar o conhecimento da categoria.",
  "ikbs.column.button.reset" : "Redefinir",
  "ikbs.knowledgeaudit.message.auditSuccess" : "O aplicativo foi aprovado com sucesso.",
  "ikbs.column.knowledge.Modifier" : "Modificado por",
  "ikbs.cntTmp.import.error.contTmpNameExist" : "O nome do modelo existe.",
  "ikbs.cntTmp.export.info.success" : "Sucesso na Exportação.",
  "ikbs.knowledge.label.kngAttachmentUpload" : "Carregar anexo da categoria de conhecimento",
  "ikbs.knowledge.tip.propCountLimited" : "Um máximo de 20 atributos podem ser associados.",
  "ikbs.column.knowledge.button.deletePermanently" : "Excluir permanentemente",
  "ikbs.knowledge.recycle.label.operateTimeEnd" : "Hora de término da exclusão",
  "ikbs.knowledge.label.display" : "Exibir na página frontal",
  "ikbs.cntTmp.export.result" : "Exportação de modelos de conteúdo concluída. Número total {0}, número de sucesso {1}, número de falhas {2}.",
  "ikbs.knowledge.label.deleteAnswer" : "Excluir resposta",
  "ikbs.knowledge.properties.operation.detail" : "Detalhes",
  "ikbs.column.knowledge.modifyEndTime" : "Final modificado",
  "ikbs.knowledgeaudit.title.name" : "Nome",
  "ikbs.knowledge.label.modifierName" : "Última atualização por",
  "ikbs.column.knowledge.button.moveTo" : "Ajustar para",
  "ikbs.knowledge.title.knowledgeContent" : "Conteúdo",
  "ikbs.knowledge.tip.fileSizeError" : "Falha ao verificar o tamanho do arquivo.",
  "ikbs.constableTmp.delete" : "Eliminar",
  "ikbs.tips.copy.onlyKngCanCopy" : "Apenas o conhecimento pode ser copiado.",
  "ikbs.column.passProcessWay.delete" : "Excluir",
  "ikbs.column.label.serviceStatus" : "Status comercial",
  "ikbs.column.button.cancel" : "Fechar",
  "ikbs.knowledge.label.clickNum" : "Vistas",
  "ikbs.Column.MoveTo.message.success" : "Operação bem-sucedida.",
  "ikbs.knowledge.index.yes" : "Sim",
  "ikbs.knowledge.tip.fileIOError" : "Falha ao carregar o arquivo.",
  "ikbs.column.modify.properties.message.warning" : "Informações",
  "ikbs.constableTmp.operate" : "Operação",
  "ikbs.knowledgeaudit.title.path" : "Caminho",
  "ikbs.column.serviceStatus.expired" : "Expirado",
  "ikbs.column.knowledge.audit" : "Revisado",
  "ikbs.contTmp.import.tip" : "1. Somente um arquivo .xlsx pode ser carregado. 2. O tamanho máximo do arquivo é de 20 MB. 3. Um máximo de 10 registros podem ser importados.",
  "ikbs.knowledge.label.save" : "Salvar",
  "ikbs.knowledge.title.knowledgeExtended" : "Informações estendidas",
  "ikbs.column.knowledge.button.modifyPropBatch" : "Modificar atributo",
  "ikbs.column.type.history" : "Histórico",
  "ikbs.cntTmp.import.contTmpNameError" : "Nome de modelo incorreto.",
  "ikbs.knowledge.tip.error" : "Error",
  "ikbs.contentTmp.delete.success" : "Sucesso de exclusão",
  "ikbs.knowledgeaudit.label.success" : "Sucesso",
  "ikbs.contentTmp.editStandard" : "Editando especificações",
  "ikbs.favorite.title.editFavoriteKngName" : "Alterar nome",
  "ikbs.column.knowledge.button.down" : "Para baixo",
  "ikbs.column.modify.message.error" : "Falha na modificação.",
  "ikbs.column.knowledge.compareKng" : "Comparar conhecimento",
  "ikbs.knowledge.check.inputText" : "Caracteres especiais não são permitidos.",
  "ikbs.column.knowledge.button.delete" : "Excluir",
  "ikbs.knowledge.service.new" : "Novos negócios",
  "ikbs.knowledge.properties.operation.disable" : "Desativar",
  "ikbs.column.serviceStatus.hot" : "Quente",
  "ikbs.contentTmp.add.success" : "Sucesso de Adição",
  "ikbs.favorite.msg.modifyNodeNameSuccess" : "Operação bem-sucedida.",
  "ikbs.column.deletePermanently.message.warning" : "Informações",
  "ikbs.contentTmp.modify" : "Modificar",
  "ikbs.kbshome.button.sevenDayList" : "7 dias",
  "ikbs.column.knowledge.button.turnToHistory" : "Mover para Histórico",
  "ikbs.constableTmp.cancle" : "cancelar",
  "ikbs.knowledge.recycle.button.resume" : "Restaurar",
  "ikbs.knowledgeaudit.label.auditing" : "Em análise",
  "ikbs.knowledge.release.audit" : "Revisar e publicar",
  "ikbs.constableTmp.yes" : "Sim",
  "ikbs.column.label.displayFlag" : "Exibir na página frontal",
  "ikbs.column.moveto.back" : "Próximo a",
  "ikbs.column.modify.message.warning" : "Informações",
  "ikbs.knowledge.label.nextStep" : "Próximo passo",
  "ikbs.column.moveTo.message.warning" : "Informações",
  "ikbs.column.isInheritProp.yes" : "Sim",
  "ikbs.knowledge.title.knowledgeBase" : "Informações básicas",
  "ikbs.knowledge.label.keyword" : "Palavras-chave",
  "ikbs.knowledgeaudit.label.reset" : "Redefinir",
  "ikbs.knowledge.message.uploadImgTypeError" : "O formato da imagem está incorreto.",
  "ikbs.knowledge.label.moveUp" : "Mover para cima",
  "ikbs.properties.inputway.checkbox" : "Caixa de seleção",
  "ikbs.knowledgeaudit.label.auditReject" : "Rejeitado",
  "ikbs.contentTmp.ModifyTime" : "Modificado em",
  "ikbs.knowledge.label.checkboxProp" : "Caixa de seleção",
  "ikbs.knowledge.label.kngMsg" : "Informações de conhecimento",
  "ikbs.column.knowledge.compareResult" : "Resultados da comparação",
  "ikbs.cntTmp.import.invalidFile" : "Arquivo inválido.",
  "ikbs.kbshome.button.orderByOrderId" : "Por Nº.",
  "ikbs.column.knowledge.modifyColumnInformation" : "Modificar categoria",
  "ikbs.knowledgeaudit.placeholder.pleaseSelect" : "- Selecionar",
  "ikbs.constableTmp.tab.modifyTitle" : "Modificando modelo",
  "ikbs.knowledge.label.modifyRemark" : "Observações",
  "ikbs.column.release.selectreleaseColumnKngNumError" : "Nenhum conhecimento selecionado.",
  "ikbs.column.knowledge.button.order" : "Classificar",
  "ikbs.contentTmp.import" : "Importação",
  "ikbs.column.label.validEndTime" : "Fim do Período de Validade",
  "ikbs.properties.status.enable" : "Ativar",
  "ikbs.column.modify.modifyValidServiceStartTimeCheckError" : "O início do período de validade comercial não pode ser anterior ao início do período de validade.",
  "ikbs.knowledge.error.contentEmpty" : "O conteúdo do conhecimento não pode estar vazio.",
  "ikbs.column.upMove.selectUpMoveColumnKngNumError" : "Nenhum conhecimento de categoria está selecionado.",
  "ikbs.constableTmp.add" : "Adicionar",
  "ikbs.constableTmp.operator.exclude" : "excluir",
  "ikbs.column.knowledge.LockedFlat" : "Bloqueado por",
  "ikbs.knowledge.label.cancelCollection" : "Desfazer Favoritos",
  "ikbs.contentTmp.reset" : "Redefinir",
  "ikbs.knowledge.label.textProp" : "Entrada de texto",
  "ikbs.knowledge.title.propType" : "Tipo de entrada",
  "ikbs.cntTmp.export.exportNum.oversize" : "Um máximo de 10 modelos de conteúdo podem ser exportados.",
  "ikbs.properties.status.disable" : "Desativar",
  "ikbs.knowledgeaudit.label.knowledgeName" : "Nome do conhecimento",
  "ikbs.constableManage.fieldName" : "Nome da coluna",
  "ikbs.contentTmp.close" : "Fechar",
  "ikbs.knowledge.properties.label.add" : "Novo",
  "ikbs.knowledgeaudit.label.auditPass" : "Aprovado",
  "ikbs.common.search" : "Pesquisa normal",
  "ikbs.contentTmp.modify.success" : "Sucesso da modificação",
  "ikbs.column.downMove.message.success" : "Movido para baixo com sucesso.",
  "ikbs.knowledge.label.draft" : "Rascunho",
  "ikbs.knowledge.label.creationTime" : "Criado em",
  "ikbs.column.modify.properties.batch.message.fail" : "Falha ao modificar os atributos em lotes.",
  "ikbs.column.message.plsSelectModifyStartTime" : "Selecione uma hora de início.",
  "ikbs.knowledge.label.problem" : "Pergunta",
  "ikbs.constableTmp.displayFlag" : "Exibido ou não",
  "ikbs.Column.MoveTo.message.fail" : "Falha na operação.",
  "ikbs.column.knowledge.columnKnowledgeName" : "Nome do conhecimento da categoria",
  "ikbs.column.knowledge.button.top" : "Pino",
  "ikbs.tips.addColumnOperation.error" : "Falha ao adicionar a categoria.",
  "ikbs.search.label.sortByName" : "Por nome",
  "ikbs.column.delete.selectdeletecolumn.comfirm" : "Tem certeza de que deseja excluir a categoria e todas as subcategorias e conhecimento?",
  "ikbs.constableTmp.modifierName" : "Modificado pelo",
  "ikbs.knowledge.label.attachment" : "Anexo",
  "ikbs.column.unlock.selectUnlockColumnKngNumError" : "Nenhum conhecimento de categoria está selecionado.",
  "ikbs.contentTmp.tmpContent" : "Conteúdo do modelo",
  "ikbs.column.label.coluKngBrowsing" : "grupo de procura",
  "ikbs.kbshome.button.totalList" : "Todo o tempo",
  "ikbs.search.label.resultDes" : "Sem dados",
  "ikbs.knowledge.recycle.button.query" : "Consulta",
  "ikbs.knowledge.properties.label.reset" : "Redefinir",
  "ikbs.constableTmp.warning" : "Informação",
  "ikbs.knowledge.label.kngReleaseWay" : "Modo de publicação",
  "ikbs.properties.inputway.radio" : "Caixa de rádio",
  "ikbs.knowledge.recycle.operationTime" : "Excluído em",
  "ikbs.constableTmp.plsSelectModifyEndTime" : "Definir a hora de término da modificação",
  "ikbs.column.knowledge.button.turnToNormal" : "Mover para Normal",
  "ikbs.knowledge.label.validStartTime" : "Início do período de validade",
  "ikbs.knowledge.pass.delete" : "Excluir",
  "ikbs.column.displayflag.show" : "Sim",
  "ikbs.knowledge.recycle.select.knowledgeStatus" : "Estado do conhecimento",
  "ikbs.column.deletePermanently.message.success" : "Exclusão permanente bem-sucedida.",
  "ikbs.time.lessThan.currentTime" : "O fim não pode ser anterior à hora atual.",
  "ikbs.knowledge.recycle.label.operateTimeStart" : "Hora de início da exclusão",
  "ikbs.knowledge.recycle.label.knowledgeName" : "Nome do conhecimento da categoria",
  "ikbs.knghisverman.label.delete" : "Excluir",
  "ikbs.column.knowledge.Name" : "Nome",
  "ikbs.knowledgeaudit.placeholder.pleaseInput" : "Insira um nome.",
  "ikbs.column.label.validStartTime" : "Início do período de validade",
  "ikbs.knowledge.display.yes" : "Sim",
  "ikbs.knowledge.label.servicesTime" : "Período de validade",
  "ikbs.search.label.updateStartTime" : "Atualizar Hora Inicial",
  "ikbs.favorite.msg.addDirectorySuccess" : "O diretório foi adicionado com sucesso.",
  "ikbs.search.label.result" : "Resultados da pesquisa",
  "ikbs.constableTmp.modify.fail" : "Falha de modificação",
  "ikbs.constableTmp.add.fail" : "Falha de adição",
  "ikbs.column.knowledge.modifierId" : "Modificado por",
  "ikbs.tips.propertyDictValueNumExceedMaxValue.warning" : "O número de valores de dicionário de atributos excede o máximo.",
  "ikbs.constableTmp.no" : "Não",
  "ikbs.knowledge.label.close" : "Fechar",
  "ikbs.favorite.table.operate" : "Operação",
  "ikbs.kngCnt.cntTmp.editStandard.title" : "Editando especificações",
  "ikbs.constableTmp.anonymizationFlag" : "Anonimizado ou não",
  "ikbs.knowledge.tip.fileCountError" : "São suportados no máximo {count} anexos.",
  "ikbs.column.copy.selectCopyColumnKngNumZERO" : "Nenhum conhecimento foi selecionado para cópia.",
  "ikbs.constableTmp.delete.byCondition" : "Apagar por condição",
  "ikbs.column.delete.selectdeletecolumnkng.comfirm" : "Tem certeza de que deseja excluir as categorias ou o conhecimento?",
  "ikbs.knowledge.label.name" : "Nome do conhecimento",
  "ikbs.knowledge.check.textArea" : "Os seguintes caracteres especiais não são permitidos: <>/",
  "ikbs.knowledge.label.addToCollection" : "Adicionar aos Favoritos",
  "ikbs.knowledge.recycle.operator" : "Operador",
  "ikbs.konwledgetype.knowledge" : "Conhecimento comum",
  "ikbs.knowledgeaudit.label.taskBeginTime" : "Hora de início da tarefa",
  "ikbs.kngCnt.cntTmp.importType.sameNameCover" : "Sobrescrever modelos com os mesmos nomes",
  "ikbs.column.knowledge.Path" : "Caminho",
  "ikbs.kbshome.label.plsInputKeyWord" : "Insira uma palavra-chave.",
  "ikbs.knowledge.label.addProperty" : "Adicionar",
  "ikbs.knowledgeaudit.title.submitter" : "Enviado por",
  "ikbs.knowledge.properties.properties.operation" : "Operação",
  "ikbs.knowledge.label.moveDown" : "Para baixo"
}