/**
 * * 不要直接引入，从 index 引入 ccmeConfig.name.channel
 */
import i18n from '@/lang'

const $t = i18n.global.t

const CHANNEL_MAP = {
  WEB: $t('ccmessaging.config.names.channels.web'),
  INSTAGRAM: $t('ccmessaging.config.names.channels.instagram'),
  TELEGRAM: $t('ccmessaging.config.names.channels.telegram'),
  WHATSAPP: $t('ccmessaging.config.names.channels.whatsapp'),
  LINE: $t('ccmessaging.config.names.channels.line'),
  WECHAT: $t('ccmessaging.config.names.channels.wechat'),
  FACEBOOK: $t('ccmessaging.config.names.channels.facebook'),
  TWITTER: $t('ccmessaging.config.names.channels.twitter'),
  '5G': $t('ccmessaging.config.names.channels.rcs'),
  EMAIL: $t('ccmessaging.config.names.channels.email'),
  SMS: $t('ccmessaging.config.names.channels.sms')
}

export default { MAP: CHANNEL_MAP }
