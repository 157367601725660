<template>
  <sweet-dialog v-model="dialogVisible"
                :title="$t('oifde.title.chooseGreyRule')"
                class="aicc-dialog-limit-height"
                width="616px" align-center :close-on-click-modal="false">
    <aicc-advanced-search ref='advancedSearch'
                          :templateMetaData="templateMetaData"
                          @handleSearch='ruleNameChange'
                          @handleRefresh="handleRefresh"
                          @handleCommand="handleCommand"
                          @ruleNameChange="ruleNameChange"></aicc-advanced-search>

    <aicc-table :tableData="tableData"
                :tableColumns="tableColumns"
                :tableTools="{ showPagination: true, columnConfig: false }"
                :height="430"
                :paginationConfig="paginationConfig"
                @handlePagination="handlePagination"
                :row-key="getKey"
                ref='aiccTable'>
    </aicc-table>
    <template #footer>
      <span class="dialog-footer">
        <sweet-button @click="dialogVisible=false">{{ $t('oifde.table.cancel') }}</sweet-button>
        <sweet-button type="primary" @click="handleConfirm">{{ $t('oifde.table.confirm') }}</sweet-button>
      </span>
    </template>
  </sweet-dialog>
</template>

<script>
import {queryRuleInfo} from '@oifde/request/api/rule'

export default {
  name: 'DialogChooseRule',
  props: {
    show: {
      type: Boolean,
      default: true
    },
    isRadio: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      templateMetaData: {
        basicConfig: { // 基础搜索配置
          basicSearch: [
            {
              searchType: 'primary', // primary: 有搜索图标的输入框；select: 下拉框; input: 搜索框；date: 日期选择器
              modelValue: 'ruleName', // v-model绑定的key值
              modelDefault: '', // v-model绑定的默认值
              placeholderValue: this.$t('oifde.rule.name'), // placeholder绑定的值 国际化之后的值
              colConfig: { lg: 12, xl: 12 },
              changeEvent: 'ruleNameChange'
            }
          ]
        }
      },
      tableData: [],
      paginationConfig: {
        pageNum: 1,
        pageSize: 10, // 每页条数
        total: 0, //总条数
        layout: "sizes, total, prev, pager, next" // 分页组件布局配置
      }
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      }
    },
    tableColumns()  {
      let basicColumn = [
        {
          label: this.$t('oifde.rule.name'), // 表头展示列名（传入国际化后的内容）
          prop: 'ruleName', // 对应表数据对象数组中的键名
        },
        {
          label: this.$t('oifde.rule.beginNum'),
          prop: 'beginNum',
        },
        {
          label: this.$t('oifde.rule.endNum'),
          prop: 'endNum',
        },
      ]
      if (this.isRadio) {
        return [
          {
            type: 'radio',
            radioKey: 'ruleId',
            width: '50'
          },
          ...basicColumn
        ]
      } else {
        return  [
          {
            type: 'selection',
            'reserve-selection': true,
            width: '50px'
          },
          ...basicColumn
        ]
      }
    },
  },
  mounted() {
    this.handleSearch()
  },
  methods: {
    handleRefresh() { // 刷新时重置回第一页，同时会触发handlePagination
      this.handleInitChange();
    },
    handleCommand(data) { // 触发事件处理，调用对应方法，方法和eventName同名
      const {eventName, params} = data
      this[eventName](params)
    },
    handleInitChange() {
      this.$refs.aiccTable.handleInitChange();
    },
    handlePagination(params) {
      Object.assign(this.paginationConfig, params);
      this.handleSearch();
    },
    getKey(row) {
      return row.ruleId
    },
    ruleNameChange() {
      this.paginationConfig.pageNum = 1;
      this.$refs.aiccTable.setCurrentPage(1);
      this.handleSearch();
    },
    async handleSearch() {
      const { pageNum, pageSize } = this.paginationConfig
      await this.$nextTick()
      let params = this.$refs.advancedSearch.getFormatParams()
      let ruleName = params.ruleName || ''
      let queryParam = {
        pageNum,
        pageSize,
        ruleName
      }
      let response = await queryRuleInfo(queryParam)
      this.tableData = response.ruleInfoList
      this.paginationConfig.total = response.total
    },
    handleConfirm() {
      const selectedRows = this.isRadio ? this.$refs.aiccTable.getRadioCurrentRow() : this.$refs.aiccTable.table.getSelectionRows()
      this.$emit('onConfirm', selectedRows)
      this.dialogVisible = false
    },
  }
}
</script>

<style scoped>

</style>