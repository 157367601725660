<template>
  <aicc-full-screen :existConfig="existConfig">
    <template #main>
      <div class="employee-basic-main">
        <employee-info
          ref="baseInfoRef"
          v-if="mode && mode !== 'config' && mode !== 'viewConfig'"
          :mode="mode"
        />
        <employee-config
          v-if="mode === 'config' || mode === 'viewConfig'"
          ref="configInfoRef"
          :user-account="userAccount"
          :user-name="userName"
          :user-id="userId"
          :mode="mode === 'viewConfig' ? 'view' : 'config'"
        />
      </div>
    </template>
    <template #footer>
      <template v-if="!isView">
        <template v-if="isNotDependencyEnv">
          <div class="info-footer" v-if="isCreate || isEdit">
            <el-button @click="cancelStep">
              {{ $t('SM.ORGSTAFF.BUTTON.TEAM_CANCEL') }}
            </el-button>
            <el-button @click="nextStep">
              {{ $t('SM.ORGSTAFF.LABEL.EMPLOYEE_SAVE') }}
            </el-button>
            <el-button v-if="isCreate || isEdit" type="primary" @click="saveAndConfig">
              {{ $t('SM.ORGSTAFF.BUTTON.SaveAndConfigure') }}
            </el-button>
          </div>
          <div class="config-footer" v-if="mode === 'config'">
            <el-button @click="cancelStep">
              {{ $t('SM.ORGSTAFF.BUTTON.TEAM_CANCEL') }}
            </el-button>
            <el-button type="primary" @click="save">
              {{ $t('SM.ORGSTAFF.LABEL.EMPLOYEE_SAVE') }}
            </el-button>
          </div>
        </template>
        <template v-if="!isNotDependencyEnv">
          <el-button @click="cancelStep">
            {{ $t('SM.ORGSTAFF.BUTTON.TEAM_CANCEL') }}
          </el-button>
          <el-button type="primary" @click="nextStep">
            {{ $t('SM.ORGSTAFF.LABEL.EMPLOYEE_SAVE') }}
          </el-button>
        </template>
      </template>
      <template v-else>
        <template v-if="isNotDependencyEnv && !isViewConfig">
          <div class="config-footer">
            <sweet-button type="primary" @click="nextStep">
              {{ $t('sumweb.employee.view.config') }}
            </sweet-button>
          </div>
        </template>
      </template>
    </template>
  </aicc-full-screen>
</template>

<script>
import AiccFullScreen from '@/components/aicc-full-screen/index.vue'
import employeeInfo from '@/views/sumweb/components/user/employeeInfo.vue'
import employeeConfig from '@/views/sumweb/components/user/employee-config.vue'
import { ElMessageBox } from 'element-plus'
import cookie from '@/utils/cookie'

export default {
  name: 'employee-basic',
  components: {
    AiccFullScreen,
    employeeInfo,
    employeeConfig
  },
  props: {
    mode: {
      type: String,
      default: () => {
        return ''
      }
    }
  },
  data() {
    return {
      tenantSpaceId: '',
      userId: '',
      userAccount: '',
      userName: ''
    }
  },
  computed: {
    isView() {
      return this.mode === 'view'
    },
    isEdit() {
      return this.mode === 'edit'
    },
    isCreate() {
      return this.mode === 'create'
    },
    isViewConfig() {
      return this.mode === 'viewConfig'
    },
    existConfig() {
      if (this.isViewConfig) {
        return ['backHeader']
      }
      return ['backHeader', 'footerAction']
    },
    isNotDependencyEnv() {
      return !this.isIndependentDeployment && this.tenantSpaceId !== 'defaultSpace'
    }
  },
  mounted() {
    this.tenantSpaceId = cookie.getCookie('tenant_space_id')
    if (this.$route.query.userId) {
      this.userId = this.$route.query.userId
    }
  },
  methods: {
    save() {
      this.$refs.configInfoRef.handleSave(this.userId)
    },
    async saveAndConfig() {
      const result = await this.nextStep()
      if (result) {
        this.$event.addPageToTab({
          title: this.$t('sumweb.agent.config'),
          name: 'employee-config',
          query: {
            mode: 'config',
            userId: result
          }
        })
      }
    },
    async nextStep() {
      let taskInfoFormdata = this.$refs.baseInfoRef.$refs.taskInfoForm
      const valid = await taskInfoFormdata.validate()
      if (!valid) {
        return
      }
      let result = false
      if (this.isCreate) {
        await ElMessageBox.confirm(
          $t('SM.ORGSTAFF.MESSAGE.STAFF_INFOAUTH_CONFIRM'),
          $t('SM.ORGSTAFF.TITLE.CONTACT_DESC'),
          {
            confirmButtonText: $t('base.ok'),
            cancelButtonText: $t('base.cancel'),
            type: 'warning'
          }
        )
        result = await this.$refs.baseInfoRef.submit(taskInfoFormdata)
      } else if (this.isEdit) {
        result = await this.$refs.baseInfoRef.submit(taskInfoFormdata)
      } else {
        if (this.isNotDependencyEnv) {
          const userInfo = this.$refs.baseInfoRef.userInfo
          this.$event.addPageToTab({
            title: this.$t('sumweb.employee.view.config'),
            name: 'employee-config',
            query: {
              mode: 'viewConfig',
              userId: userInfo.data.userId
            }
          })
        } else {
          result = true
        }
      }
      if (result) {
        this.$event.backInTab()
      }
      return result
    },
    cancelStep() {
      this.$event.backInTab()
    }
  }
}
</script>

<style scoped>
.employee-basic-main.has-step {
  padding: 0 16px 16px;
}

.employee-basic-main {
  padding: 16px;
}
.employee-step {
  padding: 20px 16px;
}

:deep(.el-step__main) {
  cursor: pointer;
}

:deep(.el-button > span:not(.el-button--text > span):not(.is-text > span)) {
  max-width: 200px;
}
</style>
