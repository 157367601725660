import i18n from '@/lang'

const $t = i18n.global.t

export const VOICE_TYPE_MAP = {
  STATIC_VOICE: {
    label: $t('oifde.service.invoke.voiceType.staticVoice'),
    value: '3',
  },
  TTS_VOICE: {
    label: $t('oifde.service.invoke.voiceType.TTSVoice'),
    value: '1',
  },
  VIDEO: {
    label: $t('oifde.service.invoke.voiceType.video'),
    value: '5',
  },
  MULTI_MEDIA: {
    label: $t('oifde.service.invoke.voiceType.multi-media'),
    value: '6',
  },
  CARD: {
    label: $t('oifde.service.invoke.voiceType.card'),
    value: '7',
  },
  MULTI_VOICE: {
    label: $t('oifde.service.invoke.multiVoice'),
    value: '8',
  },
  VOICE_TTS_MIXED: {
    label: $t('oifde.service.invoke.voiceTtsMixed'),
    value: '9',
  },
}

export const VOICE_TYPE_LIST = [
  VOICE_TYPE_MAP.STATIC_VOICE,
  VOICE_TYPE_MAP.TTS_VOICE,
  VOICE_TYPE_MAP.VIDEO,
  VOICE_TYPE_MAP.MULTI_MEDIA,
  VOICE_TYPE_MAP.CARD,
  VOICE_TYPE_MAP.MULTI_VOICE,
  VOICE_TYPE_MAP.VOICE_TTS_MIXED
]

// 需要tts的回复方式类型
export const NEED_TTS_VOICE_TYPE_LIST = [
  VOICE_TYPE_MAP.TTS_VOICE.value,
  VOICE_TYPE_MAP.VOICE_TTS_MIXED.value
]

export const VOICE_SOURCE_MAP = {
  TEMPLATE: {
    label: $t('oifde.robotReply.replyTemplate'),
    value: '1',
  },
  VARIABLE: {
    label: $t('oifde.robotReply.replyVariable'),
    value: '2',
  },
  RESOURCE: {
    label: $t('oifde.robotReply.replyResource'),
    value: '3',
  },
}

export const VOICE_SOURCE_LIST = [
  VOICE_SOURCE_MAP.TEMPLATE,
  VOICE_SOURCE_MAP.VARIABLE,
  VOICE_SOURCE_MAP.RESOURCE
]

export const MODEL_TYPE_MAP = {
  RECOGNITION_BREAK: {
    label: $t('oifde.service.invoke.modelType.voiceBreak'),
    value: '1101200',
  },
  ONLY_VOICE_NO_BREAK: {
    label: $t('oifde.service.invoke.modelType.noVoiceBreak'),
    value: '1001200',
  },
  KEYBOARD_BREAK: {
    label: $t('oifde.service.invoke.modelType.keyboardBreak'),
    value: '1011211',
  },
  RECOGNITION_AND_KEYBOARD_BREAK: {
    label: $t('oifde.service.invoke.modelType.voiceAndKeyboard'),
    value: '1111211',
  },
  RECOGNITION_NO_BREAK: {
    label: $t('oifde.service.invoke.modelType.voiceAndNobreak'),
    value: '1201211',
  },
  RECOGNITION_AND_KEYBOARD_NO_BREAK: {
    label: $t('oifde.service.invoke.modelType.voiceAndInputAndNobreak'),
    value: '1221211',
  },
  KEYBOARD_NO_BREAK: {
    label: $t('oifde.service.invoke.modelType.inputAndNobreak'),
    value: '1021211',
  },
  CUSTOMIZATION: {
    label: $t('oifde.service.invoke.modelType.customization'),
    value: 'customization',
  },
}

export const MODEL_TYPE_LIST = [
  MODEL_TYPE_MAP.RECOGNITION_BREAK,
  MODEL_TYPE_MAP.ONLY_VOICE_NO_BREAK,
  MODEL_TYPE_MAP.KEYBOARD_BREAK,
  MODEL_TYPE_MAP.RECOGNITION_AND_KEYBOARD_BREAK,
  MODEL_TYPE_MAP.RECOGNITION_NO_BREAK,
  MODEL_TYPE_MAP.RECOGNITION_AND_KEYBOARD_NO_BREAK,
  MODEL_TYPE_MAP.KEYBOARD_NO_BREAK,
  MODEL_TYPE_MAP.CUSTOMIZATION,
]

// 数字人图元回复类型
export const AVATAR_MODEL_TYPE_LIST = [
  MODEL_TYPE_MAP.RECOGNITION_BREAK,
  MODEL_TYPE_MAP.ONLY_VOICE_NO_BREAK,
  MODEL_TYPE_MAP.KEYBOARD_BREAK,
  MODEL_TYPE_MAP.RECOGNITION_AND_KEYBOARD_BREAK,
  MODEL_TYPE_MAP.RECOGNITION_NO_BREAK,
  MODEL_TYPE_MAP.RECOGNITION_AND_KEYBOARD_NO_BREAK,
  MODEL_TYPE_MAP.KEYBOARD_NO_BREAK,
]

// 需要按键收号的回复模式类型
export const NEED_COLLECT_MODEL_TYPE_LIST = [
  MODEL_TYPE_MAP.KEYBOARD_BREAK.value,
  MODEL_TYPE_MAP.RECOGNITION_AND_KEYBOARD_BREAK.value,
  MODEL_TYPE_MAP.RECOGNITION_AND_KEYBOARD_NO_BREAK.value,
  MODEL_TYPE_MAP.KEYBOARD_NO_BREAK.value,
]

// 需要asr识别的回复模式类型
export const NEED_ASR_MODEL_TYPE_LIST = [
  MODEL_TYPE_MAP.RECOGNITION_BREAK.value,
  MODEL_TYPE_MAP.RECOGNITION_AND_KEYBOARD_BREAK.value,
  MODEL_TYPE_MAP.RECOGNITION_NO_BREAK.value,
  MODEL_TYPE_MAP.RECOGNITION_AND_KEYBOARD_NO_BREAK.value,
  MODEL_TYPE_MAP.CUSTOMIZATION.value,
]

export const CANCEL_COLLECT_FLAG_LIST = [
  {
    label: $t('oifde.service.invoke.cancelCollectFlag.yes'),
    value: '1',
  },
  {
    label: $t('oifde.service.invoke.cancelCollectFlag.no'),
    value: '0',
  },
]

export const CONFIRM_COLLECT_FLAG_LIST = [
  {
    label: $t('oifde.service.invoke.confirmCollectFlag.yes'),
    value: '1',
  },
  {
    label: $t('oifde.service.invoke.confirmCollectFlag.no'),
    value: '0',
  },
]

export const ASR_RECOGNIZE_TYPE_LIST = [
  {
    label: $t('oifde.service.invoke.asrRecognizeType.normal'),
    value: '0',
  },
]

export const TTS_SOUND_EFFECT_MAP = {
  MALE: {
    label: $t('oifde.service.invoke.ttsSoundEffect.male'),
    value: '0',
  },
  FEMALE: {
    label: $t('oifde.service.invoke.ttsSoundEffect.female'),
    value: '1',
  },
  CUSTOMIZATION: {
    label: $t('oifde.service.invoke.ttsSoundEffectCust'),
    value: '2',
  }
}

export const TTS_SOUND_EFFECT_LIST = [
  TTS_SOUND_EFFECT_MAP.MALE,
  TTS_SOUND_EFFECT_MAP.FEMALE,
  TTS_SOUND_EFFECT_MAP.CUSTOMIZATION,
]

export const TTS_SOUND_SPEED_MAP = {
  LOW: {
    label: $t('oifde.service.invoke.ttsSoundSpeed.low'),
    value: '0',
  },
  NORMAL: {
    label: $t('oifde.service.invoke.ttsSoundSpeed.normal'),
    value: '1',
  },
  HIGH: {
    label: $t('oifde.service.invoke.ttsSoundSpeed.high'),
    value: '8',
  },
  CUSTOMIZATION: {
    label: $t('oifde.service.invoke.ttsSoundSpeedCust'),
    value: '3',
  }
}

export const TTS_SOUND_SPEED_LIST = [
  TTS_SOUND_SPEED_MAP.LOW,
  TTS_SOUND_SPEED_MAP.NORMAL,
  TTS_SOUND_SPEED_MAP.HIGH,
  TTS_SOUND_SPEED_MAP.CUSTOMIZATION,
]

export const ASR_ADVANCED_SETTING = {
  open: '0',
  close: '1'
}

export const TTS_ADVANCED_SETTING = {
  open: '0',
  close: '1'
}

export const TTS_MRCPVENDOR_SETTING = {
  open: '0',
  close: '1'
}

export const MULTI_VOICE_TYPE_DESC = {
  "1": $t("oifde.service.invoke.voiceVariable.multiVoice.telnum.desc"),
  "3": $t("oifde.service.invoke.voiceVariable.multiVoice.number.desc"),
  "4": $t("oifde.service.invoke.voiceVariable.multiVoice.time.desc"),
  "5": $t("oifde.service.invoke.voiceVariable.multiVoice.date.desc"),
  "6": $t("oifde.service.invoke.voiceVariable.multiVoice.price.desc"),
}

export const LANGUAGE_MAP = {
  zh_CN: {
    label: $t('ccivr.ivr.voicereview.chinese'),
    aiccLanguageId: '0'
  },
  en_US: {
    label: $t('ccivr.ivr.voicereview.english'),
    aiccLanguageId: '1'
  },
  th_TH: {
    label: $t('ccivr.ivr.voicereview.thailand'),
    aiccLanguageId: '24'
  },
  es_ES: {
    label: $t('ccivr.ivr.voicereview.spanish'),
    aiccLanguageId: '20'
  },
  pt_BR: {
    label: $t('ccivr.ivr.voicereview.portuguese'),
    aiccLanguageId: '17'
  },
  fr_FR: {
    label: $t('ccivr.ivr.voicereview.french'),
    aiccLanguageId: '7'
  },
  ar: {
    label: $t('ccivr.ivr.voicereview.arabic'),
    aiccLanguageId: '81'
  }
}

// 01~32的字符数组
export const MAX_COLLECT_LENGTH_LIST = Array.from(Array(32), (value, key) => {
  let val = key + 1
  if (val < 10) {
    return '0' + val
  } else {
    return '' + val
  }
})
