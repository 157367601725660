import ccikbsEnUS from './ccikbs_en_US'
import ccikbsZhCN from './ccikbs_zh_CN'
import ccikbsEsES from './ccikbs_es_ES'
import ccikbsFrFR from './ccikbs_fr_FR'
import ccikbsPtBR from './ccikbs_pt_BR'
import ccikbsThTH from './ccikbs_th_TH'
import ccikbsDeDE from './ccikbs_de_DE'

const ccikbs = {
  en_US: {
    ...ccikbsEnUS,
  },
  zh_CN: {
    ...ccikbsZhCN,
  },
  es_ES: {
    ...ccikbsEsES,
  },
  fr_FR: {
    ...ccikbsFrFR,
  },
  pt_BR: {
    ...ccikbsPtBR
  },
  th_TH: {
    ...ccikbsThTH
  },
  de_DE: {
    ...ccikbsDeDE
  },
}

export default ccikbs