export default {
  "agentconsole.dashboard.enumeration.aweek" : "الأسبوع الماضي",
  "SM.LOGIN.BUTTON.CHANGEPWD_CONFIRM" : "حسناً",
  "agentconsole.Intelligent.details" : "التفاصيل",
  "agentconsole.workbench.label.intelligentrecommendation" : "وكيل مساعد",
  "agentconsole.dashboard.title.case" : "نظرة عامة على الحالة",
  "agentconsole.header.label.searchtask" : "البحث في المهمة",
  "SM.LOGIN.TITLE.NOTICE_EXP_TIME" : "وقت انتهاء الصلاحية",
  "agentconsole.title.intelligentcase": "ملء بطاقة المشكلة بذكاء",
  "SM.LOGIN.LABEL.LOGIN_CONFIRM_SUFFIX" : "تأكيد ),:",
  "SM.LOGIN.LABEL.NOTICE_CREATE_TIME_TO" : "إلى",
  "SM.LOGIN.LABEL.OPER_PHONE" : "رقم الجوال:",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_NOT_OPEN_ES" : "لم يتم تشغيل نظام الطوارئ. يرجى الوصول إلى قائمة الإنتاج لتنفيذ العمليات التجارية.",
  "agentconsole.workbench.label.callinforeasons" : "سبب المستوى الأول / سبب المستوى الثاني / سبب المستوى الثالث / سبب المستوى الرابع",
  "agentconsole.dashboard.title.averageevalution" : "متوسط الرضا",
  "agentconsole.header.title.send.sms.notification" : "إشعار الرسائل القصيرة الصادرة",
  "agentconsole.index.label.caseconsole" : "حالة طاولة العمل",
  "agentconsole.login.label.empty" : "اسم المستخدم أو كلمة المرور فارغة.",
  "agentconsole.authmanage.label.authtype.menu" : "إذن القائمة",
  "SM.LOGIN.LABEL.FORGETPWD_ACCOUNT_TIPS" : "أدخل حساب تسجيل الدخول.",
  "SM.LOGIN.LABEL.CHANGEPWD_OLDPWD" : "كلمة المرور القديمة",
  "agentconsole.callmessage.setHandledNumber" : "تعيين رقم تمت معالجته",
  "agentconsole.login.label.password" : "كلمة المرور",
  "agentconsole.header.title.send.whatsapp.notification" : "إرسال رسائل تنبيه WhatsApp",
  "agentconsole.login.label.signin" : "تسجيل الدخول",
  "agentconsole.index.label.chatconsole" : "دردشة طاولة العمل",
  "SM.ORGSTAFF.LABEL.PWD_UNMATCH_RULE" : "كلمة المرور لا تتوافق مع القواعد.",
  "agentconsole.menumanage.message.paramvalidatefailed" : "فشل إنشاء القائمة. تحقق من المعلمات.",
  "SM.LOGIN.LABEL.OPER_REGION" : "منطقة المنزل:",
  "SM.LOGIN.TIPS.CHANGEPWD_COMPARE" : "يجب أن تكون كلمة المرور الجديدة مماثلة لتأكيد كلمة المرور.",
  "SM.LOGIN.TITLE.UPDATE_PWD_VERIFYCODE_EMPTY" : "رمز التحقق فارغ.",
  "SM.LOGIN.LABEL.NOTICE_INCLUDE_SUB_ORG" : "تشمل OU الطفل",
  "agentconsole.common.button.modify" : "تعديل",
  "agentconsole.msg.noData" : "لا توجد بيانات",
  "SM.LOGIN.TITLE.NOTICE" : "النشرة",
  "SM.LOGIN.TIPS.LOGINSUCCESS" : "تم تسجيل الدخول بنجاح.",
  "SM.LOGIN.TITLE.PORTAL_REGION_TITLE" : "المنطقة",
  "agentconsole.quickentrance.classname" : "يرجى إعطاء الكتالوج اسم تجميع. مثال: إدارة موارد المستأجر",
  "SM.LOGIN.TITLE.PORTAL_TITLE" : "بوابة BES",
  "SM.LOGIN.MESSAGE.LOGOUT" : "هل تريد بالتأكيد الخروج؟",
  "SM.LOGIN.LABEL.LOGIN_CONFIRM_SWITCH" : "الوصول إلى نظام المنزل",
  "agentconsole.dashboard.title.taskunassigned" : "غير معين",
  "SM.LOGIN.TITLE.UPDATE_PWD_NEWPWD_EMPTY" : "كلمة المرور الجديدة فارغة.",
  "SM.LOGIN.BUTTON.RESET" : "إعادة الضبط",
  "agentconsole.ccnotification.inputRecipient" : "الرجاء إدخال المستلمين، يتم فصل المستلمين المتعددين بفواصل",
  "agentconsole.ccnotification.inputOneRecipient": "الرجاء إدخال المستلم",
  "agentconsole.ccnotification.recipient.validate.limit.one": "لا يمكن أن يتجاوز عدد المستلمين 1.",
  "agentconsole.title.realtimetransfer" : "نقل في الوقت الحقيقي",
  "agentconsole.callmessage.callerNo" : "المتصل",
  "SM.LOGIN.LABEL.SUM" : "إدارة مستخدم النظام",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_SWITCH_ES" : "تم تحويل العمل إلى حالة الطوارئ. لا يمكن التعامل مع الأعمال إلا بعد استرداد النظام.",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_VERIFY_CODE_NOT_EXIST" : "رمز التحقق غير موجود.",
  "agentconsole.dashboard.evaluation.medium" : "3-4 نقاط",
  "agentconsole.header.title.logout" : "تسجيل الخروج",
  "agentconsole.index.label.casedec" : "يمكنك إنشاء حالة لكل طلب خدمة من عميلك وتتبع دورة الحياة الكاملة لمعالجة طلب خدمة العملاء من خلال الحالة ، وضمان إغلاق كل طلب عميل وتحسين رضا العملاء.",
  "agentconsole.common.tips.error" : "خطأ",
  "agentconsole.menumanage.title.selectauth" : "يُرجى تحديد إذن",
  "SM.LOGIN.BUTTON.FORGETPWD_CANCEL" : "إلغاء",
  "agentconsole.Intelligent.noRecord" : "لم يتم معالجة الخدمة أو أن نتيجة الاستعلام خالية.",
  "agentconsole.dashboard.evaluation.low" : "1-2 نقطة",
  "SM.LOGIN.TITLE.UPDATE_PWD_NEWPWD_RULE" : "لا تتوافق كلمة المرور الجديدة مع قاعدة كلمة المرور.",
  "SM.LOGIN.MESSAGE.SENDSMS_FAILED" : "فشل إرسال الرسالة النصية. تحقق من سجل الخلفية.",
  "agentconsole.header.title.notificationcenter" : "عرض الإشعارات",
  "agentconsole.login.indexforipcc.help" : "مساعدة",
  "agentconsole.contacthistory.title" : "تاريخ جهات الاتصال",
  "agentconsole.authmanage.field.auth_type" : "نوع الإذن",
  "SM.PORTAL.LABEL.INPUT_OLD_PWD" : "أدخل كلمة المرور القديمة.",
  "agentconsole.title.onlycustomer" : "العميل فقط",
  "SM.LOGIN.BUTTON.FORGETPWD_CONFIRM" : "حسناً",
  "SM.LOGIN.LABEL.FORGETPWD_EMAIL_TIPS" : "أدخل عنوان البريد الإلكتروني.",
  "SM.AUC.MESSAGE.PWDRULE_NO" : "لا",
  "agentconsole.callmessage.faq.spread" : "توسيع",
  "agentconsole.querycustominfo.msg.inputHandleNumber" : "أدخل رقم تمت معالجته",
  "agentconsole.login.indexforipcc.difbutton" : "يمكن عرض أزرار التحكم في التشغيل المختلفة وفقًا لسلطة موظفي خدمة العملاء لتجنب سوء التشغيل والتدخل غير الضروري ، وبالتالي تحسين كفاءة خدمة موظفي خدمة العملاء.",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_LOGIN_ID_NULL" : "لا يمكن أن يكون حساب تسجيل الدخول فارغًا.",
  "agentconsole.common.button.ok" : "حسناً",
  "agentconsole.login.indexforipcc.agentcall" : "التحكم في مكالمات الموظف، مثل الرد على المكالمات، وإعادة توجيه المكالمات، والمكالمات الثلاثية، وطلب المساعدة، وما إلى ذلك.",
  "agentconsole.contacthistory.oneMin" : "منذ 1دقيقة",
  "SM.LOGIN.TITLE.PASSWORD_HASEXPIRED" : "انتهت صلاحية كلمة المرور الخاصة بك. يُرجى الاتصال بالمسؤول لإعادة تعيين كلمة المرور.",
  "SM.LOGIN.LABEL.FRAMEWORK_CALCULATOR" : "آلة حاسبة",
  "SM.LOGIN.LABEL.NOTICE_GRADE_NORMAL" : "عادي",
  "SM.LOGIN.LABEL.CUS_LOGIN" : "تسجيل دخول العميل",
  "SM.SERVLET.BATCH.RESET.PASSWORD.EMPLOYEE" : "إعادة تعيين كلمات مرور مستخدم الإيجار على دفعات",
  "agentconsole.sessionmanagement.label.clientIp" : "العميل IP",
  "agentconsole.header.label.searchbase" : "البحث في مركز المعرفة",
  "agentconsole.login.resetpwd.empty" : "اسم المستخدم أو عنوان البريد الإلكتروني فارغ.",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED" : "فشل فتح القائمة.",
  "SM.LOGIN.TITLE.LOGIN_TITLE" : "صفحة تسجيل الدخول إلى بوابة BES",
  "agentconsole.login.indexforipcc.cccbar" : "يوفر شريط المقعد في إطار الموظف وظائف معالجة المكالمات الصوتية الرئيسية ووظائف التحكم في حالة الموظف.",
  "SM.LOGIN.TIPS.UPDATE_PWD" : "تغيير كلمة المرور",
  "SM.LOGIN.TITLE.CURRENT_BE" : "المدينة الحالية",
  "SM.ORGSTAFF.LABEL.TEAM_ACCOUNT" : "الحساب",
  "agentconsole.workbench.queNum" : "عدد عملاء قوائم الانتظار الحالية:",
  "agentconsole.callmessage.faq.folded" : "الانهيار",
  "SM.LOGIN.TITLE.PROJECT_CREATER" : "الخالق:",
  "SM.LOGIN.MESSAGE.LOGIN.LABEL.EMPTY" : "اسم المستخدم أو كلمة المرور فارغة.",
  "agentconsole.common.expiry.phone" : "الخط الساخن للخدمة",
  "agentconsole.menumanage.message.urlvalidatefailed" : "فشل إنشاء القائمة. اتصل بمسؤول النظام للتحقق من قائمة ثقة عناوين IP.",
  "SM.LOGIN.TITLE.PASSWORD_INIT_SHOULD" : "أنت تستخدم كلمة المرور الأولية. قم بتغيير كلمة المرور على الفور.",
  "SM.LOGIN.LABEL.RESETPWD.FAIL" : "فشلت إعادة تعيين كلمة المرور.",
  "agentconsole.dashboard.title.caseunhandle" : "غير المجهزة",
  "agentconsole.login.indexforipcc.connectcontrol" : "التحكم في حالة الاتصال",
  "SM.LOGIN.TIPS.MODIFY_SUCCESS" : "تم التعديل بنجاح.",
  "agentconsole.dashboard.title.casecreate" : "عدد الحالات التي تم إنشاؤها",
  "SM.ORGSTAFF.TIPS.REGION_DST_SWITCHINTERVAL_TIPS" : "الوحدة: الثانية. النطاق: 1 إلى 86400. في حالة ترك هذه المعلمة فارغة، يتم استخدام القيمة الافتراضية 3600.",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_LOGINID_NO_BIND_MSISDN" : "لم يلتزم المستخدم بأي رقم هاتف محمول.",
  "SM.PORTAL.LABEL.INPUT_NEW_PWD" : "أدخل كلمة مرور جديدة.",
  "agentconsole.menumanage.message.renamenode" : "اسم القائمة موجود بالفعل.",
  "agentconsole.contacthistory.accept" : "رقم تمت معالجته",
  "SM.LOGIN.LABEL.REMEMBER" : "تذكر اسم المستخدم",
  "agentconsole.login.indexforipcc.signinsuccess" : "بعد قيام الموظف بتسجيل الوصول بنجاح، يستطيع الموظف الرد على مكالمة العميل ومعالجة الأعمال. يقوم النظام بتعيين مكالمات العملاء استنادًا إلى الموظفين الذين تم إيداعهم. يقوم موظفو الإدارة بإدارة الحضور وفقًا لوقت تسجيل الوصول والمغادرة الخاص بالوكيل.",
  "agentconsole.login.indexforipcc.usenow" : "استخدم على الفور",
  "SM.LOGIN.TITLE.UPDATE_PWD_CONFIRMPWD" : "تأكيد كلمة المرور:",
  "agentconsole.authmanage.label.authtype.interface" : "إذن الواجهة",
  "SM.LOGIN.LABEL.RESETPWD" : "انقر إعادة تعيين. سيتم إرسال كلمة المرور الجديدة إلى صندوق البريد.",
  "agentconsole.login.indexforipcc.callcontrol" : "التحكم في المكالمات الصوتية",
  "agentconsole.header.title.send.notification" : "إرسال إشعار",
  "agentconsole.login.indexforipcc.difauth" : "إدارة مؤسسة المستأجر وأدواره وأذوناته وموظفيه. بعد قيام موظفي خدمة العملاء بتسجيل الدخول باستخدام حساب الموظف، تختلف أزرار التحكم في المكالمة وقوائم الصفحات التي يمكن عرضها وفقًا للحقوق المختلفة. فقط عندما يقوم المستخدم بتسجيل الدخول ولكن لم يتم إيداعه، لا يمكن إجراء العمليات المتعلقة بالمكالمة.",
  "agentconsole.must.be.a.number" : "يجب أن يكون رقمًا",
  "SM.LOGIN.MESSAGE.LOGOUT_REASON.IP_CHECK_FAILED" : "فشل التحقق من عنوان IP. يختلف عنوان IP الحالي عن عنوان أول تسجيل دخول.",
  "agentconsole.menumanage.message.menuname" : "يجب أن يكون عدد البايتات أقل من أو يساوي 50 بايت",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_OPEN_BE_ES" : "تم تحويل المنطقة الحالية إلى حالة الطوارئ. قم بتسجيل الدخول إلى نظام الطوارئ للتعامل مع الأعمال.",
  "agentconsole.login.indexforipcc.doublecontrol" : "تحتوي سحابة خدمة العملاء الذكية على طبقتين من التحكم في المصادقة لنظام تسجيل دخول الوكيل:",
  "SM.LOGIN.MESSAGE.SUGGESTION" : "موجه",
  "SM.LOGIN.MESSAGE.SEARCH_HISTORY_CLEAR" : "مسح التاريخ",
  "SM.LOGIN.TITLE.ICPCODE" : "جيانغسو ICP رقم التسجيل: {0}-{1}",
  "agentconsole.common.expiry.prefix" : "ستنتهي صلاحية المستأجر الخاص بك في",
  "SM.LOGIN.LABEL.OPER_LOGINID" : "تسجيل الدخول إلى الحساب:",
  "SM.LOGIN.LABEL.NOTICE_ORG" : "OU",
  "agentconsole.quickentrance.classdesc" : "يرجى كتابة ملاحظة لهذا الدليل. مثال: إدارة موارد المستأجر. يمكنك عرض صيانة موارد المستأجر هنا.",
  "SM.LOGIN.LABEL.CHANGEPWD_ACCOUNT" : "الحساب",
  "SM.LOGIN.TIPS.LOGINRESULT" : "نتيجة تسجيل الدخول:",
  "SM.LOGIN.TITLE.CHANGEPWD_ERROR" : "خطأ",
  "SM.LOGIN.LABEL.AUTH_FAILED_ES" : "دخلت المدينة الحالية حالة الطوارئ. يرجى تسجيل الدخول إلى نظام الطوارئ.",
  "agentconsole.custinfo.label.amount" : "مبلغ الدفع",
  "agentconsole.sessionmanagement.label.startime" : "وقت البدء",
  "SM.LOGIN.LABEL.FRAMEWORK_ONLINE_HELP" : "مساعدة عبر الإنترنت",
  "agentconsole.callmessage.setNumerError" : "فشل تعيين رقم المعالجة.",
  "agentconsole.sessionmanagement.label.accountNumber" : "الحساب",
  "SM.INDEX.LABEL.TASK" : "المهمة",
  "SM.LOGIN.LABEL.VCODE_INVALID" : "رمز التحقق غير صحيح.",
  "agentconsole.dashboard.evaluation.unit" : "الكمية",
  "SM.LOGIN.LABEL.ERRORPAGE_ERRORNODETAIL_CLOSE" : "تفاصيل>",
  "agentconsole.authmanage.message.renamenode" : "إعادة تسمية الإذن.",
  "SM.LOGIN.LABEL.SMS_RECONFIRM_GETCODE" : "الحصول على رمز التحقق",
  "agentconsole.index.label.querycase" : "استعلام عن حالة",
  "agentconsole.workbench.talkNum" : "مكالمة اليوم تهم:",
  "agentconsole.login.label.verifycode" : "رمز التحقق",
  "agentconsole.custinfo.label.dueDate" : "تاريخ الاستحقاق",
  "SM.INDEX.LABEL.CHATDEC" : "أثناء محادثة متعددة الوسائط عبر الإنترنت مع عميلك، يمكنك عرض معلومات الاتصال التاريخية الشاملة للعميل لزيادة كفاءة الخدمة وتوفير تجربة خدمة Omnichannel لعميلك.",
  "SM.SERVLET.BATCH.RESET.PASSWORD.EMPFAIL" : "، الموظفين الفاشلين:",
  "SM.LOGIN.BUTTON.LOGIN_CONFIRM_MSG" : "سيقوم النظام بالخروج إذا كنت تريد الوصول إلى نظام BE. تحتاج إلى إدخال اسم المستخدم وكلمة المرور لتسجيل الدخول. هل أنت متأكد من رغبتك في المتابعة؟",
  "agentconsole.dashboard.title.taskunhandle" : "غير المجهزة",
  "SM.LOGIN.MESSAGE.CHANGEPWD_FAILED" : "فشل تغيير كلمة المرور.",
  "agentconsole.sessionmanagement.table.deleteConfimMessage" : "هل تريد بالتأكيد حذف جلسة العمل هذه؟",
  "agentconsole.wordreminder.norecommend" : "لا يوجد برنامج نصي موصى به متاح.",
  "agentconsole.login.indexforipcc.agent" : "شهادة الوكيل",
  "agentconsole.login.indexforipcc.transfer" : "إعادة توجيه المكالمات",
  "agentconsole.custinfo.label.custName" : "اسم العميل",
  "SM.COMMON.MESSAGE.VALIDATE_MINLENGTHB" : "إدخال {0} أحرف على الأقل.",
  "agentconsole.index.label.taskconsole" : "منضدة المهام",
  "SM.LOGIN.MESSAGE.SENDMAIL_FAILED" : "فشل إرسال البريد الإلكتروني.",
  "SM.LOGIN.LABEL.OPER_BE" : "BE:",
  "agentconsole.authmanage.message.authlimit" : "إذا كان الاستثناء ناتجًا عن أذونات غير كافية أو أسباب أخرى، فاتصل بالدعم الفني من Huawei.",
  "agentconsole.index.label.taskdec" : "أثناء معالجة الحالة ، يمكنك إرسال المهام للعمل مع العديد من الإدارات والموظفين. يمكن إنشاء مهام متعددة لحالة.",
  "agentconsole.dashboard.title.casepriority" : "قائمة المهام",
  "agentconsole.menumanage.message.notdelnoleafmenu" : "لا تسمح بحذف القائمة مع القوائم الفرعية.",
  "agentconsole.header.title.messageCenter" : "إدارة الرسائل الداخلية",
  "SM.LOGIN.TITLE.PORTAL_SWITCH_PROJECT" : "مشروع التبديل",
  "SM.ORGSTAFF.LABEL.ADDR_PLEASE_INPUT" : "يرجى إدخال الاسم",
  "agentconsole.login.label.email" : "البريد الإلكتروني",
  "SM.LOGIN.BUTTON.SMS_RECONFIRM_CANCEL" : "إلغاء",
  "SM.LOGIN.LABEL.FORGETPWD_SENDTYPE" : "وضع الاسترجاع",
  "agentconsole.authmanage.message.authtype" : "لا يمكن إدخال اسم الصلاحية. الأحرف الخاصة ليست فارغة ويجب أن يكون عدد البايتات أقل من أو يساوي 50.",
  "SM.LOGIN.TITLE.PROJECT_DESC" : "وصف المشروع:",
  "agentconsole.dashboard.title.caseperday" : "حالة المعالجة اليومية",
  "agentconsole.menumanage.message.createsubnodefail" : "لا يمكن أن يتجاوز مستوى القائمة 3 مستويات.",
  "SM.LOGIN.TIPS.WELCOME" : "مرحبا بكم في استخدام BES!",
  "SM.LOGIN.TITLE.LOGIN" : "تسجيل الدخول",
  "agentconsole.workbench.signInCallNum" : "عدد مكالمات تسجيل الدخول الحالية:",
  "SM.LOGIN.LABEL.SMS_RECONFIRM_VCODE" : "رمز التحقق",
  "SM.LOGIN.BUTTON.UPDATE_PWD_MODIFY" : "تعديل",
  "SM.LOGIN.LABEL.OPER_EMAIL" : "البريد الإلكتروني:",
  "SM.LOGIN.MESSAGE.SUM" : "المجموع:",
  "SM.LOGIN.LABEL.VCODE" : "رمز التحقق",
  "agentconsole.login.indexforipcc.recordcontrol" : "التحكم في التسجيل والتشغيل",
  "SM.LOGIN.MESSAGE.MODIFY_PWD_SUCCESS" : "تم تغيير كلمة المرور بنجاح.",
  "SM.LOGIN.TITLE.PROJECT_CREATE_TIME" : "وقت الإنشاء:",
  "agentconsole.header.label.searchcase" : "البحث في حالة",
  "SM.LOGIN.TITLE.NOTICE_EFF_TIME" : "الوقت الفعال",
  "SM.LOGIN.SSO.IS.INVALID" : "انتهت صلاحية الصفحة، انقر لتحديث الصفحة.",
  "agentconsole.history.lastloginfailattempts" : "آخر محاولات تسجيل الدخول الفاشلة",
  "SM.LOGIN.LABEL.ERRORPAGE_RETURNBTN" : "العودة إلى صفحة تسجيل الدخول",
  "SM.LOGIN.MESSAGE.SWITCH" : "هل تريد بالتأكيد التبديل إلى نظام ESOP؟",
  "agentconsole.menu.extended.apps" : "التطبيقات الموسعة",
  "SM.LOGIN.RESETPWD.FAIL" : "فشل في معالجة طلب إعادة تعيين كلمة المرور. تحقق من حساب تسجيل الدخول وعنوان البريد الإلكتروني.",
  "agentconsole.history.loginip" : "تسجيل الدخول IP",
  "agentconsole.login.indexforipcc.call" : "التحكم في المكالمات الصوتية",
  "agentconsole.index.label.task" : "المهمة",
  "agentconsole.quickentrance.checkcharacterlength" : "يرجى إدخال ما لا يزيد عن {0} أحرف.",
  "agentconsole.index.label.entry" : "الدخول السريع",
  "agentconsole.quickentrance.information" : "معلومات",
  "SM.LOGIN.LABEL.FRAME_SEARCH_EMPLOYEE" : "استعلام الموظف",
  "SM.LOGIN.LABEL.FORGETPWD_ACCOUNT" : "تسجيل الدخول إلى الحساب",
  "SM.LOGIN.TITLE.UPDATE_PWD_OLDPWD_EMPTY" : "كلمة المرور القديمة فارغة.",
  "agentconsole.modifycustominfo.modifyfailed" : "فشل التعديل.",
  "agentconsole.querycustominfo.msg.specialStrValidateFailed" : "فقط الأحرف الخاصة التالية هي المدعومه: {'@'}.-",
  "provision.menuurl.protocol.check" : "لاحظ أن بروتوكول الشبكة المستخدم بواسطة عنوان القائمة الحالي ليس بروتوكولًا آمنًا. إذا اخترت استخدام بروتوكول الشبكة ، فقد تكون هناك مخاطر أمنية.",
  "agentconsole.dashboard.title.taskpriority" : "قائمة المهام",
  "agentconsole.authmanage.field.auth_name" : "اسم الإذن",
  "agentconsole.inputplaceholder.enter_keyword" : "أدخل كلمة أساسية.",
  "agentconsole.login.indexforipcc.gateway" : "بوابة مفتوحة",
  "SM.LOGIN.TITLE.UNIFIED_CASHER" : "أمين الصندوق الموحد",
  "SM.LOGIN.TITLE.SHOPPING_CART" : "عربة التسوق",
  "SM.LOGIN.LABEL.NOTICE_CREATE_TIME" : "وقت الإنشاء",
  "SM.LOGIN.RESETPWD.EMPTY" : "حساب تسجيل الدخول أو عنوان البريد الإلكتروني فارغ.",
  "agentconsole.header.label.searchhelp" : "البحث في مركز المساعدة",
  "SM.LOGIN.LABEL.NOTICE_CHAN_TYPE" : "نوع القناة",
  "agentconsole.login.label.service_cloud_title" : "سحابة خدمة العملاء",
  "agentconsole.custinfo.title.custinfo" : "معلومات العميل",
  "SM.LOGIN.TITLE.OPERATOR" : "المشغل الحالي",
  "SM.LOGIN.TITLE.SEARCH_CONDITION" : "شرط الاستعلام",
  "agentconsole.history.passwordremainingdays" : "كلمة المرور المتبقية أيام",
  "SM.LOGIN.TIPS.REQUIRED" : "هذه المعلمة إلزامية",
  "SM.LOGIN.LABEL.FORGETPWD_EMAIL" : "البريد الإلكتروني:",
  "SM.LOGIN.LABEL.ADD_FAST_MENU" : "إضافة قائمة اختصارات",
  "agentconsole.menumanage.message.homepagesubnode" : "لا يمكن إضافة قائمة فرعية إلى الصفحة الرئيسية.",
  "agentconsole.login.restpwd.success" : "تم إعادة تعيين كلمة المرور بنجاح.",
  "SM.LOGIN.TITLE.CHOOSE_SELECT_ORG" : "اختر OU",
  "SM.LOGIN.LABEL.HOME" : "الصفحة الرئيسية",
  "SM.LOGIN.TIPS.MODIFY" : "تعديل",
  "agentconsole.modifycustominfo.modifyexternalfailed" : "في الوقت الحالي، لا يمكن تعديل بيانات الطرف الثالث.",
  "agentconsole.authmanage.paneltitle.authmanage" : "إدارة الأذونات",
  "agentconsole.menumanage.label.newmenu" : "قائمة طعام جديدة",
  "SM.LOGIN.MESSAGE.HAVE_LOCK_USER" : "تم قفل المستخدم. قم بإلغاء قفل المستخدم أو انتظر حتى يقوم النظام بإلغاء قفل المستخدم تلقائيًا.",
  "SM.LOGIN.LABEL.RECHARGE" : "تعبئة الرصيد",
  "SM.LOGIN.MESSAGE.UNLOCK" : "هل تريد بالتأكيد قفل النظام؟",
  "agentconsole.querycustominfo.msg.noData" : "لا توجد بيانات",
  "agentconsole.dashboard.title.taskdone" : "عدد المهام التي تمت معالجتها",
  "agentconsole.dashboard.title.web" : "صفحة ويب",
  "agentconsole.workbench.talkavg" : "متوسط مدة المكالمة (بالثانية):",
  "agentconsole.callmessage.setNumerSuccess" : "تم تعيين رقم المعالجة بنجاح.",
  "SM.LOGIN.TITLE.UPDATE_PWD_NEWPWD" : "كلمة المرور الجديدة:",
  "SM.LOGIN.TIPS.ENTER_PROJECT_NAME" : "أدخل اسم المشروع.",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_VERIFY_CODE_NULL" : "رمز التحقق فارغ.",
  "agentconsole.index.label.querycasenew" : "الاستعلام عن حالة العمل (جديد)",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_SWITCH_ES_PREFIX" : "تم تحويل العمل الحالي إلى حالة الطوارئ. يرجى زيارة",
  "agentconsole.login.indexforipcc.thirdpartysystem" : "يمكن تنفيذ أنظمة الطرف الثالث من خلال واجهة متكاملة:",
  "SM.LOGIN.TITLE.REGISTERCODE" : "رقم تسجيل موقع جيانغسو للأمن العام: {0}",
  "SM.LOGIN.LABEL.LOCK" : "قفل الشاشة",
  "agentconsole.workbench.label.callreason" : "سبب المكالمة",
  "SM.LOGIN.TITLE.UPDATE_PWD_OLDPWD_NEWPWD" : "لا يمكن أن تكون كلمة المرور الجديدة مماثلة لكلمة المرور القديمة.",
  "SM.LOGIN.TITLE.NOTICE_MORE" : "المزيد",
  "SM.LOGIN.MESSAGE.CHANGE_PWD_RULE" : "فشل تغيير كلمة المرور لأن كلمة المرور الجديدة لا تتوافق مع مجموعة قواعد كلمة المرور التالية:",
  "SM.LOGIN.RESETPWD.SUCCESS" : "تمت معالجة طلب إعادة تعيين كلمة المرور بنجاح. يرجى التحقق من البريد الإلكتروني.",
  "SM.LOGIN.LABEL.FRAMEWORK_NOTEPAD" : "المفكرة",
  "agentconsole.menumanage.message.notdelbasemenu" : "لا تسمح بحذف القائمة العامة.",
  "agentconsole.quickentrance.newpop" : "إضافة إدخال",
  "agentconsole.login.label.account" : "الحساب",
  "SM.LOGIN.LABEL.NOTICE_TITLE" : "عنوان النشرة",
  "SM.LOGIN.TITLE.FORGETPWD_FINDEMAILBYPWD" : "استرداد كلمات المرور من خلال رسائل البريد الإلكتروني",
  "agentconsole.login.indexforipcc.advantage" : "مزايا المنتج",
  "agentconsole.login.indexforipcc.characteristics" : "خصائص العمل",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_BE_SWITCH_ES_PREFIX" : "تم تحويل المنطقة الحالية إلى حالة الطوارئ. تسجيل الدخول إلى نظام الطوارئ والوصول إلى",
  "agentconsole.management.message.filterYes" : "نعم",
  "agentconsole.Intelligent.robot" : "روبوت ذكي",
  "agentconsole.header.label.servicecloud.home" : "القضية الرئيسية",
  "agentconsole.header.title.news" : "اخبار",
  "SM.LOGIN.TIPS.FIRSTLOGINSYSTEM" : "تسجيل الدخول إلى النظام لأول مرة",
  "SM.LOGIN.MESSAGE.LOGOUT_REASON.SESSION_INVALID" : "انتهت صلاحية الجلسة الحالية. الرجاء تسجيل الدخول مرة أخرى.",
  "agentconsole.sessionmanagement.label.operation" : "العملية",
  "SM.LOGIN.TITLE.UPDATE_PWD" : "تغيير كلمة المرور",
  "agentconsole.workbench.label.callsummary" : "الملخص بعد المكالمة",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_VERIFY_CODE_EXPIRE" : "فشل التحقق من رمز التحقق.",
  "agentconsole.login.label.remember" : "تذكر اسم المستخدم",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_SEND_SMS_SUCCESS" : "تم إرسال رمز التحقق. يرجى التحقق.",
  "agentconsole.workbench.label.usermenu" : "قائمة المستخدم",
  "agentconsole.quickentrance.addentrance" : "إضافة إدخال",
  "agentconsole.history.lastloginsuccess" : "آخر تسجيل دخول ناجح",
  "agentconsole.login.indexforipcc.description" : "كجسر مهم بين المؤسسات والعملاء ، يوفر IPCC (IP) (مركز الاتصال IP) الاتصال الصوتي وخدمة العملاء عبر الإنترنت وقنوات أخرى لتزويد العملاء بالأسئلة وتوجيه الأعمال وخدمات معالجة الشكاوى. استنادًا إلى بيئة الشبكة الحالية ذات النطاق الترددي العالي والسرعة العالية، فإنه يدعم أيضًا ميزات جديدة مثل وكلاء الفيديو والملاحة الصوتية ذاتية الخدمة. وفي الوقت الذي توفر فيه تجربة خدمة جديدة للعملاء، فإنها تستكشف نماذج تسويقية جديدة تعتمد على الفيديو والذكاء الاصطناعي.",
  "SM.LOGIN.LABEL.OPER_ORG" : "OU:",
  "SM.LOGIN.LABEL.USERNAME" : "الحساب",
  "agentconsole.login.label.resetpwd" : "انقر إعادة تعيين. سيتم إرسال كلمة المرور الجديدة إلى صندوق البريد.",
  "agentconsole.contacthistory.contactRecord" : "سجل الاتصال",
  "agentconsole.wordreminder.greetings" : "تحية طيبة",
  "agentconsole.common.expiry.email" : "عنوان البريد الإلكتروني",
  "SM.LOGIN.LABEL.FORGETPWD_FINDPWD" : "استرداد كلمة المرور",
  "agentconsole.modifycustominfo.modifysuccess" : "تم التعديل بنجاح.",
  "agentconsole.common.label.delete" : "حذف",
  "agentconsole.login.label.verifycode.error" : "رمز التحقق غير صحيح.",
  "agentconsole.icontitle.delete" : "حذف",
  "SM.LOGIN.LABEL.LOGIN_CONFIRM_PREFIX" : "يكتشف النظام أن عنوان النظام الحالي ليس عنوان نظام BE المنزلي (",
  "SM.LOGIN.BUTTON.UPDATE_PWD_RULE" : "قاعدة كلمة المرور",
  "SM.LOGIN.TITLE.NOTICE_NUMBER" : "لا ..",
  "agentconsole.icontitle.create_child" : "إضافة مستوى فرعي",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_NOT_SWITCH_ES" : "لم يتم تبديل النظام إلى حالة الطوارئ. الوصول إلى قائمة الإنتاج لتنفيذ العمليات التجارية.",
  "agentconsole.login.label.refresh_verify_code" : "ليس واضحاً، غيّر واحداً آخر",
  "SM.LOGIN.TITLE.OPERATION_FAILED" : "فشلت العملية",
  "agentconsole.login.indexforipcc.difstatus" : "تشير حالة الموظف إلى حالات مختلفة قد يكون فيها ممثل خدمة العملاء بعد تسجيل الدخول إلى الموظف، بما في ذلك \"حالة الخمول\"، \"حالة الحديث\"، \"حالة الانتهاء\"، \"إظهار الحالة مشغول\" و \"حالة الراحة\". من خلال أداء ممثل خدمة العملاء للرد على المكالمة، والمغادرة، تظهر عملية التسلسل مشغول، وما إلى ذلك، لتحقيق حالات عامل التحويل. استنادًا إلى حالة الموظف، يحدد النظام ما إذا كان من الممكن تعيين مكالمة واردة لممثل خدمة العملاء.",
  "SM.LOGIN.LABEL.FORGETPWD_NUMBER" : "رقم الجوال",
  "SM.LOGIN.TITLE.PORTAL_PROJECT_TITLE" : "اختر المشروع",
  "SM.LOGIN.MESSAGE.SWITCHERROR" : "فشل التبديل.",
  "agentconsole.modifycustominfo.nomodifyauth" : "لا يملك المشغل الحالي الإذن بتعديل البيانات المخصصة.",
  "SM.LOGIN.LABEL.RESETPWD.SUCCESS" : "تم إعادة تعيين كلمة المرور بنجاح.",
  "agentconsole.login.indexforipcc.qualitycheck" : "وظائف التحقق من الجودة في الوقت الحقيقي ، مثل الإدراج ، والاعتراض ، والرصد ، إلخ.",
  "agentconsole.authmanage.message.createsubnodefail" : "لا يمكن أن يتجاوز مستوى الإذن 3 مستويات.",
  "agentconsole.authmanage.tab.basic_information" : "معلومات أساسية",
  "SM.LOGIN.LABEL.NOTICE_ROLE" : "دور المستلم",
  "agentconsole.header.label.changelog" : "تغيير السجل",
  "agentconsole.Intelligent.labelname" : "تسجيل الصوت الذكي",
  "SM.LOGIN.BUTTON.CHANGEPWD_CANCEL" : "إلغاء",
  "SM.LOGIN.LABEL.LANG" : "اللغة",
  "agentconsole.iolp.labelname" : "طلب عمل",
  "SM.LOGIN.LABEL.NOTICE_NOTE_TYPE" : "نوع النشرة",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_VERIFY_FAILED" : "فشل التحقق. حاول مرة أخرى لاحقًا.",
  "SM.LOGIN.LABEL.ACTIVITY" : "النشاط التسويقي",
  "agentconsole.login.indexforipcc.idle" : "يمكن للنظام فقط تعيين المكالمات الواردة إلى الموظفين الموجودين في \"حالة خامل\".",
  "agentconsole.dashboard.enumeration.otherday" : "تاريخ آخر",
  "SM.LOGIN.TITLE.SELECTBE" : "اختر BE",
  "SM.LOGIN.TITLE.SERACH_MENU" : "أدخل اسم القائمة.",
  "agentconsole.callreason.tips.saved" : "تم حفظ سبب المكالمة.",
  "agentconsole.dashboard.evaluation.high" : "5 نقاط",
  "SM.LOGIN.TITLE.CHOOSE_ORG_LIST" : "يرجى تحديد منظمة.",
  "agentconsole.login.indexforipcc.mute" : "كتم الصوت",
  "SM.LOGIN.LABEL.CUS_LOGIN_REDIRECT" : "تسجيل الدخول والقفز إلى",
  "SM.LOGIN.TITLE.SELECT_ACCOUNT_TYPE" : "حدد نوع الحساب.",
  "SM.LOGIN.TITLE.PRINT_FREE_FILLING" : "طباعة ACK زلة",
  "agentconsole.sessionmanagement.label.search" : "استعلام",
  "agentconsole.menumanage.message.menu_url" : "لا يمكن أن يكون عنوان القائمة صينيًا.",
  "SM.LOGIN.LABEL.CHANGEPWD_COMFIRMPWD" : "تأكيد كلمة المرور",
  "SM.LOGIN.LABEL.NOTICE_CREATOR" : "خالق",
  "agentconsole.index.label.chat" : "خدمة العملاء عبر الإنترنت",
  "agentconsole.login.indexforipcc.title" : "مركز الاتصال السحابي",
  "agentconsole.authmanage.rule.tips" : "الرجاء تحديد إذن قمت بتخصيصه. لا يمكن تحديد الإذن المحدد مسبقًا بواسطة النظام.",
  "agentconsole.dashboard.title.casedone" : "عدد الحالات التي تمت معالجتها",
  "SM.LOGIN.BUTTON.FORGETPWD_EMAIL" : "البريد الإلكتروني",
  "SM.SERVLET.BATCH.RESET.PASSWORD.EMPLISTEND" : "]",
  "agentconsole.login.indexforipcc.lightweight" : "خفيفة الوزن",
  "agentconsole.Intelligent.close" : "إغلاق",
  "agentconsole.dashboard.title.interactioncount" : "إجمالي كمية الاتصال في اليوم الحالي:",
  "agentconsole.header.label.searchcommunity" : "البحث في المجتمع",
  "agentconsole.modifycustominfo.noqueryauth" : "المشغل الحالي ليس لديه الإذن للاستعلام عن البيانات المخصصة.",
  "SM.LOGIN.TITLE.HELPCENTER" : "مركز المساعدة",
  "agentconsole.common.tips.warning" : "تحذير",
  "casemanagement.casequery.button.create" : "إنشاء حالة",
  "agentconsole.login.indexforipcc.hold" : "تعليق المكالمة",
  "agentconsole.contacthistory.talking" : "جلسة صوتية",
  "agentconsole.menumanage.tab.basic_information" : "معلومات أساسية",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_VERIFY_CODE_MINTIME_ERROR" : "تم إنشاء رمز التحقق.",
  "agentconsole.contacthistory.readMore" : "شاهد المزيد",
  "SM.LOGIN.RESETPWD.EMAILPATTERN_ERROR" : "عنوان بريد إلكتروني غير صالح.",
  "agentconsole.quickentrance.deleteinfo" : "هل تريد بالتأكيد حذف هذا الدليل",
  "agentconsole.authmanage.message.deleteauthfail" : "فشل حذف الإذن. يرجى التحقق مما إذا كان الإذن قد تمت الإشارة إليه من قبل أي قائمة أو إعطاء أي دور.",
  "SM.LOGIN.TITLE.NONLOCAL_USER_CHANEPWD_ERROR" : "لا يمكن لمستخدم غير محلي تغيير كلمة المرور.",
  "agentconsole.login.indexforipcc.onepoint" : "يمكن لموظفي خدمة العملاء التحقق من النظام الأساسي أو الرد على المكالمات الواردة أو تحويل المكالمات أو كتم الصوت أو تبديل الحالة أو المساعدة.",
  "agentconsole.login.indexforipcc.loginportal" : "قم بتسجيل الدخول إلى بوابة خدمة العملاء السحابية.",
  "SM.LOGIN.BUTTON.UPDATE_PWD_CLOSE" : "إغلاق",
  "agentconsole.common.button.cancel" : "إلغاء",
  "SM.LOGIN.BUTTON.LOGIN_CONFIRM_CONTINUE" : "متابعة",
  "SM.LOGIN.TITLE.COPYRIGHT" : "حقوق النشر {0} {1} Huawei Software Technologies Co., Ltd. جميع الحقوق محفوظة.",
  "agentconsole.menumanage.message.createfailures" : "فشل إنشاء قائمة",
  "SM.INDEX.LABEL.CASEDEC" : "يمكنك إنشاء حالة لكل طلب خدمة من عميلك وتتبع دورة الحياة الكاملة لمعالجة طلب خدمة العملاء من خلال الحالة ، وضمان إغلاق كل طلب عميل وتحسين رضا العملاء.",
  "agentconsole.menu.title" : "منضدة الصوت والفيديو",
  "agentconsole.menumanage.message.sysadmincannotaddmenu" : "لا يمكن لمسؤول النظام الحالي إنشاء قائمة.",
  "SM.LOGIN.MESSAGE.UNLOCK_FAILED" : "فشل إلغاء قفل الحساب. أدخل كلمة المرور الصحيحة.",
  "agentconsole.authmanage.label.newauth" : "إذن جديد",
  "agentconsole.login.indexforipcc.difplat" : "من السهل الاندماج في منصات مختلفة دون تناول الصفحات الرئيسية للبوابة ومنضدة العمل",
  "agentconsole.callmessage.calledNo" : "تم الاتصال",
  "agentconsole.sessionmanagement.input.validatorMessage" : "لا يُسمح بالأحرف الخاصة باستثناء علامات (_), وعلامات (.),.",
  "agentconsole.index.label.case" : "القضية",
  "agentconsole.login.indexforipcc.auth" : "التحكم في الوصول",
  "agentconsole.dashboard.priority.medium" : "وسيطة ذات أولوية",
  "agentconsole.header.title.send" : "إرسال رسالة داخلية",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_BE_SWITCH_ES" : "تم تحويل المنطقة الحالية إلى حالة الطوارئ. لا يمكن التعامل مع الأعمال إلا بعد استرداد النظام. تسجيل الدخول إلى نظام الطوارئ للتعامل مع الشركات الأخرى.",
  "agentconsole.ccnotification.not.currently.on.call" : "غير متصل حاليًا بمكالمة",
  "SM.LOGIN.BUTTON.LOGIN_CONFIRM_CANCEL" : "إلغاء",
  "SM.LOGIN.BUTTON.SMS_RECONFIRM_CONFIRM" : "حسناً",
  "agentconsole.authmanage.message.selectanauth" : "يرجى تحديد إذن.",
  "SM.LOGIN.LABEL.LOGIN_CONFIRM_CONTINUE" : "متابعة الوصول إلى النظام الحالي",
  "SM.LOGIN.TITLE.INFO" : "موجه",
  "agentconsole.workbench.label.remarks" : "ملاحظات",
  "SM.LOGIN.BUTTON.CANCEL" : "إلغاء",
  "agentconsole.sessionmanagement.table.fail.delete" : "فشل الحذف",
  "SM.LOGIN.TITLE.CHANGEPWD_RULE" : "يجب أن تتوافق كلمة المرور الجديدة مع القواعد التالية:",
  "SM.LOGIN.BUTTON.FORGETPWD_SMS" : "SMS",
  "SM.LOGIN.MESSAGE.LOGOUT_REASON.FORCE_CHANGE_PASSWORD" : "قم بتغيير كلمة المرور بعد تسجيل الدخول.",
  "SM.LOGIN.TITLE.CURRENT_SELECTED_PROJECT" : "المحدد:",
  "SM.LOGIN.LABEL.FRAMEWORK_AD_DEMAND" : "الإعلان عند الطلب",
  "SM.LOGIN.LABEL.CHANGEPWD_NEWPWD" : "كلمة مرور جديدة",
  "SM.LOGIN.LABEL.NOTICE_GRADE_URGENT" : "راش",
  "agentconsole.management.message.filterNo" : "لا",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_BIND_INFO_NULL" : "حساب تسجيل الدخول أو معلومات الربط فارغة.",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_SEND_SMS_FAILED" : "فشل إرسال الرسالة النصية. تحقق من سجل الخلفية.",
  "SM.LOGIN.MESSAGE.REPEAT_SUBMITED" : "تم إرسال الطلب. الرجاء الانتظار...",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_VERIFY_CODE_DATE_INVALID" : "فشل التحقق من رمز التحقق.",
  "SM.LOGIN.TITLE.UPDATE_PWD_NEWPWD_CONFIRMPWD" : "يجب أن تكون كلمة المرور الجديدة مماثلة لتأكيد كلمة المرور.",
  "agentconsole.dashboard.title.unassigned" : "غير معين",
  "SM.LOGIN.MESSAGE.NONLOCAL_USER_RETRIEVEPASSWORD_FAILED" : "فشل استرداد كلمة المرور. لا يمكن للمستخدمين غير المحليين استخدام وظيفة استرداد كلمة المرور.",
  "SM.LOGIN.TIPS.SAVE" : "حفظ",
  "agentconsole.dashboard.title.interactionperday" : "كمية الاتصال اليومية",
  "SM.LOGIN.BUTTON.UPDATE_PWD_MODIFY_SUCCESS" : "تم التعديل بنجاح.",
  "SM.LOGIN.TITLE.PASSWORD_INIT" : "أنت تستخدم كلمة المرور الأولية. قم بتغيير كلمة المرور على الفور.",
  "SM.PORTAL.LABEL.INPUT_CFM_PWD" : "قم بتأكيد كلمة المرور الجديدة.",
  "SM.LOGIN.TITLE.CHANGEPWD_SUCCESS" : "موجه",
  "SM.LOGIN.LABEL.COPYRIGHT" : "حقوق النشر محفوظة لشركة Huawei Technologies Co., Ltd. 2018.",
  "agentconsole.dashboard.title.taskcreate" : "عدد المهام التي تم إنشاؤها",
  "SM.LOGIN.TITLE.PASSWORD_WILLEXPIRED" : "كلمة المرور الخاصة بك على وشك الانتهاء. يُرجى تغيير كلمة المرور.",
  "SM.LOGIN.BUTTON.QUERY" : "استعلام",
  "agentconsole.dashboard.title.task" : "نظرة عامة على المهمة",
  "SM.LOGIN.LABEL.AUTH_FAILED" : "حساب أو كلمة مرور غير صحيحة.",
  "agentconsole.login.restpwd.fail" : "فشلت إعادة تعيين كلمة المرور.",
  "agentconsole.login.label.reset" : "إعادة الضبط",
  "SM.LOGIN.TIPS.LASTLOGINTIME" : "وقت آخر تسجيل دخول:",
  "SM.LOGIN.LABEL.SYSTEM_ERROR" : "خطأ في النظام.",
  "agentconsole.dashboard.priority.high" : "أولوية عالية",
  "agentconsole.login.label.username" : "اسم المستخدم",
  "SM.LOGIN.TITLE.NOTICE_LIST" : "قائمة النشرات",
  "agentconsole.index.label.chatdec" : "أثناء محادثة متعددة الوسائط عبر الإنترنت مع عميلك، يمكنك عرض معلومات الاتصال التاريخية الشاملة للعميل لزيادة كفاءة الخدمة وتوفير تجربة خدمة Omnichannel لعميلك.",
  "agentconsole.ccnotification.has.been.sent" : "تم إرساله",
  "SM.LOGIN.TITLE.UPDATE_PWD_MODIFY_FAILED" : "فشل التعديل",
  "SM.LOGIN.TITLE.BE_CATALOG" : "كتالوج BE:",
  "agentconsole.index.label.casedraft" : "بلدي مشروع الحالات",
  "agentconsole.login.label.forgot" : "نسيت كلمة المرور؟",
  "agentconsole.login.indexforipcc.opengateway" : "بوابة مفتوحة",
  "SM.LOGIN.TITLE.SMS_RECONFIRM" : "إعادة تأكيد رسالة SMS",
  "agentconsole.callmessage.title" : "رسالة الاتصال",
  "agentconsole.sessionmanagement.table.success.delete" : "نجاح الحذف",
  "agentconsole.quickentrance.deleteclass" : "حذف هذا الدليل",
  "agentconsole.login.indexforipcc.thirdparty" : "الاتصال ثلاثي الاتجاه",
  "agentconsole.sessionmanagement.table.deleteconfim" : "تأكيد الحذف",
  "SM.LOGIN.LABEL.FAST_ENTER" : "الدخول السريع",
  "SM.LOGIN.TITLE.UPDATE_PWD_LOGINID" : "الحساب:",
  "agentconsole.login.indexforipcc.sequence" : "الاستعلام عن معلومات قائمة الانتظار",
  "agentconsole.menumanage.field.url" : "عنوان URL للقائمة",
  "agentconsole.login.indexforipcc.statecontrol" : "التحكم في حالة الاتصال",
  "agentconsole.login.indexforipcc.signin" : "يقوم موظفو خدمة العملاء الذين يحتاجون إلى التعامل مع المكالمات الصوتية بتسجيل الوصول إلى النظام الأساسي لـ CTI.",
  "agentconsole.common.expiry.suffix" : "يرجى الانتباه إلى ذلك. إذا كنت ترغب في الاستمرار في استخدامه، اتصل بموظفي العملية.",
  "agentconsole.login.indexforipcc.answer" : "الرد على المكالمة",
  "agentconsole.authmanage.message.notdelnoleafauth" : "لا تسمح بحذف الإذن مع الأذونات الفرعية.",
  "SM.LOGIN.TITLE.APPEND_MENU_SEARCH" : "معايير البحث:",
  "agentconsole.quickentrance.managemententrance" : "إدارة المداخل",
  "agentconsole.authmanage.label.authtype.operation" : "إذن العملية",
  "SM.LOGIN.TITLE.APPEND_MENU_TITLE" : "إضافة قائمة اختصارات",
  "agentconsole.dashboard.title.todo" : "قائمة المهام",
  "agentconsole.callmessage.callskill" : "قائمة انتظار المهارات",
  "agentconsole.sessionmanagement.tip.error" : "خطأ",
  "SM.CHANGELANGAUGE.REFRESH.MESSAGE" : "سيتم إعادة تحميل الصفحة بأكملها بعد تبديل اللغة. هل أنت متأكد من رغبتك في المتابعة؟",
  "SM.LOGIN.TITLE.UPDATE_PWD_OLDPWD" : "كلمة المرور القديمة:",
  "agentconsole.faqwordreminder.norecommend" : "لا توجد أسئلة شائعة موصى بها",
  "SM.LOGIN.MESSAGE.SELECT_PROJECT" : "اختر مشروعًا.",
  "agentconsole.icontitle.create_sibling" : "إضافة مستوى نظير",
  "SM.LOGIN.LABEL.PASSWORD" : "كلمة المرور",
  "agentconsole.callmessage.faq.reminder" : "الأسئلة الشائعة الموصى بها",
  "SM.SERVLET.BATCH.RESET.PASSWORD.EMPLIST" : "إعادة تعيين تأجير كلمات مرور المستخدم في دفعات: [قائمة الموظفين:",
  "iolp.workflow.title.name" : "عملية الأعمال",
  "SM.LOGIN.MESSAGE.CHANGEPWD_SUCCESS" : "تم تغيير كلمة المرور بنجاح.",
  "agentconsole.login.indexforipcc.opengatewayde" : "توفر البوابة المفتوحة واجهة قائمة على بروتوكول RESTful قياسية لوظيفة المكالمة الصوتية في سحابة خدمة العملاء الذكية المتكاملة للنظام الثالث. يمكن لنظام الطرف الثالث تنفيذ مركز الاتصال في نظامه الخاص عن طريق شراء الخدمة دون شراء معدات مركز الاتصال الخاصة به. القدرة.",
  "agentconsole.iolp.entrancename" : "إرشادات الخدمة",
  "agentconsole.title.allchat" : "كافة المحتويات",
  "SM.LOGIN.MESSAGE.MENU_ACCESS_TIME_NOT_MATCH" : "الوقت الحالي ليس ضمن وقت الوصول المسموح به من قبل القائمة. وقت الوصول إلى القائمة هو كما يلي:",
  "SM.LOGIN.LABEL.SETTINGS" : "الإعدادات",
  "agentconsole.menumanage.field.menu_name" : "اسم القائمة",
  "agentconsole.common.button.save" : "حفظ",
  "agentconsole.login.restpwd.errorinfo" : "اسم المستخدم أو عنوان البريد الإلكتروني غير صحيح.",
  "SM.LOGIN.BUTTON.OK" : "حسناً",
  "SM.LOGIN.TIPS.LOCK" : "قفل الشاشة",
  "SM.LOGIN.LABEL.NOTICE_GRADE_EXTRA_URGENT" : "عاجل",
  "SM.INDEX.LABEL.TASKDEC" : "أثناء معالجة الحالة ، يمكنك إرسال المهام للعمل مع العديد من الإدارات والموظفين. يمكن إنشاء مهام متعددة لحالة.",
  "agentconsole.common.tips.information" : "نصائح",
  "agentconsole.dashboard.title.taskperday" : "حالة المعالجة اليومية",
  "agentconsole.authmanage.message.notdelbaseauth" : "لا تسمح بحذف الإذن العام.",
  "agentconsole.header.label.home" : "الصفحة الرئيسية",
  "agentconsole.custinfo.label.custId" : "معرف العميل",
  "agentconsole.ccnotification.not.currently.on.three" : "لا يمكن بدء العملية في حالة المكالمة الحالية",
  "agentconsole.menumanage.message.deletemenufail" : "فشل حذف القائمة.",
  "agentconsole.dashboard.enumeration.today" : "اليوم",
  "SM.LOGIN.LABEL.ERRORPAGE_ERRORNODETAIL_OPEN" : "التفاصيل<",
  "SM.LOGIN.LABEL.ERRORPAGE_ERRORSTACK" : "تكديس الأخطاء:",
  "agentconsole.header.title.search" : "البحث",
  "agentconsole.dashboard.priority.low" : "أولوية منخفضة",
  "agentconsole.wordreminder.recommend" : "البرنامج النصي الموصى به",
  "agentconsole.quickentrance.complete" : "مكتمل",
  "SM.LOGIN.LABEL.FINISH" : "إنهاء",
  "SM.LOGIN.TIPS.LOGINIP" : "عنوان IP:",
  "SM.LOGIN.MESSAGE.GRAFIC.VERIFY.CODE.REFRESH" : "ليس واضحاً، غيّر واحداً آخر",
  "SM.LOGIN.LABEL.RESET" : "إعادة الضبط",
  "SM.LOGIN.LABEL.LOGOUT" : "تسجيل الخروج",
  "agentconsole.authmanage.message.deleteconfirm" : "هل تريد بالتأكيد حذف الإذن؟",
  "SM.LOGIN.TITLE.UPDATE_PWD_CONFIRMPWD_EMPTY" : "تأكيد كلمة المرور فارغ.",
  "agentconsole.contacthistory.oneMinAgo" : "قبل دقائق",
  "agentconsole.contacthistory.hourAgo" : "قبل ساعات",
  "SM.LOGIN.TITLE.UPDATE_PWD_OLDPWD_ERROR" : "كلمة المرور القديمة غير صحيحة.",
  "agentconsole.login.indexforipcc.useage" : "توفر سحابة خدمة العملاء الذكية إطار عمل مقعد يدعم قدرات الإيجار المتعدد، مما يتيح فتح الوكلاء، والجمع بين منصات هواوي CTI وUAP لتزويد العملاء بإمكانات الخدمة الصوتية/الفيديو السحابية الكاملة.",
  "agentconsole.dashboard.title.caseunassigned" : "غير معين",
  "SM.LOGIN.TITLE.PORTAL_SWITCH_CONTEXTDATA" : "المحول BE",
  "SM.ORGSTAFF.LABEL.ORG_FORBID_TIPS" : "هل تريد بالتأكيد تعطيل المؤسسة؟",
  "agentconsole.ccnotification.max.recipients" : "لا يزيد عن 5 متلقين",
  "SM.LOGIN.LABEL.NOTICE_STATUS" : "الحالة",
  "SM.LOGIN.BUTTON.UPDATE_PWD_CANCEL" : "إلغاء",
  "agentconsole.login.label.errorinfo" : "اسم المستخدم أو كلمة المرور غير صحيحة.",
  "SM.ORGSTAFF.TITLE.ASSIGNE_MANAGER" : "تعيين مدير",
  "agentconsole.ccnotification.recipient.cannot.empty" : "لا يمكن أن يكون المستلم فارغًا",
  "SM.LOGIN.LABEL.LOCKED" : "مغلق",
  "agentconsole.dashboard.title.mobile" : "محطة متنقلة",
  "agentconsole.authmanage.message.sysauthsubnode" : "لا يمكن إضافة إذن فرعي إلى \"إدارة النظام\".",
  "SM.LOGIN.TITLE.SWITCH_ESOP" : "بنقرة واحدة التبديل إلى ESOP",
  "agentconsole.quickentrance.all" : "الكل",
  "agentconsole.workbench.label.userinfo" : "معلومات المستخدم",
  "agentconsole.dashboard.enumeration.thressdays" : "آخر 3 أيام",
  "SM.LOGIN.LABEL.CANCEL" : "إلغاء",
  "agentconsole.login.label.service_cloud_desc" : "واضح واجهة المستخدم للخدمة في نهاية المطاف",
  "agentconsole.modifycustominfo.nodatamodify" : "لم يتم تغيير أي بيانات.",
  "SM.LOGIN.TITLE.NOTICE_CONTENT" : "المحتوى",
  "SM.AUC.MESSAGE.PWDRULE_YES" : "نعم",
  "agentconsole.callmessage.setNumerLengthError" : "لا يمكن أن يتجاوز رقم المعالجة 24 حرفًا.",
  "agentconsole.contacthistory.dayAgo" : "قبل أيام",
  "agentconsole.login.indexforipcc.agentstate" : "التحكم في حالة الوكيل ، مثل الوكيل مشغول ، والترفيه ، والراحة ، إلخ.",
  "SM.LOGIN.TIPS.LOGOUT" : "تم تسجيل الخروج من النظام.",
  "SM.LOGIN.MESSAGE.CHANGEPWD_OLDNEW" : "يجب أن تكون كلمة المرور الجديدة مختلفة عن كلمة المرور القديمة.",
  "SM.LOGIN.LABEL.OPER_NAME" : "اسم المستخدم:",
  "SM.LOGIN.LABEL.UPDATE_PWD" : "تغيير كلمة المرور",
  "SM.LOGIN.LABEL.NOTICE_GRADE" : "الأولوية",
  "agentconsole.header.title.case" : "إنشاء حالة",
  "SM.ORGSTAFF.MESSAGE.STAFF_DELETE_CONFIRM" : "بعد حذف الموظف، لا يمكن استعادة الحساب المقابل للموظف ويمكن إعادة إنشائه فقط. حذف أم لا؟",
  "agentconsole.quickentrance.title.toomanymenus" : "تم تجاوز عدد القوائم المختصرة. يرجى تقليل عدد القوائم المختصرة وحفظ الإعدادات مرة أخرى.",
  "SM.LOGIN.MESSAGE.SENDMAIL_SUCCESS" : "تم إرسال كلمة المرور الجديدة إلى عنوان بريدك الإلكتروني. يُرجى استخدام كلمة المرور الجديدة لتسجيل الدخول.",
  "SM.ORGSTAFF.TITLE.OPERATION" : "العملية",
  "SM.LOGIN.TIPS.LOGINFAILED" : "فشل تسجيل الدخول.",
  "agentconsole.dashboard.enumeration.thirtydays" : "آخر 30 يوم",
  "agentconsole.menumanage.message.deleteconfirm" : "هل تريد بالتأكيد حذف القائمة؟",
  "agentconsole.login.indexforipcc.easyuse" : "سهل التشغيل",
  "SM.LOGIN.TIPS.WELCOME_LABEL" : "تحية:",
  "agentconsole.dashboard.title.total" : "المجموع",
  "SM.LOGIN.LABEL.EMERGENCY_NOTICE" : "إشعار الطوارئ",
  "agentconsole.login.indexforipcc.deal" : "يمكن لموظفي خدمة العملاء التعامل مع مكالمة العميل على النحو التالي",
  "agentconsole.login.label.cancel" : "إلغاء",
  "SM.LOGIN.MESSAGE.SENDSMS_SUCCESS" : "تم إرسال كلمة المرور الجديدة إلى هاتفك الجوال. يُرجى استخدام كلمة المرور الجديدة لتسجيل الدخول.",
  "agentconsole.menu.config.center" : "مركز التكوين",
  "agentconsole.login.restpwd.emailpattern_error" : "عنوان بريد إلكتروني غير صالح.",
  "message.center.message.messageManage" : "إدارة الرسائل الداخلية",
  "agentconsole.index.label.dashboard" : "لوحة التحكم",
  "agentconsole.menumanage.field.auth" : "إذن القائمة",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_SWITCH_ES_SUFFIX" : "الاستمرار في إدارة الأعمال.",
  "agentconsole.login.indexforipcc.release" : "الإفراج",
  "agentconsole.dashboard.title.chat" : "نظرة عامة على الدردشة عبر الإنترنت",
  "agentconsole.index.label.querytask" : "الاستعلام عن المهمة",
  "SM.LOGIN.TITLE.NOTICE_CRUM" : "إدارة النظام > صيانة النظام > استعلام النشرة",
  "agentconsole.sessionmanagement.tip.success" : "النجاح",
  "agentconsole.header.title.help" : "مساعدة",
  "agentconsole.login.indexforipcc.calldata" : "الاستعلام عن بيانات المكالمة",
  "agentconsole.header.title.send.email.notification" : "رسالة إعلام بالبريد الصادر",
  "agentconsole.dashboard.enumeration.yesterday" : "أمس",
  "SM.LOGIN.LABEL.FRAMEWORK_CALENDAR" : "التقويم",
  "SM.LOGIN.LABEL.FORGOTPASSWORD" : "هل نسيت كلمة المرور؟",
  "SM.LOGIN.BUTTON.LOGIN" : "تسجيل الدخول",
  "agentconsole.contacthistory.nocontactinfo" : "لا يوجد تاريخ تفاعل",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_BE_SWITCH_ES_SUFFIX" : "القائمة للتعامل مع الأعمال.",
  "SM.LOGIN.MESSAGE.CONFIRM" : "تأكيد",
  "SM.LOGIN.MESSAGE.LOGOUT_REASON.NO_LOGIN" : "لم تقم بتسجيل الدخول. يُرجى تسجيل الدخول والمحاولة مرة أخرى.",
  "SM.LOGIN.TITLE.COMMONTOOLS" : "الأدوات الشائعة",
  "agentconsole.login.label.verifycode.empty" : "رمز التحقق فارغ.",
  "agentconsole.dashboard.title.wechat" : "WeChat",
  "SM.LOGIN.LABEL.NOTICE_INCLUDE_SUB_ORG_Y" : "نعم",
  "SM.LOGIN.LABEL.VCODE_EMPTY" : "رمز التحقق فارغ.",
  "SM.LOGIN.TITLE.DROPLIST_MENU" : "القائمة",
  "agentconsole.contact.customerInfo" : "معلومات العميل",
  "SM.LOGIN.LABEL.NOTICE_INCLUDE_SUB_ORG_N" : "لا",
  "agentconsole.history.lastloginfail" : "آخر فشل في تسجيل الدخول",
  "agentconsole.history.logintime" : "التاريخ",
  "agentconsole.menumanage.paneltitle.menumanage" : "إدارة القائمة",
  "agentconsole.dashboard.title.evaluation" : "رضا العملاء",
  "SM.LOGIN.RESETPWD.ERRORINFO" : "حساب تسجيل الدخول أو عنوان البريد الإلكتروني غير صحيح.",
  "agentconsole.modifycustominfo.modifyfailed.validate": "فشل التعديل، لا يتوافق مع قاعدة سلسلة الأحرف أو الطول",
  "agentconsole.modifycustominfo.modifyfailed.validate.num": "فشل التعديل، لا يتوافق مع قواعد الأرقام أو الطول",
  "agentconsole.modifycustominfo.modifyfailed.validate.date":"فشل التعديل، تنسيق التاريخ غير صحيح",
  "agentconsole.modifycustominfo.modifyfailed.validate.time":"فشل التعديل، تنسيق التاريخ والوقت غير صحيح",
  "agentconsole.callmessage.query.external.custom.info": "فشل الاستعلام عن معلومات مخصصة خارجية. اتصل بموظفي التشغيل والصيانة لعرض السجلات",
  "WEB.VALIDATOR.RANGE":"أدخل قيمة بين {0} و{1}.",
  "agentconsole.voicetransfer.user" : "عميل",
  "agentconsole.voicetransfer.agent" : "موظف",
  "agentconsole.title.transcription" : "النسخ",
  "agentconsole.title.agent.overview" : "نظرة عامة على الموظف",
  "agentconsole.agent.assistant.recomend.history" : "محفوظات التوصية",
  "agentconsole.agent.assistant.faq.history" : "تاريخ الأسئلة الشائعة",
  "agentconsole.agent.assistant.auto.create.case" : "إنشاء حالة تلقائيًا",
  "agentconsole.agent.assistant.recomend.action" : "الإجراء الموصى به",
  "agentconsole.agent.assistant.summary" : "إنشاء ملخص",
  "agentconsole.agent.assistant.case" : "إنشاء حالة",
  "agentconsole.agent.refine.simple" : "بسيط",
  "agentconsole.agent.refine.formal" : "رسمي",
  "agentconsole.agent.refine.friendly" : "ودية",
  "agentconsole.agent.refine.humorous" : "روح الدعابة",
  "agentconsole.agent.refine" : "صقل",
  "agentconsole.agent.please.notice" : "يرجى الانتباه إلى الكلمات",
  "agentconsole.agent.knowledge.recommend" : "توصية المعرفة",
  "agentconsole.agent.complaint.risk" : "مخاطر الشكوى",
  "agentconsole.agent.call.context" : "سياق المكالمة",
  "agentconsole.agent.customer.info" : "معلومات العميل",
  "agentconsole.agent.session.monitor" : "مراقبة الجلسة",
  "agentconsole.agent.basic.info" : "معلومات أساسية",
  "agentconsole.agent.customer.name" : "اسم العميل",
  "agentconsole.agent.phone.number" : "رقم الهاتف المحمول",
  "agentconsole.agent.service.duration" : "مدة الوصول إلى الشبكة",
  "agentconsole.agent.customer.brand" : "العلامة التجارية",
  "agentconsole.agent.customer.status" : "حالة المستخدم",
  "agentconsole.agent.customer.address" : "منطقة المنزل",
  "agentconsole.agent.bussiness.info" : "معلومات الخدمة",
  "agentconsole.agent.order.status" : "حالة الاشتراك",
  "agentconsole.agent.business.usage" : "استخدام الخدمة",
  "agentconsole.agent.terminal.profile" : "معلومات المحطة الطرفية",
  "agentconsole.agent.terminal.vendor" : "بائع المحطة الطرفية",
  "agentconsole.agent.terminal.model" : "طراز المحطة الطرفية",
  "agentconsole.agent.network.standard" : "معيار الشبكة",
  "agentconsole.agent.generate.summary": "إنشاء ملخص",
  "agentconsole.agent.summary": "ملخص",
  "agentconsole.agent.submit.success": "تم الإرسال بنجاح",
  "agentconsole.agent.intelligent.fill": "ملء بطاقة المشكلة بذكاء",
  "agentconsole.agent.intelligent.click": "ملء الطلب بنقرة واحدة",
}