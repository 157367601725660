export default {
  "ccmessaging.chat.chat.closeDialog":"Möchten Sie es wirklich schließen?",
  "ccmessaging.chat.silentagent.config.add.error":"Die Konfiguration des Agenten für Sprachstillstand konnte nicht erstellt werden.",
  "ccmessaging.chat.silentagent.config.add.success":"Die Konfiguration des Agenten für Sprachstille war erfolgreich.",
  "ccmessaging.chat.fiveGCard.add":"5G-Karte erstellen",
  "ccmessaging.chat.fiveGCard.edit":"5G-Karte bearbeiten",
  "ccmessaging.chat.fiveGCarouselCard.add":"Erstellen Sie eine 5G-Karussellkarte",
  "ccmessaging.chat.fiveGCarouselCard.edit":"Eine 5G-Karussellkarte bearbeiten",
  "ccmessaging.chat.webCard.add":"Eine Webkarte erstellen",
  "ccmessaging.chat.webCard.edit":"Webkarte bearbeiten",
  "ccmessaging.chat.coBrowseSite.add":"Erstellen einer Adresse für die Zusammenarbeit auf der Webseite",
  "ccmessaging.chat.coBrowseSite.edit":"Bearbeiten einer Adresse für die Zusammenarbeit auf einer Webseite",
  "ccmessaging.chat.richTextPage.add":"Rich-Text erstellen",
  "ccmessaging.chat.richTextPage.edit":"Rich-Text bearbeiten",
  "ccmessaging.chat.chatPhrase.add":"Geläufige Sätze erstellen",
  "ccmessaging.chat.chatPhrase.edit":"Geläufige Sätze von Eidt",
  "ccmessaging.chat.audioLibrary.add":"Eine Stimme kreieren",
  "ccmessaging.chat.audioLibrary.edit":"Stimme bearbeiten",
  "ccmessaging.chat.videoLibrary.add":"Ein Video erstellen",
  "ccmessaging.chat.videoLibrary.edit":"Video bearbeiten",
  "ccmessaging.chat.imgLibrary.add":"Ein Bild erstellen",
  "ccmessaging.chat.imgLibrary.edit":"Ein Bild bearbeiten",
  "ccmessaging.chat.emoLibrary.add":"Persönliche Emoticons erstellen",
  "ccmessaging.chat.emoLibrary.edit":"Persönliche Emoticons bearbeiten",
  "ccmessaging.chat.docLibrary.add":"Ein Dokument erstellen",
  "ccmessaging.chat.docLibrary.edit":"Dokument bearbeiten",
  "ccmessaging.chat.locate.add":"Adresse hinzufügen",
  "ccmessaging.chat.locate.edit":"Adresse bearbeiten",
  "ccmesaging.chat.agentconsole.commom.label":"Tag",
  "ccmesaging.chat.agentconsole.label.callReason":"Anrufgrund",
  "ccmesaging.chat.agentconsole.label.commonreply":"Häufige Antworten",
  "ccmesaging.chat.agentconsole.label.expression":"Emoji",
  "ccmesaging.chat.agentconsole.label.fontstyle":"Schriftart",
  "ccmesaging.chat.agentconsole.label.historicalcontact":"Historischer Kontakt",
  "ccmesaging.chat.agentconsole.label.incallmessage":"Nachricht Eingehender Anruf",
  "ccmesaging.chat.agentconsole.label.Realtimetransfer":"Echtzeitübersetzung",
  "ccmesaging.chat.agentconsole.label.robotChat":"Dialog mit Chatbot",
  "ccmesaging.chat.agentconsole.label.screenshot":"Bildschirmfotos",
  "ccmesaging.chat.agentconsole.label.seeMore":"Weitere anzeigen",
  "ccmesaging.chat.agentconsole.label.service":"Dienstleistung",
  "ccmesaging.chat.agentconsole.label.uploadattachment":"Anhang hochladen",
  "ccmesaging.chat.agentconsole.label.uploadimage":"Bild hochladen",
  "ccmesaging.chat.agentconsole.title.release":"Freigabe",
  "ccmesaging.chat.cobrowse.accept":"Akzeptieren",
  "ccmesaging.chat.cobrowse.agentMax":"Der Agent arbeitet derzeit auf der Webseite zusammen. Bitte versuchen Sie es erneut.",
  "ccmesaging.chat.cobrowse.isAcceptWeb":"Möchten Sie die Einladung zum gemeinsamen Surfen wirklich annehmen?",
  "ccmesaging.chat.cobrowse.isAcceptWebMax":"Die maximale Anzahl der vom aktuellen Mandanten erlaubten Co-Browsing-Verbindungen wurde überschritten.",
  "ccmesaging.chat.cobrowse.noWebSiteAuth":"Der Agent verfügt nicht über die Berechtigung zum Co-Browsen.",
  "ccmesaging.chat.cobrowse.refused":"Verweigern",
  "ccmesaging.chat.cobrowse.title":"Einladung zum gemeinsamen Surfen",
  "ccmesaging.chat.cobrowse.webSite":"URL für gemeinsames Browsen:",
  "ccmesaging.chat.cobrowse.webSiteButton":"Ko-Browsen",
  "ccmesaging.chat.cobrowse.webSitePlaceholder":"Geben Sie die Freigabe-URL ein.",
  "ccmessaging.address.protocol.check":"Das von der Adresse verwendete Netzwerkprotokoll ist nicht sicher. Die Verwendung dieses Protokolls kann Sicherheitsrisiken bergen.",
  "ccmessaging.channelconfig.algorithm":"Verbindungsverschlüsselungsalgorithmus",
  "ccmessaging.channelconfig.email.freeTimeText":"Automatische Antwort außerhalb der Arbeitszeit",
  "ccmessaging.channelconfig.email.freeTimeText.tips":"Auf die erhaltene Kunden-E-Mail außerhalb der Geschäftszeiten nach dem Öffnen antworten",
  "ccmessaging.channelconfig.email.reply.each.eamil":"Jede E-Mail automatisch beantworten",
  "ccmessaging.channelconfig.email.selectAutoReply":"E-Mail-Vorlage",
  "ccmessaging.channelconfig.email.selectFreeTimeText":"E-Mail-Antwortvorlage für arbeitsfreie Zeit",
  "ccmessaging.channelconfig.email.worktime.autoReply":"Automatische Antwort während der Arbeitszeit",
  "ccmessaging.channelconfig.email.worktime.autoReply.tips":"Auf Kunden-E-Mails antworten, die während der Arbeitszeit nach dem Öffnen eingehen",
  "ccmessaging.channelconfig.sms.autoReply":"Automatische Antwort",
  "ccmessaging.channelconfig.sms.autoReply.tips":"Nach erfolgreicher Verbindung mit dem Agenten wird eine automatische Antwort gesendet.",
  "ccmessaging.channelconfig.sms.freeTimeText":"Antwort auf arbeitsfreien Tag",
  "ccmessaging.channelconfig.sms.selectAutoReply":"Vorlage für automatische SMS-Antworten",
  "ccmessaging.channelconfig.sms.selectFreeTimeText":"Vorlage für SMS-Antworten außerhalb der Arbeitstage",
  "ccmessaging.char.agentstatus.after.callends":"Die Priorität der Agentenstatuskonfiguration wird nach Ende des Anrufs wie folgt wirksam: Organisationsebene > Mandantenebene. Wenn dieser Parameter leer bleibt, wird die Konfiguration nicht wirksam und die Konfiguration auf Mandantenebene wird verwendet.",
  "ccmessaging.char.call.Idle.state":"Leerlaufzustand",
  "ccmessaging.char.call.work.state":"Nachbearbeitungsstatus",
  "ccmessaging.char.channelconfig.duplicateCodeName":"Der Kanalname ist bereits vorhanden.",
  "ccmessaging.chat.agent.organizational.structure":"Organisationsstruktur",
  "ccmessaging.chat.agent.refreshFail":"Die Seite nicht wiederholt aktualisieren",
  "ccmessaging.chat.agent.transferSessionFail":"Die Sitzung konnte nicht übertragen werden",
  "ccmessaging.chat.agentconsole.accesschannel":"Zugriffskanal",
  "ccmessaging.chat.agentconsole.agentStatus.error":"Ein Anruf kann nicht initiiert werden, da der Agent nicht online ist oder spricht.",
  "ccmessaging.chat.agentconsole.allResults":"Alle Ergebnisse",
  "ccmessaging.chat.agentconsole.asyncTimeBeyond":"Senden fehlgeschlagen, da das Intervall für unzulässige Antworten überschritten wurde.",
  "ccmessaging.chat.agentconsole.beyondLimitNum":"Die Anzahl der gesendeten Nachrichten überschreitet die Obergrenze.",
  "ccmessaging.chat.agentconsole.browserecord":"Surfverlauf",
  "ccmessaging.chat.agentconsole.chat.close":"Die aktuelle Sitzung wurde beendet",
  "ccmessaging.chat.agentconsole.chat.userId":"Benutzer-ID",
  "ccmessaging.chat.agentconsole.chat.userSettings":"Benutzereinstellungen",
  "ccmessaging.chat.agentconsole.chatPhrase":"Geläufige Phrasen",
  "ccmessaging.chat.agentconsole.cobrowse":"Ko-Browsen",
  "ccmessaging.chat.agentconsole.contract.pdfUploadMaxSizeTip":"Die Vertragsgröße darf {n}M nicht überschreiten.",
  "ccmessaging.chat.agentconsole.contract.statisticSigns":"Unterschriftenstatistik",
  "ccmessaging.chat.agentconsole.contract.statisticTotalSigns":"Statistik",
  "ccmessaging.chat.agentconsole.contract.statisticTotalSigns.failTip":"Es konnten keine Statistiken zu den Signaturelementen im Vertrag erfasst werden.",
  "ccmessaging.chat.agentconsole.contract.statisticTotalSigns.noSigns.failTip":"Symbol für den Speicherort der Unterschrift wurde nicht gefunden.",
  "ccmessaging.chat.agentconsole.contract.statisticTotalSigns.noStaticSigns.failTip":"Bitte zählen Sie zuerst Verträge mit Unterschriften.",
  "ccmessaging.chat.agentconsole.contract.upload.failTip":"Vertrag konnte nicht hochgeladen werden.",
  "ccmessaging.chat.agentconsole.contract.upload.noSelectSigns.failTip":"Das Signaturelement wurde nicht für den Vertrag ausgewählt.",
  "ccmessaging.chat.agentconsole.contract.upload.sameContract.failTip":"Der Abonnent hat einen noch nicht abgelaufenen Vertrag.",
  "ccmessaging.chat.agentconsole.current":"Aktuell",
  "ccmessaging.chat.agentconsole.customerinformation":"Kundeninformationen",
  "ccmessaging.chat.agentconsole.defaultOrgName":"Nicht angeschlossene Organisation",
  "ccmessaging.chat.agentconsole.document":"Dokument",
  "ccmessaging.chat.agentconsole.emailNotSupport":"Der E-Mail-Kanal unterstützt keine Multimedia-Nachrichten.",
  "ccmessaging.chat.agentconsole.enter.total.number":"Bitte geben Sie die Gesamtzahl der Signaturen ein",
  "ccmessaging.chat.agentconsole.filterByCondition":"Filterkriterien",
  "ccmessaging.chat.agentconsole.fivegNotSupport":"5G-Kanäle unterstützen kein Multimedia-Messaging",
  "ccmessaging.chat.agentconsole.hisconversation":"Historisches Gespräch",
  "ccmessaging.chat.agentconsole.history":"Geschichte",
  "ccmessaging.chat.agentconsole.image":"Bild",
  "ccmessaging.chat.agentconsole.information":"Informationen",
  "ccmessaging.chat.agentconsole.intention":"Absicht",
  "ccmessaging.chat.agentconsole.IpAddress":"IP-Adresse",
  "ccmessaging.chat.agentconsole.label.defaultwelcome":"Willkommen beim Online-Kundendienstsystem!",
  "ccmessaging.chat.agentconsole.label.endsession":"Ob die Sitzung beendet werden soll?",
  "ccmessaging.chat.agentconsole.label.filesFacebookNotsupport":"Facebook Messenger unterstützt derzeit den Empfang von Standortinformationen nicht",
  "ccmessaging.chat.agentconsole.label.filesTwitterNotsupport":"Twitter unterstützt Audio, Ortung oder Video nicht länger als 30 Sekunden!",
  "ccmessaging.chat.agentconsole.label.filesWechatNotsupport":"WeChat unterstützt keine Audiodaten, die länger als 60 Sekunden dauern.",
  "ccmessaging.chat.agentconsole.label.messageRejected":"Die Nachricht wurde von Twitter abgelehnt, da sie wie Spam aussieht. Bitte verwenden Sie ein Synonym oder versuchen Sie es später erneut:",
  "ccmessaging.chat.agentconsole.label.NoAudioDuration":"Die Audiodauer konnte nicht abgerufen werden. Bitte laden Sie es erneut hoch.",
  "ccmessaging.chat.agentconsole.label.NoVideoDuration":"Die Videodauer konnte nicht abgerufen werden.Bitte erneut hochladen!",
  "ccmessaging.chat.agentconsole.label.ongoingsession":"Zurzeit läuft keine Sitzung!",
  "ccmessaging.chat.agentconsole.label.ongoingwx":"Der aktuelle Kanal kann keine Standortinformationen senden!",
  "ccmessaging.chat.agentconsole.label.skillNoAgent":"Der Agent in der Skill-Warteschlange ist offline.",
  "ccmessaging.chat.agentconsole.loading":"Weitere laden",
  "ccmessaging.chat.agentconsole.locate":"Standort",
  "ccmessaging.chat.agentconsole.mailDraft":"E-Mail-Entwurf",
  "ccmessaging.chat.agentconsole.message.notworktime":"Der Agent ist nicht in der Arbeitszeit.",
  "ccmessaging.chat.agentconsole.messageloading":"Laden",
  "ccmessaging.chat.agentconsole.multimedia":"Multimedia",
  "ccmessaging.chat.agentconsole.multimediaid":"Multimedia-ID",
  "ccmessaging.chat.agentconsole.multimedianame":"Multimedianame",
  "ccmessaging.chat.agentconsole.myconversation":"Mein Gespräch",
  "ccmessaging.chat.agentconsole.nocontact":"Derzeit keine Kontakte",
  "ccmessaging.chat.agentconsole.noHiscontact":"Keine historische Sitzung",
  "ccmessaging.chat.agentconsole.notSupport":"Der Kanal unterstützt diese Art von Multimedia-Nachricht nicht.",
  "ccmessaging.chat.agentconsole.noUserArrive":"Im Moment ist kein Benutzer verfügbar!",
  "ccmessaging.chat.agentconsole.novoEmail":"Neue E-Mail-Adresse",
  "ccmessaging.chat.agentconsole.nowconversation":"Aktuelle Konversation",
  "ccmessaging.chat.agentconsole.only.pdf.supported":"Es werden nur PDF-Dateien unterstützt",
  "ccmessaging.chat.agentconsole.photo":"Foto",
  "ccmessaging.chat.agentconsole.promptConetnt.cannot.rollback":"Die Nachricht kann nicht zurückgezogen werden",
  "ccmessaging.chat.agentconsole.promptConetnt.ContentLengthExceeds":"Textinhalte, die länger als der Grenzwert sind, sind nicht zulässig!",
  "ccmessaging.chat.agentconsole.promptConetnt.ContentNull":"EmptyContent darf nicht ausgeführt werden!",
  "ccmessaging.chat.agentconsole.promptConetnt.delectMessageRecord":"Sie haben einen Datensatz gelöscht",
  "ccmessaging.chat.agentconsole.promptConetnt.onlyFiveG":"Die Nachrichten auf der 5G-Karte unterstützen nur 5G-Kanäle!",
  "ccmessaging.chat.agentconsole.promptConetnt.onlyWebCard":"Die Webkartennachrichten unterstützen nur Webkanäle!",
  "ccmessaging.chat.agentconsole.promptConetnt.operateFail":"Operationen für Nachrichten, die nicht erfolgreich gesendet wurden, konnten nicht ausgeführt werden",
  "ccmessaging.chat.agentconsole.promptConetnt.operateFailWithChannel":"Es können nur Webnachrichten bedient werden",
  "ccmessaging.chat.agentconsole.promptConetnt.operateRollBackFail":"Nachrichten, die länger als zwei Minuten gesendet wurden, können nicht zurückgenommen werden.",
  "ccmessaging.chat.agentconsole.promptConetnt.rollBackMessage":"Sie haben eine Nachricht zurückgezogen",
  "ccmessaging.chat.agentconsole.promptConetnt.userAlreadyInSession":"Das Senden ist fehlgeschlagen, da der Abonnent in einem Anruf ist.",
  "ccmessaging.chat.agentconsole.promptConetnt.userBusy":"Warten Sie, bis der Agent inaktiv ist.",
  "ccmessaging.chat.agentconsole.promptConetnt.userInfoNull":"Die abgerufene UserInfomation ist nicht korrekt. Die Sitzung wird getrennt!",
  "ccmessaging.chat.agentconsole.promptConetnt.userIsTalking.saveDraftFailed":"Die E-Mail konnte nicht gesendet werden, da sich der Teilnehmer in einem Anruf befindet. Die E-Mail kann nicht als Entwurf gespeichert werden.",
  "ccmessaging.chat.agentconsole.promptConetnt.userIsTalking.saveDraftSuccess":"Die E-Mail konnte nicht gesendet werden, da sich der Teilnehmer in einem Anruf befindet. Die E-Mail wird als Entwurf gespeichert.",
  "ccmessaging.chat.agentconsole.promptConetnt.userNoArrive":"Es ist kein Benutzer verbunden,oder es wurde kein Benutzer ausgewählt!",
  "ccmessaging.chat.agentconsole.promptConetnt.userOffline":"Der aktuelle Benutzer ist offline!",
  "ccmessaging.chat.agentconsole.queuing":"Warteschlangen",
  "ccmessaging.chat.agentconsole.quoteReply":"Zitieren Sie diese Antwort",
  "ccmessaging.chat.agentconsole.recommendedCaseType":"Empfohlene Arbeitsauftragskategorie",
  "ccmessaging.chat.agentconsole.replyEmail":"Antwort-E-Mail",
  "ccmessaging.chat.agentconsole.robotAssistant":"Roboterassistent",
  "ccmessaging.chat.agentconsole.select.contract.uploaded":"Wählen Sie das hochzuladende Dokument aus",
  "ccmessaging.chat.agentconsole.select.mandatory.signature":"Bitte wählen Sie eine obligatorische Signatur aus",
  "ccmessaging.chat.agentconsole.selectCaseType":"Wählen einer Arbeitsauftragskategorie",
  "ccmessaging.chat.agentconsole.send.contract":"Vertrag senden",
  "ccmessaging.chat.agentconsole.send.repeat":"Klicken Sie nicht wiederholt!",
  "ccmessaging.chat.agentconsole.sendEmail":"E-Mail senden",
  "ccmessaging.chat.agentconsole.sendLocalFile":"Lokale Dateien senden",
  "ccmessaging.chat.agentconsole.sendmessage":"Nachricht senden",
  "ccmessaging.chat.agentconsole.setHandledNumber":"Bearbeitete Nummer festlegen",
  "ccmessaging.chat.agentconsole.signature.variable":"Unterschrift{n}",
  "ccmessaging.chat.agentconsole.signInCallNum":"Anrufe nach der Anmeldung:",
  "ccmessaging.chat.agentconsole.smsTemplate":"SMS-Vorlage",
  "ccmessaging.chat.agentconsole.software":"Softwareumgebung",
  "ccmessaging.chat.agentconsole.spellcheck.correcting.not.finished":"Nicht alle Ihre falsch geschriebenen wurden korrigiert.“ Überprüfung beenden?",
  "ccmessaging.chat.agentconsole.spellcheck.correcting.cancel.confirmation":"Der korrigierte Inhalt wird verworfen. Möchten Sie die Prüfung wirklich abbrechen?",
  "ccmessaging.chat.agentconsole.spellcheck.correctly.writing":"Richtig geschrieben",
  "ccmessaging.chat.agentconsole.spellcheck.failed":"Rechtschreibprüfung fehlgeschlagen.",
  "ccmessaging.chat.agentconsole.spellcheck.language":"Sprache",
  "ccmessaging.chat.agentconsole.spellcheck.next":"Weiter",
  "ccmessaging.chat.agentconsole.spellcheck.previous":"Zurück",
  "ccmessaging.chat.agentconsole.spellcheck.replace":"Ersetzen",
  "ccmessaging.chat.agentconsole.spellcheck.replaceall":"Alle ersetzen",
  "ccmessaging.chat.agentconsole.spellcheck.sentence.length.failed":"Der zu überprüfende Text ist länger als 1024.",
  "ccmessaging.chat.agentconsole.spellcheck.sentence.length.failed.front":"Der zu überprüfende Text ist länger als 1024.",
  "ccmessaging.chat.agentconsole.spellcheck.sentence.no.context":"Das Textfeld enthält keinen Text. Sie müssen die Rechtschreibung nicht überprüfen.",
  "ccmessaging.chat.agentconsole.spellcheck.sentence.no.mistake":"Keine Rechtschreibfehler",
  "ccmessaging.chat.agentconsole.spellcheck.title":"Rechtschreibtest",
  "ccmessaging.chat.agentconsole.spellcheck.wrongly.writing":"Falsch geschrieben",
  "ccmessaging.chat.agentconsole.talkavg":"Durchschnittliche Gesprächsdauer (s):",
  "ccmessaging.chat.agentconsole.talkNum":"Der heutige Anruf zählt ",
  "ccmessaging.chat.agentconsole.thumbnail":"Miniaturansicht",
  "ccmessaging.chat.agentconsole.total.number.signatures":"Gesamtzahl der Signaturen",
  "ccmessaging.chat.agentconsole.upload":"Eine Datei hochladen",
  "ccmessaging.chat.agentconsole.uploadfail":"Hochladen der Datei fehlgeschlagen",
  "ccmessaging.chat.agentconsole.userInputStatus":"Zeigen Sie eine Vorschau des Benutzereingabestatus an.",
  "ccmessaging.chat.agentconsole.video":"Video",
  "ccmessaging.chat.agentconsole.voice":"Stimme",
  "ccmessaging.chat.agentconsole.waittime":"Wartezeit",
  "ccmessaging.chat.async.asyncMsgNum":"Anzahl der Offline-Nachrichten",
  "ccmessaging.chat.async.asyncMsgTrending":"Trend der Offline-Nachrichten",
  "ccmessaging.chat.async.asyncUserNum":"Anzahl der Offlinebenutzer",
  "ccmessaging.chat.async.asyncUserTrending":"Offline-Benutzertrend",
  "ccmessaging.chat.async.channelSelect":"Kanalfilterung",
  "ccmessaging.chat.async.requestError":"Anforderung fehlgeschlagen.",
  "ccmessaging.chat.async.statistics":"Offline-Nachrichtenüberwachung",
  "ccmessaging.chat.async.statistics.asynChannelTotal":"Gesamtzahl der Offline-Kanäle",
  "ccmessaging.chat.async.statistics.asynMsgTotal":"Gesamtzahl der Offline-Nachrichten",
  "ccmessaging.chat.async.statistics.asynUserTotal":"Gesamtzahl der Offlinebenutzer",
  "ccmessaging.chat.async.statistics.updateTime":"Zeit der Datenaktualisierung",
  "ccmessaging.chat.call.end.state":"Anrufbeendigungsstatus",
  "ccmessaging.chat.card":"Karte",
  "ccmessaging.chat.edit.card":"Karte bearbeiten",
  "ccmessaging.chat.multimedia.selectTemplate":"Vorlage auswählen",
  "ccmessaging.chat.card.template":"WhatsApp-Vorlage",
  "ccmessaging.chat.channelconfig.accessToken":"Zugriffstoken",
  "ccmessaging.chat.channelconfig.accessTokenSecret":"Geheimnis des Zugriffstokens",
  "ccmessaging.chat.channelconfig.accountKeyRepeat":"Der AccountKey wird im Kanal von WhatsApp wiederholt",
  "ccmessaging.chat.channelconfig.add":"Neu",
  "ccmessaging.chat.channelconfig.addressValidate":"Der Wert muss das Format sip:Number{'@'}botplatform.rcs.chinamobile.com haben und weniger als 100 Zeichen enthalten.",
  "ccmessaging.chat.channelconfig.afterhalf":", kann angeklickt werden, um den abzufangenden Browser zu entfernen)",
  "ccmessaging.chat.channelconfig.agentTimeout":"Timeout-Intervall für keine Agentenantwort und Aufforderung zur Neuzuweisung des Agenten anpassen",
  "ccmessaging.chat.channelconfig.agentTimeout.emailTimeOutTips":"Timeout-Intervall für keine Agentenantwort anpassen",
  "ccmessaging.chat.channelconfig.agentWorkTime":"Arbeitszeit des Agenten",
  "ccmessaging.chat.channelconfig.alphavalidateFailed":"Ungültige Zeichen. Der Wert darf nur Buchstaben, Ziffern und Unterstriche enthalten (_). Das erste Zeichen muss ein Buchstabe oder ein Unterstrich sein.",
  "ccmessaging.chat.channelconfig.apifabric.ak":"APP-Schlüssel",
  "ccmessaging.chat.channelconfig.apifabric.sk":"APP-Geheimnis",
  "ccmessaging.chat.channelconfig.apiKey":"API-Schlüssel",
  "ccmessaging.chat.channelconfig.apiKeySecret":"Geheimnis des API-Schlüssels",
  "ccmessaging.chat.channelconfig.appId":"App-ID",
  "ccmessaging.chat.channelconfig.appSecret":"App-Geheimnis",
  "ccmessaging.chat.channelconfig.assertionSigningKey":"Assertionssignaturschlüssel",
  "ccmessaging.chat.channelconfig.assistantAccessCode":"Zugangscode des Assistenten",
  "ccmessaging.chat.channelconfig.assistantSet":"Konfiguration des Roboterassistenten",
  "ccmessaging.chat.channelconfig.asyncFlag":"Offlinenachrichten",
  "ccmessaging.chat.channelconfig.asyncTip":"Wenn diese Funktion deaktiviert ist, gehen vorhandene Offline-Nachrichten verloren.",
  "ccmessaging.chat.channelconfig.asyncTime":"Zeitbegrenzung für das Routing von Offlinenachrichten",
  "ccmessaging.chat.channelconfig.asyncTime.tip":"Wenn der Zeitraum nach dem Auslösen des Offlineszenarios diesen Wert überschreitet, werden Offline-Kundennachrichten direkt gespeichert und nicht mehr an Service Desk-Mitarbeiter weitergeleitet.",
  "ccmessaging.chat.channelconfig.auth.duplicateAuth":"Das offizielle Konto wurde autorisiert und muss nicht erneut konfiguriert werden.",
  "ccmessaging.chat.channelconfig.auth.relation.timeout":"Die Autorisierung ist möglicherweise abgelaufen. Scannen Sie den WeChat-QR-Code zur erneuten Autorisierung.",
  "ccmessaging.chat.channelconfig.beforhalf":"Sie können auf die Schaltfläche'Probieren' klicken, um den Integrationseffekt zu sehen. (Wenn die URL korrekt ist und ein Abfangkennzeichen in der oberen rechten Ecke des Browsers angezeigt wird",
  "ccmessaging.chat.channelconfig.bsp":"Anbieter von Unternehmenslösungen",
  "ccmessaging.chat.channelconfig.cert.empty":"Das Zertifikat darf nicht leer sein, wenn die Push-Adresse das HTTPS-Protokoll verwendet.",
  "ccmessaging.chat.channelconfig.Certification":"Überprüfungsmethode",
  "ccmessaging.chat.channelconfig.changeLogo":"Avatar ändern",
  "ccmessaging.chat.channelconfig.channelID":"Kanal-ID",
  "ccmessaging.chat.channelconfig.channelSecret":"Kanalgeheimnis",
  "ccmessaging.chat.channelconfig.chatbotAddress":"Chatbot-Adresse",
  "ccmessaging.chat.channelconfig.checkAgentTimeOutInterval":"Das Timeout-Intervall für keine Agentenantwort darf nicht länger sein als das Aufforderungsintervall für keine Antwort.",
  "ccmessaging.chat.channelconfig.checkEncodingAESKey":"Schlüssellänge ist 43 und darf nur die Zeichen A-Z,a-z,0-9 enthalten",
  "ccmessaging.chat.channelconfig.checkmessage":"Die folgenden Sonderzeichen sind nicht zulässig <>'/\\",
  // "ccmessaging.chat.channelconfig.checkNickName":"Die folgenden Sonderzeichen sind nicht zulässig +~!{'@'}#$%&*()/=+{'{'}{'}'};:"\\,<.>?",
  "ccmessaging.chat.channelconfig.checkName":"Der Name darf nicht aus den Wörtern System usw. bestehen",
  "ccmessaging.chat.channelconfig.checkNumber":"Der Wert ist ungültig.",
  "ccmessaging.chat.channelconfig.checkSocialChatNum":"Kann keine Dezimalstellen enthalten",
  "ccmessaging.chat.channelconfig.checkWhatsAppBaseUrl":"Die URL ist ungültig!",
  "ccmessaging.chat.channelconfig.checkWhatsAppPhoneNum":"Ungültige Mobiltelefonnummer",
  "ccmessaging.chat.channelconfig.clicktocall.empty":"Die Skill-Warteschlange oder der IVR-Zugangscode darf nicht leer sein!",
  "ccmessaging.chat.channelconfig.clickToCallSkillList":"Click-to-Call-Kompetenzwarteschlange",
  "ccmessaging.chat.channelconfig.close":"Schließen",
  "ccmessaging.chat.channelconfig.codeName":"Kanalzugangscode.",
  "ccmessaging.chat.channelconfig.defaultReply":"Standardantwort",
  "ccmessaging.chat.channelconfig.delete":"Löschen",
  "ccmessaging.chat.channelconfig.delete.exist.asyncMsg":"Der Kanal [{n}] konnte nicht gelöscht werden. Versuchen Sie es erneut, nachdem Offlinenachrichten zugewiesen wurden.",
  "ccmessaging.chat.channelconfig.delete.exist.asyncUser":"Der Kanal [{n}] konnte nicht gelöscht werden. Versuchen Sie es erneut, nachdem Offlinebenutzer zugewiesen wurden.",
  "ccmessaging.chat.channelconfig.delete.exist.session":"Der Kanal [{n}] konnte nicht gelöscht werden. Versuchen Sie es nach Ende der Sitzung erneut.",
  "ccmessaging.chat.channelconfig.edit":"Betrieb",
  "ccmessaging.chat.channelconfig.email.bccLimit":"Max. Bcc-Empfänger",
  "ccmessaging.chat.channelconfig.email.ccLimit":"Max. Cc-Empfänger",
  "ccmessaging.chat.channelconfig.email.number":"Konfiguration der Anzahl der E-Mail-Adressen",
  "ccmessaging.chat.channelconfig.email.personal":"Mit persönlicher E-Mail antworten",
  "ccmessaging.chat.channelconfig.email.placeholder":"1 – 100, optional",
  "ccmessaging.chat.channelconfig.email.range":"Geben Sie eine positive Ganzzahl zwischen 1 und 100 ein",
  "ccmessaging.chat.channelconfig.email.address.limit":"Zahlen",
  "ccmessaging.chat.channelconfig.email.receiverLimit":"Max. Empfänger",
  "ccmessaging.chat.channelconfig.email.selectSignature":"E-Mail-Signatur",
  "ccmessaging.chat.channelconfig.email.signature":"E-Mail-Signatur",
  "ccmessaging.chat.channelconfig.email.signature.desc":"Beschreibung",
  "ccmessaging.chat.channelconfig.email.signature.name":"Name der E-Mail-Signatur",
  "ccmessaging.chat.channelconfig.email.totalEmailNumber":"Die Gesamtzahl der Empfänger-, Durchschreibe-Empfänger- und geheimen Empfänger-E-Mail-Adressen darf 100 nicht überschreiten.",
  "ccmessaging.chat.channelconfig.emailRepeate":"Das E-Mail-Empfangs-Gateway wurde verwendet. Wechseln Sie zur Konfigurationsseite für das Nachrichtenrouting, um das E-Mail-Empfangs-Gateway anzuzeigen.",
  "ccmessaging.chat.channelconfig.endchat":"Ob die Sitzung beendet werden soll?",
  "ccmessaging.chat.channelconfig.endOfSession":"Sitzungsende aufgrund keiner Kundenantwort",
  "ccmessaging.chat.channelconfig.envName":"Label der Entwicklungsumgebung",
  "ccmessaging.chat.channelconfig.envNameValidate":"Der Wert muss eine Zeichenfolge mit höchstens 24 Zeichen sein und darf nur Buchstaben und Ziffern enthalten.",
  "ccmessaging.chat.channelconfig.facebookBackground":"Facebook",
  "ccmessaging.chat.channelconfig.fiveGBackground":"5G-Nachrichten-CSP",
  "ccmessaging.chat.channelconfig.gender":"Geschlecht",
  "ccmessaging.chat.channelconfig.guestTimeout":"Angepasster Timeout-Zeitraum für keine Kundenantwort und Sitzungsendenachricht. Wenn diese Funktion deaktiviert ist, beträgt der Standard-Timeout-Zeitraum 20 Minuten.",
  "ccmessaging.chat.channelconfig.guestTimeout.timeOutTips":"Passen Sie die Timeout-Zeit für die Nicht-Antwort von Gästen an. Wenn diese Option nicht aktiviert ist, beträgt das Standard-Timeout {n} Minuten.",
  "ccmessaging.chat.channelconfig.hostingMethod":"Hosting-Methode",
  "ccmessaging.chat.channelconfig.hour":"Stunde",
  "ccmessaging.chat.channelconfig.identityCertificate":"Identitätsbescheinigung",
  "ccmessaging.chat.channelconfig.importAvatarSize":"Die Größe des Avatar-Images überschreitet 6 MB.",
  "ccmessaging.chat.channelconfig.importAvatartype":"Falscher Avatar-Bildtyp.",
  "ccmessaging.chat.channelconfig.infobipPassword":"Kennwort",
  "ccmessaging.chat.channelconfig.infobipUserName":"UserName",
  "ccmessaging.chat.channelconfig.infoConfiguration":"Infokonfiguration",
  "ccmessaging.chat.channelconfig.keyEscrow":"Schlüssel",
  "ccmessaging.chat.channelconfig.KeyValidate":"Der Wert darf nur Buchstaben, Ziffern und die folgende spezielle characters:_~!{'@'}#$%^&*- beliebiger Zusammensetzung enthalten",
  "ccmessaging.chat.channelconfig.whatsappNameValidate":"Der Wert darf nur Buchstaben, Ziffern und die folgende spezielle characters:_~!{'@'}#$%^&*-. beliebiger Zusammensetzung enthalten",
  "ccmessaging.chat.channelconfig.lastagent.time":"Zeitbereich",
  "ccmessaging.chat.channelconfig.lastModifyTime":"Letzte Änderung um",
  "ccmessaging.chat.channelconfig.logo":"Avatar",
  "ccmessaging.chat.channelconfig.messagePushUrl.not.configure.trustList":"Die Adresse steht nicht auf der Vertrauensliste. Bitte wenden Sie sich an den Systemadministrator.",
  "ccmessaging.chat.channelconfig.hrefUrl.not.configure.trustList":"Die Hyperlinkadresse befindet sich nicht in der Vertrauensliste. Bitte wenden Sie sich an den Systemadministrator.",
  "ccmessaging.chat.channelconfig.minute":"Minute",
  "ccmessaging.chat.channelconfig.name":"Name",
  "ccmessaging.chat.channelconfig.nochatonline":"Zurzeit läuft keine Sitzung!",
  "ccmessaging.chat.channelconfig.noLimit":"Keine Angabe",
  "ccmessaging.chat.channelconfig.NonworkingTimeReminder":"Benachrichtigung über arbeitsfreie Zeit",
  "ccmessaging.chat.channelconfig.nonWorkTimeDay":"Arbeitsfrei",
  "ccmessaging.chat.channelconfig.noReplyIntervalTime":"Timeout-Zeitraum für Kundenantwort aufgerufen",
  "ccmessaging.chat.channelconfig.noReplyIntervalTime.tip":"Wenn ein Kunde im Szenario eines ausgehenden Anrufs nicht innerhalb dieses Zeitraums antwortet, wird die Sitzung automatisch beendet.",
  "ccmessaging.chat.channelconfig.noReplyIntervalTimeValidate":"Das Intervall für die Benachrichtigung Kunden keine Antwort darf nicht länger sein als das Intervall für die Benachrichtigung Kunden keine Antwort.",
  "ccmessaging.chat.channelconfig.noReplyTime":"Zeitlimit für ausgehende Agentenanrufe",
  "ccmessaging.chat.channelconfig.noReplyTime.tip":"Wenn der Zeitraum nach dem letzten Kontakt eines Kunden mit einem Agenten diesen Wert überschreitet, kann der Agent keinen ausgehenden Anruf an den Kunden tätigen.",
  "ccmessaging.chat.channelconfig.offBeginTime":"Von der Startzeit bis zur aktuellen Zeit",
  "ccmessaging.chat.channelconfig.offLastAgentType":"Letzter Agententyp",
  "ccmessaging.chat.channelconfig.offTimeRange":"Innerhalb des Zeitbereichs",
  "ccmessaging.chat.channelconfig.offToday":"Am aktuellen Tag",
  "ccmessaging.chat.channelconfig.onlyContainSubType":"Nur Kompetenzwarteschlangen des Kanaltyps anzeigen, wenn ein Agent eine Sitzung überträgt.",
  "ccmessaging.chat.channelconfig.orgConfiguration":"OU-Konfiguration",
  "ccmessaging.chat.channelconfig.pageId":"Seiten-ID",
  "ccmessaging.chat.channelconfig.personalSetting":"Persönliche Einstellungen",
  "ccmessaging.chat.channelconfig.phoneNumber":"Telefonnummer",
  "ccmessaging.chat.channelconfig.pleaseSelect":"Bitte wählen Sie ...",
  "ccmessaging.chat.channelconfig.PreferredLastAgent":"Letzter Agentenmodus",
  "ccmessaging.chat.channelconfig.Previous":"Zurück",
  "ccmessaging.chat.channelconfig.privateKeyValidate":"Der Wert muss im JSON-Format vorliegen. Der Schlüsselwert in JSON darf nur Buchstaben, Ziffern und die folgenden Sonderzeichen enthalten:_~!{'@'}#$%^&*- Beliebige Zusammensetzung",
  "ccmessaging.chat.channelconfig.publicConfiguration":"Allgemeine Konfiguration",
  "ccmessaging.chat.channelconfig.pushMessageUrlValid":"Die Kanalkonfiguration konnte nicht gespeichert werden. Stellen Sie sicher, dass die Push-Adresse oder das Zertifikat der Nachricht gültig ist.",
  "ccmessaging.chat.channelconfig.qryaddressconfigfailed":"Die ChatbotAddress-Verifizierungsregel des 5G-Kanals konnte nicht abgefragt werden. Die Überprüfungsregel ist nicht konfiguriert.",
  "ccmessaging.chat.channelconfig.queryType":"Auswahlmodus",
  "ccmessaging.chat.channelconfig.queueReminder":"Warteschlangenerinnerung",
  "ccmessaging.chat.channelconfig.queueReminderContent":"Inhalt der Warteschlangenerinnerung",
  "ccmessaging.chat.channelconfig.queueReminderInterval":"Warteschlangenerinnerungsintervall",
  "ccmessaging.chat.channelconfig.reCreateScenario":"Erzeugen",
  "ccmessaging.chat.channelconfig.refreshAccessToken":"Aktualisierung des Zugriffstokens fehlgeschlagen.",
  "ccmessaging.chat.channelconfig.robotAccessCode":"Chatbot-Zugangscode",
  "ccmessaging.chat.channelconfig.robotApplicationKey":"Chatbot-Anwendungsschlüssel",
  "ccmessaging.chat.channelconfig.robotSet":"Chatbot-Konfiguration",
  "ccmessaging.chat.channelconfig.routeType":"Routingmodus",
  "ccmessaging.chat.channelconfig.routeTypeSkill":"Skill-Warteschlangen-Routing",
  "ccmessaging.chat.channelconfig.saveAndToNext":"Speichern und mit dem nächsten Schritt fortfahren",
  "ccmessaging.chat.channelconfig.savechannelconfigfailed":"Speichern der Kanalkonfiguration fehlgeschlagen!",
  "ccmessaging.chat.channelconfig.scanCodeHosting":"QR-Code",
  "ccmessaging.chat.channelconfig.scenario.status.existent":"Szenarioschlüssel:Ja",
  "ccmessaging.chat.channelconfig.scenario.status.noExistent":"Szenarioschlüssel: NEIN",
  "ccmessaging.chat.channelconfig.select.algorithm":"Wählen Sie einen Verschlüsselungsalgorithmus aus",
  "ccmessaging.chat.channelconfig.select.algorithm.configtips":"Konfigurieren Sie den unterstützten Verschlüsselungsalgorithmus basierend auf den Anforderungen der offenen Kanalplattform.",
  "ccmessaging.chat.channelconfig.select.algorithm.safetips":"Dieser Algorithmus ist nicht stark genug. Wenn die Plattform diesen Algorithmus unterstützt, wird empfohlen, einen höheren Verschlüsselungsalgorithmus zu verwenden.",
  "ccmessaging.chat.channelconfig.selectAccessChannel":"Zugriffskanal auswählen",
  "ccmessaging.chat.channelconfig.selectConfigurationMethod":"Konfigurationsmethode auswählen",
  "ccmessaging.chat.channelconfig.selectRobot":"Einen Roboter auswählen",
  "ccmessaging.chat.channelconfig.selectRobotAssistant":"Roboterassistenten auswählen",
  "ccmessaging.chat.channelconfig.sessionTransferConfig":"Sitzungstransfer",
  "ccmessaging.chat.channelconfig.silentAgentDelete":"Möchten Sie die ausgewählte Agentenkonfiguration für die Sprachstummschaltung wirklich löschen?",
  "ccmessaging.chat.channelconfig.silentAgentSelect":"Wählen Sie die zu löschende Agentenkonfiguration für die Sprachstummschaltung aus.",
  "ccmessaging.chat.channelconfig.silentAgentSkillList":"Silent-Agent-Fertigkeitswarteschlange",
  "ccmessaging.chat.channelconfig.skill":"Skillliste (Zugangscode+Extcode)",
  "ccmessaging.chat.channelconfig.skillList":"Skill-Warteschlange",
  "ccmessaging.chat.channelconfig.smsRepeate":"Das SMS-Gateway wurde verwendet. Wechseln Sie zur Konfigurationsseite für die Nachrichtenroute, um das SMS-Gateway anzuzeigen.",
  "ccmessaging.chat.channelconfig.startTime":"Startzeit",
  "ccmessaging.chat.channelconfig.sub":"Kopieren Sie den folgenden Code und fügen Sie ihn in Ihr Website-Label ein, speichern und veröffentlichen Sie ihn, um den schnellen Zugriff auf das ServiceCloud von Huawei abzuschließen. Nach der Veröffentlichung wird das Huawei Service Cloud-Portal in der unteren rechten Ecke Ihrer Website angezeigt. Klicken Sie auf, um zur Kundenservice-Workbench zu gelangen. Echtzeit-Gespräch.",
  "ccmessaging.chat.channelconfig.submit":"Sumbit",
  "ccmessaging.chat.channelconfig.submitVerification":"Integrationsanweisungen",
  "ccmessaging.chat.channelconfig.successfulAccess":"Erfolgreicher Zugriff",
  "ccmessaging.chat.channelconfig.thirdPartySecretKey":"Authentifizierungsschlüssel von Drittanbietern",
  "ccmessaging.chat.channelconfig.time":"Zeitbereich (min)",
  "ccmessaging.chat.channelconfig.timeoutReply":"Timeout-Antwort",
  "ccmessaging.chat.channelconfig.timerange":"Zeitbereich",
  "ccmessaging.chat.channelconfig.timeZone":"Zeitzone",
  "ccmessaging.chat.channelconfig.today":"Aktueller Tag",
  "ccmessaging.chat.channelconfig.triggerText":"Offline-Nachrichtenaufforderung",
  "ccmessaging.chat.channelconfig.trustCertificate":"Vertrauenszertifikat",
  "ccmessaging.chat.channelconfig.trybutton":"Versuchen",
  "ccmessaging.chat.channelconfig.turnAI":"Mit Chatbot verbinden",
  "ccmessaging.chat.channelconfig.turnArtificial":"Schlüsselwort für die Weiterleitung an den Agenten",
  "ccmessaging.chat.channelconfig.turnArtificial.tip":"Getrennt durch chinesische oder englische Semikolons",
  "ccmessaging.chat.channelconfig.turnAssistant":"Verbindung mit dem Assistenten herstellen",
  "ccmessaging.chat.channelconfig.turnManualPrompt":"Zur Weiterleitung an den Agenten auffordern",
  "ccmessaging.chat.channelconfig.twitterBackground":"Twitterserver",
  "ccmessaging.chat.channelconfig.twittertips":"Sie können auf die folgenden Websites zugreifen. Klicken Sie auf Zur Twitter-Authentifizierung senden.",
  "ccmessaging.chat.channelconfig.update":"Aktualisieren",
  "ccmessaging.chat.channelconfig.updatecontactfailed":"Aktualisieren des Kontakts fehlgeschlagen!",
  "ccmessaging.chat.channelconfig.voiceAccess":"IVR aufgerufen",
  "ccmessaging.chat.channelconfig.voiceAgentSkill":"Agenten-Kompetenzwarteschlange",
  "ccmessaging.chat.channelconfig.webhookConfirmPassword":"Webhook-Kennwort bestätigen",
  "ccmessaging.chat.channelconfig.webHookException":"Die gültige Zeit für das WebHook-Passwort konnte nicht abgerufen werden",
  "ccmessaging.chat.channelconfig.webhookPassword":"Webhook-Kennwort",
  "ccmessaging.chat.channelconfig.webhookPassword.newPassword":"Neues Webhook-Kennwort",
  "ccmessaging.chat.channelconfig.webhookPassword.noValidPassword":"Kein Kennwort verfügbar",
  "ccmessaging.chat.channelconfig.webhookPassword.oldPassword":"Webhook-Kennwort für Sperrfrist sperren",
  "ccmessaging.chat.channelconfig.webhookPassword.validPeriod":"Gültig bis:",
  "ccmessaging.chat.channelconfig.webhookPassword.validPeriod.fifthDayTip":"Gültig weniger als 15 Tage",
  "ccmessaging.chat.channelconfig.webhookPassword.validPeriod.oneDayTip":"Gültig für weniger als einen Tag. Bitte ändern!",
  "ccmessaging.chat.channelconfig.wechat.encryption.tip":"Das Deaktivieren der Nachrichtenverschlüsselung kann Sicherheitsrisiken bergen. Die Einstellung muss mit der auf der WeChat-Plattform für offizielle Konten übereinstimmen.",
  "ccmessaging.chat.channelconfig.wechat.qr.label":"QR-Code scannen",
  "ccmessaging.chat.channelconfig.wechat.qr.label2":"Erneute Autorisierung",
  "ccmessaging.chat.channelconfig.wechat.qr.label3":"Autorisierung erfolgreich",
  "ccmessaging.chat.channelconfig.weChatBackground":"WeChat-Server",
  "ccmessaging.chat.channelconfig.weChatMessageEncryptionSwitch":"WeChat-Nachrichtenverschlüsselungsschalter",
  "ccmessaging.chat.channelconfig.welcomeMessage":"Grüße",
  "ccmessaging.chat.channelconfig.whatsAppBackground":"WhatsApp-Server",
  "ccmessaging.chat.channelconfig.workTimeDay":"Arbeitstag",
  "ccmessaging.chat.channelconfig.worktimeerror":"Bitte geben Sie den normalen Zeitraum ein!",
  "ccmessaging.chat.chatcard.audio":"AUDIO",
  "ccmessaging.chat.chatcard.button.addtabs":"Kartenoptionen hinzufügen",
  "ccmessaging.chat.chatcard.cancel":"Abbrechen",
  "ccmessaging.chat.chatcard.cardId":"Vorlagen-ID",
  "ccmessaging.chat.chatcard.cardName":"Name",
  "ccmessaging.chat.chatcard.cardNamePlaceholder":"Geben Sie den Namen der Kartenvorlage ein.",
  "ccmessaging.chat.chatcard.cardTitle":"Titel",
  "ccmessaging.chat.chatcard.cardTitlePlaceholder":"Geben Sie den Kartentitel ein.",
  "ccmessaging.chat.chatcard.create":"Speichern",
  "ccmessaging.chat.chatcard.delete":"Löschen",
  "ccmessaging.chat.chatcard.deleteFail":"Löschfehler",
  "ccmessaging.chat.chatcard.deleteLimit":"Auf die Vorlage wurde verwiesen und sie kann nicht gelöscht werden.",
  "ccmessaging.chat.chatcard.deleteSuccess":"Löschvorgang erfolgreich",
  "ccmessaging.chat.chatcard.deleteTips":"Parameterverifizierung fehlgeschlagen.",
  "ccmessaging.chat.chatcard.deleteWhatsAppTemplate.Fail":"Das Löschen ist fehlgeschlagen, da die Vorlage bereits von einer WhatsApp-Vorlagennachricht referenziert wird.",
  "ccmessaging.chat.chatcard.description":"Beschreibung",
  "ccmessaging.chat.chatcard.descriptionPlaceholder":"Geben Sie eine Kartenbeschreibung ein.",
  "ccmessaging.chat.chatcard.details":"Kartendetails",
  "ccmessaging.chat.chatcard.dial":"Wählen",
  "ccmessaging.chat.chatcard.down":"Nach unten",
  "ccmessaging.chat.chatcard.edit":"Bearbeiten",
  "ccmessaging.chat.chatcard.fail":"Fehler",
  "ccmessaging.chat.chatcard.failTip":"Hinzufügen fehlgeschlagen. Die Anzahl der hinzugefügten Optionen erreicht das Maximum.",
  "ccmessaging.chat.chatcard.folded":"Gefaltet",
  "ccmessaging.chat.chatcard.id":"Geben Sie eine Vorlagen-ID ein.",
  "ccmessaging.chat.chatcard.image":"BILD",
  "ccmessaging.chat.chatcard.input":"Geben Sie einen Vorlagennamen ein.",
  "ccmessaging.chat.chatcard.invalidUrl":"Die URL beginnt nicht mit http://,https://",
  "ccmessaging.chat.chatcard.linking":"Verknüpfen",
  "ccmessaging.chat.chatcard.mediaFileType":"Mediendateityp",
  "ccmessaging.chat.chatcard.mediaHeight":"Medienhöhe",
  "ccmessaging.chat.chatcard.mediaType":"Medientyp",
  "ccmessaging.chat.chatcard.mediumHeight":"Mittlere Höhe",
  "ccmessaging.chat.chatcard.messageTemplateCreate":"Erstellen einer Kartenvorlage",
  "ccmessaging.chat.chatcard.messageTemplateDelete":"Löschen einer Vorlage",
  "ccmessaging.chat.chatcard.messageTemplateModify":"Ändern einer Vorlage",
  "ccmessaging.chat.chatcard.multiMediaId":"Multimediaressource",
  "ccmessaging.chat.chatcard.multimediaid":"Multimedia-ID",
  "ccmessaging.chat.chatcard.multimedianame":"Multimediadateiname",
  "ccmessaging.chat.chatcard.name":"Geben Sie einen Namen ein.",
  "ccmessaging.chat.chatcard.NoneTitle":"Ohne Titel",
  "ccmessaging.chat.chatcard.operate":"Betrieb",
  "ccmessaging.chat.chatcard.queryTemplateFail":"Abfragevorlage fehlgeschlagen",
  "ccmessaging.chat.chatcard.replay":"Wiederholung",
  "ccmessaging.chat.chatcard.saveFail":"Speichern fehlgeschlagen",
  "ccmessaging.chat.chatcard.saveSuccess":"Speichern erfolgreich",
  "ccmessaging.chat.chatcard.selectMultiMediaId":"Wählen Sie die Multimediaressource aus.",
  "ccmessaging.chat.chatcard.selectMultiMediaTips":"Wählen Sie Multimediaressourcen aus.",
  "ccmessaging.chat.chatcard.closeDialog":"Möchten Sie diesen Dialog wirklich schließen?",
  "ccmessaging.chat.chatcard.shortHeight":"Kurze Höhe",
  "ccmessaging.chat.chatcard.strcontentTip":"HTTP-Anfragen sind mit Sicherheitsrisiken verbunden.",
  "ccmessaging.chat.chatcard.success":"Erfolg",
  "ccmessaging.chat.chatcard.tabs":"Kartenoptionen",
  "ccmessaging.chat.chatcard.tabs.displaytext":"Text auf der Kartenanzeige",
  "ccmessaging.chat.chatcard.tabs.selectcontent":"Inhalt auswählen",
  "ccmessaging.chat.chatcard.tabs.selectType":"Optionstyp",
  "ccmessaging.chat.chatcard.tabsname":"Kartenoptionsname",
  "ccmessaging.chat.chatcard.tallHeight":"Große Höhe",
  "ccmessaging.chat.chatcard.up":"Nach oben",
  "ccmessaging.chat.chatcard.video":"VIDEO",
  "ccmessaging.chat.chatcard.viewTips":"Die Kartenvorlage wurde gelöscht und kann nicht in der Vorschau angezeigt werden.",
  "ccmessaging.chat.chatcard.whatsappTemplateCreate":"WhatsApp-Vorlage erstellen",
  "ccmessaging.chat.chatcard.willdeletechatcard":"Möchten Sie diese Karte wirklich löschen?",
  "ccmessaging.chat.chatcard.willdeletechatTemplate":"Möchten Sie die Vorlage WhatsApp wirklich löschen?",
  "ccmessaging.chat.chatcard.nameExist":"Der Name existiert bereits. Bitte geben Sie eine andere ein.",
  "ccmessaging.chat.chatconfig.add":"Neu",
  "ccmessaging.chat.chatconfig.channelAccessCode":"Kanalzugangscode",
  "ccmessaging.chat.chatconfig.channelOccupied":"Kanal ist bereits belegt.",
  "ccmessaging.chat.chatconfig.channelType":"Kanaltyp",
  "ccmessaging.chat.chatconfig.configurationId":"Konfigurations-ID",
  "ccmessaging.chat.chatconfig.delete":"Löschen",
  "ccmessaging.chat.chatconfig.deletechannelAccessCode":"Kanalkonfiguration löschen",
  "ccmessaging.chat.chatconfig.deletefail":"Löschen fehlgeschlagen.",
  "ccmessaging.chat.chatconfig.deletesuccess":"Erfolgreich gelöscht.",
  "ccmessaging.chat.chatconfig.idisnull":"Die ID ist leer.",
  "ccmessaging.chat.chatconfig.inquire":"Suche",
  "ccmessaging.chat.chatconfig.modify":"Ändern",
  "ccmessaging.chat.chatconfig.operate":"Betrieb",
  "ccmessaging.chat.chatconfig.refresh":"Aktualisieren",
  "ccmessaging.chat.chatconfig.reset":"Zurücksetzen",
  "ccmessaging.chat.chatconfig.rollBack":"Zurückfahren",
  "ccmessaging.chat.chatconfig.selectchannelAccessCode":"Wählen Sie die Kanalkonfiguration aus.",
  "ccmessaging.chat.chatconfig.skillId":"Skill-Warteschlange",
  "ccmessaging.chat.chatconfig.willdeletechannelAccessCode":"Möchten Sie die ausgewählte Kanalkonfiguration wirklich löschen?",
  "ccmessaging.chat.chatemail.cancelhandled":"Abbruch verarbeitet",
  "ccmessaging.chat.chatemail.delete":"Löschen",
  "ccmessaging.chat.chatemail.details":"Ansicht",
  "ccmessaging.chat.chatemail.forwarded":"Weitergeleitet",
  "ccmessaging.chat.chatemail.forwardedTime":"Weitergeleitet um",
  "ccmessaging.chat.chatemail.handled":"Verarbeitet",
  "ccmessaging.chat.chatemail.maxNumberImages":"Die Anzahl der hochgeladenen lokalen Bilder hat das Maximum von 20 erreicht.",
  "ccmessaging.chat.chatemail.saveDraft":"Als Entwurf speichern",
  "ccmessaging.chat.chatemail.sender":"Absender:",
  "ccmessaging.chat.chatholiday.calendar.preview":"Kalendervorschau",
  "ccmessaging.chat.chatholiday.checkHolidaySize":"Es gibt zu viele Einträge. Bitte löschen Sie zuerst die überzähligen Einträge",
  "ccmessaging.chat.chatholiday.date":"Datum",
  "ccmessaging.chat.chatholiday.dateConfiguration":"Datumskonfiguration",
  "ccmessaging.chat.chatholiday.delete":"Löschen",
  "ccmessaging.chat.chatholiday.description":"Beschreibung",
  "ccmessaging.chat.chatholiday.designation.alert":"Das Enddatum muss nach dem Startdatum liegen",
  "ccmessaging.chat.chatholiday.designation.cancel":"Abbrechen",
  "ccmessaging.chat.chatholiday.designation.complete":"Abgeschlossen",
  "ccmessaging.chat.chatholiday.designation.days":"Mehrere Tage",
  "ccmessaging.chat.chatholiday.designation.end":"Enddatum",
  "ccmessaging.chat.chatholiday.designation.norepeating":"Nicht wiederholt",
  "ccmessaging.chat.chatholiday.designation.oneday":"1 Tag",
  "ccmessaging.chat.chatholiday.designation.repeating":"Jedes Jahr wiederholt",
  "ccmessaging.chat.chatholiday.designation.selectrule":"Das ausgewählte Datum darf nicht mit dem ausgewählten Datum identisch sein.",
  "ccmessaging.chat.chatholiday.designation.show":"Jedes Jahr",
  "ccmessaging.chat.chatholiday.designation.start":"Startdatum",
  "ccmessaging.chat.chatholiday.designation.type":"Datumstyp",
  "ccmessaging.chat.chatholiday.friday":"Freitag",
  "ccmessaging.chat.chatholiday.holidays.explanation":"Sie können bestimmte Tage als arbeitsfreie Tage festlegen, z. B. den 13. September 2019 (Freitag, chinesisches Mittherbstfest).",
  "ccmessaging.chat.chatholiday.isDelete.otherdays":"Möchten Sie dieses Ausnahmedatum wirklich löschen?",
  "ccmessaging.chat.chatholiday.isDelete.specified":"Möchten Sie dieses angegebene Datum wirklich löschen?",
  "ccmessaging.chat.chatholiday.monday":"Montag",
  "ccmessaging.chat.chatholiday.new":"Neu",
  "ccmessaging.chat.chatholiday.nonWorkbench":"Konfiguration außerhalb der Arbeitstage",
  "ccmessaging.chat.chatholiday.noworking":"Arbeitsfrei",
  "ccmessaging.chat.chatholiday.operate":"betreiben",
  "ccmessaging.chat.chatholiday.other.prompt":"Das ausgewählte Datum stimmt mit einem vorhandenen Datum überein",
  "ccmessaging.chat.chatholiday.otherdays.exception":"Ausnahmedatum",
  "ccmessaging.chat.chatholiday.otherdays.explanation":"Sie können bestimmte Tage als Arbeitstage auswählen. Der maximale Zeitraum beträgt ein Jahr. Beispiel:Sie können den 13. Juli 2019 (Samstag, vorübergehende Überstunden erforderlich) als Arbeitstag auswählen.",
  "ccmessaging.chat.chatholiday.repeat":"Wiederholungsregeln",
  "ccmessaging.chat.chatholiday.saturday":"Samstag",
  "ccmessaging.chat.chatholiday.savechatholidayfailed":"Das Speichern der Feiertagskonfiguration ist fehlgeschlagen",
  "ccmessaging.chat.chatholiday.specified.date":"Angegebenes Datum",
  "ccmessaging.chat.chatholiday.sunday":"Sonntag",
  "ccmessaging.chat.chatholiday.thursday":"Donnerstag",
  "ccmessaging.chat.chatholiday.tuesday":"Dienstag",
  "ccmessaging.chat.chatholiday.wednesday":"Mittwoch",
  "ccmessaging.chat.chatholiday.weekend.explanation":"Sie können bestimmte Tage in einer Woche als arbeitsfreie Tage festlegen, z. B. Samstag und Sonntag.",
  "ccmessaging.chat.chatholiday.weekly":"Wöchentlich",
  "ccmessaging.chat.chatholiday.workbench":"Workbench-Konfiguration",
  "ccmessaging.chat.chatholiday.working.day":"Arbeitstag",
  "ccmessaging.chat.chatholiday.tip.choosedate":"Bitte wählen Sie ein Datum aus",
  "ccmessaging.chat.chatholiday.tip.descinvalid":"Die Beschreibung darf nicht länger als 20 Zeichen sein",
  "ccmessaging.chat.chatmanage.agentIntervalTime":"Timeout-Zeitraum für Agentenantwort",
  "ccmessaging.chat.chatmanage.agentSessionEndText":"Aufforderung zur Agentenneuzuweisung",
  "ccmessaging.chat.chatmanage.autoEndSession":"Wenn der Gast nicht innerhalb der angegebenen Zeit antwortet, wird der Dialog automatisch beendet.",
  "ccmessaging.chat.chatmanage.autoEndSessionMorethan":"Die Beschreibung des Intervalls, in dem der Kunde keine Antwort erhält, darf nicht mehr als 100 Zeichen umfassen.",
  "ccmessaging.chat.chatmanage.autoEndSessionnotnull":"Die Beschreibung des Nicht-Antwort-Intervalls des Kunden darf nicht leer sein.",
  "ccmessaging.chat.chatmanage.autoEndSessionNum":"Der Kunde antwortet nicht auf das Benachrichtigungsintervall muss eine Zahl sein.",
  "ccmessaging.chat.chatmanage.autoEndSessionRange":"Das Intervall für die Kundenerinnerung ohne Antwort muss zwischen 0 und 60 liegen",
  "ccmessaging.chat.chatmanage.cancel":"Abbrechen",
  "ccmessaging.chat.chatmanage.edit":"Bearbeiten",
  "ccmessaging.chat.chatmanage.endTime":"Endzeit",
  "ccmessaging.chat.chatmanage.endTimeNum":"Die Endzeit muss eine Zahl sein.",
  "ccmessaging.chat.chatmanage.endTimeRange":"Die Zahl der Endzeit muss zwischen 0 und 24 liegen.",
  "ccmessaging.chat.chatmanage.fail":"Fehler",
  "ccmessaging.chat.chatmanage.freeTimeText":"Benachrichtigung über arbeitsfreie Zeit",
  "ccmessaging.chat.chatmanage.freeTimeTextMorethan":"Die Beschreibung der Benachrichtigung über arbeitsfreie Zeit darf nicht mehr als 100 Zeichen enthalten.",
  "ccmessaging.chat.chatmanage.freeTimeTextnotnull":"Die Beschreibung der arbeitsfreien Zeiterinnerung darf nicht leer sein.",
  "ccmessaging.chat.chatmanage.intervalTime":"Timeout-Zeitraum für Kundenantwort",
  "ccmessaging.chat.chatmanage.save":"Speichern",
  "ccmessaging.chat.chatmanage.savefailed":"Speichern fehlgeschlagen.",
  "ccmessaging.chat.chatmanage.savesuccess":"Erfolgreich gespeichert.",
  "ccmessaging.chat.chatmanage.sessionEndText":"Schlussfolgerung",
  "ccmessaging.chat.chatmanage.sessionEndTextMorethan":"Die Beschreibung des Dialogfeldabschlusses darf nicht mehr als 100 Zeichen enthalten.",
  "ccmessaging.chat.chatmanage.sessionEndTextnotnull":"Die Beschreibung des Sitzungsendes darf nicht leer sein.",
  "ccmessaging.chat.chatmanage.specialCharacters":"Der Wert enthält Sonderzeichen. Bitte ändern Sie sie.",
  "ccmessaging.chat.chatmanage.startTime":"Startzeit",
  "ccmessaging.chat.chatmanage.startTimeNum":"Die Startzeit muss eine Zahl sein.",
  "ccmessaging.chat.chatmanage.startTimeRange":"Die Startzeitnummer muss zwischen 0 und 24 liegen",
  "ccmessaging.chat.chatmanage.success":"Erfolg",
  "ccmessaging.chat.chatmanage.useRobot":"Mit Chatbot verbinden",
  "ccmessaging.chat.chatmanage.workTime":"Arbeitszeit des Agenten (im 24-Stunden-Format)",
  "ccmessaging.chat.chatmanage.workTimeMorethan":"Die Arbeitszeitbeschreibung darf nicht mehr als 100 Zeichen enthalten.",
  "ccmessaging.chat.chatpersonalization.add":"Hinzufügen",
  "ccmessaging.chat.chatpersonalization.avatarerror":"Wählen Sie ein Bild aus.",
  "ccmessaging.chat.chatpersonalization.cancel":"Abbrechen",
  "ccmessaging.chat.chatpersonalization.changeAvatar":"Avatar ändern",
  "ccmessaging.chat.chatpersonalization.concurrentAgentsNumber":"Anzahl der Multimedia-Clients, die gleichzeitig von Agenten bedient werden",
  "ccmessaging.chat.chatpersonalization.concurrentNumberPriority":"Die Konfiguration der Anzahl der Multimedia-Clients, die gleichzeitig von Agenten bedient werden, hat folgende Priorität: Agentenebene>Organisationsebene>Mandantenebene. Der Wert 0 gibt an, dass die Konfiguration dieser Ebene nicht wirksam wird und die Konfiguration anderer Ebenen verwendet wird.",
  "ccmessaging.chat.chatpersonalization.delete":"Löschen",
  "ccmessaging.chat.chatpersonalization.edit":"Bearbeiten",
  "ccmessaging.chat.chatpersonalization.hour":"Stunde",
  "ccmessaging.chat.chatpersonalization.MaximumConcurrent":"Maximale Anzahl gleichzeitiger Dienstkunden",
  "ccmessaging.chat.chatpersonalization.nickname":"Nickname",
  "ccmessaging.chat.chatpersonalization.operating":"Betrieb",
  "ccmessaging.chat.chatpersonalization.prompt":"Aufforderung",
  "ccmessaging.chat.chatpersonalization.save":"Speichern",
  "ccmessaging.chat.chatpersonalization.savefailed":"Speichern fehlgeschlagen.",
  "ccmessaging.chat.chatpersonalization.saveSuccess":"Erfolgreich gespeichert.",
  "ccmessaging.chat.chatpersonalization.specialStrValidateFailed":"Der Wert darf keine Sonderzeichen enthalten.",
  "ccmessaging.chat.chatpersonalization.tips1":"Die gesendete Konfiguration wurde nicht verifiziert!",
  "ccmessaging.chat.chatpersonalization.welcome":"Grüße",
  "ccmessaging.chat.chatpersonalization.welcomeLimit":"Es können maximal vier personalisierte Grußformeln konfiguriert werden.",
  "ccmessaging.chat.chatpersonalization.welcomeText":"Persönliche Grüße",
  "ccmessaging.chat.chatpersonalization.welcometime":"Zeitbereich",
  "ccmessaging.chat.chatpersonalization.welcometimeerror":"Geben Sie ein normales Zeitsegment ein!",
  "ccmessaging.chat.chatpersonalization.welcometimeerror.nolist":"Bitte legen Sie eine personalisierte Begrüßung fest!",
  "ccmessaging.chat.chatpersonalization.welcometimeerror.startGreaterThanEnd":"Die Startzeit der personalisierten Begrüßung darf nicht größer oder gleich der Endzeit sein!",
  "ccmessaging.chat.chatpersonalization.welcometimeerror.timeOverlap":"Der Zeitraum personalisierter Grüße darf sich nicht überschneiden!",
  "ccmessaging.chat.chinese.separator":":",
  "ccmessaging.chat.clickCallClient.denial":"Der Link ist abgelaufen. Wenden Sie sich gegebenenfalls erneut an den Agenten, um den Link zu senden.",
  "ccmessaging.chat.close":"Sekunden nach dem automatischen Schließen der Seite",
  "ccmessaging.chat.connnect.offlineuser.failed":"Es konnte kein Offline-Anruf als Agent initiiert werden.",
  "ccmessaging.chat.connnect.offlineuser.userId.beyondMaxLength":"UserId liegt über 128",
  "ccmessaging.chat.contact.callin":"Anruf eingehend",
  "ccmessaging.chat.contact.callinandcallout":"Eingehender und ausgehender Anruf",
  "ccmessaging.chat.contact.callout":"Ausgehender Anruf",
  "ccmessaging.chat.email.AgentAccountName":"Agentenkonto",
  "ccmessaging.chat.email.agentNumber":"Agenten-ID",
  "ccmessaging.chat.email.autosave":"Ihre Änderungen wurden automatisch als Entwurf gespeichert.",
  "ccmessaging.chat.email.button.advancedSearch":"Erweiterte Suche",
  "ccmessaging.chat.email.button.batchAssignment":"Stapelzuweisung",
  "ccmessaging.chat.email.button.bulkMove":"Massenbewegung",
  "ccmessaging.chat.email.card.reply":"Antworten",
  "ccmessaging.chat.email.channel.attendeeError":"Die Anzahl der Cc-Empfänger überschreitet den Grenzwert.",
  "ccmessaging.chat.email.channel.BccSenderError":"Die Anzahl der BCC-Empfänger überschreitet den Grenzwert.",
  "ccmessaging.chat.email.channel.ccandbcc.containchannelemail":"Die Cc- und Bcc-Empfänger können nicht die öffentlichen E-Mail-Adressen von Channel-Partnern sein.",
  "ccmessaging.chat.email.channel.confdeletedraft":"Möchten Sie den E-Mail-Entwurf wirklich löschen?",
  "ccmessaging.chat.email.channel.confdmodifydraft":"Sind Sie sicher, dass Sie die E-Mail verarbeitet haben?",
  "ccmessaging.chat.email.channel.deletedraft":"Bestätigen",
  "ccmessaging.chat.email.channel.deletedraftfail":"Der E-Mail-Entwurf konnte nicht gelöscht werden.",
  "ccmessaging.chat.email.channel.deletedraftsuccess":"Der E-Mail-Entwurf wurde erfolgreich gelöscht.",
  "ccmessaging.chat.email.channel.draftOverMax":"Die Anzahl der E-Mail-Entwürfe überschreitet das Maximum.",
  "ccmessaging.chat.email.channel.emailaddressinvalid":"Die E-Mail-Adresse ist ungültig.",
  "ccmessaging.chat.email.channel.modifydraft":"Bestätigen",
  "ccmessaging.chat.email.channel.modifydraftfail":"Änderung fehlgeschlagen.",
  "ccmessaging.chat.email.channel.modifydraftsuccess":"Änderung erfolgreich.",
  "ccmessaging.chat.email.channel.paramisempty":"Der Parameter darf nicht leer sein.",
  "ccmessaging.chat.email.channel.paramiserror":"Der Parameter ist ungültig.",
  "ccmessaging.chat.email.channel.receiveGateway":"E-Mail-Empfangsgateway",
  "ccmessaging.chat.email.channel.receivererror":"Der Empfänger enthält nicht den ausgewählten Benutzer.",
  "ccmessaging.chat.email.channel.savedraftfail":"Die E-Mail konnte nicht als Entwurf gespeichert werden.",
  "ccmessaging.chat.email.channel.savedraftsuccess":"Die E-Mail wurde erfolgreich als Entwurf gespeichert.",
  "ccmessaging.chat.email.channel.sendGateway":"Gateway zum Senden von E-Mails",
  "ccmessaging.chat.email.channel.title.reply":"Antworten:",
  "ccmessaging.chat.email.check.containGatewayEmail":"Senden Sie keine E-Mails an Kanäle und erhalten Sie keine E-Mail-Adressen.",
  "ccmessaging.chat.email.check.contentOverMax":"Die Nachrichtentextgröße überschreitet den Grenzwert.",
  "ccmessaging.chat.email.check.localImageNumberOverMax":"Die Anzahl der lokalen Bilder, die im E-Mail-Text hochgeladen wurden, überschreitet das Maximum.",
  "ccmessaging.chat.email.check.localImageOverMax":"Die Größe der lokalen Bilder, die im E-Mail-Text hochgeladen werden, überschreitet die maximale Größe.",
  "ccmessaging.chat.email.check.localImageType":"Bildformat wird nicht unterstützt.",
  "ccmessaging.chat.email.check.uploadATTACHMENTPermissionAndFeature":"Keine Berechtigung zum Hochladen von Anhängen oder diese Funktion ist nicht aktiviert.",
  "ccmessaging.chat.email.check.uploadImagePermissionAndFeature":"Keine Berechtigung zum Hochladen lokaler Bilder oder diese Funktion ist nicht aktiviert.",
  "ccmessaging.chat.email.forward":"Vorwärts",
  "ccmessaging.chat.email.forward.prefix":"Weiter:",
  "ccmessaging.chat.email.localImage.confirm":"Bestätigen",
  "ccmessaging.chat.email.localImage.height":"Höhe",
  "ccmessaging.chat.email.localImage.lable":"Datei",
  "ccmessaging.chat.email.localImage.width":"Breite",
  "ccmessaging.chat.email.param.empty":"Der Titel oder Inhalt der E-Mail darf nicht leer sein.",
  "ccmessaging.chat.email.reply":"Antworten",
  "ccmessaging.chat.email.reply.separator":":",
  "ccmessaging.chat.email.tips.addFailDuplicateName":"Hinzufügen fehlgeschlagen: Doppelter Name.",
  "ccmessaging.chat.email.tips.addFailOtherErrors":"Hinzufügen fehlgeschlagen: Weitere Fehler.",
  "ccmessaging.chat.email.tips.addMaximumLimit":"Maximal 20 Unterverzeichnisse auf derselben Ebene.",
  "ccmessaging.chat.email.tips.addSuccess":"Hinzufügen erfolgreich.",
  "ccmessaging.chat.email.tips.amendFailDuplicateName":"Änderung fehlgeschlagen: Doppelter Name.",
  "ccmessaging.chat.email.tips.amendFailOtherErrors":"Änderung fehlgeschlagen: Weitere Fehler.",
  "ccmessaging.chat.email.tips.amendSuccess":"Änderung erfolgreich.",
  "ccmessaging.chat.email.tips.deleteFailExistEmail":"Löschen fehlgeschlagen: Im aktuellen Verzeichnis befinden sich E-Mails.",
  "ccmessaging.chat.email.tips.deleteFailExistSub":"Löschen fehlgeschlagen: Das aktuelle Verzeichnis hat Unterverzeichnisse.",
  "ccmessaging.chat.email.tips.deleteFailOtherErrors":"Löschen fehlgeschlagen: Weitere Fehler.",
  "ccmessaging.chat.email.tips.deleteSuccess":"Erfolgreich gelöscht.",
  "ccmessaging.chat.email.tips.isDeleteDirectory":"Möchten Sie das aktuelle Verzeichnis löschen?",
  "ccmessaging.chat.emailmessage.abandoned":"Aufgegeben",
  "ccmessaging.chat.emailmessage.agent":"Agent",
  "ccmessaging.chat.emailmessage.all":"Alle",
  "ccmessaging.chat.emailmessage.assigned":"Zugewiesen",
  "ccmessaging.chat.emailmessage.assignEmail.inputAgentName":"Geben Sie den Namen des Agentenkontos ein.",
  "ccmessaging.chat.emailmessage.assignEmail.queryAgentName":"Geben Sie ein Geschäftskonto ein.",
  "ccmessaging.chat.emailmessage.assignEmailFailed":"Die E-Mail-Adresse konnte nicht zugewiesen werden.",
  "ccmessaging.chat.emailmessage.assignEmailPartialSuccess":"Einige E-Mails konnten nicht zugewiesen werden.",
  "ccmessaging.chat.emailmessage.assignEmailSuccess":"Die E-Mail-Adresse wurde erfolgreich zugewiesen.",
  "ccmessaging.chat.emailmessage.assigning":"Zuweisen",
  "ccmessaging.chat.emailmessage.assignOtherEmailFailed":"Die E-Mail des Absenders kann nicht zugewiesen werden, da sie bearbeitet wird.",
  "ccmessaging.chat.emailmessage.assignStatus":"Zuordnungsstatus",
  "ccmessaging.chat.emailmessage.channel":"Selbstbeantwortung des Kanals",
  "ccmessaging.chat.emailmessage.channelAccessCode":"E-Mail-Kanal-Zugangscode",
  "ccmessaging.chat.emailmessage.disAssigned":"Nicht zugewiesen",
  "ccmessaging.chat.emailmessage.fail.assign.AbandonedEmail.assigning.tip":"Gelöschte Nachrichten können nicht zugewiesen werden.",
  "ccmessaging.chat.emailmessage.fail.assign.AbandonedEmail.moving.tip":"Abgeworfene Nachrichten können nicht verschoben werden.",
  "ccmessaging.chat.emailmessage.fail.assign.UnAssignedEmail.tip":"Nicht zugewiesene Mails können nicht zugewiesen werden.",
  "ccmessaging.chat.emailmessage.handler":"Handler",
  "ccmessaging.chat.emailmessage.handlerType":"Handlertyp",
  "ccmessaging.chat.emailmessage.handleStatus":"Bearbeitungsstatus",
  "ccmessaging.chat.emailmessage.moving":"Verschieben",
  "ccmessaging.chat.emailmessage.operation.noMoreThanOneDay":"Der Abfragezeitraum darf einen Tag nicht überschreiten.",
  "ccmessaging.chat.emailmessage.operation.noMoreThanSevenDay":"Der Abfragezeitraum darf sieben Tage nicht überschreiten.",
  "ccmessaging.chat.emailmessage.receiver":"Empfänger",
  "ccmessaging.chat.emailmessage.robot":"Roboter",
  "ccmessaging.chat.emailmessage.sender":"Absender",
  "ccmessaging.chat.emailmessage.sendStatus":"Ergebnis wird gesendet",
  "ccmessaging.chat.emailmessage.sendTime":"Gesendet am",
  "ccmessaging.chat.emailmessage.skillQueue":"Skill-Warteschlange",
  "ccmessaging.chat.emailmessage.title":"E-Mail-Titel",
  "ccmessaging.chat.emailmessage.unassigned":"Zuzuweisen",
  "ccmessaging.chat.EMOTICONS.beyondFileSize":"Die Anzahl der hochgeladenen Multimediadateien ist fehlerhaft oder überschreitet das Maximum: {n} KB!",
  "ccmessaging.chat.facebookpageconfig.add":"Facebook-Startseite hinzufügen",
  "ccmessaging.chat.facebookpageconfig.config":"Konfiguration",
  "ccmessaging.chat.facebookpageconfig.deleteError":"Die Einstellungen der Facebook-Homepage konnten nicht gelöscht werden.",
  "ccmessaging.chat.facebookpageconfig.deleteSuccess":"Die Einstellungen der Facebook-Homepage wurden erfolgreich gelöscht.",
  "ccmessaging.chat.facebookpageconfig.facebookconfig":"Einstellungen für die Facebook-Startseite",
  "ccmessaging.chat.facebookpageconfig.idNotExist":"Die Daten sind nicht vorhanden.",
  "ccmessaging.chat.facebookpageconfig.isDeleteFacebookPage":"Möchten Sie die Einstellungen der Facebook-Startseite wirklich löschen?",
  "ccmessaging.chat.facebookpageconfig.modify":"Facebook-Startseite bearbeiten",
  "ccmessaging.chat.facebookpageconfig.modifyError":"Die Facebook-Homepage konnte nicht geändert werden.",
  "ccmessaging.chat.facebookpageconfig.modifySuccess":"Die Facebook-Homepage wurde erfolgreich geändert.",
  "ccmessaging.chat.facebookpageconfig.numoversize":"Die Anzahl der Facebook-Homepages überschreitet die Obergrenze für diesen Kanal.",
  "ccmessaging.chat.facebookpageconfig.pageId":"Seiten-ID",
  "ccmessaging.chat.facebookpageconfig.pageidexist":"Die Facebook-Homepage-ID ist bereits vorhanden.",
  "ccmessaging.chat.facebookpageconfig.saveError":"Die Facebook-Homepage konnte nicht hinzugefügt werden.",
  "ccmessaging.chat.facebookpageconfig.saveSuccess":"Die Facebook-Homepage wurde erfolgreich hinzugefügt.",
  "ccmessaging.chat.facebookpageconfig.tokenSecret":"Token-Geheimnis",
  "ccmessaging.chat.fivegCard":"5G-Karte",
  "ccmessaging.chat.ifame.param":"Parameter",
  "ccmessaging.chat.iframeagentconsole.mail":"E-Mail",
  "ccmessaging.chat.iframeagentconsole.mailCollapse":"eingeklemmt",
  "ccmessaging.chat.iframeagentconsole.mailExpand":"Einzelheiten",
  "ccmessaging.chat.label.accessCode":"Zugangscode",
  "ccmessaging.chat.label.extCode":"Erweiterungscode",
  "ccmessaging.chat.label.skill":"Skill-Warteschlange",
  "ccmessaging.chat.localFile.authFail":"Keine Berechtigung zum Senden lokaler Dateien",
  "ccmessaging.chat.multi.card":"Rotierende 5G-Karte",
  "ccmessaging.chat.multi.card.add":"Karte auswählen",
  "ccmessaging.chat.multi.card.id":"Karten-ID",
  "ccmessaging.chat.multi.card.num.error":"Fügen Sie 1 bis 5 Einzelkarten hinzu.",
  "ccmessaging.chat.multi.chatcard.alignment":"Bildausrichtungsmodus",
  "ccmessaging.chat.multi.chatcard.alignment.left":"Ausrichtung links",
  "ccmessaging.chat.multi.chatcard.alignment.right":"Rechte Ausrichtung",
  "ccmessaging.chat.multi.chatcard.choose":"Kartenauswahl",
  "ccmessaging.chat.multi.chatcard.delete":"Auf die Vorlage wurde verwiesen und sie kann nicht gelöscht werden. Die referenzierte NVOD-Karte ist:",
  "ccmessaging.chat.multi.chatcard.sort":"Kartenanordnungsrichtung",
  "ccmessaging.chat.multi.chatcard.sort.flow":"schwimmt",
  "ccmessaging.chat.multi.chatcard.sort.horizontal":"Nivellierung",
  "ccmessaging.chat.multi.chatcard.sort.vertical":"Senkrecht",
  "ccmessaging.chat.multi.chatcard.width":"Kartenbreite",
  "ccmessaging.chat.multi.chatcard.width.medium":"Mittelbreite",
  "ccmessaging.chat.multi.chatcard.width.small":"Geringere Breite",
  "ccmessaging.chat.multi.media.resource.invalid":"Karten, die auf ungültige Medienressourcen verweisen. Kartenname:",
  "ccmessaging.chat.multimedia.addCategory":"Kategorie hinzufügen",
  "ccmessaging.chat.multimedia.audioManagement":"Audiobibliothek",
  "ccmessaging.chat.multimedia.audioUploadTipFormat":"Die hochzuladende Audiodatei muss im MP3-, AAC- oder M4A-Format vorliegen und die Dateigröße darf {n} MB nicht überschreiten.",
  "ccmessaging.chat.multimedia.beyondFileSize":"Die Größe der hochgeladenen Multimediadatei überschreitet das Maximum: {n} MB!",
  "ccmessaging.chat.multimedia.cancel":"Abbrechen",
  "ccmessaging.chat.multimedia.cardManagement":"Kartenvorlage",
  "ccmessaging.chat.multimedia.categoryName":"Kategoriename",
  "ccmessaging.chat.multimedia.chatPhraseManagement":"ChatPhrase-Bibliothek",
  "ccmessaging.chat.multimedia.cobrowseSite":"Website durchsuchen",
  "ccmessaging.chat.multimedia.createRichText":"Rich-Text erstellen",
  "ccmessaging.chat.multimedia.delete":"Löschen",
  "ccmessaging.chat.multimedia.deleteLimit":"Die Ressource wird von der Kartenvorlage {0} referenziert und kann nicht gelöscht werden.",
  "ccmessaging.chat.multimedia.deleteLimit.usedbyODFS":"Die Ressource wurde vom intelligenten IVR referenziert und kann nicht gelöscht werden.",
  "ccmessaging.chat.multimedia.deleteLimit.whatsapp.templatemsg":"Die Ressource kann nicht gelöscht werden, da sie bereits von einer WhatsApp-Vorlagennachricht referenziert wird. Vorlagennachricht:",
  "ccmessaging.chat.multimedia.deleteMultiMediaInfo":"Diese Multimediadaten löschen",
  "ccmessaging.chat.multimedia.deleteMultiMediaInfoError":"Multimediadaten konnten nicht gelöscht werden!",
  "ccmessaging.chat.multimedia.deleteMultiMediaInfoSuccess":"Multimediadaten erfolgreich gelöscht!",
  "ccmessaging.chat.multimedia.deleteMultiMediaType":"Diese Multimedia-Kategorie löschen",
  "ccmessaging.chat.multimedia.deleteMultiMediaTypeError":"Multimedia-Bibliothekskategorie konnte nicht gelöscht werden!",
  "ccmessaging.chat.multimedia.deleteMultiMediaTypeError.usedbyODFS":"Der Multimedia-Bibliothekstyp konnte nicht gelöscht werden. Die Ressourcen, auf die das intelligente IVR verweist, sind vorhanden.",
  "ccmessaging.chat.multimedia.deleteMultiMediaTypeError.referenced.byCard":"Die Multimedia-Kategorie konnte nicht gelöscht werden, da einige Ressourcen bereits von Karten referenziert werden.",
  "ccmessaging.chat.multimedia.deleteMultiMediaTypeError.referenced.byWhatsAppTemplateMsg":"Die Multimedia-Kategorie konnte nicht gelöscht werden, da auf einige Ressourcen bereits durch eine WhatsApp-Vorlagennachricht verwiesen wird.",
  "ccmessaging.chat.multimedia.deleteMultiMediaTypeSuccess":"Multimediakategorie erfolgreich gelöscht!",
  "ccmessaging.chat.multimedia.description":"Beschreibung",
  "ccmessaging.chat.multimedia.descriptionContainsSpecialCharacters":"Die Beschreibung enthält Sonderzeichen.",
  "ccmessaging.chat.multimedia.descriptionSuperLong":"Die Beschreibungslänge überschreitet die maximale Länge.",
  "ccmessaging.chat.multimedia.documentManagement":"Dokumentenbibliothek",
  "ccmessaging.chat.multimedia.documentUploadTipFormat":"Dateien können im Word-, PDF-, PPT-, Excel- oder Zip-Format hochgeladen werden. Die ZIP-Datei unterstützt die Formate Word, PDF, PPT und Excel. Die Dateigröße darf {n} MB nicht überschreiten.",
  "ccmessaging.chat.multimedia.download":"Herunterladen",
  "ccmessaging.chat.multimedia.edit":"Bearbeiten",
  "ccmessaging.chat.multimedia.emoticonsManagement":"Emoticon",
  "ccmessaging.chat.multimedia.emoticonsUploadTipFormat":"Die hochgeladene personalisierte Emoticon-Datei kann im JPG-, JPEG-, PNG- oder GIF-Format vorliegen und die Dateigröße darf {n} KB nicht überschreiten.",
  "ccmessaging.chat.multimedia.exceedFileSize":"Die Anzahl der hochgeladenen Multimedia-Dateitypen ist fehlerhaft oder überschreitet das Maximum: {n}!",
  "ccmessaging.chat.multimedia.fileName":"Dateiname",
  "ccmessaging.chat.multimedia.fileSize":"Dateigröße",
  "ccmessaging.chat.multiMedia.fileUploadError":"Die hochgeladene Datei hat das falsche Format",
  "ccmessaging.chat.multiMedia.fileUploadInjectionRisk":"Dieser Upload birgt Injektionsrisiken. Überprüfen Sie den Inhalt, und versuchen Sie es erneut.",
  "ccmessaging.chat.multimedia.fileUploadLabel":"Wählen Sie eine Datei zum Hochladen aus",
  "ccmessaging.chat.multiMedia.fileUploadNameError":"Das Namensformat der hochgeladenen Datei ist falsch.",
  "ccmessaging.chat.multiMedia.fileUploadTypeError":"Das Format des hochgeladenen Dateityps ist falsch.",
  "ccmessaging.chat.multimedia.finish":"Fertig stellen",
  "ccmessaging.chat.multimedia.getRichTextInfoError":"Rich-Text-Daten konnten nicht abgerufen werden.",
  "ccmessaging.chat.multimedia.id":"Kennung",
  "ccmessaging.chat.multimedia.imageManagement":"Bildbibliothek",
  "ccmessaging.chat.multimedia.imageUploadTipFormat":"Die hochgeladene Bilddatei unterstützt derzeit nur die Formate JPG, PNG und JPEG, und die Dateigröße sollte {n}M nicht überschreiten.",
  "ccmessaging.chat.multimedia.isDeleteMultiMediaInfo":"Möchten Sie diese Multimediadaten wirklich löschen?",
  "ccmessaging.chat.multimedia.isDeleteMultiMediaType":"Möchten Sie den Multimediatyp und seine Multimediadaten wirklich löschen?",
  "ccmessaging.chat.multimedia.latitude":"Breite",
  "ccmessaging.chat.multimedia.latitudeValidateFailed":"Der ganzzahlige Teil für den Breitengrad ist -90~90, und der Dezimalteil ist 0 bis 6 Ziffern!",
  "ccmessaging.chat.multimedia.locationManagement":"Adressbibliothek",
  "ccmessaging.chat.multimedia.longitude":"Längengrad",
  "ccmessaging.chat.multimedia.longitudeValidateFailed":"Der Längengrad-Ganzzahlteil ist -180~180, und der Dezimalteil ist 0 bis 6 Ziffern!",
  "ccmessaging.chat.multimedia.mediaFileType":"Medien-FileType",
  "ccmessaging.chat.multimedia.modifyCategory":"Kategorie ändern",
  "ccmessaging.chat.multimedia.modifyMultiMediaInfo":"Multimediadaten erfolgreich geändert!",
  "ccmessaging.chat.multimedia.modifyMultiMediaInfoError":"Multimediadaten konnten nicht geändert werden!",
  "ccmessaging.chat.multimedia.modifyMultiMediaType":"Multimediakategorie erfolgreich geändert!",
  "ccmessaging.chat.multimedia.modifyMultiMediaTypeError":"Multimedia-Bibliothekskategorie konnte nicht geändert werden!",
  "ccmessaging.chat.multimedia.modifyRichText":"Rich-Text bearbeiten",
  "ccmessaging.chat.multimedia.multiMediaInfoInputEmpty":"Die Multimedia-Eingabedaten sind leer!",
  "ccmessaging.chat.multimedia.multiMediaInfoInputInvalid":"Die Multimedia-Eingabedaten sind ungültig!",
  "ccmessaging.chat.multimedia.multiMediaManagement":"Multimediabibliothekseinstellungen",
  "ccmessaging.chat.multimedia.multiMediaNameExist":"Der Multimediadatenname existiert bereits in dieser Kategorie!",
  "ccmessaging.chat.multimedia.multiMediaTypeIdNotExist":"Die ausgewählte Kategorie der Multimedia-Bibliothek existiert nicht.Wählen Sie die Kategorie der Multimedia-Bibliothek aus!",
  "ccmessaging.chat.multimedia.multiMediaTypeInputEmpty":"Die Eingabedaten der Multimedia-Kategorie sind leer!",
  "ccmessaging.chat.multimedia.multiMediaTypeInputInvalid":"Die Eingabedaten der Multimedia-Kategorie sind ungültig!",
  "ccmessaging.chat.multimedia.multiMediaTypeNameExist":"Der Name der Multimedia-Kategorie ist bereits vorhanden!",
  "ccmessaging.chat.multimedia.name":"Name",
  "ccmessaging.chat.multimedia.nameContainsSpecialCharacters":"Der Name enthält Sonderzeichen.",
  "ccmessaging.chat.multimedia.nameSuperLong":"Die Namenslänge überschreitet die maximale Länge.",
  "ccmessaging.chat.multimedia.operating":"In Betrieb",
  "ccmessaging.chat.multimedia.play":"Abspielen",
  "ccmessaging.chat.multimedia.preview":"Vorschau",
  "ccmessaging.chat.multimedia.richTextContentEmpty":"Der Rich-Text-Inhalt ist leer.",
  "ccmessaging.chat.multimedia.richTextContentLongLimit":"Der Rich-Text-Inhalt darf maximal 5000 Zeichen enthalten.",
  "ccmessaging.chat.multimedia.richTextContentSuperLong":"Der Rich-Text darf maximal 5000 Zeichen enthalten.",
  "ccmessaging.chat.multimedia.richTextManagement":"Reicher Text",
  "ccmessaging.chat.multimedia.richTextTypeEmpty":"Die Rich-Text-Kategorie existiert nicht.",
  "ccmessaging.chat.multimedia.categorizeEmpty":"Wählen oder erstellen Sie zuerst eine Kategorie.",
  "ccmessaging.chat.multimedia.saveMultiMediaInfo":"Multimediadaten erfolgreich gespeichert!",
  "ccmessaging.chat.multimedia.saveMultiMediaInfoError":"Multimediadaten konnten nicht gespeichert werden!",
  "ccmessaging.chat.multimedia.saveMultiMediaType":"Multimedia-Kategorie erfolgreich gespeichert!",
  "ccmessaging.chat.multimedia.saveMultiMediaTypeError":"Multimedia-Bibliothekskategorie konnte nicht gespeichert werden!",
  "ccmessaging.chat.multimedia.selectFile":"Datei auswählen",
  "ccmessaging.chat.multimedia.selectImage":"Bild auswählen",
  "ccmessaging.chat.multimedia.upload":"Hochladen",
  "ccmessaging.chat.multimedia.uploadTime":"Hochladezeit",
  "ccmessaging.chat.multimedia.videoManagement":"Videobibliothek",
  "ccmessaging.chat.multimedia.videoUploadTipFormat":"Die hochgeladene Videodatei unterstützt derzeit nur das MP4-Format, und die Dateigröße sollte {n}M nicht überschreiten.",
  "ccmessaging.chat.multimedia.viewRichText":"Rich-Text anzeigen",
  "ccmessaging.chat.multimedia.whatsAppTemplate":"WhatsApp-Vorlage",
  "ccmessaging.chat.multimediaasccociation.approvalstatus":"Bewertungsstatus",
  "ccmessaging.chat.multimediaasccociation.checkmediainfofailed":"Die Multimediadaten wurden nicht für den zugehörigen 5G-Kanal freigegeben oder nicht genehmigt!",
  "ccmessaging.chat.multimediaasccociation.deleteassociation":"Release-Ergebnis löschen",
  "ccmessaging.chat.multimediaasccociation.deleteassociationinfo":"Möchten Sie das Freigabeergebnis für die Multimediadaten und den zugehörigen 5G-Kanal wirklich löschen?",
  "ccmessaging.chat.multimediaasccociation.deleteError":"Das Freigabeergebnis konnte nicht gelöscht werden!",
  "ccmessaging.chat.multimediaasccociation.deleteSuccess":"Das Freigabeergebnis wurde erfolgreich gelöscht!",
  "ccmessaging.chat.multimediaasccociation.examinationfailed":"Fehlgeschlagen",
  "ccmessaging.chat.multimediaasccociation.examinationpassed":"Genehmigt",
  "ccmessaging.chat.multimediaasccociation.expirationtime":"Abgelaufen am",
  "ccmessaging.chat.multimediaasccociation.isrelease":"Möchten Sie die Multimediadaten wirklich für den 5G-Kanal freigeben?",
  "ccmessaging.chat.multimediaasccociation.refresh":"Aktualisieren",
  "ccmessaging.chat.multimediaasccociation.refreshfailed":"Die Daten konnten nicht aktualisiert werden!",
  "ccmessaging.chat.multimediaasccociation.refreshsuccess":"Die Daten wurden erfolgreich aktualisiert!",
  "ccmessaging.chat.multimediaasccociation.release":"Freigabe",
  "ccmessaging.chat.multimediaasccociation.releaseerror":"Die Multimediadaten konnten nicht freigegeben werden!",
  "ccmessaging.chat.multimediaasccociation.releaseresult":"Release-Ergebnis",
  "ccmessaging.chat.multimediaasccociation.releasesuccess":"Die Multimediadaten wurden erfolgreich freigegeben!",
  "ccmessaging.chat.multimediaasccociation.releasetitle":"Multimediadaten freigeben",
  "ccmessaging.chat.multimediaasccociation.unpublishconfigempty":"Der 5G-Kanal, auf dem die Multimediadaten freigegeben werden sollen, ist nicht konfiguriert.",
  "ccmessaging.chat.multimediaasccociation.unreviewed":"Nicht geprüft",
  "ccmessaging.chat.offlineCall.channelType":"Kanaltyp",
  "ccmessaging.chat.offlineCall.select.noChannel":"Der Kanal existiert nicht.",
  "ccmessaging.chat.offlineCall.userId":"Benutzer-ID",
  "ccmessaging.chat.offlineSwitch.closed":"Senden fehlgeschlagen, da die Offline-Nachrichtenfunktion für den Kanal deaktiviert ist.",
  "ccmessaging.chat.phrase.add":"Hinzufügen",
  "ccmessaging.chat.phrase.addPhraseError":"Erstellen des allgemeinen Satzes fehlgeschlagen",
  "ccmessaging.chat.phrase.addPhraseSuccess":"Gemeinsame Phrase erfolgreich erstellt",
  "ccmessaging.chat.phrase.addType":"Neue Kategorie",
  "ccmessaging.chat.phrase.AllCount":"Wörter insgesamt",
  "ccmessaging.chat.phrase.cancel":"Abbrechen",
  "ccmessaging.chat.phrase.currentCount":"Aktuelle Wortzahl",
  "ccmessaging.chat.phrase.delete":"Löschen",
  "ccmessaging.chat.phrase.deleteType":"Typ löschen",
  "ccmessaging.chat.phrase.delPhraseError":"Das Löschen des allgemeinen Satzes ist fehlgeschlagen",
  "ccmessaging.chat.phrase.delPhraseSucess":"Gemeinsame Phrase erfolgreich gelöscht",
  "ccmessaging.chat.phrase.delPhraseTypeError":"Das Löschen des allgemeinen Phrasentyps ist fehlgeschlagen",
  "ccmessaging.chat.phrase.delPhraseTypeSuccess":"Allgemein verwendeten Ausdruckstyp erfolgreich gelöscht",
  "ccmessaging.chat.phrase.edit":"Bearbeiten",
  "ccmessaging.chat.phrase.editType":"Typ bearbeiten",
  "ccmessaging.chat.phrase.enter_keyword":"Geben Sie ein Stichwort ein.",
  "ccmessaging.chat.phrase.enterPhrase":"Bitte geben Sie einen gängigen Begriff ein",
  "ccmessaging.chat.phrase.enterPhraseType":"Bitte geben Sie einen allgemeinen Ausdruckstyp ein",
  "ccmessaging.chat.phrase.error":"Fehler",
  "ccmessaging.chat.phrase.isDeletePhrase":"Möchten Sie diesen Ausdruck wirklich löschen",
  "ccmessaging.chat.phrase.isDeletePhraseType":"Möchten Sie den allgemeinen Ausdruckstyp und seine gängigen Ausdrücke wirklich löschen?",
  "ccmessaging.chat.phrase.isSavePhrase":"Möchten Sie den allgemeinen Satz wirklich speichern?",
  "ccmessaging.chat.phrase.isSavePhraseType":"Möchten Sie den allgemeinen Ausdruckstyp wirklich speichern?",
  "ccmessaging.chat.phrase.phrase":"Phrase",
  "ccmessaging.chat.phrase.phraseList":"Liste der Phrasen",
  "ccmessaging.chat.phrase.phraseRepeat":"Gemeinsamer Name doppelt",
  "ccmessaging.chat.phrase.phraseType":"Phrasentyp",
  "ccmessaging.chat.phrase.phraseTypeEmpty":"Der allgemeine Ausdruckstyp, der gelöscht werden soll, existiert nicht. Bitte wählen Sie einen gängigen Ausdruckstyp aus.",
  "ccmessaging.chat.phrase.phraseTypeRepeat":"Die allgemeine Phrasenkategorie wird dupliziert",
  "ccmessaging.chat.phrase.phraseTypeLimit":"Die Anzahl der gängigen Phrasentypen erreicht die Obergrenze von 100.",
  "ccmessaging.chat.phrase.prompt":"Aufforderung",
  "ccmessaging.chat.phrase.save":"Speichern",
  "ccmessaging.chat.phrase.saveError":"Speichern fehlgeschlagen",
  "ccmessaging.chat.phrase.saveType":"Speichertyp",
  "ccmessaging.chat.phrase.saveTypeSuccess":"Gemeinsame Sprachkategorie erfolgreich gespeichert",
  "ccmessaging.chat.phrase.shortcut":"ShortCut",
  "ccmessaging.chat.phrase.success":"Erfolg",
  "ccmessaging.chat.phrase.updatePhraseTypeError":"Der allgemeine Phrasentyp konnte nicht aktualisiert werden.",
  "ccmessaging.chat.phrase.updatePhraseTypeSucess":"Der allgemeine Ausdruckstyp wurde erfolgreich aktualisiert.",
  "ccmessaging.chat.phrase.updateError":"Aktualisierungsfehler",
  "ccmessaging.chat.phrase.updatePhraseError":"Aktualisieren der allgemeinen Sprache fehlgeschlagen",
  "ccmessaging.chat.phrase.updatePhraseSucess":"Die allgemeine Sprache wurde erfolgreich aktualisiert",
  "ccmessaging.chat.phrase.updateSuccess":"Aktualisierung erfolgreich",
  "ccmessaging.chat.portraitchange.customize":"Benutzerdefiniert",
  "ccmessaging.chat.portraitchange.systemAvatar":"System-Avatar",
  "ccmessaging.chat.querymessage":"Medienverlaufsnachricht abfragen",
  "ccmessaging.chat.refresh.fail":"Aktualisierung fehlgeschlagen",
  "ccmessaging.chat.repeatClick":"Klicken Sie nicht wiederholt!",
  "ccmessaging.chat.richText.add":"Erstellen",
  "ccmessaging.chat.richText.cancel":"Abbrechen",
  "ccmessaging.chat.richText.idInput":"Geben Sie eine ID ein.",
  "ccmessaging.chat.richText.nameInput":"Geben Sie einen Namen ein.",
  "ccmessaging.chat.richText.openInNewWindow":"In einem neuen Fenster öffnen",
  "ccmessaging.chat.richText.save":"Speichern",
  "ccmessaging.chat.selectchannel.vaildate1":"Der Kanalzugangscode muss eindeutig sein.",
  "ccmessaging.chat.sessiontransfer.tips":"Nur Agenten im Leerlauf und Agenten, deren gleichzeitige Anrufe die Obergrenze nicht erreichen, können weitergeleitet werden.",
  "ccmessaging.chat.silentagent.aceesscode.empty":"Der Zugangscode darf nicht Null sein!",
  "ccmessaging.chat.silentagent.config.accesscode.same":"Die Konfiguration des stillen Agenten der Voice-IVR-Anrufroute ist bereits im aktuellen Leasing vorhanden.",
  "ccmessaging.chat.silentagent.config.del.error":"Die Konfiguration des Agenten für Sprachstummschaltung konnte nicht gelöscht werden.",
  "ccmessaging.chat.silentagent.config.del.success":"Löschen der Agentenkonfiguration für Sprachstummschaltung war erfolgreich.",
  "ccmessaging.chat.silentagent.config.willdel":"Möchten Sie die ausgewählte Agentenkonfiguration für Sprachstille wirklich löschen?",
  "ccmessaging.chat.silentagent.DeleteFail":"Die automatische Agentenkonfiguration konnte nicht gelöscht werden.",
  "ccmessaging.chat.silentagent.DeleteSuccess":"Das Löschen der Agentenkonfiguration im Hintergrund war erfolgreich.",
  "ccmessaging.chat.silentagent.sendContent.noOIAPNode":"Der stille Agent kann keine Informationen über den aktuellen Knoten abrufen!",
  "ccmessaging.chat.silentagent.sendContent.supervise":"Silent Agent kann keine Nachricht im Supervise-Arbeitsmodus senden!",
  "ccmessaging.chat.silentagent.silent.skill.empty":"Die Skill-Warteschlange des stillen Agenten darf nicht leer sein",
  "ccmessaging.chat.silentAgent.workMode.changeWorkMode.fail":"Die stille Agentenänderung des Arbeitsmodus ist fehlgeschlagen!",
  "ccmessaging.chat.silentAgent.workMode.changeWorkMode.prompt":"Tipp",
  "ccmessaging.chat.silentAgent.workMode.changeWorkMode.success":"Der SilentAgent hat den Arbeitsmodus erfolgreich gewechselt!",
  "ccmessaging.chat.silentAgent.workMode.common":"Allgemein",
  "ccmessaging.chat.silentAgent.workMode.insert":"Einfügen",
  "ccmessaging.chat.silentAgent.workMode.supervise":"Beaufsichtigung",
  "ccmessaging.chat.silentAgent.workMode.transfer":"Überweisung",
  "ccmessaging.chat.single.media.resource.invalid":"Karten, die auf ungültige Medienressourcen verweisen.",
  "ccmessaging.chat.sms.channel.gateway":"SMS-Gateway",
  "ccmessaging.chat.social.media.enterprise.default.username":"Anonymer Benutzer",
  "ccmessaging.chat.social.media.enterprise.manage":"Nachrichtenverarbeitung des Unternehmenskontos",
  "ccmessaging.chat.social.media.enterprise.post.add.failed":"Hinzufügen fehlgeschlagen.",
  "ccmessaging.chat.social.media.enterprise.post.add.success":"Hinzufügen erfolgreich.",
  "ccmessaging.chat.social.media.enterprise.post.all.reply":"Alle Antworten",
  "ccmessaging.chat.social.media.enterprise.post.batch.reply":"Stapelantwort",
  "ccmessaging.chat.social.media.enterprise.post.cancel":"Abbrechen",
  "ccmessaging.chat.social.media.enterprise.post.comment":"Kommentar zu",
  "ccmessaging.chat.social.media.enterprise.post.comment.tips":"Kommentar hinzufügen ...",
  "ccmessaging.chat.social.media.enterprise.post.reply":"Antworten",
  "ccmessaging.chat.social.media.enterprise.post.reply.cancel":"Abbrechen",
  "ccmessaging.chat.social.media.enterprise.post.reply.tips":"Antwort hinzufügen ...",
  "ccmessaging.chat.social.media.enterprise.post.title":"Beitragsname",
  "ccmessaging.chat.social.media.enterprise.post.topcomment.cancel":"Kommentar abbrechen",
  "ccmessaging.chat.social.media.enterprise.tips.close":"Falten",
  "ccmessaging.chat.social.media.enterprise.tips.imgnum":"Es kann maximal ein Image hinzugefügt werden.",
  "ccmessaging.chat.social.media.enterprise.tips.open":"Entfalten",
  "ccmessaging.chat.social.media.multimedia.manage":"Verarbeitung von Multimedia-Nachrichten",
  "ccmessaging.chat.speciallist.channels":"Eingeschränkte Kanäle",
  "ccmessaging.chat.speciallist.channelsType":"Eingeschränkter Kanaltyp",
  "ccmessaging.chat.speciallist.choosechannel":"Kanal auswählen",
  "ccmessaging.chat.speciallist.confirmdelete":"Möchten Sie wirklich löschen?",
  "ccmessaging.chat.speciallist.delete":"Aufgegeben",
  "ccmessaging.chat.speciallist.deletelevel":"(Aufgegeben)",
  "ccmessaging.chat.speciallist.emailchannels":"E-Mail-Adresse",
  "ccmessaging.chat.speciallist.haverestrictall":"Alle Kanäle dieses Kanaltyps sind eingeschränkt. Dieser Kanal muss nicht eingeschränkt werden.",
  "ccmessaging.chat.speciallist.haverestrictchannel":"Dieser Kanal ist eingeschränkt. Eine erneute Einschränkung ist nicht erforderlich.",
  "ccmessaging.chat.speciallist.level":"Besondere Listenebene",
  "ccmessaging.chat.speciallist.leveName":"Name des speziellen Listentyps",
  "ccmessaging.chat.speciallist.msgchoosechannel":"Wählen Sie zuerst einen Kanaltyp aus.",
  "ccmessaging.chat.speciallist.remark":"Beschreibung",
  "ccmessaging.chat.speciallist.repeatlevel":"Die spezielle Listenebene ist bereits vorhanden. Erstellen Sie es nicht erneut.",
  "ccmessaging.chat.speciallist.restrictHours":"Begrenzte Dauer",
  "ccmessaging.chat.speciallist.restrictUnit":"Stunde",
  "ccmessaging.chat.speciallist.restrictMode":"Einschränkungsmodus",
  "ccmessaging.chat.speciallist.restrictStyle":"Anwendungsmodus",
  "ccmessaging.chat.speciallist.selectchannel":"Zugewiesener Kanal",
  "ccmessaging.chat.speciallist.smschannels":"SMS",
  "ccmessaging.chat.speciallist.unuse":"Nicht verfügbar",
  "ccmessaging.chat.speciallist.unuselevel":"(Nicht verfügbar)",
  "ccmessaging.chat.speciallist.use":"Verfügbar",
  "ccmessaging.chat.successaccess":"Erfolgreicher Zugriff",
  "ccmessaging.chat.sumbitverification.Appld":"App-ID",
  "ccmessaging.chat.sumbitverification.AppSecret":"Schlüssel",
  "ccmessaging.chat.sumbitverification.facebook.tips":"Rufen Sie die Entwicklerseite von Facebook Messenger auf, um die Website und den Verifizierungscode zu konfigurieren.",
  "ccmessaging.chat.sumbitverification.fiveG.tips":"Bitte gehen Sie zur Konfigurationsseite der 5G-Message-Plattform, um die Website-Adresse zu konfigurieren.",
  "ccmessaging.chat.sumbitverification.line.tips":"Konfigurieren Sie die Webhook-URL im offiziellen Account Manager der LINE.",
  "ccmessaging.chat.sumbitverification.tips":"Konfigurations-URL und Verifizierungscode finden Sie in den Konfigurationsinformationen der öffentlichen Plattformschnittstelle von WeChat",
  "ccmessaging.chat.sumbitverification.tips.qr":"In diesem Autorisierungsmodus ist keine weitere Konfiguration erforderlich. Fahren Sie mit dem nächsten Schritt fort.",
  "ccmessaging.chat.sumbitverification.Url":"URL",
  "ccmessaging.chat.sumbitverification.Url.infobipMessAgeUrl":"URL, die zum Senden der Nachricht an den Agenten verwendet wird",
  "ccmessaging.chat.sumbitverification.Url.infobipReportUrl":"URL, die zum Senden des Berichts an den Agenten verwendet wird",
  "ccmessaging.chat.sumbitverification.VerityCode":"Verifizierungscode",
  "ccmessaging.chat.sumbitverification.whatsApp.fail":"Fehler generieren",
  "ccmessaging.chat.sumbitverification.whatsApp.prompt":"Tipp",
  "ccmessaging.chat.sumbitverification.whatsApp.success":"Erfolg generieren",
  "ccmessaging.chat.sumbitverification.whatsApp.tips":"Vom WhatsApp-Diensteanbieter zur Verarbeitung von Nachrichten verwendete URL",
  "ccmessaging.chat.system.error":"Das System ist ausgelastet. Versuchen Sie es später erneut.",
  "ccmessaging.chat.template.buttonSend":"Senden",
  "ccmessaging.chat.template.butttonName":"Auswählen",
  "ccmessaging.chat.template.card":"Nachricht der WhatsApp-Vorlage",
  "ccmessaging.chat.template.Description":"Beschreibung",
  "ccmessaging.chat.template.languageCode":"Sprache",
  "ccmessaging.chat.template.name":"Name der Vorlagennachricht",
  "ccmessaging.chat.template.namePlaceholder":"Geben Sie einen Nachrichtennamen ein.",
  "ccmessaging.chat.template.table.button":"Schaltfläche",
  "ccmessaging.chat.template.table.buttonPlaceholder":"Schnelle Antwort auf die Schaltfläche.",
  "ccmessaging.chat.template.table.no":"Nein.",
  "ccmessaging.chat.template.table.parameterContent":"Parameterinhalt",
  "ccmessaging.chat.template.table.parameterDescription":"Parameterbeschreibung",
  "ccmessaging.chat.template.table.parameterPlaceholder":"Geben Sie einen Parameter ein.",
  "ccmessaging.chat.template.whatsAppChannel":"Kanal",
  "ccmessaging.chat.template.whatsAppTemplateName":"Vorlagenname",
  "ccmessaging.chat.templateMsg.chooseMultimedia":"Wählen Sie eine Multimediaressource aus.",
  "ccmessaging.chat.templateMsg.sendTemplateMsg":"Senden",
  "ccmessaging.chat.templateMsg.sendTemplateMsgTips":"Vorlagennachricht senden",
  "ccmessaging.chat.tinymce.button.clear":"klar",
  "ccmessaging.chat.tinyMCE.FangSong":"FangSong",
  "ccmessaging.chat.tinyMCE.SimHei":"SimHei",
  "ccmessaging.chat.tinyMCE.simsun":"Simsun",
  "ccmessaging.chat.tinyMCE.yaHei":"YaHei von Microsoft",
  "ccmessaging.chat.uploadLocalFile":"Cargar lokales Archiv",
  "ccmessaging.chat.warning.sharescreen":"Achten Sie auf die Privatsphäre, wenn Sie den Desktop freigeben",
  "ccmessaging.chat.web.agent.assist":"Agentenassistent",
  "ccmessaging.chat.web.agent.prohibitedmess":"Verbotene Sprache! Bitte achten Sie auf die Wörter.",
  "ccmessaging.chat.web.agent.wrogmess":"Unangemessen formuliert! Bitte achten Sie darauf, angemessene Wörter zu verwenden.",
  "ccmessaging.chat.web.channel.called.config":"CTD-Konfiguration des angerufenen Teilnehmers",
  "ccmessaging.chat.web.channel.clickivr":"Klicken Sie hier, um den IVR-Zugangscode abzurufen.",
  "ccmessaging.chat.webCard":"WebCard",
  "ccmessaging.chat.webCard.copyUrl":"Link kopieren",
  "ccmessaging.chat.webCard.copyUrl.success":"Link erfolgreich kopiert",
  "ccmessaging.chat.webcard.pop.confirm.outside":"Ob zu Links von Drittanbietern gesprungen werden soll?",
  "ccmessaging.chat.webcard.pop.confirm.prefix":"Die Nachricht wird an die externe Adresse umgeleitet, die Website-Domain lautet“",
  "ccmessaging.chat.webcard.pop.confirm.suffix":"Möchten Sie wirklich fortfahren?",
  "ccmessaging.chat.webcard.pop.error.prefix":"Die Website-Domain",
  "ccmessaging.chat.webcard.pop.error.suffix":"steht nicht auf der weißen Liste der Adressen. Wenden Sie sich an das Betriebspersonal, um es zur Whitelist hinzuzufügen.",
  "ccmessaging.chat.webclient.chatapi.evaluation":"Bitte bewerten Sie diesen Service, vielen Dank!",
  "ccmessaging.chat.webclient.chatapi.failTransToAgent":"Umwandlung in Agenten fehlgeschlagen. Bitte versuchen Sie es erneut oder wenden Sie sich an den Administrator.",
  "ccmessaging.chat.webclient.chathtml.customer":"Kunde",
  "ccmessaging.chat.webclient.chatjs.inpuStatus":"Die andere Partei tippt.“ ",
  "ccmessaging.chat.whatsapp.body":"Körper",
  "ccmessaging.chat.whatsapp.buttons":"Schaltflächen",
  "ccmessaging.chat.whatsapp.footer":"Fußzeile",
  "ccmessaging.chat.whatsapp.header":"Kopfzeile",
  "ccmessaging.chat.whatsapp.languageAF":"Niederländisch, Südafrika",
  "ccmessaging.chat.whatsapp.languageAR":"Arabisch",
  "ccmessaging.chat.whatsapp.languageAZ":"Aserbaidschanisch",
  "ccmessaging.chat.whatsapp.languageBG":"Bulgarisch",
  "ccmessaging.chat.whatsapp.languageBN":"Bengalisch",
  "ccmessaging.chat.whatsapp.languageCA":"Katalonisch",
  "ccmessaging.chat.whatsapp.languageCS":"Tschechisch",
  "ccmessaging.chat.whatsapp.languageDA":"Dänisch",
  "ccmessaging.chat.whatsapp.languageDE":"Deutsch",
  "ccmessaging.chat.whatsapp.languageEL":"Griechisch",
  "ccmessaging.chat.whatsapp.languageEN":"Englisch",
  "ccmessaging.chat.whatsapp.languageENGB":"Englisch (Vereinigtes Königreich)",
  "ccmessaging.chat.whatsapp.languageES":"Spanisch",
  "ccmessaging.chat.whatsapp.languageESAR":"Spanisch (Argentinien)",
  "ccmessaging.chat.whatsapp.languageESES":"Spanisch (Spanien)",
  "ccmessaging.chat.whatsapp.languageESMX":"Spanisch (Mexiko)",
  "ccmessaging.chat.whatsapp.languageET":"Estnisch",
  "ccmessaging.chat.whatsapp.languageFA":"Persisch",
  "ccmessaging.chat.whatsapp.languageFI":"Finnisch",
  "ccmessaging.chat.whatsapp.languageFIL":"Philippinen",
  "ccmessaging.chat.whatsapp.languageFR":"Französisch",
  "ccmessaging.chat.whatsapp.languageGA":"Irisch",
  "ccmessaging.chat.whatsapp.languageGU":"Gujarat",
  "ccmessaging.chat.whatsapp.languageHA":"Hausa",
  "ccmessaging.chat.whatsapp.languageHE":"Hebräisch",
  "ccmessaging.chat.whatsapp.languageHI":"Hindi",
  "ccmessaging.chat.whatsapp.languageHR":"Kroatisch",
  "ccmessaging.chat.whatsapp.languageHU":"Ungarisch",
  "ccmessaging.chat.whatsapp.languageID":"Indonesisch",
  "ccmessaging.chat.whatsapp.languageIT":"Italienisch",
  "ccmessaging.chat.whatsapp.languageJA":"Japanisch",
  "ccmessaging.chat.whatsapp.languageKK":"Kasachisch",
  "ccmessaging.chat.whatsapp.languageKN":"Kanadawen",
  "ccmessaging.chat.whatsapp.languageKO":"Koreanisch",
  "ccmessaging.chat.whatsapp.languageLO":"Laotisch",
  "ccmessaging.chat.whatsapp.languageLT":"Litauisch",
  "ccmessaging.chat.whatsapp.languageLV":"Lettisch",
  "ccmessaging.chat.whatsapp.languageMK":"Nordmakedonisch",
  "ccmessaging.chat.whatsapp.languageML":"Malayalam",
  "ccmessaging.chat.whatsapp.languageMR":"Marathi",
  "ccmessaging.chat.whatsapp.languageMS":"Malaysien",
  "ccmessaging.chat.whatsapp.languageNB":"Norwegisch",
  "ccmessaging.chat.whatsapp.languageNL":"Niederländisch",
  "ccmessaging.chat.whatsapp.languagePA":"Pandschabi",
  "ccmessaging.chat.whatsapp.languagePL":"Polnisch",
  "ccmessaging.chat.whatsapp.languagePTBR":"Portugiesisch (Brasilien)",
  "ccmessaging.chat.whatsapp.languagePTPT":"Portugiesisch (Portugal)",
  "ccmessaging.chat.whatsapp.languageRO":"Rumänisch",
  "ccmessaging.chat.whatsapp.languageRU":"Russisch",
  "ccmessaging.chat.whatsapp.languageSK":"Slowakisch",
  "ccmessaging.chat.whatsapp.languageSL":"Slowenisch",
  "ccmessaging.chat.whatsapp.languageSQ":"Albanisch",
  "ccmessaging.chat.whatsapp.languageSR":"Serbisch",
  "ccmessaging.chat.whatsapp.languageSV":"Schwedisch",
  "ccmessaging.chat.whatsapp.languageSW":"Swahili",
  "ccmessaging.chat.whatsapp.languageTA":"Tamil",
  "ccmessaging.chat.whatsapp.languageTE":"Telugu",
  "ccmessaging.chat.whatsapp.languageTH":"Thailändisch",
  "ccmessaging.chat.whatsapp.languageTR":"Türkisch",
  "ccmessaging.chat.whatsapp.languageUK":"Ukrainisch",
  "ccmessaging.chat.whatsapp.languageUR":"Urdu",
  "ccmessaging.chat.whatsapp.languageUS":"Englisch (Vereinigte Staaten)",
  "ccmessaging.chat.whatsapp.languageUZ":"Usbekisch",
  "ccmessaging.chat.whatsapp.languageVI":"Vietnamesisch",
  "ccmessaging.chat.whatsapp.languageZHCN":"Chinesisch (vereinfacht),",
  "ccmessaging.chat.whatsapp.languageZHHK":"Traditionelles Chinesisch (Hongkong, China)",
  "ccmessaging.chat.whatsapp.languageZHTW":"Traditionelles Chinesisch (Taiwan, China)",
  "ccmessaging.chat.whatsapp.languageZU":"Zulu",
  "ccmessaging.chat.whatsapp.only.send.template":"Quando um novo usurio do WhatsApp ou o intervalo de contato exceder 24 horas, somente mensagens modelo podem ser enviadas.",
  "ccmessaging.chat.whatsapp.selectTemplateOrgPlaceHolder":"Organisation auswählen",
  "ccmessaging.chat.whatsapp.template.dailyLimit":"Vorlagenverfügbarkeitszeiten pro Tag",
  "ccmessaging.chat.whatsapp.template.dailyLimitDescription":"Bitte geben Sie ein, wie oft die Vorlage pro Tag verfügbar ist.",
  "ccmessaging.chat.whatsapp.template.no":"Nein.",
  "ccmessaging.chat.whatsapp.template.updateTime":"Aktualisiert",
  "ccmessaging.chat.whatsapp.templateAddButton":"Erstellen",
  "ccmessaging.chat.whatsapp.templateBodyParamDesc":"Parameterbeschreibung",
  "ccmessaging.chat.whatsapp.templateBodyParamDescPlaceholder":"Geben Sie die Parameterbeschreibung ein.",
  "ccmessaging.chat.whatsapp.templateBodyText":"Textkörperinhalt",
  "ccmessaging.chat.whatsapp.templateBodyTextPlaceholder":"Geben Sie den Textkörperinhalt ein. Der {'{{}}'}-Inhalt kann im Vorlagenkörper bearbeitet werden.",
  "ccmessaging.chat.whatsapp.templateButton.clicktoAction":"Aktion",
  "ccmessaging.chat.whatsapp.templateButton.none":"N/A",
  "ccmessaging.chat.whatsapp.templateButton.qickReply":"Schnelle Antwort",
  "ccmessaging.chat.whatsapp.templateButtonContent":"Vorlagenschaltflächeninhalt",
  "ccmessaging.chat.whatsapp.templateButtonContentPlaceholder":"Geben Sie den Inhalt der Schaltfläche ein.",
  "ccmessaging.chat.whatsapp.templateButtonName":"Name der Vorlagenschaltfläche",
  "ccmessaging.chat.whatsapp.templateButtonNamePlaceholder":"Geben Sie einen Schaltflächennamen ein.",
  "ccmessaging.chat.whatsapp.templateButtonType":"Vorlagenschaltflächentyp",
  "ccmessaging.chat.whatsapp.templateChannel":"Vorlagenkanal",
  "ccmessaging.chat.whatsapp.templateContent":"Vorlageninhalt",
  "ccmessaging.chat.whatsapp.templateCreate":"WhatsApp-Vorlage erstellen",
  "ccmessaging.chat.whatsapp.templateDescription":"Vorlagenbeschreibung",
  "ccmessaging.chat.whatsapp.templateDescriptionPlaceholder":"Geben Sie die Vorlagenbeschreibung ein.",
  "ccmessaging.chat.whatsapp.templateFooterText":"Fußzeilentextinhalt",
  "ccmessaging.chat.whatsapp.templateFooterTextPlaceholder":"Geben Sie den Textinhalt der Fußzeile ein.",
  "ccmessaging.chat.whatsapp.templateHeaderText":"Kopfzeilentextinhalt",
  "ccmessaging.chat.whatsapp.templateHeaderTextPlaceholder":"Geben Sie den Textinhalt der Kopfzeile ein.",
  "ccmessaging.chat.whatsapp.templateHeaderType":"Kopfzeile",
  "ccmessaging.chat.whatsapp.templateHeaderType.image":"Bild",
  "ccmessaging.chat.whatsapp.templateHeaderType.locate":"Standort",
  "ccmessaging.chat.whatsapp.templateHeaderType.none":"N/A",
  "ccmessaging.chat.whatsapp.templateHeaderType.text":"Text",
  "ccmessaging.chat.whatsapp.templateHeaderType.video":"Video",
  "ccmessaging.chat.whatsapp.templateLanguage":"Sprache",
  "ccmessaging.chat.whatsapp.templateName":"Vorlagenname",
  "ccmessaging.chat.whatsapp.templateNamePlaceholder":"Geben Sie einen Vorlagennamen ein.",
  "ccmessaging.chat.whatsAppTemplateMsg.selectWhatsAppTemplateMsg":"Wählen Sie die entsprechende WhatsApp-Vorlagennachricht aus.",
  "ccmessaging.chat.whatsAppTemplateMsg.selectWhatsAppTemplateMsgId":"Nachrichten-ID der WhatsApp-Vorlage",
  "ccmessaging.chat.whatsAppTemplateMsg.selectWhatsAppTemplateMsgName":"Nachrichtenname der WhatsApp-Vorlage",
  "ccmessaging.chat.workbench.body.iolp":"Serviceanleitung",
  "ccmessaging.chat.workbench.body.transfer":"Anruf weiterleiten",
  "ccmessaging.chat.workbench.transfer.agent":"Agent",
  "ccmessaging.chat.workbench.transfer.noagents":"In der aktuellen Skill-Warteschlange befindet sich kein Online-Agent.",
  "ccmessaging.chat.workbench.transfer.skill":"Skill-Warteschlange",
  "ccmessaging.chat.workbench.transfer.skilllogin":"Verfügbarer Agent",
  "ccmessaging.chat.workbench.transfer.skillname":"Skill-Warteschlangenname",
  "ccmessaging.chat.workbench.transfer.skillqueue":"In Warteschlange warten",
  "ccmessaging.chat.workbench.transfer.skillsearch":"Bitte geben Sie einen Skill-Warteschlangennamen ein",
  "ccmessaging.chatbot.button.avatarImport":"Hochladen",
  "ccmessaging.chatbot.button.avatarImportLimit":"Die Größe der hochzuladenden Datei darf 6 MB nicht überschreiten und die Datei kann im JPG- oder PNG-Format vorliegen.",
  "ccmessaging.chatbot.button.cancel":"Abbrechen",
  "ccmessaging.chatbot.button.edit":"Bearbeiten",
  "ccmessaging.chatbot.label.avatar":"Avatar",
  "ccmessaging.chatbot.label.avatarImport":"Avatar hochladen",
  "ccmessaging.chatbot.label.avatarImportFailed":"Image konnte nicht hochgeladen werden!",
  "ccmessaging.chatbot.label.avatarImportSuccess":"Image erfolgreich hochgeladen!",
  "ccmessaging.chatbot.label.avatarModifing":"Avatar ändern",
  "ccmessaging.chatbot.label.basicInformation":"Grundlegende Informationen",
  "ccmessaging.chatbot.label.commonLanguageSetting":"Gemeinsame Phrasenkonfiguration",
  "ccmessaging.chatbot.label.defaultReply":"Standardantwort",
  "ccmessaging.chatbot.label.gender":"Geschlecht",
  "ccmessaging.chatbot.label.name":"Nickname",
  "ccmessaging.chatbot.label.save":"Speichern",
  "ccmessaging.chatbot.label.timeoutReply":"Timeout-Antwort",
  "ccmessaging.chatbot.label.turnManualPrompt":"Zur Weiterleitung an den Agenten auffordern",
  "ccmessaging.chatbot.label.welcomeMessage":"Grüße",
  "ccmessaging.chatbot.message.error":"Fehler",
  "ccmessaging.chatbot.message.modifyFailure":"Änderung fehlgeschlagen.",
  "ccmessaging.chatbot.message.modifySuccess":"Geändert erfolgreich.",
  "ccmessaging.chatbot.message.specialStrValidateFailed":"Der eingegebene Inhalt darf Folgendes nicht enthalten characters: & < > /.",
  "ccmessaging.chatbot.message.success":"Erfolg",
  "ccmessaging.chatofflineCall.channelName":"Kanalauswahl",
  "ccmessaging.chatofflineCall.invalidUserIdTip":"Die Abonnenten-ID ist ungültig.",
  "ccmessaging.chatofflineCall.noSelectChanelTip":"Es wurde kein Kanal ausgewählt.",
  "ccmessaging.chatofflineCall.noSupportText":"Dieser Anruf ist ein fremder Anruf!",
  "ccmessaging.chatofflineCall.strangeOfflineTip":"Es wurde keine historische Sitzung gefunden.",
  "ccmessaging.cobrowsesite.protocol.check":"Es werden unsichere Protokolle verwendet, was ein Sicherheitsrisiko darstellt",
  "ccmessaging.cobrowsesite.mediaurl.format.error":"Das Format der Cobrowse-Website ist falsch.",
  "ccmessaging.email.attachment.fileType.tip1":"E-Mail-Anlagen unterstützen die folgenden Dateiformate:",
  "ccmessaging.email.attachment.fileType.tip2":",",
  "ccmessaging.email.attachment.fileType.tip3":"Die Dateigröße darf nicht überschreiten",
  "ccmessaging.email.attachment.fileType.tip4":"M.",
  "ccmessaging.emailcatalog.add":"Verzeichnis erstellen",
  "ccmessaging.emailcatalog.canNotAdd":"Es sind maximal zwei Verzeichnisebenen zulässig.",
  "ccmessaging.emailcatalog.deleteTopCatalog":"Das Standardverzeichnis kann nicht gelöscht werden.",
  "ccmessaging.emailcatalog.duplicateName":"Der Name existiert bereits. Verwenden Sie einen anderen Namen.",
  "ccmessaging.emailcatalog.existEmail":"Im Verzeichnis befinden sich E-Mails.",
  "ccmessaging.emailcatalog.existSubCatalog":"Das Verzeichnis enthält Unterverzeichnisse.",
  "ccmessaging.emailcatalog.modify":"Verzeichnis ändern",
  "ccmessaging.emailcatalog.modifyTopCatalog":"Das Standardverzeichnis kann nicht geändert werden.",
  "ccmessaging.emailcatalog.moveCatalogFailed":"Verzeichnis konnte nicht verschoben werden.",
  "ccmessaging.emailcatalog.moveCatalogPartialSuccess":"Einige E-Mail-Verzeichnisse konnten nicht verschoben werden.",
  "ccmessaging.emailcatalog.moveCatalogSuccess":"Das Verzeichnis wurde erfolgreich verschoben.",
  "ccmessaging.emailcatalog.moveEmailQuantityExceedsMaxValue":"Die Anzahl der verschobenen Mails überschreitet 30 oder die Parameterlänge überschreitet das Maximum.",
  "ccmessaging.emailcatalog.moveOtherCatalogFailed":"Die E-Mails anderer Agenten können nicht verschoben werden.",
  "ccmessaging.emailcatalog.catalogname":"Katalogname",
  "ccmessaging.emailcatalog.parentName":"Name des übergeordneten Verzeichnisses",
  "ccmessaging.emailcatalog.pleaseSelectCatalog":"Wählen Sie zuerst ein Verzeichnis aus.",
  "ccmessaging.emailcatalog.selectcatalog":"Verzeichnis auswählen",
  "ccmessaging.emailMessageManage.forward":"Weiterleitungsaufzeichnung",
  "ccmessaging.flowControl.addFail":"Die Flusssteuerungskonfiguration konnte nicht hinzugefügt werden.",
  "ccmessaging.flowControl.addSuccess":"Die Flusssteuerungskonfiguration wurde erfolgreich hinzugefügt.",
  "ccmessaging.flowControl.alarmInterval":"Alarmintervall",
  "ccmessaging.flowControl.alarmInterval.unit":"min",
  "ccmessaging.flowControl.alarmThreshold":"Alarmschwelle",
  "ccmessaging.flowControl.channelType":"Kanaltyp",
  "ccmessaging.flowControl.count.beyondMaxLimit":"Die Anzahl der Flusssteuerungsregeln im Mandantenbereich überschreitet die Obergrenze.",
  "ccmessaging.flowControl.createTime":"Erstellt am",
  "ccmessaging.flowControl.deleteFail":"Die Flusssteuerungskonfiguration konnte nicht gelöscht werden.",
  "ccmessaging.flowControl.deleteSelect":"Möchten Sie die ausgewählte Regel wirklich löschen?",
  "ccmessaging.flowControl.deleteSuccess":"Die Flusssteuerungskonfiguration wurde erfolgreich gelöscht.",
  "ccmessaging.flowControl.description":"Beschreibung",
  "ccmessaging.flowControl.enable":"Aktivieren",
  "ccmessaging.flowControl.limitNum":"Max. Nachrichten",
  "ccmessaging.flowControl.name":"Name der Flusskontrolle",
  "ccmessaging.flowControl.operate":"Betrieb",
  "ccmessaging.flowControl.periodType":"Periodenbereich",
  "ccmessaging.flowControl.periodType.day":"Täglich",
  "ccmessaging.flowControl.periodType.month":"Monatlich",
  "ccmessaging.flowControl.periodType.time":"Anpassung",
  "ccmessaging.flowControl.periodType.week":"Wöchentlich",
  "ccmessaging.flowControl.periodValue":"Tag",
  "ccmessaging.flowControl.period.select":"Periodenauswahl",
  "ccmessaging.flowControl.selectStop":"Deaktivieren Sie zuerst die Flusssteuerungskonfiguration.",
  "ccmessaging.flowControl.selectRule":"Wählen Sie die Flusssteuerungskonfiguration aus.",
  "ccmessaging.flowControl.selectStartTime":"Wählen Sie die Startzeit aus.",
  "ccmessaging.flowcontrol.target.deleted":"Das Flusssteuerungsobjekt wurde gelöscht. Löschen Sie die entsprechende Flusssteuerungsregel.",
  "ccmessaging.flowControl.targetId":"FC-Objekt",
  "ccmessaging.flowControl.tipText":"Aufforderungsnachricht",
  "ccmessaging.flowControl.tipTextInterval":"Aufforderungsintervall",
  "ccmessaging.flowControl.updateFail":"Die Konfiguration der Flusskontrolle konnte nicht aktualisiert werden.",
  "ccmessaging.flowControl.updateSuccess":"Die Flusssteuerungskonfiguration wurde erfolgreich aktualisiert.",
  "ccmessaging.flowControl.usage":"Nutzung",
  "ccmessaging.flowControl.title.create":"Flusskontrolle erstellen",
  "ccmessaging.flowControl.title.modify":"Flusskontrolle bearbeiten",
  "ccmessaging.flowControl.title.view":"Flusskontrolle anzeigen",
  "ccmessaging.webCollaboration.ruleName":"Regelname",
  "ccmessaging.webCollaboration.pageMatchType":"Seitenübereinstimmungstyp",
  "ccmessaging.webCollaboration.pageMatchConfiguration":"Seitenzuordnungskonfiguration",
  "ccmessaging.webCollaboration.pageElementSelector":"Seitenelementauswahl",
  "ccmessaging.webCollaboration.createTime":"Erstellungszeit",
  "ccmessaging.webCollaboration.operation":"Betrieb",
  "cobrowse.sensitiveinfo.pagematchtype.commonmatch":"Generischer Abgleich",
  "cobrowse.sensitiveinfo.pagematchtype.urlmatch":"Nach URL-Pfad zuordnen",
  "cobrowse.sensitiveinfo.pagematchtype.pageidmatch":"Nach Seiten-ID zuordnen",
  "ccmessaging.message.location.latitude":"Breite:",
  "ccmessaging.message.location.longitude":"Länge:",
  "ccmessaging.message.selectEmail.count":"Es können maximal 30 E-Mails gleichzeitig ausgewählt werden.",
  "ccmessaging.message.selectEmail.null":"Wählen Sie eine E-Mail-Adresse aus.",
  "ccmessaging.message.sendstatus.deliverable":"Gesendet",
  "ccmessaging.message.sendstatus.pending":"Warten",
  "ccmessaging.message.sendstatus.read":"Lesen",
  "ccmessaging.message.sendstatus.undeliverable":"Fehlgeschlagen",
  "ccmessaging.message.sendstatus.unread":"Ungelesen",
  "ccmessaging.message.timeerror.laterThanCurTime":"Die Startzeit darf nicht nach der aktuellen Zeit liegen.",
  "ccmessaging.messagecontroll.timeerror.beyondMaxLimit":"Die Endzeit darf nicht nach der Startzeit von 90 Tagen liegen!",
  "ccmessaging.multimedialibrary.cobrowsesite.card.target":"Ziel durchsuchen:",
  "ccmessaging.multimedialibrary.cobrowsesite.card.title":"Der Agent fordert gemeinsames Browsen mit dem Benutzer an.",
  "ccmessaging.multimedialibrary.tips":"Diese Adresse wird vom Agenten verwendet, um an den Benutzer für die Webseitenzusammenarbeit zu senden. Konfigurieren Sie sie ordnungsgemäß.",
  "ccmessaging.operlogs.emailAttachment.download":"E-Mail-Anhänge herunterladen",
  "ccmessaging.operlogs.multimedialibrary.create.richtext":"Rich-Text hinzufügen",
  "ccmessaging.operlogs.multimedialibrary.modify.richtext":"Rich-Text ändern",
  "ccmessaging.operlogs.multimedialibrary.save":"Datenkonfiguration der Multimedia-Bibliothek speichern",
  "ccmessaging.operlogs.multimedialibrary.save.audio":"Speichern Sie die Datenkonfiguration in der Multimediabibliothek und laden Sie die Audiodateidaten hoch.",
  "ccmessaging.operlogs.multimedialibrary.save.emoticons":"Speichern Sie die Datenkonfiguration in der Multimedia-Bibliothek, und laden Sie die Dateidaten des Emoticon-Typs hoch.",
  "ccmessaging.operlogs.multimedialibrary.save.image":"Speichern Sie die Datenkonfiguration in der Multimediabibliothek, und laden Sie die Bildtypdateidaten hoch.",
  "ccmessaging.operlogs.multimedialibrary.save.video":"Speichern Sie die Datenkonfiguration in der Multimediabibliothek, und laden Sie die Daten der Videodatei hoch.",
  "ccmessaging.operlogs.preview.contract":"Vertragsvorschau, Benutzer:",
  "ccmessaging.operlogs.signature.submit":"Einen unterzeichneten Vertrag einreichen",
  "ccmessaging.operlogs.upload.contract":"Vertrag hochladen",
  "ccmessaging.pop.org.window.noorg":"Ohne Organisationseinheit",
  "ccmessaging.pop.org.window.org":"Mit einer Organisationseinheit",
  "ccmessaging.ruleconfig.add":"Hinzufügen",
  "ccmessaging.ruleconfig.all":"Alle Kanäle",
  "ccmessaging.ruleconfig.applicationChannel":"Kanal",
  "ccmessaging.ruleconfig.content":"Inhalt",
  "ccmessaging.ruleconfig.contentNotNull":"Der Inhalt darf nicht leer sein.",
  "ccmessaging.ruleconfig.createdTime":"Erstellungszeit",
  "ccmessaging.ruleconfig.dateEnd":"Endzeit",
  "ccmessaging.ruleconfig.dateStart":"Startzeit",
  "ccmessaging.ruleconfig.deleteFail":"Die Regel konnte nicht gelöscht werden.",
  "ccmessaging.ruleconfig.deleteSelectRule":"Möchten Sie die ausgewählte Regel wirklich löschen?",
  "ccmessaging.ruleconfig.deleteSuccess":"Regel erfolgreich gelöscht.",
  "ccmessaging.ruleconfig.duplicateName":"Der Regelname ist doppelt vorhanden.",
  "ccmessaging.ruleconfig.effective":"Wirksam",
  "ccmessaging.ruleconfig.emailaddress.error":"Die folgenden Adressen dürfen nicht gesendet werden:",
  "ccmessaging.ruleconfig.emailAddressExists":"Die E-Mail-Adresse ist bereits vorhanden",
  "ccmessaging.ruleconfig.emailAdress":"E-Mail-Adresse",
  "ccmessaging.ruleconfig.emailChannel":"E-Mail-Kanal",
  "ccmessaging.ruleconfig.ID":"Kennung",
  "ccmessaging.ruleconfig.invalidation":"Invalidierung",
  "ccmessaging.ruleconfig.invalidEmailAddress":"Ungültige E-Mail-Adresse",
  "ccmessaging.ruleconfig.invalidSMSNumber":"Ungültige Mobiltelefonnummer.",
  "ccmessaging.ruleconfig.maxEmailAddressCount":"Es können maximal 10 E-Mail-Adressen in den Inhalt eingegeben werden.",
  "ccmessaging.ruleconfig.maxSMSNumberCount":"Es können maximal 10 Mobiltelefonnummern hinzugefügt werden.",
  "ccmessaging.ruleconfig.maxValidityPeriod":"Die Gültigkeitsdauer ist auf das Maximum festgelegt.",
  "ccmessaging.ruleconfig.ruleContentKeyword":"Schlüsselwort für Regelinhalt",
  "ccmessaging.ruleconfig.ruleDescribe":"Regelbeschreibung",
  "ccmessaging.ruleconfig.ruleName":"Regelname",
  "ccmessaging.ruleconfig.selectRule":"Bitte wählen Sie eine Regel aus",
  "ccmessaging.ruleconfig.SMSChannel":"SMS-Kanal",
  "ccmessaging.ruleconfig.SMSNumber":"Mobiltelefonnummer",
  "ccmessaging.ruleconfig.SMSNumberExists":"Die Mobiltelefonnummer ist bereits vorhanden.",
  "ccmessaging.ruleconfig.SMStips1":"Die Mobiltelefonnummer kann Platzhalter enthalten. \\* gibt an, dass null bis mehrere Zeichen übereinstimmen. \\? gibt an, dass kein oder ein Zeichen übereinstimmt.",
// "ccmessaging.ruleconfig.SMStips2":"Beispielsweise bedeutet der Wert '\\*9988", dass SMS-Nachrichten von allen Mobiltelefonnummern, die mit '9988' enden, abgelehnt werden.",
// "ccmessaging.ruleconfig.SMStips3":"Beispielsweise bedeutet der Wert '132\\*", dass SMS-Nachrichten, die von allen Mobiltelefonnummern gesendet werden, die mit '132' beginnen, abgelehnt werden.",
  "ccmessaging.ruleconfig.SMStips4":"Der Wert \\* gibt an, dass SMS-Nachrichten, die von allen Mobiltelefonnummern gesendet werden, blockiert werden. Seien Sie vorsichtig, wenn Sie diesen Wert verwenden.",
  "ccmessaging.ruleconfig.timeerror.dateValidateFailed":"Bitte wählen Sie ein Datum aus, das nach der aktuellen Zeit und vor dem 19. März 2038 liegt",
  "ccmessaging.ruleconfig.timeerror.empty":"* Die Start- und Endzeit darf nicht leer sein.",
  "ccmessaging.ruleconfig.timeerror.endTime":"* Die Endzeit darf nicht vor der aktuellen Zeit liegen.",
  "ccmessaging.ruleconfig.timeerror.laterThanEndTime":"* Die Startzeit darf nicht nach der Endzeit liegen.",
  "ccmessaging.ruleconfig.timeerror.laterThanEndTime2":"Die Startzeit darf nicht nach der Endzeit liegen.",
  "ccmessaging.ruleconfig.timeerror.startTime":"* Die Startzeit darf nicht vor der aktuellen Zeit liegen.",
  "ccmessaging.ruleconfig.tips1":"Sie können Platzhalter in die E-Mail-Adresse eingeben. \\* gibt an, dass 0 oder mehr Zeichen übereinstimmen. \\? Gibt an, dass 0 bis 1 Zeichen übereinstimmen.",
  "ccmessaging.ruleconfig.tips2":"Zum Beispiel. \\*{'@'}huawei.com“ gibt an, dass alle E-Mails mit dem Domänennamen huawei.com“ abgelehnt werden.",
  "ccmessaging.ruleconfig.tips3":"Inhaltstextfeld außer \\* \\? Die Regeln für die E-Mail-Adresse müssen erfüllt sein.",
// "ccmessaging.ruleconfig.tips4":"\\*{'@'}\\*.com blockiert alle E-Mails mit dem Domänennamen.com". Seien Sie bei der Verwendung dieses Befehls vorsichtig.",
  "ccmessaging.ruleconfig.validityPeriod":"Gültigkeitsdauer",
  "ccmessaging.ruleconfig.validityPeriodDay":"Gültigkeitsdauer des Zugriffstokens (Tage)",
  "ccmessaging.ruleconfig.duplicateEmailAddress":"Doppelte E-Mail-Adresse.",
  "ccmessaging.ruleconfig.duplicateSmsAddress":"Doppelte Mobiltelefonnummer.",
  "ccmessaging.socialPostOperation.button.approve":"Genehmigung",
  "ccmessaging.socialPostOperation.button.batchDelete":"Stapel löschen",
  "ccmessaging.socialPostOperation.button.cancel":"Rücktritt",
  "ccmessaging.socialPostOperation.button.channelConfig":"Kanaleinstellungen",
  "ccmessaging.socialPostOperation.button.creatPost":"Beitrag",
  "ccmessaging.socialPostOperation.button.edit":"Bearbeiten",
  "ccmessaging.socialPostOperation.button.delete":"Löschen",
  "ccmessaging.socialPostOperation.button.saveDraft":"Als Entwurf speichern",
  "ccmessaging.socialPostOperation.button.upload":"Hochladen",
  "ccmessaging.socialPostOperation.ceratePageTitle":"Kontoführung/Verbuchung",
  "ccmessaging.socialPostOperation.edit.approveInformation":"Genehmigungsinformationen",
  "ccmessaging.socialPostOperation.edit.basicInformation":"Grundlegende Informationen",
  "ccmessaging.socialPostOperation.edit.must":"Erforderlich",
  "ccmessaging.chat.channelconfig.checkConfirmTokenEqualToToken":"Sie müssen denselben Wert eingeben",
  "ccmessaging.socialPostOperation.edit.placeholder":"Bitte geben Sie ein",
  "ccmessaging.socialPostOperation.edit.publishInformation":"Versionsinformationen",
  "ccmessaging.socialPostOperation.facebook.publishAt.tips":"muss innerhalb von 20 Minuten bis 75 Tagen nach der Erstellung veröffentlicht werden.",
  "ccmessaging.socialPostOperation.facebook.tips.emptyPost":"Facebook kann keine leeren Beiträge veröffentlichen. Bitte wählen Sie mindestens ein Element (Inhalt, Bild oder Video) aus.",
  "ccmessaging.socialPostOperation.field.applicationType":"Anwendungstyp",
  "ccmessaging.socialPostOperation.field.applicationType.create":"Beitrag",
  "ccmessaging.socialPostOperation.field.approveComment":"Genehmigungskommentare",
  "ccmessaging.socialPostOperation.field.approverName":"Genehmigungsberechtigter",
  "ccmessaging.socialPostOperation.field.approveStatus":"Genehmigungsergebnis",
  "ccmessaging.socialPostOperation.field.approveStatus.pass":"Bestanden",
  "ccmessaging.socialPostOperation.field.approveStatus.rejected":"Ablehnen",
  "ccmessaging.socialPostOperation.field.channelName":"Kanalname",
  "ccmessaging.socialPostOperation.field.content":"Inhalt",
  "ccmessaging.socialPostOperation.field.createUserName":"Antragsteller",
  "ccmessaging.socialPostOperation.field.image":"Bild",
  "ccmessaging.socialPostOperation.field.operation":"Betrieb",
  "ccmessaging.socialPostOperation.field.postConfigInfo":"Kanalinformationen",
  "ccmessaging.socialPostOperation.field.postType":"Freigabekanal",
  "ccmessaging.socialPostOperation.field.publishAt":"Freigabezeit",
  "ccmessaging.socialPostOperation.field.publishChannel":"Einen Kanal freigeben",
  "ccmessaging.socialPostOperation.field.publishStatus":"Freigabestatus",
  "ccmessaging.socialPostOperation.field.publishStatus.published":"veröffentlicht",
  "ccmessaging.socialPostOperation.field.publishStatus.unpublished":"Unveröffentlicht",
  "ccmessaging.socialPostOperation.field.publishTime":"Geschätzte Freigabezeit",
  "ccmessaging.socialPostOperation.field.publishTimeSet":"Einstellungen für die Freigabezeit",
  "ccmessaging.socialPostOperation.field.publishTyle.now":"Echtzeitfreigabe",
  "ccmessaging.socialPostOperation.field.publishTyle.time":"Geplante Freigabe",
  "ccmessaging.socialPostOperation.field.publishType":"Veröffentlichungstyp",
  "ccmessaging.socialPostOperation.field.remark":"Anmerkungen",
  "ccmessaging.socialPostOperation.field.status":"Status",
  "ccmessaging.socialPostOperation.field.status.approved":"Abgeschlossen",
  "ccmessaging.socialPostOperation.field.status.draft":"Entwurf",
  "ccmessaging.socialPostOperation.field.status.toApproved":"zu genehmigen",
  "ccmessaging.socialPostOperation.field.title":"Thema",
  "ccmessaging.socialPostOperation.field.video":"Video",
  "ccmessaging.socialPostOperation.res.recall":"Rücknahme erfolgreich",
  "ccmessaging.socialPostOperation.search.searchTitle":"Betreff",
  "ccmessaging.socialPostOperation.select.pleaseChoose":"Bitte auswählen",
  "ccmessaging.socialPostOperation.tab.history":"Genehmigungshistorie",
  "ccmessaging.socialPostOperation.tips.approveSet":"Bitte überprüfen Sie das Konfigurationselement des Genehmigers.",
  "ccmessaging.socialPostOperation.tips.approveSuccess":"Genehmigung erfolgreich",
  "ccmessaging.socialPostOperation.tips.approvingDelete":"Daten im Status Zu genehmigen können nicht gelöscht werden",
  "ccmessaging.socialPostOperation.tips.cancelError":"Nicht zur Genehmigung vorgelegte Daten können nicht widerrufen werden",
  "ccmessaging.socialPostOperation.tips.cannotDel":"Zu genehmigende Beiträge können nicht gelöscht werden.",
  "ccmessaging.socialPostOperation.tips.delConfirm":"Möchten Sie die ausgewählten Daten wirklich löschen?",
  "ccmessaging.socialPostOperation.tips.deleteApproveSuccess":"Löschung erfolgreich gesendet. Warten auf Genehmigung.",
  "ccmessaging.socialPostOperation.tips.deleteNoApprove":"Der Genehmiger ist leer und wird direkt nach der Einreichung gelöscht. Möchten Sie wirklich fortfahren?",
  "ccmessaging.socialPostOperation.tips.fileTip":"Die Dateigröße überschreitet die Obergrenze. Die maximale Größe beträgt {n} MB.",
  "ccmessaging.socialPostOperation.tips.imageNumTip":"Es können maximal neun Bilder hochgeladen werden.",
  "ccmessaging.socialPostOperation.tips.imageType":"Der unterstützt die Formate PNG, JPG, GIF, BMP und TIFF.",
  "ccmessaging.socialPostOperation.tips.imgAndVideoChoose":"Bilder und Videos können nicht gleichzeitig hochgeladen werden.",
  "ccmessaging.socialPostOperation.tips.postTypesChoose":"Wählen Sie mindestens einen Freigabekanal aus",
  "ccmessaging.socialPostOperation.tips.publishFailed":"Publizierung fehlgeschlagen.",
  "ccmessaging.socialPostOperation.tips.saveApproveSuccess":"Erfolgreich gesendet. Warten auf Genehmigung.",
  "ccmessaging.socialPostOperation.tips.submit":"Der Genehmiger ist leer. Es wird direkt nach der Einreichung veröffentlicht. Möchten Sie wirklich fortfahren?",
  "ccmessaging.socialPostOperation.tips.submitSuccess":"Senden erfolgreich",
  "ccmessaging.socialPostOperation.tips.titleError":"kann nicht enthalten < und >,",
  "ccmessaging.socialPostOperation.tips.uploadVideo":"Bitte laden Sie eine Videodatei hoch",
  "ccmessaging.socialPostOperation.tips.videoType":"Der unterstützt das MP4-Format.",
  "ccmessaging.socialPostOperation.tips.youTubeFileChoose":"Über den Youtube-Kanal können nur Videos hochgeladen werden.",
  "ccmessaging.socialPostOperation.youTube.addLabel":"Tag hinzufügen",
  "ccmessaging.socialPostOperation.youTube.audience":"Publikum",
  "ccmessaging.socialPostOperation.youTube.audienceRule":"ist gesetzlich verpflichtet, das Gesetz zum Schutz der Privatsphäre von Kindern im Internet (Children's Online Privacy Protection Act, COPPA) und/oder andere Gesetze einzuhalten, wo immer Sie sich aufhalten. Sie müssen angeben, ob Ihr Video für Kinder bestimmt ist.",
  "ccmessaging.socialPostOperation.youTube.category":"Kategorie",
  "ccmessaging.socialPostOperation.youTube.category.car":"Autos und Fahrzeuge",
  "ccmessaging.socialPostOperation.youTube.category.Comedy":"Komödie",
  "ccmessaging.socialPostOperation.youTube.category.education":"Bildung",
  "ccmessaging.socialPostOperation.youTube.category.entertainment":"Unterhaltung",
  "ccmessaging.socialPostOperation.youTube.category.film":"Film und Animation",
  "ccmessaging.socialPostOperation.youTube.category.gaming":"Spiele",
  "ccmessaging.socialPostOperation.youTube.category.howto":"Anleitung und Stil",
  "ccmessaging.socialPostOperation.youTube.category.music":"Musik",
  "ccmessaging.socialPostOperation.youTube.category.news":"Nachrichten und Politik",
  "ccmessaging.socialPostOperation.youTube.category.nonprofits":"Nichtkommerziale Organisationen und Aktivismus",
  "ccmessaging.socialPostOperation.youTube.category.people":"Personen und Blogs",
  "ccmessaging.socialPostOperation.youTube.category.pets":"Haustiere und Tiere",
  "ccmessaging.socialPostOperation.youTube.category.science":"Wissenschaft und Technologie",
  "ccmessaging.socialPostOperation.youTube.category.shortMovies":"Kurzfilme",
  "ccmessaging.socialPostOperation.youTube.category.sport":"Sport",
  "ccmessaging.socialPostOperation.youTube.category.travel":"Reise und Veranstaltungen",
  "ccmessaging.socialPostOperation.youTube.categoryDetail":"Fügen Sie Ihren VOD zu einer Kategorie hinzu, damit der Betrachter ihn finden kann.",
  "ccmessaging.socialPostOperation.youTube.disclosureScope":"Öffentlicher Geltungsbereich",
  "ccmessaging.socialPostOperation.youTube.disclosureScopeDetail":"Wählen Sie aus, wer Ihr Video sehen kann",
  "ccmessaging.socialPostOperation.youTube.embedding":"Einbetten zulassen",
  "ccmessaging.socialPostOperation.youTube.embedding.tips":"erlaubt es anderen, Ihre Videos in ihre Websites einzubetten.",
  "ccmessaging.socialPostOperation.youTube.knowledgePermission":"Creative Commons - Namensnennung",
  "ccmessaging.socialPostOperation.youTube.labels":"Beschriftung",
  "ccmessaging.socialPostOperation.youTube.labelsDetail":"Tags können Zuschauern helfen, Ihr Video zu finden, wenn es im Videoinhalt leicht falsch geschriebene Wörter gibt. Abgesehen davon sind Tags nicht sehr nützlich, um Videos zu finden.",
  "ccmessaging.socialPostOperation.youTube.noKids":"Nicht für Kinder",
  "ccmessaging.socialPostOperation.youTube.normalPermission":"Standard-YouTube-Lizenz",
  "ccmessaging.socialPostOperation.youTube.permissions":"Lizenz",
  "ccmessaging.socialPostOperation.youTube.permissionsKnow":"Lizenztypen verstehen.",
  "ccmessaging.socialPostOperation.youTube.scopePrivate":"Privat",
  "ccmessaging.socialPostOperation.youTube.scopePublic":"Öffentlich",
  "ccmessaging.socialPostOperation.youTube.scopeUnlisted":"Nicht öffentlich gelistet",
  "ccmessaging.socialPostOperation.youTube.tags.tips":"Geben Sie nach jeder Beschriftung ein Komma ein",
  "ccmessaging.socialPostOperation.youTube.videoToKids":"Ist dieses Video für Kinder?",
  "ccmessaging.socialPostOperation.youTube.view.commentNum":"14 Kommentare",
  "ccmessaging.socialPostOperation.youTube.view.dataResolve":"Datenanalyse",
  "ccmessaging.socialPostOperation.youTube.view.editVideo":"Video bearbeiten",
  "ccmessaging.socialPostOperation.youTube.view.lookNum":"38 Aufrufe vor 2 Tagen",
  "ccmessaging.socialPostOperation.youTube.view.orderType":"Sortiermodus",
  "ccmessaging.socialPostOperation.youTube.view.share":"Freigeben",
  "ccmessaging.socialPostOperation.youTube.view.subscriber":"1 Abonnenten",
  "ccmessaging.socialPostOperation.youTube.view.unfolding":"Erweitern",
  "ccmessaging.socialPostOperation.youTube.yesKids":"Ja, der Inhalt ist für Kinder",
  "ccmessaging.socialPostOperation.youTube.publishAtTips":"Diese Einstellung ist nur möglich, wenn der öffentliche Bereich auf Privat gesetzt ist.",
  "ccmessaging.socialPostOperation.facebook.threeDay":"3 Tage",
  "ccmessaging.socialPostOperation.facebook.awful":"Wahnsinnig",
  "ccmessaging.socialPostOperation.facebook.comment":"Kommentar",
  "ccmessgaing.chat.web.channel.Agent":"Audio- und Videoagent",
  "ccmessgaing.chat.web.channel.ivr":"IVR",
  "ccmessgaing.chat.web.channel.webrtc.share":"Die Desktop-Freigabe beinhaltet eine Anzeige der Privatsphäre. Bitte bestätigen Sie.",
  "ccmessgaing.chatbot.message.female":"Weiblich",
  "ccmessgaing.chatbot.message.male":"Männlich",
  "ccmessgaing.postChannelConfig.accessToken":"accessToken",
  "ccmessgaing.postChannelConfig.add":"Hinzufügen eines Kanals",
  "ccmessgaing.postChannelConfig.apiFabricAK":"ApiFabricAK",
  "ccmessgaing.postChannelConfig.apiFabricSK":"ApiFabricSK",
  "ccmessgaing.postChannelConfig.apiKey":"apiKey",
  "ccmessgaing.postChannelConfig.appId":"Client-ID",
  "ccmessgaing.postChannelConfig.appKey":"appKey",
  "ccmessgaing.postChannelConfig.cancel":"Abbrechen",
  "ccmessgaing.postChannelConfig.channelConfig":"Kanal konfigurieren",
  "ccmessgaing.postChannelConfig.channelCreate":"Kanal wird erstellt",
  "ccmessgaing.postChannelConfig.channelEdit":"Kanalkonfiguration bearbeiten",
  "ccmessgaing.postChannelConfig.channelType":"Kanalkonfiguration",
  "ccmessgaing.postChannelConfig.confirm":"OK",
  "ccmessgaing.postChannelConfig.addUsers":"Hinzufügen",
  "ccmessgaing.postChannelConfig.createFailed":"Die Konfigurationsinformationen über den Social-Media-Kanal konnten nicht erstellt werden.",
  "ccmessgaing.postChannelConfig.createSuccess":"Die Konfigurationsinformationen des Social-Media-Kanals konnten erfolgreich erstellt werden.",
  "ccmessgaing.postChannelConfig.delete":"Löschen",
  "ccmessgaing.postChannelConfig.deleteFailed":"Die Konfigurationsinformationen über den Social-Media-Kanal konnten nicht gelöscht werden.",
  "ccmessgaing.postChannelConfig.deleteFailed.db":"Löschung fehlgeschlagen. Datenbankvorgang fehlgeschlagen.",
  "ccmessgaing.postChannelConfig.deleteFailed.permission":"Löschung fehlgeschlagen. Berechtigungsprüfung fehlgeschlagen.",
  "ccmessgaing.postChannelConfig.deleteFailed.inUse":"Löschung fehlgeschlagen. Der Kanal hat Freigabeinformationen.",
  "ccmessgaing.postChannelConfig.deleteSuccess":"Die Konfigurationsinformationen des Social-Media-Kanals konnten erfolgreich gelöscht werden.",
  "ccmessgaing.postChannelConfig.edit":"Bearbeiten",
  "ccmessgaing.postChannelConfig.editFailed":"Update fehlgeschlagen. Überprüfen Sie die Formularparameter und die Netzwerkverbindung.",
  "ccmessgaing.postChannelConfig.editSuccess":"Die Konfigurationsinformationen des Social-Media-Kanals konnten erfolgreich aktualisiert werden.",
  "ccmessgaing.postChannelConfig.failed":"Fehlgeschlagen",
  "ccmessgaing.postChannelConfig.maxLength":"Die maximale Länge beträgt {n} Zeichen.",
  "ccmessgaing.postChannelConfig.operation":"Betrieb",
  "ccmessgaing.postChannelConfig.operation.person":"Betriebspersonal",
  "ccmessgaing.postChannelConfig.placeSelectChannelType":"Wählen Sie einen Kanaltyp aus",
  "ccmessgaing.postChannelConfig.postChannelName":"Kanalname",
  "ccmessgaing.postChannelConfig.refreshToken":"refreshToken",
  "ccmessgaing.postChannelConfig.users.overSize":"Die Anzahl des Betriebspersonals übersteigt 10.",
  "ccmessgaing.postChannelConfig.repeatedly":"Der Kanal ist bereits vorhanden.",
  "ccmessgaing.postChannelConfig.selectByChannelName":"Kanalname",
  "ccmessgaing.postChannelConfig.subChannelId":"Kanal-ID",
  "ccmessgaing.postChannelConfig.success":"Erfolg",
  "ccmessgaing.postChannelConfig.unauthorized":"Der Vorgang ist nicht authentifiziert.",
  "chat.agent.chatOnlineWorkbench":"Online-Chat-Workbench",
  "chat.agent.label.balance":"Einnahmen und Ausgaben",
  "chat.agent.label.billDetail":"Rechnungsdetails",
  "chat.agent.label.chatBot":"Chatbot",
  "chat.agent.label.consumption":"Mein Konsum",
  "chat.agent.label.customerSource":"Kundenquelle: Mobile App – Persönliches Center",
  "chat.agent.label.downagainfile":"Erneut herunterladen",
  "chat.agent.label.goAway":"Abwesend",
  "chat.agent.label.historicalBill":"Historischer Gesetzentwurf",
  "chat.agent.label.money":"Betrag",
  "chat.agent.label.mybill":"Meine Rechnungen",
  "chat.agent.label.offline":"Offline",
  "chat.agent.label.online":"Online",
  "chat.agent.label.recivefile":"Empfangen",
  "chat.agent.label.refusefile":"Verweigern",
  "chat.agent.label.send":"Senden",
  "chat.agent.label.total":"Gesamt",
  "chat.agent.label.waiting":"Warten Sie",
  "chat.agent.message.changeFailed":"Der Agentenstatus konnte nicht geändert werden.",
  "chat.agent.title.changeWorkMode":"Arbeitsmodus ändern",
  "chat.agent.title.close":"Schließen",
  "chat.agent.title.createCase":"Fall erstellen",
  "chat.agent.title.createSkill":"Fähigkeiten schaffen",
  "chat.agent.title.enterText":"Bitte geben Sie ein",
  "chat.agent.title.OfflineCall":"Offline-Anruf",
  "chat.agent.title.transfer":"Weiterleiten an",
  "chat.agent.title.userInformation":"Kundeninformationen",
  "chat.agent.title.userMenu":"Kundenmenü",
  "chat.agent.title.workMode":"Arbeitsmodus",
  "chat.agentConsole.message.content.audio":"[Stimmennachricht]",
  "chat.agentConsole.message.content.connectMessage":"Eine neue Nachricht!",
  "chat.agentConsole.message.content.image":"[Bildnachricht]",
  "chat.agentConsole.message.content.locate":"[Standortmeldung]",
  "chat.agentConsole.message.content.video":"[Videonachricht]",
  "chat.agentConsole.message.error.emptyMessage":"[Die Nachricht enthält keinen Inhalt, der angezeigt werden kann.]",
  "chat.agentConsole.message.error.notification":"Die Benachrichtigung über eine neue Nachricht kann nicht erstellt werden. Bitte überprüfen Sie die Browserkonfiguration!",
  "chat.client.alert.message.invildtoken":"Ihr Dialog hat das Zeitlimit überschritten oder Sie haben sich an anderer Stelle angemeldet. Bitte schließen Sie das Chat-Fenster und versuchen Sie es erneut. Vielen Dank!",
  "chat.client.button.submit":"Senden",
  "chat.client.evaluate.message":"Bitte kommentieren Sie meinen Dienst.“ Vielen Dank!",
  "chat.client.greeting.afternoon":"Guten Tag!",
  "chat.client.greeting.dear":"Liebe",
  "chat.client.greeting.evening":"Guten Abend!",
  "chat.client.greeting.info":"Was kann ich für Sie tun?",
  "chat.client.greeting.morning":"Guten Morgen!",
  "chat.client.label.accountNumber":"Konto",
  "chat.client.label.bill":"Meine Rechnungen",
  "chat.client.label.chatTitle":"Kunde",
  "chat.client.label.download":"Herunterladen",
  "chat.client.label.evaluation":"Zufriedenheitsumfrage",
  "chat.client.label.forgotPassword":"Passwort vergessen",
  "chat.client.label.header":"Telco+",
  "chat.client.label.input":"Bitte geben Sie ein",
  "chat.client.label.like":"Das könnte Ihnen gefallen",
  "chat.client.label.login":"Anmelden",
  "chat.client.label.loginTitle":"Anmelden",
  "chat.client.label.more":"Mehr",
  "chat.client.label.no":"Nein",
  "chat.client.label.order":"Meine Bestellungen",
  "chat.client.label.password":"Kennwort",
  "chat.client.label.privacyHint":"Warnung zum Datenschutz",
  "chat.client.label.privacyHintContent":"Um die Servicequalität zu verbessern, wird Ihr Gespräch vom System aufgezeichnet. Möchten Sie wirklich weitersprechen?",
  "chat.client.label.promotions":"Aufforderung",
  "chat.client.label.rateService":"Bewertungsdienst",
  "chat.client.label.system":"Das System ist ausgelastet. Versuchen Sie es später erneut.",
  "chat.client.label.yes":"Ja",
  "chat.client.message.evaluationFail":"Die Zufriedenheitsumfrage schlägt fehl.",
  "chat.client.message.evaluationSuccess":"Die Zufriedenheitsumfrage ist erfolgreich.",
  "chat.client.message.gladtoServe":". Was kann ich für Sie tun?",
  "chat.client.message.hiMyWorkno":"Hallo! Hier ist ",
  "chat.client.message.noAgent":"Leider ist der Agent beschäftigt. Versuchen Sie es später erneut.",
  "chat.client.transfer.link":"Möchten Sie den Anruf wirklich an einen Agenten weiterleiten?",
  "chat.common.message.cancel":"Abbrechen",
  "chat.common.message.create":"Erstellen",
  "chat.common.message.dateEnd":"Endzeit",
  "chat.common.message.dateStart":"Startzeit",
  "chat.common.message.delete":"Löschen",
  "chat.common.message.error":"Fehler",
  "chat.common.message.fail":"Fehler",
  "chat.common.message.finish":"OK",
  "chat.common.message.modify":"Ändern",
  "chat.common.message.promotions":"Informationen",
  "chat.common.message.success":"Erfolg",
  "chat.common.message.warning":"Warnung",
  "chat.confirm.no":"Nein",
  "chat.confirm.ok":"OK",
  "chat.confirm.yes":"Ja",
  "chat.session.channel.all":"Alle",
  "chat.session.channel.facebook":"Facebook",
  "chat.session.channel.mobile":"Mobiles Endgerät",
  "chat.session.channel.twitter":"Twitter",
  "chat.session.channel.web":"Web",
  "chat.session.channel.wechat":"WeChat",
  "chat.session.endType.agentEnd":"Ende beim Agenten",
  "chat.session.endType.customerEnd":"Ende beim Kunden",
  "chat.session.endType.overtimeEnd":"Ende aufgrund von Zeitüberschreitung",
  "chat.session.label.alias":"Kundenname",
  "chat.session.label.allChannel":"Alle Kanaltypen",
  "chat.session.label.allEndType":"Alle Anschlussarten",
  "chat.session.label.channel":"Dialogzugriffskanal",
  "chat.session.label.created_endTime":"Endzeit",
  "chat.session.label.created_startTime":"Startzeit",
  "chat.session.label.createdTime":"Zeit der Dialogerstellung",
  "chat.session.label.endReason":"Ursache für das Ende des Dialogs",
  "chat.session.label.endTime":"Endzeit des Dialogs",
  "chat.session.label.endType":"Dialogendetyp",
  "chat.session.label.enterQueueTime":"Warteschlangenzeit",
  "chat.session.label.evaluation":"Dialogauswertung",
  "chat.session.label.evaluationTime":"Dialogauswertungszeit",
  "chat.session.label.exitQueueTime":"Warteschlangenzeit",
  "chat.session.label.remark":"Kommentar",
  "chat.session.label.reset":"Zurücksetzen",
  "chat.session.label.search":"Suche",
  "chat.session.label.servedAgent":"Kundendienstmitarbeiter",
  "chat.session.label.sessionId":"Dialog-ID",
  "chat.session.label.skillQueueId":"Skill-Warteschlangen-ID",
  "chat.session.label.skillQueueName":"Skill-Warteschlangenname",
  "chat.session.label.subChannel":"Unterzugriffskanal",
  "chat.session.title.basic":"Grundlegende Dialoginformationen",
  "chat.session.title.detail":"Dialogdetails",
  "chat.session.title.history":"Historische Chataufzeichnungen",
  "chat.skill.label.account":"Konto",
  "chat.skill.label.agents":"Agent",
  "chat.skill.label.cancel":"Abbrechen",
  "chat.skill.label.createSkill":"Erstellen",
  "chat.skill.label.createSkillTab":"Fähigkeiten schaffen",
  "chat.skill.label.delete":"Löschen",
  "chat.skill.label.deleteSkills":"Löschen",
  "chat.skill.label.description":"Beschreibung",
  "chat.skill.label.edit":"Bearbeiten",
  "chat.skill.label.inputName":"Name",
  "chat.skill.label.maxQueueNumber":"Maximale Warteschlangenanzahl",
  "chat.skill.label.maxQueueTime":"Max. Warteschlangenzeit (s)",
  "chat.skill.label.maxQueueTimeInTable":"Max. Warteschlangenzeit",
  "chat.skill.label.name":"Benutzername",
  "chat.skill.label.ok":"OK",
  "chat.skill.label.priority":"Priorität",
  "chat.skill.label.reset":"Zurücksetzen",
  "chat.skill.label.save":"Speichern",
  "chat.skill.label.skill":"Fähigkeit-",
  "chat.skill.label.skillDetail":"Fertigkeitsdetails",
  "chat.skill.label.skillId":"Nein.",
  "chat.skill.label.skillName":"Name",
  "chat.skill.label.submit":"Senden",
  "chat.skill.message.completeInputs":"Bitte vervollständigen Sie alle Eingaben.",
  "chat.skill.message.createFail":"Die Fähigkeit konnte nicht entwickelt werden.",
  "chat.skill.message.createSuccess":"Die Fähigkeit wurde erfolgreich entwickelt.",
  "chat.skill.message.delete":"Löschen",
  "chat.skill.message.error":"Fehler",
  "chat.skill.message.failDeleteSkill":"Skill konnte nicht gelöscht werden.",
  "chat.skill.message.isExisted":"Die Skill-Warteschlange ist bereits vorhanden.",
  "chat.skill.message.priorityLength":"Stellen Sie sicher, dass die Priorität kleiner oder gleich fünf Ziffern ist.",
  "chat.skill.message.saveFail":"Die Fähigkeit konnte nicht gespeichert werden.",
  "chat.skill.message.saveSuccess":"Die Fähigkeit wurde erfolgreich gespeichert.",
  "chat.skill.message.selectSkills":"Wähle eine Fertigkeit aus.",
  "chat.skill.message.success":"Erfolg",
  "chat.skill.message.warning":"Warnung",
  "chat.skill.message.willDeleteAllSkills":"Möchten Sie wirklich alle Fähigkeiten löschen?",
  "chat.skill.message.willDeleteSkill":"Möchten Sie den Skill wirklich löschen?",
  "chat.skill.message.willDeleteSkills":"Möchten Sie die ausgewählten Fähigkeiten wirklich löschen?",
  "chat.thirdportal.chatWindow.button.startChat":"Chat starten",
  "chat.thirdportal.chatWindow.label.userEmail":"E-Mail-Adresse",
  "chat.thirdportal.chatWindow.label.userName":"Name",
  "chat.thirdportal.chatWindow.label.userPhone":"Zahl",
  "chat.thirdportal.title":"Kunden-Webportal",
  "chat.timerange.label.alldays":"Alle Erstellungszeiten",
  "chat.timerange.label.confirmSocailChatNum":"Die maximale Anzahl gleichzeitiger Dienstkunden ist 0. Die globale Konfiguration wird verwendet",
  "chat.timerange.label.customizes":"Angepasst",
  "chat.timerange.label.ok":"OK",
  "chat.timerange.label.oneday":"Letzter Tag",
  "chat.timerange.label.sevendays":"Letzte 7 Tage",
  "chat.timerange.label.thirtydays":"Letzte 30 Tage",
  "chat.timerange.label.threedays":"Letzte 3 Tage",
  "chat.timerange.message.timeempty":"Die Zeit darf nicht leer sein.",
  "chat.timerange.message.timeerror":"Das Format der Uhrzeit ist falsch.",
  "chat.usefulexpressions.label.createExpressionTab":"Geläufige Phrase erstellen",
  "chat.usefulexpressions.label.expression":"Geläufige Phrasen",
  "chat.usefulexpressions.label.expressionDetail":"Geläufige Phrasendetails",
  "chat.usefulexpressions.message.contentLength":"Stellen Sie sicher, dass die allgemeine Phrase weniger als oder gleich 1000 Zeichen lang ist.",
  "chat.usefulexpressions.message.contentNotNull":"Der allgemeine Ausdruck darf nicht leer sein.",
  "chat.usefulexpressions.message.createFail":"Die allgemeine Phrase konnte nicht erstellt werden.",
  "chat.usefulexpressions.message.createSuccess":"Der allgemeine Ausdruck wurde erfolgreich erstellt.",
  "chat.usefulexpressions.message.failDeleteExpression":"Der allgemeine Ausdruck konnte nicht gelöscht werden.",
  "chat.usefulexpressions.message.isExisted":"Der allgemeine Phrasenname existiert bereits.",
  "chat.usefulexpressions.message.saveFail":"Der allgemeine Ausdruck konnte nicht gespeichert werden.",
  "chat.usefulexpressions.message.saveSuccess":"Die allgemeine Phrase wurde erfolgreich gespeichert.",
  "chat.usefulexpressions.message.selectExpressions":"Wählen Sie einen gängigen Ausdruck.",
  "chat.usefulexpressions.message.willDeleteExpression":"Möchten Sie den allgemeinen Ausdruck wirklich löschen?",
  "chat.usefulexpressions.message.willDeleteExpressions":"Möchten Sie den ausgewählten allgemeinen Ausdruck wirklich löschen?",
  "chat.web.evaluation.star1":"Sehr unzufrieden",
  "chat.web.evaluation.star2":"Unzufrieden",
  "chat.web.evaluation.star3":"Allgemein",
  "chat.web.evaluation.star4":"Zufrieden",
  "chat.web.evaluation.star5":"Sehr zufrieden",
  "chat.webconfig.button.save":"Speichern",
  "chat.webconfig.label.bandingskill":"Kompetenzwarteschlange binden",
  "chat.webconfig.label.basesettings":"Basiskonfiguration",
  "chat.webconfig.label.functionalsettings":"Funktionseinstellungen",
  "chat.webconfig.label.greeting":"Grüße",
  "chat.webconfig.label.greeting.placeholder":"Bitte geben Sie den Inhalt der Begrüßung ein, die im Chat-Fenster des Kunden angezeigt wird.",
  "chat.webconfig.label.intergratedcode":"Integrierter Code",
  "chat.webconfig.label.intergratemsg":"Live-Chat in Ihre Website integrieren",
  "chat.webconfig.label.language":"Standardsprache",
  "chat.webconfig.label.language.en":"Englisch",
  "chat.webconfig.label.language.zh":"Chinesisch",
  "chat.webconfig.label.language.pt":"Portugiesisch",
  "chat.webconfig.label.transHuman":"Weiterleitung an Agenten",
  "chat.webconfig.label.windowTitle":"Titel des Chat-Fensters",
  "chat.webconfig.label.windowTitle.placeholder":"Bitte geben Sie den Titel ein, der im Chat-Fenster des Clients angezeigt wird.",
  "chat.webconfig.message.configdirective":"Kopieren Sie den folgenden Code und fügen Sie ihn vor dem Website-Label </body> ein. Speichern und geben Sie den Code frei, um den schnellen Zugriff auf die Huawei Service Cloud abzuschließen. Nach der Freigabe wird der Huawei Service Cloud-Eintrag in der unteren rechten Ecke Ihrer Website angezeigt. Sie können darauf klicken, um in Echtzeit mit der Kundenservice-Workbench zu sprechen.",
  "chat.webconfig.message.demodirective":"Sie können auch die folgende Adresse im ausgeblendeten Fenster des Chrome-Browsers öffnen, um auf die vom System bereitgestellte Präsentationsseite zuzugreifen:",
  "chat.webconfig.message.saveFail":"Konfiguration konnte nicht gespeichert werden.",
  "chat.webconfig.message.saveSuccess":"Konfiguration erfolgreich gespeichert.",
  "chat.webconfig.title":"Webkanal aktivieren",
  "chat.weChatConfig.label.appId":"Entwickler-ID",
  "chat.weChatConfig.label.AppSecret":"Entwicklerkennwort",
  "chat.weChatConfig.label.bindSkill":"Kompetenzwarteschlange binden",
  "chat.weChatConfig.label.codeName":"Kanalzugangscode",
  "chat.weChatConfig.label.configId":"Konfigurations-ID",
  "chat.weChatConfig.label.create":"Neu",
  "chat.weChatConfig.label.delete":"Löschen",
  "chat.weChatConfig.label.Domain":"Serveradresse",
  "chat.weChatConfig.label.facebook":"Facebook",
  "chat.weChatConfig.label.nextStep":"Weiter",
  "chat.weChatConfig.label.qcode":"QR-Code",
  "chat.weChatConfig.label.save":"Speichern",
  "chat.weChatConfig.label.search":"Suche",
  "chat.weChatConfig.label.secret":"Schlüssel",
  "chat.weChatConfig.label.state":"Status",
  "chat.weChatConfig.label.tenantId":"Mieter-ID",
  "chat.weChatConfig.label.verify":"Überprüfung",
  "chat.weChatConfig.label.verifycode":"Token",
  "chat.weChatConfig.label.verifying":"Überprüfung",
  "chat.weChatConfig.label.wechat":"WeChat",
  "chat.weChatConfig.link.wechat":"WeChat-Server",
  "chat.weChatConfig.message.appIdExisted":"Die eingegebene Entwickler-ID für das öffentliche Konto ist bereits vorhanden.",
  "chat.weChatConfig.message.codeNameExisted":"Der Kanalzugriffscode ist bereits vorhanden.",
  "chat.weChatConfig.message.deleteError":"Konfigurationselement konnte nicht gelöscht werden.",
  "chat.weChatConfig.message.emptyDeleteArray":"Wählen Sie das Konfigurationselement aus, das gelöscht werden soll.",
  "chat.weChatConfig.message.ensureDelete":"Möchten Sie das ausgewählte Konfigurationselement wirklich löschen?",
  "chat.weChatConfig.message.error":"Fehler",
  "chat.weChatConfig.message.savesuccess":"Erfolgreicher Zugriff.",
  "chat.weChatConfig.message.skillExisted":"Die ausgewählte Skill-Warteschlange wurde an ein anderes öffentliches Konto gebunden.",
  "chat.weChatConfig.message.unknown":"Der Verifizierungsstatus ist unbekannt.",
  "chat.weChatConfig.stepname.selectchannel":"Zugriffskanal auswählen",
  "chat.weChatConfig.stepname.selectmode":"Konfigurationsmethode auswählen",
  "chat.weChatConfig.stepname.success":"Zugriff erfolgreich",
  "chat.weChatConfig.stepname.verify":"Zur Überprüfung einreichen",
  "chat.weChatConfig.title":"Konfiguration zur Aktivierung sozialer Medien",
  "chat.weChatConfig.title.deleteTitle":"WeChat-Konfigurationselemente löschen",
  "cmessaging.chat.answerUser.error":"Benutzersitzung konnte nicht erstellt werden.",
  "cmessaging.chat.answerUser.Tips":"Erstellen einer Benutzersitzung",
  "cmessaging.chat.channelconfig.isPushWebMessage":"Nachrichten-Push",
  "cmessaging.chat.channelconfig.pushWebMessageKey":"Schlüssel",
  "cmessaging.chat.channelconfig.pushWebMessageUrl":"Push-Adresse der Nachricht",
  "cmessaging.chat.channelconfig.pushWebCertTypeSignature":"Authentifizierung der Signatur",
  "cmessaging.chat.channelconfig.pushWebCertTypeOAuth":"OAuth-Authentifizierung",
  "cmessaging.chat.channelconfig.setAgentTimeout":"Sitzungsübertragung aufgrund keiner Agentenantwort",
  "cmessaging.chat.multimedia.numOverMaxSum":"Die Menge der von Ihnen gespeicherten Multimediadaten überschreitet den Grenzwert ",
  "cmessaging.chat.multimediatype.numOverMaxSum":"Die Anzahl der von Ihnen gespeicherten Multimedia-Kategorien überschreitet den Grenzwert ",
  "ccmessaging.socialPostOperation.search.searchContent":"Bitte geben Sie den Inhalt ein.",
  "ccmessaging.socialPostOperation.link":"Besuchen Sie den Originaltext",
  "ccmessaging.chat.social.media.enterprise.post.load.failed":"Anhang konnte nicht geladen werden.",
  "ccmessaging.chat.multimedia.imageUploadChannelFormat":"Die Kanäle WhatsApp sowie Web, Telegram, LINE, Facebook und Twitter unterstützen die Formate JPG, PNG und JPEG.",
  "ccmessaging.chat.multimedia.audioUploadChannelFormat":"Der WhatsApp-Kanal unterstützt das ACC-Format.\nDer Webkanal unterstützt die Formate MP3 und AAC.\nDie Instagram- und LINE-Kanäle unterstützen das M4A-Format.\nDie Kanäle von WeChat und Facebook unterstützen das MP3- \nDer Telegram-Kanal unterstützt die Formate MP3 und M4A.",
  "ccmessaging.chat.multimedia.documentUploadChannelFormat":"Der WhatsApp-Kanal unterstützt die Formate DOC, DOCX, PDF, PPT, PPTX, XLSX und XLS.\nDer Web-Kanal unterstützt die Formate DOC, DOCX, PDF, und ZIP-Formate.\nDer Telegram-Kanal unterstützt die Formate PDF und ZIP.",
  "ccmessaging.chat.channelconfig.searchChannelName":"Suchkanalname",
  "ccmessaging.chat.channelconfig.createChannel":"Kanal erstellen",
  "ccmessaging.chat.channelconfig.modifyChannel":"Kanal ändern",
  "ccmessaging.chat.channelconfig.channelIntegrate":"Kanalintegration",
  "ccmessaging.chat.channelconfig.baseConfig":"Grundlegende Konfiguration",
  "ccmessaging.chat.channelconfig.robotConfig":"Roboterkonfiguration",
  "ccmessaging.chat.channelconfig.chooseChannel":"Kanalauswahl",
  "ccmessaging.chat.channelconfig.range.placeholder":"{0}-{1}",
  "ccmessaging.chat.channelconfig.advanceConfig":"Mehr",
  "ccmessaging.chat.channelconfig.web.clickToCall":"Klicken, um anzurufen",
  "ccmessaging.chat.channelconfig.time.timeNoRange":"Im Zeitraum {n} muss die Startzeit vor der Endzeit liegen.",
  "ccmessaging.chat.channelconfig.time.timeCross":"Der Zeitraum {0} überschneidet sich mit dem Zeitraum {1}.",
  "ccmessaging.chat.channelconfig.time.timePeriod0":"Bitte geben Sie den Zeitraum {n} ein",
  "ccmessaging.chat.channelconfig.time.timePeriod":"Bitte geben Sie den Zeitraum {n} ein oder löschen Sie ihn",
  "ccmessaging.silentagent.button.create":"Neu",
  "ccmessaging.silentagent.dialog.title.create":"Neuer Agenten mit stiller Stimme",
  "ccmessaging.silentagent.dialog.title.edit":"Agent für Sprachstille bearbeiten",
  "ccmessaging.silentagent.dialog.title.view":"Agent ohne Sprachausgabe anzeigen",
  "ccmessaging.silentagent.delete.selectone":"Wählen Sie mindestens einen Datensatz aus.",
  "ccmessaging.silentagent.delete.select.morethan.100":"Es können maximal 100 Datensätze gleichzeitig gelöscht werden.",
  "ccmessaging.silentagent.placeholder.selectaudioskill":"Wählen Sie eine Warteschlange für Sprachfertigkeiten aus.",
  'ccmessaging.silentagent.btn.add.period':"Zeitraum hinzufügen",
  'ccmessaging.silentagent.agent.workday.worktime.error':"Die Arbeitszeitkonfiguration des Agenten ist falsch. Die Zeitsegmente überschneiden sich.",
  'ccmessaging.silentagent.agent.holiday.worktime.error':"Die Konfiguration der arbeitsfreien Zeit des Agenten ist falsch. Die Zeitsegmente überschneiden sich.",
  'ccmessaging.silentagent.agent.workday.worktime.empty':"Die Arbeitszeitkonfiguration des Agenten hat eine leere Konfiguration.",
  'ccmessaging.silentagent.agent.holiday.worktime.empty':"Die Konfiguration der arbeitsfreien Zeit des Agenten ist leer.",
  "ccmessaging.silentagent.voiceaccess.empty":"Die Konfiguration der Route für den Sprach-IVR-Anruf darf nicht leer sein",
  "ccmessaging.chat.channelconfig.searchCertCode":"Zertifikatscode suchen",
  "ccmessaging.chat.channelconfig.cloudApi.accessToken.tips":"Gültigkeitsdauer des von Meta für Entwickler generierten Tokens für den Zugriff auf die Cloud-API. Geben Sie einen Gültigkeitszeitraum ein, der zwei Tage kürzer ist als der tatsächliche Gültigkeitszeitraum. Wenn der tatsächliche Gültigkeitszeitraum beispielsweise\n 60 Tage beträgt\n, wird empfohlen, den Gültigkeitszeitraum auf 58 Tage festzulegen. Wenn dieser Parameter leer bleibt\n, läuft das Token nie ab\n, was anfällig für Lecks ist. Seien Sie vorsichtig, wenn Sie diesen Parameter leer lassen.",
  "ccmessaging.chat.channelconfig.cloudApi.bsp.tips":"Derzeit kann die Cloud-API von WhatsApp so verbunden werden, dass nur Textnachrichten verarbeitet werden.",
  "ccmessaging.chat.channelconfig.checkWhatsAppAccessTokenDuration":"Der Wert muss eine ganze Zahl zwischen 1 und 60 sein.",
  "ccmessaging.chat.channelconfig.copy":"Kopieren",
  "ccmessaging.chat.channelconfig.copySuccess":"Kopieren erfolgreich.",
  "ccmessaging.chat.sumbitverification.whatsApp.cloudapi.tips":"Rufen Sie die Seite WhatsApp unter Meta für Entwickler auf, um die URL und den Verifizierungscode zu konfigurieren.",
  "ccmessaging.chatbot.label.useIntelligentRobot":"Verbindung zum intelligenten Roboter herstellen",
  "ccmessaging.chatbot.label.useRobotAssistants":"Auf Roboterassistenten zugreifen",
  "ccmessaging.chatbot.label.update":"Aktualisieren",
  "ccmessaging.chatbot.label.pleaseSelect":"Bitte auswählen",
  "ccmessaging.chatbot.label.pleaseInput":"Bitte geben Sie ein",
  "ccmessaging.chatbot.label.intelligentRobotAccessCode":"Roboter-Zugangscode",
  "ccmessaging.chatbot.label.selectIntelligentRobot":"Intelligenten Roboter auswählen",
  "ccmessaging.chatbot.label.selectRobotAssistants":"Roboterassistent auswählen",
  "ccmessaging.chatbot.check.systeam":"System",
  "ccmessaging.chatbot.label.robotName":"Robotername",
  "ccmessaging.chatbot.label.selectRobotAvatar":"Roboter-Avatar auswählen",
  "ccmessaging.fiveG.label.chatRobotAddress":"Chatbot-Adresse",
  "ccmessaging.fiveG.label.appId":"App-ID",
  "ccmessaging.fiveG.label.appSecret":"App-Geheimnis",
  "ccmessaging.fiveG.label.certCode":"Zertifikatsdatei",
  "ccmessaging.email.label.selectGateway":"Gateway auswählen",
  "ccmessaging.email.label.gatewayName":"Gateway-Name",
  "ccmessaging.email.label.InputGatewayName":"Bitte geben Sie einen Gateway-Namen ein",
  "ccmessaging.email.label.defaultReceiveGateway":"Standard-E-Mail-Adresse",
  "ccmessaging.email.label.defaultSendGateway":"Standard-E-Mail-Adresse",
  "ccmessaging.sms.label.defaultSendGateway":"Standard-Absendernummer",
  "ccmessaging.sms.label.defaultReceiveGateway":"Standardempfängernummer",
  "ccmessaging.email.valid.signatureSearch":"kann bis zu 10 Buchstaben oder Zahlen enthalten.",
  "ccmessaging.service.time.label":"Arbeitskalenderkonfiguration",
  "ccmessaging.service.time.choose.time":"Wählen Sie arbeitsfreie Tage aus",
  "ccmessaging.service.time.select.holiday":"Wählen Sie Ruhedaten aus",
  "ccmessaging.service.time.select.weekday":"Wählen Sie ein Arbeitsdatum aus",
  "ccmessaging.multimedia.tip.not.exist":"Die Multimediaressource existiert nicht",
  "ccmessaging.multimedia.tip.modified":"Die Multimedia-Ressource wurde geändert. Bitte erneut senden.",
  "ccmessaging.service.time.close.alert":"Schließen löscht alle Daten in der Tabelle und bestätigt das Schließen",
  "ccmessaging.chat.phrase.create":"Geläufige Sätze erstellen",
  "ccmessaging.chat.phrase.update":"Geläufige Sätze von Eidt",
  "ccmessaging.chat.specialChannel.create":"Erstellen einer speziellen Listenregel",
  "ccmessaging.chat.specialChannel.update":"Bearbeiten einer Regel für spezielle Listen",
  "ccmessaging.emailAdress.contentNotNull":"Die E-Mail-Adresse darf nicht leer sein.",
  "ccmessaging.smsNumber.contentNotNull":"Die Mobiltelefonnummer darf nicht leer sein.",
  "ccmessaging.callcenterinstanceslist.vcallcenterDeatil.passValidateFailed":"Das Kennwort muss mindestens drei der folgenden Typen enthalten: Großbuchstaben, Kleinbuchstaben, Ziffern und die folgenden Sonderzeichen: {n}",
  "ccmessaging.chat.shortcut.shortcutRepeat":"Die Tastenkombination wurde verwendet.",
  "ccmessaging.chat.multimedia.agentHangupSessionResult":"Die Sitzung wurde erfolgreich beendet. Bitte schließen Sie den CRM-Arbeitsauftrag.",
  "ccmessaging.chat.multimedia.agentAutoTransferResult":"Der Anruf wurde erfolgreich weitergeleitet. Bitte schließen Sie den CRM-Arbeitsauftrag.",
  "ccmessaging.chat.multimedia.agentTimeOutTransferResult":"Die Sitzung läuft ab und wird an die Skill-Warteschlange weitergeleitet. Schließen Sie den CRM-Arbeitsauftrag.",
  "ccmessaging.chat.multimedia.userHangupSessionResult":"Der Benutzer beendet die Sitzung proaktiv. Bitte schließen Sie den CRM-Arbeitsauftrag.",
  "ccmessaging.chat.multimedia.userTimeOutHangupResult":"Die Sitzung läuft ab. Schließen Sie den CRM-Arbeitsauftrag.",
  "ccmessaging.chat.multimedia.agentLogoutHangupResult":"Der Agent meldet sich zwangsweise ab. Die Sitzung wird erfolgreich beendet. Bitte schließen Sie den CRM-Arbeitsauftrag.",
  "ccmessaging.chat.dy.reconnect":"Wiederverbinden",
  "ccmessaging.chat.dy.uploadLocalFile":"Lokale Dateien",
  "ccmessaging.chat.dy.call.end":"Die Sitzung wurde beendet.",
  "ccmessaging.chat.richtext.httpTip":"Der eingefügte Link verwendet ein unsicheres Protokoll, das Sicherheitsrisiken birgt. Möchten Sie ihn wirklich einreichen?",
  "ccmessaging.chat.riskTip":"Risikowarnung",
  "ccmessaging.chat.chatcard.location":"Adresse",
  "ccmessaging.chat.chatcard.alignment":"Kartenanzeigestil",
  "ccmessaging.chat.chatcard.templateType":"Vorlagentyp",
  "ccmessaging.chat.chatcard.templateTypeList0":"Gemeinsamer Typ",
  "ccmessaging.chat.chatcard.templateTypeList1":"Kartenoptionen",
  "ccmessaging.chat.chatcard.tabMediaResource":"Bildeigenschaft",
  "ccmessaging.chat.voice.record.send":"Stimme senden",
  "ccmessaging.chat.voice.record.start":"Aufzeichnen klicken",
  "ccmessaging.chat.voice.record.tip":"Die Aufnahmedauer beträgt höchstens 10 Minuten",
  "ccmessaging.chat.voice.record.stop":"Aufzeichnung stoppen",
  "ccmessaging.chat.selectorgs.maxlimit.warn":"Die Anzahl der ausgewählten Organisationen darf 100 nicht überschreiten.",
  "ccmessaging.chat.chatconfig.deleteFacebookPage":"Facebook-Startseite löschen",
  "ccmessaging.chat.search.name":"Suchname",
  "ccmessaging.chat.search.phraseName":"Geläufige Phrasen",
  "ccmessaging.chat.chatcard.tabnamevalidate":"redirectUrl ist der Systemstandard und nicht zulässig",
  "ccmessaging.chat.chatcard.transverse":"quer",
  "ccmessaging.chat.chatcard.longitudinal":"Längs",
  "ccmessaging.chat.email.attachment.tips.maxOnceUpload":"Es können maximal fünf Anhänge hochgeladen werden.",
  "ccmessaging.chat.email.attachment.tips.maxTotalUpload":"Die Anzahl der hochgeladenen Anhänge übersteigt 10.",
  "ccmessaging.chat.voice.record":"Stimmeingabe",
  "ccmessaging.chat.offline.userIsInService":"Bestehende Benutzer des Agentendienstes",
  "ccmessaging.chat.channelconfig.search.by.robotNameKeyword":"Buscar el nombre del bot o el código de acceso",
  "ccmessaging.chat.channelconfig.searchSkillQueueName":"Nombre de la cola de habilidades de búsqueda",
  "ccmessaging.chat.user.online":"Der Benutzer ist online.",
  "ccmessaging.chat.async.echarts.user":"Anzahl der Personen (Zeiten)",
  "ccmessaging.chat.async.echarts.message":"Nachricht (Nummer)",
// #VALUE!
  "cobrowse.sessionrecord.roomId":"Zimmernummer",
  "cobrowse.sessionrecord.searchroomId":"Bitte geben Sie die Zimmernummer ein",
  "cobrowse.sessionrecord.workNo":"Agentennummer",
  "cobrowse.sessionrecord.searchworkNo":"Bitte geben Sie die Agentennummer ein",
  "cobrowse.sessionrecord.caller":"Anrufer",
  "cobrowse.sessionrecord.searchcaller":"Geben Sie die Anruferdaten ein",
  "cobrowse.sessionrecord.startAt":"Startzeit",
  "cobrowse.sessionrecord.endAt":"Endzeit",
  "cobrowse.sessionrecord.duration":"Dauer",
  "cobrowse.sessionrecord.positiveInteger.checkmsg":"Geben Sie eine positive Ganzzahl ein",
  "cobrowse.sensitiveinfo.pageMatchValue":"Seitenzuordnungskonfiguration",
// #VALUE!
  "cobrowse.sensitiveinfo.rulenamecheck":"Der Regelname darf nur alphanumerische _. und maximal 128 Zeichen enthalten",
  "cobrowse.sensitiveinfo.url.check":"Der für den Seitenabgleich konfigurierte URL-Pfad hat ein falsches Format“",
  "cobrowse.sensitiveinfo.cssselectorcheck":"Syntaxfehler beim CSS-Selektor",
  "cobrowse.sensitiveinfo.pageIdCheck":"Die Seiten-ID darf nur Buchstaben, Ziffern, _-: enthalten. und maximal 1024 Zeichen",
  "cobrowse.sensitiveinfo.create":"Eine neue Regel für sensible Informationen erstellen",
  "cobrowse.sensitiveinfo.edit":"Eine neue Regel für sensible Informationen bearbeiten",
  "cobrowse.sensitiveinfo.createnameexist":"Name existiert bereits",
  "cobrowse.sensitiveinfo.exceedmaxcount":"Pro Mandant können bis zu 100 sensible Regeln konfiguriert werden",
  "cobrowse.sensitiveinfo.delete":"Regel für sensible Informationen löschen",
  "cobrowse.sensitiveinfo.willdeletetips":"Möchten Sie die ausgewählte Regel für vertrauliche Informationen wirklich löschen?",
  "cobrowse.sensitiveinfo.deletenum.outstripping":"Es können maximal 100 Datensätze gleichzeitig gelöscht werden.",
  "cobrowse.common.tips.selectempty":"Bitte wählen Sie die Daten aus, die zuerst ausgeführt werden sollen",
  "cobrowse.sessionrecord.workNo.rules":"Muss im Bereich von 1 bis 65535 liegen",
  "ccmessaging.chat.chatconfig.batch.delete":"Stapel löschen",
  "ccmessaging.chat.chatconfig.create":"Neu erstellt",
  "ccmessaging.channelconfig.export.datascope":"Datenumfang",
  "ccmessaging.channelconfig.export.allscope":"Alle",
  "ccmessaging.channelconfig.export.curPage":"Aktuelle Seite",
  "ccmessaging.channelconfig.export.customField":"Benutzerdefiniertes Feld",
  "ccmessaging.channelconfig.export.selectedField":"Feld exportieren",
  "ccmessaging.channelconfig.export.selectAll":"Alle",
  "ccmessaging.channelconfig.export.zipPwd":"Komprimiertes Kennwort",
  "ccmessaging.channelconfig.export.zipPwdHint":"Bitte geben Sie das Komprimierungskennwort ein",
  "ccmessaging.channelconfig.export.pwdMsg":"Passwort zum Erstellen einer komprimierten Datei. Der Wert ist eine Zeichenfolge von 8 bis 12 Zeichen und muss Buchstaben, Ziffern und Sonderzeichen enthalten (ausgenommen {'|;&$-<>/'}{'\\\\'}{'`!#(){}'}, Leerzeichen, Zeilenumbruchzeichen und Tabulatorzeichen ).",
  'ccmessaging.channelconfig.export.pwdMsgError':"Der Wert ist eine Zeichenfolge von 8 bis 12 Zeichen und muss Buchstaben, Ziffern und Sonderzeichen (außer {'|;&$-<>/'}{'\\\\'}{'`!#(){}'},-Leerzeichen, Zeilenumbruchzeichen und Tabulatorzeichen) enthalten.",
  "ccmessaging.channelconfig.export":"Exportieren",
  "ccmessaging.channelconfig.viewExportTask":"Aufgabenansicht exportieren",
  "ccmessaging.channelconfig.export.title":"Kanalkonfiguration exportieren",
  'ccmessaging.channelconfig.export.exportAll':"Sie haben keine bestimmten Datensätze ausgewählt. Alle Datensätze exportieren? Sie können den Vorgang auch abbrechen und einen bestimmten Datensatz zum Exportieren auswählen.",
  'ccmessaging.channelconfig.export.exportAllTitle':"Alle Konfigurationen exportieren",
  "ccmessaging.channelconfig.export.allExportConfirm":"Alle Exporte",
  'ccmessaging.channelconfig.export.exportSelect':"Sie haben {0} Datensätze ausgewählt. Möchten Sie wirklich fortfahren?",
  "ccmessaging.channelconfig.export.exportBeginTime":"Startzeit des Exports",
  "ccmessaging.channelconfig.export.exportEndTime":"Endzeit des Exports",
  "ccmessaging.channelconfig.export.exportTaskStatus":"Status",
  "ccmessaging.channelconfig.export.exporting":"Wird exportiert",
  "ccmessaging.channelconfig.export.unprocessed":"Unverarbeitete ",
  'ccmessaging.channelconfig.export.resultMsg':"Es wurde eine Exportaufgabe erstellt. Möchten Sie die Exportliste wirklich anzeigen?",
  "ccmessaging.channelconfig.export.resultSuccess":"Erstellt erfolgreich.",
  "ccmessaging.channelconfig.export.exception":"Bei der Generierung der Exportaufgabe ist ein Fehler aufgetreten. Überprüfen Sie die Protokolle.",
  "ccmessaging.channelconfig.export.error":"Neue Exportaufgabe fehlgeschlagen. Bitte überprüfen Sie das Protokoll",
  "ccmessaging.channelconfig.export.paramInvalid":"Die Ausfuhrbedingungen konnten nicht überprüft werden.",
  "ccmessaging.channelconfig.export.exceedlimit":"Es können maximal {0} Datensätze exportiert werden",
  "ccmessaging.channelconfig.export.nodata":"Keine Kanalkonfiguration erfüllt die Bedingungen.",
  "ccmessaging.chat.holiday.deleteWorkDay":"Ein angegebenes Arbeitsdatum löschen",
  "ccmessaging.chat.holiday.deleteAllWorkDay":"Alle angegebenen Arbeitstage löschen",
  "ccmessaging.chat.holiday.deleteHoliday":"Löschen eines bestimmten Ruhedatums",
  "ccmessaging.chat.holiday.deleteAllHoliday":"Alle angegebenen Ruhetage löschen",
  "ccmessaging.multimedia.leftMenu.category":"Kategorie",
  "ccmessaging.multimedia.leftMenu.createCategory":"Kategorie erstellen",
  "ccmessaging.multimedia.leftMenu.editCategory":"Kategorie bearbeiten",
  "ccmessaging.multimedia.audio.create":"Stimme erstellen",
  "ccmessaging.multimedia.audio.edit":"Stimme bearbeiten",
  "ccmessaging.multimedia.video.create":"Video erstellen",
  "ccmessaging.multimedia.video.edit":"Video bearbeiten",
  "ccmessaging.multimedia.image.create":"Bild erstellen",
  "ccmessaging.multimedia.image.edit":"Bild bearbeiten",
  "ccmessaging.multimedia.emoticon.create":"Emoticon erstellen",
  "ccmessaging.multimedia.emoticon.edit":"Emoticon bearbeiten",
  "ccmessaging.multimedia.document.create":"Dokument erstellen",
  "ccmessaging.multimedia.document.edit":"Dokument bearbeiten",
  "ccmessaging.multimedia.richText.create":"Rich-Text erstellen",
  "ccmessaging.multimedia.richText.edit":"Rich-Text bearbeiten",
  "ccmessaging.multimedia.local.create":"Adresse erstellen",
  "ccmessaging.multimedia.local.edit":"Adresse bearbeiten",
  'ccmessaging.multimedia.whatsAppTemplate.create':"WhatsApp-Vorlage erstellen",
  'ccmessaging.multimedia.whatsAppTemplate.edit':"WhatsApp-Vorlage bearbeiten",
  "ccmessaging.multimedia.siteAddr.create":"Cobrowse-Site erstellen",
  "ccmessaging.multimedia.siteAddr.edit":"Cobrowse-Site bearbeiten",
  "ccmessaging.multimedia.cardFiveG.create":"5G-Karte erstellen",
  "ccmessaging.multimedia.cardFiveG.edit":"5G-Karte bearbeiten",
  "ccmessaging.multimedia.carouselCardFiveG.create":"5G-Karussellkarte erstellen",
  "ccmessaging.multimedia.carouselCardFiveG.edit":"5G-Karussellkarte bearbeiten",
  'ccmessaging.multimedia.whatsAppTemplateMsg.create':"WhatsApp-Vorlagennachricht erstellen",
  'ccmessaging.multimedia.whatsAppTemplateMsg.edit':"WhatsApp-Vorlagennachricht bearbeiten",
  "ccmessaging.multimedia.cardWeb.create":"Webkarte erstellen",
  "ccmessaging.multimedia.cardWeb.edit":"Webkarte bearbeiten",
  "chat.agent.qualityCheckWorkbench.title":"Inspektionswerkbank",
  "chat.agent.qualityCheckWorkbench.currentAgent":"Inspizierter Agent:",
  "chat.agent.qualityCheckWorkbench.subscribeSessionFailed":"Die Agentensitzung konnte nicht abonniert werden.",
  "chat.agent.qualityCheckWorkbench.cancelSubscribeSessionFailed":"Das Abonnement der Agentensitzung konnte nicht gekündigt werden.",
  "chat.agent.qualityCheckWorkbench.pollMsgFailed":"Die Agentennachricht konnte nicht abgerufen werden.",
  "chat.agent.qualityCheckWorkbench.listen":"Einfügung abbrechen",
  "chat.agent.qualityCheckWorkbench.insert":"Einfügen",
  "chat.agent.qualityCheckWorkbench.intercept":"Abfangen",
  "chat.agent.qualityCheckWorkbench.changeQualityStatus.fail":"Der Inspektionsstatus konnte nicht geändert werden.",
  "chat.agent.msg.redis.connection.exception.description":"Das aktuelle System ist defekt. Bitte warten Sie.",
  "ccmessaging.chat.phrase.editPhraseTypeFail":"Das Bearbeiten des allgemeinen Ausdruckstyps ist fehlgeschlagen.",
  "ccmessaging.chat.phrase.editPhraseTypeSucess":"Gemeinsamen Phrasentyp erfolgreich bearbeiten.",
  "ccmessaging.chat.phrase.editPhraseFail":"Bearbeiten des allgemeinen Satzes fehlgeschlagen",
  "ccmessaging.chat.phrase.editPhraseSucess":"Gemeinsame Phrase erfolgreich bearbeiten",
  "ccmessaging.chat.multimedia.editMultiMediaInfo":"Multimediadaten erfolgreich bearbeitet",
  "ccmessaging.chat.multimedia.editMultiMediaInfoError":"Bearbeiten der Multimediadaten fehlgeschlagen",
  "ccmessaging.chat.multimedia.editMultiMediaType":"Multimediatyp erfolgreich bearbeitet",
  "ccmessaging.chat.multimedia.editMultiMediaTypeError":"Bearbeiten des Multimediatyps fehlgeschlagen",
  "ccmessaging.chat.multimedia.createMultiMediaInfo":"Multimediadaten erfolgreich erstellt",
  "ccmessaging.chat.multimedia.createMultiMediaInfoError":"Erstellen von Multimediadaten fehlgeschlagen",
  "ccmessaging.chat.multimedia.createMultiMediaType":"Multimediatyp erfolgreich erstellt",
  "ccmessaging.chat.multimedia.createMultiMediaTypeError":"Multimediatyp konnte nicht erstellt werden",
  "ccmessaging.chat.phrase.createTypeSuccess":"Gemeinsamen Phrasentyp erfolgreich erstellt",
// #VALUE!
  "ccmessaging.chat.multimedia.createVideoInfo":"Videodaten erfolgreich erstellt",
  "ccmessaging.chat.multimedia.createVideoInfoError":"Erstellen von Videodaten fehlgeschlagen",
  "ccmessaging.chat.multimedia.createVideoType":"Videotyp erfolgreich erstellt",
  "ccmessaging.chat.multimedia.createVideoTypeError":"Videotyp konnte nicht erstellt werden",
  "ccmessaging.chat.multimedia.editVideoInfo":"Videodaten erfolgreich bearbeitet",
  "ccmessaging.chat.multimedia.editVideoInfoError":"Bearbeiten der Videodaten fehlgeschlagen",
  "ccmessaging.chat.multimedia.editVideoType":"Videotyp erfolgreich bearbeitet",
  "ccmessaging.chat.multimedia.editVideoTypeError":"Bearbeiten des Videotyps fehlgeschlagen",
  "ccmessaging.chat.multimedia.deleteVideoInfo":"Videodaten erfolgreich gelöscht",
  "ccmessaging.chat.multimedia.deleteVideoInfoError":"Videodaten konnten nicht gelöscht werden",
  "ccmessaging.chat.multimedia.deleteVideoType":"Videotyp erfolgreich gelöscht",
  "ccmessaging.chat.multimedia.deleteVideoTypeError":"Videotyp konnte nicht gelöscht werden",
  "ccmessaging.chat.multimedia.deleteVideoTypeError.usedbyODFS":"Videotyp konnte nicht gelöscht werden. Die Ressourcen, auf die das intelligente IVR verweist, sind vorhanden.",
  "ccmessaging.chat.multimedia.deleteVideoTypeError.referenced.byCard":"Videotyp konnte nicht gelöscht werden, da einige Ressourcen bereits von Karten referenziert werden.",
  "ccmessaging.chat.multimedia.deleteVideoTypeError.referenced.byWhatsAppTemplateMsg":"Das Löschen des Videotyps ist fehlgeschlagen, da auf einige Ressourcen bereits durch eine WhatsApp-Vorlagennachricht verwiesen wird.",
  "ccmessaging.chat.multimedia.createAudioInfo":"Audiodaten erfolgreich erstellt",
  "ccmessaging.chat.multimedia.createAudioInfoError":"Erstellen von Audiodaten fehlgeschlagen",
  "ccmessaging.chat.multimedia.createAudioType":"Audiotyp erfolgreich erstellt",
  "ccmessaging.chat.multimedia.createAudioTypeError":"Audiotyp konnte nicht erstellt werden",
  "ccmessaging.chat.multimedia.editAudioInfo":"Audiodaten erfolgreich bearbeitet",
  "ccmessaging.chat.multimedia.editAudioInfoError":"Die Bearbeitung der Audiodaten ist fehlgeschlagen",
  "ccmessaging.chat.multimedia.editAudioType":"Audiotyp erfolgreich bearbeitet",
  "ccmessaging.chat.multimedia.editAudioTypeError":"Die Bearbeitung des Audiotyps ist fehlgeschlagen",
  "ccmessaging.chat.multimedia.deleteAudioInfo":"Audiodaten erfolgreich gelöscht",
  "ccmessaging.chat.multimedia.deleteAudioInfoError":"Löschen der Audiodaten fehlgeschlagen",
  "ccmessaging.chat.multimedia.deleteAudioType":"Audiotyp erfolgreich gelöscht",
  "ccmessaging.chat.multimedia.deleteAudioTypeError":"Audiotyp konnte nicht gelöscht werden",
  "ccmessaging.chat.multimedia.deleteAudioTypeError.usedbyODFS":"Audiotyp konnte nicht gelöscht werden. Die Ressourcen, auf die das intelligente IVR verweist, sind vorhanden.",
  "ccmessaging.chat.multimedia.deleteAudioTypeError.referenced.byCard":"Audiotyp konnte nicht gelöscht werden, da einige Ressourcen bereits von Karten referenziert werden.",
  "ccmessaging.chat.multimedia.deleteAudioTypeError.referenced.byWhatsAppTemplateMsg":"Audiotyp konnte nicht gelöscht werden, da auf einige Ressourcen bereits durch eine WhatsApp-Vorlagennachricht verwiesen wird.",
  "ccmessaging.chat.multimedia.createImageInfo":"Bilddaten erfolgreich erstellt",
  "ccmessaging.chat.multimedia.createImageInfoError":"Erstellen von Bilddaten fehlgeschlagen",
  "ccmessaging.chat.multimedia.createImageType":"Image-Typ erfolgreich erstellt",
  "ccmessaging.chat.multimedia.createImageTypeError":"Image-Typ konnte nicht erstellt werden",
  "ccmessaging.chat.multimedia.editImageInfo":"Bilddaten erfolgreich bearbeitet",
  "ccmessaging.chat.multimedia.editImageInfoError":"Bearbeiten der Bilddaten fehlgeschlagen",
  "ccmessaging.chat.multimedia.editImageType":"Image-Typ erfolgreich bearbeitet",
  "ccmessaging.chat.multimedia.editImageTypeError":"Bearbeiten des Bildtyps fehlgeschlagen",
  "ccmessaging.chat.multimedia.deleteImageInfo":"Bilddaten erfolgreich gelöscht",
  "ccmessaging.chat.multimedia.deleteImageInfoError":"Bilddaten konnten nicht gelöscht werden",
  "ccmessaging.chat.multimedia.deleteImageType":"Image-Typ erfolgreich gelöscht",
  "ccmessaging.chat.multimedia.deleteImageTypeError":"Image-Typ konnte nicht gelöscht werden",
  "ccmessaging.chat.multimedia.deleteImageTypeError.usedbyODFS":"Image-Typ konnte nicht gelöscht werden. Die Ressourcen, auf die das intelligente IVR verweist, sind vorhanden.",
  "ccmessaging.chat.multimedia.deleteImageTypeError.referenced.byCard":"Das Löschen des Bildtyps ist fehlgeschlagen, da einige Ressourcen bereits von Karten referenziert werden.",
  "ccmessaging.chat.multimedia.deleteImageTypeError.referenced.byWhatsAppTemplateMsg":"Das Löschen des Image-Typs ist fehlgeschlagen, da auf einige Ressourcen bereits durch eine WhatsApp-Vorlagennachricht verwiesen wird.",
  "ccmessaging.chat.multimedia.createDocumentInfo":"Dokumentdaten erfolgreich erstellt",
  "ccmessaging.chat.multimedia.createDocumentInfoError":"Erstellen von Dokumentdaten fehlgeschlagen",
  "ccmessaging.chat.multimedia.createDocumentType":"Dokumenttyp erfolgreich erstellt",
  "ccmessaging.chat.multimedia.createDocumentTypeError":"Erstellen des Dokumenttyps fehlgeschlagen",
  "ccmessaging.chat.multimedia.editDocumentInfo":"Dokumentdaten erfolgreich bearbeitet",
  "ccmessaging.chat.multimedia.editDocumentInfoError":"Bearbeiten der Dokumentdaten fehlgeschlagen",
  "ccmessaging.chat.multimedia.editDocumentType":"Dokumenttyp erfolgreich bearbeitet",
  "ccmessaging.chat.multimedia.editDocumentTypeError":"Bearbeiten des Dokumenttyps fehlgeschlagen",
  "ccmessaging.chat.multimedia.deleteDocumentInfo":"Dokumentdaten erfolgreich gelöscht",
  "ccmessaging.chat.multimedia.deleteDocumentInfoError":"Das Löschen der Dokumentdaten ist fehlgeschlagen",
  "ccmessaging.chat.multimedia.deleteDocumentType":"Dokumenttyp erfolgreich gelöscht",
  "ccmessaging.chat.multimedia.deleteDocumentTypeError":"Dokumenttyp konnte nicht gelöscht werden",
  "ccmessaging.chat.multimedia.deleteDocumentTypeError.usedbyODFS":"Dokumenttyp konnte nicht gelöscht werden. Die Ressourcen, auf die das intelligente IVR verweist, sind vorhanden.",
  "ccmessaging.chat.multimedia.deleteDocumentTypeError.referenced.byCard":"Das Löschen des Dokumenttyps ist fehlgeschlagen, da einige Ressourcen bereits von Karten referenziert werden.",
  "ccmessaging.chat.multimedia.deleteDocumentTypeError.referenced.byWhatsAppTemplateMsg":"Das Löschen des Dokumenttyps ist fehlgeschlagen, da auf einige Ressourcen bereits durch eine WhatsApp-Vorlagennachricht verwiesen wird.",
  "ccmessaging.chat.multimedia.createLocateInfo":"Standortdaten erfolgreich erstellt",
  "ccmessaging.chat.multimedia.createLocateInfoError":"Standortdaten konnten nicht erstellt werden",
  "ccmessaging.chat.multimedia.createLocateType":"Standorttyp erfolgreich erstellt",
  "ccmessaging.chat.multimedia.createLocateTypeError":"Standorttyp konnte nicht erstellt werden",
  "ccmessaging.chat.multimedia.editLocateInfo":"Standortdaten erfolgreich bearbeitet",
  "ccmessaging.chat.multimedia.editLocateInfoError":"Bearbeiten der Standortdaten fehlgeschlagen",
  "ccmessaging.chat.multimedia.editLocateType":"Standorttyp erfolgreich bearbeitet",
  "ccmessaging.chat.multimedia.editLocateTypeError":"Bearbeiten des Standorttyps fehlgeschlagen",
  "ccmessaging.chat.multimedia.deleteLocateInfo":"Standortdaten erfolgreich gelöscht",
  "ccmessaging.chat.multimedia.deleteLocateInfoError":"Löschen der Standortdaten fehlgeschlagen",
  "ccmessaging.chat.multimedia.deleteLocateType":"Standorttyp erfolgreich gelöscht",
  "ccmessaging.chat.multimedia.deleteLocateTypeError":"Löschen des Standorttyps fehlgeschlagen",
  "ccmessaging.chat.multimedia.deleteLocateTypeError.usedbyODFS":"Löschen des Standorttyps fehlgeschlagen. Die Ressourcen, auf die das intelligente IVR verweist, sind vorhanden.",
  "ccmessaging.chat.multimedia.deleteLocateTypeError.referenced.byCard":"Löschen des Standorttyps fehlgeschlagen, da einige Ressourcen bereits von Karten referenziert werden.",
  "ccmessaging.chat.multimedia.deleteLocateTypeError.referenced.byWhatsAppTemplateMsg":"Löschen des Speicherorttyps fehlgeschlagen, da auf einige Ressourcen bereits durch eine WhatsApp-Vorlagennachricht verwiesen wird.",
  "ccmessaging.chat.multimedia.createEmotionInfo":"Emoticon-Daten erfolgreich erstellt",
  "ccmessaging.chat.multimedia.createEmotionInfoError":"Emoticon-Daten konnten nicht erstellt werden",
  "ccmessaging.chat.multimedia.createEmotionType":"Emoticon-Typ erfolgreich erstellt",
  "ccmessaging.chat.multimedia.createEmotionTypeError":"Emoticon-Typ konnte nicht erstellt werden",
  "ccmessaging.chat.multimedia.editEmotionInfo":"Emoticon-Daten erfolgreich bearbeitet",
  "ccmessaging.chat.multimedia.editEmotionInfoError":"Bearbeiten der Emoticon-Daten fehlgeschlagen",
  "ccmessaging.chat.multimedia.editEmotionType":"Emoticon-Typ erfolgreich bearbeitet",
  "ccmessaging.chat.multimedia.editEmotionTypeError":"Emoticon-Typ konnte nicht bearbeitet werden",
  "ccmessaging.chat.multimedia.deleteEmotionInfo":"Emoticon-Daten erfolgreich gelöscht",
  "ccmessaging.chat.multimedia.deleteEmotionInfoError":"Emoticon-Daten konnten nicht gelöscht werden",
  "ccmessaging.chat.multimedia.deleteEmotionType":"Emoticon-Typ erfolgreich gelöscht",
  "ccmessaging.chat.multimedia.deleteEmotionTypeError":"Emoticon-Typ konnte nicht gelöscht werden",
  "ccmessaging.chat.multimedia.deleteEmotionTypeError.usedbyODFS":"Emoticon-Typ konnte nicht gelöscht werden. Die Ressourcen, auf die das intelligente IVR verweist, sind vorhanden.",
  "ccmessaging.chat.multimedia.deleteEmotionTypeError.referenced.byCard":"Emoticon-Typ konnte nicht gelöscht werden, da einige Ressourcen bereits von Karten referenziert werden.",
  "ccmessaging.chat.multimedia.deleteEmotionTypeError.referenced.byWhatsAppTemplateMsg":"Das Löschen des Emoticon-Typs ist fehlgeschlagen, da auf einige Ressourcen bereits durch eine WhatsApp-Vorlagennachricht verwiesen wird.",
  "ccmessaging.chat.multimedia.createRichTextInfo":"Rich-Text-Daten erfolgreich erstellt",
  "ccmessaging.chat.multimedia.createRichTextInfoError":"Erstellen von Rich-Text-Daten fehlgeschlagen",
  "ccmessaging.chat.multimedia.createRichTextType":"Rich-Text-Typ erfolgreich erstellt",
  "ccmessaging.chat.multimedia.createRichTextTypeError":"Rich-Text-Typ konnte nicht erstellt werden",
  "ccmessaging.chat.multimedia.editRichTextInfo":"Rich-Text-Daten erfolgreich bearbeitet",
  "ccmessaging.chat.multimedia.editRichTextInfoError":"Bearbeiten der Rich-Text-Daten fehlgeschlagen",
  "ccmessaging.chat.multimedia.editRichTextType":"Rich-Text-Typ erfolgreich bearbeitet",
  "ccmessaging.chat.multimedia.editRichTextTypeError":"Bearbeiten des Rich-Text-Typs fehlgeschlagen",
  "ccmessaging.chat.multimedia.deleteRichTextInfo":"Rich-Text-Daten erfolgreich gelöscht",
  "ccmessaging.chat.multimedia.deleteRichTextInfoError":"Das Löschen von Rich-Text-Daten ist fehlgeschlagen",
  "ccmessaging.chat.multimedia.deleteRichTextType":"Rich-Text-Typ erfolgreich gelöscht",
  "ccmessaging.chat.multimedia.deleteRichTextTypeError":"Das Löschen des Rich-Text-Typs ist fehlgeschlagen",
  "ccmessaging.chat.multimedia.deleteRichTextTypeError.usedbyODFS":"Rich-Text-Typ konnte nicht gelöscht werden. Die Ressourcen, auf die das intelligente IVR verweist, sind vorhanden.",
  "ccmessaging.chat.multimedia.deleteRichTextTypeError.referenced.byCard":"Das Löschen des Rich-Text-Typs ist fehlgeschlagen, da einige Ressourcen bereits von Karten referenziert werden.",
  "ccmessaging.chat.multimedia.deleteRichTextTypeError.referenced.byWhatsAppTemplateMsg":"Das Löschen des Rich-Text-Typs ist fehlgeschlagen, da auf einige Ressourcen bereits durch eine WhatsApp-Vorlagennachricht verwiesen wird.",
  "ccmessaging.chat.multimedia.createSiteAddrInfo":"Adressdaten für die Zusammenarbeit auf der Webseite erfolgreich erstellt",
  "ccmessaging.chat.multimedia.createSiteAddrInfoError":"Erstellen von Adressdaten für die Webseitenzusammenarbeit fehlgeschlagen",
  "ccmessaging.chat.multimedia.createSiteAddrType":"Adresstyp für die Webseitenzusammenarbeit erfolgreich erstellt",
  "ccmessaging.chat.multimedia.createSiteAddrTypeError":"Das Erstellen des Adresstyps für die Webseitenzusammenarbeit ist fehlgeschlagen",
  "ccmessaging.chat.multimedia.editSiteAddrInfo":"Die Adressdaten für die Webseitenzusammenarbeit wurden erfolgreich bearbeitet",
  "ccmessaging.chat.multimedia.editSiteAddrInfoError":"Fehler beim Bearbeiten der Adressdaten für die Webseitenzusammenarbeit",
  "ccmessaging.chat.multimedia.editSiteAddrType":"Adresstyp der Webseiten-Collaboration erfolgreich bearbeitet",
  "ccmessaging.chat.multimedia.editSiteAddrTypeError":"Fehler beim Bearbeiten des Adresstyps für die Webseitenzusammenarbeit",
  "ccmessaging.chat.multimedia.deleteSiteAddrInfo":"Adressdaten der Webseitenzusammenarbeit erfolgreich gelöscht",
  "ccmessaging.chat.multimedia.deleteSiteAddrInfoError":"Das Löschen der Adressdaten für die Webseitenzusammenarbeit ist fehlgeschlagen",
  "ccmessaging.chat.multimedia.deleteSiteAddrType":"Adresstyp der Webseitenzusammenarbeit erfolgreich gelöscht",
  "ccmessaging.chat.multimedia.deleteSiteAddrTypeError":"Adresstyp der Webseitenzusammenarbeit erfolgreich gelöscht",
  "ccmessaging.chat.multimedia.deleteSiteAddrTypeError.usedbyODFS":"Delete Web Page Collaboration Address Type ist fehlgeschlagen. Die Ressourcen, auf die das intelligente IVR verweist, sind vorhanden.",
  "ccmessaging.chat.multimedia.deleteSiteAddrTypeError.referenced.byCard":"Delete Web Page Collaboration Address Type ist fehlgeschlagen, da einige Ressourcen bereits von Karten referenziert werden.",
  "ccmessaging.chat.multimedia.deleteSiteAddrTypeError.referenced.byWhatsAppTemplateMsg":"Das Löschen des Adresstyps für die Webseitenzusammenarbeit ist fehlgeschlagen, da auf einige Ressourcen bereits durch eine WhatsApp-Vorlagennachricht verwiesen wird.",
  "ccmessaging.chat.chatcard.createFiveGCardFail":"Erstellen der 5G-Karte fehlgeschlagen",
  "ccmessaging.chat.chatcard.createFiveGCardSuccess":"5G-Karte erfolgreich erstellt",
  "ccmessaging.chat.chatcard.editFiveGCardFail":"Bearbeiten der 5G-Karte fehlgeschlagen",
  "ccmessaging.chat.chatcard.editFiveGCardSuccess":"5G-Karte erfolgreich bearbeitet",
  "ccmessaging.chat.chatcard.deleteFiveGCardFail":"Löschen der 5G-Karte fehlgeschlagen",
  "ccmessaging.chat.chatcard.deleteFiveGCardSuccess":"5G-Karte erfolgreich gelöscht",
  "ccmessaging.chat.chatcard.createFiveGCarouselFail":"Erstellung von 5G Carouse fehlgeschlagen",
  "ccmessaging.chat.chatcard.createFiveGCarouselSuccess":"5G Carouse erfolgreich erstellt",
  "ccmessaging.chat.chatcard.editFiveGCarouselFail":"Bearbeiten von 5G Carouse fehlgeschlagen",
  "ccmessaging.chat.chatcard.editFiveGCarouselSuccess":"5G Carouse erfolgreich bearbeitet",
  "ccmessaging.chat.chatcard.deleteFiveGCarouselFail":"Löschen von 5G Carouse fehlgeschlagen",
  "ccmessaging.chat.chatcard.deleteFiveGCarouselSuccess":"5G Carouse erfolgreich gelöscht",
  "ccmessaging.chat.chatcard.createWhatAppTemplateMeaasgeFail":"Erstellen der WhatsApp-Vorlagenmessung fehlgeschlagen",
  "ccmessaging.chat.chatcard.createWhatAppTemplateMeaasgeSuccess":"WhatsApp-Vorlagenmessung erfolgreich erstellt",
  "ccmessaging.chat.chatcard.editWhatAppTemplateMeaasgeFail":"Bearbeiten der WhatsApp-Vorlagenmessung fehlgeschlagen",
  "ccmessaging.chat.chatcard.editWhatAppTemplateMeaasgeSuccess":"WhatsApp-Vorlagenmessung erfolgreich bearbeitet",
  "ccmessaging.chat.chatcard.deleteWhatAppTemplateMeaasgeFail":"WhatsApp-Vorlagenmessung konnte nicht gelöscht werden",
  "ccmessaging.chat.chatcard.deleteWhatAppTemplateMeaasgeSuccess":"WhatsApp-Vorlagenmessung erfolgreich gelöscht",
  "ccmessaging.chat.chatcard.createWebCardFail":"WebCard konnte nicht erstellt werden",
  "ccmessaging.chat.chatcard.createWebCardSuccess":"WebCard erfolgreich erstellt",
  "ccmessaging.chat.chatcard.editWebCardFail":"WebCard konnte nicht bearbeitet werden",
  "ccmessaging.chat.chatcard.editWebCardSuccess":"WebCard erfolgreich bearbeitet",
  "ccmessaging.chat.chatcard.deleteWebCardFail":"WebCard konnte nicht gelöscht werden",
  "ccmessaging.chat.chatcard.deleteWebCardSuccess":"WebCard erfolgreich gelöscht",
  "ccmessaging.chat.WhatsApp.createWhatsAppTemplateSuccess":"WhatsApp-Vorlage erfolgreich erstellt",
  "ccmessaging.chat.WhatsApp.createWhatsAppTemplateFail":"Erstellen der WhatsApp-Vorlage fehlgeschlagen",
  "ccmessaging.chat.WhatsApp.editWhatsAppTemplateSuccess":"WhatsApp-Vorlage erfolgreich bearbeitet",
  "ccmessaging.chat.WhatsApp.editWhatsAppTemplateFail":"Bearbeiten der WhatsApp-Vorlage fehlgeschlagen",
  "ccmessaging.chat.WhatsApp.deleteWhatsAppTemplateSuccess":"WhatsApp-Vorlage erfolgreich gelöscht",
  "ccmessaging.chat.WhatsApp.deleteWhatsAppTemplateFail":"WhatsApp-Vorlage konnte nicht gelöscht werden",
  "ccmessaging.chat.WhatsApp.deleteWhatsAppTemplateFailMsg":"WhatsApp-Vorlage konnte nicht gelöscht werden. Die Vorlage wird von einer Vorlagennachricht referenziert.",
  "ccmessaging.multimedia.download.office.risktips":"Sie sind im Begriff, Office-Dokumente herunterzuladen, die ein Sicherheitsrisiko darstellen können. Es wird empfohlen, Antivirusvorgänge durchzuführen und die Sicherheitseinstellungen der Office-Software zu aktivieren.",
  "ccmessaging.chat.multimedia.documentUploadRiskTips":"Dieses Dokument wird von Agenten an die Benutzer gesendet. Laden Sie keine Dateien hoch, die Sicherheitsrisiken enthalten.",
  "ccmessaging.config.names.channels.web": "Web",
  "ccmessaging.config.names.channels.instagram": "Instagram",
  "ccmessaging.config.names.channels.telegram": "Telegramm",
  "ccmessaging.config.names.channels.whatsapp": "WhatsApp",
  "ccmessaging.config.names.channels.line": "LINIE",
  "ccmessaging.config.names.channels.wechat": "WeChat",
  "ccmessaging.config.names.channels.facebook": "Facebook",
  "ccmessaging.config.names.channels.twitter": "X (Twitter)",
  "ccmessaging.config.names.channels.rcs": "5G RCS",
  "ccmessaging.config.names.channels.email": "E-Mail-Adresse",
  "ccmessaging.config.names.channels.sms": "SMS",
  "ccmessaging.chat.multimedia.whatsApp.interactiveMsg": "Interaktive WhatsApp-Nachrichten",
  "ccmessaging.chat.whatsapp.interactiveMsgNamePlaceholder": "Geben Sie einen interaktiven Nachrichtennamen ein",
  "ccmessaging.chat.whatsapp.interactiveMsgName": "Nachrichtenname",
  "ccmessaging.chat.whatsapp.interactiveMsgTitle": "Titel",
  "ccmessaging.chat.whatsapp.interactiveMsgDescription": "Nachrichtenbeschreibung",
  "ccmessaging.chat.whatsapp.interactiveMsgDescriptionPlaceholder": "Geben Sie eine interaktive Nachrichtenbeschreibung ein",
  "ccmessaging.chat.whatsapp.interactiveMsgType": "Nachrichtentyp",
  "ccmessaging.chat.whatsapp.orgName": "Name der Organisation",
  "ccmessaging.chat.chatcard.queryInteractiveMsg": "Interaktive Nachrichten konnten nicht abgefragt werden",
  "ccmessaging.chat.whatsapp.interactiveType.buttons": "Schaltflächenmeldung",
  "ccmessaging.chat.whatsapp.interactiveType.list": "Nachricht auflisten",
  "ccmessaging.chat.whatsapp.delete.interactiveMsg": "Löschen interaktiver Nachrichten",
  "ccmessaging.chat.whatsapp.willdeleteinteractiveMsg": "Möchten Sie diese interaktive Nachricht wirklich löschen?",
  "ccmessaging.chat.whatsapp.interactiveTypes": "Interaktiver Nachrichtentyp",
  "ccmessaging.chat.whatsapp.interactiveMsgTitlePlaceholder": "Geben Sie interaktive Titelinhalte ein",
  "ccmessaging.chat.whatsapp.interactiveMsg.bodyContent": "Textkörperinhalt",
  "ccmessaging.chat.whatsapp.interactiveMsg.bodyContentPlaceholder": "Bitte geben Sie den Textinhalt ein.",
  "ccmessaging.chat.whatsapp.interactiveMsg.footerContent": "Fußzeileninhalt",
  "ccmessaging.chat.whatsapp.interactiveMsg.footerContentPlaceholder": "Geben Sie den Inhalt der Fußzeile ein.",
  "ccmessaging.chat.whatsapp.interactiveMsg.pleaseChoose": "Bitte auswählen",
  "ccmessaging.chat.whatsapp.interactiveMsg.attachments": "Anlagen",
  "ccmessaging.chat.multimedia.whatsApp.interactiveMsg.create": "Neue interaktive Nachricht",
  "ccmessaging.chat.multimedia.whatsApp.interactiveMsg.update": "Ändern einer interaktiven Nachricht",
  "ccmessaging.chat.whatsapp.interactiveType.list.title": "Liste",
  "ccmessaging.chat.whatsapp.interactiveType.list.titlePlaceholder": "Bitte geben Sie den Titel der Liste ein.",
  "ccmessaging.chat.whatsapp.interactiveMsg.openRow.info": "Gruppentitel ist leer. Die Option kann nicht festgelegt werden.",
  "ccmessaging.chat.whatsapp.interactiveType.section.titlePlaceholder": "Bitte geben Sie den Gruppentitel ein.",
  "ccmessaging.chat.whatsapp.interactiveType.row.titlePlaceholder": "Geben Sie einen Optionstitel ein.",
  "ccmessaging.chat.whatsapp.interactiveType.row.descPlaceholder": "Geben Sie eine Optionsbeschreibung ein.",
  "ccmessaging.chat.whatsapp.interactiveMsg.sectionTitle": "Bitte geben Sie den Titel der Liste ein.",
  "ccmessaging.chat.whatsapp.interactiveMsg.queryInteractiveError": "Interaktive Nachrichten konnten nicht abgefragt werden",
  "ccmessaging.chat.whatsapp.interactiveMsg.createInteractiveError": "Die interaktive Nachricht konnte nicht gespeichert werden. Nachdem die Liste gruppiert wurde, können die Gruppierungsoptionen nicht null sein.",
  "ccmessaging.chat.whatsapp.interactiveMsg.saveFail.duplicateName": "Speichern fehlgeschlagen. Der interaktive Nachrichtenname ist bereits vorhanden.",
  "ccmessaging.chat.whatsapp.interactiveMsg.noHeader": "Keine",
  "ccmessaging.chat.whatsapp.interactiveMsg.headerContent": "Kopfzeileninhalt",
  "ccmessaging.chat.whatsapp.interactiveMsg.no.buttons": "Die interaktive Nachricht konnte nicht gespeichert werden. Es gibt keine Einstellungstaste.",
  "ccmessaging.chat.whatsapp.interactiveMsg.no.lists": "Das Speichern der interaktiven Nachricht ist fehlgeschlagen. Keine Einstellungsliste.",
  "ccmessaging.chat.multimedia.deleteLimit.whatsapp.interactiveMsg": "Die Ressource wurde von interaktiven WhatsApp-Nachrichten referenziert und kann nicht gelöscht werden. Name der interaktiven Nachricht:",
  "ccmessaging.chat.whatsapp.interactiveMsg.saveFail.multiMedia.notExist": "Speichern fehlgeschlagen. Die Multimediaressource existiert nicht.",
  "ccmessaging.chat.whatsapp.interactiveMsg.createInteractiveError.repeateButtonContent": "Speichern fehlgeschlagen. Der Schaltflächeninhalt muss eindeutig sein.",
  "ccmessaging.chat.case.title": "Fallbezeichnung",
  "ccmessaging.chat.case.type": "Falltyp",
  "ccmessaging.chat.case.callReason": "Anrufgrund",
  "ccmessaging.chat.case.puzzled": "verwirrt",
  "ccmessaging.chat.case.calm": "Beruhige dich",
  "ccmessaging.chat.case.happy": "Glücklich",
  "ccmessaging.chat.case.upset": "verärgert",
  "ccmessaging.chat.case.surprise": "Überraschung",
  "ccmessaging.chat.case.pleasant": "angenehm",
  "ccmessaging.chat.case.angry": "wütend",
  "ccmessaging.chat.case.little.angry": "Ein wenig Wut",
  "ccmessaging.chat.case.very.angry": "Sehr wütend",
  "ccmessaging.chat.case.very.knowledgeId": "1228840300949016576",
  "ccmessaging.chat.case.complaint.yes": "ja",
  "ccmessaging.chat.case.complaint.shi": "ja",
  "ccmessaging.chat.degree.view": "360°-Kunde"
}
