import ccbarEnUS from './ccbar_en_US'
import ccbarZhCN from './ccbar_zh_CN'
import ccbarEsEs from './ccbar_es_ES'
import ccbarThTH from './ccbar_th_TH'
import ccbarPtBr from './ccbar_pt_BR'
import ccbarFrFr from './ccbar_fr_FR'

const lightccbar = {
    en_US: {
        ...ccbarEnUS,
    },
    zh_CN: {
        ...ccbarZhCN,
    },
    es_ES: {
        ...ccbarEsEs,
    },
    th_TH: {
        ...ccbarThTH,
    },
    pt_BR: {
        ...ccbarPtBr,
    },
    fr_FR: {
        ...ccbarFrFr
    },
}

export default lightccbar