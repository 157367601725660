export default {
  // #Spanish Internationalization
  // #Thu Aug 04 19:58:03 CST 2022
  "provision.encryptinfo.historical.version": "Versi\u00F3n anterior",
  "provision.callcent.platformserver.ip": "Direcci\u00F3n IP del servicio",
  "provision.callcent.error.accesscode.deletefail.all.onccgateway": "No se pudo eliminar el c\u00F3digo de acceso en la plataforma fue",
  "provision.callcenterinstanceslist.vcallcenter.nameDiffListPromt": "Hay un centro de llamadas virtual con VccName que es inconsistente con VdnName en WAS. El VccId es",
  "provision.callcent.success.updateinfo": "Actualizado correctamente.",
  "chat.usefulexpressions.message.saveSuccess": "Se guard\u00F3 la frase com\u00FAn con \u00E9xito.",
  "provision.callcent.error.checkparam": `Contiene characters("<"\,">"\,"\\"and no v\u00E1lido m\u00E1s)`,
  "provision.spinfo.addspinfosuccess": "Informaci\u00F3n de SP agregada correctamente.",
  "provision.tenant.chooseAC.first": "Seleccione un c\u00F3digo de acceso.",
  "chat.client.greeting.dear": "Estimado",
  "chat.agent.title.enterText": "Por favor ingrese",
  "promotion.tenant.management.tenantedit.provisionerror": "Error al actualizar el espacio del inquilino.",
  "provision.systemparam.url.safe.tip": "Se utilizan protocolos inseguros\, lo que provoca riesgos de seguridad",
  "chat.usefulexpressions.message.contentLength": "Aseg\u00FArese de que la longitud de la frase com\u00FAn es menor o igual a 1000 caracteres.",
  "provision.callcenterinstance.softPhone.length.Inconsistent": "Las longitudes del n\u00FAmero inicial y el n\u00FAmero final son inconsistentes.",
  "provision.softPhone.label.uapaddress.node2": "Direcci\u00F3n IP UAP del nodo 2",
  "provision.callcenterinstanceslist.label.ttschannelUseRate": "Uso de TTS",
  "provision.softPhone.label.uapaddress.node1": "Direcci\u00F3n IP UAP del nodo 1",
  "provision.tenantspace.table.chooseStatus": "Estado del espacio del inquilino",
  "provision.tenantspace.popuppageurl.whiteListStyle": "Tipo de direcci\u00F3n",
  "chat.agent.title.transfer": "Traslado a",
  "provision.tenantspace.save.success": "Se ha guardado correctamente.",
  "provision.tenantspace.gw.searchName": "Introduzca el nombre del servidor de puerta de enlace que desea consultar.",
  "provision.callcenterinstanceslist.config.wnTo": "Finalizar ID de empleado",
  "provision.callcenterinstanceslist.ccgateway.maxRegAgentNum": "Cantidad de agente simult\u00E1neo",
  "provision.callcent.error.updateaccesscode.fail.ondatabase": "\, no pudo modificar el c\u00F3digo de acceso en la base de datos",
  "chat.skill.label.inputName": "Nombre",
  "provision.softPhone.table.forcemodifyconfim": "Confirmar",
  "provision.appversionmanagement.pleaseSelect": "- Seleccionar-",
  "provision.tenant.management.pwd.checkmsg": "Las dos contrase\u00F1as son diferentes.",
  "chat.session.label.evaluation": "Evaluaci\u00F3n de di\u00E1logos",
  "provision.spinfo.issuerId": "ID de emisor",
  "provision.tenantspace.config.assignedworknonumber": "ID de agente asignado",
  "provision.callcenter.ivr.alert": "El valor debe ser el mismo que la capacidad concurrente del IVR desplegado.",
  "provision.callcenterinstance.accesscode.validatebeginaccesscode": "El n\u00FAmero inicial del c\u00F3digo de acceso no puede ser mayor que el n\u00FAmero final.",
  "provision.tenantspace.management": "Gesti\u00F3n de TenantSpace",
  "provision.tenantspace.obsServer.datasource.id": "ID de origen de datos",
  "provision.tenantspace.feature.agentSendLocalMediaFile.description": "Esta caracter\u00EDstica proporciona la funci\u00F3n de enviar archivos multimedia locales por agentes para el espacio del inquilino.",
  "chat.session.label.exitQueueTime": "Tiempo de espera",
  "provision.callcent.error.deletesipserver.part.associationvcallcener": "Algunos servidores SIP seleccionados est\u00E1n asociados con inquilinos y no se pueden eliminar.",
  "ccprovision.recorddrive.config.sftp.identitychecktips": "Se cambia la cuenta o la contrase\u00F1a del servidor SFTP. Para mantener la seguridad de la informaci\u00F3n\, ingrese su cuenta y contrase\u00F1a actuales.",
  "provision.tenantspace.feature.outboundCallMarketingSupport.description": "Despu\u00E9s de activar la funci\u00F3n de llamada saliente inteligente\, el espacio del inquilino admite la funci\u00F3n de llamada saliente inteligente.",
  "provision.callcenterinstanceslist.config.adminWnTo": "ID de empleado final de administrador",
  "provision.tenantspace.feature.forceVideoSelfRecord.forcibleSelfRecord": "Autograbaci\u00F3n forzada de la pantalla VDN",
  "chat.session.endType.customerEnd": "Fin por cliente",
  "provision.systemparam.error.reset": "Error al restablecimiento del par\u00E1metro",
  "provision.tenantspace.config.isCreateAccount": "Crear cuenta autom\u00E1ticamente para el centro de llamadas virtual",
  "chat.agent.label.send": "Enviar",
  "provision.tenantspace.table.stopTenantSpace.confirm": "Confirme la suspensi\u00F3n",
  "provision.callcenterinstanceslist.table.obssyncconfim": "Confirmar sincronizaci\u00F3n",
  "provision.sysparam.config.reset": "Restablecer",
  "provision.encryptinfo.title.encrypted.information": "Informaci\u00F3n de cifrado",
  "provision.callcenterinstanceslist.softPhone.newpassword": "Contrase\u00F1a nueva de Softphone",
  "provision.callcent.platformserver.cobrowse": "Servicio de Co-Navegaci\u00F3n (Co-Navegaci\u00F3n)",
  "chat.skill.label.skillDetail": "Detalles de Habilidad",
  "chat.skill.message.isExisted": "La cola de habilidades ya existe.",
  "provision.callcenterinstanceslist.vcallcenterDeatil.passValidateFailed": "La contrase\u00F1a debe contener al menos tres tipos de\: letras may\u00FAsculas\, min\u00FAsculas\, d\u00EDgitos y characters\: ~\! {'@'} \# $ % ^ & * () - especial _ \= /?",
  "chat.skill.message.success": "\u00C9xito",
  "provision.callcenterinstanceslist.softPhone.modifyPassword": "cambiar contrase\u00F1a",
  "provision.tenantspace.config.voicecall.more.agent.ivr.whithAms": "El n\u00FAmero m\u00E1ximo de llamadas de voz simult\u00E1neas no puede ser menor que la suma de agentes de voz\, v\u00EDdeo\, multimedia y vers\u00E1tiles\, y canales de IVR de audio y v\u00EDdeo.",
  "provision.appversionmanagement.versionNumber": "N\u00FAmero de versi\u00F3n",
  "provision.tenantspace.feature.knowledgeBaseSupport.description": "Despu\u00E9s de que se ha habilitado la caracter\u00EDstica de la base de conocimientos\, el espacio del inquilino soporta la funci\u00F3n de la base de conocimientos.",
  "provision.callcenterinstanceslist.label.maxcallnums": "M\u00E1x. llamadas de voz simult\u00E1neas",
  "provision.callcenterinstanceslist.vcallcenterDeatil.odfsStrValidateFailed": "Solo se permiten letras y d\u00EDgitos.",
  "provision.tenantspace.feature.cobrowse.cobrowseMaxNumber.placeholder": "1\u201350000",
  "provision.obs.success.sync": "Sincronizaci\u00F3n exitosa.",
  "provision.tenant.management.adminaccount": "Cuenta de administrador",
  "provision.tenantspace.feature.moveAgentNum": "N\u00FAmero m\u00E1ximo de agentes",
  "provision.tenant.need.first": "Seleccione el centro de llamadas\, el c\u00F3digo de acceso y el n\u00FAmero de agentes.",
  "provision.tenantspace.save.fail.078add": "Error al crear el espacio del inquilino al configurar el sistema de autenticaci\u00F3n de terceros.",
  "ccprovision.recorddrive.config.sftp.serverconfig": "Configuraci\u00F3n del servidor SFTP",
  "provision.tenantspace.config.tenant.webrtc.featurevalue.not.one": "El valor de la funci\u00F3n WebRTC asignada al inquilino no es 1.",
  "chat.usefulexpressions.message.selectExpressions": "Seleccione una frase com\u00FAn.",
  "ccprovision.vcallecenter.config.popup.tab.configurationinterface.modifypassword": "Modificar contrase\u00F1a de configuraci\u00F3n",
  "chat.weChatConfig.message.skillExisted": "La cola de habilidades seleccionada se ha vinculado a otra cuenta p\u00FAblica.",
  "chat.thirdportal.chatWindow.label.userName": "Nombre",
  "provision.tenantspace.feature.anonymous": "Llamada saliente an\u00F3nima",
  "provision.systemparam.error.validate.notmatchrulealpha": "Ingrese alfanum\u00E9ricos o guiones bajos y comience solo con letras o guiones bajos",
  "provision.tenantspace.cc.videoAgentUsage": "Uso del agente de v\u00EDdeo",
  "provision.tenantspace.feature.VoiceNotificationSupport": "Notificaci\u00F3n de voz",
  "provision.spinfo.id": "Identificaci\u00F3n",
  "provision.recorddrive.label.isfirstletter.yes": "S\u00ED",
  "provision.recorddrive.error.firstletter.repeated": "La primera letra de unidad ya existe",
  "provision.recorddrive.label.isfirstletter.no": "No",
  "chat.agentConsole.message.content.audio": "[Mensaje de voz]",
  "provision.tenantspace.config.success": "Espacio del inquilino configurado correctamente",
  "ccprovision.IVRSprogress.config.button.async": "Sincronizaci\u00F3n",
  "provision.callcenterinstanceslist.config.ccNameUpdateError": "No cambie el nombre del centro de llamadas al editar el centro de llamadas",
  "provision.callcenterinstanceslist.table.synchronize": "Sincronizaci\u00F3n",
  "provision.callcent.error.createaccesscode.fail.ondatabase": "\, error al crear un c\u00F3digo de acceso en la base de datos",
  "provision.callcent.error.updateSoftNumPasswordInfo": "Error de informaci\u00F3n de cuenta",
  "provision.callcenterinstance.accesscode.batchcreate.fail.repeated": "Todos los c\u00F3digos de acceso en el nuevo segmento de n\u00FAmero ya existen.",
  "chat.client.label.loginTitle": "Iniciar sesi\u00F3n",
  "chat.webconfig.label.greeting": "Saludos",
  "provision.callcent.error.deleteccgateway.part.associationvcallcener": "Algunos servidores de puerta de enlace CC seleccionados est\u00E1n asociados con inquilinos y no se pueden eliminar.",
  "provision.tenantspace.config.chooseDefaultPhoneNumber": "Seleccione el n\u00FAmero de llamada predeterminado",
  "ccprovision.recorddrive.config.isfirstletter": "Primera letra de unidad",
  "provision.tenantspace.timezone.config.error": "Error al modificar la informaci\u00F3n de zona horaria.",
  "provision.callcenterinstanceslist.label.videoAgentUseRate": "Uso del agente de v\u00EDdeo",
  "provision.tenantspace.config.ttschannelnums": "TTS Cantidad",
  "chat.skill.label.maxQueueTimeInTable": "Tiempo m\u00E1ximo de cola",
  "provision.tenantspace.save.fail.104-010": "El par\u00E1metro ID de nodo de grabaci\u00F3n no est\u00E1 configurado.",
  "provision.tenantspace.save.fail.104-011": "Error al eliminar el archivo de grabaci\u00F3n.",
  "provision.callcenterinstanceslist.table.viewing": "ver",
  "provision.tenantspace.selectStastus.noncommercial": "Uso comercial de prueba",
  "provision.softphone.label.authtype.uap": "Autenticaci\u00F3n UAP",
  "provision.callcenterccaccesscodeslist.table.singledeleteconfim": "\u00BFEst\u00E1 seguro de que desea eliminar el c\u00F3digo de acceso seleccionado? Aseg\u00FArese de que el c\u00F3digo de acceso no est\u00E1 asociado con un centro de llamadas virtual.",
  "provision.callcent.error.updatesoftphonenumber.noexist": "El n\u00FAmero de tel\u00E9fono blando no existe.",
  "provision.systemparam.error.validate.notmatchrulemin": "El par\u00E1metro de entrada es menor que el m\u00EDnimo",
  "chat.client.label.chatTitle": "Clientes",
  "provision.tenantspace.config.videocall.more.videoagent": "El n\u00FAmero m\u00E1ximo de llamadas de v\u00EDdeo simult\u00E1neas es mayor o igual que el n\u00FAmero de agentes de v\u00EDdeo.",
  "provision.tenantspace.save.fail.104-009": "Error en la conversi\u00F3n de grabaci\u00F3n.",
  "provision.callcenterinstanceslist.vcallcenter.OBSServer": "Servidor de llamadas salientes OBS",
  "provision.tenantspace.save.fail.104-008": "Los par\u00E1metros del sistema de formato de grabaci\u00F3n no est\u00E1n configurados.",
  "provision.tenantspace.sip.sipServerIP": "IP del servidor SIP",
  "provision.callcent.error.saveccgateway.nameinfo": "Error de creaci\u00F3n\, confirme si el nombre de la pasarela CC ya existe.",
  "provision.tenantspace.save.fail.104-007": "No se admite el formato de archivo de grabaci\u00F3n.",
  "provision.tenantspace.save.fail.104-006": "Espacio libre insuficiente para el fallo de borrado de grabaci\u00F3n.",
  "provision.tenantspace.save.fail.104-005": "El archivo de grabaci\u00F3n que no pudo eliminar la grabaci\u00F3n no existe.",
  "provision.tenantspace.save.fail.104-003": "El nombre de archivo de la eliminaci\u00F3n de grabaci\u00F3n fallida no es v\u00E1lido.",
  "provision.tenantspace.save.fail.104-004": "Error al eliminar la grabaci\u00F3n sin asignar la letra de unidad.",
  "provision.callcent.error.delete": "Error de eliminaci\u00F3n",
  "chat.thirdportal.chatWindow.label.userEmail": "Correo Electr\u00F3nico",
  "provision.tenantspace.save.fail.104-002": "El usuario no tiene permiso de grabaci\u00F3n de inspecci\u00F3n de calidad / reproducci\u00F3n de inspecci\u00F3n de calidad.",
  "provision.authconfig.description.toolong.error": "descripci\u00F3n ya no puede tan 200.",
  "provision.tenantspace.save.fail.104-001": "La funci\u00F3n de descarga de grabaci\u00F3n no est\u00E1 activada.",
  "provision.callcenterinstanceslist.softPhone.password": "Contrase\u00F1a",
  "provision.callcent.error.save": "Error",
  "provision.callcent.error.edit": "Error al editar.",
  "provision.callcenterinstanceslist.vcallcenter.vccName": "Nombre de VDN",
  "provision.callcenterinstanceslist.sipServer.sipServerIpAdd": "A\u00F1adir",
  "provision.tenant.management.tenantname.beginwithalpha": "debe comenzar con una letra.",
  "provision.tenantspace.save.fail.120221": "El grupo de par\u00E1metros al que hace referencia el IVR no existe.",
  "provision.tenantspace.save.fail.120222": "El nodo CTI al que hace referencia el IVR no existe.",
  "provision.tenantspace.save.fail.120220": "El grupo VDN hace referencia a IVR y no se puede eliminar.",
  "provision.callcenterinstance.softPhone.batchcreate.fail.repeated": "Todos los n\u00FAmeros en el nuevo segmento de n\u00FAmeros ya existen.",
  "provision.callcenterinstanceslist.softPhone.batchdeleteconfim": "\u00BFEst\u00E1 seguro de que desea eliminar todos los n\u00FAmeros de softphone seleccionados? Aseg\u00FArese de que el n\u00FAmero de softphone no est\u00E1 asociado con ning\u00FAn centro de llamadas virtual.",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.availableWorkNo": "No hay ID de agente disponible en el segmento de ID de agente.",
  "provision.tenantspace.cc.maxVideoCallnums": "M\u00E1x. videollamadas simult\u00E1neas",
  "provision.tenantspace.cc.searchName": "Introduzca el nombre del centro de llamadas que se va a consultar.",
  "provision.tenantspace.save.fail.120219": "VDN hace referencia a IVR y no se puede eliminar.",
  "provision.callcent.error.savesipserverdesinfo.regAgentNum": ". El n\u00FAmero de agentes registrados simult\u00E1neos es diferente del n\u00FAmero de asientos registrados restantes.",
  "provision.tenantspace.save.fail.120217": "El n\u00FAmero m\u00E1ximo de IVR para la misma direcci\u00F3n IP es 20.",
  "chat.weChatConfig.title.deleteTitle": "Eliminar elementos de configuraci\u00F3n de WeChat",
  "chat.skill.message.willDeleteSkill": "\u00BFEst\u00E1 seguro de que desea eliminar la habilidad?",
  "provision.tenant.management.adminname": "Administrador",
  "provision.tenantspace.save.fail.120216": "No se puede modificar entre IVR y CCIVR.",
  "provision.tenantspace.config.param.error.videoUsage": "Introduzca el n\u00FAmero de agentes de v\u00EDdeo.",
  "provision.callcent.error.regaddrformat": "El formato de la direcci\u00F3n de registro es incorrecto.",
  "chat.weChatConfig.label.codeName": "C\u00F3digo de acceso al canal",
  "chat.client.message.evaluationFail": "La encuesta de satisfacci\u00F3n falla.",
  "provision.tenantspace.videoagentchannnels.limit": "El n\u00FAmero de canales del agente de v\u00EDdeo debe ser mayor o igual al doble del n\u00FAmero de agentes de v\u00EDdeo.",
  "ccprovision.IVRSprogress.config.button.edit": "Editar",
  "provision.callcent.error.accesscode.deletefail.all.associationvcallcener": "El c\u00F3digo de acceso est\u00E1 asociado con el inquilino y no se puede eliminar.",
  "provision.callcenterinstanceslist.obsServer.obsServerIpUpdate": "Modificar",
  "provision.callcenterinstanceslist.vcallcenter.ServerNumber": "N\u00FAmero de servicio",
  "provision.tenantspace.config.ivrchannels": "Cantidad del canal IVR",
  "provision.callcenterinstanceslist.table.nodeid": "ID de nodo",
  "provision.tenantspace.common.agent.explain": "Agente com\u00FAn\: responde a las llamadas entrantes\, controla las llamadas de voz y video\, y env\u00EDa y recibe mensajes multimedia.",
  "pprovision.tenantspace.feature.emailAgentCCAndBcc.description": "Habilite la funci\u00F3n de copia de agente de correo electr\u00F3nico y CCO. El espacio alquilado admite copia de agente de correo electr\u00F3nico y Bcc.",
  "provision.tenantspace.config.chooseAnonymousCallServerTitle": "Seleccione servidor de llamadas an\u00F3nimas.",
  "provision.tenantspace.dataUpload.openResourceDump": "Proporciona la funci\u00F3n de volcado de recursos para el espacio del inquilino. Nota: el servicio de almacenamiento de destino de volcado de recursos actualmente solo admite el servicio de almacenamiento de objetos huawei cloud OBS. Para usar la caracter\u00EDstica de volcado de recursos\, los inquilinos deben comprar este servicio.",
  "chat.client.label.order": "Mis Pedidos",
  "provision.tenantspace.selectStastus.stop": "Suspendido",
  "provision.tenantspace.save.fail.120205": "El grupo VDN hace referencia al c\u00F3digo de acceso al sistema y no se puede eliminar.",
  "chat.weChatConfig.message.ensureDelete": "\u00BFEst\u00E1 seguro de que desea eliminar el elemento de configuraci\u00F3n seleccionado?",
  "provision.tenantspace.table.success": "\u00C9xito",
  "provision.tenantspace.save.fail.120204": "VDN hace referencia al c\u00F3digo de acceso al sistema y no se puede eliminar.",
  "provision.tenantspace.save.fail.120202": "El c\u00F3digo de acceso al sistema se superpone con el c\u00F3digo de acceso de encaminamiento especial.",
  "provision.callcenterinstanceslist.vcallcenter.UCSServer": "Servidor Multimedia CCUCS",
  "provision.recorddrive.selectrecorddrive.batchdeleteconfim": "\u00BFEst\u00E1 seguro de eliminar todas las letras de unidad seleccionadas? Por favor\, aseg\u00FArese de que la letra de unidad no est\u00E9 ocupada por el inquilino",
  "provision.tenantspace.save.fail.120203": "El c\u00F3digo de acceso al sistema y el c\u00F3digo de acceso de llamada a la red NIRC se duplican.",
  "provision.tenantspace.sip.maxRegAgentNum": "Cantidad de Agente Registrado Simult\u00E1neo",
  "provision.systemparam.error.validate.notmatchrulerange": "El par\u00E1metro de entrada excede el valor m\u00E1ximo o est\u00E1 por debajo del valor m\u00EDnimo",
  "provision.tenantspace.save.fail.120201": "El c\u00F3digo de acceso al sistema est\u00E1 duplicado.",
  "provision.tenantspace.table.stopTenantSpace": "\u00BFEst\u00E1s seguro de que quieres suspender al inquilino?",
  "provision.callcenterinstanceslist.softPhone.softPhoneNum.title": "Configuraci\u00F3n de Softphone",
  "provision.softphone.label.authtype.unified": "Autenticaci\u00F3n unificada",
  "provision.tenant.management.DSTEndDate": "Fecha de finalizaci\u00F3n del horario de verano",
  "provision.sysparam.config.mod": "Editar",
  "provision.callcenterinstanceslist.label.videoAgentCurCapacity": "Cantidad de agente de video",
  "provision.spinfo.logoutUrl": "URL de cierre de sesi\u00F3n",
  "chat.webconfig.label.intergratedcode": "C\u00F3digo integrado",
  "provision.callcent.error.updateinfo.maxchnsmall": "El n\u00FAmero m\u00E1ximo de llamadas simult\u00E1neas no puede ser menor que el n\u00FAmero de canales ocupados.",
  "provision.tenantspace.save.fail.078delete": "Error al eliminar el espacio del inquilino al configurar el sistema de autenticaci\u00F3n de terceros.",
  "provision.tenantspace.feature.appCubeSupport": "AppCube",
  "provision.whiteListAddress.PopMenu": "Pantalla emergente",
  "provision.whiteListAddress.outboundcall.obs.fileserver": "Llamada saliente al servidor de archivos OBS",
  "provision.whiteListAddress.outboundcall.sftp.fileserver": "Servidor de archivos SFTP para llamadas salientes",
  "chat.session.channel.all": "Todo",
  "provision.whiteListAddress.ivr.voiceupload.callback": "Devoluci\u00F3n de llamada de carga de voz IVR",
  "provision.tenantspace.feature.webrtc": "Despu\u00E9s de activar la funci\u00F3n WebRTC\, el espacio del inquilino admite la funci\u00F3n WebRTC.",
  "chat.skill.label.reset": "Restablecer",
  "provision.ivr.error.sync": "Error de sincronizaci\u00F3n",
  "chat.usefulexpressions.message.contentNotNull": "La frase com\u00FAn no puede estar vac\u00EDa.",
  "provision.tenantspace.common.case.explain": "Cuando se abre el caso\, el inquilino abrir\u00E1 la capacidad de servicio en la nube.",
  "provision.tenantspace.table.fail": "Fallo",
  "provision.encryptinfo.encryptKey.length.error": "Longitud de llave requerida\:",
  "provision.tenantspace.common.newcase.explain": "La funci\u00F3n Case 2.0 est\u00E1 habilitada para administrar y configurar \u00F3rdenes de trabajo para los inquilinos.",
  "provision.tenantspace.rechoose": "Aleatorizar",
  "chat.timerange.message.timeerror": "El formato de la hora es incorrecto.",
  "provision.tenantspace.ivr.searchName": "Ingrese el ID del IVR que desea consultar.",
  "provision.tenantspace.save.fail.120540": "El valor de la licencia multimedia est\u00E1 fuera de alcance.",
  "provision.tenantspace.ConversionNoncommercial": "Prueba de conversi\u00F3n Uso comercial",
  "provision.tenantspace.save.fail.120541": "Capacidad de procesamiento simult\u00E1neo del agente\: 1-60.",
  "chat.session.label.allChannel": "Todos los tipos de canales",
  "provision.tenantspace.feature.forceVideoSelfRecord.percentageFactor": "Coeficiente de porcentaje de grabaci\u00F3n de pantalla (%)",
  "provision.tenantspace.cc.ttschannelUsagePercent": "Uso de TTS",
  "provision.tenantspace.config.assignedworkno": "ID de trabajo asignado",
  "provision.callcent.error.accesscode.deletefail.all.onccgateway.with.associationvcallcener": "Error en toda la eliminaci\u00F3n porque parte del c\u00F3digo de acceso est\u00E1 asociado con el inquilino\, y algunos c\u00F3digos de acceso se eliminan en la plataforma fue.",
  "provision.tenantspace.table.maxcallnumswithdesc": "M\u00E1x llamadas de voz simult\u00E1neas (>= suma de agentes y canales IVR)",
  "provision.tenantspace.feature.appcubeIkbs.close.error": "Deshabilite la funci\u00F3n de la base de conocimientos antes de deshabilitar la funci\u00F3n AppCube.",
  "provision.tenant.management.tenantname": "Espacio para inquilinos",
  "provision.ccp.redis.feature.failed": "Error al ejecutar los datos de elemento de inquilino.",
  "provision.tenantspace.cc.current": "Capacidad actual",
  "provision.spinfo.serviceUrl": "URL de servicio",
  "provision.tenantspace.save.fail.120539": "Tipo de licencia multimedia no v\u00E1lido.",
  "provision.tenantspace.save.fail.120538": "El n\u00FAmero de ID de agente transferibles es insuficiente.",
  "provision.tenantspace.option.weeklist_fthtolast": "\u00DAltimos pero tres",
  "provision.tenantspace.save.fail.120536": "La longitud del VDN disponible no puede ser 0.",
  "provision.tenantspace.save.fail.120535": "El rango de ID del agente est\u00E1 en uso y no se puede cancelar.",
  "provision.tenantspace.save.fail.120534": "El grupo de recursos CCS al que hace referencia el VDN no existe.",
  "provision.tenantspace.save.fail.120533": "Duplicar el intervalo de ID de agente de VDN.",
  "provision.callcenterinstanceslist.vcallcenterDeatil.save": "Guardar",
  "provision.tenantspace.save.fail.120532": "El c\u00F3digo de acceso original ha sido referenciado por un n\u00FAmero llamado.",
  "provision.tenantspace.config.worknoassign.filternumber": "Excluir n\u00FAmeros",
  "provision.tenantspace.save.fail.120537": "Los VDN disponibles no pueden contener el VDN transferido.",
  "provision.tenantspace.save.fail.120531": "El c\u00F3digo de acceso contenido en la informaci\u00F3n b\u00E1sica de VDN no se asigna al VDN.",
  "provision.callcenterinstanceslist.vcallcenterDeatil.defaultCallerNum": "N\u00FAmero de llamada predeterminado",
  "provision.callcenterinstanceslist.ccgatewayserver.ccGatewayIp": "IP de puerta de enlace CC",
  "provision.tenantspace.save.fail.120530": "La unidad a la que hace referencia el VDN no existe.",
  "provision.tenant.management.contacts": "Nombre de contacto",
  "provision.callcenterinstanceslist.sipServer.sipanonymousCall": "Llamada an\u00F3nima SIP",
  "provision.tenantspace.config.fail.savedata.tenant.feature.inDatabase": "La base de datos no pudo guardar la informaci\u00F3n de caracter\u00EDsticas del inquilino",
  "chat.agent.label.offline": "Desconectado",
  "provision.tenant.config.maxAgents": "M\u00E1x. Agentes de voz",
  "provision.tenantspace.config.voicecall.more.agent.ivr.whithoutAms": "El n\u00FAmero m\u00E1ximo de llamadas de voz simult\u00E1neas no puede ser menor que la suma de agentes de voz\, v\u00EDdeo y vers\u00E1tiles\, y canales de IVR de audio y v\u00EDdeo.",
  "provision.whiteListAddress.cardPop": "Direcci\u00F3n de redirecci\u00F3n de mensaje de tarjeta de p\u00E1gina",
  "provision.sysparam.config.select.count": "Se puede seleccionar un m\u00E1ximo de 10 par\u00E1metros.",
  "provision.tenantspace.save.fail.120529": "El ID de administrador de VDN debe estar dentro del intervalo de ID de agente configurado.",
  "provision.tenantspace.save.fail.120528": "Cuando la transferencia de cuelgue del agente est\u00E1 habilitada\, el tono de solicitud se reproduce despu\u00E9s de que la llamada se transfiera de nuevo al agente. Se debe establecer el par\u00E1metro tono de solicitud.",
  "provision.tenantspace.save.fail.120527": "El cliente CCP local hace referencia al VDN y no se puede eliminar.",
  "provision.tenantspace.save.fail.120526": "El origen de datos de IVR predeterminado no est\u00E1 contenido en los or\u00EDgenes de datos de VDN ni en los or\u00EDgenes de datos de terceros asignados al VDN.",
  "provision.tenantspace.save.fail.120525": "Las fuentes de datos de IVR no est\u00E1n contenidas en las fuentes de datos de VDN ni en las fuentes de datos de terceros que se asignan a la VDN.",
  "provision.systemparam.error.validate.notmatchruleemail": "Por favor\, introduzca una direcci\u00F3n de correo electr\u00F3nico v\u00E1lida",
  "provision.tenantspace.save.fail.120524": "El c\u00F3digo de acceso al sistema asignado al VDN se ha asignado a un grupo VDN.",
  "provision.tenantspace.save.fail.120523": "El c\u00F3digo de acceso al sistema asignado al VDN se ha asignado a otro VDN.",
  "provision.tenantspace.save.fail.120522": "El VDN no soporta el tipo de llamada web\, pero los c\u00F3digos de acceso del sistema del tipo de llamada web se asignan al VDN.",
  "provision.tenantspace.save.fail.120520": "El c\u00F3digo de acceso al sistema al que hace referencia el VDN no existe.",
  "provision.tenantspace.save.fail.120521": "El VDN no admite los tipos de llamada de correo electr\u00F3nico y fax\, pero los c\u00F3digos de acceso del sistema del tipo de llamada de correo electr\u00F3nico o fax se asignan al VDN.",
  "chat.weChatConfig.stepname.verify": "Enviar para verificaci\u00F3n",
  "provision.tenantspace.detail.create.time": "Tiempo de creaci\u00F3n",
  "provision.callcenterinstanceslist.sipServer.description": "Descripcion",
  "provision.systemparam.error.validate.notmatchruleminlength": "El par\u00E1metro de entrada es menor que la longitud m\u00EDnima",
  "provision.sysparam.config.search": "Buscar",
  "provision.tenantspace.statusRecordTime.6Month": "6 meses",
  "provision.callcenter.gateway.agentnum.alert": "El valor debe ser el mismo que la capacidad de configuraci\u00F3n real del cl\u00FAster CC-Gateway.",
  "provision.tenantspace.agentinfo.message.singledeleteconfim": "\u00BFEst\u00E1 seguro de que desea eliminar el agente seleccionado?",
  "provision.tenantspace.ivr.ivrprogid": "ID de proceso",
  "provision.tenantspace.config.ivrserver.select.confirm": "confirmar selecci\u00F3n",
  "provision.systemparam.error.validate.notmatchrulemax": "El par\u00E1metro de entrada excede el m\u00E1ximo",
  "provision.tenantspace.save.fail.120519": "El nodo distribuido utilizado para la grabaci\u00F3n de voz autom\u00E1tica de VDN forzada no est\u00E1 configurado para el VDN.",
  "provision.callcent.error.saveccaccesscodeinfo": ". Aseg\u00FArese de que el c\u00F3digo de acceso no existe.",
  "provision.tenantspace.whiteNumber": "Configuraci\u00F3n de n\u00FAmeros en la lista de confianza",
  "provision.tenantspace.save.fail.120517": "Si no se especifica ning\u00FAn sistema de grabaci\u00F3n de voz en la configuraci\u00F3n b\u00E1sica de informaci\u00F3n RMS\, solo se puede configurar la grabaci\u00F3n de voz ACD de HUAWEI para el VDN actual.",
  "provision.tenant.management.accesscode": "C\u00F3digo de acceso",
  "provision.tenantspace.save.fail.120516": "El IVR al que hace referencia el VDN no existe.",
  "provision.tenantspace.save.fail.120515": "El origen de datos al que hace referencia el VDN no existe.",
  "provision.tenantspace.save.fail.120514": "El recurso distribuido al que hace referencia el VDN no existe.",
  "provision.tenantspace.feature.forceVideoSelfRecord": "Grabaci\u00F3n de pantalla",
  "provision.callcent.error.ipValidateFailed": "La direcci\u00F3n IP est\u00E1 validada.",
  "provision.tenantspace.save.fail.120512": "Duplicar el intervalo de ID de agente de VDN.",
  "provision.tenantspace.save.fail.120513": "La OBS a la que hace referencia el VDN no existe.",
  "provision.tenantspace.save.fail.120511": "El nombre de VDN se duplica con el nombre del grupo de VDN.",
  "provision.tenantspace.save.fail.120510": "El nombre de VDN se duplica con otro nombre de VDN.",
  "chat.agent.label.chatBot": "Chatbot",
  "provision.callcenterinstanceslist.ucsServer.ucsServerPort": "Puerto de servidor CCUCS",
  "provision.tenantspace.config.agentnum.limit": "Se puede suscribir un m\u00E1ximo de {0} agentes a la vez.",
  "provision.tenantspace.basic.information": "Informaci\u00F3n b\u00E1sica del inquilino",
  "provision.tenantspace.config.chooseGatewayServer": "seleccione un servidor de puerta de enlace",
  "provision.tenant.management.tenantcreate.failed": "Error al crear el espacio del inquilino. Aseg\u00FArese de que el nombre del inquilino o la cuenta de administrador sea \u00FAnica.",
  "provision.callcenterinstanceslist.softPhone.used.pleaseselect": "Seleccione el uso",
  "provision.callcenterinstanceslist.vcallcenter.maxAmsCalls": "Llamadas an\u00F3nimas simult\u00E1neas",
  "provision.tenantspace.save.fail.120509": "La restricci\u00F3n est\u00E1 habilitada. De forma predeterminada\, el n\u00FAmero que llama comienza con el prefijo.",
  "provision.tenantspace.save.fail.120508": "La restricci\u00F3n est\u00E1 habilitada. El prefijo del n\u00FAmero de llamada no puede estar vac\u00EDo.",
  "provision.tenantspace.save.fail.120507": "El n\u00FAmero de llamada no puede contener ninguna relaci\u00F3n de inclusi\u00F3n.",
  "chat.weChatConfig.title": "Habilitaci\u00F3n de la configuraci\u00F3n de redes sociales",
  "provision.tenantspace.save.fail.120506": "El n\u00FAmero m\u00E1ximo de agentes de v\u00EDdeo que pueden iniciar sesi\u00F3n simult\u00E1neamente en el sistema supera el l\u00EDmite superior.",
  "chat.weChatConfig.stepname.success": "\u00C9xito de acceso",
  "provision.tenantspace.save.fail.120505": "El n\u00FAmero de partes de la conferencia excede el l\u00EDmite superior.",
  "provision.tenantspace.save.fail.120504": "El n\u00FAmero m\u00E1ximo de llamadas salientes simult\u00E1neas excede el l\u00EDmite superior.",
  "provision.spinfo.creator": "El creador",
  "provision.tenantspace.save.fail.120503": "El n\u00FAmero m\u00E1ximo de llamadas web simult\u00E1neas excede el l\u00EDmite superior.",
  "provision.tenantspace.save.fail.120502": "El n\u00FAmero m\u00E1ximo de llamadas de voz simult\u00E1neas excede el l\u00EDmite superior.",
  "provision.tenantspace.save.fail.120501": "El n\u00FAmero m\u00E1ximo de agentes que pueden iniciar sesi\u00F3n simult\u00E1neamente en el sistema excede el l\u00EDmite superior.",
  "provision.tenantspace.agentinfo.message.media": "Multimedia",
  "provision.spinfo.issuerId.search": "Ingrese un ID de emisor.",
  "provision.callcenterinstanceslist.config.odfsvalidate": "Todas las configuraciones de servicio ODFS deben estar vac\u00EDas o no vac\u00EDas.",
  "provision.tenantspace.save.successwithsamevccid": "El ahorro se ha realizado correctamente. Un espacio de tenant cuyo ID de VCC es el mismo que el espacio de tenant actual existe. P\u00F3ngase en contacto con el administrador.",
  "provision.tenantspace.config.configVirtualCallCenter": "Configurar el centro de llamadas virtual",
  "provision.callcent.error.updatesoftphonenumber.ondatabase": "Error al modificar el n\u00FAmero de softphone en la base de datos.",
  "provision.callcenterinstanceslist.vcallcenter.tenantSpaceName": "TenantSpaceName",
  "provision.tenantspace.config.call.more.agent.ivr": "El n\u00FAmero m\u00E1ximo de llamadas de voz simult\u00E1neas debe ser mayor o igual a la suma del n\u00FAmero de agentes de voz y el n\u00FAmero de canales IVR de audio y v\u00EDdeo.",
  "chat.timerange.label.sevendays": "\u00DAltimos 7 d\u00EDas",
  "chat.thirdportal.chatWindow.title": "Servicio en l\u00EDnea al cliente",
  "ccprovision.IVRSprogress.config.number": "No",
  "provision.accesscode.table.call": "Llamadas entrantes y salientes",
  "provision.authconfig.duplicate.description.error": "duplicate.description.error.",
  "ccprovision.recorddrive.config.storageLimit": "L\u00EDmite de almacenamiento m\u00E1s bajo (MB)",
  "provision.callcent.error.accesscode.deletefail.accesscodenoexist": "Error al eliminar\, el c\u00F3digo de acceso no existe",
  "provision.tenantspace.config.amsNumber.unequal": "El n\u00FAmero de llamadas an\u00F3nimas simult\u00E1neas debe ser el consistente.",
  "provision.accesscode.table.whetherSupportTrialUse_N": "No",
  "provision.accesscode.table.whetherSupportTrialUse_Y": "S\u00ED",
  "provision.callcenterinstanceslist.whitelist.Title": "Direcci\u00F3n de devoluci\u00F3n de llamada de identificaci\u00F3n inteligente",
  "provision.tenantspace.config.chooseDrive": "Unidad de grabaci\u00F3n de voz",
  "promotion.tenant.management.tenantedit.registerserver.notavailable": "El servidor de registro no est\u00E1 disponible.",
  "provision.tenantspace.cc.currentpercent": "Proporci\u00F3n de capacidad actual",
  "provision.tenantspace.config.chooseworkNo.tip": "Por favor\, introduzca el segmento de trabajo auspicioso seg\u00FAn el trabajo inactivoSin informaci\u00F3n",
  "provision.callcenterinstanceslist.config.confirm": "Configure la informaci\u00F3n sobre los nodos relacionados con la plataforma WAS.",
  "provision.tenantspace.chooseauthenticationsystem": "Configurar el sistema de autenticaci\u00F3n de terceros",
  "provision.tenantspace.vitualCenterName": "Centro de llamadas virtual",
  "provision.tenantspace.create.tenantName": "Nombre del inquilino",
  "provision.tenantspace.config.chooseOBSServer": "Seleccione un servidor OBS.",
  "chat.weChatConfig.stepname.selectchannel": "Seleccionar canal de acceso",
  "provision.accesscode.message.invalid": "Caracteres no v\u00E1lidos.",
  "provision.tenantspace.save.fail.101": "Error de sincronizaci\u00F3n.",
  "provision.tenantspace.feature.cobrowse": "Navegaci\u00F3n conjunta",
  "provision.callcenterinstanceslist.config.nextStep": "Siguiente",
  "provision.encryptinfo.paddingScheme": "Modo de relleno",
  "provision.ivr.success.sync": "Sincronizaci\u00F3n exitosa",
  "chat.thirdportal.chatWindow.label.userPhone": "N\u00FAmero de tel\u00E9fono",
  "provision.callcenterinstanceslist.table.uapId": "ID de UAP",
  "provision.callcenterinstanceslist.whiteAddr.regAddrExample": "ejemplo\:https\://ip\:port/service-cloud/rest/cc-ai/v1/asrevent/asrresult",
  "chat.weChatConfig.label.nextStep": "Siguiente",
  "provision.callcenterinstanceslist.config.uapId": "Este valor debe ser el mismo que el UAP ID en el WAS.",
  "ccprovision.vcallecenter.error.updateinfo.currentacountpasderror": "La contrase\u00F1a de la cuenta actual es incorrecta.",
  "provision.tenant.management.batchcreatenumber": "Cantidad",
  "chat.timerange.message.timeempty": "El tiempo no puede estar vac\u00EDo.",
  "ccprovision.IVRSprogress.config.button.cacel": "Cancelar",
  "provision.tenantspace.config.agent.ivr.more.zero": "El n\u00FAmero de agentes de voz\, agente de v\u00EDdeo\, agente multimedia\, agente vers\u00E1til\, canales de audio IVR y canales de v\u00EDdeo IVR no puede ser 0.",
  "provision.callcenterinstanceslist.config.nmsservicePort": "N\u00FAmero de puerto ODFS",
  "provision.accesscode.table.accessCode": "C\u00F3digo de acceso",
  "provision.callcenterinstanceslist.table.nodemodepool": "Modo de piscina",
  "provision.tenantspace.moveAgentUsage.iszero": "N\u00FAmero insuficiente de canales de audio IVR.",
  "provision.callcenterinstanceslist.config.previousStep": "Anterior",
  "provision.tenantspace.table.activeTenantSpace": "\u00BFEst\u00E1 seguro de que desea activar al inquilino?",
  "provision.tenantspace.config.softphoneassign.softphonescope.extensiverange": "El rango de valores desde el n\u00FAmero inicial hasta el n\u00FAmero final no puede ser mayor que 1000.",
  "provision.tenantspace.feature.cobrowse.cobrowseMaxNumber": "N\u00FAmero m\u00E1ximo de conexiones de navegaci\u00F3n conjunta",
  "provision.callcent.success.deletesoftphonenumber.processed": "Borrando datos del fondo... Por favor\, compruebe m\u00E1s tarde.",
  "provision.tenantspace.gw.maxRegAgentNum": "Cantidad de Agente Registrado Simult\u00E1neo",
  "provision.callcent.error.accesscode.deletefail.part.associationvcallcener": "Algunos c\u00F3digos de acceso est\u00E1n asociados con los inquilinos y no se pueden eliminar.",
  "provision.whiteListAddress.TenantUnExist": "El inquilino no existe.",
  "provision.authconfig.updateMessage": "\u00BFEst\u00E1 seguro de que desea actualizar el SK de la aplicaci\u00F3n?",
  "ccprovision.recorddrive.config.sftp.filestoragepathStrValidateFailed": "La ruta de almacenamiento de archivos no puede contener './'",
  "provision.callcent.error.deletesoftphonenumber.part.associationvcallcener": "Algunos de los n\u00FAmeros de softphone seleccionados est\u00E1n asociados con el inquilino y no se pueden eliminar.",
  "provision.tenantspace.config.worknoassign.filternumber.explain2": "El banco de n\u00FAmeros despu\u00E9s del cribado no contendr\u00E1 los n\u00FAmeros de los n\u00FAmeros anteriores",
  "provision.tenantspace.timezone.save.success": "La informaci\u00F3n de zona horaria se guarda correctamente.",
  "provision.callcenterinstanceslist.config.createsuccess": "El centro de llamadas se crea correctamente.",
  "provision.tenantspace.config.worknoassign.filternumber.explain1": `Cuando ingrese varios n\u00FAmeros\, utilice "\," para separarlos. Ingrese hasta 5 n\u00FAmeros\, por ejemplo\, "4\,11\,13"`,
  "provision.tenantspace.config.chooseUCSServer": "Seleccione un servidor CCUCS.",
  "provision.callcenterinstanceslist.table.updateFault": "\u00BFEst\u00E1 seguro de configurar la voz de falla del centro de c\u00E9lula virtual",
  "provision.callcenterinstanceslist.whitepageurl.httptip": "(No es seguro)",
  "provision.tenantspace.tenantSpaceCount": "N\u00FAmero total de inquilinos",
  "provision.tenantspace.table.maxCalls": "M\u00E1x. llamadas de voz simult\u00E1neas",
  "promotion.tenant.management.tenantedit.vdnsync.failed": "Error al sincronizar los datos con el centro de llamadas virtual.",
  "provision.callcenterinstanceslist.config.ttschannelnumsValidate": "El n\u00FAmero de canales TTS es menor que el n\u00FAmero de canales realmente asignados en el centro de llamadas.",
  "chat.skill.message.warning": "Advertencia",
  "provision.callcenterinstanceslist.sipServer.uapPort": "Puerto UAP",
  "provision.tenantspace.prompt": "Informaci\u00F3n de contacto",
  "chat.session.label.created_startTime": "Hora de inicio",
  "provision.systemparam.error.notmatchrule": "El par\u00E1metro no cumple con la regla de comprobaci\u00F3n",
  "provision.callcenterccaccesscodeslist.table.batchdeleteconfim": "\u00BFEst\u00E1 seguro de que desea eliminar todos los c\u00F3digos de acceso seleccionados? Aseg\u00FArese de que el c\u00F3digo de acceso no est\u00E1 asociado con un centro de llamadas virtual.",
  "provision.callcenterinstanceslist.ccgatewayServer.singledeleteconfim": "\u00BFEst\u00E1 seguro de que desea eliminar el servidor CC-Gateway seleccionado?",
  "provision.tenantspace.config.softphone.noavailable": "No hay n\u00FAmero de softphone disponible.",
  "provision.tenantspace.deleteWhiteNumber": "\u00BFEst\u00E1 seguro de que desea eliminar el n\u00FAmero de la lista de confianza?",
  "provision.tenantspace.cc.max": "Capacidad m\u00E1xima",
  "provision.tenantspace.config.selectedsoftphone": "N\u00FAmeros seleccionados{0}{1}",
  "provision.tenantspace.select.start": "Iniciado",
  "chat.session.label.channel": "Canal de acceso de di\u00E1logo",
  "provision.callcenterinstance.softPhone.validateSoftPhoneNumrange": "El rango de nuevos n\u00FAmeros agregados no puede exceder de 1000",
  "provision.tenantspace.config.error.softphone.noagentadd": "No se a\u00F1ade ning\u00FAn agente de voz\, v\u00EDdeo\, multimedia\, vers\u00E1til o m\u00F3vil.",
  "provision.tenantspace.common.ivr": "IVR convencional",
  "provision.callcenterinstanceslist.table.videoAgentChannels": "Cantidad de canal de agente de v\u00EDdeo",
  "provision.tenantspace.popuppageurl.deleteconfim": "\u00BFEst\u00E1 seguro de que desea eliminar la lista de confianza de direcciones?",
  "ccprovision.recorddrive.config.sftp.port": "Puerto No.",
  "provision.callcenterinstanceslist.obsServer.obsId": "ID de OBS",
  "provision.tenantspace.table.videoAgentChannels": "Cantidad de canal de agente de v\u00EDdeo (>\= dos veces la cantidad de agente de v\u00EDdeo)",
  "provision.callcenterwhitelist.regAddr": "Direcci\u00F3n de devoluci\u00F3n de llamada",
  "chat.session.label.evaluationTime": "Tiempo de evaluaci\u00F3n de di\u00E1logo",
  "provision.callcent.label.openFaule": "OpenFault",
  "provision.tenantspace.smartAgentUsage.iszero": "Configurar el agente primero.",
  "chat.skill.label.skill": "Habilidad-",
  "provision.callcenterinstanceslist.table.nodename": "Nombre del nodo",
  "provision.tenant.management.partdbId": "ID de partici\u00F3n de base de datos",
  "chat.session.channel.facebook": "Facebook",
  "provision.callcent.label.finish": "Completo",
  "chat.skill.message.failDeleteSkill": "Error al eliminar la habilidad.",
  "provision.ccworknos.table.ccworkStartNo": "Iniciar ID de empleado",
  "provision.accesscode.table.mediatypeId": "Tipo de medios",
  "provision.tenantspace.config.softphone.using": "n\u00FAmero de softphone {0} est\u00E1 en uso.",
  "provision.callcenterinstanceslist.vcallcenter.ctiInfo": "Id. de grupo de recursos de CTI",
  "provision.tenantspace.feature.OneClickTwoCallSupport.description": "Esta funci\u00F3n proporciona funciones de llamada bidireccional con un solo clic para el espacio del inquilino.",
  "provision.whiteListAddress.CustomerMenu": "Personalizaci\u00F3n de men\u00FAs",
  "chat.weChatConfig.message.unknown": "Se desconoce el estado de verificaci\u00F3n.",
  "provision.tenant.management.tenantcreate.timezoneInfo": "Informaci\u00F3n de zona horaria",
  "chat.agent.label.waiting": "Espera",
  "provision.ccworknos.table.ccworkEndNo": "Finalizar ID de empleado",
  "provision.authconfig.authdescription": "Ingrese la descripci\u00F3n del ID de desarrollador.",
  "provision.encryptinfo.strengthdecrease": "Disminuir la fuerza disminuye la seguridad del algoritmo. Tenga cuidado al seleccionar esta opci\u00F3n.",
  "ccprovision.vcallecenter.config.popup.tab.administrator.modifypassword": "Modificar contrase\u00F1a de administrador",
  "chat.webconfig.label.basesettings": "Configuraci\u00F3n b\u00E1sica",
  "provision.tenantspace.cc.videoIvrchannels": "Cantidad de canal de video IVR",
  "provision.tenantspace.cc.videoCallnumUsagePercent": "Uso de videollamadas simult\u00E1neas",
  "provision.callcenterinstanceslist.softPhone.softPhoneNum": "N\u00FAmero de Softphone",
  "chat.weChatConfig.label.state": "Estado",
  "ccprovision.IVRSprogress.config.operate": "Operaci\u00F3n",
  "provision.callcenterinstanceslist.vcallcenter.faultEnable": "Voz de falla",
  "provision.callcenterinstanceslist.vcallcentersftp.passLengthValidateFailed": "La contrase\u00F1a debe contener entre 8 y 20 caracteres",
  "chat.client.evaluate.message": "Por favor\, comente mi servicio. \u00A1Gracias\!",
  "provision.encryptinfo.update": "Actualizacion",
  "provision.appversionmanagement.batchDelete": "Borrar lote",
  "provision.callcenterinstanceslist.softPhone.passLengthValidateFailed": "La contrase\u00F1a debe contener entre 8 y 16 caracteres.",
  "provision.system.error": "El sistema es anormal. Por favor\, int\u00E9ntelo de nuevo m\u00E1s tarde.",
  "provision.systemparam.success.save": "Par\u00E1metro guardado correctamente",
  "provision.tenantspace.passwordValidatefaild": "Error al verificar la contrase\u00F1a.",
  "provision.callcent.error.updateerrorinfo": "El c\u00F3digo de acceso se ha asociado con un centro de llamadas virtual y no se puede editar.",
  "provision.tenant.management.button.ok": "Completo",
  "provision.tenantspace.config.choose.agent.registration.server.type": "Tipo de servidor de registro de agente",
  "provision.tenantspace.config.agentusage.vidoeusage.ivr": "Cantidad de agente de voz\, Cantidad de agente de video\, Cantidad de agente multimedia\, Cantidad de canal de audio IVR y cantidad de canal de video IVR no pueden ser cero",
  "provision.tenantspace.config.error.chooseworkNo.noagent": "No hay recursos del agente seleccionados\, no hay necesidad de asignar el n\u00FAmero de trabajo",
  "provision.tenantspace.error.deleteProvision": "No se pudo eliminar el espacio de inquilino aprovisionar.",
  "provision.callcenterinstance.softPhone.validatebeginSoftPhoneNum": "El n\u00FAmero de inicio de Softphone no puede ser mayor que el n\u00FAmero final",
  "provision.callcenterinstanceslist.vcallcenter.open": "Abierto",
  "provision.accesscode.table.accessCode.selecttype": "Seleccione un tipo",
  "provision.callcent.error.deleteccgateway.associationvcallcener": "El servidor de puerta de enlace CC seleccionado est\u00E1 asociado con el inquilino y no se puede eliminar.",
  "provision.callcenterinstance.accesscode.validateaccesscode": "Introduzca un segmento de n\u00FAmero correcto.",
  "provision.tenantspace.error.deleteDDS": "Error al eliminar el espacio de inquilino DDS.",
  "provision.callcenterinstanceslist.config.nodeIdCheckError": "Aseg\u00FArese de que el ID de informaci\u00F3n de nodo CTI introducido\, uapId y nombre son \u00FAnicos.",
  "provision.callcenterinstanceslist.table.gwIp": "IP de puerta de enlace CC",
  "provision.tenantspace.config.softphoneassign.chooseworknoscope.paramserror": "La informaci\u00F3n del n\u00FAmero es incorrecta.",
  "provision.callcent.error.paramUnique": "Param \u00FAnico ha existido.",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.addmaxlimit": "Se han a\u00F1adido cinco grupos de n\u00FAmeros.",
  "provision.callcenterinstanceslist.table.agentMaxCapacity": "Capacidad del sistema del agente",
  "provision.tenantspace.option.weekday_tue": "Martes",
  "provision.appversionmanagement.downloadAddress": "Descargar Direcci\u00F3n",
  "provision.encryptinfo.metadata": "Cifrado y descifrado de metadatos",
  "provision.tenant.management.DSTStartDate": "Fecha de inicio del horario de verano",
  "provision.callcenterinstanceslist.table.resourceinfo": "Informaci\u00F3n de recursos",
  "provision.tenantspace.select.status": "Seleccione un estado.",
  "provision.callcenterinstanceslist.softPhone.authtype": "Modo de autenticaci\u00F3n",
  "chat.agentConsole.message.error.notification": "No se puede crear la nueva notificaci\u00F3n de mensaje. Por favor\, compruebe la configuraci\u00F3n del navegador\!",
  "provision.callcent.error.regaddrlength": "La direcci\u00F3n de registro es demasiado larga.",
  "provision.callcent.selectsoftnumber": "Seleccione un n\u00FAmero de softphone.",
  "provision.tenantspace.config.select.record": "Seleccione una unidad de grabaci\u00F3n.",
  "provision.tenantspace.agentinfo.deletefail.modifyvdn.onwas": "La conexi\u00F3n a la plataforma no pudo modificar el vdn",
  "provision.callcenterinstanceslist.table.asrchannelnums": "Cantidad ASR",
  "provision.callcent.error.partdeleteinfo": "El contenido asociado a un centro de llamadas virtual no se puede eliminar.",
  "provision.tenantspace.config.maxCalls": "Cantidad de llamadas de voz simult\u00E1neas",
  "chat.client.label.promotions": "Iniciar sesi\u00F3n",
  "provision.callcenterinstanceslist.vcallcenter.videoAgentChannels": "VideoAgentchannels",
  "provision.tenantspace.config.chooseDriveTime": "Periodo de retenci\u00F3n de grabaci\u00F3n",
  "provision.tenantspace.option.month_9": "septiembre",
  "chat.session.channel.web": "P\u00E1gina web",
  "provision.accesscode.table.acId": "N\u00FAmero de c\u00F3digo de acceso",
  "provision.tenantspace.option.month_8": "Agosto",
  "provision.tenantspace.option.month_7": "Noviembre",
  "provision.tenantspace.option.month_5": "Mayo",
  "provision.tenantspace.option.month_4": "Abril",
  "provision.tenantspace.option.month_6": "junio",
  "provision.tenantspace.option.month_2": "Febrero",
  "provision.tenantspace.config.worknoassign.showchoosedworkno.explain": "Nota\: Necesita un total de {0} n\u00FAmeros\, y se han seleccionado {1} n\u00FAmeros. El sistema le asignar\u00E1 n\u00FAmeros en secuencia.",
  "provision.tenantspace.option.month_3": "Marzo",
  "provision.callcenterinstanceslist.softPhone.usedselect.mobileagent": "N\u00FAmero de gerente de cliente",
  "provision.tenantspace.refresh": "Refrescar",
  "provision.tenantspace.option.month_1": "Enero",
  "provision.callcenterinstanceslist.softPhone.batchadd": "A\u00F1adir por lotes",
  "provision.callcenterinstanceslist.whiteNumber": "N\u00FAmeros en la lista de confianza",
  "provision.tenantspace.feature.intelligent.inspection": "inspecci\u00F3n inteligente",
  "provision.tenantspace.selectStastus.initialize": "Iniciando",
  "provision.tenantspace.feature.OneClickTwoCallSupport": "Llamada bidireccional con un solo clic",
  "provision.callcent.selectobsserver": "Seleccione un servidor Obs.",
  "provision.tenantspace.option.weeklist_sectolast": "\u00DAltimo pero uno",
  "provision.tenantspace.gw.name": "Nombre del servidor de puerta de enlace",
  "chat.skill.label.name": "Nombre de usuario",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.workNocontain": "Se ha seleccionado el ID de trabajo. Por favor\, ingrese otro.",
  "provision.callcent.label.cancel": "Cancelar",
  "provision.tenantspace.cc.ivrchannels": "Cantidad de canal de audio IVR",
  "provision.callcenter.sip.alert": "El valor debe ser el mismo que la capacidad real admitida por el UAP y el SBC.",
  "provision.tenantspace.passValidateFailed": `Debe contener letras may\u00FAsculas\, min\u00FAsculas\, d\u00EDgitos y caracteres especiales ~\!{'@'}\#$%&*()-_\\=+[{}];\:""\,<.>/?`,
  "provision.spinfo.beginlettervalidate": "Por favor\, comience con una carta.",
  "chat.session.channel.twitter": "Twitter",
  "provision.softphone.label.issyncuap.yes": "S\u00CD",
  "provision.recorddrive.selectrecorddrive.singledeleteconfim": "\u00BFEst\u00E1 seguro de eliminar la letra de unidad seleccionada? Por favor\, aseg\u00FArese de que la letra de unidad no est\u00E9 ocupada por el inquilino",
  "chat.agent.message.changeFailed": "Error al cambiar el estado del agente.",
  "provision.callcenterinstanceslist.softPhone.usedselect.fixedagent": "N\u00FAmero de agente",
  "chat.agentConsole.message.content.video": "[Mensaje de v\u00EDdeo]",
  "provision.tenantspace.config.select.default.phone.in.selectAccesscodes": "El n\u00FAmero de llamada predeterminado debe estar dentro del c\u00F3digo de acceso seleccionado.",
  "chat.client.label.input": "Por favor ingrese",
  "provision.tenantspace.agentusage.plus.videousage": "AgentUsage + VideoUsage debe ser inferior a 10000",
  "chat.agentConsole.message.content.connectMessage": "\u00A1Un nuevo mensaje\!",
  "provision.callcenterinstanceslist.table.eMediaAgentCurCapacity": "Cantidad de Agente Multimedia",
  "provision.systemparam.success.refresh": "Par\u00E1metro actualizado correctamente",
  "provision.authconfig.updateConfim": "actualizar confim",
  "provision.callcenterinstanceslist.table.search": "Consultar",
  "provision.callcent.error.deletesoftphonenumber.associationvcallcener": "El n\u00FAmero de tel\u00E9fono flexible seleccionado est\u00E1 asociado con el inquilino y no se puede eliminar.",
  "promotion.tenant.management.tenantedit.exception": "Se produce una excepci\u00F3n en el servidor.",
  "provision.callcenterinstanceslist.config.saveAndnextStep": "Guardar y Siguiente",
  "provision.appversionmanagement.versionName": "Nombre de la versi\u00F3n",
  "provision.callcent.error.errorinfo101": "Error de sincronizaci\u00F3n de ccm.",
  "provision.callcenterinstanceslist.resource.node.notexist": "La informaci\u00F3n del nodo no existe.",
  "provision.callcent.error.deleteobsserver.part.associationvcallcener": "Algunos de los servidores obs seleccionados est\u00E1n asociados con el inquilino y no se pueden eliminar.",
  "provision.callcenterinstanceslist.obsServer.progId": "ID de prog",
  "provision.callcenterinstanceslist.config.odfsappsercret": "AppSercret de ODFS",
  "provision.tenant.management.pool.ctinode.id": "ID de nodo",
  "provision.tenantspace.config.tenant.custdata.featurevalue.not.one": "El valor de la funci\u00F3n de personalizaci\u00F3n de datos asignada al inquilino no es 1.",
  "ccprovision.IVRSprogress.config.logincti.failed": "Error en la plataforma de conexi\u00F3n",
  "provision.tenant.management.tenantcreate.name.exist": "El nombre del inquilino ya existe",
  "provision.tenant.management.tenantcreate.adminaccount.exist": "La cuenta de administrador ya existe",
  "provision.tenantspace.ivr.ivrId": "ID de IVR",
  "provision.tenantspace.config.applySource": "Aplicar recurso",
  "provision.appversionmanagement.updateContent": "Actualizar contenido",
  "provision.tenantspace.create.applyAccount": "Registre un administrador para el inquilino.",
  "provision.tenantspace.config.isAutoAssignWorkNo": "Asignar autom\u00E1ticamente el trabajoNo para el centro de llamadas virtual",
  "provision.encryptinfo.updateInterval.error": "El intervalo para actualizar la clave no puede ser inferior a 30 d\u00EDas.",
  "provision.callcent.selectucsserver": "Seleccione el servidor CCUCS.",
  "provision.tenantspace.feature.forceVideoSelfRecord.percentage": "Registro por porcentaje",
  "provision.tenantspace.selectCC": "Seleccione un centro de llamadas.",
  "provision.callcent.error.setinfo": "Error de configuraci\u00F3n.",
  "chat.webconfig.label.intergratemsg": "Integre el chat en vivo en su sitio web",
  "provision.tenantspace.feature.outboundCallMarketingSupport": "Llamada saliente inteligente",
  "provision.whiteListAddress.mobileagenturl": "Servicio de atenci\u00F3n al cliente m\u00F3vil",
  "provision.ccp.redis.feature.inprogress": "Los datos de la caracter\u00EDstica del inquilino se est\u00E1n ejecutando.",
  "provision.callcenterinstanceslist.label.ivrchannelUseRate": "Uso del canal de audio IVR",
  "provision.tenantspace.config.chooseAgentRegistrationServer": "Seleccione un servidor de registro de agente",
  "chat.session.label.createdTime": "Hora de creaci\u00F3n de di\u00E1logo",
  "provision.tenantspace.agentinfo.message.deletefail.InProgress": "El inquilino est\u00E1 procesando el negocio\, por favor int\u00E9ntelo de nuevo m\u00E1s tarde\!",
  "provision.callcenterinstances.agentWasPassLengthValidateFailed": "La contrase\u00F1a debe contener entre 8 y 32 caracteres.",
  "chat.session.label.endReason": "Causa final del cuadro de di\u00E1logo",
  "provision.callcenterinstanceslist.table.maxVideoCallnums": "M\u00E1x. videollamadas simult\u00E1neas",
  "provision.callcenterinstanceslist.config.serviceIP": "Nginx IP",
  "provision.callcenterinstanceslist.config.createfailed": "Error al crear un centro de llamadas.",
  "ccprovision.recorddrive.config.sftp.ipadress": "Direcci\u00F3n IP SFTP",
  "provision.callcent.success.save": "\u00C9xito",
  "provision.callcent.platformserver.ccbms": "CCBMS",
  "ccprovision.vcallecenter.config.popup.enternewpassword": "Ingrese una nueva contrase\u00F1a",
  "provision.authconfig.update": "upadadote",
  "provision.callcenterinstanceslist.sipServer.uapIp": "UAP IP",
  "ccprovision.recorddrive.config.recorddrivenameconfig": "Configuraci\u00F3n de volumen",
  "provision.callcenterinstanceslist.config.workAlreadyOccupied": "El ID de empleado est\u00E1 en uso",
  "provision.tenantspace.config.param.error.agentCall": "Introduzca el n\u00FAmero de llamadas de voz simult\u00E1neas.",
  "provision.callcenterinstanceslist.table.ccId": "ID del centro de llamadas",
  "provision.callcenterinstanceslist.config.maxVideoCallnumsValidate": "El n\u00FAmero m\u00E1ximo de concurrencia de videollamadas es menor que el n\u00FAmero de videollamadas realmente asignadas por el centro de llamadas.",
  "provision.callcent.error.updateucsserver.ondatabase": "Error al modificar el servidor CCUCS en la base de datos.",
  "provision.authconfig.appkey": "ID de la aplicaci\u00F3n",
  "provision.tenantspace.config.error.chooseworkNo.noaddagent": "No se ha a\u00F1adido ning\u00FAn recurso del agente\, no es necesario asignar el n\u00FAmero de trabajo",
  "provision.tenantspace.statusRecordTime.24Month": "24 Meses",
  "provision.callcenter.resourceinfo.alert": "La siguiente informaci\u00F3n de recursos debe ser coherente con la informaci\u00F3n de licencia de dispositivos tales como el UAP y el CTI.",
  "provision.tenantspace.config.error.142": "El APPcube debe estar deshabilitado cuando la tarea de llamada de la aplicaci\u00F3n est\u00E1 habilitada",
  "provision.tenantspace.status.invalid.fail": "No se pudo revocar.",
  "provision.tenantspace.sip.searchName": "Introduzca el nombre del servidor SBC que desea consultar.",
  "provision.tenant.management.please.select": "Seleccione...",
  "provision.tenantspace.intelligent.agent": "Agente inteligente",
  "provision.callcenterinstanceslist.softPhone.singledeleteconfim": "\u00BFEst\u00E1 seguro de que desea eliminar los n\u00FAmeros de softphone seleccionados? Aseg\u00FArese de que el n\u00FAmero de softphone no est\u00E1 asociado con ning\u00FAn centro de llamadas virtual.",
  "provision.tenantspace.config.fail.modify.tenant.auth.use.sumInterface": "Error al llamar a la interfaz SUM para modificar los permisos de inquilino",
  "provision.callcenterinstanceslist.vcallcenterDeatil.whiteNumValidateLength": "La longitud del n\u00FAmero de lista de confianza var\u00EDa de 1 a 32.",
  "provision.callcent.error.accesscode.deletefail.part.onccgateway.with.associationvcallcener": "Error de eliminaci\u00F3n parcial porque parte del c\u00F3digo de acceso est\u00E1 asociado con el inquilino\, y parte del c\u00F3digo de acceso no se pudo eliminar en la plataforma fue.",
  "provision.appversionmanagement.iOS": "IOS",
  "chat.session.label.sessionId": "ID de di\u00E1logo",
  "promotion.tenant.management.tenantedit.softnumber.insufficient": "El n\u00FAmero de softphones es insuficiente.",
  "provision.callcent.label.submit": "Enviar",
  "provision.callcenterinstanceslist.sipServerUpdate.failed": "La direcci\u00F3n IP o el n\u00FAmero de puerto no cumplen con la regla.",
  "chat.skill.label.deleteSkills": "Borrar",
  "provision.tenant.management.adminpasswordagain": "Confirmar contrase\u00F1a",
  "provision.tenantspace.agentinfo.message.smartagent.iszero": "\, No hay agente de audio\, por favor desactive la funci\u00F3n de agente inteligente manualmente",
  "provision.callcenterinstanceslist.table.curCapacityProportion": "Proporci\u00F3n de capacidad real",
  "chat.session.label.search": "Buscar",
  "provision.systemparam.error.validate.ruleinvalid": "La regla de verificaci\u00F3n no es v\u00E1lida",
  "provision.grayconfig.paramIsError": "El par\u00E1metro de entrada est\u00E1 vac\u00EDo o es incorrecto",
  "provision.callcenterinstanceslist.config.adminWorkAlreadyOccupied": "El ID de empleado del administrador est\u00E1 en uso",
  "provision.tenantspace.language.zh": "Espa\u00F1ol",
  "provision.tenantspace.table.createStatus": "Nuevo Estado",
  "provision.tenantspace.config.tenant.intelligent.inspection.featurevalue.not.one": "El valor de la caracter\u00EDstica de inspecci\u00F3n inteligente asignada al inquilino no es 1.",
  "provision.callcent.error.repetenter": "Se ha utilizado el n\u00FAmero. Ingrese otro n\u00FAmero de nuevo.",
  "provision.softPhone.label.uapaddress": "Direcci\u00F3n UAP",
  "chat.session.label.allEndType": "Todos los tipos finales",
  "provision.tenantspace.agentinfo.agentstatus.online": "en l\u00EDnea",
  "provision.callcenterinstanceslist.sipServer.name": "Nombre",
  "provision.callcenterinstanceslist.softPhone.stautus.unoccupied": "Sin usar",
  "chat.usefulexpressions.message.saveFail": "Error al guardar la frase com\u00FAn.",
  "provision.tenantspace.config.worknoassign.chooseworkNo": "Seleccione el n\u00FAmero",
  "provision.tenantspace.chooseCommercial": "Selecci\u00F3n de Estado Comercial",
  "provision.callcenterinstanceslist.vcallcenter.videoIvrchannels": "Videocanales IVR",
  "chat.session.label.reset": "Restablecer",
  "provision.accesscode.table.callType": "Tipo de llamada",
  "provision.spinfo.deletespinfofailed": "Error al eliminar la informaci\u00F3n del SP.",
  "provision.callcenterinstanceslist.vcallcenter.agentNumErrorListPromt": "Hay un centro de llamadas virtual cuyo n\u00FAmero de asientos es inconsistente con el VDN en el WAS. El VccId es",
  "chat.usefulexpressions.message.failDeleteExpression": "Error al eliminar la frase com\u00FAn.",
  "provision.tenantspace.agentinfo.label.workNo": "N\u00FAmero de agente",
  "chat.agent.title.createCase": "Crear caso",
  "chat.client.label.login": "Iniciar sesi\u00F3n",
  "provision.tenantspace.config.need.wertcsipserver": "Para habilitar la funci\u00F3n WebRTC necesita seleccionar un servidor de registro de agente WebRTC.",
  "promotion.tenant.management.tenantedit.accesscode.noavailable": "El c\u00F3digo de acceso no est\u00E1 disponible.",
  "provision.callcenterinstanceslist.softPhone.stautus.select": "Seleccione el estado",
  "provision.callcenterinstanceslist.vcallcenter.maxCalls": "MaxCalls",
  "provision.appversionmanagement.cannotEnterNumberSmaller": "El valor debe ser mayor que el n\u00FAmero m\u00E1ximo de versi\u00F3n.",
  "provision.callcenterinstanceslist.config.resourceError": "La informaci\u00F3n de recursos modificada es menor que el n\u00FAmero real de centros de llamadas.",
  "provision.callcent.success.delete": "Supresi\u00F3n correcta",
  "provision.callcent.selectccgatewayservers": "Seleccione un servidor CC-Gateway.",
  "provision.callcenterinstanceslist.vcallcenterDeatil.sipServerIpAndPort": "Direcci\u00F3n SBC",
  "provision.spinfo.willdeletespinfo": "\u00BFEst\u00E1 seguro de que desea eliminar la informaci\u00F3n del SP seleccionado?",
  "provision.tenantspace.feature.emailAgentSendLocalAttachment.description": "Esta funci\u00F3n proporciona la funci\u00F3n de enviar archivos adjuntos locales por agentes de correo electr\u00F3nico para el espacio del inquilino.",
  "provision.callcenterinstanceslist.obsServer.obsName": "Nombre de OBS",
  "provision.callcenterinstanceslist.obsServer.heartbeatPort": "Puerto de latidos del coraz\u00F3n",
  "chat.skill.label.priority": "Prioridad",
  "provision.callcenter.ucs.ip.alert": "Direcci\u00F3n de equilibrio de carga de cl\u00FAster CCUCS",
  "provision.tenantspace.cc.maxcallnums": "M\u00E1x. llamadas de voz simult\u00E1neas",
  "provision.speciallist.tip.timeVerification": "Por favor\, seleccione una fecha mayor que hoy y menor que 2038-01-19",
  "chat.session.channel.wechat": "WeChat",
  "provision.sysparam.config.title": "Par\u00E1metros del sistema",
  "provision.tenantspace.feature.softphonenounifiedauth.description": "Habilite la funci\u00F3n de autenticaci\u00F3n unificada de n\u00FAmeros de softphone para que la sala de inquilinos admita la autenticaci\u00F3n unificada de n\u00FAmeros de softphone.",
  "provision.tenantspace.statusRecordTime.3Month": "3 meses",
  "provision.callcent.selectsipserver.inconsistenttype": "El tipo de servidor de registro de agente no es coherente",
  "provision.callcenterinstanceslist.softPhone.stautus.occupied": "En uso",
  "provision.callcenterinstanceslist.vcallcenter.ttschannelnums": "TTSchannelnums",
  "provision.tenantspace.create": "Crear",
  "ccprovision.recorddrive.config.sftp.ipadressport": "Puerto de servidor SFTP",
  "provision.tenantspace.table.config": "Configurar",
  "provision.callcent.error.deleteinfo": "Error en la eliminaci\u00F3n.",
  "chat.skill.label.ok": "OK",
  "chat.weChatConfig.label.qcode": "C\u00F3digo QR",
  "provision.sysparam.config.item.catalog": "Tipo de par\u00E1metro",
  "chat.weChatConfig.label.delete": "Borrar",
  "provision.ccworknos.table.vCCInstId": "Centro de llamadas virtual",
  "provision.tenantspace.gw.idleRegAgentNum": "Cantidad de Agente Registrado Simult\u00E1neo restante",
  "provision.tenant.management.tenantmodify.cobrowse.akempty": "La informaci\u00F3n de AK no puede estar vac\u00EDa porque ha habilitado la funci\u00F3n de co-navegaci\u00F3n para el inquilino.",
  "provision.callcenterinstanceslist.recorddrive.Title": "Grabaci\u00F3n de letra de unidad",
  "provision.callcenterinstanceslist.config.softUsage.more": "El n\u00FAmero de softphones es insuficiente. Verifique la configuraci\u00F3n.",
  "provision.callcenterinstanceslist.config.videocallnumsError": "El n\u00FAmero m\u00E1ximo de videollamadas simult\u00E1neas debe ser mayor o igual que el n\u00FAmero de asientos de video.",
  "provision.tenantspace.option.weeklist_firtolast": "\u00DAltimo",
  "provision.tenantspace.chooseobsdatasource.callCenterDbSource": "Fuentes de datos del centro de llamadas",
  "chat.webconfig.label.windowTitle.placeholder": "Por favor\, introduzca el t\u00EDtulo que se muestra en la ventana de chat del cliente.",
  "provision.tenant.management.DSTOffset": "Desfase de DST (min)",
  "provision.tenant.management.tenantcreate.timezone.failed": "Error al verificar la informaci\u00F3n de zona horaria.",
  "provision.accesscode.table.whetherSupportTrialUse": "Uso de prueba de soporte",
  "provision.tenantspace.cc.videoIvrchannelUsagePercent": "Uso del canal de video IVR",
  "provision.callcent.error.createaccesscode.n400Numbervalidate": "El c\u00F3digo de acceso y el n\u00FAmero de servicio no pueden existir relaciones inclusivas.",
  "provision.tenantspace.resetpwdfaild.tenant.not.exist": "Error al cambiar la contrase\u00F1a porque el espacio del inquilino no existe.",
  "provision.tenantspace.option.weekday_thu": "Jueves",
  "provision.tenantspace.cc.asrchannelUsagePercent": "Uso de ASR",
  "provision.authconfig.connected.with.tenantspace": "este ID de la aplicaci\u00F3n est\u00E1 vinculado a un espacio de inquilino\, no se puede eliminar.",
  "provision.tenantspace.feature.smsOrEmailMarketingSupport.sendMaxNumber.placeholder": "El valor var\u00EDa de 1 a 1000000000. El valor 0 indica que no se ha especificado ning\u00FAn l\u00EDmite.",
  "chat.skill.message.delete": "Borrar",
  "provision.tenantspace.feature.forceVideoSelfRecord.percentageScope": "El valor m\u00EDnimo es 0\, y el valor m\u00E1ximo es 100. El valor predeterminado es 100.",
  "provision.tenantspace.config.ivrserver.select.confirm.message": "Los procesos IVR seleccionados se implementan en el mismo servidor\, lo que puede causar un \u00FAnico punto de fallo. \u00BFEst\u00E1s seguro de que quieres continuar?",
  "chat.skill.message.createSuccess": "Creamos la habilidad con \u00E9xito.",
  "provision.callcenterinstance.config.maxcallnumsCompareValidate": "El n\u00FAmero m\u00E1ximo de llamadas de voz simult\u00E1neas debe ser mayor o igual a la suma del agente de voz y los canales IVR de audio y v\u00EDdeo.",
  "provision.callcent.error.savesipserverdesinfo": ". Aseg\u00FArese de que el nombre del servidor sbc no existe.",
  "chat.webconfig.message.saveFail": "Error al guardar la configuraci\u00F3n.",
  "chat.skill.message.priorityLength": "Aseg\u00FArese de que la prioridad es menor o igual a cinco d\u00EDgitos.",
  "provision.appversionmanagement.queryFailed": "Error de b\u00FAsqueda.",
  "provision.accesscode.table.n400Number": "N\u00FAmero de servicio",
  "provision.tenant.management.updatetenant.failed": "Error al modificar la configuraci\u00F3n del espacio del inquilino.",
  "provision.tenantspace.error.deleteSUM": "Error al eliminar el espacio del inquilino SUMA.",
  "provision.tenantspace.save.fail.079": "Error al analizar la informaci\u00F3n del usuario local o externo al configurar el sistema de autenticaci\u00F3n de terceros.",
  "provision.tenant.management.tenantcreate.expiryDate": "Fecha de caducidad",
  "provision.callcenterinstanceslist.vcallcenter.SIPServer": "Servidor SBC",
  "provision.recorddrive.error.newrecord.repeated": "No se puede agregar el mismo nombre de letra de unidad en el mismo centro de llamadas",
  "provision.tenantspace.intelligent.ivr": "IVR inteligente",
  "provision.tenantspace.config.amsobjnum.unequal": "Establezca este par\u00E1metro en el mismo valor que el del servidor de llamadas an\u00F3nimas.",
  "provision.tenantspace.authenticationsystem.enter.clientName": "Introduzca el nombre de un sistema de autenticaci\u00F3n de terceros.",
  "provision.tenantspace.popuppageurl.whiteListAdd": "Adici\u00F3n de una lista de confianza de direcciones",
  "provision.callcenterinstanceslist.vcallcenter.close": "Cerrar",
  "provision.tenantspace.agentinfo.message.selectagent": "\u00A1Seleccione un agente\!",
  "provision.tenantspace.authenticationsystem.mappingName": "Regla de asignaci\u00F3n de federaci\u00F3n",
  "provision.callcenterinstanceslist.label.callnumUseRate": "Uso de llamadas de voz simult\u00E1neas",
  "provision.callcenterinstanceslist.table.versatileAgentCurCapacity": "Agentes vers\u00E1tiles",
  "chat.skill.label.skillId": "No",
  "ccprovision.vcallecenter.sucess.updateinfo.adminpassword": "Modifique la contrase\u00F1a correctamente.",
  "provision.callcenterinstanceslist.config.servicePort": "Puerto Nginx",
  "chat.session.endType.agentEnd": "Fin por agente",
  "provision.tenantspace.feature.dualChannelRecSupport.description": "Esta caracter\u00EDstica proporciona funciones de grabaci\u00F3n de doble pista para el espacio del inquilino.",
  "provision.callcenterinstanceslist.config.maxcallnumsValidate": "El n\u00FAmero m\u00E1ximo de llamadas de voz simult\u00E1neas es menor que el n\u00FAmero de llamadas de voz realmente asignadas por el centro de llamadas.",
  "provision.tenantspace.save.fail.067": "Error al validar los datos del sistema de autenticaci\u00F3n de terceros.",
  "provision.whiteListAddress.AddressExist": "La lista de confianza de direcciones ya existe.",
  "provision.tenantspace.authenticationsystem.clientURL": "URL del sistema de autenticaci\u00F3n de terceros",
  "provision.systemparam.error.validate.notmatchruleip": "Ingrese una direcci\u00F3n IPV4 o IPV6 v\u00E1lida",
  "provision.callcenterinstanceslist.obsServer.standbyObsDirectHeartbeatIp": "Especifica la direcci\u00F3n IP del latido de la OBS en espera",
  "provision.encryptinfo.scenario": "Escenario de la aplicaci\u00F3n",
  "provision.callcenterinstanceslist.vcallcenter.newvccId": "VccInstId",
  "provision.tenantspace.save.fail.062": "El c\u00F3digo de acceso multimedia debe configurarse cuando se configura el c\u00F3digo de acceso de pollito a marcado.",
  "provision.tenantspace.save.fail.063": "Error al eliminar el proceso IVR.",
  "provision.callcenterinstanceslist.sipServer.maxRegAgentNum": "N\u00FAmero de solicitudes simult\u00E1neas",
  "provision.tenant.management.email": "Correo Electr\u00F3nico",
  "provision.appversionmanagement.systemType": "Tipo de sistema",
  "provision.callcenterinstanceslist.ucsServer.singledeleteconfim": "\u00BFEst\u00E1 seguro de que desea eliminar el servidor CCUCS seleccionado?",
  "provision.callcenterinstanceslist.obsServer.singledeleteconfim": "\u00BFEst\u00E1 seguro de que desea eliminar el servidor obs de registro seleccionado?",
  "provision.callcenterinstanceslist.vcallcenter.accessCode": "C\u00F3digo de acceso",
  "provision.callcenterinstanceslist.nodepolicy.VNDId": "VDN N\u00FAmero",
  "provision.callcenterinstanceslist.vcallcenter.nodepolicy": "Configuraci\u00F3n de directivas de selecci\u00F3n de nodos para recursos de llamadas salientes",
  "provision.callcenterinstanceslist.nodepolicy.tenantspacename": "VDN Nombre",
  "provision.callcenterinstanceslist.nodepolicy.save": "guardar",
  "provision.nodepolicy.sync.success": "Sincronizaci\u00F3n exitosa",
  "provision.nodepolicy.save.success": "Se ha guardado correctamente",
  "provision.nodepolicy.sync.failed": "Error de sincronizaci\u00F3n",
  "provision.nodepolicy.save.failed": "Error al guardar",
  "provision.callcenterinstanceslist.table.nodepolicyconfirmmessage": "\u00BFEst\u00E1 seguro de que desea sincronizar las configuraciones de NODE POLICY?",
  "provision.systemparam.success.reset": "El restablecimiento del par\u00E1metro se ha realizado correctamente",
  "provision.tenant.management.akinfo": "Informaci\u00F3n AK",
  "provision.tenantspace.sip.idleRegAgentNum": "Cantidad de Agente Registrado Simult\u00E1neo restante",
  "provision.tenantspace.save.fail.057": "El recurso asignado al espacio del inquilino es anormal.",
  "provision.tenantspace.agentinfo.agentstatus": "Estado del agente",
  "provision.tenantspace.save.fail.058": "Error al guardar los datos.",
  "provision.tenantspace.save.fail.056": "Informaci\u00F3n b\u00E1sica incorrecta del espacio del inquilino.",
  "provision.tenantspace.save.fail.055": "Las tareas pendientes existen en el espacio del inquilino.",
  "provision.ccworknos.table.ccworknos": "ID de empleado",
  "provision.tenantspace.save.fail.054": "Error al asignar manualmente los recursos de n\u00FAmeros de softphone.",
  "provision.grayconfig.deleteconfim": "\u00BFEst\u00E1 seguro de que desea eliminar la configuraci\u00F3n gris?",
  "provision.tenantspace.feature.knowledge.open.confirmmsg": "La funci\u00F3n AppCube es la base para ejecutar la funci\u00F3n de la base de conocimientos y se activar\u00E1 al mismo tiempo.",
  "provision.tenantspace.save.fail.052": "El servidor OBS no existe.",
  "provision.tenantspace.save.fail.053": "El origen de datos OBS no existe.",
  "provision.tenantspace.save.fail.050": "El n\u00FAmero de ID de agente seleccionados es menor que el n\u00FAmero de nuevos agentes.",
  "provision.tenantspace.save.fail.051": "El origen de datos OBS no est\u00E1 asignado.",
  "provision.tenantspace.status.active.fail": "Error en la activaci\u00F3n.",
  "chat.session.label.enterQueueTime": "Tiempo de cola",
  "provision.callcenterinstanceslist.table.agentCurCapacity": "Cantidad de agente de voz",
  "provision.tenant.management.administratorinfo": "Informaci\u00F3n del administrador del inquilino",
  "provision.callcenterinstanceslist.resource.associationvcallcener.updatenode.failed": "Los inquilinos se asignan\, los nodos no se pueden modificar.",
  "ccprovision.IVRSprogress.config.progressNo": "ID de proceso",
  "provision.callcent.platformserver.ccbcs": "CCBCS",
  "chat.weChatConfig.message.emptyDeleteArray": "Seleccione el elemento de configuraci\u00F3n que desea eliminar.",
  "provision.tenantspace.selectStastus.edit.active": "activo",
  "provision.tenantspace.config.param.error.ttsNumber": "Por favor\, introduzca la cantidad TTS.",
  "chat.skill.label.maxQueueTime": "Tiempo (s) de cola m\u00E1ximo (s)",
  "provision.tenantspace.save.fail.049": "El n\u00FAmero excluido no cumple con los requisitos.",
  "provision.tenantspace.cc.asrchannelnums": "Cantidad ASR",
  "provision.callcenterinstanceslist.IVRSprogress": "Proceso IVR",
  "provision.tenantspace.save.fail.048": "El segmento de ID de agente que se asigna manualmente contiene los ID de agente asignados.",
  "provision.callcenterinstanceslist.config.odfconfigerror": "La informaci\u00F3n de configuraci\u00F3n del servicio ODFS es incorrecta.",
  "provision.tenantspace.save.fail.047": "El servidor CC-Gateway no tiene recursos suficientes.",
  "provision.tenantspace.save.fail.046": "La conexi\u00F3n a la plataforma no pudo eliminar un agente.",
  "provision.tenantspace.save.fail.044": "Por favor\, configure el c\u00F3digo de acceso para el tipo de audio y v\u00EDdeo.",
  "provision.tenantspace.save.fail.043": "N\u00FAmero de aplicaciones para agentes multimedia mayor que el n\u00FAmero de agentes multimedia restantes.",
  "provision.tenantspace.save.fail.041": "La suma de los agentes de voz\, v\u00EDdeo\, multimedia y vers\u00E1tiles\, y los canales de IVR de audio y v\u00EDdeo debe ser mayor que 0.",
  "provision.tenantspace.save.fail.040": "Por favor\, configure el c\u00F3digo de acceso para el tipo multimedia.",
  "provision.tenantspace.feature.smsMarketingSupport.description": "Esta caracter\u00EDstica proporciona funciones de marketing SMS para el espacio del inquilino.",
  "provision.callcenterinstanceslist.softPhone.newpasswordconf": "Repetir nueva contrase\u00F1a",
  "provision.tenantspace.save.fail.045": "Los recursos del proceso de IVR son insuficientes.",
  "provision.tenant.management.DSTEndTime": "Hora de finalizaci\u00F3n del horario de verano",
  "provision.softPhone.label.isSyncUap": "Sincronizar UAP",
  "provision.tenant.no.more.agentUsage": "El n\u00FAmero de agentes es demasiado grande.",
  "provision.callcent.error.updatesipserver.noexist": "El servidor SIP no existe.",
  "provision.tenantspace.commercialStatus": "Si es un sitio comercial",
  "provision.tenantspace.common.ivr.explain": "IVR convencional\: Soporta funciones b\u00E1sicas como reproducci\u00F3n\, recepci\u00F3n y TTS. TTS necesita configurar el n\u00FAmero de canales TTS.",
  "provision.callcent.selectvcallcent": "Seleccione un centro de llamadas virtual.",
  "provision.encryptinfo.email": "Cifrado y descifrado de contenido de correo electr\u00F3nico",
  "provision.callcenterinstanceslist.obsServer.activeObsDirectHeartbeatIp": "Especifica la direcci\u00F3n IP del latido de la OBS activa",
  "provision.tenantspace.table.ivrchannels": "Cantidad de canal de audio IVR",
  "ccprovision.vcallecenter.config.popup.authentication": "Verificar identidad",
  "provision.callcent.error.saveucsserverdesinfo": "Error al crear. Aseg\u00FArese de que el nombre del servidor CCUCS no existe.",
  "provision.sysparam.config.checkmessage": "Contiene caracteres especiales. (" < "\, " > "\, " / "\, etc.)",
  "provision.tenantspace.save.fail.031": "Reducci\u00F3n de inquilinos por favor opere en la p\u00E1gina de informaci\u00F3n del agente.",
  "provision.tenantspace.save.fail.032": "AgentUsage + VideoUsage debe ser inferior a 10000.",
  "chat.session.title.basic": "Informaci\u00F3n de di\u00E1logo b\u00E1sico",
  "provision.tenantspace.save.fail.030": "El PhoneNumber blando no es suficiente.",
  "provision.callcenterinstanceslist.config.videoIvrchannelsValidate": "El n\u00FAmero de canales de IVR de v\u00EDdeo es menor que el n\u00FAmero de canales realmente asignados en el centro de llamadas.",
  "chat.client.label.yes": "S\u00ED",
  "provision.tenantspace.gw.port": "Puerto de servidor de puerta de enlace",
  "provision.callcenterinstanceslist.sipServer.batchdeleteconfim": "\u00BFEst\u00E1 seguro de que desea eliminar todos los servidores de registro seleccionados?",
  "chat.client.button.submit": "Enviar",
  "provision.callcent.error.accesscode.deletefail.sqlexception": "Error al eliminar\, excepci\u00F3n de ejecuci\u00F3n de SQL",
  "provision.callcent.error.createaccesscode.validate.accessCodeContainAccesscode": "El c\u00F3digo de acceso no puede contener una relaci\u00F3n de inclusi\u00F3n con otros c\u00F3digos de acceso bajo el centro de llamadas.",
  "provision.ivr.error.cannotdelete.occupyvdn": "El IVR asignado al inquilino no se puede eliminar.",
  "chat.skill.label.cancel": "Cancelar",
  "ccprovision.IVRSprogress.config.button.add": "Nuevo",
  "provision.callcenterinstanceslist.table.refresh": "Refrescar",
  "provision.systemparam.error.validate.userNotExists": "Ingrese una cuenta de usuario v\u00E1lida",
  "provision.systemparam.error.validate.notmatchruleurl": "Por favor\, introduzca una direcci\u00F3n URL v\u00E1lida",
  "provision.tenantspace.config.param.error.videoCall": "Introduzca el n\u00FAmero de videollamadas simult\u00E1neas.",
  "chat.usefulexpressions.message.willDeleteExpression": "\u00BFEst\u00E1 seguro de que desea eliminar la frase com\u00FAn?",
  "provision.callcenterinstanceslist.label.eMediaAgentUseRate": "Uso del agente multimedia",
  "provision.tenantspace.save.fail.029": "A la izquierda del n\u00FAmero ASR no es suficiente.",
  "provision.tenantspace.save.fail.027": "A la izquierda del n\u00FAmero IVR no es suficiente.",
  "provision.tenantspace.save.fail.028": "A la izquierda del n\u00FAmero TTS no es suficiente.",
  "provision.tenantspace.save.fail.026": "El n\u00FAmero restante de llamadas an\u00F3nimas simult\u00E1neas es insuficiente.",
  "provision.tenantspace.save.fail.025": "La izquierda de VoiceCallNumber no es suficiente.",
  "provision.spinfo.description": "Descripcion",
  "provision.tenantspace.save.fail.024": "La izquierda de VidoeUsage no es suficiente.",
  "provision.spinfo.modifyspinfosuccess": "La informaci\u00F3n del SP se ha modificado correctamente.",
  "provision.tenantspace.option.weeklist_tid": "Tercero",
  "provision.tenantspace.save.fail.023": "La izquierda de AgentUsage no es suficiente.",
  "provision.tenantspace.save.fail.022": "AgentUsage y MaxVoiceCallNumbers no pueden ser cero.",
  "provision.tenantspace.save.fail.021": "Error al actualizar los datos del espacio del inquilino.",
  "provision.callcenterinstanceslist.table.wasip": "Establecer IP del servidor",
  "provision.tenantspace.save.fail.020": "Error al eliminar los datos del centro de llamadas virtual.",
  "provision.callcenterinstanceslist.table.ivrchannels": "Cantidad de canal de audio IVR",
  "provision.tenantspace.ok": "OK",
  "provision.tenantspace.cc.maxAmsCallnums": "M\u00E1x. llamadas an\u00F3nimas simult\u00E1neas",
  "provision.callcent.error.abnormal": "Anormal",
  "ccprovision.IVRSprogress.config.button.complete": "Completo",
  "provision.tenantspace.config.tenant.intelligent.ivr.featurevalue.not.one": "El valor de la caracter\u00EDstica ivr inteligente asignado al inquilino no es 1",
  "provision.tenantspace.obsServer.datasource.name": "Nombre de origen de datos",
  "provision.callcenterinstanceslist.sipServer.idleRegAgentNum": "Concurrencia restante",
  "provision.tenantspace.feature.knowledgeBaseSupport": "Base de Conocimientos",
  "provision.systemparam.error.noparamselected": "Seleccione un par\u00E1metro primero",
  "provision.callcenterinstanceslist.whiteList.IP": "Direcci\u00F3n de lista de confianza",
  "provision.tenantspace.config.amsNumber.error": "El n\u00FAmero de llamadas an\u00F3nimas simult\u00E1neas no puede estar vac\u00EDo o debe ser 0 o un entero positivo.",
  "provision.tenantspace.save.fail.019": "La capacidad restante del centro de llamadas es menor que la de los agentes reci\u00E9n asignados.",
  "provision.tenantspace.save.fail.018": "El centro de llamadas est\u00E1 vac\u00EDo.",
  "provision.tenantspace.save.fail.017": "El centro de llamadas virtual est\u00E1 vac\u00EDo.",
  "chat.session.label.endType": "Tipo de fin de di\u00E1logo",
  "provision.tenantspace.save.fail.016": "Error al actualizar los datos del centro de llamadas.",
  "provision.tenantspace.save.fail.015": "Error al actualizar los datos del n\u00FAmero de softphone.",
  "chat.skill.message.willDeleteAllSkills": "\u00BFEst\u00E1 seguro de que desea eliminar todas las habilidades?",
  "provision.tenantspace.save.fail.014": "Error al actualizar los datos de ID de empleado.",
  "chat.thirdportal.chatWindow.button.startChat": "Iniciar chat",
  "provision.tenantspace.save.fail.013": "Error al actualizar los datos del c\u00F3digo de acceso.",
  "provision.tenantspace.save.fail.012": "Error al actualizar los datos del centro de llamadas virtual.",
  "chat.timerange.label.ok": "OK",
  "provision.tenantspace.sip.name": "Nombre del servidor SBC",
  "provision.tenantspace.save.fail.011": "Error al guardar los datos del centro de llamadas virtual.",
  "provision.tenantspace.save.fail.010": "El n\u00FAmero de segmentos de n\u00FAmero ocupados es menor que el n\u00FAmero de agentes aplicados. El valor devuelto es nulo.",
  "provision.callcenterinstanceslist.label.maxAmsCallNums": "Llamadas an\u00F3nimas simult\u00E1neas",
  "provision.tenant.management.pool.ctinode2": "Nodo CTI 2",
  "provision.tenant.management.pool.ctinode1": "Nodo CTI 1",
  "provision.speciallist.tip.userNoLength": "El n\u00FAmero de suscriptor no puede exceder los 100 caracteres",
  "provision.tenantspace.selectStastus.unallocated": "Pendiente",
  "provision.accesscode.table.enterpriseName": "Nombre de empresa",
  "provision.callcenterinstanceslist.config.videoAgentChannelsValidate": "El n\u00FAmero de canales de agente de v\u00EDdeo es menor que el n\u00FAmero de canales realmente asignados en el centro de llamadas.",
  "provision.tenantspace.config.choosesoftphone": "Seleccione el n\u00FAmero de softphone.",
  "provision.tenantspace.save.fail.009": "El segmento de ID de empleado est\u00E1 vac\u00EDo.",
  "provision.tenantspace.save.fail.008": "El n\u00FAmero de softphone est\u00E1 vac\u00EDo.",
  "provision.callcenterinstanceslist.table.obssyncConfimMessage": "\u00BFEst\u00E1 seguro de sincronizar la configuraci\u00F3n de OBS?",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.explain2": "Se puede a\u00F1adir un m\u00E1ximo de cinco grupos de n\u00FAmeros.",
  "provision.tenantspace.save.fail.007": "El centro de llamadas no existe.",
  "provision.callcenterinstanceslist.sipServer.sip": "SIP",
  "provision.tenantspace.save.fail.006": "El n\u00FAmero de agentes bajo el inquilino no puede ser mayor que el n\u00FAmero de agentes restantes en el servidor SBC.",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.explain1": "Vista previa de la biblioteca de n\u00FAmeros",
  "chat.weChatConfig.label.secret": "Llave",
  "provision.tenantspace.save.fail.005": "El c\u00F3digo de acceso est\u00E1 ocupado.",
  "provision.tenantspace.save.fail.004": "El c\u00F3digo de acceso est\u00E1 vac\u00EDo.",
  "provision.tenantspace.save.fail.003": "El n\u00FAmero m\u00E1ximo de llamadas de v\u00EDdeo simult\u00E1neas es mayor o igual que el n\u00FAmero de agentes de v\u00EDdeo.",
  "provision.tenantspace.save.fail.002": "El n\u00FAmero m\u00E1ximo de llamadas de voz simult\u00E1neas es mayor o igual que el n\u00FAmero de agentes y canales IVR.",
  "provision.tenantspace.feature.operation": "Los datos de la caracter\u00EDstica del inquilino se est\u00E1n procesando en el backend. Vea el resultado del procesamiento de funciones en la p\u00E1gina actual 2 minutos m\u00E1s tarde.",
  "provision.tenantspace.save.fail.001": "El n\u00FAmero de agentes de voz\, agente de v\u00EDdeo\, agente multimedia y canales IVR no puede ser 0.",
  "provision.tenant.management.tenantspaceid": "ID del espacio del inquilino",
  "provision.obs.error.sync": "Error de sincronizaci\u00F3n.",
  "provision.callcent.platformserver.cms": "CMS",
  "ccprovision.vcallecenter.config.popup.modifyinfo": "Modificar contrase\u00F1a",
  "provision.tenantspace.config.amsServer.idleregagentnum.insufficient.error": "N\u00FAmero insuficiente de llamadas simult\u00E1neas restantes en el servidor de llamadas an\u00F3nimas.",
  "chat.client.label.accountNumber": "Cuenta",
  "provision.systemparam.error.validate.notmatchruleregex": "El par\u00E1metro de entrada no coincide con la expresi\u00F3n regular de la regla de verificaci\u00F3n",
  "provision.callcenterinstanceslist.ccgatewayserver.ccGatewayPort": "Puerto de pasarela CC",
  "provision.systemparam.error.validate.watermarkinfo.notmatchruleregex": "El par\u00E1metro solo permite de 0 a 20 caracteres que contengan letras may\u00FAsculas y min\u00FAsculas\, n\u00FAmeros o -_/{'@'}.",
  "provision.tenant.management.tenantcreate.success": "El espacio del inquilino se crea con \u00E9xito.",
  "chat.agent.label.historicalBill": "Proyecto de ley hist\u00F3rico",
  "provision.tenantspace.obs.searchName": "Introduzca el nombre del servidor OBS que se va a consultar.",
  "provision.authconfig.deleteconfim": "\u00BFEst\u00E1 seguro de que desea eliminar el ID de la aplicaci\u00F3n?",
  "provision.tenant.management.edit.agentusage.mustinc": "El n\u00FAmero de nuevos agentes no puede ser menor que el de los agentes originales.",
  "provision.callcenterinstanceslist.softPhone.softPhoneAdd": "Nuevo n\u00FAmero de Softphone",
  "provision.callcenterinstanceslist.sipServer.sipServerIp": "Direcci\u00F3n IP del servidor SBC",
  "provision.speciallist.tip.reasonLength": "La raz\u00F3n no puede exceder los 1024 caracteres",
  "provision.spinfo.createTime": "CreateTime",
  "provision.callcenterinstanceslist.table.delete": "Borrar",
  "provision.callcenterinstance.config.ivrchannelsValidate": "La suma del n\u00FAmero de canales IVR de audio y v\u00EDdeo debe ser mayor o igual que la suma del n\u00FAmero de canales TTS y el n\u00FAmero de canales ASR.",
  "provision.tenantspace.option.weekday_wed": "Mi\u00E9rcoles",
  "provision.tenantspace.config.select.default.phone": "Seleccione el n\u00FAmero de llamada predeterminado.",
  "provision.callcenterinstanceslist.label.videoAgentChannelUseRate": "Uso del canal del agente de v\u00EDdeo",
  "ccprovision.IVRSprogress.config.leftovercurcallNo": "Cantidad de canal de llamada concurrente restante",
  "chat.agentConsole.message.content.locate": "[Mensaje de ubicaci\u00F3n]",
  "provision.callcenterinstanceslist.config.asrchannelnumsValidate": "El n\u00FAmero de canales ASR es menor que el n\u00FAmero de canales realmente asignados en el centro de llamadas.",
  "provision.authconfig.authinfomation": "Informaci\u00F3n del desarrollador",
  "provision.tenantspace.agentinfo": "Informaci\u00F3n del agente",
  "provision.callcenterinstanceslist.vcallcenterDeatil.uapIpAndPort": "Direcci\u00F3n UAP",
  "provision.callcent.error.accesscode.deletefail.part.onccgateway": "Parte del c\u00F3digo de acceso no se pudo eliminar en la plataforma fue.",
  "provision.callcenterinstanceslist.config.sysAdminpassword": "Contrase\u00F1a del administrador de CTI",
  "provision.callcenterinstanceslist.table.callcenterinstanceslist": "Lista de Centros de Llamadas",
  "promotion.tenant.management.tenantedit.vccupdate.failed": "Error al actualizar el centro virtual.",
  "provision.callcenterinstanceslist.table.ttschannelnums": "TTS Cantidad",
  "provision.callcenterinstanceslist.config.wnFromValidate": "La suma del segmento de n\u00FAmero de inicio y la capacidad real del sistema no puede ser mayor de 50000.",
  "chat.session.label.endTime": "Hora de finalizaci\u00F3n de di\u00E1logo",
  "provision.tenantspace.detail.apply.accout": "Cuenta",
  "chat.client.message.hiMyWorkno": "\u00A1Hola\! Esto es",
  "provision.tenantspace.table.expiryDate": "Fecha l\u00EDmite",
  "provision.tenant.management.tenantedit": "Editar informaci\u00F3n del inquilino",
  "provision.whiteListAddress.resourcedump.obsproxy": "Agente de servidor OBS de volcado de recursos",
  "chat.agent.label.mybill": "Mis facturas",
  "chat.session.title.detail": "Detalles del cuadro de di\u00E1logo",
  "chat.weChatConfig.label.bindSkill": "Cola de habilidades de enlace",
  "chat.skill.label.submit": "Enviar",
  "ccprovision.recorddrive.config.sftp.filestoragepath": "Ruta de archivo",
  "provision.tenant.management.tenantcreate.inter.failed": "Error al invocar el servicio interno para crear el espacio del inquilino.",
  "chat.weChatConfig.label.verify": "Comprobaci\u00F3n",
  "provision.callcenter.gateway.address.alert": "Direcci\u00F3n de equilibrio de carga de cl\u00FAster de puerta de enlace CC",
  "provision.tenantspace.table.maxVideoCalls": "M\u00E1x. videollamadas simult\u00E1neas",
  "provision.tenantspace.dstoffsettime.verification.fail": "El valor es un entero que oscila entre -1440 y 1440.",
  "chat.client.message.evaluationSuccess": "La encuesta de satisfacci\u00F3n tiene \u00E9xito.",
  "provision.callcent.platformserver.isqm": "iSQM",
  "provision.callcenterinstanceslist.config.save": "Guardar",
  "provision.tenantspace.cc.amsCallnumUsagePercent": "Uso de llamadas an\u00F3nimas simult\u00E1neas",
  "provision.tenantspace.option.weekday_mon": "Lunes",
  "provision.callcent.error.updateccgateway.ondatabase": "Error de actualizaci\u00F3n\, se modific\u00F3 el servidor CC-Gateway en la base de datos.",
  "chat.webconfig.message.configdirective": "Copie el siguiente c\u00F3digo y p\u00E9guelo antes de la etiqueta de su sitio web </body>. Guarde y libere el c\u00F3digo para completar el acceso r\u00E1pido a Huawei Service Cloud. Despu\u00E9s del lanzamiento\, la entrada de Huawei Service Cloud se muestra en la esquina inferior derecha de su sitio web. Puede hacer clic en \u00E9l para hablar con el banco de trabajo de servicio al cliente en tiempo real.",
  "provision.tenantspace.config.chooseSIPServer": "Seleccione un servidor SBC.",
  "provision.sysparam.config.refresh": "Refrescar",
  "provision.tenantspace.obsServer.backup.datasource.name": "Nombre de origen de datos en espera",
  "provision.tenant.management.pool.ctinode.name": "Nombre del nodo",
  "provision.tenant.management.tenantcreate.companyName": "Empresa",
  "chat.session.label.remark": "Comentario",
  "provision.callcenterinstanceslist.config.workNoCheckError": "El n\u00FAmero inicial del ID de trabajo debe ser menor que el n\u00FAmero final.",
  "provision.tenantspace.resetpwdsuccess": "La contrase\u00F1a se restablece correctamente.",
  "provision.tenantspace.config.error.softphone.noaddagent": "No se a\u00F1ade ning\u00FAn agente de audio\, v\u00EDdeo\, multimedia o m\u00F3vil.",
  "provision.callcenterinstanceslist.config.nmsserviceIP": "Direcci\u00F3n IP ODFS",
  "provision.tenantspace.agentinfo.message.searchSipAccount": "Por favor ingrese el n\u00FAmero de tel\u00E9fono",
  "chat.usefulexpressions.message.createSuccess": "Se ha creado la frase com\u00FAn con \u00E9xito.",
  "provision.tenantspace.config.softphoneassign.softphonescope.required": "Introduzca el segmento de n\u00FAmero de softphone que desea agregar y haga clic en Aceptar.",
  "provision.callcenterinstanceslist.table.deleteconfim": "Confirmar Borrar",
  "ccprovision.vcallecenter.config.popup.confirmnewpassword": "Confirmar contrase\u00F1a",
  "ccprovision.recorddrive.config.sftp.identitycheck": "Verificaci\u00F3n de identidad",
  "provision.callcenterinstanceslist.config.adminWorkNoError": "El n\u00FAmero inicial del ID de empleado del administrador debe oscilar entre 55001 y 59999.",
  "provision.callcenterinstanceslist.config.eMediaAgentCurCapacityValidate": "El n\u00FAmero de agentes multimedia es menor que el n\u00FAmero de asientos multimedia realmente asignados en el centro de llamadas.",
  "provision.appversionmanagement.harmony": "HarmonyOS",
  "provision.tenantspace.choose.accesscode": "Seleccione el c\u00F3digo de acceso",
  "provision.callcenterinstanceslist.sipServer.sipServerPort": "N\u00FAmero de puerto SBC",
  "provision.callcent.success.deleteinfo": "Se ha eliminado correctamente.",
  "provision.appversionmanagement.historicalVersion": "Versi\u00F3n hist\u00F3rica",
  "provision.tenantspace.feature.smsOrEmailMarketingSupport.sendMaxNumber": "Env\u00EDo de l\u00EDmite superior por d\u00EDa",
  "provision.callcent.label.reset": "Restablecer",
  "chat.common.message.delete": "Borrar",
  "provision.spinfo.certificate": "Contenido del certificado",
  "chat.agent.title.userInformation": "Informaci\u00F3n del cliente",
  "provision.tenantspace.config.select.commercial.status": "Seleccione un estado comercial.",
  "provision.callcenterinstanceslist.table.operate": "Operaci\u00F3n",
  "provision.tenantspace.table.videoivrchannels": "Cantidad de canal de video IVR",
  "provision.systemparam.error.validate.notmatchruledigits": "Introduzca un entero positivo v\u00E1lido",
  "provision.callcenterinstanceslist.table.wasport": "Establezca el n\u00FAmero de puerto del servidor.",
  "provision.callcenterinstanceslist.table.baseinfo": "Informaci\u00F3n b\u00E1sica",
  "provision.appversionmanagement.new": "A\u00F1adir",
  "provision.ccworknos.table.creataccessCode": "Nuevo",
  "provision.callcenterinstanceslist.table.maxcallnums": "M\u00E1x. llamadas de voz simult\u00E1neas",
  "pprovision.tenantspace.feature.emailAgentForwarding.description": "Habilite la funci\u00F3n de reenv\u00EDo del agente de correo electr\u00F3nico. El espacio alquilado admite la funci\u00F3n de reenv\u00EDo de correo electr\u00F3nico del agente de correo electr\u00F3nico.",
  "provision.callcent.selectcallcent": "Seleccione un centro de llamadas.",
  "provision.tenantspace.save.fail.123053": "El rol de agente es el rol predeterminado y no se puede modificar.",
  "provision.tenantspace.save.fail.123052": "El agente hace referencia al papel del agente y no se puede eliminar.",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.error": "El ID de agente final no puede ser menor que el ID de agente inicial.",
  "provision.tenantspace.save.fail.123051": "Nombre duplicado del rol del agente.",
  "provision.callcenterinstanceslist.table.allowedTTSAndASRCallNum": "N\u00FAmero de canales de IVR inteligentes",
  "provision.callcenterinstanceslist.table.update": "Editar",
  "provision.accesscode.table.audioAndvideo": "audio y v\u00EDdeo",
  "provision.callcent.pool.select.different.sipserver.error": "Los servidores de registro de agentes del mismo tipo deben seleccionarse para diferentes nodos.",
  "provision.callcenterinstanceslist.softPhone.used.select": "Uso",
  "provision.callcenterinstanceslist.vcallcenter.CcgatewayServer": "Servidor de puerta de enlace CC",
  "provision.tenantspace.config.chooseAgentRegistrationServerTitle": "Seleccione el servidor de registro del agente.",
  "provision.tenant.management.tenantname.minlength": "El nombre del espacio del inquilino no puede contener menos de 6 caracteres.",
  "chat.client.greeting.evening": "\u00A1Buenas noches\!",
  "provision.tenantspace.table.maxVideoAgents": "Cantidad de agente de video",
  "provision.tenantspace.create.onlineUserNumber": "N\u00FAmero de usuario en l\u00EDnea",
  "provision.tenantspace.selectStastus.edit.invalid": "Inv\u00E1lido",
  "provision.tenant.management.admininfo": "Registrarse Administrador de inquilinos",
  "chat.client.label.rateService": "Servicio de Puntuaci\u00F3n",
  "provision.tenant.management.batchcreate.success.createtask": "Se ha a\u00F1adido correctamente una tarea para crear espacios de inquilino en lotes.",
  "provision.callcenterinstanceslist.vcallcenter.vcallcenter": "Centro de llamadas virtual",
  "provision.tenant.management.phonenumber": "N\u00FAmero de Tel\u00E9fono",
  "chat.weChatConfig.label.create": "Nuevo",
  "chat.skill.message.saveSuccess": "Guardado la habilidad con \u00E9xito.",
  "provision.tenantspace.option.month_11": "Noviembre",
  "provision.callcenterinstanceslist.config": "Centro de llamadas",
  "provision.tenantspace.option.month_10": "octubre",
  "provision.tenantspace.option.month_12": "diciembre",
  "provision.callcent.error.saveccgateway.regAgentNumError": "El n\u00FAmero de asientos registrados modificados es inferior al n\u00FAmero real de asientos asignados.",
  "provision.tenantspace.config.chooseDPN": "Seleccione uno de los c\u00F3digos de acceso como n\u00FAmero de llamada predeterminado.",
  "provision.callcent.error.updateaccesscode.fail.syncccmanagement": "\, ha fallado la modificaci\u00F3n s\u00EDncrona de la informaci\u00F3n de la habitaci\u00F3n de alquiler",
  "provision.tenantspace.save.fail.107-107": "ipccbase configproxy uso.",
  "provision.callcenterinstanceslist.ucsServer.ucsServerIp": "IP del servidor CCUCS",
  "ccprovision.vcallecenter.error.updateinfo.adminpassword": "Error de modificaci\u00F3n de la contrase\u00F1a.",
  "provision.tenantspace.feature.dualCallTransparentDisplaySupport.description": "Despu\u00E9s de habilitar esta funci\u00F3n\, puede personalizar los n\u00FAmeros que se mostrar\u00E1n a los agentes y suscriptores (dependiendo de si la l\u00EDnea num\u00E9rica est\u00E1 conectada). Si el c\u00F3digo de acceso est\u00E1 vac\u00EDo\, la funci\u00F3n est\u00E1 habilitada de forma predeterminada.",
  "chat.webconfig.button.save": "Guardar",
  "chat.webconfig.label.functionalsettings": "Configuraci\u00F3n de la funci\u00F3n",
  "provision.tenantspace.table.update": "Editar",
  "provision.encryptinfo.strength": "Resistencia",
  "provision.tenantspace.save.fail.078modify": "Error al modificar el espacio del inquilino al configurar el sistema de autenticaci\u00F3n de terceros.",
  "provision.tenantspace.obsServer.datasource.databasename": "Nombre de la base de datos",
  "provision.tenantspace.common.anonymous.explain": "Habilite la funci\u00F3n de llamada saliente an\u00F3nima y habilite la funci\u00F3n de llamada saliente an\u00F3nima para el espacio del inquilino.",
  "chat.skill.label.createSkill": "Crear",
  "provision.callcent.success.deletesoftphonenumber.unfinishtask": "Se est\u00E1 procesando una tarea de eliminaci\u00F3n. Por favor\, int\u00E9ntelo de nuevo m\u00E1s tarde.",
  "provision.callcent.error.updatesoftphonenumber.fail.syncccmanagement": "Error al sincronizar el softphone para modificar la informaci\u00F3n a la habitaci\u00F3n de alquiler.",
  "provision.tenantspace.table.tenantSpaceStatus": "Estado",
  "chat.usefulexpressions.message.willDeleteExpressions": "\u00BFEst\u00E1 seguro de que desea eliminar la frase com\u00FAn seleccionada?",
  "provision.callcenterinstanceslist.vcallcenterDeatil.DateValidateFailed": "Por favor\, seleccione una fecha mayor que hoy y menor que 2038-1-19",
  "provision.appversionmanagement.latestVersion": "\u00DAltima versi\u00F3nn",
  "provision.callcenterinstance.config.videoCallValidate": "El n\u00FAmero m\u00E1ximo de videollamadas simult\u00E1neas debe ser mayor o igual que el n\u00FAmero de asientos de video.",
  "ccprovision.IVRSprogress.config.IPAddress": "Direcci\u00F3n IP",
  "provision.tenantspace.config.softphoneassign.softphonescope.contain": "Se ha seleccionado el n\u00FAmero de softphone. Por favor\, ingrese otro.",
  "provision.callcent.error.createaccesscode.fail.onccgateway": "\, error al agregar un c\u00F3digo de acceso a la plataforma.",
  "provision.tenantspace.config.worknoassign.filterworkNo": "Banco de N\u00FAmeros de Cribado",
  "provision.callcent.selectcallcentccgateway": "Por favor seleccione CC-Gateway.",
  "provision.tenantspace.option.weekday_sun": "Domingo",
  "provision.tenantspace.ivr.maxChnNum": "Cantidad de llamadas simult\u00E1neas",
  "provision.tenantspace.language.cn": "Espa\u00F1ol",
  "chat.agent.label.billDetail": "Detalles de la lista",
  "chat.session.label.skillQueueId": "ID de cola de habilidades",
  "ccprovision.vcallecenter.config.popup.adminworkno": "ID de administrador de configuraci\u00F3n",
  "provision.callcenterinstanceslist.label.maxVideoCallnums": "M\u00E1x. videollamadas simult\u00E1neas",
  "provision.callcenterinstanceslist.table.download": "Descargar",
  "chat.client.label.bill": "Mis facturas",
  "provision.callcenterinstanceslist.vcallcenter.CcgatewayServer.title": "Configuraci\u00F3n de CC-Gateway",
  "ccp.version.versioncode": "Versi\u00F3n",
  "provision.sysparam.config.itemname.tips": "Introduzca un nombre de par\u00E1metro.",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.paramserror": "La informaci\u00F3n del n\u00FAmero es incorrecta.",
  "chat.client.label.like": "Te puede gustar",
  "provision.tenantspace.feature.softphonenounifiedauth": "Autenticaci\u00F3n unificada de n\u00FAmero de softphone",
  "provision.tenantspace.chooseFeature": "Selecci\u00F3n de funciones",
  "ccprovision.recorddrive.config.sftp.accountpassword": "Contrase\u00F1a del servidor SFTP",
  "provision.tenant.management.tenantid": "ID del inquilino",
  "ccprovision.recorddrive.config.sftp.account": "Cuenta SFTP",
  "provision.spinfo.addspinfofailed": "Error al agregar informaci\u00F3n del SP.",
  "provision.callcent.label.close": "Cerrar",
  "provision.tenantspace.feature.emailMarketingSupport": "Marketing por correo electr\u00F3nico",
  "chat.webconfig.label.bandingskill": "Cola de habilidades de enlace",
  "provision.callcent.error.updatesipserver.ipandport.alreadyexist": "La IP del servidor SBC y el n\u00FAmero de puerto ya existen y no se pueden modificar.",
  "chat.agent.label.online": "En l\u00EDnea",
  "provision.tenantspace.save.fail.123004": "La cola de habilidades hace referencia a los elementos de habilidad y el nombre no se puede modificar.",
  "provision.tenantspace.save.fail.123003": "Los elementos de habilidad son referenciados por la cola de habilidades y no se pueden eliminar.",
  "provision.tenantspace.save.fail.123002": "El elemento de habilidad no existe.",
  "provision.tenantspace.intelligent.agent.description": "Agente inteligente\: admite la comunicaci\u00F3n b\u00E1sica del canal\, el an\u00E1lisis sem\u00E1ntico de texto y la recomendaci\u00F3n inteligente. Configure el n\u00FAmero de agentes antes de configurar agentes inteligentes. Configure los recursos de ASR para agentes inteligentes que admiten sesiones de voz.",
  "provision.tenantspace.agentinfo.message.audio": "audio",
  "provision.tenantspace.save.fail.123001": "Nombre duplicado del elemento de habilidad.",
  "provision.tenantspace.reset": "Restablecer",
  "provision.tenantspace.config.select.vitual.ccname": "Introduzca el nombre del centro de llamadas virtual.",
  "provision.softPhone.table.forcemodifyconfim.message": "Esta operaci\u00F3n restablecer\u00E1 la contrase\u00F1a del softphone. Para cambiar la contrase\u00F1a\, debe iniciar sesi\u00F3n en el espacio del inquilino. \u00BFEst\u00E1s seguro de que quieres continuar?",
  "provision.tenantspace.option.weeklist_tidtolast": "\u00DAltimos pero dos",
  "chat.timerange.label.customizes": "Modificado para requisitos particulares",
  "provision.tenantspace.config.select.whiteRepeate": "Duplicar el n\u00FAmero de lista de confianza.",
  "provision.tenantspace.create.basicInfo": "Informaci\u00F3n b\u00E1sica",
  "provision.title.select": "Elegir",
  "chat.client.greeting.info": "\u00BFQu\u00E9 puedo hacer por ti?",
  "provision.callcenter.videoapplicationmodel.mcuAndsfuModel": "V\u00EDdeo MCU+SFU",
  "provision.tenantspace.feature.cobrowse.selectcobrowsevalue.invalid": "El n\u00FAmero m\u00E1ximo de conexiones de exploraci\u00F3n conjunta oscila entre 1 y 50000.",
  "provision.tenantspace.feature.forceVideoSelfRecord.attendant": "Habilitado para todos los asistentes",
  "provision.tenantspace.selectStastus.edit.stop": "Suspender",
  "provision.tenantspace.table.maxAgents": "M\u00E1x. Agentes de voz",
  "provision.tenantspace.create.tenantExpiryDate": "Tiempo de caducidad del inquilino",
  "provision.tenantspace.authenticationsystem.selectClientName": "Seleccione un sistema de autenticaci\u00F3n de terceros.",
  "chat.session.label.subChannelTime": "subChannelTime Canal",
  "provision.tenantspace.config.chooseAnonymousCallServer": "Seleccionar un servidor de llamadas an\u00F3nimoa",
  "provision.spinfo.msg.certificateformatinfo": "Los identificadores inicial y final -----BEGIN CERTIFICATION--- y ----- FIN CERTIFICATION--- no se pueden omitir.",
  "provision.callcenterinstanceslist.sipServer.webRtcanonymousCall": "Llamada an\u00F3nima WebRTC",
  "chat.agentConsole.message.error.emptyMessage": "[El mensaje no contiene contenido que se pueda mostrar.]",
  "provision.tenantspace.config.choosedatasource": "Seleccione un origen de datos OBS.",
  "promotion.tenant.management.tenantedit.vcccapacity.insufficient": "La capacidad del centro virtual es insuficiente.",
  "provision.tenantspace.ExpireTenantSpaceCount": "N\u00FAmero de inquilinos que est\u00E1n a punto de caducar en un plazo de 30 d\u00EDas",
  "provision.callcenterinstanceslist.config.maxAmsCallNumsValidate": "El n\u00FAmero m\u00E1ximo de llamadas an\u00F3nimas simult\u00E1neas no puede ser menor que el n\u00FAmero de llamadas an\u00F3nimas asignadas por el centro de llamadas.",
  "provision.tenantspace.popuppageurl.tableheadname": "Direcci\u00F3n",
  "provision.tenantspace.dataUpload.resourceDump": "Volcado de recursos",
  "provision.tenantspace.selectStastus.resource.allocation.failed": "Error al asignar el recurso",
  "provision.systemparam.error.validate.notmatchrulerequired": "Se requiere el par\u00E1metro de entrada",
  "chat.client.label.header": "Tel\u00E9fono +",
  "chat.weChatConfig.label.verifycode": "T\u00E9rminos y condiciones",
  "provision.tenantspace.agentinfo.agentstatus.offline": "fuera de l\u00EDnea",
  "provision.log.downloadSK": "Descargar SK",
  "provision.tenant.management.updatetenant.success": "La configuraci\u00F3n del espacio del inquilino se modifica correctamente.",
  "chat.skill.message.saveFail": "Error al guardar la habilidad.",
  "provision.tenantspace.chooseobsdatasource.error1": "Seleccione un servidor OBS.",
  "provision.encryptinfo.mode": "Modo",
  "chat.session.channel.mobile": "Terminal m\u00F3vil",
  "provision.tenantspace.config.agentusage.ivr": "Cantidad de agente de voz\, Cantidad de agente de video\, Cantidad de agente multimedia\, Cantidad de agente vers\u00E1til\, Cantidad de canal de audio IVR y cantidad de canal de video IVR no pueden ser cero",
  "chat.weChatConfig.label.facebook": "Facebook",
  "provision.callcenterinstanceslist.sipServer.sipServerType": "Tipo de servidor SBC",
  "provision.accesscode.table.endNumber": "C\u00F3digo de acceso final",
  "provision.systemparam.error.validate.notmatchrulerangelength": "El par\u00E1metro de entrada excede la longitud m\u00E1xima o est\u00E1 por debajo de la longitud m\u00EDnima",
  "provision.tenantspace.config.worknoassign.availableworknocount": "N\u00FAmeros disponibles",
  "provision.tenant.management.DSTStartTime": "Hora de inicio del horario de verano",
  "ccprovision.IVRSprogress.config.button.delete": "Borrar",
  "provision.callcenterinstanceslist.table.nodemoderesource": "Modo de recursos",
  "provision.tenantspace.feature.VoiceNotificationSupport.description": "Esta caracter\u00EDstica proporciona funciones de notificaci\u00F3n por voz para el espacio del inquilino.",
  "provision.tenantspace.sip.port": "Puerto del servidor SIP",
  "provision.tenantspace.config.worknoassign.add": "A\u00F1adir",
  "provision.tenantspace.config.datasource": "OBS como fuente de datos",
  "provision.tenantspace.config.softphoneassign": "Asignar n\u00FAmero de softphone",
  "provision.callcent.error.accesscode.deletefail.ondatabase": "Error al eliminar el c\u00F3digo de acceso en la base de datos",
  "provision.tenantspace.create.account": "Informaci\u00F3n de la cuenta",
  "provision.tenantspace.save.oldsuccess": "La informaci\u00F3n de zona horaria no se modifica.",
  "provision.tenant.management.tenantcreate.mobileillegal": "N\u00FAmero de tel\u00E9fono m\u00F3vil no v\u00E1lido.",
  "chat.timerange.label.oneday": "\u00DAltimo d\u00EDa",
  "provision.tenantspace.chooseCC": "Seleccione un centro de llamadas",
  "provision.tenantspace.timezone": "Zona horaria predeterminada",
  "provision.spinfo.selecdeletetspinfo": "Seleccione la informaci\u00F3n del SP que desea eliminar.",
  "provision.tenantspace.selectStastus.config": "Para ser configurado",
  "provision.tenantspace.ivr.ivrServer": "Direcci\u00F3n IP",
  "chat.agent.title.close": "Cerrar",
  "provision.callcent.error.savevccfirst": "El agente no est\u00E1 vinculado al servidor de registro. Guarde el VDN de nuevo.",
  "provision.tenantspace.feature.multiChannelSupport.description": "La funci\u00F3n de soporte multicanal est\u00E1 habilitada. El ajuste temporal de la cola se puede realizar para los agentes a trav\u00E9s de los canales de servicio. Nota\: Si un agente tiene el permiso de canal de servicio cruzado\, el agente ocupa recursos de voz\, v\u00EDdeo y multimedia al mismo tiempo durante el inicio de sesi\u00F3n. El agente bajo un inquilino no puede iniciar sesi\u00F3n en el servicio de atenci\u00F3n al cliente debido a la preferencia de recursos.",
  "provision.callcenterinstanceslist.label.videoCallnumUseRate": "Uso de videollamadas simult\u00E1neas",
  "provision.callcenterinstanceslist.softPhone.accountpassword": "contrase\u00F1a de cuenta",
  "provision.systemparam.error.validate.inner": "Error al validar el par\u00E1metro",
  "chat.agent.label.customerSource": "Fuente del cliente\: App m\u00F3vil - Centro personal",
  "provision.callcent.selectsipserver": "Seleccione un servidor de registro de agente.",
  "provision.tenantspace.config.maxVideoCalls": "Cantidad de videollamadas simult\u00E1neas",
  "provision.callcent.error.deleteucsserver.part.associationvcallcener": "Algunos servidores CCUCS seleccionados est\u00E1n asociados con inquilinos y no se pueden eliminar.",
  "provision.callcenterinstanceslist.config.updatefailed": "Error al modificar el centro de llamadas.",
  "chat.skill.message.createFail": "Error al crear la habilidad.",
  "chat.session.title.history": "Registros hist\u00F3ricos del chat",
  "provision.callcenterinstanceslist.softPhone.tenantname": "Nombre del inquilino",
  "provision.tenantspace.timezone.updateconfim": "Confirmar",
  "provision.tenantspace.table.deleteTenantSpace": "\u00BFEst\u00E1 seguro de que desea eliminar el espacio de inquilino especificado?",
  "provision.tenantspace.feature.intelligent.inspection.description": "Habilite la funci\u00F3n de inspecci\u00F3n inteligente y habilite la funci\u00F3n de inspecci\u00F3n inteligente para el espacio del inquilino.",
  "provision.sysparam.config.itemdesc": "Descripcion",
  "provision.callcenterinstanceslist.vcallcenter.invalidVdnListPromt": "Hay VDNs en el WAS donde falta el centro de llamadas virtual en el AICC. El VdnID es",
  "provision.tenantspace.feature.speechAnalysis": "An\u00E1lisis de texto",
  "provision.tenantspace.feature.speechAnalysis.description": "La funci\u00F3n de an\u00E1lisis de texto est\u00E1 habilitada. La sala de inquilinos admite el an\u00E1lisis de texto y voz.",
  "provision.tenantspace.step7": "Paso 7",

  "provision.tenantspace.step6": "Paso 6",

  "provision.tenantspace.step5": "Paso 5",
  "provision.tenantspace.step3": "Paso 3",
  "provision.callcenterinstanceslist.obsServer.obsServerIpSync": "Sincronizaci\u00F3n",
  "provision.tenantspace.step4": "Paso 4",
  "chat.client.transfer.link": "\u00BFEst\u00E1 seguro de que desea transferir la llamada a un agente?",
  "provision.tenantspace.cc.videoAgentchannels": "Cantidad de canal de agente de v\u00EDdeo",
  "provision.tenantspace.step2": "Paso 2",
  "provision.tenant.chooseCC.first": "Seleccione un centro de llamadas.",
  "provision.tenant.management.tenantcreate.contact": "M\u00E9todo de contacto",
  "provision.tenantspace.cc.ttschannelnums": "TTS Cantidad",
  "provision.tenantspace.config.select.default.whiteNumber": "El n\u00FAmero de n\u00FAmeros listados de confianza no puede ser mayor que 5 por defecto.",
  "provision.tenantspace.step1": "Paso 1",
  "provision.tenantspace.cc.percent": "Proporci\u00F3n de capacidad",
  "provision.callcent.error.saveucsserveripinfo": "Error de creaci\u00F3n\, aseg\u00FArese de que la direcci\u00F3n IP CCUCS y el n\u00FAmero de puerto del servidor no existen.",
  "provision.whiteListAddress.resourcedump.obs": "Servidor OBS de volcado de recursos",
  "provision.callcenterinstanceslist.config.updatesuccess": "El centro de llamadas se modifica con \u00E9xito.",
  "chat.skill.label.agents": "Agente",
  "provision.tenantspace.feature.emailAgentSendLocalAttachment": "Env\u00EDo de archivos adjuntos locales por agentes de correo electr\u00F3nico",
  "provision.tenantspace.deleteWhiteNumber.confirm": "confirmar eliminaci\u00F3n",
  "provision.callcenterinstanceslist.obsServer.activeObsServerIp": "Ip de servidor de obs activo",
  "provision.callcenterinstanceslist.vcallcenterDeatil.passLengthValidateFailed": "Una contrase\u00F1a contiene un m\u00EDnimo de ocho caracteres.",
  "provision.callcenterinstanceslist.vcallcenter.InProgress": "En curso.",
  "provision.tenantspace.ucs.name": "Nombre del servidor CCUCS",
  "provision.tenantspace.config.gw": "Servidor de puerta de enlace",
  "provision.tenantspace.detail.tenantspace.status.not.change": "El inquilino est\u00E1 en el estado inactivo y no puede ser modificado.",
  "provision.callcenterinstanceslist.table.create": "Nuevo",
  "provision.tenantspace.feature.emailAgentForwarding": "Reenv\u00EDo de agentes de correo",
  "provision.tenantspace.modifyTimeZoneVerification": "La informaci\u00F3n de zona horaria solo puede modificarse para un espacio de inquilino en el estado comercial o comercial de prueba.",
  "provision.callcenterinstanceslist.label.amsCallNumUseRate": "Uso de llamadas an\u00F3nimas simult\u00E1neas",
  "provision.encryptinfo.modedecrease": "El modo CBC es menos seguro que el modo GCM. Tenga cuidado al seleccionar el modo CBC.",
  "provision.callcent.error.createaccesscode.validate.servicenumberContainServicenumber": "El n\u00FAmero de servicio no puede tener una relaci\u00F3n de inclusi\u00F3n con otros n\u00FAmeros de servicio bajo el centro de llamadas.",
  "provision.sysparam.config.item.detail": "Informaci\u00F3n de par\u00E1metros",
  "provision.tenantspace.table.asrchannelnums": "Cantidad ASR",
  "provision.callcenterinstanceslist.vcallcenter.vdnName": "Nombre de VDN",
  "chat.weChatConfig.message.codeNameExisted": "El c\u00F3digo de acceso al canal ya existe.",
  "provision.ivr.selectivrprogress.singledeleteconfim": "\u00BFEst\u00E1 seguro de que desea eliminar el proceso de IVR seleccionado?",
  "provision.tenantspace.option.weekday_fri": "Viernes",
  "provision.callcenterinstanceslist.vcallcenter.UCSServer.title": "Configuraci\u00F3n del servidor CCUCS",
  "chat.session.label.servedAgent": "Agente de servicio",
  "provision.callcenterinstanceslist.vcallcenter.accessCodeConfig": "Configuraci\u00F3n de AccessCode",
  "provision.callcenterinstanceslist.vcallcenter.maxMediaAgents": "MaxMediaAgents",
  "provision.callcenterinstanceslist.config.title": "Configuraci\u00F3n del centro de llamadas",
  "provision.tenantspace.table.maxAmsCalls": "M\u00E1x. llamadas an\u00F3nimas simult\u00E1neas",
  "provision.tenantspace.agentusage.plus.videousage.plus.maxVersatileAgents": "El n\u00FAmero total de agentes de voz\, v\u00EDdeo y vers\u00E1tiles no puede superar los 10000.",
  "promotion.tenant.management.tenantedit.vcc.notexist": "El centro de llamadas virtual no existe.",
  "provision.callcent.error.updatesipserver.fail.syncccmanagement": "Error al sincronizar el servidor SBC para modificar la informaci\u00F3n a la habitaci\u00F3n de alquiler.",
  "provision.tenantspace.accesscode.searchName": "Por favor\, introduzca el c\u00F3digo de acceso que desea consultar.",
  "provision.tenant.management.agentnum": "Agentes",
  "provision.callcenterinstanceslist.obsServer.config.explain": "El valor debe ser el mismo que la configuraci\u00F3n OBS en la plataforma CTI.",
  "chat.skill.label.edit": "Editar",
  "provision.tenantspace.feature.open": "Activar",
  "provision.callcent.error.savesipserverdesinfo.regAgentNumError": "El n\u00FAmero de agentes registrados simult\u00E1neos modificados es menor que el n\u00FAmero real de asientos registrados.",
  "provision.tenantspace.common.moveagent.explain": "El sistema asigna un n\u00FAmero de softphone al inquilino. El sistema del inquilino utiliza el n\u00FAmero de softphone para registrarse en el servidor del sistema AICC. El sistema del inquilino invoca la interfaz abierta proporcionada por el sistema AICC para implementar la funci\u00F3n de devoluci\u00F3n de llamada bidireccional. (N\u00FAmero m\u00E1ximo de agentes m\u00F3viles asignables \= cantidad de canal ivr de audio / 2\, redondear hacia abajo).",
  "chat.skill.label.save": "Guardar",
  "provision.tenantspace.feature.close": "Desactivar",
  "provision.tenantspace.table.invalidTenantSpace.confirm": "Confirmar la revocaci\u00F3n",
  "provision.tenantspace.config.select.record.time": "Seleccione un per\u00EDodo de retenci\u00F3n de grabaci\u00F3n.",
  "provision.ccp.redis.feature.success": "Los datos de la caracter\u00EDstica del inquilino se ejecutan correctamente.",
  "chat.weChatConfig.stepname.selectmode": "Seleccionar m\u00E9todo de configuraci\u00F3n",
  "provision.callcenterinstanceslist.table.ccName": "Nombre del centro de llamadas",
  "provision.callcent.error.updateccgateway.regAgentNumError": "Error de actualizaci\u00F3n\, el n\u00FAmero de asientos registrados modificados es menor que el n\u00FAmero real de asientos asignados.",
  "provision.tenantspace.save.fail.120006": "N\u00FAmero m\u00E1ximo de subprocesos.",
  "provision.callcenterinstanceslist.vcallcenter.maxAgents": "MaxAgents",
  "provision.tenantspace.config.maxVideoAgents": "Cantidad de agente de video",
  "provision.tenantspace.save.fail.120003": "La configuraci\u00F3n no existe.",
  "provision.tenantspace.save.fail.120005": "Error en la verificaci\u00F3n del elemento de configuraci\u00F3n.",
  "provision.tenantspace.save.fail.120002": "La configuraci\u00F3n ya existe.",
  "provision.tenantspace.save.fail.120004": "Error en la operaci\u00F3n porque el n\u00FAmero de VDN habilitados alcanza el m\u00E1ximo.",
  "provision.tenantspace.save.fail.120000": "Error de interfaz.",
  "provision.tenantspace.save.fail.120001": "El n\u00FAmero m\u00E1ximo de lotes es de 500.",
  "provision.tenantspace.feature.dualCallTransparentDisplaySupport": "Anonimizaci\u00F3n del n\u00FAmero de llamada",
  "ccp.version.microservice": "Microservicio",
  "provision.tenantspace.selectStastus.invalid": "Desactivado",
  "provision.systemparam.error.validate.notmatchrule": "El par\u00E1metro de entrada no coincide con la regla de verificaci\u00F3n",
  "provision.tenantspace.feature.multiChannelSupport": "Soporte multicanal",
  "provision.callcenterinstanceslist.sipServer.tenantname": "Nombre del inquilino",
  "ccp.version.updateTime": "UpdateTime",
  "chat.webconfig.message.saveSuccess": "Se ha guardado la configuraci\u00F3n correctamente.",
  "provision.callcenterinstanceslist.obsServer.standbyObsServerIp": "Ip del servidor obs en espera",
  "chat.usefulexpressions.label.createExpressionTab": "Crear frase com\u00FAn",
  "provision.tenantspace.error.deleteConfigTenantSpace": "Se han asignado recursos al espacio del inquilino. No se puede eliminar el espacio del inquilino.",
  "chat.agent.label.money": "Importe",
  "chat.weChatConfig.label.configId": "ID de configuraci\u00F3n",
  "provision.callcenterinstanceslist.config.warning": "Advertencia",
  "provision.spinfo.deletespinfosuccess": "La informaci\u00F3n del SP se ha eliminado correctamente.",
  "provision.tenantspace.agentinfo.message.batchdeleteconfim": "\u00BFEst\u00E1 seguro de que desea eliminar todos los agentes seleccionados?",
  "provision.spinfo.validatehttp": "El formato de URL es incorrecto.",
  "provision.tenantspace.modifyPassword": "Restablecer contrase\u00F1a",
  "provision.tenantspace.obsServer.datasource.type": "Tipo de origen de datos",
  "provision.ccworknos.table.ccworkQuantity": "N\u00FAmero Cantidad en el segmento de n\u00FAmero",
  "provision.tenantspace.config.intelligent.agentNumber.not.exceed.agentNumber": "El n\u00FAmero de agentes inteligentes asignados a un inquilino no puede ser mayor que el n\u00FAmero total de agentes.",
  "provision.callcent.error.specialStrValidateFailed": "El nombre obs no puede contener caracteres especiales.",
  "provision.tenantspace.feature.emailMarketingSupport.description": "Esta caracter\u00EDstica proporciona funciones de marketing por correo electr\u00F3nico para el espacio del inquilino.",
  "provision.tenantspace.feature.whatsAppMarketingSupport.description": "Proporciona funciones de marketing de WhatsApp para espacios de inquilinos.",
  "provision.tenantspace.feature.whatsAppMarketingSupport": "Marketing por WhatsApp",
  "provision.tenantspace.config.param.error.ivrNumber": "Ingrese el n\u00FAmero de canales IVR.",
  "chat.skill.label.createSkillTab": "Crear Habilidad",
  "provision.callcenterinstanceslist.table.gwPort": "Puerto de pasarela CC",
  "provision.callcent.error.updateccgateway.ccgatewayServernoexist": "Error de actualizaci\u00F3n\, CC-Gateway Server no existe.",
  "provision.tenantspace.selectvideomodel.isnull": "Seleccione un modo de aplicaci\u00F3n de v\u00EDdeo para el recurso de v\u00EDdeo aplicado por el inquilino.",
  "chat.common.message.warning": "Advertencia",
  "provision.tenantspace.feature.forceVideoSelfRecord.settingError": "Los par\u00E1metros de grabaci\u00F3n de pantalla est\u00E1n configurados incorrectamente.",
  "provision.tenant.management.positiveInteger.checkmsg": "Escriba un entero positivo.",
  "provision.callcenterinstanceslist.table.maxAmsCallNums": "M\u00E1x. llamadas an\u00F3nimas simult\u00E1neas",
  "provision.tenantspace.agentinfo.message.versatile": "Vers\u00E1til",
  "provision.tenantspace.worknoValidatefaild": "El WorkNo es obligatorio.",
  "provision.accesscode.table.accessCode.clicktodial": "haga clic para marcar",
  "provision.callcenterinstanceslist.config.videoAgentCurCapacityValidate": "El n\u00FAmero de asientos de v\u00EDdeo es menor que el n\u00FAmero de asientos de v\u00EDdeo realmente asignados en el centro de llamadas.",
  "provision.tenantspace.option.weeklist_fth": "Cuarto",
  "provision.callcent.error.updatesipserver.ondatabase": "Error al modificar el servidor SBC en la base de datos.",
  "provision.callcenterinstanceslist.vcallcenter.plateformServer": "Servidor de plataforma",
  "provision.appversionmanagement.cannotContainSpecialCharacters": "El valor no puede contener los siguientes caracteres especiales\: ~\!{'@'}\#$%&* ()/_\=+{}\:'<>? []",
  "provision.callcent.error.accesscode.deletefail": "Error al eliminar\, la entrada est\u00E1 vac\u00EDa",
  "provision.accesscode.table.tenantname": "Tenant",
  "chat.skill.message.completeInputs": "Por favor complete todas las entradas.",
  "provision.tenantspace.accessCode.choose": "Seleccione un grupo de c\u00F3digos de acceso.",
  "provision.tenantspace.cc.videoAgentchannelUsagePercent": "Uso del canal del agente de v\u00EDdeo",
  "provision.callcent.error.deletesipserver.associationvcallcener": "El servidor SIP seleccionado est\u00E1 asociado al inquilino y no se puede eliminar.",
  "chat.client.label.privacyHintContent": "Para mejorar la calidad del servicio\, su conversaci\u00F3n ser\u00E1 grabada por el sistema. \u00BFEst\u00E1s seguro de que quieres seguir hablando?",
  "provision.callcenterinstanceslist.obsServer.batchdeleteconfim": "\u00BFEst\u00E1 seguro de que desea eliminar todos los servidores obs de registro seleccionados?",
  "provision.tenantspace.search": "Consultar",
  "provision.callcenterinstanceslist.config.alreadyinuse": "Se ha utilizado el segmento de ID de empleado.",
  "chat.agent.title.userMenu": "Men\u00FA del cliente",
  "provision.callcent.error.partSetinfo": "Error al seleccionar el conjunto de piezas.",
  "provision.tenant.management.batchcreate": "Los espacios del inquilino se crean en lotes.",
  "chat.client.label.evaluation": "Encuesta de satisfacci\u00F3n",
  "provision.callcenterinstanceslist.sipServer.sipServerIpUpdate": "Configuraci\u00F3n del servidor SIP",
  "provision.tenant.management.select.accesscode": "Seleccione el c\u00F3digo de acceso",
  "provision.callcenterinstanceslist.vcallcenter.maxVideoCalls": "MaxVideoCalls",
  "provision.callcenterinstanceslist.sipServer.webRTC": "WebRTC",
  "provision.tenantspace.selectStastus.CommercialUse": "Uso comercial",
  "provision.tenantspace.common.agent.number": "Cantidad de agente inteligente",
  "provision.callcent.selectamsserver.inconsistenttype": "El tipo de servidor de llamadas an\u00F3nimas no es consistente",
  "chat.timerange.label.alldays": "Todo el tiempo de creaci\u00F3n",
  "provision.spinfo.modifyspinfofailed": "Error al modificar la informaci\u00F3n del SP.",
  "provision.whitepopuppageurl.protocol.check": "Tenga en cuenta que el protocolo de red utilizado por la direcci\u00F3n emergente no es un protocolo seguro. Si decide utilizar este protocolo\, pueden existir riesgos de seguridad.",
  "provision.callcenterinstanceslist.vcallcenterDeatil.wasPsdLengthRangeValidateFailed": "La longitud de la contrase\u00F1a debe estar entre 16 y 32",
  "provision.tenantspace.config.softphone.Validatefaild": "N\u00FAmero de softphone no asignado",
  "provision.callcenterinstanceslist.vcallcenter.maxVideoAgents": "MaxVideoAgents",
  "provision.callcenterinstanceslist.vcallcenterDeatil.kafkaPwdLength": "La longitud de la contrase\u00F1a debe estar entre 6 y 32",
  "provision.callcent.error.savesipserveripinfo": ". Aseg\u00FArese de que la direcci\u00F3n IP y el n\u00FAmero de puerto del servidor no existen.",
  "provision.tenantspace.cc.callnumUsagePercent": "Uso de llamadas de voz simult\u00E1neas",
  "provision.callcenter.videoapplicationmodel": "Modo de aplicaci\u00F3n de v\u00EDdeo",
  "provision.sysparam.config.select.prompt": "Sugerencias",
  "provision.tenantspace.feature.forceVideoSelfRecord.description": "Indica si se debe registrar autom\u00E1ticamente el estado de la operaci\u00F3n de pantalla cuando un agente procesa una llamada.",
  "provision.tenantspace.table.activeTenantSpace.confirm": "Confirmar activaci\u00F3n",
  "provision.tenantspace.detail.expiry.time": "Tiempo de caducidad",
  "provision.callcentaccesscodes.table.callcentaccesscodes": "C\u00F3digo de acceso",
  "provision.tenantspace.tenantid": "ID de alquiler actual.",
  "provision.tenant.management.tenantcreate": "Nuevo Inquilino",
  "provision.callcenterinstanceslist.config.agentCurCapacityValidate": "El n\u00FAmero de agentes de voz es menor que el n\u00FAmero de asientos de voz realmente asignados por el centro de llamadas.",
  "provision.tenantspace.resetpwdfaild.accountpassword.error": "La contrase\u00F1a de la cuenta actual es incorrecta.",
  "provision.callcenterinstanceslist.vcallcenterDeatil.saveerror": "Error al guardar.",
  "provision.callcent.selectaccesscode": "Seleccione un c\u00F3digo de acceso.",
  "provision.callcenterinstanceslist.config.odfsconfig": "Configuraci\u00F3n del servidor ODFS",
  "provision.callcent.error.deleteucsserver.associationvcallcener": "El servidor CCUCS seleccionado est\u00E1 asociado con el inquilino y no se puede eliminar.",
  "provision.appversionmanagement.sureDeleteData": "\u00BFEst\u00E1 seguro de que desea eliminar los datos?",
  "provision.tenantspace.save.fail.120663": "El agente hace referencia a la configuraci\u00F3n llamada y no se puede eliminar.",
  "provision.tenantspace.save.fail.120662": "La cola de habilidades hace referencia a la configuraci\u00F3n llamada y no se puede eliminar.",
  "provision.tenantspace.save.fail.120661": "La cola de habilidades privadas predeterminada hace referencia a la configuraci\u00F3n llamada y no se puede eliminar.",
  "provision.tenantspace.save.fail.120660": "El c\u00F3digo de acceso al sistema configurado por la parte llamada no es consistente con el tipo de medios del dispositivo de configuraci\u00F3n llamado.",
  "ccprovision.recorddrive.config.sftp.accountconfirmpasd": "Confirmar contrase\u00F1a del servidor SFTP",
  "chat.webconfig.label.transHuman": "Traslado al Agente",
  "chat.weChatConfig.label.AppSecret": "Contrase\u00F1a del desarrollador",
  "provision.tenantspace.feature.smsOrEmailMarketingSupport.sendMaxNumber.alert": "El n\u00FAmero m\u00E1ximo de mensajes que se pueden enviar todos los d\u00EDas oscila entre 1 y 1000000000. El valor 0 indica que no se especifica ning\u00FAn l\u00EDmite.",
  "provision.tenantspace.gw.ServerIP": "IP del servidor de puerta de enlace",
  "provision.tenantspace.feature.smsOrEmailMarketingSupport.sendMaxNumber.whatsApp.alert": "El n\u00FAmero m\u00E1ximo de mensajes que se pueden enviar todos los d\u00EDas oscila entre 1 y 100000. El valor 0 indica que no se especifica ning\u00FAn l\u00EDmite.",
  "chat.skill.label.delete": "Borrar",
  "provision.tenantspace.feature.smsOrEmailMarketingSupport.sendMaxNumber.whatsApp.placeholder": "El valor var\u00EDa de 1 a 100000. El valor 0 indica que no se ha especificado ning\u00FAn l\u00EDmite.",
  "provision.tenant.management.timezone": "Zona horaria",
  "provision.tenantspace.save.fail.120659": "El script de enrutamiento al que hace referencia la configuraci\u00F3n llamada no existe.",
  "provision.tenantspace.save.fail.120658": "El tipo de dispositivo es IVR\, y el tipo de medio del c\u00F3digo de acceso solo puede ser hacer clic para llamar\, solicitud de devoluci\u00F3n de llamada y llamada normal.",
  "provision.callcenterinstanceslist.config.ccIdUpdateError": "No cambie el ID del centro de llamadas al editar el centro de llamadas",
  "provision.callcenterinstanceslist.vcallcenter.SIPServer.title": "Configuraci\u00F3n del servidor SBC",
  "provision.encryptinfo.version": "Versi\u00F3n",
  "provision.tenantspace.feature.agenttype": "Tipo de agente",
  "provision.tenantspace.save.fail.120656": "La cola de habilidades a la que hace referencia la configuraci\u00F3n llamada no existe.",
  "provision.tenantspace.save.fail.120652": "La combinaci\u00F3n del c\u00F3digo de acceso al sistema y los c\u00F3digos de acceso subsiguientes configurados por la parte llamada no se puede repetir.",
  "provision.tenantspace.intelligent.ivr.explain": "IVR inteligente\: Adem\u00E1s de la funci\u00F3n IVR tradicional\, IVR de navegaci\u00F3n inteligente que admite reconocimiento de voz y comprensi\u00F3n sem\u00E1ntica.",
  "provision.tenantspace.config.chooseworkNo": "Selecciona WorkNo",
  "provision.tenantspace.save.fail.120655": "El c\u00F3digo de acceso al que hace referencia la configuraci\u00F3n llamada no existe.",
  "provision.tenantspace.save.fail.120651": "El c\u00F3digo de acceso al sistema configurado por la parte llamada no est\u00E1 asignado.",
  "provision.tenantspace.feature.ivrtype": "Tipo IVR",
  "provision.appversionmanagement.pleaseEnter": "- Entrar-",
  "provision.callcenterinstanceslist.table.hasaccesscodeAndivrConfim": "La eliminaci\u00F3n falla. Hay un c\u00F3digo de acceso o IVR en el centro de llamadas. Necesitas eliminarlo manualmente.",
  "provision.callcenterinstanceslist.obsServer.obsServerIpAdd": "A\u00F1adir",
  "provision.callcent.error.updateaccesscode.fail.accesscodenoexist": "\, Compruebe si el c\u00F3digo de acceso existe y est\u00E1 asociado a un centro de llamadas virtual.",
  "chat.skill.label.description": "Descripcion",
  "chat.session.endType.overtimeEnd": "Fin debido al tiempo de espera",
  "provision.callcenterinstanceslist.table.ctinodesec": "Informaci\u00F3n del nodo 2 de CTI",
  "provision.callcent.error.savesipserverdesinfo.maxRegAgentNumError": ". El n\u00FAmero de agentes registrados simult\u00E1neos modificados es menor que el n\u00FAmero real de asientos registrados.",
  "chat.skill.message.error": "Error",
  "provision.callcenterinstanceslist.callcenter": "Centro de llamadas",
  "provision.tenant.management.tenantcreate.provision.failed": "Error al crear el espacio local del inquilino.",
  "chat.weChatConfig.message.error": "Error",
  "provision.tenantspace.option.weekday_sat": "S\u00E1bado",
  "provision.grayconfig.tenantHasConfig": "El espacio del inquilino se ha configurado",
  "provision.callcent.error.addcallcenterdesinfo": "Aseg\u00FArese de que el centro de llamadas no existe.",
  "provision.log.downloadIdpMetadtaXml": "Descargar Metadatos IDP Xml",
  "provision.tenantspace.Conversioncommercial": "Conversi\u00F3n Comercial",
  "chat.usefulexpressions.label.expression": "Frases comunes",
  "provision.callcenterinstanceslist.ucsServer.batchdeleteconfim": "\u00BFEst\u00E1 seguro de que desea eliminar todos los servidores CCUCS seleccionados?",
  "provision.tenantspace.config.giveSource": "Asignar recurso",
  "provision.callcenterinstanceslist.softPhone.passValidateFailed": "Debe contener al menos 3 de letras may\u00FAsculas\, min\u00FAsculas\, n\u00FAmeros\, caracteres especiales ~{'@'}^*_+.?.",
  "provision.tenantspace.sfuprompt": "La conexi\u00F3n de integraci\u00F3n del sistema no est\u00E1 adaptada SFU V\u00EDdeo\, que se puede utilizar para el desarrollo secundario de los clientes",
  "provision.callcenter.videoapplicationmodel.mcumodel": "V\u00EDdeo de MCU",
  "provision.tenantspace.feature.cobrowse.description": "Esta caracter\u00EDstica proporciona la funci\u00F3n de co-navegaci\u00F3n para el espacio del inquilino.",
  "ccprovision.recorddrive.config.sftp.identitychecktips.placeholder": "Ingrese su cuenta actual y contrase\u00F1a.",
  "provision.callcenter.videoapplicationmodel.sfumodel": "V\u00EDdeo de SFU",
  "provision.callcent.error.modifyParamUnique": "El ObsServerIp activo existe.",
  "provision.tenantspace.feature.customData.description": "Una vez activada la funci\u00F3n de personalizaci\u00F3n de datos\, el espacio del inquilino admite la funci\u00F3n de personalizaci\u00F3n de datos.",
  "provision.callcent.error.savesipserverdesinfo.sipServernoexist": "\, servidor sbc no existe",
  "provision.ivr.selectivrprogress.batchdeleteconfim": "\u00BFEst\u00E1 seguro de que desea eliminar todos los procesos IVR seleccionados?",
  "chat.weChatConfig.message.appIdExisted": "El ID de desarrollador de cuenta p\u00FAblica introducido ya existe.",
  "chat.usefulexpressions.message.isExisted": "El nombre de la frase com\u00FAn ya existe.",
  "provision.accesscode.table.callout": "Llamada saliente",
  "provision.tenantspace.intelligent.agent.explain": "Agente inteligente\: Adem\u00E1s de la funci\u00F3n b\u00E1sica de control de voz\, es compatible con la recomendaci\u00F3n inteligente de voz a texto en tiempo real y relacionada. Para configurar los agentes inteligentes\, primero debe configurar los agentes de voz y los recursos ASR.",
  "provision.sysparam.config.select.null": "Seleccione un par\u00E1metro.",
  "chat.agent.label.balance": "Ingresos y Gastos",
  "provision.tenantspace.feature.case.open.confirmmsg": "La funci\u00F3n AppCube es la base para ejecutar la funci\u00F3n Case y se activar\u00E1 al mismo tiempo.",
  "chat.client.label.password": "Contrase\u00F1a",
  "provision.tenantspace.feature.newcase.open.customcenter.confirmmsg": "Las funciones de AppCube y Customer Center son la base de la funci\u00F3n Case 2.0 y se habilitar\u00E1n al mismo tiempo",
  "chat.weChatConfig.label.verifying": "Comprobaci\u00F3n",
  "provision.recorddrive.error.cannotdelete.occupyvdn": "No se puede eliminar la letra de unidad asignada al inquilino",
  "provision.tenantspace.table.batchcreate": "Creaci\u00F3n de lotes",
  "provision.tenantspace.common.agent": "Agente ordinario",
  "provision.tenantspace.save.fail.107-086": "No se configura ning\u00FAn prefijo de llamada para un n\u00FAmero en el UAP.",
  "provision.tenantspace.save.fail.107-087": "El n\u00FAmero de registro UAP no est\u00E1 registrado.",
  "provision.tenantspace.save.fail.107-085": "No se puede identificar el tipo de producto UAP.",
  "provision.tenantspace.table.maxVersatileAgents": "M\u00E1x. Agentes vers\u00E1tiles",
  "provision.tenantspace.save.fail.107-084": "El UAP no obtiene el tipo de producto.",
  "provision.tenantspace.save.fail.107-082": "Error al iniciar sesi\u00F3n en el UAP o se desconecta del UAP.",
  "ccprovision.vcallecenter.config.popup.curcountpassword": "Contrase\u00F1a de cuenta actual",
  "provision.tenantspace.save.fail.107-083": "El UAP no puede enviar el comando MML.",
  "provision.tenantspace.ucs.searchName": "Introduzca el nombre del servidor CCUCS que desea consultar.",
  "provision.tenantspace.save.fail.107-081": "El UAP no puede verificar el permiso de VDN porque el VDN no contiene el n\u00FAmero de usuario.",
  "provision.callcent.platformserver.identitychecktips": "Ha cambiado la contrase\u00F1a del servidor. Para garantizar la seguridad de la informaci\u00F3n\, ingrese su cuenta y contrase\u00F1a actuales.",
  "provision.grayconfig.tenantSpaceId": "TenantSpaceId",
  "provision.tenantspace.save.fail.107-080": "El n\u00FAmero de n\u00FAmeros UAP excede el l\u00EDmite.",
  "provision.callcenterinstanceslist.table.ctinodefir": "Informaci\u00F3n del nodo CTI",
  "provision.tenantspace.config.choose.agent.registration.server": "Servidor de registro de agente",

  "provision.tenantspace.resetpwdfaild.newPwd.information.is.wrong": "Error al modificar\,La informaci\u00F3n de nueva contrase\u00F1a es incorrecta",
  "provision.tenantspace.resetpwdfaild.newpassword.not.meet.passwordrule": "La nueva contrase\u00F1a no cumple con la regla de contrase\u00F1a.",
  "provision.tenantspace.save.fail.120624": "La contrase\u00F1a de acoplamiento vdn modificada es la misma que la contrase\u00F1a anterior.",
  "provision.systemparam.error.validate.notmatchrulenumber": "Por favor\, introduzca un n\u00FAmero",
  "provision.tenantspace.save.fail.120622": "El representante del negocio es el l\u00EDder del equipo y no puede realizar operaciones de cambio de grupo.",
  "provision.tenantspace.save.fail.120621": "La contrase\u00F1a debe contener al menos tres de los cuatro caracteres siguientes\: may\u00FAsculas en ingl\u00E9s\, min\u00FAsculas en ingl\u00E9s\, n\u00FAmeros y caracteres no alfanum\u00E9ricos\, y no pueden ser los mismos que el n\u00FAmero de cuenta o el orden inverso del n\u00FAmero de cuenta.",
  "provision.tenantspace.save.fail.120620": "La longitud de la contrase\u00F1a del agente es menor que la longitud m\u00EDnima de la contrase\u00F1a para la configuraci\u00F3n de seguridad\: {0}.",
  "provision.tenantspace.table.delete": "Borrar",
  "promotion.tenant.management.tenantedit.vdncreate.failed": "Error al crear o modificar la informaci\u00F3n de VDN.",
  "provision.systemparam.error.validate.notmatchruleoption": "El par\u00E1metro de entrada no est\u00E1 en el rango de enumeraci\u00F3n",
  "provision.tenantspace.save.fail.107-079": "El n\u00FAmero final del UAP es menor que el n\u00FAmero inicial.",
  "provision.callcent.error.portoutsiderange": "HeartbeatPort est\u00E1 fuera del rango de 1 a 65535.",
  "chat.weChatConfig.label.appId": "ID de desarrollador",
  "provision.tenantspace.save.fail.107-077": "No se admite el tipo de direcci\u00F3n IP UAP.",
  "chat.client.message.gladtoServe": ". \u00BFQu\u00E9 puedo hacer por ti?",
  "provision.tenantspace.save.fail.107-076": "La contrase\u00F1a UAP no es coherente con la contrase\u00F1a de confirmaci\u00F3n.",
  "provision.tenantspace.save.fail.107-078": "La longitud del n\u00FAmero inicial y el n\u00FAmero final en el UAP es inconsistente.",
  "provision.tenantspace.save.fail.107-073": "La direcci\u00F3n IP UAP es nula.",
  "provision.tenantspace.save.fail.107-075": "La longitud de la contrase\u00F1a UAP est\u00E1 fuera de rango.",
  "provision.tenantspace.save.fail.107-072": "N\u00FAmero de usuario UAP no v\u00E1lido.",
  "provision.tenantspace.save.fail.107-071": "El n\u00FAmero de usuario de UAP es nulo.",
  "provision.tenantspace.feature.emailAgentCCAndBcc": "Agente de correo electr\u00F3nico CC Bcc",
  "provision.tenantspace.save.fail.107-070": "El UAP del sistema no puede ser cancelado.",
  "chat.thirdportal.title": "Portal Web de Clientes",
  "chat.webconfig.title": "Habilitar Canal Web",
  "provision.tenantspace.save.fail.120618": "El n\u00FAmero de trabajo de representante comercial y el n\u00FAmero de trabajo de l\u00EDder de escuadr\u00F3n se duplican.",
  "provision.tenantspace.option.weeklist_sec": "En segundo lugar",
  "chat.agent.label.goAway": "Lejos",
  "provision.tenantspace.save.fail.120619": "El ID de representante de ventas no se puede repetir.",
  "chat.agent.label.total": "En total",
  "chat.weChatConfig.label.tenantId": "ID del inquilino",
  "provision.tenantspace.save.fail.107-074": "La contrase\u00F1a UAP es nul",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.required": "Introduzca el segmento de ID de agente que desea agregar y haga clic en Aceptar.",
  "provision.tenantspace.save.fail.120616": "La configuraci\u00F3n llamada a la que hace referencia el agente no existe.",
  "provision.tenantspace.save.fail.120615": "El proceso al que hace referencia el agente no existe.",
  "provision.grayconfig.tenantSpaceName": "NombreEspaciotenant",
  "provision.tenantspace.save.fail.120614": "El otro representante comercial citado por el representante comercial no existe.",
  "provision.tenantspace.save.fail.120613": "El rol de agente al que hace referencia el agente no existe.",
  "provision.tenantspace.config.asrchannelnums": "Cantidad ASR",
  "provision.tenantspace.save.fail.120612": "El grupo de habilidades al que hace referencia el agente no existe.",
  "provision.tenantspace.save.fail.120611": "La cola de habilidades a la que hace referencia el agente no existe.",
  "provision.tenantspace.feature.moveAgents": "Agente m\u00F3vil",
  "chat.agentConsole.message.content.image": "[Mensaje de imagen]",
  "provision.tenantspace.save.fail.120610": "El equipo de aplicaci\u00F3n del agente no existe.",
  "provision.tenantspace.save.fail.120617": "La letra de unidad a la que hace referencia el agente no existe.",
  "provision.tenantspace.save.fail.120972": "El tipo de submedio al que hace referencia la cola de habilidades no existe.",
  "provision.tenantspace.save.fail.120970": "El dispositivo compartido hace referencia a la cola de habilidades y no se puede eliminar.",
  "provision.callcenterinstanceslist.config.error": "Error",
  "chat.weChatConfig.label.save": "Guardar",
  "provision.tenantspace.save.fail.107-069": "El UAP no consulta el estado de registro.",
  "provision.tenantspace.save.fail.107-068": "Error al eliminar el usuario multimedia UAP.",
  "provision.tenantspace.save.fail.107-067": "El usuario multimedia UAP no puede cambiar la contrase\u00F1a.",
  "provision.tenantspace.save.fail.107-066": "Excepci\u00F3n de configuraci\u00F3n de UAP",
  "provision.tenantspace.save.fail.120971": "Referencia del grupo de habilidades de la cola de habilidades\, no se puede eliminar.",
  "provision.tenantspace.save.fail.107-064": "Error al agregar el usuario multimedia UAP.",
  "provision.tenantspace.save.fail.107-065": "Error al consultar el usuario multimedia UAP.",
  "chat.client.label.privacyHint": "Advertencia para la protecci\u00F3n de la privacidad",
  "provision.appversionmanagement.forceUpdate": "Actualizaci\u00F3n por la fuerza",
  "provision.tenantspace.agentinfo.message.selectagenttype": "Seleccione un tipo de agente",
  "provision.callcenterinstanceslist.vcallcenterDeatil.specialStrValidateFailed": "El nombre del centro de llamadas no puede contener caracteres especiales.",
  "provision.tenantspace.save.fail.120609": "Los representantes comerciales son referenciados por otros representantes comerciales y no pueden ser eliminados.",
  "provision.callcenterinstanceslist.config.ivrnumsError": "El n\u00FAmero de canales IVR debe ser mayor que la suma del n\u00FAmero de canales TTS y el n\u00FAmero de canales ASR.",
  "chat.skill.message.selectSkills": "Selecciona una habilidad.",
  "provision.tenantspace.agentinfo.message.searchworkno": "Ingrese el n\u00FAmero de agente",
  "provision.tenantspace.save.fail.120608": "La cola de habilidades hace referencia al agente y no se puede eliminar.",
  "provision.tenantspace.save.fail.120968": "La velocidad de conexi\u00F3n m\u00EDnima de la cola de habilidades no puede exceder la velocidad de conexi\u00F3n esperada.",
  "provision.tenantspace.save.fail.120607": "La cola de habilidades privadas predeterminada hace referencia al agente y no se puede eliminar.",
  "provision.tenantspace.save.fail.120606": "El proceso hace referencia al agente y no se puede eliminar.",
  "provision.tenantspace.save.fail.120969": "ACDDN hace referencia a la cola de habilidades y no se puede eliminar.",
  "provision.callcenterinstanceslist.vcallcenterDeatil.odfsPasswordKeyLengthValidateFailed": "La longitud no puede exceder los 50 caracteres.",
  "provision.tenantspace.save.fail.120967": "El tipo de medio de la cola de habilidades es una llamada de fax y se debe configurar un buz\u00F3n.",
  "provision.callcenterinstanceslist.config.success": "\u00C9xito",
  "provision.tenantspace.save.fail.120966": "La letra de unidad a la que hace referencia la cola de habilidades no existe.",
  "provision.tenantspace.save.fail.120605": "El representante de ventas es el l\u00EDder del equipo y no se puede eliminar.",
  "provision.tenantspace.save.fail.120604": "El agente es el administrador de VDN predeterminado y no se puede eliminar.",
  "provision.tenantspace.save.fail.120965": "La configuraci\u00F3n llamada hace referencia a la cola de habilidades y no se puede eliminar.",
  "provision.tenantspace.save.fail.120603": "El administrador predeterminado de VDN no puede modificar el rol.",
  "chat.timerange.label.thirtydays": "\u00DAltimos 30 d\u00EDas",
  "ccprovision.IVRSprogress.config.curcallNo": "Cantidad de llamadas simult\u00E1neas",
  "provision.tenantspace.save.fail.120964": "El proceso hace referencia a la cola de habilidades y no se puede eliminar.",
  "provision.tenantspace.save.fail.120602": "El administrador predeterminado de VDN no puede cambiar la contrase\u00F1a.",
  "provision.callcenterinstanceslist.config.voicecallnumsError": "El n\u00FAmero m\u00E1ximo de llamadas de voz simult\u00E1neas debe ser mayor o igual a la suma del n\u00FAmero de agentes de voz y el n\u00FAmero de canales IVR de audio y v\u00EDdeo.",
  "provision.tenantspace.save.fail.120601": "El administrador predeterminado de VDN no puede modificar el nombre.",
  "provision.tenantspace.save.fail.120963": "Los representantes empresariales hacen referencia a las colas de habilidades y no se pueden eliminar.",
  "provision.tenantspace.save.fail.120962": "El grupo de clientes hace referencia a la cola de habilidades y no se puede eliminar.",
  "provision.callcent.error.updateinfo": "Error al actualizar.",
  "provision.callcent.platformserver.isales": "iSales",
  "provision.tenantspace.save.fail.120960": "La cola de habilidades es referenciada por otras colas de habilidades y no se puede eliminar.",
  "provision.tenantspace.config.IVR": "Proceso IVR",
  "provision.tenantspace.save.fail.120961": "La CDN hace referencia a la cola de habilidades y no se puede eliminar.",
  "provision.callcent.error.deleteobsserver.associationvcallcener": "El n\u00FAmero de servidor obs seleccionado est\u00E1 asociado con el inquilino y no se puede eliminar.",
  "provision.tenantspace.config.SIP": "Servidor SBC",
  "chat.session.label.skillQueueName": "Nombre de la cola de habilidades",
  "provision.tenantspace.config.error": "Error en la configuraci\u00F3n. Compruebe las configuraciones relacionadas o p\u00F3ngase en contacto con el administrador.",
  "chat.common.message.success": "\u00C9xito",
  "provision.tenantspace.save.fail.120959": "La cola de habilidades privada predeterminada hace referencia a la cola de habilidades privada y no se puede eliminar.",
  "provision.tenantspace.option.weeklist_fir": "En primer lugar",
  "provision.tenantspace.save.fail.120958": "La configuraci\u00F3n llamada a la que hace referencia la cola de habilidades no existe.",
  "provision.tenantspace.save.fail.120957": "El proceso al que hace referencia la cola de habilidades no existe.",
  "provision.tenantspace.save.fail.120956": "El agente al que hace referencia la cola de habilidades no existe.",
  "provision.callcenterinstanceslist.softPhone.endNumber": "N\u00FAmero final",
  "provision.tenantspace.save.fail.120955": "La cola de habilidades a la que hace referencia la cola de habilidades no existe.",
  "provision.tenantspace.status.invalid.success": "La revocaci\u00F3n se realiz\u00F3 correctamente.",
  "provision.tenantspace.save.fail.120954": "El tipo de servicio al que hace referencia la cola de habilidades no existe.",
  "provision.tenantspace.save.fail.120953": "Los elementos de habilidad en la cola de habilidades se duplican.",
  "provision.callcent.platformserver.ccfs": "CCFS",
  "provision.appversionmanagement.updateTime": "Tiempo de actualizaci\u00F3n",
  "provision.callcenterinstanceslist.table.deleteConfimMessage": "\u00BFEst\u00E1 seguro de que desea eliminar el centro de llamadas seleccionado y la configuraci\u00F3n relacionada?",
  "provision.encryptinfo.updateTime": "Actualizado en",
  "provision.callcenterinstanceslist.config.ccIdError": "Se ha utilizado el n\u00FAmero del centro de llamadas.",
  "provision.tenantspace.save.fail.107-045": "El VDN del sistema elimina el c\u00F3digo de excepci\u00F3n donde VDNID y VDNName no coinciden.",
  "chat.webconfig.label.windowTitle": "T\u00EDtulo de la ventana de chat",
  "provision.tenantspace.save.fail.107-044": "Excepci\u00F3n de error de eliminaci\u00F3n de VDN del sistema.",
  "provision.tenantspace.save.fail.107-043": "Excepci\u00F3n de error de consulta de VDN del sistema.",
  "provision.tenantspace.save.fail.107-042": "Excepci\u00F3n de error de modificaci\u00F3n de VDN del sistema.",
  "provision.tenantspace.save.fail.107-040": "Excepci\u00F3n de error de eliminaci\u00F3n del proceso de servicio IVR.",
  "provision.tenantspace.save.fail.107-041": "Error al agregar el VDN del sistema.",
  "provision.authconfig.description.empty.error": "La descripci\u00F3n no puede estar vac\u00EDa.",
  "provision.callcent.label.closeFaule": "CloseFault",
  "provision.tenantspace.table.tenantSpaceName": "Espacio para inquilinos",
  "provision.tenantspace.config.fail.modifydata.tenant.feature.inDatabase": "La base de datos no pudo modificar la informaci\u00F3n del elemento del inquilino",
  "provision.sysparam.config.save": "Guardar",
  "provision.callcenterinstanceslist.vcallcenterDeatil.whiteNumValidateFailed": "Solo se permiten d\u00EDgitos.",
  "provision.tenantspace.table.invalidTenantSpace": "Esta operaci\u00F3n no se puede cancled despu\u00E9s de la revocaci\u00F3n\, por favor confirme si el inquilino necesita ser revocado\!",
  "provision.tenantspace.cc.videoAgentCurCapacity": "Cantidad de agente de video",
  "provision.tenantspace.save.fail.107-039": "Excepci\u00F3n de error de consulta de proceso de servicio IVR.",
  "provision.tenantspace.save.fail.107-038": "Excepci\u00F3n de error de modificaci\u00F3n del proceso de servicio IVR.",
  "provision.callcent.platformserver.port": "N\u00FAmero de puerto de servicio",
  "provision.tenantspace.save.fail.107-037": "El proceso de servicio IVR aumenta la excepci\u00F3n de falla.",
  "provision.tenantspace.save.fail.107-036": "Excepci\u00F3n de error de eliminaci\u00F3n de c\u00F3digo de acceso al sistema.",
  "provision.callcenterinstanceslist.label.asrchannelUseRate": "Uso de ASR",
  "provision.tenantspace.save.fail.107-035": "La consulta de c\u00F3digo de acceso al sistema falla de forma anormal.",
  "provision.tenantspace.save.fail.107-034": "Excepci\u00F3n de error de modificaci\u00F3n del c\u00F3digo de acceso al sistema.",
  "provision.spinfo.idpfile": "Archivo IDP",
  "provision.tenantspace.save.fail.107-033": "El fallo de adici\u00F3n de c\u00F3digo de acceso al sistema es anormal.",
  "provision.callcenterinstanceslist.table.videoAgentCurCapacity": "Cantidad de agente de video",
  "provision.systemparam.error.save": "Error al guardar par\u00E1metros",
  "provision.tenantspace.save.fail.107-032": "Excepci\u00F3n de error de protocolo de enlace de suscripci\u00F3n.",
  "provision.tenantspace.agentUsage.iszero": "Configure primero el agente de voz y los recursos de ASR.",
  "provision.tenantspace.save.fail.107-031": "Excepci\u00F3n de fallo de cancelaci\u00F3n de suscripci\u00F3n.",
  "provision.accesscode.table.multimedia": "Multimedia",
  "provision.tenantspace.save.fail.107-030": "Excepci\u00F3n de suscripci\u00F3n.",
  "chat.client.greeting.afternoon": "\u00A1Buenas tardes\!",
  "provision.callcent.error.errorinfo": "Error de creaci\u00F3n.",
  "ccprovision.recorddrive.config.recorddrivename": "Nombre de letra de unidad",
  "provision.tenantspace.config.softphoneassign.tip": "Ingrese el segmento de n\u00FAmero de softphone.",
  "provision.tenantspace.authenticationsystem.clientName": "Sistema de autenticaci\u00F3n de terceros",
  "provision.tenantspace.save.fail.107-029": "Excepci\u00F3n de tiempo de espera de solicitud de suscripci\u00F3n.",
  "provision.tenantspace.save.fail.107-028": "Excepci\u00F3n de error de solicitud de suscripci\u00F3n.",
  "provision.tenantspace.save.fail.107-027": "Excepci\u00F3n de error de registro de suscripci\u00F3n.",
  "provision.callcenterinstanceslist.vcallcenter.maxVersatileAgents": "Agentes vers\u00E1tiles",
  "provision.tenantspace.feature.selectAppCubeSupport.description": "Habilite la funci\u00F3n AppCube para que el espacio del inquilino pueda integrar la funci\u00F3n AppCube.",
  "provision.tenantspace.save.fail.107-026": "Excepci\u00F3n de error de suscripci\u00F3n.",
  "provision.tenantspace.save.fail.107-025": "El nombre del archivo de proceso IVR est\u00E1 vac\u00EDo.",
  "provision.callcent.error.deleteobsserverdesinfo": "Aseg\u00FArese de que el nombre del servidor obs no existe.",
  "provision.tenantspace.save.fail.107-024": "Excepci\u00F3n de error de consulta de proceso IVR.",
  "provision.callcenterinstanceslist.table.ctiversion": "Versi\u00F3n CTI",
  "provision.callcenterinstanceslist.vcallcenter.asrchannelnums": "ASRchannelnums",
  "provision.tenantspace.save.fail.107-023": "Excepci\u00F3n de error de consulta de VDN.",
  "provision.tenantspace.save.fail.107-021": "Se llama excepci\u00F3n con error de eliminaci\u00F3n.",
  "provision.tenantspace.save.fail.107-022": "Excepci\u00F3n de error de modificaci\u00F3n de VDN.",
  "provision.tenantspace.chooseobsdatasource.thirdPartyDbSource": "Fuentes de datos de terceros",
  "provision.tenantspace.save.fail.107-020": "La consulta llamada fall\u00F3 anormalmente.",
  "provision.appversionmanagement.sureDeleteVersionNumber": "\u00BFEst\u00E1 seguro de que desea eliminar la informaci\u00F3n de la versi\u00F3n?",
  "provision.tenantspace.cc.agentCurCapacity": "Cantidad de agente usado",
  "provision.tenantspace.config.amsNumber.positiveInteger": "El n\u00FAmero de llamadas an\u00F3nimas simult\u00E1neas no puede estar vac\u00EDo y debe ser un entero positivo.",
  "provision.tenantspace.save.fail.107-019": "La modificaci\u00F3n llamada fall\u00F3 anormalmente.",
  "provision.softphone.label.issyncuap.no": "NO",
  "provision.tenantspace.agentinfo.message.video": "Videos",
  "provision.callcenterinstanceslist.config.interfacepassword": "Contrase\u00F1a de ConfigInterface de CTI",
  "provision.tenantspace.save.fail.107-018": "Se llama excepci\u00F3n de error de aumento.",
  "provision.tenantspace.save.fail.107-017": "Excepci\u00F3n de error de eliminaci\u00F3n de roles.",
  "provision.tenantspace.save.fail.107-016": "Excepci\u00F3n de error de consulta de rol.",
  "provision.tenantspace.save.fail.107-015": "Excepci\u00F3n de error de modificaci\u00F3n de rol.",
  "provision.tenantspace.save.fail.107-013": "Excepci\u00F3n de error de eliminaci\u00F3n del agente.",
  "provision.systemparam.error.validate.notmatchrulemaxlength": "El par\u00E1metro de entrada excede la longitud m\u00E1xima",
  "provision.tenantspace.save.fail.107-011": "La modificaci\u00F3n de agente o la modificaci\u00F3n de agente por lotes encontr\u00F3 una excepci\u00F3n.",
  "provision.tenantspace.save.fail.107-012": "La consulta del agente es anormal.",
  "provision.tenantspace.save.fail.107-010": "La adici\u00F3n de agentes o la adici\u00F3n de agentes en lotes encontr\u00F3 una excepci\u00F3n.",
  "provision.tenantspace.status.stop.fail": "Error al suspender.",
  "provision.tenantspace.timezone.updatemessage": "La modificaci\u00F3n de la informaci\u00F3n de zona horaria puede afectar al procesamiento del negocio durante el per\u00EDodo de transici\u00F3n.",
  "provision.tenant.management.timezoneOffset": "Desfase de zona horaria",
  "provision.tenantspace.save.fail.107-014": "El papel anormal aumenta el fracaso.",
  "provision.callcenterinstanceslist.vcallcenter.syncVccSuccess": "La sincronizaci\u00F3n est\u00E1 completa.",
  "provision.tenant.management.batchcreate.error.accesscodenumber": "Se permite un m\u00E1ximo de 100 c\u00F3digos de acceso.",
  "chat.agent.label.consumption": "Mi Consumo",
  "provision.systemparam.error.validate.ruleregexinvalid": "Error de sintaxis de regla de validaci\u00F3n",
  "provision.tenantspace.feature.agentSendLocalMediaFile": "Env\u00EDo de archivos multimedia locales por agentes",
  "provision.tenantspace.save.fail.107-007": "Error de autenticaci\u00F3n antes de la configuraci\u00F3n de la interfaz de configuraci\u00F3n.",
  "provision.tenantspace.status.active.success": "Activaci\u00F3n exitosa.",
  "provision.tenant.management.adminpassword": "Contrase\u00F1a",
  "provision.tenantspace.save.fail.107-005": "Error en la comunicaci\u00F3n de la interfaz de configuraci\u00F3n.",

  "provision.callcent.success.setinfo": "Establezca correctamente.",
  "provision.tenantspace.save.fail.107-004": "Cuenta de administrador de configuraci\u00F3n no v\u00E1lida.",
  "provision.tenantspace.save.fail.107-002": "El par\u00E1metro de entrada no es v\u00E1lido\, el campo requerido est\u00E1 vac\u00EDo.",
  "provision.tenantspace.save.fail.107-003": "Contrase\u00F1a de administrador de configuraci\u00F3n incorrecta\, bloquear la cuenta despu\u00E9s de reintentar muchas veces.",
  "provision.obs.sync.needDelete": "El siguiente OBS se ha vinculado a los inquilinos y debe estar manualmente desvinculado y luego sincronizado\:",
  "provision.tenantspace.config.vccName": "Nombre de VCC",
  "provision.tenantspace.save.fail.107-001": "Excepci\u00F3n lanzada al llamar a la interfaz de configuraci\u00F3n.",
  "provision.tenantspace.save.fail.107-000": "La informaci\u00F3n de inicio de sesi\u00F3n en cach\u00E9 no es v\u00E1lida\, es necesario volver a iniciar sesi\u00F3n en la autenticaci\u00F3n.",
  "chat.session.label.created_endTime": "Hora de finalizaci\u00F3n",
  "provision.accesscode.table.beginNumber": "Iniciar c\u00F3digo de acceso",
  "provision.tenantspace.feature.dualChannelRecSupport": "Grabaci\u00F3n de doble pista",
  "provision.tenantspace.save.fail.120905": "La letra de unidad a la que hace referencia el tipo de negocio no existe.",
  "provision.tenantspace.selectStastus.active": "Activado",
  "provision.tenantspace.save.fail.120904": "El tipo de negocio es referenciado por el tiempo de trabajo est\u00E1ndar y no se puede eliminar.",
  "provision.tenantspace.save.fail.120903": "La cola de habilidades hace referencia al tipo de negocio y no se puede eliminar.",
  "provision.callcenterinstanceslist.config.workNoError": "El ID de empleado inicial debe estar en el rango de 101 a 50000.",
  "chat.client.greeting.morning": "\u00A1Buenos d\u00EDas\!",
  "provision.tenantspace.save.fail.120902": "El proceso hace referencia al tipo de negocio y no se puede eliminar.",
  "provision.callcent.success.saveinfo": "Creado correctamente.",
  "provision.tenantspace.save.fail.120901": "Descripci\u00F3n duplicada del tipo de negocio.",
  "chat.common.message.error": "Error",
  "chat.usefulexpressions.message.createFail": "Error al crear la frase com\u00FAn.",
  "provision.accesscode.error.transfer.queryinterface": "Error en los datos de la plataforma de consulta.",
  "provision.tenant.management.tenantcreate.emailillegal": "Direcci\u00F3n de correo electr\u00F3nico no v\u00E1lida.",
  "provision.tenantspace.statusRecordTime.12Month": "12 meses",
  "provision.callcenterinstanceslist.sipServer.singledeleteconfim": "\u00BFEst\u00E1 seguro de que desea eliminar el servidor de registro seleccionado?",
  "provision.callcenterinstanceslist.vcallcenter.ivrchannels": "Canales AudioIVR",
  "provision.tenantspace.save.fail": "Error al guardar.",
  "provision.tenantspace.config.worknoassign.filternumber.error": "El n\u00FAmero excluido no cumple con los requisitos.",
  "provision.tenantspace.resetpwdfaild.user.is.locked": "Error al cambiar la contrase\u00F1a porque el usuario est\u00E1 bloqueado.",
  "provision.systemparam.error.refresh": "Error al actualizar el par\u00E1metro",
  "provision.tenant.management.tenantcreate.dds.failed": "Error al crear el espacio del inquilino en el DDS.",
  "chat.webconfig.label.greeting.placeholder": "Por favor\, introduzca el contenido del saludo que se muestra en la ventana de chat del cliente.",
  "provision.tenantspace.config.choose.anonymous.call.server.type": "Tipo de servidor de llamadas an\u00F3nimas",
  "provision.tenant.management.tenantname.alphavalidateFailed": "Existen caracteres no v\u00E1lidos. Solo se admiten (_) letras\, d\u00EDgitos y guiones bajos.",
  "provision.tenantspace.resetpwdfaild.user.not.exist": "Error al cambiar la contrase\u00F1a porque el usuario no existe.",
  "provision.callcenterinstanceslist.vcallcenter.invalidVccListPromt": "Hay centros de llamadas virtuales virtuales en la AICC donde falta el VDN en el WAS. El VccId es",
  "provision.tenantspace.table.ttschannelnums": "TTS Cantidad",
  "provision.tenantspace.chooseobsdatasource.platformDbSource": "Fuentes de datos de plataforma",
  "provision.callcent.error.paramNotavailable": "El par\u00E1metro de solicitud est\u00E1 vac\u00EDo.",
  "ccprovision.IVRSprogress.config.tenantname": "Tenant",
  "chat.weChatConfig.label.search": "Buscar",
  "provision.tenantspace.config.tenant.featurevalue.not.digits": "La informaci\u00F3n de funci\u00F3n asignada al inquilino no es v\u00E1lida.",
  "provision.callcenterinstanceslist.IVRSprogress.Title": "Configuraci\u00F3n del proceso IVR",
  "chat.skill.label.skillName": "Nombre",
  "provision.tenant.management.mobile": "N\u00FAmero de tel\u00E9fono m\u00F3vil",
  "ccprovision.vcallecenter.config.popup.adminpassword": "Cambiar la contrase\u00F1a del administrador de configuraci\u00F3n",
  "provision.callcenterinstanceslist.ccgateway.idleRegAgentNum": "Cantidad de agente inactivo",
  "provision.recorddrive.selectneed.delete.recorddrive": "Seleccione la letra de unidad que desea eliminar",
  "provision.tenant.management.batchcreate.error.accesscode.inadequacy": "El n\u00FAmero de c\u00F3digos de acceso no puede ser menor que el de los espacios del inquilino.",
  "provision.callcenterinstanceslist.config.systemAdmin": "ID de administrador de CTI",
  "provision.tenantspace.config.choose.anonymous.call.server": "Servidor de llamadas an\u00F3nimas",
  "provision.callcenterinstanceslist.softPhone.usedselect.isnotoccupied": "Sin usar",
  "provision.tenantspace.config.param.error.agentUsage": "Introduzca el n\u00FAmero de agentes de voz.",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.noempty": "La informaci\u00F3n del n\u00FAmero es obligatoria.",
  "provision.common.title.tipsInfo": "Informaci\u00F3n de contacto",
  "provision.tenantspace.config.agentUsage": "Agentes",
  "provision.callcent.error.saveccgateway.ipportinfo": "Error de creaci\u00F3n\, por favor confirme si el IP de la pasarela CC y el puerto ya existen.",
  "provision.tenantspace.ivr.idlechnNum": "Cantidad de llamada concurrente restante",
  "provision.callcenterinstanceslist.table.hasVcallcenterConfim": "El centro de llamadas que se ha configurado con el centro de llamadas virtual no se puede eliminar.",
  "provision.tenantspace.input.agentnum": "Establezca el n\u00FAmero de agentes que se van a aplicar.",
  "chat.session.label.alias": "Nombre del cliente",
  "provision.callcenterinstanceslist.table.nodemodesingle": "Modo \u00FAnico",
  "provision.callcenterinstance.accesscode.batchcreate.fail.processingtask": "Procesar la tarea de crear c\u00F3digos de acceso en lotes",
  "provision.tenantspace.config.isAutoAssignSoftPhone": "Si el n\u00FAmero de softphone se asigna autom\u00E1ticamente al VCC",
  "provision.tenantspace.detail.apply.name": "Solicitante",
  "provision.callcent.error.portValidateFailed": "HeartbeatPort no es el n\u00FAmero.",
  "chat.weChatConfig.message.deleteError": "Error al eliminar el elemento de configuraci\u00F3n.",
  "provision.callcenterinstanceslist.config.adminWnFrom": "ID de empleado de inicio de administrador",
  "chat.weChatConfig.message.savesuccess": "Se ha accedido correctamente.",
  "provision.tenantspace.config.selectedworkNo": "ID de trabajo seleccionados {0} {1}",
  "provision.callcent.pool.select.different.amsservice.error": "Diferentes nodos deben seleccionar el mismo tipo de servidor de llamadas an\u00F3nimas.",
  "chat.client.alert.message.invildtoken": "El di\u00E1logo se agot\u00F3 el tiempo de espera o ha iniciado sesi\u00F3n en otro lugar. Cierre la ventana de chat e int\u00E9ntelo de nuevo. \u00A1Gracias\!",
  "provision.tenantspace.resetpwdfaild": "Error al restablecer la contrase\u00F1a.",
  "provision.callcent.error.updateccgateway.fail.syncccmanagement": "Error de actualizaci\u00F3n\, error al sincronizar CC-Gateway para modificar la informaci\u00F3n a la habitaci\u00F3n de alquiler.",
  "provision.tenantspace.dstEndDateVerification": "La fecha de finalizaci\u00F3n del horario de verano solo puede ser posterior a la fecha de inicio del horario de verano.",
  "provision.accesscode.table.callin": "Llamada entrante",
  "provision.callcenterinstanceslist.table.nodemodeselect": "Seleccionar modo",
  "provision.tenant.management.daylightSavingTime": "DST",
  "provision.tenantspace.config.anonymous.call.number": "N\u00FAmero de llamadas an\u00F3nimas simult\u00E1neas",
  "provision.tenantspace.config.softphoneassign.softphonescope.inadequacy": "El n\u00FAmero de softphone disponible asignado es insuficiente.",
  "provision.callcent.error.updateucsserver.ipandport.alreadyexist": "La IP del servidor CCUCS y el n\u00FAmero de puerto ya existen y no se pueden modificar.",
  "provision.callcent.error.updateccgateway.ipandport.alreadyexist": "Error en la actualizaci\u00F3n\, confirme si ya existe la IP y el puerto de la pasarela CC.",
  "provision.tenantspace.feature.outboundCallMarketingSupport.error": "Desactiva primero las funciones de SMS y email y WhatsApp marketing.",
  "provision.callcenterinstanceslist.config.wnFrom": "Iniciar ID de empleado",
  "provision.appversionmanagement.selectDataDeleted": "Seleccione los datos que desea eliminar.",
  "provision.tenantspace.feature.customData": "Personalizaci\u00F3n de datos",
  "provision.encryptinfo.encryptKey": "Clave de actualizaci\u00F3n",
  "provision.tenantspace.ivr.id": "No",
  "provision.callcenterinstanceslist.label.eMediaAgentCurCapacity": "Cantidad de Agente Multimedia",
  "chat.weChatConfig.link.wechat": "Servidor WeChat",
  "provision.tenantspace.cc.ivrchannelUsagePercent": "Uso del canal de audio IVR",
  "provision.tenantspace.config.chooseIVR": "Seleccione un proceso de IVR.",
  "chat.client.label.forgotPassword": "Olvid\u00E9 mi contrase\u00F1a",
  "provision.tenantspace.feature.smsMarketingSupport": "Mercadotecnia de SMS",
  "provision.tenantspace.input.searchName": "Introduzca el nombre del centro de llamadas que se va a consultar.",
  "provision.callcent.success.oj8k": "Completo",
  "chat.client.label.more": "M\u00E1s informaci\u00F3n",
  "provision.tenantspace.configureauthentication": "Configurar autenticaci\u00F3n",
  "provision.tenantspace.feature.cobrowse.needak": "Necesita configurar la informaci\u00F3n de AK antes de habilitar la funci\u00F3n de co-navegaci\u00F3n.",
  "provision.ivr.selectivrprogress": "Seleccione el proceso de IVR que desea eliminar.",
  "chat.skill.message.willDeleteSkills": "\u00BFEst\u00E1 seguro de que desea eliminar las habilidades seleccionadas?",
  "provision.encryptinfo.validityPeriod": "Per\u00EDodo de validez (mes)",
  "provision.callcenterinstanceslist.ucsServer.ucsServerIpUpdate": "Editar",
  "provision.appversionmanagement.androidH5": "Android H5",
  "provision.appversionmanagement.android": "Android",
  "provision.callcenterinstanceslist.table.videoIvrChannels": "Cantidad de canal de video IVR",
  "provision.appversionmanagement.iOSH5": "IOS H5",
  "provision.callcenterinstanceslist.config.odfsappkey": "AppKey de ODFS",
  "chat.skill.label.account": "Cuenta",
  "chat.skill.label.maxQueueNumber": "N\u00FAmero m\u00E1ximo de cola",
  "provision.callcenterinstanceslist.table.agentUseRate": "Uso del agente de voz",
  "chat.timerange.label.threedays": "\u00DAltimos 3 d\u00EDas",
  "chat.weChatConfig.label.Domain": "Direcci\u00F3n del servidor",
  "promotion.tenant.management.tenantedit.cc.null": "El ID del centro de llamadas al que pertenece el centro de llamadas virtual est\u00E1 vac\u00EDo.",
  "provision.tenantspace.select.stop": "Detenido",
  "provision.tenant.management.button.cancel": "Cancelar",
  "provision.sysparam.config.value": "Valor del par\u00E1metro",
  "chat.agent.title.createSkill": "Crear Habilidad",
  "provision.callcenterinstanceslist.config.passwordConfirm": "Confirmar contrase\u00F1a",
  "provision.encryptinfo.algorithm": "Algoritmo de cifrado",
  "provision.web.validator.ip": "Direcci\u00F3n IP no v\u00E1lida. (Ingrese una direcci\u00F3n IPv4 o IPv6\, o un nombre de dominio.)",
  "provision.sysparam.config.itemname": "Nombre del par\u00E1metro",
  "provision.callcenterinstanceslist.label.videoIvrChannelUseRate": "Uso del canal de video IVR",
  "chat.webconfig.label.language": "Idioma predeterminado",
  "provision.tenant.management.companyName": "Empresa",
  "provision.tenantspace.verification.fail": "Error al validar el formato de hora.",
  "chat.client.message.noAgent": "Lo siento\, el agente est\u00E1 ocupado. Int\u00E9ntalo de nuevo m\u00E1s tarde.",
  "provision.callcenterinstanceslist.softPhone.beginNumber": "N\u00FAmero de inicio",
  "provision.tenantspace.status.stop.success": "\u00C9xito.",
  "provision.tenant.management.tenantcreate.sum.failed": "Error al crear el espacio del inquilino en la SUMA.",
  "provision.tenantspace.config.agent.videoagent.ivr.more.zero": "El n\u00FAmero de agentes de voz\, agente de v\u00EDdeo\, agente multimedia\, canales de audio IVR y canales de v\u00EDdeo IVR no puede ser 0.",
  "provision.callcenterinstanceslist.softPhone.passwordconf": "Confirmar contrase\u00F1a",
  "provision.ccworknos.table.updateaccessCode": "Editar",
  "provision.callcenterinstanceslist.config.ivrchannelsValidate": "El n\u00FAmero de canales de audio IVR es menor que el n\u00FAmero de canales realmente asignados en el centro de llamadas.",
  "provision.callcenterinstanceslist.config.ccNameUsed": "Se ha utilizado el nombre del centro de llamadas.",
  "provision.sysparam.config.opterate": "Operaci\u00F3n",
  "provision.tenantspace.detail": "Detalles del inquilino",
  "provision.callcenterinstance.accesscode.validateaccesscoderange": "Se puede a\u00F1adir un m\u00E1ximo de 100 c\u00F3digos de acceso en lotes.",
  "provision.tenant.management.languagetype": "Tipo de idioma",
  "provision.tenantspace.config.param.error.asrNumber": "Por favor\, introduzca la cantidad ASR.",
  "chat.client.label.no": "No",
  "chat.webconfig.message.demodirective": "Tambi\u00E9n puede abrir la siguiente direcci\u00F3n en la ventana oculta del navegador Chrome para acceder a la p\u00E1gina de presentaci\u00F3n proporcionada por el sistema\:",
  "provision.callcenterinstanceslist.ccgatewayServer.batchdeleteconfim": "\u00BFEst\u00E1 seguro de que desea eliminar todos los servidores CC-Gateway seleccionados?",
  "provision.tenantspace.clear": "Limpiar",
  "chat.usefulexpressions.label.expressionDetail": "Detalles de la frase com\u00FAn",

  "chat.weChatConfig.label.wechat": "WeChat",
  "provision.appversion.msg.uploadfile.data.fail": "Los par\u00E1metros para cargar el archivo son incorrectos.",
  "provision.appversion.msg.uploadfile.type.fail": "El tipo de archivo cargado no cumple con los requisitos.",
  "provision.appversion.msg.uploadfile.1.fail": "Error al verificar el nombre de archivo cargado.",
  "provision.appversion.msg.uploadfile.3.fail": "Error al verificar el tama\u00F1o del archivo cargado. Compruebe el l\u00EDmite de tama\u00F1o del paquete de instalaci\u00F3n de la aplicaci\u00F3n m\u00F3vil en Configuraci\u00F3n > Configuraci\u00F3n de par\u00E1metros del sistema.",
  "provision.appversion.msg.uploadfile.0.success": "El archivo cargado se verifica correctamente.",
  "provision.appversion.msg.uploadfile.9.fail": "Error al verificar el archivo cargado.",
  "provision.appversion.msg.uploadfile.2.fail": "Error al verificar el tipo de archivo cargado.",
  "provision.appversion.msg.uploadfile.4.fail": "An\u00E1lisis de inyecci\u00F3n de archivos cargados",
  "provision.appversion.msg.process.fail": "Error al procesar la informaci\u00F3n de la versi\u00F3n de la aplicaci\u00F3n.",
  "provision.appversion.msg.uploadfile.fail": "Error al cargar el archivo. Por favor, cargue el archivo de nuevo.",
  "provision.appversion.msg.process.add": "Adici\u00F3n de informaci\u00F3n de la versi\u00F3n de la aplicaci\u00F3n",
  "provision.appversion.msg.process.modify": "Modificaci\u00F3n de la informaci\u00F3n de la versi\u00F3n de la aplicaci\u00F3n",
  "provision.appsitesmanagement.pleaseEnter": "Por favor ingrese",
  "provision.appsitesmanagement.batchDelete": "Eliminaci\u00F3n por lotes",
  "provision.appsitesmanagement.siteName": "Nombre del sitio",
  "provision.appsitesmanagement.siteDesc ": "Descripci\u00F3n del sitio",
  "provision.appsitesmanagement.new": "Nuevo",
  "provision.appsitesmanagement.siteAddr ": "Direcci\u00F3n del sitio",
  "provision.appsitesmanagement.videoAvailable ": "Implementaci\u00F3n de v\u00EDdeo",
  "provision.appsitesmanagement.creator ": "Creado por",
  "provision.appsitesmanagement.appcubeAvailable ": "Si se debe integrar AppCube",
  "provision.appsitesmanagement.modifier ": "Modificado por",
  "provision.appsitesmanagement.createTime ": "Tiempo de creaci\u00F3n",
  "provision.appsitesmanagement.sureSelectDataDeleted": "Seleccione los datos que desea eliminar",
  "provision.appsitesmanagement.sureDeleteData": "La eliminaci\u00F3n del sitio puede hacer que la aplicaci\u00F3n m\u00F3vil no pueda iniciar sesi\u00F3n. \u00BFEst\u00E1 seguro de que desea eliminar el sitio?",
  "provision.appsitesmanagement.sureBatchDeleteData": "La eliminaci\u00F3n de estos sitios puede hacer que la aplicaci\u00F3n m\u00F3vil no pueda iniciar sesi\u00F3n. \u00BFEst\u00E1 seguro de que desea eliminar estos sitios?",
  "provision.appsitesmanagement.queryFailed ": "Error de consulta",
  "provision.appsitesmanagement.modifyTime ": "Tiempo de modificaci\u00F3n",
  "provision.appsitesmanagement.cannotContainSpecialCharacters": "El valor no puede contener los siguientes caracteres especiales\: ~\!{'@'}\#$%&* ()/_\=+{}\:'<>? []",
  "provision.appsitesmanagement.details ": "Ver",
  "provision.appsitesmanagement.enable": "S\u00ED",
  "provision.appsitesmanagement.protocol.check": "Tenga en cuenta que la direcci\u00F3n del sitio introducida actualmente utiliza un protocolo de red que no es seguro. Si se utiliza\, puede haber riesgos de seguridad",
  "provision.appversionmanagement.appPackageTips": "Solo se admite la carga de archivos APK",
  "provision.appversion.msg.uploadfile.strem.fail": "Error al generar secuencias de archivos",
  "provision.callcent.packagefile.saveinfo": "Espera hasta que la p\u00E1gina actualice el resultado del procesamiento del paquete de versi\u00F3n.",
  "provision.callcent.packagefile.updateinfo": "Por favor, espere hasta que la p\u00E1gina actualice el resultado del procesamiento del paquete de versi\u00F3n.",
  "provision.appversion.msg.throttling.fail": "Cree o modifique el l\u00EDmite de tr\u00E1fico de la interfaz cada 2 minutos. Int\u00E9ntalo de nuevo m\u00E1s tarde.",
  "provision.appsitesmanagement.unable": "No",
  "provision.ccp.redis.packagefile.inprogress": "El paquete de versi\u00F3n se est\u00E1 cargando",
  "provision.ccp.redis.packagefile.success": "El paquete de versi\u00F3n se ha cargado correctamente",
  "provision.appversionmanagement.regVersion": "El n\u00FAmero de versi\u00F3n consta de letras\, d\u00EDgitos y puntos. No puede comenzar o terminar con un punto.",
  "provision.appversionmanagement.protocol.check": "Tenga en cuenta que la direcci\u00F3n de descarga introducida actualmente utiliza un protocolo de red que no es seguro. Si se utiliza\, puede haber riesgos de seguridad.",
  "provision.whiteListAddress.channel.push": "Direcci\u00F3n de inserci\u00F3n de mensaje de canal multimedia",
  "provision.whiteListAddress.downlink.message.apifabric.push": "Mensaje multimedia Direcci\u00F3n de estructura de API descendente",
  "provision.ccp.redis.packagefile.failed": "Error al cargar el paquete de versi\u00F3n",
  "provision.callcent.platformserver.kafka.switch.panel": "Indica si se debe habilitar el servicio",
  "provision.callcent.platformserver.account": "Cuenta de servicio",
  "provision.callcent.platformserver.kafka": "Middleware de mensajes (Kafka)",
  "provision.whiteListAddress.callout.push": "Direcci\u00F3n de env\u00EDo de resultados de llamadas salientes",
  "provision.whiteListAddress.internal.message.notification": "Direcci\u00F3n IP y puerto para invocar el servicio de aplicaciones para la notificaci\u00F3n de mensajes internos",

  "provision.callcent.platformserver.es": "Servicios de b\u00FAsqueda (ElasticSearch)",

  "provision.callcent.platformserver.certificateChoose": "Seleccionar certificado",
  "provision.tenantspace.feature.OneClickTwoCallSupport.featureParams.label": "Par\u00E1metros de llamada",
  "provision.tenantspace.feature.OneClickTwoCallSupport.featureParams.placeholder": "Establezca este par\u00E1metro en funci\u00F3n del n\u00FAmero de sesiones de IVR simult\u00E1neas\, n\u00FAmero de sesiones de red central simult\u00E1neas y n\u00FAmero de sesiones de l\u00EDnea simult\u00E1neas configuradas por el inquilino.",
  "provision.tenantspace.feature.OneClickTwoCallSupport.featureParams.callNums.label": "N\u00FAmero de datos de llamada (1 a 10)",
  "provision.tenantspace.feature.VoiceNotificationSupport.featureParams.label": "Par\u00E1metros de llamada",
  "provision.tenantspace.feature.VoiceNotificationSupport.featureParams.placeholder": "Establezca este par\u00E1metro en funci\u00F3n del n\u00FAmero de sesiones de IVR simult\u00E1neas\, n\u00FAmero de sesiones de red central simult\u00E1neas y n\u00FAmero de sesiones de l\u00EDnea simult\u00E1neas configuradas por el inquilino.",
  "provision.tenantspace.feature.VoiceNotificationSupport.featureParams.callNums.label": "N\u00FAmero de datos de llamada (1 a 10)",
  "provision.tenantspace.feature.OneClickTwoCallSupport.featureParams.callPeriod.label": "Periodo de llamada (1s a 60s)",
  "provision.tenantspace.feature.VoiceNotificationSupport.featureParams.callPeriod.label": "Periodo de llamada (1s a 60s)",
  "provision.subscribedetail.pageTitle": "Detalles de suscripci\u00F3n con un solo clic",

  "provision.subscribedetail.button.save": "Ahorra",
  "provision.subscribedetail.button.search": "Consultar",
  "provision.subscribedetail.button.reset": "Restablecer",
  "provision.subscribedetail.label.operate": "Operar",
  "provision.subscribedetail.button.delete": "Suprimir",
  "provision.subscribedetail.label.companyName": "Compa\u00F1\u00EDa",
  "provision.subscribedetail.button.update": "Editar",
  "provision.subscribedetail.label.contactName": "Nombre",
  "provision.subscribedetail.label.phoneNo": "Tel\u00E9fono",
  "provision.subscribedetail.label.createTime": "Solicitud",
  "provision.subscribedetail.label.companySize": "Tama\u00F1o",
  "provision.subscribedetail.label.enableStatus": "Suscripci\u00F3n",
  "provision.subscribedetail.success.title": "\u00C9xito",
  "provision.subscribedetail.label.emailStatus": "Env\u00EDo de correo",
  "provision.subscribedetail.label.remark": "Comentario",
  "provision.subscribedetail.success.save": "Se ha guardado correctamente.",
  "provision.subscribedetail.success.delete": "La eliminaci\u00F3n se realiz\u00F3 correctamente",
  "provision.subscribedetail.delete.title": "Confirmaci\u00F3n de eliminaci\u00F3n",
  "provision.subscribedetail.delete.confirm": "\u00BFEst\u00E1 seguro de que desea eliminar los detalles de la suscripci\u00F3n",
  "provision.subscribedetail.error.title": "Error",
  "provision.subscribedetail.error.save": "Error al guardar.",
  "provision.subscribedetail.error.delete": "Error al eliminar",
  "provision.subscribedetail.label.emailAddr": "Correo",
  "provision.subscribedetail.error.query.maxDay_30": "El intervalo de consulta no puede ser superior a 30 d\u00EDas",
  "provision.subscribedetail.error.query": "Error de consulta.",
  "provision.subscribedetail.enableStatus.value_0": "Error en la suscripci\u00F3n",
  "provision.subscribedetail.emailStatus.value_1": "Enviar correctamente",
  "provision.subscribedetail.emailStatus.value_0": "Error al enviar",
  "provision.subscribedetail.enableStatus.value_1": "Suscribirse correctamente",
  "provision.subscribedetail.validate.alphavalidate": "Existen caracteres no v\u00E1lidos. Solo se admiten (_) letras\, d\u00EDgitos y guiones bajos.",
  "provision.subscribedetail.validate.beginwithalpha": "debe comenzar con una letra.",
  "provision.subscribedetail.validate.laterThanEndTime": "La hora de inicio no puede ser posterior a la hora de finalizaci\u00F3n",

  "provision.subscribedetail.label.createSource": "Origen",
  "provision.subscribedetail.createSource.value_0": "Sitio web oficial de HUAWEI CLOUD",

  "provision.label.tenantexport.exportBeginTime": "Hora de inicio de exportaci\u00F3n",
  "provision.subscribedetail.createSource.value_1": "Sitio web oficial de la nube de software",
  "provision.label.tenantexport.popwindowmessage": "Introduzca la contrase\u00F1a para generar archivos comprimidos. La contrase\u00F1a debe contener letras\, d\u00EDgitos y caracteres especiales _ {'@'}%.",
  "provision.label.tenantexport.download": "Descargar",
  "provision.label.tenantexport.inputzippwd": "Introduzca la contrase\u00F1a de compresi\u00F3n.",
  "provision.label.tenantexport.refresh": "actualizaci\u00F3n",
  "provision.label.tenantexport.conditions": "Criterios de b\u00FAsqueda",
  "provision.label.tenantexport.viewExportTask": "Visualizaci\u00F3n de tareas de exportaci\u00F3n",
  "provision.label.tenantexport.passValidateFailed": "El valor debe contener letras\, d\u00EDgitos y caracteres especiales _ {'@'}%.",
  "provision.common.message.error": "Errores",
  "provision.label.tenantexport.importdata": "Importaci\u00F3n de datos",
  "provision.label.tenantexport.parainvalid": "Error al verificar la condici\u00F3n de exportaci\u00F3n.",
  "provision.label.tenantexport.confirmMessage": "\u00BFEst\u00E1 seguro de que desea crear una tarea de exportaci\u00F3n?",
  "provision.label.tenantexport.addExportTaskError": "Error al agregar la tarea de exportaci\u00F3n. Por favor\, revise el registro.",
  "provision.tenant.management.tenantexport.exceedlimit2": "\#*{'@'}$8\: acotar el intervalo de tiempo de los datos o a\u00F1adir condiciones de filtrado de datos.",
  "provision.label.tenantexport.unprocessed": "Sin procesar",
  "provision.tenant.management.tenantexport.exceedlimit1": "Se puede exportar un m\u00E1ximo de {0} registros de datos.",
  "provision.label.tenantexport.parainnull": "Seleccione los datos que desea exportar.",
  "provision.label.tenantexport.paracomplexity": "La complejidad de la contrase\u00F1a no cumple con los requisitos.",
  "provision.tenant.management.tenantexport.confirm": "Agradecimientos",
  "provision.label.tenantexport.exception": "Se produjo una excepci\u00F3n durante la generaci\u00F3n de tareas de exportaci\u00F3n. Por favor\, revise el registro.",
  "provision.tenant.management.tenantexport.cancel": "Anulaciones",
  "provision.label.tenantexport.addExportTaskSuccess": "La tarea de exportaci\u00F3n se agrega correctamente.",
  "provision.label.tenantexport.zippwd": "Contrase\u00F1a comprimida",
  "provision.tenant.management.tenantexport.addTaskError": "Error al agregar la tarea de exportaci\u00F3n. Por favor\, revise el registro.",
  "provision.tenant.management.tenantexport.addTask": "Creaci\u00F3n de una tarea de exportaci\u00F3n",
  "provision.label.tenantexport.success": "\u00C9xito",
  "provision.label.tenantexport.confirm": "Agradecimientos",
  "provision.tenantexport.download": "Descargar informaci\u00F3n del tenant",
  "provision.tenant.management.tenantexport.viewTask": "Consulta de tareas de exportaci\u00F3n",
  "provision.label.tenantexport.exportTaskStatus": "Estado de la tarea de exportaci\u00F3n",
  "provision.label.tenantexport.failed": "fallar",
  "provision.label.information": "Consejos",
  "provision.label.tenantexport.exportiong": "Exportaci\u00F3n",
  "provision.label.tenantexport.operation": "Operaci\u00F3n",
  "provision.tenant.management.tenantexport.addTaskSuccess": "La tarea de exportaci\u00F3n se agrega correctamente.",
  "provision.label.tenantexport.addExportTask": "Adici\u00F3n de una tarea de exportaci\u00F3n",
  "provision.common.message.success": "\u00C9xito",
  "provision.label.tenantexport.exportEndTime": "Hora de finalizaci\u00F3n de exportaci\u00F3n",
  "provision.tenantspace.feature.onlineCustomerServiceAssets": "Activos de servicio al cliente en l\u00EDnea",
  "provision.tenantspace.feature.appcube.close.error": "Antes de deshabilitar la funci\u00F3n AppCube\, aseg\u00FArese de que las funciones {0} est\u00E9n deshabilitadas",
  "provision.tenantspace.feature.dualCallAPPTransparentDisplaySupport.description": "Una vez activada la funci\u00F3n Tarea de llamada a la aplicaci\u00F3n\, los agentes de un tenant pueden procesar tareas de llamada en la aplicaci\u00F3n m\u00F3vil.",
  "provision.tenantspace.feature.onlineCustomerServiceAssets.description": "Despu\u00E9s de habilitar la funci\u00F3n de activos de servicio al cliente en l\u00EDnea\, los tenants pueden incrustar el cliente de chat en l\u00EDnea JS desarrollado por AppCube en p\u00E1ginas de terceros para implementar el chat en l\u00EDnea.",

  "provision.tenantspace.feature.dualCallAPPTransparentDisplaySupport": "Tarea de llamada a la aplicaci\u00F3n",
  "provision.tenantspace.feature.dualCallAppSupport.open.confirmmsg": "La funci\u00F3n AppCube es la base para ejecutar la funci\u00F3n App Call Task y se habilitar\u00E1 al mismo tiempo.",
  "provision.tenantspace.feature.onlineCustomerServiceAssets.open.confirmmsg": "La funci\u00F3n AppCube es la base para ejecutar la funci\u00F3n de activos de servicio al cliente en l\u00EDnea y se habilitar\u00E1 al mismo tiempo.",
  "provision.tenantspace.feature.smartivr.close.error": "Antes de deshabilitar la funci\u00F3n de IVR inteligente\, deshabilite la funci\u00F3n de activos de servicio al cliente en l\u00EDnea",
  "provision.tenant.management.tenantcreate.param.content.rule": "El par\u00E1metro de entrada consta de letras\, d\u00EDgitos y guiones bajos (_)\, debe comenzar con una letra y debe contener al menos dos caracteres",
  "provision.tenant.management.tenantcreate.param.exceed": "La longitud del par\u00E1metro de entrada debe ser inferior a 30 caracteres",
  "provision.tenant.management.tenantcreate.param.failed": "El par\u00E1metro de entrada no es v\u00E1lido",
  "provision.tenantspace.feature.transfer.onlinecheck.description": "Habilite la funci\u00F3n de consulta en l\u00EDnea de los recursos de volcado y la sala de alquiler admite la consulta en l\u00EDnea de los recursos de volcado",
  "provision.tenantspace.detail.tenantspace.cannot.open.uploadfeature": "Si se activa el volcado de recursos y la funci\u00F3n de consulta en l\u00EDnea de los recursos de volcado al mismo tiempo",
  "provision.tenantspace.feature.transfer.onlinecheck": "El par\u00E1metro de entrada no es v\u00E1lido.",
  "provision.tenantspace.open.uploadfeature": "Confirmar para abrir",
  "provision.vcallcenter.message.unknowdesc": "Excepci\u00F3n desconocida",
  "provision.vcallcenter.message.unknowsolution": "P\u00F3ngase en contacto con los ingenieros de Huawei para el an\u00E1lisis y la soluci\u00F3n de problemas",
  "provision.tenantspace.detail.tenantspace.cannot.close.uploadfeature": "=Por favor, desactive la funci\u00F3n de revisi\u00F3n en l\u00EDnea de recursos de volcado primero",
  "provision.vcallcenter.message.unknowcause": "Causa desconocida",
  "provision.whiteListAddress.resourcedump.css": "Servidor CSS de volcado de recursos",
  "provision.whiteListAddress.resourcedump.cssproxy": "Agente de servidor CSS de volcado de recursos",
  "provision.systemparam.algorithm.unsafe": "Se utiliza un algoritmo inseguro, que plantea riesgos de seguridad",
  "provision.systemparam.algorithm.suggest": "Precauci\u00F3n: ser coherente con UAP",

  "provision.callcenterinstanceslist.vcallcenter.ccdis": "CCDIS Servidores",

  "provision.callcenterinstanceslist.ccdis.ccdisId": "num\u00E9rotation",
  "provision.callcenterinstanceslist.ccdis.ccdisIP": "Adresse IP primaire",
  "provision.callcenterinstanceslist.ccdis.progId": "ProgID",
  "provision.callcenterinstanceslist.ccdis.ccdisbackupIP": "Adresse IP de secours",
  "provision.callcenterinstanceslist.ccdis.remark": "Description de l'utilisation",
  "provision.callcenterinstanceslist.ccdis.poolNodeId": "N\u0153ud CTI auquel appartient le CCDIS actif",
  "provision.callcenterinstanceslist.ccdis.poolNodeId2": "N\u0153ud CTI auquel appartient le CCDIS de secours",
  "provision.callcenterinstanceslist.ccdis.syncconfim.msg": "\u00CAtes-vous s\u00FBr de synchroniser les configurations li\u00E9es au CCDIS?",
  "provision.tenantspace.config.chooseCcdis": "Veuillez s\u00E9lectionner un serveur CCDIS",
  "provision.callcenterinstanceslist.ccdis.ccdisDeleteConfirm": "\u00BFEst\u00E1 seguro de que desea eliminar el servidor CCDIS seleccionado?",
  "provision.callcenterinstanceslist.ccdis.ccdisUpdate": "Editar",
  "provision.callcenterinstanceslist.ccdis.tenantSpaceName": "Nom du locataire",
  "provision.callcent.error.deleteccdis.associationvcallcener": "El servidor CCDIS seleccionado se ha asociado con un inquilino y no se puede eliminar.",
  "provision.callcent.error.updateccdis.associationvcallcener": "El servidor CCDIS seleccionado se ha asociado con un inquilino y no se puede actualizar.",

  "provision.callcenterinstanceslist.ccdis.ccdisNetId": "ID de NE activo",
  "provision.tenantspace.feature.contractdigitalsignature": "Firma digital del contrato ",
  "provision.tenantspace.feature.contractdigitalsignature.description": "Esta funci\u00F3n permite la firma digital para los contratos electr\u00F3nicos. ",

  "provision.target.selectDate": "Elegir Fecha",
  "provision.tenantspace.list.search.date.error": "La fecha de caducidad del inquilino no es v\u00E1lida. Introduzca un rango de fechas correcto",

  "provision.callcent.error.deleteinfo.from.cms.failed": "Error al eliminar la informaci\u00F3n relacionada en el CMS",
  "provision.callcent.error.deleteinfo.from.cms.unknown.error": "Error desconocido en el CMS",
  "provision.callcent.error.deleteinfo.from.cms.bad.input": "Error en la verificaci\u00F3n de entrada de CMS",
  "provision.tenantspace.feature.ivr.analysis": "An\u00E1lisis IVR",


  "provision.tenantspace.feature.ivr.analysis.description": "La funci\u00F3n de an\u00E1lisis de IVR est\u00E1 habilitada y el arrendatario admite la funci\u00F3n de an\u00E1lisis de IVR.",
  "provision.tenantspace.feature.customercenter.open.confirmmsg": "La funci\u00F3n appcube es la base para el funcionamiento de las caracter\u00EDsticas del Centro de clientes, que se abrir\u00E1n al mismo tiempo",
  "provision.tenantspace.feature.customercenter": "Centro de atenci\u00F3n al cliente",
  "provision.tenantspace.common.customercenter.explain": "Abre las caracter\u00EDsticas del Centro de atenci\u00F3n al cliente, proporciona la gesti\u00F3n local del cliente del inquilino y puede registrar la informaci\u00F3n de registro hist\u00F3rico de diferentes canales de contacto del mismo cliente",

  "provision.tenantspace.feature.appcube.close.confirmmsg": "Despu\u00E9s del cierre, los datos de negocio dentro de appcube (por ejemplo, datos de \u00F3rdenes de trabajo, datos de contacto, etc.) se eliminar\u00E1n y los datos no se pueden restaurar. confirme si est\u00E1 cerrado",
  "provision.tenantspace.common.mobileAppH5.explain": "Abre las caracter\u00EDsticas de la versi\u00F3n h5 de la aplicaci\u00F3n m\u00F3vil, los inquilinos pueden instalar autom\u00E1ticamente funciones relacionadas con asientos m\u00F3viles en el appcube, que admiten el acceso a trav\u00E9s del tel\u00E9fono m\u00F3vil.",
  "provision.tenantspace.feature.mobileAppH5.open.confirmmsg": "La funci\u00F3n appcube es la base para el funcionamiento de la funci\u00F3n Mobile App y se abrir\u00E1 al mismo tiempo.",
  "provision.tenantspace.feature.mobileAppH5": "Versi\u00F3n h5 de Mobile App",
  "provision.tenantspace.feature.customercenter.close.error ": " Before disabling the customer center feature\, ensure that the Case 2.0 feature is disabled.",

  "provision.tenantspace.feature.whatsapp.cloudapi": "Api en la nube de Whatsapp",
  "provision.tenantspace.common.whatsapp.cloudapi.explain": "Despu\u00E9s de habilitar esta funci\u00F3n\, los inquilinos pueden conectarse directamente a las API de Whatsapp Cloud para procesar mensajes de texto. Actualmente\, los inquilinos solo admiten la demostraci\u00F3n y no se pueden poner en uso comercial.",
  "provision.tenantspace.vdn.id": "ID de VDN",
  "provision.tenantspace.config.error.148": "El sistema de autenticaci\u00F3n se ha asociado con un usuario federado y no se puede modificar",

  "provision.log.config.agent.track.config.title": "Configuración de seguimiento del agente",
  "provision.log.config.agent.track.config.refresh": "Actualizar",
  "provision.log.config.agent.track.config.tenantSpaceName": "Nombre del inquilino",
  "provision.log.config.agent.track.config.workNo": "ID del agente",
  "provision.log.config.agent.track.config.tenantSpaceName.error.hints": "Solo se permiten dígitos, letras y guiones bajos",
  "provision.log.config.agent.track.config.tenantSpaceName.length.error.hints": "Ingrese un nombre de espacio de 8 a 30 caracteres que comience con una letra y contenga solo dígitos, letras y guiones bajos.",
  "provision.log.config.agent.track.config.logLevel": "Nivel de registro",
  "provision.log.config.agent.track.config.createTime": "Tiempo de creación",
  "provision.log.config.agent.track.config.endTime": "Tiempo de finalización de seguimiento",
  "provision.log.config.agent.track.config.operation": "Acción",
  "provision.log.config.agent.track.config.delete": "Eliminar",
  "provision.log.config.agent.track.config.batch.delete": "Eliminación masiva",
  "provision.log.config.agent.track.config.add": "Nuevo",
  "provision.log.config.agent.track.config.batchDelete.hints.pleaseSelect": "Seleccione la información de configuración del registro de seguimiento del agente",
  "provision.log.config.agent.track.config.batchDelete.hints.exceed.max.operate.num": "El número de registros eliminados a la vez no puede exceder de 100.",
  "provision.log.config.agent.track.config.create.title": "Crear configuración de seguimiento del agente",
  "provision.log.config.agent.track.config.create.confirm": "Confirmar",
  "provision.log.config.agent.track.config.create.cancel": "Cancelar",
  "provision.log.config.agent.track.config.create.endTime.placeholder": "Seleccione la hora de finalización del seguimiento",
  "provision.log.config.agent.track.config.create.must": "Obligatorio",
  "provision.log.config.agent.track.config.create.tenantSpaceName.min.length": "El nombre del espacio de inquilino debe contener al menos ocho caracteres.",
  "provision.log.config.agent.track.config.create.workNol.check.hints": "Ingrese un número entero entre 101 y 50000",
  "provision.log.config.agent.track.config.create.workNol.check.hints.1": "Ingrese un número entero que no sea de 50000.",
  "provision.log.config.agent.track.config.create.end.check.hints": "Seleccione un punto de tiempo entre el actual y los próximos tres días.",
  "provision.log.config.agent.track.config.success": "Éxito",
  "provision.log.config.agent.track.config.failed": "fallo",
  "provision.log.config.agent.track.config.delete.success": "Eliminación exitosa",
  "provision.log.config.agent.track.config.delete.failed.11": "No se pudo eliminar",
  "provision.log.config.agent.track.config.delete.failed.10": "Error de parámetro de operación de eliminación",
  "provision.log.config.agent.track.config.create.top.hints": "Después de habilitar el seguimiento del registro del agente del inquilino, Después de conectar la llamada, el agente recopila los registros de seguimiento generados y los envía al servicio CCManagement.Esta operación afecta al rendimiento del sistema. Habilite esta operación según sea necesario.",
  "provision.log.config.agent.track.config.create.success": "Creación exitosa",
  "provision.log.config.agent.track.config.create.failed": "No se pudo crear",
  "provision.log.config.agent.track.config.create.failed.1": "La longitud del nombre del espacio de inquilino no es válida.",
  "provision.log.config.agent.track.config.create.failed.2": "El nombre del inquilino contiene caracteres especiales",
  "provision.log.config.agent.track.config.create.failed.3": "Error al comprobar el nombre del espacio de inquilinos.",
  "provision.log.config.agent.track.config.create.failed.4": "Error al comprobar el ID del agente.",
  "provision.log.config.agent.track.config.create.failed.5": "Error al comprobar el nivel de registro",
  "provision.log.config.agent.track.config.create.failed.6": "Error al comprobar la hora de finalización del seguimiento",
  "provision.log.config.agent.track.config.create.failed.7": "Excedió el número máximo configurable",
  "provision.log.config.agent.track.config.create.failed.8": "Ya existe una configuración de seguimiento de registros para este agente",
  "provision.log.config.agent.track.config.create.failed.9": "No se pudo crear",
  "provision.log.config.agent.track.config.max.length": "La longitud máxima es ",

  "provision.tenant.management.tenantname.contact.blocklist.check": "No se permiten los siguientes caracteres especiales: & < > \" ; % / \\ ^ [ ] = + @",
}
