export default {
  'oifde.custom.displayName': '业务服务',
  'oifde.common.displayName': '通用节点',
  'oifde.basic.displayName': '基础服务',
  'oifde.test.displayName': '测试样例服务',
  'oifde.start.displayName': '开始',
  'oifde.start.description': '开始',
  'oifde.start.keywords': '开始',
  'oifde.commonBusinessInvokeService.displayName': '业务接口调用',
  'oifde.commonBusinessInvokeService.description': '业务接口调用(commonBusinessInvokeService)',
  'oifde.commonBusinessInvokeService.keywords': '业务接口调用',
  'oifde.procedureService.displayName': '存储过程',
  'oifde.procedureService.description': '存储过程(procedureService)',
  'oifde.procedureService.keywords': '存储过程',
  'oifde.conditionService.displayName': '条件判断',
  'oifde.conditionService.description': '条件判断(conditionService)',
  'oifde.conditionService.keywords': '业务接口调用',
  'oifde.callEndService.displayName': '结束',
  'oifde.callEndService.description': '结束(callEndService)',
  'oifde.callEndService.keywords': '结束',
  'oifde.sendSmsService.displayName': '短信发送',
  'oifde.sendSmsService.description': '短信发送(sendSmsService)',
  'oifde.sendSmsService.keywords': '短信发送',
  'oifde.commonVoiceService.displayName': '应答',
  'oifde.commonVoiceService.description': '应答(commonResponseService)',
  'oifde.commonVoiceService.keywords': '应答',
  'oifde.transforFlowService.displayName': '流程转移',
  'oifde.transforFlowService.description': '流程转移(transforFlowService)',
  'oifde.transforFlowService.keywords': '流程转移',
  'oifde.callTransferService.displayName': '呼叫转移',
  'oifde.callTransferService.description': '呼叫转移(callTransferService)',
  'oifde.callTransferService.keywords': '呼叫转移',
  'oifde.speechRecognitionService.displayName': '语义识别',
  'oifde.speechRecognitionService.description': '语义识别(speechRecognitionService)',
  'oifde.speechRecognitionService.keywords': '语义识别',
  'oifde.speechRecognitionService.confidence2TopError': '置信度间距必须在0和1之间',
  'oifde.keyboardRecognitionService.displayName': '按键识别',
  'oifde.keyboardRecognitionService.description': '按键识别(keyboardRecognitionService)',
  'oifde.keyboardRecognitionService.keywords': '按键识别',
  'oifde.textVariableToChatbotService.displayName': '文本转机器人',
  'oifde.textVariableToChatbotService.description': '文本转机器人(textVariableToChatbotService)',
  'oifde.textVariableToChatbotService.keywords': '文本转机器人',
  'oifde.checkRegularService.displayName': '规则校验',
  'oifde.checkRegularService.description': '规则校验(checkRegularService)',
  'oifde.checkRegularService.keywords': '规则校验',
  'oifde.dateProcessService.displayName': '日期处理',
  'oifde.dateProcessService.description': '日期处理(dateProcessService)',
  'oifde.dateProcessService.keywords': '日期处理',
  'oifde.errTimesClearService.displayName': '错误次数清零',
  'oifde.errTimesClearService.description': '错误次数清零(errTimesClearService)',
  'oifde.errTimesClearService.keywords': '错误次数清零',
  'oifde.CircleService.displayName': '逻辑循环',
  'oifde.CircleService.description': '逻辑循环(CircleService)',
  'oifde.CircleService.keywords': '逻辑循环',
  'oifde.compositeTestService.displayName': '复合图元样例',
  'oifde.service.invoke.user.level': '用户级别',
  'oifde.UserLevelService.displayName': '用户级别',
  'oifde.UserLevelService.keywords': '用户级别',
  'oifde.UserLevelService.description': '用户级别(UserLevelService)',
  'oifde.service.invoke.user.level.reminder': '请设置非负整数或对应字符型变量，用户级别最低为0，最高14，转人工后将优先处理高级别客户的呼叫，不在0-14范围内将按照边界值处理。',
  'oifde.compositeTestService.description': '复合图元样例(compositeTestService)',
  'oifde.compositeTestService.keywords': '复合图元样例',
  'oifde.service.condition.condgroup.desc': '设置相应的判断条件属性',
  'oifde.service.condition.condgroup.displayName': '判断条件列表',
  'oifde.service.condition.condgroup.keyTitle': '条件名',
  'oifde.service.condition.condgroup.valueTitle': '条件表达式',
  'oifde.service.invokemethod.group.desc': '方法调用',
  'oifde.service.invokemethod.group.displayName': '方法调用',
  'oifde.service.invokemethod.group.value1Title': '返回对象',
  'oifde.service.invokemethod.group.value2Title': '方法名',
  'oifde.service.invokemethod.group.value3Title': '方法参数',
  'oifde.service.invokemethod.group.value1Tips': '方法返回值接收对象',
  'oifde.service.invokemethod.group.value2Tips': '请选择方法名',
  'oifde.service.invokemethod.group.value3Tips': '方法参数1',
  'oifde.service.invokemethod.group.value4Tips': '方法参数2',
  'oifde.service.invokemethod.group.value5Tips': '方法参数3',
  'oifde.service.invokemethod.group.mathodType1': '字符串操作方法',
  'oifde.service.invokemethod.group.mathodType2': '数组操作方法',
  'oifde.service.invokemethod.group.mathodType3': 'Map操作方法',
  'oifde.service.invokemethod.strSplit.desc': '根据给定的分割符拆分字符串',
  'oifde.service.invokemethod.strSubstring.desc': '提取子字符串',
  'oifde.service.invokemethod.strIndexOf.desc': '获取第一次出现指定字符串的下标',
  'oifde.service.invokemethod.strStartsWith.desc': '检测字符串是否以指定的前缀开始',
  'oifde.service.invokemethod.strEndsWith.desc': '检测字符串是否以指定的后缀结束',
  'oifde.service.invokemethod.strParseJSON.desc': '将json字符串转换为对象',
  'oifde.service.invokemethod.strParseInt.desc': '将字符串转换为整型数字',
  'oifde.service.invokemethod.strParseFloat.desc': '将字符串转换为浮点型数字',
  'oifde.service.invokemethod.strLength.desc': '字符串长度',
  'oifde.service.invokemethod.strToUpperCase.desc': '将字符串小写字符转换为大写',
  'oifde.service.invokemethod.strToLowerCase.desc': '将字符串大写字符转换为小写',
  'oifde.service.invokemethod.arrayAdd.desc': '向数组中添加一个元素',
  'oifde.service.invokemethod.arrayAddAll.desc': '向数组中添加另一个集合的所有元素',
  'oifde.service.invokemethod.arrayContains.desc': '判断元素是否在数组中',
  'oifde.service.invokemethod.arrayGet.desc': '通过索引值获取数组中的元素',
  'oifde.service.invokemethod.arrayRemoveIdx.desc': '删除数组中的指定下标元素',
  'oifde.service.invokemethod.arrayRemoveAllIdx.desc': '删除数组中存在于指定集合中的下标元素',
  'oifde.service.invokemethod.arrayClear.desc': '删除数组中的所有元素',
  'oifde.service.invokemethod.arraySize.desc': '获取数组的长度',
  'oifde.service.invokemethod.arraySubList.desc': '截取并返回数组中的一部分',
  'oifde.service.invokemethod.arrayJoin.desc': '将数组转成字符串',
  'oifde.service.invokemethod.mapPut.desc': '向map中添加一个元素',
  'oifde.service.invokemethod.mapGet.desc': '获取map中指定键映射的值',
  'oifde.service.invokemethod.mapRemove.desc': '删除map中指定键值对',
  'oifde.service.invokemethod.mapClear.desc': '清空map的所有键值对',
  'oifde.service.invokemethod.mapSize.desc': '获取map的所有key的数量',
  'oifde.service.invokemethod.strSplit.parm1.desc': '待拆分的字符串',
  'oifde.service.invokemethod.strSplit.parm2.desc': '分割符',
  'oifde.service.invokemethod.strSubstring.parm1.desc': '待截取的字符串',
  'oifde.service.invokemethod.strSubstring.parm2.desc': '开始下标（整型）',
  'oifde.service.invokemethod.strSubstring.parm3.desc': '结束下标（整型）',
  'oifde.service.invokemethod.strIndexOf.parm1.desc': '待搜索的字符串',
  'oifde.service.invokemethod.strIndexOf.parm2.desc': '指定的字符串',
  'oifde.service.invokemethod.strStartsWith.parm1.desc': '待检测的字符串',
  'oifde.service.invokemethod.strStartsWith.parm2.desc': '指定的前缀字符串',
  'oifde.service.invokemethod.strEndsWith.parm1.desc': '待检测的字符串',
  'oifde.service.invokemethod.strEndsWith.parm2.desc': '指定的后缀字符串',
  'oifde.service.invokemethod.strParseJSON.parm1.desc': '待转换的字符串',
  'oifde.service.invokemethod.strParseInt.parm1.desc': '待转换的字符串',
  'oifde.service.invokemethod.strParseFloat.parm1.desc': '待转换的字符串',
  'oifde.service.invokemethod.strLength.parm1.desc': '待计算长度的字符串',
  'oifde.service.invokemethod.strToUpperCase.parm1.desc': '待转换的字符串',
  'oifde.service.invokemethod.strToLowerCase.parm1.desc': '待转换的字符串',
  'oifde.service.invokemethod.arrayAdd.parm1.desc': '待添加的数组（该方法将修改待添加的数组）',
  'oifde.service.invokemethod.arrayAdd.parm2.desc': '添加的位置（默认尾部）',
  'oifde.service.invokemethod.arrayAdd.parm3.desc': '添加的元素',
  'oifde.service.invokemethod.arrayAddAll.parm1.desc': '待添加的数组（该方法将修改待添加的数组）',
  'oifde.service.invokemethod.arrayAddAll.parm2.desc': '添加的位置（默认尾部）',
  'oifde.service.invokemethod.arrayAddAll.parm3.desc': '添加的元素',
  'oifde.service.invokemethod.arrayContains.parm1.desc': '待检测的数组',
  'oifde.service.invokemethod.arrayContains.parm2.desc': '待判断的元素',
  'oifde.service.invokemethod.arrayGet.parm1.desc': '待使用的数组',
  'oifde.service.invokemethod.arrayGet.parm2.desc': '数组下标',
  'oifde.service.invokemethod.arrayRemoveIdx.parm1.desc': '待使用的数组',
  'oifde.service.invokemethod.arrayRemoveIdx.parm2.desc': '删除数组下标',
  'oifde.service.invokemethod.arrayRemoveAllIdx.parm1.desc': '待使用的数组',
  'oifde.service.invokemethod.arrayRemoveAllIdx.parm2.desc': '指定数组下标集合',
  'oifde.service.invokemethod.arrayClear.parm1.desc': '待清理的数组',
  'oifde.service.invokemethod.arraySize.parm1.desc': '待计算长度的数组',
  'oifde.service.invokemethod.arraySubList.parm1.desc': '待截取的数组',
  'oifde.service.invokemethod.arraySubList.parm2.desc': '截取起始位置',
  'oifde.service.invokemethod.arraySubList.parm3.desc': '截取结束位置',
  'oifde.service.invokemethod.arrayJoin.parm1.desc': '待转换的数组',
  'oifde.service.invokemethod.arrayJoin.parm2.desc': '连接字符串',
  'oifde.service.invokemethod.mapPut.parm1.desc': '待添加的map(该方法将修改待添加的map)',
  'oifde.service.invokemethod.mapPut.parm2.desc': 'key（仅支持字符串类型）',
  'oifde.service.invokemethod.mapPut.parm3.desc': '添加的元素',
  'oifde.service.invokemethod.mapGet.parm1.desc': '待处理的map',
  'oifde.service.invokemethod.mapGet.parm2.desc': 'key（仅支持字符串类型）',
  'oifde.service.invokemethod.mapRemove.parm1.desc': '待处理的map',
  'oifde.service.invokemethod.mapRemove.parm2.desc': 'key（仅支持字符串类型）',
  'oifde.service.invokemethod.mapClear.parm1.desc': '待清理的map',
  'oifde.service.invokemethod.mapSize.parm1.desc': '待计算数量的map',
  'oifde.service.invokemethod.strSplit.resultObj.desc': '返回对象为数组类型，保存拆分后的字符串数组。',
  'oifde.service.invokemethod.strSubstring.resultObj.desc': '返回对象为字符串类型，为截取的子字符串。',
  'oifde.service.invokemethod.strIndexOf.resultObj.desc': '返回对象为下标值，整型。',
  'oifde.service.invokemethod.strStartsWith.resultObj.desc': '返回对象为检测结果，整型 0/1。',
  'oifde.service.invokemethod.strEndsWith.resultObj.desc': '返回对象为检测结果，整型 0/1。',
  'oifde.service.invokemethod.strParseJSON.resultObj.desc': '返回对象为转换之后的json对象。',
  'oifde.service.invokemethod.strParseInt.resultObj.desc': '返回对象为转换之后的整型数字。',
  'oifde.service.invokemethod.strParseFloat.resultObj.desc': '返回对象为转换之后的浮点型数字。',
  'oifde.service.invokemethod.strLength.resultObj.desc': '返回对象为字符串的长度，整型。',
  'oifde.service.invokemethod.strToUpperCase.resultObj.desc': '返回对象为转换之后的字符串。',
  'oifde.service.invokemethod.strToLowerCase.resultObj.desc': '返回对象为转换之后的字符串。',
  'oifde.service.invokemethod.arrayAdd.resultObj.desc': '返回对象为添加的结果，如果添加成功返回1，否则返回0。',
  'oifde.service.invokemethod.arrayAddAll.resultObj.desc': '返回对象为添加的结果，如果添加成功返回1，否则返回0。',
  'oifde.service.invokemethod.arrayContains.resultObj.desc': '返回对象为判断的结果，整型 0/1。',
  'oifde.service.invokemethod.arrayGet.resultObj.desc': '返回对象为数组的元素。',
  'oifde.service.invokemethod.arrayRemoveIdx.resultObj.desc': '返回对象为删除的结果，如果删除成功返回对应下标的元素，否则返回空。',
  'oifde.service.invokemethod.arrayRemoveAllIdx.resultObj.desc': '返回对象为删除的结果，如果删除成功返回1，否则返回0。',
  'oifde.service.invokemethod.arrayClear.resultObj.desc': '返回对象为void，输入框灰化不可编辑。',
  'oifde.service.invokemethod.arraySize.resultObj.desc': '返回对象为数组长度，整型。',
  'oifde.service.invokemethod.arraySubList.resultObj.desc': '返回对象为子数组。',
  'oifde.service.invokemethod.arrayJoin.resultObj.desc': '返回对象为转换后的字符串。',
  'oifde.service.invokemethod.mapPut.resultObj.desc': '返回对象逻辑为如果key不存在，则返回null，如果存在则返回原先被替换的value值，对象类型。',
  'oifde.service.invokemethod.mapGet.resultObj.desc': '返回对象为指定键映射的值。',
  'oifde.service.invokemethod.mapRemove.resultObj.desc': '返回对象：此方法返回此映射先前与该键关联的值;如果该映射不包含该键的映射,则返回null。',
  'oifde.service.invokemethod.mapClear.resultObj.desc': '返回对象为void，输入框灰化不可编辑。',
  'oifde.service.invokemethod.mapSize.resultObj.desc': '返回对象为map中key的数量，整型。',
  'oifde.service.invoke.cachegroup.desc': '设置相应的变量属性',
  'oifde.service.invoke.cachegroup.displayName': '设置相应的变量属性',
  'oifde.service.invoke.cachegroup.keyTitle': '缓存变量名',
  'oifde.service.invoke.cachegroup.valueTitle': '响应中的属性名',
  'oifde.service.invoke.interfaceType': '接口类型',
  'oifde.service.invoke.interfaceType.desc': '接口类型',
  'oifde.service.invoke.interfaceId': '调用接口',
  'oifde.service.invoke.interfaceId.desc': '调用接口',
  'oifde.service.invoke.paramgroup.desc': '接口入参列表',
  'oifde.service.invoke.paramgroup.name': '接口入参列表',
  'oifde.service.invoke.startTime': '开始时间',
  'oifde.service.invoke.startTime.desc': '格式 HH:MM:SS 例如: 08:00:00',
  'oifde.service.invoke.endTime': '结束时间',
  'oifde.service.invoke.endTime.desc': '格式 HH:MM:SS 例如: 23:00:00',
  'oifde.service.invoke.sendCircle': '发送周期',
  'oifde.service.invoke.sendCircle.desc': '接口调用的周期，单位为天。整型，不超过365。若配置-1表示不按照周期调用，任意时间走到结束图元均会触发。',
  'oifde.service.invoke.CallTransferCallingNum': '主叫号码',
  'oifde.service.invoke.CallTransferCallingNum.desc': '当呼转类型为5（转移第三方号码时），则使用此字段的号码作为主叫号码。主叫号码必须是系统分配的接入码或自定义参数，例：660001或FLOW.accessCode',
  'oifde.service.invoke.CallTransferCallingNum.lastAgentDesc': '主叫号码是客户来电号码．如SYS.callingNumber',
  'oifde.service.invoke.CallTransferType': '转移类型',
  'oifde.service.invoke.CallTransferType.desc': '对话转移类型。1：转人工(路由码方式转技能队列)；2：转IVR；3：转人工(技能名称方式转技能队列)；4：转人工(转座席工号)；5：转接第三方号码；6：在线数字辅助；7：转人工(转熟客，目前仅支持多媒体)；8：转人工(转座席所在技能队列，目前仅支持多媒体)；31：转多媒体留言(转熟客)；32：转多媒体留言(转技能队列)；33：转多媒体留言(转座席)',
  'oifde.service.invoke.CallTransferRouteValue': '路由值',
  'oifde.service.invoke.CallTransferRouteValue.desc': '呼叫转移路由值，根据转移类型配置对应的路由码信息',
  'oifde.service.invoke.CallTransferRouteTypePolicy': '路由策略',
  'oifde.service.invoke.CallTransferRoutePolicyType.percentage': '百分比',
  'oifde.service.invoke.CallTransferRoutePolicyType.numberSegment': '号段',
  'oifde.service.invoke.CallTransferRouteDefaultSkillName': '默认技能队列',
  'oifde.service.invoke.CallTransferMatchingType': '匹配规则',
  'oifde.service.invoke.CallTransferMatchingType.prefix': '前缀',
  'oifde.service.invoke.CallTransferMatchingType.suffix': '后缀',
  'oifde.service.invoke.CallTransferRouteTypePolicy.percentTotalError': '百分比总数不等于100',
  'oifde.service.invoke.CallTransferRouteTypePolicy.percentValueError': '请输入1-100的正整数',
  'oifde.service.invoke.CallTransferTransData': '随路数据',
  'oifde.service.invoke.CallTransferTransData.desc': '转人工时携带的随路数据',
  'oifde.service.invoke.CallTransferTransData.desc2': '您也可以输入多个字符串的流程变量或者全局变量，例如：{"data":${FLOW.XXX},"type":${GLOBAL.XXX}, "time":"2000"}',
  'oifde.service.invoke.textVariable': '文本变量',
  'oifde.service.invoke.textVariable.desc': '发送到chatbot的文本变量，变量必须在执行到此节点之前有赋值。',
  'oifde.service.invoke.KeyboardInputRegExp': '规则表达式',
  'oifde.service.invoke.KeyboardInputRegExp.desc': '用于检查用户输入的文本是否满足指定规则，正则表达式，例如：^(1([0-9]{10})|([*]|[#]))$',
  'oifde.service.invoke.voiceType': '应答类型',
  'oifde.service.invoke.voiceSource': '应答来源',
  'oifde.service.invoke.staticVoice': '静态放音',
  'oifde.service.invoke.TTSVoice': '回复文本',
  'oifde.service.invoke.multiVoice': '变量音',
  'oifde.service.invoke.multiVoiceLanguage': '语种',
  'oifde.service.invoke.multiVoiceType': '类型',
  'oifde.service.invoke.voiceTemplateCode': '回复模板',
  'oifde.service.invoke.modelType': '应答模式',
  'oifde.service.invoke.modelType.desc': '指示播放是否可以打断',
  'oifde.service.invoke.timeOut': '超时时间',
  'oifde.service.invoke.timeOut.desc': '超时时间（单位：秒），整型，例如：15。',
  'oifde.service.invoke.voiceVariable': '应答变量',
  'oifde.service.invoke.voiceVariable.mix.desc': '需要对外应答的语音变量或者文本变量，变量必须在执行到此节点之前有赋值(语音文本混合类型，应答变量实际内容格式为：录音文件URL~$TTS文本$~录音文件URL)。',
  'oifde.service.invoke.voiceVariable.tts.desc': '需要对外应答的语音变量或者文本变量，变量必须在执行到此节点之前有赋值(回复文本类型，应答变量实际内容格式为：TTS文本，只支持单个)。',
  'oifde.service.invoke.voiceVariable.voice.desc': '需要对外应答的语音变量或者文本变量，变量必须在执行到此节点之前有赋值(静态语音类型，应答变量实际内容格式为：录音文件URL，只支持单个)。',
  'oifde.service.invoke.voiceVariable.default.desc': '需要对外应答的语音变量或者文本变量，变量必须在执行到此节点之前有赋值。',
  'oifde.service.invoke.voiceVariable.multiVoice.telnum.desc': '需要对外应答的语音变量或者文本变量，变量必须在执行到此节点之前有赋值(电话号码类型，应答变量实际内容格式为：纯数字文本，输入有误将导致放音异常)。',
  'oifde.service.invoke.voiceVariable.multiVoice.number.desc': '需要对外应答的语音变量或者文本变量，变量必须在执行到此节点之前有赋值(数字类型，应答变量实际内容格式为：纯数字文本，输入有误将导致放音异常)。',
  'oifde.service.invoke.voiceVariable.multiVoice.time.desc': '需要对外应答的语音变量或者文本变量，变量必须在执行到此节点之前有赋值(时间类型，应答变量实际内容格式为：hh:mm:ss，输入有误将导致放音异常)。',
  'oifde.service.invoke.voiceVariable.multiVoice.date.desc': '需要对外应答的语音变量或者文本变量，变量必须在执行到此节点之前有赋值(日期类型，应答变量实际内容格式为：yyyymmdd，输入有误将导致放音异常)。',
  'oifde.service.invoke.voiceVariable.multiVoice.price.desc': '需要对外应答的语音变量或者文本变量，变量必须在执行到此节点之前有赋值(价格类型，应答变量实际内容格式为：纯数字文本，单位:分，输入有误将导致放音异常)。',
  'oifde.service.invoke.offsetType': '偏移类型',
  'oifde.service.invoke.offsetType.desc': '按天或者按照小时偏移',
  'oifde.service.invoke.sourceVariable': '源字符串',
  'oifde.service.invoke.sourceVariable.desc': '源字符串变量，执行到此节点前须有值，按月偏移时格式为yyyyMM，如201809；按小时偏移时格式为yyyyMMddHHmmss，如20180808122020',
  'oifde.service.invoke.destVariable': '目标字符串',
  'oifde.service.invoke.destVariable.desc': '目标字符串变量，用于保存结果的变量。',
  'oifde.service.invoke.offsetMonths': '偏移数',
  'oifde.service.invoke.offsetMonths.desc': '偏移数，整型，可以为负值，根据偏移类型可填入按月或者按小时偏移数，例如当偏移类型为按月偏移，则填入-6代表将源字符串往回倒退6个月',
  'oifde.service.invoke.keyBoardType': '键盘输入类型',
  'oifde.service.invoke.keyBoardType.desc': '键盘输入类型，可以为keyBoard_phone 或者 checkoldpassword 或者为空不填。',
  'oifde.service.invoke.inputVariable': '变量名',
  'oifde.service.invoke.inputVariable.desc': '将用户键盘输入保存到指定变量',
  'oifde.service.invoke.inputVariableTitle': '变量赋值',
  'oifde.service.invoke.inputVariableValue': '变量值',
  'oifde.service.invoke.conditionTitle': '条件分支',
  'oifde.service.invoke.branchGroup.timeout': '超时',
  'oifde.service.invoke.branchGroup.nomatch': '拒识',
  'oifde.service.invoke.branchGroup.error': '错误',
  'oifde.service.invoke.branchGroup.silentAgent': '静默座席',
  'oifde.service.invoke.targetFlowCode': '目标流程CODE',
  'oifde.service.invoke.targetNodeId': '目标节点ID',
  'oifde.service.invoke.smsTemplateCode': '短信模板',
  'oifde.service.invoke.smsContent': '短信内容',
  'oifde.service.invoke.smsContent.desc': '短信内容',
  'oifde.service.invoke.modelType.voiceBreak': '支持识别打断播放',
  'oifde.service.invoke.modelType.noVoiceBreak': '只播放',
  'oifde.service.invoke.modelType.keyboardBreak': '支持按键打断播放',
  'oifde.service.invoke.modelType.voiceAndKeyboard': '支持识别和按键打断播放',
  'oifde.service.invoke.modelType.voiceAndNobreak': '支持播放后再识别',
  'oifde.service.invoke.modelType.voiceAndInputAndNobreak': '支持播放后再识别和按键',
  'oifde.service.invoke.context': '上下文',
  'oifde.service.invoke.context.desc': '给TUC传递的上下文信息',
  'oifde.service.invoke.vendorParam': '扩展参数',
  'oifde.service.invoke.vendorParam.desc': '返回给IVR的语音识别扩展参数',
  'oifde.service.invoke.input': '语义识别内容',
  'oifde.service.invoke.input.desc': '给TUC传递的语义识别的内容，可以为变量，如果为空则默认使用用户输入的内容传递。',
  'oifde.service.invoke.event': '事件',
  'oifde.service.invoke.event.desc': '传给TUC的事件',
  'oifde.service.invoke.event.cleanup': '清空会话缓存',
  'oifde.service.invoke.event.cleanCurrent': '清空当前上下文缓存',
  'oifde.service.invoke.procedureCode': '存储过程',
  'oifde.service.invoke.complexcellgroupin.name': '入参列表',
  'oifde.service.invoke.complexcellgroupin.desc': '入参列表',
  'oifde.service.invoke.complexcellgroupout.name': '出参列表',
  'oifde.service.invoke.complexcellgroupout.desc': '出参列表',
  'oifde.service.invoke.targetIntentCount': '意图个数',
  'oifde.service.invoke.targetIntentCount.desc': '需要TUC返回的意图的最多个数。',
  'oifde.service.invoke.confidence2Top': '置信度间距',
  'oifde.service.invoke.confidence2Top.desc': '多意图时需要返回的多个意图与第一个意图的置信度的间距。',
  'oifde.service.invoke.index.desc': '计数器',
  'oifde.service.invoke.index': '计数器',
  'oifde.service.invoke.oblist.desc': '循环列表',
  'oifde.service.invoke.oblist': '循环列表',
  'oifde.service.invoke.object.desc': '循环对象',
  'oifde.service.invoke.object': '循环对象',
  'oifde.service.invoke.voiceType.staticVoice': '静态放音',
  'oifde.service.invoke.voiceType.TTSVoice': '回复文本',
  'oifde.service.invoke.voiceType.video': '视频播放',
  'oifde.service.invoke.voiceType.multi-media': '多媒体',
  'oifde.service.invoke.offsetType.month': '按月偏移',
  'oifde.service.invoke.offsetType.hour': '按小时偏移',
  'oifde.service.invoke.KeyboardInputRegExp.MobileAll': '移动号码格式校验（带#号和*号）',
  'oifde.service.invoke.KeyboardInputRegExp.Mobile': '移动号码格式校验（不带#号和*号）',
  'oifde.service.invoke.KeyboardInputRegExp.PasswordAll': '密码格式校验（带#号和*号）',
  'oifde.service.invoke.KeyboardInputRegExp.PasswordStar': '密码格式校验（带*号）',
  'oifde.service.invoke.KeyboardInputRegExp.NumberAll': '手机号码校验（带#号和*号）',
  'oifde.service.invoke.KeyboardInputRegExp.NumberStar': '手机号码校验（带*号）',
  'oifde.service.invoke.KeyboardInputRegExp.Month': '月份输入校验',
  'oifde.service.invoke.modelType.inputAndNobreak': '支持按键后不打断播放',
  'oifde.service.invoke.maxCollectLength': '最大收号长度',
  'oifde.service.invoke.maxCollectLength.desc': '设置最大收号长度',
  'oifde.service.invoke.collectTimeout': '收号超时时间',
  'oifde.service.invoke.collectTimeout.desc': '设置收号超时时间（单位：秒）,支持FLOW和GLOBAL变量,也支持输入整数,范围[1-120],不符合取默认值5。',
  'oifde.service.invoke.cancelCollectFlag': '取消键',
  'oifde.service.invoke.cancelCollectFlag.yes': '使用*号键作为取消键',
  'oifde.service.invoke.cancelCollectFlag.no': '不使用*号键作为取消键',
  'oifde.service.invoke.confirmCollectFlag': '确认键',
  'oifde.service.invoke.confirmCollectFlag.yes': '使用#号键作为确认键',
  'oifde.service.invoke.confirmCollectFlag.no': '不使用#号键作为确认键',
  'oifde.service.invoke.ttsAdvancedSetting': 'TTS高级配置',
  'oifde.service.invoke.ttsAdvancedSetting.desc': 'TTS高级配置，直接影响TTS放音的效果，有特殊需要时开启',
  'oifde.service.invoke.ttsAdvancedSetting.open': '开启',
  'oifde.service.invoke.ttsAdvancedSetting.close': '关闭',
  'oifde.service.invoke.ttsSoundEffect': '音效',
  'oifde.service.invoke.ttsSoundEffect.desc': '影响TTS放音的音效，可设置男生朗读和女生朗读',
  'oifde.service.invoke.ttsSoundEffect.male': '男生朗读',
  'oifde.service.invoke.ttsSoundEffect.female': '女生朗读',
  'oifde.service.invoke.ttsSoundSpeed': '音速',
  'oifde.service.invoke.ttsSoundSpeed.desc': '影响TTS放音的音速，可设置低速、中速和高速朗读',
  'oifde.service.invoke.ttsSoundSpeed.low': '低速朗读',
  'oifde.service.invoke.ttsSoundSpeed.normal': '中速朗读',
  'oifde.service.invoke.ttsSoundSpeed.high': '高速朗读',
  'oifde.service.invoke.ttsMRCPVendorSetting.open': '开启',
  'oifde.service.invoke.ttsMRCPVendorSetting.close': '关闭',
  'oifde.service.invoke.ttsMRCPVendorSetting': 'TTS扩展参数默认值',
  'oifde.service.invoke.ttsMRCPVendorSetting.desc': 'TTS扩展参数默认值开启，定义会覆盖所选定音效、音速参数值',
  'oifde.service.invoke.ttsMRCPVendor': 'TTS扩展参数',
  'oifde.service.invoke.ttsMRCPVendor.desc': 'TTS服务器的扩展参数，按照对应TTS服务器厂家的要求填写，格式要求为:speed=GLOBAL.ttsSpeed;pitch=GLOBAL.ttsPitch;volume=GLOBAL.ttsVolume;voice_name=GLOBAL.voiceName;支持GLOBAL和FLOW变量。变量值不在标准范围内会取缺省值。speed取值范围[-500,500],缺省值0;volume取值范围[0,100],缺省值50;pitch取值范围[-500,500]缺省值0;voicename缺省值cn_kefuman_common;',
  'oifde.service.invoke.asrAdvancedSetting': 'ASR高级配置',
  'oifde.service.invoke.asrAdvancedSetting.desc': 'ASR高级配置，直接影响ASR识别的效果，有特殊需要时开启',
  'oifde.service.invoke.asrAdvancedSetting.open': '开启',
  'oifde.service.invoke.asrAdvancedSetting.close': '关闭',
  'oifde.service.invoke.asrRecognizeType': '识别类型',
  'oifde.service.invoke.asrRecognizeType.desc': 'ASR识别的类型，当前仅支持普通识别',
  'oifde.service.invoke.asrRecognizeType.normal': '普通识别',
  'oifde.service.invoke.asrRecognizeType.intelligent': '智能识别',
  'oifde.service.invoke.asrNoInputTimeout': '用户无话超时时长',
  'oifde.service.invoke.asrNoInputTimeout.desc': 'ASR识别时，用户无话超时时长（单位：秒），取值范围：0-32000，默认100。',
  'oifde.service.invoke.asrRecognitionTimeout': '识别结果超时时长',
  'oifde.service.invoke.asrRecognitionTimeout.desc': 'ASR识别时，识别结果超时时长（单位：秒），取值范围：0-600，默认200。',
  'oifde.service.invoke.asrSpeechCompleteTimeout': '用户停顿超时时长',
  'oifde.service.invoke.asrSpeechCompleteTimeout.desc': 'ASR识别时，用户停顿超时时长（单位：毫秒），取值范围：300-2000，默认500。',
  'oifde.service.invoke.asrMRCPVendor': 'ASR扩展参数',
  'oifde.service.invoke.asrMRCPVendor.desc': 'ASR服务器的扩展参数，按照对应ASR服务器厂家的要求填写。',
  'oifde.service.invoke.voiceType.card': '卡片',
  'oifde.service.invoke.operationType': '操作类型',
  'oifde.service.invoke.operationType.open': '开启录制',
  'oifde.service.invoke.operationType.close': '停止录制',
  'oifde.service.invoke.processRecord': '全流程录制',
  'oifde.service.invoke.processRecord.open': '是',
  'oifde.service.invoke.processRecord.close': '否',
  'oifde.service.invoke.recordType': '录制类型',
  'oifde.service.invoke.recordType.audio': '音频录制',
  'oifde.service.invoke.recordType.video': '音视频录制',
  'oifde.yugaoRecordService.displayName': '宇高录制',
  'oifde.yugaoRecordService.description': '宇高录制(yugaoRecordService)',
  'oifde.yugaoRecordService.keywords': '宇高录制',
  'oifde.jsonDataProcessService.displayName': 'JSON数据调用',
  'oifde.jsonDataProcessService.description': 'JSON数据调用(jsonDataProcessService)',
  'oifde.jsonDataProcessService.keywords': 'JSON数据调用',
  'oifde.timeSelectService.displayName': '时间选择',
  'oifde.timeSelectService.description': '时间选择(timeSelectService).',
  'oifde.timeSelectService.keywords': '时间选择',
  'oifde.service.invoke.recordMode': '录制模式',
  'oifde.service.invoke.recordMode.onlyuser': '仅录制用户',
  'oifde.service.invoke.recordMode.onlyivr': '仅录制IVR',
  'oifde.service.invoke.recordMode.all': '录制用户和IVR',
  'oifde.service.invoke.timeSelect.NonWorkTime': '非指定时间分支',
  'oifde.service.invoke.timeSelect.weekParameter': '星期选择',
  'oifde.service.invoke.timeSelect.serviceTimeParameter': '服务时间段',
  'oifde.service.invoke.timeSelect.specificWorkDateParameter': '特定服务日期',
  'oifde.service.invoke.timeSelect.specificNoWorkDateParameter': '特定非服务日期',
  'oifde.service.invoke.timeSelect.serviceTimeParameter.desc': '服务时间段,至少需要填写一条数据,上限10条数据',
  'oifde.service.invoke.timeSelect.specificWorkDateParameter.desc': '上限50条数据',
  'oifde.service.invoke.timeSelect.specificNoWorkDateParameter.desc': '指定非工作日期,上限50条数据',
  'oifde.service.invoke.timeSelect.specificWorkDateParameter.title': '配置了指定工作日期，但不在星期选择中，也会被认定为工作日。',
  'oifde.service.invoke.timeSelect.specificNoWorkDateParameter.title': '优先级最高，只要配置了指定非工作日期，就会被认定为非工作日。',
  'oifde.service.invoke.workdays.Monday': '周一',
  'oifde.service.invoke.workdays.Tuesday': '周二',
  'oifde.service.invoke.workdays.Wednesday': '周三',
  'oifde.service.invoke.workdays.Thursday': '周四',
  'oifde.service.invoke.workdays.Friday': '周五',
  'oifde.service.invoke.workdays.Saturday': '周六',
  'oifde.service.invoke.workdays.Sunday': '周日',
  'oifde.queryMessageService.displayName': '信息查询',
  'oifde.queryMessageService.description': '信息查询(queryMessageService).',
  'oifde.queryMessageService.keywords': '信息查询',
  'oifde.service.interfaceType.selfDefinedInterface': '自定义接口',
  'oifde.service.interfaceType.innerInterface': '内置接口',
  'oifde.qarecord.interface.name': '记录问答',
  'oifde.qarecord.interface.desc': '用于记录问卷标识、问题标题、用户回答意图、用户原始答等信息',
  'oifde.apiToken.interface.name': '获取APIFabric token',
  'oifde.apiToken.interface.desc': '用于获取APIFabric token的接口',
  'oifde.apiToken.applyType.desc': 'token的前缀',
  'oifde.apiToken.accessToken.desc': 'token的内容',
  'oifde.apiToken.appKey.desc': 'APP标识',
  'oifde.apiToken.appSecret.desc': 'APP密钥',
  'oifde.qarecord.inputparam.seriesId': '问答的系列标识',
  'oifde.qarecord.inputparam.questionTitle': '问题标题',
  'oifde.qarecord.inputparam.questionType': '问题类型',
  'oifde.qarecord.inputparam.answerIntent': '回答意图',
  'oifde.qarecord.inputparam.originalAnswer': '原始答',
  'oifde.service.invoke.queryMsg.MessageQueryType': '查询类型',
  'oifde.service.invoke.queryMsg.MessageQueryType.desc': '排队信息使用场景:是转移到技能队列时启用伴随IVR流程查询排队信息',
  'oifde.service.invoke.queryMsg.skillMsg': '排队信息',
  'oifde.service.invoke.queryMsg.skillInfo': '队列信息',
  'oifde.service.invoke.queryMsg.specifiedSkill': '队列名称',
  'oifde.service.invoke.queryMsg.specialListQuery': '特殊名单信息',
  'oifde.service.invoke.queryMsg.result': '查询结果',
  'oifde.service.invoke.queryMsg.MessageQueryQueueIndex': '排队位置',
  'oifde.service.invoke.queryMsg.MessageQueryQueueNumber': '排队人数',
  'oifde.service.invoke.queryMsg.MessageQueryOnlineNum': '在线座席数',
  'oifde.service.invoke.queryMsg.MessageIdleAgentNum': '空闲座席数',
  'oifde.service.invoke.queryMsg.MessageQueryWaitingTime': '预计等待时间',
  'oifde.service.invoke.queryMsg.MessageQueryWaitingTime.desc': '排队预计等待时间依靠队列模型计算，结果有一定的偏差，请合理使用',
  'oifde.service.invoke.queryMsg.params': '请求参数',
  'oifde.service.invoke.queryMsg.skillQueue': '技能队列',
  'oifde.service.invoke.queryMsg.agentId': '座席工号',
  'oifde.service.invoke.queryMsg.specialList': '存在于特殊名单的标识',
  'oifde.service.invoke.breakMinTime': '最少放音时间',
  'oifde.service.invoke.modelType.customization': '自定义变量值',
  'oifde.service.invoke.voiceTtsMixed': '语音文本混合',
  'oifde.service.invoke.ttsSoundEffectCust': '自定义音效',
  'oifde.service.invoke.ttsSoundSpeedCust': '自定义音速',
  'oifde.service.invoke.modelType.customization.desc': 'interrupt标识是否可被打断，如果可打断则在最少放音时间输入框填入最少放音时间。 填写true或者false，true 表示可以被识别打断，可以传递最少放音时间，false 可以被识别但不可以打断，填写错误为默认值false。',
  'oifde.service.invoke.CallTransferType.transferToSkillQueueByRoutingCode': '转技能队列(指定路由扩展码)',
  'oifde.service.invoke.CallTransferType.transferToIVR': '转IVR',
  'oifde.service.invoke.CallTransferType.transferToSkillQueueBySkillName': '转技能队列(指定名称)',
  'oifde.service.invoke.CallTransferType.transferToAgentByID': '转座席',
  'oifde.service.invoke.CallTransferType.transferToThirdPartyNumber': '转接第三方号码',
  'oifde.service.invoke.CallTransferType.onlineDigitalAssistant': '在线数字辅助',
  'oifde.service.invoke.CallTransferType.transferToLastAgent': '转多媒体熟客',
  'oifde.service.invoke.CallTransferType.transferToSkillQueueBelongAgent': '转技能队列(多媒体座席所在技能队列)',
  'oifde.service.invoke.CallTransferType.transferToSkillQueueByRouteConfig': '转技能队列(自定义路由)',
  'oifde.service.invoke.CallTransferType.transferToMultimediaMessageToLastAgent': '转熟客留言',
  'oifde.service.invoke.CallTransferType.transferToMultimediaMessageToSkillQueue': '转技能队列留言（指定名称或接入码）',
  'oifde.service.invoke.CallTransferType.transferToMultimediaMessageToAgent': '转座席留言',
  'oifde.service.invoke.CallTransferTypeGroup.multimediaMessage': '多媒体留言',
  'oifde.service.invoke.CallTransferRecordFlag': '开启录音',
  'oifde.service.invoke.CallTransferRecordFlag.desc': '', // 本身就为空不展示
  'oifde.service.invoke.recordPath': '录音文件名',
  'oifde.service.invoke.recordPath.desc': '请输入保存录音文件名的变量。',
  'oifde.service.invoke.recordServiceNodeID': '录音服务节点编号',
  'oifde.service.invoke.recordServiceNodeID.desc': '请输入保存录音服务节点编号的变量（呼叫中心Pool模式下使用）。',
  'oifde.service.invoke.languageType.language0': '普通话',
  'oifde.service.invoke.languageType.language1': '英语',
  'oifde.service.invoke.languageType.language2': '粤语',
  'oifde.service.invoke.languageType.language3': '捷克语',
  'oifde.service.invoke.languageType.language4': '丹麦语',
  'oifde.service.invoke.languageType.language5': '荷兰语',
  'oifde.service.invoke.languageType.language6': '芬兰语',
  'oifde.service.invoke.languageType.language7': '法语',
  'oifde.service.invoke.languageType.language8': '德语',
  'oifde.service.invoke.languageType.language9': '希腊语',
  'oifde.service.invoke.languageType.language10': '匈牙利语',
  'oifde.service.invoke.languageType.language11': '冰岛语',
  'oifde.service.invoke.languageType.language12': '意大利语',
  'oifde.service.invoke.languageType.language13': '日语',
  'oifde.service.invoke.languageType.language14': '韩语',
  'oifde.service.invoke.languageType.language15': '挪威语',
  'oifde.service.invoke.languageType.language16': '波兰语',
  'oifde.service.invoke.languageType.language17': '葡萄牙语',
  'oifde.service.invoke.languageType.language18': '俄语',
  'oifde.service.invoke.languageType.language19': '斯洛伐克语',
  'oifde.service.invoke.languageType.language20': '西班牙语',
  'oifde.service.invoke.languageType.language21': '瑞典语',
  'oifde.service.invoke.languageType.language22': '土耳其语',
  'oifde.service.invoke.languageType.language23': '乌兹别克语',
  'oifde.service.invoke.languageType.language24': '泰语',
  'oifde.service.invoke.languageType.language80': '斯瓦西里语',
  'oifde.service.invoke.languageType.language81': '阿拉伯语',
  'oifde.service.invoke.languageType.language82': '北印度语',
  'oifde.service.invoke.languageType.language83': '菲律宾语',
  'oifde.service.invoke.languageType.language84': '柬埔寨语',
  'oifde.service.invoke.languageType.language85': '孟加拉语',
  'oifde.service.invoke.languageType.language86': '巴勒斯坦语',
  'oifde.service.invoke.languageType.language87': '越南语',
  'oifde.service.invoke.languageType.language88': '印尼语',
  'oifde.service.invoke.languageType.language89': '尼泊尔语',
  'oifde.service.invoke.languageType.language90': '马达加斯加语',
  'oifde.service.invoke.languageType.language92': '埃塞俄比亚语',
  'oifde.service.invoke.languageType.language93': '马来西亚语',
  'oifde.service.invoke.variableType.type1': '电话号码',
  'oifde.service.invoke.variableType.type3': '数字',
  'oifde.service.invoke.variableType.type4': '时间(hh:mm:ss)',
  'oifde.service.invoke.variableType.type5': '日期(yyyymmdd)',
  'oifde.service.invoke.variableType.type6': '价格',
  'oifde.ringService.displayName': '振铃',
  'oifde.assignValueService.displayName': '赋值',
  'oifde.transferService.displayName': '转移',
  'oifde.service.inpytOrDrag': '输入或拖拽变量',
  'oifde.service.operateAsFunction': '根据提供方法进行操作',
  'oifde.interfaceInvoke.displayName': '接口调用',
  'oifde.basicOdfsSubFlowService.displayName': '子流程(智能)',
  'oifde.basicCcivrSubFlowService.displayName': '子流程(普通)',
  'oifde.yugaoRecordServiceNew.displayName': '录制采集',
  'oifde.conditionServiceNew.displayName': '分支判断',
  'oifde.service.invoke.callTranseferService.transData.wait': '队列排队',
  'oifde.service.invoke.callTranseferService.transData.data': '传递数据',
  'oifde.service.invoke.callTranseferService.transData.isSpecifyAccessCode': '指定渠道接入码',
  'oifde.service.invoke.callTranseferService.transData.type': '熟客模式',
  'oifde.service.invoke.callTranseferService.transData.time': '时间范围（分钟）',
  'oifde.service.invoke.callTranseferService.transData.timeTips': '例：100，转移给100分钟内最近联系的座席；时间取值建议1~10080。',
  'oifde.service.invoke.callTranseferService.transData.timeErrorTips': '请输入正整数或变量',
  'oifde.service.invoke.callTranseferService.transData.starttime': '日期范围',
  'oifde.service.invoke.callTranseferService.transData.starttimeTips': '指定日期范围内最近联系的座席',
  'oifde.service.invoke.callTranseferService.transData.type.selectTime': '指定分钟',
  'oifde.service.invoke.callTranseferService.transData.type.selectStartTime': '指定日期',
  'oifde.service.invoke.callTranseferService.transData.type.currentDay': '当天',
  'oifde.service.invoke.callTranseferService.transData.paramValueTips': "请输入参数值。",
  'oifde.service.invoke.callTranseferService.transData.varTips': "可输入变量，全局变量以GLOBAL开头，流程变量以FLOW开头，系统变量以SYS开头，TUC变量以TOC开头，IVR请求变量以IVRREQUEST开头。且需要使用{'${}'}包裹变量，比如：{'${FLOW.variable}'}",
  'oifde.service.invoke.ruleValidationService.name': '规则校验V2',
  'oifde.service.invoke.ruleValidationService.variable': '待校验变量',
  'oifde.service.invoke.ruleValidationService.specialEndKey': '特殊按键结尾',
  'oifde.service.invoke.ruleValidationService.specialEndKey.option1': '带#号',
  'oifde.service.invoke.ruleValidationService.specialEndKey.option2': '带*号',
  'oifde.service.invoke.ruleValidationService.specialEndKey.option3': '带#或*号',
  'oifde.service.invoke.ruleValidationService.specialEndKey.option4': '不带特殊键',
  'oifde.service.invoke.ruleValidationService.containsChar': '包含字符',
  'oifde.service.invoke.ruleValidationService.containsChar.option1': '匹配数字',
  'oifde.service.invoke.ruleValidationService.containsChar.option2': '匹配大写字母',
  'oifde.service.invoke.ruleValidationService.containsChar.option3': '匹配小写字母',
  'oifde.service.invoke.ruleValidationService.containsChar.option4': '匹配字母、数字、下划线',
  'oifde.service.invoke.ruleValidationService.containsChar.option5': '空字符',
  'oifde.service.invoke.ruleValidationService.validationRules': '校验规则',
  'oifde.service.invoke.ruleValidationService.validationRules.ruleParam': '规则参数',
  'oifde.service.invoke.ruleValidationService.validationRules.ruleParamValueTips': "可输入变量，全局变量以GLOBAL开头，流程变量以FLOW开头，系统变量以SYS开头，TUC变量以TOC开头，IVR请求变量以IVRREQUEST开头。比如：FLOW.variable",
  'oifde.service.invoke.ruleValidationService.validationRules.minLength': '最小长度',
  'oifde.service.invoke.ruleValidationService.validationRules.minLength.valueTips': '请输入最小长度。',
  'oifde.service.invoke.ruleValidationService.validationRules.maxLength': '最大长度',
  'oifde.service.invoke.ruleValidationService.validationRules.maxLength.valueTips': '请输入最大长度。',
  'oifde.service.invoke.ruleValidationService.validationRules.rangeLength': '长度范围',
  'oifde.service.invoke.ruleValidationService.validationRules.min': '最小值',
  'oifde.service.invoke.ruleValidationService.validationRules.min.valueTips': '请输入最小值。',
  'oifde.service.invoke.ruleValidationService.validationRules.max': '最大值',
  'oifde.service.invoke.ruleValidationService.validationRules.max.valueTips': '请输入最大值。',
  'oifde.service.invoke.ruleValidationService.validationRules.range': '取值范围',
  'oifde.service.invoke.ruleValidationService.validationRules.email': 'Email',
  'oifde.service.invoke.ruleValidationService.validationRules.url': 'URL',
  'oifde.service.invoke.ruleValidationService.validationRules.dateFormat': '时间格式',
  'oifde.service.invoke.ruleValidationService.validationRules.dateFormat.valueTips': '请输入时间格式，比如日期（yyyy-MM-dd）或日期时间（yyyy-MM-dd HH:mm:ss）。',
  'oifde.service.invoke.ruleValidationService.validationRules.number': '十进制数字',
  'oifde.service.invoke.ruleValidationService.validationRules.digits': '正整数',
  'oifde.service.invoke.ruleValidationService.validationRules.ip': 'IP地址',
  'oifde.service.invoke.ruleValidationService.validationRules.alpha': '字母数字下划线（非数字开头）',
  'oifde.service.invokemethod.offsetMonth.desc': '按月偏移',
  'oifde.service.invokemethod.offsetMonth.parm1.desc': '待偏移的时间变量，字符串类型，格式为yyyyMM',
  'oifde.service.invokemethod.offsetMonth.parm2.desc': '偏移数，整型，可以为负值',
  'oifde.service.invokemethod.offsetMonth.resultObj.desc': '返回对象为偏移后的数据。',
  'oifde.service.invokemethod.offsetHour.desc': '按小时偏移',
  'oifde.service.invokemethod.offsetHour.parm1.desc': '待偏移的时间变量，字符串类型，格式为yyyyMMddHHmmss',
  'oifde.service.invokemethod.offsetHour.parm2.desc': '偏移数，整型，可以为负值',
  'oifde.service.invokemethod.offsetHour.resultObj.desc': '返回对象为偏移后的数据。',
  'oifde.service.invoke.timeSelect.workDate': '指定工作日期',
  'oifde.service.invoke.timeSelect.noWorkDate': '指定非工作日期',
  'oifde.service.invoke.yugaoRecord.type': '类型：',
  'oifde.service.invoke.yugaoRecord.mode': '模式：',
  'oifde.service.invoke.subflow.subFlowNode': '子流程',
  'oifde.service.invokemethod.group.mathodType4': '日期操作方法',
  'oifde.transdata.get.example': '例如有随路数据为：{\'{\'}"data":{\'{\'}"test": "111"{\'}\'}{\'}\'},可在变量值填入data.test获取到数据"111"。',
  'oifde.service.invoke.basicAvatarReplyService.name': '数字人回复',
  'oifde.service.invoke.basicAvatarReplyService.actionType': '操作',
  'oifde.service.invoke.basicAvatarReplyService.start': '启动',
  'oifde.service.invoke.basicAvatarReplyService.image': '形象',
  'oifde.service.invoke.basicAvatarReplyService.avatarImage': '数字人形象',
  'oifde.service.invoke.basicAvatarReplyService.ttsSpeaker': '发音人',
  'oifde.service.invoke.basicAvatarReplyService.compositeVideoSetting': '合成视频配置',
  'oifde.service.invoke.basicAvatarReplyService.compositeVideoSetting.desc': '合成视频配置，直接影响数字人展示效果，有特殊需要时开启',
  'oifde.service.invoke.basicAvatarReplyService.imageSetting': '图片视频设置',
  'oifde.service.invoke.basicAvatarReplyService.action': '动作',
  'oifde.service.invoke.basicAvatarReplyService.frontImage': '前景图',
  'oifde.service.invoke.basicAvatarReplyService.backendImage': '后景图',
  'oifde.service.invoke.basicAvatarReplyService.frontVideo': '前景视频',
  'oifde.service.invoke.basicAvatarReplyService.backendVideo': '后景视频',
  'oifde.service.invoke.basicAvatarReplyService.xPosition': '横坐标',
  'oifde.service.invoke.basicAvatarReplyService.yPosition': '纵坐标',
  'oifde.service.invoke.basicAvatarReplyService.scale': '缩放比例',
  'oifde.service.invoke.basicAvatarReplyService.beginTime': '开始时间（毫秒）',
  'oifde.service.invoke.basicAvatarReplyService.duration': '播放时长（毫秒）',
  'service.invoke.virtualHumanConfig': '数字人配置',
  'service.virtualHuman.videoParam': '视频编辑参数',
  'service.virtualHuman.captions': '字幕',
  'service.invoke.asrAdvancedSetting.open': '开启',
  'service.invoke.asrAdvancedSetting.close': '关闭'
}
