<template>
  <sweet-button @click="handleClick">
    <template v-for="(_, name) in $slots" v-slot:[name]="data">
      <slot :name="name" v-bind="data" />
    </template>
  </sweet-button>
</template>

<script>
import { throttle, debounce } from 'lodash'
export default {
  name: 'aicc-button',
  emits: ['click'],
  props: {
    // 指定当前按钮采用防抖模式还是节流模式，可选'debounce', 'throttle'，非该两项不进行处理，直接执行
    optionType: {
      type: String,
      default: () => {
        return 'debounce'
      }
    },
    // 节流设置
    throttleOptions: {
      type: Object,
      default: () => {
        return {
          // 指定在节流开始前立即调用一次
          leading: true,
          // 指定在节流结束后调用一次
          trailing: false,
          wait: 1000
        }
      }
    },
    // 防抖设置
    debounceOptions: {
      type: Object,
      default: () => {
        return {
          // 指定在防抖开始前立即调用一次
          leading: true,
          // 指定在防抖结束后调用一次
          trailing: false,
          wait: 500
        }
      }
    }
  },
  data() {
    return {
      limitClickFunc: undefined
    }
  },
  mounted() {
    if (this.optionType === 'debounce') {
      this.limitClickFunc = debounce(
        (event) => {
          this.$emit('click', event)
        },
        this.debounceOptions.wait,
        {
          leading: this.debounceOptions.leading,
          trailing: this.debounceOptions.trailing
        }
      )
    } else if (this.optionType === 'throttle') {
      this.limitClickFunc = throttle(
        (event) => {
          this.$emit('click', event)
        },
        this.throttleOptions.wait,
        {
          leading: this.throttleOptions.leading,
          trailing: this.throttleOptions.trailing
        }
      )
    } else {
      this.limitClickFunc = (event) => {
        this.$emit('click', event)
      }
    }
  },
  methods: {
    handleClick(event) {
      this.limitClickFunc(event)
    }
  }
}
</script>
