/**
 * 日期格式转换，将时间戳或日期字符串转换本地时区目标格式（YYYY/MM/DD HH24:Mi:SS)，日期分割符可通过参数指定
 * @param time string, 必选项。如果是数字值，dateVal 表示指定日期与 1970 年 1 月 1 日午夜间全球标准时间（UTC）的毫秒数。
 *                            如果是字符串，则 dateVal 按照 Date.parse 方法中的规则进行解析。
 * @param connector string 日期分隔符（/或-），默认值为'-'.
 * @returns string 按照connector分割的日期字符串
 */
function transTime(time: string, connector?: string): string {
    if (!time && time !== '0') return ''
    const con = (connector || '-') // 日期分割符默认调整为'-'
    // 判断入参是否全部是数字，如果全部是数字，则表示是时间戳转换的字符串，需要转换回数字，否则new Date会报错。
    let date: Date
    if (time) {
        date = isNumber(time) ? new Date(parseInt(time)) : new Date(time)
    } else {
        date = new Date()
    }
    const Y = `${date.getFullYear()}${con}`
    const M = `${
        date.getMonth() + 1 < 10
            ? '0' + (date.getMonth() + 1)
            : date.getMonth() + 1
        }${con}`
    const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
    const h =
        (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
    const m =
        (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
        ':'
    const s =
        date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    const resultTime = Y + M + D + h + m + s
    return resultTime
}

/**
 * 日期格式转换，将时间戳转换成本地时区可显示的字符串（YYYY/MM/DD)
 * @param time string, 必选项。如果是数字值，dateVal 表示指定日期与 1970 年 1 月 1 日午夜间全球标准时间（UTC）的毫秒数。
 *                            如果是字符串，则 dateVal 按照 Date.parse 方法中的规则进行解析。
 * @param connector string 日期分隔符（/或-），默认值为'-'.
 * @returns string 按照connector分割的日期字符串
 */
function transDate(time: string, connector?: string): string {
    const con = (connector || '-') // 日期分割符默认调整为'-'
    // 判断入参是否全部是数字，如果全部是数字，则表示是时间戳转换的字符串，需要转换回数字，否则new Date会报错。
    let date: Date
    if (time) {
        date = isNumber(time) ? new Date(parseInt(time)) : new Date(time)
    } else {
        date = new Date()
    }
    const Y = date.getFullYear() + con
    const M =
        (date.getMonth() + 1 < 10
            ? '0' + (date.getMonth() + 1)
            : date.getMonth() + 1) + con
    const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate())

    const resultTime = Y + M + D
    return resultTime
}

/**
 * 获取指定偏移的日期字符串（YYYY/MM/DD HH24:Mi:SS）
 * @param num  偏移量。
 * @param method 偏移单位，Y - 年， M - 月，D - 日， H - 时，Mi - 分， S - 秒。
 * @param time  可选参数。如果是数字值，dateVal 表示指定日期与 1970 年 1 月 1 日午夜间全球标准时间（UTC）的毫秒数。
 *                    如果是字符串，则 dateVal 按照 Date.parse 方法中的规则进行解析。
 *                    默认取值为系统当前时间
 * @returns string 日期分割符为'-'的本地时区日期字符串
 */
function preDays(num: number, method: string, time?: number): string {
    const date = time ? new Date(time) : new Date()
    let dateTime = date.getTime()
    const yearStep = 1000 * 3600 * 24 * 365
    const monthStep = 1000 * 3600 * 24 * 30
    const dayStep = 1000 * 3600 * 24
    const hourStep = 1000 * 3600
    const minuteStep = 1000 * 60
    const secondStep = 1000

    switch (method) {
        case 'Y':
            dateTime = dateTime + num * yearStep
            break
        case 'M':
            dateTime = dateTime + num * monthStep
            break
        case 'D':
            dateTime = dateTime + num * dayStep
            break
        case 'H':
            dateTime = dateTime + num * hourStep
            break
        case 'Mi':
            dateTime = dateTime + num * minuteStep
            break
        case 'S':
            dateTime = dateTime + num * secondStep
            break
        default:
            break
    }
    const resultTime = transTime(new Date(dateTime).toString())
    return resultTime
}

/**
 * 获取指定偏移的日期的UTC时间戳
 * @param num  偏移量。
 * @param method 偏移单位，Y - 年， M - 月，D - 日， H - 时，Mi - 分， S - 秒。
 * @param time 可选项。如果是数字值，dateVal 表示指定日期与 1970 年 1 月 1 日午夜间全球标准时间（UTC）的毫秒数。
 *                      如果是字符串，则 dateVal 按照 Date.parse 方法中的规则进行解析。
 *                      默认值为系统当前时间。
 * @param truncDate 是否截取到日，默认为false
 * @returns number UTC时间戳
 */
function preDaysToUTCTimestamp(num: number, method: string, time?: string, truncDate: boolean = false): number {
    let dateTime = getUTCTimestamp(time)
    if (truncDate) {
        dateTime = getUTCTimestamp(transDate(dateTime.toString()) + ' 00:00:00')
    }
    const yearStep = 1000 * 3600 * 24 * 365
    const monthStep = 1000 * 3600 * 24 * 30
    const dayStep = 1000 * 3600 * 24
    const hourStep = 1000 * 3600
    const minuteStep = 1000 * 60
    const secondStep = 1000

    switch (method) {
        case 'Y':
            dateTime = dateTime + num * yearStep
            break
        case 'M':
            dateTime = dateTime + num * monthStep
            break
        case 'D':
            dateTime = dateTime + num * dayStep
            break
        case 'H':
            dateTime = dateTime + num * hourStep
            break
        case 'Mi':
            dateTime = dateTime + num * minuteStep
            break
        case 'S':
            dateTime = dateTime + num * secondStep
            break
        default:
            break
    }
    return dateTime
}

/**
 * 按照当前时区偏移，将指定格式的时间转换成本地日期字符串（YYYY/MM/DD HH24:Mi:SS)，日期分割符可通过参数指定
 * @param time 必选项。如果是数字值，dateVal 表示指定日期与 1970 年 1 月 1 日午夜间全球标准时间（UTC）的毫秒数。
 *                    如果是字符串，则 dateVal 按照 Date.parse 方法中的规则进行解析。
 * @param connector string 日期分隔符（/或-），默认值为'-'
 * @returns string 日期分割符为connector的本地时区日期字符串
 */
function transTimeWithZone(time: number, connector?: string): string {
    if (!time && time !== 0) return ''
    const offsetInMin = new Date().getTimezoneOffset()
    const utcTime = time + offsetInMin * 60000
    return transTime(utcTime.toString(), connector)
}

/**
 * 返回当前浏览器的时区时间，将指定格式的时间转换成本地日期字符串（YYYY/MM/DD HH24:Mi:SS)，日期分割符可通过参数指定
 * @param time 必选项。如果是数字值，dateVal 表示指定日期与 1970 年 1 月 1 日午夜间全球标准时间（UTC）的毫秒数。
 *                    如果是字符串，则 dateVal 按照 Date.parse 方法中的规则进行解析。
 * @param connector string 日期分隔符（/或-），默认值为'-'
 * @returns string 日期分割符为connector的本地时区日期字符串
 */
function transTimeWithCurrentZone(time: number, connector?: string): string {
    if (!time && time !== 0) return ''
    return transTime(time.toString(), connector)
}

/**
 * 获取输入日期的UTC标准时间戳。
 * @param time string 可选参数，如果是数字值，dateVal 表示指定日期与 1970 年 1 月 1 日午夜间全球标准时间（UTC）的毫秒数。
 *                             如果是字符串，则 dateVal 按照 Date.parse 方法中的规则进行解析。
 *                    默认值为系统当前时间。
 * @returns number UTC时间戳。
 */
function getUTCTimestamp(time?: string): number {
    // 判断入参是否全部是数字，如果全部是数字，则表示是时间戳转换的字符串，需要转换回数字，否则new Date会报错。
    let date: Date
    if (time) {
        date = isNumber(time) ? new Date(parseInt(time)) : new Date(time)
    } else {
        date = new Date()
    }

    /**
     if ($UEE.$Tz.dstsupport) { // 如果服务端设置了时区，则按照服务端设置的时区返回时间戳
        let serverTime =  date.getTime() + $UEE.$Tz.stz.rawOffset + (new Date()).getTimezoneOffset() * 60000
        date = new Date(serverTime)
    }**/

    let resultTime: number
    resultTime = Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds(),
        date.getUTCMilliseconds()
    )
    return resultTime
}

/**
 * 获取系统当前时间(本地时区）
 * @param connector 日期分隔符，默认为'-'
 * @returns string 系统当前时间（本地时区），格式为YYYY-MM-DD HH24:Mi:SS
 */
function getCurrentTime(connector?: string): string {
    const d = (new Date()).getTime().toString()
    return transTime(d, connector)
}

/**
 * 获取系统当前日期（本地时区）
 * @param connector 日期分隔符，默认为'-'
 * @returns string 系统当前时间（本地时区），格式为YYYY-MM-DD
 */
function getCurrentDate(connector?: string): string {
    const d = (new Date()).getTime().toString()
    return transDate(d, connector)
}

/**
 * 将本地时区的时间戳转换成UTC标准时间戳，用于日期控件直接返回的时间转换。
 * @param time number 必选参数，本地时区的时间戳。
 * @returns number UTC时间戳。
 */
function transToUTCTimestamp(localTimestamp: number): number {
    const offsetInMin = (new Date()).getTimezoneOffset()
    const d = localTimestamp + offsetInMin * 60000
    return getUTCTimestamp(d.toString())
}

/**
 * 判断字符串内容是否全是数字，用于日期格式转换
 * @param num 输入内容
 * @returns boolean true, 全数字， false， 非全数字
 */
function isNumber(num: string): boolean {
    const reg = /^[0-9]+.?[0-9]*$/
    return reg.test(num)
}
/**
 * 日期格式转换，将时间戳或日期字符串转换本地时区目标格式（YYYY/MM/DD HH24:Mi:SS)，日期分割符可通过参数指定
 * @param time string, 必选项。如果是数字值，dateVal 表示指定日期与 1970 年 1 月 1 日午夜间全球标准时间（UTC）的毫秒数。
 *                            如果是字符串，则 dateVal 按照 Date.parse 方法中的规则进行解析。
 * @param connector string 日期分隔符（/或-），默认值为'-'.
 * @returns string 按照connector分割的日期字符串
 */
function transTimeHourMinu(time: string, connector?: string): string {
    if (!time && time !== '0') return ''
    // 判断入参是否全部是数字，如果全部是数字，则表示是时间戳转换的字符串，需要转换回数字，否则new Date会报错。
    let date: Date
    if (time) {
        date = isNumber(time) ? new Date(parseInt(time)) : new Date(time)
    } else {
        date = new Date()
    }
    const h =
        (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
    const m =
        (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
    const resultTime =  h + m
    return resultTime
}

export default {
    transTime,
    transDate,
    preDays,
    preDaysToUTCTimestamp,
    transTimeWithZone,
    transTimeWithCurrentZone,
    getUTCTimestamp,
    getCurrentTime,
    getCurrentDate,
    transToUTCTimestamp,
    transTimeHourMinu
}