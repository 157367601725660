import {defineStore} from 'pinia'

export const useUserInfo = defineStore('userInfo', {
  state: () => {
    return {
      data: {}
    }
  },
  actions: {
    pushUserInfo(param) {
      this.data = param
    }
  }
})

export const useUserInfoState = defineStore('userInfoState', {
  state: () => {
    return {
      data: {
        state: '',
        currentOrg: ''
      }
    }
  },
  actions: {
    pushUserInfoState(state, currentOrg) {
      this.data.state = state
      this.data.currentOrg = currentOrg
    }
  }
})

export const useTenantInfoState = defineStore('tenantInfoState', {
  state: () => {
    return {
      data: {
        state: '',
      }
    }
  },
  actions: {
    pushTenantInfoState(state) {
      this.data.state = state
    }
  }
})

export const useTenantInfo = defineStore('tenantInfo', {
  state: () => {
    return {
      data: {}
    }
  },
  actions: {
    pushTenantInfo(param) {
      this.data = param
    }
  }
})

export const useBatchConfigStore = defineStore('employee-batchConfig', {
  state: () => ({
    batchUserInfo: []
  }),
  actions: {
    setCurrentInfo(batchUserInfo) {
      this.batchUserInfo = batchUserInfo;
    },
  }
})
