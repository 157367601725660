<template>
  <sweet-popover v-if="isShowOrgFullName" trigger="click" :title='i18n("CCM.AGENT.CONTACT.ORG")' :placement="position" width="250px" popper-class="agent-name-popover" :content="orgFullName">
    <template #reference>
      <sweet-link :underline="false" type="primary" @click="showOrgFullName">
        {{ orgName || '' }}
      </sweet-link>
    </template>
  </sweet-popover>
  <span v-else>{{ orgName || '' }}</span>
</template>

<script setup lang="ts">
import { getCurrentInstance, ref, toRefs, computed } from "vue";
// 国际化封装
const { appContext: { config: { globalProperties } } } = getCurrentInstance() as any;
const i18n = (param: string, ...args: Array<any>): string => {
  return globalProperties.$t(param, args);
};

const props = defineProps({
   orgName: String, orgFullName: String, isShowOrgFullName: Boolean
});
const {orgName, orgFullName, isShowOrgFullName } = toRefs(props);
const loading = ref<boolean>(false);
const position = computed(() => window.isRTL ? 'left' : 'right')
const showOrgFullName = () =>{
  isShowOrgFullName.value = true;
}
</script>

<style scoped lang="less">
.hlds-downline {
  border-bottom: 1px solid #ccccccad;
  padding-bottom: 10px;
}

.agent-name-popover {
  padding: 0;
  max-width: 250px;
  height: 180px;
  word-wrap: break-word;

  .agent-name-popover--content {
    max-width: 250px;
    width: fit-content(250px);
  }

  .hlds-div-show {
    width: 250px;
    height: 150px;
    left: 15%;
    top: 45%;
    padding: 15px 0;
    transform: translate(-50%,-50%);
    background: inherit;
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(229, 229, 229, 1);
    border-radius: 8px;
    -moz-box-shadow: 5px 5px 5px rgba(204, 204, 204, 0.349019607843137);
    -webkit-box-shadow: 5px 5px 5px rgba(204, 204, 204, 0.349019607843137);
    box-shadow: 5px 5px 5px rgba(204, 204, 204, 0.349019607843137);
    z-index: 10;
  }
}

</style>