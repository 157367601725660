<template>
  <div>
    <sweet-dialog v-model="dialogVisible" :title="$t('isales.basicinfo.title.chooseCallNo')" :close-on-click-modal="false" width="616" class="aicc-dialog-limit-height">
      <aicc-table
          :tableData="tableData"
          :tableColumns="tableColumns"
          :tableTools="{ showPagination: true, columnConfig: false }"
          :paginationConfig="paginationConfig"
          :height="430"
          @handlePagination="handlePagination"
          ref='refAiccTable'>
      </aicc-table>
      <template #footer>
              <span class="dialog-footer">
                <sweet-button @click="dialogVisible = false">{{ $t('isales.common.title.cancel') }}</sweet-button>
                <sweet-button type="primary" @click="confirm">{{ $t('isales.common.title.confirm') }}</sweet-button>
              </span>
      </template>
    </sweet-dialog>
  </div>
</template>

<script>
import {
  queryCallerNo
} from '@/views/isales/api/manualCallTask'
import appConfig from '@/config'

export default {
  name: 'ChooseSingleCallNo',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    callNo: {
      type: String
    }
  },
  data() {
    return {
      tableData: [],
      tableColumns: [
        {
          type: 'radio',
          radioKey: 'accessCode', // 单选绑定的字段名
          width: '50px'
        },
        {
          label: this.$t('isales.taskinfo.field.callNo'), // 主叫号码
          prop: 'accessCode',
        }
      ],
      paginationConfig: {
        offset: 0,
        limit: 10, // 每页条数
        total: 0, //总条数
        layout: appConfig.pagination.LAYOUT.COMPACT
      }
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      }
    }
  },
  async created() {
    await this.queryCallNoList()
    if (this.callNo) {
      this.$nextTick(() => {
        this.$refs.refAiccTable.setRadioCurrentValue(this.callNo)
      })
    }
  },
  methods: {
    async queryCallNoList() {
      const { limit, offset } = this.paginationConfig
      const result = await queryCallerNo({ limit, offset })
      this.paginationConfig.total = result.count
      this.tableData = result.accessCodes
    },
    handlePagination(data) {
      Object.assign(this.paginationConfig, data)
      this.queryCallNoList()
    },
    confirm() {
      const selectedAccessCode = this.$refs.refAiccTable.getRadioCurrentRow()
      this.dialogVisible = false
      if($.isEmptyObject(this.$refs.refAiccTable.getRadioCurrentRow())){
        this.$message({
          type: 'error',
          duration: 10000,
          showClose: true,
          offset: 56,
          message: this.$t('isales.management.message.SelectNumber')
        });
      }
      this.$emit('confirm', selectedAccessCode.accessCode)
    }
  }
}
</script>

<style lang="less" scoped>
</style>