<script>
import {useUserInfoState} from "@/views/sumweb/stores/userInfo";
import {CirclePlus} from '@element-plus/icons-vue';
import skillQueueCard from "@/views/sumweb/components/user/skill-queue-card.vue";
import unbindAgentDialog from "@/views/sumweb/components/user/unbind-agent-dialog.vue";
import cookie from "@/utils/cookie";
import {
  addGroupBatch,
  deleteGroup,
  queryAgentInfoByUserId,
  queryAgentRoleItems,
  queryAllGroup,
  queryAttendedGroupByWorkNo,
  queryTenantFeatures,
  queryUsersById,
  updateAgentInfo,
  validateTransparentDisplayFlag
} from "@/views/sumweb/api";
import { ElMessageBox } from "element-plus";
import AiccElMessage from '@/utils/el-message'

export default {
  name: "employee-config",
  components: {
    CirclePlus,
    skillQueueCard,
    unbindAgentDialog
  },
  data() {
    return {
      tenantSpaceId: '',
      currentUserId: '',
      locale: '',
      configData: {
        id: undefined,
        ccRoleId: undefined,
        agentType: undefined,
        agentTypeName: undefined,
        workNo: undefined,
        sipAccount: undefined,
        authFlag: undefined,
        selfPhone: undefined,
        belongQuality: undefined,
        belongSoft: undefined,
        AIRecognition: false,
        dualChannelRecFlag: false,
        singlePhoneFlag: false,
        transparentDisplayFlag: false,
        password: undefined,
        newPwdConfirm: undefined,
        checkPwd: undefined,
        validTime: undefined,
        baseInfo: {},
      },
      state: '',
      roleItems: [],
      authDisable: true,
      aiDisable: true,
      dualchannelDisable: true,
      qualityGroups: [],
      agentGroups: [],
      queryAgentGroup: {},
      queryQualityGroup: {},
      configFormRule: {
        password: [
          {
            validator: this.passwordValidator,
            trigger: ['blur']
          }
        ],
        newPwdConfirm: [
          {
            validator: this.confirmPwdValidator,
            trigger: ['blur']
          },
          {
            validator: this.passwordValidator,
            trigger: ['blur']
          }
        ],
        checkPwd: [
          {
            validator: this.pwdInputValidator,
            trigger: ['blur']
          },
          {
            validator: this.pwdInputValidator,
            trigger: ['blur']
          }
        ],
        workNo: [
          {
            required: true,
            trigger: ['change'],
            message: $t('isales.business.result.message.must')
          }
        ],
        agentType: [
          {
            required: true,
            trigger: ['change'],
            message: $t('isales.business.result.message.must')
          }
        ],
        ccRoleId: [
          {
            required: !this.isBatchConfig,
            trigger: ['change'],
            message: $t('isales.business.result.message.must')
          }
        ],
        authFlag: [
          {
            required: !this.isBatchConfig,
            trigger: ['change'],
            message: $t('isales.business.result.message.must')
          }
        ]
      },
      skillKindProp: 0,
      skillListProp: [],
      skillGroupProp: [],
      defaultSkillProp: [],
      qualityDisabled: false,
      isFederal: false
    }
  },
  methods: {
    handleFocusWorkNo() {
      this.$refs.unbindAgentRef.display = true;
      this.$refs.agentInputRef.blur();
    },
    handleAIRecognition() {
      if (this.configData.AIRecognition && this.configData.dualChannelRecFlag) {
        ElMessageBox.confirm(
            $t('SM.EMPLOYEE.FEATURE.CONFLICT'),
            $t('SM.COMMON.TIPS.POPUP_INFO_TITLE'),
            {
              type: 'warning'
            }
        ).then(() => {
          this.configData.dualChannelRecFlag = false;
        }).catch(() => {
          this.configData.AIRecognition = false;
        })
      }
    },
    handleDualchannelRecSupport() {
      if (this.configData.AIRecognition && this.configData.dualChannelRecFlag) {
        ElMessageBox.confirm(
            $t('SM.EMPLOYEE.FEATURE.CONFLICT'),
            $t('SM.COMMON.TIPS.POPUP_INFO_TITLE'),
            {
              type: 'warning'
            }
        ).then(() => {
          this.configData.AIRecognition = false;
        }).catch(() => {
          this.configData.dualChannelRecFlag = false;
        })
      }
    },
    handleSave(accountId) {
      this.$refs.configFormRef.validate(valid => {
        if (!valid) {
          return;
        }
        let param = {
          id: this.configData.id,
          workNo: this.configData.workNo,
          accountId: accountId,
          ccRoleId: this.configData.ccRoleId,
          agentType: this.configData.agentType,
          authFlag: this.configData.authFlag,
          selfPhone: this.configData.selfPhone,
          password: this.configData.password,
          checkPwd: this.configData.checkPwd,
          validTime: this.configData.validTime,
          AIRecognition: this.configData.AIRecognition,
          dualChannelRecFlag: this.configData.dualChannelRecFlag,
          singlePhoneFlag: this.configData.singlePhoneFlag,
          transparentDisplayFlag: this.configData.transparentDisplayFlag,
        }
        let skillKind = this.$refs.skillRef.skillKind;
        if (skillKind === 0) {
          // 技能队列
          param.skillList = [
            ...this.$refs.skillRef.voiceTableData,
            ...this.$refs.skillRef.videoTableData,
            ...this.$refs.skillRef.mediaTableData,
            ...this.$refs.skillRef.voiceDialTableData,
            ...this.$refs.skillRef.videoDialTableData,
          ];
        } else {
          // 技能组
          let groupList = this.$refs.skillRef.groupList;
          let skillGroupList = [];
          for (let group of groupList) {
            skillGroupList.push({
              skillGroupId: group.skillGroupId,
              skillGroupName: group.name,
              skillGroupUid: group.id,
              skillNames: group.skills
            })
          }
          param.skillGroupList = skillGroupList;
          param.defaultSkillList = [];
          for (let group of this.$refs.skillRef.defaultTableList) {
            if (group.default) {
              param.defaultSkillList.push(group.default);
            }
          }
        }
        // 配置信息
        updateAgentInfo(param).then(res => {
          if (res) {
            if (res.returnCode === "0") {
              AiccElMessage.success($t('ccm.agent.tips.confSuccess'));
              this.handleCmsGroup();
            } else if (res.returnCode === "60101020049") {
              AiccElMessage.error($t('ccm.agent.tips.confFail'));
              window.location.href = '/service-cloud/aicc-web/index/index.html';
            } else if (res.returnCode === "1124") {
              AiccElMessage.error($t('ccm.agent.updateSoftPhone.accountPasswordFailed'));
            } else {
              AiccElMessage.error(this.getErrorMessage(res.returnCode));
            }
          }
        }).catch(() => {
          AiccElMessage.error($t('SM.COMMON.MESSAGE.OPERATE_FAIL'));
        })
      })
    },
    async handleCmsGroup() {
      // 加入质检组
      if (this.isCreate && this.configData.belongQuality) {
        await this.joinQulityGroup();
      }

      // 编辑情况下
      if (this.isEdit && this.configData.belongQuality) {
        if (!this.queryQualityGroup.id) {
          // 1.新的有，旧的没有
          await this.joinQulityGroup();
        } else if (this.queryQualityGroup.id !== this.configData.belongQuality.id) {
          // 2.新旧都有，新旧不同，需要先退出该组才能重新加入新的组
          await deleteGroup(this.queryQualityGroup.configurationId).then(res => {
            if (res.returnCode === "0") {
              this.joinQulityGroup();
            }
          });
        }
        // 3.新旧都有，新旧相同，不做处理
      }

      if (this.isEdit && !this.configData.belongQuality) {
        // 1.旧的有，新的无
        if (this.queryQualityGroup.id) {
          await deleteGroup(this.queryQualityGroup.configurationId);
        }
        // 2.新旧都无，不做处理
      }

      // 加入话务组
      if (this.isCreate && this.configData.belongSoft) {
        await this.joinAgentGroup();
      }

      if (this.isEdit && this.configData.belongSoft) {
        if (!this.queryAgentGroup.id) {
          // 1.新的有，旧的没有
          await this.joinAgentGroup();
        } else if (this.queryAgentGroup.id !== this.configData.belongSoft.id) {
          // 2.新旧都有，新旧不同，需要先退出该组才能重新加入新的组
          await deleteGroup(this.queryAgentGroup.configurationId).then(res => {
            if (res.returnCode === "0") {
              this.joinAgentGroup();
            }
          });
        }
        // 3.新旧都有，新旧相同，不做处理
      }

      if (this.isEdit && !this.configData.belongSoft) {
        // 1.旧的有，新的无
        if (this.queryAgentGroup.id) {
          await deleteGroup(this.queryAgentGroup.configurationId);
        }
        // 2.新旧都无，不做处理
      }

      this.$event.backInTab();
    },
    joinQulityGroup() {
      let groupParam = {
        agentGroupInfoList: [
          {
            workNo: this.configData.workNo,
            accountCode: this.userAccount,
            accountName: this.userName,
            ccRoleId: this.configData.ccRoleId,
          }
        ],
        groupId: this.configData.belongQuality.id,
        groupName: this.configData.belongQuality.groupName
      };
      addGroupBatch(groupParam).then(res => {
        if (res && res.returnCode !== "0") {
          AiccElMessage.error(res.errorMsg || res.description);
        }
      });
    },
    joinAgentGroup() {
      let groupParam = {
        agentGroupInfoList: [
          {
            workNo: this.configData.workNo,
            accountCode: this.userAccount,
            accountName: this.userName,
            ccRoleId: this.configData.ccRoleId,
          }
        ],
        groupId: this.configData.belongSoft.id,
        groupName: this.configData.belongSoft.groupName
      };
      addGroupBatch(groupParam).then(res => {
        if (res && res.returnCode !== "0") {
          AiccElMessage.error(res.errorMsg || res.description);
        }
      });
    },
    passwordValidator(rule, value, callback) {
      if (!value) {
        callback();
        return;
      }
      if (!this.configData.singlePhoneFlag) {
        // 非单电话座席标识，需要校验长度
        if (value.length < 8 || value.length > 32) {
          callback(new Error($t('provision.callcenterinstances.agentWasPassLengthValidateFailed')));
          return;
        }
        // 密码包含特殊字符
        let regUpper = /[A-Z]/;
        let regLower = /[a-z]/;
        let regNum = /[0-9]/;
        let regSpecial = /[~!@#$%&*()-/_=+{};:'",<.>?[\]]/
        let complex = 0;
        if (regLower.test(value)) {
          ++complex;
        }
        if (regUpper.test(value)) {
          ++complex;
        }
        if (regNum.test(value)) {
          ++complex;
        }
        if (regSpecial.test(value)) {
          ++complex;
        }
        if (complex < 3) {
          callback(new Error($t('provision.callcenterinstanceslist.vcallcenterDeatil.passValidateFailed')));
          return;
        }
      } else {
        // 单电话座席标识秘密只能为纯数字
        let regNum = /^[0-9]*$/;
        if (!regNum.test(value)) {
          callback(new Error($t('ccm.agent.message.validatordigit')));
          return;
        }
      }
      callback();
    },
    confirmPwdValidator(rule, value, callback) {
      if (this.configData.password != value) {
        callback(new Error($t('provision.tenant.management.pwd.checkmsg')));
        return;
      }
      callback();
    },
    pwdInputValidator(rule, value, callback) {
      if (this.configData.password && this.configData.newPwdConfirm && this.configData.checkPwd) {
        callback();
        return
      }
      if (!this.configData.password && !this.configData.newPwdConfirm && !this.configData.checkPwd) {
        callback();
        return
      }
      callback(new Error($t('ccm.agent.message.validatormsg')));
    },
    handleExpire() {
      if (this.configData.validTime > 180) {
        this.configData.validTime = 180
      }
    },
    getCurrentAgent(row) {
      this.configData.id = row.id;
      this.configData.workNo = row.workNo;
      this.configData.sipAccount = row.sipAccount;
      if (row.agentType !== 255) {
        this.configData.agentType = row.agentType;
        this.setAgentTypeName(row)
      }
      if (row.ccRoleId !== 255) {
        this.configData.ccRoleId = row.ccRoleId;
      }
      // 默认显示UAP认证
      this.configData.authFlag = row.authFlag || "0";
    },
    setAgentTypeName(row) {
      for (let type of this.agentTypes) {
        if (type.agentType === row.agentType) {
          this.configData.agentTypeName = type.agentTypeName;
          break;
        }
      }
    },
    handletransparentDisplayChange() {
      if (this.configData.transparentDisplayFlag && !this.configData.selfPhone) {
        AiccElMessage.error($t('ccm.agent.label.selfPhoneNotNull'));
        this.configData.transparentDisplayFlag = false;
      }
      if (this.configData.transparentDisplayFlag && this.configData.selfPhone) {
        let param = {
          transparentDisplayFlag: this.configData.transparentDisplayFlag,
          selfPhone: this.configData.selfPhone
        }
        validateTransparentDisplayFlag(param).then(res => {
          if (res && res.returnCode != 0) {
            AiccElMessage.error(res.description);
            this.configData.transparentDisplayFlag = false;
          }
        })
      }
    },
    getErrorMessage(key) {
      let errorCodeMap = {
        "0": $t('ccm.agent.message.agentConfSuccess'),
        "1": $t('ccm.agent.message.accRepeatFail'),
        "2": $t('ccm.agent.message.agentnotexist'),
        "3": $t('ccm.agent.message.agentCreateFail'),
        "4": $t('ccm.agent.message.agentUpdateFail'),
        "5": $t('ccm.agent.message.agentCTIFail'),
        "6": $t('ccm.agent.message.agentandskillnotmatch'),
        "7": $t('ccm.agent.message.synToCcpFailed'),
        "8": $t('ccm.agent.message.longCharacters'),
        "9": $t('ccm.agent.message.specialCharacters'),
        "10": $t('ccm.agent.message.agentnumreachmax'),
        "11": $t('ccm.agent.message.omsAgentTypeCheck'),
        "12": $t('ccm.agent.message.videoNotEnough'),
        "13": $t('ccm.agent.message.aiagentnummore'),
        "14": $t('ccm.agent.message.redisFailed'),
        "15": $t('ccm.agent.message.synToUapFailed'),
        "21": $t('ccm.agent.message.audioagentzero'),
        "22": $t('ccm.agent.message.videoagentzero'),
        "37": $t('ccm.agent.message.typenosame'),
        "41": $t('ccm.agent.message.agentnotexist'),
        "42": $t('ccm.agent.message.agenttypeinvalid'),
        "120005": $t('ccm.agent.message.configvalidatefailed'),
      };
      return errorCodeMap[key] ? errorCodeMap[key] : $t('ccm.agent.message.agentConfFail');
    },
  },
  watch: {
    "configData.ccRoleId": {
      handler(roleId) {
        if (roleId === 3) {
          this.qualityDisabled = false;
        } else {
          this.qualityDisabled = true;
          this.configData.belongQuality = undefined;
        }
      }
    }
  },
  computed: {
    CirclePlus() {
      return CirclePlus;
    },
    viewRoleName() {
      let find = this.roleItems.find(role => role.ccRoleId === this.configData.ccRoleId);
      if (find) {
        return find.roleName;
      }
    },
    viewAuthName() {
      let find = this.authType.find(auth => auth.authFlag === this.configData.authFlag);
      if (find) {
        return find.authFlagName;
      }
    },
    agentTypes() {
      return [
        {
          "agentType": 4,
          "agentTypeName": $t('ccm.agent.label.audio')
        },
        {
          "agentType": 11,
          "agentTypeName": $t('ccm.agent.label.video')
        },
        {
          "agentType": 5,
          "agentTypeName": $t('ccm.agent.label.mediaagent')
        },
        {
          "agentType": 99,
          "agentTypeName": $t('ccm.agent.label.versatileagent')
        }
      ]
    },
    authType() {
      if (this.authDisable) {
        return [
          {
            "authFlag": "0",
            "authFlagName": $t('ccm.agent.label.authtype.uap')
          }
        ]
      }
      return [
        {
          "authFlag": "0",
          "authFlagName": $t('ccm.agent.label.authtype.uap')
        },
        {
          "authFlag": "1",
          "authFlagName": $t('ccm.agent.label.authtype.unified')
        }
      ];
    },
    isView() {
      return this.mode === 'view';
    },
    isEdit() {
      return this.mode === 'config';
    },
    isCreate() {
      return this.mode === 'create';
    },
    isBatchConfig() {
      return this.state === 'batchConifg';
    },
  },
  beforeMount() {
    let userInfoState = useUserInfoState();
    this.state = userInfoState.data.state;
  },
  mounted() {
    this.tenantSpaceId = cookie.getCookie('tenant_space_id');
    this.currentUserId = cookie.getCookie('user_id');
    this.locale = cookie.getCookie('u-locale');

    queryAgentRoleItems(this.locale).then(res => {
      if (res) {
        this.roleItems = res;
      }
    });

    queryTenantFeatures().then(res => {
      if (res && res.tenantFeatures && res.tenantFeatures.length > 0) {
        let tenantFeatures = res.tenantFeatures;
        for (const feature of tenantFeatures) {
          // 统一认证
          if (feature.featureCode === '88002000100' && parseInt(feature.value) === 1) {
            this.authDisable = false;
          }
          // 智能座席
          if (feature.featureCode === '88000200100' && parseInt(feature.value) > 0) {
            this.aiDisable = false;
          }
          // 双轨道录音
          if (feature.featureCode === '88001400100' && parseInt(feature.value) === 1) {
            this.dualchannelDisable = false;
          }
        }
        if ((this.isCreate || this.isBatchConfig) && this.authDisable) {
          this.configData.authFlag = "0";
        }
      }
    })

    queryAllGroup().then(res => {
      if (res && res.length > 0) {
        res.forEach(group => {
          if (group.groupType === 2) {
            this.agentGroups.push(group);
          } else if (group.groupType === 3) {
            this.qualityGroups.push(group);
          }
        })
      }
    })

    if (this.isEdit || this.isView) {
      // 查询配置信息
      console.log("queryAgentInfoByUserId:", this.userId);
      queryAgentInfoByUserId(this.userId).then(res => {
        if (res && res.workNo) {
          this.configData = res;
          if (this.configData.agentType) {
            this.setAgentTypeName(this.configData);
          }
          if (res.ccAgentAndSkillList && res.ccAgentAndSkillList.length > 0) {
            this.skillListProp = res.ccAgentAndSkillList;
            this.skillKindProp = 0;
          } else if (res.ccAgentAndSkillGroupDOList && res.ccAgentAndSkillGroupDOList.length > 0) {
            this.skillGroupProp = res.ccAgentAndSkillGroupDOList;
            if (res.defaultSkillList && res.defaultSkillList.length > 0) {
              this.defaultSkillProp = res.defaultSkillList;
            }
            this.skillKindProp = 1;
          } else {
            this.skillKindProp = -1;
          }

          this.configData.AIRecognition = res.AIRecognition === 1;
          this.configData.dualChannelRecFlag = res.dualChannelRecFlag === 1;
          this.configData.singlePhoneFlag = res.singlePhoneFlag === 1;
          this.configData.transparentDisplayFlag = res.transparentDisplayFlag === 1;

          // 查询加入的组
          queryAttendedGroupByWorkNo(this.configData.workNo).then(res => {
            if (res && res.inspectorGroup) {
              this.configData.belongQuality = res.inspectorGroup;
              this.queryQualityGroup = res.inspectorGroup;
            }
            if (res && res.agentGroup) {
              this.configData.belongSoft = res.agentGroup;
              this.queryAgentGroup = res.agentGroup;
            }
          });

          // 默认显示UAP认证
          if (!this.configData.authFlag) {
            this.configData.authFlag = "0";
          }
        } else {
          this.skillKindProp = -1;
        }
      });
    }

    if (!this.isView) {
      queryUsersById(this.tenantSpaceId, this.currentUserId).then(userRes => {
        if (userRes && userRes.users && userRes.users.length === 1) {
          let user = userRes.users[0];
          this.isFederal = user.isFederal;
        }
      })
    }
  },
  props: {
    allocateAgent: {
      type: Boolean,
      required: false,
      default: true
    },
    userId: {
      type: String,
      required: false,
      default: ''
    },
    userAccount: {
      type: String,
      required: false,
      default: ''
    },
    userName: {
      type: String,
      required: false,
      default: ''
    },
    mode: {
      type: String,
      default: ()=> {
        return ''
      }
    }
  }
}
</script>

<template>
  <sweet-form class="configForm"
           ref="configFormRef"
           :model="configData"
           :rules="configFormRule"
           label-position="left"
           label-width="auto"
           :disabled="this.isView">
    <slot name="topCard" :topCard="configData.baseInfo"/>
    <sweet-card>
      <div class="card-title">{{ $t('ccm.agent.title.agentinfo') }}</div>

      <slot name="formTop"/>
      <div v-show="allocateAgent">
        <!--座席工号-->
        <sweet-form-item required
                      prop="workNo"
                      v-if="!isBatchConfig"
                      :label="$t('ccm.agent.label.workNo')">
          <div class="width-400px">
            <sweet-input v-model="configData.workNo"
                      ref="agentInputRef"
                      class="aicc-input-dialog"
                      v-if="!isView"
                      readonly
                      @focus="handleFocusWorkNo"
                      clearable/>
            <span v-if="isView">{{ configData.workNo || '-' }}</span>
          </div>

        </sweet-form-item>
        <!--座席类型-->
        <sweet-form-item required
                      prop="agentType"
                      :label="$t('ccm.agent.label.agentType')">
          <div class="width-400px">
            <sweet-select v-model="configData.agentType"
                       v-if="isBatchConfig"
                       value-key="agentType"
                       placeholder="">
              <sweet-option v-for="item in agentTypes"
                         :key="item.agentType"
                         :label="item.agentTypeName"
                         :value="item.agentType"/>
            </sweet-select>
            <span v-if="isView">{{ configData.agentTypeName || '-' }}</span>
            <sweet-input :model-value="configData.agentTypeName"
                      v-if="isCreate || isEdit"
                      readonly
                      disabled/>
          </div>
        </sweet-form-item>
        <!--平台角色-->
        <sweet-form-item required
                      prop="ccRoleId"
                      :label="$t('ccm.agent.label.ccRoleId')">
          <div class="width-400px">
            <sweet-select v-model="configData.ccRoleId"
                       v-if="!isView"
                       value-key="ccRoleId"
                       placeholder="">
              <sweet-option v-for="item in roleItems"
                         :key="item.ccRoleId"
                         :label="item.roleName"
                         :value="item.ccRoleId"/>
            </sweet-select>
            <span v-if="isView">{{ viewRoleName || '-' }}</span>
          </div>
        </sweet-form-item>
        <!--软电话号码-->
        <sweet-form-item prop="sipAccount"
                      v-if="!isBatchConfig"
                      :label="$t('ccm.agent.label.sipAccount')">
          <div class="width-400px">
            <sweet-input v-model="configData.sipAccount"
                      v-if="!isView"
                      disabled
                      readonly/>
            <span v-if="isView">{{ configData.sipAccount || '-' }}</span>
          </div>

        </sweet-form-item>
        <!--软电话号码认证方式-->
        <sweet-form-item prop="authFlag"
                      v-if="!isBatchConfig"
                      required
                      :label="$t('ccm.agent.label.authType')">
          <div class="width-400px">
            <sweet-radio-group v-model="configData.authFlag" v-if="!isView">
              <sweet-radio v-for="item in authType"
                        :key="item.authFlag"
                        :label="item.authFlag">
                {{ item.authFlagName }}
              </sweet-radio>
            </sweet-radio-group>
            <span v-if="isView">{{ viewAuthName || '-' }}</span>
          </div>


        </sweet-form-item>
        <!--坐席手机/固话-->
        <sweet-form-item prop="selfPhone"
                      v-if="!isBatchConfig"
                      :label="$t('ccm.agent.label.agentTel')">
          <div class="width-400px">
            <sweet-input v-model="configData.selfPhone"
                      v-if="!isView"
                      oninput="value=value.replace(/[^0-9^-]/g,'')"
                      :maxlength="24"
                      clearable/>
            <span v-if="isView">{{ configData.selfPhone || '-' }}</span>
          </div>
        </sweet-form-item>
        <!--归属质检组-->
        <sweet-form-item prop="belongQuality"
                      v-if="!isBatchConfig && !qualityDisabled"
                      :label="$t('SM.EMPLOYEE.INSPECTION.GROUP')">
          <div class="width-400px">
            <sweet-select v-model="configData.belongQuality"
                       v-if="!isView"
                       value-key="id"
                       clearable
                       placeholder="">
              <sweet-option v-for="item in qualityGroups"
                         :key="item.id"
                         :label="item.groupName"
                         :value="item"/>
            </sweet-select>
            <span v-if="isView">
              {{ configData.belongQuality ? (configData.belongQuality.groupName || '-') : '-' }}
            </span>
          </div>
        </sweet-form-item>
        <!--归属话务组-->
        <sweet-form-item prop="belongSoft"
                      v-if="!isBatchConfig"
                      :label="$t('SM.EMPLOYEE.SOFTCONSOLE.GROUP')">
          <div class="width-400px">
            <sweet-select v-model="configData.belongSoft"
                       v-if="!isView"
                       value-key="id"
                       clearable
                       placeholder="">
              <sweet-option v-for="item in agentGroups"
                         :key="item.id"
                         :label="item.groupName"
                         :value="item"/>
            </sweet-select>
            <span v-if="isView">
              {{ configData.belongSoft ? (configData.belongSoft.groupName || '-') : '-' }}
            </span>
          </div>
        </sweet-form-item>
        <!--智能识别-->
        <sweet-form-item prop="AIRecognition"
                      v-if="this.aiDisable === false"
                      :label="$t('ccm.agent.label.airecog')">
          <sweet-switch v-model="configData.AIRecognition"
                     @change="handleAIRecognition"/>
        </sweet-form-item>
        <!--双轨道录音-->
        <sweet-form-item prop="dualChannelRecFlag"
                      v-if="this.dualchannelDisable === false"
                      :label="$t('ccm.agent.label.dualChannelRecFlag')">
          <sweet-switch v-model="configData.dualChannelRecFlag"
                     @change="handleDualchannelRecSupport"/>
        </sweet-form-item>
        <!--单电话坐席标识-->
        <sweet-form-item prop="singlePhoneFlag"
                      :label="$t('ccm.agent.label.singlePhoneFlag')">
          <sweet-switch v-model="configData.singlePhoneFlag"/>
        </sweet-form-item>
        <!--坐席透显标识-->
        <sweet-form-item prop="transparentDisplayFlag"
                      v-if="!isBatchConfig"
                      :label="$t('ccm.agent.label.transparentDisplayFlag')">
          <sweet-switch v-model="configData.transparentDisplayFlag" @change="handletransparentDisplayChange"/>
        </sweet-form-item>
      </div>

    </sweet-card>

    <sweet-card id="middle-card" v-show="allocateAgent">
      <div class="card-title">{{ $t('SM.EMPLOYEE.CHECKED-IN.PASSWORD') }}</div>

      <sweet-form-item prop="password"
                    v-if="!isView"
                    :label="$t('base.newPassword')">
        <sweet-input v-model="configData.password"
                  class="width-400px"
                  autocomplete="off"
                  type="password"
                  :placeholder="$t('SM.ORGSTAFF.HINT.PLEASE_INPUT')"
                  show-password/>
      </sweet-form-item>
      <sweet-form-item prop="newPwdConfirm"
                    v-if="!isView"
                    :label="$t('cms.vcallecenter.label.renewpwd')">
        <sweet-input v-model="configData.newPwdConfirm"
                  class="width-400px"
                  autocomplete="off"
                  type="password"
                  :placeholder="$t('SM.ORGSTAFF.HINT.PLEASE_INPUT')"
                  show-password/>
      </sweet-form-item>
      <sweet-form-item prop="checkPwd"
                    v-if="!isView && !isFederal"
                    :label="$t('ccm.vcallcenter.config.popup.currentaccount.pwd')">
        <sweet-input v-model="configData.checkPwd"
                  class="width-400px"
                  autocomplete="off"
                  type="password"
                  :placeholder="$t('SM.ORGSTAFF.HINT.PLEASE_INPUT')"
                  show-password/>
      </sweet-form-item>
      <sweet-form-item prop="validTime"
                    :label="$t('ccm.agent.label.validTime')">
        <div class="width-400px">
          <sweet-input v-model="configData.validTime"
                    v-if="!isView"
                    oninput="value=value.replace(/[^0-9]/g,'')"
                    @blur="handleExpire"
                    :placeholder="$t('SM.ORGSTAFF.HINT.PLEASE_INPUT')"
                    clearable/>
          <span v-if="isView">{{ configData.validTime || '-' }}</span>
        </div>
      </sweet-form-item>

    </sweet-card>

    <!--座席工号-->
    <unbind-agent-dialog ref="unbindAgentRef" :id="configData.id" :role-items="roleItems" @get-agent="getCurrentAgent"/>
    <!--技能队列-->
    <skill-queue-card ref="skillRef"
                      v-if="allocateAgent"
                      :skill-kind-prop="skillKindProp"
                      :skill-group-list="skillGroupProp"
                      :default-skill-list="defaultSkillProp"
                      :skill-list="skillListProp"/>
  </sweet-form>
</template>

<style lang="less" scoped>
.card-title {
  font-weight: bolder;
  margin-bottom: 20px;
}

.width-400px {
  width: 400px;
  border-radius: 4px;
}

#middle-card {
  margin-top: 16px;
  margin-bottom: 16px;
}


</style>