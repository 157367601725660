<template>
    <div>
        <sweet-form ref="taskInfoForm" :label-position="labelPosition" class="user-info-form" :model="userInfo.data"
                    :rules="userInfoRules"
                    label-width="auto">
            <sweet-card class="employee-info-card">
                <div class="aicc-align--absolute-left aicc-p-bottom--medium">
                    <span class="card-header">{{ $t('SM.ORGSTAFF.LABEL.STAFF_INFO') }}</span>
                </div>
                <!-- 帐号 -->
                <sweet-form-item :label="$t('SM.ORGSTAFF.LABEL.EMPLOYEE_ACCOUNT')" prop="userAccount">
                    <span v-show="isView || UserInfo.data.userId == '102'">{{ userInfo.data.userAccount }}</span>
                    <sweet-input clearable class="width-400px" v-model="userInfo.data.userAccount" :placeholder="$t('SM.LABEL.ACCOUNT')"
                                 :disabled="isEdit" v-show="!(isView || UserInfo.data.userId == '102')"/>
                </sweet-form-item>
                <!-- 用户名 -->
                <sweet-form-item :label="$t('SM.ORGSTAFF.LABEL.EMPLOYEE_EMPLOYEENAME')" prop="userName">
                    <span v-show="isView || UserInfo.data.userId == '102'">{{ userInfo.data.userName }}</span>
                    <sweet-input clearable class="width-400px" v-model="userInfo.data.userName" :placeholder="$t('SM.LABEL.USERNAME')"
                                 v-show="!(isView || UserInfo.data.userId == '102')"/>
                </sweet-form-item>
                <!--邮箱-->
                <sweet-form-item :label="$t('SM.ORGSTAFF.TITLE.CONTACT_EMAIL')" prop="email">
                            <span v-show="isView || UserInfo.data.userId == '102'">{{
                                    userInfo.data.email
                                        ? userInfo.data.email : "-"
                                }}</span>
                    <sweet-input clearable class="width-400px" v-model="userInfo.data.email" :placeholder="$t('SM.LABEL.EMAIL')"
                                 v-show="!(isView || UserInfo.data.userId == '102')"/>
                </sweet-form-item>
                <!--联系电话-->
                <sweet-form-item :label="$t('SM.ORGSTAFF.TITLE.CONTACT_PHONE')" prop="phone">
                            <span v-show="isView || UserInfo.data.userId == '102'">{{
                                    userInfo.data.phone ?
                                        userInfo.data.phone : "-"
                                }}</span>
                    <sweet-input clearable class="width-400px" v-model="userInfo.data.phone" :placeholder="$t('SM.LABEL.PHONE')"
                                 v-if="!(isView || UserInfo.data.userId == '102')"/>
                </sweet-form-item>
                <!--密码规则组-->
                <sweet-form-item :label="$t('SM.ORGSTAFF.LABEL.EMPLOYEE_PWDRULEGROUP')" prop="ruleGroupCode">
                            <span v-show="isView || UserInfo.data.userId == '102'">{{
                                    dealRuleGroupCode(userInfo.data.ruleGroupCode)
                                }}</span>
                    <div class="width-400px" v-show="!(isView || UserInfo.data.userId == '102')">
                        <aicc-select v-model="userInfo.data.ruleGroupCode" :options="PwdGroupoptions.data" :disabled="isEdit"></aicc-select>
                    </div>
                </sweet-form-item>
                <!--密码-->
                <sweet-form-item :label="$t('SM.ORGSTAFF.LABEL.STAFF_PASSWORD')" type="password" prop="password"
                                 v-if="isCreate">
                    <sweet-input clearable class="width-400px" type="password" :placeholder="$t('SM.LABEL.PASSWORD')" v-model="userInfo.data.password"/>
                </sweet-form-item>
                <!--再次输入密码-->
                <sweet-form-item :label="$t('SM.ORGSTAFF.LABEL.STAFF_PASSWORDAGAIN')" type="password"
                                 prop="adminPasswordConfirm" v-if="isCreate">
                    <sweet-input clearable class="width-400px" type="password" :placeholder="$t('SM.LABEL.PASSWORD')"
                                 v-model="userInfo.data.adminPasswordConfirm"/>
                </sweet-form-item>
                <!--所属组织-->
                <sweet-form-item :label="$t('SM.ORGSTAFF.LABEL.EMPLOYEE_ORG')" prop="orgId">
                    <span v-show="isView || UserInfo.data.userId == '102'">{{ orgnationDic[userInfo.data.orgId] }}</span>
                    <div class="width-400px" v-show="!(isView || UserInfo.data.userId == '102')">
                        <org-tree-select ref="orgRef" :data="orgdata" :disabled="isEdit" :isChangeImmdiate="true" @onSelectChange="onOrgSelected" :clearable="false"></org-tree-select>
                    </div>
                </sweet-form-item>
                <!--可访问组织-->
                <sweet-form-item :label="$t('SM.COMMON.LABEL.ACCESS_RELATION')" prop="accessOrg"
                                 v-if="!(isIndependentDeployment() || cookieUtils.getCookie('tenant_space_id') == 'defaultSpace')">
                <aicc-tooltip :open-delay="200" placement="top" v-if="isView || UserInfo.data.userId == '102'" @click="showManger" overflowTargetSelector=".item">
                        <template #content>
                            <div style="max-width:400px">{{
                                   dealAccessOrg(userInfo.data.accessOrg)
                                }}</div>
                        </template>
                        <span class="item" v-show="isView || UserInfo.data.userId == '102'">{{
                                    dealAccessOrg(userInfo.data.accessOrg)
                                }}</span>
                    </aicc-tooltip>
                    <div class="width-400px" v-show="!(isView || UserInfo.data.userId == '102')">
                        <org-tree-select ref="orgsRef" :data="orgdata" multiple @onSelectChange="onOrgsSelected" @onClear="onOrgsClear" ></org-tree-select>
                    </div>
                </sweet-form-item>
                <!--职位-->
                <sweet-form-item :label="$t('SM.ORGSTAFF.LABEL.EMPLOYEE_TITLE')" prop="title">
                            <span v-show="isView || UserInfo.data.userId == '102' || (UserInfo.data.userId == cookieUtils.getCookie('user_id') && isEdit)">{{
                                    dealTitle(userInfo.data.title)
                                }}</span>
                    <div class="width-400px" v-show="!(isView || UserInfo.data.userId == '102' || (UserInfo.data.userId == cookieUtils.getCookie('user_id') && isEdit))">
                        <aicc-select v-model="userInfo.data.title" clearable :options="Titleoptions.data"></aicc-select>
                    </div>
                </sweet-form-item>
                <!--角色-->
                <sweet-form-item :label="$t('SM.ORGSTAFF.TITLE.STAFF_ROLE_BASIC')" prop="taskName">
                  <aicc-tooltip v-show="isView || (UserInfo.data.userId == cookieUtils.getCookie('user_id') && isEdit)" :open-delay="200" placement="top" overflowTargetSelector=".item">
                        <template #content>
                            <div style="max-width:400px">{{ dealRole(userInfo.data.roleIds) }}</div>
                        </template>
                        <div style="max-width:800px" v-show="isView || (UserInfo.data.userId == cookieUtils.getCookie('user_id') && isEdit)" class="item">{{ dealRole(userInfo.data.roleIds) }}</div>
                    </aicc-tooltip>
                    <div class="width-400px" v-show="!(isView || (UserInfo.data.userId == cookieUtils.getCookie('user_id') && isEdit))">
                        <sweet-tree-select clearable :disabled="isView" v-model="userInfo.data.roleIds"
                                           :node-key="roledefaultProps.value" :data="roledata.data" multiple filterable
                                           :props="roledefaultProps" :filter-node-method="filterNodeMethod"
                                           :render-after-expand="false" show-checkbox collapse-tags check-strictly
                                           check-on-click-node
                                           popper-class="employee-tree-select-popper"
                                           :placement="popperPlacement"
                                           class="org-select-dropdown"/>
                    </div>
                </sweet-form-item>
                <!--描述-->
                <sweet-form-item :label="$t('SM.ORGSTAFF.LABEL.STAFF_DESC')" prop="description">
                    <aicc-tooltip v-if="isView || UserInfo.data.userId == '102'" :open-delay="200" placement="top">
                        <template #content>
                            <div style="max-width:400px" class="tree-node-name">{{
                                    userInfo.data.description ?
                                        userInfo.data.description : "-"
                                }}
                            </div>
                        </template>
                        <span class="tree-node-name">{{
                                userInfo.data.description ?
                                    userInfo.data.description : "-"
                            }}</span>
                    </aicc-tooltip>
                    <sweet-input clearable class="width-400px" v-model="userInfo.data.description" :placeholder="$t('SM.LABEL.DESCRIPTION')"
                                 v-show="!(isView || UserInfo.data.userId == '102')"/>
                </sweet-form-item>
                <!--主管-->
                <sweet-form-item :label="$t('SM.ORGSTAFF.LABEL.TEAM_MANAGER')" prop="taskName">
                  <aicc-tooltip :open-delay="200" placement="top" overflowTargetSelector=".item">
                        <template #content>
                            <div style="max-width:800px">{{
                                    dealEmployeeContact(userInfo.data.employeeContact)
                                }}</div>
                        </template>
                        <span style="max-width:800px" class="item">{{
                                    dealEmployeeContact(userInfo.data.employeeContact)
                                }}</span>
                    </aicc-tooltip>
                </sweet-form-item>
            </sweet-card>
        </sweet-form>
    </div>
    <!-- 主管 -->
    <employeeManager ref="Manger" :orgid="userInfo.data.orgId">
    </employeeManager>
</template>

<script>
export default {
    name: 'employeeInfo',
    inheritAttrs: false,
    customOptions: {}
}
</script>

<script setup>
import {getCurrentInstance, onMounted, onUnmounted, onBeforeMount, reactive, ref, computed, toRefs, defineProps} from 'vue';
import {useUserInfo, useUserInfoState} from '@/views/sumweb/stores/userInfo';
import {
    createValidatePassword,
    queryOrgContacts,
    saveRolesForUser,
    saveAccessOrgForUser,
    updateEmployee,
    addEmployee,
    queryUsersById,
    queryEmployeeContacts,
    getListByUserIdForShow,
    queryMAX_USER_OWN_DUTY_ROLE_NUM,
    queryRoles,
    queryPwduleGroups,
    queryDataDictionary
} from '@/views/sumweb/api/index.js'
import { queryOrgList } from "@/utils/organization.js"
import employeeManager from "@/views/sumweb/components/user/employee-manager.vue"
import {checkUserName2, checkUserAccount2, checkEmail, checkPhone, checkDescription} from '@/views/sumweb/utils/validator'
import AiccElMessage from '@/utils/el-message'
import cookieUtils from "@/utils/cookie"
import { escapeAndEncodeBase64 } from '@/utils/base64Util.js'

const Manger = ref(null)
const taskInfoForm = ref(null)
const UserInfoState = useUserInfoState();
const UserInfo = useUserInfo();

const userInfo = reactive({
    data: {
        userAccount: "",
        userName: "",
        email: "",
        phone: "",
        ruleGroupCode: "Default",
        password: "",
        adminPasswordConfirm: "",
        orgId: "",
        title: "",
        description: "",
        accessOrg: [],
        roleIds: [],
        employeeContact: []
    }

});


const labelPosition = computed(() => window.isRTL ? 'right' : 'left')

let selectedRoles = []

const isIndependentDeployment = () => {
    return window.localStorage.getItem("isIndependentDeployment") === "true"
}

const dealRuleGroupCode = (code) => {
    let newCode = code
    PwdGroupoptions.data.forEach(item => {
        if (item.value === code) {
            newCode = item.label
            return false
        }
    })
    return newCode
}

const orgnationDic = computed(()=> {
  let res = {};
  orgnationListdata.data.forEach(item => {
    res[item.orgId] = item.orgName
  })
  return res
})

const popperPlacement = computed(()=> {
    return window.isRTL ? 'bottom-end': 'bottom-start'
})

const dealAccessOrg = (code) => {
    if (code == undefined || code.length == 0) {
        return "-"
    }
    let newCode = []
    for (let index = 0; index < code.length; index++) {
        const element = code[index];
        orgnationListdata.data.forEach(item => {
            if (item.orgId === element) {
                newCode.push(item.orgName)
            }
        })
    }
    return newCode.toString()
}

const dealEmployeeContact = (code) => {
    if (code == undefined || code.length == 0) {
        return "-"
    }
    const result = code.map(item => item.name +'（'+ item.code +'）');
    return result.join("，")
}

const dealRole = (code) => {
    if (code.length == 0) {
        return "-"
    }
    let newCode = []
    for (let index = 0; index < code.length; index++) {
        const element = code[index];
        roleListData.forEach(item => {
            if (item.roleId === element) {
                newCode.push(item.roleName)
                return false
            }
        })
    }
    return newCode.join("，")
}

const dealTitle = (code) => {
    let newCode = code
    Titleoptions.data.forEach(item => {
        if (item.value === code) {
            newCode = item.label
            return false
        }
    })
    return newCode ? newCode : "-"
}


const validatePass1 = (rule, value, callback) => {
    if (value === '') {
        callback(new Error($t('SM.LOGIN.TIPS.REQUIRED')))
        return
    }
    let param = {
        "param": {
            "password": escapeAndEncodeBase64(value),
            "pwdRuleGroup": userInfo.data.ruleGroupCode
        }
    }
    createValidatePassword(param).then(res => {
        if (res.returnCode == "0") {
            callback()
        } else {
            callback(new Error(JSON.parse(res.returnMess).cause))
        }
    }).catch(err => {
        if (err && err.response && err.response.data.cause) {
            AiccElMessage({
                message: err.response.data.cause,
                type: 'error',
                offset: 56,
                duration: 10000,
                showClose: true
            })
        }
    })
}

const validatePass = (rule, value, callback) => {
    if (value === '') {
        callback(new Error($t('SM.LOGIN.TIPS.REQUIRED')))
    } else if (value !== userInfo.data.password) {
        callback(new Error(globalProperties.$t('SM.ORGSTAFF.MESSAGE.PWD.CHECKMSG')))
    } else {
        callback()
    }
}

const validateOrgId = (rule, value, callback) => {
    if (value === '') {
        callback(new Error($t('SM.LOGIN.TIPS.REQUIRED')))
    } else {
        callback()
    }
}

const userInfoRules = {
    "ruleGroupCode": [{ required: true, trigger: ['blur', 'change'] }],
    "orgId": [{ required: true, validator: validateOrgId, trigger: ['blur', 'change'] }],
    "userAccount": [{ required: true, validator: checkUserAccount2, trigger: ['blur', 'change'] }],
    "userName": [{ required: true, validator: checkUserName2, trigger: ['blur', 'change'] }],
    "email": [{ required: true, validator: checkEmail, trigger: ['blur', 'change'] }],
    "phone": [{ validator: checkPhone, trigger: ['blur', 'change'] }],
    "description": [{ validator: checkDescription, trigger: ['blur', 'change'] }],
    "password": [{ required: true, validator: validatePass1, trigger: ['blur', 'change'] }],
    "adminPasswordConfirm": [{ required: true, validator: validatePass, trigger: ['blur', 'change'] }],
}

const Titleoptions = reactive({
    data: []
})
const PwdGroupoptions = reactive({
    data: []
})
const defaultProps = {
    children: 'children',
    label: 'orgName',
    value: 'orgId'
};

const roledefaultProps = {
    children: 'children',
    label: 'roleName',
    value: 'roleId'
};

let roledata = reactive({
    data: []
})

let state = reactive({
    state: ""
})

const props = defineProps(['mode'])
const { mode } = toRefs(props)
  
let MAX_USER_OWN_DUTY_ROLE_NUM

const {
    appContext: {
        config: { globalProperties }
    }
} = getCurrentInstance()

let isView = computed(() => {
    return mode.value == "view";
});

let isEdit = computed(() => {
    return mode.value == "edit";
});

let isCreate = computed(() => {
    return mode.value == "create";
});

const showManger = () => {
    Manger.value.open()
}

const HandleGetManager = (data) => {
    userInfo.data.employeeContact.push(data)
}

onBeforeMount(() => {
    state = UserInfoState.data
})

onMounted(() => {
    //查询字典值
    initTitleMap()
    //查询访问组织
    initOrg()
    //查询帐号密码规则组
    initPwdGroups()
    //查询角色
    initRoles()
    //如果是编辑态以及可视，则需要调接口获取帐号密码规则组和组织、访问组织
    if (mode.value != "create") {
        //查询密码组
        queryUsersById(cookieUtils.getCookie('tenant_space_id'), UserInfo.data.userId).then(res => {
            selectedRoles = res.users[0].roleIds
            userInfo.data = res.users[0]
            if(userInfo.data.phone == undefined){
              userInfo.data.phone = ""
            }
            orgRef.value.setDefault(userInfo.data.orgId)
            console.log("userInfo.data.orgId:", userInfo.data.orgId);
            userInfo.data.ruleGroupCode = res.users[0].pwdRuleGroup
            //查询可访问组织 getListByUserIdForShow
            let search = {
                "userId": UserInfo.data.userId
            }
            if (!(isIndependentDeployment() || cookieUtils.getCookie('tenant_space_id') == 'defaultSpace')) {
                getListByUserIdForShow(search).then(res => {
                    let accessOrg = []
                    res.forEach(element => {
                        accessOrg.push(element["orgId"])
                    });
                    userInfo.data.accessOrg = accessOrg
                    orgsRef.value.setDefault(accessOrg)
                })
            }
            //查询主管
            queryOrgContacts(cookieUtils.getCookie('tenant_space_id'), userInfo.data.orgId).then(res => {
                let employeeContact = []
                res["orgContacts"].forEach(element => {
                    employeeContact.push({
                      "name":element["employeeName"],
                      "code":element["employeeCode"]})
                });
                userInfo.data.employeeContact = employeeContact
            })
        })
    }
})

onUnmounted(() => {
    UserInfoState.data.currentOrg = ""
})


let titleMap = reactive({});
async function initTitleMap () {
    let param = {
        "dictCode": "SYS.EMPLOYEE.TITLE"
    }
    let res = await queryDataDictionary(param);
    titleMap = reactive(res)
    let data = []
    for (var key in res) {
        let item1 = res[key];
        let temp = {}
        temp.value = key
        temp.label = item1
        data.push(temp)
    }
    Titleoptions.data = data
}

function parseDesc(data) {
  try {
    if ( !data || !data.startsWith('{') || !data.endsWith('}')) {
      return data
    }
    let dataJson = JSON.parse(data)
    const lang = cookieUtils.getCookie('u-locale')
    if (dataJson[lang] != undefined) {
      return dataJson[lang]
    }
    if (dataJson ['Default'] != undefined) {
      return  dataJson ['Default']
    }
    if (dataJson ['en_US'] != undefined) {
      return  dataJson ['en_US']
    }
    return data
  } catch (err) {
    return data
  }
}
async function initPwdGroups () {
  let res = await queryPwduleGroups(cookieUtils.getCookie('tenant_space_id'));
  let data = []
  const language = cookieUtils.getCookie('u-locale');
  for (var element of res["pwdRuleGroups"]) {
    let temp = {}
    temp.value = element["ruleGroupCode"]
    if (element["ruleGroupCode"] == "Machine") {
      continue
    }
    if (element["ruleGroupCode"] == "Default") {
      temp.label = JSON.parse(element["ruleGroupName"])[language]
    } else {
      temp.label = parseDesc(element["ruleGroupName"])
    }
    data.push(temp)
  }
  PwdGroupoptions.data = data
}
let orgnationListdata = reactive({
  data:[]
})

const orgdata = ref([]);
const orgRef = ref()
const orgsRef = ref()

const initOrg = () => {
    // 查询组织，并使用默认组织
    const getChildren = (root, list) => {
        let children = list.filter(org => org.parentOrgId === root.orgId)
        for (const org of children) {
            org.children = getChildren(org, list)
        }
        return children
    }
    let orgList = []
    queryOrgList().then(res => {
        if (res && res.orgs && res.orgs.length > 0) {
            orgnationListdata.data = res.orgs
            const result = res.orgs
            for (const org of result) {
                if (!org.parentOrgId) {
                    org.parentOrgId = '1'
                }
            }
            const parentOrg = result.filter(org => org.parentOrgId === '1');
            for (const org of parentOrg) {
                org.children = getChildren(org, result)
            }
            orgList = parentOrg
            orgdata.value = orgList
            if(mode.value === 'create') {
                userInfo.data.orgId = state.currentOrg
            }
            if(userInfo.data.orgId) {
                setTimeout(() => {
                    orgRef.value.setDefault(state.currentOrg)
                }, 200)
            }
        }
    })
}
const onOrgSelected = (result) => {
    userInfo.data.orgId = result
}

const onOrgsSelected = (result) => {
    userInfo.data.accessOrg = result
}

const onOrgClear = () => {
    userInfo.data.orgId = ''
}

const onOrgsClear = () => {
    userInfo.data.accessOrg = []
}

let roleListData = reactive([])
async function initRoles () {
    //查询MAX_USER_OWN_DUTY_ROLE_NUM
    MAX_USER_OWN_DUTY_ROLE_NUM = await queryMAX_USER_OWN_DUTY_ROLE_NUM();
    //查询所有角色信息
    let roleList = []
    queryRoles(cookieUtils.getCookie('tenant_space_id')).then(res => {
        if (res && res.roles && res.roles.length > 0) {
            roleListData = res.roles
            const result = res.roles
            for (const role of result) {
                if (role.parentRoleId === undefined || role.parentRoleId === null || String(role.parentRoleId) === '') {
                    role.parentRoleId = '1'
                }
            }
            let parentRole = result.filter(role => role.parentRoleId === '1');
            for (const role of parentRole) {
                role.children = getRoleChildren(role, result);
            }
            roleList = parentRole
        }
        roledata.data = roleList
    });
}

const getRoleChildren = (root, list) => {
    let children = list.filter(org => org.parentRoleId === root.roleId)
    for (const role of children) {
        role.children = getRoleChildren(role, list)
    }
    return children
}


const filterNodeMethod = (value, data) => data.roleName.includes(value);

const filterOrgNodeMethod = (value, data) => data.orgName.includes(value);

const cancel = () => {
    globalProperties.$event.backInTab()
}

const submit = async (taskInfoFormdata) => {
    if (!taskInfoFormdata) return false
    if (UserInfo.data.userId == '102') {
        //通过已经选择的角色与本次选择的角色的差集求得需要删除的角色
        //删除角色 Revoke
        let Revokeparam = {
            "action": "Revoke",
            "userIds": [userInfo.data.userId]
        }
        let revoke = selectedRoles.concat(userInfo.data.roleIds).filter(v => !userInfo.data.roleIds.includes(v))
        for (let index = 0; index < revoke.length; index++) {
            saveRolesForUser(cookieUtils.getCookie('tenant_space_id'), Revokeparam, revoke[index]).catch(err => {
                if (err && err.response && err.response.data.cause) {
                    AiccElMessage({
                        message: err.response.data.cause,
                        type: 'error',
                        offset: 56,
                        duration: 10000,
                        showClose: true
                    })
                    return
                }
            })
        }
        //通过本次选择的角色与已经选择的角色的差集求得需要删除的角色
        //新增角色 Grant
        let param = {
            "action": "Grant",
            "userIds": [userInfo.data.userId]
        }
        let grant = selectedRoles.concat(userInfo.data.roleIds).filter(v => !selectedRoles.includes(v))
        for (let index = 0; index < grant.length; index++) {
            saveRolesForUser(cookieUtils.getCookie('tenant_space_id'), param, grant[index]).catch(err => {
                if (err && err.response && err.response.data.cause) {
                    AiccElMessage({
                        message: err.response.data.cause,
                        type: 'error',
                        offset: 56,
                        duration: 10000,
                        showClose: true
                    })
                    return
                }
            })
        }
        AiccElMessage.success(globalProperties.$t('ccm.pageinterface.saveSuccess'))
        return true
    } else {
        //提交基本信息
        var userInfoNew = {};
        if (userInfo.data.userId != undefined && userInfo.data.userId != "") {
            userInfoNew.userId = userInfo.data.userId;
            userInfoNew.action = "update";
        } else {
            userInfoNew.ruleGroupCode = userInfo.data.ruleGroupCode;
            userInfoNew.orgId = userInfo.data.orgId;
            userInfoNew.userAccount = userInfo.data.userAccount;
        }

        userInfoNew.userName = userInfo.data.userName;
        userInfoNew.description = userInfo.data.description;
        userInfoNew.phone = userInfo.data.phone;
        userInfoNew.email = userInfo.data.email;
        userInfoNew.title = userInfo.data.title;
        userInfoNew.password = escapeAndEncodeBase64(userInfo.data.password);

        let dataParam = {
            "param": userInfoNew
        }

        if (isEdit.value) {
            return await updateEmployee(dataParam).then((res) => {
                if ("0" != res.returnCode) {
                    AiccElMessage.error(globalProperties.$t('SM.FEDERATION.COMMON.UPDATE.FAIL'));
                    return false;
                }
                if (!isIndependentDeployment()) {
                    //如果选择了可访问组织则需要调用ccm接口新增可访问组织
                    if (userInfo.data.accessOrg != undefined) {
                        let saveAccessOrgParam = {
                            'userId': userInfo.data.userId,
                            'orgIds': userInfo.data.accessOrg
                        }
                        saveAccessOrgForUser(saveAccessOrgParam)
                    }
                }
                //通过已经选择的角色与本次选择的角色的差集求得需要删除的角色
                //删除角色 Revoke
                let Revokeparam = {
                    "action": "Revoke",
                    "userIds": [userInfo.data.userId]
                }
                let revoke = selectedRoles.concat(userInfo.data.roleIds).filter(v => !userInfo.data.roleIds.includes(v))
                for (let index = 0; index < revoke.length; index++) {
                    saveRolesForUser(cookieUtils.getCookie('tenant_space_id'), Revokeparam, revoke[index])
                }
                //通过本次选择的角色与已经选择的角色的差集求得需要删除的角色
                //新增角色 Grant
                let param = {
                    "action": "Grant",
                    "userIds": [userInfo.data.userId]
                }
                let grant = selectedRoles.concat(userInfo.data.roleIds).filter(v => !selectedRoles.includes(v))
                for (let index = 0; index < grant.length; index++) {
                    saveRolesForUser(cookieUtils.getCookie('tenant_space_id'), param, grant[index])
                }
                AiccElMessage.success(globalProperties.$t('ccm.pageinterface.saveSuccess') + globalProperties.$t('SM.EMPLOYEE.ROLE.EFFECT').replaceAll('[]',1));
                return userInfo.data.userId
            }).catch(err => {
                if (err && err.response && err.response.data.cause) {
                    AiccElMessage({
                        message: err.response.data.cause,
                        type: 'error',
                        offset: 56,
                        duration: 10000,
                        showClose: true
                    })
                    return false
                }
            })
        } else if (isCreate.value) {
            return await addEmployee(dataParam).then((res) => {
                userInfo.data.userId = res.userId;
                if (res.userId == undefined || res.userId == "") {
                    var tip = globalProperties.$t('SM.ORGSTAFF.LABEL.EMPLOYEE_CREATE_FAILD');
                    if (res.errCode == "60101020076") {
                        tip = res.cause + res.solution;
                        AiccElMessage.error(tip);
                    }
                    var errCode = res.errCode;
                    if (errCode.indexOf("]") != -1) {
                        var descTip = res.desc;
                        var causeTip = res.cause;
                        var solutionTip = res.solution;
                        AiccElMessage.error(causeTip);
                    } else {
                        if (res.cause && res.solution) {
                            tip = res.cause + res.desc + res.solution;
                            AiccElMessage.error(tip);
                        } else {
                            AiccElMessage.error(errCode);
                        }
                    }
                    return false
                }

                if (!isIndependentDeployment()) {
                    //如果选择了可访问组织则需要调用ccm接口新增可访问组织
                    if (userInfo.data.accessOrg != undefined && userInfo.data.accessOrg.length > 0) {
                        let saveAccessOrgParam = {
                            'userId': userInfo.data.userId,
                            'orgIds': userInfo.data.accessOrg
                        }
                        saveAccessOrgForUser(saveAccessOrgParam)
                    }
                }
                //新增角色
                let param = {
                    "action": "Grant",
                    "userIds": [userInfo.data.userId]
                }
                for (let index = 0; index < userInfo.data.roleIds.length; index++) {
                    saveRolesForUser(cookieUtils.getCookie('tenant_space_id'), param, userInfo.data.roleIds[index])
                }

                AiccElMessage.success(globalProperties.$t('ccm.pageinterface.saveSuccess'))
                return userInfo.data.userId
            }).catch(err => {
                if (err && err.response && err.response.data.cause) {
                    AiccElMessage({
                        message: err.response.data.cause,
                        type: 'error',
                        offset: 56,
                        duration: 10000,
                        showClose: true
                    })
                }
                return false
            })
        }


    }
}

defineExpose({
    submit,
    userInfo
})

</script>
<style lang="less" scoped>
.employee-info-card {
    overflow-y: auto;
}
.employee-basic-main.has-step .employee-info-card {
    height: calc(100vh - 303px);
}

.employee-basic-main.has-step.is-view .employee-info-card{
    height: calc(100vh - 231px);
}

.employee-basic-main .employee-info-card {
    height: calc(100vh - 255px);
}

.employee-basic-main.is-view .employee-info-card {
    height: calc(100vh - 183px);
}

.tree-node-name {
    max-width: 400px;
    display: inline-block;
    overflow: hidden;
    vertical-align: middle;
    text-overflow: ellipsis;
}

.item {
    max-width: 600px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space:nowrap;
}

.card-header {
    font-size: var(--16px-to-rem);
    color: #1C1C1C;
    font-weight: bold;
}

.width-180px-to-rem {
    width: var(--180px-to-rem) !important;
}

.task-icon {
    font-size: 18px !important;
    color: #366BFC;
}

.margin-bottom-10px-to-rem {
    margin-bottom: 0.625rem;
}

.required-label:before {
    content: '*';
    color: var(--el-color-danger);
    margin-right: 4px;
}

.condition-wrap {
    padding: 1.25rem;
    background: #FBFBFB;
    border-radius: 8px;
}

.width-400px {
    width: 400px;
    border-radius: 4px;
}

:deep(.el-form-item__error span){
    white-space: normal!important;
}
</style>

<style lang="less">
ul:has(.org-select-dropdown){
    max-width:800px !important;
    overflow-x: auto !important;
}

.employee-tree-select-popper .el-scrollbar ul.el-scrollbar__view.el-select-dropdown__list {
    height: 40vh;
}
</style>

<style lang="less">
body.RTL {
    .employee-tree-select-popper:has(.employee-tree-select-popper)[data-popper-placement="top-start"] {
        transform: translateX(calc(-100% + 400px)) !important;
    }
}
</style>