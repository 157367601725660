export default {
  'sm.assign.employee': 'Asignar empleado',
  'SM.USERGROUP.SHOW.LIST': 'Grupos de usuarios',
  'common.baseui.LABEL.HEADING_BASEINFO': 'Información básica',
  'SM.USERGROUP.LABEL.NAME': 'Nombre del grupo de usuarios',
  'common.bremui.label.description': 'Descripcion',
  'SM.SERVLET.EMPLOYEE': 'Employee',
  'common.statemachineui.button.add': 'Añadir',
  'sm.add.usergroup': 'Creación de un grupo de usuarios',
  'sm.edit.usergroup': 'Editar grupo de usuarios',
  'SM.ORGSTAFF.BUTTON.COMMON_OK': 'OK',
  'SM.COMMON.LABEL.SYSMENU_SUSPENDED': 'Desactivar',
  'SM.ORGSTAFF.MESSAGE.OPERATESUCCESS': 'Operación realizada con éxito.',
  'SM.ORGSTAFF.MESSAGE.STAFF_AUTHCOPY_SOURCENO_CONTBEADMIN_ERR':
    'La cuenta de administrador del sistema no se puede utilizar como cuenta de origen.',
  'SM.COMMON.BUTTON.BANKCARD_SEARCH': 'Buscar',
  'SM.ORGSTAFF.MESSAGE.BE_DELETESUCCESS': 'Se ha eliminado correctamente.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_DELETE_FAILED': 'Error al eliminar el usuario.',
  'SM.ORGSTAFF.TIPS.INVALID_IP_ADDRESS': 'Dirección IP no válida.',
  'SM.ORGSTAFF.LABEL.ACCESS.ADD_FAIL': 'Agregar falló',
  'SM.ORGSTAFF.TITLE.CONTACT_DESC': 'Descripción',
  'SM.COMMON.LABEL.AREALIST': 'Listado de Áreas',
  'SM.COMMON.LABEL.CURRENCY_DECIMALSYMBOL': 'Símbolo decimal',
  'SM.COMMON.TIPS.CHARGECODE_CODEINPUT': 'Solo se pueden introducir letras y números.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_SECONDMENT': 'Adscripción',
  'SM.COMMON.LABEL.CURRENCY_STANDARD_MEASUREMENT': 'Bandera de unidad estándar',
  'SM.COMMON.LABEL.HOLIDAY_THURSDAY': 'Miércoles',
  'SM.COMMON.LABEL.SYSMENU_AUTHID': 'ID de permiso de menú',
  'SM.ORGSTAFF.MESSAGE.REGION_CONFIRM_USEPARENT':
    'Si se utilizan los datos del nodo padre, se borrará la configuración actual de la zona horaria. ¿Estás seguro de que quieres continuar?',
  'SM.ORGSTAFF.TITLE.CONTACT_TYPE': 'Tipo de contacto',
  'SM.ORGSTAFF.TITLE.BE_PRECISION': 'Precisión de divisas',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_SET_ROLE': 'Establecer función',
  'SM.ORGSTAFF.MESSAGE.EMPLOYEE_SECONDMENT_CONFIRM': '¿Estás seguro de que quieres terminar?',
  'SM.ORGSTAFF.LABEL.ORG_BASICINFO': 'Información básica',
  'SM.COMMON.LABEL.MEASUREMENT_UNIT_OPERARION': 'Operación',
  'SM.ORGSTAFF.LABEL.BANK_TYPE': 'Tipo de banco',
  'SM.COMMON.BUTTON.SURVEY_DELETE_ANSWER_OPTIONS': 'Eliminar opciones de respuesta',
  'SM.COMMON.MESSAGE.SYSMENU_DEACTIVATED_NOT_SUPPORT':
    'El estado del menú desactivado no admite la administración de permisos.',
  'SM.ORGSTAFF.TIPS.RESTRICTIP':
    'Introduzca una dirección IP en el formato xx.* o xx.xx.* o xx.xx.xx.*.',
  'SM.ORGSTAFF.TITLE.CONTACT_EMAIL': 'Correo Electrónico',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_SECONDMENT_ENDDATE': 'Fecha de finalización',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_SEARCH_CRITERIA_BE': 'BE',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_VIEWFILE': 'Vistas',
  'SM.ORGSTAFF.MESSAGE.ORG_PARENT_FORBID': 'La unidad matriz ha sido suspendida.',
  'SM.COMMON.LABEL.ATTR_LANGUAGE': 'Idioma',
  'SM.ORGSTAFF.LABEL.TEAM_ASSIGN': 'Se puede asignar un máximo de 2000 empleados.',
  'SM.COMMON.LABEL.ATTACHMENT_DEL_ACCESSORY': 'Borrar',
  'SM.ORGSTAFF.LABEL.SEARCH_ALL': 'Buscar todo el personal',
  'SM.ORGSTAFF.LABEL.ADDR_DISPLAY_ORDER': 'Secuencia de visualización de direcciones incorrecta.',
  'SM.COMMON.LABEL.MEASUREMENT_DESTMEAUNIT': 'Unidad de medición de objetivos',
  'SM.ORGSTAFF.MESSAGE.CHOOSE_SELECTED_OU_FIRST':
    'Seleccione primero un nodo en el árbol de relaciones organizativas.',
  'SM.ORGSTAFF.MESSAGE.STAFF_AUTHCOPY_SOURCENO_EQUALTOTARGET_ERROR':
    'La cuenta de origen no se puede incluir en la cuenta de destino.',
  'SM.ORGSTAFF.TITLE.ROLE_BASIC': 'Función básica',
  'SM.ORGSTAFF.LABEL.STAFF_SESSION_VALIDITY_PERIOD': 'Período de validez de la sesión (mínimo)',
  'SM.COMMON.LABEL.CURRENCY_MEASUREMENT': 'Unidad de Moneda',
  'SM.PROJECT.TITLE.EXPORT_CURRENT_ORG_EMPLOYEE': 'Exportar todo',
  'SM.ORGSTAFF.LABEL.DEPTMACRESTRICT': 'Restricción de dirección MAC',
  'SM.ORGSTAFF.LABEL.REGION_DAYLIGHTSAVINGTIME': 'DST',
  'SM.ORGSTAFF.MESSAGE.BE_CANNOTDELETECURRENCY': 'No se puede eliminar la moneda predeterminada.',
  'SM.ORGSTAFF.TITLE.CONTACT_FULL_NAME': 'Nombre Completo',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_VST': 'Tipo de tiempo de espera de sesión',
  'SM.COMMON.LABEL.CURRENCY_MEASUREMENT_LIST': 'Unidad de medición de divisas',
  'SM.COMMON.LABEL.MEASUREMENT_CONV_LIST': 'Lista de conversiones de medición',
  'SM.COMMON.MESSAGE.VALIDATE_MINLENGTHB': 'Ingrese al menos 0 caracteres.',
  'SM.ORGSTAFF.LABEL.REGION_DESCRIPTION': 'Descripción',
  'SM.COMMON.LABEL.BANKCARD_ACCT_LENG_PARAM': 'Longitud del número de tarjeta',
  'SM.COMMON.LABEL.SURVEY_CLASS_SORT': 'Secuencia de clasificación',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_IP_TYPE': 'Tipo de IP',
  'SM.COMMON.LABEL.CURRENCY_DISPFORMAT_DISPLAYTYPE': 'Tipo de pantalla',
  'SM.COMMON.LABEL.SYSMENU_CONFIGURESYSMENU': 'Configuración del menú del sistema',
  'SM.ORGSTAFF.LABEL.TEAM_ORG_EMPLOYEE': 'Organizar el personal',
  'SM.ORGSTAFF.LABEL.REGION_DST_ENDDATE': 'Fecha de finalización del horario de verano',
  'SM.ORGSTAFF.LABEL.ORG_STOP_TIPS':
    'Una vez suspendida, la organización u organización y su organización subordinada no estarían disponibles. ¿confirme para suspender?',
  'SM.ORGSTAFF.TITLE.OPPAUTH_TITLE_MUTEXAUTH_INFOLIST': 'Lista de permisos exclusivos',
  'SM.COMMON.LABEL.ATTACHMENT_DELETE_INFO': '¿Está seguro de eliminar el archivo?',
  'SM.ORGSTAFF.OPTION.STAFF_REMOVE_PERMISSION': 'Eliminar el permiso del operador de destino',
  'SM.COMMON.LABEL.MEASUREMENT_EXPORT_SUCCESS': 'ÉXITO',
  'SM.ORGSTAFF.TITLE.CONTACT_TITLE': 'Titulo',
  'SM.ORGSTAFF.BUTTON.EMPLOYEE_CANCLE': 'Cancelar',
  'SM.ORGSTAFF.LABEL.REGION_DST_SWITCHINTERVAL': 'Desfase de DST',
  'SM.COMMON.LABEL.SYSMENU_AFFECTED_MENU': 'Menú afectado',
  'SM.ORGSTAFF.MESSAGE.ENDEARLIERSTART':
    'La fecha de inicio no puede ser posterior a la fecha de finalización.',
  'SM.COMMON.LABEL.MEASUREMENT_UPLOAD_FILE': 'Cargar archivo',
  'SM.COMMON.LABEL.SYSMENU_USEPARENT': 'Usar datos primarios',
  'SM.COMMON.LABEL.EDIT_CURRENCY_EXCHANGE_RATE': 'Modificar tipo de cambio',
  'SM.COMMON.LABEL.HOLIDAY_FRIDAY': 'EN',
  'SM.COMMON.LABEL.CURRENCY_TYPE_SYMBOL': 'Símbolo',
  'SM.COMMON.LABEL.SYSMENU_MENUNAME': 'Nombre del menú',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_UPLOAD_SIZE_MAX':
    'El archivo cargado excedió el tamaño máximo de 0 KB.',
  'SM.COMMON.LABEL.SURVEY_ITEM_NAME': 'Nombre del artículo',
  'SM.COMMON.TIPS.POPUP_INFO_TITLE': 'Información de contacto',
  'SM.COMMON.LABEL.CHARGECODE_STATUS': 'Estado',
  'SM.COMMOM.MESSAGE.SYSMENU_MENU_STATUS_NOT_CONSISTENT': 'El estado del menú no es consistente.',
  'SM.COMMON.MESSAGE.ERATE_NUMBER_INVALID': 'Número no válido',
  'SM.COMMON.LABEL.SURVEY_QUESTIONNAIRE_HEAD': 'Jefe del cuestionario',
  'SM.COMMON.LABEL.MODULE_SHORTNAME': 'Nombre corto',
  'SM.ORGSTAFF.LABEL.REGION_DST_MONTHDAY': 'Mes/Día',
  'SM.TENANTSPACE.AUTHTYPE.TITLE': 'Tipo de autenticación',
  'SM.ORGSTAFF.MESSAGE.DSTOFFSET.OUTRANAGE': 'El desfase DST no está en el rango de 1 a 86400.',
  'SM.ORGSTAFF.LABEL.WORKGROUP_CONTENTS': 'Contenido de ruta del grupo de trabajo',
  'SM.ORGSTAFF.MESSAGE.SELECT_EMPLOYEE': 'Por favor, seleccione empleado',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_NEW_PASSWORD': 'nueva contraseña',
  'SM.COMMON.MESSAGE.EXPORT_VIEW':
    ', vaya al menú "Gestión del sistema > Configuración basada en proyectos > Proyecto" para ver los resultados exportados.',
  'SM.ORGSTAFF.LABEL.TEAM_TEAMLEVER': 'Nivel de equipo',
  'SM.ORGSTAFF.LABEL.ROLE_TYPE_PUB': 'Rol público',
  'SM.ORGSTAFF.TITLE.BE_CURRENCY': 'Moneda',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_QUERY_LIST': 'Lista de empleados',
  'SM.COMMON.BUTTON.MEASUREMENT_ADD': 'Nuevo',
  'SM.COMMON.LABEL.SEARCH_NAME': 'Por favor ingrese su nombre',
  'SM.COMMON.LABEL.HOLIDAY_HOLIDAYDAY': 'Vacaciones',
  'SM.ORGSTAFF.MESSAGE.COMMON_TIPS_STAFF_SELECT_NO_PERIOD': 'Seleccionar empleado',
  'SM.ORGSTAFF.TITLE.ASSIGNED_MANAGER': 'Gestores asignados',
  'SM.TENANTSPACE.LDAP_RDNPREFIX': 'Prefijo RDN',
  'SM.ORGSTAFF.MESSAGE.EXPORT_LIMIT':
    'El número de exportaciones superiores a 10,000 no se recomienda para la exportación.',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_BE': 'BE',
  'SM.COMMON.LABEL.OPERLOG_ENDTIME': 'Hora de finalización',
  'SM.COMMON.LABEL.CURRENCY_TYPE_CODE': 'Código',
  'SM.ORGSTAFF.BUTTON.DELETE': 'Borrar',
  'SM.COMMON.BUTTON.OK': 'OK',
  'SM.ORGSTAFF.BUTTON.ADDR_ADD': 'Añadir',
  'SM.COMMON.LABEL.CHARGECODE_CODE': 'Código',
  'SM.ORGSTAFF.BUTTON.ADDR_CREATE': 'Nuevo',
  'SM.COMMON.LABEL.SURVEY_APPLICATION_SCOPE': 'Ámbito de aplicación',
  'SM.COMMON.TITLE.SYSMENU_ERROR': 'Error',
  'SM.COMMON.LABEL.SYSMENU_DEACTIVATED': 'Desechar',
  'SM.COMMON.LABEL.SURVEY_OPTION_NAME': 'Opciones Nombre',
  'SM.ORGSTAFF.LABEL.ORG_SHORT_NAME': 'Nombre corto de la OU',
  'SM.ORGSTAFF.BUTTON.EMPLOYEE_CLEAR': 'Limpiar',
  'SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE_CREATE': 'Nuevo Cuestionario',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_DESC': 'Descripcion',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ROAM_VALID': 'Válido',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_EMAIL_VALIDATOR': 'Correo electrónico no válido',
  'SM.COMMON.LABEL.CHARGECODE_TEST': 'Ensayo',
  'SM.COMMON.LABEL.SURVEY_CREATED_TIME': 'Creado en',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ACCOUNTRANGE': 'Rango de cuenta',
  'SM.ORGSTAFF.LABEL.REGION_DST_MONTHWEEKDAY': 'Mes/Semana/Día',
  'SM.ORGSTAFF.LABEL.TEAM_STATUS': 'Estado',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_UPLOAD_EXT_TITLE':
    'Error al cargar. Formatos de archivo permitidos: 0; Formatos de archivo no permitidos: {1}.',
  'SM.ORGSTAFF.MESSAGE.ERROR_QUERY': 'Error al consultar.',
  'SM.COMMON.LABEL.BANKCARD_BANKTYPE_PARAM': 'Tipo de banco',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_USERNAME_VALIDATOR':
    'El nombre de usuario contiene caracteres especiales no válidos.',
  'SM.COMMON.LABEL.CHARGECODE': 'Código de Cargo',
  'SM.ORGSTAFF.TITLE.ORG_ASSIGNED_EMP': 'Empleados asignados',
  'SM.ORGSTAFF.MESSAGE.SAVE_SUGGESTION': 'Información',
  'SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE_EDIT': 'Modificar cuestionario',
  'SM.COMMON.MESSAGE.CHARGECODE_PERFORM_DELETION': '¿Está seguro de que desea eliminar?',
  'SM.COMMON.BUTTON.CHARGECODE_ADD': 'Nuevo',
  'SM.COMMON.MESSAGE.ERATE_CODE_COMPARE':
    'El código de moneda de origen no puede ser el mismo que el código de moneda de destino.',
  'SM.ORGSTAFF.LABEL.BANK_LEVEL': 'Nivel de Banco',
  'SM.ORGSTAFF.LABEL.RESTRICTTYPE': 'Tipo de restricción de inicio de sesión',
  'SM.COMMON.LABEL.HOLIDAY_WEDNESDAY': 'Miércoles',
  'SM.ORGSTAFF.TITLE.CONTACT_PHONE': 'Número de Teléfono',
  'SM.ORGSTAFF.LABEL.BE_ADDRESS_FORMAT': 'Plantilla de formato de dirección',
  'SM.ORGSTAFF.LABEL.ORG_DISPLAYORDER': 'Secuencia de visualización',
  'SM.ORGSTAFF.LABEL.PWD_UNMATCH_RULE': 'La contraseña no cumple con las reglas.',
  'SM.COMMON.LABEL.SYSMENU_LABEL': 'Menú del sistema',
  'SM.COMMON.MESSAGE.AREA_DELETE_INFO': '¿Está seguro de que desea eliminar?',
  'SM.ORGSTAFF.TITLE.STAFF_ROLE_ASSIGN': 'Asignar rol',
  'SM.ORGSTAFF.OPTION.WEEKDAY_FRI': 'Viernes',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_RESETPWD': 'Restablecer contraseña',
  'SM.COMMON.LABEL.MODULE_MODULE': 'Módulo',
  'SM.ORGSTAFF.TITLE.STAFF_LOGIN_RESTRICT': 'Restricción de inicio de sesión',
  'SM.COMMON.LABEL.MODULE_ADDRESS': 'Dirección URL',
  'SM.ORGSTAFF.TITLE.CURRENCY_DISPLAY_FORMAT': 'Formato de visualización de moneda',
  'SM.COMMON.LABEL.MEASUREMENT_IMPORT_RESULT': 'Resultado de importación',
  'SM.ORGSTAFF.OPTION.STAFF_IP_SEGMENT': 'Segmento de dirección IP',
  'SM.COMMON.TITLE.ACCESS.SELECT_STAFF': 'Seleccionar personal de acceso',
  'SM.COMMON.MESSAGE.SYSMENU_ACCESS_TIME_ERROR':
    'La hora de finalización de la hora de acceso al menú debe ser mayor que la hora de inicio.',
  'SM.ORGSTAFF.MESSAGE.CONTAINS_SUB_ORGS': 'La organización contiene suborganizaciones.',
  'SM.ORGSTAFF.TITLE.ORG_ACCESS_EMP': 'Personal visitante asignado',
  'SM.COMMON.LABEL.BANKCARD_ACCT_LENG': 'Longitud del número de tarjeta',
  'SM.COMMON.LABEL.SYSMENU_VISITCOUNT': 'Cuenta de Visitas',
  'SM.ORGSTAFF.BUTTON.OURELATIONSHIP_RELOAD': 'Refrescar',
  'SM.COMMON.LABEL.OPERLOG_ACCOUNT': 'Cuenta',
  'SM.COMMON.LABEL.BANKCARD_SEARCH_CONDITION': 'Condición de búsqueda',
  'SM.COMMON.LABEL.ATTACHMENT_ADD_ACCESSORY': 'Nuevo',
  'SM.COMMON.MESSAGE.CHARGECODE_TO_TEST':
    '¿Está seguro de que desea cambiarlo al estado de Prueba?',
  'SM.COMMON.LABEL.SYSMENU_MENUURL': 'URL de menú',
  'SM.ORGSTAFF.AUTHTYPE.LABEL': 'Tipo de autenticación',
  'SM.COMMON.LABEL.ERATE_TODATE': 'en',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_TEAMINFO': 'Información del equipo',
  'SM.COMMON.TIPS.HOME_PHONE':
    'Por favor, introduzca el número de teléfono correcto, por ejemplo. (+) (0123-)1234567890 (-0123)',
  'SM.COMMON.LABEL.CURRENCY_MEASUREMENT_TITLE': 'Unidad de medición de divisas',
  'SM.COMMON.LABEL.SURVEY_CLASS_Code': 'Código de clasificación',
  'SM.COMMON.LABEL.SEARCHCONDITION': 'Criterios de búsqueda',
  'SM.ORGSTAFF.MESSAGE.SAVESUCCESS': 'Operación realizada con éxito.',
  'SM.TENANTSPACE.LDAP_DOMAIN': 'dominio',
  'SM.ORGSTAFF.LABEL.ORGANIZATION': 'Unidad de Organización',
  'SM.COMMON.BUTTON.ADD_MENU_ACCESS_TIME':
    'Añade tiempo de acceso al menú. Si no es config, se puede acceder al menú en cualquier momento.',
  'SM.AUC.LABEL.BANK_NAME_INPUT': 'Ingrese nombre del banco',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_RESET_SUCCESS':
    'La contraseña del empleado se cambia correctamente. No se puede cambiar lo siguiente:',
  'SM.ORGSTAFF.LABEL.ORGIMPORT_TIP':
    'Asegúrese de que la información del transportador se haya importado al sistema y que el operador actual sea el administrador.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ACCOUNT_VALIDATOR_ADD':
    "El valor puede contener solo dígitos, letras, guiones bajos (_), guion, puntos (.), y signos at ({'@'}).",
  'SM.COMMON.LABEL.MENU_INFO_TITLE': 'Menú Información básica',
  'SM.COMMON.LABEL.HOLIDAY_YEAR': 'Año',
  'SM.COMMON.MESSAGE.DELETE_CONFIRM': '¿Está seguro de que desea eliminar?',
  'SM.COMMON.LABEL.SYSMENU_ACTIVE_A': 'activar',
  'SM.COMMON.LABEL.HOLIDAY_SATURDAY': 'Sábado',
  'SM.COMMON.BUTTON.SURVEY_ENABLE': 'Habilitado',
  'SM.ORGSTAFF.TITLE.OPERATION': 'Operación',
  'SM.ORGSTAFF.MESSAGE.FORBID_EXSIT_EMPLOYEE':
    'No se pudo deshabilitar el departamento de forma permanente porque hay empleados en el departamento.',
  'SM.ORGSTAFF.LABEL.AUTHVIEW_AUTH_BENAME': 'Inicio de permiso BE',
  'SM.COMMON.TIPS.MENU_REBUILD': 'Información de contacto',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_SUSPEND': 'Suspender',
  'SM.ORGSTAFF.LABEL.REGION_SHORTNAME': 'Nombre corto de la región',
  'SM.ORGSTAFF.LABEL.STAFF_ADD_ROLE': 'Añadir funciónes del rol',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_ATTACHMENTID': 'Archivos adjuntos',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_NOT_ALLOW_4A':
    'Permitir el inicio de sesión de la página web directamente',
  'SM.COMMON.LABEL.CURRENCY_TYPE_NAME': 'Nombre',
  'SM.ORGSTAFF.OPTION.STAFF_RESTRICTIONTYPE_ALLOWED': 'Permitir',
  'SM.COMMON.LABEL.OPERLOG_RESET': 'Restablecer',
  'SM.COMMON.LABEL.CHARGECODE_NAME': 'Nombre',
  'SM.ORGSTAFF.LABEL.STAFF_DESC': 'Descripcion',
  'SM.ORGSTAFF.LABEL.TEAM_DESCRIPTION': 'Descripcion',
  'SM.COMMON.MESSAGE.DATADICT_DELETE_FAILED': 'Error en la eliminación.',
  'SM.COMMON.LABEL.BANKCARD_NAME': 'Nombre de la tarjeta',
  'SM.COMMON.BUTTON.CANCEL': 'Cancelar',
  'SM.COMMON.LABEL.ATTACHMENT_DEL_SUCCESS': 'Archivo accesorio eliminado correctamente',
  'SM.ORGSTAFF.LABEL.TO_BE_ASSIGNED_ROLE': 'Rol que se va a asignar',
  'SM.COMMON.LABEL.SYSMENU_STATUS': 'Estado',
  'SM.ORGSTAFF.OPTION.STAFF_RETAIN_PERMISSION': 'Conservar el permiso del operador objetivo',
  'SM.COMMON.LABEL.MEASUREMENT_ENTITY_ID': 'Identificación',
  'SM.COMMON.LABEL.MEASUREMENT_UNIT_SUNIT': 'Unidad estándar',
  'SM.COMMON.MESSAGE.PROPERTY_SUCCESS_CREATE_INFO': 'Operación realizada con éxito.',
  'SM.ORGSTAFF.BUTTON.TEAM_OK': 'OK',
  'SM.COMMON.MESSAGE.CHARGECODE_DELETE_DATA':
    '¿Está seguro de que desea eliminar el código de cargo?',
  'SM.COMMON.MESSAGE.VALIDATE_RANGELENGTHB': 'Introduzca un valor de 0 a 1 caracteres.',
  'SM.COMMON.LABEL.OPERLOG_OBJECT_TYPE': 'Tipo de objeto',
  'SM.COMMON.BUTTON.BANKCARD_CANCEL': 'Cancelar',
  'SM.ORGSTAFF.TITLE.BE_ADDRESSSETTING': 'Dirección',
  'SM.ORGSTAFF.LABEL.ORG_EMPLOYEE_QUERY_INFO': 'Criterios de búsqueda',
  'SM.COMMON.LABEL.MEASUREMENT_EXPORT_MEA': 'Medida',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_UPLODATPERSON': 'Cargado por',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_SEARCH': 'Buscar',
  'SM.COMMON.BUTTON.MENUVSEARCH': 'Recrear índice de menú',
  'SM.ORGSTAFF.TITLE.CONTACT_HOME_FAX': 'Inicio Fax',
  'SM.COMMON.LABEL.CREATE_OPER': 'Creado por',
  'SM.COMMON.LABEL.ERATE_DCURRENCY_CODE': 'Código de moneda de destino',
  'SM.COMMON.LABEL.CURRENCY_DISPLAY_POSTFIX': 'Postfijo de visualización',
  'SM.ORGSTAFF.LABEL.STAFF_ROLE_DESC': 'Descripcion',
  'SM.ORGSTAFF.TITLE.ADDRESS_DISPLAY_FORMAT': 'Formato de visualización de direcciones',
  'SM.ORGSTAFF.TITLE.ADDR_NAME': 'Nombre',
  'SM.COMMON.LABEL.MEASUREMENT_ENTITY_CODE': 'Código de unidad',
  'SM.COMMON.MESSAGE.SYSMENU_DELSUCCESS': 'Se ha eliminado correctamente.',
  'SM.ORGSTAFF.LABEL.INPUT_ROLE_NAME': 'Ingrese el nombre del rol',
  'SM.ORGSTAFF.TIPS.DESC': 'No introducir caracteres especiales <>',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD':
    'Error al verificar la contraseña del empleado.',
  'SM.ORGSTAFF.TITLE.CONTACT_OFFICE_FAX': 'Fax de oficina',
  'SM.COMMON.LABEL.CURRENCY_FORMAT_DISPLAY': 'Formato de vista previa',
  'SM.ORGSTAFF.AUTHTYPE.LDAP': 'LDAP',
  'SM.PROJECT.TITLE.IMPORT_ORG_PAR': 'Paquete de importación de par',
  'SM.ORGSTAFF.MESSAGE.DSTFLAG_WITHOUT_TIMEZONE':
    'Ninguna zona horaria puede abrir el horario de verano.',
  'SM.COMMON.LABEL.MEASUREMENT_CONV_EDIT': 'Modificar conversión de unidades de medida',
  'SM.ORGSTAFF.BUTTON.BE_COPYPARENT': 'Copiar datos de nodo padre',
  'SM.ORGSTAFF.LABEL.ORG_FORBID': 'Desactivar',
  'SM.ORGSTAFF.LABEL.STAFF_ROLE_TYPE': 'Tipo de rol',
  'SM.COMMON.LABEL.CURRENCY_MEASUREMENT_ID': 'Identificación',
  'SM.COMMON.LABEL.OPERLOG_STARTTIME': 'Hora de inicio',
  'SM.ORGSTAFF.MESSAGE.REGION_VALIDATE_TIMESAME':
    'La hora de inicio y la hora de finalización de una zona horaria no pueden ser las mismas.',
  'SM.COMMON.LABEL.HOLIDAY_SUNDAY': 'El Sol',
  'SM.COMMON.TIPS.ERATE_ERATE_TIPS':
    'Ingrese una cadena numérica positiva con un máximo de 13 posiciones integrales y 6 decimales y la última no puede ser 0.',
  'SM.ORGSTAFF.LABEL.REGION_CODE': 'Código de región',
  'SM.ORGSTAFF.TITLE.REGION_ADMINREGION': 'Región administrativa',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_NATIONALITY': 'Nacionalidad',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_VALUE_TYPE': 'Tipo de valor',
  'SM.COMMON.MESSAGE.OPERATESUCCESS': 'Operación realizada con éxito.',
  'SM.ORGSTAFF.TITLE.SUCCESS': 'Información',
  'SM.COMMON.LABEL.MEASUREMENT_UNIT_RATIO': 'Ratio de unidad de medición',
  'SM.COMMON.TITLE.SURVEY_QUESTION_NAME_INFO': 'Información sobre el nombre de la pregunta',
  'SM.COMMON.BUTTON.RESET': 'Restablecer',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ACCOUNT_PASSWORD': 'la contraseña actual',
  'SM.COMMON.BUTTON.ADD_ADD': 'Añadir',
  'SM.COMMON.LABEL.ATTACHMENT_SAVE_SUCCESS': 'El archivo adjunto se ha añadido correctamente.',
  'SM.ORGSTAFF.TITLE.TEAM_NAMEEXIST': 'El nombre del equipo ya existe.',
  'SM.ORGSTAFF.LABEL.TEAM_ACCOUNTRANGE': 'Rango de cuenta',
  'SM.COMMON.BUTTON.CHARGECODE_SAVE': 'Guardar',
  'SM.COMMON.LABEL.OPERLOG_EMPLOYEE_NAME_NORMAL': 'Nombre del empleado',
  'SM.COMMON.LABEL.BANKCARD_BANKCODE': 'Código bancario de emisión de tarjeta',
  'SM.COMMON.LABEL.SURVEY_INTERLOCUTION': 'Preguntas y respuestas',
  'SM.COMMON.BUTTON.MEASUREMENT_DELETE': 'Borrar',
  'SM.ORGSTAFF.LABEL.ORG_IMPORT': 'Importación',
  'SM.COMMON.MESSAGE.CHARGECODE_TO_RELEASE':
    '¿Está seguro de que desea cambiarlo al estado Release?',
  'SM.COMMON.LABEL.CHARGECODE_FORBIDEN_BE': 'SER Prohibido',
  'SM.COMMON.MESSAGE.OPERATE_FAIL': 'Error en la operación.',
  'SM.COMMON.MESSAGE.CURRENCY_MEASUREMNET_CONFIRMCOVERMSG':
    'El tipo de moneda actual ya tiene una unidad de moneda estándar o una unidad de moneda mínima. ¿Estás seguro de que quieres continuar?',
  'SM.ORGSTAFF.TITLE.RELATION_CONFIRM_RECOVER':
    'Después de haber sido reanudado, la relación organizacional y su relación subordinada estarán disponibles,¿Confirma para reanudar?',
  'SM.ORGSTAFF.LABEL.ACCESS.USER_REQUIRED': 'Por favor elija personal',
  'SM.ORGSTAFF.TITLE.REGION_IPRANGE': 'Segmento IP',
  'SM.ORGSTAFF.BUTTON.REMOVE_OUT': 'Eliminar',
  'SM.ORGSTAFF.TITLE.ADDR_DESCRIPTION': 'Descripción',
  'SM.ORGSTAFF.TIPS.LOGINID': 'No introduzca caracteres especiales excepto @.',
  'SM.COMMON.LABEL.SURVEY_CLASS_SORT_DESCRIPTION': 'El rango de entrada es 1-100',
  'SM.ORGSTAFF.TITLE.COMMON_QUERYCONDITION_TITLE': 'Criterios de búsqueda',
  'SM.ORGSTAFF.LABEL.ORG_SELECTREGION': 'Seleccionar región',
  'SM.ORGSTAFF.MESSAGE.ERROR.SAVE': 'Error',
  'SM.ORGSTAFF.MESSAGE.BE_SELECTREGION': 'Seleccionar región',
  'SM.ORGSTAFF.MESSAGE.BE_MAKE_SURE_DELETE': '¿Está seguro de que desea eliminar el miembro?',
  'SM.ORGSTAFF.MESSAGE.ADDR_DELETE_CONFIRM': '¿Está seguro de que desea eliminar?',
  'SM.ORGSTAFF.MESSAGE.REGION_VALIDATE_IPEXIST': 'La dirección IP está duplicada.',
  'SM.COMMON.TITLE.SUCCESS': 'Información de contacto',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_IS_LOCKED': 'El empleado ha sido bloqueado.',
  'SM.ORGSTAFF.TITLE.ASSIGNE_EMPLOYEE': 'Asignar empleado',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PWDRULEGROUP': 'Reglas para cuentas y contraseñas',
  'SM.COMMON.BUTTON.MEASUREMENT_EXPORT': 'Exportación',
  'SM.ORGSTAFF.BUTTON.EMPLOYEE_SELECTORG': 'Seleccionar OU',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_ATTACHMENTDESC': 'Descripcion',
  'SM.ORGSTAFF.TITLE.IMPORT_MAC_TITLE': 'Restricción de importación de inicio de sesión',
  'SM.ORGSTAFF.OPTION.WEEKLIST_FTH': 'Cuarto',
  'SM.COMMON.MESSAGE.SAVESUCCESS': 'Operación realizada con éxito.',
  'SM.ORGSTAFF.LABEL.TEAM_EMPLOYEE': 'Empleado',
  'SM.ORGSTAFF.BUTTON.FORBID_FOREVER': 'Desactivar permanentemente',
  'SM.COMMON.LABEL.CURRENCY_GROUPSIZE': 'Tamaño del grupo',
  'SM.COMMON.LABEL.OPERLOG_OBJECT_ID': 'ID de objeto',
  'SM.COMMON.LABEL.OPERLOG_RESULT': 'Resultado de la operación',
  'SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE_TEMP': 'Cuestionario',
  'SM.COMMON.MESSAGE.OPERATEFAIL': 'Error en la operación.',
  'SM.ORGSTAFF.MESSAGE.BE_SUBBE':
    'Se han configurado BE, OU, equipos o roles de nivel inferior para el BE.',
  'SM.ORGSTAFF.MESSAGE.STAFF_INFOAUTH_CONFIRM':
    'Confirme que la información del empleado introducida ha sido autorizada',
  'SM.COMMON.BUTTON.SURVEY_DELETE_QUESTION': 'Eliminar pregunta',
  'SM.ORGSTAFF.TITLE.RELATION_CONFIRM_SUSPEND':
    'Después de haber sido suspendido, la relación organizativa y su relación subordinada no estarán disponibles, ¿confirma que desea suspender?',
  'SM.ORGSTAFF.LABEL.STAFF_LOCALE': 'Idioma predeterminado',
  'SM.COMMON.LABEL.SURVEY_QUESTIONNAIRE_STATUS': 'Estado',
  'SM.ORGSTAFF.MESSAGE.SRC_ACCOUNT': 'La cuenta de origen no existe.',
  'SM.ORGSTAFF.MESSAGE.CONTACT_ACCOUNT': 'Cuenta',
  'SM.COMMON.LABEL.ACCESS_RELATION': 'Organizaciones accesibles',
  'SM.COMMON.TIPS.SPECIAL_CHAR_AVAILABLE': 'El valor de entrada debe ser dígito, alfa o _.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_CREATE_FAILD':
    'Error al crear el empleado. Confirme la información de la cuenta.',
  'SM.ORGSTAFF.OPTION.WEEKDAY_TUE': 'Martes',
  'SM.ORGSTAFF.BUTTON.CANCEL': 'Cancelar',
  'SM.COMMON.BUTTON.BANKCARD_SAVE': 'Guardar',
  'SM.COMMON.TITLE.ERROR': 'Error',
  'SM.ORGSTAFF.LABEL.REGION_DST_STARTDATE': 'Fecha de inicio del horario de verano',
  'SM.COMMON.LABEL.AREANAME': 'Nombre del área',
  'SM.ORGSTAFF.LABEL.TEAM_TEAMCODE': 'Código del equipo',
  'SM.COMMON.LABEL.MEASUREMENT_ENTITY_NAME': 'Nombre de unidad',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ROAM_ORG': 'Organización de Roam',
  'SM.COMMON.LABEL.BANKCARD_BANKCODE_PARAM': 'Código bancario de emisión de tarjeta',
  'SM.ORGSTAFF.TITLE.CONTACT_MOBILE_PHONE': 'Número de teléfono móvil',
  'SM.ORGSTAFF.LABEL.ORG_CHANNEL_CLASS': 'Clasificación de canales',
  'SM.COMMON.BUTTON.TOP': 'Arriba',
  'SM.AUC.LABEL.BANK_LIST': 'Lista de Bancos',
  'SM.COMMON.TITLE.MEASUREMENT_STDLIST': 'Lista de sistemas de medición',
  'SM.COMMON.LABEL.ERATE_RANGEDATE': 'Período de validez',
  'SM.COMMON.LABEL.BANKCARD_OPERATION': 'Operación',
  'SM.ORGSTAFF.TITLE.BE_INFORMATION': 'Información',
  'SM.ORGSTAFF.OPTION.MONTH_12': 'diciembre',
  'SM.ORGSTAFF.OPTION.MONTH_11': 'Noviembre',
  'SM.ORGSTAFF.MESSAGE.TIMEZONE_TIME_ERROR':
    'El tiempo de inicio no puede ser mayor o igual que el tiempo de finalización.',
  'SM.ORGSTAFF.LABEL.REGION_LAYER': 'Capa de región',
  'SM.ORGSTAFF.OPTION.MONTH_10': 'octubre',
  'SM.ORGSTAFF.OPTION.WEEKDAY_SAT': 'Sábado',
  'SM.ORGSTAFF.LABEL.REGION_NAME': 'Nombre de la región',
  'SM.COMMON.MESSAGE.SYSMENU_CONFIRM_MODIFY_MENU_STATUS_TO': '¿Está seguro de que desea:',
  'SM.COMMON.LABEL.BANKCARD_BANKSIGN_LENG_PARAM':
    'Duración de la identificación bancaria de la emisión de la tarjeta',
  'SM.COMMON.BUTTON.CHARGECODE_EDIT': 'Modificar',
  'SM.ORGSTAFF.LABEL.BE_ADDRESS_DISPLAYFORMAT':
    'Plantilla de formato de visualización de direcciones',
  'SM.COMMON.LABEL.HOLIDAY': 'Vacaciones',
  'SM.ORGSTAFF.LABEL.ORG_CODE': 'Código OU',
  'SM.ORGSTAFF.LABEL.USER_COUNT': 'Número de usuarios',
  'SM.COMMON.MESSAGE.MEASUREMENT_STANDARD_ERROR': 'Por favor, elija la medición estándar correcta.',
  'SM.ORGSTAFF.TITLE.ADDR_LINE_NUMBER': 'Número de línea',
  'SM.COMMON.TIPS.DIGITS_ONLY': 'Por favor ingrese dígitos.',
  'SM.COMMON.LABEL.BANKCARD_BANKNAME': 'Nombre del banco emisor de la tarjeta',
  'SM.ORGSTAFF.MESSAGE.ADDR_DELETE_ERROR': 'Error de eliminación.',
  'SM.ORGSTAFF.TITLE.REGION_TIMEZONE': 'Zona horaria',
  'SM.COMMON.LABEL.OPERLOG_FAILUER': 'Fallo',
  'SM.COMMON.LABEL.HIGHRISKLOG': 'Monitoreo de operaciones de alto riesgo',
  'SM.COMMON.LABEL.CURRENCY_MEASUREMENT_NAME': 'Unidad',
  'SM.ORGSTAFF.TITLE.CONTACT': 'Contactar',
  'SM.ORGSTAFF.LABEL.BE_CODE': 'Código BE',
  'SM.COMMON.LABEL.SURVEY_SINGAL_CHOOSE': 'Opción única',
  'SM.ORGSTAFF.LABEL.BE_SHORT_NAME': 'Nombre corto de BE',
  'SM.ORGSTAFF.TITLE.CONTACT_ID': 'ID de contacto',
  'SM.ORGSTAFF.LABEL.TEAM_CODE': 'Código OU',
  'SM.COMMON.MESSAGE.MODULE_MODULEADDRESSNOTMATCH': 'La dirección del módulo no coincide.',
  'SM.COMMON.LABEL.CHARGECODE_RELEASE': 'Lanzamiento',
  'SM.COMMON.TITLE.SYSMENU_MENUNAME': 'Menú Nombre Info',
  'SM.COMMON.LABEL.SYSMENU_SELECTORGANIZATION': 'Seleccionar permiso',
  'SM.ORGSTAFF.LABEL.TEAM_SEARCH': 'Buscar',
  'SM.ORGSTAFF.TITLE.CHARGE_CODE': 'Código de Cargo',
  'SM.ORGSTAFF.LABEL.STAFF_ROLE_EFFECTIVE_OU_INPUT': 'Ingrese un nombre de unidad organizativa',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ACCOUNT': 'Cuenta',
  'SM.PROJECT.TITLE.EXPORT_CURRENT_ORG_AND_SUB': 'Exportar elemento seleccionado',
  'SM.COMMON.BUTTON.SURVEY_NEW_QUESTION_TYPE': 'Nuevo tipo de pregunta',
  'SM.ORGSTAFF.LABEL.BE_LANGUAGE': 'Idioma',
  'SM.COMMON.LABEL.HEADING_SEARCHCRITERIA': 'Criterios de búsqueda',
  'SM.COMMON.LABEL.SYSMENU_SEARCH_RESULT': 'Resultado de la búsqueda',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_TIMERANGEERROR':
    'La hora de finalización  de sesión debe ser posterior a la hora de inicio de sesión. Enlace de dirección MAC',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_RESET_FAILD':
    'Error al restablecimiento de contraseña de empleado',
  'SM.ORGSTAFF.TITLE.STAFF_INFO_ADD': 'Añadir empleado',
  'SM.ORGSTAFF.OPTION.EMPLOYEE_DELETE_ROLE': 'Eliminar rol',
  'SM.ORGSTAFF.TITLE.ADDRESS_FORMAT': 'Formato de dirección',
  'SM.ORGSTAFF.LABEL.FUNCAUTH_OPPAUTHNAME': 'Permiso Mutuamente Exclusivo',
  'SM.COMMON.LABEL.CURRENCY_DECIMALPOSITIONS': 'Lugares decimales',
  'SM.ORGSTAFF.BUTTON.INCREASE': 'Añadir',
  'SM.ORGSTAFF.LABEL.TEAM_PARENTORG': 'OU padre',
  'SM.ORGSTAFF.TITLE.NEW_ORG': 'Creación de una organización',
  'SM.ORGSTAFF.TITLE.EDIT_ORG': 'Editar organización',
  'SM.ORGSTAFF.MESSAGE.INPUT_ORG_NAME': 'Introduzca un nombre de organización',
  'SM.ORGSTAFF.TITLE.NEW_ACCESS_USER': 'Agregar un empleado de acceso',
  'SM.ORGSTAFF.TITLE.NEW_MANAGER': 'Agregar supervisor',
  'SM.ORGSTAFF.TITLE.USER_ACCOUNT': 'Cuenta de empleado',
  'SM.ORGSTAFF.MESSAGE.SEARCH_USERNAME': 'Buscar nombres de empleados',
  'SM.ORGSTAFF.TITLE.SELECT_ROLE': 'Seleccionar roles',
  'SM.ORGSTAFF.MESSAGE.FOLLOW_TENANT': 'Siga la configuración del inquilino',
  'SM.ORGSTAFF.MESSAGE.SEARCH_ROLE_NAME': 'Nombre del rol de búsqueda',
  'SM.ORGSTAFF.TIP.ACCESS_USER_DESC':
    'Empleados que no pertenecen a la organización pero que tienen acceso y uso de los recursos de la organización',
  'SM.COMMON.BUTTON.CHARGECODE_SUCCESS': 'Información de contacto',
  'SM.ORGSTAFF.LABEL.TEAM_MANAGER': 'Gerente',
  'SM.COMMON.LABEL.MEASUREMENT_EXPORT_COUNT': 'Exportación Cantidad',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_BIRTH': 'Fecha de nacimiento',
  'SM.COMMON.LABEL.CREATE_TIME': 'Creado en',
  'SM.ORGSTAFF.TITLE.COMMON_CONTACT_INFORMATION': 'Información de contacto',
  'SM.ORGSTAFF.MESSAGE.STAFF_AUTHCOPY_PATTERN': 'Modo de copia',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_IP': 'Dirección IP',
  'SM.COMMON.LABEL.MEASUREMENT_NO': 'No',
  'SM.COMMON.TITLE.BANKCARD_ADD': 'Nueva tarjeta bancaria',
  'SM.ORGSTAFF.MESSAGE.STAFF_RESUME_CONFIRM':
    '¿Está seguro de que desea reanudar la cuenta de empleado?',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ONLINE_NAME': 'Empleado',
  'SM.ORGSTAFF.MESSAGE.SELECT_REGION': 'Seleccione una región.',
  'SM.ORGSTAFF.LABEL.DEPTIPRESTRICT': 'Restricción de IP',
  'SM.ORGSTAFF.LABEL.INPUT_REGION_NAME': 'Introduzca el nombre de la región',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_MIDDLENAME': 'Segundo nombre',
  'SM.ORGSTAFF.TITLE.TEAM_OPERSUCCESS': 'Operación realizada con éxito.',
  'SM.COMMON.BUTTON.IMPORT': 'Importación',
  'SM.ORGSTAFF.LABEL.TEAM_TEAMNAME': 'Nombre del equipo',
  'SM.ORGSTAFF.MESSAGE.BE_COPYCURRENCY': '¿Está seguro de que desea copiar el nodo padre?',
  'SM.ORGSTAFF.TITLE.REGION_ADDRESS': 'Dirección',
  'SM.ORGSTAFF.LABEL.STAFFAUTH_AUTHSOURCE': 'Origen del permiso',
  'SM.ORGSTAFF.LABEL.BE_DESCRIPTION': 'Descripción',
  'SM.ORGSTAFF.LABEL.ORG_DELETE_CONFIRM':
    'Después de eliminar una organización, la cuenta correspondiente de la organización no se puede restaurar y solo se puede volver a crear. ¿Eliminar o no?',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_NAME_EXIST':
    'Error al crear el empleado porque la cuenta ya existe en el sistema.',
  'SM.COMMON.LABEL.BANKCARD_BANKSIGN': 'Identificación bancaria de emisión de tarjeta',
  'SM.COMMON.BUTTON.SURVEY_DELETE_QUESTION_TYPE': 'Eliminar tipo de pregunta',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_RESET': 'Restablecer',
  'SM.COMMON.LABEL.NEW_CURRENCY_EXCHANGE_RATE': 'Nuevo tipo de cambio',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_TO': 'En',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_SAVE': 'Guardar',
  'SM.COMMON.LABEL.OPERLOG_SUCCESS': 'Éxito',
  'SM.COMMON.MESSAGE.DATADICT_DELETE_SUCCESSFULLY': 'Se ha eliminado correctamente.',
  'SM.ORGSTAFF.LABEL.ORG_FORBID_TIPS': '¿Estás seguro de que quieres desactivar la organización?',
  'SM.COMMON.TIPS.AREA_DELETE_SUCCESS': 'Eliminado correctamente.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_FIRSTNAME': 'Nombre de usuario',
  'SM.ORGSTAFF.TITLE.ERROR': 'Error',
  'SM.ORGSTAFF.TITLE.REGION_ENDIP': 'IP final',
  'SM.ORGSTAFF.LABEL.ORG_NAME': 'Nombre de OU',
  'SM.ORGSTAFF.LABEL.ORG_IMPORTDATA': 'Importar datos',
  'SM.COMMON.LABEL.SURVEY_DRAFT': 'Borrador',
  'SM.ORGSTAFF.MESSAGE.REGION_CONFIRM_NOTUSEPARENT':
    'Si se cancelan los datos del nodo padre, no se utilizará la configuración del nodo padre. ¿Continuar?',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ORG': 'Organización',
  'SM.ORGSTAFF.LABEL.BANK_CODE': 'Código del Banco',
  'SM.COMMON.BUTTON.ADD': 'Nuevo',
  'SM.ORGSTAFF.TITLE.CONTACT_OFFICE_PHONE': 'Teléfono de oficina',
  'SM.COMMON.LABEL.THE_MENU': '?',
  'SM.ORGSTAFF.TIPS.UNLOCK.USER':
    'Introduzca la contraseña del operador actual para la confirmación:',
  'SM.COMMON.MESSAGE.CURRENCY_DECIMALNOZEROFLAG': 'Eliminar los últimos ceros',
  'SM.COMMON.LABEL.ERATE_EFFDATE': 'Tiempo efectivo',
  'SM.COMMON.LABEL.HOLIDAY_IMPORTDATA': 'Importar datos',
  'SM.ORGSTAFF.LABEL.ONLINEOPERATORNUM': 'Total de operadores en línea',
  'SM.ORGSTAFF.LABEL.ORG_PARENTORG': 'OU padre',
  'SM.COMMON.LABEL.BANKCARD_NAME_PARAM': 'Nombre de la tarjeta',
  'SM.COMMON.LABEL.CHARGECODE_DRAFT': 'Borrador',
  'SM.COMMON.LABEL.OPERLOG_OPER_TYPE': 'Tipo de operación',
  'SM.COMMON.TITLE.MEASUREMENT_INFO': 'Información básica',
  'SM.ORGSTAFF.TITLE.REGION_STARTIP': 'IP de inicio',
  'SM.ORGSTAFF.LABEL.IMPORT_TEMPLATE': 'Plantilla de archivo de importación',
  'SM.COMMON.LABEL.SURVEY_TITLE_NAME': 'Nombre de la pregunta',
  'SM.COMMON.LABEL.HOLIDAY_IMPORT': 'Importación de vacaciones',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_9': 'septiembre',
  'SM.TENANTSPACE.LDAP_BASICDN': 'DN Básico',
  'SM.TENANTSPACE.LDAP_TITLE': 'Autenticación LDAP',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_8': 'Agosto',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_7': 'Noviembre',
  'SM.ORGSTAFF.LABEL.BE_NAME': 'Nombre BE',
  'SM.COMMON.LABEL.CHARGECODE_SEARCH_CRITERIA': 'Criterios de búsqueda',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_6': 'junio',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_5': 'Mayo',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_4': 'Abril',
  'SM.ORGSTAFF.OPTION.WEEKLIST_SEC': 'Segundo',
  'SM.ORGSTAFF.LABEL.WORKGROUP_NAME': 'Nombre del grupo de trabajo',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_3': 'Marzo',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_2': 'Febrero',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_1': 'Enero',
  'SM.ORGSTAFF.LABEL.ORG_STOP': 'Suspender',
  'SM.COMMON.TIPS.CURRENCY_GROUPSIZE_TIPS':
    'Número de dígitos separados por separadores en una cantidad. El valor 0 indica que la cantidad no se separa con separadores.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_STATUS_NORMAL': 'Normales',
  'SM.COMMON.LABEL.BANKCARD_BANKNAME_PARAM': 'Nombre del banco emisor de la tarjeta',
  'SM.ORGSTAFF.TITLE.REGION_EX_ATTR': 'Atributos extendidos',
  'SM.COMMON.TITLE.SUCCESS_SUCCESS': 'Operación realizada con éxito.',
  'SM.COMMON.LABEL.MEASUREMENT_CONV_CREATE': 'Nueva conversión de medición',
  'SM.ORGSTAFF.LABEL.TEAM_TEAMID': 'ID del equipo',
  'SM.ORGSTAFF.BUTTON.ORG_EDIT': 'Modificar',
  'SM.ORGSTAFF.TITLE.TEAM_ASSIGNEDEMPL':
    'Empleados asignados (no se puede vincular a más de 2000 empleados)',
  'SM.ORGSTAFF.TITLE.STAFF_INFO_EXT': 'Información extendida',
  'SM.ORGSTAFF.TITLE.SELECT_ORG': 'Seleccionar grupo de trabajo',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_BIRTHPLACE': 'Lugar de nacimiento',
  'SM.ORGSTAFF.MESSAGE.REFRESH_SUCCESS': 'Se ha actualizado correctamente.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ACCOUNT_VALIDATOR':
    'El valor puede contener solo dígitos, letras, caracteres chinos, guiones bajos (_), guiones, puntos (.), y en signos (@).',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_IP_ADDR': 'Dirección IP',
  'SM.ORGSTAFF.TITLE.TEAM_DELETEFSUCCESS': 'Se ha eliminado correctamente.',
  'SM.ORGSTAFF.LABEL.ORG_INFO': 'Información de OU',
  'SM.COMMON.TIPS.CHARGECODE_NAME': 'El valor de entrada no puede contener caracteres especiales.',
  'SM.COMMON.MESSAGE.SYSMENU_AUTH_ASSIGNED_MUTIL_MENUS':
    'El permiso del menú se ha asignado a varios menús. Si descarta el menú, se revocarán todos los permisos. ¿Continúa?',
  'SM.COMMON.LABEL.MEASUREMENT_IGNORE_IMPORT':
    'Durante la importación de archivos por lotes, si un archivo no se importa, la importación de archivos posteriores continuará.',
  'SM.COMMON.TIPS.POPUP_LABEL_DELETE': '¿Está seguro de que desea eliminar?',
  'SM.ORGSTAFF.AUTHTYPE.DEFAULT': 'Predeterminado',
  'SM.ORGSTAFF.LABEL.BE_BIZCODE': 'Código de Negocio BE',
  'SM.COMMON.LABEL.SYSMENU_DIS_POSTION': 'Posición de visualización',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_STATUS_FORBID': 'Prohibir',
  'SM.ORGSTAFF.LABEL.CREATESOURCE': 'Crear origen',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_FORBIDFOREVER': 'Desactivar permanentemente',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ONLINE_CLIENT': 'IP del cliente',
  'SM.ORGSTAFF.TIPS.STAFF_LOCALE':
    'Idioma de inicio de sesión predeterminado del empleado que se utiliza cuando el idioma no está seleccionado al iniciar sesión.',
  'SM.COMMON.TITLE.MEASUREMENT_UNIT': 'Tipo de medición',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_UPLOAD_SIZE_EXT_TITLE':
    'El tamaño de un archivo que se va a cargar puede variar de {1} KB a 0 KB. Los archivos {2} están permitidos, pero los archivos {3} no.',
  'SM.SM.COMMON.TITLE.NO_CHARGECODE': 'Nada de ChargeCode.',
  'SM.COMMON.BUTTON.ATTACHMENT_MGR_SEARCH_CRITERIA_QUERY': 'Buscar',
  'SM.ORGSTAFF.OPTION.WEEKLIST_FIV': 'Quinto',
  'SM.ORGSTAFF.LABEL.ATTR_ATTRSETTING': 'Atributos extendidos',
  'SM.COMMON.LABEL.HIGHRISKLOG_SERVICENAME': 'Nombre del servicio',
  'SM.ORGSTAFF.MESSAGE.AUTHENTICATION': 'Verificar identidad',
  'SM.COMMON.MESSAGE.DATADICT_SURE_DELETE': '¿Está seguro de que desea eliminar?',
  'SM.ORGSTAFF.OPTION.WEEKLIST_FIR': 'Primero',
  'SM.COMMON.BUTTON.COMMON_ADD': 'Añadir',
  'SM.COMMON.LABEL.SYS_MEASUREMENT_METRIC': 'Indicador del sistema métrico',
  'SM.ORGSTAFF.TIPS.BE_LOGO':
    'Seleccione una imagen PNG, GIF o JPG cuyo tamaño no exceda de 50 KB y 145*50 píxeles.',
  'SM.COMMON.BUTTON.CHARGECODE_ADDMEMBER': 'Añadir código de carga',
  'SM.COMMON.LABEL.SURVEY_QUESTIONNAIRE_TAIL': 'Cuestionario Tail',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_MATCH_VALUE': 'Valor de coincidencia',
  'SM.ORGSTAFF.LABEL.ORG_SUBTYPE': 'Tipo de sub-OU',
  'SM.ORGSTAFF.MESSAGE.SELECT_ORG': 'Seleccione una unidad organizativa.',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_SEARCH_CRITERIA': 'Criterios de búsqueda',
  'SM.COMMON.LABEL.SURVEY_ALLOW_INPUT': 'Permitir la entrada manual',
  'SM.ORGSTAFF.LABEL.BE_ID': 'BE ID',
  'SM.ORGSTAFF.LABEL.ROLE_TYPE_DUTY': 'Función de roles',
  'SM.COMMON.LABEL.SURVEY_MODIFIED_ACCOUNT': 'Modificado por',
  'SM.ORGSTAFF.LABEL.TEAM_TDISPLAYORDER': 'Secuencia de visualización',
  'SM.COMMON.LABEL.MENU_ACCESS_TIME': 'Menú Tiempo de acceso',
  'SM.COMMON.LABEL.ERATE_INFO_LIST': 'Lista de tipos de cambio de divisas',
  'SM.COMMON.LABEL.SYSMENU_LANGUAGE': 'Idioma',
  'SM.ORGSTAFF.BUTTON.IMPORT': 'Importación',
  'SM.COMMON.LABEL.MODULE_ID': 'Identificación',
  'SM.COMMON.LABEL.OPERLOG_OPER_TIME': 'Tiempo de operación',
  'SM.COMMOM.MESSAGE.BESEXCEPTION':
    'No se puede completar la operación actual. Póngase en contacto con el administrador.',
  'SM.COMMON.LABEL.SURVEY_PROVINCE': 'Provincia',
  'SM.ORGSTAFF.LABEL.STAFF_ACCOUNT': 'Cuenta',
  'SM.ORGSTAFF.BUTTON.RESET_TEAM': 'Restablecer',
  'SM.ORGSTAFF.MESSAGE.USER_OWN_DUTY_ROLE_INVALID2':
    'que es mayor que el número máximo, el número máximo es:',
  'SM.ORGSTAFF.MESSAGE.USER_OWN_DUTY_ROLE_INVALID1':
    'El número de funciones propias del empleado de la organización:',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_SEARCH_CRITERIA_ATTACHMENTCODE': 'Tipo de adjunto',
  'SM.ORGSTAFF.LABEL.ORG_FORCEDELETE': 'Eliminar permanentemente',
  'SM.ORGSTAFF.TITLE.CURRENCY_TYPE': 'Tipo de moneda',
  'SM.COMMON.LABEL.HIGHRISKLOGList': 'Monitoreo de operaciones de alto riesgo',
  'SM.COMMON.LABEL.SURVEY_TITLE_SORT': 'Secuencia de preguntas',
  'SM.ORGSTAFF.LABEL.BANK_NAME': 'Nombre del banco',
  'SM.COMMON.LABEL.CURRENCY_CREATE_TITLE': 'Tipo de Divisa Información Básica',
  'SM.ORGSTAFF.TIPS.REGION_DST_SWITCHINTERVAL_TIPS':
    'Unidad: segundo. rango: 1 a 86400. Si este parámetro se deja vacío, se utiliza el valor por defecto 3600.',
  'SM.COMMON.LABEL.SURVEY_MUTI_CHOOSE': 'Múltiples opciones',
  'SM.ORGSTAFF.OPTION.WEEKLIST_LST': 'Último',
  'SM.ORGSTAFF.TITLE.CURRENCY_EXCHANGE_RATE': 'Tipo de cambio de divisas',
  'SM.COMMON.LABEL.BANKCARD_BANKSIGN_LENG':
    'Duración de la identificación bancaria de la emisión de la tarjeta',
  'SM.COMMON.LABEL.CHARGE_CODE_GROUP': 'Grupo de código de carga',
  'SM.COMMON.LABEL.CHARGECODE_GROUPTYPE': 'Tipo de grupo',
  'SM.COMMON.BUTTON.PROPERTY_CREATE_GROUP': 'Nuevos grupos de atributos extendidos',
  'SM.COMMON.MESSAGE.DATADICT_SAVE_SUCCESSFULLY': 'Operación realizada con éxito.',
  'SM.ORGSTAFF.TITLE.ADDR_CFG_LIST': 'Formato de visualización de direcciones',
  'SM.ORGSTAFF.OPTION.WEEKDAY_THU': 'Jueves',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ONLINE_SIGNTIME': 'Hora de inicio de sesión',
  'SM.COMMON.LABEL.HOLIDAY_MONDAY': 'EN',
  'SM.COMMON.LABEL.OPERLOG_MODULE': 'Módulo',
  'SM.COMMON.LABEL.SURVEY_OPTION_TYPE': 'Tipo de opción',
  'SM.ORGSTAFF.LABEL.ADDR_ADDRESS_NAME': 'Nombre de la dirección',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_RESTRICT_MODE': 'Modo de restricción',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_TEAM': 'Equipo de trabajo',
  'SM.ORGSTAFF.TITLE.CONTACT_INFO': 'Gerente',
  'SM.COMMON.LABEL.OPERLOG_OBJECT_TYP_NAME': 'Nombre de tipo de objeto',
  'SM.COMMON.LABEL.SYSMENU_SELECT_MENUS': 'Menú seleccionado',
  'SM.COMMON.BUTTON.SURVEY_NEW_QUESTION': 'Nueva pregunta',
  'SM.COMMON.LABEL.MENU_ACCESS_END_TIME': 'Hora de finalización',
  'SM.ORGSTAFF.LABEL.AFFILIATED_BE': 'Afiliado BE',
  'SM.COMMON.LABEL.AREAINFO_MAINTAIN': 'Gestión de códigos de área',
  'SM.ORGSTAFF.LABEL.REGION_LEVEL': 'Nivel de región',
  'SM.ORGSTAFF.MESSAGE.REGION_VALIDATE_IPFORMATERROR': 'Dirección IP no válida',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_GENDER': 'Sexo',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_RESET_ALLSUCCESS':
    'Restablecimiento éxitoso de contraseña de empleado',
  'SM.ORGSTAFF.TIPS.ENTER_ORGNAME': 'Introduzca el nombre del grupo de trabajo.',
  'SM.COMMON.LABEL.SYSMENU_STATUSDATE': 'Fecha de estado',
  'SM.COMMON.LABEL.CURRENCY_MEASUREMENT_RATE': 'Tarifas',
  'SM.ORGSTAFF.TITLE.CONFIRM_DEL': 'Confirmar',
  'SM.COMMON.LABEL.COLON': ':',
  'SM.COMMON.BUTTON.ACCESS_RELATION': 'Añadir organización de acceso',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_MARITALSTATUS': 'Estado civil',
  'SM.COMMON.BUTTON.PROPERTY_CREATE': 'Nuevo',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_STATUS_PAUSE': 'Desactivado',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_SECONDMENT_STARTDATE': 'Fecha de inicio',
  'SM.COMMON.BUTTON.ATTACHMENT_ADDACCESSORY': 'Guardar',
  'SM.COMMON.LABEL.MEASUREMENT_SOUMEASYS': 'Sistema de medición de fuente',
  'SM.ORGSTAFF.MESSAGE.DELETE_TITLE': 'Confirmar',
  'SM.COMMON.LABEL.ERATE_EXPDATE': 'Tiempo de caducidad',
  'SM.COMMON.TITLE.PRJ_EXPORT': 'Han comenzado a exportar',
  'SM.ORGSTAFF.LABEL.STAFF_AUTHCOPY_TARGETNO': 'Cuenta de destino',
  'SM.ORGSTAFF.TITLE.ERROR_TITLE': 'Error',
  'SM.COMMON.MESSAGE.ERATE_TIME_COMPARE':
    'La hora de finalización debe ser posterior a la hora de inicio.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_RESET': 'Restablecer la contraseña de empleado',
  'SM.ORGSTAFF.LABEL.ADDR_DISP_DETAIL_LIST': 'Lista de detalles de formato',
  'SM.ORGSTAFF.MESSAGE.TEAM_BLANK_VIEW': 'Gestionar equipos en el árbol de OU.',
  'SM.COMMON.LABEL.BANKCARD_CREATETIME': 'Adición de tiempo',
  'SM.ORGSTAFF.OPTION.EMPLOYEE_ADD_ROLE': 'Añadir rol',
  'SM.ORGSTAFF.LABEL.STAFF_INFO_BASIC': 'Información básica',
  'SM.ORGSTAFF.OPTION.STAFF_IP_SINGLE': 'Dirección IP única',
  'SM.COMMON.TITLE.CONFIRM_DEL': 'Confirmar',
  'SM.ORGSTAFF.OPTION.MONTH_9': 'septiembre',
  'SM.ORGSTAFF.OPTION.MONTH_8': 'agosto',
  'SM.ORGSTAFF.OPTION.MONTH_7': 'Julio',
  'SM.COMMON.TITLE.SURVEY_CLASS_NAME_INFO': 'Información del nombre de clasificación',
  'SM.COMMON.MESSAGE.SYSMENU_AUTHIDREQUIRE': 'Ingrese el ID de permiso del menú.',
  'SM.ORGSTAFF.OPTION.MONTH_6': 'junio',
  'SM.ORGSTAFF.OPTION.MONTH_5': 'Mayo',
  'SM.ORGSTAFF.OPTION.MONTH_4': 'Abril',
  'SM.ORGSTAFF.TITLE.MEASUREMENT': 'Unidad de medición',
  'SM.ORGSTAFF.MESSAGE.ORG_NOT_EXSIT': 'La OU no existe.',
  'SM.ORGSTAFF.OPTION.MONTH_3': 'Marzo',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_EMPLOYEENAME': 'Empleado',
  'SM.ORGSTAFF.OPTION.MONTH_2': 'Febrero',
  'SM.COMMON.LABEL.CURRENCY_TYPE_ID': 'Identificación',
  'SM.ORGSTAFF.OPTION.MONTH_1': 'Enero',
  'SM.COMMON.TIPS.BANKCARD_LENGTH': 'Ingrese un entero que contenga no más de 2 dígitos.',
  'SM.ORGSTAFF.TITLE.TEAM_SAVESUCCESS': 'Operación realizada con éxito.',
  'SM.ORGSTAFF.LABEL.BE_LOGO': 'Logotipo',
  'SM.ORGSTAFF.MESSAGE.MAX_TREE_LEVEL': 'Solo puede crear hasta 6 niveles de suborganizaciones.',
  'SM.PROJECT.TITLE.EXPORT_SELECTED_EMPLOYEE': 'Exportar elemento seleccionado',
  'SM.ORGSTAFF.LABEL.TEAM_INCLUDESUB': 'Incluir Sub-OU',
  'SM.ORGSTAFF.LABEL.ORG_LEVEL': 'Nivel OU',
  'SM.ORGSTAFF.TITLE.EMPTY':
    'El correo electrónico y el teléfono de contacto no pueden estar vacíos',
  'SM.ORGSTAFF.TITLE.COMMON_STAFF_INFO': 'Lista de cuentas de empleados',
  'SM.COMMON.TITLE.ACCESS.SELECT_ALL_STAFF': 'Seleccionar todos los empleados de esta organización',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_SEARCH_CRITERIA_ATTACHMENTNAME': 'Nombre del adjunto',
  'SM.COMMON.LABEL.SYSMENU_IDEL': 'Lanzamiento',
  'SM.COMMON.LABEL.SYSMENU_MENUID': 'ID de menú',
  'SM.ORGSTAFF.LABEL.ROLETMPL_PERMISSION_NAME': 'Nombre de permiso de función',
  'SM.COMMON.LABEL.CURRENCY_TYPE_INPUT_TIPS': 'Introduzca el nombre del tipo de moneda',
  'SM.ORGSTAFF.LABEL.ADDR_ORDER': 'Secuencia de visualización',
  'SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE_TEMP_LIST': 'Lista de cuestionarios',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_RESTRICTIONTYPE': 'Modo de procesamiento',
  'SM.COMMON.LABEL.MEASUREMENT_SOUMEAUNIT': 'Unidad de medición de fuente',
  'SM.ORGSTAFF.MESSAGE.ERROR.SAVEFAILED': 'Error al guardar',
  'SM.ORGSTAFF.LABEL.TEAM_ACCESS_EMPLOYEE': 'Personal de visita',
  'SM.ORGSTAFF.LABEL.NO': 'No',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_TERMINATE_SECONDMENT': 'Cese de personal',
  'SM.COMMON.LABEL.CHARGECODE_DESC': 'Descripcion',
  'SM.COMMON.LABEL.HOLIDAY_TUESDAY': 'Miércoles',
  'SM.ORGSTAFF.BUTTON.EMPLOYEE_EXPORTALL': 'Exportación',
  'SM.ORGSTAFF.OPTION.QUERYSUBDEPT': 'Departamento de Consulta de Nivel Inferior',
  'SM.COMMON.LABEL.MEASUREMENT_DESTMEASYS': 'Sistema de medición de objetivos',
  'SM.ORGSTAFF.MESSAGE.SELECTEDOU_CANNOT_SELECTED_AGAIN':
    'La organización seleccionada no se puede volver a seleccionar.',
  'SM.ORGSTAFF.MESSAGE.STAFF_DELETE_CONFIRM':
    'Después de eliminar un empleado, la cuenta correspondiente del empleado no se puede restaurar y solo se puede volver a crear. ¿Eliminar o no?',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ONLINE_BE': 'BE',
  'SM.ORGSTAFF.LABEL.REGION_DST_ENDTIME': 'Hora de finalización del horario de verano',
  'SM.TENANTSPACE.LDAP_SERVERURL': 'URL del servidor',
  'SM.ORGSTAFF.TITLE.BE_CURRENCYCODE': 'Código de Moneda',
  'SM.ORGSTAFF.TITLE.ATTR_ATTRSETTING': 'Atributo extendido',
  'SM.COMMON.LABEL.BANKCARD_TYPE': 'Tipo de tarjeta',
  'SM.COMMON.MESSAGE.DELETE_SUCCESS': 'Se ha eliminado correctamente.',
  'SM.COMMON.TIPS.NEED_TO_NEW_STD_MIN_CURRENCY_UNIT':
    'Necesidad de configurar la unidad estándar de moneda y la unidad más pequeña.',
  'SM.COMMON.LABEL.OPERLOG_OPER_LIST': 'Lista de registros de operaciones',
  'SM.ORGSTAFF.LABEL.INH_BY_SUB': 'Permitir herencia por Sub-OU',
  'SM.COMMON.LABEL.MEASUREMENT_ERROR_DESCRIPTION': 'Descripción de error',
  'SM.COMMON.LABEL.SYSMENU_SUSPENDED_S': 'desactivar',
  'SM.COMMON.LABEL.ERATE_DESCRIPTION': 'Descripcion',
  'SM.ORGSTAFF.TITLE.CHARGE_CODE_GROUP': 'Cargar código de grupo',
  'SM.COMMON.TIPS.CURRENCY_DECIMALPOSITIONS_TIPS':
    'El valor – indica que las posiciones decimales se muestran en función de la precisión de almacenamiento de moneda.',
  'SM.COMMON.LABEL.OPERLOG_CONTENT': 'Contenido de la operación',
  'SM.ORGSTAFF.LABEL.TEAM_MANAGERINFO': 'Información del gerente',
  'SM.COMMON.MESSAGE.MODIFY_AREAID_TYPE':
    'La información ya existe. Cambie el código de área o el tipo.',
  'SM.ORGSTAFF.OPTION.YES': 'Sí',
  'SM.COMMON.LABEL.SYSMENU_ACTIVE': 'Activar',
  'SM.ORGSTAFF.LABEL.ORG_REGION': 'Región',
  'SM.ORGSTAFF.BUTTON.ADD_IN': 'Añadir en',
  'SM.COMMON.LABEL.SYS_MEASUREMENT_DEFAULT': 'Medición por defecto',
  'SM.ORGSTAFF.LABEL.STAFF_PASSWORD': 'Contraseña nueva',
  'SM.ORGSTAFF.LABEL.TEAM_ORGANIZATION': 'Unidad de Organización',
  'SM.COMMON.MESSAGE.CHARGECODE_TO_RETIRE':
    '¿Está seguro de que desea cambiarlo al estado Cancelado?',
  'SM.ORGSTAFF.LABEL.ACCESS.ORG_REQUIRED': 'Seleccione una organización',
  'SM.ORGSTAFF.TITLE.REGION_USEPARENT': 'Heredar datos de región superior',
  'SM.ORGSTAFF.TITLE.CONTACT_MIDDLE_NAME': 'Segundo nombre',
  'SM.ORGSTAFF.LABEL.STAFF_PASSWORDAGAIN': 'Confirmar contraseña',
  'SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE': 'Menú',
  'SM.ORGSTAFF.TITLE.ASSIGNE_MANAGER': 'Asignar administrador',
  'SM.COMMON.LABEL.HIGHRISKLOG_PERATOR': 'Operador',
  'SM.COMMON.BUTTON.MEASUREMENT_IMPORT': 'Importación',
  'SM.COMMON.LABEL.ATTACHMENT_ADD_TITLE': 'Nuevo archivo adjunto',
  'SM.ORGSTAFF.MESSAGE.PWD.CHECKMSG': 'Las dos contraseñas son diferentes.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_FORCE_DELETE': 'Forzar la supresión',
  'SM.ORGSTAFF.BUTTON.TEAM_CANCEL': 'Cancelar',
  'SM.ORGSTAFF.MESSAGE.COMMON_TIPS_STAFF_SELECT': 'Seleccionar empleado.',
  'SM.COMMON.LABEL.SYSMENU_DEACTIVATED_D': 'desechar',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_RESUME': 'Reanudar',
  'SM.ORGSTAFF.MESSAGE.STAFF_PWD_RESET_CONFIRM':
    '¿Está seguro de que desea restablecer la contraseña?',
  'SM.COMMON.LABEL.HOLIDAY_HOLIDAYDESCRIPTION': 'Descripcion',
  'SM.ORGSTAFF.LABEL.STAFF_ROLE_EFFECTIVE_OU_ADD': 'Seleccionar OU',
  'SM.COMMON.LABEL.SYSMENU_CREATEDATE': 'Fecha de creación',
  'SM.COMMOM.BUTTON.IMPORT': 'Importación',
  'SM.ORGSTAFF.TIPS.ENTER_EMPNAME': 'Por favor, introduzca el nombre del empleado.',
  'SM.ORGSTAFF.LABEL.ORG_ORGID': 'ID de la OU',
  'SM.ORGSTAFF.MESSAGE.ADDR_USE_DELETE':
    'Error en la eliminación porque se ha hecho referencia a la dirección.',
  'SM.ORGSTAFF.MESSAGE.BLANK_VIEW':
    'Gestionar departamentos en el árbol de unidades organizativas.',
  'SM.ORGSTAFF.TITLE.CONTACT_EMPLOYEE_CODE': 'Contactar con el empleado',
  'SM.COMMON.MESSAGE.PROJECT_CODE': 'El código del proyecto es:',
  'SM.COMMON.LABEL.AREAID': 'ID de área',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_UPLOADTIME': 'Tiempo de carga',
  'SM.ORGSTAFF.LABEL.CHANNEL_ID': 'ID de canal',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ROAM_INVALID': 'Inválido',
  'SM.ORGSTAFF.LABEL.INPUT_SEARCH_KEYWORD': 'Ingrese la palabra clave',
  'SM.COMMON.LABEL.MEASUREMENT_EXPORT_FILE_FORMAT': 'Formato de archivo exportado',
  'SM.COMMON.LABEL.MEASUREMENT_EXPORT_FAILURE': 'Fallo',
  'SM.ORGSTAFF.MESSAGE.ORG_ROLE_DELETE':
    'Si se eliminan estos datos, la relación entre el usuario y el rol se eliminará automáticamente?',
  'SM.COMMON.LABEL.SURVEY_DESC': 'Descripcion',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PVT': 'Tipo de validación de contraseña',
  'SM.COMMON.LABEL.CHARGECODE_OPERATION': 'Operación',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_ATTACHMENTCODE': 'Tipo de adjunto',
  'SM.COMMON.LABEL.ERATE_SEARCH_CRITERIA': 'Criterios de búsqueda',
  'SM.COMMON.LABEL.MODULE_MODULETYPE': 'Categoría',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ROAM_CREATEDATE': 'Fecha de creación',
  'SM.COMMON.LABEL.OPERLOG_OBJECT_NAME': 'Nombre del objeto',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_LOGIONRESTRICTION': 'Restricción de inicio de sesión',
  'SM.COMMON.LABEL.SYSMENU_PARENT': 'ID del menú principal',
  'SM.COMMON.TIPS.PLZ_SAVE_CURRENCY_MEASUREMENT_UNIT':
    'Por favor, guarde la unidad de medida de moneda.',
  'SM.COMMON.LABEL.CURRENCY_FORMAT_TITLE': 'Formato de visualización de moneda',
  'SM.COMMON.LABEL.SYSMENU_GRANT_MENU_AUTH': 'Administrar permisos de menú',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST': 'Lista de archivos adjuntos',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_MAC_ADDR': 'Dirección MAC',
  'SM.COMMON.LABEL.MEASUREMENT_YES': 'Sí',
  'SM.ORGSTAFF.LABEL.ADDR_REGION_LAYER': 'Nivel de región administrativa',
  'SM.COMMON.TITLE.ACCESS.FILTER': 'Por favor ingrese palabras clave para filtrar',
  'SM.ORGSTAFF.TITLE.TEAM_SUCCESS': 'Información',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_TITLE': 'Titulo',
  'SM.ORGSTAFF.OPTION.WEEKLIST_TID': 'Tercero',
  'SM.ORGSTAFF.LABEL.STAFF_ROLE_EFFECTIVE_OU_DETAIL': 'Detalle',
  'SM.ORGSTAFF.OPTION.WEEKDAY_WED': 'Miércoles',
  'SM.ORGSTAFF.LABEL.ORG_RECOVER': 'Reanudar',
  'SM.ORGSTAFF.MESSAGE.DELETE_MSG': '¿Está seguro de que desea eliminarlo?',
  'SM.ORGSTAFF.TITLE.BE_CURRENCYNAME': 'Nombre de la moneda',
  'SM.COMMON.LABEL.CURRENCY_DISPLAY_PREFIX': 'Prefijo de visualización',
  'SM.COMMON.LABEL.ADD_EXCHANGE_RATE':
    'Añadir / Ajustar el tipo de cambio de las monedas asociadas.',
  'SM.COMMON.BUTTON.SAVE': 'Guardar',
  'SM.ORGSTAFF.LABEL.TEAM_REFRESH': 'Actualizacion',
  'SM.COMMON.TITLE.ERATE_ERROR': 'Error',
  'SM.ORGSTAFF.LABEL.STAFF_ROLE_NAME': 'Nombre del rol',
  'SM.ORGSTAFF.LABEL.TEAM_EMPLOYEENAME': 'Empleado',
  'SM.COMMON.LABEL.SURVEY_TYPE_NAME': 'Nombre de la categoría',
  'SM.ORGSTAFF.LABEL.REGION_CONFIG_TIMEZONE':
    'Región falta información de zona horaria, por favor, configure la zona horaria para la región o la región padre.',
  'SM.ORGSTAFF.TITLE.IMPORT_EMPLOYEE_TITLE': 'Importación de empleados',
  'SM.COMMON.LABEL.HIGHRISKLOG_PARAMS': 'Parámetro',
  'SM.ORGSTAFF.LABEL.TEAM_ACCOUNT': 'Cuenta',
  'SM.ORGSTAFF.OPTION.STAFF_RESTRICTIONTYPE_DISALLOWED': 'Rechazar',
  'SM.ORGSTAFF.MESSAGE.BE_SELECT_DEFAULT_CURRENCY': 'Seleccione la moneda predeterminada.',
  'SM.COMMON.BUTTON.CHARGECODE_CONFIRM': 'Información de contacto',
  'SM.ORGSTAFF.TITLE.CONTACT_FIRST_NAME': 'Nombre',
  'SM.COMMOM.LABEL.SURVEY_INFO': 'InformaciÃ3n del cuestionario',
  'SM.ORGSTAFF.LABEL.BANK_CHILEBEUSED': 'Permitir al hijo usar BE',
  'SM.COMMON.LABEL.HEADING_BASEINFO': 'Información básica',
  'SM.ORGSTAFF.LABEL.TEAM_ADD': 'Nuevo',
  'SM.ORGSTAFF.TITLE.TEAM_AVAILABLEEMPL': 'Empleados disponibles',
  'SM.ORGSTAFF.LABEL.STAFF_INFO': 'Información del empleado',
  'SM.ORGSTAFF.LABEL.STAFF_ROLE_ID': 'ID de rol',
  'SM.COMMON.LABEL.SYSMENU_VERSION': 'Versión',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_LASTNAME': 'Apellido',
  'SM.ORGSTAFF.LABEL.TEAM_BUSINESSENTITY': 'Nombre BE',
  'SM.COMMON.LABEL.SYSMENU_CURRENCYLANGUAGE': 'Idioma actual',
  'SM.ORGSTAFF.TITLE.CONTACT_HOME_PHONE': 'Teléfono de la casa',
  'SM.ORGSTAFF.LABEL.CHANNEL_NAME': 'Nombre del canal',
  'SM.COMMON.MESSAGE.CHARGECODE_DELETE_GROUP':
    '¿Está seguro de que desea eliminar el grupo de código de cargo?',
  'SM.COMMON.LABEL.AREATYPE': 'Tipo de área',
  'SM.COMMON.LABEL.SYSMENU_IDEL_I': 'Lanzamiento',
  'SM.COMMON.LABEL.MEASUREMENT_ENTITY_TYPE': 'Tipo de unidad',
  'SM.COMMON.MESSAGE.CURRENCY_UNIT_TIPS': 'Por favor ingrese el número.',
  'SM.ORGSTAFF.LABEL.ORG_IMPORTSUCCESS': 'Datos importados correctamente.',
  'SM.COMMON.TIPS.MENU_REBUILD_INFO':
    'La información de recreación del índice del menú se ha enviado a la cola de mensajes. Espere.',
  'SM.ORGSTAFF.LABEL.SELECTED_ROLE': 'Rol seleccionado',
  'SM.COMMON.LABEL.MEASUREMENT_CONV_RATIO': 'Ratio de unidad de medición',
  'SM.COMMON.LABEL.OPERLOG_SEARCH': 'Buscar',
  'SM.COMMON.LABEL.SYSMENU_GRANT_AUTH': 'Administrar permisos',
  'SM.ORGSTAFF.LABEL.BREGION_NAME': 'Región',
  'SM.ORGSTAFF.TITLE.BE_DEFAULT': 'Moneda por defecto',
  'SM.COMMON.TITLE.MEASUREMENT_UNITLIST': 'Lista de unidades de medición',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_BE': 'ID BE',
  'SM.COMMON.LABEL.HIGHRISKLOG_OPERATIONNAME': 'Nombre de operación',
  'SM.COMMON.MESSAGE.DELETE_CURRENCY_STD_MIN_MEASUREMENT':
    'Moneda necesita utilizar unidad estándar y unidad mínima, ¿está seguro de eliminar?',
  'SM.ORGSTAFF.LABEL.STAFF_ROLE_PATH': 'Ruta',
  'SM.ORGSTAFF.TITLE.MSGBOX_INFO': 'Sugerencias',
  'SM.ORGSTAFF.OPTION.WEEKDAY_MON': 'Lunes',
  'SM.COMMON.LABEL.CURRENCY_TYPE_FLAG': 'Últimos',
  'SM.COMMON.LABEL.OPERLOG_LOGIN_IP': 'IP de inicio de sesión',
  'SM.COMMON.LABEL.CHARGECODE_GROUPMEMBER': 'Códigos de carga',
  'SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE_NAME_LANG': 'Cuestionario Nombre Idioma',
  'SM.COMMON.LABEL.CURRENCY_ROUNDMODE': 'Modo de redondeo',
  'SM.ORGSTAFF.LABEL.ACCESS.ADD_SUCCESS': 'Agregado exitosamente',
  'SM.COMMON.LABEL.SYSMENU_MENU_AUTH_ROLE_LIST': 'Lista de roles asignados',
  'SM.COMMON.TITLE.BANKCARD_CONFIRM': 'Información de contacto',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_12': 'diciembre',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_11': 'Noviembre',
  'SM.COMMON.LABEL.CHARGECODE_DATAGIRD_CATALOG': 'Catálogo',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_10': 'octubre',
  'SM.COMMON.TIPS.ONLYONE_UNDERLINE_FORBIDDEN':
    'El valor de entrada no puede contener caracteres especiales.',
  'SM.ORGSTAFF.LABEL.ADDR_PLEASE_INPUT': 'Por favor ingrese su nombre',
  'SM.COMMOM.BUTTON.EDIT': 'Modificar',
  'SM.ORGSTAFF.MESSAGE.SUCCESS.SAVE': 'Éxito',
  'SM.COMMON.LABEL.BANKCARD_BANKTYPE': 'Tipo de banco',
  'SM.COMMON.LABEL.MEASUREMENT_NOTIFICATION_NAME': 'Nombre en la notificación',
  'SM.ORGSTAFF.BUTTON.ADDR_LINE': 'Línea',
  'SM.ORGSTAFF.TITLE.TEAM_DELETE_CONFIRM': 'Confirmar',
  'SM.COMMON.LABEL.CHARGECODE_RETIRE': 'Cancelar',
  'SM.COMMON.TIPS.SPECIAL_CHAR_CANNOT_START_NUMBER': 'No se permite comenzar con el número.',
  'SM.COMMON.LABEL.CURRENCY_DISPLAY_FORMATE': 'Formato de visualización',
  'SM.ORGSTAFF.LABEL.TEAM_CONTACTINFO': 'Información de contacto',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_ATTACHMENTNAME': 'Nombre del adjunto',
  'SM.ORGSTAFF.LABEL.ADDR_ADDERSS': 'Dirección',
  'SM.ORGSTAFF.MESSAGE.SURE_TO_DO': '¿Continúar?',
  'SM.ORGSTAFF.LABEL.TEAM_TEAMSHORTNAME': 'Nombre corto del equipo',
  'SM.ORGSTAFF.TITLE.BANK_TITLE': 'Banco',
  'SM.COMMON.MESSAGE.CHARGECODE_OPERATION_SUCCESS': 'Operación realizada con éxito.',
  'SM.ORGSTAFF.MESSAGE.USERRESTRICT_CONFIRM_SAVE':
    'El segmento de tiempo de inicio de sesión solo puede mantener un registro y el otro segmento de tiempo de inicio de sesión se eliminará cuando se agregue.',
  'SM.COMMON.LABEL.BANKCARD_BANKSIGN_PARAM': 'Identificación bancaria de emisión de tarjeta',
  'SM.COMMON.BUTTON.SURVEY_NEW_ANSWER_OPTIONS': 'Nueva opción de respuesta',
  'SM.ORGSTAFF.TIPS.MAC_FORMAT_ERROR': 'Error de formato de dirección MAC',
  'SM.COMMON.LABEL.MODULE_BASICINFO': 'Información básica',
  'SM.ORGSTAFF.LABEL.REGION_DST_MODE': 'Modo DST',
  'SM.COMMON.BUTTON.EDIT': 'Modificar',
  'SM.COMMON.MESSAGE.ERATE_VLUEW_RANGE': 'El valor varía de 0 a {1}.',
  'SM.COMMON.LABEL.PROVINCE': 'Provincia',
  'SM.ORGSTAFF.LABEL.STAFF_OPRATION': 'Operación',
  'SM.COMMON.LABEL.ATTACHMENT_LIMIT_INFO': 'Sin restricciones',
  'SM.COMMON.LABEL.SYSMENU_DESCRIPTION': 'Menú Descripción',
  'SM.COMMON.MESSAGE.BANKCARD_DELETE_CONFIRM': '¿Está seguro de que desea eliminar?',
  'SM.COMMON.MESSAGE.CURRENCY_UNIT_EXCHAGE_TIPS': 'Por favor ingrese entero.',
  'SM.ORGSTAFF.MESSAGE.CHOOSE_BE_FIRST': 'Por favor, primero elija BE',
  'SM.COMMON.LABEL.MODULE_NAME': 'Nombre',
  'SM.ORGSTAFF.TITLE.MEASUREMENT_CONV': 'Conversión de medidas',
  'SM.COMMON.LABEL.SURVEY_REMARKS': 'Comentarios',
  'SM.ORGSTAFF.OPTION.ROLE_OPTION_MODE': 'Modo de operación',
  'SM.ORGSTAFF.LABEL.OU_HIERARCHY': 'Jerarquía OU',
  'SM.COMMON.BUTTON.VIEW': 'Vistas',
  'SM.COMMON.MESSAGE.CHARGECODE_CREATE_DATA':
    '¿Está seguro de que desea agregar estos códigos de cargo?',
  'SM.ORGSTAFF.BUTTON.ADD': 'Nuevo',
  'SM.ORGSTAFF.MESSAGE.STAFF_SUSPEND_CONFIRM':
    '¿Está seguro de que desea suspender la cuenta de empleado?',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_SVD': 'Intervalo de tiempo de espera de sesión',
  'SM.ORGSTAFF.MESSAGE.BANK_STATUS_ERROR':
    'Error al guardar porque la información bancaria ha caducado.',
  'SM.COMMON.LABEL.HOLIDAY_BASICINFORMATION': 'Información básica',
  'SM.ORGSTAFF.LABEL.ORG_ADDITIONINFO': 'Información adicional',
  'SM.ORGSTAFF.TITLE.BE_ADDRESS_CFG': 'Formato de dirección',
  'SM.ORGSTAFF.TITLE.STAFF_INFO_MDY': 'Modificar empleado',
  'SM.COMMON.TITLE.ACCESS.SELECT_ORG': 'Seleccione la organización de acceso',
  'SM.ORGSTAFF.MESSAGE.STAFF_FORBID_CONFIRM':
    'Después de que un empleado está deshabilitado permanentemente, la cuenta del empleado solo se puede crear repetidamente después de que se elimina. ¿Está seguro de que desea deshabilitar al empleado de forma permanente?',
  'SM.ORGSTAFF.MESSAGE.ERROR.SAVEACCESSLIMIT':
    'Los empleados accesibles pueden agregar hasta 100 por lote',
  'SM.COMMON.LABEL.OU_HIERARCHY_NAME': 'Nombre de RelationShip de OU',
  'SM.COMMON.LABEL.BANKCARD_LIST': 'Lista de tarjetas bancarias',
  'SM.COMMON.MESSAGE.MEASUREMENT_SAVE_CONFIRM':
    'Al guardar una unidad de medida estándar, sustituye a una unidad estándar existente y modifica las unidades estándar de otras unidades de medida.',
  'SM.COMMON.LABEL.SYSMENU_SEARCH_MENU': 'Criterios de búsqueda',
  'SM.ORGSTAFF.TITLE.ADDR_TEMPATE_ID': 'ID de plantilla',
  'SM.COMMON.MESSAGE.SYSMENU_MENUNAMEREQUIRE': 'Introduzca el nombre del menú.',
  'SM.ORGSTAFF.TITLE.ADDR_DISP_FORMAT': 'Formato de visualización',
  'SM.ORGSTAFF.MESSAGE.STARTANDTEND_ISNULL':
    'Las fechas de inicio y fin no pueden estar vacías al mismo tiempo.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_TIMERANGE': 'Rango de tiempo',
  'SM.ORGSTAFF.LABEL.SELECT_EXCEL': 'Por favor, seleccione un archivo de Excel',
  'SM.ORGSTAFF.TITLE.EMP_BELONG_DEPT': 'Departamento',
  'SM.ORGSTAFF.LABEL.BUSINESSENTITY_DISPLAY_NO': 'Secuencia de visualización',
  'SM.COMMON.BUTTON.CHARGECODE_CANCEL': 'Cancelar',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_ADDR': 'Dirección',
  'SM.ORGSTAFF.LABEL.TEAM_TEAMTYPE': 'Tipo de equipo',
  'SM.COMMON.LABEL.SYSMENU_MENUCRUMB': 'Ruta',
  'SM.COMMON.LABEL.SURVEY_QUESTIONNAIRE_NAME': 'Nombre del cuestionario',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_NEW_PASSWORD_CONFIRM': 'Confirmar nueva contraseña',
  'SM.COMMON.LABEL.CURRENCY_TYPE': 'Tipo de moneda',
  'SM.COMMON.BUTTON.ATTACHMENT_MGR_SEARCH_CRITERIA_RESET': 'Restablecer',
  'SM.COMMON.LABEL.MENU_ATTR_TITLE': 'Extender atributo',
  'SM.ORGSTAFF.LABEL.TEAM_REMOVE': 'Retirar',
  'SM.ORGSTAFF.BUTTON.SAVE': 'Guardar',
  'SM.COMMON.LABEL.CHOOSE_OU_ADD': 'Elegir OU para añadir',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_COPYPERMISSION': 'Copiar permiso',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD41':
    'Bloqueo debido a fallos de inicio de sesión consecutivos dentro del período especificado, en minutos',
  'SM.ORGSTAFF.MESSAGE.REGION_VALIDATE_IPRANGEERROR':
    'El segmento de dirección IP está configurado incorrectamente.',
  'SM.ORGSTAFF.MESSAGE.ERROR.MAXLIMIT':
    'El número de instituciones bajo un inquilino no puede exceder 999',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD40': 'Regla personalizada',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ONLINE_ACCOUNT': 'Cuenta',
  'SM.COMMON.LABEL.CREATE_AREAINFO_MAINTAIN': 'Nueva información de la región',
  'SM.COMMON.TITLE.BANKCARD_MDF': 'Modificar tarjeta bancaria',
  'SM.ORGSTAFF.TIPS.RESTRICTMAC': 'Introduzca la dirección MAC correcta.',
  'SM.ORGSTAFF.TITLE.QUERYFAIL_TITLE': 'Error de búsqueda.',
  'SM.COMMON.BUTTON.CREATE': 'Nuevo',
  'SM.COMMON.MESSAGE.VALIDATE_MAXLENGTHB': 'Se permite un máximo de 0 caracteres.',
  'SM.COMMON.LABEL.HOLIDAY_HOLIDAYTYPE': 'Tipo',
  'SM.COMMON.LABEL.SURVEY_MODIFIED_TIME': 'Tiempo de modificación',
  'SM.COMMON.LABEL.OPERLOG_SUCCESS_MEW': 'Éxito',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD39':
    'La contraseña solo puede contener dígitos',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD38':
    'Caracteres que no pueden estar contenidos en una contraseña',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD37':
    'La contraseña no puede ser la misma que la cuenta o un subconjunto de la cuenta',
  'SM.COMMON.MESSAGE.CHARGECODE_TO_DRAFT':
    '¿Está seguro de que desea cambiarlo al estado Borrador?',
  'SM.COMMON.BUTTON.BANKCARD_RESET': 'Restablecer',
  'SM.COMMON.MESSAGE.SYSMENU_MAX_EDIT_LIMIT': 'Límite máximo de edición.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD36':
    'Lista de formatos de contraseña prohibidos, como ABCABC y ABCDEF',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD35': 'Contraseña predeterminada',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD34':
    'Período mínimo de validez de la contraseña, en horas',
  'SM.COMMON.LABEL.MENU_ACCESS_STRART_TIME': 'Hora de inicio',
  'SM.COMMON.LABEL.MEASUREMENT_RESULT_LIST': 'Lista de resultados',
  'SM.ORGSTAFF.MESSAGE.ADDR_DELETE_SUCCESS': 'Se ha eliminado correctamente.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD33':
    'La contraseña no puede ser caracteres o dígitos consecutivos como AB, 123, AA y abc',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_UPLOAD_SIZE_MIN':
    'El archivo cargado es menor que el tamaño mínimo de 0 KB.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD32':
    'Número mínimo de caracteres de cada tipo que deben estar contenidos en una contraseña',
  'SM.ORGSTAFF.LABEL.ORG_TYPE': 'Tipo de OU',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD31': 'Longitud mínima de la cuenta',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD30': 'Longitud máxima de la cuenta',
  'SM.COMMON.LABEL.OPERLOG_SERRCH_CRITERIA': 'Criterios de búsqueda',
  'SM.COMMON.MESSAGE.OPERLOG_TIME_LOG2': 'día (s).',
  'SM.COMMON.MESSAGE.OPERLOG_TIME_LOG1': 'El rango de tiempo no puede exceder',
  'SM.COMMON.LABEL.CURRENCY_BASIC_INFO': 'Información básica',
  'SM.ORGSTAFF.TITLE.RELATION_CONFIRM_DELETE':
    'Una vez deshabilitada, la relación de organización y su relación subordinada se eliminarán. ¿Confirma que desea eliminar?',
  'SM.COMMON.LABEL.ERATE_SCURRENCY_CODE': 'Código de moneda de origen',
  'SM.COMMON.LABEL.SELECTED_OUS': 'Unidades organizativas seleccionadas',
  'SM.ORGSTAFF.LABEL.REGION_DST_STARTTIME': 'Hora de inicio del horario de verano',
  'SM.ORGSTAFF.TITLE.POPUP_NEW_PROPERTY': 'Nueva propiedad',
  'SM.COMMON.LABEL.SURVEY_TITLE_TYPE': 'Tipo de pregunta',
  'SM.ORGSTAFF.MESSAGE.DELETE_CONFIRM': '¿Está seguro de que desea eliminar?',
  'SM.ORGSTAFF.OPTION.NO': 'No',
  'SM.ORGSTAFF.LABEL.BE_TYPE': 'Tipo BE',
  'SM.COMMON.LABEL.OPERLOG_SESSION_ID': 'ID de sesión',
  'SM.COMMON.MESSAGE.MEASUREMENT_STANDARD_MUSTEXIST':
    'El sistema de medición debe tener una unidad de medición estándar.',
  'SM.ORGSTAFF.OPTION.WEEKDAY_SUN': 'Domingo',
  'SM.ORGSTAFF.LABEL.STAFF_ROLE_EFFECTIVE_OU': 'OU eficaz',
  'SM.ORGSTAFF.VALIDATOR.AFTER_TODAY':
    'La hora introducida debe ser anterior o igual a las 00:00 de hoy.',
  'SM.SM.COMMON.TITLE.GROUP_NAME_EXIST': 'El nombre del grupo de usuarios es exsited.',
  'SM.ORGSTAFF.LABEL.ORG_RECOVER_TIPS':
    'Una vez reanudada, la organización u organización y su organización subordinada estarán disponibles. ¿confirma para reanudar?',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_LOGINTIMERANGE': 'Segmento de tiempo de inicio de sesión',
  'SM.COMMON.LABEL.CURRENCY_MINIMUM_MEASUREMENT': 'Bandera de unidad mínima',
  'SM.ORGSTAFF.TITLE.BE_ADDRESS_INFO': 'Información de dirección',
  'SM.ORGSTAFF.TIPS.ENTER_OUNAME': 'Introduzca el nombre de la organización.',
  'SM.COMMON.LABEL.SURVEY_SECTION': 'Regionales',
  'SM.COMMON.TIPS.SPECIAL_CHAR_FORBIDDEN':
    'El valor de entrada no puede contener caracteres especiales.',
  'SM.COMMON.LABEL.SYS_MEASUREMENT_NAME': 'Nombre',
  'SM.COMMON.LABEL.SURVEY_CREATED_ACCOUNT': 'Creado por',
  'SM.SM.COMMON.TITLE.NO_CHINAWORD': 'No se permiten caracteres especiales.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD19':
    'Cambio de contraseña forzado en el primer inicio de sesión o después de restablecer la contraseña',
  'SM.COMMON.LABEL.MODULE_MODULELIST': 'Lista de módulos',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD18':
    'Número de días de antelación a los usuarios se les notifica la expiración de la contraseña',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_SECONDMENT_HISTORY': 'Historial de adscripción',
  'SM.COMMON.TITLE.SURVEY_ITEM_NAME_INFO': 'Información del nombre del artículo',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD15':
    'Número máximo de reintentos de contraseña',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD14':
    'Período máximo de validez de la contraseña, en días',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD13':
    'La contraseña no puede contener el nombre',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD12':
    'La contraseña debe ser diferente de la cuenta o la cuenta en orden inverso',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD11': 'Contraseña no válida',
  'SM.COMMON.LABEL.SURVEY_NOT_INPUT': 'No permitir la entrada manual',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD10': 'Caracteres especiales válidos',
  'SM.COMMON.MESSAGE.SYSMENU_SAVESUCCESS': 'Operación realizada con éxito.',
  'SM.ORGSTAFF.TITLE.CONTACT_LAST_NAME': 'Apellido',
  'SM.COMMON.LABEL.MEASUREMENT_CONV': 'Conversión de medición',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_STATUS': 'Estado',
  'SM.COMMON.LABEL.MEASUREMENT_UNIT_STANDARD': 'Bandera de unidad estándar',
  'SM.ORGSTAFF.LABEL.REGION_DISPLAYORDER': 'Secuencia de visualización',
  'SM.ORGSTAFF.LABEL.TEAM_TO': 'A',
  'SM.COMMON.LABEL.BANKCARD_TYPE_PARAM': 'Tipo de tarjeta',
  'SM.COMMON.LABEL.SURVEY_CLASS_NAME': 'Nombre de la categoría',
  'SM.ORGSTAFF.LABEL.STAFF_AUTHCOPY_SOURCENO': 'Cuenta de origen',
  'SM.COMMON.LABEL.CURRENCY_GROUPSYMBOL': 'Separador',
  'SM.ORGSTAFF.TITLE.STAFF_ROLE_BASIC': 'Rol',
  'SM.ORGSTAFF.TITLE.ADDR_DISP_LIST': 'Lista de formatos de visualización de direcciones',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD09':
    'Número máximo de caracteres consecutivos en una contraseña',
  'SM.ORGSTAFF.LABEL.TEAM_EXTENDPRO': 'Atributos extendidos',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD08':
    'La contraseña debe ser diferente de las últimas contraseñas',
  'SM.COMMON.LABEL.DATA_OPRATION': 'Operación',
  'SM.COMMON.LABEL.SURVEY_QUESTIONNAIRE_ID': 'ID del cuestionario',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD07':
    'La contraseña debe contener caracteres especiales',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD06':
    'La contraseña debe contener letras minúsculas',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD05':
    'La contraseña debe contener letras mayúsculas',
  'SM.COMMON.LABEL.MEASUREMENT_UNIT_CODE': 'Código',
  'SM.ORGSTAFF.MESSAGE.ADDR_LEVELS_HIGH': 'La dirección no es secuencial.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD04': 'La contraseña debe contener letras',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD03': 'La contraseña debe contener dígitos',
  'SM.ORGSTAFF.LABEL.PARENT_BE': 'Padre BE',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD02': 'Longitud mínima de la contraseña',
  'SM.ORGSTAFF.MESSAGE.SELECT_WG_INFO': 'Seleccione un grupo de trabajo.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD01': 'Longitud máxima de la contraseña',
  'SM.COMMON.BUTTON.PROPERTY_CONFIRM': 'Información de contacto',
  'SM.COMMON.LABEL.EDIT_AREAINFO_MAINTAIN': 'Modificar información de región',
  'SM.COMMON.TITLE.PROPERTY_SUCCESS': 'Información de contacto',
  'SM.ORGSTAFF.LABEL.ORG_SELECTORG': 'Seleccionar OU',
  'SM.COMMON.BUTTON.SEARCH': 'Buscar',
  'SM.COMMON.TITLE.ITEM_NOTE': 'Ver imagen',
  'SM.COMMON.LABEL.ERATE_EXCHANGE_RATE': 'Tipo de cambio',
  'SM.COMMON.BUTTON.DELETE': 'Borrar',
  'SM.ORGSTAFF.BUTTON.EMPLOYEE_DELETE': 'Borrar',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_MATCH_MODE': 'Modo de coincidencia',
  'SM.ORGSTAFF.TITLE.WORKGROUP': 'Grupo de trabajo',
  'SM.COMMON.LABEL.PROPERTY_CREATE_PROPERTY': 'Nuevo atributo',
  'SM.ORGSTAFF.MESSAGE.RECOVERORG': 'Reanudar la unidad organizativa.',
  'SM.AUC.LABEL.LOGINLOG_EMPLOYEE_NAME': 'Nombre del empleado',
  'SM.AUC.LABEL.LOGINLOG_LOGIN_IP_ADDRESS': 'IP de inicio de sesión',
  'SM.AUC.LABEL.LOGINLOG_OPER_CONTENT': 'Contenido de la operación',
  'SM.AUC.LABEL.LOGINLOG_OPER_TIME': 'Tiempo de operación',
  'common.bremui.title.operation': 'Operación',
  'SM.ORGSTAFF.HINT.PLEASE_INPUT': 'Por favor ingrese',
  'SM.MENU.SEARCH.INPUT': 'Nombre del menú de búsqueda',
  'SM.MENU.ADD.CUSTOM.MENU': 'Añadir menú',
  'SM.MENU.ADD.CUSTOM.PERM_REPEAT': 'Nombre de permiso duplicado',
  'SM.AUC.MESSAGE.PWDR_VALIDATE': 'Regla de verificación',
  'SM.AUC.MESSAGE.PWDR_MANAGE': 'Regla de gestión',
  'SM.AUC.MESSAGE.RULEGROUP': 'Grupo de reglas',
  'SM.AUC.MESSAGE.RULEGROUPNAME': 'Nombre del grupo de reglas',
  'SM.AUC.MESSAGE.RULEGROUPList': 'Lista de reglas',
  'SM.AUC.MESSAGE.RULEGROUPListEDIT': 'Editar un grupo de reglas',
  'SM.AUC.MESSAGE.RULEGROUPListADD': 'Agregar un grupo de reglas',
  'SM.AUC.MESSAGE.LOGIN.IP': 'Dirección IP de inicio de sesión',
  'SM.AUC.MESSAGE.PWDRULE_ACCOUNT': 'Regla de cuenta',
  'SM.AUC.LABEL.PWD_PWDRULE': 'Regla de contraseña',
  'SM.AUC.LABEL.PWD_CUSTOMIZEDRULE': 'Regla personalizada',
  'SM.AUC.LABEL.KIND': 'Categorías',
  'SM.AUC.LABEL.VALUE': 'Valor',
  'SM.MENU.DELETE.CUSTOM.ROLE.FAIL':
    'El menú se elimina correctamente. Error al eliminar el permiso asociado al menú. Compruebe si el permiso sigue siendo referenciado por otros menús o roles',
  'SM.AUC.ACCOUNT.LOCK.SEARCH.ACCOUNT': 'Buscar una cuenta',
  'SM.AUC.ACCOUNT.LOCK.USER.NOT.EXIT': 'El usuario no existe',
  'SM.AUC.LABEL.LOGINSESSION_SESSION': 'Estado de la sesión',
  'SM.AUC.LABEL.LOGINSESSION_LOGINSTATUS': 'Estado de inicio de sesión',
  'SM.AUC.LABEL.USERLOGUI_LOGINIDACCOUNT': 'Cuenta de inicio de sesión',
  'SM.AUC.MESSAGE.OPERLOG_TIPS_LOGOUT': 'Forzar la salida',
  'SM.AUC.LABEL.LOGINSESSION_LOCKSTATUS': 'Estado de bloqueo',
  'SM.AUC.LABEL.LOGINSESSION_LOCKTIME': 'Tiempo de bloqueo',
  'SM.AUC.LABEL.LOGINSESSION_LOCK': 'Bloqueo',
  'SM.AUC.LABEL.LOGINSESSION_UNLOCK': 'Desbloquear',
  'SM.AUC.LABEL.LOGINSESSION_SURE_LOCK': '¿Está seguro de que desea bloquear el usuario?',
  'SM.AUC.LABEL.LOGINSESSION_LOCKFLAG': 'Bandera de bloqueo',
  'SM.AUC.LABEL.LOGINSESSION_LOCKTYPE': 'Tipo de bloqueo',
  'SM.AUC.LABEL.LOGINSESSION_LOCK_EMPLOYEE': 'Bloqueado por',
  'SM.AUC.LABEL.LOGINSESSION_LOCKBYHAND': 'Bloqueo manual',
  'SM.AUC.LABEL.LOGINSESSION_UNLOCKBYHAND': 'Desbloqueo manual',
  'SM.AUC.LABEL.LOGINSESSION_AUTHSTATUS': 'Estado de acreditación',
  'SM.AUC.LABEL.LOGINSESSION_NEARESTPAWDAUTHTYPE': 'Estado de autenticación de última contraseña',
  'SM.AUC.LABEL.LOGINSESSION_NEARESTPWDAUTHTIME': 'Tiempo de autenticación de la última contraseña',
  'SM.AUC.LABEL.LOGINSESSION_PWDAUTHFAILTIME': 'Tiempos de error de autenticación de contraseña',
  'SM.AUC.MESSAGE.LOGINSESSION_LOGININ_STATUS': 'Iniciar sesión',
  'SM.AUC.MESSAGE.LOGINSESSION_LOGOUT_STATUS': 'No se ha iniciado sesión',
  'SM.AUC.MESSAGE.LOGINSESSION_LOCKSTATUS': 'Bloqueado',
  'SM.AUC.MESSAGE.LOGINSESSION_UNLOCKSTATUS': 'No bloqueado',
  'SM.AUC.LABEL.LOGINSESSION_PWDAUTHFAIL': 'La contraseña se autentica',
  'SM.USERGROUP.DELETEG_GROUP.TITLE': '¿Eliminar el grupo de usuarios seleccionado?',
  'SM.USERGROUP.DELETEG_GROUP.CONTENT':
    'Después de eliminar un grupo de usuarios, solo se puede volver a crear.',
  'SM.USERGROUP.DELETEG_EMPLOYEE.TITLE':
    '¿Eliminar los empleados seleccionados del grupo de usuarios actual?',
  'SM.USERGROUP.DELETEG_EMPLOYEE.CONTENT':
    'Después de eliminar un empleado de un grupo de usuarios, puede agregar el empleado al grupo de usuarios de nuevo si desea restaurar el empleado.',
  'SM.AUTH.MESSAGE.FUNCAUTH_MENU': 'Menú',
  'SM.AUTH.LABEL.ROLETMPL_OPERTIME': 'Tiempo de operación',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DESC_FIELD_INPUT': 'Nombre del campo Nombre:',
  'SM.AUTH.TITLE.ROLE_FUNCAUTH_FAIL':
    'Error al guardar algunos permisos de función. Estos permisos son exclusivos o no se pueden conceder a usted.',
  'SM.AUTH.MESSAGE.CHANGE_SCOPE_SUGGESTION':
    'Si el nodo padre cambia el ámbito del rol, el ámbito del nodo hijo debe modificarse como el mismo del nodo padre.',
  'SM.AUTH.TITLE.ROLE_DISALLOWED_FUNCTION_PERMISSION': 'Permiso de función excluido',
  'SM.AUTH.LABEL.OPPAUTH_SOURCE_TYPE_INPUT': 'Fuente de permiso:',
  'SM.FEDERATION.COMMON.DELETE.FAIL': 'Error en la eliminación.',
  'SM.AUTH.TITLE.ERROR': 'Error',
  'SM.AUTH.TITLE.SELECT_OPEN_LOGIN': 'Elegir Iniciar sesión Abrir menú',
  'SM.AUTH.LABEL.OPERATOR_ACCOUNT_INPUT': 'Cuenta:',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_OPER_TYPE_LIST_INPUT': 'ID de lista de tipo de operación:',
  'SM.AUTH.LABEL.MODULE_INPUT': 'Módulo:',
  'SM.AUTH.MESSAGE.ROLE_RELATIONSHIP_EXISTS_NOT_BEINHER':
    'Los dos objetos son exclusivos y no se permite la herencia.',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_STAUTS_NORMAL': 'Normales',
  'SM.AUTH.LABEL.ROLE_AUTH_DATA': 'Permiso de datos',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_PARENT_NODE_FIELD': 'Nombre del campo PARENT_ID',
  'SM.AUTH.TITLE.ROLE_GRANTABLE_DATA_PERMISSION': 'Permiso de datos concedido',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_OPERATION': 'Nombre de operación',
  'SM.AUTH.LABEL.AUTH_CONF_DATA': 'Configuración de permisos de datos',
  'SM.AUTH.LABEL.ROLE_GENERALROLELIST': 'Lista de roles generales',
  'SM.FEDERATION.FEDERATIONAPP.PHONE': 'Número de contacto',
  'SM.AUTH.BUTTON.ADD': 'Nuevo',
  'SM.AUTH.MESSAGE.ROLETMPL_SELECT_ONE_ROLE': 'Seleccione al menos un rol.',
  'SM.AUTH.MESSAGE.TIME_LOG2': 'día (s).',
  'SM.AUTH.MESSAGE.TIME_LOG1': 'El rango de tiempo no puede exceder',
  'SM.AUTH.TITLE.USERAUTHVIEW_USERHAVAEROLES': 'Función de usuario',
  'SM.AUTH.LABEL.ROLE_GRANTED_AUTH_TYPE': 'Tipo otorgado',
  'SM.AUTH.TITLE.INPUT_MENU_NAME': 'Introducir un nombre de menú',
  'SM.AUTH.LABEL.FUNCAUTH_STATUS': 'Estado',
  'SM.AUTH.TITLE.USERAUTHVIEW_MENU_AUTHID': 'ID de permiso',
  'SM.AUTH.MESSAGE.ROLE_RELATIONSHIP_EXISTS': 'La relación mutua-exclusiva ya existe.',
  'SM.AUTH.TITLE.ROLE_OPPAUTHNAME': 'Permiso Mutuamente Exclusivo',
  'SM.AUTH.LABEL.ROLETMPL_ROLE_LIST': 'Lista de roles',
  'SM.AUTH.MESSAGE.BIZ_OBJ_POLICY_DUPLICATE': 'Existe una política con el mismo papel y alcance.',
  'SM.FEDERATION.FEDERATIONAPP.CLIENTNAME': 'Sistema de autenticación de terceros',
  'SM.AUTH.MESSAGE.ROLETMPL_CAN_NOT_DELETE':
    'No se pudo eliminar el nodo porque el nodo contiene subnodos.',
  'SM.AUTH.LABEL.ROLE_NORMALROLE_BENAME': 'Inicio del rol común BE',
  'SM.AUTH.TITLE.MENU_NAME': 'Nombre del menú',
  'SM.AUTH.TIPS.ROLETMPL_HAS_OPP_AUTH':
    'Error al guardar porque existe un permiso mutuamente exclusivo.',
  'SM.AUTH.TIPS.FUNCAUTH_NO': 'No',
  'SM.AUTH.TITLE.ROLE_LOGIN_MENU_UPON': 'Menú de visualización automática al iniciar sesión',
  'SM.AUTH.LABEL.ROLETMPL_ROLETMPL_CONFIGURATE': 'Configurar plantilla de rol',
  'SM.AUTH.LABEL.OPERATOR_ACCOUNT': 'Cuenta',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_SERVICE_INPUT': 'Nombre del servicio:',
  'SM.FEDERATION.IMPORT.FILESIZEVALIDATEFAIL': 'Seleccione un archivo de menos de 10 MB.',
  'SM.AUTH.LABEL.STAFFROLELOG_OPER_TYPE_INPUT': 'Tipo de operación:',
  'SM.AUTH.LABEL.ROLE_PARENTROLE_NAME': 'Nombre de rol general',
  'SM.AUTH.LABEL.ROLETMPL_OPERATOR': 'Operador',
  'SM.AUTH.MESSAGE.ROLE_ERROR_BEEN_EXTENDED':
    'Error al eliminar el rol porque ha sido heredado por o ha heredado otro rol.',
  'SM.AUTH.TITLE.USERAUTHVIEW_ATTR_DEPARTMENT': 'Departamento',
  'SM.AUTH.MESSAGE.ROLE_THE_ROLES_PERMISSION_OPPED':
    'Error al guardar porque el permiso de función básica tiene una relación mutuamente excluyente.',
  'SM.AUTH.LABEL.THIRD_DATASOURCE_NAME': 'Nombre de origen de datos',
  'SM.AUTH.TITLE.PERMISSION_RECOVERY': 'Reciclaje de permisos',
  'SM.AUTH.BUTTON.EXPORT_CURRENT_ROLE': 'Exportar elemento seleccionado',
  'SM.FEDERATION.COMMON.REFRESH': 'Refrescar',
  'SM.AUTH.LABEL.FUNCAUTH_ROLE_UNAVAILABLE': 'Permiso de rol no disponible',
  'SM.AUTH.TITLE.USERAUTHVIEW_AUTHVIEW': 'Vista de permisos',
  'SM.AUTH.LABEL.FUNCAUTH_AUTHID': 'ID de permiso',
  'SM.AUTH.MESSAGE.PERM_RECOVERY_SUCCESS': 'Permiso revocado correctamente.',
  'SM.AUTH.TITLE.USERAUTHVIEW_FUNCTIONLIST': 'Lista de permisos de función',
  'SM.AUTH.LABEL.ROLE_EMPLOYEE_ACCOUNT': 'Cuenta',
  'SM.AUTH.MESSAGE.ROLETMPL_INFORMATION': 'Detalles',
  'SM.AUTH.LABEL.STAFFROLELOG_OPER_TYPE': 'Tipo de operación',
  'SM.AUTH.TITLE.EMPOLYEE_ROLESCOPE': 'Empleado',
  'SM.AUTH.BUTTON.CLEAR': 'Limpiar',
  'SM.AUTH.LABEL.ROLE_CODE_INPUT': 'Código de rol:',
  'SM.AUTH.LABEL.BIZ_OBJ_FIELD_PRIVILEGE': 'Privilegio de campo',
  'SM.AUTH.LABEL.FUNCAUTH_AUTHTYPE': 'Tipo de permiso',
  'SM.FEDERATION.COMMON.DETAIL': 'Detalles',
  'SM.FEDERATION.COMMON.NEW': 'Crear',
  'SM.AUTH.LABEL.MENU_AUTHID_INPUT': 'ID de permiso:',
  'SM.AUTH.TITLE.USER_PERMISSION_COMPARE': 'Comparación de permisos de personal',
  'SM.AUTH.TIPS.DATA_AUTH_PARAM_INVALID':
    "El nombre de la tabla, el nombre de la columna solo puede contener letras, número y '_' y solo puede comenzar con letras.",
  'SM.AUTH.TITLE.ROLE_GRANTABLE_FUNCTION_PERMISSION': 'Permiso de función concesionable',
  'SM.AUTH.TITLE.ROLE_PERMISSION_COMPARE': 'Comparación de permisos de rol',
  'SM.AUTH.TITLE.ROLE_BUSINESS_ENTITY': 'SER ID',
  'SM.AUTH.LABEL.ROLE_DERIVE_PARENTROLE': 'Papel general heredado',
  'SM.AUTH.MESSAGE.ROLE_ADD_SUCCESSFUL': 'Se ha añadido correctamente.',
  'SM.AUTH.LABEL.ROLE_EMPLOYEE_NAME': 'Empleado',
  'SM.AUTH.MESSAGE.FUNCAUTH_INTERFACE': 'Interfaz',
  'SM.AUTH.BUTTON.ROLETMPL_RESET': 'Restablecer',
  'SM.AUTH.TITLE.ROLE_PERMISSION_OBJECT_TYPE': 'Tipo de objeto de permiso',
  'SM.AUTH.TITLE.INPUT_AUTH_OBJ_NAME': 'Introducir un nombre de objeto de permiso',
  'SM.FEDERATION.MAPPINGRULE.LOCAL': 'Información del usuario local',
  'SM.AUTH.LABEL.CLEAR_TIMEOUT_SELECT_MONTH_FRONT': 'Meses (30 Días) para reservar',
  'SM.AUTH.LABEL.STAFFROLELOG_ROLE_NAME': 'Nombre del rol',
  'SM.AUTH.LABEL.ROLE_ID_B_INPUT': 'ID de rol (B):',
  'SM.AUTH.LABEL.PERMISSION_CLASS': 'Clasificación de permisos',
  'SM.AUTH.BUTTON.OK': 'OK',
  'SM.AUTH.MESSAGE.ROLE_ERROR_HAS_EXCLUDED': 'Error al eliminar el rol porque excluye otro rol.',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DYNA_CONF': 'Indicador de configuración dinámica',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_PERMISSION_FUNCTION': 'Función',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DATA_FILTER_CONDITION': 'Criterios de filtro de datos',
  'SM.FEDERATION.COMMON.UPDATE.FAIL': 'Error en la modificación.',
  'SM.FEDERATION.FEDERATIONAPP.VALDATEHTTP': 'El formato de URL no es válido',
  'SM.AUTH.LABEL.ROLETMPL_START_TIME': 'Hora de inicio',
  'SM.AUTH.LABEL.DATA_DICTABLE_ID': 'Código del diccionario de datos',
  'SM.AUTH.LABEL.DATAAUTHMGR_TITLE_AUTHNAME_CONF_INPUT': 'Nombre del permiso:',
  'SM.AUTH.LABEL.ROLE_EXCLUDINGROLE': 'Excluyendo la función',
  'SM.AUTH.MESSAGE.OPERATE_SUCCESS': 'Operación realizada con éxito.',
  'SM.AUTH.LABEL.FUNCAUTH_PARENTAUTH': 'ID de permiso de padre',
  'SM.AUTH.LABEL.PERMISSION_INPUT': 'Autoridad:',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_PERMISSION_MENU': 'Menú',
  'SM.AUTH.MESSAGE.ROLETMPL_QUERY_FAILED': 'Error de búsqueda.',
  'SM.AUTH.LABEL.ROLE_NAME_INPUT': 'Nombre del rol:',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_PUT_FUNCTION': 'Permiso de función concesionable',
  'SM.AUTH.LABEL.ROLE_NORMALROLE': 'Función común',
  'SM.AUTH.OPTION.DISALLOWED_FUNCTION_PERM': 'Permiso de función excluido',
  'SM.AUTH.MESSAGE.ROLETMPL_ROLETMPL_EXISTS': 'El nombre de la plantilla de rol ya existe.',
  'SM.AUTH.MESSAGE.DATAPER_NO_PERM': 'No tiene el permiso de operación.',
  'SM.AUTH.LABEL.ROLE_ROLE_ID': 'ID de rol',
  'SM.AUTH.LABEL.OPERATION_TYPE': 'Tipo de operación',
  'SM.AUTH.MESSAGE.FUNCAUTH_UPLOADINFO': 'Seleccione Formato de archivo adjunto:',
  'SM.AUTH.LABEL.DATA': 'Permiso de datos',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DISPLAYNO_FIELD': 'Ordenar campo',
  'SM.AUTH.TITLE.ORG_SELECTION': 'Selección de Organización',
  'SM.AUTH.LABEL.ROLE': 'Papel',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_STAUTS_PAUSE': 'Suspender',
  'SM.AUTH.TITLE.USERAUTHVIEW_ROLEVIEW': 'Vista de roles',
  'SM.AUTH.LABEL.FUNCAUTH_ROLE_GRANTABLE': 'Permiso de Función Concesionable',
  'SM.AUTH.LABEL.DATAPROVIDER_TYPE_INPUT': 'Tipo de origen de valor de datos:',
  'SM.AUTH.TITLE.USERAUTHVIEW_ATTR_BE': 'BE',
  'SM.AUTH.TITLE.USERAUTHVIEW_ROLE_TYPE': 'Tipo de rol',
  'SM.FEDERATION.IMPORT.USER': 'Importar usuario federado',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DISPLAYNO_FIELD_INPUT': 'Ordenar campo:',
  'SM.AUTH.LABEL.CLEAR_TIMEOUT_SELECT_MONTH_END': 'datos del mes (un mes es de 30 días).',
  'SM.AUTH.LABEL.BIZ_OBJ_PRIVACY_LEVEL_SECRET': 'Secreto',
  'SM.AUTH.TITLE.STAFFROLELOG_LIST': 'Lista de registros de roles de empleado',
  'SM.FEDERATION.IMPORT.FAIL': 'Error',
  'SM.AUTH.MESSAGE.ROLE_ERROR_HAS_USERS':
    'No se pudo eliminar el rol porque el rol estaba enlazado a un empleado.',
  'SM.AUTH.LABEL.ROLE_PARENTROLE_BENAME': "General Role's Home BE",
  'SM.AUTH.LABEL.FUNCAUTH_STATUS_INPUT': 'Estado:',
  'SM.AUTH.LABEL.CLEAR_POPUP_TITLE': 'Eliminar datos caducados',
  'SM.AUTH.TITLE.ROLE_HORTCUT_MENU': 'Menú de acceso directo',
  'SM.AUTH.LABEL.MODULE': 'Módulo',
  'SM.AUTH.LABEL.ROLETMPL_ASSINGED_PERMISSION': 'Conceder permiso al rol',
  'SM.AUTH.TITLE.INPUT_ROLE_NAME': 'Nombre del rol de entrada',
  'SM.AUTH.LABEL.ROLE_NO_INFO': 'Gestionar roles en el árbol de roles de la izquierda.',
  'SM.AUTH.LABEL.BIZ_OBJ_LIST': 'Lista de objetos de negocio',
  'SM.AUTH.LABEL.ROLE_SUPERID': 'Id. de rol principal',
  'SM.AUTH.TITLE.ROLEAUTHVIEW_USERVIEW': 'Vista del empleado',
  'SM.AUTH.LABEL.ROLE_CONFIG_INFO': 'Configurar información de rol',
  'SM.AUTH.LABEL.STAFFROLELOG_OPER_TIME': 'Tiempo de operación',
  'SM.AUTH.LABEL.THIRD_DATASOURCE': 'Tercera fuente de datos',
  'SM.AUTH.BUTTON.ADDAUTH': 'Añadir',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_STAUTS_DISCARDED': 'Borrar',
  'SM.AUTH.TIPS.FUNCAUTH_YES': 'Sí',
  'SM.FEDERATION.COMMON.UPDATE.SUCCESS': 'La modificación se realizó correctamente.',
  'SM.AUTH.MESSAGE.FUNCAUTH_UPLOADSIZE': 'Tamaño máximo del archivo adjunto (bytes):',
  'SM.AUTH.MESSAGE.DATAPER_OBJ_TREE_ROOT': 'Árbol de objetos de permiso',
  'SM.AUTH.TITLE.ROLEAUTHVIEW_AUTHVIEW': 'Vista de permisos',
  'SM.AUTH.LABEL.BIZ_OBJ_PRIVACY_LEVEL_CONFIDENTIAL': 'Confidencialidad',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DATA_FILTER_CONDITION_INPUT':
    'Criterios de filtro de datos:',
  'SM.AUTH.LABEL.ROLEBUSINESS_ENTITY_ID': 'El rol de BE ID',
  'SM.AUTH.MESSAGE.FUNCAUTH_DISABLE': 'No disponible',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_OPER_TYPE_LIST': 'ID de lista de tipo de operación',
  'SM.AUTH.BUTTON.IMPORT_ROLE': 'Función de importación',
  'SM.FEDERATION.COMMON.RESET': 'Restablecer',
  'SM.FEDERATION.FEDERATIONAPP.MAPPINGRULE': 'Regla de asignación de usuarios federados',
  'SM.AUTH.TITLE.USERAUTHVIEW_DATA_OBJECT_TREE': 'Árbol de objetos de permiso',
  'SM.AUTH.BUTTON.ROLE_COPY_ROLE_AND_CREATE':
    'Copiar permiso de función y permiso de datos y crear rol',
  'SM.AUTH.LABEL.ROLETMPL_SEARCH_CRITERIA': 'Criterios de búsqueda',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_PARENT_NODE_FIELD_INPUT': 'Nombre del campo PARENT_ID:',
  'SM.AUTH.LABEL.ROLE_EXCLUDEDROLE': 'Rol excluido',
  'SM.AUTH.TITLE.ROLEAUTHVIEW_PARENT_ROLETYPE': 'Función general',
  'SM.FEDERATION.FEDERATIONAPP.EMAIL': 'Correo Electrónico',
  'SM.AUTH.LABEL.ROLE_COPY_MODE_INPUT': 'Modo de copia:',
  'SM.FEDERATION.COMMON.OPERATION': 'Operación',
  'SM.AUTH.LABEL.ROLEPERMISSION_LOGL_IST': 'Lista de registro de permisos de rol',
  'SM.AUTH.LABEL.FUNCAUTH_DEPENDENT': 'Dependiente de permisos',
  'SM.AUTH.TITLE.ROLE_ASSIGNMENT_TIME': 'Tiempo de asignación',
  'SM.AUTH.LABEL.STAFFROLELOG_ACCOUNT_INPUT': 'Cuenta:',
  'SM.AUTH.LABEL.FUNCAUTH_LOADMORE': 'Cargar más',
  'SM.FEDERATION.IMPORT.FILENAMEVALIDATEFAIL':
    'Nombre de archivo incorrecto. Seleccione un archivo cuyo nombre solo contenga caracteres chinos, letras, dígitos o guiones bajos (_).',
  'SM.AUTH.TITLE.ROLE_NOT_HOLD': 'Lista de información no concesionable',
  'SM.AUTH.TITLE.ROLE_EXTENDED_PROPERTY': 'Atributo extendido',
  'SM.AUTH.TITLE.USER_PERM_COMPARE': 'Comparación de permisos de usuario',
  'SM.AUTH.LABEL.BIZ_OBJ_SCOPE': 'Ámbito de aplicación',
  'SM.FEDERATION.IMPORT.DOWLOADIMPORTRESULT': 'Descargar',
  'SM.FEDERATION.IMPORT.UPLOADSELECT': 'Seleccione un archivo.',
  'SM.AUTH.TITLE.USERAUTHVIEW_OPERTYPE': 'Tipo de operación',
  'SM.AUTH.LABEL.ROLE_TMPL_LIST': 'Lista de plantillas de rol',
  'SM.AUTH.MESSAGE.DATAPER_CONFIRM_DEL': '¿Está seguro de que desea eliminar?',
  'SM.AUTH.LABEL.FUNCAUTH_AUTHNAME': 'Nombre de permiso',
  'SM.AUTH.LABEL.ROLE_COPY_TARGETROLE': 'Función de destino',
  'SM.AUTH.BUTTON.ROLE_DESELECT_ALL': 'Deseleccionar todo',
  'SM.AUTH.BUTTON.ROLETMPL_SEARCH': 'Buscar',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_TYPE_TABLE_INPUT': 'Tabla:',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_BASE_FUNCTION': 'Permiso de función básica',
  'SM.AUTH.LABEL.ROLE_STAFF_LIST': 'Lista de empleados asignados',
  'SM.AUTH.TITLE.USERAUTHVIEW_ROLE_ID': 'ID de rol',
  'SM.AUTH.MESSAGE.ROLETMPL_CANNOT_SELECT_MODULE': 'No se puede seleccionar el módulo.',
  'SM.FEDERATION.IMPORT.IPLODATIP':
    'Importando datos... Vea los detalles en el área Resultado de importación de usuarios federados.',
  'SM.AUTH.MESSAGE.ROLETMPL_TIME_CONDITION': 'La hora debe ser posterior a la hora de inicio.',
  'SM.AUTH.TITLE.ROLE_PERMISSION_OBJECT_NAME': 'Nombre de objeto de permiso',
  'SM.AUTH.TITLE.CONFIRM_PERM_RECOVERY': 'Confirmar',
  'SM.AUTH.TITLE.ROLE_SHORTCUT_MENU_TOOLBAR': 'Menú de acceso directo',
  'SM.AUTH.TITLE.USERAUTHVIEW_TITLE_AUTHNAME_CONF': 'Nombre de permiso',
  'SM.AUTH.LABEL.BIZ_OBJ_FIELD_PRIVACY': 'Nivel de privacidad de campo',
  'SM.AUTH.TITLE.SELECT_SHORT_CUT': 'Seleccionar menú de acceso directo',
  'SM.FEDERATION.FEDERATIONAPP.EDIT': 'Modificar aplicación federada',
  'SM.AUTH.LABEL.ROLE_ID_B': 'ID de rol (B)',
  'SM.AUTH.LABEL.ROLE_ID_A': 'ID de rol (A)',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_IS_BE_FILTER_INPUT': 'Filtrar por BE:',
  'SM.AUTH.TITLE.USERAUTHVIEW_LOG_OBJ_NAME': 'Nombre del objeto',
  'oms.operationparam.msg.unsafeinfo':
    'Tenga en cuenta que el protocolo de red utilizado por la dirección actual no es seguro. Si decide usarlo, pueden existir riesgos de seguridad.',
  'SM.AUTH.LABEL.ROLE_TYPE_INPUT': 'Tipo de función:',
  'SM.AUTH.LABEL.ROLE_COPY_SOURCEROLE_INPUT': 'Función de origen:',
  'SM.AUTH.MESSAGE.PASSWORD_RULE_CONFIRM_MESSAGE_41':
    'Si el intervalo de tiempo es demasiado corto, la seguridad de la contraseña se ve afectada. ¿Estás seguro de cambiarlo?',
  'SM.AUTH.LABEL.DATA_DICTABLE_ID_INPUT': 'Código del diccionario de datos:',
  'SM.FEDERATION.IMPORT.STARTTIME': 'Hora de inicio',
  'SM.AUTH.TITLE.USERAUTHVIEW_USER_INFORMATION': 'Información del empleado',
  'SM.AUTH.LABEL.FUNCAUTH_DELETE_CHECK2': 'Los roles también se borrarán:',
  'SM.AUTH.TIPS.ROLE_INPUTROLENAME': 'Introducir un nombre de rol',
  'SM.AUTH.LABEL.FUNCAUTH_DELETE_CHECK1':
    'Si elimina el permiso, la información de autorización de los empleados relacionados y lo siguiente',
  'SM.AUTH.MESSAGE.ROLETMPL_CANT_COPY_PERMISSION': 'No puede copiar cierto permiso. ¿Continuar?',
  'SM.AUTH.MESSAGE.FUNCAUTH_LANGUAGE': 'Idioma',
  'SM.AUTH.MESSAGE.PASSWORD_LENTH_LESS_THAN_8':
    'Una contraseña corta conlleva riesgos de seguridad. ¿Está seguro de que desea utilizar esta nueva contraseña?',
  'SM.FEDERATION.FEDERATIONAPP.ERROR.INVALIDFIELD': 'El campo no es válido.',
  'SM.FEDERATION.FEDERATIONAPP.ERROR.PARSEFAIL':
    'Error al analizar la información de usuario externo.',
  'SM.AUTH.LABEL.ROLE_COPY_MODE': 'Modo de copia',
  'SM.AUTH.LABEL.FUNCAUTH_ROLE_GRANTABLE_DETAIL':
    'Roles que pueden conceder el permiso de la función a otros',
  'SM.AUTH.LABEL.OPERATION_TYPE_INPUT': 'Tipo de operación:',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_TREE_INFO_CONF_INPUT': 'Configuración de árbol adicional:',
  'SM.AUTH.LABEL.ROLE_TYPE': 'Tipo de rol',
  'SM.FEDERATION.IMPORT.FILESUFFIXVALIDATEFAIL':
    'Formato de archivo incorrecto. Seleccione un archivo XLSX.',
  'SM.AUTH.LABEL.ROLETMPL_END_TIME_INPUT': 'Hora de finalización:',
  'SM.FEDERATION.FEDERATIONAPP.NAME': 'UserAccount',
  'SM.FEDERATION.COMMON.DELETE.SUCCESS': 'La eliminación se realizó correctamente.',
  'SM.AUTH.TIPS.ROLETMPL_CREATE_AND_ASSIGN': 'Crear un rol y asignar permisos',
  'SM.AUTH.MESSAGE.PASSWORD_RULE_CONFIRM_MESSAGE_39':
    'Abrir esta regla afectará la seguridad de la contraseña, por favor confirme si continúa abriendo?',
  'SM.AUTH.TITLE.INPUT_ROLE_TMPL_NAME': 'Nombre de plantilla de rol de entrada',
  'SM.AUTH.LABEL.ROLETMPL_ROLE_ASSOCIATION_LOG': 'Registro de asociación de roles',
  'SM.AUTH.TITLE.INPUT_USER_LOGINID': 'Ingresar una cuenta de usuario',
  'SM.FEDERATION.FEDERATIONAPP.ERROR.EXISTS':
    'El nombre del sistema de autenticación de terceros, la URL o el nombre de regla de asignación ya existe.',
  'SM.AUTH.LABEL.EMPLOYEEINCLUDED': 'Incluir personal de Sub-OU',
  'SM.AUTH.LABEL.FUNCAUTH_AUTHTYPE_INPUT': 'Tipo de permiso:',
  'SM.AUTH.BUTTON.SAVE': 'Guardar',
  'SM.AUTH.TITLE.PERMISSION_SELECTION': 'Seleccionar permiso',
  'SM.AUTH.LABEL.STAFFROLELOG_OPER_ACCOUNT_INPUT': 'Operador:',
  'SM.AUTH.LABEL.ROLETMPL_PERMISSION_NAME_INPUT': 'Nombre de permiso de función:',
  'SM.AUTH.LABEL.FUNCAUTH_MODULE_INPUT': 'Id. del subsistema:',
  'SM.FEDERATION.IMPORT.FAILCOUNT': 'Registros fallidos',
  'SM.AUTH.TITLE.ROLE_PERMISSION_TIPS':
    'Para realizar operaciones por lotes en el árbol de permisos, utilice menús contextuales en los nodos de permisos.',
  'SM.AUTH.LABEL.ROLE_ID_INPUT': 'ID de rol:',
  'SM.AUTH.LABEL.FUNCAUTH_BASICINFO': 'Información básica',
  'SM.AUTH.MESSAGE.ROLE_ERROR_BEED_EXCLUDED': 'Error al eliminar el rol porque excluye otro rol.',
  'SM.AUTH.TITLE.USERAUTHVIEW_ROLE_NAME': 'Nombre del rol',
  'SM.AUTH.TITLE.ROLEAUTHVIEW_DATAAUTHLIST': 'Lista de objetos de permiso',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DESC_FIELD': 'Nombre del campo Nombre',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_KEY_FIELD': 'Nombre del campo ID',
  'SM.AUTH.OPTION.ROLE_AUTH_REMOVE': 'Borrar',
  'SM.AUTH.TITLE.ROLE_PERMISSION_OBJECT_ID': 'ID de objeto de permiso',
  'SM.AUTH.LABEL.PERMISSION': 'Autorización',
  'SM.AUTH.MESSAGE.ROLE_DELETE_SUCCESSFUL': 'Se ha eliminado correctamente.',
  'SM.AUTH.LABEL.ROLE_PARENTROLE_ID': 'ID de rol general',
  'SM.AUTH.TITLE.USERAUTHVIEW_AUTH': 'Permiso de función',
  'SM.AUTH.LABEL.FUNCAUTH_ATTRIBUTE': 'Extender atributo',
  'SM.AUTH.BUTTON.USERAUTHVIEW_RESET': 'Restablecer',
  'SM.AUTH.TITLE.ASSIGN_EMPLOYEE': 'Asignar empleado',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_TYPE_TABLE': 'Cuadros',
  'SM.AUTH.MESSAGE.DATAPER_DEL_SUCCESS': 'Se ha eliminado correctamente.',
  'SM.AUTH.TITLE.USERAUTHVIEW_ACCOUNTLOGIN': 'Cuenta',
  'SM.FEDERATION.COMMON.CONFIRM': 'Confirmar',
  'SM.AUTH.TIPS.ROLETMPL_ASSIGN_PERMISSON': 'Asignar permiso',
  'SM.AUTH.TIPS.ROLETMPL_VIEW_DETAIL_PERMISSION':
    'Ver detalles sobre los permisos que no se pueden copiar en la plantilla de rol.',
  'SM.AUTH.TITLE.ROLE_BASIC_FUNCTION_PERMISSION': 'Permiso de función básica',
  'SM.AUTH.HEADVALUE.AUTHUSERVIEW_AUTH_DATA': 'Por favor elija...',
  'SM.AUTH.MESSAGE.OPERATEFAIL': 'Error al guardar.',
  'SM.FEDERATION.FEDERATIONAPP.ERROR.PARAMNULL': 'El parámetro de solicitud está vacío.',
  'SM.AUTH.LABEL.ROLETMPL_TEMPLATENAME_INPUT': 'Nombre de plantilla de rol:',
  'SM.AUTH.TITLE.CUSTOMER_ROLESCOPE_PERMISSION':
    'Cuando el ámbito de uso no es un empleado, no se pueden asignar roles a los empleados',
  'SM.FEDERATION.COMMON.SUCCESS': 'Éxito',
  'SM.AUTH.LABEL.OPPAUTH_SOURCE_TYPE': 'Origen del permiso',
  'SM.AUTH.MESSAGE.PASSWORD_RULE_CONFIRM_MESSAGE_12':
    'La desactivación de esta regla afectará a la seguridad de la contraseña. ¿Está seguro de que desea desactivarlo?',
  'SM.AUTH.MESSAGE.PASSWORD_RULE_CONFIRM_MESSAGE_10':
    'Los caracteres especiales válidos afectarán a la seguridad de la contraseña. ¿Estás seguro de que quieres usarlos?',
  'SM.AUTH.LABEL.ROLETMPL_PERMISSION_ID': 'ID de permiso de función',
  'SM.AUTH.LABEL.FUNCAUTH_ROLE_AVAILABLE_DETAIL': 'Roles que pueden usar el permiso de función',
  'SM.AUTH.TITLE.ROLE_DELEVER_DATA_PERMISSION': 'Conceder permiso de datos',
  'SM.AUTH.LABEL.ROLE_PARENTS_TITLE': 'Información de herencia de roles',
  'SM.FEDERATION.IMPORT.UNDO': 'Pendiente',
  'SM.FEDERATION.FEDERATIONAPP.ACCOUNT': 'Cuenta',
  'SM.AUTH.LABEL.ROLE_COPY_TARGETROLE_INPUT': 'Función de destino:',
  'SM.AUTH.MESSAGE.SELECTA_ROLE': 'Seleccione un rol.',
  'SM.AUTH.BUTTON.USERAUTHVIEW_SEARCH': 'Buscar',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_SERVICE': 'Nombre del servicio',
  'SM.AUTH.BUTTON.PERM_RECOVERY': 'Reciclar',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_IS_BE_FILTER': 'Filtrar por BE',
  'SM.AUTH.MESSAGE.ROLE_SURE_TO_DELETE': '¿Está seguro de que desea eliminar?',
  'SM.AUTH.LABEL.ROLE_ID_A_INPUT': 'ID de rol (A):',
  'SM.AUTH.LABEL.DATAAUTHMGR_TAB_AUTH_ROLE_VIEW': 'Roles asociados con el permiso de datos',
  'SM.AUTH.LABEL.ROLE_DERIVE_NORMALROLE': 'Función común derivada',
  'SM.AUTH.LABEL.ROLE_ASSIGN_STAFF': 'Empleado asignado',
  'SM.AUTH.TITLE.ROLE_TMPLATE_ID': 'ID de plantilla de rol',
  'SM.AUTH.LABEL.ATTR_LANGUAGE': 'Idioma',
  'SM.AUTH.BUTTON.EXPORT': 'Exportación',
  'SM.AUTH.LABEL.BIZ_OBJ_FIELD_PRIVILEGE_SETTING': 'Configuración',
  'SM.AUTH.MESSAGE.OPERATE_WITH_ROLES':
    'El permiso se crea correctamente y se asigna al operador actual y a las siguientes funciones:',
  'SM.AUTH.MESSAGE.ROLETMPL_DELETE_SUCCESS': 'Se ha eliminado correctamente.',
  'SM.AUTH.LABEL.STAFFROLELOG_ROLE_BE_ID_INPUT': 'El rol de BE:',
  'SM.AUTH.TIPS.FUNCAUTH_STOPUSE': 'Suspender',
  'SM.AUTH.MESSAGE.OPERATE_WITHOUT_ROLES':
    'El permiso se crea correctamente y se asigna al operador actual.',
  'SM.AUTH.MESSAGE.ROLE_OPP_WITH_PARENT': 'El rol seleccionado es opuesto al rol heredado:',
  'SM.AUTH.LABEL.STAFFROLELOG_ROLE_NAME_INPUT': 'Nombre del rol:',
  'SM.AUTH.TIPS.ENTER_AUTHNAME': 'Ingrese el nombre del permiso',
  'SM.AUTH.TITLE.TO_CHOOSE_ROLE_TMPL_INPUT': 'Plantilla de rol para elegir:',
  'SM.AUTH.TITLE.USERAUTHVIEW_AUTH_MODULE': 'Módulo',
  'SM.AUTH.LABEL.LOGINLOG_RESET': 'Restablecer',
  'SM.AUTH.MESSAGE.FUNCAUTH_PAUTHNAME': 'Nombre de permiso',
  'SM.AUTH.LABEL.FUNCAUTH_AUTHID_INPUT': 'ID de permiso:',
  'SM.AUTH.TITLE.ROLE_BASIC_DATA_PERMISSION': 'Permiso de datos básico',
  'SM.AUTH.MESSAGE.ROLE_CAN_NOT_ADD_ITSELF': 'No puede heredar su propio rol.',
  'SM.FEDERATION.MAPPINGRULE.NAME': 'Regla de asignación',
  'SM.AUTH.MESSAGE.SAVE_DEPENDENT_SELF': 'El permiso depende de sí mismo, esto no está permitido.',
  'SM.FEDERATION.IMPORT.STATUS': 'Estado',
  'SM.AUTH.LABEL.FUNCAUTH_ROLE_UNAVAILABLE_DETAIL':
    'Roles que no pueden usar el permiso de función',
  'SM.AUTH.LABEL.ROLETMPL_HOME_ENTITY': 'Inicio de permiso BE',
  'SM.AUTH.TITLE.USERAUTHVIEW_AUTH_DATA': 'Permiso de datos',
  'SM.AUTH.LABEL.BIZ_OBJ_FIELD_IS_WRITEABLE': 'Es escribible',
  'SM.AUTH.MESSAGE.FUNCAUTH_NORMAL': 'Normales',
  'SM.AUTH.LABEL.USERAUTHVIEW_SEARCH': 'Consultar',
  'SM.AUTH.MESSAGE.ROLE_NAME_SAME': 'El nombre del rol ya existe.',
  'SM.AUTH.LABEL.DATAPROVIDER_TYPE': 'Tipo de origen de valor de datos',
  'SM.AUTH.BUTTON.ROLE_COPY_ROLE':
    'Copiar información de rol (Solo copiar permiso de función y permiso de datos)',
  'SM.AUTH.TITLE.USERAUTHVIEW_USER_LIST': 'Lista de empleados',
  'SM.AUTH.LABEL.ROLETMPL_PERMISSION_NAME': 'Nombre de permiso de función',
  'SM.AUTH.LABEL.USER_ACCOUNTB': 'Cuenta (B)',
  'SM.AUTH.LABEL.USER_ACCOUNTA': 'Cuenta (A)',
  'SM.AUTH.LABEL.ROLE_NAME': 'Nombre del rol',
  'SM.FEDERATION.COMMON.DOWLOADTEM': 'Descargar plantilla',
  'SM.AUTH.LABEL.ROLETMPL_ROLE_NAME': 'Nombre del rol',
  'SM.AUTH.LABEL.ORGANIZATION_INPUT': 'Unidad de organización:',
  'SM.AUTH.LABEL.ROLE_SCOPE': 'Alcance del rol',
  'SM.AUTH.MESSAGE.ROLE_NOT_EXSIT': 'El rol no existe.',
  'SM.AUTH.LABEL.AUTHVIEW_ROLE_BE_ID': 'Inicio del rol BE ID',
  'SM.AUTH.MESSAGE.DATAPER_TREE_ROOT': 'Árbol de permisos de datos',
  'SM.AUTH.TITLE.SUCCESS': 'Información de contacto',
  'SM.AUTH.BUTTON.AUTH_EDIT': 'Modificar',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_OPERATION_INPUT': 'Nombre de la operación:',
  'SM.AUTH.NO.SELECT': 'No se ha seleccionado ningún permiso',
  'SM.AUTH.MESSAGE.AUTH_DATA_OBJECT_TREE': 'Árbol de objetos de permiso',
  'SM.FEDERATION.COMMON.CREATE.FAIL': 'Error en la creación.',
  'SM.FEDERATION.FEDERATIONAPP.NEW': 'Crear una aplicación federada',
  'SM.AUTH.BUTTON.ROLE_SELECT_ALL': 'Seleccionar todo',
  'SM.AUTH.LABEL.FUNCAUTH_PARENTAUTH_INPUT': 'Id. de permiso de padre:',
  'SM.AUTH.LABEL.USER_ACCOUNTB_INPUT': 'Cuenta (B):',
  'SM.FEDERATION.COMMON.QUERY.CONDITION': 'Criterios de búsqueda',
  'SM.AUTH.BUTTON.CANCEL': 'Cancelar',
  'SM.AUTH.TITLE.USERAUTHVIEW_LOG_OBJ_ID': 'ID de objeto',
  'SM.AUTH.TITLE.USERAUTHVIEW_QUERYCONDITION_TITLE': 'Criterios de búsqueda',
  'SM.AUTH.LABEL.ROLE_ID': 'ID de rol',
  'SM.AUTH.LABEL.DATAAUTHMGR_ATTRIBUTE': 'Extender atributo',
  'SM.FEDERATION.DOWNLOAD.RESULT': 'Descargar resultados de importación de usuarios federados',
  'SM.FEDERATION.FEDERATIONAPP.CLIENTURL': 'Dirección URL',
  'SM.AUTH.LABEL.ROLETMPL_END_TIME': 'Hora de finalización',
  'SM.AUTH.LABEL.STAFFROLELOG_ROLE_BE_ID': 'El rol de BE',
  'SM.AUTH.TITLE.ASSIGNED_EMPLOYEE': 'Empleado asignado',
  'SM.AUTH.LABEL.ROLETMPL_BASIC_FUNCTION_PERMISSION': 'Permiso de función básica',
  'SM.AUTH.LABEL.ROLETMPL_DESCRITPION': 'Descripcion',
  'SM.AUTH.TITLE.ADD_ROLE_FROM_ROLE_TMPL': 'Seleccionar plantilla de rol',
  'SM.AUTH.TITLE.USERAUTHVIEW_QUERY_AUTHTYPE': 'Tipo de permiso',
  'SM.AUTH.LABEL.ROLE_CODE': 'Código de rol',
  'SM.FEDERATION.IMPORT.SUCCESSCOUNT': 'Registros exitosos',
  'SM.AUTH.TIPS.ROLETMPL_WARNING': 'Advertencia',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_BASE_DAUTH': 'Permiso de datos básico',
  'SM.AUTH.TITLE.CUSTOMER_ROLESCOPE': 'Clientes',
  'SM.AUTH.LABEL.STAFFROLELOG_ROLE_ID': 'ID de rol',
  'SM.AUTH.LABEL.STAFFROLELOG_ACCOUNT': 'Cuenta',
  'SM.AUTH.LABEL.ROLE_DERIVE': 'Herencia de roles',
  'SM.AUTH.LABEL.BIZ_OBJ_FULL_NAME': 'Nombre completo del objeto',
  'SM.AUTH.LABEL.ROLE_COPY_SOURCEROLE': 'Función de origen',
  'SM.AUTH.MESSAGE.ROLE_SELECT_A_ROLE': 'Seleccione un rol.',
  'SM.AUTH.LABEL.DETAIL_INFO':
    'Seleccione un nodo de permiso en el árbol de navegación de la izquierda para ver la información sobre el permiso.',
  'SM.FEDERATION.FEDERATIONAPP.ERROR.ISUSED':
    'Los datos no pueden ser eliminados o modificados porque han sido utilizados por un tenant.',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_TREE_INFO_CONF': 'Configuración de árbol adicional',
  'SM.AUTH.LABEL.FUNCAUTH_ISDY': 'Indicador de configuración dinámica',
  'SM.AUTH.LABEL.ROLETMPL_TEMPLATENAME': 'Nombre de plantilla de rol',
  'SM.AUTH.BUTTON.CONFIRM_DELETE': 'Confirmar Borrar',
  'SM.AUTH.TITLE.USERAUTHVIEW_AUTHVIEW_DATAAUTHLIST': 'Lista de objetos de permiso',
  'SM.AUTH.TITLE.TO_CHOOSE_ROLE_TMPL': 'Seleccionar plantilla de rol',
  'SM.AUTH.LABEL.CLEAR_CONFIRM_CLEAR_DATA':
    '¿Está seguro de que desea eliminar los permisos no utilizados recientemente?',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_ID_TREE_INPUT': 'Estructura del árbol:',
  'SM.AUTH.LABEL.BIZ_OBJ_PRIVACY_LEVEL_PUBLIC': 'Publicado',
  'SM.AUTH.LABEL.ROLE_AUTH_DATA_INPUT': 'Permiso de datos:',
  'SM.AUTH.LABEL.PERMISSION_CLASS_INPUT': 'Clasificación de permisos:',
  'SM.FEDERATION.FEDERATIONAPP.REMOTETIP':
    'El valor es el campo de asignación correspondiente al usuario externo.',
  'SM.FEDERATION.MAPPINGRULE.DESC': 'Descripción',
  'SM.AUTH.LABEL.ROLE_SUPERCODE_INPUT': 'Código de rol principal:',
  'SM.FEDERATION.IMPORT.RESULT': 'Resultado de importación de usuario federado',
  'SM.AUTH.TITLE.ROLEAUTHVIEW_COMMON_ROLETYPE': 'Función común',
  'SM.AUTH.LABEL.ROLE_SUPERCODE': 'Código de rol principal',
  'SM.AUTH.LABEL.ROLETMPL_ROLE_ID': 'ID de rol',
  'SM.AUTH.LABEL.CONFIRM_DELETE_DEPEND_AUTH': 'Confirmar para eliminar el permiso de dependencia?',
  'SM.AUTH.MESSAGE.ROLETMPL_SHOWNAME': 'Plantilla de rol',
  'SM.AUTH.TITLE.ROLE_MUTUALLY_EXCLUSIVE_PERMISSION': 'Lista de permisos mutuamente exclusivos',
  'SM.AUTH.LABEL.COMPARE_RESULT': 'Resultado de la comparación',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_CODE_INPUT': 'Código de permiso:',
  'SM.FEDERATION.FEDERATIONAPP.DELETETIP': '¿Está seguro de que desea eliminar los datos?',
  'SM.AUTH.TITLE.USERAUTHVIEW_AUTH_STATUS': 'Estado',
  'SM.AUTH.TITLE.BELONG_ROLE_TMPL': 'Plantilla de rol de propietario',
  'SM.AUTH.LABEL.FUNCAUTH_ROLE_AVAILABLE': 'Permiso de rol disponible',
  'SM.AUTH.LABEL.USER_ACCOUNTA_INPUT': 'Cuenta (A):',
  'SM.FEDERATION.COMMON.FAIL': 'Error',
  'SM.FEDERATION.IMPORT.IMPORTRESULT': 'Resultado de importación',
  'SM.AUTH.OPTION.ROLE_REMOVE_INFO_OF_TARGET_ROLE': 'Eliminar información del rol de destino',
  'SM.FEDERATION.IMPORT.UPLOADFILE': 'Archivo',
  'SM.AUTH.TITLE.USERAUTHVIEW_AUTHSOURCE': 'Origen del permiso',
  'SM.AUTH.TITLE.AUTHNAMEINFO': 'Información de nombre de permiso',
  'SM.AUTH.LABEL.LOGINLOG_SEARCH': 'Buscar',
  'SM.AUTH.TIPS.ROLE_PARENTROLE': 'Nombre del rol',
  'SM.AUTH.LABEL.ROLE_DESC': 'Descripcion',
  'SM.AUTH.LABEL.FUNCAUTH_OPPAUTHNAME': 'Permiso Mutuamente Exclusivo',
  'SM.FEDERATION.IMPORT.ENDTIME': 'Hora de finalización',
  'SM.FEDERATION.COMMON.OPERATION.DELETE': 'Borrar',
  'SM.FEDERATION.FEDERATIONAPP.LIST': 'Aplicaciones Federadas',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_PUT_DAUTH': 'Permiso de datos concedido',
  'SM.AUTH.LABEL.ROLE_NORMALROLE_ID': 'Código de rol común',
  'SM.AUTH.TITLE.ROLE_TMPL_ROLE_LIST': 'Lista de roles',
  'SM.FEDERATION.COMMON.OPERATION.EDIT': 'Editar',
  'SM.AUTH.LABEL.ROLE_ROLE_NAME': 'Nombre del rol',
  'SM.AUTH.MESSAGE.ROLETMPL_SELECT_NO_ENTITY': 'Seleccione al menos un rol o BE.',
  'SM.AUTH.BUTTON.CLEAR_ALL_TIPS': 'Borrar permiso no disponible',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_CODE': 'ID de permiso',
  'SM.AUTH.MESSAGE.OK': 'OK',
  'SM.AUTH.TITLE.CONFIRM_DEL': 'Confirmar',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_OPP_FUNCTION': 'Permiso de función excluido',
  'SM.AUTH.LABEL.FUNCAUTH_MODULE': 'Id. del subsistema',
  'SM.AUTH.LABEL.ROLE_INFO': 'Información de rol',
  'SM.AUTH.LABEL.DATAAUTHMGR_TITLE_AUTHNAME_CONF': 'Nombre de permiso',
  'SM.AUTH.MESSAGE.ROLETMPL_SELECT_NODE_INFO':
    'Seleccione un nodo padre en el árbol de navegación de la izquierda y administre plantillas de rol en el árbol de plantillas de rol.',
  'SM.AUTH.LABEL.MENU_AUTHID': 'ID de permiso',
  'SM.AUTH.TITLE.INFO': 'Información de contacto',
  'SM.AUTH.MESSAGE.FUNCAUTH_READONLY': 'No asignable',
  'SM.AUTH.LABEL.ROLETMPL_START_TIME_INPUT': 'Hora de inicio:',
  'SM.FEDERATION.IMPORT.FAILFILE': 'Archivos fallidos',
  'SM.AUTH.LABEL.FUNCAUTH_DESC_INPUT': 'Descripción:',
  'SM.AUTH.LABEL.BIZ_OBJ_NAME': 'Nombre',
  'SM.AUTH.LABEL.ORGANIZATION': 'Unidad de Organización',
  'SM.AUTH.BUTTON.DELETE': 'Borrar',
  'SM.AUTH.BUTTON.CLOSE': 'Cerrar',
  'SM.AUTH.MESSAGE.PERM_RECOVERY':
    "Si hace clic en 'Aceptar', se revocan los permisos de todas las funciones siguientes. Si hace clic en 'Cancelar', no se realizará ninguna operación.",
  'SM.AUTH.MESSAGE.ROLE_RELATIONSHIP_EXISTS_NOT_BEEXCLUDING':
    'La relación de herencia ya existe y el rol no se puede excluir.',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_KEY_FIELD_INPUT': 'Nombre del campo ID:',
  'SM.FEDERATION.IMPORT.FAILREASON': 'Causa de falla',
  'SM.AUTH.LABEL.FUNCAUTH_UPLOAD': 'Cargar archivo adjunto',
  'SM.AUTH.LABEL.BIZ_OBJ_FIELD': 'Campo',
  'SM.AUTH.BUTTON.ROLE_DELETE_FOREVER': 'Eliminar permanentemente',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DYNA_CONF_INPUT': 'Indicador de configuración dinámica:',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DESC_INPUT': 'Descripción:',
  'SM.FEDERATION.COMMON.CREATE.SUCCESS': 'Creación exitosa.',
  'SM.AUTH.LABEL.BE_BASIC_INFORMATION': 'Información básica',
  'SM.AUTH.TITLE.USERAUTHVIEW_NAME': 'Empleado',
  'SM.AUTH.MESSAGE.SAVE_SUCCESS': 'Operación realizada con éxito.',
  'SM.AUTH.LABEL.ROLE_DESC_INPUT': 'Descripción:',
  'SM.AUTH.TITLE.USERAUTHVIEW_STAFF_BE_NAME': 'Hogar del empleado BE',
  'SM.AUTH.LABEL.ROLETMPL_ROLETMPL_INFO': 'Información de plantilla de rol',
  'SM.AUTH.TITLE.TO_ASSIGNED_EMPLOYEE': 'Asignar empleado',
  'SM.AUTH.LABEL.ROLE_NORMALROLE_NAME': 'Nombre de rol común',
  'SM.AUTH.OPTION.GEANTABLE_FUNCTION_PERM': 'Permiso de función concesionable',
  'SM.AUTH.LABEL.BIZ_OBJ_ACCESS_RULE': 'Regla de acceso',
  'SM.AUTH.LABEL.ROLETMPL_DESCRITPION_INPUT': 'Descripción:',
  'SM.AUTH.TITLE.USERAUTHVIEW_ROLE_PARENTS_TITLE': 'Información de herencia de roles',
  'SM.AUTH.TIPS.ENTER_ORGNAME': 'Ingrese el nombre de la organización',
  'SM.FEDERATION.MAPPINGRULE.REMOTE': 'Información del usuario externo',
  'SM.AUTH.MESSAGE.FUNCAUTH_FUNCTION': 'Función',
  'SM.FEDERATION.COMMON.QUERY': 'Buscar',
  'SM.AUTH.LABEL.ROLETMPL_FUNCTION_PERMISSION': ' Permiso de función',
  'SM.AUTH.LABEL.STAFFROLELOG_OPER_ACCOUNT': 'Operador',
  'SM.AUTH.MESSAGE.ROLE_ERROR_SUBROLES': 'El rol no se puede eliminar porque tiene subroles.',
  'SM.AUTH.LABEL.CLEAR_TIMEOUT_SUCCESS_MSG':
    'Permisos eliminados que no están disponibles durante el tiempo correctamente.',
  'SM.AUTH.TITLE.USERAUTHVIEW_USER_LOGINID': 'Cuenta',
  'SM.AUTH.LABEL.AUTHVIEW_ROLE_BE_NAME': 'Inicio del rol BE',
  'SM.AUTH.LABEL.BIZ_OBJ_IS_OWNER': 'Es Propietario',
  'SM.AUTH.OPTION.ROLE_RETAIN_INFO_OF_TARGET_ROLE': 'Conservar la información del rol de destino',
  'SM.AUTH.LABEL.BIZ_OBJ_ACCESS_RULE_ROLE_POLICY': 'Política de acceso a funciones',
  'SM.AUTH.LABEL.PERMRECOVERY_ROLE_NAME': 'Rol con permiso',
  'SM.AUTH.LABEL.FUNCAUTH_DESC': 'Descripcion',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DESC': 'Descripcion',
  'SM.AUTH.LABEL.DETAIL': 'Detalles',
  'SM.AUTH.TITLE.INPUT_AUTHID_AUTHNAME': 'Ingrese un ID de permiso o un nombre',
  'SM.AUTH.LABEL.TITLE_CLEAR_SUCCESS': 'Iniciar sesión',
  'SM.AUTH.LABEL.STAFFROLELOG_ROLE_ID_INPUT': 'ID de rol:',
  'SM.AUTH.LABEL.FUNCAUTH_AUTHNAME_INPUT': 'Nombre del permiso:',
  'SM.AUTH.LABEL.ROLE_OPERTYPE': 'Tipo de operación',
  'SM.AUTH.LABEL.ROLETMPL_ROLE_NAME_INPUT': 'Nombre del rol:',
  'SM.AUTH.TITLE.USERAUTHVIEW_DETAIL_INFO': 'Seleccione un nodo de permiso con una URL.',
  'SM.FEDERATION.FEDERATIONAPP.VALDATEJSON':
    'El elemento de configuración debe estar en formato JSON.',
  'SM.AUTH.LABEL.FUNCAUTH_INFO': 'Información básica',
  'SM.AUTH.TITLE.INPUT_PERMISSION_NAME': 'Introducir un nombre de permiso',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_ID_TREE': 'Estructura del árbol',
  'SM.AUTH.MESSAGE.DELETE_CONFIRM': '¿Está seguro de que desea eliminar?',
  'SM.AUTH.TITLE.THIRD_DATASOURCE':
    'Operación realizada con éxito (El tercer origen de datos está cerrado, ya que el origen de datos está vacío.).',
  'SM.AUTH.TITLE.ADD_ROLE_FROM_AUTH_TREE': 'Seleccionar permiso de función',
  'SM.FEDERATION.COMMON.CANEL': 'Cancelar',
  'SM.FEDERATION.COMMON.SUBMIT': 'Enviar',
  'SM.AUTH.OPTION.ROLE_AUTH_ADD': 'Añadir',
  'SM.AUTH.MESSAGE.ROLE_INHERITANCE_RELATIONSHIP_EXISTS': 'La herencia ya existe.',
  'SM.AUTH.LABEL.ASSIGNEMPLOYEE': 'Asignación de empleados',
  'SM.AUTH.LABEL.ASSIGNRIGHTS': 'Permisos asignables',
  'SM.AUTH.LABEL.OWNEDRIGHTS': 'Permisos propios',
  'SM.ORGSTAFF.LABEL.ALLORG': 'Todas las organizaciones',
  'SM.FEDERATION.USER.TEMPLATE': 'Descargar plantilla de importación de usuarios federados',
  'common.baseui.MESSAGE.VALIDATE_MAXLENGTHB': 'Se permite un máximo de 0 caracteres.',
  'SM.GADGET.VALUE.MENUPERM': 'Permisos de menú',
  'SM.GADGET.VALUE.FUNCPERM': 'Permisos de operación',
  'SM.GADGET.VALUE.INTFPERM': 'Permisos de API',
  'SM.ROLE.PARENT.ROLE': 'Rol de nivel superior',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_BATCH_SET_ROLE': 'Adición de roles en lotes',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_BATCH_DELETE_ROLE': 'Eliminación de roles en lotes',
  'SM.USERPWDGROUP.RANGE.VALIDATE': 'Rango %-%',
  'SM.ROLE.SELECT.BATCH': 'Veuillez sélectionner un rôle',
  'SM.ROLE.SELECT.ERRORMSG': 'Descripción de la excepción：',
  'SM.ROLE.SELECT.ERRORCAUSE': 'Causa de excepción：',
  'SM.ROLE.SELECT.ERRORSLOUTION': 'Sugerencias de manejo：',
  'SM.ROLE.MESSAGE.DELETE_CONFIRM':
    'Después de eliminar un rol, sólo se puede crear de nuevo. ¿Desea eliminarlo?',
  'SM.ORG.DISABLE.FAILED.PARENT':
    'Deshabilitar todas las organizaciones hijo de la organización antes de deshabilitar la organización actual.',
  'SM.ORG.DISABLE.FAILED.EMPLOYEE':
    'Elimine todos los empleados de la organización y, a continuación, deshabilite la organización.',
  'SM.EMPLOYEE.SELECTED.EMPLOYEE': 'Empleados seleccionados',
  'SM.EMPLOYEE.ALLOCATE.WORKNO': 'Asignar ID de empleado',
  'SM.EMPLOYEE.SELECTED.WORKNO.NOT.ENOUGH':
    'El número de ID de agente que se pueden asignar es insuficiente para el tipo de agente seleccionado',
  'SM.EMPLOYEE.CHECKED-IN.PASSWORD': 'Contraseña de inicio de sesión',
  'SM.EMPLOYEE.SOFTCONSOLE.GROUP': 'Grupo de tráfico doméstico',
  'SM.EMPLOYEE.INSPECTION.GROUP': 'Equipo de inspección de calidad',
  'SM.EMPLOYEE.PLATFORM.CONFIG': 'Configuración de la plataforma',
  'SM.EMPLOYEE.CONFIG.EMPLOYEE': 'Configuración de Empleados',
  'SM.EMPLOYEE.CANNOT.CHOOSE.WORKNO':
    'No se puede seleccionar un empleado al que se ha asignado un ID de empleado',
  'SM.EMPLOYEE.OPERATE.MAX.HUNDRED': 'Operar hasta 100 empleados a la vez',
  'SM.EMPLOYEE.SKILL.INFO': 'Información de habilidades',
  'SM.EMPLOYEE.FEATURE.CONFLICT':
    'El reconocimiento inteligente y la grabación de doble pista no se pueden habilitar al mismo tiempo. Después de habilitar el reconocimiento inteligente, se anula la selección del otro',
  'SM.EMPLOYEE.BATCH.CONFIG': 'Configuración por lotes',
  'SM.TENANT.LABEL': 'Inquilino',
  'SM.TENANT.INFO': 'Información del inquilino',
  'SM.TENANT.VIEW': 'Explorar inquilinos',
  'SM.TENANT.EDIT': 'Editar inquilino',
  'SM.TENANT.CREATE': 'Creación de un inquilino',
  'SM.TENANT.TENANTSPACENAME': 'Nombre del entorno del inquilino',
  'SM.TENANT.TENANTNAME': 'Nombre del inquilino',
  'SM.TENANT.ADMINACCOUNT': 'Cuenta de administrador',
  'SM.TENANT.PHONE': 'Número de teléfono',
  'SM.TENANT.CREATETIME': 'Tiempo de creación',
  'SM.TENANT.DELETE_CONFIRM':
    'El inquilino no se puede restaurar después de ser eliminado. ¿Está seguro de que desea eliminar el inquilino',
  'SM.PWDRULE.PLEASE.INPUT': 'Introduzca un nombre de grupo de reglas',
  'SM.EMPLOYEE.CANNOT.CHOOSE.STATUS':
    'No se puede seleccionar un empleado en el estado deshabilitado',
  'SM.EMPLOYEE.CANCEL.CONFIG.CONFIRM': '¿Está seguro de que desea cancelar la configuración',
  'SM.EMPLOYEE.ROLE.EFFECT':
    'La información del rol del empleado entrará en vigor [] minutos más tarde.',
  'SM.ROLE.AUTH.EFFECT': 'La información de permiso del rol entrará en vigor [] minutos más tarde.',
  'SM.ROLE.TEMPLATE': 'Plantilla de rol',
  'SM.LABEL.APPCUBE.SWITCH': 'Página AppCube-Integrada',
  'SM.LABEL.APPCUBE.APP': 'Seleccionar aplicación',
  'SM.LABEL.APPCUBE.MENU': 'Seleccionar menú',
  'SM.MENU.NAME.SPECIAL.CHAR':
    "No se pueden introducir caracteres especiales: !#{'$'}%&()￥+:\"{'{'}{'}'},\\/'[]=-^<>",
  'SM.COMMON.MESSAGE.DUTY.FORBIDDEN':
    'Los empleados seleccionados contienen empleados que se han deshabilitado.',
  'SM.LABEL.ACCOUNT': 'Introduzca una cuenta',
  'SM.LABEL.USERNAME': 'Introduzca su nombre de usuario',
  'SM.LABEL.EMAIL': 'Introduzca una dirección de correo electrónico',
  'SM.LABEL.PHONE': 'Introduzca el número de teléfono de contacto',
  'SM.LABEL.PASSWORD': 'Introduzca la contraseña',
  'SM.LABEL.DESCRIPTION': 'Introduzca una descripción',
  'SM.ORGSTAFF.BUTTON.SaveAndConfigure': 'Guardar y configurar',
  'SM.ROLE.BUTTON.DELETE': '¿Está seguro de que desea eliminar el empleado seleccionado?',
  'SM.ROLE.ADD': 'Nuevo rol',
  'SM.ROLE.ADD.NAME': 'Introduzca un nombre de rol',
  'SM.ROLE.ADD.DECRI': 'Introduzca comentarios',
  'sumweb.export.confirm.exportall': 'No ha seleccionado ningún registro. ¿Exportar todos los registros? También puede hacer clic en un registro específico para exportar.',
  'sumweb.export.export.select': 'Ha seleccionado el registro {0}. ¿Estás seguro de que quieres continuar.',
  'sumweb.export.org': 'Exportación de información de organización',
  'sumweb.export.employee': 'Exportación de información de empleados',
  'sumweb.view.import.list': 'Lista de importación',
  "sumweb.import.success": "Se ha creado una tarea de importación. ¿Está seguro de que desea ver la lista de importación?",
  "SM.ROLE.EDIT": "Editar rol",
  "SM.AUTH.BUTTON.CONFIRM.CLOSE": "Confirmar Cerrar",
  "sumweb.agent.config": "Configurar Asientos",
  "sumweb.employee.export.nodata": "Ningún dato de los empleados cumple con los requisitos.",
  "sumweb.employee.view.config": "Ver Configuración"
}
