<template>
    <sweet-dialog v-model="isShow" :title="$t('ccnotification.notifition.sendmessage.recipientGroup')" width="616px" class="aicc-dialog-limit-height"  :close-on-click-modal="false"  @close="onDialogClose">
    <aicc-table row-key="groupId" @handlePagination="handlePagination" :tableData="tableData" :tableColumns="tableColumns" :tableTools="{ columnConfig: false, showPagination: true }" :paginationConfig="paginationConfig" @select="handleSelect" @select-all="handleSelectAll" ref="refAiccTable" height="430" />

    <template #footer>
      <span class="dialog-footer">
        <sweet-button @click="close">{{ $t("message.template.message.cancel") }}</sweet-button>
        <sweet-button type="primary" @click="confirmSelect">{{ $t("ccnotification.common.button.finish") }}</sweet-button>
      </span>
    </template>
  </sweet-dialog>
</template>

<script>
import { queryUserGroup } from '../../../api/index'
import cookie from "@/utils/cookie"

export default {
  data() {
    return {
      isShow: true,
      offset: 0,
      limit: 10,
      tableData: [],
      selectedData: [],
      tableColumns: [
        {
          type: 'selection',
          'reserve-selection': true,
          width: '50px'
        },
        {
          label: $t('SM.USERGROUP.LABEL.NAME'),
          prop: 'groupName'
        },
        {
          label: $t('SM.ORGSTAFF.LABEL.TEAM_DESCRIPTION'),
          prop: 'description'
        }
      ],
      paginationConfig: {
        pageSizes: [10, 20, 50, 100],
        pageSize: 10,
        total: 0,
        layout: "sizes, total, prev, pager, next"
      },
      tenantId: cookie.getCookie('tenant_space_id')
    }
  },
  methods: {
    onDialogClose() {
      this.$emit('onClose')
    },
    confirmSelect() {
      if (this.selectedData) {
        this.$emit('groupSelected', this.selectedData)
        this.close()
      }
    },
    close() {
      this.isShow = false
    },
    handleSelect(selection, row) {
      let item = this.selectedData.find(item => item.groupId == row.groupId)
      if (item) {
        this.selectedData = this.selectedData.filter(item => item.groupId != row.groupId)
      } else {
        this.selectedData.push(row)
      }
    },
    handleSelectAll(selection) {
      //判断当前操作是选择当前页还是取消选择
      let isSelect = selection.some(item => item.groupId == this.tableData[0].groupId)
      if (isSelect) {
        let selectedIds = this.selectedData.map(item => item.groupId)
        // 之前没有选择的行，添加到已选择
        this.tableData.filter(item => {
          return undefined == selectedIds.find(id => id == item.groupId)
        }).forEach(item => {
          this.selectedData.push(item)
        })
      } else {
        let cancelIds = this.tableData.map(item => item.groupId)
        // 表格页取消全选时，已选择数据去掉当前展示的数据
        this.selectedData = this.selectedData.filter(item => {
          return undefined == cancelIds.find(id => id == item.groupId)
        })
      }
    },
    toggleSelectedRow() {
      this.tableData.forEach(item => {
        for (let i = 0; i < this.selectedData.length; i++) {
          if (this.selectedData[i].groupId == item.groupId) {
            this.$refs.refAiccTable.table.toggleRowSelection(item, true)
          }
        }
      })
    },
    searchGroups() {
      queryUserGroup(this.tenantId, {limit: this.limit, offset: this.offset}).then(res => {
        if (res) {
          this.tableData = res.groups
          this.paginationConfig.total = res.total
          this.$nextTick(() => {
            this.toggleSelectedRow()
          })
        }
      })
    },
    handlePagination(val) {
      this.limit = val.limit
      this.offset = val.offset
      this.searchGroups()
    },
    initData() {
      if (this.propSelectedData) {
        this.selectedData = this.propSelectedData.map(group => {
          return {
            groupId: group.groupId,
            groupName: group.groupName,
            description: group.description
          }
        })
      }
      this.offset = 0
      this.searchGroups()
    }
  },
  mounted() {
    this.initData()
  },
  props: {
    propSelectedData: Array
  }
}
</script>