import request from '@/utils/request'

/**
 * 查询租户下业务结果策略（外呼任务编辑/详情）
 *
 * @param data 入参
 * @return result
 */
export const queryResultCodeAndNameOfTenant = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/businessResult/queryResultCodeAndNameOfTenant',
        method: 'post',
        data
    })
}

/**
 * 查询外呼数据属性列表
 *
 * @param data 入参
 * @return result
 */
export const queryDataPropList = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/dataProp/queryDataPropList',
        method: 'post',
        data
    })
}

/**
 * 查询技能队列
 *
 * @param data 入参
 * @return result
 */
export const querySkill = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/skill/query',
        method: 'post',
        data
    })
}

/**
 * 查询座席
 *
 * @param data 入参
 * @return result
 */
export const queryAgentAndSkill = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/skill/queryAgentAndSkill',
        method: 'post',
        data
    })
}

/**
 * 查询主叫号码
 *
 * @param data 入参
 * @return result
 */
export const queryCallerNo = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/callerNo/queryCallerNo',
        method: 'post',
        data
    })
}

/**
 * 新增手动外呼任务
 *
 * @param data 入参
 * @return result
 */
export const createAgentTask = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/task/createAgentTask',
        method: 'post',
        data
    })
}

/**
 * 修改手动外呼任务
 *
 * @param data 入参
 * @return result
 */
export const updateAgentTask = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/task/updateAgentTask',
        method: 'post',
        data
    })
}

/**
 * 创建人工外呼模版
 *
 * @param data 入参
 * @return result
 */
export const createManualOutboundCallTemplate = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/outboundcalltemplate/createManualOutboundCallTemplate',
        method: 'post',
        data
    })
}

/**
 * 修改人工外呼模版
 *
 * @param data 入参
 * @return result
 */
export const updateManualOutboundCallTemplate = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/outboundcalltemplate/updateManualOutboundCallTemplate',
        method: 'post',
        data
    })
}