export default {
  'aicc.loading': '加载中',
  'aicc.select': '请选择',
  'aicc.numberOnly': '只能由数字组成',
  'aicc.unorganized': '无组织机构',
  'aicc.organized': '有组织机构',
  'aicc.columnConfig': '列配置',
  'aicc.selectAll': '全部列',
  'aicc.selectedColumns': '已选列',
  'aicc.selectedColumnsTip': '（可拖动进行排列）',
  'aicc.columnSettings': '表头设置',
  'aicc.selectedInfo': '已选择 {n} 条',
  'aicc.advancedSearch': '高级搜索',
  'aicc.lengthLimit': '长度不能超过{limit}个字符',
  'aicc.ok': '确定',
  'aicc.error': '错误',
  'aicc.search': '搜索',
  'aicc.reset': '重置',
  'aicc.skip': '跳过',
  'aicc.goto': '前往查看',
  'aicc.more': '更多',
  'aicc.intRangeLimit': '请输入{min}~{max}之间的整数',
  'aicc.MaxLength': '字符串长度超出取值范围，长度必须小于等于0',
  'aicc.previous': '上一条',
  'aicc.next': '下一条',
  'aicc.noData': '暂无数据',
  'aicc.lengthMinLimit': '长度不能小于{0}个字符',
  'aicc.traceId.error': 'TraceId:0,请将TraceId信息反馈给运维人员进行定位',
  'aicc.apibuilder.dev': '接口开发',
  'aicc.tenantSpace.management': '租户管理',
  'aicc.timeout.desc':'会话过期',
  'aicc.timeout.solution':'你已登录超时，需要重新登录。',
  'aicc.input': '请输入',
}
