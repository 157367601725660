import { defineStore } from "pinia";
// postMessage 兼容写法
export const useCcmePostMessage = defineStore('ccmePostMessage', {
    state: () => {
        return {
            postMessageData: {}
        }
    },
    actions: {
        changePostMessageData(info) {
            this.postMessageData = Object.assign({}, info)
        },
    }
})