<template>
  <div class="iolpNodeWrap" @click="clickElement">
    <!--图元信息-->
    <div class="aicc-align--absolute-left nodeInfo">
      <img class="nodeIcon" :src="ic_logic_condition" v-if="nodeData.nodeType == 'decision'"/>
      <img class="nodeIcon" :src="ic_function" v-if="nodeData.nodeType != 'decision'"/>
      <div class="nodeNameClass">{{ nodeData.pText }}</div>
    </div>
  </div>
</template>

<script>
import ic_logic_condition from "@/views/ccmanagement/assets/image/diagram-icon/ic_condition.png";
import ic_function from "@/views/ccmanagement/assets/image/diagram-icon/ic_function@3x.png";

export default {
  name: "TaskElement",
  inject: ['getNode', 'getGraphAPI'],
  data() {
    return {
      ic_logic_condition,
      ic_function,
      nodeData: {}
    }
  },
  mounted() {
    const node = this.getNode();
    this.nodeData = node.data;
  },
  methods: {
    clickElement() {
      this.showNodeForm();
    },
    showNodeForm() {
      const node = this.getNode();
      const graphAPI = this.getGraphAPI();
      graphAPI.showNodeForm({
        shape: 'taskElement',
        nodeId: node.id,
      });
    },
  }
}
</script>

<style scoped>
.iolpNodeWrap {
  min-width: 120px;
  max-width: 300px;
  max-height: 450px;
  width: max-content;
  padding: 10px;
  background: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0 3px 12px rgba(0, 98, 213, 0.15);
  word-break: break-word;
}

.nodeIcon {
  width: var(--20px-to-rem);
  height: var(--20px-to-rem);
  margin-inline-end: 8px;
}

.nodeNameClass {
  font-weight: bold;
  color: #1c1c1c;
  font-size: var(--12px-to-rem);
}

.x6-node-selected .iolpNodeWrap {
  outline: 1px solid #366bfc;
}
</style>
