<template>
  <aicc-table
    :tableData="fileList"
    :tableColumns="tableColumns"
    :tableTools="{ showPagination: false, columnConfig: false }"
    @radio-change="handleSelect"
    ref='selectFileRef'>
  </aicc-table>
  <sweet-form inline>
    <sweet-form-item>
      <sweet-pagination
        v-model:current-page="currPage"
        :page-size="10"
        layout="total, prev, pager, next"
        :total="total"
        @current-change="handleCurrentChange"
      />
    </sweet-form-item>
  </sweet-form>
</template>
<script lang="ts">
import {
  defineComponent
} from "vue";
import { queryMultiMediaLibraryListByTypeCommon } from '@/views/ccmanagement/ccma-api/otherProjectsRequest.js';

export default defineComponent({
  props: {
    mediaType: {
      type: String,
      required: true
    },
    selectedFileId: {
      type: String
    }
  },
  data() {
    return {
      templateName: '',
      currPage: 1,  // 当前页，默认为第一页
      total: 0, // 记录总条数
      fileList: new Array(),
      queryParam: {
        limit: 10,
        offset: 0
      },
      selectedTemplate: '',
      orgTreeList: [],
      tableColumns: [
        {
          type: 'radio',
          radioKey: 'id', // 单选绑定的字段名
          width: '50px',
          'show-overflow-tooltip': false
        },
        {
          label: this.$t('ccmessaging.chat.chatcard.multimediaid'),
          prop: 'id',
        },
        {
          label: this.$t('ccmessaging.chat.chatcard.multimedianame'),
          prop: 'name',
        }
      ],
      mediaTypeMap: {
        0: '',
        1: 'IMAGE',
        2: 'VIDEO',
        3: 'LOCATE'
      },
      mediaTypeIds: ['0', '1', '2', '3']
    };
  },
  methods: {
    init() {
      let mediaType = this.$props.mediaType;
      let mediaTypeStr = this.mediaTypeIds.indexOf(mediaType) != -1 ? this.mediaTypeMap[mediaType] : 'IMAGE';
      this.queryParam.mediaType = mediaTypeStr;
      this.queryMediaFile(this.queryParam)
    },
    queryMediaFile (param) {
      queryMultiMediaLibraryListByTypeCommon(param).then(result => {
        if (!result) {
          return;
        }
        this.fileList = result["resultList"] || [];
        let item = this.fileList.find(item => item.id == this.$props.selectedFileId);
        if (item) {
          this.$refs.selectFileRef.setRadioCurrentValue(item.id);
          this.$emit('selectFile', item);
        }
        this.total = result["total"];
      });
    },
    handleCurrentChange(currentPage: number) {
      this.queryParam.offset = (currentPage - 1) * 10;
      this.currPage = currentPage;
      this.queryMediaFile(this.queryParam);
    },
    handleSelect() {
      let file = this.$refs.selectFileRef.getRadioCurrentRow();
      this.$emit('selectFile', file);
    }
  },
  created() {
    this.init();
  }
})
</script>
<style scoped lang="less">
.data_nav {
  margin-top: 10px;
}
</style>