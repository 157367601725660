<template>
  <sweet-dialog
    v-model="dialogVisible"
    :title="dialogTitle"
    width="616"
    class="aicc-dialog-limit-height"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <sweet-row>
      <sweet-col class="button-area">
        <sweet-button class="aicc-button-icon" @click="handleRefresh">
          <aicc-tooltip
            :open-delay="800"
            :content="$t('ccmessaging.chat.chatconfig.refresh')"
            placement="top"
          >
            <img
              class="sweetui-icon-download-l refresh-icon-img"
              src="@/assets/img/search/search_refresh.png"
            />
          </aicc-tooltip>
        </sweet-button>
      </sweet-col>
    </sweet-row>
    <aicc-table
      class="aicc-table contact-export-view-table"
      :tableData="tableDataChild.tasks"
      :tableColumns="tableColumns"
      :tableTools="{ showPagination: true, columnConfig: false }"
      :paginationConfig="paginationConfig"
      @handlePagination="handlePagination"
      ref="refAiccTable"
      height="430"
    >
      <template #status="scope">
        <sweet-button
          text
          @click="download(scope.row.id)"
          v-if="scope.row.status == 2"
          type="primary"
          >{{ $t('ccm.custdata.operlog.download') }}</sweet-button
        >
      </template>
    </aicc-table>
  </sweet-dialog>
</template>
<script>
import { getLanguage } from '@/lang'

export default {
  name: 'ExportViewPop',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    dialogTitle: {
      type: String,
      default: ''
    },
    tableData: {
      type: Object,
      default: []
    }
  },
  watch: {
    tableData: {
      handler(newValue) {
        if (!newValue || !newValue.data) return
        this.tableDataChild = JSON.parse(JSON.stringify(newValue.data))
        if (this.tableDataChild && this.tableDataChild.tasks?.length == 0) {
          this.tableDataChild.tasks = []
          return
        }
        this.tableDataHandle()
      },

      immediate: true, // 强制立即执行回调
      deep: true //深度监听
    }
  },
  data() {
    return {
      statusMap: {
        0: this.$t('ccm.custdata.operlog.exportiong'),
        1: this.$t('ccm.custdata.operlog.failed'),
        2: this.$t('ccm.custdata.operlog.success'),
      },
      tableColumns: [
        {
          label: this.$t('ccm.export.table.taskTime'),
          prop: 'beginTime',
          display: true,
          configFixed: true,
          description: '',
          width: '192px'
        },
        {
          label: this.$t('ccm.export.table.taskStatus'),
          prop: 'resultText',
          display: true,
          configFixed: true,
          description: '',
          width: '192px'
        },
        {
          label: this.$t('ccm.custdata.operlog.operation'),
          prop: 'status',
          display: true,
          configFixed: true,
          description: '',
          slot: 'status',
          width: '192px'
        }
      ],
      tableDataChild: {},
      paginationConfig: {
        pageSizes: [10, 20, 50, 100], // 每页条数列表
        pageSize: 10, // 每页条数
        total: 0, //总条数
        currentPage: 1,
        layout: 'sizes, total, prev, pager, next' // 分页组件布局配置
      },
      isInit: true
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      }
    }
  },

  methods: {
    tableDataHandle() {
      if (this.isInit) {
        this.paginationConfig.total = this.tableDataChild['total'] || 0
      }
      if (!this.tableDataChild.tasks) {
        return
      }

      for (let i = 0; i < this.tableDataChild.tasks.length; i++) {
        this.tableDataChild.tasks[i]['resultText'] =
          this.statusMap[this.tableDataChild.tasks[i]['status']]
        if (this.tableDataChild.tasks[i]['beginTime']) {
          this.tableDataChild.tasks[i]['beginTime'] = this.$TimeUtils.transTime(
            this.tableDataChild.tasks[i]['beginTime']
          )
        } else {
          this.tableDataChild.tasks[i]['beginTime'] = '-'
        }
      }
    },

    handlePagination(param) {
      this.isInit = false
      this.$emit('handlePagination', param)
    },

    initData(param) {
      this.$emit('handlePagination', param)
    },

    handleRefresh() {
      this.$refs.refAiccTable.setCurrentPage(1)
      this.isInit = true
      this.initData({ limit: 10, offset: 0 })
    },

    download(id) {
      this.$emit('download', id)
    }
  },
  mounted() {
    this.isInit = true
    this.initData({ limit: 10, offset: 0 })
  }
}
</script>

<style lang="less" scoped>
.button-area {
  display: flex;
  flex-direction: column;
}
.aicc-button-icon {
  position: relative;
  align-self: flex-end;

  img {
    width: 16px;
    position: relative;
    top: 1px;
  }
}

.contact-export-view-table {
  margin-top: 10px;
}

div button {
  height: 32px;
}
</style>

