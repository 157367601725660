<template>
    <sweet-dialog class="rich-Text-dialog_preview rich-text-page" :title="$t('ccmessaging.chat.multimedia.preview')" draggable
        v-model="dialogVisible" width="1248px" @closed="handleClosed" :append-to-body="true" :close-on-click-modal="false">
        <div class="preview-main">
            <div class="rich-text-info">
                <div class="rich-text-info_title">
                    {{ previewContent.name }}
                </div>
                <div class="rich-text-info_description">
                    {{ previewContent.description }}
                </div>
            </div>
            <div class="rich-text-info_content" v-html="previewContent.richTextContent"></div>
        </div>
    </sweet-dialog>
</template>

<script setup>
import { reactive, ref, computed, inject, onMounted, getCurrentInstance, nextTick, watch, toRefs } from "vue";
const { appContext: { config: { globalProperties } } } = getCurrentInstance();
import {
    queryMultimediaInfoById,
} from '@ccme/api/multimedia-library/index.js'
import {getRichTextWhiteList, tipHandler} from "@ccme/utils/index.js"

// xss校验器
const filterXssFunc = window['filterXSS']
const richTextWhiteList = getRichTextWhiteList()

const dialogVisible = ref(false)
const previewContent = reactive({
    name: '',
    description: '',
    richTextContent: ''
})

// 查询富文本内容
const getRichTextContent = (id) => {
    queryMultimediaInfoById({ id }).then(result => {
        if (Array.isArray(result) && result.length) {
            const { name, description, content } = result[0]
            previewContent.name = name
            previewContent.description = description
            // 兼容处理，富文本编辑器中图片url地址需要兼容
            // TODO 是否需要后端接口去修改
            const compatibleAfterContent = content.replaceAll('<img src="../../../u-route', '<img src="' + location.protocol + '//' + location.host + '/service-cloud/u-route')
            previewContent.richTextContent = filterXssFunc(compatibleAfterContent, { // 自定义js-xss的options
              whiteList: richTextWhiteList, // 设置标签白名单
              stripIgnoreTag: true, // 去除不在白名单中的标签
              stripIgnoreTagBody: ["script"], // 去除不在白名单标签的标签体里的内容
            })
            // 设置行高
            previewContent.richTextContent = previewContent.richTextContent.replaceAll('<p style="','<p style="margin-block-start: 1em;margin-block-end: 1em;line-height: initial;');
            previewContent.richTextContent = previewContent.richTextContent.replaceAll('<p>','<p style="margin-block-start: 1em;margin-block-end: 1em;line-height: initial;">');
        } else {
            tipHandler(globalProperties.$t('ccmessaging.chat.multimedia.getRichTextInfoError'), 'error')
        }
    })
}

const handleOpen = (id) => {
    if (!id) {
        return
    }
    getRichTextContent(id)
    dialogVisible.value = true
}

const handleClosed = () => {
    Object.keys(previewContent).forEach(item => {
        previewContent[item] = ''
    })
}

defineExpose({ handleOpen })
</script>

<style lang="less" scoped>
.preview-main {
    width: 100%;
    height: 50vh;
    overflow: auto;

    .rich-text-info {
        width: 100%;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(238, 238, 238, 1);

        &_title {
            font-size: var(--swt-font-size-normal1);
            color: #1C1C1C;
            font-weight: 600;
            line-height: 2;
        }

        &_description {
            font-size: var(--swt-font-size-normal);
            color: #333333;
            font-weight: 400;
        }

        &_content {
            padding: 10px;
        }
    }
}
</style>