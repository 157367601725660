<template>
  <sweet-dialog
      :title="$t('oifde.ivrJourney.chooseAccessCode')"
      :close-on-click-modal="false"
      width="616px"
      class="aicc-dialog-limit-height"
      @close="closeDialog"
      v-model="accessCodeDialogVisible">
    <aicc-advanced-search ref='advancedSearch'
                          :templateMetaData="chooseAccessCodeMetaData"
                          @handleSearch='handleSearch'
                          @handleCommand="handleCommand"></aicc-advanced-search>

    <aicc-table
        :tableData="accessCodeTableData"
        :tableColumns="accessCodeColumns"
        :tableTools="{ showPagination: true, columnConfig: false}"
        :paginationConfig="paginationConfig"
        :row-key="getKey"
        :height="430"
        @handlePagination="handlePagination"
        @selection-change="handleSelection"
        ref='refAccessCodeTable'>
      <template #robotsSource="scope">
        <span v-if="scope.row.source === 'OIFDE'">{{ $t('oifde.route.RobotManage') }}</span>
        <span v-if="scope.row.source === 'IVR'">{{ $t('oifde.route.keyPressRobot') }}</span>
      </template>
    </aicc-table>

    <template #footer>
      <span class="dialog-footer">
        <sweet-button @click="closeDialog()">{{ $t('oifde.table.cancel') }}</sweet-button>
        <sweet-button type="primary" @click="checkAccessCode()">{{ $t('oifde.table.confirm') }}</sweet-button>
      </span>
    </template>
  </sweet-dialog>
</template>

<script>

import {queryAccessCodeList} from "@oifde/request/api/sysmgr";
import {ElMessage} from "element-plus";

export default {
  name: 'accessCodeDialog',
  props: {
    show: {
      type: Boolean, //是否展示 true or false
      default: false
    },
    source: { //机器人来源
      type: String,
      default: 'ALL'
    },
    isRadio: {  // 是否单选框 true-单选 false-复选 默认复选
      type: Boolean,
      default: false
    },
    cancelable: {  // 回显选中的数据是否能取消勾选 默认可以
      type: Boolean,
      default: true
    },
    acceessData: { // 回显选中用的，accessId的数组
      type: Array,
      default: () => { return [] }
    },
    maxLength: { // 最大选中数量，默认10个
      type: Number,
      default: 10
    },
  },
  computed: {
    accessCodeDialogVisible: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      }
    },
    accessCodeColumns() {
      return this.isRadio ? this.accessCodeColumnsRadio : this.accessCodeColumnsSelection;
    },
  },
  created() {
    this.accessCodeTableData = [];
    this.getAccessCodeList();
  },
  data() {
    return {
      selectedRow: null, // 记录所有的选中数据，用来判断是否超过最大条数，提示限制信息
      selecteable: true,
      accessCodeTableData: [],
      accessCodeColumnsRadio: [
        {
          type: 'radio',
          radioKey: 'accessId',
          width: '60'
        },
        {
          label: this.$t('oifde.accesscode.robotName'),
          prop: 'chatBotName',
        },
        {
          label: this.$t('oifde.flow.exportFlowList.accessCode'),
          prop: 'accessCode',
          width: '200px'
        },
        {
          label: this.$t('oifde.trackmgr.robotsSource'),
          slot: 'robotsSource',
          width: '150px'
        }
      ],
      accessCodeColumnsSelection: [
        {
          type: 'selection',
          'reserve-selection':true,
          width: '50px',
          selectable: this.checkSelected,
        },
        {
          label: this.$t('oifde.accesscode.robotName'),
          prop: 'chatBotName',
        },
        {
          label: this.$t('oifde.flow.exportFlowList.accessCode'),
          prop: 'accessCode',
          width: '200px'
        },
        {
          label: this.$t('oifde.trackmgr.robotsSource'),
          slot: 'robotsSource',
          width: '150px'
        }
      ],
      paginationConfig: {
        pageSize: 10, // 每页条数
        total: 0, //总条数
        pageNum: 1,
        layout: "sizes, total, prev, pager, next" // 分页组件布局配置
      },
      chooseAccessCodeMetaData: {
        basicConfig: { // 基础搜索配置
          basicSearch: [
            {
              searchType: 'primary', // primary: 有搜索图标的输入框；select: 下拉框; input: 搜索框；date: 日期选择器
              modelValue: 'keyword', // v-model绑定的key值
              placeholderValue: this.$t('oifde.accesscode.accessCodeSearch'), // placeholder绑定的值 国际化之后的值
              changeEvent: 'handleSearch',
              colConfig: { lg: 12, xl: 12 },
            }
          ]
        },
      },
    }
  },
  methods: {
    /**
     * 回车搜索
     * @param params
     */
    handleSearch({params}) {
      // 点击搜索回到第一页
      this.paginationConfig.pageNum = 1;
      this.$refs.refAccessCodeTable.setCurrentPage(1)
      this.getAccessCodeList(params)
    },
    handleCommand(data) { // 触发事件处理，调用对应方法，方法和eventName同名
      const { eventName, params } = data
      this[eventName](params)
    },
    getAccessCodeList(params) {
      const queryParams = {
        pageNum: this.paginationConfig.pageNum,
        pageSize: this.paginationConfig.pageSize,
        source: this.source,
      };
      if (params && params.keyword) {
        queryParams.keyword = params.keyword;
      }
      queryAccessCodeList(queryParams).then(async resp => {
        this.accessCodeTableData = resp.accessInfoList;
        this.paginationConfig.total = resp.total;
        this.initSelected();
      })
    },
    closeDialog(){
      this.accessCodeDialogVisible = false;
      this.$emit('accessCodeDialogClose');
    },
    handlePagination(data) {
      Object.assign(this.paginationConfig, data)
      let params = this.$refs.advancedSearch.getFormatParams();
      this.getAccessCodeList(params);
    },

    checkAccessCode() {
      if (this.isRadio) {
        // 单选直接返回选中数据
        let selectedRow = this.$refs.refAccessCodeTable.getRadioCurrentRow();
        this.accessCodeDialogVisible = false;
        this.$emit('accessCodeDialogConfirm', selectedRow);
      } else {
        // 多选需要校验是否超出最大长度
        if (this.selectedRow.size > this.maxLength) {
          ElMessage({
            type: 'error',
            duration: 10000,
            showClose: true,
            offset: 56,
            message: this.$t('oifde.accessCode.select.max', [this.selectedRow.size, this.maxLength])
          })
          return;
        }
        this.accessCodeDialogVisible = false;
        // 新勾选的数据, 用来传值回显
        this.$emit('accessCodeDialogConfirm', this.$refs.refAccessCodeTable.table.getSelectionRows());
      }
    },
    getKey(row){
      return row.accessId;
    },
    initSelected() {
      if (!this.isRadio) {
        this.accessCodeTableData.forEach(row => {
          if (this.acceessData.some(item => row.accessId === item)) {
            this.$nextTick(() => {
              this.$refs.refAccessCodeTable.table.toggleRowSelection(row, true);
            })
          }
        })
      }
    },
    handleSelection(selection) {
      if(this.isRadio) {
        return;
      }
      // 不考虑外面已选数据在弹窗中勾选的情况，只计算新增的
      this.selectedRow = new Set(this.acceessData);
      for(let item of selection) {
        this.selectedRow.add(item.accessId);
      }
    },
    checkSelected(row) {
      // 可操作勾选的情况：未满maxLength个 || 新勾选的
      return this.cancelable || this.acceessData.indexOf(row.accessId) === -1
    },
  },
}
</script>