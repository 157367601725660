export default {
  mainTitle: 'Customer Service Cloud',
  subTitle: 'Clear UI for Ultimate Service',
  account: 'Compte',
  password: 'Mot de passe',
  signIn: 'Sign In',
  configCenter: 'Centre de configuration',
  configFirstAgent: 'Configuration de l\'agent',
  ConfigureFirstBot: 'Configuration du robot',
  howToUseTheCallCenter: "Guide de l'utilisateur",
  welcomeBack: 'Bon retour !',
  totalNumberOfCalls: "Appels au total",
  numberOfCallLosses: "Appels abandonnés",
  totalNumberOfPeopleInLine: 'Appels en file d\'attente',
  helpCenter: "Centre d'aide",
  knowledgeCenter: 'Centre de connaissances',
  forum: 'Forum',
  virtualCallCenterTrendsOfTheDay: "Tendances du jour des centres d’appels virtuels",
  quickEntry: 'Accès rapide',
  myToDo: 'Liste des tâches',
  notificationCenter: 'Centre de notifications',
  more: 'Plus',
  addStaff: 'Ajouter du personnel',
  setSkillQueue: "Définir la file d'attente des compétences",
  agentManagement: 'Gestion des agents',
  calledSettings: 'Paramètres appelés',
  workbenchConfiguration: "Configuration de l'établi",
  outboundTask: 'Tâche sortante',
  workOrder: 'Ordre de travail',
  message: 'Message',
  name: 'Nom',
  taskProgress: 'Progrès des tâches',
  province: 'Province',
  urbanArea: 'Zone urbaine',
  address: 'Adresse',
  postCode: 'Code postal',
  homePage: "Page d'accueil",
  caseHome: 'Accueil',
  robotManagement: 'Gestion des robots',
  mailMessageManagement: 'Gestion des messages mails',
  changePassword: 'Modifier le mot de passe',
  resetSkillQueue: "Réinitialiser la file d'attente des compétences",
  signOut: 'Se déconnecter',
  changePasswordSuccess:
    'Le nouveau mot de passe a été défini avec succès. Veuillez vous souvenir de votre nouveau mot de passe. La page reviendra dans 3 secondes.',
  passwordInvalid:
    'Le mot de passe actuel ne répond pas aux règles de mot de passe. Veuillez le réinitialiser selon les règles.',
  passwordIsAboutToExpire: "Le mot de passe est sur le point d'expirer. Changez le mot de passe.",
  passwordForceChange:
    'Le mot de passe actuel est le mot de passe initial. Pour des raisons de sécurité, changez le mot de passe immédiatement.',
  passwordFirstChange:
    'Le mot de passe actuel est le mot de passe initial. Pour assurer la sécurité, changez le mot de passe immédiatement.',
  passwordExpireTitle: "Notification d'expiration du mot de passe",
  passwordExpireMessage:
    "Le mot de passe que vous avez défini est sur le point d'expirer. Vous ne pouvez pas vous connecter au système après l'expiration du mot de passe. Changez le mot de passe maintenant.",
  passwordFirstChangeTitle: 'Rappel initial du mot de passe',
  passwordFirstChangeMessage:
    "Le mot de passe actuel est le mot de passe initial, qui n'est pas sécurisé. Voulez-vous changer le mot de passe maintenant?",
  systemError: 'Erreur système',
  continueLogin: 'Continuer la connexion',
  verifyCode: 'Code de vérification',
  pageInvalid: "La page n'est pas valide. Veuillez recharger la page.",
  confirm: 'Confirmer',
  verifyCodeInvalid: "Le code de vérification n'est pas valide.",
  passwordHasExpried: 'Le mot de passe a expiré',
  passWordRuleLength: 'Le mot de passe contient [1] à [2] caractères',
  passWordRuleMinLength: 'Le mot de passe doit contenir au moins [1] caractères',
  passWordRuleMaxLength: 'Le mot de passe peut contenir un maximum de [1] caractères',
  passwordRuleForbidContinueCharacter:
    'Le nombre de caractères répétés consécutifs ne peut dépasser [1]',
  passwordRuleForbidName: 'Le nom ne peut pas être contenu',
  passwordRuleForbidAccount: 'Le compte ne peut pas être en ordre positif ou négatif',
  digit: 'Nombre',
  letter: 'Lettre',
  uppercaseLetters: 'Lettres majuscules',
  lowercaseLetters: 'Lettres minuscules',
  specialCharacters: 'Caractère spécial',
  necessaryContains: 'Obligatoire',
  specialCharacterCandidates: 'Candidat de caractère spécial :',
  descriptionOfInvalidandUnqualifiedPasswords:
    'Description de mot de passe non valide et non qualifiée :',
  error: 'Erreur',
  copyrightInfomation:
    'Copyright © [1] Huawei Software Technologies Co., Ltd. Tous les droits sont r\u00E9serv\u00E9s.',
  accountEmpty: 'Entrez un compte.',
  oldPasswordEmpty: 'Saisissez un mot de passe.',
  newPasswordEmpty: 'Saisissez un nouveau mot de passe.',
  passwordNotSame: 'Les mots de passe sont incohérents.',
  verifyCodeEmpty: 'Entrez un code de vérification.',
  oldPassword: 'Ancien mot de passe',
  newPassword: 'Nouveau mot de passe',
  confirmPassword: 'Confirmer le mot de passe',
  ok: 'OK',
  cancel: 'Annuler',
  LogoutMessage: 'Êtes-vous sûr de vouloir sortir ?',
  keywordFilter: 'Saisissez un mot-clé',
  noOrganization: 'Organisation non affiliée',
  createCaseOrder: 'Créer un cas',
  configMenuSearchPlaceholder: 'Mots-clés du menu de recherche',
  changePasswordSuccessAndLogout: 'Mot de passe changé avec succès! Le système est sur le point de quitter automatiquement!',
  accountLocked: 'L\'utilisateur a été verrouillé parce que le nombre d\'échecs de changement de mot de passe dépasse le seuil. La page de connexion sera affichée dans 3 secondes.',
  'queueNumbers': 'Nombre actuel de files d\'attente :',
  'callsToday': 'Nombre d\'appels aujourd\'hui :',
  'averageCallTime': 'Temps de conversation moyen :',
  'entryIsEmpty': 'Le menu de raccourcis actuel est vide. Veuillez le définir.',
  'goToSettings': 'Aller aux paramètres',
  'settingShortcutEntry': 'Configuration d\'une entrée rapide',
  'frontDeskWork': 'Travail à la réception',
  'selectedShortcutMenu': 'Menu de raccourcis sélectionné',
  'pendingMessages': 'Message en attente ({n})',
  'messageManagement': 'Gestion des messages',
  'messageTime': 'Heure du message',
  'useCallFunction': 'Comment utiliser la fonction d\'appel',
  'performingOutboundCallTask': 'Exécution d\'une tâche d\'appel sortant',
  'notificationDetails': 'Détails de la notification',
  'sender': 'De l\'expéditeur',
  'receiveGroup': 'Groupe de réception',
  'bulletinType': 'Type de bulletin',
  'internalMessage': 'Message interne',
  'announcements': 'Annonce',
  'systemBulletin': 'Annonce du système',
  'internalBulletin': 'Annonce interne',
  'sendNotification': 'Envoyer une notification',
  'notificationReply': 'Réponse de notification',
  'notificationDeleteConfirm': 'Voulez -vous vraiment supprimer cette notification ?',
  'alarmnote':'Notes d\'inspection de la qualité',
  'alarmmessage':'Informations d\'avertissement',
  'alarmmessagedetail':'Vous avez {0} nouvelles alarmes QC à afficher. Cliquez pour voir les détails',
  'schedule': 'WFM',
  'privacystatement':'Declaração de Privacidade da AICC',
  'id':'ID',
  'decs':'DESCRIPTION',
  'cause':'RAISONS',
  'action':'Action de réparation',
  personalCenter:"Centre personnel",
  'noagent':"Ce compte n'est pas lié à un agent. Vous pouvez contacter l'administrateur pour le configurer",
  'nickName':"Pseudo de session",
  'socailChatNum':"Nombre de clients servis simultanément",
  'avatar':"Avatar de conversation",
  'confirmClose':"Confirmer Fermer",
  'importListView': "Affichage des tâches d'importation",
  'listView': "Affichage des tâches",
  'minorityLanguageTips': 'Désolé, le système WFM ne prend en charge que le chinois et l\'anglais. Veuillez passer à une autre langue.',
  'minorityLanguageTipsAgent': 'Désolé, le système WFM ne prend pas en charge le portugais et le thaï. Veuillez passer à une autre langue.',
  "sureCloseCurrentPage": "Êtes-vous sûr de vouloir fermer la page ?",
}
