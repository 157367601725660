<template>
    <div>
        <sweet-dialog v-model="dialogVisible" :title="$t('isales.management.title.selectResult')"
                      :close-on-click-modal="false" width="616px" class="aicc-dialog-limit-height">
            <aicc-advanced-search :templateMetaData="templateMetaData"
                                  @handleReset="searchPageFirst"
                                  @handleSearch="searchPageFirst"
                                  @searchReasonClear="searchPageFirst"
                                  ref='advancedSearch'>
            </aicc-advanced-search>
            <!-- 任务列表 -->
            <aicc-table ref="aiccTable"
                        :tableData="showReasonCodeList"
                        :tableColumns="tableColumns"
                        :tableTools="{ showPagination: true, checkedInfo: true, columnConfig: false }"
                        :paginationConfig="paginationConfig"
                        :row-key="getKey"
                        @handlePagination="handlePagination"
                        :height="430">
                <!--模版名称-->
                <template #failCode="scope">
                    <div style="cursor: pointer">
                        {{ scope.row.value }}
                    </div>
                </template>
                <template #description="scope">
                    <div style="cursor: pointer">
                        {{ scope.row.label }}
                    </div>
                </template>
            </aicc-table>
            <template #footer>
                  <span class="dialog-footer">
                    <sweet-button @click="dialogVisible = false">{{ $t('isales.common.title.cancel') }}</sweet-button>
                    <sweet-button type="primary" @click="confirm">{{ $t('isales.common.title.confirm') }}</sweet-button>
                  </span>
            </template>
        </sweet-dialog>
    </div>
</template>

<script>
    import {ALL_REASON_CODE_LIST} from "@/views/isales/views/outboundresult/enum";
    import {queryObResultMapFailCode} from "@/views/isales/api/outboundResultManage";
    import {deepClone} from "@vitest/utils";

    export default {
        name: "outboundResultMapAdd",
        props: {
            show: {
                type: Boolean,
                default: false
            },
            selectedAttributes: {
                type: Array,
                default() {
                    return []
                }
            }
        },
        computed: {
            dialogVisible: {
                get() {
                    return this.show
                },
                set(val) {
                    this.$emit('update:show', val)
                }
            },
        },
        created() {
            this.initData();
        },
        data() {
            return {
                allReasonCodeList: ALL_REASON_CODE_LIST,
                showReasonCodeList: [],
                templateMetaData: {
                    basicConfig: { // 基础搜索配置
                        iconButton: [{buttonType: 'refresh'}], // 图标按钮
                        colConfig:{
                            lg:2, xl:2
                        },
                        iconAreaColConfig:{lg:1, xl:1},
                        basicSearch: [
                            {
                                searchType: 'primary', // primary: 有搜索图标的输入框；select: 下拉框; input: 搜索框；date: 日期选择器
                                modelValue: 'searchReason', // v-model绑定的key值
                                modelDefault: '', // v-model绑定的默认值
                                placeholderValue: this.$t('isales.result.search.displayResultOrCode'), // placeholder绑定的值 国际化之后的值
                                rules: [
                                    {
                                        maxLength: 100,
                                        validator: this.$Validate.maxLengthValidator,
                                        trigger: ['change', 'blur']
                                    },
                                    {validator: this.$Validate.checkSpecialChar, trigger: ['change', 'blur']},
                                ],
                                clearEvent: 'searchReasonClear',
                                colConfig: {
                                    lg: 20,
                                    xl: 20
                                },
                            }
                        ]
                    },

                },
                tableColumns: [
                    {
                        type: 'selection', // 多选
                        fixed: true,
                        width: '50px',
                        selectable: this.selectable,
                        'reserve-selection': true
                    },
                    {
                        label: this.$t('isales.result.outgoingCall.failCode'), // 模版名称
                        slot: 'failCode',
                        'min-width': '50px'
                    },
                    {
                        label: this.$t('isales.result.outgoingCall.Description'), // 任务类型
                        slot: 'description',
                        'min-width': '250px'
                    }
                ],
                paginationConfig: {
                    offset: 0,
                    pageSize:10,
                    limit: 10, // 每页条数
                    total: 0, //总条数
                    layout: "total, prev, pager, next"
                },
            }
        },
        methods: {
            handleRefresh() { // 刷新时重置回第一页，同时会触发handlePagination
                this.$refs.aiccTable.handleInitChange()
            },
            async initData() {
                //初始化模版列表
                // 过滤有效枚举
                let params = {
                    "defId": this.defId
                }
                let result = await queryObResultMapFailCode(params);
                if (result && result.resultCode == "0202000") {
                    for (let tempFailCode of result.failCodes || []) {
                        this.allReasonCodeList = this.allReasonCodeList.filter(temp => temp.value != tempFailCode);
                    }
                } else {
                    this.$message({
                        type: 'error',
                        duration: 10000,
                        offset: 56,
                        'show-close': true,
                        message: result.resultDesc
                    })
                }
                await this.getList();

            },
          searchPageFirst({params}){
            this.paginationConfig.offset = 0;
            this.$refs.aiccTable.setCurrentPage(1);
            this.handleSearch({params});
          },
          async handleSearch({params}) {
            await this.getList(params)
          },
            async getList(params) {
                const {offset, limit} = this.paginationConfig
                let tempReasonList = [];
                if (params && params.searchReason) {
                    tempReasonList = deepClone(this.allReasonCodeList.filter(temp => temp.label.indexOf(params.searchReason) >= 0 || temp.value == params.searchReason));
                } else {
                    tempReasonList = deepClone(this.allReasonCodeList);
                }
                if (tempReasonList.length > 0) {
                    this.paginationConfig.total = tempReasonList.length;
                    this.showReasonCodeList = await tempReasonList.splice(offset, limit);
                } else {
                    this.paginationConfig.total = 0;
                    this.showReasonCodeList = [];
                }
            },
            handleCommand(data) { // 触发事件处理，调用对应方法，方法和eventName同名
                const {eventName, params} = data
                this[eventName](params)
            },
            async handlePagination(params) {
                Object.assign(this.paginationConfig, params)
                params.searchReason = this.$refs.advancedSearch.getFormatParams().searchReason
                await this.handleSearch({params})
            },
            getKey(row) {
                return row.value
            },
            confirm() {
                const selectionRows = this.$refs.aiccTable.table.getSelectionRows()
                if (!selectionRows || selectionRows.length <= 0) {
                    this.$message({
                        type: 'error',
                        duration: 10000,
                        offset: 56,
                        'show-close': true,
                        message: this.$t('isales.file.message.rule.dataIsNull')
                    });
                    return;
                }
                if (selectionRows && selectionRows.length > 50) {
                    this.$message({
                        type: 'error',
                        duration: 10000,
                        offset: 56,
                        'show-close': true,
                        message: this.$t('isales.result.addResultMaptips')
                    })
                }
                this.$emit('confirm', selectionRows);
                this.dialogVisible = false
            },
            selectable(row) {
                return !this.selectedAttributes.includes(row.value)
            },
        }
    }
</script>

<style scoped>

</style>