<template>
  <div id="isales-manual-task">
    <aicc-full-screen v-if="active === 0" ref="fullScreen" :existConfig="['backHeader', 'footerAction']">
      <template #main>
        <div style="padding: 20px 16px;">
          <!--任务信息-->
          <FormManualTaskInfo ref="taskInfoForm"
                              :form="form"
                              :type="$route.query.type"
                              @templateSelectConfirm="templateSelectConfirm"></FormManualTaskInfo>
        </div>
      </template>
      <template #footer>
        <sweet-button v-show="active === 0 && !isView" @click="cancel">{{ $t('isales.button.cancel') }}</sweet-button>
        <sweet-tooltip :content="$t('isales.tasktemplate.title.saveAsTemplate')" placement="top" effect="light">
        <sweet-button v-show="active === 0 && !isBatchEdit && !isTemplate && !isView" @click="saveAsTemplate">{{ this.$t('isales.tasktemplate.title.saveAsTemplate') }}</sweet-button>
        </sweet-tooltip>
        <sweet-button v-show="isTemplate && !isView" type="primary" @click="saveAsTemplate">{{ $t('isales.common.title.save') }}</sweet-button>
        <sweet-button v-show="active === 0 && isBatchEdit"
                      type="primary"
                      @click="batchEditTask">{{ $t('isales.common.title.save') }}</sweet-button>
        <sweet-button v-show="active === 0 && (isCreate || isEdit) && !isTemplate"
                      :type="isCreate ? '' : 'primary'"
                      @click="save">{{ $t('isales.common.title.save') }}</sweet-button>

        <sweet-tooltip :content="$t('isales.taskmanagement.title.saveAndImportData')" placement="top" effect="light">
        <sweet-button v-show="active === 0 && isCreate && !isTemplate" type="primary" @click="saveAndImportData">{{ this.$t('isales.taskmanagement.title.saveAndImportData') }}</sweet-button>
        </sweet-tooltip>
      </template>
    </aicc-full-screen>
    <!--外呼数据-->
    <OutboundCallData v-if="active === 1" ref="callData" :task-info="form" :taskId="form.taskId"></OutboundCallData>
  </div>
</template>

<script>
import FormManualTaskInfo from '@/views/isales/views/manualCallTask/FormManualTaskInfo.vue'
import OutboundCallData from '@/views/isales/components/OutboundCallData/OutboundCallData.vue'
import { batchUpdateAgentTask, queryTaskByCondition } from '@/views/isales/api/taskManagement'
import { RETURN_CODE, TASK_TYPE } from '@/views/isales/views/taskManagement/enum'
import {
  createAgentTask,
  updateAgentTask,
  createManualOutboundCallTemplate,
  updateManualOutboundCallTemplate
} from '@/views/isales/api/manualCallTask'
import {querySurveyListAndCount} from '@/views/isales/api/systemAutoCallTask'
import {useIsalesTaskDetailStore, useIsalesTaskManagementStore} from '@/views/isales/stores/taskManagement'
import {ElMessage, ElMessageBox} from "element-plus";
import { useBaseStore } from '@/views/base/stores'
export default {
  name: 'isalesManualCallTask',
  setup() {
    const taskManagementStore = useIsalesTaskManagementStore()
    const baseStore = useBaseStore()
    return {
      taskManagementStore,
      baseStore
    }
  },
  components: {
    FormManualTaskInfo,
    OutboundCallData,
  },
  data() {
    return {
      TASK_TYPE,
      active: null,
      stepsList: [
        { title: this.$t('isales.step.taskinfo') },
        { title: this.$t('isales.step.outboundcalldata') }
      ],
      form: {
        taskId: '',
        taskName: '',
        taskType: '',
        callerNo: '',
        queryCallerNos: '',
        vdnId: '',
        skillId: '',
        ivrFlowCode: '',
        beginDate: '',
        endDate: '',
        status: '',
        lockFlag: '',
        tenantId: '',
        skillName: '',
        smsTemplateId: '',
        smsTemplateName: '',
        callTimes: null,
        priority: '',
        dataMode: '',
        description: '',
        surveyId : '',
        surveyName : '',
        scenarioType : '',
        flowName : '',
        ivrAccessCode : '',
        versionNo: '',
        pickUp: {
          isAutoPickUp: false,
          busiResult: '',
          primaryCondition: [],
          subConditions: [],
          operator: 'and',
          conditions:[],
          callCompletedNum: null,
          pickUpNum: null,
          quotas: null
        },
        agents: []
      },
      isLoading: false,
      queryType:'',
      queryMode:''
    }
  },
  computed: {
    isView() {
      return this.queryType === 'view'
    },
    isEdit() {
      return this.queryType === 'edit'
    },
    isCreate() {
      return this.queryType === 'create'
    },
    isBatchEdit() {
      return this.queryType === 'batchEdit'
    },
    isTemplate() {
      return this.queryMode === 'template'
    },
  },
  async activated() {
    if (this.isView || this.isEdit) {
      await this.queryTaskInfo(this.form.taskId)
    }
  },
  methods: {
    preStep() {
      this.active = 0
    },
    async saveAndImportData() {
      if (!this.isLoading && this.isCreate && !this.form.taskId) {
        this.isLoading = true
        const isSuccess = await this.saveTask('create')
        this.isLoading = false
        if (!isSuccess) return
      }
      if (!this.form.taskId) return
      this.active = 1
      this.baseStore.routeTitles['isalesManualCallTask'] = `${this.$t('isales.manutask.title.viewdata')}-${this.form.taskName}`
    },
    async save() {
      if (!this.isLoading && this.isCreate && !this.form.taskId) {
        this.isLoading = true
        const isSuccess = await this.saveTask('create')
        this.isLoading = false
        if (!isSuccess) return
      } else if (!this.isLoading && (this.isEdit || (this.isCreate && this.form.taskId))) {
        // 创建成功后，从外呼数据返回到form页面，有taskId，作为编辑处理
        this.isLoading = true
        const isSuccess = await this.saveTask('modify')
        this.isLoading = false
        if (!isSuccess) return
      }
      if (!this.form.taskId) return
      this.$event.backInTab()
    },
    cancel() {
      this.$event.backInTab()
    },
    confirm() {
      this.$event.backInTab()
    },
    async queryTaskInfo(taskId = null, type = null) {
      let param = {
        dataObject: { taskId: taskId || this.form.taskId },
        limit: 1,
        offset: 0,
      }
      const resultList = await queryTaskByCondition(param)
      let result = resultList[0]
      if (type === 'template') {
        // 选择任务模板时，不修改任务名称，任务起止时间
        let { taskName, taskId, beginDate, endDate, ...data } = result
        Object.assign(this.form, data)
      } else {
        this.form = result
      }
      if (this.$route.query.step === '1') {
        this.form.agents = this.form.agents.map(item => {
          return { ...item, accountCode: item.userAccount }
        })
        if (this.form.beginDate && this.form.endDate) {
          this.form.taskTime = [this.$TimeUtils.transDate(this.form.beginDate), this.$TimeUtils.transDate(this.form.endDate)]
        }
        this.convertPickUp(this.form.pickUp)
        if (this.form.surveyId) {
          this.setSurveyName(this.form.surveyId)
        }
      }
    },
    // 转换自动提取条件数据
    convertPickUp(pickUp) {
      if (pickUp && pickUp.pickUpCondition) {
        let limitRule = JSON.parse(pickUp.limitRule)
        let pickUpCondition = JSON.parse(pickUp.pickUpCondition)
        // 没有subConditions的为主条件
        let primaryCondition = pickUpCondition.conditions.filter(condition => !condition.subConditions)
        // 有subConditions的为子条件
        let subConditions = pickUpCondition.conditions.filter(condition => !!condition.subConditions)
        let operator = pickUpCondition.operator
        this.form.pickUp = {
          isAutoPickUp: true,
          pickUpNum: pickUp.pickUpNum,
          quotas: limitRule.quotas,
          busiResult: limitRule.busiResult,
          callCompletedNum: limitRule.callCompletedNum,
          primaryCondition: primaryCondition,
          subConditions: subConditions,
          operator
        }
      } else {
        this.form.pickUp = {
          isAutoPickUp: false,
          pickUpNum: null,
          quotas: null,
          busiResult: '',
          callCompletedNum: null,
          primaryCondition: [],
          subConditions: [],
          operator: 'and',
          conditions: [],
        }
      }
      this.$nextTick(() => {
        this.$refs.taskInfoForm.setSelectedAttributes()
      })
    },
    templateSelectConfirm(taskId) {
      this.queryTaskInfo(taskId, 'template')
    },
    async saveTask(type) {
      const { taskInfoValid, conditionValid } = await this.$refs.taskInfoForm.validate()
      console.log({ taskInfoValid, conditionValid })
      if (taskInfoValid !== true || conditionValid !== true) return
      this.form.taskType = TASK_TYPE.MANUAL
      let hh = new Date().getHours()
      this.form.beginDate = new Date(new Date(this.form.beginDate).setHours(hh)).getTime()
      this.form.endDate = new Date(new Date(this.form.endDate).setHours(hh)).getTime()
      let taskInfo = {}
      let filedList = ['beginDate', 'endDate', 'skillId', 'skillName','smsTemplateId', 'smsTemplateName', 'taskName','taskType', 'callTimes', 'surveyId','scenarioType','ivrAccessCode']
      filedList.forEach((field) => {
        taskInfo[field] = this.form[field]
      })
      taskInfo.callerNo = [{ accessCode: this.form.callerNo }]
      let agents = this.form.agents.map((item) => {
        return { accountCode: item.accountCode }
      })
      let { primaryCondition, subConditions, ...pickUp } = this.form.pickUp
      pickUp.conditions = []
      pickUp.conditions.push(...primaryCondition)
      pickUp.conditions.push(...subConditions)
      let params = { taskInfo, agents, pickUp }
      let result
      if (type === 'create') {
        result = await createAgentTask(params)
      } else if (type === 'modify') {
        params.taskInfo.taskId = this.form.taskId
        result = await updateAgentTask(params)
      }
      const isSuccess = this.handleReturnCode(result.returnCode)
      if (type === 'create' && isSuccess) {
        this.form.taskId = result.taskId // 保存taskId，用于跳转下一页的外呼数据
      }
      return isSuccess
    },
    async setSurveyName(surveyId) {
      let result = await querySurveyListAndCount({ rows: 1, page: 1, scriptId: surveyId })
      if (result && result.data && result.data.length > 0) {
        this.form.surveyName = result.data[0].scriptName + '_V' + result.data[0].versionNo;
        this.form.scenarioType = result.data[0].scenarioType;
      }
    },
    async batchEditTask() {
      const valid = await this.$refs.taskInfoForm.validateBatchEdit()
      if (!valid) return
      const { basicInfoDisalbed, conditionDisalbed } = this.$refs.taskInfoForm.getBatchEditDisablesStatus()
      let param = {
        taskInfo: {
          taskIds: this.taskManagementStore.batchEditTaskIds.join(','),
          taskType: this.TASK_TYPE.MANUAL
        },
        agents: []
      }
      if (!basicInfoDisalbed) {
        if (this.form.callerNo) {
          param.taskInfo.callerNo = [{ accessCode: this.form.callerNo }]
        }
        if (this.form.surveyId) {
          param.taskInfo.surveyId = this.form.surveyId
        }
        if (this.form.ivrAccessCode) {
          param.taskInfo.ivrAccessCode = this.form.ivrAccessCode
        }
        if (this.form.scenarioType) {
          param.taskInfo.scenarioType = this.form.scenarioType
        }
        let hh = new Date().getHours()
        if (this.form.beginDate) {
          param.taskInfo.beginDate = new Date(new Date(this.form.beginDate).setHours(hh)).getTime()
        }
        if (this.form.endDate) {
          param.taskInfo.endDate = new Date(new Date(this.form.endDate).setHours(hh)).getTime()
        }
        if (this.form.smsTemplateName) {
          param.taskInfo.smsTemplateId = this.form.smsTemplateId
          param.taskInfo.smsTemplateName = this.form.smsTemplateName
        }
        if (this.form.callTimes) {
          param.taskInfo.callTimes = this.form.callTimes
        }
        if (this.form.agents.length) {
          param.taskInfo.skillId = this.form.skillId;
          param.taskInfo.skillName = this.form.skillName;
          param.agents =  this.form.agents.map((item) => {
            return { accountCode: item.accountCode }
          })
        }
      }
      if (!conditionDisalbed) {
        let { primaryCondition, subConditions, ...pickUp } = this.form.pickUp
        pickUp.conditions = []
        pickUp.conditions.push(...primaryCondition)
        pickUp.conditions.push(...subConditions)
        param.pickUp = pickUp
      }
      const { returnCode } = await batchUpdateAgentTask(param)
      const isSuccess = this.handleReturnCode(returnCode)
      if (isSuccess) {
        this.$event.backInTab()
      }
    },
    handleReturnCode(returnCode) {
      switch (returnCode) {
        case RETURN_CODE.SUCCESS:
          this.$message({
            type: 'success',
            offset: 56,
            duration: 5000,
            message: this.$t('isales.task.message.saveSuccess')
          })
          return true
        case RETURN_CODE.FAIL_EXIT:
          this.$message({
            type: 'error',
            duration: 10000,
            offset: 56,
            'show-close': true,
            message: this.$t('isales.taskinfo.message.nameExists')
          })
          return false
        case RETURN_CODE.WARNING:
          this.$message({
            type: 'error',
            duration: 10000,
            offset: 56,
            'show-close': true,
            message: this.$t('isales.taskinfo.message.agent')
          })
          return false
        default:
          this.$message({
            type: 'error',
            duration: 10000,
            offset: 56,
            'show-close': true,
            message: this.$t('isales.task.message.saveFail')
          })
          return false
      }
    },
    async saveAsTemplate(){
      if (!this.isLoading) {
        this.isLoading = true
        const { taskInfoValid, conditionValid } = await this.$refs.taskInfoForm.validate()
        console.log({ taskInfoValid, conditionValid })
        if (taskInfoValid !== true || conditionValid !== true) {
          this.isLoading = false
          return ;
        }
        if (!this.isTemplate) {
          ElMessageBox.prompt(this.$t('isales.tasktemplate.field.templateName'), this.$t('isales.tasktemplate.title.saveAsTemplate'), {
            confirmButtonText: this.$t('isales.specialday.title.confirm'),
            cancelButtonText: this.$t('isales.statistics.business.cancel'),
            customStyle: {'width': '616px'},
            inputValidator: this.$Validate.checkNotEmptyAndSpecialChar,
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                const {inputValue} = instance
                this.createTemp(inputValue);
                done()
              } else {
                done()
              }
            }
          });
        } else {
          this.createTemp(null);
        }
        this.isLoading = false
      }
    },
    async createTemp(inputValue) {
      let params = await this.getRequestParamsOfOutboundCallTemplate()
      let result
      if (this.isTemplate && this.isEdit) {
        params.taskTemplate.taskId = this.form.taskId
        result = await updateManualOutboundCallTemplate(params)
      } else {
        if (!this.isTemplate){
          params.taskTemplate.taskName = inputValue
        }
        result = await createManualOutboundCallTemplate(params)
      }
      if (result.returnCode === RETURN_CODE.SUCCESS) {
        ElMessage({
          type: 'success',
          offset: 56,
          duration: 5000,
          message: this.$t('isales.tasktemplate.message.saveSuccess')
        });
        if (this.isTemplate){
          this.$event.backInTab()
        }
      } else if (result.returnCode == RETURN_CODE.DUPLICATED) {
        ElMessage({
          type: 'error',
          offset: 56,
          duration: 10000,
          'show-close': true,
          message: this.$t('isales.tasktemplate.message.nameDuplicated')
        });
      } else {
        ElMessage({
          type: 'error',
          offset: 56,
          duration: 10000,
          'show-close': true,
          message: this.$t('isales.tasktemplate.message.saveFail')
        });
      }
    },
    async getRequestParamsOfOutboundCallTemplate(){
      let params = {};
      this.form.taskType = TASK_TYPE.MANUAL
      let taskTemplate = {}
      let filedList = ['skillId', 'skillName','smsTemplateId', 'smsTemplateName', 'taskName','taskType', 'callTimes', 'surveyId', 'callerNo','scenarioType','ivrAccessCode']
      filedList.forEach((field) => {
        taskTemplate[field] = this.form[field]
      })
      params.taskTemplate = taskTemplate
      let agents = this.form.agents.map((item) => {
        return { userAccount: item.accountCode }
      })
      let { primaryCondition, subConditions, ...pickUp } = this.form.pickUp
      pickUp.conditions = []
      pickUp.conditions.push(...primaryCondition)
      pickUp.conditions.push(...subConditions)
      return { taskTemplate: taskTemplate, agents: agents, pickUp: pickUp };
    },
  },
  async created() {
    if (this.$route.query.step) {
      this.active = this.$route.query.step - 1
    }
    this.queryType = this.$route.query.type;
    this.queryMode = this.$route.query.mode;
    if (this.isView || this.isEdit) {
      this.form.taskId = this.$route.query.taskId;
    }
  }
}
</script>

<style scoped>

</style>