export default {
  "ccivr.nms.leavemessageservice.choose.agent" : "معرف الموظف المحدد",
  "ccivr.ivr.ivrflow.templateName" : "اسم القالب",
  "ccivr.ivr.ivrusage.skillName" : "اسم قائمة انتظار المهارة",
  "ccivr.nms.ringService.videomodel" : "وضع الفيديو",
  "ccivr.ivr.tracelog.calledNumbererror" : "فشل التحقق من الرقم الطالب!",
  "ccivr.ivr.voice.scene" : "سيناريو الاستخدام",
  "ccivr.nms.process.saveerror.noexistend" : "فشل الحفظ. يوجد عنصر مخطط نهاية واحد على الأقل.",
  "ccivr.ivr.error.paramlimit" : "لا يمكن أن يتجاوز إجمالي طول المعلمة 4000 بايت.",
  "ccivr.nms.tansferservice.chooseSkill" : "تحديد قائمة انتظار المهارات",
  "ccivr.ivr.description.variableerror" : "تنسيق متغير القالب غير صحيح.",
  "ccivr.nms.shortmessageservice.countryCode" : "رمز البلد",
  "ccivr.nms.playset.open" : "ممكّن",
  "ccivr.ivr.voiceupload.fileformatconverterror" : "حدث خطأ عند تحويل تنسيق الملف، أو أن تكوين FFmpeg غير صحيح.",
  "ccivr.ivrflow.grayrule.beginnum" : "رقم البدء",
  "ccivr.ivr.voiceedit.editfail" : "فشل التحرير.",
  "ccivr.nms.queryservice.specialListQuery" : "معلومات قائمة خاصة",
  "ccivr.nms.playcollectservice.videofile" : "ملف الفيديو",
  "ccivr.ivr.ivrusage.ivrname" : "السيناريو المرجعي",
  "ccivr.ivr.voicereview.manualreview" : "مراجعة يدوية",
  "ccivr.nms.timeselectservice.monday" : "الاثنين",
  "ccivr.ivr.ivrflow.name" : "الاسم",
  "ccivr.ivr.voiceupload.sizewrong" : "حجم الملف يتجاوز 2 ميغابايت. قم باستيراد الملف مرة أخرى.",
  "ccivr.ivr.voiceupload.referenceFlow" : "معلومات تدفق المرجع",
  "ccivr.nms.conditionService.processLayout" : "تزامن العمليات",
  "ccivr.ivrflow.unlock.confirm" : "هل تريد تأكيد حالة إلغاء القفل في التحرير؟",
  "ccivr.nms.playservice.multivoice.type.number" : "عدد",
  "ccivr.nms.businessinvokeservice.value" : "القيمة",
  "ccivr.nms.tansferservice.transferLabor" : "التحويل إلى قائمة انتظار المهارات",
  "ccivr.ivrflow.cancelrelease.referenced" : "تمت الإشارة إلى التدفق بواسطة رمز وصول.",
  "ccivr.ivr.bussinessinvoke.interfacename" : "اسم الواجهة",
  "ccivr.nms.playservice.multivoice.language24" : "تايلاند",
  "ccivr.nms.playservice.multivoice.language23" : "أوزبكستان",
  "ccivr.flowtest.hashangup" : "ينتهي الاختبار، ويتم تحرير المكالمة.",
  "ccivr.nms.playservice.multivoice.language22" : "التركية",
  "ccivr.nms.playservice.multivoice.language21" : "السويدية",
  "ccivr.nms.timeselectservice.saturday" : "السبت",
  "ccivr.nms.playservice.multivoice.language20" : "الأسبانية",
  "ccivr.nms.playset.malevoice" : "صوت الذكور",
  "ccivr.ivr.voicereview.thailand" : "تايلاند",
  "ccivr.nms.timeselectservice.selectworktime" : "يوم الاسبوع",
  "ccivr.ivr.voiceupload.deletefail" : "فشل الحذف.",
  "ccivr.ivr.voiceedit.choosefile" : "تحديد ملف",
  "ccivr.nms.tansferservice.transferLastAgent.type.currentday.info" : "يتم تحويل المكالمة إلى الموظف الذي تم الاتصال به في اليوم الحالي.",
  "ccivr.nms.queryservice.specifiedSkill.paramValue.sample" : "مثال: 101 أو ${FLOW.workNo}.",
  "ccivr.nms.satisfactionservice.isNull" : "معلمة الرضا فارغة",
  "ccivr.nms.tansferservice.transferLastAgent.type.none" : "افتراضي",
  "ccivr.ivr.ivrflow.operate.release.fail" : "فشل التحرير.",
  "ccivr.nms.playservice.multivoice.language19" : "السلوفاكية",
  "ccivr.ivr.voiceupload.languagemanage" : "إدارة اللغة",
  "ccivr.nms.playservice.multivoice.language18" : "الروسية",
  "ccivr.nms.playservice.multivoice.language17" : "البرتغالية",
  "ccivr.nms.playservice.multivoice.language16" : "البولندية",
  "ccivr.flowtest.client" : "العميل",
  "ccivr.nms.playservice.multivoice.language15" : "النرويجية",
  "ccivr.nms.playservice.multivoice.language14" : "الكورية",
  "ccivr.ivr.voicereview.portuguese" : "البرتغالية",
  "ccivr.nms.playservice.multivoice.language13" : "اليابانية",
  "ccivr.nms.playservice.multivoice.language12" : "الإيطالية",
  "ccivr.nms.leavemessageservice.playbi" : "العب نغمة الصافرة",
  "ccivr.nms.playservice.multivoice.language11" : "الآيسلندية",
  "ccivr.nms.tansferservice.transferLastAgent.type.startTime.info" : "يتم تحويل المكالمة إلى الموظف الذي تم الاتصال به من وقت البدء.",
  "ccivr.nms.playservice.multivoice.language10" : "الهنغارية",
  "ccivr.ivr.voiceupload.vedioformatwrong" : "تنسيق الملف غير صحيح. استيراد ملف 3GP.",
  "ccivr.ivr.voiceupload.ivrVoiceUsage" : "معلومات مرجعية",
  "ccivr.nms.interface.whitelistcheckfailed" : "عنوان URL (عنوان IP ورقم المنفذ) غير موجود في قائمة الثقة. يمكن استدعاء الواجهة فقط بعد الاتصال بمسؤول النظام لإضافة URL إلى قائمة الثقة. هل تريد المتابعة؟",
  "ccivr.ivr.voice.voicename" : "timbre",
  "ccivr.ivr.voiceedit.inputtext" : "أدخل النص.",
  "ccivr.nms.process.saveerror.queuing" : "أدخل واحدًا على الأقل من وضع قوائم الانتظار وعدد الوكلاء عبر الإنترنت ووقت الانتظار المتوقع.",
  "ccivr.ivr.ivrflow.unreleased" : "لم يتم إصدارها",
  "ccivr.nms.SubFlowService.processjump" : "اقفز إلى",
  "ccivr.nms.businessinvokeservice.opertaion" : "العملية",
  "ccivr.nms.playcollectservice.voiceprompts" : "موجه الصوت",
  "ccivr.ivr.voiceupload.checkcallcenterparamfailed" : "فشل التحقق من معلمة مركز الاتصال.",
  "ccivr.nms.lightweight.tips" : "قم بإجراء العمليات على الاستبيان خفيف الوزن.",
  "ccivr.ivr.tracelog.errorInfo" : "رسالة خطأ",
  "ccivr.ivr.voiceedit.sftpinfoisnull" : "معلومات SFTP الخاصة بمركز المكالمات خالية.",
  "ccivr.nms.queryservice.queueInfo" : "يتم الاستعلام عن معلومات قائمة الانتظار قبل تحويل المكالمة إلى قائمة انتظار مهارة.",
  "ccivr.ivr.voiceupload.audioformat" : "تنسيق ملف الصوت: ALAW ، 8bit ، 8000Hz ، 64 kbps ، mono.",
  "ccivr.nms.interface.urltip" : "استخدم عنوان URL الذي يبدأ بـ HTTP أو HTTPS.",
  "ccivr.ivr.voiceupload.selectLangNull" : "لم يتم تحديد أي لغة. يرجى اختيار لغة.",
  "ccivr.ivr.voiceupload.pleaserecording" : "قم بتحميل الملف بعد التسجيل.",
  "ccivr.ivrvoice.play.error" : "استثناء مورد التحميل، يُرجى التحقق من التكوين ذي الصلة.",
  "ccivr.ivr.ivrflow.operate.ensurecancelrelease.info" : "هل تريد بالتأكيد إلغاء الإصدار؟",
  "ccivr.ivr.voiceupload.recording" : "سجل",
  "ccivr.ivr.voiceupload.textcontent" : "نص",
  "ccivr.ivr.voiceupload.file.content" : "المحتوى",
  "ccivr.ivr.bussinessinvoke.long" : "عدد صحيح طويل",
  "ccivr.nms.playcollectservice.advancedconfig" : "إعدادات متقدمة",
  "ccivr.ivrflow.operate.ensurereplace.info" : "هل تريد بالتأكيد استبدال التدفق الذي تم تحريره؟",
  "ccivr.conditionService.param.tips" : "عند تعيين المعلمة 2 كثابت سلسلة، يجب إضافة علامات الاقتباس للاستخدام العادي.",
  "ccivr.ivr.voiceupload.playrecord" : "الصوت",
  "ccivr.ivr.voiceupload.fileformatwrong" : "نوع غير صحيح.",
  "ccivr.nms.playcollectservice.collectnumtimeout" : "انتهت مهلة جمع الأرقام.",
  "ccivr.ivr.voice.recordupload" : "التسجيل عبر الإنترنت",
  "ccivr.ivr.voiceupload.deleteodfsivrfail" : "فشل الحذف. الـIVR مشغول",
  "ccivr.nms.assignservice.assignmentParameters" : "إعداد معلمات التعيين",
  "ccivr.nms.conditionService.conditionService" : "الحكم المشروط",
  "ccivr.ivr.voiceupload.deletereferenceivrfail" : "فشل حذف الصوت. تم الإشارة إلى الصوت.",
  "ccivr.ivr.voiceedit.idisnull" : "المعرف فارغ.",
  "ccivr.ivr.tracelog.maxNumberValidate" : "تجاوز عدد الأرقام الطالبة الحد الأعلى!",
  "ccivr.ivrflow.grayrule.rule" : "القاعدة الرمادية",
  "ccivr.nms.playset.status" : "إعدادات TTS المتقدمة",
  "ccivr.nms.timeselectservice.tuesday" : "الثلاثاء",
  "ccivr.ivr.tracelog.result.transfailed" : "فشل",
  "ccivr.ivr.tracelog.result" : "نتائج التدفق",
  "ccivr.ivr.bussinessinvoke.updateinterface" : "تحرير الواجهة",
  "ccivr.ivr.voiceedit.statusisnull" : "معلمة الحالة خالية.",
  "ccivr.ivr.tracelog.createTime" : "إدخال وقت التدفق",
  "ccivr.nms.leavemessageservice.max.recordtime" : "الحد الأقصى لمدة التسجيل",
  "ccivr.ivr.voicereview.text" : "نص",
  "ccivr.nms.playservice.multivoice.language" : "اللغة",
  "ccivr.nms.queryservice.type" : "اختر النوع",
  "ccivr.nms.label.certType" : "نوع الشهادة",
  "ccivr.nms.menuservice.moveup" : "لأعلى",
  "ccivr.ivr.ivrflow.deleteconfim" : "هل تريد بالتأكيد حذف تدفق IVR؟",
  "ccivr.nms.tansferservice.callForwarding" : "تكوين إعادة توجيه المكالمات",
  "ccivr.nms.questionnaireService.question" : "أسئلة الاستبيان",
  "ccivr.nms.assignservice.setTransferData" : "تعيين نقل البيانات",
  "ccivr.ivr.voiceedit.pathisnull" : "فشل الاستعلام عن معلومات مسار SFTP لمركز المكالمات.",
  "ccivr.flowtest.hangup" : "شنق",
  "ccivr.nms.menuservice.tip" : "انتهاء المهلة أو مفتاح غير صحيح",
  "ccivr.ivr.ivrflow.batchdeleteconfim" : "هل تريد بالتأكيد حذف جميع تدفقات IVR المحددة؟ تأكد من عدم تحرير تدفق IVR.",
  "ccivr.ivr.voiceupload.file.update.time" : "تحديث الوقت",
  "ccivr.ivrflow.cancelrelease.referenced.by.idauth.survey" : "تمت الإشارة إلى العملية بواسطة تكوين المصادقة ولا يمكن إلغاء نشرها!",
  "ccivr.ivrflow.updateivrinfo.editing" : "جاري تحرير التدفق ولا يمكن تعديله.",
  "ccivr.nms.process.saveerror.alreadyreleased" : "حالة التدفق غير طبيعية، يُرجى إعادة فتح اللوحة القماشية.",
  "ccivr.ivr.voiceupload.file.update" : "التحديثات",
  "ccivr.nms.leavemessageservice.localtionID" : "localtionID",
  "ccivr.ivr.voiceupload.tipvideoformat" : "حاليا، يتم دعم ملفات الفيديو 3GP فقط. لا يمكن أن يتجاوز حجم الملف 5 ميغابايت.",
  "ccivr.ivr.voiceupload.audiosampleratefailed" : "معدل أخذ العينات لملف الصوت ليس 8000 هرتز.",
  "ccivr.ivr.voicereview.reviewsuggestion" : "التعليقات",
  "ccivr.ivr.ivrflow.operate.cancelrelease" : "إلغاء الإصدار",
  "ccivr.nms.process.paramname" : "الاسم",
  "ccivr.nms.playservice.playfile" : "ملف صوتي",
  "ccivr.ivr.voiceupload.fileheaderwrong" : "تنسيق ملف الصوت الأصلي ليس WAV أو MP3. قم بتحميل ملف صوتي آخر.",
  "ccivr.nms.queryservice.audio" : "الصوت",
  "ccivr.nms.label.inputCertCode" : "أدخل رمز شهادة.",
  "ccivr.ivr.voicereview.edit" : "تحرير",
  "ccivr.ivr.tracelog.callInfo" : "معلومات الاتصال",
  "ccivr.nms.playservice.multivoice.type" : "النوع",
  "ccivr.ivr.voiceupload.file.update.content" : "تحديث المحتوى",
  "ccivr.ivr.success" : "النجاح",
  "ccivr.nms.tansferservice.chooseZNIvr" : "حدد الذكي  IVR",
  "ccivr.ivrflow.release.grayrule" : "الإصدار الرمادي",
  "ccivr.nms.process.saveerror.dataerror" : "فشل التحقق من بيانات عنصر المخطط.",
  "ccivr.nms.timeselectservice.min" : "دقيقة",
  "ccivr.nms.delete" : "حذف",
  "ccivr.ivr.variable.errorspecial" : "النوع غير موجود.",
  "ccivr.nms.satisfactionservice.buttonNumber.Number" : "لا يزيد عدد المفاتيح عن 9 ولا يقل عن 1.",
  "ccivr.ivr.transferRecord.noMoreThan90Days" : "لا يمكن أن يتجاوز النطاق 90 يومًا.",
  "ccivr.ivr.tracelog.deleteFailed" : "فشل الحذف!",
  "ccivr.ivr.voiceupload.tmpformaterror" : "نوع ملف مؤقت خاطئ.",
  "ccivr.ivr.voiceupload.file.update.status" : "الحالة",
  "ccivr.nms.queryservice.getDataAssign" : "نتيجة الاستعلام",
  "ccivr.ivr.voiceupload.checkuploadparmfailed" : "فشل التحقق من إيداع الملف parm.",
  "ccivr.ivrflow.grayrule.inputrulename" : "أدخل اسم القاعدة.",
  "ccivr.ivr.tracelog.addFailed" : "فشلت الإضافة!",
  "ccivr.ivr.variable.addvariable" : "إضافة متغير",
  "ccivr.nms.tansferservice.onlineagent" : "عدد الوكلاء عبر الإنترنت",
  "ccivr.ivrflow.updateivrinfo.fail" : "فشل تعديل سمات تدفق IVR.",
  "ccivr.ivr.voiceedit.addsuccess" : "تمت الإضافة بنجاح.",
  "ccivr.ivr.variable.defaultlimit" : "لا يمكن أن تحتوي القيمة الافتراضية على أكثر من 120 حرفًا.",
  "ccivr.whitelist.deletetip" : "يرجى تحديد العناصر التي تريد حذفها",
  "ccivr.ivr.ivrflow.operate.clone.fail" : "فشل في ترقية التدفق.",
  "ccivr.nms.conditionService.maxroute" : "الحد الأقصى لعدد الفروع الشرطية هو 20.",
  "ccivr.nms.businessinvokeservice.servicename" : "اسم الواجهة",
  "ccivr.ivr.voicereview.arabic" : "العربية",
  "ccivr.ivr.voicereview.german" : "الألمانية",
  "ccivr.nms.process.saveerror.menukeynoconnection" : "فشل الحفظ. تحقق مما إذا كان لكل مفتاح في تكوين القائمة خط.",
  "ccivr.ivr.transferRecord.init" : "التهيئة",
  "ccivr.ivr.voiceedit.deletesuccess" : "تم الحذف بنجاح.",
  "ccivr.ivr.voicereview.operat" : "العملية",
  "ccivr.nms.playservice.chosetts" : "يتم تحديد المحتوى المراد تشغيله من نص TTS الذي تم تحميله ومراجعته. مطلوب موارد TTS.",
  "ccivr.nms.shortmessageservice.setParameter" : "تعيين متغير",
  "ccivr.ivr.voice.scene.note" : "نغمة",
  "ccivr.ivr.bussinessinvoke.validate" : "فشل التحقق",
  "ccivr.flowtest.begin.tip" : "أدخل الرقم الطالب لبدء الاختبار.",
  "ccivr.ivr.voiceupload.fileovermax" : "يتجاوز عدد أصوات IVR المطلوب إدارتها الحد الأقصى.",
  "ccivr.nms.process.description" : "الوصف",
  "ccivr.ivr.voiceedit.filenamecheckfail" : "فشل التحقق من صحة اسم الملف.",
  "ccivr.nms.leavemessageservice.choose.recordfilename" : "تسجيل اسم الملف",
  "ccivr.nms.queryservice.skillInfo" : "معلومات قائمة الانتظار",
  "ccivr.ivr.ivrflow.ivrname" : "الاسم",
  "ccivr.ivr.voicereview.search" : "البحث",
  "ccivr.nms.playcollectservice.collectnumerror" : "فشل جمع الأرقام.",
  "ccivr.nms.businessinvokeservice.title" : "استدعاء واجهة",
  "ccivr.nms.businessinvokeservice.inputflowValidate" : "إدخال غير صحيح، يُرجى التعديل",
  "ccivr.ivr.error.sftp" : "معلومات SFTP غير صحيحة.",
  "ccivr.nms.playcollectservice.times" : "مرات",
  "ccivr.nms.assignservice.othersDataAssign" : "تعيين البيانات الاخري",
  "ccivr.nms.SubFlowService.jumpnode" : "عقدة القفز",
  "ccivr.nms.playcollectservice.maxfailtimes" : "ماكس. مرات الفشل",
  "ccivr.nms.tansferservice.selectPath" : "تحديد مسار",
  "ccivr.flowtest.operatefail" : "فشل",
  "ccivr.ivr.bussinessinvoke.string" : "سلسلة الحرف",
  "ccivr.nms.varibalesselect.sysvariables" : "متغير النظام",
  "ccivr.ivrflow.grayrule.name" : "اسم القاعدة",
  "ccivr.nms.process.saveerror.noselectType" : "حدد نوع استعلام.",
  "ccivr.whitelist.urlvalidatefailed" : "الرجاء إدخال عنوان IP أو اسم المجال",
  "ccivr.ivrflow.grayrule.bindingflow" : "تدفق مقيد:",
  "ccivr.ivr.ivrflow.operate.ensurerelease" : "تأكيد الإصدار",
  "ccivr.ivrflow.createivrflow.exceptionfail" : "تدفق معالجة الاستثناء موجود بالفعل. فشلت الإضافة.",
  "ccivr.ivr.tracelog.result.default" : "افتراضي",
  "ccivr.ivr.success.import" : "تمت الإضافة بنجاح.",
  "ccivr.nms.process.saveerror.queues" : "أدخل واحدًا على الأقل من موضع قائمة الانتظار، وعدد الوكلاء المتصلين، وعدد الوكلاء الخاملين، ووقت الانتظار المتوقع.",
  "ccivr.ivrflow.grayrule.inputendnum" : "يجب أن يكون رقم النهاية أكبر من رقم البدء أو مساويًا له.",
  "ccivr.ivr.voicereview.uploadtime" : "وقت التحميل",
  "ccivr.nms.playservice.placeholder" : "تشغيل الصوت",
  "ccivr.ivr.bussinessinvoke.int" : "عدد صحيح",
  "ccivr.ivr.voicereview.language" : "اللغة",
  "ccivr.nms.questionnaireService.info" : "تُستخدم عناصر مخطط الاستبيان في مهام المكالمات الصادرة التلقائية. يجب أن تكون عملية استبيان IVR مرتبطة.",
  "ccivr.nms.tansferservice.add" : "جديد",
  "ccivr.nms.tansferservice.parameter" : "القيمة",
  "ccivr.nms.playcollectservice.Failedreceivenum" : "فشل جمع الأرقام",
  "ccivr.ivrflow.grayrule.numrange": "مقطع الرقم",
  "ccivr.ivr.voiceedit.filenameformaterror": "تنسيق اسم الملف غير صحيح.",
  "ccivr.ivr.variable.typeisnull": "النوع فارغ.",
  "ccivr.nms.playcollectservice.changtovideoO": "فيديو",
  "ccivr.nms.playset.speed": "سرعة التحدث",
  "ccivr.ivr.voiceupload.referenceFlowByCCIVR": "مرجع تدفق IVR",
  "ccivr.nms.timeselectservice.hour": "ساعة",
  "ccivr.ivr.voiceupload.vedioheaderwrong": "تنسيق ملف الفيديو الأصلي ليس 3gp. قم بتحميل ملف فيديو آخر.",
  "ccivr.nms.satisfactionservice.menubuttonconfig": "تكوين زر الرضا",
  "ccivr.ivrflow.grayrule.info": "التفاصيل",
  "ccivr.nms.tansferservice.record": "سجل",
  "ccivr.nms.conditionService.simpleExp": "تعبير بسيط",
  "ccivr.ivrflow.cancelrelease.error": "تتم الإشارة إلى التدفق بواسطة قائمة انتظار مهارة!",
  "ccivr.nms.tansferservice.transferLastAgent.sampleForCallingnum": "مثال: 660001 أو {\'$\'}{\'{\'}SYS.callingNumber{\'}\'}",
  "ccivr.ivrflow.error.deleteivrflow.fail": "التدفق غير موجود.",
  "ccivr.nms.process.saveerror": "فشل الحفظ. تحقق من عقدة التدفق وخط الاتصال.",
  "ccivr.nms.tansferservice.transferLastAgent.type.startTime": "وقت البدء",
  "ccivr.whitelist.whiteip": "عنوان IP لقائمة الثقة",
  "ccivr.nms.SubFlowService.Rotorflow": "نقل إلى التدفق الفرعي",
  "ccivr.nms.tansferservice.transferLastAgent.type.none.info": "يتم تحويل المكالمة إلى الموظف الذي تم الاتصال به مؤخرًا.",
  "ccivr.nms.conditionService.relationOperator": "المشغل",
  "ccivr.nms.playcollectservice.changtovoice": "TTS",
  "ccivr.nms.tansferservice.transSkill": "يتم تحويل المكالمة إلى قائمة انتظار المهارات المطابقة. يتم دعم المعلمات المعرفة من قبل المستخدم. Example: {\'$\'}{\'{\'}FLOW.Parameter name{\'}\'}",
  "ccivr.interface.typeerror": "حاليًا، يتم دعم نوعي GET وPOST فقط.",
  "ccivr.nms.conditionService.complexExp": "التعبير المعقد",
  "ccivr.ivr.tracelog.errorpop": "فشل الاستعلام!",
  "ccivr.nms.timeselectservice.tips3": "يجب أن تكون نطاقات وقت الخدمة بترتيب تصاعدي. لا يمكن تداخل كل نطاق زمني مع بعضها البعض.",
  "ccivr.nms.timeselectservice.tips2": "يجب أن يكون وقت انتهاء الخدمة بعد وقت البدء.",
  "ccivr.nms.tansferservice.transferZNIvr": "سيتم تحويل مكالمات العملاء إلى نظام IVR الذكي المطابق",
  "ccivr.nms.timeselectservice.tips1": "التاريخ الذي يتوافق مع يوم الأسبوع المحدد والتاريخ المحدد هو يوم عمل.",
  "ccivr.ivr.voicereview.name": "الاسم",
  "ccivr.nms.tansferservice.transferThirdParty.recordingPrompt": "مطالبة التسجيل",
  "ccivr.nms.businessinvokeservice.outputparam": "معلمة الإخراج",
  "ccivr.ivr.languageid.repeat.fail": "Language Id غير موجود.",
  "ccivr.ivr.tracelog.flowName": "اسم التدفق",
  "ccivr.ivr.voiceupload.opreationdbfailed": "فشل تحديث الرسالة الصوتية IVR لقاعدة البيانات.",
  "ccivr.ivr.voiceupload.pleaseuploadfile": "الملف فارغ. الرجاء استيراد الملف.",
  "ccivr.flowtest.callingnumber": "رقم المتصل",
  "ccivr.ivr.voicereview.voice": "صوت",
  "ccivr.nms.queryservice.queueSpecialInfo": "الاستعلام عما إذا كان رقم المشترك المتصل موجودًا في القائمة الخاصة.",
  "ccivr.ivr.ivrflow.alreadyreleased": "الإصدار الرسمي",
  "ccivr.ivr.voicereview.nosubmit" : "لم يتم الإرسال",
  "ccivr.nms.menuservice.menubuttonconfig" : "تكوين مفتاح القائمة",
  "ccivr.whitelist.port" : "منفذ",
  "ccivr.ivr.ivrflow.operate.release.success" : "نجاح الافراج.",
  "ccivr.nms.tansferservice.transferLastAgent.type.time.sample" : "مثال: 100، يشير إلى أنه تم تحويل المكالمة إلى الموظف الذي تم الاتصال به في غضون 100 دقيقة.",
  "ccivr.ivr.voice.pitch" : "نغمة",
  "ccivr.nms.shortmessageservice.hwCloudMessage" : "سحابة هواوي",
  "ccivr.ivr.connectsftp.uploadfile.exception" : "فشل الاتصال بـ SFTP. فشل إيداع الملف.",
  "ccivr.nms.assignservice.transferDataAssign" : "الحصول على TransferData",
  "ccivr.nms.timeselectservice.servicetimeperiod" : "النطاق الزمني للخدمة",
  "ccivr.nms.userLevelService.placeholder" : "تعيين مستوى المستخدم",
  "ccivr.nms.tansferservice.setTransfer" : "تكوين التحويل",
  "ccivr.ivr.ivrflow.no" : "لا",
  "ccivr.flowtest.testoperate" : "العمليات:",
  "ccivr.ivr.transferRecord.flowAccessCode" : "رمز الوصول إلى العملية",
  "ccivr.ivr.ivrflow.operate.add" : "إضافة",
  "ccivr.ivr.voicereview.choosevoicetype" : "النوع",
  "ccivr.nms.process.saveerror.flownoexist" : "التدفق غير موجود.",
  "ccivr.ivr.voicereview.voiceId" : "معرف الصوت",
  "ccivr.whitelist.descinfo" : "الوصف",
  "ccivr.ivr.voiceupload.auditionTextlimit" : "يمكن أن يحتوي محتوى نص المعاينة على 300 بايت كحد أقصى.",
  "ccivr.nms.brach.duplicatebranch" : "الفرع موجود بالفعل. حدد ملفًا آخر.",
  "ccivr.ivr.voiceupload.authvalidatefailed" : "فشل التحقق من إذن إيداع الملف.",
  "ccivr.nms.tansferservice.empty" : "المعلمة فارغة.",
  "ccivr.ivr.transferRecord.noright" : "المستخدم الحالي ليس لديه الإذن بتنزيل التسجيلات.",
  "ccivr.nms.tansferservice.processName" : "أدخل اسم تدفق.",
  "ccivr.ivr.voicereview.reviewstatus" : "الحالة",
  "ccivr.nms.queryservice.Dataassignment" : "الاستعلام عن المعلومات",
  "ccivr.nms.tansferservice.sample" : "مثال: 28956666 أو {\'$\'}{\'{\'}FLOW.currentUserNum{\'}\'}",
  "ccivr.nms.tansferservice.transferThirdParty" : "التحويل إلى طرف ثالث",
  "ccivr.nms.conditionService.logicOR" : "أو",
  "ccivr.nms.modulename" : "اسم العنصر",
  "ccivr.nms.tansferservice.transferLastAgent.transCallingnum" : "الرقم المتصل",
  "ccivr.nms.menuservice.samenamevalidate" : "مفاتيح متكررة:",
  "ccivr.ivr.voicereview.reviewmethod" : "وضع مراجعة موارد الصوت",
  "ccivr.ivr.ivrflow.updateTime" : "تحديث الوقت",
  "ccivr.ivr.voiceupload.tipvideomodel" : "يمكنك تعيين ملفات الصوت أو الفيديو بلغات مختلفة في نفس الوقت. أثناء تشغيل الفيديو، يقوم النظام تلقائيًا بتشغيل ملف الفيديو المقابل استنادًا إلى اللغة المحددة من قبل المستخدم.",
  "ccivr.ivr.voicereview.pass" : "الموافقة",
  "ccivr.ivr.error.releaseFailed" : "فشل نشر البرنامج النصي تلقائيًا",
  "ccivr.ivr.voiceedit.import" : "استيراد",
  "ccivr.nms.shortmessageservice.satisfactionMessage" : "استبيان الرضا",
  "ccivr.ivr.ivrflow.exceptionHandingFlow" : "استثناء التعامل مع التدفق",
  "ccivr.ivr.voiceadvice.close" : "إغلاق",
  "ccivr.nms.process.parameterisEncrypt" : "التشفير",
  "ccivr.ivr.ivrflow.currentHandler" : "معالج",
  "ccivr.nms.satisfactionservice.buttonNumber" : "عدد مفاتيح استطلاع الرضا لا يزيد عن 9 ولا يقل عن 3.",
  "ccivr.ivr.bussinessinvoke.URL" : "طلب عنوان URL",
  "ccivr.ivr.voiceupload.selectvoice" : "حدد ملف صوتي.",
  "ccivr.nms.tansferservice.transferLastAgent.type.startTime.sample" : "حدد وقت البدء.",
  "ccivr.ivr.voiceupload.VoiceNotificationTextrule" : "لمزيد من قواعد قالب الإعلام الصوتي، حرك المؤشر فوق رمز i في مربع النص.",
  "ccivr.nms.tansferservice.processLayout" : "تزامن العمليات",
  "ccivr.ivr.voice.recordtimeout.fail" : "انتهت مهلة التسجيل.",
  "ccivr.nms.shortmessageservice.messageTemplateContent" : "قالب",
  "ccivr.ivr.bussinessinvoke.errormessage" : "حدد الواجهة المراد حذفها.",
  "ccivr.nms.tansferservice.transferLastAgent.type.time" : "النطاق الزمني",
  "ccivr.ivr.ivrflow.mainflow" : "التدفق الرئيسي",
  "ccivr.nms.process.samenamevalidate" : "اسم المعلمة المكررة:",
  "ccivr.nms.interface.nmsexception" : "التواصل مع ODFS تلبية استثناء! يُرجى التحقق من التكوين.",
  "ccivr.ivr.tracelog.result.timeout" : "المهلة",
  "ccivr.ivrflow.grayrule.rulename" : "اسم القاعدة",
  "ccivr.ivr.voice.scene.error" : "خطأ",
  "ccivr.nms.shortmessageservice.fiveGMessage" : "رسالة 5G",
  "ccivr.nms.conditionService.Parameter_2" : "المعلمة 2",
  "ccivr.ivr.voicereview.vedio" : "فيديو",
  "ccivr.ivr.voiceedit.filenameisnull" : "اسم الملف خالٍ.",
  "ccivr.nms.conditionService.Parameter_1" : "المعلمة 1",
  "ccivr.nms.userLevelService.example" : "مثال: 1-14 أو FLOW.variableName",
  "ccivr.ivr.ivrflow.subflow" : "التدفق الفرعي",
  "ccivr.nms.shortmessageservice.addressee" : "رقم الجوال",
  "ccivr.nms.playservice.inputtitle" : "موجه الصوت",
  "ccivr.ivrflow.createivrflow.sameivrflowname" : "اسم التدفق موجود بالفعل.",
  "ccivr.ivr.voice.scene.questionnaires" : "استبيان",
  "ccivr.ivr.tracelog.sameNumberValidate" : "نفس الرقم الطالب موجود بالفعل!",
  "ccivr.nms.playcollectservice.close" : "إغلاق",
  "ccivr.nms.process.saveerror.noexistconnection" : "فشل الحفظ. يجب أن يتوفر خط افتراضي لعناصر المخطط باستثناء النهاية والتحويل إلى عناصر المخطط الفرعي.",
  "ccivr.nms.queryservice.queryType" : "نوع الاستعلام",
  "ccivr.nms.playset.femalevoice" : "صوت أنثى",
  "ccivr.nms.playservice.multivoice.type.telnum" : "رقم الهاتف",
  "ccivr.flowtest.test" : "اختبار",
  "ccivr.ivr.voicereview.language81" : "العربية",
  "ccivr.ivr.voicereview.language8" : "الألمانية",
  "ccivr.nms.tansferservice.transNum" : "رقم إعادة التوجيه",
  "ccivr.ivr.voicereview.filename" : "اسم الملف",
  "ccivr.ivr.voicereview.scene" : "سيناريو الاستخدام",
  "ccivr.nms.tansferservice.transAppointWorkNo" : "يتم تحويل مكالمة واردة إلى موظف محدد.",
  "ccivr.ivr.delete.fail" : "فشل الحذف.",
  "ccivr.ivr.variable.descvalueerror" : "يحتوي الوصف على أحرف خاصة. يرجى تعديله.",
  "ccivr.nms.satisfactionservice.title" : "تكوين الرضا",
  "ccivr.ivr.tracelog.title" : "TraceLog",
  "ccivr.nms.timeselectservice.selectdate" : "التاريخ",
  "ccivr.nms.playcollectservice.collectTimeout.error" : "الفاصل الزمني لمهلة جمع الأرقام غير صالح (1–120).",
  "ccivr.nms.menuservice.contentvalidate" : "بعد اكتمال التحرير، قم بإضافة تكوين المفتاح.",
  "ccivr.nms.process.initialvalue" : "القيمة الأولية",
  "ccivr.whitelist.imordomainname" : "عنوان IP أو اسم المجال",
  "ccivr.ivr.tracelog.addAccessCode" : "إضافة رمز الوصول",
  "ccivr.nms.playcollectservice.ttsvoice" : "TTS",
  "ccivr.nms.playcollectservice.entercollectwaittime" : "أدخل وقت الانتظار لجمع الأرقام.",
  "ccivr.nms.leavemessageservice.agent" : "تحديد عامل للمعالجة",
  "ccivr.nms.ChangeMediaService.label" : "تحديد الوسائط",
  "ccivr.ivr.variable.willdeleteselectvariable" : "هل تريد بالتأكيد حذف المتغيرات المحددة؟",
  "ccivr.nms.playservice.TTS" : "تشغيل صوت TTS",
  "ccivr.ivr.description.variablelimit" : "لا يمكن أن تتجاوز متغيرات القالب 10.",
  "ccivr.ivr.tracelog.transitionOn" : "فرع التصدير",
  "ccivr.ivr.name.notnull" : "لا يمكن ترك الاسم فارغًا.",
  "ccivr.nms.callendservice.checkbox" : "يجب استدعاء الواجهة عند انتهاء المكالمة.",
  "ccivr.ivr.voiceupload.formatwrong" : "تنسيق ملف غير صحيح. استيراد ملف بتنسيق WAV أو MP3.",
  "ccivr.nms.process.basicinformation" : "معلومات أساسية",
  "ccivr.nms.playcollectservice.choosefile" : "صوت",
  "ccivr.ivr.voiceupload.playtype" : "وضع تشغيل الصوت",
  "ccivr.ivr.voice.filechoose" : "تحديد ملف صوتي",
  "ccivr.ivr.voiceedit.editsuccess" : "تم التحرير بنجاح.",
  "ccivr.ivr.voiceupload.file.update.delete" : "حذف",
  "ccivr.ivrflow.specify.accessCode" : "تحديد رمز الوصول إلى القناة",
  "ccivr.ivr.voicereview.refresh" : "تحديث",
  "ccivr.ivr.voiceedit.addfail" : "فشلت الإضافة.",
  "ccivr.nms.tansferservice.estimateTime" : "تقدير الوقت",
  "ccivr.ivr.voicereview.spanish" : "الأسبانية",
  "ccivr.ivr.tracelogprompt" : "ستؤثر هذه العملية على أداء النظام. سيتم حذف هذا التكوين تلقائيًا بعد {0} ساعة. هل تريد المتابعة؟",
  "ccivr.nms.tansferservice.transNumType" : "يمكن أن يكون رقم إعادة التوجيه رقم هاتف محمول أو رقم هاتف خط ثابت أو معلمة مخصصة. Example: {\'$\'}{\'{\'}FLOW.Parameter name{\'}\'}",
  "ccivr.nms.queryservice.paramName" : "طلب معلمة",
  "ccivr.ivr.tracelog.callingNumberplaceHolder" : "الرجاء إدخال رقم الاستدعاء",
  "ccivr.ivr.tracelog.nodeName" : "اسم العنصر",
  "ccivr.whitelist.desc" : "الوصف",
  "ccivr.ivr.voice.fileupload" : "تحميل ملف",
  "ccivr.ivr.tracelog.addcallingnumber" : "إضافة رقم المتصل",
  "ccivr.nms.playcollectservice.mulen" : "الحد الأقصى لعدد الأرقام المستلمة",
  "ccivr.nms.playset.lowspeed" : "منخفض",
  "ccivr.ivr.voicereview.english" : "الإنجليزية",
  "ccivr.ivr.bussinessinvoke.cancel" : "إلغاء",
  "ccivr.nms.process.parameterconfiguration" : "تكوين المعلمة",
  "ccivr.nms.playcollectservice.onenumbers" : "مجموعة متعددة الأرقام",
  "ccivr.nms.interface.whitelistwarn" : "عنوان URL (عنوان IP ورقم المنفذ) غير موجود في قائمة الثقة. لا يمكن استدعاء الواجهة إلا بعد الاتصال بمسؤول النظام لإضافة عنوان URL إلى قائمة الثقة.",
  "ccivr.ivr.voicereview.language7" : "الفرنسية",
  "ccivr.ivr.tracelog.selecttip" : "يرجى تحديد الرقم الطالب!",
  "ccivr.nms.playservice.multivoice.type.time" : "time(hh:mm:ss)",
  "ccivr.ivr.voicereview.language1" : "الإنجليزية",
  "ccivr.ivr.voicereview.language0" : "الصينية",
  "ccivr.flowtest.stoptest" : "توقف",
  "ccivr.ivr.ivrflow.operate.cancelrelease.fail" : "فشل إلغاء الإصدار.",
  "ccivr.ivr.bussinessinvoke.tip" : "اسم المعلمة أو قيمتها ، على سبيل المثال ، 123 أو FLOW.testid.",
  "ccivr.ivr.bussinessinvoke.createinterface" : "إنشاء واجهة",
  "ccivr.ivr.voiceadvice.audition" : "الاختبار",
  "ccivr.nms.playset.sound" : "تأثير الصوت",
  "ccivr.ivrflow.unlock" : "فتح القفل",
  "ccivr.nms.tansferservice.mustbeaccesscode" : "يجب أن يكون الرقم الطالب رمز وصول مخصص من قبل النظام أو معلمة مخصصة. Example: {\'$\'}{\'{\'}FLOW.Parameter name{\'}\'}",
  "ccivr.nms.tansferservice.transferLastAgent.type.currentday" : "اليوم الحالي",
  "ccivr.flowtest.flowtest" : "اختبار التدفق",
  "ccivr.ivr.voiceupload.stoprecording" : "توقف",
  "ccivr.ivrflow.release.formal" : "الإصدار الرسمي",
  "ccivr.nms.assignservice.ietype" : "نوع IE",
  "ccivr.ivr.voiceedit.updatefail" : "فشل التحديث.",
  "ccivr.flowtest.success" : "النجاح",
  "ccivr.nms.leavemessageservice.placeholder" : "ترك رسالة",
  "ccivr.ivr.tracelog.deleteSuccess" : "تم الحذف بنجاح!",
  "ccivr.nms.shortmessageservice.period" : "فترة الرد صالحة (دقائق)",
  "ccivr.ivr.ivrflow.operate.ensurerelease.info" : "هل أنت متأكد من أنك تريد تحرير التدفق؟",
  "ccivr.ivr.ivrflow.type" : "النوع",
  "ccivr.ivr.voiceupload.referenceFlowByNMS" : "مرجع قالب المورد",
  "ccivr.ivr.voiceedit.tip" : "موجه",
  "ccivr.ivr.bussinessinvoke.interfacedesc" : "وصف الواجهة",
  "ccivr.ivr.ivrflow.operate.release.cancelexecptionhanding" : "يرجى إلغاء حالة الإفراج عن العمليات الأخرى تحت المستأجر.",
  "ccivr.nms.LanguageService.placeholder" : "اختر اللغة",
  "ccivr.ivr.description.voiceNotificationlimit" : "لا يمكن أن يحتوي وصف النص على أكثر من 500 بايت.",
  "ccivr.nms.menuservice.voicenotice" : "موجه الصوت",
  "ccivr.ivr.voicereview.filePath" : "مسار الملف:",
  "ccivr.nms.transferservice.choose.recordfilename.info" : "عند استدعاء الواجهة لتنزيل ملفات التسجيل، يتم استخدام المتغير لتخزين القيمة مؤقتًا.",
  "ccivr.ivr.bussinessinvoke.saveserror" : "فشل الحفظ.",
  "ccivr.nms.interface.warn" : "بروتوكول الشبكة المستخدم من قبل الواجهة غير آمن وقد يجلب مخاطر أمنية",
  "ccivr.ivrflow.cancelrelease.referenced.by.satisfaction.survey" : "تمت الإشارة إلى التدفق بواسطة تكوين استطلاع الرضا ولا يمكن حذفه!",
  "ccivr.ivr.bussinessinvoke.interfacetype" : "نوع الواجهة",
  "ccivr.ivr.ivrflow.filtercondition" : "معايير الفلتر",
  "ccivr.ivr.voiceedit.querycallcenterfailed" : "فشل الاستعلام عن معلومات SFTP الخاصة بمركز المكالمات.",
  "ccivr.ivrflow.error.deleteivrflow.association" : "يرتبط التدفق بتدفق آخر ولا يمكن حذفه.",
  "ccivr.nms.playcollectservice.collectTimeout" : "الفاصل الزمني للمهلة لجمع الأرقام",
  "ccivr.ivr.voiceupload.VoiceNotificationTextDes" : "لا يمكن أن يبدأ محتوى القالب بمتغير. يمكن أن يحتوي المتغير على 32 بايت كحد أقصى. إذا كان المتغير يحتوي على أكثر من 32 بايت، ننصحك بتقسيمه إلى متغيرات متعددة.\nالمتغيرات التالية عبارة عن سلسلة supported:\n{\'$\'}{\'{\'}TXT_Digit{\'}\'}: ذات الطول الأقصى المحدد برقم Digit\n{\'$\'}{\'{\'}NUM_Digit{\'}\'}: مع الطول الأقصى المحدد بواسطة الرقم (بالنسبة إلى example, {\'$\'}{\'{\'}NUM_6{\'}\'} يشير إلى رقم بحد أقصى ستة digits.)\n{\'$\'}{\'{\'}DATE{\'}\'}: التاريخ في YYYY/MM/DD يجب أن يفصل بين الوقت في الساعة HH:MM أو HH:MM:SS format\n{\'$\'}{DATE} و {\'$\'}{\'{\'}TIME{\'}\'} بفاصلة (,).",
  "ccivr.ivr.voiceedit.statuserror" : "معلمة الحالة هي خطأ.",
  "ccivr.ivr.voicereview.notpass" : "مرفوض",
  "ccivr.nms.playservice.multivoice.content" : "المحتوى",
  "ccivr.nms.playset.highspeed" : "عالية",
  "ccivr.ivr.voiceupload.referenceText" : "معلومات نص الإشعار الصوتي",
  "ccivr.ivr.bussinessinvoke.savesuccess" : "تم الحفظ بنجاح.",
  "ccivr.nms.varibalesselect.globalvariables" : "المتغير العالمي",
  "ccivr.ivr.transferRecord.endTime" : "وقت الانتهاء",
  "ccivr.ivrflow.grayrule.inputendlength" : "يجب أن يكون طول رقم البداية هو نفسه طول رقم النهاية.",
  "ccivr.ivr.transferRecord.connect.file.notexist" : "فشل تنزيل ملف التسجيل. يرجى التحقق مما إذا كان الملف موجودًا.",
  "ccivr.nms.timeselectservice.friday" : "الجمعة",
  "ccivr.ivr.tracelog.calledNumber" : "الرقم المطلوب",
  "ccivr.ivr.voicereview.passed" : "تمت الموافقة",
  "ccivr.ivr.voiceupload.audiofilereadfailed" : "فشل في قراءة سمات ملف الصوت.",
  "ccivr.nms.assignservice.ieDataAssign" : "تعيين IEData",
  "ccivr.nms.process.customparameter" : "معلمات التدفق",
  "ccivr.ivr.voiceupload.fileinjectionerror" : "تحقق من استثناء مخاطر حقن الملف.",
  "ccivr.ivr.voicereview.language24" : "تايلاند",
  "ccivr.nms.satisfactionservice.singleproblem.menubuttonconfig" : "تكوين زر مسح أحادي السؤال",
  "ccivr.ivr.voicereview.language20" : "الأسبانية",
  "ccivr.ivr.voiceupload.uploadfiletosftpfailed" : "فشل إيداع الملف إلى sftp.",
  "ccivr.nms.shortmessageservice.mspMessage" : "بوابة الرسائل القصيرة",
  "ccivr.ivrflow.operate.ensurereplace" : "تأكيد الاستبدال",
  "ccivr.ivr.variable.string" : "سلسلة",
  "ccivr.ivr.voiceedit.filenamenull" : "محتوى اسم الملف خالٍ.",
  "ccivr.nms.playcollectservice.required" : "حقل إلزامي",
  "ccivr.nms.tansferservice.transferThirdParty.recordingSettings" : "إعداد التسجيل",
  "ccivr.nms.playset.intermediatespeed" : "متوسط",
  "ccivr.ivr.tracelog.deleteconfirminfo" : "ما إذا كان سيتم حذف الرقم الطالب الحالي:",
  "ccivr.ivr.fail" : "فشل",
  "ccivr.ivr.voiceupload.delete" : "حذف",
  "ccivr.ivr.ivrflow.filter" : "فلتر",
  "ccivr.ivr.voicereview.language17" : "البرتغالية",
  "ccivr.ivr.transferRecord.callNumber" : "الرقم المتصل",
  "ccivr.nms.tansferservice.transferLastAgent.type" : "وضع الموظف الأخير",
  "ccivr.ivr.flowAccessCode.repeat" : "رمز الوصول موجود بالفعل!",
  "ccivr.nms.playcollectservice.failtitle" : "فشل تشغيل الصوت لتجميع الأرقام.",
  "ccivr.ivr.bussinessinvoke.basicconfig" : "التكوين الأساسي",
  "ccivr.nms.label.certCode" : "رمز الشهادة",
  "ccivr.nms.ringService.sendrecv" : "ارسال استقبال",
  "ccivr.nms.timeselectservice.wednesday" : "الأربعاء",
  "ccivr.ivr.tracelog.result.recordend" : "نجاح التسجيل",
  "ccivr.ivrflow.variable.settings" : "إعدادات متغيرة",
  "ccivr.ivr.tracelog.deleteconfirminfocode" : "ما إذا كان سيتم حذف رمز الوصول الحالي:",
  "ccivr.nms.tansferservice.transferLastAgent" : "ضيوف الصوت والفيديو المألوفين",
  "ccivr.flowtest.flowname" : "الاسم",
  "ccivr.ivr.ivrflow.operate.gateway.pulishrepeat" : "اسم العملية مكرر أو تم إصدار إصدار.",
  "ccivr.ivr.ivrflow.version" : "رقم الإصدار",
  "ccivr.ivr.voiceupload.english" : "الإنجليزية",
  "ccivr.nms.add" : "جديد",
  "ccivr.nms.playservice.chosetts.survey" : "حدد نص TTS تم تحميله واعتماده، أو أضف نص TTS جديد. يجب أن تكون موارد TTS متاحة.",
  "ccivr.ivr.transferRecord.fail" : "فشل",
  "ccivr.nms.playservice.chosemultivoice" : "قم بتعيين المحتوى الذي سيتم تشغيله من التحديدات التالية",
  "ccivr.ivr.tracelog.addSuccess" : "تمت الإضافة بنجاح!",
  "ccivr.flowtest.presskey" : "الأزرار",
  "ccivr.nms.label.certScene" : "سيناريو التطبيق",
  "ccivr.ivr.tracelog.selecttipcode" : "يرجى تحديد رمز الوصول!",
  "ccivr.ivrflow.ivrflowname.specialStrValidateFailed128" : "لا يمكن أن تحتوي القيمة على أحرف خاصة ولا يمكن أن يتجاوز الطول 128 حرفًا.",
  "ccivr.nms.queryservice.specifiedSkill" : "اسم قائمة الانتظار",
  "ccivr.ivr.voice.exception.recordfailmessage" : "خطأ في التسجيل. تحقق مما إذا كان لديك إذن الميكروفون.",
  "ccivr.ivrflow.updateivrinfo.success" : "تم تعديل سمات تدفق IVR بنجاح.",
  "ccivr.ivr.voiceupload.VoiceNotificationtipmodel" : "يمكنك تعيين ملفات صوتية أو نص بلغات مختلفة في نفس الوقت (لا يمكن أن يتجاوز الحد الأقصى للطول 500 بايت). أثناء تشغيل الصوت ، يقوم النظام تلقائيًا بتشغيل ملف الصوت المقابل استنادًا إلى اللغة المحددة من قبل المستخدم.",
  "ccivr.nms.businessinvokeservice.second" : "ثانياً",
  "ccivr.ivr.tracelog.index" : "الفهرس",
  "ccivr.nms.tansferservice.operat" : "العملية",
  "ccivr.ivr.voice.recordtimeout.failmes" : "أقصى مدة للتسجيل هي دقيقتان. سجل مرة أخرى.",
  "ccivr.nms.tansferservice.transferZNIvrCode" : "نقل إلى IVR الذكي",
  "ccivr.nms.process.saveerror.noresult" : "ليس كياناً.",
  "ccivr.nms.queryservice.specialList.flag" : "علم الوجود في القائمة الخاصة",
  "ccivr.nms.leavemessageservice.saveleavemessage" : "تسجيل معلومات الرسالة.",
  "ccivr.nms.ringService.placeholder" : "الرنين",
  "ccivr.nms.playservice.multivoice.language9" : "اليونانية",
  "ccivr.flowtest.platform" : "منصة",
  "ccivr.nms.playservice.multivoice.language8" : "الألمانية",
  "ccivr.ivrflow.grayrule.grayinfo" : "إذا كان الرقم يطابق قاعدة رمادية، يتم تنفيذ تدفق هذا الإصدار.<br /> بالنسبة للأرقام التي لا تتطابق مع القواعد الرمادية، يتم تنفيذ التدفق الذي تم إصداره رسميًا.<br /> إذا لم يكن هناك تدفق تم إصداره رسميًا، يفشل التنفيذ.",
  "ccivr.nms.playservice.multivoice.language7" : "الفرنسية",
  "ccivr.nms.playservice.multivoice.language6" : "الفنلندية",
  "ccivr.nms.playservice.multivoice.language5" : "الهولندية",
  "ccivr.nms.playservice.multivoice.language4" : "الدنماركية",
  "ccivr.nms.playservice.multivoice.language3" : "التشيكية",
  "ccivr.nms.userLevelService.label" : "مستوى المستخدم",
  "ccivr.nms.playservice.multivoice.language2" : "الصينية (الكانتونية)",
  "ccivr.nms.playservice.multivoice.language1" : "الإنجليزية",
  "ccivr.nms.playservice.multivoice.language0" : "بوتونغهوا",
  "ccivr.nms.playcollectservice.interrupt" : "اضغط على أي مفتاح لمقاطعة التشغيل.",
  "ccivr.ivr.description.limit" : "لا يمكن أن يحتوي وصف النص على أكثر من 1024 بايت.",
  "ccivr.ivr.voice.vediochoose" : "تحديد ملف فيديو",
  "ccivr.ivr.variable.name" : "متغير",
  "ccivr.nms.tansferservice.delete" : "حذف",
  "ccivr.ivr.changtovoice" : "TTS",
  "ccivr.ivr.voicereview.french" : "الفرنسية",
  "ccivr.ivr.voiceupload.isconfdelete" : "هل تريد بالتأكيد حذفه؟",
  "ccivr.ivr.ivrflow.clone" : "الترقية",
  "ccivr.nms.callendservice.title" : "نهاية",
  "ccivr.ivr.variable.nameisnull" : "الاسم فارغ.",
  "ccivr.nms.playcollectservice.chooseparam" : "حدد معلمة لحفظ إدخال المستخدم.",
  "ccivr.nms.leavemessageservice.choose.agent.sample" : "مثال: 101 أو ${FLOW.workNo}",
  "ccivr.ivr.voiceupload.voiceUsageBySkill" : "مرجع قائمة انتظار المهارات",
  "ccivr.nms.ringService.recvonly" : "تلقي فقط",
  "ccivr.ivr.transferRecord.connect.uap.failed" : "فشل الاتصال بخادم الملفات",
  "ccivr.ivr.voice.scene.voicenotification" : "إشعار صوتي",
  "ccivr.nms.process.parametertype" : "نوع المعلمة",
  "ccivr.ivrflow.createivrflow.fail" : "فشلت الإضافة.",
  "ccivr.nms.process.isParameterisEncrypt" : "مشفرة أم لا",
  "ccivr.nms.playcollectservice.entermaxfailtimes" : "أدخل الحد الأقصى لعدد مرات الفشل.",
  "ccivr.nms.businessinvokeservice.paramname" : "الاسم",
  "ccivr.ivr.tracelog.callingNumber" : "الرقم المتصل",
  "ccivr.nms.playset.close" : "معطل",
  "ccivr.nms.tansferservice.transferLastAgent.type.time.info" : "يتم تحويل المكالمة إلى الموظف الذي تم الاتصال به ضمن نطاق زمني.",
  "ccivr.nms.queryservice.queryParam" : "طلب معلمة",
  "ccivr.nms.varibalesselect.processvaribales" : "متغير التدفق",
  "ccivr.nms.conditionService.moduleDescription" : "وصف الوحدة",
  "ccivr.ivr.voicereview.reset" : "إعادة الضبط",
  "ccivr.ivr.voicereview.choosetenantspace" : "اختر مستأجرًا.",
  "ccivr.nms.queryservice.video" : "فيديو",
  "ccivr.nms.process.String" : "سلسلة الحرف",
  "ccivr.nms.tansferservice.skillId" : "معرف المهارة",
  "ccivr.ivr.textcontent.notnull" : "لا يمكن أن تكون معلومات النص فارغة.",
  "ccivr.nms.varibalesselect.selectvariables" : "تحديد متغير",
  "ccivr.ivr.voicereview.textContent" : "محتوى النص",
  "ccivr.nms.queryservice.querySkillMessage" : "الاستعلام عن المعلومات",
  "ccivr.ivr.variable.idisnull" : "المعرف فارغ.",
  "ccivr.ivrflow.createivrflow.mainfail" : "التدفق الرئيسي موجود بالفعل. فشلت الإضافة.",
  "ccivr.ivrflow.variable.selectvariable" : "يرجى تحديد معلمة متغير.",
  "ccivr.ivr.error.paramNumlimit" : "لا يمكن أن يتجاوز إجمالي عدد المعلمة 16.",
  "ccivr.ivr.ivrtextexception.fail" : "فشلت إضافة نص IVR.",
  "ccivr.ivr.voiceupload.fileioerror" : "استثناء ملف IO.",
  "ccivr.ivrflow.unlock.success" : "نجحت حالة إلغاء القفل",
  "ccivr.ivr.voiceedit.deleteodfsivrfail" : "فشل الحذف. بيانات IVR مشغولة!",
  "ccivr.nms.playset.extendparam" : "معلمة TTS الموسعة",
  "ccivr.flowtest.operatehangup" : "شنق",
  "ccivr.nms.tansferservice.transferThirdParty.enableRecording" : "التسجيل",
  "ccivr.ivr.ivrflow.new" : "جديد",
  "ccivr.ivr.ivrflow.operate.ensurecancelrelease" : "تأكيد إلغاء الإصدار",
  "ccivr.transferrecord.play.timeCompare" : "لا يمكن أن يكون وقت البدء بعد وقت الانتهاء.",
  "ccivr.nms.answerService.placeholder" : "الجواب",
  "ccivr.ivr.type.fail" : "نوع الإيداع غير صحيح.",
  "ccivr.ivr.voiceupload.selectLangRe" : "تم تحديد اللغة الحالية. يرجى اختيار لغة أخرى.",
  "ccivr.nms.process.saveerror.unSucctransfer" : "فشل الحفظ. يحتوي عنصر مخطط النقل على خط نجاح النقل.",
  "ccivr.nms.process.interfaceerr" : "خطأ في الواجهة. يرجى التحقق من السجل.",
  "ccivr.ivr.voiceupload.query" : "التفاصيل",
  "ccivr.nms.process.processinformation" : "معلومات التدفق",
  "ccivr.ivrflow.updateivrinfo.released" : "تم الإفراج عن التدفق. قم بإلغاء الإصدار وتعديل السمات.",
  "ccivr.nms.playcollectservice.second" : "الثانية",
  "ccivr.nms.timeselectservice.timeperiod" : "الفترة الزمنية",
  "ccivr.ivrflow.variable.typeerror" : "أنواع متغيرات الإدخال غير المتسقة.",
  "ccivr.ivr.tracelog.accessCode" : "رمز وصول التدفق",
  "ccivr.nms.tansferservice.idleAgentCount" : "عدد الموظفين الخاملين",
  "ccivr.ivr.variable.defaulttypeerror" : "لا يتطابق نوع القيمة الافتراضية مع نوع المتغير.",
  "ccivr.nms.conditionService.logicAND" : "و",
  "ccivr.ivr.tracelog.result.transsuccess" : "ناجح",
  "ccivr.ivr.ivrflow.property" : "السمة",
  "ccivr.ivr.tracelog.actionParams" : "المعلمات الرئيسية للخلية",
  "ccivr.ivr.ivrflow.ivrtype" : "النوع",
  "ccivr.ivr.voiceupload.file.update.operation" : "العملية",
  "ccivr.nms.queryservice.info" : "يتم الاستعلام عن معلومات قائمة الانتظار باستخدام تدفق IVR عند تحويل مكالمة إلى قائمة انتظار مهارة.",
  "ccivr.ivr.error.uncommitted" : "لا يمكن لمسؤول النظام تشغيل الملف الذي لم يتم إرساله.",
  "ccivr.nms.timeselectservice.sunday" : "الأحد",
  "ccivr.nms.leavemessageservice.end" : "بشكل افتراضي ، يتم إنهاء التسجيل بمفتاح الجنيه (#).",
  "ccivr.ivr.ivrflow.operate.cancelrelease.success" : "تم الإلغاء بنجاح.",
  "ccivr.ivr.voiceedit.deleteoldfilefailed" : "فشل حذف الملف القديم.",
  "ccivr.ivr.ivrflow.selectivrflow" : "حدد تدفق IVR.",
  "ccivr.ivr.voice.volume" : "حجم",
  "ccivr.ivr.voice.clicktorecord" : "انقر فوق سجل",
  "ccivr.nms.tansferservice.transferWorkNo" : "التحويل إلى الوكيل",
  "ccivr.ivr.transferRecord.transNumber" : "رقم إعادة التوجيه",
  "ccivr.nms.shortmessageservice.processInformation" : "رسالة نصية",
  "ccivr.ivr.name.existence" : "الاسم موجود بالفعل. الرجاء إدخال واحدة أخرى.",
  "ccivr.nms.process.parameterencryptips" : "لاحظ أن المعلمات غير المشفرة تخزن قيم المعلمات في نص عادي، والتي قد تشكل مخاطر أمنية إذا تم استخدامها.",
  "ccivr.nms.leavemessageservice.choose" : "تحديد",
  "ccivr.ivr.voiceadvice.cancel" : "إلغاء",
  "ccivr.nms.menuservice.title" : "تكوين القائمة",
  "ccivr.ivr.ivrflow.operate.release.needexecptionhanding" : "يُرجى تكوين عملية معالجة استثناء وإصدارها رسميًا.",
  "ccivr.ivr.tracelog.result.playover" : "نجاح الصوت",
  "ccivr.nms.tansferservice.moduleDescription" : "وصف الوحدة",
  "ccivr.nms.timeselectservice.thursday" : "الخميس",
  "ccivr.nms.conditionService.parameterEmpty" : "المعلمة 1 فارغة.",
  "ccivr.nms.queryservice.paramValue" : "القيمة",
  "ccivr.ivr.voiceupload.inputlanguageerror" : "اللغة غير موجودة!",
  "ccivr.ivr.voice.clicktostop" : "انقر فوق إيقاف",
  "ccivr.nms.playcollectservice.title" : "تشغيل الصوت وجمع الأرقام",
  "ccivr.nms.tansferservice.transCallingnum" : "رقم المتصل",
  "ccivr.ivr.ivrflow.state" : "الحالة",
  "ccivr.ivr.voiceupload.defaultNoDe" : "لا يمكن حذف اللغة الافتراضية.",
  "ccivr.ivr.create.exception" : "فشلت الإضافة.",
  "ccivr.ivr.error.other" : "فشل التنزيل.",
  "ccivr.ivr.tracelog.cellType" : "نوع العنصر",
  "ccivr.ivr.voiceupload.tipformat" : "يتم دعم تنسيقات MP3 و WAV ، ولا يمكن أن يتجاوز حجم الملف 12 ميغابايت.",
  "ccivr.ivr.variable.int" : "عدد صحيح",
  "ccivr.ivr.voiceupload.new" : "جديد",
  "ccivr.ivr.voiceupload.choosestatus" : "حدد حالة المراجعة.",
  "ccivr.ivrflow.grayrule.editsuccess" : "تم التعديل بنجاح.",
  "ccivr.nms.businessinvokeservice.inpuparam" : "معلمة الإدخال",
  "ccivr.ivr.transferRecord.recordedFile" : "ملف التسجيل",
  "ccivr.ivr.bussinessinvoke.bussinessinvoke" : "تكوين الواجهة",
  "ccivr.nms.brach.label" : "تحديد شرط",
  "ccivr.ivr.voiceedit.pathcheckfailed" : "فشل التحقق من صحة معلومات مسار SFTP.",
  "ccivr.ivr.ivrflow.placeholder.ivrname" : "أدخل اسم تدفق.",
  "ccivr.flowtest.enter.phone" : "أدخل رقم الهاتف.",
  "ccivr.ivrflow.operate.replace.fail" : "فشل الاستبدال.",
  "ccivr.ivr.voicereview.filter" : "فلتر",
  "ccivr.flowtest.test.enter.callingnumber" : "يُرجى إدخال الرقم الطالب أولاً!",
  "ccivr.nms.tansferservice.sampleForCallingnum" : "مثال: 660001 أو {\'$\'}{\'{\'}FLOW.accessCode{\'}\'}",
  "ccivr.ivr.voiceupload.tipmodel" : "يمكنك تعيين ملفات صوتية أو نص بلغات مختلفة في نفس الوقت (لا يمكن أن يتجاوز الحد الأقصى للطول 1024 بايت). أثناء تشغيل الصوت ، يقوم النظام تلقائيًا بتشغيل ملف الصوت المقابل استنادًا إلى اللغة المحددة من قبل المستخدم.",
  "ccivr.ivrflow.ivrflowtype.validateFailed" : "نوع التدفق غير صحيح.",
  "ccivr.nms.satisfactionservice.tip" : "يرجى تكوين مستوى الرضا في قائمة تكوين الرضا. إذا تم إصدار التدفق ، فيمكنك ترقية التدفق وتحريره وحفظه وإطلاقه مرة أخرى لاستبدال التدفق الأصلي لاستخدام مستوى الرضا المحدث.",
  "ccivr.ivr.voiceedit.passwdisnull" : "فشل الاستعلام عن معلومات SFTP passewd الخاصة بمركز المكالمات.",
  "ccivr.common.warning.message" : "حدد سجلاً.",
  "ccivr.nms.conditionService.conditionalEdit" : "تحرير الشرط",
  "ccivr.ivr.ivrflow.operate.ensureclone" : "تأكيد ترقية التدفق",
  "ccivr.nms.LanguageService.label" : "اللغة",
  "ccivr.nms.tansferservice.transferLastAgent.mustbeaccesscode" : "الرقم الطالب هو الرقم الوارد للعميل، لـ example, {\'$\'}{\'{\'}SYS.callingNumber{\'}\'}.",
  "ccivr.ivrflow.grayrule.existgrayflow" : "إصدار الإصدار الرمادي موجود بالفعل!",
  "ccivr.ivr.voiceadvice.lengthError" : "لا يمكن أن يتجاوز الطول 100 حرف.",
  "ccivr.nms.satisfactionservice.descripLength" : "لا يتجاوز الوصف الرئيسي لمسح الرضا 200",
  "ccivr.flowtest.operatesuccess" : "النجاح",
  "ccivr.nms.playservice.chose.survey" : "حدد ملف صوتي تم تحميله والموافقة عليه، أو أضف ملف صوتي جديد.",
  "ccivr.nms.interface.paramKeyValidate" : "لا تستخدم اسم المعلمة المعرّف بواسطة المتغير المضمن (callId، businessReqId، callSorId، currentTime). وإلا، يصبح تعيين القيمة غير صالح.",
  "ccivr.ivrflow.ivrflowname.specialStrValidateFailed" : "لا يمكن أن تحتوي القيمة على أحرف خاصة ولا يمكن أن يتجاوز الطول 50 حرفًا.",
  "ccivr.ivr.voiceupload.file.update.submit" : "إرسال",
  "ccivr.ivr.element.namecheck" : "يحتوي الاسم على أحرف خاصة. يرجى تعديله.",
  "ccivr.nms.interface.outparamvalidate" : "يمكن أن تحتوي قيمة المعلمة على أرقام وحروف فقط والأحرف الخاصة التالية:._-",
  "ccivr.nms.interface.paramvalidate" : "يجب أن تحتوي المعلمات فقط على أرقام أو أحرف إنجليزية أو _-",
  "ccivr.ivr.transferRecord.play" : "الصوت",
  "ccivr.ivr.voiceupload.texttovoice" : "يتم تحويل النص الذي تم إدخاله تلقائيًا إلى صوت.",
  "ccivr.ivr.voiceupload.amountLimit5" : "يمكن إضافة خمسة ملفات صوتية كحد أقصى في هذا السيناريو.",
  "ccivr.ivr.voicereview.upload" : "تم التحميل",
  "ccivr.nms.shortmessageservice.sendType" : "وضع إرسال الرسائل",
  "ccivr.nms.playcollectservice.receivednum" : "الأرقام المستلمة",
  "ccivr.nms.brach" : "فرع",
  "ccivr.ivr.ivrflow.operate.queryInfo" : "عرض",
  "ccivr.ivr.voiceupload.exceedLimit" : "يتجاوز عدد ملفات IVR الحد.",
  "ccivr.nms.singleproblem.title" : "دراسة استقصائية ذات قضية واحدة",
  "ccivr.nms.tansferservice.transInputWorkNo" : "أدخل معرف الموظف الذي تم تحويل المكالمة إليه.",
  "ccivr.nms.satisfactionservice.type" : "نوع استبيان الرضا",
  "ccivr.ivr.ivrflow.yes" : "نعم",
  "ccivr.transferrecord.play.record" : "تشغيل تسجيلات نقل العملية:",
  "ccivr.nms.assignservice.Dataassignment" : "تخصيص البيانات",
  "ccivr.ivr.error.download" : "فشل تنزيل الملف من خادم SFTP.",
  "ccivr.ivr.voice.scene.afterReportWorkNo" : "بعد الإبلاغ عن هوية الموظف",
  "ccivr.ivr.voicereview.tenantspace" : "مستأجر المنزل",
  "ccivr.ivr.voiceupload.audiochannelfailed" : "ملف الصوت ليس ملف صوت أحادي.",
  "ccivr.ivr.error.overpower" : "المستأجر غير مصرح به.",
  "ccivr.nms.process.success" : "تم حفظ التدفق بنجاح.",
  "ccivr.ivr.voiceedit.inputmore" : "لا يمكن أن تتجاوز القيمة",
  "ccivr.nms.shortmessageservice.selectTemplate" : "تحديد قالب",
  "ccivr.whitelist.whiteport" : "منفذ قائمة الثقة",
  "ccivr.ivr.voiceadvice.setdefault" : "تعيين كافتراضي",
  "ccivr.nms.label.selectCert" : "تحديد شهادة",
  "ccivr.nms.leavemessageservice.recordparam" : "معلمة التسجيل",
  "ccivr.ivr.variable.namevaluerule" : "يحتوي الاسم على أحرف خاصة. يرجى تعديله.",
  "ccivr.ivrflow.grayrule.selectgrayrule" : "حدد قاعدة رمادية.",
  "ccivr.nms.leavemessageservice.leavemessage" : "ترك رسالة",
  "ccivr.nms.conditionService.sample" : "أدخل تعبير شرط. يمكن أن يحتوي التعبير على اسم المعلمة\\ وvalue\\ والعملية المعقدة. تتم الإشارة إلى المعلمة في تنسيق اسم FLOW.Parameter. مثال: SYS.currentNumber==FLOW.RechargeNumber&& (FLOW.AccountBalance - 20)>=FLOW.BillAmount",
  "ccivr.nms.playcollectservice.enterCollectTimeout" : "الرجاء إدخال الفاصل الزمني لمهلة مجموعة الأرقام.",
  "ccivr.nms.tansferservice.passingParameters" : "معلمات النقل",
  "ccivr.ivr.transferRecord.startTime" : "وقت البدء",
  "ccivr.ivr.voiceadvice.submit" : "إرسال",
  "ccivr.nms.interface.certFile" : "ملف الشهادة",
  "ccivr.ivr.ivrflow.operate" : "العملية",
  "ccivr.ivr.transferRecord.success" : "النجاح",
  "ccivr.ivrflow.grayrule.endnum" : "رقم النهاية",
  "ccivr.ivrflow.release.formalinfo" : "بعد الإصدار ، يتم تنفيذ تدفق هذا الإصدار لجميع الأرقام. <br />إذا كنت بحاجة إلى تعديل التدفق، فأنت بحاجة إلى ترقية إصدار جديد وتعديل الإصدار.",
  "ccivr.ivr.voiceupload.queryReferenceFlow" : "عرض",
  "ccivr.ivrflow.grayrule.inputbeginnum" : "مثال: 12366668888",
  "ccivr.nms.conditionService.add" : "جديد",
  "ccivr.nms.conditionService.createCondition" : "إضافة شرط",
  "ccivr.ivr.ivrflow.operate.clone.success" : "تمت عملية الترقية بنجاح.",
  "ccivr.nms.shortmessageservice.normalMessage" : "رسالة قصيرة",
  "ccivr.ivr.bussinessinvoke.float" : "العائمة",
  "ccivr.nms.leavemessageservice.choose.localtionID" : "تحديد وحفظ متغير localtionID",
  "ccivr.ivr.voicereview.play" : "الصوت",
  "ccivr.nms.queryservice.estimateTime.prompte" : "يتم حساب وقت الانتظار المتوقع لقائمة الانتظار استنادًا إلى نموذج قائمة الانتظار والنتيجة بها بعض الانحراف. يرجى استخدامها بشكل صحيح.",
  "ccivr.flowtest.fail" : "فشل",
  "ccivr.ivr.description.containspecialcharacter" : "يحتوي القالب على رموز خاصة من نوع ‪‬الرمز‪‬",
  "ccivr.ivr.voiceupload.vedionamewrong" : "فشل التحقق من تحميل الملف FileNmae!",
  "ccivr.nms.queryservice.specifiedSkill.paramValue" : "معرف الموظف",
  "ccivr.ivr.variable.management" : "إدارة المتغيرات",
  "ccivr.nms.playcollectservice.Receivemaxnum" : "الحد الأقصى لعدد الأرقام المستلمة",
  "ccivr.ivr.ivrflow.operate.release" : "الافراج",
  "ccivr.ivrflow.error.deleteivrflow.part.released" : "تم إصدار بعض تدفقات IVR المحددة. قم بإلغاء الإصدار قبل حذفها.",
  "ccivr.ivr.name.errorspecial" : "يحتوي الاسم على أحرف خاصة. يرجى تعديله.",
  "ccivr.ivr.voiceupload.tipformat1M" : "يتم دعم تنسيقات MP3 و WAV ، ولا يمكن أن يتجاوز حجم الملف 1 ميغابايت.",
  "ccivr.ivr.tracelog.result.syserr" : "خطأ في النظام",
  "ccivr.ivr.ivrflow.operate.delete" : "حذف",
  "ccivr.nms.playservice.chose" : "يتم تحديد محتوى التشغيل من الملفات الصوتية التي تم تحميلها ومراجعتها.",
  "ccivr.ivr.variable.variableisreferenced" : "تتم الإشارة إلى المتغيرات بواسطة التدفق.",
  "ccivr.ivr.bussinessinvoke.save" : "حفظ",
  "ccivr.ivr.transferRecord.transResult" : "نتيجة التحويل",
  "ccivr.nms.playcollectservice.tip8" : "أثناء جمع الأرقام، يقوم المستخدم بإدخال مفتاح الجنيه (#) لإنهاء جمع الأرقام. يرجى المطالبة في الصوت.",
  "ccivr.ivr.message.uploadimport.success" : "تمت الإضافة بنجاح.",
  "ccivr.nms.playcollectservice.tip7" : "عندما يتجاوز العدد المتراكم من مرات الفشل الحد الأقصى، يتم تنفيذ مخرج الفشل العام.",
  "ccivr.ivr.variable.textvaluelimit" : "لا يمكن أن يحتوي النص على أكثر من 120 حرفًا.",
  "ccivr.nms.playcollectservice.tip6" : "إذا فشلت مجموعة الأرقام، فلن تحتاج إلى تشغيل الصوت. في حالة عدم تحديد ملف صوتي، يقوم النظام بتشغيل المطالبة الصوتية مرة أخرى بعد الفشل.",
  "ccivr.nms.playcollectservice.tip5" : "في حالة حدوث خطأ أثناء جمع الأرقام، يسجل النظام فشلًا، ويقوم تلقائيًا بتنفيذ خطأ في جمع الأرقام، ويستمر في انتظار جمع الأرقام.",
  "ccivr.whitelist.whitelistmanage" : "إدارة قائمة الثقة",
  "ccivr.nms.playcollectservice.tip4" : "إذا تجاوز الوقت الذي يقوم فيه المشترك بإيقاف الإدخال وقت انتظار مجموعة الأرقام التي تم تكوينها مسبقًا، فسيقوم النظام بتسجيل الفشل. بالإضافة إلى ذلك، يقوم النظام تلقائيًا بتنفيذ مهلة تجميع الأرقام وينتظر جمع الأرقام.",
  "ccivr.nms.playcollectservice.tip3" : "أثناء جمع الأرقام، يقوم المستخدم بإدخال مفتاح الجنيه (#) لإنهاء جمع الأرقام. يرجى المطالبة في الصوت. (لا تحتاج إلى الضغط على مفتاح الجنيه (#) لجمع الأرقام في عنصر مخطط تكوين القائمة)",
  "ccivr.nms.playcollectservice.tip2" : "- يرجى تحديد معلمة نوع السلسلة التي أضفتها في معلومات العملية.",
  "ccivr.nms.playcollectservice.tip1" : "حدد معلمة لحفظ إدخال المستخدم.",
  "ccivr.flowtest.begintest" : "ابدأ",
  "ccivr.nms.process.paramnameNoCN" : "الـ paramname خاطئ.",
  "ccivr.nms.menuservice.button" : "الأزرار",
  "ccivr.ivr.ivrflow.operate.edit" : "تحرير",
  "ccivr.ivr.bussinessinvoke.error" : "خطأ",
  "ccivr.ivr.voiceupload.submit": "إرسال",
  "ccivr.TimeSelectService.branch.wrongtime": "وقت خاطئ",
  "ccivr.ivr.voiceupload.onlyOneData": "احتفظ بسجل لغة واحد على الأقل.",
  "ccivr.ivrflow.operate.replace.success": "تم الاستبدال بنجاح.",
  "ccivr.ivr.voiceupload.audiosamplebitfailed": "عدد وحدات البت النموذجية لملف الصوت ليس 8.",
  "ccivr.nms.playservice.multivoice.type.date": "التاريخ (yyyymmdd)",
  "ccivr.ivr.name.toolong": "يتجاوز الاسم الحد الأقصى للطول المسموح به. يرجى المحاولة مرة أخرى.",
  "ccivr.ivr.voicereview.all": "الكل",
  "ccivr.ivrvoice.play.voicetimelimit": "صوت الاختبار طويل، قم بتقليل المحتوى النصي وحاول مرة أخرى.",
  "ccivr.nms.leavemessageservice.recordfile": "ملف التسجيل",
  "ccivr.nms.conditionService.logicOperator": "منطقي",
  "ccivr.nms.tansferservice.queueindex": "موقف قائمة الانتظار",
  "ccivr.ivr.voicereview.filetype": "النوع",
  "ccivr.ivr.tracelog.result.hangup": "اغلاق",
  "ccivr.ivr.voiceupload.voiceUsageByTenant": "مرجع سيناريو المستأجر",
  "ccivr.ivr.tracelog.traceConfig": "تكوين تتبع رمز الوصول",
  "ccivr.ivr.variable.deleteselectvariable": "حذف المتغير",
  "ccivr.ivr.ivrflow.operate.create.accesscode.fail": "فشل إضافة رمز الوصول في ODFS أثناء تحرير تدفق IVR.",
  "ccivr.nms.playcollectservice.voicefile": "ملف صوتي",
  "ccivr.nms.playcollectservice.playset.reset": "إعادة الضبط",
  "ccivr.nms.conditionService.condition": "المعلمة",
  "ccivr.nms.tansferservice.queuenum" : "عدد قوائم الانتظار",
  "ccivr.ivr.voice.speed" : "السرعة",
  "ccivr.nms.questionnaireService.placeholder" : "استبيان",
  "ccivr.ivr.voiceupload.chinese" : "الصينية",
  "ccivr.ivr.ivrflow.edit" : "التحرير",
  "ccivr.ivr.voiceadvice.confirm" : "حسناً",
  "ccivr.nms.process.sysparam" : "معلمات النظام",
  "ccivr.nms.conditionService.operat" : "العملية",
  "ccivr.grayrule.errorinfo.containspecialcha" : "تحتوي قاعدة ruleName على حرف خاص",
  "ccivr.nms.ChangeMediaService.placeholder" : "تغيير الوسائط",
  "ccivr.ivrflow.error.deleteivrflow.released" : "تم تحرير تدفق IVR المحدد. قم بإلغاء الإصدار قبل حذفه.",
  "ccivr.ivr.voicereview.refuse" : "رفض",
  "ccivr.ivrflow.cancelrelease.accesscode.delete.failed" : "فشل حذف رمز الوصول في ODFS أثناء إلغاء تدفق IVR الذي تم تحريره.",
  "ccivr.nms.leavemessageservice.flownamerepeat" : "لا يمكن لاسم ملف التسجيل ومعرف localtionID استخدام نفس المتغير.",
  "ccivr.ivr.bussinessinvoke.validatemessage" : "توجد بالفعل معلمة بنفس الاسم.",
  "ccivr.ivr.voiceupload.file.update.comments" : "التعليقات",
  "ccivr.nms.playservice.interrupt" : "اضغط على أي مفتاح لمقاطعة التشغيل.",
  "ccivr.nms.timeselectservice.title" : "تحديد الوقت",
  "ccivr.nms.queryservice.specifiedSkill.result" : "اسم قائمة الانتظار",
  "ccivr.ivr.voiceupload.selectvideo" : "حدد ملف فيديو.",
  "ccivr.nms.process.saveerror.connection" : "فشل الحفظ. توجد أسطر غير صالحة.",
  "ccivr.nms.questionnaireService.surveyname" : "اسم الاستبيان",
  "ccivr.nms.playcollectservice.collectwaittime" : "وقت الانتظار لجمع الأرقام",
  "ccivr.ivr.illegal.fail" : "معلمة غير صالحة.",
  "ccivr.nms.conditionService.delete" : "حذف",
  "ccivr.nms.process.saveerror.select" : "حدد نوع تغيير.",
  "ccivr.ivr.voiceadvice.currentdefault" : "افتراضي",
  "ccivr.nms.playservice.chosevideo" : "يتم تحديد المحتوى المراد تشغيله من مقاطع الفيديو التي تم تحميلها ومراجعتها.",
  "ccivr.ivr.ivrflow.flowquote" : "هو المشار إليها",
  "ccivr.nms.queryservice.skillMsg" : "معلومات قائمة الانتظار",
  "ccivr.ivr.variable.specialcharacters" : "لا يُسمح بالرموز الخاصة أو الصينية.",
  "ccivr.ivr.voicereview.waitreview" : "للمراجعة",
  "ccivr.ivr.voice.exception.recordfail" : "خطأ في التسجيل.",
  "ccivr.ivr.error.advicelimit" : "لا يمكن أن يحتوي تعليق المراجعة على أكثر من 100 حرف.",
  "ccivr.nms.businessinvokeservice.selectservice" : "تحديد الواجهة",
  "ccivr.nms.playservice.multivoice.language81" : "العربية",
  "ccivr.ivr.voicepop.inputname" : "أدخل اسم الصوت IVR.",
  "ccivr.nms.queryservice.assignmentParameters" : "المعلمة التي سيتم تعيينها",
  "ccivr.whitelist.validator.ip" : "عنوان IP غير صالح. (أدخل عنوان IPv4 أو IPv6 أو اسم مجال.)",
  "ccivr.ivr.voicereview.chinese" : "الصينية",
  "ccivr.ivrflow.unlock.fail" : "فشل حالة إلغاء القفل",
  "ccivr.ivr.tracelog.callid" : "كاليد",
  "ccivr.ivr.variable.modifyvariable" : "تعديل المتغير",
  "ccivr.ivr.pageurls.errorsave" : "خطأ",
  "ccivr.nms.queryservice.specialList.flagInfo" : "ما إذا كان رقم المشترك في القائمة الخاصة. الخيارات هي 0 (لا) و 1 (نعم).",
  "ccivr.nms.tansferservice.transferLastAgent.type.time.label" : "النطاق الزمني (دقيقة)",
  "ccivr.nms.shortmessageservice.smsType" : "قناة",
  "ccivr.ivr.ivrflow.operate.ensureclone.info" : "هل تريد بالتأكيد ترقية التدفق؟",
  "ccivr.nms.transInputWorkNo.sample" : "مثال: 28956666 أو {\'$\'}{\'{\'}FLOW.currentWorkNo{\'}\'}",
  "ccivr.nms.shortmessageservice.addresseeDesc" : "اختياري. إذا كان فارغًا ، فسيتم إرساله إلى المتصل.",
  "ccivr.flowtest.enter" : "أدخل الرقم الطالب.",
  "ccivr.nms.businessinvokeservice.overtime" : "الفاصل الزمني للمهلة",
  "ccivr.ivr.voiceupload.audiotypefailed" : "ملف الصوت ليس بتنسيق ALAW.",
  "ccivr.ivr.transferRecord.query.failed" : "فشل الاستعلام عن تسجيلات النقل",
  "ccivr.nms.process.number" : "عدد صحيح",
  "ccivr.nms.playcollectservice.changetomultivoice" : "صوت متغير",
  "ccivr.ivr.voice.scene.beforeReportWorkNo" : "قبل الإبلاغ عن هوية الموظف",
  "ccivr.ivr.error.connect" : "فشل الاتصال بخادم SFTP.",
  "ccivr.flowtest.test.error.tip" : "لم يقم التدفق بتكوين أو تطبيقه",
  "ccivr.nms.tansferservice.timedPrompt" : "لم تقم بحفظها لفترة طويلة. يرجى حفظها في الوقت المناسب.",
  "ccivr.nms.ringService.sendonly" : "إرسال فقط",
  "ccivr.flowtest.pleasebegintest" : "انتهت العملية.",
  "ccivr.ivr.variable.desclimit" : "لا يمكن أن يحتوي الوصف على أكثر من 120 حرفًا.",
  "ccivr.nms.menuname.specialStrValidateFailed" : "لا يمكن أن يتجاوز الطول 255 حرفًا",
  "ccivr.nms.playservice.multivoice.type.price" : "السعر",
  "ccivr.ivr.tracelog.result.finshed" : "انتهى",
  "ccivr.nms.playcollectservice.onenumber" : "مجموعة مكونة من رقم واحد",
  "ccivr.ivr.error.releaseFailed.tips" : "فشل تحرير الاستبيان تلقائيًا. قم بتكوين عملية معالجة استثناء وتحريرها وتحرير الاستبيان يدويًا.",
  "ccivr.nms.menuservice.movedown" : "إلى الأسفل",
  "ccivr.ivr.variable.defaultValue" : "القيمة الافتراضية",
  "ccivr.nms.process.saveerror.existnullentity" : "عناصر مخطط البيانات فارغة موجودة.",
  "ccivr.ivrflow.operate.replace" : "استبدال",
  "ccivr.transferrecord.download.record" : "تنزيل تسجيل نقل التدفق:",
  "ccivr.ivrflow.flowAccessCode" : "رمز وصول التدفق",
  "ccivr.ivr.voiceedit.cancel" : "إلغاء",
  "ccivr.nms.tansferservice.samenamevalidate" : "اسم المعلمة مكرر.",
  "ccivr.ivr.voicereview.autopass" : "تمرير تلقائي",
  "ccivr.nms.businessinvokeservice.info2" : "في حالة تجاوز الفاصل الزمني لمهلة الاستدعاء، يتم تنفيذ وحدة فشل الاستدعاء.",
  "ccivr.nms.playservice.contentisnull" : "لا يمكن أن يكون المحتوى الصوتي فارغًا.",
  "ccivr.nms.businessinvokeservice.info1" : "يجب تكوين الواجهات المتوفرة على صفحة تكوين الواجهة.",
  "ccivr.ivr.transferRecord.download" : "تحميل",
  "ccivr.ivr.variable.defaultvalueerror" : "تحتوي القيمة الافتراضية على أحرف خاصة. يرجى تعديله.",
  'ccivr.nms.timeselectservice.timeSelectType':'وضع التكوين',
  'ccivr.nms.timeselectservice.customConfig':'تخصيص',
  'ccivr.nms.timeselectservice.workDayType':'قالب',
  'ccivr.nms.timeselectservice.workDay':'تقويم العمل',
  'ccivr.voiceupload.filename.validate': 'نام پرونده نمی تواند حاوی نویسه های خاص زیر باشد:',
  'ccivr.nms.play.method': 'الأسلوب',
  'ccivr.nms.play.source': 'الموارد',
  'ccivr.ivr.ivrflow.operate.release.referencedflow': 'خرابی در لغو انتشار! جريان قبلاً ارجاع شده.',
  'ccivr.ivr.voice.scene.2d.avatar': 'الإنسان الافتراضي',
  'ccivr.ivr.voice.picturechoose': 'انتخاب پرونده ها',
  'ccivr.ivr.voiceupload.selectpicture':'يه پرونده تصويري انتخاب کن',
  'ccivr.ivr.voiceupload.tippictureformat': 'در حال حاضر ، فقط از JPG ، PNG و فایلهای تصویر JPEG پشتیبانی می شوند. دقت پرونده نمی تواند از ۷۲۰p تجاوز کند و اندازه پرونده نمی تواند از ۲ مگابایت تجاوز کند.',
  'ccivr.ivr.voiceupload.tippicturemodel': 'می توانید پرونده های تصویر را در آن واحد در زبان های مختلف تنظیم کنید. این سیستم به طور خودکار به فایل تصویر متناظر بر اساس زبان انتخاب شده در طول تولید شخص دیجیتال سوئیچ می کند.',
  'ccivr.ivr.voiceupload.tip2DVideoformat': 'در حال حاضر ، فقط پرونده های MP4 پشتیبانی می شوند. دقت پرونده نمی تواند از ۷۲۰p تجاوز کند و اندازه پرونده نمی تواند از ۵ مگابایت بیشتر شود.',
  'ccivr.ivr.voiceupload.tip2Dvideomodel': 'می توانید فایل های ویدئویی را در آن واحد در زبان های مختلف تنظیم کنید. در طول ترکیب دیجیتال انسان، سیستم به طور خودکار به فایل ویدئویی متناظر بر اساس زبان انتخاب شده توسط کاربر تغییر می کند.',
  'ccivr.ivr.voiceupload.vedioMp4formatwrong': 'قالب پروندۀ نادرست. لطفاً ، پروندۀ MP4 را وارد کنید.',
  'ccivr.ivr.voiceupload.tipvideo2Dformat': 'در حال حاضر تنها فایل های ویدئویی با فرمت mp4 پشتیبانی می شوند و حجم فایل ها نباید بیش از 5 مگابایت باشد.',
  'ccivr.ivr.voicereview.picture': 'تصویر',
  'ccivr.ivr.voicereview.2dVideo': 'فيديو الإنسان الافتراضي',
  'ccivr.ivr.voiceupload.tipPicture2Dformat': 'در حال حاضر تنها فایل های تصویری با فرمت های jpg، png و jpeg پشتیبانی می شوند و حجم فایل ها نباید بیش از 2 مگابایت باشد.',
  'ccivr.ivr.voiceupload.pictureformatwrong': 'خطای قالب پرونده. لطفاً ، پروندۀ jpg ، png ، یا JPEG را وارد کنید!',
  'ccivr.ivr.voiceupload.file.updateTitle': 'ویرایش منابع صوتی و تصویری',
  'ccivr.ivr.voiceupload.file.createTitle': 'جدید منابع صوتی و تصویری',
  'ccivr.voiceupload.tippictureresolution': 'رزولوشن تصویر نباید بیش از 720p باشد',
  'ccivr.ivr.voiceupload.tipvideoresolution': 'رزولوشن ویدئو نباید بیش از 720p باشد',
  'ccivr.ivr.voiceupload.fileName.validate': 'لا يمكن أن يحتوي اسم الملف على الأحرف الخاصة التالية:',
  'oifde.service.invoke.businessVirtualHumanService.name': 'رد الشخص الظاهري',
}
