export default {
  'oifde.custom.displayName ': 'Serviço comercial',
  'oifde.common.displayName': 'Nó comum',
  'oifde.basic.displayName': 'Serviço básico',
  'oifde.test.displayName': 'Testar serviço de amostra',
  'oifde.start.displayName': 'Início',
  'oifde.start.description': 'Início',
  'oifde.start.keywords': 'Início',
  'oifde.commonBusinessInvokeService.displayName': 'Invocação de interface comercial',
  'oifde.commonBusinessInvokeService.description': 'Abrir o serviço de interface comercial comum.',
  'oifde.commonBusinessInvokeService.keywords': 'Invocação de interface comercial',
  'oifde.procedureService.displayName': 'Procedimento armazenado',
  'oifde.procedureService.description': 'Abrir o serviço de procedimento armazenado.',
  'oifde.procedureService.keywords': 'Procedimento armazenado',
  'oifde.conditionService.displayName': 'Avaliação de condição',
  'oifde.conditionService.description': 'Abrir o serviço de avaliação de condição.',
  'oifde.conditionService.keywords': 'Avaliação de condição',
  'oifde.callEndService.displayName': 'Encerram. de chamada',
  'oifde.callEndService.description': 'Abrir o serviço de encerramento de chamadas.',
  'oifde.callEndService.keywords': 'Encerram. de chamada',
  'oifde.sendSmsService.displayName': 'Envio de SMS',
  'oifde.sendSmsService.description': 'Abrir o serviço de envio de SMS.',
  'oifde.sendSmsService.keywords': 'Envio de SMS',
  'oifde.commonVoiceService.displayName': 'Resposta',
  'oifde.commonVoiceService.description': 'Abrir o serviço de resposta comum.',
  'oifde.commonVoiceService.keywords': 'Resposta',
  'oifde.transforFlowService.displayName': 'Transfer. de fluxo',
  'oifde.transforFlowService.description': 'Abrir o serviço de transferência de fluxo.',
  'oifde.transforFlowService.keywords': 'Transfer. de fluxo',
  'oifde.callTransferService.displayName': 'Transfer. de chamada',
  'oifde.callTransferService.description': 'Abrir o serviço de transferência de chamadas.',
  'oifde.callTransferService.keywords': 'Transfer. de chamada',
  'oifde.speechRecognitionService.displayName': 'Reconhecimento semântico',
  'oifde.speechRecognitionService.description': 'Abrir o serviço de reconhecimento semântico.',
  'oifde.speechRecognitionService.keywords': 'Reconhecimento semântico',
  'oifde.speechRecognitionService.confidence2TopError': 'O intervalo de confiança deve estar entre 0 e 1.',
  'oifde.keyboardRecognitionService.displayName': 'Reconhecimento de chave',
  'oifde.keyboardRecognitionService.description': 'Abrir o serviço de reconhecimento de chave.',
  'oifde.keyboardRecognitionService.keywords': 'Reconhecimento de chave',
  'oifde.textVariableToChatbotService.displayName': 'Texto para chatbot',
  'oifde.textVariableToChatbotService.description': 'Texto para chatbot (textVariableToChatbotService)',
  'oifde.textVariableToChatbotService.keywords': 'Texto para chatbot',
  'oifde.checkRegularService.displayName': 'Validação de regras',
  'oifde.checkRegularService.description': 'Abrir o serviço de validação de regras.',
  'oifde.checkRegularService.keywords': 'Validação de regras',
  'oifde.dateProcessService.displayName': 'Processamento de data',
  'oifde.dateProcessService.description': 'Abrir o serviço de processamento de data.',
  'oifde.dateProcessService.keywords': 'Processamento de data',
  'oifde.errTimesClearService.displayName': 'Liberação de contagem de erros',
  'oifde.errTimesClearService.description': 'Abrir o serviço de liberação de contagem de erros.',
  'oifde.errTimesClearService.keywords': 'Liberação de contagem de erros',
  'oifde.CircleService.displayName': 'Loop lógico',
  'oifde.CircleService.description': 'Abrir o serviço de loop lógico.',
  'oifde.CircleService.keywords': 'Loop lógico',
  'oifde.compositeTestService.displayName': 'Amostra de elemento de diagrama composto',
  'oifde.compositeTestService.description': 'Amostra de elemento de diagrama composto (CompositeTestService)',
  'oifde.compositeTestService.keywords': 'Amostra de elemento de diagrama composto',
  'oifde.service.invoke.user.level': 'Nível de usuário',
  'oifde.UserLevelService.displayName': 'Nível de usuário',
  'oifde.UserLevelService.keywords': 'Nível de usuário',
  'oifde.UserLevelService.description': 'Serviço de nível de usuário',
  'oifde.service.invoke.user.level.reminder': 'Defina este parâmetro como uma variável ou número inteiro não negativo do tipo de caracter. O valor padrão é 0 e o valor máximo é 14. As chamadas de clientes de nível alto são processadas primeiro após a chamada ser transferida para o serviço manual.',
  'oifde.service.condition.condgroup.desc': 'Defina atributos de condição de avaliação.',
  'oifde.service.condition.condgroup.displayName': 'Condições de avaliação',
  'oifde.service.condition.condgroup.keyTitle': 'Condição',
  'oifde.service.condition.condgroup.valueTitle': 'Expressão de condição',
  'oifde.service.invokemethod.group.desc': 'Método de operação',
  'oifde.service.invokemethod.group.displayName': 'Método de operação',
  'oifde.service.invokemethod.group.value1Title': 'Retornar objeto',
  'oifde.service.invokemethod.group.value2Title': 'Nome do Método',
  'oifde.service.invokemethod.group.value3Title': 'Parâmetros do método',
  'oifde.service.invokemethod.group.value1Tips': 'Objeto de recebimento do valor de retorno do método',
  'oifde.service.invokemethod.group.value2Tips': 'Selecione o nome do método',
  'oifde.service.invokemethod.group.value3Tips': 'Parâmetros do método 1',
  'oifde.service.invokemethod.group.value4Tips': 'Parâmetros do método 2',
  'oifde.service.invokemethod.group.value5Tips': 'Parâmetros do método 3',
  'oifde.service.invokemethod.group.mathodType1': 'Métodos de operação de string',
  'oifde.service.invokemethod.group.mathodType2': 'Métodos de ação de matriz',
  'oifde.service.invokemethod.group.mathodType3': 'Métodos de operação do mapa',
  'oifde.service.invokemethod.strSplit.desc': 'Dividir uma string de acordo com o separador fornecido',
  'oifde.service.invokemethod.strSubstring.desc': 'Extrair substring',
  'oifde.service.invokemethod.strIndexOf.desc': 'Obtém o subscrito da primeira ocorrência da string especificada',
  'oifde.service.invokemethod.strStartsWith.desc': 'Detecta se a string começa com o prefixo especificado',
  'oifde.service.invokemethod.strEndsWith.desc': 'Detecta se a string termina com o sufixo especificado',
  'oifde.service.invokemethod.strParseJSON.desc': 'Converter uma string JSON em um objeto',
  'oifde.service.invokemethod.strParseInt.desc': 'Converte uma string em um número inteiro',
  'oifde.service.invokemethod.strParseFloat.desc': 'Converte strings em números de ponto flutuante',
  'oifde.service.invokemethod.strLength.desc': 'Comprimento da string',
  'oifde.service.invokemethod.strToUpperCase.desc': 'Converter caracteres minúsculos em maiúsculas de string',
  'oifde.service.invokemethod.strToLowerCase.desc': 'Converter caracteres maiúsculos de string em minúsculas',
  'oifde.service.invokemethod.arrayAdd.desc': 'Adicionar um elemento a uma matriz',
  'oifde.service.invokemethod.arrayAddAll.desc': 'Adicionar todos os elementos de outro conjunto à matriz',
  'oifde.service.invokemethod.arrayContains.desc': 'Determina se um elemento está em uma matriz',
  'oifde.service.invokemethod.arrayGet.desc': 'Obtém elementos de uma matriz a partir de um valor indexado',
  'oifde.service.invokemethod.arrayRemoveIdx.desc': 'Remove o elemento subscrito especificado de uma matriz',
  'oifde.service.invokemethod.arrayRemoveAllIdx.desc': 'Remove elementos subscritos de uma matriz que existam na coleção especificada',
  'oifde.service.invokemethod.arrayClear.desc': 'Remover todos os elementos de uma matriz',
  'oifde.service.invokemethod.arraySize.desc': 'Obtém o comprimento de uma matriz',
  'oifde.service.invokemethod.arraySubList.desc': 'Intercepta e retorna uma parte de uma matriz',
  'oifde.service.invokemethod.arrayJoin.desc': 'Converter uma matriz em uma string',
  'oifde.service.invokemethod.mapPut.desc': 'Adicionar um elemento ao mapa',
  'oifde.service.invokemethod.mapGet.desc': 'Obtém o valor do mapa de chave especificado no mapa',
  'oifde.service.invokemethod.mapRemove.desc': 'Excluir um par chave-valor especificado no mapa',
  'oifde.service.invokemethod.mapClear.desc': 'Limpar todos os pares chave-valor do mapa',
  'oifde.service.invokemethod.mapSize.desc': 'Obtém o número de todas as chaves do mapa',
  'oifde.service.invokemethod.strSplit.parm1.desc': 'Strings a serem divididas',
  'oifde.service.invokemethod.strSplit.parm2.desc': 'Separador',
  'oifde.service.invokemethod.strSubstring.parm1.desc': 'Strings a serem interceptadas',
  'oifde.service.invokemethod.strSubstring.parm2.desc': 'Iniciar subscrito ( inteiro )',
  'oifde.service.invokemethod.strSubstring.parm3.desc': 'Subscrito Final ( Inteiro )',
  'oifde.service.invokemethod.strIndexOf.parm1.desc': 'Strings a serem pesquisadas',
  'oifde.service.invokemethod.strIndexOf.parm2.desc': 'String especificada',
  'oifde.service.invokemethod.strStartsWith.parm1.desc': 'Strings a serem testadas',
  'oifde.service.invokemethod.strStartsWith.parm2.desc': 'String de prefixo especificada',
  'oifde.service.invokemethod.strEndsWith.parm1.desc': 'Strings a serem testadas',
  'oifde.service.invokemethod.strEndsWith.parm2.desc': 'String de sufixo especificada',
  'oifde.service.invokemethod.strParseJSON.parm1.desc': 'Strings a serem convertidas',
  'oifde.service.invokemethod.strParseInt.parm1.desc': 'Strings a serem convertidas',
  'oifde.service.invokemethod.strParseFloat.parm1.desc': 'Strings a serem convertidas',
  'oifde.service.invokemethod.strLength.parm1.desc': 'String de comprimento a ser calculado',
  'oifde.service.invokemethod.strToUpperCase.parm1.desc': 'Strings a serem convertidas',
  'oifde.service.invokemethod.strToLowerCase.parm1.desc': 'Strings a serem convertidas',
  'oifde.service.invokemethod.arrayAdd.parm1.desc': 'Arrays a serem adicionados ( este método modifica os arrays a serem adicionados )',
  'oifde.service.invokemethod.arrayAdd.parm2.desc': 'Posição adicionada ( cauda padrão )',
  'oifde.service.invokemethod.arrayAdd.parm3.desc': 'Elementos adicionados',
  'oifde.service.invokemethod.arrayAddAll.parm1.desc': 'Arrays a serem adicionados ( este método modifica os arrays a serem adicionados )',
  'oifde.service.invokemethod.arrayAddAll.parm2.desc': 'Posição adicionada ( cauda padrão )',
  'oifde.service.invokemethod.arrayAddAll.parm3.desc': 'Elementos adicionados',
  'oifde.service.invokemethod.arrayContains.parm1.desc': 'Matriz a ser testada',
  'oifde.service.invokemethod.arrayContains.parm2.desc': 'Elementos a julgar',
  'oifde.service.invokemethod.arrayGet.parm1.desc': 'Arrays a serem usados',
  'oifde.service.invokemethod.arrayGet.parm2.desc': 'Subscritos de matriz',
  'oifde.service.invokemethod.arrayRemoveIdx.parm1.desc': 'Arrays a serem usados',
  'oifde.service.invokemethod.arrayRemoveIdx.parm2.desc': 'Remover subscritos de matriz',
  'oifde.service.invokemethod.arrayRemoveAllIdx.parm1.desc': 'Arrays a serem usados',
  'oifde.service.invokemethod.arrayRemoveAllIdx.parm2.desc': 'Especificar uma coleção de subscritos de matriz',
  'oifde.service.invokemethod.arrayClear.parm1.desc': 'Arrays a serem limpos',
  'oifde.service.invokemethod.arraySize.parm1.desc': 'Matriz de comprimentos a serem calculados',
  'oifde.service.invokemethod.arraySubList.parm1.desc': 'Arrays a serem interceptados',
  'oifde.service.invokemethod.arraySubList.parm2.desc': 'Posição inicial da interceptação',
  'oifde.service.invokemethod.arraySubList.parm3.desc': 'Posição final da captura',
  'oifde.service.invokemethod.arrayJoin.parm1.desc': 'Arrays a serem convertidos',
  'oifde.service.invokemethod.arrayJoin.parm2.desc': 'String de Conexão',
  'oifde.service.invokemethod.mapPut.parm1.desc': 'Mapa a ser adicionado ( este método irá modificar o mapa a ser adicionado )',
  'oifde.service.invokemethod.mapPut.parm2.desc': 'key ( somente strings são suportadas )',
  'oifde.service.invokemethod.mapPut.parm3.desc': 'Elementos adicionados',
  'oifde.service.invokemethod.mapGet.parm1.desc': 'Mapas pendentes',
  'oifde.service.invokemethod.mapGet.parm2.desc': 'key ( somente strings são suportadas )',
  'oifde.service.invokemethod.mapRemove.parm1.desc': 'Mapas pendentes',
  'oifde.service.invokemethod.mapRemove.parm2.desc': 'key ( somente strings são suportadas )',
  'oifde.service.invokemethod.mapClear.parm1.desc': 'Mapas a serem limpos',
  'oifde.service.invokemethod.mapSize.parm1.desc': 'Mapa da quantidade a calcular',
  'oifde.service.invokemethod.strSplit.resultObj.desc': 'O objeto de retorno é do tipo array , salva a matriz de strings divididas .',
  'oifde.service.invokemethod.strSubstring.resultObj.desc': 'O objeto de retorno é do tipo string e é uma substring interceptada .',
  'oifde.service.invokemethod.strIndexOf.resultObj.desc': 'Retorna o objeto como um valor subscrito , inteiro .',
  'oifde.service.invokemethod.strStartsWith.resultObj.desc': 'O objeto de retorno é o resultado da detecção , inteiro 0 / 1 .',
  'oifde.service.invokemethod.strEndsWith.resultObj.desc': 'O objeto de retorno é o resultado da detecção , inteiro 0 / 1 .',
  'oifde.service.invokemethod.strParseJSON.resultObj.desc': 'O objeto retornado é o objeto JSON convertido .',
  'oifde.service.invokemethod.strParseInt.resultObj.desc': 'Retorna o objeto como um número inteiro convertido .',
  'oifde.service.invokemethod.strParseFloat.resultObj.desc': 'Retorna o objeto como um número de ponto flutuante convertido .',
  'oifde.service.invokemethod.strLength.resultObj.desc': 'Retorna o tamanho do objeto como uma string , inteiro .',
  'oifde.service.invokemethod.strToUpperCase.resultObj.desc': 'Retorna o objeto como uma string convertida .',
  'oifde.service.invokemethod.strToLowerCase.resultObj.desc': 'Retorna o objeto como uma string convertida .',
  'oifde.service.invokemethod.arrayAdd.resultObj.desc': 'Retorna o resultado da adição de um objeto , retorna 1 se a adição for bem-sucedida , caso contrário retorna 0 .',
  'oifde.service.invokemethod.arrayAddAll.resultObj.desc': 'Retorna o resultado da adição de um objeto , retorna 1 se a adição for bem-sucedida , caso contrário retorna 0 .',
  'oifde.service.invokemethod.arrayContains.resultObj.desc': 'O objeto de retorno é o resultado da decisão , inteiro 0 / 1 .',
  'oifde.service.invokemethod.arrayGet.resultObj.desc': 'Retorna elementos cujo objeto é uma matriz .',
  'oifde.service.invokemethod.arrayRemoveIdx.resultObj.desc': 'Retorna o resultado da exclusão do objeto . Se a exclusão for bem-sucedida , retorna o elemento do subscrito correspondente . Caso contrário , retorna vazio .',
  'oifde.service.invokemethod.arrayRemoveAllIdx.resultObj.desc': 'Retorna o resultado da exclusão do objeto . Se a exclusão for bem-sucedida , retorna 1 . Caso contrário , retorna 0 .',
  'oifde.service.invokemethod.arrayClear.resultObj.desc': 'O objeto retornado é nulo , a caixa de entrada está acinzentada e não pode ser editada .',
  'oifde.service.invokemethod.arraySize.resultObj.desc': 'O objeto retornado é o comprimento da matriz , do tipo inteiro .',
  'oifde.service.invokemethod.arraySubList.resultObj.desc': 'Os objetos retornados são sub-arrays .',
  'oifde.service.invokemethod.arrayJoin.resultObj.desc': 'Retorna o objeto como uma string convertida .',
  'oifde.service.invokemethod.mapPut.resultObj.desc': 'A lógica de retorno de objeto é retornar null se a chave não existir , e retornar o valor original substituído se existir , o tipo de objeto .',
  'oifde.service.invokemethod.mapGet.resultObj.desc': 'Retorna o valor para o qual o objeto é mapeado para a chave especificada .',
  'oifde.service.invokemethod.mapRemove.resultObj.desc': 'Retornar objeto : este método retorna o valor anteriormente associado a essa chave por este mapeamento;Se o mapeamento não contiver um mapeamento para a chave , null será retornado .',
  'oifde.service.invokemethod.mapClear.resultObj.desc': 'O objeto retornado é nulo , a caixa de entrada está acinzentada e não pode ser editada .',
  'oifde.service.invokemethod.mapSize.resultObj.desc': 'O objeto de retorno é o número de chaves no mapa , do tipo inteiro .',
  'oifde.service.invoke.cachegroup.desc': 'Defina os atributos variáveis correspondentes.',
  'oifde.service.invoke.cachegroup.displayName': 'Atributos variáveis',
  'oifde.service.invoke.cachegroup.keyTitle': 'Variável de cache',
  'oifde.service.invoke.cachegroup.valueTitle': 'Atributo de resposta',
  'oifde.service.invoke.interfaceType': 'Tipo de interface',
  'oifde.service.invoke.interfaceType.desc': 'Tipo de interface',
  'oifde.service.invoke.interfaceId': 'Interface invocada',
  'oifde.service.invoke.interfaceId.desc': 'Interface invocada.',
  'oifde.service.invoke.paramgroup.desc': 'Lista de parâmetros de entrada da interface.',
  'oifde.service.invoke.paramgroup.name': 'Parâmetros de entrada da interface',
  'oifde.service.invoke.startTime': 'Hora de início',
  'oifde.service.invoke.startTime.desc': 'Formato HH:MM:SS Exemplo: 08:00:00',
  'oifde.service.invoke.endTime': 'Hora de término',
  'oifde.service.invoke.endTime.desc': 'Formato HH:MM:SS Exemplo: 23:00:00',
  'oifde.service.invoke.sendCircle': 'Intervalo de envio',
  'oifde.service.invoke.sendCircle.desc': 'Período de chamada da interface, em dias. Não mais do que 365. Se a configuração - 1 significa que a chamada não é cíclica, a qualquer momento o elemento final é acionado.',
  'oifde.service.invoke.CallTransferCallingNum': 'Número chamador',
  'oifde.service.invoke.CallTransferCallingNum.desc': 'Esse valor será usado como o número chamador se o tipo de transferência de chamada for definido como 5 (indicando que a chamada foi transferida para um terceiro).',
  'oifde.service.invoke.CallTransferCallingNum.lastAgentDesc': 'O número de chamada é o número de chamada do cliente. Como SYS.callingNumber',
  'oifde.service.invoke.CallTransferType': 'Tipo de transferência',
  'oifde.service.invoke.CallTransferType.desc': 'Tipo de transferência de diálogo. As opções são as seguintes: 1: transferência para o serviço manual (para uma fila de habilidades por código de roteamento); 2: transferência para um IVR; 3: transferência para o serviço manual (para uma fila de habilidades por nome da habilidade); 4: transferência para o serviço manual (para um agente por ID); 5: transferência para um número de terceiros; 6: assistente digital online; 7: transferência para o serviço manual (para o último agente, somente multimídia é suportado atualmente); 8: transferência para o serviço manual (para a fila de habilidades à qual um agente pertence, somente multimídia é suportado atualmente); 31: transferência para uma mensagem multimídia (para o último agente); 32: transferência para uma mensagem multimídia (para uma fila de habilidades); 33: transferência para uma mensagem multimídia (para um agente).',
  'oifde.service.invoke.CallTransferRouteValue': 'Valor da rota',
  'oifde.service.invoke.CallTransferRouteValue.desc': 'Valor da rota de transferência da chamada. Defina esse parâmetro com base no tipo de transferência de chamada.',
  'oifde.service.invoke.CallTransferRouteTypePolicy': 'Política de roteamento',
  'oifde.service.invoke.CallTransferRoutePolicyType.percentage': 'Porcentagem',
  'oifde.service.invoke.CallTransferRoutePolicyType.numberSegment': 'Segmento do número',
  'oifde.service.invoke.CallTransferRouteDefaultSkillName': 'Fila de habilidades padrão',
  'oifde.service.invoke.CallTransferMatchingType': 'Regra de correspondência',
  'oifde.service.invoke.CallTransferMatchingType.prefix': 'Prefixo',
  'oifde.service.invoke.CallTransferMatchingType.suffix': 'Sufixo',
  'oifde.service.invoke.CallTransferRouteTypePolicy.percentTotalError': 'Percentual total não é igual a 100',
  'oifde.service.invoke.CallTransferRouteTypePolicy.percentValueError': ' Digite um número inteiro positivo de 1 a 100 ',
  'oifde.service.invoke.CallTransferTransData': 'Dados associados',
  'oifde.service.invoke.CallTransferTransData.desc': 'Dados associados transferidos durante a transferência de chamadas para um agente.',
  'oifde.service.invoke.CallTransferTransData.desc2': 'Alternativamente, você pode inserir várias variáveis de fluxo ou globais, por exemplo, {"data":${FLOW.XXX},"type":${GLOBAL.XXX}, "time":"2000"}.',
  'oifde.service.invoke.textVariable': 'Variável de texto',
  'oifde.service.invoke.textVariable.desc': 'Variável de texto enviada ao chatbot. Um valor deve ser atribuído a essa variável antes que o nó possa ser executado.',
  'oifde.service.invoke.KeyboardInputRegExp': 'Expressão da regra',
  'oifde.service.invoke.KeyboardInputRegExp.desc': 'A expressão verifica se o texto inserido pelo usuário atende à regra especificada. O valor pode ser uma expressão regular. Exemplo: ^(1([0-9]{10})|([*]|[#]))$.',
  'oifde.service.invoke.voiceType': 'Tipo de resposta',
  'oifde.service.invoke.voiceSource': 'Origem da resposta',
  'oifde.service.invoke.staticVoice': 'Voz estática',
  'oifde.service.invoke.TTSVoice': 'TTS',
  'oifde.service.invoke.voiceTemplateCode': 'Modelo de resposta',
  'oifde.service.invoke.modelType': 'Modo de resposta',
  'oifde.service.invoke.modelType.desc': 'Se o diálogo pode ser interrompido.',
  'oifde.service.invoke.voiceTtsMixed': 'Voz e texto',
  'oifde.service.invoke.ttsSoundEffectCust': 'Efeito de som personalizado',
  'oifde.service.invoke.ttsSoundSpeedCust': 'Velocidade personalizada',
  'oifde.service.invoke.multiVoice': 'Voz variável',
  'oifde.service.invoke.timeOut': 'Intervalo tempo esgotado',
  'oifde.service.invoke.timeOut.desc': 'Intervalo de tempo esgotado. O valor é um inteiro. Exemplo: 15.',
  'oifde.service.invoke.voiceVariable': 'Variável de resposta',
  'oifde.service.invoke.voiceVariable.default.desc': 'Variáveis de voz ou de texto que precisam ser respondidas externamente. As variáveis devem ter um valor atribuído antes de serem executadas neste nó',
  'oifde.service.invoke.voiceVariable.voice.desc': 'Variável de voz ou texto incluída na resposta. Um valor deve ser atribuído a essa variável antes que o nó possa ser executado (Para um tipo de voz estática, a variável de resposta está no formato de URL do arquivo de gravação. Apenas um URL é suportado.)',
  'oifde.service.invoke.voiceVariable.tts.desc': 'respondida externamente, a variável deve ter um valor atribuído antes de ser executada para este nó (tipo de texto de resposta, o formato real da variável de resposta é: texto TTS, apenas um é suportado)',
  'oifde.service.invoke.voiceVariable.mix.desc': 'Variável de voz ou variável de texto que precisa ser respondida externamente, a variável deve ter um valor atribuído antes da execução deste nó (tipo misto de voz-texto, o formato real do conteúdo da variável de resposta é: URL do arquivo de gravação ~ $TTS texto $~ URL do arquivo de gravação)',
  'oifde.service.invoke.voiceVariable.desc': 'Variável de voz ou texto incluída na resposta. Um valor deve ser atribuído a essa variável antes que o nó possa ser executado.',
  'oifde.service.invoke.offsetType': 'Período de compens.',
  'oifde.service.invoke.offsetType.desc': 'Compensação por hora.',
  'oifde.service.invoke.sourceVariable': 'Cadeia de caracteres de origem',
  'oifde.service.invoke.sourceVariable.desc': 'Variável de cadeia de origem. O formato é aaaaMM se a compensação for por mês e aaaaMMddHHmmss se a compensação for por hora. Exemplo: 202103 ou 20210308122020. Um valor deve ser atribuído a essa variável antes que o nó possa ser executado.',
  'oifde.service.invoke.destVariable': 'Cadeia de caracteres de destino',
  'oifde.service.invoke.destVariable.desc': 'Variável de cadeia de destino usada para salvar o resultado.',
  'oifde.service.invoke.offsetMonths': 'Número de compensações',
  'oifde.service.invoke.offsetMonths.desc': 'Número de compensações. O valor é um inteiro positivo ou negativo. Defina esse parâmetro com base no período de compensação (compensação por mês ou hora). Por exemplo, se Compensação por mês for selecionado, o valor -6 indica que a cadeia de caracteres de origem é revertida para o que foi usado há seis meses.',
  'oifde.service.invoke.keyBoardType': 'Tipo de entrada do teclado',
  'oifde.service.invoke.keyBoardType.desc': 'Tipo de entrada do teclado. O valor pode ser keyBoard_phone ou checkoldpassword, ou ser deixado em branco.',
  'oifde.service.invoke.inputVariable': 'Variável',
  'oifde.service.invoke.inputVariable.desc': 'Variável para salvar a entrada do teclado do usuário.',
  'oifde.service.invoke.inputVariableTitle': 'Affectation de la variable',
  'oifde.service.invoke.inputVariableValue': 'Valeur de la variable',
  'oifde.service.invoke.conditionTitle': 'Branches conditionnelles',
  'oifde.service.invoke.branchGroup.timeout': 'Tempo esgotado',
  'oifde.service.invoke.branchGroup.nomatch': 'Sem correspondência',
  'oifde.service.invoke.branchGroup.error': 'Erro',
  'oifde.service.invoke.branchGroup.silentAgent': 'Agente silencioso',
  'oifde.service.invoke.targetFlowCode': 'Cód. de fluxo de destino',
  'oifde.service.invoke.targetNodeId': 'ID do nó de destino',
  'oifde.service.invoke.smsTemplateCode': 'Modelo do SMS',
  'oifde.service.invoke.smsContent': 'Conteúdo do SMS',
  'oifde.service.invoke.smsContent.desc': 'Conteúdo do SMS.',
  'oifde.service.invoke.modelType.voiceBreak': 'Reconhecimento de interrupções',
  'oifde.service.invoke.modelType.noVoiceBreak': 'Somente reprodução',
  'oifde.service.invoke.modelType.keyboardBreak': 'Interrup. por pression. tecla',
  'oifde.service.invoke.modelType.voiceAndKeyboard': 'Reconhecimento e pressionamento de tecla',
  'oifde.service.invoke.modelType.voiceAndNobreak': 'Reconhecimento após a reprodução',
  'oifde.service.invoke.modelType.voiceAndInputAndNobreak': 'Reconhecimento e pressionamentos de teclas após a reprodução',
  'oifde.service.invoke.context': 'Contexto',
  'oifde.service.invoke.context.desc': 'Informações de contexto transferidas para o TUC.',
  'oifde.service.invoke.vendorParam': 'Parâmetro estendido',
  'oifde.service.invoke.vendorParam.desc': 'O parâmetro de reconhecimento de voz estendido retornou ao IVR.',
  'oifde.service.invoke.input': 'Conteúdo de reconhecimento semântico',
  'oifde.service.invoke.input.desc': 'Conteúdo de reconhecimento semântico transferido para o TUC. O conteúdo pode ser uma variável. Se este campo estiver vazio, o conteúdo inserido pelo usuário será transferido para o TUC.',
  'oifde.service.invoke.event': 'Evento',
  'oifde.service.invoke.event.desc': 'Evento enviado ao TUC.',
  'oifde.service.invoke.event.cleanup': 'Apagar cache da sessão',
  'oifde.service.invoke.event.cleanCurrent': 'Apagar cache de contexto atual',
  'oifde.service.invoke.procedureCode': 'Procedimento armazenado',
  'oifde.service.invoke.complexcellgroupin.name': 'Parâmetros de entrada',
  'oifde.service.invoke.complexcellgroupin.desc': 'Parâmetros de entrada.',
  'oifde.service.invoke.complexcellgroupout.name': 'Parâmetro de saída',
  'oifde.service.invoke.complexcellgroupout.desc': 'Parâmetros de saída.',
  'oifde.service.invoke.targetIntentCount': 'Número de intenções',
  'oifde.service.invoke.targetIntentCount.desc': 'Número máximo de intenções retornadas pelo TUC.',
  'oifde.service.invoke.confidence2Top': 'Intervalo de confiança',
  'oifde.service.invoke.confidence2Top.desc': 'Intervalo entre a confiança de várias intenções e a da primeira intenção quando várias intenções precisam ser retornadas.',
  'oifde.service.invoke.index.desc': 'Contador.',
  'oifde.service.invoke.index': 'Contador',
  'oifde.service.invoke.oblist.desc': 'Lista de loops.',
  'oifde.service.invoke.oblist': 'Loops',
  'oifde.service.invoke.object.desc': 'Objeto de loop.',
  'oifde.service.invoke.object': 'Objeto de loop',
  'oifde.service.invoke.voiceType.staticVoice': 'Voz estática',
  'oifde.service.invoke.voiceType.TTSVoice': 'TTS',
  'oifde.service.invoke.voiceType.video': 'Vídeo',
  'oifde.service.invoke.voiceType.multi-media': 'Multimídia',
  'oifde.service.invoke.offsetType.month': 'Por mês',
  'oifde.service.invoke.offsetType.hour': 'Por hora',
  'oifde.service.invoke.KeyboardInputRegExp.MobileAll': 'Validar o formato do número de celular (com # e *)',
  'oifde.service.invoke.KeyboardInputRegExp.Mobile': 'Validar o formato do número de celular (sem # ou *)',
  'oifde.service.invoke.KeyboardInputRegExp.PasswordAll': 'Validar formato da senha (com # e *)',
  'oifde.service.invoke.KeyboardInputRegExp.PasswordStar': 'Validar formato da senha (com *)',
  'oifde.service.invoke.KeyboardInputRegExp.NumberAll': 'Validar número do celular (com # e *)',
  'oifde.service.invoke.KeyboardInputRegExp.NumberStar': 'Validar número de celular (com *)',
  'oifde.service.invoke.KeyboardInputRegExp.Month': 'Validar a entrada do mês',
  'oifde.service.invoke.modelType.inputAndNobreak': 'Sem interrupção após a coleta de dígitos',
  'oifde.service.invoke.maxCollectLength': 'Número de dígitos coletados',
  'oifde.service.invoke.maxCollectLength.desc': 'Defina o número máximo de dígitos coletados.',
  'oifde.service.invoke.collectTimeout': 'Tempo limite da coleção de dígitos',
  'oifde.service.invoke.collectTimeout.desc': 'Define o intervalo de tempo limite da coleta de dígitos. As variáveis FLOW e GLOBAL são suportadas. O valor também pode ser um número inteiro que varia de 1 a 120. Se o valor não atender aos requisitos, o valor padrão 5 é usado.',
  'oifde.service.invoke.cancelCollectFlag': 'Tecla cancelar',
  'oifde.service.invoke.cancelCollectFlag.yes': 'Usar a tecla asterisco (*)',
  'oifde.service.invoke.cancelCollectFlag.no': 'Não usar a tecla asterisco (*)',
  'oifde.service.invoke.confirmCollectFlag': 'Confirmar chave',
  'oifde.service.invoke.confirmCollectFlag.yes': 'Usar a tecla jogo da velha (#)',
  'oifde.service.invoke.confirmCollectFlag.no': 'Não usar a tecla jogo da velha (#)',
  'oifde.service.invoke.ttsAdvancedSetting': 'Configurações avançadas de TTS',
  'oifde.service.invoke.ttsAdvancedSetting.desc': 'Se essas configurações devem ser habilitadas para especificar a reprodução de voz baseada em TTS.',
  'oifde.service.invoke.ttsAdvancedSetting.open': 'Ativar',
  'oifde.service.invoke.ttsAdvancedSetting.close': 'Desativar',
  'oifde.service.invoke.ttsSoundEffect': 'Voz',
  'oifde.service.invoke.ttsSoundEffect.desc': 'Selecione se deseja usar uma voz masculina ou feminina para a reprodução de voz baseada em TTS.',
  'oifde.service.invoke.ttsSoundEffect.male': 'Masculina',
  'oifde.service.invoke.ttsSoundEffect.female': 'Feminina',
  'oifde.service.invoke.ttsSoundSpeed': 'Velocidade da voz',
  'oifde.service.invoke.ttsSoundSpeed.desc': 'Selecione a velocidade na qual a reprodução de voz baseada em TTS é executada.',
  'oifde.service.invoke.ttsSoundSpeed.low': 'Lenta',
  'oifde.service.invoke.ttsSoundSpeed.normal': 'Média',
  'oifde.service.invoke.ttsSoundSpeed.high': 'Rápida',
  'oifde.service.invoke.ttsMRCPVendorSetting.open': 'Ativar',
  'oifde.service.invoke.ttsMRCPVendorSetting.close': 'Desativar',
  'oifde.service.invoke.ttsMRCPVendorSetting': 'Valores padrão para parâmetros TTS estendidos',
  'oifde.service.invoke.ttsMRCPVendorSetting.desc': 'Depois que os valores padrão para os parâmetros TTS estendidos forem ativados, as definições substituirão o efeito sonoro e a velocidade do som selecionados.',
  'oifde.service.invoke.ttsMRCPVendor': 'Parâmetro estendido de TTS',
  'oifde.service.invoke.ttsMRCPVendor.desc': 'Parâmetro estendido do servidor do TTS. Defina este parâmetro com base nas necessidades do fornecedor.',
  'oifde.service.invoke.asrAdvancedSetting': 'Configurações avançadas de ASR',
  'oifde.service.invoke.asrAdvancedSetting.desc': 'Se essas configurações devem ser habilitadas para especificar o reconhecimento ASR.',
  'oifde.service.invoke.asrAdvancedSetting.open': 'Ativar',
  'oifde.service.invoke.asrAdvancedSetting.close': 'Desativar',
  'oifde.service.invoke.asrRecognizeType': 'Tipo de reconhecimento',
  'oifde.service.invoke.asrRecognizeType.desc': 'Tipo de identificação ASR. Somente a identificação comum é suportada atualmente.',
  'oifde.service.invoke.asrRecognizeType.normal': 'Comum',
  'oifde.service.invoke.asrRecognizeType.intelligent': 'Inteligente',
  'oifde.service.invoke.asrNoInputTimeout': 'Intervalo de tempo limite de silenciamento do assinante',
  'oifde.service.invoke.asrNoInputTimeout.desc': 'Intervalo de tempo limite quando um assinante não fala durante o reconhecimento ASR, em segundos. O intervalo é de 0 a 32000. O valor padrão é 100.',
  'oifde.service.invoke.asrRecognitionTimeout': 'Intervalo de tempo limite de reconhecimento',
  'oifde.service.invoke.asrRecognitionTimeout.desc': 'Intervalo quando o reconhecimento ASR expira, em segundos. O intervalo é de 0 a 600. O valor padrão é 200.',
  'oifde.service.invoke.asrSpeechCompleteTimeout': 'Intervalo de tempo limite de pausa do assinante',
  'oifde.service.invoke.asrSpeechCompleteTimeout.desc': 'Intervalo de tempo limite quando um assinante pausa durante o reconhecimento ASR, em segundos. O intervalo é de 300 a 2000. O valor padrão é 500.',
  'oifde.service.invoke.asrMRCPVendor': 'Parâmetro estendido de ASR',
  'oifde.service.invoke.asrMRCPVendor.desc': 'Parâmetro estendido do servidor do ASR. Defina este parâmetro de acordo com as necessidades do fornecedor.',
  'oifde.service.invoke.voiceType.card': 'Cartão',
  'oifde.service.invoke.operationType': 'Tipo de operação',
  'oifde.service.invoke.operationType.open': 'Iniciar gravação',
  'oifde.service.invoke.operationType.close': 'Parar gravação',
  'oifde.service.invoke.processRecord': 'Registros completos',
  'oifde.service.invoke.processRecord.open': 'Sim',
  'oifde.service.invoke.processRecord.close': 'Não',
  'oifde.service.invoke.recordType': 'Tipo',
  'oifde.service.invoke.recordType.audio': 'Só áudio',
  'oifde.service.invoke.recordType.video': 'Áudio e vídeo',
  'oifde.yugaoRecordService.displayName': 'Gravação VoiceCyber',
  'oifde.yugaoRecordService.description': 'Abra o serviço de gravação VoiceCyber.',
  'oifde.yugaoRecordService.keywords': 'Gravação VoiceCyber',
  'oifde.jsonDataProcessService.displayName': 'Chamada de Dados JSON',
  'oifde.jsonDataProcessService.description': 'Chamada de dados JSON (jsonDataProcessService).',
  'oifde.jsonDataProcessService.keywords': 'Chamada de Dados JSON',
  'oifde.timeSelectService.displayName': 'Seleção de Tempo',
  'oifde.timeSelectService.description': 'Seleção de tempo (timeSelectService).',
  'oifde.timeSelectService.keywords': 'Seleção de Tempo',
  'oifde.queryMessageService.displayName': 'Consulta de informações',
  'oifde.queryMessageService.description': 'Consulta de informações(queryMessageService).',
  'oifde.queryMessageService.keywords': 'Consulta de informações',
  'oifde.service.invoke.recordMode': 'Modo',
  'oifde.service.invoke.recordMode.onlyuser': 'Gravar somente o assinante',
  'oifde.service.invoke.recordMode.onlyivr': 'Gravar somente IVR',
  'oifde.service.invoke.recordMode.all': 'Gravar assinante e IVR',
  'oifde.service.invoke.timeSelect.NonWorkTime': 'ramificação de tempo não especificada',
  'oifde.service.invoke.timeSelect.weekParameter': 'Seleção Semana',
  'oifde.service.invoke.timeSelect.serviceTimeParameter': 'Período de serviço',
  'oifde.service.invoke.timeSelect.specificWorkDateParameter': 'Data de Serviço Especificada',
  'oifde.service.invoke.timeSelect.specificNoWorkDateParameter': 'Data de Não Serviço Especificada',
  'oifde.service.invoke.timeSelect.serviceTimeParameter.desc': 'Período de serviço. Configure 1 a 10 registros.',
  'oifde.service.invoke.timeSelect.specificWorkDateParameter.desc': 'Máximo de 50 registros suportados.',
  'oifde.service.invoke.timeSelect.specificNoWorkDateParameter.desc': 'Data de não-trabalho especificada. Máximo de 50 registros suportados.',
  'oifde.service.invoke.timeSelect.specificWorkDateParameter.title': 'Se uma data de trabalho especificada configurada não estiver disponível na seleção da semana, a data de serviço será considerada como um dia útil.',
  'oifde.service.invoke.timeSelect.specificNoWorkDateParameter.title': 'Se uma data de não-trabalho especificada for configurada, a data de não-serviço será considerada como um dia de não-trabalho com a prioridade mais alta.',
  'oifde.service.invoke.workdays.Monday': 'Segunda-feira',
  'oifde.service.invoke.workdays.Tuesday': 'Terça-feira',
  'oifde.service.invoke.workdays.Wednesday': 'Quarta-feira',
  'oifde.service.invoke.workdays.Thursday': 'Quinta-feira',
  'oifde.service.invoke.workdays.Friday': 'Sexta-feira',
  'oifde.service.invoke.workdays.Saturday': 'Sábado',
  'oifde.service.invoke.workdays.Sunday': 'Domingo',
  'oifde.service.interfaceType.selfDefinedInterface': 'interface auto-definida',
  'oifde.service.interfaceType.innerInterface': 'interface interna',
  'oifde.qarecord.interface.name': 'Gravar Q&A',
  'oifde.oifde.qarecord.interface.desc': 'Registra o ID do questionário, o título da pergunta, a intenção da resposta do usuário e a resposta original do usuário.',
  'oifde.apiToken.interface.name':'Obter o token APIFabric',
  'oifde.apiToken.interface.desc':'Interface usada para obter o token API Fabric',
  'oifde.apiToken.applyType.desc': 'Prefixo do token',
  'oifde.apiToken.accessToken.desc': 'Conteúdo do token',
  'oifde.apiToken.appKey.desc': 'Chave do aplicativo',
  'oifde.apiToken.appSecret.desc': 'App secret',
  'oifde.qarecord.inputparam.seriesId': 'Pergunta e resposta ID',
  'oifde.qarecord.inputparam.questionTitle': 'Título da pergunta',
  'oifde.qarecord.inputparam.questionType': 'Tipo de Questão',
  'oifde.qarecord.inputparam.answerIntent': 'Intenção de resposta',
  'oifde.qarecord.inputparam.originalAnswer': 'Resposta original',
  'oifde.service.invoke.queryMsg.MessageQueryType': 'Tipo de Consulta',
  'oifde.service.invoke.queryMsg.MessageQueryType.desc': 'Informações de enfileiramento: Sim Ativar a consulta de informações de enfileiramento de fluxo de IVR acompanhada ao transferir para a fila de habilidades',
  'oifde.service.invoke.queryMsg.skillMsg': 'Informações de enfileiramento',
  'oifde.service.invoke.queryMsg.skillInfo': 'Informações da Fila',
  'oifde.service.invoke.queryMsg.specifiedSkill': 'Nome da fila',
  'oifde.service.invoke.queryMsg.specialListQuery': 'Informações da lista especial',
  'oifde.service.invoke.queryMsg.result': 'Resultados da consulta',
  'oifde.service.invoke.queryMsg.MessageQueryQueueIndex': 'Posição da fila',
  'oifde.service.invoke.queryMsg.MessageQueryQueueNumber': 'Número de filas',
  'oifde.service.invoke.queryMsg.MessageQueryOnlineNum': 'Número de agentes on-line',
  'oifde.service.invoke.queryMsg.MessageIdleAgentNum': 'Número de agentes ociosos',
  'oifde.service.invoke.queryMsg.MessageQueryWaitingTime': 'Tempo de espera estimado',
  'oifde.service.invoke.queryMsg.MessageQueryWaitingTime.desc': 'O tempo de espera estimado de enfileiramento é calculado com base no modelo de fila, o resultado tem um certo desvio, por favor, use corretamente',
  'oifde.service.invoke.queryMsg.params': 'Parâmetros de solicitação',
  'oifde.service.invoke.queryMsg.skillQueue': 'Fila de Habilidades',
  'oifde.service.invoke.queryMsg.agentId': 'Número do agente',
  'oifde.service.invoke.queryMsg.specialList': 'Identificação presente na lista especial',
  'oifde.service.invoke.CallTransferType.transferToSkillQueueByRoutingCode': 'Fila de Habilidades (Código de Extensão de Roteamento)',
  'oifde.service.invoke.CallTransferType.transferToIVR': 'IVR',
  'oifde.service.invoke.CallTransferType.transferToSkillQueueBySkillName': 'Fila de Habilidades (Nome)',
  'oifde.service.invoke.CallTransferType.transferToAgentByID': 'Assento',
  'oifde.service.invoke.CallTransferType.transferToThirdPartyNumber': 'Número de terceiros',
  'oifde.service.invoke.CallTransferType.onlineDigitalAssistant': 'Assistente digital online',
  'oifde.service.invoke.CallTransferType.transferToLastAgent': 'Clientes multimídia',
  'oifde.service.invoke.CallTransferType.transferToSkillQueueBelongAgent': 'Fila de habilidades (agente multimídia)',
  'oifde.service.invoke.CallTransferType.transferToSkillQueueByRouteConfig': 'Transferência para Fila de Habilidades (Rota Personalizada)',
  'oifde.service.invoke.CallTransferType.transferToMultimediaMessageToLastAgent': 'Mensagem do Cliente',
  'oifde.service.invoke.CallTransferType.transferToMultimediaMessageToSkillQueue': 'Mensagem da fila habilidades (nome ou código acesso)',
  'oifde.service.invoke.CallTransferType.transferToMultimediaMessageToAgent': 'Mensagem do agente',
  'oifde.service.invoke.CallTransferTypeGroup.multimediaMessage': 'Mensagens multimídia',
  'oifde.service.invoke.CallTransferRecordFlag': 'Ativar gravação',
  'oifde.service.invoke.CallTransferRecordFlag.desc': '', // 本身就为空不展示
  'oifde.service.invoke.recordPath': 'Nome do arquivo de gravação',
  'oifde.service.invoke.recordPath.desc': 'Digite a variável para armazenar o nome do arquivo de gravação.',
  'oifde.service.invoke.recordServiceNodeID': 'ID do nó de serviço de registro',
  'oifde.service.invoke.languageType.language0': 'Putonghua',
  'oifde.service.invoke.languageType.language1': 'Inglês',
  'oifde.service.invoke.languageType.language2': 'Chinês (Cantonês)',
  'oifde.service.invoke.languageType.language3': 'Tcheco',
  'oifde.service.invoke.languageType.language4': 'Dinamarquês',
  'oifde.service.invoke.languageType.language5': 'Holandês',
  'oifde.service.invoke.languageType.language6': 'Finlandês',
  'oifde.service.invoke.languageType.language7': 'Francês',
  'oifde.service.invoke.languageType.language8': 'Alemão',
  'oifde.service.invoke.languageType.language9': 'Grego',
  'oifde.service.invoke.languageType.language10': 'Húngaro',
  'oifde.service.invoke.languageType.language11': 'Islandês',
  'oifde.service.invoke.languageType.language12': 'Italiano',
  'oifde.service.invoke.languageType.language13': 'Japonês',
  'oifde.service.invoke.languageType.language14': 'Coreano',
  'oifde.service.invoke.languageType.language15': 'Norueguês',
  'oifde.service.invoke.languageType.language16': 'Polonês',
  'oifde.service.invoke.languageType.language17': 'Português',
  'oifde.service.invoke.languageType.language18': 'Russo',
  'oifde.service.invoke.languageType.language19': 'Eslovaco',
  'oifde.service.invoke.languageType.language20': 'Espanhol',
  'oifde.service.invoke.languageType.language21': 'Sueco',
  'oifde.service.invoke.languageType.language22': 'Turco',
  'oifde.service.invoke.languageType.language23': 'Uzbequistão',
  'oifde.service.invoke.languageType.language24': 'Tailândia',
  'oifde.service.invoke.languageType.language80': 'Kiswahili',
  'oifde.service.invoke.languageType.language81': 'Árabe',
  'oifde.service.invoke.languageType.language82': 'Hindi',
  'oifde.service.invoke.languageType.language83': 'Filipino',
  'oifde.service.invoke.languageType.language84': 'Cambojano',
  'oifde.service.invoke.languageType.language85': 'Bengalês',
  'oifde.service.invoke.languageType.language86': 'Palestino',
  'oifde.service.invoke.languageType.language87': 'Vietnamita',
  'oifde.service.invoke.languageType.language88': 'Indonésio',
  'oifde.service.invoke.languageType.language89': 'Nepalês',
  'oifde.service.invoke.languageType.language90': 'Malgaxe',
  'oifde.service.invoke.languageType.language92': 'Etíope',
  'oifde.service.invoke.languageType.language93': 'Malaio',
  'oifde.service.invoke.variableType.type1': 'Número de telefone',
  'oifde.service.invoke.variableType.type3': 'Número',
  'oifde.service.invoke.variableType.type4': 'Tempo (hh:mm:ss)',
  'oifde.service.invoke.variableType.type5': 'Data (aaaammdd)',
  'oifde.service.invoke.variableType.type6': 'Preço',
  'oifde.service.invoke.modelType.customization': 'Valor da variável personalizada',
  'oifde.service.invoke.multiVoiceLanguage': 'Línguas',
  'oifde.service.invoke.multiVoiceType': 'Tipo',
  'oifde.service.invoke.recordServiceNodeID.desc': 'Digite a variável que armazena o ID do nó de serviço de gravação (usado no modo de pool de call center).',
  'oifde.service.invoke.modelType.customization.desc': 'Interrupção indica se pode ser interrompido. Se puder ser interrompido, preencha a caixa de entrada do tempo mínimo de reprodução. Variáveis são preenchidas com true ou false, true indica que elas podem ser reconhecidas e quebradas, e o tempo mínimo de reprodução pode ser passado. False pode ser reconhecido, mas não quebrado, e o preenchimento de erros é o valor padrão de false.',
  'oifde.service.invoke.breakMinTime': 'Duração mínima de reprodução de voz',
  'oifde.ringService.displayName': 'Campainha',
  'oifde.assignValueService.displayName': 'Atribuir',
  'oifde.transferService.displayName': 'Transferir',
  'oifde.service.inpytOrDrag': 'inserir ou arrastar variáveis',
  'oifde.service.operateAsFunction': 'Operar como o método fornecido',
  'oifde.basicOdfsSubFlowService.displayName': 'Subprocesso (Inteligente)',
  'oifde.basicCcivrSubFlowService.displayName': 'Subprocesso (Normal)',
  'oifde.yugaoRecordServiceNew.displayName': 'Coleção de gravação',
  'oifde.conditionServiceNew.displayName': 'Julgamento de ramificação',
  'oifde.service.invoke.callTranseferService.transData.wait': 'Fila em fila',
  'oifde.service.invoke.callTranseferService.transData.data': 'Transferir dados',
  'oifde.service.invoke.callTranseferService.transData.isSpecifyAccessCode': 'Especificar o código de acesso do canal',
  'oifde.service.invoke.callTranseferService.transData.type': 'Modelo de cliente regular',
  'oifde.service.invoke.callTranseferService.transData.time': 'Intervalo de tempo (minutos)',
  'oifde.service.invoke.callTranseferService.transData.timeTips': 'Exemplo: 100, transferido para o agente mais recentemente contatado nos últimos 100 minutos; O valor recomendado é de 1 a 10080.',
  'oifde.service.invoke.callTranseferService.transData.timeErrorTips': 'Insira um inteiro positivo ou uma variável',
  'oifde.service.invoke.callTranseferService.transData.starttime': 'Intervalo de datas',
  'oifde.service.invoke.callTranseferService.transData.starttimeTips': 'Agentes recentemente contatados em um intervalo de datas especificado',
  'oifde.service.invoke.callTranseferService.transData.type.selectTime': 'Especificar minutos',
  'oifde.service.invoke.callTranseferService.transData.type.selectStartTime': 'Data especificada',
  'oifde.service.invoke.callTranseferService.transData.type.currentDay': 'No mesmo dia',
  'oifde.service.invoke.callTranseferService.transData.paramValueTips': "Insira um valor de parâmetro. ",
  'oifde.service.invoke.callTranseferService.transData.varTips': "Variáveis podem ser inseridas. As variáveis globais começam com GLOBAL, as variáveis de fluxo, as variáveis de sistema começam com SYS, as variáveis TUC começam com TOC e as variáveis de solicitação IVR começam com IVRREQUEST. Use {'${}'} para quebrar variáveis, por exemplo, {'${FLOW.variable}'}.",
  'oifde.service.invoke.ruleValidationService.name': 'Verificação de Regras V2',
  'oifde.service.invoke.ruleValidationService.variable': 'Variáveis a serem verificadas',
  'oifde.service.invoke.ruleValidationService.specialEndKey': 'Fim das teclas especiais',
  'oifde.service.invoke.ruleValidationService.specialEndKey.option1': 'com o número #',
  'oifde.service.invoke.ruleValidationService.specialEndKey.option2': 'com *',
  'oifde.service.invoke.ruleValidationService.specialEndKey.option3': 'com # ou *',
  'oifde.service.invoke.ruleValidationService.specialEndKey.option4': 'Sem teclas especiais',
  'oifde.service.invoke.ruleValidationService.containsChar': 'Caracteres Contidos',
  'oifde.service.invoke.ruleValidationService.containsChar.option1': 'Corresponder a os números',
  'oifde.service.invoke.ruleValidationService.containsChar.option2': 'Corresponde a as letras maiúsculas',
  'oifde.service.invoke.ruleValidationService.containsChar.option3': 'Corresponde a as letras minúsculas',
  'oifde.service.invoke.ruleValidationService.containsChar.option4': 'Corresponde a letras, números, sublinhados',
  'oifde.service.invoke.ruleValidationService.containsChar.option5': 'Caracteres em branco',
  'oifde.service.invoke.ruleValidationService.validationRules': 'Regra de validação',
  'oifde.service.invoke.ruleValidationService.validationRules.ruleParam': 'Parâmetros da regra',
  'oifde.service.invoke.ruleValidationService.validationRules.ruleParamValueTips': "As variáveis podem ser inseridas, variáveis globais começam com GLOBAL, variáveis de processo com FLOW, variáveis de sistema com SYS, variáveis TUC com TOC e variáveis de solicitação IVR com IVRREQUEST. Exemplo: FLOW.variable",
  'oifde.service.invoke.ruleValidationService.validationRules.minLength': 'Comprimento mínimo',
  'oifde.service.invoke.ruleValidationService.validationRules.minLength.valueTips': 'Insira o comprimento mínimo.',
  'oifde.service.invoke.ruleValidationService.validationRules.maxLength': 'Comprimento máximo',
  'oifde.service.invoke.ruleValidationService.validationRules.maxLength.valueTips': 'Digite o comprimento máximo.',
  'oifde.service.invoke.ruleValidationService.validationRules.rangeLength': 'Faixa de comprimento',
  'oifde.service.invoke.ruleValidationService.validationRules.min': 'Mínimo',
  'oifde.service.invoke.ruleValidationService.validationRules.min.valueTips': 'Insira um valor mínimo.',
  'oifde.service.invoke.ruleValidationService.validationRules.max': 'Máximo',
  'oifde.service.invoke.ruleValidationService.validationRules.max.valueTips': 'Insira um valor máximo.',
  'oifde.service.invoke.ruleValidationService.validationRules.range': 'Intervalo de valores',
  'oifde.service.invoke.ruleValidationService.validationRules.email': 'Email',
  'oifde.service.invoke.ruleValidationService.validationRules.url': 'URL',
  'oifde.service.invoke.ruleValidationService.validationRules.dateFormat': 'Formato da hora',
  'oifde.service.invoke.ruleValidationService.validationRules.dateFormat.valueTips': 'Digite um formato de hora, como data (aaaa-MM-dd) ou data e hora (aaaa-MM-dd HH:mm:ss).',
  'oifde.service.invoke.ruleValidationService.validationRules.number': 'Número decimal',
  'oifde.service.invoke.ruleValidationService.validationRules.digits': 'Números inteiros positivos',
  'oifde.service.invoke.ruleValidationService.validationRules.ip': 'Endereço IP',
  'oifde.service.invoke.ruleValidationService.validationRules.alpha': 'Sublinhado alfanumérico (não começa com um número)',
  'oifde.service.invoke.timeSelect.workDate': 'Especificar data de trabalho',
  'oifde.service.invoke.timeSelect.noWorkDate': 'Especificar data de não-trabalho',
  'oifde.service.invokemethod.offsetMonth.desc': 'Deslocamento mensal',
  'oifde.service.invokemethod.offsetMonth.parm1.desc': 'Variável de tempo a ser deslocada, string, formato aaaaMM',
  'oifde.service.invokemethod.offsetMonth.parm2.desc': 'Número de deslocamentos, do tipo inteiro, que pode ser negativo',
  'oifde.service.invokemethod.offsetMonth.resultObj.desc': 'Retorna os dados após o deslocamento.',
  'oifde.service.invokemethod.offsetHour.desc': 'Deslocamento por hora',
  'oifde.service.invokemethod.offsetHour.parm1.desc': 'Variável de hora a ser deslocada, tipo string, no formato aaaaMMddHHmmss',
  'oifde.service.invokemethod.offsetHour.parm2.desc': 'Número de deslocamentos, do tipo inteiro, que pode ser negativo',
  'oifde.service.invokemethod.offsetHour.resultObj.desc': 'Retorna os dados após o deslocamento.',
  'oifde.interfaceInvoke.displayName': 'Chamada de interface',
  'oifde.service.invoke.yugaoRecord.type': 'Subproceso:',
  'oifde.service.invoke.yugaoRecord.mode': 'Padrão:',
  'oifde.service.invoke.subflow.subFlowNode': 'Subprocesso',
  'oifde.service.invokemethod.group.mathodType4': 'Método de operação de data',
  'oifde.transdata.get.example': 'Por exemplo, se os dados associados forem {\'{\'}"data":{\'{\'}"test": "111"{\'}\'}{\'}\'}, você poderá inserir data.test no valor da variável para obter os dados "111".',
  'oifde.service.invoke.basicAvatarReplyService.name': 'Resposta do Avatar',
  'oifde.service.invoke.basicAvatarReplyService.actionType': 'Operação',
  'oifde.service.invoke.basicAvatarReplyService.start': 'Iniciar',
  'oifde.service.invoke.basicAvatarReplyService.image': 'Imagem',
  'oifde.service.invoke.basicAvatarReplyService.avatarImage': 'Imagem do Avatar',
  'oifde.service.invoke.basicAvatarReplyService.ttsSpeaker': 'Alto-falante',
  'oifde.service.invoke.basicAvatarReplyService.compositeVideoSetting': 'Configuração de vídeo composto',
  'oifde.service.invoke.basicAvatarReplyService.compositeVideoSetting.desc': 'Configuração de vídeo composta, que afeta diretamente o efeito de exibição de pessoa digital. Habilite esta função quando necessário.',
  'oifde.service.invoke.basicAvatarReplyService.imageSetting': 'Configurações de imagem e vídeo',
  'oifde.service.invoke.basicAvatarReplyService.action': 'Acções',
  'oifde.service.invoke.basicAvatarReplyService.frontImage': 'Imagem Frontal',
  'oifde.service.invoke.basicAvatarReplyService.backendImage': 'Imagem de back-end',
  'oifde.service.invoke.basicAvatarReplyService.frontVideo': 'Vídeo Frontal',
  'oifde.service.invoke.basicAvatarReplyService.backendVideo': 'Vídeo de backend',
  'oifde.service.invoke.basicAvatarReplyService.xPosition': 'Abscissa',
  'oifde.service.invoke.basicAvatarReplyService.yPosition': 'Ordenar',
  'oifde.service.invoke.basicAvatarReplyService.scale': 'Escala',
  'oifde.service.invoke.basicAvatarReplyService.beginTime': 'Hora de início (ms)',
  'oifde.service.invoke.basicAvatarReplyService.duration': 'Duração da exibição (ms)',
  'service.invoke.virtualHumanConfig': 'Configuração Humana Virtual',
  'service.virtualHuman.videoParam': 'Parâmetros de edição de vídeo',
  'service.virtualHuman.captions': 'Legendas',
  'service.invoke.asrAdvancedSetting.open': 'Ativar',
  'service.invoke.asrAdvancedSetting.close': 'Desativar',
}
