import { defineStore } from "pinia";

// 记录离开路由
export const useRecordLeaveRoute = defineStore('recordLeaveRoute', {
  state: () => {
    return {
      leaveRoute: '',
    }
  },
  actions: {
    // 修改离开路由
    changeLeaveRoute(leaveRoute = '') {
      this.leaveRoute = leaveRoute
    },
  }
})

// WhatsApp模板保存通知WhatsApp模板消息
export const useWhatsAppTemplateSave = defineStore('whatsAppTemplateSave', {
  state: () => {
    return {
      templateId: '',
    }
  },
  actions: {
    saveTemplateId(templateId = '') {
      this.templateId = templateId
    }
  }
})

// WhatsApp模板消息保存后通知页面刷新
export const useWhatsAppMessageSave = defineStore('whatsAppMessageSave', {
  state: () => {
    return {
      isRefresh : false,
    }
  },
  actions: {
    changeRefreshStatus(flag) {
      this.isRefresh = flag
    }
  }
})

// WhatsApp模板保存后通知页面刷新
export const useWhatsAppSave = defineStore('whatsAppSave', {
  state: () => {
    return {
      isRefresh : false,
    }
  },
  actions: {
    changeRefreshStatus(flag) {
      this.isRefresh = flag
    }
  }
})
