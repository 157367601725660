import i18n from '@/lang/index'

const $t = i18n.global.t

const isalesRoutes = {
  default: [
    {
      path: '/isalesTaskManagement',
      name: '6007001',
      title: $t('isales.work.title.outboundCallTask'),
      component: () => import('@/views/isales/views/taskManagement/taskManagement.vue')
    },
    {
      path: '/isalesAppointment',
      name: '6007005',
      title: $t('isales.taskmanagement.title.appoint'),
      component: () => import('@/views/isales/views/appointment/appointmentList.vue')
    },
    {
      path: '/isalesRbtRecognition',
      name: '6007007',
      title: $t('isales.taskresult.field.failsRingbacktone'),
      component: () => import('@/views/isales/views/rbtRecognition/rbtrecognition.vue')
    },
    {
      path: '/agentworkstatisticsmanage',
      name: '6007008',
      title: $t('isales.taskmanagement.title.agentStatistics'),
      component: () => import('@/views/isales/views/agentworkstatistics/agentworkstatisticsmanage.vue')
    },
    {
      path: '/isalesMultimediaManagement',
      name: '6007002',
      title: $t('isales.work.title.multimediaManagement'),
      component: () => import('@/views/isales/views/multimediaManagement/multimediaManagement.vue')
    },
    {
      path: '/isalesSpeclist',
      name: '6007006',
      title: $t('isales.speclistmanage.menu.name'),
      component: () => import('@/views/isales/views/speclistManagement/speclistManagement.vue')
    },
    {
      path: '/isalesOutboundBusinessStatisticsManage',
      name: '6007009',
      title: $t('isales.statistics.business.title'),
      component: () => import('@/views/isales/views/outboundBusinessStatistics/outboundBusinessStatisticsManage.vue')
    },
    {
      path: '/manualTask',
      name: '6007004',
      title: $t('isales.enum.workSpace'),
      component: () => import('@/views/isales/views/manual/manualTask.vue')
    },
    {
      path: '/dataSourceManagement',
      name: '6002008',
      title: $t('isales.enum.workSpace'),
      component: () => import('@/views/isales/views/dataSourceManagement/dataSourceManagement.vue')
    },
    {
      path: '/OutboundCallWorkbenchDetails',
      name: 'OutboundCallWorkbenchDetails',
      title: $t('isales.taskmanagement.title.addAuto'),
      component: () => import('@/views/isales/views/outboundCallWorkbenchDetails/OutboundCallWorkbenchDetails.vue'),
    },
  ],
  pageWithSideNav: [
    {
      path: '/outboundCallTemplateList',
      name: '8004006',
      title: $t('isales.enum.workSpace'),
      component: () => import('@/views/isales/views/outboundCallTemplate/outboundCallTemplateList.vue')
    },
    {
      path: '/specialdayList',
      name: '8004001',
      title: $t('isales.enum.workSpace'),
      component: () => import('@/views/isales/views/specialday/specialdayList.vue')
    },
    {
      path: '/blackList',
      name: '8004002',
      title: $t('isales.enum.workSpace'),
      component: () => import('@/views/isales/views/blackList/blackList.vue')
    },
    {
      path: '/outboundDataProp',
      name: '8004004',
      title: $t('isales.enum.workSpace'),
      component: () => import('@/views/isales/views/outboundDataProp/outboundDataProp.vue')
    },
    {
      path: '/fileImportRule',
      name: '8004008',
      title: $t('isales.enum.workSpace'),
      component: () => import('@/views/isales/views/fileservermanage/fileImportRule.vue')
    },
    {
      path: '/outboundResult',
      name: '8004005',
      title: $t('isales.enum.workSpace'),
      component: () => import('@/views/isales/views/outboundresult/outboundResultManage.vue')
    },
    {
      path: '/fileServerManage',
      name: '8004007',
      title: $t('isales.enum.workSpace'),
      component: () => import('@/views/isales/views/fileservermanage/fileServerManage.vue')
    },
    {
      path: '/outboundBusinessResultManage',
      name: '8004003',
      title: $t('isales.enum.workSpace'),
      component: () => import('@/views/isales/views/outboundbusinessresult/outboundBusinessResultManage.vue')
    },
    {
      path: '/skillQueueManage',
      name: '8001010',
      title: $t('isales.enum.workSpace'),
      component: () => import('@/views/isales/views/skillqueue/skillQueueManage.vue')
    },
    {
      path: '/agentManage',
      name: '8001011',
      title: $t('isales.enum.workSpace'),
      component: () => import('@/views/isales/views/agent/agentManage.vue')
    },
    {
      path: '/calledConfig',
      name: '8001012',
      title: $t('isales.enum.workSpace'),
      component: () => import('@/views/isales/views/calledconfig/calledConfigManage.vue')
    },
  ],
  pageWithBackHeader: [
    {
      path: '/isalesManualCallTask',
      name: 'isalesManualCallTask',
      title: $t('isales.taskmanagement.title.addAuto'),
      component: () => import('@/views/isales/views/manualCallTask/manualCallTask.vue'),
    },
    {
      path: '/isalesTaskDetail',
      name: 'isalesTaskDetail',
      title: $t('isales.task.detail'),
      component: () => import('@/views/isales/views/taskDetail/isalesTaskDetail.vue'),
      children: []
    },
    {
      path: '/isalesAppointmentWithBack',
      name: 'isalesAppointmentWithBack',
      title: $t('isales.taskmanagement.title.appoint'),
      component: () => import('@/views/isales/views/appointment/taskAppointmentList.vue')
    },
    {
      path: '/isalesSystemAutoCallTask',
      name: 'isalesSystemAutoCallTask',
      title: $t('isales.taskmanagement.title.addAuto'),
      component: () => import('@/views/isales/views/systemAutoCallTask/systemAutoCallTask.vue')
    },
    {
      path: '/multimediaResult',
      name: 'multimediaResult',
      title: $t('isales.notification.viewResult.button'),
      component: () => import('@/views/isales/views/multimediaManagement/multimediaResult.vue')
    },
    {
      path: '/multimediaDataImport',
      name: 'multimediaDataImport',
      title: $t('isales.notification.import.button'),
      component: () => import('@/views/isales/views/multimediaManagement/multimediaDataImport.vue')
    },
    {
      path: '/isalesManualCallTemplate',
      name: 'isalesManualCallTemplate',
      title: $t('isales.template.title.addManual'),
      component: () => import('@/views/isales/views/outboundCallTemplate/manualCallTemplate.vue'),
    },
    {
      path: '/isalesSystemAutoCallTemplate',
      name: 'isalesSystemAutoCallTemplate',
      title: $t('isales.taskmanagement.title.sysAuto.template'),
      component: () => import('@/views/isales/views/outboundCallTemplate/systemAutoCallTemplate.vue')
    }
  ],
  pageWithSelfTab: [
    {
      path: '/isalesManualCallTaskAdd',
      name: 'isalesManualCallTaskAdd',
      title: $t('isales.taskmanagement.title.addAuto'),
      component: () => import('@/views/isales/views/manualCallTask/manualCallTaskAdd.vue')
    },
    {
      path: '/isalesSystemAutoCallTaskAdd',
      name: 'isalesSystemAutoCallTaskAdd',
      title: $t('isales.taskmanagement.title.addAuto'),
      component: () => import('@/views/isales/views/systemAutoCallTask/systemAutoCallTaskAdd.vue')
    },
    {
      path: '/isalesIVRCallTaskAdd',
      name: 'isalesIVRCallTaskAdd',
      title: $t('isales.taskmanagement.title.addAuto'),
      component: () => import('@/views/isales/views/systemAutoCallTask/isalesIVRCallTaskAdd.vue')
    },
    {
      path: '/isalesSystemAutoCallTemplateAdd',
      name: 'isalesSystemAutoCallTemplateAdd',
      title: $t('isales.taskmanagement.title.sysAuto.template'),
      component: () => import('@/views/isales/views/outboundCallTemplate/isalesSystemAutoCallTemplateAdd.vue')
    },
    {
      path: '/isalesIVRCallTemplateAdd',
      name: 'isalesIVRCallTemplateAdd',
      title: $t('isales.taskmanagement.title.sysAuto.template'),
      component: () => import('@/views/isales/views/outboundCallTemplate/isalesIVRCallTemplateAdd.vue')
    },
    {
      path: '/isalesManualCallTemplateAdd',
      name: 'isalesManualCallTemplateAdd',
      title: $t('isales.template.title.addManual'),
      component: () => import('@/views/isales/views/outboundCallTemplate/isalesManualCallTemplateAdd.vue'),
    },
  ]
}
export default isalesRoutes
