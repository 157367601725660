import dayjs, { type OpUnitType, type QUnitType } from "dayjs";
import AiccElMessage from '@/utils/el-message';
// @ts-ignore
import i18n from "@/lang";

// 对特殊字符进行编码
export function safeHtmlEncode(inputStr: string): string {
  let ret = inputStr;
  ret = ret.replace(/&/g, "&amp;");
  ret = ret.replace(/</g, "&lt;");
  ret = ret.replace(/>/g, "&gt;");
  ret = ret.replace(/"/g, "&quot;");
  ret = ret.replace(/'/g, "&#39;");
  ret = ret.replace(/\//g, "&#47;");
  ret = ret.replace(/\\/g, "&#92;");
  ret = ret.replace(/\(/g, "&#40;");
  ret = ret.replace(/\)/g, "&#41;");
  return ret;
}

// 工号正则校验规则：由3~5位数字构成
const workNoReg: RegExp = /^\d{3,5}$/;

/**
 * 校验是否为工号
 * <p/>
 * 该方法只是校验是否类似工号，并不保证正确性；工号校验方法：由3~5位数字构成，
 * 取值区间为(100, 50000]
 * @param num 需要校验的输入
 * @return boolean 校验成功为true
 */
export const isWorkNo = (num?: String | Number): boolean => {
  // 空校验
  if (!num) {
    return false;
  }
  if (!workNoReg.test(String(num))) {
    return false;
  }
  const callNo: number = Number(num);
  return callNo > 100 && callNo <= 50000;
};

/**
 * 防抖函数
 * @param func 需要执行的函数
 * @param wait 防抖时间间隔,单位毫秒,默认500
 * @return {Function} 在 wait 时间内，仅会执行一次
 * @description 返回一个新的函数，这个新函数会在防抖时间间隔内被连续调用时，只执行最后一次调用。
 * @example
 * function handleInput() {
 *   console.log('input changed');
 * }
 * const debouncedHandleInput = debounce(handleInput, 500);
 * debouncedHandleInput();
 */
export function debounce<T extends (...args: any[]) => any>(func: T, wait: number = 500): (...args: Parameters<T>) => void {
  let timeoutId: ReturnType<typeof setTimeout> | null = null;
  return function debounced(this: typeof debounced, ...args: Parameters<T>): void {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    timeoutId = setTimeout(() => func.apply(this, args), wait);
  };
}

/**
 * 限流函数
 * @param func 需要执行的函数
 * @param interval 限流时间间隔，默认值500
 * @param unit 限流时间单位，默认为毫秒
 * @description 返回一个新的函数，这个新函数会在防抖时间间隔内被连续调用时，只执行第一次调用。
 * @example
 * const func = (num) => ++num // 需要执行限流的函数
 * const throttledHandle = throttle(func, 500, 'millisecond') // 函数限流处理
 * throttledHandle(1) // 结果返回2
 */
export function throttle<T extends (...args: any[]) => any>(func: T, interval: number = 500, unit: QUnitType | OpUnitType = 'millisecond') {
  let last: dayjs.Dayjs | null = null // 维护上次执行的时间
  return function throttled(this: typeof throttled, ...args: Parameters<T>): ReturnType<T> | undefined {
    const current = dayjs() // 获取当前时间
    // 从未执行过，或者执行的时间间隔超过配置值，则触发函数执行
    if (!last || current.diff(last, unit) >= interval) {
      last = current
      return func.apply(this, args)
    }
  }
}


// 一天的毫秒数
const MILLI_OF_ONE_DAY: Readonly<number> = 24 * 60 * 60 * 1000;

/**
 * 高级搜索时间范围生成器
 * @description
 * 1. 根据传入的preDay参数，生成指定的时间范围。精确到毫秒
 * 2. 此方法仅用于<b>高级搜索</b>组件的timeSelect提供快捷选项，返回的数组中，截止日期默认为null
 * @param {number} preDay 提前天数
 * @param {dayjs.Dayjs|Date|Number|String} today 可选参数，防止因时间跨天导致数据生成结果截止日期不一致
 * @return {Array<dayjs.Dayjs|Date>} 时间区间，精确到毫秒。最后一个元素值固定为null
 * @example
 * // 获取最近3天的时间范围,设当前时区为 UTC+0
 * const now = '2023-05-06T12:00:00Z'
 * buildTimeSelectOptionItem(3, now) // [2023-05-03T00:00:00Z, null]
 */
export function buildTimeSelectOptionItem(preDay: number, today?: dayjs.Dayjs|Date|Number|String): Array<Date|null> {
  const currentDate = today ? new Date(today.valueOf()) : new Date();
  const startTimestamp: number = currentDate.setHours(0, 0, 0, 0);
  const startTime: number = startTimestamp - ((preDay - 1) * MILLI_OF_ONE_DAY);
  return [new Date(startTime), null];
}

/**
 * 获取时间范围
 * @description 本方法为获取高级搜索“timeSelect”中，传入的时间范围
 * @param param 从高级搜索组件接收的参数
 * @return {Array<dayjs.Dayjs|Date|String>}
 */
export const getTimeRange = (param: any): Array<dayjs.Dayjs|Date|String|Number> => {
  const timeRange = param.timeRange;
  if (timeRange == "custom") {
    return param.timeRangeCustom;
  }
  if (Array.isArray(timeRange)) {
    return timeRange;
  }
  // 重置时，其值非数组，需要取值value
  return timeRange.value;
}

/**
 * 提前天数信息 接口对象
 */
export interface PreDayInfo {
  /** 允许的最大提前天数 */
  maxPreDay: number,
  /** 超出最大提前天数的告警信息函数 */
  exceededMaxPreDayMsg(): string,
}

/**
 * 检查时间范围的合法性
 * @param {Array<dayjs.Dayjs|Date|String|Number>} timeRange 时间范围数组
 * @param {PreDayInfo} preDayInfo 提前天数信息
 * @param {() => string} illegalTimeRangeMsg 可选参数。当参数timeRange非法时，执行的回调函数，返回要展示的告警信息
 * @example
 * const preDayInfo = {
 *  maxPreDay: 5,
 *  exceededMaxPreDayMsg: () => i18n.global.t('Warning!')
 * }
 *
 * const timeRage1 = [new Date(), null]
 * validDateRangeHandler(timeRange1, preDayInfo) // true
 *
 * const now = dayjs()
 * const timeRage2 = [now.subtract(7, 'days'), now.add(7, 'days')]
 * validDateRangeHandler(timeRange2, preDayInfo) // false
 */
export function validDateRangeHandler(timeRange: Array<dayjs.Dayjs|Date|String|Number>, preDayInfo: PreDayInfo, illegalTimeRangeMsg?: () => string): boolean {
  if (!timeRange || timeRange.length < 2 || !timeRange[0]) {
    const warningFunc = illegalTimeRangeMsg || (() => i18n.global.t("ccd.contact.message.timeisempty"))
    AiccElMessage.error(warningFunc());
    return false;
  }
  const begin = dayjs(timeRange[0].valueOf());
  const maxTime: dayjs.Dayjs = begin.add(preDayInfo.maxPreDay, 'days') // 允许的最大截止时间
  // 实际选择的截止时间
  // 因设置提前天数快捷选项时，截止时间可以为null，故需要对其做适配
  const endTime: dayjs.Dayjs = dayjs(timeRange[1]?.valueOf()) || maxTime;
  if (maxTime.isBefore(endTime)) {
    AiccElMessage.error(preDayInfo.exceededMaxPreDayMsg());
    return false;
  }
  return true;
}

/**
 * 下载操作的错误回调函数
 * @param errResp 错误响应信息
 */
export const downloadErrHandle = (errResp: any) => {
  const message: String = errResp.message
  const code: String = message.substring(message.indexOf('!') + 1, message.indexOf(':'))
  switch (code) {
    case '60199-1':
      AiccElMessage.error(i18n.global.t('ccm.agent.message.download.err'))
      return
    default:
      AiccElMessage.error(i18n.global.t('ccm.agent.contact.downloadFail'))
      return
  }
}
