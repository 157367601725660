<template>
  <sweet-dialog v-model="dialogVisible" width="616px"
                :close-on-click-modal="false" destroy-on-close :modal="!isSink">
    <template #header>
      <div class="aicc-back-header">
        <i class="back-btn" v-if="isSink" @click="dialogVisible = false"></i>
        <span>{{ $t('ccm.calledroute.title.selectivrflow') }}</span>
      </div>
    </template>
    <div class="search-ivr">
      <sweet-input v-model="searchName" :placeholder="$t('ccm.ivr.label.processdesc')" @keydown.enter="doSearchIVRFlow" clearable>
        <template #prefix>
          <sweet-icon class="input-icon" @click="doSearchIVRFlow()" :title="$t('chat.session.label.search')">
            <search/>
          </sweet-icon>
        </template>
      </sweet-input>
    </div>
    <aicc-table
      :tableData="showModel"
      max-height="384"
      :tableColumns="tableColumns"
      :tableTools="{ showPagination: true, columnConfig: false }"
      :paginationConfig="paginationConfig"
      @handlePagination="handlePagination"
      ref='selectIvrRef'>
    </aicc-table>
    <template #footer>
          <span class="dialog-footer">
            <sweet-button @click="dialogVisible = false">{{ $t('ccm.calledroute.button.cancel') }}</sweet-button>
            <sweet-button type="primary" @click="save">{{ $t('aicc.ok') }}</sweet-button>
          </span>
    </template>
  </sweet-dialog>
</template>
<script>

import {ivrList} from "@/views/ccmanagement/ccma-api";
import AiccElMessage from '@/utils/el-message';
import {CCIVR} from "@/views/ccmanagement/entity/CCIVR.entity";
import $ from 'jquery';

window.$ = $;
window.jQuery = $;

export default {
  name: 'SelectIvr',
  props: {
    deviceType: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
      default: false
    },
    isSink: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      }
    },
  },
  data() {
    const selectedIVR = new CCIVR()
    return {
      selectedIVR,
      selectItem: [],
      resultModel: [],
      target: '',
      //分页控件属性
      pageStart: 0,
      recordPerPage: 10,
      showPagination: true,
      showModel: [],
      employeeCount: 0,
      id: "",
      searchName: "",
      allIVRList: [],
      searchResultIVRLits: [],

      tableColumns: [
        {
          type: 'radio',
          radioKey: 'id', // 单选绑定的字段名
          width: '50px',
          'show-overflow-tooltip': false
        },
        {
          label: this.$t('ccm.accesscode.label.index'),
          prop: 'index',
        },
        {
          label: this.$t('ccm.ivr.label.processdesc'),
          prop: 'description',
        },
        {
          label: this.$t('ccm.ivr.label.processaccesscode'),
          prop: 'accessCode',
        },
        {
          label: this.$t('ccm.ivr.label.processfilename'),
          prop: 'flowFileName',
        }
      ],
      paginationConfig: {
        pageSizes: [10, 20, 50, 100], // 每页条数列表
        pageSize: 10, // 每页条数
        total: 0, //总条数
        layout: "sizes, total, prev, pager, next" // 分页组件布局配置
      },
      limit: 10,
      offset: 0,
    }
  },
  methods: {
    async queryAllIVRS() {
      ivrList().then(data => {
        this.resultModel = data;
        if (this.resultModel["retCode"] == 0) {
          for (let k = 0; k < this.resultModel["result"].length; k++) {
            this.resultModel["result"][k]["index"] = k + 1;
          }
          const ivrList = this.resultModel["result"];
          this.allIVRList = ivrList;
          this.paginationConfig.total = ivrList.length;
          let count = this.recordPerPage;
          if (ivrList.length < count) {
            count = ivrList.length;
          }
          for (let j = 0; j < count; j++) {
            this.showModel[j] = ivrList[j];
          }
        } else {
          AiccElMessage.error($t('ccm.calledroute.tip.selectivrexception'));
        }
      })
    },

    save() {
      let selectItem = this.$refs.selectIvrRef.getRadioCurrentRow();
      this.selectedIVR = selectItem;
      if (!selectItem || !selectItem.id) {
        AiccElMessage.info($t('ccnotification.message.choose'));
        return;
      }

      let popups = $('.popwin');
      let popid = popups.eq(popups.length - 1).data('popid');
      if (null != this.deviceType) {
        let sendParam = {};
        sendParam["deviceType"] = this.deviceType;
        sendParam["ivrParam"] = this.selectedIVR;
        this.$emit('selectConfigIvr', sendParam);
      } else {
        this.$emit("selectIVR." + popid, this.selectedIVR);
        this.$emit("selectIVR", this.selectedIVR);
      }

      this.resultModel = [];
      this.selectItem = [];
      this.dialogVisible = false;
    },

    doSearchIVRFlow() {
      if (this.searchName == "") {
        this.queryAllIVRS();
      } else {
        let allIVRList = this.allIVRList;
        let ivrList = [];
        for (let i = 0; i < allIVRList.length; i++) {
          if (allIVRList[i].description.indexOf(this.searchName) != -1) {
            ivrList.push(allIVRList[i]);
          }
        }
        this.paginationConfig.total = ivrList.length;
        let count = this.recordPerPage;
        if (ivrList.length < count) {
          count = ivrList.length;
        }
        this.showModel = [];
        for (let k = 0; k < ivrList.length; k++) {
          ivrList[k]["index"] = k + 1;
        }
        this.searchResultIVRLits = ivrList;
        for (let j = 0; j < count; j++) {
          this.showModel[j] = ivrList[j];
        }
      }
    },

    // 因直接调用平台接口查询IVR流程，故采用前端分页
    handlePagination(params) {
      this.showModel = [];
      if (this.resultModel["retCode"] == 0) {
        let ivrList;
        if (this.searchName == "") {
          ivrList = this.resultModel["result"];
        } else {
          ivrList = this.searchResultIVRLits;
        }
        const start = params.offset;
        const end = Math.min(start + params.limit, this.paginationConfig.total);
        for (let i = start; i < end; ++i) {
          this.showModel.push(ivrList[i]);
        }
      }
    },

    openDialogInit() {
      this.limit = this.paginationConfig.pageSize;
      this.offset = 0;
      this.searchName = '';
      this.queryAllIVRS();
    },
  },
  mounted() {
    this.openDialogInit();
  }
}
</script>
<style lang="less" scoped>
@import "@/views/ccmanagement/css/common.less";

.search-ivr {
  margin-bottom: 16px;
  width: 240px;
}

.input-icon {
  cursor: pointer;
  transition: all 0.2s;

  &:active {
    transform: scale(0.9);
  }
}
</style>