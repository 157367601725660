<template>
  <div>
    <sweet-dialog v-model="dialogVisible" :title="$t('isales.data.propConfig.button.configProp')" width="896px" class="aicc-dialog-limit-height">
      <sweet-input v-model="searchPropName"
                   :placeholder="$t('isales.data.prop.inputPropName')"
                   @keydown.enter="handleSearch"
                   style="width: 50%">
        <template #prefix>
          <sweet-icon class="input-icon" @click="handleSearch"
                      :title="$t('chat.session.label.search')">
            <search />
          </sweet-icon>
        </template>
      </sweet-input>
      <div class="aicc-align--absolute-left aicc-m-top--small aicc-m-bottom--small">
        <sweet-switch v-model="extPropEnable"
                      :active-value="1"
                      :inactive-value="0"
                      :disabled="isView" />
        <div class="aicc-m-left--small">{{ $t('isales.data.propConfig.message.isUseProp') }}</div>
      </div>
      <aicc-table ref='aiccTable'
                  :tableData="propList"
                  :tableColumns="tableColumns"
                  :tableTools="{ showPagination: true, columnConfig: false }"
                  :paginationConfig="paginationConfig"
                  :row-key="getKey"
                  :height="430"
                  @rowSelectionChange = "rowSelectionChange"
                  @handlePagination="handlePagination">
        <template #propType="scope">{{ PROP_TYPE[scope.row.propType] }}</template>
        <template #isPushAgent="scope">{{ yesOrNoItems[scope.row.isPushAgent] }}</template>
        <template #mandatory="scope">{{ yesOrNoItems[scope.row.mandatory] }}</template>
      </aicc-table>
      <template #footer>
      <span class="dialog-footer">
        <sweet-button @click="dialogVisible = false">{{ $t('isales.common.title.cancel') }}</sweet-button>
        <sweet-button type="primary" @click="confirm">{{ $t('isales.common.title.confirm') }}</sweet-button>
      </span>
      </template>
    </sweet-dialog>
  </div>
</template>

<script>
import { PROP_TYPE, yesOrNoItems } from '@/views/isales/views/taskManagement/enum'
import {queryTaskByCondition, savePropInTaskInfo} from '@/views/isales/api/taskManagement'
import { queryDataPropList } from '@/views/isales/api/manualCallTask'
import appConfig from '@/config'

export default {
  name: 'ConfigProp',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    marketingMode: Number,
    mode: String,
    taskId: String
  },
  data() {
    return {
      PROP_TYPE,
      yesOrNoItems,
      searchPropName: '',
      extPropEnable: 0,
      propList: [],
      tableColumns: [
        {
          type: 'selection', // 多选
          selectable: this.selectable,
          width: '50px',
          'reserve-selection': true
        },
        {
          label: this.$t('isales.data.prop.propName'), // 属性名称
          prop: 'propName',
          'show-overflow-tooltip': true
        },
        {
          label: this.$t('isales.data.prop.propCode'), // 属性编码
          prop: 'propCode',
          'show-overflow-tooltip': true
        },
        {
          label: this.$t('isales.data.prop.propType'), // 属性类型
          slot: 'propType',
        },
        {
          label: this.$t('isales.data.propConfig.title.isPushAgent'), // 是否推送座席
          slot: 'isPushAgent',
        },
        {
          label: this.$t('isales.data.prop.mandatory'), // 是否必填
          slot: 'mandatory'
        },
      ],
      paginationConfig: {
        offset: 0,
        pageSize: 5,
        pageSizes: [5, 10, 20], // 每页条数列表
        limit: 5, // 每页条数
        total: 0, //总条数
        layout: appConfig.pagination.LAYOUT.ADVANCED
      },
      taskInfo: {},
      currentPageSelectedRows: [], // 当前页选中数据
      currentPageUnSelectedRows: [], // 当前页未选中数据
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      }
    },
    isView() {
      return this.mode === 'view'
    }
  },
  async created() {
    await this.queryPropList()
    await this.queryTaskInfo()
  },
  methods: {
    selectable() {
      return !this.isView && this.extPropEnable === 1
    },
    handleSearch() {
      this.$refs.aiccTable.handleInitChange()
    },
    async handlePagination(data) {
      Object.assign(this.paginationConfig, data)
      await this.queryPropList()
      if (this.taskInfo.extProp) {
        // 计算方式举例：
        // 初始化：
        // 所有选中数据 [1, 2, 3, 4, 5, 6]，1~5为第一页，6为第二页
        // 当前页选中数据 = []
        // 当前页未选中数据 = []
        // selectedRows = 所有选中数据 - 当前页未选中数据 + 当前页选中数据 = [1, 2, 3, 4, 5, 6]
        // 所有选中数据 = selectedRows

        // 第1页，取消勾选 1：
        // 所有选中数据 [1, 2, 3, 4, 5, 6]
        // 当前页选中数据 = [2, 3, 4, 5,]  当前页未选中数据 = [1]
        // selectedRows = 所有选中数据 - 当前页未选中数据 + 当前页选中数据 = [2, 3, 4, 5, 6]
        // 所有选中数据 = selectedRows

        // 到第2页，初始化：
        // 所有选中数据 [2, 3, 4, 5, 6]
        // 当前页选中数据 = []  当前页未选中数据 = []
        // selectedRows = 所有选中数据 - 当前页未选中数据 + 当前页选中数据 = [2, 3, 4, 5, 6]
        // 所有选中数据 = selectedRows

        // 到第2页，新增勾选7：
        // 当前页选中数据 = [6,7]  当前页未选中数据 = [8, 9, 10]
        // selectedRows = 所有选中数据 - 当前页未选中数据 + 当前页选中数据 = [2, 3, 4, 5, 6, 7]
        // 所有选中数据 = selectedRows
        console.log('this.taskInfo.extProp:', this.taskInfo.extProp)
        console.log('this.currentPageSelectedRows:', this.currentPageSelectedRows)
        console.log('this.currentPageUnSelectedRows:', this.currentPageUnSelectedRows)
        // 所有选中数据
        let extProp = this.taskInfo.extProp.split(',')
        // 所有选中数据 - 当前页未选中数据
        let selectedRows = this.subArray(extProp, this.currentPageUnSelectedRows)
        // + 当前页选中数据
        selectedRows.push(...this.currentPageSelectedRows)
        // 去重
        selectedRows = Array.from(new Set(selectedRows))
        console.log('selectedRows:', selectedRows)
        this.taskInfo.extProp = selectedRows.join(',')
        // 遍历数据设置勾选
        this.propList.forEach(item => {
          if (selectedRows.includes(item.propId)) {
            this.$nextTick(() => {
              // 此处注意一定要设置第二个参数为true，即每次都是设置选中，否则会变成切换选中状态，导致往回翻页时选中的数据被取消勾选
              this.$refs.aiccTable.table.toggleRowSelection(item, true)
            })
          }
        })
        // 勾选完后更新 当前页选中数据 和 当前页未选中数据
        await this.$nextTick(() => {
          this.currentPageSelectedRows = this.$refs.aiccTable.table.getSelectionRows().map(item => item.propId)
          let allPageRows = this.propList.map(item => item.propId)
          this.currentPageUnSelectedRows = this.subArray(allPageRows, this.currentPageSelectedRows)
          console.log('this.taskInfo.extProp:', this.taskInfo.extProp)
          console.log('this.currentPageUnSelectedRows:', this.currentPageUnSelectedRows)
          console.log('this.currentPageSelectedRows:', this.currentPageSelectedRows)
        })
      }
    },
    // 数组差集
    subArray(a, b) {
      return a.filter(item => !b.includes(item))
    },
    async queryPropList(propIds) {
      const { limit, offset } = this.paginationConfig
      let propObject = { status : 1 }
      if (this.searchPropName) {
        propObject.propName = this.searchPropName
      }
      if (this.marketingMode) {
        propObject.marketingMode = this.marketingMode
      }
      let params = { propObject, limit, offset }
      let result = await queryDataPropList(params);
      if (result && result.resultCode === '0203000') {
        this.propList = result.propList
        this.paginationConfig.total = result.total
      } else {
        this.$message({
          type: 'error',
          duration: 10000,
          showClose: true,
          offset: 56,
          message: result.resultDesc
        })
      }
    },
    async confirm(){
      await this.updateTaskInfo()
      this.dialogVisible = false
    },
    async updateTaskInfo(){
      let params = {
        extPropEnable: this.extPropEnable,
        taskId : this.taskId,
      }
      const selectionRows = this.taskInfo.extProp
      if (selectionRows.length && this.extPropEnable) { // 如果选了并且开启，则以选的为准
        params.extProp = this.taskInfo.extProp
      } else { // 一个都没选 or 关闭属性，则传空
        params.extProp = ''
      }
      let result = await savePropInTaskInfo(params)
      if (result && result.resultCode === '0204000') {
        this.$message({
          type: 'success',
          duration: 5000,
          offset: 56,
          message: result.resultDesc
        })
      } else {
        this.$message({
          type: 'error',
          duration: 10000,
          showClose: true,
          offset: 56,
          message: result.resultDesc
        })
      }
    },
    async queryTaskInfo() {
      let param = {
        dataObject: { taskId: this.taskId },
        limit: 1,
        offset: 0,
      }
      const resultList = await queryTaskByCondition(param)
      this.taskInfo = resultList[0]
      this.extPropEnable = this.taskInfo.extPropEnable
      // 勾选数据回显
      if (this.taskInfo.extProp) {
        const selectedRows = this.taskInfo.extProp.split(",")
        this.propList.forEach(item => {
          if (selectedRows.includes(item.propId)) {
            this.$nextTick(() => {
              this.$refs.aiccTable.table.toggleRowSelection(item)
            })
          }
        })
      } else {
        this.taskInfo.extProp = ""
      }
    },
    getKey(row){
      return row.propId
    },
    // 每次勾选后，更新数据：selectedRows = 所有选中数据 - 当前页未选中数据 + 当前页选中数据
    // data为当前页选中的数据
    rowSelectionChange(data) {
      console.log('rowSelectionChange', data)
      this.currentPageSelectedRows = data.map(item => item.propId)
      let allPageRows = this.propList.map(item => item.propId)
      this.currentPageUnSelectedRows = this.subArray(allPageRows, this.currentPageSelectedRows)
      console.log('this.taskInfo.extProp:', this.taskInfo.extProp)
      console.log('this.currentPageSelectedRows:', this.currentPageSelectedRows)
      console.log('this.currentPageUnSelectedRows:', this.currentPageUnSelectedRows)
      // 空字符串，split后的数组会有一项是空字符串，需要过滤
      let extProp = this.taskInfo.extProp.split(',').filter(item => item !== '')
      console.log('extProp:', extProp)
      let selectedRows = this.subArray(extProp, this.currentPageUnSelectedRows)
      selectedRows.push(...this.currentPageSelectedRows)
      selectedRows = Array.from(new Set(selectedRows))
      this.taskInfo.extProp = selectedRows.join(',')
      console.log('this.taskInfo.extProp:', this.taskInfo.extProp)
    }
  }
}
</script>

<style lang="less" scoped>

</style>