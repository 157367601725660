export default {
  'isales.basicinfo.title.chooseCallNo': '选择主叫号码',
  'isales.common.title.cancel': '取消',
  'isales.common.title.close': '关闭',
  'isales.common.title.confirm': '确定',
  'isales.common.title.dataReset': '重置',
  'isales.common.title.dataSearch': '搜索',
  'isales.common.title.fail': '失败',
  'isales.common.title.save': '保存',
  'isales.common.title.ready': '准备',
  'isales.common.title.view': '查看',
  'isales.common.title.success': '成功',
  'isales.common.title.operation': '操作',
  'isales.common.title.tipsInfo': '提示',
  'isales.data.import.task.status.complete': '导入完成',
  'isales.data.import.task.status.error': '导入异常',
  'isales.data.import.task.status.executing': '导入中',
  'isales.data.import.task.status.ready': '导入准备',
  'isales.data.title.create': '新建',
  'isales.data.title.edit': '编辑',
  'isales.data.title.importData': '导入',
  'isales.data.title.importResult': '导入结果',
  'isales.data.title.tableTitle': '号码列表',
  'isales.exportdata.message.failinfo': '导出数据失败',
  'isales.exportdata.message.nodata': '没有符合条件的数据可以导出',
  'isales.exportdata.message.successinfo': '数据导出中，请从“导出结果”查看详情',
  'isales.exportdata.title.exportresult': '导出结果',
  'isales.exportdata.title.fail': '失败',
  'isales.exportdata.title.success': '成功',
  'isales.import.message.template.show': '根据下载模板按行添加客户数据，客户多个号码信息按列添加',
  'isales.import.message.template.dataNum': '每次最多导入{0}条数据',
  'isales.import.message.allow.task.statue': ',任务状态为“草稿”或者“暂停”时，才能导入！',
  'isales.import.message.begintime': '开始时间',
  'isales.import.message.endtime': '结束时间',
  'isales.import.message.fail': '导入失败。',
  'isales.import.message.numberTooMany': '最大上传50000个号码。',
  'isales.import.message.upload.fail': '上传文件失败。',
  'isales.import.message.failnum': '失败记录数',
  'isales.import.message.pleaseuploadfile': '请选择一个文件。',
  'isales.import.message.repeatnum': '重复记录数',
  'isales.import.message.result.tip': '差选导出结果失败',
  'isales.import.message.status': '状态',
  'isales.import.message.success': '数据导入中，请稍后点击刷新获取最新数据。',
  'isales.import.message.succnum': '成功记录数',
  'isales.import.message.taskstatuswrong': '待导入数据的外呼任务不是草稿或暂停状态。',
  'isales.import.message.uploadedfileformatvalidate': '文件格式错误，请选择一个csv文件。',
  'isales.import.message.uploadedfileNameformatvalidate': '文件名错误，请选择一个只包含中文、小写字母、数字或下划线作为文件名的文件，文件名后缀为csv',
  'isales.import.message.uploadedfiletoolarge': '请选择一个小于20mb的文件。',
  'isales.import.title.result': '导入结果查看',
  'isales.import.title.statusComplete': '已完成',
  'isales.import.title.statusExecuting': '执行中',
  'isales.import.title.statusReady': '待执行',
  'isales.repeatfile.export.repeatline': '重复行',
  'isales.repeatfile.export.repeatCalledNo': '重复号码',
  'isales.failfile.export.failline': '失败行',
  'isales.failfile.export.failCalledNo': '失败号码',
  'isales.failfile.export.failReason': '失败原因',
  'isales.input.message.large': '输入的值过大',
  'isales.input.message.small': '输入的值过小',
  'isales.management.message.sessionId': '外呼标识',
  'isales.management.message.calledNo': '被叫号码',
  'isales.management.message.calledNoEmpty': '被叫号码为空',
  'isales.management.message.calledNoExists': '被叫号码已存在',
  'isales.management.message.calledNoFormatIncorrect': '外呼号码只能由数字组成',
  'isales.management.message.sessionIdFormatIncorrect': '外呼标识只能有数字组成',
  'isales.management.message.calledNoLengthError': '被叫号码长度超标',
  'isales.management.message.calledNoOne': '被叫号码1',
  'isales.management.message.calledNoOneCategory': '号码1分类',
  'isales.management.message.calledNoOneInterval': '号码1呼叫间隔',
  'isales.management.message.calledNoTwo': '被叫号码2',
  'isales.management.message.calledNoTwoCategory': '号码2分类',
  'isales.management.message.calledNoTwoInterval': '号码2呼叫间隔',
  'isales.management.message.createFailed': '新建外呼数据失败',
  'isales.management.message.createSuccess': '新建外呼数据成功',
  'isales.management.message.createTaskstatusError': '所属外呼任务为非草稿非暂停状态，禁止新增数据！',
  'isales.management.message.deleteConfirm': '确认要删除所选外呼数据？',
  'isales.management.message.deleteCount': '一次最多删除100条外呼数据',
  'isales.management.message.deletedataIdsEmpty': '待删除外呼数据标识为空',
  'isales.management.message.deletedataIdsError': '待删除外呼数据标识错误',
  'isales.management.message.deleteFailed': '删除外呼数据失败',
  'isales.management.message.deleteSuccess': '删除外呼数据成功',
  'isales.management.message.deleteTaskstatusError': '所属外呼任务为非草稿状态或暂停态，禁止删除！',
  'isales.management.message.download': '下载外呼数据模板',
  'isales.management.message.notification.download': '下载营销数据模板',
  'isales.management.message.filter': '数据是否过滤',
  'isales.management.message.filterNo': '否',
  'isales.management.message.filterYes': '是',
  'isales.management.message.addPropTips': '个人数据或者敏感数据需要加密存储和掩码展示',
  'isales.management.message.import': '导入追加数据',
  'isales.management.message.importOrder': '导入顺序',
  'isales.management.message.orderReverse': '逆序',
  'isales.management.message.orderSequence': '顺序',
  'isales.management.message.taskIdEmpty': '外呼任务标识为空',
  'isales.management.message.upload': '上传',
  'isales.management.message.uploadSelect': '请选择要上传的文件',
  'isales.management.placeholder.calledNo': '请输入被叫号码',
  'isales.management.placeholder.ivrName': '输入智能机器人流程名称',
  'isales.management.placeholder.commonIvrName': '输入普通IVR流程名称',
  'isales.management.placeholder.commonIvrFlowAccessCode': '输入普通IVR流程接入码。',
  'isales.management.placeholder.skillName': '输入技能队列名称',
  'isales.management.placeholder.surveyName': '输入问卷名称',
  'isales.management.title.calledNo': '客户号码',
  'isales.management.title.createNew': '新建外呼数据',
  'isales.management.title.deleteInfo': '请选择要删除的外呼数据',
  'isales.management.title.details': '详情',
  'isales.management.title.editPolicy': '策略变更',
  'isales.management.title.edit': '修改',
  'isales.management.title.extendedField': '扩展字段',
  'isales.management.title.import': '导入外呼数据',
  'isales.management.title.notification.import': '导入营销数据',
  'isales.management.title.warning': '警告',
  'isales.management.title.callbot': '配置智能外呼机器人',
  'isales.management.title.refresh': '刷新',
  'isales.management.title.busiresult': '业务结果',
  'isales.management.title.toblocklist': '添加至黑名单',
  'isales.outboundtime.field.hour': '时',
  'isales.outboundtime.field.minute': '分',
  'isales.outboundtime.message.crossover': '呼出时间段交叉！',
  'isales.outboundtime.message.empty': '外呼时间未设置！',
  'isales.outboundtime.title.beginTime': '开始时间',
  'isales.outboundtime.title.clear': '清空',
  'isales.outboundtime.title.endTime': '结束时间',
  'isales.outboundtime.title.period': '外呼时段',
  'isales.outboundtime.title.set': '外呼时间设置',
  'isales.outboundtime.title.timePeriod': '外呼时间段',
  'isales.specialday.field.beginDate': '开始日期',
  'isales.specialday.field.byDate': '按日期',
  'isales.specialday.field.byWeek': '按周',
  'isales.specialday.field.byYear': '按年重复',
  'isales.specialday.field.endDate': '结束日期',
  'isales.specialday.field.Friday': '周五',
  'isales.specialday.field.Monday': '周一',
  'isales.specialday.field.Saturday': '周六',
  'isales.specialday.field.Sunday': '周日',
  'isales.specialday.field.Thursday': '周四',
  'isales.specialday.field.Tuesday': '周二',
  'isales.specialday.field.Wednesday': '周三',
  'isales.specialday.message.confirmDelete': '你确认想删除所选的特殊日期吗？',
  'isales.specialday.message.createFail': '特殊日期创建失败',
  'isales.specialday.message.createSuccess': '特殊日期创建成功',
  'isales.specialday.message.crossover': '特殊日期呼出时间段交叉！',
  'isales.specialday.message.dateEmpty': '特殊日期时间为空',
  'isales.specialday.message.dateFormatError': '特殊日期时间格式错误',
  'isales.specialday.message.dateParseError': '特殊日期时间类型转换错误',
  'isales.specialday.message.deleteFail': '特殊日期删除失败！',
  'isales.specialday.message.deletePartiallyFail': '部分特殊日期删除失败！',
  'isales.specialday.message.deleteSpecialday': '选择要删除的特殊日期',
  'isales.specialday.message.deleteSuccess': '特殊日期删除成功！',
  'isales.specialday.message.deleteTips': '该特殊日期已被外呼任务关联，无法删除！',
  'isales.specialday.message.idEmpty': '特殊日期标识为空',
  'isales.specialday.message.idError': '特殊日期标识错误',
  'isales.specialday.message.isEmpty': '特殊日期外呼时间未设置！',
  'isales.specialday.message.nameError': '特殊日期名称不规范',
  'isales.specialday.message.nameExists': '特殊日期名称已存在',
  'isales.specialday.message.typeError': '特殊日期类型错误',
  'isales.specialday.message.updateFail': '特殊日期修改失败',
  'isales.specialday.message.updateSuccess': '特殊日期修改成功',
  'isales.specialday.message.weeklyEmpty': '特殊日期周期值为空',
  'isales.specialday.message.weeklyError': '特殊日期周期值错误',
  'isales.specialday.message.weeklyFormatError': '特殊日期周期值格式错误',
  'isales.specialday.message.weeklyRepeat': '特殊日期周期值重复',
  'isales.specialday.placeholder.searchname': '输入特殊日期名称',
  'isales.specialday.title.add': '添加特殊日期',
  'isales.specialday.title.addSpecialTime': '+ 添加特殊日期',
  'isales.specialday.title.choose': '选择特殊日期',
  'isales.specialday.title.date': '特殊日期',
  'isales.specialday.title.chooseDate': '选择日期',
  'isales.specialday.title.create': '创建',
  'isales.specialday.title.createspecialday': '创建特殊日期',
  'isales.specialday.title.delete': '删除',
  'isales.specialday.title.scoreDetail': '得分详情',
  'isales.specialday.title.deleteError': '错误',
  'isales.specialday.title.deleteSuccess': '成功',
  'isales.specialday.title.edit': '编辑',
  'isales.specialday.title.editSpecialday': '编辑特殊日期',
  'isales.specialday.title.holiday': '特殊日期时间',
  'isales.specialday.title.holidayName': '特殊日期名称',
  'isales.specialday.title.holidayType': '特殊日期类型',
  'isales.specialday.title.reset': '重置',
  'isales.specialday.title.search': '查询',
  'isales.specialday.title.timeSet': '特殊日期外呼时间设置',
  'isales.specialday.title.timePeriod': '特殊日期外呼时间段',
  'isales.specialday.title.warning': '警告',
  'isales.task.field.callFail': '呼出失败',
  'isales.task.field.callFlagCompletion': '呼叫完成',
  'isales.task.field.callFlagExecuted': '待执行呼叫',
  'isales.task.field.callFlagProgress': '呼叫执行中',
  'isales.task.field.callFlag0': '待执行呼叫',
  'isales.task.field.callFlag1': '呼叫执行中',
  'isales.task.field.callFlag2': '呼叫完成',
  'isales.task.field.callSuccess': '呼出成功',
  'isales.task.message.completionRate': '任务完成度',
  'isales.task.message.download': '下载录音',
  'isales.task.message.numberCalled': '{0}个号码已呼出',
  'isales.task.message.numberFailed': '呼叫失败数',
  'isales.task.message.numberNotcalled': '{0}个号码未呼出',
  'isales.task.message.numbersTotal': '共计{0}个号码',
  'isales.task.message.numberSuccessful': '呼叫成功数',
  'isales.task.message.pauseFail': '外呼任务暂停失败',
  'isales.task.message.pauseSuccess': '外呼任务暂停成功',
  'isales.task.message.play': '播放',
  'isales.task.message.saveFail': '外呼任务配置失败。',
  'isales.task.message.saveSuccess': '外呼任务配置成功。',
  'isales.taskpolicy.message.saveSuccess': '策略变更成功',
  'isales.taskpolicy.message.saveFail': '策略变更失败',
  'isales.task.message.specialdayEmpty': '特殊日期未选择',
  'isales.task.message.startFail': '外呼任务启动失败',
  'isales.task.message.startSuccess': '外呼任务启动成功',
  'isales.task.message.startWarning': '有外呼数据正在导入，不允许启动！',
  'isales.task.message.statistics': '统计',
  'isales.task.message.successRate': '成功率',
  'isales.task.message.timeIncompletes': '单个外呼时间段未填写完全',
  'isales.task.title.basicInfo': '基本信息',
  'isales.task.title.taskSample': '对象管理',
  'isales.task.title.callBack': '退回',
  'isales.task.title.callpolicy': '外呼策略',
  'isales.task.title.callTime': '外呼时间',
  'isales.task.title.complete': '完成',
  'isales.task.title.nextStep': '下一步',
  'isales.task.title.preStep': '上一步',
  'isales.taskinfo.field.beginDate': '任务开始时间',
  'isales.taskinfo.field.callNo': '主叫号码',
  'isales.taskinfo.field.endDate': '任务结束时间',
  'isales.taskinfo.field.flowName': 'IVR流程',
  'isales.taskinfo.field.skillName': '技能队列',
  'isales.taskinfo.field.survey': '问卷调查',
  'isales.taskinfo.field.taskType': '任务类型',
  'isales.taskinfo.field.callTimes': '试呼次数',
  'isales.taskinfo.field.smsTemplate': '外呼失败短信提醒模板',
  'isales.taskinfo.field.description': '任务描述',
  'isales.taskinfo.field.surveyAnswerNum': '问卷作答次数',
  'isales.taskinfo.field.questionNumber': '问题数量',
  'isales.taskinfo.field.surveyScore': '试题总分',
  'isales.taskinfo.message.beginTimeError': '开始时间不能晚于结束时间！',
  'isales.taskinfo.message.endTimeError': '任务结束时间必须大于当前时间',
  'isales.taskinfo.message.input': '非法字符',
  'isales.taskinfo.message.callintervalrange': '请输入1-3600之间的正整数。',
  'isales.taskinfo.message.nameExists': '外呼任务名称不允许重复',
  'isales.taskinfo.message.agent': '座席信息异常请检查是否存在非当前技能队列的座席',
  'isales.taskinfo.message.require': '请填写所有必填字段',
  'isales.taskinfo.placeholder.choose': '请选择',
  'isales.taskinfo.placeholder.input': '请输入',
  'isales.taskinfo.title.chooseIvrFlow': '选择IVR流程',
  'isales.taskinfo.title.chooseCommonIvrFlow': '选择普通IVR',
  'isales.taskinfo.title.chooseSkill': '选择技能队列',
  'isales.taskinfo.title.chooseSurvey': '选择问卷',
  'isales.taskinfo.title.ivrFlow': '智能机器人',
  'isales.taskinfo.title.commonIvrFlow': '普通IVR',
  'isales.taskinfo.title.skillName': '技能队列',
  'isales.taskinfo.title.surveyName': '问卷名称',
  'isales.taskinfo.title.versionNo': '问卷版本',
  'isales.taskinfo.title.chooseSmsTemplateQuery': '选择短信模板',
  'isales.taskinfo.title.smsTemplate': '短信模板',
  'isales.taskmanagement.field.intelligence': '智能外呼',
  'isales.taskmanagement.field.Automatic': '自动外呼',
  'isales.taskmanagement.field.outboundType': '外呼类型',
  'isales.taskmanagement.field.prediction': '预测外呼',
  'isales.taskmanagement.field.taskName': '任务名称',
  'isales.taskmanagement.field.taskTime': '任务时间',
  'isales.taskmanagement.field.taskStatus': '任务状态',
  'isales.taskmanagement.message.deleteConfirm': '请确认是否删除',
  'isales.common.message.choose': '请选择数据',
  'isales.taskmanagement.message.queryDateError': '查询日期有误，请重新选择',
  'isales.taskmanagement.message.queryFailed': '查询失败',
  'isales.taskmanagement.message.queryLimit': '每页记录数必须大于0，并且小于101。',
  'isales.taskmanagement.message.queryTime': '请输入完整的时间查询',
  'isales.taskmanagement.title.batchStartTask': '批量启动',
  'isales.taskmanagement.title.batchPauseTask': '批量暂停',
  'isales.taskmanagement.title.batchEdit': '批量修改',
  'isales.taskmanagement.title.batchEditTaskInfo': '批量修改基本信息',
  'isales.taskmanagement.title.batchEditCallTime': '批量修改外呼时间',
  'isales.taskmanagement.title.batchEditCallPolicy': '批量修改外呼策略',
  'isales.taskmanagement.title.batchEditCallLogic': '批量修改外呼逻辑',
  'isales.taskmanagement.title.tipsBatchStartTask': '选择要启动的任务',
  'isales.taskmanagement.title.batchStart.finishedStatus': '已过期任务不可以启动',
  'isales.taskmanagement.title.batchPause.statusError': '草稿和已过期状态任务不可以暂停',
  'isales.taskmanagement.title.startCount': '一次最多启动30条外呼任务',
  'isales.taskmanagement.title.startConfirm': '确认要启动所选外呼任务？',
  'isales.taskmanagement.title.tipsBatchPauseTask': '选择要暂停的任务',
  'isales.taskmanagement.title.pauseCount': '一次最多暂停30条外呼任务',
  'isales.taskmanagement.title.pauseConfirm': '确认要暂停所选外呼任务？',
  'isales.taskmanagement.title.batchEditTaskInfo.errStatus': '已过期任务不可以批量修改',
  'isales.taskmanagement.title.batchEditTaskInfo.manual': '自动外呼和手动外呼不能同时修改',
  'isales.taskmanagement.title.batchEditCallPolicy.sameTaskType': '只有相同类型任务才可以批量修改外呼策略',
  'isales.taskmanagement.title.batchEditCallTime.manualError': '手动外呼不可以修改外呼时间',
  'isales.taskmanagement.title.batchEditCallPolicy.manualError': '手动外呼不可以修改外呼策略',
  'isales.task.message.NoStartTask': '没有符合条件的任务可以启动！',
  'isales.task.message.NoPauseTask': '没有符合条件的任务可以暂停！',
  'isales.taskinfo.field.priority': '优先级',
  'isales.taskmanagement.title.addAuto': '新建系统自动外呼',
  'isales.taskmanagement.title.addSysAuto': '新建系统自动外呼',
  'isales.taskmanagement.title.chooseBegintime': '请选择开始时间',
  'isales.taskmanagement.title.begintime': '开始时间',
  'isales.task.message.startTips': '该外呼任务已过期，不能启动！',
  'isales.taskmanagement.title.chooseEndtime': '请选择结束时间',
  'isales.taskmanagement.title.endtime': '结束时间',
  'isales.taskmanagement.title.configData': '外呼数据',
  'isales.taskmanagement.title.createTask': '新建外呼任务',
  'isales.taskmanagement.title.deleteFailed': '删除失败',
  'isales.taskmanagement.title.deleteSuccess': '删除成功',
  'isales.taskmanagement.title.edit': '编辑',
  'isales.taskmanagement.title.editPolicy': '策略变更',
  'isales.taskmanagement.title.inputTaskName': '请输入任务名称',
  'isales.taskmanagement.title.outbound': '配置智能外呼机器人',
  'isales.taskmanagement.title.pause': '暂停',
  'isales.taskmanagement.title.query': '查询',
  'isales.taskmanagement.title.queryTimeTip': '提示',
  'isales.taskmanagement.title.reset': '重置',
  'isales.taskmanagement.title.start': '启动',
  'isales.taskmanagement.title.status': '状态',
  'isales.taskmanagement.title.viewTaskResult': '任务结果',
  'isales.taskmanagment.title.viewstatistics': '查看统计',
  'isales.taskmanagement.title.editInfo': '信息变更',
  'isales.taskmanagement.title.editSample': '对象管理',
  'isales.taskmanagement.title.editTime': '时间变更',
  'isales.taskmanagement.title.editLogic': '逻辑变更',
  'isales.taskpolicy.field.connRate': '外呼接通率',
  'isales.taskpolicy.field.connRateMethod': '外呼接通率算法',
  'isales.taskpolicy.field.experienceMethod': '经验预测算法',
  'isales.taskpolicy.field.failInterval': '呼出间隔时长',
  'isales.taskpolicy.field.lossRate': '最低呼损率',
  'isales.taskpolicy.field.maxAvgCallTime': '最大平均通话时长',
  'isales.taskpolicy.field.minAvgCallTime': '最小平均通话时长',
  'isales.taskpolicy.field.minChangeTime': '最短停留时长',
  'isales.taskpolicy.field.multiSkill': '混合技能队列',
  'isales.taskpolicy.field.notSupportMultiSkill': '不支持呼入呼出混合座席',
  'isales.taskpolicy.field.obCallCount': '每次呼出数量',
  'isales.taskpolicy.field.obOverTime': '最大应答时长',
  'isales.taskpolicy.field.calculationFactor': '计算因子',
  'isales.taskpolicy.field.pickupRateMethod': '摘机率算法',
  'isales.taskpolicy.field.predictMethod': '预测外呼的算法',
  'isales.taskpolicy.field.supportMultiSkill': '支持呼入呼出混合座席',
  'isales.taskpolicy.field.talkingRate': '呼出速度',
  'isales.taskpolicy.field.utilRate': '座席利用率',
  'isales.taskpolicy.field.pickupRate': '摘机率',
  'isales.taskpolicy.field.utilRateCalculateMethod': '座席利用率的计算方法',
  'isales.taskpolicy.field.utilRateCalculateOne': '座席利用率＝总的通话时长/总的签入时长',
  'isales.taskpolicy.field.utilRateCalculateTwo': '座席利用率＝(总的通话时长＋工作态时长)/总的签入时长',
  'isales.taskpolicy.field.utilRateMethod': '座席利用率算法',
  'isales.taskpolicy.field.waitTime': '用户等待时间',
  'isales.taskpolicy.message.input.maxAvgCallTime': '最大平均通话时长不能小于最小平均通话时长',
  'isales.taskpolicy.message.input.minAvgCallTime': '最小平均通话时长不能大于最大平均通话时长',
  'isales.taskresult.field.failedtooccupytheagent': '占用座席失败',
  'isales.taskresult.field.failedtopause': '任务已经为暂停态，暂停失败',
  'isales.taskresult.field.failedtoresume': '任务已经为非暂停态，恢复失败',
  'isales.taskresult.field.failsAcdtimeout': '排队机超时',
  'isales.taskresult.field.failsAgentdevicetimesout': '占用座席设备超时',
  'isales.taskresult.field.failsAgentfailswithuser': '座席和用户做话路搭接失败',
  'isales.taskresult.field.failsAns1': '文本电话(ANS1)',
  'isales.taskresult.field.failsAns2': '文本电话(ANS2)',
  'isales.taskresult.field.failsAns3': '文本电话(ANS3)',
  'isales.taskresult.field.failsAns4': '文本电话(ANS4)',
  'isales.taskresult.field.failsAns5': '文本电话(ANS5)',
  'isales.taskresult.field.failsAutomaticansweringmachine': '自动应答机',
  'isales.taskresult.field.failsAutomaticdevicedetected': '检测到自动设备',
  'isales.taskresult.field.failsBeingchanged': '被叫正在变更，已经转移',
  'isales.taskresult.field.failsBusytone': '忙音',
  'isales.taskresult.field.failsCallattemptfails': '调用呼出接口失败',
  'isales.taskresult.field.failsCalldatatimeout': '呼叫数据超时',
  'isales.taskresult.field.failsCalledmodem': '被叫为Modem',
  'isales.taskresult.field.failsCallednumberincorrect': '预览呼出被叫号码填写不正确',
  'isales.taskresult.field.failsCalledpartybusy': '被叫忙',
  'isales.taskresult.field.failsCallqueueoverflow': '呼叫排队溢出',
  'isales.taskresult.field.failsCallrejected': '呼叫拒绝',
  'isales.taskresult.field.failsCallwaiting': '呼叫等待',
  'isales.taskresult.field.failsCpp': '被叫拍叉转移',
  'isales.taskresult.field.failsDestinationunreachable': '目的地不可达',
  'isales.taskresult.field.failsDisconnection': '用户未呼通',
  'isales.taskresult.field.failsDnd': '暂不受话服务',
  'isales.taskresult.field.failsDtmf': '文本电话(DTMF)',
  'isales.taskresult.field.failsEdt': '文本电话(EDT)',
  'isales.taskresult.field.failsEmptyorwrong': '空号或错号',
  'isales.taskresult.field.failsFak': '传真',
  'isales.taskresult.field.failsFaxdetected': '检测到Fax',
  'isales.taskresult.field.failsFaxormodem': '传真或Modem',
  'isales.taskresult.field.failsFsk': '文本电话(FSK)',
  'isales.taskresult.field.failsHangsupbeforeanswering': '应答前用户挂机',
  'isales.taskresult.field.failsHangsupwhencall': '座席通过座席条控件应答呼叫时，座席自身话机挂断',
  'isales.taskresult.field.failsInsufficientresources': '资源不足',
  'isales.taskresult.field.failsInvalidstatus': 'DTE终端状态无效',
  'isales.taskresult.field.failsModemdetected': '检测到Modem',
  'isales.taskresult.field.failsModemorfax': 'Modem或者Fax',
  'isales.taskresult.field.failsMusicdetected': '检测到音乐',
  'isales.taskresult.field.failsNoacdresource': '排队机无资源',
  'isales.taskresult.field.failsNoagentavailable': 'OBS倒换为备时，安全退出时以及预占用呼出发现没有可用座席时，未呼出的记录退单',
  'isales.taskresult.field.failsNoanswer': '用户无应答',
  'isales.taskresult.field.failsNoreason': 'DTE终端转移，无原因',
  'isales.taskresult.field.failsNoreply': '用户无应答',
  'isales.taskresult.field.failsNoresource': '无资源',
  'isales.taskresult.field.failsNoresponse': '用户无响应',
  'isales.taskresult.field.failsNotintheservicearea': '手机不在服务区',
  'isales.taskresult.field.failsNumberchange': '号码改变',
  'isales.taskresult.field.failsNumberreacheslimit': '达到限制obs呼叫数',
  'isales.taskresult.field.failsObsexits': 'OBS退出时，对正在呼出的没回结果的记录退单回写',
  'isales.taskresult.field.failsPhonenumberisnull': '电话号码为空，即外呼获取的被叫号码为空字符串',
  'isales.taskresult.field.failsPlayingvoices': '信令检测到对端交换机正在放音',
  'isales.taskresult.field.failsPoweredoff': '手机关机',
  'isales.taskresult.field.failsQueuingtimesout': '排队超时，平台释放呼叫',
  'isales.taskresult.field.failsRecordcanceled': 'OBS倒换为备时，正在呼出的记录退单',
  'isales.taskresult.field.failsRecordswrittenback': 'OBS退出(或任务被删除)，将没有呼出的记录回写',
  'isales.taskresult.field.failsReleasesbeforecall': '座席和用户做话路搭接之前，座席通过座席条控件释放用户呼叫',
  'isales.taskresult.field.failsReleaseswhencall': '座席和用户做话路搭接时，座席通过座席条控件释放用户呼叫',
  'isales.taskresult.field.failsResultnotobtained': '呼出后，没有得到呼出结果信息，超时后将该呼叫数据回写。',
  'isales.taskresult.field.failsResulttimesoutafterpicksup': '等待排队机摘机后外呼检测结果超时',
  'isales.taskresult.field.failsRingbacktone': '回铃音',
  'isales.taskresult.field.failsRingingtimesout': '振铃超时，平台释放呼叫',
  'isales.taskresult.field.failsSittone': 'SIT音',
  'isales.taskresult.field.failsSsit': '发送专用信息音',
  'isales.taskresult.field.failsSubscriberbusy': '用户忙',
  'isales.taskresult.field.failsSubscriberhangsup': '用户挂机',
  'isales.taskresult.field.failsSwitchingdevicebusy': '交换设备忙',
  'isales.taskresult.field.failsTasknotexist': '外呼任务不存在',
  'isales.taskresult.field.failsTransferbeingalerted': '振铃中，被叫正在转移',
  'isales.taskresult.field.failsTransfernoreply': '无应答，被叫正在转移',
  'isales.taskresult.field.failsTransferonbusy': '遇忙，被叫正在转移',
  'isales.taskresult.field.failsTransferunconditionally': '被叫正在转移',
  'isales.taskresult.field.failsUnallocatednumber': '未分配的号码',
  'isales.taskresult.field.failsUndefinedsignaltone': '未定义信号音',
  'isales.taskresult.field.failsUnreachable': '不可达的移动用户',
  'isales.taskresult.field.failsUserhangsupinthequeue': '座席遇忙，用户主动挂机',
  'isales.taskresult.field.failsUserrejection': '用户拒绝',
  'isales.taskresult.field.failsWaitresulttimesout': '平台内部等待外呼结果超时',
  'isales.taskresult.field.failsWithoutringing': '立即回应转移，未振铃',
  'isales.taskresult.field.failsCallRouting': '呼叫路由失败',
  'isales.taskresult.field.failsAgentHangsUp': '座席挂断',
  'isales.taskresult.field.failsAgentCancelingCall': '座席取消呼叫',
  'isales.taskresult.field.failsAgentDisconnection': '座席断连',
  'isales.taskresult.field.failsCallTimeout': '呼叫超时',
  'isales.taskresult.field.failsResourceOverload': '资源不足',
  'isales.taskresult.field.failsUnallocatedOrIncorrectNumber': '空号或错号',
  'isales.taskresult.field.failsTimeout': '超时',
  'isales.taskresult.field.failsInvalidUser': '无效用户',
  'isales.taskresult.field.failsInvalidDevice': '无效设备',
  'isales.taskresult.field.failsNoAnsweringDeviceAvailable': '无可用应答设备',
  'isales.taskresult.field.failsAbnormal': '异常',
  'isales.taskresult.field.failsInvalidRoutingDevice': '无效路由设备',
  'isales.taskresult.field.failsSwitchRestart': '交换设备重启',
  'isales.taskresult.field.failsUnknownError': '未知异常',
  'isales.taskresult.field.failsCallResourcesInsufficient': '呼叫资源不足',
  'isales.taskresult.field.failsNoValidVDN': '无有效VDN',
  'isales.taskresult.field.failsCallFailure': '呼叫失败',
  'isales.taskresult.field.failsFailedRouteAgent': '路由座席失败',
  'isales.taskresult.field.failsInvalidSkillQueue': '无效技能队列',
  'isales.taskresult.field.failsFailedRouteSkillQueue': '路由技能队列失败',
  'isales.taskresult.field.failsFailedRouteCallToAttendant': '路由转接人工失败',
  'isales.taskresult.field.failsFailedAgentIsBusy': '座席当前非空闲态',
  'isales.taskresult.field.failsCalledOnHook': '座席挂机',
  'isales.taskresult.field.success': '呼出成功',
  'isales.taskresult.field.no': '否',
  'isales.taskresult.message.details': '详情',
  'isales.taskresult.message.queryTime': '请输入完整的呼出时间查询',
  'isales.taskresult.title.busiresultupdate': '业务结果补录',
  'isales.taskresult.title.beginTime': '呼出开始时间',
  'isales.taskresult.title.chooseBeginTime': '请选择呼出开始时间',
  'isales.taskresult.title.calledNo': '外呼号码',
  'isales.taskresult.title.callFlag': '外呼状态',
  'isales.taskresult.title.chooseCallFlag': '请选择外呼状态',
  'isales.taskresult.title.callResult': '外呼结果',
  'isales.taskresult.title.endTime': '呼出结束时间',
  'isales.taskresult.title.chooseEndTime': '请选择呼出结束时间',
  'isales.taskresult.title.export': '导出',
  'isales.taskresult.title.exportQuestion': '导出试题',
  'isales.taskresult.title.trainingQuestion': '训练试题',
  'isales.taskresult.title.exportResult': '查看导出结果',
  'isales.taskresult.title.failCode': '呼出失败原因',
  'isales.taskresult.title.inputSessionId': '请输入外呼标识',
  'isales.taskresult.title.inputCallerNo': '请输入外呼号码',
  'isales.taskresult.title.queryTime': '提示',
  'isales.taskresult.title.result': '呼出结果',
  'isales.taskresult.title.busisubstatus': '子状态',
  'isales.taskresult.title.busidesc': '业务描述',
  'isales.taskresult.title.chooseResult': '请选择呼出结果',
  'isales.taskresult.title.choosebusisubstatus': '请先选择业务结果再选择子状态',
  'isales.taskresult.title.choosebusiresult': '请选择业务结果',
  'isales.taskresult.title.resultDetail': '外呼结果详情',
  'isales.taskresultexport.message.begintime': '开始时间',
  'isales.taskresultexport.message.downloadConfirmInfo': '确认要下载吗？',
  'isales.taskresultexport.message.downloadExportdatafailinfo': '校验失败，导出文件数据找不到',
  'isales.taskresultexport.message.downloadFail': '下载失败',
  'isales.taskresultexport.message.downloadFilesuffixfailinfo': '下载失败，导出文件信息不含文件后缀',
  'isales.taskresultexport.message.downloadRedischeckfailinfo': '相同的文件在下载好之前不可重复下载',
  'isales.taskresultexport.message.downloadFilenopemission': '无导出文件权限',
  'isales.callrecordexport.message.downloadFilenopemission': '无导出智能外呼通信记录权限',
  'isales.taskresultexport.message.endtime': '结束时间',
  'isales.taskresultexport.message.result': '结果',
  'isales.taskresultexport.title.download': '下载',
  'isales.taskresultexport.title.downloadConfirm': '确认',
  'isales.taskresultexport.title.exported': '待导出',
  'isales.taskresultexport.title.fail': '导出失败',
  'isales.taskresultexport.title.progress': '导出中',
  'isales.taskresultexport.title.success': '导出成功',
  'isales.taskstatistics.field.calling': '呼叫中',
  'isales.taskstatistics.field.completedcalls': '已呼出',
  'isales.taskstatistics.field.notcompletedcalls': '未呼出',
  'isales.taskstatistics.placeholder.called': '已呼叫 {0}',
  'isales.taskstatistics.placeholder.calling': '呼叫中 {0}',
  'isales.taskstatistics.placeholder.notcalled': '待呼叫 {0}',
  'isales.taskstatistics.placeholder.statistics': '统计',
  'isales.taskstatus.field.all': '全部',
  'isales.taskstatus.field.draft': '草稿',
  'isales.taskstatus.field.execute': '执行中',
  'isales.taskstatus.field.waiting': '未开始',
  'isales.taskstatus.field.callfinish': '呼叫完成',
  'isales.taskstatus.field.finish': '已过期',
  'isales.taskstatus.field.suspend': '暂停',
  'isales.tasktype.field.intelligence': '智能外呼',
  'isales.tasktype.field.prediction': '预测外呼',
  'isales.tasktype.field.automatic': '自动外呼',
  'isales.validate.message.holidayDateComparisonError': '结束日期不能早于开始日期',
  'isales.validate.message.formatError': '请输入MM-dd格式的日期',
  'isales.validate.message.notSpecial': '名称不能包含特殊字符',
  'isales.operlog.message.data.import': '向外呼任务{0}导入外呼数据。',
  'isales.operlog.message.data.downloadtemplate': '下载外呼数据导入模板。',
  'isales.operlog.message.data.downloadrepeatfile': '下载外呼数据导入时的重复数据文件。',
  'isales.operlog.message.data.downloadfailfile': '下载外呼数据导入时的失败数据文件。',
  'isales.operlog.message.data.downloadexportfile': '下载外呼任务{0}导出结果文件。',
  'isales.statistics.field.addHis': '统计归档数据',
  'isales.statistics.field.addHisDetail': '否：统计{0}个月内的数据/是：统计{1}个月内的数据',
  'isales.statistics.field.addHisYes': '是',
  'isales.taskresult.title.queryArchivedData': '查询归档数据',
  'isales.taskresult.field.yes': '是',
  'isales.taskmanagement.message.afterNow': '开始时间不能大于当前时间!',
  'isales.taskresult.title.failedDetail': '呼叫失败详情',
  'isales.taskmanagement.field.dailyStatistics': '日统计',
  'isales.taskmanagement.field.statisticsDate': '统计日期',
  'isales.taskmanagement.field.statisticsType': '统计类型',
  'isales.blacklist.title.calledNo': '号码',
  'isales.blacklist.title.remark': '备注',
  'isales.blacklist.title.restrictTimes': '限制次数',
  'isales.blacklist.placeholder.restrictTimes': '输入限制次数',
  'isales.blacklist.title.restrictPeriod': '限制周期',
  'isales.blacklist.title.hour': '每小时',
  'isales.blacklist.title.day': '每天',
  'isales.blacklist.placeholder.searchcalledno': '输入号码',
  'isales.blacklist.title.createblacklist': '新增黑名单号码',
  'isales.blacklist.title.name': '姓名',
  'isales.tasklogic.field.retryDay': '未接通最大试呼天数',
  'isales.tasklogic.field.retryTimes': '未接通每天最大试呼次数',
  'isales.tasklogic.title.addPolicy': '添加策略',
  'isales.tasklogic.field.result': '结果',
  'isales.tasklogic.field.retryNumber': '重试次数',
  'isales.tasklogic.field.callInterval': '呼出间隔(秒)',
  'isales.task.title.calllogic': '外呼逻辑',
  'isales.blacklist.placeholder.searchname': '姓名',
  'isales.blacklist.placeholder.searchremark': '备注',
  'isales.blacklist.message.requestEmpty': '新增黑名单参数校验失败',
  'isales.blacklist.message.createSuccess': '新增黑名单成功',
  'isales.blacklist.message.createFail': '新增黑名单失败',
  'isales.blacklist.message.error': '新增黑名单异常',
  'isales.blacklist.message.confirmDelete': '您确认想删除所选的黑名单号码吗？',
  'isales.blacklist.message.deleteBlacklist': '选择要删除的黑名单号码',
  'isales.blacklist.title.deleteError': '错误',
  'isales.blacklist.message.paramEmpty': '请选择具体的黑名单号码',
  'isales.blacklist.message.deletePartiallyFail': '部分黑名单信息删除失败！',
  'isales.blacklist.title.deleteSuccess': '成功',
  'isales.blacklist.message.deleteSuccess': '黑名单号码删除成功！',
  'isales.blacklist.message.Description': '有效时长内 每小时/每天 限制该号码的呼叫次数',
  'isales.blacklist.message.periodAndTime': '限制周期和限制次数必须两个都填或都不填',
  'isales.blacklist.message.timeserror': '限制次数错误',
  'isales.blacklist.message.perioderror': '限制周期错误',
  'isales.calledNo.message.input': '号码只能输入数字',
  'isales.blacklist.message.calledno.empty': '号码输入为空',
  'isales.blacklist.message.name.reg.empty': '姓名的正则规则为空，联系管理员添加！',
  'isales.blacklist.message.calledno.error': '号码存在非法字符',
  'isales.blacklist.message.name.error': '姓名存在非法字符',
  'isales.blacklist.message.remark.error': '备注存在非法字符',
  'isales.blacklist.message.calledno.exist': '黑名单号码已存在',
  'isales.blacklist.message.customer.notincall': '客户未在呼叫中',
  'isales.blacklist.message.customer.empty': '未查到呼叫客户信息',
  'isales.taskcalllogic.message.choosePolicy': '请至少选择一种结果处理策略',
  'isales.taskcalllogic.title.resultPolicySet': '结果处理策略',
  'isales.taskcalllogic.title.retryPolicySet': '试呼策略',
  'isales.taskcalllogic.title.resultSetPolicy': '根据结果配置策略',
  'isales.taskcalllogic.message.resultTips': '一种结果只能有一条策略',
  'isales.taskcalllogic.field.customerbusy': '客户忙',
  'isales.taskcalllogic.field.customerMissed': '客户未接',
  'isales.taskcalllogic.field.customerRefused': '客户拒接',
  'isales.taskcalllogic.field.emptyNumber': '空号',
  'isales.taskcalllogic.field.shutdown': '客户关机',
  'isales.taskcalllogic.field.notServiceArea': '客户不在服务区',
  'isales.taskcalllogic.field.fax': '传真、总机等',
  'isales.taskcalllogic.field.callTimeout': '呼叫超时',
  'isales.taskcalllogic.field.callFailed': '呼叫失败',
  'isales.taskcalllogic.field.other': '其它',
  'isales.manutask.title.viewdata': '外呼数据',
  'isales.manutask.title.viewresult': '外呼结果',
  'isales.manual.title.viewManualData': '手动外呼数据-',
  'isales.manualdata.title.lock': '锁定',
  'isales.manualdata.title.release': '释放',
  'isales.manualdata.title.order': '预约外呼',
  'isales.manualdata.title.call': '呼叫',
  'isales.manual.title.lock': '锁定',
  'isales.manual.message.confirmLockData': '确定想锁定所选的外呼数据吗？',
  'isales.manual.message.empty': '为空！',
  'isales.manual.message.lock.failed': '外呼数据锁定失败！',
  'isales.manual.message.lock.success': '外呼数据锁定成功！',
  'isales.manual.message.error': '数据更新异常！',
  'isales.manual.message.confirmReleaseData': '确定想释放所选的外呼数据吗？',
  'isales.manual.message.release.success': '外呼数据释放成功！',
  'isales.manual.message.release.failed': '外呼数据释放失败！',
  'isales.manual.message.releaseData': '选择要释放的外呼数据',
  'isales.manual.message.lockData': '选择要锁定的外呼数据',
  'isales.management.message.blackCalledNo': '被叫号码在黑名单列表中，不能添加',
  'isales.taskstatistics.field.failCode': '外呼失败原因',
  'isales.taskstatistics.field.failCount': '失败数量',
  'isales.appointment.title.appointTime': '预约呼叫时间',
  'isales.appointment.title.task': '关联外呼任务',
  'isales.appointment.title.failCode': '失败原因',
  'isales.appointment.title.remark': '备注',
  'isales.appointment.title.call': '呼叫',
  'isales.appointment.title.createAppoint': '新建预约外呼',
  'isales.appointment.title.editAppoint': '修改预约外呼',
  'isales.appointment.placeholder.appointTime': '请选择预约呼叫时间',
  'isales.appointment.placeholder.remark': '请输入备注信息',
  'isales.appointment.message.afterNow': '预约时间不能早于当前时间！',
  'isales.appointment.message.createSuccess': '预约外呼新建成功',
  'isales.appointment.message.updateSuccess': '预约外呼修改成功',
  'isales.appointment.message.createFail': '预约外呼新建失败',
  'isales.appointment.message.updateFail': '预约外呼修改失败',
  'isales.appointment.message.timeout': '有{0}条预约外呼已过预约时间，请及时呼叫',
  'isales.appointment.title.timeout': '已过预约时间',
  'isales.appointment.title.soontimeout': '即将到达预约时间',
  'isales.appointment.message.soontimeout': '未来5分钟内，有{0}条预约外呼即将到达预约时间，请准备呼叫',
  'isales.manual.message.query.agentstatus.error': '查询座席签入状态异常！',
  'isales.manual.message.agentstatus.isnofree': '当前座席非空闲状态',
  'isales.manual.message.task.isnorunning': '当前外呼任务非运行状态',
  'isales.manual.message.update.fail': '更新外呼数据状态失败',
  'isales.manual.message.data.query.error': '外呼数据查询异常',
  'isales.manual.message.data.checklock.error': '当前外呼数据未锁定或被其他人锁定',
  'isales.manual.message.data.checklock.inblack': '当前外呼号码在黑名单中',
  'isales.manual.message.precall.success': '发起外呼中',
  'isales.manual.title.fail.reason': '失败原因',
  'isales.manual.title.result': '外呼结果',
  'isales.manual.title.status': '外呼状态',
  'isales.manual.title.calledNo': '外呼号码',
  'isales.manual.title.viewManualResult': '手动外呼结果-',
  'isales.manual.title.viewPreviewResult': '预览外呼结果-',
  'isales.manual.title.viewPredictionResult': '预测外呼结果-',
  'isales.manual.title.viewPreemptionResult': '预占外呼结果-',
  'isales.manual.title.inputfailcode': '请输入需要查询的失败原因',
  'isales.manual.title.inputresult': '请输入需要查询的外呼结果',
  'isales.manual.title.inputstatus': '请输入需要查询的外呼状态',
  'isales.manual.field.callflag.inprocess': '呼叫中',
  'isales.manual.field.callflag.finish': '外呼结束',
  'isales.manual.field.callflag.return': '退回',
  'isales.manual.field.result.fail': '失败',
  'isales.manual.field.result.success': '成功',
  'isales.manual.message.data.isalready.called': '该客户呼叫已完成，无法再次呼叫',
  'isales.taskmanagement.message.timeerror': '开始时间不能大于截止时间',
  'isales.appointment.message.callNotAllowed': '当前座席未处于空闲或者示忙状态，该预约外呼不可呼叫！',
  'isales.appointment.message.confirmDelete': '你确认想删除所选的预约外呼吗？',
  'isales.appointment.message.deleteSuccess': '删除预约外呼成功',
  'isales.appointment.message.deleteFail': '删除预约外呼失败',
  'isales.appointment.message.agentNotCalling': '当前座席未处于呼叫中，无法新增预约外呼！',
  'isales.manual.message.taskstatus.error': '当前外呼状态非执行中',
  'isales.manual.message.call.success': '外呼成功',
  'isales.manual.message.not.call': '未发起外呼',
  'isales.manual.message.call.failed': '外呼失败',
  'isales.manual.message.call.update.error': '更新外呼结果失败',
  'isales.manual.message.call.error': '外呼结果处理异常',
  'isales.taskmanagement.title.viewAppointList': '预约',
  'isales.taskmanagement.title.viewAppointListDetail': '预约详情',
  'isales.taskmanagement.title.appoint': '预约外呼',
  'isales.appointment.title.userName': '外呼执行人',
  'isales.appointment.title.callTime': '外呼时间',
  'isales.manualdata.title.updateCalledNo': '更改外呼号码',
  'isales.appointment.message.callSuccess': '呼叫成功',
  'isales.appointment.message.callFail': '呼叫失败',
  'isales.taskmanagement.field.manual': '手动外呼',
  'isales.taskresult.title.userAccount': '外呼执行人',
  'isales.taskresult.title.chooseUserAccount': '请选择外呼执行人',
  'isales.taskmanagement.title.addManual': '新建手动外呼',
  'isales.taskinfo.field.noSpecifiedAgent': '整个技能队列',
  'isales.taskinfo.field.specifiedAgent': '指定座席',
  'isales.taskinfo.title.chooseAgent': '选择座席',
  'isales.manual.message.afterNow': '结束时间不能早于当前时间',
  'isales.appointment.title.add': '新增',
  'isales.taskmanagement.title.view.callrecord': '通话记录',
  'isales.taskmanagement.title.callRecord': '外呼通话记录',
  'isales.taskmanagement.field.calledNo': '被叫号码',
  'isales.taskmanagement.field.calltime': '呼出时间',
  'isales.taskmanagement.field.usersaying': '请求文本',
  'isales.taskmanagement.field.answer': '应答文本',
  'isales.taskmanagement.field.intention': '识别意图',
  'isales.taskinfo.message.agentsEmpty': '已选座席为空',
  'isales.appointment.message.queryTimeError': '开始时间应该小于结束时间',
  'isales.taskmanagement.title.export': '导出通话记录',
  'isales.taskmanagement.field.zip.password': '压缩包密码',
  'isales.taskmanagement.field.zip.input.password': '请输入八到十二位(包含除/\\-#(){\'{\'}{\'}\'};&{\'|\'}<>{\'$\'}`\\\\!\\n\\s\\t\\r外的数字字母特殊字符)',
  'isales.export.message.input.error': '密码输入不符合规范',
  'isales.taskmanagement.message.period.seven': '时间间隔不能大于七天',
  'isales.appointment.message.notInWhiteList': '该号码不在白名单中，无法呼叫',
  'isales.taskinfo.field.audioAgentType': '音频',
  'isales.taskinfo.field.videoAgentType': '视频',
  'isales.taskinfo.field.mediaAgentType': '多媒体',
  'isales.taskinfo.field.versatileAgentType': '全能型',
  'isales.taskInfo.message.saveSuccess': '信息变更成功',
  'isales.taskInfo.message.saveFail': '信息变更失败',
  'isales.appointment.message.tasknotrun': '当前外呼任务并未启动，禁止呼叫',
  'isales.taskresult.field.failsCallednumbernotinwhitelist': '号码不在外呼白名单中，禁止呼出',
  'isales.taskresult.title.retryTime': '试呼次数',
  'isales.appointment.message.callTimeTooEarly': '呼叫时间早于预约时间，禁止呼叫！',
  'isales.appointment.message.plaintext': '用户没有明文权限无法发起呼叫，请开启明文权限',
  'isales.management.message.remarkLengthError': '备注内容长度超过1000',
  'isales.management.message.callNoInBlackList': '号码在黑名单中，禁止呼叫！',
  'isales.taskmanagement.field.zip.makesure.password': '再次输入压缩包密码',
  'isales.taskmanagement.message.password.not.same': '两次输入的密码不一致',
  'isales.taskmanagement.title.out.callresult': '外呼结果',
  'isales.taskresultexport.message.type': '导出类型',
  'isales.taskpolicy.message.input.digits': '输入值必须为正整数',
  'isales.tasktype.field.intelligent': '智能机器人外呼',
  'isales.tasktype.field.robot': '机器人外呼',
  'isales.tasktype.field.predict': '预测外呼',
  'isales.tasktype.field.auto': '普通IVR外呼',
  'isales.callNo.field.type': '号码类型',
  'isales.callNo.field.delayTime': '呼叫失败重试间隔(秒)',
  'isales.callNo.field.add': '新增',
  'isales.callno.message.delayTime': '呼叫失败重试呼叫间隔：当前号码距离上个号码呼叫的时间间隔',
  'isales.callNo.title.no': '序号',
  'isales.data.message.addNumberUpperLimit': '最多只能添加10个号码',
  'isales.data.message.preview.addNumberUpperLimit': '预览外呼最多只能添加{count}个号码',
  'isales.data.message.duplicateExist': '存在重复号码',
  'isales.tasklogic.field.policyType': '策略类型',
  'isales.tasklogic.field.addCallnoLogic': '新增号码策略',
  'isales.tasklogic.field.dataType': '号码类型',
  'isales.tasklogic.field.isResultCallnoLogic': '是否根据结果配置',
  'isales.taskcalllogic.field.globalPolicy': '全局策略',
  'isales.taskcalllogic.field.callnoPolicy': '号码分类匹配策略',
  'isales.tasklogic.title.addCallnoPolicy': '新增结果策略',
  'isales.tasklogic.field.addDataType': '新增号码类型',
  'isales.tasklogic.field.existingDataType': '选择已有号码类型',
  'isales.taskcalllogic.message.alreadyLastPolicy': '已经是最后一条策略',
  'isales.data.filed.noResult': '否',
  'isales.data.filed.isResult': '是',
  'isales.taskcalllogic.message.dataTypeExist': '号码类型已经存在',
  'isales.taskcalllogic.message.maxPolicyNo': '非默认号码类型数量不能超过5',
  'isales.agent.addblocklist.numbertype': '号码',
  'isales.agent.addblocklist.calldatatype': '客户',
  'isales.agent.toblocklist.addType': '添加黑名单方式',
  'isales.data.message.confirmaddblock.calldatatype': '当前为按客户整个外呼数据添加黑名单，请确认',
  'isales.busi.result.title.result': '结果',
  'isales.busi.result.title.description': '描述',
  'isales.busi.result.item.unset': '未设置',
  'isales.busi.result.item.succ': '成功',
  'isales.busi.result.item.uncertain': '待定',
  'isales.busi.result.item.fail': '失败',
  'isales.busi.result.message.recordsucc': '业务结果记录成功。',
  'isales.busi.result.message.recordfail': '业务结果记录失败。',
  'isales.busi.result.message.lastrecord': '请先设置最近一次通话的业务结果。',
  'isales.busi.result.message.lastappointrecord': '请先设置最近一次预约外呼的业务结果。',
  'isales.busi.result.message.nodatarecord': '没有找到通话信息，请选择需要更新业务结果的外呼记录。',
  'isales.data.message.confirmSubmit': '当前号码顺序为呼叫顺序，请再次确认数据无误',
  'isales.callpolicy.title.add': '增加',
  'isales.management.message.updateSuccess': '修改外呼数据成功',
  'isales.management.message.updateFail': '修改外呼数据失败',
  'isales.blacklist.title.validityTime': '有效时长(小时)',
  'isales.blacklist.title.expirationTime': '失效时间',
  'isales.blacklist.title.operateAgent': '添加座席',
  'isales.management.title.updateData': '修改外呼数据',
  'isales.management.title.detailData': '外呼数据详情',
  'isales.management.title.editTime': '时间变更',
  'isales.management.title.editLogic': '逻辑变更',
  'isales.common.message.checknonnegativeinteger': '请输入正确格式的非负整数',
  'isales.common.message.checkpositiveinteger': '请输入正确格式的正整数',
  'isales.taskcalllogic.message.mostPolicys': '一个号码的结果策略最多只能10条.',
  'isales.tasklogic.field.callresultpolicy': '外呼结果策略',
  'isales.tasklogic.field.busiresultpolicy': '业务结果策略',
  'isales.tasklogic.field.enablebusiresultpolicy': '启用业务结果策略',
  'isales.tasklogic.field.connectsameagent': '接入同一座席',
  'isales.tasklogic.field.addbusiresultpolicy': '新增业务策略',
  'isales.tasklogic.field.substate': '子状态',
  'isales.tasklogic.field.policy': '策略',
  'isales.tasklogic.field.interval': '间隔时间(秒)',
  'isales.tasklogic.field.callpolicy': '呼叫策略',
  'isales.tasklogic.field.callmethod': '呼叫方式',
  'isales.tasklogic.field.callbyorder': '按顺序',
  'isales.tasklogic.field.callbyturn': '按轮次',
  'isales.tasklogic.field.callturntype': '轮次形式',
  'isales.tasklogic.field.callturntype.numbertype': '按号码分类',
  'isales.tasklogic.field.callturntype.numberorder': '按号码序号',
  'isales.tasklogic.field.addturn': '添加轮次',
  'isales.tasklogic.field.turn': '轮次',
  'isales.tasklogic.field.numberorder': '号码序号',
  'isales.tasklogic.field.numbertype': '号码分类',
  'isales.tasklogic.field.add.numbertype': '新增号码分类',
  'isales.tasklogic.field.exist.numbertype': '选择已有号码分类',
  'isales.tasklogic.field.default': '默认',
  'isales.tasklogic.message.no.numbertype': '号码序号为空',
  'isales.tasklogic.message.exist.orderNo': '号码序号已存在，请重新输入',
  'isales.tasklogic.message.need.turns': '请至少添加一个轮次数据',
  'isales.common.title.edit': '编辑',
  'isales.taskcalllogic.busiresultpolicy.stopcall': '停止呼叫',
  'isales.taskcalllogic.busiresultpolicy.callnext': '呼叫下个号码',
  'isales.taskcalllogic.busiresultpolicy.callcurrent': '呼叫当前号码',
  'isales.tasklogic.message.busiresultpolicyIncomplete': '业务结果策略信息不完整',
  'isales.tasklogic.message.busiresultpolicyNoadded': '未添加业务结果策略',
  'isales.tasklogic.message.busiresultpolicyNotsaved': '有业务结果策略未保存',
  'isales.tasklogic.message.busiresultpolicySame': '多个业务结果策略的业务结果与子状态相同',
  'isales.tasklogic.message.busiresultpolicyExists': '已存在相同业务结果与子状态的业务结果策略',
  'isales.taskLogic.message.saveSuccess': '外呼逻辑变更成功',
  'isales.taskLogic.message.saveFail': '外呼逻辑变更失败',
  'isales.taskTime.message.saveSuccess': '外呼时间变更成功',
  'isales.taskTime.message.saveFail': '外呼时间变更失败',
  'isales.business.result.searchname': '请输入业务结果名称',
  'isales.business.result.name': '业务结果名称',
  'isales.business.result.code': '业务结果编码',
  'isales.business.result.description': '业务结果描述',
  'isales.business.result.create': '创建业务结果',
  'isales.business.result.edit': '编辑业务结果',
  'isales.business.result.view': '查看业务结果',
  'isales.business.result.children': '子状态',
  'isales.business.name': '名称',
  'isales.business.code': '编码',
  'isales.business.description': '描述',
  'isales.business.result.addchildren': '新增子状态',
  'isales.business.result.deletetips': '确认删除业务结果吗？',
  'isales.business.result.delete.error': '您未选中任何业务结果',
  'isales.business.result.addchildrentips': '一条业务结果下面只允许增加50条子业务结果',
  'isales.business.result.message.specialStr': '不能包含特殊字符',
  'isales.business.result.message.checkCode': '只能输入数字、字母、-',
  'isales.business.result.delete.data.error': '一次只允许删除100条',
  'isales.tasklogic.message.busiresultpolicyNotSaved': '请先保存已有的业务结果策略！',
  'isales.taskpolicy.field.previewTimeoutInterval': '预览超时时长',
  'isales.tasktype.field.preview': '预览外呼',
  'isales.manual.field.previewinformation': '预览信息',
  'isales.manual.field.handlednumber': '外呼标识',
  'isales.manual.field.callednumber': '被叫号码',
  'isales.manual.field.callinginformation': '外呼信息',
  'isales.manual.field.callingtask': '呼叫任务',
  'isales.taskresult.title.userName': '用户名',
  'isales.taskresult.title.operateBeginTime': '开始时间',
  'isales.taskresult.title.operateEndTime': '结束时间',
  'isales.taskresult.title.operationTime': '操作时间',
  'isales.taskresult.title.busiresultLog': '业务结果日志',
  'isales.manual.message.release.DataNotWaitExists': '存在呼叫中或呼叫完成的外呼数据',
  'isales.manual.message.dataId.Empty': 'dataId不能为空',
  'isales.busi.result.message.agentstatus.fail': '查询座席状态失败',
  'isales.busi.result.message.systemparams.fail': '查询系统参数失败',
  'isales.business.result.message.add.tips': '业务结果未定义。',
  'isales.business.result.message.must': '必填项',
  'isales.blacklist.title.modifyRemark': '修改备注',
  'isales.blacklist.message.updateSuccess': '修改成功',
  'isales.taskpolicy.field.previewAutoCallTime': '预览自动呼叫时长',
  'isales.taskinfo.message.autoCallTimeValueError': '预览自动呼叫时长必须小于预览超时时长',
  'isales.taskinfo.message.batchUpdate.paramsNull': '请编辑要修改的参数',
  'isales.agent.contact.Voice': '语音',
  'isales.agent.contact.multimedia': '多媒体',
  'isales.agent.contact.vidio': '视频',
  'isales.agent.contact.audioclicktodial': '语音点击通话',
  'isales.agent.contact.videoclicktodial': '视频点击通话',
  'isales.agent.message.searchSkillName': '请输入技能队列名称',
  'isales.agent.button.search': '查询',
  'isales.agent.button.agent.association': '配置座席',
  'isales.agent.title.skillCreate': '创建技能队列',
  'isales.agent.message.updateCtiAndDbSuccess': '同步刷新技能队列成功',
  'isales.agent.message.deleteSkillAndAgentFail': '删除技能队列关联关系失败',
  'isales.agent.message.addSkillFail': '添加技能队列失败',
  'isales.agent.message.modifySkillFail': '更新技能队列失败',
  'isales.agent.message.queryCallCenterFail': '查询呼叫中心数据失败',
  'isales.agent.message.refreshSkillException': '刷新技能队列异常',
  'isales.agent.message.deleteSkillConfFail': '技能队列信息删除失败。',
  'isales.agent.message.agentMsgException': '请先修改座席初始密码并同步座席',
  'isales.agent.label.information': '提示',
  'isales.agent.label.skillId': ' 技能队列编号',
  'isales.agent.label.skillName': '技能队列名称',
  'isales.agent.label.description': '描述',
  'isales.agent.label.maxWaitTime': '最大排队时间',
  'isales.agent.label.agentList': '座席工号',
  'ccivr.ivr.ivrflow.type': '类型',
  'isales.agent.label.adjustTime': '整理态时长(秒)',
  'isales.agent.label.operate': '操作',
  'isales.agent.title.skillModify': '修改技能队列',
  'isales.agent.message.deleteSkill': '删除技能队列',
  'isales.agent.message.willDeleteSkills': '该操作可能导致关联该技能队列的外呼任务呼叫失败，请谨慎操作。确认需要删除吗？',
  'isales.agent.message.deleteSkillConfSuccess': '技能队列信息删除成功。',
  'isales.agent.message.idIsEmpty': '技能队列id为空。',
  'isales.agent.label.theAgent': '座席',
  'isales.agent.label.useTheSkill': '请先在配置坐席中删除座席与技能队列的绑定关系。',
  'isales.agent.label.skillQueue': '技能队列:',
  'isales.agent.label.bindTheSkill': '已绑定被叫配置，请先解绑被叫配置',
  'isales.agent.label.cti': 'cti',
  'isales.agent.label.deleteCtiFailed': '删除数据失败',
  'isales.agent.message.skillConfFail': '技能队列信息配置失败。',
  'isales.agent.message.warning': '警告',
  'isales.agent.message.selectSkills': '请选择技能队列！',
  'isales.agent.label.skills': '技能队列',
  'isales.agent.label.release': '释放',
  'isales.agent.label.transfer': '转接',
  'isales.agent.label.playVoice': '播放等待音',
  'isales.agent.label.maxWaitNum': '最大排队人数',
  'isales.agent.button.edit': '编辑',
  'isales.agent.label.skillpara': '参数配置',
  'isales.agent.label.timeOut': '排队超时配置',
  'isales.agent.label.transferType': '处理方式',
  'isales.agent.Transfer.devicetype': '转移目的设备类型',
  'isales.agent.label.ivrChoice': '请选择IVR',
  'isales.agent.label.deviceType': '设备类型',
  'isales.agent.label.skillChoice': '请选择技能队列',
  'isales.agent.label.noAgents': '无人上班配置',
  'isales.agent.label.waitVoice': '等待音配置',
  'isales.agent.label.parameterType': '排队等待方式',
  'isales.agent.label.answerMode': '业务代表应答方式配置',
  'isales.agent.button.createComplete': '完成',
  'isales.agent.button.cancel': '取消',
  'isales.agent.label.busy': '排队遇忙、溢出配置',
  'isales.agent.label.answerType': '业务代表应答方式',
  'isales.agent.label.answerType0': '报工号',
  'isales.agent.label.answerType2': '不报语音',
  'isales.agent.pageurls.errorsave': '错误',
  'isales.agent.message.name.limit': '技能队列名称超过最大长度。',
  'isales.agent.calledroute.require': '请填写所有必填字段',
  'isales.agent.pageurls.success': '成功',
  'isales.agent.message.createSkillConfSuccess': '创建技能队列信息成功',
  'isales.agent.calledroute.fail': '失败',
  'isales.agent.calledroute.gateway.fail': '同步CTI平台失败，请检查gateway配置',
  'isales.skillcreate.maxnumber.failed': '租户技能队列数量不能超过限制',
  'isales.agent.message.createSkillConfFail': '创建技能队列信息失败。',
  'isales.agent.accesscode.description.limit': '接入码描述不能超过50个字符',
  'isales.agent.message.modifySkillConfSuccess': '技能队列信息修改成功。',
  'isales.agent.message.modifySkillConfFail': '技能队列信息修改失败。',
  'isales.agent.label.maxWaitTimeSpan': '取值[0-60000]',
  'isales.agent.label.maxAgentWrapupTime': '取值[0-3600]',
  'isales.agent.contact.mediaType': '媒体类型',
  'isales.agent.label.ivr': 'IVR',
  'isales.calledroute.title.selectivrflow': '选择智能机器人',
  'isales.skill.label.skillname': '技能队列名称',
  'isales.skill.label.skilldesc': '描述',
  'isales.skill.label.maxwaittime': '最大排队时间',
  'isales.skill.label.maxwaitnum': '最大排队人数',
  'isales.skill.placeholder.skilldesc': '请输入技能队列名称',
  'isales.management.message.SelectNumber': '请选择号码',
  'isales.taskmanagement.title.tip.batchEditTask': '选择要修改的任务',
  'isales.taskmanagement.title.editCountOutOfBounds': '一次最多修改30条外呼任务',
  'isales.taskinfo.message.batchEditCallLogic.differentPolicyTypeExist': '策略类型不同的外呼任务无法批量修改外呼逻辑',
  'isales.management.message.batchEditCallLogic.notSupportBusiresult': '自动/智能外呼不支持业务结果策略配置，确认批量修改？',
  'isales.management.message.batchEditCallLogic.notSupportBusiresultInCMS': '自动外呼不支持业务结果策略配置，确认批量修改？',
  'isales.management.message.batchEditCallLogic.statusError': '仅草稿/执行中/暂停的任务支持批量修改外呼逻辑',
  'isales.tasklogic.message.confirmBatchUpdate': '批量修改仅勾选区域有效，请确认是否继续提交？',
  'isales.taskinfo.message.batchEditCallLogic.manualExist': '手动外呼不支持修改外呼逻辑',
  'isales.taskinfo.message.batchEditCallLogic.selectEmpty': '未勾选区域，操作无效，请重试',
  'isales.taskinfo.message.batchEditCallLogic.differentCallModeExist': '呼叫方式不同的外呼任务无法批量修改外呼逻辑',
  'isales.taskinfo.message.batchEditCallLogic.differentRoundTypeExist': '呼叫轮次形式不同的外呼任务无法批量修改外呼逻辑',
  'isales.taskinfo.message.batchEditCallLogic.CallPolicyNotification': '已有的轮次不会变化，没有的会新增在已有轮次之后',
  'isales.taskinfo.message.batchEditCallLogic.CallResultNotification': '已有的策略会修改，没有的会新增在已有策略之后',
  'isales.taskinfo.message.batchEditCallLogic.BusiResultNotification': '添加的策略，会覆盖原有的策略',
  'isales.taskcalllogic.message.typeConflictWhenDataTypeSame': '存在与原策略号码类型相同但根据结果配置不同的策略',
  'isales.taskcalllogic.message.callNoPolicyOutOfRangeAfetUpdate': '外呼结果策略的非默认号码策略数量修改后将会超过最大5条',
  'isales.taskcalllogic.message.roundPolicyLengthTooLong': '呼叫策略的轮次数量修改后将会超过最大长度',
  'isales.taskcalllogic.message.dataTypeDefaultNoCreate': '系统默认号码类型',
  'isales.taskcalllogic.message.callNoPolicyEmpty': '请至少新增一条号码策略',
  'isales.result.search.displayResult': '请输入外显结果',
  'isales.result.resultCode': '结果编码',
  'isales.result.displayResult': '外显结果',
  'isales.result.view': '查看外显结果',
  'isales.result.edit': '编辑外显结果',
  'isales.result.delete.error': '没有外显结果被选择',
  'isales.result.deletetips': '确认删除外显结果吗？',
  'isales.result.create': '创建外显结果',
  'isales.result.outgoingCallResult': '外呼结果',
  'isales.result.addOutgoingCallResult': '添加外呼结果',
  'isales.result.outgoingCall.failCode': '外呼结果码',
  'isales.result.outgoingCall.Description': '外呼结果说明',
  'isales.result.addResultMaptips': '最多只能添加50条外呼结果',
  'isales.result.addAgentTips': '最多只能添加20000个座席',
  'isales.result.outgoingCall.input.description': '请输入外呼结果码或外呼结果说明',
  'isales.result.obResultIsNull': '外显结果下的外呼结果不能为空',
  'isales.calledroute.button.cancel': '取消',
  'isales.calledroute.button.finish': '完成',
  'isales.calledroute.title.selectskill': '选择技能队列',
  'isales.taskresult.field.userisunleashed': '座席签入的媒体服务器上的呼叫被释放',
  'isales.taskresult.field.invalidCall': '无效的呼叫',
  'isales.taskresult.field.notCheckIn': '座席当前未签入',
  'isales.calledroute.title.selectagent': '选择座席',
  'isales.agent.placeholder.agentdesc': '请输入座席名称',
  'isales.agent.label.workNo': '座席工号',
  'isales.agent.label.accountName': '业务代表名',
  'isales.agent.label.ccRoleName': '角色名称',
  'isales.skillqueue.button.add': '新增技能队列',
  'isales.specialday.message.enterworkno': '请输入座席工号',
  'isales.taskresult.field.workNo': '座席工号',
  'isales.taskresult.field.sipAccount': '软电话号码',
  'isales.taskresult.field.agentRole': '座席角色名称',
  'isales.taskresult.field.skillqueue': '技能队列',
  'isales.taskresult.field.operation': '操作',
  'isales.taskInfo.message.skillAlreadyExists': '技能队列已存在',
  'isales.agent.message.saveSuccess': '保存座席配置成功',
  'isales.agent.field.skill': '技能队列',
  'isales.agent.field.skillWeight': '技能权重',
  'isales.agent.label.agentWeight': '座席权重',
  'isales.agent.message.chooseAnAgent': '请先选择座席',
  'isales.agent.message.batchSaveSuccess': '批量修改座席成功',
  'isales.agent.message.batchSaveFail': '批量修改座席失败',
  'isales.agent.message.configAgent': '请先配置座席或者同步座席',
  'isales.tasktemplate.title.saveAsTemplate': '存为模板',
  'isales.taskmanagement.title.createDirectly': '直接新建',
  'isales.taskmanagement.title.newFromTemplate': '从模板新建',
  'isales.taskmanagement.title.selectTemplate': '选择模板',
  'isales.tasktemplate.title.inputTemplateName': '请输入模板名称',
  'isales.tasktemplate.title.addTemplate': '新建外呼模板',
  'isales.tasktemplate.title.editTemplate': '修改外呼模板',
  'isales.tasktemplate.title.viewTemplate': '查看外呼模板',
  'isales.tasktemplate.title.autoTemplate': '自动外呼模板',
  'isales.tasktemplate.title.manualTemplate': '手动外呼模板',
  'isales.tasktemplate.title.addAutoTemplate': '新建自动外呼模板',
  'isales.tasktemplate.title.addManualTemplate': '新建手动外呼模板',
  'isales.tasktemplate.field.templateName': '模板名称',
  'isales.tasktemplate.field.release': '发布',
  'isales.tasktemplate.field.released': '已发布',
  'isales.tasktemplate.message.deleteConfirm': '确认要删除所选外呼模板？',
  'isales.tasktemplate.message.releasesuccess': '发布成功',
  'isales.tasktemplate.message.releasefailed': '发布失败',
  'isales.tasktemplate.message.pauseFail': '暂停失败',
  'isales.tasktemplate.message.pauseSuccess': '暂停成功',
  'isales.tasktemplate.message.saveFail': '外呼模板配置失败',
  'isales.tasktemplate.message.saveSuccess': '外呼模板配置成功',
  'isales.tasktemplate.message.nameDuplicated': '存在同名外呼模板',
  'isales.data.prop.propName': '属性名称',
  'isales.data.prop.propCode': '属性编码',
  'isales.data.prop.propType': '属性类型',
  'isales.data.prop.isPushAgent': '是否推送座席',
  'isales.data.prop.mandatory': '是否必填',
  'isales.data.prop.anonym': '是否匿名化',
  'isales.data.prop.status': '发布状态',
  'isales.data.prop.publish': '发布',
  'isales.data.prop.stringType': '字符串',
  'isales.data.prop.numberType': '数字',
  'isales.data.prop.anonymType': '加密',
  'isales.data.prop.status.published': '已发布',
  'isales.data.prop.status.unpublished': '未发布',
  'isales.data.prop.editDataProp': '编辑属性',
  'isales.data.prop.createDataProp': '创建属性',
  'isales.data.prop.inputPropName': '请输入属性名称',
  'isales.data.prop.inputPropCode': '请输入属性编码',
  'isales.data.prop.editDataPropSuccess': '编辑属性成功',
  'isales.data.prop.createDataPropSuccess': '创建属性成功',
  'isales.data.prop.editDataPropFail': '编辑属性失败',
  'isales.data.prop.createDataPropFail': '创建属性失败',
  'isales.data.prop.propParamsError': '属性参数校验失败',
  'isales.data.prop.propCodeExists': '属性编码已存在',
  'isales.data.prop.propNameExists': '属性名称已存在',
  'isales.data.prop.message.confirmDelete': '您确认想删除所选的属性吗？',
  'isales.data.prop.stringType.countError': '字符串属性类型最多可定义30个',
  'isales.data.prop.numberType.countError': '数字属性类型最多可定义10个',
  'isales.data.prop.isPushAgent.countError': '推送座席的属性最多可定义15个',
  'isales.data.prop.anonymType.countError': '加密属性类型最多可定义10个',
  'isales.data.prop.isPushAgent.anonymTypeError': '推送座席属性类型不可以设置为加密',
  'isales.data.propConfig.button.configProp': '配置属性',
  'isales.data.propConfig.title.isPushAgent': '是否推送座席',
  'isales.data.propConfig.message.isUseProp': '启用自定义属性',
  'isales.data.propConfig.title.prop': '属性',
  'isales.file.title.create': '添加',
  'isales.file.title.ip': '地址',
  'isales.file.title.port': '端口',
  'isales.file.title.username': '用户名',
  'isales.file.title.filepath': '默认路径',
  'isales.file.title.password': '密码',
  'isales.file.title.ip.placeholder': '请输入服务器ip',
  'isales.file.title.port.placeholder': '请输入服务器端口',
  'isales.file.title.username.placeholder': '请输入登录服务器用户名',
  'isales.file.title.filepath.placeholder': '请输入文件路径，如/home',
  'isales.file.title.password.placeholder': '请输入登录服务器密码',
  'isales.file.title.addserver': '添加服务器',
  'isales.file.title.server.info': '编辑服务器信息成功',
  'isales.file.title.server.fail': '编辑服务器信息失败',
  'isales.file.title.delete.fail': '删除服务器信息失败',
  'isales.file.title.editserver': '编辑服务器',
  'isales.file.title.rightpath': '请输入合法路径，如/isales/upload',
  'isales.file.title.rule.basic': '基本信息',
  'isales.file.title.rule.name': '规则名称',
  'isales.file.title.rule.desc': '规则描述',
  'isales.file.title.addrule': '添加规则',
  'isales.file.title.editrule': '编辑规则',
  'isales.file.title.rule.name.placeHolder': '请输入规则名称',
  'isales.file.title.rule.desc.placeHolder': '请输入规则描述',
  'isales.file.title.rule.data': '号码数据',
  'isales.file.title.rule.index': '号码序号',
  'isales.file.title.rule.number': '号码列号',
  'isales.file.title.rule.type': '分类列号',
  'isales.file.title.rule.interval': '呼叫间隔列号',
  'isales.file.title.rule.property': '业务属性',
  'isales.file.title.rule.property.name': '属性名称',
  'isales.file.title.rule.property.row': '属性列号',
  'isales.file.title.rule.validator.row': '请输入A-EZ列中的序号',
  'isales.file.title.rule.validator.maxdata': '最多添加40条号码配置数据',
  'isales.file.title.rule.validator.ruleId': '映射规则不存在',
  'isales.file.title.rule.validator.taskId': '任务信息不存在',
  'isales.file.title.import.file.invalid': '文件校验失败',
  'isales.file.title.rule.validator.prop': '任务发布的自定义属性与规则配置的属性不一致',
  'isales.file.title.rule.validator.server': '服务器配置信息不正确',
  'isales.file.message.rule.success': '编辑规则成功',
  'isales.file.message.rule.fail': '编辑规则失败',
  'isales.management.message.fileImport': '文件导入',
  'isales.management.message.serverImport': '服务器导入',
  'isales.management.message.appendMode': '追加',
  'isales.management.message.coverMode': '覆盖',
  'isales.management.message.templateAnalyse': '按模板',
  'isales.management.message.ruleAnalyse': '按规则',
  'isales.management.message.importType': '导入方式',
  'isales.management.message.importMode': '导入模式',
  'isales.management.message.import.server': '服务器',
  'isales.management.message.import.filePath': '文件地址',
  'isales.management.message.import.fileName': '文件名',
  'isales.management.message.import.analyseMode': '解析方式',
  'isales.management.message.import.mapRule': '映射规则',
  'isales.data.title.timeImport': '定时导入',
  'isales.management.title.timeImport': '定时导入配置',
  'isales.management.title.timeImport.execTime': '执行时间',
  'isales.timeImport.message.success': '定时导入增加配置成功',
  'isales.timeImport.message.fail': '定时导入增加配置失败',
  'isales.agent.field.curcountpassword': '当前帐号密码',
  'isales.agent.button.batchmodify': '批量修改',
  'isales.agent.button.restAgentPwd': '修改座席密码',
  'isales.agent.title.agentEdit': '座席信息配置',
  'isales.agent.field.newpassword': '座席新密码',
  'isales.agent.field.newpasswordconf': '重复新密码',
  'isales.agent.message.passValidateFailed': '应包含大写字母，小写字母，数字，特殊字符`~!{\'@\'}#{\'$\'}^&*()-_+{\'|\'}[{\'{\'}{\'}\'}]<.>/?中的至少3种',
  'isales.agent.message.passLengthValidateFailed': '输入的密码长度必须为8',
  'isales.agent.message.modifyPwdSuccess': '修改座席密码成功',
  'isales.agent.message.syncPwdFailed': '座席密码同步失败',
  'isales.agent.message.pwdRepeat': '提交的密码与最近修改过的密码相同',
  'isales.agent.message.modifyPwdFailed': '修改座席密码失败',
  'isales.file.message.rule.dataIsNull': '号码数据不能为空',
  'isales.timeImport.message.rulePropsError': '定时导入所选规则的业务属性和当前任务定义的属性不一致!',
  'isales.import.message.selectServer.tips': '外呼文件服务器未定义。',
  'isales.import.message.selectRule.tips': '导入映射规则未定义。',
  'isales.file.message.rule.receiver.columnRepeat': '接收者列号和属性的列号不能重复',
  'isales.file.message.rule.columnRepeat': '外呼标识列号、号码列号、分类列号、呼叫间隔列号和属性的列号不能重复',
  'isales.taskresult.field.other': '其他',
  'isales.taskmanagement.title.speclistmanage.calledno': '外呼号码',
  'isales.taskmanagement.title.speclistmanage.reason': '原因',
  'isales.specialday.title.speclistmanage.moveblacklists': '转移至黑名单',
  'isales.specialday.title.speclistmanage.misjudgment': '误判',
  'isales.speclistmanage.title.calledNo': '号码',
  'isales.speclistmanage.title.reason': '原因',
  'isales.speclistmanage.title.operation': '操作',
  'isales.specreason.field.all': '请选择',
  'isales.specreason.field.emptynumber': '空号',
  'isales.specreason.field.systemtone': '系统音',
  'isales.specist.message.speclistmanage': '选择要操作的特殊名单号码',
  'isales.blacklist.message.confirmOpt': '您确认想操作所选的特殊名单号码吗？',
  'isales.specist.title.misjudgment': '误判',
  'isales.specist.message.updateFail': '修改失败',
  'isales.tasklogic.field.callfilter': '外呼筛选',
  'isales.tasklogic.field.addCondition': '添加条件',
  'isales.tasklogic.field.addSubCondition': '添加子条件',
  'isales.tasklogic.field.logic': '运算符',
  'isales.tasklogic.field.value': '值',
  'isales.tasklogic.field.selectProp': '添加属性',
  'isales.management.title.selectProp': '选择属性',
  'isales.specialday.title.confirm': '确认',
  'isales.data.prop.quantity': '数量',
  'isales.tasklogic.field.and': '与',
  'isales.tasklogic.field.or': '或',
  'isales.tasklogic.field.eq': '=',
  'isales.tasklogic.field.neq': '!=',
  'isales.tasklogic.field.gt': '>',
  'isales.tasklogic.field.egt': '>=',
  'isales.tasklogic.field.lt': '<',
  'isales.tasklogic.field.elt': '<=',
  'isales.tasklogic.field.like': '包含',
  'isales.tasklogic.label.primaryCondition': '主条件',
  'isales.tasklogic.label.subCondition': '子条件',
  'isales.tasklogic.field.orderPolicy': '呼叫排序',
  'isales.tasklogic.field.addOrderPolicy': '添加属性',
  'isales.tasklogic.field.prop': '属性',
  'isales.tasklogic.field.moveUp': '上移',
  'isales.tasklogic.field.moveDown': '下移',
  'isales.tasklogic.message.repeatProp': '不可选择重复属性',
  'isales.skillqueue.agentrole.administrator': '租间管理员',
  'isales.skillqueue.agentrole.agent': '座席',
  'isales.skillqueue.agentrole.qc': '质检员',
  'isales.data.prop.orderType': '排序类型',
  'isales.data.orderType.asc': '升序',
  'isales.data.orderType.desc': '降序',
  'isales.speclist.blacklist.message.moveSuccess': '号码转移至黑名单成功',
  'isales.speclist.blacklist.message.movePartiallyFail': '号码转移至黑名单失败',
  'isales.taskmanagment.title.callquality.viewstatistics': '呼叫质量统计',
  'isales.taskmanagment.callquality.maxConnectedRate': '最高接通率',
  'isales.taskmanagment.callquality.minConnectedRate': '最低接通率',
  'isales.taskmanagment.message.chartDataError': '加载图表失败',
  'isales.taskmanagment.title.open.agentMonitor': '座席监控',
  'isales.taskmanagment.title.open.callMonitor': '呼叫监控',
  'isales.callquality.statistics.message.afterNow': '不能选择早于任务开始时间和晚于今天的时间！',
  'isales.taskmanagment.title.callMonitor': '呼叫监控图',
  'isales.taskmanagment.title.callMonitor.tips': '间隔15分钟自动刷新',
  'isales.taskstatistics.field.callstatistics': '呼叫统计',
  'isales.taskstatistics.field.totalNumberOfUsers': '用户总数',
  'isales.taskstatistics.field.Completed': '完成呼叫的客户数',
  'isales.taskstatistics.field.inProgress': '正在呼叫的客户数',
  'isales.taskstatistics.field.incomplete': '等待呼叫的客户数',
  'isales.taskstatistics.field.numberOfConnectedCalls': '用户接通数',
  'isales.taskstatistics.field.numberOfUnconnectedCalls': '用户未接通数',
  'isales.taskstatistics.field.touchRate': '触达率',
  'isales.taskstatistics.field.callCompletionRate': '接通率',
  'isales.taskstatistics.field.abandonRate': '放弃率',
  'isales.taskstatistics.field.callResultStatistics': '呼叫结果统计',
  'isales.statistics.field.touchRateDescription': '触达率=接通用户数/用户数',
  'isales.statistics.field.callCompletionRateDescription': '接通率=接通用户数/外呼次数',
  'isales.statistics.field.abandonmentRateDescription': '放弃率=放弃数/呼叫总数',
  'isales.taskstatistics.message.Unit.Piece': '单位（个）',
  'isales.taskstatistics.field.callRatePie': '呼叫完成度',
  'isales.taskstatistics.field.connectedCallsPie': '用户接通量',
  'isales.business.result.delete': '删除业务结果',
  'isales.business.result.search': '查询业务结果',
  'isales.business.result.deleteChildrenTips': '确认删除子业务结果吗？',
  'isales.business.result.searchChildrenName': '请输入子业务结果名称',
  'isales.file.message.deletefiletips': '确认删除外呼文件服务器吗？',
  'isales.file.message.deleteruletips': '确认删除导入映射规则吗？',
  'isales.work.title.serviceAccount': '业务帐号',
  'isales.work.title.surveyAnswerNum': '问卷作答次数',
  'isales.work.title.reservedCalls': '预约呼叫数',
  'isales.work.title.totalOutgoingCalls': '呼出总数',
  'isales.work.title.successNum': '呼出成功数',
  'isales.work.title.completeNum': '完成问卷数',
  'isales.work.title.calledNum': '已处理呼叫数',
  'isales.work.title.callTimeTotal': '通话总时长（秒）',
  'isales.work.title.callTimeAvg': '平均通话时长（秒）',
  'isales.work.title.busiSuccess': '业务成功',
  'isales.work.title.busiFail': '业务失败',
  'isales.work.title.outboundCallTask': '外呼任务',
  'isales.work.title.multimediaManage': '多媒体营销管理',
  'isales.work.message.dategt7days': '查询时间间隔不允许超过7天',
  'isales.work.message.agentOrTaskChange': '外呼任务和座席工号至少选一个',
  'isales.import.message.closeExtProp': '按模板导入必需禁止其中自定义属性，请点击导入旁边的配置属性按钮，进行关闭启用自定义属性',
  'isales.file.message.endPoint.placeholder': '请输入地址',
  'isales.file.title.bucket': '桶',
  'isales.file.message.bucket.placeholder': '请输入桶',
  'isales.file.title.AK': 'AK',
  'isales.file.title.SK': 'SK',
  'isales.file.message.AK.placeholder': '请输入AK',
  'isales.file.message.SK.placeholder': '请输入SK',
  'isales.file.title.serverInformation': '服务器信息',
  'isales.file.title.userNameOrAK': '用户名/AK',
  'isales.file.message.isDeleteFile': '是否删除源文件',
  'isales.management.title.adjustment': '调整',
  'isales.agent.query.error': '目标座席与所选记录的执行人不能相同。',
  'isales.management.title.adjustment.log': '调整记录',
  'isales.management.title.adjustment.changeTime': '调整时间',
  'isales.management.title.adjustment.orgAccountName': '调整前执行人',
  'isales.management.title.adjustment.destAccountName': '调整后执行人',
  'isales.management.title.adjustment.modifier': '调整人',
  'isales.management.title.adjustment.remark': '备注',
  'isales.appointment.message.adjustmentSuccess': '调整预约外呼成功',
  'isales.appointment.message.adjustmentFail': '调整预约外呼失败',
  'isales.appointment.message.adjustmentEmpty': '未选中有效的预约外呼数据',
  'isales.tasksample.message.agentEmpty': '未选择实训对象',
  'isales.work.title.selectTask': '选择外呼任务',
  'isales.import.button.configProp': '配置过滤规则',
  'isales.import.filter.button.lineRepeat': '过滤行重复数据',
  'isales.import.filter.button.noRepeat': '过滤批次重复数据',
  'isales.import.filter.button.recentCall': '过滤最近已呼数据',
  'isales.import.filter.button.callResult': '过滤业务结果数据',
  'isales.import.filter.button.readyCall': '过滤尚未呼叫数据',
  'isales.import.filter.button.calling': '过滤尚未呼叫完成数据',
  'isales.import.filter.title.popup': '过滤规则',
  'isales.import.filter.title.callDay': '最近已呼的时间（天）',
  'isales.import.filter.message.selectRuleTips': '至少选择一个过滤规则',
  'isales.taskmanagement.field.basicTaskInfo': '基本信息',
  'isales.taskmanagement.field.pickUpRule': '自动提取规则',
  'isales.taskmanagement.field.isAutoPickUp': '启用自动提取规则',
  'isales.taskmanagement.field.pickUpRuleLimit': '提取限制',
  'isales.taskmanagement.field.pickUpRuleBusiResult': '业务结果',
  'isales.taskmanagement.field.quotas': '配额',
  'isales.taskmanagement.field.callCompletedNum': '完成呼叫数',
  'isales.taskmanagement.field.pickUpNum': '每次提取数量',
  'isales.taskmanagement.field.pickUpCondition': '提取条件',
  'isales.manualdata.title.pickUp': '提取',
  'isales.manualData.message.pickUpSuccess': '提取成功',
  'isales.manualData.message.reachQuatos': '该任务下的呼叫数已经达到配额上限，不能再提取外呼数据',
  'isales.manualData.message.reachMax': '该任务下的呼叫数已经达到呼叫上限，不能再提取外呼数据',
  'isales.manualData.message.pickUpFailed': '没有可提取的数据',
  'isales.taskstatistics.message.no.busiResult': '没有标记业务结果',
  'isales.appointmanagement.field.remark': '备注',
  'isales.appointmanagement.title.inputRemark': '请输入备注',
  'isales.agent.query.emptyError': '未选中有效的坐席数据',
  'isales.import.filter.message.busiResultIsNull': '业务结果不能为空',
  'isales.import.filter.message.busiSubStatusSame': '同一业务结果下的子业务结果不能相同',
  'isales.file.server.message.checkObsPath': '只能输入数字、字母、-、.',
  'isales.file.server.message.notChinese': '不能输入中文',
  'isales.manual.title.sendEmail': '发送Email',
  'isales.manual.button.send': '发送',
  'isales.manual.title.recipient': '收件人',
  'isales.manual.message.recipient': '请输入收件人',
  'isales.manual.title.title': '标题',
  'isales.manual.message.title': '请输入标题',
  'isales.file.message.isDeleteFileTips': '删除导入OBS服务器上数据文件',
  'isales.file.message.FilePathTips': '非必填，外呼文件放在默认路径下可不填，如果放在默认路径下的文件夹里面，需填文件路径',
  'isales.operlog.message.downloadAgentWorkExportFile': '下载座席工作量导出结果文件。',
  'isales.operlog.message.downloadSurveyExportFile': '导出实训问题。',
  'isales.taskinfo.field.chooseOrInputCallNo': '支持同时输入和选择主叫号码',
  'isales.taskinfo.field.mutiCallNo': '请输入主叫号码',
  'isales.agent.message.curcountpasswordWrong': '当前帐号密码错误',
  'isales.taskinfo.callno.repeat': '主叫号码重复',
  'isales.file.title.password.again': '再次输入密码',
  'isales.file.title.password.same': '两次输入的密码不一致',
  'isales.file.title.sk.same': '两次输入的sk不一致',
  'isales.file.title.SK.again': '再次输入sk',
  'isales.taskinfo.callno.length': '单个主叫号码长度超过32',
  'isales.workbench.execution': '外呼执行',
  'isales.workbench.task.management': '外呼管理',
  'isales.workbench.appointment.task': '预约任务',
  'isales.workbench.number.execution': '执行',
  'isales.notification.receiver.address': '接收者地址',
  'isales.notification.receiver.number': '接收者号码',
  'isales.notification.receiver.address.placeholder': '请输入接收者地址',
  'isales.notification.receiver.number.placeholder': '请输入接收者号码',
  'isales.notification.configVariable': '变量配置',
  'isales.notification.receiver.tips': '接收者唯一，请不要重复输入',
  'isales.marketing.mode': '营销模式',
  'isales.marketing.OutgoingCall': '外呼营销',
  'isales.marketing.notification': '多媒体营销',
  'isales.notification.receiver': '接收者',
  'isales.notification.receiver.info': '接收者信息',
  'isales.import.message.errMsg': '异常原因',
  'isales.notification.variable.name': '变量名称',
  'isales.notification.propo.name': '属性名称',
  'isales.notification.variable.value': '变量值',
  'isales.notification.import.button': '数据导入',
  'isales.taskpolicy.message.obOverTime': '请先填写预览超时时长',
  'isales.taskpolicy.message.rangeAutoCallTime': '输入值超出范围：{0}-{1}',
  'isales.taskpolicy.message.obOverTimeRange': '预览超时时长超出范围：9-120\,请先更改预览超时时长的输入值',
  'isales.task.promotion.pauseSuccess': '多媒体营销任务暂停成功',
  'isales.task.promotion.pauseFaied': '多媒体营销任务暂停失败',
  'isales.task.promotion.startSuccess': '多媒体营销任务发布成功',
  'isales.task.promotion.startFailed': '多媒体营销任务发布成功',
  'isales.task.promotion.isExp': '多媒体营销任务已失效',
  'isales.task.promotion.saveSuccess': '保存多媒体营销任务成功',
  'isales.task.promotion.nameRepeat': '多媒体营销任务名称重复',
  'isales.task.promotion.saveFailed': '保存多媒体营销任务失败',
  'isales.task.promotion.deleteSuccess': '删除多媒体营销任务成功',
  'isales.task.promotion.statusStart': '多媒体营销任务运行中',
  'isales.task.promotion.deleteFailed': '删除多媒体营销任务失败',
  'isales.task.promotion.updateSuccess': '编辑多媒体营销任务成功',
  'isales.task.promotion.updateFailed': '编辑多媒体营销任务失败',
  'isales.task.promotion.messageTemplate': '通知模板',
  'isales.task.promotion.promotionName': '营销名称',
  'isales.task.promotion.inputPromotionName': '请输入营销名称',
  'isales.task.promotion.basicInfo': '基本信息',
  'isales.task.promotion.promotionType': '媒体类型',
  'isales.task.promotion.promotionEffTime': '生效时间',
  'isales.task.promotion.promotionExpTime': '失效时间',
  'isales.task.promotion.baseInfo': '基础信息',
  'isales.task.promotion.promotionPolicy': '营销策略',
  'isales.task.promotion.templateType': '模板类型',
  'isales.task.promotion.sendTemplate': '营销通知模板',
  'isales.task.promotion.selectSendTemplate': '请选择模板',
  'isales.task.promotion.prefix': '国家码',
  'isales.task.promotion.sendGateway': '网关配置',
  'isales.task.promotion.selectSendGateway': '请选择网关',
  'isales.task.promotion.sender': '发送者',
  'isales.task.promotion.sendLimit': '单日发送限制',
  'isales.task.promotion.isRetry': '失败是否重试',
  'isales.task.promotion.retryTimes': '失败重试次数',
  'isales.task.promotion.isSendNow': '是否立即发送',
  'isales.task.promotion.expectedTime': '期望发送时间',
  'isales.task.promotion.dndTime': '免打扰时段',
  'isales.task.promotion.sms': '短信',
  'isales.task.promotion.email': '邮件',
  'isales.task.promotion.hwsms': '华为云短信',
  'isales.task.promotion.smsgw': '短信网关',
  'isales.task.promotion.promotionStatus': '营销状态',
  'isales.task.promotion.tobesent': '待发送量',
  'isales.task.promotion.invalidation': '失效',
  'isales.task.promotion.all': '全部',
  'isales.task.promotion.create': '新建多媒体营销任务',
  'isales.task.promotion.edit': '编辑多媒体营销任务',
  'isales.task.promotion.view': '查看多媒体营销任务',
  'isales.task.promotion.message.variableIsNull': '属性配置列表为空',
  'isales.task.promotion.message.variableNotConfig': '有变量未配置',
  'isales.task.promotion.message.limitGtTenantLimit': '单日发送限制超出租户限制',
  'isales.task.promotion.message.dndEndTimeltBeginTime': '免打扰时间结束时间早于开始时间',
  'isales.task.promotion.message.dndTimeltCrossExpectedTime': '期望发送时间不能在免打扰时间段内',
  'isales.notification.viewResult.button': '查看营销结果',
  'isales.task.promotion.message.dndTimeError': '免打扰时间段不完整',
  'isales.task.promotion.message.selectPromotionType': '请先选择媒体类型',
  'isales.import.filter.button.notification.noisPush': '过滤尚未推送数据',
  'isales.import.filter.button.notification.isPushCom': '过滤尚未推送完成数据',
  'isales.import.filter.title.notification.isPushDay': '最近已推的时间（天）',
  'isales.notification.configVariableRel': '变量与属性关系配置',
  'isales.notification.configVariableValue': '变量值配置',
  'isales.notification.prop.tips': '属性唯一，请不要重复输入',
  'isales.notification.receiver.delete.error': '没有数据被选中',
  'isales.notification.receiver.delete.variable': '请配置属性',
  'isales.import.filter.button.notification.isPush': '过滤最近已推数据',
  'isales.task.promotion.templateCode': '模板编码',
  'isales.task.promotion.gateway': '发送网关',
  'isales.task.promotion.sendStatus': '发送状态',
  'isales.task.promotion.beginTime': '开始时间',
  'isales.task.promotion.endTime': '结束时间',
  'isales.task.promotion.receiver': '接收者',
  'isales.task.promotion.sendTime': '发送时间',
  'isales.sendStatus.field.prepare': '准备发送',
  'isales.sendStatus.field.sending': '发送中',
  'isales.sendStatus.field.success': '发送成功',
  'isales.sendStatus.field.fail': '发送失败',
  'isales.sendStatus.field.all': '全部',
  'isales.task.promotion.message.selectTemplateType': '请先选择模板类型',
  'isales.task.promotion.remark': '失败原因',
  'isales.task.promotion.createTime': '创建时间',
  'isales.task.promotion.message.monthGtThree': '只能查询三个月的结果',
  'isales.notification.configVariableTe': '模板变量配置',
  'isales.notification.config': '配置',
  'isales.notification.receiver.number.tips': '号码只能由数字组成',
  'isales.notification.variable.defvalue': '默认值',
  'isales.task.promotion.message.noprop': '请先到外呼数据属性定义菜单添加属性',
  'isales.task.promotion.message.variableSelected': '请选择属性',
  'isales.notification.receiver.deletetips': '确认删除所选数据吗？',
  'isales.task.promotion.tempalteError': '营销通知模板与模板类型不匹配',
  'isales.task.promotion.gatewwayError': '网关配置与媒体类型不匹配',
  'isales.taskmanagement.field.taskId': '任务标识',
  'isales.taskmanagement.title.inputTaskId': '请输入任务标识',
  'isales.taskinfo.field.callBackUrl': '回调地址',
  'isales.taskinfo.message.callBackUrl': '请输入正确的回调地址',
  'isales.tasktype.field.preemption': '预占外呼',
  'isales.taskmanagement.title.toBeCallCount': '待呼叫量',
  'isales.manual.field.canBeCallCount': '可呼叫量',
  'isales.manual.field.noDataCanbeCallTips': '当前可呼叫数量为0，请检查属性配置是否满足筛选条件。',
  'isales.tasklogic.field.callOrderType.noOrder': '无序呼叫',
  'isales.tasklogic.field.callOrderType.isOrder': '有序呼叫',
  'isales.tasklogic.field.callOrderMethod': '排序规则',
  'isales.tasklogic.field.callOrderMethod.byOrderNo': '按序号',
  'isales.tasklogic.field.callOrderMethod.bySelf': '按自定义规则',
  'isales.tasklogic.message.need.orderPolicy': '至少添加一个自定义排序规则',
  'isales.taskmanagement.message.beforeNow': '开始时间不能早于当前时间',
  'isales.operation.success': '操作成功',
  'isales.operation.training.success': '操作成功。训练大约需要几个小时，训练进度请登录Lodas查看作业详情。',
  'isales.operation.failed': '操作失败',
  'isales.taskmanagement.title.clicktorefresh': '点击刷新',
  'isales.audio.content.calltype': '语音呼出',
  'isales.audio.message.errortip1': '查询录音信息失败\,请联系管理员',
  'isales.audio.title.audiorate': '播放速率',
  'isales.audio.title.callId': '录音流水号',
  'isales.audio.title.audiotime': '录音时长(HH:mm:ss)',
  'isales.audio.title.starttime': '录音开始时间',
  'isales.audio.title.endtime': '录音结束时间',
  'isales.audio.title.calltype': '呼叫类型',
  'isales.audio.title.recording': '录音试听',
  'isales.datasourcemanagement.field.vdnId': 'VDNID',
  'isales.datasourcemanagement.title.inputVdnId': '请输入VDNID',
  'isales.datasourcemanagement.field.dbId': '数据源编号',
  'isales.datasourcemanagement.title.inputDbId': '请输入数据源编号',
  'isales.datasourcemanagement.field.dbschema': '数据源名称',
  'isales.datasourcemanagement.title.inputDbschema': '请输入数据源名称',
  'isales.datasourcemanagement.field.dbType': '数据源类型',
  'isales.datasourcemanagement.field.dbdriver': '数据源驱动',
  'isales.datasourcemanagement.field.dsType.all': '全部',
  'isales.datasourcemanagement.field.dsType.mysql': 'MYSQL',
  'isales.datasourcemanagement.field.dsType.oracle': 'ORACLE',
  'isales.datasourcemanagement.field.dsType.gauss': '高斯',
  'isales.datasourcemanagement.field.dsType.dm': '达梦',
  'isales.datasourcemanagement.field.add': '新增',
  'isales.datasourcemanagement.field.sync': '同步',
  'isales.datasourcemanagement.field.backDbdriver': '备用数据源驱动名',
  'isales.datasourcemanagement.field.addDataSource': '新增外呼数据源',
  'isales.datasourcemanagement.field.dbIp': '数据源IP或别名',
  'isales.datasourcemanagement.field.dbPort': '数据源端口',
  'isales.datasourcemanagement.field.dbCnUrl': '数据源连接',
  'isales.datasourcemanagement.field.dbUserName': '数据源用户名',
  'isales.datasourcemanagement.field.dbPass': '数据源密码',
  'isales.datasourcemanagement.field.dbPass2': '确认数据源密码',
  'isales.data.propConfig.message.isExistBackDb': '备用数据源',
  'isales.datasourcemanagement.field.backdbId': '备用数据源编号',
  'isales.datasourcemanagement.field.backdbUserName': '备用数据源用户名',
  'isales.datasourcemanagement.field.backdbType': '备用数据库类型',
  'isales.datasourcemanagement.field.backdbIp': '备用数据库IP或别名',
  'isales.datasourcemanagement.field.backdbport': '备用数据库端口',
  'isales.datasourcemanagement.field.backDbCnUrl': '备用数据源连接',
  'isales.datasourcemanagement.field.backdbpass': '备用数据源密码',
  'isales.datasourcemanagement.field.backdbpass2': '确认备用数据源密码',
  'isales.datasourcemanagement.field.backdbSchema': '备用数据源名称',
  'isales.datasourcemanagement.field.editDataSource': '编辑外呼数据源',
  'isales.datasourcemanagement.field.viewDataSource': '查看外呼数据源',
  'isales.datasourcemanagement.message.dbIp': '数据库IP格式不正确',
  'isales.datasourcemanagement.message.dbpass': '请输入一致的密码',
  'isales.datasourcemanagement.message.addDataSourceFail': '新建外呼数据源失败',
  'isales.datasourcemanagement.message.addDataSourceSuccess': '新建外呼数据源成功',
  'isales.datasourcemanagement.message.alreadyExistDs': '此VDN已经存在外呼数据源',
  'isales.datasourcemanagement.message.confirDelete': '删除数据源配置为高风险操作，请谨慎操作.',
  'isales.datasourcemanagement.message.deleteDataSourceFail': '删除数据源失败',
  'isales.datasourcemanagement.message.deleteDataSourceSuccess': '删除数据源成功',
  'isales.datasourcemanagement.message.updateDataSourceSuccess': '修改数据源成功',
  'isales.datasourcemanagement.message.updateDataSourceFail': '修改数据源失败',
  'isales.datasourcemanagement.message.frequentOperation': '操作频繁，请稍后',
  'isales.datasourcemanagement.message.noObsINFO': '未配置或错误配置外呼数据源',
  'isales.datasourcemanagement.message.passValidateFailed': '应包含大写字母\,小写字母\,数字\,特殊字符`~!{\'@\'}#{\'$\'}^&*()-_+{\'|\'}[{\'{\'}{\'}\'}]<.>/?中的至少3种',
  'isales.datasourcemanagement.message.syncSuccess': '同步数据源成功',
  'isales.tasklogic.field.noRetryLogic': '新增不重试结果码',
  'isales.management.title.selectResultCode': '选择结果码',
  'isales.management.title.setResultCode': '请设置结果码',
  'isales.manualData.message.noPickCondition': '请设置提取条件',
  'isales.enum.workSpace': '座席外呼任务',
  'isales.survey.statistic': '答卷统计',
  'isales.survey.querydetail': '问卷结果查询',
  'isales.taskmanagment.title.obs.viewstatistics': 'OBS获取数据监控',
  'isales.taskmanagment.title.open.obsMonitor': 'OBS数据监控',
  'isales.task.statistics.obs.startTime': '开始时间不能早于七天前',
  'isales.task.statistics.obs.endTime': '开始时间和结束时间相差不能超过半小时',
  'isales.taskstatistics.field.specialCall': '特殊名单',
  'isales.agent.calledroute.search.devicedesc': '请输入设备名称',
  'isales.agent.calledroute.create': '新增被叫配置',
  'isales.agent.calledroute.device': '请选择设备',
  'isales.agent.calledroute.devicetype': '设备类型',
  'isales.agent.calledroute.extcode': '扩展码',
  'isales.agent.calledroute.ivr': '请选择IVR',
  'isales.agent.calledroute.placeholder.ivr': '请选择IVR',
  'isales.agent.calledroute.skill': '技能队列',
  'isales.agent.calledroute.placeholder.skill': '请选择一个技能队列',
  'isales.agent.calledroute.accesscode': '接入码',
  'isales.agent.calledroute.placeholder.accesscode': '请选择接入码',
  'isales.agent.calledroute.placeholder.extcode': '请输入扩展码',
  'isales.calledroute.title.selectaccesscode': '选择接入码',
  'isales.agent.calledroute.extcodewarn': '扩展码只能包含数字',
  'isales.agent.calledroute.extlengthcodewarn': '扩展码长度不能超过12',
  'isales.agent.calledroute.editsuccess': '编辑成功',
  'isales.agent.calledroute.exception': '请检查Redis状态或CC-Gateway配置。',
  'isales.agent.calledroute.editfail': '本地数据编辑失败',
  'isales.agent.calledroute.accesscodewarn': '接入码只能包含数字',
  'isales.agent.calledroute.createsuccess': '新增成功',
  'isales.agent.calledroute.createfail': '本地数据新增失败',
  'isales.agent.calledroute.create.accesscode.none': '被叫配置的系统接入码没有分配',
  'isales.agent.calledroute.create.accesscode.repeat': '被叫配置的系统接入码和扩展码组合不能重复.',
  'isales.agent.calledroute.create.accesscode.noexist': '被叫配置引用的接入码不存在.',
  'isales.agent.calledroute.create.skill.none': '被叫配置引用的技能队列不存在.',
  'isales.agent.calledroute.create.ivr.limit': '设备类型为IVR，接入码的媒体类型只能为点击通话，回呼请求，普通呼叫.',
  'isales.agent.calledroute.create.gateway.none': '被叫配置引用的路由脚本不存在.',
  'isales.agent.calledroute.create.media.match': '被叫配置的系统接入码同被叫配置设备的媒体类型不一致.',
  'isales.agent.calledroute.recordexist': '该接入码与扩展码已绑定，请输入新的扩展码',
  'isales.agent.calledroute.nmsdata.error': '同步ODFS失败，ODFS被叫配置数据与CCM本地数据不一致',
  'isales.agent.calledroute.ccivrdata.error': '同步ODFS失败，CC-IVR流程数据有误',
  'isales.agent.calledroute.nmsconnect.fail': '连接ODFS失败',
  'isales.management.placeholder.accesscodeDesc': '请输入接入码描述',
  'isales.taskinfo.title.accesscode': '接入码',
  'isales.taskinfo.title.accesscodedesc': '接入码描述',
  'isales.agent.contact.voiceVidio': '音视频',
  'isales.agent.contact.clicktodial': '点击通话',
  'isales.note.config.success': '成功',
  'isales.agent.calledroute.refreshsuccess': '刷新成功',
  'isales.agent.calledroute.updatecalledroute': '编辑',
  'isales.agent.calledroute.confdelete': '删除确认',
  'isales.agent.calledroute.isconfdelete': '是否确认删除？',
  'isales.agent.calledroute.success': '成功',
  'isales.agent.calledroute.delsuccess': '删除成功',
  'isales.agent.message.redislocknull': '获取Redis锁失败',
  'isales.calledroute.message.selectcalledroute': '请选择被叫配置',
  'isales.agent.calledroute.delfail': '本地数据删除失败',
  'isales.manual.task.survey.questiontotal': '共{0}道题',
  'isales.manual.task.survey.unanswered': '未答',
  'isales.manual.task.survey.answered': '已答',
  'isales.manual.task.survey.currentprogress': '当前进度',
  'isales.manual.task.survey.previousquestion': '上一题',
  'isales.manual.task.survey.nextquestion': '下一题',
  'isales.manual.task.survey.submit': '提交',
  'isales.management.message.black.download': '下载黑名单模板',
  'isales.management.title.black.import': '导入黑名单',
  'isales.black.filed.number': '号码（必填）',
  'isales.black.filed.expTime': '有效时长（非必填，单位小时）',
  'isales.black.filed.remark': '备注（非必填）',
  'isales.manual.task.survey.noAnswer': '无需回答',
  'isales.manual.task.survey.answerSuccess': '答卷成功！',
  'isales.label.appraise.call.quality.agent': '座席',
  'isales.label.appraise.call.quality.customer': '客户',
  'isales.label.appraise.call.quality.customerInfo': '客户信息',
  'isales.data.message.empty': '被叫号码不能为空！',
  'isales.export.message.noSelectData': '请选择需要导出的数据',
  'isales.export.message.noSelectTrainingData': '请选择需要训练的数据',
  'isales.export.message.selectToomach': '最多选择十条数据',
  'isales.training.scoreDetails': '得分详情',
  'isales.training.startTraining': '开始实训',
  'isales.training.queryQuestionnaire': '答卷查询',
  'isales.training.answeringRecord': '作答记录',
  'isales.training.Questionnaire': '实训问卷',
  'isales.training.MaxAnswerNumber': '问卷作答最大次数',
  'isales.training.forwardNumber': '转接号码',
  'isales.training.AssociatedSurvey': '关联问卷',
  'isales.training.resultAnalysis': '结果分析',
  'isales.training.surveyDetail': '问卷详情',
  'isales.training.questionNumber': '问题数量',
  'isales.training.surveyScore': '试题总分',
  'isales.training.callTime': '呼叫时间段',
  'isales.training.callTimeSegment': '呼叫时段',
  'isales.training.specialday.callTime': '特殊日期呼叫时段',
  'isales.taskstatistics.field.feedBackStatistics': '反馈统计',
  'isales.task.message.feedBackRate': '反馈率',
  'isales.task.message.numberEffFeedback': '有效反馈呼叫数',
  'isales.task.message.numberInvalFeedback': '无效反馈呼叫数',
  'isales.training.startTraining.exists': '实训中，请实训完成后再试',
  'isales.survey.details': '答卷详情',
  'isales.survey.createTrainingTask': '新建实训任务',
  'isales.training.taskdetails': '任务详情',
  'isales.training.sampleTotal': '样本总量',
  'isales.training.completeSamples': '已完成样本量',
  'isales.training.remainingSamples': '剩余量',
  'isales.training.trainingRate': '实训率',
  'isales.training.surveydetails': '答卷统计详情',
  'isales.training.averageScore': '平均得分',
  'isales.training.voiceAverageScore': '语音平均得分',
  'isales.training.questionScoreDetails': '问题得分明细',
  'isales.training.answerNumber': '答题次数',
  'isales.training.fullScoreNumber': '满分次数',
  'isales.training.averageDuration': '平均耗时',
  'isales.training.tip1': '作答次数已达上限',
  'isales.training.tip2': '试题正在训练中，请勿频繁点击。',
  'isales.warning.data.export': '您导出的数据包含个人信息,请谨慎操作!',
  'isales.statistics.business.taskId': '任务标识',
  'isales.statistics.business.reportType': '报表类型',
  'isales.statistics.business.beginDate': '开始日期',
  'isales.statistics.business.endDate': '结束日期',
  'isales.statistics.business.startYear': '开始年份',
  'isales.statistics.business.startWeek': '开始周数',
  'isales.statistics.business.startMonth': '开始月份',
  'isales.statistics.business.endYear': '结束年份',
  'isales.statistics.business.endWeek': '结束周数',
  'isales.statistics.business.endMonth': '结束月份',
  'isales.statistics.business.search': '查询',
  'isales.statistics.business.reset': '重置',
  'isales.statistics.business.date': '日期',
  'isales.statistics.business.week': '周数',
  'isales.statistics.business.month': '月份',
  'isales.statistics.business.byDay': '日报',
  'isales.statistics.business.byWeek': '周报',
  'isales.statistics.business.byMonth': '月报',
  'isales.statistics.business.title': '外呼业务报表',
  'isales.statistics.business.trend.title': '外呼业务趋势报表',
  'isales.statistics.business.nomorethanerror': '选择的日期不得早于 {0}',
  'isales.statistics.business.currentDateCheck': '选择的日期必须小于当天',
  'isales.statistics.business.dategt31days': '查询天数不能超过31天',
  'isales.statistics.business.dategt12weeks': '周范围不能超过12周',
  'isales.statistics.business.dategt6month': '月份范围不能超过6个月',
  'isales.statistics.business.weekLessThan': '结束年份周数不能小于开始年份周数',
  'isales.statistics.business.monthLessThan': '结束年份月份不能小于开始年份月份',
  'isales.statistics.business.yearLessThan': '结束年份不能小于开始年份',
  'isales.statistics.business.noData': '无数据',
  'isales.statistics.business.noMoreThanEight': '选择数量不能超过8个',
  'isales.statistics.business.noMoreThanFive': '选择数量不能超过5个',
  'isales.statistics.business.busiResult': '业务结果',
  'isales.statistics.business.subBusiResult': '子业务结果',
  'isales.statistics.business.filteringIndicator': '筛选指标',
  'isales.statistics.business.indicatorName': '指标名称',
  'isales.statistics.business.description': '描述',
  'isales.statistics.business.cancel': '取消',
  'isales.statistics.business.confirm': '确认',
  'isales.statistics.business.saveError': '错误',
  'isales.statistics.business.errorInfo': '保存失败，至少选择一个指标',
  'isales.statistics.business.error': '错误',
  'isales.statistics.business.warningFilterMax': '最多选择{0}个指标！',
  'isales.agent.message.description.limit': '技能队列描述的长度不能超过50',
  'isales.agent.message.updateCtiAndDbFail': '刷新失败',
  'isales.operlog.message.exportSurveyData': '导出实训试题',
  'isales.language.label.language': '请选择语言',
  'isales.language.label.zh_CN': '中文',
  'isales.language.label.en_US': '英文',
  'isales.language.label.es_ES': '西班牙语',
  'isales.taskmanagement.title.batchOpt': '批量操作',
  'isales.audio.title.beginTime': '录制开始时间',
  'isales.audio.title.endTime': '录制结束时间',
  'isales.audio.title.asrTransResult': '语音转写结果',
  'isales.audio.title.confirmedResult': '回铃音识别结果',
  'isales.audio.title.confirmStatus': '业务结果',
  'isales.taskstatus.field.confirming': '待确认',
  'isales.taskstatus.field.confirmed': '已确认',
  'isales.rbtresult.field.other': '其他',
  'isales.rbtresult.field.emptynumber': '空号',
  'isales.rbtresult.field.cusclose': '被叫用户关机',
  'isales.rbtresult.field.interbusy': '网络忙',
  'isales.rbtresult.field.callershutdown': '主叫欠费停机/单向停机',
  'isales.rbtresult.field.calledshutdown': '被叫停机',
  'isales.rbtresult.field.calledbusy': '被叫忙，被叫用户登记了呼叫等待功能',
  'isales.rbtresult.field.calledbusycalling': '被叫忙，被叫用户未登记呼叫等待功能',
  'isales.rbtresult.field.calledoutarea': '被叫不在服务区',
  'isales.rbtresult.field.transfer': '呼叫转移',
  'isales.rbtresult.field.tollcaller': '国际长途限制',
  'isales.rbtresult.field.longtimemissed': '长时间未接',
  'isales.rbtresult.field.calledrestriction': '被叫用户设置了呼入限制',
  'isales.rbtresult.field.linefaulty': '其他运营商方向线路故障',
  'isales.rbtresult.field.calledunconnected': '被叫不通，开通了来电提醒',
  'isales.rbtresult.emptyparam.message': '起始结束时间不能为空',
  'isales.asrtransresult.field.undefine': '未定义',
  'isales.taskmanagement.message.crossmonth': '开始时间和结束时间不能跨月',
  'isales.taskinfo.url.not.whitelist': '地址不在白名单内',
  'isales.whitepopuppageurl.protocol.check': '使用不安全协议，存在安全风险',
  'isales.import.message.ruleExtProp': '已开启自定义属性，请先配置导入映射规则，再点击按规则导入',
  'isales.datasourcemanagement.field.load': '加载',
  'isales.datasourcemanagement.message.loadSuccess': '加载数据源成功',
  'isales.datasourcemanagement.message.loadfail': '加载数据源失败',
  'isales.datasourcemanagement.message.confirLoad': '加载数据源配置为高风险操作，请谨慎操作.',
  'isales.management.message.SelectNumberMax': '一次最多选择100个主叫号码',
  'isales.task.promotion.whatsApp': 'WhatsApp',
  'isales.task.promotion.whatsAppChannel': 'WhatsApp渠道',
  'isales.task.promotion.whatsApp.message.template': '模板消息',
  'isales.task.promotion.whatsApp.message.template.select': '请选择一个WhatsApp模板消息',
  'isales.task.promotion.whatsApp.channel.select': '请先选择一个whatsapp渠道',
  'isales.task.promotion.whatsApp.message.id': '模板消息ID',
  'isales.task.promotion.whatsApp.message.name': '模板消息名称',
  'isales.task.promotion.whatsApp.variable.description': '参数描述',
  'isales.task.promotion.whatsApp.variable.content': '参数内容',
  'isales.basicinfo.title.addCallNo': '新增主叫号码',
  'isales.taskmanagement.title.deleteFailExitOne': '外呼任务至少配置一个主叫号码',
  'isales.failInterval.check': '间隔时间小于10s时可能存在风险',
  'isales.taskmanagement.title.agentStatistics': '座席工作统计',
  'isales.work.message.daterequest': '起始结束时间必须同时传递',
  'isales.work.title.selectAgent': '选择座席',
  'isales.work.title.agent.workno': '座席工号',
  'isales.message.promptForAgent': '请输入座席工号',
  'isales.button.search': '查询',
  'isales.button.reset': '重置',
  'isales.title.agent.workno': '座席工号',
  'isales.label.agent.accountcode': '业务帐号',
  'isales.agent.work.statistics.AgentName': '座席姓名',
  'isales.button.cancel': '取消',
  'isales.button.confirm': '确认',
  'isales.task.status.running': '进行中',
  'isales.task.status.pause': '暂停',
  'isales.task.finished': '已完成',
  'isales.task.notFinished': '未完成',
  'isales.task.info': '任务信息',
  'isales.task.benginEndTime': '起止时间',
  'isales.task.detail': '外呼任务详情',
  'isales.taskmanagement.startTask': '开启任务',
  'isales.taskmanagement.title.taskDataVolume': '任务数据量',
  'isales.taskmanagement.title.callCompletionRate': '成功率',
  'isales.taskInfo.title.taskTemplate': '任务模板',
  'isales.taskmanagement.title.taskTime': '任务起止时间',
  'isales.taskmanagement.title.setAgent': '分配座席',
  'isales.taskInfo.title.quotas': '结果达成量',
  'isales.taskInfo.title.quotasTips': '当座席标记为上述业务结果的呼叫超过此处配置的达成量时，将不再进行自动提取。',
  'isales.taskInfo.title.callCompletedNum': '完成呼叫数',
  'isales.taskInfo.title.callCompletedNumTips': '当座席完成的呼叫超过此处配置的完成呼叫数时，将不再进行自动提取。',
  'isales.taskInfo.title.pickUpNum': '坐席单次可领数据量',
  'isales.taskInfo.title.pickUpCondition': '数据提取条件',
  'isales.step.taskinfo': '任务信息',
  'isales.step.callpolicy': '外呼策略',
  'isales.step.outboundcalldata': '任务数据',
  'isales.calltime.title.default.datetime': '常规日期时间段',
  'isales.calltime.title.spec.date': '特殊日期',
  'isales.calltime.title.add.date': '添加特殊日期',
  'isales.calltime.title.add.time.period': '添加时段',
  'isales.calltime.title.holidayName': '特殊日期名称',
  'isales.calltime.title.holidayType': '类型',
  'isales.calltime.title.holiday': '特殊时间',
  'isales.calltime.title.spec.datetime.period': '特殊日期时间段',
  'isales.calltime.task.template': '任务模板',
  'isales.calltime.task.template.select.placeholder': '可选择从模板创建外呼任务',
  'isales.calltime.task.callerno.select.placeholder': '请选择主叫号码',
  'isales.calltime.task.begin.end.date': '任务起止时间',
  'isales.calldata.prop.filter': '外呼数据属性筛选',
  'isales.taskinfo.header.policy': '任务策略',
  'isales.taskinfo.call.header.policy': '呼叫策略',
  'isales.taskmanagement.title.sysAuto': '系统自动外呼',
  'isales.work.message.dateRangeRequest': '请选择查询时间范围',
  'isales.work.message.taskNameLengthLimit': '任务名称长度不能超过50',
  'isales.work.message.workNoLengthLimit': '坐席工号长度不能超过5',
  'isales.work.message.workNoTypeLimit': '必须为0或正整数',
  'isales.taskInfo.title.filterCondition': '筛选条件',
  'isales.appointment.title.appointState': '预约状态',
  'isales.taskinfo.field.agentType4': '音频',
  'isales.taskinfo.field.agentType11': '视频',
  'isales.taskinfo.field.agentType5': '多媒体',
  'isales.taskinfo.field.agentType99': '全能型',
  'isales.task.field.appointState1': '已预约',
  'isales.task.field.appointState2': '准备回呼',
  'isales.task.field.appointState3': '可回呼',
  'isales.task.field.appointState4': '已完成',
  'isales.label.agent.agentType': '座席类型',
  'isales.label.agent.role': '角色',
  'isales.calleeNo.length.max32': '客户号码最大长度为32',
  'isales.label.calldata.order': '呼叫顺序',
  'isales.tasklogic.field.callOrderMethod.byData': '按数据属性',
  'isales.tasklogic.field.callOrderMethod.orderType': '排序方式',
  'isales.label.multiple.callno.round': '多号码客户呼叫轮次',
  'isales.task.result.export.query': '前往查看',
  'isales.task.result.export.skip': '跳过',
  'isales.task.result.export.message': '已成功创建导出任务，是否前往导出列表进行查看？',
  'isales.rbtrecognition.remark.length': '字符串长度超出取值范围，长度必须小于等于200',
  'isales.outboundBusiness.statistic.di': '第',
  'isales.outboundBusiness.statistic.week': '周',
  'isales.task.result.callrecord.conversation': '对话记录',
  'isales.taskinfo.title.adjustAgent': '调整座席',
  'isales.taskinfo.check.empty': '参数不能为空',
  'isales.tasktype.field.robot.create': '新建机器人外呼',
  'isales.tasktype.field.auto.info': '普通IVR',
  'isales.tasktype.field.intelligent.info': '智能机器人',
  'isales.taskmanagement.title.batchEditTaskInfo.errTaskType': '不同的任务类型不可以批量修改',
  'isales.taskmanagement.title.pauseTask.askConfirm': '是否确认暂停外呼任务？若强制暂停，可能会影响正在进行中的任务通话。',
  'isales.tasktype.field.auto.robot.create': '新建普通IVR',
  'isales.tasktype.field.intelligent.robot.create': '新建智能机器人',
  'isales.management.title.callbot.auto': '配置按键外呼机器人',
  'isales.taskmanagement.title.importData.template': '模板',
  'isales.taskmanagement.title.importData.upload': '上传文件',
  'isales.taskmanagement.title.importData.selectFile': '选择文件',
  'isales.taskmanagement.pauseTask': '暂停任务',
  'isales.taskInfo.spec.date.title.quotasTips': '该配置表示需要外呼的特殊日期和时段。',
  'isales.calltime.title.spec.date.empty.warn': '特殊日期未配置！',
  'isales.tasklogic.message.empty.orderNo': '存在为空的号码序号，请重新输入',
  'isales.taskmanagement.exportResult': '导出任务结果',
  'isales.manual.field.callingRecord': '外呼记录',
  'isales.rbtrecognition.remark.tip': '不能包含的特殊字符:',
  'isales.business.callInterval.message.must': '间隔时间必填',
  'isales.call.count': '呼叫次数',
  'isales.template.title.addManual': '手动外呼模板',
  'isales.taskmanagement.title.sysAuto.template': '系统自动外呼模板',
  'isales.taskmanagement.title.sysAuto.templateAdd': '新建系统自动外呼模板',
  'isales.tasktype.field.robot.template': '机器人外呼模板',
  'isales.tasktype.field.robot.templateAdd': '新建机器人外呼模板',
  'isales.manual.exit.preview': '执行预览外呼',
  'isales.manual.exit.preview.tips': '若预览超时或关闭窗口，系统将自动为您外呼',
  'isales.task.promotion.createBeginTime': '创建开始时间',
  'isales.task.promotion.createEndTime': '创建结束时间',
  'isales.task.promotion.timeerror': '生效时间必须小于失效时间',
  'isales.busiresult.description.length': '字符串长度超出取值范围，长度必须小于等于500',
  'isales.import.coverMode.tips': '覆盖导入会删除所有待呼叫数据，执行时间较长，请谨慎选择',
  'isales.taskmanagement.title.taskContent': '任务内容',
  'isales.taskmanagement.title.saveAndImportData': '保存并导入数据',
  'isales.data.title.undoEdit': '撤销编辑',
  'isales.task.promotion.numberOnly': '只能是非负整数',
  'isales.data.lock': '当前存在外呼数据未处理完成，如正在进行外呼数据导入、新增、删除，请稍后再试',
  'isales.survey.type': '问卷类型',
  'ccsurvey.label.scenarioType.manual': '人工问卷',
  'ccsurvey.label.scenarioType.manualIvr': '人工与IVR问卷',
  'isales.tasktype.field.manualIvr.info': '播放问卷流程',
  'ccsurvey.info.tip36': '此题必填',
  'ccsurvey.transfer.tip36': '挂起转IVR播放问卷',
  'isales.taskinfo.message.callintervalrangeMax': '请输入1-86400之间的正整数。',
  'isales.notification.receiver.whatsapp.placeholder': '请输入带国际区号的接收者号码',
  'isales.data.title.more': '更多',
  'isales.result.search.displayResultOrCode': '请输入外呼结果码或外呼结果描述',
  'isales.management.message.dbIp': 'IP格式不正确',
  'isales.agent.message.modifyPwdVdnFailed': '不允许修改配置管理员密码！',
  'isales.business.result.message.checkCodePoint': '只能输入数字、字母、-、.',
  'isales.import.calldata.selecttime': '请选择执行时间',
  'isales.statistics.message.chooseCondition': '请先选择查询条件',
  'isales.taskpolicy.field.time': '（秒）',
  'isales.taskInfo.title.filterSubCondition': '筛选子条件',
  'isales.agent.work.statistics.tableTips': '请先选择您要查看的外呼任务或座席',
  'isales.file.message.rule.add.success': '新建规则成功',
  'isales.file.title.server.add.success': '新建服务器信息成功',
  'isales.management.title.selectResult': '选择外呼结果',
  'isales.specialday.placeholder.year.beginTime': '格式为：MM-DD，例如：12-01',
  'isales.management.message.appointTime': '预约呼叫时间不能早于当前系统时间',
  'isales.management.message.intelligence.taskName': '智能机器人外呼类型的任务名称长度不能超过36字节，1个英文或数字占1个字节，1个中文占3个字节',
}
