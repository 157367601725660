export default {
  'ccivr.ivrvoice.play.error': 'Loading resource exception, please check related configuration.',
  'ccivr.ivrvoice.play.voicetimelimit': 'The audition audio is long, Reduce the text content and try again.',
  'ccivr.ivr.ivrflow.name': 'Name',
  'ccivr.ivr.ivrflow.type': 'Type',
  'ccivr.ivr.ivrflow.updateTime': 'Update Time',
  'ccivr.ivr.ivrflow.state': 'Status',
  'ccivr.ivr.ivrflow.currentHandler': 'Handler',
  'ccivr.ivr.ivrflow.operate': 'Operation',
  'ccivr.ivr.ivrflow.operate.edit': 'Edit',
  'ccivr.ivr.ivrflow.operate.delete': 'Delete',
  'ccivr.ivr.ivrflow.operate.release': 'Release',
  'ccivr.ivr.ivrflow.operate.ensurerelease': 'Confirm Release',
  'ccivr.ivr.ivrflow.operate.ensurerelease.info': 'Are you sure you want to release the flow?',
  'ccivr.ivr.ivrflow.operate.release.success': 'Release success.',
  'ccivr.ivr.ivrflow.operate.release.fail': 'Release failure.',
  'ccivr.ivr.ivrflow.operate.release.needexecptionhanding': 'Please configure and officially release an exception handling process.',
  'ccivr.ivr.ivrflow.operate.release.cancelexecptionhanding': 'Please cancel the release status of other processes under the tenant.',
  'ccivr.ivr.ivrflow.operate.cancelrelease': 'Cancel Release',
  'ccivr.ivr.ivrflow.operate.queryInfo': 'View',
  'ccivr.ivr.flowAccessCode.repeat': 'The access code already exists!',
  'ccivr.ivr.ivrflow.operate.ensurecancelrelease': 'Confirm Release Cancellation',
  'ccivr.ivr.ivrflow.operate.ensurecancelrelease.info': 'Are you sure you want to cancel the release?',
  'ccivr.ivr.ivrflow.operate.cancelrelease.success': 'Canceled successfully.',
  'ccivr.ivr.ivrflow.operate.cancelrelease.fail': 'Failed to cancel the release.',
  'ccivr.ivrflow.operate.replace': 'Replace',
  'ccivr.ivrflow.operate.ensurereplace': 'Confirm Replace',
  'ccivr.ivrflow.operate.ensurereplace.info': 'Are you sure you want to replace the released flow?',
  'ccivr.ivrflow.operate.replace.success': 'Replaced successfully.',
  'ccivr.ivrflow.operate.replace.fail': 'Replace failed.',
  'ccivr.ivrflow.cancelrelease.referenced': 'The flow has been referenced by an access code.',
  'ccivr.ivrflow.cancelrelease.referenced.by.idauth.survey': 'The process has been referenced by an authentication configuration and cannot be unpublished!',
  'ccivr.ivrflow.cancelrelease.referenced.by.satisfaction.survey': 'The flow has been referenced by the satisfaction survey configuration and cannot be deleted!',
  'ccivr.ivr.error.releaseFailed': 'Failed to publish the script automatically',
  'ccivr.ivrflow.cancelrelease.error': 'The flow is referenced by a skill queue!',
  'ccivr.ivrflow.flowAccessCode': 'FlowAccessCode',
  'ccivr.ivrflow.unlock': 'Unlock',
  'ccivr.ivrflow.unlock.confirm': 'Confirm unlock status in edit?',
  'ccivr.ivrflow.unlock.success': 'Unlocked status successful',
  'ccivr.ivrflow.unlock.fail': 'Unlocked status failed',
  'ccivr.ivr.ivrflow.operate.ensureclone': 'Confirm Flow Upgrading',
  'ccivr.ivr.ivrflow.operate.ensureclone.info': 'Are you sure you want to upgrade the flow?',
  'ccivr.ivr.ivrflow.operate.clone.success': 'The upgrade process is successful.',
  'ccivr.ivr.ivrflow.operate.clone.fail': 'Failed to upgrade the flow.',
  'ccivr.ivr.ivrflow.ivrname': 'Name',
  'ccivr.ivr.ivrusage.ivrname': 'Reference Scenario',
  'ccivr.ivr.ivrusage.skillName': 'Skill Queue Name',
  'ccivr.ivr.ivrflow.placeholder.ivrname': 'Enter a flow name.',
  'ccivr.ivr.ivrflow.ivrtype': 'Type',
  'ccivr.ivr.ivrflow.mainflow': 'Main Flow',
  'ccivr.ivr.ivrflow.subflow': 'Subflow',
  'ccivr.ivr.ivrflow.exceptionHandingFlow': 'Exception Handling Flow',
  'ccivr.ivr.ivrflow.new': 'New',
  'ccivr.ivr.ivrflow.edit': 'Editing',
  'ccivr.ivr.ivrflow.unreleased': 'Unreleased',
  'ccivr.ivr.ivrflow.alreadyreleased': 'Official Release',
  'ccivr.ivr.ivrflow.flowquote': 'Is Referenced',
  'ccivr.ivr.ivrflow.yes': 'Yes',
  'ccivr.ivr.ivrflow.no': 'No',
  'ccivr.ivr.ivrflow.selectivrflow': 'Select an IVR flow.',
  'ccivr.ivr.ivrflow.batchdeleteconfim': 'Are you sure you want to delete all selected IVR flows? Ensure that the IVR flow is not released.',
  'ccivr.ivr.ivrflow.deleteconfim': 'Are you sure you want to delete the IVR flow?',
  'ccivr.ivrflow.error.deleteivrflow.released': 'The selected IVR flow has been released. Cancel the release before deleting it.',
  'ccivr.ivrflow.error.deleteivrflow.part.released': 'Some of the selected IVR flows have been released. Cancel the release before deleting them.',
  'ccivr.ivrflow.error.deleteivrflow.association': 'The flow is associated with another flow and cannot be deleted.',
  'ccivr.ivrflow.error.deleteivrflow.fail': 'The flow does not exist.',
  'ccivr.ivrflow.createivrflow.mainfail': 'The main flow already exists. Adding failed.',
  'ccivr.ivrflow.createivrflow.exceptionfail': 'The exception handling flow already exists. Adding failed!',
  'ccivr.ivrflow.createivrflow.sameivrflowname': 'The flow name already exists!',
  'ccivr.ivrflow.createivrflow.fail': 'Adding failed.',
  'ccivr.ivr.ivrflow.filter': 'Filter',
  'ccivr.ivr.ivrflow.filtercondition': 'Filter Criteria',
  'ccivr.ivrflow.updateivrinfo.success': 'Modified the attributes of the IVR flow successfully.',
  'ccivr.ivrflow.updateivrinfo.fail': 'Failed to modify IVR flow attributes.',
  'ccivr.ivrflow.updateivrinfo.editing': 'The flow is being edited and cannot be modified.',
  'ccivr.ivrflow.updateivrinfo.released': 'The flow has been released. Cancel the release and modify the attributes.',
  'ccivr.ivr.ivrflow.version': 'Version Number',
  'ccivr.ivr.ivrflow.property': 'Attribute',
  'ccivr.ivr.ivrflow.clone': 'Upgrade',
  'ccivr.ivrflow.ivrflowname.specialStrValidateFailed': 'The value cannot contain special characters and the length cannot exceed 50 characters.',
  'ccivr.ivrflow.ivrflowname.specialStrValidateFailed128': 'The value cannot contain special characters and the length cannot exceed 128 characters.',
  'ccivr.ivr.ivrflow.operate.create.accesscode.fail': 'Failed to add the access code in ODFS while releasing the IVR flow.',
  'ccivr.ivrflow.cancelrelease.accesscode.delete.failed': 'Failed to delete the access code in ODFS while canceling the released IVR flow.',
  'ccivr.ivrflow.ivrflowtype.validateFailed': 'Incorrect flow type.',
  'ccivr.ivrflow.release.grayrule': 'Gray Release',
  'ccivr.ivrflow.release.formal': 'Official Release',
  'ccivr.ivrflow.grayrule.rule': 'Gray Rule',
  'ccivr.ivrflow.grayrule.grayinfo': 'If a number matches a gray rule, the flow of this version is executed.<br /> For numbers that do not match gray rules, the officially released flow is executed.<br /> If there is no officially released flow, the execution fails.',
  'ccivr.ivrflow.release.formalinfo': 'After the release, the flow of this version is executed for all numbers. <br />If you need to modify the flow, you need to upgrade a new version and modify the version.',
  'ccivr.ivrflow.grayrule.rulename': 'Rule Name',
  'ccivr.ivrflow.grayrule.numrange': 'Number Segment',
  'ccivr.ivrflow.grayrule.bindingflow': 'Bound flow:',
  'ccivr.ivrflow.grayrule.name': 'Rule Name',
  'ccivr.ivrflow.grayrule.beginnum': 'Start Number',
  'ccivr.ivrflow.grayrule.endnum': 'End Number',
  'ccivr.ivrflow.grayrule.inputrulename': 'Enter the rule name.',
  'ccivr.ivrflow.grayrule.inputbeginnum': 'Example: 12366668888',
  'ccivr.ivrflow.grayrule.inputendnum': 'The end number must be greater than or equal to the start number.',
  'ccivr.ivrflow.grayrule.inputendlength': 'The length of the start number must be the same as that of the end number.',
  'ccivr.ivrflow.grayrule.selectgrayrule': 'Select a gray rule.',
  'ccivr.ivrflow.grayrule.existgrayflow': 'The gray release version already exists!',
  'ccivr.ivrflow.grayrule.editsuccess': 'Modified successfully.',
  'ccivr.ivrflow.grayrule.info': 'Details',
  'ccivr.ivrflow.variable.settings': 'Variable Settings',
  'ccivr.ivrflow.variable.selectvariable': 'Please select a variable parameter.',
  'ccivr.ivrflow.variable.typeerror': 'Inconsistent input variable types.',
  'ccivr.ivr.voicereview.choosetenantspace': 'Select a tenant.',
  'ccivr.ivr.voicereview.choosevoicetype': 'Type',
  'ccivr.ivr.voicereview.waitreview': 'To Review',
  'ccivr.ivr.voicereview.search': 'Search',
  'ccivr.ivr.voicereview.reset': 'Reset',
  'ccivr.ivr.voicereview.refresh': 'Refresh',
  'ccivr.ivr.voicereview.tenantspace': 'Home Tenant',
  'ccivr.ivr.voicereview.name': 'Name',
  'ccivr.ivr.voicereview.filename': 'File Name',
  'ccivr.ivr.voicereview.filetype': 'Type',
  'ccivr.ivr.voicereview.language': 'Language',
  'ccivr.ivr.voicereview.scene': 'Usage Scenario',
  'ccivr.ivr.voicereview.uploadtime': 'Upload Time',
  'ccivr.ivr.voicereview.upload': 'Uploaded',
  'ccivr.ivr.voicereview.reviewstatus': 'Status',
  'ccivr.ivr.voicereview.reviewsuggestion': 'Comments',
  'ccivr.ivr.voicereview.voiceId': 'Voice Id',
  'ccivr.ivr.voicereview.operat': 'Operation',
  'ccivr.ivr.voicereview.play': 'Play',
  'ccivr.ivr.voicereview.pass': 'Approve',
  'ccivr.ivr.voicereview.filter': 'Filter',
  'ccivr.ivr.voicereview.refuse': 'Reject',
  'ccivr.ivr.voicereview.voice': 'Voice',
  'ccivr.ivr.voicereview.text': 'Text',
  'ccivr.ivr.voicereview.edit': 'Edit',
  'ccivr.ivr.voicereview.nosubmit': 'Not submitted',
  'ccivr.ivr.voicereview.passed': 'Approved',
  'ccivr.ivr.voicereview.notpass': 'Rejected',
  'ccivr.ivr.voicereview.all': 'All',
  'ccivr.ivr.voicereview.vedio': 'Video',
  'ccivr.ivr.voicereview.filePath': 'File path:',
  'ccivr.ivr.voicereview.textContent': 'Text Content',
  'ccivr.ivr.voicereview.chinese': 'Chinese',
  'ccivr.ivr.voicereview.english': 'English',
  'ccivr.ivr.voicereview.thailand': 'Thailand',
  'ccivr.ivr.voicereview.spanish': 'Spanish',
  'ccivr.ivr.voicereview.portuguese': 'Portuguese',
  'ccivr.ivr.voicereview.french': 'French',
  'ccivr.ivr.voicereview.arabic': 'Arabic',
  'ccivr.ivr.voicereview.german': 'German',
  'ccivr.ivr.voicereview.language0': 'Chinese',
  'ccivr.ivr.voicereview.language1': 'English',
  'ccivr.ivr.voicereview.language24': 'Thailand',
  'ccivr.ivr.voicereview.language17': 'Portuguese',
  'ccivr.ivr.voicereview.language20': 'Spanish',
  'ccivr.ivr.voicereview.language7': 'French',
  'ccivr.ivr.voicereview.language81': 'Arabic',
  'ccivr.ivr.voicereview.language8': 'German',
  'ccivr.ivr.voiceadvice.close': 'Close',
  'ccivr.ivr.voiceadvice.audition': 'Audition',
  'ccivr.ivr.voiceadvice.cancel': 'Cancel',
  'ccivr.ivr.voiceadvice.confirm': 'OK',
  'ccivr.ivr.voiceadvice.currentdefault': 'Default',
  'ccivr.ivr.voiceadvice.setdefault': 'Set As Default',
  'ccivr.ivr.voiceadvice.submit': 'Submit',
  'ccivr.ivr.voiceadvice.lengthError': 'The length cannot exceed 100 characters.',
  'ccivr.ivr.variable.int': 'Integer',
  'ccivr.ivr.variable.string': 'String',
  'ccivr.ivr.variable.name': 'Variable',
  'ccivr.ivr.variable.defaultValue': 'Default Value',
  'ccivr.ivr.variable.management': 'Variable Management',
  'ccivr.ivr.variable.addvariable': 'Add Variable',
  'ccivr.ivr.variable.modifyvariable': 'Modify Variable',
  'ccivr.ivr.variable.idisnull': 'The ID is empty.',
  'ccivr.ivr.variable.nameisnull': 'The name is empty.',
  'ccivr.ivr.variable.typeisnull': 'The type is empty.',
  'ccivr.ivr.variable.variableisreferenced': 'Variables are referenced by flow.',
  'ccivr.ivr.variable.deleteselectvariable': 'Delete Variable',
  'ccivr.ivr.variable.willdeleteselectvariable': 'Are you sure you want to delete the selected variables?',
  'ccivr.ivr.variable.descvalueerror': 'The description contains special characters. Please modify it.',
  'ccivr.ivr.variable.defaultvalueerror': 'The default value contains special characters. Please modify it.',
  'ccivr.ivr.variable.errorspecial': 'The type does not exist.',
  'ccivr.ivr.variable.desclimit': 'The description cannot contain more than 120 characters.',
  'ccivr.ivr.variable.defaultlimit': 'The default value cannot contain more than 120 characters.',
  'ccivr.ivr.variable.defaulttypeerror': 'The default value type does not match the variable type.',
  'ccivr.ivr.variable.textvaluelimit': 'The text cannot contain more than 120 characters.',
  'ccivr.ivr.variable.namevaluerule': 'The name contains special characters. Please modify it.',
  'ccivr.ivr.element.namecheck': 'The name contains special characters. Please modify it.',
  'ccivr.ivr.variable.specialcharacters': 'Special or Chinese characters are not allowed.',
  'ccivr.ivr.voiceupload.defaultNoDe': 'The default language cannot be deleted.',
  'ccivr.ivr.voiceupload.onlyOneData': 'Keep at least one language record.',
  'ccivr.ivr.voiceupload.texttovoice': 'The entered text is automatically converted into a voice.',
  'ccivr.ivr.voiceupload.selectvoice': 'Select a voice file.',
  'ccivr.ivr.voiceupload.selectvideo': 'Select a video file.',
  'ccivr.ivr.voiceupload.recording': 'record',
  'ccivr.ivr.voiceupload.stoprecording': 'stop',
  'ccivr.ivr.voiceupload.playrecord': 'play',
  'ccivr.ivr.voiceupload.playtype': 'Voice Playing Mode',
  'ccivr.ivr.voiceupload.languagemanage': 'Language Management',
  'ccivr.ivr.voiceupload.new': 'New',
  'ccivr.ivr.voiceupload.delete': 'Delete',
  'ccivr.ivr.voiceupload.deletefail': 'Deletion failed.',
  'ccivr.ivr.voiceupload.deleteodfsivrfail': 'Deletion failed. The ivr is occupied',
  'ccivr.ivr.voiceupload.deletereferenceivrfail': 'Deleting the voice failed. The voice has been referenced.',
  'ccivr.ivr.voiceupload.submit': 'Submit',
  'ccivr.ivr.voiceupload.chinese': 'Chinese',
  'ccivr.ivr.voiceupload.english': 'English',
  'ccivr.ivr.voiceupload.pleaseuploadfile': 'The file is empty. Please import the file.',
  'ccivr.ivr.voiceupload.pleaserecording': 'Upload the file after recording.',
  'ccivr.ivr.voiceupload.formatwrong': 'Incorrect file format. Import a file in WAV or MP3 format.',
  'ccivr.ivr.voiceupload.vedioformatwrong': 'The file format is incorrect. Import a 3GP file.',
  'ccivr.ivr.voiceupload.fileheaderwrong': 'The original voice file format is not WAV or MP3. Upload another voice file.',
  'ccivr.ivr.voiceupload.vedioheaderwrong': 'The original video file format is not 3gp. Upload another video file.',
  'ccivr.ivr.voiceupload.vedionamewrong': 'File upload FileNmae check failed!',
  'ccivr.ivr.voiceupload.sizewrong': 'The file size exceeds 2 MB. Import the file again.',
  'ccivr.ivr.voiceupload.isconfdelete': 'Are you sure you want to delete it?',
  'ccivr.ivr.voiceupload.tipformat': 'The MP3 and WAV formats are supported, and the file size cannot exceed 12 MB.',
  'ccivr.ivr.voiceupload.tipformat1M': 'The MP3 and WAV formats are supported, and the file size cannot exceed 1 MB.',
  'ccivr.ivr.voiceupload.amountLimit5': 'A maximum of five voice files can be added in this scenario.',
  'ccivr.ivr.voiceupload.fileformatconverterror': 'An error occurred when converting the file format, or the FFmpeg configuration is incorrect.',
  'ccivr.ivr.voiceupload.exceedLimit': 'The number of IVR files exceeds the limit.',
  'ccivr.ivr.voiceupload.tipvideoformat': 'Currently, only 3GP video files are supported. The file size cannot exceed 5 MB.',
  'ccivr.ivr.voiceupload.tipvideomodel': 'You can set voice or video files of different languages at the same time. During video playback, the system automatically plays the corresponding video file based on the language selected by the user.',
  'ccivr.ivr.voiceupload.tipmodel': 'You can set voice files or text of different languages at the same time(maximum length cannot exceed 1024 bytes). During voice playback, the system automatically plays the corresponding voice file based on the language selected by the user.',
  'ccivr.ivr.voiceupload.VoiceNotificationtipmodel': 'You can set voice files or text of different languages at the same time(maximum length cannot exceed 500 bytes). During voice playback, the system automatically plays the corresponding voice file based on the language selected by the user.',
  'ccivr.ivr.voiceupload.VoiceNotificationTextDes': 'The template content cannot start with a variable. A variable can contain a maximum of 32 bytes. If a variable contains more than 32 bytes, you are advised to split it into multiple variables.\nThe following variables are supported:\n{\'$\'}{\'{\'}TXT_Digit{\'}\'}: string with the maximum length specified by Digit\n{\'$\'}{\'{\'}NUM_Digit{\'}\'}: number with the maximum length specified by Digit (For example, {\'$\'}{\'{\'}NUM_6{\'}\'} indicates a number with a maximum of six digits.)\n{\'$\'}{\'{\'}DATE{\'}\'}: date in YYYY/MM/DD format\n{\'$\'}{\'{\'}TIME{\'}\'}: time in HH:MM or HH:MM:SS format\n{\'$\'}{DATE} and {\'$\'}{\'{\'}TIME{\'}\'} must be separated by a comma (,).',
  'ccivr.ivr.voiceupload.VoiceNotificationTextrule': 'For more voice notification template rules, move the cursor over the i symbol in the text box.',
  'ccivr.ivr.voiceupload.auditionTextlimit': 'The preview text content can contain a maximum of 300 bytes.',
  'ccivr.ivr.voiceupload.audioformat': 'Audio file format: ALAW, 8bit, 8000Hz, 64 kbps, mono.',
  'ccivr.ivr.voiceupload.audiotypefailed': 'The voice file is not in ALAW format.',
  'ccivr.ivr.voiceupload.audiochannelfailed': 'The voice file is not a mono voice file.',
  'ccivr.ivr.voiceupload.audiosampleratefailed': 'The sampling rate of the voice file is not 8000 Hz.',
  'ccivr.ivr.voiceupload.audiosamplebitfailed': 'The number of sample bits for the voice file is not 8.',
  'ccivr.ivr.voiceupload.audiofilereadfailed': 'Failed to read voice file attributes.',
  'ccivr.ivr.voiceupload.authvalidatefailed': 'File upload permission check failed.',
  'ccivr.ivr.voiceupload.fileinjectionerror': 'Check file injection risk exception.',
  'ccivr.ivr.voiceupload.fileioerror': 'File IO exception.',
  'ccivr.ivr.voiceupload.tmpformaterror': 'Wrong temporary file type.',
  'ccivr.ivr.voiceupload.checkuploadparmfailed': 'File upload param check failed.',
  'ccivr.ivr.voiceupload.checkcallcenterparamfailed': 'Callcenter param check failed.',
  'ccivr.ivr.voiceupload.uploadfiletosftpfailed': 'File upload to sftp failed.',
  'ccivr.ivr.voiceupload.opreationdbfailed': 'Update database IVR voice message failed.',
  'ccivr.ivr.voiceupload.referenceFlow': 'Reference Flow Information',
  'ccivr.ivr.voiceupload.ivrVoiceUsage': 'Reference Information',
  'ccivr.ivr.voiceupload.referenceText': 'Voice Notification Text Information',
  'ccivr.ivr.voiceupload.queryReferenceFlow': 'View',
  'ccivr.ivr.voiceupload.query': 'Detail',
  'ccivr.ivr.voiceupload.selectLangNull': 'No language is selected. Please select a language.',
  'ccivr.ivr.voiceupload.selectLangRe': 'The current language has been selected. Please select another language.',
  'ccivr.ivr.voiceedit.idisnull': 'The ID is empty.',
  'ccivr.ivr.voiceedit.deleteoldfilefailed': 'Old file delete failed.',
  'ccivr.ivr.voiceedit.statusisnull': 'the param of status is null.',
  'ccivr.ivr.voiceedit.statuserror': 'the param of status is error.',
  'ccivr.ivr.voiceedit.filenameisnull': 'File Name is null.',
  'ccivr.ivr.voiceedit.filenameformaterror': 'File Name format is wrong.',
  'ccivr.ivr.voiceedit.filenamenull': 'File Name content is null.',
  'ccivr.ivr.voiceedit.filenamecheckfail': 'File Name validate failed.',
  'ccivr.ivr.voiceedit.querycallcenterfailed': 'Querying the SFTP information of the call center failed.',
  'ccivr.ivr.voiceedit.sftpinfoisnull': 'The SFTP information of the call center is null.',
  'ccivr.ivr.voiceedit.passwdisnull': 'Querying the SFTP passwd information of the call center failed.',
  'ccivr.ivr.voiceedit.pathisnull': 'Querying the SFTP path information of the call center failed.',
  'ccivr.ivr.voiceedit.pathcheckfailed': 'The SFTP path information validate failed.',
  'ccivr.ivr.voiceedit.choosefile': 'Select File',
  'ccivr.ivr.voiceedit.cancel': 'Cancel',
  'ccivr.ivr.voiceedit.import': 'Import',
  'ccivr.ivr.voiceedit.inputmore': 'The value cannot exceed',
  'ccivr.ivr.voiceedit.addsuccess': 'Adding successfully.',
  'ccivr.ivr.voiceedit.addfail': 'Adding failed.',
  'ccivr.ivr.voiceedit.deletesuccess': 'Deleted successfully.',
  'ccivr.ivr.voiceedit.deleteodfsivrfail': 'Delete failed . The IVR data is occupied !',
  'ccivr.ivr.voiceedit.tip': 'Prompt',
  'ccivr.ivr.voiceedit.editsuccess': 'Edited successfully.',
  'ccivr.ivr.voiceedit.editfail': 'Failed to edit.',
  'ccivr.ivr.voiceedit.updatefail': 'Update failed.',
  'ccivr.ivr.voiceedit.inputtext': 'Enter text.',
  'ccivr.ivr.message.uploadimport.success': 'Adding successfully.',
  'ccivr.ivr.success.import': 'Adding successfully.',
  'ccivr.ivr.pageurls.errorsave': 'Error',
  'ccivr.ivr.description.limit': 'The text description cannot contain more than 1024 bytes.',
  'ccivr.ivr.description.voiceNotificationlimit': 'The text description cannot contain more than 500 bytes.',
  'ccivr.ivr.description.variableerror': 'the format of the template variable is incorrect.',
  'ccivr.ivr.description.variablelimit': 'template variables cannot exceed 10.',
  'ccivr.ivr.description.containspecialcharacter': 'The template contains special characters ~',
  'ccivr.ivr.voice.filechoose': 'Select a voice file',
  'ccivr.ivr.voice.vediochoose': 'Select a video file',
  'ccivr.ivr.changtovoice': 'TTS',
  'ccivr.ivr.voice.scene': 'Usage Scenario',
  'ccivr.ivr.voice.speed': 'speed',
  'ccivr.ivr.voice.volume': 'volume',
  'ccivr.ivr.voice.pitch': 'pitch',
  'ccivr.ivr.voice.voicename': 'timbre',
  'ccivr.ivr.voice.scene.note': 'tone',
  'ccivr.ivr.voice.scene.error': 'error',
  'ccivr.ivr.voice.scene.questionnaires': 'questionnaire',
  'ccivr.ivr.voice.scene.beforeReportWorkNo': 'before reporting employee ID',
  'ccivr.ivr.voice.scene.afterReportWorkNo': 'after reporting employee ID',
  'ccivr.ivr.voice.fileupload': 'Upload File',
  'ccivr.ivr.voice.recordupload': 'Online Recording',
  'ccivr.ivr.voice.clicktorecord': 'Click Record',
  'ccivr.ivr.voice.clicktostop': 'Click Stop',
  'ccivr.ivr.voice.recordtimeout.fail': 'Recording timed out.',
  'ccivr.ivr.voice.recordtimeout.failmes': 'The maximum recording duration is 2 minutes. Record again.',
  'ccivr.ivr.voice.exception.recordfail': 'Recording error.',
  'ccivr.ivr.voice.exception.recordfailmessage': 'Recording error. Check whether you have the microphone permission.',
  'ccivr.ivr.success': 'Success',
  'ccivr.ivr.fail': 'Failure',
  'ccivr.ivr.textcontent.notnull': 'Text information cannot be empty.',
  'ccivr.ivr.name.errorspecial': 'The name contains special characters. Please modify it.',
  'ccivr.ivr.name.existence': 'The name already exists. Please enter another one.',
  'ccivr.ivr.error.sftp': 'The SFTP information is incorrect.',
  'ccivr.ivr.error.download': 'Failed to download the file from the SFTP server.',
  'ccivr.ivr.error.connect': 'Failed to connect to the SFTP server.',
  'ccivr.ivr.error.other': 'Download failed.',
  'ccivr.ivr.name.notnull': 'The name cannot be left empty.',
  'ccivr.ivr.name.toolong': 'The name exceeds the maximum length allowed. Please try again.',
  'ccivr.ivr.error.uncommitted': 'The system administrator cannot play the file that is not submitted.',
  'ccivr.ivr.error.overpower': 'The tenant is unauthorized.',
  'ccivr.ivr.error.advicelimit': 'The review comment cannot contain more than 100 characters.',
  'ccivr.ivr.error.paramlimit': 'The total length of the parameter cannot exceed 4000 bytes.',
  'ccivr.ivr.error.paramNumlimit': 'The total number of the parameter cannot exceed 16.',
  'ccivr.ivr.voiceupload.fileovermax': 'The number of IVR voices to be managed exceeds the maximum.',
  'ccivr.ivr.bussinessinvoke.bussinessinvoke': 'Interface Configuration',
  'ccivr.ivr.bussinessinvoke.interfacename': 'Interface Name',
  'ccivr.ivr.bussinessinvoke.URL': 'Request URL',
  'ccivr.ivr.bussinessinvoke.interfacedesc': 'Interface Description',
  'ccivr.ivr.bussinessinvoke.updateinterface': 'Edit Interface',
  'ccivr.ivr.bussinessinvoke.createinterface': 'Create Interface',
  'ccivr.ivr.bussinessinvoke.basicconfig': 'Basic Configuration',
  'ccivr.ivr.bussinessinvoke.interfacetype': 'Interface Type',
  'ccivr.ivr.bussinessinvoke.int': 'Integer',
  'ccivr.ivr.bussinessinvoke.string': 'Character string',
  'ccivr.ivr.bussinessinvoke.savesuccess': 'Saved successfully.',
  'ccivr.ivr.bussinessinvoke.saveserror': 'Saving failed.',
  'ccivr.ivr.bussinessinvoke.validate': 'Verification Failure',
  'ccivr.ivr.bussinessinvoke.validatemessage': 'A parameter with the same name already exists.',
  'ccivr.ivr.bussinessinvoke.error': 'Error',
  'ccivr.ivr.bussinessinvoke.errormessage': 'Select the interface to be deleted.',
  'ccivr.ivr.bussinessinvoke.save': 'Save',
  'ccivr.ivr.bussinessinvoke.cancel': 'Cancel',
  'ccivr.ivr.bussinessinvoke.tip': 'Please enter a value or variable',
  'ccivr.ivr.tracelog.traceConfig': 'Access code trace config',
  'ccivr.ivr.tracelog.accessCode': 'Flow access code',
  'ccivr.ivr.tracelog.addAccessCode': 'Add access code',
  'ccivr.ivr.tracelog.title': 'TraceLog',
  'ccivr.ivr.tracelog.callingNumber': 'CallingNumber',
  'ccivr.ivr.tracelog.callingNumberplaceHolder': 'Please enter the callingNumber',
  'ccivr.ivr.tracelog.addcallingnumber': 'Add CallingNumber',
  'ccivr.ivr.tracelog.deleteconfirminfo': 'Whether to delete the current calling number:',
  'ccivr.ivr.tracelog.selecttip': 'Please select the calling number!',
  'ccivr.ivr.tracelog.deleteconfirminfocode': 'Whether to delete the current access code:',
  'ccivr.ivr.tracelog.selecttipcode': 'Please select the access code!',
  'ccivr.ivr.tracelog.index': 'Index',
  'ccivr.ivr.tracelog.callid': 'Callid',
  'ccivr.ivr.tracelog.calledNumber': 'CalledNumber',
  'ccivr.ivr.tracelog.flowName': 'FlowName',
  'ccivr.ivr.tracelog.createTime': 'Entering flow time',
  'ccivr.ivr.tracelog.errorInfo': 'Error message',
  'ccivr.ivr.tracelog.callInfo': 'Call information',
  'ccivr.ivr.tracelog.cellType': 'Element type',
  'ccivr.ivr.tracelog.nodeName': 'Element Name',
  'ccivr.ivr.tracelog.result': 'Results of the flow',
  'ccivr.ivr.tracelog.transitionOn': 'Export branch',
  'ccivr.ivr.tracelog.actionParams': 'Key parameters of Cell',
  'ccivr.ivr.tracelog.errorpop': 'Query failed!',
  'ccivr.ivr.tracelog.addFailed': 'Add failed!',
  'ccivr.ivr.tracelog.deleteFailed': 'Failed to delete!',
  'ccivr.ivr.tracelog.addSuccess': 'Added successfully!',
  'ccivr.ivr.tracelogprompt': 'This operation will affect system performance. This configuration will be automatically deleted after {0} hour. Do you want to continue?',
  'ccivr.ivr.tracelog.deleteSuccess': 'Successfully deleted!',
  'ccivr.ivr.tracelog.maxNumberValidate': 'The number of calling numbers exceeds the upper limit!',
  'ccivr.ivr.tracelog.sameNumberValidate': 'The same calling number already exists!',
  'ccivr.ivr.tracelog.calledNumbererror': 'Failed to verify the calling number!',
  'ccivr.ivr.tracelog.result.finshed': 'Finished',
  'ccivr.ivr.tracelog.result.transsuccess': 'Successful',
  'ccivr.ivr.tracelog.result.transfailed': 'Failed',
  'ccivr.ivr.tracelog.result.recordend': 'Recording success',
  'ccivr.ivr.tracelog.result.playover': 'Play success',
  'ccivr.ivr.tracelog.result.timeout': 'Timeout',
  'ccivr.ivr.tracelog.result.syserr': 'system error',
  'ccivr.ivr.tracelog.result.hangup': 'HangUp',
  'ccivr.ivr.tracelog.result.default': 'Default',
  'ccivr.nms.interface.urltip': 'Use the URL starting with HTTP or HTTPS.',
  'ccivr.nms.interface.certFile': 'Certificate File',
  'ccivr.nms.label.selectCert': 'Select Certificate',
  'ccivr.common.warning.message': 'Select a record.',
  'ccivr.nms.interface.warn': 'The network protocol used by the interface is not secure and may bring security risks',
  'ccivr.nms.interface.paramvalidate': 'Parameters should only contain numbers, English characters or _-',
  'ccivr.nms.interface.paramKeyValidate': 'Do not use the parameter name defined by the built-in variable (callId, businessReqId, callSorId, currentTime). Otherwise, the value assignment becomes invalid.',
  'ccivr.nms.interface.outparamvalidate': 'The parameter value can contain only digits, letters, and the following special characters: ._-',
  'ccivr.nms.interface.nmsexception': 'communication with ODFS meet Exception! Please check the configuration.',
  'ccivr.nms.interface.whitelistcheckfailed': 'The URL (IP address and port number) is not in the trustlist. The interface can be invoked only after you contact the system administrator to add the URL to the trustlist. Continue?',
  'ccivr.nms.interface.whitelistwarn': 'The URL (IP address and port number) is not in the trustlist. The interface can be invoked only after you contact the system administrator to add the URL to the trustlist.',
  'ccivr.nms.varibalesselect.globalvariables': 'Global Variable',
  'ccivr.nms.varibalesselect.processvaribales': 'Flow Variable',
  'ccivr.nms.varibalesselect.sysvariables': 'System Variable',
  'ccivr.nms.varibalesselect.selectvariables': 'Select Variable',
  'ccivr.nms.playservice.placeholder': 'Voice Playback',
  'ccivr.nms.playservice.contentisnull': 'The Voice Content Cannot Be Empty.',
  'ccivr.nms.playcollectservice.collectTimeout.error': 'Invalid digit collection timeout interval (1–120).',
  'ccivr.nms.playcollectservice.enterCollectTimeout': 'Please enter the digit collection timeout interval.',
  'ccivr.nms.playservice.inputtitle': 'Voice Prompt',
  'ccivr.nms.playservice.chose': 'Play content is selected from the audio files that have been uploaded and reviewed.',
  'ccivr.nms.playservice.chosetts': 'The content to be played is selected from the TTS text that has been uploaded and reviewed. TTS resources are required.',
  'ccivr.nms.playservice.chosevideo': 'The content to be played is selected from the videos that have been uploaded and reviewed.',
  'ccivr.nms.playservice.playfile': 'Voice File',
  'ccivr.nms.brach': 'Branch',
  'ccivr.nms.brach.label': 'Select Condition',
  'ccivr.nms.brach.duplicatebranch': 'The branch already exists. Select another file.',
  'ccivr.nms.LanguageService.placeholder': 'Select Language',
  'ccivr.nms.LanguageService.label': 'Language',
  'ccivr.nms.ChangeMediaService.placeholder': 'Change Media',
  'ccivr.nms.ChangeMediaService.label': 'Media',
  'ccivr.nms.userLevelService.placeholder': 'Set User Level',
  'ccivr.nms.userLevelService.label': 'User Level',
  'ccivr.nms.userLevelService.example': 'Example: 1-14 or FLOW.variableName',
  'ccivr.nms.answerService.placeholder': 'Answer',
  'ccivr.nms.ringService.placeholder': 'Ringing',
  'ccivr.nms.ringService.videomodel': 'Video Mode',
  'ccivr.nms.ringService.sendonly': 'Send Only',
  'ccivr.nms.ringService.recvonly': 'Receive Only',
  'ccivr.nms.ringService.sendrecv': 'Send Receive',
  'ccivr.nms.questionnaireService.placeholder': 'Questionnaire',
  'ccivr.nms.questionnaireService.surveyname': 'Questionnaire Name',
  'ccivr.nms.questionnaireService.question': 'Questionnaire Questions',
  'ccivr.nms.questionnaireService.info': 'Questionnaire diagram elements are used in automatic outbound call tasks. The IVR questionnaire process needs to be associated.',
  'ccivr.nms.process.samenamevalidate': 'Duplicate parameter name:',
  'ccivr.nms.process.interfaceerr': 'Interface error. Please check the log.',
  'ccivr.nms.process.saveerror': 'Saving failed. Check the flow node and connection line.',
  'ccivr.nms.process.saveerror.connection': 'Saving failed. Invalid lines exist.',
  'ccivr.nms.process.saveerror.noexistend': 'Saving failed. At least one end diagram element exists.',
  'ccivr.nms.process.saveerror.noexistconnection': 'Saving failed. A default line must be available for diagram elements except the end and transfer to subflow diagram elements.',
  'ccivr.nms.process.saveerror.unSucctransfer': 'Saving failed. The transfer diagram element have the transfer success line.',
  'ccivr.nms.process.saveerror.menukeynoconnection': 'Saving failed. Check whether each key in the menu configuration has a line.',
  'ccivr.nms.process.saveerror.noselectType': 'Select a query type.',
  'ccivr.nms.process.saveerror.noresult': 'Is not Entity.',
  'ccivr.nms.process.saveerror.select': 'Select a change type.',
  'ccivr.nms.process.saveerror.dataerror': 'Failed to verify diagram element data.',
  'ccivr.nms.process.saveerror.existnullentity': 'Empty data diagram elements exist.',
  'ccivr.nms.process.saveerror.alreadyreleased': 'The flow status is abnormal,please reopen canvas.',
  'ccivr.nms.process.saveerror.flownoexist': 'The flow does not exist.',
  'ccivr.nms.modulename': 'Element Name',
  'ccivr.nms.add': 'New',
  'ccivr.nms.delete': 'Delete',
  'ccivr.nms.businessinvokeservice.title': 'Invoke Interface',
  'ccivr.nms.businessinvokeservice.selectservice': 'Select Interface',
  'ccivr.nms.businessinvokeservice.servicename': 'Interface Name',
  'ccivr.nms.businessinvokeservice.overtime': 'Timeout Interval',
  'ccivr.nms.businessinvokeservice.second': 'Second',
  'ccivr.nms.businessinvokeservice.info1': 'The available interfaces need to be configured on the interface configuration page.',
  'ccivr.nms.businessinvokeservice.info2': 'If the invoking timeout interval is exceeded, the invoking failure module is executed.',
  'ccivr.nms.businessinvokeservice.inpuparam': 'Input Parameter',
  'ccivr.nms.businessinvokeservice.paramname': 'Name',
  'ccivr.nms.businessinvokeservice.value': 'Value',
  'ccivr.nms.businessinvokeservice.opertaion': 'Operation',
  'ccivr.nms.businessinvokeservice.outputparam': 'Output Parameter',
  'ccivr.nms.businessinvokeservice.inputflowValidate': 'Incorrect input, please modify',
  'ccivr.nms.process.isParameterisEncrypt': 'Encrypted or Not',
  'ccivr.nms.process.parameterisEncrypt': 'Encryption',
  'ccivr.nms.playcollectservice.title': 'Play Voice & Collect Digit',
  'ccivr.nms.playcollectservice.voiceprompts': 'Voice Prompt',
  'ccivr.nms.playcollectservice.voicefile': 'Voice File',
  'ccivr.nms.playcollectservice.ttsvoice': 'TTS',
  'ccivr.nms.playcollectservice.videofile': 'Video File',
  'ccivr.nms.playcollectservice.interrupt': 'Press any key to interrupt the playback.',
  'ccivr.nms.playcollectservice.receivednum': 'Received Digits',
  'ccivr.nms.playcollectservice.tip1': 'Select a parameter to save the input of the user.',
  'ccivr.nms.playcollectservice.tip2': 'Please select the string type parameter you added in the process information.',
  'ccivr.nms.playcollectservice.collectwaittime': 'Waiting Time for Digit Collection',
  'ccivr.nms.playcollectservice.entercollectwaittime': 'Enter the waiting time for digit collection.',
  'ccivr.nms.playcollectservice.collectTimeout': 'Timeout interval for digit collection',
  'ccivr.nms.playcollectservice.maxfailtimes': 'Max. Failure Times',
  'ccivr.nms.playcollectservice.entermaxfailtimes': 'Enter the maximum number of failures.',
  'ccivr.nms.playcollectservice.failtitle': 'Voice playback for digit collection failure.',
  'ccivr.nms.playcollectservice.collectnumerror': 'Digit collection failure.',
  'ccivr.nms.playcollectservice.collectnumtimeout': 'Digit collection timed out.',
  'ccivr.nms.playcollectservice.tip3': 'During digit collection, the user enters the pound key (#) to end the digit collection. Please prompt in the voice.(do not need to press the pound key (#) to collect digits in the menu configuration diagram element)',
  'ccivr.nms.playcollectservice.tip4': 'If the time when the subscriber stops the input exceeds the preconfigured digit collection waiting time, the system records the failure. In addition, the system automatically performs digit collection timeout and waits for digit collection.',
  'ccivr.nms.playcollectservice.tip5': 'If an error occurs during digit collection, the system records a failure, performs digit collection error automatically, and continues waiting for digit collection.',
  'ccivr.nms.playcollectservice.tip6': 'If the digit collection fails, you do not need to play the voice. If no voice file is selected, the system plays the voice prompt again after the failure.',
  'ccivr.nms.playcollectservice.tip7': 'When the accumulated number of failure times exceeds the maximum, the general failure exit is executed.',
  'ccivr.nms.playcollectservice.choosefile': 'Voice',
  'ccivr.nms.playcollectservice.changtovoice': 'TTS',
  'ccivr.nms.playcollectservice.changtovideoO': 'Video',
  'ccivr.nms.playcollectservice.onenumber': 'One-digit collection',
  'ccivr.nms.playcollectservice.onenumbers': 'Multi-digit collection',
  'ccivr.nms.playcollectservice.mulen': 'Max. number of digits received',
  'ccivr.nms.playcollectservice.chooseparam': 'Save user input',
  'ccivr.nms.playcollectservice.advancedconfig': 'Advanced Settings',
  'ccivr.nms.playcollectservice.close': 'Close',
  'ccivr.nms.playcollectservice.required': 'Mandatory Field',
  'ccivr.nms.menuname.specialStrValidateFailed': 'The length cannot exceed 255 characters',
  'ccivr.nms.tansferservice.timedPrompt': 'You have not saved it for a long time. Please save it in time.',
  'ccivr.nms.tansferservice.processLayout': 'Process Orchestration',
  'ccivr.nms.tansferservice.callForwarding': 'Call Forwarding Configuration',
  'ccivr.nms.tansferservice.moduleDescription': 'Module Description',
  'ccivr.nms.tansferservice.processName': 'Enter a flow name.',
  'ccivr.nms.tansferservice.setTransfer': 'Transfer Configuration',
  'ccivr.nms.tansferservice.transSkill': 'The call is transferred to the corresponding skill queue. User-defined parameters are supported. Example: {\'$\'}{\'{\'}FLOW.Parameter name{\'}\'}',
  'ccivr.nms.tansferservice.transNumType': 'The forwarding number can be a mobile number, fixed-line phone number, or customized parameter. Example: {\'$\'}{\'{\'}FLOW.Parameter name{\'}\'}',
  'ccivr.nms.tansferservice.transNum': 'Forwarding number',
  'ccivr.nms.tansferservice.passingParameters': 'Transfer Parameters',
  'ccivr.nms.tansferservice.parameter': 'Value',
  'ccivr.nms.tansferservice.operat': 'Operation',
  'ccivr.nms.tansferservice.add': 'New',
  'ccivr.nms.tansferservice.delete': 'Delete',
  'ccivr.nms.tansferservice.samenamevalidate': 'Duplicate parameter name.',
  'ccivr.nms.tansferservice.transferZNIvrCode': 'Transfer to Intelligent IVR',
  'ccivr.nms.tansferservice.chooseZNIvr': 'Select Intelligent IVR',
  'ccivr.nms.tansferservice.transferZNIvr': 'Customer calls will be transferred to the corresponding smart IVR',
  'ccivr.nms.tansferservice.record': 'Record',
  'ccivr.nms.tansferservice.selectPath': 'Select a path',
  'ccivr.nms.tansferservice.transAppointWorkNo': 'An inbound call is transferred to a specified agent.',
  'ccivr.nms.tansferservice.transferWorkNo': 'Transfer to Agent',
  'ccivr.nms.tansferservice.transInputWorkNo': 'Enter the ID of the agent to whom a call is transferred.',
  'ccivr.nms.transInputWorkNo.sample': 'Example: 28956666 or {\'$\'}{\'{\'}FLOW.currentWorkNo{\'}\'}',
  'ccivr.nms.tansferservice.transCallingnum': 'Caller Number',
  'ccivr.nms.tansferservice.sampleForCallingnum': 'Example: 660001 or {\'$\'}{\'{\'}FLOW.accessCode{\'}\'}',
  'ccivr.nms.tansferservice.mustbeaccesscode': 'The calling number must be an accesscode allocated by the system or customized parameter. Example: {\'$\'}{\'{\'}FLOW.Parameter name{\'}\'}',
  'ccivr.nms.tansferservice.skillId': 'Skill ID',
  'ccivr.nms.tansferservice.queueindex': 'Queuing position',
  'ccivr.nms.tansferservice.queuenum': 'Number of queues',
  'ccivr.nms.tansferservice.onlineagent': 'Number of online agents',
  'ccivr.nms.tansferservice.estimateTime': 'estimateTime',
  'ccivr.nms.queryservice.estimateTime.prompte': 'The expected waiting time of queuing is calculated based on the queue model and the result has some deviation. Please use it properly.',
  'ccivr.nms.tansferservice.empty': 'Parameter is empty.',
  'ccivr.nms.tansferservice.transferLastAgent': 'Audio and video familiar customers',
  'ccivr.nms.tansferservice.transferLastAgent.transCallingnum': 'Calling Number',
  'ccivr.nms.tansferservice.transferLastAgent.mustbeaccesscode': 'The calling number is the customer\'s inbound number, for example, {\'$\'}{\'{\'}SYS.callingNumber{\'}\'}.',
  'ccivr.nms.tansferservice.transferLastAgent.sampleForCallingnum': 'Example: 660001 or {\'$\'}{\'{\'}SYS.callingNumber{\'}\'}',
  'ccivr.nms.tansferservice.transferLastAgent.type': 'Last Agent Mode',
  'ccivr.nms.tansferservice.transferLastAgent.type.time': 'Time range',
  'ccivr.nms.tansferservice.transferLastAgent.type.time.label': 'min',
  'ccivr.nms.tansferservice.transferLastAgent.type.time.info': 'The call is transferred to the agent who is contacted within a time range.',
  'ccivr.nms.tansferservice.transferLastAgent.type.time.sample': 'Example: 100, indicating that the call is transferred to the agent who is contacted within 100 minutes.',
  'ccivr.nms.tansferservice.transferLastAgent.type.startTime': 'Start time',
  'ccivr.nms.tansferservice.transferLastAgent.type.startTime.info': 'The call is transferred to the agent who is contacted from the start time.',
  'ccivr.nms.tansferservice.transferLastAgent.type.startTime.sample': 'Select the start time.',
  'ccivr.nms.tansferservice.transferLastAgent.type.currentday': 'Current day',
  'ccivr.nms.tansferservice.transferLastAgent.type.currentday.info': 'The call is transferred to the agent who is contacted on the current day.',
  'ccivr.nms.tansferservice.transferLastAgent.type.none': 'Default',
  'ccivr.nms.tansferservice.transferLastAgent.type.none.info': 'The call is transferred to the agent who is contacted recently.',
  'ccivr.nms.tansferservice.transferThirdParty.recordingSettings': 'Recording Setting',
  'ccivr.nms.tansferservice.transferThirdParty.enableRecording': 'Recording',
  'ccivr.nms.tansferservice.transferThirdParty.recordingPrompt': 'Recording Prompt',
  'ccivr.nms.transferservice.choose.recordfilename.info': 'When the interface is invoked to download recording files, the variable is used to temporarily store the value.',
  'ccivr.nms.conditionService.processLayout': 'Process Orchestration',
  'ccivr.nms.conditionService.conditionService': 'Conditional Judgment',
  'ccivr.nms.conditionService.moduleDescription': 'Module Description',
  'ccivr.nms.conditionService.createCondition': 'Add Condition',
  'ccivr.nms.conditionService.conditionalEdit': 'Condition Editing',
  'ccivr.nms.conditionService.condition': 'Condition Name',
  'ccivr.nms.conditionService.Parameter_1': 'Parameter 1',
  'ccivr.nms.conditionService.Parameter_2': 'Parameter 2',
  'ccivr.nms.conditionService.relationOperator': 'Operator',
  'ccivr.nms.conditionService.logicOperator': 'Logical',
  'ccivr.nms.conditionService.operat': 'Operation',
  'ccivr.nms.conditionService.add': 'New',
  'ccivr.nms.conditionService.delete': 'Delete',
  'ccivr.nms.conditionService.parameterEmpty': 'Parameter 1 is empty.',
  'ccivr.nms.conditionService.maxroute': 'The maximum number of conditional branches is 20.',
  'ccivr.nms.timeselectservice.title': 'Time Selection',
  'ccivr.nms.timeselectservice.servicetimeperiod': 'Service Time Range',
  'ccivr.nms.timeselectservice.timeperiod': 'Time Period',
  'ccivr.nms.timeselectservice.hour': 'Hour',
  'ccivr.nms.timeselectservice.min': 'Minute',
  'ccivr.nms.timeselectservice.timeSelectType':'Configuration Mode',
  'ccivr.nms.timeselectservice.customConfig':'Customize',
  'ccivr.nms.timeselectservice.workDayType':'Template',
  'ccivr.nms.timeselectservice.workDay':'Work Calendar',
  'ccivr.nms.timeselectservice.selectworktime': 'Week Day',
  'ccivr.nms.timeselectservice.selectdate': 'Date',
  'ccivr.nms.timeselectservice.tips1': 'The date that meets the selected week day and date is a workday.',
  'ccivr.nms.timeselectservice.tips2': 'The service end time must be later than the start time.',
  'ccivr.nms.timeselectservice.tips3': 'The service time ranges must be in ascending order. Each time range cannot be overlapped with each other.',
  'ccivr.nms.timeselectservice.monday': 'Monday',
  'ccivr.nms.timeselectservice.tuesday': 'Tuesday',
  'ccivr.nms.timeselectservice.wednesday': 'Wednesday',
  'ccivr.nms.timeselectservice.thursday': 'Thursday',
  'ccivr.nms.timeselectservice.friday': 'Friday',
  'ccivr.nms.timeselectservice.saturday': 'Saturday',
  'ccivr.nms.timeselectservice.sunday': 'Sunday',
  'ccivr.ivr.ivrflow.operate.gateway.pulishrepeat': 'The process name is duplicate or a version has been released.',
  'ccivr.nms.SubFlowService.Rotorflow': 'Transfer to Subflow',
  'ccivr.nms.SubFlowService.processjump': 'Jump To',
  'ccivr.nms.SubFlowService.jumpnode': 'Jump Node',
  'ccivr.nms.process.processinformation': 'Flow Information',
  'ccivr.nms.process.basicinformation': 'Basic Information',
  'ccivr.nms.process.parameterconfiguration': 'Parameter Configuration',
  'ccivr.nms.process.customparameter': 'Flow Parameters',
  'ccivr.nms.process.parametertype': 'Parameter Type',
  'ccivr.nms.process.initialvalue': 'Initial Value',
  'ccivr.nms.process.description': 'Description',
  'ccivr.nms.process.String': 'Character string',
  'ccivr.nms.process.number': 'Integer',
  'ccivr.nms.process.sysparam': 'System Parameters',
  'ccivr.nms.process.paramname': 'Name',
  'ccivr.nms.process.success': 'The flow is saved successfully.',
  'ccivr.nms.process.paramnameNoCN': 'The paramname is wrong.',
  'ccivr.nms.playcollectservice.Receivemaxnum': 'Max. number of digits received',
  'ccivr.nms.playcollectservice.second': 'second',
  'ccivr.nms.playcollectservice.times': 'times',
  'ccivr.nms.playcollectservice.Failedreceivenum': 'Digit Collection Failure',
  'ccivr.nms.playcollectservice.tip8': 'During digit collection, the user enters the pound key (#) to end the digit collection. Please prompt in the voice.',
  'ccivr.nms.menuservice.title': 'Menu configuration',
  'ccivr.nms.menuservice.voicenotice': 'Voice Prompt',
  'ccivr.nms.menuservice.menubuttonconfig': 'Menu Key Configuration',
  'ccivr.nms.menuservice.button': 'Buttons',
  'ccivr.nms.menuservice.moveup': 'Up',
  'ccivr.nms.menuservice.movedown': 'Down',
  'ccivr.nms.menuservice.contentvalidate': 'After the editing is complete, add key configuration.',
  'ccivr.nms.menuservice.samenamevalidate': 'Repeated keys:',
  'ccivr.nms.conditionService.simpleExp': 'Simple Expression',
  'ccivr.nms.conditionService.complexExp': 'Complex Expression',
  'ccivr.nms.conditionService.logicAND': 'And',
  'ccivr.nms.conditionService.logicOR': 'Or',
  'ccivr.nms.tansferservice.transferLabor': 'Transfer to Skill Queue',
  'ccivr.nms.tansferservice.transferThirdParty': 'Transfer to Third Party',
  'ccivr.nms.tansferservice.chooseSkill': 'Select Skill Queue',
  'ccivr.nms.tansferservice.sample': 'Example: 28956666 or {\'$\'}{\'{\'}FLOW.currentUserNum{\'}\'}',
  'ccivr.nms.menuservice.tip': 'Timeout or Incorrect Key',
  'ccivr.nms.leavemessageservice.leavemessage': 'Leave Message',
  'ccivr.nms.leavemessageservice.recordfile': 'Recording File',
  'ccivr.nms.leavemessageservice.choose.recordfilename': 'Save File Name',
  'ccivr.nms.leavemessageservice.localtionID': 'localtionID',
  'ccivr.nms.leavemessageservice.choose.localtionID': 'Saving the localtionID',
  'ccivr.nms.leavemessageservice.max.recordtime': 'Max. Recording Duration',
  'ccivr.nms.leavemessageservice.playbi': 'Play the beep tone',
  'ccivr.nms.leavemessageservice.recordparam': 'Recording Parameter',
  'ccivr.nms.leavemessageservice.agent': 'Specify Agent for Processing',
  'ccivr.nms.leavemessageservice.choose.agent': 'Specified Agent ID',
  'ccivr.nms.leavemessageservice.choose.agent.sample': 'Example: 101 or {\'$\'}{\'{\'}FLOW.workNo{\'}\'}',
  'ccivr.nms.leavemessageservice.choose': 'Select',
  'ccivr.nms.leavemessageservice.end': 'By default, the recording is ended with the pound key (#).',
  'ccivr.nms.leavemessageservice.placeholder': 'Leave Message',
  'ccivr.nms.leavemessageservice.saveleavemessage': 'Record message information.',
  'ccivr.nms.leavemessageservice.flownamerepeat': 'The recording file name and localtionID cannot use the same variable.',
  'ccivr.nms.callendservice.title': 'End',
  'ccivr.nms.callendservice.checkbox': 'The interface needs to be invoked when a call ends.',
  'ccivr.flowtest.flowname': 'Name',
  'ccivr.flowtest.begin.tip': 'Enter the calling number to start the test.',
  'ccivr.flowtest.callingnumber': 'Caller Number',
  'ccivr.flowtest.enter': 'Enter the calling number.',
  'ccivr.flowtest.begintest': 'Start',
  'ccivr.flowtest.stoptest': 'Stop',
  'ccivr.flowtest.success': 'Success',
  'ccivr.flowtest.fail': 'Failure',
  'ccivr.flowtest.presskey': 'Buttons',
  'ccivr.flowtest.enter.phone': 'Enter the phone number.',
  'ccivr.flowtest.hangup': 'Hang Up',
  'ccivr.flowtest.test': 'Test',
  'ccivr.flowtest.testoperate': 'Operations:',
  'ccivr.flowtest.client': 'Customer',
  'ccivr.flowtest.platform': 'Platform',
  'ccivr.flowtest.pleasebegintest': 'The process has ended.',
  'ccivr.flowtest.hashangup': 'The test ends, and the call is released.',
  'ccivr.flowtest.operatesuccess': 'Success',
  'ccivr.flowtest.operatefail': 'Failure',
  'ccivr.flowtest.operatehangup': 'Hang up',
  'ccivr.flowtest.test.enter.callingnumber': 'Please enter calling number first!',
  'ccivr.flowtest.flowtest': 'Flow test',
  'ccivr.whitelist.whitelistmanage': 'Trustlist management',
  'ccivr.whitelist.whiteip': 'Trustlist IP',
  'ccivr.whitelist.imordomainname': 'IP or domain name',
  'ccivr.whitelist.whiteport': 'Trustlist port',
  'ccivr.whitelist.port': 'port',
  'ccivr.whitelist.desc': 'description',
  'ccivr.whitelist.descinfo': 'Description',
  'ccivr.whitelist.deletetip': 'Please tick the items you want to delete',
  'ccivr.whitelist.urlvalidatefailed': 'Please enter IP or domain name',
  'ccivr.nms.assignservice.Dataassignment': 'Data assignment',
  'ccivr.nms.assignservice.assignmentParameters': 'Prepare assignment parameters',
  'ccivr.nms.assignservice.transferDataAssign': 'Get TransferData',
  'ccivr.nms.assignservice.othersDataAssign': 'Assign OthersData',
  'ccivr.nms.assignservice.setTransferData': 'Set TransferData',
  'ccivr.nms.assignservice.ieDataAssign': 'Assign IEData',
  'ccivr.nms.assignservice.ietype': 'IE Type',
  'ccivr.nms.satisfactionservice.type': 'Satisfaction Survey Type',
  'ccivr.nms.satisfactionservice.title': 'Satisfaction Configuration',
  'ccivr.nms.singleproblem.title': 'Single-issue survey',
  'ccivr.nms.satisfactionservice.menubuttonconfig': 'Satisfaction Button Configuration',
  'ccivr.nms.satisfactionservice.singleproblem.menubuttonconfig': 'Single-question survey button configuration',
  'ccivr.nms.satisfactionservice.buttonNumber': 'The number of satisfaction survey keys is not more than 9 and not less than 3.',
  'ccivr.nms.satisfactionservice.tip': 'Please configure the satisfaction level in the Satisfaction Configuration menu. If the flow has been released, you can upgrade, edit, save, and release the flow again to replace the original one to use the updated satisfaction level.',
  'ccivr.nms.satisfactionservice.descripLength': 'Satisfaction survey key description does not exceed 200',
  'ccivr.nms.satisfactionservice.isNull': 'Satisfaction Parameter is empty',
  'ccivr.grayrule.errorinfo.containspecialcha': 'The param ruleName contains special character',
  'ccivr.ivr.ivrflow.templateName': 'Template Name',
  'ccivr.ivr.voiceupload.referenceFlowByCCIVR': 'IVR Flow Reference',
  'ccivr.ivr.voiceupload.referenceFlowByNMS': 'Resource Template Reference',
  'ccivr.ivr.voiceupload.voiceUsageByTenant': 'Tenant Scenario Reference',
  'ccivr.ivr.voiceupload.voiceUsageBySkill': 'Skill Queue Reference',
  'ccivr.nms.queryservice.querySkillMessage': 'Information Query',
  'ccivr.nms.queryservice.assignmentParameters': 'Parameter to Be Set',
  'ccivr.nms.queryservice.getDataAssign': 'Query result',
  'ccivr.nms.queryservice.Dataassignment': 'Information query',
  'ccivr.nms.queryservice.queryType': 'Query Type',
  'ccivr.nms.queryservice.queryParam': 'Request Parameter',
  'ccivr.nms.queryservice.skillMsg': 'Queuing information',
  'ccivr.nms.queryservice.skillInfo': 'Queue information',
  'ccivr.nms.queryservice.type': 'Select Type',
  'ccivr.nms.queryservice.paramName': 'Request Parameter',
  'ccivr.nms.queryservice.paramValue': 'Value',
  'ccivr.nms.queryservice.info': 'The queuing information is queried using an IVR flow when a call is transferred to a skill queue.',
  'ccivr.nms.queryservice.queueInfo': 'The queue information is queried before a call is transferred to a skill queue.',
  'ccivr.nms.queryservice.queueSpecialInfo': 'Query whether the calling subscriber number is in the special list.',
  'ccivr.nms.queryservice.specialList.flagInfo': 'Whether the subscriber number is in the special list. The options are 0 (no) and 1 (yes).',
  'ccivr.nms.queryservice.audio': 'Audio',
  'ccivr.nms.queryservice.video': 'Video',
  'ccivr.nms.queryservice.specifiedSkill': 'Queue name',
  'ccivr.nms.queryservice.specifiedSkill.paramValue': 'Agent ID',
  'ccivr.nms.queryservice.specifiedSkill.paramValue.sample': 'Example: 101 or {\'$\'}{\'{\'}FLOW.workNo{\'}\'}.',
  'ccivr.nms.queryservice.specifiedSkill.result': 'Queue Name',
  'ccivr.nms.queryservice.specialListQuery': 'Special list information',
  'ccivr.nms.queryservice.specialList.flag': 'Flag of Being in the Special List',
  'ccivr.nms.playset.status': 'Advanced TTS settings',
  'ccivr.nms.playset.open': 'Enabled',
  'ccivr.nms.playset.close': 'Disabled',
  'ccivr.nms.playset.sound': 'Sound effect',
  'ccivr.nms.playset.malevoice': 'Male voice',
  'ccivr.nms.playset.femalevoice': 'Female voice',
  'ccivr.nms.playset.speed': 'Speaking speed',
  'ccivr.nms.playset.lowspeed': 'Low',
  'ccivr.nms.playset.intermediatespeed': 'Medium',
  'ccivr.nms.playset.highspeed': 'High',
  'ccivr.nms.playset.extendparam': 'TTS extended parameter',
  'ccivr.nms.playcollectservice.playset.reset': 'Reset',
  'ccivr.nms.shortmessageservice.processInformation': 'Text Message',
  'ccivr.nms.shortmessageservice.sendType': 'Message Sending Mode',
  'ccivr.nms.shortmessageservice.normalMessage': 'Short Message',
  'ccivr.nms.shortmessageservice.fiveGMessage': '5G Message',
  'ccivr.nms.shortmessageservice.satisfactionMessage': 'Satisfaction Survey',
  'ccivr.nms.shortmessageservice.smsType': 'Channel',
  'ccivr.nms.shortmessageservice.hwCloudMessage': 'Huawei Cloud',
  'ccivr.nms.shortmessageservice.mspMessage': 'SMS Gateway',
  'ccivr.nms.shortmessageservice.selectTemplate': 'Select Template',
  'ccivr.nms.shortmessageservice.messageTemplateContent': 'Template',
  'ccivr.nms.shortmessageservice.setParameter': 'Set Variable',
  'ccivr.nms.shortmessageservice.countryCode': 'Country Code',
  'ccivr.nms.shortmessageservice.addressee': 'Mobile Number',
  'ccivr.nms.shortmessageservice.phoneNumber': 'Mobile phone number',
  'ccivr.nms.shortmessageservice.addresseeDesc': 'Optional, Single mobile number, If blank, it will be send to the caller.',
  'ccivr.nms.shortmessageservice.period': 'Valid Reply Period(Minutes)',
  'ccivr.nms.playcollectservice.changetomultivoice': 'Variable voice',
  'ccivr.nms.playservice.chosemultivoice': 'Set the content to be played from the following selections',
  'ccivr.nms.playservice.multivoice.language': 'Language',
  'ccivr.nms.playservice.multivoice.language0': 'Putonghua',
  'ccivr.nms.playservice.multivoice.language1': 'English',
  'ccivr.nms.playservice.multivoice.language2': 'Chinese(Cantonese)',
  'ccivr.nms.playservice.multivoice.language3': 'Czech',
  'ccivr.nms.playservice.multivoice.language4': 'Danish',
  'ccivr.nms.playservice.multivoice.language5': 'Dutch',
  'ccivr.nms.playservice.multivoice.language6': 'Finnish',
  'ccivr.nms.playservice.multivoice.language7': 'French',
  'ccivr.nms.playservice.multivoice.language8': 'German',
  'ccivr.nms.playservice.multivoice.language9': 'Greek',
  'ccivr.nms.playservice.multivoice.language10': 'Hungarian',
  'ccivr.nms.playservice.multivoice.language11': 'Icelandic',
  'ccivr.nms.playservice.multivoice.language12': 'Italian',
  'ccivr.nms.playservice.multivoice.language13': 'Japanese',
  'ccivr.nms.playservice.multivoice.language14': 'Korean',
  'ccivr.nms.playservice.multivoice.language15': 'Norwegian',
  'ccivr.nms.playservice.multivoice.language16': 'Polish',
  'ccivr.nms.playservice.multivoice.language17': 'Portuguese',
  'ccivr.nms.playservice.multivoice.language18': 'Russian',
  'ccivr.nms.playservice.multivoice.language19': 'Slovak',
  'ccivr.nms.playservice.multivoice.language20': 'Spanish',
  'ccivr.nms.playservice.multivoice.language21': 'Swedish',
  'ccivr.nms.playservice.multivoice.language22': 'Turkish',
  'ccivr.nms.playservice.multivoice.language23': 'Uzbekistan',
  'ccivr.nms.playservice.multivoice.language24': 'Thailand',
  'ccivr.nms.playservice.multivoice.language81': 'Arabic',
  'ccivr.nms.playservice.multivoice.type': 'Type',
  'ccivr.nms.playservice.multivoice.type.telnum': 'Phone number',
  'ccivr.nms.playservice.multivoice.type.number': 'Number',
  'ccivr.nms.playservice.multivoice.type.time': 'time(hh:mm:ss)',
  'ccivr.nms.playservice.multivoice.type.date': 'Date(yyyymmdd)',
  'ccivr.nms.playservice.multivoice.type.price': 'Price',
  'ccivr.nms.playservice.multivoice.content': 'Content',
  'ccivr.ivr.languageid.repeat.fail': 'languageId is not exist.',
  'ccivr.ivr.ivrtextexception.fail': 'Failed to add the IVR text.',
  'ccivr.ivr.illegal.fail': 'Invalid parameter.',
  'ccivr.ivr.type.fail': 'The upload type is incorrect.',
  'ccivr.ivr.delete.fail': 'Deletion failed.',
  'ccivr.ivr.create.exception': 'Adding failed.',
  'ccivr.whitelist.validator.ip': 'Invalid IP address. (Enter an IPv4 or IPv6 address, or a domain name.)',
  'ccivr.interface.typeerror': 'Currently, only the GET and POST types are supported.',
  'ccivr.nms.process.parameterencryptips': 'Note that unencrypted parameters store parameter values in plaintext, which may pose security risks if used.',
  'ccivr.ivrflow.specify.accessCode': 'Specify Channel Access Code',
  'ccivr.ivr.transferRecord.startTime': 'Start Time',
  'ccivr.ivr.transferRecord.endTime': 'End Time',
  'ccivr.ivr.transferRecord.callNumber': 'Calling number',
  'ccivr.ivr.transferRecord.flowAccessCode': 'Process access code',
  'ccivr.ivr.transferRecord.transNumber': 'Forwarding Number',
  'ccivr.ivr.transferRecord.transResult': 'Transfer Result',
  'ccivr.ivr.transferRecord.recordedFile': 'Recording file',
  'ccivr.ivr.transferRecord.play': 'Play',
  'ccivr.ivr.transferRecord.download': 'download',
  'ccivr.ivr.transferRecord.success': 'Success',
  'ccivr.ivr.transferRecord.fail': 'Fail',
  'ccivr.ivr.transferRecord.noMoreThan90Days': 'The range cannot exceed 90 days.',
  'ccivr.transferrecord.download.record': 'Download Flow Transfer Recording:',
  'ccivr.transferrecord.play.record': 'Playing Process Transfer Recordings:',
  'ccivr.transferrecord.play.timeCompare': 'The start time cannot be later than the end time.',
  'ccivr.ivr.transferRecord.init': 'Initialization',
  'ccivr.ivr.transferRecord.noright': 'The current user does not have the permission to download recordings.',
  'ccivr.ivr.transferRecord.query.failed': 'Failed to Query Transfer Recordings',
  'ccivr.ivr.transferRecord.connect.uap.failed': 'Failed to Connect to the File Server',
  'ccivr.ivr.transferRecord.connect.file.notexist': 'Failed to download the recording file. Please check whether the file exists.',
  'ccivr.nms.tansferservice.idleAgentCount': 'Number of idle agents',
  'ccivr.nms.process.saveerror.queuing': 'Enter at least one of the queuing position, number of online agents, and expected waiting time.',
  'ccivr.nms.process.saveerror.queues': 'Enter at least one of Queue Position, Number of Online Agents, Number of Idle Agents, and Expected Wait Time.',
  'ccivr.ivr.voice.scene.voicenotification': 'Voice notification',
  'ccivr.ivr.connectsftp.uploadfile.exception': 'Failed to connect to the SFTP. The file fails to be uploaded.',
  'ccivr.ivr.voicepop.inputname': 'Enter the IVR voice name.',
  'ccivr.ivr.voicereview.reviewmethod': 'Voice resource review mode',
  'ccivr.ivr.voicereview.manualreview': 'Manual review',
  'ccivr.ivr.voicereview.autopass': 'Auto Pass',
  'ccivr.ivr.voiceupload.file.content': 'Content',
  'ccivr.ivr.voiceupload.file.update.content': 'Update Content',
  'ccivr.ivr.voiceupload.file.update.time': 'Update Time',
  'ccivr.ivr.voiceupload.file.update.status': 'Status',
  'ccivr.ivr.voiceupload.file.update.comments': 'Comments',
  'ccivr.ivr.voiceupload.file.update.operation': 'Operation',
  'ccivr.ivr.voiceupload.file.update.delete': 'Delete',
  'ccivr.ivr.voiceupload.file.update.submit': 'Submit',
  'ccivr.ivr.voiceupload.file.update': 'Updates',
  'ccivr.ivr.voiceupload.textcontent': 'Text',
  'ccivr.ivr.bussinessinvoke.long': 'Long integer',
  'ccivr.ivr.voiceupload.fileformatwrong': 'Incorrect type.',
  'ccivr.nms.playservice.TTS': 'TTS Voice Playback',
  'ccivr.ivr.voiceupload.inputlanguageerror': 'The language does not exist！',
  'ccivr.ivr.voiceupload.choosestatus': 'Select review status.',
  'ccivr.ivr.bussinessinvoke.float': 'Floating',
  'ccivr.nms.playservice.interrupt': 'Press any key to interrupt the playback.',
  'ccivr.flowtest.test.error.tip': 'flow has not config or apply',
  'ccivr.nms.label.certType': 'Certificate Type',
  'ccivr.nms.label.inputCertCode': 'Enter a certificate code.',
  'ccivr.nms.label.certCode': 'Certificate Code',
  'ccivr.nms.label.certScene': 'Application Scenario',
  'ccivr.nms.playservice.chose.survey': 'Select an uploaded and approved voice file, or add a new voice file.',
  'ccivr.nms.playservice.chosetts.survey': 'Select an uploaded and approved TTS text, or add a new TTS text. TTS resources must be available.',
  'ccivr.ivr.error.releaseFailed.tips': 'Failed to release the questionnaire automatically. Configure and release an exception handling process and manually release the questionnaire.',
  'ccivr.ivr.ivrflow.operate.add': 'Add',
  'ccivr.nms.satisfactionservice.buttonNumber.Number': 'The number of keys is not more than 9 and not less than 1.',
  'ccivr.nms.lightweight.tips': 'Perform operations on the lightweight questionnaire.',
  'ccivr.conditionService.param.tips': 'When parameter 2 is set as a string constant, quotes must be added for normal use.',
  'ccivr.nms.conditionService.sample': 'Enter a condition expression. The expression can contain the parameter name, value, and complex operation. A parameter is referenced in FLOW.Parameter name format. Example: SYS.currentNumber==FLOW.RechargeNumber&& (FLOW.AccountBalance - 20)>=FLOW.BillAmount',
  'ccivr.nms.conditionService.tips': 'Conditional expressions can contain variables, values, and complex operations. The comparison operator between values or character strings supports > < >= <= !=. The Boolean operator supports && {\'|\'}{\'|\'} == !=. Example: SYS.currentNumber==FLOW.RechargeNumber && (FLOW.AccountBalance-20)>=FLOW.BillAmount',
  'ccivr.TimeSelectService.branch.wrongtime': 'Wrong Time',
  'ccivr.playservice.branch.query': 'Query error',
  'ccivr.businessInvokeService.err': 'Interface call failed',
  'ccivr.playservice.branch.ring': 'Ring error',
  'ccivr.playservice.branch.userLevel': 'Setting error',
  'ccivr.tansferservice.branch.transferErr': 'Transfer Error',
  'ccivr.nms.transferservice.choose.recordfilename.remark': 'used in the call center pool mode',
  'ccivr.playservice.branch.change': 'Change error',
  'ccivr.nms.conditionService.conditionBranch': 'Condition Branch',
  'ccivr.playservice.branch.playerr': 'Play error',
  'ccivr.PlayCollectService.branch.err': 'PlayCollect Error',
  'ccivr.nms.playcollectservice.warntype': 'Prompt Type',
  'ccivr.ivr.bussinessinvoke.paramTip': 'Parameter name, for example, GLOBAL.language or FLOW.testid',
  'ccivr.leaveMessage.maxtime.range': 'The maximum recording duration ranges from 10 to 180 seconds.',
  'ccivr.ivr.voiceupload.fileName.validate': 'The file name cannot contain the following special characters:',
  'ccivr.nms.play.method': 'Method',
  'ccivr.nms.play.source': 'Resource',
  'ccivr.ivr.ivrflow.operate.release.referencedflow': 'Failed to cancel the release! The flow has been referenced.',
  'ccivr.ivr.voice.scene.2d.avatar': 'Virtual Human',
  'ccivr.ivr.voice.picturechoose':'Select an image file',
  'ccivr.ivr.voiceupload.selectpicture': 'Select an image file',
  'ccivr.ivr.voiceupload.tippictureformat': 'Currently, only JPG, PNG, and JPEG image files are supported. The resolution of the file cannot exceed 720p, and the file size cannot exceed 2 MB.',
  'ccivr.ivr.voiceupload.tippicturemodel': 'You can set image files in different languages at the same time. The system automatically switches to the corresponding image file based on the language selected by the user during virtual human synthesis.',
  'ccivr.ivr.voiceupload.tip2DVideoformat': 'Currently, only MP4 video files are supported. The file resolution cannot exceed 720p, and the file size cannot exceed 5 MB.',
  'ccivr.ivr.voiceupload.tip2Dvideomodel': 'You can set video files in different languages at the same time. During virtual human composition, the system automatically switches to the corresponding video file based on the language selected by the user.',
  'ccivr.ivr.voiceupload.vedioMp4formatwrong': 'Incorrect file format. Please import an MP4 file.',
  'ccivr.ivr.voiceupload.tipvideo2Dformat': 'Currently, only MP4 video files are supported. The file size cannot exceed 5 MB.',
  'ccivr.ivr.voicereview.picture': 'Image',
  'ccivr.ivr.voicereview.2dVideo': 'Virtual Human Video',
  'ccivr.ivr.voiceupload.tipPicture2Dformat': 'Currently, only JPG, PNG, and JPEG image files are supported. The file size cannot exceed 2 MB.',
  'ccivr.ivr.voiceupload.pictureformatwrong': 'Incorrect file format. Please import a JPG, PNG, or JPEG file.',
  'ccivr.ivr.voiceupload.file.updateTitle': 'Edit audio and video resources',
  'ccivr.ivr.voiceupload.file.createTitle': 'New Audio and Video Resource',
  'ccivr.ivr.voiceupload.tippictureresolution': 'The image resolution cannot exceed 720p.',
  'ccivr.ivr.voiceupload.tipvideoresolution': 'The video resolution cannot exceed 720p.',
  'oifde.service.invoke.businessVirtualHumanService.name': 'Virtual Human Reply',
};
