export default {
  "ccmessaging.chat.chat.closeDialog": "Confirmar o fechamento?",
  "ccmessaging.chat.silentagent.config.add.error": "Falha ao criar nova configuração de agente silencioso de voz",
  "ccmessaging.chat.silentagent.config.add.success": "A nova configuração do agente silencioso de voz foi criada com sucesso",
  "ccmessaging.chat.fiveGCard.add": "Novo cartão 5G",
  "ccmessaging.chat.fiveGCard.edit": "Editar cartão 5G",
  "ccmessaging.chat.fiveGCarouselCard.add": "Novo cartão de carrossel 5G",
  "ccmessaging.chat.fiveGCarouselCard.edit": "Editar cartão de carrossel 5G",
  "ccmessaging.chat.webCard.add": "Adicionar um Web Card",
  "ccmessaging.chat.webCard.edit": "Editando um Web Card",
  "ccmessaging.chat.coBrowseSite.add": "Adicionar um endereço de colaboração Web",
  "ccmessaging.chat.coBrowseSite.edit": "Editar um endereço de sinergia da Web",
  "ccmessaging.chat.richTextPage.add": "Novo Rich Text",
  "ccmessaging.chat.richTextPage.edit": "Editar rich text",
  "ccmessaging.chat.chatPhrase.add": "Adicionar uma frase comum",
  "ccmessaging.chat.chatPhrase.edit": "Editar frases comuns",
  "ccmessaging.chat.audioLibrary.add": "Adicionar voz",
  "ccmessaging.chat.audioLibrary.edit": "Editar voz",
  "ccmessaging.chat.videoLibrary.add": "Adicionar um vídeo",
  "ccmessaging.chat.videoLibrary.edit": "Editar vídeo",
  "ccmessaging.chat.imgLibrary.add": "Adicionar imagem",
  "ccmessaging.chat.imgLibrary.edit": "Editar imagem",
  "ccmessaging.chat.emoLibrary.add": "Adicionar um emoticon personalizado",
  "ccmessaging.chat.emoLibrary.edit": "Editar emoticons personalizados",
  "ccmessaging.chat.docLibrary.add": "Novo Documento",
  "ccmessaging.chat.docLibrary.edit": "Editar documento",
  "ccmessaging.chat.locate.add": "Adicionar um endereço",
  "ccmessaging.chat.locate.edit": "Editar endereço",
  "ccmesaging.chat.agentconsole.commom.label": "Etiqueta",
  "ccmesaging.chat.agentconsole.label.callReason": "Motivo da chamada",
  "ccmesaging.chat.agentconsole.label.commonreply": "Respostas comuns",
  "ccmesaging.chat.agentconsole.label.expression": "Emoticons",
  "ccmesaging.chat.agentconsole.label.fontstyle": "Estilo de fonte",
  "ccmesaging.chat.agentconsole.label.historicalcontact": "Contato histórico",
  "ccmesaging.chat.agentconsole.label.incallmessage": "Mensagem de chamada recebida",
  "ccmesaging.chat.agentconsole.label.Realtimetransfer": "Tradução em Tempo Real",
  "ccmesaging.chat.agentconsole.label.robotChat": "Diálogo com Chatbot",
  "ccmesaging.chat.agentconsole.label.screenshot": "Capturas de tela",
  "ccmesaging.chat.agentconsole.label.seeMore": "Ver mais",
  "ccmesaging.chat.agentconsole.label.service": "Serviço",
  "ccmesaging.chat.agentconsole.label.uploadattachment": "Carregar anexo",
  "ccmesaging.chat.agentconsole.label.uploadimage": "Carregar imagem",
  "ccmesaging.chat.agentconsole.title.release": "Liberação",
  "ccmesaging.chat.cobrowse.accept": "Aceitar",
  "ccmesaging.chat.cobrowse.agentMax": "O agente está colaborando atualmente na página da web, tente novamente",
  "ccmesaging.chat.cobrowse.isAcceptWeb": "Aceitar o convite para colaboração na Web?",
  "ccmesaging.chat.cobrowse.isAcceptWebMax": "O número máximo de conexões de colaboração Web permitidas para o locatário atual foi excedido",
  "ccmesaging.chat.cobrowse.noWebSiteAuth": "O agente não tem permissão de colaboração na Web",
  "ccmesaging.chat.cobrowse.refused": "Recusa",
  "ccmesaging.chat.cobrowse.title": "Convite para colaboração na Web",
  "ccmesaging.chat.cobrowse.webSite": "Colaboração Web URL:",
  "ccmesaging.chat.cobrowse.webSiteButton": "Colaboração Web",
  "ccmesaging.chat.cobrowse.webSitePlaceholder": "Digite o URL de compartilhamento",
  "ccmessaging.address.protocol.check": "O protocolo de rede usado pelo endereço não é seguro. O uso deste protocolo pode trazer riscos de segurança.",
  "ccmessaging.channelconfig.algorithm": "Conecte-se com Algoritmos de Criptografia",
  "ccmessaging.channelconfig.email.freeTimeText": "Resposta automática durante o horário de trabalho",
  "ccmessaging.channelconfig.email.freeTimeText.tips": "Responder ao e-mail recebido do cliente durante o horário de trabalho após a abertura",
  "ccmessaging.channelconfig.email.reply.each.eamil": "Responder automaticamente a cada e-mail",
  "ccmessaging.channelconfig.email.selectAutoReply": "Modelo de email",
  "ccmessaging.channelconfig.email.selectFreeTimeText": "Modelo de email",
  "ccmessaging.channelconfig.email.worktime.autoReply": "Resposta automática durante o horário de trabalho",
  "ccmessaging.channelconfig.email.worktime.autoReply.tips": "Resposta ao e-mail do cliente recebido durante o horário de trabalho após a abertura",
  "ccmessaging.channelconfig.sms.autoReply": "Resposta automática",
  "ccmessaging.channelconfig.sms.autoReply.tips": "Uma resposta automática é eenviada depois que o agente é conectado com sucesso.",
  "ccmessaging.channelconfig.sms.freeTimeText": "Resposta de Dia Não ",
  "ccmessaging.channelconfig.sms.selectAutoReply": "Modelo de resposta automática SMS",
  "ccmessaging.channelconfig.sms.selectFreeTimeText": "Non-Working Day SMS Reply Template Útil",
  "ccmessaging.char.agentstatus.after.callends": "A prioridade da configura\u00E7\u00E3o de status do agente entra em vigor ap\u00F3s o t\u00E9rmino da chamada \u00E9 a seguinte: n\u00EDvel da organiza\u00E7\u00E3o > n\u00EDvel do locat\u00E1rio. Se esse par\u00E2metro for deixado em branco, a configura\u00E7\u00E3o n\u00E3o ter\u00E1 efeito e a configura\u00E7\u00E3o no n\u00EDvel do locat\u00E1rio ser\u00E1 usada.",
  "ccmessaging.char.call.Idle.state": "Estado ocioso",
  "ccmessaging.char.call.work.state": "estado do trabalho",
  "ccmessaging.char.channelconfig.duplicateCodeName": "O nome do canal já existe.",
  "ccmessaging.chat.agent.organizational.structure": "Estrutura organizacional",
  "ccmessaging.chat.agent.refreshFail": "Não atualize a página repetidamente",
  "ccmessaging.chat.agent.transferSessionFail": "Falha ao transferir a sessão",
  "ccmessaging.chat.agentconsole.accesschannel": "Canal de acesso",
  "ccmessaging.chat.agentconsole.agentStatus.error": "O agente offline não pode iniciar uma chamada.",
  "ccmessaging.chat.agentconsole.allResults": "Todos os resultados",
  "ccmessaging.chat.agentconsole.asyncTimeBeyond": "O envio falhou porque o intervalo de reatribuição off-line foi excedido.",
  "ccmessaging.chat.agentconsole.beyondLimitNum": "Número máximo de mensagens enviadas excedido.",
  "ccmessaging.chat.agentconsole.browserecord": "Histórico de navegação",
  "ccmessaging.chat.agentconsole.chat.close": "A sessão atual terminou.",
  "ccmessaging.chat.agentconsole.chat.userId": "ID de usuário",
  "ccmessaging.chat.agentconsole.chat.userSettings": "Configurações do usuário",
  "ccmessaging.chat.agentconsole.chatPhrase": "Frases",
  "ccmessaging.chat.agentconsole.cobrowse": "Colaboração Web",
  "ccmessaging.chat.agentconsole.contract.pdfUploadMaxSizeTip": "O tamanho do contrato não pode exceder {n}M.",
  "ccmessaging.chat.agentconsole.contract.statisticSigns": "Estatísticas de assinatura",
  "ccmessaging.chat.agentconsole.contract.statisticTotalSigns": "Estatística",
  "ccmessaging.chat.agentconsole.contract.statisticTotalSigns.failTip": "Falha ao coletar estatísticas sobre os itens de assinatura no contrato.",
  "ccmessaging.chat.agentconsole.contract.statisticTotalSigns.noSigns.failTip": "Ícone de localização da assinatura não encontrado.",
  "ccmessaging.chat.agentconsole.contract.statisticTotalSigns.noStaticSigns.failTip": "Por favor, conte os contratos com assinaturas primeiro.",
  "ccmessaging.chat.agentconsole.contract.upload.failTip": "Falha ao enviar contrato !",
  "ccmessaging.chat.agentconsole.contract.upload.noSelectSigns.failTip": "O item de assinatura não está selecionado para o contrato.",
  "ccmessaging.chat.agentconsole.contract.upload.sameContract.failTip": "O assinante tem um contrato não expirado.",
  "ccmessaging.chat.agentconsole.current": "Atual",
  "ccmessaging.chat.agentconsole.customerinformation": "Informações do cliente",
  "ccmessaging.chat.agentconsole.defaultOrgName": "organização não afiliada",
  "ccmessaging.chat.agentconsole.document": "Documentos",
  "ccmessaging.chat.agentconsole.emailNotSupport": "O canal de email não suporta mensagens multimídia.",
  "ccmessaging.chat.agentconsole.enter.total.number": "Insira o número total de assinaturas",
  "ccmessaging.chat.agentconsole.filterByCondition": "Critérios de filtro",
  "ccmessaging.chat.agentconsole.fivegNotSupport": "Canais 5G não suportam mensagens multimídia",
  "ccmessaging.chat.agentconsole.hisconversation": "Conversa histórica",
  "ccmessaging.chat.agentconsole.history": "História",
  "ccmessaging.chat.agentconsole.image": "Imagem",
  "ccmessaging.chat.agentconsole.information": "Informação",
  "ccmessaging.chat.agentconsole.intention": "Intenção",
  "ccmessaging.chat.agentconsole.IpAddress": "Endereço IP",
  "ccmessaging.chat.agentconsole.label.defaultwelcome": "Bem-vindo ao sistema de atendimento ao cliente online!",
  "ccmessaging.chat.agentconsole.label.endsession": "Deseja encerrar a sessão?",
  "ccmessaging.chat.agentconsole.label.filesFacebookNotsupport": "Facebook Messenger atualmente não suporta receber informações de localização",
  "ccmessaging.chat.agentconsole.label.filesTwitterNotsupport": "O X (Twitter) não suporta áudio, localização ou vídeo por mais de 30 segundos!",
  "ccmessaging.chat.agentconsole.label.filesWechatNotsupport": "O WeChat não suporta áudio com mais de 60 segundos de duração!",
  "ccmessaging.chat.agentconsole.label.messageRejected": "A mensagem foi rejeitada pelo X (Twitter) porque parece spam, use sinônimo ou tente novamente mais tarde:",
  "ccmessaging.chat.agentconsole.label.NoAudioDuration": "Não foi possível obter a duração do áudio. Por favor, faça o upload novamente!",
  "ccmessaging.chat.agentconsole.label.NoVideoDuration": "Falha ao obter a duração do vídeo, Carregue novamente!",
  "ccmessaging.chat.agentconsole.label.ongoingsession": "Não há nenhuma sessão em curso!",
  "ccmessaging.chat.agentconsole.label.ongoingwx": "O canal atual não pode enviar informações de localização!",
  "ccmessaging.chat.agentconsole.label.skillNoAgent": "O agente na fila de habilidades está off-line.",
  "ccmessaging.chat.agentconsole.loading": "carregar mais",
  "ccmessaging.chat.agentconsole.locate": "Localização",
  "ccmessaging.chat.agentconsole.mailDraft": "E-mail rascunho",
  "ccmessaging.chat.agentconsole.message.notworktime": "O agente está fora do horário de trabalho! ",
  "ccmessaging.chat.agentconsole.messageloading": "carregamento",
  "ccmessaging.chat.agentconsole.multimedia": "Multimídia",
  "ccmessaging.chat.agentconsole.multimediaid": "ID multimídia",
  "ccmessaging.chat.agentconsole.multimedianame": "Nome de multimídia",
  "ccmessaging.chat.agentconsole.myconversation": "Minha conversa",
  "ccmessaging.chat.agentconsole.nocontact": "Nenhum contato no momento",
  "ccmessaging.chat.agentconsole.noHiscontact": "Sem Sessões Históricas",
  "ccmessaging.chat.agentconsole.notSupport": "Este canal não suporta este tipo de mensagens multimídia ",
  "ccmessaging.chat.agentconsole.noUserArrive": "Nenhum usuário está disponível agora!",
  "ccmessaging.chat.agentconsole.novoEmail": "Novo email",
  "ccmessaging.chat.agentconsole.nowconversation": "Conversa atual",
  "ccmessaging.chat.agentconsole.only.pdf.supported": "Apenas arquivos PDF são suportados",
  "ccmessaging.chat.agentconsole.photo": "Fotografia",
  "ccmessaging.chat.agentconsole.promptConetnt.cannot.rollback": "A mensagem não pode ser retirada",
  "ccmessaging.chat.agentconsole.promptConetnt.ContentLengthExceeds": "Conteúdo de texto maior que o limite não é permitido!",
  "ccmessaging.chat.agentconsole.promptConetnt.ContentNull": "EmptyContent não é permitido!",
  "ccmessaging.chat.agentconsole.promptConetnt.delectMessageRecord": "Você excluiu um registro",
  "ccmessaging.chat.agentconsole.promptConetnt.onlyFiveG": "Mensagens de cartão 5G suportam apenas canais 5G!",
  "ccmessaging.chat.agentconsole.promptConetnt.onlyWebCard": "As mensagens de cartão da Web são suportadas apenas para o canal da Web!",
  "ccmessaging.chat.agentconsole.promptConetnt.operateFail": "Falha ao executar operações em mensagens que não foram enviadas com êxito",
  "ccmessaging.chat.agentconsole.promptConetnt.operateFailWithChannel": "Apenas mensagens da Web podem ser operadas",
  "ccmessaging.chat.agentconsole.promptConetnt.operateRollBackFail": "Mensagens enviadas por mais de dois minutos não podem ser retiradas",
  "ccmessaging.chat.agentconsole.promptConetnt.rollBackMessage": "Você retirou uma mensagem",
  "ccmessaging.chat.agentconsole.promptConetnt.userAlreadyInSession": "Falha ao iniciar atendimento: Este cliente já está sendo atendido por outro colaborador",
  "ccmessaging.chat.agentconsole.promptConetnt.userBusy": "Quando os assentos estiverem livres",
  "ccmessaging.chat.agentconsole.promptConetnt.userInfoNull": "UserInfomation obtido não correto, a sessão será desligada!",
  "ccmessaging.chat.agentconsole.promptConetnt.userIsTalking.saveDraftFailed": "Falha ao enviar o email porque o assinante está em uma chamada. O email não pode ser salvo como um rascunho.",
  "ccmessaging.chat.agentconsole.promptConetnt.userIsTalking.saveDraftSuccess": "Falha ao enviar o email porque o assinante está em uma chamada. email salvo como um rascunho.",
  "ccmessaging.chat.agentconsole.promptConetnt.userNoArrive": "Não há nenhum usuário conectado, ou nenhum usuário foi selecionado!",
  "ccmessaging.chat.agentconsole.promptConetnt.userOffline": "O usuário atual está offline!",
  "ccmessaging.chat.agentconsole.queuing": "Enfileiramento",
  "ccmessaging.chat.agentconsole.quoteReply": "Citar esta resposta",
  "ccmessaging.chat.agentconsole.recommendedCaseType": "Categoria de ordem de serviço recomendada",
  "ccmessaging.chat.agentconsole.replyEmail": "Responder email",
  "ccmessaging.chat.agentconsole.robotAssistant": "Assistente de robô",
  "ccmessaging.chat.agentconsole.select.contract.uploaded": "Selecione o documento a ser carregado",
  "ccmessaging.chat.agentconsole.select.mandatory.signature": "Selecione uma assinatura obrigatória",
  "ccmessaging.chat.agentconsole.selectCaseType": "Selecionando uma categoria de ordem de serviço",
  "ccmessaging.chat.agentconsole.send.contract": "Envio do contrato",
  "ccmessaging.chat.agentconsole.send.repeat": "Não clique repetidamente!",
  "ccmessaging.chat.agentconsole.sendEmail": "Enviar e-mail",
  "ccmessaging.chat.agentconsole.sendLocalFile": "Enviar arquivos locais",
  "ccmessaging.chat.agentconsole.sendmessage": "Enviar mensagem",
  "ccmessaging.chat.agentconsole.setHandledNumber": "Definir número manipulado",
  "ccmessaging.chat.agentconsole.signature.variable": "Assinatura{n}",
  "ccmessaging.chat.agentconsole.signInCallNum": "Número de chamadas de login atuais:",
  "ccmessaging.chat.agentconsole.smsTemplate": "Modelo de SMS",
  "ccmessaging.chat.agentconsole.software": "Ambiente de software",
  "ccmessaging.chat.agentconsole.spellcheck.correcting.not.finished": "Nem todos os seus erros de digitação foram corrigidos. Terminar o cheque?",
  "ccmessaging.chat.agentconsole.spellcheck.correcting.cancel.confirmation": "O conteúdo corrigido será descartado. Tem certeza de que deseja cancelar a verificação?",
  "ccmessaging.chat.agentconsole.spellcheck.correctly.writing": "Escrito Corretamente",
  "ccmessaging.chat.agentconsole.spellcheck.failed": "Falha na verificação ortográfica.",
  "ccmessaging.chat.agentconsole.spellcheck.language": "Língua",
  "ccmessaging.chat.agentconsole.spellcheck.next": "Próxima",
  "ccmessaging.chat.agentconsole.spellcheck.previous": "Anterior",
  "ccmessaging.chat.agentconsole.spellcheck.replace": "Substituir",
  "ccmessaging.chat.agentconsole.spellcheck.replaceall": "Substituir tudo",
  "ccmessaging.chat.agentconsole.spellcheck.sentence.length.failed": "O comprimento do texto a ser verificado excede o limite de 1024",
  "ccmessaging.chat.agentconsole.spellcheck.sentence.length.failed.front": "O comprimento do texto a ser verificado excede o limite de 1024",
  "ccmessaging.chat.agentconsole.spellcheck.sentence.no.context": "Não há texto na caixa de texto. Você não precisa verificar a ortografia.",
  "ccmessaging.chat.agentconsole.spellcheck.sentence.no.mistake": "Sem erros de ortografia",
  "ccmessaging.chat.agentconsole.spellcheck.title": "Teste de Feitiço",
  "ccmessaging.chat.agentconsole.spellcheck.wrongly.writing": "Escrito Incorretamente",
  "ccmessaging.chat.agentconsole.talkavg": "Duração média de chamada (s)",
  "ccmessaging.chat.agentconsole.talkNum": "Contagem de chamadas de hoje:",
  "ccmessaging.chat.agentconsole.thumbnail": "Miniatura",
  "ccmessaging.chat.agentconsole.total.number.signatures": "Número total de assinaturas",
  "ccmessaging.chat.agentconsole.upload": "Carregar arquivo",
  "ccmessaging.chat.agentconsole.uploadfail": "Falha no upload do arquivo",
  "ccmessaging.chat.agentconsole.userInputStatus": "Visualizar o estado de entrada do usuário.",
  "ccmessaging.chat.agentconsole.video": "Vídeo",
  "ccmessaging.chat.agentconsole.voice": "Voz",
  "ccmessaging.chat.agentconsole.waittime": "Tempo de espera",
  "ccmessaging.chat.async.asyncMsgNum": "Número de mensagens offline",
  "ccmessaging.chat.async.asyncMsgTrending": "Tendência de mensagens off-line",
  "ccmessaging.chat.async.asyncUserNum": "Número de usuários off-line",
  "ccmessaging.chat.async.asyncUserTrending": "Tendência do usuário off-line",
  "ccmessaging.chat.async.channelSelect": "Filtragem de canais",
  "ccmessaging.chat.async.requestError": "O pedido falhou.",
  "ccmessaging.chat.async.statistics": "Monitoramento de mensagens off-line",
  "ccmessaging.chat.async.statistics.asynChannelTotal": "Número total de canais off-line",
  "ccmessaging.chat.async.statistics.asynMsgTotal": "Número total de mensagens off-line",
  "ccmessaging.chat.async.statistics.asynUserTotal": "Número total de usuários off-line",
  "ccmessaging.chat.async.statistics.updateTime": "Tempo de atualização de dados",
  "ccmessaging.chat.call.end.state": "Estado do fim da chamada",
  "ccmessaging.chat.card": "Cartão",
  "ccmessaging.chat.edit.card": "Editar Cartão",
  "ccmessaging.chat.multimedia.selectTemplate": "Selecionar Modelo do",
  "ccmessaging.chat.card.template": "Modelo do Whatsapp",
  "ccmessaging.chat.channelconfig.accessToken": "Ficha de Acesso",
  "ccmessaging.chat.channelconfig.accessTokenSecret": "Segredo do Ficheiro de Acesso",
  "ccmessaging.chat.channelconfig.accountKeyRepeat": "A chave de conta é repetida no canal do WhatsApp",
  "ccmessaging.chat.channelconfig.add": "Novo",
  "ccmessaging.chat.channelconfig.addressValidate": "O valor deve estar no formato sip:Número{'@'}botplatform.rcs.chinamobile.com e deve conter menos de 100 caracteres.",
  "ccmessaging.chat.channelconfig.afterhalf": ", pode ser clicado para remover o navegador para interceptar)",
  "ccmessaging.chat.channelconfig.agentTimeout": "Personalizar intervalo de tempo limite para nenhuma resposta do agente e solicitar reatribuição do agente",
  "ccmessaging.chat.channelconfig.agentTimeout.emailTimeOutTips": "Personalizar intervalo de tempo limite para resposta sem agente",
  "ccmessaging.chat.channelconfig.agentWorkTime": "Tempo de trabalho do agente",
  "ccmessaging.chat.channelconfig.alphavalidateFailed": "Caractere inválido. O valor de entrada só pode consistir em letras, dígitos ou sublinhados (o primeiro caractere só pode ser uma letra ou sublinhado)",
  "ccmessaging.chat.channelconfig.apifabric.ak": "Chave da APP",
  "ccmessaging.chat.channelconfig.apifabric.sk": "Segredo APP",
  "ccmessaging.chat.channelconfig.apiKey": "Chave API",
  "ccmessaging.chat.channelconfig.apiKeySecret": "Segredo da Chave da API",
  "ccmessaging.chat.channelconfig.appId": "App Id",
  "ccmessaging.chat.channelconfig.appSecret": "Chave App",
  "ccmessaging.chat.channelconfig.assertionSigningKey": "Chave de assinatura Asserção",
  "ccmessaging.chat.channelconfig.assistantAccessCode": "Código de acesso do assistente",
  "ccmessaging.chat.channelconfig.assistantSet": "Configuração do assistente do robô",
  "ccmessaging.chat.channelconfig.asyncFlag": "Mensagens off-line",
  "ccmessaging.chat.channelconfig.asyncTip": "Se esta função estiver desativada, as mensagens off-line existentes serão perdidas.",
  "ccmessaging.chat.channelconfig.asyncTime": "Limite de tempo para roteamento de mensagens off-line",
  "ccmessaging.chat.channelconfig.auth.duplicateAuth": "A autorização de conta pública já existe. Não é necessário configurá-la novamente.",
  "ccmessaging.chat.channelconfig.auth.relation.timeout": "A autorização pode ser inválida. Por favor, use o WeChat para verificar novamente a autorização ",
  "ccmessaging.chat.channelconfig.beforhalf": `Você pode clicar no botão "Tente-o" para ver o efeito de integração. (Se a URL estiver correta e aparecer um sinalizador de intercepção no canto superior direito do navegador`,
  "ccmessaging.chat.channelconfig.bsp": "Provedor de solução comercial",
  "ccmessaging.chat.channelconfig.cert.empty": "O certificado não pode estar vazio quando o endereço de push usa o protocolo HTTPS.",
  "ccmessaging.chat.channelconfig.Certification": "método de verificação",
  "ccmessaging.chat.channelconfig.changeLogo": "Change Avatar",
  "ccmessaging.chat.channelconfig.channelID": "ID do Canal",
  "ccmessaging.chat.channelconfig.channelSecret": "Tecla do canal",
  "ccmessaging.chat.channelconfig.chatbotAddress": "Endere�o do Chatbot",
  "ccmessaging.chat.channelconfig.checkAgentTimeOutInterval": "O intervalo de tempo limite para nenhuma resposta do agente não pode ser maior do que o intervalo de prompt para nenhuma resposta.",
  "ccmessaging.chat.channelconfig.checkEncodingAESKey": "comprimento da chave é 43 e pode conter apenas A-Z,a-z,0-9",
  "ccmessaging.chat.channelconfig.checkmessage": `Não pode conter os caracteres especiais <>'"/\\`,
  "ccmessaging.chat.channelconfig.checkNickName": `Não pode conter os caracteres especiais +~!{'@'}#$%&*()/=+{'{'}{'}'};:'\\",<.>?`,
  "ccmessaging.chat.channelconfig.checkName": "O nome não pode ser o sistema de palavras, etc",
  "ccmessaging.chat.channelconfig.checkNumber": "O valor é inválido.",
  "ccmessaging.chat.channelconfig.checkSocialChatNum": "O valor não pode ser decimal.",
  "ccmessaging.chat.channelconfig.checkWhatsAppBaseUrl": "A URL é inválida!",
  "ccmessaging.chat.channelconfig.checkWhatsAppPhoneNum": "Número de telemóvel inválido",
  "ccmessaging.chat.channelconfig.clicktocall.empty": "A fila de habilidades ou o código de acesso IVR não pode estar vazia!",
  "ccmessaging.chat.channelconfig.clickToCallSkillList": "Fila de habilidades para Clicar para chamar",
  "ccmessaging.chat.channelconfig.close": "Fechar",
  "ccmessaging.chat.channelconfig.codeName": "Código de acesso do canal",
  "ccmessaging.chat.channelconfig.defaultReply": "Resposta padrão",
  "ccmessaging.chat.channelconfig.delete": "Excluir",
  "ccmessaging.chat.channelconfig.delete.exist.asyncMsg": "Falha ao excluir o canal [{n}]. Tente novamente após a distribuição de mensagens off-line",
  "ccmessaging.chat.channelconfig.delete.exist.asyncUser": "Falha ao excluir o canal [{n}]. Tente novamente depois de atribuí-lo ao usuário off-line",
  "ccmessaging.chat.channelconfig.delete.exist.session": "Falha ao excluir o canal [{n}]. Tente novamente após o término da sessão",
  "ccmessaging.chat.channelconfig.edit": "Operação",
  "ccmessaging.chat.channelconfig.email.bccLimit": "Máximo de destinatários em Cco",
  "ccmessaging.chat.channelconfig.email.ccLimit": "Máximo de destinatários em Cc",
  "ccmessaging.chat.channelconfig.email.number": "Configuração de quantidade de endereço de e-mail",
  "ccmessaging.chat.channelconfig.email.personal": "Responda com e-mail pessoal",
  "ccmessaging.chat.channelconfig.email.placeholder": "1 - 100, opcional",
  "ccmessaging.chat.channelconfig.email.range": "Insira um número inteiro positivo variando de 1 a 100",
  "ccmessaging.chat.channelconfig.email.address.limit": "do",
  "ccmessaging.chat.channelconfig.email.receiverLimit": "Máximo de destinatários",
  "ccmessaging.chat.channelconfig.email.selectSignature": "Selecione uma assinatura de e-mail.",
  "ccmessaging.chat.channelconfig.email.signature": "Assinatura de e-mail",
  "ccmessaging.chat.channelconfig.email.signature.desc": "Descrição da Assinatura de E-mail",
  "ccmessaging.chat.channelconfig.email.signature.name": "Nome da assinatura de e-mail",
  "ccmessaging.chat.channelconfig.email.totalEmailNumber": "O número total de endereços de e-mail do destinatário, do destinatário da cópia carbono e do destinatário secreto não pode exceder 100",
  "ccmessaging.chat.channelconfig.emailRepeate": "O gateway de recebimento de email foi usado. Vá para a página de configuração de roteamento de mensagens para visualizar o gateway de recebimento de email.",
  "ccmessaging.chat.channelconfig.endchat": "Deseja encerrar a sessão?",
  "ccmessaging.chat.channelconfig.endOfSession": "Término da sessão devido à falta de resposta do cliente",
  "ccmessaging.chat.channelconfig.envName": "Rótulo do ambiente de desenvolvimento",
  "ccmessaging.chat.channelconfig.envNameValidate": "O valor deve ser uma sequência de caracteres inferior ou igual a 24 caracteres e pode conter apenas letras e dígitos.",
  "ccmessaging.chat.channelconfig.facebookBackground": "Facebook",
  "ccmessaging.chat.channelconfig.fiveGBackground": "CSP de mensagem 5G",
  "ccmessaging.chat.channelconfig.gender": "Gênero",
  "ccmessaging.chat.channelconfig.guestTimeout": "Período de tempo limite personalizado para falta de resposta do cliente e mensagem de término de sessão. Se essa função estiver desativada, o período de tempo limite padrão será de 20 minutos.",
  "ccmessaging.chat.channelconfig.guestTimeout.timeOutTips": "Personalizar intervalo de tempo limite para nenhuma resposta do cliente (Se esta alternância estiver desativada, o intervalo de tempo limite padrão será de {n} minutos.)",
  "ccmessaging.chat.channelconfig.hostingMethod": "Método de hospedagem",
  "ccmessaging.chat.channelconfig.hour": "Hora",
  "ccmessaging.chat.channelconfig.identityCertificate": "Certificado de identidade",
  "ccmessaging.chat.channelconfig.importAvatarSize": "O tamanho da imagem de avatar excede 6 MB.",
  "ccmessaging.chat.channelconfig.importAvatartype": "Tipo de imagem de avatar incorreto.",
  "ccmessaging.chat.channelconfig.infobipPassword": "Senha do Infobip",
  "ccmessaging.chat.channelconfig.infobipUserName": "Nome do usário do infobip",
  "ccmessaging.chat.channelconfig.infoConfiguration": "Configuração de informações",
  "ccmessaging.chat.channelconfig.keyEscrow": "Chave",
  "ccmessaging.chat.channelconfig.KeyValidate": "Apenas letras, números e 11 caracteres especiais _~!{'@'}#$%^&*- qualquer composição",
  "ccmessaging.chat.channelconfig.whatsappNameValidate": "Apenas letras, números e 12 caracteres especiais _~!{'@'}#$%^&*-. qualquer composição",
  "ccmessaging.chat.channelconfig.lastagent.time": "Intervalo de tempo",
  "ccmessaging.chat.channelconfig.lastModifyTime": "Hora da última modificação",
  "ccmessaging.chat.channelconfig.logo": "Imagem de perfil",
  "ccmessaging.chat.channelconfig.messagePushUrl.not.configure.trustList": "O endereço não está na lista de confiança. Entre em contato com o administrador do sistema.",
  "ccmessaging.chat.channelconfig.hrefUrl.not.configure.trustList": "O endereço do hiperlink não está na lista confiável. Entre em contato com o administrador do sistema.",
  "ccmessaging.chat.channelconfig.minute": "Minuto",
  "ccmessaging.chat.channelconfig.name": "Nome",
  "ccmessaging.chat.channelconfig.nochatonline": "Não há nenhuma sessão em curso!",
  "ccmessaging.chat.channelconfig.noLimit": "Sem limite",
  "ccmessaging.chat.channelconfig.NonworkingTimeReminder": "Notificação de Tempo Não Útil",
  "ccmessaging.chat.channelconfig.nonWorkTimeDay": "Dia não útil",
  "ccmessaging.chat.channelconfig.noReplyIntervalTime": "Período de tempo limite de resposta do cliente chamado",
  "ccmessaging.chat.channelconfig.noReplyIntervalTimeValidate": "O Intervalo para Cliente Sem Resposta não pode ser maior do que o Intervalo para Cliente Sem Notificação de Resposta.",
  "ccmessaging.chat.channelconfig.noReplyTime": "Limite de tempo de chamada de saída do agente",
  "ccmessaging.chat.channelconfig.offBeginTime": "Hora de início",
  "ccmessaging.chat.channelconfig.offLastAgentType": "Tipo de Último Agente",
  "ccmessaging.chat.channelconfig.offTimeRange": "Intervalo de tempo",
  "ccmessaging.chat.channelconfig.offToday": "Hoje",
  "ccmessaging.chat.channelconfig.onlyContainSubType": "Exibir apenas filas de habilidades do tipo de canal quando um agente transfere uma sessão.",
  "ccmessaging.chat.channelconfig.orgConfiguration": "Configuração da organização",
  "ccmessaging.chat.channelconfig.pageId": "ID da página",
  "ccmessaging.chat.channelconfig.personalSetting": "Configurações personalizadas",
  "ccmessaging.chat.channelconfig.phoneNumber": "Número de telefone",
  "ccmessaging.chat.channelconfig.pleaseSelect": "Selecione...",
  "ccmessaging.chat.channelconfig.PreferredLastAgent": "Modo Último Agente",
  "ccmessaging.chat.channelconfig.Previous": "Anterior",
  "ccmessaging.chat.channelconfig.privateKeyValidate": "É necessário estar no formato JSON. Os valores de chave no JSON só podem ser formados por letras, números e 11 caracteres especiais _~!{'@'}#$%^&*- qualquer composição",
  "ccmessaging.chat.channelconfig.publicConfiguration": "Configurações comuns",
  "ccmessaging.chat.channelconfig.pushMessageUrlValid": "Falha ao salvar a configuração do canal. Verifique se o endereço de envio de mensagem ou certificado é válido.",
  "ccmessaging.chat.channelconfig.qryaddressconfigfailed": "Falha ao consultar a regra de verificação do chatbotAddress do canal 5G. A regra de verificação não está configurada.",
  "ccmessaging.chat.channelconfig.queryType": "Modo de seleção",
  "ccmessaging.chat.channelconfig.queueReminder": "Lembrete de fila",
  "ccmessaging.chat.channelconfig.queueReminderContent": "Conteúdo do lembrete de fila",
  "ccmessaging.chat.channelconfig.queueReminderInterval": "Intervalo de lembrete de fila",
  "ccmessaging.chat.channelconfig.reCreateScenario": "Gerar",
  "ccmessaging.chat.channelconfig.refreshAccessToken": "Falha ao atualizar o arquivo.",
  "ccmessaging.chat.channelconfig.robotAccessCode": "Código de acesso do chatbot",
  "ccmessaging.chat.channelconfig.robotApplicationKey": "Chave do aplicativo do chatbot",
  "ccmessaging.chat.channelconfig.robotSet": "Configuração do chatbot",
  "ccmessaging.chat.channelconfig.routeType": "Modo de roteamento",
  "ccmessaging.chat.channelconfig.routeTypeSkill": "Roteamento de fila de habilidades",
  "ccmessaging.chat.channelconfig.saveAndToNext": "Salvar e prossiga para a próxima etapa",
  "ccmessaging.chat.channelconfig.savechannelconfigfailed": "Falha ao salvar a configuração do canal!",
  "ccmessaging.chat.channelconfig.scanCodeHosting": "Código QR",
  "ccmessaging.chat.channelconfig.scenario.status.existent": "chave de cenário:Sim",
  "ccmessaging.chat.channelconfig.scenario.status.noExistent": "chave de cenário:NÃO",
  "ccmessaging.chat.channelconfig.select.algorithm": "Selecione um Algoritmo de Criptografia",
  "ccmessaging.chat.channelconfig.select.algorithm.configtips": "Certifique-se de que os algoritmos de criptografia configurados para o canal são consistentes com a plataforma",
  "ccmessaging.chat.channelconfig.select.algorithm.safetips": "O algoritmo não é forte o suficiente , se a plataforma suporta a escolha de um algoritmo de criptografia mais forte",
  "ccmessaging.chat.channelconfig.selectAccessChannel": "Selecionar Canal de Acesso",
  "ccmessaging.chat.channelconfig.selectConfigurationMethod": "Escolher método de configuração",
  "ccmessaging.chat.channelconfig.selectRobot": "Selecionar bot",
  "ccmessaging.chat.channelconfig.selectRobotAssistant": "Selecionar assistente do robô",
  "ccmessaging.chat.channelconfig.sessionTransferConfig": "Transferência de Sessão",
  "ccmessaging.chat.channelconfig.silentAgentDelete": "Tem certeza de que deseja excluir a configuração de agente silencioso de voz selecionada?",
  "ccmessaging.chat.channelconfig.silentAgentSelect": "Selecione a configuração do agente mudo de voz a ser excluída.",
  "ccmessaging.chat.channelconfig.silentAgentSkillList": "Fila de habilidades do agente silencioso",
  "ccmessaging.chat.channelconfig.skill": "Lista de habilidades (código de acesso + código ext)",
  "ccmessaging.chat.channelconfig.skillList": "Fila de habilidades",
  "ccmessaging.chat.channelconfig.smsRepeate": "O gateway sms j%e1 est%e1 em uso . por favor , v%e1 para a p%e1gina de configura%e7%e3o de roteamento de mensagens",
  "ccmessaging.chat.channelconfig.startTime": "Hora de Início",
  "ccmessaging.chat.channelconfig.sub": "Para acessar rapidamente o Huawei Service Cloud, salve e publique o seguinte script antes de colá-lo no HTML do seu site. O portal do Huawei Service Cloud será exibido no canto inferior direito do seu site. Clique nele para acessar o workbench do agente para conversas em tempo real.",
  "ccmessaging.chat.channelconfig.submit": "Enviar",
  "ccmessaging.chat.channelconfig.submitVerification": "Instruções de integração",
  "ccmessaging.chat.channelconfig.successfulAccess": "Acesso bem-sucedido",
  "ccmessaging.chat.channelconfig.thirdPartySecretKey": "Chave de autenticação de terceiros",
  "ccmessaging.chat.channelconfig.time": "Intervalo de tempo (min)",
  "ccmessaging.chat.channelconfig.timeoutReply": "Resposta de tempo limite",
  "ccmessaging.chat.channelconfig.timerange": "Intervalo de tempo",
  "ccmessaging.chat.channelconfig.timeZone": "Fuso horário",
  "ccmessaging.chat.channelconfig.today": "Dia atual",
  "ccmessaging.chat.channelconfig.triggerText": "Solicitação de mensagem off-line",
  "ccmessaging.chat.channelconfig.trustCertificate": "Certificado de confiança",
  "ccmessaging.chat.channelconfig.trybutton": "Tente",
  "ccmessaging.chat.channelconfig.turnAI": "Conectar",
  "ccmessaging.chat.channelconfig.turnArtificial": "Palavra-chave para Transferir para Agente",
  "ccmessaging.chat.channelconfig.turnArtificial.tip": "separado por ponto-e-vírgula chinês ou inglês",
  "ccmessaging.chat.channelconfig.turnAssistant": "Conectar",
  "ccmessaging.chat.channelconfig.turnManualPrompt": "Solicitação para transferência para o agente",
  "ccmessaging.chat.channelconfig.twitterBackground": "Servidor Twitter",
  "ccmessaging.chat.channelconfig.twittertips": "Você pode acessar os seguintes sites. Clique em Enviar para autenticação do Twitter.",
  "ccmessaging.chat.channelconfig.update": "Atualizar",
  "ccmessaging.chat.channelconfig.updatecontactfailed": "Falha ao atualizar contato!",
  "ccmessaging.chat.channelconfig.voiceAccess": "IVR chamado",
  "ccmessaging.chat.channelconfig.voiceAgentSkill": "Fila de habilidades do agente",
  "ccmessaging.chat.channelconfig.webhookConfirmPassword": "Senha de confirmação do Webhook",
  "ccmessaging.chat.channelconfig.webHookException": "Falha ao obter tempo válido para a senha do webHook",
  "ccmessaging.chat.channelconfig.webhookPassword": "Palavra-passe do webhook",
  "ccmessaging.chat.channelconfig.webhookPassword.newPassword": "Nova palavra-passe do webhook",
  "ccmessaging.chat.channelconfig.webhookPassword.noValidPassword": "Nenhuma senha disponível",
  "ccmessaging.chat.channelconfig.webhookPassword.oldPassword": "Palavra-passe do período de bloqueio do webhook",
  "ccmessaging.chat.channelconfig.webhookPassword.validPeriod": "Válido até:",
  "ccmessaging.chat.channelconfig.webhookPassword.validPeriod.fifthDayTip": "Válido por menos de 15 dias",
  "ccmessaging.chat.channelconfig.webhookPassword.validPeriod.oneDayTip": "Válido por menos de um dia. Modifique!",
  "ccmessaging.chat.channelconfig.wechat.encryption.tip": "Observe que desabilitar a criptografia de mensagens pode representar riscos de segurança. Certifique-se de que as configurações sejam as mesmas da plataforma pública do WeChat.",
  "ccmessaging.chat.channelconfig.wechat.qr.label": "Ler código QR",
  "ccmessaging.chat.channelconfig.wechat.qr.label2": "Reautorização",
  "ccmessaging.chat.channelconfig.wechat.qr.label3": "Autorização bem-sucedida ",
  "ccmessaging.chat.channelconfig.weChatBackground": "Servidor do WeChat",
  "ccmessaging.chat.channelconfig.weChatMessageEncryptionSwitch": "Criptografia de mensagens do WeChat",
  "ccmessaging.chat.channelconfig.welcomeMessage": "Saudação",
  "ccmessaging.chat.channelconfig.whatsAppBackground": "Servidor do WhatsApp",
  "ccmessaging.chat.channelconfig.workTimeDay": "Dia útil",
  "ccmessaging.chat.channelconfig.worktimeerror": "Insira o período de tempo normal!",
  "ccmessaging.chat.chatcard.audio": "Áudio",
  "ccmessaging.chat.chatcard.button.addtabs": "Adicionar opções de cartão",
  "ccmessaging.chat.chatcard.cancel": "Cancelar",
  "ccmessaging.chat.chatcard.cardId": "ID do modelo",
  "ccmessaging.chat.chatcard.cardName": "Nome",
  "ccmessaging.chat.chatcard.cardNamePlaceholder": "Insira um nome de modelo de cartão.",
  "ccmessaging.chat.chatcard.cardTitle": "Título",
  "ccmessaging.chat.chatcard.cardTitlePlaceholder": "inserir o título do cartão.",
  "ccmessaging.chat.chatcard.create": "Salvar",
  "ccmessaging.chat.chatcard.delete": "Excluir",
  "ccmessaging.chat.chatcard.deleteFail": "Falha na exclusão.",
  "ccmessaging.chat.chatcard.deleteLimit": "O modelo foi referenciado e não pode ser excluído.",
  "ccmessaging.chat.chatcard.deleteSuccess": "Exclusão realizada com sucesso.",
  "ccmessaging.chat.chatcard.deleteTips": "Falha na verificação do parâmetro.",
  "ccmessaging.chat.chatcard.deleteWhatsAppTemplate.Fail": "Falha na exclusão porque o modelo já está referenciado por uma mensagem de modelo WhatsApp.",
  "ccmessaging.chat.chatcard.description": "Descrição",
  "ccmessaging.chat.chatcard.descriptionPlaceholder": "inserir a descrição do cartão.",
  "ccmessaging.chat.chatcard.details": "Detalhes do cartão",
  "ccmessaging.chat.chatcard.dial": "Discar",
  "ccmessaging.chat.chatcard.down": "Mover baixo",
  "ccmessaging.chat.chatcard.edit": "Editar",
  "ccmessaging.chat.chatcard.fail": "Falha",
  "ccmessaging.chat.chatcard.failTip": "Falha ao adicionar a opção. O número máximo foi atingido.",
  "ccmessaging.chat.chatcard.folded": "Esconder",
  "ccmessaging.chat.chatcard.id": "Insira uma ID de modelo.",
  "ccmessaging.chat.chatcard.image": "Imagem",
  "ccmessaging.chat.chatcard.input": "Insira um nome de modelo.",
  "ccmessaging.chat.chatcard.invalidUrl": "O endereço do link não começa com http://,https://",
  "ccmessaging.chat.chatcard.linking": "vinculação",
  "ccmessaging.chat.chatcard.mediaFileType": "Tipo de arquivo de mídia ",
  "ccmessaging.chat.chatcard.mediaHeight": "Altura da mídia",
  "ccmessaging.chat.chatcard.mediaType": "Tipo de mídia",
  "ccmessaging.chat.chatcard.mediumHeight": "Altura média",
  "ccmessaging.chat.chatcard.messageTemplateCreate": "Criar modelo de cartão",
  "ccmessaging.chat.chatcard.messageTemplateDelete": "Excluir modelo",
  "ccmessaging.chat.chatcard.messageTemplateModify": "Modificando um modelo",
  "ccmessaging.chat.chatcard.multimediaid": "ID de multimídia",
  "ccmessaging.chat.chatcard.multiMediaId": "Recurso de mídia",
  "ccmessaging.chat.chatcard.multimedianame": "Nome de multimídia",
  "ccmessaging.chat.chatcard.name": "Inserir um nome.",
  "ccmessaging.chat.chatcard.NoneTitle": "Sem título",
  "ccmessaging.chat.chatcard.operate": "Operação",
  "ccmessaging.chat.chatcard.queryTemplateFail": "Falha ao consultar o modelo",
  "ccmessaging.chat.chatcard.replay": "reprodução",
  "ccmessaging.chat.chatcard.saveFail": "Salvar falha",
  "ccmessaging.chat.chatcard.saveSuccess": "Salvo com sucesso",
  "ccmessaging.chat.chatcard.selectMultiMediaId": "Selecione o recurso multimídia correspondente.",
  "ccmessaging.chat.chatcard.selectMultiMediaTips": "Selecione recursos multimídia.",
  "ccmessaging.chat.chatcard.closeDialog": "Tem certeza de que deseja fechar esta caixa de diálogo?",
  "ccmessaging.chat.chatcard.shortHeight": "Altura curta",
  "ccmessaging.chat.chatcard.strcontentTip": "Solicitações HTTP têm riscos de segurança.",
  "ccmessaging.chat.chatcard.success": "Sucesso",
  "ccmessaging.chat.chatcard.tabs": "Opções de cartão",
  "ccmessaging.chat.chatcard.tabs.displaytext": "Texto de exibição do cartão",
  "ccmessaging.chat.chatcard.tabs.selectcontent": "selecionar conteúdo",
  "ccmessaging.chat.chatcard.tabs.selectType": "Tipo de opção",
  "ccmessaging.chat.chatcard.tabsname": "Nome da opção do cartão",
  "ccmessaging.chat.chatcard.tallHeight": "Altura alta",
  "ccmessaging.chat.chatcard.up": "Mover cima",
  "ccmessaging.chat.chatcard.video": "Vídeo",
  "ccmessaging.chat.chatcard.viewTips": "O modelo de cartão foi excluído e não pode ser visualizado.",
  "ccmessaging.chat.chatcard.whatsappTemplateCreate": "Criar modelo do WhatsApp",
  "ccmessaging.chat.chatcard.willdeletechatcard": "Tem certeza de que deseja excluir este cartão?",
  "ccmessaging.chat.chatcard.willdeletechatTemplate": "Tem certeza de que deseja excluir o modelo do WhatsApp?",
  "ccmessaging.chat.chatcard.nameExist": "O nome já existe. Insira outro.",
  "ccmessaging.chat.chatconfig.add": "Novo",
  "ccmessaging.chat.chatconfig.channelAccessCode": "Código de Acesso ao Canal",
  "ccmessaging.chat.chatconfig.channelOccupied": "O canal já está ocupado.",
  "ccmessaging.chat.chatconfig.channelType": "Tipo de canal",
  "ccmessaging.chat.chatconfig.configurationId": "ID de configuração",
  "ccmessaging.chat.chatconfig.delete": "Excluir",
  "ccmessaging.chat.chatconfig.deletechannelAccessCode": "Excluir configuração de canal",
  "ccmessaging.chat.chatconfig.deletefail": "Falha na exclusão.",
  "ccmessaging.chat.chatconfig.deletesuccess": "Excluído com sucesso",
  "ccmessaging.chat.chatconfig.idisnull": "A ID está vazia.",
  "ccmessaging.chat.chatconfig.inquire": "Pesquisar",
  "ccmessaging.chat.chatconfig.modify": "Modificar",
  "ccmessaging.chat.chatconfig.operate": "Operação",
  "ccmessaging.chat.chatconfig.refresh": "Atualizar",
  "ccmessaging.chat.chatconfig.reset": "Redefinir",
  "ccmessaging.chat.chatconfig.rollBack": "Retrair",
  "ccmessaging.chat.chatconfig.selectchannelAccessCode": "Selecione a configuração de canal.",
  "ccmessaging.chat.chatconfig.skillId": "Vincular Fila de habilidades",
  "ccmessaging.chat.chatconfig.willdeletechannelAccessCode": "Tem certeza de que deseja excluir a configuração de canal selecionada?",
  "ccmessaging.chat.chatemail.cancelhandled": "Cancelamento Processado",
  "ccmessaging.chat.chatemail.delete": "Excluir",
  "ccmessaging.chat.chatemail.details": "Exibir",
  "ccmessaging.chat.chatemail.forwarded": "Encaminhado",
  "ccmessaging.chat.chatemail.forwardedTime": "Encaminhado em",
  "ccmessaging.chat.chatemail.handled": "Processado",
  "ccmessaging.chat.chatemail.maxNumberImages": "O número de imagens locais carregadas atingiu o limite superior de 20.",
  "ccmessaging.chat.chatemail.saveDraft": "Salvar como rascunho",
  "ccmessaging.chat.chatemail.sender": "Remetente:",
  "ccmessaging.chat.chatholiday.calendar.preview": "Visualização do calendário",
  "ccmessaging.chat.chatholiday.checkHolidaySize": "Existem muitas entradas, exclua as entradas extras primeiro",
  "ccmessaging.chat.chatholiday.date": "Data",
  "ccmessaging.chat.chatholiday.dateConfiguration": "Configuração da data",
  "ccmessaging.chat.chatholiday.delete": "Excluir",
  "ccmessaging.chat.chatholiday.description": "Descrição",
  "ccmessaging.chat.chatholiday.designation.alert": "A data final deve ser posterior à data inicial",
  "ccmessaging.chat.chatholiday.designation.cancel": "Cancelar",
  "ccmessaging.chat.chatholiday.designation.complete": "Concluído",
  "ccmessaging.chat.chatholiday.designation.days": "Vários dias",
  "ccmessaging.chat.chatholiday.designation.end": "Data de término",
  "ccmessaging.chat.chatholiday.designation.norepeating": "Não repetido",
  "ccmessaging.chat.chatholiday.designation.oneday": "1 dia",
  "ccmessaging.chat.chatholiday.designation.repeating": "Repetido todos os anos",
  "ccmessaging.chat.chatholiday.designation.selectrule": "A data selecionada não pode ser a mesma que a data selecionada",
  "ccmessaging.chat.chatholiday.designation.show": "Anualmente",
  "ccmessaging.chat.chatholiday.designation.start": "Data Inicial",
  "ccmessaging.chat.chatholiday.designation.type": "Tipo de data",
  "ccmessaging.chat.chatholiday.friday": "Sexta",
  "ccmessaging.chat.chatholiday.holidays.explanation": "Você pode especificar certos dias como dias não úteis, por exemplo, 13 de setembro de 2019 (Sexta-feira, Festival Chinês de Meio Outono).",
  "ccmessaging.chat.chatholiday.isDelete.otherdays": "Tem certeza de que deseja excluir a data?",
  "ccmessaging.chat.chatholiday.isDelete.specified": "Tem certeza de que deseja excluir a data?",
  "ccmessaging.chat.chatholiday.monday": "Segunda",
  "ccmessaging.chat.chatholiday.new": "Novo",
  "ccmessaging.chat.chatholiday.nonWorkbench": "Configuração de dias não úteis",
  "ccmessaging.chat.chatholiday.noworking": "Dia não útil",
  "ccmessaging.chat.chatholiday.operate": "Operar",
  "ccmessaging.chat.chatholiday.other.prompt": "A data selecionada é a mesma que uma data existente",
  "ccmessaging.chat.chatholiday.otherdays.exception": "Data de exceção",
  "ccmessaging.chat.chatholiday.otherdays.explanation": "Você pode selecionar determinados dias como dias úteis. O intervalo máximo de tempo é de um ano. Por exemplo:você pode selecionar 13 de julho de 2019 (sábado, horas extras temporárias necessárias) como um dia útil.",
  "ccmessaging.chat.chatholiday.repeat": "Regras de repetição",
  "ccmessaging.chat.chatholiday.saturday": "Sábado",
  "ccmessaging.chat.chatholiday.savechatholidayfailed": "Falha ao salvar a configuração de férias",
  "ccmessaging.chat.chatholiday.specified.date": "Data especificada",
  "ccmessaging.chat.chatholiday.sunday": "Domingo",
  "ccmessaging.chat.chatholiday.thursday": "Quinta",
  "ccmessaging.chat.chatholiday.tuesday": "Terça",
  "ccmessaging.chat.chatholiday.wednesday": "Quarta",
  "ccmessaging.chat.chatholiday.weekend.explanation": "Você pode definir certos dias em uma semana como dias não úteis, por exemplo, sábado e domingo.",
  "ccmessaging.chat.chatholiday.weekly": "Semanalmente",
  "ccmessaging.chat.chatholiday.workbench": "Configuração da Workbench",
  "ccmessaging.chat.chatholiday.working.day": "Dia útil",
  "ccmessaging.chat.chatholiday.tip.choosedate": "Selecione uma data",
  "ccmessaging.chat.chatholiday.tip.descinvalid": "A descrição não pode ter mais de 20 caracteres",
  "ccmessaging.chat.chatmanage.agentIntervalTime": "Período de tempo limite de resposta do agente",
  "ccmessaging.chat.chatmanage.agentSessionEndText": "Prompt de reatribuição de agente",
  "ccmessaging.chat.chatmanage.autoEndSession": "Se o convidado não responder dentro do tempo especificado, a caixa de diálogo será encerrada automaticamente.",
  "ccmessaging.chat.chatmanage.autoEndSessionMorethan": "A descrição do intervalo em que o cliente não tem resposta não pode exceder 100 caracteres.",
  "ccmessaging.chat.chatmanage.autoEndSessionnotnull": "A descrição do intervalo de não resposta do cliente não pode estar vazia",
  "ccmessaging.chat.chatmanage.autoEndSessionNum": "O intervalo do lembrete de não resposta do cliente deve ser um número puro",
  "ccmessaging.chat.chatmanage.autoEndSessionRange": "O intervalo numérico entre lembretes de não resposta do cliente deve estar entre 0 e 60",
  "ccmessaging.chat.chatmanage.cancel": "Cancelar",
  "ccmessaging.chat.chatmanage.edit": "Editar",
  "ccmessaging.chat.chatmanage.endTime": "Hora final",
  "ccmessaging.chat.chatmanage.endTimeNum": "A hora final deve ser um número puro",
  "ccmessaging.chat.chatmanage.endTimeRange": "O intervalo numérico da hora final deve estar entre 0 e 24",
  "ccmessaging.chat.chatmanage.fail": "Falha",
  "ccmessaging.chat.chatmanage.freeTimeText": "Notificação de Tempo Não Útil",
  "ccmessaging.chat.chatmanage.freeTimeTextMorethan": "A descrição da notificação de tempo não útil não pode conter mais de 100 caracteres.",
  "ccmessaging.chat.chatmanage.freeTimeTextnotnull": "A descrição da mensagem de lembrete fora do horário de trabalho não pode estar em branco",
  "ccmessaging.chat.chatmanage.intervalTime": "Período de tempo limite de resposta do cliente",
  "ccmessaging.chat.chatmanage.save": "Salvar",
  "ccmessaging.chat.chatmanage.savefailed": "Falha ao salvar.",
  "ccmessaging.chat.chatmanage.savesuccess": "Salvado com sucesso.",
  "ccmessaging.chat.chatmanage.sessionEndText": "Conclusão",
  "ccmessaging.chat.chatmanage.sessionEndTextMorethan": "A descrição da conclusão da caixa de diálogo não pode conter mais de 100 caracteres.",
  "ccmessaging.chat.chatmanage.sessionEndTextnotnull": "A descrição da frase de encerramento da sessão não pode estar vazia",
  "ccmessaging.chat.chatmanage.specialCharacters": "Contém caracteres especiais. Por favor, modifique-os",
  "ccmessaging.chat.chatmanage.startTime": "Hora de início",
  "ccmessaging.chat.chatmanage.startTimeNum": "A hora de início deve ser um número puro",
  "ccmessaging.chat.chatmanage.startTimeRange": "A hora de início deve estar no intervalo numérico de 0 a 24",
  "ccmessaging.chat.chatmanage.success": "Sucesso",
  "ccmessaging.chat.chatmanage.useRobot": "Conectar-se ao Chatbot",
  "ccmessaging.chat.chatmanage.workTime": "Tempo de Trabalho do Agente (no formato de 24 horas)",
  "ccmessaging.chat.chatmanage.workTimeMorethan": "A descrição do tempo de trabalho não pode conter mais de 100 caracteres.",
  "ccmessaging.chat.chatpersonalization.add": "Adicionar",
  "ccmessaging.chat.chatpersonalization.avatarerror": "Selecione uma imagem!",
  "ccmessaging.chat.chatpersonalization.cancel": "Cancelar",
  "ccmessaging.chat.chatpersonalization.changeAvatar": "Mudar Avatar",
  "ccmessaging.chat.chatpersonalization.concurrentAgentsNumber": "Clientes multimídia de serviços simultâneos de agentes",
  "ccmessaging.chat.chatpersonalization.concurrentNumberPriority": "Número de clientes multimídia de serviço simultâneo do agente Prioridade efetiva : nível do agente > nível da organização > nível do locatário . O valor 0 indica que este nível de configuração não tem efeito . Outros níveis de configuração serão usados",
  "ccmessaging.chat.chatpersonalization.delete": "Excluir",
  "ccmessaging.chat.chatpersonalization.edit": "Editar",
  "ccmessaging.chat.chatpersonalization.hour": "Hora",
  "ccmessaging.chat.chatpersonalization.MaximumConcurrent": "Máximo de clientes de serviço simultâneo",
  "ccmessaging.chat.chatpersonalization.nickname": "Apelido",
  "ccmessaging.chat.chatpersonalization.operating": "Operação",
  "ccmessaging.chat.chatpersonalization.prompt": "Solicitar",
  "ccmessaging.chat.chatpersonalization.save": "Salvar",
  "ccmessaging.chat.chatpersonalization.savefailed": "Falha ao salvar.",
  "ccmessaging.chat.chatpersonalization.saveSuccess": "Salvado com sucesso.",
  "ccmessaging.chat.chatpersonalization.specialStrValidateFailed": "O valor não pode conter caracteres especiais.",
  "ccmessaging.chat.chatpersonalization.tips1": "A configuração enviada não foi verificada!",
  "ccmessaging.chat.chatpersonalization.welcome": "Saudações",
  "ccmessaging.chat.chatpersonalization.welcomeLimit": "Até 4 mensagens de boas-vindas personalizadas",
  "ccmessaging.chat.chatpersonalization.welcomeText": "Saudações personalizadas",
  "ccmessaging.chat.chatpersonalization.welcometime": "Intervalo de tempo",
  "ccmessaging.chat.chatpersonalization.welcometimeerror": "Insira um segmento de tempo válido.",
  "ccmessaging.chat.chatpersonalization.welcometimeerror.nolist": "Por favor, defina uma saudação personalizada",
  "ccmessaging.chat.chatpersonalization.welcometimeerror.startGreaterThanEnd": "A hora de início da saudação personalizada não pode ser maior ou igual à hora de fim",
  "ccmessaging.chat.chatpersonalization.welcometimeerror.timeOverlap": "O período de tempo de saudações personalizadas não pode se sobrepor",
  "ccmessaging.chat.chinese.separator": ":",
  "ccmessaging.chat.clickCallClient.denial": "O link expirou. Se necessário entre em contato com o agente novamente para enviar o link.",
  "ccmessaging.chat.close": "segundos após fechar a página automaticamente",
  "ccmessaging.chat.connnect.offlineuser.failed": "Falha ao iniciar uma chamada off-line como agente.",
  "ccmessaging.chat.connnect.offlineuser.userId.beyondMaxLength": "UserId é mais de 128",
  "ccmessaging.chat.contact.callin": "Chamada de entrada",
  "ccmessaging.chat.contact.callinandcallout": "Chamada de entrada e saída",
  "ccmessaging.chat.contact.callout": "Saída de chamada",
  "ccmessaging.chat.email.AgentAccountName": "Nome da conta do agente",
  "ccmessaging.chat.email.agentNumber": "Número do agente",
  "ccmessaging.chat.email.autosave": "Suas edições foram salvas automaticamente no rascunho.",
  "ccmessaging.chat.email.button.advancedSearch": "Pesquisa avançada ",
  "ccmessaging.chat.email.button.batchAssignment": "Atribuição em lote ",
  "ccmessaging.chat.email.button.bulkMove": "Movimentação em massa ",
  "ccmessaging.chat.email.card.reply": "Responder",
  "ccmessaging.chat.email.channel.attendeeError": "O número de destinatários Cc excede o limite.",
  "ccmessaging.chat.email.channel.BccSenderError": "O número de destinatários BCC excede o limite.",
  "ccmessaging.chat.email.channel.ccandbcc.containchannelemail": "Cc e Bcc não podem ser endereços de e-mail públicos do canal",
  "ccmessaging.chat.email.channel.confdeletedraft": "Tem certeza de que deseja excluir o rascunho de email?",
  "ccmessaging.chat.email.channel.confdmodifydraft": "Tem certeza de que processou o email?",
  "ccmessaging.chat.email.channel.deletedraft": "Confirmar",
  "ccmessaging.chat.email.channel.deletedraftfail": "Falha ao excluir o rascunho de email.",
  "ccmessaging.chat.email.channel.deletedraftsuccess": "O rascunho do email foi excluído com sucesso.",
  "ccmessaging.chat.email.channel.draftOverMax": "O número de rascunhos de e-mail excedeu o limite máximo! ",
  "ccmessaging.chat.email.channel.emailaddressinvalid": "O endereço de email é inválido.",
  "ccmessaging.chat.email.channel.modifydraft": "Confirmar",
  "ccmessaging.chat.email.channel.modifydraftfail": "Falha na modificação.",
  "ccmessaging.chat.email.channel.modifydraftsuccess": "Modificação bem-sucedida.",
  "ccmessaging.chat.email.channel.paramisempty": "O parâmetro não pode estar vazio.",
  "ccmessaging.chat.email.channel.paramiserror": "O parâmetro é inválido.",
  "ccmessaging.chat.email.channel.receiveGateway": "Gateway de recebimento de e-mail",
  "ccmessaging.chat.email.channel.receivererror": "O destinatário não contém o usuário selecionado ",
  "ccmessaging.chat.email.channel.savedraftfail": "Falha ao salvar o email como rascunho.",
  "ccmessaging.chat.email.channel.savedraftsuccess": "O email é salvo como um rascunho com sucesso.",
  "ccmessaging.chat.email.channel.sendGateway": "Gateway de envio de e-mail",
  "ccmessaging.chat.email.channel.title.reply": "Resposta:",
  "ccmessaging.chat.email.check.containGatewayEmail": "E-mails não podem ser enviados para o endereço do canal que recebe o e-mail.",
  "ccmessaging.chat.email.check.contentOverMax": "O corpo da mensagem excedeu o limite de tamanho",
  "ccmessaging.chat.email.check.localImageNumberOverMax": "O número de imagens locais carregadas no corpo do e-mail excede o intervalo.",
  "ccmessaging.chat.email.check.localImageOverMax": "O tamanho da imagem local carregada no corpo do e-mail excede o intervalo.",
  "ccmessaging.chat.email.check.localImageType": "O formato de imagem a ser carregado não é suportado atualmente.",
  "ccmessaging.chat.email.check.uploadATTACHMENTPermissionAndFeature": "Você não tem permissão para carregar anexos ou a função está desativada.",
  "ccmessaging.chat.email.check.uploadImagePermissionAndFeature": "Você não tem permissão para carregar imagens locais ou a função está desativada.",
  "ccmessaging.chat.email.forward": "Encaminhar",
  "ccmessaging.chat.email.forward.prefix": "Encaminhar:",
  "ccmessaging.chat.email.localImage.confirm": "Sim",
  "ccmessaging.chat.email.localImage.height": "Altura",
  "ccmessaging.chat.email.localImage.lable": "Arquivo",
  "ccmessaging.chat.email.localImage.width": "Largura",
  "ccmessaging.chat.email.param.empty": "O título ou conteúdo do email não pode estar vazio.",
  "ccmessaging.chat.email.reply": "RE",
  "ccmessaging.chat.email.reply.separator": ":",
  "ccmessaging.chat.email.tips.addFailDuplicateName": "Falha ao adicionar: nome duplicado ",
  "ccmessaging.chat.email.tips.addFailOtherErrors": "Falha ao adicionar: outros erros ",
  "ccmessaging.chat.email.tips.addMaximumLimit": "Até 20 subdiretórios no mesmo nível ",
  "ccmessaging.chat.email.tips.addSuccess": "Adicionado com sucesso.",
  "ccmessaging.chat.email.tips.amendFailDuplicateName": "Falha na modificação: nome duplicado ",
  "ccmessaging.chat.email.tips.amendFailOtherErrors": "Falha na modificação: outros erros ",
  "ccmessaging.chat.email.tips.amendSuccess": "Modificado com sucesso ",
  "ccmessaging.chat.email.tips.deleteFailExistEmail": "Falha ao excluir: o e-mail existe no diretório atual ",
  "ccmessaging.chat.email.tips.deleteFailExistSub": "Falha ao excluir: existem subdiretórios no diretório atual ",
  "ccmessaging.chat.email.tips.deleteFailOtherErrors": "Falha ao excluir: outro erro ",
  "ccmessaging.chat.email.tips.deleteSuccess": "Excluído com sucesso ",
  "ccmessaging.chat.email.tips.isDeleteDirectory": "Deseja excluir o diretório atual? ",
  "ccmessaging.chat.emailmessage.abandoned": "abandonados",
  "ccmessaging.chat.emailmessage.agent": "Agente",
  "ccmessaging.chat.emailmessage.all": "Todos",
  "ccmessaging.chat.emailmessage.assigned": "Atribuído",
  "ccmessaging.chat.emailmessage.assignEmail.inputAgentName": "Informe o nome da conta do agente.",
  "ccmessaging.chat.emailmessage.assignEmail.queryAgentName": "Insira uma conta comercial.",
  "ccmessaging.chat.emailmessage.assignEmailFailed": "Falha ao atribuir o e-mail.",
  "ccmessaging.chat.emailmessage.assignEmailPartialSuccess": "Falha ao atribuir alguns e-mails.",
  "ccmessaging.chat.emailmessage.assignEmailSuccess": "O e-mail foi atribuído com sucesso.",
  "ccmessaging.chat.emailmessage.assigning": "Atribuir",
  "ccmessaging.chat.emailmessage.assignOtherEmailFailed": "O e-mail do remetente não pode ser atribuído porque está sendo tratado.",
  "ccmessaging.chat.emailmessage.assignStatus": "Status da Atribuição",
  "ccmessaging.chat.emailmessage.channel": "Resposta automática do canal",
  "ccmessaging.chat.emailmessage.channelAccessCode": "Código de acesso ao canal de e-mail",
  "ccmessaging.chat.emailmessage.disAssigned": "N?o atribuído",
  "ccmessaging.chat.emailmessage.fail.assign.AbandonedEmail.assigning.tip": "As mensagens descartadas não podem ser atribuídas.",
  "ccmessaging.chat.emailmessage.fail.assign.AbandonedEmail.moving.tip": "Mensagens descartadas não podem ser movidas.",
  "ccmessaging.chat.emailmessage.fail.assign.UnAssignedEmail.tip": "E-mails não atribudos não podem ser atribuídos.",
  "ccmessaging.chat.emailmessage.handler": "Manipulador",
  "ccmessaging.chat.emailmessage.handlerType": "Tipo do manipulador",
  "ccmessaging.chat.emailmessage.handleStatus": "Status de Manipulação",
  "ccmessaging.chat.emailmessage.moving": "Mover",
  "ccmessaging.chat.emailmessage.operation.noMoreThanOneDay": "O intervalo de tempo da consulta não pode exceder um dia.",
  "ccmessaging.chat.emailmessage.operation.noMoreThanSevenDay": "O intervalo de tempo da consulta não pode exceder 7 dias.",
  "ccmessaging.chat.emailmessage.receiver": "Destinatário",
  "ccmessaging.chat.emailmessage.robot": "robô",
  "ccmessaging.chat.emailmessage.sender": "Remetente",
  "ccmessaging.chat.emailmessage.sendStatus": "Enviando resultado",
  "ccmessaging.chat.emailmessage.sendTime": "Enviado em",
  "ccmessaging.chat.emailmessage.skillQueue": "Fila de habilidade",
  "ccmessaging.chat.emailmessage.title": "Email Title",
  "ccmessaging.chat.emailmessage.unassigned": "Para ser atribuído",
  "ccmessaging.chat.EMOTICONS.beyondFileSize": "O número de arquivos multimídia carregados é o tipo de erro ou excede o máximo : {n} KB!",
  "ccmessaging.chat.facebookpageconfig.add": "Adicionar página inicial do Facebook",
  "ccmessaging.chat.facebookpageconfig.config": "Configuração",
  "ccmessaging.chat.facebookpageconfig.deleteError": "Falha ao excluir as configurações da página inicial do Facebook.",
  "ccmessaging.chat.facebookpageconfig.deleteSuccess": "As configurações da página inicial do Facebook são excluídas com sucesso.",
  "ccmessaging.chat.facebookpageconfig.facebookconfig": "Configurações da página inicial do Facebook",
  "ccmessaging.chat.facebookpageconfig.idNotExist": "Os dados não existem.",
  "ccmessaging.chat.facebookpageconfig.isDeleteFacebookPage": "Tem certeza de que deseja excluir as configurações da página inicial do Facebook?",
  "ccmessaging.chat.facebookpageconfig.modify": "Editar página do Facebook",
  "ccmessaging.chat.facebookpageconfig.modifyError": "Falha ao modificar a página inicial do Facebook.",
  "ccmessaging.chat.facebookpageconfig.modifySuccess": "A página inicial do Facebook foi modificada com sucesso.",
  "ccmessaging.chat.facebookpageconfig.numoversize": "O número de homepages do Facebook excede o limite superior para este canal.",
  "ccmessaging.chat.facebookpageconfig.pageId": "ID da página",
  "ccmessaging.chat.facebookpageconfig.pageidexist": "A ID da página inicial do Facebook já existe.",
  "ccmessaging.chat.facebookpageconfig.saveError": "Falha ao adicionar a página inicial do Facebook.",
  "ccmessaging.chat.facebookpageconfig.saveSuccess": "A página inicial do Facebook foi adicionada com sucesso.",
  "ccmessaging.chat.facebookpageconfig.tokenSecret": "Segredo do Ficheiro",
  "ccmessaging.chat.fivegCard": "Cartão 5G",
  "ccmessaging.chat.ifame.param": "Parametro",
  "ccmessaging.chat.iframeagentconsole.mail": "envio por correio",
  "ccmessaging.chat.iframeagentconsole.mailCollapse": "Ocultar",
  "ccmessaging.chat.iframeagentconsole.mailExpand": "Detalhes",
  "ccmessaging.chat.label.accessCode": "Código de acesso",
  "ccmessaging.chat.label.extCode": "Código de extensão",
  "ccmessaging.chat.label.skill": "Fila de habilidades",
  "ccmessaging.chat.localFile.authFail": "Sem permissão de envio de arquivo local",
  "ccmessaging.chat.multi.card": "Placa rotativa 5G",
  "ccmessaging.chat.multi.card.add": "Selecionar cartão",
  "ccmessaging.chat.multi.card.id": "ID do cartão",
  "ccmessaging.chat.multi.card.num.error": "Adicione de 1 a 5 cartões individuais.",
  "ccmessaging.chat.multi.chatcard.alignment": "Modo de alinhamento da imagem",
  "ccmessaging.chat.multi.chatcard.alignment.left": "Alinhamento esquerdo",
  "ccmessaging.chat.multi.chatcard.alignment.right": "Alinhamento direito",
  "ccmessaging.chat.multi.chatcard.choose": "Seleção de cartão",
  "ccmessaging.chat.multi.chatcard.delete": "O modelo foi referenciado e não pode ser excluído. O cartão NVOD referenciado é:",
  "ccmessaging.chat.multi.chatcard.sort": "Direção do cartão",
  "ccmessaging.chat.multi.chatcard.sort.flow": "flutuadores",
  "ccmessaging.chat.multi.chatcard.sort.horizontal": "nivelamento",
  "ccmessaging.chat.multi.chatcard.sort.vertical": "vertical",
  "ccmessaging.chat.multi.chatcard.width": "Largura do cartão",
  "ccmessaging.chat.multi.chatcard.width.medium": "Largura média",
  "ccmessaging.chat.multi.chatcard.width.small": "Largura menor",
  "ccmessaging.chat.multi.media.resource.invalid": "Um cartão está referenciando recursos de mídia inválidos. Nome do cartão:",
  "ccmessaging.chat.multimedia.addCategory": "Adicionar categoria",
  "ccmessaging.chat.multimedia.audioManagement": "Biblioteca de áudio",
  "ccmessaging.chat.multimedia.audioUploadTipFormat": "O arquivo de áudio a ser carregado deve estar em formato MP3, AAC ou M4A, e o tamanho do arquivo não pode exceder {n} MB.",
  "ccmessaging.chat.multimedia.beyondFileSize": "O tamanho do ficheiro multimédia a enviar excede o limite máximo : {n} MB!",
  "ccmessaging.chat.multimedia.cancel": "Cancelar",
  "ccmessaging.chat.multimedia.cardManagement": "Modelo de cartão",
  "ccmessaging.chat.multimedia.categoryName": "Nome da categoria",
  "ccmessaging.chat.multimedia.chatPhraseManagement": "Biblioteca ChatPhrase",
  "ccmessaging.chat.multimedia.cobrowseSite": "Cobrowse Site",
  "ccmessaging.chat.multimedia.createRichText": "Criar Rich Text",
  "ccmessaging.chat.multimedia.delete": "Excluir",
  "ccmessaging.chat.multimedia.deleteLimit": "O recurso é referenciado pelo modelo de cartão {0} e não pode ser excluído.",
  "ccmessaging.chat.multimedia.deleteLimit.usedbyODFS": "Este recurso foi referenciado pelo IVR inteligente e não pode ser excluído",
  "ccmessaging.chat.multimedia.deleteLimit.whatsapp.templatemsg": "O recurso não pode ser excluído porque ele já está referenciado por uma mensagem de modelo WhatsApp.Mensagem do modelo:",
  "ccmessaging.chat.multimedia.deleteMultiMediaInfo": "Excluir estes dados multimídia",
  "ccmessaging.chat.multimedia.deleteMultiMediaInfoError": "Falha ao excluir dados multimídia!",
  "ccmessaging.chat.multimedia.deleteMultiMediaInfoSuccess": "Exclua dados multimídia com sucesso!",
  "ccmessaging.chat.multimedia.deleteMultiMediaType": "Excluir esta categoria multimídia",
  "ccmessaging.chat.multimedia.deleteMultiMediaTypeError": "Falha ao excluir categoria de biblioteca multimídia!",
  "ccmessaging.chat.multimedia.deleteMultiMediaTypeError.usedbyODFS": "Falha ao excluir a categoria da biblioteca multimídia! Existem recursos referenciados pelo IVR inteligente!",
  "ccmessaging.chat.multimedia.deleteMultiMediaTypeError.referenced.byCard": "Falha ao excluir a categoria multimídia porque alguns recursos já são referenciados por cartões.",
  "ccmessaging.chat.multimedia.deleteMultiMediaTypeError.referenced.byWhatsAppTemplateMsg": "Falha ao excluir a categoria multimídia porque alguns recursos já são referenciados por uma mensagem de modelo WhatsApp.",
  "ccmessaging.chat.multimedia.deleteMultiMediaTypeSuccess": "Exclua com sucesso a categoria multimídia!",
  "ccmessaging.chat.multimedia.description": "Descrição",
  "ccmessaging.chat.multimedia.descriptionContainsSpecialCharacters": "Descrição contém caracteres especiais",
  "ccmessaging.chat.multimedia.descriptionSuperLong": "Excedido número máximo de caracteres Descrição",
  "ccmessaging.chat.multimedia.documentManagement": "Biblioteca de documentos",
  "ccmessaging.chat.multimedia.documentUploadTipFormat": "O envio de documentos suporta word,pdf,ppt,excel,zip. Os formatos word,pdf,ppt,excel são suportados no zip,e o tamanho do arquivo não excede {n} MB.",
  "ccmessaging.chat.multimedia.download": "Baixar",
  "ccmessaging.chat.multimedia.edit": "Editar",
  "ccmessaging.chat.multimedia.emoticonsManagement": "Emoticons",
  "ccmessaging.chat.multimedia.emoticonsUploadTipFormat": "O arquivo emoticon personalizado carregado pode estar no formato JPG, JPEG, PNG ou GIF e o tamanho do arquivo não pode exceder {n} KB.",
  "ccmessaging.chat.multimedia.exceedFileSize": "O número de arquivos multimídia carregados é o tipo de erro ou excede o máximo : {n}!",
  "ccmessaging.chat.multimedia.fileName": "Nome do arquivo",
  "ccmessaging.chat.multimedia.fileSize": "Tamanho do arquivo",
  "ccmessaging.chat.multiMedia.fileUploadError": "O arquivo carregado tem o tamanho ou formato errado",
  "ccmessaging.chat.multiMedia.fileUploadInjectionRisk": "Este upload é arriscado. Verifique o conteúdo e tente novamente",
  "ccmessaging.chat.multimedia.fileUploadLabel": "Selecione um arquivo para carregar",
  "ccmessaging.chat.multiMedia.fileUploadNameError": "O formato de nome do arquivo enviado está incorreto",
  "ccmessaging.chat.multiMedia.fileUploadTypeError": "O tipo de arquivo enviado está em um formato incorreto",
  "ccmessaging.chat.multimedia.finish": "Terminar",
  "ccmessaging.chat.multimedia.getRichTextInfoError": "Falha ao obter dados de rich text.",
  "ccmessaging.chat.multimedia.id": "ID",
  "ccmessaging.chat.multimedia.imageManagement": "Biblioteca de imagens",
  "ccmessaging.chat.multimedia.imageUploadTipFormat": "O arquivo de imagem carregado atualmente suporta apenas formatos jpg, png, jpeg e o tamanho do arquivo não deve exceder {n}M.",
  "ccmessaging.chat.multimedia.isDeleteMultiMediaInfo": "Tem certeza de que deseja excluir esses dados multimídia?",
  "ccmessaging.chat.multimedia.isDeleteMultiMediaType": "Tem certeza de que deseja excluir o tipo de multimídia e seus dados de multimídia?",
  "ccmessaging.chat.multimedia.latitude": "Latitude",
  "ccmessaging.chat.multimedia.latitudeValidateFailed": "A parte inteira de latitude é -90-90, e a parte decimal é de 0 a 6 dígitos!",
  "ccmessaging.chat.multimedia.locationManagement": "Biblioteca de endereços",
  "ccmessaging.chat.multimedia.longitude": "Longitude",
  "ccmessaging.chat.multimedia.longitudeValidateFailed": "A parte inteira de longitude é -180-180, e a parte decimal é de 0 a 6 dígitos!",
  "ccmessaging.chat.multimedia.mediaFileType": "FileType de mídia",
  "ccmessaging.chat.multimedia.modifyCategory": "Modificar categoria",
  "ccmessaging.chat.multimedia.modifyMultiMediaInfo": "Modificar dados multimídia com sucesso!",
  "ccmessaging.chat.multimedia.modifyMultiMediaInfoError": "Falha ao modificar dados multimídia!",
  "ccmessaging.chat.multimedia.modifyMultiMediaType": "Modificar a categoria multimídia com sucesso!",
  "ccmessaging.chat.multimedia.modifyMultiMediaTypeError": "Falha ao modificar a categoria de biblioteca multimídia!",
  "ccmessaging.chat.multimedia.modifyRichText": "Editar Rich Text",
  "ccmessaging.chat.multimedia.multiMediaInfoInputEmpty": "Os dados de entrada multimídia estão vazios!",
  "ccmessaging.chat.multimedia.multiMediaInfoInputInvalid": "Os dados de entrada multimídia são inválidos!",
  "ccmessaging.chat.multimedia.multiMediaManagement": "Configurações da biblioteca multimídia",
  "ccmessaging.chat.multimedia.multiMediaNameExist": "O nome dos dados multimídia já existe nesta categoria!",
  "ccmessaging.chat.multimedia.multiMediaTypeIdNotExist": "A categoria de biblioteca multimídia selecionada não existe, selecione a categoria de biblioteca multimídia!",
  "ccmessaging.chat.multimedia.multiMediaTypeInputEmpty": "Os dados de entrada da categoria multimídia estão vazios!",
  "ccmessaging.chat.multimedia.multiMediaTypeInputInvalid": "Os dados de entrada da categoria multimídia são inválidos!",
  "ccmessaging.chat.multimedia.multiMediaTypeNameExist": "O nome da categoria multimídia já existe!",
  "ccmessaging.chat.multimedia.name": "Nome",
  "ccmessaging.chat.multimedia.nameContainsSpecialCharacters": "Nome contém caracteres especiais",
  "ccmessaging.chat.multimedia.nameSuperLong": "Excedido número máximo de caracteres Nome",
  "ccmessaging.chat.multimedia.operating": "Operação",
  "ccmessaging.chat.multimedia.play": "Reproduzir",
  "ccmessaging.chat.multimedia.preview": "Visualização",
  "ccmessaging.chat.multimedia.richTextContentEmpty": "O conteúdo de rich text está vazio.",
  "ccmessaging.chat.multimedia.richTextContentLongLimit": "O conteúdo de rich text pode conter no máximo 5000 caracteres.",
  "ccmessaging.chat.multimedia.richTextContentSuperLong": "O rich text pode conter no máximo 5000 caracteres.",
  "ccmessaging.chat.multimedia.richTextManagement": "Rich Text",
  "ccmessaging.chat.multimedia.richTextTypeEmpty": "A categoria rich text não existe.",
  "ccmessaging.chat.multimedia.categorizeEmpty": "Selecione ou crie uma categoria primeiro.",
  "ccmessaging.chat.multimedia.saveMultiMediaInfo": "Dados multimídia salvos com sucesso!",
  "ccmessaging.chat.multimedia.saveMultiMediaInfoError": "Falha ao salvar dados multimídia!",
  "ccmessaging.chat.multimedia.saveMultiMediaType": "Categoria multimídia salva com sucesso!",
  "ccmessaging.chat.multimedia.saveMultiMediaTypeError": "Falha ao salvar a categoria de biblioteca multimídia!",
  "ccmessaging.chat.multimedia.selectFile": "Selecionar arquivo",
  "ccmessaging.chat.multimedia.selectImage": "Selecionar imagem",
  "ccmessaging.chat.multimedia.upload": "Carregar",
  "ccmessaging.chat.multimedia.uploadTime": "Tempo de carregamento",
  "ccmessaging.chat.multimedia.videoManagement": "Biblioteca de vídeo",
  "ccmessaging.chat.multimedia.videoUploadTipFormat": "O arquivo de vídeo carregado atualmente suporta apenas o formato mp4, e o tamanho do arquivo não deve exceder {n}M.",
  "ccmessaging.chat.multimedia.viewRichText": "Exibir Rich Text",
  "ccmessaging.chat.multimedia.whatsAppTemplate": "Modelo do Whatsapp",
  "ccmessaging.chat.multimediaasccociation.approvalstatus": "Status da revisão",
  "ccmessaging.chat.multimediaasccociation.checkmediainfofailed": "Os dados multimídia não foram liberados para o canal 5G associado ou não foram aprovados!",
  "ccmessaging.chat.multimediaasccociation.deleteassociation": "Excluir resultado da publicação",
  "ccmessaging.chat.multimediaasccociation.deleteassociationinfo": "Tem certeza de que deseja excluir o resultado da publicação para a multimídia e para o canal 5G associado?",
  "ccmessaging.chat.multimediaasccociation.deleteError": "Falha ao excluir o resultado da versão!",
  "ccmessaging.chat.multimediaasccociation.deleteSuccess": "O resultado da versão foi excluído com sucesso!",
  "ccmessaging.chat.multimediaasccociation.examinationfailed": "Falha",
  "ccmessaging.chat.multimediaasccociation.examinationpassed": "Aprovado",
  "ccmessaging.chat.multimediaasccociation.expirationtime": "Expirado em",
  "ccmessaging.chat.multimediaasccociation.isrelease": "Tem certeza de que deseja publicar a multimídia no canal 5G?",
  "ccmessaging.chat.multimediaasccociation.refresh": "Atualizar",
  "ccmessaging.chat.multimediaasccociation.refreshfailed": "Falha ao atualizar os dados!",
  "ccmessaging.chat.multimediaasccociation.refreshsuccess": "Os dados são atualizados com sucesso!",
  "ccmessaging.chat.multimediaasccociation.release": "Liberação",
  "ccmessaging.chat.multimediaasccociation.releaseerror": "Falha ao liberar os dados multimídia!",
  "ccmessaging.chat.multimediaasccociation.releaseresult": "Resultado da liberação",
  "ccmessaging.chat.multimediaasccociation.releasesuccess": "Os dados multimídia são lançados com sucesso!",
  "ccmessaging.chat.multimediaasccociation.releasetitle": "Liberar dados multimídia",
  "ccmessaging.chat.multimediaasccociation.unpublishconfigempty": "O canal 5G onde os dados multimídia devem ser liberados não está configurado.",
  "ccmessaging.chat.multimediaasccociation.unreviewed": "Não revisado",
  "ccmessaging.chat.offlineCall.channelType": "Tipo do canal",
  "ccmessaging.chat.offlineCall.select.noChannel": "O canal não existe.",
  "ccmessaging.chat.offlineCall.userId": "ID do usuário",
  "ccmessaging.chat.offlineSwitch.closed": "O envio falhou porque a função de mensagem de canal offline está desativada.",
  "ccmessaging.chat.phrase.add": "Adicionar",
  "ccmessaging.chat.phrase.addPhraseError": "Falha ao criar frase comum",
  "ccmessaging.chat.phrase.addPhraseSuccess": "Criar frase comum com sucesso",
  "ccmessaging.chat.phrase.addType": "Adicionar uma categoria",
  "ccmessaging.chat.phrase.AllCount": "Total de palavras",
  "ccmessaging.chat.phrase.cancel": "Cancelar",
  "ccmessaging.chat.phrase.currentCount": "Palavras Atual",
  "ccmessaging.chat.phrase.delete": "Excluir",
  "ccmessaging.chat.phrase.deleteType": "Excluir tipo",
  "ccmessaging.chat.phrase.delPhraseError": "Falha ao excluir frase comum",
  "ccmessaging.chat.phrase.delPhraseSucess": "Excluir frase comum com sucesso",
  "ccmessaging.chat.phrase.delPhraseTypeError": "Falha ao excluir tipo de frase comum",
  "ccmessaging.chat.phrase.delPhraseTypeSuccess": "Excluir o tipo de frase comum com sucesso",
  "ccmessaging.chat.phrase.edit": "Editar",
  "ccmessaging.chat.phrase.editType": "Editar tipo",
  "ccmessaging.chat.phrase.enter_keyword": "Inserir uma palavra-chave.",
  "ccmessaging.chat.phrase.enterPhrase": "Por favor insira uma frase comum",
  "ccmessaging.chat.phrase.enterPhraseType": "Por favor, insira um tipo de frase comum.",
  "ccmessaging.chat.phrase.error": "Erro",
  "ccmessaging.chat.phrase.isDeletePhrase": "Tem certeza de que deseja excluir esta frase",
  "ccmessaging.chat.phrase.isDeletePhraseType": "Tem certeza de que deseja excluir a categoria de frase comum e suas frases comuns?",
  "ccmessaging.chat.phrase.isSavePhrase": "Tem certeza de que deseja salvar a frase?",
  "ccmessaging.chat.phrase.isSavePhraseType": "Tem certeza de que deseja salvar a categoria de frase?",
  "ccmessaging.chat.phrase.phrase": "Frase",
  "ccmessaging.chat.phrase.phraseList": "Lista de frases",
  "ccmessaging.chat.phrase.phraseRepeat": "nome comum duplicado",
  "ccmessaging.chat.phrase.phraseType": "Tipo de Pharas",
  "ccmessaging.chat.phrase.phraseTypeEmpty": "O tipo de frase comum a ser excluído não existe. Selecione um tipo de frase comum.",
  "ccmessaging.chat.phrase.phraseTypeRepeat": "A categoria de frase comum é duplicada",
  "ccmessaging.chat.phrase.phraseTypeLimit": "O número de tipos de frases comuns atinge o limite superior 100.",
  "ccmessaging.chat.phrase.prompt": "Solicitar",
  "ccmessaging.chat.phrase.save": "Salvar",
  "ccmessaging.chat.phrase.saveError": "Falha ao salvar",
  "ccmessaging.chat.phrase.saveType": "Salvar tipo",
  "ccmessaging.chat.phrase.saveTypeSuccess": "Salvar categoria de idioma comum com sucesso",
  "ccmessaging.chat.phrase.shortcut": "Teclas de atalho",
  "ccmessaging.chat.phrase.success": "Sucesso",
  "ccmessaging.chat.phrase.updatePhraseTypeError": "Falha ao atualizar o tipo de frase comum.",
  "ccmessaging.chat.phrase.updatePhraseTypeSucess": "Obteve sucesso na atualização do tipo de frase comum.",
  "ccmessaging.chat.phrase.updateError": "Erro de atualização",
  "ccmessaging.chat.phrase.updatePhraseError": "Falha na atualização da linguagem comum",
  "ccmessaging.chat.phrase.updatePhraseSucess": "Atualizar idioma comum com sucesso",
  "ccmessaging.chat.phrase.updateSuccess": "Atualização de sucesso",
  "ccmessaging.chat.portraitchange.customize": "Personalizado",
  "ccmessaging.chat.portraitchange.systemAvatar": "Avatar do sistema",
  "ccmessaging.chat.querymessage": "Consultar mensagens de histórico multimídia",
  "ccmessaging.chat.refresh.fail": "Falha na atualização",
  "ccmessaging.chat.repeatClick": "Não clique repetidamente!",
  "ccmessaging.chat.richText.add": "Criar",
  "ccmessaging.chat.richText.cancel": "Cancelar",
  "ccmessaging.chat.richText.idInput": "Insira um ID.",
  "ccmessaging.chat.richText.nameInput": "Insira um nome.",
  "ccmessaging.chat.richText.openInNewWindow": "Abre em uma nova janela ",
  "ccmessaging.chat.richText.save": "Salvar",
  "ccmessaging.chat.selectchannel.vaildate1": "O código de acesso do canal deve ser único.",
  "ccmessaging.chat.sessiontransfer.tips": "Apenas agentes disponívels e agentes cujas chamadas simultâneas não atingem o limite superior podem ser transferidos.",
  "ccmessaging.chat.silentagent.aceesscode.empty": "O código de acesso não pode ser nulo!",
  "ccmessaging.chat.silentagent.config.accesscode.same": "A configuração do agente silencioso do código de acesso de áudio já existe no lease atual.",
  "ccmessaging.chat.silentagent.config.del.error": "Falha ao excluir a configuração do agente silencioso de voz",
  "ccmessaging.chat.silentagent.config.del.success": "A exclusão da configuração do agente silencioso de voz foi bem-sucedida",
  "ccmessaging.chat.silentagent.config.willdel": "Tem certeza de que deseja excluir o agente de voz selecionado?",
  "ccmessaging.chat.silentagent.DeleteFail": "Falha ao excluir a configuração do agente silencioso",
  "ccmessaging.chat.silentagent.DeleteSuccess": "A exclusão da configuração do agente silencioso foi bem-sucedida",
  "ccmessaging.chat.silentagent.sendContent.noOIAPNode": "Silencioso Agente não consegue obter informações do nó atual",
  "ccmessaging.chat.silentagent.sendContent.supervise": "Agente silencioso não pode enviar mensagem no modo de supervisão de trabalho!",
  "ccmessaging.chat.silentagent.silent.skill.empty": "A fila de habilidades do agente silencioso não pode estar vazia",
  "ccmessaging.chat.silentAgent.workMode.changeWorkMode.fail": "Falha no modo de trabalho da alteração do silentAgent",
  "ccmessaging.chat.silentAgent.workMode.changeWorkMode.prompt": "Dica",
  "ccmessaging.chat.silentAgent.workMode.changeWorkMode.success": "O silentAgent mudar o modo de trabalho bem-sucedido",
  "ccmessaging.chat.silentAgent.workMode.common": "Comum",
  "ccmessaging.chat.silentAgent.workMode.insert": "Inserir",
  "ccmessaging.chat.silentAgent.workMode.supervise": "supervisionar",
  "ccmessaging.chat.silentAgent.workMode.transfer": "Transferência",
  "ccmessaging.chat.single.media.resource.invalid": "O cartão está referenciando recursos de mídia inválidos",
  "ccmessaging.chat.sms.channel.gateway": "Gateway SMS",
  "ccmessaging.chat.social.media.enterprise.default.username": "Usuário anônimo ",
  "ccmessaging.chat.social.media.enterprise.manage": "Processamento de mensagens da conta empresarial",
  "ccmessaging.chat.social.media.enterprise.post.add.failed": "Falha ao adicionar",
  "ccmessaging.chat.social.media.enterprise.post.add.success": "Adicionado com sucesso",
  "ccmessaging.chat.social.media.enterprise.post.all.reply": "Responder a todos",
  "ccmessaging.chat.social.media.enterprise.post.batch.reply": "Resposta em massa",
  "ccmessaging.chat.social.media.enterprise.post.cancel": "Cancelar",
  "ccmessaging.chat.social.media.enterprise.post.comment": "Comentário",
  "ccmessaging.chat.social.media.enterprise.post.comment.tips": "Adicionar um comentário...",
  "ccmessaging.chat.social.media.enterprise.post.reply": "Responder",
  "ccmessaging.chat.social.media.enterprise.post.reply.cancel": "Cancelar",
  "ccmessaging.chat.social.media.enterprise.post.reply.tips": "Adicionar resposta...",
  "ccmessaging.chat.social.media.enterprise.post.title": "Nome da postagem",
  "ccmessaging.chat.social.media.enterprise.post.topcomment.cancel": "Cancelar comentário",
  "ccmessaging.chat.social.media.enterprise.tips.close": "dobrar",
  "ccmessaging.chat.social.media.enterprise.tips.imgnum": "Até 1 imagem pode ser adicionada ",
  "ccmessaging.chat.social.media.enterprise.tips.open": "Expandir",
  "ccmessaging.chat.social.media.multimedia.manage": "Processamento de mensagens multimídia",
  "ccmessaging.chat.speciallist.channels": "Canais restritos",
  "ccmessaging.chat.speciallist.channelsType": "Tipo de canal restrito",
  "ccmessaging.chat.speciallist.choosechannel": "Selecionar canal",
  "ccmessaging.chat.speciallist.confirmdelete": "Você tem certeza que quer deletar?",
  "ccmessaging.chat.speciallist.delete": "Obsoleto",
  "ccmessaging.chat.speciallist.deletelevel": "(Obsoleto)",
  "ccmessaging.chat.speciallist.emailchannels": "E-mail",
  "ccmessaging.chat.speciallist.haverestrictall": "Todos os canais deste tipo de canal são restritos. Não há necessidade de restringir este canal.",
  "ccmessaging.chat.speciallist.haverestrictchannel": "Este canal é restrito. Não há necessidade de restringi-lo novamente.",
  "ccmessaging.chat.speciallist.level": "Nível Especial da Lista",
  "ccmessaging.chat.speciallist.leveName": "Nome do Tipo de Lista Especial",
  "ccmessaging.chat.speciallist.msgchoosechannel": "Selecione um tipo de canal primeiro.",
  "ccmessaging.chat.speciallist.remark": "Descrição",
  "ccmessaging.chat.speciallist.repeatlevel": "O nível de lista especial já existe. Não criá-lo novamente.",
  "ccmessaging.chat.speciallist.restrictHours": "Duração restrita",
  "ccmessaging.chat.speciallist.restrictUnit": "hora",
  "ccmessaging.chat.speciallist.restrictMode": "Modo de restrição",
  "ccmessaging.chat.speciallist.restrictStyle": "Modo de aplicação",
  "ccmessaging.chat.speciallist.selectchannel": "Canal Designado",
  "ccmessaging.chat.speciallist.smschannels": "Mensagens",
  "ccmessaging.chat.speciallist.unuse": "Não disponível",
  "ccmessaging.chat.speciallist.unuselevel": "(Não disponível)",
  "ccmessaging.chat.speciallist.use": "Disponível",
  "ccmessaging.chat.successaccess": "Acesso bem-sucedido",
  "ccmessaging.chat.sumbitverification.Appld": "ID do aplicativo",
  "ccmessaging.chat.sumbitverification.AppSecret": "Chave",
  "ccmessaging.chat.sumbitverification.facebook.tips": "Vá para a página de desenvolvedor do Facebook Messenger para configurar o site e o código de verificação.",
  "ccmessaging.chat.sumbitverification.fiveG.tips": "Vá para a página Configuração da plataforma de mensagens 5G para configurar o endereço do site.",
  "ccmessaging.chat.sumbitverification.line.tips": "Configure o URL do Webhook no Gerenciador de Contas Oficial da Linha.",
  "ccmessaging.chat.sumbitverification.tips": "Vá para WeChat informações de configuração da interface da plataforma pública para URL de configuração e código de verificação",
  "ccmessaging.chat.sumbitverification.tips.qr": "Não é necessária mais nenhuma configuração neste modo de autorização. Por favor, continue com a próxima etapa ",
  "ccmessaging.chat.sumbitverification.Url": "URL",
  "ccmessaging.chat.sumbitverification.Url.infobipMessAgeUrl": "URL usada pelo infobip para enviar mensagem ao agente",
  "ccmessaging.chat.sumbitverification.Url.infobipReportUrl": "url usada pelo infobip para enviar relatório ao agente",
  "ccmessaging.chat.sumbitverification.VerityCode": "Código de verificação",
  "ccmessaging.chat.sumbitverification.whatsApp.fail": "Falha de geração",
  "ccmessaging.chat.sumbitverification.whatsApp.prompt": "Dica",
  "ccmessaging.chat.sumbitverification.whatsApp.success": "Gerar sucesso",
  "ccmessaging.chat.sumbitverification.whatsApp.tips": "URL usada pelo provedor de serviços infobip WhatsApp para processar mensagens",
  "ccmessaging.chat.system.error": "O sistema está ocupado. Tente novamente mais tarde.",
  "ccmessaging.chat.template.buttonSend": "Enviar",
  "ccmessaging.chat.template.butttonName": "Selecionar",
  "ccmessaging.chat.template.card": "Mensagem de modelo do Whatsapp",
  "ccmessaging.chat.template.Description": "Descrição",
  "ccmessaging.chat.template.languageCode": "Idioma",
  "ccmessaging.chat.template.name": "Nome da mensagem do modelo",
  "ccmessaging.chat.template.namePlaceholder": "Insira um nome de mensagem.",
  "ccmessaging.chat.template.table.button": "Botão",
  "ccmessaging.chat.template.table.buttonPlaceholder": "Resposta rápida do botão.",
  "ccmessaging.chat.template.table.no": "Não",
  "ccmessaging.chat.template.table.parameterContent": "Conteúdo do parâmetro",
  "ccmessaging.chat.template.table.parameterDescription": "Descrição do parâmetro",
  "ccmessaging.chat.template.table.parameterPlaceholder": "Insira um parâmetro.",
  "ccmessaging.chat.template.whatsAppChannel": "Canal",
  "ccmessaging.chat.template.whatsAppTemplateName": "Nome do modelo",
  "ccmessaging.chat.templateMsg.chooseMultimedia": "Selecione um recurso multimídia.",
  "ccmessaging.chat.templateMsg.sendTemplateMsg": "Enviar",
  "ccmessaging.chat.templateMsg.sendTemplateMsgTips": "Enviar mensagem de modelo",
  "ccmessaging.chat.tinymce.button.clear": "vazio",
  "ccmessaging.chat.tinyMCE.FangSong": "FangSong",
  "ccmessaging.chat.tinyMCE.SimHei": "SimHei",
  "ccmessaging.chat.tinyMCE.simsun": "Simsun",
  "ccmessaging.chat.tinyMCE.yaHei": "YaHei da Microsoft",
  "ccmessaging.chat.uploadLocalFile": "Carregar arquivo local",
  "ccmessaging.chat.warning.sharescreen": "Preste atenção à privacidade pessoal ao compartilhar o desktop",
  "ccmessaging.chat.web.agent.assist": "Assistente de agente",
  "ccmessaging.chat.web.agent.prohibitedmess": "Linguagem proibida! Por favor, preste atenção nas palavras.",
  "ccmessaging.chat.web.agent.wrogmess": "Palavras inadequadas! Tenha cuidado com o uso de palavras apropriadas.",
  "ccmessaging.chat.web.channel.called.config": "Configuração da parte chamada do CTD",
  "ccmessaging.chat.web.channel.clickivr": "Obtenha o código de acesso do IVR.",
  "ccmessaging.chat.webCard": "Cartão Web",
  "ccmessaging.chat.webCard.copyUrl": "Copiar link",
  "ccmessaging.chat.webCard.copyUrl.success": "Link copiado com sucesso",
  "ccmessaging.chat.webcard.pop.confirm.outside": "Se deve pular para links de terceiros?",
  "ccmessaging.chat.webcard.pop.confirm.prefix": "A mensagem será redirecionada para o endereço externo, nome de domínio ",
  "ccmessaging.chat.webcard.pop.confirm.suffix": "Confirme se deseja continuar ",
  "ccmessaging.chat.webcard.pop.error.prefix": "Nome de domínio do endereço de redirecionamento atual ",
  "ccmessaging.chat.webcard.pop.error.suffix": "Não está na lista de permissões de endereços, entre em contato com a equipe de operações para adicionar ",
  "ccmessaging.chat.webclient.chatapi.evaluation": "Por favor, classifique este serviço, obrigado!",
  "ccmessaging.chat.webclient.chatapi.failTransToAgent": "Falha de transformação para agente, tente novamente ou entre em contato com o administrador",
  "ccmessaging.chat.webclient.chathtml.customer": "Cliente",
  "ccmessaging.chat.webclient.chatjs.inpuStatus": "A outra parte está a digitar.",
  "ccmessaging.chat.whatsapp.body": "Área de texto",
  "ccmessaging.chat.whatsapp.buttons": "Área do botão",
  "ccmessaging.chat.whatsapp.footer": "Área do rodapé",
  "ccmessaging.chat.whatsapp.header": "Área do cabeçalho",
  "ccmessaging.chat.whatsapp.languageAF": "Holandês, África do Sul",
  "ccmessaging.chat.whatsapp.languageAR": "Árabe",
  "ccmessaging.chat.whatsapp.languageAZ": "Azerbaijão",
  "ccmessaging.chat.whatsapp.languageBG": "Búlgaro",
  "ccmessaging.chat.whatsapp.languageBN": "Bengali",
  "ccmessaging.chat.whatsapp.languageCA": "Catalão",
  "ccmessaging.chat.whatsapp.languageCS": "Tcheco",
  "ccmessaging.chat.whatsapp.languageDA": "Dinamarquês",
  "ccmessaging.chat.whatsapp.languageDE": "Alemão",
  "ccmessaging.chat.whatsapp.languageEL": "Grego",
  "ccmessaging.chat.whatsapp.languageEN": "Inglês",
  "ccmessaging.chat.whatsapp.languageENGB": "Inglês (Reino Unido)",
  "ccmessaging.chat.whatsapp.languageES": "Espanhol",
  "ccmessaging.chat.whatsapp.languageESAR": "Espanhol (Argentina)",
  "ccmessaging.chat.whatsapp.languageESES": "Espanhol (Espanha)",
  "ccmessaging.chat.whatsapp.languageESMX": "Espanhol (México)",
  "ccmessaging.chat.whatsapp.languageET": "Estoniano",
  "ccmessaging.chat.whatsapp.languageFA": "Persa",
  "ccmessaging.chat.whatsapp.languageFI": "Finlandês",
  "ccmessaging.chat.whatsapp.languageFIL": "Filipinas",
  "ccmessaging.chat.whatsapp.languageFR": "Francês",
  "ccmessaging.chat.whatsapp.languageGA": "Irlandês",
  "ccmessaging.chat.whatsapp.languageGU": "Gujarat",
  "ccmessaging.chat.whatsapp.languageHA": "Hausa",
  "ccmessaging.chat.whatsapp.languageHE": "Hebraico",
  "ccmessaging.chat.whatsapp.languageHI": "Hindi",
  "ccmessaging.chat.whatsapp.languageHR": "Croata",
  "ccmessaging.chat.whatsapp.languageHU": "Húngaro",
  "ccmessaging.chat.whatsapp.languageID": "Indonésio",
  "ccmessaging.chat.whatsapp.languageIT": "Italiano",
  "ccmessaging.chat.whatsapp.languageJA": "Japonês",
  "ccmessaging.chat.whatsapp.languageKK": "Cazaque",
  "ccmessaging.chat.whatsapp.languageKN": "Kanadawen",
  "ccmessaging.chat.whatsapp.languageKO": "Coreano",
  "ccmessaging.chat.whatsapp.languageLO": "Laos",
  "ccmessaging.chat.whatsapp.languageLT": "Lituano",
  "ccmessaging.chat.whatsapp.languageLV": "Letónia",
  "ccmessaging.chat.whatsapp.languageMK": "Macedónio do Norte",
  "ccmessaging.chat.whatsapp.languageML": "Malaialam",
  "ccmessaging.chat.whatsapp.languageMR": "Marati",
  "ccmessaging.chat.whatsapp.languageMS": "Maláio",
  "ccmessaging.chat.whatsapp.languageNB": "Norueguês",
  "ccmessaging.chat.whatsapp.languageNL": "Holandês",
  "ccmessaging.chat.whatsapp.languagePA": "Punjabi",
  "ccmessaging.chat.whatsapp.languagePL": "Polonês",
  "ccmessaging.chat.whatsapp.languagePTBR": "Português (Brasil)",
  "ccmessaging.chat.whatsapp.languagePTPT": "Português (Portugal)",
  "ccmessaging.chat.whatsapp.languageRO": "Romeno",
  "ccmessaging.chat.whatsapp.languageRU": "Russo",
  "ccmessaging.chat.whatsapp.languageSK": "Eslovaco",
  "ccmessaging.chat.whatsapp.languageSL": "Esloveno",
  "ccmessaging.chat.whatsapp.languageSQ": "Albanês",
  "ccmessaging.chat.whatsapp.languageSR": "Sérvio",
  "ccmessaging.chat.whatsapp.languageSV": "Sueco",
  "ccmessaging.chat.whatsapp.languageSW": "Suaíli",
  "ccmessaging.chat.whatsapp.languageTA": "Tamil",
  "ccmessaging.chat.whatsapp.languageTE": "Telugu",
  "ccmessaging.chat.whatsapp.languageTH": "Tailandês",
  "ccmessaging.chat.whatsapp.languageTR": "Turco",
  "ccmessaging.chat.whatsapp.languageUK": "Ucraniano",
  "ccmessaging.chat.whatsapp.languageUR": "Urdu",
  "ccmessaging.chat.whatsapp.languageUS": "Inglês (Estados Unidos)",
  "ccmessaging.chat.whatsapp.languageUZ": "Usbeque",
  "ccmessaging.chat.whatsapp.languageVI": "Vietnamita",
  "ccmessaging.chat.whatsapp.languageZHCN": "Chinês simplificado",
  "ccmessaging.chat.whatsapp.languageZHHK": "Chinês tradicional (Hong Kong, China)",
  "ccmessaging.chat.whatsapp.languageZHTW": "Chinês tradicional (Taiwan, China)",
  "ccmessaging.chat.whatsapp.languageZU": "Zulu",
  "ccmessaging.chat.whatsapp.only.send.template": "Quando um novo usurio do WhatsApp ou o intervalo de contato exceder 24 horas, somente mensagens modelo podem ser enviadas.",
  "ccmessaging.chat.whatsapp.selectTemplateOrgPlaceHolder": "Selecionar organização",
  "ccmessaging.chat.whatsapp.template.dailyLimit": "Horários de disponibilidade do modelo por dia",
  "ccmessaging.chat.whatsapp.template.dailyLimitDescription": "Digite o número de vezes que o modelo está disponível por dia.",
  "ccmessaging.chat.whatsapp.template.no": "Não",
  "ccmessaging.chat.whatsapp.template.updateTime": "Atualizado",
  "ccmessaging.chat.whatsapp.templateAddButton": "Criar",
  "ccmessaging.chat.whatsapp.templateBodyParamDesc": "Descrição do parâmetro",
  "ccmessaging.chat.whatsapp.templateBodyParamDescPlaceholder": "inserir a descrição do parâmetro.",
  "ccmessaging.chat.whatsapp.templateBodyText": "Conteúdo do corpo do texto",
  "ccmessaging.chat.whatsapp.templateBodyTextPlaceholder": "Insira o conteúdo do corpo do texto,O conteúdo no {'{{}}'} são partes editáveis no corpo do modelo.",
  "ccmessaging.chat.whatsapp.templateButton.clicktoAction": "Ação",
  "ccmessaging.chat.whatsapp.templateButton.none": "N/A",
  "ccmessaging.chat.whatsapp.templateButton.qickReply": "Resposta rápida",
  "ccmessaging.chat.whatsapp.templateButtonContent": "Conteúdo do botão Modelo",
  "ccmessaging.chat.whatsapp.templateButtonContentPlaceholder": "Insira o conteúdo do botão.",
  "ccmessaging.chat.whatsapp.templateButtonName": "Nome do botão Modelo",
  "ccmessaging.chat.whatsapp.templateButtonNamePlaceholder": "Insira um nome de botão.",
  "ccmessaging.chat.whatsapp.templateButtonType": "Tipo de botão Modelo",
  "ccmessaging.chat.whatsapp.templateChannel": "Canal do modelo",
  "ccmessaging.chat.whatsapp.templateContent": "Conteúdo do modelo",
  "ccmessaging.chat.whatsapp.templateCreate": "Criar modelo do WhatsApp",
  "ccmessaging.chat.whatsapp.templateDescription": "Descrição do modelo",
  "ccmessaging.chat.whatsapp.templateDescriptionPlaceholder": "Informe a descrição do modelo.",
  "ccmessaging.chat.whatsapp.templateFooterText": "Conteúdo do texto do rodapé",
  "ccmessaging.chat.whatsapp.templateFooterTextPlaceholder": "Inserir o conteúdo do texto do rodapé.",
  "ccmessaging.chat.whatsapp.templateHeaderText": "Conteúdo do texto do cabeçalho",
  "ccmessaging.chat.whatsapp.templateHeaderTextPlaceholder": "Insira o conteúdo do texto do cabeçalho.",
  "ccmessaging.chat.whatsapp.templateHeaderType": "Cabeçalho",
  "ccmessaging.chat.whatsapp.templateHeaderType.image": "Imagem",
  "ccmessaging.chat.whatsapp.templateHeaderType.locate": "Localização",
  "ccmessaging.chat.whatsapp.templateHeaderType.none": "N/A",
  "ccmessaging.chat.whatsapp.templateHeaderType.text": "Texto",
  "ccmessaging.chat.whatsapp.templateHeaderType.video": "Vídeo",
  "ccmessaging.chat.whatsapp.templateLanguage": "Idioma",
  "ccmessaging.chat.whatsapp.templateName": "Nome do modelo",
  "ccmessaging.chat.whatsapp.templateNamePlaceholder": "Insira um nome de modelo.",
  "ccmessaging.chat.whatsAppTemplateMsg.selectWhatsAppTemplateMsg": "Selecione a mensagem de modelo do WhatsApp correspondente",
  "ccmessaging.chat.whatsAppTemplateMsg.selectWhatsAppTemplateMsgId": "ID da mensagem do modelo WhatsApp",
  "ccmessaging.chat.whatsAppTemplateMsg.selectWhatsAppTemplateMsgName": "Nome da mensagem do modelo WhatsApp",
  "ccmessaging.chat.workbench.body.iolp": " Orientação de negócios ",
  "ccmessaging.chat.workbench.body.transfer": "Encaminhamento de chamadas ",
  "ccmessaging.chat.workbench.transfer.agent": "Agente",
  "ccmessaging.chat.workbench.transfer.noagents": "Nenhum agente online na fila de habilidades atual ",
  "ccmessaging.chat.workbench.transfer.skill": "Fila de habilidades ",
  "ccmessaging.chat.workbench.transfer.skilllogin": "Número de agentes conectados ",
  "ccmessaging.chat.workbench.transfer.skillname": "Nome da fila de habilidades ",
  "ccmessaging.chat.workbench.transfer.skillqueue": "Número de pessoas na fila atual ",
  "ccmessaging.chat.workbench.transfer.skillsearch": "Digite o nome da fila de habilidades ",
  "ccmessaging.chatbot.button.avatarImport": "Carregar",
  "ccmessaging.chatbot.button.avatarImportLimit": "O tamanho do arquivo a ser carregado não pode exceder 6 MB e o arquivo pode estar em formato JPG ou PNG.",
  "ccmessaging.chatbot.button.cancel": "Cancelar",
  "ccmessaging.chatbot.button.edit": "Editar",
  "ccmessaging.chatbot.label.avatar": "Imagem de perfil",
  "ccmessaging.chatbot.label.avatarImport": "Carregar Avatar",
  "ccmessaging.chatbot.label.avatarImportFailed": "Falha ao carregar a imagem!",
  "ccmessaging.chatbot.label.avatarImportSuccess": "Carregar a imagem com sucesso!",
  "ccmessaging.chatbot.label.avatarModifing": "Change Avatar",
  "ccmessaging.chatbot.label.basicInformation": "Informações básicas",
  "ccmessaging.chatbot.label.commonLanguageSetting": "Common Phrase Configuration",
  "ccmessaging.chatbot.label.defaultReply": "Default reply",
  "ccmessaging.chatbot.label.gender": "Gênero",
  "ccmessaging.chatbot.label.name": "Apelido",
  "ccmessaging.chatbot.label.save": "Salvar",
  "ccmessaging.chatbot.label.timeoutReply": "Resposta de tempo limite",
  "ccmessaging.chatbot.label.turnManualPrompt": "Solicitação para transferência para o agente",
  "ccmessaging.chatbot.label.welcomeMessage": "Saudação",
  "ccmessaging.chatbot.message.error": "Falha",
  "ccmessaging.chatbot.message.modifyFailure": "Falha na modificação.",
  "ccmessaging.chatbot.message.modifySuccess": "Modificado com sucesso.",
  "ccmessaging.chatbot.message.specialStrValidateFailed": "O conteúdo inserido não pode conter os seguintes characters: & < > /.",
  "ccmessaging.chatbot.message.success": "Sucesso",
  "ccmessaging.chatofflineCall.channelName": "Nome do Canal",
  "ccmessaging.chatofflineCall.invalidUserIdTip": "A ID do assinante é inválida.",
  "ccmessaging.chatofflineCall.noSelectChanelTip": "Nenhum canal está selecionado.",
  "ccmessaging.chatofflineCall.noSupportText": "Chamada para estranhos!",
  "ccmessaging.chatofflineCall.strangeOfflineTip": "Nenhuma sessão histórica foi encontrada.",
  "ccmessaging.cobrowsesite.protocol.check": "Uso de protocolos inseguros\, riscos de segurança.",
  "ccmessaging.cobrowsesite.mediaurl.format.error": "O formato do site cobrowse está incorreto.",
  "ccmessaging.email.attachment.fileType.tip1": "Os anexos de e-mail suportam os seguintes formatos de arquivo:",
  "ccmessaging.email.attachment.fileType.tip2": ",",
  "ccmessaging.email.attachment.fileType.tip3": "O tamanho do arquivo não pode exceder",
  "ccmessaging.email.attachment.fileType.tip4": "M.",
  "ccmessaging.emailcatalog.add": "Criar diretório",
  "ccmessaging.emailcatalog.canNotAdd": "Um máximo de dois níveis de diretórios são permitidos.",
  "ccmessaging.emailcatalog.deleteTopCatalog": "O diretório padrão não pode ser excluído.",
  "ccmessaging.emailcatalog.duplicateName": "O nome já existe. Use outro nome.",
  "ccmessaging.emailcatalog.existEmail": "Existem e-mails no diretório.",
  "ccmessaging.emailcatalog.existSubCatalog": "O diretório contém subdiretórios.",
  "ccmessaging.emailcatalog.modify": "Modificar diretório",
  "ccmessaging.emailcatalog.modifyTopCatalog": "O diretório padrão não pode ser modificado.",
  "ccmessaging.emailcatalog.moveCatalogFailed": "Falha ao mover um diretório.",
  "ccmessaging.emailcatalog.moveCatalogPartialSuccess": "Falha ao mover alguns diretórios de e-mail.",
  "ccmessaging.emailcatalog.moveCatalogSuccess": "O diretório foi movido com sucesso.",
  "ccmessaging.emailcatalog.moveEmailQuantityExceedsMaxValue": "O número de mensagens móveis excede 30 ou o tamanho do parâmetro excede o máximo",
  "ccmessaging.emailcatalog.moveOtherCatalogFailed": "Os e-mails de outros agentes não podem ser movidos.",
  "ccmessaging.emailcatalog.catalogname": "Nome do catÃ¡logo",
  "ccmessaging.emailcatalog.parentName": "Nome do Diretório Pai",
  "ccmessaging.emailcatalog.pleaseSelectCatalog": "Selecione um diretório primeiro.",
  "ccmessaging.emailcatalog.selectcatalog": "Selecionar diretório",
  "ccmessaging.emailMessageManage.forward": "Registro de encaminhamento",
  "ccmessaging.flowControl.addFail": "Falha ao adicionar a configuração de controle de fluxo.",
  "ccmessaging.flowControl.addSuccess": "Configuração de controle de fluxo adicionada com sucesso.",
  "ccmessaging.flowControl.alarmInterval": "Intervalo do alarme",
  "ccmessaging.flowControl.alarmInterval.unit": "min",
  "ccmessaging.flowControl.alarmThreshold": "Limite de alarme",
  "ccmessaging.flowControl.channelType": "Tipo do canal",
  "ccmessaging.flowControl.count.beyondMaxLimit": "O número de regras de controle de fluxo no espaço do locatário excede o limite superior.",
  "ccmessaging.flowControl.createTime": "Criado em",
  "ccmessaging.flowControl.deleteFail": "Falha ao excluir a configuração de controle de fluxo.",
  "ccmessaging.flowControl.deleteSelect": "Tem certeza de que deseja excluir a regra selecionada?",
  "ccmessaging.flowControl.deleteSuccess": "Configuração de controle de fluxo excluída com sucesso.",
  "ccmessaging.flowControl.description": "Descrição",
  "ccmessaging.flowControl.enable": "Ativar",
  "ccmessaging.flowControl.limitNum": "Máx. Mensagens",
  "ccmessaging.flowControl.name": "Nome do Controle de Fluxo",
  "ccmessaging.flowControl.operate": "Operação",
  "ccmessaging.flowControl.periodType": "Tipo de Período",
  "ccmessaging.flowControl.periodType.day": "Diário",
  "ccmessaging.flowControl.periodType.month": "Mensal",
  "ccmessaging.flowControl.periodType.time": "Intervalo de tempo",
  "ccmessaging.flowControl.periodType.week": "Semanal",
  "ccmessaging.flowControl.periodValue": "Dia",
  "ccmessaging.flowControl.period.select": "Seleção do Período",
  "ccmessaging.flowControl.selectStop": "Desabilite a configuração de controle de fluxo primeiro.",
  "ccmessaging.flowControl.selectRule": "Selecione a configuração de controle de fluxo.",
  "ccmessaging.flowControl.selectStartTime": "Selecione a hora de início.",
  "ccmessaging.flowcontrol.target.deleted": "O objeto de controle de fluxo foi excluído. Exclua a regra de controle de fluxo correspondente.",
  "ccmessaging.flowControl.targetId": "Objeto de Controle de Fluxo",
  "ccmessaging.flowControl.tipText": "Prompt",
  "ccmessaging.flowControl.tipTextInterval": "Intervalo de alerta",
  "ccmessaging.flowControl.updateFail": "Falha ao atualizar a configuração de controle de fluxo.",
  "ccmessaging.flowControl.updateSuccess": "Configuração de controle de fluxo atualizada com sucesso.",
  "ccmessaging.flowControl.usage": "Utilização",
  "ccmessaging.flowControl.title.create": "Criar controle de fluxo",
  "ccmessaging.flowControl.title.modify": "Editar fluxo de mensagem",
  "ccmessaging.flowControl.title.view": "Afficher le contrôle de flux",
  "ccmessaging.webCollaboration.ruleName": "Nome da regra",
  "ccmessaging.webCollaboration.pageMatchType": "Tipo de correspondência de página",
  "ccmessaging.webCollaboration.pageMatchConfiguration": "Configuração de correspondência de página",
  "ccmessaging.webCollaboration.pageElementSelector": "Seletor de elementos de página",
  "ccmessaging.webCollaboration.createTime": "Criado em",
  "ccmessaging.webCollaboration.operation": "Operação",
  "cobrowse.sensitiveinfo.pagematchtype.commonmatch": "Correspondência genérica",
  "cobrowse.sensitiveinfo.pagematchtype.urlmatch": "Corresponder pelo caminho do URL",
  "cobrowse.sensitiveinfo.pagematchtype.pageidmatch": "Corresponder pelo ID da página",
  "ccmessaging.message.location.latitude": "Latitude:",
  "ccmessaging.message.location.longitude": "Longitude:",
  "ccmessaging.message.selectEmail.count": "Máximo 30 e-mails",
  "ccmessaging.message.selectEmail.null": "Selecione um e-mail.",
  "ccmessaging.message.sendstatus.deliverable": "Enviado",
  "ccmessaging.message.sendstatus.pending": "Aguardando",
  "ccmessaging.message.sendstatus.read": "Leitura",
  "ccmessaging.message.sendstatus.undeliverable": "Falha",
  "ccmessaging.message.sendstatus.unread": "Não Lido",
  "ccmessaging.message.timeerror.laterThanCurTime": "A hora de início não pode ser posterior à hora atual.",
  "ccmessaging.messagecontroll.timeerror.beyondMaxLimit": "A hora de término não pode ser posterior à hora de início 90 dias!",
  "ccmessaging.multimedialibrary.cobrowsesite.card.target": "Procurar alvo:",
  "ccmessaging.multimedialibrary.cobrowsesite.card.title": "O agente solicita navegação colaborativa com o usuário.",
  "ccmessaging.multimedialibrary.tips": "Este endereço é usado pelo agente para enviar aos usuários para colaboração na web . Por favor , configure corretamente",
  "ccmessaging.operlogs.emailAttachment.download": "Baixar anexos de e-mail",
  "ccmessaging.operlogs.multimedialibrary.create.richtext": "Novo Rich Text",
  "ccmessaging.operlogs.multimedialibrary.modify.richtext": "Modificar rich text",
  "ccmessaging.operlogs.multimedialibrary.save": "Salvar configuração de dados da biblioteca multimídia",
  "ccmessaging.operlogs.multimedialibrary.save.audio": "Salve a configuração de dados na biblioteca multimídia e enviar os dados de arquivo de tipo áudio.",
  "ccmessaging.operlogs.multimedialibrary.save.emoticons": "Salve a configuração de dados na biblioteca multimídia e enviar os dados de arquivo do tipo emoticon.",
  "ccmessaging.operlogs.multimedialibrary.save.image": "Salve a configuração de dados na biblioteca multimídia e carregue os dados de arquivo de tipo imagem.",
  "ccmessaging.operlogs.multimedialibrary.save.video": "Salve a configuração de dados na biblioteca multimídia e enviar os dados do arquivo do tipo vídeo.",
  "ccmessaging.operlogs.preview.contract": "Pré-visualização Contrato, Usuário:",
  "ccmessaging.operlogs.signature.submit": "Enviar contrato para firmar",
  "ccmessaging.operlogs.upload.contract": "Carregar Contrato",
  "ccmessaging.pop.org.window.noorg": "Sem uma Organização",
  "ccmessaging.pop.org.window.org": "Com uma Organização",
  "ccmessaging.ruleconfig.add": "Adicionar",
  "ccmessaging.ruleconfig.all": "Todos os canais",
  "ccmessaging.ruleconfig.applicationChannel": "Canal do aplicativo",
  "ccmessaging.ruleconfig.content": "Conteúdo",
  "ccmessaging.ruleconfig.contentNotNull": "O conteúdo não pode ficar vazio.",
  "ccmessaging.ruleconfig.createdTime": "Horário de criação",
  "ccmessaging.ruleconfig.dateEnd": "Hora de término",
  "ccmessaging.ruleconfig.dateStart": "Hora de início",
  "ccmessaging.ruleconfig.deleteFail": "Falha ao excluir regra!",
  "ccmessaging.ruleconfig.deleteSelectRule": "Tem certeza de que deseja excluir a regra selecionada?",
  "ccmessaging.ruleconfig.deleteSuccess": "Regra excluída com sucesso.",
  "ccmessaging.ruleconfig.duplicateName": "O nome da regra é duplicado.",
  "ccmessaging.ruleconfig.effective": "Efetivo",
  "ccmessaging.ruleconfig.emailaddress.error": "Os seguintes endereços estão proibidos de enviar:",
  "ccmessaging.ruleconfig.emailAddressExists": "O endereço de e-mail já existe",
  "ccmessaging.ruleconfig.emailAdress": "Endereço de e-mail",
  "ccmessaging.ruleconfig.emailChannel": "Canal de e-mail",
  "ccmessaging.ruleconfig.ID": "ID",
  "ccmessaging.ruleconfig.invalidation": "Invalidação",
  "ccmessaging.ruleconfig.invalidEmailAddress": "Endereço de email inválido",
  "ccmessaging.ruleconfig.invalidSMSNumber": "Número de telemóvel inválido.",
  "ccmessaging.ruleconfig.maxEmailAddressCount": "É possível inserir, no máximo, 10 endereços de e-mail.",
  "ccmessaging.ruleconfig.maxSMSNumberCount": "É possível inserir, no máximo, 10 endereços de mobile number",
  "ccmessaging.ruleconfig.maxValidityPeriod": "Período de validade definido como máximo",
  "ccmessaging.ruleconfig.ruleContentKeyword": "Palavra-chave de conteúdo da regra",
  "ccmessaging.ruleconfig.ruleDescribe": "Descrição da regra",
  "ccmessaging.ruleconfig.ruleName": "Nome da regra",
  "ccmessaging.ruleconfig.selectRule": "Selecione uma regra",
  "ccmessaging.ruleconfig.SMSChannel": "Canal de SMS",
  "ccmessaging.ruleconfig.SMSNumber": "Número de telemóvel",
  "ccmessaging.ruleconfig.SMSNumberExists": "O número de celular já existe",
  "ccmessaging.ruleconfig.SMStips1": `Você pode inserir curingas no endereço de SMS.Você pode inserir curingas no número de celular. "\\*" indica que 0 ou mais caracteres são correspondidos. "\\?" indica que 0 a 1 caracteres são correspondidos`,
  "ccmessaging.ruleconfig.SMStips2": `Por exemplo. "\\*9988" indica que todas as mensagens SMS enviadas de números móveis terminados em "9988" são rejeitadas.`,
  "ccmessaging.ruleconfig.SMStips3": `Por exemplo. "132\\*" indica que todas as mensagens SMS enviadas de números móveis que começam com "132" são rejeitadas.`,
  "ccmessaging.ruleconfig.SMStips4": `O "\\*" bloqueará todas as mensagens SMS enviadas por números de celular. Tenha cuidado ao usar este comando.`,
  "ccmessaging.ruleconfig.timeerror.dateValidateFailed": "Selecione uma data posterior à hora atual e anterior a 2038-1-19",
  "ccmessaging.ruleconfig.timeerror.empty": "* A hora inicial e a hora final não podem estar vazias.",
  "ccmessaging.ruleconfig.timeerror.endTime": "* A hora final não pode ser anterior à hora atual.",
  "ccmessaging.ruleconfig.timeerror.laterThanEndTime": "* A hora inicial não pode ser posterior à hora final.",
  "ccmessaging.ruleconfig.timeerror.laterThanEndTime2": "A hora inicial não pode ser posterior à hora final.",
  "ccmessaging.ruleconfig.timeerror.startTime": "* A hora inicial não pode ser anterior à hora atual.",
  "ccmessaging.ruleconfig.tips1": `Você pode inserir caracteres curingas no endereço de email. "\\*" indica que 0 ou mais caracteres são correspondentes. "\\?" Indica que 0 a 1 caracteres são correspondentes.`,
  "ccmessaging.ruleconfig.tips2": `Por exemplo, "\\*{'@'}huawei.com" indica que todos os e-mails cujo nome de domínio é "huawei.com" são rejeitados.`,
  "ccmessaging.ruleconfig.tips3": "Insira o conteúdo que atende às regras de endereço de e-mail na caixa de texto. Além disso, asteriscos (\\*) e pontos de interrogação (\\?) não são permitidos.",
  "ccmessaging.ruleconfig.tips4": `"\\*{'@'}\\*.com" bloqueará todos os e-mails com o nome de domínio ".com". Cuidado ao usar este comando.`,
  "ccmessaging.ruleconfig.validityPeriod": "Período de validade",
  "ccmessaging.ruleconfig.validityPeriodDay": "Access Token Período de validade(dias)",
  "ccmessaging.ruleconfig.duplicateEmailAddress": "Endereço de e-mail duplicado",
  "ccmessaging.ruleconfig.duplicateSmsAddress": "Número de telemóvel duplicado",
  "ccmessaging.socialPostOperation.button.approve": "Aprovação",
  "ccmessaging.socialPostOperation.button.batchDelete": "Exclusão em lote",
  "ccmessaging.socialPostOperation.button.cancel": "Retirar",
  "ccmessaging.socialPostOperation.button.channelConfig": "Configurações de canal",
  "ccmessaging.socialPostOperation.button.creatPost": "Publicar",
  "ccmessaging.socialPostOperation.button.edit": "Editar",
  "ccmessaging.socialPostOperation.button.delete": "Excluir",
  "ccmessaging.socialPostOperation.button.saveDraft": "Salvar como rascunho",
  "ccmessaging.socialPostOperation.button.upload": "Enviar",
  "ccmessaging.socialPostOperation.ceratePageTitle": "Operações de conta / Posts",
  "ccmessaging.socialPostOperation.edit.approveInformation": "Informações de aprovação",
  "ccmessaging.socialPostOperation.edit.basicInformation": "Informações básicas",
  "ccmessaging.socialPostOperation.edit.must": "Preenchimento obrigatório",
  "ccmessaging.chat.channelconfig.checkConfirmTokenEqualToToken": "Deve inserir o mesmo valor",
  "ccmessaging.socialPostOperation.edit.placeholder": "Digite",
  "ccmessaging.socialPostOperation.edit.publishInformation": "Informações de lançamento",
  "ccmessaging.socialPostOperation.facebook.publishAt.tips": "A publicação programada deve ser publicada entre 20 minutos e 75 dias após a criação",
  "ccmessaging.socialPostOperation.facebook.tips.emptyPost": "O Facebook não pode publicar posts em branco. Selecione pelo menos um item (conteúdo, imagens, vídeos)",
  "ccmessaging.socialPostOperation.field.applicationType": "Tipo de aplicação",
  "ccmessaging.socialPostOperation.field.applicationType.create": "Publicar",
  "ccmessaging.socialPostOperation.field.approveComment": "Comentário de aprovação",
  "ccmessaging.socialPostOperation.field.approverName": "Aprovado por",
  "ccmessaging.socialPostOperation.field.approveStatus": "Resultado da aprovação",
  "ccmessaging.socialPostOperation.field.approveStatus.pass": "Aprovado",
  "ccmessaging.socialPostOperation.field.approveStatus.rejected": "Rejeitado",
  "ccmessaging.socialPostOperation.field.channelName": "Nome do canal",
  "ccmessaging.socialPostOperation.field.content": "Conteúdo",
  "ccmessaging.socialPostOperation.field.createUserName": "Requerente",
  "ccmessaging.socialPostOperation.field.image": " Imagem",
  "ccmessaging.socialPostOperation.field.operation": " Operação",
  "ccmessaging.socialPostOperation.field.postConfigInfo": "Informações do canal",
  "ccmessaging.socialPostOperation.field.postType": "Canal de publicação",
  "ccmessaging.socialPostOperation.field.publishAt": "Tempo de publicação",
  "ccmessaging.socialPostOperation.field.publishChannel": "Publicar canal",
  "ccmessaging.socialPostOperation.field.publishStatus": "Estado de lançamento",
  "ccmessaging.socialPostOperation.field.publishStatus.published": " Publicado",
  "ccmessaging.socialPostOperation.field.publishStatus.unpublished": "Não publicado",
  "ccmessaging.socialPostOperation.field.publishTime": "Tempo de lançamento estimado",
  "ccmessaging.socialPostOperation.field.publishTimeSet": "Configuração de tempo de publicação",
  "ccmessaging.socialPostOperation.field.publishTyle.now": " Publicação em tempo real",
  "ccmessaging.socialPostOperation.field.publishTyle.time": "Publicação agendada",
  "ccmessaging.socialPostOperation.field.publishType": "Tipo de publicação",
  "ccmessaging.socialPostOperation.field.remark": "Comentário",
  "ccmessaging.socialPostOperation.field.status": "Estado",
  "ccmessaging.socialPostOperation.field.status.approved": "Concluído",
  "ccmessaging.socialPostOperation.field.status.draft": "Rascunho",
  "ccmessaging.socialPostOperation.field.status.toApproved": " Aprovação pendente",
  "ccmessaging.socialPostOperation.field.title": "Tema",
  "ccmessaging.socialPostOperation.field.video": "Vídeo",
  "ccmessaging.socialPostOperation.res.recall": "Revogação bem-sucedida",
  "ccmessaging.socialPostOperation.search.searchTitle": "Assunto",
  "ccmessaging.socialPostOperation.select.pleaseChoose": "Por favor selecione",
  "ccmessaging.socialPostOperation.tab.history": "Histórico de aprovação",
  "ccmessaging.socialPostOperation.tips.approveSet": "Verifique a configuração do aprovador",
  "ccmessaging.socialPostOperation.tips.approveSuccess": "Aprovado com sucesso",
  "ccmessaging.socialPostOperation.tips.approvingDelete": "Os dados de estado pendentes não podem ser excluídos",
  "ccmessaging.socialPostOperation.tips.cancelError": "Os dados não podem ser retirados.",
  "ccmessaging.socialPostOperation.tips.cannotDel": "As postagens pendentes não podem ser excluídas",
  "ccmessaging.socialPostOperation.tips.delConfirm": "Tem certeza de que deseja excluir os dados selecionados?",
  "ccmessaging.socialPostOperation.tips.deleteApproveSuccess": " Enviado para exclusão com sucesso, aguardando aprovação",
  "ccmessaging.socialPostOperation.tips.deleteNoApprove": "O aprovador está em branco. Ele será excluído após o envio. Tem certeza de que deseja enviar?",
  "ccmessaging.socialPostOperation.tips.fileTip": "O tamanho do arquivo excedeu o limite máximo de {n}MB",
  "ccmessaging.socialPostOperation.tips.imageNumTip": "Um máximo de 9 imagens podem ser enviadas",
  "ccmessaging.socialPostOperation.tips.imageType": "Suporte aos formatos png, jpg, gif, bmp, tiff",
  "ccmessaging.socialPostOperation.tips.imgAndVideoChoose": "A imagem e o vídeo não podem ser carregados ao mesmo tempo",
  "ccmessaging.socialPostOperation.tips.postTypesChoose": "Selecione pelo menos um canal de publicação",
  "ccmessaging.socialPostOperation.tips.publishFailed": "Falha ao publicar",
  "ccmessaging.socialPostOperation.tips.saveApproveSuccess": " Enviado com sucesso, aguardando aprovação",
  "ccmessaging.socialPostOperation.tips.submit": "Aprovador está vazio, será publicado após o envio. Tem certeza de que deseja enviar?",
  "ccmessaging.socialPostOperation.tips.submitSuccess": " Enviado com sucesso",
  "ccmessaging.socialPostOperation.tips.titleError": "Não pode conter < e >",
  "ccmessaging.socialPostOperation.tips.uploadVideo": "Por favor, carregue um arquivo de vídeo",
  "ccmessaging.socialPostOperation.tips.videoType": "Suporte ao formato mp4",
  "ccmessaging.socialPostOperation.tips.youTubeFileChoose": "O canal do YouTube só suporta o upload de vídeos",
  "ccmessaging.socialPostOperation.youTube.addLabel": "Adicionar tag",
  "ccmessaging.socialPostOperation.youTube.audience": " Audiência",
  "ccmessaging.socialPostOperation.youTube.audienceRule": " Quando exigido por lei, você deve cumprir a Lei de Proteção da Privacidade Online das Crianças (COPPA) e/ou outras leis, onde quer que você esteja. Você deve indicar se o seu vídeo é destinado a crianças.",
  "ccmessaging.socialPostOperation.youTube.category": "Categoria",
  "ccmessaging.socialPostOperation.youTube.category.car": "Automóveis",
  "ccmessaging.socialPostOperation.youTube.category.Comedy": "Comédia",
  "ccmessaging.socialPostOperation.youTube.category.education": "Educação",
  "ccmessaging.socialPostOperation.youTube.category.entertainment": "Entretenimento",
  "ccmessaging.socialPostOperation.youTube.category.film": "Filmes e desenhos",
  "ccmessaging.socialPostOperation.youTube.category.gaming": "Jogos",
  "ccmessaging.socialPostOperation.youTube.category.howto": "Tutoriais e estilo",
  "ccmessaging.socialPostOperation.youTube.category.music": "Música",
  "ccmessaging.socialPostOperation.youTube.category.news": "Notícias e política",
  "ccmessaging.socialPostOperation.youTube.category.nonprofits": "Sem fins lucrativos/ativismo",
  "ccmessaging.socialPostOperation.youTube.category.people": "Pessoas e blogs",
  "ccmessaging.socialPostOperation.youTube.category.pets": "Animais",
  "ccmessaging.socialPostOperation.youTube.category.science": "Ciência e tecnologia",
  "ccmessaging.socialPostOperation.youTube.category.shortMovies": "Curtas",
  "ccmessaging.socialPostOperation.youTube.category.sport": "Esportes",
  "ccmessaging.socialPostOperation.youTube.category.travel": "Viagens e eventos",
  "ccmessaging.socialPostOperation.youTube.categoryDetail": "Adicione seu viewwix a uma categoria para que os espectadores possam encontrá-lo",
  "ccmessaging.socialPostOperation.youTube.disclosureScope": "Escopo público",
  "ccmessaging.socialPostOperation.youTube.disclosureScopeDetail": "Selecione quem pode ver seu vídeo",
  "ccmessaging.socialPostOperation.youTube.embedding": "Permitir incorporação",
  "ccmessaging.socialPostOperation.youTube.embedding.tips": "Permitir que outras pessoas incorporem seus vídeos em seus sites.",
  "ccmessaging.socialPostOperation.youTube.knowledgePermission": " Creative Commons - Atribuição",
  "ccmessaging.socialPostOperation.youTube.labels": "Etiqueta",
  "ccmessaging.socialPostOperation.youTube.labelsDetail": "Se o conteúdo do vídeo contiver palavras que possam estar incorretas, as tags podem ajudar os espectadores a encontrar o seu vídeo. Além disso, as tags não são muito úteis para encontrar vídeos.",
  "ccmessaging.socialPostOperation.youTube.noKids": "Não é destinado a crianças",
  "ccmessaging.socialPostOperation.youTube.normalPermission": " Licença padrão do YouTube",
  "ccmessaging.socialPostOperation.youTube.permissions": "Licença",
  "ccmessaging.socialPostOperation.youTube.permissionsKnow": "Conheça os tipos de licença.",
  "ccmessaging.socialPostOperation.youTube.scopePrivate": "Privado",
  "ccmessaging.socialPostOperation.youTube.scopePublic": " Público",
  "ccmessaging.socialPostOperation.youTube.scopeUnlisted": "Não listar publicamente",
  "ccmessaging.socialPostOperation.youTube.tags.tips": "Digite uma vírgula após cada tag",
  "ccmessaging.socialPostOperation.youTube.videoToKids": "Este vídeo é destinado a crianças?",
  "ccmessaging.socialPostOperation.youTube.view.commentNum": " 14 comentários",
  "ccmessaging.socialPostOperation.youTube.view.dataResolve": " Análise de dados",
  "ccmessaging.socialPostOperation.youTube.view.editVideo": "Editar vídeo",
  "ccmessaging.socialPostOperation.youTube.view.lookNum": " 38 visualizações 2 dias atrás",
  "ccmessaging.socialPostOperation.youTube.view.orderType": "Ordenar por",
  "ccmessaging.socialPostOperation.youTube.view.share": "Compartilhar",
  "ccmessaging.socialPostOperation.youTube.view.subscriber": " 1 assinante",
  "ccmessaging.socialPostOperation.youTube.view.unfolding": "Expandir ",
  "ccmessaging.socialPostOperation.youTube.yesKids": "Sim, o conteúdo é voltado para crianças",
  "ccmessaging.socialPostOperation.youTube.publishAtTips": "Esta definição só está disponível se o âmbito público estiver definido como Particular",
  "ccmessaging.socialPostOperation.facebook.threeDay": "Três dias",
  "ccmessaging.socialPostOperation.facebook.awful": "Gostou",
  "ccmessaging.socialPostOperation.facebook.comment": "Comentário",
  "ccmessgaing.chat.web.channel.Agent": "Agente de áudio e vídeo",
  "ccmessgaing.chat.web.channel.ivr": "IVR",
  "ccmessgaing.chat.web.channel.webrtc.share": "O compartilhamento de desktop envolve exibição de privacidade. Por favor, confirme.",
  "ccmessgaing.chatbot.message.female": "Feminino",
  "ccmessgaing.chatbot.message.male": "Masculino",
  "ccmessgaing.postChannelConfig.accessToken": "Credenciais de Acesso",
  "ccmessgaing.postChannelConfig.add": "Selecione o tipo de canal",
  "ccmessgaing.postChannelConfig.apiFabricAK": "ApiFabric Identificação",
  "ccmessgaing.postChannelConfig.apiFabricSK": "ApiFabric Chaves",
  "ccmessgaing.postChannelConfig.apiKey": "Chave da API",
  "ccmessgaing.postChannelConfig.appId": "Identificação do cliente",
  "ccmessgaing.postChannelConfig.appKey": "Chave do cliente",
  "ccmessgaing.postChannelConfig.cancel": "Cancelar",
  "ccmessgaing.postChannelConfig.channelConfig": "Configurar canal",
  "ccmessgaing.postChannelConfig.channelCreate": "Criar um canal",
  "ccmessgaing.postChannelConfig.channelEdit": "Editar configuração do canal",
  "ccmessgaing.postChannelConfig.channelType": "Tipo de canal",
  "ccmessgaing.postChannelConfig.confirm": "Confirmação",
  "ccmessgaing.postChannelConfig.addUsers": "Adicionar novo",
  "ccmessgaing.postChannelConfig.createFailed": "Falha ao criar. Verifique os parâmetros do formulário e a conexão de rede",
  "ccmessgaing.postChannelConfig.createSuccess": "Configuração do canal de mídia social criada com sucesso",
  "ccmessgaing.postChannelConfig.delete": "Apagar",
  "ccmessgaing.postChannelConfig.deleteFailed": "Falha ao excluir as informações de configuração do canal de mídia social",
  "ccmessgaing.postChannelConfig.deleteFailed.db": "Falha na exclusão. Falha na operação do banco de dados",
  "ccmessgaing.postChannelConfig.deleteFailed.permission": "Falha ao excluir, falha na verificação de permissão",
  "ccmessgaing.postChannelConfig.deleteFailed.inUse": "Falha ao excluir. Existem informações de publicação no canal",
  "ccmessgaing.postChannelConfig.deleteSuccess": "Sucesso na exclusão das informações de configuração do canal de mídia social",
  "ccmessgaing.postChannelConfig.edit": "Editar",
  "ccmessgaing.postChannelConfig.editFailed": "Falha na atualização. Verifique os parâmetros do formulário e a conexão de rede",
  "ccmessgaing.postChannelConfig.editSuccess": "Atualizando as informações de configuração do canal de mídia social com sucesso",
  "ccmessgaing.postChannelConfig.failed": "Falhou",
  "ccmessgaing.postChannelConfig.maxLength": "Tamanho máximo de {n} caracteres",
  "ccmessgaing.postChannelConfig.operation": "Operação",
  "ccmessgaing.postChannelConfig.operation.person": "Pessoal de Operações",
  "ccmessgaing.postChannelConfig.placeSelectChannelType": "Selecione o tipo de canal",
  "ccmessgaing.postChannelConfig.postChannelName": "Nome do canal",
  "ccmessgaing.postChannelConfig.refreshToken": "Atualizar Credenciais",
  "ccmessgaing.postChannelConfig.repeatedly": "Canal já existe",
  "ccmessgaing.postChannelConfig.users.overSize": "Mais de 10 funcionários de operações",
  "ccmessgaing.postChannelConfig.selectByChannelName": "Nome do canal",
  "ccmessgaing.postChannelConfig.subChannelId": "Identificação do canal",
  "ccmessgaing.postChannelConfig.success": "Sucesso",
  "ccmessgaing.postChannelConfig.unauthorized": "Operação não certificada",
  "chat.agent.chatOnlineWorkbench": "Workbench de bate-papo online",
  "chat.agent.label.balance": "Receita e Despesa",
  "chat.agent.label.billDetail": "Detalhes da fatura",
  "chat.agent.label.chatBot": "Chatbot",
  "chat.agent.label.consumption": "Meu consumo",
  "chat.agent.label.customerSource": "Customer source: Mobile App - Personal Center",
  "chat.agent.label.downagainfile": "Baixar novamente",
  "chat.agent.label.goAway": "Longe",
  "chat.agent.label.historicalBill": "Faturamento Histórico",
  "chat.agent.label.money": "Quantidade",
  "chat.agent.label.mybill": "Minhas contas",
  "chat.agent.label.offline": "Desligado",
  "chat.agent.label.online": "Online",
  "chat.agent.label.recivefile": "Receber",
  "chat.agent.label.refusefile": "Recusa",
  "chat.agent.label.send": "Enviar",
  "chat.agent.label.total": "Total",
  "chat.agent.label.waiting": "Aguarde",
  "chat.agent.message.changeFailed": "Falha ao alterar o status do agente.",
  "chat.agent.title.changeWorkMode": "Alterar estado",
  "chat.agent.title.close": "Fechar",
  "chat.agent.title.createCase": "Criar caso",
  "chat.agent.title.createSkill": "Create Skill",
  "chat.agent.title.enterText": "por favor, entre",
  "chat.agent.title.OfflineCall": "Chamada off-line",
  "chat.agent.title.transfer": "Transferir para",
  "chat.agent.title.userInformation": "Customer Info",
  "chat.agent.title.userMenu": "Customer Menu",
  "chat.agent.title.workMode": "modo de trabalho",
  "chat.agentConsole.message.content.audio": "[Mensagem de voz]",
  "chat.agentConsole.message.content.connectMessage": "Uma nova mensagem!",
  "chat.agentConsole.message.content.image": "[Mensagem Imagem]",
  "chat.agentConsole.message.content.locate": "[Mensagem de Localização]",
  "chat.agentConsole.message.content.video": "[Mensagem de vídeo]",
  "chat.agentConsole.message.error.emptyMessage": "[A mensagem não contém conteúdo que possa ser mostrado.]",
  "chat.agentConsole.message.error.notification": "A nova notificação de mensagem não pode ser criada. Verifique a configuração do navegador!",
  "chat.client.alert.message.invildtoken": "Sua caixa de diálogo expirou o tempo limite ou você entrou em outro lugar. Feche a janela de chat e tente novamente. Obrigado!",
  "chat.client.button.submit": "Enviar",
  "chat.client.evaluate.message": "Por favor, comentem sobre o meu serviço. Obrigado!",
  "chat.client.greeting.afternoon": "Boa tarde!",
  "chat.client.greeting.dear": "Querido",
  "chat.client.greeting.evening": "Boa noite!",
  "chat.client.greeting.info": "O que posso fazer por você?",
  "chat.client.greeting.morning": "Bom dia!",
  "chat.client.label.accountNumber": "Conta",
  "chat.client.label.bill": "Minhas contas",
  "chat.client.label.chatTitle": "Cliente",
  "chat.client.label.download": "Baixar",
  "chat.client.label.evaluation": "Pesquisa de Satisfação",
  "chat.client.label.forgotPassword": "Esqueceu-se da senha",
  "chat.client.label.header": "Telco+",
  "chat.client.label.input": "por favor, entre",
  "chat.client.label.like": "Você pode gostar",
  "chat.client.label.login": "Iniciar sessão",
  "chat.client.label.loginTitle": "Iniciar sessão",
  "chat.client.label.more": "Mais",
  "chat.client.label.no": "Não",
  "chat.client.label.order": "Meus pedidos",
  "chat.client.label.password": "Senha",
  "chat.client.label.privacyHint": "Aviso para proteção de privacidade",
  "chat.client.label.privacyHintContent": "Para melhorar a qualidade do serviço, sua conversa será gravada pelo sistema. Tem certeza de que deseja continuar?",
  "chat.client.label.promotions": "Solicitar",
  "chat.client.label.rateService": "Serviço de Pontuação",
  "chat.client.label.system": "O sistema está ocupado. Por favor, tente novamente mais tarde.",
  "chat.client.label.yes": "Sim",
  "chat.client.message.evaluationFail": "A pesquisa de satisfação falhou.",
  "chat.client.message.evaluationSuccess": "A pesquisa de satisfação foi bem-sucedida.",
  "chat.client.message.gladtoServe": ". O que posso fazer por você?",
  "chat.client.message.hiMyWorkno": "Olá",
  "chat.client.message.noAgent": "Desculpa, o agente está ocupado. Tente novamente mais tarde.",
  "chat.client.transfer.link": "Are you sure you want to transfer the call to an agent?",
  "chat.common.message.cancel": "Cancelar",
  "chat.common.message.create": "Criar",
  "chat.common.message.dateEnd": "Hora de término",
  "chat.common.message.dateStart": "Hora de início",
  "chat.common.message.delete": "Excluir",
  "chat.common.message.error": "Erro",
  "chat.common.message.fail": "Falha",
  "chat.common.message.finish": "Ok",
  "chat.common.message.modify": "Modificar",
  "chat.common.message.promotions": "Informação",
  "chat.common.message.success": "Sucesso",
  "chat.common.message.warning": "Aviso",
  "chat.confirm.no": "Não ",
  "chat.confirm.ok": "Confirmação",
  "chat.confirm.yes": "Sim ",
  "chat.session.channel.all": "Todos",
  "chat.session.channel.facebook": "Facebook",
  "chat.session.channel.mobile": "Telefone Celular",
  "chat.session.channel.twitter": "Twitter",
  "chat.session.channel.web": "Web",
  "chat.session.channel.wechat": "WeChat",
  "chat.session.endType.agentEnd": "Finalizado pelo agente",
  "chat.session.endType.customerEnd": "Finalizado pelo cliente",
  "chat.session.endType.overtimeEnd": "Terminado por timeout",
  "chat.session.label.alias": "Customer Name",
  "chat.session.label.allChannel": "Todos os tipos de canais",
  "chat.session.label.allEndType": "Todos os tipos de extremidade",
  "chat.session.label.channel": "Canal de acesso da caixa de diálogo",
  "chat.session.label.created_endTime": "Hora de término",
  "chat.session.label.created_startTime": "Hora de início",
  "chat.session.label.createdTime": "Horário de criação de diálogo",
  "chat.session.label.endReason": "Causa final da caixa de diálogo",
  "chat.session.label.endTime": "Hora final da caixa de diálogo",
  "chat.session.label.endType": "Tipo de extremidade da caixa de diálogo",
  "chat.session.label.enterQueueTime": "Tempo de entrada em fila",
  "chat.session.label.evaluation": "Avaliação da caixa de diálogo",
  "chat.session.label.evaluationTime": "Horário de avaliação da caixa de diálogo",
  "chat.session.label.exitQueueTime": "Tempo de enfileiramento",
  "chat.session.label.remark": "Comentário",
  "chat.session.label.reset": "Redefinir",
  "chat.session.label.search": "Pesquisar",
  "chat.session.label.servedAgent": "Agente de serviço",
  "chat.session.label.sessionId": "ID da caixa de diálogo",
  "chat.session.label.skillQueueId": "ID da fila de habilidades",
  "chat.session.label.skillQueueName": "Nome da fila de habilidades",
  "chat.session.label.subChannel": "Subcanal de acesso",
  "chat.session.title.basic": "Informação Básica de Diálogo",
  "chat.session.title.detail": "Detalhes da caixa de diálogo",
  "chat.session.title.history": "Registros históricos de bate-papo",
  "chat.skill.label.account": "Conta",
  "chat.skill.label.agents": "Agente",
  "chat.skill.label.cancel": "Cancelar",
  "chat.skill.label.createSkill": "Criar",
  "chat.skill.label.createSkillTab": "Create Skill",
  "chat.skill.label.delete": "Excluir",
  "chat.skill.label.deleteSkills": "Excluir",
  "chat.skill.label.description": "Descrição",
  "chat.skill.label.edit": "Editar",
  "chat.skill.label.inputName": "Nome",
  "chat.skill.label.maxQueueNumber": "Número máximo da fila",
  "chat.skill.label.maxQueueTime": "Tempo máximo de fila (s)",
  "chat.skill.label.maxQueueTimeInTable": "Tempo máximo de fila",
  "chat.skill.label.name": "Nome do usuário",
  "chat.skill.label.ok": "OK",
  "chat.skill.label.priority": "Prioridade",
  "chat.skill.label.reset": "Redefinir",
  "chat.skill.label.save": "Salvar",
  "chat.skill.label.skill": "Habilidade",
  "chat.skill.label.skillDetail": "Detalhes da habilidade",
  "chat.skill.label.skillId": "Não",
  "chat.skill.label.skillName": "Nome",
  "chat.skill.label.submit": "Enviar",
  "chat.skill.message.completeInputs": "Preencha todas as entradas.",
  "chat.skill.message.createFail": "Falha ao criar a habilidade.",
  "chat.skill.message.createSuccess": "Created the skill successfully.",
  "chat.skill.message.delete": "Excluir",
  "chat.skill.message.error": "Erro",
  "chat.skill.message.failDeleteSkill": "Falha ao excluir a habilidade.",
  "chat.skill.message.isExisted": "A fila de habilidades já existe.",
  "chat.skill.message.priorityLength": "Certifique-se de que a prioridade seja menor ou igual a cinco dígitos.",
  "chat.skill.message.saveFail": "Falha ao salvar a habilidade.",
  "chat.skill.message.saveSuccess": "Salvou a habilidade com sucesso.",
  "chat.skill.message.selectSkills": "Selecione uma habilidade.",
  "chat.skill.message.success": "Sucesso",
  "chat.skill.message.warning": "Aviso",
  "chat.skill.message.willDeleteAllSkills": "Tem certeza de que deseja excluir todas as habilidades?",
  "chat.skill.message.willDeleteSkill": "Tem certeza de que deseja excluir a habilidade?",
  "chat.skill.message.willDeleteSkills": "Are you sure you want to delete the selected skills?",
  "chat.thirdportal.chatWindow.button.startChat": "Iniciar bate-papo",
  "chat.thirdportal.chatWindow.label.userEmail": "Email",
  "chat.thirdportal.chatWindow.label.userName": "Nome",
  "chat.thirdportal.chatWindow.label.userPhone": "Número",
  "chat.thirdportal.title": "Portal Web do Cliente",
  "chat.timerange.label.alldays": "Todos os Tempos de Criação",
  "chat.timerange.label.confirmSocailChatNum": "O número máximo de clientes de serviço simultâneo é 0 usará a configuração global",
  "chat.timerange.label.customizes": "Customized",
  "chat.timerange.label.ok": "OK",
  "chat.timerange.label.oneday": "Último dia",
  "chat.timerange.label.sevendays": "Últimos 7 dias",
  "chat.timerange.label.thirtydays": "Últimos 30 dias",
  "chat.timerange.label.threedays": "Últimos 3 dias",
  "chat.timerange.message.timeempty": "O tempo não pode ficar vazio.",
  "chat.timerange.message.timeerror": "O formato da hora está incorreto.",
  "chat.usefulexpressions.label.createExpressionTab": "Create Common Phrase",
  "chat.usefulexpressions.label.expression": "Common Phrases",
  "chat.usefulexpressions.label.expressionDetail": "Common Phrase Details",
  "chat.usefulexpressions.message.contentLength": "Certifique-se de que o comprimento da frase comum seja menor ou igual a 1000 caracteres.",
  "chat.usefulexpressions.message.contentNotNull": "A frase comum não pode estar vazia.",
  "chat.usefulexpressions.message.createFail": "Falha ao criar a frase comum.",
  "chat.usefulexpressions.message.createSuccess": "Created the common phrase successfully.",
  "chat.usefulexpressions.message.failDeleteExpression": "Falha ao excluir a frase comum.",
  "chat.usefulexpressions.message.isExisted": "O nome da frase comum já existe.",
  "chat.usefulexpressions.message.saveFail": "Falha ao salvar a frase comum.",
  "chat.usefulexpressions.message.saveSuccess": "Salvado a frase comum com sucesso.",
  "chat.usefulexpressions.message.selectExpressions": "Selecione uma frase comum.",
  "chat.usefulexpressions.message.willDeleteExpression": "Are you sure you want to delete the common phrase?",
  "chat.usefulexpressions.message.willDeleteExpressions": "Are you sure you want to delete the selected common phrase?",
  "chat.web.evaluation.star1": "Muito insatisfeito",
  "chat.web.evaluation.star2": "Insatisfeito",
  "chat.web.evaluation.star3": "Indiferente",
  "chat.web.evaluation.star4": "Satisfeito",
  "chat.web.evaluation.star5": "Muito satisfeito",
  "chat.webconfig.button.save": "Salvar",
  "chat.webconfig.label.bandingskill": "Vincular Fila de habilidades",
  "chat.webconfig.label.basesettings": "Configuração básica",
  "chat.webconfig.label.functionalsettings": "Configurações da função",
  "chat.webconfig.label.greeting": "Saudação",
  "chat.webconfig.label.greeting.placeholder": "Insira o conteúdo da saudação exibida na janela de bate-papo do cliente.",
  "chat.webconfig.label.intergratedcode": "Código integrado",
  "chat.webconfig.label.intergratemsg": "Integrar chat ao vivo ao seu site",
  "chat.webconfig.label.language": "Default Language",
  "chat.webconfig.label.language.en": "Inglês",
  "chat.webconfig.label.language.zh": "Chinês",
  "chat.webconfig.label.language.pt": "Português",
  "chat.webconfig.label.transHuman": "Transferir para Agente",
  "chat.webconfig.label.windowTitle": "Chat Window Title",
  "chat.webconfig.label.windowTitle.placeholder": "Insira o título exibido na janela de bate-papo no cliente.",
  "chat.webconfig.message.configdirective": "Copy the following code and paste it before your website label </body>. Save and release the code to complete the quick access to Huawei Service Cloud. After the release, the Huawei Service Cloud entry is displayed in the lower right corner of your website. You can click it to talk with the customer service workbench in real time.",
  "chat.webconfig.message.demodirective": "Você também pode abrir o seguinte endereço na janela oculta do navegador Chrome para acessar a página de apresentação fornecida pelo sistema:",
  "chat.webconfig.message.saveFail": "Falha ao salvar a configuração.",
  "chat.webconfig.message.saveSuccess": "Salvada configuração com sucesso.",
  "chat.webconfig.title": "Ativar canal Web",
  "chat.weChatConfig.label.appId": "ID do desenvolvedor",
  "chat.weChatConfig.label.AppSecret": "Senha do desenvolvedor",
  "chat.weChatConfig.label.bindSkill": "Vincular Fila de habilidades",
  "chat.weChatConfig.label.codeName": "Código de Acesso ao Canal",
  "chat.weChatConfig.label.configId": "Configuration ID",
  "chat.weChatConfig.label.create": "Novo",
  "chat.weChatConfig.label.delete": "Excluir",
  "chat.weChatConfig.label.Domain": "Endereço do servidor",
  "chat.weChatConfig.label.facebook": "Facebook",
  "chat.weChatConfig.label.nextStep": "Próximo",
  "chat.weChatConfig.label.qcode": "Código QR",
  "chat.weChatConfig.label.save": "Salvar",
  "chat.weChatConfig.label.search": "Pesquisar",
  "chat.weChatConfig.label.secret": "Chave",
  "chat.weChatConfig.label.state": "Status",
  "chat.weChatConfig.label.tenantId": "ID do inquilino",
  "chat.weChatConfig.label.verify": "Verificação",
  "chat.weChatConfig.label.verifycode": "Token",
  "chat.weChatConfig.label.verifying": "Verificando",
  "chat.weChatConfig.label.wechat": "WeChat",
  "chat.weChatConfig.link.wechat": "Servidor do WeChat",
  "chat.weChatConfig.message.appIdExisted": "A ID do desenvolvedor de conta pública inserida já existe.",
  "chat.weChatConfig.message.codeNameExisted": "O código de acesso do canal já existe.",
  "chat.weChatConfig.message.deleteError": "Falha ao excluir o item de configuração.",
  "chat.weChatConfig.message.emptyDeleteArray": "Selecione o item de configuração a ser excluído.",
  "chat.weChatConfig.message.ensureDelete": "Are you sure you want to delete the selected configuration item?",
  "chat.weChatConfig.message.error": "Erro",
  "chat.weChatConfig.message.savesuccess": "Acessado com sucesso.",
  "chat.weChatConfig.message.skillExisted": "A fila de habilidades selecionada foi vinculada a outra conta pública.",
  "chat.weChatConfig.message.unknown": "O status da verificação é desconhecido.",
  "chat.weChatConfig.stepname.selectchannel": "Selecionar Canal de Acesso",
  "chat.weChatConfig.stepname.selectmode": "Selecionar método de configuração",
  "chat.weChatConfig.stepname.success": "Acesso bem-sucedido",
  "chat.weChatConfig.stepname.verify": "Submeter para verificação",
  "chat.weChatConfig.title": "Configuração de Habilitação de Mídia Social",
  "chat.weChatConfig.title.deleteTitle": "Excluir Itens de Configuração do WeChat",
  "cmessaging.chat.answerUser.error": "Falha ao criar sessão de usuário",
  "cmessaging.chat.answerUser.Tips": "Criar uma conversa do usuário",
  "cmessaging.chat.channelconfig.isPushWebMessage": "Mensagem push ",
  "cmessaging.chat.channelconfig.pushWebMessageKey": "chave de envio de mensagem ",
  "cmessaging.chat.channelconfig.pushWebMessageUrl": "Endereço para envio de mensagens ",
  "cmessaging.chat.channelconfig.pushWebCertTypeSignature": "Autenticação de assinatura",
  "cmessaging.chat.channelconfig.pushWebCertTypeOAuth": "Autenticação OAuth",
  "cmessaging.chat.channelconfig.setAgentTimeout": "Transferência de sessão devido a falta de resposta do agente",
  "cmessaging.chat.multimedia.numOverMaxSum": "A quantidade de dados multimídia que você salvou excede o limite ",
  "cmessaging.chat.multimediatype.numOverMaxSum": "O número de categorias salvas excedeu o limite ",
  "ccmessaging.socialPostOperation.search.searchContent": "Por favor, digite o conteúdo",
  "ccmessaging.socialPostOperation.link": "Acesse o texto original",
  "ccmessaging.chat.social.media.enterprise.post.load.failed": "Falha ao carregar o anexo",
  "ccmessaging.chat.multimedia.imageUploadChannelFormat": "Os canais do WhatsApp, Web, Telegram, LINE, WeChat, Facebook e Twitter dão suporte aos formatos JPG, PNG e JPEG.",
  "ccmessaging.chat.multimedia.audioUploadChannelFormat": "O canal do WhatsApp dá suporte ao formato ACC.\nO canal da Web dá suporte aos formatos MP3 e AAC.\nOs canais do Instagram e do LINE dão suporte ao formato M4A.\nOs canais do WeChat e do Facebook dão suporte ao formato MP3.\nO canal do Telegram dá suporte aos formatos MP3 e M4A.",
  "ccmessaging.chat.multimedia.documentUploadChannelFormat": "O canal do WhatsApp dá suporte aos formatos DOC, DOCX, PDF, PPT, PPTX, XLSX e XLS.\nO canal da Web dá suporte aos formatos DOC, DOCX, PDF e ZIP.\nO canal do Telegram dá suporte aos formatos PDF e ZIP.",
  "ccmessaging.chat.channelconfig.searchChannelName": "Pesquisa pelo nome do canal ",
  "ccmessaging.chat.channelconfig.createChannel": "Novo canal ",
  "ccmessaging.chat.channelconfig.modifyChannel": "Modificar canal ",
  "ccmessaging.chat.channelconfig.channelIntegrate": "integração de canais ",
  "ccmessaging.chat.channelconfig.baseConfig": "Configuração básica ",
  "ccmessaging.chat.channelconfig.robotConfig": "Configuração do robô ",
  "ccmessaging.chat.channelconfig.chooseChannel": " Seleção de canal ",
  "ccmessaging.chat.channelconfig.range.placeholder": "{0}-{1}",
  "ccmessaging.chat.channelconfig.advanceConfig": "Mais",
  "ccmessaging.chat.channelconfig.web.clickToCall": "Clique para chamar",
  "ccmessaging.chat.channelconfig.time.timeNoRange": "No Período {n}, a hora de início deve ser anterior à hora de término.",
  "ccmessaging.chat.channelconfig.time.timeCross": "Período {0} sobrepõe-se ao Período {1}..",
  "ccmessaging.chat.channelconfig.time.timePeriod0": "Período {0} sobrepõe-se ao Período {1}.",
  "ccmessaging.chat.channelconfig.time.timePeriod": "Preencha ou exclua Período {n}",
  "ccmessaging.silentagent.button.create": "Novo",
  "ccmessaging.silentagent.dialog.title.create": "Novo Agt vocal silencieux",
  "ccmessaging.silentagent.dialog.title.edit": "Editar Agt vocal silencieux",
  "ccmessaging.silentagent.dialog.title.view": "Exibir Agt vocal silencieux",
  "ccmessaging.silentagent.delete.selectone": "Selecione pelo menos um registro.",
  "ccmessaging.silentagent.delete.select.morethan.100": "Um máximo de 100 registros de dados podem ser excluídos por vez.",
  "ccmessaging.silentagent.placeholder.selectaudioskill": "Selecione uma fila de habilidades de voz.",
  'ccmessaging.silentagent.btn.add.period': 'adicionar período de tempo',
  'ccmessaging.silentagent.agent.workday.worktime.error': 'A configuração do tempo de trabalho do agente está incorreta. Os segmentos de tempo se sobrepõem.',
  'ccmessaging.silentagent.agent.holiday.worktime.error': 'A configuração de tempo de não trabalho do agente está incorreta. Os segmentos de tempo se sobrepõem.',
  'ccmessaging.silentagent.agent.workday.worktime.empty': 'A configuração de tempo de trabalho do agente tem configuração vazia.',
  'ccmessaging.silentagent.agent.holiday.worktime.empty': 'A configuração de tempo de não trabalho do agente tem a configuração vazia.',
  "ccmessaging.silentagent.voiceaccess.empty": "A configuração de rota chamada IVR de voz não pode estar vazia",
  "ccmessaging.chat.channelconfig.searchCertCode": "Pesquisar o código do certificado",
  "ccmessaging.chat.channelconfig.cloudApi.accessToken.tips": "O token de acesso é o token de acesso gerado pela plataforma do desenvolvedor para acessar a API da nuvem. Por favor\n, diminua o período de validade em 2 dias\n, por exemplo, 60 dias. É recomendável preencher 58 dias. Se ficar em branco\n, é um token permanente",
  "ccmessaging.chat.channelconfig.cloudApi.bsp.tips": "Atualmente, a API do Whatsapp Cloud se conecta apenas a mensagens de texto",
  "ccmessaging.chat.channelconfig.checkWhatsAppAccessTokenDuration": "O tempo de validade do token de acesso deve ser um inteiro positivo entre 1 e 60",
  "ccmessaging.chat.channelconfig.copy": "Copiar",
  "ccmessaging.chat.channelconfig.copySuccess": "Cópia bem-sucedida",
  "ccmessaging.chat.sumbitverification.whatsApp.cloudapi.tips": "Por favor, configure o URL e o código de verificação no aplicativo whatsapp da plataforma de desenvolvedores do Facebook",
  "ccmessaging.chatbot.label.useIntelligentRobot": "Acessar um robô inteligente",
  "ccmessaging.chatbot.label.useRobotAssistants": "Acessar o assistente do robô",
  "ccmessaging.chatbot.label.update": "atualizar",
  "ccmessaging.chatbot.label.pleaseSelect": "Selecione",
  "ccmessaging.chatbot.label.pleaseInput": "Digite",
  "ccmessaging.chatbot.label.intelligentRobotAccessCode": "Código de acesso do robô inteligente",
  "ccmessaging.chatbot.label.selectIntelligentRobot": "Selecionar robô inteligente",
  "ccmessaging.chatbot.label.selectRobotAssistants": "Selecionar assistente do robô",
  "ccmessaging.chatbot.check.systeam": "sistema",
  "ccmessaging.chatbot.label.robotName": "Nome do robô",
  "ccmessaging.chatbot.label.selectRobotAvatar": "Selecionar avatar do robô",
  "ccmessaging.fiveG.label.chatRobotAddress": "Endereço do chatbot",
  "ccmessaging.fiveG.label.appId": "Identidade do aplicativo",
  "ccmessaging.fiveG.label.appSecret": "Segredo do aplicativo",
  "ccmessaging.fiveG.label.certCode": "Arquivo de certificado",
  "ccmessaging.email.label.selectGateway": "Selecionar gateway",
  "ccmessaging.email.label.gatewayName": "Nome do gateway",
  "ccmessaging.email.label.InputGatewayName": "Digite o nome do gateway",
  "ccmessaging.email.label.defaultReceiveGateway": "E-mail de recebimento padrão",
  "ccmessaging.email.label.defaultSendGateway": "E-mail de envio padrão",
  "ccmessaging.sms.label.defaultSendGateway": "Número de envio padrão",
  "ccmessaging.sms.label.defaultReceiveGateway": "Número de recebimento padrão",
  "ccmessaging.email.valid.signatureSearch": "Apenas 10 letras ou números são permitidos",
  "ccmessaging.service.time.label": "Configuração do Calendário de Trabalho",
  "ccmessaging.service.time.choose.time": "Selecionar dias não úteis",
  "ccmessaging.service.time.select.holiday": "Selecionar datas de descanso",
  "ccmessaging.service.time.select.weekday": "Selecionar datas de trabalho",
  "ccmessaging.multimedia.tip.not.exist": "O recurso multimídia não existe",
  "ccmessaging.multimedia.tip.not.modified": "O recurso multimídia foi modificado. Por favor, envie novamente",
  "ccmessaging.service.time.close.alert": "Fechar irá limpar todos os dados desta tabela. Tem certeza de que deseja fechar",
  "ccmessaging.chat.phrase.create": "Criar Frases Comuns",
  "ccmessaging.chat.phrase.update": "Editar Frases Comuns",
  "ccmessaging.chat.specialChannel.create": "Nova regra de lista especial",
  "ccmessaging.chat.specialChannel.update": "Editar Regras de Listas Especiais",
  "ccmessaging.emailAdress.contentNotNull": "O endereço de e-mail não pode estar vazio",
  "ccmessaging.smsNumber.contentNotNull": "O número de telemóvel não pode estar em branco",
  "ccmessaging.callcenterinstanceslist.vcallcenterDeatil.passValidateFailed": "A senha deve conter pelo menos três tipos dos seguintes: letras maiúsculas, letras minúsculas, dígitos e characters: {n}",
  "ccmessaging.chat.shortcut.shortcutRepeat": "Teclas de atalho já estão em uso",
  "ccmessaging.chat.multimedia.agentHangupSessionResult": "Sessão encerrada com sucesso. Por favor, feche o tíquete de CRM",
  "ccmessaging.chat.multimedia.agentAutoTransferResult": "A chamada foi transferida com sucesso. Por favor, feche o tíquete de CRM",
  "ccmessaging.chat.multimedia.agentTimeOutTransferResult": "Tempo limite da sessão para transferir a fila de habilidades, por favor, feche o tíquete de CRM",
  "ccmessaging.chat.multimedia.userHangupSessionResult": "O usuário encerrou a sessão, por favor, feche o tíquete de CRM",
  "ccmessaging.chat.multimedia.userTimeOutHangupResult": "A sessão expirou. Por favor, feche o tíquete de CRM",
  "ccmessaging.chat.multimedia.agentLogoutHangupResult": "O agente fez logout forçado. Sessão encerrada. Feche o tíquete de CRM",
  "ccmessaging.chat.dy.reconnect": "Reconectar",
  "ccmessaging.chat.dy.uploadLocalFile": 'Arquivos',
  "ccmessaging.chat.dy.call.end": 'Sessão encerrada',
  "ccmessaging.chat.richtext.httpTip": "O link inserido usa um protocolo inseguro, o que representa um risco de segurança. Submeter?",
  "ccmessaging.chat.riskTip": "Aviso de risco",
  "ccmessaging.chat.chatcard.location": "Endereço",
  "ccmessaging.chat.chatcard.alignment": "Estilo de exibição do cartão",
  "ccmessaging.chat.chatcard.templateType": "tipo de modelo",
  "ccmessaging.chat.chatcard.templateTypeList0": "Tipo comum",
  "ccmessaging.chat.chatcard.templateTypeList1": "opções de cartão",
  "ccmessaging.chat.chatcard.tabMediaResource": "Propriedade da imagem",
  "ccmessaging.chat.chatcard.tabnamevalidate": "redireccionamentoUrl é o padrão do sistema e não é permitido",
  "ccmessaging.chat.chatcard.transverse": "Horizontal",
  "ccmessaging.chat.chatcard.longitudinal": "retrato",
  "ccmessaging.chat.voice.record.send": "Enviar voz",
  "ccmessaging.chat.voice.record.start": "Clique para gravar",
  "ccmessaging.chat.voice.record.tip": "Tempo de gravação não superior a 10 minutos",
  "ccmessaging.chat.voice.record.stop": "Parar gravação",
  "ccmessaging.chat.selectorgs.maxlimit.warn": "จํานวนองค์กรที่เลือกต้องไม่เกิน 100 องค์กร",
  "ccmessaging.chat.chatconfig.deleteFacebookPage": "Excluir página do Facebook",
  "ccmessaging.chat.search.name": "Pesquisar Nome",
  "ccmessaging.chat.search.phraseName": "Frases comuns",
  "ccmessaging.chat.channelconfig.asyncTime.tip": "Se o período após o cenário off-line ser disparado exceder esse valor, as mensagens off-line do cliente serão armazenadas diretamente e não serão mais roteadas para os agentes.",
  "ccmessaging.chat.channelconfig.noReplyTime.tip": "Se o período após a última vez em que um cliente contatou um agente exceder esse valor, o agente não poderá fazer uma chamada para o cliente.",
  "ccmessaging.chat.channelconfig.noReplyIntervalTime.tip": "No cenário de chamada efetuada, se um cliente não responder nesse período, a sessão será encerrada automaticamente.",
  "ccmessaging.chat.email.attachment.tips.maxOnceUpload": "É possível carregar até cinco anexos.",
  "ccmessaging.chat.email.attachment.tips.maxTotalUpload": "O número de anexos carregados excede 10.",
  "ccmessaging.chat.voice.record": "Microfone",
  "ccmessaging.chat.offline.userIsInService": "Usuário do serviço de agente existente",
  "ccmessaging.chat.channelconfig.search.by.robotNameKeyword": "Procurar o nome do bot ou código de acesso",
  "ccmessaging.chat.channelconfig.searchSkillQueueName": "Nome da Fila de Habilidades de Pesquisa",
  "ccmessaging.chat.user.online": "O usuário está online.",
  "ccmessaging.chat.async.echarts.user": "Número de pessoas (vezes)",
  "ccmessaging.chat.async.echarts.message": "Mensagem(s)",
  "cobrowse.sessionrecord.roomId": "número do quarto",
  "cobrowse.sessionrecord.searchroomId": "Por favor, insira o número do quarto",
  "cobrowse.sessionrecord.workNo": "número do agente",
  "cobrowse.sessionrecord.searchworkNo": "Por favor, insira o número do agente",
  "cobrowse.sessionrecord.caller": "Informações do chamador",
  "cobrowse.sessionrecord.searchcaller": "Insira as informações de chamada",
  "cobrowse.sessionrecord.startAt": "Hora de início",
  "cobrowse.sessionrecord.endAt": "Fim do tempo",
  "cobrowse.sessionrecord.duration": "duração",
  "cobrowse.sessionrecord.positiveInteger.checkmsg": "inserir um inteiro positivo",
  "cobrowse.sensitiveinfo.pageMatchValue": "configuração de correspondência de página",

  "cobrowse.sensitiveinfo.rulenamecheck": "O nome da regra pode conter apenas _. alfanuméricos e um máximo de 128 caracteres",
  "cobrowse.sensitiveinfo.url.check": "O caminho de URL configurado para correspondência de página está no formato incorreto",
  "cobrowse.sensitiveinfo.cssselectorcheck": "Erro de sintaxe do seletor CSS",
  "cobrowse.sensitiveinfo.pageIdCheck": "O ID da página pode conter apenas letras, dígitos, _-:. e um máximo de 1024 caracteres.",
  "cobrowse.sensitiveinfo.create": "Criar uma nova regra de informações confidenciais",
  "cobrowse.sensitiveinfo.edit": "Editar uma nova regra de informações confidenciais",
  "cobrowse.sensitiveinfo.createnameexist": "nome já existe",
  "cobrowse.sensitiveinfo.exceedmaxcount": "Até 100 regras confidenciais podem ser configuradas por locatário",
  "cobrowse.sensitiveinfo.delete": "Excluir regra de informações confidenciais",
  "cobrowse.sensitiveinfo.willdeletetips": "Tem certeza de que deseja excluir a regra de informações confidenciais selecionada?",
  "cobrowse.sensitiveinfo.deletenum.outstripping": "No máximo 100 registros podem ser excluídos por vez",
  "cobrowse.common.tips.selectempty": "Por favor, selecione os dados para operar primeiro",
  "cobrowse.sessionrecord.workNo.rules": "Precisa estar na faixa de 1 - 65535",
  "ccmessaging.chat.chatconfig.batch.delete": "Exclusão em massa",
  "ccmessaging.chat.chatconfig.create": "Novo",
  "ccmessaging.channelconfig.export.datascope": "Escopo de dados",
  "ccmessaging.channelconfig.export.allscope": "Todos",
  "ccmessaging.channelconfig.export.curPage": "Página atual",
  "ccmessaging.channelconfig.export.customField": "Campo personalizado",
  "ccmessaging.channelconfig.export.selectedField": "Campo Exportar",
  "ccmessaging.channelconfig.export.selectAll": "Todos",
  "ccmessaging.channelconfig.export.zipPwd": "Senha do arquivo",
  "ccmessaging.channelconfig.export.zipPwdHint": "Digite a senha de compactação",
  "ccmessaging.channelconfig.export.pwdMsg": "Digite a senha usada para gerar o arquivo compactado. A senha compactada deve conter de 8-12 caracteres , conter letras , números , caracteres especiais , caracteres especiais excluindo {'|;&$-<>/'}{'\\\\'}{'`!#(){}'} espaços, caracteres de nova linha e tabulações",
  'ccmessaging.channelconfig.export.pwdMsgError': "8-12 caracteres, que devem conter letras , caracteres especiais , caracteres especiais excluindo {'|;&$-<>/'}{'\\\\'}{'`!#(){}'} espaços, caracteres de nova linha e tabulações",
  "ccmessaging.channelconfig.export": "Exportar",
  "ccmessaging.channelconfig.viewExportTask": "Exibição de tarefa de exportação",
  "ccmessaging.channelconfig.export.title": "Configuração do canal de exportação",
  'ccmessaging.channelconfig.export.exportAll': 'Você não selecionou registros específicos. Exportar todos os registros? Você também pode cancelar a operação e selecionar um registro específico para exportar.',
  'ccmessaging.channelconfig.export.exportAllTitle': "Exportar todas as configurações",
  "ccmessaging.channelconfig.export.allExportConfirm": "Todas as exportações",
  'ccmessaging.channelconfig.export.exportSelect': "Você selecionou {0} registros. Tem certeza de que deseja continuar?",
  "ccmessaging.channelconfig.export.exportBeginTime": "Hora de início da exportação",
  "ccmessaging.channelconfig.export.exportEndTime": "Hora final da exportação",
  "ccmessaging.channelconfig.export.exportTaskStatus": "Status",
  "ccmessaging.channelconfig.export.exporting": "Exportando",
  "ccmessaging.channelconfig.export.unprocessed": "Não processadas",
  'ccmessaging.channelconfig.export.resultMsg': 'A tarefa de exportação foi criada com sucesso. Deseja ver a lista de exportação?',
  "ccmessaging.channelconfig.export.resultSuccess": "Criação com sucesso.",
  "ccmessaging.channelconfig.export.exception": "Ocorreu um erro ao gerar a tarefa de exportação. Verifique os registos.",
  "ccmessaging.channelconfig.export.error": "Nova tarefa de exportação falhou, verifique o registro",
  "ccmessaging.channelconfig.export.paramInvalid": "Falha ao verificar as condições de exportação.",
  "ccmessaging.channelconfig.export.exceedlimit": "No máximo {0} registros de dados podem ser exportados",
  "ccmessaging.channelconfig.export.nodata": "Nenhuma configuração de canal atende às condições.",
  "ccmessaging.chat.holiday.deleteWorkDay": "Excluir datas de trabalho especificadas",
  "ccmessaging.chat.holiday.deleteAllWorkDay": "Excluir todas as datas de trabalho especificadas",
  "ccmessaging.chat.holiday.deleteHoliday": "Excluir uma data de descanso especificada",
  "ccmessaging.chat.holiday.deleteAllHoliday": "Remover todas as datas de descanso especificadas",
  "ccmessaging.multimedia.leftMenu.category": 'Categoria',
  "ccmessaging.multimedia.leftMenu.createCategory": 'Criar Categoria',
  "ccmessaging.multimedia.leftMenu.editCategory": 'Editar Categoria',
  "ccmessaging.multimedia.audio.create": 'Criar Voz',
  "ccmessaging.multimedia.audio.edit": 'Editar Voz',
  "ccmessaging.multimedia.video.create": 'Criar Vídeo',
  "ccmessaging.multimedia.video.edit": 'Editar Video',
  "ccmessaging.multimedia.image.create": 'Criar Imagem',
  "ccmessaging.multimedia.image.edit": 'Editar Imagem',
  "ccmessaging.multimedia.emoticon.create": 'Criar Emoticon',
  "ccmessaging.multimedia.emoticon.edit": 'Editar Emoticon',
  "ccmessaging.multimedia.document.create": 'Criar Documento',
  "ccmessaging.multimedia.document.edit": 'Editar Documento',
  "ccmessaging.multimedia.richText.create": 'Criar Rich Text',
  "ccmessaging.multimedia.richText.edit": 'Editar Rich Text',
  "ccmessaging.multimedia.local.create": 'Criar Endereço',
  "ccmessaging.multimedia.local.edit": 'Editar Endereço',
  'ccmessaging.multimedia.whatsAppTemplate.create': 'Créer Modèle WhatsApp',
  'ccmessaging.multimedia.whatsAppTemplate.edit': 'Editar Modèle WhatsApp',
  "ccmessaging.multimedia.siteAddr.create": 'Criar Site Cobrowse',
  "ccmessaging.multimedia.siteAddr.edit": 'Editar Site Cobrowse',
  "ccmessaging.multimedia.cardFiveG.create": 'Criar Cartão 5G',
  "ccmessaging.multimedia.cardFiveG.edit": 'Editar Cartão 5G',
  "ccmessaging.multimedia.carouselCardFiveG.create": 'Criar Cartão Carrossel 5G',
  "ccmessaging.multimedia.carouselCardFiveG.edit": 'Editar Cartão Carrossel 5G',
  'ccmessaging.multimedia.whatsAppTemplateMsg.create': 'Créer Mensagem Modèle WhatsApp',
  'ccmessaging.multimedia.whatsAppTemplateMsg.edit': 'Editar Mensagem Modèle WhatsApp',
  "ccmessaging.multimedia.cardWeb.create": 'Criar Cartão Web',
  "ccmessaging.multimedia.cardWeb.edit": 'Editar Cartão Web',
  "chat.agent.qualityCheckWorkbench.title": "Inspecção de Qualidade",
  "chat.agent.qualityCheckWorkbench.currentAgent": "Sede de monitoramento atual",
  "chat.agent.qualityCheckWorkbench.subscribeSessionFailed": "A sessão de agente de subscrição falhou",
  "chat.agent.qualityCheckWorkbench.cancelSubscribeSessionFailed": "Fallou a dessubscrição da sessão de agente",
  "chat.agent.qualityCheckWorkbench.pollMsgFailed": "Não conseguiu obter a mensagem de assento",
  "chat.agent.qualityCheckWorkbench.closeConfirm": "Por favor, confirme se a verificação de qualidade da sessão multimídia para o lugar atual acabou?",
  "chat.agent.qualityCheckWorkbench.listen": "Cancelar a inserção",
  "chat.agent.qualityCheckWorkbench.insert": "inserir",
  "chat.agent.qualityCheckWorkbench.intercept": "interceptar",
  "chat.agent.qualityCheckWorkbench.changeQualityStatus.fail": "Fallou a modificação do estado de inspecção de qualidade",
  "chat.agent.msg.redis.connection.exception.description": "Falha do sistema atual, por favor aguarde",
  "ccmessaging.chat.phrase.editPhraseTypeFail": "Falha ao editar o tipo de frase comum",
  "ccmessaging.chat.phrase.editPhraseTypeSucess": "Editar o tipo de frase comum com sucesso",
  "ccmessaging.chat.phrase.editPhraseFail": "Échec de la modification de la phrase commune",
  "ccmessaging.chat.phrase.editPhraseSucess": "Editar frase comum com sucesso",
  "ccmessaging.chat.multimedia.editMultiMediaInfo": "Edite dados multimídia com sucesso",
  "ccmessaging.chat.multimedia.editMultiMediaInfoError": "Falha ao editar dados multimídia",
  "ccmessaging.chat.multimedia.editMultiMediaType": "Editar o tipo multimídia com sucesso",
  "ccmessaging.chat.multimedia.editMultiMediaTypeError": "Falha ao editar tipo de multimídia",
  "ccmessaging.chat.multimedia.createMultiMediaInfo": "Criar dados multimídia com sucesso",
  "ccmessaging.chat.multimedia.createMultiMediaInfoError": "Falha ao criar dados multimídia",
  "ccmessaging.chat.multimedia.createMultiMediaType": "Criar o tipo de multimídia com sucesso",
  "ccmessaging.chat.multimedia.createMultiMediaTypeError": "Falha ao criar tipo multimídia",
  "ccmessaging.chat.phrase.createTypeSuccess": "Criar um tipo de frase comum com sucesso",

  "ccmessaging.chat.multimedia.createVideoInfo": "Criar dados de vídeo com sucesso",
  "ccmessaging.chat.multimedia.createVideoInfoError": "Falha ao criar dados de vídeo",
  "ccmessaging.chat.multimedia.createVideoType": "Criar o tipo de vídeo com sucesso",
  "ccmessaging.chat.multimedia.createVideoTypeError": "Falha ao criar tipo de vídeo",
  "ccmessaging.chat.multimedia.editVideoInfo": "Edite dados de vídeo com sucesso",
  "ccmessaging.chat.multimedia.editVideoInfoError": "Falha ao editar dados de vídeo",
  "ccmessaging.chat.multimedia.editVideoType": "Editar o tipo de vídeo com sucesso",
  "ccmessaging.chat.multimedia.editVideoTypeError": "Falha ao editar o tipo de vídeo",
  "ccmessaging.chat.multimedia.deleteVideoInfo": "Excluir dados de vídeo com sucesso",
  "ccmessaging.chat.multimedia.deleteVideoInfoError": "Falha ao excluir dados de vídeo",
  "ccmessaging.chat.multimedia.deleteVideoType": "Eliminar o tipo de vídeo com sucesso",
  "ccmessaging.chat.multimedia.deleteVideoTypeError": "Falha ao excluir tipo de vídeo",
  "ccmessaging.chat.multimedia.deleteVideoTypeError.usedbyODFS": "Falha ao excluir tipo de vídeo. Os recursos referenciados pelo IVR inteligente existem.",
  "ccmessaging.chat.multimedia.deleteVideoTypeError.referenced.byCard": "Falha ao excluir tipo de vídeo, porque alguns recursos já são referenciados por cartões.",
  "ccmessaging.chat.multimedia.deleteVideoTypeError.referenced.byWhatsAppTemplateMsg": "Falha ao excluir tipo de vídeo porque alguns recursos já são referenciados por uma mensagem de modelo do WhatsApp.",
  "ccmessaging.chat.multimedia.createAudioInfo": "Criar dados de áudio com sucesso",
  "ccmessaging.chat.multimedia.createAudioInfoError": "Falha ao criar dados de áudio",
  "ccmessaging.chat.multimedia.createAudioType": "Criar o tipo de áudio com sucesso",
  "ccmessaging.chat.multimedia.createAudioTypeError": "Falha ao criar tipo de áudio",
  "ccmessaging.chat.multimedia.editAudioInfo": "Edite dados de áudio com sucesso",
  "ccmessaging.chat.multimedia.editAudioInfoError": "Falha ao editar dados de áudio",
  "ccmessaging.chat.multimedia.editAudioType": "Edite o tipo de áudio com sucesso",
  "ccmessaging.chat.multimedia.editAudioTypeError": "Falha na edição do tipo de áudio",
  "ccmessaging.chat.multimedia.deleteAudioInfo": "Excluir dados de áudio com sucesso",
  "ccmessaging.chat.multimedia.deleteAudioInfoError": "Falha ao excluir dados de áudio",
  "ccmessaging.chat.multimedia.deleteAudioType": "Eliminar o tipo de áudio com sucesso",
  "ccmessaging.chat.multimedia.deleteAudioTypeError": "Falha ao excluir tipo de áudio",
  "ccmessaging.chat.multimedia.deleteAudioTypeError.usedbyODFS": "Falha ao excluir o tipo de áudio. Os recursos referenciados pelo IVR inteligente existem.",
  "ccmessaging.chat.multimedia.deleteAudioTypeError.referenced.byCard": "Falha ao excluir tipo de áudio, porque alguns recursos já são referenciados pelos cartões.",
  "ccmessaging.chat.multimedia.deleteAudioTypeError.referenced.byWhatsAppTemplateMsg": "Falha ao excluir tipo de áudio porque alguns recursos já são referenciados por uma mensagem de modelo do WhatsApp.",
  "ccmessaging.chat.multimedia.createImageInfo": "Criar dados de imagem com sucesso",
  "ccmessaging.chat.multimedia.createImageInfoError": "Falha ao criar dados de imagem",
  "ccmessaging.chat.multimedia.createImageType": "Criar o tipo de imagem com sucesso",
  "ccmessaging.chat.multimedia.createImageTypeError": "Falha ao criar tipo de imagem",
  "ccmessaging.chat.multimedia.editImageInfo": "Edite dados de imagem com sucesso",
  "ccmessaging.chat.multimedia.editImageInfoError": "Falha ao editar dados da imagem",
  "ccmessaging.chat.multimedia.editImageType": "Editar o tipo de imagem com sucesso",
  "ccmessaging.chat.multimedia.editImageTypeError": "Falha ao editar o tipo de imagem",
  "ccmessaging.chat.multimedia.deleteImageInfo": "Eliminar dados de imagem com sucesso",
  "ccmessaging.chat.multimedia.deleteImageInfoError": "Falha ao excluir dados de imagem",
  "ccmessaging.chat.multimedia.deleteImageType": "Apagar o tipo de imagem com sucesso",
  "ccmessaging.chat.multimedia.deleteImageTypeError": "Falha ao excluir tipo de imagem",
  "ccmessaging.chat.multimedia.deleteImageTypeError.usedbyODFS": "Falha ao excluir tipo de imagem. Os recursos referenciados pelo IVR inteligente existem.",
  "ccmessaging.chat.multimedia.deleteImageTypeError.referenced.byCard": "Falha ao excluir tipo de imagem, porque alguns recursos já são referenciados por cartões.",
  "ccmessaging.chat.multimedia.deleteImageTypeError.referenced.byWhatsAppTemplateMsg": "Falha ao excluir tipo de imagem, porque alguns recursos já são referenciados por uma mensagem de modelo do WhatsApp.",
  "ccmessaging.chat.multimedia.createDocumentInfo": "Criar dados do documento com sucesso",
  "ccmessaging.chat.multimedia.createDocumentInfoError": "Falha ao criar dados do documento",
  "ccmessaging.chat.multimedia.createDocumentType": "Criar o tipo de documento com sucesso",
  "ccmessaging.chat.multimedia.createDocumentTypeError": "Falha ao criar tipo de documento",
  "ccmessaging.chat.multimedia.editDocumentInfo": "Editar dados do documento com sucesso",
  "ccmessaging.chat.multimedia.editDocumentInfoError": "Falha ao editar dados do documento",
  "ccmessaging.chat.multimedia.editDocumentType": "Editar o tipo de documento com sucesso",
  "ccmessaging.chat.multimedia.editDocumentTypeError": "Falha ao editar o tipo de documento",
  "ccmessaging.chat.multimedia.deleteDocumentInfo": "Excluir dados do documento com sucesso",
  "ccmessaging.chat.multimedia.deleteDocumentInfoError": "Falha ao excluir dados do documento",
  "ccmessaging.chat.multimedia.deleteDocumentType": "Excluir o tipo de documento com sucesso",
  "ccmessaging.chat.multimedia.deleteDocumentTypeError": "Falha ao excluir tipo de documento",
  "ccmessaging.chat.multimedia.deleteDocumentTypeError.usedbyODFS": "Falha ao excluir tipo de documento. Os recursos referenciados pelo IVR inteligente existem.",
  "ccmessaging.chat.multimedia.deleteDocumentTypeError.referenced.byCard": "Falha ao excluir tipo de documento, porque alguns recursos já são referenciados por cartões.",
  "ccmessaging.chat.multimedia.deleteDocumentTypeError.referenced.byWhatsAppTemplateMsg": "Falha ao excluir tipo de documento porque alguns recursos já são referenciados por uma mensagem de modelo do WhatsApp.",
  "ccmessaging.chat.multimedia.createLocateInfo": "Criar dados de localização com sucesso",
  "ccmessaging.chat.multimedia.createLocateInfoError": "Falha ao criar dados de localização",
  "ccmessaging.chat.multimedia.createLocateType": "Criar o tipo de local com sucesso",
  "ccmessaging.chat.multimedia.createLocateTypeError": "Falha ao criar tipo de local",
  "ccmessaging.chat.multimedia.editLocateInfo": "Editar dados de localização com sucesso",
  "ccmessaging.chat.multimedia.editLocateInfoError": "Falha ao editar dados de localização",
  "ccmessaging.chat.multimedia.editLocateType": "Editar o tipo de localização com sucesso",
  "ccmessaging.chat.multimedia.editLocateTypeError": "Falha ao editar o tipo de local",
  "ccmessaging.chat.multimedia.deleteLocateInfo": "Apagar dados de localização com sucesso",
  "ccmessaging.chat.multimedia.deleteLocateInfoError": "Falha ao excluir dados de localização",
  "ccmessaging.chat.multimedia.deleteLocateType": "Eliminar o tipo de localização com êxito",
  "ccmessaging.chat.multimedia.deleteLocateTypeError": "Falha ao excluir tipo de local",
  "ccmessaging.chat.multimedia.deleteLocateTypeError.usedbyODFS": "Falha ao excluir o tipo de local. Os recursos referenciados pelo IVR inteligente existem.",
  "ccmessaging.chat.multimedia.deleteLocateTypeError.referenced.byCard": "Falha ao excluir tipo de local, porque alguns recursos já são referenciados por cartões.",
  "ccmessaging.chat.multimedia.deleteLocateTypeError.referenced.byWhatsAppTemplateMsg": "Falha ao excluir tipo de local porque alguns recursos já são referenciados por uma mensagem de modelo do WhatsApp.",
  "ccmessaging.chat.multimedia.createEmotionInfo": "Criar dados do emoticon com sucesso",
  "ccmessaging.chat.multimedia.createEmotionInfoError": "Falha ao criar dados do emoticon",
  "ccmessaging.chat.multimedia.createEmotionType": "Criar o tipo de emoticon com sucesso",
  "ccmessaging.chat.multimedia.createEmotionTypeError": "Falha ao criar tipo de emoticon",
  "ccmessaging.chat.multimedia.editEmotionInfo": "Edite os dados do emoticon com sucesso",
  "ccmessaging.chat.multimedia.editEmotionInfoError": "Falha ao editar dados do emoticon",
  "ccmessaging.chat.multimedia.editEmotionType": "Editar o tipo de emoticon com sucesso",
  "ccmessaging.chat.multimedia.editEmotionTypeError": "Falha ao editar tipo de emoticon",
  "ccmessaging.chat.multimedia.deleteEmotionInfo": "Excluir dados do emoticon com sucesso",
  "ccmessaging.chat.multimedia.deleteEmotionInfoError": "Falha ao excluir dados do emoticon",
  "ccmessaging.chat.multimedia.deleteEmotionType": "Apagar o tipo de emoticon com sucesso",
  "ccmessaging.chat.multimedia.deleteEmotionTypeError": "Falha ao excluir tipo de emoticon",
  "ccmessaging.chat.multimedia.deleteEmotionTypeError.usedbyODFS": "Falha ao excluir tipo de emoticon. Os recursos referenciados pelo IVR inteligente existem.",
  "ccmessaging.chat.multimedia.deleteEmotionTypeError.referenced.byCard": "Falha ao excluir tipo de emoticon, porque alguns recursos já são referenciados por cartões.",
  "ccmessaging.chat.multimedia.deleteEmotionTypeError.referenced.byWhatsAppTemplateMsg": "Falha ao excluir tipo de emoticon porque alguns recursos já são referenciados por uma mensagem de modelo do WhatsApp.",
  "ccmessaging.chat.multimedia.createRichTextInfo": "Criar dados de Rich Text com sucesso",
  "ccmessaging.chat.multimedia.createRichTextInfoError": "Falha ao criar dados de Rich Text",
  "ccmessaging.chat.multimedia.createRichTextType": "Criar o tipo Rich Text com sucesso",
  "ccmessaging.chat.multimedia.createRichTextTypeError": "Falha ao criar tipo Rich Text",
  "ccmessaging.chat.multimedia.editRichTextInfo": "Edite dados de Rich Text com sucesso",
  "ccmessaging.chat.multimedia.editRichTextInfoError": "Falha ao editar dados de Rich Text",
  "ccmessaging.chat.multimedia.editRichTextType": "Editar o tipo de Rich Text com sucesso",
  "ccmessaging.chat.multimedia.editRichTextTypeError": "Falha ao editar tipo de Rich Text",
  "ccmessaging.chat.multimedia.deleteRichTextInfo": "Apagar dados de Rich Text com sucesso",
  "ccmessaging.chat.multimedia.deleteRichTextInfoError": "Falha ao excluir dados de Rich Text",
  "ccmessaging.chat.multimedia.deleteRichTextType": "Apagar o tipo Rich Text com sucesso",
  "ccmessaging.chat.multimedia.deleteRichTextTypeError": "Falha ao excluir tipo de Rich Text",
  "ccmessaging.chat.multimedia.deleteRichTextTypeError.usedbyODFS": "Falha ao excluir tipo de Rich Text. Os recursos referenciados pelo IVR inteligente existem.",
  "ccmessaging.chat.multimedia.deleteRichTextTypeError.referenced.byCard": "Falha ao excluir tipo Rich Text, porque alguns recursos já são referenciados por cartões.",
  "ccmessaging.chat.multimedia.deleteRichTextTypeError.referenced.byWhatsAppTemplateMsg": "Falha ao excluir tipo Rich Text, porque alguns recursos já são referenciados por uma mensagem de modelo do WhatsApp.",
  "ccmessaging.chat.multimedia.createSiteAddrInfo": "Criar dados de endereço de colaboração de página da Web com sucesso",
  "ccmessaging.chat.multimedia.createSiteAddrInfoError": "Falha ao criar dados de endereço de colaboração de página da Web",
  "ccmessaging.chat.multimedia.createSiteAddrType": "Criar tipo de endereço de colaboração de página da Web com sucesso",
  "ccmessaging.chat.multimedia.createSiteAddrTypeError": "Falha ao criar tipo de endereço de colaboração de página da Web",
  "ccmessaging.chat.multimedia.editSiteAddrInfo": "Editar dados de endereço de colaboração de página da Web com sucesso",
  "ccmessaging.chat.multimedia.editSiteAddrInfoError": "Falha ao editar dados de endereço de colaboração de página da Web",
  "ccmessaging.chat.multimedia.editSiteAddrType": "Editar o tipo de endereço de colaboração de página da Web com sucesso",
  "ccmessaging.chat.multimedia.editSiteAddrTypeError": "Falha ao editar o tipo de endereço de colaboração de página da Web",
  "ccmessaging.chat.multimedia.deleteSiteAddrInfo": "Excluir dados do endereço de colaboração da página da Web com sucesso",
  "ccmessaging.chat.multimedia.deleteSiteAddrInfoError": "Falha ao excluir dados do endereço de colaboração de página da Web",
  "ccmessaging.chat.multimedia.deleteSiteAddrType": "Excluir tipo de endereço de colaboração de página da Web com êxito",
  "ccmessaging.chat.multimedia.deleteSiteAddrTypeError": "Excluir tipo de endereço de colaboração de página da Web com êxito",
  "ccmessaging.chat.multimedia.deleteSiteAddrTypeError.usedbyODFS": "Falha ao excluir tipo de endereço de colaboração de página da Web. Os recursos referenciados pelo IVR inteligente existem.",
  "ccmessaging.chat.multimedia.deleteSiteAddrTypeError.referenced.byCard": "Falha ao excluir tipo de endereço de colaboração de página da Web, porque alguns recursos já são referenciados por cartões.",
  "ccmessaging.chat.multimedia.deleteSiteAddrTypeError.referenced.byWhatsAppTemplateMsg": "Falha ao excluir tipo de endereço de colaboração de página da Web, porque alguns recursos já são referenciados por uma mensagem de modelo do WhatsApp.",
  "ccmessaging.chat.chatcard.createFiveGCardFail": "Falha ao criar cartão 5G",
  "ccmessaging.chat.chatcard.createFiveGCardSuccess": "Criar cartão 5G com sucesso",
  "ccmessaging.chat.chatcard.editFiveGCardFail": "Falha ao editar o cartão 5G",
  "ccmessaging.chat.chatcard.editFiveGCardSuccess": "Edite o cartão 5G com sucesso",
  "ccmessaging.chat.chatcard.deleteFiveGCardFail": "Falha ao excluir cartão 5G",
  "ccmessaging.chat.chatcard.deleteFiveGCardSuccess": "Excluir cartão 5G com sucesso",
  "ccmessaging.chat.chatcard.createFiveGCarouselFail": "Falha ao criar 5G Carouse",
  "ccmessaging.chat.chatcard.createFiveGCarouselSuccess": "Criar 5G Carouse com sucesso",
  "ccmessaging.chat.chatcard.editFiveGCarouselFail": "Falha ao editar 5G Carouse",
  "ccmessaging.chat.chatcard.editFiveGCarouselSuccess": "Edite o 5G Carouse com sucesso",
  "ccmessaging.chat.chatcard.deleteFiveGCarouselFail": "Falha ao excluir 5G Carouse",
  "ccmessaging.chat.chatcard.deleteFiveGCarouselSuccess": "Excluir 5G Carouse com sucesso",
  "ccmessaging.chat.chatcard.createWhatAppTemplateMeaasgeFail": "Falha ao criar medição de modelo WhatsApp",
  "ccmessaging.chat.chatcard.createWhatAppTemplateMeaasgeSuccess": "Criar medição de modelo do WhatsApp com sucesso",
  "ccmessaging.chat.chatcard.editWhatAppTemplateMeaasgeFail": "Falha ao editar a medição do modelo WhatsApp",
  "ccmessaging.chat.chatcard.editWhatAppTemplateMeaasgeSuccess": "Editar medição de modelo do WhatsApp com sucesso",
  "ccmessaging.chat.chatcard.deleteWhatAppTemplateMeaasgeFail": "Falha ao excluir a medição do modelo WhatsApp",
  "ccmessaging.chat.chatcard.deleteWhatAppTemplateMeaasgeSuccess": "Excluir medição de modelo do WhatsApp com sucesso",
  "ccmessaging.chat.chatcard.createWebCardFail": "Falha ao criar WebCard",
  "ccmessaging.chat.chatcard.createWebCardSuccess": "Criar WebCard com sucesso",
  "ccmessaging.chat.chatcard.editWebCardFail": "Falha ao editar WebCard",
  "ccmessaging.chat.chatcard.editWebCardSuccess": "Editar WebCard com sucesso",
  "ccmessaging.chat.chatcard.deleteWebCardFail": "Falha ao excluir WebCard",
  "ccmessaging.chat.chatcard.deleteWebCardSuccess": "Apagar WebCard com sucesso",
  "ccmessaging.chat.WhatsApp.createWhatsAppTemplateSuccess": "Criar modelo do WhatsApp com sucesso",
  "ccmessaging.chat.WhatsApp.createWhatsAppTemplateFail": "Falha ao criar modelo WhatsApp",
  "ccmessaging.chat.WhatsApp.editWhatsAppTemplateSuccess": "Editar modelo do WhatsApp com sucesso",
  "ccmessaging.chat.WhatsApp.editWhatsAppTemplateFail": "Falha ao editar modelo de WhatsApp",
  "ccmessaging.chat.WhatsApp.deleteWhatsAppTemplateSuccess": "Excluir modelo do WhatsApp com sucesso",
  "ccmessaging.chat.WhatsApp.deleteWhatsAppTemplateFail": "Falha ao excluir modelo do WhatsApp",
  "ccmessaging.chat.WhatsApp.deleteWhatsAppTemplateFailMsg": "Falha ao excluir modelo do WhatsApp. O modelo é referenciado por uma mensagem de modelo.",
  "ccmessaging.multimedia.download.office.risktips":"Você está prestes a baixar documentos do Office, que podem representar riscos de segurança. É recomendável que você execute operações antivírus e habilite as configurações de segurança do software do Office.",
  "ccmessaging.chat.multimedia.documentUploadRiskTips":"Este documento é enviado aos usuários pelos agentes. Não carregue arquivos que contenham riscos de segurança.",
  "ccmessaging.config.names.channels.web": "Web",
  "ccmessaging.config.names.channels.instagram": "Instagram",
  "ccmessaging.config.names.channels.telegram": "Telegram",
  "ccmessaging.config.names.channels.whatsapp": "WhatsApp",
  "ccmessaging.config.names.channels.line": "LINE",
  "ccmessaging.config.names.channels.wechat": "WeChat",
  "ccmessaging.config.names.channels.facebook": "Facebook",
  "ccmessaging.config.names.channels.twitter": "X (Twitter)",
  "ccmessaging.config.names.channels.rcs": "5G RCS",
  "ccmessaging.config.names.channels.email": "Email",
  "ccmessaging.config.names.channels.sms": "SMS",
  "ccmessaging.chat.multimedia.whatsApp.interactiveMsg":"porta mensagens interativas",
  "ccmessaging.chat.whatsapp.interactiveMsgNamePlaceholder":"Digite o nome da mensagem interativa",
  "ccmessaging.chat.whatsapp.interactiveMsgName":"Nome da Mensagem",
  "ccmessaging.chat.whatsapp.interactiveMsgTitle":"Título",
  "ccmessaging.chat.whatsapp.interactiveMsgDescription":"Descrição da Mensagem",
  "ccmessaging.chat.whatsapp.interactiveMsgDescriptionPlaceholder":"Digite uma descrição interativa da mensagem",
  "ccmessaging.chat.whatsapp.interactiveMsgType":"Tipo de Mensagem",
  "ccmessaging.chat.whatsapp.orgName":"Nome da organização",
  "ccmessaging.chat.chatcard.queryInteractiveMsg":"Falha ao consultar a mensagem interativa",
  "ccmessaging.chat.whatsapp.interactiveType.buttons":"Mensagem do botão",
  "ccmessaging.chat.whatsapp.interactiveType.list":"Mensagens da lista",
  "ccmessaging.chat.whatsapp.delete.interactiveMsg":"Remover mensagens interativas",
  "ccmessaging.chat.whatsapp.willdeleteinteractiveMsg":"Tem certeza de que deseja excluir esta mensagem interativa?",
  "ccmessaging.chat.whatsapp.interactiveTypes":"Tipo de mensagem interativa",
  "ccmessaging.chat.whatsapp.interactiveMsgTitlePlaceholder":"Digite o conteúdo do título interativo",
  "ccmessaging.chat.whatsapp.interactiveMsg.bodyContent":"Conteúdo do corpo",
  "ccmessaging.chat.whatsapp.interactiveMsg.bodyContentPlaceholder":"Digite o conteúdo do corpo",
  "ccmessaging.chat.whatsapp.interactiveMsg.footerContent":"Conteúdo do rodapé",
  "ccmessaging.chat.whatsapp.interactiveMsg.footerContentPlaceholder":"Digite o conteúdo do rodapé",
  "ccmessaging.chat.whatsapp.interactiveMsg.pleaseChoose":"Selecione",
  "ccmessaging.chat.whatsapp.interactiveMsg.attachments":"Acessórios",
  "ccmessaging.chat.multimedia.porta.interactiveMsg.create":"Nova mensagem interativa",
  "ccmessaging.chat.multimedia.whatsApp.interactiveMsg.update":"Modificar uma mensagem interativa",
  "ccmessaging.chat.whatsapp.interactiveType.list.title":"Lista",
  "ccmessaging.chat.whatsapp.interactiveType.list.titlePlaceholder":"Digite o título da lista",
  "ccmessaging.chat.whatsapp.interactiveMsg.openRow.info":"O título do grupo está vazio, não é possível definir opções",
  "ccmessaging.chat.whatsapp.interactiveType.section.titlePlaceholder":"Insira o título do grupo",
  "ccmessaging.chat.whatsapp.interactiveType.row.titlePlaceholder":"Insira a opção Título",
  "ccmessaging.chat.whatsapp.interactiveType.row.descPlaceholder":"Digite a descrição da opção",
  "ccmessaging.chat.whatsapp.interactiveMsg.sectionTitle":"Digite o título da lista",
  "ccmessaging.chat.whatsapp.interactiveMsg.queryInteractiveError":"Falha na consulta da mensagem interativa",
  "ccmessaging.chat.whatsapp.interactiveMsg.createInteractiveError":"Falha ao salvar a mensagem interativa. A opção de agrupamento não pode ficar vazia depois que a lista é agrupada",
  "ccmessaging.chat.whatsapp.interactiveMsg.saveFail.duplicateName":"Falha ao salvar, o nome da mensagem interativa já existe",
  "ccmessaging.chat.whatsapp.interactiveMsg.noHeader":"Nenhum",
  "ccmessaging.chat.whatsapp.interactiveMsg.headerContent":"Conteúdo do cabeçalho",
  "ccmessaging.chat.whatsapp.interactiveMsg.no.buttons":"Falha ao salvar a mensagem interativa sem botão definido",
  "ccmessaging.chat.whatsapp.interactiveMsg.no.lists":"Falha ao salvar a mensagem interativa, nenhuma lista definida",
  "ccmessaging.chat.multimedia.deleteLimit.whatsapp.interactiveMsg":"Este recurso foi referenciado por uma mensagem interativa do whatsApp e não pode ser excluído. Nome da mensagem interativa:",
  "ccmessaging.chat.whatsapp.interactiveMsg.saveFail.multiMedia.notExist":"Falha ao salvar, o recurso multimídia não existe",
  "ccmessaging.chat.whatsapp.interactiveMsg.createInteractiveError.repeateButtonContent":"Falha ao salvar, o conteúdo do botão não pode ser duplicado",
  "ccmessaging.chat.case.title":"Case Title",
  "ccmessaging.chat.case.type":"Case Type",
  "ccmessaging.chat.case.callReason":"Call Reason",
  "ccmessaging.chat.case.puzzled":"puzzled",
  "ccmessaging.chat.case.calm":"calm",
  "ccmessaging.chat.case.happy":"happy",
  "ccmessaging.chat.case.upset":"upset",
  "ccmessaging.chat.case.surprise":"surprise",
  "ccmessaging.chat.case.pleasant":"pleasant",
  "ccmessaging.chat.case.angry":"angry",
  "ccmessaging.chat.case.little.angry":"a little anger",
  "ccmessaging.chat.case.very.angry":"very mad",
  "ccmessaging.chat.case.very.knowledgeId":"1228840300949016576",
  "ccmessaging.chat.case.complaint.yes":"yes",
  "ccmessaging.chat.case.complaint.shi":"yes",
  "ccmessaging.chat.degree.view":"Cliente 360 graus",
}
