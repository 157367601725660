export default {
    "ccm.agent.button.search": "Abfrage",
    "ccm.agent.button.clear": "Zurücksetzen",
    "ccm.agent.button.refresh": "Aktualisieren",
    "ccm.agent.button.export": "Exportieren",
    "ccm.agent.button.edit": "Bearbeiten",
    "ccm.agent.button.cancel": "Abbrechen",
    "ccm.agent.button.submit": "Senden",
    "ccm.agent.button.ok": "OK",
    "ccm.agent.button.create": "Neu",
    "ccm.agent.button.createComplete": "Abgeschlossen",
    "ccm.agent.button.save": "Speichern",
    "ccm.agent.button.delete": "Löschen",
    "ccm.transfer.number.pop.create.title": "Erstellen einer weitergeleiteten externen Nummer",
    "ccm.transfer.number.pop.modify.title": "Weitergeleitete externe Nummer bearbeiten",
    "ccm.transfer.number.pop.delete.title": "Löschen",
    "ccm.transfer.number.pop.delete.message": "Möchten Sie die ausgewählte externe Nummer wirklich löschen?",
    "ccm.transfer.number.search.number": "Bitte geben Sie eine Nummer ein",
    "ccm.transfer.number.search.name": "Bitte geben Sie einen Namen ein",
    "ccm.transfer.number.label.index": "Index",
    "ccm.transfer.number.label.number": "Zahl",
    "ccm.transfer.number.label.name": "Name",
    "ccm.agent.message.addSkill": "Kompetenzwarteschlange hinzufügen",
    "ccm.transfer.number.label.flag": "Benutzernummernweiterleitung",
    "ccm.transfer.number.label.flag.yes": "Ja",
    "ccm.transfer.number.label.flag.no": "Nein",
    "ccm.pageinterface.custTableConfig": "Basistabellenkonfiguration",
    "ccm.agent.button.coloumedit": "Feldeinstellungen",
    "ccm.satisfactionconfig.group.createsuccess": "Die Richtliniengruppe wurde erfolgreich hinzugefügt.",
    "ccm.satisfactionconfig.group.deletesuccess": "Die Richtliniengruppe wurde erfolgreich gelöscht.",
    "ccm.satisfactionconfig.strategy.createsuccess": "Die Channel-Zufriedenheitsrichtlinie konnte erfolgreich hinzugefügt werden.",
    "ccm.satisfactionconfig.strategy.updateuccess": "Die Channel-Zufriedenheitsrichtlinie konnte erfolgreich geändert werden.",
    "ccm.satisfactionconfig.strategy.deletesuccess": "Die Channel-Zufriedenheitsrichtlinie wurde erfolgreich gelöscht.",
    "ccm.satisfactionconfig.strategy.surveytyperequired": "Der Umfragetyp darf nicht leer sein.",

    "ccm.agent.message.weight.limit": "[1-100]",
    "ccm.agent.message.agentweight.limit": "[1-300]",
    "ccm.agent.button.downloadvideoterminal": "OpenEye herunterladen",
    "ccm.agent.label.querymobileagent": "Abfrage der Softphone-Nummer",
    "ccm.agent.label.modifymobileagent": "Bearbeiten Sie die Softphone-Nummer",
    "ccm.agent.label.mobileagentremark": "Anmerkungen",
    "ccm.agent.label.mobileagententer": "Eingabe",
    "ccm.agent.label.isaireconition": "WAHR",
    "ccm.agent.label.noaireconition": "FALSCH",
    "ccm.agent.label.startworkno": "Mitarbeiter-ID starten",
    "ccm.agent.label.endworkno": "Ende Mitarbeiter-ID",
    "ccm.callreason.case.check.error": "Arbeitsauftragstyp {0} existiert nicht",
    "ccm.agent.label.inputstartworkno": "Bitte geben Sie die ID des ersten Mitarbeiters ein",
    "ccm.agent.label.inputendworkno": "Bitte geben Sie die ID des letzten Mitarbeiters ein",
    "ccm.agent.label.batchselect": "Agenten in Stapeln auswählen",
    "ccm.agent.label.batchselectway": "Stapelauswahlmodus",
    "ccm.agent.label.selectagent": "Ausgewählte Agenten",
    "ccm.agent.ten.mediaagents": "Anzahl der Multimedia-Agenten",
    "ccm.agent.ten.versatileagents": "Vielseitige Agenten",
    "ccm.agent.ten.allowedTTSAndASRCallNum": "Anzahl der intelligenten IVR-Kanäle",
    "ccm.agent.label.airecog": "Intelligente Erkennung",
    "ccm.agent.label.singlePhoneFlag": "Nur telefonischer Agent",
    "ccm.agent.label.dualChannelRecFlag": "Aufzeichnung mit zwei Titeln",
    "ccm.agent.message.addAIRecogFail": "Die Anzahl der aktivierten intelligenten Agenten darf die Anzahl der Agenten nicht überschreiten, die bei der Aktivierung des Mandanten zugewiesen wurden.",
    "ccm.agent.message.ctipasswdrepeat": "Das neue Kennwort darf nicht mit den letzten fünf alten Kennwörtern identisch sein.",
    "ccm.agent.message.ctipasswdsimple": "Das Kennwort erfüllt nicht die Anforderungen an die Komplexität der Plattform. Wenden Sie sich an das O M-Personal, um das Kennwort zu überprüfen.",
    "ccm.agent.message.ctipasswdless": "Das Kennwort darf nicht weniger als 8 Zeichen lang sein.",
    "ccm.agent.label.transparentDisplayFlag": "Agentennummern-Anonymisierungsmarkierung",
    "ccm.agent.label.selfPhoneNotNull": "Wenn die Agentennummern-Anonymisierungsfunktion aktiviert ist, darf die Festnetz- oder Mobiltelefonnummer des Agenten nicht leer sein.",
    "ccm.agent.label.theAgent": "Agent:",
    "ccm.agent.label.bindTheSkill": "Bevor Sie die Skill-Warteschlange verwenden, heben Sie zuerst die Bindung der angerufenen Route auf.",
    "ccm.agent.label.groupUseTheSkill": ". bitte löschen Sie zuerst die Konfiguration der Skill-Warteschlange in der Skill-Gruppe.",
    "ccm.agent.label.groupBind": "Die Skill-Warteschlange wurde an die Skill-Gruppe gebunden ",
    "ccm.agent.label.skillQueue": "Skill-Warteschlange:",
    "ccm.agent.label.cti": "cti:",
    "ccm.agent.contact.agent.recall": "Der Agent hat die Nachricht zurückgezogen",
    "ccm.agent.contact.user.recall": "Der Benutzer hat die Nachricht zurückgezogen",
    "ccm.agent.label.deleteAgentFailed": "Eine Skill-Warteschlange wurde an ein Geschäftskonto gebunden. Löschen Sie zuerst die Skill-Warteschlangenkonfiguration des Agenten.",
    "ccm.agent.label.deleteCtiFailed": "Das Löschen der Daten auf der Cti-Plattform ist fehlgeschlagen.",
    "ccm.agent.label.useTheSkill": "Die Skill-Warteschlange {0} wurde an die Agenten-ID {1} gebunden. Löschen Sie zuerst die Konfiguration der Skill-Warteschlange unter dem Agenten.",
    "ccm.agent.label.information": "Tipps",
    "ccm.agent.recall.fail": "Der Kunde konnte nicht zurückgerufen werden.",
    "ccm.agent.recall.param.callid.empty": "Es ist keine Rückrufnummer verfügbar.",
    "ccm.agent.recall": "Rückruf",
    "ccm.agent.recall.agent.calltype.invalid": "Der aktuelle Anruftyp unterstützt den Rückrufvorgang nicht.",
    "ccm.agent.recall.agent.status.invalid": "Der Agent befindet sich nicht im Leerlauf- oder Besetztzustand und kann nicht zurückrufen.",
    "ccm.agent.label.addChildReason": "Ursacheneinstellungen sollten Stufe 6 nicht überschreiten",
    "ccm.agent.label.paramsConfig": "Konfiguration der Systemparameter",
    "ccm.agent.label.registrationMessage": "Anmeldeinformationen",
    "ccm.agent.label.basicInfo": "Grundlegende Informationen",
    "ccm.agent.label.serverAddress": "Adresse des Registrierungsservers",
    "ccm.agent.label.fixedLine": "Festnetz-/Mobiltelefonnummer",
    "ccm.agent.label.audio": "Sprachagent",
    "ccm.agent.label.video": "Videoagent",
    "ccm.agent.label.mediaagent": "Multimediaagent",
    "ccm.agent.label.versatileagent": "Vielseitig einsetzbarer Agent",
    "ccm.agent.label.on": "Einschalten",
    "ccm.agent.label.off": "Herunterfahren",
    "ccm.agent.label.selectSkill": "Skill-Warteschlange auswählen",
    "ccm.speciallist.msg.partexport": "Möchten Sie die ausgewählten Datensätze wirklich exportieren?",
    "ccm.agent.label.workNo": "Mitarbeiter-ID",
    "ccm.agent.label.sipAccount": "Softphone-Nummer",
    "ccm.agent.label.sipPwd": "Softphone-Kennwort",
    "ccm.agent.label.agentType": "Agententyp",
    "ccm.agent.label.agentTel": "Mobil-/Festnetznummer des Agenten",
    "ccm.agent.label.validTime": "Gültigkeitsdauer des Kennworts (Tage)",
    "ccm.agent.label.timeRemaining": "Passwortablauf (Tag)",
    "ccm.agent.label.ccRole": "Rolle",
    "ccm.agent.label.ccRoleId": "Plattformrolle",
    "ccm.agent.label.serviceAccountId": "Zugeordnete Service-Cloud-Konto-ID",
    "ccm.agent.label.serviceAccountName": "Name des Dienst-Cloud-Kontos",
    "ccm.agent.label.serviceAccountCode": "Konto",
    "ccm.agent.label.skills": "Skill-Warteschlange",
    "ccm.agent.label.maxWaitTime": "Max. Wartezeit (s)",
    "ccm.agent.label.maxWaitNum": "Max. Anrufe in der Warteschleife",
    "ccm.agent.label.agentList": "Dienstkonto",
    "ccm.agent.label.Account": "Konto",
    "ccm.agent.label.adjustTime": "Dauer (s) im Vereinbarungsstaat",
    "ccm.agent.label.description": "Beschreibung",
    "ccm.agent.label.subtype": "Untertyp",
    "ccm.agent.contact.subtype.none": "Keine",
    "ccm.agent.label.skillId": "Skill-Warteschlangen-ID",
    "ccm.agent.label.skillName": "Skill-Warteschlangenname",
    "ccm.agent.label.maxWaitTimeSpan": "Wert [1\u201360000]",
    "ccm.agent.label.maxAgentWrapupTime": "Wert [0\u20133600]",
    "ccm.agent.label.operate": "Betrieb",
    "ccm.agent.label.ordinaryAgent": "Gemeinsamer Agent",
    "ccm.agent.label.configAdmin": "Konfigurationsadministrator",
    "ccm.agent.label.weight": "Gewicht",
    "ccm.agent.label.skillWeight": "Gewichtung der Agentenfähigkeiten",
    "ccm.agent.label.agentWeight": "Agentengewicht",
    "ccm.agent.label.skillWeightTip": "Wenn ein Service Desk-Mitarbeiter in mehrere Warteschleifen eintritt, werden die Anrufe in der Kompetenzwarteschleife mit der höchsten Gewichtung zuerst bearbeitet.",
    "ccm.agent.label.agentWeightTip": "Anrufe werden vorzugsweise an Agenten mit der höchsten Gewichtung vergeben.",
    "ccm.agent.label.waitSkillWeight": "Geben Sie eine Fertigkeitsgewichtung ein.",
    "ccm.agent.label.waitAgentWeight": "Geben Sie ein Agentengewicht ein.",
    "ccm.agent.label.param.index": "Nein.",
    "ccm.agent.label.paramValue": "Wert",
    "ccm.agent.label.CommomAgent": "Gemeinsamer Agent",
    "ccm.agent.label.qualityChecker": "Qualitätsprüfer",
    "ccm.agent.label.CallOutAgent": "Anrufagent",
    "ccm.agent.label.skillpara": "Konfiguration der Skill-Parameter",
    "ccm.agent.label.timeOut": "Skill-Zeitüberschreitung",
    "ccm.agent.label.busy": "Fertigkeiten beschäftigt",
    "ccm.agent.label.noAgents": "Anrufverarbeitung bei niemandem im Dienst",
    "ccm.agent.label.transferType": "Verarbeitungsmethode",
    "ccm.agent.label.deviceType": "Gerätetyp",
    "ccm.agent.label.ivrChoice": "IVR auswählen",
    "ccm.agent.label.skillChoice": "Skill auswählen",
    "ccm.agent.label.release": "Freigabe",
    "ccm.agent.label.transfer": "Übertragung",
    "ccm.agent.label.ivr": "IVR",
    "ccm.agent.label.withinFifteenDays": "Innerhalb von 15 Tagen",
    "ccm.agent.label.withinSevenDays": "Binnen 7 Tagen",
    "ccm.agent.label.expired": "Abgelaufen",
    "ccm.agent.label.waitVoiceConfig": "Warteschlangen- und Wartekonfiguration",
    "ccm.agent.label.waitVoiceSelect": "Bitte wählen Sie einen Warteton",
    "ccm.agent.label.waitVoiceDefault": "Standard-Warteton",
    "ccm.agent.label.waitVoiceCustom": "Anpassen des Wartetons",
    "ccm.agent.label.parameterType": "Warteschlangenmethode",
    "ccm.agent.label.playVoice": "Abspielen des Wartetons",
    "ccm.agent.label.answerType": "Antworttyp",
    "ccm.agent.label.answerType0": "Mitarbeiter-ID melden",
    "ccm.agent.label.answerType2": "Keine Stimme melden",
    "ccm.agent.label.keepVoiceConfig": "Konfiguration wird beibehalten und gewartet",
    "ccm.agent.label.keepWaitType": "Aufbewahrungsmethode",
    "ccm.agent.label.keepVoiceDefault": "Standard-Keeping-Ton",
    "ccm.agent.label.keepVoiceSelect": "Bitte wählen Sie einen Erhaltungston aus",
    "ccm.agent.label.keepVoiceCustom": "Anpassen des Keeping-Tons",
    "ccm.agent.label.createWorkOrdeType": "Zugehöriger Falltyp",
    "ccm.satisfactiontemplate.message.resetfail": "Der Inhalt der Vorlage für die Zufriedenheitsumfrage konnte nicht zurückgesetzt werden.",
    "ccm.satisfactionconfig.msg.templateenpty": "Wählen Sie eine SMS-Vorlage aus.",
    "ccm.satisfactionconfig.msg.timelimitenpty": "Die gültige Antwortzeit ist null.",
    "ccm.agent.label.promptParameters": "Aufforderungston",
    "ccm.agent.label.queueParameters": "Warteschlangen",
    "ccm.agent.label.queueTransferType": "Auf Objekttyp übertragen",
    "ccm.agent.label.queueTransferObj": "Auf Objekt übertragen",
    "ccm.agent.label.busyRatePrefix": "Wenn die Gerätenutzung",
    "ccm.agent.label.busyRateSuffix": ",-Anrufe können nicht an die Warteschleife weitergeleitet werden.",
    "ccm.agent.label.busyJudging": "Anrufweiterleitung bei Belegt oder Überlauf",
    "ccm.agent.label.deviceUsageThresholdPrefix": "Wenn die Gerätenutzung größer oder gleich ",
    "ccm.agent.label.deviceUsageThresholdSuffix": ", die Warteschlange ist ausgelastet.",
    "ccm.agent.label.waitTimePrefix": "Wenn die prognostizierte Wartezeit ",
    "ccm.agent.label.waitTimeSuffix": ", die Warteschlange ist ausgelastet.",
    "ccm.agent.label.queuingCallsMaxNumPrefix": "Wenn die Anzahl der Anrufe in der Warteschleife ",
    "ccm.agent.label.queuingCallsMaxNumSuffix": ", die Warteschlange läuft über.",
    "ccm.agent.label.scaleFactorPrefix": "Wenn die Anzahl der Anrufe in der Warteschleife (",
    "ccm.agent.label.scaleFactorSuffix": "x Anzahl der angemeldeten Agenten), läuft die Warteschlange über.",
    "ccm.speciallist.msg.sltype.illegal": "Ungültiger spezieller Listentyp.",
    "ccm.agent.message.queryctiagentmessage": "Agenteninformationen konnten in CTI nicht gefunden werden.",
    "ccm.agent.message.nulagentincti": "Der derzeit ausgewählte Agent wird nicht in CTI erstellt. Sie müssen die Rolleninformationen auswählen.",
    "ccm.agent.message.isornoairecognition": "Intelligenter Status",
    "ccm.agent.message.querycenterfailed": "Die Abfrage des intelligenten Agenten des Callcenters ist fehlgeschlagen.",
    "ccm.agent.message.aiagentnummore": "Die Anzahl der aktivierten intelligenten Agenten darf die Anzahl der Agenten nicht überschreiten, die bei der Aktivierung des Mandanten zugewiesen wurden.",
    "ccm.agent.message.aiagentclosed": "Die intelligente Identifizierungsfunktion des Mandanten ist deaktiviert. Wenden Sie sich an den Systemadministrator.",
    "ccm.agent.message.updateagentandskillfail": "Die Zuordnung zwischen dem Agenten und der Skill-Warteschlange konnte nicht aktualisiert werden.",
    "ccm.agent.message.worknull": "Die Mitarbeiter-ID ist leer.",
    "ccm.agent.message.airecognull": "Die intelligente Erkennungsflagge ist leer.",
    "ccm.agent.message.worknonoexit": "Die Mitarbeiter-ID existiert nicht.",
    "ccmanagement.agent.batchmodify.agentnum": "Nach Mitarbeiter-ID",
    "ccmanagement.agent.batchmodify.agentlis": "Nach Segment",
    "ccm.agent.button.batchmodify": "Stapeländerung",
    "ccm.agent.message.worknostartbig": "Die Anfangs-Mitarbeiter-ID darf nicht größer als die End-Mitarbeiter-ID sein.",
    "ccm.agent.message.typenosame": "Die Skill-Warteschlangentypen müssen identisch sein.",
    "ccm.agent.message.agentandskillnotmatch": "Der Skill-Typ muss mit dem Agenten-Typ übereinstimmen",
    "ccm.agent.message.agenttypecannotbenull": "Wählen Sie einen Agententyp aus, wenn die Skill-Warteschlange ausgewählt ist.",
    "ccm.agent.message.agentnumreachmax": "Anzahl der Zielagententypen hat das Maximum bereits erreicht",
    "ccm.agent.message.omsAgentTypeCheck": "Die ausgewählte Rolle stimmt nicht mit dem Agententyp für einen Online-Agenten überein.",
    "ccm.agent.message.audioagentzero": "Die Anzahl der Audioagenten ist 0. Die Audio-Queue für Klick-zu-Wählen-Kompetenzen kann nicht konfiguriert werden.",
    "ccm.agent.message.videoagentzero": "Die Anzahl der Videoagenten ist 0. Die Video-Queue für Klick-zu-Wählen-Kompetenzen kann nicht konfiguriert werden.",
    "ccm.agent.message.omsAgentTypeBatchCheck": "Der ausgewählte Rollen- oder Agententyp stimmt nicht überein, wenn der Agent (Mitarbeiter-ID [{0}]) online aktiviert ist.",
    "ccm.agent.message.agentnumcannotmuchthanmax": "Anzahl des Ziel-Agententyps kann nicht viel als das Maximum",
    "ccm.agent.message.agentnummaxoperation": "Wählen Sie bis zu 100 Agenten aus.",
    "ccm.agent.message.searchAccount": "Geben Sie eine Agenten-ID ein.",
    "ccm.agent.message.searchSipAccount": "Geben Sie eine SIP-Softphone-Nummer ein.",
    "ccm.agent.message.searchAccountName": "Geben Sie ein Konto ein.",
    "ccm.agent.message.warning": "Warnung",
    "ccm.agent.message.selectAgents": "Wählen Sie einen Agenten aus.",
    "ccm.agent.message.selectSkills": "Bitte wählen Sie eine Skill-Warteschlange aus.",
    "ccm.agent.message.clear": "Alle löschen",
    "ccm.agent.message.delete": "Löschen",
    "ccm.agent.message.deleteSkill": "Skill-Warteschlange löschen",
    "ccm.agent.message.willClearAgents": "Möchten Sie die ausgewählten Agenten wirklich löschen?",
    "ccm.agent.message.willDeleteAgents": "Möchten Sie den ausgewählten Agenten wirklich löschen?",
    "ccm.agent.message.willDeleteSkills": "Möchten Sie wirklich {0} Fertigkeitswarteschlangen löschen?",
    "ccm.agent.message.willDeleteSkillGroups": "Möchten Sie die ausgewählten Kompetenzgruppen wirklich löschen?",
    "ccm.agent.message.agentConfSuccess": "Agenteninformationen wurden erfolgreich konfiguriert.",
    "ccm.agent.message.agentConfFail": "Agenteninformationen konnten nicht konfiguriert werden.",
    "ccm.agent.message.accRepeatFail": "Der Kontoparameter ist doppelt.",
    "ccm.agent.message.agentNotFoundFail": "Agenteninformationen konnten nicht gefunden werden.",
    "ccm.agent.message.agentCreateFail": "Agent in CTI konnte nicht erstellt werden.",
    "ccm.agent.message.agentUpdateFail": "Update-Protokoll fehlgeschlagen.",
    "ccm.agent.message.description.limit": "Die Beschreibung der Skill-Warteschlange ist zu lang",
    "ccm.agent.message.name.limit": "Der Name ist zu lang.",
    "ccm.agent.message.name.grouplimit": "Der Name darf nicht größer als 64 sein.",
    "ccm.agent.message.sysParamCofig": "Systemparameter konnten nicht konfiguriert werden.",
    "ccm.agent.message.sysParamCofig.openVersatileAgentFail": "Ändern Sie den Agententyp auf der Seite Agenten-Verwaltung. Die Anzahl der vielseitigen Agenten und Videoagenten darf nicht größer sein als die maximale Anzahl von Videoagenten, die ein Mandant abonnieren kann.",
    "ccm.agent.message.sysParamCofig.closeVersatileAgentFail": "Ändern Sie den Agententyp auf der Seite Agenten-Verwaltung. Ein vielseitiger Agent kann nicht mit einer Video- oder Video-Klick-zu-Wählen-Fähigkeitswarteschlange verknüpft werden.",
    "ccm.agent.message.deleteAgentSkillFail": "Die Zuordnung der Service Desk-Mitarbeiter-Fertigkeitswarteschlange konnte nicht gelöscht werden.",
    "ccm.agent.message.updateAgentAndSkillFail": "Die Zuordnung der Service Desk-Mitarbeiter-Fertigkeitswarteschlange konnte nicht aktualisiert werden.",
    "ccm.agent.message.createAgentAndSkillFail": "Die Zuordnung der Agenten-Skill-Warteschlange konnte nicht erstellt werden.",
    "ccm.agent.message.searchSkillName": "Geben Sie den Namen der Skill-Warteschlange ein.",
    "ccm.agent.message.searchsoftphone": "Geben Sie die Softphone-Nummer ein.",
    "ccm.agent.message.skillConfSuccess": "Die Informationen der Skill-Warteschlange wurden erfolgreich konfiguriert.",
    "ccm.agent.message.deleteSkillConfSuccess": "Die Informationen der Skill-Warteschlange wurden erfolgreich gelöscht.",
    "ccm.agent.message.createSkillConfSuccess": "Die Informationen der Skill-Warteschlange wurden erfolgreich erstellt.",
    "ccm.agent.message.modifySkillConfSuccess": "Die Informationen der Skill-Warteschlange wurden erfolgreich geändert.",
    "ccm.agent.message.modifymobileagentsuccess": "Die Informationen über die Softphone-Nummer des Mobile Agents wurden erfolgreich geändert.",
    "ccm.agent.message.skillConfFail": "Die Informationen der Skill-Warteschlange konnten nicht konfiguriert werden.",
    "ccm.agent.message.deleteSkillConfFail": "Die Informationen der Skill-Warteschlange konnten nicht gelöscht werden.",
    "ccm.agent.message.createSkillConfFail": "Die Informationen der Skill-Warteschlange konnten nicht erstellt werden.",
    "ccm.agent.message.modifySkillConfFail": "Die Informationen der Skill-Warteschlange konnten nicht geändert werden.",
    "ccm.agent.message.modifymobileagentfail": "Informationen über die Softphone-Nummer des Mobile Agents konnten nicht geändert werden.",
    "ccm.agent.message.idIsEmpty": "Die Skill-Warteschlangen-ID ist leer.",
    "ccm.agent.message.skillHasbeenUsedByAgent": "Die Skill-Warteschlange wurde von einem Agenten besetzt. Löschen Sie zuerst den Agenten.",
    "ccm.agent.message.updateCtiAndDbSuccess": "Synchroner Erfolg.",
    "ccm.agent.message.deleteSkillAndAgentFail": "Skill und Agent konnten nicht gelöscht werden.",
    "ccm.agent.message.addSkillFail": "Skill hinzufügen fehlgeschlagen.",
    "ccm.agent.message.addSkillSuccess": "Skill-Warteschlange erfolgreich hinzugefügt.",
    "ccm.agent.message.maxSkillFail": "Es können maximal 100 Richtlinien für Skill-Warteschlangen konfiguriert werden.",
    "ccm.agent.message.modifySkillFail": "Aktualisierungskompetenz fehlgeschlagen.",
    "ccm.agent.message.queryCallCenterFail": "Abfrage des Callcenters fehlgeschlagen.",
    "ccm.agent.message.refreshSkillException": "Skill-Ausnahme aktualisieren.",
    "ccm.agent.message.updateCtiAndDbFail": "Aktualisieren von CTI und Db fehlgeschlagen.",
    "ccm.agent.message.inputWeight": "Geben Sie einen Gewichtswert ein.",
    "ccm.agent.message.agentCTIFail": "Abfrage der im Gateway geänderten Agenten fehlgeschlagen.Bitte überprüfen Sie die Gateway-Konfiguration.",
    "ccm.agent.message.longCharacters": "Die Nummer darf maximal 25 Zeichen enthalten.",
    "ccm.agent.message.longCharacters.form": "Die Nummer darf maximal 25 Zeichen enthalten",
    "ccm.agent.message.specialCharacters": "Die Nummer enthält Sonderzeichen und Buchstaben.",
    "ccm.agent.message.export": "Exportieren",
    "ccm.agent.message.export.skill": "Kompetenzwarteschlange exportieren",
    "ccm.agent.info.message.export": "Agenteninformationen werden exportiert",
    "ccm.agent.message.selected": "Sie haben\u0020 ausgewählt",
    "ccm.agent.message.willExportAgent": "\u0020Nachrichten. Möchten Sie wirklich exportieren?",
    "ccm.agent.message.allExportAgent": "Möchten Sie wirklich alle Agenteninformationen exportieren?",
    "ccm.agent.message.configvalidatefailed": "Die Validierung des Gateway-Konfigurationselements ist fehlgeschlagen",
    "ccm.agent.message.batchExport": "Stapelexport",
    "ccm.agent.message.allExport": "Alle exportieren",
    "ccm.agent.message.searchTimeRemaining": "Passwortablauf",
    "ccm.agent.message.redislocknull": "Redis-Sperre konnte nicht abgerufen werden.",
    "ccm.agent.message.webRTCVerification": "Dieser Parameter kann nicht konfiguriert werden, wenn die WebRTC-Funktion aktiviert ist.",
    "ccm.agent.title.agentEdit": "Konfiguration der Agenteninformationen",
    "ccm.agent.title.batchagentEdit": "Konfiguration der Batch-Agent-Informationen",
    "ccm.agent.title.selectskill": "Skill-Warteschlange auswählen",
    "ccm.agent.title.serviceAccount": "Zugeordnetes Dienst-Cloud-Konto",
    "ccm.agent.title.skillModify": "Skill-Warteschlange ändern",
    "ccm.agent.title.skillCreate": "Neue Skill-Warteschlange",
    "ccm.agent.title.specialListHis": "Verlauf der speziellen Liste",
    "ccm.satisfactionconfig.message.switchtip": "Nach der Aktivierung dieser Funktion zeigt das System automatisch die Seite mit der Zufriedenheitsumfrage an, nachdem der Web-Channel-Service abgeschlossen ist. Nachdem andere Multimedia-Kanaldienste abgeschlossen sind, sendet das System automatisch den Inhalt der Zufriedenheitsvorlage.",
    "ccm.satisfactionconfig.message.timelimittip": "Wenn die Antwortzeit des Kunden die gültige Antwortzeit überschreitet, wird die Antwort nicht wirksam.",
    "ccm.agent.tips.confSuccess": "Konfiguration erfolgreich.",
    "ccm.agent.tips.noagent": "Im ausgewählten Nummernsegment ist kein Agent vorhanden.",
    "ccm.agent.tips.confFail": "Konfiguration fehlgeschlagen",
    "ccm.speciallist.msg.enable.fail": "Aktivierung fehlgeschlagen",
    "ccm.agent.pageurls.create": "Neu",
    "ccm.agent.pageurls.sernum": "Nein.",
    "ccm.agent.pageurls.pagename": "Seitenname",
    "ccm.agent.pageurls.accessaddr": "Zugriffsadresse",
    "ccm.agent.pageurls.openMode": "Offener Modus",
    "ccm.agent.pageurls.configpage": "Konfigurationsseite",
    "ccm.agent.pageurls.config": "Konfiguration",
    "ccm.agent.pageurls.isenable": "Aktivieren",
    "ccm.agent.pageurls.operation": "Betrieb",
    "ccm.agent.pageurls.delete": "Löschen",
    "ccm.agent.pageurls.edit": "Bearbeiten",
    "ccm.agent.pageurls.moveup": "Auf",
    "ccm.agent.pageurls.movedown": "Unten",
    "ccm.agent.pageurls.createcall": "Popup-Fenster Neuer eingehender Anruf",
    "ccm.agent.pageurls.config.screen.pop.up": "Konfigurationsbildschirm-Popup",
    "ccm.agent.pageurls.unsaved.data.exists": "Es sind nicht gespeicherte Daten vorhanden. Bitte speichern Sie es zuerst.",
    "ccm.agent.pageurls.errorsave": "Fehler",
    "ccm.agent.pageurls.errorinfo": "Erstellung fehlgeschlagen.",
    "ccm.agent.pageurls.successinfo": "Erstellt erfolgreich.",
    "ccm.agent.pageurls.successinfo.noPoint": "Erstellt erfolgreich",
    "ccm.agent.pageurls.maxcalling": "Die maximale Anzahl von Popup-Bildschirmen für eingehende Anrufe beträgt {limit}.",
    "ccm.agent.pageurls.updatecall": "Popup-Fenster zum Bearbeiten des eingehenden Anrufs",
    "ccm.agent.pageurls.confdelete": "Löschvorgang bestätigen",
    "ccm.agent.pageurls.confreset": "Zurücksetzen bestätigen",
    "ccm.agent.pageurls.isconfdelete": "Möchten Sie sie wirklich löschen?",
    "ccm.agent.pageurls.isconfreset": "Möchten Sie es wirklich zurücksetzen?",
    "ccm.agent.pageurls.success": "Erfolgreich",
    "ccm.agent.pageurls.delsuccess": "Erfolgreich gelöscht.",
    "ccm.agent.pageurls.fail": "Fehler",
    "ccm.agent.pageurls.delfail": "Löschen fehlgeschlagen.",
    "ccm.agent.pageurls.adressval": "Die Zugriffsadresse muss mit'https://' oder'http://' beginnen.",
    "ccm.agent.pageurls.adressval.noPoint": "Die Zugriffsadresse muss mit'https://' oder'http://' beginnen",
    "ccm.agent.pageurls.updateinfo": "Aktualisiert erfolgreich.",
    "ccm.agent.moveup.error": "Aufrücken fehlgeschlagen.",
    "ccm.agent.moveup.success": "Erfolgreich aufsteigen.",
    "ccm.agent.movedown.error": "Abwärtsbewegung fehlgeschlagen.",
    "ccm.agent.movedown.success": "Erfolgreich nach unten bewegt.",
    "ccm.agent.pageurls.errorupdate": "Aktualisierung fehlgeschlagen.",
    "ccm.agent.pageurls.confnaming": ". Stellen Sie sicher, dass der Name nicht im System vorhanden ist.",
    "ccm.agent.pageurls.confonlynaming": ". Stellen Sie sicher, dass der Name eindeutig ist.",
    "ccm.agent.pageurls.maxcallingnum": ". Stellen Sie sicher, dass die Anzahl der Popup-Bildschirme 5 nicht überschreitet.",
    "ccm.agent.pageurls.whitelisturl": ". Wenden Sie sich an den Systemadministrator, um die URL zur Vertrauensliste hinzuzufügen.",
    "ccm.agent.pageurls.typenosupported": "Multimedia unterstützt keine interne Seitenkonfiguration.",
    "ccm.agent.pageurls.independentlynosupported": "Die interne Seitenkonfiguration wird für das unabhängige Öffnen nicht unterstützt.",
    "ccm.agent.pageurls.integrationType": "Integrationstyp",
    "ccm.pageurls.select.embedding": "Eingebettet",
    "ccm.pageurls.select.independently": "Unabhängig geöffnet",
    "ccm.pageurls.select.urlParameter": "Parameter für URL-GET-ANFORDERUNG",
    "ccm.pageurls.select.webApi": "Web-API",
    "ccm.pageurls.select.postParameter": "Parameter für URL-POST-ANFORDERUNG",
    "ccm.agent.pageurl.httptip": "(Nicht sicher)",
    "ccm.agent.protocol.check": "Beachten Sie, dass das von der URL-Adresse verwendete Netzwerkprotokoll kein sicheres Protokoll ist. Wenn Sie sich für die Verwendung dieses Protokolls entscheiden, können Sicherheitsrisiken bestehen.",
    "ccm.agent.url.params.tips": "Beachten Sie, dass die Art und Weise, wie der Parameter URL GET REQUEST erstellt wird, Ihre Parameter für eine offene Adresse offenlegt.",
    "ccm.agent.contact.accountname": "Kontoname",
    "ccm.agent.contact.caller": "Anrufernummer",
    "ccm.agent.contact.called": "Angerufene Nummer",
    "ccm.agent.contact.acceptno": "Verarbeitete Nummer",
    "ccm.agent.contact.customername": "Kundenname",
    "CCM.AGENT.CONTACT.ORG": "Agentenorganisation",
    "ccm.agent.contact.allcalltype": "Alle Anrufarten",
    "ccm.agent.contact.callserno": "S/N anrufen",
    "ccm.agent.contact.workno": "Mitarbeiter-ID",
    "ccm.agent.contact.begintime": "Startzeit ",
    "ccm.agent.contact.endtime": "Endzeit",
    "ccm.agent.contact.calltime": "Anrufdauer (s)",
    "ccm.agent.contact.recordtime": "Aufzeichnungsdauer(n)",
    "ccm.agent.contact.hungup": "Aufhängerparty",
    "ccm.agent.contact.oircall": "Original angerufene Nummer",
    "ccm.agent.contact.contactno": "Kontaktnummer",
    "ccm.agent.contact.callid": "Anrufkennung",
    "ccm.agent.contact.assistantCallid": "ID für gemeinsamen Anruf",
    "ccm.agent.contact.calltype": "Anruftyp",
    "ccm.agent.contact.recordingfiles": "Aufnahmedatei",
    "ccm.agent.contact.userhungup": "Benutzer",
    "ccm.agent.contact.agenthungup": "Agent",
    "ccm.agent.contact.robothungup": "Roboter",
    "ccm.agent.contact.otherhungup": "Sonstige",
    "ccm.agent.contact.skillhungup": "Zur Kompetenzwarteschlange weiterleiten",
    "ccm.agent.contact.businesshungup": "Weiterleitung an Agenten",
    "ccm.agent.contact.numhungup": "Weiterleiten an externe Nummer",
    "ccm.agent.contact.ivrhungup": "Weiter zu IVR",
    "ccm.agent.contact.callin": "Eingehender Anruf",
    "ccm.agent.contact.callout": "Ausgehender Anruf",
    "ccm.agent.contact.callinandout": "Doppelter Anruf",
    "ccm.agent.contact.predictiveCall": "Prognostizierter ausgehender Anruf",
    "ccm.agent.contact.preCallout": "Vorschau des ausgehenden Anrufs",
    "ccm.agent.contact.assistant": "Gemeinsamer Anruf",
    "ccm.agent.contact.clicktocall": "Klicken-zum-Wählen",
    "ccm.agent.contact.multi-media": "Multimedia",
    "ccm.agent.contact.audio-visual": "Audio/Video",
    "ccm.agent.contact.web": "Web",
    "ccm.agent.contact.weChat": "WeChat",
    "ccm.agent.contact.faceBook": "Facebook",
    "ccm.agent.contact.twitter": "X (Twitter)",
    "ccm.agent.contact.fiveg": "5G RCS",
    "ccm.agent.contact.email": "E-Mail-Adresse",
    "ccm.agent.contact.Email": "E-Mail-Adresse",
    "ccm.agent.contact.voiceBot": "Stimme",
    "ccm.agent.contact.line": "LINIE",
    "ccm.agent.contact.whatsapp": "WhatsApp",
    "ccm.agent.contact.sms": "SMS",
    "ccm.agent.contact.Instagram": "Instagram",
    "ccm.agent.contact.Telegram": "Telegramm",
    "ccm.agent.contact.others-channels": "Andere Kanäle",
    "ccm.agent.contact.satiscomment": "Zufriedenheitsbewertung",
    "ccm.ivr.button.traceInfo": "Klicken Sie hier, um das aktuelle Ablaufverfolgungsprotokoll anzuzeigen.",
    "ccm.agent.title.IVRTrace": "IVR-Ablaufverfolgung",
    "ccm.agent.contact.noAuth": "Keine Erlaubnis.",
    "ccm.agent.contact.authHandle": "Wenden Sie sich an das entsprechende Managementpersonal.",
    "ccm.agent.contact.Voice": "Stimme",
    "ccm.agent.contact.vidio": "Video",
    "ccm.agent.contact.mediaType": "Medientyp",
    "ccm.agent.contact.reset": "Zurücksetzen",
    "ccm.agent.contact.resetAll": "Alle zurückgesetzt",
    "ccm.agent.contact.refreshAll": "Aktualisieren",
    "ccm.agent.contact.play": "Abspielen",
    "ccm.agent.contact.playcomfirm": "Wiedergabe bestätigen",
    "ccm.agent.contact.playdefault": "Die Aufnahmedauer ist leer. Möchten Sie die Standarddauer von 60 Minuten verwenden?",
    "ccm.agent.contact.download": "Herunterladen",
    "ccm.agent.contact.downloadrecord": "Aufzeichnung herunterladen",
    "ccm.agent.contact.downloadvideo": "Video herunterladen",
    "ccm.agent.contact.playLeaveMessage": "Die folgende Nachricht wird abgespielt:",
    "ccm.agent.contact.playRecording": "Abspielen einer Aufnahme",
    "ccm.agent.contact.playVideo": "Abspielen eines Videos",
    "ccm.agent.contact.norecording": "Keine Aufzeichnung.",
    "ccm.agent.contact.customizes": "Anpassung",
    "ccm.agent.contact.downloadFail": "Download fehlgeschlagen.",
    "ccm.agent.contact.QUERU_RECORDINFO_FAIL_CODE": "Die Aufnahmeinformationen sind leer.",
    "ccm.agent.contact.QUERU_SFTP_FAIL_CODE": "Die SFTP-Informationen sind leer.",
    "ccm.agent.contact.QUERU_VCCINFO_FAIL_CODE": "Die Informationen zum virtuellen Callcenter sind leer.",
    "ccm.agent.contact.SFTP_DOWNLOAD_FAIL_CODE": "Aufzeichnungsdatei konnte nicht vom SFTP-Server heruntergeladen werden.",
    "ccm.agent.contact.CONNECT_FAIL_CODE": "Die Verbindung zum SFTP-Server konnte nicht hergestellt werden.",
    "ccm.agent.contact.QUERY_SERVER_FAIL_CODE": "Abfragedienst konnte nicht aufgerufen werden.",
    "ccm.agent.contact.SFTP_INCORRECT_FILE_CODE": "Die SFTP-Serverinformationen sind falsch",
    "ccm.agent.contact.resourceloading": "Ressourcen werden geladen ...",
    "ccm.agent.contact.playfail": "Wiedergabe fehlgeschlagen.",
    "ccm.agent.contact.resourcefail": "Beim Laden der Aufzeichnung ist ein Fehler aufgetreten.",
    "ccm.agent.contact.download.frequently": "Sie haben auch schon oft versucht, es herunterzuladen. Versuchen Sie es später erneut.",
    "ccm.agent.contact.contactdetail": "Kontaktdaten",
    "ccm.agent.contact.talkReason": "Anrufgrund",
    "ccm.agent.contact.sameContactRecord": "Gleicher Anrufkontaktdatensatz",
    "ccm.agent.contact.transferRecord": "Übertragungsaufzeichnung",
    "ccm.agent.contact.talkRemark": "Anmerkungen zum Anruf",
    "ccm.agent.contact.voiceVidio": "Audio/Video",
    "ccm.agent.contact.multimedia": "Multimedia",
    "ccm.agent.contact.clicktodial": "Klicken Sie, um zu wählen",
    "ccm.agent.contact.audioclicktodial": "Voice Click to Dial (Wählen durch Klicken mit der Stimme)",
    "ccm.agent.contact.videoclicktodial": "Video-Klicken zum Wählen",
    "ccm.agent.contact.callerPresent": "Anzeigenummer des Benutzers",
    "ccm.agent.contact.agentPresent": "Anzeigenummer des Agenten",
    "ccm.agent.contact.reasonCode": "Code für den Grund",
    "ccm.agent.contact.relCallBackFlag": "Rückrufmarkierung freigeben",
    "ccm.agent.contact.talkCallbackFlag": "Gesprächsrückrufmarkierung",
    "ccm.agent.contact.nohaveevent": "Kein solches Ereignis",
    "ccm.agent.contact.calledhangup": "Ende des Anrufs, Auflegen angerufen",
    "ccm.agent.contact.callershutdown": "Ende des Anrufs, Anrufer wird beendet",
    "ccm.agent.contact.callerfailed": "Ausgehender Anrufer fehlgeschlagen",
    "ccm.agent.contact.calledfailed": "Ausgehender Anruf fehlgeschlagen",
    "ccm.agent.contact.callernook": "Ausgehender Anrufer antwortet nicht",
    "ccm.agent.contact.callednook": "Ausgehender Anruf ohne Antwort",
    "ccm.agent.contact.startcallfailed": "Start des Anrufs fehlgeschlagen",
    "ccm.agent.contact.otherexception": "Andere Ausnahme",
    "ccm.agent.contact.recordInfo": "Aufzeichnen von Dateidetails",
    "ccm.agent.contact.gateway.fail": "Anforderung an die Gateway-Ausnahme. Bitte überprüfen Sie die Gateway-Konfiguration.",
    "ccm.agent.contact.gateway.exception": "Gateway-interne Ausnahme. Bitte überprüfen Sie die Gateway-Konfiguration.",
    "ccm.agent.operation.noMoreThan1Days": "Der Zeitraum darf nicht länger als 1 Tage sein.",
    "ccm.agent.operation.noMoreThan3Days": "Der Zeitraum darf drei Tage nicht überschreiten.",
    "ccm.agent.operation.noMoreThan7Days": "Der Zeitraum darf 7 Tage nicht überschreiten.",
    "ccm.agent.operation.noMoreThan30Days": "Der Zeitraum darf 30 Tage nicht überschreiten.",
    "ccm.agent.operation.noMoreThan31Days": "Der Zeitraum darf 31 Tage nicht überschreiten.",
    "ccm.agent.operation.noMoreThan90Days": "Der Zeitraum darf 90 Tage nicht überschreiten.",
    "ccm.agent.label.thirtyonedays": "31 Tage",
    "ccm.agent.label.ninetydays": "90 Tage",
    "ccm.agent.operation.searchcountid": "Dienstkonto",
    "ccm.agent.operation.countid": "Kontonummer",
    "ccm.agent.operation.searchworkno": "Bitte geben Sie die Mitarbeiter-ID ein",
    "ccm.agent.operation.optype": "Vorgangstyp",
    "ccm.agent.operation.optime": "Betriebszeit",
    "ccm.agent.operation.opresult": "Ergebnis",
    "ccm.agent.operation.ID": "Vorgang Nr.",
    "ccm.agent.operation.failCode": "Code für Fehlerursache",
    "ccm.agent.operation.vdnNotEnabled": "Der VDN ist nicht aktiviert.",
    "ccm.recognition.msg.unsubscribeConfirm": "Wenn die intelligente Erkennung deaktiviert ist, ist auch der intelligente Agentenassistent deaktiviert.",
    "ccm.agent.operation.HOLD": "Anhalten",
    "ccm.agent.operation.UNHOLD": "Halten",
    "ccm.agent.operation.MUTE": "Stummschalten",
    "ccm.agent.operation.UNMUTE": "Stummschaltung aufheben",
    "ccm.agent.operation.RECORDPLAY": "Aufzeichnung wiedergeben",
    "ccm.agent.operation.LISTEN": "Prüfung der Hörqualität",
    "ccm.agent.operation.INSERT": "Qualitätsprüfung einfügen",
    "ccm.agent.operation.CANCELLISTEN": "Abhören abbrechen",
    "ccm.agent.operation.CANCELINSERT": "Einfügung abbrechen",
    "ccm.agent.operation.SWITCH": "Umschalten",
    "ccm.agent.operation.INTERCEPT": "Abfangen",
    "ccm.agent.operation.FORCELOGOUT": "Ausgang erzwingen",
    "ccm.agent.operation.FORCEIDEL": "Leerlauf erzwingen",
    "ccm.agent.operation.FORCEBUSY": "Beschäftigt erzwingen",
    "ccm.agent.operation.HELP": "Beratung",
    "ccm.agent.operation.CALLINNER": "Interner Anruf",
    "ccm.agent.operation.TWOSTAGEDIALING": "Zweistufiges Wählen",
    "ccm.agent.operation.WHISPER": "Flüstern",
    "ccm.agent.operation.CANCELWHISPER": "Flüstern abbrechen",
    "ccm.agent.operation.SAYBUSY": "Ausgelastet",
    "ccm.agent.operation.SAYFREE": "Leerlauf",
    "ccm.agent.operation.REST": "Ersuchen um Ruhe",
    "ccm.agent.operation.CANCELREST": "Ruhezeit abbrechen",
    "ccm.agent.operation.CONFERENCE": "Drei-Parteien-Konferenz",
    "ccm.agent.operation.AllOpType": "Alle Vorgangsarten",
    "ccm.agent.operation.CALLOUT": "CallOut",
    "ccm.agent.operation.ANSWER": "Antworten",
    "ccm.agent.operation.mediaswitch": "Audio-/Videoschalter",
    "ccm.agent.operation.RELEASE": "Freigabe",
    "ccm.agent.operation.ADJUSTSKILL": "Skill-Warteschlange anpassen",
    "ccm.agent.operation.FORCEDROPCALL": "Erzwungene Freigabe",
    "ccm.agent.operation.ADJUSTVIDEOMODE": "Anpassen der Videofenstergröße",
    "ccm.agent.login.logIP": "IP-Adresse für die Anmeldung",
    "ccm.agent.login.ID": "Betriebsabfolge",
    "ccm.agent.login.LOGIN": "Anmelden",
    "ccm.agent.login.LOGOUT": "",
    "ccm.agent.login.FORCELOGOUT": "Abmeldung erzwingen",
    "ccm.agent.login.FORCELOGIN": "Anmeldung erzwingen",
    "ccm.speciallist.msg.delete.fail": "Löschen fehlgeschlagen",
    "ccm.agent.Transfer.CallForwarding": "Anruf weiterleiten ",
    "ccm.agent.Transfer.Intercept": "Abfangen",
    "ccm.agent.Transfer.tranfermode": "Übertragungsmodus",
    "ccm.agent.Transfer.devicetype": "Zielgerätetyp",
    "ccm.agent.Transfer.deviceDesc": "Zielgerät",
    "ccm.agent.Transfer.deviceAddr": "Zielgeräteadresse",
    "ccm.agent.Transfer.theAgent": "Agent",
    "ccm.contactitemconfig.message.itemValidatefirst": "Der erste Buchstabe muss ein englischer Buchstabe sein, Unterstrich (_), bzw. Dollarzeichen ($)",
    "ccm.agent.restrecord.reststatus": "Ruhezustand",
    "ccm.agent.restrecord.reststatus.resting": "Ruhen",
    "ccm.agent.restrecord.reststatus.normal.endrest": "Rest normal beendet",
    "ccm.agent.restrecord.reststatus.timeout.endrest": "Restzeitüberschreitung",
    "ccm.agent.restrecord.reststatus.timeout.Forcible.endrest": "Rest zum Ende gezwungen",
    "ccm.agent.restrecord.reststatus.Abnormal.endrest": "Rest anormal beendet",
    "ccm.agent.restrecord.rest.beginTime": "Startzeit Rest",
    "ccm.agent.restrecord.rest.endTime": "Endzeit der Restzeit",
    "ccm.agent.restrecord.rest.duration": "Ruhedauer",
    "ccm.agent.restrecord.rest.currentstatus": "Aktueller Status",
    "ccm.agent.restrecord.rest.reason": "Restgrund",
    "SM.ORGSTAFF.LABEL.INPUT_SEARCH_KEYWORD": "Schlüsselwort",
    "casemanagement.common.label.nodata": "Keine Daten.",
    "casemanagement.user.title.name": "Benutzername",
    "casemanagement.user.title.select": "Auswählen",
    "casemanagement.user.title.account": "Konto",
    "casemanagement.user.button.cancel": "Abbrechen",
    "casemanagement.user.button.ok": "OK",
    "ccm.agent.verify.fail": "Überprüfung fehlgeschlagen.",
    "ccm.agent.password.wrong": "Kennwort konnte nicht bestätigt werden.",
    "ccm.agent.password.agentNull": "Die Agenteninformationen sind leer.",
    "ccm.agent.tips.inputpassword": "Geben Sie das Passwort des aktuellen Anmeldekontos ein.",
    "ccm.agent.title.agentpassword": "Kontokennwort",
    "ccm.agent.button.close": "Schließen",
    "ccm.agent.title.agentinfo": "Agenteninformationen",
    "ccm.agent.title.querysippwd": "Softphone-Kennwort überprüfen",
    "ccm.agent.skill.nameInfo": "Skill-Warteschlangenname:",
    "ccm.agent.skill.skillExist": "existiert bereits. Geben Sie einen anderen Namen ein.",
    "ccm.agent.ten.tenantInfor": "Tenant-Info",
    "ccm.agent.ten.tenantName": "Name des Mieters",
    "ccm.agent.ten.companyName": "Unternehmen",
    "ccm.agent.ten.createDate": "Erstellungszeit",
    "ccm.agent.ten.expiryDate": "Ablaufdatum",
    "ccm.agent.ten.contactInfor": "Kontaktmethode",
    "ccm.agent.ten.phonenumber": "Mobiltelefonnummer",
    "ccm.agent.ten.email": "E-Mail-Adresse",
    "ccm.agent.ten.resourceinfor": "Ressourceninformationen",
    "ccm.agent.ten.maxAgents": "Sprachagenten",
    "ccm.agent.ten.maxCalls": "Max. gleichzeitige Sprachanrufe",
    "ccm.agent.ten.maxVideoAgents": "Anzahl der Videoagenten",
    "ccm.agent.ten.maxVideoCalls": "Max. gleichzeitige Videoanrufe",
    "ccm.agent.ten.ivrchannels": "Anzahl der Audio-IVR-Kanäle",
    "ccm.agent.ten.videoIvrchannels": "Anzahl der Video-IVR-Kanäle",
    "ccm.agent.ten.ttschannelnums": "TTS-Anzahl",
    "ccm.agent.ten.asrchannelnums": "ASR-Menge",
    "ccm.agent.ten.recordtime": "Aufbewahrungszeitraum der Aufzeichnung",
    "ccm.agent.ten.vdnid": "VDN-ID",
    "ccm.agent.ten.tenantId": "Mieter-Raum-ID",
    "ccm.agent.ten.sipInfo": "SIP-Adresse und Port",
    "ccm.agent.ten.agentSipInfo": "IP-Adresse und Portnummer des Agentenregistrierungsservers",
    "ccm.agent.ten.anonymousSipInfo": "IP-Adresse und Portnummer des anonymen Anrufservers",
    "ccm.satisfactiontemplate.message.savefail": "Das Speichern der Zufriedenheitsumfragevorlage ist fehlgeschlagen.",
    "ccm.satisfactiontemplate.message.confirm": "Bestätigen Sie, ob der Vorlageninhalt basierend auf dem Zufriedenheitsgrad aktualisiert werden soll.",
    "ccm.satisfactiontemplate.message.queryfail": "Die Zufriedenheitsumfragevorlage konnte nicht abgefragt werden.",
    "ccm.satisfactiontemplate.tipscontent": "Die Nachrichtenvorlage für die Zufriedenheitsumfrage wird verwendet, um Zufriedenheitsumfragenachrichten an Kunden in den Kanälen Instagram, Telegram, Telegram, Line, Web Chat, Face Book, X (Twitter), 5G RCS und SMS zu senden",
    "ccm.satisfaction.changetips": "Hinweis: Die Konfiguration der Zufriedenheitsstufe wurde geändert. Ändern Sie die Vorlage für die Zufriedenheitsumfrage entsprechend.",
    "ccm.satisfactionconfig.message.question": "Die Nachrichtenvorlage für die Zufriedenheitsumfrage wird verwendet, um Zufriedenheitsumfragenachrichten an Kunden in den Kanälen Instagram, Telegram, Telegram, Line, Web Chat, Face Book, X (Twitter), 5G RCS und SMS zu senden",
    "ccm.satisfactionlevel.satislevel.config": "Konfiguration der Zufriedenheitsstufe",
    "ccm.satisfactionlevel.satislevel.button": "Schaltflächen",
    "ccm.satisfactionlevel.satislevel.description": "Beschreibung",
    "ccm.satisfactionlevel.satislevel.operat": "Betrieb",
    "ccm.satisfactionlevel.satislevel.moveup": "Nach oben",
    "ccm.satisfactionlevel.satislevel.movedown": "Nach unten",
    "ccm.agent.calledroute.createcalledroute": "Neu",
    "ccm.agent.calledroute.errorsave": "Fehler",
    "ccm.agent.calledroute.createfail": "Lokale Daten konnten nicht hinzugefügt werden.",
    "ccm.agent.calledroute.createsuccess": "Erfolgreich hinzugefügt.",
    "ccm.agent.calledroute.updatecalledroute": "Bearbeiten",
    "ccm.agent.calledroute.title.create": "Erstellen einer Konfiguration des angerufenen Teilnehmers",
    "ccm.agent.calledroute.title.updatecalledroute": "Konfiguration des angerufenen Teilnehmers bearbeiten",
    "ccm.agent.calledroute.confdelete": "Löschvorgang bestätigen",
    "ccm.agent.calledroute.isconfdelete": "Möchten Sie den ausgewählten angerufenen Teilnehmer wirklich löschen?",
    "ccm.agent.calledroute.success": "Erfolg",
    "ccm.agent.calledroute.delsuccess": "Erfolgreich gelöscht.",
    "ccm.agent.calledroute.fail": "Fehler",
    "ccm.agent.calledroute.exception": "Überprüfen Sie den Redis-Status oder die CC-Gateway-Konfiguration.",
    "ccm.agent.calledroute.delfail": "Die lokalen Daten konnten nicht gelöscht werden.",
    "ccm.agent.calledroute.editfail": "Die lokalen Daten konnten nicht bearbeitet werden.",
    "ccm.agent.calledroute.editsuccess": "Erfolgreich bearbeitet.",
    "ccm.agent.calledroute.create": "Neu",
    "ccm.agent.calledroute.accesscode": "Zugangscode",
    "ccm.agent.calledroute.devicetype": "Gerätetyp",
    "ccm.agent.calledroute.devicedesc": "Gerätename",
    "ccm.agent.calledroute.extcode": "Erweiterungscode",
    "ccm.agent.calledroute.delete": "Löschen",
    "ccm.agent.calledroute.edit": "Bearbeiten",
    "ccm.agent.calledroute.pagename": "Seitenname",
    "ccm.agent.calledroute.operation": "Betrieb",
    "ccm.agent.calledroute.search.devicedesc": "Geben Sie eine Gerätebeschreibung ein.",
    "ccm.calledroute.title.selectaccesscode": "Zugangscode auswählen",
    "ccm.calledroute.tip.selectaccesscode": "Bitte wählen Sie zuerst einen Zugangscode aus.",
    "ccm.accesscode.placeholder.accessdesc": "Geben Sie eine Beschreibung des Zugangscodes ein.",
    "ccm.agent.calledroute.refresh": "Aktualisieren",
    "ccm.agent.calledroute.refreshsuccess": "Aktualisierung erfolgreich.",
    "ccm.agent.calledroute.ivr": "Bitte wählen Sie IVR.",
    "ccm.agent.calledroute.skill": "Skill-Warteschlange",
    "ccm.calledroute.button.cancel": "Abbrechen",
    "ccm.calledroute.button.finish": "Abgeschlossen",
    "ccm.agent.calledroute.placeholder.extcode": "Bitte geben Sie einen Durchwahlcode ein.",
    "ccm.agent.calledroute.placeholder.accesscode": "Bitte wählen Sie einen Zugangscode aus.",
    "ccm.agent.calledroute.placeholder.ivr": "Bitte wählen Sie ein IVR.",
    "ccm.agent.calledroute.placeholder.skill": "Bitte wählen Sie eine Skill-Warteschlange aus.",
    "ccm.calledroute.title.selectivrflow": "IVR auswählen",
    "ccm.calledroute.title.selectskill": "Skill-Warteschlange auswählen",
    "ccm.calledroute.tip.selectivrexception": "IVR-Flows konnten in CTI nicht abgefragt werden.",
    "ccm.calledroute.message.selectcalledroute": "Wählen Sie die Konfiguration des angerufenen Teilnehmers aus.",
    "ccm.agent.calledroute.dnid": "Konfigurations-ID",
    "ccm.agent.calledroute.create.accesscode.none": "Der Systemzugangscode, der für den angerufenen Teilnehmer konfiguriert wurde, wurde nicht zugewiesen.",
    "ccm.agent.calledroute.create.accesscode.repeat": "Die Kombination aus Systemzugangscode und Durchwahlcode für den angerufenen Teilnehmer muss eindeutig sein.",
    "ccm.agent.calledroute.create.accesscode.noexist": "Der Zugangscode, auf den die Konfiguration des angerufenen Teilnehmers verweist, existiert nicht.",
    "ccm.agent.calledroute.create.skill.none": "Die Skill-Warteschlange, auf die die Konfiguration des angerufenen Teilnehmers verweist, existiert nicht.",
    "ccm.agent.calledroute.create.ivr.limit": "Wenn der Gerätetyp IVR ist, kann der Medientyp des Zugriffscodes nur Click-to-Dial, Rückrufanforderung oder allgemeiner Anruf sein.",
    "ccm.agent.calledroute.create.gateway.none": "Das Routing-Skript, auf das die Konfiguration des angerufenen Teilnehmers verweist, existiert nicht.",
    "ccm.agent.calledroute.create.media.match": "Der Systemzugangscode, der für den angerufenen Teilnehmer konfiguriert wurde, unterscheidet sich von dem Medientyp, der für den angerufenen Teilnehmer konfiguriert wurde.",
    "ccm.agent.calledroute.gateway.fail": "Die Informationen konnten nicht mit der CTI-Plattform synchronisiert werden. Bitte überprüfen Sie die Gateway-Konfiguration.",
    "ccm.agent.calledroute.delete.skill.default": "Die Konfiguration des angerufenen Teilnehmers wird von der standardmäßigen privaten Skill-Warteschlange referenziert und kann nicht gelöscht werden.",
    "ccm.agent.calledroute.delete.skill.use": "Die Konfiguration des angerufenen Teilnehmers wird von einer Skill-Warteschlange referenziert und kann nicht gelöscht werden.",
    "ccm.agent.calledroute.delete.work.use": "Die Konfiguration des angerufenen Teilnehmers wird vom Agenten referenziert und kann nicht gelöscht werden.",
    "ccm.agent.calledroute.nmsfail": "Synchronisation mit dem NMS fehlgeschlagen.",
    "ccm.agent.calledroute.nmsdata.error": "Daten konnten nicht mit dem NMS synchronisiert werden. Die Konfigurationsdaten der angerufenen Partei des NMS stimmen nicht mit den lokalen Daten des CCM überein.",
    "ccm.agent.calledroute.ccivrdata.error": "Daten konnten nicht mit dem NMS synchronisiert werden. Die CC-IVR-Prozessdaten sind falsch.",
    "ccm.agent.calledroute.nmsconnect.fail": "NMS-Verbindung fehlgeschlagen.",
    "ccm.agent.accesscode.description.notempty": "Die Zugangscodebeschreibung darf nicht leer sein.",
    "ccm.agent.accesscode.description.containspatial": "Die Beschreibung des Zugangscodes darf keine Sonderzeichen enthalten %_^ & * < > $ /",
    "ccm.satisfactiontemplate.tips": "Hinweis: Das System verwendet die Standardsprache des Mandanten, um den Vorlageninhalt zu generieren. Die Vorlage für die Zufriedenheitsumfrage wird verwendet, um Zufriedenheitsumfragenachrichten an Kunden in Instagram, Telegram und WhatsApp zu senden. Line, Web Chat, Face Book, X (Twitter), 5G RCS und SMS-Kanäle.",
    "ccm.accesscode.label.index": "Nein.",
    "ccm.accesscode.label.accesscode": "Zugangscode",
    "ccm.accesscode.label.accesscodedesc": "Beschreibung des Zugangscodes",
    "ccm.accesscode.title.accesscodedesc": "Zugangscodebeschreibung bearbeiten",
    "ccm.ivr.label.processid": "Prozess-ID",
    "ccm.ivr.label.processdesc": "Prozessbeschreibung",
    "ccm.ivr.label.processaccesscode": "Flusscode",
    "ccm.ivr.label.processfilename": "Dateiname",
    "ccm.ivr.placeholder.ivrdesc": "Geben Sie die Prozessbeschreibung ein.",

    "ccm.skill.label.skillname": "Skill-Warteschlangenname",
    "ccm.skill.label.skilldesc": "Beschreibung",
    "ccm.skill.label.maxwaittime": "Max. Warteschlangendauer",
    "ccm.skill.label.maxwaitnum": "Max. Anrufe in der Warteschleife",
    "ccm.skill.placeholder.skilldesc": "Bitte geben Sie einen Skill-Warteschlangennamen ein.",
    "ccm.skill.message.skillpara": "Kann Skill nicht selbst konfigurieren",
    "ccm.agent.calledroute.recordexist": "Der Zugangscode ist an den Erweiterungscode gebunden. Bitte geben Sie einen neuen Durchwahlcode ein.",
    "ccm.agent.calledroute.require": "Geben Sie alle Pflichtfelder ein.",
    "ccm.agent.calledroute.extcodewarn": "Der Durchwahlcode darf nur Ziffern enthalten.",
    "ccm.agent.calledroute.extlengthcodewarn": "Die Länge des Erweiterungscodes darf nicht länger als 12 sein",
    "ccm.agent.calledroute.accesscodewarn": "Der Zugangscode darf nur Ziffern enthalten!",
    "ccm.agent.calledroute.device": "Wählen Sie ein Gerät aus.",

    "ccm.agent.accesscode.index": "Nein.",
    "ccm.agent.accesscode.accesscode": "Zugangscode",
    "ccm.agent.accesscode.description": "Beschreibung des Zugangscodes",
    "ccm.agent.accesscode.n400number": "Servicenummer",
    "ccm.agent.accesscode.description.require": "Die Beschreibung des Zugangscodes darf nicht leer sein.",
    "ccm.agent.accesscode.description.limit": "Die Beschreibung des Zugangscodes darf nicht mehr als 50 Zeichen enthalten.",
    "ccm.agent.sysparamconfig.paramwarn": "Wählen Sie einen gültigen Parameterwert aus.",
    "ccm.agent.sysparamconfig.resttimewarn": "Der Wert muss eine ganze Zahl sein.",
    "ccm.agent.sysparamconfig.worknowarn": "Trennen Sie Agenten-IDs durch Kommas (,).",
    "ccm.agent.sysparamconfig.worknonumwarn": "Die Anzahl der Agenten-IDs darf 10 nicht überschreiten.",
    "ccm.agent.sysparamconfig.hasSelectAgentWorkRepeat": "Im ausgewählten Abschnitt gibt es Duplikate!",
    "ccm.agent.sysparamconfig.hasSelectAgentWorkLimit": "Es sind nicht mehr als fünf Sitze zulässig.",
    "ccm.agent.sysparamconfig.positiveinteger": "Bitte geben Sie eine positive ganze Zahl ein!",
    "ccm.agent.sysparamconfig.resttimenumberwarn": "Die Ruhedauer muss zwischen 1 und 1440 liegen (enthält nicht 1440)",
    "ccm.agent.sysparamconfig.worknorangewarn": "Die Agenten-ID muss zwischen 101 und 50000 liegen.",
    "ccm.agent.sysparamconfig.multimediawarn": "Die Anzahl der gleichzeitigen Multimedia-Nachrichten muss zwischen 1 und 60 liegen.",
    "ccm.agent.sysparamconfig.outboundtimecheck": "Das Zeitlimit für die automatische Vorschau ausgehender Anrufe muss zwischen 1 und 3600 Sekunden liegen.",
    "ccm.agent.sysparamconfig.checkWebhookValidityDays": "Die Webhook-Zertifizierung muss zwischen 7 und 365 Tagen gültig sein",
    "ccm.agent.sysparamconfig.verifyTemplateIdLength": "Die interne Nachrichtenvorlagen-ID muss eine ganze Zahl sein und darf keine negative Zahl sein.",
    "ccm.agent.sysparamconfig.refreshSuccess": "Reset mit einem Klick erfolgreich.",
    "ccm.agent.sysparamconfig.resetAllParamsFail": "Die Parameterkonfigurationsdatei fehlt. Bitte überprüfen Sie dies.",
    "ccm.agent.sysparamconfig.refreshAllSysParamSuccess": "Aktualisierung erfolgreich.",
    "ccm.agent.sysparamconfig.refreshAllSysParamFail": "Systemfehler. Aktualisierung fehlgeschlagen.",
    "ccm.vdn.label.index": "Monate",
    "ccm.agent.message.sysparamservicerr": "Interner Servicefehler. Bitte überprüfen Sie das Protokoll.",
    "ccm.agent.message.download.err": "Download fehlgeschlagen. Kontaktsystem-O&M-Ingenieure.",
    "ccm.vdn.label.defaultvoice": "Standard",

    "ccm.recognition.label.sucess": "Erfolg",
    "ccm.recognition.label.fail": "Fehler",
    "ccm.recognition.label.ordersucess": "Abonnement erfolgreich.",
    "ccm.recognition.label.orderfail": "Abonnement fehlgeschlagen.",
    "ccm.recognition.label.orderfailwhilte": "Abonnement fehlgeschlagen. Die Vertrauensliste konnte nicht synchronisiert werden.",
    "ccm.recognition.label.cancelordersucess": "Das Abonnement wurde erfolgreich gekündigt.",
    "ccm.recognition.label.updatesucess": "Mandantenfehler aktualisieren erfolgreich.",
    "ccm.recognition.label.updatefail": "Fehler beim Aktualisieren des Mandanten.",
    "ccm.recognition.label.cancelorderfail": "Kündigen des Abonnements fehlgeschlagen.",
    "ccm.recognition.label.orderfailpd": "Abonnement fehlgeschlagen. Versuchen Sie es später erneut.",
    "ccm.recognition.label.cancelorderfailpd": "Das Abonnement konnte nicht gekündigt werden. Versuchen Sie es später erneut.",
    "ccm.recognition.label.characteristics": "Funktion",
    "ccm.recognition.label.intellrecogn": "Intelligente Erkennung",
    "ccm.recognition.label.intellagent": "Assistent des intelligenten Agenten",
    "ccm.recognition.label.processaccesscode": "Geben Sie einen Flow-Zugangscode ein.",
    "ccm.recognition.label.save": "Speichern",
    "ccm.recognition.label.openIvrAgentSuccess": "Der intelligente Agentenassistent wurde erfolgreich aktiviert.",
    "ccm.recognition.label.closeIvrAgentSuccess": "Der intelligente Agentenassistent wurde erfolgreich deaktiviert.",
    "ccm.recognition.label.mobileagent": "Mobilagent/bidirektionaler Anruf mit einem Klick",
    "ccm.recognition.label.modifymobileagentsuccess": "Mobile-Agent-Status erfolgreich geändert!",
    "ccm.recognition.label.openmobileagentsuccess": "Mobile Agent erfolgreich aktiviert.",
    "ccm.recognition.label.closemobileagentsuccess": "Mobile Agent erfolgreich deaktiviert.",
    "ccm.recognition.label.modifymobileagentfailed": "Der Status des Mobile Agents konnte nicht geändert werden.",
    "ccm.recognition.label.modifymobileagentclose": "Der Mobile Agent und die bidirektionalen Ein-Klick-Anruffunktionen sind für den Mandantenbereich nicht aktiviert!",
    "ccm.recognition.label.modifymobileagentflowidnull": "Die Flow-ID ist leer!",
    "ccm.recognition.label.openBreakDown": "Stimme des Zusammenbruchs",
    "ccm.recognition.label.breakDownVoice": "Stimme des Zusammenbruchs",
    "ccm.recognition.label.voiceNotification": "Stimmbenachrichtigungsflag",
    "ccm.recognition.label.openVoiceNotificationSuccess": "Die Sprachbenachrichtigungsfunktion wurde erfolgreich aktiviert.",
    "ccm.recognition.label.closeVoiceNotificationSuccess": "Die Sprachbenachrichtigungsfunktion wurde erfolgreich deaktiviert.",
    "ccm.recognition.label.modifyVoiceNotificationClose": "Die Sprachbenachrichtigungsfunktion ist für den Mandantenbereich nicht aktiviert.",

    "ccm.callreason.label.input": "Bitte geben Sie ein",
    "ccm.callreason.label.callreason": "Anrufgrund",
    "ccm.callreason.title.callreason": "Grund des assoziierten Anrufs",
    "ccm.callreason.label.parentcallreason": "Grund des Elternanrufs",
    "ccm.callReason.title.createCallReason": "Anrufgrund erstellen",
    "ccm.callReason.title.editCallReason": "Anrufgrund bearbeiten",
    "ccm.callreason.label.selectedcallreason": "Ausgewählter Anrufgrund",
    "ccm.callreason.label.mark": "Anmerkungen",
    "ccm.callreason.label.enable": "Aktivieren",
    "ccm.callreason.label.disable": "Deaktivieren",
    "ccm.callreason.label.setcallreason": "Konfiguration des Anrufgrunds",
    "ccm.callreason.label.name": "Name",
    "ccm.callreason.label.status": "Status",
    "ccm.agent.label.deletecallreason": "Der aktuelle Grund enthält einen untergeordneten Grund, der nicht gelöscht werden kann",
    "ccm.agent.label.notselectcallreason": "Bitte wählen Sie den Anrufgrund aus",
    "ccm.agent.contact.talkcontent": "Gesprächsinhalte",
    "ccm.callreason.input.specialStrValidateFailed": "Der Name des Anrufgrunds darf keine Sonderzeichen enthalten.",
    "ccm.callreason.label.allreason": "Alle Gründe",
    "ccm.callreason.label.selectedreason": "Ausgewählte Gründe",

    "ccm.calledconfig.label.numbers": "Zahlen",
    "ccm.calledconfig.label.querysoftnum": "Softphone-Nummern anzeigen",
    "ccm.calledconfig.label.add": "Hinzufügen",
    "ccm.calledconfig.label.selectcalled": "Angerufen auswählen",
    "ccm.calledconfig.table.devtype": "Zweckgerätetyp",
    "ccm.calledconfig.table.skillormumbers": "Zweckgerät",
    "ccm.calledconfig.table.selectnumbers": "Zahlen auswählen",
    "ccm.calledconfig.table.addnumbers": "Nummern hinzufügen",
    "ccm.calledconfig.title.numbersadded": "Zahlen hinzugefügt",
    "ccm.calledconfig.message.deletemobileagent": "Mobile Agent löschen",
    "ccm.calledconfig.message.willdeletemobileagent": "Möchten Sie den ausgewählten mobilen Agenten wirklich löschen?",
    "ccm.calledconfig.message.deletemobileagentsuccess": "Die Mobile Agent-Konfiguration wurde erfolgreich gelöscht",
    "ccm.calledconfig.message.deletemobileagentfail": "Die Zuordnung der Mobile Agent-Konfiguration konnte nicht gelöscht werden“",
    "ccm.calledconfig.message.savemobileagentsuccess": "Die Mobile Agent-Konfiguration wurde erfolgreich gespeichert",
    "ccm.calledconfig.message.savemobileagentfail": "Die Zuordnung der Mobile Agent-Konfiguration konnte nicht gespeichert werden“",
    "ccm.calledconfig.message.numbersinvalid": "Mehr als 15 oder weniger als 1 Zahl",
    "ccm.calledconfig.message.nocalled": "Bitte wählen Sie Angerufen",
    "ccm.calledconfig.message.duplicatecalled": "Doppelter Anruf",
    "ccm.calledconfig.message.nonumbers": "Bitte wählen Sie Nummern",
    "ccm.calledconfig.message.noskill": "Bitte wählen Sie eine Skill-Warteschlange.",
    "ccm.satisfactionlevel.title.templateupdate": "Vorlage aktualisieren",
    "ccm.calledconfig.message.willdeleteassociation": "Möchten Sie die Zuordnungsinformationen zum Anrufgrund wirklich löschen?",
    "ccm.calledconfig.message.callreasontips": "Bitte beachten Sie: Das zugehörige TT auf der aktuellen Ebene ist nicht verfügbar. Wählen Sie einen Teilsatz-Anrufgrund aus, um den zugehörigen TT einzustellen.",
    "casemanagement.casedraft.label.casetype": "Falltyp",
    "casemanagement.casedraft.label.selectcasetype": "Falltyp auswählen",

    "ccm.callout.lable.index": "Index",
    "ccm.callout.lable.type": "Parametertyp",
    "ccm.callout.lable.number": "Parameterwert",
    "ccm.callout.lable.default": "Standardwert",
    "ccm.callout.lable.reset": "Zurücksetzen",
    "ccm.callout.lable.caller": "Anrufer anrufen",
    "ccm.callout.placeholder.caller": "Bitte geben Sie die Rufnummer des ausgehenden Anrufs ein",
    "ccm.callout.title.fail": "Fehler",
    "ccm.callout.title.success": "Erfolg",
    "ccm.callout.message.confreset": "Bestätigung zurücksetzen",
    "ccm.callout.message.isconfreset": "Möchten Sie den Callout-Anrufer wirklich zurücksetzen?",
    "ccm.callout.message.choosecaller": "Bitte wählen Sie einen Callout-Anrufer",
    "ccm.callout.message.nocallers": "Es wurden keine Callout-Anrufer ausgewählt",
    "ccm.callout.message.editsuccess": "Der Callout-Anrufer konnte erfolgreich bearbeitet werden",
    "ccm.callout.message.editfail": "Die Bearbeitung des Callout-Aufrufers ist fehlgeschlagen",
    "ccm.callout.message.resetsuccess": "Parameter erfolgreich zurückgesetzt.",
    "ccm.callout.message.resetfail": "Parameter konnten nicht zurückgesetzt werden.",
    "ccm.agentparam.message.editsuccess": "Erfolgreich bearbeitet.",
    "ccm.agentparam.message.editfail": "Bearbeitung fehlgeschlagen.",
    "ccm.agent.mobileagent.notice": "Hinweis",
    "ccm.agent.mobileagent.confirm": "Bestätigen",
    "ccm.agent.mobileagent.success": "Erfolgreich",
    "ccm.agent.mobileagent.fail": "Fehlgeschlagen",
    "ccm.agent.mobileagent.label.nodata": "Keine übereinstimmenden Daten gefunden!",
    "ccm.agent.mobileagent.label.choosehint": "Wählen Sie Agenten aus, bevor Sie sie löschen!",
    "ccm.agent.mobileagent.label.deletehint": "Möchten Sie wirklich löschen?",
    "ccm.agent.mobileagent.label.deletecomplete": "Agenten löschen abgeschlossen!",
    "ccm.agent.label.usedMode": "Typ",
    "ccm.agent.label.usedMode.phone": "Telefonnummer",
    "ccm.agent.label.usedMode.sipAccount": "Sip-Konto",
    "ccm.agent.message.searchAgentServiceNo": "Geben Sie die Servicenummer des Agenten ein",
    "ccm.agent.message.nocomments": "Keine Kommentare",
    "ccm.agent.message.deletefailed": "Löschen des Agenten fehlgeschlagen",
    "ccm.agent.message.createfailed": "Agentenerstellung fehlgeschlagen",

    "ccm.urlconfig.label.talkurl": "Verbindungsrückruf-URL",
    "ccm.urlconfig.label.talkmethod": "Verbindungsrückrufmethode",
    "ccm.urlconfig.label.relurl": "URL für aufgelegten Rückruf",
    "ccm.urlconfig.label.relmethod": "HTTP-Anforderungsmethode",
    "ccm.urlconfig.message.addsuccess": "URLs konnten erfolgreich hinzugefügt werden",
    "ccm.urlconfig.message.addfail": "URLs konnten nicht hinzugefügt werden",
    "ccm.urlconfig.message.modifysuccess": "Die URLs konnten erfolgreich geändert werden",
    "ccm.urlconfig.message.modifyfail": "Die URLs konnten nicht geändert werden",
    "ccm.urlconfig.message.deletesuccess": "Die URLs konnten erfolgreich zurückgesetzt werden",
    "ccm.urlconfig.message.deletefail": "URLs konnten nicht zurückgesetzt werden",
    "ccm.urlconfig.pageurls.urlvalidate": "Die URLs müssen mit http:// oder https:// beginnen",
    "ccm.agent.message.validatormsg": "Passwortbezogene Eingaben sollten leer oder gar nicht leer sein",
    "ccm.agent.message.validatordigit": "Das Setup-Kennwort für einen einzelnen Telefonagenten darf nur numerisch sein",
    "ccm.agent.agentcontact.error": "Abfrage fehlgeschlagen",
    "ccm.agent.agentcontact.nosearch": "Kann nicht in nicht geschäftlicher Hinsicht abgefragt werden.",
    "ccm.urlconfig.message.httpurl.safetip": "(Nicht sicher)",
    "ccm.callback.url.callback": "Konfiguration des bidirektionalen Rückrufs",
    "ccm.callback.url.callnotify": "Rückrufkonfiguration für Sprachbenachrichtigungen",
    "ccm.urlconfig.label.oauth.label": "OAuth 2.0-Autorisierungsinformationen",
    "ccm.agent.message.samepassword": "Das neue Kennwort darf nicht mit dem aktuellen Kennwort identisch sein.",
    "ccm.urlconfig.label.key": "Geheimschlüssel",
    "ccm.urlconfig.label.appsecret": "Gemeinsamer Schlüssel",
    "ccm.urlconfig.modify.authtype1": "Gemeinsamer Schlüssel",
    "ccm.urlconfig.modify.authtype2": "OAuth 2.0",
    "ccm.urlconfig.modify.authtype3": "Keine",
    "ccm.urlconfig.label.oauth.ak": "AK",
    "ccm.urlconfig.label.oauth.sk": "SK",
    "ccm.urlconfig.label.oauth.oauth2loginurl": "URL für die OAuth 2.0-Authentifizierungsanmeldung",

    "ccm.callback.url.auth.tip": "Callback-URL-Authentifizierungsmodus",
    "ccm.urlconfig.modify.callnotify.ak.tooshort": "Der Schlüssel muss mindestens 16 Zeichen enthalten.",
    "ccm.urlconfig.modify.oauth.ak.tooshort": "Die AK muss mindestens 16 Zeichen enthalten.",
    "ccm.urlconfig.modify.oauth.sk.tooshort": "Die SK muss mindestens 16 Zeichen enthalten.",
    "ccm.urlconfig.modify.authbothnull": "Die Autorisierungsinformationen dürfen nicht leer sein.",
    "ccm.urlconfig.label.oauth.oauth2loginurl.illegal": "Ungültige Anmelde-URL.",

    "ccm.urlconfig.label.relurl.voicemsg.alerttip": "Wenn beim Aufruf der Sprachbenachrichtigungserstellungs-API keine Rückruf-URL übertragen wird, wird diese URL nach Abschluss der Sprachbenachrichtigung zurückgerufen. Andernfalls wird die übertragene URL zurückgerufen.",
    "ccm.urlconfig.label.oauth.voicemsg.alerttip": "Wenn eine Rückruf-URL übertragen wird, wenn die Sprachbenachrichtigungserstellungs-API aufgerufen und die folgenden Authentifizierungsinformationen konfiguriert werden, werden diese Informationen beim Rückruf der URL nach Abschluss der Sprachbenachrichtigung verwendet. Andernfalls wird während des Rückrufs keine Authentifizierung durchgeführt.",
    "ccm.urlconfig.label.relurl.callback.alerttip": "Wenn beim Aufruf der bidirektionalen Anruferstellungs-API keine Rückruf-URL übertragen wird, wird diese URL bei Freigabe des bidirektionalen Anrufs zurückgerufen. Andernfalls wird die übertragene URL zurückgerufen.",
    "ccm.urlconfig.label.oauth.callback.alerttip": "Wenn eine Rückruf-URL übertragen wird, wenn die bidirektionale Anruferstellungs-API aufgerufen und die folgenden Authentifizierungsinformationen konfiguriert werden, werden diese Informationen beim Rückruf der URL nach Freigabe des bidirektionalen Anrufs verwendet. Andernfalls wird während des Rückrufs keine Authentifizierung durchgeführt.",

    "ccm.urlconfig.label.noramalOrITA": "Wählen Sie den bidirektionalen CEC-Anruf oder den bidirektionalen ITA-Anruftyp aus.",
    "ccm.urlconfig.label.radio.radioType1": "CEC bidirektionaler Anruf",
    "ccm.urlconfig.label.radio.radioType2": "ITA bidirektionaler Anruf",
    "ccm.urlconfig.message.warning": "Stellen Sie sicher, dass die CDR-Push-URL auf dem ITA konfiguriert wurde. Andernfalls kann der ITA keine CDRs an die CEC weitergeben. Ein Beispiel für eine Push-URL lautet wie folgt:",
    "ccm.urlconfig.message.tip.warning": "Stellen Sie sicher, dass Sie ITA-bezogene Dienstleistungen erworben haben. Andernfalls sind Anrufe betroffen.",
    "ccm.urlconfig.message.oauth.tip.warning": "Keine Callback-URL-Authentifizierung kann ein Sicherheitsrisiko darstellen. Möchten Sie diesen Authentifizierungsmodus wirklich verwenden?",
    "ccm.urlconfig.label.noramalOrITA.input": "ITA-Parameterkonfiguration",
    "ccm.urlconfig.label.input.appId": "AccessKey",
    "ccm.urlconfig.label.input.appSecret": "SecretKey",
    "ccm.urlconfig.message.missing.appIdOrAppSecret": "Der Zugriffsschlüssel oder der geheime Schlüssel sind in der Konfiguration des bidirektionalen ITA-Anrufs nicht festgelegt.",
    "ccm.urlconfig.message.overlimit.appId": "Der für bidirektionale ITA-Anrufe konfigurierte Zugriffsschlüssel darf nicht länger als 128 Zeichen sein.",
    "ccm.urlconfig.message.overlimit.appSecret": "Der für bidirektionale ITA-Anrufe konfigurierte geheime Schlüssel darf nicht länger als 512 Zeichen sein.",
    "ccm.urlconfig.label.numberAllocationMode1": "Automatisch",
    "ccm.urlconfig.label.numberAllocationMode2": "Spezifiziert",
    "ccm.urlconfig.label.input.appKey": "AppKey",
    "ccm.urlconfig.label.input.domainName": "ITA-Domänenname",
    "ccm.urlconfig.label.xNumber": "X-Nummer",
    "ccm.urlconfig.message.missing.appKeyOrDomainName": "Der App-Schlüssel und der Domänenname in der Konfiguration des bidirektionalen ITA-Anrufs müssen festgelegt werden.",
    "ccm.urlconfig.message.overlimit.appKey": "Der App-Schlüssel in der ITA-Konfiguration für bidirektionale Anrufe darf nicht länger als 128 Zeichen sein.",
    "ccm.urlconfig.message.overlimit.domainName": "Der Domänenname in der ITA-Konfiguration für bidirektionale Anrufe darf nicht länger als 128 Zeichen sein.",
    "ccm.urlconfig.message.overlimit.xNumber": "Die für bidirektionale ITA-Anrufe konfigurierte X-Nummer darf nicht länger als 20 Zeichen sein.",
    "ccm.urlconfig.message.notNumber.xNumber": "Die für bidirektionale ITA-Anrufe konfigurierte X-Nummer ist keine Nummer.",

    "ccm.satisfactionconfig.web.switch.remark": "Nach dem Aktivieren dieses Schalters wird die Zufriedenheitsumfrage an den Kunden gesendet, nachdem der Service abgeschlossen ist.",
    "ccm.satisfactionconfig.param.faile": "Falscher Parameter.",
    "ccm.satisfactionconfig.select.ivr.process": "Bitte wählen Sie einen IVR-Flow für eine Zufriedenheitsumfrage aus.",
    "ccm.satisfactionconfig.web.satisfaction.survey": "Umfrage zur Zufriedenheit mit dem Web-Channel",
    "ccm.satisfactionconfig.sms.satisfaction.survey": "SMS-Zufriedenheitsumfrage",
    "ccm.satisfactionconfig.ivr.voice.satisfaction.survey": "IVR Voice-Zufriedenheitsumfrage",
    "ccm.satisfactionconfig.audio.and.video.remark": "Wenn sowohl der IVR-Sprachumfrageschalter als auch der SMS-Zufriedenheitsumfrageschalter eingeschaltet sind und der Agent zuerst auflegt, wird keine SMS-Zufriedenheitsumfrage durchgeführt, wenn eine IVR-Sprachumfrage zur Zufriedenheit durchgeführt wurde.",
    "ccm.satisfactionconfig.audio.and.video": "Umfrage zur Zufriedenheit mit dem Sprach- oder Videokanal",
    "ccm.satisfactionconfig.satisfaction.sending.channel": "SMS-Sendekanal",
    "ccm.satisfactionconfig.sms.switch.remark": "Nach dem Einschalten dieses Schalters wird nach Ende des Anrufs eine SMS-Nachricht zur Zufriedenheitsumfrage an den Kunden gesendet.",
    "ccm.satisfactionconfig.satisfaction.process": "Zufriedenheitsumfragefluss",
    "ccm.satisfactionconfig.satisfaction.switch": "Umschalten",
    "ccm.satisfactionconfig.message.audio": "Zufriedenheitsumfrage",
    "ccm.satisfactionconfig.message.media": "Multimediakanäle",
    "ccm.satisfactionconfig.message.satisreport": "Zufriedenheitsumfragebericht",
    "ccm.satisfactionconfig.message.satis": "Zufriedenheitsumfrage",
    "ccm.satisfactionconfig.message.staistip1": "Nach dem Aktivieren dieses Schalters wird der Kunde zur Zufriedenheitsumfrage weitergeleitet, nachdem der Mitarbeiter aufgelegt hat.",
    "ccm.satisfactionconfig.message.surveymethod": "Umfragemethode",
    "ccm.satisfactionconfig.message.surveyconfig": "Umfragekonfiguration",
    "ccm.satisfactionconfig.message.flowname": "Prozessname",
    "ccm.satisfactionconfig.message.save": "Speichern",
    "ccm.satisfactionconfig.message.success": "Erfolg",
    "ccm.satisfactionconfig.message.failed": "Fehlgeschlagen",
    "ccm.satisfactionconfig.message.createsuccess": "Die Konfiguration der Zufriedenheitsumfrage wurde erfolgreich hinzugefügt.",
    "ccm.satisfactionconfig.message.createfailed": "Die Konfiguration der neuen Zufriedenheitsumfrage ist fehlgeschlagen.",
    "ccm.satisfactionconfig.message.updatesuccess": "Die Konfiguration der Zufriedenheitsumfrage wurde erfolgreich aktualisiert.",
    "ccm.satisfactionconfig.message.updatefailed": "Aktualisierung der Konfiguration der Zufriedenheitsumfrage fehlgeschlagen",
    "ccm.satisfactionconfig.message.enterflowname": "Bitte geben Sie den Prozessnamen ein",
    "ccm.satisfactionconfig.message.number": "Zahl",
    "ccm.satisfactionconfig.message.flowaccesscode": "Zugriffscode verarbeiten",
    "ccm.satisfactionconfig.message.version": "Versionsnummer",
    "ccm.satisfactionconfig.message.confirm": "Bestätigen",
    "ccm.satisfactionconfig.message.cancel": "Abbrechen",
    "ccm.satisfactionconfig.message.sms": "SMS",
    "ccm.satisfactionconfig.message.chooseivrflow": "IVR-Fluss auswählen",
    "ccm.satisfactionconfig.message.failtogetivrflow": "Bitte schalten Sie die Zufriedenheitsumfrage ein",

    "ccm.agent.leavemessage.serviceContactNumber": "Kontaktnummer",
    "ccm.agent.leavemessage.allStatus": "Alle Nachrichten ",
    "ccm.agent.leavemessage.status.toBeAssigned": "Zuzuweisen ",
    "ccm.agent.leavemessage.status.toBeProcessed": "Zu bearbeiten ",
    "ccm.agent.leavemessage.status.unprocessed": "Unverarbeitete ",
    "ccm.agent.leavemessage.status.inProcess": "Verarbeitung von ",
    "ccm.agent.leavemessage.status.solved": "Verarbeitete ",
    "ccm.agent.leavemessage.status.allocationFailed": "Zuweisung fehlgeschlagen",
    "ccm.agent.leavemessage.contactNumber": "Kontaktnummer ",
    "ccm.agent.leavemessage.workNo": "Agent",
    "ccm.agent.leavemessage.channel": "Kanal-",
    "ccm.agent.leavemessage.status": "Status",
    "ccm.agent.leavemessage.auditAccount": "Prüfkonto",
    "ccm.agent.leavemessage.auditcomments": "Prüfungskommentare",
    "ccm.agent.leavemessage.auditdate": "Aktualisierungszeit ",
    "ccm.agent.leavemessage.assign": "Zuweisen",
    "ccm.agent.leavemessage.title.assign": "Nachrichten zuweisen",
    "ccm.agent.leavemessage.process": "Prozess",
    "ccm.agent.leavemessage.messageprocess": "Nachricht verarbeiten",
    "ccm.agent.leavemessage.inputauditcomments": "Geben Sie Vorschläge ein.",
    "ccm.agent.leavemessage.processsuccess": "Die Nachricht wurde erfolgreich verarbeitet.",
    "ccm.agent.leavemessage.processfail": "Die Nachricht konnte nicht verarbeitet werden.",
    "ccm.agent.leavemessage.lengthcodewarn": "Eine Nachricht darf maximal 300 Zeichen enthalten.",
    "ccm.agent.leavemessage.downloadLeaveMessage": "Nachricht herunterladen",
    "ccm.agent.leavemessage.assignSuccess": "Die Nachricht wurde erfolgreich zugewiesen",
    "ccm.agent.leavemessage.assignFailed": "Die Nachricht konnte nicht zugewiesen werden.",
    "ccm.agent.leavemessage.paramisempty": "Leerer Parameter.",
    "ccm.agent.leavemessage.QUERU_LEAVEMESSAGE_FAIL_CODE": "Leere SFTP-Informationen zum Abfragen von Nachrichten.",
    "ccm.agent.leavemessage.statusFailed": "Der Status des Nachrichtendatensatzes konnte nicht geändert werden.",
    "ccm.agent.leavemessage.cannotBeModified": "Die Nachricht kann im aktuellen Status nicht geändert werden.",
    "ccm.agent.leavemessage.notLoginIn": "Der Agent hat sich nicht angemeldet.",
    "ccm.agent.leavemessage.notAgent": "Das Konto ist nicht an einen Agenten gebunden.",
    "ccm.agent.leavemessage.view": "Nachrichten anzeigen",
    "ccm.agent.leavemessage.channelType": "Kanaltyp",
    "ccm.agent.label.positiveEmotionNumber": "Positiv ",
    "ccm.agent.label.negativeEmotionNumber": "Negativ",
    "ccm.agent.label.sensitiveWordNumber": "Unzulässige Wörter",
    "ccm.agent.label.positiveEmotion": "Gut gemacht. Weiter so.",
    "ccm.agent.label.negativeEmotion": "Unangemessene Formulierung. Verwenden Sie ein entsprechendes Skript.",
    "ccm.agent.label.sensitiveWord": "Es wurden verbotene Wörter erkannt. Achten Sie auf Ihre Formulierung.",

    "ccm.agent.videoDownload.disabled": "Die Video-Download-Funktion ist deaktiviert",
    "ccm.agent.videoDownload.noPermission": "Der Agent hat keine Berechtigung zum Herunterladen",
    "ccm.agent.videoDownload.RSETimeout": "Zeitüberschreitung beim Anfordern des RSE-Dienstes",

    "ccm.agent.log.downloadVideoTerminal": "Herunterladen des OpenEye-Videoterminals",

    "ccm.page.label.status": "Seitenstatus",
    "ccm.agent.page.release": "Freigabe",
    "ccm.agent.page.cancel.release": "Nicht freigegeben",
    "ccm.label.pageName": "Seitenname",
    "ccm.page.label.pageInput": "Geben Sie einen Seitennamen ein",
    "ccm.label.page.createPage": "Neue Seite",
    "ccm.label.page.modifyPage": "Seite ändern",
    "ccm.page.select.blockLayout": "Blocklayout",
    "ccm.page.select.tileLayout": "Rasterlayout",
    "ccm.agent.message.pageConfSuccess": "Operation erfolgreich.",
    "ccm.agent.message.pageConfFail": "Vorgang fehlgeschlagen",
    "ccm.agent.message.inSpecialStrFail": "Der Seitenname darf keine Sonderzeichen enthalten.",
    "ccm.agent.message.samePageNameFail": "Doppelter Seitenname.",
    "ccm.agent.message.numInRowFail": "Der Wert muss eine ganze Zahl zwischen 2 und 4 sein.",
    "ccm.agent.message.pageNumFail": "Es sind maximal {0} Seiten zulässig.",
    "ccm.agent.message.pageLayoutFail": "Das Seitenlayout oder die Zeilenanzahl wurde nicht ausgewählt.",
    "ccm.agent.message.quotingDataOperationFail": "Auf die Daten wird im Popup-Fenster verwiesen. Vorgang fehlgeschlagen.",
    "ccm.agent.message.dataIntegrityFail": "Unvollständige Daten für das Seitenlayout oder den Schnittstellensatz konfiguriert. Vorgang fehlgeschlagen.",
    "ccm.label.page.delete.confirm": "Möchten Sie sie wirklich löschen?",
    "ccm.pageconfig.label.layoutType": "Layouttyp",
    "ccm.pageconfig.label.numInRow": "Anzahl der Zeilen",
    "ccm.agent.message.pageLayout": "Seitenlayout",
    "ccm.agent.message.pageInterface": "Seitenschnittstelle",
    "ccm.agent.label.numInRow": "Geben Sie eine Ganzzahl zwischen 2 und 4 ein.",
    "ccm.agent.message.noReleaseFail": "Nur nicht freigegebene Daten können gelöscht werden.",
    "ccm.agent.message.pageNameValidateFail": "Sonderzeichen sind nicht zulässig",
    "ccm.agent.message.itemNameValidateFail": "Die folgenden Sonderzeichen sind nicht zulässig  &<>\";%/[]=+{'@'} ",
    "ccm.agent.page.pageParameter": "Seitenparameter",
    "ccm.agent.label.parameter": "Parameter 1",
    "ccm.agent.input.userNumber": "Benutzernummer",

    "ccm.agent.button.restSoftPhonePwd": "Softphone-Passwort zurücksetzen",
    "ccm.agent.button.restSoftPhonePwdError": "Binde den Agenten.",
    "ccm.agent.button.restSoftPhonePwd.tips": "Das Zurücksetzen des Softphone-Passworts wirkt sich auf das verwendete Softphone aus und erfordert, dass sich der Agent erneut anmeldet. Möchten Sie das Softphone-Passwort wirklich zurücksetzen?",
    "ccm.agent.button.unregister": "",
    "ccm.agent.button.restores": "Wiederherstellungen",
    "ccm.agent.ccagentinfo.unregister.success": "Registrierung erfolgreich aufheben",
    "ccm.agent.ccagentinfo.unregister.fail": "Aufheben der Registrierung fehlgeschlagen",
    "ccm.agent.ccagent.modify.fail": "Intelligente Erkennung und Dual-Track-Aufnahme können nicht gleichzeitig aktiviert werden.",
    "ccm.agent.ccagent.qcmodify.fail": "Wählen Sie einen Audioagententyp aus.",
    "ccm.agent.softPhoneStatus": "Status der Softphone-Nummer",
    "ccm.agent.softPhoneStatusNode1": "Knoten 1",
    "ccm.agent.softPhoneStatusNode2": "Knoten 2",
    "ccm.agent.softPhoneStatus.Registered": "Registriert",
    "ccm.agent.softPhoneStatus.UnRegistered": "Nicht registriert",


    "ccm.custdata.title.create": "Erstellen einer Basistabelle",
    "ccm.custdata.title.modify": "Bearbeiten einer Basistabelle",

    "ccm.custdata.button.detail": "Einzelheiten",
    "ccm.custdata.button.release": "Freigabe",
    "ccm.custdata.button.withdraw": "Zurückziehen",
    "ccm.custdata.button.abandon": "Verwerfen",
    "ccm.custdata.button.return": "Zurück",
    "ccm.custdata.button.moveTop": "Oben",
    "ccm.custdata.button.moveUp": "Auf",
    "ccm.custdata.button.moveDown": "Unten",
    "ccm.custdata.button.moveBottom": "Unten",
    "ccm.custdata.button.exportTemplate": "Vorlage exportieren",
    "ccm.custdata.button.importData": "Daten importieren",
    "ccm.custdata.button.importRecord": "Importergebnisansicht",

    "ccm.custdata.title.edit": "Daten bearbeiten",
    "ccm.custdata.title.coloum.add": "Erstellen einer Feldspalte",
    "ccm.custdata.title.coloum.edit": "Feldspalte bearbeiten",
    "ccm.custdata.title.coloum.detail": "Anzeigen von Felddetails",
    "ccm.custdata.label.tableName": "Tabellenname",
    "ccm.custdata.label.columnNum": "Felder",
    "ccm.custdata.label.status": "Status",
    "ccm.custdata.label.description": "Beschreibung",
    "ccm.custdata.label.columnNo": "Nein",
    "ccm.custdata.label.columnName": "Feldname",
    "ccm.custdata.label.columnType": "Feldtyp",
    "ccm.custdata.label.columnLength": "Feldlänge",
    "ccm.custdata.label.valueRange": "Wertebereich",
    "ccm.custdata.label.isIndex": "Als Index verwendet",
    "ccm.custdata.label.isEncrypt": "Verschlüsselt",
    "ccm.custdata.label.isMandatory": "Obligatorisch",
    "ccm.custdata.label.isMaskDisplay": "Maskiert",
    "ccm.custdata.label.maskStyle": "Maskenstil",
    "ccm.custdata.label.importantTips": "Hinweis: Persönliche oder sensible Daten müssen zur Speicherung verschlüsselt und zur Anzeige maskiert werden.",
    "ccm.custdata.label.relaBaseColumn": "Feld in der zugrunde liegenden Tabelle",
    "ccm.custdata.label.dataImportRecord": "Datenimportdatensatz",
    "ccm.custdata.label.beginTime": "Startzeit",
    "ccm.custdata.label.endTime": "Endzeit",
    "ccm.custdata.label.sucNum": "Anzahl erfolgreicher Datensätze",
    "ccm.custdata.label.failNum": "Anzahl der fehlgeschlagenen Datensätze",
    "ccm.custdata.label.dualCall.sucNum": "Anzahl erfolgreicher Datensätze",
    "ccm.custdata.label.dualCall.failNum": "Anzahl der fehlgeschlagenen Datensätze",
    "ccm.custdata.label.template": "_Vorlage",
    "ccm.custdata.label.failreaon": "Fehlerursache",

    "ccm.custdata.option.all": "Alle",
    "ccm.custdata.option.status.draft": "Entwurf",
    "ccm.custdata.option.status.release": "Freigabe",
    "ccm.custdata.option.status.abandon": "Verwerfen",
    "ccm.custdata.option.columnType.string": "Zeichenfolge",
    "ccm.custdata.option.columnType.number": "Zahl",
    "ccm.custdata.option.columnType.date": "Datum",
    "ccm.custdata.option.columnType.time": "DateTime",
    "ccm.custdata.option.columnType.dictionary": "Wörterbuch",
    "ccm.custdata.option.isIndex.yes": "Ja",
    "ccm.custdata.option.isIndex.no": "Nein",
    "ccm.custdata.option.isEncrypt.yes": "Ja",
    "ccm.custdata.option.isEncrypt.no": "Nein",
    "ccm.custdata.option.isMandatory.yes": "Erforderlich",
    "ccm.custdata.option.isMandatory.no": "Dieses Feld ist optional",
    "ccm.custdata.option.isMaskDisplay.yes": "Ja",
    "ccm.custdata.option.isMaskDisplay.no": "Nein",
    "ccm.custdata.option.maskStyle.retainLast4": "Die letzten vier Ziffern reservieren",
    "ccm.custdata.option.maskStyle.retainFirst4": "Die ersten vier Ziffern reservieren",
    "ccm.custdata.option.maskStyle.maskLast4": "Die letzten vier Ziffern maskieren",
    "ccm.custdata.option.maskStyle.maskFirst4": "Die ersten vier Ziffern maskieren",
    "ccm.custdata.option.maskStyle.maskAll": "Alle Ziffern maskieren",
    "ccm.custdata.option.maskStyle.maskDate": "Datumsmaske",
    "ccm.custdata.option.maskStyle.maskEmail": "E-Mail-Maske",
    "ccm.custdata.option.maskStyle.maskBankAccountNum": "Bankkontomaske",
    "ccm.custdata.option.maskStyle.maskIdCardNum": "Maske der Personalausweisnummer",
    "ccm.custdata.option.importDoing": "Verarbeitung",
    "ccm.custdata.option.importDone": "Abgeschlossen",
    "ccm.custdata.option.importFail": "Fehlgeschlagen",

    "ccm.custdata.msg.msgValidateFailed": "Es darf nicht mit =, es darf nicht exec, eval und die folgenden Zeichen enthalten:",
    "ccm.custdata.msg.dictionaryValidateFailed": "Falsches Format des Datenlexikon-Wertebereichs",
    "ccm.custdata.msg.inputTableName": "Geben Sie einen Tabellennamen ein.",
    "ccm.custdata.msg.confirmDelete": "Möchten Sie sie wirklich löschen?",
    "ccm.custdata.msg.confirmRelease": "Sind Sie sicher, dass Sie es freigeben möchten?",
    "ccm.custdata.msg.confirmWithdraw": "Sind Sie sicher, dass Sie es zurückziehen möchten?",
    "ccm.custdata.msg.confirmAbandon": "Sind Sie sicher, dass Sie es verwerfen möchten?",
    "ccm.custdata.msg.operationSuccess": "Operation erfolgreich.",
    "ccm.custdata.msg.operationFailed": "Vorgang fehlgeschlagen.",
    "ccm.custdata.msg.notAllowOperation": "Dieser Vorgang ist nicht zulässig. Aktualisieren Sie die Seite und versuchen Sie es erneut.",
    "ccm.custdata.msg.duplicateTableName": "Doppelter Tabellenname. Benennen Sie es um.",
    "ccm.custdata.msg.deletedItemNotExist": "Das Löschobjekt existiert nicht. Aktualisieren Sie die Seite und versuchen Sie es erneut.",
    "ccm.custdata.msg.columnNoMatch": "Das Tabellenfeld stimmt nicht mit dem in der Datenbank überein. Aktualisieren Sie die Tabelle, und versuchen Sie es erneut.",
    "ccm.custdata.msg.duplicateColumnName": "Doppelter Feldname. Benennen Sie es um.",
    "ccm.custdata.msg.updatedItemNotExist": "Das zu aktualisierende Feld existiert nicht. Aktualisieren Sie die Seite und versuchen Sie es erneut.",
    "ccm.custdata.msg.typeColumnNumValidateFailed": "Die Anzahl der Felder überschreitet die Obergrenze: Es sind nur ein Indexfeld und maximal 30 nicht verschlüsselte Felder und 20 verschlüsselte Felder zulässig.",
    "ccm.custdata.msg.confirmReturn": "Die Sortierung ist nicht vollständig. Möchten Sie wirklich zu der Seite zurückkehren, auf der Sie die angepasste Tabelle anzeigen können?",
    "ccm.custdata.msg.sortNoChange": "Die Sortierung ändert sich nicht.",
    "ccm.custdata.msg.dictionaryInputFormat": "Format: Schlüssel 1:Wert 1, Schlüssel 2:Wert 2",
    "ccm.custdata.msg.indexColumnValidateFailed": "Ein Feld, das als Index verwendet wird, ist obligatorisch und kann nicht verschlüsselt werden.",
    "ccm.custdata.msg.uploadSelect": "Wählen Sie eine Datei aus.",
    "ccm.custdata.msg.fileNameValidateFail": "Falscher Dateiname. Wählen Sie eine Datei aus, deren Name nur chinesische Zeichen, Kleinbuchstaben, Ziffern oder Unterstriche (_) enthält.",
    "ccm.custdata.msg.fileSuffixValidateFail": "Falsches Dateiformat. Wählen Sie eine CSV-Datei aus.",
    "ccm.custdata.msg.fileSizeValidateFail": "Wählen Sie eine Datei mit weniger als 20 MB.",
    "ccm.custdata.msg.importDataSuccess": "Daten werden exportiert ...Details im Importergebnis anzeigen.",
    "ccm.custdata.msg.importDataFail": "Daten konnten nicht importiert werden.",
    "ccm.custdata.msg.reachUpperTableNum": "Die Anzahl der benutzerdefinierten Tabellen hat die Obergrenze erreicht.",
    "ccm.custdata.msg.missRequiredColumn": "Eine angepasste Tabelle kann nur freigegeben werden, wenn sie mindestens ein Indexfeld und andere Informationsfelder enthält.",
    "ccm.custdata.msg.tableBeUsed": "Auf die angepasste Tabelle wird von einem Konfigurationsseitenobjekt verwiesen. Stellen Sie sicher, dass auf die Tabelle nicht verwiesen wird, bevor Sie diesen Vorgang ausführen.",
    "ccm.custdata.msg.tableExistInstData": "Die angepasste Tabelle enthält Instanzdaten und kann nicht zur Änderung zurückgezogen werden.",
    "ccm.custdata.msg.selectItem": "Wählen Sie vor dem Sortieren ein Element aus.",
    "ccm.custdata.operlog.downloadTemplate": "Herunterladen der Basistabellenvorlage",
    "ccm.custdata.operlog.uploadDataFile": "Hochladen der grundlegenden Tabellendatendatei",
    "ccm.custdata.operlog.downloadData": "Herunterladen grundlegender Tabellendaten",
    "ccm.custdata.operlog.authFailed": "Authentifizierung fehlgeschlagen",
    "ccm.custdata.operlog.addExportTask": "Neue Exportaufgabe",
    "ccm.custdata.operlog.viewExportTask": "Aufgabenansicht exportieren",
    "ccm.custdata.operlog.confirm": "Bestätigen",
    "ccm.custdata.operlog.confirmMessage": "Möchten Sie wirklich eine neue Exportaufgabe erstellen?",
    "ccm.custdata.operlog.refresh": "Aktualisieren",
    "ccm.custdata.operlog.download": "Herunterladen",
    "ccm.custdata.operlog.exportBeginTime": "Startzeit des Exports",
    "ccm.custdata.operlog.exportEndTime": "Endzeit des Exports",
    "ccm.custdata.operlog.conditions": "Bedingungen",
    "ccm.custdata.operlog.exportTaskStatus": "Status",
    "ccm.custdata.operlog.displayMask": "Anzeigemaske",
    "ccm.custdata.operlog.operation": "Betrieb",
    "ccm.custdata.operlog.addExportTaskSuccess": "Neue Exportaufgabe erfolgreich",
    "ccm.custdata.operlog.addExportTaskError": "Neue Exportaufgabe fehlgeschlagen. Bitte überprüfen Sie das Protokoll",
    "ccm.custdata.operlog.exportiong": "Wird exportiert",
    "ccm.custdata.operlog.success": "Erfolg",
    "ccm.custdata.operlog.failed": "Fehlgeschlagen",
    "ccm.custdata.operlog.passValidateFailed": "Muss englische Buchstaben, Zahlen, Sonderzeichen enthalten: {'@'} {'%'}",
    "ccm.custdata.operlog.zippwd": "Komprimiertes Kennwort",
    "ccm.custdata.operlog.inputzippwd": "Geben Sie das Komprimierungskennwort ein",
    "ccm.custdata.operlog.importdata": "Daten importieren",
    "ccm.custdata.operlog.popwindowmessage": "Bitte geben Sie das Kennwort ein, das zum Erstellen der komprimierten Datei verwendet wurde. Das komprimierte Kennwort sollte englische Buchstaben, Zahlen und Sonderzeichen enthalten {'@'} {'%'}",


    "ccm.querycustdata.msg.inputIndexData": "Bitte geben Sie etwas ein",
    "ccm.querycustdata.msg.noData": "Keine Daten",
    "ccm.querycustdata.msg.selectTable": "Wählen Sie einen Tabellennamen aus",

    "ccm.agent.updateSoftPhone.queryNodeFailed": "Die Abfrage der Mandantenknoteninformationen ist fehlgeschlagen.",
    "ccm.agent.updateSoftPhone.modifyLocalPwd": "Das lokale Softphone-Passwort konnte nicht geändert werden.",
    "ccm.agent.updateSoftPhone.newPasswordFailed": "Das neue Kennwort konnte nicht bestätigt werden.",
    "ccm.agent.updateSoftPhone.accountPasswordFailed": "Kontokennwort konnte nicht bestätigt werden.",
    "ccm.agent.updateSoftPhone.updateGatewayFailed": "Das Gateway-Passwort konnte nicht geändert werden.",
    "ccm.agent.updateSoftPhone.updateFailed": "Passwort konnte nicht geändert werden",
    "ccm.agent.updateSoftPhone.updateSuccess": "Passwort erfolgreich geändert.",
    "ccm.agent.updateSoftPhone.unFoundInfo": "Das Callcenter ist nicht synchronisiert.",
    "ccm.agent.updateSoftPhone.unErrorInfo": "Systemausnahme.",
    "ccm.agent.updateSoftPhone.updateOpertionSuccess": "Warnung",
    "ccm.agent.updateSoftPhone.reason": "Der Grund",

    "ccm.agent.pageurls.pagetype": "Seitentyp",
    "ccm.pageurls.select.externalInterface": "Externe Seite",
    "ccm.pageurls.select.internalInterface": "Interne Seite",

    "ccm.pageinterface.title": "Seitenschnittstelle",
    "ccm.pageinterface.objectList": "Objektliste",
    "ccm.pageinterface.addObject": "Objekt hinzufügen",
    "ccm.pageinterface.objectName": "Objektname",
    "ccm.pageinterface.custTableName": "Name der zugrunde liegenden Tabelle",
    "ccm.pageinterface.selectedAttributeNum": "Ausgewählte Objektattribute",
    "ccm.pageinterface.pleaseSelectAttribute": "Wählen Sie ein Objektattribut aus.",
    "ccm.pageinterface.interfaceName": "Schnittstellenname",
    "ccm.pageinterface.inputInterfaceName": "Eingabe",
    "ccm.pageinterface.interfaceType.internal": "Interne Schnittstelle",
    "ccm.pageinterface.interfaceType.external": "Externe Schnittstelle",
    "ccm.pageinterface.selectAttributeTitle": "Objektattribut auswählen",
    "ccm.pageinterface.pleaseSelectInterfaceType": "Wählen Sie einen Schnittstellentyp aus.",
    "ccm.pageinterface.saveSuccess": "Die Seitenschnittstelle wurde erfolgreich gespeichert.",
    "ccm.pageinterface.optionalAttribute": "Optionales Attribut",
    "ccm.pageinterface.selectedAttribute": "Ausgewähltes Attribut",
    "ccm.pageinterface.viewAttribute": "Objektattribut anzeigen",
    "ccm.pageinterface.interfaceType": "Schnittstellentyp",
    "ccm.pageinterface.interfaceType.externalExplain": "Externe Schnittstellen werden auf der Seite API-Management konfiguriert. Wenden Sie sich an den Systemadministrator.",
    "ccm.pageinterface.selectedAll": "Alle auswählen",
    "ccm.pageinterface.cancelAll": "Alle abbrechen",
    "ccm.agent.callacceptno.set": "Festlegen",
    "ccm.recognition.label.interconnection.parameters": "Verbindungsparameter",
    "ccm.recognition.label.api.fabric.id": "API-Fabric-ID",
    "ccm.recognition.label.appSecret.download": "SK herunterladen",
    "ccm.recognition.label.appSecret.reset": "SK zurücksetzen",
    "ccm.recognition.queryAK.error": "Bei der Abfrage der APP-Schlüsselinformationen ist ein Fehler aufgetreten.",
    "ccm.recognition.queryAK.not.exist": "Der aktuelle Mandantenbereich hat keinen App-Schlüssel.",
    "ccm.vcallcenter.config.popup.authentication": "Authentifizieren",
    "ccm.vcallcenter.config.popup.currentaccount.pwd": "Aktuelles Kontokennwort",
    "ccm.vcallcenter.config.popup.authentication.failed": "Authentifizierung fehlgeschlagen.",
    "ccm.recognition.resetSK.success": "SK erfolgreich zurückgesetzt.",
    "ccm.recognition.resetSK.failed": "SK konnte nicht zurückgesetzt werden.",
    "ccm.recognition.label.appSecret.download.appId.empty.error": "Das Feld appId darf nicht leer sein.",
    "ccm.recognition.label.appSecret.download.querySK.error": "Die Abfrage des SK ist fehlgeschlagen.",
    "ccm.recognition.label.appSecret.download.SK.not.exist": "Die SK-Informationen existieren nicht.",
    "ccm.vcallcenter.config.popup.current.user.locked.error": "Der aktuelle Benutzer ist gesperrt und wird 10 Minuten später entsperrt.",
    "ccm.recognition.label.appSecret.download.SK.only.once.error": "Wenn Sie den Schlüssel vergessen, setzen Sie ihn zurück und laden Sie ihn erneut herunter.",
    "ccm.recognition.label.appSecret.download.SK.io.error": "SK konnte nicht heruntergeladen werden. E/A-Ausnahme.",

    "ccm.agent.pageurlparam.urlParamName": "URL-Parameter für Bildschirm-Popup",
    "ccm.agent.pageurlparam.relSysParamType": "Zugeordneter Systemdatenparametertyp",
    "ccm.agent.pageurlparam.calldata": "Anrufdaten",
    "ccm.agent.pageurlparam.channelassociateddata": "Channel-zugehörige Daten",
    "ccm.agent.pageurlparam.relSysParamName": "Zugeordneter Systemdatenparameter",
    "ccm.agent.pageurlparam.paramIsConfiged": "Der Bildschirm-Popup-URL-Parameter oder der zugehörige Systemdatenparameter ist bereits vorhanden.",
    "ccm.agent.pageurlparam.checkSpecialChar": "Geben Sie Ziffern, englische Buchstaben, Unterstriche (_), und Bindestriche (-) ein. Der Punkt (.) muss in der Mitte sein.",
    "ccm.agent.pageurlparam.checkSpecialChar.noPoint": "Geben Sie Ziffern, englische Buchstaben, Unterstriche (_), und Bindestriche (-) ein. Der Punkt (.) muss in der Mitte sein",
    "ccm.agent.pageurlparam.checkSpecialChar.name": "Geben Sie Ziffern, englische Buchstaben, Unterstriche (_), und Bindestriche (-) ein",
    "ccm.agent.pageurlparam.paramLengthLimit": "Die Parameterlänge darf 64 Zeichen nicht überschreiten",
    "ccm.agent.pageurlparam.paramCountLimit": "Es können maximal {limit} Popup-URL-Parameter konfiguriert werden.",
    "ccm.skillcreate.maxnumber.failed": "Die Anzahl der Mandanten-Skill-Queues darf die Obergrenze von ",

    "ccm.agent.restReason.create": "Hinzufügen",
    "ccm.agent.restReason.createReason": "Restgrund hinzufügen",
    "ccm.agent.restReason.operation": "Betrieb",
    "ccm.agent.restReason.delete": "Löschen",
    "ccm.agent.restReason.edit": "Grund für Pause bearbeiten",
    "ccm.agent.restReason.restCauseCode": "Code für den Restgrund",
    "ccm.agent.restReason.confOnlyCauseCode": "Vorgang fehlgeschlagen. Der Ursachencode existiert bereits.",
    "ccm.agent.restReason.restCauseDesc": "Beschreibung",
    "ccm.agent.restReason.restCauseTime": "Ruhedauer",
    "ccm.agent.restReason.restCauseTime.formate": "Die Ruhedauer muss im Format HH:MM:SS angegeben werden.",
    "ccm.agent.restReason.restCauseTime.limit": "Die Ruhezeit muss mehr als 0 Sekunden und weniger als 24 Stunden betragen.",
    "ccm.agent.restReason.restCauseDesc.limit": "Die Beschreibung des Restgrunds darf maximal 100 Zeichen enthalten.",
    "ccm.agent.restReason.restCauseDesc.limit.form": "Die Beschreibung des Restgrunds darf maximal 100 Zeichen enthalten",
    "ccm.agent.restReason.selectRestReasons": "Wählen Sie einen Ruhegrund.",
    "ccm.agent.restReason.selectRestReasons.limit": "Es können maximal 10 Pausengründe in Batches gelöscht werden.",
    "ccm.agent.restReason.pleaseRefresh": "Synchronisieren und erneut versuchen.",
    "ccm.agent.restReason.updateFailed": "Änderung fehlgeschlagen.",
    "ccm.agent.restReason.createFailed": "Hinzufügen fehlgeschlagen.",
    "ccm.agent.restReason.partDeleteFailed": "Teilweises Löschen fehlgeschlagen.",
    "ccm.agent.restReason.isusing.warning": "Die Codes für die Pausengründe sind in Kraft. Dies kann dazu führen, dass der Restgrund im Restdatensatz falsch angezeigt wird.",

    "ccm.certificate.management.search.certcode": "Geben Sie den Zertifikatscode ein",
    "ccm.certificate.management.search.scenename": "Bitte geben Sie die Zertifikatsszene ein",
    "ccm.certificate.management.certcode": "Zertifikatsname",
    "ccm.certificate.management.scenename": "Beschreibung des Zertifikats",
    "ccm.certificate.management.type": "Zertifikatstyp",
    "ccm.certificate.management.expiretime": "Ablaufzeit",
    "ccm.certificate.management.updatetime": "Aktualisierungszeit",
    "ccm.certificate.management.passwd": "Zertifikatskennwort",
    "ccm.certificate.management.certificatefile": "Zertifikatsdatei",
    "ccm.certificate.management.certificatecrlfile": "CRL-Datei",
    "ccm.certificate.management.certificatefile.notupload": "Bitte Zertifikatsdatei hochladen.",
    "ccm.certificate.management.certificatefile.parseerror": "Das Zertifikat konnte nicht geparst werden. Überprüfen Sie, ob das Zertifikatformat oder das Kennwort richtig ist.",
    "ccm.certificate.management.certificatefile.certcodeexist": "Der Zertifikatscode ist bereits vorhanden.",
    "ccm.certificate.management.search.status": "Bitte wählen Sie den Zertifikatstatus",
    "ccm.certificate.management.status": "Zertifikatsstatus",
    "ccm.certificate.management.status.value.inuse": "Wird verwendet",
    "ccm.certificate.management.status.value.discard": "Verwerfen",
    "ccm.certificate.management.status.oper.reuse": "Wiederverwendung",
    "ccm.certificate.management.status.oper.discard": "Verwerfen",
    "ccm.certificate.management.status.confirm.reuse": "Bestätigung der Wiederverwendung",
    "ccm.certificate.management.status.confirm.discard": "Bestätigung der Aufgabe",
    "ccm.certificate.management.status.confirmReuse": "Möchten Sie dieses Zertifikat wirklich weiterhin verwenden?",
    "ccm.certificate.management.status.confirmDiscard": "Bitte überprüfen Sie, ob das Zertifikat in keinem Service-Szenario verwendet wird. Und dieses Zertifikat verwerfen?",
    "ccm.certificate.management.status.update.success": "Zertifikatstatus erfolgreich geändert.",
    "ccm.certificate.management.status.update.failed": "Zertifikatstatus konnte nicht geändert werden.",
    "ccm.certificate.management.certificatefile.status.error": "Das Zertifikat wurde verworfen und kann nicht geändert werden.",
    "ccm.certificate.message.tip.warning": "Der Signaturalgorithmus und die Schlüssellänge des Zertifikats erfüllen nicht die Anforderungen, was riskant ist. Möchten Sie wirklich fortfahren?",
    "ccm.certificate.management.certificatefile.suffixerror": "Es können nur Dateien im Format jks,cer,crt,pem,pfx,p12 hochgeladen werden.",
    "ccm.certificate.management.certificatecrlfile.suffixerror": "Die Datei mit der Zertifikatssperrliste muss im.crl-Format vorliegen.",
    "ccm.certificate.management.certificatefile.suffixerror.pre": "Zertifikate können nur Dateien mit der Erweiterung ",
    "ccm.certificate.management.certificatefile.suffixerror.suffix": "oder eine leere Erweiterung.",
    "ccm.certificate.management.certificate.crlfile.sizeerror": "Die CRL-Datei darf nicht größer als 2048 KB sein.",
    "ccm.certificate.management.certificatefile.certfile.sizeerror": "Die Größe der Zertifikatdatei darf 20 KB nicht überschreiten.",
    "ccm.certificate.management.certificatefile.maxrecord": "Die maximale Anzahl von Zertifikaten beträgt {0}.",
    "ccm.certificate.management.certificatefile.fileempty": "Die Datei ist leer.",
    "ccm.certificate.management.certificatefile.typeerror": "Falscher Dateityp",
    "ccm.certificate.management.certificatefile.injectionerror": "Die Datei birgt Injektionsrisiken.",
    "ccm.certificate.management.certificatefile.uploadcertfile": "Zertifikatsdatei hochladen",
    "ccm.certificate.management.certificatefile.uploadcertcrlfile": "CRL-Datei hochladen",
    "ccm.certificate.management.certificate.crlfile.certhasexpired": "Das Zertifikat ist abgelaufen.",
    "ccm.certificate.management.certificate.crlfile.crlfileinvalid": "Falsches CRL-Dateiformat.",
    "ccm.certificate.management.update.certfile": "Zertifikatsdatei aktualisieren",
    "ccm.certificate.management.add.title": "Hinzufügen eines Zertifikats",
    "ccm.certificate.management.update.title": "Zertifikate werden aktualisiert",
    "ccm.certificate.management.update.crlfile": "CRL-Datei aktualisieren",
    "ccm.certificate.management.update.cancel.certfile": "Möchten Sie die Zertifikataktualisierung wirklich abbrechen?",
    "ccm.certificate.management.update.cancel.crlfile": "Möchten Sie die Aktualisierung der CRL-Datei wirklich abbrechen?",
    "ccm.certificate.management.certificate.tip": "Tipps:",
    "ccm.certificate.management.certificatefile.certcodeerror": "Der Zertifikatscode ist fehlerhaft.",
    "ccm.certificate.management.certificatefile.certsceneerror": "Die Zertifikatsszene ist ein Fehler.",
    "ccm.certificate.management.certificatefile.certpwderror": "Das Zertifikatskennwort ist falsch.",
    "ccm.certificate.management.certificatefile.certtypeerror": "Der Zertifikatstyp ist fehlerhaft.",
    "ccm.certificate.management.warn.selectcerttype": "Wählen Sie den Zertifikatstyp aus.",

    "ccm.basicdata.msg.queryfail": "Die Abfrage der Basisdaten ist fehlgeschlagen.",
    "ccm.basicdata.msg.modifyfail": "Die Basisdaten konnten nicht geändert werden.",
    "ccm.basicdata.msg.modifysuccess": "Die Basisdaten wurden erfolgreich geändert.",
    "ccm.basicdata.msg.deletefail": "Die Basisdaten konnten nicht gelöscht werden.",
    "ccm.basicdata.msg.deletesuccess": "Die Basisdaten wurden erfolgreich gelöscht.",
    "ccm.basicdata.msg.nodata": "Keine Daten",
    "ccm.basicdata.msg.nodatamodify": "Es müssen keine Daten geändert werden.",
    "ccm.basicdata.msg.error": "Fehler",
    "ccm.basicdata.error.syserror": "Systemfehler. Wenden Sie sich an das Wartungspersonal",
    "ccm.basicdata.error.capacitylimit": "Die Datenkapazität hat die Obergrenze {0} erreicht",
    "ccm.basicdata.error.firstlinenotmatch": "Die erste Zeile in der importierten Datei stimmt nicht mit der in der Vorlage überein",
    "ccm.basicdata.error.datatypenotmatch": "Das Datenformat stimmt nicht mit dem Format der Felddefinition überein",
    "ccm.basicdata.error.databaseerror": "Die Indexdaten sind doppelt vorhanden. Infolgedessen können die Daten nicht stapelweise in die Datenbank importiert werden.",
    "ccm.basicdata.error.suffix": ".",

    "ccm.note.config": "Konfiguration des Rückruftons",
    "ccm.note.select": "Wählen Sie einen Rückrufton aus",
    "ccm.note.config.policy": "Agenten-Rückrufton",
    "ccm.note.config.agent.ring.back.tone": "Konfiguration des Agentenrückruftons",
    "ccm.note.config.filepath": "Notizdatei auswählen",
    "ccm.note.config.choosenote": "Bitte wählen Sie eine Notizdatei aus",
    "ccm.worknobeforevoice.config.choosenote": "Wählen Sie einen Wert für Stimme vor Mitarbeiter-ID aus",
    "ccm.worknoaftervoice.config.choosenote": "Wählen Sie einen Wert für die Voice After-Mitarbeiter-ID aus.",
    "ccm.note.config.policy.systemdefault": "Systemstandard",
    "ccm.note.config.policy.custom": "Anpassung",
    "ccm.note.config.error": "Fehler",
    "ccm.note.config.save": "Speichern",
    "ccm.note.config.failed": "Anmerkung konfigurieren fehlgeschlagen",
    "ccm.note.config.success": "Erfolg",
    "ccm.note.config.configsuccess": "Notiz erfolgreich konfigurieren.",
    "ccm.note.config.searchnotename": "Bitte geben Sie einen Namen ein",
    "ccm.note.config.notename": "Name",

    "ccm.recognition.label.anonymous": "Flag für anonymen ausgehenden Anruf",
    "ccm.recognition.label.openanonymoussuccess": "Die Kennzeichnung für den anonymen ausgehenden Anruf wurde erfolgreich aktiviert. Bitte wählen Sie einen Click-to-Call-Zugangscode aus, der für die angerufene Route konfiguriert ist.",
    "ccm.recognition.label.closeanonymoussuccess": "Die Kennzeichnung für den anonymen ausgehenden Anruf wurde erfolgreich deaktiviert",
    "ccm.recognition.label.clicktocallaccesscode": "Zugangscode für Klick-zu-Anruf",
    "ccm.recognition.label.calledroutesuccess": "Die angerufene Route wurde erfolgreich konfiguriert",
    "ccm.anonymous.message.fail.feature": "Die Feature-Authentisierung des anonymen ausgehenden Anrufs ist anormal",
    "ccm.anonymous.message.fail.open": "Flagge konnte nicht geöffnet werden. Überprüfen Sie die CTI-Konfiguration",
    "ccm.anonymous.message.fail.close": "Flagge konnte nicht geschlossen werden. CTI-Konfiguration überprüfen",
    "ccm.anonymous.message.fail.notmatch": "Das Feature-Flag stimmt nicht mit dem aktuellen Status überein",
    "ccm.anonymous.message.fail.paranull": "Der Zugangscode im Parameter ist leer",
    "ccm.anonymous.message.fail.cti": "Fehler bei der Abfrage des anonymen ausgehenden Anrufverlaufs auf CTI",
    "ccm.anonymous.message.fail.save": "Die angerufene Route konnte nicht gespeichert werden. Bitte überprüfen Sie die CTI-Konfiguration",
    "ccm.anonymous.message.fail.delete": "Die angerufene Route konnte nicht gelöscht werden. Bitte überprüfen Sie die CTI-Konfiguration",
    "ccm.satisfactionlevel.message.savesuccess": "Die Zufriedenheitsstufe wurde erfolgreich gespeichert.Wenn ein Zufriedenheitsprozess konfiguriert wurde, geben Sie ihn in Flow Management erneut frei. Andernfalls wird das Zufriedenheitsergebnis falsch angezeigt. Die neue Zufriedenheitsumfrage wird auf den Ergebnissen dieser Konfiguration basieren.",
    "ccm.satisfactionlevel.message.savefail": "Zufriedenheitsstufe konnte nicht gespeichert werden.",
    "ccm.satisfactionlevel.message.resetsuccess": "Zufriedenheitsstufe erfolgreich zurückgesetzt.Wenn ein Zufriedenheitsprozess konfiguriert wurde, geben Sie ihn in Flow Management erneut frei. Andernfalls wird das Zufriedenheitsergebnis falsch angezeigt. Die neue Zufriedenheitsumfrage wird auf den Ergebnissen dieser Konfiguration basieren.",
    "ccm.satisfactionlevel.message.resetfail": "Zufriedenheitsstufe konnte nicht zurückgesetzt werden.",
    "ccm.satisfactionlevel.message.satislevel": "Zufriedenheitsgrad",
    "ccm.satisfactionlevel.message.satistip": "Stellen Sie sicher, dass die Zufriedenheitsniveaus, die in der Zufriedenheitsumfrage für jeden Kanal verwendet werden, gleich sind. z. B. Umfrage zur Sprachzufriedenheit, SMS-Zufriedenheitsumfrage und Web-Zufriedenheitsumfrage.",
    "ccm.satisfactionlevel.message.nopara": "Die Beschreibung darf nicht leer sein.",
    "ccm.satisfactionlevel.message.samedesc": "Die Beschreibung ist doppelt vorhanden. Bitte ändern Sie sie.",
    "ccm.satisfactionlevel.message.leastcalltype": "Wählen Sie mindestens einen Anruftyp aus.",
    "ccm.agent.contact.isExistTalkReason": "Anrufgrund festgelegt oder nicht",
    "ccm.agent.callreason.need": "Bitte wählen Sie mindestens einen Anrufgrund aus.",
    "ccm.agent.callreason.modify.success": "Der Anrufgrund wurde erfolgreich geändert.",
    "ccm.agent.callreason.modify.failed": "Das Ändern des Anrufgrunds ist fehlgeschlagen.",
    "ccm.agent.callreason.delete.confirm": "Möchten Sie den Anrufgrund wirklich löschen?",
    "ccm.common.label.yes": "Ja",
    "ccm.common.label.no": "Nein",
    "ccm.common.label.error": "Fehler",
    "ccm.label.contactexport.selectrange": "Bereich auswählen",
    "ccm.label.contactexport.selectdata": "Daten auswählen",
    "ccm.label.contactexport.selectall": "Alle",
    "ccm.label.contactexport.curpage": "Aktuelle Seite",
    "ccm.label.contactexport.exception": "Bei der Generierung der Exportaufgabe ist ein Fehler aufgetreten. Überprüfen Sie die Protokolle.",
    "ccm.label.contactexport.parainvalid": "Die Ausfuhrbedingungen konnten nicht überprüft werden.",
    "ccm.label.contactexport.parainnull": "Wählen Sie die zu exportierenden Daten aus.",
    "ccm.label.chatRecord.channeltypeinnull": "Wählen Sie die Kanaltypen aus.",
    "ccm.transtask.label.task.chatrecordall.uniqueness": "Sie können nicht alle Kanaltypen hinzufügen, da für einige Kanaltypen Aufgaben vorhanden sind.",
    "ccm.transtask.label.task.chatrecord.uniqueness": "Aufgaben sind für einige ausgewählte Kanaltypen vorhanden.",
    "ccm.label.contactexport.exceedlimit1": "Es können maximal {0} Datensätze exportiert werden",
    "ccm.label.contactexport.exceedlimit2": ". Grenzen Sie den Datenzeitbereich ein, oder fügen Sie Datenfilterkriterien hinzu.",
    "ccm.label.contactexport.nocount": "Kein Kontaktdatensatz erfüllt die Bedingungen.",
    "ccm.label.dualcallexport.nocount": "Es gibt keine doppelten Anrufprotokolle, die die Bedingungen erfüllen.",
    "ccm.operlog.contactexport.download": "Kontaktdatensätze herunterladen",

    "ccm.contactitemconfig.label.contact.recorddata": "Datenelement des Kontaktdatensatzes",
    "ccm.contactitemconfig.label.contact.extended.information.dataset": "Erweiterter Kontaktinformationsdatensatz",
    "ccm.contactitemconfig.label.data.item.name": "Name des Datenelements",
    "ccm.contactitemconfig.label.data.item.identification": "Datenelement-ID",
    "ccm.contactitemconfig.label.data.item.category": "Datentyp",
    "ccm.contactitemconfig.label.data.item.contact.list.visibility": "Sichtbarer Kontaktdatensatz",
    "ccm.contactitemconfig.label.data.item.contact.list.visibility.yes": "Ja",
    "ccm.contactitemconfig.label.data.item.contact.list.visibility.no": "Nein",
    "ccm.contactitemconfig.label.data.item.sequence.number": "Nein.",
    "ccm.contactitemconfig.label.data.extended.set": "Quelldatensatz",
    "ccm.contactitemconfig.label.data.extended.dataset.mapping.field": "Dataset-Zuordnungsfeld",
    "ccm.contactitemconfig.label.data.extended.writeback.extended.field": "Erweitertes Rückschreibefeld",
    "ccm.contactitemconfig.label.data.extended.Type": "Datentyp",
    "ccm.contactitemconfig.label.data.extended.Type.String": "Zeichenfolge",
    "ccm.contactitemconfig.label.data.extended.Type.DataDictionary": "Datenwörterbuch",
    "ccm.contactitemconfig.label.data.extended.dictionary.value": "Datenlexikonwert",
    "ccm.contactitemconfig.label.data.item.basic": "Basisdaten",
    "ccm.contactitemconfig.label.data.item.extended": "Erweiterungsdaten",
    "ccm.contactitemconfig.label.data.item.basicext": "Erweiterte Basisdaten",
    "ccm.contactitemconfig.label.data.extended.dataset.name": "Name des Datensatzes",
    "ccm.contactitemconfig.label.data.extended.dataset.source.interface": "Datensatzquellenschnittstelle",
    "ccm.contactitemconfig.message.init.basicdata.success": "Die Basisdaten wurden erfolgreich initialisiert.",
    "ccm.contactitemconfig.message.confOnlyDataItemName": "Vorgang fehlgeschlagen, da der Datenelementname bereits vorhanden ist.",
    "ccm.contactitemconfig.message.confOnlyDataItemCode": "Vorgang fehlgeschlagen, da die Datenelement-ID bereits vorhanden ist.",
    "ccm.contactitemconfig.message.confOnlyDatasetName": "Vorgang fehlgeschlagen, da der Datensatzname bereits vorhanden ist.",
    "ccm.contactitemconfig.message.DatasetName.inuse": "Der Datensatz kann nicht gelöscht werden, da er bereits im Kontaktdatensatz-Datenelement konfiguriert ist.",
    "ccm.contactitemconfig.message.itemValidate": "Der erste Buchstabe muss ein Buchstabe, ein Unterstrich (_),-Zeichen oder ein Dollarzeichen sein ($). Andere Buchstaben können Unterstriche, Dollarzeichen oder Ziffern sein.",

    "ccm.agent.contact.pictures": "Bilder",

    "ccm.agent.message.videoNotEnough": "Die Anzahl der Videoressourcen ist unzureichend, da sowohl Videoagenten als auch Agenten, die mit Videokompetenzwarteschlangen konfiguriert sind, Videoressourcen belegen. Weisen Sie Videoressourcen ordnungsgemäß zu.",

    "ccm.callreasonconfig.message.deletecallreason.failed": "Löschen fehlgeschlagen.",
    "ccm.callreasonconfig.message.deletecallreason.used": "Löschen fehlgeschlagen, der Anrufgrund wurde verwendet.",
    "ccm.callreasonconfig.message.deletecallreason.success": "Erfolgreich gelöscht.",
    "ccm.common.label.success": "Erfolgreich",

    "ccm.agent.message.agenttypebothempty": "Für den ausgewählten Agenten ist kein Agententyp konfiguriert. Sie müssen den Agententyp konfigurieren.",

    "ccm.agent.button.batchconfig": "Stapelkonfiguration",
    "ccm.agent.button.config": "Konfigurieren",
    "ccm.agent.button.cancelconfig": "Konfiguration abbrechen",
    "ccm.agent.message.cancelconfig": "Konfiguration abbrechen",
    "ccm.agent.message.willcancelconfig": "Wenn Sie die Konfiguration abbrechen, steht sie den Agenten, die die Konfiguration verwenden, nicht zur Verfügung. Möchten Sie die Konfiguration wirklich abbrechen?",
    "ccm.agent.callreason.exceedmaxnum": "Es sind maximal fünf Anrufgründe zulässig.",

    "ccm.agent.clear.message.agentnotexist": "Konfiguration konnte nicht abgebrochen werden, da der Agent nicht vorhanden ist.",
    "ccm.agent.message.agentnotexist": "Der Agent existiert nicht. Aktualisieren Sie die Seite Agentenverwaltung und versuchen Sie es erneut.",
    "ccm.agent.message.agenttypeinvalid": "Der Agententyp ist ungültig.",

    "ccm.basicpage.not.sk.download": "Es ist keine Downloadberechtigung für SK verfügbar.",

    "ccm.label.contactexport.selectdatarange": "Datenumfang",
    "ccm.label.contactexport.selectdatafield": "Feld exportieren",
    "ccm.label.contactexport.currentfield": "Feld in der aktuellen Liste",
    "ccm.label.contactexport.customfield": "Benutzerdefiniertes Feld",

    "ccm.transserver.label.edit": "Ressourcenabbildserver",
    "ccm.transserver.label.servername": "Servername",
    "ccm.transserver.label.servertype": "Typ",
    "ccm.transserver.label.serveraddr": "Adresse",
    "ccm.transserver.label.obs": "OBS-Server",
    "ccm.transserver.label.objobs": "OBS",
    "ccm.transserver.label.obsaddr": "OBS-Adresse",
    "ccm.transserver.label.view": "Ansicht",
    "ccm.transserver.delete.success": "Der Abbildserver wurde erfolgreich gelöscht.",
    "ccm.transserver.delete.fail": "Der Speicherauszugsserver konnte nicht gelöscht werden.",
    "ccm.transserver.discard.confirm": "Möchten Sie die Aufgabe wirklich verwerfen?",
    "ccm.transserverparam.label.info": "Grundlegende Informationen",
    "ccm.transserverparam.label.bucket": "Daten-Bucket",
    "ccm.transserverparam.label.ak": "AK",
    "ccm.transserverparam.label.sk": "SK",
    "ccm.transserverparam.label.isagent": "Proxy aktivieren",
    "ccm.transserverparam.label.agentip": "Proxyserveradresse",
    "ccm.transserverparam.label.agentport": "Hafen",
    "ccm.transserverparam.label.proxyUserName": "Benutzername",
    "ccm.transserverparam.label.password": "Kennwort",
    "ccm.transtask.label.taskedit": "Ressourcenabbildaufgabe",
    "ccm.transtask.label.taskid": "Aufgaben-ID",
    "ccm.transtask.label.taskname": "Aufgabenname",
    "ccm.transtask.label.tasktype": "Typ",
    "ccm.transtask.label.taskupload": "Server hochladen",
    "ccm.transtask.label.status": "Status",
    "ccm.transtask.label.start": "Starten",

    "ccm.transtask.label.contactRecord": "Kontaktdatensatz",
    "ccm.transtask.label.chatRecord": "Multimedia-Chatdaten",
    "ccm.transtask.label.intelligentInspection": "AI-Inspektionsdaten",
    "ccm.transtask.label.cdrData": "CDR-Daten",
    "ccm.transtask.label.voiceFile": "Stimmendatei",
    "ccm.transtask.label.reportData": "Berichtsdaten",
    "ccm.transtask.label.notEnabled": "Deaktiviert",
    "ccm.transtask.label.discarded": "Verworfen",
    "ccm.transtask.label.View": "Ansicht",
    "ccm.transtask.label.enable": "Aktivieren",
    "ccm.transtask.label.pause": "Pause",
    "ccm.transtask.label.discard": "Verwerfen",
    "ccm.transtask.label.restores": "Wiederherstellen",
    "ccm.transtask.label.cantDelete": "Der Server kann nicht gelöscht werden, da er bereits von einer Speicherabbildaufgabe referenziert wird.",
    "ccm.transtask.label.delete": "Die Speicherauszugsaufgabe wurde erfolgreich gelöscht.",
    "ccm.transserver.delete.time.fail": "Löschen Sie die Aufgabe 10 Minuten nach dem Verwerfen.",
    "ccm.transtask.label.cantsave": "Der Server kann nicht geändert werden, da er von einer Speicherabbildaufgabe referenziert wurde.",
    "ccm.transtask.label.savefail": "Es konnte kein Abbildserver hinzugefügt werden, da maximal {0} Abbildserver zulässig sind.",
    "ccm.transtask.label.saveexit": "Es konnte kein Speicherabbildserver hinzugefügt werden, da der Servername bereits vorhanden ist.",
    "ccm.transtask.label.datatype": "Dump-Datentyp",
    "ccm.transtask.label.dataname": "Name des Abladeservers",
    "ccm.transtask.label.datapath": "Dump-Dateipfad",
    "ccm.transtask.label.datapath.tips": "OBS-Pfad",
    "ccm.transtask.label.dumpinterval": "Dump-Dauer",
    "ccm.transtask.label.dumpintervalInitial": "Beginn der Abladedauer",
    "ccm.transtask.label.reportedir": "Abladebericht",
    "ccm.transtask.label.reports": "Abladebericht",
    "ccm.transtask.label.contactrcd": "Kontaktdatensatz-Dump",
    "ccm.transtask.label.chatrecordinf": "Einstellung für Multimedia-Chat-Datenabbild",
    "ccm.transtask.label.chatrecordday": "Chat-Datenabbilddauer (Tag)",
    "ccm.transtask.label.datacondition": "Datenfilterkriterien",
    "ccm.transtask.label.exportdata": "Datenfeld exportieren:",
    "ccm.transtask.label.contactrcddata": "Felder in Kontaktdatensatzliste",
    "ccm.transtask.label.contactchoose": "Angepasste Felder im Kontaktdatensatz",
    "ccm.transtask.label.cancel": "Abbrechen",
    "ccm.transtask.label.save": "OK",
    "ccm.transtask.label.day": "Tag",
    "ccm.transtask.label.week": "Woche",
    "ccm.transtask.label.month": "Monat",
    "ccm.transtask.label.vdnReport": "VDN-Verkehrsbericht",
    "ccm.transtask.label.vdnAccessCodeReport": "VDN-Datenverkehrsbericht nach Zugangscode",
    "ccm.transtask.label.ivrReport": "IVR-Datenbericht",
    "ccm.transtask.label.ivrAccessCodeReport": "IVR-Datenstatistikbericht nach Zugriffscode",
    "ccm.transtask.label.skillReport": "Bericht zum Skill-Warteschlangenverkehr",
    "ccm.transtask.label.skillAccessCodeReport": "Bericht zum Skill-Warteschlangenverkehr nach Zugangscode",
    "ccm.transtask.label.agentReport": "Bericht zur Zusammenfassung der Agentenleistung",
    "ccm.transtask.label.agentOutBoundReport": "Zusammenfassungsbericht über ausgehende Anrufe des Agenten",
    "ccm.transtask.label.agentOperationReport": "Bericht zum Agentenverbindungsbetrieb",
    "ccm.transtask.label.abandonedRingReport": "Zusammenfassender Bericht zu Anrufen, die während des Klingelns aufgegeben wurden",
    "ccm.transtask.label.monday": "Montag",
    "ccm.transtask.label.tuesday": "Dienstag",
    "ccm.transtask.label.wednesday": "Mittwoch",
    "ccm.transtask.label.thurs": "Donnerstag",
    "ccm.transtask.label.friday": "Freitag",
    "ccm.transtask.label.saturday": "Samstag",
    "ccm.transtask.label.sunday": "Sonntag",
    "ccm.transtask.label.taskfail": "Aufgabe konnte nicht erstellt werden, da maximal {0} Aufgaben des Kontaktdatensatztyps zulässig sind.",
    "ccm.transtask.label.tasknameexits": "Die Aufgabe konnte nicht erstellt werden, da der Aufgabenname bereits vorhanden ist.",
    "ccm.transtask.config.pathvalidate": "Der Pfad der Speicherauszugsdatei darf nicht die Zeichenfolge './''.",

    "ccm.transtask.label.skillSummaryReport": "Verkehrszusammenfassungsbericht",
    "ccm.transtask.label.task.uniqueness": "Es kann nur eine Berichtsdatenaufgabe im selben Speicherauszugsintervall für denselben Speicherauszugsdatentyp erstellt werden.",
    "ccm.transtask.label.task.otheruniquene": "Für denselben Dump-Datentyp kann nur eine Sprachdatei, KI-Prüfdaten oder CDR-Datentypaufgabe erstellt werden.",
    "ccm.transtask.label.updateexsit": "Vorgang fehlgeschlagen, da der Servername bereits vorhanden ist.",

    "ccm.transserver.label.css.edit": "Konfiguration des Cloud Search-Servers",
    "ccm.transserver.label.css.serveraddr": "CSS-Adresse",
    "ccm.transserverparam.label.cssClusterPass": "Kennwort",
    "ccm.transserver.label.css": "CSS-Server",
    "ccm.transserver.label.cloudsearchserver.css": "CSS-Konfiguration",
    "ccm.transserver.label.cssaddr": "CSS-Dienstadresse",
    "ccm.transserver.label.cssServerPort": "CSS-Dienstport",
    "ccm.transserverparam.label.login.cssClusterUser": "Benutzername für die Clusteranmeldung",
    "ccm.transtask.label.isOpenCssServer": "Gibt an, ob CSS aktiviert werden soll.",
    "ccm.transtask.label.certTip.proxyCert": "Wählen Sie ein Proxy-Server-Zertifikat aus.",
    "ccm.transtask.label.certTip.cssCert": "Wählen Sie ein CSS-Zertifikat aus.",
    "ccm.transserver.delete.css.confirm": "Möchten Sie die CSS-Konfiguration wirklich löschen?",
    "ccm.transserver.delete.css.success": "Die CSS-Konfiguration wurde erfolgreich gelöscht.",
    "ccm.transserver.delete.css.fail": "Die CSS-Konfigurationsinformationen konnten nicht gelöscht werden.",

    "ccm.agent.ten.timezone": "Zeitzone",
    "ccm.agent.ten.timezoneOffset": "Zeitzonenverschiebung",
    "ccm.agent.ten.daylightSavingTime": "Sommerzeit",
    "ccm.agent.ten.DSTOffset": "DST-Versatz (min)",
    "ccm.agent.ten.DSTStartDate": "Startdatum Sommerzeit",
    "ccm.agent.ten.DSTStartTime": "Startzeit Sommerzeit",
    "ccm.agent.ten.DSTEndDate": "Enddatum der Sommerzeit",
    "ccm.agent.ten.DSTEndTime": "Endzeit der Sommerzeit",
    "ccm.agent.ten.systemdefault": "Standardzeitzone",
    "ccm.agent.ten.dstOn": "Aktiviert",
    "ccm.agent.ten.dstOff": "Deaktiviert",
    "ccmanagement.tenantspace.option.month_1": "Januar",
    "ccmanagement.tenantspace.option.month_2": "Februar",
    "ccmanagement.tenantspace.option.month_3": "Märsche",
    "ccmanagement.tenantspace.option.month_4": "April",
    "ccmanagement.tenantspace.option.month_5": "Mai",
    "ccmanagement.tenantspace.option.month_6": "Juni",
    "ccmanagement.tenantspace.option.month_7": "Juli",
    "ccmanagement.tenantspace.option.month_8": "August",
    "ccmanagement.tenantspace.option.month_9": "September",
    "ccmanagement.tenantspace.option.month_10": "Oktober",
    "ccmanagement.tenantspace.option.month_11": "November",
    "ccmanagement.tenantspace.option.month_12": "Dezember",
    "ccmanagement.tenantspace.option.weeklist_fir": "Erste",
    "ccmanagement.tenantspace.option.weeklist_sec": "Zweite",
    "ccmanagement.tenantspace.option.weeklist_tid": "Dritte",
    "ccmanagement.tenantspace.option.weeklist_fth": "Vierter",
    "ccmanagement.tenantspace.option.weeklist_fthtolast": "Letzte drei",
    "ccmanagement.tenantspace.option.weeklist_tidtolast": "Letzte zwei",
    "ccmanagement.tenantspace.option.weeklist_sectolast": "Vorletzter",
    "ccmanagement.tenantspace.option.weeklist_firtolast": "Letztes",
    "ccmanagement.tenantspace.option.weekday_sun": "Sonntag",
    "ccmanagement.tenantspace.option.weekday_mon": "Montag",
    "ccmanagement.tenantspace.option.weekday_tue": "Dienstag",
    "ccmanagement.tenantspace.option.weekday_wed": "Mittwoch",
    "ccmanagement.tenantspace.option.weekday_thu": "Donnerstag",
    "ccmanagement.tenantspace.option.weekday_fri": "Freitag",
    "ccmanagement.tenantspace.option.weekday_sat": "Samstag",

    "ccm.taskrecord.label.executeBeginTime": "Startzeit",
    "ccm.taskrecord.label.executeEndTime": "Endzeit",
    "ccm.taskrecord.label.executeStatus": "Aufgabenstatus",
    "ccm.taskrecord.label.executeSuccess": "Erfolgreich",
    "ccm.taskrecord.label.executeFail": "Fehlgeschlagen",
    "ccm.taskrecord.label.executing": "Wird ausgeführt",
    "ccm.taskrecord.label.reexecute": "Erneut ausführen",
    "ccm.taskrecord.label.choosetask": "Aufgabe auswählen",
    "ccm.taskrecord.message.reexecutetranstasksuccess": "Wiederholung erfolgreich.",
    "ccm.taskrecord.message.reexecutetranstaskerror": "Erneute Ausführung fehlgeschlagen.",
    "ccm.transserver.delete.confirm": "Möchten Sie den Server wirklich löschen?",
    "ccm.transtask.delete.confirm": "Möchten Sie die Dump-Aufgabe und die historischen Aufgabendatensätze wirklich löschen?",

    "ccm.agent.message.existDualchannelrecAgent": "Intelligente Erkennung und Dual-Track-Aufzeichnung können nicht gleichzeitig aktiviert werden, während die Dual-Track-Aufzeichnung für einige der Agenten aktiviert wurde, die in Batches modifiziert werden.",
    "ccm.agent.message.existAIAgent": "Intelligente Erkennung und Dual-Track-Aufzeichnung können nicht gleichzeitig aktiviert werden, während die intelligente Erkennung für einige der Agenten aktiviert wurde, die in Batches geändert werden können.",

    "ccm.agent.contact.preemptionCallout": "Voreingenommener ausgehender Anruf",
    "ccm.agent.sysparamconfig.emailMaxRecipientsNoCheck": "Die maximale Anzahl der Empfänger, die von Mail-Agenten weitergeleitet werden, muss zwischen 1 und 50 liegen.",

    "ccm.systemparam.success.save": "Parameter erfolgreich gespeichert.",
    "ccm.systemparam.error.save": "Parameter konnten nicht gespeichert werden.",
    "ccm.systemparam.success.reset": "Parameter erfolgreich zurückgesetzt.",
    "ccm.systemparam.error.reset": "Parameter konnten nicht zurückgesetzt werden.",
    "ccm.systemparam.success.refresh": "Parameter erfolgreich aktualisiert.",
    "ccm.systemparam.error.refresh": "Parameter konnten nicht aktualisiert werden.",
    "ccm.systemparam.error.notmatchrule": "Der Parameter erfüllt die Überprüfungsregel nicht.",
    "ccm.systemparam.error.noparamselected": "Bitte wählen Sie zuerst einen Parameter aus.",
    "ccm.sysparam.config.search": "Abfragen",
    "ccm.sysparam.config.reset": "Zurücksetzen",
    "ccm.sysparam.config.resetAbb": "Zurücksetzen",
    "ccm.sysparam.config.refresh": "Aktualisieren",
    "ccm.sysparam.config.itemname": "Parametername",
    "ccm.sysparam.config.value": "Parameterwert",
    "ccm.sysparam.config.itemdesc": "Beschreibung",
    "ccm.sysparam.config.opterate": "Betrieb",
    "ccm.sysparam.config.save": "Speichern",
    "ccm.sysparam.config.mod": "Bearbeiten",
    "ccm.sysparam.config.checkmessage": "Enthält Sonderzeichen.  (" < ", " > ", " / ", etc.)",
    "ccm.sysparam.config.itemname.tips": "Geben Sie einen Parameternamen ein.",
    "ccm.sysparam.config.select.prompt": "Hinweise",
    "ccm.sysparam.config.select.null": "Wählen Sie einen Parameter aus.",
    "ccm.sysparam.config.select.count": "Es können maximal 10 Parameter ausgewählt werden.",
    "ccm.sysparam.config.item.detail": "Parameterinformationen",
    "ccm.sysparam.config.item.catalog": "Parametertyp",
    "ccm.sysparam.config.title": "Tenantparameter",
    "ccm.sysparam.success.save": "Erfolg",
    "ccm.sysparam.error.save": "Fehler",
    "ccm.sysparam.config.reset.multiple.error": "Die folgenden Parameter können nicht zurückgesetzt werden:",
    "ccm.sysparam.config.agent.title": "Agentenparameter",

    "ccm.sysparam.error.validate.inner": "Parameterüberprüfung fehlgeschlagen",
    "ccm.sysparam.error.validate.ruleinvalid": "Die Überprüfungsregel ist ungültig",
    "ccm.sysparam.error.validate.ruleregexinvalid": "Syntaxfehler der Validierungsregel",
    "ccm.sysparam.error.validate.notmatchrule": "Der Eingabeparameter stimmt nicht mit der Überprüfungsregel überein",
    "ccm.sysparam.error.validate.notmatchruleregex": "Der Eingabeparameter stimmt nicht mit dem regulären Ausdruck der Überprüfungsregel überein",
    "ccm.sysparam.error.validate.watermarkinfo.notmatchruleregex": "Der Parameter lässt nur 0 bis 20 englische Zeichen zu, die Groß- und Kleinbuchstaben, Zahlen oder -_/@ enthalten.",
    "ccm.sysparam.error.validate.notmatchrulealpha": "Bitte geben Sie alphanumerische Zeichen oder Unterstriche ein und beginnen Sie nur mit Buchstaben oder Unterstrichen",
    "ccm.sysparam.error.validate.notmatchruledigits": "Bitte geben Sie eine gültige positive Ganzzahl ein",
    "ccm.sysparam.error.validate.notmatchruleemail": "Bitte geben Sie eine gültige E-Mail-Adresse ein",
    "ccm.sysparam.error.validate.notmatchruleip": "Geben Sie eine gültige IPv4- oder IPv6-Adresse ein",
    "ccm.sysparam.error.validate.notmatchrulemaxlength": "Maximale Parameterlänge",
    "ccm.sysparam.error.validate.notmatchrulemax": "Der Eingabeparameter überschreitet das Maximum",
    "ccm.sysparam.error.validate.notmatchruleminlength": "Der Eingabeparameter ist kleiner als die Mindestlänge",
    "ccm.sysparam.error.validate.notmatchrulemin": "Der Eingabeparameter ist niedriger als das Minimum",
    "ccm.sysparam.error.validate.notmatchrulenumber": "Bitte geben Sie eine Zahl ein",
    "ccm.sysparam.error.validate.notmatchruleoption": "Der Eingabeparameter befindet sich nicht im Aufzählungsbereich",
    "ccm.sysparam.error.validate.notmatchrulerangelength": "Der Eingabeparameter überschreitet die maximale Länge oder unterschreitet die minimale Länge",
    "ccm.sysparam.error.validate.notmatchrulerange": "Der Eingabeparameter überschreitet den Maximalwert oder unterschreitet den Minimalwert",
    "ccm.sysparam.error.validate.notmatchrulerequired": "Pflichtfelder",
    "ccm.sysparam.error.validate.notmatchruleurl": "Geben Sie eine gültige URL-Adresse ein",
    "ccm.sysparam.error.validate.ccme.alarmThreshold": "Der Alarmschweregrad stimmt nicht mit dem Alarmschwellenwert überein. Die Alarmschwelle mit niedrigem Schweregrad muss kleiner (gleich) als die Alarmschwelle mit hohem Schweregrad sein.",
    "ccm.sysparam.error.validate.ccme.daysAlarmOverSync": "Der Eingabeparameter muss kürzer als die konfigurierte Zeit (standardmäßig 7 Tage) für die Migration von Offlinebenutzern in die Verlaufstabelle sein“",
    "ccm.calledconfig.message.overmaxnum": "Die maximale Menge von {0} wurde erreicht.",
    "ccm.calledconfig.message.samename": "Derselbe Name existiert bereits.",
    "ccm.calledconfig.message.nullparent": "Der übergeordnete Knoten existiert nicht.",
    "ccm.ivrflow.placeholder.ivrflowdesc": "Geben Sie eine Beschreibung für den IVR-Fluss ein.",

    "ccm.transserver.label.obsnotinwhite": "Vorgang fehlgeschlagen. Wenden Sie sich an den Systemadministrator, um die OBS-Dienstadresse zur OBS-Server-Vertrauensliste des Ressourcenabbilds hinzuzufügen.",
    "ccm.transserver.label.proxynotinwhite": "Vorgang fehlgeschlagen. Wenden Sie sich an den Systemadministrator, um die Proxy-Serveradresse zur Proxy-Vertrauensliste des OBS-Servers hinzuzufügen.",
    "ccm.urlconfig.message.talkurlnotinwhite": "Vorgang fehlgeschlagen. Wenden Sie sich an den Systemadministrator, um die Verbindungsrückruf-URL zur Rückrufvertrauensliste hinzuzufügen.",
    "ccm.urlconfig.message.relurlnotinwhite": "Vorgang fehlgeschlagen. Wenden Sie sich an den Systemadministrator, um die Handup-Rückruf-URL zur Rückrufvertrauensliste hinzuzufügen.",
    "ccm.urlconfig.message.urlnotinwhite": "Vorgang fehlgeschlagen. Wenden Sie sich an den Systemadministrator, um die URL zur Vertrauensliste hinzuzufügen.",

    "ccm.speciallist.title.specialList": "Besondere Liste",
    "ccm.speciallist.label.expirationDate": "Ablaufzeit",
    "ccm.speciallist.label.reason": "Grund für den Beitritt",
    "ccm.speciallist.label.operTime": "Erstellungszeit",
    "ccm.speciallist.label.operator": "Bediener",
    "ccm.speciallist.label.blocklist": "Blockliste",
    "ccm.speciallist.label.redlist": "Redliste",
    "ccm.speciallist.label.line": "Linie",
    "ccm.speciallist.msg.userNo": "Teilnehmernummer",
    "ccm.speciallist.msg.sltype": "Besonderer Listentyp",
    "ccm.speciallist.msg.success": "erfolgreich",
    "ccm.speciallist.msg.transferToHistory": "Transfer in die Geschichte",
    "ccm.speciallist.msg.modify": "Ändern",
    "ccm.speciallist.msg.delete": "Löschen",
    "ccm.speciallist.msg.restores": "Wiederherstellungen",
    "ccm.speciallist.msg.successNum": "{limit} erfolgreich",
    "ccm.speciallist.msg.operationsuccess": "Vorgang erfolgreich",
    "ccm.speciallist.msg.error": "Fehler",
    "ccm.speciallist.msg.fail": "nicht bestanden",
    "ccm.speciallist.msg.errorreason": "Fehlerursache",
    "ccm.speciallist.msg.errorNum": "{limit} fehlgeschlagen",
    "ccm.speciallist.msg.crateerror": "Hinzufügen fehlgeschlagen",
    "ccm.speciallist.msg.modifyerror": "Änderung fehlgeschlagen",
    "ccm.speciallist.msg.reviewerror": "Genehmigung fehlgeschlagen",
    "ccm.speciallist.msg.operationdoing": "Andere Prüfer stimmen zu",
    "ccm.speciallist.msg.deleteyerror": "Die spezielle Liste konnte nicht gelöscht werden",
    "ccm.speciallist.msg.crateerrorreason": "Die Teilnehmernummer existiert bereits",
    "ccm.speciallist.msg.transfererror": "Übertragung in die Geschichte fehlgeschlagen",
    "ccm.speciallist.msg.transferout": "Die Anzahl der historischen Datensätze hat {0} erreicht",
    "ccm.speciallist.msg.willdelete": "Möchten Sie diese {limit} Elemente wirklich löschen?",
    "ccm.common.msg.willdelete": "Möchten Sie die ausgewählten {limit}-Datensätze wirklich löschen?",
    "ccm.speciallist.msg.confirmdelete": "Möchten Sie die ausgewählte spezielle Liste wirklich löschen?",
    "ccm.speciallist.msg.confirmdeletehis": "Möchten Sie den ausgewählten speziellen Listenverlauf wirklich löschen?",
    "ccm.speciallist.msg.selectspeciallist": "Bitte wählen Sie eine spezielle Liste aus",
    "ccm.speciallist.msg.selectspeciallisthis": "Bitte wählen Sie einen speziellen Listenverlauf aus",
    "ccm.speciallist.msg.endtime": "Das Ablaufdatum darf nicht nach dem 19. Januar 2038 liegen.",
    "ccm.speciallist.msg.endtimeerror": "Die Ablaufzeit muss nach der aktuellen Zeit liegen",
    "ccm.speciallist.msg.tip": "Hinweis: Es können jedes Mal maximal 1000 Datensätze importiert werden.",
    "ccm.speciallist.msg.warning": "Warnung",
    "ccm.speciallist.msg.selectonly": "Es kann nur eine spezielle Liste zur Änderung ausgewählt werden",
    "ccm.speciallist.msg.besureexport": "Möchten Sie wirklich alle Daten exportieren?",
    "ccm.speciallist.msg.restoreout": "Ein Mandantenbereich kann maximal 10.000 spezielle Listendatensätze speichern",
    "ccm.speciallist.msg.exists": "Der ausgewählte Benutzer ist bereits in der speziellen Liste enthalten",
    "ccm.speciallist.msg.restoreerror": "Die spezielle Liste konnte nicht wiederhergestellt werden",
    "ccm.speciallist.msg.restoresuccess": "Wiederherstellung erfolgreich",
    "ccm.speciallist.msg.download": "Herunterladen der Vorlage für den Import spezieller Listen",
    "ccm.speciallist.msg.import.template": "Vorlage für den Import spezieller Listen",
    "ccm.speciallist.msg.limit": "Die Anzahl der importierten Inhalte übersteigt 1000",
    "ccm.speciallist.msg.maxlimit": "Die maximale Anzahl von Datensätzen überschreitet {limit}",
    "ccm.speciallist.msg.size": "Die zu importierende Datei ist größer als 10 MB",
    "ccm.speciallist.msg.norecord": "Es kann kein Datensatz exportiert werden",
    "ccm.speciallist.msg.operationerror": "Vorgang fehlgeschlagen",
    "ccm.speciallist.msg.suffix": "Die Dateinamenerweiterung ist falsch",
    "ccm.speciallist.msg.maxCountQuery": "Das aktuelle Datenvolumen ist zu groß. Das System zeigt nur die neuesten {0} Datensätze an.",
    "ccm.speciallist.button.cancel": "Abbrechen",
    "ccm.speciallist.button.finish": "Fertig stellen",
    "ccm.speciallist.button.history": "Transfer in die Geschichte",
    "ccm.speciallist.button.modify": "Ändern",
    "ccm.levelMessage.title.modify": "Ebenenverwaltung ändern",
    "ccm.speciallist.title.modify": "Besondere Liste ändern",
    "ccm.speciallistreminder.button.modify": "Erinnerung an spezielle Liste ändern",
    "ccm.speciallist.button.import": "Importieren",
    "ccm.speciallist.title.import": "Spezialliste importieren",
    "ccm.speciallist.button.add": "Hinzufügen",
    "ccm.levelMessage.title.add": "Ebenenverwaltung erstellen",
    "ccm.speciallist.title.add": "Spezialliste erstellen",
    "ccm.speciallistreminder.button.add": "Erinnerung an spezielle Liste erstellen",
    "ccm.speciallisthis.operlog.downloadData": "Verlauf spezieller Listen exportieren",
    "ccm.speciallist.operlog.downloadData": "Exportieren spezieller Listendaten",
    "ccm.speciallist.operlog.downloadtemplate": "Vorlage herunterladen",
    "ccm.datatask.checkNum": "Der Wert ist eine positive ganze Zahl.",
    "ccm.agent.ten.cobrowseMaxNumber": "Maximale Anzahl von Verbindungen zur Zusammenarbeit auf Webseiten",

    "ccm.nms.satisfactionservice.buttonNumber": "Die Anzahl der Schlüssel für die Zufriedenheitsumfrage beträgt nicht mehr als 5 und nicht weniger als 3.",

    "ccm.satisfactionservice.noevaluation": "Nicht bewertet",
    "ccm.satisfactionservice.evaluationerror": "Auswertung fehlgeschlagen",
    "ccm.asr.create.case": "Fall erstellen",
    "ccm.asr.create.case.info": "Sie können nur während eines Anrufs oder nach Beendigung eines Anrufs auf Fall erstellen klicken.",
    "ccm.asr.create.case.chat.info": "Sie können nur während eines Chats oder nach dem Ende eines Chats auf Fall erstellen klicken.",
    "ccm.asr.create.case.text.info": "Die Fallerstellung per Mausklick wird nicht unterstützt, da kein Textinhalt vorhanden ist.",

    "ccm.ucconfig.message.userId": "Verzeichnis-ID (Tenant)",
    "ccm.ucconfig.message.clientId": "Anwendungs-(Client-)ID",
    "ccm.ucconfig.message.clientSecret": "Anwendungskennwort (Client)",
    "ccm.ucconfig.message.title": "Registrieren von Anwendungen bei der Microsoft Identity Platform",
    "ccm.ucconfig.message.integration": "Integration mit Microsoft Teams",
    "ccm.ucconfig.message.info.alert": "Bitte kopieren Sie die folgende Adresse als URI für die Authentifizierungsumleitung akzeptieren an Microsoft für die Anwendungsregistrierung. Kopieren Sie das Registrierungsergebnis, und geben Sie die folgenden Informationen zum Registrierungsantrag ein.",
    "ccm.ucconfig.message.copy": "Kopieren",
    "ccm.ucconfig.message.register": "Anwendungsinformationen werden registriert",
    "ccm.ucconfig.message.required.fields": "Pflichtfelder",
    "ccm.ucconfig.message.required.limit": "Die Länge darf 64 Zeichen nicht überschreiten.",
    "ccm.ucconfig.message.urlTitle": "Umleiten von URIs, die für die Microsoft-Plattform konfiguriert sind, um Authentifizierungsantworten zu akzeptieren:",
    "ccm.ucconfig.message.success": "Erfolg",
    "ccm.ucconfig.message.failed": "fehlgeschlagen",
    "ccm.ucconfig.message.updatesuccess": "Die UC-Integrationskonfiguration wurde erfolgreich gespeichert.",
    "ccm.ucconfig.message.updatefailed": "Die UC-Integrationskonfiguration konnte nicht gespeichert werden.",

    "ccm.agent.label.authType": "Authentifizierungsmodus",
    "ccm.agent.label.authtype.uap": "UAP-Authentifizierung",
    "ccm.agent.label.authtype.unified": "Einheitliche Authentifizierung",

    "ccm.agent.message.synToCcpFailed": "Konnte den Agenten-Authentifizierungsmodus nicht mit der cc-Provision synchronisieren.",
    "ccm.agent.message.redisFailed": "Redis-Sperre konnte nicht abgerufen werden.",
    "ccm.agent.message.synToUapFailed": "Konnte den Agentenauthentifizierungsmodus nicht mit dem UAP synchronisieren.",
    "ccm.skillcreate.repeat.fail": "Der Name der Skill-Warteschlange ist bereits vorhanden.",

    "ccm.create.case.confirm": "Der Falltyp ist leer. Beim Erstellen eines Falls muss der Falltyp manuell ausgewählt werden. Möchten Sie wirklich fortfahren?",
    "ccm.case.title.selectWorkOrder": "Wählen einer Arbeitsauftragskategorie",
    "ccm.case.label.recommendedWorkOrder0": "Empfohlene TT-Kategorie 1",
    "ccm.case.label.recommendedWorkOrder1": "Empfohlene TT-Kategorie 2",
    "ccm.case.label.recommendedWorkOrder2": "Empfohlene TT-Kategorie 3",

    "ccm.recognition.label.policy.information": "Richtlinieninformationen",
    "ccm.recognition.label.editPolicy.information": "Richtlinieninformationen bearbeiten",
    "ccm.agent.label.callDispatchModel": "Anrufzuweisungsmodus",
    "ccm.agent.label.restQueueRule": "Richtlinie für Restwarteschlangen",
    "ccm.agent.label.ifReleaseNoAnswerCall": "Anrufe entlassen, die seit langem nicht mehr beantwortet wurden",
    "ccm.agent.label.ifBusyNoAnswerAgent": "Agenten, die über einen längeren Zeitraum nicht antworten, in den Status Beschäftigt versetzen",
    "ccm.agent.label.asyncUserDispatchMode": "Abrufbedingung für Offline-Nachrichten",
    "ccm.agent.label.asyncUserDispatchMode.zero": "nach Stapelanzahl",
    "ccm.agent.label.asyncUserDispatchMode.one": "nach Chargenanzahl und Geschicklichkeit",
    "ccm.agent.label.asyncUserDispatchMode.two": "nach Stapelanzahl und Online-Status",
    "ccm.agent.label.asyncUserDispatchMode.three": "nach Chargenanzahl, Fähigkeiten und Online-Status",
    "ccm.agent.label.asyncUserDispatchMode.four": "nach Chargenanzahl, Agent, Fähigkeiten und Online-Status",
    "ccm.agent.label.callDispatchModel.best": "Optimal",
    "ccm.agent.label.callDispatchModel.incomingCall": "Skill-Zuweisung eingehender Anrufe vor der Skill-Zuweisung ausgehender Anrufe",
    "ccm.agent.label.callDispatchModel.average": "Durchschnitt",
    "ccm.agent.label.callDispatchModel.manySkills": "Agent mit den meisten Fähigkeiten",
    "ccm.agent.label.restQueueRule.applyFirst": "Agent, der sich zuerst um eine Pause bewirbt",
    "ccm.agent.label.restQueueRule.lessRest": "Agent mit der geringsten Ruhezeit am aktuellen Tag",


    "ccm.agent.label.maxTalkTime": "Maximale Anrufdauer (s)",
    "ccm.agent.label.maxTalkTimeRange": "Wert [1-86400]",
    "ccm.agent.label.busyRate": "Warteschlangennutzung (%)",
    "ccm.agent.label.busyRateRange": "Wert [0-100]",
    "ccm.agent.label.conditionOfBusyTransfer": "Weiterleitung im Besetztzustand",
    "ccm.agent.label.deviceUsage": "Gerätenutzung (%)",
    "ccm.agent.label.waitTime": "Wartedauer (s)",
    "ccm.agent.label.deviceUsageThreshold": "Gerätenutzungsschwelle (%)",
    "ccm.agent.label.deviceUsageThresholdRange": "Wert [0-100]",
    "ccm.agent.label.waitTimeRange": "Wert [0-9999]",
    "ccm.agent.label.calcOfMaxCallNum": "Methode zur Berechnung der maximalen Anrufwarteschlangendaten",
    "ccm.agent.label.fixedValue": "Festwert",
    "ccm.agent.label.signedinAgentsPercentage": "Prozentsatz der angemeldeten Agenten",
    "ccm.agent.label.queuingCallsMaxNum": "Maximale Anzahl von Anrufen in der Warteschlange",
    "ccm.agent.label.scaleFactor": "Skalierfaktor",
    "ccm.agent.label.queuingCallsMaxNumRange": "Wert [0-10000]",
    "ccm.agent.label.scaleFactorRange": "Wert [0-10000]",
    "ccm.agent.label.queuingCallsMaxNumRangeTwo": "Wert [1-10000]",
    "ccm.agent.label.scaleFactorRangeTwo": "Wert [1-10000]",

    "ccm.policy.label.updatesucess": "Die Richtlinieninformationen wurden erfolgreich aktualisiert.",
    "ccm.policy.label.updateVerificationFailed": "Anforderungsparameterverifizierung fehlgeschlagen.",
    "ccm.policy.label.updateFailed": "Richtlinieninformationen konnten nicht aktualisiert werden.",

    "ccm.agent.conditionOfBusyTransfer.error": "Konfigurieren Sie die CFB-Bedingungsparameter.",
    "ccm.agent.calcOfMaxCallNum.error": "Konfigurieren Sie die maximale Anzahl von Anrufen in der Warteschlange.",
    "ccm.contact.detail.message.sending.record": "Nachrichtensendeaufzeichnung",
    "ccm.contact.detail.sendmessage": "Senden",
    "ccm.contact.detail.receiver": "Empfänger",
    "ccm.contact.detail.sending.time": "Absendezeit",
    "ccm.contact.detail.view.message.content": "Ansicht",
    "ccm.contact.detail.message.content": "Nachrichteninhalt",
    "ccm.agent.export.agentinfo.tips": "Die exportierten Daten enthalten personenbezogene Daten, die ordnungsgemäß verwendet und geschützt werden müssen.",
    "ccm.message.center.nocitiction.sending.records.status": "Status senden",

    "title.contact.agent.name": "Dienstkontoname",
    "ccm.contact.tip.not.get.workname": "Der Kontoname, der dem Agenten entspricht, wurde nicht abgerufen\uFF01“",
    "ccm.contact.tip.no.workname": "Der entsprechende Agent wurde nicht abgerufen\uFF01",

    "ccm.common.ajax.searchErr": "Suchfehler.",
    "ccm.dual.call.record.normal.end": "Normales Ende",
    "ccm.dual.call.record.unexpected.end": "Unerwartetes Ende",
    "ccm.dual.call.record.caller": "Anrufernummer",
    "ccm.dual.call.record.called": "Angerufene Nummer",
    "ccm.dual.call.record.startTime": "Zeitpunkt, zu dem ein Anruf initiiert wird",
    "ccm.dual.call.record.endTime": "Endzeit des Anrufs",
    "ccm.dual.call.record.agentTalkingTime": "Anrufbeantwortungszeit des anrufenden Teilnehmers",
    "ccm.dual.call.record.talkingTime": "Anrufantwortzeit",
    "ccm.dual.call.record.talkDuration": "Anrufdauer (s)",
    "ccm.dual.call.record.reasonCode": "Freigabegrund",
    "ccm.dual.call.record.option": "Betrieb",
    "ccm.operlog.dualcallexport.download": "Aufzeichnungen für Doppelanrufe herunterladen",
    "ccm.dual.call.record.callCompletionRate": "Anrufabschlussrate",
    "ccm.dual.call.record.noMatchingDualCallRecordExists": "Es existiert kein passender Dual-Anruf-Datensatz.",
    "ccm.dual.call.record.dualCallType": "Doppelter Anruftyp",
    "ccm.dual.call.record.statistics": "Statistik",
    "ccm.sysparam.url.safe.tip": "HTTP ist unsicher und kann Sicherheitsrisiken verursachen.",

    "ccm.agent.contact.multimedia.web": "Multimedia-Web",
    "ccm.agent.contact.multimedia.whatsapp": "Multimedia- Whatsapp",
    "ccm.agent.contact.multimedia.line": "Multimedia-Linie",
    "ccm.agent.contact.multimedia.wechat": "Multimedia-WeChat",
    "ccm.agent.contact.multimedia.facebook": "Multimedia-Facebook-",
    "ccm.agent.contact.multimedia.twitter": "Multimedia-X (Twitter)",
    "ccm.agent.contact.multimedia.fiveG": "Multimedia-5G RCS",
    "ccm.agent.contact.multimedia.email": "Multimedia-E-Mail",
    "ccm.agent.contact.multimedia.instagram": "Multimedia-Instagram",
    "ccm.agent.contact.multimedia.telegram": "Multimedia-Telegramm",

    "ccm.agent.label.answerType4": "Mitarbeiter-ID und individuelle Stimmen melden",
    "ccm.agent.label.preVoiceSelect": "Stimme vor Mitarbeiter-ID",
    "ccm.agent.label.afterVoiceSelect": "Stimme nach Mitarbeiter-ID",
    "ccm.agent.resetskillrecord.adjustbusinessaccount": "Dienstkonto anpassen",
    "ccm.agent.resetskillrecord.adjustedbusinessaccount": "Angepasstes Dienstkonto",
    "ccm.agent.resetskillrecord.adjustagentworkno": "Anpassen der Agenten-ID",
    "ccm.agent.resetskillrecord.adjustedagentworkno": "Angepasste Agenten-ID",
    "ccm.agent.resetskillrecord.adjusttime": "Zeit anpassen",
    "ccm.agent.resetskillrecord.skillbeforeadjust": "Skill-Warteschlange vor Anpassung",
    "ccm.agent.resetskillrecord.skillafteradjust": "Skill-Warteschlange nach Anpassung",
    "ccm.agent.resetskillrecord.adjustresult": "Ergebnis anpassen",
    "ccm.callreason.label.createrelatecase": "Verwandte Fälle",
    "SM.ORGSTAFF.LABEL.ORGANIZATION": "Organisationseinheit",

    "ccm.idauth.title.idauthconfig": "Identitätsauthentifizierungsverfahren",
    "ccm.idauth.title.name": "Name des Authentifizierungsmodus",
    "ccm.idauth.title.ivr": "Authentifizierungsprozess",
    "ccm.idauth.title.create": "Identitätsauthentifizierungsverfahren hinzufügen",
    "ccm.idauth.title.edit": "Identitätsauthentifizierungsverfahren ändern",
    "ccm.idauth.create.error.name": "Der Authentifizierungsmodus ist bereits vorhanden.",
    "ccm.idauth.create.error.limit": "Es können maximal fünf Prozesse zur Identitätsauthentifizierung hinzugefügt werden.",
    "ccm.idauth.title.code": "Code für den Authentifizierungsmodus",
    "ccm.idauth.create.error.format": "Der Code für den Authentifizierungsmodus darf nur Ziffern, englische Buchstaben und Unterstriche enthalten (_).",
    "ccm.idauth.create.error.length": "Die Länge des Codes für den Authentifizierungsmodus überschreitet die Obergrenze.",

    "ccmanagement.satisfactionconfig.acceptdate.timeerror.laterThanEndTime": "Das Startdatum muss vor dem Enddatum liegen.",
    "ccmanagement.satisfactionconfig.calltime.timeerror.laterThanEndTime": "Die minimale Anrufdauer muss kürzer als die maximale Anrufdauer sein.",
    "ccmanagement.satisfactionconfig.acceptdata.timeVerification": "Wählen Sie ein Datum aus, das nach der aktuellen Uhrzeit und vor dem 19.01.2038 liegt.",
    "ccmanagement.satisfaction.strategy.fail.unknown": "Speichern fehlgeschlagen.",
    "ccmanagement.satisfaction.strategy.fail.calltime.inputnull": "Die minimale oder maximale Anrufdauer darf nicht leer sein.",
    "ccmanagement.satisfaction.strategy.fail.calltime.inputlength": "Die minimale oder maximale Anrufdauer darf maximal 12 Ziffern enthalten.",
    "ccmanagement.satisfaction.strategy.fail.calltime.inputnumber": "Der Wert muss eine positive ganze Zahl sein.",
    "ccmanagement.satisfaction.strategy.fail.calltime.inputnagetive": "Die minimale oder maximale Anrufdauer kann nicht kleiner als 0 sein.",
    "ccmanagement.satisfaction.strategy.fail.calltime.startafterend": "Die minimale Anrufdauer muss kürzer als die maximale Anrufdauer sein.",
    "ccmanagement.satisfaction.strategy.fail.calltime.overlimit": "Es können maximal 100 Richtlinien für die Anrufdauer gespeichert werden.",
    "ccmanagement.satisfaction.strategy.fail.accepttime.inputnull": "Die Start- oder Endzeit darf nicht leer sein.",
    "ccmanagement.satisfaction.strategy.fail.accepttime.inputlength": "Die Länge der Start- oder Endzeit darf die Obergrenze nicht überschreiten.",
    "ccmanagement.satisfaction.strategy.fail.accepttime.inputnumber": "Die Start- oder Endzeit muss eine Zahl sein.",
    "ccmanagement.satisfaction.strategy.fail.accepttime.inputparse": "Die Start- oder Endzeit hat ein falsches Format.",
    "ccmanagement.satisfaction.strategy.fail.accepttime.startafterend": "Die Startzeit muss vor der Endzeit liegen.",
    "ccmanagement.satisfaction.strategy.fail.accepttime.overlimit": "Es können maximal 100 Bearbeitungszeitrichtlinien gespeichert werden.",
    "ccmanagement.satisfaction.strategy.fail.acceptdate.inputnull": "Das Start- oder Enddatum darf nicht leer sein.",
    "ccmanagement.satisfaction.strategy.fail.acceptdate.inputnumber": "Das Start- oder Enddatum hat ein falsches Format.",
    "ccmanagement.satisfaction.strategy.fail.acceptdate.inputparse": "Das Start- oder Enddatum hat ein falsches Format.",
    "ccmanagement.satisfaction.strategy.fail.acceptdate.startafterend": "Das Startdatum muss vor dem Enddatum liegen.",
    "ccmanagement.satisfaction.strategy.fail.acceptdate.overlimit": "Es können maximal 100 Bearbeitungsdatumsrichtlinien gespeichert werden.",
    "ccmanagement.satisfaction.strategy.calltime.minvalue": "Mindestanrufdauer (s)",
    "ccmanagement.satisfaction.strategy.calltime.maxvalue": "Maximale Anrufdauer (s)",
    "ccmanagement.satisfaction.strategy.save": "Speichern",
    "ccmanagement.satisfaction.strategy.delete": "Löschen",
    "ccmanagement.satisfaction.strategy.calltime.interval": "Anrufdauerbereich",
    "ccmanagement.satisfaction.strategy.operation": "Betrieb",
    "ccmanagement.satisfaction.strategy.calltime.lengthlimit": "Die minimale oder maximale Anrufdauer darf maximal 12 Ziffern enthalten.",
    "ccmanagement.satisfaction.strategy.deleteselect": "Möchten Sie die ausgewählten Richtlinien wirklich löschen?",
    "ccmanagement.satisfaction.strategy.calltime.second": "s",
    "ccmanagement.satisfaction.strategy.addsuccess": "Speichern erfolgreich.",
    "ccmanagement.satisfaction.strategy.success": "Erfolg",
    "ccmanagement.satisfaction.strategy.fail": "Fehler",
    "ccmanagement.satisfaction.strategy.saveerror": "Fehler",
    "ccmanagement.satisfaction.strategy.deletesuccess": "Löschung erfolgreich.",
    "ccmanagement.satisfaction.strategy.deletefail": "Löschen fehlgeschlagen.",
    "ccmanagement.satisfaction.strategy.accepttime.timeStart": "Startzeit",
    "ccmanagement.satisfaction.strategy.accepttime.accepttime": "Bearbeitungszeit",
    "ccmanagement.satisfaction.strategy.accepttime.hour": "Stunde",
    "ccmanagement.satisfaction.strategy.accepttime.minute": "Minute",
    "ccmanagement.satisfaction.strategy.accepttime.timeEnd": "Endzeit",
    "ccmanagement.satisfaction.strategy.hourformatvalidate": "Die Stunde hat ein falsches Format.",
    "ccmanagement.satisfaction.strategy.hourvalidate": "Die Stunde kann nicht länger als 24 sein.",
    "ccmanagement.satisfaction.strategy.acceptdate.dateStart": "Startdatum",
    "ccmanagement.satisfaction.strategy.acceptdate.dateEnd": "Enddatum",
    "ccmanagement.satisfaction.strategy.acceptdate.startdate": "Startdatum",
    "ccmanagement.satisfaction.strategy.acceptdate.enddate": "Enddatum",
    "ccmanagement.satisfaction.strategy.minutevalidate": "Die Minute hat ein falsches Format.",

    "ccm.satisfactionconfig.message.duplicateconfig": "Die Priorität jeder Richtlinie für denselben Kanal muss eindeutig sein.",
    "ccm.satisfactionconfig.message.numberlimit": "In einem Mieterbereich sind maximal 100 Kanalkonfigurationen für die Zufriedenheitsumfrage zulässig.",
    "ccm.satisfactionconfig.message.channelused": "Die Richtliniengruppe wird von einem Kanal referenziert und kann nicht gelöscht werden.",
    "ccm.satisfactionconfig.message.smstitle": "SMS",
    "ccm.satisfactionconfig.message.smscloud": "Cloud-SMS von Huawei",
    "ccm.satisfactionconfig.message.smsgateway": "SMS-Gateway",
    "ccm.satisfactionconfig.message.smschannel": "SMS-Push-Kanal",
    "ccm.satisfactionconfig.message.smstemplate": "SMS-Vorlage",
    "ccm.satisfactionconfig.message.validtime": "Gültiger Antwortzeitraum (min.)",
    "ccm.satisfactionconfig.message.strategygroup": "Richtliniengruppe zur Zufriedenheitsumfrage",
    "ccm.satisfactionconfig.message.strategy": "Richtlinie zur Zufriedenheitsumfrage",
    "ccm.satisfactionconfig.message.groupcreate": "Hinzufügen",
    "ccm.satisfactionconfig.message.groupbatchdelete": "Stapel löschen",
    "ccm.satisfactionconfig.message.strategygroupsrv": "Konfiguration des Kanals für die Zufriedenheitsumfragerichtlinie",
    "ccm.satisfactionconfig.message.strategygroupid": "Richtliniengruppen-ID",
    "ccm.satisfactionconfig.message.strategygroupname": "Name der Richtliniengruppe",
    "ccm.satisfactionconfig.title.strategygroup": "Erstellen einer Richtliniengruppe",
    "ccm.satisfactionconfig.message.strategygroupdesc": "Beschreibung der Richtliniengruppe",
    "ccm.satisfactionconfig.message.strategygroupmember": "Richtlinie",
    "ccm.satisfactionconfig.message.strategygroupmemconfig": "Richtlinienkonfiguration",
    "ccm.satisfactionconfig.message.strategygroupmemchoose": "Wählen Sie Richtlinien aus.",
    "ccm.satisfactionconfig.message.strategychanneltitle": "Kanalrichtlinie konfigurieren",
    "ccm.satisfactionconfig.add.strategychanneltitle": "Erstellen einer Channel-Zufriedenheitsrichtlinie",
    "ccm.satisfactionconfig.update.strategychanneltitle": "Channel-Zufriedenheitsstrategie bearbeiten",
    "ccm.satisfactionconfig.message.strategychannelid": "ID der Channel-Richtlinie",
    "ccm.satisfactionconfig.message.channelstrategyconfig": "Richtlinie zur Channel-Zufriedenheitsumfrage",
    "ccm.satisfactionconfig.message.channeltype": "Kanaltyp",
    "ccm.satisfactionconfig.message.channelname": "Kanal",
    "ccm.satisfactionconfig.message.grouppriority": "Priorität",
    "ccm.satisfactionconfig.message.surveytype": "Umfragetyp",
    "ccm.satisfactionconfig.message.surveystrategygroup": "Umfragerichtlinie",
    "ccm.satisfactionconfig.message.audiochannel": "Sprach- oder Videokanal",
    "ccm.satisfactionconfig.message.webchannel": "Web-Multimediakanal",
    "ccm.satisfactionconfig.message.audiofeedbacktype": "IVR-Stimme",
    "ccm.satisfactionconfig.message.smsfeedbacktype": "SMS",
    "ccm.satisfactionconfig.message.webfeedbacktype": "Web",
    "ccm.satisfactionconfig.message.nofeedbacktype": "Keine Umfrage",
    "ccm.satisfactionconfig.message.operate": "Betrieb",
    "ccm.satisfactionconfig.message.delete": "Löschen",
    "ccm.satisfactionconfig.message.update": "Bearbeiten",
    "ccm.satisfactionconfig.message.comfirmtitle": "Bestätigen",
    "ccm.satisfactionconfig.message.comfirm": "Möchten Sie die Richtliniengruppe wirklich löschen?",
    "ccm.satisfactionconfig.message.choosestrategy": "*Wählen Sie die Strategie für die Zufriedenheitsumfrage aus",
    "ccm.satisfactionconfig.message.validatename": "Der Richtliniengruppenname darf nicht leer oder doppelt vorhanden sein.",
    "ccm.satisfactionconfig.message.calltypeout": "Ausgehender Anruf",
    "ccm.satisfactionconfig.message.calltypein": "Eingehender Anruf",
    "ccm.satisfactionconfig.message.prioritytip": "*Ein kleinerer Wert gibt eine höhere Priorität an. Die Richtlinie mit einer höheren Priorität wird bevorzugt für die Durchführung der Umfrage ausgewählt.",
    "ccm.satisfactionconfig.message.accepttime": "Bearbeitungszeit",
    "ccm.satisfactionconfig.message.acceptdate": "Bearbeitungsdatum",
    "ccm.satisfactionconfig.message.acceptagent": "Handling-Agent",
    "ccm.satisfactionconfig.message.callskill": "Skill-Warteschlange",
    "ccm.satisfactionconfig.message.calltype": "Anruftyp",
    "ccm.satisfactionconfig.message.calltime": "Anrufdauer",
    "ccm.satisfactionconfig.message.creategroup": "Richtliniengruppe hinzufügen",
    "ccm.satisfactionconfig.message.configstrategydata": "Richtliniendaten konfigurieren",
    "ccm.satisfactionconfig.message.memberstrategychoose": "Verfügbare Richtlinien",
    "ccm.satisfactionconfig.message.strategychoosed": "Ausgewählte Richtlinien",
    "ccm.satisfactionconfig.message.memberstrategyconfig": "Konfiguration der Strategiegruppenmitglieder",
    "ccm.satisfactionconfig.message.validatemaxnumber": "Es können maximal 100 Richtliniengruppen erstellt werden.",

    "ccm.note.config.agent.ring.setUp": "Konfiguration der Aufforderungsinformationen",
    "ccm.note.config.agent.page.ring": "Ringtones",
    "ccm.note.config.agent.page.ring.audition": "Audition",
    "ccm.note.config.agent.page.ring.choose": "Wählen Sie einen Klingelton.",
    "ccm.note.config.agent.page.ring.title": "Diese Konfiguration gilt nur, wenn die Seite ausgeblendet ist, z. B. wenn der Browser minimiert ist oder wenn die Seite mit Tabulatoren im Hintergrund angezeigt wird.",
    "ccm.note.config.agent.page.ring.tipTitle": "Wenn ein Dialogfeld konfiguriert ist, ist es nur gültig, nachdem der Agent die Option Diese Website darf während der Verbindungsintegration Popup-Fenster im Browser verwenden manuell konfiguriert hat.",
    "ccm.note.config.agent.page.ring.warn": "Es wird empfohlen, einen Anruftest durchzuführen, um zu verhindern, dass sich der Klingelton mit dem auf dem Telefon konfigurierten Klingelton überschneidet.",
    "ccm.note.config.agent.page.windowTip": "Erinnerung an das Popup-Dialogfeld",
    "ccm.note.config.agent.page.ring.select": "Klingelton auswählen",
    "ccm.note.config.agent.page.ring.setUp": "Konfiguration der Seitennachrichtenbenachrichtigung",
    "ccm.note.config.agent.page.ring.voice": "Benachrichtigung über eingehende Audio- und Videoanrufe",
    "ccm.note.config.agent.page.ring.media": "Multimedia-Benachrichtigung über eingehende Anrufe",
    "ccm.note.config.agent.page.ring.newMedia": "Benachrichtigung über neue Multimedia-Nachricht",

    "ccm.contact.customer.config.label": "Kontaktkunden-Informationsbereich",
    "ccm.contact.customer.config.type": "Daten des Kundeninformationsbereichs kontaktieren",
    "ccm.contact.customer.config.visible": "Sichtbar",
    "ccm.contact.customer.config.writeBack": "Kontaktdatensatz zurückschreiben",
    "ccm.contact.customer.config.style": "Anzeigestil",
    "ccm.contact.customer.config.key": "Datenschlüssel",
    "ccm.contact.customer.config.value": "Datenwert",
    "ccm.contact.customer.config.foregroundColor": "Vordergrundfarbe",
    "ccm.contact.customer.config.backgroundColor": "Hintergrundfarbe",
    "ccm.contact.customer.config.fonts": "Schriftart",
    "ccm.contact.customer.config.regular": "Normal",
    "ccm.contact.customer.config.bold": "Fett",
    "ccm.contact.customer.config.italic": "Kursiv",
    "ccm.contact.customer.config.underline": "Unterstrichen",
    "ccm.contact.customer.config.preview": "Vorschau",
    "ccm.contact.customer.config.datasourceType": "Datenquellentyp",
    "ccm.contact.customer.config.datasource.callData": "Anrufzugehörige Daten",
    "ccm.contact.customer.config.datasource.msgDataset": "Informationsdatensatz",
    "ccm.contact.customer.config.datasource.customData": "Lokale Daten",
    "ccm.contact.customer.config.datasource.objId": "Datenquellenobjekt",
    "ccm.contact.customer.config.datasource.objName": "Zuordnungsfeld für Datenquellenobjekte",
    "ccm.contact.customer.config.writeBackField": "Erweitertes Rückschreibefeld für Kontaktdatensätze",
    "ccm.contact.customer.config.writeBackFieldError": "Das erweiterte Kontaktdatensatz-Writeback-Feld kann nicht verwendet werden.",
    "ccm.contact.customer.config.dataItemCodeError": "Die Datenelement-ID ist falsch.",
    "ccm.contact.customer.config.dataItemError": "Der Name oder die ID des Datenelements muss eindeutig sein.",
    "ccm.contact.customer.config.referenced": "Auf das Datenelement wurde vom Kontaktkunden-Informationsbereich verwiesen.",
    "ccm.contact.record.data.referenced": "Auf das Datenelement wurde vom Kontaktdatensatz-Datenelement verwiesen.",

    "ccm.verifyidentity.noMoreThan30Days": "Der Zeitraum darf 30 Tage nicht überschreiten.",
    "ccm.verifyidentity.loginId": "Geschäftskonto",
    "ccm.verifyidentity.workNo": "Agenten-ID",
    "ccm.verifyidentity.callId": "SN anrufen",
    "ccm.verifyidentity.userId": "Kundennummer",
    "ccm.verifyidentity.verifyName": "Authentifizierungsmodus",
    "ccm.verifyidentity.verifyTime": "Authentifizierungszeit",
    "ccm.verifyidentity.verifyResult": "Authentifizierungsergebnis",
    "ccm.verifyidentity.NOTRETURN": "Wird nicht zurückgegeben",
    "ccm.verifyidentity.SUCCESS": "Passieren",
    "ccm.verifyidentity.FAIL": "Fehler",
    "ccm.verifyidentity.ERROR": "Fehler",
    "ccm.verifyidentity.fail.unknown": "Abfrage fehlgeschlagen",
    "ccm.verifyidentity.fail.WORKNOPARSEERROR": "Die Agenten-ID muss eine Nummer sein.",
    "ccm.verifyidentity.fail.CALLIDPARSEERROR": "Die Anruf-SN muss eine Nummer sein.",
    "ccm.verifyidentity.fail.USERIDPARSEERROR": "Die Kundennummer darf keine Sonderzeichen enthalten.",
    "ccm.verifyidentity.fail.VERIFYRESULTPARSEERROR": "Falscher Authentifizierungsergebnisparameter.",
    "ccm.verifyidentity.fail.TIMEPARAMNULLERROR": "Der Abfragezeitbereich darf nicht leer sein.",
    "ccm.verifyidentity.fail.INPUTNULLERROR": "Die Abfrageparameter dürfen nicht leer sein.",
    "ccm.verifyidentity.fail.VERIFYTIMERANGEERROR": "Unzulässiger Abfragezeitraum.",
    "ccm.verifyidentity.fail.LIMITOVERLIMIT": "Der Wert des Paginierungsabfrageparameters überschreitet die Obergrenze.",
    "ccm.verifyidentity.label": "Identitätsauthentifizierung",

    "ccm.transserver.label.cssnotinwhite": "Vorgang fehlgeschlagen. Wenden Sie sich an den Systemadministrator, um die CSS-Dienstadresse zur Vertrauensliste des Ressourcenabbild-CSS-Servers hinzuzufügen.",
    "ccm.transserver.label.cssproxynotinwhite": "Vorgang fehlgeschlagen. Wenden Sie sich an den Systemadministrator, um den Proxy-Server Adresse zur Proxy-Vertrauensliste des CSS-Servers hinzuzufügen.",

    "ccm.whatsapp.template.message.id": "Vorlagennachrichten-ID",
    "ccm.whatsapp.template.message.name": "Nachrichtenname",
    "ccm.whatsapp.whatsApp.channel": "WhatsApp-Kanal",
    "ccm.whatsapp.whatsApp.message.template": "WhatsApp-Vorlage",
    "ccm.whatsapp.select.whatsApp.message.template": "Wählen Sie eine WhatsApp-Vorlagennachricht aus.",
    "ccm.whatsapp.select.whatsApp.channel": "Wählen Sie einen WhatsApp-Kanal aus.",
    "ccm.agent.mobileagent.syserror": "Intelligente Erkennung konnte nicht ermöglicht werden. Wenden Sie sich an den Systemadministrator, um die Umgebungskonfigurationen zu überprüfen.",

    "ccm.agent.contact.info": "Dokumentinformationen",
    "ccm.agent.contact.name": "Dokumentname",
    "ccm.agent.contact.searchName": "Dokumentname",
    "ccm.agent.contact.status": "Dokumentenstatus",
    "ccm.agent.contact.signaturedate": "Unterschriftsdatum",
    "ccm.agent.contact.updatedate": "Aktualisierungsdatum",
    "ccm.agent.contact.invalid": "Invalidierung",
    "ccm.agent.contact.preview": "Vorschau",
    "ccm.contact.label.unsigned": "Unsigniert",
    "ccm.contact.label.signed": "Gezeichnet",
    "ccm.contact.label.inactive": "Abgelaufen",
    "ccm.contact.msg.inactivesuccess": "Aufhebung erfolgreich.",
    "ccm.contact.msg.dataverification": "Anforderungsparameter konnte nicht verifiziert werden.",
    "ccm.contact.msg.dataEmpty": "Die Betriebsdaten existieren nicht im AICC.",
    "ccm.operlog.contactfile.download": "Datei herunterladen",
    "ccm.contract.management.confirm": "Das Dokument enthält sensible Daten. Möchten Sie wirklich fortfahren?",
    "ccm.contract.management.confirmtitle": "Betrieb bestätigen",
    "ccm.contract.management.invalid.confirm": "Möchten Sie dieses Dokument wirklich ungültig machen?",

    "ccm.agent.label.notreply": "Kann nicht antworten",
    "ccm.agent.contact.itacall": "ITA bidirektionaler Anruf",

    "ccm.chatbot.pop.title": "Einen Roboter auswählen",
    "ccm.chatbot.pop.table.botname": "Robotername",
    "ccm.chatbot.label.assist": "Roboterassistent",
    "ccm.chatbot.invalid.empty": "Der Roboterassistent darf nicht leer sein.",
    "ccm.chatbot.invalid.bot": "Dieser Roboter ist ungültig",
    "ccm.chatbot.query.name.fail": "Der Robotername konnte nicht ermittelt werden.",
    "ccm.chatbot.query.list.fail": "Die Roboterliste konnte nicht abgerufen werden.",

    "ccm.transtask.label.expirationDuration": "Aufbewahrungszeitraum für Speicherabzugsdateien",

    "ccm.agent.speciallist.validityPeriod": "Gültigkeitszeitraum",
    "ccm.agent.speciallist.begintime": "Effektive Zeit",
    "ccm.agent.speciallist.endtime": "Ablaufzeit",
    "ccm.agent.speciallist.levelName": "Besondere Listenebene",
    "ccm.speciallist.label.startDate": "Effektive Zeit",
    "ccm.speciallist.msg.necessary": "Die Benutzernummer und der spezielle Listentyp sind obligatorisch.",
    "ccm.agent.speciallist.addList": "Beitreten zum Belästigungsprotokoll",
    "ccm.speciallist.msg.levelName": "Ebene",
    "ccm.speciallevel.speciallist.use": "Verfügbar",
    "ccm.speciallevel.speciallist.unuse": "Nicht verfügbar",
    "ccm.speciallevel.speciallist.restrictMode": "Einschränkungsmodus",
    "ccm.speciallevel.speciallist.restrictHours": "Eingeschränkte Dauer (Stunde)",
    "ccm.speciallevel.speciallist.remark": "Anmerkung",
    "ccm.speciallevel.speciallist.levelName": "Ebenenname",
    "ccm.speciallevel.speciallist.levelMessage": "Ebenenmanagement",
    "ccm.speciallevel.speciallist.level": "Ebene",
    "ccm.speciallevel.tip.start": "Möchten Sie es wirklich aktivieren?",
    "ccm.speciallevel.tip.stop": "Möchten Sie es wirklich deaktivieren?",
    "ccm.speciallist.msg.userNum": "Benutzernummer",
    "ccm.speciallist.msg.userno.error": "Geben Sie Zahlen, englische Zeichen oder die folgenden Zeichen ein:",
    "ccm.speciallist.msg.dateerror": "Sie müssen die effektive Zeit und die Ablaufzeit eingeben.",

    "ccm.agent.title.specialListReminder": "Besondere Listenerinnerung",
    "ccm.speciallist.msg.level": "Besondere Listenebene",
    "ccm.speciallist.msg.typeName": "Besonderer Listentyp",
    "ccm.speciallist.label.msgContent": "Erinnerungsnachricht",
    "ccm.speciallist.msg.empty": "Die Erinnerungsnachricht darf nicht Null sein.",
    "ccm.speciallist.msg.overlimit": "Die Erinnerungsnachricht ist länger als 256 Zeichen.",
    "ccm.speciallist.msg.selectslreminder": "Wählen Sie eine spezielle Listenerinnerung.",
    "ccm.speciallist.msg.selectonlyslreminder": "Es kann nur eine spezielle Listenerinnerung geändert werden.",
    "ccm.speciallist.msg.deleteerror": "Die Erinnerung an die spezielle Liste konnte nicht gelöscht werden.",
    "ccm.speciallist.msg.typeidempty": "Der spezielle Listentyp darf nicht leer sein.",
    "ccm.speciallist.msg.specialchar": "Die Erinnerungsnachricht darf keine Sonderzeichen enthalten.",
    "ccm.speciallist.msg.alreadyexist": "Es gibt bereits eine spezielle Listenerinnerung desselben Typs und derselben Stufe.",

    "ccm.agent.message.selectMaxAgents": "Sie können maximal 20 gleichzeitig verarbeiten.",

    "ccm.speciallist.msg.approve": "Genehmigung",
    "ccm.speciallist.label.approve": "Genehmigungskommentare",
    "ccm.speciallist.poptitle.approve": "Genehmigung von Belästigungsaufzeichnungen",
    "ccm.speciallist.poptitle.view": "Anzeigen von Belästigungsaufzeichnungen",
    "ccm.speciallist.title.approve": "Genehmigungsinformationen",
    "ccm.speciallist.msg.view": "Ansicht",
    "ccm.speciallist.msg.applyTimeFrom": "Startzeit der Anwendung",
    "ccm.speciallist.msg.applyTimeTo": "Endzeit der Anwendung",
    "ccm.speciallist.msg.applyTime": "Anwendungszeit",
    "ccm.botherlist.label.contactrecorddetail": "Kontaktdaten",
    "ccm.botherlist.label.comment": "Meinungen",

    "ccm.botherlist.label.approveStatus.toApprove": "Zu genehmigen",
    "ccm.botherlist.label.approveStatus.approved": "Genehmigt",
    "ccm.botherlist.label.approveStatus.rejected": "Abgelehnt",

    "ccm.botherlist.label.approveComment.toApprove": "Ich stimme zu",
    "ccm.botherlist.label.approveComment.approved": "Ablehnen",

    "ccm.agent.addbother.success": "Es ist gelungen, den Ärger-Rekord zu erstellen.",
    "ccm.agent.addbother.fail": "Hinzufügen fehlgeschlagen.",
    "ccm.agent.contact.joinreason": "Grund für den Beitritt",
    "ccm.agent.contact.callserialno": "Seriennummer anrufen",
    "ccm.agent.contact.contactinfo": "Kontaktinformationen",
    "ccm.agent.contact.createbother": "Beider-Datensatz erstellen",

    "ccm.speciallist.tip.timeVerification": "Wählen Sie eine Zeit aus, die nach der aktuellen Zeit und vor dem 19.01.2038 liegt.",
    "ccm.speciallist.msg.datecompareerror": "Die Ablaufzeit darf nicht nach der effektiven Zeit liegen.",


    "ccm.speciallevel.msg.selectspecialLevel": "Bitte wählen Sie eine spezielle Stufe aus",
    "ccm.botherlist.label.approveComment.agree": "Ich stimme zu",
    "ccm.botherlist.label.approveComment.reject": "Ablehnen",
    "ccm.agent.contact.callinandcallout": "Eingehende und ausgehende Anrufe",
    "ccm.speciallevel.msg.deleteyerror": "Die spezielle Listenebene konnte nicht gelöscht werden.",

    "ccm.agent.addbother.msg.emailOrPhoneValidate": "Unzulässiges Format.",
    "ccm.ccagent.management.advanced.search": "Erweiterte Suche",
    "ccm.ccagent.management.batch.operation": "Batch-Betrieb",
    "ccm.ccagent.management.additions": "Hinzufügen",
    "ccm.ccagent.management.default.options": "Standardoptionen",
    "ccm.ccagent.management.cannot.exceed.25": "Die Nummer darf nicht mehr als 25 Zeichen umfassen.",
    "ccm.ivrvoice.play.error": "Ressourcenausnahme wird geladen. Bitte überprüfen Sie die zugehörige Konfiguration.",
    "ccm.contact.details.tab.title.audio": "'Stimme'",
    "ccm.contact.details.tab.title.video": "'Video'",
    "ccm.agent.contact.transfercontent": "'ASR-Inhalt'",
    "ccm.agent.leavemessage.opinionempty": "Auditkommentare sind obligatorisch.",
    'ccm.contact.placeholder.searchCustomerNo': "'Kontaktnummer'",
    "ccm.agent.label.oneday": "1 Tag",
    "ccm.agent.label.threedays": "'3 Tage'",
    "ccm.agent.label.sevendays": "'7 Tage'",
    "ccm.agent.label.thirtydays": "'30 Tage'",
    "ccm.contactitemconfig.message.noBaseData": "Es stehen keine Basisdaten zur Verfügung",

    "ccm.label.downloadAttachment.confirm": "Möchten Sie die ausgewählte Anlage wirklich herunterladen?",
    "ccm.contact.detail.talk.content.seachtips": "Suchergebnisse: {Anzahl}",
    "ccm.contact.detail.talk.content.not.support": "Der Suchtext darf nicht die Zeichen() enthalten",
    "ccm.contact.detail.talk.content.seach.remark": "Nur Textchat-Inhalte, die auf dieser Seite angezeigt werden, können durchsucht werden",
    "ccm.contact.detail.talk.content.seach.remark.emaile": "Es können nur Nachrichten durchsucht werden, die auf der Suchseite angezeigt wurden. Nach Abschluss der Suche werden nur übereinstimmende Nachrichten angezeigt.",
    "ccm.download.passwd.validator.specialStr": "Nur die folgenden Sonderzeichen sind zulässig: _{'@'}{'%'}",
    "ccm.login.record.noMatchingLoginRecordExists": "'Kein Anmeldeprotokoll verfügbar.'",

    "ccm.agent.message.searchSkillQueueName": "Skill-Warteschlangenname",
    "ccm.agent.button.skill.synchronize": "Synchronisieren",
    "ccm.skillgroup.label.groupid": "Skillgruppen-ID",
    "ccm.skillgroup.label.groupname": "Name der Kompetenzgruppe",
    "ccm.skillgroup.message.groupBind": "Die aktuelle Kompetenz kann nicht für den Agenten-Typ ausgewählt werden, der an die Kompetenzgruppe gebunden ist.",
    "ccm.skillgroup.label.orgname": "Organisation",
    "ccm.agent.label.skillgroup": "Fertigkeitsgruppe",
    "ccm.skillgroup.label.searchname": "Name der Kompetenzgruppe",
    "ccm.skillgroup.label.groupskills": "Zuordnen von Skill-Warteschlangen",
    "ccm.skillgroup.title.add": "Kompetenzgruppe hinzufügen",
    "ccm.skillgroup.title.update": "Kompetenzgruppe aktualisieren",
    "ccm.agent.message.skillweight.invalid": "Das Geschicklichkeitsgewicht muss zwischen 1 und 300 liegen.",
    "ccm.agent.message.agentweight.invalid": "Das Gewicht des Agenten muss zwischen 1 und 100 liegen.",
    "ccm.agent.message.skillweight.outlimit": "Der Gewichtswert muss zwischen 1 und 100 liegen.",
    "ccm.skillgroup.message.noskill": "Die Skillgruppenliste darf nicht leer sein.",
    "ccm.skillgroup.msg.deleteerror": "Wählen Sie die zu löschende Skill-Gruppe aus.",
    "ccm.skillgroup.create.fail": "Die Skill-Gruppe konnte nicht erstellt werden.",
    "ccm.skillgroup.message.skillgroup": "Bitte geben Sie einen Skill-Gruppennamen ein.",
    "ccm.skillgroup.message.nameValidateFail": "Die Skill-Gruppe darf nicht die folgenden speziellen characters:{\\'@\\'}#$%^&*+{\\'|\\'}=~!?,.:;-",
    "ccm.skillgroup.message.namePrefixSuffix": "Die Skill-Gruppe darf nicht mit einem Leerzeichen beginnen oder enden",
    "ccm.skillgroup.message.nameLimit": "Der Name der Skill-Gruppe ist zu lang.",
    "ccm.skillgroup.message.nameExistence": "Der Skillgruppenname existiert bereits.",
    "ccm.skillgroup.update.fail": "Die Skill-Gruppe konnte nicht aktualisiert werden.",
    "ccm.skillgroup.query.fail": "Die Skillgruppeneinstellungen konnten nicht abgerufen werden.",
    "ccm.skillgroup.update.failtip": "Die Skill-Gruppe konnte nicht aktualisiert werden. Synchronisieren Sie zuerst die Skill-Warteschlange und die Skill-Gruppendaten.",
    "ccm.agent.download.forbidden": "Keine Downloadberechtigung.",
    "ccm.skillgroup.create.failtip": "Die Skill-Gruppe konnte nicht erstellt werden. Synchronisieren Sie zuerst die Skill-Warteschlange und die Skill-Gruppendaten.",
    "ccm.skillgroup.create.limit": "Es können maximal 2000 Kompetenzgruppen erstellt werden.",
    "ccm.skillgroup.msg.deleteTips": "Die aktuelle Skill-Gruppe wurde dem Agenten {0} zugeordnet. Entfernen Sie die Zuordnung, und löschen Sie sie dann.",
    "ccm.title.org.all": "Bitte wählen Sie eine Organisation aus",
    'ccm.title.template.select.email': "'E-Mail-Vorlage auswählen'",
    'ccm.title.template.select.sms': "'SMS-Vorlage auswählen'",
    "ccm.sendmsg.whatsapp.withoutTemplate": "Kein Inhalt. Wählen Sie zuerst eine Vorlage aus.",
    "ccm.skillgroup.msg.deleteFailed": "Die Skillgruppe konnte nicht gelöscht werden.",
    "ccm.agent.label.skillType": "Fähigkeitstyp",
    "ccm.agent.label.selectSkillGroup": "Skillset auswählen",
    "ccm.skill.label.defaultskill": "Standard-Skill-Warteschlange",
    "ccm.agent.leavemessage.overtime": "Zeitüberschreitung bei der Verarbeitung. Bitte öffnen Sie das Popup-Fenster erneut.",
    "ccm.notifition.sendmessage.smsconfignull": "Die SMS-Nachrichtenkonfiguration ist leer. Wählen Sie Konfigurationscenter > Zugriff auf Konfiguration > SMS-Konfiguration, um SMS-Informationen zu konfigurieren.",

    "iolp.label.basicInfo": "Grundlegende Informationen",
    "iolp.label.workflow": "Arbeitsablauf",
    "iolp.workflow.node.title": "Knotenattribute erfolgreich festgelegt",
    "iolp.workflow.line.title": "Zeilenattribute erfolgreich festgelegt",
    "iolp.workflow.node.label.name": "Name",
    "iolp.workflow.node.label.nodeType": "Knotentyp",
    "iolp.workflow.node.label.nodeId": "Knoten-ID",
    "iolp.workflow.node.label.executeType": "Ausführungstyp",
    "iolp.workflow.node.label.autoNodeInfo": "ID des nächsten Knotens",
    "iolp.workflow.node.label.executeContent": "Ausführungsinhalt",
    "iolp.workflow.node.label.explain": "Erklärung",
    "iolp.workflow.node.label.associateKbs": "Zugehöriges Wissen",
    "iolp.workflow.node.label.associateBusiness": "Zugehöriger Service-Abwicklungslink",
    "iolp.workflow.node.label.hintTextInfo": "Informationen",
    "iolp.workflow.node.label.sms": "SMS-Vorlage",
    "iolp.workflow.node.label.associateJs": "Zugeordnete JS-Funktion",
    "iolp.workflow.nodetype.andsign": "Aufgabe",
    "iolp.workflow.nodetype.fork": "Zweigstelle",
    "iolp.workflow.nodetype.andjoin": "Kombination",
    "iolp.workflow.executetype.common": "Allgemein",
    "iolp.workflow.executetype.SMS": "SMS",
    "iolp.workflow.executetype.email": "E-Mail-Adresse",
    "iolp.workflow.executetype.IVR": "Trans IVR",
    "iolp.workflow.executetype.auto": "Automatisch",
    "iolp.workflow.executetype.subFlow": "Unterfluss",
    "iolp.workflow.executetype.case": "Fall erstellen",
    "iolp.configur.service.label.basicInfo": "Grundlegende Informationen",
    "iolp.configur.service.label.workflow": "Geführter Prozess",
    "iolp.configur.service.label.directory.name": "Kategoriename",
    "iolp.configur.service.label.name": "Name",
    "iolp.configur.service.label.workflow.name": "Prozessname",
    "iolp.configur.service.label.serviceCode": "Servicecode",
    "iolp.configur.service.label.upperLevelServiceCode": "Servicecode der oberen Ebene",
    "iolp.configur.service.label.imageUrl": "Image-URL",
    "iolp.configur.service.label.customizedPageUrl": "URL der benutzerdefinierten Seite",
    "iolp.configur.service.label.customizedPageContainerId": "Oberste Container-ID auf der benutzerdefinierten Seite",
    "iolp.configur.servicelabel.shortcutButtonPage": "Shortcut-Schaltflächenseite",
    "iolp.configur.service.label.shortcutButtonContainerId": "Oberste Container-ID auf der Verknüpfungsschaltflächenseite",
    "iolp.configur.service.label.search.tips": "Bitte geben Sie einen Namen ein",
    "iolp.configur.service.add": "Neu",
    "iolp.configur.service.operate.edit": "Bearbeiten",
    "iolp.configur.service.operate.delete": "Löschen",
    "iolp.configur.service.tips.customizedPageUrl": "Beispiel: HTML-Datei (CustomerInfoDemo)",
    "iolp.configur.service.add.select.title": "Name der ausgewählten Kategorie",
    "iolp.configur.service.add.parent.title": "Name der übergeordneten Kategorie",
    "iolp.configur.service.dialog.root.directory.title": "Keine übergeordnete Kategorie",
    "iolp.configur.service.add.operate.title": "Neuer Typ",
    "iolp.configur.service.dialog.add.directory.title": "Serviceanleitungskategorie erstellen",
    "iolp.configur.service.dialog.modify.directory.title": "Serviceanleitungskategorie bearbeiten",
    "iolp.configur.service.dialog.add.workflow.title": "Erstellen eines Service-Leitfadenprozesses",
    "iolp.configur.service.dialog.modify.workflow.title": "Serviceberatungsprozess bearbeiten",
    "iolp.configur.servicelabel.label.serialNumber": "Seriennummer",
    "iolp.configur.servicelabel.label.status": "Status",
    "iolp.configur.servicelabel.label.description": "Beschreibung",
    "iolp.configur.servicelabel.button.saveDraft": "Als Entwurf speichern",
    "iolp.configur.servicelabel.status.draft": "Entwurf",
    "iolp.configur.servicelabel.button.publish": "Veröffentlicht",
    "iolp.configur.servicelabel.common.tips.error": "Fehler",
    "iolp.configur.servicelabel.common.tips.success": "Erfolg",
    "iolp.tree.tips": "Sie können keinen Knoten unter einem Blattknoten hinzufügen.",
    "iolp.configur.servicelabel.save.successful": "Speichern erfolgreich.",
    "iolp.configur.servicelabel.release.successful": "Veröffentlichung erfolgreich.",
    "iolp.configur.servicelabel.save.failed": "Speichern fehlgeschlagen.",
    "iolp.configur.servicelabel.release.failed": "Publizierung fehlgeschlagen.",
    "iolp.configur.servicelabel.contact.administrator": "Der Servicecode konnte nicht generiert werden. Bitte wenden Sie sich an den Administrator.",
    "iolp.configur.servicelabel.select.node": "Wählen Sie einen Knoten aus.",
    "iolp.configur.servicelabel.confirm.delete": "Löschen",
    "iolp.configur.servicelabel.confirm.deleteAll": "Möchten Sie den aktuellen Knoten und seine untergeordneten Knoten wirklich löschen?",
    "iolp.configur.servicelabel.confirm.deleteCurrent": "Möchten Sie den aktuellen Knoten wirklich löschen?",
    "iolp.configur.servicelabel.delete.successful": "Erfolgreich gelöscht.",
    "iolp.configur.servicelabel.delete.failed": "Löschen fehlgeschlagen.",
    "iolp.configur.servicelabel.tips.info": "Informationen",
    "iolp.workflow.label.graphZoomOut": "Auszoomen",
    "iolp.workflow.label.graphZoomIn": "Einzoomen",
    "iolp.workflow.label.previous": "Abbrechen",
    "iolp.workflow.label.next": "Wiederherstellung",
    "iolp.workflow.label.cleanGraph": "Leinwand löschen",
    "iolp.workflow.label.taskElement": "Funktionsdiagrammelement",
    "iolp.workflow.label.decisionElement": "Zweigdiagrammelement",
    "iolp.workflow.label.operation": "Betrieb",
    "iolp.workflow.label.information": "Informationen",
    "iolp.workflow.label.unmatchedFlow": "Der entsprechende Dienst kann nicht zugeordnet werden",
    "iolp.workflow.button.restart": "Ablauf neu starten",
    "iolp.workflow.button.hideFlowChart": "Flussdiagramm ausblenden",
    "iolp.workflow.button.showFlowChart": "Flussdiagramm anzeigen",
    "iolp.workflow.label.currentNodeName": "Aktueller Knotenname",
    "iolp.workflow.label.customizeInfo": "Info anpassen",
    "iolp.workflow.label.flowInfo": "Informationen und Erläuterungen",
    "iolp.workflow.button.kbs": "Wissensdatenbank",
    "iolp.workflow.button.service": "Geschäftsabwicklung",
    "iolp.workflow.title.name": "Geschäftsprozess",
    "iolp.configur.service.label.service.title": "Dienstkategorie",
    "iolp.configur.service.label.none.desc": "Es ist keine Konfiguration der Servicekategorie verfügbar. Erstellen Sie zuerst eine Dienstkategorie.",
    "iolp.run.service.label.customizedPage": "Anpassungsinformationen",
    "iolp.run.service.label.overview": "Prozessübersicht",
    "iolp.run.service.label.nodeName": "Knotenname:",
    "agentconsole.ccnotification.recipient.validate.error": "Jede Nummer darf nur Ziffern und maximal 20 Ziffern enthalten.",
    "ccm.sendwhatsapp.content.too.long": "Der Vorlageninhalt ist zu lang.",
    "ccm.sendwhatsapp.body.too.long": "Der Körperinhalt ist zu lang.",
    "ccm.speciallist.tip.time.max": "Bitte wählen Sie einen Zeitpunkt aus, der vor dem Ablaufzeitpunkt des Projekts (19.01.2013) liegt“",
    "ccm.satisfactionconfig.message.textmsg": "Textnachricht",
    "ccm.speciallist.tip.startTime.min": "Die effektive Zeit muss nach der aktuellen Zeit liegen.",
    "ccm.custdata.operlog.specCharValidate": "Darf keine Sonderzeichen außer _ @ % enthalten",
    "ccm.calledconfig.message.casetype": "Falltyp",
    "ccm.satisfaction.message.resetconfirm": "Möchten Sie den Zufriedenheitsgrad und die Vorlage für die Zufriedenheitsumfrage wirklich zurücksetzen? Jeder Kanal muss den Inhalt für den Zufriedenheitsgrad und die Vorlage zum Zurücksetzen verwenden.",
    "ccm.contactitemconfig.message.itemValidateall": "Geben Sie einen Unterstrich (_), englischen Buchstaben oder Ziffern ein",
    "ccm.speciallist.msg.disable.fail": "Deaktivierung fehlgeschlagen",
    "ccm.speciallist.tip.endTime.min": "Die Ablaufzeit muss nach der aktuellen Zeit liegen.",
    "ccm.satisfactiontemplate.title": "Konfiguration des Inhalts der Vorlagen für die Zufriedenheitsumfrage",
    "ccm.satisfactiontemplate.icontitle": "Vorlageninhalt nach Zufriedenheitsgrad aktualisieren",
    "ccm.speciallist.msg.withoutauth": "Sie haben keine Vorgangsberechtigung.",
    "ccm.sysparam.url.unsafe": "(unsicher)",
    "ccm.satisfaction.leveltips": "Hinweis: Alle Kanäle verwenden die gleiche Zufriedenheitskonfiguration.",
    "ccm.calledconfig.message.selectcasetype": "Wählen Sie einen Falltyp aus",
    "ccm.notifition.sendmessage.largerthanlimit": "Die Anzahl der Empfänger überschreitet das Maximum von {n}.",
    "ccm.agent.callreason.must": "Der Anrufgrund ist obligatorisch.",
    "iolp.workflow.efficiency.analysis": "Effizienzanalyse",
    "iolp.configur.servicelabel.label.version": "Freigabeversion",
    "iolp.configur.servicelabel.label.version.number": "Anzahl der Versionen",
    "iolp.configur.servicelabel.status.abandon": "Ungültig",
    "iolp.configur.servicelabel.status.publish": "Freigabe",
    "iolp.configur.servicelabel.status.unpublish": "Nicht veröffentlicht",
    "iolp.configur.service.operate.copy": "Replikation",
    "iolp.configur.service.operate.publish": "Freigabe",
    "iolp.configur.service.operate.cancel": "Veröffentlichung abbrechen",
    "iolp.configur.servicelabel.publish.successful": "wurde erfolgreich veröffentlicht",
    "iolp.configur.servicelabel.publish.failed": "Publizierung fehlgeschlagen",
    "iolp.configur.servicelabel.cancel.successful": "Freigabe erfolgreich abgebrochen.",
    "iolp.configur.servicelabel.cancel.failed": "Abbruch fehlgeschlagen",
    "iolp.configur.servicelabel.copy.successful": "wurde erfolgreich kopiert.",
    "iolp.configur.servicelabel.copy.failed": "Replikation fehlgeschlagen",
    "iolp.configur.servicelabel.confirm.delete.flow": "Möchten Sie die aktuelle Version wirklich löschen?",
    "iolp.process.utilization.efficiency.analysis": "Analyse der Prozessnutzungseffizienz",
    "iolp.flow.number.of.contacts": "Anzahl der Kontakte (Stücke)",
    "iolp.flow.first.solution.rate": "Erste Lösungsrate (%)",
    "iolp.flow.please.select.time.before.today": "Bitte wählen Sie eine Zeit vor dem heutigen Tag aus",
    "iolp.flow.the.interval.validate": "Das Intervall beträgt 1 Tag oder 3 - 30 Tage.",
    "iolp.flow.analysis.date.time": "Datum/Uhrzeit",
    "ccm.empty.template": "Die Vorlage existiert nicht. Überprüfen Sie die Benachrichtigungsvorlage.",
    "ccm.empty.variables": "Die Vorlagenvariable existiert nicht. Überprüfen Sie die Benachrichtigungsvorlage.",
    "ccm.no.meeting.variables": "Die Vorlagenvariable enthält nicht die Variable Meeting_URL. Überprüfen Sie die Benachrichtigungsvorlage.",
    "ccm.agent.meeting.failed": "Der Agent kann keine Konferenz erstellen.",
    "ccm.agent.link.failed": "Der Agent kann den SMS-Link nicht generieren.",
    "ccm.agent.meeting.receiver.check.error": "Die Anzahl der Empfänger darf 1 nicht überschreiten.",
    "ccm.agent.contact.timeouthungup": "Timeout-Abschaltung",
    "ccm.agent.contact.skilltimeouthungup": "Bei Zeitüberschreitung an Skill-Warteschlange weiterleiten",
    "ccm.sms.meeting.link": "Konferenzverbindungs-SMS",
    "ccm.agent.sms.meeting.gateway.commonerror": "Die Konferenz konnte nicht erstellt werden. Fehlercode: {n}",
    "ccm.agent.sms.meeting.gateway.error": "Die Konferenz konnte nicht erstellt werden. Fehlercode: {n}. Erstellen Sie die Konferenz nicht im Stumm-, Halte- oder Mehrteilnehmermodus.",
    "ccm.contactRecordDetail.notSetTraceLogError": "Es wurde keine Flussspur gefunden. Fügen Sie Nachverfolgungseinstellungen hinzu und zeigen Sie den Flow-Tracking des entsprechenden Kontaktdatensatzes an.",
    "ccm.agent.callreason.modify.exceeds.limit": "Anrufgrund konnte nicht geändert werden. Das Intervall überschreitet die Gültigkeitsdauer des Anrufgrunds.",
    "ccm.label.appcube.switch": "AppCube-integrierte Seite",
    "ccm.label.appcube.app": "Anwendung auswählen",
    "ccm.label.appcube.menu": "Menü auswählen",
    "ccm.contactitemconfig.label.data.item.sequence.number.seq": "Feldreihenfolge",
    "ccm.contactitemconfig.label.data.item.add": "Kontaktdatensatzfeld erstellen",
    "ccm.contactitemconfig.label.data.item.edit": "Kontaktdatensatzfeld bearbeiten",
    "ccm.contactitemconfig.label.data.item.tips": "Die Schnittstelle der Datensatzquelle muss auf der Schnittstellenverwaltungsseite konfiguriert werden. Wenden Sie sich an den Systembetriebsadministrator.",
    "ccm.contactitemconfig.label.data.item.basic.item": "Erweitertes Basisfeld",
    "ccm.contactitemconfig.label.data.item.custom.item": "Benutzerdefiniertes erweitertes Feld",
    "ccm.contactitemconfig.label.dataInfo.item.add": "Datenelement erstellen",
    "ccm.contactitemconfig.label.dataInfo.item.edit": "Datenelement bearbeiten",
    "ccm.custdata.label.data.table.template": "Datentabellenvorlage",
    "ccm.custdata.msg.uploadType": "Doppelte Datenimportmethode",
    "ccm.custdata.msg.uploadTypeInfo": "Wenn Daten doppelt vorhanden sind und Sie die Originaldaten überschreiben, wird der Datensatz aktualisiert. Wenn Sie die Originaldaten nicht überschreiben, werden sie als fehlerhafte Daten betrachtet",
    "ccm.custdata.msg.uploadType.cover": "Originaldaten überschreiben",
    "ccm.custdata.msg.uploadType.notcover": "Originaldaten nicht überschreiben",
    "ccm.pageconfig.label.page": "Seite",
    "ccm.pageconfig.label.publishpage": "Seite veröffentlichen",
    "ccm.transtask.label.intelligentInspectionConfig": "AI-Inspektionsdaten-Dump-Einstellung",
    "ccm.validate.portLengthError": "Der Portbereich sollte 1-65535 sein",
    "ccm.resource.dump.server.add": "Erstellen Sie einen Ressourcenabbildserver",
    "ccm.resource.dump.server.edit": "Ressourcenabbildserver bearbeiten",
    "ccm.resource.dump.server.view": "Ressourcenabbildserver anzeigen",
    "ccm.resource.dump.css.add": "Erstellen Sie einen Cloud Search-Server",
    "ccm.resource.dump.css.edit": "Cloud Search Server bearbeiten",
    "ccm.resource.dump.css.view": "Cloud Search Server anzeigen",
    "ccm.resource.dump.task.add": "Erstellen einer Ressourcenabbildaufgabe",
    "ccm.resource.dump.task.edit": "Bearbeiten einer Ressourcenabbildaufgabe",
    "ccm.resource.dump.task.view": "Anzeigen einer Ressourcenabbildaufgabe",
    "ccm.transtask.label.intelligentInspectionDay": "AI-Inspektionsdaten-Dump-Dauer",
    "ccm.transtask.css.server.num": "Die Anzahl der Cloud Search Server darf 1 nicht überschreiten.",
    "ccm.contact.customer.config.num": "Die Spalte mit den Kontaktkundeninformationen darf 10 nicht überschreiten.",
    "ccm.export.password.error.tips": "Das Kennwort der komprimierten Datei enthält 8 bis 12 Zeichen, einschließlich englischer Buchstaben, Ziffern und Sonderzeichen {\\'@\\'}#$%^&*+{\\'|\\'}=~!?,.:;-",
    "ccm.export.password.error.message": "Der Wert muss englische Buchstaben, Ziffern und Sonderzeichen enthalten  {\\'@\\'}#$%^&*+{\\'|\\'}=~!?,.:;-_\\'\\()",
    "ccm.agent.ccagentinfo.modifyselfphone.success": "Änderung der Festnetz-/Mobilfunknummer erfolgreich",
    "ccm.agent.ccagentinfo.modifyselfphone.fail": "Festnetz-/Mobiltelefonnummer konnte nicht geändert werden",
    "ccm.export.table.taskTime": "Aufgabenzeit",
    "ccm.export.table.taskStatus": "Aufgabenstatus",
    "ccm.agent.contact.multimedia.sms": "Multimedia-SMS",
    "ccm.agent.label.skill": "Geschicklichkeit",
    "ccm.agent.button.randomRestSoftPhonePwd": "Setzen Sie das Softphone-Passwort zurück",
    "ccm.agent.label.skillGroup": "Skill-Warteschlangengruppe",
    "ccm.agent.message.searchSkillGroupName": "Name der Skill-Warteschlangengruppe",
    "ccm.skill.label.skillGroupName": "Name der Skill-Warteschlangengruppe",
    "ccm.agent.button.restSoftPhonePwd.remark": "Wenn Sie Agenten-Softphone-Passwörter in Batches auf zufällige Passwörter zurücksetzen, müssen Agenten ihre eigenen Softphone-Passwörter zurücksetzen, bevor sie die Softphone-Funktion verwenden. Der Administrator kann auch in der Liste auf Softphone-Kennwort zurücksetzen klicken, um das Kennwort für den Agenten manuell zu ändern.",
    "ccm.agent.management.exporting.agent.information": "Exportieren von Agenteninformationen",
    "ccm.agent.clear.message.clearcommonerror": "Konfiguration konnte nicht abgebrochen werden. Der Dienst ist nicht verfügbar.",
    "ccm.contactitemconfig.label.dataInfo.item.name.exist": "Der Datenelementname ist bereits vorhanden.",
    "ccm.agent.button.downloadvrc": "VRC herunterladen",
    "ccm.dual.call.callback.url.config": "Konfiguration der Rückruf-URL",
    "ccm.dual.call.callback.url.auth": "Schnittstelle, die URL-Authentifizierung aufruft",
    "ccm.dual.call.callback.url.authType": "Rückruf-URL-Authentifizierungsmodus",
    "ccm.dual.call.callback.url.ita": "ITA-Parameterkonfiguration",
    "ccm.dual.call.callback.url.xNumberType": "X-Nummern-Zuweisungsmodus",
    "ccm.agent.management.select.agent": "Agenten nach Mitarbeiter-ID auswählen",
    'ccm.password.length.limit.min': 'Das Kennwort ist zu kurz. Geben Sie {min}-{max} Zeichen ein',
    'ccm.password.length.limit.max': 'Das Kennwort ist zu lang. Geben Sie {min}-{max} Zeichen ein',
    "ccm.agent.operation.SCREENMONITOR": "Bildschirmüberwachung",
    'ccm.mobile.agent.front.add': 'Erstellen eines Mobile Agents',
    'ccm.mobile.agent.front.edit': 'Mobile Agent bearbeiten',
    'ccm.mobile.agent.front.checkNum': 'Wählen Sie mindestens eine Nummer aus.',
    'ccm.mobile.agent.front.maxNum': 'Die Anzahl darf 15 nicht überschreiten.',
    'ccm.contactitemconfig.label.contactData.add': 'Erstellen eines Kontaktdatensatzes',
    'ccm.contactitemconfig.label.contactData.edit': 'Bearbeiten eines Kontaktdatensatzes',
    'ccm.agent.button.downloading': 'Wird heruntergeladen ...',
    'ccm.label.operationexport.nodata': 'Kein Betriebsdatensatz erfüllt die Bedingungen.',
    'ccm.label.transferexport.nodata': 'Kein Weiterleitungsdatensatz erfüllt die Bedingungen.',
    'ccm.label.leaveexport.nodata': 'Kein Restdatensatz entspricht den Anforderungen.',
    'ccm.label.send.whatsapp': "'Wassapp senden'",
    "ccm.callcenterinstanceslist.vcallcenterDeatil.specialStrValidateFailed": "Der Name des Callcenters darf keine Sonderzeichen enthalten",
    'ccm.common.validation.numbers': "'Bitte geben Sie nur Ziffern ein'",
    'ccm.common.validation.click.number': "'Bitte klicken Sie auf die Nummer'",
    'ccm.ccagent.agent.weight.tips': 'Ein höherer Wert weist auf eine höhere Priorität hin. Wenn mehrere Agenten im Leerlauf sind, weist das System eingehende Anrufe bevorzugt dem Agenten mit einer höheren Gewichtung zu.',
    'ccm.ccagent.skill.weight.tips': 'Ein höherer Wert weist auf eine höhere Priorität hin. Wenn sich Anrufe in mehreren Warteschleifen befinden, weist das System die Anrufe in der Warteschleife mit einer höheren Gewichtung dem Agenten im Leerlauf zu.',
    'ccm.ccagent.batch.modify.special.character.verification': 'kann nicht enthalten',
    'ccm.resource.dump.obs.cert.warn.tip': 'Bitte wenden Sie sich an das O&M-Personal, um das OBS-Vertrauenszertifikat in die Zertifikatsbibliothek zu importieren.',
    "ccm.message.cannot.supported.chars": "Die folgenden Zeichen werden nicht unterstützt:{nicht unterstützt}",
    'ccm.message.cannot.supported.contain.chars': 'Die folgenden Zeichen werden nicht unterstützt:',
    "ccm.audiovideoworkbench.mood": "Kundenstimmung",
    "ccm.audiovideoworkbench.intention": "Intention",
    "ccm.audiovideoworkbench.complain": "Absicht",
    "ccm.audiovideoworkbench.aiswitch": "Großes Sprachmodell",
    "ccm.audiovideoworkbench.airecommendation": "Intelligente Empfehlung",
    "ccm.audiovideoworkbench.case.list": "Fallliste",
}