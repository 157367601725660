export default {
  'oifde.custom.displayName': 'Service d\'affaires',
  'oifde.common.displayName': 'Nœud commun',
  'oifde.basic.displayName': 'Service de base',
  'oifde.test.displayName': 'Testez le service d\'échantillon',
  'oifde.start.displayName': 'Démarrer',
  'oifde.start.description': 'Démarrer',
  'oifde.start.keywords': 'Démarrer',
  'oifde.commonBusinessInvokeService.displayName': ' Invocation de l\'interface d\'affaires',
  'oifde.commonBusinessInvokeService.description': 'Invocation de l\'interface .Business (commonBusinessInvokeService)',
  'oifde.commonBusinessInvokeService.keywords': ' Invocation de l\'interface d\'affaires',
  'oifde.procedureService.displayName': 'Procédure stockée',
  'oifde.procedureService.description': 'Procédure stockée (procedureService)',
  'oifde.procedureService.keywords': 'Procédure stockée',
  'oifde.conditionService.displayName': 'Condition Jugement',
  'oifde.conditionService.description': 'Condition Jugement (conditionService)',
  'oifde.conditionService.keywords': 'Condition Jugement',
  'oifde.callEndService.displayName': 'Fin d\'appel',
  'oifde.callEndService.description': 'Call Ending (callEndService)',
  'oifde.callEndService.keywords': 'Fin d\'appel',
  'oifde.sendSmsService.displayName': 'SMS Envoi',
  'oifde.sendSmsService.description': 'Envoi .SMS (sendSmsService)',
  'oifde.sendSmsService.keywords': 'SMS Envoi',
  'oifde.commonVoiceService.displayName': 'Réponse',
  'oifde.commonVoiceService.description': 'Response (commonResponseService)',
  'oifde.commonVoiceService.keywords': 'Réponse',
  'oifde.transforFlowService.displayName': ' Transfert de flux',
  'oifde.transforFlowService.description': 'Transfert .Flow (transforFlowService)',
  'oifde.transforFlowService.keywords': ' Transfert de flux',
  'oifde.callTransferService.displayName': ' Transfert d\'appel',
  'oifde.callTransferService.description': 'Call Transfer (callTransferService)',
  'oifde.callTransferService.keywords': ' Transfert d\'appel',
  'oifde.speechRecognitionService.displayName': 'Reconnaissance sémantique',
  'oifde.speechRecognitionService.description': 'Reconnaissance sémantique (speechRecognitionService)',
  'oifde.speechRecognitionService.keywords': 'Reconnaissance sémantique',
  'oifde.speechRecognitionService.confidence2TopError': 'L\'intervalle de confiance doit être compris entre 0 et 1.',
  'oifde.keyboardRecognitionService.displayName': 'Reconnaissance des clés',
  'oifde.keyboardRecognitionService.description': 'Reconnaissance des clés (keyboardRecognitionService)',
  'oifde.keyboardRecognitionService.keywords': 'Reconnaissance des clés',
  'oifde.textVariableToChatbotService.displayName': 'Texte au chatbot',
  'oifde.textVariableToChatbotService.description': 'Text to chatbot (textVariableToChatbotService)',
  'oifde.textVariableToChatbotService.keywords': 'Texte au chatbot',
  'oifde.checkRegularService.displayName': ' Validation des règles',
  'oifde.checkRegularService.description': 'Rule Validation (checkRegularService)',
  'oifde.checkRegularService.keywords': ' Validation des règles',
  'oifde.dateProcessService.displayName': 'Traitement des dates',
  'oifde.dateProcessService.description': 'Date Processing (dateProcessService)',
  'oifde.dateProcessService.keywords': 'Traitement des dates',
  'oifde.errTimesClearService.displayName': 'Error Count Clearance',
  'oifde.errTimesClearService.description': 'Error Count Clearance (errTimesClearService)',
  'oifde.errTimesClearService.keywords': 'Error Count Clearance',
  'oifde.CircleService.displayName': 'Logic Loop',
  'oifde.CircleService.description': ' Boucle logique (CircleService)',
  'oifde.CircleService.keywords': 'Logic Loop',
  'oifde.service.invoke.user.level': 'Niveau d\'utilisateur',
  'oifde.UserLevelService.displayName': 'Niveau d\'utilisateur',
  'oifde.UserLevelService.keywords': 'Niveau d\'utilisateur',
  'oifde.UserLevelService.description': ' Niveau d\'utilisateur (UserLevelService)',
  'oifde.service.invoke.user.level.reminder': 'Définir ce paramètre à un entier non négatif ou à une variable de type caractère. La valeur par défaut est 0 et la valeur maximale est 14. Les appels des clients de haut niveau sont traités d\'abord après le transfert de l\'appel au service manuel.',
  'oifde.compositeTestService.displayName': ' Échantillon d\'élément de diagramme composite',
  'oifde.compositeTestService.description': 'Échantillon d\'élément de diagramme composite (compositeTestService)',
  'oifde.compositeTestService.keywords': ' Échantillon d\'élément de diagramme composite',
  'oifde.service.condition.condgroup.desc': 'Définir les attributs de condition de jugement.',
  'oifde.service.condition.condgroup.displayName': 'Conditions de jugement',
  'oifde.service.condition.condgroup.keyTitle': 'Condition',
  'oifde.service.condition.condgroup.valueTitle': ' Expression de condition',
  'oifde.service.invoke.cachegroup.desc': 'Définir les attributs de la variable correspondante.',
  'oifde.service.invoke.cachegroup.displayName': 'Attributs variables',
  'oifde.service.invoke.cachegroup.keyTitle': 'Cache Variable',
  'oifde.service.invoke.cachegroup.valueTitle': 'Attribut de réponse',
  'oifde.service.invoke.interfaceType': 'Type d\'interface',
  'oifde.service.invoke.interfaceType.desc': 'Type d\'interface.',
  'oifde.service.invoke.interfaceId': 'Interface invoquée',
  'oifde.service.invoke.interfaceId.desc': 'Interface invoquée.',
  'oifde.service.invoke.paramgroup.desc': 'Liste des paramètres d\'entrée de l\'interface.',
  'oifde.service.invoke.paramgroup.name': 'Paramètres d\'entrée d\'interface',
  'oifde.service.invoke.startTime': ' Heure de début',
  'oifde.service.invoke.startTime.desc': 'Le format est HH:MM:SS. Par exemple, 08:00:00',
  'oifde.service.invoke.endTime': ' Temps de fin',
  'oifde.service.invoke.endTime.desc': 'Le format est HH:MM:SS. Par exemple, 23:00:00',
  'oifde.service.invoke.sendCircle': 'Intervalle d\'envoi',
  'oifde.service.invoke.sendCircle.desc': 'Intervalle d\'invocation de l\'interface, en jours. La valeur est un entier. La valeur ne peut pas dépasser 365. Si ce paramètre est défini à 1, l\'élément de diagramme de fin n\'est pas appelé périodiquement.',
  'oifde.service.invoke.CallTransferCallingNum': 'Numéro d\'appel',
  'oifde.service.invoke.CallTransferCallingNum.desc': 'Cette valeur est utilisée comme numéro appelant si le type de transfert d\'appel est défini à 5 . (indiquant que l\'appel est transféré à un tiers).Le numéro appelant doit être un code d\'accès attribué par le système ou un paramètre personnalisé.Exemple : 660001 ou FLOW.accessCode',
  'oifde.service.invoke.CallTransferCallingNum.lastAgentDesc': 'Le numéro d\'appel est le numéro d\'appel entrant du client. Par exemple, SYS.callingNumber',
  'oifde.service.invoke.CallTransferType': 'Type de transfert',
  'oifde.service.invoke.CallTransferType.desc': 'Type de transfert de dialogue. Les options sont les suivantes',
  'oifde.service.invoke.CallTransferRouteValue': 'Valeur de la route',
  'oifde.service.invoke.CallTransferRouteValue.desc': 'Appelez la valeur de la route de transfert. Définissez ce paramètre en fonction du type de transfert d\'appel.',
  'oifde.service.invoke.CallTransferRouteTypePolicy': 'Politique de routage',
  'oifde.service.invoke.CallTransferRoutePolicyType.percentage': 'Pourcentage',
  'oifde.service.invoke.CallTransferRoutePolicyType.numberSegment': 'Segment de nombre',
  'oifde.service.invoke.CallTransferRouteDefaultSkillName': 'File d\'attente de compétences par défaut',
  'oifde.service.invoke.CallTransferMatchingType': 'Règle de correspondance',
  'oifde.service.invoke.CallTransferMatchingType.prefix': 'Préfixe',
  'oifde.service.invoke.CallTransferMatchingType.suffix': 'Suffixe',
  'oifde.service.invoke.CallTransferRouteTypePolicy.percentTotalError': 'Le pourcentage total n\'est pas égal à 100',
  'oifde.service.invoke.CallTransferRouteTypePolicy.percentValueError': 'Entrez un nombre entier positif compris entre 1 et 100',
  'oifde.service.invoke.CallTransferTransData': 'Données associées',
  'oifde.service.invoke.CallTransferTransData.desc': ' Données associées transportées pendant le transfert d\'appel à un agent.',
  'oifde.service.invoke.CallTransferTransData.desc2': 'Alternativement vous pouvez entrer plusieurs variables de flux ou globales par exemple {"data"',
  'oifde.service.invoke.textVariable': 'Variable de texte',
  'oifde.service.invoke.textVariable.desc': 'Text variable envoyée au chatbot. Une valeur doit être attribuée à cette variable avant que le nœud puisse être exécuté.',
  'oifde.service.invoke.KeyboardInputRegExp': 'Expression de la règle',
  'oifde.service.invoke.KeyboardInputRegExp.desc': 'L\'expression vérifie si le texte entré par l\'utilisateur répond à la règle spécifiée. La valeur peut être une expression régulière. Exemple',
  'oifde.service.invoke.voiceType': 'Type de réponse',
  'oifde.service.invoke.voiceSource': 'Source de réponse',
  'oifde.service.invoke.staticVoice': 'Voix statique',
  'oifde.service.invoke.TTSVoice': 'TTS',
  'oifde.service.invoke.multiVoice': 'Voix variable',
  'oifde.service.invoke.multiVoiceLanguage': 'Langue',
  'oifde.service.invoke.multiVoiceType': 'Type',
  'oifde.service.invoke.voiceTemplateCode': 'Modèle de réponse',
  'oifde.service.invoke.modelType': 'Mode de réponse',
  'oifde.service.invoke.modelType.desc': 'Si la boîte de dialogue peut être interrompue.',
  'oifde.service.invoke.timeOut': 'Intervalle de temporisation',
  'oifde.service.invoke.timeOut.desc': 'Timeout intervalle(Unité: secondes). La valeur est un entier. Exemple',
  'oifde.service.invoke.voiceVariable': 'Variable de réponse',
  'oifde.service.invoke.voiceVariable.mix.desc': 'Variable vocale ou textuelle incluse dans la réponse. Une valeur doit être attribuée à cette variable avant que le noeud puisse être exécuté (Pour un type voix et texte, la variable de réponse est dans le fichier d\'enregistrement URL~$TTS text$~recording file URL format.)',
  'oifde.service.invoke.voiceVariable.tts.desc': 'Variable vocale ou textuelle incluse dans la réponse. Une valeur doit être attribuée à cette variable avant que le noeud puisse être exécuté (Pour un type de texte de réponse, la variable de réponse est au format texte TTS. Un seul texte TTS est pris en charge.)',
  'oifde.service.invoke.voiceVariable.voice.desc': 'Variable vocale ou textuelle incluse dans la réponse. Une valeur doit être attribuée à cette variable avant que le noeud puisse être exécuté (Pour un type de voix statique, la variable de réponse est au format d\'URL du fichier d\'enregistrement. Une seule URL est prise en charge.)',
  'oifde.service.invoke.voiceVariable.default.desc': 'Variable vocale ou textuelle incluse dans la réponse. Une valeur doit être attribuée à cette variable avant que le nœud puisse être exécuté.',
  'oifde.service.invoke.voiceVariable.multiVoice.telnum.desc': 'Variable vocale ou textuelle incluse dans la réponse. Une valeur doit être attribuée à cette variable avant que le noeud puisse être exécuté (Pour un type de numéro de téléphone, la variable de réponse est dans le format de contenu réel de la variable de réponse est du texte numérique. Si l\'entrée est incorrecte la lecture de la voix est anormale.)',
  'oifde.service.invoke.voiceVariable.multiVoice.number.desc': 'Variable vocale ou textuelle incluse dans la réponse. Une valeur doit être attribuée à cette variable avant que le noeud puisse être exécuté (Pour un type de nombre, la variable de réponse est dans le format de contenu réel de la variable de réponse est du texte numérique. Si l\'entrée est incorrecte la voix jouant est anormale.)',
  'oifde.service.invoke.voiceVariable.multiVoice.time.desc': 'Variable vocale ou textuelle incluse dans la réponse. Une valeur doit être assignée à cette variable avant que le noeud puisse être exécuté(Pour un type de temps, la variable de réponse est:hh',
  'oifde.service.invoke.voiceVariable.multiVoice.date.desc': 'Variable vocale ou textuelle incluse dans la réponse. Une valeur doit être attribuée à cette variable avant que le noeud puisse être exécuté (Pour un type de date, la variable de réponse est:yyyymmdd,Si l\'entrée est incorrecte, la voix jouant est anormale.)',
  'oifde.service.invoke.voiceVariable.multiVoice.price.desc': 'Variable vocale ou textuelle incluse dans la réponse. Une valeur doit être attribuée à cette variable avant que le noeud puisse être exécuté (pour un type de prix, la variable de réponse est dans le format de contenu réel de la variable de réponse est un texte numérique.Unité',
  'oifde.service.invoke.offsetType': ' Période de décalage',
  'oifde.service.invoke.offsetType.desc': ' Décalage par jour ou heure',
  'oifde.service.invoke.offsetType.month': 'Par mois',
  'oifde.service.invoke.offsetType.hour': ' Par heure',
  'oifde.service.invoke.sourceVariable': 'Chaîne de caractères source',
  'oifde.service.invoke.sourceVariable.desc': 'Chaîne de caractères source variable. Le format est aaaaMM si le décalage est par mois et est aaaaMMdHHmmss si le décalage est par heure. Exemple',
  'oifde.service.invoke.destVariable': 'Chaîne de caractères ciblée',
  'oifde.service.invoke.destVariable.desc': 'Variable de chaîne de caractères de cible utilisée pour enregistrer le résultat.',
  'oifde.service.invoke.offsetMonths': 'Nombre de compensations',
  'oifde.service.invoke.offsetMonths.desc': 'Nombre de décalages. La valeur est un entier positif ou négatif. Définissez ce paramètre en fonction de la période de décalage (offset par mois ou par heure). Par exemple, si Offset par mois est sélectionné, la valeur -6 indique que la chaîne de caractères source est ramenée à celle utilisée il y a six mois.',
  'oifde.service.invoke.keyBoardType': ' Type d\'entrée de clavier',
  'oifde.service.invoke.keyBoardType.desc': ' Type d\'entrée de clavier. La valeur peut être keyBoard_phone ou checkoldpassword ou être laissée vide.',
  'oifde.service.invoke.inputVariable': 'Variable',
  'oifde.service.invoke.inputVariable.desc': ' Variable pour enregistrer l\'entrée du clavier de l\'utilisateur.',
  'oifde.service.invoke.inputVariableTitle': 'Affectation de la variable',
  'oifde.service.invoke.inputVariableValue': 'Valeur de la variable',
  'oifde.service.invoke.conditionTitle': 'Branches conditionnelles',
  'oifde.service.invoke.branchGroup.timeout': 'Délai d\'expiration',
  'oifde.service.invoke.branchGroup.nomatch': 'efus d\'être reconnu',
  'oifde.service.invoke.branchGroup.error': 'erreur',
  'oifde.service.invoke.branchGroup.silentAgent': 'Agent silencieux',
  'oifde.service.invoke.targetFlowCode': 'Code de flux cible',
  'oifde.service.invoke.targetNodeId': 'Identifiant du nœud cible',
  'oifde.service.invoke.voiceType.TTSVoice': 'TTS',
  'oifde.service.invoke.voiceType.video': 'Vidéo',
  'oifde.service.invoke.smsTemplateCode': 'Modèle .SMS',
  'oifde.service.invoke.smsContent': 'Contenu .SMS',
  'oifde.service.invoke.smsContent.desc': 'Contenu .SMS.',
  'oifde.service.invoke.modelType.voiceBreak': ' Reconnaissance d\'interruption',
  'oifde.service.invoke.modelType.noVoiceBreak': 'Playback seulement',
  'oifde.service.invoke.modelType.keyboardBreak': 'Interruption par des pressions sur les touches',
  'oifde.service.invoke.modelType.voiceAndKeyboard': 'Reconnaissance et pressions sur les touches',
  'oifde.service.invoke.modelType.voiceAndNobreak': ' Reconnaissance après la lecture',
  'oifde.service.invoke.modelType.voiceAndInputAndNobreak': ' Reconnaissance et touches presses après la lecture',
  'oifde.service.invoke.KeyboardInputRegExp.MobileAll': 'Validate format de numéro de mobile (avec # et *)',
  'oifde.service.invoke.KeyboardInputRegExp.Mobile': 'Validate format de numéro de mobile (sans # ou *)',
  'oifde.service.invoke.KeyboardInputRegExp.PasswordAll': 'Validate format de mot de passe (avec # et *)',
  'oifde.service.invoke.KeyboardInputRegExp.PasswordStar': 'Validate format de mot de passe (avec *)',
  'oifde.service.invoke.KeyboardInputRegExp.NumberAll': 'Valider le numéro de téléphone mobile (avec # et *)',
  'oifde.service.invoke.KeyboardInputRegExp.NumberStar': ' Valider le numéro de téléphone mobile (avec *)',
  'oifde.service.invoke.KeyboardInputRegExp.Month': ' Valider l\'entrée du mois',
  'oifde.service.invoke.context': 'Contexte',
  'oifde.service.invoke.context.desc': 'Informations contextuelles transférées au TUC.',
  'oifde.service.invoke.vendorParam': 'Paramètre étendu',
  'oifde.service.invoke.vendorParam.desc': 'Paramètre de reconnaissance vocale étendu renvoyé à l\'IVR.',
  'oifde.service.invoke.input': 'Contenu de reconnaissance sémantique',
  'oifde.service.invoke.input.desc': 'Contenu de reconnaissance sémantique transféré au TUC. Le contenu peut être une variable. Si ce champ est vide, le contenu saisi par l\'utilisateur est transféré au TUC.',
  'oifde.service.invoke.event': 'Événement',
  'oifde.service.invoke.event.desc': 'Evénement envoyé au TUC.',
  'oifde.service.invoke.event.cleanup': 'Effacer le cache de session',
  'oifde.service.invoke.event.cleanCurrent': 'Effacer le cache du contexte actuel',
  'oifde.service.invoke.procedureCode': 'Procédure stockée',
  'oifde.service.invoke.complexcellgroupin.name': 'Paramètres d\'entrée',
  'oifde.service.invoke.complexcellgroupin.desc': 'Paramètres d\'entrée.',
  'oifde.service.invoke.complexcellgroupout.name': 'Paramètres de sortie',
  'oifde.service.invoke.complexcellgroupout.desc': 'Paramètres de sortie.',
  'oifde.service.invoke.targetIntentCount': 'Nombre d\'intentions',
  'oifde.service.invoke.targetIntentCount.desc': 'Nombre maximum d\'intentions retournées par le TUC.',
  'oifde.service.invoke.confidence2Top': ' Intervalle de confiance',
  'oifde.service.invoke.confidence2Top.desc': 'Intervalle entre la confiance des intentions multiples et celle de la première intention lorsque plusieurs intentions doivent être retournées.',
  'oifde.service.invoke.index.desc': 'Compteur.',
  'oifde.service.invoke.index': 'Compteur',
  'oifde.service.invoke.oblist.desc': 'Liste des boucles.',
  'oifde.service.invoke.oblist': 'Boucles',
  'oifde.service.invoke.object.desc': 'Loop objet.',
  'oifde.service.invoke.object': 'Objet de boucle',
  'oifde.service.invoke.voiceType.multi-media': 'Multimédia',
  'oifde.service.invoke.modelType.inputAndNobreak': 'Pas d\'interruption après la collecte de chiffres',
  'oifde.service.invoke.maxCollectLength': 'Nombre de chiffres collectés',
  'oifde.service.invoke.maxCollectLength.desc': 'Définir le nombre maximum de chiffres collectés.',
  'oifde.service.invoke.collectTimeout': 'Délai de collecte des chiffres',
  'oifde.service.invoke.collectTimeout.desc': ' Définit l\'intervalle de temporisation de la collecte des chiffres. Les variables FLOW et GLOBAL sont prises en charge. La valeur peut aussi être un entier compris entre 1 et 120. Si la valeur ne répond pas aux exigences, la valeur par défaut 5 est utilisée.',
  'oifde.service.invoke.cancelCollectFlag': 'Annuler la clé',
  'oifde.service.invoke.cancelCollectFlag.yes': 'Utiliser la touche étoile (*)',
  'oifde.service.invoke.cancelCollectFlag.no': 'Ne pas utiliser la touche étoile (*)',
  'oifde.service.invoke.confirmCollectFlag': ' Confirmer la clé',
  'oifde.service.invoke.confirmCollectFlag.yes': 'Utilisez la clé (#)',
  'oifde.service.invoke.confirmCollectFlag.no': ' Ne pas utiliser la clé (#)',
  'oifde.service.invoke.ttsAdvancedSetting': 'TTS Paramètres avancés',
  'oifde.service.invoke.ttsAdvancedSetting.desc': 'S\'il faut activer ces paramètres pour spécifier la lecture vocale basée sur TTS.',
  'oifde.service.invoke.ttsAdvancedSetting.open': 'Activer',
  'oifde.service.invoke.ttsAdvancedSetting.close': 'Désactiver',
  'oifde.service.invoke.ttsSoundEffect': 'Voix',
  'oifde.service.invoke.ttsSoundEffect.desc': 'Sélectionnez si vous souhaitez utiliser une voix masculine ou féminine pour la lecture vocale basée sur TTS.',
  'oifde.service.invoke.ttsSoundEffect.male': 'Mâle',
  'oifde.service.invoke.ttsSoundEffect.female': 'Femme',
  'oifde.service.invoke.ttsSoundSpeed': 'Vitesse vocale',
  'oifde.service.invoke.ttsSoundSpeed.desc': 'Sélectionnez la vitesse à laquelle la lecture vocale basée sur TTS est effectuée.',
  'oifde.service.invoke.ttsSoundSpeed.low': 'Lenteur',
  'oifde.service.invoke.ttsSoundSpeed.normal': 'Moyen',
  'oifde.service.invoke.ttsSoundSpeed.high': 'Rapide',
  'oifde.service.invoke.ttsMRCPVendorSetting.open': 'Activer',
  'oifde.service.invoke.ttsMRCPVendorSetting.close': 'Désactiver',
  'oifde.service.invoke.ttsMRCPVendorSetting': 'Valeurs par défaut pour les paramètres TTS étendus.',
  'oifde.service.invoke.ttsMRCPVendorSetting.desc': 'Après que les valeurs par défaut pour les paramètres TTS étendus sont activées, les définitions écraseront l\'effet sonore et la vitesse du son sélectionnés.',
  'oifde.service.invoke.ttsMRCPVendor': 'Paramètre étendu de .TTS',
  'oifde.service.invoke.ttsMRCPVendor.desc': 'Paramètres du serveur TTS étendus. Définissez les paramètres en fonction des exigences du fournisseur du serveur TTS. Un exemple est speed=GLOBAL.ttsSpeed;pitch=GLOBAL.ttsPitch;volume=GLOBAL.ttsVolume;voice_name=GLOBAL.voiceName;. Les variables GLOBAL et FLOW sont prises en charge. Si une valeur variable ne se trouve pas dans la plage standard, la valeur par défaut est utilisée. La plage de valeurs de vitesse est [-500 500] et la valeur par défaut est 0. La plage de valeurs du volume est [0 100] et la valeur par défaut est 50. La plage de valeurs de hauteur est [-500 500] et la valeur par défaut est 0. La valeur par défaut de voice_name est cn_kefuman_common.',
  'oifde.service.invoke.asrAdvancedSetting': 'ASR Paramètres avancés',
  'oifde.service.invoke.asrAdvancedSetting.desc': 'S\'il faut activer ces paramètres pour spécifier la reconnaissance ASR.',
  'oifde.service.invoke.asrAdvancedSetting.open': 'Activer',
  'oifde.service.invoke.asrAdvancedSetting.close': 'Désactiver',
  'oifde.service.invoke.asrRecognizeType': 'Type de reconnaissance',
  'oifde.service.invoke.asrRecognizeType.desc': 'Type d\'identification .ASR. Seule l\'identification commune est actuellement prise en charge.',
  'oifde.service.invoke.asrRecognizeType.normal': 'Commun',
  'oifde.service.invoke.asrRecognizeType.intelligent': 'Intelligent',
  'oifde.service.invoke.asrNoInputTimeout': 'Intervalle de délai d\'expiration du silence de l\'abonné',
  'oifde.service.invoke.asrNoInputTimeout.desc': 'Intervalle de temps d\'attente lorsqu\'un abonné ne parle pas pendant la reconnaissance ASR en secondes. La plage de valeurs est de 0 à 32000. La valeur par défaut est 100.',
  'oifde.service.invoke.asrRecognitionTimeout': 'Intervalle de délai d\'expiration de reconnaissance',
  'oifde.service.invoke.asrRecognitionTimeout.desc': 'Intervalle lorsque la reconnaissance ASR se termine en secondes. La plage de valeurs est de 0 à 600. La valeur par défaut est 200.',
  'oifde.service.invoke.asrSpeechCompleteTimeout': 'Intervalle de délai de pause de l\'abonné',
  'oifde.service.invoke.asrSpeechCompleteTimeout.desc': ' Intervalle de temps d\'attente lorsqu\'un abonné fait une pause pendant la reconnaissance ASR en secondes. La plage de valeurs est de 300 à 2000. La valeur par défaut est 500.',
  'oifde.service.invoke.asrMRCPVendor': 'Paramètre étendu .ASR',
  'oifde.service.invoke.asrMRCPVendor.desc': 'Paramètre étendu du serveur ASR. Définissez ce paramètre en fonction des exigences du fournisseur.',
  'oifde.service.invoke.voiceType.card': 'Carte',
  'oifde.service.invoke.operationType': 'Type d\'opération',
  'oifde.service.invoke.operationType.open': ' Commencer l\'enregistrement',
  'oifde.service.invoke.operationType.close': ' Arrêter l\'enregistrement',
  'oifde.service.invoke.processRecord': ' Enregistrement complet',
  'oifde.service.invoke.processRecord.open': 'Oui',
  'oifde.service.invoke.processRecord.close': 'Non',
  'oifde.service.invoke.recordType': 'Type',
  'oifde.service.invoke.recordType.audio': 'Audio seulement',
  'oifde.service.invoke.recordType.video': 'Audio et vidéo',
  'oifde.yugaoRecordService.displayName': 'VoiceCyber Enregistrement',
  'oifde.yugaoRecordService.description': 'VoiceCyber Enregistrement (voiceCyberRecordService)',
  'oifde.yugaoRecordService.keywords': 'VoiceCyber Enregistrement',
  'oifde.jsonDataProcessService.displayName': 'Invocation de données .JSON',
  'oifde.jsonDataProcessService.description': 'Appel de données .JSON (jsonDataProcessService).',
  'oifde.jsonDataProcessService.keywords': 'Invocation de données .JSON',
  'oifde.timeSelectService.displayName': 'Sélection de l\'heure',
  'oifde.timeSelectService.description': 'Sélection de l\'heure (timeSelectService).',
  'oifde.timeSelectService.keywords': 'Sélection de l\'heure',
  'oifde.queryMessageService.displayName': 'Requête d\'informations',
  'oifde.queryMessageService.description': 'Requête d\'information(queryMessageService).',
  'oifde.queryMessageService.keywords': 'Requête d\'informations',
  'oifde.service.invoke.recordMode': 'Mode',
  'oifde.service.invoke.recordMode.onlyuser': 'Enregistrer uniquement l\'abonné',
  'oifde.service.invoke.recordMode.onlyivr': 'Enregistrer seulement IVR',
  'oifde.service.invoke.recordMode.all': 'Enregistrer l\'abonné et l\'IVR',
  'oifde.service.invoke.timeSelect.NonWorkTime': ' Branche de temps non spécifiée',
  'oifde.service.invoke.timeSelect.weekParameter': 'Sélection de la semaine',
  'oifde.service.invoke.timeSelect.serviceTimeParameter': 'Période de service',
  'oifde.service.invoke.timeSelect.specificWorkDateParameter': 'Date de service spécifiée',
  'oifde.service.invoke.timeSelect.specificNoWorkDateParameter': 'Date de non-service spécifiée',
  'oifde.service.invoke.timeSelect.serviceTimeParameter.desc': 'Période de service. Configurez 1 à 10 enregistrements.',
  'oifde.service.invoke.timeSelect.specificWorkDateParameter.desc': 'Un maximum de 50 enregistrements sont pris en charge.',
  'oifde.service.invoke.timeSelect.specificNoWorkDateParameter.desc': 'Date de non-travail spécifiée. Un maximum de 50 enregistrements sont pris en charge.',
  'oifde.service.invoke.timeSelect.specificWorkDateParameter.title': 'Si une date de travail spécifiée configurée n\'est pas disponible dans la sélection de la semaine, la date de service est considérée comme un jour ouvrable.',
  'oifde.service.invoke.timeSelect.specificNoWorkDateParameter.title': 'Si une date de non-travail spécifiée est configurée, la date de non-service est considérée comme un jour non-ouvrable avec la priorité la plus élevée.',
  'oifde.service.invoke.workdays.Monday': 'Lundi',
  'oifde.service.invoke.workdays.Tuesday': 'Mardi',
  'oifde.service.invoke.workdays.Wednesday': 'Mercredi',
  'oifde.service.invoke.workdays.Thursday': 'Jeudi',
  'oifde.service.invoke.workdays.Friday': 'Vendredi',
  'oifde.service.invoke.workdays.Saturday': 'Samedi',
  'oifde.service.invoke.workdays.Sunday': 'Dimanche',
  'oifde.service.interfaceType.selfDefinedInterface': 'interface définie par soi-même',
  'oifde.service.interfaceType.innerInterface': 'Interface .inner',
  'oifde.qarecord.interface.name': 'Enregistrer Q & R',
  'oifde.qarecord.interface.desc': 'Enregistre l\'intention de réponse de l\'utilisateur et la réponse originale de l\'utilisateur de titre de question d\'ID du questionnaire.',
  'oifde.apiToken.interface.name':'Obtenir le jeton APIFabric',
  'oifde.apiToken.interface.desc':'Interface utilisée pour obtenir le jeton API Fabric',
  'oifde.apiToken.applyType.desc': 'Token prefix',
  'oifde.apiToken.accessToken.desc': 'Contenu du token',
  'oifde.apiToken.appKey.desc': 'App Key',
  'oifde.apiToken.appSecret.desc': 'Secret d\'application',
  'oifde.qarecord.inputparam.seriesId': 'ID de question et de réponse',
  'oifde.qarecord.inputparam.questionTitle': 'Titre de la question',
  'oifde.qarecord.inputparam.questionType': 'Type de question',
  'oifde.qarecord.inputparam.answerIntent': 'Intention de réponse',
  'oifde.qarecord.inputparam.originalAnswer': 'Réponse originale',
  'oifde.service.invoke.queryMsg.MessageQueryType': 'Type de requête',
  'oifde.service.invoke.queryMsg.MessageQueryType.desc': 'Scénario d\'application d\'information de file d\'attente',
  'oifde.service.invoke.queryMsg.skillMsg': 'Informations sur la file d\'attente',
  'oifde.service.invoke.queryMsg.skillInfo': 'Informations sur la file d\'attente',
  'oifde.service.invoke.queryMsg.specifiedSkill': 'Nom de la file d\'attente',
  'oifde.service.invoke.queryMsg.specialListQuery': 'Informations sur la liste spéciale',
  'oifde.service.invoke.queryMsg.result': 'Résultat de la requête',
  'oifde.service.invoke.queryMsg.MessageQueryQueueIndex': 'Position de la file d\'attente',
  'oifde.service.invoke.queryMsg.MessageQueryQueueNumber': 'Nombre de files d\'attente',
  'oifde.service.invoke.queryMsg.MessageQueryOnlineNum': 'Nombre d\'agents en ligne',
  'oifde.service.invoke.queryMsg.MessageIdleAgentNum': 'Nombre d\'agents inactifs',
  'oifde.service.invoke.queryMsg.MessageQueryWaitingTime': ' Temps d\'attente estimé',
  'oifde.service.invoke.queryMsg.MessageQueryWaitingTime.desc': 'Le temps d\'attente attendu de la mise en file d\'attente est calculé sur la base du modèle de file d\'attente. Le résultat est différent. Veuillez l\'utiliser correctement.',
  'oifde.service.invoke.queryMsg.params': 'Paramètre de demande',
  'oifde.service.invoke.queryMsg.skillQueue': 'Queue de compétences',
  'oifde.service.invoke.queryMsg.agentId': 'ID d\'agent',
  'oifde.service.invoke.queryMsg.specialList': 'Identifiant dans la liste spéciale',
  'oifde.service.invoke.breakMinTime': 'Durée minimale de jeu de voix',
  'oifde.service.invoke.modelType.customization': ' Valeur variable personnalisée',
  'oifde.service.invoke.voiceTtsMixed': 'Voix et texte',
  'oifde.service.invoke.ttsSoundEffectCust': 'Effet sonore personnalisé',
  'oifde.service.invoke.ttsSoundSpeedCust': 'Vitesse personnalisée',
  'oifde.service.invoke.modelType.customization.desc': 'L\'interruption indique si elle peut être interrompue. Si elle peut être interrompue, remplissez la case de saisie du temps minimum de lecture. Les variables sont remplies avec true ou false, true indique qu\'elles peuvent être reconnues et cassées, et le temps minimum de lecture peut être dépassé. False peut être reconnu mais pas cassé, et le remplissage d\'erreurs est la valeur par défaut de false.',
  'oifde.service.invoke.CallTransferType.transferToSkillQueueByRoutingCode': 'File d\'attente de compétences (code d\'extension de route)',
  'oifde.service.invoke.CallTransferType.transferToIVR': 'IVR',
  'oifde.service.invoke.CallTransferType.transferToSkillQueueBySkillName': 'File d\'attente de compétences (nom)',
  'oifde.service.invoke.CallTransferType.transferToAgentByID': 'Agent',
  'oifde.service.invoke.CallTransferType.transferToThirdPartyNumber': 'Numéro de tiers',
  'oifde.service.invoke.CallTransferType.onlineDigitalAssistant': 'assistant numérique en ligne',
  'oifde.service.invoke.CallTransferType.transferToLastAgent': 'Invités familiers multimédias',
  'oifde.service.invoke.CallTransferType.transferToSkillQueueBelongAgent': 'File d\'attente de compétences (agent multimédia)',
  'oifde.service.invoke.CallTransferType.transferToSkillQueueByRouteConfig': 'Transfert vers Skill Queue (itinéraire personnalisé)',
  'oifde.service.invoke.CallTransferType.transferToMultimediaMessageToLastAgent': 'Messages d\'invités familiers',
  'oifde.service.invoke.CallTransferType.transferToMultimediaMessageToSkillQueue': 'Message file d\'attente compétences(nom ou code)',
  'oifde.service.invoke.CallTransferType.transferToMultimediaMessageToAgent': 'Message de l\'agent',
  'oifde.service.invoke.CallTransferTypeGroup.multimediaMessage': 'Message multimédia',
  'oifde.service.invoke.CallTransferRecordFlag': 'Activer l\'enregistrement',
  'oifde.service.invoke.CallTransferRecordFlag.desc': '',
  'oifde.service.invoke.recordPath': 'Chemin d\'enregistrement',
  'oifde.service.invoke.recordPath.desc': 'Entrez la variable pour stocker le nom du fichier d\'enregistrement.',
  'oifde.service.invoke.recordServiceNodeID': 'ID du nœud de service d\'enregistrement',
  'oifde.service.invoke.recordServiceNodeID.desc': 'Entrez la variable qui stocke l\'ID du nœud de service d\'enregistrement (utilisé dans le mode pool de centre d\'appels).',
  'oifde.service.invoke.languageType.language0': 'Putonghua',
  'oifde.service.invoke.languageType.language1': 'Français',
  'oifde.service.invoke.languageType.language2': 'Chinois (cantonais)',
  'oifde.service.invoke.languageType.language3': 'Tchèque',
  'oifde.service.invoke.languageType.language4': 'Danois',
  'oifde.service.invoke.languageType.language5': 'Néerlandais',
  'oifde.service.invoke.languageType.language6': 'finnois',
  'oifde.service.invoke.languageType.language7': 'Français',
  'oifde.service.invoke.languageType.language8': 'Allemand',
  'oifde.service.invoke.languageType.language9': 'Grec',
  'oifde.service.invoke.languageType.language10': 'Hongrois',
  'oifde.service.invoke.languageType.language11': 'Islande',
  'oifde.service.invoke.languageType.language12': 'Italien',
  'oifde.service.invoke.languageType.language13': 'Japonais',
  'oifde.service.invoke.languageType.language14': 'Coréen',
  'oifde.service.invoke.languageType.language15': 'Norvégien',
  'oifde.service.invoke.languageType.language16': 'Polonais',
  'oifde.service.invoke.languageType.language17': 'Portugais',
  'oifde.service.invoke.languageType.language18': 'Russe',
  'oifde.service.invoke.languageType.language19': 'Slovaque',
  'oifde.service.invoke.languageType.language20': 'Espagnol',
  'oifde.service.invoke.languageType.language21': 'Suédois',
  'oifde.service.invoke.languageType.language22': 'Turc',
  'oifde.service.invoke.languageType.language23': 'Ouzbékistan',
  'oifde.service.invoke.languageType.language24': 'Thaïlande',
  'oifde.service.invoke.languageType.language80': 'Kiswahili',
  'oifde.service.invoke.languageType.language81': 'Arabe',
  'oifde.service.invoke.languageType.language82': 'Hindi',
  'oifde.service.invoke.languageType.language83': 'Filipino',
  'oifde.service.invoke.languageType.language84': 'Cambodgien',
  'oifde.service.invoke.languageType.language85': 'Bengali',
  'oifde.service.invoke.languageType.language86': 'Palestinien',
  'oifde.service.invoke.languageType.language87': 'Vietnamien',
  'oifde.service.invoke.languageType.language88': 'Indonésien',
  'oifde.service.invoke.languageType.language89': 'Nepali',
  'oifde.service.invoke.languageType.language90': 'Malagasy',
  'oifde.service.invoke.languageType.language92': 'Ethiopique',
  'oifde.service.invoke.languageType.language93': 'Malaisien',
  'oifde.service.invoke.variableType.type1': 'Numéro de téléphone',
  'oifde.service.invoke.variableType.type3': 'Numéro',
  'oifde.service.invoke.variableType.type4': 'time(hh',
  'oifde.service.invoke.variableType.type5': 'Date(aaaammjj)',
  'oifde.service.invoke.variableType.type6': 'Prix',
  'oifde.service.invoke.voiceType.staticVoice': 'Lecture d\'annonces statiques',
  'oifde.service.invokemethod.group.desc': 'Appel de méthode',
  'oifde.service.invokemethod.group.displayName': 'Appel de méthode',
  'oifde.service.invokemethod.group.value1Title': 'Retour à l\'objet',
  'oifde.service.invokemethod.group.value2Title': 'Nom de la méthode',
  'oifde.service.invokemethod.group.value3Title': 'Paramètres de la méthode',
  'oifde.service.invokemethod.group.value1Tips': 'Objet de réception de valeur de retour de méthode',
  'oifde.service.invokemethod.group.value2Tips': 'Veuillez sélectionner un nom de méthode.',
  'oifde.service.invokemethod.group.value3Tips': 'Paramètre de la méthode 1',
  'oifde.service.invokemethod.group.value4Tips': 'Paramètre de la méthode 2',
  'oifde.service.invokemethod.group.value5Tips': 'Paramètre de la méthode 3',
  'oifde.service.invokemethod.group.mathodType1': 'Méthode d\'opération de chaîne',
  'oifde.service.invokemethod.group.mathodType2': 'Méthodes d\'opération de tableau',
  'oifde.service.invokemethod.group.mathodType3': 'Fonctionnement de la carte',
  'oifde.service.invokemethod.strSplit.desc': 'Séparation d\'une chaîne en fonction d\'un séparateur donné',
  'oifde.service.invokemethod.strSubstring.desc': 'Extraire les sous-chaînes',
  'oifde.service.invokemethod.strIndexOf.desc': 'Récupère l\'indice de la première occurrence d\'une chaîne spécifiée.',
  'oifde.service.invokemethod.strStartsWith.desc': 'Détecte si la chaîne commence par le préfixe spécifié.',
  'oifde.service.invokemethod.strEndsWith.desc': 'Détecte si la chaîne se termine par le suffixe spécifié.',
  'oifde.service.invokemethod.strParseJSON.desc': 'Convertit une chaîne JSON en un objet.',
  'oifde.service.invokemethod.strParseInt.desc': 'Convertir une chaîne de caractères en nombres entiers',
  'oifde.service.invokemethod.strParseFloat.desc': 'Convertir une chaîne en nombre à virgule flottante',
  'oifde.service.invokemethod.strLength.desc': 'Longueur de la chaîne',
  'oifde.service.invokemethod.strToUpperCase.desc': 'Convertit une chaîne de caractères minuscules en majuscules',
  'oifde.service.invokemethod.strToLowerCase.desc': 'Convertit une chaîne de caractères majuscules en minuscules',
  'oifde.service.invokemethod.arrayAdd.desc': 'Ajouter un élément à un tableau',
  'oifde.service.invokemethod.arrayAddAll.desc': 'Ajouter tous les éléments d\'une autre collection au tableau',
  'oifde.service.invokemethod.arrayContains.desc': 'Déterminer si un élément est dans un tableau',
  'oifde.service.invokemethod.arrayGet.desc': 'Obtient les éléments d\'un tableau à l\'aide de la valeur d\'index.',
  'oifde.service.invokemethod.arrayRemoveIdx.desc': 'Supprime l\'élément d\'indice spécifié d\'un tableau.',
  'oifde.service.invokemethod.arrayRemoveAllIdx.desc': 'Supprime les éléments en indice du tableau qui existent dans la collection spécifiée',
  'oifde.service.invokemethod.arrayClear.desc': 'Supprime tous les éléments du tableau',
  'oifde.service.invokemethod.arraySize.desc': 'Obtenir la longueur du tableau',
  'oifde.service.invokemethod.arraySubList.desc': 'Intercepter et retourner une partie d\'un tableau',
  'oifde.service.invokemethod.arrayJoin.desc': 'Convertir un tableau en une chaîne de caractères',
  'oifde.service.invokemethod.mapPut.desc': 'Ajouter un élément à la carte',
  'oifde.service.invokemethod.mapGet.desc': 'Obtenir la valeur du mappage de clé spécifié dans la carte',
  'oifde.service.invokemethod.mapRemove.desc': 'Supprime la paire clé-valeur spécifiée de la carte',
  'oifde.service.invokemethod.mapClear.desc': 'Effacer toutes les paires clé-valeur de la carte',
  'oifde.service.invokemethod.mapSize.desc': 'Obtenir le nombre de toutes les clés de la carte',
  'oifde.service.invokemethod.strSplit.parm1.desc': 'Chaîne de caractères à diviser',
  'oifde.service.invokemethod.strSplit.parm2.desc': 'séparateur',
  'oifde.service.invokemethod.strSubstring.parm1.desc': 'Chaîne de caractères à tronquer',
  'oifde.service.invokemethod.strSubstring.parm2.desc': 'Indice de départ (entier)',
  'oifde.service.invokemethod.strSubstring.parm3.desc': 'Indice de fin (entier)',
  'oifde.service.invokemethod.strIndexOf.parm1.desc': 'Chaîne de caractères à rechercher',
  'oifde.service.invokemethod.strIndexOf.parm2.desc': 'Chaîne de caractères spécifiée',
  'oifde.service.invokemethod.strStartsWith.parm1.desc': 'Chaîne de caractères à vérifier',
  'oifde.service.invokemethod.strStartsWith.parm2.desc': 'Chaîne de préfixe spécifiée',
  'oifde.service.invokemethod.strEndsWith.parm1.desc': 'Chaîne de caractères à détecter',
  'oifde.service.invokemethod.strEndsWith.parm2.desc': 'Suffixe de chaîne spécifié',
  'oifde.service.invokemethod.strParseJSON.parm1.desc': 'Chaîne de caractères à convertir',
  'oifde.service.invokemethod.strParseInt.parm1.desc': 'Chaîne de caractères à convertir',
  'oifde.service.invokemethod.strParseFloat.parm1.desc': 'Chaîne de caractères à convertir',
  'oifde.service.invokemethod.strLength.parm1.desc': 'Chaîne de caractères à calculer',
  'oifde.service.invokemethod.strToUpperCase.parm1.desc': 'Chaîne de caractères à convertir',
  'oifde.service.invokemethod.strToLowerCase.parm1.desc': 'Chaîne de caractères à convertir',
  'oifde.service.invokemethod.arrayAdd.parm1.desc': 'Array à ajouter (Cette méthode modifiera le tableau à ajouter)',
  'oifde.service.invokemethod.arrayAdd.parm2.desc': 'Position ajoutée (queue par défaut)',
  'oifde.service.invokemethod.arrayAdd.parm3.desc': 'Éléments ajoutés',
  'oifde.service.invokemethod.arrayAddAll.parm1.desc': 'Array à ajouter (Cette méthode modifiera le tableau à ajouter)',
  'oifde.service.invokemethod.arrayAddAll.parm2.desc': 'Position ajoutée (queue par défaut)',
  'oifde.service.invokemethod.arrayAddAll.parm3.desc': 'Éléments ajoutés',
  'oifde.service.invokemethod.arrayContains.parm1.desc': 'Array à vérifier',
  'oifde.service.invokemethod.arrayContains.parm2.desc': 'Éléments à déterminer',
  'oifde.service.invokemethod.arrayGet.parm1.desc': 'Array à utiliser',
  'oifde.service.invokemethod.arrayGet.parm2.desc': 'indice de tableau',
  'oifde.service.invokemethod.arrayRemoveIdx.parm1.desc': 'Array à utiliser',
  'oifde.service.invokemethod.arrayRemoveIdx.parm2.desc': 'Supprimer l\'indice dutableau',
  'oifde.service.invokemethod.arrayRemoveAllIdx.parm1.desc': 'Array à utiliser',
  'oifde.service.invokemethod.arrayRemoveAllIdx.parm2.desc': 'Spécifier la collection d\'indices detableau',
  'oifde.service.invokemethod.arrayClear.parm1.desc': 'Array à nettoyer',
  'oifde.service.invokemethod.arraySize.parm1.desc': 'Le tableau dont la longueur doit être calculée',
  'oifde.service.invokemethod.arraySubList.parm1.desc': 'Array à tronquer',
  'oifde.service.invokemethod.arraySubList.parm2.desc': 'Position de départ de l\'interception',
  'oifde.service.invokemethod.arraySubList.parm3.desc': 'Position de fin d\'interception',
  'oifde.service.invokemethod.arrayJoin.parm1.desc': 'Array à convertir',
  'oifde.service.invokemethod.arrayJoin.parm2.desc': 'Chaîne de connexion',
  'oifde.service.invokemethod.mapPut.parm1.desc': 'La carte à ajouter (Cette méthode modifiera la carte à ajouter)',
  'oifde.service.invokemethod.mapPut.parm2.desc': 'clé (seul le type de chaîne est pris en charge)',
  'oifde.service.invokemethod.mapPut.parm3.desc': 'Éléments ajoutés',
  'oifde.service.invokemethod.mapGet.parm1.desc': 'Carte à traiter',
  'oifde.service.invokemethod.mapGet.parm2.desc': 'clé (seul le type de chaîne est pris en charge)',
  'oifde.service.invokemethod.mapRemove.parm1.desc': 'Carte à traiter',
  'oifde.service.invokemethod.mapRemove.parm2.desc': 'clé (seul le type de chaîne est pris en charge)',
  'oifde.service.invokemethod.mapClear.parm1.desc': 'Map à nettoyer',
  'oifde.service.invokemethod.mapSize.parm1.desc': 'Carte de la quantité à calculer',
  'oifde.service.invokemethod.strSplit.resultObj.desc': 'L\'objet retourné est un tableau, qui stocke le tableau de chaînes de caractères fractionnées.',
  'oifde.service.invokemethod.strSubstring.resultObj.desc': 'L\'objet retourné est une chaîne de caractères et est une sous-chaîne tronquée.',
  'oifde.service.invokemethod.strIndexOf.resultObj.desc': 'L\'objet retourné est un indice et est un entier.',
  'oifde.service.invokemethod.strStartsWith.resultObj.desc': 'L\'objet retourné est le résultat de la détection. La valeur est un entier 0/1.',
  'oifde.service.invokemethod.strEndsWith.resultObj.desc': 'L\'objet retourné est le résultat de la détection. La valeur est un entier 0/1.',
  'oifde.service.invokemethod.strParseJSON.resultObj.desc': 'L\'objet retourné est l\'objet JSON converti.',
  'oifde.service.invokemethod.strParseInt.resultObj.desc': 'L\'objet retourné est un entier après la conversion.',
  'oifde.service.invokemethod.strParseFloat.resultObj.desc': 'L\'objet retourné est un nombre à virgule flottante après la conversion.',
  'oifde.service.invokemethod.strLength.resultObj.desc': 'L\'objet retourné est la longueur d\'une chaînede caractères,qui estun entier.',
  'oifde.service.invokemethod.strToUpperCase.resultObj.desc': 'L\'objet retourné est une chaîne de caractères convertie.',
  'oifde.service.invokemethod.strToLowerCase.resultObj.desc': 'L\'objet retourné est une chaîne de caractères convertie.',
  'oifde.service.invokemethod.arrayAdd.resultObj.desc': 'L\'objet retourné est le résultat de l\'ajout. Si l\'ajout réussit, 1 est retourné. Sinon, 0 est retourné.',
  'oifde.service.invokemethod.arrayAddAll.resultObj.desc': 'L\'objet retourné est le résultat de l\'ajout. Si l\'ajout réussit, 1 est retourné. Sinon, 0 est retourné.',
  'oifde.service.invokemethod.arrayContains.resultObj.desc': 'L\'objet retourné est le résultat de la détermination. La valeur est un entier 0/1.',
  'oifde.service.invokemethod.arrayGet.resultObj.desc': 'Renvoie un élément dont l\'objet est un tableau.',
  'oifde.service.invokemethod.arrayRemoveIdx.resultObj.desc': 'L\'objet retourné est le résultat de la suppression. Si la suppression est réussie, l\'élément correspondant à l\'indice est retourné.',
  'oifde.service.invokemethod.arrayRemoveAllIdx.resultObj.desc': 'L\'objet retourné est le résultat de la suppression. Si la suppression est réussie, 1 est retourné. Sinon, 0 est retourné.',
  'oifde.service.invokemethod.arrayClear.resultObj.desc': 'L\'objet retourné est vide. La zone de texte est grisée et ne peut pas être modifiée.',
  'oifde.service.invokemethod.arraySize.resultObj.desc': 'L\'objet retourné est une longueur de tableau et est un entier.',
  'oifde.service.invokemethod.arraySubList.resultObj.desc': 'L\'objet retourné est un sous-réseau.',
  'oifde.service.invokemethod.arrayJoin.resultObj.desc': 'L\'objet retourné est une chaîne de caractères convertie.',
  'oifde.service.invokemethod.mapPut.resultObj.desc': 'La logique de l\'objet retourné est que si la clé n\'existe pas, null est retourné. Si la clé existe, la valeur originale remplacée est retournée.',
  'oifde.service.invokemethod.mapGet.resultObj.desc': 'L\'objet retourne la valeur du mappage de clé spécifié.',
  'oifde.service.invokemethod.mapRemove.resultObj.desc': 'Return Object: cette méthode renvoie la valeur précédemment associée à cette clé; Renvoie null si le mappage ne contient pas de mappage pour la clé.',
  'oifde.service.invokemethod.mapClear.resultObj.desc': 'L\'objet retourné est vide. La zone de texte est grisée et ne peut pas être modifiée.',
  'oifde.service.invokemethod.mapSize.resultObj.desc': 'L\'objet retourné est le nombre de clés dans la carte. La valeur est un entier.',
  'oifde.ringService.displayName': 'Sonnerie',
  'oifde.assignValueService.displayName': 'Attribuer',
  'oifde.transferService.displayName': 'Transfert',
  'oifde.service.inpytOrDrag': 'saisir ou faire glisser des variables',
  'oifde.service.operateAsFunction': 'Fonctionner en tant que méthode fournie',
  'oifde.basicOdfsSubFlowService.displayName': 'Sous-processus (intelligent)',
  'oifde.basicCcivrSubFlowService.displayName': 'Sous-processus (commun)',
  'oifde.yugaoRecordServiceNew.displayName': 'Enregistrement de la collection',
  'oifde.conditionServiceNew.displayName': 'Détermination de la branche',
  'oifde.service.invoke.callTranseferService.transData.wait': 'File d\'attente',
  'oifde.service.invoke.callTranseferService.transData.data': 'Transférer les données',
  'oifde.service.invoke.callTranseferService.transData.isSpecifyAccessCode': 'Spécifiez le code d\'accès du canal',
  'oifde.service.invoke.callTranseferService.transData.type': 'Mode client familier',
  'oifde.service.invoke.callTranseferService.transData.time': 'Plage de temps (minutes)',
  'oifde.service.invoke.callTranseferService.transData.timeTips': 'Par exemple, 100 est transféré à l\'agent qui a été contacté le plus récemment dans les 100 minutes. La plage de temps recommandée est de 1 à 10080.',
  'oifde.service.invoke.callTranseferService.transData.timeErrorTips': 'Saisissez un entier positif ou une variable',
  'oifde.service.invoke.callTranseferService.transData.starttime': 'Plage de dates',
  'oifde.service.invoke.callTranseferService.transData.starttimeTips': 'Agent(s) le plus récemment contacté(s) dans la plage de dates spécifiée',
  'oifde.service.invoke.callTranseferService.transData.type.selectTime': 'Spécifier les minutes',
  'oifde.service.invoke.callTranseferService.transData.type.selectStartTime': 'Spécifiez la date',
  'oifde.service.invoke.callTranseferService.transData.type.currentDay': 'Le jour même',
  'oifde.service.invoke.callTranseferService.transData.paramValueTips': "Entrez une valeur de paramètre. ",
  'oifde.service.invoke.callTranseferService.transData.varTips': "Les variables peuvent être saisies. Les variables globales commencent par GLOBAL, les variables de flux, les variables système commencent par SYS, les variables TUC commencent par TOC et les variables de requête IVR commencent par IVRREQUEST. Utilisez {'${}'} pour envelopper les variables, par exemple, {'${FLOW.variable}'}.",
  'oifde.service.invoke.ruleValidationService.name': 'Vérification des règles V2',
  'oifde.service.invoke.ruleValidationService.variable': 'Variables à vérifier',
  'oifde.service.invoke.ruleValidationService.specialEndKey': 'Variables à vérifier',
  'oifde.service.invoke.ruleValidationService.specialEndKey.option1': 'Avec #',
  'oifde.service.invoke.ruleValidationService.specialEndKey.option2': 'avec *',
  'oifde.service.invoke.ruleValidationService.specialEndKey.option3': 'Avec # ou *',
  'oifde.service.invoke.ruleValidationService.specialEndKey.option4': 'Sans clé spéciale',
  'oifde.service.invoke.ruleValidationService.containsChar': 'Contient des caractères',
  'oifde.service.invoke.ruleValidationService.containsChar.option1': 'Correspondance des chiffres',
  'oifde.service.invoke.ruleValidationService.containsChar.option2': 'Correspond à les lettres majuscules',
  'oifde.service.invoke.ruleValidationService.containsChar.option3': 'Correspond à les lettres minuscules',
  'oifde.service.invoke.ruleValidationService.containsChar.option4': 'Correspond à des lettres, des chiffres et des soulignements',
  'oifde.service.invoke.ruleValidationService.containsChar.option5': 'Caractère vide',
  'oifde.service.invoke.ruleValidationService.validationRules': 'Règle de vérification',
  'oifde.service.invoke.ruleValidationService.validationRules.ruleParam': 'Paramètres de la règle',
  'oifde.service.invoke.ruleValidationService.validationRules.ruleParamValueTips': "Vous pouvez saisir des variables. Les variables globales commencent par GLOBAL, les variables de processus par FLUX, les variables système par SYS, les variables TUC par TOC et les variables de requête IVR par IVRREQUEST. Exemple : Flow.variable",
  'oifde.service.invoke.ruleValidationService.validationRules.minLength': 'Longueur minimale',
  'oifde.service.invoke.ruleValidationService.validationRules.minLength.valueTips': 'Veuillez saisir la longueur minimale.',
  'oifde.service.invoke.ruleValidationService.validationRules.maxLength': 'Longueur maximale',
  'oifde.service.invoke.ruleValidationService.validationRules.maxLength.valueTips': 'Veuillez saisir la longueur maximale.',
  'oifde.service.invoke.ruleValidationService.validationRules.rangeLength': 'Plage de longueur',
  'oifde.service.invoke.ruleValidationService.validationRules.min': 'Valeur minimale',
  'oifde.service.invoke.ruleValidationService.validationRules.min.valueTips': 'Veuillez saisir la valeur minimale.',
  'oifde.service.invoke.ruleValidationService.validationRules.max': 'Valeur maximale',
  'oifde.service.invoke.ruleValidationService.validationRules.max.valueTips': 'Veuillez saisir la valeur maximale.',
  'oifde.service.invoke.ruleValidationService.validationRules.range': 'Plage de valeurs',
  'oifde.service.invoke.ruleValidationService.validationRules.email': 'Courriel',
  'oifde.service.invoke.ruleValidationService.validationRules.url': 'URL',
  'oifde.service.invoke.ruleValidationService.validationRules.dateFormat': 'Format de l\'heure',
  'oifde.service.invoke.ruleValidationService.validationRules.dateFormat.valueTips': 'Saisissez le format d\'heure, par exemple, date (yyyy-MM-dd) ou date-heure (yyyy-MM-dd HH:mm:ss).',
  'oifde.service.invoke.ruleValidationService.validationRules.number': 'Nombre décimal',
  'oifde.service.invoke.ruleValidationService.validationRules.digits': 'Entier positif',
  'oifde.service.invoke.ruleValidationService.validationRules.ip': 'Adresse IP',
  'oifde.service.invoke.ruleValidationService.validationRules.alpha': 'Soulignement alphanumérique (sans commencer par un chiffre)',
  'oifde.service.invoke.timeSelect.workDate': 'Spécifiez la date de travail',
  'oifde.service.invoke.timeSelect.noWorkDate': 'Spécifiez la date de non-travail',
  'oifde.service.invokemethod.offsetMonth.desc': 'offset par mois',
  'oifde.service.invokemethod.offsetMonth.parm1.desc': 'Variable temporelle à décaler, type de chaîne de caractères au format yyyMM',
  'oifde.service.invokemethod.offsetMonth.parm2.desc': 'Offset, qui est un entier et peut être une valeur négative',
  'oifde.service.invokemethod.offsetMonth.resultObj.desc': 'L\'objet retourné est les données après le décalage.',
  'oifde.service.invokemethod.offsetHour.desc': 'Décalage horaire',
  'oifde.service.invokemethod.offsetHour.parm1.desc': 'Variable temporelle à décaler. La valeur est une chaîne de caractères au format yyyMMddHHmmss',
  'oifde.service.invokemethod.offsetHour.parm2.desc': 'Offset, qui est un entier et peut être une valeur négative',
  'oifde.service.invokemethod.offsetHour.resultObj.desc': 'L\'objet retourné est les données décalées.',
  'oifde.interfaceInvoke.displayName': 'Invocation de l\'interface',
  'oifde.service.invoke.yugaoRecord.type': 'Type :',
  'oifde.service.invoke.yugaoRecord.mode': 'Motif :',
  'oifde.service.invoke.subflow.subFlowNode': 'Sous-processus',
  'oifde.service.invokemethod.group.mathodType4': 'Méthode d\'opération de date',
  'oifde.transdata.get.example': 'Par exemple, si les données associées sont {\'{\'}"data":{\'{\'}"test": "111"{\'}\'}{\'}\'}, vous pouvez entrer data.test dans la valeur de la variable pour obtenir les données "111".',
  'oifde.service.invoke.basicAvatarReplyService.name': 'Avatar Répondre',
  'oifde.service.invoke.basicAvatarReplyService.actionType': 'Opération',
  'oifde.service.invoke.basicAvatarReplyService.start': 'Initier',
  'oifde.service.invoke.basicAvatarReplyService.image': 'L\'image',
  'oifde.service.invoke.basicAvatarReplyService.avatarImage': 'Image d\'avatar',
  'oifde.service.invoke.basicAvatarReplyService.ttsSpeaker': 'Orateur',
  'oifde.service.invoke.basicAvatarReplyService.compositeVideoSetting': 'Configuration vidéo composite',
  'oifde.service.invoke.basicAvatarReplyService.compositeVideoSetting.desc': 'Configuration vidéo composite, qui affecte directement l\'effet d\'affichage de personne numérique. Activez cette fonction si nécessaire.',
  'oifde.service.invoke.basicAvatarReplyService.imageSetting': 'Paramètres de l\'image et de la vidéo',
  'oifde.service.invoke.basicAvatarReplyService.action': 'Actions',
  'oifde.service.invoke.basicAvatarReplyService.frontImage': 'Image de face',
  'oifde.service.invoke.basicAvatarReplyService.backendImage': 'Image de backend',
  'oifde.service.invoke.basicAvatarReplyService.frontVideo': 'Vidéo avant',
  'oifde.service.invoke.basicAvatarReplyService.backendVideo': 'Vidéo Backend',
  'oifde.service.invoke.basicAvatarReplyService.xPosition': 'Abscisse',
  'oifde.service.invoke.basicAvatarReplyService.yPosition': 'Ordiner',
  'oifde.service.invoke.basicAvatarReplyService.scale': 'Échelle',
  'oifde.service.invoke.basicAvatarReplyService.beginTime': 'Heure de début (ms)',
  'oifde.service.invoke.basicAvatarReplyService.duration': 'Durée d\'affichage (ms)',
  'service.invoke.virtualHumanConfig': 'Configuration humaine virtuelle',
  'service.virtualHuman.videoParam': 'Paramètres de montage vidéo',
  'service.virtualHuman.captions': 'Sous-titres',
  'service.invoke.asrAdvancedSetting.open': 'Activer',
  'service.invoke.asrAdvancedSetting.close': 'Désactiver',
}
