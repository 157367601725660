import { defineStore } from 'pinia'
import { moduleRoutes } from '../../../router'

export const useBaseStore = defineStore('base', {
  state: () => ({
    configPageMenu: {},
    configPageCard: {},
    keepAliveRouteNames: ['oifde-layout'],
    moduleRoutes: [],
    topTabsMenus: {},
    routeTitles: {},
    errorLogoutIndex: 0,
    timeOutLogoutIndex:0,
    preTabMap: {},
    // 智能IVR流程管理编辑页关闭侦听key
    ivrFlowHandelKey: '',
    refreshPageWithSideNavIndex: 0,
    businessMenus: [],
    businessMenusMap: {},
    businessMenusRelIdMap: {},
    businessChildrenMenusList: [],
    configMenus: [],
    configMenusMap: {},
    configMenusRelIdMap: {},
    configChildrenMenusList: [],
    isCaseHomeEnable: false,
    userAuths: {},
    userRoleAuths: [],
  }),
  getters: {
    menusDefinedRoutes() {
      const numberReg = /^\d+$/
      const routesInBase = moduleRoutes.filter((item) => numberReg.test(item.name))
      const routesWithSideNav = moduleRoutes[1].pageWithSideNav.filter((item) =>
        numberReg.test(item.name)
      )
      const routesWithBackHeader = moduleRoutes[1].pageWithBackHeader.filter((item) =>
        numberReg.test(item.name)
      )
      const childRoute = [...routesInBase, ...routesWithSideNav, ...routesWithBackHeader]
      return childRoute
    },
    routesDic() {
      const routesDic = {}
      moduleRoutes.forEach((route) => {
        routesDic[route.name] = {
          type: 'base',
          route: route
        }
      })
      moduleRoutes[1].pageWithSideNav.forEach((route) => {
        routesDic[route.name] = {
          type: 'pageWithSideNav',
          route: route
        }
      })
      moduleRoutes[1].pageWithBackHeader.forEach((route) => {
        routesDic[route.name] = {
          type: 'pageWithBackHeader',
          route: route
        }
      })
      moduleRoutes[1].pageWithSelfTab.forEach((route) => {
        routesDic[route.name] = {
          type: 'pageWithSelfTab',
          route: route
        }
      })
      return routesDic
    },
    isIndependentDeployment() {
      let result = window.localStorage.getItem('isIndependentDeployment')
      if (result === 'true') {
        return true
      } else {
        return false
      }
    },
    isApiBuilderDeployment() {
      let result = window.localStorage.getItem('isApiBuilderDeployment')
      if (result === 'true') {
        return true
      } else {
        return false
      }
    },
    topHeaderName() {
      return `${ this.configPageCard.name } / ${ this.configPageMenu.name }`
    }
  },
  actions: {
    addPageWithSideNav(menu, card) {
      this.configPageMenu = menu
      if (card) {
        this.configPageCard = card
      }
    },
    isRouteDefined(menu) {
      return this.menusDefinedRoutes.find((item) => item.name === menu.navMenuId)
    },
    inErrorLogout() {
      this.errorLogoutIndex++
    },
    inTimeOutLogout() {
      this.timeOutLogoutIndex++
    },
    refreshPageWithSideNav() {
      this.refreshPageWithSideNavIndex++
    }
  }
})

export const useEventStore = defineStore('event', {
  state: () => ({
    addPageToTabIndex: 0,
    addPageToTabData: {},
    backInTabIndex: 0,
    createTabForOldPageIndex: 0,
    createTabForOldPageData: {},
    clickMenuIndex: 0,
    clickMenuData: '',
    callBacks: {}, // 支持在tab关闭时进行回调，存储一个以方法名为key，方法体为value的对象
    isHideMenuDetail: false, //左侧菜单栏是否折叠
    showTabIndex: 0,
    showTabData: {},
    isPrePageCreateMode: false
  }),
  actions: {
    getPrePageCreateMode() {
      if (this.isPrePageCreateMode) {
        this.isPrePageCreateMode = false
        return true
      }
      return false
    },
    showTab(route) {
      this.showTabIndex++
      this.showTabData = route
    },
    addPageToTab(route) {
      this.addPageToTabIndex++
      this.addPageToTabData = route
    },
    backInTab() {
      this.backInTabIndex++
    },
    createTabForOldPage(config) {
      this.createTabForOldPageIndex++
      this.createTabForOldPageData = config
    },
    clickMenu(key) {
      this.clickMenuIndex++
      this.clickMenuData = key
    },
    initCallBack(funcName, funcAction) {
      this.callBacks[funcName] = funcAction
    }
  }
})
