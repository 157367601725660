import request from '@/utils/request'
import { aiccDownload } from '@/utils/aiccUploadAndDownload'

// 查询多媒体分类列表
export const queryCategoryList = data => {
    return request({
        url: "/service-cloud/rest/ccmessaging/v1/multiMediaLibraryType/query",
        method: 'post',
        data: data
    })
}

// 新增分类名称
export const addCategoryName = data => {
    return request({
        url: "/service-cloud/rest/ccmessaging/v1/multiMediaLibraryType/add",
        method: 'post',
        data: data
    })
}

// 修改分类名称
export const modifyCategoryName = data => {
    return request({
        url: "/service-cloud/rest/ccmessaging/v1/multiMediaLibraryType/modify",
        method: 'post',
        data: data
    })
}

// 删除分类名称
export const deleteCategoryName = (data) => {
    return request({
        url: `/service-cloud/rest/ccmessaging/v1/multiMediaLibraryType/delete`,
        method: 'delete',
        data: data
    })
}

// 查询多媒体分类详情
export const queryCategoryDetails = data => {
    return request({
        url: "/service-cloud/rest/ccmessaging/v1/multiMediaLibrary/query",
        method: 'post',
        data: data
    })
}

// 提供给servlet 查询多媒体数据
export const queryMultimediaInfoById = data => {
    let serviceUrl = "/service-cloud/rest/ccmessaging/v1/multiMediaLibrary/queryById";
    return request({
        url: serviceUrl,
        method: 'get',
        data
    })
}

// 删除多媒体数据
export const deleteMultimediaContent = data => {
    let serviceUrl = "/service-cloud/rest/ccmessaging/v1/multiMediaLibrary/delete";
    return request({
        url: serviceUrl,
        method: 'delete',
        data
    })
}


// 创建富文本内容
export const createRichText = data => {
    let serviceUrl = "/service-cloud/rest/ccmessaging/v1/multiMediaLibrary/createRichText";
    return request({
        url: serviceUrl,
        method: 'post',
        data
    })
}

// 修改富文本内容
export const modifyRichText = data => {
    let serviceUrl = "/service-cloud/rest/ccmessaging/v1/multiMediaLibrary/modifyRichText";
    return request({
        url: serviceUrl,
        method: 'post',
        data
    })
}

// 创建网页协同地址
export const createCobrowseSite = data => {
    let serviceUrl = "/service-cloud/rest/ccmessaging/v1/multiMediaLibrary/createCobrowseSite";
    return request({
        url: serviceUrl,
        method: 'post',
        data
    })
}

// 修改网页协同地址
export const modifyCobrowseSite = data => {
    let serviceUrl = "/service-cloud/rest/ccmessaging/v1/multiMediaLibrary/modifyCobrowseSite";
    return request({
        url: serviceUrl,
        method: 'post',
        data
    })
}

// 查询卡片列表
export const queryCardList = data => {
    let serviceUrl = "/service-cloud/rest/ccmessaging/v1/CardInfo/list";
    return request({
        url: serviceUrl,
        method: 'post',
        data
    })
}

// 查询WhatsApp模板消息列表
export const getWhatsAppTemplateMsg = data => {
    let serviceUrl = "/service-cloud/rest/ccmessaging/v1/whatsapptemplatemsg/getWhatsAppTemplateMsg";
    return request({
        url: serviceUrl,
        method: 'post',
        data
    })
}

// 新增WhatsApp模板消息
export const addWhatsAppTemplateMsg = data => {
    let serviceUrl = "/service-cloud/rest/ccmessaging/v1/whatsapptemplatemsg/saveWhatsAppTemplateMsg";
    return request({
        url: serviceUrl,
        method: 'post',
        data
    })
}

// 修改WhatsApp模板消息
export const modifyWhatsAppTemplateMsgById = data => {
    let serviceUrl = "/service-cloud/rest/ccmessaging/v1/whatsapptemplatemsg/modifyWhatsAppTemplateMsgById";
    return request({
        url: serviceUrl,
        method: 'post',
        data
    })
}


// 删除WhatsApp模板消息
export const deleteWhatsAppTemplateMsg = data => {
    let serviceUrl = "/service-cloud/rest/ccmessaging/v1/whatsapptemplatemsg/removeWhatsAppTemplateMsg";
    return request({
        url: serviceUrl,
        method: 'post',
        data
    })
}

// 查询图像多媒体
export const queryImagesMultimedia = data => {
    let serviceUrl = "/service-cloud/rest/ccmessaging/v1/multiMediaLibrary/queryImages";
    return request({
        url: serviceUrl,
        method: 'post',
        data
    })
}

// WEB查询图像,视频，地址多媒体
export const queryMediaByType = data => {
    let serviceUrl = "/service-cloud/rest/ccmessaging/v1/multiMediaLibrary/queryMediaByType";
    return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

// 查询图像多媒体
export const queryMultimediaByType = data => {
    let serviceUrl = "/service-cloud/rest/ccmessaging/v1/multiMediaLibrary/queryByType";
    return request({
        url: serviceUrl,
        method: 'post',
        data
    })
}

// 新增卡片
export const addCardInfo = data => {
    let serviceUrl = "/service-cloud/rest/ccmessaging/v1/CardInfo/addCardInfo";
    return request({
        url: serviceUrl,
        method: 'post',
        data
    })
}

// 修改卡片
export const modifyCardInfo = data => {
    let serviceUrl = "/service-cloud/rest/ccmessaging/v1/CardInfo/modify";
    return request({
        url: serviceUrl,
        method: 'post',
        data
    })
}

// 修改卡片
export const deleteCardInfo = data => {
    let serviceUrl = "/service-cloud/rest/ccmessaging/v1/CardInfo/delete";
    return request({
        url: serviceUrl,
        method: 'delete',
        data
    })
}

// 查询轮播卡片详情
export const queryCarouselCards = data => {
    let serviceUrl = "/service-cloud/rest/ccmessaging/v1/CardInfo/queryMultiCards";
    return request({
        url: serviceUrl,
        method: 'post',
        data
    })
}

// 查询所有WhatsApp渠道
export const queryWhatsAppChannelList = data => {
    return request({
        url: '/service-cloud/rest/ccmessaging/v1/CardInfo/queryWhatsAppChannelList',
        method: 'get',
        data
    })
}

// 新增WhatsApp模板
export const addWhatsAppTemplate = data => {
    let serviceUrl = "/service-cloud/rest/ccmessaging/v1/CardInfo/addWhatsAppTemplate";
    return request({
        url: serviceUrl,
        method: 'post',
        data
    })
}

// 修改WhatsApp模板
export const updateWhatsAppTemplate = data => {
    let serviceUrl = "/service-cloud/rest/ccmessaging/v1/CardInfo/updateWhatsAppTemplate";
    return request({
        url: serviceUrl,
        method: 'post',
        data
    })
}

// 删除WhatsApp模板
export const deleteWhatsAppTemplate = data => {
    let serviceUrl = "/service-cloud/rest/ccmessaging/v1/CardInfo/deleteWhatsAppTemplate";
    return request({
        url: serviceUrl,
        method: 'post',
        data
    })
}

// 保存多媒体文件
export const saveMultiMediaInfo = data => {
    let serviceUrl = "/service-cloud/u-route/ccmessaging/multiMediaUploadService/saveMultiMediaInfo";
    return request({
        url: serviceUrl,
        method: 'post',
        data
    })
}

// 修改多媒体文件信息
export const modifyMultiMediaInfo = data => {
    let serviceUrl = "/service-cloud/rest/ccmessaging/v1/multiMediaLibrary/modify";
    return request({
        url: serviceUrl,
        method: 'post',
        data
    })
}

// 发布多媒体
export const publishMultiMediaInfo = data => {
    let serviceUrl = "/service-cloud/rest/ccmessaging/v1/multiMediaAssociation/save";
    return request({
        url: serviceUrl,
        method: 'post',
        data
    })
}

// 查看发布结果
export const queryPublishMultiMediaResult = data => {
    let serviceUrl = "/service-cloud/rest/ccmessaging/v1/multiMediaAssociation/query";
    return request({
        url: serviceUrl,
        method: 'post',
        data
    })
}

// 删除发布结果
export const deletePublishResult = data => {
    let serviceUrl = "/service-cloud/rest/ccmessaging/v1/multiMediaAssociation/delete";
    return request({
        url: serviceUrl,
        method: 'delete',
        data
    })
}

// 刷新发布结果
export const refreshPublishResult = data => {
    let serviceUrl = "/service-cloud/rest/ccmessaging/v1/multiMediaAssociation/reupload";
    return request({
        url: serviceUrl,
        method: 'get',
        data
    })
}

// 查询常用语类别
export const queryChatPhraseType = data => {
    let serviceUrl = "/service-cloud/rest/ccmessaging/v1/chatphrasetype/queryAllChatPhraseType";
    return request({
        url: serviceUrl,
        method: 'post',
        data
    })
}

// 新增常用语类别
export const addChatPhraseType = data => {
    let serviceUrl = "/service-cloud/rest/ccmessaging/v1/chatphrasetype/saveChatPhraseType";
    return request({
        url: serviceUrl,
        method: 'post',
        data
    })
}

// 修改常用语类别
export const modifyChatPhraseType = data => {
    let serviceUrl = "/service-cloud/rest/ccmessaging/v1/chatphrasetype/updateChatPhraseType";
    return request({
        url: serviceUrl,
        method: 'put',
        data
    })
}

// 删除常用语类别
export const deleteChatPhraseType = data => {
    let serviceUrl = "/service-cloud/rest/ccmessaging/v1/chatphrasetype/deleteChatPhraseTypeById";
    return request({
        url: serviceUrl,
        method: 'delete',
        data
    })
}

// 查询常用语信息
export const queryChatPhrase = data => {
    let serviceUrl = "/service-cloud/rest/ccmessaging/v1/chatphrase/queryAllChatPhrase";
    return request({
        url: serviceUrl,
        method: 'post',
        data
    })
}

// 新增常用语信息
export const addChatPhrase = data => {
    let serviceUrl = "/service-cloud/rest/ccmessaging/v1/chatphrase/saveChatPhrase";
    return request({
        url: serviceUrl,
        method: 'post',
        data
    })
}

// 修改常用语信息
export const modifyChatPhrase = data => {
    let serviceUrl = "/service-cloud/rest/ccmessaging/v1/chatphrase/updateChatPhrase";
    return request({
        url: serviceUrl,
        method: 'put',
        data
    })
}

// 删除常用语信息
export const deleteChatPhrase = data => {
    let serviceUrl = "/service-cloud/rest/ccmessaging/v1/chatphrase/deleteChatPhraseById";
    return request({
        url: serviceUrl,
        method: 'delete',
        data
    })
}

// 下载文档
export const downLoadDocumentStream = data => {
    const serviceUrl = "/service-cloud/rest/ccmessaging/v1/fileservice/documentDownLoadStream"
    return request({
        url: serviceUrl,
        method: 'post',
        data
    })
}

// 新增位置多媒体信息
export const addLocateMultiMediaInfo = data => {
    const serviceUrl = "/service-cloud/rest/ccmessaging/v1/multiMediaLibrary/addLocate"
    return request({
    url: serviceUrl, method: 'post', data
    })
}


