export default {
  'oifde.custom.displayName': 'Business Service',
  'oifde.common.displayName': 'Common Node',
  'oifde.basic.displayName': 'Basic Service',
  'oifde.test.displayName': 'Test Sample Service',
  'oifde.start.displayName': 'Start',
  'oifde.start.description': 'Start',
  'oifde.start.keywords': 'Start',
  'oifde.commonBusinessInvokeService.displayName': 'Business Interface Invocation',
  'oifde.commonBusinessInvokeService.description': 'Business interface Invocation (commonBusinessInvokeService)',
  'oifde.commonBusinessInvokeService.keywords': 'Business Interface Invocation',
  'oifde.procedureService.displayName': 'Stored Procedure',
  'oifde.procedureService.description': 'Stored Procedure (procedureService)',
  'oifde.procedureService.keywords': 'Stored Procedure',
  'oifde.conditionService.displayName': 'Condition Judgment',
  'oifde.conditionService.description': 'Condition Judgment (conditionService)',
  'oifde.conditionService.keywords': 'Condition Judgment',
  'oifde.callEndService.displayName': 'Call Ending',
  'oifde.callEndService.description': 'Call Ending (callEndService)',
  'oifde.callEndService.keywords': 'Call Ending',
  'oifde.sendSmsService.displayName': 'SMS Sending',
  'oifde.sendSmsService.description': 'SMS Sending (sendSmsService)',
  'oifde.sendSmsService.keywords': 'SMS Sending',
  'oifde.commonVoiceService.displayName': 'Response',
  'oifde.commonVoiceService.description': 'Response (commonResponseService)',
  'oifde.commonVoiceService.keywords': 'Response',
  'oifde.transforFlowService.displayName': 'Flow Transfer',
  'oifde.transforFlowService.description': 'Flow Transfer (transforFlowService)',
  'oifde.transforFlowService.keywords': 'Flow Transfer',
  'oifde.callTransferService.displayName': 'Call Transfer',
  'oifde.callTransferService.description': 'Call Transfer (callTransferService)',
  'oifde.callTransferService.keywords': 'Call Transfer',
  'oifde.speechRecognitionService.displayName': 'Semantic Recognition',
  'oifde.speechRecognitionService.description': 'Semantic Recognition (speechRecognitionService)',
  'oifde.speechRecognitionService.keywords': 'Semantic Recognition',
  'oifde.speechRecognitionService.confidence2TopError': 'The confidence interval must be between 0 and 1.',
  'oifde.keyboardRecognitionService.displayName': 'Key Recognition',
  'oifde.keyboardRecognitionService.description': 'Key Recognition (keyboardRecognitionService)',
  'oifde.keyboardRecognitionService.keywords': 'Key Recognition',
  'oifde.textVariableToChatbotService.displayName': 'Text to chatbot',
  'oifde.textVariableToChatbotService.description': 'Text to chatbot (textVariableToChatbotService)',
  'oifde.textVariableToChatbotService.keywords': 'Text to chatbot',
  'oifde.checkRegularService.displayName': 'Rule Validation',
  'oifde.checkRegularService.description': 'Rule Validation (checkRegularService)',
  'oifde.checkRegularService.keywords': 'Rule Validation',
  'oifde.dateProcessService.displayName': 'Date Processing',
  'oifde.dateProcessService.description': 'Date Processing (dateProcessService)',
  'oifde.dateProcessService.keywords': 'Date Processing',
  'oifde.errTimesClearService.displayName': 'Error Count Clearance',
  'oifde.errTimesClearService.description': 'Error Count Clearance (errTimesClearService)',
  'oifde.errTimesClearService.keywords': 'Error Count Clearance',
  'oifde.CircleService.displayName': 'Logic Loop',
  'oifde.CircleService.description': 'Logic Loop (CircleService)',
  'oifde.CircleService.keywords': 'Logic Loop',
  'oifde.service.invoke.user.level': 'User Level',
  'oifde.UserLevelService.displayName': 'User Level',
  'oifde.UserLevelService.keywords': 'User Level',
  'oifde.UserLevelService.description': 'User Level(UserLevelService)',
  'oifde.service.invoke.user.level.reminder': 'Set this parameter to a non-negative integer or a character variable. The minimum user level is 0 and the maximum user level is 14. After the call is transferred to the manual service, the call of a high-level customer is processed first. If the call is not within the range of 0-14, the call is processed based on the boundary value.',
  'oifde.compositeTestService.displayName': 'Composite diagram element sample',
  'oifde.compositeTestService.description': 'Composite diagram element sample (compositeTestService)',
  'oifde.compositeTestService.keywords': 'Composite diagram element sample',
  'oifde.service.condition.condgroup.desc': 'Set judgment condition attributes.',
  'oifde.service.condition.condgroup.displayName': 'Judgment Conditions',
  'oifde.service.condition.condgroup.keyTitle': 'Condition',
  'oifde.service.condition.condgroup.valueTitle': 'Condition Expression',
  'oifde.service.invokemethod.group.desc': 'Method Invocation',
  'oifde.service.invokemethod.group.displayName': 'Method Invocation',
  'oifde.service.invokemethod.group.value1Title': 'Return Object',
  'oifde.service.invokemethod.group.value2Title': 'Method Name',
  'oifde.service.invokemethod.group.value3Title': 'Method Parameters',
  'oifde.service.invokemethod.group.value1Tips': 'Method return value receiving object',
  'oifde.service.invokemethod.group.value2Tips': 'Select a method name.',
  'oifde.service.invokemethod.group.value3Tips': 'Method Parameters1',
  'oifde.service.invokemethod.group.value4Tips': 'Method Parameters2',
  'oifde.service.invokemethod.group.value5Tips': 'Method Parameters3',
  'oifde.service.invokemethod.group.mathodType1': 'String Manipulation Method',
  'oifde.service.invokemethod.group.mathodType2': 'Array operation method',
  'oifde.service.invokemethod.group.mathodType3': 'Map Operation Method',
  'oifde.service.invokemethod.strSplit.desc': 'Splits a string based on the given delimiter',
  'oifde.service.invokemethod.strSubstring.desc': 'Extracting Substrings',
  'oifde.service.invokemethod.strIndexOf.desc': 'Obtains the subscript of the first occurrence of a specified string',
  'oifde.service.invokemethod.strStartsWith.desc': 'Checks whether the string starts with the specified prefix',
  'oifde.service.invokemethod.strEndsWith.desc': 'Checks whether a string ends with a specified suffix',
  'oifde.service.invokemethod.strParseJSON.desc': 'Converts a JSON character string to an object',
  'oifde.service.invokemethod.strParseInt.desc': 'Converts a string to an integer number',
  'oifde.service.invokemethod.strParseFloat.desc': 'Converts a string to a floating-point number',
  'oifde.service.invokemethod.strLength.desc': 'Character string length',
  'oifde.service.invokemethod.strToUpperCase.desc': 'Converts string lowercase characters to uppercase',
  'oifde.service.invokemethod.strToLowerCase.desc': 'Converts string uppercase characters to lowercase',
  'oifde.service.invokemethod.arrayAdd.desc': 'Add an element to an array',
  'oifde.service.invokemethod.arrayAddAll.desc': 'Adds all elements of another collection to an array',
  'oifde.service.invokemethod.arrayContains.desc': 'Determines whether an element is in an array.',
  'oifde.service.invokemethod.arrayGet.desc': 'Gets elements in an array by index value',
  'oifde.service.invokemethod.arrayRemoveIdx.desc': 'Deletes the specified subscript element from the array',
  'oifde.service.invokemethod.arrayRemoveAllIdx.desc': 'Deletes subscript elements in the array that exist in the specified collection',
  'oifde.service.invokemethod.arrayClear.desc': 'Deletes all elements in an array',
  'oifde.service.invokemethod.arraySize.desc': 'Obtains the length of an array',
  'oifde.service.invokemethod.arraySubList.desc': 'Truncates and returns a portion of an array',
  'oifde.service.invokemethod.arrayJoin.desc': 'Convert an array to a string',
  'oifde.service.invokemethod.mapPut.desc': 'Add an element to a map',
  'oifde.service.invokemethod.mapGet.desc': 'Obtains the value of the specified key mapping in the map',
  'oifde.service.invokemethod.mapRemove.desc': 'Deletes a specified key-value pair from the map',
  'oifde.service.invokemethod.mapClear.desc': 'Clear all key-value pairs of the map',
  'oifde.service.invokemethod.mapSize.desc': 'Obtains the number of all keys of the map',
  'oifde.service.invokemethod.strSplit.parm1.desc': 'Character string to be split',
  'oifde.service.invokemethod.strSplit.parm2.desc': 'Separator',
  'oifde.service.invokemethod.strSubstring.parm1.desc': 'Character string to be truncated',
  'oifde.service.invokemethod.strSubstring.parm2.desc': 'Start subscript (integer)',
  'oifde.service.invokemethod.strSubstring.parm3.desc': 'End subscript (integer)',
  'oifde.service.invokemethod.strIndexOf.parm1.desc': 'String to be searched for',
  'oifde.service.invokemethod.strIndexOf.parm2.desc': 'Specified String',
  'oifde.service.invokemethod.strStartsWith.parm1.desc': 'Character string to be checked',
  'oifde.service.invokemethod.strStartsWith.parm2.desc': 'Specified prefix string',
  'oifde.service.invokemethod.strEndsWith.parm1.desc': 'Character string to be checked',
  'oifde.service.invokemethod.strEndsWith.parm2.desc': 'Specified suffix string',
  'oifde.service.invokemethod.strParseJSON.parm1.desc': 'Character string to be converted',
  'oifde.service.invokemethod.strParseInt.parm1.desc': 'Character string to be converted',
  'oifde.service.invokemethod.strParseFloat.parm1.desc': 'Character string to be converted',
  'oifde.service.invokemethod.strLength.parm1.desc': 'Character string of length to be calculated',
  'oifde.service.invokemethod.strToUpperCase.parm1.desc': 'Character string to be converted',
  'oifde.service.invokemethod.strToLowerCase.parm1.desc': 'Character string to be converted',
  'oifde.service.invokemethod.arrayAdd.parm1.desc': 'Array to be added (this method modifies the array to be added)',
  'oifde.service.invokemethod.arrayAdd.parm2.desc': 'Added Position (Default Trailer)',
  'oifde.service.invokemethod.arrayAdd.parm3.desc': 'Added elements',
  'oifde.service.invokemethod.arrayAddAll.parm1.desc': 'Array to be added (this method modifies the array to be added)',
  'oifde.service.invokemethod.arrayAddAll.parm2.desc': 'Added Position (Default Trailer)',
  'oifde.service.invokemethod.arrayAddAll.parm3.desc': 'Added elements',
  'oifde.service.invokemethod.arrayContains.parm1.desc': 'Array to be detected',
  'oifde.service.invokemethod.arrayContains.parm2.desc': 'Elements to be judged',
  'oifde.service.invokemethod.arrayGet.parm1.desc': 'Array to be used',
  'oifde.service.invokemethod.arrayGet.parm2.desc': 'array subscript',
  'oifde.service.invokemethod.arrayRemoveIdx.parm1.desc': 'Array to be used',
  'oifde.service.invokemethod.arrayRemoveIdx.parm2.desc': 'Delete Array Subscript',
  'oifde.service.invokemethod.arrayRemoveAllIdx.parm1.desc': 'Array to be used',
  'oifde.service.invokemethod.arrayRemoveAllIdx.parm2.desc': 'Specifies the array subscript set',
  'oifde.service.invokemethod.arrayClear.parm1.desc': 'Array to be cleared',
  'oifde.service.invokemethod.arraySize.parm1.desc': 'Array of lengths to be calculated',
  'oifde.service.invokemethod.arraySubList.parm1.desc': 'Array to be intercepted',
  'oifde.service.invokemethod.arraySubList.parm2.desc': 'Capture start position',
  'oifde.service.invokemethod.arraySubList.parm3.desc': 'Intercept end position',
  'oifde.service.invokemethod.arrayJoin.parm1.desc': 'Array to be converted',
  'oifde.service.invokemethod.arrayJoin.parm2.desc': 'Connection String',
  'oifde.service.invokemethod.mapPut.parm1.desc': 'Map to be added (this method modifies the map to be added)',
  'oifde.service.invokemethod.mapPut.parm2.desc': 'key (only the string type is supported)',
  'oifde.service.invokemethod.mapPut.parm3.desc': 'Added elements',
  'oifde.service.invokemethod.mapGet.parm1.desc': 'To-be-processed map',
  'oifde.service.invokemethod.mapGet.parm2.desc': 'key (only the string type is supported)',
  'oifde.service.invokemethod.mapRemove.parm1.desc': 'To-be-processed map',
  'oifde.service.invokemethod.mapRemove.parm2.desc': 'key (only the string type is supported)',
  'oifde.service.invokemethod.mapClear.parm1.desc': 'Map to be cleared',
  'oifde.service.invokemethod.mapSize.parm1.desc': 'Map of the quantity to be calculated',
  'oifde.service.invokemethod.strSplit.resultObj.desc': 'The returned object is of the array type and stores the string array after splitting.',
  'oifde.service.invokemethod.strSubstring.resultObj.desc': 'The returned object is a character string, which is a truncated substring.',
  'oifde.service.invokemethod.strIndexOf.resultObj.desc': 'The returned object is a subscript value. The value is an integer.',
  'oifde.service.invokemethod.strStartsWith.resultObj.desc': 'The returned object is the detection result. The value is an integer of 0 or 1.',
  'oifde.service.invokemethod.strEndsWith.resultObj.desc': 'The returned object is the detection result. The value is an integer of 0 or 1.',
  'oifde.service.invokemethod.strParseJSON.resultObj.desc': 'The returned object is the converted JSON object.',
  'oifde.service.invokemethod.strParseInt.resultObj.desc': 'The returned object is an integer number after conversion.',
  'oifde.service.invokemethod.strParseFloat.resultObj.desc': 'The returned object is a converted floating-point number.',
  'oifde.service.invokemethod.strLength.resultObj.desc': 'The return object is the length of a character string. The value is an integer.',
  'oifde.service.invokemethod.strToUpperCase.resultObj.desc': 'The returned object is a character string after conversion.',
  'oifde.service.invokemethod.strToLowerCase.resultObj.desc': 'The returned object is a character string after conversion.',
  'oifde.service.invokemethod.arrayAdd.resultObj.desc': 'The returned object is the adding result. If the adding is successful, 1 is returned. Otherwise, 0 is returned.',
  'oifde.service.invokemethod.arrayAddAll.resultObj.desc': 'The returned object is the adding result. If the adding is successful, 1 is returned. Otherwise, 0 is returned.',
  'oifde.service.invokemethod.arrayContains.resultObj.desc': 'The returned object is the judgment result. The value is an integer of 0 or 1.',
  'oifde.service.invokemethod.arrayGet.resultObj.desc': 'Returns an element whose object is an array.',
  'oifde.service.invokemethod.arrayRemoveIdx.resultObj.desc': 'The returned object is the deletion result. If the deletion is successful, the corresponding subscript element is returned. Otherwise, null is returned.',
  'oifde.service.invokemethod.arrayRemoveAllIdx.resultObj.desc': 'The returned object is the deletion result. If the deletion is successful, 1 is returned. Otherwise, 0 is returned.',
  'oifde.service.invokemethod.arrayClear.resultObj.desc': 'The returned object is void, and the text box is dimmed and cannot be edited.',
  'oifde.service.invokemethod.arraySize.resultObj.desc': 'The returned object is the length of an array. The value is an integer.',
  'oifde.service.invokemethod.arraySubList.resultObj.desc': 'The returned object is a subarray.',
  'oifde.service.invokemethod.arrayJoin.resultObj.desc': 'The returned object is the converted character string.',
  'oifde.service.invokemethod.mapPut.resultObj.desc': 'The returned object logic is as follows: If the key does not exist, null is returned. If the key exists, the replaced value and object type are returned.',
  'oifde.service.invokemethod.mapGet.resultObj.desc': 'Returns the value for which the object is mapped for the specified key.',
  'oifde.service.invokemethod.mapRemove.resultObj.desc': 'Return Object: This method returns the value previously associated with this key for this mapping; Returns null if the mapping does not contain a mapping for the key.',
  'oifde.service.invokemethod.mapClear.resultObj.desc': 'The returned object is void, and the text box is dimmed and cannot be edited.',
  'oifde.service.invokemethod.mapSize.resultObj.desc': 'The returned object is the number of keys in the map. The value is an integer.',
  'oifde.service.invoke.cachegroup.desc': 'Set the corresponding variable attributes.',
  'oifde.service.invoke.cachegroup.displayName': 'Variable Attributes',
  'oifde.service.invoke.cachegroup.keyTitle': 'Cache Variable',
  'oifde.service.invoke.cachegroup.valueTitle': 'Response Attribute',
  'oifde.service.invoke.interfaceType': 'Interface Type',
  'oifde.service.invoke.interfaceType.desc': 'Interface Type.',
  'oifde.service.invoke.interfaceId': 'Invoked Interface',
  'oifde.service.invoke.interfaceId.desc': 'Invoked interface.',
  'oifde.service.invoke.paramgroup.desc': 'List of interface input parameters.',
  'oifde.service.invoke.paramgroup.name': 'Interface Input Parameters',
  'oifde.service.invoke.startTime': 'Start Time',
  'oifde.service.invoke.startTime.desc': 'The format is HH:MM:SS. Example: 08:00:00.',
  'oifde.service.invoke.endTime': 'End Time',
  'oifde.service.invoke.endTime.desc': 'The format is HH:MM:SS. Example: 23:00:00.',
  'oifde.service.invoke.sendCircle': 'Sending Interval',
  'oifde.service.invoke.sendCircle.desc': 'Interval for invoking an interface, in days. Integer, no more than 365. If this parameter is set to 1, the diagram element is not invoked periodically. The diagram element is triggered at any time when the diagram element is invoked.',
  'oifde.service.invoke.CallTransferCallingNum': 'Calling Number',
  'oifde.service.invoke.CallTransferCallingNum.desc': 'This value is used as the calling number if the call transfer type is set to 5 (indicating that the call is transferred to a third party).The calling number must be an accessCode allocated by the system or customized parameter. Example: 660001 or FLOW.accessCode',
  'oifde.service.invoke.CallTransferCallingNum.lastAgentDesc': 'The calling number is the calling number of the customer. For example, SYS.callingNumber.',
  'oifde.service.invoke.CallTransferType': 'Transfer Type',
  'oifde.service.invoke.CallTransferType.desc': 'Dialog transfer type. The options are as follows: 1: transfer to the manual service (to a skill queue by routing code); 2: transfer to an IVR; 3: transfer to the manual service (to a skill queue by skill name); 4: transfer to the manual service (to an agent by ID); 5: transfer to a third-party number; 6: online digital assistant; 7: transfer to the manual service (to the last agent, only multimedia is supported currently); 8: transfer to the manual service (to the skill queue to which an agent belongs, only multimedia is supported currently); 31: transfer to a multimedia message (to the last agent); 32: transfer to a multimedia message (to a skill queue); 33: transfer to a multimedia message (to an agent).',
  'oifde.service.invoke.CallTransferRouteValue': 'Route Value',
  'oifde.service.invoke.CallTransferRouteValue.desc': 'Call transfer route value. Set this parameter based on the call transfer type.',
  'oifde.service.invoke.CallTransferRouteTypePolicy': 'Routing Policy',
  'oifde.service.invoke.CallTransferRoutePolicyType.percentage': 'Percentage',
  'oifde.service.invoke.CallTransferRoutePolicyType.numberSegment': 'Number segment',
  'oifde.service.invoke.CallTransferRouteDefaultSkillName': 'Default Skill Queue',
  'oifde.service.invoke.CallTransferMatchingType': 'Matching rule',
  'oifde.service.invoke.CallTransferMatchingType.prefix': 'Prefix',
  'oifde.service.invoke.CallTransferMatchingType.suffix': 'Suffix',
  'oifde.service.invoke.CallTransferRouteTypePolicy.percentTotalError': 'Percentage total does not equal 100',
  'oifde.service.invoke.CallTransferRouteTypePolicy.percentValueError':'Enter a positive integer ranging from 1 to 100.',
  'oifde.service.invoke.CallTransferTransData': 'Associated Data',
  'oifde.service.invoke.CallTransferTransData.desc': 'Associated data carried during call transfer to an agent.',
  'oifde.service.invoke.CallTransferTransData.desc2': 'Alternatively, you can enter multiple flow or global variables, for example, {"data":${FLOW.XXX},"type":${GLOBAL.XXX}, "time":"2000"}.',
  'oifde.service.invoke.textVariable': 'Text Variable',
  'oifde.service.invoke.textVariable.desc': 'Text variable sent to the chatbot. A value must be assigned to this variable before the node can be executed.',
  'oifde.service.invoke.KeyboardInputRegExp': 'Rule Expression',
  'oifde.service.invoke.KeyboardInputRegExp.desc': 'The expression checks whether user-entered text meets the specified rule. The value can be a regular expression. Example: ^(1([0-9]{10})|([*]|[#]))$.',
  'oifde.service.invoke.voiceType': 'Response Type',
  'oifde.service.invoke.voiceSource': 'Response Source',
  'oifde.service.invoke.staticVoice': 'Static Voice',
  'oifde.service.invoke.TTSVoice': 'TTS',
  'oifde.service.invoke.multiVoice': 'Variable voice',
  'oifde.service.invoke.multiVoiceLanguage': 'Language',
  'oifde.service.invoke.multiVoiceType': 'Type',
  'oifde.service.invoke.voiceTemplateCode': 'Response Template',
  'oifde.service.invoke.modelType': 'Response Mode',
  'oifde.service.invoke.modelType.desc': 'Whether the dialog can be interrupted.',
  'oifde.service.invoke.timeOut': 'Timeout Interval',
  'oifde.service.invoke.timeOut.desc': 'Timeout interval(Unit: seconds). The value is an integer. Example: 15.',
  'oifde.service.invoke.voiceVariable': 'Response Variable',
  'oifde.service.invoke.voiceVariable.mix.desc': 'Voice or text variable that is included in the response. A value must be assigned to this variable before the node can be executed(For a voice and text type, the response variable is in the recording file URL~$TTS text$~recording file URL format.)',
  'oifde.service.invoke.voiceVariable.tts.desc': 'Voice or text variable that is included in the response. A value must be assigned to this variable before the node can be executed(For a reply text type, the response variable is in the TTS text format. Only one TTS text is supported.)',
  'oifde.service.invoke.voiceVariable.voice.desc': 'Voice or text variable that is included in the response. A value must be assigned to this variable before the node can be executed(For a static voice type, the response variable is in the recording file URL format. Only one URL is supported.)',
  'oifde.service.invoke.voiceVariable.default.desc': 'Voice or text variable that is included in the response. A value must be assigned to this variable before the node can be executed.',
  'oifde.service.invoke.voiceVariable.multiVoice.telnum.desc': 'Voice or text variable that is included in the response. A value must be assigned to this variable before the node can be executed(For a phone number type，the response variable is in the actual content format of the response variable is numeric text. If the input is incorrect, the voice playing is abnormal.)',
  'oifde.service.invoke.voiceVariable.multiVoice.number.desc': 'Voice or text variable that is included in the response. A value must be assigned to this variable before the node can be executed(For a number type，the response variable is in the actual content format of the response variable is numeric text. If the input is incorrect, the voice playing is abnormal.)',
  'oifde.service.invoke.voiceVariable.multiVoice.time.desc': 'Voice or text variable that is included in the response. A value must be assigned to this variable before the node can be executed(For a time type，the response variable is：hh:mm:ss，If the input is incorrect, the voice playing is abnormal.)',
  'oifde.service.invoke.voiceVariable.multiVoice.date.desc': 'Voice or text variable that is included in the response. A value must be assigned to this variable before the node can be executed(For a date type，the response variable is：yyyymmdd，If the input is incorrect, the voice playing is abnormal.)',
  'oifde.service.invoke.voiceVariable.multiVoice.price.desc': 'Voice or text variable that is included in the response. A value must be assigned to this variable before the node can be executed(For a price type，the response variable is in the actual content format of the response variable is numeric text.Unit: minute. If the input is incorrect, the voice playing is abnormal.)',
  'oifde.service.invoke.offsetType': 'Offset Period',
  'oifde.service.invoke.offsetType.desc': 'Offset by day or hour',
  'oifde.service.invoke.offsetType.month': 'By month',
  'oifde.service.invoke.offsetType.hour': 'By hour',
  'oifde.service.invoke.sourceVariable': 'Source Character String',
  'oifde.service.invoke.sourceVariable.desc': 'Source string variable. The format is yyyyMM if the offset is by month, and is yyyyMMddHHmmss if the offset is by hour. Example: 202103 or 20210308122020. A value must be assigned to this variable before the node can be executed.',
  'oifde.service.invoke.destVariable': 'Target Character String',
  'oifde.service.invoke.destVariable.desc': 'Target string variable used to save the result.',
  'oifde.service.invoke.offsetMonths': 'Number of Offsets',
  'oifde.service.invoke.offsetMonths.desc': 'Number of offsets. The value is a positive or negative integer. Set this parameter based on the offset period (offset by month or hour). For example, if Offset by month is selected, the value -6 indicates that the source character string is rolled back to that used six months ago.',
  'oifde.service.invoke.keyBoardType': 'Keyboard Input Type',
  'oifde.service.invoke.keyBoardType.desc': 'Keyboard input type. The value can be keyBoard_phone or checkoldpassword, or be left empty.',
  'oifde.service.invoke.inputVariable': 'Variable',
  'oifde.service.invoke.inputVariable.desc': 'Variable for saving user keyboard input.',
  'oifde.service.invoke.inputVariableTitle': 'Variable Assignment',
  'oifde.service.invoke.inputVariableValue': 'Variable Value',
  'oifde.service.invoke.conditionTitle': 'Condition branch',
  'oifde.service.invoke.branchGroup.timeout': 'Timeout',
  'oifde.service.invoke.branchGroup.nomatch': 'Nomatch',
  'oifde.service.invoke.branchGroup.error': 'Error',
  'oifde.service.invoke.branchGroup.silentAgent': 'Silent Agent',
  'oifde.service.invoke.targetFlowCode': 'Target Flow Code',
  'oifde.service.invoke.targetNodeId': 'Target Node ID',
  'oifde.service.invoke.voiceType.staticVoice': 'Static voice',
  'oifde.service.invoke.voiceType.TTSVoice': 'TTS',
  'oifde.service.invoke.voiceType.video': 'Video',
  'oifde.service.invoke.smsTemplateCode': 'SMS Template',
  'oifde.service.invoke.smsContent': 'SMS Content',
  'oifde.service.invoke.smsContent.desc': 'SMS content.',
  'oifde.service.invoke.modelType.voiceBreak': 'Interruption recognition',
  'oifde.service.invoke.modelType.noVoiceBreak': 'Playback only',
  'oifde.service.invoke.modelType.keyboardBreak': 'Interruption by key presses',
  'oifde.service.invoke.modelType.voiceAndKeyboard': 'Recognition and key presses',
  'oifde.service.invoke.modelType.voiceAndNobreak': 'Recognition after playback',
  'oifde.service.invoke.modelType.voiceAndInputAndNobreak': 'Recognition and key presses after playback',
  'oifde.service.invoke.KeyboardInputRegExp.MobileAll': 'Validate mobile number format (with # and *)',
  'oifde.service.invoke.KeyboardInputRegExp.Mobile': 'Validate mobile number format (without # or *)',
  'oifde.service.invoke.KeyboardInputRegExp.PasswordAll': 'Validate password format (with # and *)',
  'oifde.service.invoke.KeyboardInputRegExp.PasswordStar': 'Validate password format (with *)',
  'oifde.service.invoke.KeyboardInputRegExp.NumberAll': 'Validate mobile number (with # and *)',
  'oifde.service.invoke.KeyboardInputRegExp.NumberStar': 'Validate mobile number (with *)',
  'oifde.service.invoke.KeyboardInputRegExp.Month': 'Validate month input',
  'oifde.service.invoke.context': 'Context',
  'oifde.service.invoke.context.desc': 'Context information transferred to the TUC.',
  'oifde.service.invoke.vendorParam': 'Extended Parameter',
  'oifde.service.invoke.vendorParam.desc': 'Extended voice recognition parameter returned to the IVR.',
  'oifde.service.invoke.input': 'Semantic Recognition Content',
  'oifde.service.invoke.input.desc': 'Semantic recognition content transferred to the TUC. The content can be a variable. If this field is empty, user-entered content is transferred to the TUC.',
  'oifde.service.invoke.event': 'Event',
  'oifde.service.invoke.event.desc': 'Event sent to the TUC.',
  'oifde.service.invoke.event.cleanup': 'Clear Session Cache',
  'oifde.service.invoke.event.cleanCurrent': 'Clear Current Context Cache',
  'oifde.service.invoke.procedureCode': 'Stored Procedure',
  'oifde.service.invoke.complexcellgroupin.name': 'Input Parameters',
  'oifde.service.invoke.complexcellgroupin.desc': 'Input parameters.',
  'oifde.service.invoke.complexcellgroupout.name': 'Output Parameters',
  'oifde.service.invoke.complexcellgroupout.desc': 'Output parameters.',
  'oifde.service.invoke.targetIntentCount': 'Number of Intentions',
  'oifde.service.invoke.targetIntentCount.desc': 'Maximum number of intentions that are returned by the TUC.',
  'oifde.service.invoke.confidence2Top': 'Confidence Interval',
  'oifde.service.invoke.confidence2Top.desc': 'Interval between the confidence of multiple intentions and that of the first intention when multiple intentions need to be returned.',
  'oifde.service.invoke.index.desc': 'Counter.',
  'oifde.service.invoke.index': 'Counter',
  'oifde.service.invoke.oblist.desc': 'List of loops.',
  'oifde.service.invoke.oblist': 'Loops',
  'oifde.service.invoke.object.desc': 'Loop object.',
  'oifde.service.invoke.object': 'Loop Object',
  'oifde.service.invoke.voiceType.multi-media': 'Multimedia',
  'oifde.service.invoke.modelType.inputAndNobreak': 'No interruption after digit collection',
  'oifde.service.invoke.maxCollectLength': 'Number of Collected Digits',
  'oifde.service.invoke.maxCollectLength.desc': 'Set the maximum number of collected digits.',
  'oifde.service.invoke.collectTimeout': 'Digit collection timeout',
  'oifde.service.invoke.collectTimeout.desc': 'Sets the digit collection timeout interval. The FLOW and GLOBAL variables are supported. The value can also be an integer ranging from 1 to 120. If the value does not meet the requirements, the default value 5 is used.',
  'oifde.service.invoke.cancelCollectFlag': 'Cancel Key',
  'oifde.service.invoke.cancelCollectFlag.yes': 'Use the star key (*)',
  'oifde.service.invoke.cancelCollectFlag.no': 'Do not use the star key (*)',
  'oifde.service.invoke.confirmCollectFlag': 'Confirm Key',
  'oifde.service.invoke.confirmCollectFlag.yes': 'Use the pound key (#)',
  'oifde.service.invoke.confirmCollectFlag.no': 'Do not use the pound key (#)',
  'oifde.service.invoke.ttsAdvancedSetting': 'TTS Advanced Settings',
  'oifde.service.invoke.ttsAdvancedSetting.desc': 'Whether to enable these settings to specify TTS-based voice playback.',
  'oifde.service.invoke.ttsAdvancedSetting.open': 'Enable',
  'oifde.service.invoke.ttsAdvancedSetting.close': 'Disable',
  'oifde.service.invoke.ttsSoundEffect': 'Voice',
  'oifde.service.invoke.ttsSoundEffect.desc': 'Select whether to use a male or female voice for TTS-based voice playback.',
  'oifde.service.invoke.ttsSoundEffect.male': 'Male',
  'oifde.service.invoke.ttsSoundEffect.female': 'Female',
  'oifde.service.invoke.ttsSoundSpeed': 'Voice Speed',
  'oifde.service.invoke.ttsSoundSpeed.desc': 'Select the speed at which TTS-based voice playback is performed.',
  'oifde.service.invoke.ttsSoundSpeed.low': 'Slow',
  'oifde.service.invoke.ttsSoundSpeed.normal': 'Medium',
  'oifde.service.invoke.ttsSoundSpeed.high': 'Fast',
  'oifde.service.invoke.ttsMRCPVendorSetting.open': 'Enable',
  'oifde.service.invoke.ttsMRCPVendorSetting.close': 'Disable',
  'oifde.service.invoke.ttsMRCPVendorSetting': 'Default Values for Extended TTS Parameters.',
  'oifde.service.invoke.ttsMRCPVendorSetting.desc': 'After default values for extended TTS parameters are enabled, the definitions will overwrite the selected sound effect and sound speed.',
  'oifde.service.invoke.ttsMRCPVendor': 'TTS Extended Parameter',
  'oifde.service.invoke.ttsMRCPVendor.desc': 'Extended TTS server parameters. Set the parameters based on the TTS server vendor\'s requirements. An example is speed=GLOBAL.ttsSpeed;pitch=GLOBAL.ttsPitch;volume=GLOBAL.ttsVolume;voice_name=GLOBAL.voiceName;. The GLOBAL and FLOW variables are supported. If a variable value is not within the standard range, the default value is used. The value range of speed is [-500, 500], and the default value is 0. The value range of volume is [0, 100], and the default value is 50. The value range of pitch is [-500, 500], and the default value is 0. The default value of voice_name is cn_kefuman_common.',
  'oifde.service.invoke.asrAdvancedSetting': 'ASR Advanced Settings',
  'oifde.service.invoke.asrAdvancedSetting.desc': 'Whether to enable these settings to specify ASR recognition.',
  'oifde.service.invoke.asrAdvancedSetting.open': 'Enable',
  'oifde.service.invoke.asrAdvancedSetting.close': 'Disable',
  'oifde.service.invoke.asrRecognizeType': 'Recognition Type',
  'oifde.service.invoke.asrRecognizeType.desc': 'ASR identification type. Only common identification is currently supported.',
  'oifde.service.invoke.asrRecognizeType.normal': 'Common',
  'oifde.service.invoke.asrRecognizeType.intelligent': 'Intelligent',
  'oifde.service.invoke.asrNoInputTimeout': 'Subscriber Silence Timeout Interval',
  'oifde.service.invoke.asrNoInputTimeout.desc': 'Timeout interval when a subscriber does not speak during ASR recognition, in seconds. The value ranges from 0 to 3200. The default value is 100.',
  'oifde.service.invoke.asrRecognitionTimeout': 'Recognition Timeout Interval',
  'oifde.service.invoke.asrRecognitionTimeout.desc': 'Interval when the ASR recognition times out, in seconds. The value ranges from 0 to 600. The default value is 200.',
  'oifde.service.invoke.asrSpeechCompleteTimeout': 'Subscriber Pause Timeout Interval',
  'oifde.service.invoke.asrSpeechCompleteTimeout.desc': 'Timeout interval when a subscriber pauses during ASR recognition, in seconds. The value ranges from 300 to 2000. The default value is 500.',
  'oifde.service.invoke.asrMRCPVendor': 'ASR Extended Parameter',
  'oifde.service.invoke.asrMRCPVendor.desc': 'Extended parameter of the ASR server. Set this parameter according to vendor requirements.',
  'oifde.service.invoke.voiceType.card': 'Card',
  'oifde.service.invoke.operationType': 'Operation Type',
  'oifde.service.invoke.operationType.open': 'Start recording',
  'oifde.service.invoke.operationType.close': 'Stop recording',
  'oifde.service.invoke.processRecord': 'Full Recording',
  'oifde.service.invoke.processRecord.open': 'Yes',
  'oifde.service.invoke.processRecord.close': 'No',
  'oifde.service.invoke.recordType': 'Type',
  'oifde.service.invoke.recordType.audio': 'Audio only',
  'oifde.service.invoke.recordType.video': 'Audio and video',
  'oifde.yugaoRecordService.displayName': 'VoiceCyber Recording',
  'oifde.yugaoRecordService.description': 'VoiceCyber Recording (voiceCyberRecordService)',
  'oifde.yugaoRecordService.keywords': 'VoiceCyber Recording',
  'oifde.jsonDataProcessService.displayName': 'JSON Data Invoking',
  'oifde.jsonDataProcessService.description': 'JSON data invoking (jsonDataProcessService).',
  'oifde.jsonDataProcessService.keywords': 'JSON Data Invoking',
  'oifde.timeSelectService.displayName': 'Time Selection',
  'oifde.timeSelectService.description': 'Time selection (timeSelectService).',
  'oifde.timeSelectService.keywords': 'Time Selection',
  'oifde.queryMessageService.displayName': 'Information query',
  'oifde.queryMessageService.description': 'Information query(queryMessageService).',
  'oifde.queryMessageService.keywords': 'Information query',
  'oifde.service.invoke.recordMode': 'Mode',
  'oifde.service.invoke.recordMode.onlyuser': 'Record only subscriber',
  'oifde.service.invoke.recordMode.onlyivr': 'Record only IVR',
  'oifde.service.invoke.recordMode.all': 'Record subscriber and IVR',
  'oifde.service.invoke.timeSelect.NonWorkTime': 'Unspecified time branch',
  'oifde.service.invoke.timeSelect.weekParameter': 'Week Selection',
  'oifde.service.invoke.timeSelect.serviceTimeParameter': 'Service Period',
  'oifde.service.invoke.timeSelect.specificWorkDateParameter': 'Specified Service Date',
  'oifde.service.invoke.timeSelect.specificNoWorkDateParameter': 'Specified Non-service Date',
  'oifde.service.invoke.timeSelect.serviceTimeParameter.desc': 'Service period. Configure 1 to 10 records.',
  'oifde.service.invoke.timeSelect.specificWorkDateParameter.desc': 'A maximum of 50 records are supported.',
  'oifde.service.invoke.timeSelect.specificNoWorkDateParameter.desc': 'Specified non-work date. A maximum of 50 records are supported.',
  'oifde.service.invoke.timeSelect.specificWorkDateParameter.title': 'If a configured specified work date is not available in week selection, the service date is considered as a workday.',
  'oifde.service.invoke.timeSelect.specificNoWorkDateParameter.title': 'If a specified non-work date is configured, the non-service date is considered as a non-workday with the highest priority.',
  'oifde.service.invoke.workdays.Monday': 'Monday',
  'oifde.service.invoke.workdays.Tuesday': 'Tuesday',
  'oifde.service.invoke.workdays.Wednesday': 'Wednesday',
  'oifde.service.invoke.workdays.Thursday': 'Thursday',
  'oifde.service.invoke.workdays.Friday': 'Friday',
  'oifde.service.invoke.workdays.Saturday': 'Saturday',
  'oifde.service.invoke.workdays.Sunday': 'Sunday',
  'oifde.service.interfaceType.selfDefinedInterface': 'self defined interface',
  'oifde.service.interfaceType.innerInterface': 'inner interface',
  'oifde.qarecord.interface.name': 'Record Q&A',
  'oifde.qarecord.interface.desc': 'Records the questionnaire ID, question title, user reply intention, and user original reply.',
  'oifde.apiToken.interface.name':'Obtener el token APIFabric',
  'oifde.apiToken.interface.desc': 'Interfaz utilizada para obtener el token API Fabric',
  'oifde.apiToken.applyType.desc': 'Token prefix',
  'oifde.apiToken.accessToken.desc': 'Content of the token',
  'oifde.apiToken.appKey.desc': 'App Key',
  'oifde.apiToken.appSecret.desc': 'App secret',
  'oifde.qarecord.inputparam.seriesId': 'Question and Answer ID',
  'oifde.qarecord.inputparam.questionTitle': 'Question Title',
  'oifde.qarecord.inputparam.questionType': 'Question Type',
  'oifde.qarecord.inputparam.answerIntent': 'Answer Intent',
  'oifde.qarecord.inputparam.originalAnswer': 'Original answer',
  'oifde.service.invoke.queryMsg.MessageQueryType': 'Query Type',
  'oifde.service.invoke.queryMsg.MessageQueryType.desc': 'Queue information application scenario: When a call is transferred to a skill queue, the IVR process is enabled to query queuing information.',
  'oifde.service.invoke.queryMsg.skillMsg': 'Queuing Information',
  'oifde.service.invoke.queryMsg.skillInfo': 'Skill Information',
  'oifde.service.invoke.queryMsg.specifiedSkill': 'Queue Name',
  'oifde.service.invoke.queryMsg.specialListQuery': 'Special List Information',
  'oifde.service.invoke.queryMsg.result': 'Query Result',
  'oifde.service.invoke.queryMsg.MessageQueryQueueIndex': 'Queue Position',
  'oifde.service.invoke.queryMsg.MessageQueryQueueNumber': 'Number of queues',
  'oifde.service.invoke.queryMsg.MessageQueryOnlineNum': 'Number of online agents',
  'oifde.service.invoke.queryMsg.MessageIdleAgentNum': 'Number of idle agents',
  'oifde.service.invoke.queryMsg.MessageQueryWaitingTime': 'Estimated waiting time',
  'oifde.service.invoke.queryMsg.MessageQueryWaitingTime.desc': 'The expected waiting time of queuing is calculated based on the queue model. The result is different. Please use it properly.',
  'oifde.service.invoke.queryMsg.params': 'Request Parameter',
  'oifde.service.invoke.queryMsg.skillQueue': 'Skill Queue',
  'oifde.service.invoke.queryMsg.agentId': 'Agent ID',
  'oifde.service.invoke.queryMsg.specialList': 'Identifier in the special list',
  'oifde.service.invoke.breakMinTime': 'Minimum Voice Playing Duration',
  'oifde.service.invoke.modelType.customization': 'Custom Variable Value',
  'oifde.service.invoke.voiceTtsMixed': 'Voice and text',
  'oifde.service.invoke.ttsSoundEffectCust': 'Custom Sound Effect',
  'oifde.service.invoke.ttsSoundSpeedCust': 'Custom Speed',
  'oifde.service.invoke.modelType.customization.desc': 'Interrupt indicates whether it can be interrupted. If it can be interrupted, fill in the minimum playback time input box. Variables are filled with true or false, true indicates that they can be recognized and broken, and the minimum playback time can be passed. False can be recognized but not broken, and filling in errors is the default value of false.',
  'oifde.service.invoke.CallTransferType.transferToSkillQueueByRoutingCode': 'Skill Queue (Route Extension Code)',
  'oifde.service.invoke.CallTransferType.transferToIVR': 'IVR',
  'oifde.service.invoke.CallTransferType.transferToSkillQueueBySkillName': 'Skill Queue (Name)',
  'oifde.service.invoke.CallTransferType.transferToAgentByID': 'Agent',
  'oifde.service.invoke.CallTransferType.transferToThirdPartyNumber': 'Third-party number',
  'oifde.service.invoke.CallTransferType.onlineDigitalAssistant': 'Online Digital Assistant',
  'oifde.service.invoke.CallTransferType.transferToLastAgent': 'Multimedia familiar customer',
  'oifde.service.invoke.CallTransferType.transferToSkillQueueBelongAgent': 'Skill queue (MAgent)',
  'oifde.service.invoke.CallTransferType.transferToSkillQueueByRouteConfig': 'Transfer to Skill Queue (Customized Route)',
  'oifde.service.invoke.CallTransferType.transferToMultimediaMessageToLastAgent': 'Familiar customer message',
  'oifde.service.invoke.CallTransferType.transferToMultimediaMessageToSkillQueue': 'Skill queue message (name or access code)',
  'oifde.service.invoke.CallTransferType.transferToMultimediaMessageToAgent': 'Message to an Agent',
  'oifde.service.invoke.CallTransferTypeGroup.multimediaMessage': 'Multimedia message',
  'oifde.service.invoke.CallTransferRecordFlag': 'Enable Recording',
  'oifde.service.invoke.CallTransferRecordFlag.desc': '', // 本身就为空不展示
  'oifde.service.invoke.recordPath': 'Record Path',
  'oifde.service.invoke.recordPath.desc': 'Enter the variable for storing the recording file name.',
  'oifde.service.invoke.recordServiceNodeID': 'Record Service Node ID',
  'oifde.service.invoke.recordServiceNodeID.desc': 'Enter the variable that stores the ID of the recording service node (used in the call center pool mode).',
  'oifde.service.invoke.languageType.language0': 'Putonghua',
  'oifde.service.invoke.languageType.language1': 'English',
  'oifde.service.invoke.languageType.language2': 'Chinese(Cantonese)',
  'oifde.service.invoke.languageType.language3': 'Czech',
  'oifde.service.invoke.languageType.language4': 'Danish',
  'oifde.service.invoke.languageType.language5': 'Dutch',
  'oifde.service.invoke.languageType.language6': 'Finnish',
  'oifde.service.invoke.languageType.language7': 'French',
  'oifde.service.invoke.languageType.language8': 'German',
  'oifde.service.invoke.languageType.language9': 'Greek',
  'oifde.service.invoke.languageType.language10': 'Hungarian',
  'oifde.service.invoke.languageType.language11': 'Icelandic',
  'oifde.service.invoke.languageType.language12': 'Italian',
  'oifde.service.invoke.languageType.language13': 'Japanese',
  'oifde.service.invoke.languageType.language14': 'Korean',
  'oifde.service.invoke.languageType.language15': 'Norwegian',
  'oifde.service.invoke.languageType.language16': 'Polish',
  'oifde.service.invoke.languageType.language17': 'Portuguese',
  'oifde.service.invoke.languageType.language18': 'Russian',
  'oifde.service.invoke.languageType.language19': 'Slovak',
  'oifde.service.invoke.languageType.language20': 'Spanish',
  'oifde.service.invoke.languageType.language21': 'Swedish',
  'oifde.service.invoke.languageType.language22': 'Turkish',
  'oifde.service.invoke.languageType.language23': 'Uzbekistan',
  'oifde.service.invoke.languageType.language24': 'Thailand',
  'oifde.service.invoke.languageType.language80': 'Kiswahili',
  'oifde.service.invoke.languageType.language81': 'Arabic',
  'oifde.service.invoke.languageType.language82': 'Hindi',
  'oifde.service.invoke.languageType.language83': 'Filipino',
  'oifde.service.invoke.languageType.language84': 'Cambodian',
  'oifde.service.invoke.languageType.language85': 'Bengali',
  'oifde.service.invoke.languageType.language86': 'Palestinian',
  'oifde.service.invoke.languageType.language87': 'Vietnamese',
  'oifde.service.invoke.languageType.language88': 'Indonesian',
  'oifde.service.invoke.languageType.language89': 'Nepali',
  'oifde.service.invoke.languageType.language90': 'Malagasy',
  'oifde.service.invoke.languageType.language92': 'Ethiopic',
  'oifde.service.invoke.languageType.language93': 'Malaysian',
  'oifde.service.invoke.variableType.type1': 'Phone number',
  'oifde.service.invoke.variableType.type3': 'Number',
  'oifde.service.invoke.variableType.type4': 'Time(hh:mm:ss)',
  'oifde.service.invoke.variableType.type5': 'Date(yyyymmdd)',
  'oifde.service.invoke.variableType.type6': 'Price',
  'oifde.ringService.displayName': 'Ringing',
  'oifde.assignValueService.displayName': 'Assign',
  'oifde.transferService.displayName': 'Transfer',
  'oifde.service.inpytOrDrag': 'enter or dragging variables',
  'oifde.service.operateAsFunction': 'Operate as the provided method',
  'oifde.basicOdfsSubFlowService.displayName': 'Sub Flow(Intelligent)',
  'oifde.basicCcivrSubFlowService.displayName': 'Sub Flow(Common)',
  'oifde.yugaoRecordServiceNew.displayName': 'Recording And Collecting',
  'oifde.conditionServiceNew.displayName': 'Branch Judgment',
  'oifde.service.invoke.callTranseferService.transData.wait': 'Queue Queuing',
  'oifde.service.invoke.callTranseferService.transData.data': 'Transfer Data',
  'oifde.service.invoke.callTranseferService.transData.isSpecifyAccessCode': 'Specified Channel Access Code',
  'oifde.service.invoke.callTranseferService.transData.type': 'Familiar Customer Mode',
  'oifde.service.invoke.callTranseferService.transData.time': 'Time Range (Minutes)',
  'oifde.service.invoke.callTranseferService.transData.timeTips': 'For example, 100 indicates that the call is transferred to the agent who is recently contacted within 100 minutes. The recommended value is 1 to 10080.',
  'oifde.service.invoke.callTranseferService.transData.timeErrorTips': 'Enter a positive integer or variable.',
  'oifde.service.invoke.callTranseferService.transData.starttime': 'Date Range',
  'oifde.service.invoke.callTranseferService.transData.starttimeTips': 'Agents most recently contacted in a specified date range',
  'oifde.service.invoke.callTranseferService.transData.type.selectTime': 'Specified Minutes',
  'oifde.service.invoke.callTranseferService.transData.type.selectStartTime': 'Designated Date',
  'oifde.service.invoke.callTranseferService.transData.paramValueTips': "Enter a parameter value. ",
  'oifde.service.invoke.callTranseferService.transData.varTips': "Variables can be entered. Global variables start with GLOBAL, flow variables, system variables start with SYS, TUC variables start with TOC, and IVR request variables start with IVRREQUEST. Use {'${}'} to wrap variables, for example, {'${FLOW.variable}'}.",
  'oifde.service.invoke.callTranseferService.transData.type.currentDay': 'On the same day',
  'oifde.service.invoke.ruleValidationService.name': 'Rule validation V2',
  'oifde.service.invoke.ruleValidationService.variable': 'Variable to be Verified',
  'oifde.service.invoke.ruleValidationService.specialEndKey': 'End of Special Key',
  'oifde.service.invoke.ruleValidationService.specialEndKey.option1': 'With #',
  'oifde.service.invoke.ruleValidationService.specialEndKey.option2': 'With *',
  'oifde.service.invoke.ruleValidationService.specialEndKey.option3': 'With # or *',
  'oifde.service.invoke.ruleValidationService.specialEndKey.option4': 'Without special keys',
  'oifde.service.invoke.ruleValidationService.containsChar': 'Contains Characters',
  'oifde.service.invoke.ruleValidationService.containsChar.option1': 'Matches numbers',
  'oifde.service.invoke.ruleValidationService.containsChar.option2': 'Matches uppercase letters',
  'oifde.service.invoke.ruleValidationService.containsChar.option3': 'Matches lowercase letters',
  'oifde.service.invoke.ruleValidationService.containsChar.option4': 'Matches letters, digits, and underscores.',
  'oifde.service.invoke.ruleValidationService.containsChar.option5': 'Empty character',
  'oifde.service.invoke.ruleValidationService.validationRules': 'Verification Rules',
  'oifde.service.invoke.ruleValidationService.validationRules.ruleParam': 'Rule Parameters',
  'oifde.service.invoke.ruleValidationService.validationRules.ruleParamValueTips': "Variables can be entered. Global variables start with GLOBAL, flow variables, system variables start with SYS, TUC variables start with TOC, and IVR request variables start with IVRREQUEST. Example: FLOW.variable",
  'oifde.service.invoke.ruleValidationService.validationRules.minLength': 'Minimum Length',
  'oifde.service.invoke.ruleValidationService.validationRules.minLength.valueTips': 'Please enter the minimum length.',
  'oifde.service.invoke.ruleValidationService.validationRules.maxLength': 'Maximum Length',
  'oifde.service.invoke.ruleValidationService.validationRules.maxLength.valueTips': 'Please enter the maximum length.',
  'oifde.service.invoke.ruleValidationService.validationRules.rangeLength': 'Length Range',
  'oifde.service.invoke.ruleValidationService.validationRules.min': 'Min.',
  'oifde.service.invoke.ruleValidationService.validationRules.min.valueTips': 'Please enter a minimum value.',
  'oifde.service.invoke.ruleValidationService.validationRules.max': 'Max.',
  'oifde.service.invoke.ruleValidationService.validationRules.max.valueTips': 'Please enter the maximum value.',
  'oifde.service.invoke.ruleValidationService.validationRules.range': 'Value Range',
  'oifde.service.invoke.ruleValidationService.validationRules.email': 'Email',
  'oifde.service.invoke.ruleValidationService.validationRules.url': 'URL',
  'oifde.service.invoke.ruleValidationService.validationRules.dateFormat': 'Time Format',
  'oifde.service.invoke.ruleValidationService.validationRules.dateFormat.valueTips': 'Please enter a time format, such as date (yyyy-MM-dd) or datetime (yyyy-MM-dd HH:mm:ss).',
  'oifde.service.invoke.ruleValidationService.validationRules.number': 'Decimal Number',
  'oifde.service.invoke.ruleValidationService.validationRules.digits': 'Positive Integer',
  'oifde.service.invoke.ruleValidationService.validationRules.ip': 'IP Address',
  'oifde.service.invoke.ruleValidationService.validationRules.alpha': 'Alphanumeric Underscore (not starting with a number)',
  'oifde.service.invoke.timeSelect.workDate': 'Specify Work Date',
  'oifde.service.invoke.timeSelect.noWorkDate': 'Specify Non-Working Date',
  'oifde.service.invokemethod.offsetMonth.desc': 'Offset by month',
  'oifde.service.invokemethod.offsetMonth.parm1.desc': 'Time variable to be offset. Character string type. Format: yyyyMM',
  'oifde.service.invokemethod.offsetMonth.parm2.desc': 'Offset number, which is an integer and can be negative.',
  'oifde.service.invokemethod.offsetMonth.resultObj.desc': 'The returned object is the offset data.',
  'oifde.service.invokemethod.offsetHour.desc': 'Offset by hour',
  'oifde.service.invokemethod.offsetHour.parm1.desc': 'Time variable to be offset. Character string type. Format: yyyyMMddHHmmss.',
  'oifde.service.invokemethod.offsetHour.parm2.desc': 'Offset number, which is an integer and can be negative.',
  'oifde.service.invokemethod.offsetHour.resultObj.desc': 'The returned object is the offset data.',
  'oifde.interfaceInvoke.displayName': 'Interface Invoking',
  'oifde.service.invoke.yugaoRecord.type': 'Type:',
  'oifde.service.invoke.yugaoRecord.mode': 'Mode:',
  'oifde.service.invoke.subflow.subFlowNode': 'Sub Flow',
  'oifde.service.invokemethod.group.mathodType4': 'Date operation method',
  'oifde.transdata.get.example': 'For example, if the associated data is {\'{\'}"data":{\'{\'}"test": "111"{\'}\'}{\'}\'}, you can enter data.test in the variable value to obtain the data "111".',
  'oifde.service.invoke.basicAvatarReplyService.name': 'Avatar Reply',
  'oifde.service.invoke.basicAvatarReplyService.actionType': 'Operation',
  'oifde.service.invoke.basicAvatarReplyService.start': 'Initiate',
  'oifde.service.invoke.basicAvatarReplyService.image': 'Image',
  'oifde.service.invoke.basicAvatarReplyService.avatarImage': 'Avatar Image',
  'oifde.service.invoke.basicAvatarReplyService.ttsSpeaker': 'Speaker',
  'oifde.service.invoke.basicAvatarReplyService.compositeVideoSetting': 'Composite Video Configuration',
  'oifde.service.invoke.basicAvatarReplyService.compositeVideoSetting.desc': 'Composite video configuration, which directly affects the digital person display effect. Enable this function when required.',
  'oifde.service.invoke.basicAvatarReplyService.imageSetting': 'Picture and Video Settings',
  'oifde.service.invoke.basicAvatarReplyService.action': 'Actions',
  'oifde.service.invoke.basicAvatarReplyService.frontImage': 'Front Image',
  'oifde.service.invoke.basicAvatarReplyService.backendImage': 'Backend Image',
  'oifde.service.invoke.basicAvatarReplyService.frontVideo': 'Front Video',
  'oifde.service.invoke.basicAvatarReplyService.backendVideo': 'Backend Video',
  'oifde.service.invoke.basicAvatarReplyService.xPosition': 'Abscissa',
  'oifde.service.invoke.basicAvatarReplyService.yPosition': 'Ordinate',
  'oifde.service.invoke.basicAvatarReplyService.scale': 'Scale',
  'oifde.service.invoke.basicAvatarReplyService.beginTime': 'Start Time (ms)',
  'oifde.service.invoke.basicAvatarReplyService.duration': 'Display Duration (ms)',
  'service.invoke.virtualHumanConfig': 'Virtual human Configuration',
  'service.virtualHuman.videoParam': 'Video editing parameters',
  'service.virtualHuman.captions': 'Subtitles',
  'service.invoke.asrAdvancedSetting.open': 'Enable',
  'service.invoke.asrAdvancedSetting.close': 'Disable',
}
