<template>
  <sweet-dialog :title="$t('ccivr.ivr.voiceupload.referenceFlow')" v-model="referenceDialogVisible" width="616px"
                class="aicc-dialog-limit-height"
                :close-on-click-modal="false">
    <aicc-advanced-search ref='advancedSearch'
                          :templateMetaData="templateMetaData"
                          @handleSearch='handleSearch'
                          @handleCommand="handleCommand"></aicc-advanced-search>
    <aicc-table
        :tableData="tableData"
        :tableColumns="tableColumns"
        :tableTools="{ showPagination: true, columnConfig: false }"
        :paginationConfig="paginationConfig"
        @handlePagination="handlePagination"
        :height="430"
        ref='aiccTable'>
      <template #source="scope">
        <span v-if="scope.row.source === 'OIFDE'">{{ $t('oifde.route.RobotManage') }}</span>
        <span v-if="scope.row.source === 'IVR'">{{ $t('oifde.route.keyPressRobot') }}</span>
      </template>
    </aicc-table>
  </sweet-dialog>
</template>

<script>

import {queryFlowByResource} from "@/views/oifde/request/api/flow";

export default {
  name: 'resourceReference',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    resourceId: String,
    resourceType: String,
  },
  data() {
    return {
      flowName: '',
      tableData: [],
      tableColumns: [
        {
          label: this.$t('ccivr.ivr.ivrflow.ivrname'),
          prop: 'flowName',
        },
        {
          label: this.$t('ccivr.ivr.ivrflow.version'),
          prop: 'version',
        },
        {
          label: this.$t('oifde.trackmgr.robotsSource'),
          slot: 'source',
        }
      ],
      paginationConfig: {
        pageSize: 10, // 每页条数
        total: 0, //总条数
        offset: 0,
        layout: "total, prev, pager, next" // 分页组件布局配置
      },
      templateMetaData: {
        basicConfig: { // 基础搜索配置
          basicSearch: [
            {
              searchType: 'primary', // primary: 有搜索图标的输入框；select: 下拉框; input: 搜索框；date: 日期选择器
              modelValue: 'flowName', // v-model绑定的key值
              modelDefault: '', // v-model绑定的默认值
              placeholderValue: this.$t('ccivr.ivr.ivrflow.placeholder.ivrname'), // placeholder绑定的值 国际化之后的值
              rules: [],
              changeEvent: 'handleSearch',
              colConfig: {lg: 12, xl: 12},
            }
          ]
        },
      },
    }
  },
  computed: {
    referenceDialogVisible: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      }
    }
  },
  async created() {
    this.queryReferenceIvrInfoById()
  },
  methods: {
    handleSearch({params}) {
      this.paginationConfig.offset = 0;
      this.$refs.aiccTable.setCurrentPage(1);
      this.queryReferenceIvrInfoById(params)
    },
    handleCommand(data) { // 触发事件处理，调用对应方法，方法和eventName同名
      const {eventName, params} = data
      this[eventName](params)
    },
    queryReferenceIvrInfoById(params) {
      const queryParams = {
        offset: this.paginationConfig.offset,
        limit: this.paginationConfig.pageSize,
        resourceType: this.resourceType,
        resourceId: this.resourceId,
      };
      if (params && params.flowName) {
        queryParams.flowName = params.flowName;
      }
      queryFlowByResource(queryParams).then(async resp => {
        // ivr流程引用情况结果返回
        this.paginationConfig.total = resp.total
        this.tableData = resp.records
      })
    },
    handlePagination(data) {
      Object.assign(this.paginationConfig, data)
      let params = this.$refs.advancedSearch.getFormatParams();
      this.queryReferenceIvrInfoById(params);
    },
  }
}
</script>
<style scoped>
.status-icon-text {
  width: 12px;
  height: 12px;
  margin-right: 6px;
}
</style>