export default {
  'isales.manualdata.title.lock': 'ล็อก',
  'isales.taskmanagement.message.queryLimit': 'จำนวนบันทึกในแต่ละหน้าต้องเป็นจำนวนเต็มบวกน้อยกว่า 101',
  'isales.taskresult.field.failsRecordcanceled': 'เมื่อ OBS เปลี่ยนเป็นสถานะสแตนด์บาย\, การบันทึกต่อเนื่องจะถูกยกเลิก',
  'isales.taskpolicy.field.minAvgCallTime': 'ขั้นต่ำ ระยะเวลาการโทรโดยเฉลี่ย',
  'isales.tasktype.field.preview': 'การโทรออกแบบ PREVIEW',
  'isales.blacklist.message.calledno.empty': 'ไม่มีการป้อนหมายเลข',
  'isales.tasktype.field.intelligent': 'โทรออกหุ่นยนต์อัจฉริยะ',
  'isales.busi.result.message.lastappointrecord': 'กรุณาตั้งค่าผลลัพธ์ทางธุรกิจของการโทรออกที่จองไว้ล่าสุดก่อน',
  'isales.manual.field.handlednumber': 'หมายเลขเซสชัน',
  'isales.taskinfo.field.endDate': 'เวลาสิ้นสุด',
  'isales.blacklist.title.calledNo': 'หมายเลขที่โทร',
  'isales.manual.message.data.query.error': 'เกิดข้อผิดพลาดขณะสืบค้นข้อมูลการโทรออก',
  'isales.blacklist.message.customer.notincall': 'ลูกค้าไม่อยู่ในสาย',
  'isales.specialday.message.idEmpty': 'การติดธงวันที่พิเศษว่างเปล่า',
  'isales.manual.message.release.failed': 'การเผยแพร่ข้อมูลการโทรออกล้มเหลว',
  'isales.appointment.title.userName': 'ริเริ่มโดย',
  'isales.export.message.input.error': 'รหัสผ่านไม่อยู่ในรูปแบบที่ถูกต้อง',
  'isales.task.title.callBack': 'ปฏิเสธ',
  'isales.taskpolicy.field.notSupportMultiSkill': 'ไม่รองรับเจ้าหน้าที่แบบไฮบริดสำหรับการโทรเข้าและโทรออก',
  'isales.taskresult.field.failsFsk': 'ข้อความโทรศัพท์ (FSK)',
  'isales.failfile.export.failCalledNo': 'failCalledNo',
  'isales.management.message.createTaskstatusError': 'ไม่สามารถสร้างข้อมูลสำหรับงานการโทรออกได้เนื่องจากไม่อยู่ในสถานะแบบร่างหรือถูกระงับ',
  'isales.taskresult.title.queryTime': 'ข้อมูล',
  'isales.appointment.placeholder.remark': 'กรุณากรอกหมายเหตุ',
  'isales.specialday.title.create': 'สร้าง',
  'isales.management.message.blackCalledNo': 'หมายเลขที่โทรอยู่ในรายการบล็อกและไม่สามารถเพิ่มได้',
  'isales.taskresult.field.failsCallwaiting': 'การรอสาย',
  'isales.taskcalllogic.busiresultpolicy.callnext': 'โทรหมายเลขถัดไป',
  'isales.taskresultexport.message.result': 'ผลลัพธ์',
  'isales.management.message.deleteFailed': 'การลบข้อมูลการโทรออกล้มเหลว',
  'isales.business.result.message.checkCode': 'อนุญาตเฉพาะตัวเลข ตัวอักษร และขีดกลาง (-) เท่านั้น',
  'isales.blacklist.title.deleteError': 'ข้อผิดพลาด',
  'isales.taskresult.field.failsResulttimesoutafterpicksup': 'ผลการตรวจจับการโทรออกหลังจาก ACD offhooking หมดเวลา',
  'isales.management.message.calledNoOneInterval': 'ช่วงการโทรหมายเลขแรก',
  'isales.outboundtime.title.clear': 'ล้าง',
  'isales.specialday.message.confirmDelete': 'คุณต้องการลบข้อมูลใช่หรือไม่',
  'isales.outboundtime.title.timePeriod': 'ส่วนเวลาการโทรออก',
  'isales.appointment.message.updateFail': 'ไม่สามารถแก้ไขการโทรออกที่สำรองไว้',
  'isales.taskresult.field.failsInsufficientresources': 'ทรัพยากรไม่เพียงพอ',
  'isales.taskresultexport.message.downloadConfirmInfo': 'คุณต้องการดาวน์โหลดไฟล์ใช่หรือไม่',
  'isales.tasklogic.field.retryNumber': 'จำนวนครั้งที่ลอง',
  'isales.task.message.specialdayEmpty': 'ไม่ได้เลือกวันพิเศษ',
  'isales.management.message.calledNoEmpty': 'หมายเลขที่โทรว่างเปล่า',
  'isales.taskmanagement.title.outbound': 'การโทรออกของ Chatbot',
  'isales.management.message.calledNoTwo': 'หมายเลขโทรที่สอง',
  'isales.taskresult.field.failsCallqueueoverflow': 'คิวการโทรมากเกินไป',
  'isales.business.result.addchildrentips': 'ผลลัพธ์ทางธุรกิจย่อยสูงสุด 50 รายการที่ได้รับอนุญาตในผลลัพธ์ทางธุรกิจ',
  'isales.taskresult.field.failsFailedRouteSkillQueue': 'การ Route skill queu ล้มเหลว',
  'isales.specialday.message.updateFail': 'แก้ไขวันพิเศษล้มเหลว',
  'isales.validate.message.notSpecial': 'ห้ามมีอักขระพิเศษในชื่อ',
  'isales.taskresult.field.failsNoanswer': 'คู่สายไม่รับสาย',
  'isales.taskresult.field.failsHangsupwhencall': 'เมื่อเจ้าหน้าที่รับสายโดยใช้ตัวควบคุมแถบเจ้าหน้าที่\, สายของเจ้าหน้าที่จะถูกตัดการเชื่อมต่อ',
  'isales.management.message.calledNoFormatIncorrect': 'หมายเลขของ IE จะต้องประกอบด้วยตัวเลขเท่านั้น',
  'isales.appointment.message.deleteSuccess': 'การลบการโทรออกที่สำรองไว้สำเร็จ',
  'isales.taskresultexport.message.begintime': 'เวลาเริ่มต้น',
  'isales.taskinfo.message.endTimeError': 'เวลาสิ้นสุดต้องอยู่หลังจากเวลาปัจจุบัน',
  'isales.taskstatistics.field.completedcalls': 'โทร',
  'isales.specialday.message.dateFormatError': 'รูปแบบวันที่พิเศษไม่ถูกต้อง',
  'isales.specialday.message.createSuccess': 'สร้างวันที่พิเศษสำเร็จ',
  'isales.task.message.saveSuccess': 'กำหนดค่างานการโทรออกสำเร็จ',
  'isales.management.message.deletedataIdsError': 'ต้องลบการตั้งค่าการติดธงของข้อมูลการโทรที่ไม่ถูกต้อง',
  'isales.specialday.field.endDate': 'วันที่สิ้นสุด',
  'isales.import.message.begintime': 'เวลาเริ่มต้น',
  'isales.appointment.placeholder.appointTime': 'เลือกเวลาโทรที่สำรองไว้',
  'isales.taskresult.field.failsUnreachable': 'ไม่สามารถเข้าถึงผู้ใช้บริการเคลื่อนที่ได้',
  'isales.taskmanagement.message.queryTime': 'กรอกเวลาที่สมบูรณ์',
  'isales.taskresult.field.failsPhonenumberisnull': 'หมายเลขโทรออกว่างเปล่า',
  'isales.blacklist.message.customer.empty': 'ไม่มีลูกค้าอยู่ในสาย',
  'isales.appointment.message.callTimeTooEarly': 'เวลาในโทรเร็วกว่าเวลาที่สำรองไว้ การโทรถูกห้าม!',
  'isales.taskresult.field.failsModemorfax': 'ตรวจพบโมเด็มหรือแฟกซ์',
  'isales.business.result.message.must': 'พารามิเตอร์นี้จําเป็น',
  'isales.import.message.uploadedfiletoolarge': 'เลือกไฟล์ที่มีขนาดไม่เกิน 20 MB',
  'isales.taskresult.field.failedtoresume': 'ไม่สามารถกู้คืนงานได้เนื่องจากงานไม่อยู่ในสถานะถูกระงับ',
  'isales.taskresultexport.title.success': 'สำเร็จ',
  'isales.taskmanagement.title.start': 'เริ่ม',
  'isales.taskmanagement.field.zip.makesure.password': 'ป้อนรหัสผ่านแพ็กเกจที่บีบอัดอีกครั้ง',
  'isales.common.title.ready': 'พร้อม',
  'isales.tasklogic.field.callresultpolicy': 'นโยบายผลลัพธ์การโทรออก',
  'isales.taskresult.field.failsBusytone': 'โทนสายไม่ว่าง',
  'isales.specialday.title.chooseDate': 'เลือกวัน',
  'isales.specialday.title.holidayName': 'ชื่อวันพิเศษ',
  'isales.taskresult.field.failsUnknownError': 'ข้อผิดพลาดที่ไม่ทราบสาเหตุ',
  'isales.specialday.message.typeError': 'ประเภทวันที่พิเศษไม่ถูกต้อง',
  'isales.taskresultexport.message.downloadExportdatafailinfo': 'การยืนยันล้มเหลว ไม่พบข้อมูลไฟล์ที่ส่งออก',
  'isales.taskresult.title.endTime': 'เวลาสิ้นสุด',
  'isales.busi.result.message.nodatarecord': 'ไม่พบข้อมูลการโทร กรุณาเลือกบันทึกการโทรออกที่จำเป็นต้องตั้งค่าผลลัพธ์ทางธุรกิจ',
  'isales.management.message.deleteCount': 'ลบบันทึกการโทรออกได้สูงสุดครั้งละ 100 รายการเท่านั้น',
  'isales.outboundtime.field.minute': 'นาที',
  'isales.specialday.message.dateEmpty': 'วันที่พิเศษว่างเปล่า',
  'isales.tasklogic.field.addbusiresultpolicy': 'เพิ่มนโยบายผลลัพธ์ทางธุรกิจ',
  'isales.taskinfo.title.chooseSmsTemplateQuery': 'เลือกเทมเพลต SMS',
  'isales.blacklist.message.createFail': 'การเพิ่มหมายเลขลงในรายการบล็อกล้มเหลว',
  'isales.business.result.searchname': 'กรอกชื่อผลลัพธ์ทางธุรกิจ',
  'isales.specialday.field.Sunday': 'วันอาทิตย์',
  'isales.management.placeholder.skillName': 'กรอกชื่อ skill queue',
  'isales.blacklist.title.expirationTime': 'เวลาหมดอายุ',
  'isales.data.message.confirmSubmit': 'ลำดับหมายเลขปัจจุบันคือลำดับการโทร กรุณาตรวจสอบข้อมูลอีกครั้ง',
  'isales.taskinfo.message.nameExists': 'ชื่องานการโทรออกต้องไม่ซ้ำกัน',
  'isales.taskmanagement.title.export': 'ส่งออกบันทึกการโทร',
  'isales.taskresult.field.failsCalledpartybusy': 'คู่สายไม่ว่าง',
  'isales.taskresult.field.failsCallResourcesInsufficient': 'ทรัพยากรการโทรไม่เพียงพอ',
  'isales.taskresult.field.failsTransferonbusy': 'สายที่ถูกโอนไม่ว่าง',
  'isales.management.title.createNew': 'สร้างข้อมูลการโทรออก',
  'isales.operlog.message.data.downloadexportfile': 'ดาวน์โหลดไฟล์ผลลัพธ์การส่งออกงาน {0}',
  'isales.taskinfo.title.smsTemplate': 'เทมเพลต SMS',
  'isales.taskresult.field.failsCallFailure': 'การโทรล้มเหลว',
  'isales.taskresult.title.operationTime': 'เวลาดำเนินการ',
  'isales.import.message.pleaseuploadfile': 'เลือกไฟล์',
  'isales.common.title.save': 'บันทึก',
  'isales.taskmanagement.title.view.callrecord': 'บันทึกการโทร',
  'isales.tasklogic.field.isResultCallnoLogic': 'กำหนดค่าเป็นไปตามผลลัพธ์หรือไม่',
  'isales.taskresult.title.chooseUserAccount': 'กรุณาเลือกเริ่มต้นโดย',
  'isales.taskresult.field.failsSwitchRestart': 'การสลับเริ่มทำงานใหม่',
  'isales.management.message.taskIdEmpty': 'การติดธงงานการโทรออกว่างเปล่า',
  'isales.input.message.large': 'ค่าอินพุตใหญ่เกินไป',
  'isales.taskstatus.field.all': 'ทั้งหมด',
  'isales.taskcalllogic.message.choosePolicy': 'กรุณาเลือกกลยุทธ์การประมวลผลผลลัพธ์อย่างน้อยหนึ่งรายการ',
  'isales.blacklist.message.remark.error': 'มีอักขระที่ไม่ถูกต้องในหมายเหตุ',
  'isales.taskcalllogic.field.customerMissed': 'พลาดลูกค้าไป',
  'isales.taskpolicy.field.talkingRate': 'ความเร็วในการโทรออก',
  'isales.tasklogic.title.addPolicy': 'เพิ่มนโยบาย',
  'isales.taskpolicy.field.connRateMethod': 'อัลกอริทึมสำหรับอัตราการเชื่อมต่อสายโทรออก',
  'isales.taskresult.field.failsAutomaticdevicedetected': 'ตรวจพบอุปกรณ์อัตโนมัติ',
  'isales.blacklist.title.modifyRemark': 'ปรับเปลี่ยนหมายเหตุ',
  'isales.busi.result.message.recordfail': 'บันทึกผลลัพธ์ทางธุรกิจล้มเหลว',
  'isales.taskstatus.field.execute': 'กำลังดำเนินการ',
  'isales.taskresult.title.busisubstatus': 'สถานะย่อยของธุรกิจ',
  'isales.specialday.title.deleteSuccess': 'สำเร็จ',
  'isales.blacklist.message.error': 'เกิดข้อผิดพลาดเมื่อเพิ่มหมายเลขลงในรายการบล็อก',
  'isales.taskresult.field.failsFaxormodem': 'คู่สายเป็นแฟกซ์หรือโมเด็ม',
  'isales.specialday.message.deleteSpecialday': 'เลือกวันที่พิเศษ',
  'isales.management.title.details': 'รายละเอียด',
  'isales.specialday.message.weeklyFormatError': 'รูปแบบช่วงเวลาวันที่พิเศษไม่ถูกต้อง',
  'isales.specialday.message.weeklyError': 'ช่วงเวลาวันที่พิเศษไม่ถูกต้อง',
  'isales.manual.field.callednumber': 'หมายเลขที่โทร',
  'isales.business.code': 'รหัส',
  'isales.taskinfo.message.beginTimeError': 'เวลาเริ่มต้นต้องไม่เกินกว่าเวลาสิ้นสุด',
  'isales.taskresult.field.failsRecordswrittenback': 'บันทึกที่ล้มเหลวจะถูกส่งกลับเมื่อ OBS ออก (หรืองานถูกลบ)',
  'isales.taskpolicy.field.maxAvgCallTime': 'สูงสุด ระยะเวลาการโทรโดยเฉลี่ย',
  'isales.busi.result.message.agentstatus.fail': 'การสืบค้นสถานะเจ้าหน้าที่ล้มเหลว',
  'isales.taskmanagement.title.begintime': 'เวลาเริ่มต้น',
  'isales.management.title.updateData': 'ปรับเปลี่ยนข้อมูลการโทรออก',
  'isales.taskresult.field.failsSubscriberbusy': 'คู่สายไม่ว่าง',
  'isales.taskresult.field.failsCallRouting': 'ไม่สามารถกำหนดเส้นทางการโทรได้',
  'isales.taskcalllogic.field.notServiceArea': 'ลูกค้าไม่ได้อยู่ในพื้นที่ให้บริการ',
  'isales.taskresult.field.failedtooccupytheagent': 'การครอบครองเจ้าหน้าที่ล้มเหลว',
  'isales.taskinfo.title.commonIvrFlow': 'IVR ปกติ',
  'isales.taskpolicy.field.connRate': 'อัตราการเชื่อมต่อสายโทรออก',
  'isales.manual.title.inputstatus': 'กรอกสถานะของการโทรออก',
  'isales.task.title.complete': 'เสร็จสิ้น',
  'isales.management.title.extendedfield': 'ขยายเขตข้อมูล',
  'isales.taskpolicy.field.utilRateCalculateMethod': 'วิธีการคำนวณการใช้เจ้าหน้าที่',
  'isales.taskpolicy.field.experienceMethod': 'อัลกอริทึมสำหรับการทำนายเชิงประจักษ์',
  'isales.busi.result.item.succ': 'สำเร็จ',
  'isales.specialday.title.add': 'เพิ่มวันที่พิเศษ',
  'isales.blacklist.message.deletePartiallyFail': 'การลบหมายเลขบางส่วนออกจากรายการบล็อกล้มเหลว',
  'isales.taskresult.field.failsInvalidDevice': 'อุปกรณ์ไม่ถูกต้อง',
  'isales.taskresult.field.failsSsit': 'โทนเสียงสำหรับการส่งข้อมูลเฉพาะ',
  'isales.outboundtime.title.beginTime': 'เวลาเริ่มต้น',
  'isales.common.title.edit': 'แก้ไข',
  'isales.taskresult.field.failsDisconnection': 'การโทรล้มเหลว',
  'isales.blacklist.title.deleteSuccess': 'สำเร็จ',
  'isales.taskresult.field.failsFailedRouteCallToAttendant': 'การโอนสายไปยังเจ้าหน้าที่ล้มเหลว',
  'isales.exportdata.message.successinfo': 'กำลังนำข้อมูลออก...ดูรายละเอียดในผลลัพธ์การส่งออก',
  'isales.manual.title.viewPreviewResult': 'ดูตัวอย่างผลลัพธ์การโทรออก -',
  'isales.manual.message.confirmReleaseData': 'ต้องการเผยแพร่ข้อมูลการโทรออกใช่หรือไม่',
  'isales.taskresult.field.failsPlayingvoices': 'การส่งสัญญาณตรวจพบว่าปุ่มเพียร์กำลังเล่นเสียง',
  'isales.taskresultexport.message.downloadFilesuffixfailinfo': 'การดาวน์โหลดล้มเหลว ข้อมูลไฟล์ที่นำออกไม่มีนามสกุลไฟล์',
  'isales.business.result.delete.data.error': 'ลบบันทึกการโทรออกได้สูงสุดครั้งละ 100 รายการเท่านั้น',
  'isales.taskinfo.placeholder.input': '--ป้อน--',
  'isales.common.title.view': 'ดู',
  'isales.taskresult.field.failsWaitresulttimesout': 'หมดเวลารอผลลัพธ์การโทรออกบนแพลตฟอร์ม',
  'isales.taskmanagement.title.pause': 'ระงับ',
  'isales.taskresult.title.inputCallerNo': 'กรอกหมายเลขการโทรออก',
  'isales.taskpolicy.field.utilRate': 'การใช้งานเจ้าหน้าที่',
  'isales.task.field.callSuccess': 'สำเร็จ',
  'isales.manualdata.title.call': 'โทร',
  'isales.outboundtime.title.set': 'ตั้งเวลาการโทรออก',
  'isales.tasklogic.field.policy': 'นโยบาย',
  'isales.task.message.numberCalled': 'โทร: {0}',
  'isales.taskresult.field.no': 'ไม่ใช่',
  'isales.exportdata.message.failinfo': 'การส่งออกข้อมูลล้มเหลว',
  'isales.outboundtime.message.crossover': 'ส่วนเวลาการโทรออกซ้อนทับกัน',
  'isales.task.field.callFail': 'ล้มเหลว',
  'isales.management.message.sessionId': 'หมายเลขเซสชัน',
  'isales.outboundtime.field.hour': 'ชั่วโมง',
  'isales.taskresult.field.success': 'สำเร็จ',
  'isales.taskcalllogic.busiresultpolicy.callcurrent': 'โทรไปยังหมายเลขปัจจุบัน',
  'isales.business.result.edit': 'ปรับเปลี่ยนผลลัพธ์ทางธุรกิจ',
  'isales.taskresult.title.chooseResult': 'กรุณาเลือกผลการโทร',
  'isales.management.message.import': 'นำเข้าข้อมูลผนวก',
  'isales.management.message.deletedataIdsEmpty': 'การติดธงของข้อมูลการโทรออกที่จะลบออกว่างเปล่า',
  'isales.specialday.title.search': 'การสืบค้น',
  'isales.taskmanagement.title.queryTimeTip': 'ข้อมูล',
  'isales.taskresultexport.title.downloadConfirm': 'ตกลง',
  'isales.import.message.result.tip': 'การส่งออกผลลัพธ์ของค่าผลต่างล้มเหลว',
  'isales.taskmanagement.field.zip.password': 'รหัสผ่านของ Compressed package',
  'isales.taskresult.title.failCode': 'สาเหตุ',
  'isales.tasklogic.message.busiresultpolicyNoadded': 'ไม่มีการเพิ่มนโยบายผลลัพธ์ทางธุรกิจ',
  'isales.business.result.view': 'ดูผลลัพธ์ทางธุรกิจ',
  'isales.taskcalllogic.message.mostPolicys': 'ในหนึ่งตัวเลขสามารถกำหนดค่านโยบายผลลัพธ์สูงสุดได้ 10 รายการ',
  'isales.appointment.message.notInWhiteList': 'หมายเลขดังกล่าวไม่อยู่ในรายการที่เชื่อถือและไม่สามารถโทรติดต่อได้',
  'isales.tasklogic.field.addCallnoLogic': 'เพิ่มนโยบายการติดต่อ',
  'isales.manual.message.taskstatus.error': 'การโทรออกไม่อยู่ในสถานะดำเนินการ',
  'isales.business.name': 'ชื่อ',
  'isales.manual.message.not.call': 'ไม่มีการโทรออก',
  'isales.taskmanagement.title.chooseEndtime': 'เวลาสิ้นสุด',
  'isales.blacklist.title.validityTime': 'ช่วงเวลาที่ใช้ได้ (ชั่วโมง)',
  'isales.appointment.message.timeout': '{0} เกินเวลาจองสำหรับการโทรออกที่จองไว้ กรุณาโทรออกตามกำหนดเวลา',
  'isales.common.title.tipsInfo': 'ข้อมูล',
  'isales.taskcalllogic.busiresultpolicy.stopcall': 'หยุดการโทร',
  'isales.input.message.small': 'ค่าที่ป้อนน้อยเกินไป',
  'isales.manual.message.confirmLockData': 'คุณต้องการล็อกข้อมูลการโทรออกใช่หรือไม่',
  'isales.business.result.code': 'รหัสผลลัพธ์ทางธุรกิจ',
  'isales.taskcalllogic.field.shutdown': 'การปิดระบบของลูกค้า',
  'isales.tasklogic.field.result': 'ผลลัพธ์',
  'isales.import.message.success': 'กําลังนําเข้าข้อมูล โปรดแตะรีเฟรชในภายหลังเพื่อรับข้อมูลล่าสุด',
  'isales.manual.message.release.DataNotWaitExists': 'มีข้อมูลการโทรออกที่กำลังโทรหรือเสร็จสิ้น',
  'isales.taskmanagement.field.usersaying': 'ร้องขอข้อความ',
  'isales.taskcalllogic.field.customerbusy': 'ลูกค้าไม่ว่าง',
  'isales.taskcalllogic.field.callTimeout': 'หมดเวลาโทร',
  'isales.specialday.field.Thursday': 'วันพฤหัสบดี',
  'isales.taskinfo.field.beginDate': 'เวลาเริ่มต้น',
  'isales.tasklogic.field.dataType': 'ประเภทข้อมูล',
  'isales.blacklist.message.deleteSuccess': 'ลบหมายเลขออกจากรายการบล็อกเรียบร้อย',
  'isales.taskmanagement.title.deleteSuccess': 'ลบงานสำเร็จ',
  'isales.taskresult.field.failsAcdtimeout': 'ACD หมดเวลา',
  'isales.taskresult.title.chooseCallFlag': 'กรุณาเลือกสถานะ',
  'isales.taskresult.field.failsHangsupbeforeanswering': 'คู่สายวางสายก่อนที่จะรับสาย',
  'isales.taskresult.field.failsTransferbeingalerted': 'โอนสายระหว่างการเรียกเข้า',
  'isales.manual.field.result.fail': 'ล้มเหลว',
  'isales.operlog.message.data.import': 'นำเข้าข้อมูลสำหรับงาน {0}',
  'isales.specialday.field.Tuesday': 'วันอังคาร',
  'isales.taskresult.field.failsCalledmodem': 'คู่สายเป็นโมเด็ม',
  'isales.statistics.field.addHisYes': 'ใช่',
  'isales.tasklogic.field.policyType': 'PolicyType',
  'isales.specialday.title.createspecialday': 'สร้างวันที่พิเศษ',
  'isales.data.title.importData': 'นำเข้า',
  'isales.taskresult.field.failsEmptyorwrong': 'ไม่สามารถติดต่อได้หรือหมายเลขผิด',
  'isales.management.title.import': 'นำเข้าข้อมูลการโทรออก',
  'isales.management.message.filter': 'กรองข้อมูล',
  'isales.task.message.saveFail': 'การกำหนดค่างานโทรออกล้มเหลว',
  'isales.tasklogic.message.busiresultpolicyNotSaved': 'บันทึกนโยบายผลลัพธ์ทางธุรกิจที่มีอยู่ก่อน!',
  'isales.management.placeholder.ivrName': 'ป้อนชื่อกระบวนการหุ่นยนต์อัจฉริยะ',
  'isales.taskresultexport.message.endtime': 'เวลาสิ้นสุด',
  'isales.import.title.statusReady': 'สิ่งที่ต้องดำเนินการ',
  'isales.tasklogic.message.busiresultpolicyIncomplete': 'ข้อมูลนโยบายผลลัพธ์ทางธุรกิจไม่สมบูรณ์',
  'isales.manual.message.update.fail': 'อัปเดตสถานะข้อมูลการโทรออกล้มเหลว',
  'isales.outboundtime.title.period': 'ส่วนเวลาการโทรออก',
  'isales.task.message.pauseSuccess': 'งานการโทรออกถูกระงับเรียบร้อยแล้ว',
  'isales.task.message.statistics': 'สถิติ',
  'isales.taskresult.field.failsRingingtimesout': 'การเรียกเข้าหมดเวลาและแพลตฟอร์มจะปล่อยสาย',
  'isales.taskresult.field.failsRingbacktone': 'เสียงเรียกเข้า',
  'isales.taskmanagement.title.viewTaskResult': 'ผลลัพธ์งาน',
  'isales.manual.message.dataId.Empty': 'dataId ต้องไม่ว่างเปล่า',
  'isales.specialday.title.editSpecialday': 'แก้ไขวันที่พิเศษ',
  'isales.taskresult.field.failsNoacdresource': 'ไม่มีทรัพยากรใน ACD',
  'isales.taskresult.field.failsBeingchanged': 'กำลังเปลี่ยนคู่สายและการโทรถูกโอนสาย',
  'isales.tasklogic.field.enablebusiresultpolicy': 'เปิดใช้นโยบายผลลัพธ์ทางธุรกิจ',
  'isales.taskmanagement.field.taskTime': 'ระยะเวลา',
  'isales.repeatfile.export.repeatCalledNo': 'repeatCalledNo',
  'isales.taskinfo.title.ivrFlow': 'แชทบอทอัจฉริยะ',
  'isales.appointment.message.updateSuccess': 'ปรับเปลี่ยนการโทรออกที่จองไว้สำเร็จ',
  'isales.business.result.addchildren': 'สร้างสถานะย่อย',
  'isales.management.message.sessionIdFormatIncorrect': 'sessionId สามารถมีได้เฉพาะตัวเลข',
  'isales.specialday.message.weeklyEmpty': 'วันที่พิเศษว่างเปล่า',
  'isales.management.message.upload': 'อัปโหลด',
  'isales.management.message.callNoInBlackList': 'ไม่สามารถโทรไปยังหมายเลขดังกล่าวได้เนื่องจากเป็นรายการที่ถูกบล็อก',
  'isales.taskmanagement.title.endtime': 'เวลาสิ้นสุด',
  'isales.manual.message.data.checklock.inblack': 'หมายเลขโทรออกอยู่ในรายการบล็อก',
  'isales.taskresult.field.failsDestinationunreachable': 'ไม่สามารถติดต่อปลายทางได้',
  'isales.taskcalllogic.message.alreadyLastPolicy': 'นโยบายสุดท้ายแล้ว',
  'isales.management.message.calledNoOne': 'หมายเลขที่โทรครั้งแรก',
  'isales.taskmanagement.field.calledNo': 'หมายเลขที่โทร',
  'isales.tasktype.field.predict': 'การโทรออกแบบ PREDICTED',
  'isales.common.title.cancel': 'ยกเลิก',
  'isales.taskpolicy.message.input.digits': 'กรอกจำนวนเต็ม',
  'isales.management.title.warning': 'คำเตือน',
  'isales.taskresultexport.message.downloadRedischeckfailinfo': 'ไฟล์จะดาวน์โหลดได้อีกครั้งก็ต่อเมื่อการดาวน์โหลดก่อนหน้านี้เสร็จสมบูรณ์',
  'isales.management.title.refresh': 'รีเฟรช',
  'isales.taskpolicy.message.input.minAvgCallTime': 'ขั้นต่ำ ระยะเวลาโทรเฉลี่ยต้องไม่เกินค่าสูงสุด ระยะเวลาการโทรโดยเฉลี่ย',
  'isales.taskresult.field.failsDnd': 'การโทรถูกปฏิเสธชั่วคราว',
  'isales.taskresult.field.failsSittone': 'โทนสำหรับข้อมูลพิเศษ',
  'isales.taskstatistics.placeholder.calling': 'กำลังโทร: {0}',
  'isales.business.result.name': 'ชื่อผลลัพธ์ทางธุรกิจ',
  'isales.taskresult.field.failsFak': 'คู่สายคือแฟกซ์',
  'isales.management.message.updateSuccess': 'ข้อมูลการโทรออกถูกปรับเปลี่ยนสำเร็จ',
  'isales.taskresultexport.message.type': 'ประเภทการส่งออก',
  'isales.taskmanagement.message.timeerror': 'เวลาเริ่มต้นต้องไม่เกินเวลาสิ้นสุด',
  'isales.taskstatistics.placeholder.statistics': 'สถิติ',
  'isales.common.title.success': 'สำเร็จ',
  'isales.taskresult.field.failsMusicdetected': 'ตรวจพบเพลง',
  'isales.manutask.title.viewdata': 'ข้อมูลการโทรออก -',
  'isales.taskstatus.field.draft': 'ฉบับร่าง',
  'isales.task.title.preStep': 'ก่อนหน้า',
  'isales.manual.field.result.success': 'สำเร็จ',
  'isales.agent.addblocklist.calldatatype': 'ประเภทลูกค้า',
  'isales.tasklogic.message.busiresultpolicyExists': 'มีนโยบายผลลัพธ์ทางธุรกิจที่มีผลลัพธ์ทางธุรกิจเดียวกันและสถานะย่อยอยู่แล้ว',
  'isales.taskresultexport.message.downloadFail': 'การดาวน์โหลดล้มเหลว',
  'isales.data.message.duplicateExist': 'มีตัวเลขที่ซ้ำกันอยู่',
  'isales.task.message.successRate': 'โอกาสสำเร็จ',
  'isales.specialday.field.beginDate': 'วันที่เริ่มต้น',
  'isales.taskstatistics.field.calling': 'กำลังโทร',
  'isales.taskpolicy.field.failInterval': 'ช่วงเวลาการโทรออก',
  'isales.blacklist.title.createblacklist': 'เพิ่มหมายเลขในบัญชีดำ',
  'isales.manual.message.lock.failed': 'การล็อกข้อมูลการโทรออกล้มเหลว',
  'isales.taskpolicy.field.minChangeTime': 'ขั้นต่ำ ระยะเวลาการค้าง',
  'isales.taskstatistics.placeholder.notcalled': 'รอดำเนินการ: {0}',
  'isales.import.message.template.show': 'เพิ่มข้อมูลลูกค้าตามแถวและเพิ่มหมายเลขโทรหลายหมายเลขตามคอลัมน์',
  'isales.import.message.uploadedfileformatvalidate': 'รูปแบบไฟล์ไม่ถูกต้อง เลือกไฟล์ CSV',
  'isales.tasklogic.field.substate': 'สถานะย่อย',
  'isales.management.title.editPolicy': 'ปรับเปลี่ยนนโยบาย',
  'isales.management.message.remarkLengthError': 'อนุญาตให้มีอักขระได้สูงสุด 1\,000 ตัว',
  'isales.task.message.download': 'ดาวน์โหลดการบันทึก',
  'isales.management.message.uploadSelect': 'เลือกไฟล์',
  'isales.taskmanagement.message.afterNow': 'เวลาเริ่มต้นต้องไม่ช้ากว่าเวลาปัจจุบัน',
  'isales.task.title.callpolicy': 'นโยบายการโทรออก',
  'isales.taskresult.field.failsCallednumberincorrect': 'หมายเลขโทรไม่ถูกต้องในการแสดงตัวอย่าง',
  'isales.import.message.failnum': 'จำนวนบันทึกที่ล้มเหลว',
  'isales.taskresult.field.failsQueuingtimesout': 'คิวหมดเวลาและแพลตฟอร์มปล่อยสายการโทร',
  'isales.common.message.checkpositiveinteger': 'กรอกจำนวนเต็มบวกในรูปแบบที่ถูกต้อง',
  'isales.manual.message.lock.success': 'ข้อมูลการโทรออกถูกล็อกเรียบร้อยแล้ว',
  'isales.management.message.filterNo': 'ไม่ใช่',
  'isales.management.message.calledNoExists': 'หมายการโทรนี้มีอยู่แล้ว',
  'isales.taskresult.title.export': 'นำออก',
  'isales.taskmanagement.title.edit': 'แก้ไข',
  'isales.business.result.delete.error': 'ไม่ได้เลือกผลลัพธ์ทางธุรกิจ',
  'isales.taskcalllogic.field.emptyNumber': 'หมายเลขที่ยังไม่เปิดบริการ',
  'isales.taskcalllogic.title.retryPolicySet': 'นโยบายการทดลองการโทร',
  'isales.task.message.startWarning': 'กำลังนำข้อมูลออก...ไม่สามารถเริ่มงานโทรออกได้',
  'isales.taskinfo.placeholder.choose': '-- เลือก --',
  'isales.taskresult.field.invalidCall': 'การโทรไม่ถูกต้อง',
  'isales.specialday.field.Monday': 'วันจันทร์',
  'isales.taskinfo.field.flowName': 'IVR Flow',
  'isales.taskresult.field.failsUnallocatedOrIncorrectNumber': 'ไม่สามารถติดต่อได้หรือหมายเลขผิด',
  'isales.management.title.edit': 'ปรับเปลี่ยน',
  'isales.operlog.message.data.downloadrepeatfile': 'ดาวน์โหลดไฟล์ข้อมูลที่ซ้ำกัน',
  'isales.taskcalllogic.message.resultTips': 'สามารถมีเพียงหนึ่งกลยุทธ์สำหรับผลลัพธ์เดียว',
  'isales.taskmanagement.title.createTask': 'สร้างงานการโทรออก',
  'isales.taskresult.field.failsPoweredoff': 'โทรศัพท์ปิดอยู่',
  'isales.manual.message.lockData': 'เลือกข้อมูลการโทรออกที่จะล็อก',
  'isales.taskpolicy.field.waitTime': 'ระยะเวลาการรอสายของคู่สาย ',
  'isales.tasklogic.field.existingDataType': 'เลือกประเภทข้อมูลที่มีอยู่',
  'isales.outboundtime.title.endTime': 'เวลาสิ้นสุด',
  'isales.management.message.orderReverse': 'เรียงลำดับจากบนลงล่าง',
  'isales.manual.message.afterNow': 'เวลาสิ้นสุดต้องไม่อยู่ก่อนเวลาปัจจุบัน',
  'isales.import.title.statusExecuting': 'กำลังดำเนินการ',
  'isales.taskresult.field.failsUserrejection': 'สายถูกปฏิเสธ',
  'isales.taskresult.field.failsCallattemptfails': 'การเรียกใช้อินเทอร์เฟซการโทรออกล้มเหลว',
  'isales.taskmanagement.title.query': 'การสืบค้น',
  'isales.tasklogic.field.callInterval': 'ช่วงการโทรออก',
  'isales.taskmanagement.field.Automatic': 'การโทรออกอัตโนมัติ',
  'isales.busi.result.message.recordsucc': 'บันทึกผลลัพธ์ทางธุรกิจสำเร็จ',
  'isales.business.result.description': 'คำอธิบายผลลัพธ์ทางธุรกิจ',
  'isales.management.message.deleteConfirm': 'คุณต้องการลบข้อมูลการโทรออกที่เลือกใช่หรือไม่',
  'isales.specialday.message.crossover': 'กลุ่มเวลาของการโทรออกในวันพิเศษทับซ้อนกัน',
  'isales.taskmanagement.field.statisticsType': 'ประเภทสถิติ',
  'isales.manual.message.data.checklock.error': 'ข้อมูลการโทรออกปัจจุบันไม่ได้ถูกล็อกหรือถูกล็อกโดยเจ้าหน้าที่ท่านอื่น',
  'isales.taskresult.title.beginTime': 'เวลาเริ่มต้น',
  'isales.manual.field.callinginformation': 'ข้อมูลการโทร',
  'isales.specialday.message.isEmpty': 'ไม่มีการตั้งค่าเวลาโทรออกของวันพิเศษ',
  'isales.taskmanagement.message.queryFailed': 'การสืบค้นล้มเหลว',
  'isales.taskresult.title.exportResult': 'ดูผลการส่งออก',
  'isales.blacklist.message.paramEmpty': 'เลือกหมายเลขที่อยู่ในรายการบล็อก',
  'isales.appointment.title.failCode': 'สาเหตุความล้มเหลว',
  'isales.taskresult.field.failsCalledOnHook': 'เจ้าหน้าที่ติดสาย',
  'isales.task.title.callTime': 'ระยะเวลาการโทรออก',
  'isales.outboundtime.message.empty': 'ไม่มีการตั้งเวลาการโทรออก',
  'isales.taskresult.field.failsAgentdevicetimesout': 'การครอบครองของเจ้าหน้าที่หมดเวลา',
  'isales.taskresult.title.busiresultupdate': 'ผนวก Busiresult',
  'isales.repeatfile.export.repeatline': 'โทรซ้ำ',
  'isales.blacklist.title.name': 'ชื่อ',
  'isales.specialday.field.byDate': 'ตามวันที่',
  'isales.taskpolicy.field.predictMethod': 'อัลกอริทึมสำหรับการโทรออกที่คาดการณ์ไว้',
  'isales.specialday.message.dateParseError': 'เกิดข้อผิดพลาดในการแปลงประเภทเวลาของวันที่พิเศษ',
  'isales.manual.title.viewPredictionResult': 'ผลลัพธ์การโทรออกที่คาดการณ์ไว้ -',
  'isales.taskresult.field.failsSwitchingdevicebusy': 'การสลับไม่ว่าง',
  'isales.taskresult.field.failsNumberreacheslimit': 'จำนวนการโทร OBS ถึงขีดจำกัดสูงสุด',
  'isales.appointment.message.createFail': 'การสร้างการโทรออกที่สำรองไว้ล้มเหลว',
  'isales.taskcalllogic.field.globalPolicy': 'นโยบายที่ครอบคลุม',
  'isales.taskresult.field.failsUserhangsupinthequeue': 'เจ้าหน้าที่ทั้งหมดไม่ว่าง \ และผู้ใช้วางสายทันที',
  'isales.taskresult.field.failsAgentDisconnection': 'เจ้าหน้าที่ถูกตัดการเชื่อมต่อ',
  'isales.taskmanagement.field.calltime': 'เริ่มต้นที่',
  'isales.blacklist.placeholder.searchname': 'ชื่อ',
  'isales.taskmanagement.title.addAuto': 'ระบบใหม่โทรออกอัตโนมัติ',
  'isales.taskresult.title.choosebusiresult': 'กรุณาเลือกผลลัพธ์ทางธุรกิจ',
  'isales.taskinfo.field.smsTemplate': 'เทมเพลต SMS สำหรับการโทรออกที่ล้มเหลว',
  'isales.taskinfo.field.description': 'คําอธิบายงาน',
  'isales.specialday.message.createFail': 'การสร้างวันที่พิเศษล้มเหลว',
  'isales.specialday.message.deleteSuccess': 'การลบวันที่พิเศษสำเร็จ',
  'isales.manual.message.call.failed': 'การโทรออกล้มเหลว',
  'isales.failfile.export.failline': 'failLine',
  'isales.taskresult.field.failsAns5': 'ข้อความโทรศัพท์ (ANS5)',
  'isales.appointment.message.queryTimeError': 'เวลาเริ่มต้นต้องมาก่อนเวลาสิ้นสุด',
  'isales.taskresult.field.failsAns4': 'ข้อความโทรศัพท์ (ANS4)',
  'isales.taskresult.field.failsAns3': 'ข้อความโทรศัพท์ (ANS3)',
  'isales.taskresult.field.failsAns2': 'ข้อความโทรศัพท์ (ANS2)',
  'isales.taskresult.field.failsAns1': 'ข้อความโทรศัพท์ (ANS1)',
  'isales.specialday.title.addSpecialTime': 'เพิ่มวันที่พิเศษ',
  'isales.taskresult.title.operateBeginTime': 'beginTime',
  'isales.taskcalllogic.field.callnoPolicy': 'นโยบายการติดต่อ',
  'isales.specialday.message.weeklyRepeat': 'ทำช่วงวันที่พิเศษซ้ำ',
  'isales.taskresult.field.failsNoagentavailable': 'เมื่อ OBS เปลี่ยนเป็นสถานะสแตนด์บาย\, หากไม่มีเจ้าหน้าที่ว่างสำหรับการออกที่ปลอดภัยหรือการโทรออกที่จองไว้อยู่แล้ว\, การบันทึกที่ล้มเหลวจะถูกยกเลิก',
  'isales.taskstatus.field.suspend': 'ถูกระงับ',
  'isales.business.result.message.specialStr': 'ไม่อนุญาตให้ใช้อักขระพิเศษ',
  'isales.specialday.title.reset': 'รีเซ็ต',
  'isales.blacklist.placeholder.searchcalledno': 'หมายเลข',
  'isales.taskcalllogic.message.dataTypeExist': 'DataType มีอยู่แล้ว',
  'isales.specialday.field.byWeek': 'ตามสัปดาห์',
  'isales.tasktype.field.auto': 'ปกติ IVR',
  'isales.busi.result.item.uncertain': 'ไม่แน่นอน',
  'isales.taskresultexport.title.exported': 'ที่จะถูกนำออก',
  'isales.tasklogic.field.connectsameagent': 'เชื่อมต่อกับเจ้าหน้าที่คนเดียวกัน',
  'isales.taskmanagement.message.period.seven': 'ช่วงเวลาต้องไม่เกินกว่า 7 วัน',
  'isales.task.field.callFlagProgress': 'กำลังดำเนินการ',
  'isales.taskmanagement.title.addManual': 'สร้างการโทรออกของเจ้าหน้าที่',
  'isales.task.message.completionRate': 'อัตรางานที่สำเร็จ',
  'isales.tasklogic.title.addCallnoPolicy': 'เพิ่มนโยบายผลลัพธ์',
  'isales.taskmanagement.field.intelligence': 'การโทรออกอัจฉริยะ',
  'isales.specialday.title.holidayType': 'ประเภทวันที่พิเศษ',
  'isales.appointment.title.task': 'งานการโทรออกที่เกี่ยวข้อง',
  'isales.business.result.deletetips': 'คุณต้องการลบผลลัพธ์ทางธุรกิจใช่หรือไม่',
  'isales.taskmanagement.title.chooseBegintime': 'เวลาเริ่มต้น',
  'isales.statistics.field.addHis': 'เก็บข้อมูลถาวร',
  'isales.taskresult.field.failsUndefinedsignaltone': 'ไม่สามารถกำหนดโทนสัญญาณได้',
  'isales.taskresult.message.details': 'รายละเอียด',
  'isales.failfile.export.failReason': 'failReason',
  'isales.callNo.field.delayTime': 'เวลาล่าช้า (วินาที)',
  'isales.task.title.calllogic': 'ตรรกะการโทรออก',
  'isales.taskresult.field.failsCallTimeout': 'การโทรหมดเวลา',
  'isales.taskpolicy.field.multiSkill': 'Skill Queue แบบไฮบริด',
  'isales.taskresult.field.failsObsexits': 'เมื่อ OBS ออก\, การบันทึกต่อเนื่องของสายที่ไม่ได้รับจะถูกยกเลิกและตอบกลับ',
  'isales.business.result.children': 'สถานะย่อย',
  'isales.taskmanagement.field.zip.input.password': 'ป้อนอักขระอย่างน้อยแปดตัวและไม่เกินสิบสองตัวที่มีตัวเลขตัวอักษรและอักขระพิเศษ แต่ไม่ประกอบด้วย /\\-#(){\'{\'}{\'}\'};&{\'|\'}<>{\'$\'}`\\\\!\\n\\s\\t\\r',
  'isales.task.message.numberNotcalled': 'การบันทึกที่ล้มเหลว: {0}',
  'isales.taskpolicy.field.obCallCount': 'จำนวนการโทรออกในแต่ละครั้ง',
  'isales.appointment.message.confirmDelete': 'คุณต้องการลบการโทรออกที่จองไว้ใช่หรือไม่',
  'isales.taskinfo.field.callNo': 'เบอร์โทรเข้า',
  'isales.blacklist.message.name.error': 'ชื่อมีอักขระที่ไม่ถูกต้อง',
  'isales.specialday.title.deleteError': 'ข้อผิดพลาด',
  'isales.taskmanagement.field.manual': 'การโทรออกของเจ้าหน้าที่',
  'isales.operlog.message.data.downloadfailfile': 'ดาวน์โหลดไฟล์ข้อมูลที่ล้มเหลว',
  'isales.blacklist.message.updateSuccess': 'การปรับเปลี่ยนหมายเหตุสำเร็จ',
  'isales.appointment.message.callFail': 'ล้มเหลว',
  'isales.taskpolicy.field.supportMultiSkill': 'รองรับเจ้าหน้าที่แบบไฮบริดสำหรับการโทรเข้าและโทรออก',
  'isales.appointment.title.editAppoint': 'ปรับเปลี่ยนการโทรออกที่จองไว้',
  'isales.taskresult.field.failsTransferunconditionally': 'สายถูกโอนสายโดยไม่มีเงื่อนไข',
  'isales.manual.message.precall.success': 'กำลังเริ่มต้นการโทรออก',
  'isales.tasktype.field.automatic': 'การโทรออกอัตโนมัติ',
  'isales.taskresultexport.title.fail': 'ล้มเหลว',
  'isales.management.message.calledNoTwoCategory': 'หมวดหมู่หมายเลขโทรที่สอง',
  'isales.management.title.detailData': 'รายละเอียดข้อมูลการโทรออก',
  'isales.common.title.dataReset': 'รีเซ็ต',
  'isales.taskresult.title.busiresultLog': 'บันทึกผลลัพธ์ทางธุรกิจ',
  'isales.specialday.field.Wednesday': 'วันพุธ',
  'isales.appointment.message.deleteFail': 'การลบการโทรออกที่จองไว้ที่ล้มเหลว',
  'isales.appointment.message.createSuccess': 'การสร้างการโทรออกที่จองไว้สำเร็จ',
  'isales.taskpolicy.field.utilRateMethod': 'อัลกอริทึมสำหรับเจ้าหน้าที่',
  'isales.taskmanagement.field.prediction': 'การโทรออกแบบ',
  'isales.import.message.succnum': 'จำนวนบันทึกที่สำเร็จ',
  'isales.tasklogic.field.busiresultpolicy': 'นโยบายผลลัพธ์ทางธุรกิจ',
  'isales.exportdata.message.nodata': 'ไม่มีข้อมูลที่ตรงตามเงื่อนไขที่สามารถส่งออกได้!',
  'isales.taskresult.field.failsAgentCancelingCall': 'เจ้าหน้าที่ยกเลิกการโทร',
  'isales.taskmanagement.title.deleteFailed': 'การลบงานล้มเหลว',
  'isales.taskresult.field.failsSubscriberhangsup': 'คู่สายวางสาย',
  'isales.specialday.message.deleteFail': 'การลบวันพิเศษล้มเหลว',
  'isales.taskresult.field.failsDtmf': 'ข้อความโทรศัพท์ (DTMF)',
  'isales.specialday.field.Saturday': 'วันเสาร์',
  'isales.taskresult.field.failsCallednumbernotinwhitelist': 'หมายเลขดังกล่าวไม่อยู่ในรายการการโทรออกที่เชื่อถือได้',
  'isales.appointment.title.createAppoint': 'สร้างการโทรออกที่จองไว้',
  'isales.specialday.title.choose': 'เลือกวันที่พิเศษ',
  'isales.manual.message.agentstatus.isnofree': 'เจ้าหน้าที่ปัจจุบันไม่ได้อยู่ในสถานะว่าง',
  'isales.taskpolicy.field.obOverTime': 'สูงสุด ระยะเวลาตอบสนอง ',
  'isales.blacklist.message.name.reg.empty': 'กฎทั่วไปของชื่อว่างเปล่า ติดต่อผู้ดูแลระบบเพื่อทำการเพิ่ม',
  'isales.taskstatistics.field.notcompletedcalls': 'อยู่ระหว่างดำเนินการ',
  'isales.appointment.title.call': 'โทร',
  'isales.taskresult.field.failsNoAnsweringDeviceAvailable': 'ไม่มีอุปกรณ์ตอบรับว่าง',
  'isales.manual.message.error': 'เกิดข้อผิดพลาดขณะอัปเดตข้อมูล',
  'isales.appointment.message.tasknotrun': 'งานโทรออกที่กำลังดำเนินการอยู่ไม่เริ่มทำงาน การโทรถูกห้าม',
  'isales.taskresult.title.queryArchivedData': 'สืบค้นข้อมูลที่เก็บถาวร',
  'isales.specialday.message.deletePartiallyFail': 'การลบวันพิเศษบางวันล้มเหลว',
  'isales.taskresult.title.chooseBeginTime': 'กรุณาเลือกเวลาเริ่มต้น',
  'isales.taskinfo.message.agentsEmpty': 'ไม่ได้เลือกเจ้าหน้าที่',
  'isales.taskcalllogic.title.resultSetPolicy': 'กำหนดค่านโยบายตามผลลัพธ์',
  'isales.management.message.createSuccess': 'การสร้างข้อมูลการโทรออกสำเร็จ',
  'isales.manual.message.task.isnorunning': 'งานการโทรออกปัจจุบันไม่อยู่ในสถานะกำลังดำเนินการ',
  'isales.taskstatistics.field.failCount': 'จำนวนการโทรที่ล้มเหลว',
  'isales.taskresult.title.callResult': 'ผลลัพธ์',
  'isales.taskinfo.field.noSpecifiedAgent': 'คิวสกิลทั้งหมด',
  'isales.taskinfo.field.specifiedAgent': 'เจาะจงเจ้าหน้าที่',
  'isales.appointment.message.callSuccess': 'สำเร็จ',
  'isales.taskresult.field.failsInvalidUser': 'ผู้ใช้งานไม่ถูกต้อง',
  'isales.taskmanagement.title.inputTaskName': 'กรอกชื่องาน',
  'isales.task.message.timeIncompletes': 'ส่วนเวลาของการโทรออกเดี่ยวไม่สมบูรณ์',
  'isales.data.message.confirmaddblock.calldatatype': 'ประเภทปัจจุบันคือข้อมูลการโทร กรุณายืนยันอีกครั้ง',
  'isales.blacklist.message.deleteBlacklist': 'เลือกหมายเลขที่อยู่ในรายการบล็อกที่ต้องการจะลบ',
  'isales.taskpolicy.field.lossRate': 'ขั้นต่ำ อัตราสายการโทรหลุด',
  'isales.taskinfo.field.taskType': 'ประเภทงาน',
  'isales.taskinfo.title.chooseCommonIvrFlow': 'เลือก IVR ปกติ',
  'isales.management.placeholder.commonIvrName': 'ป้อนชื่อโพรเซส IVR ปกติ',
  'isales.common.message.checknonnegativeinteger': 'กรอกจำนวนเต็มที่ไม่เป็นลบในรูปแบบที่ถูกต้อง',
  'isales.management.message.createFailed': 'การสร้างข้อมูลการโทรออกล้มเหลว',
  'isales.tasklogic.message.busiresultpolicySame': 'ผลลัพธ์ทางธุรกิจและสถานะย่อยของนโยบายผลลัพธ์ทางธุรกิจหลายรายการเหมือนกัน',
  'isales.taskmanagement.title.callRecord': 'บันทึกการโทรออก',
  'isales.appointment.message.callNotAllowed': 'ไม่สามารถจองการโทรออกได้เนื่องจากเจ้าหน้าที่ไม่ได้อยู่ในสถานะว่างหรือไม่ว่าง',
  'isales.taskresult.field.userisunleashed': 'การโทรบนเซิร์ฟเวอร์สื่อที่เจ้าหน้าที่เข้าสู่ระบบถูกปล่อยสาย',
  'isales.common.title.fail': 'ล้มเหลว',
  'isales.data.title.tableTitle': 'รายการหมายเลข',
  'isales.taskpolicy.message.saveSuccess': 'การกำหนดค่านโยบายการโทรออกสำเร็จ',
  'isales.callno.message.delayTime': 'เวลาดีเลย์: ช่วงเวลาระหว่างหมายเลขปัจจุบันและหมายเลขก่อนหน้า',
  'isales.manual.title.viewManualData': 'ข้อมูลการโทรออกของตัวแทน -',
  'isales.taskresult.field.failsTimeout': 'ระบบหมดเวลา',
  'isales.exportdata.title.success': 'สำเร็จ',
  'isales.taskresult.field.failsCalldatatimeout': 'ข้อมูลการโทรหมดเวลา',
  'isales.management.message.filterYes': 'ใช่',
  'isales.task.field.callFlagExecuted': 'สิ่งที่ต้องดำเนินการ',
  'isales.tasklogic.field.interval': 'ช่วงเวลา (วินาที)',
  'isales.exportdata.title.fail': 'ล้มเหลว',
  'isales.manual.field.callflag.return': 'ย้อนกลับ',
  'isales.taskpolicy.message.input.maxAvgCallTime': 'สูงสุด ระยะเวลาโทรเฉลี่ยต้องไม่น้อยกว่าค่าต่ำสุด ระยะเวลาการโทรโดยเฉลี่ย',
  'isales.taskmanagement.field.dailyStatistics': 'สถิติรายวัน',
  'isales.taskmanagement.title.appoint': 'จองการโทรออก',
  'isales.taskresult.field.failsTransfernoreply': 'สายถูกโอนไปโดยไม่มีการตอบกลับ',
  'isales.manual.message.release.success': 'เผยแพร่ข้อมูลการโทรออกสำเร็จ',
  'isales.taskresult.field.failsFailedRouteAgent': 'การกำหนดเส้นทางการโทรไปยังเจ้าหน้าที่ล้มเหลว',
  'isales.taskresult.field.failsResourceOverload': 'ทรัพยากรไม่เพียงพอ',
  'isales.task.message.numberFailed': 'จำนวนบันทึกที่ล้มเหลว',
  'isales.tasktype.field.intelligence': 'การโทรออกอัจฉริยะ',
  'isales.manual.title.result': 'ผลลัพธ์',
  'isales.specialday.placeholder.searchname': 'กรอกชื่อวันที่พิเศษ',
  'isales.business.result.message.add.tips': 'ไม่ได้กำหนดผลลัพธ์ทางธุรกิจ',
  'isales.manualdata.title.updateCalledNo': 'เปลี่ยนหมายเลขขาออก',
  'isales.manualdata.title.order': 'จองการโทรออก',
  'isales.taskresult.field.failedtopause': 'ไม่สามารถระงับงานได้เนื่องจากอยู่ในสถานะถูกระงับแล้ว',
  'isales.taskresult.field.failsNoresponse': 'คู่สายไม่ตอบสนอง',
  'isales.task.message.startSuccess': 'เริ่มต้นงานโทรออกสำเร็จ',
  'isales.taskcalllogic.field.other': 'อื่น ๆ',
  'isales.taskresult.title.busidesc': 'คำอธิบายธุรกิจ',
  'isales.taskresult.message.queryTime': 'กรอกเวลาที่สมบูรณ์',
  'isales.taskresult.field.failsNotintheservicearea': 'โทรศัพท์ไม่สามารถใช้งานได้',
  'isales.import.title.result': 'ดูผลการนำเข้า',
  'isales.taskmanagement.title.viewAppointList': 'การจอง',
  'isales.taskmanagement.title.viewAppointListDetail': 'รายละเอียดการจอง',
  'isales.taskmanagement.title.editPolicy': 'แก้ไขนโยบาย',
  'isales.manual.title.inputfailcode': 'กรอกสาเหตุความล้มเหลว',
  'isales.taskresult.field.failsFaxdetected': 'ตรวจพบแฟกซ์',
  'isales.taskresult.field.failsNoresource': 'ไม่มีทรัพยากร',
  'isales.management.title.toblocklist': 'เพิ่มในรายการบล็อก',
  'isales.taskresult.field.failsReleasesbeforecall': 'ก่อนที่จะตั้งค่าช่องทางเสียง\, เจ้าหน้าที่จะปล่อยสายโดยใช้แถบการควบคุมของเจ้าหน้าที่',
  'isales.taskmanagement.message.deleteConfirm': 'คุณต้องการที่จะลบใช่หรือไม่',
  'isales.tasklogic.field.retryTimes': 'สูงสุด จำนวนความพยายามในการโทรรายวัน',
  'isales.specialday.message.deleteTips': 'ไม่สามารถลบวันที่พิเศษได้เนื่องจากมีความเกี่ยวโยงกับงานการโทรออก',
  'isales.taskmanagement.message.password.not.same': 'รหัสผ่านทั้งสองไม่สอดคล้องกัน',
  'isales.import.message.taskstatuswrong': 'ไม่สามารถนำเข้าข้อมูลไปยังงานการโทรออกได้เนื่องจากไม่อยู่ในสถานะแบบร่างหรือถูกระงับ',
  'isales.manual.field.callflag.inprocess': 'กำลังโทร',
  'isales.taskresult.field.failsFailedAgentIsBusy': 'เจ้าหน้าที่ไม่ได้อยู่ในสถานะว่าง',
  'isales.taskcalllogic.field.fax': 'แฟกซ์ \ สวิตช์บอร์ด \ ฯลฯ',
  'isales.taskmanagment.title.viewstatistics': 'ดูสถิติ',
  'isales.taskinfo.message.input': 'อักขระที่ไม่ถูกต้อง',
  'isales.task.message.numbersTotal': 'รวม: {0}',
  'isales.tasklogic.message.busiresultpolicyNotsaved': 'นโยบายผลลัพธ์ทางธุรกิจบางอย่างไม่ได้ถูกบันทึก',
  'isales.management.message.deleteTaskstatusError': 'ไม่สามารถลบงานการโทรออกเนื่องจากไม่ได้อยู่ในสถานะร่างหรือสถานะหยุดชั่วคราว',
  'isales.data.title.create': 'ใหม่',
  'isales.taskpolicy.field.previewTimeoutInterval': 'ดูตัวอย่างช่วงหมดเวลา',
  'isales.exportdata.title.exportresult': 'ผลการส่งออก',
  'isales.taskinfo.message.callintervalrange': 'กรุณาป้อนจำนวนเต็มตั้งแต่ 1 ถึง 3600',
  'isales.manual.field.callingtask': 'งานโทร',
  'isales.tasktype.field.prediction': 'การโทรออกแบบ PREDICTED',
  'isales.task.title.nextStep': 'ต่อไป',
  'isales.manual.message.query.agentstatus.error': 'เกิดข้อผิดพลาดเมื่อสืบค้นสถานะเจ้าหน้าที่',
  'isales.data.import.task.status.ready': 'การเตรียมพร้อม',
  'isales.task.message.startFail': 'การเริ่มงานโทรออกล้มเหลว',
  'isales.task.message.pauseFail': 'การระงับงานโทรออกล้มเหลว',
  'isales.specialday.field.byYear': 'ทำซ้ำตามปี',
  'isales.busi.result.title.result': 'ผลลัพธ์',
  'isales.task.message.numberSuccessful': 'จำนวนบันทึกที่สำเร็จ',
  'isales.data.filed.noResult': 'ไม่ใช่',
  'isales.specialday.title.edit': 'แก้ไข',
  'isales.taskresult.field.failsInvalidRoutingDevice': 'อุปกรณ์กำหนดเส้นทางไม่ถูกต้อง',
  'isales.blacklist.message.calledno.error': 'ตัวเลขมีอักขระที่ไม่ถูกต้อง',
  'isales.taskmanagement.field.taskName': 'ชื่องาน',
  'isales.taskresult.field.failsResultnotobtained': 'ข้อมูลการโทรออกจะถูกตอบกลับเมื่อไม่ได้รับผลลัพธ์หลังจากหมดเวลาการโทร',
  'isales.taskmanagement.title.configData': 'ข้อมูลการโทรออก',
  'isales.taskcalllogic.title.resultPolicySet': 'ผลลัพธ์กลยุทธ์การประมวลผล',
  'isales.taskmanagement.field.answer': 'ข้อความตอบกลับ',
  'isales.specialday.title.warning': 'คำเตือน',
  'isales.taskresult.field.failsAgentHangsUp': 'เจ้าหน้าที่วางสาย',
  'isales.validate.message.formatError': 'กรอกวันที่ในรูปแบบ MM / DD',
  'isales.common.title.close': 'ปิด',
  'isales.taskresult.title.operateEndTime': 'endTime',
  'isales.taskresult.field.failsNoValidVDN': 'ไม่มี VDN ที่ถูกต้อง',
  'isales.manual.message.empty': '{0} ว่างเปล่า',
  'isales.taskresult.field.failsInvalidSkillQueue': 'Skill Queue ไม่ถูกต้อง',
  'isales.blacklist.message.confirmDelete': 'คุณต้องการลบหมายเลขที่เลือกออกจากรายการบล็อกใช่หรือไม่',
  'isales.validate.message.holidayDateComparisonError': 'วันที่สิ้นสุดต้องไม่อยู่ก่อนวันที่เริ่มต้น',
  'isales.manual.field.previewinformation': 'ดูตัวอย่างข้อมูล',
  'isales.specialday.message.idError': 'ธงวันที่พิเศษไม่ถูกต้อง',
  'isales.taskresult.field.failsAutomaticansweringmachine': 'คู่สายเป็นเครื่องตอบรับอัตโนมัติ',
  'isales.specialday.title.holiday': 'วันที่พิเศษ',
  'isales.taskresultexport.title.download': 'ดาวน์โหลด',
  'isales.data.message.addNumberUpperLimit': 'สามารถเพิ่มได้สูงสุด 10 หมายเลข',
  'isales.specialday.message.nameError': 'ชื่อวันที่พิเศษอยู่ในรูปแบบที่ไม่ถูกต้อง',
  'isales.management.message.calledNoOneCategory': 'หมวดหมู่ของหมายเลขโทรครั้งแรก',
  'isales.taskmanagement.title.reset': 'รีเซ็ต',
  'isales.management.title.deleteInfo': 'เลือกข้อมูลการโทรออก',
  'isales.manual.message.call.success': 'โทรออกได้สำเร็จ',
  'isales.blacklist.message.createSuccess': 'การเพิ่มหมายเลขลงในรายการบล็อกสำเร็จ',
  'isales.taskinfo.message.require': 'กำหนดเขตข้อมูลที่จำเป็นทั้งหมด',
  'isales.import.message.fail': 'การนำเข้าข้อมูลล้มเหลว',
  'isales.appointment.message.agentNotCalling': 'ไม่สามารถเพิ่มการโทรออกที่จองไว้ได้เนื่องจากตัวแทนไม่ได้อยู่ในสาย',
  'isales.busi.result.item.unset': 'ไม่ได้ตั้งค่า',
  'isales.taskmanagement.field.intention': 'ตรวจพบเจตนา',
  'isales.taskresult.field.failsUnallocatednumber': 'หมายเลขที่ไม่ได้จัดสรร',
  'isales.management.message.calledNoLengthError': 'ความยาวของหมายเลขที่โทรเกินขีดจำกัด',
  'isales.import.message.repeatnum': 'จำนวนบันทึกที่ซ้ำกัน',
  'isales.taskcalllogic.field.customerRefused': 'ลูกค้าปฏิเสธ',
  'isales.import.message.uploadedfileNameformatvalidate': 'ชื่อไฟล์ไม่ถูกต้อง เลือกไฟล์ที่ชื่อมีเฉพาะตัวอักษรจีน \, อักษรตัวพิมพ์เล็ก \, ตัวเลข \ หรือขีดล่าง (_)',
  'isales.management.message.orderSequence': 'เรียงลำดับจากล่างขึ้นบน',
  'isales.taskresult.field.yes': 'ใช่',
  'isales.taskresult.field.failsNumberchange': 'หมายเลขมีการเปลี่ยนแปลง',
  'isales.common.title.operation': 'การดำเนินการ',
  'isales.taskresult.field.failsNoreply': 'คู่สายไม่รับสาย',
  'isales.taskresult.field.failsReleaseswhencall': 'เมื่อช่องเสียงถูกตั้งค่าแล้ว\, เจ้าหน้าที่จะปล่อยสายโดยใช้แถบการควบคุมของเจ้าหน้าที่',
  'isales.basicinfo.title.chooseCallNo': 'เลือกหมายเลขโทร',
  'isales.specialday.message.nameExists': 'ชื่อวันพิเศษมีอยู่แล้ว',
  'isales.management.message.updateFail': 'การแก้ไขข้อมูลการโทรออกล้มเหลว',
  'isales.taskmanagement.message.queryDateError': 'วันที่ไม่ถูกต้อง เลือกอย่างอื่น',
  'isales.taskresult.field.failsAgentfailswithuser': 'ไม่สามารถตั้งค่าช่องเสียงระหว่างเจ้าหน้าที่และผู้ใช้บริการ',
  'isales.manual.title.lock': 'ล็อก',
  'isales.manual.title.viewManualResult': 'ผลการโทรออกของเจ้าหน้าที่ -',
  'isales.busi.result.item.fail': 'ล้มเหลว',
  'isales.management.placeholder.commonIvrFlowAccessCode': 'กรอกรหัสการเข้าถึง common IVR flow',
  'isales.calledNo.message.input': 'อนุญาตเฉพาะตัวเลขเท่านั้น',
  'isales.taskresult.field.other': 'อื่น ๆ',
  'isales.blacklist.message.requestEmpty': 'การตรวจสอบความถูกต้องของพารามิเตอร์สำหรับการเพิ่มหมายเลขลงในรายการบล็อกล้มเหลว',
  'isales.management.placeholder.calledNo': 'กรอกหมายเลขที่โทร',
  'isales.common.title.confirm': 'ตกลง',
  'isales.appointment.title.callTime': 'ส่วนเวลาการโทร',
  'isales.data.import.task.status.executing': 'กำลังนำเข้า',
  'isales.blacklist.message.calledno.exist': 'หมายเลขอยู่ในรายการบล็อก',
  'isales.taskresultexport.title.progress': 'กำลังส่งออก',
  'isales.taskinfo.field.audioAgentType': 'เสียง',
  'isales.taskinfo.field.videoAgentType': 'วิดีโอ',
  'isales.taskinfo.field.mediaAgentType': 'มัลติมีเดีย',
  'isales.taskinfo.field.versatileAgentType': 'อเนกประสงค์',
  'isales.taskresult.field.failsModemdetected': 'ตรวจพบโมเด็ม',
  'isales.appointment.message.soontimeout': '{0} การโทรออกที่ได้รับการนัดหมายจะมาถึงใน 5 นาทีถัดไปกรุณาเตรียมตัวที่จะโทร',
  'isales.taskresult.title.result': 'ผลการโทร',
  'isales.busi.result.message.lastrecord': 'กรุณาตั้งค่าผลลัพธ์ทางธุรกิจของการโทรออกล่าสุดก่อน',
  'isales.agent.toblocklist.addType': 'เพิ่มประเภท blockList',
  'isales.data.title.importResult': 'ผลลัพธ์การนำเข้า',
  'isales.taskpolicy.field.pickupRateMethod': 'อัลกอริทึมสำหรับอัตรา Offhook',
  'isales.manual.message.call.update.error': 'การอัปเดตผลลัพธ์การโทรออกล้มเหลว',
  'isales.management.message.calledNo': 'หมายเลขที่โทร',
  'isales.management.message.importOrder': 'ลําดับการนําเข้า',
  'isales.busi.result.message.systemparams.fail': 'การสืบค้นพารามิเตอร์ของระบบล้มเหลว',
  'isales.taskresult.field.failsTasknotexist': 'ไม่มีงานโทรออก',
  'isales.manual.message.releaseData': 'เลือกข้อมูลการโทรออกที่จะเผยแพร่',
  'isales.taskresult.field.failsWithoutringing': 'สายถูกโอนไปด้วยการตอบสนองทันทีก่อนที่จะเรียกเข้า',
  'isales.task.message.startTips': 'งานนี้หมดอายุแล้ว ไม่สามารถเริ่มงานโทรออกได้',
  'isales.specialday.title.timeSet': 'กำหนดเวลาโทรออกในวันที่พิเศษ',
  'isales.blacklist.title.operateAgent': 'เพิ่มเจ้าหน้าที่',
  'isales.management.message.download': 'ดาวน์โหลดเทมเพลตข้อมูลการโทรออก',
  'isales.taskresult.field.failsCallrejected': 'สายถูกปฏิเสธ',
  'isales.taskmanagement.field.statisticsDate': 'วันที่รวบรวมสถิติ',
  'isales.management.title.callbot': 'การโทรออกของ Chatbot',
  'isales.taskresult.title.choosebusisubstatus': 'กรุณาเลือกผลลัพธ์ทางธุรกิจก่อนเลือกสถานะย่อย',
  'isales.appointment.message.afterNow': 'เวลาที่จองไว้ต้องไม่อยู่ก่อนเวลาปัจจุบัน',
  'isales.taskpolicy.message.saveFail': 'การกำหนดค่างานโทรออกล้มเหลว',
  'isales.specialday.field.Friday': 'วันศุกร์',
  'isales.taskresult.field.failsEdt': 'ข้อความโทรศัพท์ (EDT)',
  'isales.management.message.calledNoTwoInterval': 'ช่วงเวลาโทรหมายเลขที่สอง',
  'isales.management.message.deleteSuccess': 'การลบข้อมูลการโทรออกสำเร็จ',
  'isales.manual.message.data.isalready.called': 'ลูกค้าทำการโทรแล้วไม่สามารถโทรได้อีก',
  'isales.taskresult.title.userName': 'ชื่อผู้ใช้',
  'isales.import.message.endtime': 'เวลาสิ้นสุด',
  'isales.specialday.message.updateSuccess': 'การปรับปรุงวันที่พิเศษสำเร็จ',
  'isales.taskresult.title.chooseEndTime': 'กรุณาเลือกเวลาสิ้นสุด',
  'isales.data.title.edit': 'แก้ไข',
  'isales.data.title.undoEdit': 'เลิกแก้ไข',
  'isales.tasklogic.field.retryDay': 'สูงสุด จำนวนวันสำหรับความพยายามโทรก่อนการเชื่อมต่อ',
  'isales.manual.title.inputresult': 'กรอกผลลัพธ์การโทรออก',
  'isales.management.title.calledNo': 'หมายเลขที่โทร',
  'isales.manual.message.call.error': 'เกิดข้อผิดพลาดขณะประมวลผลผลลัพธ์การโทรออก',
  'isales.taskresult.field.failsCpp': 'การโอนสายต่อโดยการเกี่ยว',
  'isales.data.filed.isResult': 'ใช่',
  'isales.operlog.message.data.downloadtemplate': 'ดาวน์โหลดเทมเพลตการนำเข้าข้อมูล',
  'isales.taskresult.field.failsNoreason': 'โอน DTE โดยไม่มีเงื่อนไข',
  'isales.taskcalllogic.field.callFailed': 'การโทรล้มเหลว',
  'isales.task.message.play': 'เล่น',
  'isales.callNo.title.no': 'ไม่ใช่',
  'isales.taskresult.title.failedDetail': 'รายละเอียดการโทรที่ล้มเหลว',
  'isales.manual.title.fail.reason': 'สาเหตุความล้มเหลว',
  'isales.taskstatistics.field.failCode': 'สาเหตุความล้มเหลว',
  'isales.taskresult.title.userAccount': 'ริเริ่มโดย',
  'isales.taskresult.title.resultDetail': 'รายละเอียดผลลัพธ์การโทร',
  'isales.data.import.task.status.error': 'ข้อผิดพลาด',
  'isales.taskresult.field.failsAbnormal': 'ข้อผิดพลาด',
  'isales.appointment.title.appointTime': 'เวลาโทรที่จองไว้',
  'isales.taskstatistics.placeholder.called': 'โทร: {0}',
  'isales.taskresult.field.failsInvalidstatus': 'สถานะ DTE ไม่ถูกต้อง',
  'isales.taskmanagement.field.outboundType': 'ประเภทการโทร',
  'isales.tasklogic.field.addDataType': 'เพิ่ม DataType',
  'isales.business.result.create': 'สร้างผลลัพธ์ทางธุรกิจ',
  'isales.speclist.blacklist.message.moveSuccess': 'โอนหมายเลขเข้าบัญชีดำเรียบร้อยแล้ว',
  'isales.speclist.blacklist.message.movePartiallyFail': 'การโอนหมายเลขไปยังบัญชีดำล้มเหลว',
  'isales.common.title.dataSearch': 'ค้นหา',
  'isales.data.import.task.status.complete': 'เสร็จสมบูรณ์',
  'isales.import.message.status': 'สถานะ',
  'isales.import.title.statusComplete': 'เสร็จสมบูรณ์',
  'isales.management.title.busiresult': 'ผลลัพธ์ทางธุรกิจ',
  'isales.specialday.title.delete': 'ลบ',
  'isales.specialday.title.scoreDetail': 'รายละเอียดคะแนน',
  'isales.task.field.callFlagCompletion': 'เสร็จสมบูรณ์',
  'isales.task.title.basicInfo': 'ข้อมูลพื้นฐาน',
  'isales.task.title.taskSample': 'การจัดการวัตถุ',
  'isales.taskinfo.field.skillName': 'คิวทักษะ',
  'isales.taskinfo.title.chooseIvrFlow': 'เลือก IVR Flow',
  'isales.taskinfo.title.chooseSkill': 'เลือกคิวทักษะ',
  'isales.taskinfo.title.skillName': 'คิวทักษะ',
  'isales.taskmanagement.title.status': 'สถานะ',
  'isales.taskresult.title.calledNo': 'หมายเลขโทรออก',
  'isales.taskresult.title.callFlag': 'สถานะ',
  'isales.taskstatus.field.finish': 'หมดอายุแล้ว',
  'isales.blacklist.title.remark': 'หมายเหตุ',
  'isales.blacklist.placeholder.searchremark': 'หมายเหตุ',
  'isales.appointment.title.remark': 'หมายเหตุ',
  'isales.manual.title.status': 'สถานะ',
  'isales.manual.title.calledNo': 'หมายเลขโทรออก',
  'isales.manual.field.callflag.finish': 'เสร็จสมบูรณ์',
  'isales.manutask.title.viewresult': 'ผลลัพธ์การโทรออก',
  'isales.manualdata.title.release': 'ยกเลิก',
  'isales.taskinfo.title.chooseAgent': 'เลือกเจ้าหน้าที่',
  'isales.appointment.title.add': 'เพิ่ม',
  'isales.agent.addblocklist.numbertype': 'ประเภทหมายเลข',
  'isales.taskmanagement.title.out.callresult': 'ผลลัพธ์การโทรออก',
  'isales.taskresult.title.retryTime': 'ความพยายามในการโทร',
  'isales.callNo.field.type': 'ประเภทหมายเลข',
  'isales.callNo.field.add': 'เพิ่ม',
  'isales.busi.result.title.description': 'คำอธิบาย',
  'isales.callpolicy.title.add': 'เพิ่ม',
  'isales.business.description': 'คำอธิบาย',
  'isales.taskresult.field.skillqueue': 'คิวทักษะ',
  'isales.data.prop.publish': 'เผยแพร่',
  'isales.data.prop.status.published': 'เผยแพร่แล้ว',
  'isales.taskinfo.field.callTimes': 'ความพยายามในการโทร',
  'isales.taskmanagement.title.batchStartTask': 'เริ่มเป็นชุด',
  'isales.taskmanagement.title.batchPauseTask': 'ระงับเป็นชุด',
  'isales.taskmanagement.title.batchEditTaskInfo': 'แก้ไขข้อมูลพื้นฐานเป็นชุด',
  'isales.taskmanagement.title.batchEditCallTime': 'เปลี่ยนเวลาการโทรออกเป็นชุด',
  'isales.taskmanagement.title.batchEditCallPolicy': 'แก้ไขนโยบายการโทรออกเป็นชุด',
  'isales.taskmanagement.title.batchEditCallLogic': 'แก้ไขตรรกะการโทรออกเป็นชุด',
  'isales.taskmanagement.title.tipsBatchStartTask': 'เลือกงานที่จะเริ่ม',
  'isales.taskmanagement.title.batchStart.finishedStatus': 'ไม่สามารถเริ่มงานที่เสร็จสมบูรณ์แล้วได้',
  'isales.taskmanagement.title.batchPause.statusError': 'งานในสถานะร่างหรือหมดอายุไม่สามารถระงับได้',
  'isales.taskmanagement.title.startCount': 'สามารถเริ่มงานการโทรออกได้สูงสุดครั้งละ 30 รายการ',
  'isales.taskmanagement.title.startConfirm': 'คุณแน่ใจหรือไม่ว่าต้องการเริ่มงานการโทรออกที่เลือก',
  'isales.taskmanagement.title.tipsBatchPauseTask': 'เลือกข้อมูลที่จะระงับ',
  'isales.taskmanagement.title.pauseCount': 'สามารถระงับงานการโทรออกได้สูงสุดครั้งละ 30 รายการ',
  'isales.taskmanagement.title.pauseConfirm': 'คุณแน่ใจหรือไม่ว่าต้องการระงับงานการโทรออกที่เลือก',
  'isales.taskmanagement.title.batchEditTaskInfo.errStatus': 'เลือกงานในสถานะแบบร่าง ระงับ หรือดำเนินการ',
  'isales.taskmanagement.title.batchEditCallPolicy.sameTaskType': 'นโยบายการโทรออกจะสามารถแก้ไขเป็นชุดได้สำหรับงานที่เป็นประเภทเดียวกันเท่านั้น',
  'isales.taskmanagement.title.batchEditCallTime.manualError': 'เวลาการโทรออกด้วยตนเองไม่สามารถเปลี่ยนได้',
  'isales.taskmanagement.title.batchEditCallPolicy.manualError': 'นโยบายการโทรออกด้วยตนเองไม่สามารถแก้ไขได้',
  'isales.task.message.NoStartTask': 'ไม่มีงานที่ตรงตามเงื่อนไข',
  'isales.task.message.NoPauseTask': 'ไม่มีงานที่ตรงตามเงื่อนไข',
  'isales.taskinfo.field.priority': 'ระดับความสำคัญ',
  'isales.taskmanagement.title.editInfo': 'การเปลี่ยนข้อมูล',
  'isales.taskmanagement.title.editSample': 'การจัดการวัตถุ',
  'isales.taskmanagement.title.editTime': 'การเปลี่ยนเวลา',
  'isales.taskmanagement.title.editLogic': 'การเปลี่ยนตรรกะ',
  'isales.taskpolicy.field.calculationFactor': 'ปัจจัยการคำนวณ',
  'isales.taskpolicy.field.pickupRate': 'อัตราการยกหู',
  'isales.taskresult.title.inputSessionId': 'ป้อนหมายเลขเซสชัน',
  'isales.taskstatus.field.waiting': 'กำลังรอ',
  'isales.taskstatus.field.callfinish': 'เสร็จสมบูรณ์',
  'isales.taskInfo.message.saveSuccess': 'แก้ไขข้อมูลสำเร็จแล้ว',
  'isales.taskInfo.message.saveFail': 'ไม่สามารถแก้ไขข้อมูลได้',
  'isales.tasklogic.field.callpolicy': 'นโยบายการโทร',
  'isales.tasklogic.field.callmethod': 'โหมดการโทร',
  'isales.tasklogic.field.callbyorder': 'ตามลำดับ',
  'isales.tasklogic.field.callbyturn': 'ตามรอบ',
  'isales.tasklogic.field.callturntype': 'โหมดรอบ',
  'isales.tasklogic.field.callturntype.numbertype': 'ตามประเภทหมายเลข',
  'isales.tasklogic.field.callturntype.numberorder': 'ตามหมายเลขลำดับ',
  'isales.tasklogic.field.addturn': 'เพิ่มรอบ',
  'isales.tasklogic.field.turn': 'รอบ',
  'isales.tasklogic.field.numberorder': 'หมายเลขลำดับ',
  'isales.tasklogic.field.numbertype': 'ประเภทหมายเลข',
  'isales.tasklogic.field.add.numbertype': 'ประเภทหมายเลขใหม่',
  'isales.tasklogic.field.exist.numbertype': 'ประเภทหมายเลขที่มีอยู่',
  'isales.tasklogic.field.default': 'ค่าเริ่มต้น',
  'isales.tasklogic.message.no.numbertype': 'หมายเลขซีเรียลว่างเปล่า',
  'isales.tasklogic.message.exist.orderNo': 'ลำดับหมายเลขมีอยู่แล้ว กรอกค่าอื่น',
  'isales.tasklogic.message.need.turns': 'เพิ่มข้อมูลรอบอย่างน้อยหนึ่งรายการ',
  'isales.taskpolicy.field.previewAutoCallTime': 'ระยะเวลาการโทรออกที่แสดงตัวอย่าง ',
  'isales.taskinfo.message.autoCallTimeValueError': 'ระยะเวลาการโทรออกที่แสดงตัวอย่างจะต้องสั้นกว่าระยะเวลาการหมดเวลาแสดงตัวอย่าง',
  'isales.taskinfo.message.batchUpdate.paramsNull': 'แก้ไขพารามิเตอร์ที่จะแก้ไข',
  'isales.agent.contact.Voice': 'เสียง',
  'isales.agent.contact.multimedia': 'มัลติมีเดีย',
  'isales.agent.contact.vidio': 'วิดีโอ',
  'isales.agent.contact.audioclicktodial': 'การคลิกเพื่อโทรด้วยเสียง',
  'isales.agent.contact.videoclicktodial': 'การคลิกเพื่อโทรด้วยวิดีโอ',
  'isales.agent.message.searchSkillName': 'กรอกชื่อคิวทักษะ',
  'isales.agent.button.agent.association': 'กำหนดค่าเจ้าหน้าที่',
  'isales.agent.title.skillCreate': 'สร้างคิวทักษะ',
  'isales.agent.message.updateCtiAndDbSuccess': 'อัปเดตคิวทักษะสำเร็จแล้ว',
  'isales.agent.message.deleteSkillAndAgentFail': 'ไม่สามารถลบการเชื่อมโยงคิวทักษะได้',
  'isales.agent.message.addSkillFail': 'ไม่สามารถเพิ่มคิวทักษะได้',
  'isales.agent.message.modifySkillFail': 'ไม่สามารถอัปเดตคิวทักษะได้',
  'isales.agent.message.queryCallCenterFail': 'ไม่สามารถสืบค้นข้อมูลศูนย์บริการได้',
  'isales.agent.message.refreshSkillException': 'เกิดข้อผิดพลาดขึ้นในขณะที่กำลังอัปเดตคิวทักษะ',
  'isales.agent.message.deleteSkillConfFail': 'ไม่สามารถลบข้อมูลคิวทักษะได้',
  'isales.agent.message.agentMsgException': 'เปลี่ยนรหัสผ่านเริ่มต้นของเจ้าหน้าที่และซิงค์รหัสผ่านกับเจ้าหน้าที่',
  'isales.agent.label.skillName': 'ชื่อคิวทักษะ',
  'isales.agent.label.maxWaitTime': 'เวลาคิวสูงสุด',
  'isales.agent.label.agentList': 'ID เจ้าหน้าที่',
  'ccivr.ivr.ivrflow.type': 'ประเภท',
  'isales.agent.label.adjustTime': 'ระยะเวลาการสรุป (วินาที)',
  'isales.agent.title.skillModify': 'แก้ไขคิวทักษะ',
  'isales.agent.message.deleteSkill': 'ลบคิวทักษะ',
  'isales.agent.message.deleteSkillConfSuccess': 'ลบคิวทักษะสำเร็จแล้ว',
  'isales.agent.message.idIsEmpty': 'ห้ามเว้นว่าง ID คิวทักษะ',
  'isales.agent.label.theAgent': 'เจ้าหน้าที่',
  'isales.agent.label.skillQueue': 'คิวทักษะ:',
  'isales.agent.label.bindTheSkill': 'การกำหนดค่าผู้รับถูกผูกแล้ว ยกเลิกการผูกก่อน',
  'isales.agent.label.deleteCtiFailed': 'ไม่สามารถลบข้อมูลได้',
  'isales.agent.message.skillConfFail': 'ไม่สามารถกำหนดค่าคิวทักษะได้',
  'isales.agent.message.selectSkills': 'เลือกคิวทักษะ',
  'isales.agent.label.skills': 'คิวทักษะ',
  'isales.agent.label.release': 'ยกเลิก',
  'isales.agent.label.transfer': 'ถ่ายโอน',
  'isales.agent.label.playVoice': 'เล่นเสียงรอสาย',
  'isales.agent.label.maxWaitNum': 'การโทรสูงสุดในคิว',
  'isales.agent.label.skillpara': 'การตั้งค่าพารามิเตอร์',
  'isales.agent.label.timeOut': 'การหมดเวลาคิว',
  'isales.agent.label.transferType': 'โหมดการจัดการ',
  'isales.agent.Transfer.devicetype': 'ประเภทอุปกรณ์เป้าหมาย',
  'isales.agent.label.ivrChoice': 'IVR',
  'isales.agent.label.deviceType': 'ประเภทอุปกรณ์',
  'isales.agent.label.skillChoice': 'คิวทักษะ',
  'isales.agent.label.noAgents': 'การขาดงานของเจ้าหน้าที่ที่ปฏิบัติงาน',
  'isales.agent.label.waitVoice': 'เสียงรอสาย',
  'isales.agent.label.parameterType': 'โหมดคิว',
  'isales.agent.label.answerMode': 'โหมดการรับสายของเจ้าหน้าที่',
  'isales.agent.label.busy': 'คิวไม่ว่างหรือล้น',
  'isales.agent.label.answerType': 'โหมดการรับสายของเจ้าหน้าที่',
  'isales.agent.label.answerType0': 'รายงาน ID เจ้าหน้าที่',
  'isales.agent.label.answerType2': 'เสียงไม่ได้รายงาน ID เจ้าหน้าที่',
  'isales.agent.message.name.limit': 'ชื่อคิวสกิลเกินความยาวสูงสุด',
  'isales.agent.calledroute.require': 'ตั้งค่าฟิลด์ที่จำเป็นทั้งหมด',
  'isales.agent.message.createSkillConfSuccess': 'สร้างคิวทักษะสำเร็จแล้ว',
  'isales.agent.calledroute.gateway.fail': 'ไม่สามารถซิงค์ข้อมูลกับแพลตฟอร์ม CTI ได้ ตรวจสอบการกำหนดค่าเกตเวย์',
  'isales.skillcreate.maxnumber.failed': 'จำนวนของคิวทักษะของผู้เช่าไม่สามารถเกินขีดจำกัดสูงสุดได้',
  'isales.agent.message.createSkillConfFail': 'ไม่สามารถสร้างคิวทักษะได้',
  'isales.agent.accesscode.description.limit': 'คำอธิบายของรหัสการเข้าถึงสามารถมีอักขระได้สูงสุด 50 ตัว',
  'isales.agent.message.modifySkillConfSuccess': 'แก้ไขคิวทักษะสำเร็จแล้ว',
  'isales.agent.message.modifySkillConfFail': 'ไม่สามารถแก้ไขคิวทักษะได้',
  'isales.agent.label.maxWaitTimeSpan': 'ค่าต้องอยู่ในช่วงตั้งแต่ 0 ถึง 60000',
  'isales.agent.label.maxAgentWrapupTime': 'ค่าต้องอยู่ในช่วงตั้งแต่ 0 ถึง 3600',
  'isales.agent.contact.mediaType': 'ประเภทสื่อ',
  'isales.calledroute.title.selectivrflow': 'เลือกหุ่นยนต์อัจฉริยะ',
  'isales.management.message.SelectNumber': 'เลือกหมายเลข',
  'isales.taskmanagement.title.tip.batchEditTask': 'เลือกงานที่จะแก้ไข',
  'isales.taskmanagement.title.editCountOutOfBounds': 'สามารถแก้ไขงานการโทรออกได้สูงสุดครั้งละ 30 รายการ',
  'isales.taskinfo.message.batchEditCallLogic.differentPolicyTypeExist': 'ตรรกะการโทรออกจะไม่สามารถแก้ไขเป็นชุดได้สำหรับงานที่มีประเภทนโยบายแตกต่างกัน',
  'isales.management.message.batchEditCallLogic.notSupportBusiresult': 'นโยบายผลลัพธ์ทางธุรกิจจะไม่สามารถกำหนดค่าได้สำหรับการโทรออกอัตโนมัติหรืออัจฉริยะ คุณแน่ใจหรือไม่ว่าต้องการทำการแก้ไขเป็นชุด',
  'isales.management.message.batchEditCallLogic.notSupportBusiresultInCMS': 'นโยบายผลลัพธ์ทางธุรกิจจะไม่สามารถกำหนดค่าได้สำหรับการโทรออกอัตโนมัติ คุณแน่ใจหรือไม่ว่าต้องการทำการแก้ไขเป็นชุด',
  'isales.management.message.batchEditCallLogic.statusError': 'ตรรกะการโทรออกจะสามารถแก้ไขเป็นชุดได้สำหรับงานในสถานะแบบร่าง ดำเนินการ หรือระงับเท่านั้น',
  'isales.tasklogic.message.confirmBatchUpdate': 'การแก้ไขเป็นชุดจะใช้กับภูมิภาคที่ถูกเลือกเท่านั้น คุณแน่ใจหรือไม่ว่าต้องการดำเนินการต่อ',
  'isales.taskinfo.message.batchEditCallLogic.manualExist': 'ตรรกะการโทรออกด้วยตนเองไม่สามารถแก้ไขได้',
  'isales.taskinfo.message.batchEditCallLogic.selectEmpty': 'การดำเนินการไม่ถูกต้องเนื่องจากไม่ได้เลือกภูมิภาค เลือกภูมิภาค',
  'isales.taskinfo.message.batchEditCallLogic.differentCallModeExist': 'ตรรกะการโทรออกจะไม่สามารถแก้ไขเป็นชุดได้สำหรับงานที่มีโหมดการโทรแตกต่างกัน',
  'isales.taskinfo.message.batchEditCallLogic.differentRoundTypeExist': 'ตรรกะการโทรออกจะไม่สามารถแก้ไขเป็นชุดได้สำหรับงานที่มีโหมดรอบการโทรแตกต่างกัน',
  'isales.taskinfo.message.batchEditCallLogic.CallPolicyNotification': 'รอบที่มีอยู่จะยังคงไม่เปลี่ยน และรอบใหม่จะถูกเพิ่มต่อจากรอบที่มีอยู่',
  'isales.taskinfo.message.batchEditCallLogic.CallResultNotification': 'นโยบายที่มีอยู่สามารถแก้ไขได้ และนโยบายใหม่จะถูกเพิ่มต่อจากนโยบายที่มีอยู่',
  'isales.taskinfo.message.batchEditCallLogic.BusiResultNotification': 'นโยบายใหม่จะเขียนทับนโยบายที่มีอยู่',
  'isales.taskcalllogic.message.typeConflictWhenDataTypeSame': 'นโยบายที่มีประเภทหมายเลขเดียวกันกับนโยบายที่มีอยู่ แต่นโยบายแตกต่างกันนั้นจะถูกกำหนดค่าตามผลลัพธ์',
  'isales.taskcalllogic.message.callNoPolicyOutOfRangeAfetUpdate': 'หลังจากที่จำนวนของนโยบายหมายเลขที่ไม่ใช่ค่าเริ่มต้นในนโยบายผลลัพธ์การโทรออกถูกเปลี่ยน จะเกินห้าซึ่งเป็นจำนวนสูงสุด',
  'isales.taskcalllogic.message.roundPolicyLengthTooLong': 'หลังจากที่จำนวนรอบในนโยบายการโทรถูกเปลี่ยน จะเกินความยาวสูงสุด',
  'isales.taskcalllogic.message.dataTypeDefaultNoCreate': 'ประเภทหมายเลขเริ่มต้น',
  'isales.taskcalllogic.message.callNoPolicyEmpty': 'โปรดเพิ่มนโยบายหมายเลขอย่างน้อยหนึ่งรายการ',
  'isales.result.search.displayResult': 'กรอกสาเหตุของการล้มเหลวของการโทรออก',
  'isales.result.resultCode': 'รหัสสาเหตุ',
  'isales.result.displayResult': 'สาเหตุของการล้มเหลวของการโทรออก',
  'isales.result.view': 'ดูสาเหตุของการล้มเหลวของการโทรออก',
  'isales.result.edit': 'แก้ไขสาเหตุของการล้มเหลวของการโทรออก',
  'isales.result.delete.error': 'เลือกสาเหตุของการล้มเหลวของการโทรออก',
  'isales.result.deletetips': 'คุณแน่ใจหรือไม่ว่าต้องการลบสาเหตุของการล้มเหลวของการโทรออก',
  'isales.result.create': 'สร้างสาเหตุของการล้มเหลวของการโทรออก',
  'isales.result.outgoingCallResult': 'ผลลัพธ์การโทรออก',
  'isales.result.addOutgoingCallResult': 'เพิ่มผลลัพธ์',
  'isales.result.outgoingCall.failCode': 'รหัสผลลัพธ์',
  'isales.result.outgoingCall.Description': 'คำอธิบาย',
  'isales.result.addResultMaptips': 'สามารถเพิ่มผลลัพธ์การโทรออกได้สูงสุด 50 รายการ',
  'isales.result.addAgentTips': 'สามารถเพิ่มตัวแทนสูงสุด 20000 คน',
  'isales.result.outgoingCall.input.description': 'กรอกรหัสหรือคำอธิบายผลลัพธ์การโทรออก',
  'isales.result.obResultIsNull': 'ห้ามเว้นว่างผลลัพธ์การโทรออกของสาเหตุของการล้มเหลว',
  'isales.calledroute.title.selectskill': 'เลือกคิวทักษะ',
  'isales.calledroute.title.selectagent': 'เลือกเจ้าหน้าที่',
  'isales.agent.placeholder.agentdesc': 'กรอกชื่อเจ้าหน้าที่',
  'isales.agent.label.workNo': 'ID เจ้าหน้าที่',
  'isales.agent.label.accountName': 'ชื่อเจ้าหน้าที่',
  'isales.skillqueue.button.add': 'เพิ่มคิวทักษะ',
  'isales.specialday.message.enterworkno': 'กรอก ID เจ้าหน้าที่',
  'isales.taskresult.field.sipAccount': 'หมายเลขซอฟต์โฟน',
  'isales.taskresult.field.agentRole': 'ชื่อบทบาทเจ้าหน้าที่',
  'isales.taskInfo.message.skillAlreadyExists': 'คิวทักษะมีอยู่แล้ว',
  'isales.agent.message.saveSuccess': 'บันทึกการกำหนดค่าเจ้าหน้าที่สำเร็จแล้ว',
  'isales.agent.field.skillWeight': 'น้ำหนักทักษะ',
  'isales.agent.label.agentWeight': 'น้ำหนักเจ้าหน้าที่',
  'isales.agent.message.chooseAnAgent': 'เลือกเจ้าหน้าที่',
  'isales.agent.message.batchSaveSuccess': 'แก้ไขเจ้าหน้าที่เป็นชุดสำเร็จแล้ว',
  'isales.agent.message.batchSaveFail': 'ไม่สามารถแก้ไขเจ้าหน้าที่เป็นชุดได้',
  'isales.agent.message.configAgent': 'กำหนดค่าหรือซิงค์เจ้าหน้าที่',
  'isales.tasktemplate.title.saveAsTemplate': 'บันทึกเป็นแม่แบบ',
  'isales.taskmanagement.title.createDirectly': 'สร้างโดยตรง',
  'isales.taskmanagement.title.newFromTemplate': 'สร้างโดยใช้แม่แบบ',
  'isales.taskmanagement.title.selectTemplate': 'เลือกแม่แบบ',
  'isales.tasktemplate.title.inputTemplateName': 'กรอกชื่อแม่แบบ',
  'isales.tasktemplate.title.addTemplate': 'สร้างแม่แบบการโทรออก',
  'isales.tasktemplate.title.editTemplate': 'แก้ไขแม่แบบการโทรออก',
  'isales.tasktemplate.title.viewTemplate': 'ดูแม่แบบการโทรออก',
  'isales.tasktemplate.title.autoTemplate': 'แม่แบบการโทรออกอัตโนมัติ',
  'isales.tasktemplate.title.manualTemplate': 'แม่แบบการโทรออกด้วยตนเอง',
  'isales.tasktemplate.title.addAutoTemplate': 'สร้างแม่แบบการโทรออกอัตโนมัติ',
  'isales.tasktemplate.title.addManualTemplate': 'สร้างแม่แบบการโทรออกด้วยตนเอง',
  'isales.tasktemplate.field.templateName': 'ชื่อแม่แบบ',
  'isales.tasktemplate.field.release': 'เผยแพร่',
  'isales.tasktemplate.field.released': 'เผยแพร่แล้ว',
  'isales.tasktemplate.message.deleteConfirm': 'คุณแน่ใจหรือไม่ว่าต้องการลบแม่แบบการโทรออกที่เลือก',
  'isales.tasktemplate.message.releasesuccess': 'การเผยแพร่สำเร็จ',
  'isales.tasktemplate.message.releasefailed': 'การเผยแพร่ล้มเหลว',
  'isales.tasktemplate.message.pauseFail': 'การระงับล้มเหลว',
  'isales.tasktemplate.message.pauseSuccess': 'การระงับสำเร็จ',
  'isales.tasktemplate.message.saveFail': 'ไม่สามารถกำหนดค่าแม่แบบการโทรออกได้',
  'isales.tasktemplate.message.saveSuccess': 'กำหนดค่าแม่แบบการโทรออกสำเร็จแล้ว',
  'isales.tasktemplate.message.nameDuplicated': 'แม่แบบการโทรออกที่มีชื่อเดียวกันมีอยู่แล้ว',
  'isales.data.prop.propName': 'ชื่อแอตทริบิวต์',
  'isales.data.prop.propCode': 'รหัสแอตทริบิวต์',
  'isales.data.prop.propType': 'ประเภทแอตทริบิวต์',
  'isales.data.prop.isPushAgent': 'ส่งให้เจ้าหน้าที่',
  'isales.data.prop.mandatory': 'บังคับ',
  'isales.data.prop.anonym': 'ลบชื่อแล้ว',
  'isales.data.prop.status': 'สถานะ',
  'isales.data.prop.numberType': 'ตัวเลข',
  'isales.data.prop.anonymType': 'การเข้ารหัส',
  'isales.data.prop.status.unpublished': 'ไม่ได้เผยแพร่',
  'isales.data.prop.editDataProp': 'แก้ไขแอตทริบิวต์',
  'isales.data.prop.createDataProp': 'สร้างแอตทริบิวต์',
  'isales.data.prop.inputPropName': 'กรอกชื่อแอตทริบิวต์',
  'isales.data.prop.inputPropCode': 'กรอกรหัสแอตทริบิวต์',
  'isales.data.prop.editDataPropSuccess': 'แก้ไขแอตทริบิวต์สำเร็จแล้ว',
  'isales.data.prop.createDataPropSuccess': 'สร้างแอตทริบิวต์สำเร็จแล้ว',
  'isales.data.prop.editDataPropFail': 'ไม่สามารถแก้ไขแอตทริบิวต์ได้',
  'isales.data.prop.createDataPropFail': 'ไม่สามารถสร้างแอตทริบิวต์',
  'isales.data.prop.propParamsError': 'ไม่สามารถตรวจสอบพารามิเตอร์ของแอตทริบิวต์ได้',
  'isales.data.prop.propCodeExists': 'รหัสแอตทริบิวต์มีอยู่แล้ว',
  'isales.data.prop.propNameExists': 'ชื่อแอตทริบิวต์มีอยู่แล้ว',
  'isales.data.prop.message.confirmDelete': 'คุณแน่ใจหรือว่าต้องการลบแอตทริบิวต์ที่เลือก',
  'isales.data.prop.numberType.countError': 'สามารถกำหนดแอตทริบิวต์ประเภทตัวเลขได้สูงสุด 10 รายการ',
  'isales.data.prop.isPushAgent.countError': 'สามารถกำหนดแอตทริบิวต์ได้สูงสุด 15 รายการเพื่อส่งให้เจ้าหน้าที่',
  'isales.data.prop.anonymType.countError': 'สามารถกำหนดแอตทริบิวต์ประเภทการเข้ารหัสได้สูงสุด 10 รายการ',
  'isales.data.prop.isPushAgent.anonymTypeError': 'แอตทริบิวต์ประเภทการเข้ารหัสไม่สามารถส่งให้เจ้าหน้าที่ได้',
  'isales.data.propConfig.button.configProp': 'กำหนดค่าแอตทริบิวต์',
  'isales.data.propConfig.title.isPushAgent': 'ส่งให้เจ้าหน้าที่',
  'isales.data.propConfig.message.isUseProp': 'เปิดใช้งานแอตทริบิวต์ที่กำหนดเอง',
  'isales.data.propConfig.title.prop': 'แอตทริบิวต์',
  'isales.file.title.ip': 'ที่อยู่ IP',
  'isales.file.title.port': 'พอร์ต',
  'isales.file.title.username': 'ชื่อผู้ใช้',
  'isales.file.title.filepath': 'เส้นทางเริ่มต้น',
  'isales.file.title.password': 'รหัสผ่าน',
  'isales.file.title.ip.placeholder': 'กรอกที่อยู่ IP ของเซิร์ฟเวอร์',
  'isales.file.title.port.placeholder': 'กรอกหมายเลขพอร์ตของเซิร์ฟเวอร์',
  'isales.file.title.username.placeholder': 'กรอกชื่อผู้ใช้สำหรับล็อกอินเข้าสู่เซิร์ฟเวอร์',
  'isales.file.title.filepath.placeholder': 'กรอกเส้นทางไฟล์ ตัวอย่างเช่น /home',
  'isales.file.title.password.placeholder': 'กรอกรหัสผ่านสำหรับล็อกอินเข้าสู่เซิร์ฟเวอร์',
  'isales.file.title.addserver': 'เพิ่มเซิร์ฟเวอร์',
  'isales.file.title.server.info': 'แก้ไขข้อมูลเซิร์ฟเวอร์สำเร็จแล้ว',
  'isales.file.title.server.fail': 'ไม่สามารถแก้ไขข้อมูลเซิร์ฟเวอร์ได้',
  'isales.file.title.delete.fail': 'ไม่สามารถลบข้อมูลเซิร์ฟเวอร์ได้',
  'isales.file.title.editserver': 'แก้ไขเซิร์ฟเวอร์',
  'isales.file.title.rightpath': 'ป้อนพาธที่ถูกต้อง ตัวอย่างเช่น /isales/upload',
  'isales.file.title.rule.basic': 'ข้อมูลพื้นฐาน',
  'isales.file.title.rule.name': 'ชื่อกฎ',
  'isales.file.title.rule.desc': 'คำอธิบาย',
  'isales.file.title.addrule': 'เพิ่มกฎ',
  'isales.file.title.editrule': 'แก้ไขกฎ',
  'isales.file.title.rule.name.placeHolder': 'กรอกชื่อกฎ',
  'isales.file.title.rule.desc.placeHolder': 'กรอกคำอธิบายกฎ',
  'isales.file.title.rule.data': 'ข้อมูลหมายเลข',
  'isales.file.title.rule.number': 'คอลัมน์หมายเลข',
  'isales.file.title.rule.type': 'คอลัมน์ประเภท',
  'isales.file.title.rule.interval': 'คอลัมน์ช่วงเวลาการโทร',
  'isales.file.title.rule.property': 'แอตทริบิวต์ธุรกิจ',
  'isales.file.title.rule.property.row': 'คอลัมน์แอตทริบิวต์',
  'isales.file.title.rule.validator.row': 'กรอกหมายเลขลำดับตั้งแต่คอลัมน์ A ถึง EZ',
  'isales.file.title.rule.validator.maxdata': 'สามารถเพิ่มบันทึกการกำหนดค่าหมายเลขได้สูงสุด 40 รายการ',
  'isales.file.title.rule.validator.ruleId': 'ไม่มีกฎการแมป',
  'isales.file.title.rule.validator.taskId': 'ไม่มีข้อมูลงาน',
  'isales.file.title.import.file.invalid': 'ไม่สามารถตรวจสอบไฟล์ได้',
  'isales.file.title.rule.validator.prop': 'แอตทริบิวต์ที่กำหนดเองของงานไม่สอดคล้องกับแอตทริบิวต์ที่กำหนดค่าในกฎ',
  'isales.file.title.rule.validator.server': 'การกำหนดค่าเซิร์ฟเวอร์ไม่ถูกต้อง',
  'isales.management.message.fileImport': 'ใช้ไฟล์',
  'isales.management.message.serverImport': 'ใช้เซิร์ฟเวอร์',
  'isales.management.message.appendMode': 'เพิ่ม',
  'isales.management.message.coverMode': 'เขียนทับ',
  'isales.management.message.templateAnalyse': 'ตามแม่แบบ',
  'isales.management.message.ruleAnalyse': 'ตามกฎ',
  'isales.management.message.importType': 'วิธีการนำเข้า',
  'isales.management.message.importMode': 'โหมดการนำเข้า',
  'isales.management.message.import.server': 'เซิร์ฟเวอร์',
  'isales.management.message.import.filePath': 'ที่อยู่ไฟล์',
  'isales.management.message.import.fileName': 'ชื่อไฟล์',
  'isales.management.message.import.analyseMode': 'โหมดการแยกวิเคราะห์',
  'isales.management.message.import.mapRule': 'กฎการแมป',
  'isales.data.title.timeImport': 'การนำเข้าที่กำหนดเวลา',
  'isales.management.title.timeImport': 'กำหนดค่าการนำเข้าที่กำหนดเวลา',
  'isales.management.title.timeImport.execTime': 'ดำเนินการแล้ว',
  'isales.timeImport.message.success': 'เพิ่มการกำหนดค่าการนำเข้าที่กำหนดเวลาสำเร็จแล้ว',
  'isales.timeImport.message.fail': 'ไม่สามารถเพิ่มการกำหนดค่าการนำเข้าที่กำหนดเวลาได้',
  'isales.agent.field.curcountpassword': 'รหัสผ่านปัจจุบัน',
  'isales.agent.button.batchmodify': 'การเปลี่ยนเป็นชุด',
  'isales.agent.button.restAgentPwd': 'เปลี่ยนรหัสผ่านเจ้าหน้าที่',
  'isales.agent.title.agentEdit': 'แก้ไขข้อมูลเจ้าหน้าที่',
  'isales.agent.field.newpassword': 'รหัสผ่านใหม่',
  'isales.agent.field.newpasswordconf': 'ยืนยันรหัสผ่าน',
  'isales.agent.message.passValidateFailed': 'ค่าต้องมีอักขระอย่างน้อยสามประเภทดังนี้: ตัวอักษรพิมพ์ใหญ่ ตัวอักษรพิมพ์เล็ก ตัวเลข และอักขระพิเศษ (`-!{\'@\'}#{\'$\'}^&*()-_+{\'|\'}[{\'{\'}{\'}\'}]<.>/?)',
  'isales.agent.message.passLengthValidateFailed': 'รหัสผ่านต้องประกอบด้วยอักขระ 8 ตัว',
  'isales.agent.message.modifyPwdSuccess': 'เปลี่ยนรหัสผ่านเจ้าหน้าสำเร็จแล้ว',
  'isales.agent.message.syncPwdFailed': 'ไม่สามารถซิงค์รหัสผ่านเจ้าหน้าที่ได้',
  'isales.agent.message.modifyPwdFailed': 'ไม่สามารถเปลี่ยนรหัสผ่านเจ้าหน้าที่ได้',
  'isales.file.message.rule.dataIsNull': 'ห้ามเว้นว่างข้อมูลหมายเลข',
  'isales.timeImport.message.rulePropsError': 'แอตทริบิวต์ธุรกิจของกฎการนำเข้าที่กำหนดเวลาต้องสอดคล้องกับแอตทริบิวต์ที่กำหนดในงานปัจจุบัน',
  'isales.import.message.selectServer.tips': 'กำหนดเซิร์ฟเวอร์ไฟล์การโทรออก',
  'isales.import.message.selectRule.tips': 'กำหนดกฎการแมปที่จะนำเข้า',
  'isales.taskmanagement.title.speclistmanage.calledno': 'หมายเลขโทรออก',
  'isales.specialday.title.speclistmanage.moveblacklists': 'ย้ายไปยังรายการที่ปิดกั้น',
  'isales.specialday.title.speclistmanage.misjudgment': 'ตัดสินผิด',
  'isales.specreason.field.emptynumber': 'ไม่ได้จัดสรรหมายเลข',
  'isales.specreason.field.systemtone': 'เสียงแจ้งของระบบ',
  'isales.specist.message.speclistmanage': 'เลือกหมายเลขรายการพิเศษที่จะดำเนินการ',
  'isales.blacklist.message.confirmOpt': 'คุณแน่ใจหรือไม่ว่าต้องการดำเนินการในหมายเลขรายการพิเศษที่เลือก',
  'isales.specist.title.misjudgment': 'ตัดสินผิด',
  'isales.specist.message.updateFail': 'การแก้ไขล้มเหลว',
  'isales.taskcalllogic.message.maxPolicyNo': 'อนุญาตให้มีประเภทหมายเลขที่ไม่ใช่ค่าเริ่มต้นได้สูงสุดห้ารายการ',
  'isales.import.message.upload.fail': 'ไม่สามารถอัปโหลดไฟล์ได้',
  'isales.taskLogic.message.saveSuccess': 'แก้ไขตรรกะการโทรออกสำเร็จแล้ว',
  'isales.taskLogic.message.saveFail': 'ไม่สามารถแก้ไขตรรกะการโทรออกได้',
  'isales.taskTime.message.saveSuccess': 'เปลี่ยนเวลาการโทรออกสำเร็จแล้ว',
  'isales.taskTime.message.saveFail': 'ไม่สามารถเปลี่ยนเวลาการโทรออกได้',
  'isales.tasklogic.field.callfilter': 'การกรองการโทร',
  'isales.tasklogic.field.addCondition': 'เพิ่มเงื่อนไข',
  'isales.tasklogic.field.addSubCondition': 'เพิ่มเงื่อนไขรอง',
  'isales.tasklogic.field.logic': 'ผู้ดำเนินงาน',
  'isales.tasklogic.field.value': 'ค่า',
  'isales.tasklogic.field.selectProp': 'เพิ่มแอตทริบิวต์',
  'isales.management.title.selectProp': 'เลือกแอตทริบิวต์',
  'isales.data.prop.quantity': 'จำนวน',
  'isales.tasklogic.field.and': 'และ',
  'isales.tasklogic.field.or': 'หรือ',
  'isales.tasklogic.field.eq': 'เท่ากับ',
  'isales.tasklogic.field.neq': 'ไม่เท่ากับ',
  'isales.tasklogic.field.gt': 'มากกว่า',
  'isales.tasklogic.field.egt': 'มากกว่าหรือเท่ากับ',
  'isales.tasklogic.field.lt': 'น้อยกว่า',
  'isales.tasklogic.field.elt': 'น้อยกว่าหรือเท่ากับ',
  'isales.tasklogic.field.like': 'รวม',
  'isales.tasklogic.field.orderPolicy': 'การเรียงการโทร',
  'isales.tasklogic.field.moveUp': 'ขึ้น',
  'isales.tasklogic.field.moveDown': 'ลง',
  'isales.tasklogic.message.repeatProp': 'ไม่สามารถเลือกแอตทริบิวต์ซ้ำได้',
  'isales.tasklogic.label.primaryCondition': 'เงื่อนไขหลัก',
  'isales.tasklogic.label.subCondition': 'เงื่อนไขรอง',
  'isales.agent.label.skillId': 'ID คิวทักษะ',
  'isales.management.title.editTime': 'การเปลี่ยนเวลา',
  'isales.management.title.editLogic': 'การเปลี่ยนตรรกะ',
  'isales.agent.label.description': 'คำอธิบาย',
  'isales.agent.label.ivr': 'IVR',
  'isales.skill.label.skillname': 'ชื่อคิวทักษะ',
  'isales.skill.label.skilldesc': 'คำอธิบาย',
  'isales.skill.label.maxwaittime': 'เวลาคิวสูงสุด',
  'isales.skill.label.maxwaitnum': 'การโทรสูงสุดในคิว',
  'isales.skill.placeholder.skilldesc': 'กรอกชื่อคิวทักษะ',
  'isales.file.title.create': 'เพิ่ม',
  'isales.file.title.rule.index': 'หมายเลขลำดับ',
  'isales.file.title.rule.property.name': 'ชื่อแอตทริบิวต์',
  'isales.taskstatistics.field.abandonRate': 'อัตราการทิ้ง',
  'isales.business.result.delete': 'ลบ',
  'isales.business.result.search': 'ค้นหา',
  'isales.business.result.deleteChildrenTips': 'คุณแน่ใจหรือไม่ว่าต้องการลบผลลัพธ์ทางธุรกิจรอง',
  'isales.business.result.searchChildrenName': 'กรอกชื่อผลลัพธ์ทางธุรกิจรอง',
  'isales.taskmanagment.title.callMonitor': 'การตรวจสอบการโทร',
  'isales.taskstatistics.field.callResultStatistics': 'สถิติผลลัพธ์การโทร',
  'isales.file.message.deletefiletips': 'คุณแน่ใจหรือไม่ว่าต้องการลบเซิร์ฟเวอร์ไฟล์โทรออก',
  'isales.file.message.deleteruletips': 'คุณแน่ใจหรือไม่ว่าต้องการลบกฎการแมปของการนำเข้า',
  'isales.taskmanagment.callquality.maxConnectedRate': 'อัตราการเชื่อมต่อสูงสุด',
  'isales.taskmanagment.callquality.minConnectedRate': 'อัตราการเชื่อมต่อต่ำสุด',
  'isales.work.title.serviceAccount': 'บัญชีธุรกิจ',
  'isales.work.title.surveyAnswerNum': 'จํานวนการตอบแบบสอบถาม',
  'isales.work.title.reservedCalls': 'การโทรที่สำรอง',
  'isales.work.title.totalOutgoingCalls': 'การโทรออก',
  'isales.work.title.successNum': 'การโทรออกที่สำเร็จ',
  'isales.work.title.completeNum': 'แบบสำรวจที่เสร็จสมบูรณ์',
  'isales.work.title.calledNum': 'การโทรที่ประมวลผล',
  'isales.work.title.callTimeTotal': 'ระยะเวลาโทรทั้งหมด (วินาที)',
  'isales.work.title.callTimeAvg': 'ระยะเวลาการโทรเฉลี่ย (วินาที)',
  'isales.work.title.busiSuccess': 'ธุรกิจที่สำเร็จ',
  'isales.work.title.busiFail': 'ธุรกิจที่ล้มเหลว',
  'isales.work.title.outboundCallTask': 'ภารกิจของตัวแทน',
  'isales.work.title.multimediaManage': 'การจัดการการตลาดมัลติมีเดีย',
  'isales.work.message.dategt7days': 'ช่วงเวลาจะต้องไม่เกินเจ็ดวัน',
  'isales.work.message.agentOrTaskChange': 'เลือกงานและหมายเลขประจําตัวอย่างน้อยหนึ่งรายการ',
  'isales.import.message.closeExtProp': 'ต้องปิดใช้งานแอตทริบิวต์ที่กําหนดเองสําหรับการนําเข้าโดยเทมเพลต คลิกปุ่มกําหนดค่าแอตทริบิวต์ถัดจาก นําเข้า เพื่อปิดใช้งานและเปิดใช้งานแอตทริบิวต์ที่กําหนดเอง',
  'isales.file.message.endPoint.placeholder': 'กรอกที่อยู่',
  'isales.file.title.bucket': 'ถัง',
  'isales.file.message.bucket.placeholder': 'กรอกถัง',
  'isales.file.title.AK': 'AK',
  'isales.file.title.SK': 'SK',
  'isales.file.message.AK.placeholder': 'กรอก AK',
  'isales.file.message.SK.placeholder': 'กรอก SK',
  'isales.file.title.serverInformation': 'ข้อมูลเซิร์ฟเวอร์',
  'isales.file.title.userNameOrAK': 'ชื่อผู้ใช้/AK',
  'isales.file.message.isDeleteFile': 'ลบไฟล์ต้นทาง',
  'isales.data.message.preview.addNumberUpperLimit': 'สามารถเพิ่มหมายเลขได้สูงสุด {count} รายการสำหรับการโทรออกที่แสดงตัวอย่าง',
  'isales.management.title.adjustment': 'ปรับ',
  'isales.agent.query.error': 'เจ้าหน้าที่เป้าหมายต้องไม่เหมือนกับผู้ดำเนินการของบันทึกที่เลือก',
  'isales.management.title.adjustment.log': 'บันทึกของการปรับ',
  'isales.management.title.adjustment.changeTime': 'ปรับเมื่อ',
  'isales.management.title.adjustment.orgAccountName': 'ผู้ดำเนินการเก่า',
  'isales.management.title.adjustment.destAccountName': 'ผู้ดำเนินการใหม่',
  'isales.management.title.adjustment.modifier': 'ปรับโดย',
  'isales.management.title.adjustment.remark': 'หมายเหตุ',
  'isales.appointment.message.adjustmentSuccess': 'ปรับการโทรออกที่สำรองสำเร็จแล้ว',
  'isales.appointment.message.adjustmentFail': 'ไม่สามารถปรับการโทรออกที่สำรองได้',
  'isales.appointment.message.adjustmentEmpty': 'เลือกข้อมูลการโทรออกที่สำรองที่ถูกต้อง',
  'isales.tasksample.message.agentEmpty': 'ไม่มีการเลือกวัตถุการฝึก',
  'isales.work.title.selectTask': 'เลือกงานการโทรออก',
  'isales.import.button.configProp': 'กำหนดค่ากฎของตัวกรอง',
  'isales.import.filter.button.lineRepeat': 'กรองข้อมูลซ้ําบรรทัด',
  'isales.import.filter.button.noRepeat': 'กรองข้อมูลซ้ําของชุดข้อมูล',
  'isales.import.filter.button.recentCall': 'กรองข้อมูลการโทรที่เพิ่งเสร็จสมบูรณ์ออก',
  'isales.import.filter.button.callResult': 'กรองข้อมูลผลลัพธ์ทางธุรกิจ',
  'isales.import.filter.button.readyCall': 'กรองข้อมูลการโทรที่ไม่ได้เริ่มออก',
  'isales.import.filter.button.calling': 'กรองข้อมูลการโทรที่ไม่เสร็จสมบูรณ์ออก',
  'isales.import.filter.title.popup': 'กฎของตัวกรอง',
  'isales.import.filter.message.selectRuleTips': 'เลือกกฎของตัวกรองอย่างน้อยหนึ่งรายการ',
  'isales.taskmanagment.title.callMonitor.tips': 'อัปเดตอัตโนมัติทุก 15 นาที',
  'isales.taskmanagement.field.basicTaskInfo': 'ข้อมูลพื้นฐาน',
  'isales.taskmanagement.field.pickUpRule': 'กฎการคัดแยกอัตโนมัติ',
  'isales.taskmanagement.field.isAutoPickUp': 'เปิดใช้งานกฎการคัดแยกอัตโนมัติ',
  'isales.taskmanagement.field.pickUpRuleLimit': 'ข้อจำกัด',
  'isales.taskmanagement.field.pickUpRuleBusiResult': 'ผลลัพธ์ทางธุรกิจ',
  'isales.taskmanagement.field.quotas': 'ขีดจำกัดสูงสุด',
  'isales.taskmanagement.field.callCompletedNum': 'การโทรที่เสร็จสมบูรณ์',
  'isales.taskmanagement.field.pickUpNum': 'การโทรที่คัดแยกแต่ละครั้ง',
  'isales.taskmanagement.field.pickUpCondition': 'เงื่อนไข',
  'isales.manualdata.title.pickUp': 'คัดแยก',
  'isales.manualData.message.pickUpSuccess': 'การคัดแยกสำเร็จ',
  'isales.manualData.message.reachQuatos': 'ไม่สามารถคัดแยกข้อมูลการโทรออกได้อีกเนื่องจากจำนวนของการโทรในงานถึงขีดจำกัดสูงสุดของการคัดแยกแล้ว',
  'isales.manualData.message.reachMax': 'ไม่สามารถคัดแยกข้อมูลการโทรออกได้อีกเนื่องจากถึงจำนวนสูงสุดของการโทรในงานแล้ว',
  'isales.manualData.message.pickUpFailed': 'ไม่มีข้อมูลที่จะดึง',
  'isales.taskstatistics.message.no.busiResult': 'ไม่ได้ทำเครื่องหมายมีผลลัพธ์ทางธุรกิจ',
  'isales.appointmanagement.field.remark': 'หมายเหตุ',
  'isales.appointmanagement.title.inputRemark': 'กรอกหมายเหตุ',
  'isales.agent.query.emptyError': 'ไม่ได้เลือกข้อมูลเจ้าหน้าที่ที่ถูกต้อง',
  'isales.statistics.field.touchRateDescription': 'อัตราการติดต่อ',
  'isales.statistics.field.callCompletionRateDescription': 'อัตราการเชื่อมต่อ',
  'isales.statistics.field.abandonmentRateDescription': 'อัตราการทิ้ง',
  'isales.taskstatistics.message.Unit.Piece': 'หน่วย: จำนวน',
  'isales.skillqueue.agentrole.agent': 'เจ้าหน้าที่',
  'isales.statistics.field.addHisDetail': 'ไม่: ข้อมูลของ {0} เดือนถูกรวบรวมไว้ ใช่: ข้อมูลของ {1} เดือนถูกรวบรวมไว้',
  'isales.import.filter.message.busiResultIsNull': 'ห้ามเว้นว่างผลลัพธ์ทางธุรกิจ',
  'isales.import.filter.message.busiSubStatusSame': 'ผลลัพธ์ทางธุรกิจรองภายใต้ผลลัพธ์ทางธุรกิจเดียวกันต้องไม่เหมือนกัน',
  'isales.file.server.message.checkObsPath': 'อนุญาตเฉพาะตัวเลข ตัวอักษร ขีด (-) และจุด (.) เท่านั้น',
  'isales.file.server.message.notChinese': 'ไม่อนุญาตให้ใช้อักขระภาษาจีน',
  'isales.import.filter.title.callDay': 'กิจกรรมล่าสุด (วัน)',
  'isales.manual.title.sendEmail': 'ส่งอีเมล',
  'isales.manual.button.send': 'ส่ง',
  'isales.manual.title.recipient': 'ผู้รับ',
  'isales.manual.title.title': 'คำนำหน้า',
  'isales.file.message.isDeleteFileTips': 'ลบไฟล์ออกจาก OBS',
  'isales.file.message.rule.success': 'แก้ไขกฎสำเร็จแล้ว',
  'isales.file.message.rule.fail': 'ไม่สามารถแก้ไขกฎได้',
  'isales.file.message.rule.columnRepeat': 'คอลัมน์ ID การโทรออก คอลัมน์หมายเลข คอลัมน์หมวดหมู่ คอลัมน์ช่วงเวลาการโทร และคอลัมน์แอตทริบิวต์ต้องไม่เหมือนกัน',
  'isales.manual.message.recipient': 'กรอกผู้รับ',
  'isales.manual.message.title': 'กรอกคำนำหน้า',
  'isales.operlog.message.downloadAgentWorkExportFile': 'ดาวน์โหลดไฟล์การส่งออกปริมาณงานของเจ้าหน้าที่',
  'isales.operlog.message.downloadSurveyExportFile': 'ส่งออกคําถามการฝึกอบรม',
  'isales.file.title.password.again': 'ยืนยันรหัสผ่าน',
  'isales.taskmanagment.title.open.callMonitor': 'การตรวจสอบการโทร',
  'isales.taskresult.field.workNo': 'WorkNo',
  'isales.taskresult.field.operation': 'การปฏิบัติการ',
  'isales.agent.field.skill': 'SkillName',
  'isales.agent.message.pwdRepeat': 'รหัสผ่านที่ส่งมาก็เหมือนกับรหัสผ่านที่เพิ่งเปลี่ยนไป',
  'isales.taskmanagement.title.speclistmanage.reason': 'เหตุผล',
  'isales.speclistmanage.title.calledNo': 'หมายเลข',
  'isales.speclistmanage.title.reason': 'เหตุผล',
  'isales.speclistmanage.title.operation': 'ทํางาน',
  'isales.specreason.field.all': 'กรุณาเลือก',
  'isales.specialday.title.confirm': 'ยืนยัน',
  'isales.tasklogic.field.addOrderPolicy': 'เพิ่ม Prop',
  'isales.tasklogic.field.prop': 'อุปกรณ์เสริม',
  'isales.skillqueue.agentrole.administrator': 'ผู้จัดการห้อง',
  'isales.skillqueue.agentrole.qc': 'ผู้ตรวจสอบคุณภาพ',
  'isales.data.prop.orderType': 'ประเภทการสั่งซื้อ',
  'isales.data.orderType.asc': 'กําลังขึ้น',
  'isales.data.orderType.desc': 'กําลังลง',
  'isales.agent.message.willDeleteSkills': 'การดําเนินการนี้อาจทําให้การโทรออกที่เกี่ยวข้องกับคิวทักษะล้มเหลวโปรดดําเนินการด้วยความระมัดระวังคุณจําเป็นต้องลบมันหรือไม่',
  'isales.agent.label.useTheSkill': 'ลบความสัมพันธ์ที่ผูกพันระหว่างตัวแทนและคิวทักษะในการกําหนดค่าตัวแทน',
  'isales.data.prop.stringType': 'สตริง',
  'isales.data.prop.stringType.countError': 'สามารถกําหนดคุณลักษณะประเภทสตริงอักขระสูงสุด 30 คน',
  'isales.agent.button.search': 'สอบถาม',
  'isales.agent.label.information': 'ข้อมูล',
  'isales.agent.label.operate': 'ปฏิบัติการ',
  'isales.agent.label.cti': 'ซีที',
  'isales.agent.message.warning': 'คําเตือน',
  'isales.agent.button.edit': 'แก้ไข',
  'isales.agent.button.createComplete': 'จบ',
  'isales.agent.button.cancel': 'ยกเลิก',
  'isales.agent.pageurls.errorsave': 'เกิดข้อผิดพลาด',
  'isales.agent.pageurls.success': 'ความสําเร็จ',
  'isales.agent.calledroute.fail': 'ล้มเหลว',
  'isales.calledroute.button.cancel': 'ยกเลิก',
  'isales.calledroute.button.finish': 'จบ',
  'isales.agent.label.ccRoleName': 'ชื่อบทบาท',
  'isales.taskstatistics.field.callstatistics': 'สถิติการโทร',
  'isales.taskstatistics.field.totalNumberOfUsers': 'จํานวนผู้ใช้ทั้งหมด',
  'isales.taskstatistics.field.numberOfConnectedCalls': 'จํานวนการโทรที่เชื่อมต่อ',
  'isales.taskstatistics.field.numberOfUnconnectedCalls': 'จํานวนการโทรที่ไม่เชื่อมต่อ',
  'isales.taskstatistics.field.touchRate': 'อัตราการสัมผัส',
  'isales.taskstatistics.field.callCompletionRate': 'อัตราการโทรเสร็จสิ้น',
  'isales.taskinfo.field.chooseOrInputCallNo': 'คุณสามารถป้อนหรือเลือกการโทรไม่พร้อมกัน',
  'isales.agent.message.curcountpasswordWrong': 'รหัสผ่านบัญชีปัจจุบันไม่ถูกต้อง',
  'isales.taskinfo.callno.repeat': 'หมายเลขผู้โทรเป็นซ้ํา',
  'isales.file.title.password.same': 'รหัสผ่านสองรหัสแตกต่างกัน',
  'isales.file.title.sk.same': 'SK สองตัวแตกต่างกัน',
  'isales.file.title.SK.again': 'ยืนยัน SK',
  'isales.import.message.template.dataNum': 'สามารถนําเข้าบันทึกข้อมูลสูงสุด {0} ได้ในครั้งละ',
  'isales.import.message.allow.task.statue': ' สามารถนําเข้างานได้เฉพาะเมื่ออยู่ในสถานะร่างหรือหยุดชั่วคราว',
  'isales.taskinfo.callno.length': 'ความยาวของ Single CallerNo เกิน 32 อักขระ',
  'isales.taskstatistics.field.callRatePie': 'อัตราการโทรเสร็จสิ้น',
  'isales.taskstatistics.field.connectedCallsPie': 'จํานวนการโทรที่เชื่อมต่อ',
  'isales.taskstatistics.field.Completed': 'จํานวนการโทรที่เสร็จสมบูรณ์ของลูกค้า',
  'isales.taskstatistics.field.inProgress': 'จํานวนลูกค้าที่โทร',
  'isales.taskstatistics.field.incomplete': 'จํานวนลูกค้าที่รอสาย',
  'isales.taskinfo.field.mutiCallNo': 'กรุณากรอกหมายเลขโทรศัพท์',
  'isales.taskresultexport.message.downloadFilenopemission': 'คุณไม่มีสิทธิ์ส่งออกไฟล์',
  'isales.callrecordexport.message.downloadFilenopemission': 'คุณไม่มีสิทธิ์ส่งออกบันทึกการสื่อสารการโทรออกอัจฉริยะ',
  'isales.taskmanagment.title.callquality.viewstatistics': 'สถิติคุณภาพการโทร',
  'isales.taskmanagment.message.chartDataError': 'ล้มเหลวในการโหลดแผนภูมิ',
  'isales.taskmanagment.title.open.agentMonitor': 'การตรวจสอบตัวแทน',
  'isales.callquality.statistics.message.afterNow': 'คุณไม่สามารถเลือกเวลาเร็วกว่าเวลาเริ่มต้นงานหรือช้ากว่าวันปัจจุบัน!',
  'isales.operation.success': 'การดำเนินการสำเร็จ',
  'isales.operation.training.success': 'ปฏิบัติการสําเร็จ การฝึกอบรมใช้เวลาประมาณหลายชั่วโมง เข้าสู่ระบบ Lodas เพื่อดูความคืบหน้าของการฝึกอบรม',
  'isales.operation.failed': 'การดำเนินการล้มเหลว',
  'isales.taskmanagement.title.batchEditTaskInfo.manual': 'ไม่สามารถแก้ไขการโทรออกอัตโนมัติและการโทรออกด้วยตนเองในเวลาเดียวกัน',
  'isales.management.message.addPropTips': 'ข้อมูลส่วนบุคคลหรือข้อมูลสําคัญต้องถูกเข้ารหัส จัดเก็บ และปกปิด',
  'isales.enum.workSpace': 'เวิร์กเบนต์โทรออก',
  'isales.management.title.black.import': 'นําเข้ารายการบล็อก',
  'isales.black.filed.number': 'หมายเลข (จําเป็น)',
  'isales.black.filed.expTime': 'ระยะเวลาความถูกต้อง (เลือกได้, เป็นชั่วโมง)',
  'isales.black.filed.remark': 'หมายเหตุ (ไม่บังคับ)',
  'isales.taskpolicy.message.obOverTime': 'ป้อนระยะเวลาหมดเวลาแสดงตัวอย่างก่อน',
  'isales.taskpolicy.message.rangeAutoCallTime': 'ค่าอินพุตอยู่นอกช่วง {0} - {1}',
  'isales.taskpolicy.message.obOverTimeRange': 'ระยะเวลาหมดเวลาแสดงตัวอย่างอยู่นอกช่วง: 9 ถึง 120. โปรดเปลี่ยนระยะเวลาหมดเวลาแสดงตัวอย่างก่อน',
  'isales.survey.statistic': 'สถิติการตอบสนอง',
  'isales.survey.querydetail': 'การสอบถามผลแบบสอบถาม',
  'isales.manual.task.survey.questiontotal': 'คําถามทั้งหมด {0} คําถาม',
  'isales.manual.task.survey.unanswered': 'ยังไม่ได้ตอบ',
  'isales.manual.task.survey.answered': 'ตอบแล้ว',
  'isales.manual.task.survey.currentprogress': 'ความคืบหน้าปัจจุบัน',
  'isales.manual.task.survey.previousquestion': 'คําถามก่อนหน้า',
  'isales.manual.task.survey.nextquestion': 'คําถามต่อไป',
  'isales.manual.task.survey.submit': 'ส่ง',
  'isales.taskinfo.field.survey': 'แบบสอบถาม',
  'isales.manual.task.survey.noAnswer': 'ไม่มีคําตอบที่ต้องการ',
  'isales.manual.task.survey.answerSuccess': 'ตอบสําเร็จแล้ว!',
  'isales.agent.calledroute.search.devicedesc': 'กรุณาใส่ชื่ออุปกรณ์',
  'isales.agent.calledroute.create': 'เพิ่มการกำหนดค่าที่เรียกว่า',
  'isales.agent.calledroute.device': 'กรุณาเลือกอุปกรณ์',
  'isales.agent.calledroute.devicetype': 'ประเภทอุปกรณ์',
  'isales.agent.calledroute.extcode': 'รหัสกระจาย',
  'isales.agent.calledroute.ivr': 'กรุณาเลือก IVR',
  'isales.agent.calledroute.placeholder.ivr': 'กรุณาเลือก IVR',
  'isales.agent.calledroute.skill': 'คิวสกิล',
  'isales.agent.calledroute.placeholder.skill': 'กรุณาเลือกคิวทักษะ',
  'isales.agent.calledroute.accesscode': 'รหัสการเข้าถึง',
  'isales.agent.calledroute.placeholder.accesscode': 'กรุณาเลือกรหัสเข้าใช้',
  'isales.agent.calledroute.placeholder.extcode': 'กรุณากรอกรหัสนามสกุล',
  'isales.calledroute.title.selectaccesscode': 'เลือกรหัสเข้าใช้',
  'isales.agent.calledroute.extcodewarn': 'รหัสส่วนขยายสามารถมีได้เฉพาะตัวเลขเท่านั้น',
  'isales.agent.calledroute.extlengthcodewarn': 'ความยาวรหัสส่วนขยายต้องไม่เกิน 12',
  'isales.agent.calledroute.editsuccess': 'แก้ไขเรียบร้อยแล้ว',
  'isales.agent.calledroute.exception': 'โปรดตรวจสอบสถานะ Redis หรือการกำหนดค่า CC-Gateway',
  'isales.agent.calledroute.editfail': 'การแก้ไขข้อมูลในเครื่องล้มเหลว',
  'isales.agent.calledroute.accesscodewarn': 'รหัสเข้าใช้ได้เฉพาะตัวเลขเท่านั้น',
  'isales.agent.calledroute.createsuccess': 'เพิ่มเรียบร้อยแล้ว',
  'isales.agent.calledroute.createfail': 'ไม่สามารถเพิ่มข้อมูลในเครื่อง',
  'isales.agent.calledroute.create.accesscode.none': 'ไม่ได้กำหนดรหัสการเข้าถึงระบบที่กำหนดค่าโดยฝ่ายที่เรียก',
  'isales.agent.calledroute.create.accesscode.repeat': 'การรวมกันของรหัสการเข้าถึงระบบและรหัสส่วนขยายที่กำหนดค่าโดยฝ่ายที่เรียกไม่สามารถทำซ้ำได้',
  'isales.agent.calledroute.create.accesscode.noexist': 'ไม่มีรหัสการเข้าถึงที่อ้างอิงโดยการกำหนดค่าที่เรียก',
  'isales.agent.calledroute.create.skill.none': 'ไม่มีคิวทักษะที่อ้างอิงโดยการกำหนดค่าที่เรียก',
  'isales.agent.calledroute.create.ivr.limit': 'ประเภทอุปกรณ์คือ IVR และประเภทสื่อของรหัสการเข้าถึงต้องเป็นแบบคลิกเพื่อโทร คำขอโทรกลับ หรือการโทรปกติเท่านั้น',
  'isales.agent.calledroute.create.gateway.none': 'ไม่มีสคริปต์การกำหนดเส้นทางที่อ้างอิงโดยการกำหนดค่าที่เรียก',
  'isales.agent.calledroute.create.media.match': 'รหัสการเข้าถึงระบบที่กำหนดค่าโดยฝ่ายที่เรียกไม่สอดคล้องกับประเภทสื่อของอุปกรณ์ที่กำหนดค่าโดยฝ่ายที่เรียก',
  'isales.agent.calledroute.recordexist': 'มีการผูกรหัสเข้าใช้งานและรหัสส่วนขยายแล้ว โปรดป้อนรหัสส่วนขยายใหม่',
  'isales.agent.calledroute.nmsdata.error': 'การซิงโครไนซ์ของ ODFS ล้มเหลว และข้อมูลการกำหนดค่าของ ODFS ที่เรียกว่าปาร์ตี้ไม่สอดคล้องกับข้อมูลในเครื่องของ CCM',
  'isales.agent.calledroute.ccivrdata.error': 'ไม่สามารถซิงโครไนซ์ ODFS ได้ ข้อมูลกระบวนการ CC-IVR ไม่ถูกต้อง',
  'isales.agent.calledroute.nmsconnect.fail': 'ไม่สามารถเชื่อมต่อกับ ODFS',
  'isales.management.placeholder.accesscodeDesc': 'โปรดป้อนคำอธิบายรหัสการเข้าถึง',
  'isales.taskinfo.title.accesscode': 'รหัสการเข้าถึง',
  'isales.taskinfo.title.accesscodedesc': 'คำอธิบายรหัสเข้าใช้งาน',
  'isales.agent.contact.voiceVidio': 'เสียงและวิดีโอ',
  'isales.agent.contact.clicktodial': 'คลิกเพื่อโทร',
  'isales.note.config.success': 'ความสำเร็จ',
  'isales.agent.calledroute.refreshsuccess': 'รีเฟรชสำเร็จแล้ว',
  'isales.agent.calledroute.updatecalledroute': 'แก้ไข',
  'isales.agent.calledroute.confdelete': 'ลบการยืนยัน',
  'isales.agent.calledroute.isconfdelete': 'แน่ใจว่าจะลบ?',
  'isales.agent.calledroute.success': 'ความสำเร็จ',
  'isales.agent.calledroute.delsuccess': 'ลบเรียบร้อยแล้ว',
  'isales.agent.message.redislocknull': 'ไม่สามารถรับ Redis lock',
  'isales.calledroute.message.selectcalledroute': 'โปรดเลือกการกำหนดค่าที่เรียกว่า',
  'isales.agent.calledroute.delfail': 'การลบข้อมูลในเครื่องล้มเหลว',
  'isales.label.appraise.call.quality.agent': 'ตัวแทน',
  'isales.label.appraise.call.quality.customer': 'ลูกค้า',
  'isales.label.appraise.call.quality.customerInfo': 'ข้อมูลลูกค้า',
  'isales.taskstatistics.field.feedBackStatistics': 'สถิติการตอบรับ',
  'isales.task.message.feedBackRate': 'อัตราการตอบรับ',
  'isales.task.message.numberEffFeedback': 'จํานวนการโทรตอบกลับที่ถูกต้อง',
  'isales.task.message.numberInvalFeedback': 'จํานวนการโทรตอบกลับที่ไม่ถูกต้อง',
  'isales.survey.details': 'ตอบรายละเอียด',
  'isales.taskstatistics.field.specialCall': 'รายการพิเศษ',
  'isales.taskmanagment.title.open.obsMonitor': 'การตรวจสอบข้อมูล OBS',
  'isales.management.message.black.download': 'ดาวน์โหลดเทมเพลต Blocklist',
  'isales.taskmanagment.title.obs.viewstatistics': 'การตรวจสอบ OBS การได้รับข้อมูล',
  'isales.task.statistics.obs.startTime': 'เวลาเริ่มต้นต้องไม่เร็วกว่าเจ็ดวันที่ผ่านมา',
  'isales.warning.data.export': 'ข้อมูลที่ส่งออกมีข้อมูลส่วนบุคคล ใช้ความระมัดระวังเมื่อทําการดําเนินการนี้',
  'isales.statistics.business.taskId': 'รหัสงาน',
  'isales.statistics.business.reportType': 'ประเภทรายงาน',
  'isales.statistics.business.beginDate': 'วันที่เริ่มต้น',
  'isales.statistics.business.endDate': 'วันที่สิ้นสุด',
  'isales.statistics.business.startYear': 'เริ่มต้นปี',
  'isales.statistics.business.startWeek': 'เริ่มต้นสัปดาห์',
  'isales.statistics.business.startMonth': 'เริ่มต้นเดือน',
  'isales.statistics.business.endYear': 'สิ้นปี',
  'isales.statistics.business.endWeek': 'สัปดาห์สิ้นสุด',
  'isales.statistics.business.endMonth': 'สิ้นสุดเดือน',
  'isales.statistics.business.search': 'แบบสอบถาม',
  'isales.statistics.business.reset': 'รีเซ็ต',
  'isales.statistics.business.date': 'วันที่',
  'isales.statistics.business.week': 'สัปดาห์',
  'isales.statistics.business.month': 'เดือน',
  'isales.statistics.business.byDay': 'ทุกวัน',
  'isales.statistics.business.byWeek': 'รายงานประจําสัปดาห์',
  'isales.statistics.business.byMonth': 'รายงานประจําเดือน',
  'isales.statistics.business.title': 'รายงานบริการโทรออก',
  'isales.statistics.business.trend.title': 'รายงานแนวโน้มบริการโทรออก',
  'isales.statistics.business.nomorethanerror': 'วันที่ที่เลือกต้องไม่เร็วกว่า {0}',
  'isales.statistics.business.currentDateCheck': 'วันที่ที่เลือกต้องเร็วกว่าวันปัจจุบัน',
  'isales.statistics.business.dategt31days': 'วันแบบสอบถามต้องไม่เกิน 31 วัน',
  'isales.statistics.business.dategt12weeks': 'ช่วงสัปดาห์ต้องไม่เกิน 12 สัปดาห์',
  'isales.statistics.business.dategt6month': 'ช่วงเดือนต้องไม่เกิน 6 เดือน',
  'isales.statistics.business.weekLessThan': 'จํานวนสัปดาห์ในปีสุดท้ายต้องไม่น้อยกว่าจํานวนสัปดาห์ในปีเริ่มต้น',
  'isales.statistics.business.monthLessThan': 'ปีสิ้นสุดและเดือนต้องไม่เร็วกว่าปีเริ่มต้น',
  'isales.statistics.business.yearLessThan': 'ปีสุดท้ายต้องไม่เร็วกว่าปีเริ่มต้น',
  'isales.statistics.business.noData': 'ไม่มีข้อมูล',
  'isales.statistics.business.noMoreThanEight': 'จํานวนอุปกรณ์ที่เลือกต้องไม่เกิน 8',
  'isales.statistics.business.noMoreThanFive': 'จํานวนเร็กคอร์ดที่เลือกต้องไม่เกิน 5 รายการ',
  'isales.statistics.business.busiResult': 'ผลลัพธ์ทางธุรกิจ',
  'isales.statistics.business.subBusiResult': 'ผลลัพธ์ของบริการย่อย',
  'isales.manual.title.viewPreemptionResult': 'ผลลัพธ์การโทรออกล่วงหน้า -',
  'isales.statistics.business.filteringIndicator': 'ตัวชี้วัดการกรอง',
  'isales.statistics.business.indicatorName': 'ชื่อตัวนับ',
  'isales.statistics.business.description': 'คําบรรยายภาพ',
  'isales.statistics.business.cancel': 'ยกเลิก',
  'isales.statistics.business.confirm': 'ยืนยัน',
  'isales.statistics.business.saveError': 'ข้อผิดพลาด',
  'isales.statistics.business.errorInfo': 'การบันทึกล้มเหลว โปรดเลือกอย่างน้อยหนึ่งตัวนับ',
  'isales.statistics.business.error': 'ข้อผิดพลาด',
  'isales.statistics.business.warningFilterMax': 'คุณสามารถเลือกเคาน์เตอร์ได้สูงสุด {0} ตัว',
  'isales.agent.message.description.limit': 'คําอธิบายคิวทักษะต้องไม่เกิน 50 ตัวอักษร',
  'isales.agent.message.updateCtiAndDbFail': 'การรีเฟรชล้มเหลว',
  'isales.taskinfo.message.agent': 'ข้อมูลของเจ้าหน้าที่ไม่ปกติ โปรดตรวจสอบว่าเอเจนต์ในคิวทักษะไม่ใช่ตัวปัจจุบันหรือไม่',
  'isales.language.label.language': 'โปรดเลือกภาษา',
  'isales.language.label.zh_CN': 'จีน',
  'isales.language.label.en_US': 'ภาษาไทย',
  'isales.language.label.es_ES': 'ภาษาไทย',
  'isales.taskmanagement.title.batchOpt': 'การดําเนินการแบบกลุ่ม',
  'isales.audio.title.beginTime': 'เวลาเริ่มต้นการบันทึก',
  'isales.audio.title.endTime': 'เวลาสิ้นสุดการบันทึก',
  'isales.audio.title.asrTransResult': 'ผลการถอดเสียงพูด',
  'isales.audio.title.confirmedResult': 'ผลการจดจำเสียงเรียกเข้า',
  'isales.audio.title.confirmStatus': 'ผลลัพธ์ทางธุรกิจ',
  'isales.taskstatus.field.confirming': 'ได้รับการยืนยัน',
  'isales.taskstatus.field.confirmed': 'ได้รับการยืนยัน',
  'isales.rbtresult.field.other': 'อื่นๆ',
  'isales.rbtresult.field.emptynumber': 'เบอร์ว่าง',
  'isales.rbtresult.field.cusclose': 'ผู้ใช้ที่โทรมาปิดโทรศัพท์',
  'isales.rbtresult.field.interbusy': 'เครือข่ายไม่ว่าง',
  'isales.rbtresult.field.callershutdown': 'การระงับการโทรค้าง / การระงับทางเดียว',
  'isales.rbtresult.field.calledshutdown': 'เรียกให้หยุด',
  'isales.rbtresult.field.calledbusy': 'ฝ่ายที่รับสายไม่ว่าง และฝ่ายที่รับสายได้ลงทะเบียนฟังก์ชันสายเรียกซ้อนแล้ว',
  'isales.rbtresult.field.calledbusycalling': 'ฝ่ายที่รับสายไม่ว่าง และฝ่ายที่รับสายยังไม่ได้ลงทะเบียนฟังก์ชันสายเรียกซ้อน',
  'isales.rbtresult.field.calledoutarea': 'ฝ่ายที่โทรไม่อยู่ในพื้นที่ให้บริการ',
  'isales.rbtresult.field.transfer': 'การโอนสาย',
  'isales.rbtresult.field.tollcaller': 'ข้อ จำกัด การโทรระหว่างประเทศ',
  'isales.rbtresult.field.longtimemissed': 'นานแล้วที่ไม่ได้โทร',
  'isales.rbtresult.field.calledrestriction': 'ผู้ใช้ที่โทรได้ตั้งค่าการจำกัดการโทรเข้า',
  'isales.rbtresult.field.linefaulty': 'สายล้มเหลวในทิศทางของผู้ดำเนินการรายอื่น',
  'isales.rbtresult.field.calledunconnected': 'บุคคลที่โทรไม่พร้อมใช้งาน และเปิดใช้งานการเตือนสายเรียกเข้า',
  'isales.rbtresult.emptyparam.message': 'เวลาเริ่มต้นและเวลาสิ้นสุดต้องไม่ว่างเปล่า',
  'isales.asrtransresult.field.undefine': 'ไม่ได้กำหนด',
  'isales.taskmanagement.message.crossmonth': 'เวลาเริ่มต้นและเวลาสิ้นสุดต้องไม่นับรวมเป็นเดือน',
  'isales.taskinfo.url.not.whitelist': 'ที่อยู่ไม่อยู่ในรายการที่อนุญาต',
  'isales.whitepopuppageurl.protocol.check': 'การใช้โปรโตคอลที่ไม่ปลอดภัย มีความเสี่ยงด้านความปลอดภัย',
  'isales.import.message.ruleExtProp': 'เปิดใช้แอตทริบิวต์แบบกําหนดเองแล้ว กําหนดค่ากฎการแมปการนําเข้าก่อน แล้วคลิก นําเข้าโดยกฎ',
  'isales.export.message.noSelectTrainingData': 'โปรดเลือกข้อมูลที่จะเข้ารับการฝึกอบรม',
  'isales.datasourcemanagement.field.load': 'โหลด',
  'isales.datasourcemanagement.message.loadSuccess': 'โหลดแหล่งข้อมูลเรียบร้อยแล้ว',
  'isales.datasourcemanagement.message.loadfail': 'โหลดแหล่งข้อมูลไม่สำเร็จ',
  'isales.datasourcemanagement.message.confirLoad': 'การโหลดการกำหนดค่าแหล่งข้อมูลเป็นการดำเนินการที่มีความเสี่ยงสูง โปรดดำเนินการด้วยความระมัดระวัง',
  'isales.task.promotion.whatsApp': 'WhatsApp',
  'isales.task.promotion.whatsAppChannel': 'ช่องทาง WhatsApp',
  'isales.task.promotion.whatsApp.message.template': 'ข้อความแม่แบบ',
  'isales.task.promotion.whatsApp.message.template.select': 'โปรดเลือกข้อความเทมเพลต WhatsApp',
  'isales.task.promotion.whatsApp.channel.select': 'โปรดเลือกช่อง WhatsApp',
  'isales.task.promotion.whatsApp.message.id': 'รหัสข้อความของแม่แบบ',
  'isales.task.promotion.whatsApp.message.name': 'ชื่อข้อความของแม่แบบ',
  'isales.task.promotion.whatsApp.variable.description': ' คําอธิบายพารามิเตอร์',
  'isales.task.promotion.whatsApp.variable.content': ' เนื้อหาพารามิเตอร์',
  'isales.task.promotion.promotionPolicy': 'กลยุทธ์การตลาด',
  'isales.management.message.SelectNumberMax': 'เลือกได้สูงสุด 100 เลขหมายต่อครั้ง',
  'isales.basicinfo.title.addCallNo': 'เพิ่มเบอร์โทร',
  'isales.taskmanagement.title.deleteFailExitOne': 'กำหนดค่าอย่างน้อยหนึ่งหมายเลขโทรสำหรับงานโทรออก',
  'isales.failInterval.check': 'อาจมีความเสี่ยงเมื่อช่วงเวลาน้อยกว่า 10 วินาที',
  'isales.taskmanagement.title.agentStatistics': 'สถิติการทำงานของตัวแทน',
  'isales.work.message.daterequest': 'ต้องผ่านเวลาเริ่มต้นและสิ้นสุดในเวลาเดียวกัน',
  'isales.work.title.selectAgent': 'เลือกตัวแทน',
  'isales.work.title.agent.workno': 'รหัสตัวแทน',
  'isales.message.promptForAgent': 'โปรดป้อนรหัสตัวแทน',
  'isales.button.search': 'สอบถามข้อมูล',
  'isales.button.reset': 'รีเซ็ต',
  'isales.title.agent.workno': 'รหัสตัวแทน',
  'isales.label.agent.accountcode': 'บัญชีบริการ',
  'isales.agent.work.statistics.AgentName': 'ชื่อตัวแทน',
  'isales.button.cancel': 'ยกเลิก',
  'isales.button.confirm': 'ยืนยัน',
  'isales.file.message.FilePathTips': 'ไม่จําเป็น ต้องกรอกพาธของแฟ้มในพาธปริยาย หากคุณวางอยู่ในพาธปริยาย คุณต้องกรอกพาธของแฟ้ม',
  'isales.blacklist.message.timeserror': 'การจํากัดจํานวนครั้งผิดพลาด',
  'isales.blacklist.message.perioderror': 'เกิดข้อผิดพลาดในการจํากัดระยะเวลา',
  'isales.datasourcemanagement.message.updateDataSourceSuccess': 'แก้ไขแหล่งข้อมูลเรียบร้อยแล้ว',
  'isales.datasourcemanagement.message.updateDataSourceFail': 'ไม่สามารถแก้ไขแหล่งข้อมูลได้',
  'isales.taskmanagement.title.batchEdit': 'แก้ไขแบทช์',
  'isales.task.status.running': 'กําลังดําเนินการ',
  'isales.task.status.pause': 'หยุดชั่วคราว',
  'isales.task.finished': 'เสร็จสิ้น',
  'isales.task.notFinished': 'ยังไม่เสร็จ',
  'isales.task.info': 'ข้อมูลงาน',
  'isales.task.benginEndTime': 'เวลาเริ่มต้น',
  'isales.task.detail': 'รายละเอียดงานการโทรออก',
  'isales.taskmanagement.startTask': 'เปิดงาน',
  'isales.step.taskinfo': 'ข้อมูลงาน',
  'isales.step.callpolicy': 'กลยุทธ์ขาออก',
  'isales.step.outboundcalldata': 'ข้อมูลงาน',
  'isales.calltime.title.default.datetime': 'ช่วงเวลาวันที่ปกติ',
  'isales.calltime.title.spec.date': 'วันที่พิเศษ',
  'isales.calltime.title.add.date': 'เพิ่มวันที่พิเศษ',
  'isales.calltime.title.add.time.period': 'เพิ่มช่วงเวลา',
  'isales.calltime.title.holidayName': 'ชื่อวันที่พิเศษ',
  'isales.calltime.title.holidayType': 'พิมพ์',
  'isales.calltime.title.holiday': 'ช่วงเวลาพิเศษ',
  'isales.calltime.title.spec.datetime.period': 'ช่วงเวลาพิเศษ',
  'isales.calltime.task.template': 'เทมเพลตงาน',
  'isales.calltime.task.template.select.placeholder': 'ตัวเลือกในการสร้างงานขาออกจากเทมเพลต',
  'isales.calltime.task.callerno.select.placeholder': 'กรุณาเลือกเบอร์โทร',
  'isales.calltime.task.begin.end.date': 'เวลาเริ่มต้นและสิ้นสุดของงาน',
  'isales.calldata.prop.filter': 'การกรองแอตทริบิวต์ข้อมูลการโทรออก',
  'isales.taskinfo.header.policy': 'กลยุทธ์งาน',
  'isales.taskinfo.call.header.policy': 'กลยุทธ์การโทร',
  'isales.taskmanagement.title.sysAuto': 'ระบบจะโทรออกโดยอัตโนมัติ',
  'isales.work.message.dateRangeRequest': 'โปรดเลือกช่วงเวลาในการสืบค้น',
  'isales.work.message.taskNameLengthLimit': 'ความยาวของชื่องานต้องไม่เกิน 50',
  'isales.work.message.workNoLengthLimit': 'ความยาวของชื่องานต้องไม่เกิน 50',
  'isales.work.message.workNoTypeLimit': 'ต้องเป็น 0 หรือเต็มจํานวน',
  'isales.label.agent.agentType': 'ประเภทตัวแทน',
  'isales.label.agent.role': 'บทบาท',
  'isales.calleeNo.length.max32': 'ความยาวสูงสุดคือ 32',
  'isales.label.calldata.order': 'ลำดับการโทร',
  'isales.tasklogic.field.callOrderMethod.byData': 'ตามแอตทริบิวต์ข้อมูล',
  'isales.tasklogic.field.callOrderMethod.orderType': 'จัดเรียงตาม',
  'isales.label.multiple.callno.round': 'รอบการโทรของลูกค้าหลายหมายเลข',
  'isales.task.result.export.query': 'ไปที่ดู',
  'isales.task.result.export.skip': 'ข้าม',
  'isales.task.result.export.message': 'สร้างงานส่งออกสําเร็จแล้ว คุณต้องการดูรายการส่งออกหรือไม่?',
  'isales.rbtrecognition.remark.length': 'ความยาวของสตริงเกินช่วงต้น ความยาวต้องน้อยกว่า 200',
  'isales.task.field.callFlag0': 'สิ่งที่ต้องดำเนินการ',
  'isales.task.field.callFlag1': 'กำลังดำเนินการ',
  'isales.task.field.callFlag2': 'เสร็จสมบูรณ์',
  'isales.taskinfo.field.agentType4': 'เสียง',
  'isales.taskinfo.field.agentType11': 'วิดีโอ',
  'isales.taskinfo.field.agentType5': 'มัลติมีเดีย',
  'isales.taskinfo.field.agentType99': 'อเนกประสงค์',
  'isales.appointment.title.appointState': 'สถานะการจอง',
  'isales.task.field.appointState1': 'จองแล้ว',
  'isales.task.field.appointState2': 'เตรียมตัวให้พร้อม',
  'isales.task.field.appointState3': 'แก้ไขได้',
  'isales.task.field.appointState4': 'เสร็จสิ้นแล้ว',
  'isales.taskinfo.title.adjustAgent': 'ปรับตัวแทน',
  'isales.taskinfo.check.empty': 'พารามิเตอร์ต้องไม่ว่างเปล่า',
  'isales.tasktype.field.robot.create': 'การโทรออกของหุ่นยนต์ตัวใหม่',
  'isales.tasktype.field.auto.info': 'IVR ปกติ',
  'isales.tasktype.field.intelligent.info': 'หุ่นยนต์อัจฉริยะ',
  'isales.taskmanagement.title.pauseTask.askConfirm': 'คุณแน่ใจหรือไม่ว่าต้องการระงับงานการโทรขาออก หากงานถูกบังคับหยุดชั่วคราว การโทรต่อเนื่องอาจได้รับผลกระทบ',
  'isales.task.result.callrecord.conversation': 'บันทึกการสนทนา',
  'isales.tasktype.field.auto.robot.create': 'IVR ปกติใหม่',
  'isales.tasktype.field.intelligent.robot.create': 'สร้างหุ่นยนต์อัจฉริยะตัวใหม่',
  'isales.management.title.callbot.auto': 'กำหนดค่าปุ่มเพื่อเรียกหุ่นยนต์ออกมา',
  'isales.taskmanagement.title.taskDataVolume': 'ปริมาณข้อมูลงาน',
  'isales.taskmanagement.title.callCompletionRate': 'อัตราการเสร็จสิ้นการโทร',
  'isales.taskmanagement.title.importData.template': 'ภาษาไทย',
  'isales.taskmanagement.title.importData.upload': 'อัพโหลด',
  'isales.taskmanagement.title.importData.selectFile': 'เลือกไฟล์',
  'isales.audio.content.calltype': 'การโทรออกด้วยเสียง',
  'isales.taskmanagement.pauseTask': 'ปริมาณข้อมูลงาน',
  'isales.taskInfo.spec.date.title.quotasTips': 'การกำหนดค่านี้ระบุวันที่และช่วงเวลาพิเศษที่ต้องใช้การโทรออก',
  'isales.calltime.title.spec.date.empty.warn': 'ไม่ได้กำหนดวันที่พิเศษ!',
  'isales.tasklogic.message.empty.orderNo': 'ไม่มีหมายเลขประจำเครื่อง โปรดป้อนใหม่',
  'isales.taskmanagement.exportResult': 'ผลลัพธ์งานส่งออก',
  'isales.outboundBusiness.statistic.di': 'สัปดาห์',
  'isales.outboundBusiness.statistic.week': ' ',
  'isales.rbtrecognition.remark.tip': 'ไม่สามารถรวมอักขระพิเศษได้:',
  'isales.business.callInterval.message.must': 'ต้องระบุช่วงเวลา',
  'isales.tasktype.field.robot': 'ขาออกของหุ่นยนต์',
  'isales.taskInfo.title.taskTemplate': 'แม่แบบงาน',
  'isales.taskmanagement.title.taskTime': 'เวลางาน',
  'isales.taskmanagement.title.setAgent': 'การมอบหมายตัวแทน',
  'isales.taskInfo.title.quotas': 'ความสําเร็จทางธุรกิจ',
  'isales.taskInfo.title.quotasTips': 'เมื่อจํานวนการโทรที่เอเจนต์ทําเครื่องหมายว่าเป็นผลมาจากบริการก่อนหน้านี้เกินค่าของพารามิเตอร์นี้ ระบบจะไม่เรียกสายโดยอัตโนมัติ',
  'isales.taskInfo.title.callCompletedNumTips': 'เมื่อจํานวนการโทรที่เสร็จสมบูรณ์เกินค่าของพารามิเตอร์นี้ระบบจะไม่เรียกสายโดยอัตโนมัติ',
  'isales.taskInfo.title.pickUpNum': 'จํานวนที่เรียกร้องครั้งเดียว',
  'isales.taskInfo.title.pickUpCondition': 'เงื่อนไขการสกัดข้อมูล',
  'isales.taskInfo.title.filterCondition': 'เงื่อนไข',
  'isales.taskInfo.title.callCompletedNum': 'การโทรที่เสร็จสมบูรณ์',
  'isales.taskmanagement.title.sysAuto.template': 'เทมเพลตการโทรออกอัตโนมัติของระบบ',
  'isales.taskmanagement.title.sysAuto.templateAdd': 'สร้างเทมเพลตระบบใหม่อัตโนมัติ',
  'isales.tasktype.field.robot.template': 'เทมเพลตการโทรออกของหุ่นยนต์',
  'isales.tasktype.field.robot.templateAdd': 'สร้างเทมเพลตหุ่นยนต์ใหม่',
  'isales.template.title.addManual': 'เทมเพลต ppt ทั่วไป',
  'isales.manual.field.callingRecord': 'บันทึก',
  'isales.call.count': 'จํานวนการเปิดรับ',
  'isales.task.promotion.createBeginTime': 'สร้างเวลาเริ่มต้น',
  'isales.task.promotion.createEndTime': 'สร้างเวลาสิ้นสุด',
  'isales.task.promotion.timeerror': 'เวลาที่มีผลบังคับใช้ต้องน้อยกว่าเวลาหมดอายุ',
  'isales.taskmanagement.title.taskContent': 'เนื้อหางาน',
  'isales.taskmanagement.title.saveAndImportData': 'บันทึกและนําเข้าข้อมูล',
  'isales.task.promotion.baseInfo': 'ข้อมูลพื้นฐาน',
  'isales.busiresult.description.length': 'ความยาวของสตริงอักขระเกินช่วงค่า ความยาวต้องน้อยกว่าหรือเท่ากับ 500 ตัวอักษร',
  'isales.import.coverMode.tips': 'เขียนทับการนําเข้าข้อมูลจะลบข้อมูล ที่เหลือทั้งหมด ระยะเวลาการดําเนินการจะยาวนานขึ้น โปรดเลือกอย่างระมัดระวัง',
  'isales.task.promotion.numberOnly': 'เฉพาะจํานวนเต็มที่ไม่ใช่ลบเท่านั้น',
  'isales.data.lock': 'ข้อมูลการโทรออกบางรายการไม่ได้รับการประมวลผล ตัวอย่างเช่น คุณกําลังนําเข้า เพิ่ม หรือลบข้อมูลการโทรออก โปรดลองอีกครั้งในภายหลัง',
  'isales.survey.type': 'ประเภทแบบสอบถาม',
  'ccsurvey.label.scenarioType.manual': 'แบบสอบถามด้วยตนเอง',
  'ccsurvey.label.scenarioType.manualIvr': 'คู่มือเทียบกับแบบสอบถาม IVR',
  'isales.tasktype.field.manualIvr.info': 'ขั้นตอนการเล่นแบบสอบถาม',
  'ccsurvey.info.tip36': 'จําเป็นต้องใช้หัวข้อนี้',
  'ccsurvey.transfer.tip36': 'ระงับการเปิดใช้แบบสอบถามการเล่น IVR',
  'isales.taskmanagement.title.inputTaskId': 'ป้อนรหัสงาน',
  'isales.notification.configVariableTe': 'ปรับแต่งตัวแปรแม่แบบ',
  'isales.notification.config': 'ปรับแต่ง',
  'isales.taskmanagement.title.toBeCallCount': 'สายที่รอดําเนินการ',
  'isales.taskmanagement.field.taskId': 'รหัสงาน',
  'isales.taskinfo.message.callintervalrangeMax': 'โปรดป้อนจำนวนเต็มบวกระหว่าง 1-86400',
  'isales.notification.receiver.whatsapp.placeholder': 'โปรดป้อนหมายเลขผู้รับที่มีรหัสโซนระหว่างประเทศ',
  'isales.data.title.more': 'เพิ่มเติม',
  'isales.manual.field.canBeCallCount': 'ปริมาณการเปิดรับสัญญาณ',
  'isales.manual.exit.preview': 'ประมวลผลการแสดงตัวอย่าง',
  'isales.manual.exit.preview.tips': 'หากการแสดงตัวอย่างหมดเวลาหรือปิดหน้าต่าง ระบบจะแจ้งให้คุณโดยอัตโนมัติ',
  'isales.result.search.displayResultOrCode': 'กรุณาใส่รหัสหรือคำอธิบาย',
  'isales.management.message.dbIp': 'รูปแบบ IP ไม่ถูกต้อง',
  'isales.agent.message.modifyPwdVdnFailed': 'ไม่อนุญาตให้แก้ไขรหัสผ่านผู้ดูแลระบบการกำหนดค่า!',
  'isales.business.result.message.checkCodePoint': 'สามารถป้อนได้เฉพาะตัวเลข, ตัวอักษร, -,. เท่านั้น',
  'isales.import.calldata.selecttime': 'เลือกเวลาดําเนินการ',
  'isales.statistics.message.chooseCondition': 'โปรดเลือกเงื่อนไขการค้นหาก่อน',
  'isales.taskpolicy.field.time': '(ที่สอง)',
  'isales.taskInfo.title.filterSubCondition': 'กรองเงื่อนไขย่อย',
  'isales.task.promotion.sendTime': 'เวลาส่ง',
  'isales.agent.work.statistics.tableTips': 'โปรดเลือกงานหรือเจ้าหน้าที่ที่คุณต้องการดูก่อน',
  'isales.task.promotion.selectSendTemplate': 'เลือกเทมเพลต',
  'isales.file.message.rule.add.success': 'กฎใหม่สําเร็จ',
  'isales.file.title.server.add.success': 'ข้อมูลเซิร์ฟเวอร์ใหม่เสร็จสมบูรณ์',
  'isales.management.title.selectResult': 'เลือกผลลัพธ์',
  'isales.specialday.placeholder.year.beginTime': 'รูปแบบคือ: MM-DD เช่น: 12-01',
  'isales.datasourcemanagement.field.dbIp': 'IP แหล่งข้อมูลหรือนามแฝง',
  'isales.datasourcemanagement.field.backdbIp': 'IP ฐานข้อมูลสแตนด์บายหรือนามแฝง',
  'isales.management.message.appointTime': 'เวลาโทรที่สํารองไว้ต้องไม่อยู่ก่อนเวลาระบบปัจจุบัน',
  'isales.management.message.intelligence.taskName': 'ความยาวของชื่องานของประเภทการโทรขาออกของหุ่นยนต์อัจฉริยะต้องยาวไม่เกิน 36 ไบต์ ตัวอักษรหรือตัวเลขหนึ่งตัวใช้พื้นที่ 1 ไบต์ และตัวอักษรจีนหนึ่งตัวใช้พื้นที่ 3 ไบต์',
}
