<template>
  <div class="aicc-back-header">
    <div v-if="isShowBackButton" class="back-btn" @click="backInTab"></div>
    <div class="header-title" :class="{ 'header-title--noback': !isShowBackButton }" v-if="pageType === 'pageWithSideNav'">
      {{ baseStore.topHeaderName }}
    </div>
    <div class="header-title" :class="{ 'header-title--noback': !isShowBackButton }" v-if="pageType === 'pageWithBackHeader'">
      {{ headerTitle }}
    </div>
    <div class="header-title" :class="{ 'header-title--noback': !isShowBackButton }" v-if="pageType === 'pageWithFixedTitle'">
      {{ fixedTitle }}
    </div>
    <div class="expanded-title" :id="'expanded-title-' + routeName"></div>
    <div class="right-content" :id="'right-content-' + routeName"></div>
  </div>
</template>

<script>
import { useBaseStore } from '../../stores'
export default {
  name: 'AiccBackHeader',
  setup() {
    const baseStore = useBaseStore()
    return { baseStore }
  },
  props: {
    pageType: {
      type: String,
      default() {
        return 'pageWithBackHeader'
      }
    },
    routeName: {
      type: String,
      default() {
        return ''
      }
    },
    isShowBackButton: {
      type: Boolean,
      default: true
    },
    fixedTitle: {
      type: String,
      default: ''
    },
  },
  computed: {
    headerTitle() {
      return this.baseStore.routeTitles[this.$route.name]
    }
  },
  methods: {
    backInTab() {
      this.$event.backInTab()
    }
  }
}
</script>

<style lang="less" scoped>
.aicc-back-header {
  position: relative;
  display: flex;
  align-items: center;
  height: 56px;
  padding: 0 20px;
  background-color: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.1);
  z-index: 99;

  .back-btn {
    position: relative;
    height: 20px;
    width: 20px;
    background-color: #f4f4f4;
    border-radius: 2px;

    &::after {
      position: absolute;
      content: '';
      background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAOCAYAAAASVl2WAAAAAXNSR0IArs4c6QAAAHdJREFUGBljYAACBQUFDhCNDTDJycm5/Pnz54m8vLwhVgVJSUn7gBLb/v37tweXIoaGhgYmGRmZRbKysm8pVwQ0ZSFBk2CKgLQyEzaXA8X+AjETKysrqjzIsUBd84D4PRCboGimkSTIDmAA2QPte4dhJ7ID8EUWALHHMmR0Xyj+AAAAAElFTkSuQmCC')
        no-repeat center;
      cursor: pointer;
      width: 8px;
      height: 8px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(180deg);
    }
  }

  .header-title {
    margin-left: 18.5px;
    font-size: var(--20px-to-rem);
    font-weight: 500;
    color: #1c1c1c;
  }

  .header-title--noback {
    margin-left: 0;
  }

  .expanded-title {
    display: flex;
    align-items: center;
    margin-left: 24px;
  }

  .right-content {
    display: flex;
    flex: 1;
    justify-content: right;
    align-items: center;
  }
}

body.RTL {
  .aicc-back-header {
    .back-btn {
      &::after {
        left: unset;
        right: 50%;
        transform: translate(50%, -50%) rotate(0);
      }
    }
  }

  .header-title {
    margin-right: 18.5px;
    margin-left: unset;
  }

  .header-title--noback {
    margin-left: unset;
    margin-right: 0;
  }

  .expanded-title {
    margin-right: 24px;
    margin-left: unset;
  }

  .right-content {
    justify-content: left;
  }
}
</style>
