<template>
    <div>
        <sweet-dialog v-model="dialogVisible" :title="$t('isales.import.filter.title.popup')" width="616px" class="aicc-dialog-limit-height">
            <sweet-form :model="form" label-width="auto" :label-position="labelPosition">
                <sweet-form-item :label="$t('isales.import.filter.button.lineRepeat')">
                    <div class="switch-class">
                        <sweet-switch v-model="form.lineRepeat"/>
                    </div>
                </sweet-form-item>
                <sweet-form-item :label="$t('isales.import.filter.button.noRepeat')" >
                    <div class="switch-class">
                        <sweet-switch v-model="form.noRepeat"/>
                    </div>
                </sweet-form-item>
                <sweet-form-item :label="$t('isales.import.filter.button.recentCall')">
                    <div class="flex flex-wrap gap-2 switch-class">
                        <sweet-switch v-model="form.recentCall"/>
                    </div>
                </sweet-form-item>
                <sweet-form-item :label="$t('isales.import.filter.title.callDay')" v-if="form.recentCall" required style="padding:20px 10px;background-color: #F4F6FA;">
                    <sweet-select v-model="form.callDay" :placeholder="$t('isales.taskinfo.placeholder.choose')">
                        <sweet-option v-for="item in CALL_DAY" :key="item.key" :label="item.value"
                                      :value="item.key"/>
                    </sweet-select>
                </sweet-form-item>
                <sweet-form-item :label="$t('isales.import.filter.button.callResult')">
                    <div class="flex flex-wrap gap-2 switch-class">
                        <sweet-switch v-model="form.callResult" @change="switchCallResult"/>
                    </div>
                </sweet-form-item>
                <div style="padding:20px 10px;background-color: #F4F6FA;" v-if="form.callResult" >
                    <sweet-form-item :label="$t('isales.taskmanagement.field.pickUpRuleBusiResult')" required>
                        <sweet-select v-model="form.busiResult" @change="selectBusiResult" :placeholder="$t('isales.taskinfo.placeholder.choose')">
                            <template #empty >
                              <div style="text-align: center;padding: 10px">
                                {{ $t('isales.statistics.business.noData') }}
                              </div>
                            </template>
                            <sweet-option v-for="item in this.busiResultItems" :key="item.code" :label="item.name"
                                          :value="item.code"/>
                        </sweet-select>
                    </sweet-form-item>
                    <sweet-form-item :label="$t('isales.taskresult.title.busisubstatus')" >
                        <sweet-select v-model="form.busiSubStatus" @change="selectBusiSubStatus" :placeholder="$t('isales.taskinfo.placeholder.choose')">
                            <template #empty >
                              <div style="text-align: center;padding: 10px">
                                {{ $t('isales.statistics.business.noData') }}
                              </div>
                            </template>
                            <sweet-option v-for="item in this.tempFilterResult.busiSubStatusItems" :key="item.code" :label="item.name"
                                          :value="item.code"/>
                        </sweet-select>
                    </sweet-form-item>
                </div>
                <sweet-form-item :label="$t('isales.import.filter.button.readyCall')">
                    <div class="flex flex-wrap gap-2 switch-class">
                        <sweet-switch v-model="form.readyCall"/>
                    </div>
                </sweet-form-item>
                <sweet-form-item :label="$t('isales.import.filter.button.calling')">
                    <div class="flex flex-wrap gap-2 switch-class">
                        <sweet-switch v-model="form.calling"/>
                    </div>
                </sweet-form-item>
            </sweet-form>
            <template #footer>
                <span class="dialog-footer">
                    <sweet-button @click="dialogVisible = false">{{ $t('isales.common.title.cancel') }}</sweet-button>
                    <sweet-button type="primary" @click="confirm">{{ $t('isales.common.title.confirm') }}</sweet-button>
                </span>
            </template>
        </sweet-dialog>
    </div>
</template>

<script>
    import {
        CALL_DAY
    } from '@/views/isales/views/taskManagement/enum';
    import {
        queryBusinessResult,
    } from '@/views/isales/api/importCallData';
    import {ElMessage} from "element-plus";
    export default {
        name: 'filterRule',
        props: {
            show: {
                type: Boolean,
                default: false
            },
            marketingMode : {
                type: Number,
                default: 1
            },
            filterRuleString:{
                type:String,
                default:''
            },
        },
        data() {
            return {
                labelPosition: window.isRTL ? 'right' : 'left',
                CALL_DAY,
                form: {
                    lineRepeat: false,
                    noRepeat: false,
                    recentCall: false,
                    callDay: '3',
                    callResult: false,
                    busiResult:'',
                    busiSubStatus:'',
                    readyCall:false,
                    calling:false
                },
                busiResultItems:[],
                tempFilterResult: {
                    busiResult: '',
                    busiSubStatus:'',
                    busiSubStatusItems:[]
                },
                filterRule:{},
            }
        },
        computed: {
            dialogVisible: {
                get() {
                    return this.show
                },
                set(val) {
                    this.$emit('update:show', val)
                }
            },
        },
        created() {
            this.inintBusiResult();
            if (this.filterRuleString) {
                this.filterRule = JSON.parse(this.filterRuleString);
                this.form.noRepeat = this.filterRule.noRepeat?true:false;
                this.form.recentCall = this.filterRule.recentCall?true:false;
                this.form.callResult = this.filterRule.callResult?true:false;
                this.form.readyCall = this.filterRule.readyCall?true:false;
                this.form.calling = this.filterRule.calling?true:false;
                this.form.callDay = this.filterRule.day;
                this.form.lineRepeat = this.filterRule.lineRepeat?true:false;
                if(this.form.callResult){
                    let selectFilterResult = this.filterRule.filterResult[0];
                    this.form.busiResult = selectFilterResult.busiResult
                    this.tempFilterResult.busiResult = selectFilterResult.busiResult;
                    for (let tempItem of this.busiResultItems || []) {
                        if (this.form.busiResult == tempItem["code"]) {
                            this.tempFilterResult.busiSubStatusItems = tempItem["subCodes"];
                            break;
                        }
                    }
                    if(selectFilterResult.busiSubStatusList && selectFilterResult.busiSubStatusList.length>0){
                        this.tempFilterResult.busiSubStatus = selectFilterResult.busiSubStatusList[0];
                        this.form.busiSubStatus = selectFilterResult.busiSubStatusList[0];
                    }
                }
            }
        },
        methods: {
            inintBusiResult(){
                console.log("inintBusiResult");
                queryBusinessResult().then(res => {
                    if (res && res.length > 0) {
                        this.busiResultItems = res;
                    }
                });
            },
            //选择业务结果
            selectBusiResult(){
                console.log("selectBusiResult");
                if (this.form.busiResult) {
                    this.tempFilterResult.busiResult = this.form.busiResult;
                    for (let tempData of this.busiResultItems || []) {
                        if (this.form.busiResult == tempData["code"]) {
                            this.tempFilterResult.busiSubStatusItems = tempData["subCodes"];
                            break;
                        }
                    }
                }
            },
            //选择子业务结果
            selectBusiSubStatus() {
                console.log("selectBusiSubStatus");
                if (this.form.busiSubStatus) {
                    this.tempFilterResult.busiSubStatus = this.form.busiSubStatus;
                }
            },
            switchCallResult(){
                console.log("switchCallResult");
                if(!this.form.callResult){
                    this.form.busiResult = '';
                    this.form.busiSubStatus = '';
                    this.tempFilterResult = {
                        busiResult: '',
                        busiSubStatus:'',
                        busiSubStatusItems:[]
                    };
                }
            },
            confirm(){
                if ( !this.form.noRepeat && !this.form.recentCall && !this.form.callResult && !this.form.readyCall && !this.form.calling && !this.form.lineRepeat) {
                    ElMessage({
                        type: 'error',
                        message: this.$t('isales.import.filter.message.selectRuleTips')
                    });
                    return;
                }
                let tempFilterResultList = [];
                if (this.form.callResult) {
                    if (!this.tempFilterResult || !this.tempFilterResult.busiResult) {
                        ElMessage({
                            type: 'error',
                            duration: 10000,
                            showClose: true,
                            offset: 56,
                            message: this.$t('isales.import.filter.message.busiResultIsNull')
                        });
                        return;
                    }
                    let tempBusiResult;
                    if (tempFilterResultList.length > 0) {
                        tempBusiResult = tempFilterResultList.find(item => item.busiResult == this.tempFilterResult.busiResult);
                    }
                    if (tempBusiResult) {
                        let subExist = tempBusiResult.busiSubStatusList.find(item => item == this.tempFilterResult.busiSubStatus);
                        if (subExist) {
                            $$(this).$UI.msgbox.error($UEE.i18n('isales.common.title.tipsInfo'), $UEE.i18n('isales.import.filter.message.busiSubStatusSame'));
                            return;
                        }
                        if (temp.busiSubStatus) {
                            tempBusiResult.busiSubStatusList.push(temp.busiSubStatus);
                        }
                    } else {
                        let newBusiResult = {"busiResult": this.tempFilterResult.busiResult};
                        if (this.tempFilterResult.busiSubStatus) {
                            let newBusiSubStatusList = [];
                            newBusiSubStatusList.push(this.tempFilterResult.busiSubStatus);
                            newBusiResult["busiSubStatusList"] = newBusiSubStatusList;
                        }
                        tempFilterResultList.push(newBusiResult);
                    }
                }
                let params = {
                    "noRepeat": this.form.noRepeat?"1":"",
                    "recentCall": this.form.recentCall?"1":"",
                    "callResult": this.form.callResult?"1":"",
                    "readyCall": this.form.readyCall?"1":"",
                    "calling": this.form.calling?"1":"",
                    "day": this.form.callDay,
                    "lineRepeat": this.form.lineRepeat?"1":"",
                    "filterResult":tempFilterResultList
                }
                this.$emit('filterRuleConfirm', params);
                this.dialogVisible = false;
            }
        }
    }
</script>

<style lang="less" scoped>
    .switch-class{
        margin-right: 20px
    }
    body.RTL{
        .switch-class{
            margin-left: 20px;
            margin-right: unset;
        }
    }
</style>