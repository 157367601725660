import request from '@/utils/request'
import odfsRequest from '@oifde/utils/odfsRequest'
import {useOifdeUserStore} from '../../stores/user'
import cookieUtils from "@/utils/cookie"

export function queryFlows(query) {
  const userStore = useOifdeUserStore()
  const data = {
    tenantId: cookieUtils.getCookie('tenant_space_id'),
    source: userStore.source
  }
  for (let prop in query) {
    if (typeof query[prop] === undefined) {
      continue
    }
    if (typeof query[prop] === 'string' && query[prop] === '') {
      continue
    }
    data[prop] = query[prop]
  }
  if (typeof data.pageNum === 'undefined') {
    data.pageNum = 1
  }
  if (typeof data.pageSize === 'undefined') {
    data.pageSize = 20
  }

  return odfsRequest({
    url: '/oifde/rest/queryflows',
    method: 'post',
    data: data
  })
}

export function updateFlow(flow) {
  const userStore = useOifdeUserStore()
  const data = {
    tenantId: cookieUtils.getCookie('tenant_space_id'),
    source: userStore.source
  }
  for (let prop in flow) {
    if (typeof flow[prop] === undefined) {
      continue
    }
    data[prop] = flow[prop]
  }

  return odfsRequest({
    url: '/oifde/rest/updateflow',
    method: 'post',
    data
  })
}

export function updateFlowStatus(flow) {
  const userStore = useOifdeUserStore()
  const data = {
    tenantId: cookieUtils.getCookie('tenant_space_id'),
    source: userStore.source
  }
  for (let prop in flow) {
    if (typeof flow[prop] === undefined) {
      continue
    }
    data[prop] = flow[prop]
  }

  return odfsRequest({
    url: '/oifde/rest/updateFlowStatus',
    method: 'post',
    data
  })
}

export function deleteFlow(flowIdList) {
  const data = {
    tenantId: cookieUtils.getCookie('tenant_space_id'),
    flowIdList: flowIdList
  }

  return odfsRequest({
    url: '/oifde/rest/deleteflow',
    method: 'post',
    data
  })
}

// 升级流程
export function copyFlow(flow) {
  const data = {
    flowId: flow.flowId,
    version: flow.version
  }

  return odfsRequest({
    url: '/oifde/rest/cloneflow',
    method: 'post',
    data
  })
}

// 复制流程
export function copyOneFlow(flow) {
  const data = {
    flowId: flow.oldFlowId,
    flowCode: flow.newFlowCode,
    flowName: flow.newFlowName
  }

  return odfsRequest({
    url: '/oifde/rest/copyflow',
    method: 'post',
    data
  })
}

// 查询统计信息
export function queryUsage() {
  const userStore = useOifdeUserStore()
  const data = {
    tenantId: cookieUtils.getCookie('tenant_space_id'),
    source: userStore.source
  }

  return odfsRequest({
    url: '/oifde/rest/queryusagestat',
    method: 'post',
    data
  })
}

// 执行统计任务
export function runUsageStatTask() {
  const userStore = useOifdeUserStore()
  const data = {
    tenantId: cookieUtils.getCookie('tenant_space_id'),
    source: userStore.source
  }

  return odfsRequest({
    url: '/oifde/rest/startusagestat',
    method: 'post',
    data
  })
}

// 执行统计任务
export function queryUnusedResource(resourceType, page, pageSize) {
  const userStore = useOifdeUserStore()
  const data = {
    tenantId: cookieUtils.getCookie('tenant_space_id'),
    source: userStore.source,
    resourceType: resourceType,
    pageNum: page,
    pageSize: pageSize
  }

  return odfsRequest({
    url: '/oifde/rest/queryunusedresource',
    method: 'post',
    data
  })
}

export function exportFlow(query) {
  const data = {
    tenantId: cookieUtils.getCookie('tenant_space_id'),
    source: 'OIFDE'
  }
  for (var prop in query) {
    if (typeof query[prop] === undefined) {
      continue
    }
    if (typeof query[prop] === 'string' && query[prop] === '') {
      continue
    }
    data[prop] = query[prop]
  }
  return odfsRequest({
    url: '/oifde/rest/exportflow',
    method: 'post',
    timeout: 1000 * 60 * 30,
    data,
    responseType: 'blob'
  })
}

// 生成uuid
export function uuid() {
  let uuid = ''
  let len = 36
  let buffer = new Uint8Array(len / 2)
  crypto.getRandomValues(buffer)
  uuid += Array.from(buffer, dec2hex).join('')
  return uuid
}

export function dec2hex(dec) {
  return ('0' + dec.toString(16)).substr(-2)
}

/**
 * 查询画布数据
 * @param data
 * @returns {*}
 */
export function queryflowcanvas(data) {
  return odfsRequest({
    url: '/oifde/rest/queryflowcanvas',
    method: 'post',
    data
  })
}

/**
 * 保存画布
 * @param data
 * @returns {*}
 */
export function saveDiagramByJson(data) {
  return request({
    url: '/oifde/rest/saveDiagramByJson',
    method: 'post',
    data
  })
}

/**
 * oifde查询模板
 * @param data
 * @returns {*}
 */
export function queryflowtemplate(data) {
  return odfsRequest({
    url: '/oifde/rest/queryflowtemplate',
    method: 'post',
    data
  })
}

// 根据流程名称精确查询流程数量
export function queryFlowsCountByFlowName(param) {
  let data = {
    tenantId: cookieUtils.getCookie('tenant_space_id'),
    source: 'OIFDE'
  }
  Object.assign(data, param)
  return odfsRequest({
    url: '/oifde/rest/queryflowscountbyflowname',
    method: 'post',
    data
  })
}

/**
 * 根据租户id和source查询流程
 * @param param param
 * @returns {*}
 */
export function queryFlowsByTenantIdAndSource(param) {
  let data = {
    tenantId: cookieUtils.getCookie('tenant_space_id'),
    source: param.source
  }
  return odfsRequest({
    url: '/oifde/rest/queryFlowsByTenantIdAndSource',
    method: 'post',
    data
  })
}

export function queryIvrNodes(param) {
  let data = {
    tenantId: cookieUtils.getCookie('tenant_space_id'),
    flowId: param.flowId
  }
  return odfsRequest({
    url: '/oifde/rest/queryIvrNodes',
    method: 'post',
    data
  })
}

export function saveFlowCanvasDraft(param) {
  let data = {
    tenantId: cookieUtils.getCookie('tenant_space_id'),
    flowId: param.flowId,
    graphjsontemp: param.graphjsontemp
  }
  return odfsRequest({
    url: '/oifde/rest/saveFlowCanvasDraft',
    method: 'post',
    data
  })
}

export function saveComplexcellCanvasDraft(param) {
  let data = {
    tenantId: cookieUtils.getCookie('tenant_space_id'),
    complexCellCode: param.complexCellCode,
    graphjsontemp: param.graphjsontemp
  }
  return odfsRequest({
    url: '/oifde/rest/saveComplexcellCanvasDraft',
    method: 'post',
    data
  })
}

export function deleteComplexcellCanvasDraft(param) {
  let data = {
    tenantId: cookieUtils.getCookie('tenant_space_id'),
    complexCellCode: param.complexCellCode,
  }
  return odfsRequest({
    url: '/oifde/rest/deleteComplexcellCanvasDraft',
    method: 'post',
    data
  })
}

export function deleteFlowCanvasDraft(param) {
  let data = {
    tenantId: cookieUtils.getCookie('tenant_space_id'),
    flowId: param.flowId,
  }
  return odfsRequest({
    url: '/oifde/rest/deleteFlowCanvasDraft',
    method: 'post',
    data
  })
}

// 查询特性信息
export const queryTenantFeatureInfoForUser = data => {
  return odfsRequest({
    url: '/service-cloud/rest/cc-management/v1/tenantInfo/queryTenantFeatureInfoForUser',
    method: 'post',
    data
  })
}

// 查询流程列表
export const queryFlowList = data => {
  return odfsRequest({
    url: '/oifde/rest/queryFlowList',
    method: 'post',
    data
  })
}

// 查询流程版本列表
export const queryFlowDetail = data => {
  return odfsRequest({
    url: '/oifde/rest/queryFlowDetail',
    method: 'get',
    data
  })
}

// 修改流程信息
export const batchUpdateFlowName = data => {
  return odfsRequest({
    url: '/oifde/rest/batchUpdateFlowName',
    method: 'post',
    data
  })
}

/**
 * 导出流程列表
 * @param data
 * @returns {*}
 */
export function flowExportByCondition(data) {
  return request({
    url: '/oifde/rest/flowExportByCondition',
    method: 'post',
    data
  })
}

// 查询引用资源的流程
export const queryFlowByResource = data => {
  return odfsRequest({
    url: '/oifde/rest/queryFlowByResource',
    method: 'post',
    data
  })
}