<template>
  <sweet-dialog
    append-to-body
    destroy-on-close
    v-model="dialogVisible"
    custom-class="dy-email-dialog"
    :title="$t('ccmessaging.chat.multimedia.selectImage')"
    width="616px"
    @open="handleOpen"
    @closed="handleClosed"
    :close-on-click-modal="false"
  >
    <div class="select-image">
      <sweet-form :model="selectImageForm" @submit.prevent>
        <sweet-row>
          <sweet-col>
            <sweet-form-item>
              <sweet-input
                v-model="selectImageForm.name"
                clearable
                @keydown.enter="handleSearch"
                :placeholder="$t('ccmessaging.chat.richText.nameInput')"
              >
                <template #prefix>
                  <sweet-icon
                    class="input-icon"
                    @click="handleSearch"
                    :title="$t('chat.session.label.search')"
                  >
                    <search />
                  </sweet-icon>
                </template>
              </sweet-input>
            </sweet-form-item>
          </sweet-col>
        </sweet-row>
      </sweet-form>
      <aicc-table
        ref="imgTableRef"
        :tableData="imgTableInfo.tableData"
        :tableColumns="imgTableInfo.tableColumns"
        :tableTools="{ showPagination: true, columnConfig: false }"
        :paginationConfig="imgTableInfo.paginationConfig"
        @handlePagination="handleQuery"
        @radio-change="handleRadio"
      ></aicc-table>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleCancel">{{ $t('message.template.message.cancel') }}</el-button>
        <el-button type="primary" @click="handleSubmit">{{
          $t('ccmessaging.chat.multimedia.finish')
        }}</el-button>
      </span>
    </template>
  </sweet-dialog>
</template>

<script setup>
import { computed, getCurrentInstance, reactive, ref, toRefs } from 'vue'
import { queryImages } from '@ccme/api/online-chat-workbench/index.js'
import appConfig from '@/config'

const emit = defineEmits(['update:selectImageDialog', 'getSelectedImage'])
const {
  appContext: {
    config: { globalProperties }
  }
} = getCurrentInstance()

const props = defineProps({
  selectImageDialog: {
    default: false
  }
})
const { selectImageDialog } = toRefs(props)
const dialogVisible = computed({
  get() {
    return selectImageDialog.value
  },
  set(val) {
    emit('update:selectImageDialog', val)
  }
})

const selectImageForm = reactive({
  name: ''
})

const imgTableRef = ref(null)
const imgTableInfo = reactive({
  tableData: [],
  tableColumns: [
    {
      type: 'radio',
      radioKey: 'id',
      width: '60'
    },
    {
      label: globalProperties.$t('ccmessaging.chat.multimedia.name'),
      prop: 'name'
    },
    {
      label: globalProperties.$t('ccmessaging.chat.multimedia.description'),
      prop: 'description'
    }
  ],
  orgTableColumns: [
    {
      type: 'radio',
      radioKey: 'id',
      width: '60'
    },
    {
      label: globalProperties.$t('ccmessaging.chat.multimedia.description'),
      prop: 'description'
    },
    {
      label: globalProperties.$t('ccmessaging.chat.multimedia.name'),
      prop: 'name'
    }
  ],
  paginationConfig: {
    ...appConfig.pagination.dialog,
    layout: appConfig.pagination.LAYOUT.ADVANCED
  }
})

const handleSearch = () => {
  imgTableRef.value.handleInitChange()
}

const handleQuery = (pagination) => {
  const { limit, offset } = pagination
  queryImages({
    limit,
    offset,
    name: selectImageForm.name.replace(/\\%/g, '')
  }).then((result = {}) => {
    const { imageList, imageCount } = result
    imgTableInfo.tableData = imageList
    imgTableInfo.paginationConfig.total = imageCount
  })
}

const handleOpen = () => {
  if (window.isRTL) {
    imgTableInfo.tableColumns = JSON.parse(JSON.stringify(imgTableInfo.orgTableColumns))
  }
  handleSearch()
}

let rowInfo = {}
const handleRadio = ({ row }) => {
  rowInfo = row
}

const handleCancel = () => {
  dialogVisible.value = false
}

const handleSubmit = () => {
  if (Object.keys(rowInfo).length) {
    emit('getSelectedImage', rowInfo)
    dialogVisible.value = false
  }
}

const handleClosed = () => {
  selectImageForm.name = ''
  imgTableInfo.tableData = []
  imgTableInfo.paginationConfig.total = 0
  rowInfo = {}
}
</script>

<style lang="less" scoped>
.select-image {
  .input-icon {
    cursor: pointer;
    transition: all 0.2s;

    &:active {
      transform: scale(0.9);
    }
  }
}
</style>
