<template>
  <div :id="id" :style="{height:height}" style="width: 100%"></div>
</template>

<script>
import * as echarts from 'echarts'
import { useEventStore } from '@/views/base/stores/index'

export default {
  name: 'LineChart',
  setup() {
    const eventStore = useEventStore()
    return { eventStore }
  },
  props: {
    id: {
      type: String,
      required: true
    },
    option: {
      type: Object
    },
    height: {
      type: String,
      default: '250px'
    }
  },
  data() {
    return {
    }
  },
  computed: {
    isHideMenuDetail() {
      return this.eventStore.isHideMenuDetail
    }
  },
  watch: {
    isHideMenuDetail() {
      this.$nextTick(() => {
        this.chartResize()
      })
    }
  },
  activated() {
    this.$nextTick(() => {
      this.chartResize()
    })
  },
  methods: {
    init() {
      // 基于准备好的dom，初始化echarts实例
      this.myChart = echarts.init(document.getElementById(this.id))
      // 绘制图表
      this.myChart.setOption(this.option)
    },
    chartResize(){
      if(this.myChart) {
         this.myChart.resize()
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.init()
    })
    window.addEventListener('resize',this.chartResize)
  },
  beforeDestroy() {
    this.chart.dispose()
    this.chart = null
    window.removeEventListener('resize',this.chartResize)
  }
}
</script>

<style lang="less" scoped>
  #trendsChart {
    height: 250px;
  }
</style>
