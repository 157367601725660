<template>
  <aicc-full-screen ref="aiccFullScreenRef" class="aicc-background-white contact-record-query-layout">
    <template #header>
      <div v-if="!isFromWorkbench">
        <aicc-advanced-search :templateMetaData="templateMetaData" @handleSearch="handleRefresh" @handleReset="handleReset" @timeRangeChange="timeRangeChange" @searchCallNoChange="handleRefresh"
                              @orgTreeSelectChange="handleRefresh"  @handleRefresh="handleRefresh"  @handleExportData="handleExportData" @handleTransList="handleExportView" header-cell-class-name="table-header-cell" ref="advancedSearchRef">
          <template #otherPart>
            <aicc-audio v-if="isShowPlayer" class="contact-record-search--aicc-audio" :src="playerSrc" ref="aiccAudio" :can-down-load="false"
                        :can-close="true" autoplay="autoplay" @closeAudioHandle="closeAudioHandle" @audioPlayingHandle="audioPlayingHandle" />
          </template>
        </aicc-advanced-search>
      </div>
    </template>
    <template #main>
      <aicc-table id="aicc-ccmanegement-contract--table" class="aicc-table" @row-click="rowClick"
                  :row-key="(row: any) => row.callSerialno" ref="contractRecordTable" :tableData="tableData" :cell-class-name="getCellStyleClass"
                  :tableColumns="tableColumns" :tableTools="{ showPagination: false, columnConfig: false}" >
        <template #callSerialno="scope">
          <sweet-link :underline="false" type="primary" @click="jumpToDetail(scope.row)">
            {{ scope.row.callSerialno }}
          </sweet-link>
        </template>
        <template #callNo="scope">
          <agent-name-show-pop :is-show-pop="scope.row.callNoIsWorkNo" :work-number="scope.row.callNo"/>
        </template>
        <template #caller="scope">
          <agent-name-show-pop :is-show-pop="scope.row.callerIsWorkNo" :work-number="scope.row.caller"/>
        </template>
        <template #called="scope">
          <agent-name-show-pop :is-show-pop="scope.row.calledIsWorkNo" :work-number="scope.row.called"/>
        </template>
        <template #acceptNo="scope">
          <agent-name-show-pop :is-show-pop="scope.row.acceptNoIsWorkNo" :work-number="scope.row.acceptNo"/>
        </template>
        <template  #orgName="scope">
          <orgFullName-show-pop  :is-show-org-full-name = "true" :orgName="scope.row.orgName" :orgFullName="scope.row.orgFullName"/>
        </template>
        <template #mediaAbility="scope">
          <span>
            {{ scope.row.mediaAbilityStr || "-" }}
            <span v-if="scope.row.channelType">
              -{{ scope.row.channelTypeStr }}
            </span>
          </span>
        </template>
        <template #staffHangup="scope">
          <span>
            {{ scope.row.staffHangupStr || "-" }}
          </span>
        </template>
        <template #calltype="scope">
          <span>
            {{ scope.row.calltypeStr || "-" }}
          </span>
        </template>
        <template #recordingfiles="scope">
          <div class="cell-style__recordingfiles">
            <!--没有录音-->
            <div v-if="!scope.row.recordId && !scope.row.isExist">
              <sweet-button text type="primary" disabled>{{ i18n("ccm.agent.contact.play") }}</sweet-button>
              <sweet-button text type="primary" disabled>{{ i18n("ccm.agent.contact.download") }}</sweet-button>
            </div>
            <div v-else>
              <sweet-button v-if="!scope.row.audioPlaying" text type="primary"
                            :disabled="!play || scope.row.isPlayVideoTag"
                            @click="handleMediaPlay(scope.row)">
                {{ i18n("ccm.agent.contact.play") }}
              </sweet-button>
              <sweet-button v-else text type="primary"
                            :disabled="!play || scope.row.isPlayVideoTag"
                            @click="handleMediaPlay(scope.row)">
                {{ i18n("ccm.transtask.label.pause") }}
              </sweet-button>
              <sweet-button text type="primary" :disabled="scope.row.recordInfoDisable || !down"
                            @click="downloadRecord(scope.row)">
                {{ i18n("ccm.agent.contact.download") }}
              </sweet-button>
            </div>
          </div>
        </template>
      </aicc-table>
    </template>
    <template #footer>
      <sweet-form :inline="true">
        <sweet-form-item>
          <sweet-pagination :total="total" :page-sizes="[10, 20, 50, 100]" :current-page="currPage"
                            layout="sizes, total, prev, pager, next, jumper" :page-size="limit"
                            @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </sweet-form-item>
        <sweet-form-item>
          <span class="footer--tips" v-if="isShowTips">
            *{{ safeHtmlEncode(i18n("ccm.speciallist.msg.maxCountQuery", maxCountQuery)) }}
          </span>
        </sweet-form-item>
      </sweet-form>

    </template>
  </aicc-full-screen>
  <download-file id="contactRecordDownloadFilePop" ref="downloadFileDialog" :title="downloadFileDialogTitle" :required-pwd="showZipPwd"
                 :data-info="downloadFileDialogDataInfo" :data-type="downloadFileDialogDataType" useExtendedValidator :vrc-record-id="vrcRecordId"
                 @get-download-result="downloadFileResult"/>
  <contact-video-pop ref="contactVideoPopRef" v-if="isShowContactVideoPop"
                     :title="videoPlayTitle" :record-id="videoPlayRecordId" :video-type="videoPlayType" :vrc-record-id="vrcRecordId"
                     @close="() => isShowContactVideoPop = false"/>
  <contact-file-pop ref="ContactFilePopRef" v-if="isShowContactFilePop" :is-show="isShowContactFilePop" :vrc-record-id="vrcRecordId"
                    :record-ids="contactFilePopRecordIds" :video-disable="contactFilePopVideoDisable"
                    :title="i18n('ccm.agent.contact.recordInfo')" @play-video="playFile"
                    @download="downloadRecord" @close="() => isShowContactFilePop = false"/>
  <contact-export-create id="contactExportCreate" v-if="isShowContactExportCreate" ref="contactExportCreateDialogRef" @close="() => isShowContactExportCreate = false"
                         :title="contactExportCreateDialogTitle" :is-show="isShowContactExportCreate" :searchObject="searchModel" @change="changeExportCreate"
                         :show-zip-pwd="showZipPwd"/>
  <contact-export-view id="contactExportView" v-if="isShowContactExportView" ref="contactExportViewRef" @close="() => isShowContactExportView = false"
                       :title="contactExportViewDialogTitle" :is-show="isShowContactExportView" @change="changeExportView"/>
</template>

<script setup lang="ts">
import {computed, getCurrentInstance, nextTick, onBeforeMount, reactive, ref, watch} from "vue";
import { useRoute } from 'vue-router'
import {
  checkContractExportAuth,
  getAllSkillList,
  initContactItemConfig,
  queryAllContactCount,
  queryAllContactRecord,
  queryCallReasonInfo,
  queryContactExtNumRecord,
  queryDefaultSatisfactionConfig, queryExportTenantSysParam
} from "@/views/ccmanagement/ccma-api/index.js";
import cookieUtils from "@/utils/cookie.js";
import timeUtils from "@/utils/timeUtils";
import validator from '@/utils/validate.js'
import { ElMessageBox } from "element-plus";
import AiccElMessage from '@/utils/el-message';
import AiccFullScreen from "@/components/aicc-full-screen/index.vue";
import AiccAudio from "@/components/aicc-audio/index.vue";
import { querySocialMedia } from "@/views/ccmanagement/externals-api/ccmessage.js";
import { safeHtmlEncode } from "@/views/ccmanagement/common/common";
import dayjs from "dayjs";
import DownloadFile from "@/views/ccmanagement/pages/download-file/download-file.vue";
import {getUserAccess} from "@/utils/organization";
import {
  channelTypeMap,
  mediaAbilityMap,
  staffHangupMap,
  callTypeMap,
  getContactFileRecordsColumnWidth, getContactCallSerialnoColumnWidth, getContactDateTimeColumnWidth, SystemParamEnum
} from "@/views/ccmanagement/common/constants";
import ContactVideoPop from "@/views/ccmanagement/pages/contact-video-pop/contact-video-pop.vue";
import ContactFilePop from "@/views/ccmanagement/pages/contact-file-pop/contact-file-pop.vue";
import AgentNameShowPop from "@/views/ccmanagement/pages/agent-name-show-pop/agent-name-show-pop.vue";
import { useContactInfoPageStore } from "@/views/ccmanagement/stores/contact-detail-store";
import ContactExportCreate from "@/views/ccmanagement/pages/contact-export-create/contact-export-create.vue";
import ContactExportView from "@/views/ccmanagement/pages/contact-export-view/contact-export-view.vue";
import { DIALOG_STYLE } from "@/views/ccmanagement/common/styleConfig";
import orgFullNameShowPop from "@/views/ccmanagement/pages/contact-record/orgFullName-show-pop.vue";
/**
 * UX改版后的查询定义
 */
declare interface SearchModel {
  locale: string, // 国际化
  acceptNo?: string, // 客户号码
  beginTime?: number | string| Date| dayjs.Dayjs, //查询起始时间，非工作台跳转时必填
  endTime?: number | string| Date| dayjs.Dayjs, // 查询截止时间，非工作台跳转时必填
  calltype?: number | string, // 呼叫类型
  mediaAbility?: number | string, // 媒体类型
  skillId?: string | number, // 技能队列,
  evaluation?: string | number, // 满意度评价，
  existTalkReason?: string | number | boolean, // 是否存在来电原因
  countName?: string | undefined | null, // 帐号名，对于普通坐席，此字段必填
  workNo?: string | number, // 座席工号
  caller?: string | number, // 主叫号码
  called?: string | number, // 被叫号码
  chatUserName?: string, // 客户名称
  offset: number | string, // 偏移量
  limit: number | string, // 每页条数
  orderby?: string // 排序字段
  callid?: String|Number, // 呼叫id，用于接受工作台跳转传参
  callNo?: string | number, // 客户号码，UX改版新增参数
  orgIdList?: [],
  noOrgFlag?: string,
  orgFlag?: string
}
// 国际化封装
const { appContext: { config: { globalProperties } } } = getCurrentInstance() as any;
const i18n = (param: string, ...args: Array<any>): string => {
  return globalProperties.$t(param, args);
};
// 全屏组件ref
const aiccFullScreenRef = ref();

// 定义入参，兼容工作台跳转值接触记录详情
const props = defineProps({
  callid: [String,Number],
  originMenuId: {
    required: true,
    type: [String,Number],
  },
  moduleType: String,
  isAgentOrigin: {
    type: [Boolean, String],
    required: false,
    default: false,
  },
  /** 下沉页路由名称 */
  sinkRouteName: {
    type: String,
    required: true,
  }
});
const route = useRoute();
const routeName = computed(() => route.name)
const callid = computed(() => props.callid);
const originMenuId = computed(() => props.originMenuId);
const moduleType = computed(() => props.moduleType);
const isAgentOrigin = computed(() => props.isAgentOrigin);
const VDN_NOT_ENABLE: string = "9"; // vdn未启用错误码
const today: dayjs.Dayjs = dayjs();
const defaultDateRange: Array<dayjs.Dayjs> = [today.startOf('date'), today.endOf('date')];
const isFromWorkbench = ref<Boolean>(); // 来源是否为工作台
const advancedSearchRef = ref();
const contractRecordTable = ref();
// 表头信息
const tableColumns = ref<Array<any>>();
const tableData = ref<Array<any>>([]); // 表数据
const skillInfoList = ref<Array<any>>(); // 技能队列信息列表
const satisfactionLevel = ref<Array<any>>(); // 满意度评价列表
const noMoreThanDays = ref<string>();
const newSocialMedias = ref<Array<any>>();
const maxCountQuery = ref<number>(10000); // 最大允许的查询数量,默认预置为1w，后续查询时会更新
const contactItem = ref<Array<string>>();
const auth = ref<Boolean>(false); // 是否是租间管理员
const isShowUserName = ref<Boolean>(false); // 是否展示业务帐号输入框
const play = ref<Boolean>(false); // 播放是否可用
const down = ref<Boolean>(false); // 下载是否可用
const isQC = ref<Boolean>(false); // 是否质检员
const workNo = ref<String>(); // 工号
const isCheckedAllOfThePage = ref<Array<any>>([]);
const isShowTips = ref<Boolean>(false); // 是否展示footer的tips
const hasExportAuth = ref<Boolean>(false); // 是否有导出权限
const currPage = ref<number>(1); // 当前页
const limit = ref<number>(20); // 每页条数
const offset = computed(() => (currPage.value - 1) * limit.value); // 偏移量
const total = ref<number>(0); // 总条数
const isShowRedDot = ref<boolean>(false); // 是否展示红点
const searchModel = reactive<SearchModel>({
  locale: cookieUtils.getCookie("u-locale"),
  limit: limit.value,
  offset: offset.value
});
const preDays = ref<number>(7);

const aiccAudio = ref();
const isShowPlayer = ref<boolean>(false); // 是否展示播放器
const playerSrc = ref<string>(""); // 播放资源地址
const playingId = ref<string>(); // 播放id
const isPlaying = ref<boolean>(false); // 录音播放状态
const downloadFileDialog = ref(); // 录制内容下载弹窗
const downloadFileDialogTitle = ref<any>(); // 录制内容下载弹窗的标题
const downloadFileDialogDataType = ref<any>(); // 录制内容下载弹窗的 dataType参数
const downloadFileDialogDataInfo = ref<Object>(); // 录制内容下载弹窗的 dataInfo参数
const videoPlayRecordId = ref<String>(); // 视频
const vrcRecordId = ref<String>(); // 视频的记录id// 的记录id
const videoPlayType = ref<String>("video/mp4"); // 视频的播放类型
const videoPlayTitle = ref<String>(); // 视频弹窗标题
const contactVideoPopRef = ref();
const isShowContactVideoPop = ref<Boolean>(false);
const isShowContactFilePop = ref<Boolean>(false);
const contactFilePopRecordIds = ref<String>("");
const contactFilePopVideoDisable = ref<Boolean>(false);
const isShowContactExportCreate = ref<Boolean>(false);
const isShowContactExportView = ref<Boolean>(false);
const contactExportCreateDialogTitle = ref<any>(); // 导出接触记录弹窗的标题
const contactExportViewDialogTitle = ref<any>(); // 导出接触记录弹窗的标题
const orgTreeList = ref<Array<any>>([]) // 组织机构树形数据
const showZipPwd = ref<boolean>(true) // 导出是否必填压缩密码

/**
 * 校验特殊字符,不匹配@
 * @param rule 校验规则
 * @param value 需要校验的值
 * @param callback 回调执行方法
 */
const validateSpecialChar = (rule: any, value: any, callback: any) => {
  const myReg = /^[a-zA-Z0-9_-ÁáÉéxÍÍíÓóÚúÂâÊêÔôÀàÃãÕõÜüÇç@\"\s!#$%&'*+-/=?^_`{|}~]+$/
  if (value && !myReg.test(value)) {
    callback(new Error(i18n("agentconsole.querycustominfo.msg.specialStrValidateFailed")));
    return;
  }
  callback();
}

const shortcuts = ref<Array<any>>([
  { label: i18n("ccm.agent.label.oneday"), value: defaultDateRange, isDefault: true, },
  { label: i18n('ccm.agent.contact.customizes'), value: 'custom', }
]);

/**
 * 初始化下拉选项
 * @param {Number} preDay 最大可查询天数，默认为7天
 */
const initShortcuts = (preDay: Number = 7) => {
  const custom = shortcuts.value.pop();
  for (const [key, value] of preDayMap) {
    if (key > preDay) {
      break;
    }
    // 默认已设置最近一天的可选项，故此处需要跳过最近1天
    if (key > 1) {
      shortcuts.value.push(value);
    }
  }
  // 自定义数据
  shortcuts.value.push(custom)
};

const buildShortcutsItems = (preDay: number): Array<dayjs.Dayjs> => {
  const endDate = today.endOf("date");
  const startDate = today.startOf("date").subtract(preDay - 1, "day");
  return [startDate, endDate];
};

const preDayMap: ReadonlyMap<number, Object> = new Map<number, Object>([
  [1, { label: i18n("ccm.agent.label.oneday"), value: defaultDateRange, isDefault: true }],
  [3, { label: i18n("ccm.agent.label.threedays"), value: buildShortcutsItems(3) }],
  [7, { label: i18n("ccm.agent.label.sevendays"), value: buildShortcutsItems(7) }],
  [31, { label: i18n("ccm.agent.label.thirtyonedays"), value: buildShortcutsItems(31) }],
  [90, { label: i18n("ccm.agent.label.ninetydays"), value: buildShortcutsItems(90) }]
]);

/**
 * 高级搜索，前缀内容列表
 */
const searchPrefixList = [
  {
    searchType: "select",
    modelValue: "searchCallType",
    placeholderValue: i18n("ccm.agent.contact.calltype"), // 呼叫类型
    selectOptions: [
      { label: i18n("ccm.agent.contact.callout"), value: "1" },
      { label: i18n("ccm.agent.contact.callin"), value: "0" },
      { label: i18n("ccm.agent.contact.predictiveCall"), value: "2" },
      { label: i18n("ccm.agent.contact.preCallout"), value: "3" },
      { label: i18n("ccm.agent.contact.preemptionCallout"), value: "4" },
      { label: i18n("ccm.agent.contact.itacall"), value: "5" },
      { label: i18n("ccm.agent.contact.callinandout"), value: "10" },
      { label: i18n("ccm.agent.contact.assistant"), value: "11" },
      { label: i18n("ccm.agent.contact.clicktocall"), value: "12" }
    ]
  },
  {
    searchType: "select",
    modelValue: "searchMediaType",
    placeholderValue: i18n("ccm.agent.contact.mediaType"), // 媒体类型
    selectOptions: [
      { label: i18n("ccm.agent.contact.Voice"), value: "1" },
      { label: i18n("ccm.agent.contact.vidio"), value: "3" },
      { label: i18n("ccm.agent.contact.multimedia"), value: "5" }
    ]
  },
  {
    searchType: "select",
    modelValue: "skillId",
    placeholderValue: i18n("ccm.agent.label.skills"),
    selectOptions: skillInfoList,
    filterable: true,
  },
  {
    searchType: "select",
    modelValue: "evaluation",
    placeholderValue: i18n("ccm.agent.contact.satiscomment"),
    selectOptions: satisfactionLevel
  },
  {
    searchType: "select",
    modelValue: "existTalkReason",
    placeholderValue: i18n("ccm.agent.contact.isExistTalkReason"),
    selectOptions: [
      { label: i18n("ccm.common.label.yes"), value: "Y" },
      { label: i18n("ccm.common.label.no"), value: "N" }
    ]
  },
];

/**
 * 高级搜索，后缀内容列表
 */
const searchSuffixList = [
  {
    searchType: 'input',
    modelValue: 'searchCaller',
    placeholderValue: i18n('ccm.agent.contact.caller'),
    rules: [
      {validator: validateSpecialChar, trigger: 'blur'}
    ]
  },
  {
    searchType: 'input',
    modelValue: 'searchCalled',
    placeholderValue: i18n('ccm.agent.contact.called'),
    rules: [
      {validator: validateSpecialChar, trigger: 'blur'}
    ]
  },
  {
    searchType: "input",
    modelValue: "searchAcceptNo",
    placeholderValue: i18n('ccm.agent.contact.acceptno'),
    rules: [
      {validator: validateSpecialChar, trigger: 'blur'}
    ]
  },
  {
    searchType: 'input',
    modelValue: 'chatUserName',
    placeholderValue: i18n('ccm.agent.contact.customername'),
  }
];

/**
 * 高级搜索 —— 无导出权限下图标展示列表
 */
const iconButtonWithoutList = reactive<Array<any>>([
  {
    buttonType: "refresh",
    eventName: "handleRefresh",
  },
])

/**
 * 高级搜索 —— 有导出权限下图标展示列表
 */
const iconButtonWithExportList = reactive<Array<any>>([
  {
    buttonType: "refresh",
    eventName: "handleRefresh"
  },
  {
    buttonType: "export",
    eventName: "handleExportData",
    buttonTitle: i18n('cms.report.label.export')
  },
  {
    buttonType: "transList",
    eventName: "handleTransList",
    buttonTitle: i18n('ccm.custdata.operlog.viewExportTask'),
    dot: isShowRedDot
  }
])

// 高级搜索配置
const templateMetaData = reactive({
  // 基础搜索配置
  basicConfig: {
    // 基础搜索
    basicSearch: [
      {
        searchType: 'timeSelect',
        modelValue: 'timeRange',
        placeholderValue: i18n('aicc.select'),
        clearable: false,
        modelDefault: { label: i18n("ccm.agent.label.oneday"), value: defaultDateRange, isDefault: true },
        datePickerType: "datetimerange",
        changeEvent: 'timeRangeChange',
        selectOptions: shortcuts,
        rules: [
          {required: true, message: i18n('SM.LOGIN.TIPS.REQUIRED'), trigger: ['blur']}
        ],
        colConfig: { lg: 7, xl: 6 }
      },
      {
        searchType: "primary",
        modelValue: 'searchCallNo',
        clearEvent: 'searchCallNoChange',
        placeholderValue: i18n('ccm.contact.placeholder.searchCustomerNo'),
        rules: [
          {validator: validateSpecialChar, trigger: 'blur'},
        ],
        colConfig: {
          lg: 5,xl: 4
        },
      },
      {
        isShow: false,
        searchType: 'orgTreeSelect', // 组织选择树
        modelValue: 'orgIds',
        treeOptions: orgTreeList,
        placeholderValue: i18n("SM.ORGSTAFF.LABEL.ORGANIZATION"),
        multiple: true
      }
    ],
    iconButton: [...iconButtonWithoutList],
    iconAreaColConfig: {
      sm: 8,
      lg: 5,
      xl: 4
    },
  },
  advancedSearch: {
    outwardMargin: '20px',
    searchList: [
      ...searchPrefixList,
      ...searchSuffixList,
    ]
  }
});
const queryOrgInfo = (): void => {
  getUserAccess().then((data) => {
    orgTreeList.value = data
  })
};

const queryUsualChannelConfig = (): void => {
  querySocialMedia()
    .then((data: any) => {
      newSocialMedias.value = data?.filter((e: any) => e.accessMode == 1);
    });
};

/**
 * 校验导出权限
 */
const checkExportAuth = (): void => {
  checkContractExportAuth().then((result: Boolean) => {
    hasExportAuth.value = result;
    if (!result) {
      return;
    }
    templateMetaData.basicConfig.iconButton = [...iconButtonWithExportList];
  })
};

/**
 * 查询满意度级别
 */
const querySatisfactionLevel = (): void => {
  if (!satisfactionLevel.value) {
    satisfactionLevel.value = [];
  }
  queryDefaultSatisfactionConfig().then((result: Array<any>) => {
    if (result) {
      for (let i = 0; i < result.length; ++i) {
        satisfactionLevel.value!.push({ label: result[i].value, value: result[i].index });
      }
    }
    satisfactionLevel.value!.push({ label: i18n("ccm.satisfactionservice.noevaluation"), value: "-1" });
    satisfactionLevel.value!.push({ label: i18n("ccm.satisfactionservice.evaluationerror"), value: "-2" });
  });
};

/**
 * 查询技能队列信息
 */
const querySkillInfoList = (): void => {
  skillInfoList.value = [];
  getAllSkillList({}).then((data: Array<any>) => {
    for (const skillInfo of data) {
      skillInfoList.value?.push({ label: skillInfo.name, value: skillInfo.skillId });
    }
  });
};

/**
 * 初始化接触记录页面
 */
const initCCContact = (): void => {
  const param: SearchModel = {
    locale: cookieUtils.getCookie("u-locale"),
    limit: limit.value,
    offset: 0
  };
  if (isFromWorkbench.value) {
    param.callid = callid.value;
    searchModel.callid = callid.value;
  } else {
    param.beginTime = defaultDateRange[0];
    param.endTime = defaultDateRange[1];
    searchModel.beginTime = defaultDateRange[0].toISOString();
    searchModel.endTime = defaultDateRange[1].toISOString();
  }
  queryAllContactRecord(param).then((resp: any) => {
    if (resp["returnCode"] == VDN_NOT_ENABLE) {
      AiccElMessage({
        showClose: true,
        message: i18n("ccm.agent.operation.vdnNotEnabled"),
        type: "success"
      });
      return;
    }
    preDays.value = resp.paraday ? Number.parseInt(resp.paraday) : 7;
    initShortcuts(preDays.value);
    switch (preDays.value) {
      case 90:
        noMoreThanDays.value = i18n("ccm.agent.operation.noMoreThan90Days");
        break;
      case 31:
        noMoreThanDays.value = i18n("ccm.agent.operation.noMoreThan31Days");
        break;
      case 3:
        noMoreThanDays.value = i18n("ccm.agent.operation.noMoreThan3Days");
        break;
      case 1:
        noMoreThanDays.value = i18n("ccm.agent.operation.noMoreThan1Days");
        break;
      default:
        noMoreThanDays.value = i18n("ccm.agent.operation.noMoreThan7Days");
        break;
    }
    if (!resp["column"]) {
      initContactBasicDataConfig();
      return;
    }
    // 组装列数据
    combineTableColumn(resp["column"], resp["contactItem"]);
    if (resp["maxCountQuery"]) {
      maxCountQuery.value = resp["maxCountQuery"];
    }
    auth.value = resp["tenantManager"];
    // 处理"业务帐号"输入框展示结果
    handleShowUserNameInput();
    play.value = resp.playAuth;
    down.value = resp.downAuth;
    contactItem.value = resp["contactItem"]
    // 无表单数据，赋默认值后，直接返回
    if (!resp["contactRecordList"]) {
      tableData.value = [];
      total.value = 0;
      isShowTips.value = false;
      return;
    }
    isQC.value = resp["isQC"];
    workNo.value = resp["workNo"];
    fillContactRecordData(resp);
    isCheckedAllOfThePage.value[0] = false;
    getMediaAbilitype();
    initAgentNumbers(param);
  })
};

/**
 * 处理"业务帐号"输入框展示结果
 */
const handleShowUserNameInput = () => {
  if (auth.value) {
    // 管理员帐号，则需要加基础查询条件“组织机构”
    templateMetaData.basicConfig.basicSearch[2].isShow = true
  }
  // 如果帐号权限与业务帐号展示情况相同，则不需要额外处理逻辑
  if (auth.value == isShowUserName.value) {
    return;
  }
  // 不是管理员帐号，则需要将首个查询条件“业务帐号”移除
  if (!auth.value) {
    templateMetaData.advancedSearch.searchList= [
      ...searchPrefixList,
      ...searchSuffixList,
    ];
    isShowUserName.value = false;
    return;
  }
  // 是管理员帐号，则需要展示按业务帐号/座席工号搜索
  const adminConditionList = [
    {
      searchType: 'input',
      modelValue: 'userName',
      placeholderValue: i18n('ccm.agent.contact.accountname'),
    },
    {
      searchType: 'input',
      modelValue: 'searchWorkNo',
      placeholderValue: i18n('ccm.agent.label.workNo'),
      rules: [
        {validator: validator.numberOnlyValidator, trigger: 'blur'}
      ],
    },
  ];
  templateMetaData.advancedSearch.searchList = [
    ...searchPrefixList,
    ...adminConditionList,
    ...searchSuffixList,
  ];
  isShowUserName.value = true;
}

/**
 * 组装列数据
 * @param columns 表头展示文本数组
 * @param contactItems 表内容的key
 */
const combineTableColumn = (columns: Array<string>, contactItems: Array<string>): void => {
  // 针对无数据时，contactItems返回为空进行处理
  const len = Math.min(columns.length, contactItems.length);
  const tableHeaders: Array<any> = [
    {
      label: i18n("ccm.agent.contact.callserno"),
      prop: "callSerialno",
      slot: "callSerialno",
      "show-overflow-tooltip": true,
      "min-width": getContactCallSerialnoColumnWidth(), // 需要完整展示呼叫流水号内容
      fixed: "left",
    }
  ];
  let hasRecordFiles = false;
  let midColumns = [];
  for (let i = 0; i < len; i++) {
    const column: any = {
      label: columns[i],
      prop: contactItems[i],
      "show-overflow-tooltip": true
    };
    if (slotSet.has(contactItems[i])) {
      column.slot = contactItems[i];
    }
    // 开始时间和结束时间，需要完整展示其内容
    if (["beginTime", "endTime"].includes(contactItems[i])) {
      column["min-width"] = getContactDateTimeColumnWidth();
    }
    if (contactItems[i] == "recordingfiles") {
      // 工作台进入，不展示录音文件
      if (isFromWorkbench.value) {
        continue;
      }
      hasRecordFiles = true;
      column.fixed = "right";
      column.slot = "recordingfiles";
      column['show-overflow-tooltip'] = false;
    }
    midColumns.push(column);
  }
  if (len > 0) {
    if (window.isRTL) {
      midColumns.reverse();
    }
    tableHeaders.push(...midColumns);
  }
  // 不存在录音文件，且不是从工作台进入，需要在前端固定添加该列
  if (!hasRecordFiles && !isFromWorkbench.value) {
    const column: any = {
      label: i18n('ccm.agent.contact.recordingfiles'),
      prop: 'recordingfiles',
      fixed: 'right',
      slot: "recordingfiles",
      'show-overflow-tooltip': false,
    }
    // 不同国际化，需要针对宽度适配
    column["min-width"] = getContactFileRecordsColumnWidth();
    tableHeaders.push(column);
  }
  tableColumns.value = tableHeaders;
};

const slotSet: ReadonlySet<String> = new Set<String>([
  "callSerialno", "channelType", "mediaAbility", "staffHangup", "calltype", "recordingfiles",
  "acceptNo", "called", "caller", 'callNo', 'orgName', 'orgId', 'orgFullName'
]);


const pageInfoStore = useContactInfoPageStore();
// 跳转到接触详情
const jumpToDetail = (row: any) => {
  const contactArray = [...tableData.value];
  pageInfoStore.updatePageInfo(originMenuId.value, contactArray, row.callSerialno).then(() => {
    const route : any = {
      // DTS2023070301118 SE方案：AiccBackHeader标题使用'呼叫流水号'替换客户号码字段
      title: row.callSerialno,
      name: props.sinkRouteName,
      query: {
        callSerialno: row.callSerialno,
        beginTime: timeUtils.getUTCTimestamp(row.beginTime),
        originMenuId: originMenuId.value,
        isSink: true,
        moduleType: moduleType.value,
        isAgentOrigin: isAgentOrigin.value,
      }
    }
    globalProperties.$event.addPageToTab(route)
  });
};

// 来电原因映射对象。key为来电原因id，value为来电原因完整对象
const callReasonMap = new Map<String, any>()

/**
 * 查询所有的呼叫原因
 */
const queryAllCallReason = async () => {
  await queryCallReasonInfo().then((resp: any) => {
    callReasonMap.clear()
    // 针对返回无数据/非数组场景，不处理
    if (!resp || !Array.isArray(resp)) {
      return
    }
    for (const item of resp) {
      callReasonMap.set(item.id, item);
    }
  });
};


/**
 * 填充来电原因信息
 * @param rowData 需要填充的行
 */
const fillCallReason = (rowData: any) => {
  const talkReasonIds = rowData?.talkReason
  if (!talkReasonIds) {
    return
  }
  let reason = ""
  const list = talkReasonIds.split(",")
  for (const id of list) {
    const value = callReasonMap.get(id)
    if(value) {
      reason += `,${value.name}`
    }
  }
  // 移除首位逗号
  rowData.talkReason = reason.substring(1)
}

const initAgentNumbers = (param: SearchModel): void => {
  // 非租间管理员，只会查询自己相关的接触记录总数
  if (!auth.value) {
    queryContactExtNumRecord(param)
      .then((resp: number) => {
        isShowTips.value = resp >= maxCountQuery.value;
        total.value = Math.min(resp, maxCountQuery.value);
      }).finally(() => aiccFullScreenRef.value?.init()); // 数据更新后，重新计算一次表格高度
    return;
  }
  queryAllContactCount(param).then((count: number) => {
    isShowTips.value = count >= maxCountQuery.value;
    total.value = Math.min(count, maxCountQuery.value);
  }).finally(() => aiccFullScreenRef.value?.init()); // 数据更新后，重新计算一次表格高度
};

/**
 * 获取媒体类别信息
 */
const getMediaAbilitype = () => {
  if (!tableData.value) {
    return;
  }
  for (const index of tableData.value) {
    index["channelTypeStr"] = loadChannelType(index["channelType"]) || "";
    index["mediaAbilityStr"] = mediaAbilityMap.get(String(index["mediaAbility"])) || "";
    index["staffHangupStr"] = staffHangupMap.get(String(index["staffHangup"])) || "";
    index["calltypeStr"] = callTypeMap.get(String(index["calltype"])) || "";
  }
};

const loadChannelType = (key: any) => {
  const arr = newSocialMedias.value?.filter((e: any) => e.socialId == key);
  if (arr && arr.length > 0) {
    return arr[0].socialCode;
  }
  return channelTypeMap.get(String(key));
}


/**
 * 检查是否为内部呼叫
 *
 * @param mediaTypeId 媒体类型
 * @param mediaAbility 媒体渠道
 * @returns { boolean }
 */
const innerCallCheck = (mediaTypeId: number | undefined, mediaAbility: number | undefined): boolean => {
  // 6-内部呼叫 51-内部两方求助 52-内部三方求助 以及 5-多媒体 的工号才需要转
  if (mediaTypeId === 6 || mediaTypeId === 51 || mediaTypeId === 52 || !mediaTypeId) {
    return true;
  }
  return mediaAbility === 5;
};

/**
 * 判断是否是工号
 * @param callNum 呼叫号码
 */
const isWorkNo = (callNum: string | number | undefined | null): boolean => {
  if (!callNum) {
    return false;
  }
  const callNumStr: string = String(callNum);
  const reg: RegExp = /^\d{3,5}$/;
  if (!reg.test(callNumStr)) {
    return false;
  }
  const callNumber: number = Number(callNumStr);
  return callNumber > 100 && callNumber <= 50000;
};

/**
 * 视频播放下载需要判断当前座席工号是否是质检员，如果不是，判断当前工号是否和接触记录里的相同。
 *
 * @param colValue 表格中当前行数据
 */
const recordPlayAndDownloadCheck = (colValue: any): boolean => {
  if (!colValue.RecordInfo || colValue.RecordInfo.length != 1 || colValue.RecordInfo[0].mediaAbility != 2) {
    return false;
  }
  return !isQC.value && workNo.value != colValue.workNo;
};

const initContactBasicDataConfig = () => {
  initContactItemConfig().then((resp: any) => {
    if (resp && resp["returnCode"] == 0) {
      // 查询接触记录数据配置项
      initCCContact();
    }
  });
};

const handleReset = (param: any) => {
  fillContactQueryParam(param.params);
}

/**
 * 页面刷新
 * 功能与handleSearch()一致，区别在于次方法会将页面回到第一页
 */
const handleRefresh = (param: any) => {
  const timeRange: Array<dayjs.Dayjs|Date> = getTimeRange(param.params);
  // 时间不合法，直接返回
  if (!validDateRangeHandler(timeRange)) {
    return;
  }
  currPage.value = 1;
  searchModel.limit = limit.value;
  searchModel.offset = offset.value;
  fillContactQueryParam(param.params);
  nextTick(()=>{
    advancedSearchRef.value.form.validate(async (valid: any) => {
      if (valid) {
        search();
      }
    })
  })
};
const handleSizeChange = (pageSize: number) => {
  limit.value = pageSize;
  currPage.value = 1;
  searchModel.limit = limit.value;
  searchModel.offset = offset.value;
  if (isFromWorkbench.value) {
    search();
    return;
  }
  advancedSearchRef.value.form.validate(async (valid: any) => {
    if (valid) {
      search();
    }
  })
};

const handleCurrentChange = (currentPage: number) => {
  currPage.value = currentPage;
  searchModel.offset = offset.value;
  if (isFromWorkbench.value) {
    search();
    return;
  }
  advancedSearchRef.value.form.validate(async (valid: any) => {
    if (valid) {
      search();
    }
  })
};

const search = () => {
  initAgentNumbers(searchModel);
  tableData.value = []
  queryAllContactRecord(searchModel).then((resp: any) => {
    if (resp["returnCode"] == VDN_NOT_ENABLE) {
      AiccElMessage({
        showClose: true,
        message: i18n("ccm.agent.operation.vdnNotEnabled"),
        type: "success"
      });
      return;
    }
    if (!resp["column"]) {
      initContactBasicDataConfig();
      return;
    }
    auth.value = resp["tenantManager"];
    handleShowUserNameInput();
    // 组装列数据
    combineTableColumn(resp["column"], resp["contactItem"]);
    contactItem.value = resp["contactItem"]

    isQC.value = resp["isQC"];
    workNo.value = resp["workNo"];
    fillContactRecordData(resp);
    getMediaAbilitype();
  })
};

const timeRangeChange = (param: any) => {
  // DTS2023070409396 时间组件事件触发后，直接执行一次校验和查询
  handleRefresh(param)
}

/**
 * 检查时间范围的合法性
 * @param timeRange 时间范围数组
 */
const validDateRangeHandler = (timeRange: Array<dayjs.Dayjs|Date>): boolean => {
  if (!timeRange || timeRange.length < 2) {
    AiccElMessage.error(i18n("ccd.contact.message.timeisempty"));
    return false;
  }
  const begin = dayjs(timeRange[0].toISOString());
  const maxTime: dayjs.Dayjs = begin.add(preDays.value, 'days') // 允许的最大截止时间
  const endTime: dayjs.Dayjs = dayjs(timeRange[1].toISOString()) || maxTime; // 实际选择的截止时间
  if (maxTime.isBefore(endTime)) {
    AiccElMessage.error(noMoreThanDays.value);
    return false;
  }
  return true;
}

const getTimeRange = (param: any) : Array<dayjs.Dayjs|Date> =>  {
  const timeRange = param.timeRange;
  if (timeRange == 'custom' || timeRange == undefined) {
    return param.timeRangeCustom;
  }
  if (Array.isArray(timeRange)) {
    return timeRange;
  }
  // 重置时，其值非数组，需要取值value
  return timeRange.value;
}

/**
 * 填充接触记录数据
 */
const fillContactRecordData = (resp: any) => {
  tableData.value = resp["contactRecordList"];
  if (!tableData.value) {
    return;
  }
  // 更新音频播放状态，防止翻页再返回，丢失当前播放录音文件信息
  updatePlayingRecordStatus();
  for (let i: number = 0; i < resp["contactRecordList"].length; i++) {
    const index: any = tableData.value[i];
    const mediaTypeId: number | undefined = index["mediaTypeId"];
    const mediaAbility: number | undefined = index["mediaAbility"];
    const isInnerCall: boolean = innerCallCheck(mediaTypeId, mediaAbility);
    // 时间转化，以及内部呼叫特殊标识
    for (const proto in index) {
      if (proto == "beginTime") {
        index[proto] = timeUtils.transTime(index[proto]);
        continue;
      }
      if (proto == "endTime") {
        // 数据库时间改为utc时间戳
        index[proto] = timeUtils.transTime(index[proto]);
        continue;
      }
      if (proto == "acceptNo" || proto == "called" || proto == "caller" || proto == 'callNo') {
        const key: string = proto + "IsWorkNo";
        tableData.value[i][key] = isInnerCall && isWorkNo(index[proto]);
      }
      if (proto == "skillId" && index[proto] == -1) {
        tableData.value[i][proto] = null;
      }
    }
    let onSftpServer = false;
    if (index.RecordInfo && index.RecordInfo.length == 1) {
      onSftpServer = index.RecordInfo[0].recordfileaddr && index.RecordInfo[0].recordfileaddr.indexOf(":/") != -1;
    }

    // 视频文件格式支持宇高在线播放，其他隐藏播放按钮
    if (index.RecordInfo && index.RecordInfo.length == 1 && index.RecordInfo[0].mediaAbility == 2) {
      index.isPlayVideoTag = !onSftpServer;
    }
    // MCU(cloud-vc)视频播放下载需要判断当前座席工号是否是质检员，如果不是，判断当前工号是否和接触记录里的相同。
    if (!onSftpServer && recordPlayAndDownloadCheck(index)) {
      index.recordInfoDisable = true;
    }
    // 填充来电原因信息
    fillCallReason(index)
  }
};

const rowClick = (row: any, column: any, event: any) => {
  if (event.target.className == 'el-link__inner') {
    return;
  }
  if (!column || column.property == 'recordingfiles' || column.property == 'orgName') {
    return;
  }
  jumpToDetail(row);
};


const getCellStyleClass = ({column} : any) => {
  if (column.property == 'recordingfiles') {
    return '';
  }
  return 'clickable-cell-style'
}
/**
 * 填充查询参数，主要用于获取aicc-advanced-search组件输入的查询
 * @param param aicc-advanced-search的查询参数
 */
const fillContactQueryParam = (param: any): void => {
  if (!param) {
    return;
  }
  const timeRange = getTimeRange(param);
  searchModel.beginTime = timeRange[0];
  searchModel.endTime = timeRange[1];
  searchModel.acceptNo = param?.searchAcceptNo;
  searchModel.calltype = param?.searchCallType;
  searchModel.mediaAbility = param?.searchMediaType;
  searchModel.skillId = param?.skillId;
  searchModel.evaluation = param?.evaluation;
  searchModel.existTalkReason = param?.existTalkReason;
  // 针对管理员帐号，获取入参；否则值取当前用户的帐号名称
  searchModel.countName = param?.userName;
  searchModel.workNo = param?.searchWorkNo;
  searchModel.caller = param?.searchCaller;
  searchModel.called = param?.searchCalled;
  searchModel.chatUserName = param?.chatUserName;
  searchModel.callNo = param?.searchCallNo;
  if(param.orgIds){
    searchModel.orgFlag = param.orgIds.indexOf("1") !== -1 ? "true" : "false";
    searchModel.noOrgFlag = param.orgIds.indexOf("0") !== -1 ? "true" : "false";
  }else{
    searchModel.orgFlag = "false";
    searchModel.noOrgFlag =  "false";
  }
  searchModel.orgIdList = !param.orgIds ? [] : param.orgIds.filter(function (item: any) {
    return item != '0' && item != '1'
  });
};

  const handleMediaPlay = (row: any) => {
    // 播放/暂停操作
    if (row.audioPlaying === undefined) {
      if (row.associateCall) {
        playAudioRecord(row, () => {
          isShowPlayer.value = true;
          row.audioPlaying = true;
          playerSrc.value = `${location.protocol}//${location.host}/service-cloud/u-route/ccmanagement/recordplay/recordext?associateCall=${row.associateCall}`;
        });
        return;
      }
      const recordIds: Array<String> = row.recordId.split(",");
      if (recordIds.length > 1) {
        contactFilePopVideoDisable.value = !isQC.value && row.workNo != workNo.value;
        contactFilePopRecordIds.value = row.recordId + "," + row.vrcRecordId + "-vrc"; // vrc场景下还需要播放视频
        isShowContactFilePop.value = true;
      } else {
        playFile(row.RecordInfo[0], row);
      }
      return;
    }
    // 以下操作均只涉及到音频，故可以直接根据row.audioPlaying的状态判断
    // 正在播放中，切换至暂停
    if (row.audioPlaying) {
      row.audioPlaying = false;
      aiccAudio.value.playPauseAudio("");
    } else {
      if (row.vrcRecordId) {
        const recordIds: Array<String> = row.recordId.split(",");
        if (recordIds.length > 1) {
          contactFilePopVideoDisable.value = !isQC.value && row.workNo != workNo.value;
          contactFilePopRecordIds.value = row.recordId + "," + row.vrcRecordId + "-vrc"; // vrc场景下还需要播放视频
          isShowContactFilePop.value = true;
        } else {
          playFile(row.RecordInfo[0], row);
        }
      }
      row.audioPlaying = true;
      aiccAudio.value.playPauseAudio("");
    }
  };

  const playFile = (recordInfo: any, row: any) => {
    const mediaAbility = recordInfo.mediaAbility || 1;
    // 语音文件
    if (mediaAbility == 1) {
      if (row.vrcRecordId) {
        playVideo(row, "vrc"); // vrc场景下还需要播放视频
      }
      playAudioRecord(row, () => {
        isShowPlayer.value = true;
        playerSrc.value = `${location.protocol}//${location.host}/service-cloud/u-route/ccmanagement/recordplay/voice?recordId=${recordInfo.recordId}`;
        row.audioPlaying = true;
      });
      return;
    }
    //视频文件,打开弹窗播放
    if (mediaAbility == 2) {
      if (row.vrcRecordId) {
        contactFilePopVideoDisable.value = !isQC.value && row.workNo != workNo.value;
        contactFilePopRecordIds.value = row.recordId;
        if (row.vrcRecordId) {
          contactFilePopRecordIds.value = row.recordId + "," + row.vrcRecordId + "-vrc"; // vrc场景下还需要播放视频
        }
        isShowContactFilePop.value = true;
        return;
      }
      playVideo(recordInfo, "video");
    }
  };

  const playVideo = (recordInfo: any, type: String) => {
    if (type == "video" && recordInfo.recordfileaddr.indexOf(":/") < 0) {
      if (!window.top?.agentStatus){
        AiccElMessage.info(i18n("ccm.agent.leavemessage.notLoginIn"));
        return;
      }
      if (window.top?.agentStatus?.isLogOut()){
        AiccElMessage.info(i18n("ccm.agent.leavemessage.notLoginIn"));
        return;
      }
    }
    if (type == "video") {
      videoPlayRecordId.value = recordInfo.recordId;
      vrcRecordId.value = undefined;
    } else {
      videoPlayRecordId.value = undefined;
      vrcRecordId.value = recordInfo.vrcRecordId;
    }
    videoPlayTitle.value = i18n("ccm.agent.contact.vidio");
    isShowContactVideoPop.value = true;
  };

  /**
   * 播放音频文件
   * @param row 表格列所在数据
   * @param callback 播放音频的回调
   */
  function playAudioRecord(row: any, callback: () => void) {
    // 针对非本次的播放/暂停，需要将原状态重置
    if (playingId.value && row.callSerialno != playingId.value) {
      playingId.value = row.callSerialno;
      resetAudioPlayStatus();
    }
    playingId.value = row.callSerialno;
    callback();
  }

  const closeAudioHandle = () => {
    resetAudioPlayStatus();
    playingId.value = void 0; // 初始化为空
    isPlaying.value = false;
    isShowPlayer.value = false;
  };

  /**
   * 重置音频播放状态
   * @param resetAll 是否重置所有，默认为true
   */
  const resetAudioPlayStatus = (resetAll: boolean = true): void => {
    if (!tableData.value) {
      return;
    }
    for (const data of tableData.value) {
      if (resetAll || (playingId.value && playingId.value === data.callSerialno)) {
        delete data.audioPlaying;
      }
    }
  };

  const audioPlayingHandle = (status: boolean) => {
    if (!tableData.value) {
      isPlaying.value = false;
      return;
    }
    isPlaying.value = status;
    for (const data of tableData.value) {
      if (data.audioPlaying !== undefined) {
        if (playingId.value == data.callSerialno) {
          data.audioPlaying = status;
        } else {
          delete data.audioPlaying;
        }
      }
    }
  };


  /**
   * 更新播放音频中的接触记录状态
   */
  const updatePlayingRecordStatus = async () => {
    // 未记录到播放id，直接返回
    if (!playingId.value) {
      return;
    }
    // 未查询到数据 无需更新状态
    if (!tableData.value || tableData.value.length == 0) {
      return;
    }
    for (const data of tableData.value) {
      if (playingId.value === data.callSerialno) {
        data.audioPlaying = isPlaying.value;
        return;
      }
    }
  }

  /**
   * 下载录制文件
   * @param row 对应数据
   */
  const downloadRecord = (row: any) => {
    // 检查是否需要设置压缩密码
    queryExportTenantSysParam().then((resp: any) => {
      showZipPwd.value = resp?.data?.value != 'false'
    }).finally(() => {
      if (row.associateCall) {
        popDownloadFileDialog(row, "mobileRecord");
        return;
      }
      const recordIds: Array<String> = row.recordId.split(",");
      if (recordIds.length > 1 || (recordIds.length == 1 && row.vrcRecordId && row.recordId.indexOf("-vrc") == -1)) {
        contactFilePopVideoDisable.value = !isQC.value && row.workNo != workNo.value;
        contactFilePopRecordIds.value = row.recordId;
        if (row.vrcRecordId) {
          contactFilePopRecordIds.value = row.recordId + "," + row.vrcRecordId + "-vrc"; // vrc场景下还需要播放视频
        }
        isShowContactFilePop.value = true;
        return;
      }
      popDownloadFileDialog(row, "contactRecord");
    })
  }

  /**
   * 弹出文件下载框
   * @param row 对应列的信息
   * @param dataType 下载文件类型
   */
  const popDownloadFileDialog = (row: any, dataType: String) => {
    const msg = row.mediaAbility == 1 ? "ccm.agent.contact.downloadrecord" : "ccm.agent.contact.downloadvideo";
    downloadFileDialog.value.handleDialog(true);
    downloadFileDialogTitle.value = i18n(msg);
    downloadFileDialogDataType.value = dataType;
    downloadFileDialogDataInfo.value = JSON.parse(JSON.stringify(row));
  };

  const downloadFileResult = () => {
    downloadFileDialog.value.handleDialog();
  };


  const handleExportData = () => {
    contactExportCreateDialogTitle.value = i18n("cms.report.task.export.records");
    // 检查是否需要设置压缩密码
    queryExportTenantSysParam().then((resp: any) => {
      showZipPwd.value = resp?.data?.value != 'false'
    })
    isShowContactExportCreate.value = true;
  }

  const handleExportView = () => {
    isShowRedDot.value = false;
    contactExportViewDialogTitle.value = i18n("ccm.custdata.operlog.viewExportTask");
    isShowContactExportView.value = true;
  }

  const changeExportCreate = (result: any) => {
    isShowContactExportCreate.value = false;
    if (!result) {
      return
    }
    isShowRedDot.value = true;
    ElMessageBox.confirm(
        i18n('isales.task.result.export.message'),
        i18n('ccm.agent.pageurls.successinfo.noPoint'),
        {
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: i18n('isales.task.result.export.query'),
          cancelButtonText: i18n('aicc.skip'),
          customStyle: DIALOG_STYLE.operation, // 按照UCD规范，此类弹窗宽度固定为616px
          closeOnClickModal: false,
          type: 'success',
        }
    ).then(() => handleExportView())
  }

  const changeExportView = () => {
    isShowContactExportView.value = false;
  }

  /**
   * 页面数据初始化
   */
  const initData = async () => {
    isFromWorkbench.value = Boolean(callid.value || props.callid); // 是否属于历史页面
    currPage.value = 1;
    queryOrgInfo();
    await queryAllCallReason();
    queryUsualChannelConfig();
    initCCContact();
    checkExportAuth();
    querySatisfactionLevel();
    querySkillInfoList();
  }

  const callIdCache = ref<any>(callid.value || '-');
  const routName = route.name;

  watch([callid, originMenuId, isAgentOrigin, moduleType], () => {
    if (routeName.value == routName && callid.value && callid.value != callIdCache.value) {
      initData();
      callIdCache.value = callid.value;
    }
  })

  onBeforeMount(() => {
    initData();
  });
</script>

<style lang="less" scoped>
.contact-record-query-layout {
  padding: 20px 20px 0;
}

.contact-record-query-layout :deep(.clickable-cell-style){
  cursor: pointer;
}

.contact-record-search--aicc-audio {
  min-width: 19rem;
}

.search {
  background-color: rgba(255, 255, 255, 1);
}

.table-header-cell {
  width: 141px;
  height: 28px;
  background: rgba(249, 249, 249, 1);
}

.agent-name-show-pop {
  width: 280px;
  padding: 0;

  .agent-name-show-pop-card {
    width: inherit;
    height: 150px;
    padding: 0;
  }
}

.footer--tips {
  color: #6E6E6E;
}

.el-form-item {
  margin-bottom: 0;
}

@media screen and (max-width: 1919px){
  // 页面链接字体大小，需要适配分辨率
  :deep(.el-link) {
    font-size: var(--12px-to-rem);
  }
}
</style>