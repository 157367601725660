export default {
    "dy.agent.audio.tab.outgoingcall": "Ausgehender Anruf",
    "dy.agent.audio.tab.internalcall": "Interner Anruf",
    "dy.agent.tab.over.number.hints": "Es können maximal acht Registerkarten geöffnet werden. Sie können keine weiteren Registerkarten öffnen. Bitte schließen Sie zuerst andere Registerkarten.",
    "dy.chat.multimedia.update": "Aktualisieren",
    "dy.chat.agent.logout.hints": "Keine Interaktionen. Bitte melden Sie sich an, um mit der Arbeit zu beginnen.",
    "dy.chat.label.contacts": "Kontakte",
    "dy.chat.label.call": "Anruf",
    "dy.chat.label.multimedia": "Multimedia",
    "dy.chat.label.history": "Geschichte",
    "dy.chat.label.monitor": "Überwachen",
    "dy.agent.label.id": "Kennung:",
    "dy.agent.label.enter.exnum": "Externe Nummer eingeben",
    "dy.chat.multimedia.required": "Erforderlich",
    "dy.chat.history.disconnect.tip": "Der Anruf wurde getrennt. Sie können eine Offline-Nachricht senden, um die Verbindung mit dem Kunden wiederherzustellen.",
    "dy.agent.voice.extnumber.isnull": "Die externe Nummer ist null.",
    'dy.agent.session.list.timeout': 'Zeitüberschreitung',
    "dy.agent.voice.msg.noskill": "Es ist keine Skill-Warteschlange verfügbar",
    'dy.session.transfer.button': 'Habe ich',
    'dy.agent.transfer.failed': 'Weiterleitung an den Zielagenten fehlgeschlagen',
    'dy.agent.twoinnerhelp.failed': "Die Konsultation des Zielagenten versäumt",
    'dy.agent.threeinnerhelp.failed': 'Ausfall des Zielagenten durch drei Parteien',
    'dy.agent.message.tipsNotMonitor': 'Sie sind kein Inspektor. Sie können die Inspektion nicht durchführen.',
    'dy.agent.message.selectInfo': 'Klicken Sie zuerst auf den Agenten, um ihn zu überprüfen.',
    'dy.agent.attachment.notPreview': 'Dateityp unterstützt keine Vorschau. Laden Sie die Datei herunter, um den Inhalt anzuzeigen.',
    "dy.chat.agent.novoEmail": "Neue E-Mail-Adresse",
    "dy.chat.sendmessage.recipient": "An",
    "dy.chat.chatcard.folded": "Gefaltet",
    "dy.chat.status.drafts": "Entwürfe",
    "dy.agent.call.selectextnumber": "Bitte wählen Sie eine externe Nummer.",
    "dy.agent.call.inputextnumber": "Bitte geben Sie eine externe Nummer ein."

}