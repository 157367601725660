<template>
  <sweet-tooltip :ref="id" :disabled="!isToolTipShow">
    <template #content>
      <slot name="content"> </slot>
    </template>
    <template #default>
      <slot name="default"> </slot>
    </template>
  </sweet-tooltip>
</template>

<script>
export default {
  name: 'aicc-tooltip',
  props: {
    overflowTargetSelector: {
      type: String,
      default() {
        return ''
      }
    },
    disabled: {
      type: Boolean,
      default() {
        return false
      }
    },
    // 支持传入一个参数，使仅省略号出现tooltip的规则临时失效
    isShowAnyway: {
      type: Boolean,
      default() {
        return false
      }
    },
    // 某些特殊场景下，找父节点可能找错，再提供一个容错的选择器，当传入该值时，在该过滤器下再去找子元素
    parentSelector: {
      type: String,
      default() {
        return ''
      }
    },
    changeText: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      targetDom: null,
      id: '',
      clientWidth: 0,
      scrollWidth: 0,
      observer: null,
      timemout: null,
      isAlive: false
    }
  },
  computed: {
    isToolTipShow() {
      if (this.isShowAnyway) {
        return true
      } else if (this.disabled || !this.isAlive) {
        return false
      } else if (!this.targetDom) {
        return true
      } else if (this.clientWidth < this.scrollWidth) {
        // scrollWidth更大，说明有溢出
        return true
      } else {
        return false
      }
    }
  },
  watch:{
    changeText(){
      this.init()
    }
  },
  mounted() {
    this.init()
  },
  unmounted() {
    this.isAlive = false
    if (this.timemout) {
      clearTimeout(this.timemout)
    }
  },
  methods: {
    init() {
      this.isAlive = true
      if (!this.id) {
        this.id = new Date().getTime().toString()
      }
      this.timemout = setTimeout(() => {
        try {
          if (this.overflowTargetSelector) {
            let parent = $($(this.$refs[this.id].$el)[0].parentElement)
            if (this.parentSelector) {
              parent = parent.find(this.parentSelector)
            }
            this.targetDom = parent.find(this.overflowTargetSelector)[0]
          }
        } catch (error) {
          this.targetDom = null
        }

        if (this.targetDom) {
          this.clientWidth = this.targetDom.clientWidth
          this.scrollWidth = this.targetDom.scrollWidth
          this.observer = new ResizeObserver(() => {
            this.clientWidth = this.targetDom.clientWidth
            this.scrollWidth = this.targetDom.scrollWidth
          })
          this.observer.observe(this.targetDom, { box: 'border-box' })
        }
      }, 500)
    }
  }
}
</script>
