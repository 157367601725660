<script>

import {queryAllSkill, queryAllSkillCount} from "@/views/sumweb/api";
import {Search} from "@element-plus/icons-vue";
import {nextTick} from "vue";

export default {
  name: "skill-queue-dialog",
  components: {
    Search
  },
  data() {
    return {
      display: false,
      tableData: [],
      limit: 10,
      offset: 0,
      total: 0,
      pageSize: 10,
      currentPage: 1,
      pageSizes: [10, 20, 50, 100],
      filterSkill: undefined,
      selected: [],
      placeHolderToolVisible: false,
      placeHolderToolAccess: false,
    }
  },
  mounted() {

  },
  methods: {
    open(skillList) {
      this.skillList = skillList;
      this.display = true;
    },
    handleOpen() {
      this.filterSkill = undefined;
      this.offset = 0;
      this.limit = 10;
      this.currentPage = 1;
      this.tableData = []
      this.total = 0
      if (this.skillList.length > 0) {
        nextTick(() => {
          for (let row of this.skillList) {
            this.$refs.multipleTable.toggleRowSelection(row, true);
          }
        })
      }
      this.handleToolTips()
      this.handleRefresh()
    },
    handleRefresh() {
      queryAllSkillCount(this.filterSkill).then(res => {
        this.total = res;
      })
      queryAllSkill(this.limit, this.offset, this.filterSkill).then(res => {
        if (res && res.length > 0) {
          this.tableData = res;
        } else {
          this.tableData = [];
          this.total = 0;
        }
      })
    },
    handleToolTips() {
      let inputRef = this.$refs.inputRef.ref;
      let fontSize = window.getComputedStyle(inputRef).fontSize;
      let placeholder = inputRef.placeholder;
      let inputWidth = inputRef.offsetWidth;

      let span = document.createElement('span');
      span.innerText = placeholder;
      span.style.fontSize = fontSize;
      document.body.appendChild(span);
      let placeHolderWidth = span.offsetWidth;
      document.body.removeChild(span);

      if (inputWidth < placeHolderWidth) {
        this.placeHolderToolAccess = true;
      }
    },
    handleCurrentChange(currentPage) {
      this.offset = (currentPage - 1) * this.limit
      this.handleRefresh()
    },
    handleSizeChange(count) {
      this.limit = count
      this.offset = 0
      this.currentPage = 1
      this.handleRefresh()
    },
    getRowKey(row) {
      return row.skillId
    },
    handleConfirm() {
      this.selected = this.$refs.multipleTable.getSelectionRows();
      if (this.skillList.length > 0) {
        let map = new Map(this.skillList.map(row => [row.skillId, row]));
        for (let data of this.selected) {
          if (map.has(data.skillId)) {
            let row = map.get(data.skillId);
            data.agentWeight = row.agentWeight;
            data.skillWeight = row.skillWeight;
          }
        }
      }
      this.$emit('getSkills', this.selected)
      this.display = false
    },
    clearSelected() {
      this.$refs.multipleTable.clearSelection()
    },
    handleClose() {
      this.clearSelected();
    },
    mediaTypeFormatter(row, column) {
      let mediaTypeId = row[column.property];
      if (mediaTypeId) {
        return this.getSkillType[mediaTypeId];
      }
    },
    mounseEnter() {
      if (this.placeHolderToolAccess) {
        this.placeHolderToolVisible = true
      }
    },
    mounseLeave() {
      if (this.placeHolderToolAccess) {
        this.placeHolderToolVisible = false
      }
    },
  },
  computed: {
    getSkillType() {
      return {
        "2": $t('ccm.agent.contact.audioclicktodial'),
        "5": $t('ccm.agent.contact.Voice'),
        "18": $t('ccm.agent.contact.videoclicktodial'),
        "19": $t('ccm.agent.contact.vidio'),
        "53": $t('ccm.agent.contact.multimedia'),
        "53-1": $t('ccm.agent.contact.multimedia.web'),
        "53-2": $t('ccm.agent.contact.multimedia.whatsapp'),
        "53-3": $t('ccm.agent.contact.multimedia.line'),
        "53-4": $t('ccm.agent.contact.multimedia.wechat'),
        "53-5": $t('ccm.agent.contact.multimedia.facebook'),
        "53-6": $t('ccm.agent.contact.multimedia.twitter'),
        "53-7": $t('ccm.agent.contact.multimedia.fiveG'),
        "53-8": $t('ccm.agent.contact.multimedia.email'),
        "53-9": $t('ccm.agent.contact.multimedia.sms'),
        "53-11": $t('ccm.agent.contact.multimedia.instagram'),
        "53-12": $t('ccm.agent.contact.multimedia.telegram')
      }
    },
  }
}
</script>

<template>
  <el-dialog v-model="display"
             :title="$t('ccm.agent.title.selectskill')"
             @open="handleOpen"
             @close="handleClose"
             width="616" class="aicc-dialog-limit-height">
    <el-tooltip :content="$t('ccm.agent.label.skillName')"
                :visible="placeHolderToolVisible"
                placement="top"
                effect="light">
      <el-input
          ref="inputRef"
          v-model="filterSkill"
          style="width: 240px;margin-end: 10px"
          clearable
          autocomplete="off"
          @keyup.enter="handleRefresh"
          @mouseenter="mounseEnter"
          @mouseleave="mounseLeave"
          :placeholder="$t('ccm.note.config.notename')">
        <template #prefix>
          <el-icon class="search-icon" @click="handleRefresh">
            <Search />
          </el-icon>
        </template>
      </el-input>
    </el-tooltip>
    <el-tooltip :content="$t('cms.export.field.refresh')"
                placement="top"
                effect="light">
      <el-button class="aicc-button-icon" @click="handleRefresh">
        <img class="refresh-icon-img" src="@/assets/img/search/search_refresh.png" alt="search">
      </el-button>
    </el-tooltip>
    <el-table :data="tableData"
              highlight-current-row
              ref="multipleTable"
              border
              height="430"
              style="margin-top: 10px"
              :row-key="getRowKey"
              tooltip-effect="light">
      <el-table-column type="selection"
                       width="55"
                       :reserve-selection="true" />
      <el-table-column property="name"
                       width="140"
                       :show-overflow-tooltip="true"
                       :label="$t('ccm.note.config.notename')" />
      <el-table-column property="maxWaitTime"
                       :show-overflow-tooltip="true"
                       :label="$t('ccm.agent.label.maxWaitTime')"  />
      <el-table-column property="maxWaitNum"
                       :show-overflow-tooltip="true"
                       :label="$t('ccm.agent.label.maxWaitNum')" />
      <el-table-column property="mediaTypeId"
                       width="110"
                       :formatter="mediaTypeFormatter"
                       :show-overflow-tooltip="true"
                       :label="$t('ccm.agent.label.usedMode')" />
    </el-table>
    <el-pagination
        v-model:page-size="pageSize"
        :page-sizes="pageSizes"
        :total="total"
        v-model:current-page="currentPage"
        :small="false"
        :disabled="false"
        :background="false"
        layout="sizes, total, prev, pager, next"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
    />
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="display = false">{{$t('cms.indexmonitor.field.cancel')}}</el-button>
        <el-button type="primary" @click="handleConfirm">
          {{$t('cms.indexmonitor.field.confirm')}}
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<style scoped lang="less">

.search-icon {
  height: 16px;
  margin-top: 0;
  cursor: pointer;
  transition: all 0.2s;

  &:active {
    transform: scale(0.9);
  }
}

img {
  width: 16px;
  position: relative;
  top: 1px;
}

</style>