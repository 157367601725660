import i18n from '@/lang'
import ccmeConfig from '@/views/ccmessaging/config'

const $t = i18n.global.t

// 默认的渠道类型，搜索下拉框使用
export const DEFAULT_CHANNEL_TYPES = [
  {
    label: ccmeConfig.name.channel.MAP.WECHAT,
    value: 'WECHAT'
  },
  {
    label: ccmeConfig.name.channel.MAP.WEB,
    value: 'WEB'
  },
  {
    label: ccmeConfig.name.channel.MAP.FACEBOOK,
    value: 'FACEBOOK'
  },
  {
    label: ccmeConfig.name.channel.MAP.TWITTER,
    value: 'TWITTER'
  },
  {
    label: ccmeConfig.name.channel.MAP['5G'],
    value: '5G'
  },
  {
    label: ccmeConfig.name.channel.MAP.EMAIL,
    value: 'EMAIL'
  },
  {
    label: ccmeConfig.name.channel.MAP.LINE,
    value: 'LINE'
  },
  {
    label: ccmeConfig.name.channel.MAP.WHATSAPP,
    value: 'WHATSAPP'
  },
  {
    label: ccmeConfig.name.channel.MAP.SMS,
    value: 'SMS'
  }
]

// 多媒体
export const MEDIA_TYPE_ID_MULTIMEDIA = 53
// 点击通话
export const MEDIA_TYPE_ID_CLICK_TO_DIAL = 2

export const DEFAULT_CHANNEL_TYPE_MAP = {
  WECHAT: {
    subMediaTypeId: 4
  },
  TWITTER: {
    subMediaTypeId: 6
  },
  FACEBOOK: {
    subMediaTypeId: 5
  },
  '5G': {
    subMediaTypeId: 7
  },
  WEB: {
    subMediaTypeId: 1
  },
  EMAIL: {
    subMediaTypeId: 8
  },
  SMS: {
    subMediaTypeId: 9
  },
  LINE: {
    subMediaTypeId: 3
  },
  WHATSAPP: {
    subMediaTypeId: 2
  },
  INSTAGRAM: {
    subMediaTypeId: 11,
    commonFlag: true
  },
  TELEGRAM: {
    subMediaTypeId: 12,
    commonFlag: true
  }
}
export const DEFAULT_BOOLEAN_VARIABLE = {
  commonFlag: false,
  robotFlag: false,
  offlineFlag: false,
  assitFlag: false,
  workTimeFlag: false,
  transFlag: false,
  timeoutTransFlag: false,
  sessionEndFlag: false,
  lastAgentFlag: false
}

// 修改时填充的默认密码值
export const DEFAULT_PASSWORD_STR = 'Default Values Of Important Parameters'
export const DEFAULT_PAGE_SIZES = [10, 20, 50, 100]
export const DEFAULT_DIALOG_PAGE_SIZES = DEFAULT_PAGE_SIZES
export const DEFAULT_DIALOG_LAYOUT = 'total, prev, pager, next' // 分页组件布局配置
// 判断是否是默认密码
export function isDefaultPassword(obj, field) {
  if (obj === undefined) {
    return false
  }
  return obj[field] === DEFAULT_PASSWORD_STR
}

// 编辑时密钥框的样式
export function passwordDivClass(opeType, obj, field) {
  if (obj === undefined) {
    return ''
  }
  return opeType === 'modify' && obj[field] === DEFAULT_PASSWORD_STR ? 'is-disabled' : ''
}

export function emptyToNull(value) {
  if (value === '' || value === undefined) {
    return null
  }
  return value
}

export function appIdValidate(rule, value, callback) {
  if (value === '') {
    callback()
  } else {
    const regEn = /^[0-9a-zA-Z_~!@#$%^&*-]*$/g
    const re = new RegExp(regEn)
    if (re.test(value)) {
      callback()
    } else {
      callback(new Error($t('ccmessaging.chat.channelconfig.KeyValidate')))
    }
  }
}

// 密钥验证
export function KeyValidate(rule, value, callback) {
  if (value === '' || value === DEFAULT_PASSWORD_STR) {
    callback()
  } else {
    const regEn = /^[0-9a-zA-Z_~!@#$%^&*-]*$/g
    const re = new RegExp(regEn)
    if (re.test(value)) {
      callback()
    } else {
      callback(new Error($t('ccmessaging.chat.channelconfig.KeyValidate')))
    }
  }
}

// 环境名验证
export function envNameValidate(rule, value, callback) {
  if (value === '') {
    callback()
  } else {
    const regEn = /^[0-9a-zA-Z]*$/g
    const re = new RegExp(regEn)
    if (re.test(value)) {
      callback()
    } else {
      callback(new Error($t('ccmessaging.chat.channelconfig.envNameValidate')))
    }
  }
}

//私钥验证
export function privateKeyValidate(rule, value, callback) {
  if (value === '' || value === DEFAULT_PASSWORD_STR) {
    callback()
  } else {
    try {
      const valueObj = JSON.parse(value)
      if (typeof valueObj == 'object') {
        const regEn = /^[0-9a-zA-Z_~!@#$%^&*-]*$/
        const re = new RegExp(regEn)
        for (const key in valueObj) {
          if (!re.test(key) || !re.test(valueObj[key])) {
            callback(new Error($t('ccmessaging.chat.channelconfig.privateKeyValidate')))
          }
        }
        callback()
      } else {
        callback(new Error($t('ccmessaging.chat.channelconfig.privateKeyValidate')))
      }
    } catch (e) {
      callback(new Error($t('ccmessaging.chat.channelconfig.privateKeyValidate')))
    }
  }
}

//电话号码验证
export function phoneNumberValidate(rule, value, callback) {
  if (value === '') {
    callback()
  } else {
    const regEn = /^[0-9]{1,64}$/
    const re = new RegExp(regEn)
    if (re.test(value)) {
      callback()
    } else {
      callback(new Error($t('ccmessaging.chat.channelconfig.checkWhatsAppPhoneNum')))
    }
  }
}

export function checkWhatsAppBaseUrl(rule, value, callback) {
  if (value === '') {
    callback()
  } else {
    const regEn = /^(https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?$/
    const re = new RegExp(regEn)
    if (re.test(value)) {
      callback()
    } else {
      callback(new Error($t('ccmessaging.chat.channelconfig.checkWhatsAppBaseUrl')))
    }
  }
}

export function checkPubMessageUrl(rule, value, callback) {
  if (value === '') {
    callback()
  } else {
    const regEn =
      /^(https|http):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?$/
    const re = new RegExp(regEn)
    if (re.test(value)) {
      callback()
    } else {
      callback(new Error($t('ccmessaging.chat.channelconfig.checkWhatsAppBaseUrl')))
    }
  }
}

export function tokenLengthRange(rule, value, callback) {
  if (value === '' || value === DEFAULT_PASSWORD_STR) {
    callback()
  } else {
    if (value.length < 16 || value.length > 32) {
      callback(
        new Error(
          $t('provision.callcenterinstanceslist.vcallcenterDeatil.wasPsdLengthRangeValidateFailed')
        )
      )
    } else {
      callback()
    }
  }
}

export function tokenValidate(rule, value, callback) {
  if (value === '' || value === DEFAULT_PASSWORD_STR) {
    callback()
  } else {
    var regUpper = /[A-Z]/
    var regLower = /[a-z]/
    var regNum = /[0-9]/
    var regSpecial = /[~!@#$%&*()-/_=+{};:'",<.>?\[\]]/
    var complex = 0
    if (regLower.test(value)) {
      ++complex
    }
    if (regUpper.test(value)) {
      ++complex
    }
    if (regNum.test(value)) {
      ++complex
    }
    if (regSpecial.test(value)) {
      ++complex
    }
    if (complex < 3) {
      callback(
        new Error(
          $t('ccmessaging.callcenterinstanceslist.vcallcenterDeatil.passValidateFailed', {
            n: '~!@#$%^&*()-_=+|[{}];:\'",<.>/?'
          })
        )
      )
    } else {
      callback()
    }
  }
}

export function accessTokenDurationValidate(rule, value, callback) {
  if (value === '') {
    callback()
  } else {
    const regEn = /^(([1-5][0-9]{0,1})|[1-9]|60){0,1}$/g
    const re = new RegExp(regEn)
    if (re.test(value)) {
      callback()
    } else {
      callback(new Error($t('ccmessaging.chat.channelconfig.checkWhatsAppAccessTokenDuration')))
    }
  }
}

export function NameValidate(rule, value, callback) {
  if (value === '') {
    callback()
    return
  }
  const regEn = /^[a-zA-Z_][a-zA-Z0-9_]*$/
  const re = new RegExp(regEn)
  if (re.test(value)) {
    callback()
  } else {
    callback(new Error($t('ccmessaging.chat.channelconfig.alphavalidateFailed')))
  }
}

export function StrValidate(rule, value, callback) {
  if (value === '') {
    callback()
    return
  }

  const regEn = /['"\/<>\\]/gi
  const re = new RegExp(regEn)
  if (!re.test(value)) {
    callback()
  } else {
    callback(new Error($t('ccmessaging.chat.channelconfig.checkmessage')))
  }
}

export function ruleNameValidate(rule, value, callback) {
  if (value === '') {
    callback()
    return
  }

  const regEn = /^[a-zA-Z0-9_.]*$/gi
  const re = new RegExp(regEn)
  if (re.test(value)) {
    callback()
  } else {
    callback(new Error($t('cobrowse.sensitiveinfo.rulenamecheck')))
  }
}

export function cssValidate(rule, value, callback) {
  if (value === '') {
    callback()
    return
  }

  const regEn = /^[a-zA-Z0-9.#*,\s>+~\[\]=\|^$:\-()_]*$/gi
  const re = new RegExp(regEn)
  if (re.test(value)) {
    callback()
  } else {
    callback(new Error($t('cobrowse.sensitiveinfo.cssselectorcheck')))
  }
}

export function EncAESKeyValidate(rule, value, callback) {
  if (value === '' || value === DEFAULT_PASSWORD_STR) {
    callback()
    return
  }

  const regEn = /^[0-9a-zA-Z]*$/g
  const re = new RegExp(regEn)
  if (re.test(value) && (value.length === 43 || value.length === 9)) {
    callback()
  } else {
    callback(new Error($t('ccmessaging.chat.channelconfig.checkEncodingAESKey')))
  }
}

export function procNumberRangeCheck(value, range, callback) {
  if (checkNumberRange(value, range)) {
    callback()
  } else {
    callback(new Error($t('isales.taskpolicy.message.rangeAutoCallTime', range)))
  }
}

export function checkNumberRange(value, range) {
  if (value == null || value === '') {
    return true
  }
  return value >= range[0] && value <= range[1]
}
