<template>
    <div>
        <sweet-dialog v-model="dialogVisible" :title="$t('isales.management.title.selectProp')"
                      :close-on-click-modal="false" width="616px" class="aicc-dialog-limit-height">
            <sweet-form :model="propList" label-width="auto"
                        ref="isalesChoosePropForm">
                <aicc-table ref="aiccTable"
                            :tableData="propList"
                            :tableColumns="tableColumns"
                            :tableTools="{ showPagination: true, checkedInfo: true, columnConfig: false }"
                            :paginationConfig="paginationConfig"
                            :height="430"
                            :row-key="getKey"
                            @handlePagination="handlePagination">
                    <template #propType="scope">
                        <span>{{ PROP_TYPE[scope.row.propType] }}</span>
                    </template>
                    <template #quantity="scope">
                        <sweet-form-item :prop="`${scope.$index}.count`"
                                         :rules="[{ min: 1, max: 10, validator: this.callTimesValidator, trigger: ['blur', 'change'] }]">
                            <sweet-input-number v-model="scope.row.count"
                                                :disabled="!selectable(scope.row)"/>
                        </sweet-form-item>
                    </template>
                </aicc-table>
            </sweet-form>
            <template #footer>
      <span class="dialog-footer">
        <sweet-button @click="dialogVisible = false">{{ $t('isales.common.title.cancel') }}</sweet-button>
        <sweet-button type="primary" @click="confirm">{{ $t('isales.common.title.confirm') }}</sweet-button>
      </span>
            </template>
        </sweet-dialog>
    </div>
</template>

<script>
  import {queryDataPropList} from '@/views/isales/api/manualCallTask'
  import {PROP_TYPE} from '@/views/isales/views/taskManagement/enum'
  import appConfig from '@/config'

  export default {
    name: 'ChooseProp',
    props: {
      show: {
        type: Boolean,
        default: false
      },
      mode: {
        type: String,
        required: true
      },
      type: {
        type: String,
        default: ''
      },
      selectedAttributes: {
        type: Array,
        default() {
          return []
        }
      }
    },
    data() {
      return {
        PROP_TYPE,
        tableData: [],
        tableColumns: [
          {
            type: 'selection', // 多选
            selectable: this.selectable,
            width: '50px',
            'reserve-selection': true
          },
          {
            label: this.$t('isales.data.prop.propName'), // 属性名称
            prop: 'propName',
          },
          {
            label: this.$t('isales.data.prop.propType'), // 属性类型
            prop: 'propType',
            slot: 'propType'
          },
          {
            label: this.isOrderMode ? this.$t('isales.data.prop.orderType') : this.$t('isales.data.prop.quantity'), // 数量
            slot: this.isOrderMode ? 'orderType' : 'quantity',
            'show-overflow-tooltip' : false,
            width: '200px'
          }
        ],
        paginationConfig: {
          offset: 0,
          pageSize: 10,
          limit: 10, // 每页条数
          total: 0, //总条数
          layout: appConfig.pagination.LAYOUT.COMPACT
        },
        propList: [],
      }
    },
    computed: {
      dialogVisible: {
        get() {
          return this.show
        },
        set(val) {
          this.$emit('update:show', val)
        }
      },
      isOrderMode() {
        return this.mode === 'orderPolicy'
      },
      isPickUpMode() {
        return this.mode === 'pickUp'
      },
      isMediaMode() {
        return this.mode === 'metaMultimedia'
      },
    },
    created() {
      if(this.type==='callPolicy'){
        this.tableColumns = [
          {
            type: 'selection', // 多选
            selectable: this.selectable,
            width: '50px'
          },
          {
            label: this.$t('isales.data.prop.propName'), // 属性名称
            prop: 'propName',
          },
          {
            label: this.$t('isales.data.prop.propType'), // 属性类型
            prop: 'propType',
            slot: 'propType'
          }
        ]
      }
      this.queryPropList()
    },
    methods: {
      callTimesValidator(rule, value, callback) {
        let reg = /[.]/;
        if (reg.test(value)) {
          callback($t('isales.task.promotion.numberOnly'));
        }
        if (Number(value) < rule.min || Number(value) > rule.max) {
          callback($t('isales.taskpolicy.message.rangeAutoCallTime', [rule.min, rule.max]));
        } else {
          callback();
        }
      },
      async confirm() {
        let aiccTableResult = await this.$refs.isalesChoosePropForm.validate().catch(err => err);
        if (aiccTableResult !== true) {
          if (aiccTableResult['propList.-1.count']) {
            delete aiccTableResult['propList.-1.count']
          }
        }
        let keysSysAutoResult = Object.keys(aiccTableResult)
        if (keysSysAutoResult.length) {
          return;
        }
        const selectionRows = this.$refs.aiccTable.table.getSelectionRows()
        this.$emit('confirm', selectionRows)
        this.dialogVisible = false
      },
      async queryPropList() {
        const {limit, offset} = this.paginationConfig
        let params = {
          limit,
          offset,
          propObject: {
            status: 1,
            propType: 3,
            marketingMode: 1
          }
        }
        if (this.isOrderMode) {
          params.propObject['mandatory'] = 1
        }
        if (this.isMediaMode) {
          params.propObject.marketingMode = 2;
        }
        const result = await queryDataPropList(params)
        if (result && result.resultCode === '0203000') {
          result.propList.forEach((item) => {
            item.count = 1
          })
          this.propList = result.propList
          this.paginationConfig.total = result.total
        } else {
          this.$message({
            type: 'error',
            duration: 10000,
            'show-close': true,
            offset: 56,
            message: result.resultDesc
          })
        }
      },
      selectable(row) {
        return !this.selectedAttributes.includes(row.propName)
      },
      handlePagination(data) {
        Object.assign(this.paginationConfig, data)
        this.queryPropList()
      },
      getKey(row){
        return row.propId
      },
    }
  }
</script>

<style scoped>

</style>