<template>
  <sweet-dialog
    v-model="info.isShow"
    width="56rem"
    append-to-body
    class="aicc-dialog"
    :title="i18n('ccm.contact.detail.message.sending.record')"
  >
    <div class="chat-email-view">
      <div class="email-preview-header">
        <div class="email-preview-header__title" :title="title">{{ title }}</div>
        <div class="email-preview-header__text" v-if="isSuccessI18">
          <span class="hlds-text-color--less-weak" :span="3">{{
            i18n('ccm.message.center.nocitiction.sending.records.status')
          }}</span>
          <span :title="isSuccessI18">{{ isSuccessI18 }}</span>
        </div>
        <div class="email-preview-header__text" v-if="mediaType == '0'">
          <span class="hlds-text-color--less-weak" :span="3">{{
            i18n('message.center.nocitiction.outbox')
          }}</span>
          <span :title="senderNo">{{ senderNo }}</span>
        </div>
        <div class="email-preview-header__text" v-if="mediaType == '1' && smsType == '1'">
          <span class="hlds-text-color--less-weak" :span="3">{{
            i18n('ccnotification.notifition.sendmessage.sender')
          }}</span>
          <span :title="senderNo">{{ senderNo }}</span>
        </div>
        <div class="email-preview-header__text" v-if="receiverNames">
          <span class="hlds-text-color--less-weak" :span="3">{{
            i18n('message.center.label.receiver')
          }}</span>
          <span :title="receiverNames">{{ receiverNames }}</span>
        </div>
        <div class="email-preview-header__text" v-if="sendTime">
          <span class="hlds-text-color--less-weak" :span="3">{{
            i18n('message.center.label.sendTime')
          }}</span>
          <span :title="sendTime">{{ sendTime }}</span>
        </div>
        <div class="email-preview-header__text email-appendixfile" v-if="attachName">
          <span class="hlds-text-color--less-weak" :span="3">{{
            i18n('ccnotification.notification.label.attachment')
          }}</span>
          <div v-for="(item, index) in attachName" :key="index">
            <a class="hlds-m-left--small" href="#" @click.prevent="download(index)">
              {{ item }}
            </a>
          </div>
        </div>
      </div>
      <div
        class="hlds-p-around--x-small hlds-m-top--none hlds-text-link--reset hlds-m-right--small"
        style="
          white-space: normal;
          word-wrap: normal;
          word-break: break-all;
          text-align: justify;
          line-height: 22px;
          cursor: default;
          padding-top: 0px !important;
        "
      >
        <div class="hlds-text--x-small hlds-m-left--large hlds-border--top hlds-m-top--x-small">
          <pre
            class="
              hlds-word-break-all hlds-white-space
              hlds-text--medium
              hlds-text-family--regular hlds-text-family--regular-EN
            "
            style="overflow-y: auto; white-space: normal; height: 500px; width: 100%"
          >
                <!-- 短信邮件内容 pre标签不要代码格式化，和div标签之间有空行 -->
                <pre class="email-preview-content hlds-text-family--regular hlds-text-family--regular-EN" style="overflow:hidden;width:100%;white-space: pre-wrap;" v-if="'2' != info.mediaType.value"><div id="sendHtml" v-html="sendHtml" ></div></pre>
                <!-- whatsapp消息内容 -->
                <div class="whatsapp-content-div" v-if="'2' == info.mediaType.value">

                    <div  class="content-ac-chat-box ac-chat-box-whatsapp-template">

                        <div class="hlds-user-textbubble-whatsapp-template ng-binding ng-scope"
                            style="word-break:break-word">
                            <div class="head-body-footer-div">
                                <!-- head 文本-->
                                <div v-if="headerType == '0'" class="template-header-content">
                                    {{headerContent}}
                                </div>

                                <!-- head 视频-->
                                <div v-if="headerType == '2'" class="content-whatsapp-template-media-show"  @click="clickWhatsappVedio">
                                    <video :src="mediaUrl" class="template-image" controls="controls"
                                        controlslist="nodownload" disablepictureinpicture></video>
                                    <div class="hlds-video-shadow"></div>
                                    <img class="hlds-absolute--center" src="../../assets/image/icon_circle_pause.png"/>
                                </div>

                                <!-- head 图片-->
                                <div v-if="headerType == '1'" class="content-whatsapp-template-media-show" @click="clickWhatsappImg">
                                    <img :src="mediaUrlThumbail" class="template-image img-fit"/>
                                </div>

                                <!-- head 位置-->
                                <div class="template-local content-whatsapp-template-media-show" v-if="headerType == '3'">
                                    <a :href="detailUrl" target="_blank">
                                        <img src="../../assets/image/map-default-thumbail.png"
                                            style="height:119px;width:328px" />
                                    </a>
                                    <div class="template-local-name">{{locateName}}</div>
                                    <div class="template-local-desc">{{locateDesc}}</div>
                                    <div class="template-local-detail">
                                        <p style="float:left;">{{i18n('ccmessaging.chat.multimedia.longitude')}} :
                                            {{locateLongitude}}</p>
                                        <p style="margin-left:50%">{{i18n('ccmessaging.chat.multimedia.latitude')}} :
                                            {{locateLatitude}}</p>
                                    </div>
                                </div>

                                <!-- body -->
                                <div class="template-content">
                                    {{content}}
                                </div>

                                <!-- footer -->
                                <div class="template-foot" v-if="null != footer && '' != footer">
                                    {{footer}}</div>
                            </div>
                            <div style="border-bottom: 1px solid #EFEFEF"
                                v-if="null != buttonList && buttonList.length > 0">
                            </div>
                            <div class="button-div" v-for="(buttonItem, index) in buttonList" :key="index">
                                <a class="content-button-bottom" href="javascript:void(0);">{{buttonItem.value}}</a>
                            </div>
                        </div>
                    </div>
                </div>
                </pre>
        </div>
      </div>
    </div>

    <!--弹框-->
    <div class="hlds-chat-box" v-if="isShowBoxContent">
      <div class="image-video-show" @click="closeVideo()">
        <!--pic-->
        <img
          :src="sourceURL"
          style="
            max-height: 800px;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            top: 0;
            bottom: 0;
          "
          v-if="isImg"
        />
        <!--pic-->
        <!--video-->
        <video
          :src="sourceURL"
          autoplay
          controls
          controlslist="nodownload"
          disablepictureinpicture=""
          preload
          v-if="!isImg"
          style="
            max-height: 800px;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            top: 0;
            bottom: 0;
          "
        ></video>
        <!--video-->
      </div>
    </div>
  </sweet-dialog>
</template>
<script setup lang="ts">
import { aiccDownload } from '@/utils/aiccUploadAndDownload'
import { ElMessageBox } from 'element-plus';
import AiccElMessage from '@/utils/el-message';
import { defineProps, ref, getCurrentInstance, toRefs } from 'vue'
import { queryMessage } from '../../ccma-api'
import {
  ccmeQueryMultiMediaLibraryById,
  ccmeGetLocationUrl
} from '@/views/ccmanagement/ccma-api/otherProjectsRequest'
import { safeHtmlEncode } from '../../common/common'
import { HttpStatusCode } from "axios";
import * as xss from "xss";

const props = defineProps({
  sndMsgId: String,
  mediaType: String,
  isShow: Boolean
})

const info = toRefs<any>(props)

// 国际化封装
const {
  appContext: {
    config: { globalProperties }
  }
} = getCurrentInstance() as any
const i18n = (param: string, ...args: Array<any>): string => {
  return globalProperties.$t(param, args)
}

const messageInformationInstance = ref<any>({})
const sendHtml = ref<String>()
const mediaType = ref<String>()
const talkdata = ref()
// 地图服务url集合
const locationUrlArr = ref<Array<any>>([])
const isShowBoxContent = ref<Boolean>(false)
const isImg = ref<Boolean>(false)
const sourceURL = ref<String>()
const title = ref<String>('')
const isSuccessI18 = ref<String>()
const senderNo = ref<String>()
const smsType = ref<String>()
const receiverNames = ref<String>()
const sendTime = ref<String>()
const attachName = ref<String>()
const attachmentIds = ref<Array<any>>([])

// whatsapp
const headerType = ref<String>()
const headerContent = ref<String>()
const mediaUrl = ref<String>()
const mediaUrlThumbail = ref<String>()
const detailUrl = ref<String>()
const locateName = ref<String>()
const locateDesc = ref<String>()
const locateLongitude = ref<String>()
const locateLatitude = ref<String>()
const content = ref<String>()
const footer = ref<String>()
const buttonList = ref<Array<any>>([])
const buttonsType = ref<String>()

const getLocationUrlFunc = () => {
  ccmeGetLocationUrl().then((resp: any) => {
    if (resp) {
      queryMessageFunc()
      if (Object.keys(resp).length != 0) {
        locationUrlArr.value = resp
      }
    }
  })
}
getLocationUrlFunc()

const setSomeLocateData = (resultData: any, whatsAppTemplateContent: any) => {
  const whatsappChannel = resultData[0]['channel']
  ccmeQueryMultiMediaLibraryById({id:whatsAppTemplateContent['header']}).then((resp: any) => {
    // 位置名称
    locateName.value = resp[0].name
    // 位置详细描述
    locateDesc.value = locateFix(resp[0].description)
    // 经度
    locateLongitude.value = locateFix(resp[0].longitude)
    // 纬度
    locateLatitude.value = resp[0].latitude
    detailUrl.value = findLocationDetailUrlByCurrentUserChannel(
      whatsappChannel,
      resp[0].latitude,
      resp[0].longitude,
      resp[0].name,
      resp[0].description
    )
  })
}

const queryMessageFunc = () => {
  const params = { sndMsgId: info.sndMsgId.value, mediaType: info.mediaType.value }
  queryMessage(params).then((resp: any) => {
    if ('2' == info.mediaType.value) {
      // whatsapp消息
      whatsappMsgHandle(resp.result)
      return
    }
    messageInformationInstance.value = resp['result'][0]
    messageInformationInstance.value.sendTime = globalProperties.$TimeUtils.transTime(
      messageInformationInstance.value.sendTime
    )
    messageInformationInstance.value.isSuccessI18 =
      messageInformationInstance.value.isSuccess == '0'
        ? i18n('ccm.agent.pageurls.success')
        : i18n('ccm.callout.title.fail')
    isSuccessI18.value = messageInformationInstance.value.isSuccessI18
    sendTime.value = messageInformationInstance.value.sendTime
    var contents
    if (
      messageInformationInstance.value.mediaType == '1' &&
      (messageInformationInstance.value.smsType == '0' ||
        (messageInformationInstance.value.MessageTemplate != undefined &&
          messageInformationInstance.value.MessageTemplate.smsType == '0'))
    ) {
      let content = JSON.parse(messageInformationInstance.value.content)
      var string = ''
      if (
        messageInformationInstance.value.MessageTemplate != undefined &&
        messageInformationInstance.value.MessageTemplate.content != 'none'
      ) {
        let type = JSON.parse(messageInformationInstance.value.MessageTemplate.content)
        for (let i = 0; i < content.length; i++) {
          let description = type[i].des
          if (description == undefined) {
            description = ''
          }
          string =
            string +
            '<p>        ' +
            safeHtmlEncode(i18n('ccnotification.center.message.var.type')) +
            type[i].type +
            '；' +
            safeHtmlEncode(i18n('ccnotification.center.message.var.des')) +
            description +
            '；' +
            safeHtmlEncode(i18n('ccnotification.center.message.var.value')) +
            content[i] +
            '。</p>\r\n'
        }
      }
      contents =
        '<p><strong>' +
        safeHtmlEncode(i18n('ccnotification.notifition.template.label.templateid')) +
        '：' +
        messageInformationInstance.value.MessageTemplate.id +
        '</strong></p>\r\n' +
        '<p><strong>' +
        safeHtmlEncode(i18n('ccnotification.notifition.template.label.hwtemplateid')) +
        '：' +
        messageInformationInstance.value.smsTemplate[0].templateId +
        '</strong></p>\r\n' +
        string
    } else {
      contents = messageInformationInstance.value.content
    }

    sendHtml.value = xssHtmlEncode(contents)

    if (messageInformationInstance.value.attachName) {
      attachName.value = messageInformationInstance.value.attachName.split(',')
      messageInformationInstance.value.attachName = attachName.value
    }
    if (messageInformationInstance.value.attachmentId) {
      attachmentIds.value = messageInformationInstance.value.attachmentId.split(',')
      messageInformationInstance.value.attachmentIds = attachmentIds.value
    }
    if (messageInformationInstance.value.title) {
      title.value = messageInformationInstance.value.title
    }
    if (messageInformationInstance.value.mediaType) {
      mediaType.value = messageInformationInstance.value.mediaType
    }
    if (messageInformationInstance.value.senderNo) {
      senderNo.value = messageInformationInstance.value.senderNo
    }
    if (messageInformationInstance.value.smsType) {
      smsType.value = messageInformationInstance.value.smsType
    }
    if (messageInformationInstance.value.senderNo) {
      senderNo.value = messageInformationInstance.value.senderNo
    }
    if (messageInformationInstance.value.receiverNames) {
      const names = messageInformationInstance.value.receiverNames.split(';')
      if (names) {
        receiverNames.value = names.filter(Boolean).join(';')
      } else {
        receiverNames.value = messageInformationInstance.value.receiverNames
      }
    }
  })
}

const whatsappMsgHandle = (resultData: any) => {
  talkdata.value = resultData[0]
  setSendStatus()
  const whatsAppMessage = JSON.parse(resultData[0]['content'])
  const whatsAppTemplate = whatsAppMessage['template']
  const whatsAppTemplateContent = whatsAppMessage['content']
  content.value = whatsAppTemplateContent['bodyContent']
  footer.value = whatsAppTemplate['footer']
  buttonsType.value = whatsAppTemplate['buttonsType']
  talkdata.value['buttons'] =
    whatsAppTemplate['buttonsType'] == '0'
      ? whatsAppTemplateContent['buttons']
      : whatsAppTemplate['actionButtons']
  let buttonListTmp = []
  const buttons = talkdata.value['buttons']
  if (whatsAppTemplate['buttonsType'] == '0') {
    if (!(JSON.stringify(buttons) === '[]')) {
      for (let index = 0; index < buttons.length; index++) {
        const tempButton = {key: index, value: buttons[index]['quickReplyPayload']}
        buttonListTmp.push(tempButton)
      }
    }
  } else if (whatsAppTemplate['buttonsType'] == '1') {
    buttonListTmp = talkdata.value['buttons']
  }
  buttonList.value = buttonListTmp
  headerContent.value = whatsAppTemplateContent['header']
  headerType.value = whatsAppTemplate['headerType']
  if (whatsAppTemplate['headerType'] == '1') {
    mediaUrl.value =
      location.protocol +
      '//' +
      location.host +
      '/service-cloud/u-route/ccmessaging/getMultiMediaLibraryFile?id=' +
      whatsAppTemplateContent['header'] +
      '&isShowThumb=false'
    mediaUrlThumbail.value =
      location.protocol +
      '//' +
      location.host +
      '/service-cloud/u-route/ccmessaging/getMultiMediaLibraryFile?id=' +
      whatsAppTemplateContent['header']
  } else if (whatsAppTemplate['headerType'] == '2') {
    mediaUrl.value =
      location.protocol +
      '//' +
      location.host +
      '/service-cloud/u-route/ccmessaging/getMultiMediaLibraryFile?id=' +
      whatsAppTemplateContent['header'] +
      '&isShowThumb=false'
    mediaUrlThumbail.value =
      location.protocol +
      '//' +
      location.host +
      '/service-cloud/u-route/ccmessaging/getMultiMediaLibraryFile?id=' +
      whatsAppTemplateContent['header']
  } else if (whatsAppTemplate['headerType'] == '3') {
    setSomeLocateData(resultData, whatsAppTemplateContent)
  }
}

const setSendStatus = () => {
  if ('1' == talkdata.value.sendStatus) {
    // 发送中
    messageInformationInstance.value.isSuccessI18 = i18n('ccmessaging.message.sendstatus.pending')
    isSuccessI18.value = messageInformationInstance.value.isSuccessI18
  } else if ('2' == talkdata.value.sendStatus) {
    // 发送失败
    messageInformationInstance.value.isSuccessI18 = i18n(
      'ccmessaging.message.sendstatus.undeliverable'
    )
    isSuccessI18.value = messageInformationInstance.value.isSuccessI18
  } else if ('3' == talkdata.value.sendStatus) {
    // 已读
    messageInformationInstance.value.isSuccessI18 = i18n('ccmessaging.message.sendstatus.read')
    isSuccessI18.value = messageInformationInstance.value.isSuccessI18
  } else {
    // 已送达
    messageInformationInstance.value.isSuccessI18 = i18n(
      'ccmessaging.message.sendstatus.deliverable'
    )
    isSuccessI18.value = messageInformationInstance.value.isSuccessI18
  }
  messageInformationInstance.value.receiverNames = talkdata.value.userId
  messageInformationInstance.value.sendTime = globalProperties.$TimeUtils.transTime(
    talkdata.value.sendTime
  )
  receiverNames.value = messageInformationInstance.value.receiverNames
  sendTime.value = messageInformationInstance.value.sendTime
}

const locateFix = (x: any) => {
  if (!x) {
    return x
  }

  const decimalsArr = x.split('.')
  if (decimalsArr.length < 2) {
    return x
  }

  const decimal = decimalsArr[1]
  return decimalsArr[0] + '.' + decimal.substr(0, 6)
}

// 根据当前用户所属渠道获取地图服务url
const findLocationDetailUrlByCurrentUserChannel = (
  channel: any,
  latitude: any,
  longitude: any,
  name: any,
  desc: any
) => {
  for (const pos in locationUrlArr.value) {
    if (locationUrlArr.value[pos].channel != channel) {
      continue
    }
    const locationUrl = locationUrlArr.value[pos].locationUrl
    const mapService = locationUrlArr.value[pos].mapService
    // 谷歌地图
    if ('google' == mapService) {
      return locationUrl + '?api=1&query=' + latitude + ',' + longitude
    }
    let newDesc = desc
    // 腾讯地图
    if (!desc) {
      newDesc = name
    }
    const marker =
      'coord:' +
      latitude +
      ',' +
      longitude +
      ';title:' +
      filterSpc(name) +
      ';addr:' +
      filterSpc(newDesc)
    return locationUrl + '?marker=' + encodeURIComponent(marker) + '&referer=AICC'
  }
}

// 过滤位置特殊字符
const filterSpc = (chars: any) => {
  return chars.replaceAll(';', '')
}

//处理视频播放关闭异常
const closeVideo = () => {
  const videos = document.getElementsByTagName('video')
  for (let k = videos.length - 1; k >= 0; k--) {
    videos[k].muted = true
    videos[k].pause()
  }
  isShowBoxContent.value = false
}

const clickWhatsappImg = () => {
  sourceURL.value = mediaUrl.value
  isShowBoxContent.value = true
  isImg.value = true
}

const clickWhatsappVedio = () => {
  sourceURL.value = mediaUrl.value
  isShowBoxContent.value = true
  isImg.value = false
}

// 下载发件箱邮件附件
const download = (index: any) => {
  const id = attachmentIds.value[index]
  ElMessageBox.confirm(
    i18n('ccm.label.downloadAttachment.confirm'),
    i18n('ccm.agent.label.information'),
    {
      closeOnClickModal: false,
      type: 'warning'
    }
  ).then(() => {
    const downInfo = {
      downloadInfo: {
        id,
        messageId: info.sndMsgId?.value
      },
    }
    const serviceUrl = '/service-cloud/u-route/ccnotification/emailattachment/downLoadFileForContact'
    aiccDownload(downInfo, serviceUrl).then((resp: any) => {
      // 二进制文件下载时，是带有响应头的且为200，此时可直接返回
      if (resp.status == HttpStatusCode.Ok){
        return
      }
      const errCode: String = resp.message.substring(resp.message.indexOf('!') + 1, resp.message.indexOf(':'))
      let errMsg: string
      if (errCode == "80031001012002-1") {
        // 无下载权限
        errMsg = i18n('ccm.agent.download.forbidden')
      } else {
        errMsg = i18n('ccm.agent.contact.downloadFail')
      }
      AiccElMessage.error(errMsg)
    })
  })
}

const xssHtmlEncode = (inputStr:any) => {
  var options = {
    whiteList: {
      p: ["style"],
      div: ["id", "style"],
      s: ["style"],
      span: ["style"],
      em: ["style"],
      strong: ["style"],
      table: ["style", "border"],
      colgroup: ["style"],
      col: ["style"],
      tbody: ["style"],
      tr: ["style"],
      th: ["style"],
      td: ["style"],
      br: ["style"],
      a: ["style"],
    },
  };
  var myxss = new xss.FilterXSS(options);
  return myxss.process(inputStr);
}
</script>
<style scoped lang="less">
.chat-email-view {
  .tools-button-wrap {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
.content-ac-chat-box {
  word-break: break-all;
  display: inline-block;
  border-radius: 8px;
  max-width: 90%;
  position: relative;
  box-shadow: 0 0 3px 0 #00000026;
}

.ac-chat-box-whatsapp-template {
  box-shadow: none;
}

.hlds-user-textbubble-whatsapp-template {
  color: inherit;
  position: relative;
  border-radius: 4px;
  background-color: transparent;
  display: block;
  float: left;
  box-shadow: 0 0 3px 0 #00000026;

  &:dir(rtl) {
    float: right;
  }
}

.template-header-content {
  padding: 5px;
  font-family: PingFangSC-Medium;
  font-size: 16px;
  color: #33333d;
  font-weight: 500;
  font-weight: bold;
  max-height: 80px;
  overflow: auto;
}

.content-whatsapp-template-media-show {
  max-width: 308px;
  max-height: 308px;
  overflow: hidden;
}

.template-image {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  width: 308px;
  height: 172px;
}

.template-local {
  padding: 5px;
  border: 1px solid #00000026;
  padding-bottom: 5px;
}

.template-local-name {
  font-size: 16px;
  color: #33333e;
  font-weight: 500;
  font-weight: bold;
  padding: 3px;
}

.template-local-detail > div {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #505050;
  font-weight: 400;
  padding: 3px;
}

.template-local-desc {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #9a9a9a;
  font-weight: 400;
  padding: 3px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.template-foot {
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #778d9e;
  font-weight: 400;
  padding: 5px;
  max-height: 80px;
  overflow: auto;
}

.template-local {
  padding: 5px;
  border: 1px solid #00000026;
  padding-bottom: 5px;
}

.template-image {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.template-content {
  padding: 5px;
  max-height: 80px;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #505050;
  font-weight: 400;
  overflow: auto;
}

.template-local-name {
  font-size: 16px;
  color: #33333e;
  font-weight: 500;
  font-weight: bold;
  padding: 3px;
}

.template-local-detail > div {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #505050;
  font-weight: 400;
  padding: 3px;
}

.hlds-user-textbubble-whatsapp-template {
  color: inherit;
  position: relative;
  border-radius: 4px;
  background-color: transparent;
  display: block;
  float: left;
  box-shadow: 0 0 3px 0 #00000026;

  &:dir(rtl) {
    float: right;
  }
}

.template-header-content {
  padding: 5px;
  font-family: PingFangSC-Medium;
  font-size: 16px;
  color: #33333d;
  font-weight: 500;
  font-weight: bold;
  max-height: 80px;
  overflow: auto;
}

.content-button-bottom {
  width: 100%;
  border-bottom: 1px solid #efefef;
  border-radius: 4px;
  float: left;
  margin-top: 5px;
  text-align: center;
  cursor: pointer;
  color: #1b8adb;
  line-height: 30px;
  font-family: PingFangSC-Medium;
  font-weight: bold;
  max-height: 80px;
  overflow: auto;

  &:dir(rtl) {
    float: right;
  }
}
.whatsapp-content-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
}

.head-body-footer-div {
  flex-direction: column;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button-div {
  display: flex;
  justify-content: center;
}

.hlds-p-around--x-small {
  padding: 0.5rem;
}
.hlds-m-right--small {
  margin-inline-end: 1rem;
}
.hlds-text-link--reset,
.hlds-text-link--reset:active,
.hlds-text-link--reset:focus,
.hlds-text-link--reset:hover {
  color: inherit;
  text-decoration: inherit;
}
.hlds-text-link--reset {
  cursor: pointer;
  line-height: inherit;
  font-size: inherit;
}

.hlds-text-color--less-weak {
  color: #91989e;
}
.hlds-text--x-small {
  font-size: var(--12px-to-rem);
}

.hlds-m-top--x-small {
  margin-top: 0.5rem;
}

  .email-preview-header {
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1.5px solid #91989e7e;
    line-height: 1.5;

    .email-preview-header__title {
      width: 100%;
      font-size: var(--16px-to-rem);
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-bottom: 10px;
    }

    .email-preview-header__text {
      width: 100%;
      font-size: var(--12px-to-rem);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-bottom: 10px;
    }

    .email-preview-header__attachment {
      width: 100%;
      font-size: 0.75rem;
      display: flex;
      align-items: center;

      .attachment-list {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .icon-button {
        font-size: var(--12px-to-rem) !important;
        padding-left: 1rem;
      }
    }
  }

  .email-preview-content {
    padding-top: 1rem;
    width: 100%;
    height: 25rem;
    overflow: auto;
    font-family: 'Arial';
    font-size: 1rem;
  }
  .aicc-dialog {
    width: 1248px;
    height: 740px;
  }

  .hlds-text-color--less-weak {
    color: #91989e;
    display: inline-block;
    padding-inline-end: 15px;
  }
  .hlds-m-left--small {
    color: #0ea5f3;
    text-decoration: none;
    transition: color 0.1s linear;
    padding-inline-end: 10px;
  }
}
.hlds-chat-box {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;

  &:dir(rtl) {
    left: unset;
    right: 0;
  }
}
.image-video-show {
  position: fixed;
  z-index: 1000;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.hlds-video-shadow {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  display: none;

  &:dir(rtl) {
    left: unset;
    right: 0;
  }
}

.hlds-media-box:hover .hlds-video-shadow {
  display: block;
}
.hlds-absolute--center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);

}
.img-fit{
  object-fit: cover;
  max-height: 100%
}
.email-appendixfile {
  display: flex;
}
</style>