export default {
  "provision.callcenterinstanceslist.table.ccName": "Call Center Name",
  "provision.callcenterinstanceslist.table.callcenterinstanceslist": "Call Center List",
  "provision.common.title.tipsInfo": "Information",
  "provision.callcenterinstanceslist.table.gwIp": "CC-Gateway IP",
  "provision.callcenterinstanceslist.table.gwPort": "CC-Gateway Port",
  "provision.callcenterinstanceslist.table.wasip": "Set Server IP",
  "provision.callcenterinstanceslist.table.ccId": "Call Center ID",

  "provision.callcenterinstanceslist.table.agentMaxCapacity": "Agent System Capacity",
  "provision.callcenterinstanceslist.table.operate": "Operation",
  "provision.callcenterinstanceslist.table.wasport": "Set the port number of the server.",
  "provision.callcenterinstanceslist.table.curCapacityProportion": "Actual Capacity Proportion",
  "provision.callcenterinstanceslist.table.create": "New",
  "provision.callcenterinstanceslist.table.agentUseRate": "Voice Agent Usage",
  "provision.callcenterinstanceslist.table.update": "Edit",
  "provision.callcenterinstanceslist.table.search": "Query",
  "provision.callcenterinstanceslist.table.delete": "Delete",
  "provision.callcenterinstanceslist.table.refresh": "Refresh",
  "provision.callcenterinstanceslist.table.deleteConfimMessage": "Are you sure you want to delete the selected call center and related configuration?",
  "provision.callcenterinstanceslist.table.synchronize": "Sync",
  "provision.callcenterinstanceslist.table.deleteconfim": "Confirm Delete",
  "provision.callcenterinstanceslist.table.updateFault": "Are you sure set virtual cell center fault voice",
  "provision.callcenterinstanceslist.table.obssyncConfimMessage": "Are you sure to synchronize OBS configuration?",
  "provision.callcenterinstanceslist.table.hasVcallcenterConfim": "The call center that has been configured with the virtual call center cannot be deleted.",
  "provision.callcenterinstanceslist.table.hasaccesscodeAndivrConfim": "The deletion fails. There is an access code or IVR in the call center. You need to delete it manually.",
  "provision.callcenterinstanceslist.table.obssyncconfim": "Confirm Sync",
  "provision.callcenterinstanceslist.table.resourceinfo": "Resource Info",
  "provision.callcenterinstanceslist.table.baseinfo": "Basic Info",
  "provision.callcenterinstanceslist.table.agentCurCapacity": "Voice Agent Quantity",
  "provision.callcenterinstanceslist.table.videoAgentCurCapacity": "Video Agent Quantity",
  "provision.callcenterinstanceslist.table.maxVideoCallnums": "Max. Concurrent Video Calls",
  "provision.callcenterinstanceslist.table.maxcallnums": "Max. Concurrent Voice Calls",
  "provision.callcenterinstanceslist.table.videoIvrChannels": "Video IVR Channel Quantity",
  "provision.callcenterinstanceslist.table.videoAgentChannels": "Video Agent Channel Quantity",
  "provision.callcenterinstanceslist.table.ivrchannels": "Audio IVR Channel Quantity",
  "provision.callcenterinstanceslist.table.eMediaAgentCurCapacity": "Multimedia Agent Quantity",
  "provision.callcenterinstanceslist.table.ttschannelnums": "TTS Quantity",
  "provision.callcenterinstanceslist.table.ctinodesec": "CTI node 2 information",
  "provision.callcenterinstanceslist.table.asrchannelnums": "ASR Quantity",
  "provision.callcenterinstanceslist.table.nodemodeselect": "Select Mode",
  "provision.callcenterinstanceslist.table.nodemodesingle": "Single mode",
  "provision.callcenterinstanceslist.table.nodeid": "Node ID",
  "provision.callcenterinstanceslist.table.nodename": "Node Name",
  "provision.callcenterinstanceslist.table.ctinodefir": "CTI node information",
  "provision.callcenterinstanceslist.table.nodemoderesource": "Resource mode",
  "provision.callcenterinstanceslist.table.uapId": "UAP ID",
  "provision.callcenterinstanceslist.table.nodemodepool": "Pool mode",
  "provision.callcenterinstanceslist.table.maxAmsCallNums": "Max. Concurrent Anonymous Calls",
  "provision.callcenterinstanceslist.table.allowedTTSAndASRCallNum": "Number of intelligent IVR channels",
  "provision.callcenterinstanceslist.table.nodepolicyconfirmmessage": "Are you sure you want to synchronize node policy configurations?",
  "provision.callcenterinstanceslist.table.versatileAgentCurCapacity": "Versatile Agents",

  "provision.callcenterinstanceslist.nodepolicy.VNDId": "VDN Id",
  "provision.callcenterinstanceslist.nodepolicy.tenantspacename": "VDN name",
  "provision.callcenterinstanceslist.nodepolicy.save": "save",
  "provision.nodepolicy.sync.success": "Synchronization succeeded.",
  "provision.nodepolicy.sync.failed": "Synchronization failed.",
  "provision.nodepolicy.save.success": "Saved successfully.",

  "provision.nodepolicy.save.failed": "Saving failed.",
  "provision.callcenterinstanceslist.label.maxcallnums": "Max. Concurrent Voice Calls",
  "provision.callcenterinstanceslist.label.callnumUseRate": "Concurrent Voice Call Usage",
  "provision.callcenterinstanceslist.label.videoAgentCurCapacity": "Video Agent Quantity",
  "provision.callcenterinstanceslist.label.eMediaAgentCurCapacity": "Multimedia Agent Quantity",
  "provision.callcenterinstanceslist.label.videoAgentUseRate": "Video Agent Usage",
  "provision.callcenterinstanceslist.label.eMediaAgentUseRate": "Multimedia Agent Usage",
  "provision.callcenterinstanceslist.label.videoIvrChannelUseRate": "Video IVR Channel Usage",
  "provision.callcenterinstanceslist.label.maxVideoCallnums": "Max. Concurrent Video Calls",
  "provision.callcenterinstanceslist.label.videoCallnumUseRate": "Concurrent Video Call Usage",
  "provision.callcenterinstanceslist.label.videoAgentChannelUseRate": "Video Agent Channel Usage",
  "provision.callcenterinstanceslist.label.amsCallNumUseRate": "Concurrent Anonymous Call Usage",
  "provision.callcenterinstanceslist.label.ivrchannelUseRate": "Audio IVR Channel Usage",
  "provision.callcenterinstanceslist.label.maxAmsCallNums": "Concurrent Anonymous Calls",
  "provision.callcenterinstanceslist.label.ttschannelUseRate": "TTS Usage",
  "provision.callcenterinstanceslist.label.asrchannelUseRate": "ASR Usage",

  "provision.callcenterinstanceslist.callcenter": "Call Center",
  "provision.callcenterinstanceslist.config.title": "Call Center Configuration",
  "provision.callcenterinstanceslist.config.warning": "Warning",
  "provision.callcenterinstanceslist.config.serviceIP": "Nginx IP",
  "provision.callcenterinstanceslist.config.servicePort": "Nginx Port",
  "provision.callcenterinstanceslist.config.odfsconfig": "ODFS Server Configuration",
  "provision.callcenterinstanceslist.config.nmsserviceIP": "ODFS IP Address",
  "provision.callcenterinstanceslist.config": "Call Center",
  "provision.callcenterinstanceslist.config.nmsservicePort": "ODFS Port Number",
  "provision.callcenterinstanceslist.config.odfsappkey": "ODFS AppKey",
  "provision.callcenterinstanceslist.config.odfsappsercret": "ODFS AppSercret",
  "provision.callcenterinstanceslist.config.odfsvalidate": "All ODFS service configurations must be empty or not empty.",
  "provision.callcenterinstanceslist.config.wnFrom": "Start Employee ID",
  "provision.callcenterinstanceslist.config.odfconfigerror": "ODFS service configuration information is incorrect.",
  "provision.callcenterinstanceslist.config.adminWnTo": "Administrator end employee ID",
  "provision.callcenterinstanceslist.config.adminWnFrom": "Administrator start employee ID",
  "provision.callcenterinstanceslist.config.sysAdminpassword": "CTI Administrator Password",
  "provision.callcenterinstanceslist.config.interfacepassword": "CTI ConfigInterface Password",
  "provision.callcenterinstanceslist.config.passwordConfirm": "Confirm Password",
  "provision.callcenterinstanceslist.config.nextStep": "Next",
  "provision.callcenterinstanceslist.config.wnTo": "End Employee ID",
  "provision.callcenterinstanceslist.config.systemAdmin": "CTI Administrator ID",
  "provision.callcenterinstanceslist.config.previousStep": "Previous",

  "provision.callcenterinstanceslist.config.saveAndnextStep": "Save and Next",
  "provision.callcenterinstanceslist.config.createsuccess": "The call center is created successfully.",
  "provision.callcenterinstanceslist.config.success": "Success",
  "provision.callcenterinstanceslist.config.updatesuccess": "The call center is modified successfully.",
  "provision.callcenterinstanceslist.config.updatefailed": "Failed to modify the call center.",
  "provision.callcenterinstanceslist.config.createfailed": "Failed to create a call center.",
  "provision.callcenterinstanceslist.config.alreadyinuse": "The employee ID segment has been used.",
  "provision.callcenterinstanceslist.config.wnFromValidate": "The sum of the start number segment and the actual system capacity cannot be greater than 50000.",
  "provision.callcenterinstanceslist.config.agentCurCapacityValidate": "The number of voice agents is less than the number of voice seats actually assigned by the call center.",
  "provision.callcenterinstanceslist.config.error": "Error",
  "provision.callcenterinstanceslist.config.ccNameUsed": "The call center name has been used.",
  "provision.callcenterinstanceslist.config.videoAgentCurCapacityValidate": "The number of video seats is less than the number of video seats actually allocated in the call center.",
  "provision.callcenterinstanceslist.config.maxVideoCallnumsValidate": "The maximum number of video call concurrency is less than the number of video calls actually allocated by the call center.",
  "provision.callcenterinstanceslist.config.ivrchannelsValidate": "The number of audio IVR channels is smaller than the number of channels actually allocated in the call center.",
  "provision.callcenterinstanceslist.config.ttschannelnumsValidate": "The number of TTS channels is smaller than the number of channels actually allocated in the call center.",
  "provision.callcenterinstanceslist.config.asrchannelnumsValidate": "The number of ASR channels is smaller than the number of channels actually allocated in the call center.",
  "provision.callcenterinstanceslist.config.videoIvrchannelsValidate": "The number of video IVR channels is smaller than the number of channels actually allocated in the call center.",
  "provision.callcenterinstanceslist.config.eMediaAgentCurCapacityValidate": "The number of multimedia agents is less than the number of multimedia seats actually allocated in the call center.",
  "provision.callcenterinstanceslist.config.resourceError": "The modified resource information is smaller than the actual number of call centers.",
  "provision.callcenterinstanceslist.config.maxcallnumsValidate": "The maximum number of concurrent voice calls is less than the number of voice calls actually assigned by the call center.",
  "provision.callcenterinstanceslist.config.ccIdError": "The call center number has been used.",
  "provision.callcenterinstanceslist.config.videocallnumsError": "The maximum number of concurrent video calls must be greater than or equal to the number of video seats.",
  "provision.callcenterinstanceslist.config.videoAgentChannelsValidate": "The number of video agent channels is smaller than the number of channels actually allocated in the call center.",
  "provision.callcenterinstanceslist.config.ccNameUpdateError": "Do not change the call center name when editing the call center",
  "provision.callcenterinstanceslist.config.ccIdUpdateError": "Do not change the call center ID when editing the call center",
  "provision.callcenterinstanceslist.config.ivrnumsError": "The number of IVR channels must be greater than the sum of the number of TTS channels and the number of ASR channels.",
  "provision.callcenterinstanceslist.config.voicecallnumsError": "The maximum number of concurrent voice calls must be greater than or equal to the sum of voice agents\, voice IVR channels\, and video IVR channels.",
  "provision.callcenterinstanceslist.config.adminWorkNoError": "The start number of the administrator employee ID must range from 55001 to 59999.",
  "provision.callcenterinstanceslist.config.workAlreadyOccupied": "The employee ID is in use",
  "provision.callcenterinstanceslist.config.workNoError": "The start employee ID must be in the range of 101 to 50000.",
  "provision.callcenterinstanceslist.config.workNoCheckError": "The start number of the work ID must be smaller than the end number.",
  "provision.callcenterinstanceslist.config.nodeIdCheckError": "Ensure that the entered CTI node information ID\, uapId\, and name are unique.",
  "provision.callcenterinstanceslist.config.adminWorkAlreadyOccupied": "The administrator employee ID is in use",
  "provision.callcenterinstanceslist.config.confirm": "Configure the information about the nodes related to the WAS platform.",
  "provision.callcenterinstanceslist.config.maxAmsCallNumsValidate": "The maximum number of concurrent anonymous calls cannot be less than the number of anonymous calls allocated by the call center.",
  "provision.callcenterinstanceslist.config.uapId": "This value must be the same as the UAP ID on the WAS.",

  "provision.callcenterinstance.config.maxcallnumsCompareValidate": "The maximum number of concurrent voice calls must be greater than or equal to the sum of voice agents\, voice IVR channels\, and video IVR channels.",
  "provision.callcenterinstance.config.ivrchannelsValidate": "The sum of the number of audio and video IVR channels must be greater than or equal to the sum of the number of TTS channels and the number of ASR channels.",
  "provision.callcenterinstance.config.videoCallValidate": "The maximum number of concurrent video calls must be greater than or equal to the number of video seats.",
  "provision.callcenterinstanceslist.vcallcenter.accessCode": "Access Code",
  "provision.callcenterinstanceslist.vcallcenter.vccName": "VDN Name",
  "provision.callcenterinstanceslist.vcallcenter.nodepolicy": "Configuring the node selection policy for outgoing call resources",
  "provision.callcenterinstanceslist.vcallcenter.SIPServer": "SBC Server",
  "provision.callcenterinstanceslist.vcallcenter.accessCodeConfig": "AccessCode Configuration",
  "provision.callcenterinstanceslist.vcallcenter.SIPServer.title": "SBC Server Configuration",
  "provision.callcenterinstanceslist.vcallcenter.ctiInfo ": " CTI Resource Group ID",
  "provision.callcenterinstanceslist.vcallcenter.vcallcenter": "Virtual Call Center",
  "provision.callcenterinstanceslist.vcallcenterDeatil.defaultCallerNum": "Default Calling Number",
  "provision.callcenterinstanceslist.vcallcenterDeatil.sipServerIpAndPort": "SBC address",
  "provision.callcenterinstanceslist.vcallcenterDeatil.passValidateFailed": `The password must contain at least three types of the following: uppercase letters\, lowercase letters\, digits\, and following special characters: ~ ! {'@'} # $ % ^ & * () - _ = + [  ] ; : \" \" \\ , < . > / ?`,
  "provision.callcenterinstanceslist.vcallcenterDeatil.save": "Save",
  "provision.callcenterinstanceslist.vcallcenterDeatil.saveerror": "Saving failed.",
  "provision.callcenterinstanceslist.vcallcenterDeatil.specialStrValidateFailed": "The call center name cannot contain special characters.",
  "provision.callcenterinstanceslist.vcallcenterDeatil.passLengthValidateFailed": "A password contains a minimum of eight characters.",
  "provision.callcenterinstanceslist.vcallcenterDeatil.odfsStrValidateFailed": "Only letters and digits are allowed.",
  "provision.callcenterinstanceslist.vcallcenterDeatil.odfsPasswordKeyLengthValidateFailed": "The length cannot exceed 50 characters.",
  "provision.callcenterinstanceslist.vcallcenter.OBSServer": "OBS Outbound Call Server",
  "provision.callcenterinstanceslist.softPhone.softPhoneNum.title": "Softphone Configuration",
  "provision.callcenterinstanceslist.softPhone.softPhoneNum": "Softphone Number",
  "provision.callcenterinstanceslist.softPhone.softPhoneAdd": "New Softphone Number",
  "provision.callcenterinstanceslist.softPhone.newpassword": "Softphone new password",
  "provision.callcenterinstanceslist.softPhone.passwordconf": "Confirm Password",
  "provision.callcenterinstanceslist.softPhone.password": "Password",
  "provision.callcenterinstanceslist.softPhone.modifyPassword": "change Password",
  "provision.callcenterinstanceslist.softPhone.tenantname": "Tenant name",
  "provision.callcenterinstanceslist.softPhone.newpasswordconf": "Repeat new password",
  "provision.callcenterinstanceslist.softPhone.singledeleteconfim": "Are you sure you want to delete the selected softphone numbers? Ensure that the softphone number is not associated with any virtual call center.",
  "provision.callcenterinstanceslist.softPhone.batchdeleteconfim": "Are you sure you want to delete all selected softphone numbers? Ensure that the softphone number is not associated with any virtual call center.",
  "provision.callcenterinstanceslist.softPhone.accountpassword": "account password",
  "provision.callcenterinstanceslist.softPhone.stautus.unoccupied": "Unused",
  "provision.callcenterinstanceslist.softPhone.stautus.occupied": "In use",
  "provision.callcenterinstanceslist.softPhone.passLengthValidateFailed": "The password must contain 8 to 16 characters.",
  "provision.callcenterinstanceslist.softPhone.stautus.select": "Select the status",
  "provision.callcenterinstanceslist.softPhone.passValidateFailed": "Must contain at least 3 of uppercase letters\, lowercase letters\, numbers\, special characters ~{'@'}^*_+.?.",
  "provision.callcenterinstanceslist.softPhone.beginNumber": "Start Number",
  "provision.callcenterinstanceslist.softPhone.batchadd": "Batch Add",
  "provision.callcenterinstanceslist.softPhone.endNumber": "End Number",
  "provision.callcenterinstances.agentWasPassLengthValidateFailed": "The password must contain 8 to 32 characters.",
  "provision.callcenterinstance.softPhone.validateSoftPhoneNumrange": "The range of newly added numbers cannot exceed 1000",
  "provision.callcenterinstance.softPhone.batchcreate.fail.repeated": "All numbers in the new number segment already exist.",
  "provision.callcenterinstance.softPhone.length.Inconsistent": "The lengths of the start number and end number are inconsistent.",
  "provision.callcenterinstanceslist.sipServer.sipServerIp": "SBC Server IP Address",
  "provision.callcenterinstance.softPhone.validatebeginSoftPhoneNum": "Softphone start number cannot be greater than end number",
  "provision.callcenterinstanceslist.sipServer.sipServerIpAdd": "Add",
  "provision.callcenterinstanceslist.sipServer.sipServerIpUpdate": "SIP Server Configuration",
  "provision.callcenterinstanceslist.sipServer.sipServerType": "SBC Server Type",
  "provision.callcenterinstanceslist.sipServer.idleRegAgentNum": "Remaining Concurrency",
  "provision.callcenterinstanceslist.sipServer.name": "Name",
  "provision.callcenterinstanceslist.sipServer.sipServerPort": "SBC Port Number",
  "provision.callcenterinstanceslist.sipServer.tenantname": "Tenant name",
  "provision.callcenterinstanceslist.sipServer.description": "Description",
  "provision.callcenterinstanceslist.sipServer.batchdeleteconfim": "Are you sure you want to delete all selected registration servers?",
  "provision.callcenterinstanceslist.sipServer.singledeleteconfim": "Are you sure you want to delete the selected registration server?",
  "provision.ccworknos.table.ccworknos": "Employee ID",
  "provision.ccworknos.table.ccworkStartNo": "Start Employee ID",
  "provision.callcenterinstanceslist.sipServer.maxRegAgentNum": "Number of Concurrent Requests",
  "provision.ccworknos.table.ccworkQuantity": "Number Quantity in Number Segment",
  "provision.callcenterinstanceslist.obsServer.obsServerIpUpdate": "Modify",
  "provision.ccworknos.table.ccworkEndNo": "End Employee ID",
  "provision.callcenterinstanceslist.obsServer.obsServerIpAdd": "Add",
  "provision.callcenterinstanceslist.obsServer.obsServerIpSync": "Sync",
  "provision.callcenterinstanceslist.obsServer.progId": "prog ID",
  "provision.callcenterinstanceslist.obsServer.obsId": "OBS ID",
  "provision.callcenterinstanceslist.obsServer.obsName": "OBS name",
  "provision.callcenterinstanceslist.obsServer.activeObsServerIp": "Active Obs Server Ip",
  "provision.callcenterinstanceslist.obsServer.activeObsDirectHeartbeatIp": "Specifies the heartbeat IP address of the active OBS",
  "provision.callcenterinstanceslist.obsServer.standbyObsDirectHeartbeatIp": "Specifies the heartbeat IP address of the standby OBS",
  "provision.callcenterinstanceslist.obsServer.heartbeatPort": "Heartbeat Port",
  "provision.callcenterinstanceslist.obsServer.standbyObsServerIp": "Standby Obs Server Ip",
  "provision.callcenterinstanceslist.obsServer.config.explain": "The value must be the same as the OBS configuration on the CTI platform.",
  "provision.callcenterinstanceslist.obsServer.batchdeleteconfim": "Are you sure you want to delete all selected registration obs servers?",
  "provision.callcenterinstanceslist.obsServer.singledeleteconfim": "Are you sure you want to delete the selected registration obs server?",
  "provision.callcent.error.deleteobsserver.associationvcallcener": "The selected obs server number is associated with the tenant and cannot be deleted.",
  "provision.callcent.error.deleteobsserverdesinfo": "Ensure that the obs server name does not exist.",
  "provision.callcent.error.deleteobsserver.part.associationvcallcener": "Some of the selected obs server are associated with the tenant and cannot be deleted.",
  "provision.callcent.selectobsserver": "Please Select a Obs Server.",
  "provision.callcent.error.ipValidateFailed": "ip address is validate.",

  "provision.callcent.error.addcallcenterdesinfo": "Ensure that the callcenter does not exist.",
  "provision.callcent.error.portoutsiderange": "HeartbeatPort is outside the range of 1 to 65535.",
  "provision.callcent.error.paramUnique": "Unique param has exist.",
  "provision.callcent.error.specialStrValidateFailed": "The obs name cannot contain special characters.",

  "provision.callcent.error.portValidateFailed": "HeartbeatPort is not number.",
  "provision.callcent.error.paramNotavailable": "Request parameter is empty.",
  "provision.accesscode.table.accessCode": "Access Code",
  "provision.callcent.error.modifyParamUnique": "Active ObsServerIp has exist.",
  "provision.accesscode.table.tenantname": "Tenant",
  "provision.accesscode.table.n400Number": "Service Number",
  "provision.ccworknos.table.vCCInstId": "Virtual Call Center",
  "provision.accesscode.table.mediatypeId": "Media Type",
  "provision.accesscode.table.acId": "Access Code Number",
  "provision.accesscode.table.multimedia": "multimedia",
  "provision.callcentaccesscodes.table.callcentaccesscodes": "Access Code",
  "provision.accesscode.table.audioAndvideo": "audio and video",
  "provision.accesscode.table.accessCode.selecttype": "Select a type",
  "provision.accesscode.table.accessCode.clicktodial": "click to dial",
  "provision.accesscode.table.whetherSupportTrialUse": "Support Trial Use",
  "provision.accesscode.table.endNumber": "End access code",
  "provision.accesscode.table.whetherSupportTrialUse_Y": "Yes",
  "provision.accesscode.table.beginNumber": "Start Access Code",
  "provision.callcenterinstance.accesscode.validateaccesscode": "Enter a correct number segment.",
  "provision.callcenterinstance.accesscode.validateaccesscoderange": "A maximum of 100 access codes can be added in batches.",
  "provision.callcenterinstance.accesscode.validatebeginaccesscode": "The start number of the access code cannot be greater than the end number.",
  "provision.callcenterinstance.accesscode.batchcreate.fail.repeated": "All access codes in the new number segment already exist.",
  "provision.accesscode.table.whetherSupportTrialUse_N": "No",
  "provision.callcenterinstance.accesscode.batchcreate.fail.processingtask": "Processing the task of creating access codes in batches",
  "provision.callcent.selectvcallcent": "Select a virtual call center.",

  "provision.callcent.selectcallcent": "Select a call center.",
  "provision.callcent.selectaccesscode": "Select an access code.",
  "provision.callcent.selectamsserver.inconsistenttype": "Anonymous call server type is not consistent",
  "provision.callcent.selectsipserver.inconsistenttype": "Agent registration server type is not consistent",
  "provision.callcent.selectsoftnumber": "Select a softphone number.",
  "provision.callcent.selectsipserver": "Select a agent registration server.",
  "provision.callcent.label.openFaule": "OpenFault",
  "provision.callcent.label.closeFaule": "CloseFault",
  "provision.callcent.label.cancel": "Cancel",
  "provision.callcent.label.close": "Close",
  "provision.callcent.label.submit": "Submit",
  "provision.callcent.label.finish": "Complete",
  "provision.callcent.label.reset": "Reset",
  "provision.callcent.success.save": "Success",
  "provision.callcent.success.saveinfo": "Created successfully.",
  "provision.callcent.error.errorinfo101": "ccm synchronization failed.",
  "provision.ccworknos.table.creataccessCode": "New",
  "provision.callcent.error.errorinfo": "Creation failed.",
  "provision.callcent.error.save": "Error",
  "provision.callcent.success.updateinfo": "Updated successfully.",
  "provision.callcent.success.delete": "Deletion Success",
  "provision.callcent.error.updateinfo": "Update failed.",
  "provision.ccworknos.table.updateaccessCode": "Edit",
  "provision.callcent.error.setinfo": "Set failed.",
  "provision.callcent.success.deleteinfo": "Deleted successfully.",
  "provision.callcent.error.partSetinfo": "Select part set failed.",
  "provision.callcent.error.delete": "Deletion Failure",
  "provision.callcent.error.deleteinfo": "Deletion failed.",
  "provision.callcent.error.partdeleteinfo": "The content associated with a virtual call center cannot be deleted.",
  "provision.callcenterccaccesscodeslist.table.batchdeleteconfim": "Are you sure you want to delete all selected access codes? Ensure that the access code is not associated with a virtual call center.",
  "provision.callcenterccaccesscodeslist.table.singledeleteconfim": "Are you sure you want to delete the selected access code? Ensure that the access code is not associated with a virtual call center.",
  "provision.callcent.success.setinfo": "Set successfully.",
  "provision.callcent.error.saveccaccesscodeinfo": ". Ensure that the access code does not exist.",
  "provision.callcent.error.savesipserverdesinfo": ". Ensure that the sbc server name does not exist.",
  "provision.callcent.error.savesipserverdesinfo.regAgentNum": ". The number of concurrent registered agents is different from the number of remaining registered seats.",
  "provision.callcent.error.savesipserverdesinfo.maxRegAgentNumError": ". The number of modified concurrent registered agents is smaller than the actual number of registered seats.",
  "provision.callcent.success.oj8k": "Complete",
  "provision.callcent.error.savesipserverdesinfo.regAgentNumError": "The number of modified concurrent registered agents is smaller than the actual number of registered seats.",
  "provision.callcent.error.savesipserverdesinfo.sipServernoexist": "\, sbc server does not exist",
  "provision.callcent.error.savesipserveripinfo": ". Ensure that the IP address and port number of the server do not exist.",
  "provision.callcent.error.createaccesscode.fail.onccgateway": "\, adding an access code to the platform failed.",
  "provision.callcent.error.updateSoftNumPasswordInfo": "Account information error",
  "provision.callcent.error.repetenter": "The number has been used. Enter another number again.",
  "provision.callcent.error.createaccesscode.validate.servicenumberContainServicenumber": "The service number cannot have an inclusion relationship with other service numbers under the call center.",
  "provision.callcent.error.createaccesscode.n400Numbervalidate": "Access code and service number cannot exist inclusive relationship.",
  "provision.callcent.error.createaccesscode.validate.accessCodeContainAccesscode": "The access code cannot contain an inclusion relationship with other access codes under the call center.",
  "provision.callcent.error.createaccesscode.fail.ondatabase": "\, creating an access code in the database failed",
  "provision.callcent.error.edit": "Editing failed.",

  "provision.callcent.error.updateerrorinfo": "The access code has been associated with a virtual call center and cannot be edited.",
  "provision.callcent.error.updateaccesscode.fail.accesscodenoexist": "\, Check whether the access code exists and is associated with a virtual call center.",
  "provision.callcent.error.updateaccesscode.fail.syncccmanagement": "\, synchronous modification of information to the rental room failed",
  "provision.callcent.error.updateaccesscode.fail.ondatabase": "\, failed to modify the access code in the database",
  "provision.callcent.error.accesscode.deletefail.sqlexception": "Delete failed\, sql execution exception",
  "provision.callcent.error.accesscode.deletefail": "Delete failed\, the entry is empty",
  "provision.callcent.error.accesscode.deletefail.accesscodenoexist": "Delete failed\, access code does not exist",
  "provision.callcent.error.accesscode.deletefail.ondatabase": "Failed to delete access code in database",
  "provision.callcent.error.accesscode.deletefail.all.onccgateway": "Access code failed to be deleted on the was platform",
  "provision.callcent.error.accesscode.deletefail.all.onccgateway.with.associationvcallcener": "All deletion failed because part of the access code is associated with the tenant\, and some access codes are deleted on the was platform.",
  "provision.callcent.error.accesscode.deletefail.part.associationvcallcener": "Some access codes are associated with tenants and cannot be deleted.",
  "provision.callcent.error.accesscode.deletefail.part.onccgateway": "Part of the access code failed to delete on the was platform.",
  "provision.callcent.error.accesscode.deletefail.all.associationvcallcener": "The access code is associated with the tenant and cannot be deleted.",
  "provision.callcent.error.accesscode.deletefail.part.onccgateway.with.associationvcallcener": "Partial deletion failed because part of the access code is associated with the tenant\, and part of the access code failed to be deleted on the was platform.",
  "provision.callcent.error.deletesoftphonenumber.part.associationvcallcener": "Some of the selected softphone numbers are associated with the tenant and cannot be deleted.",
  "provision.accesscode.error.transfer.queryinterface": "Query platform data failed.",
  "provision.callcent.error.deletesoftphonenumber.associationvcallcener": "The selected soft phone number is associated with the tenant and cannot be deleted.",
  "provision.callcent.error.updatesoftphonenumber.ondatabase": "Failed to modify softphone number in database.",
  "provision.callcent.success.deletesoftphonenumber.processed": "Deleting data from the background... Please check later.",
  "provision.callcent.success.deletesoftphonenumber.unfinishtask": "A deletion task is being processed. Please try again later.",
  "provision.callcent.error.updatesoftphonenumber.fail.syncccmanagement": "Synchronizing softphone to modify information to the rental room failed.",
  "provision.callcent.error.updatesoftphonenumber.noexist": "Soft phone number does not exist.",
  "provision.callcent.error.updatesipserver.ondatabase": "Modifying the SBC server in the database failed.",
  "provision.callcent.error.updatesipserver.ipandport.alreadyexist": "The SBC server IP and port number already exist and cannot be modified.",
  "provision.callcent.error.updatesipserver.fail.syncccmanagement": "Synchronizing the SBC server to modify the information to the rental room failed.",
  "provision.callcent.error.updatesipserver.noexist": "SIP server does not exist.",
  "provision.callcent.error.deletesipserver.associationvcallcener": "The selected SIP server is associated with tenant and cannot be deleted.",
  "provision.callcent.error.deletesipserver.part.associationvcallcener": "Some SIP servers selected are associated with tenants and cannot be deleted.",
  "provision.tenantspace.table.tenantSpaceName": "Tenant Space",
  "provision.callcent.error.checkparam": "Contains invalid characters(\"<\"\\,\">\"\\,\"\\\"and more)",

  "provision.tenantspace.table.chooseStatus": "Tenant space status",
  "provision.tenantspace.table.tenantSpaceStatus": "Status",
  "provision.tenantspace.vitualCenterName": "Virtual Call Center",
  "provision.tenantspace.reset": "Reset",
  "provision.tenantspace.search": "Query",
  "provision.tenantspace.config.agentUsage": "Agents",
  "provision.tenant.management.adminaccount": "Administrator Account",
  "provision.tenant.management.tenantname": "Tenant Space",
  "provision.tenant.management.email": "Email",
  "provision.tenant.management.adminpasswordagain": "Confirm Password",
  "provision.tenant.management.phonenumber": "Phone Number",
  "provision.tenant.management.tenantspaceid": "Tenant Space ID",
  "provision.tenant.management.adminpassword": "Password",
  "provision.tenant.management.partdbId": "Database partition ID",
  "provision.tenant.management.mobile": "Mobile Number",
  "provision.tenant.management.adminname": "Administrator",
  "provision.tenant.management.agentnum": "Agents",
  "provision.tenant.management.tenantid": "Tenant ID",
  "provision.tenant.management.tenantcreate": "New Tenant",
  "provision.tenant.management.accesscode": "Access Code",
  "provision.tenant.management.administratorinfo": "Tenant Administrator Info",
  "provision.tenant.management.tenantedit": "Edit Tenant Info",
  "provision.tenant.management.admininfo": "Register Tenant Administrator",
  "provision.tenant.management.languagetype": "Language Type",
  "provision.tenant.management.pwd.checkmsg": "The two passwords are different.",
  "provision.tenant.management.button.ok": "Complete",
  "provision.tenant.management.tenantname.minlength": "The tenant space name cannot contain less than 6 characters.",
  "provision.tenant.management.tenantname.alphavalidateFailed": "Invalid characters exist. Only letters\, digits\, and underscores (_) are supported.",
  "provision.tenant.management.select.accesscode": "Select Access Code",
  "provision.tenant.management.tenantname.beginwithalpha": "must start with a letter.",
  "provision.tenant.management.updatetenant.success": "The tenant space configuration is modified successfully.",
  "provision.tenant.management.edit.agentusage.mustinc": "The number of new agents cannot be less than that of original agents.",

  "provision.tenant.management.updatetenant.failed": "Failed to modify the tenant space configuration.",
  "provision.tenant.management.tenantcreate.emailillegal": "Invalid email address.",
  "provision.tenant.management.tenantcreate.mobileillegal": "Invalid mobile number.",
  "promotion.tenant.management.tenantedit.exception": "An exception occurs on the server.",
  "provision.tenant.management.tenantcreate.failed": "Failed to create the tenant space. Ensure that the tenant name or administrator account is unique.",
  "promotion.tenant.management.tenantedit.provisionerror": "Failed to update the tenant space.",
  "provision.tenant.management.tenantcreate.success": "The tenant space is created successfully.",
  "promotion.tenant.management.tenantedit.registerserver.notavailable": "The registration server is unavailable.",
  "promotion.tenant.management.tenantedit.vdncreate.failed": "Failed to create or modify the VDN information.",
  "promotion.tenant.management.tenantedit.softnumber.insufficient": "The number of softphones is insufficient.",
  "promotion.tenant.management.tenantedit.vdnsync.failed": "Failed to synchronize data to the virtual call center.",
  "promotion.tenant.management.tenantedit.vcccapacity.insufficient": "The capacity of the virtual center is insufficient.",
  "promotion.tenant.management.tenantedit.vccupdate.failed": "Failed to update the virtual center.",
  "promotion.tenant.management.tenantedit.accesscode.noavailable": "The access code is unavailable.",
  "promotion.tenant.management.tenantedit.cc.null": "The ID of the call center to which the virtual call center belongs is empty.",

  "provision.tenantspace.table.update": "Edit",
  "provision.tenantspace.table.delete": "Delete",
  "promotion.tenant.management.tenantedit.vcc.notexist": "The virtual call center does not exist.",
  "provision.tenantspace.refresh": "Refresh",
  "provision.tenantspace.table.config": "Configure",
  "provision.tenantspace.chooseCC": "Select a call center",
  "provision.tenantspace.table.deleteTenantSpace": "Are you sure you want to delete the specified tenant space?",
  "provision.tenantspace.ok": "OK",
  "provision.tenantspace.create": "Create",
  "provision.tenantspace.input.searchName": "Enter the name of the call center to be queried.",

  "provision.tenantspace.selectCC": "Select a call center.",
  "provision.tenantspace.input.agentnum": "Set the number of agents to be applied for.",
  "provision.tenantspace.cc.percent": "Capacity Proportion",
  "provision.tenantspace.cc.current": "Current Capacity",
  "provision.tenantspace.cc.max": "Max. Capacity",
  "provision.tenantspace.cc.currentpercent": "Current Capacity Proportion",
  "provision.tenantspace.cc.searchName": "Enter the name of the call center to be queried.",
  "provision.tenantspace.choose.accesscode": "Select Access Code",
  "provision.tenantspace.accessCode.choose": "Select a group of access codes.",
  "provision.tenantspace.save.success": "Saved successfully.",
  "provision.tenantspace.clear": "Clear",
  "provision.tenantspace.rechoose": "Randomize",
  "provision.tenantspace.select.start": "Started",
  "provision.tenantspace.save.fail": "Saving failed.",

  "provision.tenantspace.table.success": "Success",
  "provision.tenantspace.table.createStatus": "New State",
  "provision.tenantspace.select.stop": "Stopped",
  "provision.tenant.chooseCC.first": "Select a call center.",
  "provision.tenant.chooseAC.first": "Select an access code.",
  "provision.tenant.need.first": "Select the call center\, access code\, and number of agents.",

  "provision.tenantspace.table.fail": "Failure",
  "provision.tenant.no.more.agentUsage": "The number of agents is too large.",
  "provision.tenantspace.error.deleteSUM": "Failed to delete the SUM tenant space.",
  "provision.callcenterinstanceslist.config.softUsage.more": "The number of softphones is insufficient. Check the configuration.",

  "provision.tenantspace.error.deleteDDS": "Failed to delete the DDS tenant space.",
  "provision.tenantspace.config.error": "Configuration failed. Check related configurations or contact the administrator.",
  "provision.tenantspace.select.status": "Select a state.",
  "provision.tenantspace.table.maxVideoAgents": "Video Agent Quantity",
  "provision.tenantspace.table.maxCalls": "Max. Concurrent Voice Calls",
  "provision.tenantspace.table.maxAgents": "Max. Voice Agents",
  "provision.tenantspace.table.maxVideoCalls": "Max. Concurrent Video Calls",
  "provision.tenantspace.table.videoAgentChannels": "Video Agent Channel Quantity(>= Twice the Video Agent Quantity)",
  "provision.tenantspace.table.ttschannelnums": "TTS Quantity",
  "provision.tenantspace.table.videoivrchannels": "Video IVR Channel Quantity",
  "provision.tenantspace.table.asrchannelnums": "ASR Quantity",
  "provision.tenantspace.table.maxAmsCalls": "Max. Concurrent Anonymous Calls",
  "provision.tenantspace.table.ivrchannels": "Audio IVR Channel Quantity",
  "provision.tenantspace.table.maxVersatileAgents": "Max. Versatile Agents",
  "provision.tenantspace.cc.callnumUsagePercent": "Concurrent Voice Call Usage",
  "provision.tenantspace.cc.maxcallnums": "Max. Concurrent Voice Calls",
  "provision.tenantspace.cc.videoAgentCurCapacity": "Video Agent Quantity",
  "provision.tenantspace.cc.agentCurCapacity": "Used Agent Quantity",
  "provision.tenantspace.cc.maxVideoCallnums": "Max. Concurrent Video Calls",
  "provision.tenantspace.cc.maxAmsCallnums": "Max. Concurrent Anonymous Calls",
  "provision.tenantspace.cc.videoCallnumUsagePercent": "Concurrent Video Call Usage",
  "provision.tenantspace.cc.amsCallnumUsagePercent": "Concurrent Anonymous Call Usage",
  "provision.tenantspace.cc.videoAgentUsage": "Video Agent Usage",
  "provision.tenantspace.cc.videoAgentchannels": "Video Agent Channel Quantity",
  "provision.tenantspace.cc.ivrchannelUsagePercent": "Audio IVR Channel Usage",
  "provision.tenantspace.cc.videoAgentchannelUsagePercent": "Video Agent Channel Usage",
  "provision.tenantspace.cc.ivrchannels": "Audio IVR Channel Quantity",
  "provision.tenantspace.cc.videoIvrchannels": "Video IVR Channel Quantity",
  "provision.tenantspace.cc.videoIvrchannelUsagePercent": "Video IVR Channel Usage",
  "provision.tenantspace.cc.ttschannelUsagePercent": "TTS Usage",
  "provision.tenantspace.cc.ttschannelnums": "TTS Quantity",
  "provision.tenantspace.cc.asrchannelnums": "ASR Quantity",
  "provision.tenantspace.ivr.id": "No.",
  "provision.tenantspace.cc.asrchannelUsagePercent": "ASR Usage",
  "provision.tenantspace.ivr.ivrServer": "IP Address",
  "provision.tenantspace.ivr.ivrprogid": "Process ID",
  "provision.tenantspace.ivr.maxChnNum": "Concurrent Call Quantity",
  "provision.tenantspace.ivr.idlechnNum": "Remaining Concurrent Call Quantity",
  "provision.tenantspace.ivr.ivrId": "IVR ID",

  "provision.tenantspace.gw.ServerIP": "Gateway Server IP",
  "provision.tenantspace.gw.port": "Gateway Server Port",
  "provision.tenantspace.gw.idleRegAgentNum": "Remaining Concurrent Registered Agent Quantity",
  "provision.tenantspace.gw.name": "Gateway Server Name",
  "provision.tenantspace.gw.maxRegAgentNum": "Concurrent Registered Agent Quantity",

  "provision.tenantspace.sip.sipServerIP": "SIP Server IP",
  "provision.tenantspace.sip.port": "SIP Server Port",
  "provision.tenantspace.sip.maxRegAgentNum": "Concurrent Registered Agent Quantity",
  "provision.tenantspace.sip.name": "SBC Server Name",

  "provision.tenantspace.ucs.name": "CCUCS Server Name",
  "provision.tenantspace.sip.idleRegAgentNum": "Remaining Concurrent Registered Agent Quantity",
  "provision.tenant.management.tenantcreate.expiryDate": "Expiration Date",
  "provision.tenant.management.contacts": "Contact name",
  "provision.tenant.management.tenantcreate.companyName": "Company",

  "provision.tenant.management.button.cancel": "Cancel",
  "provision.tenantspace.detail": "Tenant Details",
  "provision.tenant.management.companyName": "Company",

  "provision.tenant.config.maxAgents": "Max. Voice Agents",
  "provision.tenantspace.config.maxVideoAgents": "Video Agent Quantity",
  "provision.tenantspace.config.maxCalls": "Concurrent Voice Call Quantity",
  "provision.tenantspace.config.maxVideoCalls": "Concurrent Video Call Quantity",
  "provision.tenantspace.config.ivrchannels": "IVR Channel Quantity",

  "provision.tenantspace.config.ttschannelnums": "TTS Quantity",
  "provision.tenantspace.config.chooseDefaultPhoneNumber": "Select Default Calling Number",
  "provision.tenantspace.config.chooseDriveTime": "Recording Retention Period",
  "provision.tenantspace.config.chooseGatewayServer": "please select a gateway server",
  "provision.tenantspace.config.asrchannelnums": "ASR Quantity",
  "provision.tenantspace.config.chooseDrive": "Voice Recording Drive",
  "provision.tenantspace.config.chooseUCSServer": "Select a CCUCS server.",
  "provision.tenantspace.config.isCreateAccount": "Automatically Create Account for Virtual Call Center",
  "provision.tenantspace.config.configVirtualCallCenter": "Configure Virtual Call Center",

  "provision.tenantspace.config.chooseSIPServer": "Select a SBC server.",
  "provision.tenantspace.selectStastus.config": "To be configured",
  "provision.tenantspace.selectStastus.stop": "Suspended",
  "provision.tenantspace.selectStastus.active": "Activated",
  "provision.tenantspace.selectStastus.initialize": "Initializing",
  "provision.tenantspace.selectStastus.resource.allocation.failed": "Failed to allocate the resource",
  "provision.tenantspace.selectStastus.invalid": "Disabled",
  "provision.tenantspace.selectStastus.unallocated": "Pending",
  "provision.tenantspace.language.zh": "Chinese",
  "provision.tenantspace.language.cn": "English",

  "provision.tenantspace.config.giveSource": "Allocate Resource",

  "provision.tenantspace.config.applySource": "Apply Resource",
  "provision.tenantspace.config.chooseDPN": "Select one of the access codes as the default calling number.",
  "provision.tenantspace.config.SIP": "SBC Server",
  "provision.tenantspace.config.chooseIVR": "Select an IVR process.",
  "provision.tenantspace.config.gw": "Gateway Server",
  "provision.tenantspace.config.vccName": "VCC Name",
  "provision.callcenterinstanceslist.vcallcenter.ServerNumber": "Service Number",

  "provision.tenantspace.config.IVR": "IVR Process",
  "provision.callcenterinstanceslist.IVRSprogress": "IVR process",
  "provision.callcenterinstanceslist.IVRSprogress.Title": "IVR Process Configuration",
  "ccprovision.IVRSprogress.config.button.add": "New",
  "ccprovision.IVRSprogress.config.button.async": "Sync",
  "ccprovision.IVRSprogress.config.button.edit": "Edit",
  "ccprovision.IVRSprogress.config.button.delete": "Delete",
  "ccprovision.IVRSprogress.config.progressNo": "Process ID",
  "ccprovision.IVRSprogress.config.IPAddress": "IP Address",
  "ccprovision.IVRSprogress.config.number": "No.",
  "ccprovision.IVRSprogress.config.curcallNo": "Concurrent Call Quantity",
  "ccprovision.IVRSprogress.config.leftovercurcallNo": "Remaining Concurrent Call Channel Quantity",
  "ccprovision.IVRSprogress.config.operate": "Operation",
  "ccprovision.IVRSprogress.config.button.cacel": "Cancel",
  "ccprovision.IVRSprogress.config.button.complete": "Complete",
  "provision.ivr.selectivrprogress": "Select the IVR process that you want to delete.",
  "provision.ivr.selectivrprogress.singledeleteconfim": "Are you sure you want to delete the selected IVR process?",
  "provision.ivr.selectivrprogress.batchdeleteconfim": "Are you sure you want to delete all selected IVR processes?",
  "ccprovision.IVRSprogress.config.tenantname": "Tenant",
  "provision.callcent.error.updateinfo.maxchnsmall": "The maximum number of concurrent calls cannot be less than the number of occupied channels.",
  "provision.ivr.success.sync": "Successful synchronization ",
  "provision.ivr.error.cannotdelete.occupyvdn": "The IVR assigned to the tenant cannot be deleted.",
  "provision.ivr.error.sync": "Synchronization failed",
  "provision.obs.success.sync": "Successful synchronization.",
  "provision.obs.sync.needDelete": "The following OBS has been bound to tenants and needs to be manually unbound and then synchronized:",
  "provision.callcenterinstanceslist.recorddrive.Title": "Recording Drive Letter",
  "provision.callcenterinstanceslist.vcallcenter.vdnName": "VDN Name",
  "provision.obs.error.sync": "Synchronization failed.",
  "provision.callcenterinstanceslist.vcallcenter.newvccId": "VccInstId",
  "provision.callcenterinstanceslist.vcallcenter.maxAgents": "MaxAgents",
  "provision.callcenterinstanceslist.vcallcenter.tenantSpaceName": "TenantSpaceName",
  "provision.callcenterinstanceslist.vcallcenter.maxCalls": "MaxCalls",
  "provision.callcenterinstanceslist.vcallcenter.maxVideoAgents": "MaxVideoAgents",
  "provision.callcenterinstanceslist.vcallcenter.videoAgentChannels": "VideoAgentchannels",
  "provision.callcenterinstanceslist.vcallcenter.ivrchannels": "AudioIVRchannels",
  "provision.callcenterinstanceslist.vcallcenter.videoIvrchannels": "VideoIVRchannels",
  "provision.callcenterinstanceslist.vcallcenter.ttschannelnums": "TTSchannelnums",
  "provision.callcenterinstanceslist.vcallcenter.maxVideoCalls": "MaxVideoCalls",
  "provision.callcenterinstanceslist.vcallcenter.asrchannelnums": "ASRchannelnums",
  "provision.callcenterinstanceslist.vcallcenter.open": "Open",
  "provision.callcenterinstanceslist.vcallcenter.maxMediaAgents": "MaxMediaAgents",
  "provision.callcenterinstanceslist.vcallcenter.maxAmsCalls": "Concurrent Anonymous Calls",
  "provision.callcenterinstanceslist.vcallcenter.close": "Close",
  "provision.callcenterinstanceslist.vcallcenter.faultEnable": "Fault Voice",
  "provision.tenantspace.config.success": "Tenant space configured successfully",
  "provision.callcenterinstanceslist.vcallcenter.maxVersatileAgents": "Versatile Agents",


  "provision.tenantspace.statusRecordTime.3Month": "3 Month",
  "provision.tenantspace.statusRecordTime.6Month": "6 Month",
  "provision.tenantspace.statusRecordTime.12Month": "12 Month",
  "provision.tenantspace.create.account": "Account Information",

  "provision.tenantspace.create.applyAccount": "Register an administrator for the tenant.",
  "provision.tenantspace.create.tenantName": "Tenant Name",
  "provision.tenantspace.create.tenantExpiryDate": "Tenant Expiration Time",
  "provision.tenantspace.create.basicInfo": "Basic Info",
  "provision.tenantspace.save.fail.001": "Number of voice agents\, video agent\, multimedia agent\, and IVR channels cannot be 0.",
  "provision.tenantspace.save.fail.002": "The maximum number of concurrent voice calls is greater than or equal to the number of agents and IVR channels.",
  "provision.tenantspace.save.fail.004": "The access code is empty.",
  "provision.tenantspace.save.fail.003": "The maximum number of concurrent video calls is greater than or equal to the number of video agents.",

  "provision.tenantspace.save.fail.005": "The access code is occupied.",
  "provision.tenantspace.save.fail.006": "The number of agents under the tenant cannot be greater than the number of remaining agents on the SBC server.",
  "provision.tenantspace.save.fail.008": "The softphone number is empty.",
  "provision.tenantspace.save.fail.007": "The call center does not exist.",
  "provision.tenantspace.save.fail.010": "The number of occupied number segments is less than the number of applied agents. The returned value is null.",
  "provision.tenantspace.save.fail.009": "The employee ID segment is empty.",
  "provision.tenantspace.save.fail.011": "Failed to save the virtual call center data.",
  "provision.tenantspace.save.fail.013": "Failed to update the access code data.",
  "provision.tenantspace.save.fail.012": "Failed to update the virtual call center data.",
  "provision.tenantspace.save.fail.015": "Failed to update the softphone number data.",
  "provision.tenantspace.save.fail.014": "Failed to update the employee ID data.",
  "provision.tenantspace.save.fail.016": "Failed to update the call center data.",
  "provision.tenantspace.save.fail.018": "The call center is empty.",
  "provision.tenantspace.save.fail.017": "The virtual call center is empty.",
  "provision.tenantspace.save.fail.019": "The remaining capacity of the call center is less than that of the newly allocated agents.",
  "provision.tenantspace.save.fail.022": "AgentUsage and MaxVoiceCallNumbers can not be zero.",
  "provision.tenantspace.save.fail.020": "Failed to delete the virtual call center data.",
  "provision.tenantspace.save.fail.023": "Left of AgentUsage is not enough.",
  "provision.tenantspace.save.fail.021": "Failed to update the tenant space data.",
  "provision.tenantspace.save.fail.026": "The remaining number of concurrent anonymous calls is insufficient.",
  "provision.tenantspace.save.fail.024": "Left of VidoeUsage is not enough.",
  "provision.tenantspace.save.fail.025": "Left of VoiceCallNumber is not enough.",
  "provision.tenantspace.save.fail.028": "Left of TTS Number is not enough.",
  "provision.tenantspace.save.fail.027": "Left of IVR Number is not enough.",
  "provision.tenantspace.save.fail.030": "Soft PhoneNumber is not enough.",
  "provision.tenantspace.save.fail.029": "Left of ASR Number is not enough.",
  "provision.tenantspace.save.fail.031": "Tenant reduction please operate on the agent information page.",
  "provision.tenantspace.save.fail.041": "The sum of voice, video, multimedia, and versatile agents, and audio and video IVR channels must be greater than 0.",
  "provision.tenantspace.save.fail.032": "AgentUsage + VideoUsage must be less than 10000.",
  "provision.tenantspace.save.fail.040": "Please configure the access code for the multimedia type.",
  "provision.tenantspace.save.fail.044": "Please configure the access code for the audio and video type.",
  "provision.tenantspace.save.fail.043": "Number of applications for multimedia agents greater than the number of remaining multimedia agents.",
  "provision.tenantspace.save.fail.046": "Connection to the was platform failed to delete an agent.",
  "provision.tenantspace.save.fail.045": "IVR process resources are insufficient.",
  "provision.tenantspace.save.fail.047": "CC-Gateway server has insufficient resources.",
  "provision.tenantspace.save.fail.048": "The agent ID segment that is manually allocated contains the allocated agent IDs.",
  "provision.tenantspace.save.fail.050": "The number of selected agent IDs is less than the number of new agents.",
  "provision.tenantspace.save.fail.051": "The OBS data source is not allocated.",
  "provision.tenantspace.save.fail.049": "The excluded number does not meet the requirements.",
  "provision.tenantspace.save.fail.053": "The OBS data source does not exist.",
  "provision.tenantspace.save.fail.054": "Failed to manually allocate softphone number resources.",
  "provision.tenantspace.save.fail.052": "The OBS server does not exist.",
  "provision.tenantspace.save.fail.056": "Incorrect basic tenant space information.",
  "provision.tenantspace.save.fail.062": "The multimedia access code must be configured when the chick-to-dial access code is configured.",
  "provision.tenantspace.save.fail.058": "Failed to save the data.",
  "provision.tenantspace.save.fail.055": "Pending tasks exist in the tenant space.",
  "provision.tenantspace.save.fail.057": "The resource allocated to the tenant space is abnormal.",
  "provision.tenantspace.save.fail.067": "Failed to validate the data of the third-party authentication system.",
  "provision.tenantspace.save.fail.063": "Failed to delete the IVR process.",
  "provision.tenantspace.save.fail.078add": "Failed to create the tenant space when configuring the third-party authentication system.",
  "provision.tenantspace.save.fail.078delete": "Failed to delete the tenant space when configuring the third-party authentication system.",
  "provision.tenantspace.save.fail.079": "Failed to parse local or external user information when configuring the third-party authentication system.",
  "provision.tenantspace.save.fail.078modify": "Failed to modify the tenant space when configuring the third-party authentication system.",
  "provision.tenantspace.save.fail.104-001": "The recording download function is not turned on.",
  "provision.tenantspace.save.fail.101": "Synchronization error.",
  "provision.tenantspace.save.fail.104-002": "The user has no quality inspection recording / quality inspection playback permission.",

  "provision.tenantspace.save.fail.104-003": "The file name of the failed recording deletion is invalid.",
  "provision.tenantspace.save.fail.104-005": "The recording file that failed to delete the recording does not exist.",
  "provision.tenantspace.save.fail.104-004": "Recording deletion failed without mapping drive letter.",
  "provision.tenantspace.save.fail.104-006": "Insufficient free space for recording deletion failure.",
  "provision.tenantspace.save.fail.104-008": "Recording format system parameters are not configured.",
  "provision.tenantspace.save.fail.104-009": "Recording conversion failed.",
  "provision.tenantspace.save.fail.104-010": "Recording node id parameter is not configured.",
  "provision.tenantspace.save.fail.104-011": "Recording file deletion failed.",
  "provision.tenantspace.save.fail.107-000": "The cached login information is invalid\, you need to re-login authentication.",
  "provision.tenantspace.save.fail.107-003": "Incorrect configuration administrator password\, lock account after retrying many times.",
  "provision.tenantspace.save.fail.107-001": "Exception thrown when calling configuration interface.",
  "provision.tenantspace.save.fail.107-005": "Configuration interface communication failed.",
  "provision.tenantspace.save.fail.107-002": "Input parameter is invalid\, required field is empty.",
  "provision.tenantspace.save.fail.107-007": "Authentication failed before configuration interface configuration.",
  "provision.tenantspace.save.fail.107-004": "Invalid configuration administrator account.",
  "provision.tenantspace.save.fail.107-010": "Adding agents or adding agents in batches encountered an exception.",
  "provision.tenantspace.save.fail.104-007": "Recording file format is not supported.",
  "provision.tenantspace.save.fail.107-011": "Agent modification or batch agent modification encountered an exception.",
  "provision.tenantspace.save.fail.107-012": "Agent query is abnormal.",
  "provision.tenantspace.save.fail.107-015": "Role modification failure exception.",
  "provision.tenantspace.save.fail.107-014": "Abnormal role increase failure.",
  "provision.tenantspace.save.fail.107-018": "Called increase failure exception.",
  "provision.tenantspace.save.fail.107-019": "The called modification failed abnormally.",
  "provision.tenantspace.save.fail.107-016": "Role query failure exception.",
  "provision.tenantspace.save.fail.107-013": "Agent deletion failure exception.",
  "provision.tenantspace.save.fail.107-022": "VDN modification failure exception.",
  "provision.tenantspace.save.fail.107-023": "VDN query failure exception.",
  "provision.tenantspace.save.fail.107-017": "Role deletion failure exception.",
  "provision.tenantspace.save.fail.107-020": "The called query failed abnormally.",
  "provision.tenantspace.save.fail.107-024": "IVR process query failure exception.",
  "provision.tenantspace.save.fail.107-021": "Called delete failed exception.",
  "provision.tenantspace.save.fail.107-026": "Subscription failure exception.",
  "provision.tenantspace.save.fail.107-027": "Subscription registration failure exception.",
  "provision.tenantspace.save.fail.107-028": "Subscription request failure exception.",
  "provision.tenantspace.save.fail.107-025": "IVR process file name is empty.",
  "provision.tenantspace.save.fail.107-029": "Subscription request timeout exception.",
  "provision.tenantspace.save.fail.107-031": "Subscription cancellation failure exception.",
  "provision.tenantspace.save.fail.107-032": "Subscription handshake failure exception.",
  "provision.tenantspace.save.fail.107-030": "Subscription exception.",
  "provision.tenantspace.save.fail.107-033": "System access code addition failure is abnormal.",
  "provision.tenantspace.save.fail.107-036": "System access code deletion failure exception.",
  "provision.tenantspace.save.fail.107-034": "System access code modification failure exception.",
  "provision.tenantspace.save.fail.107-037": "IVR service process increase failure exception.",
  "provision.tenantspace.save.fail.107-038": "IVR service process modification failure exception.",
  "provision.tenantspace.save.fail.107-035": "The system access code query fails abnormally.",
  "provision.tenantspace.save.fail.107-040": "IVR service process deletion failure exception.",
  "provision.tenantspace.save.fail.107-041": "Failed to add system VDN.",
  "provision.tenantspace.save.fail.107-039": "IVR service process query failure exception.",
  "provision.tenantspace.save.fail.107-044": "System VDN deletion failure exception.",
  "provision.tenantspace.save.fail.107-042": "System VDN modification failure exception.",
  "provision.tenantspace.save.fail.107-043": "System VDN query failure exception.",
  "provision.tenantspace.save.fail.107-045": "The system VDN deletes the exception code where VDNID and VDNName do not match.",
  "provision.tenantspace.save.fail.107-065": "Failed to query the UAP multimedia user.",
  "provision.tenantspace.save.fail.107-066": "UAP Configuration Exception",
  "provision.tenantspace.save.fail.107-064": "Failed to add the UAP multimedia user.",
  "provision.tenantspace.save.fail.107-067": "The UAP multimedia user fails to change the password.",
  "provision.tenantspace.save.fail.107-068": "Failed to delete the UAP multimedia user.",
  "provision.tenantspace.save.fail.107-069": "The UAP fails to query the registration status.",
  "provision.tenantspace.save.fail.107-070": "The system UAP fails to be deregistered.",
  "provision.tenantspace.save.fail.107-071": "The UAP user number is null.",
  "provision.tenantspace.save.fail.107-073": "The UAP IP address is null.",
  "provision.tenantspace.save.fail.107-072": "Invalid UAP user number.",
  "provision.tenantspace.save.fail.107-076": "The UAP password is inconsistent with the confirm password.",
  "provision.tenantspace.save.fail.107-074": "The UAP password is nul",
  "provision.tenantspace.save.fail.107-075": "The UAP password length is out of range.",
  "provision.tenantspace.save.fail.107-077": "The UAP IP address type is not supported.",
  "provision.tenantspace.save.fail.107-081": "The UAP fails to verify the VDN permission because the VDN does not contain the user number.",
  "provision.tenantspace.save.fail.107-079": "The end number of the UAP is smaller than the start number.",
  "provision.tenantspace.save.fail.107-078": "The length of the start number and end number on the UAP is inconsistent.",
  "provision.tenantspace.save.fail.107-083": "The UAP fails to send the MML command.",
  "provision.tenantspace.save.fail.107-082": "Failed to sign in to the UAP or disconnected from the UAP.",
  "provision.tenantspace.save.fail.107-080": "The number of UAP numbers exceeds the limit.",
  "provision.tenantspace.save.fail.107-086": "No call prefix is configured for a number on the UAP.",
  "provision.tenantspace.save.fail.107-087": "The UAP deregistered number is not registered.",
  "provision.tenantspace.save.fail.107-084": "The UAP fails to obtain the product type.",
  "provision.tenantspace.save.fail.107-085": "The UAP product type cannot be identified.",

  "provision.tenantspace.save.fail.120000": "Interface error.",
  "provision.tenantspace.save.fail.120001": "The maximum number of batches is 500.",
  "provision.tenantspace.save.fail.107-107": "ipccbase configproxy use.",
  "provision.tenantspace.save.fail.120003": "Configuration does not exist.",
  "provision.tenantspace.save.fail.120004": "Operation failed because the number of enabled VDNs reaches the maximum.",
  "provision.tenantspace.save.fail.120005": "Configuration item verification failed.",

  "provision.tenantspace.save.fail.120002": "Configuration already exists.",
  "provision.tenantspace.save.fail.120006": "The maximum number of threads.",
  "provision.tenantspace.save.fail.120601": "VDN default administrator cannot modify the name.",
  "provision.tenantspace.save.fail.120602": "VDN default administrator cannot change password.",
  "provision.tenantspace.save.fail.120605": "The sales representative is the team leader and cannot be deleted.",
  "provision.tenantspace.save.fail.120603": "VDN default administrator cannot modify role.",
  "provision.tenantspace.save.fail.120604": "The agent is the default VDN administrator and cannot be deleted.",
  "provision.tenantspace.save.fail.120607": "The agent is referenced by the default private skill queue and cannot be deleted.",
  "provision.tenantspace.save.fail.120608": "The agent is referenced by the skill queue and cannot be deleted.",
  "provision.tenantspace.save.fail.120610": "The agent application team does not exist.",
  "provision.tenantspace.save.fail.120606": "The agent is referenced by the process and cannot be deleted.",
  "provision.tenantspace.save.fail.120609": "Business representatives are referenced by other business representatives and cannot be deleted.",
  "provision.tenantspace.save.fail.120611": "The skill queue referenced by the agent does not exist.",
  "provision.tenantspace.save.fail.120613": "The agent role referenced by the agent does not exist.",
  "provision.tenantspace.save.fail.120615": "The process referenced by the agent does not exist.",
  "provision.tenantspace.save.fail.120612": "The skill group referenced by the agent does not exist.",
  "provision.tenantspace.save.fail.120614": "The other business representative cited by the business representative does not exist.",
  "provision.tenantspace.save.fail.120616": "The called configuration referenced by the agent does not exist.",
  "provision.tenantspace.save.fail.120618": "Business representative job number and squad leader job number are duplicated.",
  "provision.tenantspace.save.fail.120619": "Sales representative ID cannot be repeated.",
  "provision.tenantspace.save.fail.120617": "The drive letter referenced by the agent does not exist.",
  "provision.tenantspace.save.fail.120620": "Agent password length is less than the minimum password length for security configuration: {0}.",
  "provision.tenantspace.save.fail.120621": "The password must contain at least three of the following four characters: English capital letters\, English lowercase letters\, numbers\, and non-alphanumeric characters\, and they cannot be the same as the account number or the reverse order of the account number.",

  "provision.tenantspace.save.fail.120622": "The business representative is the team leader and cannot perform group change operations.",
  "provision.tenantspace.save.fail.123053": "The agent role is the default role and cannot be modified.",
  "provision.tenantspace.save.fail.123052": "The role of the agent is referenced by the agent and cannot be deleted.",
  "provision.tenantspace.save.fail.120955": "The skill queue referenced by the skill queue does not exist.",

  "provision.tenantspace.save.fail.123051": "Duplicate name of agent role.",
  "provision.tenantspace.save.fail.120953": "Skill elements in skill queue are duplicated.",
  "provision.tenantspace.save.fail.120956": "The agent referenced by the skill queue does not exist.",
  "provision.tenantspace.save.fail.120954": "The service type referenced by the skill queue does not exist.",
  "provision.tenantspace.save.fail.120958": "The called configuration referenced by the skill queue does not exist.",
  "provision.tenantspace.save.fail.120959": "The skill queue is referenced by the default private skill queue and cannot be deleted.",
  "provision.tenantspace.save.fail.120961": "Skill queue is referenced by CDN and cannot be deleted.",
  "provision.tenantspace.save.fail.120957": "The process referenced by the skill queue does not exist.",
  "provision.tenantspace.save.fail.120960": "The skill queue is referenced by other skill queues and cannot be deleted.",
  "provision.tenantspace.save.fail.120963": "Skill queues are referenced by business representatives and cannot be deleted.",
  "provision.tenantspace.save.fail.120962": "Skill queue is referenced by customer group and cannot be deleted.",
  "provision.tenantspace.save.fail.120964": "The skill queue is referenced by the process and cannot be deleted.",
  "provision.tenantspace.save.fail.120965": "The skill queue is referenced by the called configuration and cannot be deleted.",
  "provision.tenantspace.save.fail.120967": "The media type of the skill queue is a fax call\, and a mailbox must be configured.",
  "provision.tenantspace.save.fail.120968": "The minimum connection rate of the skill queue cannot exceed the expected connection rate.",
  "provision.tenantspace.save.fail.120966": "The drive letter referenced by the skill queue does not exist.",
  "provision.tenantspace.save.fail.120970": "Skill queue is referenced by shared device and cannot be deleted.",
  "provision.tenantspace.save.fail.120972": "The sub media type referenced by the skill queue does not exist.",
  "provision.tenantspace.save.fail.120969": "Skill queue is referenced by ACDDN and cannot be deleted.",
  "provision.tenantspace.save.fail.123001": "Duplicate name of skill element.",
  "provision.tenantspace.save.fail.120971": "Skill queue skill group reference\, cannot be deleted.",
  "provision.tenantspace.save.fail.123003": "Skill elements are referenced by the skill queue and cannot be deleted.",
  "provision.tenantspace.save.fail.123002": "Skill element does not exist.",
  "provision.tenantspace.save.fail.123004": "Skill elements are referenced by the skill queue and the name cannot be modified.",
  "provision.tenantspace.save.fail.120902": "The business type is referenced by the process and cannot be deleted.",
  "provision.tenantspace.save.fail.120903": "The business type is referenced by the skill queue and cannot be deleted.",
  "provision.tenantspace.save.fail.120904": "Business type is referenced by working standard time and cannot be deleted.",
  "provision.tenantspace.save.fail.120905": "The drive letter referenced by the business type does not exist.",

  "provision.tenantspace.save.fail.120901": "Duplicate description of business type.",
  "provision.tenantspace.save.fail.120651": "The system access code configured by the called party is not assigned.",
  "provision.tenantspace.save.fail.120652": "The combination of the system access code and subsequent access codes configured by the called party cannot be repeated.",
  "provision.tenantspace.save.fail.120656": "The skill queue referenced by the called configuration does not exist.",
  "provision.tenantspace.save.fail.120658": "The device type is IVR\, and the media type of the access code can only be click to call\, callback request\, and normal call.",
  "provision.tenantspace.save.fail.120659": "The routing script referenced by the called configuration does not exist.",
  "provision.tenantspace.save.fail.120655": "The access code referenced by the called configuration does not exist.",
  "provision.tenantspace.save.fail.120660": "The system access code configured by the called party is not consistent with the media type of the called configuration device.",
  "provision.tenantspace.save.fail.120661": "The called configuration is referenced by the default private skill queue and cannot be deleted.",
  "provision.tenantspace.save.fail.120662": "The called configuration is referenced by the skill queue and cannot be deleted.",
  "provision.tenantspace.save.fail.120663": "The called configuration is referenced by the agent and cannot be deleted.",

  "provision.tenantspace.save.fail.120216": "Cannot be modified between IVR and CCIVR.",
  "provision.tenantspace.save.fail.120217": "The maximum number of IVRs for the same IP address is 20.",
  "provision.tenantspace.save.fail.120221": "Parameter group referenced by IVR does not exist.",
  "provision.tenantspace.save.fail.120220": "IVR is referenced by VDN group and cannot be deleted.",
  "provision.tenantspace.save.fail.120222": "The CTI node referenced by the IVR does not exist.",
  "provision.tenantspace.save.fail.120219": "IVR is referenced by VDN and cannot be deleted.",

  "provision.tenantspace.save.fail.120502": "The maximum number of concurrent voice calls exceeds the upper limit.",
  "provision.tenantspace.save.fail.120501": "The maximum number of agents that are allowed to concurrently sign in to the system exceeds the upper limit. ",
  "provision.tenantspace.save.fail.120505": "The number of conference parties exceeds the upper limit.",
  "provision.tenantspace.save.fail.120503": "The maximum number of concurrent web calls exceeds the upper limit.",
  "provision.tenantspace.save.fail.120504": "The maximum number of concurrent outgoing calls exceeds the upper limit.",
  "provision.tenantspace.save.fail.120508": "The restriction is enabled. The calling number prefix cannot be empty.",
  "provision.tenantspace.save.fail.120506": "The maximum number of video agents that are allowed to concurrently sign in to the system exceeds the upper limit.",
  "provision.tenantspace.save.fail.120510": "The VDN name is duplicate with another VDN name.",
  "provision.tenantspace.save.fail.120507": "The calling number cannot contain any inclusion relationship.",
  "provision.tenantspace.save.fail.120509": "The restriction is enabled. By default\, the calling number starts with the prefix.",
  "provision.tenantspace.save.fail.120513": "The OBS referenced by the VDN does not exist.",
  "provision.tenantspace.save.fail.120511": "The VDN name is duplicate with the name of the VDN group.",
  "provision.tenantspace.save.fail.120514": "The distributed resource referenced by the VDN does not exist.",
  "provision.tenantspace.save.fail.120515": "The data source referenced by the VDN does not exist.",
  "provision.tenantspace.save.fail.120516": "The IVR referenced by the VDN does not exist.",
  "provision.tenantspace.save.fail.120517": "If no voice recording system is specified in the basic RMS information configuration\, only HUAWEI ACD voice recording can be configured for the current VDN.",
  "provision.tenantspace.save.fail.120520": "The system access code referenced by the VDN does not exist.",
  "provision.tenantspace.save.fail.120519": "The distributed node used for forcible VDN auto-voice-recording is not configured for the VDN.",
  "provision.tenantspace.save.fail.120521": "The VDN does not support the email and fax call types\, but system access codes of the email or fax call type are allocated to the VDN.",
  "provision.tenantspace.save.fail.120522": "The VDN does not support the web call type\, but system access codes of the web call type are allocated to the VDN.",
  "provision.tenantspace.save.fail.120523": "The system access code allocated to the VDN has been allocated to another VDN.",
  "provision.tenantspace.save.fail.120524": "The system access code allocated to the VDN has been allocated to a VDN group.",
  "provision.tenantspace.save.fail.120525": "The IVR data sources are not contained in the VDN data sources or third-party data sources that are allocated to the VDN.",
  "provision.tenantspace.save.fail.120526": "The default IVR data source is not contained in the VDN data sources or third-party data sources that are allocated to the VDN.",
  "provision.tenantspace.save.fail.120512": "Duplicate VDN agent ID range.",
  "provision.tenantspace.save.fail.120527": "The VDN is referenced by the local CCP client and cannot be deleted.",
  "provision.tenantspace.save.fail.120528": "When the agent hang-up transfer is enabled\, the prompt tone is played after the call is transferred back to the agent. The prompt tone parameter must be set.",
  "provision.tenantspace.save.fail.120530": "The drive referenced by the VDN does not exist.",
  "provision.tenantspace.save.fail.120529": "The VDN administrator ID must be within the configured agent ID range.",
  "provision.tenantspace.save.fail.120531": "The access code contained in basic VDN information is not allocated to the VDN.",
  "provision.tenantspace.save.fail.120533": "Duplicate VDN agent ID range.",
  "provision.tenantspace.save.fail.120534": "The CCS resource group referenced by the VDN does not exist.",
  "provision.tenantspace.save.fail.120536": "The length of the available VDN cannot be 0.",
  "provision.tenantspace.save.fail.120535": "The agent ID range is in use and cannot be canceled.",
  "provision.tenantspace.save.fail.120538": "The number of transferable agent IDs is insufficient.",
  "provision.tenantspace.save.fail.120537": "The available VDNs cannot contain the transferred VDN.",
  "provision.tenantspace.save.fail.120540": "The multimedia license value is out of range.",
  "provision.tenantspace.save.fail.120539": "Invalid multimedia license type.",
  "provision.tenantspace.save.fail.120541": "Agent concurrent processing capability: 1-60.",
  "provision.tenantspace.save.fail.120624": "The modified vdn docking password is the same as the old password.",

  "provision.tenantspace.save.fail.120202": "System access code overlaps with special routing access code.",
  "provision.tenantspace.save.fail.120203": "System access code and NIRC network call access code are duplicated.",
  "provision.tenantspace.save.fail.120201": "System access code is duplicated.",
  "provision.tenantspace.save.fail.120204": "The system access code is referenced by VDN and cannot be deleted.",
  "provision.tenantspace.save.fail.120205": "The system access code is referenced by the VDN group and cannot be deleted.",
  "ccprovision.vcallecenter.config.popup.modifyinfo": "Modify Password",

  "ccprovision.vcallecenter.config.popup.tab.administrator.modifypassword": "Modify Administrator Password",
  "ccprovision.vcallecenter.config.popup.tab.configurationinterface.modifypassword": "Modify Configuration Password",
  "ccprovision.vcallecenter.config.popup.enternewpassword": "Enter a New Password",
  "ccprovision.vcallecenter.config.popup.adminworkno": "Configuration Administrator ID",
  "ccprovision.vcallecenter.config.popup.curcountpassword": "Current Account Password",
  "ccprovision.vcallecenter.config.popup.adminpassword": "Change Configuration Administrator Password",
  "ccprovision.vcallecenter.config.popup.confirmnewpassword": "Confirm Password",
  "ccprovision.vcallecenter.config.popup.authentication": "Verify Identity",
  "ccprovision.vcallecenter.error.updateinfo.currentacountpasderror": "The password of the current account is incorrect.",
  "ccprovision.vcallecenter.error.updateinfo.adminpassword": "Modify password error.",
  "ccprovision.vcallecenter.sucess.updateinfo.adminpassword": "Modify password successfully.",
  "ccprovision.recorddrive.config.recorddrivename": "Drive Letter Name",
  "ccprovision.recorddrive.config.recorddrivenameconfig": "Volume Configuration",
  "provision.recorddrive.selectneed.delete.recorddrive": "Please select the drive letter to delete",
  "provision.recorddrive.selectrecorddrive.batchdeleteconfim": "Are you sure to delete all selected drive letters? Please make sure that the drive letter is not occupied by the tenant",
  "provision.recorddrive.selectrecorddrive.singledeleteconfim": "Are you sure to delete the selected drive letter? Please make sure that the drive letter is not occupied by the tenant",
  "provision.recorddrive.error.newrecord.repeated": "Unable to add the same drive letter name under the same call center",
  "ccprovision.recorddrive.config.storageLimit": "Lowest Storage Limit (MB)",
  "provision.recorddrive.error.cannotdelete.occupyvdn": "The drive letter assigned to the tenant cannot be deleted",
  "provision.recorddrive.label.isfirstletter.no": "no",
  "provision.recorddrive.label.isfirstletter.yes": "yes",
  "provision.recorddrive.error.firstletter.repeated": "The first drive letter already exists",
  "ccprovision.recorddrive.config.sftp.ipadress": "SFTP IP address",
  "ccprovision.recorddrive.config.isfirstletter": "Indicates whether the drive is the first",
  "ccprovision.recorddrive.config.sftp.ipadressport": "SFTP Server Port",
  "ccprovision.recorddrive.config.sftp.serverconfig": "SFTP Server Configuration",
  "ccprovision.recorddrive.config.sftp.port": "Port No.",
  "ccprovision.recorddrive.config.sftp.account": "SFTP Account",
  "ccprovision.recorddrive.config.sftp.filestoragepath": "File Path ",
  "ccprovision.recorddrive.config.sftp.filestoragepathStrValidateFailed": "The file storage path cannot contain './'",
  "ccprovision.recorddrive.config.sftp.accountpassword": "SFTP Server Password",

  "ccprovision.recorddrive.config.sftp.accountconfirmpasd": "SFTP Server Confirm Password",
  "provision.callcenterinstanceslist.vcallcentersftp.passLengthValidateFailed": "The password must contain 8 to 20 characters",
  "ccprovision.recorddrive.config.sftp.identitycheck": "Identity Verification",
  "ccprovision.recorddrive.config.sftp.identitychecktips": "The account or password of the SFTP server is changed. To maintain information security\, enter your current account and password.",
  "ccprovision.recorddrive.config.sftp.identitychecktips.placeholder": "Enter your current account and password.",

  "provision.tenantspace.status.active.success": "Successful activation.",

  "provision.tenantspace.status.active.fail": "Activation failed.",
  "provision.tenantspace.status.stop.fail": "Failed to suspend.",
  "provision.tenantspace.status.invalid.success": "Revocation succeeded.",
  "provision.tenantspace.status.invalid.fail": "Failed to revoke.",

  "provision.tenantspace.status.stop.success": "Success.",
  "provision.tenant.management.tenantcreate.provision.failed": "Failed to create the local tenant space.",
  "provision.tenant.management.tenantcreate.sum.failed": "Failed to create the tenant space in the SUM.",
  "provision.tenantspace.sip.searchName": "Please enter the name of the SBC server to be queried.",
  "provision.tenant.management.tenantcreate.dds.failed": "Failed to create the tenant space in the DDS.",
  "provision.tenantspace.ucs.searchName": "Please enter the name of the CCUCS server to be queried.",
  "provision.tenantspace.ivr.searchName": "Please enter the ID of the IVR to be queried.",
  "provision.tenantspace.gw.searchName": "Please enter the name of the Gateway server to query.",
  "provision.tenantspace.error.deleteConfigTenantSpace": "Resources have been allocated to the tenant space. You cannot delete the tenant space.",
  "provision.tenantspace.error.deleteProvision": "Failed to delete the Provision tenant space.",
  "provision.tenantspace.selectStastus.edit.invalid": "Invalid",

  "provision.tenantspace.selectStastus.edit.stop": "Suspend",
  "provision.tenantspace.selectStastus.edit.active": "active",
  "provision.tenant.management.tenantcreate.inter.failed": "Failed to invoke the internal service to create the tenant space.",
  "provision.tenant.management.tenantcreate.contact": "Contact Method",
  "provision.tenantspace.detail.apply.name": "Applicant",
  "provision.tenantspace.detail.create.time": "Creation Time",
  "provision.tenantspace.detail.expiry.time": "Expiration Time",
  "provision.tenantspace.detail.apply.accout": "Account",
  "provision.tenant.management.please.select": "Select\.\.\.",

  "provision.tenantspace.config.agent.ivr.more.zero": "Number of voice agents\, video agent\, multimedia agent\, versatile agent\, audio IVR channels and video IVR channels cannot be 0.",
  "provision.tenantspace.config.call.more.agent.ivr": "The maximum number of concurrent voice calls must be greater than or equal to the sum of voice agents\, voice IVR channels\, and video IVR channels.",
  "provision.tenantspace.config.voicecall.more.agent.ivr.whithAms": "The maximum number of concurrent voice calls cannot be less than the sum of voice\, video\, multimedia\, and versatile agents\, and audio and video IVR channels.",
  "provision.tenantspace.config.videocall.more.videoagent": "The maximum number of concurrent video calls is greater than or equal to the number of video agents.",
  "provision.tenantspace.config.agent.videoagent.ivr.more.zero": "Number of voice agents\, video agent\, multimedia agent\, audio IVR channels and video IVR channels cannot be 0.",
  "provision.tenantspace.config.voicecall.more.agent.ivr.whithoutAms": "The maximum number of concurrent voice calls cannot be less than the sum of voice\, video\, and versatile agents\, and audio and video IVR channels.",
  "provision.tenantspace.config.select.default.phone.in.selectAccesscodes": "The default calling number must be within the selected access code.",
  "provision.tenantspace.config.select.default.phone": "Please select the default calling number.",
  "provision.tenantspace.config.select.record.time": "Please select a recording retention period.",
  "provision.tenantspace.config.select.vitual.ccname": "Please enter the name of the virtual call center.",

  "provision.tenantspace.config.select.record": "Please select a recording drive.",
  "provision.tenantspace.config.param.error.agentUsage": "Please enter the number of voice agents.",
  "provision.tenantspace.config.param.error.agentCall": "Please enter the number of concurrent voice calls.",
  "provision.tenantspace.config.param.error.videoCall": "Please enter the number of concurrent video calls.",
  "provision.tenantspace.config.param.error.videoUsage": "Please enter the number of video agents.",
  "provision.tenantspace.config.param.error.ivrNumber": "Please enter the number of IVR channels.",
  "provision.tenantspace.config.param.error.ttsNumber": "Please enter the TTS quantity.",

  "provision.tenantspace.config.param.error.asrNumber": "Please enter the ASR quantity.",
  "provision.tenantspace.table.invalidTenantSpace": "This operate cannot be cancled after the revocation\, Please confirm whether the tenant needs to be revoked!",
  "provision.tenantspace.table.activeTenantSpace": "Are you sure you want to activate the tenant?",
  "provision.tenantspace.table.invalidTenantSpace.confirm": "Confirm Revocation",
  "provision.tenantspace.table.stopTenantSpace": "Are you sure you want to suspend the tenant?",
  "provision.tenantspace.table.activeTenantSpace.confirm": "Confirm Activation",

  "provision.tenantspace.table.stopTenantSpace.confirm": "Confirm Suspension",
  "provision.tenantspace.detail.tenantspace.status.not.change": "The tenant is in the inactive state and cannot be modified.",
  "provision.callcent.error.abnormal": "Abnormal",
  "ccprovision.IVRSprogress.config.logincti.failed": "Connection platform failed",
  "provision.tenantspace.selectvideomodel.isnull": "Select a video application mode for the video resource applied for by the tenant.",
  "provision.tenantspace.agentusage.plus.videousage": "AgentUsage + VideoUsage must be less than 10000",
  "provision.tenantspace.agentusage.plus.videousage.plus.maxVersatileAgents": "The total number of voice\, video\, and versatile agents cannot exceed 10000.",
  "provision.tenantspace.table.maxcallnumswithdesc": "Max. Concurrent Voice Calls (>= Sum of Agents and IVR Channels)",
  "provision.tenantspace.accesscode.searchName": "Please enter accesscode to be queried.",
  "provision.tenantspace.config.agentusage.vidoeusage.ivr": "Voice Agent Quantity\, Video Agent Quantity\, Multimedia Agent Quantity\, Audio IVR Channel Quantity and Video IVR Channel Quantity can not all be zero",
  "provision.tenantspace.passValidateFailed": "Should contain uppercase letters\, lowercase letters\, digits\, and special characters ~!{'@'}#$%&*()-_=+[{}];:\"\"\\,<.>/?",
  "provision.tenantspace.config.agentusage.ivr": "Voice Agent Quantity\, Video Agent Quantity\, Multimedia Agent Quantity\, Versatile Agent Quantity\,Audio IVR Channel Quantity and Video IVR Channel Quantity can not all be zero",
  "provision.tenantspace.videoagentchannnels.limit": "The number of video agent channels must be greater than or equal to twice the number of video agents.",
  "provision.tenantspace.statusRecordTime.24Month": "24 Month",
  "provision.tenantspace.create.onlineUserNumber": "Online User Number",
  "provision.tenant.management.tenantcreate.name.exist": "Tenant name already exists",
  "provision.tenant.management.tenantcreate.adminaccount.exist": "The administrator account already exists",
  "provision.callcenterinstanceslist.vcallcenter.syncVccSuccess": "Synchronization is complete.",
  "provision.tenantspace.management": "TenantSpace Management",
  "provision.callcenterinstanceslist.vcallcenter.agentNumErrorListPromt": "There is a virtual call center whose number of seats is inconsistent with the VDN in the WAS. The VccId is",
  "provision.callcenterinstanceslist.vcallcenter.nameDiffListPromt": "There is a virtual call center with VccName that is inconsistent with VdnName in WAS. The VccId is",
  "provision.callcenterinstanceslist.vcallcenter.invalidVccListPromt": "There are virtual virtual call centers in the AICC where the VDN is missing in the WAS. The VccId is",
  "provision.callcenterinstanceslist.vcallcenter.InProgress": "In progress.",
  "provision.callcenterinstanceslist.vcallcenter.invalidVdnListPromt": "There are VDNs in the WAS where the virtual call center is missing in the AICC. The VdnID is",
  "provision.tenantspace.smartAgentUsage.iszero": "Configure the agent first.",
  "provision.tenantspace.agentUsage.iszero": "Please configure voice agent and ASR resources first.",
  "provision.tenantspace.prompt": "Information",
  "provision.tenantspace.moveAgentUsage.iszero": "Insufficient number of audio IVR channels.",
  "provision.tenantspace.common.anonymous.explain": "Enable the anonymous outbound call feature and enable the the anonymous outbound call function for the tenant space.",
  "provision.tenantspace.chooseFeature": "Feature Selection",
  "provision.tenantspace.feature.agenttype": "Agent Type",
  "provision.tenantspace.feature.anonymous": "Anonymous Outbound Call",
  "provision.tenantspace.common.ivr": "Conventional IVR",
  "provision.tenantspace.intelligent.ivr": "Intelligent IVR",
  "provision.tenantspace.feature.ivrtype": "IVR Type",
  "provision.tenantspace.common.agent": "Ordinary Agent",
  "provision.tenantspace.feature.moveAgents": "Mobile agent",
  "provision.tenantspace.intelligent.agent": "Intelligent Agent",
  "provision.tenantspace.feature.webrtc": "After the WebRTC feature is enabled\, the tenant space supports the WebRTC function.",
  "provision.tenantspace.feature.close": "Disable",
  "provision.tenantspace.feature.forceVideoSelfRecord": "Screen Recording",
  "provision.tenantspace.feature.forceVideoSelfRecord.description": "Indicates whether to automatically record the screen operation status when an agent processes a call.",
  "provision.tenantspace.feature.open": "Enable",
  "provision.tenantspace.feature.forceVideoSelfRecord.forcibleSelfRecord": "Forcible self-recording of the VDN screen",
  "provision.tenantspace.feature.forceVideoSelfRecord.percentageFactor": "Screen recording percentage coefficient (%)",
  "provision.tenantspace.feature.forceVideoSelfRecord.percentage": "Record by Percentage",
  "provision.tenantspace.feature.forceVideoSelfRecord.attendant": "Enabled for all attendants",
  "provision.tenantspace.feature.forceVideoSelfRecord.percentageScope": "The minimum value is 0, and the maximum value is 100. The default value is 100.",
  "provision.tenantspace.intelligent.ivr.explain": "Intelligent IVR: In addition to the traditional IVR function\, intelligent navigation IVR supporting speech recognition and semantic understanding.",
  "provision.tenantspace.feature.forceVideoSelfRecord.settingError": "Screen recording parameters are incorrectly configured.",
  "provision.tenantspace.common.ivr.explain": "Conventional IVR: Supports basic functions such as playback\, receiving\, and TTS. TTS needs to configure the number of TTS channels.",
  "provision.tenantspace.intelligent.agent.explain": "Intelligent Agent: In addition to the basic voice control function\, it supports real-time voice-to-text and related intelligent recommendation. For configuring smart agents\, you need to configure voice agents and ASR resources first.",
  "provision.tenantspace.intelligent.agent.description": "Intelligent agent: supports basic channel communication\, text semantic analysis\, and intelligent recommendation. Configure the number of agents before configuring intelligent agents. Configure ASR resources for intelligent agents that support voice sessions.",
  "provision.tenantspace.common.moveagent.explain": "The system allocates a softphone number to the tenant. The tenant's system uses the softphone number to register with the server of the AICC system. The tenant's system invokes the open interface provided by the AICC system to implement the bidirectional callback function. (Maximum number of allocatable mobile agents = audio ivr channel quantity / 2\, round down).",
  "provision.tenantspace.common.agent.explain": "Common agent: answers inbound calls\, controls voice and video calls\, and sends and receives multimedia messages.",
  "provision.tenantspace.common.agent.number": "Intelligent Agent Quantity",
  "provision.tenantspace.config.tenant.featurevalue.not.digits": "The feature information assigned to the tenant is invalid.",
  "provision.tenantspace.config.intelligent.agentNumber.not.exceed.agentNumber": "The number of intelligent agents allocated to a tenant cannot be greater than the total number of agents.",
  "provision.tenantspace.config.fail.savedata.tenant.feature.inDatabase": "Database failed to save tenant feature information",
  "provision.tenantspace.config.tenant.intelligent.ivr.featurevalue.not.one": "The intelligent ivr feature value assigned to the tenant is not 1",
  "provision.tenantspace.config.fail.modify.tenant.auth.use.sumInterface": "Calling the SUM interface to modify tenant permissions failed",
  "provision.tenantspace.feature.moveAgentNum": "Maximum number of agents",
  "provision.tenantspace.config.fail.modifydata.tenant.feature.inDatabase": "Database failed to modify tenant feature information",
  "provision.callcenterinstanceslist.vcallcenter.UCSServer.title": "CCUCS Server Configuration",
  "provision.callcenterinstanceslist.ucsServer.ucsServerIp": "CCUCS Server IP",
  "provision.callcenterinstanceslist.vcallcenter.UCSServer": "CCUCS Multimedia Server",
  "provision.callcenterinstanceslist.ucsServer.ucsServerIpUpdate": "Edit",
  "provision.callcenterinstanceslist.ucsServer.batchdeleteconfim": "Are you sure you want to delete all selected CCUCS servers?",
  "provision.callcent.selectucsserver": "Please Select CCUCS server.",
  "provision.callcenterinstanceslist.ucsServer.ucsServerPort": "CCUCS Server Port",
  "provision.callcenterinstanceslist.ucsServer.singledeleteconfim": "Are you sure you want to delete the selected CCUCS server?",
  "provision.callcent.error.deleteucsserver.part.associationvcallcener": "Some CCUCS servers selected are associated with tenants and cannot be deleted.",
  "provision.callcent.error.saveucsserveripinfo": "Creation failed\, Ensure that the CCUCS IP address and port number of the server do not exist.",
  "provision.callcent.error.deleteucsserver.associationvcallcener": "The selected CCUCS server is associated with tenant and cannot be deleted.",

  "provision.callcent.error.saveucsserverdesinfo": "Creation failed\,Ensure the CCUCS server name does not exist.",
  "provision.callcent.error.updateucsserver.ondatabase": "Modifying the CCUCS server in the database failed.",
  "provision.callcent.error.updateucsserver.ipandport.alreadyexist": "The CCUCS server IP and port number already exist and cannot be modified.",

  "provision.callcenterinstanceslist.whitelist.Title": "Intelligent Identification Callback Address",
  "provision.callcenterinstanceslist.whiteList.IP": "Trustlist Address",
  "provision.callcent.error.regaddrformat": "Registration address format is incorrect.",
  "provision.callcenterwhitelist.regAddr": "Callback Address",
  "provision.callcenterinstanceslist.config.save": "Save",
  "provision.callcenterinstanceslist.whiteAddr.regAddrExample": "example:https://ip:port/service-cloud/rest/cc-ai/v1/asrevent/asrresult",

  "provision.callcent.error.regaddrlength": "Registration address is too long.",
  "provision.callcenterinstanceslist.softPhone.used.pleaseselect": "Select the usage",


  "provision.callcenterinstanceslist.softPhone.used.select": "Usage",
  "provision.callcenterinstanceslist.softPhone.usedselect.isnotoccupied": "Unused",
  "provision.callcenterinstanceslist.softPhone.usedselect.fixedagent": "Agent number",
  "provision.callcenterinstanceslist.softPhone.usedselect.mobileagent": "Customer manager number",
  "provision.callcenterinstanceslist.vcallcenterDeatil.DateValidateFailed": "Please select a date greater than today and less than 2038-1-19",
  "provision.tenantspace.popuppageurl.whiteListAdd": "Adding An Address Trustlist",
  "provision.tenantspace.popuppageurl.deleteconfim": "Are you sure you want to delete the address trustlist?",

  "provision.tenantspace.popuppageurl.tableheadname": "Address",
  "provision.tenantspace.popuppageurl.whiteListStyle": "Address Type",
  "provision.tenantspace.agentinfo": "Agent Info",
  "provision.tenantspace.agentinfo.label.workNo": "Agent Number",
  "provision.tenantspace.agentinfo.message.searchworkno": "Please enter agent number",
  "provision.tenantspace.agentinfo.message.searchSipAccount": "Please enter the soft phone number",
  "provision.tenantspace.agentinfo.agentstatus": "Agent Status",

  "provision.tenantspace.agentinfo.message.selectagenttype": "Please select an agent type",
  "provision.tenantspace.agentinfo.agentstatus.offline": "offline",
  "provision.tenantspace.agentinfo.message.audio": "audio",
  "provision.tenantspace.agentinfo.agentstatus.online": "online",
  "provision.tenantspace.agentinfo.message.versatile": "Versatile",
  "provision.tenantspace.agentinfo.message.video": "video",
  "provision.tenantspace.agentinfo.message.selectagent": "Please select an agent!",
  "provision.tenantspace.agentinfo.message.batchdeleteconfim": "Are you sure you want to delete all selected agents?",
  "provision.tenantspace.agentinfo.message.singledeleteconfim": "Are you sure you want to delete the selected agent?",
  "provision.tenantspace.agentinfo.message.deletefail.InProgress": "Tenant is currently processing the business\, please try again later!",
  "provision.tenantspace.agentinfo.message.media": "multimedia",
  "provision.tenantspace.agentinfo.deletefail.modifyvdn.onwas": "Connection to the was platform failed to modify the vdn",
  "provision.tenantspace.agentinfo.message.smartagent.iszero": "\, There is no audio agent\, please turn off the intelligent agent feature manually",
  "provision.callcenterinstanceslist.vcallcenterDeatil.whiteNumValidateFailed": "Only digits are allowed.",
  "provision.callcenterinstanceslist.vcallcenterDeatil.whiteNumValidateLength": "The length of the trustlist number ranges from 1 to 32.",
  "provision.tenantspace.commercialStatus": "Whether a Commercial Site",
  "provision.tenantspace.selectStastus.CommercialUse": "Commercial Use",
  "provision.tenantspace.selectStastus.noncommercial": "Trial Commercial Use",
  "provision.tenantspace.Conversioncommercial": "Conversion Commercial ",
  "provision.tenantspace.ConversionNoncommercial": "Conversion Trial Commercial Use",
  "provision.tenantspace.config.select.commercial.status": "Please Select a Commercial State.",
  "provision.tenantspace.config.select.default.whiteNumber": "The umber of trustlisted numbers cannot be greater than 5 by default.",
  "provision.tenantspace.chooseCommercial": "Commercial Status Selection",
  "provision.callcenterinstanceslist.whiteNumber": "Numbers In Trustlist",
  "provision.tenantspace.config.select.whiteRepeate": "Duplicate Trustlist Number.",
  "provision.tenantspace.deleteWhiteNumber": "Are you sure you want to delete the number from the trustlist?",
  "provision.tenantspace.deleteWhiteNumber.confirm": "confirm deletion",
  "provision.tenantspace.step1": "Step 1",
  "provision.tenantspace.step2": "Step 2",
  "provision.tenantspace.step4": "Step 4",
  "provision.tenantspace.step3": "Step 3",
  "provision.tenantspace.step5": "Step 5",
  "provision.tenantspace.whiteNumber": "Configuring Numbers In The Trustlist",
  "provision.tenantspace.modifyPassword": "Reset Password",

  "provision.tenantspace.table.expiryDate": "Deadline",
  "provision.tenantspace.resetpwdsuccess": "The password is reset successfully.",
  "provision.tenantspace.resetpwdfaild": "Failed to reset the password.",
  "provision.tenantspace.passwordValidatefaild": "Failed to verify the password.",

  "provision.callcenterinstanceslist.vcallcenter.CcgatewayServer": "CC-Gateway Server",
  "provision.callcenterinstanceslist.vcallcenter.CcgatewayServer.title": "CC-Gateway Configuration",
  "provision.callcenterinstanceslist.ccgatewayserver.ccGatewayIp": "CC-Gateway IP",
  "provision.callcenterinstanceslist.ccgatewayserver.ccGatewayPort": "CC-Gateway Port",
  "provision.callcenterinstanceslist.ccgateway.idleRegAgentNum": "Idle Agent Quantity",
  "provision.callcenterinstanceslist.ccgateway.maxRegAgentNum": "Concurrent Agent Quantity",
  "provision.callcenterinstanceslist.ccgatewayServer.batchdeleteconfim": "Are you sure you want to delete all selected CC-Gateway servers?",
  "provision.callcent.selectccgatewayservers": "Select a CC-Gateway server.",
  "provision.callcenterinstanceslist.ccgatewayServer.singledeleteconfim": "Are you sure you want to delete the selected CC-Gateway server?",
  "provision.callcent.error.deleteccgateway.associationvcallcener": "The selected CC-Gateway server is associated with tenant and cannot be deleted.",
  "provision.callcent.error.deleteccgateway.part.associationvcallcener": "Some CC-Gateway servers selected are associated with tenants and cannot be deleted.",
  "provision.tenantspace.tenantid": "Current Rental ID.",
  "provision.callcent.error.saveccgateway.nameinfo": "Create fail\,please confirm if the CC-Gateway name already exists.",
  "provision.callcent.error.updateccgateway.regAgentNumError": "Update fail\,the number of registered seats modified is less than the actual number of allocated seats.",
  "provision.callcent.error.saveccgateway.ipportinfo": "Create fail\,please confirm if the CC-Gateway IP and Port already exists.",
  "provision.callcent.error.saveccgateway.regAgentNumError": "The number of registered seats modified is less than the actual number of allocated seats.",
  "provision.callcent.error.updateccgateway.ccgatewayServernoexist": "Update fail\,CC-Gateway Server not exist.",
  "provision.callcent.error.updateccgateway.ondatabase": "Update fail\,modified the CC-Gateway server in the database failed.",
  "provision.callcent.error.updateccgateway.ipandport.alreadyexist": "Update fail\,please confirm if the CC-Gateway IP and Port already exists.",
  "provision.callcent.error.updateccgateway.fail.syncccmanagement": "Update fail\,synchronizing the CC-Gateway to modify the information to the rental room failed.",

  "provision.callcenterinstanceslist.vcallcenterDeatil.wasPsdLengthRangeValidateFailed": "Password length should be between 16 and 32",
  "provision.callcent.selectcallcentccgateway": "Please select CC-Gateway.",
  "provision.callcenterinstanceslist.vcallcenterDeatil.kafkaPwdLength": "Password length should be between 6 and 32",
  "provision.callcenterinstanceslist.resource.associationvcallcener.updatenode.failed": "Tenants are assigned\, nodes cannot be modified.",
  "provision.callcenterinstanceslist.resource.node.notexist": "Node information does not exist.",


  "provision.callcenterinstanceslist.whitepageurl.httptip": "(Not safe)",
  "provision.tenantspace.common.case.explain": " When the Case is opened\, the tenant will open the service cloud service capability.",
  "provision.tenantspace.ExpireTenantSpaceCount": "Number of Tenants Which Are About to Expire Within 30 Days",
  "provision.tenantspace.tenantSpaceCount": "Total Number of Tenants",
  "provision.whiteListAddress.outboundcall.obs.fileserver": "Outbound call OBS file server",
  "provision.whiteListAddress.PopMenu": "Pop-up Screen",
  "provision.whiteListAddress.outboundcall.sftp.fileserver": "SFTP file server for outbound calls",
  "provision.whiteListAddress.ivr.voiceupload.callback": "IVR voice upload callback",
  "provision.whiteListAddress.CustomerMenu": "Customizing Menus",
  "provision.whiteListAddress.mobileagenturl": "Mobile customer service callback",
  "provision.tenantspace.common.newcase.explain": "Enable the Case 2.0 feature to provide the case management and configuration capabilities for tenants.",
  "provision.whiteListAddress.resourcedump.obs": "Resource dump OBS server",
  "provision.whiteListAddress.resourcedump.obsproxy": "Resource dump OBS server agent",
  "provision.whiteListAddress.AddressExist": "The address trustlist already exists.",
  "provision.whiteListAddress.TenantUnExist": "The tenant does not exist.",
  "provision.whiteListAddress.cardPop": "Page card message redirection address",
  "provision.tenantspace.config.isAutoAssignWorkNo": "Automatically assign workNo for Virtual Call Center",

  "provision.tenantspace.config.chooseworkNo": "Select WorkNo",
  "provision.tenantspace.config.chooseworkNo.tip": "Please enter the auspicious workno segment according to the idle workNo information",
  "provision.tenantspace.config.error.chooseworkNo.noaddagent": "No agent resource added\, no need to assign work number",
  "provision.tenantspace.config.error.chooseworkNo.noagent": "No agent resources selected\, no need to assign work number",
  "provision.tenantspace.config.error.142": "The appcube feature must be available when the app call tas feature is available.",
  "provision.tenantspace.config.worknoassign.filterworkNo": "Screening Number Bank",
  "provision.tenantspace.config.worknoassign.filternumber": "Exclude numbers",
  "provision.tenantspace.config.worknoassign.filternumber.explain1": "When entering multiple numbers\, please use \",\" to separate. Enter up to 5 numbers\, for example\, \"4,11,13\"",
  "provision.tenantspace.config.worknoassign.chooseworkNo": "Select number",
  "provision.tenantspace.config.worknoassign.filternumber.explain2": "The number bank after screening will not contain the numbers of the above numbers",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.explain1": "Number Library Preview",
  "provision.tenantspace.config.worknoassign.filternumber.error": "The excluded number does not meet the requirements.",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.error": "End agent ID cannot be less than start agent ID.",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.explain2": "A maximum of five groups of numbers can be added.",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.addmaxlimit": "Five groups of numbers have been added.",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.paramserror": "The number information is incorrect.",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.required": "Enter the agent ID segment to be added and click OK.",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.workNocontain": "The work ID has been selected. Please enter another one.",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.availableWorkNo": "No available agent ID in the agent ID segment.",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.noempty": "The number information is mandatory.",
  "provision.tenantspace.config.worknoassign.availableworknocount": "Available Numbers",
  "provision.tenantspace.config.worknoassign.showchoosedworkno.explain": "Note: You need a total of \{0} numbers\, and \{1} numbers have been selected. The system will assign numbers to you in sequence.",
  "provision.tenantspace.config.assignedworkno": "Allocated work ID",
  "provision.tenantspace.config.assignedworknonumber": "Allocated Agent IDs",
  "provision.tenantspace.config.selectedworkNo": "Selected \{0} work IDs \{1}",
  "provision.tenantspace.worknoValidatefaild": "The WorkNo is mandatory.",

  "provision.tenantspace.config.worknoassign.add": "Add",
  "provision.tenantspace.obsServer.datasource.name": "Data Source Name",
  "provision.tenantspace.obsServer.datasource.databasename": "Database Name",
  "provision.tenantspace.obsServer.datasource.id": "Data Source ID",
  "provision.tenantspace.obsServer.datasource.type": "Data Source Type",
  "provision.tenantspace.obs.searchName": "Enter the name of the OBS server to be queried.",
  "provision.tenantspace.config.chooseOBSServer": "Select an OBS server.",
  "provision.tenantspace.config.choosedatasource": "Select an OBS data source.",

  "provision.tenantspace.chooseobsdatasource.error1": "Select an OBS server.",
  "provision.tenantspace.chooseobsdatasource.platformDbSource": "Platform Data Sources",
  "provision.tenantspace.chooseobsdatasource.callCenterDbSource": "Call Center Data Sources",
  "provision.tenantspace.config.datasource": "OBS Data Source",
  "provision.tenantspace.chooseobsdatasource.thirdPartyDbSource": "Third-Party Data Sources",
  "provision.tenantspace.config.isAutoAssignSoftPhone": "Whether the softphone number is automatically allocated to the VCC",
  "provision.tenantspace.config.choosesoftphone": "Select softphone number.",
  "provision.tenantspace.config.softphoneassign": "Allocate softphone number",

  "provision.tenantspace.config.softphoneassign.tip": "Please enter the softphone number segment.",
  "provision.tenantspace.config.softphone.Validatefaild": "Unallocated softphone number",
  "provision.tenantspace.config.softphone.noavailable": "No available softphone number.",
  "provision.tenantspace.config.error.softphone.noagentadd": "No voice\, video\, multimedia\, versatile\, or mobile agent is added.",
  "provision.tenantspace.config.softphone.using": "softphone number \{0} is in use.",
  "provision.tenantspace.config.error.softphone.noaddagent": "No audio\, video\, multimedia\, or mobile agent is added.",
  "provision.tenantspace.config.softphoneassign.softphonescope.required": "Enter the softphone number segment to be added and click OK.",
  "provision.tenantspace.config.softphoneassign.softphonescope.contain": "The softphone number has been selected. Please enter another one.",
  "provision.tenantspace.config.softphoneassign.softphonescope.extensiverange": "The value range from the start number to the end number cannot be greater than 1000.",
  "provision.tenantspace.config.softphoneassign.chooseworknoscope.paramserror": "The number information is incorrect.",
  "provision.tenantspace.config.softphoneassign.softphonescope.inadequacy": "The allocated available softphone number are insufficient.",
  "provision.tenantspace.config.selectedsoftphone": "Selected\{0} Numbers\{1}",

  "provision.callcent.platformserver.cms": "CMS",
  "provision.callcent.platformserver.isales": "iSales",
  "provision.callcent.platformserver.ccbcs": "CCBCS",
  "provision.callcent.platformserver.isqm": "iSQM",
  "provision.callcent.platformserver.ccbms": "CCBMS",
  "provision.callcent.platformserver.ccfs": "CCFS",
  "provision.callcenterinstanceslist.vcallcenter.plateformServer": "Platform Server",
  "provision.callcent.platformserver.port": "Service port number",
  "provision.callcent.platformserver.identitychecktips": "You have changed the password of the server. To ensure information security\, please enter your current account and password.",
  "provision.callcent.platformserver.ip": "Service IP address",
  "provision.tenantspace.save.fail.120532": "The original access code has been referenced by a called number.",
  "provision.tenantspace.table.batchcreate": "Batch Create",

  "provision.tenant.management.batchcreate": "Tenant spaces are created in batches.",
  "provision.tenant.management.batchcreatenumber": "Quantity",

  "provision.tenant.management.batchcreate.error.accesscodenumber": "A maximum of 100 access codes are allowed.",
  "provision.tenant.management.batchcreate.success.createtask": "Successfully added a task for creating tenant spaces in batches.",
  "provision.tenant.management.pool.ctinode2": "CTI Node 2",
  "provision.tenant.management.batchcreate.error.accesscode.inadequacy": "The number of access codes cannot be less than that of tenant spaces.",
  "provision.tenant.management.akinfo": "AK information",
  "provision.tenant.management.pool.ctinode1": "CTI Node 1",
  "provision.tenant.management.pool.ctinode.id": "Node ID",
  "provision.callcenterinstanceslist.table.download": "download",
  "provision.callcenterinstanceslist.table.viewing": "viewing",
  "provision.authconfig.appkey": "APP ID",
  "provision.tenant.management.pool.ctinode.name": "Node Name",
  "provision.authconfig.updateMessage": "Are you sure you want to update the App SK?",
  "provision.authconfig.deleteconfim": "Are you sure you want to delete the App ID?",
  "provision.authconfig.connected.with.tenantspace": "this APP ID is bound to a tenant space\, can not delete.",
  "provision.authconfig.update": "upadate",
  "provision.authconfig.updateConfim": "update confim",
  "provision.authconfig.duplicate.description.error": "duplicate.description.error.",
  "provision.authconfig.description.toolong.error": "description can not longer tan 200.",
  "provision.authconfig.authdescription": "Enter the description of the developer ID.",
  "provision.authconfig.description.empty.error": "description can not be empty.",
  "provision.authconfig.authinfomation": "Developer information",
  "provision.grayconfig.tenantSpaceId": "tenantSpaceId",
  "provision.grayconfig.paramIsError": "The input parameter is empty or incorrect",
  "provision.grayconfig.deleteconfim": "Are you sure you want to delete the gray configuration?",

  "provision.grayconfig.tenantHasConfig": "The tenant space has been configured",
  "provision.grayconfig.tenantSpaceName": "tenantSpaceName",

  "provision.tenantspace.config.ivrserver.select.confirm": "confirm Selection",
  "provision.tenantspace.config.ivrserver.select.confirm.message": "The selected IVR processes are deployed on the same server\, which may cause single point of failure. Are you sure you want to continue?",
  "provision.tenantspace.resetpwdfaild.accountpassword.error": "The current account password is incorrect.",
  "provision.tenantspace.resetpwdfaild.tenant.not.exist": "Failed to change the password because the tenant space does not exist.",
  "provision.tenantspace.resetpwdfaild.user.not.exist": "Failed to change the password because the user does not exist.",
  "provision.tenantspace.resetpwdfaild.newpassword.not.meet.passwordrule": "The new password does not meet the password rule.",

  "provision.tenantspace.resetpwdfaild.user.is.locked": "Failed to change the password because the user is locked.",
  "provision.tenantspace.resetpwdfaild.newPwd.information.is.wrong": "Modify failed,The newPwd information is wrong",

  "provision.callcenterinstanceslist.sipServer.sip": "SIP",
  "provision.callcenterinstanceslist.sipServer.sipanonymousCall": "SIP anonymous call",
  "provision.callcenterinstanceslist.sipServer.webRtcanonymousCall": "WebRTC anonymous call",
  "provision.callcenterinstanceslist.vcallcenterDeatil.uapIpAndPort": "UAP address",
  "provision.callcenterinstanceslist.sipServer.uapIp": "UAP IP",

  "provision.callcenterinstanceslist.sipServer.webRTC": "WebRTC",
  "provision.tenantspace.config.choose.agent.registration.server": "Agent registration server",
  "provision.tenantspace.config.choose.agent.registration.server.type": "Agent registration server type",
  "provision.tenantspace.config.choose.anonymous.call.server.type": "Anonymous call server type",
  "provision.tenantspace.config.choose.anonymous.call.server": "Anonymous call server",
  "provision.callcenterinstanceslist.sipServer.uapPort": "UAP Port",
  "provision.tenantspace.config.chooseAgentRegistrationServer": "Select an agent registration server",
  "provision.tenantspace.config.chooseAnonymousCallServer": "Select an anonymousa call server",
  "provision.tenantspace.config.chooseAnonymousCallServerTitle": "Select anonymous call server.",
  "provision.tenantspace.config.anonymous.call.number": "Number of Concurrent Anonymous Calls",
  "provision.tenantspace.config.need.wertcsipserver": "To enable the WebRTC feature need select a WebRTC agent registration server. ",
  "provision.callcent.pool.select.different.sipserver.error": "Agent registration servers of the same type must be selected for different nodes.",
  "provision.callcent.pool.select.different.amsservice.error": "Different nodes should select the same type of anonymous call server.",
  "provision.tenantspace.config.chooseAgentRegistrationServerTitle": "Select agent registration server.",
  "provision.tenantspace.config.amsNumber.error": "The number of concurrent anonymous calls can not be empty or must be 0 or a positive integer.",
  "provision.tenantspace.config.amsServer.idleregagentnum.insufficient.error": "Insufficient number of remaining concurrent calls on the anonymous call server.",
  "provision.tenantspace.config.tenant.webrtc.featurevalue.not.one": "The value of the WebRTC feature allocated to the tenant is not 1.",

  "provision.callcenterinstanceslist.sipServerUpdate.failed": "The IP address or port number does not meet the rule.",
  "provision.whitepopuppageurl.protocol.check": "Note that the network protocol used by the pop-up address is not a secure protocol. If you decide to use this protocol\, security risks may exist.",

  "provision.tenantspace.feature.intelligent.inspection": "intelligent inspection",
  "provision.tenantspace.feature.intelligent.inspection.description": "Enable the intelligent inspection feature and enable the intelligent inspection function for the tenant space.",
  "provision.tenantspace.config.tenant.intelligent.inspection.featurevalue.not.one": "The value of the intelligent inspection feature allocated to the tenant is not 1.",

  "provision.tenantspace.feature.speechAnalysis": "speech analysis",
  "provision.tenantspace.feature.speechAnalysis.description": "The speech analysis feature is enabled. The tenant space supports text and voice analysis.",

  "provision.softphone.label.issyncuap.yes": "YES",

  "provision.softphone.label.issyncuap.no": "NO",
  "provision.softPhone.label.uapaddress": "UAP Address",
  "provision.softPhone.label.uapaddress.node1": "UAP IP Address Of Node 1",
  "provision.softPhone.label.isSyncUap": "Synchronize UAP",
  "provision.softPhone.table.forcemodifyconfim.message": "This operation will reset the softphone password. To change the password\, you must log in to the tenant space. Are you sure you want to continue?",
  "provision.softPhone.table.forcemodifyconfim": "Confirm",
  "provision.softPhone.label.uapaddress.node2": "UAP IP Address Of Node 2",

  "provision.tenantspace.feature.customData": "Data customization",
  "provision.tenantspace.feature.customData.description": "After the data customization feature is enabled\, the tenant space supports the data customization function.",
  "provision.callcenter.resourceinfo.alert": "The following resource information must be consistent with the license information of devices such as the UAP and CTI.",
  "provision.tenantspace.config.tenant.custdata.featurevalue.not.one": "The value of the data customization feature allocated to the tenant is not 1.",
  "provision.callcenter.ivr.alert": "The value must be the same as the concurrent capability of the IVR deployed.",
  "provision.callcenter.sip.alert": "The value must be the same as the actual capability supported by the UAP and SBC.",
  "provision.callcenter.gateway.agentnum.alert": "The value must be the same as the actual configuration capability of the CC-Gateway cluster.",

  "provision.callcenter.gateway.address.alert": "CC-Gateway cluster load balancing address",

  "provision.callcenter.ucs.ip.alert": "CCUCS cluster load balancing address",
  "provision.callcenter.videoapplicationmodel.mcuAndsfuModel": "MCU+SFU video",
  "provision.callcenter.videoapplicationmodel.mcumodel": "MCU video",
  "provision.callcenter.videoapplicationmodel": "Video application mode",
  "provision.tenantspace.sfuprompt": "The system does not adapt to the SFU video. Customers can adapt to the SFU video during secondary development.",

  "provision.callcenter.videoapplicationmodel.sfumodel": "SFU video",
  "provision.tenantspace.feature.multiChannelSupport": "Multi-Channel support",
  "provision.tenantspace.feature.multiChannelSupport.description": "The Multi-Channel Support feature is enabled. Temporary queue adjustment can be performed for agents across service channels. Note: If an agent has the cross-service channel permission\, the agent occupies voice\, video\, and multimedia resources at the same time during sign-in. The agent under a tenant may not sign in for customer service due to resource preemption.",
  "provision.tenantspace.feature.outboundCallMarketingSupport.description": "After the intelligent outbound call feature is enabled\, the tenant space supports the intelligent outbound call function.",
  "provision.tenantspace.feature.outboundCallMarketingSupport": "Intelligent Outbound Call",
  "provision.tenantspace.feature.knowledgeBaseSupport.description": "After the knowledge base feature is enabled\, the tenant space supports the knowledge base function.",

  "provision.tenantspace.feature.knowledgeBaseSupport": "Knowledge base",

  "provision.tenantspace.feature.smsMarketingSupport": "SMS Marketing",
  "provision.tenantspace.feature.smsMarketingSupport.description": "This feature provides SMS marketing functions for the tenant space.",
  "provision.tenantspace.feature.emailMarketingSupport.description": "This feature provides email marketing functions for the tenant space.",
  "provision.tenantspace.feature.emailMarketingSupport": "Email Marketing",
  "provision.tenantspace.feature.whatsAppMarketingSupport": "WhatsApp Marketing",
  "provision.tenantspace.feature.whatsAppMarketingSupport.description": "This feature provides WhatsApp marketing functions for the tenant space.",
  "provision.tenantspace.feature.outboundCallMarketingSupport.error": "Disable the SMS\, email and WhatsApp marketing features first.",
  "provision.tenantspace.feature.smsOrEmailMarketingSupport.sendMaxNumber": "Sending Upper Limit Per Day",
  "provision.tenantspace.feature.smsOrEmailMarketingSupport.sendMaxNumber.placeholder": "The value ranges from 1 to 1000000000. The value 0 indicates no limit is specified.",
  "provision.tenantspace.feature.smsOrEmailMarketingSupport.sendMaxNumber.alert": "The maximum number of messages that can be sent every day ranges from 1 to 1000000000. The value 0 indicates that no limit is specified.",

  "provision.tenantspace.feature.smsOrEmailMarketingSupport.sendMaxNumber.whatsApp.placeholder": "The value ranges from 1 to 100000. The value 0 indicates no limit is specified.",
  "provision.tenantspace.feature.smsOrEmailMarketingSupport.sendMaxNumber.whatsApp.alert": "The maximum number of messages that can be sent every day ranges from 1 to 100000. The value 0 indicates that no limit is specified.",
  "provision.system.error": "The system is abnormal. Please try again later.",
  "provision.tenantspace.config.amsNumber.unequal": "The number of concurrent anonymous calls must be the consistent.",
  "provision.tenantspace.config.amsNumber.positiveInteger": "The number of concurrent anonymous calls can not be empty and must be a positive integer.",


  "provision.tenant.management.positiveInteger.checkmsg": "Enter a positive integer.",
  "provision.callcenterinstanceslist.table.ctiversion": "CTI Version",
  "provision.tenantspace.feature.OneClickTwoCallSupport": "One-Click Bidirectional Call",
  "provision.tenantspace.feature.OneClickTwoCallSupport.description": "This feature provides one-click bidirectional call functions for the tenant space.",
  "provision.tenantspace.feature.VoiceNotificationSupport": "Voice Notification",

  "provision.tenantspace.feature.VoiceNotificationSupport.description": "This feature provides voice notification functions for the tenant space.",
  "provision.tenantspace.feature.dualCallTransparentDisplaySupport.description": "After enabling this feature\, you can customize the numbers to be displayed to agents and subscribers (depending on whether the number line is connected). If the access code is empty\, the feature is enabled by default.",
  "provision.tenantspace.feature.dualCallTransparentDisplaySupport": "Calling Number Anonymization",

  "provision.tenantspace.step6": "Step 6",

  "provision.tenantspace.step7": "Step 7",
  "provision.tenantspace.chooseauthenticationsystem": "Configure Third-Party Authentication System",
  "provision.tenantspace.authenticationsystem.selectClientName": "Select a third-party authentication system.",
  "provision.tenantspace.authenticationsystem.enter.clientName": "Enter the name of a third-party authentication system.",
  "provision.tenantspace.authenticationsystem.mappingName": "Federation Mapping Rule",
  "provision.tenantspace.authenticationsystem.clientName": "Third-Party Authentication System",
  "provision.tenantspace.authenticationsystem.clientURL": "Third-Party Authentication System URL",
  "provision.encryptinfo.scenario": "Application Scenario",
  "provision.encryptinfo.algorithm": "Encryption Algorithm",
  "provision.encryptinfo.strength": "Strength",
  "provision.encryptinfo.paddingScheme": "Padding Mode",
  "provision.encryptinfo.mode": "Mode",
  "provision.encryptinfo.encryptKey": "Update Key",

  "provision.encryptinfo.version": "Version",
  "provision.encryptinfo.validityPeriod": "Validity Period (Month)",
  "provision.encryptinfo.metadata": "Encryption and decryption of metadata",
  "provision.encryptinfo.email": "Encryption and decryption of email content",
  "provision.encryptinfo.updateTime": "Updated On",
  "provision.encryptinfo.title.encrypted.information": "Encryption Information",
  "provision.encryptinfo.historical.version": "Earlier Version",
  "provision.encryptinfo.encryptKey.length.error": "Required key length:",
  "provision.encryptinfo.updateInterval.error": "The interval for updating the key cannot be less than 30 days.",
  "provision.encryptinfo.modedecrease": "The CBC mode is less secure than the GCM mode. Exercise caution when selecting the CBC mode.",
  "provision.encryptinfo.strengthdecrease": "Decreasing the strength decreases algorithm security. Exercise caution when selecting this option.",
  "provision.tenantspace.dataUpload.resourceDump": "Resource Dump",
  "provision.tenantspace.dataUpload.openResourceDump": "Provides the resource dump function for the tenant space. Note: Currently\, only HUAWEI CLOUD OBS is supported. To use the resource dump feature\, tenants need to purchase this service.",
  "provision.encryptinfo.update": "Update",

  "provision.tenantspace.obsServer.backup.datasource.name": "Standby Data Source Name",
  "provision.tenantspace.feature.dualChannelRecSupport": "Dual-Track Recording",

  "provision.tenantspace.feature.dualChannelRecSupport.description": "This feature provides dual-track recording functions for the tenant space.",

  "provision.tenantspace.dstEndDateVerification": "The DST end date can only be later than the DST start date.",
  "provision.tenantspace.basic.information": "Basic Tenant Information",
  "provision.tenant.management.tenantcreate.timezoneInfo": "Time Zone Information",
  "provision.tenant.management.timezone": "Time Zone",
  "provision.tenant.management.timezoneOffset": "Time Zone Offset",
  "provision.tenant.management.DSTOffset": "DST Offset (min)",
  "provision.tenant.management.daylightSavingTime": "DST",
  "provision.tenant.management.DSTStartDate": "DST Start Date",
  "provision.tenant.management.DSTStartTime": "DST Start Time",
  "provision.tenant.management.DSTEndDate": "DST End Date",
  "provision.tenantspace.option.month_1": "January",
  "provision.tenant.management.DSTEndTime": "DST End Time",
  "provision.tenantspace.option.month_3": "March",
  "provision.tenantspace.option.month_2": "February",
  "provision.tenantspace.option.month_4": "April",
  "provision.tenantspace.option.month_6": "June",
  "provision.tenantspace.option.month_5": "May",
  "provision.tenantspace.option.month_8": "August",
  "provision.tenantspace.option.month_9": "September",
  "provision.tenantspace.option.month_11": "November",
  "provision.tenantspace.option.month_10": "October",
  "provision.tenantspace.option.month_12": "December",
  "provision.tenantspace.option.weeklist_fir": "First",
  "provision.tenantspace.option.month_7": "July",
  "provision.tenantspace.option.weeklist_tid": "Third",
  "provision.tenantspace.option.weeklist_sec": "Second",
  "provision.tenantspace.option.weeklist_fthtolast": "Last but three",
  "provision.tenantspace.option.weeklist_tidtolast": "Last but two",
  "provision.tenantspace.option.weeklist_sectolast": "Last but one",
  "provision.tenantspace.option.weekday_sun": "Sunday",
  "provision.tenantspace.option.weeklist_firtolast": "Last",
  "provision.tenantspace.option.weeklist_fth": "Fourth",
  "provision.tenantspace.option.weekday_tue": "Tuesday",
  "provision.tenantspace.option.weekday_wed": "Wednesday",
  "provision.tenantspace.option.weekday_thu": "Thursday",
  "provision.tenantspace.option.weekday_fri": "Friday",
  "provision.tenantspace.option.weekday_sat": "Saturday",
  "provision.tenantspace.timezone": "Default time zone",
  "provision.tenantspace.modifyTimeZoneVerification": "Time zone information can be modified only for a tenant space in the commercial or trial commercial state.",
  "provision.tenantspace.timezone.updateconfim": "Confirm",
  "provision.tenantspace.timezone.updatemessage": "Modifying the time zone information may affect business processing during the transition period.",
  "provision.tenantspace.save.oldsuccess": "The time zone information is not modified.",
  "provision.tenantspace.option.weekday_mon": "Monday",
  "provision.tenantspace.verification.fail": "Failed to validate the time format.",

  "provision.title.select": "Select",
  "provision.tenantspace.timezone.save.success": "The time zone information is saved successfully.",
  "provision.tenant.management.tenantcreate.timezone.failed": "Failed to verify the time zone information.",

  "provision.tenantspace.timezone.config.error": "Failed to modify the time zone information.",
  "provision.callcent.error.savevccfirst": "The agent is not bound to the registration server. Save the VDN again.",

  "ccp.version.versioncode": "Version",
  "ccp.version.microservice": "Microservice",
  "provision.tenantspace.config.amsobjnum.unequal": "Set this parameter to the same value as that of the anonymous call server.",
  "provision.tenantspace.config.agentnum.limit": "A maximum of {0} agents can be subscribed to at a time.",
  "ccp.version.updateTime": "UpdateTime",
  "provision.tenantspace.dstoffsettime.verification.fail": "The value is an integer ranging from -1440 to 1440.",

  "provision.web.validator.ip": "Invalid IP address. (Enter an IPv4 or IPv6 address\, or a domain name.)",
  "provision.systemparam.success.save": "Parameter saved successfully",

  "provision.systemparam.error.save": "Parameter saving failed",
  "provision.systemparam.success.reset": "Parameter reset succeeded",
  "provision.systemparam.error.reset": "Parameter reset failed",

  "provision.systemparam.error.notmatchrule": "The parameter does not meet the check rule",
  "provision.systemparam.error.refresh": "Parameter refresh failed",
  "provision.systemparam.success.refresh": "Parameter refreshed successfully",
  "provision.systemparam.error.noparamselected": "Please select a parameter first",

  "provision.sysparam.config.reset": "Reset",
  "provision.sysparam.config.search": "Search",
  "provision.sysparam.config.refresh": "Refresh",
  "provision.sysparam.config.value": "Parameter Value",
  "provision.sysparam.config.itemname": "Parameter Name",
  "provision.sysparam.config.opterate": "Operation",
  "provision.sysparam.config.itemdesc": "Description",
  "provision.sysparam.config.checkmessage": "Contains special characters. (" < "\, " > "\, " / ", etc.)",
  "provision.sysparam.config.save": "Save",
  "provision.sysparam.config.mod": "Edit",
  "provision.sysparam.config.itemname.tips": "Enter a parameter name.",
  "provision.sysparam.config.select.null": "Please select a parameter.",
  "provision.sysparam.config.select.count": "A maximum of 10 parameters can be selected.",
  "provision.sysparam.config.item.detail": "Parameter information",
  "provision.sysparam.config.select.prompt": "Hints",
  "provision.sysparam.config.item.catalog": "Parameter Type",
  "provision.systemparam.error.validate.inner": "Validate parameter failed",
  "provision.systemparam.error.validate.ruleregexinvalid": "Validate rule syntax error",
  "provision.sysparam.config.title": "System parameters",
  "provision.systemparam.error.validate.ruleinvalid": "The verification rule is invalid",
  "provision.systemparam.error.validate.notmatchruleregex": "The input parameter does not match the regular expression of the verification rule",
  "provision.systemparam.error.validate.watermarkinfo.notmatchruleregex": "The parameter only allows 0 to 20 characters containing uppercase and lowercase letters\, numbers or -_/{'@'}.",
  "provision.systemparam.error.validate.notmatchrulealpha": "Please enter alphanumerics or underscores, and only start with letters or underscores",
  "provision.systemparam.error.validate.notmatchrule": "The input parameter does not match the verification rule",
  "provision.systemparam.error.validate.notmatchruleemail": "Please enter a valid email address",
  "provision.systemparam.error.validate.notmatchruleip": "Please enter a valid IPV4 or IPV6 address",
  "provision.systemparam.error.validate.notmatchrulemaxlength": "The input parameter exceeds the maximum length",
  "provision.systemparam.error.validate.notmatchrulemax": "The input parameter exceeds the maximum",
  "provision.systemparam.error.validate.notmatchruledigits": "Please enter a valid positive integer",
  "provision.systemparam.error.validate.notmatchruleminlength": "The input parameter is less than the minimum length",
  "provision.systemparam.error.validate.notmatchrulemin": "The input parameter is lower than the minimum",
  "provision.systemparam.error.validate.notmatchruleoption": "The input parameter is not in the enumeration range",
  "provision.systemparam.error.validate.notmatchrulenumber": "Please enter a number",
  "provision.systemparam.error.validate.notmatchrulerangelength": "The input parameter exceeds the maximum length or is below the minimum length",
  "provision.systemparam.error.validate.notmatchrulerange": "The input parameter exceeds the maximum value or is below the minimum value",
  "provision.systemparam.error.validate.notmatchruleurl": "Please enter a valid URL address",
  "provision.systemparam.error.validate.notmatchrulerequired": "The input parameter is required",
  "provision.log.downloadSK": "Download SK",
  "provision.systemparam.error.validate.userNotExists": "Please enter a valid user account",
  "provision.tenantspace.feature.operation": "The tenant feature data is being processed at the backend. View the feature processing result on the current page 2 minutes later.",
  "provision.ccp.redis.feature.success": "The tenant feature data is executed successfully.",
  "provision.ccp.redis.feature.inprogress": "The tenant feature data is being executed.",
  "provision.tenantspace.feature.agentSendLocalMediaFile": "Local Multimedia File Sending by Agents",
  "provision.tenantspace.feature.emailAgentSendLocalAttachment": "Local Attachment Sending by Email Agents",
  "provision.tenantspace.feature.agentSendLocalMediaFile.description": "This feature provides the function of sending local multimedia files by agents for the tenant space.",
  "provision.tenantspace.feature.emailAgentSendLocalAttachment.description": "This feature provides the function of sending local attachments by email agents for the tenant space.",
  "provision.ccp.redis.feature.failed": "Failed to execute tenant feature data.",
  "pprovision.tenantspace.feature.emailAgentCCAndBcc.description": "Enable the email agent copy and Bcc feature. The leased space supports email agent copy and Bcc.",
  "provision.tenantspace.feature.emailAgentCCAndBcc": "Email Agent CC Bcc",
  "provision.tenantspace.feature.emailAgentForwarding": "Mail Agent Forwarding",
  "pprovision.tenantspace.feature.emailAgentForwarding.description": "Enable the email agent forwarding feature. The leased space supports the email forwarding function of the email agent.",

  "provision.speciallist.tip.userNoLength": "The subscriber number cannot exceed 100 characters",

  "provision.speciallist.tip.reasonLength": "The reason cannot exceed 1024 characters",

  "provision.speciallist.tip.timeVerification": "Please select a date greater than today and less than 2038-01-19",
  "provision.tenantspace.feature.cobrowse.description": "This feature provides the co-browsing function for the tenant space.",
  "provision.tenantspace.feature.cobrowse": "Co-browsing",
  "provision.callcent.platformserver.cobrowse": "Co-browsing Service (Co-Browse)",
  "provision.tenantspace.feature.cobrowse.cobrowseMaxNumber.placeholder": "1\u201350000",
  "provision.tenantspace.feature.cobrowse.cobrowseMaxNumber": "Maximum Number of Co-browsing Connections",
  "provision.tenantspace.feature.cobrowse.selectcobrowsevalue.invalid": "The maximum number of co-browsing connections ranges from 1 to 50000.",
  "provision.tenant.management.tenantmodify.cobrowse.akempty": "The AK information cannot be empty because you have enabled the co-browsing feature for the tenant.",
  "provision.tenantspace.feature.cobrowse.needak": "You need to configure AK information before enabling the co-browsing feature.",
  "provision.tenantspace.configureauthentication": "Configure Authentication",

  "provision.spinfo.id": "ID",
  "provision.spinfo.issuerId": "Issuer ID",
  "provision.spinfo.serviceUrl": "Service URL",
  "provision.spinfo.creator": "Creator",
  "provision.spinfo.logoutUrl": "Logout URL",
  "provision.spinfo.issuerId.search": "Enter an Issuer ID.",
  "provision.spinfo.description": "Description",
  "provision.spinfo.certificate": "Certificate Content",
  "provision.spinfo.createTime": "CreateTime",
  "provision.spinfo.validatehttp": "The URL format is incorrect.",
  "provision.spinfo.idpfile": "IDP file",
  "provision.spinfo.willdeletespinfo": "Are you sure you want to delete the selected SP information?",
  "provision.spinfo.selecdeletetspinfo": "Please select the SP information to be deleted.",
  "provision.spinfo.modifyspinfofailed": "Modifying SP information failed.",
  "provision.spinfo.addspinfofailed": "Failed to add SP information.",
  "provision.spinfo.addspinfosuccess": "SP information added successfully.",
  "provision.spinfo.deletespinfosuccess": "SP information deleted successfully.",
  "provision.spinfo.deletespinfofailed": "Deleting SP information failed.",
  "provision.spinfo.modifyspinfosuccess": "SP information modified successfully.",
  "provision.spinfo.beginlettervalidate": "Please start with a letter.",

  "provision.accesscode.message.invalid": "Invalid characters.",
  "provision.spinfo.msg.certificateformatinfo": "The start and end identifiers -----BEGIN CERTIFICATION--- and -----END CERTIFICATION--- cannot be omitted.",
  "provision.accesscode.table.enterpriseName": "Enterprise Name",
  "provision.accesscode.table.callout": "Outgoing call",
  "provision.accesscode.table.callType": "Call Type",
  "provision.accesscode.table.call": "Incoming and outgoing calls",

  "provision.accesscode.table.callin": "Incoming call",
  "provision.tenantspace.feature.appCubeSupport": "AppCube",
  "provision.tenantspace.feature.selectAppCubeSupport.description": "Enable the AppCube feature so that the tenant space can integrate the AppCube function.",
  "provision.tenantspace.feature.appcube.close.error": "Before disabling the AppCube feature\, disable the {0} base features.",
  "provision.tenantspace.feature.case.open.confirmmsg": "The AppCube feature is the basis for running the Case feature and will be enabled at the same time.",
  "provision.tenantspace.feature.newcase.open.customcenter.confirmmsg": "The AppCube and Customer center features will also be enabled because they are the basis for running the Case 2.0 feature.",
  "provision.tenantspace.feature.appcubeIkbs.close.error": "Disable the Knowledge base feature before disabling the AppCube feature.",
  "provision.tenantspace.feature.knowledge.open.confirmmsg": "The AppCube feature is the basis for running the Knowledge base feature and will be enabled at the same time.",

  "provision.appversionmanagement.systemType": "System Type",
  "provision.appversionmanagement.versionNumber": "Version Number",
  "provision.appversionmanagement.pleaseEnter": "-Enter-",
  "provision.appversionmanagement.pleaseSelect": "-Select-",
  "provision.appversionmanagement.versionName": "Version Name",
  "provision.appversionmanagement.new": "Add",
  "provision.appversionmanagement.batchDelete": "Batch Delete",
  "provision.appversionmanagement.forceUpdate": "Forcible Update",
  "provision.appversionmanagement.updateContent": "Update Content",
  "provision.appversionmanagement.downloadAddress": "Download Address",
  "provision.appversionmanagement.appPackage": "Version Package",
  "provision.appversionmanagement.updateTime": "Update Time",
  "provision.appversionmanagement.iOS": "IOS",
  "provision.appversionmanagement.android": "Android",
  "provision.appversionmanagement.androidH5": "Android H5",
  "provision.appversionmanagement.harmony": "HarmonyOS",
  "provision.appversionmanagement.sureDeleteVersionNumber": "Are you sure you want to delete the version information?",
  "provision.appversionmanagement.iOSH5": "IOS H5",
  "provision.appversionmanagement.historicalVersion": "Historical version",
  "provision.appversionmanagement.selectDataDeleted": "Select the data to be deleted.",
  "provision.appversionmanagement.latestVersion": "Latest Version",
  "provision.appversionmanagement.sureDeleteData": "Are you sure you want to delete the data?",
  "provision.appversionmanagement.cannotEnterNumberSmaller": "The value must be greater than the maximum version number.",
  "provision.appversionmanagement.cannotContainSpecialCharacters": "The value cannot contain the following special characters: ~!{'@'}#$%&*()/\\_=+{}:'\"<>?[]",

  "provision.appversionmanagement.queryFailed": "Search error.",
  "provision.appsitesmanagement.pleaseEnter": "-Select-",

  "provision.appsitesmanagement.siteName": " Site Name",
  "provision.appsitesmanagement.batchDelete": "Batch Delete",
  "provision.appsitesmanagement.siteDesc ": "Site Description",
  "provision.appsitesmanagement.new": "Add",
  "provision.appsitesmanagement.siteAddr ": "Site Address",
  "provision.appsitesmanagement.videoAvailable ": "Video Available",
  "provision.appsitesmanagement.creator ": "Creator",
  "provision.appsitesmanagement.appcubeAvailable ": "AppCube Available",
  "provision.appsitesmanagement.modifyTime ": "Modify Time",
  "provision.appsitesmanagement.modifier ": "Modifier",
  "provision.appsitesmanagement.createTime ": "Create Time",
  "provision.appsitesmanagement.sureSelectDataDeleted": "Select the data to be deleted.",
  "provision.appsitesmanagement.sureDeleteData": "Deleting this site may cause users to fail to log in to the mobile app. Are you sure you want to delete it?",
  "provision.appsitesmanagement.queryFailed ": "Search error.",
  "provision.appsitesmanagement.sureBatchDeleteData": "Deleting these sites may cause users to fail to log in to the mobile app. Are you sure you want to delete them?",
  "provision.appsitesmanagement.cannotContainSpecialCharacters": "The value cannot contain the following special characters: ~!{'@'}#$%&*()/\\_=+{}:'\"<>?[]",
  "provision.appsitesmanagement.unable": "No",
  "provision.appsitesmanagement.enable": "Yes",
  "provision.appsitesmanagement.protocol.check": "Note that the network protocol used by the entered site address is not a secure protocol. If you are sure to use it\, security risks may exist.",
  "provision.callcenterinstanceslist.softPhone.authtype": "Authentication Mode",
  "provision.appsitesmanagement.details ": "Site Details",
  "provision.softphone.label.authtype.uap": "UAP Authentication",
  "provision.softphone.label.authtype.unified": "Unified authentication",
  "provision.tenantspace.feature.softphonenounifiedauth": "softphone number unified authentication",
  "provision.tenantspace.feature.softphonenounifiedauth.description": "Enable the softphone number unified authentication feature so that the tenant room supports unified authentication of softphone numbers.",
  "provision.systemparam.url.safe.tip": "Insecure protocols are used, causing security risks",
  "provision.log.downloadIdpMetadtaXml": "Download IDP Metadata Xml",

  "provision.tenantspace.save.successwithsamevccid": "Saving succeeded. A tenant space whose VCC ID is the same as the current tenant space exists. Contact the administrator.",

  "provision.appversion.msg.uploadfile.data.fail": "Incorrect file parameters.",

  "provision.appversion.msg.uploadfile.type.fail": "Incorrect file format.",
  "provision.appversion.msg.uploadfile.0.success": "File verification successful.",
  "provision.appversion.msg.uploadfile.1.fail": "File name verification failed.",
  "provision.appversion.msg.uploadfile.2.fail": "File format verification failed.",
  "provision.appversion.msg.uploadfile.3.fail": "File size verification failed. Check the system parameter Maximum Size of an Uploaded Mobile App Installation Package on the Settings > System Parameter Configuration page.",
  "provision.appversion.msg.uploadfile.fail": "Failed to upload the file. Modify and upload it again.",
  "provision.appversion.msg.uploadfile.4.fail": "The file has injection risks. Perform an analysis.",
  "provision.appversion.msg.uploadfile.9.fail": "File verification failed.",
  "provision.appversion.msg.process.fail": "Failed to process the app version information.",
  "provision.appversion.msg.uploadfile.strem.fail": "Failed to generate the file stream.",
  "provision.callcent.packagefile.saveinfo": "Creation successful. The version package is being processed. Wait for the page to refresh the version package processing result.",
  "provision.appversionmanagement.appPackageTips": "Only an .apk file can be uploaded.",
  "provision.callcent.packagefile.updateinfo": "Update successful. The version package is being processed. Wait for the page to refresh the version package processing result.",
  "provision.ccp.redis.packagefile.success": "The version package is uploaded successfully.",
  "provision.ccp.redis.packagefile.failed": "Failed to upload the version package.",
  "provision.appversion.msg.process.modify": "Modify app version",
  "provision.ccp.redis.packagefile.inprogress": "The version package is being uploaded.",

  "provision.appversion.msg.process.add": "Add app version",
  "provision.appversion.msg.throttling.fail": "Create or modify the interface traffic limit every 2 minutes. Try again later.",
  "provision.appversionmanagement.protocol.check": "Note that the network protocol used by the entered download address is not secure. If you are sure to use it\, security risks may exist.",
  "provision.whiteListAddress.channel.push": "Multimedia channel message push address",
  "provision.whiteListAddress.downlink.message.apifabric.push": "Multimedia Message Downstream API Fabric Address",
  "provision.appversionmanagement.regVersion": "The version number consists of letters\, digits and dots. It cannot start or end with a dot.",
  "provision.callcent.platformserver.kafka": "Message middleware (Kafka)",
  "provision.callcent.platformserver.kafka.switch.panel": "Indicates whether to enable the service.",
  "provision.whiteListAddress.callout.push": "Outbound Call Result Push Address",
  "provision.whiteListAddress.internal.message.notification": "IP Address and Port for Invoking App Service for Internal Message Notification",

  "provision.callcent.platformserver.account": "Service account",
  "provision.callcent.platformserver.es": "Searching for services (ElasticSearch)",
  "provision.tenantspace.feature.OneClickTwoCallSupport.featureParams.label": "Call parameters",
  "provision.callcent.platformserver.certificateChoose": "Select Certificate",

  "provision.tenantspace.feature.OneClickTwoCallSupport.featureParams.callPeriod.label": "Call period(1s to 60s)",
  "provision.tenantspace.feature.OneClickTwoCallSupport.featureParams.placeholder": "Set this parameter based on the number of concurrent IVR sessions\, number of concurrent core network sessions\, and number of concurrent line sessions configured by the tenant.",
  "provision.tenantspace.feature.OneClickTwoCallSupport.featureParams.callNums.label": "Number of call data(1 to 10)",
  "provision.tenantspace.feature.VoiceNotificationSupport.featureParams.label": "Call parameters",
  "provision.tenantspace.feature.VoiceNotificationSupport.featureParams.placeholder": "Set this parameter based on the number of concurrent IVR sessions\, number of concurrent core network sessions\, and number of concurrent line sessions configured by the tenant.",
  "provision.tenantspace.feature.VoiceNotificationSupport.featureParams.callPeriod.label": "Call period(1s to 60s)",
  "provision.tenantspace.feature.VoiceNotificationSupport.featureParams.callNums.label": "Number of call data(1 to 10)",
  "provision.subscribedetail.pageTitle": "One-Click Subscribe Details",

  "provision.subscribedetail.button.save": "Save",
  "provision.subscribedetail.button.reset": "Reset",
  "provision.subscribedetail.button.search": "Query",
  "provision.subscribedetail.button.delete": "Delete",
  "provision.subscribedetail.label.operate": "Operate",
  "provision.subscribedetail.label.companyName": "Company",
  "provision.subscribedetail.button.update": "Edit",
  "provision.subscribedetail.label.phoneNo": "Contact Phone",
  "provision.subscribedetail.label.emailAddr": "Contact Email",
  "provision.subscribedetail.label.createTime": "Applied At",
  "provision.subscribedetail.label.companySize": "Company Size",
  "provision.subscribedetail.label.enableStatus": "Subscription",
  "provision.subscribedetail.label.remark": "Remark",
  "provision.subscribedetail.label.emailStatus": "Email Sending",
  "provision.subscribedetail.label.contactName": "Contact Name",
  "provision.subscribedetail.success.save": "Saved successfully.",
  "provision.subscribedetail.success.delete": "Delete successfully.",
  "provision.subscribedetail.success.title": "Success",
  "provision.subscribedetail.delete.confirm": "Are you sure you want to delete the subscription details?",
  "provision.subscribedetail.delete.title": "Delete confirmation",
  "provision.subscribedetail.error.title": "Error",
  "provision.subscribedetail.error.save": "Saving failed.",
  "provision.subscribedetail.error.delete": "Delete failed.",
  "provision.subscribedetail.error.query": "Query failed.",
  "provision.subscribedetail.error.query.maxDay_30": "The query interval cannot be longer than 30 days.",
  "provision.subscribedetail.enableStatus.value_1": "Subscribe successfully",
  "provision.subscribedetail.emailStatus.value_0": "Submit failed",
  "provision.subscribedetail.enableStatus.value_0": "Subscribe failed",
  "provision.subscribedetail.emailStatus.value_1": "Submit successfully",
  "provision.subscribedetail.validate.alphavalidate": "Invalid characters exist. Only letters\, digits\, and underscores (_) are supported.",
  "provision.subscribedetail.validate.laterThanEndTime": "The start time cannot be later than the end time.",
  "provision.subscribedetail.validate.beginwithalpha": "Must start with a letter.",
  "provision.subscribedetail.label.createSource": "Source",
  "provision.subscribedetail.createSource.value_0": "HUAWEI CLOUD official website",
  "provision.subscribedetail.createSource.value_1": "Software Cloud official website",


  "provision.tenantspace.feature.dualCallAPPTransparentDisplaySupport": "App Call Task",
  "provision.tenantspace.feature.dualCallAPPTransparentDisplaySupport.description": "This feature enables agents of the tenant to handle call tasks on mobile apps.",
  "provision.tenantspace.feature.onlineCustomerServiceAssets.description": "This feature enables the tenant to embed JS online chat clients developed in AppCube into third-party pages to implement online chat.",
  "provision.tenantspace.feature.onlineCustomerServiceAssets.open.confirmmsg": "The AppCube and intelligent IVR features will also be enabled because they are the basis for running the online customer service asset feature.",
  "provision.tenantspace.feature.onlineCustomerServiceAssets": "Online Customer Service Asset",
  "provision.tenantspace.feature.smartivr.close.error": "Disable the online customer service asset feature before disabling the intelligent IVR feature.",
  "provision.tenantspace.feature.dualCallAppSupport.open.confirmmsg": "The AppCube feature will also be enabled because it is the basis for running the app call task feature.",
  "provision.tenant.management.tenantexport.addTask": "Creating an Export Task",
  "provision.tenant.management.tenantexport.viewTask": "Querying Export Tasks",
  "provision.tenant.management.tenantexport.cancel": "Cancels",
  "provision.tenant.management.tenantexport.exceedlimit1": "A maximum of \{0} data records can be exported.",
  "provision.tenant.management.tenantexport.exceedlimit2": ", narrow down the data time range or add data filtering conditions.",

  "provision.tenant.management.tenantexport.confirm": "Acknowledgement",
  "provision.tenant.management.tenantexport.addTaskSuccess": "The export task is added successfully.",
  "provision.tenant.management.tenantexport.addTaskError": "Failed to add the export task. Please check the log.",
  "provision.label.tenantexport.exception": "An exception occurred during the export task generation. Please check the log.",
  "provision.label.tenantexport.parainvalid": "Failed to verify the export condition.",
  "provision.label.tenantexport.paracomplexity": "The password complexity does not meet requirements.",
  "provision.common.message.error": "Errors",
  "provision.label.tenantexport.parainnull": "Please select the data to be exported.",
  "provision.common.message.success": "Success",
  "provision.label.tenantexport.addExportTask": "Adding an Export Task",
  "provision.label.tenantexport.viewExportTask": "Viewing Export Tasks",
  "provision.label.tenantexport.confirm": "Acknowledgement",
  "provision.label.information": "hints",
  "provision.label.tenantexport.confirmMessage": "Are you sure you want to create an export task?",
  "provision.label.tenantexport.refresh": "refresh",
  "provision.label.tenantexport.download": "download",
  "provision.label.tenantexport.exportEndTime": "Export End Time",
  "provision.label.tenantexport.exportBeginTime": "Export Start Time",
  "provision.label.tenantexport.exportTaskStatus": "Export Task Status",
  "provision.label.tenantexport.operation": "Operation",
  "provision.label.tenantexport.conditions": "Search criteria",
  "provision.label.tenantexport.addExportTaskSuccess": "The export task is added successfully.",
  "provision.label.tenantexport.addExportTaskError": "Failed to add the export task. Please check the log.",
  "provision.label.tenantexport.success": "Success",
  "provision.label.tenantexport.exportiong": "Exporting",
  "provision.label.tenantexport.zippwd": "Compressed password",
  "provision.label.tenantexport.passValidateFailed": "The value must contain letters\, digits\, and special characters _ {'@'}%.",
  "provision.label.tenantexport.inputzippwd": "Enter the compression password.",
  "provision.label.tenantexport.importdata": "Importing Data",
  "provision.label.tenantexport.popwindowmessage": "Enter the password for generating compressed files. The password must contain letters\, digits\, and special characters _ {'@'}%.",
  "provision.label.tenantexport.unprocessed": "Unprocessed",
  "provision.tenantexport.download": "Downloading Tenant Information",
  "provision.tenant.management.tenantcreate.param.content.rule": "The input parameter consists of letters\, digits\, and underscores (_)\. It must start with a letter and must contain at least two characters.",
  "provision.tenant.management.tenantcreate.param.exceed": "The length of the input parameter must be less than 30 characters.",
  "provision.label.tenantexport.failed": "fail",
  "provision.tenant.management.tenantcreate.param.failed": "The input parameter is invalid.",
  "provision.tenantspace.feature.transfer.onlinecheck.description": "Enable the online query feature of dump resources, and rent room supports online query of dump resources",
  "provision.tenantspace.feature.transfer.onlinecheck": "View dump resources online",
  "provision.tenantspace.detail.tenantspace.cannot.open.uploadfeature": "Whether to turn on the resource dump and the online query feature of dump resources at the same time",
  "provision.tenantspace.open.uploadfeature": "Confirm to open",
  "provision.vcallcenter.message.unknowdesc": "Unknown exception.",
  "provision.vcallcenter.message.unknowsolution": "Contact Huawei engineers to analyze and rectify the fault",
  "provision.tenantspace.detail.tenantspace.cannot.close.uploadfeature": "Please turn off the dump resource online review feature first",
  "provision.whiteListAddress.resourcedump.css": "Resource dump CSS server",
  "provision.vcallcenter.message.unknowcause": "Unknown cause",
  "provision.whiteListAddress.resourcedump.cssproxy": "Resource dump CSS server agent",
  "provision.systemparam.algorithm.unsafe": "An insecure algorithm is used, which poses security risks",

  "provision.systemparam.algorithm.suggest": "Caution: be consistent with UAP",

  "provision.tenantspace.feature.contractdigitalsignature": "Contract Digital Signature",
  "provision.tenantspace.feature.contractdigitalsignature.description": "This feature enables digital signatures for electronic contracts.",
  "provision.callcenterinstanceslist.vcallcenter.ccdis": "CCDIS Server",

  "provision.callcenterinstanceslist.ccdis.ccdisId": "ID",
  "provision.callcenterinstanceslist.ccdis.ccdisIP": "Primary IP Address",
  "provision.callcenterinstanceslist.ccdis.ccdisbackupIP": "Standby IP Address",
  "provision.callcenterinstanceslist.ccdis.remark": "Usage Description",
  "provision.callcenterinstanceslist.ccdis.poolNodeId2": "CTI node to which the standby CCDIS belongs",
  "provision.callcenterinstanceslist.ccdis.poolNodeId": "CTI node to which the active CCDIS belongs",
  "provision.callcenterinstanceslist.ccdis.progId": "ProgID",
  "provision.callcenterinstanceslist.ccdis.tenantSpaceName": "Tenant Name",
  "provision.callcenterinstanceslist.ccdis.syncconfim.msg": "Are you sure you want to synchronize CCDIS configurations?",
  "provision.tenantspace.config.chooseCcdis": "Please select a CCDIS server.",
  "provision.callcenterinstanceslist.ccdis.ccdisDeleteConfirm": "Are you sure you want to delete the selected CCDIS server?",
  "provision.callcenterinstanceslist.ccdis.ccdisNetId": "Active NE ID",
  "provision.callcenterinstanceslist.ccdis.ccdisUpdate": "Editing",
  "provision.callcent.error.updateccdis.associationvcallcener": "The selected CCDIS server is associated with tenant and cannot be updated.",
  "provision.callcent.error.deleteccdis.associationvcallcener": "The selected CCDIS server is associated with tenant and cannot be deleted.",
  "provision.tenantspace.list.search.date.error": "Invalid tenant expiration date. Please enter a correct date range.",

  "provision.target.selectDate": "Select Date",
  "provision.callcent.error.deleteinfo.from.cms.failed": "The CMS fails to delete related information.",

  "provision.callcent.error.deleteinfo.from.cms.bad.input": "Failed to verify the CMS input.",
  "provision.callcent.error.deleteinfo.from.cms.unknown.error": "An unknown error occurred in the CMS.",
  "chat.usefulexpressions.message.saveSuccess": "Common phrase saved successfully.",
  "chat.client.greeting.dear": "The honorable",
  "chat.agent.title.enterText": "Please enter",
  "chat.usefulexpressions.message.contentLength": "Ensure that the common phrase contains a maximum of 1000 characters.",
  "chat.agent.title.transfer": "Transferred to",
  "provision.callcenterinstanceslist.vcallcenter.comma": ";",
  "provision.callcenterinstanceslist.config.SysAdminAccount": "System administrator account",
  "chat.skill.label.inputName": "Name",
  "chat.session.label.evaluation": "Session evaluation",
  "chat.session.label.exitQueueTime": "Out-of-queue time",

  "provision.callcent.error.entrynotexsit": "The trustlist record does not exist.",
  "chat.agent.label.send": "Sending",
  "chat.session.endType.customerEnd": "End of customer",
  "chat.skill.message.success": "Success.",
  "chat.skill.label.skillDetail": "Skill Details",
  "chat.skill.message.isExisted": "The skill name already exists.",
  "chat.usefulexpressions.message.selectExpressions": "Please select a common phrase.",
  "chat.weChatConfig.message.skillExisted": "The selected skill queue has been bound to another official account.",
  "chat.thirdportal.chatWindow.label.userName": "Name",
  "chat.agentConsole.message.content.audio": "[voice message]",
  "chat.client.label.loginTitle": "Login",
  "OPERLOGS.TENANTSPACE.SYNC": "Synchronize the tenant space.",
  "chat.skill.label.maxQueueTimeInTable": "Maximum queuing time",
  "chat.client.label.chatTitle": "Client",
  "chat.webconfig.label.greeting": "Greetings",
  "chat.thirdportal.chatWindow.label.userEmail": "Mailbox",
  "chat.weChatConfig.title.deleteTitle": "Deleting a WeChat Configuration Item",
  "chat.skill.message.willDeleteSkill": "Are you sure you want to delete the skill?",
  "chat.client.message.evaluationFail": "Satisfaction survey failed.",
  "chat.weChatConfig.label.codeName": "Channel access code.",
  "chat.weChatConfig.message.ensureDelete": "Are you sure you want to delete the selected configuration item?",
  "chat.client.label.order": "My Orders",
  "chat.webconfig.label.intergratedcode": "Integration Code",
  "provision.callcent.error.ccInstidInvalid": "Invalid call center instance.",
  "chat.session.channel.all": "All of them",
  "chat.skill.label.reset": "resetting",
  "chat.usefulexpressions.message.contentNotNull": "Common phrases cannot be empty.",
  "chat.timerange.message.timeerror": "The selected time format is incorrect.",
  "chat.agent.label.offline": "Offline",
  "chat.session.label.allChannel": "All access channels",
  "chat.weChatConfig.stepname.verify": "Submit for verification",
  "provision.callcenterinstanceslist.softPhone.vcallcenterid": "ID of a virtual call center.",
  "provision.systemparam.url.unsafe": "(unsafe)",
  "provision.tenantspace.dataUpload.resourceType": "Resource Dump Type",
  "chat.agent.label.chatBot": "Intelligent customer service",
  "chat.weChatConfig.title": "Social Media Provisioning Configuration",
  "chat.session.label.subChannel": "Sub-Access Channel",
  "chat.timerange.label.sevendays": "Last 7 days",
  "chat.thirdportal.chatWindow.title": "Online customer service",
  "chat.weChatConfig.stepname.success": "The access is successful.",
  "provision.tenantspace.dataUpload.reportData": "Report data",
  "provision.callcenterinstanceslist.vcallcenter.vccId": "VCC ID",
  "provision.callcent.error.redisfail": "Failed to update the CC-AI Redis.",
  "provision.callcenterinstanceslist.config.startworkno": "Work ID Start Number",
  "provision.tenantspace.config.agentcall.zero": "The number of concurrent voice calls cannot be 0.",
  "chat.weChatConfig.stepname.selectchannel": "Selecting an access channel",
  "chat.weChatConfig.label.nextStep": "The next step",
  "chat.thirdportal.chatWindow.label.userPhone": "Number",
  "provision.callcenterinstanceslist.vcallcenterDeatil.vccEnabled": "Indicates whether to enable the VDN.",
  "chat.skill.message.warning": "warnings",
  "chat.timerange.message.timeempty": "The selected time cannot be empty.",
  "provision.batchimport.message.uploaded.button": "Upload",
  "chat.session.label.channel": "Session access channel",
  "provision.callcenterinstanceslist.config.agentUsage": "Number of Assigned Agents",
  "chat.session.label.created_startTime": "Start Time",
  "provision.callcenterinstanceslist.vcallcenterDeatil.ccInstId": "Call Center No.",
  "chat.session.label.evaluationTime": "Session Evaluation Time",
  "chat.agent.label.waiting": "Waiting for",
  "chat.session.channel.facebook": "Facebook",
  "provision.callcenterinstanceslist.vcallcenterDeatil.vccName": "VDN name",
  "chat.skill.message.failDeleteSkill": "Failed to delete the skill.",
  "chat.weChatConfig.message.unknown": "Verification status unknown!",
  "provision.tenantspace.table.maxcallnums": "Maximum number of concurrent voice calls (> = Sum of the number of voice agents and the number of audio and video IVR channels)",
  "chat.webconfig.label.basesettings": "Basic Configuration",
  "provision.callcenterinstanceslist.vcallcenterDeatil.tenantSpaceId": "Tenant ID",
  "chat.agentConsole.message.error.notification": "Cannot create a new message reminder. Please check your browser configuration.",
  "chat.weChatConfig.label.state": "Status",
  "chat.client.evaluate.message": "Please comment on my service\, thank you!",
  "chat.session.channel.web": "Web page",
  "chat.client.label.promotions": "Hints",
  "chat.skill.label.name": "User name",
  "chat.session.channel.twitter": "twitter",
  "chat.agent.message.changeFailed": "Failed to change the agent status.",
  "chat.client.label.input": "Please enter",
  "chat.agentConsole.message.content.video": "[Video Message]",
  "chat.webconfig.label.intergratemsg": "Integrate Live Chat to your Website",
  "chat.agentConsole.message.content.connectMessage": "You have a new message!",
  "provision.sysparam.config.reset.multiple.error": "The following parameters cannot be reset:",
  "chat.session.label.endReason": "Session End Reason",
  "chat.session.label.createdTime": "Session creation time.",
  "provision.callcent.error.pagechangednowrefresh": "The page content has changed and needs to be refreshed.",
  "chat.session.label.search": "Searching for",
  "chat.session.label.sessionId": "Session ID",
  "chat.session.label.allEndType": "All End Types",
  "chat.skill.label.deleteSkills": "Deletion",
  "chat.session.label.reset": "resetting",
  "chat.usefulexpressions.message.saveFail": "Failed to save the common phrase.",
  "chat.usefulexpressions.message.failDeleteExpression": "Failed to delete the common phrase.",
  "chat.client.label.login": "Login",
  "provision.tenantspace.dataUpload.choose": "Please select...",
  "chat.agent.title.createCase": "Creating a Case",
  "chat.session.channel.wechat": "Wechat",
  "chat.weChatConfig.label.qcode": "Scan code hosting",
  "chat.skill.label.priority": "Priority",
  "chat.skill.label.ok": "Confirmed",
  "chat.weChatConfig.label.delete": "Deletion",
  "chat.skill.message.delete": "Deletion",
  "chat.webconfig.label.windowTitle.placeholder": "Enter the title to be displayed in the client chat window.",
  "chat.webconfig.message.saveFail": "Failed to save the configuration.",
  "chat.skill.message.priorityLength": "Ensure that the priority contains no more than five digits.",
  "chat.skill.message.createSuccess": "Skill created successfully.",
  "provision.callcenterinstanceslist.whiteList.whiteListAdd": "Adding a trustlist",
  "chat.session.endType.agentEnd": "Agent End",
  "chat.skill.label.skillId": "No.",
  "provision.tenantspace.ccName": "Call center",
  "chat.session.label.enterQueueTime": "Enqueuing Time",
  "chat.weChatConfig.message.emptyDeleteArray": "Please select the configuration item to be deleted.",
  "chat.session.title.basic": "Basic session information",
  "chat.client.label.yes": "It's the",
  "chat.skill.label.maxQueueTime": "Max Queue Time (s)",
  "chat.usefulexpressions.message.willDeleteExpression": "Are you sure you want to delete the common phrase?",
  "chat.client.button.submit": "Submitting",
  "chat.timerange.label.ok": "Confirmed",
  "chat.skill.label.cancel": "To cancel",
  "provision.callcent.error.savewhiteaddripinfo": "The trustlist address format is incorrect.",
  "chat.skill.message.willDeleteAllSkills": "Are you sure you want to delete all skills?",
  "provision.callcenterinstanceslist.vcallcenterDeatil.accessCode": "Access code allocated to the VDN.",
  "chat.session.label.endType": "Session End Type",
  "chat.thirdportal.chatWindow.button.startChat": "Start a conversation",
  "chat.weChatConfig.label.secret": "Key Escrow",
  "provision.callcenterinstanceslist.vcallcenter.agentNum": "Number of Assigned Agents",
  "provision.tenantspace.dataUpload.voiceFile": "Voice file",
  "chat.agentConsole.message.content.locate": "[Location Message]",
  "chat.agent.label.historicalBill": "Historical Bills",
  "provision.callcenterinstanceslist.vcallcenter.period": ".",
  "chat.session.label.endTime": "Session end time",
  "chat.client.label.accountNumber": "Account Number",
  "chat.client.message.hiMyWorkno": "Hello\, my employee ID is",
  "chat.agent.label.mybill": "My bill.",
  "chat.weChatConfig.label.bindSkill": "Binding a Skill Queue",
  "chat.skill.label.submit": "Submitting",
  "chat.client.message.evaluationSuccess": "Satisfaction survey succeeded.",
  "chat.session.title.detail": "Session Details",
  "chat.webconfig.message.configdirective": "Copy and paste the following code to the </body> tag on your website\, save and release the code to quickly access Huawei Service Cloud. After the code is released\, the Huawei Service Cloud entry will be displayed in the lower right corner of your website. You can click to have a real-time conversation with the customer service workbench.",
  "chat.weChatConfig.label.verify": "Verifying",
  "chat.session.label.remark": "Comment on",
  "chat.usefulexpressions.message.createSuccess": "Common phrase created successfully.",
  "provision.callcent.error.saveucsserverdesinfo.ucsServernoexist": "The CCUCS server does not exist.",
  "chat.common.message.delete": "Deletion",
  "chat.agent.title.userInformation": "Customer Information",
  "chat.client.label.rateService": "Please rate the service",
  "chat.client.greeting.evening": "Good evening!",
  "provision.batchimport.message.import.button": "Import",
  "chat.skill.message.saveSuccess": "Skill saved successfully.",
  "chat.weChatConfig.label.create": "Add",
  "chat.webconfig.label.functionalsettings": "Function Settings",
  "chat.skill.label.createSkill": "Create",
  "provision.tenantspace.popuppageurl.modifyprompt": "Change the IP address in the trustlist first.",
  "provision.tenantspace.table.maxVideoCallnums": "Maximum number of concurrent video calls (\u2265 number of video agents)",
  "chat.usefulexpressions.message.willDeleteExpressions": "Are you sure you want to delete the selected common phrase?",
  "chat.webconfig.button.save": "Saved",
  "chat.agent.label.billDetail": "Bill Details",
  "chat.session.label.skillQueueId": "Skill queue ID.",
  "chat.agent.label.online": "Online",
  "chat.client.label.bill": "My bill.",
  "chat.client.label.like": "Guess you like",
  "chat.webconfig.label.bandingskill": "Binding a Skill Queue",
  "provision.callcenterinstanceslist.whitelist.singledeleteconfim": "Are you sure you want to delete the selected trustlist address?",
  "chat.timerange.label.customizes": "Customization",
  "provision.callcenterinstanceslist.vcallcenterDeatil.vccId": "VCC ID",
  "chat.agentConsole.message.error.emptyMessage": "[The message contains no content to be displayed.]",
  "chat.client.label.header": "Telco+",
  "chat.client.greeting.info": "How can I help you?",
  "chat.skill.message.saveFail": "Failed to save the skill.",
  "provision.callcenterinstanceslist.obsServer.taskRefreshPeriod": "Task Update Period",
  "chat.session.channel.mobile": "Mobile terminal",
  "chat.weChatConfig.label.facebook": "Facebook",
  "provision.tenantspace.gw.serverIP": "Gateway server IP address",
  "chat.timerange.label.oneday": "Last 1 day",
  "chat.agent.title.close": "To shut down",
  "chat.session.title.history": "Historical chat records",
  "chat.agent.label.customerSource": "Customer source: mobile app - personal center",
  "chat.skill.message.createFail": "Failed to create the skill.",
  "chat.client.transfer.link": "Transfer to manual service?",
  "provision.callcenterinstanceslist.vcallcenterDeatil.maxCalls": "Maximum number of concurrent calls",
  "chat.skill.label.agents": "Agents",
  "chat.session.label.servedAgent": "Service agent",
  "chat.weChatConfig.message.codeNameExisted": "The channel access code already exists.",
  "chat.weChatConfig.label.verifycode": "Token",
  "chat.skill.label.edit": "Editing",
  "chat.skill.label.save": "Saved",
  "chat.weChatConfig.stepname.selectmode": "Selecting a Configuration Method",
  "chat.usefulexpressions.label.createExpressionTab": "Creating Common Phrases",
  "chat.agent.label.money": "Amount",
  "chat.webconfig.message.saveSuccess": "Configuration saved successfully.",
  "provision.callcent.error.whiteaddrduplicate": "Duplicate address in the trustlist.",
  "chat.weChatConfig.label.configId": "Configuration ID",
  "chat.skill.label.createSkillTab": "Creating a Skill",
  "chat.skill.message.completeInputs": "Please complete all entries.",
  "chat.client.label.privacyHintContent": "To improve service quality\, your conversation will be recorded by the system. Do you want to continue?",
  "provision.callcent.error.paramValidateFailed": "Adding failed. The values of obsId\, progId\, and heartbeatPort must be all digits.",
  "chat.agent.title.userMenu": "Customer Menu",
  "provision.tenantspace.obs.name": "OBS Server Name",
  "chat.common.message.warning": "warnings",
  "chat.client.label.evaluation": "Satisfaction survey",
  "chat.webconfig.label.transHuman": "Transfer to manual customer service",
  "provision.tenantspace.customerAndmobileAgent.conflict": "The mobile agent feature and the one-to-one feature cannot be enabled at the same time.",
  "chat.timerange.label.alldays": "All Creation Time",
  "provision.ccworknos.table.virtualcallcenterNumber": "VCC ID",
  "chat.session.endType.overtimeEnd": "Timeout End",
  "chat.weChatConfig.label.AppSecret": "Developer password",
  "chat.skill.message.error": "Mistakes",
  "chat.skill.label.description": "Description",
  "chat.weChatConfig.message.error": "Mistakes",
  "chat.usefulexpressions.label.expression": "Common Phrases",
  "provision.callcenterinstanceslist.vcallcenterDeatil.agentNum": "Number of Assigned Agents",
  "provision.tenantspace.updateTenantSpaceStatus": "Are you sure you want to change the tenant space status?",
  "chat.skill.label.delete": "Deletion",
  "chat.usefulexpressions.message.isExisted": "The common phrase name already exists.",
  "chat.weChatConfig.message.appIdExisted": "The official account developer ID already exists.",
  "chat.client.label.password": "The password",
  "chat.agent.label.balance": "income and expenditure",
  "chat.client.message.gladtoServe": "It's a pleasure to serve you!",
  "chat.weChatConfig.label.verifying": "Verifying",
  "provision.tenantspace.dataUpload.selectType": "Select a resource dump service type.",
  "chat.thirdportal.title": "Customer Web Portal",
  "chat.webconfig.title": "Enable Web Channel",
  "chat.agent.label.goAway": "To leave",
  "chat.agent.label.total": "Total",
  "chat.weChatConfig.label.tenantId": "Tenant ID",
  "chat.agentConsole.message.content.image": "[Picture Message]",
  "provision.callcenterinstanceslist.vcallcenterDeatil.password": "Configure the administrator password.",
  "chat.weChatConfig.label.save": "Saved",
  "chat.weChatConfig.label.appId": "Developer ID.",
  "provision.callcenterinstanceslist.config.differentPassword": "The two passwords are inconsistent.",
  "provision.callcenterinstanceslist.whitelist.batchdeleteconfim": "Are you sure you want to delete all selected trustlist addresses?",
  "chat.skill.message.selectSkills": "Please select a skill.",
  "chat.client.label.privacyHint": "Privacy Tips",
  "chat.timerange.label.thirtydays": "Last 30 days",
  "chat.session.label.skillQueueName": "Skill Queue Name",
  "chat.common.message.success": "Success.",
  "provision.callcenterinstanceslist.whiteList.whiteNumberAdd": "Adding a number to the trustlist",
  "provision.callcenterinstanceslist.vcallcenter.tenantSpaceId": "Tenant ID",
  "chat.webconfig.label.windowTitle": "Page Title",
  "provision.tenantspace.config.agent.zero": "The number of voice agents cannot be 0.",
  "chat.client.greeting.afternoon": "Good afternoon!",
  "provision.callcent.selectwhitelist": "Please select a trustlist address.",
  "provision.tenantspace.dataUpload.contactRecord": "Contact record",
  "chat.session.label.created_endTime": "End Time",
  "provision.callcenterwhitelist.whiteList": "Trustlist Addresses",
  "chat.client.greeting.morning": "Good morning!",
  "chat.common.message.error": "Mistakes",
  "chat.agent.label.consumption": "My spending",
  "chat.usefulexpressions.message.createFail": "Failed to create the common phrase.",
  "provision.tenantspace.updateTenantSpaceStatus.confirm": "Confirming the Tenant Space Status Change",
  "chat.webconfig.label.greeting.placeholder": "Please enter the greeting content to be displayed in the chat window of the client.",
  "chat.skill.label.skillName": "Name",
  "chat.weChatConfig.label.search": "Searching for",
  "provision.tenantspace.feature.customeragenttype": "Leader one-on-one",
  "ccprovision.recorddrive.config.sftp.accountname": "SFTP Server Account",
  "provision.callcenterinstanceslist.vcallcenterDeatil.configWorkNo": "Configuration administrator ID",
  "chat.weChatConfig.message.deleteError": "Failed to delete the configuration item.",
  "chat.session.label.alias": "Customer Name",
  "chat.weChatConfig.message.savesuccess": "Access succeeded.",
  "chat.client.alert.message.invildtoken": "Your session has timed out\, or you have logged in to another place. Please close the chat window and try again. Thank you!",
  "chat.client.label.forgotPassword": "Forgot Password",
  "chat.weChatConfig.link.wechat": "WeChat backstage",
  "chat.client.label.more": "More",
  "provision.callcenterinstanceslist.vcallcenter.vcallcenterDeatil": "VCC Details",
  "chat.skill.message.willDeleteSkills": "Are you sure you want to delete the selected skill?",
  "chat.skill.label.account": "Account",
  "chat.skill.label.maxQueueNumber": "Maximum number of queues",
  "chat.weChatConfig.label.Domain": "Server Address",
  "chat.timerange.label.threedays": "Last 3 days",
  "chat.agent.title.createSkill": "Creating a Skill",
  "chat.client.message.noAgent": "Sorry\, the customer service is busy. Please try again later.",
  "chat.webconfig.label.language": "Default language",
  "chat.webconfig.message.demodirective": "You can also open the following URL in the invisible window of the Chrome browser to access the demonstration page provided by the system.",
  "chat.usefulexpressions.label.expressionDetail": "Common Phrase Details",
  "chat.client.label.no": "No.",
  "provision.tenantspace.dataUpload.cdrData": "CDR data",

  "chat.weChatConfig.label.wechat": "Wechat",
  "provision.tenantspace.feature.ivr.analysis": "IVR analysis",
  "provision.tenantspace.feature.ivr.analysis.description": "The feature of IVR analysis is enabled, and the tenant supports the IVR analysis function.",
  "provision.tenantspace.feature.customercenter": "Customer Center",
  "provision.tenantspace.feature.customercenter.open.confirmmsg": "The AppCube feature is the foundation for the operation of the customer center feature and will be enabled simultaneously",
  "provision.tenantspace.feature.appcube.close.confirmmsg": "After closing, the business data (such as work order data, contact data, etc.) in the AppCube will be cleared and cannot be restored. Please confirm whether to close or not",
  "provision.tenantspace.feature.whatsapp.cloudapi": "WhatsApp Cloud API Connection",
  "provision.tenantspace.common.customercenter.explain": "Activate the customer center feature, provide local customer management for tenants, and record the historical information of different contact channels for the same customer",

  "provision.tenantspace.common.whatsapp.cloudapi.explain": "This feature enables the tenant to connect to the WhatsApp Cloud API through channel configuration to handle text messages. Currently\, this feature can only be demonstrated and cannot be put into commercial use.",
  "provision.tenantspace.feature.mobileAppH5": "MobileApp H5 version",
  "provision.tenantspace.common.mobileAppH5.explain": "Enabling the features of MobileApp H5, tenants can automatically install mobile agent related functions on the appcube, supporting access through mobile phones",
  "provision.tenantspace.feature.mobileAppH5.open.confirmmsg": "The AppCube feature is the foundation for running MobileApp features and will be enabled simultaneously",

  "provision.tenantspace.feature.customercenter.close.error ": " Before disabling the customer center feature\, ensure that the Case 2.0 feature is disabled.",
  "provision.tenantspace.vdn.id": "VDN ID",

  "provision.tenantspace.config.error.148": "The authentication system has been associated with a federated user and cannot be modified.",

  "provision.log.config.agent.track.config.title": "Agent Tracing Configuration",
  "provision.log.config.agent.track.config.refresh": "Refresh",
  "provision.log.config.agent.track.config.tenantSpaceName": "Tenant Space Name",
  "provision.log.config.agent.track.config.workNo": "Agent ID",
  "provision.log.config.agent.track.config.tenantSpaceName.error.hints": "Only digits, letters, and underscores are allowed",
  "provision.log.config.agent.track.config.tenantSpaceName.length.error.hints": "Please enter a tenant space name of 8 to 30 characters starting with a letter and containing only digits, letters, and underscores.",
  "provision.log.config.agent.track.config.logLevel": "Log Level",
  "provision.log.config.agent.track.config.createTime": "Creation Time",
  "provision.log.config.agent.track.config.endTime": "Tracing End Time",
  "provision.log.config.agent.track.config.operation": "Operation",
  "provision.log.config.agent.track.config.delete": "Delete",
  "provision.log.config.agent.track.config.batch.delete": "Batch Delete",
  "provision.log.config.agent.track.config.add": "Add",
  "provision.log.config.agent.track.config.batchDelete.hints.pleaseSelect": "Please select agent tracing log configuration information",
  "provision.log.config.agent.track.config.batchDelete.hints.exceed.max.operate.num": "A maximum of 100 records can be deleted at a time.",
  "provision.log.config.agent.track.config.create.title": "Create Agent Tracing Configuration",
  "provision.log.config.agent.track.config.create.confirm": "Confirm",
  "provision.log.config.agent.track.config.create.cancel": "Cancel",
  "provision.log.config.agent.track.config.create.endTime.placeholder": "Please select the tracing end time",
  "provision.log.config.agent.track.config.create.must": "Required",
  "provision.log.config.agent.track.config.create.tenantSpaceName.min.length": "The tenant space name must contain at least eight characters.",
  "provision.log.config.agent.track.config.create.workNol.check.hints": "Please enter an integer ranging from 101 to 50000",
  "provision.log.config.agent.track.config.create.workNol.check.hints.1": "Please enter an integer within 50000",
  "provision.log.config.agent.track.config.create.end.check.hints": "Please select a time point from the current day to the next three days.",
  "provision.log.config.agent.track.config.success": "Succeeded",
  "provision.log.config.agent.track.config.failed": "Failed",
  "provision.log.config.agent.track.config.delete.success": "Deleted successfully",
  "provision.log.config.agent.track.config.delete.failed.11": "Deletion failed.",
  "provision.log.config.agent.track.config.delete.failed.10": "The deletion operation parameter is incorrect.",
  "provision.log.config.agent.track.config.create.top.hints": "After the agent log tracing function is enabled for the corresponding tenant, the agent connects the call and collects the tracing logs to the CCManagement service. This operation affects the system performance. You need to enable this function based on the actual tracing requirements.",
  "provision.log.config.agent.track.config.create.success": "Created successfully.",
  "provision.log.config.agent.track.config.create.failed": "Creation failed.",
  "provision.log.config.agent.track.config.create.failed.1": "The length of the tenant space name is invalid.",
  "provision.log.config.agent.track.config.create.failed.2": "The tenant space name contains special characters.",
  "provision.log.config.agent.track.config.create.failed.3": "Failed to check the tenant space name.",
  "provision.log.config.agent.track.config.create.failed.4": "Failed to check the work ID.",
  "provision.log.config.agent.track.config.create.failed.5": "Failed to check the log level.",
  "provision.log.config.agent.track.config.create.failed.6": "Failed to check the tracing end time.",
  "provision.log.config.agent.track.config.create.failed.7": "Exceeded the maximum number of configurable",
  "provision.log.config.agent.track.config.create.failed.8": "A log tracing configuration already exists for the agent.",
  "provision.log.config.agent.track.config.create.failed.9": "Creation failed.",
  "provision.log.config.agent.track.config.max.length": "The maximum length is",

  "provision.tenant.management.tenantname.contact.blocklist.check": "Invalid characters. The following special characters are not allowed: & < > \" ; % / \\ ^ [ ] = + @",
}