import type { CSSProperties } from "vue";

/**
 * 对话框类型定义
 */
const enum DialogType {
  /** 纯文本提示 */
  text = "text",
  /** 带图标提示类、确定类、普通列表、表单操作类弹窗 */
  operation = "operation",
  /** 复杂列表、表单操作类弹窗 */
  complex = "complex",
  /** 大弹窗（新建、回复邮件弹窗）*/
  large = "large"
}

/** 对话框类型样式定义 */
export const DIALOG_STYLE: Record<DialogType, CSSProperties> = {
  [DialogType.text]: { width: "458px" },
  [DialogType.operation]: { width: "616px" },
  [DialogType.complex]: { width: "896px" },
  [DialogType.large]: { width: "1248px" }
};