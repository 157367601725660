import i18n from '@/lang/index'

const $t = i18n.global.t

export const OIFDE_EXPORT_TYPE_ENUM = {
  '1': $t('oifde.route.keyPressRobot'),
}

export const OIFDE_EXPORT_RESULT_ENUM = {
  '0': $t('isales.taskresultexport.title.exported'),
  '1': $t('isales.taskresultexport.title.progress'),
  '2': $t('isales.taskresultexport.title.success'),
  '3': $t('isales.taskresultexport.title.fail')
}
