/**
 * 获取rest服务调用路径
 */
export function getRestService(servicePath, version, operation) {
    let service = "/service-cloud/rest";

    if (servicePath) {
        service += servicePath;
    }

    if (version) {
        service += "/" + version;
    }

    // 处理beqlName
    if (operation) {
        service += operation;
    }

    return service; //返回参数值
}