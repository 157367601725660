import agentconsole_en_US from './agentconsole_en_US'
import agentconsole_es_ES from './agentconsole_es_ES'
import agentconsole_pt_BR from './agentconsole_pt_BR'
import agentconsole_fr_FR from './agentconsole_fr_FR'
import agentconsole_th_TH from './agentconsole_th_TH'
import agentconsole_zh_CN from './agentconsole_zh_CN'
import Agentconsole_ar_SA from "./agentconsole_ar_SA"
import agentconsole_de_DE from './agentconsole_de_DE';

const agentconsole = {
  en_US: {
    ...agentconsole_en_US
  },
  es_ES: {
    ...agentconsole_es_ES
  },
  pt_BR: {
    ...agentconsole_pt_BR
  },
  fr_FR: {
    ...agentconsole_fr_FR
  },
  th_TH: {
    ...agentconsole_th_TH
  },
  zh_CN: {
    ...agentconsole_zh_CN
  },
  ar_SA: {
    ...Agentconsole_ar_SA
  },
  de_DE: {
    ...agentconsole_de_DE
  }
}

export default agentconsole