import request from "@/utils/request.js";

/**
 * 查询IVR语音信息
 *
 * @param data 查询参数
 * @returns {*}
 */
export function queryAllIvrVoice(data) {
  return request({
    url: '/oifde/rest/cc-ivr/v1/voicemanageservice/queryAllIvrVoice',
    method: 'post',
    data
  })
}

/**
 * 查询IVR语音记录总计
 *
 * @param data 查询参数
 * @returns {*}
 */
export function queryAllCCIvrCount(data) {
  return request({
    url: '/oifde/rest/cc-ivr/v1/voicemanageservice/queryAllCCIvrCount',
    method: 'post',
    data
  })
}

/**
 * 查询可引用的主流程和子流程
 *
 * @param data 查询参数
 * @returns {*}
 */
export function queryPublishIVRFlow(data) {
  return request({
    url: '/oifde/rest/cc-ivr/v1/ivrFlow/queryPublishIVRFlow',
    method: 'post',
    data
  })
}