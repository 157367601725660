import request from '@/utils/request'

export const queryAccessConfig = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/recMessageRouterConfig/queryRecMessageRouterConfig',
    method: 'post',
    data
  })
}

export const deleteAccessConfig = (ids) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/recMessageRouterConfig/deleteRecMessageRouterConfig',
    method: 'post',
    data: ids
  })
}

export const querySumUser = () => {
  return request({
    url: '/service-cloud/u-route/employeeOperation/getAllUser',
    method: 'post'
  })
}

export const queryEmailReceiveConfig = () => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/gatewayConfig/queryEmailReceiveConfigNodes',
    method: 'post'
  })
}

export const updateAccessConfig = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/recMessageRouterConfig/updateRecMessageRouterConfig',
    method: 'post',
    data
  })
}

export const createAccessConfig = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/recMessageRouterConfig/addRecMessageRouterConfig',
    method: 'post',
    data
  })
}

export const queryVariable = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/variable/queryVariable',
    method: 'post',
    data
  })
}

export const deleteVariable = (id) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/variable/deleteVariable',
    method: 'post',
    data: {
      id: id
    }
  })
}

export const queryTamplate = () => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/variable/queryTamplate',
    method: 'post'
  })
}

export const updateVariable = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/variable/updateVariable',
    method: 'post',
    data
  })
}

export const queryTemplatesByVariable = (id) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/variable/queryTemplatesByVariable',
    method: 'post',
    data: {
      id: id
    }
  })
}

export const queryBulletinType = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/bulletinType/queryBulletinTypeByCondition',
    method: 'post',
    data
  })
}

// 添加变量
export const addVariable = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/variable/addVariable',
    method: 'post',
    data
  })
}

// 查询公共变量
export const queryPublicVariableAndTemplateVariable = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/variable/queryPublicVariableAndTemplateVariable',
    method: 'post',
    data
  })
}

// 查询消息模板
export const queryMessageTemplate = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/messageTemplate/queryMessageTemplate',
    method: 'post',
    data
  })
}

// 系统管理员查询消息模板
export const querySysMessageTemplate = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/messageTemplate/querySysMessageTemplate',
    method: 'post',
    data
  })
}

// 删除消息模板
export const deleteMessageTemplate = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/messageTemplate/deleteMessageTemplate',
    method: 'post',
    data
  })
}

// 删除系统管理员消息模板
export const deleteSysMessageTemplate = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/messageTemplate/deleteSysMessageTemplate',
    method: 'post',
    data
  })
}

export const queryAllUsers = (tenantSpaceId, data) => {
  return request({
    url: `/service-cloud/rest/sum/v2/tenantSpaces/${tenantSpaceId}/users`,
    method: 'GET',
    data
  })
}

export const operateUserFromGroup = (tenantSpaceId, id, data) => {
  return request({
    url: `/service-cloud/rest/sum/v1/tenantSpaces/${tenantSpaceId}/groups/${id}/users`,
    method: 'POST',
    data
  })
}

// 查询用户群组下的员工列表
export const queryUserListByGroupId = (datas) => {
  return request({
    url: `/service-cloud/u-route/usergroupaction/queryUsersByGroupId`,
    method: 'POST',
    requestHeaders: [
      {
        'content-type': 'application/json'
      }
    ],
    data: { param: datas }
  })
}

// 上传邮件附件
export const batchUploadFileForEmailAttachment = (data) => {
  return request({
    url: '/service-cloud/u-route/ccnotification/eamilattachment/batchUploadFile',
    method: 'post',
    data
  })
}

// 根据组织ID获取组织名称
export const queryorgsbyid = (data) => {
  return request({
    url: '/service-cloud/u-route/orgs/queryorgsbyid',
    method: 'post',
    data
  })
}

// 创建通知模板
export const createMessageTemplate = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/messageTemplate/createMessageTemplate',
    method: 'post',
    data
  })
}

// 创建系统管理员通知模板
export const createSysMessageTemplate = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/messageTemplate/createSysMessageTemplate',
    method: 'post',
    data
  })
}

// 修改通知模板
export const modifyMessageTemplate = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/messageTemplate/modifyMessageTemplate',
    method: 'post',
    data
  })
}

// 修改系统管理员通知模板
export const modifySysMessageTemplate = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/messageTemplate/modifySysMessageTemplate',
    method: 'post',
    data
  })
}

// 上传邮件附件
export const uploadFile = (data) => {
  return request({
    url: '/service-cloud/u-route/ccnotification/eamilattachment/uploadFile',
    method: 'post',
    data
  })
}

export const createBulletinType = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/bulletinType/createBulletinType',
    method: 'post',
    data
  })
}

export const updateBulletinType = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/bulletinType/updateBulletinType',
    method: 'post',
    data
  })
}

export const deleteBulletinType = (ids) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/bulletinType/deleteBulletinType',
    method: 'post',
    data: {
      ids: ids
    }
  })
}

// 查询邮件签名列表
export const queryEmailSignature = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/signature/queryEmailSignature',
    method: 'post',
    data
  })
}

// 根据id删除邮件签名
export const deleteEmailSignature = (ids) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/signature/deleteEmailSignature',
    method: 'post',
    data: ids
  })
}

// 邮件签名支持的最大图片大小
export const queryImageMaxSize = () => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/signature/queryMaxImageSizeOfEmailSignature',
    method: 'post'
  })
}

// 查询邮件签名详情
export const querySignatureInfo = (id) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/signature/queryEmailSignatureInfo',
    method: 'post',
    data: { signatureId: id }
  })
}

// 创建新的邮件签名
export const createEmailSignature = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/signature/createEmailSignature',
    method: 'post',
    data
  })
}

// 修改邮件签名
export const updateEmailSignature = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/signature/updateEmailSignature',
    method: 'post',
    data
  })
}

// 根据模板id查变量信息
export const queryVariableByTemplateId = (templateId) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/variable/queryVariableByTemplateId',
    method: 'post',
    data: { templateId }
  })
}

// 查询发件箱
export const queryEmailSendConfigNodes = () => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/gatewayConfig/queryEmailSendConfigNodes',
    method: 'post'
  })
}

// 查询租间信息
export const queryTenantSpaceInformation = (data) => {
  return request({
    url: '/service-cloud/rest/ipccprovision/v1/tenantspace/queryTenantSpaceInformation',
    method: 'post',
    data
  })
}

// 查询群组
export const queryUserGroup = (tenantSpaceId, data) => {
  return request({
    url: `/service-cloud/rest/sum/v2/tenantSpaces/${tenantSpaceId}/groups`,
    method: 'GET',
    data
  })
}

// 上传邮件附件
export const uploadFileForEmailAttachment = (data) => {
  return request({
    url: '/service-cloud/u-route/ccnotification/eamilattachment/uploadFile',
    method: 'post',
    data
  })
}

// 查询公告类型
export const queryBulletinTypeByCondition = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/bulletinType/queryBulletinTypeByCondition',
    method: 'post',
    data
  })
}

// 租间管理员发送公告
export const sendTenantBulletin = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/sendmessage/sendTenantBulletin',
    method: 'post',
    data
  })
}

// 租间管理员发送站内信
export const sendTenantInternalMessage = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/sendmessage/sendTenantInternalMessage',
    method: 'post',
    data
  })
}

// 租间管理员发送华为云短信
export const sendTenantSms = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/sendmessage/sendTenantSms',
    method: 'post',
    data
  })
}

// 租间管理员发送网关短信
export const sendTenantMspSms = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/sendmessage/sendTenantMspSms',
    method: 'post',
    data
  })
}

// 租间管理员发送邮件
export const sendTenantMspEmail = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/sendmessage/sendTenantMspEmail',
    method: 'post',
    data
  })
}

// 座席发送公告
export const sendAgentBulletin = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/sendmessage/sendAgentBulletin',
    method: 'post',
    data
  })
}

// 座席发送站内信
export const sendAgentInternalMessage = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/sendmessage/sendAgentInternalMessage',
    method: 'post',
    data
  })
}

// 发送系统公告
export const sendSysBulletin = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/sendmessage/sendSysBulletin',
    method: 'post',
    data
  })
}

// 系统管理员发送站内信
export const sendSysInternalMessage = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/sendmessage/sendSysInternalMessage',
    method: 'post',
    data
  })
}

// 系统管理员发送短信网关短信
export const sendSysMspSms = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/sendmessage/sendSysMspSms',
    method: 'post',
    data
  })
}

// 系统管理员发送华为云短信
export const sendSysSms = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/sendmessage/sendSysSms',
    method: 'post',
    data
  })
}

// 系统管理员发送系统邮件
export const sendSysMspEmail = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/sendmessage/sendSysMspEmail',
    method: 'post',
    data
  })
}

// 查询所有用户，不分页
export const getAllUser = () => {
  return request({
    url: '/service-cloud/u-route/employeeOperation/getAllUser',
    method: 'post',
    data: {}
  })
}

// 查询呼叫中心
export const queryAllCallCenterInstance = (data) => {
  return request({
    url: '/service-cloud/rest/ipccprovision/v1/callcenterinstances/queryAllCallCenterInstance',
    method: 'post',
    data
  })
}

// 查询用户权限
export const getUserPerms = (tenantId, userId) => {
  return request({
    url: `/service-cloud/rest/sum/v2/tenantSpaces/${tenantId}/users/${userId}/perms`,
    method: 'get'
  })
}

// 查询当前用户所有可访问组织
export const getOrgListByUserId = (userId) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/accessRelation/getListByUserId',
    method: 'post',
    data: { userId }
  })
}

// 查询网关监控信息
export const queryGatewayConfigStatusByCondition = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/gatewayConfigStatus/queryGatewayConfigStatusByCondition',
    method: 'post',
    data
  })
}

// 重建网关
export const chargeGatewayConfigStatus = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/gatewayConfigStatus/chargeGatewayConfigStatus',
    method: 'post',
    data
  })
}

//公用api字典查询
export const queryDataDictionary = (locale) => {
  return request({
    url: '/service-cloud/u-route/dictionary/queryDataDictionary',
    method: 'get',
    data: { dictCode: 'CCN.COUNTRY_CODE', locale }
  })
}

export const queryGatewayByCondition = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/gatewayConfig/queryGatewayConfigByCondition',
    method: 'POST',
    data
  })
}

export const deleteGateway = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/gatewayConfig/deleteGatewayConfig',
    method: 'POST',
    data
  })
}

export const setFlowControl = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/flowControl/setFlowControl',
    method: 'POST',
    data
  })
}

export const querySmsNumberConfigList = (data) => {
  return request({
    url: `/service-cloud/rest/ccnotification/v1/smsnumbercofig/querySmsNumberConfigList`,
    method: 'POST',
    data
  })
}
export const addSmsNumberConfig = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/smsnumbercofig/addSmsNumberConfig',
    method: 'POST',
    data
  })
}

export const queryCertFile = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/gatewayConfig/queryCertFile',
    method: 'POST',
    data
  })
}

export const queryConfigByNameAndType = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/gatewayConfig/queryConfigByNameAndType',
    method: 'POST',
    data
  })
}

export const createGatewayConfig = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/gatewayConfig/createGatewayConfig',
    method: 'POST',
    data
  })
}

export const updateGatewayConfig = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/gatewayConfig/updateGatewayConfig',
    method: 'POST',
    data
  })
}

export const getFlowControl = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/flowControl/getFlowControl',
    method: 'POST',
    data
  })
}

export const queryMessageInfo = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/receivermessage/queryMessageInfo',
    method: 'post',
    data
  })
}

// 基于userId获取用户详情
export const getInfoByUserId = (tenantSpaceId, userIds) => {
  return request({
    url: `/service-cloud/rest/sum/v2/tenantSpaces/${tenantSpaceId}/users/${userIds}`,
    method: 'get'
  })
}

export const queryRecMessageInfo = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/receivermessage/queryRecMessageInfo',
    method: 'post',
    data
  })
}

export const queryMessageByCondition = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/sendmessage/queryMessageByCondition',
    method: 'post',
    data
  })
}

export const queryCaseMessageInfo = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/receivermessage/queryCaseMessageInfo',
    method: 'post',
    data
  })
}

export const uploadReadStatus = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/receivermessage/uploadReadStatus',
    method: 'put',
    data
  })
}

export const deleteReceiverMessage = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/receivermessage/deleteReceiverMessage',
    method: 'post',
    data
  })
}

export const deleteSendMessage = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/sendmessage/deleteSendMessage',
    method: 'post',
    data
  })
}

export const querySendRecordByCondition = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/sendmessage/querySendRecordByCondition',
    method: 'post',
    data
  })
}

export const queryMessageInfoById = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/sendmessage/queryMessageInfoById',
    method: 'post',
    data
  })
}

export const queryInternalMessageById = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/receivermessage/queryInternalMessageById',
    method: 'post',
    data
  })
}

// 查询华为云短信信息
export const querySmsHuawei = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/smsHuawei/querySmsHuawei',
    method: 'post',
    data
  })
}

//修改短信对接渠道配置
export const updateSmsHuawei = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/smsHuawei/updateSmsHuawei',
    method: 'post',
    data
  })
}

//更新短信配置
export const updateSmsNumberConfig = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/smsnumbercofig/updateSmsNumberConfig',
    method: 'post',
    data
  })
}

//删除短信配置
export const deleteSmsNumberConfig = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/smsnumbercofig/deleteSmsNumberConfig',
    method: 'post',
    data
  })
}

//重置华为云短信配置
export const resetSmsHuawei = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/smsHuawei/resetSmsHuawei',
    method: 'post',
    data
  })
}

// 查询当前用户未读消息数量（系统公告、内部公告、站内信、邮件、短信）
export function queryUnreadMessageCountByType() {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/receivermessage/queryUnreadMessageCountByType',
    method: 'get'
  })
}
