import request from '@/utils/request'
import odfsRequest from '@oifde/utils/odfsRequest'
import {useOifdeUserStore} from "@oifde/stores/user";
import cookieUtils from "@/utils/cookie"

export function queryRuleInfo(query) {
    const data = {
        tenantId: cookieUtils.getCookie('tenant_space_id'),
        source: "ALL"
    }
    for (let prop in query) {
        if (typeof query[prop] === undefined) {
            continue
        }
        if (typeof query[prop] === 'string' && query[prop] === '') {
            continue
        }
        data[prop] = query[prop]
    }

    return odfsRequest({
        url: '/oifde/rest/queryruleinfos',
        method: 'post',
        data: data
    })
}

export function updateRuleInfo(rule) {
    const data = {
        tenantId: cookieUtils.getCookie('tenant_space_id'),
        source: useOifdeUserStore().source
    }
    for (let prop in rule) {
        if (typeof rule[prop] === undefined) {
            continue
        }
        data[prop] = rule[prop]
    }

    return request({
        url: '/oifde/rest/updateruleinfo',
        method: 'post',
        data
    })
}

export function deleteRuleInfo(ruleIdList) {
    const data = {
        tenantId: cookieUtils.getCookie('tenant_space_id'),
        ruleIdList: ruleIdList
    }

    return request({
        url: '/oifde/rest/deleteruleinfo',
        method: 'post',
        data
    })
}

export function createRuleInfo(rule) {
    const data = {
        tenantId: cookieUtils.getCookie('tenant_space_id'),
        source: useOifdeUserStore().source
    }
    for (let prop in rule) {
        if (typeof rule[prop] === undefined) {
            continue
        }
        if (typeof rule[prop] === 'string' && rule[prop] === '') {
            continue
        }
        data[prop] = rule[prop]
    }
    return request({
        url: '/oifde/rest/createruleinfo',
        method: 'post',
        data
    })
}

export function queryFlowsByRuleId(ruleInfo) {
    const data = {
        tenantId: cookieUtils.getCookie('tenant_space_id'),
        source: "ALL"
    }
    for (let prop in ruleInfo) {
        if (typeof ruleInfo[prop] === undefined) {
            continue
        }
        if (typeof ruleInfo[prop] === 'string' && ruleInfo[prop] === '') {
            continue
        }
        data[prop] = ruleInfo[prop]
    }
    return request({
        url: '/oifde/rest/queryflows',
        method: 'post',
        data
    })
}