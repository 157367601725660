export default {
    "webclient.body.message.bot.recommend": "Raten Sie, dass Sie fragen wollen",
    "webclient.body.message.card.url": "Verknüpfung kopieren",
    "webclient.body.message.cobrowse.agree": "Ich stimme zu",
    "webclient.body.message.cobrowse.cancel": "Abbrechen",
    "webclient.body.message.cobrowse.target": "Ziele durchsuchen:",
    "webclient.body.message.cobrowse.title": "Agent fordert Co-Browsing mit Ihnen an",
    "webclient.body.message.file.download": "Herunterladen",
    "webclient.body.message.file.download.again": "Erneut herunterladen",
    "webclient.body.message.locate.latitude": "Breite",
    "webclient.body.message.locate.longitude": "Längengrad",
    "webclient.body.message.recall": "Nachricht zurückgezogen",
    "webclient.body.name.assistant": "Intelligenter Assistent",
    "webclient.body.tip.leave.click": "Klicken Sie hier, um eine Nachricht zu hinterlassen",
    "webclient.body.tip.leave.message": "Wenn nötig, bitte",
    "webclient.body.tip.leave.message.finish": "Ihre Nachricht wurde erfolgreich gesendet. Sie können eine weitere Nachricht hinterlassen.",
    "webclient.body.tip.privacy": "Um die Servicequalität zu verbessern, speichern wir den Chat-Verlauf zwischen Ihnen und dem Kundendienstmitarbeiter oder den Chat-Verlauf des Roboters. Wir werden die Datensicherheit durch Verschlüsselung oder Zugriffskontrolle gewährleisten.",
    "webclient.body.tip.queue.cancel": "Warteschlangenerstellung wird abgebrochen",
    "webclient.body.tip.queue.cancel.done": "Warteschlange erfolgreich abgebrochen, bitte erneut in die Warteschlange einreihen, bitte weiterhin Nachrichten senden“",
    "webclient.body.tip.queue.cancel.robot": "Warteschlange erfolgreich abgebrochen, Roboter wird verbunden ...",
    "webclient.body.tip.queue.part1": "Du bist Nein.",
    "webclient.body.tip.queue.part2": "in der Warteschlange und müssen warten ",
    "webclient.body.tip.queue.part3": "Sekunden ",
    "webclient.body.tip.queue.timeout": "Die Warteschlange hat das Zeitlimit überschritten",
    "webclient.body.tip.session.end": "Sitzung beendet",
    "webclient.body.tip.session.reconnect": "Sitzung neu starten",
    "webclient.body.tip.session.timeout": "Sie haben nicht innerhalb der angegebenen Zeit geantwortet. Das System hat die Sitzung automatisch beendet. Sie können",
    "webclient.body.tip.token.invalid": "Token ist abgelaufen oder wurde anders angemeldet",
    "webclient.body.title.call.disconnect": "Anruf getrennt",
    "webclient.body.title.call.end": "Anruf beendet",
    "webclient.body.title.call.fail": "Anruf fehlgeschlagen",
    "webclient.body.title.call.queue": "Anruf in Warteschlange",
    "webclient.body.title.call.start": "Anrufe",
    "webclient.body.title.call.timeout": "Anrufwarteschlangen-Timeout",
    "webclient.body.title.call.transfer": "Anrufweiterleitung",
    "webclient.box.tip.click.cannot.link": "Es kann keine Verbindung zum WebRTC-Gateway hergestellt werden",
    "webclient.box.tip.click.check.device.fail": "Es konnte nicht geprüft werden, ob Click-to-Call unterstützt wird",
    "webclient.box.tip.click.no.audio.input": "Audioeingabegeräte nicht verfügbar",
    "webclient.box.tip.click.no.audio.output": "Audioausgabegeräte nicht verfügbar",
    "webclient.box.tip.click.no.video.input": "Videoeingabegeräte nicht verfügbar",
    "webclient.box.tip.click.not.support.browser": "Der Browser unterstützt WebRTC nicht",
    "webclient.box.tip.click.not.support.channel": "WebRTC wird vom Mandanten nicht unterstützt",
    "webclient.box.tip.click.not.support.tenant": "Click-to-Call wird vom Kanal nicht unterstützt",
    "webclient.box.tip.cobrowse.no.auth": "Der Agent verfügt nicht über die Berechtigung zum Co-Browsing",
    "webclient.chat.nickname.system": "System",
    "webclient.dialog.evaluate.placeholder": "Bitte geben Sie Ihre Bewertung ein",
    "webclient.dialog.evaluate.title": "Bitte bewerten Sie den Kundenservice!",
    "webclient.dialog.leave.format.content": "Der Nachrichteninhalt darf nicht leer sein und keine Sonderzeichen enthalten",
    "webclient.dialog.leave.format.phone": "Die Nummer darf nicht leer sein und nur Ziffern enthalten, - oder +",
    "webclient.dialog.leave.phone": "Geben Sie eine Mobiltelefonnummer ein",
    "webclient.dialog.leave.success": "Nachricht erfolgreich hinterlassen",
    "webclient.dialog.leave.text": "Bitte hinterlassen Sie eine Nachricht",
    "webclient.dialog.leave.title": "Nachricht verlassen",
    "webclient.dialog.selectArea.title": "Wählen Sie den zu blockierenden Bereich aus",
    "webclient.dialog.video.default": "Standard",
    "webclient.dialog.video.hide": "Lokales Video ausblenden",
    "webclient.dialog.video.large": "Groß",
    "webclient.dialog.video.medium": "Mittel",
    "webclient.dialog.video.pause": "Video stummschalten",
    "webclient.dialog.video.share.off": "Freigabe abbrechen",
    "webclient.dialog.video.share.on": "Desktop-Freigabe",
    "webclient.dialog.video.size": "Videogröße",
    "webclient.dialog.video.small": "Klein",
    "webclient.dialog.video.start": "Videostummschaltung aufheben",
    "webclient.footer.label.send.message": "Nachricht senden",
    "webclient.footer.label.share.direct": "Direktes Teilen",
    "webclient.footer.label.share.sensitive": "Auswahl eines sensiblen Bereichs",
    "webclient.footer.label.stop.direct": "Freigabe beenden",
    "webclient.footer.placeholder.richtext": "Bitte geben Sie die Frage ein, die Sie stellen möchten",
    "webclient.footer.send.fail.content.big": "Der gesendete Inhalt überschreitet die maximale Länge (500 Zeichen)",
    "webclient.footer.send.fail.file.format": "Das Dateiformat entspricht nicht den Anforderungen",
    "webclient.footer.send.fail.file.other": "Datei konnte nicht gesendet werden",
    "webclient.footer.send.fail.file.screen": "Die Screenshot-Datei konnte nicht gesendet werden",
    "webclient.footer.send.fail.file.size.audio": "Die Größe der Audiodatei darf nicht überschreiten",
    "webclient.footer.send.fail.file.size.common": "Die Dateigröße darf nicht überschreiten",
    "webclient.footer.send.fail.file.size.video": "Die Größe der Videodatei darf  nicht überschreiten",
    "webclient.footer.send.fail.no.file": "Bitte wählen Sie Dateien aus",
    "webclient.footer.send.fail.picture.with.text": "Derzeit können Bilder nicht zusammen mit Texten oder Emoticons versendet werden.",
    "webclient.footer.tip.cobrowse.share": "Dieser Vorgang teilt Ihre Browser-Seite, die Ihre persönlichen Daten enthalten kann. Möchten Sie wirklich fortfahren?",
    "webclient.footer.tip.connect.fail": "Verbindung fehlgeschlagen. Versuchen Sie es erneut oder wenden Sie sich an den Administrator",
    "webclient.footer.tip.download": "The chat content you download is in HTML format, and the multimedia content in the chat content is time-sensitive. It is recommended that you convert it to a PDF file immediately after downloading. Steps to convert HTML to PDF: 1. Open the HTML file to be converted in a browser; 2. Press Ctrl+P (Windows) or Command+P (Mac) to open the print dialog box; 3. In the print dialog box, select \"Destination Printer\" as \"Save As PDF\"; 4. Click the \"Save\" button to convert the HTML page to PDF format and save it to the local computer.",
    "webclient.footer.tooltip.audio": "Audio",
    "webclient.footer.tooltip.cobrowse": "Freigeben",
    "webclient.footer.tooltip.download": "Gespräche herunterladen",
    "webclient.footer.tooltip.emotion": "Gefühl",
    "webclient.footer.tooltip.evaluation": "Auswerten",
    "webclient.footer.tooltip.file": "Dateien hochladen",
    "webclient.footer.tooltip.more": "Mehr",
    "webclient.footer.tooltip.send": "Senden",
    "webclient.footer.tooltip.send.way": "Sendemodus",
    "webclient.footer.tooltip.video": "Video",
    "webclient.header.title.input": "Die andere Partei tippt",
    "webclient.header.title.service": "Kundendienst",
    "webclient.header.tooltip.close": "Schließen",
    "webclient.header.tooltip.transfer.agent": "Weiterleitung an Agenten",
    "webclient.header.tooltip.transfer.robot": "Übertragung zum Roboter",
}