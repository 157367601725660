export default {
  "ccd.agent.agentevent.AgentEvent_Auto_Answer": "Réponse automatique de l'agent",
  "ccd.agent.button.ok": "OK",
  "ccd.agent.webrtc.label.large": "grande",
  "ccd.agent.tips.success": "Succès",
  "ccd.agent.resetphonepass.inputcur": "Mot de passe du compte actuel",
  "ccd.agent.button.threeParty": "Trois-parties",
  "ccd.agent.agentevent.AgentEvent_Call_Release": "L'agent quitte l'appel",
  "ccd.agent.agentevent.AgentConf_SelfJoinResult": "Agent se joignant à une conférence",
  "ccd.agent.agentevent.AgentOther_InService": "L'agent s'est inscrit avec succès.",
  "ccd.agent.agentevent.AgentEvent_Call_Out_Fail": "L'agent ne parvient pas à lancer un appel externe.",
  "ccd.agent.openeye.deregister": "Le numéro de téléphone logiciel est désactivé avec succès.",
  "ccd.agent.webrtc.label.online": "enregistré",
  "ccd.agent.agentevent.AgentEvent_Customer_Alerting": "Le téléphone sonne.",
  "ccd.agent.webrtc.label.remote.name": "Autre partie",
  "ccd.agent.openeye.sipconfig.success": "Le SIP est configuré avec succès.",
  "ccd.agent.label.idle": "Idle",
  "ccd.agent.webrtc.label.sippwd": "Mot de passe du téléphone logiciel",
  "ccd.agent.tips.media.agentType.no.support.callout": "Les agents multimédias ne peuvent pas faire d'appels sortants",
  "ccd.agent.agentevent.info": "Conseils",
  "ccd.agent.button.innerHelp": "Consulter",
  "ccd.agent.agentevent.AgentEvent_Agent_Occupyed": "L'agent a déjà été préempté",
  "ccd.agent.agentevent.AgentEvent_Inside_Call_Fail": "L'agent ne parvient pas à lancer un appel interne.",
  "ccd.agent.flash.calling": "Appeler",
  "ccd.agent.message.agentNoConfSkill": "L'agent n'est pas configuré avec une file d'attente de compétences.",
  "ccd.agent.flash.message": "Message",
  "ccd.agent.webrtc.label.busy.confirm": "Confirmer",
  "ccd.agent.agentevent.AgentState_Work": "L'agent entre dans l'état de clôture.",
  "ccd.agent.button.signOut": "Se déconnecter",
  "ccd.agent.button.hangUp": "Raccrocher",
  "ccd.agent.msg.notification.permission.denied": "l'utilisateur rejette l'autorisation et ne peut pas afficher la notification. Veuillez modifier les paramètres du navigateur.",
  "ccd.agent.button.transfer": "Transfert",
  "ccd.agent.openeye.videowindowparam.fail": "Impossible d'initialiser les paramètres vidéo. En conséquence, la fonction d'appel vidéo est anormale.",
  "ccd.agent.webrtc.label.hidelocalvideo": "Masquer la vidéo locale",
  "ccd.agent.agentevent.feature.callin": "Appel entrant commun",
  "ccd.agent.agentevent.callType": "Type d'appel:",
  "ccd.agent.agentevent.mediaAblity.voiceandvideo": "audio et vidéo",
  "ccd.agent.agentevent.AgentState_CancelRest_Success": "L'agent a annulé le reste avec succès.",
  "ccd.agent.pagination.total": "Total :",
  "ccd.agent.agentevent.AgentEvent_Consult_Fail": "Agent conseil Échec",
  "ccd.agent.button.endMute": "Désactiver le son",
  "ccd.agent.label.offline": "Hors ligne",
  "ccd.agent.agentevent.mediaAblity.text": "Texte",
  "ccd.agent.button.mute": "muet",
  "ccd.agent.tips.maxCountQuery.info": "Le volume de données actuel est trop important. Le système affiche uniquement les derniers enregistrements de données {0}.",
  "ccd.agent.button.sayBusy": "Définir Occupé",
  "ccd.agent.label.busy": "Occupé",
  "ccd.agent.agentevent.AgentMediaEvent_PausePlayDone": "La lecture de l'enregistrement est interrompue avec succès.",
  "ccd.agent.agentevent.AgentMediaEvent_Play": "La lecture de l'enregistrement démarre.",
  "ccd.agent.agentevent.AgentOther_PhoneUnknow": "L'état du téléphone de l'agent est inconnu.",
  "ccd.agent.webrtc.label.button.no": "Non",
  "ccd.agent.webrtc.label.connect": "S'inscrire",
  "ccd.agent.openeye.switchshare.fail": "Impossible d'activer la fonction de partage de bureau.",
  "ccd.agent.agentevent.AgentState_CancelNotReady_Success": "L'agent a annulé occupé avec succès.",
  "ccd.agent.openeye.poolsipconfig.fail": "Impossible de configurer le POOL. Connectez-vous à nouveau en tant qu'agent ou connectez-vous manuellement au OpenEye.",
  "ccd.agent.openeye.videolayoutmode.success": "Réussir à définir le mode d'agencement de l'image de la fenêtre vidéo.",
  "ccd.agent.button.answer": "Réponse",
  "ccd.agent.agentevent.warning": "Avertissement",
  "ccd.agent.agentevent.AgentOther_PhoneOffhook": "L'agent s'est décroché",
  "ccd.agent.openeye.deregister.fail": "Impossible de désactiver le numéro de téléphone logiciel. Se déconnecter manuellement du OpenEye.",
  "ccd.agentmonitor.span.insert": "Insertion",
  "ccd.agent.frontpage.callreason": "Raison d'appel",
  "ccd.agent.agentevent.AgentMediaEvent_ResumePlayDone": "La lecture de l'enregistrement est reprise avec succès.",
  "ccd.agent.button.screenShare": "Partage de bureau",
  "ccd.agent.agentevent.AgentEvent_No_Answer.number": "Numéro de la partie raccrochée:",
  "ccd.agent.agentevent.AgentState_Ready": "L'agent a mis inactif avec succès.",
  "ccd.agent.button.notscreenShare": "Annuler le partage de bureau",
  "ccd.agent.agentevent.AgentEvent_Preview": "L'agent reçoit la demande d'aperçu avec succès.",
  "ccd.agent.openeye.getapplist.fail": "Impossible d'obtenir la liste des applications ouvertes.",
  "ccd.agent.login.multimedia.failinfo": "En conséquence, l'appel vocal et vidéo, ainsi que les fonctions d'aide interne sont indisponibles.",
  "ccd.agent.label.adjust": "Récapitulation",
  "ccd.agent.agentevent.AgentState_Busy": "L'agent travaille",
  "ccd.agent.tips.confFail": "Échec de la configuration.",
  "ccd.agent.webrtc.label.accept": "Réponse",
  "ccd.agent.tips.error": "Erreur",
  "ccd.agent.agentevent.AgentState_SetWork_Success": "L'agent entre dans l'état de fonctionnement.",
  "ccd.agent.label.reply": "Répondre",
  "ccd.agent.webrtc.label.videopop": "Fenêtre vidéo",
  "ccd.agent.button.offlineSignIn": "Changer de mobile",
  "ccd.agent.button.queryStatus": "Statut de la requête",
  "ccd.agent.resetphonepass.inputagain": "Confirmer le mot de passe",
  "ccd.agent.openeye.poolsipconfig.success": "Le POOL est configuré avec succès.",
  "ccd.agent.agentevent.AgentEvent_MediaSwith.success": "Commutation audio/vidéo réussie.",
  "ccd.agent.openeye.deamonready": "La OpenEye est lancée.",
  "ccd.agentMonitor.label.switchlistenorinsert": "Dans l'état de pré-inspection, un agent qui est chuchoté ne peut pas cliquer sur le bouton Écouter ou Insérer.",
  "ccd.agent.openeye.switchshare.success": "La fonction de partage de bureau est activée avec succès.",
  "ccd.agent.message.phone": "Numéro d'appelé",
  "ccd.agent.button.cancelRest": "Annuler le reste",
  "ccd.agent.agentevent.mediaAblity": "Type de média:",
  "ccd.agent.agentevent.AgentOther_PhoneRelease": "L'agent a raccroché",
  "ccd.agent.openeye.sipconfig.fail": "Impossible de configurer le SIP. Connectez-vous à nouveau en tant qu'agent ou connectez-vous manuellement au OpenEye.",
  "ccd.agent.openeye.deamonready.failinfo": "Impossible de démarrer l'OpenEye.",
  "ccd.agent.agentevent.AgentEvent_Hold.desc": "L'agent est déjà en attente",
  "ccd.agent.agentevent.AgentMediaEvent_StopPlayDone": "La lecture de l'enregistrement s'est arrêtée.",
  "ccd.agent.agentevent.feature.caller": "Numéro de l'appelant:",
  "ccd.agent.openeye.register": "Le numéro de téléphone logiciel est enregistré avec succès.",
  "ccd.agent.agentevent.type.main": "appel principal",
  "ccd.agent.tips.info": "Conseils",
  "ccd.agent.agentevent.failed": "Échec",
  "ccd.agent.button.rest": "Repos",
  "ccd.agent.button.signIn": "Se connecter",
  "ccd.agent.openeye.share": "Veuillez sélectionner...",
  "ccd.agent.agentevent.feature.called": "Numéro appelé:",
  "ccd.agentmonitor.span.listening": "Écouter",
  "ccd.agent.agentevent.type.assistant": "appel d'assistance",
  "ccd.agent.openeye.already.register": "Le numéro de téléphone logiciel a été enregistré.",
  "ccd.agent.agentevent.AgentEvent_Release_Preview": "L'appel de prévisualisation est publié avec succès.",
  "ccd.agent.agentevent.AgentEvent_Preview_CallOut.controlid": "Prévisualiser l'ID du bloc de contrôle d'appel sortant :",
  "ccd.agent.title.consult": "Consulter",
  "ccd.agent.webrtc.label.localvideo": "Vidéo locale",
  "ccd.agent.message.AgentIsSupend": "L'agent actuel est gelé.",
  "ccd.agent.agentevent.AgentEvent_Customer_Release": "Le client quitte l'appel.",
  "ccd.agent.query.sip.fail": "Impossible d'interroger les données SIP. Connectez-vous à nouveau en tant qu'agent ou connectez-vous manuellement au OpenEye.",
  "ccd.agent.agentevent.AgentEvent_Preview_CallOut.dialeddigits": "Prévisualiser le numéro appelé sortant:",
  "ccd.agent.webrtc.label.offline": "Non enregistré",
  "ccd.agent.agentevent.AgentEvent_Ringing": "L'appel entrant de l'agent",
  "ccd.agent.button.callOut": "Appeler",
  "ccd.agent.webrtc.label.connetfailed": "Échec",
  "ccd.agent.webrtc.label.buildfailed": "Impossible de configurer la connexion.",
  "ccd.agent.webrtc.label.unsupportbrower": "Le navigateur ne supporte pas cette fonction.",
  "ccd.agent.webrtc.label.sotpsendvideo": "Arrêter d'envoyer des vidéos",
  "ccd.agent.login.fail": "l'enregistrement a échoué.",
  "ccd.agent.tips.welcome": "Bon retour. Bonne journée !",
  "ccd.agent.agentevent.mediaAblity.voice": "audio",
  "ccd.agent.agentevent.callId": "Numéro d'appel:",
  "ccd.agent.openeye.stopshare.fail": "Impossible d'arrêter le partage.",
  "ccd.agent.agentevent.AgentOther_PhoneAlerting": "L'agent sonne",
  "ccd.agent.webrtc.label.missmic": "Le microphone n'est pas disponible.",
  "ccd.agent.openeye.videodisplaymode.success": "Réussi à définir le mode de recadrage de la fenêtre vidéo.",
  "ccd.message.title.mediaIncomingCallNotify": "Notification d'appel entrant multimédia",
  "ccd.agent.webrtc.label.misspeaker": "Le haut-parleur n'est pas disponible.",
  "ccd.agent.agentevent.otherPhone": "Numéro de téléphone/ID de travail de la sonnerie:",
  "ccd.agent.openeye.videolayoutmode.fail": "Impossible de définir le mode d'agencement de l'image de la fenêtre vidéo.",
  "ccd.agent.openeye.videodisplaymode.fail": "Impossible de définir le mode de recadrage de la fenêtre vidéo.",
  "ccd.agent.agentevent.mediaAblity.video": "Vidéo",
  "ccd.agent.agentevent.quality.calloutconnectfail": "Impossible de faire un appel sortant parce que le QC est en cours.",
  "ccd.agent.openeye.initsuccess": "L'OpenEye est initialisée avec succès.",
  "ccd.agent.agentevent.AgentEvent_Conference": "L'appel à trois est réussi.",
  "ccd.agent.webrtc.label.sippwd.placeholder": "Entrez le mot de passe du softphone.",
  "ccd.message.title.mediaNewMsgNotify": "Nouvelle notification de message multimédia",
  "ccd.agent.webrtc.label.busy.tip": "Chamada",
  "ccd.agent.agentevent.AgentState_SetRest_Success": "L'agent s'est reposé avec succès.",
  "ccd.agent.button.mediaswitch": "Commutateur audio/vidéo",
  "ccd.agent.webrtc.label.medium": "moyen",
  "ccd.agent.webrtc.label.videosize": "Taille de la vidéo",
  "ccd.agent.agentevent.AgentState_Force_SetNotReady": "L'agent a placé occupé avec succès.",
  "ccd.agent.callrelease.end": "Appel terminé",
  "ccd.ccaction.title.shareapp": "Sélectionner un programme partagé",
  "ccd.agent.webrtc.label.remotevideo": "Vidéo à distance",
  "ccd.agent.agentevent.feature.callout": "Appel sortant commun",
  "ccd.agent.button.close": "Annuler",
  "ccd.agent.webrtc.label.passwordvalidator": "La longueur du caractère saisi dépasse la plage de valeurs. La longueur doit être inférieure ou égale à 50 caractères.",
  "ccd.agent.openeye.autoanswer.fail": "Impossible de répondre automatiquement à l'appel.",
  "ccd.agent.agentevent.AgentEvent_MediaSwith.failed": "Échec de la commutation audio/vidéo.",
  "ccd.agent.openeye.register.fail": "Le numéro de téléphone logiciel est déjà enregistré, ou d'autres raisons.",
  "ccd.media.tips.new.online.customer": "Vous avez un nouveau client en ligne",
  "ccd.agent.agentevent.AgentOther_All_Agent_Busy": "Tous les agents sont occupés.",
  "ccd.agent.agentevent.AgentEvent_ReturnFromIvr": "L'agent est renvoyé avec succès de l'IVR.",
  "ccd.agent.webrtc.label.reject": "Rejeter",
  "ccd.agent.webrtc.label.button.yes": "Oui",
  "ccd.agent.warning.notification.cancel": "Annuler",
  "ccd.agent.agentevent.calloutconnectfail": "L'abonné est occupé ou le numéro n'existe pas.",
  "ccd.agent.button.sayFree": "Définir Idle",
  "ccd.agent.login.failinfo": "En conséquence, la fonction d'aide interne n'est pas disponible.",
  "ccd.agent.webrtc.label.busy.info": "Astuces",
  "ccd.agent.webrtc.label.local.name": "Agent",
  "ccd.agent.webrtc.label.small": "petit",
  "ccd.agent.message.AgentIsEmpty": "Le compte courant n'est pas lié à un agent.",
  "ccd.agent.button.hold": "Maintenir",
  "ccd.agentmonitor.span.whisper": "Chuchotement",
  "ccd.agent.button.offlineSignOut": "Quitter le mobile",
  "ccd.agent.agentevent.AgentEvent_No_Answer": "L'agent ne répond pas à l'appel depuis longtemps.",
  "ccd.agent.agentevent.AgentMediaEvent_JumpPlayDone": "L'enregistrement est avancé rapidement ou rembobiné avec succès.",
  "ccd.agent.agentevent.AgentEvent_Hold": "L'appel est tenu avec succès.",
  "ccd.agent.webrtc.label.talk": "Appeler",
  "ccd.agent.label.rest": "Repos",
  "ccd.agent.openeye.autoanswer": "L'appel est répondu automatiquement.",
  "ccd.agent.agentevent.AgentOther_ShutdownService": "L'agent a signé avec succès.",
  "ccd.agent.agentevent.AgentState_CancelWork_Success": "L'agent a quitté l'état de fonctionnement avec succès.",
  "ccd.agent.openeye.setshareapp.fail": "Impossible de définir le partage d'écran.",
  "ccd.message.title.audioIncomingCallNotify": "Notification d'appels entrants audio et vidéo",
  "ccd.agent.openeye.deamonready.fail": "Impossible de démarrer l'OpenEye Connectez-vous manuellement à la OpenEye la fonction de réponse automatique ne peut pas être utilisée.",
  "ccd.agent.button.twostage": "Dialpad",
  "ccd.agent.warning.notification.allowed": "Pour assurer les alertes des messages de session, le site Web doit être autorisé à afficher les notifications!",
  "ccd.agent.agentevent.AgentEvent_Talking": "L'agent est entré dans l'état Talking.",
  "ccd.agent.openeye.startshare.fail": "Impossible de commencer le partage.",
  "ccd.agent.msg.notification.allowed": "Demande de site Web AICC Autoriser le navigateur à afficher les notifications!",
  "ccd.agent.agentevent.AgentEvent_Connect_Fail": "Échec de la connexion",
  "ccd.agent.openeye.videowindowparam": "Les paramètres vidéo sont initialisés avec succès.",
  "ccd.agent.warning.notification.setting": "Paramètres",
  "ccd.agent.label.talk": "Discuter",
  "ccd.agent.button.getHold": "Annuler la mise en attente",
  "ccd.agent.agentevent.AgentEvent_Preview_CallOut": "La prévisualisation de l'appel sortant a réussi.",
  "ccd.agent.webrtc.label.changeVoice": "Transfert vers la voix",
  "ccd.agent.agentevent.AgentEvent_Conference.partner": "Numéro de l'appelant:",
  "ccd.agent.agentevent.success": "Succès",
  "ccd.agent.resetphonepass.input": "Mot de passe",
  "ccd.agent.tips.confSuccess": "La configuration est réussie.",
  "ccd.agent.agentevent.AgentOther_Restskill_Queue": "L'opération de file d'attente de compétences a réussi.",
  "ccd.agent.webrtc.label.logoutregistered": "S'il faut se déconnecter.",
  "ccd.agent.reset.success": "Réservation de repos réussie.",
  "ccd.agent.button.restSkillQueue": "Réinitialiser les compétences",
  "ccd.agent.warning.sharescreen": "Faites attention à la vie privée lorsque vous partagez le bureau",
  "ccd.agent.msg.queryMsg.specialListQuery": "Notification de liste spéciale",
  "ccd.agent.message.AgentIsProhibited": "L'espace loué a été interdit.",
  "ccd.agent.message.repeatPhonenum": "Le numéro de téléphone utilisé par l'agent pour se connecter a été utilisé par un autre agent pour se connecter. Vérifiez la configuration.",
  "ccd.agent.message.destinationAgentFailed": "Impossible d'occuper l'agent de destination.",
  "ccd.agent.message.noAgent": "Pas d'agent en service.",
  "ccd.agent.warning.waserror": "Impossible d'accéder à la ressource WAS ou une erreur interne se produit.",
  "ccd.quality.message.cancelwhisper": "Stop Whisper",
  "ccd.agent.videocall.stateError": "L'état de l'appel est incorrect lorsque le mode d'affichage est ajusté pour un appel vidéo à trois parties.",
  "ccd.cccbar.label.backspace": "Backspace",
  "ccd.agent.videomode.triangular": "Triangulaire",
  "ccd.ccaction.message.holdError": "L'appel actuel ne peut pas être maintenu.",
  "ccd.agent.status.hold": "Maintenir",
  "ccd.agent.message.selectTeams": "Sélectionnez un compte d'équipe.",
  "ccd.agent.message.restInvalid": "Le paramètre ou l'adresse transféré via l'URL n'est pas conforme à la définition.",
  "ccd.transfer.message.inputIvrName": "Entrez un ivr pour le transfert.",
  "ccd.agent.videomode.maximizeThreeparty": "Maximiser les trois parties",
  "ccd.agent.void.call": "Appel sortant vocal",
  "ccd.ccaction.message.retCode": "Erreur Annuler le travail lorsque vous définissez occupé. Code d'erreur :",
  "ccd.agent.videocall.paramError": "Erreur de paramètre lors du réglage du mode d'affichage d'un appel vidéo à trois parties.",
  "ccd.ccaction.message.minutes": "Minutes",
  "ccd.ccaction.message.isForceBusy": "L'agent a été montré occupé.Ne pas afficher à plusieurs reprises occupé.",
  "ccd.transfer.message.NoAgentInSkill": "Aucun agent en ligne n'existe dans la file d'attente de compétences actuelle.",
  "ccd.cccbar.message.syserrormessage": "Erreur système.",
  "ccd.agent.status.help": "Consulter",
  "ccd.ccaction.message.logoutfailed": "La déconnexion a échoué. Veuillez vérifier le statut de l'agent.",
  "ccd.ccagent.contract.preview": "Aperçu",
  "ccm.agent.message.selectIvr": "Sélectionnez un flux IVR.",
  "ccd.ccaction.message.search.restCauseDesc": "Saisissez la description de la raison du reste.",
  "ccd.quality.message.insert": "Insérer",
  "ccd.outgoing.out.workbench": "Établi de travail sortant",
  "ccd.transfer.label.skill": "File d'attente de compétences",
  "ccd.transfer.button.refresh": "Actualiser",
  "ccd.agent.status.innerThree": "Trois-parties",
  "ccd.agent.message.threePartyNoCall": "Pas d'appel pour que vous initiiez un appel à trois.",
  "ccd.innerhelp.label.innerHelpToSkill": "Demande de consultation de Skill Queue",
  "ccd.record.message.stopPlay": "Une erreur d'état se produit lorsque la lecture s'arrête.",
  "ccd.transfer.label.ivr": "IVR",
  "ccd.ccaction.message.abilityError": "Vous ne pouvez pas couper l'utilisateur dans la fonction média courante.",
  "ccd.agent.message.agentNotLogin": "L'agent ne se connecte pas.",
  "ccd.agent.videocall.adjustvideo": "Ajustement de la taille de la vidéo",
  "ccd.agent.status.askAnswer": "Attendre la réponse",
  "ccd.transfer.label.agent": "Agent",
  "ccd.cccbar.label.voice": "audio",
  "ccd.agent.message.loginInvalidPara": "La connexion a échoué. Le paramètre de connexion est vide ou invalide.",
  "ccd.innerhelp.label.innerHelpToAgent": "Demande de consultation de l'agent",
  "ccd.agentMonitor.label.selectAgentOne": "Veuillez mettre fin à l'agent en cours de traitement et sélectionner une autre opération d'agent.",
  "ccd.quality.message.whisper": "Whisper",
  "ccd.agent.mediaswitch.unsupportedagenttype": "L'agent actuel ne prend pas en charge les appels vidéo.",
  "ccd.ccaction.message.unHold": "Pas d'appel à retenir dans l'état actuel.",
  "ccd.agent.title.warning": "Alarme",
  "ccd.cccbar.label.video": "Vidéo",
  "ccd.ccaction.message.errorRetCode": "Erreur! Code d'erreur:",
  "ccd.agent.message.mediaSwitchNoCall": "Aucun appel n'est effectué pendant la commutation audio/vidéo.",
  "ccd.agent.mediaswitch.switchaudiofailed": "Déjà dans un appel audio.",
  "ccd.ccaction.message.recording": "La voix ne peut être jouée que lorsque l'agent est en état d'inactivité. Veuillez modifier le statut de l'agent.",
  "ccd.record.message.resumePlay": "Une erreur d'état se produit lorsque la lecture se poursuit.",
  "ccd.transfer.label.successTransfer": "Transfert de succès",
  "ccd.ccaction.message.confJoinFail": "L'agent ne se connecte pas, et l'appel à trois parties échoue.",
  "ccd.agent.message.noHoldCall": "L'appel n'est pas retenu.",
  "ccd.agent.message.releaseNumberNoCall": "Aucun appel pour communiquer un numéro de téléphone spécifié.",
  "ccd.cccbar.label.total": "Total :",
  "ccd.quality.message.forceexit": "Forcer la sortie",
  "ccd.agent.videocall.noCall": "Aucune erreur d'appel ne se produit lorsque le mode d'affichage d'un appel vidéo à trois parties est ajusté.",
  "ccd.innerhelp.label.twoPartyHelp": "Consultation en deux parties",
  "ccd.quality.message.interceptErr": "L'agent à vérifier n'est pas dans un appel et ne peut pas être intercepté.",
  "ccd.cccbar.label.callcenter": "Centre d'appels",
  "ccd.quality.message.noAgentInfo": "Aucune information d'agent n'est trouvée lors du contrôle de la qualité.",
  "ccd.cccbar.message.pleaseAnswer": "Répondez à l'appel.",
  "ccd.transfer.label.hangUpTransfer": "Transfert à raccrocher",
  "ccd.agent.message.holdCallStatusErr": "Une erreur d'état d'appel se produit lorsque vous maintenez l'appel.",
  "ccd.agent.forcesignout.confirm": "La déconnexion affectera le service de connexion que l'agent est en train de traiter. Êtes-vous sûr de vouloir signer de force ?",
  "ccd.agent.message.mediaSwitchFailed": "Échec de la commutation audio/vidéo.",
  "ccd.record.message.jumpBack": "Une erreur d'état se produit pendant le rembobinage.",
  "ccd.agentMonitor.intercept.noAudio": "Aucun appel vocal ou vidéo ne peut être intercepté.",
  "ccd.agent.message.endMuteNoCall": "Aucun appel n'existe lorsque vous annulez la mise en sourdine.",
  "ccd.quality.message.forcebusy": "Force Occupé",
  "ccd.transfer.label.externalNumber": "Numéro externe",
  "ccd.agent.message.beginMuteNoCall": "Pas d'appel pour la mise en sourdine.",
  "ccd.agent.chat.channle.choose": "Sélection des canaux",
  "ccd.quality.message.noProvider": "L'erreur NOPROVIDER se produit lors du contrôle de qualité.",
  "ccd.agentMonitor.forceRelease.noAudio": "Aucun appel audio ou vidéo ne peut être diffusé.",
  "ccd.transfer.label.statusUnknow": "Inconnu",
  "ccd.ccaction.message.repeateRest": "L'agent est le repos. Ne forcez pas spectacle occupé.",
  "ccd.agent.message.logOutStatusErr": "Échec de la déconnexion. Vous avez peut-être une affaire en attente à gérer.",
  "ccd.agent.videomode.maximizeUser": "Maximiser l'utilisateur",
  "ccd.agent.status.mute": "muet",
  "ccd.agent.videomode.maximizeAgent": "Maximiser l'agent",
  "ccd.agent.tips.restTooLong": "Les temps de repos.",
  "ccd.agent.status.occupy": "Occupé",
  "ccd.record.message.startRecord": "Une erreur d'état se produit au démarrage de l'enregistrement.",
  "ccd.agent.message.monitorSelf": "L'agent inspecté et l'inspecteur ne peuvent pas être les mêmes.",
  "ccd.login.message.logoutReason.sessionInvalid": "La session n'est pas valide. Reconnectez-vous s'il vous plaît.",
  "ccd.transfer.message.microsoftTeamsLogin": "Se connecter",
  "ccd.transfer.message.inputTeamsUser": "Entrez le compte de l'équipe.",
  "ccd.transfer.label.skillName": "File d'attente de compétences",
  "ccd.ccaction.message.retMessEnd": ".",
  "ccd.record.message.pauseRecord": "Une erreur d'état se produit lors de l'enregistrement des pauses.",
  "ccd.agent.message.InvalidNumber": "Numéro non valide.",
  "ccd.agent.videocall.typeError": "Le type d'appel est incorrect lorsque le mode d'affichage est ajusté pour un appel vidéo à trois parties.",
  "ccd.ccaction.message.recordFail": "L'agent ne se connecte pas, et la lecture de la voix échoue.",
  "ccd.transfer.label.transferToSkill": "Transfert vers Skill Queue",
  "ccd.ccaction.message.confJoinErr": "Aucun appel n'est retenu. Un appel à trois parties n'est pas autorisé.",
  "ccd.agent.message.insultNoCall": "Aucun appel pour Consulter.",
  "ccd.callmessage.workbench": "Espace de travail audio & vidéo",
  "ccd.agent.message.beginMuteStateErr": "Une erreur d'état d'appel se produit lorsque vous vous mettez en sourdine.",
  "ccd.ccaction.message.hour": "Horaires",
  "ccd.agent.status.threeParty": "Trois-parties",
  "ccd.agent.message.loginInvalidParam": "Le numéro de téléphone n'est pas valide ou a été connecté par un autre agent.",
  "ccd.agent.message.checkAgentStatus": "Effectuez cette opération lorsque l'inspecteur est inactif.",
  "ccd.agent.message.loginInvalidPhone": "Numéro de téléphone non valide.",
  "ccd.quality.message.intercept": "intercepter",
  "ccd.agent.mediaswitch.switchvideofailed": "Déjà dans un appel vidéo.",
  "ccd.agent.message.mediaSwitchSuccess": "Commutation audio/vidéo Succès.",
  "ccd.quality.message.searchWorkno": "S'il vous plaît entrer l'ID de l'agent pour rechercher.",
  "ccd.cccbar.label.yourself": "Moi-même",
  "ccd.transfer.label.transferToAgent": "Transfert à l'agent",
  "ccd.cccbar.label.cccbar": "Barre de connexion",
  "ccd.contact.message.timeerror": "L'heure de début doit être antérieure à l'heure de fin.",
  "ccd.quality.message.qualityTitle": "Contrôle de qualité",
  "ccd.cccbar.label.empty": "Tout effacer",
  "ccd.contact.message.timerangeerror": "L'intervalle de temps ne peut pas dépasser {0} jours.",
  "ccd.cccbar.label.send": "Envoyer",
  "ccd.ccaction.message.error": "Erreur",
  "ccd.quality.message.stopListen": "Arrêter d'écouter",
  "ccd.quality.message.switch": "commutateur",
  "ccd.agent.status.innerCall": "Appel interne",
  "ccd.quality.message.statusErr": "Le statut est anormal lors du contrôle de qualité.",
  "ccd.callout.message.numberFormat": "Un numéro de téléphone ne peut contenir que les chiffres, le signe numérique (#), et l'astérisque (*), et contient un maximum de 24 caractères.",
  "ccd.ccaction.message.inputWorkNo": "L'ID de l'agent est vide. Veuillez entrer l'ID de l'agent.",
  "ccd.cccbar.message.calltip": "Bonjour, un nouvel appel arrive !",
  "ccd.agent.message.getHoldCallStatusErr": "Une erreur d'état d'appel se produit lorsque vous annulez l'appel.",
  "ccd.ccaction.message.statusError": "Aucun appel à la muette dans l'état actuel.",
  "ccd.innerhelp.label.helpType": "Consulter le type",
  "ccd.cccbar.label.nocall": "Pas d'appel.",
  "ccd.callmessage.chatOnlineWorkbench": "Workbench de chat en ligne",
  "ccd.cccbar.label.inputNumber": "Entrez un nombre.",
  "ccd.record.message.startPlay": "Une erreur d'état se produit au démarrage de la lecture.",
  "ccd.innerhelp.message.inputExNumhelp": "Entrez un numéro pour Consulter",
  "ccd.agent.message.connectHoldNoCall": "Pas d'appel à retenir.",
  "ccd.agent.message.restNoRight": "L'agent n'a pas la permission d'invoquer l'interface. L'agent a peut-être été forcé de signer.",
  "ccd.agent.label.recordPlay": "Lecture vocale",
  "ccd.ccaction.message.registerPhoneFailed": "L'enregistrement WebRTC échoue. Actuellement, les fonctions d'aide interne ne sont pas disponibles.",
  "ccd.agentstate.notTalking": "L'agent sélectionné n'est pas dans l'état parlant.",
  "ccd.agent.chat.mutimedia.call": "Appel sortant multimédia",
  "ccd.cccbar.label.cancel": "Annuler",
  "ccd.quality.message.resourceUnable": "Une exception se produit au cours de l'inspection. Vérifiez les journaux pertinents.",
  "ccd.agent.message.setStatErr": "Impossible de définir le statut de l'agent.",
  "ccd.quality.message.inspectInspectorErr": "L'agent inspecté ne peut pas être un inspecteur.",
  "ccd.transfer.button.sure": "OK",
  "ccd.quality.message.listen": "Écouter",
  "ccd.transfer.label.transferNumber": "Indique si le processus est un processus libre-service.",
  "ccd.agent.message.endMuteStateErr": "Une erreur d'état d'appel se produit lorsque vous annulez la mise en sourdine.",
  "ccd.cccbar.label.callout": "Appeler",
  "ccd.transfer.label.releaseTransfer": "Transfert de libération",
  "ccd.contact.message.timeisempty": "La plage de temps ne peut pas être vide.",
  "ccd.transfer.label.transferType": "Type de transfert",
  "ccd.agent.status.talk": "Parler",
  "ccd.agent.message.eventMethodErr": "La méthode d'obtention de l'événement agent est incorrecte.",
  "ccd.transfer.button.cancel": "Annuler",
  "ccd.ccaction.message.retMessage": "Message d'erreur :",
  "ccd.agent.mediaswitch.video": "Appel vidéo",
  "ccd.agent.chat.start": "Initiation d'une session",
  "ccd.agent.message.AgentAlreadyLogout": "L'agent a signé.",
  "ccd.ccaction.message.repeateBusy": "L'agent est occupé maintenant. Ne pas mettre occupé à plusieurs reprises.",
  "ccd.agent.message.threePartyNoHoldCall": "Aucun appel n'est maintenu pour lancer un appel à trois parties.",
  "ccd.agent.message.invalidWasURL": "Le chemin de liaison WAS configuré sur le serveur est incorrect.",
  "ccd.record.message.resumeRecord": "Une erreur d'état se produit lorsque l'enregistrement se poursuit.",
  "ccd.callinner.label.callinner": "Appel interne",
  "ccd.agent.message.getHoldNoHoldCall": "Pas d'appel à unhold.",
  "ccd.ccaction.message.inputIvrName": "Le nom du flux IVR est vide. Veuillez saisir le nom du flux IVR.",
  "ccd.agent.message.userOrPasswdInvalid": "La connexion a échoué. Le compte ou le mot de passe est incorrect.",
  "ccd.transfer.label.talkingTransfer": "Transfert de conversation",
  "ccd.agent.message.misconnection": "Impossible d'appeler l'interface de persistance de session.",
  "ccd.transfer.message.inputExNumber": "Veuillez saisir le numéro à transférer",
  "ccd.agent.message.accoutnLocked": "Le compte est verrouillé. Connectez-vous plus tard.",
  "ccd.quality.message.back": "Retour",
  "ccd.transfer.label.callTransfer": "Transfert d'appel",
  "ccd.agent.message.answerNoCall": "Pas d'appel à répondre.",
  "ccd.record.message.jumpForw": "Une erreur d'état se produit pendant le transfert.",
  "ccd.agent.message.releaseNumberNoNumber": "Pas d'appel du numéro de téléphone à libérer.",
  "ccd.agent.message.AgentInnerError": "L'opération a échoué. La cause possible est qu'une erreur interne se produit, qu'aucune ressource n'est disponible ou que l'agent n'est pas inactif.",
  "ccd.agent.message.wait": "L'appel est en cours de traitement. Veuillez réessayer plus tard.",
  "ccd.agent.message.reset": "Trop d'échecs de traitement d'appels, essayant de synchroniser l'état de l'agent",
  "ccd.agent.previewcall.invalParam": "Les paramètres de prévisualisation de l'appel sortant sont incorrects.",
  "ccd.ccaction.message.seconds": "secondes",
  "ccd.agent.message.default": "Erreur interne. Vérifiez le journal.",
  "ccd.agent.status.innerTwo": "Consulter",
  "ccd.ccaction.message.rest": "Repos",
  "ccd.agent.message.callOutPhoneErr": "Le numéro d'appel sortant n'est pas valide.",
  "ccd.agent.message.holdNoCall": "Pas d'appel à retenir.",
  "ccd.innerhelp.label.threePartyHelp": "Consultation en trois parties",
  "ccd.record.message.pausePlay": "Une erreur d'état se produit lors d'une pause de lecture.",
  "ccd.agent.callout.notrest": "Annulez la pause, puis faites un appel sortant.",
  "ccd.quality.message.qualityAgentTitle": "Moniteur d'agent",
  "ccd.agent.message.transferNoCall": "Pas d'appel au transfert.",
  "ccd.ccaction.message.resignin": "Le mot de passe du siège n'est pas valide, veuillez vérifier à nouveau!",
  "ccd.callmessage.whiteNumberExist": "Le numéro n'existe pas dans la liste de confiance.",
  "ccd.agent.mediaswitch.audio": "Appel audio",
  "ccd.transfer.message.refreshTab": "Ne rafraîchissez pas la page à plusieurs reprises.",
  "ccd.quality.message.stopInsert": "Arrêter l'insertion",
  "ccd.agent.videocall.displayError": "Une exception se produit lorsque le mode de présentation d'un appel vidéo à trois parties est ajusté.",
  "ccd.transfer.label.transferToExNumber": "Transfert vers un numéro externe",
  "ccd.agent.status.callout": "Appel sortant",
  "ccd.quality.message.forcerest": "Forcer le ralenti",
  "ccd.innerhelp.label.help": "Consulter",
  "ccd.transfer.message.NoIvrOnline": "Aucun flux IVR n'est disponible.",
  "ccd.quality.message.noAuth": "Aucune exception d'autorisation ne se produit lors du contrôle de qualité.",
  "ccd.agent.message.releaseNoCall": "Pas d'appel pour raccrocher.",
  "ccd.agent.message.hangupnocall": "L'appel ne peut pas être raccroché. Si l'appel est en attente, s'il vous plaît annuler l'attente d'abord.",
  "ccd.transfer.label.threeParthTransfer": "Transfert à trois parties",
  "ccd.agent.message.needLogin": "Connectez-vous en tant qu'agent et essayez à nouveau.",
  "ccd.ccaction.message.registerPhone": "Enregistrez le softphone.",
  "ccd.agent.message.logOutInvalidParam": "La connexion a échoué. Le paramètre n'est pas valide.",
  "ccd.agent.message.qc.restskill": "La file d'attente de compétences ne peut pas être réinitialisée si un inspecteur ne répond pas à un appel entrant.",
  "ccd.transfer.label.transferTypeColon": "Type de transfert:",
  "ccd.transfer.label.transferTypeColon.ccbar": "Type:",
  "ccd.ccaction.message.loginagent": "Connectez-vous en tant qu'agent.",
  "ccd.skill.selectskill": "Sélectionnez au moins une file d'attente de compétences.",
  "ccd.transfer.label.queueSize": "Attente en ligne",
  "ccd.transfer.label.queueSize.ccbar": "Attente en ligne",
  "ccd.transfer.label.loggedOnAgents": "Agent disponible",
  "ccd.transfer.label.loggedOnAgents.ccbar": "Agent disponible",
  "ccd.label.search": "Rechercher",
  "ccd.label.org.all": "Toutes les organisations accessibles",
  "ccd.title.call.three": "Appel à trois voies",
  'ccd.agent.message.calloutagent.restskill': "L'agent d'appel ne répond pas aux appels entrants et ne peut pas réinitialiser les files d'attente de compétences.",
  "ccd.agent.message.videoLimit": "Les ressources de l'agent vidéo sont insuffisantes, et l'agent ne parvient pas à se connecter au serveur multimédia vidéo.",
  "ccd.agent.message.withoutskill": "La file d'attente des compétences est manquante.",
  "ccdesktop.login.wertc.failed": "L'enregistrement du Softphone webrtc a échoué",
  "ccd.agent.label.skillGroupOrSkill": "Groupe de compétences / File d'attente de compétences",
  "ccd.transfer.placeholder.agentName": "Entrez le nom de l'agent",
  "ccd.agent.videocall.displayWarn": "La taille de la fenêtre vidéo peut être ajustée uniquement lorsque les trois parties jouent des vidéos.",
  "ccd.ccaction.message.nosupport": "Cette opération n'est pas supportée sur la page web. Utilisez Œil ouvert pour couper le son.",
  "ccd.ccbar.before.signin.tips":"Avant d'utiliser la fonction de connexion, cliquez pour vous connecter.",
  "ccd.ccbar.calling.number":"Numéro d'appel",
  "ccd.ccbar.mediaSwitch.failed":"Échec du changement de média.",
  "ccd.ccbar.mediaSwitch.notsupport":"La commutation audio et vidéo n'est pas autorisée pour les appels internes en mode vidéo sur le MCU.",
  "ccd.webrtc.message.bad":"Mauvais",
  "ccd.webrtc.message.poor":"Les pauvres",
  "ccd.webrtc.message.good":"Bon",
  "ccd.webrtc.message.recive":"Recevoir",
  "ccd.webrtc.message.Packetloss":"Perte de paquets",
  "ccd.webrtc.message.delay":"Retard",
  "ccd.webrtc.message.jitter":"Jitter",
  "ccd.webrtc.message.send":"Par courrier",
  "ccd.webrtc.message.signal":"Le signal",
  'ccd.webrtc.message.medium':'Bon',
  'ccd.webrtc.login.success':'La connexion au softphone est réussie',
  'ccd.webrtc.login.fail':'Échec de connexion au téléphone logiciel',
  'ccd.webrtc.login.fail.noPwd':'Aucun mot de passe initial n\'est disponible. Réinitialisez le mot de passe du softphone et réessayez.',
  'ccd.webrtc.login.fail.pop.tips':'Impossible de se connecter automatiquement au softphone. Vous ne pouvez pas utiliser la fonction vocale. Reconnectez-vous au softphone ?',
  'ccd.webrtc.login.try':'Essayez à nouveau',
  'ccd.webrtc.login.ignore':'Négligence',
  'ccd.webrtc.login.fail.top.tips':'La connexion au softphone est anormale, et les fonctions connexes telles que la voix ne peuvent pas être utilisées',
  "ccd.ccbar.webrtc.network.signal.pool":"Le signal réseau actuel est pauvre, ce qui peut affecter l'appel.",
  "ccd.agentMonitor.label.switchwhisper": "Vous ne pouvez pas cliquer sur Whisper pour un agent en état de pré-écoute ou par insertion.",
  'ccd.agent.webrtc.register.tip':'Enregistrer le softphone?',
  "ccd.consult.message.two.inputExNumber": "S'il vous plaît entrer le numéro pour la consultation de deux parties",
  "ccd.consult.message.three.inputExNumber": "S'il vous plaît entrer le numéro pour la consultation de trois parties",
  "ccd.ccbar.mediaSwitch.sfunotsupport":"L'appel actuel ne prend pas en charge le changement de média.",
  "ccd.agent.tips.welcome.agentlogout": "Bon retour !",
  "ccd.agent.message.continuously.online":"Le service en ligne sera maintenu après l'enregistrement. S'il vous plaît vérifier à temps après le travail est terminé.",
  "ccd.agent.vrpserviceaddress.isempty" : "L'adresse du serveur VRP est vide. Contactez l'administrateur système pour le configurer.",
  "ccd.agent.initializeagentex.success" : "L'agent s'enregistre avec succès auprès de la VRC. Votre bureau sera surveillé et votre appel sera enregistré à l'écran. Veuillez maintenir un bon état de fonctionnement.",
  "ccd.agent.initializeagentex.passworderror" : "ID d'agent ou mot de passe incorrect. Impossible de s'inscrire auprès du VRC.",
  "ccd.agent.initializeagentex.alreadyregistered" : "L'agent s'est inscrit auprès de la VRC.",
  "ccd.agent.vrpexe.closed" : "Le logiciel VRC est en cours d'arrêt.",
  "ccd.agent.startMonitorEx.uninitialize" : "La connexion vrc n'est pas valide. Connectez-vous à nouveau à l'agent actuel.",
  "ccd.agent.startMonitorEx.qcnotexit" : "L'objet QC n'existe pas. Vérifiez si le préposé est correctement connecté au VRC.",
  "ccd.agent.startMonitorEx.qctimelimitexceed" : "La connexion associée au vrc a échoué. Veuillez vous connecter à nouveau.",
  "ccd.agent.startMonitorEx.qcunsigned": "L'agent n'a pas signé. Vérifiez si l'agent s'est connecté ou doit se connecter à nouveau.",
  "ccd.agent.startMonitorEx.othererror": "Impossible de se connecter au serveur. Contactez le personnel de maintenance ou réessayez plus tard.",

}