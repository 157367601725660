import axios from 'axios'
import { qsStringify } from './index'
import cookieUtils from '@/utils/cookie'
import { useBaseStore } from '@/views/base/stores'
import { ElMessageBox } from 'element-plus'
import i18n from '@/lang'
import { h } from 'vue'

// 创建axios实例
const service = axios.create({
  baseURL: '', // api的base_url
  timeout: 180000, // 请求超时时间
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
})

// request拦截器
service.interceptors.request.use(
  (config) => {
    config.headers['x-requested-with'] = 'XMLHttpRequest'
    if (config.url.includes('/u-route/')) {
      config.headers['Content-Type'] = 'application/ueefire'
    }

    if (config.requestHeaders && config.requestHeaders.length > 0) {
      config.requestHeaders.forEach((header) => {
        const key = Object.keys(header)[0]
        config.headers[key] = header[key]
      })
    }

    config.headers['u-token'] = cookieUtils.getCookie('u-token')
    config.headers['u-locale'] = cookieUtils.getCookie('u-locale')
    config.headers['access-token'] = cookieUtils.getCookie('access-token')

    // 处理请求参数
    handleParams(config)

    if (config.method === 'post' || config.method === 'put') {
      // 保留该结构，去uee后，该处可能需要进行调整
    } else {
      if (config.data) {
        config.url = config.url + '?' + qsStringify(config.data)
      }
    }

    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

// respone拦截器
service.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      const res = response.data
      //如果返回体中存在traceId，说明遇到了未知错误，需要前台展示
      if(res && res.traceId != undefined) {
            const $t = i18n.global.t
            ElMessageBox.confirm($t('aicc.traceId.error').replace("0",res.traceId), $t('SM.AUTH.TIPS.ROLETMPL_WARNING'), {
                confirmButtonText: $t('base.confirm'),
                cancelButtonText: $t('cms.recorddrive.label.button_cacel'),
                type: 'warning'
            })
      }

      //如果返回体中存在errorDisplay并且为正，说明遇到了需要前台展示的报错
      if (res && res.errorDisplay != undefined && res.errorDisplay) {
        const $t = i18n.global.t
        ElMessageBox.confirm(h('p', null, [
          h('p', null, $t('base.id')+":"+$t(res.retCode)),
          h('p', null, $t('base.decs')+":"+$t(res.resultDesc)),
          h('p', null, $t('base.cause')+":"+ $t(res.resultCause)),
          h('p', null, $t('base.action')+":"+ $t(res.resultAction)),
        ]),{
          title: $t('base.error'),
          confirmButtonText: $t('base.confirm'),
          cancelButtonText: $t('cms.recorddrive.label.button_cacel'),
          type: 'warning'
        })
      }
      if (response['headers'] && response['headers']['content-disposition']) {
        return Promise.resolve(response) // 用于从返回头中获取下载文件名称
      }
      return Promise.resolve(res)
    } else if (response.status === 201) {
        // 智能ivr下载，返回状态为201
        if (response['headers'] && response['headers']['content-disposition']) {
            return Promise.resolve(response) // 用于从返回头中获取下载文件名称
        } else {
            return Promise.reject(response)
        }
    }else if (response.status === 202) {
        // 删除租间，返回状态为202
        return Promise.resolve(response.data)
    } 
    else {
      return Promise.reject(response)
    }
  },
  (error) => {
    // sumweb会话失效返回410 webrouter会话失效返回408
    if (error.response.status === 410 || error.response.status === 408) {
      // 针对签入坐席，清除轮询事件，坐席状态改为离线
      if (window?.$Model?.signStatus === 'signIn' && window?.$Model?.tenantStatus !== 'status_offline') {
        try {
          top?.window?.ccAction?.signOutAndForceSignOutParamProcess(window.$Scope, false, false);
          const SUCCESSCODE = "0";
          top?.window?.ccAction?.signOutSuccess({retcode: SUCCESSCODE});
        } catch (e) {
        }
      }
      // 获取store实例
      const baseStore = useBaseStore()
      baseStore.inErrorLogout() // 处理异常登出
    }

    if (error.response.status === 441) {
      top.window.ackTimeOut()
      if (
        window?.$Model?.signStatus === 'signIn' &&
        window?.$Model?.tenantStatus !== 'status_offline'
      ) {
        try {
          top?.window?.ccAction?.signOutAndForceSignOutParamProcess(window.$Scope, false, false)
          const SUCCESSCODE = '0'
          top?.window?.ccAction?.signOutSuccess({ retcode: SUCCESSCODE })
        } catch (e) {}
      }
      const baseStore = useBaseStore()
      baseStore.inTimeOutLogout()
    }
    return Promise.reject(error)
  }
)

// 处理请求参数
function handleParams(config) {
  if (!config.data) {
    // 防止不传参数的情况下，config中没有data属性
    config['data'] = {}
  }

  // 合并请求参数
  if (config.params) {
    config.data = {
      ...config.data,
      ...config.params
    }
  }
}

export default service
