export default {
  "ccivr.nms.leavemessageservice.choose.agent": "ID de l'agent spécifié",
  "ccivr.ivr.ivrflow.templateName": "Nom du modèle",
  "ccivr.ivr.ivrusage.skillName": "Nom de file d'attente de compétence",
  "ccivr.nms.ringService.videomodel": "Mode vidéo",
  "ccivr.ivr.tracelog.calledNumbererror": "Impossible de vérifier le numéro d'appel\!",
  "ccivr.ivr.voice.scene": "Scénario d'utilisation",
  "ccivr.nms.process.saveerror.noexistend": "L'enregistrement a échoué. Au moins un élément de diagramme de fin existe.",
  "ccivr.ivr.error.paramlimit": "La longueur totale du paramètre ne peut pas dépasser 4000 octets.",
  "ccivr.nms.tansferservice.chooseSkill": "Sélectionner la file d'attente de compétences",
  "ccivr.ivr.description.variableerror": "le format de la variable template est incorrect.",
  "ccivr.nms.shortmessageservice.countryCode": "Code de pays",
  "ccivr.nms.playset.open": "Activé",
  'ccivr.ivr.flowAccessCode.repeat': 'Le code d\'accès existe déjà!',
  "ccivr.ivr.voiceupload.fileformatconverterror": "Une erreur s'est produite lors de la conversion du format de fichier\, ou la configuration de FFmpeg est incorrecte.",
  "ccivr.ivrflow.grayrule.beginnum": "Numéro de départ",
  "ccivr.ivr.voiceedit.editfail": "Échec de la modification.",
  "ccivr.nms.queryservice.specialListQuery": "Informations sur la liste spéciale",
  "ccivr.nms.playcollectservice.videofile": "Fichier vidéo",
  "ccivr.ivr.ivrusage.ivrname": "Scénario de référence",
  "ccivr.ivr.voicereview.manualreview": "Révision manuelle",
  "ccivr.nms.timeselectservice.monday": "Lundi",
  "ccivr.ivr.ivrflow.name": "Nom",
  "ccivr.ivr.error.releaseFailed": "Impossible de publier le script automatiquement",
  "ccivr.ivr.voiceupload.sizewrong": "La taille du fichier dépasse 2 Mo. Importez à nouveau le fichier.",
  "ccivr.ivr.voiceupload.referenceFlow": "Informations de flux de référence",
  "ccivr.nms.conditionService.processLayout": "Orchestration de processus",
  "ccivr.ivrflow.unlock.confirm": "Confirmer le statut de déverrouillage dans l'édition?",
  "ccivr.nms.playservice.multivoice.type.number": "Numéro",
  "ccivr.nms.businessinvokeservice.value": "Valeur",
  "ccivr.nms.tansferservice.transferLabor": "Transfert vers Skill Queue",
  "ccivr.ivrflow.cancelrelease.referenced": "Le flux a été référencé par un code d'accès.",
  "ccivr.ivr.bussinessinvoke.interfacename": "Nom de l'interface",
  "ccivr.nms.playservice.multivoice.language24": "Thaïlande",
  "ccivr.nms.playservice.multivoice.language81": "Arabe",
  "ccivr.nms.playservice.multivoice.language23": "Ouzbékistan",
  "ccivr.flowtest.hashangup": "Le test se termine\, et l'appel est relâché.",
  "ccivr.nms.playservice.multivoice.language22": "Turque",
  "ccivr.nms.timeselectservice.saturday": "Samedi",
  "ccivr.nms.playservice.multivoice.language21": "Suédois",
  "ccivr.nms.playset.malevoice": "Voix masculine",
  "ccivr.nms.playservice.multivoice.language20": "Espagnol",
  "ccivr.ivr.voicereview.thailand": "Thaïlande",
  "ccivr.nms.timeselectservice.selectworktime": "Jour de la semaine",
  "ccivr.ivr.voiceupload.deletefail": "La suppression a échoué.",
  "ccivr.ivr.voiceedit.choosefile": "Sélectionner un fichier",
  "ccivr.nms.tansferservice.transferLastAgent.type.currentday.info": "L'appel est transféré à l'agent qui est contacté le jour en cours.",
  "ccivr.nms.tansferservice.transferLastAgent.type.none": "default",
  "ccivr.nms.satisfactionservice.isNull": "Le paramètre de satisfaction est vide",
  "ccivr.nms.queryservice.specifiedSkill.paramValue.sample": "Exemple \: 101 ou {\'$\'}{\'{\'}FLOW.workNo{\'}\'}.",
  "ccivr.ivr.ivrflow.operate.release.fail": "Échec de la libération.",
  "ccivr.ivr.voiceupload.languagemanage": "Gestion de la langue",
  "ccivr.nms.playservice.multivoice.language19": "Slovaque",
  "ccivr.nms.playservice.multivoice.language18": "Russe",
  "ccivr.nms.playservice.multivoice.language17": "Portugais",
  "ccivr.nms.playservice.multivoice.language16": "Polonais",
  "ccivr.flowtest.client": "Clientèle",
  "ccivr.nms.playservice.multivoice.language15": "Norvégien",
  "ccivr.nms.playservice.multivoice.language14": "Coréen",
  "ccivr.ivr.voicereview.portuguese": "Portugais",
  "ccivr.nms.playservice.multivoice.language13": "Japonais",
  "ccivr.nms.playservice.multivoice.language12": "Italien",
  "ccivr.nms.leavemessageservice.playbi": "Jouer le bip sonore",
  "ccivr.nms.playservice.multivoice.language11": "Islandais",
  "ccivr.nms.tansferservice.transferLastAgent.type.startTime.info": "L'appel est transféré à l'agent qui est contacté dès le début.",
  "ccivr.nms.playservice.multivoice.language10": "Hongrois",
  "ccivr.ivr.voiceupload.vedioformatwrong": "Le format de fichier est incorrect. Importer un fichier 3GP.",
  "ccivr.ivr.voiceupload.ivrVoiceUsage": "Informations de référence",
  "ccivr.ivr.voice.voicename": "timbre",
  "ccivr.ivr.voiceedit.inputtext": "Saisissez du texte.",
  "ccivr.nms.process.saveerror.queuing": "Entrez au moins l'une des positio de file d'attente\, le nombre d'agents en ligne et le temps d'attente prévu.",
  "ccivr.ivr.ivrflow.unreleased": "Inédit",
  "ccivr.nms.SubFlowService.processjump": "Aller à",
  "ccivr.nms.businessinvokeservice.opertaion": "Opération",
  "ccivr.nms.playcollectservice.voiceprompts": "Invite vocale",
  "ccivr.ivr.voiceupload.checkcallcenterparamfailed": "La vérification des paramètres du centre d'appels a échoué.",
  "ccivr.ivr.tracelog.errorInfo": "Message d'erreur",
  "ccivr.ivr.voiceedit.sftpinfoisnull": "Les informations SFTP du centre d'appel sont nulles.",
  "ccivr.nms.queryservice.queueInfo": "Les informations de file d'attente sont interrogées avant qu'un appel ne soit transféré à une file d'attente de compétences.",
  "ccivr.ivr.voiceupload.audioformat": "Format de fichier audio\: ALAW\, 8bit\, 8000Hz\, 64 kbps\, mono.",
  "ccivr.nms.interface.urltip": "Utilisez l'URL commençant par HTTP ou HTTPS.",
  "ccivr.ivr.voiceupload.selectLangNull": "Aucune langue n'est sélectionnée. Veuillez sélectionner une langue.",
  "ccivr.ivr.voiceupload.pleaserecording": "Téléchargez le fichier après l'enregistrement.",
  "ccivr.ivrvoice.play.error": "Chargement de l'exception de ressource\, veuillez vérifier la configuration associée.",
  "ccivr.ivr.ivrflow.operate.ensurecancelrelease.info": "Êtes-vous sûr de vouloir annuler la sortie ?",
  "ccivr.ivr.voiceupload.recording": "enregistrer",
  "ccivr.nms.playcollectservice.advancedconfig": "Paramètres avancés",
  "ccivr.ivrflow.operate.ensurereplace.info": "Êtes-vous sûr de vouloir remplacer le flux libéré?",
  "ccivr.ivr.voiceupload.playrecord": "Jouer",
  "ccivr.ivr.voiceupload.playtype": "Mode de lecture vocale",
  "ccivr.nms.playcollectservice.collectnumtimeout": "Délai de collecte des chiffres expiré.",
  "ccivr.ivr.voice.recordupload": "Enregistrement en ligne",
  "ccivr.ivr.voiceupload.deleteodfsivrfail": "La suppression a échoué. L'ivr est occupé",
  "ccivr.nms.assignservice.assignmentParameters": "Préparer les paramètres d'affectation",
  "ccivr.nms.conditionService.conditionService": "Jugement conditionnel",
  "ccivr.ivr.voiceupload.deletereferenceivrfail": "La suppression de la voix a échoué. La voix a été référencée.",
  "ccivr.ivr.voiceedit.idisnull": "L'ID est vide.",
  "ccivr.ivr.tracelog.maxNumberValidate": "Le nombre de numéros d'appel dépasse la limite supérieure\!",
  "ccivr.ivrflow.grayrule.rule": "Règle grise",
  "ccivr.nms.playset.status": "Paramètres TTS avancés",
  "ccivr.nms.timeselectservice.tuesday": "Mardi",
  "ccivr.ivr.tracelog.result.transfailed": "Échec",
  "ccivr.ivr.tracelog.result": "Résultats du flux",
  "ccivr.ivr.bussinessinvoke.updateinterface": "Modifier l'interface",
  "ccivr.ivr.voiceedit.statusisnull": "le paramètre d'état est null.",
  "ccivr.ivr.tracelog.createTime": "Saisie du temps de flux",
  "ccivr.nms.leavemessageservice.max.recordtime": "Max. Durée d'enregistrement",
  "ccivr.ivr.voicereview.text": "Texte",
  "ccivr.nms.queryservice.type": "Sélectionner le type",
  "ccivr.nms.playservice.multivoice.language": "Langue",
  "ccivr.nms.menuservice.moveup": "Vers le haut",
  "ccivr.ivr.ivrflow.deleteconfim": "Êtes-vous sûr de vouloir supprimer le flux IVR ?",
  "ccivr.nms.tansferservice.callForwarding": "Configuration du renvoi d'appels",
  "ccivr.nms.assignservice.setTransferData": "Définir les TransferData",
  "ccivr.ivr.voiceedit.pathisnull": "L'interrogation des informations de chemin SFTP du centre d'appels a échoué.",
  "ccivr.flowtest.hangup": "Raccrocher",
  "ccivr.nms.menuservice.tip": "Délai d'expiration ou clé incorrecte",
  "ccivr.ivr.ivrflow.batchdeleteconfim": "Êtes-vous sûr de vouloir supprimer tous les flux IVR sélectionnés ? Assurez-vous que le flux de RVI n'est pas libéré.",
  "ccivr.ivrflow.cancelrelease.referenced.by.idauth.survey": "Le processus a été référencé par une configuration d'authentification et ne peut pas être non publié!",
  "ccivr.ivrflow.updateivrinfo.editing": "Le flux est en cours d'édition et ne peut pas être modifié.",
  "ccivr.nms.process.saveerror.alreadyreleased": "L'état d'écoulement est anormal\, s'il vous plaît rouvrir la toile.",
  "ccivr.nms.leavemessageservice.localtionID": "localtionID",
  "ccivr.ivr.voiceupload.tipvideoformat": "Actuellement\, seuls les fichiers vidéo 3GP sont pris en charge. La taille du fichier ne peut pas dépasser 5 Mo.",
  "ccivr.ivr.voiceupload.audiosampleratefailed": "Le taux d'échantillonnage du fichier vocal n'est pas de 8000 Hz.",
  "ccivr.ivr.voicereview.reviewsuggestion": "commentaires",
  "ccivr.ivr.ivrflow.operate.cancelrelease": "Annuler la libération",
  "ccivr.nms.process.paramname": "Nom",
  "ccivr.nms.playservice.playfile": "Fichier vocal",
  "ccivr.ivr.voiceupload.fileheaderwrong": "Le format de fichier vocal d'origine n'est pas WAV ou MP3. Téléchargez un autre fichier vocal.",
  "ccivr.nms.queryservice.audio": "audio",
  "ccivr.ivr.voicereview.edit": "Modifier",
  "ccivr.ivr.tracelog.callInfo": "Renseignements sur l'appel",
  "ccivr.nms.playservice.multivoice.type": "Type",
  "ccivr.ivr.success": "Succès",
  "ccivr.nms.tansferservice.chooseZNIvr": "Sélectionner Intelligent IVR",
  "ccivr.ivrflow.release.grayrule": "Libération grise",
  "ccivr.nms.process.saveerror.dataerror": "Impossible de vérifier les données des éléments du diagramme.",
  "ccivr.nms.timeselectservice.min": "Minute",
  "ccivr.nms.timeselectservice.timeSelectType":'Mode de configuration',
  "ccivr.nms.timeselectservice.customConfig":'Personnaliser',
  "ccivr.nms.timeselectservice.workDayType":'Modèle',
  "ccivr.nms.timeselectservice.workDay":'Calendrier de travail',
  "ccivr.nms.delete": "supp.",
  "ccivr.ivr.variable.errorspecial": "Le type n'existe pas.",
  "ccivr.ivr.transferRecord.noMoreThan90Days": "La portée ne peut excéder 90 jours.",
  "ccivr.ivr.tracelog.deleteFailed": "Impossible de supprimer \!",
  "ccivr.ivr.voiceupload.tmpformaterror": "Mauvais type de fichier temporaire.",
  "ccivr.nms.queryservice.getDataAssign": "Résultat de la requête",
  "ccivr.ivr.voiceupload.checkuploadparmfailed": "La vérification du téléchargement du fichier parm a échoué.",
  "ccivr.ivrflow.grayrule.inputrulename": "Saisissez le nom de la règle.",
  "ccivr.ivr.tracelog.addFailed": "L&apos;ajout a échoué\!",
  "ccivr.ivr.variable.addvariable": "Ajouter une variable",
  "ccivr.nms.tansferservice.onlineagent": "Nombre d'agents en ligne",
  "ccivr.ivrflow.updateivrinfo.fail": "Impossible de modifier les attributs de flux IVR.",
  "ccivr.ivr.voiceedit.addsuccess": "Ajout réussi.",
  "ccivr.ivr.variable.defaultlimit": "La valeur par défaut ne peut pas contenir plus de 120 caractères.",
  "ccivr.whitelist.deletetip": "Veuillez cocher les éléments que vous souhaitez supprimer",
  "ccivr.ivr.ivrflow.operate.clone.fail": "Impossible de mettre à niveau le flux.",
  "ccivr.nms.conditionService.maxroute": "Le nombre maximum de branches conditionnelles est de 20.",
  "ccivr.nms.businessinvokeservice.servicename": "Nom de l'interface",
  "ccivr.nms.process.saveerror.menukeynoconnection": "L'enregistrement a échoué. Vérifiez si chaque touche de la configuration du menu a une ligne.",
  "ccivr.ivr.transferRecord.init": "Initialisation",
  "ccivr.ivr.voiceedit.deletesuccess": "Supprimé avec succès.",
  "ccivr.ivr.voicereview.operat": "Opération",
  "ccivr.nms.playservice.chosetts": "Le contenu à lire est sélectionné à partir du texte TTS qui a été téléchargé et examiné. Des ressources TTS sont nécessaires.",
  "ccivr.nms.shortmessageservice.setParameter": "Définir la variable",
  "ccivr.ivr.voice.scene.note": "ton",
  "ccivr.ivr.bussinessinvoke.validate": "Échec de la vérification",
  "ccivr.flowtest.begin.tip": "Entrez le numéro d'appel pour lancer le test.",
  "ccivr.ivr.voiceupload.fileovermax": "Le nombre de voix IVR à gérer dépasse le maximum.",
  "ccivr.nms.process.description": "Descriptif",
  "ccivr.ivr.voiceedit.filenamecheckfail": "La validation du nom du fichier a échoué.",
  "ccivr.nms.leavemessageservice.choose.recordfilename": "Enregistrer le nom du fichier",
  "ccivr.nms.queryservice.skillInfo": "Informations sur la file d'attente",
  "ccivr.ivr.ivrflow.ivrname": "Nom",
  "ccivr.ivr.voicereview.search": "Rechercher",
  "ccivr.nms.playcollectservice.collectnumerror": "Échec de la collecte des chiffres.",
  "ccivr.nms.businessinvokeservice.title": "Invoquer l'interface",
  "ccivr.nms.businessinvokeservice.inputflowValidate": "Saisie incorrecte\, veuillez modifier",
  "ccivr.ivr.error.sftp": "Les informations SFTP sont incorrectes.",
  "ccivr.nms.playcollectservice.times": "Horaires",
  "ccivr.nms.assignservice.othersDataAssign": "Attribuer des OthersData",
  "ccivr.nms.SubFlowService.jumpnode": "Nœud de saut",
  "ccivr.nms.playcollectservice.maxfailtimes": "Max. Temps d'échec",
  "ccivr.nms.tansferservice.selectPath": "Sélectionner un chemin",
  "ccivr.flowtest.operatefail": "Échec",
  "ccivr.ivr.bussinessinvoke.string": "Chaîne de caractères",
  "ccivr.nms.varibalesselect.sysvariables": "Variable du système",
  "ccivr.ivrflow.grayrule.name": "Nom de la règle",
  "ccivr.nms.process.saveerror.noselectType": "Sélectionnez un type de requête.",
  "ccivr.whitelist.urlvalidatefailed": "Veuillez saisir l'adresse IP ou le nom de domaine",
  "ccivr.ivrflow.grayrule.bindingflow": "Écoulement lié\:",
  "ccivr.ivr.ivrflow.operate.ensurerelease": "Confirmer la libération",
  "ccivr.ivrflow.createivrflow.exceptionfail": "Le flux de gestion des exceptions existe déjà. L'ajout a échoué!",
  "ccivr.ivr.tracelog.result.default": "default",
  "ccivr.ivr.success.import": "Ajout réussi.",
  "ccivr.ivrflow.grayrule.inputendnum": "Le numéro de fin doit être supérieur ou égal au numéro de début.",
  "ccivr.nms.process.saveerror.queues": "Entrez au moins l'un des éléments suivants \: Position de la file d'attente\, Nombre d'agents en ligne\, Nombre d'agents en veille et Temps d'attente prévu.",
  "ccivr.ivr.voicereview.uploadtime": "Temps de téléchargement",
  "ccivr.nms.playservice.placeholder": "Lecture vocale",
  "ccivr.ivr.bussinessinvoke.int": "Entier",
  "ccivr.ivr.voicereview.language": "Langue",
  "ccivr.nms.questionnaireService.info": "Les éléments du diagramme du questionnaire sont utilisés dans les tâches d'appel sortant automatique. Le processus du questionnaire RVI doit être associé.",
  "ccivr.nms.tansferservice.add": "Nouveau",
  "ccivr.nms.tansferservice.parameter": "Valeur",
  "ccivr.ivrflow.grayrule.numrange": "Segment de nombre",
  "ccivr.nms.playcollectservice.Failedreceivenum": "Échec de la collecte des chiffres",
  "ccivr.ivr.voiceedit.filenameformaterror": "Le format du nom de fichier est incorrect.",
  "ccivr.ivr.variable.typeisnull": "Le type est vide.",
  "ccivr.nms.playcollectservice.changtovideoO": "Vidéo",
  "ccivr.ivr.voiceupload.referenceFlowByCCIVR": "Référence du flux IVR",
  "ccivr.nms.playset.speed": "Vitesse de parole",
  "ccivr.nms.timeselectservice.hour": "Heure",
  "ccivr.ivr.voiceupload.vedioheaderwrong": "Le format de fichier vidéo d'origine n'est pas 3gp. Téléchargez un autre fichier vidéo.",
  "ccivr.nms.satisfactionservice.menubuttonconfig": "Configuration du bouton de satisfaction",
  "ccivr.nms.satisfactionservice.singleproblem.menubuttonconfig": "Configuration du bouton d'enquête d'un seul problème",
  "ccivr.ivrflow.grayrule.info": "Détails",
  "ccivr.nms.tansferservice.record": "enregistrer",
  "ccivr.nms.conditionService.simpleExp": "Expression simple",
  "ccivr.ivrflow.cancelrelease.error": "Le flux est référencé par une file d'attente de compétences!",
  "ccivr.nms.tansferservice.transferLastAgent.sampleForCallingnum": "Exemple\: 660001 ou {'$'}{'{'}SYS.callingNumber{'}'}",
  "ccivr.ivrflow.error.deleteivrflow.fail": "Le flux n'existe pas.",
  "ccivr.nms.process.saveerror": "L'enregistrement a échoué. Vérifiez le nœud de flux et la ligne de connexion.",
  "ccivr.nms.tansferservice.transferLastAgent.type.startTime": "Heure de début",
  "ccivr.whitelist.whiteip": "IP de liste de confiance",
  "ccivr.nms.tansferservice.transferLastAgent.type.none.info": "L'appel est transféré à l'agent qui a été contacté récemment.",
  "ccivr.nms.SubFlowService.Rotorflow": "Transfert vers le sous-flux",
  "ccivr.nms.conditionService.relationOperator": "Opérateur",
  "ccivr.nms.playcollectservice.changtovoice": "TTS",
  "ccivr.nms.tansferservice.transSkill": "L'appel est transféré à la file d'attente de compétences correspondante. Prise en charge des paramètres personnalisès. Exemple \: {\'$\'}{\'{\'}FLOW.Paramètre nom{\'}\'}",
  "ccivr.interface.typeerror": "Actuellement\, seuls les types GET et POST sont pris en charge.",
  "ccivr.nms.conditionService.complexExp": "Expression complexe",
  "ccivr.ivr.tracelog.errorpop": "La requête a échoué \!",
  "ccivr.nms.timeselectservice.tips3": "Les plages de temps de service doivent être dans l'ordre croissant. Chaque plage de temps ne peut pas se chevaucher l'une avec l'autre.",
  "ccivr.nms.timeselectservice.tips2": "L'heure de fin de service doit être postérieure à l'heure de début.",
  "ccivr.nms.tansferservice.transferZNIvr": "Les appels des clients seront transférés à l'IVR intelligent correspondant",
  "ccivr.nms.timeselectservice.tips1": "La date qui correspond au jour et à la date de la semaine sélectionnés est un jour ouvrable.",
  "ccivr.ivr.voicereview.name": "Nom",
  "ccivr.nms.tansferservice.transferThirdParty.recordingPrompt": "Invite d'enregistrement",
  "ccivr.nms.businessinvokeservice.outputparam": "Paramètre de sortie",
  "ccivr.ivr.languageid.repeat.fail": "languageId n'existe pas.",
  "ccivr.ivr.tracelog.flowName": "FlowName",
  "ccivr.ivr.voiceupload.opreationdbfailed": "Échec du message vocal IVR de la base de données de mise à jour.",
  "ccivr.ivr.voiceupload.pleaseuploadfile": "Le fichier est vide. Veuillez importer le fichier.",
  "ccivr.ivr.voicereview.voice": "Voix",
  "ccivr.flowtest.callingnumber": "Numéro de l'appelant",
  "ccivr.ivr.ivrflow.alreadyreleased": "Communiqué officiel",
  "ccivr.nms.queryservice.queueSpecialInfo": "Demander si le numéro d'abonné appelant est dans la liste spéciale.",
  "ccivr.ivr.voicereview.nosubmit": "Non soumis",
  "ccivr.nms.menuservice.menubuttonconfig": "Configuration des touches de menu",
  "ccivr.ivr.ivrflow.operate.release.success": "Sortie réussie.",
  "ccivr.whitelist.port": "Port",
  "ccivr.nms.tansferservice.transferLastAgent.type.time.sample": "Exemple \: 100\, indiquant que l'appel est transféré à l'agent qui est contacté dans les 100 minutes.",
  "ccivr.ivr.voice.pitch": "hauteur",
  "ccivr.nms.shortmessageservice.hwCloudMessage": "Huawei Cloud",
  "ccivr.ivr.connectsftp.uploadfile.exception": "Impossible de se connecter au SFTP. Le fichier ne parvient pas à être téléchargé.",
  "ccivr.nms.timeselectservice.servicetimeperiod": "Plage de temps de service",
  "ccivr.nms.assignservice.transferDataAssign": "Obtenir TransferData",
  "ccivr.nms.userLevelService.placeholder": "Définir le niveau de l'utilisateur",
  "ccivr.nms.tansferservice.setTransfer": "Configuration de transfert",
  "ccivr.ivr.ivrflow.no": "Non",
  "ccivr.flowtest.testoperate": "Opérations \:",
  "ccivr.ivr.transferRecord.flowAccessCode": "Code d'accès au processus",
  "ccivr.ivr.voicereview.choosevoicetype": "Type",
  "ccivr.nms.process.saveerror.flownoexist": "Le flux n'existe pas.",
  "ccivr.ivr.voicereview.voiceId": "Id de voix",
  "ccivr.whitelist.descinfo": "Descriptif",
  "ccivr.ivr.voiceupload.auditionTextlimit": "Le contenu de l'aperçu du texte peut contenir un maximum de 300 octets.",
  "ccivr.nms.brach.duplicatebranch": "La branche existe déjà. Sélectionnez un autre fichier.",
  "ccivr.ivr.voiceupload.authvalidatefailed": "Échec de la vérification des autorisations de téléchargement du fichier.",
  "ccivr.nms.tansferservice.empty": "Le paramètre est vide.",
  "ccivr.nms.tansferservice.processName": "Saisissez un nom de flux.",
  "ccivr.ivr.voicereview.reviewstatus": "Statut",
  "ccivr.ivr.transferRecord.noright": "L'utilisateur actuel n'a pas la permission de télécharger les enregistrements.",
  "ccivr.nms.queryservice.Dataassignment": "Requête d'informations",
  "ccivr.nms.tansferservice.transferThirdParty": "Transfert à un tiers",
  "ccivr.nms.tansferservice.sample": "Exemple\: 28956666 ou {'$'}{'{'}FLOW.currentUserNum{'}'}",
  "ccivr.nms.modulename": "Nom de l'élément",
  "ccivr.nms.conditionService.logicOR": "Ou",
  "ccivr.nms.tansferservice.transferLastAgent.transCallingnum": "Numéro d'appel",
  "ccivr.nms.menuservice.samenamevalidate": "Clés répétées \:",
  "ccivr.ivr.voicereview.reviewmethod": "Mode de révision des ressources vocales",
  "ccivr.ivr.ivrflow.updateTime": "Temps de mise à jour",
  "ccivr.ivr.voiceupload.tipvideomodel": "Vous pouvez définir des fichiers vocaux ou vidéo de différentes langues en même temps. Pendant la lecture vidéo\, le système lit automatiquement le fichier vidéo correspondant sur la base de la langue sélectionnée par l'utilisateur.",
  "ccivr.ivr.voicereview.pass": "Approuver",
  "ccivr.ivr.voiceedit.import": "Importer",
  "ccivr.ivr.ivrflow.exceptionHandingFlow": "Débit de gestion d'exception",
  "ccivr.nms.shortmessageservice.satisfactionMessage": "Sondage de satisfaction",
  "ccivr.ivr.voiceadvice.close": "Fermer",
  "ccivr.nms.process.parameterisEncrypt": "Chiffrement",
  "ccivr.ivr.ivrflow.currentHandler": "Gestionnaire",
  "ccivr.nms.satisfactionservice.buttonNumber": "Le nombre de clés de l'enquête de satisfaction n'est pas supérieur à 9 et pas inférieur à 3.",
  "ccivr.ivr.bussinessinvoke.URL": "URL de la demande",
  "ccivr.ivr.voiceupload.selectvoice": "Sélectionnez un fichier vocal.",
  "ccivr.nms.tansferservice.transferLastAgent.type.startTime.sample": "Sélectionnez l'heure de début.",
  "ccivr.ivr.voiceupload.VoiceNotificationTextrule": "Pour plus de règles de modèle de notification vocale\, déplacez le curseur sur le symbole i dans la zone de texte.",
  "ccivr.nms.tansferservice.processLayout": "Orchestration de processus",
  "ccivr.ivr.voice.recordtimeout.fail": "Délai d'enregistrement expiré.",
  "ccivr.nms.shortmessageservice.messageTemplateContent": "Modèle",
  "ccivr.ivr.bussinessinvoke.errormessage": "Sélectionnez l'interface à supprimer.",
  "ccivr.nms.tansferservice.transferLastAgent.type.time": "Plage de temps",
  "ccivr.ivr.ivrflow.mainflow": "Flux principal",
  "ccivr.nms.process.samenamevalidate": "Nom du paramètre dupliqué \:",
  "ccivr.nms.interface.nmsexception": "la communication avec ODFS rencontrez l'exception\! Veuillez vérifier la configuration.",
  "ccivr.nms.interface.whitelistcheckfailed": "L'URL (adresse IP et numéro de port) n'est pas dans la liste de confiance. L'interface ne peut être invoquée qu'après avoir contacté l'administrateur système pour ajouter l'URL à la liste de confiance. Continuer & #160;?",
  "ccivr.nms.interface.whitelistwarn": "L'URL (adresse IP et numéro de port) n'est pas dans la liste de confiance. L'interface ne peut être invoquée qu'après avoir contacté l'administrateur système pour ajouter l'URL à la liste de confiance.",
  "ccivr.ivr.tracelog.result.timeout": "Délai d'expiration",
  "ccivr.ivrflow.grayrule.rulename": "Nom de la règle",
  "ccivr.ivr.voice.scene.error": "Erreur",
  "ccivr.nms.shortmessageservice.fiveGMessage": "Message 5G",
  "ccivr.nms.conditionService.Parameter_2": "Paramètre 2",
  "ccivr.ivr.voicereview.vedio": "Vidéo",
  "ccivr.ivr.voiceedit.filenameisnull": "Le nom de fichier est null.",
  "ccivr.nms.conditionService.Parameter_1": "Paramètre 1",
  "ccivr.nms.userLevelService.example": "Exemple \: 1-14 ou FLOW.variableName",
  "ccivr.ivr.ivrflow.subflow": "Sous-flux",
  "ccivr.nms.shortmessageservice.addressee": "Numéro de téléphone portable",
  'ccivr.nms.shortmessageservice.phoneNumber': 'Numéro de téléphone portable',
  "ccivr.nms.playservice.inputtitle": "Invite vocale",
  "ccivr.ivrflow.createivrflow.sameivrflowname": "Le nom du flux existe déjà!",
  "ccivr.ivr.voice.scene.questionnaires": "Questionnaire",
  "ccivr.ivr.tracelog.sameNumberValidate": "Le même numéro d'appel existe déjà\!",
  "ccivr.nms.playcollectservice.close": "Fermer",
  "ccivr.nms.process.saveerror.noexistconnection": "L'enregistrement a échoué. Une ligne par défaut doit être disponible pour les éléments du diagramme à l'exception de la fin et du transfert aux éléments du diagramme de sous-flux.",
  "ccivr.nms.queryservice.queryType": "Type de requête",
  "ccivr.nms.playset.femalevoice": "Voix féminine",
  "ccivr.flowtest.test": "Tester",
  "ccivr.nms.playservice.multivoice.type.telnum": "Numéro de téléphone",
  "ccivr.nms.tansferservice.transNum": "Numéro de transfert",
  "ccivr.ivr.voicereview.filename": "Nom du fichier",
  "ccivr.ivr.voicereview.scene": "Scénario d'utilisation",
  "ccivr.nms.tansferservice.transAppointWorkNo": "Un appel entrant est transféré à un agent spécifié.",
  "ccivr.ivr.delete.fail": "La suppression a échoué.",
  "ccivr.ivr.variable.descvalueerror": "La description contient des caractères spéciaux. Veuillez le modifier.",
  "ccivr.nms.satisfactionservice.type": "Types d'enquêtes de satisfaction",
  "ccivr.nms.satisfactionservice.title": "Configuration de la satisfaction",
  "ccivr.nms.singleproblem.title": "Enquête sur un seul problème",
  "ccivr.ivr.tracelog.title": "TraceLog",
  "ccivr.nms.playcollectservice.collectTimeout.error": "Intervalle de temps de collecte des chiffes non valide (1–120).",
  "ccivr.nms.timeselectservice.selectdate": "Date",
  "ccivr.nms.menuservice.contentvalidate": "Une fois l'édition terminée\, ajoutez la configuration de la clé.",
  "ccivr.nms.process.initialvalue": "Valeur initiale",
  "ccivr.whitelist.imordomainname": "IP ou nom de domaine",
  "ccivr.ivr.tracelog.addAccessCode": "Ajouter un code d'accès",
  "ccivr.nms.playcollectservice.ttsvoice": "TTS",
  "ccivr.nms.playcollectservice.entercollectwaittime": "Entrez le temps d'attente pour la collecte des chiffres.",
  "ccivr.nms.ChangeMediaService.label": "média",
  "ccivr.nms.leavemessageservice.agent": "Spécifier l'agent pour le traitement",
  "ccivr.ivr.variable.willdeleteselectvariable": "Êtes-vous sûr de vouloir supprimer les variables sélectionnées ?",
  "ccivr.ivr.description.variablelimit": "les variables de gabarit ne peuvent pas dépasser 10.",
  "ccivr.ivr.tracelog.transitionOn": "Exporter la branche",
  "ccivr.ivr.name.notnull": "Le nom ne peut pas être laissé vide.",
  "ccivr.nms.callendservice.checkbox": "L'interface doit être invoquée à la fin d'un appel.",
  "ccivr.ivr.voiceupload.formatwrong": "Format de fichier incorrect. Importer un fichier au format WAV ou MP3.",
  "ccivr.nms.process.basicinformation": "Informations de base",
  "ccivr.nms.playcollectservice.choosefile": "Voix",
  "ccivr.ivr.voice.filechoose": "Sélectionner un fichier vocal",
  "ccivr.ivr.voiceedit.editsuccess": "Modifié avec succès.",
  "ccivr.ivrflow.specify.accessCode": "Spécifiez le code d'accès au canal",
  "ccivr.ivr.voicereview.refresh": "Actualiser",
  "ccivr.ivr.voiceedit.addfail": "L'ajout a échoué.",
  "ccivr.nms.tansferservice.estimateTime": "estimationTemps",
  "ccivr.ivr.voicereview.spanish": "Espagnol",
  "ccivr.ivr.tracelogprompt": "Cette opération affectera les performances du système. Cette configuration sera automatiquement supprimée après {0} heure. Voulez-vous continuer ?",
  "ccivr.nms.tansferservice.transNumType": "Le numéro de transfert peut être un numéro de téléphone mobile\, un numéro de téléphone fixe ou un paramètre personnalisé. Exemple \: {'$'}{'{'}FLOW.Paramètre nom{'}'}",
  "ccivr.nms.queryservice.paramName": "Paramètre de demande",
  "ccivr.ivr.tracelog.callingNumberplaceHolder": "S'il vous plaît entrer le numéro d'appel",
  "ccivr.ivr.tracelog.nodeName": "Nom de l'élément",
  "ccivr.whitelist.desc": "Descriptif",
  "ccivr.ivr.voice.fileupload": "Télécharger le fichier",
  "ccivr.ivr.tracelog.addcallingnumber": "Ajouter un CallingNumber",
  "ccivr.nms.playcollectservice.mulen": "Nombre max. de chiffres reçus",
  "ccivr.ivr.voicereview.english": "Anglais",
  "ccivr.nms.playset.lowspeed": "Faible",
  "ccivr.ivr.bussinessinvoke.cancel": "Annuler",
  "ccivr.nms.process.parameterconfiguration": "Configuration des paramètres",
  "ccivr.nms.playcollectservice.onenumbers": "Collecte à plusieurs chiffres",
  "ccivr.ivr.tracelog.selecttip": "Veuillez sélectionner le numéro d'appel\!",
  "ccivr.ivr.voicereview.language1": "Anglais",
  "ccivr.nms.playservice.multivoice.type.time": "time(hh\:mm\:ss)",
  "ccivr.ivr.voicereview.language0": "Chinois",
  "ccivr.flowtest.stoptest": "Arrêter",
  "ccivr.ivr.ivrflow.operate.cancelrelease.fail": "Impossible d'annuler la libération.",
  "ccivr.ivr.bussinessinvoke.tip": "Veuillez saisir une valeur ou une variable",
  "ccivr.ivr.bussinessinvoke.createinterface": "Créer une interface",
  "ccivr.ivr.voiceadvice.audition": "Audition",
  "ccivr.nms.playset.sound": "Effet sonore",
  "ccivr.ivrflow.unlock": "Déverrouiller",
  "ccivr.nms.tansferservice.mustbeaccesscode": "Le numéro appelant doit être un code d'accès attribué par le système ou un paramètre personnalisé. Exemple \: {'$'}{'{'}FLOW.Paramètre nom{'}'}",
  "ccivr.nms.tansferservice.transferLastAgent.type.currentday": "Le jour actuel",
  "ccivr.flowtest.flowtest": "Test de débit",
  "ccivr.ivr.voiceupload.stoprecording": "Arrêter",
  "ccivr.ivrflow.release.formal": "Communiqué officiel",
  "ccivr.nms.assignservice.ietype": "Type IE",
  "ccivr.ivr.voiceedit.updatefail": "La mise à jour a échoué.",
  "ccivr.ivr.tracelog.deleteSuccess": "Supprimé avec succès\!",
  "ccivr.nms.leavemessageservice.placeholder": "Laisser un message",
  "ccivr.flowtest.success": "Succès",
  "ccivr.nms.shortmessageservice.period": "Période de réponse valide (minutes)",
  "ccivr.ivr.ivrflow.operate.ensurerelease.info": "Êtes-vous sûr de vouloir libérer le flux ?",
  "ccivr.ivr.ivrflow.type": "Type",
  "ccivr.ivr.voiceedit.tip": "Inviter",
  "ccivr.ivr.voiceupload.referenceFlowByNMS": "Référence du modèle de ressource",
  "ccivr.ivr.bussinessinvoke.interfacedesc": "Description de l'interface",
  "ccivr.ivr.ivrflow.operate.release.cancelexecptionhanding": "Veuillez annuler le statut de libération d'autres processus sous le locataire.",
  "ccivr.nms.LanguageService.placeholder": "Sélectionner la langue",
  "ccivr.ivr.description.voiceNotificationlimit": "La description textuelle ne peut pas contenir plus de 500 octets.",
  "ccivr.nms.menuservice.voicenotice": "Invite vocale",
  "ccivr.ivr.voicereview.filePath": "Chemin du fichier:",
  "ccivr.nms.transferservice.choose.recordfilename.info": "Lorsque l'interface est appelée pour télécharger des fichiers d'enregistrement\, la variable est utilisée pour stocker temporairement la valeur.",
  "ccivr.ivr.bussinessinvoke.saveserror": "L'enregistrement a échoué.",
  "ccivr.nms.interface.warn": "Le protocole réseau utilisé par l'interface n'est pas sécurisé et peut entraîner des risques de sécurité",
  "ccivr.ivrflow.cancelrelease.referenced.by.satisfaction.survey": "Le flux a été référencé par la configuration de l'enquête de satisfaction et ne peut pas être supprimé!",
  "ccivr.ivr.bussinessinvoke.interfacetype": "Type d'interface",
  "ccivr.ivr.ivrflow.filtercondition": "Critères de filtrage",
  "ccivr.ivr.voiceedit.querycallcenterfailed": "L'interrogation des informations SFTP du centre d'appels a échoué.",
  "ccivr.ivrflow.error.deleteivrflow.association": "Le flux est associé à un autre flux et ne peut pas être supprimé.",
  "ccivr.nms.playcollectservice.collectTimeout": "Intervalle de temps pour la collecte des chiffres",
  "ccivr.ivr.voiceupload.VoiceNotificationTextDes": "Le contenu du modèle ne peut pas commencer par une variable. Une variable peut contenir un maximum de 32 octets. Si une variable contient plus de 32 octets\, il est conseillé de la diviser en plusieurs variables.",
  "ccivr.ivr.voiceedit.statuserror": "Les variables suivantes sont supportées \:",
  "ccivr.ivr.voicereview.notpass": "Rejeté",
  "ccivr.nms.playset.highspeed": "Élevé",
  "ccivr.nms.playservice.multivoice.content": "Contenu",
  "ccivr.ivr.voiceupload.referenceText": "${TIME}\: heure au format HH\:MM ou HH\:MM\:SS",
  "ccivr.ivr.bussinessinvoke.savesuccess": "Sauvegardé avec succès.",
  "ccivr.nms.varibalesselect.globalvariables": "Variable globale",
  "ccivr.ivr.transferRecord.endTime": "Heure de fin",
  "ccivr.ivrflow.grayrule.inputendlength": "La longueur du numéro de départ doit être la même que celle du numéro de fin.",
  "ccivr.ivr.transferRecord.connect.file.notexist": "Impossible de télécharger le fichier d'enregistrement. Veuillez vérifier si le fichier existe.",
  "ccivr.ivr.tracelog.calledNumber": "CalledNumber",
  "ccivr.nms.timeselectservice.friday": "Vendredi",
  "ccivr.ivr.voicereview.passed": "Approuvé",
  "ccivr.ivr.voiceupload.audiofilereadfailed": "Impossible de lire les attributs des fichiers vocaux.",
  "ccivr.ivr.voiceupload.fileinjectionerror": "Vérifiez l'exception de risque d'injection de fichier.",
  "ccivr.nms.process.customparameter": "Paramètres de débit",
  "ccivr.nms.assignservice.ieDataAssign": "Affecter IEData",
  "ccivr.ivr.voicereview.language24": "Thaïlande",
  "ccivr.ivr.voicereview.language20": "Espagnol",
  "ccivr.ivr.voiceupload.uploadfiletosftpfailed": "Le téléchargement du fichier vers sftp a échoué.",
  "ccivr.ivrflow.operate.ensurereplace": "Confirmer le remplacement",
  "ccivr.nms.shortmessageservice.mspMessage": "Passerelle SMS",
  "ccivr.ivr.variable.string": "Chaîne",
  "ccivr.ivr.voiceedit.filenamenull": "Le contenu du nom de fichier est null.",
  "ccivr.nms.playcollectservice.required": "Champ obligatoire",
  "ccivr.nms.menuname.specialStrValidateFailed": "La longueur ne peut pas dépasser 255 caractères",
  "ccivr.nms.tansferservice.transferThirdParty.recordingSettings": "Paramètres d'enregistrement",
  "ccivr.nms.playset.intermediatespeed": "moyen",
  "ccivr.ivr.tracelog.deleteconfirminfo": "S'il faut supprimer le numéro appelant actuel \:",
  "ccivr.ivr.fail": "Échec",
  "ccivr.ivr.voiceupload.delete": "supp.",
  "ccivr.ivr.ivrflow.filter": "Filtrer",
  "ccivr.ivr.voicereview.french": "Français",
  "ccivr.ivr.voicereview.arabic": "Arabe",
  "ccivr.ivr.voicereview.german": "Allemand",
  "ccivr.ivr.voicereview.language17": "Portugais",
  "ccivr.ivr.voicereview.language7": "Français",
  "ccivr.ivr.voicereview.language81": "Arabe",
  "ccivr.ivr.voicereview.language8": "Allemand",
  "ccivr.ivr.transferRecord.callNumber": "Numéro d'appel",
  "ccivr.nms.tansferservice.transferLastAgent.type": "Mode dernier agent",
  "ccivr.nms.playcollectservice.failtitle": "Lecture vocale pour échec de collecte de chiffres.",
  "ccivr.ivr.bussinessinvoke.basicconfig": "Configuration de base",
  "ccivr.nms.ringService.sendrecv": "sendrecv",
  "ccivr.nms.timeselectservice.wednesday": "Mercredi",
  "ccivr.ivr.tracelog.result.recordend": "Succès de l'enregistrement",
  "ccivr.ivrflow.variable.settings": "Paramètres des variables",
  "ccivr.ivr.tracelog.deleteconfirminfocode": "S'il faut supprimer le code d'accès actuel \:",
  "ccivr.nms.tansferservice.transferLastAgent": "Invités familiers audio et vidéo",
  "ccivr.flowtest.flowname": "Nom",
  "ccivr.ivr.ivrflow.operate.gateway.pulishrepeat": "Le nom du processus est en double ou une version a été publiée.",
  "ccivr.ivr.ivrflow.version": "Numéro de version",
  "ccivr.ivr.voiceupload.english": "Anglais",
  "ccivr.nms.add": "Nouveau",
  "ccivr.ivr.transferRecord.fail": "Échec",
  "ccivr.nms.playservice.chosemultivoice": "Définissez le contenu à lire à partir des sélections suivantes",
  "ccivr.ivr.tracelog.addSuccess": "Ajouté avec succès\!",
  "ccivr.flowtest.presskey": "Boutons",
  "ccivr.ivr.tracelog.selecttipcode": "Veuillez sélectionner le code d'accès\!",
  "ccivr.ivrflow.ivrflowname.specialStrValidateFailed128": "La valeur ne peut pas contenir de caractères spéciaux et la longueur ne peut pas dépasser 128 caractères.",
  "ccivr.nms.queryservice.specifiedSkill": "Nom de la file d'attente",
  "ccivr.ivr.voice.exception.recordfailmessage": "Erreur d'enregistrement. Vérifiez si vous avez l'autorisation du microphone.",
  "ccivr.ivrflow.updateivrinfo.success": "Modifié les attributs du flux IVR avec succès.",
  "ccivr.ivr.voiceupload.VoiceNotificationtipmodel": "Vous pouvez définir des fichiers vocaux ou du texte de différentes langues en même temps (la longueur maximale ne peut pas dépasser 500 octets). Pendant la lecture vocale\, le système joue automatiquement le fichier vocal correspondant en fonction de la langue sélectionnée par l'utilisateur.",
  "ccivr.nms.businessinvokeservice.second": "Deuxième",
  "ccivr.ivr.tracelog.index": "Index",
  "ccivr.nms.tansferservice.operat": "Opération",
  "ccivr.ivr.voice.recordtimeout.failmes": "La durée maximale d'enregistrement est de deux minutes. Enregistrez à nouveau.",
  "ccivr.nms.tansferservice.transferZNIvrCode": "Transfert vers Intelligent IVR",
  "ccivr.nms.process.saveerror.noresult": "N'est pas Entité.",
  "ccivr.nms.queryservice.specialList.flag": "Drapeau d'être dans la liste spéciale",
  "ccivr.nms.leavemessageservice.saveleavemessage": "Enregistrer les informations du message.",
  "ccivr.nms.ringService.placeholder": "Sonnerie",
  "ccivr.nms.playservice.multivoice.language9": "Grecque",
  "ccivr.flowtest.platform": "Plateforme",
  "ccivr.ivrflow.grayrule.grayinfo": "Si un nombre correspond à une règle grise\, le flux de cette version est exécuté.<br /> Pour les nombres qui ne correspondent pas aux règles grises\, le flux officiellement publié est exécuté.<br /> S'il n'y a pas de flux officiellement libéré\, l'exécution échoue.",
  "ccivr.nms.playservice.multivoice.language8": "Allemand",
  "ccivr.nms.playservice.multivoice.language7": "Français",
  "ccivr.nms.playservice.multivoice.language6": "finnois",
  "ccivr.nms.playservice.multivoice.language5": "Néerlandais",
  "ccivr.nms.playservice.multivoice.language4": "Danois",
  "ccivr.nms.playservice.multivoice.language3": "Tchèque",
  "ccivr.nms.userLevelService.label": "Niveau utilisateur",
  "ccivr.nms.playservice.multivoice.language2": "Chinois (cantonais)",
  "ccivr.nms.playservice.multivoice.language1": "Anglais",
  "ccivr.nms.playservice.multivoice.language0": "Putonghua",
  "ccivr.nms.playcollectservice.interrupt": "Appuyez sur n'importe quelle touche pour interrompre la lecture.",
  "ccivr.ivr.description.limit": "La description textuelle ne peut pas contenir plus de 1024 octets.",
  "ccivr.ivr.voice.vediochoose": "Sélectionner un fichier vidéo",
  "ccivr.ivr.variable.name": "Variable",
  "ccivr.nms.tansferservice.delete": "supp.",
  "ccivr.ivr.changtovoice": "TTS",
  "ccivr.ivr.voiceupload.isconfdelete": "Êtes-vous sûr de vouloir le supprimer ?",
  "ccivr.ivr.ivrflow.clone": "Mise à niveau",
  "ccivr.nms.callendservice.title": "fin",
  "ccivr.ivr.variable.nameisnull": "Le nom est vide.",
  "ccivr.nms.playcollectservice.chooseparam": "Enregistrer l'entrée de l'utilisateur",
  "ccivr.nms.leavemessageservice.choose.agent.sample": "Exemple \: 101 ou {\"$\"}{\"{\"}FLOW.workNo{\"}\"}",
  "ccivr.nms.ringService.recvonly": "Recevoir uniquement",
  "ccivr.ivr.voiceupload.voiceUsageBySkill": "Référence de file d'attente de compétences",
  "ccivr.ivr.transferRecord.connect.uap.failed": "Impossible de se connecter au serveur de fichiers",
  "ccivr.ivr.voice.scene.voicenotification": "Notification vocale",
  "ccivr.nms.process.parametertype": "Type de paramètre",
  "ccivr.ivrflow.createivrflow.fail": "L'ajout a échoué.",
  "ccivr.nms.process.isParameterisEncrypt": "Chiffré ou non",
  "ccivr.nms.playcollectservice.entermaxfailtimes": "Entrez le nombre maximal d'échecs.",
  "ccivr.nms.businessinvokeservice.paramname": "Nom",
  "ccivr.ivr.tracelog.callingNumber": "CallingNumber",
  "ccivr.nms.playset.close": "Désactivé",
  "ccivr.nms.tansferservice.transferLastAgent.type.time.info": "L'appel est transféré à l'agent qui est contacté dans un intervalle de temps.",
  "ccivr.nms.queryservice.queryParam": "Paramètre de demande",
  "ccivr.nms.varibalesselect.processvaribales": "Variable de débit",
  "ccivr.nms.conditionService.moduleDescription": "Description du module",
  "ccivr.ivr.voicereview.reset": "Réinitialiser",
  "ccivr.ivr.voicereview.choosetenantspace": "Sélectionnez un locataire.",
  "ccivr.nms.queryservice.video": "Vidéo",
  "ccivr.nms.process.String": "Chaîne de caractères",
  "ccivr.nms.tansferservice.skillId": "ID de compétence",
  "ccivr.ivr.textcontent.notnull": "Les informations textuelles ne peuvent pas être vides.",
  "ccivr.nms.varibalesselect.selectvariables": "Sélectionner une variable",
  "ccivr.ivr.voicereview.textContent": "Contenu du texte",
  "ccivr.nms.queryservice.querySkillMessage": "Requête d'information",
  "ccivr.ivr.variable.idisnull": "L'ID est vide.",
  "ccivr.ivrflow.createivrflow.mainfail": "Le flux principal existe déjà. L'ajout a échoué.",
  "ccivr.ivrflow.variable.selectvariable": "Veuillez sélectionner un paramètre variable.",
  "ccivr.ivr.error.paramNumlimit": "Le nombre total du paramètre ne peut dépasser 16.",
  "ccivr.ivr.ivrtextexception.fail": "Impossible d'ajouter le texte de l'IVR.",
  "ccivr.ivr.voiceupload.fileioerror": "Exception d'E/S de fichier.",
  "ccivr.ivrflow.unlock.success": "Statut déverrouillé réussi",
  "ccivr.ivr.voiceedit.deleteodfsivrfail": "La suppression a échoué. Les données IVR sont occupées \!",
  "ccivr.nms.playset.extendparam": "Paramètre étendu TTS",
  "ccivr.nms.tansferservice.transferThirdParty.enableRecording": "Enregistrement",
  "ccivr.flowtest.operatehangup": "Raccrocher",
  "ccivr.ivr.ivrflow.new": "Nouveau",
  "ccivr.ivr.ivrflow.operate.ensurecancelrelease": "Confirmer l'annulation de la version",
  "ccivr.transferrecord.play.timeCompare": "L'heure de début ne peut pas être postérieure à l'heure de fin.",
  "ccivr.nms.answerService.placeholder": "Réponse",
  "ccivr.ivr.type.fail": "Le type de téléchargement est incorrect.",
  "ccivr.ivr.voiceupload.selectLangRe": "La langue actuelle a été sélectionnée. Veuillez sélectionner une autre langue.",
  "ccivr.nms.process.saveerror.unSucctransfer": "L'enregistrement a échoué. L'élément du diagramme de transfert a la ligne de succès de transfert.",
  "ccivr.nms.process.interfaceerr": "Erreur d'interface. Veuillez vérifier le journal.",
  "ccivr.ivr.voiceupload.query": "Détail",
  "ccivr.nms.process.processinformation": "Informations sur le flux",
  "ccivr.ivrflow.updateivrinfo.released": "Le flux a été libéré. Annulez la publication et modifiez les attributs.",
  "ccivr.nms.playcollectservice.second": "Deuxième",
  "ccivr.nms.timeselectservice.timeperiod": "Période de temps",
  "ccivr.ivrflow.variable.typeerror": "Types de variables d'entrée incohérents.",
  "ccivr.ivr.tracelog.accessCode": "Code d'accès de flux",
  "ccivr.nms.tansferservice.idleAgentCount": "Nombre d'agents inactifs",
  "ccivr.ivr.variable.defaulttypeerror": "Le type de valeur par défaut ne correspond pas au type de variable.",
  "ccivr.nms.conditionService.logicAND": "et",
  "ccivr.ivr.tracelog.result.transsuccess": "Succès",
  "ccivr.ivr.ivrflow.property": "Attribut",
  "ccivr.ivr.tracelog.actionParams": "Paramètres clés de Cell",
  "ccivr.ivr.ivrflow.ivrtype": "Type",
  "ccivr.nms.queryservice.info": "Les informations de mise en file d'attente sont interrogées à l'aide d'un flux IVR lorsqu'un appel est transféré à une file d'attente de compétences.",
  "ccivr.ivr.error.uncommitted": "L'administrateur système ne peut pas lire le fichier qui n'est pas soumis.",
  "ccivr.nms.timeselectservice.sunday": "Dimanche",
  "ccivr.nms.leavemessageservice.end": "Par défaut\, l'enregistrement se termine avec la touche pound (\#).",
  "ccivr.ivr.ivrflow.operate.cancelrelease.success": "Annulé avec succès.",
  "ccivr.ivr.voiceedit.deleteoldfilefailed": "La suppression de l'ancien fichier a échoué.",
  "ccivr.ivr.ivrflow.selectivrflow": "Sélectionnez un flux IVR.",
  "ccivr.ivr.voice.volume": "volume",
  "ccivr.ivr.voice.clicktorecord": "Cliquez sur Enregistrer",
  "ccivr.nms.tansferservice.transferWorkNo": "Transfert à l'agent",
  "ccivr.ivr.transferRecord.transNumber": "Numéro de transfert",
  "ccivr.nms.shortmessageservice.processInformation": "SMS",
  "ccivr.ivr.name.existence": "Le nom existe déjà. Veuillez en saisir un autre.",
  "ccivr.nms.leavemessageservice.choose": "Sélectionner",
  "ccivr.ivr.voiceadvice.cancel": "Annuler",
  "ccivr.nms.menuservice.title": "Configuration du menu",
  "ccivr.ivr.ivrflow.operate.release.needexecptionhanding": "Veuillez configurer et publier officiellement un processus de gestion des exceptions.",
  "ccivr.ivr.tracelog.result.playover": "Succès de jeu",
  "ccivr.nms.tansferservice.moduleDescription": "Description du module",
  "ccivr.nms.timeselectservice.thursday": "Jeudi",
  "ccivr.nms.conditionService.parameterEmpty": "Le paramètre 1 est vide.",
  "ccivr.nms.queryservice.paramValue": "Valeur",
  "ccivr.ivr.voice.clicktostop": "Cliquez sur Arrêter",
  "ccivr.ivr.ivrflow.state": "Statut",
  "ccivr.nms.tansferservice.transCallingnum": "Numéro de l'appelant",
  "ccivr.nms.playcollectservice.title": "Jouer la voix et collecter le chiffre",
  "ccivr.ivr.voiceupload.defaultNoDe": "La langue par défaut ne peut pas être supprimée.",
  "ccivr.ivr.create.exception": "L'ajout a échoué.",
  "ccivr.ivr.tracelog.cellType": "Type d'élément",
  "ccivr.ivr.error.other": "Le téléchargement a échoué.",
  "ccivr.ivr.voiceupload.tipformat": "Les formats MP3 et WAV sont pris en charge\, et la taille du fichier ne peut pas dépasser 12 Mo.",
  "ccivr.ivr.variable.int": "Entier",
  "ccivr.ivr.voiceupload.new": "Nouveau",
  "ccivr.ivrflow.grayrule.editsuccess": "Modifié avec succès.",
  "ccivr.nms.businessinvokeservice.inpuparam": "Paramètre d'entrée",
  "ccivr.ivr.transferRecord.recordedFile": "Fichier d'enregistrement",
  "ccivr.ivr.bussinessinvoke.bussinessinvoke": "Configuration de l'interface",
  "ccivr.ivr.voiceedit.pathcheckfailed": "La validation des informations de chemin SFTP a échoué.",
  "ccivr.nms.brach.label": "Sélectionner la condition",
  "ccivr.ivr.ivrflow.placeholder.ivrname": "Saisissez un nom de flux.",
  "ccivr.ivrflow.operate.replace.fail": "Le remplacement a échoué.",
  "ccivr.flowtest.enter.phone": "Entrez le numéro de téléphone.",
  "ccivr.ivr.voicereview.filter": "Filtrer",
  "ccivr.flowtest.test.enter.callingnumber": "S'il vous plaît entrer le numéro d'appel en premier!",
  "ccivr.nms.tansferservice.sampleForCallingnum": "Exemple\: 660001 ou {'$'}{'{'}FLOW.accessCode{'}'}",
  "ccivr.ivr.voiceupload.tipmodel": "Vous pouvez définir des fichiers vocaux ou du texte de différentes langues en même temps (la longueur maximale ne peut pas dépasser 1024 octets). Pendant la lecture vocale\, le système joue automatiquement le fichier vocal correspondant basé sur la langue sélectionnée par l'utilisateur.",
  "ccivr.ivrflow.ivrflowtype.validateFailed": "Type de flux incorrect.",
  "ccivr.nms.satisfactionservice.tip": "Veuillez configurer le niveau de satisfaction dans le menu Configuration de la satisfaction. Si le flux a été publié, vous pouvez mettre à jour, modifier, enregistrer et libérer le flux à nouveau pour remplacer le flux original et utiliser le niveau de satisfaction mis à jour.",
  "ccivr.common.warning.message": "Sélectionnez un enregistrement.",
  "ccivr.ivr.voiceedit.passwdisnull": "L'interrogation des informations de passewd SFTP du centre d'appels a échoué.",
  "ccivr.nms.conditionService.conditionalEdit": "Édition de condition",
  "ccivr.ivr.ivrflow.operate.ensureclone": "Confirmer la mise à niveau du flux",
  "ccivr.nms.LanguageService.label": "Langue",
  "ccivr.ivrflow.grayrule.existgrayflow": "La version grise existe déjà!",
  "ccivr.nms.tansferservice.transferLastAgent.mustbeaccesscode": "Le numéro d'appel est le numéro entrant du client\, par exemple\, {'$'}{'{'}SYS.callingNumber{'}'}.",
  "ccivr.nms.satisfactionservice.descripLength": "La description des clés du sondage de satisfaction ne dépasse pas 200",
  "ccivr.flowtest.operatesuccess": "Succès",
  "ccivr.nms.interface.paramKeyValidate": "N'utilisez pas le nom de paramètre défini par la variable intégrée (callId\, businessReqId\, callSorId\, currentTime). Sinon\, l'affectation de valeur devient invalide.",
  "ccivr.nms.interface.outparamvalidate": "La valeur du paramètre ne peut contenir que des chiffres\, des lettres et les caractères spéciaux suivants \:._-",
  "ccivr.nms.interface.paramvalidate": "Les paramètres ne doivent contenir que des nombres\, des caractères anglais ou _-",
  "ccivr.ivr.transferRecord.play": "Jouer",
  "ccivr.ivr.voiceupload.texttovoice": "Le texte saisi est automatiquement converti en voix.",
  "ccivr.ivr.voicereview.upload": "Téléchargé",
  "ccivr.nms.shortmessageservice.sendType": "Mode d'envoi de message",
  "ccivr.nms.brach": "Branche",
  "ccivr.nms.playcollectservice.receivednum": "Chiffres reçus",
  "ccivr.ivr.ivrflow.operate.queryInfo": "Voir",
  "ccivr.nms.tansferservice.transInputWorkNo": "Entrez l'ID de l'agent à qui l'appel est transféré.",
  "ccivr.ivr.ivrflow.yes": "Oui",
  "ccivr.transferrecord.play.record": "Lecture des enregistrements de transfert de processus\:",
  "ccivr.ivr.error.download": "Impossible de télécharger le fichier à partir du serveur SFTP.",
  "ccivr.nms.assignservice.Dataassignment": "Attribution des données",
  "ccivr.ivr.voicereview.tenantspace": "Accueil Locataire",
  "ccivr.ivr.error.overpower": "Le locataire est non autorisé.",
  "ccivr.ivr.voiceupload.audiochannelfailed": "Le fichier vocal n'est pas un fichier vocal mono.",
  "ccivr.nms.process.success": "Le flux est sauvegardé avec succès.",
  "ccivr.ivr.voiceedit.inputmore": "La valeur ne peut pas dépasser",
  "ccivr.ivr.voiceadvice.setdefault": "Définir par défaut",
  "ccivr.whitelist.whiteport": "Port de liste de confiance",
  "ccivr.nms.shortmessageservice.selectTemplate": "Sélectionner un modèle",
  "ccivr.nms.label.selectCert": "Sélectionnez Certificat",
  "ccivr.ivr.variable.namevaluerule": "Le nom contient des caractères spéciaux. Veuillez le modifier.",
  "ccivr.ivr.element.namecheck": "Le nom contient des caractères spéciaux. Veuillez le modifier.",
  "ccivr.nms.leavemessageservice.recordparam": "Paramètre d'enregistrement",
  "ccivr.ivrflow.grayrule.selectgrayrule": "Sélectionnez une règle grise.",
  "ccivr.nms.leavemessageservice.leavemessage": "Laisser un message",
  "ccivr.nms.tansferservice.passingParameters": "Paramètres de transfert",
  "ccivr.ivr.voiceadvice.submit": "Envoyer",
  "ccivr.ivr.transferRecord.startTime": "Heure de début",
  "ccivr.nms.interface.certFile": "Fichier de certificat",
  "ccivr.ivr.ivrflow.operate": "Opération",
  "ccivr.ivrflow.grayrule.endnum": "Numéro de fin",
  "ccivr.ivr.transferRecord.success": "Succès",
  "ccivr.ivrflow.release.formalinfo": "Après la publication, le flux de cette version est exécuté pour tous les numéros.<br /> Si vous avez besoin de modifier le flux, vous devez mettre à jour une nouvelle version et modifier la version.",
  "ccivr.ivr.voiceupload.queryReferenceFlow": "voir",
  "ccivr.ivrflow.grayrule.inputbeginnum": "Exemple\: 12366668888",
  "ccivr.nms.conditionService.add": "Nouveau",
  "ccivr.nms.conditionService.createCondition": "Ajouter une condition",
  "ccivr.ivr.ivrflow.operate.clone.success": "Le processus de mise à niveau est réussi.",
  "ccivr.nms.shortmessageservice.normalMessage": "Message court",
  "ccivr.nms.leavemessageservice.choose.localtionID": "Enregistrement du localtionID",
  "ccivr.ivr.voicereview.play": "Jouer",
  "ccivr.nms.queryservice.estimateTime.prompte": "Le temps d'attente attendu de la mise en file d'attente est calculé sur la base du modèle de file d'attente et le résultat présente un certain écart. Veuillez l'utiliser correctement.",
  "ccivr.flowtest.fail": "Échec",
  "ccivr.ivr.voiceupload.vedionamewrong": "Échec de la vérification de l'FileNmae de téléchargement du fichier\!",
  "ccivr.nms.queryservice.specifiedSkill.paramValue": "ID de l'agent",
  "ccivr.ivr.variable.management": "Gestion des variables",
  "ccivr.nms.playcollectservice.Receivemaxnum": "Nombre max. de chiffres reçus",
  "ccivr.ivr.ivrflow.operate.release": "libération",
  "ccivr.ivr.name.errorspecial": "Le nom contient des caractères spéciaux. Veuillez le modifier.",
  "ccivr.ivrflow.error.deleteivrflow.part.released": "Certains des flux RVI sélectionnés ont été libérés. Annulez la publication avant de les supprimer.",
  "ccivr.ivr.tracelog.result.syserr": "erreur système",
  "ccivr.nms.playservice.chose": "Le contenu de lecture est sélectionné à partir des fichiers audio qui ont été téléchargés et examinés.",
  "ccivr.ivr.ivrflow.operate.delete": "supp.",
  "ccivr.ivr.bussinessinvoke.save": "Enregistrer",
  "ccivr.ivr.variable.variableisreferenced": "Les variables sont référencées par flux.",
  "ccivr.ivr.transferRecord.transResult": "Résultat du transfert",
  "ccivr.ivr.message.uploadimport.success": "Ajout réussi.",
  "ccivr.nms.playcollectservice.tip8": "Pendant la collecte de chiffres\, l'utilisateur entre la (\#) de clé de livre pour mettre fin à la collecte de chiffres. S'il vous plaît invitez dans la voix.",
  "ccivr.nms.playcollectservice.tip7": "Lorsque le nombre cumulé de temps de défaillance dépasse le maximum\, la sortie générale de défaillance est exécutée.",
  "ccivr.nms.playcollectservice.tip6": "Si la collecte des chiffres échoue\, vous n'avez pas besoin de jouer la voix. Si aucun fichier vocal n'est sélectionné\, le système rejoue l'invite vocale après l'échec.",
  "ccivr.ivr.variable.textvaluelimit": "Le texte ne peut pas contenir plus de 120 caractères.",
  "ccivr.nms.playcollectservice.tip5": "Si une erreur se produit pendant la collecte de chiffres\, le système enregistre une défaillance\, effectue automatiquement une erreur de collecte de chiffres et continue d'attendre la collecte de chiffres.",
  "ccivr.nms.playcollectservice.tip4": "Si le temps pendant lequel l'abonné arrête l'entrée dépasse le temps d'attente de collecte de chiffres préconfigurés\, le système enregistre l'échec. De plus\, le système effectue automatiquement un délai d'attente pour la collecte des chiffres et attend la collecte des chiffres.",
  "ccivr.whitelist.whitelistmanage": "Gestion des listes de confiance",
  "ccivr.nms.playcollectservice.tip3": "Pendant la collecte de chiffres\, l'utilisateur entre la (\#) de clé de livre pour mettre fin à la collecte de chiffres. S'il vous plaît invitez dans la voix.(n'avez pas besoin d'appuyer sur la touche (\#) pour collecter des chiffres dans l'élément de diagramme de configuration de menu)",
  "ccivr.nms.playcollectservice.tip2": "Veuillez sélectionner le paramètre type de chaîne que vous avez ajouté dans les informations du processus.",
  "ccivr.nms.playcollectservice.tip1": "Sélectionnez un paramètre pour enregistrer l'entrée de l'utilisateur.",
  "ccivr.flowtest.begintest": "Démarrer",
  "ccivr.nms.process.paramnameNoCN": "Le paramname est faux.",
  "ccivr.ivr.ivrflow.operate.edit": "Modifier",
  "ccivr.nms.menuservice.button": "Boutons",
  "ccivr.ivr.bussinessinvoke.error": "Erreur",
  "ccivr.ivr.voiceupload.submit": "Envoyer",
  "ccivr.ivr.voiceupload.onlyOneData": "Conservez au moins un enregistrement linguistique.",
  "ccivr.ivr.voiceupload.audiosamplebitfailed": "Le nombre d'échantillons de bits pour le fichier vocal n'est pas 8.",
  "ccivr.ivrflow.operate.replace.success": "Remplacé avec succès.",
  "ccivr.ivr.name.toolong": "Le nom dépasse la longueur maximale autorisée. Veuillez réessayer.",
  "ccivr.nms.playservice.multivoice.type.date": "Date(aaaammjj)",
  "ccivr.ivr.voicereview.all": "Tous",
  "ccivr.ivrvoice.play.voicetimelimit": "L'audio de l'audition est long\, Réduisez le contenu du texte et essayez à nouveau.",
  "ccivr.nms.leavemessageservice.recordfile": "Fichier d'enregistrement",
  "ccivr.nms.conditionService.logicOperator": "Logique",
  "ccivr.nms.tansferservice.queueindex": "Position de mise en file d'attente",
  "ccivr.nms.tansferservice.queuenum": "Nombre de files d'attente",
  "ccivr.ivr.tracelog.result.hangup": "HangUp",
  "ccivr.ivr.voicereview.filetype": "Type",
  "ccivr.ivr.tracelog.traceConfig": "Configuration de la trace du code d'accès",
  "ccivr.ivr.voiceupload.voiceUsageByTenant": "Référence du scénario du locataire",
  "ccivr.ivr.variable.deleteselectvariable": "Supprimer la variable",
  "ccivr.ivr.ivrflow.operate.create.accesscode.fail": "Impossible d'ajouter le code d'accès dans ODFS lors de la libération du flux IVR.",
  "ccivr.nms.playcollectservice.voicefile": "Fichier vocal",
  "ccivr.nms.conditionService.condition": "Nom de la condition",
  "ccivr.nms.playcollectservice.playset.reset": "Réinitialiser",
  "ccivr.nms.questionnaireService.placeholder": "Questionnaire",
  "ccivr.nms.questionnaireService.surveyname": "Nom du questionnaire",
  "ccivr.nms.questionnaireService.question": "Question du questionnaire",
  "ccivr.ivr.voice.speed": "vitesse",
  "ccivr.ivr.ivrflow.edit": "Édition",
  "ccivr.ivr.voiceupload.chinese": "Chinois",
  "ccivr.ivr.voiceadvice.confirm": "OK",
  "ccivr.nms.conditionService.operat": "Opération",
  "ccivr.nms.process.sysparam": "Paramètres du système",
  "ccivr.grayrule.errorinfo.containspecialcha": "Le paramètre ruleName contient un caractère spécial",
  "ccivr.nms.ChangeMediaService.placeholder": "Changer de média",
  "ccivr.ivrflow.error.deleteivrflow.released": "Le flux de RVI sélectionné a été libéré. Annulez la publication avant de la supprimer.",
  "ccivr.ivr.voicereview.refuse": "Rejeter",
  "ccivr.ivrflow.cancelrelease.accesscode.delete.failed": "Impossible de supprimer le code d'accès dans ODFS lors de l'annulation du flux IVR libéré.",
  "ccivr.ivr.bussinessinvoke.validatemessage": "Un paramètre du même nom existe déjà.",
  "ccivr.nms.leavemessageservice.flownamerepeat": "Le nom du fichier d'enregistrement et localtionID ne peuvent pas utiliser la même variable.",
  "ccivr.nms.timeselectservice.title": "Sélection de l'heure",
  "ccivr.nms.process.saveerror.connection": "L'enregistrement a échoué. Des lignes non valides existent.",
  "ccivr.ivr.voiceupload.selectvideo": "Sélectionnez un fichier vidéo.",
  "ccivr.nms.queryservice.specifiedSkill.result": "Nom de la file d'attente",
  "ccivr.nms.playcollectservice.collectwaittime": "Temps d'attente pour la collecte des chiffres",
  "ccivr.ivr.illegal.fail": "Paramètre non valide.",
  "ccivr.nms.conditionService.delete": "supp.",
  "ccivr.nms.process.saveerror.select": "Sélectionnez un type de changement.",
  "ccivr.ivr.voiceadvice.currentdefault": "default",
  "ccivr.ivr.ivrflow.flowquote": "Est référencé",
  "ccivr.nms.playservice.chosevideo": "Le contenu à lire est sélectionné parmi les vidéos qui ont été téléchargées et examinées.",
  "ccivr.nms.queryservice.skillMsg": "Informations de mise en file d'attente",
  "ccivr.ivr.voicereview.waitreview": "Pour revoir",
  "ccivr.ivr.variable.specialcharacters": "Les caractères spéciaux ou chinois ne sont pas autorisés.",
  "ccivr.ivr.voice.exception.recordfail": "Erreur d'enregistrement.",
  "ccivr.ivr.error.advicelimit": "Le commentaire de la revue ne peut pas contenir plus de 100 caractères.",
  "ccivr.nms.businessinvokeservice.selectservice": "Sélectionner l'interface",
  "ccivr.ivr.voicepop.inputname": "Saisissez le nom de la voix IVR.",
  "ccivr.nms.queryservice.assignmentParameters": "Paramètre à définir",
  "ccivr.whitelist.validator.ip": "Adresse IP non valide. (Entrez une adresse IPv4 ou IPv6\, ou un nom de domaine.)",
  "ccivr.ivr.voicereview.chinese": "Chinois",
  "ccivr.ivrflow.unlock.fail": "Échec du statut déverrouillé",
  "ccivr.ivr.tracelog.callid": "Callid",
  "ccivr.ivr.variable.modifyvariable": "Modifier la variable",
  "ccivr.ivr.pageurls.errorsave": "Erreur",
  "ccivr.nms.queryservice.specialList.flagInfo": "Si le numéro d'abonné est dans la liste spéciale. Les options sont 0 (non) et 1 (oui).",
  "ccivr.nms.tansferservice.transferLastAgent.type.time.label": "min",
  "ccivr.nms.shortmessageservice.smsType": "Canal",
  "ccivr.ivr.ivrflow.operate.ensureclone.info": "Êtes-vous sûr de vouloir mettre à jour le flux?",
  "ccivr.nms.transInputWorkNo.sample": "Exemple\: 28956666 ou {'$'}{'{'}FLOW.currentWorkNo{'}'}",
  "ccivr.flowtest.enter": "Entrez le numéro d'appel.",
  "ccivr.nms.shortmessageservice.addresseeDesc": "Facultatif, Numéro de mobile unique, Si vide, il sera envoyé à l'appelant",
  "ccivr.nms.businessinvokeservice.overtime": "Intervalle de temporisation",
  "ccivr.ivr.voiceupload.audiotypefailed": "Le fichier vocal n'est pas au format ALAW.",
  "ccivr.ivr.transferRecord.query.failed": "Impossible d'interroger les enregistrements de transfert",
  "ccivr.nms.process.number": "Entier",
  "ccivr.nms.playcollectservice.changetomultivoice": "Voix variable",
  "ccivr.ivr.voice.scene.beforeReportWorkNo": "avant de déclarer l'ID de l'employé",
  "ccivr.ivr.error.connect": "Impossible de se connecter au serveur SFTP.",
  "ccivr.nms.tansferservice.timedPrompt": "Vous ne l'avez pas sauvé depuis longtemps. S'il vous plaît\, économisez-le à temps.",
  "ccivr.nms.ringService.sendonly": "Envoyer seulement",
  "ccivr.ivr.variable.desclimit": "La description ne peut pas contenir plus de 120 caractères.",
  "ccivr.flowtest.pleasebegintest": "Le processus est terminé.",
  "ccivr.ivr.tracelog.result.finshed": "Terminé",
  "ccivr.nms.playservice.multivoice.type.price": "Prix",
  "ccivr.nms.playcollectservice.onenumber": "Collecte à un chiffre",
  "ccivr.nms.menuservice.movedown": "En bas",
  "ccivr.ivr.variable.defaultValue": "Valeur par défaut",
  "ccivr.nms.process.saveerror.existnullentity": "Des éléments de diagramme de données vides existent.",
  "ccivr.ivrflow.operate.replace": "Remplacer",
  "ccivr.ivrflow.flowAccessCode": "Code d\'accès au flux",
  "ccivr.transferrecord.download.record": "Télécharger l'enregistrement de transfert de flux\:",
  "ccivr.ivr.voiceedit.cancel": "Annuler",
  "ccivr.nms.tansferservice.samenamevalidate": "Dupliquer le nom du paramètre.",
  "ccivr.ivr.voicereview.autopass": "Passage automatique",
  "ccivr.nms.businessinvokeservice.info2": "Si l'intervalle de délai d'attente d'appel est dépassé\, le module d'échec d'appel est exécuté.",
  "ccivr.nms.businessinvokeservice.info1": "Les interfaces disponibles doivent être configurées sur la page de configuration de l'interface.",
  "ccivr.nms.playservice.contentisnull": "Le contenu vocal ne peut pas être vide.",
  "ccivr.ivr.transferRecord.download": "Téléchargement",
  "ccivr.ivr.variable.defaultvalueerror": "La valeur par défaut contient des caractères spéciaux. Veuillez le modifier.",
  "ccivr.ivr.voiceupload.amountLimit5": "Un maximum de cinq fichiers vocaux peuvent être ajoutés dans ce scénario.",
  "ccivr.ivr.voiceupload.tipformat1M": "Les formats MP3 et WAV sont pris en charge\, et la taille du fichier ne peut pas dépasser 1 Mo.",
  "ccivr.ivr.voiceupload.exceedLimit": "Le nombre de fichiers IVR dépasse la limite.",
  "ccivr.ivrflow.ivrflowname.specialStrValidateFailed": "La valeur ne peut pas contenir de caractères spéciaux et la longueur ne peut pas dépasser 50 caractères.",
  "ccivr.nms.playcollectservice.enterCollectTimeout": "Veuillez saisir l'intervalle de temps de collecte des chiffres.",
  "ccivr.nms.process.parameterencryptips": "Notez que les paramètres non chiffrés stockent les valeurs des paramètres en clair\, ce qui peut poser des risques de sécurité s'ils sont utilisés.",
  "ccivr.ivr.voice.scene.afterReportWorkNo": "après avoir signalé l'ID de l'employé",
  "ccivr.ivr.description.containspecialcharacter": "Le modèle contient des caractères spéciaux ~",
  "ccivr.ivr.voiceadvice.lengthError": "La longueur ne peut pas dépasser 100 caractères.",
  "ccivr.ivr.voiceupload.file.content": "Contenu",
  "ccivr.ivr.voiceupload.file.update.content": "Mise à jour du contenu",
  "ccivr.ivr.voiceupload.file.update.time": "Heure de mise à jour",
  "ccivr.ivr.voiceupload.file.update.status": "Statut",
  "ccivr.ivr.voiceupload.file.update.comments": "Commentaires",
  "ccivr.ivr.voiceupload.file.update.operation": "Opération",
  "ccivr.ivr.voiceupload.file.update.delete": "Supprimer",
  "ccivr.ivr.voiceupload.file.update.submit": "Envoyer",
  "ccivr.ivr.voiceupload.file.update": "mise à jour",
  "ccivr.ivr.voiceupload.textcontent": "Texte",
  "ccivr.ivr.bussinessinvoke.long": "Long entier",
  "ccivr.ivr.voiceupload.fileformatwrong": "Type incorrect.",
  "ccivr.nms.playservice.TTS": "Lecture vocale TTS",
  "ccivr.ivr.voiceupload.inputlanguageerror": "La langue n'existe pas \!",
  "ccivr.ivr.voiceupload.choosestatus": "Sélectionnez l'état de l'examen.",
  "ccivr.ivr.bussinessinvoke.float": "Flottant",
  "ccivr.nms.playservice.interrupt": "Appuyez sur n'importe quelle touche pour interrompre la lecture.",
  "ccivr.flowtest.test.error.tip": "flow n'a pas config ou ne s'applique pas",
  "ccivr.nms.label.certType": "Type de certificat",
  "ccivr.nms.label.inputCertCode": "Saisissez le code du certificat.",
  "ccivr.nms.label.certCode": "Code du certificat",
  "ccivr.nms.label.certScene": "Scénarios d'application du certificat",
  "ccivr.ivr.error.releaseFailed.tips": "Échec de la publication automatique du questionnaire. Veuillez configurer et publier un processus de gestion des exceptions\, puis publier manuellement le questionnaire.",
  "ccivr.ivr.ivrflow.operate.add": "Ajouté",
  "ccivr.nms.playservice.chose.survey": "Lire le contenu Sélectionner ou ajouter une voix à partir de l'audio téléchargé et revu",
  "ccivr.nms.playservice.chosetts.survey": "Pour lire le contenu, sélectionnez ou ajoutez du texte à partir du texte TTS téléchargé et examiné. Les ressources TTS doivent être disponibles.",
  "ccivr.nms.satisfactionservice.buttonNumber.Number": "Le nombre de clés n'est pas supérieur à 9 et pas inférieur à 1.",
  "ccivr.nms.lightweight.tips": "Effectuez des opérations sur l'édition légère du questionnaire.",
  "ccivr.conditionService.param.tips": "Lorsque le paramètre 2 est défini comme une constante de chaîne, il est nécessaire d'ajouter des guillemets pour pouvoir l'utiliser correctement.",
  "ccivr.nms.conditionService.sample": "Entrez une expression de condition. L'expression peut contenir le nom du paramètre\, la valeur et l'opération complexe. Un paramètre est référencé dans le format FLOW.Parameter name. Exemple\: SYS.currentNumber\=\=FLOW.RechargeNumber&& (FLOW.AccountBalance - 20)>\=FLOW.BillAmount",
  "ccivr.TimeSelectService.branch.wrongtime": 'Mauvais moment',
  "ccivr.playservice.branch.query": "Erreur de requête",
  'ccivr.businessInvokeService.err': 'Échec de l\'appel d\'interface',
  'ccivr.playservice.branch.ring': 'Erreur de sonnerie',
  'ccivr.playservice.branch.userLevel': 'Erreur de réglage',
  'ccivr.tansferservice.branch.transferErr': 'Erreur de transfert',
  'ccivr.nms.transferservice.choose.recordfilename.remark': 'utilisé dans le mode pool de centre d\'appels',
  'ccivr.playservice.branch.change': 'Erreur de modification',
  'ccivr.nms.conditionService.conditionBranch': 'Branches conditionnelles',
  'ccivr.playservice.branch.playerr': 'Erreur de lecture',
  'ccivr.PlayCollectService.branch.err': 'Erreur PlayCollect',
  'ccivr.nms.playcollectservice.warntype': 'Type d\'invite',
  'ccivr.ivr.bussinessinvoke.paramTip': 'Nom du paramètre, par exemple, GLOBAL.language ou FLOW.testid',
  'ccivr.leaveMessage.maxtime.range': 'La durée maximale d\'enregistrement est comprise entre 10 et 180 secondes.',
  'ccivr.nms.conditionService.tips': 'Les expressions conditionnelles peuvent contenir des variables, des valeurs et des opérations complexes. L\'opérateur de comparaison entre les valeurs ou les chaînes de caractères prend en charge les > < >= <= !=. L\'opérateur booléen supporte && {\'|\'} {\'|\'} = Exemple: SYS.currentNumber==FLOW.RechargeNumber && (FLOW.AccountBalance-20)>=FLOW.BillAmount',
  'ccivr.ivr.voiceupload.fileName.validate': 'Le nom du fichier ne peut pas contenir les caractères spéciaux suivants:',
  'ccivr.nms.play.method': 'Méthode',
  'ccivr.nms.play.source': 'Ressource',
  'ccivr.ivr.ivrflow.operate.release.referencedflow': 'Impossible d\'annuler la libération! Le flux a été référencé.',
  'ccivr.ivr.voice.scene.2d.avatar': 'Humain virtuel',
  'ccivr.ivr.voice.picturechoose': 'Sélection du fichier image',
  'ccivr.ivr.voiceupload.selectpicture': 'Sélectionner un fichier image',
  'ccivr.ivr.voiceupload.tippictureformat' : 'Actuellement, seuls les fichiers image JPG, PNG et JPEG sont pris en charge. La résolution du fichier ne peut pas dépasser 720p, et la taille du fichier ne peut pas dépasser 2 Mo.',
  'ccivr.ivr.voiceupload.tippicturemodel': 'Vous pouvez définir des fichiers image dans différentes langues en même temps. Le système commute automatiquement vers le fichier d\'image correspondant en fonction de la langue choisie par l\'utilisateur lors de la synthèse numérique de la personne.',
  'ccivr.ivr.voiceupload.tip2DVideoformat': 'Actuellement, seuls les fichiers vidéo MP4 sont pris en charge. La résolution du fichier ne peut pas dépasser 720p, et la taille du fichier ne peut pas dépasser 5 Mo.',
  'ccivr.ivr.voiceupload.tip2Dvideomodel': 'Vous pouvez définir des fichiers vidéo dans différentes langues en même temps. Pendant la composition humaine numérique, le système bascule automatiquement vers le fichier vidéo correspondant en fonction de la langue choisie par l\'utilisateur.',
  'ccivr.ivr.voiceupload.vedioMp4formatwrong' : 'Format de fichier incorrect. Veuillez importer un fichier MP4.',
  'ccivr.ivr.voiceupload.tipvideo2Dformat': 'Actuellement, seuls les fichiers vidéo au format MP4 sont pris en charge, et la taille du fichier ne peut pas dépasser 5 Mo.',
  'ccivr.ivr.voicereview.picture': 'Image',
  'ccivr.ivr.voicereview.2dVideo': 'Vidéo virtuelle humaine',
  'ccivr.ivr.voiceupload.tipPicture2Dformat': 'Actuellement, seuls les fichiers image au format JPG, PNG et JPEG sont pris en charge. La taille du fichier ne peut pas dépasser 2 Mo.',
  'ccivr.ivr.voiceupload.pictureformatwrong': 'Format de fichier incorrect, veuillez importer des fichiers au format jpg, png ou jpeg !',
  'ccivr.ivr.voiceupload.file.updateTitle': 'Modifier les ressources audio et vidéo',
  'ccivr.ivr.voiceupload.file.createTitle': 'Créer une ressource audio et vidéo',
  'ccivr.ivr.voiceupload.tippictureresolution': 'La résolution de l\'image ne doit pas dépasser 720p',
  'ccivr.ivr.voiceupload.tipvideoresolution': 'La résolution vidéo ne doit pas dépasser 720p',
  'oifde.service.invoke.businessVirtualHumanService.name': 'Répondre à la personne virtuelle',
}
