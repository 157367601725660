<template>
  <div>
    <sweet-dialog v-model="dialogVisible" :title="title" width="896px" class="aicc-dialog-limit-height" :close-on-click-modal="false" :close-on-press-escape="false">
      <div v-if="isEnding">
        <sweet-form ref="callDataForm" :model="form" :rules="rules" :label-position="labelPosition" label-width="auto" :disabled="isView">
          <sweet-form-item prop="sessionId">
            <sweet-input v-model="form.sessionId"
                         :placeholder="$t('isales.taskresult.title.inputSessionId')"
                         :disabled="!isCreate"
                         maxlength="32"
                         style="width: 50%"/>
          </sweet-form-item>
          <sweet-tabs v-model="activeName" class="aicc-m-top--medium">
            <sweet-tab-pane :label="$t('isales.management.title.calledNo')" name="calledNo">
              <div class="aicc-align--absolute-between aicc-m-top--medium aicc-m-bottom--medium">
                <div style="display: flex;align-items: flex-end;">
                  <div class="table-title">{{ $t('isales.data.title.tableTitle') }}</div>
                  <div class="delay-time-tips">{{ $t('isales.callno.message.delayTime') }}</div>
                </div>
                <sweet-button v-if="!isView" type="primary" @click="addNewRow">{{ $t('isales.callNo.field.add') }}</sweet-button>
              </div>
              <div style="width: 850px">
                  <aicc-table :tableData="form.callNoList"
                              :tableColumns="tableColumns"
                              :tableTools="{ columnConfig: false }"
                              :height="430"
                              class="margin-bottom-10px-to-rem"
                              size="small">
                    <template #calledNo="scope">
                      <sweet-form-item v-if="!isView" class="aicc-m-bottom--small aicc-m-top--small" :prop="`callNoList.${scope.$index}.calledNo`" :rules="calledNoRules">
                        <sweet-input v-model="scope.row.calledNo" />
                      </sweet-form-item>
                      <span v-if="isView">{{ scope.row.calledNo }}</span>
                    </template>
                    <template #type="scope">
                      <sweet-form-item v-if="!isView" class="aicc-m-bottom--small aicc-m-top--small" :prop="`callNoList.${scope.$index}.type`" :rules="typeRules">
                        <sweet-select v-model="scope.row.type" value-key="value">
                          <sweet-option v-for="item in types" :value="item.value" :label="item.label"></sweet-option>
                        </sweet-select>
                      </sweet-form-item>
                      <span v-if="isView">{{ scope.row.type }}</span>
                    </template>
                    <template #delayTime="scope">
                      <sweet-form-item v-if="!isView" class="aicc-m-bottom--small aicc-m-top--small" :prop="`callNoList.${scope.$index}.delayTime`" :rules="delayTimeRules">
                        <sweet-input v-model="scope.row.delayTime" :min="0" :max="86400" :maxlength="5" placeholder="0-86400"/>
                      </sweet-form-item>
                      <span v-if="isView">{{ scope.row.delayTime }}</span>
                    </template>
                    <template #action="scope">
                      <sweet-button class="task-icon" :icon="Delete" type="icon" @click="deleteRow(scope.row)"/>
                    </template>
                  </aicc-table>
                </div>
            </sweet-tab-pane>
            <sweet-tab-pane v-if="isShowPropTab" :label="$t('isales.data.propConfig.title.prop')" name="prop">
              <div class="aicc-m-top--medium">
                <sweet-form-item v-for="(item, index) in propList"
                                 :label="item.propName"
                                 :required="item.mandatory === '1'"
                                 :prop="`propValueObject.${item.mapField}`"
                                 :rules="getPropValueRules(item)">
                  <sweet-input v-if="item.propType === '1'"
                               v-model="form.propValueObject[item.mapField]"/>
                  <sweet-input v-if="item.propType === '2'"
                               v-model="form.propValueObject[item.mapField]"/>
                  <sweet-input v-if="item.propType === '3'"
                               type="password"
                               v-model="form.propValueObject[item.mapField]"/>
                </sweet-form-item>
              </div>
            </sweet-tab-pane>
          </sweet-tabs>
        </sweet-form>
      </div>
      <template #footer>
      <span class="dialog-footer">
        <sweet-button @click="dialogVisible = false">{{ $t('isales.common.title.cancel') }}</sweet-button>
        <sweet-button type="primary" @click="submit">{{ $t('isales.common.title.confirm') }}</sweet-button>
      </span>
      </template>
    </sweet-dialog>
  </div>
</template>

<script>
import {Delete} from '@element-plus/icons-vue'
import {
  createCallData,
  queryAllCallNoByDataId,
  queryCallNoTypeByTaskId,
  queryDataPropList,
  queryPreviewCallNoMaxLength,
  queryTaskByCondition,
  updateCallData
} from '@/views/isales/api/taskManagement'
import {RETURN_CODE, TASK_TYPE} from '@/views/isales/views/taskManagement/enum'
import {objDeepCopy} from '@oifde/utils/common'

export default {
  name: 'DialogCallData',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    type: String,
    dataId: String,
    taskId: String,
    taskType: String,
    policyType: Number,
    currentData: Object
  },
  data() {
    const calledNoValidator = (rule, value, callback) => {
      if (!this.$Validate.numberOnly(value)) {
        callback(this.$t('aicc.numberOnly'))
      }
      if (value.length > 24) {
        callback(this.$t('aicc.lengthLimit', {limit: 24}))
      } else {
        callback()
      }
    }
    const delayTimeValidator = (rule, value, callback) => {
      let reg
      if (value.toString().length === 1) {
        reg = /^\d+$/
      } else {
        reg = /^[1-9]\d*$/
      }
      if (!reg.test(value)) {
        callback(this.$t('isales.common.message.checknonnegativeinteger'))
      } if (parseInt(value) < 0 || parseInt(value) > 86400) {
        callback(this.$t('isales.taskpolicy.message.rangeAutoCallTime', [0, 86400]))
      } else {
        callback()
      }
    }
    return {
      labelPosition: window.isRTL ? 'right' : 'left',
      TASK_TYPE,
      Delete,
      form: {
        sessionId: '',
        callNoList: [],
        propValueObject: {}
      },
      activeName: 'calledNo',
      isShowPropTab: true,
      tableColumns: [
        {
          label: this.$t('isales.callNo.title.no'), // 序号
          type: 'index',
          width: '100px'
        },
        {
          label: this.$t('isales.management.title.calledNo'), // 客户号码
          slot: 'calledNo'
        },
        {
          label: this.$t('isales.callNo.field.delayTime'), // 呼叫失败重试间隔
          slot: 'delayTime',
          width: '200px'
        },
        {
          label: this.$t('isales.common.title.operation'), // 操作
          slot: 'action',
          width: '100px'
        }
      ],
      tableTypeColumn: {
        label: this.$t('isales.callNo.field.type'), // 号码类型
        slot: 'type'
      },
      isTypesExist: false,
      types: [],
      isInit: true,
      index: 0,
      propList: [],
      oldList: [],
      previewCallNoMaxCount: '',
      // OBS的PhoneNumber最多存放120字节
      OBS_PhoneNumber: 120,
      isEnding: false,
      calledNoRules: [
          { required: true, trigger: ['blur', 'change'], message: this.$t('isales.business.result.message.must') },
          { validator: calledNoValidator, trigger: ['blur', 'change'] }
      ],
      delayTimeRules: [
        { required: true, trigger: ['blur', 'change'], message: this.$t('isales.business.result.message.must') },
        { validator: delayTimeValidator, trigger: ['blur', 'change'] }
      ],
      typeRules: [
        { required: true, trigger: ['blur', 'change'], message: this.$t('isales.business.result.message.must') }
      ]
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      }
    },
    isView() {
      return this.type === 'view'
    },
    isEdit() {
      return this.type === 'edit'
    },
    isCreate() {
      return this.type === 'create'
    },
    title() {
      if (this.isView) {
        return this.$t('isales.management.title.detailData')
      } else if (this.isEdit) {
        return this.$t('isales.management.title.updateData')
      } else {
        return this.$t('isales.management.title.createNew')
      }
    },
    rules() {
      return {
        sessionId: [
          { validator: this.$Validate.numberOnlyValidator,  trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  created() {
    // 查看详情时不显示操作列
    if (this.isView) {
      this.tableColumns.pop()
    }
    if (this.policyType === 1) {
      this.initCallNoType()
    }
    if (this.isCreate) {
      this.addNewRow()
    } else {
      this.form.sessionId = this.currentData.sessionId
      this.queryCallNoList(this.dataId, this.taskId)
    }
    this.ifShowPropTab()
  },
  methods: {
    async initCallNoType() {
      const result = await queryCallNoTypeByTaskId({ taskId: this.taskId })
        if (result && result.length) {
          result.forEach((element) => {
            if (element !== '9D9T9') {
              this.types.push({label: element, value: element})
            }
          })
          if (this.types && this.types.length > 0) {
            this.tableColumns.splice(2, 0, this.tableTypeColumn)
            this.isTypesExist = true;
          }
        }
    },
    async addNewRow() {
      if (this.taskType === TASK_TYPE.PREVIEW && this.isInit) {
        let result = await queryPreviewCallNoMaxLength()
        this.previewCallNoMaxCount = parseInt((this.OBS_PhoneNumber / (result.previewCallNoMaxLength + 1)).toString())
        this.isInit = false
      }
      if (this.taskType !== TASK_TYPE.PREVIEW && this.form.callNoList.length >= 10) {
        this.$message({
          type: 'error',
          duration: 10000,
          offset: 56,
          'show-close': true,
          message: this.$t('isales.data.message.addNumberUpperLimit')
        })
        return
      }
      if (this.taskType === TASK_TYPE.PREVIEW && this.form.callNoList.length >= this.previewCallNoMaxCount) {
        this.$message({
          type: 'error',
          duration: 10000,
          offset: 56,
          'show-close': true,
          message: this.$t('isales.data.message.preview.addNumberUpperLimit', { count: this.previewCallNoMaxCount })
        })
        return
      }
      if (this.policyType === 0) {
        this.form.callNoList.push({ orderNo: ++this.index, calledNo: '', delayTime: '0' })
      } else {
        this.form.callNoList.push({ orderNo: ++this.index, calledNo: '', type: '', delayTime: '0' })
      }
    },
    async queryCallNoList(dataId, taskId) {
      if (this.taskType) {
        const result = await queryAllCallNoByDataId({ dataId, taskId })
          this.form.callNoList = result.callNoList
          this.form.callNoList.forEach(item => {
              this.oldList.push(objDeepCopy(item));
          })
        this.index = this.form.callNoList.length
        if (this.policyType === 1) {
          if (result.dataTypes) {
            result.dataTypes.forEach((element) => {
              if (element !== '9D9T9') {
                this.types.push({ "label": element, "value": element })
              }
            })
            if (this.types && this.types.length > 0) {
              this.isTypesExist = true
            }
          }
        }
      }
    },
    async ifShowPropTab() {
      //1、如果当前租户下没有配置任何自定义属性，数据编辑页面也只显示基础字段
      await this.queryPropList()
      if (!this.propList.length) {
        this.isEnding = true
        this.isShowPropTab = false
        return
      }
      //2、判断外呼任务是否启用了自定义属性，如果没有启用，数据编辑页面只显示基础字段
      let searchObject = {}
      if (this.taskId) {
        searchObject.taskId = this.taskId
      }
      let params = {
        dataObject: searchObject,
        limit: 10,
        offset: 0,
      }
      let result = await queryTaskByCondition(params)
      if (result && result.length) {
        if (result[0].extPropEnable === 1 && result[0].extProp) {
          await this.queryPropList(result[0].extProp.split(','))
        } else if (result[0].extPropEnable === 0) {
          this.isShowPropTab = false
        }
      }
      if (!this.isCreate) {
        this.initPropValue()
      }
      this.isEnding = true
    },
    initPropValue() {
      if (this.currentData) {
        for (let tempDate of this.propList) {
          if (tempDate.propType === '3') {
            this.form.propValueObject[tempDate.mapField] = '********'
          } else {
            this.form.propValueObject[tempDate.mapField] = this.currentData[tempDate.mapField]
          }
        }
      }
    },
    async queryPropList(propIds) {
      let searchObject = { status: 1, marketingMode: 1 }
      if (propIds) {
        searchObject.propIds = propIds
      }
      let params = {
        propObject: searchObject,
        limit: 50,
        offset: 0
      }
      let result = await queryDataPropList(params);
      if (result && result.resultCode === '0203000') {
        this.propList = result.propList
      } else {
        this.$message({
          type: 'error',
          duration: 10000,
          offset: 56,
          'show-close': true,
          message: this.$t('isales.common.title.tipsInfo')
        })
      }
    },
    deleteRow(row) {
      let index = this.form.callNoList.findIndex(item => item == row);
      this.form.callNoList.splice(index, 1)
      this.index -= 1
      this.form.callNoList.forEach(item => {
        if (item.orderNo > row.orderNo) {
          item.orderNo -= 1
        }
      })
      if (this.form.callNoList.length <= 0) {
        this.index = 1
        if (this.policyType == 0) {
          this.form.callNoList.push({ orderNo: this.index, calledNo: '', delayTime: '0' })
        } else {
          this.form.callNoList.push({ orderNo: this.index, calledNo: '', type: "", delayTime: '0' })
        }
      }
    },
    isDuplicateExist() {
      let callNos = []
      for (let item of this.form.callNoList) {
        if (callNos.includes(item.calledNo)) {
          continue
        }
        callNos.push(item.calledNo)
      }
      return callNos.length !== this.form.callNoList.length
    },
    isNotChanged() {
      let oldList = this.oldList.map(item => {
        const { orderNo, calledNo, delayTime, type } = item
        return { orderNo, calledNo, delayTime, type }
      })
      let newList = this.form.callNoList.map(item => {
        const { orderNo, calledNo, delayTime, type } = item
        return { orderNo, calledNo, delayTime, type }
      })
      return this.arrayCompare(oldList, newList);
    },
    isNotChangProp() {
      if (this.currentData && this.propList.length) {
        for (let tempDate of this.propList) {
          if (this.form.propValueObject[tempDate.mapField] !== this.currentData[tempDate.mapField]) {
            return false
          }
        }
      }
      return true
    },
    arrayCompare(arr1, arr2) {
      return arr1.length == arr2.length
          && arr1.every((item, index) => item.orderNo == arr2[index].orderNo
              && item.calledNo == arr2[index].calledNo
              && item.delayTime == arr2[index].delayTime
              && item.type == arr2[index].type
          )
    },
    async submit() {
      const valid = await this.$refs.callDataForm.validate().catch(err => err)
      if (valid !== true) {
        console.log(valid)
        // 会有一个index为-1的rule，原因未知，暂时规避
        if (valid['callNoList.-1.calledNo']) {
          delete valid['callNoList.-1.calledNo']
        }
        if (valid['callNoList.-1.delayTime']) {
          delete valid['callNoList.-1.delayTime']
        }
        if (valid['callNoList.-1.type']) {
          delete valid['callNoList.-1.type']
        }
        console.log(Object.keys(valid))
        let keys = Object.keys(valid)
        let callNoIndex = keys.findIndex(item => item.includes('callNoList.'))
        let propIndex = keys.findIndex(item => item.includes('propValueObject.'))
        if (callNoIndex === -1 && propIndex !== -1) {
          this.activeName = 'prop'
        }
        if (keys.length) {
          return
        }
      }
      if (this.isDuplicateExist()) {
        this.$message({
          type: 'error',
          duration: 10000,
          offset: 56,
          'show-close': true,
          message: this.$t('isales.data.message.duplicateExist')
        })
        return
      }
      if (!this.form.callNoList.length) {
        this.$message({
          type: 'error',
          duration: 10000,
          offset: 56,
          'show-close': true,
          message: this.$t('isales.data.message.empty')
        })
        return
      }
      await this.save()
    },
    async save() {
      if (!this.isCreate && this.isNotChanged() && this.isNotChangProp()) {
        this.$message({
          type: 'success',
          duration: 5000,
          offset: 56,
          message: this.$t('isales.management.message.updateSuccess')
        })
        this.dialogVisible = false
        return
      }
      let params = { taskId: this.taskId }
      params.callNoList = this.form.callNoList.map(item => {
        const { orderNo, calledNo, delayTime, type } = item
        return { orderNo, calledNo, delayTime, type }
      })
      let isPushAgentProps = ''
      for (let tempProp of this.propList) {
        if (this.form.propValueObject[tempProp.mapField] != '********') {
          params[tempProp.mapField] = this.form.propValueObject[tempProp.mapField];
        }
        if (tempProp.isPushAgent == '1' && this.form.propValueObject[tempProp.mapField]) {
          isPushAgentProps += ",\\\"" + tempProp.propCode + "\\\":\\\"" + this.form.propValueObject[tempProp.mapField] + "\\\"";
        }
      }
      if (isPushAgentProps) {
        params.isPushAgentProps = isPushAgentProps
      }
      let result
      if (this.type === 'create') {
        params.sessionId = this.form.sessionId
        result = await createCallData(params)
      } else {
        params.dataId = this.dataId
        result = await updateCallData(params)
      }
      const { returnCode, description } = result
      if (returnCode === RETURN_CODE.SUCCESS) {
        this.$emit('refreshDataTable')
        this.$message({
          type: 'success',
          offset: 56,
          duration: 5000,
          message: this.$t(`isales.management.message.${this.type == "create" ? 'createSuccess' : 'updateSuccess'}`)
        });
        this.dialogVisible = false;
      } else if (returnCode === '04000018') {
        this.$message({
          type: 'error',
          duration: 10000,
          offset: 56,
          'show-close': true,
          message: this.$t('isales.data.lock')
        })
      } else if (description === 'The calledNo is in the blacklist') {
        this.$message({
          type: 'error',
          duration: 10000,
          offset: 56,
          'show-close': true,
          message: this.$t('isales.management.message.blackCalledNo')
        })
      } else {
        this.$message({
          type: 'error',
          duration: 10000,
          offset: 56,
          'show-close': true,
          message: this.$t(`isales.management.message.${this.type == "create" ? 'createFailed' : 'updateFail'}`)
        })
      }
    },
    getPropValueRules(item) {
      let rules = []
      if (item.mandatory === '1') {
        rules.push({ required: true, trigger: ['blur', 'change'], message: this.$t('isales.business.result.message.must') })
      }
      if (item.propType === '1') {
        rules.push({ maxLength: 40, validator: this.$Validate.maxLengthValidator, trigger: ['blur', 'change'] })
      }
      if (item.propType === '2') {
        rules.push({ min: 0, max: 9999999999, validator: this.numberPropValidator, trigger: ['blur', 'change'] })
      }
      if (item.propType === '3') {
        rules.push({ maxLength: 30, validator: this.$Validate.maxLengthValidator, trigger: ['blur', 'change'] })
      }
      return rules
    },
    numberPropValidator (rule, value, callback) {
      if (!value) {
        callback();
      }
      let reg
      if (value.toString().length === 1) {
        reg = /^\d+$/
      } else {
        reg = /^[1-9]\d*$/
      }
      if (!reg.test(value) || Number(value) > rule.max) {
        callback(this.$t('aicc.intRangeLimit', { min: rule.min, max: rule.max }))
      } else {
        callback()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.table-title {
  font-size:20px;
}
.delay-time-tips {
  font-size:10px;
  margin-left: 10px;
  color: grey;
}
</style>
