export default {
  "ccnotification.flowcontrol.messageThreshold" : "\u0E04\u0E48\u0E32\u0E08\u0E33\u0E01\u0E31\u0E14\u0E23\u0E32\u0E22\u0E27\u0E31\u0E19\u0E02\u0E2D\u0E07 SMS",
  "ccnotification.gatewayconfig.label.addrNpi" : "\u0E42\u0E2B\u0E21\u0E14\u0E40\u0E02\u0E49\u0E32\u0E23\u0E2B\u0E31\u0E2A\u0E17\u0E35\u0E48\u0E2D\u0E22\u0E39\u0E48 ESME",
  "ccnotification.notifition.template.label.index" : "\u0E2B\u0E21\u0E32\u0E22\u0E40\u0E25\u0E02",
  "ccnotification.gatewayconfig.label.transactionTimeouttitle" : "\u0E0A\u0E48\u0E27\u0E07\u0E40\u0E27\u0E25\u0E32\u0E2B\u0E21\u0E14\u0E40\u0E27\u0E25\u0E32\u0E18\u0E38\u0E23\u0E01\u0E23\u0E23\u0E21\u0E04\u0E37\u0E2D\u0E15\u0E31\u0E27\u0E40\u0E25\u0E02\u0E17\u0E35\u0E48\u0E40\u0E2B\u0E21\u0E32\u0E30\u0E2A\u0E21",
  "ccnotification.notifition.sendmessage.delaysend" : "\u0E01\u0E32\u0E23\u0E41\u0E08\u0E49\u0E07\u0E40\u0E15\u0E37\u0E2D\u0E19\u0E15\u0E32\u0E21\u0E01\u0E33\u0E2B\u0E19\u0E14\u0E40\u0E27\u0E25\u0E32",
  "ccnotification.notifition.template.message.money" : "\u0E04\u0E48\u0E32\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E1B\u0E23\u0E30\u0E01\u0E2D\u0E1A\u0E14\u0E49\u0E27\u0E22\u0E15\u0E31\u0E27\u0E40\u0E25\u0E02 1 \u0E16\u0E36\u0E07 20 \u0E2B\u0E25\u0E31\u0E01  \u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E16\u0E48\u0E32\u0E22\u0E42\u0E2D\u0E19\u0E40\u0E09\u0E1E\u0E32\u0E30\u0E15\u0E31\u0E27\u0E40\u0E25\u0E02\u0E41\u0E25\u0E30\u0E08\u0E38\u0E14\u0E17\u0E28\u0E19\u0E34\u0E22\u0E21\u0E17\u0E35\u0E48\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E41\u0E2A\u0E14\u0E07\u0E08\u0E33\u0E19\u0E27\u0E19\u0E44\u0E14\u0E49\u0E2D\u0E22\u0E48\u0E32\u0E07\u0E16\u0E39\u0E01\u0E15\u0E49\u0E2D\u0E07\u0E40\u0E17\u0E48\u0E32\u0E19\u0E31\u0E49\u0E19",
  "message.template.message.deleteTemplate" : "\u0E25\u0E1A\u0E40\u0E17\u0E21\u0E40\u0E1E\u0E25\u0E15\u0E01\u0E32\u0E23\u0E41\u0E08\u0E49\u0E07\u0E40\u0E15\u0E37\u0E2D\u0E19",
  "ccnotification.gatewayconfig.label.maxsmGatewayIsError" : "\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E01\u0E33\u0E2B\u0E19\u0E14\u0E04\u0E48\u0E32\u0E40\u0E01\u0E15\u0E40\u0E27\u0E22\u0E4C SMS \u0E44\u0E14\u0E49\u0E21\u0E32\u0E01\u0E17\u0E35\u0E48\u0E2A\u0E38\u0E14 10 \u0E23\u0E32\u0E22\u0E01\u0E32\u0E23\u0E43\u0E19\u0E1E\u0E37\u0E49\u0E19\u0E17\u0E35\u0E48\u0E1C\u0E39\u0E49\u0E40\u0E0A\u0E48\u0E32\u0E40\u0E14\u0E35\u0E22\u0E27\u0E01\u0E31\u0E19",
  "ccnotification.gatewayconfig.label.deletegatewayfailed" : "\u0E25\u0E49\u0E21\u0E40\u0E2B\u0E25\u0E27\u0E43\u0E19\u0E01\u0E32\u0E23\u0E25\u0E1A\u0E01\u0E32\u0E23\u0E01\u0E33\u0E2B\u0E19\u0E14\u0E04\u0E48\u0E32\u0E40\u0E01\u0E15\u0E40\u0E27\u0E22\u0E4C",
  "message.template.message.titleLengthError" : "\u0E0A\u0E37\u0E48\u0E2D\u0E44\u0E21\u0E48\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E40\u0E01\u0E34\u0E19 200 \u0E2D\u0E31\u0E01\u0E02\u0E23\u0E30\u0E2B\u0E23\u0E37\u0E2D\u0E21\u0E35\u0E2D\u0E31\u0E01\u0E02\u0E23\u0E30\u0E1E\u0E34\u0E40\u0E28\u0E29",
  "message.template.button.delete" : "\u0E25\u0E1A",
  "ccnotification.notifition.sendmessage.enterCCRecipient" : "\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E1C\u0E39\u0E49\u0E23\u0E31\u0E1A Cc",
  "ccnotification.smstemplate.message.var.number" : "\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E15\u0E31\u0E27\u0E41\u0E1B\u0E23\u0E08\u0E4D\u0E32\u0E19\u0E27\u0E19\u0E04\u0E4D\u0E32\u0E2D\u0E18\u0E34\u0E1A\u0E32\u0E22\u0E2B\u0E23\u0E37\u0E2D\u0E04\u0E48\u0E32\u0E40\u0E23\u0E34\u0E48\u0E21\u0E15\u0E49\u0E19\u0E44\u0E21\u0E48\u0E40\u0E1B\u0E47\u0E19\u0E44\u0E1B\u0E15\u0E32\u0E21\u0E02\u0E49\u0E2D\u0E01\u0E4D\u0E32\u0E2B\u0E19\u0E14",
  "ccnotification.gatewayconfig.label.authtitle" : "\u0E15\u0E31\u0E27\u0E1A\u0E48\u0E07\u0E0A\u0E35\u0E49\u0E01\u0E32\u0E23\u0E1E\u0E34\u0E2A\u0E39\u0E08\u0E19\u0E4C\u0E15\u0E31\u0E27\u0E15\u0E19 \u0E15\u0E31\u0E27\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E04\u0E37\u0E2D \u0E08\u0E23\u0E34\u0E07 \u0E41\u0E25\u0E30 \u0E40\u0E17\u0E47\u0E08",
  "ccnotification.common.title.fail" : "\u0E25\u0E49\u0E21\u0E40\u0E2B\u0E25\u0E27",
  "ccnotification.flowcontrol.shortMsgCode" : "\u0E2B\u0E21\u0E32\u0E22\u0E40\u0E25\u0E02\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21 SMS",
  "message.center.message.delete" : "\u0E25\u0E1A",
  "ccnotification.notification.label.hasUplaodtAttachment" : "\u0E40\u0E2D\u0E01\u0E2A\u0E32\u0E23\u0E41\u0E19\u0E1A\u0E17\u0E35\u0E48\u0E2D\u0E31\u0E1B\u0E42\u0E2B\u0E25\u0E14",
  "ccnotification.gatewayconfig.label.receiverPoolMinCounttitle" : "Min Receiving Thread Pools \u0E04\u0E37\u0E2D\u0E15\u0E31\u0E27\u0E40\u0E25\u0E02 \u0E40\u0E0A\u0E48\u0E19 20",
  "message.template.type.system" : "\u0E01\u0E32\u0E23\u0E41\u0E08\u0E31\u0E07\u0E40\u0E15\u0E37\u0E2D\u0E19\u0E02\u0E2D\u0E07\u0E23\u0E30\u0E1A\u0E1A",
  "message.center.nocitiction.interal" : "\u0E1B\u0E23\u0E30\u0E01\u0E32\u0E28\u0E20\u0E32\u0E22\u0E43\u0E19",
  "ccnotification.gatewayconfig.label.destAddrNpi" : "Destination Address Code Scheme",
  "ccnotification.notifition.template.message.charDigit" : "\u0E04\u0E48\u0E32\u0E04\u0E37\u0E2D\u0E2A\u0E15\u0E23\u0E34\u0E07\u0E17\u0E35\u0E48\u0E21\u0E35\u0E15\u0E31\u0E27\u0E40\u0E25\u0E02\u0E41\u0E25\u0E30\u0E15\u0E31\u0E27\u0E2D\u0E31\u0E01\u0E29\u0E23 1 \u0E16\u0E36\u0E07 20 \u0E15\u0E31\u0E27",
  "ccnotification.notifition.sendmessage.cancelSelectall" : "\u0E22\u0E01\u0E40\u0E25\u0E34\u0E01\u0E17\u0E31\u0E49\u0E07\u0E2B\u0E21\u0E14",
  "ccnotification.notification.variable.publicvariable" : "\u0E04\u0E38\u0E13\u0E2A\u0E21\u0E1A\u0E31\u0E15\u0E34\u0E2A\u0E32\u0E18\u0E32\u0E23\u0E13\u0E30",
  "ccnotification.notifition.sendmessage.templateselect" : "\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E40\u0E17\u0E21\u0E40\u0E1E\u0E25\u0E15",
  "ccnotification.gatewayconfig.label.maxMessageLengthtitle" : "\u0E04\u0E27\u0E32\u0E21\u0E22\u0E32\u0E27\u0E21\u0E32\u0E01\u0E17\u0E35\u0E48\u0E2A\u0E38\u0E14\u0E02\u0E2D\u0E07\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21 (byte) \u0E15\u0E31\u0E27\u0E40\u0E25\u0E02 \u0E17\u0E35\u0E48\u0E21\u0E35\u0E01\u0E32\u0E23\u0E23\u0E30\u0E1A\u0E38\u0E43\u0E19\u0E42\u0E1B\u0E23\u0E42\u0E15\u0E04\u0E2D\u0E25 \u0E40\u0E0A\u0E48\u0E19 2048",
  "ccnotification.notifition.template.message.hwsms" : "\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21 HUAWWEI CLOUD SMS",
  "ccnotification.gatewayconfig.label.splitMsgConcatModeIsError" : "\u0E42\u0E2B\u0E21\u0E14\u0E23\u0E27\u0E21\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21\u0E2A\u0E31\u0E49\u0E19\u0E22\u0E32\u0E27 0: \u0E2A\u0E48\u0E27\u0E19\u0E2B\u0E31\u0E27\u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25\u0E1C\u0E39\u0E49\u0E43\u0E0A\u0E49 GSM\, 1: \u0E1E\u0E32\u0E23\u0E32\u0E21\u0E34\u0E40\u0E15\u0E2D\u0E23\u0E4C SMPP",
  "ccnotification.gatewayconfig.label.senderPoolMinCount" : "Min Sending Thread Pools",
  "ccnotification.notifition.template.message.mspsms" : "\u0E40\u0E01\u0E15\u0E40\u0E27\u0E22\u0E4C SMS",
  "ccnotification.notifition.sendmessage.selectbyskill" : "\u0E25\u0E33\u0E14\u0E31\u0E1A\u0E17\u0E31\u0E01\u0E29\u0E30",
  "ccnotification.gatewayconfig.label.senderPoolQueueCapacitytitle" : "Sending Thread Pool Queue Length \u0E04\u0E37\u0E2D\u0E15\u0E31\u0E27\u0E40\u0E25\u0E02 \u0E40\u0E0A\u0E48\u0E19 2000",
  "ccnotification.notifition.variable.createvariable" : "\u0E2A\u0E23\u0E49\u0E32\u0E07",
  "ccnotification.gatewayconfig.label.bindMode" : "\u0E42\u0E2B\u0E21\u0E14\u0E01\u0E32\u0E23\u0E23\u0E31\u0E1A\u0E41\u0E25\u0E30\u0E01\u0E32\u0E23\u0E2A\u0E48\u0E07",
  "ccnotification.gatewayconfig.label.authIsError" : "ID \u0E01\u0E32\u0E23\u0E1E\u0E34\u0E2A\u0E39\u0E08\u0E19\u0E4C\u0E15\u0E31\u0E27\u0E15\u0E19\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E40\u0E1B\u0E47\u0E19\u0E08\u0E23\u0E34\u0E07\u0E2B\u0E23\u0E37\u0E2D\u0E40\u0E17\u0E47\u0E08\u0E44\u0E14\u0E49\u0E40\u0E17\u0E48\u0E32\u0E19\u0E31\u0E49\u0E19",
  "ccnotification.gatewayconfig.label.smHost" : "\u0E17\u0E35\u0E48\u0E2D\u0E22\u0E39\u0E48 SMG",
  "ccnotification.notification.recmessagerouterconfig.placeholder.receiver" : "\u0E42\u0E1B\u0E23\u0E14\u0E43\u0E2A\u0E48\u0E17\u0E35\u0E48\u0E2D\u0E22\u0E39\u0E48\u0E02\u0E2D\u0E07\u0E1C\u0E39\u0E49\u0E23\u0E31\u0E1A",
  "ccnotification.title.flowcontrol.receiveSmsFlag" : "\u0E23\u0E31\u0E1A\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21 MO SMS",
  "ccnotification.agent.operation.searchworkno" : "\u0E42\u0E1B\u0E23\u0E14\u0E01\u0E23\u0E2D\u0E01 ID \u0E1E\u0E19\u0E31\u0E01\u0E07\u0E32\u0E19",
  "ccnotification.notification.label.upload" : "\u0E2D\u0E31\u0E1E\u0E42\u0E2B\u0E25\u0E14",
  "ccnotification.gatewayconfig.label.addgateway" : "\u0E2A\u0E23\u0E49\u0E32\u0E07\u0E40\u0E01\u0E15\u0E40\u0E27\u0E22\u0E4C",
  "ccnotification.flowcontrol.getwayset" : "\u0E01\u0E33\u0E2B\u0E19\u0E14",
  "ccnotification.gatewayconfig.label.auth" : "\u0E01\u0E32\u0E23\u0E1E\u0E34\u0E2A\u0E39\u0E08\u0E19\u0E4C\u0E15\u0E31\u0E27\u0E15\u0E19",
  "ccnotification.notifition.sendmessage.sendexception" : "\u0E02\u0E49\u0E2D\u0E1C\u0E34\u0E14\u0E1E\u0E25\u0E32\u0E14\u0E43\u0E19\u0E01\u0E32\u0E23\u0E2A\u0E48\u0E07",
  "ccnotification.gatewayconfig.label.willDeletegateway" : "\u0E04\u0E38\u0E13\u0E41\u0E19\u0E48\u0E43\u0E08\u0E27\u0E48\u0E32\u0E15\u0E49\u0E2D\u0E07\u0E01\u0E32\u0E23\u0E25\u0E1A\u0E01\u0E32\u0E23\u0E01\u0E33\u0E2B\u0E19\u0E14\u0E04\u0E48\u0E32\u0E40\u0E01\u0E15\u0E40\u0E27\u0E22\u0E4C\u0E17\u0E35\u0E48\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E43\u0E0A\u0E48\u0E2B\u0E23\u0E37\u0E2D\u0E44\u0E21\u0E48?",
  "ccnotification.notification.recmessagerouterconfig.defaultsendemail" : "\u0E04\u0E48\u0E32\u0E40\u0E23\u0E34\u0E48\u0E21\u0E15\u0E49\u0E19\u0E01\u0E32\u0E23\u0E2A\u0E48\u0E07\u0E2D\u0E35\u0E40\u0E21\u0E25",
  "ccnotification.notification.recmessagerouterconfig.mediaType" : "\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E21\u0E35\u0E40\u0E14\u0E35\u0E22",
  "ccnotification.notification.recmessagerouterconfig.intelligentOrder" : "\u0E01\u0E32\u0E23\u0E2A\u0E23\u0E49\u0E32\u0E07\u0E40\u0E04\u0E2A\u0E2D\u0E31\u0E08\u0E09\u0E23\u0E34\u0E22\u0E30",
  "message.template.message.selectRecipient" : "\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E1C\u0E39\u0E49\u0E23\u0E31\u0E1A",
  "ccnotification.notifition.sendmessage.receiverPhoneIsNull" : "\u0E1C\u0E39\u0E49\u0E23\u0E31\u0E1A\u0E2B\u0E23\u0E37\u0E2D\u0E2B\u0E21\u0E32\u0E22\u0E40\u0E25\u0E02\u0E1C\u0E39\u0E49\u0E23\u0E31\u0E1A\u0E27\u0E48\u0E32\u0E07\u0E40\u0E1B\u0E25\u0E48\u0E32",
  "ccnotification.gatewayconfig.label.sourceAddrNpititle" : "Destination Address Code Scheme \u0E04\u0E37\u0E2D\u0E15\u0E31\u0E27\u0E40\u0E25\u0E02",
  "ccnotification.notifition.sendmessage.send" : "\u0E15\u0E01\u0E25\u0E07",
  "ccnotification.notifition.sendmessage.isSendallSuccess" : "\u0E2A\u0E48\u0E07\u0E41\u0E25\u0E49\u0E27 (\u0E2A\u0E33\u0E40\u0E23\u0E47\u0E08)",
  "ccnotification.gatewayconfig.label.registeredDelivery" : "\u0E2A\u0E48\u0E07\u0E04\u0E37\u0E19\u0E23\u0E32\u0E22\u0E07\u0E32\u0E19\u0E22\u0E37\u0E19\u0E22\u0E31\u0E19\u0E2A\u0E16\u0E32\u0E19\u0E30",
  "message.center.nocitiction.sender" : "\u0E1C\u0E39\u0E49\u0E2A\u0E48\u0E07",
  "ccnotification.notifition.sendmessage.senddate" : "\u0E27\u0E31\u0E19\u0E17\u0E35\u0E48\u0E2A\u0E48\u0E07",
  "ccnotification.notification.systemmonitor.modifyTime" : "\u0E41\u0E01\u0E49\u0E44\u0E02\u0E40\u0E21\u0E37\u0E48\u0E2D",
  "ccnotification.notifition.sendmessage.isSendallFail" : "\u0E2A\u0E48\u0E07\u0E41\u0E25\u0E49\u0E27 (\u0E25\u0E49\u0E21\u0E40\u0E2B\u0E25\u0E27)",
  "ccnotification.notifition.template.placeholder.hwtemplateid" : "\u0E01\u0E23\u0E2D\u0E01 ID \u0E40\u0E17\u0E21\u0E40\u0E1E\u0E25\u0E15 HUAWEI CLOUD",
  "ccnotification.notifition.template.message.phone" : "\u0E04\u0E48\u0E32\u0E04\u0E37\u0E2D\u0E2A\u0E15\u0E23\u0E34\u0E07\u0E17\u0E35\u0E48\u0E21\u0E35\u0E15\u0E31\u0E27\u0E40\u0E25\u0E02 1 \u0E16\u0E36\u0E07 15 \u0E2B\u0E25\u0E31\u0E01",
  "ccnotification.notifition.sendmessage.unSend" : "\u0E01\u0E33\u0E25\u0E31\u0E07\u0E2A\u0E48\u0E07",
  "ccnotification.notifition.variable.searchVariableName" : "\u0E01\u0E23\u0E2D\u0E01\u0E0A\u0E37\u0E48\u0E2D\u0E15\u0E31\u0E27\u0E41\u0E1B\u0E23",
  "ccnotification.gatewayconfig.label.configTypeIsError" : "\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E01\u0E32\u0E23\u0E01\u0E33\u0E2B\u0E19\u0E14\u0E04\u0E48\u0E32\u0E44\u0E21\u0E48\u0E16\u0E39\u0E01\u0E15\u0E49\u0E2D\u0E07 \u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E01\u0E32\u0E23\u0E01\u0E33\u0E2B\u0E19\u0E14\u0E04\u0E48\u0E32\u0E20\u0E32\u0E22\u0E43\u0E19\u0E02\u0E2D\u0E07\u0E1E\u0E32\u0E23\u0E32\u0E21\u0E34\u0E40\u0E15\u0E2D\u0E23\u0E4C\u0E44\u0E21\u0E48\u0E2A\u0E2D\u0E14\u0E04\u0E25\u0E49\u0E2D\u0E07",
  "ccnotification.notifition.sendmessage.bulletin" : "\u0E1B\u0E23\u0E30\u0E01\u0E32\u0E28",
  "ccnotification.smshuawei.message.specialCharacters" : "\u0E44\u0E21\u0E48\u0E2D\u0E19\u0E38\u0E0D\u0E32\u0E15\u0E43\u0E2B\u0E49\u0E43\u0E0A\u0E49\u0E2D\u0E31\u0E01\u0E02\u0E23\u0E30\u0E1E\u0E34\u0E40\u0E28\u0E29",
  "ccnotification.notifition.sendmessage.bulletinType" : "\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E1B\u0E23\u0E30\u0E01\u0E32\u0E28",
  "ccnotification.notifition.sendmessage.enterBulletinType" : "\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E1B\u0E23\u0E30\u0E01\u0E32\u0E28",
  "ccnotification.notifition.sendmessage.priority" : "\u0E23\u0E30\u0E14\u0E31\u0E1A\u0E04\u0E27\u0E32\u0E21\u0E2A\u0E33\u0E04\u0E31\u0E0D",
  "ccnotification.notifition.sendmessage.priorityGeneral" : "\u0E15\u0E48\u0E33",
  "ccnotification.notifition.sendmessage.priorityExtraUrgent" : "\u0E2A\u0E39\u0E07",
  "ccnotification.notifition.sendmessage.priorityUrgent" : "\u0E1B\u0E32\u0E19\u0E01\u0E25\u0E32\u0E07",
  "ccnotification.notifition.sendmessage.expireTime" : "\u0E2B\u0E21\u0E14\u0E2D\u0E32\u0E22\u0E38\u0E40\u0E21\u0E37\u0E48\u0E2D",
  "ccnotification.notifition.sendmessage.priorityFault" : "\u0E27\u0E34\u0E01\u0E24\u0E15",
  "ccnotification.notifition.sendmessage.bulletinTypeName" : "\u0E0A\u0E37\u0E48\u0E2D\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E1B\u0E23\u0E30\u0E01\u0E32\u0E28",
  "ccnotification.notifition.sendmessage.inputBulletinType" : "\u0E01\u0E23\u0E2D\u0E01\u0E0A\u0E37\u0E48\u0E2D\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E1B\u0E23\u0E30\u0E01\u0E32\u0E28",
  "ccnotification.gatewayconfig.label.dataCodingtitle" : "0: \u0E2A\u0E15\u0E23\u0E34\u0E07 ASCII 4: \u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25\u0E40\u0E25\u0E02\u0E10\u0E32\u0E19\u0E2A\u0E2D\u0E07 8: \u0E01\u0E32\u0E23\u0E40\u0E02\u0E49\u0E32\u0E23\u0E2B\u0E31\u0E2A UCS2",
  "ccnotification.notifition.sendmessage.invalidSect" : "\u0E2A\u0E48\u0E27\u0E19\u0E40\u0E27\u0E25\u0E32\u0E2B\u0E21\u0E14\u0E2D\u0E32\u0E22\u0E38",
  "ccnotification.notifition.sendmessage.internal" : "\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21\u0E20\u0E32\u0E22\u0E43\u0E19",
  "ccnotification.notification.systemmonitor.gatewayName" : "\u0E0A\u0E37\u0E48\u0E2D\u0E40\u0E01\u0E15\u0E40\u0E27\u0E22\u0E4C",
  "ccnotification.notifition.sendmessage.recipient" : "\u0E1C\u0E39\u0E49\u0E23\u0E31\u0E1A",
  "ccnotification.gatewayconfig.label.errorstylenumber" : "\u0E23\u0E39\u0E1B\u0E41\u0E1A\u0E1A\u0E2B\u0E21\u0E32\u0E22\u0E40\u0E25\u0E02\u0E44\u0E21\u0E48\u0E16\u0E39\u0E01\u0E15\u0E49\u0E2D\u0E07",
  "ccnotification.gatewayconfig.label.dataCoding" : "\u0E23\u0E39\u0E1B\u0E41\u0E1A\u0E1A\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21",
  "ccnotification.notifition.sendmessage.enterRecipientGroup" : "\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E01\u0E25\u0E38\u0E48\u0E21\u0E1C\u0E39\u0E49\u0E23\u0E31\u0E1A",
  "ccnotification.gatewayconfig.label.certScene" : "\u0E2A\u0E16\u0E32\u0E19\u0E01\u0E32\u0E23\u0E13\u0E4C\u0E01\u0E32\u0E23\u0E19\u0E33\u0E44\u0E1B\u0E43\u0E0A\u0E49",
  "ccnotification.common.button.create" : "\u0E2A\u0E23\u0E49\u0E32\u0E07",
  "ccnotification.notifition.sendmessage.recipientGroup" : "\u0E01\u0E25\u0E38\u0E48\u0E21\u0E1C\u0E39\u0E49\u0E23\u0E31\u0E1A",
  "message.template.message.nameDuplicationError" : "\u0E17\u0E33\u0E0B\u0E49\u0E33\u0E0A\u0E37\u0E48\u0E2D\u0E40\u0E17\u0E21\u0E40\u0E1E\u0E25\u0E15",
  "ccnotification.notifition.sendmessage.sendTimeFailed" : "\u0E40\u0E27\u0E25\u0E32\u0E2A\u0E48\u0E07\u0E0A\u0E49\u0E32\u0E01\u0E27\u0E48\u0E32\u0E40\u0E27\u0E25\u0E32\u0E1B\u0E31\u0E08\u0E08\u0E38\u0E1A\u0E31\u0E19",
  "ccnotification.gatewayconfig.label.addressRange" : "\u0E0A\u0E48\u0E27\u0E07\u0E17\u0E35\u0E48\u0E2D\u0E22\u0E39\u0E48 ESME",
  "ccnotification.notifition.sendmessage.sendtimebeforenow" : "\u0E40\u0E27\u0E25\u0E32\u0E2A\u0E48\u0E07\u0E17\u0E35\u0E48\u0E01\u0E33\u0E2B\u0E19\u0E14\u0E40\u0E27\u0E25\u0E32\u0E44\u0E27\u0E49\u0E15\u0E49\u0E2D\u0E07\u0E0A\u0E49\u0E32\u0E01\u0E27\u0E48\u0E32\u0E40\u0E27\u0E25\u0E32\u0E1B\u0E31\u0E08\u0E08\u0E38\u0E1A\u0E31\u0E19 5 \u0E19\u0E32\u0E17\u0E35",
  "ccnotification.flowcontrol.emailThreshold" : "\u0E04\u0E48\u0E32\u0E08\u0E33\u0E01\u0E31\u0E14\u0E23\u0E32\u0E22\u0E27\u0E31\u0E19\u0E02\u0E2D\u0E07\u0E2D\u0E35\u0E40\u0E21\u0E25",
  "message.template.message.contentError" : "\u0E40\u0E19\u0E37\u0E49\u0E2D\u0E2B\u0E32\u0E44\u0E21\u0E48\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E1B\u0E25\u0E48\u0E2D\u0E22\u0E27\u0E48\u0E32\u0E07\u0E44\u0E27\u0E49",
  "message.template.message.contentLengthError" : "????????????????????????????????????????",
  "message.center.nocitiction.inbox" : "\u0E01\u0E25\u0E48\u0E2D\u0E07\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21\u0E02\u0E32\u0E40\u0E02\u0E49\u0E32",
  "ccnotification.smshuawei.label.appAdress" : "\u0E17\u0E35\u0E48\u0E2D\u0E22\u0E39\u0E48\u0E2A\u0E33\u0E2B\u0E23\u0E31\u0E1A\u0E40\u0E02\u0E49\u0E32\u0E16\u0E36\u0E07\u0E41\u0E2D\u0E1B",
  "ccnotification.agent.pageurls.isconfreset" : "\u0E04\u0E38\u0E13\u0E41\u0E19\u0E48\u0E43\u0E08\u0E27\u0E48\u0E32\u0E15\u0E49\u0E2D\u0E07\u0E01\u0E32\u0E23\u0E15\u0E31\u0E49\u0E07\u0E43\u0E2B\u0E21\u0E48\u0E43\u0E0A\u0E48\u0E2B\u0E23\u0E37\u0E2D\u0E44\u0E21\u0E48?",
  "ccnotification.gatewayconfig.label.SSLEncryptionMode" : "\u0E42\u0E2B\u0E21\u0E14\u0E40\u0E02\u0E49\u0E32\u0E23\u0E2B\u0E31\u0E2A",
  "ccnotification.gatewayconfig.label.addgatewayconfig" : "\u0E40\u0E1E\u0E34\u0E48\u0E21\u0E01\u0E32\u0E23\u0E01\u0E33\u0E2B\u0E19\u0E14\u0E04\u0E48\u0E32\u0E40\u0E01\u0E15\u0E40\u0E27\u0E22\u0E4C",
  "ccnotification.gatewayconfig.label.defaultsendno" : "\u0E2B\u0E21\u0E32\u0E22\u0E40\u0E25\u0E02\u0E01\u0E32\u0E23\u0E2A\u0E48\u0E07\u0E40\u0E23\u0E34\u0E48\u0E21\u0E15\u0E49\u0E19",
  "ccnotification.gatewayconfig.label.heartbeatNoRespCount" : "\u0E08\u0E33\u0E19\u0E27\u0E19\u0E04\u0E23\u0E31\u0E49\u0E07\u0E43\u0E19\u0E01\u0E32\u0E23\u0E40\u0E0A\u0E37\u0E48\u0E2D\u0E21\u0E15\u0E48\u0E2D\u0E2A\u0E39\u0E07\u0E2A\u0E38\u0E14\u0E40\u0E21\u0E37\u0E48\u0E2D\u0E44\u0E21\u0E48\u0E21\u0E35\u0E01\u0E32\u0E23\u0E15\u0E2D\u0E1A\u0E2A\u0E19\u0E2D\u0E07\u0E02\u0E2D\u0E07\u0E01\u0E32\u0E23\u0E40\u0E15\u0E49\u0E19\u0E02\u0E2D\u0E07\u0E2B\u0E31\u0E27\u0E43\u0E08",
  "ccnotification.gatewayconfig.label.RcvAndSendMode" : "\u0E42\u0E2B\u0E21\u0E14 TX / RX",
  "ccnotification.template.message.delete" : "\u0E21\u0E35\u0E01\u0E32\u0E23\u0E2D\u0E49\u0E32\u0E07\u0E2D\u0E34\u0E07\u0E40\u0E17\u0E21\u0E40\u0E1E\u0E25\u0E15\u0E41\u0E25\u0E30\u0E44\u0E21\u0E48\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E25\u0E1A\u0E44\u0E14\u0E49",
  "ccnotification.gatewayconfig.label.senderPoolQueueCapacity" : "Sending Thread Pool Queue Length",
  "ccnotification.gatewayconfig.label.replaceIfPresentFlagtitle" : "0: \u0E41\u0E17\u0E19\u0E17\u0E35\u0E48 1: \u0E44\u0E21\u0E48\u0E41\u0E17\u0E19\u0E17\u0E35\u0E48 2-255:",
  "message.center.label.search" : "\u0E04\u0E49\u0E19\u0E2B\u0E32",
  "message.center.label.allSelect" : "\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E17\u0E31\u0E49\u0E07\u0E2B\u0E21\u0E14",
  "ccnotification.notifition.sendmessage.ccRecipient" : "Cc",
  "ccnotification.notifition.sendmessage.sendfailed" : "\u0E01\u0E32\u0E23\u0E2A\u0E48\u0E07\u0E25\u0E49\u0E21\u0E40\u0E2B\u0E25\u0E27",
  "ccnotification.notification.variable.templateselect" : "\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E40\u0E17\u0E21\u0E40\u0E1E\u0E25\u0E15",
  "message.template.message.nameError" : "\u0E0A\u0E37\u0E48\u0E2D\u0E40\u0E17\u0E21\u0E40\u0E1E\u0E25\u0E15\u0E44\u0E21\u0E48\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E1B\u0E25\u0E48\u0E2D\u0E22\u0E27\u0E48\u0E32\u0E07\u0E44\u0E27\u0E49\u0E2B\u0E23\u0E37\u0E2D\u0E40\u0E01\u0E34\u0E19 200 \u0E2D\u0E31\u0E01\u0E02\u0E23\u0E30",
  "message.center.label.select" : "\u0E15\u0E31\u0E27\u0E01\u0E23\u0E2D\u0E07",
  "ccnotification.flowcontrol.setFlowType" : "\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E01\u0E32\u0E23\u0E01\u0E33\u0E2B\u0E19\u0E14\u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25",
  "ccnotification.gatewayconfig.label.configNameIsError" : "\u0E0A\u0E37\u0E48\u0E2D\u0E01\u0E32\u0E23\u0E15\u0E31\u0E49\u0E07\u0E04\u0E48\u0E32\u0E15\u0E49\u0E2D\u0E07\u0E21\u0E35\u0E15\u0E31\u0E27\u0E40\u0E25\u0E02\u0E2B\u0E23\u0E37\u0E2D\u0E15\u0E31\u0E27\u0E2D\u0E31\u0E01\u0E29\u0E23\u0E21\u0E32\u0E01\u0E17\u0E35\u0E48\u0E2A\u0E38\u0E14 10 \u0E2B\u0E25\u0E31\u0E01",
  "ccnotification.flowcontrol.modifySuccess" : "\u0E01\u0E32\u0E23\u0E01\u0E33\u0E2B\u0E19\u0E14\u0E04\u0E48\u0E32\u0E01\u0E32\u0E23\u0E41\u0E08\u0E49\u0E07\u0E40\u0E15\u0E37\u0E2D\u0E19\u0E44\u0E14\u0E49\u0E23\u0E31\u0E1A\u0E01\u0E32\u0E23\u0E1B\u0E23\u0E31\u0E1A\u0E40\u0E1B\u0E25\u0E35\u0E48\u0E22\u0E19\u0E41\u0E25\u0E49\u0E27",
  "ccnotification.gatewayconfig.label.lastmodifytime" : "\u0E1B\u0E23\u0E31\u0E1A\u0E40\u0E1B\u0E25\u0E35\u0E48\u0E22\u0E19\u0E25\u0E48\u0E32\u0E2A\u0E38\u0E14\u0E40\u0E21\u0E37\u0E48\u0E2D",
  "message.center.message.willDeleteMessages" : "\u0E04\u0E38\u0E13\u0E41\u0E19\u0E48\u0E43\u0E08\u0E27\u0E48\u0E32\u0E15\u0E49\u0E2D\u0E07\u0E01\u0E32\u0E23\u0E25\u0E1A\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21\u0E17\u0E35\u0E48\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E43\u0E0A\u0E48\u0E2B\u0E23\u0E37\u0E2D\u0E44\u0E21\u0E48?",
  "message.template.button.view" : "\u0E14\u0E39",
  "ccnotification.gatewayconfig.label.certCode" : "\u0E23\u0E2B\u0E31\u0E2A\u0E43\u0E1A\u0E23\u0E31\u0E1A\u0E23\u0E2D\u0E07",
  "ccnotification.caseserver.fail.categoryNotExist" : "\u0E2B\u0E21\u0E27\u0E14\u0E2B\u0E21\u0E39\u0E48\u0E01\u0E23\u0E13\u0E35\u0E17\u0E35\u0E48\u0E44\u0E21\u0E48\u0E21\u0E35\u0E2D\u0E22\u0E39\u0E48\u0E08\u0E23\u0E34\u0E07",
  "message.template.mediaType.shortmessage" : "SMS",
  "ccnotification.gatewayconfig.label.inputCertCode" : "\u0E01\u0E23\u0E2D\u0E01\u0E23\u0E2B\u0E31\u0E2A\u0E43\u0E1A\u0E23\u0E31\u0E1A\u0E23\u0E2D\u0E07",
  "message.template.message.descriptionLengthError" : "\u0E04\u0E33\u0E2D\u0E18\u0E34\u0E1A\u0E32\u0E22\u0E40\u0E01\u0E34\u0E19\u0E02\u0E35\u0E14\u0E08\u0E33\u0E01\u0E31\u0E14",
  "ccnotification.gatewayconfig.label.SSLEncryptionModetitle" : "\u0E42\u0E2B\u0E21\u0E14\u0E01\u0E32\u0E23\u0E40\u0E02\u0E49\u0E32\u0E23\u0E2B\u0E31\u0E2A\, NONE: \u0E01\u0E32\u0E23\u0E2A\u0E48\u0E07\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21\u0E18\u0E23\u0E23\u0E21\u0E14\u0E32 (\u0E1E\u0E2D\u0E23\u0E4C\u0E15\u0E40\u0E23\u0E34\u0E48\u0E21\u0E15\u0E49\u0E19: 25) TLS: \u0E01\u0E32\u0E23\u0E2A\u0E48\u0E07\u0E41\u0E1A\u0E1A\u0E40\u0E02\u0E49\u0E32\u0E23\u0E2B\u0E31\u0E2A\u0E42\u0E14\u0E22\u0E43\u0E0A\u0E49 TLS (\u0E1E\u0E2D\u0E23\u0E4C\u0E15\u0E40\u0E23\u0E34\u0E48\u0E21\u0E15\u0E49\u0E19: 465) STARTTLS: \u0E01\u0E32\u0E23\u0E2A\u0E48\u0E07\u0E41\u0E1A\u0E1A\u0E40\u0E02\u0E49\u0E32\u0E23\u0E2B\u0E31\u0E2A\u0E42\u0E14\u0E22\u0E43\u0E0A\u0E49\u0E04\u0E33\u0E2A\u0E31\u0E48\u0E07 STARTTLS (\u0E1E\u0E2D\u0E23\u0E4C\u0E15\u0E40\u0E23\u0E34\u0E48\u0E21\u0E15\u0E49\u0E19: 587)",
  "ccnotification.notification.label.uploadAttachmentSuccess" : "\u0E2D\u0E31\u0E1E\u0E42\u0E2B\u0E25\u0E14\u0E44\u0E1F\u0E25\u0E4C\u0E41\u0E19\u0E1A\u0E2A\u0E33\u0E40\u0E23\u0E47\u0E08",
  "ccnotification.gatewayconfig.label.mailHost" : "\u0E17\u0E35\u0E48\u0E2D\u0E22\u0E39\u0E48\u0E41\u0E21\u0E48\u0E02\u0E48\u0E32\u0E22",
  "ccnotification.center.message.deleteAllRead" : "\u0E04\u0E38\u0E13\u0E41\u0E19\u0E48\u0E43\u0E08\u0E27\u0E48\u0E32\u0E15\u0E49\u0E2D\u0E07\u0E01\u0E32\u0E23\u0E25\u0E1A\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21\u0E20\u0E32\u0E22\u0E43\u0E19\u0E17\u0E35\u0E48\u0E2D\u0E48\u0E32\u0E19\u0E41\u0E25\u0E49\u0E27\u0E17\u0E31\u0E49\u0E07\u0E2B\u0E21\u0E14\u0E43\u0E0A\u0E48\u0E2B\u0E23\u0E37\u0E2D\u0E44\u0E21\u0E48?",
  "ccnotification.flowcontrol.setThreshold" : "\u0E15\u0E31\u0E49\u0E07\u0E04\u0E48\u0E32\u0E02\u0E35\u0E14\u0E40\u0E23\u0E34\u0E48\u0E21\u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25",
  "ccnotification.gatewayconfig.label.messageFlowControlMessageDropPolicytitle" : "\u0E1B\u0E31\u0E08\u0E08\u0E38\u0E1A\u0E31\u0E19 \u0E23\u0E2D\u0E07\u0E23\u0E31\u0E1A\u0E40\u0E09\u0E1E\u0E32\u0E30\u0E40\u0E1E\u0E34\u0E01\u0E40\u0E09\u0E22\u0E40\u0E17\u0E48\u0E32\u0E19\u0E31\u0E49\u0E19",
  "ccnotification.gatewayconfig.label.dataCodingIsError" : "\u0E23\u0E39\u0E1B\u0E41\u0E1A\u0E1A\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21 SMS \u0E15\u0E49\u0E2D\u0E07\u0E40\u0E1B\u0E47\u0E19 0: \u0E2A\u0E15\u0E23\u0E34\u0E07 ASCII\, 4: \u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25\u0E40\u0E25\u0E02\u0E10\u0E32\u0E19\u0E2A\u0E2D\u0E07\, 8: \u0E23\u0E2B\u0E31\u0E2A UCS2",
  "ccnotification.notifition.sendmessage.templateselected" : "\u0E01\u0E32\u0E23\u0E41\u0E08\u0E49\u0E07\u0E40\u0E15\u0E37\u0E2D\u0E19\u0E19\u0E35\u0E49\u0E2D\u0E49\u0E32\u0E07\u0E2D\u0E34\u0E07\u0E16\u0E36\u0E07",
  "message.template.message.modifyTemplateFail" : "\u0E25\u0E49\u0E21\u0E40\u0E2B\u0E25\u0E27\u0E43\u0E01\u0E32\u0E23\u0E1B\u0E23\u0E31\u0E1A\u0E40\u0E1B\u0E25\u0E35\u0E48\u0E22\u0E19\u0E40\u0E17\u0E21\u0E40\u0E1E\u0E25\u0E15\u0E01\u0E32\u0E23\u0E41\u0E08\u0E49\u0E07\u0E40\u0E15\u0E37\u0E2D\u0E19",
  "ccnotification.notification.variable.nameexist" : "\u0E21\u0E35\u0E0A\u0E37\u0E48\u0E2D\u0E15\u0E31\u0E27\u0E41\u0E1B\u0E23\u0E2D\u0E22\u0E39\u0E48\u0E41\u0E25\u0E49\u0E27",
  "ccnotification.notification.label.uploadPermissionFailed" : "\u0E01\u0E32\u0E23\u0E15\u0E23\u0E27\u0E08\u0E40\u0E0A\u0E47\u0E04\u0E2A\u0E34\u0E17\u0E18\u0E34\u0E4C\u0E43\u0E19\u0E01\u0E32\u0E23\u0E2D\u0E31\u0E1E\u0E42\u0E2B\u0E25\u0E14\u0E44\u0E1F\u0E25\u0E4C\u0E41\u0E19\u0E1A\u0E2D\u0E35\u0E40\u0E21\u0E25\u0E25\u0E49\u0E21\u0E40\u0E2B\u0E25\u0E27",
  "message.template.message.inputTitle" : "\u0E01\u0E23\u0E2D\u0E01\u0E0A\u0E37\u0E48\u0E2D",
  "ccnotification.notifition.sendmessage.preview" : "\u0E14\u0E39\u0E15\u0E31\u0E27\u0E2D\u0E22\u0E48\u0E32\u0E07",
  "ccnotification.smstemplate.message.number" : "\u0E15\u0E31\u0E27\u0E40\u0E25\u0E02\u0E21\u0E32\u0E01\u0E17\u0E35\u0E48\u0E2A\u0E38\u0E14 10 \u0E2B\u0E25\u0E31\u0E01",
  "ccnotification.common.spec.message" : "\u0E44\u0E21\u0E48\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E01\u0E23\u0E2D\u0E01\u0E2D\u0E31\u0E01\u0E02\u0E23\u0E30\u0E1E\u0E34\u0E40\u0E28\u0E29\u0E44\u0E14\u0E49",
  "ccnotification.notification.recmessagerouterconfig.receiveAddress" : "\u0E17\u0E35\u0E48\u0E2D\u0E22\u0E39\u0E48\u0E1C\u0E39\u0E49\u0E23\u0E31\u0E1A",
  "ccnotification.gatewayconfig.label.systemId" : "\u0E0A\u0E37\u0E48\u0E2D\u0E2A\u0E33\u0E2B\u0E23\u0E31\u0E1A\u0E40\u0E02\u0E49\u0E32\u0E2A\u0E39\u0E48\u0E23\u0E30\u0E1A\u0E1A",
  "ccnotification.common.title.confirm" : "\u0E22\u0E37\u0E19\u0E22\u0E31\u0E19",
  "message.template.message.messageTemplateId" : "ID \u0E40\u0E17\u0E21\u0E40\u0E1E\u0E25\u0E15",
  "ccnotification.common.button.ok" : "OK",
  "ccnotification.notification.systemmonitor.status" : "\u0E2A\u0E16\u0E32\u0E19\u0E30",
  "ccnotification.gatewayconfig.label.messageFlowControlMaxCapsForRecv" : "\u0E2D\u0E31\u0E15\u0E23\u0E32\u0E01\u0E32\u0E23\u0E23\u0E31\u0E1A\u0E2A\u0E39\u0E07\u0E2A\u0E38\u0E14",
  "ccnotification.notifition.sendmessage.notifitiontitle" : "\u0E01\u0E23\u0E2D\u0E01\u0E0A\u0E37\u0E48\u0E2D\u0E2B\u0E31\u0E27\u0E02\u0E49\u0E2D",
  "ccnotification.gatewayconfig.label.smDefaultMsgId" : "ID \u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21\u0E40\u0E23\u0E34\u0E48\u0E21\u0E15\u0E49\u0E19",
  "ccnotification.common.title.success" : "\u0E2A\u0E33\u0E40\u0E23\u0E47\u0E08",
  "ccnotification.notification.recmessagerouterconfig.inbox" : "\u0E01\u0E25\u0E48\u0E2D\u0E07\u0E08\u0E14\u0E2B\u0E21\u0E32\u0E22\u0E40\u0E02\u0E49\u0E32",
  "ccnotification.caseserver.fail.serviceException" : "\u0E1A\u0E23\u0E34\u0E01\u0E32\u0E23\u0E1C\u0E34\u0E14\u0E1B\u0E01\u0E15\u0E34",
  "message.template.mediaType.email" : "\u0E2D\u0E35\u0E40\u0E21\u0E25",
  "ccnotification.gatewayconfig.label.esmClass" : "\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25",
  "ccnotification.notifition.variable.selectvariable" : "\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E15\u0E31\u0E27\u0E41\u0E1B\u0E23",
  "ccnotification.smshuawei.label.signatureChannelId" : "\u0E0A\u0E48\u0E2D\u0E07\u0E43\u0E2A\u0E48\u0E25\u0E32\u0E22\u0E40\u0E0B\u0E47\u0E19",
  "ccnotification.gatewayconfig.label.defaultsendnotitle" : "กฎนําหน้าของหมายเลขผู้ส่ง ตัวอย่างเช่น หากกําหนดค่า 130 ตัวเลขที่ขึ้นต้นด้วย 130 จะถูกจับคู่ จํานวนหลายตัวคั่นด้วยอัฒภาค (;).",
  "ccnotification.gatewayconfig.label.recFlowControllertitle" : "\u0E0A\u0E37\u0E48\u0E2D\u0E02\u0E2D\u0E07\u0E15\u0E31\u0E27\u0E04\u0E27\u0E1A\u0E04\u0E38\u0E21\u0E01\u0E32\u0E23\u0E23\u0E31\u0E1A\u0E2A\u0E48\u0E07\u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25\u0E17\u0E35\u0E48\u0E04\u0E27\u0E1A\u0E04\u0E38\u0E21\u0E2D\u0E31\u0E15\u0E23\u0E32\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21\u0E2D\u0E31\u0E1E\u0E25\u0E34\u0E07\u0E01\u0E4C",
  "ccnotification.notifition.variable.not.exist" : "\u0E44\u0E21\u0E48\u0E21\u0E35!",
  "ccnotification.gatewayconfig.label.sourceAddrTon" : "\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E23\u0E2B\u0E31\u0E2A\u0E17\u0E35\u0E48\u0E2D\u0E22\u0E39\u0E48\u0E15\u0E49\u0E19\u0E17\u0E32\u0E07",
  "ccnotification.notifition.sendmessage.enterrecipient" : "\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E1C\u0E39\u0E49\u0E23\u0E31\u0E1A",
  "ccnotification.flowcontrol.modifyFailed" : "\u0E25\u0E49\u0E21\u0E40\u0E2B\u0E25\u0E27\u0E43\u0E19\u0E01\u0E32\u0E23\u0E1B\u0E23\u0E31\u0E1A\u0E40\u0E1B\u0E25\u0E35\u0E48\u0E22\u0E19\u0E01\u0E32\u0E23\u0E01\u0E33\u0E2B\u0E19\u0E14\u0E04\u0E48\u0E32\u0E01\u0E32\u0E23\u0E41\u0E08\u0E49\u0E07\u0E40\u0E15\u0E37\u0E2D\u0E19 \u0E15\u0E23\u0E27\u0E08\u0E40\u0E0A\u0E47\u0E04\u0E1E\u0E32\u0E23\u0E32\u0E21\u0E34\u0E40\u0E15\u0E2D\u0E23\u0E4C\u0E2D\u0E34\u0E19\u0E1E\u0E38\u0E15\u0E2B\u0E23\u0E37\u0E2D\u0E40\u0E04\u0E23\u0E37\u0E2D\u0E02\u0E48\u0E32\u0E22",
  "message.center.label.senderName" : "\u0E1C\u0E39\u0E49\u0E2A\u0E48\u0E07",
  "message.template.message.mediaType" : "\u0E42\u0E2B\u0E21\u0E14\u0E2A\u0E48\u0E07\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21",
  "ccnotification.gatewayconfig.label.destAddrNpititle" : "Destination Address Code Scheme \u0E04\u0E37\u0E2D\u0E15\u0E31\u0E27\u0E40\u0E25\u0E02",
  "ccnotification.notification.label.nameContainSpecialChar" : "\u0E0A\u0E37\u0E48\u0E2D\u0E44\u0E1F\u0E25\u0E4C\u0E41\u0E19\u0E1A\u0E2D\u0E35\u0E40\u0E21\u0E25\u0E21\u0E35\u0E2D\u0E31\u0E01\u0E02\u0E23\u0E30\u0E1E\u0E34\u0E40\u0E28\u0E29",
  "ccnotification.sms.huawei" : "Huawei SMS",
  "ccnotification.notifition.sendmessage.sendsuccess" : "\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21\u0E16\u0E39\u0E01\u0E2A\u0E48\u0E07\u0E41\u0E25\u0E49\u0E27",
  "ccnotification.notifition.sendmessage.savesuccess" : "\u0E1A\u0E31\u0E19\u0E17\u0E36\u0E01\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21\u0E2A\u0E33\u0E40\u0E23\u0E47\u0E08\u0E41\u0E25\u0E49\u0E27",
  "ccnotification.notification.label.compressZipFileFailed" : "\u0E25\u0E49\u0E21\u0E40\u0E2B\u0E25\u0E27\u0E43\u0E19\u0E01\u0E32\u0E23\u0E1A\u0E35\u0E1A\u0E2D\u0E31\u0E14\u0E44\u0E1F\u0E25\u0E4C .zip",
  "ccnotification.gatewayconfig.label.smPassword" : "\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19\u0E2A\u0E33\u0E2B\u0E23\u0E31\u0E1A\u0E40\u0E02\u0E49\u0E32\u0E2A\u0E39\u0E48\u0E23\u0E30\u0E1A\u0E1A",
  "message.template.message.mediaTypeError" : "\u0E42\u0E2B\u0E21\u0E14\u0E2A\u0E48\u0E07\u0E44\u0E21\u0E48\u0E16\u0E39\u0E01\u0E15\u0E49\u0E2D\u0E07",
  "ccnotification.notification.recmessagerouterconfig.selectconfig" : "\u0E42\u0E1B\u0E23\u0E14\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E01\u0E32\u0E23\u0E01\u0E33\u0E2B\u0E19\u0E14\u0E04\u0E48\u0E32",
  "ccnotification.gatewayconfig.label.bindModeIsError" : "\u0E42\u0E2B\u0E21\u0E14\u0E01\u0E32\u0E23\u0E2A\u0E48\u0E07\u0E41\u0E25\u0E30\u0E01\u0E32\u0E23\u0E23\u0E31\u0E1A SMS 1: \u0E42\u0E2B\u0E21\u0E14\u0E01\u0E32\u0E23\u0E23\u0E31\u0E1A\, 2: \u0E42\u0E2B\u0E21\u0E14\u0E01\u0E32\u0E23\u0E2A\u0E48\u0E07\u0E1C\u0E48\u0E32\u0E19\, 9: \u0E42\u0E2B\u0E21\u0E14 TX/RX",
  "ccnotification.smshuawei.message.deletefail" : "\u0E25\u0E49\u0E21\u0E40\u0E2B\u0E25\u0E27\u0E43\u0E19\u0E01\u0E32\u0E23\u0E25\u0E1A\u0E0A\u0E48\u0E2D\u0E07\u0E2A\u0E31\u0E0D\u0E0D\u0E32\u0E13 SMS",
  "message.center.nocitiction.outbox" : "\u0E01\u0E25\u0E48\u0E2D\u0E07\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21\u0E02\u0E32\u0E2D\u0E2D\u0E01",
  "ccnotification.smshuawei.message.updatefail" : "\u0E25\u0E49\u0E21\u0E40\u0E2B\u0E25\u0E27\u0E43\u0E19\u0E01\u0E32\u0E23\u0E1B\u0E23\u0E31\u0E1A\u0E40\u0E1B\u0E25\u0E35\u0E48\u0E22\u0E19\u0E0A\u0E48\u0E2D\u0E07\u0E2A\u0E31\u0E0D\u0E0D\u0E32\u0E13 SMS",
  "ccnotification.gatewayconfig.label.hostIsError" : "\u0E41\u0E21\u0E48\u0E02\u0E48\u0E32\u0E22\u0E2D\u0E35\u0E40\u0E21\u0E25\u0E2A\u0E33\u0E2B\u0E23\u0E31\u0E1A\u0E2D\u0E22\u0E39\u0E48\u0E43\u0E19\u0E23\u0E39\u0E1B\u0E41\u0E1A\u0E1A 127.0.0.1 \u0E2B\u0E23\u0E37\u0E2D www.mail.com \u0E40\u0E17\u0E48\u0E32\u0E19\u0E31\u0E49\u0E19",
  "ccnotification.gatewayconfig.label.messageFlowControlMaxCapsForSendtitle" : "\u0E2D\u0E31\u0E15\u0E23\u0E32\u0E01\u0E32\u0E23\u0E2A\u0E48\u0E07\u0E2A\u0E39\u0E07\u0E2A\u0E38\u0E14\u0E04\u0E37\u0E2D\u0E15\u0E31\u0E27\u0E40\u0E25\u0E02",
  "ccnotification.notifition.receivermessage.receiverSuccess" : "\u0E44\u0E14\u0E49\u0E23\u0E31\u0E1A\u0E41\u0E25\u0E49\u0E27",
  "ccnotification.gatewayconfig.label.mailPassword" : "\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19\u0E02\u0E2D\u0E07\u0E1C\u0E39\u0E49\u0E43\u0E0A\u0E49",
  "ccnotification.notifition.template.placeholder.varnumber" : "\u0E01\u0E23\u0E2D\u0E01\u0E08\u0E33\u0E19\u0E27\u0E19\u0E15\u0E31\u0E27\u0E41\u0E1B\u0E23",
  "ccnotification.notifition.sendmessage.internalmessage" : "\u0E01\u0E32\u0E23\u0E41\u0E08\u0E49\u0E07\u0E40\u0E15\u0E37\u0E2D\u0E19\u0E20\u0E32\u0E22\u0E43\u0E19",
  "ccnotification.common.warning.message" : "\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E1A\u0E31\u0E19\u0E17\u0E36\u0E01",
  "ccnotification.smshuawei.label.appKey" : "App Key",
  "ccnotification.smshuawei.label.certTip.withProxy" : "\u0E42\u0E1B\u0E23\u0E14\u0E2D\u0E31\u0E1B\u0E42\u0E2B\u0E25\u0E14\u0E43\u0E1A\u0E23\u0E31\u0E1A\u0E23\u0E2D\u0E07\u0E1E\u0E23\u0E47\u0E2D\u0E01\u0E0B\u0E35\u0E40\u0E0B\u0E34\u0E23\u0E4C\u0E1F\u0E40\u0E27\u0E2D\u0E23\u0E4C",
  "ccnotification.notification.systemmonitor.rebuilding" : "\u0E01\u0E33\u0E25\u0E31\u0E07\u0E2A\u0E23\u0E49\u0E32\u0E07\u0E43\u0E2B\u0E21\u0E48",
  "message.template.message.messageTemplateCreate" : "\u0E2A\u0E23\u0E49\u0E32\u0E07\u0E40\u0E17\u0E21\u0E40\u0E1E\u0E25\u0E15\u0E01\u0E32\u0E23\u0E41\u0E08\u0E49\u0E07\u0E40\u0E15\u0E37\u0E2D\u0E19",
  "ccnotification.notification.label.downloadAttachment" : "\u0E14\u0E32\u0E27\u0E19\u0E4C\u0E42\u0E2B\u0E25\u0E14\u0E44\u0E1F\u0E25\u0E4C\u0E41\u0E19\u0E1A",
  "ccnotification.smshuawei.label.certTip.withoutProxy" : "\u0E2D\u0E31\u0E1B\u0E42\u0E2B\u0E25\u0E14\u0E43\u0E1A\u0E23\u0E31\u0E1A\u0E23\u0E2D\u0E07\u0E1A\u0E23\u0E34\u0E01\u0E32\u0E23 HUAWEI CLOUD",
  "ccnotification.smstemplate.message.smstemplate" : "\u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25\u0E40\u0E17\u0E21\u0E40\u0E1E\u0E25\u0E15 HUAWEI CLOUD \u0E27\u0E48\u0E32\u0E07\u0E40\u0E1B\u0E25\u0E48\u0E32",
  "ccnotification.notifition.template.placeholder.des" : "\u0E2D\u0E18\u0E34\u0E1A\u0E32\u0E22\u0E15\u0E31\u0E27\u0E41\u0E1B\u0E23",
  "ccnotification.gatewayconfig.label.positive" : "\u0E08\u0E33\u0E19\u0E27\u0E19\u0E44\u0E21\u0E48\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E40\u0E1B\u0E47\u0E19\u0E04\u0E48\u0E32\u0E25\u0E1A",
  "ccnotification.notification.label.isDeleteAttachment" : "\u0E25\u0E1A\u0E44\u0E1F\u0E25\u0E4C\u0E41\u0E19\u0E1A",
  "ccnotification.notification.recmessagerouterconfig.satisfaction" : "\u0E41\u0E1A\u0E1A\u0E2A\u0E33\u0E23\u0E27\u0E08\u0E04\u0E27\u0E32\u0E21\u0E1E\u0E36\u0E07\u0E1E\u0E2D\u0E43\u0E08",
  "ccnotification.gatewayconfig.label.messageFlowControlMessageDropPolicy" : "\u0E19\u0E42\u0E22\u0E1A\u0E32\u0E22\u0E01\u0E32\u0E23\u0E1B\u0E23\u0E30\u0E21\u0E27\u0E25\u0E1C\u0E25\u0E01\u0E32\u0E23\u0E23\u0E31\u0E1A\u0E2A\u0E48\u0E07\u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25\u0E1E\u0E34\u0E40\u0E28\u0E29",
  "ccnotification.notifition.template.message.text" : "\u0E04\u0E48\u0E32\u0E04\u0E37\u0E2D\u0E2A\u0E15\u0E23\u0E34\u0E07 1 \u0E16\u0E36\u0E07 20 \u0E2D\u0E31\u0E01\u0E02\u0E23\u0E30\u0E41\u0E25\u0E30\u0E44\u0E21\u0E48\u0E21\u0E35 000",
  "ccnotification.gatewayconfig.label.configNameAlreadyExists" : "\u0E21\u0E35\u0E0A\u0E37\u0E48\u0E2D\u0E01\u0E32\u0E23\u0E01\u0E33\u0E2B\u0E19\u0E14\u0E04\u0E48\u0E32\u0E2D\u0E22\u0E39\u0E48\u0E41\u0E25\u0E49\u0E27",
  "message.template.message.searchMessageTemplateName" : "\u0E01\u0E23\u0E2D\u0E01\u0E02\u0E37\u0E48\u0E2D\u0E40\u0E17\u0E21\u0E40\u0E1E\u0E25\u0E15",
  "ccnotification.notifition.sendmessage.delaynotice" : "\u0E01\u0E32\u0E23\u0E41\u0E08\u0E49\u0E07\u0E40\u0E15\u0E37\u0E2D\u0E19\u0E19\u0E35\u0E49\u0E08\u0E30\u0E16\u0E39\u0E01\u0E2A\u0E48\u0E07\u0E40\u0E21\u0E37\u0E48\u0E2D",
  "ccnotification.smstemplate.message.signatureName" : "\u0E0A\u0E37\u0E48\u0E2D\u0E25\u0E32\u0E22\u0E40\u0E0B\u0E47\u0E19\u0E15\u0E49\u0E2D\u0E07\u0E44\u0E21\u0E48\u0E27\u0E48\u0E32\u0E07\u0E40\u0E1B\u0E25\u0E48\u0E32 \u0E22\u0E32\u0E27\u0E40\u0E01\u0E34\u0E19\u0E44\u0E1B \u0E2B\u0E23\u0E37\u0E2D\u0E44\u0E21\u0E48\u0E40\u0E1B\u0E47\u0E19\u0E44\u0E1B\u0E15\u0E32\u0E21\u0E02\u0E49\u0E2D\u0E01\u0E4D\u0E32\u0E2B\u0E19\u0E14",
  "ccnotification.agent.contact.workno" : "ID \u0E1E\u0E19\u0E31\u0E01\u0E07\u0E32\u0E19",
  "message.template.message.createTime" : "\u0E2A\u0E23\u0E49\u0E32\u0E07\u0E40\u0E21\u0E37\u0E48\u0E2D",
  "ccnotification.smshuawei.label.signatureChannelId.length" : "\u0E04\u0E27\u0E32\u0E21\u0E22\u0E32\u0E27\u0E02\u0E2D\u0E07\u0E2B\u0E21\u0E32\u0E22\u0E40\u0E25\u0E02\u0E0A\u0E48\u0E2D\u0E07\u0E2A\u0E31\u0E0D\u0E0D\u0E32\u0E13\u0E02\u0E2D\u0E07\u0E25\u0E32\u0E22\u0E40\u0E0B\u0E47\u0E19\u0E40\u0E01\u0E34\u0E19\u0E0A\u0E48\u0E27\u0E07\u0E04\u0E48\u0E32",
  "message.center.nocitiction.message" : "SMS",
  "ccnotification.smstemplate.message.receiverPhone" : "\u0E1C\u0E39\u0E49\u0E23\u0E31\u0E1A\u0E44\u0E21\u0E48\u0E2D\u0E19\u0E38\u0E0D\u0E32\u0E15\u0E43\u0E2B\u0E49\u0E17\u0E4D\u0E32\u0E23\u0E32\u0E22\u0E01\u0E32\u0E23\u0E0B\u0E49\u0E4D\u0E32",
  "ccnotification.notifition.variable.variable" : "\u0E15\u0E31\u0E27\u0E41\u0E1B\u0E23",
  "ccnotification.notification.variable.templatevariable" : "\u0E40\u0E17\u0E21\u0E40\u0E1E\u0E25\u0E15",
  "ccnotification.gatewayconfig.label.sourceAddrTontitle" : "\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E23\u0E2B\u0E31\u0E2A\u0E17\u0E35\u0E48\u0E2D\u0E22\u0E39\u0E48\u0E15\u0E49\u0E19\u0E17\u0E32\u0E07\u0E04\u0E37\u0E2D\u0E15\u0E31\u0E27\u0E40\u0E25\u0E02",
  "ccnotification.notification.label.incorrectAttachmentType" : "\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E44\u0E1F\u0E25\u0E4C\u0E41\u0E19\u0E1A\u0E2D\u0E35\u0E40\u0E21\u0E25\u0E44\u0E21\u0E48\u0E16\u0E39\u0E01\u0E15\u0E49\u0E2D\u0E07",
  "ccnotification.flowcontrol.countryCode" : "\u0E23\u0E2B\u0E31\u0E2A\u0E1B\u0E23\u0E30\u0E40\u0E17\u0E28",
  "ccnotification.notifition.template.title.setting" : "\u0E01\u0E33\u0E2B\u0E19\u0E14\u0E15\u0E31\u0E27\u0E41\u0E1B\u0E23",
  "ccnotification.notifition.sendmessage.selectmode" : "\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E42\u0E14\u0E22",
  "message.template.message.noneTemplate" : "\u0E44\u0E21\u0E48\u0E21\u0E35\u0E40\u0E17\u0E21\u0E40\u0E1E\u0E25\u0E15",
  "ccnotification.gatewayconfig.label.smHosttitle" : "\u0E17\u0E35\u0E48\u0E2D\u0E22\u0E39\u0E48 SMG \u0E40\u0E0A\u0E48\u0E19 127.0.0.1",
  "ccnotification.notifition.sendmessage.paramvalidatefailed" : "\u0E01\u0E32\u0E23\u0E15\u0E23\u0E27\u0E08\u0E2A\u0E2D\u0E1A\u0E04\u0E27\u0E32\u0E21\u0E2A\u0E21\u0E40\u0E2B\u0E15\u0E38\u0E2A\u0E21\u0E1C\u0E25\u0E02\u0E2D\u0E07\u0E1E\u0E32\u0E23\u0E32\u0E21\u0E34\u0E40\u0E15\u0E2D\u0E23\u0E4C\u0E25\u0E49\u0E21\u0E40\u0E2B\u0E25\u0E27",
  "ccnotification.gatewayconfig.label.smPort" : "\u0E1E\u0E2D\u0E23\u0E4C\u0E15 SMG",
  "ccnotification.gatewayconfig.label.splitTypeIsError" : "\u0E42\u0E2B\u0E21\u0E14\u0E41\u0E1A\u0E48\u0E07\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21 SMS \u0E22\u0E32\u0E27\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E16\u0E39\u0E01\u0E01\u0E33\u0E2B\u0E19\u0E14\u0E15\u0E31\u0E49\u0E07\u0E04\u0E48\u0E32\u0E40\u0E1B\u0E47\u0E19 0: \u0E1B\u0E34\u0E14\u0E01\u0E32\u0E23\u0E43\u0E0A\u0E49\u0E07\u0E32\u0E19\, 1: \u0E44\u0E21\u0E48\u0E41\u0E1A\u0E48\u0E07\, 2: \u0E01\u0E32\u0E23\u0E41\u0E1A\u0E48\u0E07\u0E17\u0E32\u0E07\u0E01\u0E32\u0E22\u0E20\u0E32\u0E1E\, 3: \u0E01\u0E32\u0E23\u0E41\u0E1A\u0E48\u0E07\u0E41\u0E1A\u0E1A\u0E22\u0E32\u0E27\u0E21\u0E32\u0E01",
  "ccnotification.gatewayconfig.label.selectCert" : "\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E43\u0E1A\u0E23\u0E31\u0E1A\u0E23\u0E2D\u0E07",
  "message.center.nocitiction.callback.record" : "\u0E1A\u0E31\u0E19\u0E17\u0E36\u0E01\u0E01\u0E32\u0E23\u0E42\u0E17\u0E23\u0E01\u0E25\u0E31\u0E1A",
  "ccnotification.notification.recmessagerouterconfig.gateway" : "\u0E40\u0E01\u0E15\u0E40\u0E27\u0E22\u0E4C",
  "message.center.nocitiction.modifyBulletin" : "\u0E41\u0E01\u0E49\u0E44\u0E02\u0E1B\u0E23\u0E30\u0E01\u0E32\u0E28",
  "message.center.nocitiction.modifyInternalMessage" : "\u0E41\u0E01\u0E49\u0E44\u0E02\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21\u0E20\u0E32\u0E22\u0E43\u0E19",
  "message.center.nocitiction.draftBox" : "\u0E01\u0E25\u0E48\u0E2D\u0E07\u0E23\u0E48\u0E32\u0E07",
  "ccnotification.notifition.template.label.des" : "\u0E04\u0E33\u0E2D\u0E18\u0E34\u0E1A\u0E32\u0E22",
  "ccnotification.notifition.template.label.value" : "\u0E04\u0E48\u0E32\u0E40\u0E23\u0E34\u0E48\u0E21\u0E15\u0E49\u0E19",
  "ccnotification.notifition.template.placeholder.signatureName" : "\u0E01\u0E23\u0E2D\u0E01\u0E0A\u0E37\u0E48\u0E2D\u0E25\u0E32\u0E22\u0E40\u0E0B\u0E47\u0E19",
  "ccnotification.gatewayconfig.label.gatewayipaddrconfig" : "\u0E01\u0E32\u0E23\u0E01\u0E33\u0E2B\u0E19\u0E14\u0E04\u0E48\u0E32\u0E01\u0E32\u0E23\u0E40\u0E0A\u0E37\u0E48\u0E2D\u0E21\u0E15\u0E48\u0E2D\u0E01\u0E31\u0E19\u0E02\u0E2D\u0E07\u0E17\u0E35\u0E48\u0E2D\u0E22\u0E39\u0E48\u0E40\u0E01\u0E15\u0E40\u0E27\u0E22\u0E4C",
  "ccnotification.notifition.template.label.templatetype" : "\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E40\u0E17\u0E21\u0E40\u0E1E\u0E25\u0E15",
  "message.center.label.messageCenter" : "\u0E28\u0E39\u0E19\u0E22\u0E4C\u0E01\u0E32\u0E23\u0E41\u0E08\u0E49\u0E07\u0E40\u0E15\u0E37\u0E2D\u0E19",
  "ccnotification.gatewayconfig.label.transactionTimeout" : "\u0E0A\u0E48\u0E27\u0E07\u0E2B\u0E21\u0E14\u0E40\u0E27\u0E25\u0E32\u0E02\u0E2D\u0E07\u0E18\u0E38\u0E23\u0E01\u0E23\u0E23\u0E21 (ms)",
  "message.template.message.recipientLengthError" : "\u0E08\u0E33\u0E19\u0E27\u0E19\u0E02\u0E2D\u0E07\u0E1C\u0E39\u0E49\u0E23\u0E31\u0E1A\u0E40\u0E01\u0E34\u0E19\u0E02\u0E35\u0E14\u0E08\u0E33\u0E01\u0E31\u0E14",
  "ccnotification.notifition.receivermessage.unread" : "\u0E44\u0E21\u0E48\u0E44\u0E14\u0E49\u0E2D\u0E48\u0E32\u0E19",
  "ccnotification.notification.label.discounnectedFailed" : "\u0E15\u0E31\u0E14\u0E01\u0E32\u0E23\u0E40\u0E0A\u0E37\u0E48\u0E2D\u0E21\u0E15\u0E48\u0E2D\u0E25\u0E49\u0E21\u0E40\u0E2B\u0E25\u0E27",
  "ccnotification.notifition.template.label.mspcontent" : "\u0E40\u0E19\u0E37\u0E49\u0E2D\u0E2B\u0E32\u0E40\u0E17\u0E21\u0E40\u0E1E\u0E25\u0E15 SMS",
  "ccnotification.notifition.recmessagerouterconfig.index" : "\u0E25\u0E33\u0E14\u0E31\u0E1A",
  "ccnotification.gatewayconfig.label.serviceType" : "\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E01\u0E32\u0E23\u0E1A\u0E23\u0E34\u0E01\u0E32\u0E23",
  "ccnotification.smshuawei.message.paramisempty" : "\u0E1E\u0E32\u0E23\u0E32\u0E21\u0E34\u0E40\u0E15\u0E2D\u0E23\u0E4C\u0E27\u0E48\u0E32\u0E07\u0E40\u0E1B\u0E25\u0E48\u0E32",
  "ccnotification.notification.recmessagerouterconfig.defaultreceiveemail" : "\u0E04\u0E48\u0E32\u0E40\u0E23\u0E34\u0E48\u0E21\u0E15\u0E49\u0E19\u0E01\u0E32\u0E23\u0E23\u0E31\u0E1A\u0E2D\u0E35\u0E40\u0E21\u0E25",
  "ccnotification.smstemplate.message.var" : "\u0E23\u0E39\u0E1B\u0E41\u0E1A\u0E1A\u0E2D\u0E34\u0E19\u0E1E\u0E38\u0E15\u0E02\u0E2D\u0E07\u0E15\u0E31\u0E27\u0E41\u0E1B\u0E23\u0E44\u0E21\u0E48\u0E16\u0E39\u0E01\u0E15\u0E49\u0E2D\u0E07\u0E2B\u0E23\u0E37\u0E2D\u0E44\u0E21\u0E48\u0E44\u0E14\u0E49\u0E01\u0E33\u0E2B\u0E19\u0E14\u0E1B\u0E23\u0E34\u0E21\u0E32\u0E13",
  "ccnotification.smshuawei.label.appSecret" : "App Secret",
  "ccnotification.notification.variable.variablevalue" : "\u0E04\u0E48\u0E32\u0E15\u0E31\u0E27\u0E41\u0E1B\u0E23",
  "ccnotification.notifition.sendmessage.selectall" : "\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E17\u0E31\u0E49\u0E07\u0E2B\u0E21\u0E14",
  "ccnotification.agent.label.account" : "\u0E0A\u0E37\u0E48\u0E2D\u0E1A\u0E31\u0E0D\u0E0A\u0E35",
  "ccnotification.caseserver.fail.titleAndContentNull" : "\u0E0A\u0E37\u0E48\u0E2D\u0E41\u0E25\u0E30\u0E40\u0E19\u0E37\u0E49\u0E2D\u0E2B\u0E32\u0E27\u0E48\u0E32\u0E07\u0E40\u0E1B\u0E25\u0E48\u0E32",
  "ccnotification.notification.label.downloadFromObsFailed" : "\u0E25\u0E49\u0E21\u0E40\u0E2B\u0E25\u0E27\u0E43\u0E19\u0E01\u0E32\u0E23\u0E14\u0E32\u0E27\u0E19\u0E4C\u0E42\u0E2B\u0E25\u0E14\u0E44\u0E1F\u0E25\u0E4C\u0E08\u0E32\u0E01 OBS",
  "ccnotification.caseserver.fail.duplicateContent" : "\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21\u0E0B\u0E49\u0E33",
  "ccnotification.notifition.sendmessage.selectbyorg" : "\u0E42\u0E04\u0E23\u0E07\u0E2A\u0E23\u0E49\u0E32\u0E07\u0E2D\u0E07\u0E04\u0E4C\u0E01\u0E23",
  "ccnotification.gatewayconfig.label.splitType" : "",
  "ccnotification.notifition.receivermessage.all" : "\u0E17\u0E31\u0E49\u0E07\u0E2B\u0E21\u0E14",
  "ccnotification.gatewayconfig.label.maxEmailGatewayIsError" : "\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E01\u0E33\u0E2B\u0E19\u0E14\u0E04\u0E48\u0E32\u0E40\u0E01\u0E15\u0E40\u0E27\u0E22\u0E4C\u0E01\u0E32\u0E23\u0E23\u0E31\u0E1A\u0E2B\u0E23\u0E37\u0E2D\u0E2A\u0E48\u0E07\u0E2D\u0E35\u0E40\u0E21\u0E25\u0E44\u0E14\u0E49\u0E2A\u0E39\u0E07\u0E2A\u0E38\u0E14\u0E2B\u0E49\u0E32\u0E23\u0E32\u0E22\u0E01\u0E32\u0E23\u0E2A\u0E33\u0E2B\u0E23\u0E31\u0E1A\u0E1E\u0E37\u0E49\u0E19\u0E17\u0E35\u0E48\u0E1C\u0E39\u0E49\u0E40\u0E0A\u0E48\u0E32\u0E40\u0E14\u0E35\u0E22\u0E27\u0E01\u0E31\u0E19",
  "ccnotification.notifition.template.label.type" : "\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17",
  "message.template.message.messageTemplateName" : "\u0E0A\u0E37\u0E48\u0E2D\u0E40\u0E17\u0E21\u0E40\u0E1E\u0E25\u0E15",
  "message.template.message.recipientError" : "\u0E44\u0E21\u0E48\u0E21\u0E35\u0E1C\u0E39\u0E49\u0E23\u0E31\u0E1A\u0E1A\u0E32\u0E07\u0E23\u0E32\u0E22 \u0E40\u0E25\u0E37\u0E2D\u0E01\u0E1C\u0E39\u0E49\u0E23\u0E31\u0E1A\u0E04\u0E19\u0E2D\u0E37\u0E48\u0E19",
  "ccnotification.notifition.variable.been.used" : "\u0E16\u0E39\u0E01\u0E2D\u0E49\u0E32\u0E07\u0E2D\u0E34\u0E07\u0E42\u0E14\u0E22\u0E40\u0E17\u0E21\u0E40\u0E1E\u0E25\u0E15\u0E41\u0E25\u0E30\u0E44\u0E21\u0E48\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E25\u0E1A\u0E44\u0E14\u0E49",
  "ccnotification.gatewayconfig.label.deletegatewaysuccess" : "\u0E25\u0E1A\u0E01\u0E32\u0E23\u0E01\u0E33\u0E2B\u0E19\u0E14\u0E04\u0E48\u0E32\u0E40\u0E01\u0E15\u0E40\u0E27\u0E22\u0E4C\u0E41\u0E25\u0E49\u0E27!",
  "ccnotification.notifition.variable.variableType" : "\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E15\u0E31\u0E27\u0E41\u0E1B\u0E23",
  "ccnotification.notification.recmessagerouterconfig.receiveaddressexist" : "\u0E21\u0E35\u0E17\u0E35\u0E48\u0E2D\u0E22\u0E39\u0E48\u0E23\u0E31\u0E1A\u0E2D\u0E22\u0E39\u0E48\u0E41\u0E25\u0E49\u0E27",
  "message.center.nocitiction.sending.records" : "\u0E1A\u0E31\u0E19\u0E17\u0E36\u0E01\u0E01\u0E32\u0E23\u0E2A\u0E48\u0E07",
  "message.center.label.sendTime" : "\u0E40\u0E27\u0E25\u0E32\u0E2A\u0E48\u0E07",
  "message.center.label.expireTime" : "\u0E2B\u0E21\u0E14\u0E2D\u0E32\u0E22\u0E38\u0E40\u0E21\u0E37\u0E48\u0E2D",
  "ccnotification.notifition.variable.syntax.error" : "\u0E44\u0E27\u0E22\u0E32\u0E01\u0E23\u0E13\u0E4C\u0E1C\u0E34\u0E14!",
  "ccnotification.flowcontrol.setShortMsgCode" : "\u0E01\u0E33\u0E2B\u0E19\u0E14\u0E2B\u0E21\u0E32\u0E22\u0E40\u0E25\u0E02\u0E2A\u0E33\u0E2B\u0E23\u0E31\u0E1A\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21 SMS",
  "message.center.label.bulletinType" : "\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E1B\u0E23\u0E30\u0E01\u0E32\u0E28",
  "ccnotification.notification.label.rebuildFailed" : "\u0E01\u0E32\u0E23\u0E2A\u0E23\u0E49\u0E32\u0E07\u0E43\u0E2B\u0E21\u0E48\u0E25\u0E49\u0E21\u0E40\u0E2B\u0E25\u0E27",
  "ccnotification.notification.label.rebuildContainsErrorId" : "\u0E01\u0E32\u0E23\u0E2A\u0E23\u0E49\u0E32\u0E07\u0E43\u0E2B\u0E21\u0E48\u0E25\u0E49\u0E21\u0E40\u0E2B\u0E25\u0E27 \u0E42\u0E1B\u0E23\u0E14\u0E04\u0E25\u0E34\u0E01\u0E1B\u0E38\u0E48\u0E21\u0E41\u0E1A\u0E1A\u0E2A\u0E2D\u0E1A\u0E16\u0E32\u0E21\u0E40\u0E1E\u0E37\u0E48\u0E2D\u0E2D\u0E31\u0E1B\u0E40\u0E14\u0E15\u0E23\u0E32\u0E22\u0E01\u0E32\u0E23\u0E41\u0E25\u0E30\u0E25\u0E2D\u0E07\u0E2D\u0E35\u0E01\u0E04\u0E23\u0E31\u0E49\u0E07",
  "ccnotification.gatewayconfig.label.mailProtocolIsError" : "\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E42\u0E1B\u0E23\u0E42\u0E15\u0E04\u0E2D\u0E25\u0E2D\u0E35\u0E40\u0E21\u0E25\u0E15\u0E49\u0E2D\u0E07\u0E40\u0E1B\u0E47\u0E19 SMTP\, POP3",
  "ccnotification.gatewayconfig.label.smPorttitle" : "\u0E0A\u0E48\u0E27\u0E07\u0E2B\u0E21\u0E32\u0E22\u0E40\u0E25\u0E02\u0E1E\u0E2D\u0E23\u0E4C\u0E15 SMG: \u0E08\u0E33\u0E19\u0E27\u0E19\u0E40\u0E15\u0E47\u0E21\u0E08\u0E32\u0E01 0 \u0E16\u0E36\u0E07 65535",
  "ccnotification.notification.systemmonitor.disconnected" : "\u0E15\u0E31\u0E14\u0E01\u0E32\u0E23\u0E40\u0E0A\u0E37\u0E48\u0E2D\u0E21\u0E15\u0E48\u0E2D\u0E41\u0E25\u0E49\u0E27",
  "ccnotification.gatewayconfig.label.serverSocketPort" : "\u0E1E\u0E2D\u0E23\u0E4C\u0E15\u0E2A\u0E4D\u0E32\u0E2B\u0E23\u0E31\u0E1A\u0E23\u0E31\u0E1A\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21\u0E15\u0E49\u0E19\u0E19\u0E49\u0E4D\u0E32",
  "ccnotification.notifition.template.label.operation" : "\u0E01\u0E32\u0E23\u0E14\u0E33\u0E40\u0E19\u0E34\u0E19\u0E01\u0E32\u0E23",
  "message.center.message.messageQueryResultIsEmpty" : "\u0E1C\u0E25\u0E01\u0E32\u0E23\u0E04\u0E49\u0E19\u0E2B\u0E32\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21\u0E27\u0E48\u0E32\u0E07\u0E40\u0E1B\u0E25\u0E48\u0E32",
  "ccnotification.notification.label.rebuildSuccess" : "\u0E01\u0E32\u0E23\u0E2A\u0E23\u0E49\u0E32\u0E07\u0E43\u0E2B\u0E21\u0E48\u0E2A\u0E33\u0E40\u0E23\u0E47\u0E08",
  "ccnotification.flowcontrol.totalThreshold" : "\u0E02\u0E35\u0E14\u0E08\u0E33\u0E01\u0E31\u0E14\u0E23\u0E32\u0E22\u0E27\u0E31\u0E19\u0E02\u0E2D\u0E07\u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25\u0E23\u0E32\u0E22\u0E27\u0E31\u0E19\u0E23\u0E27\u0E21\u0E17\u0E38\u0E01\u0E0A\u0E48\u0E2D\u0E07\u0E17\u0E32\u0E07",
  "ccnotification.gatewayconfig.label.splitTypetitle" : "\u0E42\u0E2B\u0E21\u0E14\u0E01\u0E32\u0E23\u0E41\u0E1A\u0E48\u0E07\u0E02\u0E2D\u0E07\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21 SMS \u0E17\u0E35\u0E48\u0E22\u0E32\u0E27\u0E40\u0E01\u0E34\u0E19\u0E44\u0E1B \u0E15\u0E31\u0E27\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E21\u0E35\u0E14\u0E31\u0E07\u0E15\u0E48\u0E2D\u0E44\u0E1B\u0E19\u0E35\u0E49: 0: \u0E44\u0E21\u0E48\u0E41\u0E1A\u0E48\u0E07\, 1: \u0E44\u0E21\u0E48\u0E41\u0E1A\u0E48\u0E07\, 2: \u0E41\u0E1A\u0E48\u0E07\u0E17\u0E32\u0E07\u0E01\u0E32\u0E22\u0E20\u0E32\u0E1E\, 3: \u0E41\u0E1A\u0E48\u0E07\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21 SMS \u0E17\u0E35\u0E48\u0E22\u0E32\u0E27\u0E40\u0E01\u0E34\u0E19\u0E44\u0E1B",
  "message.center.nocitiction.system" : "\u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25\u0E23\u0E30\u0E1A\u0E1A",
  "message.template.message.messageTemplateModify" : "\u0E1B\u0E23\u0E31\u0E1A\u0E40\u0E1B\u0E25\u0E35\u0E48\u0E22\u0E19\u0E40\u0E17\u0E21\u0E40\u0E1E\u0E25\u0E15",
  "ccnotification.gatewayconfig.label.resendWaitTimetitle" : "\u0E40\u0E27\u0E25\u0E32\u0E23\u0E2D\u0E01\u0E32\u0E23\u0E2A\u0E48\u0E07\u0E2D\u0E35\u0E01\u0E04\u0E23\u0E31\u0E49\u0E07\u0E04\u0E37\u0E2D\u0E08\u0E33\u0E19\u0E27\u0E19\u0E17\u0E35\u0E48\u0E40\u0E2B\u0E21\u0E32\u0E30\u0E2A\u0E21",
  "ccnotification.center.message.toIsRead" : "\u0E04\u0E38\u0E13\u0E41\u0E19\u0E48\u0E43\u0E08\u0E27\u0E48\u0E04\u0E38\u0E13\u0E15\u0E49\u0E2D\u0E07\u0E01\u0E32\u0E23\u0E40\u0E04\u0E23\u0E37\u0E48\u0E2D\u0E07\u0E2B\u0E21\u0E32\u0E22\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21\u0E17\u0E35\u0E48\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E27\u0E48\u0E32\u0E2D\u0E48\u0E32\u0E19\u0E41\u0E25\u0E49\u0E27\u0E43\u0E0A\u0E48\u0E2B\u0E23\u0E37\u0E2D\u0E44\u0E21\u0E48?",
  "ccnotification.gatewayconfig.label.paramMaxLengthIsError" : "\u0E04\u0E48\u0E32\u0E1E\u0E32\u0E23\u0E32\u0E21\u0E34\u0E40\u0E15\u0E2D\u0E23\u0E4C\u0E44\u0E21\u0E48\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E40\u0E01\u0E34\u0E19 500 \u0E2D\u0E31\u0E01\u0E02\u0E23\u0E30",
  "ccnotification.notifition.gatewaycontact.failed" : "\u0E01\u0E32\u0E23\u0E40\u0E0A\u0E37\u0E48\u0E2D\u0E21\u0E15\u0E48\u0E2D\u0E1C\u0E34\u0E14\u0E1E\u0E25\u0E32\u0E14",
  "ccnotification.common.operation.noMoreThan7Days" : "\u0E0A\u0E48\u0E27\u0E07\u0E40\u0E27\u0E25\u0E32\u0E44\u0E21\u0E48\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E40\u0E01\u0E34\u0E19 7 \u0E27\u0E31\u0E19",
  "message.center.nocitiction.callback.status" : "\u0E2A\u0E16\u0E32\u0E19\u0E30\u0E01\u0E32\u0E23\u0E42\u0E17\u0E23\u0E01\u0E25\u0E31\u0E1A",
  "ccnotification.notification.label.selectAttachment" : "\u0E42\u0E1B\u0E23\u0E14\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E44\u0E1F\u0E25\u0E4C\u0E41\u0E19\u0E1A\u0E2D\u0E35\u0E40\u0E21\u0E25",
  "ccnotification.gatewayconfig.label.inputgatewayname" : "\u0E01\u0E23\u0E2D\u0E01\u0E0A\u0E37\u0E48\u0E2D\u0E40\u0E01\u0E15\u0E40\u0E27\u0E22\u0E4C",
  "ccnotification.notification.recmessagerouterconfig.serviceType" : "\u0E27\u0E34\u0E18\u0E35\u0E01\u0E32\u0E23\u0E19\u0E33\u0E44\u0E1B\u0E1B\u0E0F\u0E34\u0E1A\u0E31\u0E15\u0E34",
  "ccnotification.gatewayconfig.label.heartbeatInterval" : "\u0E0A\u0E48\u0E27\u0E07\u0E01\u0E32\u0E23\u0E40\u0E15\u0E49\u0E19\u0E02\u0E2D\u0E07\u0E2B\u0E31\u0E27\u0E43\u0E08 (ms)",
  "ccnotification.gatewayconfig.label.defaultreceinfo" : "ID \u0E01\u0E32\u0E23\u0E23\u0E31\u0E1A\u0E40\u0E23\u0E34\u0E48\u0E21\u0E15\u0E49\u0E19",
  "message.template.message.save" : "\u0E1A\u0E31\u0E19\u0E17\u0E36\u0E01",
  "ccnotification.notification.label.isUploadAttachment" : "\u0E2D\u0E31\u0E1E\u0E42\u0E2B\u0E25\u0E14\u0E44\u0E1F\u0E25\u0E4C\u0E41\u0E19\u0E1A",
  "ccnotification.gatewayconfig.label.maxContentLength" : "\u0E04\u0E27\u0E32\u0E21\u0E22\u0E32\u0E27\u0E40\u0E19\u0E37\u0E49\u0E2D\u0E2B\u0E32\u0E2A\u0E39\u0E07\u0E2A\u0E38\u0E14 (byte)",
  "ccnotification.gatewayconfig.label.addrTon" : "\u0E23\u0E2B\u0E31\u0E2A\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E17\u0E35\u0E48\u0E2D\u0E22\u0E39\u0E48 ESME",
  "ccnotification.notifition.sendmessage.bccRecipient" : "Bcc",
  "message.center.nocitiction.sending.records.smsType" : "\u0E42\u0E2B\u0E21\u0E14\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21 SMS",
  "ccnotification.notification.label.serverIsNotobs" : "\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E14\u0E32\u0E27\u0E19\u0E4C\u0E42\u0E2B\u0E25\u0E14\u0E44\u0E1F\u0E25\u0E4C\u0E41\u0E19\u0E1A\u0E08\u0E32\u0E01\u0E40\u0E0B\u0E34\u0E23\u0E4C\u0E1F\u0E40\u0E27\u0E2D\u0E23\u0E4C\u0E44\u0E1F\u0E25\u0E4C OBS \u0E40\u0E17\u0E48\u0E32\u0E19\u0E31\u0E49\u0E19",
  "ccnotification.gatewayconfig.label.statusisnoterror" : "\u0E2A\u0E16\u0E32\u0E19\u0E30\u0E44\u0E21\u0E48\u0E43\u0E0A\u0E48 ERROR",
  "ccnotification.notification.label.fileInjectionRiskException" : "\u0E02\u0E49\u0E2D\u0E22\u0E01\u0E40\u0E27\u0E49\u0E19\u0E04\u0E27\u0E32\u0E21\u0E40\u0E2A\u0E35\u0E48\u0E22\u0E07\u0E43\u0E19\u0E01\u0E32\u0E23\u0E41\u0E17\u0E23\u0E01\u0E44\u0E1F\u0E25\u0E4C\u0E41\u0E19\u0E1A\u0E2D\u0E35\u0E40\u0E21\u0E25",
  "message.center.button.query" : "\u0E04\u0E49\u0E19\u0E2B\u0E32",
  "ccnotification.gatewayconfig.label.receiverPoolMaxCount" : "Max Receiving Thread Pools",
  "message.center.label.deleteisread" : "\u0E25\u0E1A\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21\u0E17\u0E35\u0E48\u0E2D\u0E48\u0E32\u0E19\u0E41\u0E25\u0E49\u0E27\u0E17\u0E31\u0E49\u0E07\u0E2B\u0E21\u0E14",
  "message.template.message.recipient" : "\u0E1C\u0E39\u0E49\u0E23\u0E31\u0E1A",
  "ccnotification.gatewayconfig.label.protocol" : "\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E42\u0E1B\u0E23\u0E42\u0E15\u0E04\u0E2D\u0E25",
  "ccnotification.notification.recmessagerouterconfig.gatewaynotexist" : "\u0E44\u0E21\u0E48\u0E21\u0E35\u0E40\u0E01\u0E15\u0E40\u0E27\u0E22\u0E4C\u0E17\u0E35\u0E48\u0E40\u0E25\u0E37\u0E2D\u0E01",
  "ccnotification.center.message.setIsRead" : "\u0E17\u0E33\u0E40\u0E04\u0E23\u0E37\u0E48\u0E2D\u0E07\u0E2B\u0E21\u0E32\u0E22\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21\u0E27\u0E48\u0E32\u0E2D\u0E48\u0E32\u0E19\u0E41\u0E25\u0E49\u0E27",
  "ccnotification.gatewayconfig.label.mailPort" : "\u0E1E\u0E2D\u0E23\u0E4C\u0E15",
  "ccnotification.gatewayconfig.label.viewgateway" : "\u0E14\u0E39\u0E40\u0E01\u0E15\u0E40\u0E27\u0E22\u0E4C",
  "ccnotification.gatewayconfig.label.slipWindowSizetitle" : "\u0E02\u0E19\u0E32\u0E14\u0E2B\u0E19\u0E49\u0E32\u0E15\u0E48\u0E32\u0E07\u0E40\u0E25\u0E37\u0E48\u0E2D\u0E19\u0E04\u0E37\u0E2D\u0E15\u0E31\u0E27\u0E40\u0E25\u0E02\u0E17\u0E35\u0E48\u0E40\u0E2B\u0E21\u0E32\u0E30\u0E2A\u0E21",
  "message.center.nocitiction.ineermessage" : "\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21\u0E20\u0E32\u0E22\u0E43\u0E19",
  "ccnotification.gatewayconfig.label.configType" : "\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E40\u0E01\u0E15\u0E40\u0E27\u0E22\u0E4C",
  "ccnotification.gatewayconfig.label.addgatewayfailed" : "\u0E01\u0E32\u0E23\u0E40\u0E1E\u0E34\u0E48\u0E21\u0E01\u0E32\u0E23\u0E01\u0E33\u0E2B\u0E19\u0E14\u0E04\u0E48\u0E32\u0E40\u0E01\u0E15\u0E40\u0E27\u0E22\u0E4C\u0E25\u0E49\u0E21\u0E40\u0E2B\u0E25\u0E27!",
  "ccnotification.flowcontrol.eachThreshold" : "\u0E02\u0E35\u0E14\u0E08\u0E33\u0E01\u0E31\u0E14\u0E23\u0E32\u0E22\u0E27\u0E31\u0E19\u0E02\u0E2D\u0E07\u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25\u0E23\u0E32\u0E22\u0E27\u0E31\u0E19\u0E42\u0E14\u0E22\u0E41\u0E22\u0E01\u0E0A\u0E48\u0E2D\u0E07\u0E17\u0E32\u0E07",
  "message.center.nocitiction.bulletin" : "\u0E1B\u0E23\u0E30\u0E01\u0E32\u0E28",
  "ccnotification.gatewayconfig.label.recFlowController" : "\u0E0A\u0E37\u0E48\u0E2D\u0E15\u0E31\u0E27\u0E04\u0E27\u0E1A\u0E04\u0E38\u0E21\u0E01\u0E32\u0E23\u0E23\u0E31\u0E1A\u0E2A\u0E48\u0E07\u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25\u0E2D\u0E31\u0E1E\u0E25\u0E34\u0E07\u0E01\u0E4C",
  "ccnotification.notification.label.downPermissionFailed" : "\u0E01\u0E32\u0E23\u0E15\u0E23\u0E27\u0E08\u0E40\u0E0A\u0E47\u0E04\u0E2A\u0E34\u0E17\u0E18\u0E34\u0E4C\u0E01\u0E32\u0E23\u0E14\u0E32\u0E27\u0E19\u0E4C\u0E42\u0E2B\u0E25\u0E14\u0E44\u0E1F\u0E25\u0E4C\u0E41\u0E19\u0E1A\u0E2D\u0E35\u0E40\u0E21\u0E25\u0E25\u0E49\u0E21\u0E40\u0E2B\u0E25\u0E27",
  "ccnotification.notification.recmessagerouterconfig.mobilerule" : "\u0E42\u0E1B\u0E23\u0E14\u0E01\u0E23\u0E2D\u0E01\u0E2B\u0E21\u0E32\u0E22\u0E40\u0E25\u0E02\u0E42\u0E17\u0E23\u0E28\u0E31\u0E1E\u0E17\u0E4C\u0E21\u0E37\u0E2D\u0E16\u0E37\u0E2D \u0E41\u0E22\u0E01\u0E2B\u0E21\u0E32\u0E22\u0E40\u0E25\u0E02\u0E42\u0E17\u0E23\u0E28\u0E31\u0E1E\u0E17\u0E4C\u0E21\u0E37\u0E2D\u0E16\u0E37\u0E2D\u0E2B\u0E25\u0E32\u0E22\u0E2B\u0E21\u0E32\u0E22\u0E40\u0E25\u0E02\u0E14\u0E49\u0E27\u0E22\u0E40\u0E04\u0E23\u0E37\u0E48\u0E2D\u0E07\u0E2B\u0E21\u0E32\u0E22\u0E2D\u0E31\u0E12\u0E20\u0E32\u0E04",
  "message.center.nocitiction.sending.records.sended" : "\u0E2A\u0E48\u0E07\u0E17\u0E31\u0E19\u0E17\u0E35",
  "ccnotification.gatewayconfig.label.resendWaitTime" : "\u0E40\u0E27\u0E25\u0E32\u0E23\u0E2D\u0E01\u0E32\u0E23\u0E2A\u0E48\u0E07\u0E2D\u0E35\u0E01\u0E04\u0E23\u0E31\u0E49\u0E07 (ms)",
  "ccnotification.gatewayconfig.label.selectredisconnectedgatewayconfig" : "\u0E42\u0E1B\u0E23\u0E14\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E40\u0E01\u0E15\u0E40\u0E27\u0E22\u0E4C\u0E17\u0E35\u0E48\u0E08\u0E30\u0E15\u0E31\u0E14\u0E01\u0E32\u0E23\u0E40\u0E0A\u0E37\u0E48\u0E2D\u0E21\u0E15\u0E48\u0E2D",
  "ccnotification.notification.label.attachmentIsEmpty" : "\u0E44\u0E1F\u0E25\u0E4C\u0E41\u0E19\u0E1A\u0E27\u0E48\u0E32\u0E07\u0E40\u0E1B\u0E25\u0E48\u0E32",
  "ccnotification.common.label.name" : "\u0E0A\u0E37\u0E48\u0E2D",
  "ccnotification.gatewayconfig.label.destAddrTon" : "\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E23\u0E2B\u0E31\u0E2A\u0E17\u0E35\u0E48\u0E2D\u0E22\u0E39\u0E48\u0E1B\u0E25\u0E32\u0E22\u0E17\u0E32\u0E07",
  "ccnotification.notification.label.attachmentExceed10" : "\u0E2D\u0E31\u0E1B\u0E42\u0E2B\u0E25\u0E14\u0E40\u0E2D\u0E01\u0E2A\u0E32\u0E23\u0E41\u0E19\u0E1A\u0E44\u0E14\u0E49\u0E2A\u0E39\u0E07\u0E2A\u0E38\u0E14 10 \u0E23\u0E32\u0E22\u0E01\u0E32\u0E23",
  "ccnotification.common.title.errorsave" : "\u0E02\u0E49\u0E2D\u0E1C\u0E34\u0E14\u0E1E\u0E25\u0E32\u0E14",
  "ccnotification.gatewayconfig.label.code" : "\u0E42\u0E2B\u0E21\u0E14\u0E40\u0E02\u0E49\u0E32\u0E15\u0E31\u0E27\u0E2D\u0E31\u0E01\u0E29\u0E23",
  "ccnotification.gatewayconfig.label.modifygateway" : "\u0E1B\u0E23\u0E31\u0E1A\u0E40\u0E1B\u0E25\u0E35\u0E48\u0E22\u0E19\u0E40\u0E01\u0E15\u0E40\u0E27\u0E22\u0E4C",
  "ccnotification.notification.recmessagerouterconfig.receivernotexist" : "\u0E44\u0E21\u0E48\u0E21\u0E35\u0E1C\u0E39\u0E49\u0E23\u0E31\u0E1A\u0E17\u0E35\u0E48\u0E40\u0E25\u0E37\u0E2D\u0E01",
  "ccnotification.gatewayconfig.label.resendNumbertitle" : "\u0E08\u0E33\u0E19\u0E27\u0E19\u0E04\u0E23\u0E31\u0E49\u0E07\u0E43\u0E19\u0E01\u0E32\u0E23\u0E2A\u0E48\u0E07\u0E2D\u0E35\u0E01\u0E04\u0E23\u0E31\u0E49\u0E07\u0E04\u0E37\u0E2D\u0E15\u0E31\u0E27\u0E40\u0E25\u0E02\u0E17\u0E35\u0E48\u0E40\u0E2B\u0E21\u0E32\u0E30\u0E2A\u0E21",
  "message.center.nocitiction.callback.receivetime" : "\u0E40\u0E27\u0E25\u0E32\u0E23\u0E31\u0E1A",
  "ccnotification.gatewayconfig.label.defaultsendnoIsError" : "\u0E23\u0E39\u0E1B\u0E41\u0E1A\u0E1A\u0E02\u0E2D\u0E07\u0E17\u0E35\u0E48\u0E2D\u0E22\u0E39\u0E48\u0E2D\u0E35\u0E40\u0E21\u0E25\u0E40\u0E23\u0E34\u0E48\u0E21\u0E15\u0E49\u0E19\u0E44\u0E21\u0E48\u0E16\u0E39\u0E01\u0E15\u0E49\u0E2D\u0E07",
  "ccnotification.smstemplate.message.smstemplateid" : "\u0E23\u0E2B\u0E31\u0E2A\u0E40\u0E17\u0E21\u0E40\u0E1E\u0E25\u0E15 HUAWEI CLOUD \u0E15\u0E49\u0E2D\u0E07\u0E44\u0E21\u0E48\u0E40\u0E27\u0E49\u0E19\u0E27\u0E48\u0E32\u0E07 \u0E44\u0E21\u0E48\u0E22\u0E32\u0E27\u0E40\u0E01\u0E34\u0E19 \u0E2B\u0E23\u0E37\u0E2D\u0E44\u0E21\u0E48\u0E40\u0E1B\u0E47\u0E19\u0E44\u0E1B\u0E15\u0E32\u0E21\u0E02\u0E49\u0E2D\u0E01\u0E4D\u0E32\u0E2B\u0E19\u0E14",
  "ccnotification.notifition.sendmessage.isSendall" : "\u0E2A\u0E48\u0E07\u0E41\u0E25\u0E49\u0E27",
  "ccnotification.notifition.template.label.templateid" : "ID \u0E40\u0E17\u0E21\u0E40\u0E1E\u0E25\u0E15",
  "ccnotification.gatewayconfig.label.messageFlowControlMaxCapsForRecvtitle" : "\u0E2D\u0E31\u0E15\u0E23\u0E32\u0E01\u0E32\u0E23\u0E23\u0E31\u0E1A\u0E2A\u0E39\u0E07\u0E08\u0E38\u0E14\u0E04\u0E37\u0E2D\u0E15\u0E31\u0E27\u0E40\u0E25\u0E02",
  "ccnotification.notifition.sendmessage.enterBCCRecipient" : "\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E1C\u0E39\u0E49\u0E23\u0E31\u0E1A Bcc",
  "message.template.message.typeError" : "\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E01\u0E32\u0E23\u0E41\u0E08\u0E49\u0E07\u0E40\u0E15\u0E37\u0E2D\u0E19\u0E44\u0E21\u0E48\u0E16\u0E39\u0E01\u0E15\u0E49\u0E2D\u0E07",
  "ccnotification.notifition.sendmessage.selectuser" : "\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E1E\u0E19\u0E31\u0E01\u0E07\u0E32\u0E19",
  "ccnotification.gatewayconfig.label.readTimeouttitle" : "\u0E0A\u0E48\u0E27\u0E07\u0E2B\u0E21\u0E27\u0E14\u0E40\u0E27\u0E25\u0E32\u0E01\u0E32\u0E23\u0E23\u0E31\u0E1A\u0E04\u0E37\u0E2D\u0E15\u0E31\u0E27\u0E40\u0E25\u0E02\u0E17\u0E35\u0E48\u0E40\u0E2B\u0E21\u0E32\u0E30\u0E2A\u0E21\, 0: \u0E44\u0E21\u0E48\u0E2B\u0E21\u0E14\u0E40\u0E27\u0E25\u0E32",
  "ccnotification.notification.label.attachment" : "\u0E44\u0E1F\u0E25\u0E4C\u0E41\u0E19\u0E1A",
  "ccnotification.gatewayconfig.label.receiverPoolMinCount" : "Min Receiving Thread Pools",
  "ccnotification.notifition.sendmessage.entertenantname" : "\u0E01\u0E23\u0E2D\u0E01\u0E0A\u0E37\u0E48\u0E2D\u0E1E\u0E37\u0E49\u0E19\u0E17\u0E35\u0E48\u0E02\u0E2D\u0E07\u0E1C\u0E39\u0E49\u0E40\u0E0A\u0E48\u0E32",
  "message.template.button.edit" : "\u0E41\u0E01\u0E49\u0E44\u0E02",
  "ccnotification.smstemplate.message.fail" : "\u0E25\u0E49\u0E21\u0E40\u0E2B\u0E25\u0E27\u0E43\u0E19\u0E01\u0E32\u0E23\u0E2A\u0E23\u0E49\u0E32\u0E07\u0E40\u0E17\u0E21\u0E40\u0E1E\u0E25\u0E15 HUAWEI CLOUD",
  "ccnotification.gatewayconfig.label.maxRcvError" : "\u0E41\u0E1E\u0E47\u0E01\u0E40\u0E01\u0E47\u0E15\u0E02\u0E49\u0E2D\u0E1C\u0E34\u0E14\u0E1E\u0E25\u0E32\u0E14\u0E2A\u0E39\u0E07\u0E2A\u0E38\u0E14\u0E17\u0E35\u0E48\u0E44\u0E14\u0E49\u0E23\u0E31\u0E1A",
  "ccnotification.gatewayconfig.label.maxContentLengthtitle" : "\u0E04\u0E27\u0E32\u0E21\u0E22\u0E32\u0E27\u0E40\u0E19\u0E37\u0E49\u0E2D\u0E2B\u0E32\u0E2A\u0E39\u0E07\u0E2A\u0E38\u0E14 (byte) \u0E04\u0E37\u0E2D\u0E15\u0E31\u0E27\u0E40\u0E25\u0E02 \u0E0B\u0E36\u0E48\u0E07\u0E16\u0E39\u0E01\u0E43\u0E0A\u0E49\u0E40\u0E1B\u0E47\u0E19\u0E1E\u0E37\u0E49\u0E19\u0E10\u0E32\u0E19\u0E01\u0E32\u0E23\u0E41\u0E1A\u0E48\u0E07\u0E17\u0E35\u0E48\u0E15\u0E23\u0E07\u0E01\u0E31\u0E19 \u0E40\u0E0A\u0E48\u0E19 140",
  "message.template.message.frequently" : "\u0E08\u0E33\u0E19\u0E27\u0E19\u0E02\u0E2D\u0E07\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21\u0E17\u0E35\u0E48\u0E2A\u0E48\u0E07\u0E40\u0E01\u0E34\u0E19\u0E04\u0E48\u0E32\u0E02\u0E35\u0E14\u0E40\u0E23\u0E34\u0E48\u0E21\u0E23\u0E32\u0E22\u0E27\u0E31\u0E19",
  "message.center.message.selectMessages" : "\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21",
  "message.center.label.isRead" : "\u0E17\u0E33\u0E40\u0E04\u0E23\u0E37\u0E48\u0E2D\u0E07\u0E2B\u0E21\u0E32\u0E22\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21\u0E27\u0E48\u0E32\u0E2D\u0E48\u0E32\u0E19\u0E41\u0E25\u0E49\u0E27",
  "ccnotification.notifition.sendmessage.limitdate" : "\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E27\u0E31\u0E19\u0E17\u0E35\u0E48\u0E01\u0E48\u0E2D\u0E19 2038-1-19",
  "message.template.message.titleError" : "\u0E0A\u0E37\u0E48\u0E2D\u0E40\u0E17\u0E21\u0E40\u0E1E\u0E25\u0E15\u0E44\u0E21\u0E48\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E40\u0E01\u0E34\u0E19 200 \u0E2D\u0E31\u0E01\u0E02\u0E23\u0E30",
  "message.template.type.internal" : "\u0E01\u0E32\u0E23\u0E41\u0E08\u0E49\u0E07\u0E40\u0E15\u0E37\u0E2D\u0E19\u0E20\u0E32\u0E22\u0E43\u0E19",
  "ccnotification.notifition.sendmessage.selecttenant" : "\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E1C\u0E39\u0E49\u0E40\u0E0A\u0E48\u0E32",
  "ccnotification.notification.variable.templatetype" : "\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E40\u0E17\u0E21\u0E40\u0E1E\u0E25\u0E15",
  "ccnotification.gatewayconfig.label.senderPoolMaxCounttitle" : "Max Sending Thread Pools \u0E04\u0E37\u0E2D\u0E15\u0E31\u0E27\u0E40\u0E25\u0E02 \u0E40\u0E0A\u0E48\u0E19 200",
  "ccnotification.smshuawei.message.invalidappadress" : "\u0E17\u0E35\u0E48\u0E2D\u0E22\u0E39\u0E48\u0E2A\u0E33\u0E2B\u0E23\u0E31\u0E1A\u0E40\u0E02\u0E49\u0E32\u0E16\u0E36\u0E07\u0E41\u0E2D\u0E1B\u0E44\u0E21\u0E48\u0E16\u0E39\u0E01\u0E15\u0E49\u0E2D\u0E07",
  "ccnotification.gatewayconfig.label.receiverPoolQueueCapacity" : "Receiving Thread Pool Queue Length",
  "ccnotification.gatewayconfig.label.checkConfigName" : "\u0E04\u0E48\u0E32\u0E04\u0E37\u0E2D\u0E2A\u0E15\u0E23\u0E34\u0E07\u0E17\u0E35\u0E48\u0E21\u0E35\u0E15\u0E31\u0E27\u0E40\u0E25\u0E02\u0E41\u0E25\u0E30\u0E15\u0E31\u0E27\u0E2D\u0E31\u0E01\u0E29\u0E23\u0E19\u0E49\u0E2D\u0E22\u0E01\u0E27\u0E48\u0E32 10 \u0E15\u0E31\u0E27",
  "ccnotification.gatewayconfig.label.specialStrValidate" : "\u0E2D\u0E19\u0E38\u0E0D\u0E32\u0E15\u0E43\u0E2B\u0E49\u0E43\u0E0A\u0E49\u0E40\u0E09\u0E1E\u0E32\u0E30\u0E15\u0E31\u0E27\u0E2D\u0E31\u0E01\u0E29\u0E23\u0E1E\u0E34\u0E21\u0E1E\u0E4C\u0E43\u0E2B\u0E0D\u0E48 \u0E15\u0E31\u0E27\u0E1E\u0E34\u0E21\u0E1E\u0E4C\u0E40\u0E25\u0E47\u0E01 \u0E41\u0E25\u0E30\u0E15\u0E31\u0E27\u0E40\u0E25\u0E02\u0E40\u0E17\u0E48\u0E32\u0E19\u0E31\u0E49\u0E19",
  "message.template.type.out" : "\u0E01\u0E32\u0E23\u0E41\u0E08\u0E49\u0E07\u0E40\u0E15\u0E37\u0E2D\u0E19\u0E20\u0E32\u0E22\u0E19\u0E2D\u0E01",
  "ccnotification.notifition.template.message.error" : "\u0E04\u0E48\u0E32\u0E2D\u0E34\u0E19\u0E1E\u0E38\u0E15\u0E44\u0E21\u0E48\u0E16\u0E39\u0E01\u0E15\u0E49\u0E2D\u0E07",
  "ccnotification.gatewayconfig.label.systemType" : "\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17 ESME",
  "message.center.label.delete" : "\u0E25\u0E1A",
  "ccnotification.gatewayconfig.label.userid" : "ID \u0E1C\u0E39\u0E49\u0E43\u0E0A\u0E49",
  "ccnotification.agent.message.updateCtiAndDbFail" : "\u0E2D\u0E31\u0E1B\u0E40\u0E14\u0E15 cti \u0E41\u0E25\u0E30 Db \u0E25\u0E49\u0E21\u0E40\u0E2B\u0E25\u0E27",
  "message.center.label.reply" : "\u0E15\u0E2D\u0E1A\u0E01\u0E25\u0E31\u0E1A",
  "ccnotification.notifition.sendmessage.enterusername" : "\u0E01\u0E23\u0E2D\u0E01\u0E0A\u0E37\u0E48\u0E2D",
  "message.template.message.agentRecipientError" : "\u0E1C\u0E39\u0E49\u0E23\u0E31\u0E1A\u0E1A\u0E32\u0E07\u0E23\u0E32\u0E22\u0E44\u0E21\u0E48\u0E44\u0E14\u0E49\u0E40\u0E01\u0E35\u0E48\u0E22\u0E27\u0E1E\u0E31\u0E19\u0E01\u0E31\u0E1A\u0E40\u0E08\u0E49\u0E32\u0E2B\u0E19\u0E49\u0E32\u0E17\u0E35\u0E48 \u0E42\u0E1B\u0E23\u0E14\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E1C\u0E39\u0E49\u0E23\u0E31\u0E1A\u0E23\u0E32\u0E22\u0E2D\u0E37\u0E48\u0E19",
  "ccnotification.notification.label.deleteAttachmentSuccess" : "\u0E25\u0E1A\u0E44\u0E1F\u0E25\u0E4C\u0E41\u0E19\u0E1A\u0E2A\u0E33\u0E40\u0E23\u0E47\u0E08",
  "ccnotification.gatewayconfig.label.deviceTypeDescript" : "\u0E04\u0E33\u0E2D\u0E18\u0E34\u0E1A\u0E32\u0E22\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E2D\u0E38\u0E1B\u0E01\u0E23\u0E13\u0E4C",
  "message.template.message.inputTemplateName" : "\u0E01\u0E23\u0E2D\u0E01\u0E0A\u0E37\u0E48\u0E2D\u0E40\u0E17\u0E21\u0E40\u0E1E\u0E25\u0E15",
  "message.template.message.queryagentbebindedfailed" : "\u0E25\u0E49\u0E21\u0E40\u0E2B\u0E25\u0E27\u0E43\u0E19\u0E01\u0E32\u0E23\u0E04\u0E49\u0E19\u0E2B\u0E32\u0E1C\u0E39\u0E49\u0E43\u0E0A\u0E49\u0E17\u0E35\u0E48\u0E40\u0E01\u0E35\u0E48\u0E22\u0E27\u0E1E\u0E31\u0E19\u0E01\u0E31\u0E1A\u0E40\u0E08\u0E49\u0E32\u0E2B\u0E19\u0E49\u0E32\u0E17\u0E35\u0E48",
  "ccnotification.notifition.template.label.varnumber" : "\u0E08\u0E33\u0E19\u0E27\u0E19\u0E15\u0E31\u0E27\u0E41\u0E1B\u0E23",
  "ccnotification.notifition.template.label.signatureName" : "\u0E0A\u0E37\u0E48\u0E2D\u0E25\u0E32\u0E22\u0E40\u0E0B\u0E47\u0E19",
  "message.center.label.modify" : "\u0E41\u0E01\u0E49\u0E44\u0E02",
  "message.template.message.cancel" : "\u0E22\u0E01\u0E40\u0E25\u0E34\u0E01",
  "ccnotification.gatewayconfig.label.selectrebuildgatewayconfig" : "\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E01\u0E32\u0E23\u0E01\u0E33\u0E2B\u0E19\u0E14\u0E04\u0E48\u0E32\u0E40\u0E01\u0E15\u0E40\u0E27\u0E22\u0E4C\u0E17\u0E35\u0E48\u0E08\u0E30\u0E16\u0E39\u0E01\u0E2A\u0E23\u0E49\u0E32\u0E07\u0E43\u0E2B\u0E21\u0E48",
  "ccnotification.gatewayconfig.label.splitMsgConcatModetitle" : "\u0E42\u0E2B\u0E25\u0E14\u0E23\u0E27\u0E21\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21 SMS \u0E22\u0E32\u0E27 0: \u0E2A\u0E48\u0E27\u0E19\u0E2B\u0E31\u0E27\u0E02\u0E2D\u0E07\u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25\u0E1C\u0E39\u0E49\u0E43\u0E0A\u0E49 GSM; 1: \u0E1E\u0E32\u0E23\u0E32\u0E21\u0E34\u0E40\u0E15\u0E2D\u0E23\u0E4C SMPP",
  "ccnotification.gatewayconfig.label.mailHosttitle" : "\u0E17\u0E35\u0E48\u0E2D\u0E22\u0E39\u0E48\u0E41\u0E21\u0E48\u0E02\u0E48\u0E32\u0E22 \u0E40\u0E0A\u0E48\u0E19 127.0.0.1 \u0E2B\u0E23\u0E37\u0E2D www.mail.com",
  "ccnotification.gatewayconfig.label.paramContainsSpecialChar" : "\u0E1E\u0E32\u0E23\u0E32\u0E21\u0E34\u0E40\u0E15\u0E2D\u0E23\u0E4C\u0E21\u0E35\u0E2D\u0E31\u0E01\u0E02\u0E23\u0E30\u0E1E\u0E34\u0E40\u0E28\u0E29",
  "ccnotification.gatewayconfig.label.smppPduTypeIsError" : "\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17 SMS SMPP PDU \u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E40\u0E1B\u0E47\u0E19 1: submit_sm\, 2: data_sm",
  "ccnotification.gatewayconfig.label.bindModetitle" : "\u0E23\u0E30\u0E1A\u0E38\u0E42\u0E2B\u0E21\u0E14 TX/RX 1: \u0E42\u0E2B\u0E21\u0E14 RX; 2: \u0E42\u0E2B\u0E21\u0E14 TX; 9: \u0E42\u0E2B\u0E21\u0E14 TX/RX",
  "ccnotification.gatewayconfig.label.selectredgatewayconfig" : "\u0E01\u0E32\u0E23\u0E40\u0E1D\u0E49\u0E32\u0E15\u0E34\u0E14\u0E15\u0E32\u0E21\u0E40\u0E01\u0E15\u0E40\u0E27\u0E22\u0E4C\u0E17\u0E35\u0E48\u0E40\u0E25\u0E37\u0E2D\u0E01:",
  "ccnotification.gatewayconfig.label.splitMsgConcatMode" : "\u0E42\u0E2B\u0E21\u0E14\u0E01\u0E32\u0E23\u0E15\u0E48\u0E2D\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21\u0E22\u0E32\u0E27",
  "ccnotification.notifition.template.button.down" : "\u0E25\u0E07",
  "message.template.mediaType.internal" : "\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21\u0E20\u0E32\u0E22\u0E43\u0E19",
  "ccnotification.common.title.delete" : "\u0E25\u0E1A",
  "ccnotification.gatewayconfig.label.gatewayname" : "\u0E0A\u0E37\u0E48\u0E2D\u0E40\u0E01\u0E15\u0E40\u0E27\u0E22\u0E4C",
  "ccnotification.notifition.variable.templatename" : "\u0E0A\u0E37\u0E48\u0E2D\u0E40\u0E17\u0E21\u0E40\u0E1E\u0E25\u0E15",
  "ccnotification.gatewayconfig.label.paramIsNegative" : "\u0E15\u0E31\u0E27\u0E40\u0E25\u0E02\u0E44\u0E21\u0E48\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E15\u0E34\u0E14\u0E25\u0E1A",
  "ccnotification.smshuawei.message.deletesuccess" : "\u0E0A\u0E48\u0E2D\u0E07\u0E2A\u0E31\u0E0D\u0E0D\u0E32\u0E13 SMS \u0E16\u0E39\u0E01\u0E25\u0E1A\u0E41\u0E25\u0E49\u0E27",
  "ccnotification.smshuawei.message.fail" : "\u0E25\u0E49\u0E21\u0E40\u0E2B\u0E25\u0E27",
  "ccnotification.notifition.sendmessage.referenceTemplate" : "\u0E40\u0E17\u0E21\u0E40\u0E1E\u0E25\u0E15\u0E2D\u0E49\u0E32\u0E07\u0E2D\u0E34\u0E07",
  "ccnotification.agent.contact.downloadFail" : "\u0E14\u0E32\u0E27\u0E19\u0E4C\u0E42\u0E2B\u0E25\u0E14\u0E25\u0E49\u0E21\u0E40\u0E2B\u0E25\u0E27",
  "ccnotification.notification.recmessagerouterconfig.deletenum.outstripping" : "\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E25\u0E1A\u0E1A\u0E31\u0E19\u0E17\u0E36\u0E01\u0E44\u0E14\u0E49\u0E21\u0E32\u0E01\u0E17\u0E35\u0E48\u0E2A\u0E38\u0E14 100 \u0E1A\u0E31\u0E19\u0E17\u0E36\u0E01\u0E43\u0E19\u0E41\u0E15\u0E48\u0E25\u0E30\u0E04\u0E23\u0E31\u0E49\u0E07",
  "ccnotification.gatewayconfig.label.smppPduTypetitle" : "1:submit_sm  2:data_sm",
  "ccnotification.notifition.sendmessage.selectbyworkno" : "ID \u0E1E\u0E19\u0E31\u0E01\u0E07\u0E32\u0E19",
  "message.center.nocitiction.sending.records.status" : "\u0E2A\u0E16\u0E32\u0E19\u0E30",
  "ccnotification.notification.recmessagerouterconfig.serviceName" : "\u0E0A\u0E37\u0E48\u0E2D\u0E1A\u0E23\u0E34\u0E01\u0E32\u0E23",
  "ccnotification.gatewayconfig.label.localPort" : "\u0E1E\u0E2D\u0E23\u0E4C\u0E15\u0E25\u0E39\u0E01\u0E04\u0E49\u0E32",
  "ccnotification.gatewayconfig.label.interfaceVersion" : "\u0E2B\u0E21\u0E32\u0E22\u0E40\u0E25\u0E02\u0E40\u0E27\u0E2D\u0E23\u0E4C\u0E0A\u0E31\u0E19\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21\u0E40\u0E02\u0E49\u0E32\u0E2A\u0E39\u0E48\u0E23\u0E30\u0E1A\u0E1A",
  "ccnotification.notification.recmessagerouterconfig.receiver" : "\u0E1C\u0E39\u0E49\u0E23\u0E31\u0E1A",
  "message.template.message.inputRecipient" : "\u0E1B\u0E49\u0E2D\u0E19\u0E1C\u0E39\u0E49\u0E23\u0E31\u0E1A",
  "message.center.button.clear" : "\u0E23\u0E35\u0E40\u0E0B\u0E47\u0E17",
  "ccnotification.gatewayconfig.label.heartbeatNoRespCounttitle" : "\u0E08\u0E33\u0E19\u0E27\u0E19\u0E04\u0E23\u0E31\u0E49\u0E07\u0E43\u0E19\u0E01\u0E32\u0E23\u0E40\u0E0A\u0E37\u0E48\u0E2D\u0E21\u0E15\u0E48\u0E2D\u0E2A\u0E39\u0E07\u0E2A\u0E38\u0E14\u0E40\u0E21\u0E37\u0E48\u0E2D\u0E44\u0E21\u0E48\u0E21\u0E35\u0E01\u0E32\u0E23\u0E15\u0E2D\u0E1A\u0E2A\u0E19\u0E2D\u0E07\u0E02\u0E2D\u0E07\u0E01\u0E32\u0E23\u0E40\u0E15\u0E49\u0E19\u0E02\u0E2D\u0E07\u0E2B\u0E31\u0E27\u0E43\u0E08\u0E04\u0E37\u0E2D\u0E15\u0E31\u0E27\u0E40\u0E25\u0E02\u0E17\u0E35\u0E48\u0E40\u0E2B\u0E21\u0E32\u0E30\u0E2A\u0E21",
  "message.template.message.basicInformation" : "\u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25\u0E1E\u0E37\u0E49\u0E19\u0E10\u0E32\u0E19",
  "ccnotification.notifition.variable.searchTempdateName" : "\u0E01\u0E23\u0E2D\u0E01\u0E0A\u0E37\u0E48\u0E2D\u0E40\u0E17\u0E21\u0E40\u0E1E\u0E25\u0E15",
  "ccnotification.notification.label.createTemFileFailed" : "\u0E25\u0E49\u0E21\u0E40\u0E2B\u0E25\u0E27\u0E43\u0E19\u0E01\u0E32\u0E23\u0E2A\u0E23\u0E49\u0E32\u0E07\u0E44\u0E1F\u0E25\u0E4C\u0E0A\u0E31\u0E48\u0E27\u0E04\u0E23\u0E32\u0E27",
  "ccnotification.gatewayconfig.label.mailPorttitle" : "\u0E2B\u0E21\u0E32\u0E22\u0E40\u0E25\u0E02\u0E1E\u0E2D\u0E23\u0E4C\u0E15\u0E2D\u0E22\u0E39\u0E48\u0E23\u0E30\u0E2B\u0E27\u0E48\u0E32\u0E07 0 \u0E16\u0E36\u0E07 65535 \u0E2B\u0E21\u0E32\u0E22\u0E40\u0E25\u0E02\u0E1E\u0E2D\u0E23\u0E4C\u0E15 SMTP \u0E40\u0E23\u0E34\u0E48\u0E21\u0E15\u0E49\u0E19\u0E04\u0E37\u0E2D 25",
  "ccnotification.common.title.warning" : "\u0E04\u0E33\u0E40\u0E15\u0E37\u0E2D\u0E19",
  "ccnotification.center.message.var.type" : "\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E15\u0E31\u0E27\u0E41\u0E1B\u0E23:",
  "ccnotification.gatewayconfig.label.readTimeout" : "\u0E0A\u0E48\u0E27\u0E07\u0E2B\u0E21\u0E14\u0E40\u0E27\u0E25\u0E32\u0E01\u0E32\u0E23\u0E23\u0E31\u0E1A (ms)",
  "ccnotification.gatewayconfig.label.registeredDeliverytitle" : "\u0E23\u0E32\u0E22\u0E07\u0E32\u0E19\u0E01\u0E32\u0E23\u0E22\u0E37\u0E19\u0E22\u0E31\u0E19\u0E2A\u0E16\u0E32\u0E19\u0E30\u0E01\u0E32\u0E23\u0E2A\u0E48\u0E07\u0E04\u0E37\u0E19\u0E04\u0E37\u0E2D\u0E15\u0E31\u0E27\u0E40\u0E25\u0E02",
  "ccnotification.gatewayconfig.label.modifygatewayfailed" : "\u0E01\u0E32\u0E23\u0E1B\u0E23\u0E31\u0E1A\u0E40\u0E1B\u0E25\u0E35\u0E48\u0E22\u0E19\u0E01\u0E32\u0E23\u0E01\u0E33\u0E2B\u0E19\u0E14\u0E04\u0E48\u0E32\u0E40\u0E01\u0E15\u0E40\u0E27\u0E22\u0E4C\u0E25\u0E49\u0E21\u0E40\u0E2B\u0E25\u0E27!",
  "ccnotification.notifition.template.button.addvar" : "\u0E2A\u0E23\u0E49\u0E32\u0E07",
  "ccnotification.gatewayconfig.label.sourceAddrNpi" : "Source Address Code Scheme",
  "message.center.label.newMessage" : "\u0E2A\u0E48\u0E07\u0E01\u0E32\u0E23\u0E41\u0E08\u0E49\u0E07\u0E40\u0E15\u0E37\u0E2D\u0E19",
  "ccnotification.notifition.sendmessage.sysmessage" : "\u0E01\u0E32\u0E23\u0E41\u0E08\u0E49\u0E07\u0E40\u0E15\u0E37\u0E2D\u0E19\u0E02\u0E2D\u0E07\u0E23\u0E30\u0E1A\u0E1A",
  "ccnotification.common.button.finish" : "\u0E2A\u0E33\u0E40\u0E23\u0E47\u0E08",
  "ccnotification.smshuawei.label.config.explain" : "\u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25\u0E01\u0E32\u0E23\u0E01\u0E33\u0E2B\u0E19\u0E14\u0E04\u0E48\u0E32\u0E15\u0E49\u0E2D\u0E07\u0E40\u0E2B\u0E21\u0E37\u0E2D\u0E19\u0E01\u0E31\u0E1A\u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25\u0E01\u0E32\u0E23\u0E01\u0E33\u0E2B\u0E19\u0E14\u0E04\u0E48\u0E32\u0E02\u0E2D\u0E07 HUAWEI CLOUD SMS",
  "ccnotification.smshuawei.message.securityInfo" : "\u0E2B\u0E32\u0E01\u0E1B\u0E34\u0E14\u0E01\u0E32\u0E23\u0E15\u0E23\u0E27\u0E08\u0E2A\u0E2D\u0E1A\u0E43\u0E1A\u0E23\u0E31\u0E1A\u0E23\u0E2D\u0E07 \u0E21\u0E35\u0E04\u0E27\u0E32\u0E21\u0E40\u0E2A\u0E35\u0E48\u0E22\u0E07\u0E17\u0E35\u0E48\u0E08\u0E30\u0E16\u0E39\u0E01\u0E1B\u0E25\u0E2D\u0E21\u0E41\u0E1B\u0E25\u0E07\u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25\u0E1B\u0E23\u0E30\u0E08\u0E33\u0E15\u0E31\u0E27 \u0E02\u0E2D\u0E41\u0E19\u0E30\u0E19\u0E33\u0E43\u0E2B\u0E49\u0E40\u0E1B\u0E34\u0E14\u0E43\u0E0A\u0E49\u0E07\u0E32\u0E19\u0E01\u0E32\u0E23\u0E15\u0E23\u0E27\u0E08\u0E2A\u0E2D\u0E1A\u0E43\u0E1A\u0E23\u0E31\u0E1A\u0E23\u0E2D\u0E07",
  "ccnotification.notifition.sendmessage.entercompanyName" : "\u0E01\u0E23\u0E2D\u0E01\u0E0A\u0E37\u0E48\u0E2D\u0E1A\u0E23\u0E34\u0E29\u0E31\u0E17",
  "ccnotification.smshuawei.label.certVerifyEnabled" : "\u0E40\u0E1B\u0E34\u0E14\u0E43\u0E0A\u0E49\u0E07\u0E32\u0E19\u0E01\u0E32\u0E23\u0E15\u0E23\u0E27\u0E08\u0E2A\u0E2D\u0E1A\u0E43\u0E1A\u0E23\u0E31\u0E1A\u0E23\u0E2D\u0E07\u0E2B\u0E23\u0E37\u0E2D\u0E44\u0E21\u0E48",
  "ccnotification.notifition.receivermessage.isread" : "\u0E2D\u0E48\u0E32\u0E19\u0E41\u0E25\u0E49\u0E27",
  "message.template.message.deleteSuccess" : "\u0E40\u0E17\u0E21\u0E40\u0E1E\u0E25\u0E15\u0E01\u0E32\u0E23\u0E41\u0E08\u0E49\u0E07\u0E40\u0E15\u0E37\u0E2D\u0E19\u0E16\u0E39\u0E01\u0E25\u0E1A\u0E41\u0E25\u0E49\u0E27",
  "ccnotification.gatewayconfig.label.paramIsNotInRange" : "\u0E0A\u0E48\u0E27\u0E07\u0E02\u0E2D\u0E07\u0E04\u0E48\u0E32\u0E1E\u0E32\u0E23\u0E32\u0E21\u0E34\u0E40\u0E15\u0E2D\u0E23\u0E4C\u0E44\u0E21\u0E48\u0E16\u0E39\u0E01\u0E15\u0E49\u0E2D\u0E07",
  "message.center.nocitiction.callback.attachment" : "\u0E44\u0E1F\u0E25\u0E4C\u0E41\u0E19\u0E1A",
  "ccnotification.notifition.variable.variablename" : "\u0E0A\u0E37\u0E48\u0E2D\u0E15\u0E31\u0E27\u0E41\u0E1B\u0E23",
  "ccnotification.flowcontrol.title" : "\u0E15\u0E31\u0E49\u0E07\u0E04\u0E48\u0E32\u0E01\u0E32\u0E23\u0E01\u0E33\u0E2B\u0E19\u0E14\u0E04\u0E48\u0E32\u0E01\u0E32\u0E23\u0E41\u0E08\u0E49\u0E07\u0E40\u0E15\u0E37\u0E2D\u0E19",
  "message.center.nocitiction.email" : "\u0E2D\u0E35\u0E40\u0E21\u0E25",
  "ccnotification.sms.gateway" : "เกตเวย์ SMS",
  "ccnotification.gatewayconfig.label.defaultreceinfotitle" : "กฎนําหน้าหมายเลขที่ยอมรับ: ตัวอย่างเช่น หากมีการกําหนดค่า 130 ตัวเลขที่ขึ้นต้นด้วย 130 จะถูกจับคู่ จํานวนหลายตัวคั่นด้วยอัฒภาค (;).",
  "message.template.message.ccOrBccRecipientError" : "\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21\u0E16\u0E39\u0E01\u0E2A\u0E48\u0E07\u0E41\u0E25\u0E49\u0E27 \u0E44\u0E21\u0E48\u0E21\u0E35\u0E1C\u0E39\u0E49\u0E23\u0E31\u0E1A Cc \u0E2B\u0E23\u0E37\u0E2D Bcc",
  "ccnotification.gatewayconfig.label.smppPduType" : "\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17 SMPP PDU",
  "ccnotification.gatewayconfig.label.receiverPoolMaxCounttitle" : "Max Receiving Thread Pools \u0E04\u0E37\u0E2D\u0E15\u0E31\u0E27\u0E40\u0E25\u0E02 \u0E40\u0E0A\u0E48\u0E19 200",
  "message.template.message.agentbebinded" : "\u0E1C\u0E39\u0E49\u0E23\u0E31\u0E1A\u0E17\u0E35\u0E48\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E44\u0E21\u0E48\u0E40\u0E01\u0E35\u0E48\u0E22\u0E27\u0E1E\u0E31\u0E19\u0E01\u0E31\u0E1A\u0E40\u0E08\u0E49\u0E32\u0E2B\u0E19\u0E49\u0E32\u0E17\u0E35\u0E48",
  "ccnotification.gatewayconfig.label.requestParamIsNull" : "\u0E1E\u0E32\u0E23\u0E32\u0E21\u0E34\u0E40\u0E15\u0E2D\u0E23\u0E4C\u0E01\u0E32\u0E23\u0E23\u0E49\u0E2D\u0E07\u0E02\u0E2D\u0E27\u0E48\u0E32\u0E07\u0E40\u0E1B\u0E25\u0E48\u0E32",
  "ccnotification.notification.recmessagerouterconfig.selectitem" : "\u0E42\u0E1B\u0E23\u0E14\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E1A\u0E31\u0E19\u0E17\u0E36\u0E01",
  "ccnotification.notifition.variable.insertvariable" : "\u0E01\u0E32\u0E23\u0E40\u0E1E\u0E34\u0E48\u0E21\u0E04\u0E38\u0E13\u0E2A\u0E21\u0E1A\u0E31\u0E15\u0E34",
  "ccnotification.common.title.confreset" : "\u0E22\u0E37\u0E19\u0E22\u0E31\u0E19\u0E01\u0E32\u0E23\u0E15\u0E31\u0E49\u0E07\u0E43\u0E2B\u0E21\u0E48",
  "ccnotification.notifition.template.label.hwtemplateid" : "ID \u0E40\u0E17\u0E21\u0E40\u0E1E\u0E25\u0E15 HUAWEI CLOUD",
  "ccnotification.gatewayconfig.label.messageprotocol" : "\u0E1E\u0E32\u0E23\u0E32\u0E21\u0E34\u0E40\u0E15\u0E2D\u0E23\u0E4C\u0E42\u0E1B\u0E23\u0E42\u0E15\u0E04\u0E2D\u0E25\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21",
  "message.template.message.createTemplateFail" : "\u0E25\u0E49\u0E21\u0E40\u0E2B\u0E25\u0E27\u0E43\u0E19\u0E01\u0E32\u0E23\u0E2A\u0E23\u0E49\u0E32\u0E07\u0E40\u0E17\u0E21\u0E40\u0E1E\u0E25\u0E15\u0E01\u0E32\u0E23\u0E41\u0E08\u0E49\u0E07\u0E40\u0E15\u0E37\u0E2D\u0E19",
  "ccnotification.gatewayconfig.label.slipWindowSize" : "",
  "message.center.nocitiction.sending.records.unsend" : "\u0E01\u0E32\u0E23\u0E2A\u0E48\u0E07\u0E15\u0E32\u0E21\u0E01\u0E33\u0E2B\u0E19\u0E14\u0E40\u0E27\u0E25\u0E32",
  "ccnotification.gatewayconfig.label.replaceIfPresentFlag" : "\u0E15\u0E31\u0E27\u0E1A\u0E48\u0E07\u0E0A\u0E35\u0E49\u0E01\u0E32\u0E23\u0E40\u0E1B\u0E25\u0E35\u0E48\u0E22\u0E19",
  "ccnotification.notifition.sendmessage.smsconfignull" : "\u0E01\u0E32\u0E23\u0E01\u0E33\u0E2B\u0E19\u0E14\u0E04\u0E48\u0E32\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21 SMS \u0E27\u0E48\u0E32\u0E07\u0E40\u0E1B\u0E25\u0E48\u0E32 \u0E40\u0E25\u0E37\u0E2D\u0E01\u0E01\u0E32\u0E23\u0E15\u0E31\u0E49\u0E07\u0E04\u0E48\u0E32> \u0E01\u0E32\u0E23\u0E01\u0E33\u0E2B\u0E19\u0E14\u0E04\u0E48\u0E32 SMS \u0E40\u0E1E\u0E37\u0E48\u0E2D\u0E01\u0E33\u0E2B\u0E19\u0E14\u0E04\u0E48\u0E32\u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25 SMS",
  "message.template.message.type" : "\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E01\u0E32\u0E23\u0E41\u0E08\u0E49\u0E07\u0E40\u0E15\u0E37\u0E2D\u0E19",
  "message.template.message.createSuccess" : "\u0E40\u0E17\u0E21\u0E40\u0E1E\u0E25\u0E15\u0E01\u0E32\u0E23\u0E41\u0E08\u0E49\u0E07\u0E40\u0E15\u0E37\u0E2D\u0E19\u0E16\u0E39\u0E01\u0E2A\u0E23\u0E49\u0E32\u0E07\u0E41\u0E25\u0E49\u0E27",
  "ccnotification.gatewayconfig.label.maxMessageLength" : "\u0E04\u0E27\u0E32\u0E21\u0E22\u0E32\u0E27\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21\u0E2A\u0E39\u0E07\u0E2A\u0E38\u0E14 (byte)",
  "ccnotification.gatewayconfig.label.modifygatewaysuccess" : "\u0E1B\u0E23\u0E31\u0E1A\u0E40\u0E1B\u0E25\u0E35\u0E48\u0E22\u0E19\u0E01\u0E32\u0E23\u0E01\u0E33\u0E2B\u0E19\u0E14\u0E04\u0E48\u0E32\u0E40\u0E01\u0E15\u0E40\u0E27\u0E22\u0E4C\u0E2A\u0E33\u0E40\u0E23\u0E47\u0E08!",
  "ccnotification.gatewayconfig.label.defaultemailaddr" : "\u0E2D\u0E35\u0E40\u0E21\u0E25\u0E40\u0E23\u0E34\u0E48\u0E21\u0E15\u0E49\u0E19",
  "ccnotification.gatewayconfig.label.defaultsendname" : "\u0E0A\u0E37\u0E48\u0E2D\u0E1C\u0E39\u0E49\u0E43\u0E0A\u0E49\u0E40\u0E23\u0E34\u0E48\u0E21\u0E15\u0E49\u0E19",
  "ccnotification.notification.recmessagerouterconfig.willdeleteselectconfig" : "\u0E04\u0E38\u0E13\u0E41\u0E19\u0E48\u0E43\u0E08\u0E27\u0E48\u0E32\u0E15\u0E49\u0E2D\u0E07\u0E01\u0E32\u0E23\u0E25\u0E1A\u0E01\u0E32\u0E23\u0E01\u0E33\u0E2B\u0E19\u0E14\u0E04\u0E48\u0E32\u0E17\u0E35\u0E48\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E43\u0E0A\u0E48\u0E2B\u0E23\u0E37\u0E2D\u0E44\u0E21\u0E48?",
  "ccnotification.notification.recmessagerouterconfig.selectgateway" : "\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E40\u0E01\u0E15\u0E40\u0E27\u0E22\u0E4C",
  "ccnotification.notifition.variable.close.syntax.error2" : "\u0E15\u0E23\u0E27\u0E08\u0E40\u0E0A\u0E47\u0E04\u0E27\u0E48\u0E32 ${} \u0E16\u0E39\u0E01\u0E1B\u0E34\u0E14\u0E2B\u0E23\u0E37\u0E2D\u0E44\u0E21\u0E48",
  "ccnotification.notifition.variable.close.syntax.error1" : "\u0E40\u0E19\u0E37\u0E49\u0E2D\u0E2B\u0E32\u0E40\u0E17\u0E21\u0E40\u0E1E\u0E25\u0E15\u0E21\u0E35\u0E44\u0E27\u0E22\u0E32\u0E01\u0E23\u0E13\u0E4C\u0E1C\u0E34\u0E14\u0E17\u0E35\u0E48\u0E2D\u0E22\u0E39\u0E48\u0E43\u0E01\u0E25\u0E49",
  "ccnotification.gatewayconfig.label.selectdeletegatewayconfig" : "\u0E42\u0E1B\u0E23\u0E14\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E01\u0E32\u0E23\u0E01\u0E33\u0E2B\u0E19\u0E14\u0E04\u0E48\u0E32\u0E40\u0E01\u0E15\u0E40\u0E27\u0E22\u0E4C\u0E17\u0E35\u0E48\u0E08\u0E30\u0E25\u0E1A",
  "ccnotification.gatewayconfig.label.senderPoolMinCounttitle" : "Min Sending Thread Pools \u0E04\u0E37\u0E2D\u0E15\u0E31\u0E27\u0E40\u0E25\u0E02 \u0E40\u0E0A\u0E48\u0E19 20",
  "ccnotification.gatewayconfig.label.certFile" : "\u0E44\u0E1F\u0E25\u0E4C\u0E43\u0E1A\u0E23\u0E31\u0E1A\u0E23\u0E2D\u0E07",
  "ccnotification.gatewayconfig.label.maxRcvErrortitle" : "\u0E41\u0E1E\u0E47\u0E01\u0E40\u0E01\u0E47\u0E15\u0E02\u0E49\u0E2D\u0E1C\u0E34\u0E14\u0E1E\u0E25\u0E32\u0E14\u0E2A\u0E39\u0E07\u0E2A\u0E38\u0E14\u0E17\u0E35\u0E48\u0E44\u0E14\u0E49\u0E23\u0E31\u0E1A\u0E04\u0E37\u0E2D\u0E15\u0E31\u0E27\u0E40\u0E25\u0E02\u0E17\u0E35\u0E48\u0E40\u0E2B\u0E21\u0E32\u0E30\u0E2A\u0E21",
  "message.template.message.generate" : "\u0E2A\u0E23\u0E49\u0E32\u0E07\u0E40\u0E17\u0E21\u0E40\u0E1E\u0E25\u0E15",
  "ccnotification.common.button.new" : "\u0E2A\u0E23\u0E49\u0E32\u0E07",
  "ccnotification.gatewayconfig.label.destAddrTontitle" : "\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E23\u0E2B\u0E31\u0E2A\u0E17\u0E35\u0E48\u0E2D\u0E22\u0E39\u0E48\u0E1B\u0E25\u0E32\u0E22\u0E17\u0E32\u0E07\u0E04\u0E37\u0E2D\u0E15\u0E31\u0E27\u0E40\u0E25\u0E02",
  "ccnotification.urlconfig.pageurls.urlvalidate" : "urls \u0E15\u0E49\u0E2D\u0E07\u0E40\u0E23\u0E34\u0E48\u0E21\u0E15\u0E49\u0E19\u0E14\u0E49\u0E27\u0E22 https://",
  "ccnotification.notification.label.deleteAttachmentFailed" : "\u0E01\u0E32\u0E23\u0E25\u0E1A\u0E44\u0E1F\u0E25\u0E4C\u0E41\u0E19\u0E1A\u0E25\u0E49\u0E21\u0E40\u0E2B\u0E25\u0E27",
  "ccnotification.gatewayconfig.label.paramIsNotNumber" : "\u0E04\u0E48\u0E32\u0E1E\u0E32\u0E23\u0E32\u0E21\u0E34\u0E40\u0E15\u0E2D\u0E23\u0E4C\u0E21\u0E35\u0E44\u0E14\u0E49\u0E40\u0E09\u0E1E\u0E32\u0E30\u0E15\u0E31\u0E27\u0E40\u0E25\u0E02\u0E40\u0E17\u0E48\u0E32\u0E19\u0E31\u0E49\u0E19",
  "ccnotification.gatewayconfig.label.certType" : "\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E43\u0E1A\u0E23\u0E31\u0E1A\u0E23\u0E2D\u0E07",
  "ccnotification.gatewayconfig.label.heartbeatIntervaltitle" : "\u0E0A\u0E48\u0E27\u0E07\u0E01\u0E32\u0E23\u0E40\u0E15\u0E49\u0E19\u0E02\u0E2D\u0E07\u0E2B\u0E31\u0E27\u0E43\u0E08\u0E04\u0E37\u0E2D\u0E15\u0E31\u0E27\u0E40\u0E25\u0E02\u0E17\u0E35\u0E48\u0E40\u0E2B\u0E21\u0E32\u0E30\u0E2A\u0E21",
  "message.center.label.refresh" : "\u0E23\u0E35\u0E40\u0E1F\u0E23\u0E0A",
  "ccnotification.notification.label.discounnectedSuccess" : "\u0E15\u0E31\u0E14\u0E01\u0E32\u0E23\u0E40\u0E0A\u0E37\u0E48\u0E2D\u0E21\u0E15\u0E48\u0E2D\u0E2A\u0E33\u0E40\u0E23\u0E47\u0E08",
  "ccnotification.notification.label.attachmentSizeError" : "\u0E02\u0E19\u0E32\u0E14\u0E44\u0E1F\u0E25\u0E4C\u0E41\u0E19\u0E1A\u0E2D\u0E35\u0E40\u0E21\u0E25\u0E40\u0E01\u0E34\u0E19 10 MB",
  "ccnotification.notifition.gatewaycontact.success" : "\u0E01\u0E32\u0E23\u0E40\u0E0A\u0E37\u0E48\u0E2D\u0E21\u0E15\u0E48\u0E2D\u0E2A\u0E33\u0E40\u0E23\u0E47\u0E08\u0E41\u0E25\u0E49\u0E27",
  "message.template.message.operate" : "\u0E01\u0E32\u0E23\u0E14\u0E33\u0E40\u0E19\u0E34\u0E19\u0E01\u0E32\u0E23",
  "ccnotification.notification.variable.template" : "\u0E40\u0E17\u0E21\u0E40\u0E1E\u0E25\u0E15",
  "ccnotification.gatewayconfig.label.interfaceVersiontitle" : "\u0E2B\u0E21\u0E32\u0E22\u0E40\u0E25\u0E02\u0E40\u0E27\u0E2D\u0E23\u0E4C\u0E0A\u0E31\u0E19\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21\u0E40\u0E02\u0E49\u0E32\u0E2A\u0E39\u0E48\u0E23\u0E30\u0E1A\u0E1A\u0E15\u0E49\u0E2D\u0E07\u0E40\u0E1B\u0E47\u0E19\u0E15\u0E31\u0E27\u0E40\u0E25\u0E02",
  "message.template.message.sendEmailError" : "\u0E17\u0E35\u0E48\u0E2D\u0E22\u0E39\u0E48\u0E2D\u0E35\u0E40\u0E21\u0E25\u0E02\u0E2D\u0E07\u0E1C\u0E39\u0E49\u0E2A\u0E48\u0E07\u0E27\u0E48\u0E32\u0E07\u0E40\u0E1B\u0E25\u0E48\u0E32",
  "ccnotification.notifition.template.message.dateTime" : "\u0E27\u0E31\u0E19\u0E17\u0E35\u0E48: \u0E1B\u0E1B\u0E1B\u0E1B-\u0E14\u0E14-\u0E27\u0E27 \u0E40\u0E27\u0E25\u0E32: \u0E0A\u0E0A:\u0E19\u0E19:\u0E27\u0E27",
  "ccnotification.gatewayconfig.label.INIT" : "\u0E01\u0E33\u0E2B\u0E19\u0E14\u0E04\u0E48\u0E32\u0E40\u0E23\u0E34\u0E48\u0E21\u0E15\u0E49\u0E19",
  "ccnotification.notifition.template.button.up" : "\u0E02\u0E36\u0E49\u0E19",
  "ccnotification.gatewayconfig.label.deviceDescript" : "\u0E04\u0E33\u0E2D\u0E18\u0E34\u0E1A\u0E32\u0E22\u0E2D\u0E38\u0E1B\u0E01\u0E23\u0E13\u0E4C",
  "ccnotification.gatewayconfig.label.sslencryptionmodeIsError" : "\u0E42\u0E2B\u0E21\u0E14\u0E01\u0E32\u0E23\u0E40\u0E02\u0E49\u0E32\u0E23\u0E2B\u0E31\u0E2A\u0E2D\u0E35\u0E40\u0E21\u0E25\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E40\u0E1B\u0E47\u0E19 NONE\, STARTTLS\, \u0E2B\u0E23\u0E37\u0E2D TLS \u0E40\u0E17\u0E48\u0E32\u0E19\u0E31\u0E49\u0E19",
  "message.template.message.notificationContent" : "\u0E40\u0E19\u0E37\u0E49\u0E2D\u0E2B\u0E32\u0E01\u0E32\u0E23\u0E41\u0E08\u0E49\u0E07\u0E40\u0E15\u0E37\u0E2D\u0E19",
  "ccnotification.center.message.var.value" : "\u0E04\u0E48\u0E32\u0E15\u0E31\u0E27\u0E41\u0E1B\u0E23:",
  "ccnotification.notification.recmessagerouterconfig.emailOnlineChat" : "\u0E01\u0E32\u0E23\u0E2A\u0E19\u0E17\u0E19\u0E32\u0E2D\u0E2D\u0E19\u0E44\u0E25\u0E19\u0E4C\u0E1C\u0E48\u0E32\u0E19\u0E0A\u0E48\u0E2D\u0E07\u0E2A\u0E31\u0E0D\u0E0D\u0E32\u0E13\u0E2D\u0E35\u0E40\u0E21\u0E25",
  "ccnotification.notifition.gatewaycontact.stop" : "\u0E01\u0E32\u0E23\u0E40\u0E0A\u0E37\u0E48\u0E2D\u0E21\u0E15\u0E48\u0E2D\u0E2B\u0E22\u0E38\u0E14\u0E25\u0E07\u0E41\u0E25\u0E49\u0E27",
  "ccnotification.notifition.sendmessage.sendtime" : "\u0E40\u0E27\u0E25\u0E32\u0E2A\u0E48\u0E07",
  "message.template.message.nameLengthError" : "\u0E2B\u0E49\u0E32\u0E21\u0E40\u0E27\u0E49\u0E19\u0E27\u0E48\u0E32\u0E07\u0E0A\u0E37\u0E48\u0E2D\u0E41\u0E25\u0E30\u0E15\u0E49\u0E2D\u0E07\u0E44\u0E21\u0E48\u0E40\u0E01\u0E34\u0E19 200 \u0E2D\u0E31\u0E01\u0E02\u0E23\u0E30\u0E2B\u0E23\u0E37\u0E2D\u0E21\u0E35\u0E2D\u0E31\u0E01\u0E02\u0E23\u0E30\u0E1E\u0E34\u0E40\u0E28\u0E29",
  "message.template.message.phoneError" : "\u0E44\u0E21\u0E48\u0E21\u0E35\u0E2B\u0E21\u0E32\u0E22\u0E40\u0E25\u0E02\u0E42\u0E17\u0E23\u0E28\u0E31\u0E1E\u0E17\u0E4C",
  "message.center.label.download" : "\u0E14\u0E32\u0E27\u0E19\u0E4C\u0E42\u0E2B\u0E25\u0E14",
  "ccnotification.notification.label.isDownloadAttachment": "หากไฟล์แนบที่คุณกำลังดาวน์โหลดเป็นรูปแบบเอกสารของ Office โปรดดำเนินการป้องกันที่ปลอดภัยตามที่จำเป็น",
  "ccnotification.gatewayconfig.label.addgatewaysuccess" : "\u0E40\u0E1E\u0E34\u0E48\u0E21\u0E01\u0E32\u0E23\u0E01\u0E33\u0E2B\u0E19\u0E14\u0E04\u0E48\u0E32\u0E40\u0E01\u0E15\u0E40\u0E27\u0E22\u0E4C\u0E41\u0E25\u0E49\u0E27!",
  "ccnotification.notification.recmessagerouterconfig.retryTimes" : "\u0E08\u0E33\u0E19\u0E27\u0E19\u0E04\u0E23\u0E31\u0E49\u0E07\u0E17\u0E35\u0E48\u0E25\u0E2D\u0E07\u0E43\u0E2B\u0E21\u0E48",
  "ccnotification.notification.label.checkAttachNameLegnth" : "\u0E0A\u0E37\u0E48\u0E2D\u0E44\u0E1F\u0E25\u0E41\u0E19\u0E1A\u0E2D\u0E35\u0E40\u0E21\u0E25\u0E44\u0E21\u0E48\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E40\u0E01\u0E34\u0E19 100 \u0E2D\u0E31\u0E01\u0E02\u0E23\u0E30",
  "ccnotification.gatewayconfig.label.resendNumber" : "\u0E08\u0E33\u0E19\u0E27\u0E19\u0E04\u0E23\u0E31\u0E49\u0E07\u0E02\u0E2D\u0E07\u0E01\u0E32\u0E23\u0E2A\u0E48\u0E07\u0E2D\u0E35\u0E01\u0E04\u0E23\u0E31\u0E49\u0E07",
  "ccnotification.center.message.var.des" : "\u0E04\u0E33\u0E2D\u0E18\u0E34\u0E1A\u0E32\u0E22\u0E15\u0E31\u0E27\u0E41\u0E1B\u0E23:",
  "ccnotification.common.title.tips" : "\u0E40\u0E04\u0E25\u0E47\u0E14\u0E25\u0E31\u0E1A",
  "ccnotification.notification.variable.editfailed" : "\u0E01\u0E32\u0E23\u0E1B\u0E23\u0E31\u0E1A\u0E40\u0E1B\u0E25\u0E35\u0E48\u0E22\u0E19\u0E25\u0E49\u0E21\u0E40\u0E2B\u0E25\u0E27",
  "message.template.message.deleteTemplateFail" : "\u0E25\u0E49\u0E21\u0E40\u0E2B\u0E25\u0E27\u0E43\u0E19\u0E01\u0E32\u0E23\u0E25\u0E1A\u0E40\u0E17\u0E21\u0E40\u0E1E\u0E25\u0E15\u0E01\u0E32\u0E23\u0E41\u0E08\u0E49\u0E07\u0E40\u0E08\u0E37\u0E2D\u0E19",
  "message.template.message.willDeleteTemplate" : "\u0E01\u0E32\u0E23\u0E25\u0E1A\u0E40\u0E17\u0E21\u0E40\u0E1E\u0E25\u0E15\u0E2D\u0E32\u0E08\u0E17\u0E33\u0E43\u0E2B\u0E49\u0E1F\u0E31\u0E07\u0E01\u0E4C\u0E0A\u0E31\u0E19\u0E17\u0E35\u0E48\u0E2D\u0E49\u0E32\u0E07\u0E2D\u0E34\u0E07\u0E44\u0E21\u0E48\u0E1E\u0E23\u0E49\u0E2D\u0E21\u0E43\u0E0A\u0E49\u0E07\u0E32\u0E19 \u0E04\u0E38\u0E13\u0E41\u0E19\u0E48\u0E43\u0E08\u0E27\u0E48\u0E32\u0E15\u0E49\u0E2D\u0E07\u0E01\u0E32\u0E23\u0E14\u0E33\u0E40\u0E19\u0E34\u0E19\u0E01\u0E32\u0E23\u0E15\u0E48\u0E2D\u0E43\u0E0A\u0E48\u0E2B\u0E23\u0E37\u0E2D\u0E44\u0E21\u0E48",
  "message.center.label.receiver" : "\u0E1C\u0E39\u0E49\u0E23\u0E31\u0E1A",
  "message.center.label.receiverGroup" : "\u0E01\u0E25\u0E38\u0E48\u0E21\u0E1C\u0E39\u0E49\u0E23\u0E31\u0E1A",
  "ccnotification.notification.label.queryAttachmentFailed" : "\u0E25\u0E49\u0E21\u0E40\u0E2B\u0E25\u0E27\u0E43\u0E19\u0E01\u0E32\u0E23\u0E2A\u0E2D\u0E1A\u0E16\u0E32\u0E21\u0E44\u0E1F\u0E25\u0E4C\u0E41\u0E19\u0E1A",
  "ccnotification.notifition.variable.namerule" : "\u0E0A\u0E37\u0E48\u0E2D\u0E15\u0E31\u0E27\u0E41\u0E1B\u0E23\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E21\u0E35\u0E15\u0E31\u0E27\u0E2D\u0E31\u0E01\u0E29\u0E23 \u0E15\u0E31\u0E27\u0E40\u0E25\u0E02 _ \u0E41\u0E25\u0E30 . \u0E40\u0E17\u0E48\u0E32\u0E19\u0E31\u0E49\u0E19",
  "ccnotification.notification.systemmonitor.mspId" : "Mspld",
  "ccnotification.notifition.template.placeholder.var" : "\u0E01\u0E23\u0E2D\u0E01\u0E04\u0E48\u0E32\u0E17\u0E35\u0E48\u0E15\u0E23\u0E07\u0E15\u0E32\u0E21\u0E02\u0E49\u0E2D\u0E01\u0E33\u0E2B\u0E19\u0E14",
  "ccnotification.gatewayconfig.label.protocolId" : "ID \u0E42\u0E1B\u0E23\u0E42\u0E15\u0E04\u0E2D\u0E25",
  "message.template.message.modifySuccess" : "\u0E40\u0E17\u0E21\u0E40\u0E1E\u0E25\u0E15\u0E01\u0E32\u0E23\u0E41\u0E08\u0E49\u0E07\u0E40\u0E15\u0E37\u0E2D\u0E19\u0E44\u0E14\u0E49\u0E23\u0E31\u0E1A\u0E01\u0E32\u0E23\u0E1B\u0E23\u0E30\u0E1A\u0E40\u0E1B\u0E25\u0E35\u0E48\u0E22\u0E19\u0E41\u0E25\u0E49\u0E27",
  "ccnotification.notifition.sendmessage.messagetitle" : "\u0E2B\u0E31\u0E27\u0E02\u0E49\u0E2D",
  "ccnotification.notification.label.uploadAttachmentFailed" : "\u0E01\u0E32\u0E23\u0E2D\u0E49\u0E1E\u0E42\u0E2B\u0E25\u0E14\u0E44\u0E1F\u0E25\u0E4C\u0E41\u0E19\u0E1A\u0E25\u0E49\u0E21\u0E40\u0E2B\u0E25\u0E27",
  "ccnotification.notification.fileUploadRiskWarning" : "\u0E44\u0E1F\u0E25\u0E4C\u0E17\u0E35\u0E48\u0E04\u0E38\u0E13\u0E2D\u0E31\u0E1B\u0E42\u0E2B\u0E25\u0E14\u0E21\u0E35\u0E04\u0E27\u0E32\u0E21\u0E40\u0E2A\u0E35\u0E48\u0E22\u0E07\u0E14\u0E49\u0E32\u0E19\u0E04\u0E27\u0E32\u0E21\u0E1B\u0E25\u0E2D\u0E14\u0E20\u0E31\u0E22 \u0E42\u0E1B\u0E23\u0E14\u0E22\u0E37\u0E19\u0E22\u0E31\u0E19\u0E27\u0E48\u0E32\u0E08\u0E30\u0E2D\u0E31\u0E1B\u0E42\u0E2B\u0E25\u0E14\u0E15\u0E48\u0E2D\u0E2B\u0E23\u0E37\u0E2D\u0E44\u0E21\u0E48",
  "ccnotification.notification.label.fileUploadFileNameError" : "\u0E0A\u0E37\u0E48\u0E2D\u0E44\u0E1F\u0E25\u0E4C\u0E17\u0E35\u0E48\u0E04\u0E38\u0E13\u0E2D\u0E31\u0E1B\u0E42\u0E2B\u0E25\u0E14\u0E44\u0E21\u0E48\u0E15\u0E23\u0E07\u0E15\u0E32\u0E21\u0E02\u0E49\u0E2D\u0E01\u0E33\u0E2B\u0E19\u0E14",
  "ccnotification.notification.label.fileUploadFileTypeError" : "\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E44\u0E1F\u0E25\u0E4C\u0E17\u0E35\u0E48\u0E04\u0E38\u0E13\u0E2D\u0E31\u0E1B\u0E42\u0E2B\u0E25\u0E14\u0E44\u0E21\u0E48\u0E15\u0E23\u0E07\u0E15\u0E32\u0E21\u0E02\u0E49\u0E2D\u0E01\u0E33\u0E2B\u0E19\u0E14",
  "ccnotification.notification.label.fileUploadFileIOError" : "\u0E21\u0E35\u0E02\u0E49\u0E2D\u0E1C\u0E34\u0E14\u0E1E\u0E25\u0E32\u0E14\u0E43\u0E19\u0E01\u0E32\u0E23\u0E41\u0E22\u0E01\u0E27\u0E34\u0E40\u0E04\u0E23\u0E32\u0E30\u0E2B\u0E4C\u0E01\u0E31\u0E1A\u0E44\u0E1F\u0E25\u0E4C\u0E17\u0E35\u0E48\u0E04\u0E38\u0E13\u0E2D\u0E31\u0E1B\u0E42\u0E2B\u0E25\u0E14",
  "ccnotification.notifition.sendmessage.enteraccount" : "\u0E01\u0E23\u0E2D\u0E01\u0E1A\u0E31\u0E0D\u0E0A\u0E35\u0E18\u0E38\u0E23\u0E01\u0E34\u0E08",
  "ccnotification.notifition.sendmessage.maxselectcount" : "\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E1E\u0E19\u0E31\u0E01\u0E07\u0E32\u0E19\u0E44\u0E14\u0E49\u0E21\u0E32\u0E01\u0E17\u0E35\u0E48\u0E2A\u0E38\u0E14 100 \u0E04\u0E19",
  "ccnotification.notification.label.fileUploadFileSizeError" : "\u0E02\u0E19\u0E32\u0E14\u0E44\u0E1F\u0E25\u0E4C\u0E17\u0E35\u0E48\u0E04\u0E38\u0E13\u0E2D\u0E31\u0E1B\u0E42\u0E2B\u0E25\u0E14\u0E40\u0E01\u0E34\u0E19\u0E02\u0E35\u0E14\u0E08\u0E33\u0E01\u0E31\u0E14",
  "ccnotification.gatewayconfig.label.receiverPoolQueueCapacitytitle" : "Receiving Thread Pool Queue Length \u0E04\u0E37\u0E2D\u0E15\u0E31\u0E27\u0E40\u0E25\u0E02 \u0E40\u0E0A\u0E48\u0E19 20",
  "ccnotification.smshuawei.message.updatesuccess" : "\u0E0A\u0E48\u0E2D\u0E07\u0E2A\u0E31\u0E0D\u0E0D\u0E32\u0E13 SMS \u0E16\u0E39\u0E01\u0E1B\u0E23\u0E31\u0E1A\u0E40\u0E1B\u0E25\u0E35\u0E48\u0E22\u0E19\u0E41\u0E25\u0E49\u0E27",
  "ccnotification.gatewayconfig.label.senderPoolMaxCount" : "Max Sending Thread Pools",
  "ccnotification.common.button.delete" : "\u0E25\u0E1A",
  "ccnotification.flowcontrol.getFailed" : "\u0E25\u0E49\u0E21\u0E40\u0E2B\u0E25\u0E27\u0E43\u0E19\u0E01\u0E32\u0E23\u0E23\u0E31\u0E1A\u0E01\u0E32\u0E23\u0E01\u0E33\u0E2B\u0E19\u0E14\u0E04\u0E48\u0E32\u0E01\u0E32\u0E23\u0E41\u0E08\u0E49\u0E07\u0E40\u0E15\u0E37\u0E2D\u0E19 \u0E42\u0E1B\u0E23\u0E14\u0E15\u0E23\u0E27\u0E08\u0E40\u0E0A\u0E47\u0E04\u0E40\u0E04\u0E23\u0E37\u0E2D\u0E02\u0E48\u0E32\u0E22",
  "ccnotification.bulletinType.title.bulletintypeInvalidSect" : "เวลาหมดอายุปริยาย",
  "ccnotification.bulletinType.title.bulletinTypeName" : "\u0E0A\u0E37\u0E48\u0E2D\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E1B\u0E23\u0E30\u0E01\u0E32\u0E28",
  "ccnotification.notification.recmessagerouterconfig.caseserver" : "\u0E01\u0E32\u0E23\u0E40\u0E23\u0E35\u0E22\u0E01\u0E43\u0E0A\u0E49\u0E1A\u0E23\u0E34\u0E01\u0E32\u0E23",
  "ccnotification.bulletinType.title.bulletinTypeDesc" : "\u0E04\u0E33\u0E2D\u0E18\u0E34\u0E1A\u0E32\u0E22",
  "ccnotification.gatewayconfig.label.messageFlowControlMaxCapsForSend" : "\u0E2D\u0E31\u0E15\u0E23\u0E32\u0E01\u0E32\u0E23\u0E2A\u0E48\u0E07\u0E2A\u0E39\u0E07\u0E2A\u0E38\u0E14",
  "ccnotification.bulletinType.title.createBulletinType" : "\u0E2A\u0E23\u0E49\u0E32\u0E07\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E1B\u0E23\u0E30\u0E01\u0E32\u0E28",
  "ccnotification.bulletinType.title.editBulletinType" : "\u0E41\u0E01\u0E49\u0E44\u0E02\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E1B\u0E23\u0E30\u0E01\u0E32\u0E28",
  "ccnotification.bulletinType.message.createBulletinTypeSuccessful" : "\u0E2A\u0E23\u0E49\u0E32\u0E07\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E1B\u0E23\u0E30\u0E01\u0E32\u0E28\u0E40\u0E23\u0E35\u0E22\u0E1A\u0E23\u0E49\u0E2D\u0E22\u0E41\u0E25\u0E49\u0E27",
  "ccnotification.bulletinType.message.searchPlaceHolder" : "\u0E0A\u0E37\u0E48\u0E2D\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E1B\u0E23\u0E30\u0E01\u0E32\u0E28",
  "ccnotification.bulletinType.message.editBulletinTypeSuccessful" : "\u0E41\u0E01\u0E49\u0E44\u0E02\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E1B\u0E23\u0E30\u0E01\u0E32\u0E28\u0E40\u0E23\u0E35\u0E22\u0E1A\u0E23\u0E49\u0E2D\u0E22\u0E41\u0E25\u0E49\u0E27",
  "ccnotification.bulletinType.message.betchDeleteBulletinTypeSuccessful" : "\u0E25\u0E1A\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E1B\u0E23\u0E30\u0E01\u0E32\u0E28\u0E41\u0E1A\u0E1A\u0E40\u0E1B\u0E47\u0E19\u0E0A\u0E38\u0E14\u0E40\u0E23\u0E35\u0E22\u0E1A\u0E23\u0E49\u0E2D\u0E22\u0E41\u0E25\u0E49\u0E27",
  "ccnotification.bulletinType.message.createBulletinTypeFailed" : "\u0E44\u0E21\u0E48\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E2A\u0E23\u0E49\u0E32\u0E07\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E1B\u0E23\u0E30\u0E01\u0E32\u0E28\u0E44\u0E14\u0E49",
  "ccnotification.bulletinType.message.editBulletinTypeFailed" : "\u0E44\u0E21\u0E48\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E41\u0E01\u0E49\u0E44\u0E02\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E1B\u0E23\u0E30\u0E01\u0E32\u0E28\u0E44\u0E14\u0E49",
  "ccnotification.bulletinType.message.deleteBulletinTypeFailed" : "\u0E44\u0E21\u0E48\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E25\u0E1A\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E1B\u0E23\u0E30\u0E01\u0E32\u0E28\u0E44\u0E14\u0E49",
  "ccnotification.bulletinType.message.deleteBulletinTypeSuccessful" : "\u0E25\u0E1A\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E1B\u0E23\u0E30\u0E01\u0E32\u0E28\u0E40\u0E23\u0E35\u0E22\u0E1A\u0E23\u0E49\u0E2D\u0E22\u0E41\u0E25\u0E49\u0E27",
  "ccnotification.bulletinType.message.betchDeleteBulletinTypeFailed" : "\u0E44\u0E21\u0E48\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E25\u0E1A\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E1B\u0E23\u0E30\u0E01\u0E32\u0E28\u0E41\u0E1A\u0E1A\u0E40\u0E1B\u0E47\u0E19\u0E0A\u0E38\u0E14\u0E44\u0E14\u0E49",
  "ccnotification.bulletinType.message.nameContainSpecialChar" : "\u0E0A\u0E37\u0E48\u0E2D\u0E15\u0E49\u0E2D\u0E07\u0E44\u0E21\u0E48\u0E21\u0E35\u0E2D\u0E31\u0E01\u0E02\u0E23\u0E30\u0E1E\u0E34\u0E40\u0E28\u0E29",
  "ccnotification.bulletinType.message.decimealsCheck" : "\u0E04\u0E48\u0E32\u0E40\u0E23\u0E34\u0E48\u0E21\u0E15\u0E49\u0E19\u0E02\u0E2D\u0E07\u0E40\u0E27\u0E25\u0E32\u0E2B\u0E21\u0E14\u0E2D\u0E32\u0E22\u0E38\u0E15\u0E49\u0E2D\u0E07\u0E44\u0E21\u0E48\u0E21\u0E35\u0E40\u0E25\u0E02\u0E17\u0E28\u0E19\u0E34\u0E22\u0E21",
  "ccnotification.bulletinType.message.selectItem" : "\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E1B\u0E23\u0E30\u0E01\u0E32\u0E28",
  "ccnotification.bulletinType.message.willDelete" : "\u0E04\u0E38\u0E13\u0E41\u0E19\u0E48\u0E43\u0E08\u0E2B\u0E23\u0E37\u0E2D\u0E27\u0E48\u0E32\u0E15\u0E49\u0E2D\u0E07\u0E01\u0E32\u0E23\u0E25\u0E1A\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E1B\u0E23\u0E30\u0E01\u0E32\u0E28\u0E17\u0E35\u0E48\u0E40\u0E25\u0E37\u0E2D\u0E01",
  "ccnotification.bulletinType.message.invalidSectCheck" : "\u0E04\u0E48\u0E32\u0E40\u0E23\u0E34\u0E48\u0E21\u0E15\u0E49\u0E19\u0E02\u0E2D\u0E07\u0E40\u0E27\u0E25\u0E32\u0E2B\u0E21\u0E14\u0E2D\u0E32\u0E22\u0E38\u0E15\u0E49\u0E2D\u0E07\u0E2D\u0E22\u0E39\u0E48\u0E43\u0E19\u0E0A\u0E48\u0E27\u0E07 1 \u0E16\u0E36\u0E07 2,000 \u0E27\u0E31\u0E19",
  "ccnotification.bulletinType.message.nameIsNotUnique" : "\u0E0A\u0E37\u0E48\u0E2D\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E1B\u0E23\u0E30\u0E01\u0E32\u0E28\u0E19\u0E35\u0E49\u0E21\u0E35\u0E2D\u0E22\u0E39\u0E48\u0E41\u0E25\u0E49\u0E27",
  "ccnotification.bulletinType.message.releaseTime" : "\u0E40\u0E27\u0E25\u0E32\u0E1B\u0E25\u0E48\u0E2D\u0E22",
  "ccnotification.bulletinType.message.periodicallySend" : "\u0E2A\u0E48\u0E07\u0E40\u0E1B\u0E47\u0E19\u0E23\u0E30\u0E22\u0E30",
  "ccnotification.bulletinType.message.sendingFrequency" : "\u0E04\u0E27\u0E32\u0E21\u0E16\u0E35\u0E48\u0E43\u0E19\u0E01\u0E32\u0E23\u0E2A\u0E48\u0E07",
  "ccnotification.bulletinType.message.weekly" : "\u0E23\u0E32\u0E22\u0E2A\u0E31\u0E1B\u0E14\u0E32\u0E2B\u0E4C",
  "ccnotification.bulletinType.message.perMonth" : "\u0E15\u0E48\u0E2D\u0E40\u0E14\u0E37\u0E2D\u0E19",
  "ccnotification.bulletinType.message.Monday" : "\u0E27\u0E31\u0E19\u0E08\u0E31\u0E19\u0E17\u0E23\u0E4C",
  "ccnotification.bulletinType.message.Thursday" : "\u0E27\u0E31\u0E19\u0E1E\u0E24\u0E2B\u0E31\u0E2A\u0E1A\u0E14\u0E35",
  "ccnotification.bulletinType.message.everyDay" : "\u0E17\u0E38\u0E01\u0E27\u0E31\u0E19",
  "ccnotification.bulletinType.message.Wednesday" : "\u0E27\u0E31\u0E19\u0E1E\u0E38\u0E18",
  "ccnotification.bulletinType.message.Tuesday" : "\u0E27\u0E31\u0E19\u0E2D\u0E31\u0E07\u0E04\u0E32\u0E23",
  "ccnotification.bulletinType.message.Saturday" : "\u0E27\u0E31\u0E19\u0E40\u0E2A\u0E32\u0E23\u0E4C",
  "ccnotification.bulletinType.message.Sunday" : "\u0E27\u0E31\u0E19\u0E2D\u0E32\u0E17\u0E34\u0E15\u0E22\u0E4C",
  "ccnotification.bulletinType.message.Friday" : "\u0E27\u0E31\u0E19\u0E28\u0E38\u0E01\u0E23\u0E4C",
  "ccnotification.bulletinType.message.daysOfMonth" : "\u0E27\u0E31\u0E19\u0E17\u0E35\u0E48 0",
  "ccnotification.bulletinType.message.sendTimeParamsError1" : "\u0E40\u0E27\u0E25\u0E32\u0E40\u0E1C\u0E22\u0E41\u0E1E\u0E23\u0E48\u0E15\u0E49\u0E2D\u0E07\u0E44\u0E21\u0E48\u0E40\u0E23\u0E47\u0E27\u0E01\u0E27\u0E48\u0E32\u0E40\u0E27\u0E25\u0E32\u0E1B\u0E31\u0E08\u0E08\u0E38\u0E1A\u0E31\u0E19",
  "ccnotification.bulletinType.message.sendTimeParamsError2" : "\u0E40\u0E27\u0E25\u0E32\u0E40\u0E1C\u0E22\u0E41\u0E1E\u0E23\u0E48\u0E15\u0E49\u0E2D\u0E07\u0E44\u0E21\u0E48\u0E0A\u0E49\u0E32\u0E01\u0E27\u0E48\u0E32\u0E40\u0E27\u0E25\u0E32\u0E2B\u0E21\u0E14\u0E2D\u0E32\u0E22\u0E38",
  "ccnotification.bulletinType.title.General" : "\u0E15\u0E48\u0E33",
  "ccnotification.bulletinType.title.Rushse" : "\u0E2A\u0E39\u0E07",
  "ccnotification.bulletinType.title.Faults" : "\u0E27\u0E34\u0E01\u0E24\u0E15",
  "ccnotification.common.message.maxLength" : "\u0E40\u0E19\u0E37\u0E49\u0E2D\u0E2B\u0E32\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21\u0E21\u0E35\u0E2D\u0E31\u0E01\u0E02\u0E23\u0E30\u0E44\u0E14\u0E49\u0E2A\u0E39\u0E07\u0E2A\u0E38\u0E14 4,096 \u0E15\u0E31\u0E27",
  "ccnotification.common.message.emptyContent" : "\u0E40\u0E19\u0E37\u0E49\u0E2D\u0E2B\u0E32\u0E15\u0E49\u0E2D\u0E07\u0E44\u0E21\u0E48\u0E40\u0E27\u0E49\u0E19\u0E27\u0E48\u0E32\u0E07\u0E44\u0E27\u0E49",
  "ccnotification.notification.message.noAttachement" : "\u0E04\u0E38\u0E13\u0E22\u0E31\u0E07\u0E44\u0E21\u0E48\u0E44\u0E14\u0E49\u0E2D\u0E31\u0E1B\u0E42\u0E2B\u0E25\u0E14\u0E40\u0E2D\u0E01\u0E2A\u0E32\u0E23\u0E41\u0E19\u0E1A\u0E43\u0E14 \u0E46",
  "ccnotification.common.message.maxExpireTime" : "\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E40\u0E27\u0E25\u0E32\u0E2B\u0E21\u0E14\u0E2D\u0E32\u0E22\u0E38\u0E44\u0E21\u0E48\u0E40\u0E01\u0E34\u0E19 {0}",
  "ccnotification.notifition.sendmessage.selectGroupOrRecipient" : "\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E1C\u0E39\u0E49\u0E23\u0E31\u0E1A\u0E2B\u0E23\u0E37\u0E2D\u0E01\u0E25\u0E38\u0E48\u0E21\u0E1C\u0E39\u0E49\u0E23\u0E31\u0E1A\u0E2D\u0E22\u0E48\u0E32\u0E07\u0E43\u0E14\u0E2D\u0E22\u0E48\u0E32\u0E07\u0E2B\u0E19\u0E36\u0E48\u0E07",
  "ccnotification.chatserver.fail.barred" : "\u0E01\u0E32\u0E23\u0E08\u0E31\u0E14\u0E2A\u0E23\u0E23\u0E40\u0E2D\u0E40\u0E08\u0E19\u0E15\u0E4C\u0E17\u0E35\u0E48\u0E16\u0E39\u0E01\u0E1B\u0E34\u0E14\u0E01\u0E31\u0E49\u0E19",
  "ccnotification.bulletinType.title.Urgent" : "\u0E1B\u0E32\u0E19\u0E01\u0E25\u0E32\u0E07",
  "ccnotification.chatserver.fail.assign" : "\u0E01\u0E32\u0E23\u0E08\u0E31\u0E14\u0E2A\u0E23\u0E23\u0E40\u0E2D\u0E40\u0E08\u0E19\u0E15\u0E4C\u0E17\u0E35\u0E48\u0E25\u0E49\u0E21\u0E40\u0E2B\u0E25\u0E27",
  "ccnotification.notifition.template.message.phoneLabel" : "\u0E2B\u0E21\u0E32\u0E22\u0E40\u0E25\u0E02\u0E42\u0E17\u0E23\u0E28\u0E31\u0E1E\u0E17\u0E4C",
  "ccnotification.notifition.template.message.charDigitLabel" : "\u0E15\u0E31\u0E27\u0E40\u0E25\u0E02\u0E41\u0E25\u0E30\u0E15\u0E31\u0E27\u0E2D\u0E31\u0E01\u0E29\u0E23",
  "ccnotification.notifition.template.message.textLabel" : "\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21",
  "ccnotification.notifition.template.message.moneyLabel" : "\u0E08\u0E33\u0E19\u0E27\u0E19",
  "ccnotification.notifition.sendmessage.selectOutbox" : "",
  "ccnotification.common.message.sendIntervalShort" : "",
  "ccnotification.notifition.gatewaycontact.connecting" : "",
  "ccnotification.gatewayconfig.message.securityInfo" : "NONE \u0E1A\u0E48\u0E07\u0E0A\u0E35\u0E49\u0E27\u0E48\u0E32\u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25\u0E16\u0E39\u0E01\u0E16\u0E48\u0E32\u0E22\u0E17\u0E2D\u0E14\u0E40\u0E1B\u0E47\u0E19\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21\u0E18\u0E23\u0E23\u0E21\u0E14\u0E32 \u0E14\u0E31\u0E07\u0E19\u0E31\u0E49\u0E19\u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25\u0E2D\u0E32\u0E08\u0E23\u0E31\u0E48\u0E27\u0E44\u0E2B\u0E25 STARTTLS \u0E40\u0E1B\u0E47\u0E19\u0E42\u0E1B\u0E23\u0E42\u0E15\u0E04\u0E2D\u0E25\u0E17\u0E35\u0E48\u0E44\u0E21\u0E48\u0E1B\u0E25\u0E2D\u0E14\u0E20\u0E31\u0E22 \u0E01\u0E32\u0E23\u0E43\u0E0A\u0E49\u0E42\u0E1B\u0E23\u0E42\u0E15\u0E04\u0E2D\u0E25\u0E17\u0E35\u0E48\u0E44\u0E21\u0E48\u0E1B\u0E25\u0E2D\u0E14\u0E20\u0E31\u0E22\u0E01\u0E48\u0E2D\u0E43\u0E2B\u0E49\u0E40\u0E01\u0E34\u0E14\u0E04\u0E27\u0E32\u0E21\u0E40\u0E2A\u0E35\u0E48\u0E22\u0E07\u0E14\u0E49\u0E32\u0E19\u0E04\u0E27\u0E32\u0E21\u0E1B\u0E25\u0E2D\u0E14\u0E20\u0E31\u0E22 \u0E02\u0E2D\u0E41\u0E19\u0E30\u0E19\u0E4D\u0E32\u0E42\u0E1B\u0E23\u0E42\u0E15\u0E04\u0E2D\u0E25 TLS",
  "ccn.smshuawei.message.table.label" : "",
  "ccn.smsnoconfig.table.label" : "",
  "ccn.smsnoconfig.table.header.countrycode" : "รหัสประเทศผู้ส่ง",
  "ccnotification.notifition.template.message.dateTimeLabel" : "\u0E27\u0E31\u0E19\u0E17\u0E35\u0E48\u0E41\u0E25\u0E30\u0E40\u0E27\u0E25\u0E32",
  "ccn.smsnoconfig.table.header.countrycode.desc" : "ประเทศ",
  "ccn.smsnoconfig.pop.title.edittitle" : "",
  "ccnotification.notifition.sendmessage.sender" : "หมายเลขผู้ส่ง",
  "ccnotification.flowcontrol.recipient.countrycode" : "",
  "ccnotification.notifition.sendmessage.entersender" : "เลือกหมายเลขผู้ส่ง",
  "ccn.smsnoconfig.pop.check.selectwarning" : "",
  "ccn.smsnoconfig.pop.search.reminder" : "ป้อนหมายเลข",
  "ccn.smsnoconfig.pop.title.createtitle" : "",
  "ccn.smsnoconfig.pop.check.duplicatecode" : "",
  "ccn.smsnoconfig.pop.check.deletefailed" : "",
  "ccn.smsnoconfig.pop.check.deletesuccess" : "",
  "ccnotification.notification.label.uploadAttachmentTips" : "\u0E01\u0E32\u0E23\u0E41\u0E19\u0E1A\u0E2D\u0E35\u0E40\u0E21\u0E25\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E2D\u0E22\u0E39\u0E48\u0E43\u0E19\u0E23\u0E39\u0E1B\u0E41\u0E1A\u0E1A\u0E15\u0E48\u0E2D\u0E44\u0E1B\u0E19\u0E35\u0E49: doc, docx, xls, xlsx, txt, png, jpg, xml, json, csv, bmp, zip, ppt, pptx, pdf, \u0E02\u0E19\u0E32\u0E14\u0E44\u0E1F\u0E25\u0E4C\u0E44\u0E21\u0E48\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E40\u0E01\u0E34\u0E19 10 MB ไฟล์ในแพ็คเกจบีบอัดต้องเป็นไปตามรูปแบบก่อนหน้า",
  "ccnotification.notification.uploadfail.duplicateFileName" : "\u0E0A\u0E37\u0E48\u0E2D\u0E44\u0E1F\u0E25\u0E4C\u0E0B\u0E49\u0E4D\u0E32\u0E01\u0E31\u0E19",
  "ccnotification.notification.emailsignature.placeholder.name" : "\u0E42\u0E1B\u0E23\u0E14\u0E1B\u0E49\u0E2D\u0E19\u0E0A\u0E37\u0E48\u0E2D\u0E25\u0E32\u0E22\u0E40\u0E0B\u0E47\u0E19",
  "ccnotification.notification.emailsignature.name" : "\u0E0A\u0E37\u0E48\u0E2D\u0E25\u0E32\u0E22\u0E40\u0E0B\u0E47\u0E19",
  "ccnotification.notification.emailsignature.description" : "\u0E04\u0E4D\u0E32\u0E2D\u0E18\u0E34\u0E1A\u0E32\u0E22\u0E25\u0E32\u0E22\u0E40\u0E0B\u0E47\u0E19",
  "ccnotification.notification.recmessagerouterconfig.updatenum.outstripping" : "\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E2D\u0E31\u0E1B\u0E40\u0E14\u0E15\u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25\u0E44\u0E14\u0E49\u0E2A\u0E39\u0E07\u0E2A\u0E38\u0E14100\u0E23\u0E32\u0E22\u0E01\u0E32\u0E23\u0E43\u0E19\u0E41\u0E15\u0E48\u0E25\u0E30\u0E04\u0E23\u0E31\u0E49\u0E07",
  "ccnotification.gatewayconfig.message.authInfo" : "\u0E40\u0E17\u0E47\u0E08\u0E21\u0E35\u0E04\u0E27\u0E32\u0E21\u0E40\u0E2A\u0E35\u0E48\u0E22\u0E07\u0E14\u0E49\u0E32\u0E19\u0E04\u0E27\u0E32\u0E21\u0E1B\u0E25\u0E2D\u0E14\u0E20\u0E31\u0E22 \u0E02\u0E2D\u0E41\u0E19\u0E30\u0E19\u0E4D\u0E32\u0E43\u0E2B\u0E49\u0E04\u0E38\u0E13\u0E15\u0E31\u0E49\u0E07\u0E04\u0E48\u0E32 true \u0E40\u0E1E\u0E37\u0E48\u0E2D\u0E40\u0E1B\u0E34\u0E14\u0E43\u0E0A\u0E49\u0E07\u0E32\u0E19\u0E01\u0E32\u0E23\u0E15\u0E23\u0E27\u0E08\u0E2A\u0E2D\u0E1A\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19",
  "ccn.smsnoconfig.pop.check.deletewarning" : "",
  "ccnotification.sms.rcvAndSendMode.receiveSms" : "\u0E23\u0E31\u0E1A\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21 SMS",
  "ccnotification.sms.rcvAndSendMode.receiveAndSendSms" : "\u0E2A\u0E48\u0E07\u0E41\u0E25\u0E30\u0E23\u0E31\u0E1A\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21 SMS",
  "ccnotification.sms.smppPduTye.submit.sm" : "submit_sm",
  "ccnotification.sms.rcvAndSendMode.sendSms" : "\u0E2A\u0E48\u0E07\u0E02\u0E49\u0E2D\u0E04\u0E27\u0E32\u0E21 SMS",
  "ccnotification.sms.gms.user.data.head" : "\u0E2A\u0E48\u0E27\u0E19\u0E2B\u0E31\u0E27\u0E02\u0E2D\u0E07\u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25\u0E1C\u0E39\u0E49\u0E43\u0E0A\u0E49 GSM",
  "ccnotification.sms.smpp.parameters" : "\u0E1E\u0E32\u0E23\u0E32\u0E21\u0E34\u0E40\u0E15\u0E2D\u0E23\u0E4C SMPP",
  "ccnotification.sms.smpp.replaceIfPresentFlag.not.replace" : "\u0E44\u0E21\u0E48\u0E41\u0E17\u0E19\u0E17\u0E35\u0E48",
  "ccnotification.sms.smpp.replaceIfPresentFlag.replace" : "\u0E41\u0E17\u0E19\u0E17\u0E35\u0E48",
  "ccnotification.sms.smpp.stow.away" : "\u0E40\u0E01\u0E47\u0E1A\u0E2D\u0E2D\u0E01\u0E44\u0E1B",
  "ccnotification.sms.smpp.ESME.Connection.Configuration" : "\u0E01\u0E32\u0E23\u0E01\u0E4D\u0E32\u0E2B\u0E19\u0E14\u0E04\u0E48\u0E32\u0E01\u0E32\u0E23\u0E40\u0E0A\u0E37\u0E48\u0E2D\u0E21\u0E15\u0E48\u0E2D ESME",
  "ccnotification.gatewayconfig.label.splitType.disabled" : "\u0E1B\u0E34\u0E14\u0E01\u0E32\u0E23\u0E43\u0E0A\u0E49\u0E07\u0E32\u0E19",
  "ccnotification.gatewayconfig.label.splitType.physical" : "\u0E01\u0E32\u0E23\u0E41\u0E1A\u0E48\u0E07\u0E17\u0E32\u0E07\u0E01\u0E32\u0E22\u0E20\u0E32\u0E1E",
  "ccnotification.gatewayconfig.label.splitType.noSplit" : "\u0E44\u0E21\u0E48\u0E41\u0E1A\u0E48\u0E07",
  "ccnotification.gatewayconfig.label.splitType.ultraLong" : "\u0E01\u0E32\u0E23\u0E41\u0E1A\u0E48\u0E07\u0E41\u0E1A\u0E1A\u0E22\u0E32\u0E27\u0E21\u0E32\u0E01",
  "ccnotification.gatewayconfig.label.dataCoding.ASCII" : "\u0E2A\u0E15\u0E23\u0E34\u0E07 ASCII",
  "ccnotification.gatewayconfig.label.dataCoding.Binary" : "\u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25\u0E40\u0E25\u0E02\u0E10\u0E32\u0E19\u0E2A\u0E2D\u0E07",
  "ccnotification.gatewayconfig.label.dataCoding.UCS2" : "\u0E01\u0E32\u0E23\u0E40\u0E02\u0E49\u0E32\u0E23\u0E2B\u0E31\u0E2A UCS2",
  "ccnotification.gatewayconfig.label.advancedconfig" : "\u0E15\u0E31\u0E49\u0E07\u0E04\u0E48\u0E32\u0E02\u0E31\u0E49\u0E19\u0E2A\u0E39\u0E07",
  "ccnotification.gatewayconfig.label.basic.configuration" : "\u0E01\u0E32\u0E23\u0E01\u0E4D\u0E32\u0E2B\u0E19\u0E14\u0E04\u0E48\u0E32\u0E1E\u0E37\u0E49\u0E19\u0E10\u0E32\u0E19",
  "ccnotification.notification.recmessagerouterconfig.defaultsendnumber" : "\u0E2B\u0E21\u0E32\u0E22\u0E40\u0E25\u0E02\u0E2A\u0E48\u0E07\u0E40\u0E23\u0E34\u0E48\u0E21\u0E15\u0E49\u0E19 (m\u01CEay-l\u00EA-s\u01D2ng r\u01D2i-dton)",
  "ccnotification.sms.smppPduTye.data.sm" : "data_sm",
  "ccnotification.notification.recmessagerouterconfig.defaultreceivenumber" : "\u0E2B\u0E21\u0E32\u0E22\u0E40\u0E25\u0E02\u0E23\u0E31\u0E1A\u0E40\u0E23\u0E34\u0E48\u0E21\u0E15\u0E49\u0E19 (m\u01CEay-l\u00EA-r\u00E1p r\u01D2i-dton)",
  "ccnotification.template.message.organizationStructure" : "องค์กร",
  "ccnotification.template.message.alignment": "จำนวนตัวเลือกการ์ดต้องไม่เกิน",
  "ccnotification.email.signature.message.uploadImgTypeError" : "\u0E23\u0E2D\u0E07\u0E23\u0E31\u0E1A\u0E40\u0E09\u0E1E\u0E32\u0E30\u0E23\u0E39\u0E1B\u0E20\u0E32\u0E1E\u0E43\u0E19\u0E23\u0E39\u0E1B\u0E41\u0E1A\u0E1A jpg jpeg png \u0E40\u0E17\u0E48\u0E32\u0E19\u0E31\u0E49\u0E19",
  "ccnotification.email.signature.knowledge.message.uploadImgSizeError" : "\u0E44\u0E21\u0E48\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E2D\u0E31\u0E1B\u0E42\u0E2B\u0E25\u0E14\u0E23\u0E39\u0E1B\u0E20\u0E32\u0E1E\u0E44\u0E14\u0E49\u0E40\u0E01\u0E34\u0E19 {}",
  "ccnotification.email.signature.knowledge.message.uploadError" : "\u0E40\u0E19\u0E37\u0E49\u0E2D\u0E2B\u0E32\u0E25\u0E32\u0E22\u0E40\u0E0B\u0E47\u0E19\u0E2D\u0E35\u0E40\u0E21\u0E25\u0E21\u0E35\u0E23\u0E39\u0E1B\u0E20\u0E32\u0E1E\u0E17\u0E35\u0E48\u0E21\u0E35\u0E02\u0E19\u0E32\u0E14\u0E44\u0E21\u0E48\u0E2A\u0E21\u0E40\u0E2B\u0E15\u0E38\u0E2A\u0E21\u0E1C\u0E25\u0E2B\u0E23\u0E37\u0E2D\u0E23\u0E39\u0E1B\u0E41\u0E1A\u0E1A\u0E44\u0E21\u0E48\u0E2A\u0E21\u0E40\u0E2B\u0E15\u0E38\u0E2A\u0E21\u0E1C\u0E25 \u0E42\u0E1B\u0E23\u0E14\u0E25\u0E1A\u0E23\u0E39\u0E1B\u0E20\u0E32\u0E1E\u0E01\u0E48\u0E2D\u0E19",
  "ccn.flow.grayrule.editsuccess" : "\u0E41\u0E01\u0E49\u0E44\u0E02\u0E2A\u0E33\u0E40\u0E23\u0E47\u0E08\u0E41\u0E25\u0E49\u0E27",
  "ccn.voiceedit.addsuccess" : "\u0E40\u0E1E\u0E34\u0E48\u0E21\u0E2A\u0E33\u0E40\u0E23\u0E47\u0E08",
  "ccn.voiceedit.addfail" : "\u0E01\u0E32\u0E23\u0E40\u0E1E\u0E34\u0E48\u0E21\u0E25\u0E49\u0E21\u0E40\u0E2B\u0E25\u0E27",
  "ccnotification.notification.emailsignature.selection.userEmail" : "\u0E2D\u0E35\u0E40\u0E21\u0E25\u0E1C\u0E39\u0E49\u0E43\u0E0A\u0E49",
  "ccnotification.notification.emailsignature.selection.userPosition" : "\u0E15\u0E4D\u0E32\u0E41\u0E2B\u0E19\u0E48\u0E07\u0E1C\u0E39\u0E49\u0E43\u0E0A\u0E49\u0E07\u0E32\u0E19",
  "ccn.voiceedit.deletesuccess" : "\u0E25\u0E1A\u0E2A\u0E33\u0E40\u0E23\u0E47\u0E08",
  "ccnotification.notifition.template.create" : "การสร้างแม่แบบการแจ้งเตือน",
  "ccnotification.template.message.channelType" : "ประเภทช่อง",
  "pop.org.window.org" : "Home องค์กร",
  "ccnotification.template.message.add.variable": "เพิ่ม",
  "ccnotification.template.message.content": "เนื้อหาแม่แบบ",
  "ccnotification.template.message.enterDescription": "โปรดใส่คําอธิบาย",
  "ccnotification.notification.systemmonitor.batchrebuild":"การสร้างชุดใหม่",
  "ccnotification.notification.systemmonitor.batchdisconnect":"ตัดการเชื่อมต่อเป็นจํานวนมาก",
  "ccnotification.gatewayconfig.label.send.prefix":"คํานําหน้าของหมายเลขผู้ส่ง",
  "ccnotification.gatewayconfig.label.receive.prefix":"คํานําหน้าหมายเลขที่ยอมรับ",
  "ccnotification.signature.add":"\u0E2A\u0E23\u0E49\u0E32\u0E07\u0E25\u0E32\u0E22\u0E40\u0E0B\u0E47\u0E19\u0E02\u0E2D\u0E07\u0E08\u0E14\u0E2B\u0E21\u0E32\u0E22",
  "ccnotification.signature.update":"\u0E41\u0E01\u0E49\u0E44\u0E02\u0E25\u0E32\u0E22\u0E40\u0E0B\u0E47\u0E19\u0E02\u0E2D\u0E07\u0E08\u0E14\u0E2B\u0E21\u0E32\u0E22",
  "ccnotification.signature.view":"ดูลายเซ็นอีเมล",
  "ccnotification.gatewayconfig.label.select.sendNumber.title":"การเลือกหมายเลขผู้ส่ง",
  "ccnotification.gatewayconfig.label.add.sendNumber.title":"การเพิ่มหมายเลขผู้ส่ง",
  "ccn.whitelist.urlvalidatefailed":"\u0E42\u0E1B\u0E23\u0E14\u0E1B\u0E49\u0E2D\u0E19 IP \u0E2B\u0E23\u0E37\u0E2D\u0E0A\u0E37\u0E48\u0E2D\u0E42\u0E14\u0E40\u0E21\u0E19",
  "ccnotification.notification.notificationRoute.recipientAddress" : "ที่อยู่ผู้รับ",
  "ccnotification.notification.notificationRoute.implementationMode" : "ดําเนินการตาม",
  "ccnotification.notification.notificationRoute.edit" : "แก้ไข",
  "ccnotification.notification.notificationRoute.newNotificationRoute" : "กําหนดค่าการแจ้งเตือนใหม่",
  "ccnotification.notification.notificationRoute.editNotificationRoute" : "แก้ไขเส้นทางการแจ้งเตือน",
  "ccnotification.notification.notificationRoute.inbox" : "กล่องจดหมาย",
  "ccnotification.notification.notificationRoute.serviceInvoking" : "การเรียกใช้บริการ",
  "ccnotification.notification.notificationRoute.selectconfig" : "\u0E42\u0E1B\u0E23\u0E14\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E01\u0E32\u0E23\u0E01\u0E33\u0E2B\u0E19\u0E14\u0E04\u0E48\u0E32",
  "ccnotification.notification.notificationRoute.willdeleteselectconfig" : "\u0E04\u0E38\u0E13\u0E41\u0E19\u0E48\u0E43\u0E08\u0E27\u0E48\u0E32\u0E15\u0E49\u0E2D\u0E07\u0E01\u0E32\u0E23\u0E25\u0E1A\u0E01\u0E32\u0E23\u0E01\u0E33\u0E2B\u0E19\u0E14\u0E04\u0E48\u0E32\u0E17\u0E35\u0E48\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E43\u0E0A\u0E48\u0E2B\u0E23\u0E37\u0E2D\u0E44\u0E21\u0E48?",
  "ccnotification.notification.notificationRoute.deletenum.outstripping" : "\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E25\u0E1A\u0E1A\u0E31\u0E19\u0E17\u0E36\u0E01\u0E44\u0E14\u0E49\u0E21\u0E32\u0E01\u0E17\u0E35\u0E48\u0E2A\u0E38\u0E14 100 \u0E1A\u0E31\u0E19\u0E17\u0E36\u0E01\u0E43\u0E19\u0E41\u0E15\u0E48\u0E25\u0E30\u0E04\u0E23\u0E31\u0E49\u0E07",
  "ccnotification.notification.notificationRoute.delSuccess" : "\u0E25\u0E1A\u0E2A\u0E33\u0E40\u0E23\u0E47\u0E08",
  "ccnotification.notification.notificationRoute.delFail" : "การลบล้มเหลว",
  "ccnotification.notification.notificationRoute.gateway" : "เกตเวย์",
  "ccnotification.notification.notificationRoute.gatewaynotexist" : "เกตเวย์ที่เลือกไม่มีอยู่",
  "ccnotification.notification.notificationRoute.receivernotexist" : "ผู้รับที่เลือกไม่มีอยู่",
  "ccnotification.notification.notificationRoute.receiveaddressexist" : "ที่อยู่รับมีอยู่แล้ว",
  "ccnotification.notification.notificationRoute.intelligentOrder" : "การสร้างเคสอัจฉริยะ",
  "ccnotification.notification.notificationRoute.mobilerule" : "โปรดกรอกหมายเลขโทรศัพท์มือถือ แยกหมายเลขโทรศัพท์มือถือหลายหมายเลขด้วยเครื่องหมายอัฒภาค",
  "ccnotification.notification.notificationRoute.inputPhoneNum" : "ป้อนหมายเลขโทรศัพท์มือถือ",
  "ccnotification.notification.notificationRoute.receiveAddrLen" : "ความยาวต้องไม่เกิน 1024",
  "ccnotification.notification.notificationRoute.retryNumLimitRule" : "ค่าอินพุตอยู่นอกช่วง: 0-3",
  "ccn.variable.willdeleteselectvariable": "แน่ใจไหมว่าต้องการลบตัวแปรที่เลือก",
  "ccn.variable.deleteselectvariable": "ลบตัวแปร",
  "ccn.variable.variableisreferenced": "ตัวแปรอ้างอิงโดยโฟลว์",
  "ccn.variable.view.references": "ดูการอ้างอิง",
  "ccn.variable.view.references.none": "ดูการอ้างอิง",
  "ccn.variable.selectvariable": "โปรดเลือกพารามิเตอร์ตัวแปร",
  "ccn.variable.field": "ตัวแปรการแจ้งเตือน",
  "ccn.unit.bytes": "ไบต์",
  'ccn.unit.millisecond': 'มิลลิวินาที',
  "ccn.message.fullscreen": "ขยายให้ใหญ่ที่สุด",
  'ccn.sms.config.batch.delete': 'การลบจํานวนมาก',
  'ccn.sms.config.receiver.uplinkMess': 'รับ SMS อัปลิงก์',
  "ccn.sms.config.create.smsConfig": "การกําหนดค่า SMS ใหม่",
  "ccn.sms.config.modify.smsConfig": "การแก้ไขการตั้งค่า SMS",
  "ccnotification.smstemplate.message.edit.fail": "ไม่สามารถแก้ไขแม่แบบ HUAWEI CLOUD SMS ได้",
  "ccnotification.message.choose": "โปรดเลือกข้อมูล",
  "ccnotification.message.add.attachement": "เพิ่มเอกสารแนบ",
  "ccnotification.message.defaultsenderid.error": "หมายเลขผู้ส่งเริ่มต้นไม่อยู่ในช่วงที่อนุญาต โปรดเลือกหมายเลขที่ถูกต้อง",
  "ccnotification.template.message.emailAddressEmpty": "มีอีเมลผู้รับที่ยังไม่ได้กำหนดค่า:",
  "ccn.countrycode.type": "ประเภทของรหัสประเทศนำหน้า",
  "ccn.countrycode.type.with.plus": "มีเครื่องหมาย + นำหน้า",
  "ccn.countrycode.type.without.plus": "ไม่มีเครื่องหมาย + นำหน้า"
}