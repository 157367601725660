<template>
  <!-- 音频组件功能： 播放 暂停 下载 调整音量 调整播放速度 关闭 -->
  <div class="aicc-audio">
    <div class="audio" :class="{ 'audio-width-canDownLoad': canDownLoad }">
      <!-- 播放暂停 -->
      <div class="play-stop-div" @click="playPauseAudio">
        <img src="@/assets/img/audio/ic_record_play@3x.png" v-show="!audioData.playing" class="audio-img" />
        <img src="@/assets/img/audio/ic_record_stop@3x.png" v-show="audioData.playing" class="audio-img" />
      </div>

      <!-- 时间 -->
      <div class="time-wrap" :class="{ 'time-wrap-lite': isLite }">
        <span class="currentTime">{{ transTime(audioData.currentTime) }}</span>
        <span class="duraTime" v-show="durationShow">/{{ transTime(audioData.duration) }}</span>
      </div>

      <!-- 进度条 -->
      <div id="slider-wrap-id" ref="sliderWrapRef" class="slider-wrap"
        :class="{ 'slider-wrap-width-canDownLoad': canDownLoad }" target="wrap" @click="clickSliderWrap">
        <!-- 正在播放的进度条 -->
        <div class="slider-bar" target="sliderbar" :style="{ width: sliderbarWidth }"></div>
      </div>

      <!-- 倍速 -->
      <div v-if="!isLite" class="playback-speed-div" @mouseleave="playbackSpeedMouseLeave"
        @mouseenter="playbackSpeedMouseEnter">
        <span class="playback-speed-span">{{ playbackSpeed }}</span>
        <!-- 倍速条 -->
        <div class="playback-speed-bar" v-show="audioData.showPlaybackSpeedBar">
          <ul class="playback-speed-ul">
            <li class="playback-speed-li" @click="adjustingPlaybackSpeed(0.5, '0.5X')">0.5X</li>
            <li class="playback-speed-li" @click="adjustingPlaybackSpeed(0.75, '0.75X')">0.75X</li>
            <li class="playback-speed-li" @click="adjustingPlaybackSpeed(1, '1.0X')">1.0X</li>
            <li class="playback-speed-li" @click="adjustingPlaybackSpeed(1.25, '1.25X')">1.25X</li>
            <li class="playback-speed-li" @click="adjustingPlaybackSpeed(1.5, '1.5X')">1.5X</li>
            <li class="playback-speed-li" @click="adjustingPlaybackSpeed(2, '2.0X')">2.0X</li>
          </ul>
        </div>
      </div>

      <!-- 下载 -->
      <img class="audio-img download-img" src="@/assets/img/audio/ic_record_download@3x.png" @click="downloadHandle"
        v-show="!isLite && canDownLoad" />

      <!-- 调整音量 -->
      <div v-if="!isLite" class="volume-and-volume-bar" @mouseleave="volumeMouseLeave" @mouseenter="volumeMouseEnter">
        <img class="audio-img" src="@/assets/img/audio/ic_record_volume@3x.png" @click="mutedClick" v-show="!aiccMuted" />
        <img src="@/assets/img/audio/ic_record_muted@3x.png" v-show="aiccMuted" @click="mutedClick" class="audio-img" />
        <!-- 音量条 -->
        <div class="volume-bar" v-show="audioData.showVolumeBar">
          <div class="bar-inner" target="inner" @click="clickBarInner">
            <div class="bar-inner-curr" target="curr" :style="{ height: volumeBarInnerCurrHeight }"></div>
            <span class="bar-inner-curr-btn" target="btn" :style="{ top: volumeBarInnerCurrBtnTop }"></span>
          </div>
          <span class="bar-inner-num">{{ volumeBarInnerCurrElHeight }}</span>
        </div>
      </div>

      <!-- 关闭 -->
      <img src="@/assets/img/audio/ic_record_close@3x.png" v-show="!isLite && canClose" @click="closeAiccAudio"
        class="audio-img close-img" />
    </div>
    <!-- 真实音频组件 -->
    <audio ref="audioRef" id="realAudio" preload="auto" @ended="audioEnded" @timeupdate="audioTimeupdate"
      @canplay="audioCanplay" @error="audioError"></audio>
  </div>
</template>
<script lang="ts">
import {
  defineComponent,
  reactive,
  ref,
  onDeactivated,
  onMounted,
  getCurrentInstance,
  watch,
  onBeforeUnmount
} from 'vue'
import AiccElMessage from '@/utils/el-message';

export default defineComponent({
  name: 'AiccAudio',
  props: {
    src: {
      type: String,
      required: true
    },
    autoplay: {
      type: String,
      required: false
    },
    canDownLoad: {
      type: Boolean,
      required: false
    },
    canClose: {
      type: Boolean,
      required: false
    },
    isLite: {
      type: Boolean,
      required: false
    },
    isOptimizationSuggestions: {
      type: Boolean,
      required: false
    }
  },
  setup(props, context) {
    const {
      appContext: {
        config: { globalProperties }
      }
    } = getCurrentInstance()
    const sliderbarWidth = ref('0%')
    const audioRef = ref()
    const sliderWrapRef = ref()
    const canDownLoad = props.canDownLoad
    const canClose = props.canClose
    const isLite = props.isLite
    const isOptimizationSuggestions = props.isOptimizationSuggestions
    const audioData = reactive({
      autoplay: props.autoplay, // 如果出现该属性，则音频在就绪后马上播放
      playing: false, //现在是否正在播放中
      duration: 0, // 音频总时长
      currentTime: 0, // 当前播放的位置
      showVolumeBar: false, //是否显示音量条
      showPlaybackSpeedBar: false // 是否显示倍速条
    })
    const durationShow = ref(true)
    let audioEl: HTMLAudioElement | null = null
    let playbackSpeed = ref('1.0X')
    let aiccMuted = ref(false)
    const volumeBarHeight = 100 // 音量条的高度 和 bar-inner 的height保持一致
    let volumeBarInnerCurrHeight = ref('100%')
    let volumeBarInnerCurrBtnTop = ref('97%')
    let volumeBarInnerCurrElHeight = ref(100)
    // 判断是否资源是否加载错误
    const resourceLoadError = ref(false)

    watch(
      () => props.src,
      (newSrc) => {
        // 动态设置音频src
        audioRef.value && audioRef.value.setAttribute('src', newSrc)
      }
    )

    // 添加音频url
    const setAudioSrc = (audioUrl) => {
      audioRef.value && audioRef.value.setAttribute('src', audioUrl)
    }

    // 点击进度条，调整播放进度
    const clickSliderWrap = (e) => {
      const wrapElWidth = window.getComputedStyle(sliderWrapRef.value).width
      const styleLeft = `${getPercentage(e.offsetX, wrapElWidth)}%`
      updateProgressBar(styleLeft)
      currentTimeByProgressBar(styleLeft)
    }

    const audioError = () => {
      resourceLoadError.value = true
    }

    // 播放或暂停音频
    const playPauseAudio = (e) => {
      if (!props.src) {
        // 如果没有传入资源链接，点击播放直接忽略
        audioData.playing = false
        return
      }
      if (resourceLoadError.value || !audioEl) {
        audioData.playing = false
        // 没有加载到音频，提示 加载资源异常，请检查相关配置
        AiccElMessage.error(globalProperties.$t('ccm.ivrvoice.play.error'))
      }
      if (e === 'isFirst') {
        audioPlay()
        return
      }
      if (!audioData.playing) {
        audioPlay()
      } else {
        audioPause()
      }
    }

    // 计算百分比的分子
    function getPercentage(num: number | string, den: number | string): number {
      const numerator = typeof num === 'number' ? num : parseFloat(num)
      const denominator = typeof den === 'number' ? den : parseFloat(den)
      return Math.round((numerator / denominator) * 10000) / 100
    }

    /**
     * 更新进度条
     * @param percentage 得到一个百分比的字符串
     */
    function updateProgressBar(percentage: string) {
      sliderbarWidth.value = percentage
    }

    // 音频播放结束
    const audioEnded = () => {
      audioData.playing = false
      context.emit('audioPlayingHandle', false) //更新父组件的播放暂停按钮
    }

    // 播放进度：表示audio正在播放，currentTime在更新
    const audioTimeupdate = (e) => {
      audioData.currentTime = e.target.currentTime
      progressBarBycurrentTime()
    }

    // 当媒体音频能够被播放时
    const audioCanplay = (e) => {
      resourceLoadError.value = false
      audioEl = e.target
      let time = Math.floor(audioEl?.duration)
      if (!Number.isFinite(time)) {
        durationShow.value = false
      }

      if (time !== 0 && time !== Infinity) {
        durationShow.value = true
        audioData.duration = e.target.duration
        context.emit('getAudioDuration', e.target.duration)
        aiccMuted.value = audioEl.muted
        if (audioData.autoplay == 'autoplay') {
          playPauseAudio('isFirst')
        }
      }
    }

    // 播放
    function audioPlay() {
      if (audioEl) {
        audioEl.play()
        audioData.playing = true
        context.emit('audioPlayingHandle', true) //更新父组件的播放暂停按钮
      }
    }

    // 暂停播放
    function audioPause() {
      if (audioEl) {
        audioEl.pause()
        audioData.playing = false
        context.emit('audioPlayingHandle', false)
      }
    }

    // 进度条和音频播放进度进行关联
    function progressBarBycurrentTime() {
      const progress = getPercentage(audioData.currentTime, audioData.duration)
      updateProgressBar(`${progress}%`)
    }

    /**
     * 播放进度与进度条进行关联
     * @param stylePercentage 圆圈的left值
     */
    function currentTimeByProgressBar(styleLeft: string) {
      if (audioEl) {
        const currentTime = (parseFloat(styleLeft) / 100) * audioData.duration
        audioEl.currentTime = currentTime
        audioData.currentTime = currentTime
      }
    }

    // 格式化时间格式
    const transTime = (time: number) => {
      let minute = parseInt(time / 60)
      let minuteStr = minute
      let sec = parseInt(time % 60) + ''
      let isM0 = ':'
      if (minute == 0) {
        minuteStr = '00'
      } else if (minute < 10) {
        minuteStr = '0' + minute
      }
      if (sec.length == 1) {
        sec = '0' + sec
      }
      return minuteStr + isM0 + sec
    }

    // 调整音量
    const clickBarInner = (e) => {
      if (e.target.getAttribute('target') === 'btn') {
        return
      }
      let currElHeight = e.offsetY
      const percentage = getPercentage(currElHeight, volumeBarHeight)
      adjustVolume(percentage, currElHeight)
    }

    // 调整正在播放的音量条的高度和音频实际音量
    const adjustVolume = (percentage, currElHeight) => {
      if (!audioEl) {
        return
      }
      volumeBarInnerCurrHeight.value = `${percentage}%`
      volumeBarInnerCurrBtnTop.value = `${percentage - 3}%`
      volumeBarInnerCurrElHeight.value = parseInt(currElHeight)
      audioEl.volume = percentage / 100 // 调整音频音量
    }

    // 静音
    const mutedClick = () => {
      if (!audioEl) {
        return
      }
      audioEl.muted = !audioEl.muted // 设置静音
      aiccMuted.value = audioEl.muted
      if (audioEl.muted) {
        adjustVolume(2, 0)
      } else {
        adjustVolume(100, 100)
      }
    }

    // 鼠标离开音量图标
    const volumeMouseLeave = () => {
      audioData.showVolumeBar = !audioData.showVolumeBar
    }

    // 鼠标进入音量图标
    const volumeMouseEnter = () => {
      audioData.showVolumeBar = !audioData.showVolumeBar
    }

    // 鼠标离开倍速
    const playbackSpeedMouseLeave = () => {
      audioData.showPlaybackSpeedBar = !audioData.showPlaybackSpeedBar
    }

    // 鼠标进入倍速
    const playbackSpeedMouseEnter = () => {
      audioData.showPlaybackSpeedBar = !audioData.showPlaybackSpeedBar
    }

    // 调整播放速度
    const adjustingPlaybackSpeed = (speed, speedStr) => {
      if (!audioEl) {
        return
      }
      audioEl.playbackRate = speed
      playbackSpeed.value = speedStr
    }

    // 下载回调
    const downloadHandle = () => {
      if (!audioEl) {
        return
      }
      context.emit('downloadHandle')
    }

    // 关闭音频回调
    const closeAiccAudio = () => {
      context.emit('closeAudioHandle')
    }

    onMounted(() => {
      setAudioSrc(props.src)
    })

    const stopWatch = watch(
      () => audioRef.value,
      (value, oldValue) => {
        if (isOptimizationSuggestions) {
          return;
        }
        if (value && !oldValue && props.src) {
          audioRef.value.onerror = function (data: string | Event) {
            audioData.playing = false
            AiccElMessage.error(globalProperties.$t('ccm.ivrvoice.play.error'));
          }
        }
      }
    )

    onBeforeUnmount(() => {
      stopWatch();
    })

    onDeactivated(() => {
      if (audioData.playing) {
        audioPause()
      }
    })
    return {
      volumeBarInnerCurrHeight,
      volumeBarInnerCurrBtnTop,
      audioRef,
      sliderWrapRef,
      sliderbarWidth,
      canDownLoad,
      canClose,
      isLite,
      isOptimizationSuggestions,
      audioData,
      volumeBarInnerCurrElHeight,
      aiccMuted,
      playbackSpeed,
      setAudioSrc,
      closeAiccAudio,
      clickSliderWrap,
      clickBarInner,
      playPauseAudio,
      audioEnded,
      audioTimeupdate,
      audioCanplay,
      transTime,
      mutedClick,
      volumeMouseLeave,
      volumeMouseEnter,
      playbackSpeedMouseEnter,
      playbackSpeedMouseLeave,
      adjustingPlaybackSpeed,
      downloadHandle,
      audioError,
      durationShow
    }
  }
})
</script>
<style lang="less" scoped>
.aicc-audio {
  .audio-width-canDownLoad {
    width: 100%;
    background: #f7f7f7;
    box-shadow: 0px 2px 4px 2px rgba(215, 215, 215, 0.5);
  }

  .audio {
    display: flex;
    padding: 0 10px;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    border-radius: 18px;
    width: 100%;
    background: #f9f9f9;
    padding: 0 16px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);

    .play-stop-div {
      line-height: 0px;

      .icon {
        cursor: pointer;
      }
    }

    .slider-wrap-width-canDownLoad {
      width: 41%;
    }

    .slider-wrap {
      position: relative;
      display: flex;
      align-items: center;
      height: 4px;
      background-color: rgba(23, 23, 23, 0.15);
      cursor: pointer;
      flex: 1;
      margin-inline-start: 16px;

      .slider-bar {
        height: 4px;
        background-color: #1c1c1c;
      }
    }

    .time-wrap {
      -moz-user-select: none;
      -webkit-user-select: none;
      user-select: none;
      height: 30px;
      line-height: 30px;
      font-family: PingFangSC-Regular;
      font-size: var(--14px-to-rem);
      color: #36383c;
      font-weight: 400;
      margin-inline-start: 16px;
    }

    .time-wrap-lite {
      order: 9;
    }

    .volume-and-volume-bar {
      position: relative;
      line-height: 0px;
      margin-inline-start: 16px;

      .volume-bar {
        position: absolute;
        z-index: 9999;
        top: 20px;
        left: -6px;
        clear: both;
        width: 30px;
        height: 139px;
        background: #ffffff;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
        border-radius: 4px;

        .bar-inner-num {
          font-family: PingFangSC-Medium;
          font-size: var(--12px-to-rem);
          color: #1c1c1c;
          font-weight: 500;
          position: absolute;
          bottom: 10px;
          top: auto;
          left: 5px;
          margin: 0 auto;
        }

        .bar-inner {
          position: absolute;
          left: 14px;
          width: 4px;
          height: 100px; //音量条的高度，和常量 volumeBarHeight 保持一致
          padding: 4px 0;
          bottom: 30px;
          background-color: rgba(23, 23, 23, 0.15);
          border-radius: 3px;

          .bar-inner-curr {
            position: absolute;
            bottom: auto;
            top: 0px;
            left: 0;
            overflow: hidden;
            height: 100px;
            width: 4px;
            background: #1c1c1c;
            border-radius: 3px;
          }

          .bar-inner-curr-btn {
            position: absolute;
            top: 97px;
            left: 2px;
            width: 6px;
            height: 6px;
            background-position: -40px -250px;
            background-color: #1c1c1c;
            border-radius: 100%;
            cursor: pointer;
            user-select: none;
            transform: translate(-50%);
          }
        }
      }
    }

    .playback-speed-div {
      position: relative;
      font-family: PingFangSC-Medium;
      font-size: var(--14px-to-rem);
      color: #36383c;
      line-height: 24px;
      font-weight: 500;
      margin-inline-start: 16px;

      .playback-speed-span {
        font-family: PingFangSC-Medium;
        font-size: var(--14px-to-rem);
        color: #36383c;
        line-height: 24px;
        font-weight: 500;
      }

      .playback-speed-bar {
        z-index: 9999;
        position: absolute;
        width: 78px;
        height: 192px;
        left: -24px;
        background: #ffffff;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
        border-radius: 4px;

        .playback-speed-ul {
          .playback-speed-li {
            margin: 0 auto;
            text-align: center;
            line-height: 32px;
            border-radius: 4px;
            cursor: pointer;

            &:hover {
              background: #f5f5f5;
            }
          }
        }
      }
    }
  }

  .audio-img {
    height: 20px;
    width: 20px;
    cursor: pointer;
  }

  .download-img {
    margin-inline-start: 16px;
  }

  .close-img {
    margin-inline-start: 16px;
  }
}
</style>