<template>
    <div>
        <sweet-dialog v-model="dialogVisible" :title="$t('isales.taskinfo.title.chooseSkill')" width="616" class="aicc-dialog-limit-height" :close-on-click-modal="false">
            <aicc-advanced-search
                    :templateMetaData="templateMetaData"
                    @handleSearch='searchPageFirst'>
            </aicc-advanced-search>
            <aicc-table :height="430"
                    :tableData="skillList"
                    :tableColumns="tableColumns"
                    :tableTools="{ showPagination: true, columnConfig: false }"
                    :paginationConfig="paginationConfig"
                    @handlePagination="handlePagination"
                    ref='refAiccTable'>
            </aicc-table>
            <template #footer>
      <span class="dialog-footer">
        <sweet-button v-if="cmsEnvironment==='cms' && menuMode!='called'" @click="createSkill">{{ $t('isales.agent.title.skillCreate') }}</sweet-button>
        <sweet-button @click="dialogVisible = false">{{ $t('isales.common.title.cancel') }}</sweet-button>
        <sweet-button type="primary" @click="confirm">{{ $t('isales.common.title.confirm') }}</sweet-button>
      </span>
            </template>
        </sweet-dialog>
    </div>
</template>

<script>
    import {querySkillListAndCount} from "@/views/isales/api/systemAutoCallTask.js";

  export default {
    name: "isalesSkillSelect",
    props: {
      show: {
        type: Boolean,
        default: false
      },
      cmsEnvironment: '',
      menuMode: '',
    },
    data() {
      return {
        skillList: [],
        tableColumns: [
          {
            type: 'radio',
            radioKey: 'skillId', // 单选绑定的字段名
            width: '50px',
            'show-overflow-tooltip': false
          },
          {
            label: this.$t('isales.taskinfo.title.skillName'),
            prop: 'name',
          }
        ],
        paginationConfig: {
          pageSize: 10, // 每页条数
          total: 0, //总条数
          layout: "total, prev, pager, next" // 分页组件布局配置
        },
        templateMetaData: {
          basicConfig: { // 基础搜索配置
            basicSearch: [
              {
                searchType: 'primary', // primary: 有搜索图标的输入框；select: 下拉框; input: 搜索框；date: 日期选择器
                modelValue: 'name', // v-model绑定的key值
                modelDefault: '', // v-model绑定的默认值
                placeholderValue: this.$t('isales.agent.message.searchSkillName'), // placeholder绑定的值 国际化之后的值
                rules: [],
                colConfig: {
                  xl: 15,
                  lg: 15
                }
              }
            ]
          }
        },
        limit: 10,
        offset: 0,
      }
    },
    computed: {
      dialogVisible: {
        get() {
          return this.show
        },
        set(val) {
          this.$emit('update:show', val)
        }
      }
    },
    methods: {
      searchPageFirst(params){
        this.offset = 0;
        this.$refs.refAiccTable.setCurrentPage(1);
        this.querySkillListAndCount(params);
      },
      async querySkillListAndCount(params) {
        let queryCondition = {};
        if (params && params.params && params.params.name) {
          queryCondition['name'] = params.params.name;
        }
        queryCondition['limit'] = this.limit;
        queryCondition['offset'] = this.offset;
        querySkillListAndCount(queryCondition).then(res => {
          if (res) {
            this.skillList = res.skills;
            this.paginationConfig.total = res.count;
          }
        })
      },
      handlePagination(params) {
        this.limit = params.limit;
        this.offset = params.offset;
        this.querySkillListAndCount(params);
      },
      confirm() {
        this.dialogVisible = false
        if($.isEmptyObject(this.$refs.refAiccTable.getRadioCurrentRow())){
          this.$message({
            type: 'error',
            duration: 10000,
            showClose: true,
            offset: 56,
            message: this.$t('isales.agent.label.skillChoice')
          });
        }
        this.$emit('isalesSkillSelectConfirm', this.$refs.refAiccTable.getRadioCurrentRow())
      },
      createSkill() {
        this.dialogVisible = false
        this.$emit('isalesSkillSelectConfirm', null);
      }
    },
    async created() {
      await this.querySkillListAndCount();
    },
  }
</script>

<style scoped>

</style>