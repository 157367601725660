import i18n from '@/lang'

const $t = i18n.global.t

export function checkUserName(rule, value, callback) {
  if (value && value.length > 64) {
    callback(new Error($t('common.baseui.MESSAGE.VALIDATE_MAXLENGTHB').replace('0', 64)))
  }
  var reg = /[~#$%&*()/=+{}\\[\];'"|!,]/
  if (value == '' || !reg.test(value)) {
    //校验通过
    callback()
  } else {
    callback(new Error($t('SM.ORGSTAFF.LABEL.EMPLOYEE_USERNAME_VALIDATOR')))
  }
}

export function checkUserAccount(rule, value, callback) {
  if (value && value.length > 64) {
    callback(new Error($t('common.baseui.MESSAGE.VALIDATE_MAXLENGTHB').replace('0', 64)))
  }
  var reg = /^[a-zA-Z0-9_\-@\\.]+$/
  if (value == '' || reg.test(value)) {
    //校验通过
    callback()
  } else {
    callback(new Error($t('SM.ORGSTAFF.LABEL.EMPLOYEE_ACCOUNT_VALIDATOR_ADD')))
  }
}

export function checkUserName2(rule, value, callback) {
  if (value && value.length > 64) {
    callback(new Error($t('common.baseui.MESSAGE.VALIDATE_MAXLENGTHB').replace('0', 64)))
  }
  if (value === '') {
    callback(new Error($t('SM.LOGIN.TIPS.REQUIRED')))
  } 
  const reg = /[~#$%&*()/=+{}\\[\];'"|!,]/
  if (!reg.test(value)) {
    //校验通过
    callback()
  } else {
    callback(new Error($t('SM.ORGSTAFF.LABEL.EMPLOYEE_USERNAME_VALIDATOR')))
  }
}


export function checkUserAccount2(rule, value, callback) {
  if (value && value.length > 64) {
    callback(new Error($t('common.baseui.MESSAGE.VALIDATE_MAXLENGTHB').replace('0', 64)))
  }
  if (value === '') {
    callback(new Error($t('SM.LOGIN.TIPS.REQUIRED')))
  } 
  const reg = /^[a-zA-Z0-9_\-@\\.]+$/
  if (reg.test(value)) {
    //校验通过
    callback()
  } else {
    callback(new Error($t('SM.ORGSTAFF.LABEL.EMPLOYEE_ACCOUNT_VALIDATOR_ADD')))
  }
}
export function checkEmail(rule, value, callback) {
  if (value && value.length > 64) {
    callback(new Error($t('common.baseui.MESSAGE.VALIDATE_MAXLENGTHB').replace('0', 64)))
  }
  const emailPattern = new RegExp('(.+@.+\\..+){1}');
  if (emailPattern.test(value)) {
    //校验通过
    callback()
  } else {
    callback(new Error($t('SM.ORGSTAFF.LABEL.EMPLOYEE_EMAIL_VALIDATOR')))
  }
}

export function checkPhone(rule, value, callback) {
  if (value && value.length > 32) {
    callback(new Error($t('common.baseui.MESSAGE.VALIDATE_MAXLENGTHB').replace('0', 32)))
  }
  var reg = /^[0-9]*$/
  if (value == '' || reg.test(value)) {
    //校验通过
    callback()
  } else {
    callback(new Error($t('oifde.common.validation.digits')))
  }
}

export function checkDescription(rule, value, callback) {
    if (value && value.length > 540) {
        callback(new Error($t('common.baseui.MESSAGE.VALIDATE_MAXLENGTHB').replace('0', 540)))
      }
      var reg = /^[^<>]+$/
      if (value == '' || reg.test(value)) {
        //校验通过
        callback()
      } else {
        callback(new Error($t('SM.ORGSTAFF.TIPS.DESC')))
      }
}

export function menuNameValidator(rule, value, callback) {
  let regEn = /[!！#$%&()￥+:："{},\\/'[\]=-]/g;
  let regSpec = /[<>^]/g;
  let regCn = /[]/g;
  if (regEn.test(value) || regSpec.test(value) || regCn.test(value)) {
    callback(new Error($t('SM.MENU.NAME.SPECIAL.CHAR')));
  }
  value = value.trim();
  let len = 0;
  for (let i = 0; i < value.length; i++) {
    if (value.charCodeAt(i) > 127 || value.charCodeAt(i) === 94) {
      len += 3;
    } else {
      len++;
    }
  }
  if (len > 50) {
    callback(new Error($t('agentconsole.menumanage.message.menuname')));
  }
  callback();
}

export function urlValidator(rule, value, callback) {
  let regCne = /[\u4e00-\u9fa5]/;
  if (regCne.test(value)) {
    callback(new Error($t('agentconsole.menumanage.message.menu_url')));
  }
  callback();
}

// 业务帐号校验
export const accountCodeValidate = (rule, value, callback) => {
  let reg = /^[a-zA-Z0-9_-ÁáÉéxÍÍíÓóÚúÂâÊêÔôÀàÃãÕõÜüÇç@\"\s!#$%&'*+-/=?^_`{|}~]+$/
  if (value === '' || reg.test(value)) {
    callback()
    return
  }
  callback(new Error($t('cms.normal.text.validate')))
}

export const specialCodeValidate = (rule, value, callback) => {
  let regEnSpecialChar = /[`~!#$%^&*()<>+=|,{}":;\[\]/?]/
  let regCnSpecialChar = /[~！#￥%……&*（）<>——+|{}《》。【】‘；：”“’,、？]/
  if (!regEnSpecialChar.test(value) && !regCnSpecialChar.test(value)) {
    callback()
    return
  }
  callback(new Error($t('cms.sendnote.message.checkInput')))
}

export const ChineseValidate = (rule, value, callback) => {

    let ChineseSpeciaChar = new RegExp("[\\u4E00-\\u9FFF]+","g")
  if (ChineseSpeciaChar.test(value)) {
    callback(new Error($t('cms.sendnote.message.checkInput')))
    return
  }
  callback()
}
export default {}
