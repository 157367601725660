export default {
  'sm.assign.employee': 'Affecter un employé',
  'SM.USERGROUP.SHOW.LIST': "Groupes d'utilisateurs",
  'common.baseui.LABEL.HEADING_BASEINFO': 'Informations de base',
  'SM.USERGROUP.LABEL.NAME': "Nom du groupe d'utilisateurs",
  'common.bremui.label.description': 'Descriptif',
  'SM.SERVLET.EMPLOYEE': 'Empleado',
  'common.statemachineui.button.add': 'Ajouter',
  'sm.add.usergroup': "Création d'un groupe d'utilisateurs",
  'sm.edit.usergroup': "Modifier le groupe d'utilisateurs",
  'SM.ORGSTAFF.BUTTON.COMMON_OK': 'OK',
  'SM.COMMON.LABEL.SYSMENU_SUSPENDED': 'Désactiver',
  'SM.ORGSTAFF.MESSAGE.OPERATESUCCESS': 'Opération réalisée avec succès.',
  'SM.ORGSTAFF.MESSAGE.STAFF_AUTHCOPY_SOURCENO_CONTBEADMIN_ERR':
    'Le compte administrateur système ne peut pas être utilisé comme compte source.',
  'SM.COMMON.BUTTON.BANKCARD_SEARCH': 'Rechercher',
  'SM.ORGSTAFF.MESSAGE.BE_DELETESUCCESS': 'Supprimé avec succès.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_DELETE_FAILED': "Impossible de supprimer l'utilisateur.",
  'SM.ORGSTAFF.TIPS.INVALID_IP_ADDRESS': 'Adresse IP non valide.',
  'SM.ORGSTAFF.LABEL.ACCESS.ADD_FAIL': "L'ajout a échoué.",
  'SM.ORGSTAFF.TITLE.CONTACT_DESC': 'Descriptif',
  'SM.COMMON.LABEL.AREALIST': 'Liste des zones',
  'SM.COMMON.LABEL.CURRENCY_DECIMALSYMBOL': 'Symbole décimal',
  'SM.COMMON.TIPS.CHARGECODE_CODEINPUT': 'Vous ne pouvez entrer que des lettres et des chiffres.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_SECONDMENT': 'Détachement',
  'SM.COMMON.LABEL.CURRENCY_STANDARD_MEASUREMENT': "Drapeau d'unité standard",
  'SM.COMMON.LABEL.HOLIDAY_THURSDAY': 'Jeu',
  'SM.COMMON.LABEL.SYSMENU_AUTHID': "ID d'autorisation du menu",
  'SM.ORGSTAFF.MESSAGE.REGION_CONFIRM_USEPARENT':
    'Si les données du nœud parent sont utilisées, la configuration actuelle du fuseau horaire sera effacée. Êtes-vous sûr de vouloir continuer ?',
  'SM.ORGSTAFF.TITLE.CONTACT_TYPE': 'Type de contact',
  'SM.ORGSTAFF.TITLE.BE_PRECISION': 'Précision de devise',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_SET_ROLE': 'Définir le rôle',
  'SM.ORGSTAFF.MESSAGE.EMPLOYEE_SECONDMENT_CONFIRM': 'Êtes-vous sûr de vouloir résilier ?',
  'SM.ORGSTAFF.LABEL.ORG_BASICINFO': 'Informations de base',
  'SM.COMMON.LABEL.MEASUREMENT_UNIT_OPERARION': 'Opération',
  'SM.ORGSTAFF.LABEL.BANK_TYPE': 'Type de banque',
  'SM.COMMON.BUTTON.SURVEY_DELETE_ANSWER_OPTIONS': 'Supprimer les options de réponse',
  'SM.COMMON.MESSAGE.SYSMENU_DEACTIVATED_NOT_SUPPORT':
    'Le statut du menu désactivé ne prend pas en charge la gestion des autorisations.',
  'SM.ORGSTAFF.TIPS.RESTRICTIP':
    'Saisissez une adresse IP au format xx.* ou xx.xx.* ou xx.xx.xx.*.',
  'SM.ORGSTAFF.TITLE.CONTACT_EMAIL': 'Courriel',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_SECONDMENT_ENDDATE': 'Date de fin',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_SEARCH_CRITERIA_BE': 'BE',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_VIEWFILE': 'voir',
  'SM.ORGSTAFF.MESSAGE.ORG_PARENT_FORBID': "L'unité d'organisation parente a été suspendue.",
  'SM.COMMON.LABEL.ATTR_LANGUAGE': 'Langue',
  'SM.ORGSTAFF.LABEL.TEAM_ASSIGN': 'Un maximum de 2000 employés peuvent être affectés.',
  'SM.COMMON.LABEL.ATTACHMENT_DEL_ACCESSORY': 'Supprimer',
  'SM.ORGSTAFF.LABEL.SEARCH_ALL': 'Rechercher tout le personnel',
  'SM.ORGSTAFF.LABEL.ADDR_DISPLAY_ORDER': "Séquence d'affichage de l'adresse incorrecte.",
  'SM.COMMON.LABEL.MEASUREMENT_DESTMEAUNIT': 'Unité de mesure de cible',
  'SM.ORGSTAFF.MESSAGE.CHOOSE_SELECTED_OU_FIRST':
    "Veuillez d'abord sélectionner un nœud dans l'arborescence des relations organisationnelles.",
  'SM.ORGSTAFF.MESSAGE.STAFF_AUTHCOPY_SOURCENO_EQUALTOTARGET_ERROR':
    'Le compte source ne peut pas être contenu dans le compte cible.',
  'SM.ORGSTAFF.TITLE.ROLE_BASIC': 'Rôle de base',
  'SM.ORGSTAFF.LABEL.STAFF_SESSION_VALIDITY_PERIOD': 'Période de validité de la session (Min)',
  'SM.COMMON.LABEL.CURRENCY_MEASUREMENT': 'Unité Monnaie',
  'SM.PROJECT.TITLE.EXPORT_CURRENT_ORG_EMPLOYEE': 'Tout exporter',
  'SM.ORGSTAFF.LABEL.DEPTMACRESTRICT': "Restriction d'adresse MAC",
  'SM.ORGSTAFF.LABEL.REGION_DAYLIGHTSAVINGTIME': 'DST',
  'SM.ORGSTAFF.MESSAGE.BE_CANNOTDELETECURRENCY': 'La devise par défaut ne peut pas être supprimée.',
  'SM.ORGSTAFF.TITLE.CONTACT_FULL_NAME': 'Nom complet',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_VST': "Type de délai d'expiration de session",
  'SM.COMMON.LABEL.CURRENCY_MEASUREMENT_LIST': 'Unité de mesure de devise',
  'SM.COMMON.LABEL.MEASUREMENT_CONV_LIST': 'Liste de conversion de mesure',
  'SM.COMMON.MESSAGE.VALIDATE_MINLENGTHB': 'Saisissez au moins 0 caractères.',
  'SM.ORGSTAFF.LABEL.REGION_DESCRIPTION': 'Descriptif',
  'SM.COMMON.LABEL.BANKCARD_ACCT_LENG_PARAM': 'Longueur du numéro de carte',
  'SM.COMMON.LABEL.SURVEY_CLASS_SORT': 'Séquence de classification',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_IP_TYPE': "Type d'IP",
  'SM.COMMON.LABEL.CURRENCY_DISPFORMAT_DISPLAYTYPE': "Type d'affichage",
  'SM.COMMON.LABEL.SYSMENU_CONFIGURESYSMENU': 'Configurer le menu Système',
  'SM.ORGSTAFF.LABEL.TEAM_ORG_EMPLOYEE': 'Organiser les employés',
  'SM.ORGSTAFF.LABEL.REGION_DST_ENDDATE': 'Date de fin DST',
  'SM.ORGSTAFF.LABEL.ORG_STOP_TIPS':
    "Après avoir été suspendu, l'organisation ou l'organisation et son organisation subordonnée ne seraient plus disponibles. Confirmez-vous de suspendre ?",
  'SM.ORGSTAFF.TITLE.OPPAUTH_TITLE_MUTEXAUTH_INFOLIST': 'Liste des autorisations exclusives',
  'SM.COMMON.LABEL.ATTACHMENT_DELETE_INFO': 'Êtes-vous sûr de supprimer le fichier?',
  'SM.ORGSTAFF.OPTION.STAFF_REMOVE_PERMISSION': "Supprimer l'autorisation de l'opérateur cible",
  'SM.COMMON.LABEL.MEASUREMENT_EXPORT_SUCCESS': 'Succès',
  'SM.ORGSTAFF.TITLE.CONTACT_TITLE': 'Titre',
  'SM.ORGSTAFF.BUTTON.EMPLOYEE_CANCLE': 'Annuler',
  'SM.ORGSTAFF.LABEL.REGION_DST_SWITCHINTERVAL': 'Décalage DST',
  'SM.COMMON.LABEL.SYSMENU_AFFECTED_MENU': 'Menu concerné',
  'SM.ORGSTAFF.MESSAGE.ENDEARLIERSTART':
    'La date de début ne peut être postérieure à la date de fin.',
  'SM.COMMON.LABEL.MEASUREMENT_UPLOAD_FILE': 'Télécharger le fichier',
  'SM.COMMON.LABEL.SYSMENU_USEPARENT': 'Utiliser les données parentes',
  'SM.COMMON.LABEL.EDIT_CURRENCY_EXCHANGE_RATE': 'Modifier le taux de change',
  'SM.COMMON.LABEL.HOLIDAY_FRIDAY': 'Mon',
  'SM.COMMON.LABEL.CURRENCY_TYPE_SYMBOL': 'Symbole',
  'SM.COMMON.LABEL.SYSMENU_MENUNAME': 'Nom du menu',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_UPLOAD_SIZE_MAX':
    'Le fichier téléchargé a dépassé la taille maximale 0 KB.',
  'SM.COMMON.LABEL.SURVEY_ITEM_NAME': "Nom de l'article",
  'SM.COMMON.TIPS.POPUP_INFO_TITLE': 'Informations',
  'SM.COMMON.LABEL.CHARGECODE_STATUS': 'Statut',
  'SM.COMMOM.MESSAGE.SYSMENU_MENU_STATUS_NOT_CONSISTENT': "L'état du menu n'est pas cohérent.",
  'SM.COMMON.MESSAGE.ERATE_NUMBER_INVALID': 'Numéro non valide',
  'SM.COMMON.LABEL.SURVEY_QUESTIONNAIRE_HEAD': 'Tête de questionnaire',
  'SM.COMMON.LABEL.MODULE_SHORTNAME': 'Nom abrégé',
  'SM.ORGSTAFF.LABEL.REGION_DST_MONTHDAY': 'Mois/Jour',
  'SM.TENANTSPACE.AUTHTYPE.TITLE': "Type d'authentification",
  'SM.ORGSTAFF.MESSAGE.DSTOFFSET.OUTRANAGE':
    "La compensation de l'heure à l'heure n'est pas comprise entre 1 et 86400.",
  'SM.ORGSTAFF.LABEL.WORKGROUP_CONTENTS': 'Contenu du chemin du groupe de travail',
  'SM.ORGSTAFF.MESSAGE.SELECT_EMPLOYEE': 'Veuillez sélectionner un employé',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_NEW_PASSWORD': 'Nouveau mot de passe',
  'SM.COMMON.MESSAGE.EXPORT_VIEW':
    'Veuillez aller dans le menu "Gestion système > Configuration basée sur le projet > Projet" pour voir les résultats exportés.',
  'SM.ORGSTAFF.LABEL.TEAM_TEAMLEVER': "Niveau d'équipe",
  'SM.ORGSTAFF.LABEL.ROLE_TYPE_PUB': 'Rôle public',
  'SM.ORGSTAFF.TITLE.BE_CURRENCY': 'Monnaie',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_QUERY_LIST': 'Liste des employés',
  'SM.COMMON.BUTTON.MEASUREMENT_ADD': 'Nouveau',
  'SM.COMMON.LABEL.SEARCH_NAME': "S'il vous plaît entrer le nom",
  'SM.COMMON.LABEL.HOLIDAY_HOLIDAYDAY': 'vacances',
  'SM.ORGSTAFF.MESSAGE.COMMON_TIPS_STAFF_SELECT_NO_PERIOD': 'Sélectionner un employé',
  'SM.ORGSTAFF.TITLE.ASSIGNED_MANAGER': 'Gestionnaires assignés',
  'SM.TENANTSPACE.LDAP_RDNPREFIX': 'Préfixe RDN',
  'SM.ORGSTAFF.MESSAGE.EXPORT_LIMIT':
    "Le nombre d'exportés plus de 10,000 n'est pas recommandé pour l'exportation.",
  'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_BE': 'BE',
  'SM.COMMON.LABEL.OPERLOG_ENDTIME': 'Heure de fin',
  'SM.COMMON.LABEL.CURRENCY_TYPE_CODE': 'Code',
  'SM.ORGSTAFF.BUTTON.DELETE': 'Supprimer',
  'SM.COMMON.BUTTON.OK': 'OK',
  'SM.ORGSTAFF.BUTTON.ADDR_ADD': 'Ajouter',
  'SM.COMMON.LABEL.CHARGECODE_CODE': 'Code',
  'SM.ORGSTAFF.BUTTON.ADDR_CREATE': 'Nouveau',
  'SM.COMMON.LABEL.SURVEY_APPLICATION_SCOPE': "Champ d'application",
  'SM.COMMON.TITLE.SYSMENU_ERROR': 'Erreur',
  'SM.COMMON.LABEL.SYSMENU_DEACTIVATED': 'Rejeter',
  'SM.COMMON.LABEL.SURVEY_OPTION_NAME': 'Nom des options',
  'SM.ORGSTAFF.LABEL.ORG_SHORT_NAME': 'OU Nom abrégé',
  'SM.ORGSTAFF.BUTTON.EMPLOYEE_CLEAR': 'Effacer',
  'SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE_CREATE': 'Nouveau questionnaire',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_DESC': 'Descriptif',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ROAM_VALID': 'Valide',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_EMAIL_VALIDATOR': 'E-mail non valide',
  'SM.COMMON.LABEL.CHARGECODE_TEST': 'Tester',
  'SM.COMMON.LABEL.SURVEY_CREATED_TIME': 'Créé à',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ACCOUNTRANGE': 'Gamme de compte',
  'SM.ORGSTAFF.LABEL.REGION_DST_MONTHWEEKDAY': 'Mois/Semaine/Jour',
  'SM.ORGSTAFF.LABEL.TEAM_STATUS': 'Statut',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_UPLOAD_EXT_TITLE':
    'Le téléchargement a échoué. Formats de fichiers autorisés : 0 ; Formats de fichiers non autorisés : {1}.',
  'SM.ORGSTAFF.MESSAGE.ERROR_QUERY': "Impossible d'interroger.",
  'SM.COMMON.LABEL.BANKCARD_BANKTYPE_PARAM': 'Type de banque',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_USERNAME_VALIDATOR':
    "Le nom d'utilisateur contient des caractères spéciaux non valides.",
  'SM.COMMON.LABEL.CHARGECODE': 'Code de charge',
  'SM.ORGSTAFF.TITLE.ORG_ASSIGNED_EMP': 'Employés affectés',
  'SM.ORGSTAFF.MESSAGE.SAVE_SUGGESTION': 'Informations',
  'SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE_EDIT': 'Modifier le questionnaire',
  'SM.COMMON.MESSAGE.CHARGECODE_PERFORM_DELETION': 'Êtes-vous sûr de vouloir supprimer ?',
  'SM.COMMON.BUTTON.CHARGECODE_ADD': 'Nouveau',
  'SM.COMMON.MESSAGE.ERATE_CODE_COMPARE':
    'Le code de devise source ne peut pas être le même que le code de devise cible.',
  'SM.ORGSTAFF.LABEL.BANK_LEVEL': 'Niveau de la banque',
  'SM.ORGSTAFF.LABEL.RESTRICTTYPE': 'Type de restriction de connexion',
  'SM.COMMON.LABEL.HOLIDAY_WEDNESDAY': 'Mon',
  'SM.ORGSTAFF.TITLE.CONTACT_PHONE': 'Numéro de téléphone',
  'SM.ORGSTAFF.LABEL.BE_ADDRESS_FORMAT': "Modèle de format d'adresse",
  'SM.ORGSTAFF.LABEL.ORG_DISPLAYORDER': "Séquence d'affichage",
  'SM.ORGSTAFF.LABEL.PWD_UNMATCH_RULE': "Le mot de passe n'est pas conforme aux règles.",
  'SM.COMMON.LABEL.SYSMENU_LABEL': 'Menu Système',
  'SM.COMMON.MESSAGE.AREA_DELETE_INFO': 'Êtes-vous sûr de vouloir supprimer ?',
  'SM.ORGSTAFF.TITLE.STAFF_ROLE_ASSIGN': 'Attribuer un rôle',
  'SM.ORGSTAFF.OPTION.WEEKDAY_FRI': 'Vendredi',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_RESETPWD': 'Réinitialiser le mot de passe',
  'SM.COMMON.LABEL.MODULE_MODULE': 'Module',
  'SM.ORGSTAFF.TITLE.STAFF_LOGIN_RESTRICT': 'Restriction de connexion',
  'SM.COMMON.LABEL.MODULE_ADDRESS': 'URL',
  'SM.ORGSTAFF.TITLE.CURRENCY_DISPLAY_FORMAT': "Format d'affichage de devise",
  'SM.COMMON.LABEL.MEASUREMENT_IMPORT_RESULT': 'Importer le résultat',
  'SM.ORGSTAFF.OPTION.STAFF_IP_SEGMENT': "Segment d'adresse IP",
  'SM.COMMON.TITLE.ACCESS.SELECT_STAFF': "Sélectionnez les employés d'accès",
  'SM.COMMON.MESSAGE.SYSMENU_ACCESS_TIME_ERROR':
    "L'heure de fin d'accès au menu devrait être plus grande que l'heure de début.",
  'SM.ORGSTAFF.MESSAGE.CONTAINS_SUB_ORGS': "L'organisation contient des sous-organisations.",
  'SM.ORGSTAFF.TITLE.ORG_ACCESS_EMP': "Accéder à l'employé affecté",
  'SM.COMMON.LABEL.BANKCARD_ACCT_LENG': 'Longueur du numéro de carte',
  'SM.COMMON.LABEL.SYSMENU_VISITCOUNT': 'Nombre de visites',
  'SM.ORGSTAFF.BUTTON.OURELATIONSHIP_RELOAD': 'Actualiser',
  'SM.COMMON.LABEL.OPERLOG_ACCOUNT': 'Compte',
  'SM.COMMON.LABEL.BANKCARD_SEARCH_CONDITION': 'Condition de recherche',
  'SM.COMMON.LABEL.ATTACHMENT_ADD_ACCESSORY': 'Nouveau',
  'SM.COMMON.MESSAGE.CHARGECODE_TO_TEST': "Êtes-vous sûr de vouloir le changer à l'état Test ?",
  'SM.COMMON.LABEL.SYSMENU_MENUURL': 'URL du menu',
  'SM.ORGSTAFF.AUTHTYPE.LABEL': "Type d'authentification",
  'SM.COMMON.LABEL.ERATE_TODATE': 'À',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_TEAMINFO': "Infos sur l'équipe",
  'SM.COMMON.TIPS.HOME_PHONE':
    "S'il vous plaît entrer le numéro de téléphone correct, p. ex. (+)(0123-)1234567890(-0123)",
  'SM.COMMON.LABEL.CURRENCY_MEASUREMENT_TITLE': 'Unité de mesure de devise',
  'SM.COMMON.LABEL.SURVEY_CLASS_Code': 'Code de classification',
  'SM.COMMON.LABEL.SEARCHCONDITION': 'Critères de recherche',
  'SM.ORGSTAFF.MESSAGE.SAVESUCCESS': 'Opération réalisée avec succès.',
  'SM.TENANTSPACE.LDAP_DOMAIN': 'Domaine',
  'SM.ORGSTAFF.LABEL.ORGANIZATION': "Unité d'organisation",
  'SM.COMMON.BUTTON.ADD_MENU_ACCESS_TIME':
    "Ajout du temps d'accès au menu. Si ce n'est pas config, le menu est accessible à tout moment.",
  'SM.AUC.LABEL.BANK_NAME_INPUT': "Nom de la banque d'entrée",
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_RESET_SUCCESS':
    "Le mot de passe de l'employé est changé avec succès. Les éléments suivants ne peuvent pas être modifiés :",
  'SM.ORGSTAFF.LABEL.ORGIMPORT_TIP':
    "Assurez-vous que les informations du transporteur ont été importées dans le système et que l'opérateur actuel est l'administrateur.",
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ACCOUNT_VALIDATOR_ADD':
    "La valeur ne peut contenir que des chiffres, des lettres, des traits de soulignement (_), des traits d'union, des (.), de points et des signes à ({'@'}).",
  'SM.COMMON.LABEL.MENU_INFO_TITLE': 'Menu Informations de base',
  'SM.COMMON.LABEL.HOLIDAY_YEAR': 'année',
  'SM.COMMON.MESSAGE.DELETE_CONFIRM': 'Êtes-vous sûr de vouloir supprimer ?',
  'SM.COMMON.LABEL.SYSMENU_ACTIVE_A': 'Activer',
  'SM.COMMON.LABEL.HOLIDAY_SATURDAY': 'Sat',
  'SM.COMMON.BUTTON.SURVEY_ENABLE': 'Activé',
  'SM.ORGSTAFF.TITLE.OPERATION': 'Opération',
  'SM.ORGSTAFF.MESSAGE.FORBID_EXSIT_EMPLOYEE':
    "Impossible de désactiver le département de façon permanente parce qu'il y a des employés sous le département.",
  'SM.ORGSTAFF.LABEL.AUTHVIEW_AUTH_BENAME': "Permission's Accueil BE",
  'SM.COMMON.TIPS.MENU_REBUILD': 'Informations',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_SUSPEND': 'Suspendre',
  'SM.ORGSTAFF.LABEL.REGION_SHORTNAME': 'Nom abrégé de la région',
  'SM.ORGSTAFF.LABEL.STAFF_ADD_ROLE': 'Ajouter un rôle de relation',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_ATTACHMENTID': 'Pièce jointe',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_NOT_ALLOW_4A': 'Autoriser la connexion à la page Web directement',
  'SM.COMMON.LABEL.CURRENCY_TYPE_NAME': 'Nom et prénom',
  'SM.ORGSTAFF.OPTION.STAFF_RESTRICTIONTYPE_ALLOWED': 'Autoriser',
  'SM.COMMON.LABEL.OPERLOG_RESET': 'Réinitialiser',
  'SM.COMMON.LABEL.CHARGECODE_NAME': 'Nom et prénom',
  'SM.ORGSTAFF.LABEL.STAFF_DESC': 'Descriptif',
  'SM.ORGSTAFF.LABEL.TEAM_DESCRIPTION': 'Descriptif',
  'SM.COMMON.MESSAGE.DATADICT_DELETE_FAILED': 'La suppression a échoué.',
  'SM.COMMON.LABEL.BANKCARD_NAME': 'Nom de la carte',
  'SM.COMMON.BUTTON.CANCEL': 'Annuler',
  'SM.COMMON.LABEL.ATTACHMENT_DEL_SUCCESS': 'Fichier accessoire supprimé avec succès',
  'SM.ORGSTAFF.LABEL.TO_BE_ASSIGNED_ROLE': 'Se voir attribuer un rôle',
  'SM.COMMON.LABEL.SYSMENU_STATUS': 'Statut',
  'SM.ORGSTAFF.OPTION.STAFF_RETAIN_PERMISSION': "Conserver l'autorisation de l'opérateur cible",
  'SM.COMMON.LABEL.MEASUREMENT_ENTITY_ID': 'ID',
  'SM.COMMON.LABEL.MEASUREMENT_UNIT_SUNIT': 'Unité standard',
  'SM.COMMON.MESSAGE.PROPERTY_SUCCESS_CREATE_INFO': 'Opération réalisée avec succès.',
  'SM.ORGSTAFF.BUTTON.TEAM_OK': 'OK',
  'SM.COMMON.MESSAGE.CHARGECODE_DELETE_DATA':
    'Êtes-vous sûr de vouloir supprimer le code de charge?',
  'SM.COMMON.MESSAGE.VALIDATE_RANGELENGTHB': 'Entrez une valeur de 0 dans 1 caractères.',
  'SM.COMMON.LABEL.OPERLOG_OBJECT_TYPE': "Type d'objet",
  'SM.COMMON.BUTTON.BANKCARD_CANCEL': 'Annuler',
  'SM.ORGSTAFF.TITLE.BE_ADDRESSSETTING': 'Adresse',
  'SM.ORGSTAFF.LABEL.ORG_EMPLOYEE_QUERY_INFO': 'Critères de recherche',
  'SM.COMMON.LABEL.MEASUREMENT_EXPORT_MEA': 'Mesurer',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_UPLODATPERSON': 'Téléchargé par',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_SEARCH': 'Rechercher',
  'SM.COMMON.BUTTON.MENUVSEARCH': "Recréer l'index du menu",
  'SM.ORGSTAFF.TITLE.CONTACT_HOME_FAX': 'Télécopieur à domicile',
  'SM.COMMON.LABEL.CREATE_OPER': 'Créé par',
  'SM.COMMON.LABEL.ERATE_DCURRENCY_CODE': 'Code de devise cible',
  'SM.COMMON.LABEL.CURRENCY_DISPLAY_POSTFIX': 'Afficher Postfix',
  'SM.ORGSTAFF.LABEL.STAFF_ROLE_DESC': 'Descriptif',
  'SM.ORGSTAFF.TITLE.ADDRESS_DISPLAY_FORMAT': "Format d'affichage de l'adresse",
  'SM.ORGSTAFF.TITLE.ADDR_NAME': 'Nom et prénom',
  'SM.COMMON.LABEL.MEASUREMENT_ENTITY_CODE': "Code d'unité",
  'SM.COMMON.MESSAGE.SYSMENU_DELSUCCESS': 'Supprimé avec succès.',
  'SM.ORGSTAFF.LABEL.INPUT_ROLE_NAME': 'Veuillez saisir le nom du rôle',
  'SM.ORGSTAFF.TIPS.DESC': 'Ne saisissez pas de caractères spéciaux <>',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD':
    "Impossible de vérifier le mot de passe de l'employé.",
  'SM.ORGSTAFF.TITLE.CONTACT_OFFICE_FAX': 'Fax de bureau',
  'SM.COMMON.LABEL.CURRENCY_FORMAT_DISPLAY': 'Format de prévisualisation',
  'SM.ORGSTAFF.AUTHTYPE.LDAP': 'LDAP',
  'SM.PROJECT.TITLE.IMPORT_ORG_PAR': 'Importer le paquet Par',
  'SM.ORGSTAFF.MESSAGE.DSTFLAG_WITHOUT_TIMEZONE':
    "Aucun fuseau horaire ne peut pas ouvrir l'heure d'été.",
  'SM.COMMON.LABEL.MEASUREMENT_CONV_EDIT': "Modifier la conversion de l'unité de mesure",
  'SM.ORGSTAFF.BUTTON.BE_COPYPARENT': 'Copier les données du nœud parent',
  'SM.ORGSTAFF.LABEL.ORG_FORBID': 'Désactiver',
  'SM.ORGSTAFF.LABEL.STAFF_ROLE_TYPE': 'Type de rôle',
  'SM.COMMON.LABEL.CURRENCY_MEASUREMENT_ID': 'ID',
  'SM.COMMON.LABEL.OPERLOG_STARTTIME': 'Heure de début',
  'SM.ORGSTAFF.MESSAGE.REGION_VALIDATE_TIMESAME':
    "L'heure de début et l'heure de fin d'un fuseau horaire ne peuvent pas être les mêmes.",
  'SM.COMMON.LABEL.HOLIDAY_SUNDAY': 'Soleil',
  'SM.COMMON.TIPS.ERATE_ERATE_TIPS':
    'Entrez une chaîne numérique positive avec un maximum de 13 places intégrales et 6 décimales et la dernière ne peut pas être 0.',
  'SM.ORGSTAFF.LABEL.REGION_CODE': 'Code Région',
  'SM.ORGSTAFF.TITLE.REGION_ADMINREGION': 'Région administrative',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_NATIONALITY': 'Nationalité',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_VALUE_TYPE': 'Type de valeur',
  'SM.COMMON.MESSAGE.OPERATESUCCESS': 'Opération réalisée avec succès.',
  'SM.ORGSTAFF.TITLE.SUCCESS': 'Informations',
  'SM.COMMON.LABEL.MEASUREMENT_UNIT_RATIO': "Rapport d'unité de mesure",
  'SM.COMMON.TITLE.SURVEY_QUESTION_NAME_INFO': 'Nom de la question Info',
  'SM.COMMON.BUTTON.RESET': 'Réinitialiser',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ACCOUNT_PASSWORD': 'le mot de passe actuel',
  'SM.COMMON.BUTTON.ADD_ADD': 'Ajouter',
  'SM.COMMON.LABEL.ATTACHMENT_SAVE_SUCCESS': 'Pièce jointe ajoutée avec succès.',
  'SM.ORGSTAFF.TITLE.TEAM_NAMEEXIST': "Le nom de l'équipe existe déjà.",
  'SM.ORGSTAFF.LABEL.TEAM_ACCOUNTRANGE': 'Gamme de compte',
  'SM.COMMON.BUTTON.CHARGECODE_SAVE': 'Enregistrer',
  'SM.COMMON.LABEL.OPERLOG_EMPLOYEE_NAME_NORMAL': "Nom de l'employé",
  'SM.COMMON.LABEL.BANKCARD_BANKCODE': 'Code de la banque émettrice de la carte',
  'SM.COMMON.LABEL.SURVEY_INTERLOCUTION': 'Questions et réponses',
  'SM.COMMON.BUTTON.MEASUREMENT_DELETE': 'Supprimer',
  'SM.ORGSTAFF.LABEL.ORG_IMPORT': 'Importer',
  'SM.COMMON.MESSAGE.CHARGECODE_TO_RELEASE':
    "Êtes-vous sûr de vouloir le changer à l'état Release ?",
  'SM.COMMON.LABEL.CHARGECODE_FORBIDEN_BE': 'Interdit BE',
  'SM.COMMON.MESSAGE.OPERATE_FAIL': "L'opération a échoué.",
  'SM.COMMON.MESSAGE.CURRENCY_MEASUREMNET_CONFIRMCOVERMSG':
    'Le type de devise actuel a déjà une unité monétaire standard ou une unité monétaire minimale. Êtes-vous sûr de vouloir continuer ?',
  'SM.ORGSTAFF.TITLE.RELATION_CONFIRM_RECOVER':
    'Après avoir été repris, la relation organisationnelle et sa relation subordonnée sera disponible,Êtes-vous confirmer de reprendre?',
  'SM.ORGSTAFF.LABEL.ACCESS.USER_REQUIRED': 'Veuillez sélectionner un employé.',
  'SM.ORGSTAFF.TITLE.REGION_IPRANGE': 'Segment IP',
  'SM.ORGSTAFF.BUTTON.REMOVE_OUT': 'Retirer la sortie',
  'SM.ORGSTAFF.TITLE.ADDR_DESCRIPTION': 'Descriptif',
  'SM.ORGSTAFF.TIPS.LOGINID': 'Ne saisissez pas de caractères spéciaux sauf @.',
  'SM.COMMON.LABEL.SURVEY_CLASS_SORT_DESCRIPTION': "La plage d'entrée est de 1-100",
  'SM.ORGSTAFF.TITLE.COMMON_QUERYCONDITION_TITLE': 'Critères de recherche',
  'SM.ORGSTAFF.LABEL.ORG_SELECTREGION': 'Sélectionner une région',
  'SM.ORGSTAFF.MESSAGE.ERROR.SAVE': 'Erreur',
  'SM.ORGSTAFF.MESSAGE.BE_SELECTREGION': 'Sélectionner une région',
  'SM.ORGSTAFF.MESSAGE.BE_MAKE_SURE_DELETE': 'Êtes-vous sûr de vouloir supprimer le membre ?',
  'SM.ORGSTAFF.MESSAGE.ADDR_DELETE_CONFIRM': 'Êtes-vous sûr de vouloir supprimer ?',
  'SM.ORGSTAFF.MESSAGE.REGION_VALIDATE_IPEXIST': "L'adresse IP est en double.",
  'SM.COMMON.TITLE.SUCCESS': 'Informations',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_IS_LOCKED': "L'employé a été verrouillé.",
  'SM.ORGSTAFF.TITLE.ASSIGNE_EMPLOYEE': 'Affecter un employé',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PWDRULEGROUP': 'Groupe de règles de compte et de mot de passe',
  'SM.COMMON.BUTTON.MEASUREMENT_EXPORT': 'Exporter',
  'SM.ORGSTAFF.BUTTON.EMPLOYEE_SELECTORG': 'Sélectionner OU',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_ATTACHMENTDESC': 'Descriptif',
  'SM.ORGSTAFF.TITLE.IMPORT_MAC_TITLE': "Restriction de connexion à l'importation",
  'SM.ORGSTAFF.OPTION.WEEKLIST_FTH': 'Quatrième',
  'SM.COMMON.MESSAGE.SAVESUCCESS': 'Opération réalisée avec succès.',
  'SM.ORGSTAFF.LABEL.TEAM_EMPLOYEE': 'Employé',
  'SM.ORGSTAFF.BUTTON.FORBID_FOREVER': 'Désactiver définitivement',
  'SM.COMMON.LABEL.CURRENCY_GROUPSIZE': 'Taille du groupe',
  'SM.COMMON.LABEL.OPERLOG_OBJECT_ID': "ID de l'objet",
  'SM.COMMON.LABEL.OPERLOG_RESULT': "Résultat de l'opération",
  'SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE_TEMP': 'Questionnaire',
  'SM.COMMON.MESSAGE.OPERATEFAIL': "L'opération a échoué.",
  'SM.ORGSTAFF.MESSAGE.BE_SUBBE':
    'Des EI, des OU, des équipes ou des rôles de niveau inférieur ont été configurés pour le BE.',
  'SM.ORGSTAFF.MESSAGE.STAFF_INFOAUTH_CONFIRM':
    "Veuillez confirmer que les renseignements sur l'employé saisis ont été autorisés par moi.",
  'SM.COMMON.BUTTON.SURVEY_DELETE_QUESTION': 'supprimer la question',
  'SM.ORGSTAFF.TITLE.RELATION_CONFIRM_SUSPEND':
    'Après avoir été suspendu, la relation organisationnelle et sa relation subordonnée ne sera pas disponible, Êtes-vous confirmer de suspendre?',
  'SM.ORGSTAFF.LABEL.STAFF_LOCALE': 'Langue par défaut',
  'SM.COMMON.LABEL.SURVEY_QUESTIONNAIRE_STATUS': 'Statut',
  'SM.ORGSTAFF.MESSAGE.SRC_ACCOUNT': "Le compte source n'existe pas.",
  'SM.ORGSTAFF.MESSAGE.CONTACT_ACCOUNT': 'Compte',
  'SM.COMMON.TIPS.SPECIAL_CHAR_AVAILABLE':
    "La valeur d'entrée doit être un chiffre, un alpha ou un _.",
  'SM.COMMON.LABEL.ACCESS_RELATION': 'Organisations accessibles',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_CREATE_FAILD':
    "Impossible de créer l'employé. Veuillez confirmer les informations du compte.",
  'SM.ORGSTAFF.OPTION.WEEKDAY_TUE': 'Mardi',
  'SM.ORGSTAFF.BUTTON.CANCEL': 'Annuler',
  'SM.COMMON.BUTTON.BANKCARD_SAVE': 'Enregistrer',
  'SM.COMMON.TITLE.ERROR': 'Erreur',
  'SM.ORGSTAFF.LABEL.REGION_DST_STARTDATE': "Date de début de l'heure d'été",
  'SM.COMMON.LABEL.AREANAME': 'Nom de la zone',
  'SM.ORGSTAFF.LABEL.TEAM_TEAMCODE': "Code d'équipe",
  'SM.COMMON.LABEL.MEASUREMENT_ENTITY_NAME': "Nom de l'unité",
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ROAM_ORG': "Organisation d'itinérance",
  'SM.COMMON.LABEL.BANKCARD_BANKCODE_PARAM': 'Code de la banque émettrice de la carte',
  'SM.ORGSTAFF.TITLE.CONTACT_MOBILE_PHONE': 'Numéro de téléphone portable',
  'SM.ORGSTAFF.LABEL.ORG_CHANNEL_CLASS': 'Classification des canaux',
  'SM.COMMON.BUTTON.TOP': 'Vers le haut',
  'SM.AUC.LABEL.BANK_LIST': 'Liste des banques',
  'SM.COMMON.TITLE.MEASUREMENT_STDLIST': 'Liste des systèmes de mesure',
  'SM.COMMON.LABEL.ERATE_RANGEDATE': 'Période de validité',
  'SM.COMMON.LABEL.BANKCARD_OPERATION': 'Opération',
  'SM.ORGSTAFF.TITLE.BE_INFORMATION': 'Informations',
  'SM.ORGSTAFF.OPTION.MONTH_12': 'Décembre',
  'SM.ORGSTAFF.OPTION.MONTH_11': 'Novembre',
  'SM.ORGSTAFF.MESSAGE.TIMEZONE_TIME_ERROR':
    "L'heure de départ ne peut pas être supérieure ou égale à l'heure de fin.",
  'SM.ORGSTAFF.LABEL.REGION_LAYER': 'Couche Région',
  'SM.ORGSTAFF.OPTION.MONTH_10': 'Octobre',
  'SM.ORGSTAFF.OPTION.WEEKDAY_SAT': 'Samedi',
  'SM.ORGSTAFF.LABEL.REGION_NAME': 'Nom de la région',
  'SM.COMMON.MESSAGE.SYSMENU_CONFIRM_MODIFY_MENU_STATUS_TO': 'Êtes-vous sûr de vouloir :',
  'SM.COMMON.LABEL.BANKCARD_BANKSIGN_LENG_PARAM':
    "Longueur de l'identifiant bancaire émetteur de la carte",
  'SM.COMMON.BUTTON.CHARGECODE_EDIT': 'Modifier',
  'SM.ORGSTAFF.LABEL.BE_ADDRESS_DISPLAYFORMAT': "Modèle de format d'affichage d'adresse",
  'SM.COMMON.LABEL.HOLIDAY': 'vacances',
  'SM.ORGSTAFF.LABEL.ORG_CODE': 'Code OU',
  'SM.ORGSTAFF.LABEL.USER_COUNT': "Nombre d'utilisateurs",
  'SM.COMMON.MESSAGE.MEASUREMENT_STANDARD_ERROR': 'Veuillez choisir la mesure standard correcte.',
  'SM.ORGSTAFF.TITLE.ADDR_LINE_NUMBER': 'Numéro de ligne',
  'SM.COMMON.TIPS.DIGITS_ONLY': 'Veuillez saisir les chiffres.',
  'SM.COMMON.LABEL.BANKCARD_BANKNAME': 'Nom de la banque émettrice de la carte',
  'SM.ORGSTAFF.MESSAGE.ADDR_DELETE_ERROR': 'Erreur de suppression.',
  'SM.ORGSTAFF.TITLE.REGION_TIMEZONE': 'Fuseau horaire',
  'SM.COMMON.LABEL.OPERLOG_FAILUER': 'Échec',
  'SM.COMMON.LABEL.HIGHRISKLOG': 'Surveillance des opérations à haut risque',
  'SM.COMMON.LABEL.CURRENCY_MEASUREMENT_NAME': 'Unité',
  'SM.ORGSTAFF.TITLE.CONTACT': 'Contactez-nous',
  'SM.ORGSTAFF.LABEL.BE_CODE': 'Code BE',
  'SM.COMMON.LABEL.SURVEY_SINGAL_CHOOSE': 'Choix unique',
  'SM.ORGSTAFF.LABEL.BE_SHORT_NAME': 'BE Nom abrégé',
  'SM.ORGSTAFF.TITLE.CONTACT_ID': 'ID de contact',
  'SM.ORGSTAFF.LABEL.TEAM_CODE': 'Code OU',
  'SM.COMMON.MESSAGE.MODULE_MODULEADDRESSNOTMATCH': "L'adresse du module ne correspond pas.",
  'SM.COMMON.LABEL.CHARGECODE_RELEASE': 'libération',
  'SM.COMMON.TITLE.SYSMENU_MENUNAME': 'Nom du menu Infos',
  'SM.COMMON.LABEL.SYSMENU_SELECTORGANIZATION': "Sélectionner l'autorisation",
  'SM.ORGSTAFF.LABEL.TEAM_SEARCH': 'Rechercher',
  'SM.ORGSTAFF.TITLE.CHARGE_CODE': 'Code de charge',
  'SM.ORGSTAFF.LABEL.STAFF_ROLE_EFFECTIVE_OU_INPUT': "Veuillez entrer un nom d'OU",
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ACCOUNT': 'Compte',
  'SM.PROJECT.TITLE.EXPORT_CURRENT_ORG_AND_SUB': "Exporter l'élément sélectionné",
  'SM.COMMON.BUTTON.SURVEY_NEW_QUESTION_TYPE': 'Nouveau type de question',
  'SM.ORGSTAFF.LABEL.BE_LANGUAGE': 'Langue',
  'SM.COMMON.LABEL.HEADING_SEARCHCRITERIA': 'Critères de recherche',
  'SM.COMMON.LABEL.SYSMENU_SEARCH_RESULT': 'Résultat de la recherche',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_TIMERANGEERROR':
    "L'heure de fin de connexion doit être plus tard, puis l'attache d'adresse de Time.MAC de début de connexion",
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_RESET_FAILD':
    "La réinitialisation du mot de passe de l'employé a échoué",
  'SM.ORGSTAFF.TITLE.STAFF_INFO_ADD': 'Ajouter un employé',
  'SM.ORGSTAFF.OPTION.EMPLOYEE_DELETE_ROLE': 'Supprimer le rôle',
  'SM.ORGSTAFF.TITLE.ADDRESS_FORMAT': "Format d'adresse",
  'SM.ORGSTAFF.LABEL.FUNCAUTH_OPPAUTHNAME': 'Autorisation Mutuelle-Exclusive',
  'SM.COMMON.LABEL.CURRENCY_DECIMALPOSITIONS': 'Places décimales',
  'SM.ORGSTAFF.BUTTON.INCREASE': 'Ajouter',
  'SM.ORGSTAFF.LABEL.TEAM_PARENTORG': 'Parent OU',
  'SM.ORGSTAFF.TITLE.NEW_ORG': "Création d'une organisation",
  'SM.ORGSTAFF.TITLE.EDIT_ORG': "Modifier l'organisation",
  'SM.ORGSTAFF.MESSAGE.INPUT_ORG_NAME': "Veuillez saisir le nom de l'organisation",
  'SM.ORGSTAFF.TITLE.NEW_ACCESS_USER': "Ajouter un employé d'accès",
  'SM.ORGSTAFF.TITLE.NEW_MANAGER': 'Ajouter un superviseur',
  'SM.ORGSTAFF.TITLE.USER_ACCOUNT': "Compte de l'employé",
  'SM.ORGSTAFF.MESSAGE.SEARCH_USERNAME': "Rechercher le nom de l'employé",
  'SM.ORGSTAFF.TITLE.SELECT_ROLE': 'Sélectionner un rôle',
  'SM.ORGSTAFF.MESSAGE.FOLLOW_TENANT': 'Suivre la configuration du locataire',
  'SM.ORGSTAFF.MESSAGE.SEARCH_ROLE_NAME': 'Rechercher le nom du rôle',
  'SM.ORGSTAFF.TIP.ACCESS_USER_DESC':
    "Employés qui n'appartiennent pas à l'organisation mais qui sont autorisés à accéder aux ressources de l'organisation et à les utiliser",
  'SM.COMMON.BUTTON.CHARGECODE_SUCCESS': 'Informations',
  'SM.ORGSTAFF.LABEL.TEAM_MANAGER': 'Gestionnaire',
  'SM.COMMON.LABEL.MEASUREMENT_EXPORT_COUNT': "Quantité d'exportation",
  'SM.ORGSTAFF.LABEL.EMPLOYEE_BIRTH': 'Date de naissance',
  'SM.COMMON.LABEL.CREATE_TIME': 'Créé à',
  'SM.ORGSTAFF.TITLE.COMMON_CONTACT_INFORMATION': 'Informations de contact',
  'SM.ORGSTAFF.MESSAGE.STAFF_AUTHCOPY_PATTERN': 'Mode de copie',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_IP': 'Adresse IP',
  'SM.COMMON.LABEL.MEASUREMENT_NO': 'Non',
  'SM.COMMON.TITLE.BANKCARD_ADD': 'Nouvelle carte bancaire',
  'SM.ORGSTAFF.MESSAGE.STAFF_RESUME_CONFIRM':
    "Êtes-vous sûr de vouloir reprendre le compte de l'employé?",
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ONLINE_NAME': 'Employé',
  'SM.ORGSTAFF.MESSAGE.SELECT_REGION': 'Sélectionnez une région.',
  'SM.ORGSTAFF.LABEL.DEPTIPRESTRICT': 'Restriction IP',
  'SM.ORGSTAFF.LABEL.INPUT_REGION_NAME': 'Veuillez saisir le nom de la région',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_MIDDLENAME': 'Deuxième nom',
  'SM.ORGSTAFF.TITLE.TEAM_OPERSUCCESS': 'Opération réalisée avec succès.',
  'SM.COMMON.BUTTON.IMPORT': 'Importer',
  'SM.ORGSTAFF.LABEL.TEAM_TEAMNAME': "Nom de l'équipe",
  'SM.ORGSTAFF.MESSAGE.BE_COPYCURRENCY': 'Êtes-vous sûr de vouloir copier le nœud parent ?',
  'SM.ORGSTAFF.TITLE.REGION_ADDRESS': 'Adresse',
  'SM.ORGSTAFF.LABEL.STAFFAUTH_AUTHSOURCE': "Source d'autorisation",
  'SM.ORGSTAFF.LABEL.BE_DESCRIPTION': 'Descriptif',
  'SM.ORGSTAFF.LABEL.ORG_DELETE_CONFIRM':
    "Après la suppression d'une organisation, le compte correspondant de l'organisation ne peut pas être restauré et peut seulement être recréé. supprimer ou pas?",
  'SM.ORGSTAFF.LABEL.EMPLOYEE_NAME_EXIST':
    "Impossible de créer l'employé parce que le compte existe déjà dans le système.",
  'SM.COMMON.LABEL.BANKCARD_BANKSIGN': 'ID de la banque émettrice de la carte',
  'SM.COMMON.BUTTON.SURVEY_DELETE_QUESTION_TYPE': 'Supprimer le type de question',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_RESET': 'Réinitialiser',
  'SM.COMMON.LABEL.NEW_CURRENCY_EXCHANGE_RATE': 'Nouveau taux de change',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_TO': 'À',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_SAVE': 'Enregistrer',
  'SM.COMMON.LABEL.OPERLOG_SUCCESS': 'Succès',
  'SM.COMMON.MESSAGE.DATADICT_DELETE_SUCCESSFULLY': 'Supprimé avec succès.',
  'SM.ORGSTAFF.LABEL.ORG_FORBID_TIPS': "Êtes-vous sûr de vouloir désactiver l'organisation ?",
  'SM.COMMON.TIPS.AREA_DELETE_SUCCESS': 'Supprimé avec succès.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_FIRSTNAME': 'Prénom',
  'SM.ORGSTAFF.TITLE.ERROR': 'Erreur',
  'SM.ORGSTAFF.TITLE.REGION_ENDIP': 'IP de fin',
  'SM.ORGSTAFF.LABEL.ORG_NAME': "Nom de l'OU",
  'SM.ORGSTAFF.LABEL.ORG_IMPORTDATA': 'Importer des données',
  'SM.COMMON.LABEL.SURVEY_DRAFT': 'Ébauche',
  'SM.ORGSTAFF.MESSAGE.REGION_CONFIRM_NOTUSEPARENT':
    'Si les données du nœud parent sont annulées, la configuration du nœud parent ne sera pas utilisée. Continuer ?',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ORG': "Organisation d'accueil",
  'SM.ORGSTAFF.LABEL.BANK_CODE': 'Code Banque',
  'SM.COMMON.BUTTON.ADD': 'Nouveau',
  'SM.ORGSTAFF.TITLE.CONTACT_OFFICE_PHONE': 'Téléphone de bureau',
  'SM.COMMON.LABEL.THE_MENU': ']',
  'SM.ORGSTAFF.TIPS.UNLOCK.USER': "Entrez le mot de passe de l'opérateur actuel pour confirmation:",
  'SM.COMMON.MESSAGE.CURRENCY_DECIMALNOZEROFLAG': 'Supprimer les derniers zéros',
  'SM.COMMON.LABEL.ERATE_EFFDATE': 'Temps effectif',
  'SM.COMMON.LABEL.HOLIDAY_IMPORTDATA': 'Importer des données',
  'SM.ORGSTAFF.LABEL.ONLINEOPERATORNUM': 'Total des opérateurs en ligne',
  'SM.ORGSTAFF.LABEL.ORG_PARENTORG': 'Parent OU',
  'SM.COMMON.LABEL.BANKCARD_NAME_PARAM': 'Nom de la carte',
  'SM.COMMON.LABEL.CHARGECODE_DRAFT': 'Ébauche',
  'SM.COMMON.LABEL.OPERLOG_OPER_TYPE': "Type d'opération",
  'SM.COMMON.TITLE.MEASUREMENT_INFO': 'Informations de base',
  'SM.ORGSTAFF.TITLE.REGION_STARTIP': "Démarrer l'IP",
  'SM.ORGSTAFF.LABEL.IMPORT_TEMPLATE': 'Importer un modèle de fichier',
  'SM.COMMON.LABEL.SURVEY_TITLE_NAME': 'Nom de la question',
  'SM.COMMON.LABEL.HOLIDAY_IMPORT': "Vacances d'importation",
  'SM.COMMON.LABEL.HOLIDAY_MONTH_9': 'Septembre',
  'SM.TENANTSPACE.LDAP_BASICDN': 'DN de base',
  'SM.TENANTSPACE.LDAP_TITLE': 'Authentification LDAP',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_8': 'Août',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_7': 'Juillet',
  'SM.ORGSTAFF.LABEL.BE_NAME': 'Nom BE',
  'SM.COMMON.LABEL.CHARGECODE_SEARCH_CRITERIA': 'Critères de recherche',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_6': 'Juin',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_5': 'mai',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_4': 'Avril',
  'SM.ORGSTAFF.OPTION.WEEKLIST_SEC': 'Deuxième',
  'SM.ORGSTAFF.LABEL.WORKGROUP_NAME': 'Nom du groupe de travail',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_3': 'Mars',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_2': 'Février',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_1': 'Janvier',
  'SM.ORGSTAFF.LABEL.ORG_STOP': 'Suspendre',
  'SM.COMMON.TIPS.CURRENCY_GROUPSIZE_TIPS':
    "Nombre de chiffres séparés par des séparateurs dans une quantité. La valeur 0 indique que la quantité n'est pas séparée par des séparateurs.",
  'SM.ORGSTAFF.LABEL.EMPLOYEE_STATUS_NORMAL': 'Normal',
  'SM.COMMON.LABEL.BANKCARD_BANKNAME_PARAM': 'Nom de la banque émettrice de la carte',
  'SM.ORGSTAFF.TITLE.REGION_EX_ATTR': 'Attributs étendus',
  'SM.COMMON.TITLE.SUCCESS_SUCCESS': 'Opération réalisée avec succès.',
  'SM.COMMON.LABEL.MEASUREMENT_CONV_CREATE': 'Nouvelle conversion de mesure',
  'SM.ORGSTAFF.LABEL.TEAM_TEAMID': "ID d'équipe",
  'SM.ORGSTAFF.BUTTON.ORG_EDIT': 'Modifier',
  'SM.ORGSTAFF.TITLE.TEAM_ASSIGNEDEMPL':
    'Employés affectés (ne peut pas lier plus de 2000 employés.)',
  'SM.ORGSTAFF.TITLE.STAFF_INFO_EXT': 'Informations étendues',
  'SM.ORGSTAFF.TITLE.SELECT_ORG': 'Sélectionner un groupe de travail',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_BIRTHPLACE': 'Lieu de naissance',
  'SM.ORGSTAFF.MESSAGE.REFRESH_SUCCESS': 'Rafraîchi avec succès.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ACCOUNT_VALIDATOR':
    "La valeur ne peut contenir que des chiffres, des lettres, des caractères chinois, des (-) de traits d'union, des points (.), et des signes à (@).",
  'SM.ORGSTAFF.TITLE.EMPLOYEE_IP_ADDR': 'Adresse IP',
  'SM.ORGSTAFF.TITLE.TEAM_DELETEFSUCCESS': 'Supprimé avec succès.',
  'SM.ORGSTAFF.LABEL.ORG_INFO': 'Info OU',
  'SM.COMMON.TIPS.CHARGECODE_NAME':
    "La valeur d'entrée ne peut pas contenir de caractères spéciaux.",
  'SM.COMMON.MESSAGE.SYSMENU_AUTH_ASSIGNED_MUTIL_MENUS':
    "L'autorisation sur le menu a été attribuée à plusieurs menus. Si vous supprimez le menu, toutes les autorisations seront révoquées. Continuer ?",
  'SM.COMMON.LABEL.MEASUREMENT_IGNORE_IMPORT':
    "Lors de l'importation de fichiers par lots, si un fichier ne parvient pas à être importé, l'importation des fichiers suivants se poursuivra.",
  'SM.COMMON.TIPS.POPUP_LABEL_DELETE': 'Êtes-vous sûr de vouloir supprimer ?',
  'SM.ORGSTAFF.AUTHTYPE.DEFAULT': 'default',
  'SM.ORGSTAFF.LABEL.BE_BIZCODE': "Code d'entreprise BE",
  'SM.COMMON.LABEL.SYSMENU_DIS_POSTION': "Position d'affichage",
  'SM.ORGSTAFF.LABEL.EMPLOYEE_STATUS_FORBID': 'Interdire',
  'SM.ORGSTAFF.LABEL.CREATESOURCE': 'Créer une source',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_FORBIDFOREVER': 'Désactiver définitivement',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ONLINE_CLIENT': 'Ip client',
  'SM.ORGSTAFF.TIPS.STAFF_LOCALE':
    "Langue de connexion par défaut de l'employé utilisée lorsque la langue n'est pas sélectionnée lors de la connexion.",
  'SM.COMMON.TITLE.MEASUREMENT_UNIT': 'Type de mesure',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_UPLOAD_SIZE_EXT_TITLE':
    "La taille d'un fichier à télécharger peut aller de {1} Ko à 0 Ko. Les fichiers {2} sont autorisés, mais pas les fichiers {3}.",
  'SM.SM.COMMON.TITLE.NO_CHARGECODE': 'Pas de ChargeCode.',
  'SM.COMMON.BUTTON.ATTACHMENT_MGR_SEARCH_CRITERIA_QUERY': 'Rechercher',
  'SM.ORGSTAFF.OPTION.WEEKLIST_FIV': 'Cinquième',
  'SM.ORGSTAFF.LABEL.ATTR_ATTRSETTING': 'Attributs étendus',
  'SM.COMMON.LABEL.HIGHRISKLOG_SERVICENAME': 'Nom du service',
  'SM.ORGSTAFF.MESSAGE.AUTHENTICATION': "Vérifier l'identité",
  'SM.COMMON.MESSAGE.DATADICT_SURE_DELETE': 'Êtes-vous sûr de vouloir supprimer ?',
  'SM.ORGSTAFF.OPTION.WEEKLIST_FIR': "D'abord",
  'SM.COMMON.BUTTON.COMMON_ADD': 'Ajouter',
  'SM.COMMON.LABEL.SYS_MEASUREMENT_METRIC': 'Drapeau du système métrique',
  'SM.ORGSTAFF.TIPS.BE_LOGO':
    'Sélectionnez une image PNG, GIF ou JPG dont la taille ne dépasse pas 50 Ko et 145*50 pixels.',
  'SM.COMMON.BUTTON.CHARGECODE_ADDMEMBER': 'Ajouter le code de charge',
  'SM.COMMON.LABEL.SURVEY_QUESTIONNAIRE_TAIL': 'Questionnaire Tail',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_MATCH_VALUE': 'Valeur de correspondance',
  'SM.ORGSTAFF.LABEL.ORG_SUBTYPE': 'Type de sous-OU',
  'SM.ORGSTAFF.MESSAGE.SELECT_ORG': 'Sélectionnez une OU.',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_SEARCH_CRITERIA': 'Critères de recherche',
  'SM.COMMON.LABEL.SURVEY_ALLOW_INPUT': 'Autoriser la saisie manuelle',
  'SM.ORGSTAFF.LABEL.BE_ID': 'ID BE',
  'SM.ORGSTAFF.LABEL.ROLE_TYPE_DUTY': 'Rôle de devoir',
  'SM.COMMON.LABEL.SURVEY_MODIFIED_ACCOUNT': 'Modifié par',
  'SM.ORGSTAFF.LABEL.TEAM_TDISPLAYORDER': "Séquence d'affichage",
  'SM.COMMON.LABEL.MENU_ACCESS_TIME': "Temps d'accès au menu",
  'SM.COMMON.LABEL.ERATE_INFO_LIST': 'Liste des taux de change',
  'SM.COMMON.LABEL.SYSMENU_LANGUAGE': 'Langue',
  'SM.ORGSTAFF.BUTTON.IMPORT': 'Importer',
  'SM.COMMON.LABEL.MODULE_ID': 'ID',
  'SM.COMMON.LABEL.OPERLOG_OPER_TIME': "Temps d'opération",
  'SM.COMMOM.MESSAGE.BESEXCEPTION':
    "L'opération en cours ne peut pas être terminée. Contactez l'administrateur.",
  'SM.COMMON.LABEL.SURVEY_PROVINCE': 'Provinciale',
  'SM.ORGSTAFF.LABEL.STAFF_ACCOUNT': 'Compte',
  'SM.ORGSTAFF.BUTTON.RESET_TEAM': 'Réinitialiser',
  'SM.ORGSTAFF.MESSAGE.USER_OWN_DUTY_ROLE_INVALID2':
    'qui est plus grand que le nombre max, le nombre max est:',
  'SM.ORGSTAFF.MESSAGE.USER_OWN_DUTY_ROLE_INVALID1':
    "Le nombre d'employés propres rôle de l'organisation:",
  'SM.COMMON.LABEL.ATTACHMENT_MGR_SEARCH_CRITERIA_ATTACHMENTCODE': 'Type de pièce jointe',
  'SM.ORGSTAFF.LABEL.ORG_FORCEDELETE': 'Supprimer définitivement',
  'SM.ORGSTAFF.TITLE.CURRENCY_TYPE': 'Type de devise',
  'SM.COMMON.LABEL.HIGHRISKLOGList': 'Surveillance des opérations à haut risque',
  'SM.COMMON.LABEL.SURVEY_TITLE_SORT': 'Séquence des questions',
  'SM.ORGSTAFF.LABEL.BANK_NAME': 'Nom de la banque',
  'SM.COMMON.LABEL.CURRENCY_CREATE_TITLE': 'Type de devise Informations de base',
  'SM.ORGSTAFF.TIPS.REGION_DST_SWITCHINTERVAL_TIPS':
    'Unité : deuxième. Portée: 1 à 86400. Si ce paramètre est laissé vide, la valeur par défaut 3600 est utilisée.',
  'SM.COMMON.LABEL.SURVEY_MUTI_CHOOSE': 'Choix multiples',
  'SM.ORGSTAFF.OPTION.WEEKLIST_LST': 'Dernier',
  'SM.ORGSTAFF.TITLE.CURRENCY_EXCHANGE_RATE': 'Taux de change',
  'SM.COMMON.LABEL.BANKCARD_BANKSIGN_LENG':
    "Longueur de l'identifiant bancaire émetteur de la carte",
  'SM.COMMON.LABEL.CHARGE_CODE_GROUP': 'Groupe de code de charge',
  'SM.COMMON.LABEL.CHARGECODE_GROUPTYPE': 'Type de groupe',
  'SM.COMMON.BUTTON.PROPERTY_CREATE_GROUP': "Nouveaux groupes d'attributs étendus",
  'SM.COMMON.MESSAGE.DATADICT_SAVE_SUCCESSFULLY': 'Opération réalisée avec succès.',
  'SM.ORGSTAFF.TITLE.ADDR_CFG_LIST': "Format d'affichage de l'adresse",
  'SM.ORGSTAFF.OPTION.WEEKDAY_THU': 'Jeudi',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ONLINE_SIGNTIME': 'Heure de connexion',
  'SM.COMMON.LABEL.HOLIDAY_MONDAY': 'Mon',
  'SM.COMMON.LABEL.OPERLOG_MODULE': 'Module',
  'SM.COMMON.LABEL.SURVEY_OPTION_TYPE': "Type d'option",
  'SM.ORGSTAFF.LABEL.ADDR_ADDRESS_NAME': "Nom de l'adresse",
  'SM.ORGSTAFF.TITLE.EMPLOYEE_RESTRICT_MODE': 'Mode Restriction',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_TEAM': 'Équipe de travail',
  'SM.ORGSTAFF.TITLE.CONTACT_INFO': 'Gestionnaire',
  'SM.COMMON.LABEL.OPERLOG_OBJECT_TYP_NAME': "Nom du type d'objet",
  'SM.COMMON.LABEL.SYSMENU_SELECT_MENUS': 'Menu Sélectionné',
  'SM.COMMON.BUTTON.SURVEY_NEW_QUESTION': 'Nouvelle question',
  'SM.COMMON.LABEL.MENU_ACCESS_END_TIME': 'Heure de fin',
  'SM.ORGSTAFF.LABEL.AFFILIATED_BE': 'Affilié BE',
  'SM.COMMON.LABEL.AREAINFO_MAINTAIN': "Gestion de l'indicatif régional",
  'SM.ORGSTAFF.LABEL.REGION_LEVEL': 'Niveau Régional',
  'SM.ORGSTAFF.MESSAGE.REGION_VALIDATE_IPFORMATERROR': 'Adresse IP non valide',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_GENDER': 'Sexe',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_RESET_ALLSUCCESS':
    "réussite de la réinitialisation du mot de passe de l'employé",
  'SM.ORGSTAFF.TIPS.ENTER_ORGNAME': 'Entrez le nom du groupe de travail.',
  'SM.COMMON.LABEL.SYSMENU_STATUSDATE': 'Date du statut',
  'SM.COMMON.LABEL.CURRENCY_MEASUREMENT_RATE': 'Taux',
  'SM.ORGSTAFF.TITLE.CONFIRM_DEL': 'Confirmer',
  'SM.COMMON.LABEL.COLON': ':',
  'SM.COMMON.BUTTON.ACCESS_RELATION': "Ajouter une organisation d'accès",
  'SM.ORGSTAFF.LABEL.EMPLOYEE_MARITALSTATUS': 'État matrimonial',
  'SM.COMMON.BUTTON.PROPERTY_CREATE': 'Nouveau',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_STATUS_PAUSE': 'Déactif',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_SECONDMENT_STARTDATE': 'Date de début',
  'SM.COMMON.BUTTON.ATTACHMENT_ADDACCESSORY': 'Enregistrer',
  'SM.COMMON.LABEL.MEASUREMENT_SOUMEASYS': 'Système de mesure de source',
  'SM.ORGSTAFF.MESSAGE.DELETE_TITLE': 'Confirmer',
  'SM.COMMON.LABEL.ERATE_EXPDATE': "Temps d'expiration",
  'SM.COMMON.TITLE.PRJ_EXPORT': 'Ont commencé à exporter',
  'SM.ORGSTAFF.LABEL.STAFF_AUTHCOPY_TARGETNO': 'Compte cible',
  'SM.ORGSTAFF.TITLE.ERROR_TITLE': 'Erreur',
  'SM.COMMON.MESSAGE.ERATE_TIME_COMPARE':
    "L'heure de fin doit être postérieure à l'heure de début.",
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_RESET': "réinitialisation du mot de passe de l'employé",
  'SM.ORGSTAFF.LABEL.ADDR_DISP_DETAIL_LIST': 'Format Détails Liste',
  'SM.ORGSTAFF.MESSAGE.TEAM_BLANK_VIEW': "Gérer les équipes dans l'arborescence de l'OU.",
  'SM.COMMON.LABEL.BANKCARD_CREATETIME': 'Ajout de temps',
  'SM.ORGSTAFF.OPTION.EMPLOYEE_ADD_ROLE': 'Ajouter un rôle',
  'SM.ORGSTAFF.LABEL.STAFF_INFO_BASIC': 'Informations de base',
  'SM.ORGSTAFF.OPTION.STAFF_IP_SINGLE': 'Adresse IP unique',
  'SM.COMMON.TITLE.CONFIRM_DEL': 'Confirmer',
  'SM.ORGSTAFF.OPTION.MONTH_9': 'Septembre',
  'SM.ORGSTAFF.OPTION.MONTH_8': 'Août',
  'SM.ORGSTAFF.OPTION.MONTH_7': 'Juillet',
  'SM.COMMON.TITLE.SURVEY_CLASS_NAME_INFO': 'Nom de la classification Info',
  'SM.COMMON.MESSAGE.SYSMENU_AUTHIDREQUIRE': "Saisissez l'ID d'autorisation du menu.",
  'SM.ORGSTAFF.OPTION.MONTH_6': 'Juin',
  'SM.ORGSTAFF.OPTION.MONTH_5': 'mai',
  'SM.ORGSTAFF.OPTION.MONTH_4': 'Avril',
  'SM.ORGSTAFF.TITLE.MEASUREMENT': 'Unité de mesure',
  'SM.ORGSTAFF.MESSAGE.ORG_NOT_EXSIT': "L'OU n'existe pas.",
  'SM.ORGSTAFF.OPTION.MONTH_3': 'Mars',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_EMPLOYEENAME': 'Employé',
  'SM.ORGSTAFF.OPTION.MONTH_2': 'Février',
  'SM.COMMON.LABEL.CURRENCY_TYPE_ID': 'ID',
  'SM.ORGSTAFF.OPTION.MONTH_1': 'Janvier',
  'SM.COMMON.TIPS.BANKCARD_LENGTH': 'Entrez un nombre entier ne contenant pas plus de 2 chiffres.',
  'SM.ORGSTAFF.TITLE.TEAM_SAVESUCCESS': 'Opération réalisée avec succès.',
  'SM.ORGSTAFF.LABEL.BE_LOGO': 'Logo',
  'SM.ORGSTAFF.MESSAGE.MAX_TREE_LEVEL':
    "Vous ne pouvez créer jusqu'à 6 niveaux de sous-organisations.",
  'SM.PROJECT.TITLE.EXPORT_SELECTED_EMPLOYEE': "Exporter l'élément sélectionné",
  'SM.ORGSTAFF.LABEL.TEAM_INCLUDESUB': 'Inclure le sous-OU',
  'SM.ORGSTAFF.LABEL.ORG_LEVEL': 'Niveau OU',
  'SM.ORGSTAFF.TITLE.EMPTY': "L'e-mail et les ContactPhone ne peuvent pas être vides",
  'SM.ORGSTAFF.TITLE.COMMON_STAFF_INFO': 'Liste des comptes des employés',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_SEARCH_CRITERIA_ATTACHMENTNAME': 'Nom de la pièce jointe',
  'SM.COMMON.LABEL.SYSMENU_IDEL': 'libération',
  'SM.COMMON.TITLE.ACCESS.SELECT_ALL_STAFF': "Sélectionner tous les employés de l'organisation",
  'SM.COMMON.LABEL.SYSMENU_MENUID': 'ID du menu',
  'SM.ORGSTAFF.LABEL.ROLETMPL_PERMISSION_NAME': "Nom de l'autorisation de la fonction",
  'SM.COMMON.LABEL.CURRENCY_TYPE_INPUT_TIPS': 'Entrez le nom du type de devise',
  'SM.ORGSTAFF.LABEL.ADDR_ORDER': "Séquence d'affichage",
  'SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE_TEMP_LIST': 'Liste des questionnaires',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_RESTRICTIONTYPE': 'Mode de traitement',
  'SM.COMMON.LABEL.MEASUREMENT_SOUMEAUNIT': 'Unité de mesure de source',
  'SM.ORGSTAFF.MESSAGE.ERROR.SAVEFAILED': "L'enregistrement a échoué",
  'SM.ORGSTAFF.LABEL.TEAM_ACCESS_EMPLOYEE': 'Visitez le personnel',
  'SM.ORGSTAFF.LABEL.NO': 'Non',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_TERMINATE_SECONDMENT': 'Résiliation du détachement',
  'SM.COMMON.LABEL.CHARGECODE_DESC': 'Descriptif',
  'SM.COMMON.LABEL.HOLIDAY_TUESDAY': 'Mon',
  'SM.ORGSTAFF.BUTTON.EMPLOYEE_EXPORTALL': 'Exporter',
  'SM.ORGSTAFF.OPTION.QUERYSUBDEPT': 'Interroger le département de niveau inférieur',
  'SM.COMMON.LABEL.MEASUREMENT_DESTMEASYS': 'Système de mesure de cible',
  'SM.ORGSTAFF.MESSAGE.SELECTEDOU_CANNOT_SELECTED_AGAIN':
    "L'organisation sélectionnée ne peut pas être sélectionnée à nouveau.",
  'SM.ORGSTAFF.MESSAGE.STAFF_DELETE_CONFIRM':
    "Après la suppression d'un employé, le compte correspondant de l'employé ne peut pas être restauré et peut seulement être recréé. supprimer ou pas?",
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ONLINE_BE': 'BE',
  'SM.ORGSTAFF.LABEL.REGION_DST_ENDTIME': 'Heure de fin DST',
  'SM.TENANTSPACE.LDAP_SERVERURL': 'URL du serveur',
  'SM.ORGSTAFF.TITLE.BE_CURRENCYCODE': 'Code de devise',
  'SM.ORGSTAFF.TITLE.ATTR_ATTRSETTING': 'Attribut étendu',
  'SM.COMMON.LABEL.BANKCARD_TYPE': 'Type de carte',
  'SM.COMMON.MESSAGE.DELETE_SUCCESS': 'Supprimé avec succès.',
  'SM.COMMON.TIPS.NEED_TO_NEW_STD_MIN_CURRENCY_UNIT':
    "Nécessité de configurer l'unité standard de la devise et la plus petite unité.",
  'SM.COMMON.LABEL.OPERLOG_OPER_LIST': 'Liste du journal des opérations',
  'SM.ORGSTAFF.LABEL.INH_BY_SUB': "Autoriser l'héritage par sous-OU",
  'SM.COMMON.LABEL.MEASUREMENT_ERROR_DESCRIPTION': "Description de l'erreur",
  'SM.COMMON.LABEL.SYSMENU_SUSPENDED_S': 'Désactiver',
  'SM.COMMON.LABEL.ERATE_DESCRIPTION': 'Descriptif',
  'SM.ORGSTAFF.TITLE.CHARGE_CODE_GROUP': 'Groupe de code de charge',
  'SM.COMMON.TIPS.CURRENCY_DECIMALPOSITIONS_TIPS':
    'La valeur –1 indique que les décimales sont affichées en fonction de la précision du stockage des devises.',
  'SM.COMMON.LABEL.OPERLOG_CONTENT': "Contenu de l'opération",
  'SM.ORGSTAFF.LABEL.TEAM_MANAGERINFO': 'Infos sur le gestionnaire',
  'SM.COMMON.MESSAGE.MODIFY_AREAID_TYPE':
    "L'information existe déjà. Changez l'indicatif régional ou le type.",
  'SM.ORGSTAFF.OPTION.YES': 'Oui',
  'SM.COMMON.LABEL.SYSMENU_ACTIVE': 'Activer',
  'SM.ORGSTAFF.LABEL.ORG_REGION': 'Région',
  'SM.ORGSTAFF.BUTTON.ADD_IN': 'Ajouter dans',
  'SM.COMMON.LABEL.SYS_MEASUREMENT_DEFAULT': 'Mesure par défaut',
  'SM.ORGSTAFF.LABEL.STAFF_PASSWORD': 'Nouveau mot de passe',
  'SM.ORGSTAFF.LABEL.TEAM_ORGANIZATION': "Unité d'organisation",
  'SM.COMMON.MESSAGE.CHARGECODE_TO_RETIRE': "Êtes-vous sûr de vouloir le changer à l'état Annulé ?",
  'SM.ORGSTAFF.LABEL.ACCESS.ORG_REQUIRED': 'Veuillez sélectionner une organisation.',
  'SM.ORGSTAFF.TITLE.REGION_USEPARENT': 'Hériter des données de la région supérieure',
  'SM.ORGSTAFF.TITLE.CONTACT_MIDDLE_NAME': 'Deuxième nom',
  'SM.ORGSTAFF.LABEL.STAFF_PASSWORDAGAIN': 'Confirmer le mot de passe',
  'SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE': 'Le menu',
  'SM.ORGSTAFF.TITLE.ASSIGNE_MANAGER': 'Assigner le gestionnaire',
  'SM.COMMON.LABEL.HIGHRISKLOG_PERATOR': 'Opérateur',
  'SM.COMMON.BUTTON.MEASUREMENT_IMPORT': 'Importer',
  'SM.COMMON.LABEL.ATTACHMENT_ADD_TITLE': 'Nouvelle pièce jointe',
  'SM.ORGSTAFF.MESSAGE.PWD.CHECKMSG': 'Les deux mots de passe sont différents.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_FORCE_DELETE': 'Forcer la suppression',
  'SM.ORGSTAFF.BUTTON.TEAM_CANCEL': 'Annuler',
  'SM.ORGSTAFF.MESSAGE.COMMON_TIPS_STAFF_SELECT': 'Sélectionnez employé.',
  'SM.COMMON.LABEL.SYSMENU_DEACTIVATED_D': 'Rejeter',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_RESUME': 'Reprendre',
  'SM.ORGSTAFF.MESSAGE.STAFF_PWD_RESET_CONFIRM':
    'Êtes-vous sûr de vouloir réinitialiser le mot de passe ?',
  'SM.COMMON.LABEL.HOLIDAY_HOLIDAYDESCRIPTION': 'Descriptif',
  'SM.ORGSTAFF.LABEL.STAFF_ROLE_EFFECTIVE_OU_ADD': 'Sélectionner OU',
  'SM.COMMON.LABEL.SYSMENU_CREATEDATE': 'Date de création',
  'SM.COMMOM.BUTTON.IMPORT': 'Importer',
  'SM.ORGSTAFF.TIPS.ENTER_EMPNAME': "Veuillez entrer le nom de l'employé.",
  'SM.ORGSTAFF.LABEL.ORG_ORGID': 'ID OU',
  'SM.ORGSTAFF.MESSAGE.ADDR_USE_DELETE': "La suppression a échoué car l'adresse a été référencée.",
  'SM.ORGSTAFF.MESSAGE.BLANK_VIEW': "Gérer les départements dans l'arborescence OU.",
  'SM.ORGSTAFF.TITLE.CONTACT_EMPLOYEE_CODE': "Contacter l'employé",
  'SM.COMMON.MESSAGE.PROJECT_CODE': 'Le code du projet est :',
  'SM.COMMON.LABEL.AREAID': 'ID de la zone',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_UPLOADTIME': 'Temps de téléchargement',
  'SM.ORGSTAFF.LABEL.CHANNEL_ID': 'ID de canal',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ROAM_INVALID': 'Invalide',
  'SM.ORGSTAFF.LABEL.INPUT_SEARCH_KEYWORD': 'Veuillez saisir le mot-clé',
  'SM.COMMON.LABEL.MEASUREMENT_EXPORT_FILE_FORMAT': 'Format de fichier exporté',
  'SM.COMMON.LABEL.MEASUREMENT_EXPORT_FAILURE': 'Échec',
  'SM.ORGSTAFF.MESSAGE.ORG_ROLE_DELETE':
    "Si vous supprimez ces données, la relation entre l'utilisateur et le rôle sera automatiquement supprimée. Continuer ?",
  'SM.COMMON.LABEL.SURVEY_DESC': 'Descriptif',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PVT': 'Type de validité du mot de passe',
  'SM.COMMON.LABEL.CHARGECODE_OPERATION': 'Opération',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_ATTACHMENTCODE': 'Type de pièce jointe',
  'SM.COMMON.LABEL.ERATE_SEARCH_CRITERIA': 'Critères de recherche',
  'SM.COMMON.LABEL.MODULE_MODULETYPE': 'Catégorie',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ROAM_CREATEDATE': 'Créer une date',
  'SM.COMMON.LABEL.OPERLOG_OBJECT_NAME': "Nom de l'objet",
  'SM.ORGSTAFF.TITLE.EMPLOYEE_LOGIONRESTRICTION': 'Restriction de connexion',
  'SM.COMMON.LABEL.SYSMENU_PARENT': 'ID du menu parent',
  'SM.COMMON.TIPS.PLZ_SAVE_CURRENCY_MEASUREMENT_UNIT':
    "S'il vous plaît enregistrer l'unité de mesure de devise.",
  'SM.COMMON.LABEL.CURRENCY_FORMAT_TITLE': "Format d'affichage de devise",
  'SM.COMMON.LABEL.SYSMENU_GRANT_MENU_AUTH': 'Gérer les autorisations du menu',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST': 'Liste des pièces jointes',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_MAC_ADDR': 'Adresse MAC',
  'SM.COMMON.LABEL.MEASUREMENT_YES': 'Oui',
  'SM.ORGSTAFF.LABEL.ADDR_REGION_LAYER': 'Niveau de la région administrative',
  'SM.COMMON.TITLE.ACCESS.FILTER': "S'il vous plaît entrer des mots-clés à filtrer",
  'SM.ORGSTAFF.TITLE.TEAM_SUCCESS': 'Informations',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_TITLE': 'Titre',
  'SM.ORGSTAFF.OPTION.WEEKLIST_TID': 'Troisième',
  'SM.ORGSTAFF.LABEL.STAFF_ROLE_EFFECTIVE_OU_DETAIL': 'Détail',
  'SM.ORGSTAFF.OPTION.WEEKDAY_WED': 'Mercredi',
  'SM.ORGSTAFF.LABEL.ORG_RECOVER': 'Reprendre',
  'SM.ORGSTAFF.MESSAGE.DELETE_MSG': 'Êtes-vous sûr de vouloir le supprimer ?',
  'SM.ORGSTAFF.TITLE.BE_CURRENCYNAME': 'Nom de la devise',
  'SM.COMMON.LABEL.CURRENCY_DISPLAY_PREFIX': "Préfixe d'affichage",
  'SM.COMMON.LABEL.ADD_EXCHANGE_RATE': 'Ajouter / Ajuster le taux de change des devises associées.',
  'SM.COMMON.BUTTON.SAVE': 'Enregistrer',
  'SM.ORGSTAFF.LABEL.TEAM_REFRESH': 'Mise à jour',
  'SM.COMMON.TITLE.ERATE_ERROR': 'Erreur',
  'SM.ORGSTAFF.LABEL.STAFF_ROLE_NAME': 'Nom du rôle',
  'SM.ORGSTAFF.LABEL.TEAM_EMPLOYEENAME': 'Employé',
  'SM.COMMON.LABEL.SURVEY_TYPE_NAME': 'Nom de la catégorie',
  'SM.ORGSTAFF.LABEL.REGION_CONFIG_TIMEZONE':
    'Région manquante informations de fuseau horaire, veuillez configurer le fuseau horaire pour la région ou la région parente.',
  'SM.ORGSTAFF.TITLE.IMPORT_EMPLOYEE_TITLE': 'Importer un employé',
  'SM.COMMON.LABEL.HIGHRISKLOG_PARAMS': 'Paramètre',
  'SM.ORGSTAFF.LABEL.TEAM_ACCOUNT': 'Compte',
  'SM.ORGSTAFF.OPTION.STAFF_RESTRICTIONTYPE_DISALLOWED': 'Rejeter',
  'SM.ORGSTAFF.MESSAGE.BE_SELECT_DEFAULT_CURRENCY': 'Sélectionnez la devise par défaut.',
  'SM.COMMON.BUTTON.CHARGECODE_CONFIRM': 'Informations',
  'SM.ORGSTAFF.TITLE.CONTACT_FIRST_NAME': 'Prénom',
  'SM.COMMOM.LABEL.SURVEY_INFO': 'Informations sur le questionnaire',
  'SM.ORGSTAFF.LABEL.BANK_CHILEBEUSED': "Autoriser l'enfant BE à utiliser",
  'SM.COMMON.LABEL.HEADING_BASEINFO': 'Informations de base',
  'SM.ORGSTAFF.LABEL.TEAM_ADD': 'Nouveau',
  'SM.ORGSTAFF.TITLE.TEAM_AVAILABLEEMPL': 'Employés disponibles',
  'SM.ORGSTAFF.LABEL.STAFF_INFO': 'Informations sur les employés',
  'SM.ORGSTAFF.LABEL.STAFF_ROLE_ID': 'ID de rôle',
  'SM.COMMON.LABEL.SYSMENU_VERSION': 'Version',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_LASTNAME': 'Nom de famille',
  'SM.ORGSTAFF.LABEL.TEAM_BUSINESSENTITY': 'Nom BE',
  'SM.COMMON.LABEL.SYSMENU_CURRENCYLANGUAGE': 'Langue actuelle',
  'SM.ORGSTAFF.TITLE.CONTACT_HOME_PHONE': 'Téléphone à la maison',
  'SM.ORGSTAFF.LABEL.CHANNEL_NAME': 'Nom de la chaîne',
  'SM.COMMON.MESSAGE.CHARGECODE_DELETE_GROUP':
    'Êtes-vous sûr de vouloir supprimer le groupe de code de charge?',
  'SM.COMMON.LABEL.AREATYPE': 'Type de zone',
  'SM.COMMON.LABEL.SYSMENU_IDEL_I': 'libération',
  'SM.COMMON.LABEL.MEASUREMENT_ENTITY_TYPE': "Type d'unité",
  'SM.COMMON.MESSAGE.CURRENCY_UNIT_TIPS': "S'il vous plaît entrer le numéro.",
  'SM.ORGSTAFF.LABEL.ORG_IMPORTSUCCESS': 'Données importées avec succès.',
  'SM.COMMON.TIPS.MENU_REBUILD_INFO':
    "L'index du menu recréant les informations a été envoyé à la file d'attente des messages. Veuillez patienter.",
  'SM.ORGSTAFF.LABEL.SELECTED_ROLE': 'Rôle sélectionné',
  'SM.COMMON.LABEL.MEASUREMENT_CONV_RATIO': "Rapport d'unité de mesure",
  'SM.COMMON.LABEL.OPERLOG_SEARCH': 'Rechercher',
  'SM.COMMON.LABEL.SYSMENU_GRANT_AUTH': 'Gérer les autorisations',
  'SM.ORGSTAFF.LABEL.BREGION_NAME': 'Région',
  'SM.ORGSTAFF.TITLE.BE_DEFAULT': 'Devise par défaut',
  'SM.COMMON.TITLE.MEASUREMENT_UNITLIST': 'Liste des unités de mesure',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_BE': 'ID BE',
  'SM.COMMON.LABEL.HIGHRISKLOG_OPERATIONNAME': "Nom de l'opération",
  'SM.COMMON.MESSAGE.DELETE_CURRENCY_STD_MIN_MEASUREMENT':
    "La devise doit utiliser l'unité standard et l'unité minimale, êtes-vous sûr de supprimer?",
  'SM.ORGSTAFF.LABEL.STAFF_ROLE_PATH': 'Chemin',
  'SM.ORGSTAFF.TITLE.MSGBOX_INFO': 'Conseils',
  'SM.ORGSTAFF.OPTION.WEEKDAY_MON': 'Lundi',
  'SM.COMMON.LABEL.CURRENCY_TYPE_FLAG': 'Virtuelle',
  'SM.COMMON.LABEL.OPERLOG_LOGIN_IP': 'IP de connexion',
  'SM.COMMON.LABEL.CHARGECODE_GROUPMEMBER': 'Codes de charge',
  'SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE_NAME_LANG': 'Questionnaire Nom Langue',
  'SM.COMMON.LABEL.CURRENCY_ROUNDMODE': "Mode d'arrondi",
  'SM.ORGSTAFF.LABEL.ACCESS.ADD_SUCCESS': "L'ajout a réussi.",
  'SM.COMMON.LABEL.SYSMENU_MENU_AUTH_ROLE_LIST': 'Liste des rôles assignés',
  'SM.COMMON.TITLE.BANKCARD_CONFIRM': 'Informations',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_12': 'Décembre',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_11': 'Novembre',
  'SM.COMMON.LABEL.CHARGECODE_DATAGIRD_CATALOG': 'Catalogues',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_10': 'Octobre',
  'SM.COMMON.TIPS.ONLYONE_UNDERLINE_FORBIDDEN':
    "La valeur d'entrée ne peut pas contenir de caractères spéciaux.",
  'SM.ORGSTAFF.LABEL.ADDR_PLEASE_INPUT': "S'il vous plaît entrer le nom",
  'SM.COMMOM.BUTTON.EDIT': 'Modifier',
  'SM.ORGSTAFF.MESSAGE.SUCCESS.SAVE': 'Succès',
  'SM.COMMON.LABEL.BANKCARD_BANKTYPE': 'Type de banque',
  'SM.COMMON.LABEL.MEASUREMENT_NOTIFICATION_NAME': 'Nom dans la notification',
  'SM.ORGSTAFF.BUTTON.ADDR_LINE': 'LIGNE',
  'SM.ORGSTAFF.TITLE.TEAM_DELETE_CONFIRM': 'Confirmer',
  'SM.COMMON.LABEL.CHARGECODE_RETIRE': 'Annuler',
  'SM.COMMON.TIPS.SPECIAL_CHAR_CANNOT_START_NUMBER': 'Ne pas permettre de commencer par le nombre.',
  'SM.COMMON.LABEL.CURRENCY_DISPLAY_FORMATE': "Format d'affichage",
  'SM.ORGSTAFF.LABEL.TEAM_CONTACTINFO': 'Informations de contact',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_ATTACHMENTNAME': 'Nom de la pièce jointe',
  'SM.ORGSTAFF.LABEL.ADDR_ADDERSS': 'Adresse',
  'SM.ORGSTAFF.MESSAGE.SURE_TO_DO': 'Continuer ?',
  'SM.ORGSTAFF.LABEL.TEAM_TEAMSHORTNAME': "Nom abrégé de l'équipe",
  'SM.ORGSTAFF.TITLE.BANK_TITLE': 'Banque',
  'SM.COMMON.MESSAGE.CHARGECODE_OPERATION_SUCCESS': 'Opération réalisée avec succès.',
  'SM.ORGSTAFF.MESSAGE.USERRESTRICT_CONFIRM_SAVE':
    "Le segment de temps de connexion ne peut conserver qu'un seul enregistrement, et l'autre segment de temps de connexion est supprimé lorsqu'il est ajouté.",
  'SM.COMMON.LABEL.BANKCARD_BANKSIGN_PARAM': 'ID de la banque émettrice de la carte',
  'SM.COMMON.BUTTON.SURVEY_NEW_ANSWER_OPTIONS': 'Nouvelle option de réponse',
  'SM.ORGSTAFF.TIPS.MAC_FORMAT_ERROR': "Erreur de format d'adresse MAC",
  'SM.COMMON.LABEL.MODULE_BASICINFO': 'Informations de base',
  'SM.ORGSTAFF.LABEL.REGION_DST_MODE': 'Mode DST',
  'SM.COMMON.BUTTON.EDIT': 'Modifier',
  'SM.COMMON.MESSAGE.ERATE_VLUEW_RANGE': 'La valeur va de 0 à {1}.',
  'SM.COMMON.LABEL.PROVINCE': 'Province',
  'SM.ORGSTAFF.LABEL.STAFF_OPRATION': 'Opération',
  'SM.COMMON.LABEL.ATTACHMENT_LIMIT_INFO': 'Aucune restriction',
  'SM.COMMON.LABEL.SYSMENU_DESCRIPTION': 'Description du menu',
  'SM.COMMON.MESSAGE.BANKCARD_DELETE_CONFIRM': 'Êtes-vous sûr de vouloir supprimer ?',
  'SM.COMMON.MESSAGE.CURRENCY_UNIT_EXCHAGE_TIPS': 'Veuillez saisir un entier.',
  'SM.ORGSTAFF.MESSAGE.CHOOSE_BE_FIRST': "Veuillez d'abord choisir BE",
  'SM.COMMON.LABEL.MODULE_NAME': 'Nom et prénom',
  'SM.ORGSTAFF.TITLE.MEASUREMENT_CONV': 'Conversion de mesure',
  'SM.COMMON.LABEL.SURVEY_REMARKS': 'Remarques',
  'SM.ORGSTAFF.OPTION.ROLE_OPTION_MODE': "Mode d'opération",
  'SM.ORGSTAFF.LABEL.OU_HIERARCHY': 'Hiérarchie OU',
  'SM.COMMON.BUTTON.VIEW': 'voir',
  'SM.COMMON.MESSAGE.CHARGECODE_CREATE_DATA':
    'Êtes-vous sûr de vouloir ajouter ces codes de charge?',
  'SM.ORGSTAFF.BUTTON.ADD': 'Nouveau',
  'SM.ORGSTAFF.MESSAGE.STAFF_SUSPEND_CONFIRM':
    "Êtes-vous sûr de vouloir suspendre le compte de l'employé?",
  'SM.ORGSTAFF.LABEL.EMPLOYEE_SVD': "Intervalle de délai d'expiration de session",
  'SM.ORGSTAFF.MESSAGE.BANK_STATUS_ERROR':
    "L'enregistrement a échoué parce que les informations bancaires ont expiré.",
  'SM.COMMON.LABEL.HOLIDAY_BASICINFORMATION': 'Informations de base',
  'SM.ORGSTAFF.LABEL.ORG_ADDITIONINFO': 'Informations supplémentaires',
  'SM.ORGSTAFF.TITLE.BE_ADDRESS_CFG': "Format d'adresse",
  'SM.ORGSTAFF.TITLE.STAFF_INFO_MDY': "Modifier l'employé",
  'SM.COMMON.TITLE.ACCESS.SELECT_ORG': "Sélectionnez l'organisation d'accès",
  'SM.ORGSTAFF.MESSAGE.STAFF_FORBID_CONFIRM':
    "Après la désactivation permanente d'un employé, le compte de l'employé ne peut être créé à plusieurs reprises qu'après avoir été supprimé. Êtes-vous sûr de vouloir désactiver l'employé de façon permanente ?",
  'SM.ORGSTAFF.MESSAGE.ERROR.SAVEACCESSLIMIT':
    "Les employés accessibles peuvent ajouter jusqu'à 100 par lot",
  'SM.COMMON.LABEL.OU_HIERARCHY_NAME': 'Nom du RelationShip OU',
  'SM.COMMON.LABEL.BANKCARD_LIST': 'Liste des cartes bancaires',
  'SM.COMMON.MESSAGE.MEASUREMENT_SAVE_CONFIRM':
    'Lorsque vous enregistrez une unité de mesure standard, elle remplace une unité standard existante et modifie les unités standard des autres unités de mesure.',
  'SM.COMMON.LABEL.SYSMENU_SEARCH_MENU': 'Critères de recherche',
  'SM.ORGSTAFF.TITLE.ADDR_TEMPATE_ID': 'ID de modèle',
  'SM.COMMON.MESSAGE.SYSMENU_MENUNAMEREQUIRE': 'Entrez le nom du menu.',
  'SM.ORGSTAFF.TITLE.ADDR_DISP_FORMAT': "Format d'affichage",
  'SM.ORGSTAFF.MESSAGE.STARTANDTEND_ISNULL':
    'Les dates de début et de fin ne peuvent pas être vides en même temps.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_TIMERANGE': 'Plage de temps',
  'SM.ORGSTAFF.LABEL.SELECT_EXCEL': 'Veuillez sélectionner le fichier Excel',
  'SM.ORGSTAFF.TITLE.EMP_BELONG_DEPT': 'Département',
  'SM.ORGSTAFF.LABEL.BUSINESSENTITY_DISPLAY_NO': "Séquence d'affichage",
  'SM.COMMON.BUTTON.CHARGECODE_CANCEL': 'Annuler',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_ADDR': 'Adresse',
  'SM.ORGSTAFF.LABEL.TEAM_TEAMTYPE': "Type d'équipe",
  'SM.COMMON.LABEL.SYSMENU_MENUCRUMB': 'Chemin',
  'SM.COMMON.LABEL.SURVEY_QUESTIONNAIRE_NAME': 'Nom du questionnaire',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_NEW_PASSWORD_CONFIRM': 'nouveau mot de passe confirmer',
  'SM.COMMON.LABEL.CURRENCY_TYPE': 'Type de devise',
  'SM.COMMON.BUTTON.ATTACHMENT_MGR_SEARCH_CRITERIA_RESET': 'Réinitialiser',
  'SM.COMMON.LABEL.MENU_ATTR_TITLE': "Étendre l'attribut",
  'SM.ORGSTAFF.LABEL.TEAM_REMOVE': 'Supprimer',
  'SM.ORGSTAFF.BUTTON.SAVE': 'Enregistrer',
  'SM.COMMON.LABEL.CHOOSE_OU_ADD': 'Choisissez OU pour ajouter',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_COPYPERMISSION': 'Autorisation de copie',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD41':
    'Verrouillage dû à des échecs consécutifs de connexion dans la période spécifiée, en minutes',
  'SM.ORGSTAFF.MESSAGE.REGION_VALIDATE_IPRANGEERROR': "Le segment d'adresse IP est mal configuré.",
  'SM.ORGSTAFF.MESSAGE.ERROR.MAXLIMIT':
    "Le nombre d'établissements sous un locataire ne peut pas dépasser 999",
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD40': 'Règle personnalisée',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ONLINE_ACCOUNT': 'Compte',
  'SM.COMMON.LABEL.CREATE_AREAINFO_MAINTAIN': 'Infos sur la nouvelle région',
  'SM.COMMON.TITLE.BANKCARD_MDF': 'Modifier la carte bancaire',
  'SM.ORGSTAFF.TIPS.RESTRICTMAC': "Veuillez saisir l'adresse MAC correcte.",
  'SM.ORGSTAFF.TITLE.QUERYFAIL_TITLE': 'La recherche a échoué.',
  'SM.COMMON.BUTTON.CREATE': 'Nouveau',
  'SM.COMMON.MESSAGE.VALIDATE_MAXLENGTHB': 'Un maximum de caractères 0 est autorisé.',
  'SM.COMMON.LABEL.HOLIDAY_HOLIDAYTYPE': 'Type',
  'SM.COMMON.LABEL.SURVEY_MODIFIED_TIME': 'Temps de modification',
  'SM.COMMON.LABEL.OPERLOG_SUCCESS_MEW': 'Succès',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD39':
    'Le mot de passe ne peut contenir que des chiffres',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD38':
    'Caractères qui ne peuvent pas être contenus dans un mot de passe',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD37':
    'Le mot de passe ne peut pas être le même que le compte ou un sous-ensemble du compte',
  'SM.COMMON.MESSAGE.CHARGECODE_TO_DRAFT':
    'Êtes-vous sûr de vouloir le changer en état de brouillon ?',
  'SM.COMMON.BUTTON.BANKCARD_RESET': 'Réinitialiser',
  'SM.COMMON.MESSAGE.SYSMENU_MAX_EDIT_LIMIT': "Limite d'édition maximale.",
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD36':
    'Liste des formats de mots de passe interdits, tels que ABCABC et ABCDEF',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD35': 'Mot de passe par défaut',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD34':
    'Durée minimale de validité du mot de passe, en heures',
  'SM.COMMON.LABEL.MENU_ACCESS_STRART_TIME': 'Heure de début',
  'SM.COMMON.LABEL.MEASUREMENT_RESULT_LIST': 'Liste des résultats',
  'SM.ORGSTAFF.MESSAGE.ADDR_DELETE_SUCCESS': 'Supprimé avec succès.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD33':
    'Le mot de passe ne peut pas être des caractères consécutifs ou des chiffres tels que AB, 123, AA et abc',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_UPLOAD_SIZE_MIN':
    'Le fichier téléchargé est plus petit que la taille minimale 0 Ko.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD32':
    'Nombre minimum de caractères de chaque type qui doivent être contenus dans un mot de passe',
  'SM.ORGSTAFF.LABEL.ORG_TYPE': "Type d'OU",
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD31': 'Durée minimale du compte',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD30': 'Longueur maximale du compte',
  'SM.COMMON.LABEL.OPERLOG_SERRCH_CRITERIA': 'Critères de recherche',
  'SM.COMMON.MESSAGE.OPERLOG_TIME_LOG2': 'jour(s).',
  'SM.COMMON.MESSAGE.OPERLOG_TIME_LOG1': "L'intervalle de temps ne peut pas dépasser",
  'SM.COMMON.LABEL.CURRENCY_BASIC_INFO': 'Informations de base',
  'SM.ORGSTAFF.TITLE.RELATION_CONFIRM_DELETE':
    "Après avoir été désactivé, la relation d'organisation et sa relation subordonnée seront supprimées. Confirmez-vous de supprimer ?",
  'SM.COMMON.LABEL.ERATE_SCURRENCY_CODE': 'Code de devise source',
  'SM.COMMON.LABEL.SELECTED_OUS': 'OU sélectionnées',
  'SM.ORGSTAFF.LABEL.REGION_DST_STARTTIME': 'Heure de début DST',
  'SM.ORGSTAFF.TITLE.POPUP_NEW_PROPERTY': 'Nouvelle propriété',
  'SM.COMMON.LABEL.SURVEY_TITLE_TYPE': 'Type de question',
  'SM.ORGSTAFF.MESSAGE.DELETE_CONFIRM': 'Êtes-vous sûr de vouloir supprimer ?',
  'SM.ORGSTAFF.OPTION.NO': 'Non',
  'SM.ORGSTAFF.LABEL.BE_TYPE': 'Type BE',
  'SM.COMMON.LABEL.OPERLOG_SESSION_ID': 'ID de session',
  'SM.COMMON.MESSAGE.MEASUREMENT_STANDARD_MUSTEXIST':
    'Le système de mesure doit avoir une unité de mesure standard.',
  'SM.ORGSTAFF.OPTION.WEEKDAY_SUN': 'Dimanche',
  'SM.ORGSTAFF.LABEL.STAFF_ROLE_EFFECTIVE_OU': 'OU efficace',
  'SM.ORGSTAFF.VALIDATOR.AFTER_TODAY':
    "L'heure saisie doit être antérieure ou égale à 00:00 aujourd'hui.",
  'SM.SM.COMMON.TITLE.GROUP_NAME_EXIST': "Le nom du groupe d'utilisateurs est exsited.",
  'SM.ORGSTAFF.LABEL.ORG_RECOVER_TIPS':
    "Après avoir été repris, l'organisation ou l'organisation et son organisation subordonnée serait disponible. Confirmez-vous de reprendre ?",
  'SM.ORGSTAFF.TITLE.EMPLOYEE_LOGINTIMERANGE': 'Segment de temps de connexion',
  'SM.COMMON.LABEL.CURRENCY_MINIMUM_MEASUREMENT': "Drapeau d'unité min",
  'SM.ORGSTAFF.TITLE.BE_ADDRESS_INFO': "Informations sur l'adresse",
  'SM.ORGSTAFF.TIPS.ENTER_OUNAME': "Entrez le nom de l'organisation.",
  'SM.COMMON.LABEL.SURVEY_SECTION': 'Régional',
  'SM.COMMON.TIPS.SPECIAL_CHAR_FORBIDDEN':
    "La valeur d'entrée ne peut pas contenir de caractères spéciaux.",
  'SM.COMMON.LABEL.SYS_MEASUREMENT_NAME': 'Nom et prénom',
  'SM.COMMON.LABEL.SURVEY_CREATED_ACCOUNT': 'Créé par',
  'SM.SM.COMMON.TITLE.NO_CHINAWORD': 'Les caractères spéciaux ne sont pas autorisés.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD19':
    'Changement forcé de mot de passe lors de la première connexion ou après la réinitialisation du mot de passe',
  'SM.COMMON.LABEL.MODULE_MODULELIST': 'Liste des modules',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD18':
    "Nombre de jours à l'avance les utilisateurs sont informés de l'expiration du mot de passe",
  'SM.ORGSTAFF.LABEL.EMPLOYEE_SECONDMENT_HISTORY': 'Historique des détachements',
  'SM.COMMON.TITLE.SURVEY_ITEM_NAME_INFO': "Informations sur le nom de l'article",
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD15':
    'Nombre maximum de tentatives de mot de passe',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD14':
    'Durée maximale de validité du mot de passe, en jours',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD13':
    'Le mot de passe ne peut pas contenir le nom',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD12':
    "Le mot de passe doit être différent du compte ou du compte dans l'ordre inverse",
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD11': 'Mot de passe invalide',
  'SM.COMMON.LABEL.SURVEY_NOT_INPUT': 'Ne pas autoriser la saisie manuelle',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD10': 'Caractères spéciaux valides',
  'SM.COMMON.MESSAGE.SYSMENU_SAVESUCCESS': 'Opération réalisée avec succès.',
  'SM.ORGSTAFF.TITLE.CONTACT_LAST_NAME': 'Nom de famille',
  'SM.COMMON.LABEL.MEASUREMENT_CONV': 'Conversion de mesure',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_STATUS': 'Statut',
  'SM.COMMON.LABEL.MEASUREMENT_UNIT_STANDARD': "Drapeau d'unité standard",
  'SM.ORGSTAFF.LABEL.REGION_DISPLAYORDER': "Séquence d'affichage",
  'SM.ORGSTAFF.LABEL.TEAM_TO': 'À',
  'SM.COMMON.LABEL.BANKCARD_TYPE_PARAM': 'Type de carte',
  'SM.COMMON.LABEL.SURVEY_CLASS_NAME': 'Nom de la catégorie',
  'SM.ORGSTAFF.LABEL.STAFF_AUTHCOPY_SOURCENO': 'Compte source',
  'SM.COMMON.LABEL.CURRENCY_GROUPSYMBOL': 'Séparateur',
  'SM.ORGSTAFF.TITLE.STAFF_ROLE_BASIC': 'Rôle',
  'SM.ORGSTAFF.TITLE.ADDR_DISP_LIST': "Liste des formats d'affichage de l'adresse",
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD09':
    'Nombre maximum de caractères consécutifs dans un mot de passe',
  'SM.ORGSTAFF.LABEL.TEAM_EXTENDPRO': 'Attributs étendus',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD08':
    'Le mot de passe doit être différent des derniers mots de passe',
  'SM.COMMON.LABEL.DATA_OPRATION': 'Opération',
  'SM.COMMON.LABEL.SURVEY_QUESTIONNAIRE_ID': 'ID du questionnaire',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD07':
    'Le mot de passe doit contenir des caractères spéciaux',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD06':
    'Le mot de passe doit contenir des lettres minuscules',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD05':
    'Le mot de passe doit contenir des lettres majuscules',
  'SM.COMMON.LABEL.MEASUREMENT_UNIT_CODE': 'Code',
  'SM.ORGSTAFF.MESSAGE.ADDR_LEVELS_HIGH': "L'adresse n'est pas séquentielle.",
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD04':
    'Le mot de passe doit contenir des lettres',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD03':
    'Le mot de passe doit contenir des chiffres',
  'SM.ORGSTAFF.LABEL.PARENT_BE': 'Parent BE',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD02': 'Longueur minimale du mot de passe',
  'SM.ORGSTAFF.MESSAGE.SELECT_WG_INFO': 'Veuillez sélectionner un groupe de travail.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD01': 'Longueur maximale du mot de passe',
  'SM.COMMON.BUTTON.PROPERTY_CONFIRM': 'Informations',
  'SM.COMMON.LABEL.EDIT_AREAINFO_MAINTAIN': 'Modifier les infos de la région',
  'SM.COMMON.TITLE.PROPERTY_SUCCESS': 'Informations',
  'SM.ORGSTAFF.LABEL.ORG_SELECTORG': 'Sélectionner OU',
  'SM.COMMON.BUTTON.SEARCH': 'Rechercher',
  'SM.COMMON.TITLE.ITEM_NOTE': "Voir l'image",
  'SM.COMMON.LABEL.ERATE_EXCHANGE_RATE': 'Taux de change',
  'SM.COMMON.BUTTON.DELETE': 'Supprimer',
  'SM.ORGSTAFF.BUTTON.EMPLOYEE_DELETE': 'Supprimer',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_MATCH_MODE': 'Mode Match',
  'SM.ORGSTAFF.TITLE.WORKGROUP': 'Groupe de travail',
  'SM.COMMON.LABEL.PROPERTY_CREATE_PROPERTY': 'Nouvel attribut',
  'SM.ORGSTAFF.MESSAGE.RECOVERORG': "Reprendre l'OU.",
  'SM.AUC.LABEL.LOGINLOG_EMPLOYEE_NAME': "Nom de l'employé",
  'SM.AUC.LABEL.LOGINLOG_LOGIN_IP_ADDRESS': 'IP de connexion',
  'SM.AUC.LABEL.LOGINLOG_OPER_CONTENT': "Contenu de l'opération",
  'SM.AUC.LABEL.LOGINLOG_OPER_TIME': "Temps d'opération",
  'common.bremui.title.operation': 'Opération',
  'SM.ORGSTAFF.HINT.PLEASE_INPUT': "S'il vous plaît entrer",
  'SM.MENU.SEARCH.INPUT': 'Nom du menu de recherche',
  'SM.MENU.ADD.CUSTOM.MENU': 'Ajouter un menu',
  'SM.MENU.ADD.CUSTOM.PERM_REPEAT': "Dupliquer le nom de l'autorisation",
  'SM.AUC.MESSAGE.PWDR_VALIDATE': 'Règle de vérification',
  'SM.AUC.MESSAGE.PWDR_MANAGE': 'Règle de gestion',
  'SM.AUC.MESSAGE.RULEGROUP': 'Groupe de règles',
  'SM.AUC.MESSAGE.RULEGROUPNAME': 'Nom du groupe de règles',
  'SM.AUC.MESSAGE.RULEGROUPList': 'Liste des règles',
  'SM.AUC.MESSAGE.RULEGROUPListEDIT': "modification d'un groupe de règles",
  'SM.AUC.MESSAGE.RULEGROUPListADD': "Ajout d'un de règles",
  'SM.AUC.MESSAGE.LOGIN.IP': 'Adresse IP de connexion',
  'SM.AUC.MESSAGE.PWDRULE_ACCOUNT': 'Règle de compte',
  'SM.AUC.LABEL.PWD_PWDRULE': 'Règle de mot de passe',
  'SM.AUC.LABEL.PWD_CUSTOMIZEDRULE': 'Règle personnalisée',
  'SM.AUC.LABEL.KIND': 'Catégories',
  'SM.AUC.LABEL.VALUE': 'Valeur',
  'SM.MENU.DELETE.CUSTOM.ROLE.FAIL':
    "Le menu a été supprimé avec succès, mais les permissions associées au menu n'ont pas été supprimées. Vérifiez si les permissions sont toujours référencées par d'autres menus ou rôles",
  'SM.AUC.ACCOUNT.LOCK.SEARCH.ACCOUNT': 'Rechercher un compte',
  'SM.AUC.ACCOUNT.LOCK.USER.NOT.EXIT': "L'utilisateur n'existe pas",
  'SM.AUC.LABEL.LOGINSESSION_SESSION': 'État de la session',
  'SM.AUC.LABEL.LOGINSESSION_LOGINSTATUS': 'État de la session',
  'SM.AUC.LABEL.USERLOGUI_LOGINIDACCOUNT': 'Compte de connexion',
  'SM.AUC.MESSAGE.OPERLOG_TIPS_LOGOUT': 'Forcer la sortie',
  'SM.AUC.LABEL.LOGINSESSION_LOCKSTATUS': 'Statut de verrouillage',
  'SM.AUC.LABEL.LOGINSESSION_LOCKTIME': 'Temps de verrouillage',
  'SM.AUC.LABEL.LOGINSESSION_LOCK': 'Verrouiller',
  'SM.AUC.LABEL.LOGINSESSION_UNLOCK': 'Déverrouiller',
  'SM.AUC.LABEL.LOGINSESSION_SURE_LOCK': "Voulez -vous vraiment verrouiller l'utilisateur?",
  'SM.AUC.LABEL.LOGINSESSION_LOCKFLAG': 'Drapeau de verrouillage',
  'SM.AUC.LABEL.LOGINSESSION_LOCKTYPE': 'Type de verrouillage',
  'SM.AUC.LABEL.LOGINSESSION_LOCK_EMPLOYEE': 'Verrouillé par',
  'SM.AUC.LABEL.LOGINSESSION_LOCKBYHAND': 'Verrouiller manuellement',
  'SM.AUC.LABEL.LOGINSESSION_UNLOCKBYHAND': 'Déverrouiller manuellement',
  'SM.AUC.LABEL.LOGINSESSION_AUTHSTATUS': "Statut d'accréditation",
  'SM.AUC.LABEL.LOGINSESSION_NEARESTPAWDAUTHTYPE':
    "État de l'authentification du dernier mot de passe",
  'SM.AUC.LABEL.LOGINSESSION_NEARESTPWDAUTHTIME':
    "Heure d'authentification du dernier mot de passe",
  'SM.AUC.LABEL.LOGINSESSION_PWDAUTHFAILTIME':
    "Temps d'échec de l'authentification du mot de passe",
  'SM.AUC.MESSAGE.LOGINSESSION_LOGININ_STATUS': 'Signé dans',
  'SM.AUC.MESSAGE.LOGINSESSION_LOGOUT_STATUS': 'Non signé',
  'SM.AUC.MESSAGE.LOGINSESSION_LOCKSTATUS': 'Verrouillé',
  'SM.AUC.MESSAGE.LOGINSESSION_UNLOCKSTATUS': 'Non verrouillé',
  'SM.AUC.LABEL.LOGINSESSION_PWDAUTHFAIL': 'La contraseña se autentica fallar',
  'SM.USERGROUP.DELETEG_GROUP.TITLE': "Supprimer le groupe d'utilisateurs sélectionné?",
  'SM.USERGROUP.DELETEG_GROUP.CONTENT':
    "Après la suppression d'un groupe d'utilisateurs, il ne peut qu'être recréé.",
  'SM.USERGROUP.DELETEG_EMPLOYEE.TITLE':
    "Supprimer les employés sélectionnés du groupe d'utilisateurs actuel?",
  'SM.USERGROUP.DELETEG_EMPLOYEE.CONTENT':
    "Après qu'un employé a été supprimé d'un groupe d'utilisateurs, vous pouvez l'ajouter à nouveau au groupe d'utilisateurs si vous voulez restaurer l'employé.",
  'SM.AUTH.MESSAGE.FUNCAUTH_MENU': 'Le menu',
  'SM.AUTH.LABEL.ROLETMPL_OPERTIME': "Temps d'opération",
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DESC_FIELD_INPUT': 'Nom du champ Nom:',
  'SM.AUTH.TITLE.ROLE_FUNCAUTH_FAIL':
    "Impossible d'enregistrer certaines autorisations de fonction. Ces autorisations sont exclusives ou ne peuvent vous être accordées.",
  'SM.AUTH.MESSAGE.CHANGE_SCOPE_SUGGESTION':
    'Si le nœud parent change la portée du rôle, la portée du nœud enfant doit être modifiée comme celle du nœud parent.',
  'SM.AUTH.TITLE.ROLE_DISALLOWED_FUNCTION_PERMISSION': 'Autorisation de fonction exclue',
  'SM.AUTH.LABEL.OPPAUTH_SOURCE_TYPE_INPUT': 'Autorisation Source :',
  'SM.FEDERATION.COMMON.DELETE.FAIL': 'La suppression a échoué.',
  'SM.AUTH.TITLE.ERROR': 'Erreur',
  'SM.AUTH.TITLE.SELECT_OPEN_LOGIN': 'Choisissez Login Ouvrir le menu',
  'SM.AUTH.LABEL.OPERATOR_ACCOUNT_INPUT': 'Compte:',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_OPER_TYPE_LIST_INPUT': "ID de liste de type d'opération :",
  'SM.AUTH.LABEL.MODULE_INPUT': 'Module:',
  'SM.AUTH.MESSAGE.ROLE_RELATIONSHIP_EXISTS_NOT_BEINHER':
    "Les deux objets sont exclusifs et l'héritage n'est pas autorisé.",
  'SM.AUTH.OPTION.ROLEAUTHVIEW_STAUTS_NORMAL': 'Normal',
  'SM.AUTH.LABEL.ROLE_AUTH_DATA': 'Autorisation des données',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_PARENT_NODE_FIELD': 'Nom du champ PARENT_ID',
  'SM.AUTH.TITLE.ROLE_GRANTABLE_DATA_PERMISSION': 'Autorisation de données accordée',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_OPERATION': "Nom de l'opération",
  'SM.AUTH.LABEL.AUTH_CONF_DATA': "Paramètres d'autorisation de données",
  'SM.AUTH.LABEL.ROLE_GENERALROLELIST': 'Liste générale des rôles',
  'SM.FEDERATION.FEDERATIONAPP.PHONE': 'Numéro de contact',
  'SM.AUTH.BUTTON.ADD': 'Nouveau',
  'SM.AUTH.MESSAGE.ROLETMPL_SELECT_ONE_ROLE': 'Sélectionnez au moins un rôle.',
  'SM.AUTH.MESSAGE.TIME_LOG2': 'jour(s).',
  'SM.AUTH.MESSAGE.TIME_LOG1': "L'intervalle de temps ne peut pas dépasser",
  'SM.AUTH.TITLE.USERAUTHVIEW_USERHAVAEROLES': 'Rôle utilisateur',
  'SM.AUTH.LABEL.ROLE_GRANTED_AUTH_TYPE': 'Type accordé',
  'SM.AUTH.TITLE.INPUT_MENU_NAME': 'Saisir un nom de menu',
  'SM.AUTH.LABEL.FUNCAUTH_STATUS': 'Statut',
  'SM.AUTH.TITLE.USERAUTHVIEW_MENU_AUTHID': "ID d'autorisation",
  'SM.AUTH.MESSAGE.ROLE_RELATIONSHIP_EXISTS': 'La relation mutuelle-exclusivité existe déjà.',
  'SM.AUTH.TITLE.ROLE_OPPAUTHNAME': 'Autorisation Mutuelle-Exclusive',
  'SM.AUTH.LABEL.ROLETMPL_ROLE_LIST': 'Liste des rôles',
  'SM.AUTH.MESSAGE.BIZ_OBJ_POLICY_DUPLICATE':
    'Il existe des politiques ayant le même rôle et la même portée.',
  'SM.FEDERATION.FEDERATIONAPP.CLIENTNAME': "Système d'authentification tiers",
  'SM.AUTH.MESSAGE.ROLETMPL_CAN_NOT_DELETE':
    'Impossible de supprimer le nœud car le nœud contient des sous-nœuds.',
  'SM.AUTH.LABEL.ROLE_NORMALROLE_BENAME': 'Accueil du rôle commun BE',
  'SM.AUTH.TITLE.MENU_NAME': 'Nom du menu',
  'SM.AUTH.TIPS.ROLETMPL_HAS_OPP_AUTH':
    "L'enregistrement a échoué parce qu'il existe une autorisation mutuellement exclusive.",
  'SM.AUTH.TIPS.FUNCAUTH_NO': 'Non',
  'SM.AUTH.TITLE.ROLE_LOGIN_MENU_UPON': "Menu d'affichage automatique lors de la connexion",
  'SM.AUTH.LABEL.ROLETMPL_ROLETMPL_CONFIGURATE': 'Configurer le modèle de rôle',
  'SM.AUTH.LABEL.OPERATOR_ACCOUNT': 'Compte',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_SERVICE_INPUT': 'Nom du service:',
  'SM.FEDERATION.IMPORT.FILESIZEVALIDATEFAIL': 'Sélectionnez un fichier de moins de 10 Mo.',
  'SM.AUTH.LABEL.STAFFROLELOG_OPER_TYPE_INPUT': "Type d'opération:",
  'SM.AUTH.LABEL.ROLE_PARENTROLE_NAME': 'Nom général du rôle',
  'SM.AUTH.LABEL.ROLETMPL_OPERATOR': 'Opérateur',
  'SM.AUTH.MESSAGE.ROLE_ERROR_BEEN_EXTENDED':
    "Impossible de supprimer le rôle parce qu'il a été hérité par ou a hérité d'un autre rôle.",
  'SM.AUTH.TITLE.USERAUTHVIEW_ATTR_DEPARTMENT': 'Département',
  'SM.AUTH.MESSAGE.ROLE_THE_ROLES_PERMISSION_OPPED':
    "L'enregistrement a échoué parce que la permission de la fonction de base a une relation mutuellement exclusive.",
  'SM.AUTH.LABEL.THIRD_DATASOURCE_NAME': 'Nom de la source de données',
  'SM.AUTH.TITLE.PERMISSION_RECOVERY': 'Recyclage des autorisations',
  'SM.AUTH.BUTTON.EXPORT_CURRENT_ROLE': "Exporter l'élément sélectionné",
  'SM.FEDERATION.COMMON.REFRESH': 'Actualiser',
  'SM.AUTH.LABEL.FUNCAUTH_ROLE_UNAVAILABLE': 'Autorisation de rôle non disponible',
  'SM.AUTH.TITLE.USERAUTHVIEW_AUTHVIEW': 'Vue des autorisations',
  'SM.AUTH.LABEL.FUNCAUTH_AUTHID': "ID d'autorisation",
  'SM.AUTH.MESSAGE.PERM_RECOVERY_SUCCESS': 'Autorisation révoquée avec succès.',
  'SM.AUTH.TITLE.USERAUTHVIEW_FUNCTIONLIST': 'Liste des autorisations des fonctions',
  'SM.AUTH.LABEL.ROLE_EMPLOYEE_ACCOUNT': 'Compte',
  'SM.AUTH.MESSAGE.ROLETMPL_INFORMATION': 'Détails',
  'SM.AUTH.LABEL.STAFFROLELOG_OPER_TYPE': "Type d'opération",
  'SM.AUTH.TITLE.EMPOLYEE_ROLESCOPE': 'Employé',
  'SM.AUTH.BUTTON.CLEAR': 'Effacer',
  'SM.AUTH.LABEL.ROLE_CODE_INPUT': 'Code de rôle :',
  'SM.AUTH.LABEL.BIZ_OBJ_FIELD_PRIVILEGE': 'Privilège de champ',
  'SM.AUTH.LABEL.FUNCAUTH_AUTHTYPE': "Type d'autorisation",
  'SM.FEDERATION.COMMON.DETAIL': 'Détails',
  'SM.FEDERATION.COMMON.NEW': 'Créer',
  'SM.AUTH.LABEL.MENU_AUTHID_INPUT': "Identifiant d'autorisation :",
  'SM.AUTH.TITLE.USER_PERMISSION_COMPARE': 'Comparaison des autorisations du personnel',
  'SM.AUTH.TIPS.DATA_AUTH_PARAM_INVALID':
    "Le nom de la table, le nom de la colonne ne peut contenir que des lettres, des nombres et des '_' et ne peut commencer que par des lettres.",
  'SM.AUTH.TITLE.ROLE_GRANTABLE_FUNCTION_PERMISSION': 'Transfert de autorisation',
  'SM.AUTH.TITLE.ROLE_PERMISSION_COMPARE': 'Comparaison des autorisations de rôles',
  'SM.AUTH.TITLE.ROLE_BUSINESS_ENTITY': 'ID BE',
  'SM.AUTH.LABEL.ROLE_DERIVE_PARENTROLE': 'Rôle général hérité',
  'SM.AUTH.MESSAGE.ROLE_ADD_SUCCESSFUL': 'Ajouté avec succès.',
  'SM.AUTH.LABEL.ROLE_EMPLOYEE_NAME': 'Employé',
  'SM.AUTH.MESSAGE.FUNCAUTH_INTERFACE': "L'interface",
  'SM.AUTH.BUTTON.ROLETMPL_RESET': 'Réinitialiser',
  'SM.AUTH.TITLE.ROLE_PERMISSION_OBJECT_TYPE': "Type d'objet d'autorisation",
  'SM.AUTH.TITLE.INPUT_AUTH_OBJ_NAME': "Saisir un nom d'objet d'autorisation",
  'SM.FEDERATION.MAPPINGRULE.LOCAL': "Informations sur l'utilisateur local",
  'SM.AUTH.LABEL.CLEAR_TIMEOUT_SELECT_MONTH_FRONT': 'Mois (30 jours) à réserver',
  'SM.AUTH.LABEL.STAFFROLELOG_ROLE_NAME': 'Nom du rôle',
  'SM.AUTH.LABEL.ROLE_ID_B_INPUT': 'Identifiant de rôle (B) :',
  'SM.AUTH.LABEL.PERMISSION_CLASS': 'Classification des autorisations',
  'SM.AUTH.BUTTON.OK': 'OK',
  'SM.AUTH.MESSAGE.ROLE_ERROR_HAS_EXCLUDED':
    'Impossible de supprimer le rôle car il exclut un autre rôle.',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DYNA_CONF': 'Drapeau de configuration dynamique',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_PERMISSION_FUNCTION': 'Fonction',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DATA_FILTER_CONDITION': 'Critères de filtre de données',
  'SM.FEDERATION.COMMON.UPDATE.FAIL': 'Échec de la modification.',
  'SM.FEDERATION.FEDERATIONAPP.VALDATEHTTP': "Le format de l'URL n'est pas valide",
  'SM.AUTH.LABEL.ROLETMPL_START_TIME': 'Heure de début',
  'SM.AUTH.LABEL.DATA_DICTABLE_ID': 'Code de dictionnaire de données',
  'SM.AUTH.LABEL.DATAAUTHMGR_TITLE_AUTHNAME_CONF_INPUT': "Nom de l'autorisation :",
  'SM.AUTH.LABEL.ROLE_EXCLUDINGROLE': 'Exclure le rôle',
  'SM.AUTH.MESSAGE.OPERATE_SUCCESS': 'Opération réalisée avec succès.',
  'SM.AUTH.LABEL.FUNCAUTH_PARENTAUTH': "ID d'autorisation parent",
  'SM.AUTH.LABEL.PERMISSION_INPUT': 'Autorité:',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_PERMISSION_MENU': 'Le menu',
  'SM.AUTH.MESSAGE.ROLETMPL_QUERY_FAILED': 'La recherche a échoué.',
  'SM.AUTH.LABEL.ROLE_NAME_INPUT': 'Nom du rôle:',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_PUT_FUNCTION': 'Transfert de autorisation',
  'SM.AUTH.LABEL.ROLE_NORMALROLE': 'Rôle commun',
  'SM.AUTH.OPTION.DISALLOWED_FUNCTION_PERM': 'Autorisation de fonction exclue',
  'SM.AUTH.MESSAGE.ROLETMPL_ROLETMPL_EXISTS': 'Le nom du modèle de rôle existe déjà.',
  'SM.AUTH.MESSAGE.DATAPER_NO_PERM': "Vous n'avez pas l'autorisation d'opération.",
  'SM.AUTH.LABEL.ROLE_ROLE_ID': 'ID de rôle',
  'SM.AUTH.LABEL.OPERATION_TYPE': "Type d'opération",
  'SM.AUTH.MESSAGE.FUNCAUTH_UPLOADINFO': 'Sélectionnez le format de la pièce jointe :',
  'SM.AUTH.LABEL.DATA': 'Autorisation des données',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DISPLAYNO_FIELD': 'Trier le champ',
  'SM.AUTH.TITLE.ORG_SELECTION': "Sélection de l'organisation",
  'SM.AUTH.LABEL.ROLE': 'Rôle',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_STAUTS_PAUSE': 'Suspendre',
  'SM.AUTH.TITLE.USERAUTHVIEW_ROLEVIEW': 'Vue des rôles',
  'SM.AUTH.LABEL.FUNCAUTH_ROLE_GRANTABLE': 'Permission de rôle accordée',
  'SM.AUTH.LABEL.DATAPROVIDER_TYPE_INPUT': 'Valeur des données Type de source :',
  'SM.AUTH.TITLE.USERAUTHVIEW_ATTR_BE': 'BE',
  'SM.AUTH.TITLE.USERAUTHVIEW_ROLE_TYPE': 'Type de rôle',
  'SM.FEDERATION.IMPORT.USER': 'Importer un utilisateur fédéré',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DISPLAYNO_FIELD_INPUT': 'Trier le champ:',
  'SM.AUTH.LABEL.CLEAR_TIMEOUT_SELECT_MONTH_END': 'mois (un mois est 30 jours) données.',
  'SM.AUTH.LABEL.BIZ_OBJ_PRIVACY_LEVEL_SECRET': 'Secret',
  'SM.AUTH.TITLE.STAFFROLELOG_LIST': 'Liste de journal des rôles des employés',
  'SM.FEDERATION.IMPORT.FAIL': 'Échec',
  'SM.AUTH.MESSAGE.ROLE_ERROR_HAS_USERS':
    'Impossible de supprimer le rôle parce que le rôle a été lié à un employé.',
  'SM.AUTH.LABEL.ROLE_PARENTROLE_BENAME': 'Rôle général Accueil BE',
  'SM.AUTH.LABEL.FUNCAUTH_STATUS_INPUT': 'Statut:',
  'SM.AUTH.LABEL.CLEAR_POPUP_TITLE': 'Supprimer les données expirées',
  'SM.AUTH.TITLE.ROLE_HORTCUT_MENU': 'Menu Raccourci',
  'SM.AUTH.LABEL.MODULE': 'Module',
  'SM.AUTH.LABEL.ROLETMPL_ASSINGED_PERMISSION': 'Accorder la permission de jouer un rôle',
  'SM.AUTH.TITLE.INPUT_ROLE_NAME': "Nom du rôle d'entrée",
  'SM.AUTH.LABEL.ROLE_NO_INFO': "Gérer les rôles dans l'arborescence des rôles à gauche.",
  'SM.AUTH.LABEL.BIZ_OBJ_LIST': "Liste d'objets métier",
  'SM.AUTH.LABEL.ROLE_SUPERID': 'ID de rôle parent',
  'SM.AUTH.TITLE.ROLEAUTHVIEW_USERVIEW': 'Vue des employés',
  'SM.AUTH.LABEL.ROLE_CONFIG_INFO': 'Configurer les informations de rôle',
  'SM.AUTH.LABEL.STAFFROLELOG_OPER_TIME': "Temps d'opération",
  'SM.AUTH.LABEL.THIRD_DATASOURCE': 'Troisième source de données',
  'SM.AUTH.BUTTON.ADDAUTH': 'Ajouter',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_STAUTS_DISCARDED': 'Supprimer',
  'SM.AUTH.TIPS.FUNCAUTH_YES': 'Oui',
  'SM.FEDERATION.COMMON.UPDATE.SUCCESS': 'Modification réussie.',
  'SM.AUTH.MESSAGE.FUNCAUTH_UPLOADSIZE': 'Taille maximale de la pièce jointe (octets) :',
  'SM.AUTH.MESSAGE.DATAPER_OBJ_TREE_ROOT': "Arborescence d'objets d'autorisation",
  'SM.AUTH.TITLE.ROLEAUTHVIEW_AUTHVIEW': 'Vue des autorisations',
  'SM.AUTH.LABEL.BIZ_OBJ_PRIVACY_LEVEL_CONFIDENTIAL': 'Confidentiel',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DATA_FILTER_CONDITION_INPUT':
    'Critères de filtre de données :',
  'SM.AUTH.LABEL.ROLEBUSINESS_ENTITY_ID': 'ID BE du rôle',
  'SM.AUTH.MESSAGE.FUNCAUTH_DISABLE': 'Indisponible',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_OPER_TYPE_LIST': "ID de liste de type d'opération",
  'SM.AUTH.BUTTON.IMPORT_ROLE': 'Importer le rôle',
  'SM.FEDERATION.COMMON.RESET': 'Réinitialiser',
  'SM.FEDERATION.FEDERATIONAPP.MAPPINGRULE': "Règle de mappage d'utilisateurs fédérés",
  'SM.AUTH.TITLE.USERAUTHVIEW_DATA_OBJECT_TREE': "Arborescence d'objets d'autorisation",
  'SM.AUTH.BUTTON.ROLE_COPY_ROLE_AND_CREATE':
    "Copier l'autorisation de fonction et l'autorisation de données et créer un rôle",
  'SM.AUTH.LABEL.ROLETMPL_SEARCH_CRITERIA': 'Critères de recherche',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_PARENT_NODE_FIELD_INPUT': 'Nom du champ PARENT_ID:',
  'SM.AUTH.LABEL.ROLE_EXCLUDEDROLE': 'Rôle exclu',
  'SM.AUTH.TITLE.ROLEAUTHVIEW_PARENT_ROLETYPE': 'Rôle général',
  'SM.FEDERATION.FEDERATIONAPP.EMAIL': 'Courriel',
  'SM.AUTH.LABEL.ROLE_COPY_MODE_INPUT': 'Mode de copie:',
  'SM.FEDERATION.COMMON.OPERATION': 'Opération',
  'SM.AUTH.LABEL.ROLEPERMISSION_LOGL_IST': 'Liste de journal des autorisations de rôle',
  'SM.AUTH.LABEL.FUNCAUTH_DEPENDENT': 'Autorisation dépendante',
  'SM.AUTH.TITLE.ROLE_ASSIGNMENT_TIME': "Temps d'affectation",
  'SM.AUTH.LABEL.STAFFROLELOG_ACCOUNT_INPUT': 'Compte:',
  'SM.AUTH.LABEL.FUNCAUTH_LOADMORE': 'Charger plus',
  'SM.FEDERATION.IMPORT.FILENAMEVALIDATEFAIL':
    'Nom de fichier incorrect. Sélectionnez un fichier dont le nom ne contient que des caractères chinois, des lettres, des chiffres ou des traits de soulignement (_).',
  'SM.AUTH.TITLE.ROLE_NOT_HOLD': "Liste d'informations non gratables",
  'SM.AUTH.TITLE.ROLE_EXTENDED_PROPERTY': 'Attribut étendu',
  'SM.AUTH.TITLE.USER_PERM_COMPARE': 'Comparaison des autorisations utilisateur',
  'SM.AUTH.LABEL.BIZ_OBJ_SCOPE': "Champ d'application",
  'SM.FEDERATION.IMPORT.DOWLOADIMPORTRESULT': 'Téléchargement',
  'SM.FEDERATION.IMPORT.UPLOADSELECT': 'Sélectionnez un fichier.',
  'SM.AUTH.TITLE.USERAUTHVIEW_OPERTYPE': "Type d'opération",
  'SM.AUTH.LABEL.ROLE_TMPL_LIST': 'Liste des modèles de rôles',
  'SM.AUTH.MESSAGE.DATAPER_CONFIRM_DEL': 'Êtes-vous sûr de vouloir supprimer ?',
  'SM.AUTH.LABEL.FUNCAUTH_AUTHNAME': "Nom de l'autorisation",
  'SM.AUTH.LABEL.ROLE_COPY_TARGETROLE': 'Rôle cible',
  'SM.AUTH.BUTTON.ROLE_DESELECT_ALL': 'Désélectionner tout',
  'SM.AUTH.BUTTON.ROLETMPL_SEARCH': 'Rechercher',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_TYPE_TABLE_INPUT': 'Tableau:',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_BASE_FUNCTION': 'Autorisation de fonction de base',
  'SM.AUTH.LABEL.ROLE_STAFF_LIST': 'Liste des employés affectés',
  'SM.AUTH.TITLE.USERAUTHVIEW_ROLE_ID': 'ID de rôle',
  'SM.AUTH.MESSAGE.ROLETMPL_CANNOT_SELECT_MODULE': 'Impossible de sélectionner le module.',
  'SM.FEDERATION.IMPORT.IPLODATIP':
    "Importation de données... Affichez les détails dans la zone Résultat d'importation utilisateur fédéré.",
  'SM.AUTH.MESSAGE.ROLETMPL_TIME_CONDITION': "L'heure doit être postérieure à l'heure de début.",
  'SM.AUTH.TITLE.ROLE_PERMISSION_OBJECT_NAME': "Nom de l'objet d'autorisation",
  'SM.AUTH.TITLE.CONFIRM_PERM_RECOVERY': 'Confirmer',
  'SM.AUTH.TITLE.ROLE_SHORTCUT_MENU_TOOLBAR': 'Menu Raccourci',
  'SM.AUTH.TITLE.USERAUTHVIEW_TITLE_AUTHNAME_CONF': "Nom de l'autorisation",
  'SM.AUTH.LABEL.BIZ_OBJ_FIELD_PRIVACY': 'Niveau de confidentialité du champ',
  'SM.AUTH.TITLE.SELECT_SHORT_CUT': 'Sélectionner le menu Raccourci',
  'SM.FEDERATION.FEDERATIONAPP.EDIT': "Modifier l'application fédérée",
  'SM.AUTH.LABEL.ROLE_ID_B': 'ID de rôle (B)',
  'SM.AUTH.LABEL.ROLE_ID_A': 'ID de rôle (A)',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_IS_BE_FILTER_INPUT': 'Filtrer par BE:',
  'SM.AUTH.TITLE.USERAUTHVIEW_LOG_OBJ_NAME': "Nom de l'objet",
  'oms.operationparam.msg.unsafeinfo':
    "Notez que le protocole réseau utilisé par l'adresse actuelle n'est pas sécurisé. Si vous décidez de l'utiliser, des risques de sécurité peuvent exister.",
  'SM.AUTH.LABEL.ROLE_TYPE_INPUT': 'Type de rôle:',
  'SM.AUTH.LABEL.ROLE_COPY_SOURCEROLE_INPUT': 'Rôle source :',
  'SM.AUTH.MESSAGE.PASSWORD_RULE_CONFIRM_MESSAGE_41':
    'Si la plage de temps est trop courte, la sécurité du mot de passe est affectée. Êtes-vous sûr de le changer ?',
  'SM.AUTH.LABEL.DATA_DICTABLE_ID_INPUT': 'Code de dictionnaire de données :',
  'SM.FEDERATION.IMPORT.STARTTIME': 'Heure de début',
  'SM.AUTH.TITLE.USERAUTHVIEW_USER_INFORMATION': 'Informations sur les employés',
  'SM.AUTH.LABEL.FUNCAUTH_DELETE_CHECK2': 'les rôles seront également effacés:',
  'SM.AUTH.TIPS.ROLE_INPUTROLENAME': 'Saisir un nom de rôle',
  'SM.AUTH.LABEL.FUNCAUTH_DELETE_CHECK1':
    "Si vous supprimez l'autorisation, les informations d'autorisation des employés liés et les éléments suivants",
  'SM.AUTH.MESSAGE.ROLETMPL_CANT_COPY_PERMISSION':
    'Vous ne pouvez pas copier certaines autorisations. Continuer ?',
  'SM.AUTH.MESSAGE.FUNCAUTH_LANGUAGE': 'Langue',
  'SM.AUTH.MESSAGE.PASSWORD_LENTH_LESS_THAN_8':
    'Un mot de passe court comporte des risques de sécurité. Êtes-vous sûr de vouloir utiliser ce nouveau mot de passe ?',
  'SM.FEDERATION.FEDERATIONAPP.ERROR.INVALIDFIELD': "Le champ n'est pas valide.",
  'SM.FEDERATION.FEDERATIONAPP.ERROR.PARSEFAIL':
    "Impossible d'analyser les informations de l'utilisateur externe.",
  'SM.AUTH.LABEL.ROLE_COPY_MODE': 'Mode de copie',
  'SM.AUTH.LABEL.FUNCAUTH_ROLE_GRANTABLE_DETAIL':
    "Rôles qui peuvent accorder l'autorisation de la fonction à d'autres",
  'SM.AUTH.LABEL.OPERATION_TYPE_INPUT': "Type d'opération:",
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_TREE_INFO_CONF_INPUT':
    "Configuration supplémentaire de l'arborescence :",
  'SM.AUTH.LABEL.ROLE_TYPE': 'Type de rôle',
  'SM.FEDERATION.IMPORT.FILESUFFIXVALIDATEFAIL':
    'Format de fichier incorrect. Sélectionnez un fichier XLSX.',
  'SM.AUTH.LABEL.ROLETMPL_END_TIME_INPUT': 'Heure de fin:',
  'SM.FEDERATION.FEDERATIONAPP.NAME': 'UserAccount',
  'SM.FEDERATION.COMMON.DELETE.SUCCESS': 'Suppression réussie.',
  'SM.AUTH.TIPS.ROLETMPL_CREATE_AND_ASSIGN': 'Créer un rôle et attribuer des autorisations',
  'SM.AUTH.MESSAGE.PASSWORD_RULE_CONFIRM_MESSAGE_39':
    "L'ouverture de cette règle affectera la sécurité du mot de passe, veuillez confirmer si vous continuez à ouvrir?",
  'SM.AUTH.TITLE.INPUT_ROLE_TMPL_NAME': "Nom du modèle de rôle d'entrée",
  'SM.AUTH.LABEL.ROLETMPL_ROLE_ASSOCIATION_LOG': 'Journal des associations de rôles',
  'SM.AUTH.TITLE.INPUT_USER_LOGINID': 'Saisir un compte utilisateur',
  'SM.FEDERATION.FEDERATIONAPP.ERROR.EXISTS':
    "Le nom du système d'authentification tiers, l'URL ou le nom de la règle de mappage existe déjà.",
  'SM.AUTH.LABEL.EMPLOYEEINCLUDED': 'Inclure le personnel des sous-OU',
  'SM.AUTH.LABEL.FUNCAUTH_AUTHTYPE_INPUT': "Type d'autorisation:",
  'SM.AUTH.BUTTON.SAVE': 'Enregistrer',
  'SM.AUTH.TITLE.PERMISSION_SELECTION': "Sélectionner l'autorisation",
  'SM.AUTH.LABEL.STAFFROLELOG_OPER_ACCOUNT_INPUT': 'Opérateur :',
  'SM.AUTH.LABEL.ROLETMPL_PERMISSION_NAME_INPUT': "Nom de l'autorisation de la fonction:",
  'SM.AUTH.LABEL.FUNCAUTH_MODULE_INPUT': 'ID du sous-système :',
  'SM.FEDERATION.IMPORT.FAILCOUNT': 'Enregistrements échoués',
  'SM.AUTH.TITLE.ROLE_PERMISSION_TIPS':
    "Pour effectuer des opérations par lots sur l'arborescence des autorisations, utilisez les menus raccourcis sur les nœuds d'autorisation.",
  'SM.AUTH.LABEL.ROLE_ID_INPUT': 'ID de rôle :',
  'SM.AUTH.LABEL.FUNCAUTH_BASICINFO': 'Informations de base',
  'SM.AUTH.MESSAGE.ROLE_ERROR_BEED_EXCLUDED':
    'Impossible de supprimer le rôle car il exclut un autre rôle.',
  'SM.AUTH.TITLE.USERAUTHVIEW_ROLE_NAME': 'Nom du rôle',
  'SM.AUTH.TITLE.ROLEAUTHVIEW_DATAAUTHLIST': "Liste d'objets d'autorisation",
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DESC_FIELD': 'Nom du champ Nom',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_KEY_FIELD': 'Nom du champ ID',
  'SM.AUTH.OPTION.ROLE_AUTH_REMOVE': 'Supprimer',
  'SM.AUTH.TITLE.ROLE_PERMISSION_OBJECT_ID': "ID d'objet d'autorisation",
  'SM.AUTH.LABEL.PERMISSION': 'Autorisation',
  'SM.AUTH.MESSAGE.ROLE_DELETE_SUCCESSFUL': 'Supprimé avec succès.',
  'SM.AUTH.LABEL.ROLE_PARENTROLE_ID': 'ID de rôle général',
  'SM.AUTH.TITLE.USERAUTHVIEW_AUTH': 'Autorisation de fonction',
  'SM.AUTH.LABEL.FUNCAUTH_ATTRIBUTE': "Étendre l'attribut",
  'SM.AUTH.BUTTON.USERAUTHVIEW_RESET': 'Réinitialiser',
  'SM.AUTH.TITLE.ASSIGN_EMPLOYEE': 'Affecter un employé',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_TYPE_TABLE': 'Tableau',
  'SM.AUTH.MESSAGE.DATAPER_DEL_SUCCESS': 'Supprimé avec succès.',
  'SM.AUTH.TITLE.USERAUTHVIEW_ACCOUNTLOGIN': 'Compte',
  'SM.FEDERATION.COMMON.CONFIRM': 'Confirmer',
  'SM.AUTH.TIPS.ROLETMPL_ASSIGN_PERMISSON': 'Attribuer des autorisations',
  'SM.AUTH.TIPS.ROLETMPL_VIEW_DETAIL_PERMISSION':
    'Afficher les détails sur les autorisations que vous ne pouvez pas copier dans le modèle de rôle.',
  'SM.AUTH.TITLE.ROLE_BASIC_FUNCTION_PERMISSION': 'Autorisation de fonction de base',
  'SM.AUTH.HEADVALUE.AUTHUSERVIEW_AUTH_DATA': 'Veuillez choisir...',
  'SM.AUTH.MESSAGE.OPERATEFAIL': "L'enregistrement a échoué.",
  'SM.FEDERATION.FEDERATIONAPP.ERROR.PARAMNULL': 'Le paramètre request est vide.',
  'SM.AUTH.LABEL.ROLETMPL_TEMPLATENAME_INPUT': 'Nom du modèle de rôle :',
  'SM.AUTH.TITLE.CUSTOMER_ROLESCOPE_PERMISSION':
    "Lorsque le champ d'utilisation n'est pas un employé, vous ne pouvez pas attribuer des rôles aux employés",
  'SM.FEDERATION.COMMON.SUCCESS': 'Succès',
  'SM.AUTH.LABEL.OPPAUTH_SOURCE_TYPE': "Source d'autorisation",
  'SM.AUTH.MESSAGE.PASSWORD_RULE_CONFIRM_MESSAGE_12':
    'La désactivation de cette règle affectera la sécurité des mots de passe. Êtes-vous sûr de vouloir le désactiver ?',
  'SM.AUTH.MESSAGE.PASSWORD_RULE_CONFIRM_MESSAGE_10':
    'Les caractères spéciaux valides affecteront la sécurité des mots de passe. Êtes-vous sûr de vouloir les utiliser ?',
  'SM.AUTH.LABEL.ROLETMPL_PERMISSION_ID': "ID d'autorisation de fonction",
  'SM.AUTH.LABEL.FUNCAUTH_ROLE_AVAILABLE_DETAIL':
    'Rôles qui peuvent utiliser la permission de la fonction',
  'SM.AUTH.TITLE.ROLE_DELEVER_DATA_PERMISSION': "Autorisation d'accorder des données",
  'SM.AUTH.LABEL.ROLE_PARENTS_TITLE': "Informations sur l'héritage des rôles",
  'SM.FEDERATION.IMPORT.UNDO': 'En attente',
  'SM.FEDERATION.FEDERATIONAPP.ACCOUNT': 'Compte',
  'SM.AUTH.LABEL.ROLE_COPY_TARGETROLE_INPUT': 'Rôle cible :',
  'SM.AUTH.MESSAGE.SELECTA_ROLE': 'Sélectionnez un rôle.',
  'SM.AUTH.BUTTON.USERAUTHVIEW_SEARCH': 'Rechercher',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_SERVICE': 'Nom du service',
  'SM.AUTH.BUTTON.PERM_RECOVERY': 'Recycler',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_IS_BE_FILTER': 'Filtrer par BE',
  'SM.AUTH.MESSAGE.ROLE_SURE_TO_DELETE': 'Êtes-vous sûr de vouloir supprimer ?',
  'SM.AUTH.LABEL.ROLE_ID_A_INPUT': 'ID de rôle (A) :',
  'SM.AUTH.LABEL.DATAAUTHMGR_TAB_AUTH_ROLE_VIEW': 'Rôles associés aux autorisations de données',
  'SM.AUTH.LABEL.ROLE_DERIVE_NORMALROLE': 'Rôle commun dérivé',
  'SM.AUTH.LABEL.ROLE_ASSIGN_STAFF': 'Employé affecté',
  'SM.AUTH.TITLE.ROLE_TMPLATE_ID': 'ID de modèle de rôle',
  'SM.AUTH.LABEL.ATTR_LANGUAGE': 'Langue',
  'SM.AUTH.BUTTON.EXPORT': 'Exporter',
  'SM.AUTH.LABEL.BIZ_OBJ_FIELD_PRIVILEGE_SETTING': 'Réglage',
  'SM.AUTH.MESSAGE.OPERATE_WITH_ROLES':
    "L'autorisation est créée avec succès et attribuée à l'opérateur actuel et aux rôles suivants :",
  'SM.AUTH.MESSAGE.ROLETMPL_DELETE_SUCCESS': 'Supprimé avec succès.',
  'SM.AUTH.LABEL.STAFFROLELOG_ROLE_BE_ID_INPUT': 'Rôle BE:',
  'SM.AUTH.TIPS.FUNCAUTH_STOPUSE': 'Suspendre',
  'SM.AUTH.MESSAGE.OPERATE_WITHOUT_ROLES':
    "L'autorisation est créée avec succès et attribuée à l'opérateur actuel.",
  'SM.AUTH.MESSAGE.ROLE_OPP_WITH_PARENT': 'Le rôle sélectionné est opposé au rôle hérité :',
  'SM.AUTH.LABEL.STAFFROLELOG_ROLE_NAME_INPUT': 'Nom du rôle:',
  'SM.AUTH.TIPS.ENTER_AUTHNAME': "Entrez le nom d'autorisation",
  'SM.AUTH.TITLE.TO_CHOOSE_ROLE_TMPL_INPUT': 'Modèle de rôle à choisir :',
  'SM.AUTH.TITLE.USERAUTHVIEW_AUTH_MODULE': 'Module',
  'SM.AUTH.LABEL.LOGINLOG_RESET': 'Réinitialiser',
  'SM.AUTH.MESSAGE.FUNCAUTH_PAUTHNAME': "Nom de l'autorisation",
  'SM.AUTH.LABEL.FUNCAUTH_AUTHID_INPUT': "Identifiant d'autorisation :",
  'SM.AUTH.TITLE.ROLE_BASIC_DATA_PERMISSION': 'Permission de données de base',
  'SM.AUTH.MESSAGE.ROLE_CAN_NOT_ADD_ITSELF': 'Ne peut pas hériter de son propre rôle.',
  'SM.FEDERATION.MAPPINGRULE.NAME': 'Règle de mappage',
  'SM.AUTH.MESSAGE.SAVE_DEPENDENT_SELF':
    "Autorisation dépendante elle-même, ce n'est pas autorisé.",
  'SM.FEDERATION.IMPORT.STATUS': 'Statut',
  'SM.AUTH.LABEL.FUNCAUTH_ROLE_UNAVAILABLE_DETAIL':
    'Rôles qui ne peuvent pas utiliser la permission de la fonction',
  'SM.AUTH.LABEL.ROLETMPL_HOME_ENTITY': "Permission's Accueil BE",
  'SM.AUTH.TITLE.USERAUTHVIEW_AUTH_DATA': 'Autorisation des données',
  'SM.AUTH.LABEL.BIZ_OBJ_FIELD_IS_WRITEABLE': 'Est inscriptible',
  'SM.AUTH.MESSAGE.FUNCAUTH_NORMAL': 'Normal',
  'SM.AUTH.LABEL.USERAUTHVIEW_SEARCH': 'Requête',
  'SM.AUTH.MESSAGE.ROLE_NAME_SAME': 'Le nom du rôle existe déjà.',
  'SM.AUTH.LABEL.DATAPROVIDER_TYPE': 'Valeur des données Type de source',
  'SM.AUTH.BUTTON.ROLE_COPY_ROLE':
    "Copier les informations sur le rôle (seulement copier l'autorisation de fonction et l'autorisation de données)",
  'SM.AUTH.TITLE.USERAUTHVIEW_USER_LIST': 'Liste des employés',
  'SM.AUTH.LABEL.ROLETMPL_PERMISSION_NAME': "Nom de l'autorisation de la fonction",
  'SM.AUTH.LABEL.USER_ACCOUNTB': 'Compte (B)',
  'SM.AUTH.LABEL.USER_ACCOUNTA': 'Compte (A)',
  'SM.AUTH.LABEL.ROLE_NAME': 'Nom du rôle',
  'SM.FEDERATION.COMMON.DOWLOADTEM': 'Télécharger le modèle',
  'SM.AUTH.LABEL.ROLETMPL_ROLE_NAME': 'Nom du rôle',
  'SM.AUTH.LABEL.ORGANIZATION_INPUT': "Unité d'organisation :",
  'SM.AUTH.LABEL.ROLE_SCOPE': "Champ d'application du rôle",
  'SM.AUTH.MESSAGE.ROLE_NOT_EXSIT': "Le rôle n'existe pas.",
  'SM.AUTH.LABEL.AUTHVIEW_ROLE_BE_ID': 'Accueil du rôle BE ID',
  'SM.AUTH.MESSAGE.DATAPER_TREE_ROOT': 'Arborescence des autorisations de données',
  'SM.AUTH.TITLE.SUCCESS': 'Informations',
  'SM.AUTH.BUTTON.AUTH_EDIT': 'Modifier',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_OPERATION_INPUT': "Nom de l'opération:",
  'SM.AUTH.NO.SELECT': "Aucune autorisation n'est sélectionnée",
  'SM.AUTH.MESSAGE.AUTH_DATA_OBJECT_TREE': "Arborescence d'objets d'autorisation",
  'SM.FEDERATION.COMMON.CREATE.FAIL': 'La création a échoué.',
  'SM.FEDERATION.FEDERATIONAPP.NEW': 'Créer une application fédérée',
  'SM.AUTH.BUTTON.ROLE_SELECT_ALL': 'Sélectionner tout',
  'SM.AUTH.LABEL.FUNCAUTH_PARENTAUTH_INPUT': "Identifiant d'autorisation parent :",
  'SM.AUTH.LABEL.USER_ACCOUNTB_INPUT': 'Compte (B):',
  'SM.FEDERATION.COMMON.QUERY.CONDITION': 'Critères de recherche',
  'SM.AUTH.BUTTON.CANCEL': 'Annuler',
  'SM.AUTH.TITLE.USERAUTHVIEW_LOG_OBJ_ID': "ID de l'objet",
  'SM.AUTH.TITLE.USERAUTHVIEW_QUERYCONDITION_TITLE': 'Critères de recherche',
  'SM.AUTH.LABEL.ROLE_ID': 'ID de rôle',
  'SM.AUTH.LABEL.DATAAUTHMGR_ATTRIBUTE': "Étendre l'attribut",
  'SM.FEDERATION.DOWNLOAD.RESULT':
    "Télécharger le résultat de l'importation d'utilisateurs fédérés",
  'SM.FEDERATION.FEDERATIONAPP.CLIENTURL': 'URL',
  'SM.AUTH.LABEL.ROLETMPL_END_TIME': 'Heure de fin',
  'SM.AUTH.LABEL.STAFFROLELOG_ROLE_BE_ID': 'Rôle BE',
  'SM.AUTH.TITLE.ASSIGNED_EMPLOYEE': 'Employé affecté',
  'SM.AUTH.LABEL.ROLETMPL_BASIC_FUNCTION_PERMISSION': 'Autorisation de fonction de base',
  'SM.AUTH.LABEL.ROLETMPL_DESCRITPION': 'Descriptif',
  'SM.AUTH.TITLE.ADD_ROLE_FROM_ROLE_TMPL': 'Sélectionner un modèle de rôle',
  'SM.AUTH.TITLE.USERAUTHVIEW_QUERY_AUTHTYPE': "Type d'autorisation",
  'SM.AUTH.LABEL.ROLE_CODE': 'Code de rôle',
  'SM.FEDERATION.IMPORT.SUCCESSCOUNT': 'Enregistrements réussis',
  'SM.AUTH.TIPS.ROLETMPL_WARNING': 'Avertissement',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_BASE_DAUTH': 'Permission de données de base',
  'SM.AUTH.TITLE.CUSTOMER_ROLESCOPE': 'Clientèle',
  'SM.AUTH.LABEL.STAFFROLELOG_ROLE_ID': 'ID de rôle',
  'SM.AUTH.LABEL.STAFFROLELOG_ACCOUNT': 'Compte',
  'SM.AUTH.LABEL.ROLE_DERIVE': 'Héritage des rôles',
  'SM.AUTH.LABEL.BIZ_OBJ_FULL_NAME': "Nom complet de l'objet",
  'SM.AUTH.LABEL.ROLE_COPY_SOURCEROLE': 'Rôle source',
  'SM.AUTH.MESSAGE.ROLE_SELECT_A_ROLE': 'Sélectionnez un rôle.',
  'SM.AUTH.LABEL.DETAIL_INFO':
    "Sélectionnez un nœud d'autorisation dans l'arborescence de navigation à gauche pour afficher les informations sur l'autorisation.",
  'SM.FEDERATION.FEDERATIONAPP.ERROR.ISUSED':
    'Les données ne peuvent pas être supprimées ou modifiées car elles ont été utilisées par un locataire.',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_TREE_INFO_CONF':
    "Configuration supplémentaire de l'arborescence",
  'SM.AUTH.LABEL.FUNCAUTH_ISDY': 'Drapeau de configuration dynamique',
  'SM.AUTH.LABEL.ROLETMPL_TEMPLATENAME': 'Nom du modèle de rôle',
  'SM.AUTH.BUTTON.CONFIRM_DELETE': 'Confirmer la suppression',
  'SM.AUTH.TITLE.USERAUTHVIEW_AUTHVIEW_DATAAUTHLIST': "Liste d'objets d'autorisation",
  'SM.AUTH.TITLE.TO_CHOOSE_ROLE_TMPL': 'Sélectionner un modèle de rôle',
  'SM.AUTH.LABEL.CLEAR_CONFIRM_CLEAR_DATA':
    "Voulez -vous vraiment supprimer les autorisations qui n'ont pas été utilisées récemment ?",
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_ID_TREE_INPUT': "Structure d'arbre:",
  'SM.AUTH.LABEL.BIZ_OBJ_PRIVACY_LEVEL_PUBLIC': 'Publique',
  'SM.AUTH.LABEL.ROLE_AUTH_DATA_INPUT': 'Autorisation de données:',
  'SM.AUTH.LABEL.PERMISSION_CLASS_INPUT': 'Classification des autorisations :',
  'SM.FEDERATION.FEDERATIONAPP.REMOTETIP':
    "La valeur est le champ de mappage correspondant à l'utilisateur externe.",
  'SM.FEDERATION.MAPPINGRULE.DESC': 'Descriptif',
  'SM.AUTH.LABEL.ROLE_SUPERCODE_INPUT': 'Code de rôle parent :',
  'SM.FEDERATION.IMPORT.RESULT': "Résultat de l'importation d'utilisateurs fédérés",
  'SM.AUTH.TITLE.ROLEAUTHVIEW_COMMON_ROLETYPE': 'Rôle commun',
  'SM.AUTH.LABEL.ROLE_SUPERCODE': 'Code de rôle parent',
  'SM.AUTH.LABEL.ROLETMPL_ROLE_ID': 'ID de rôle',
  'SM.AUTH.LABEL.CONFIRM_DELETE_DEPEND_AUTH':
    "Confirmer pour supprimer l'autorisation de dépendance?",
  'SM.AUTH.MESSAGE.ROLETMPL_SHOWNAME': 'Modèle de rôle',
  'SM.AUTH.TITLE.ROLE_MUTUALLY_EXCLUSIVE_PERMISSION':
    "Liste d'autorisations mutuellement exclusives",
  'SM.AUTH.LABEL.COMPARE_RESULT': 'Résultat de la comparaison',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_CODE_INPUT': "Code d'autorisation :",
  'SM.FEDERATION.FEDERATIONAPP.DELETETIP': 'Êtes-vous sûr de vouloir supprimer les données ?',
  'SM.AUTH.TITLE.USERAUTHVIEW_AUTH_STATUS': 'Statut',
  'SM.AUTH.TITLE.BELONG_ROLE_TMPL': 'Modèle de rôle de propriétaire',
  'SM.AUTH.LABEL.FUNCAUTH_ROLE_AVAILABLE': 'Autorisation de rôle disponible',
  'SM.AUTH.LABEL.USER_ACCOUNTA_INPUT': 'Compte (A):',
  'SM.FEDERATION.COMMON.FAIL': 'Erreur',
  'SM.FEDERATION.IMPORT.IMPORTRESULT': 'Importer le résultat',
  'SM.AUTH.OPTION.ROLE_REMOVE_INFO_OF_TARGET_ROLE': 'Supprimer les informations de rôle cible',
  'SM.FEDERATION.IMPORT.UPLOADFILE': 'Fichier',
  'SM.AUTH.TITLE.USERAUTHVIEW_AUTHSOURCE': "Source d'autorisation",
  'SM.AUTH.TITLE.AUTHNAMEINFO': "Informations sur le nom de l'autorisation",
  'SM.AUTH.LABEL.LOGINLOG_SEARCH': 'Rechercher',
  'SM.AUTH.TIPS.ROLE_PARENTROLE': 'Nom du rôle',
  'SM.AUTH.LABEL.ROLE_DESC': 'Descriptif',
  'SM.AUTH.LABEL.FUNCAUTH_OPPAUTHNAME': 'Autorisation Mutuelle-Exclusive',
  'SM.FEDERATION.IMPORT.ENDTIME': 'Heure de fin',
  'SM.FEDERATION.COMMON.OPERATION.DELETE': 'Supprimer',
  'SM.FEDERATION.FEDERATIONAPP.LIST': 'Apps fédérées',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_PUT_DAUTH': 'Autorisation de données accordée',
  'SM.AUTH.LABEL.ROLE_NORMALROLE_ID': 'Code de rôle commun',
  'SM.AUTH.TITLE.ROLE_TMPL_ROLE_LIST': 'Liste des rôles',
  'SM.FEDERATION.COMMON.OPERATION.EDIT': 'Modifier',
  'SM.AUTH.LABEL.ROLE_ROLE_NAME': 'Nom du rôle',
  'SM.AUTH.MESSAGE.ROLETMPL_SELECT_NO_ENTITY': 'Sélectionnez au moins un rôle ou BE.',
  'SM.AUTH.BUTTON.CLEAR_ALL_TIPS': "Effacer l'autorisation non disponible",
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_CODE': "ID d'autorisation",
  'SM.AUTH.MESSAGE.OK': 'OK',
  'SM.AUTH.TITLE.CONFIRM_DEL': 'Confirmer',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_OPP_FUNCTION': 'Autorisation de fonction exclue',
  'SM.AUTH.LABEL.FUNCAUTH_MODULE': 'ID de sous-système',
  'SM.AUTH.LABEL.ROLE_INFO': 'Informations sur le rôle',
  'SM.AUTH.LABEL.DATAAUTHMGR_TITLE_AUTHNAME_CONF': "Nom de l'autorisation",
  'SM.AUTH.MESSAGE.ROLETMPL_SELECT_NODE_INFO':
    "Sélectionnez un nœud parent dans l'arborescence de navigation à gauche et gérez les modèles de rôles dans l'arborescence des modèles de rôles.",
  'SM.AUTH.LABEL.MENU_AUTHID': "ID d'autorisation",
  'SM.AUTH.TITLE.INFO': 'Informations',
  'SM.AUTH.MESSAGE.FUNCAUTH_READONLY': 'Ni-assignable',
  'SM.AUTH.LABEL.ROLETMPL_START_TIME_INPUT': 'Heure de début:',
  'SM.FEDERATION.IMPORT.FAILFILE': 'Fichiers ayant échoué',
  'SM.AUTH.LABEL.FUNCAUTH_DESC_INPUT': 'Description:',
  'SM.AUTH.LABEL.BIZ_OBJ_NAME': 'Nom et prénom',
  'SM.AUTH.LABEL.ORGANIZATION': "Unité d'organisation",
  'SM.AUTH.BUTTON.DELETE': 'Supprimer',
  'SM.AUTH.BUTTON.CLOSE': 'Fermer',
  'SM.AUTH.MESSAGE.PERM_RECOVERY':
    "Si vous cliquez sur 'OK', les autorisations de tous les rôles suivants sont révoquées. Si vous cliquez sur 'Annuler', aucune opération ne sera effectuée.",
  'SM.AUTH.MESSAGE.ROLE_RELATIONSHIP_EXISTS_NOT_BEEXCLUDING':
    'La relation successorale existe déjà et le rôle ne peut être exclu.',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_KEY_FIELD_INPUT': 'Nom du champ ID:',
  'SM.FEDERATION.IMPORT.FAILREASON': "Cause d'échec",
  'SM.AUTH.LABEL.FUNCAUTH_UPLOAD': 'Télécharger la pièce jointe',
  'SM.AUTH.LABEL.BIZ_OBJ_FIELD': 'Champ',
  'SM.AUTH.BUTTON.ROLE_DELETE_FOREVER': 'Supprimer définitivement',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DYNA_CONF_INPUT': 'Drapeau de configuration dynamique :',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DESC_INPUT': 'Description:',
  'SM.FEDERATION.COMMON.CREATE.SUCCESS': 'Création réussie.',
  'SM.AUTH.LABEL.BE_BASIC_INFORMATION': 'Informations de base',
  'SM.AUTH.TITLE.USERAUTHVIEW_NAME': 'Employé',
  'SM.AUTH.MESSAGE.SAVE_SUCCESS': 'Opération réalisée avec succès.',
  'SM.AUTH.LABEL.ROLE_DESC_INPUT': 'Description:',
  'SM.AUTH.TITLE.USERAUTHVIEW_STAFF_BE_NAME': "Maison de l'employé BE",
  'SM.AUTH.LABEL.ROLETMPL_ROLETMPL_INFO': 'Informations sur le modèle de rôle',
  'SM.AUTH.TITLE.TO_ASSIGNED_EMPLOYEE': 'Affecter un employé',
  'SM.AUTH.LABEL.ROLE_NORMALROLE_NAME': 'Nom de rôle commun',
  'SM.AUTH.OPTION.GEANTABLE_FUNCTION_PERM': 'Transfert de autorisation',
  'SM.AUTH.LABEL.BIZ_OBJ_ACCESS_RULE': "Règle d'accès",
  'SM.AUTH.LABEL.ROLETMPL_DESCRITPION_INPUT': 'Description:',
  'SM.AUTH.TITLE.USERAUTHVIEW_ROLE_PARENTS_TITLE': "Informations sur l'héritage des rôles",
  'SM.AUTH.TIPS.ENTER_ORGNAME': "Entrez le nom de l'organisation",
  'SM.FEDERATION.MAPPINGRULE.REMOTE': "Informations sur l'utilisateur externe",
  'SM.AUTH.MESSAGE.FUNCAUTH_FUNCTION': 'Fonction',
  'SM.FEDERATION.COMMON.QUERY': 'Rechercher',
  'SM.AUTH.LABEL.ROLETMPL_FUNCTION_PERMISSION': ' Une autorisation de fonction',
  'SM.AUTH.LABEL.STAFFROLELOG_OPER_ACCOUNT': 'Opérateur',
  'SM.AUTH.MESSAGE.ROLE_ERROR_SUBROLES':
    'Le rôle ne peut pas être supprimé car il a des sous-rôles.',
  'SM.AUTH.LABEL.CLEAR_TIMEOUT_SUCCESS_MSG':
    'Suppression des autorisations qui ne sont pas disponibles pendant le temps avec succès.',
  'SM.AUTH.TITLE.USERAUTHVIEW_USER_LOGINID': 'Compte',
  'SM.AUTH.LABEL.AUTHVIEW_ROLE_BE_NAME': 'Accueil du rôle BE',
  'SM.AUTH.LABEL.BIZ_OBJ_IS_OWNER': 'Est Propriétaire',
  'SM.AUTH.OPTION.ROLE_RETAIN_INFO_OF_TARGET_ROLE': 'Conserver les informations sur le rôle cible',
  'SM.AUTH.LABEL.BIZ_OBJ_ACCESS_RULE_ROLE_POLICY': "Politique d'accès aux rôles",
  'SM.AUTH.LABEL.PERMRECOVERY_ROLE_NAME': 'Rôle avec autorisation',
  'SM.AUTH.LABEL.FUNCAUTH_DESC': 'Descriptif',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DESC': 'Descriptif',
  'SM.AUTH.LABEL.DETAIL': 'Détails',
  'SM.AUTH.TITLE.INPUT_AUTHID_AUTHNAME': "Entrez un ID d'autorisation ou un nom",
  'SM.AUTH.LABEL.TITLE_CLEAR_SUCCESS': 'Inviter',
  'SM.AUTH.LABEL.STAFFROLELOG_ROLE_ID_INPUT': 'ID de rôle :',
  'SM.AUTH.LABEL.FUNCAUTH_AUTHNAME_INPUT': "Nom de l'autorisation :",
  'SM.AUTH.LABEL.ROLE_OPERTYPE': "Type d'opération",
  'SM.AUTH.LABEL.ROLETMPL_ROLE_NAME_INPUT': 'Nom du rôle:',
  'SM.AUTH.TITLE.USERAUTHVIEW_DETAIL_INFO': "Sélectionnez un nœud d'autorisation avec une URL.",
  'SM.FEDERATION.FEDERATIONAPP.VALDATEJSON': "L'élément de configuration doit être au format JSON.",
  'SM.AUTH.LABEL.FUNCAUTH_INFO': 'Informations de base',
  'SM.AUTH.TITLE.INPUT_PERMISSION_NAME': "Saisir un nom d'autorisation",
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_ID_TREE': "Structure de l'arbre",
  'SM.AUTH.MESSAGE.DELETE_CONFIRM': 'Êtes-vous sûr de vouloir supprimer ?',
  'SM.AUTH.TITLE.THIRD_DATASOURCE':
    'Opération effectuée avec succès (La troisième source de données est fermée, car la source de données est vide.).',
  'SM.AUTH.TITLE.ADD_ROLE_FROM_AUTH_TREE': "Sélectionner l'autorisation de fonction",
  'SM.FEDERATION.COMMON.CANEL': 'Annuler',
  'SM.FEDERATION.COMMON.SUBMIT': 'Envoyer',
  'SM.AUTH.OPTION.ROLE_AUTH_ADD': 'Ajouter',
  'SM.AUTH.MESSAGE.ROLE_INHERITANCE_RELATIONSHIP_EXISTS': "L'héritage existe déjà.",
  'SM.AUTH.LABEL.ASSIGNEMPLOYEE': 'Affecter des employés',
  'SM.AUTH.LABEL.ASSIGNRIGHTS': 'Autorisations assignables',
  'SM.AUTH.LABEL.OWNEDRIGHTS': 'Autorisations propres',
  'SM.ORGSTAFF.LABEL.ALLORG': 'Toutes les organisations',
  'SM.FEDERATION.USER.TEMPLATE': "Téléchargez le modèle d'importation d'utilisateur fédéré",
  'common.baseui.MESSAGE.VALIDATE_MAXLENGTHB': 'Un maximum de caractères 0 est autorisé.',
  'SM.GADGET.VALUE.MENUPERM': 'Autorisations de menu',
  'SM.GADGET.VALUE.FUNCPERM': "Autorisations d'exploitation",
  'SM.GADGET.VALUE.INTFPERM': 'Autorisations API',
  'SM.ROLE.PARENT.ROLE': 'Rôle de niveau supérieur',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_BATCH_SET_ROLE': 'Ajout de rôles par lots',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_BATCH_DELETE_ROLE': 'Suppression des rôles par lots',
  'SM.USERPWDGROUP.RANGE.VALIDATE': 'Gamme %-%',
  'SM.ROLE.SELECT.BATCH': 'Veuillez sélectionner un rôle',
  'SM.ROLE.SELECT.ERRORMSG': "Description de l'exception：",
  'SM.ROLE.SELECT.ERRORCAUSE': "Cause de l'exception：",
  'SM.ROLE.SELECT.ERRORSLOUTION': 'Suggestions de manipulation：',
  'SM.ROLE.MESSAGE.DELETE_CONFIRM':
    "Une fois qu'un rôle a été supprimé, il ne peut être créé qu'à nouveau.Supprimer ?",
  'SM.ORG.DISABLE.FAILED.PARENT':
    "Désactiver toutes les organisations enfants de l'organisation avant de désactiver l'organisation actuelle.",
  'SM.ORG.DISABLE.FAILED.EMPLOYEE':
    "Supprimez tous les employés de l'organisation, puis désactivez l'organisation.",
  'SM.EMPLOYEE.SELECTED.EMPLOYEE': 'Employés sélectionnés',
  'SM.EMPLOYEE.ALLOCATE.WORKNO': "Attribuer un ID d'employé",
  'SM.EMPLOYEE.SELECTED.WORKNO.NOT.ENOUGH':
    "Le nombre d'ID d'agent qui peuvent être attribués au type d'agent sélectionné est insuffisant",
  'SM.EMPLOYEE.CHECKED-IN.PASSWORD': "Mot de passe d'enregistrement",
  'SM.EMPLOYEE.SOFTCONSOLE.GROUP': "Groupe de trafic d'accueil",
  'SM.EMPLOYEE.INSPECTION.GROUP': "Équipe d'inspection de la qualité à domicile",
  'SM.EMPLOYEE.PLATFORM.CONFIG': 'Configuration de la plate-forme',
  'SM.EMPLOYEE.CONFIG.EMPLOYEE': 'Configuration des employés',
  'SM.EMPLOYEE.CANNOT.CHOOSE.WORKNO':
    "Vous ne pouvez pas sélectionner un employé auquel un ID d'employé a été attribué",
  'SM.EMPLOYEE.OPERATE.MAX.HUNDRED': "Opérer jusqu'à 100 employés à la fois",
  'SM.EMPLOYEE.SKILL.INFO': 'Informations sur les compétences',
  'SM.EMPLOYEE.FEATURE.CONFLICT':
    "La reconnaissance intelligente et l'enregistrement double piste ne peuvent pas être activés en même temps. Une fois activés, l'autre ne sera pas sélectionnée",
  'SM.EMPLOYEE.BATCH.CONFIG': 'Configuration par lots',
  'SM.TENANT.LABEL': 'Locataire',
  'SM.TENANT.INFO': 'Informations sur les locataires',
  'SM.TENANT.VIEW': 'Parcourir les locataires',
  'SM.TENANT.EDIT': "Modification d'un locataire",
  'SM.TENANT.CREATE': "Création d'un locataire",
  'SM.TENANT.TENANTSPACENAME': "Nom de l'environnement du locataire",
  'SM.TENANT.TENANTNAME': 'Nom du locataire',
  'SM.TENANT.ADMINACCOUNT': 'Compte administrateur',
  'SM.TENANT.PHONE': 'Numéro de téléphone',
  'SM.TENANT.CREATETIME': 'Heure de création',
  'SM.TENANT.DELETE_CONFIRM': 'Un locataire supprimé ne peut pas être restauré.Supprimer ?',
  'SM.PWDRULE.PLEASE.INPUT': 'Saisissez un nom de groupe de règles',
  'SM.EMPLOYEE.CANNOT.CHOOSE.STATUS':
    "Les employés dans l'état désactivé ne peuvent pas être sélectionnés",
  'SM.EMPLOYEE.CANCEL.CONFIG.CONFIRM': "Êtes-vous sûr d'annuler la configuration",
  'SM.EMPLOYEE.ROLE.EFFECT':
    "L'information sur le rôle de l'employé prendra effet [] minutes plus tard.",
  'SM.ROLE.AUTH.EFFECT':
    "Les informations d'autorisation du rôle prendront effet [] minutes plus tard.",
  'SM.ROLE.TEMPLATE': 'Modèle de rôle',
  'SM.LABEL.APPCUBE.SWITCH': 'Page intégrée à AppCube',
  'SM.LABEL.APPCUBE.APP': 'Sélectionner une application',
  'SM.LABEL.APPCUBE.MENU': 'Sélectionner le menu',
  'SM.MENU.NAME.SPECIAL.CHAR':
    "Les caractères spéciaux ne sont pas autorisés: !#{'$'}%&()￥+:\"{'{'}{'}'},\\/'[]=-^<>",
  'SM.COMMON.MESSAGE.DUTY.FORBIDDEN':
    'Les employés sélectionnés contiennent des employés qui ont été désactivés.',
  'SM.LABEL.ACCOUNT': 'Saisissez un numéro de compte',
  'SM.LABEL.USERNAME': "Saisissez un nom d'utilisateur",
  'SM.LABEL.EMAIL': 'Saisissez une adresse e-mail',
  'SM.LABEL.PHONE': 'Veuillez saisir le numéro de téléphone du contact',
  'SM.LABEL.PASSWORD': 'Veuillez saisir la description',
  'SM.LABEL.DESCRIPTION': 'Veuillez saisir la description',
  'SM.ORGSTAFF.BUTTON.SaveAndConfigure': 'Enregistrer et configurer',
  'SM.ROLE.BUTTON.DELETE': 'Êtes-vous sûr de vouloir supprimer les employés sélectionnés ?',
  'SM.ROLE.ADD': 'Nouveau rôle',
  'SM.ROLE.ADD.NAME': 'Saisissez un nom de rôle',
  'SM.ROLE.ADD.DECRI': 'Veuillez saisir les remarques',
  'sumweb.export.confirm.exportall': 'Vous n\'avez sélectionné aucun enregistrement. Exporter tous les enregistrements ? Vous pouvez également cliquer sur un enregistrement spécifique à exporter.',
  'sumweb.export.export.select': 'Vous avez sélectionné {0} enregistrement. Êtes-vous sûr de vouloir continuer.',
  'sumweb.export.org': 'Exportation de l\'information sur l\'organisation',
  'sumweb.export.employee': 'Exportation des renseignements sur les employés',
  'sumweb.view.import.list': 'Liste d\'importation',
  "sumweb.import.success": "Une tâche d'importation a été créée. Êtes-vous sûr de vouloir afficher la liste d'importation?",
  "SM.ROLE.EDIT": "Modifier le rôle",
  "SM.AUTH.BUTTON.CONFIRM.CLOSE": "Confirmer Fermer",
  "sumweb.agent.config": "Configurer les Sièges",
  "sumweb.employee.export.nodata": "Aucune donnée sur les employés ne répond aux exigences.",
  "sumweb.employee.view.config": "Voir la configuration"
}
