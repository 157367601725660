export default {
  'oifde.traceCause.000001': 'El tipo de transferencia no está configurado para el elemento de diagrama de transferencia de llamada.',
  'oifde.traceCause.000002': 'La interfaz es anormal porque el valor de entrada de inacción es incorrecto. {0}',
  'oifde.traceCause.000003': 'No se encuentra el elemento de diagrama compuesto.',
  'oifde.traceCause.000004': 'No se encuentra el nodo de inicio.',
  'oifde.traceCause.000005': 'El número de reciclados internos excede el límite.',
  'oifde.traceCause.000006': 'El procesamiento del elemento del diagrama es anormal.',
  'oifde.traceCause.000007': 'La rama de salida está vacía.',
  'oifde.traceCause.000008': 'Error interno del sistema.',
  'oifde.traceCause.000009': 'La rama no se encuentra. {0}',
  'oifde.traceCause.000010': 'El elemento de diagrama de transferencia de flujo no se puede transferir al siguiente nodo. {0}',
  'oifde.traceCause.000011': 'El tipo de elemento del diagrama es incorrecto.',
  'oifde.traceCause.000012': 'Error de ejecución de expresión {0}.',
  'oifde.traceCause.000013': 'Error al ejecutar la interfaz de terceros.',
  'oifde.traceCause.000014': 'La plantilla de respuesta y la variable no están seleccionadas.',
  'oifde.traceCause.000015': 'La plantilla SMS no está configurada para el elemento de diagrama de envío de SMS.',
  'oifde.traceCause.000016': 'El lenguaje de la plantilla de respuesta no es coherente con el lenguaje establecido. {0}',
  'oifde.traceCause.000017': 'El idioma de la plantilla SMS es diferente del idioma establecido en el elemento de diagrama de envío de SMS. {0}',
  'oifde.traceCause.000018': 'El flujo de destino y el nodo de destino no están configurados para el elemento del diagrama de transferencia de flujo.',
  'oifde.traceCause.000019': 'No se encuentra ningún tenant.',
  'oifde.traceCause.000020': 'El tenant ha sido suspendido.',
  'oifde.traceCause.000021': 'El módulo se inicializa más de una vez.',
  'oifde.traceCause.000022': 'El elemento de diagrama compuesto no tiene un nodo de inicio.',
  'oifde.traceCause.000023': 'Error interno del sistema.',
  'oifde.traceCause.000024': 'La ejecución del flujo interno es anormal.',
  'oifde.traceCause.000025': 'La suspensión automática se realiza si se producen excepciones internas más de tres veces.',
  'oifde.traceCause.000026': 'El flujo no se inicializa.',
  'oifde.traceCause.000028': 'El flujo ha terminado.',
  'oifde.traceCause.000029': 'No se encuentra ninguna salida en el elemento de diagrama actual cuyo ID de nodo es {0} y el nombre de nodo es {1}.',
  'oifde.traceCause.000030': 'Los datos del elemento de diagrama cuyo ID de nodo es {0} y el nombre de nodo es {1} son anormales.',
  'oifde.traceCause.000031': 'Error interno del sistema.',
  'oifde.traceCause.000032': 'La ejecución del flujo interno es anormal.',
  'oifde.traceCause.000033': 'La información de instancia de llamada no existe.',
  'oifde.traceCause.000034': 'El elemento de diagrama de finalización de llamada no existe.',
  'oifde.traceCause.000035': 'Se ha producido un error al comprobar auth_token.',
  'oifde.traceCause.000036': 'Se ha producido un error al comprobar auth_token.',
  'oifde.traceCause.000037': 'Se ha producido un error al comprobar auth_token.',
  'oifde.traceCause.000038': 'Error al procesar el siguiente nodo.',
  'oifde.traceCause.000039': 'Error al invocar el TUC. {0}',
  'oifde.traceCause.000040': 'El error no se encuentra. {0}',
  'oifde.traceCause.000041': 'Error de ejecución del procedimiento almacenado. {0}',
  'oifde.traceCause.000044': 'La URL (dirección IP y número de puerto) no está en la lista de confianza.',
  'oifde.traceAdvice.000001': 'Configure el tipo de transferencia de llamada para el elemento de diagrama de transferencia de llamada.',
  'oifde.traceAdvice.000002': 'Póngase en contacto con el administrador del sistema para modificar los parámetros de entrada de la interfaz.',
  'oifde.traceAdvice.000003': 'Compruebe si el elemento de diagrama compuesto existe en el sistema.',
  'oifde.traceAdvice.000004': 'Compruebe si el flujo se libera o si el flujo es normal.',
  'oifde.traceAdvice.000005': 'Póngase en contacto con el administrador del sistema para comprobar el sistema.',
  'oifde.traceAdvice.000006': 'Póngase en contacto con el administrador del sistema para comprobar si la lógica del elemento del diagrama es correcta.',
  'oifde.traceAdvice.000007': 'Póngase en contacto con el administrador del sistema para comprobar si la lógica del elemento del diagrama es correcta.',
  'oifde.traceAdvice.000008': 'Póngase en contacto con el administrador del sistema para comprobar el sistema.',
  'oifde.traceAdvice.000009': 'Compruebe si la salida del elemento de diagrama actual tiene ramas sin procesar. Se recomienda que todos los elementos del diagrama tengan una rama incondicional predeterminada.',
  'oifde.traceAdvice.000010': 'Compruebe si el elemento del diagrama de transferencia de flujo está configurado correctamente.',
  'oifde.traceAdvice.000011': 'Póngase en contacto con el administrador del sistema para comprobar el sistema.',
  'oifde.traceAdvice.000012': 'Compruebe si la sintaxis configurada para la expresión es correcta y si las variables de la expresión tienen valores.',
  'oifde.traceAdvice.000013': 'Compruebe si la interfaz de terceros es normal.',
  'oifde.traceAdvice.000014': 'Compruebe el elemento del diagrama de respuesta para asegurarse de que al menos una de las plantillas de respuesta o variable de respuesta tiene un valor.',
  'oifde.traceAdvice.000015': 'Compruebe si la plantilla SMS está configurada para el elemento de diagrama de envío de SMS.',
  'oifde.traceAdvice.000016': 'Compruebe el contenido y la configuración de idioma en la plantilla de recursos, el idioma integrado en la variable global y si se asigna un nuevo valor al idioma en la orquestación de flujo. El idioma utilizado para la reproducción debe configurarse en la plantilla de recursos.',
  'oifde.traceAdvice.000017': 'Compruebe el contenido y la configuración de idioma en la plantilla de recursos, el idioma integrado en la variable global y si se asigna un nuevo valor al idioma en la orquestación de flujo. El idioma utilizado para la reproducción debe configurarse en la plantilla de recursos.',
  'oifde.traceAdvice.000018': 'Compruebe si el elemento del diagrama de transferencia de flujo está configurado correctamente.',
  'oifde.traceAdvice.000019': 'Compruebe si el código de acceso transferido por la interfaz de diálogo es normal y existe en el sistema.',
  'oifde.traceAdvice.000020': 'Compruebe si el estado del tenant es normal.',
  'oifde.traceAdvice.000021': 'Compruebe si la aplicación de terceros o IVR envía repetidamente una solicitud de inicialización con el mismo callid</b>.',
  'oifde.traceAdvice.000022': 'Compruebe si el elemento de diagrama compuesto es normal y existe en el sistema.',
  'oifde.traceAdvice.000023': 'Póngase en contacto con el administrador del sistema para comprobar el sistema.',
  'oifde.traceAdvice.000024': 'Verifique la información de error de cada elemento del diagrama y rectifique los errores en consecuencia.',
  'oifde.traceAdvice.000025': 'Póngase en contacto con el administrador del sistema para comprobar el sistema.',
  'oifde.traceAdvice.000026': 'El flujo de ejecución es anormal. Primero debe solicitar que se invoque la interfaz de inicialización.',
  'oifde.traceAdvice.000028': 'Compruebe si el flujo es normal.',
  'oifde.traceAdvice.000029': 'Compruebe si se ha configurado una salida de nodo para el elemento de diagrama.',
  'oifde.traceAdvice.000030': 'Póngase en contacto con el administrador del sistema para comprobar si la configuración del elemento del diagrama es correcta.',
  'oifde.traceAdvice.000031': 'Póngase en contacto con el administrador del sistema para comprobar el sistema.',
  'oifde.traceAdvice.000032': 'Verifique la información de error de cada elemento del diagrama y rectifique los errores en consecuencia.',
  'oifde.traceAdvice.000033': 'Compruebe si la interfaz para la solicitud de cuelgue envía el identificador de llamada incorrecto</b>.',
  'oifde.traceAdvice.000034': 'Compruebe si el elemento del diagrama de finalización de llamada está configurado correctamente para el flujo.',
  'oifde.traceAdvice.000035': 'Compruebe si la entrada auth_token es correcta.',
  'oifde.traceAdvice.000036': 'Compruebe si la entrada auth_token es correcta.',
  'oifde.traceAdvice.000037': 'Compruebe si la entrada auth_token es correcta.',
  'oifde.traceAdvice.000038': 'Póngase en contacto con el administrador del sistema para comprobar el sistema.',
  'oifde.traceAdvice.000039': '1. Compruebe si la dirección TUC y el puerto configurados en el ODFS son normales. 2. Compruebe si los servicios en cada nodo TUC son normales.',
  'oifde.traceAdvice.000040': 'Compruebe si el procedimiento almacenado es normal.',
  'oifde.traceAdvice.000041': 'Compruebe si el procedimiento almacenado es normal.',
  'oifde.traceAdvice.000044': 'Póngase en contacto con el administrador del sistema para agregarlo a la lista de confianza.'
}
