export default {
  'ccivr.ivrvoice.play.error': '加载资源异常，请检查相关配置',
  'ccivr.ivrvoice.play.voicetimelimit': '试听音频较长，请减少文本内容后重新尝试试听效果。',
  'ccivr.ivr.ivrflow.name': '名称',
  'ccivr.ivr.ivrflow.type': '类型',
  'ccivr.ivr.ivrflow.updateTime': '更新时间',
  'ccivr.ivr.ivrflow.state': '状态',
  'ccivr.ivr.ivrflow.currentHandler': '更新者帐号',
  'ccivr.ivr.ivrflow.operate': '操作',
  'ccivr.ivr.ivrflow.operate.edit': '编辑',
  'ccivr.ivr.ivrflow.operate.delete': '删除',
  'ccivr.ivr.ivrflow.operate.add': '添加',
  'ccivr.ivr.ivrflow.operate.release': '发布',
  'ccivr.ivr.ivrflow.operate.ensurerelease': '发布确认',
  'ccivr.ivr.ivrflow.operate.ensurerelease.info': '确认发布吗？',
  'ccivr.ivr.ivrflow.operate.release.success': '发布成功！',
  'ccivr.ivr.ivrflow.operate.release.fail': '发布失败！',
  'ccivr.ivr.ivrflow.operate.release.needexecptionhanding': '请配置并正式发布一个异常处理流程！',
  'ccivr.ivr.ivrflow.operate.release.cancelexecptionhanding': '请取消租户下其他流程发布状态！',
  'ccivr.ivr.ivrflow.operate.cancelrelease': '取消发布',
  'ccivr.ivr.ivrflow.operate.queryInfo': '查看',
  'ccivr.ivr.ivrflow.operate.ensurecancelrelease': '取消发布确认',
  'ccivr.ivr.ivrflow.operate.ensurecancelrelease.info': '确认取消发布吗？',
  'ccivr.ivr.ivrflow.operate.cancelrelease.success': '取消发布成功！',
  'ccivr.ivr.ivrflow.operate.cancelrelease.fail': '取消发布失败！',
  'ccivr.ivrflow.operate.replace': '替换',
  'ccivr.ivrflow.operate.ensurereplace': '替换确认',
  'ccivr.ivrflow.operate.ensurereplace.info': '确认替换已发布的流程吗？',
  'ccivr.ivrflow.operate.replace.success': '替换成功！',
  'ccivr.ivrflow.operate.replace.fail': '替换失败！',
  'ccivr.ivrflow.cancelrelease.referenced': '该流程已被接入码引用！',
  'ccivr.ivrflow.cancelrelease.referenced.by.satisfaction.survey': '该流程已被满意度调查配置引用，不能取消发布！',
  'ccivr.ivrflow.cancelrelease.referenced.by.idauth.survey': '该流程已被身份验证配置引用，不能取消发布！',
  'ccivr.ivrflow.cancelrelease.error': '该流程已被技能队列引用！',
  'ccivr.ivrflow.flowAccessCode': '流程接入码',
  'ccivr.ivrflow.unlock': '解锁',
  'ccivr.ivrflow.unlock.confirm': '确认解锁编辑中状态吗？',
  'ccivr.ivrflow.unlock.success': '解锁状态成功！',
  'ccivr.ivrflow.unlock.fail': '解锁状态失败！',
  'ccivr.ivr.ivrflow.operate.ensureclone': '升级流程确认',
  'ccivr.ivr.ivrflow.operate.ensureclone.info': '确认升级流程吗？',
  'ccivr.ivr.ivrflow.operate.clone.success': '升级流程成功！',
  'ccivr.ivr.ivrflow.operate.clone.fail': '升级流程失败！',
  'ccivr.ivr.ivrflow.ivrname': '流程名称',
  'ccivr.ivr.ivrusage.ivrname': '引用场景',
  'ccivr.ivr.ivrusage.skillName': '技能队列名称',
  'ccivr.ivr.ivrflow.placeholder.ivrname': '请输入流程名称',
  'ccivr.ivr.ivrflow.ivrtype': '流程类型',
  'ccivr.ivr.ivrflow.mainflow': '主流程',
  'ccivr.ivr.ivrflow.subflow': '子流程',
  'ccivr.ivr.ivrflow.exceptionHandingFlow': '异常处理流程',
  'ccivr.ivr.ivrflow.new': '新建',
  'ccivr.ivr.ivrflow.edit': '编辑中',
  'ccivr.ivr.ivrflow.unreleased': '未发布',
  'ccivr.ivr.ivrflow.alreadyreleased': '正式发布',
  'ccivr.ivr.ivrflow.flowquote': '是否被引用',
  'ccivr.ivr.ivrflow.yes': '是',
  'ccivr.ivr.ivrflow.no': '否',
  'ccivr.ivr.ivrflow.selectivrflow': '请选择IVR流程！',
  'ccivr.ivr.ivrflow.batchdeleteconfim': '是否确定删除所有已选择IVR流程？请确保IVR流程未发布。',
  'ccivr.ivr.ivrflow.deleteconfim': '是否确定删除IVR流程？',
  'ccivr.ivrflow.error.deleteivrflow.released': '选择的IVR流程已发布，请先取消发布再删除',
  'ccivr.ivrflow.error.deleteivrflow.part.released': '选择的部分IVR流程已发布，请先取消发布在删除！',
  'ccivr.ivrflow.error.deleteivrflow.association': '该流程被其他流程关联，无法删除',
  'ccivr.ivrflow.error.deleteivrflow.fail': '流程不存在',
  'ccivr.ivrflow.createivrflow.mainfail': '主流程已经存在，新增失败！',
  'ccivr.ivrflow.createivrflow.exceptionfail': '异常处理流程已经存在，新增失败！',
  'ccivr.ivrflow.createivrflow.sameivrflowname': '该流程名称已经存在！',
  'ccivr.ivrflow.createivrflow.fail': '新增失败！',
  'ccivr.ivr.ivrflow.filter': '筛选',
  'ccivr.ivr.ivrflow.filtercondition': '筛选条件',
  'ccivr.ivrflow.updateivrinfo.success': '修改IVR流程属性信息成功！',
  'ccivr.ivrflow.updateivrinfo.fail': '修改IVR流程属性信息失败！',
  'ccivr.ivrflow.updateivrinfo.editing': '该流程正在编辑中，禁止修改！',
  'ccivr.ivrflow.updateivrinfo.released': '该流程已发布，请先取消发布在修改属性！',
  'ccivr.ivr.ivrflow.version': '版本号',
  'ccivr.ivr.ivrflow.property': '属性',
  'ccivr.ivr.ivrflow.clone': '升级',
  'ccivr.ivrflow.ivrflowname.specialStrValidateFailed': '不允许包含特殊字符且长度不超过50字符',
  'ccivr.ivrflow.ivrflowname.specialStrValidateFailed128': '不允许包含特殊字符且长度不超过128',
  'ccivr.ivr.ivrflow.operate.create.accesscode.fail': '发布流程同步新增odfs接入码失败！',
  'ccivr.ivrflow.cancelrelease.accesscode.delete.failed': '取消发布流程同步删除odfs接入码失败！',
  'ccivr.ivrflow.ivrflowtype.validateFailed': '流程类型错误',
  'ccivr.ivrflow.release.grayrule': '灰度发布',
  'ccivr.ivrflow.release.formal': '正式发布',
  'ccivr.ivrflow.grayrule.rule': '灰度规则',
  'ccivr.ivrflow.grayrule.grayinfo': '对于匹配灰度规则的号码，将执行该版本流程。<br />对于不匹配灰度规则的号码，将执行正式发布的版本流程。<br />若无正式发布版本流程，将执行失败。',
  'ccivr.ivrflow.release.formalinfo': '正式发布后，所有号码都将执行该版本流程。<br />如果需要对该流程进行修改，需要升级一个新版本，在该版本上进行修改。',
  'ccivr.ivrflow.grayrule.rulename': '规则名',
  'ccivr.ivrflow.grayrule.numrange': '号段范围',
  'ccivr.ivrflow.grayrule.bindingflow': '已绑定的流程：',
  'ccivr.ivrflow.grayrule.name': '规则名称',
  'ccivr.ivrflow.grayrule.beginnum': '起始号码',
  'ccivr.ivrflow.grayrule.endnum': '结束号码',
  'ccivr.ivrflow.grayrule.inputrulename': '请输入规则名称',
  'ccivr.ivrflow.grayrule.inputbeginnum': '例如:12366668888',
  'ccivr.ivrflow.grayrule.inputendnum': '结束号码必须大于或等于起始号码',
  'ccivr.ivrflow.grayrule.inputendlength': '起始号码和结束号码长度必须保持一致',
  'ccivr.ivrflow.grayrule.selectgrayrule': '请选择灰度规则',
  'ccivr.ivrflow.grayrule.existgrayflow': '已存在灰度发布版本！',
  'ccivr.ivrflow.grayrule.editsuccess': '修改成功！',
  'ccivr.ivrflow.grayrule.info': '详情',
  'ccivr.ivrflow.variable.settings': '变量设置',
  'ccivr.ivrflow.variable.selectvariable': '请选择变量！',
  'ccivr.ivrflow.variable.typeerror': '变量类型不一致！',
  'ccivr.ivr.voicereview.choosetenantspace': '请选择所属租户',
  'ccivr.ivr.voicereview.choosevoicetype': '类型',
  'ccivr.ivr.voicereview.waitreview': '待审核',
  'ccivr.ivr.voicereview.search': '查询',
  'ccivr.ivr.voicereview.reset': '重置',
  'ccivr.ivr.voicereview.refresh': '刷新',
  'ccivr.ivr.voicereview.tenantspace': '所属租户',
  'ccivr.ivr.voicereview.name': '名称',
  'ccivr.ivr.voicereview.filename': '文件名',
  'ccivr.ivr.voicereview.filetype': '类型',
  'ccivr.ivr.voicereview.language': '语种',
  'ccivr.ivr.voicereview.scene': '使用场景',
  'ccivr.ivr.voicereview.uploadtime': '上传时间',
  'ccivr.ivr.voicereview.upload': '上传了',
  'ccivr.ivr.voicereview.reviewstatus': '审核状态',
  'ccivr.ivr.voicereview.reviewsuggestion': '审核意见',
  'ccivr.ivr.voicereview.voiceId': '语音标识',
  'ccivr.ivr.voicereview.operat': '操作',
  'ccivr.ivr.voicereview.play': '播放',
  'ccivr.ivr.voicereview.pass': '通过',
  'ccivr.ivr.voicereview.filter': '筛选',
  'ccivr.ivr.voicereview.refuse': '驳回',
  'ccivr.ivr.voicereview.voice': '语音',
  'ccivr.ivr.voicereview.text': '文本',
  'ccivr.ivr.voicereview.edit': '编辑',
  'ccivr.ivr.voicereview.nosubmit': '未提交',
  'ccivr.ivr.voicereview.passed': '已通过',
  'ccivr.ivr.voicereview.notpass': '未通过',
  'ccivr.ivr.voicereview.all': '所有',
  'ccivr.ivr.voicereview.vedio': '视频',
  'ccivr.ivr.voicereview.filePath': '文件路径：',
  'ccivr.ivr.voicereview.textContent': '文本内容',
  'ccivr.ivr.voicereview.reviewmethod': '语音资源审核方式',
  'ccivr.ivr.voicereview.manualreview': '人工审核',
  'ccivr.ivr.voicereview.autopass': '自动通过',
  'ccivr.ivr.voicereview.chinese': '中文',
  'ccivr.ivr.voicereview.english': '英文',
  'ccivr.ivr.voicereview.thailand': '泰语',
  'ccivr.ivr.voicereview.portuguese': '葡萄牙语',
  'ccivr.ivr.voicereview.spanish': '西班牙语',
  'ccivr.ivr.voicereview.french': '法语',
  'ccivr.ivr.voicereview.arabic': '阿拉伯语',
  'ccivr.ivr.voicereview.german': '德语',
  'ccivr.ivr.voicereview.language0': '中文',
  'ccivr.ivr.voicereview.language1': '英文',
  'ccivr.ivr.voicereview.language24': '泰语',
  'ccivr.ivr.voicereview.language17': '葡萄牙语',
  'ccivr.ivr.voicereview.language20': '西班牙语',
  'ccivr.ivr.voicereview.language7': '法语',
  'ccivr.ivr.voicereview.language81': '阿拉伯语',
  'ccivr.ivr.voicereview.language8': '德语',
  'ccivr.ivr.voiceadvice.close': '关闭',
  'ccivr.ivr.voiceadvice.audition': '试听',
  'ccivr.ivr.voiceadvice.cancel': '取消',
  'ccivr.ivr.voiceadvice.confirm': '确认',
  'ccivr.ivr.voiceadvice.currentdefault': '当前默认',
  'ccivr.ivr.voiceadvice.setdefault': '设为默认',
  'ccivr.ivr.voiceadvice.submit': '提交',
  'ccivr.ivr.voiceadvice.lengthError': '长度不能超过100',
  'ccivr.ivr.variable.int': '整型',
  'ccivr.ivr.variable.string': '字符串',
  'ccivr.ivr.variable.name': '变量名称',
  'ccivr.ivr.variable.defaultValue': '缺省值',
  'ccivr.ivr.variable.management': '变量管理',
  'ccivr.ivr.variable.addvariable': '新增变量',
  'ccivr.ivr.variable.modifyvariable': '修改变量',
  'ccivr.ivr.variable.idisnull': 'ID信息为空！',
  'ccivr.ivr.variable.nameisnull': '名字信息为空！',
  'ccivr.ivr.variable.typeisnull': '类型信息为空！',
  'ccivr.ivr.variable.variableisreferenced': '变量被流程引用',
  'ccivr.ivr.variable.deleteselectvariable': '删除变量',
  'ccivr.ivr.variable.willdeleteselectvariable': '确定删除所选的变量吗？',
  'ccivr.ivr.variable.descvalueerror': '描述含有特殊字符，请修改！',
  'ccivr.ivr.variable.defaultvalueerror': '缺省值含有特殊字符，请修改！',
  'ccivr.ivr.variable.errorspecial': '类型含有特殊字符，请修改！',
  'ccivr.ivr.variable.desclimit': '描述不得超过120字！',
  'ccivr.ivr.variable.defaultlimit': '缺省值不得超过120字！',
  'ccivr.ivr.variable.defaulttypeerror': '缺省值类型跟变量类型不匹配！',
  'ccivr.ivr.variable.textvaluelimit': '文本不得超过120字!',
  'ccivr.ivr.variable.namevaluerule': '名称含有特殊字符或中文，请修改！',
  'ccivr.ivr.element.namecheck': '名称含有特殊字符，请修改！',
  'ccivr.ivr.variable.specialcharacters': '包含特殊字符或中文！',
  'ccivr.ivr.voiceupload.defaultNoDe': '默认语种不能删除.',
  'ccivr.ivr.voiceupload.onlyOneData': '至少保留一条语种记录.',
  'ccivr.ivr.voiceupload.texttovoice': '输入的文字将被自动转化成语音',
  'ccivr.ivr.voiceupload.selectvoice': '请选择音频文件',
  'ccivr.ivr.voiceupload.selectvideo': '请选择视频文件',
  'ccivr.ivr.voiceupload.recording': '录音',
  'ccivr.ivr.voiceupload.stoprecording': '停止',
  'ccivr.ivr.voiceupload.playrecord': '放音',
  'ccivr.ivr.voiceupload.playtype': '放音模式',
  'ccivr.ivr.voiceupload.languagemanage': '语种管理',
  'ccivr.ivr.voiceupload.choosestatus': '请选择审核状态',
  'ccivr.ivr.voiceupload.new': '新增',
  'ccivr.ivr.voiceupload.delete': '删除',
  'ccivr.ivr.voiceupload.fileovermax': 'IVR语音管理数量超过最大。',
  'ccivr.ivr.voiceupload.deletefail': '删除失败！',
  'ccivr.ivr.voiceupload.deleteodfsivrfail': '删除失败，资源已被引用',
  'ccivr.ivr.voiceupload.deletereferenceivrfail': '删除失败，该语音已被引用',
  'ccivr.ivr.voiceupload.submit': '提交',
  'ccivr.ivr.voiceupload.textcontent': '文本内容',
  'ccivr.ivr.voiceupload.chinese': '中文',
  'ccivr.ivr.voiceupload.english': '英文',
  'ccivr.ivr.voiceupload.pleaseuploadfile': '文件为空，请导入文件！',
  'ccivr.ivr.voiceupload.pleaserecording': '请录音之后上传！',
  'ccivr.ivr.voiceupload.fileformatwrong': '类型选择错误！',
  'ccivr.ivr.voiceupload.formatwrong': '文件格式错误，请导入wav，mp3格式的文件！',
  'ccivr.ivr.voiceupload.fileheaderwrong': '该语音文件原格式不是wav、mp3，请更换其他语音文件上传',
  'ccivr.ivr.voiceupload.vedioheaderwrong': '该视频文件原格式不是3gp，请更换其他视频文件上传',
  'ccivr.ivr.voiceupload.vedioformatwrong': '文件格式错误，请导入3gp格式的文件！',
  'ccivr.ivr.voiceupload.vedionamewrong': '文件名称校验失败！',
  'ccivr.ivr.voiceupload.sizewrong': '文件超过2MB，请重新导入文件！',
  'ccivr.ivr.voiceupload.isconfdelete': '是否确认删除？',
  'ccivr.ivr.voiceupload.tipformat': '支持mp3，wav格式，文件大小不超过12M。',
  'ccivr.ivr.voiceupload.tipformat1M': '支持mp3，wav格式，文件大小不超过1M。',
  'ccivr.ivr.voiceupload.amountLimit5': '该场景最多可以添加5个语音文件。',
  'ccivr.ivr.voiceupload.tipvideoformat': '目前仅支持3gp格式的视频文件，文件大小不得超过5M。',
  'ccivr.ivr.voiceupload.inputlanguageerror': '语种不存在！',
  'ccivr.ivr.voiceupload.tipvideomodel': '您可以同时设置不同语种的视频文件或视频文本。视频播放时将根据用户的语种选择自动切换播放对应的视频文件.',
  'ccivr.ivr.voiceupload.tipmodel': '您可以同时设置不同语种的放音文件或放音文本（最大长度不可超过1024字节）。放音时将根据用户的语种选择自动切换播放对应的放音文件。',
  'ccivr.ivr.voiceupload.audioformat': '音频文件格式:8bit\, 8000Hz\, 64kbps\, 单声道.',
  'ccivr.ivr.voiceupload.audiotypefailed': '音频文件格式非ALAW.',
  'ccivr.ivr.voiceupload.audiochannelfailed': '音频文件非单声道文件.',
  'ccivr.ivr.voiceupload.audiosampleratefailed': '音频文件采样速率非8000HZ.',
  'ccivr.ivr.voiceupload.audiosamplebitfailed': '音频文件帧数非8BIT.',
  'ccivr.ivr.voiceupload.VoiceNotificationtipmodel': '您可以同时设置不同语种的放音文件或放音文本（最大长度不可超过500字节）。放音时将根据用户的语种选择自动切换播放对应的放音文件。',
  'ccivr.ivr.voiceupload.VoiceNotificationTextDes': '模板内容不能以变量开头;单个变量长度不超过32字节\,如果单个变量长度超过32字节\,建议拆分成多变量;\n支持的变量格式有:{\'$\'}{\'{\'}TXT_数字{\'}\'}:表示变量为定义最大长度的字符串\,数字表示该字符串的最大长度;\n{\'$\'}{\'{\'}NUM_数字{\'}\'}:表示变量只能为数字(0-9)的组合\,数字表示该变量的最大个数\,如{\'$\'}{\'{\'}NUM_6{\'}\'}表示最大6个数字的组合;\n{\'$\'}{\'{\'}DATE{\'}\'}表示变量为日期\,格式要求为"YYYY/MM/DD";\n{\'$\'}{\'{\'}TIME{\'}\'}表示变量为时间\,支持两种格式\,格式为"HH:MM"和"HH:MM:SS";\n{\'$\'}{\'{\'}DATE{\'}\'}和{\'$\'}{\'{\'}TIME{\'}\'}必须用逗号隔开。',
  'ccivr.ivr.voiceupload.VoiceNotificationTextrule': '更多语音通知模板规则请参考输入框内 i 符号查看',
  'ccivr.ivr.voiceupload.auditionTextlimit': '试听文本内容不能超过300字节',
  'ccivr.ivr.voiceupload.audiofilereadfailed': '读取音频文件属性失败.',
  'ccivr.ivr.voiceupload.authvalidatefailed': '文件上传权限校验失败.',
  'ccivr.ivr.voiceupload.fileinjectionerror': '校验文件注入风险异常.',
  'ccivr.ivr.voiceupload.fileioerror': '文件IO异常.',
  'ccivr.ivr.voiceupload.tmpformaterror': '临时文件类型错误.',
  'ccivr.ivr.voiceupload.fileformatconverterror': '文件格式转换出错，或ffmpeg配置有误。',
  'ccivr.ivr.voiceupload.exceedLimit': 'IVR文件数量超出限制。',
  'ccivr.ivr.tracelog.traceConfig': '接入码跟踪配置',
  'ccivr.ivr.tracelog.accessCode': '流程接入码',
  'ccivr.ivr.tracelog.addAccessCode': '添加接入码',
  'ccivr.ivr.tracelog.deleteconfirminfocode': '是否删除当前接入码：',
  'ccivr.ivr.tracelog.selecttipcode': '请选择接入码!',
  'ccivr.ivr.voiceupload.checkuploadparmfailed': '文件上传参数校验失败.',
  'ccivr.ivr.voiceupload.checkcallcenterparamfailed': '呼叫中心数据校验失败.',
  'ccivr.ivr.voiceupload.uploadfiletosftpfailed': '文件上传至sftp失败.',
  'ccivr.ivr.voiceupload.opreationdbfailed': '更新数据库IVR语音信息失败.',
  'ccivr.ivr.voiceupload.referenceFlow': '引用流程信息',
  'ccivr.ivr.voiceupload.ivrVoiceUsage': '引用信息',
  'ccivr.ivr.voiceupload.referenceText': '语音通知文本信息',
  'ccivr.ivr.voiceupload.queryReferenceFlow': '查看',
  'ccivr.ivr.voiceupload.query': '详情',
  'ccivr.ivr.voiceupload.selectLangNull': '未选择语种，请重新选择！',
  'ccivr.ivr.voiceupload.selectLangRe': '当前语种已选择，请重新选择语种！',
  'ccivr.ivr.voiceedit.idisnull': 'id为空',
  'ccivr.ivr.voiceedit.deleteoldfilefailed': '删除原表数据中的文件失败！',
  'ccivr.ivr.voiceedit.statusisnull': '状态参数值为空',
  'ccivr.ivr.voiceedit.statuserror': '状态参数错误',
  'ccivr.ivr.voiceedit.filenameisnull': '文件名称为空',
  'ccivr.ivr.voiceedit.filenameformaterror': '文件名称格式错误',
  'ccivr.ivr.voiceedit.filenamenull': '文件名称内容为空',
  'ccivr.ivr.voiceedit.filenamecheckfail': '文件名称校验失败！',
  'ccivr.ivr.voiceedit.querycallcenterfailed': '查询呼叫中心SFTP信息失败！',
  'ccivr.ivr.voiceedit.sftpinfoisnull': '呼叫中心SFTP信息为空',
  'ccivr.ivr.voiceedit.passwdisnull': '呼叫中心SFTP密码获取失败！',
  'ccivr.ivr.voiceedit.pathisnull': '呼叫中心SFTP路径为空',
  'ccivr.ivr.voiceedit.pathcheckfailed': '呼叫中心SFTP路径校验失败！',
  'ccivr.ivr.voiceedit.choosefile': '选择文件',
  'ccivr.ivr.voiceedit.cancel': '取消',
  'ccivr.ivr.voiceedit.import': '导入',
  'ccivr.ivr.voiceedit.inputmore': '输入不超过',
  'ccivr.ivr.voiceedit.addsuccess': '新增成功！',
  'ccivr.ivr.voiceedit.addfail': '新增失败！',
  'ccivr.ivr.voiceedit.deletesuccess': '删除成功！',
  'ccivr.ivr.voiceedit.tip': '提示',
  'ccivr.ivr.voiceedit.deleteodfsivrfail': '删除失败，资源已被引用',
  'ccivr.ivr.voiceedit.editsuccess': '编辑成功！',
  'ccivr.ivr.voiceedit.editfail': '编辑失败！',
  'ccivr.ivr.voiceedit.updatefail': '更新失败！',
  'ccivr.ivr.voiceedit.inputtext': '请输入文本',
  'ccivr.ivr.message.uploadimport.success': '新增成功！！',
  'ccivr.ivr.success.import': '新增成功！',
  'ccivr.ivr.pageurls.errorsave': '错误',
  'ccivr.ivr.description.limit': '文本描述不得超过1024字节',
  'ccivr.ivr.description.voiceNotificationlimit': '文本描述不得超过500字节',
  'ccivr.ivr.description.variableerror': '模板变量格式错误',
  'ccivr.ivr.description.variablelimit': '模板变量不得超出10个',
  'ccivr.ivr.description.containspecialcharacter': '模板包含特殊字符 ~',
  'ccivr.ivr.voice.filechoose': '语音文件选择',
  'ccivr.ivr.voice.vediochoose': '视频文件选择',
  'ccivr.ivr.changtovoice': '文字转语音',
  'ccivr.ivr.voice.scene': '使用场景',
  'ccivr.ivr.voice.speed': '语速',
  'ccivr.ivr.voice.volume': '音量',
  'ccivr.ivr.voice.pitch': '音调',
  'ccivr.ivr.voice.voicename': '音色',
  'ccivr.ivr.voice.scene.note': '提示音',
  'ccivr.ivr.voice.scene.error': '故障音',
  'ccivr.ivr.voice.scene.questionnaires': '问卷',
  'ccivr.ivr.voice.scene.beforeReportWorkNo': '报工号前',
  'ccivr.ivr.voice.scene.afterReportWorkNo': '报工号后',
  'ccivr.ivr.voice.fileupload': '文件上传',
  'ccivr.ivr.voice.recordupload': '在线录音',
  'ccivr.ivr.voice.clicktorecord': '点击录制',
  'ccivr.ivr.voice.clicktostop': '点击停止',
  'ccivr.ivr.voice.recordtimeout.fail': '录音超时',
  'ccivr.ivr.voice.recordtimeout.failmes': '录音最大时长为2分钟，请重新录音',
  'ccivr.ivr.voice.exception.recordfail': '录音异常',
  'ccivr.ivr.voice.exception.recordfailmessage': '录音异常，请确认有无麦克风权限',
  'ccivr.ivr.success': '成功',
  'ccivr.ivr.fail': '失败',
  'ccivr.ivr.textcontent.notnull': '文本信息不能为空！',
  'ccivr.ivr.name.errorspecial': '名称含有特殊字符，请修改',
  'ccivr.ivr.name.existence': '名称已存在，请修改',
  'ccivr.ivr.error.sftp': 'Sftp信息有误！',
  'ccivr.ivr.error.download': '从sftp服务器下载失败！',
  'ccivr.ivr.error.connect': '连接sftp服务器失败！',
  'ccivr.ivr.error.other': '下载失败！',
  'ccivr.ivr.name.notnull': '名称不能为空！',
  'ccivr.ivr.name.toolong': '名称过长，请重新输入！',
  'ccivr.ivr.error.uncommitted': '系统管理员不能播放未提交的文件！',
  'ccivr.ivr.error.overpower': '租户越权！',
  'ccivr.ivr.error.advicelimit': '审核意见不得超过100字！',
  'ccivr.ivr.error.paramlimit': '参数总体长度不超过4000字节!',
  'ccivr.ivr.error.paramNumlimit': '参数总数量不超过16个!',
  'ccivr.ivr.bussinessinvoke.bussinessinvoke': '接口配置',
  'ccivr.ivr.bussinessinvoke.interfacename': '接口名称',
  'ccivr.ivr.bussinessinvoke.URL': '请求URL',
  'ccivr.ivr.bussinessinvoke.interfacedesc': '接口描述',
  'ccivr.ivr.bussinessinvoke.updateinterface': '编辑接口',
  'ccivr.ivr.bussinessinvoke.createinterface': '创建新接口',
  'ccivr.ivr.bussinessinvoke.basicconfig': '基本配置',
  'ccivr.ivr.bussinessinvoke.interfacetype': '接口类型',
  'ccivr.ivr.bussinessinvoke.int': '整型',
  'ccivr.ivr.bussinessinvoke.string': '字符串',
  'ccivr.ivr.bussinessinvoke.float': '浮点数',
  'ccivr.ivr.bussinessinvoke.long': '长整型',
  'ccivr.ivr.bussinessinvoke.savesuccess': '保存成功！',
  'ccivr.ivr.bussinessinvoke.saveserror': '保存失败！',
  'ccivr.ivr.bussinessinvoke.validate': '校验失败！',
  'ccivr.ivr.bussinessinvoke.validatemessage': '存在同名参数',
  'ccivr.ivr.bussinessinvoke.error': '错误',
  'ccivr.ivr.bussinessinvoke.errormessage': '请选择要删除的接口',
  'ccivr.ivr.bussinessinvoke.save': '保存',
  'ccivr.ivr.bussinessinvoke.cancel': '取消',
  'ccivr.ivr.bussinessinvoke.tip': '请输入值或变量',
  'ccivr.ivr.tracelog.title': '流程轨迹',
  'ccivr.ivr.tracelog.callingNumber': '主叫号码',
  'ccivr.ivr.tracelog.callingNumberplaceHolder': '请输入用户号码',
  'ccivr.ivr.tracelog.addcallingnumber': '添加主叫号码',
  'ccivr.ivr.tracelog.deleteconfirminfo': '是否删除当前主叫号码：',
  'ccivr.ivr.tracelog.selecttip': '请选择主叫号码!',
  'ccivr.ivr.tracelog.index': '序号',
  'ccivr.ivr.tracelog.callid': '呼叫标识',
  'ccivr.ivr.tracelog.calledNumber': '被叫号码',
  'ccivr.ivr.tracelog.flowName': '流程名称',
  'ccivr.ivr.tracelog.createTime': '进入流程时间',
  'ccivr.ivr.tracelog.errorInfo': '错误信息',
  'ccivr.ivr.tracelog.callInfo': '呼叫信息',
  'ccivr.ivr.tracelog.cellType': '图元类型',
  'ccivr.ivr.tracelog.nodeName': '图元名称',
  'ccivr.ivr.tracelog.result': '执行结果',
  'ccivr.ivr.tracelog.transitionOn': '出口分支',
  'ccivr.ivr.tracelog.actionParams': '图元关键参数',
  'ccivr.ivr.tracelog.errorpop': '查询失败！',
  'ccivr.ivr.tracelog.addFailed': '增加失败!',
  'ccivr.ivr.tracelog.deleteFailed': '删除失败！',
  'ccivr.ivr.tracelog.addSuccess': '增加成功！',
  'ccivr.ivr.tracelogprompt': '此操作将影响系统性能，此配置将于{0}小时后自动删除，是否继续？',
  'ccivr.ivr.tracelog.deleteSuccess': '删除成功！',
  'ccivr.ivr.tracelog.maxNumberValidate': '主叫号码数量超过上限！',
  'ccivr.ivr.tracelog.sameNumberValidate': '已经存在相同的主叫号码！',
  'ccivr.ivr.tracelog.calledNumbererror': '主叫号码校验失败！',
  'ccivr.ivr.tracelog.result.finshed': '完成',
  'ccivr.ivr.tracelog.result.transsuccess': '成功',
  'ccivr.ivr.tracelog.result.transfailed': '失败',
  'ccivr.ivr.tracelog.result.recordend': '录音成功！',
  'ccivr.ivr.tracelog.result.playover': '放音成功！',
  'ccivr.ivr.tracelog.result.timeout': '超时',
  'ccivr.ivr.tracelog.result.syserr': '系统错误',
  'ccivr.ivr.tracelog.result.hangup': '挂机',
  'ccivr.ivr.tracelog.result.default': '默认',
  'ccivr.ivr.languageid.repeat.fail': 'languageId不存在',
  'ccivr.ivr.ivrtextexception.fail': 'ivr新增文本失败！',
  'ccivr.ivr.illegal.fail': '非法参数',
  'ccivr.ivr.type.fail': '上传类型错误',
  'ccivr.ivr.delete.fail': '删除失败',
  'ccivr.ivr.create.exception': '新增失败',
  'ccivr.nms.interface.urltip': '请使用http/https开头的url',
  'ccivr.nms.interface.certFile': '证书文件',
  'ccivr.nms.label.selectCert': '选择证书',
  'ccivr.common.warning.message': '请选择一条数据',
  'ccivr.nms.label.inputCertCode': '请输入证书编码',
  'ccivr.nms.label.certCode': '证书编码',
  'ccivr.nms.label.certScene': '证书使用场景',
  'ccivr.nms.label.certType': '证书类型',
  'ccivr.nms.interface.warn': '请注意，当前输入的接口所使用的网络协议不是安全协议，如确定使用，可能存在安全风险',
  'ccivr.nms.interface.whitelistwarn': '请注意，接口URL中的IP、端口或域名不在白名单中，需要联系系统管理员添加后才能正常调用',
  'ccivr.nms.interface.paramvalidate': '参数应该只包含数字，英文字符或_-',
  'ccivr.nms.interface.paramKeyValidate': '参数名称请不要使用内置变量（callId，businessReqId，callSorId，currentTime）定义的名称，否则会导致赋值失效',
  'ccivr.nms.interface.outparamvalidate': '参数应该只包含数字，英文字符或._-',
  'ccivr.nms.interface.nmsexception': '连接ODFS失败！请检查相关配置',
  'ccivr.nms.interface.whitelistcheckfailed': 'URL中的IP和端口未添加白名单，需要联系系统管理员增加白名单后才能正常使用，是否继续？',
  'ccivr.nms.varibalesselect.globalvariables': '全局变量',
  'ccivr.nms.varibalesselect.processvaribales': '流程变量',
  'ccivr.nms.varibalesselect.sysvariables': '系统变量',
  'ccivr.nms.varibalesselect.selectvariables': '选择变量',
  'ccivr.nms.playcollectservice.playset.reset': '重置',
  'ccivr.nms.playservice.placeholder': '放音',
  'ccivr.nms.playservice.inputtitle': '语音提示',
  'ccivr.nms.playservice.chose': '播放内容从已上传并审核过的音频中选择',
  'ccivr.nms.playservice.chosetts': '播放内容从已上传并审核过的TTS文本中选择，需拥有TTS资源',
  'ccivr.nms.playservice.chosevideo': '播放内容从已上传并审核过的视频中选择',
  'ccivr.nms.playservice.playfile': '放音文件',
  'ccivr.nms.playservice.TTS': 'TTS放音',
  'ccivr.nms.playservice.interrupt': '任意键打断播放',
  'ccivr.nms.brach': '分支',
  'ccivr.nms.brach.label': '条件选择',
  'ccivr.nms.brach.duplicatebranch': '已经存在相同的分支！请重新选择',
  'ccivr.nms.LanguageService.placeholder': '语种选择',
  'ccivr.nms.LanguageService.label': '选择语种',
  'ccivr.nms.ChangeMediaService.placeholder': '音视频切换',
  'ccivr.nms.ChangeMediaService.label': '媒体类型',
  'ccivr.nms.userLevelService.placeholder': '设置用户级别',
  'ccivr.nms.userLevelService.label': '用户级别',
  'ccivr.nms.userLevelService.example': '例：1-14或FLOW.变量名',
  'ccivr.nms.answerService.placeholder': '应答',
  'ccivr.nms.ringService.placeholder': '振铃',
  'ccivr.nms.ringService.videomodel': '视频模式',
  'ccivr.nms.ringService.sendonly': '只发送',
  'ccivr.nms.ringService.recvonly': '只接收',
  'ccivr.nms.ringService.sendrecv': '接收发送',
  'ccivr.nms.questionnaireService.placeholder': '问卷',
  'ccivr.nms.questionnaireService.surveyname': '问卷名称',
  'ccivr.nms.questionnaireService.question': '问卷题目',
  'ccivr.nms.questionnaireService.info': '问卷图元使用场景是自动外呼任务进行问卷调查时需要关联IVR问卷流程',
  'ccivr.nms.process.samenamevalidate': '参数名称重复：',
  'ccivr.nms.process.interfaceerr': '接口错误，请查看相关日志',
  'ccivr.nms.process.saveerror': '保存失败，请检查流程节点及连线',
  'ccivr.nms.process.saveerror.connection': '保存失败，存在无效连线',
  'ccivr.nms.process.saveerror.noexistend': '保存失败，至少存在一个结束图元',
  'ccivr.nms.process.saveerror.noexistconnection': '保存失败，非结束、转子流程图元必须要有一个默认连线',
  'ccivr.nms.process.saveerror.unSucctransfer': '保存失败，转移图元必须有转移成功连线',
  'ccivr.nms.process.saveerror.menukeynoconnection': '保存失败，请检测菜单配置各个按键有无对应一条连线',
  'ccivr.nms.process.saveerror.noselectType': '请选择查询类型',
  'ccivr.nms.process.saveerror.noresult': '必填项为空',
  'ccivr.nms.process.saveerror.select': '请选择切换类型',
  'ccivr.nms.process.saveerror.dataerror': '图元数据校验失败！',
  'ccivr.nms.process.saveerror.existnullentity': '存在空数据图元',
  'ccivr.nms.process.saveerror.alreadyreleased': '流程状态异常,请重新打开画布',
  'ccivr.nms.process.saveerror.flownoexist': '流程不存在',
  'ccivr.nms.modulename': '图元名称',
  'ccivr.nms.add': '新增',
  'ccivr.nms.delete': '删除',
  'ccivr.nms.businessinvokeservice.title': '接口调用',
  'ccivr.nms.businessinvokeservice.selectservice': '选择接口',
  'ccivr.nms.businessinvokeservice.servicename': '接口名称',
  'ccivr.nms.businessinvokeservice.overtime': '超过时间',
  'ccivr.nms.businessinvokeservice.second': '秒',
  'ccivr.nms.businessinvokeservice.info1': '可用接口需要在接口配置页面进行配置',
  'ccivr.nms.businessinvokeservice.info2': '若超出调用超时时间，则执行调用失败模块',
  'ccivr.nms.businessinvokeservice.inpuparam': '入参',
  'ccivr.nms.businessinvokeservice.paramname': '参数名称',
  'ccivr.nms.businessinvokeservice.value': '赋值',
  'ccivr.nms.businessinvokeservice.opertaion': '操作',
  'ccivr.nms.businessinvokeservice.outputparam': '出参',
  'ccivr.nms.businessinvokeservice.inputflowValidate': '输入内容有误，请修改',
  'ccivr.nms.process.isParameterisEncrypt': '是否加密',
  'ccivr.nms.process.parameterisEncrypt': '加密',
  'ccivr.ivr.error.releaseFailed': '问卷自动发布失败！',
  'ccivr.nms.playcollectservice.title': '放音收号',
  'ccivr.nms.playcollectservice.voiceprompts': '语音提示',
  'ccivr.nms.playcollectservice.voicefile': '放音文件',
  'ccivr.nms.playcollectservice.ttsvoice': '文本放音(TTS)',
  'ccivr.nms.playcollectservice.videofile': '视频文件',
  'ccivr.nms.playcollectservice.interrupt': '任意键打断',
  'ccivr.nms.playcollectservice.receivednum': '接收数字',
  'ccivr.nms.playcollectservice.tip1': '选择参数，用于保存用户的输入',
  'ccivr.nms.playcollectservice.tip2': '请选择您在流程信息中添加的字符串类型参数',
  'ccivr.nms.playcollectservice.collectwaittime': '收号等待时间',
  'ccivr.nms.playcollectservice.entercollectwaittime': '请输入收号等待时间',
  'ccivr.nms.playcollectservice.collectTimeout': '收号超时时间',
  'ccivr.nms.playservice.contentisnull': '放音内容不能为空',
  'ccivr.nms.playcollectservice.maxfailtimes': '最大失败次数',
  'ccivr.nms.playcollectservice.entermaxfailtimes': '请输入最大失败次数',
  'ccivr.nms.playcollectservice.failtitle': '收号失败放音',
  'ccivr.nms.playcollectservice.collectnumerror': '收号错误',
  'ccivr.nms.playcollectservice.collectnumtimeout': '收号超时',
  'ccivr.nms.playcollectservice.tip3': '收号时，需要用户输入“#”号键表示收号结束。请在语音中进行提示。(菜单配置图元收号无需按“#”号键)',
  'ccivr.nms.playcollectservice.tip4': '若用户停止输入时间超过设定的”收号等待时间“，将记录为一次失败，并自动执行“收号超时“，继续等待收号。',
  'ccivr.nms.playcollectservice.tip5': '若收号出错，将记录为一次失败，并自动执行”收号错误“，继续等待收号。',
  'ccivr.nms.playcollectservice.tip6': '收号失败放音可不选。未选择时，失败后将重新播放语音提示。',
  'ccivr.nms.playcollectservice.tip7': '当累计的失败次数超过”最大失败次数“，将执行通用的失败出口。',
  'ccivr.nms.playcollectservice.choosefile': '语音',
  'ccivr.nms.playcollectservice.collectTimeout.error': '无效的收号超时时间 (1-120)',
  'ccivr.nms.playcollectservice.enterCollectTimeout': '请输入收号的超时时间',
  'ccivr.nms.playcollectservice.changtovoice': '文字转语音',
  'ccivr.nms.playcollectservice.changtovideoO': '视频',
  'ccivr.nms.playcollectservice.onenumber': '一位收号',
  'ccivr.nms.playcollectservice.onenumbers': '多位收号',
  'ccivr.nms.playcollectservice.mulen': '接收最大位数',
  'ccivr.nms.playcollectservice.chooseparam': '保存用户输入',
  'ccivr.nms.playcollectservice.advancedconfig': '高级配置',
  'ccivr.nms.playcollectservice.close': '收起',
  'ccivr.nms.playcollectservice.required': '必填项',
  'ccivr.nms.menuname.specialStrValidateFailed': '长度不允许超过255字符',
  'ccivr.nms.tansferservice.timedPrompt': '您已长时间未保存，请及时保存。',
  'ccivr.nms.tansferservice.processLayout': '流程编排',
  'ccivr.nms.tansferservice.callForwarding': '呼叫转移',
  'ccivr.nms.tansferservice.moduleDescription': '模块描述',
  'ccivr.nms.tansferservice.processName': '请输入流程名称',
  'ccivr.nms.tansferservice.setTransfer': '转接设置',
  'ccivr.nms.tansferservice.transSkill': '客户来电将被转移到对应的技能队列.支持自定义参数.如{\'$\'}{\'{\'}FLOW.参数名称{\'}\'}',
  'ccivr.nms.tansferservice.transNumType': '转接号码可以为手机号码、固话或自定义参数．如{\'$\'}{\'{\'}FLOW.参数名称{\'}\'}',
  'ccivr.nms.tansferservice.transNum': '转接号码',
  'ccivr.nms.tansferservice.passingParameters': '传递参数',
  'ccivr.nms.tansferservice.parameter': '参数值',
  'ccivr.nms.tansferservice.operat': '操作',
  'ccivr.nms.tansferservice.add': '新增',
  'ccivr.nms.tansferservice.delete': '删除',
  'ccivr.nms.tansferservice.samenamevalidate': '传递参数名称重复',
  'ccivr.nms.tansferservice.transferZNIvr': '客户来电将被转移到对应的智能IVR',
  'ccivr.nms.tansferservice.transferZNIvrCode': '转智能IVR',
  'ccivr.nms.tansferservice.chooseZNIvr': '选择智能IVR',
  'ccivr.nms.tansferservice.record': '是否录音',
  'ccivr.nms.tansferservice.selectPath': '选择路径',
  'ccivr.nms.tansferservice.transAppointWorkNo': '客户来电将被转移到指定座席',
  'ccivr.nms.tansferservice.transferWorkNo': '转座席',
  'ccivr.nms.tansferservice.transInputWorkNo': '转接座席工号',
  'ccivr.nms.transInputWorkNo.sample': '例：101或{\'$\'}{\'{\'}FLOW.currentWorkNo{\'}\'}',
  'ccivr.nms.tansferservice.transCallingnum': '主叫号码',
  'ccivr.nms.tansferservice.sampleForCallingnum': '例：660001或{\'$\'}{\'{\'}FLOW.accessCode{\'}\'}',
  'ccivr.nms.tansferservice.mustbeaccesscode': '主叫号码必须是系统分配的接入码或自定义参数．如{\'$\'}{\'{\'}FLOW.参数名称{\'}\'}',
  'ccivr.nms.tansferservice.skillId': '技能队列',
  'ccivr.nms.tansferservice.queueindex': '排队位置',
  'ccivr.nms.tansferservice.queuenum': '排队人数',
  'ccivr.nms.tansferservice.onlineagent': '在线座席数',
  'ccivr.nms.tansferservice.estimateTime': '预计等待时间',
  'ccivr.nms.queryservice.estimateTime.prompte': '排队预计等待时间依靠队列模型计算，结果有一定的偏差，请合理使用',
  'ccivr.nms.tansferservice.transferLastAgent': '转音视频熟客',
  'ccivr.nms.tansferservice.transferLastAgent.transCallingnum': '主叫号码',
  'ccivr.nms.tansferservice.transferLastAgent.mustbeaccesscode': '主叫号码是客户来电号码．如{\'$\'}{\'{\'}SYS.callingNumber{\'}\'}',
  'ccivr.nms.tansferservice.transferLastAgent.sampleForCallingnum': '例：660001或{\'$\'}{\'{\'}SYS.callingNumber{\'}\'}',
  'ccivr.nms.tansferservice.transferLastAgent.type': '熟客模式',
  'ccivr.nms.tansferservice.transferLastAgent.type.time': '时间范围',
  'ccivr.nms.tansferservice.transferLastAgent.type.time.label': '分钟',
  'ccivr.nms.tansferservice.transferLastAgent.type.time.info': '转移给时间范围内最近联系的座席',
  'ccivr.nms.tansferservice.transferLastAgent.type.time.sample': '例：100，转移给 100 分钟内最近联系的座席',
  'ccivr.nms.tansferservice.transferLastAgent.type.startTime': '起始时间',
  'ccivr.nms.tansferservice.transferLastAgent.type.startTime.info': '转移给从起始时间至今最近联系的座席',
  'ccivr.nms.tansferservice.transferLastAgent.type.startTime.sample': '请选择起始时间',
  'ccivr.nms.tansferservice.transferLastAgent.type.currentday': '当天',
  'ccivr.nms.tansferservice.transferLastAgent.type.currentday.info': '转移给当天内最近联系的座席',
  'ccivr.nms.tansferservice.transferLastAgent.type.none': '默认',
  'ccivr.nms.tansferservice.transferLastAgent.type.none.info': '转移给最近联系的座席',
  'ccivr.nms.tansferservice.transferThirdParty.recordingSettings': '录音设置',
  'ccivr.nms.tansferservice.transferThirdParty.enableRecording': '开启录音',
  'ccivr.nms.tansferservice.transferThirdParty.recordingPrompt': '录音提示',
  'ccivr.nms.tansferservice.empty': '参数为空',
  'ccivr.nms.conditionService.processLayout': '流程编排',
  'ccivr.nms.conditionService.conditionService': '条件判断',
  'ccivr.nms.conditionService.moduleDescription': '模块描述',
  'ccivr.nms.conditionService.createCondition': '新增条件',
  'ccivr.nms.conditionService.conditionalEdit': '条件编辑',
  'ccivr.nms.conditionService.condition': '条件名称',
  'ccivr.nms.conditionService.Parameter_1': '参数1',
  'ccivr.nms.conditionService.Parameter_2': '参数2',
  'ccivr.nms.conditionService.relationOperator': '运算符',
  'ccivr.nms.conditionService.logicOperator': '逻辑',
  'ccivr.nms.conditionService.operat': '操作',
  'ccivr.nms.conditionService.add': '新增',
  'ccivr.nms.conditionService.delete': '删除',
  'ccivr.nms.conditionService.parameterEmpty': '参数1为空',
  'ccivr.nms.conditionService.maxroute': '条件分支数量最大值为20',
  'ccivr.nms.timeselectservice.title': '时间选择',
  'ccivr.nms.timeselectservice.servicetimeperiod': '服务时间段',
  'ccivr.nms.timeselectservice.timeperiod': '时间段',
  'ccivr.nms.timeselectservice.hour': '时',
  'ccivr.nms.timeselectservice.min': '分',
  'ccivr.nms.timeselectservice.timeSelectType':'配置模式',
  'ccivr.nms.timeselectservice.customConfig':'自定义配置',
  'ccivr.nms.timeselectservice.workDayType':'引用模板',
  'ccivr.nms.timeselectservice.workDay':'工作日历',
  'ccivr.nms.timeselectservice.selectworktime': '星期选择',
  'ccivr.nms.timeselectservice.selectdate': '日期选择',
  'ccivr.nms.timeselectservice.tips1': '同时符合星期选择和日期选择的日期，将会被认为是工作日',
  'ccivr.nms.timeselectservice.tips2': '服务时间段结束时间需要大于开始时间',
  'ccivr.nms.timeselectservice.tips3': '服务时间段请由小到大排序，请不要设置交叉的时间段',
  'ccivr.nms.timeselectservice.monday': '周一',
  'ccivr.nms.timeselectservice.tuesday': '周二',
  'ccivr.nms.timeselectservice.wednesday': '周三',
  'ccivr.nms.timeselectservice.thursday': '周四',
  'ccivr.nms.timeselectservice.friday': '周五',
  'ccivr.nms.timeselectservice.saturday': '周六',
  'ccivr.nms.timeselectservice.sunday': '周日',
  'ccivr.ivr.ivrflow.operate.gateway.pulishrepeat': '该流程名称重复或已发布一个版本，不能重复发布！',
  'ccivr.nms.SubFlowService.Rotorflow': '转子流程',
  'ccivr.nms.SubFlowService.processjump': '流程跳转到',
  'ccivr.nms.SubFlowService.jumpnode': '跳转节点',
  'ccivr.nms.process.processinformation': '流程信息',
  'ccivr.nms.process.basicinformation': '基本信息',
  'ccivr.nms.process.parameterconfiguration': '参数配置',
  'ccivr.nms.process.customparameter': '流程变量',
  'ccivr.nms.process.parametertype': '参数类型',
  'ccivr.nms.process.initialvalue': '初始值',
  'ccivr.nms.process.description': '描述',
  'ccivr.nms.process.String': '字符串',
  'ccivr.nms.process.number': '整型',
  'ccivr.nms.process.sysparam': '系统参数',
  'ccivr.nms.process.paramname': '参数名称',
  'ccivr.nms.process.success': '流程保存成功！',
  'ccivr.nms.process.paramnameNoCN': '参数名称有误',
  'ccivr.nms.playcollectservice.Receivemaxnum': '接收最大位数',
  'ccivr.nms.playcollectservice.second': '秒',
  'ccivr.nms.playcollectservice.times': '次',
  'ccivr.nms.playcollectservice.Failedreceivenum': '收号失败',
  'ccivr.nms.playcollectservice.tip8': '收号时，需要用户输入“#”号键表示收号结束。请在语音中进行提示。',
  'ccivr.nms.menuservice.title': '菜单配置',
  'ccivr.nms.menuservice.voicenotice': '语音提示',
  'ccivr.nms.menuservice.menubuttonconfig': '菜单按键配置',
  'ccivr.nms.menuservice.button': '按键',
  'ccivr.nms.menuservice.moveup': '上移',
  'ccivr.nms.menuservice.movedown': '下移',
  'ccivr.nms.menuservice.contentvalidate': '完成编辑之后添加新按键配置',
  'ccivr.nms.menuservice.samenamevalidate': '按键重复:',
  'ccivr.nms.conditionService.simpleExp': '简单表达式',
  'ccivr.nms.conditionService.complexExp': '复杂表达式',
  'ccivr.nms.conditionService.logicAND': '与',
  'ccivr.nms.conditionService.logicOR': '或',
  'ccivr.nms.tansferservice.transferLabor': '转人工',
  'ccivr.nms.tansferservice.transferThirdParty': '转第三方',
  'ccivr.nms.tansferservice.chooseSkill': '选择技能队列',
  'ccivr.nms.tansferservice.sample': '例：28956666或{\'$\'}{\'{\'}FLOW.currentUserNum{\'}\'}',
  'ccivr.nms.menuservice.tip': '超时或按其他键',
  'ccivr.nms.leavemessageservice.leavemessage': '留言',
  'ccivr.nms.leavemessageservice.recordfile': '录音文件',
  'ccivr.nms.leavemessageservice.choose.recordfilename': '保存文件名',
  'ccivr.nms.transferservice.choose.recordfilename.info': '后续使用接口调用图元下载录音文件时使用变量暂存该值',
  'ccivr.nms.transferservice.choose.recordfilename.remark': '呼叫中心Pool模式下使用',
  'ccivr.nms.leavemessageservice.localtionID': 'localtionID',
  'ccivr.nms.leavemessageservice.choose.localtionID': '保存节点编号',
  'ccivr.nms.leavemessageservice.max.recordtime': '最大录音时长',
  'ccivr.nms.leavemessageservice.playbi': '播放"哔"音',
  'ccivr.nms.leavemessageservice.recordparam': '录音参数',
  'ccivr.nms.leavemessageservice.choose': '请选择',
  'ccivr.nms.leavemessageservice.end': '默认以 ”#“ 号键结束录音',
  'ccivr.nms.leavemessageservice.placeholder': '留言',
  'ccivr.nms.leavemessageservice.saveleavemessage': '记录留言信息',
  'ccivr.nms.leavemessageservice.flownamerepeat': '录音文件名和localtionID不能使用同一个变量',
  'ccivr.nms.leavemessageservice.agent': '指定座席处理',
  'ccivr.nms.leavemessageservice.choose.agent': '指定座席工号',
  'ccivr.nms.leavemessageservice.choose.agent.sample': '例：101或{\'$\'}{\'{\'}FLOW.workNo{\'}\'}',
  'ccivr.nms.callendservice.title': '结束',
  'ccivr.nms.callendservice.checkbox': '呼叫结束时需要调用接口',
  'ccivr.flowtest.flowname': '流程名称',
  'ccivr.flowtest.begin.tip': '输入主叫号码可启动测试',
  'ccivr.flowtest.callingnumber': '主叫号码',
  'ccivr.flowtest.enter': '请输入主叫号码',
  'ccivr.flowtest.begintest': '启动测试',
  'ccivr.flowtest.stoptest': '停止测试',
  'ccivr.flowtest.success': '成功',
  'ccivr.flowtest.fail': '失败',
  'ccivr.flowtest.presskey': '按键',
  'ccivr.flowtest.enter.phone': '请输入电话号码',
  'ccivr.flowtest.hangup': '挂机',
  'ccivr.flowtest.test': '测试',
  'ccivr.flowtest.testoperate': '测试操作',
  'ccivr.flowtest.client': '客户侧',
  'ccivr.flowtest.platform': '平台侧',
  'ccivr.flowtest.pleasebegintest': '流程已结束',
  'ccivr.flowtest.hashangup': '测试结束，已挂机',
  'ccivr.flowtest.operatesuccess': '执行操作:成功',
  'ccivr.flowtest.operatefail': '执行操作:失败',
  'ccivr.flowtest.operatehangup': '执行操作:挂机',
  'ccivr.flowtest.test.enter.callingnumber': '请先输入主叫号码！',
  'ccivr.flowtest.test.error.tip': '该流程尚未进行被叫配置或者未被引用',
  'ccivr.flowtest.flowtest': '流程测试',
  'ccivr.whitelist.whitelistmanage': '白名单管理',
  'ccivr.whitelist.whiteip': '白名单IP',
  'ccivr.whitelist.imordomainname': 'IP或域名',
  'ccivr.whitelist.whiteport': '白名单端口',
  'ccivr.whitelist.port': '端口',
  'ccivr.whitelist.desc': '描述',
  'ccivr.whitelist.descinfo': '描述信息',
  'ccivr.whitelist.deletetip': '请勾选要删除的项',
  'ccivr.whitelist.urlvalidatefailed': '请输入ip或域名',
  'ccivr.nms.assignservice.Dataassignment': '数据赋值',
  'ccivr.nms.assignservice.assignmentParameters': '待赋值参数',
  'ccivr.nms.assignservice.transferDataAssign': '获取随路数据',
  'ccivr.nms.assignservice.othersDataAssign': '其他数据赋值',
  'ccivr.nms.assignservice.setTransferData': '设置随路数据',
  'ccivr.nms.assignservice.ieDataAssign': 'IE数据赋值',
  'ccivr.nms.assignservice.ietype': 'IE类型',
  'ccivr.nms.satisfactionservice.type': '满意度调查类型',
  'ccivr.nms.satisfactionservice.title': '满意度调查',
  'ccivr.nms.singleproblem.title': '单问题调查',
  'ccivr.nms.satisfactionservice.menubuttonconfig': '满意度调查按键配置',
  'ccivr.nms.satisfactionservice.singleproblem.menubuttonconfig': '单问题调查按键配置',
  'ccivr.nms.satisfactionservice.buttonNumber': '满意度调查按键数不超过9个，不低于3个。',
  'ccivr.nms.satisfactionservice.tip': '请在满意度调查设置菜单中配置满意度等级，若流程已发布，可通过升级、编辑、保存，重新发布的方式替换原流程，以使用更新的满意度等级。',
  'ccivr.nms.satisfactionservice.descripLength': '满意度调查按键描述不超过200',
  'ccivr.nms.satisfactionservice.isNull': '参数不能为空',
  'ccivr.grayrule.errorinfo.containspecialcha': '规则名称包含特殊字符',
  'ccivr.ivr.ivrflow.templateName': '资源模板名称',
  'ccivr.ivr.voiceupload.referenceFlowByCCIVR': 'IVR流程引用',
  'ccivr.ivr.voiceupload.referenceFlowByNMS': '资源模板引用',
  'ccivr.ivr.voiceupload.voiceUsageByTenant': '租户场景引用',
  'ccivr.ivr.voiceupload.voiceUsageBySkill': '技能队列引用',
  'ccivr.nms.queryservice.querySkillMessage': '信息查询',
  'ccivr.nms.queryservice.assignmentParameters': '待赋值参数',
  'ccivr.nms.queryservice.getDataAssign': '查询结果',
  'ccivr.nms.queryservice.Dataassignment': '查询信息',
  'ccivr.nms.queryservice.queryType': '查询类型',
  'ccivr.nms.queryservice.queryParam': '请求参数',
  'ccivr.nms.queryservice.skillMsg': '排队信息',
  'ccivr.nms.queryservice.skillInfo': '队列信息',
  'ccivr.nms.queryservice.type': '类型选择',
  'ccivr.nms.queryservice.paramName': '请求参数名',
  'ccivr.nms.queryservice.paramValue': '请求参数值',
  'ccivr.nms.queryservice.info': '排队信息使用场景是转移到技能队列时启用伴随IVR流程查询排队信息',
  'ccivr.nms.queryservice.queueInfo': '队列信息使用场景是转移到技能队列之前查询队列信息',
  'ccivr.nms.queryservice.queueSpecialInfo': '特殊名单信息使用场景是查询呼叫用户号码归属的特殊名单信息',
  'ccivr.nms.queryservice.specifiedSkill': '队列名称',
  'ccivr.nms.queryservice.specialListQuery': '特殊名单信息',
  'ccivr.nms.queryservice.specifiedSkill.paramValue': '座席工号',
  'ccivr.nms.queryservice.specifiedSkill.paramValue.sample': '例：101或 {\'$\'}{\'{\'}FLOW.workNo{\'}\'}',
  'ccivr.nms.queryservice.specifiedSkill.result': '队列名称',
  'ccivr.nms.queryservice.specialList.flag': '存在于特殊名单的标识',
  'ccivr.nms.queryservice.specialList.flagInfo': '特殊名单存在标识结果值：0标识不存在于特殊名单；1标识存在于特殊名单',
  'ccivr.nms.queryservice.audio': '音频',
  'ccivr.nms.queryservice.video': '视频',
  'ccivr.nms.playset.status': 'TTS高级设置',
  'ccivr.nms.playset.open': '开启',
  'ccivr.nms.playset.close': '关闭',
  'ccivr.nms.playset.sound': '音效',
  'ccivr.nms.playset.malevoice': '男生朗读',
  'ccivr.nms.playset.femalevoice': '女生朗读',
  'ccivr.nms.playset.speed': '音速',
  'ccivr.nms.playset.lowspeed': '低速朗读',
  'ccivr.nms.playset.intermediatespeed': '中速朗读',
  'ccivr.nms.playset.highspeed': '高速朗读',
  'ccivr.nms.playset.extendparam': 'TTS扩展参数',
  'ccivr.nms.shortmessageservice.processInformation': '短信',
  'ccivr.nms.shortmessageservice.sendType': '发送方式',
  'ccivr.nms.shortmessageservice.normalMessage': '普通短信',
  'ccivr.nms.shortmessageservice.fiveGMessage': '5G消息',
  'ccivr.nms.shortmessageservice.satisfactionMessage': '满意度调查短信',
  'ccivr.nms.shortmessageservice.smsType': '渠道',
  'ccivr.nms.shortmessageservice.hwCloudMessage': '华为云短信',
  'ccivr.nms.shortmessageservice.mspMessage': '短信网关',
  'ccivr.nms.shortmessageservice.selectTemplate': '选择短信模板',
  'ccivr.nms.shortmessageservice.messageTemplateContent': '短信模板',
  'ccivr.nms.shortmessageservice.setParameter': '变量设置',
  'ccivr.nms.shortmessageservice.countryCode': '国家代码',
  'ccivr.nms.shortmessageservice.addressee': '手机号',
  'ccivr.nms.shortmessageservice.phoneNumber': '手机号码',
  'ccivr.nms.shortmessageservice.addresseeDesc': '选填,仅支持单个手机号,为空时默认发给呼叫方',
  'ccivr.nms.shortmessageservice.period': '有效回复时间期（单位分钟）',
  'ccivr.nms.playcollectservice.changetomultivoice': '变量音',
  'ccivr.nms.playservice.chosemultivoice': '播放内容从以下选择中设置',
  'ccivr.nms.playservice.multivoice.language': '语种',
  'ccivr.nms.playservice.multivoice.language0': '普通话',
  'ccivr.nms.playservice.multivoice.language1': '英语',
  'ccivr.nms.playservice.multivoice.language2': '粤语',
  'ccivr.nms.playservice.multivoice.language3': '捷克语',
  'ccivr.nms.playservice.multivoice.language4': '丹麦语',
  'ccivr.nms.playservice.multivoice.language5': '荷兰语',
  'ccivr.nms.playservice.multivoice.language6': '芬兰语',
  'ccivr.nms.playservice.multivoice.language7': '法语',
  'ccivr.nms.playservice.multivoice.language8': '德语',
  'ccivr.nms.playservice.multivoice.language9': '希腊语',
  'ccivr.nms.playservice.multivoice.language10': '匈牙利语',
  'ccivr.nms.playservice.multivoice.language11': '冰岛语',
  'ccivr.nms.playservice.multivoice.language12': '意大利语',
  'ccivr.nms.playservice.multivoice.language13': '日语',
  'ccivr.nms.playservice.multivoice.language14': '韩语',
  'ccivr.nms.playservice.multivoice.language15': '挪威语',
  'ccivr.nms.playservice.multivoice.language16': '波兰语',
  'ccivr.nms.playservice.multivoice.language17': '葡萄牙语',
  'ccivr.nms.playservice.multivoice.language18': '俄语',
  'ccivr.nms.playservice.multivoice.language19': '斯洛伐克语',
  'ccivr.nms.playservice.multivoice.language20': '西班牙语',
  'ccivr.nms.playservice.multivoice.language21': '瑞典语',
  'ccivr.nms.playservice.multivoice.language22': '土耳其语',
  'ccivr.nms.playservice.multivoice.language23': '乌兹别克语',
  'ccivr.nms.playservice.multivoice.language24': '泰语',
  'ccivr.nms.playservice.multivoice.language81': '阿拉伯语',
  'ccivr.nms.playservice.multivoice.type': '类型',
  'ccivr.nms.playservice.multivoice.type.telnum': '电话号码',
  'ccivr.nms.playservice.multivoice.type.number': '数字',
  'ccivr.nms.playservice.multivoice.type.time': '时间(hh:mm:ss)',
  'ccivr.nms.playservice.multivoice.type.date': '日期(yyyymmdd)',
  'ccivr.nms.playservice.multivoice.type.price': '价格',
  'ccivr.nms.playservice.multivoice.content': '内容',
  'ccivr.whitelist.validator.ip': '无效的IP地址（IPv4或IPv6或域名）',
  'ccivr.interface.typeerror': '当前仅支持 GET 和 POST 类型',
  'ccivr.nms.process.parameterencryptips': '请注意，未加密的参数将明文存储参数值，如确定使用，可能存在安全风险。',
  'ccivr.ivrflow.specify.accessCode': '是否指定渠道接入码',
  'ccivr.ivr.transferRecord.startTime': '开始时间',
  'ccivr.ivr.transferRecord.endTime': '结束时间',
  'ccivr.ivr.transferRecord.callNumber': '主叫号码',
  'ccivr.ivr.transferRecord.flowAccessCode': '流程接入码',
  'ccivr.ivr.flowAccessCode.repeat': '该接入码已经存在！',
  'ccivr.ivr.transferRecord.transNumber': '转接号码',
  'ccivr.ivr.transferRecord.transResult': '转接结果',
  'ccivr.ivr.transferRecord.recordedFile': '录音文件',
  'ccivr.ivr.transferRecord.play': '播放',
  'ccivr.ivr.transferRecord.download': '下载',
  'ccivr.ivr.transferRecord.success': '成功',
  'ccivr.ivr.transferRecord.fail': '失败',
  'ccivr.ivr.transferRecord.noMoreThan90Days': '范围不能超过90天',
  'ccivr.transferrecord.download.record': '下载流程转接录音：',
  'ccivr.transferrecord.play.record': '播放流程转接录音：',
  'ccivr.transferrecord.play.timeCompare': '开始时间不能大于结束时间',
  'ccivr.ivr.transferRecord.init': '初始化',
  'ccivr.ivr.transferRecord.noright': '当前用户没有下载录音权限',
  'ccivr.ivr.transferRecord.query.failed': '查询转接录音失败！',
  'ccivr.ivr.transferRecord.connect.uap.failed': '连接文件服务器失败！',
  'ccivr.ivr.transferRecord.connect.file.notexist': '下载录音文件失败，请检查文件是否存在',
  'ccivr.nms.tansferservice.idleAgentCount': '空闲座席数',
  'ccivr.nms.process.saveerror.queuing': '排队位置、在线座席数、预计等待时间至少填一项。',
  'ccivr.nms.process.saveerror.queues': '排队位置、在线座席数、空闲座席数、预计等待时间至少填一项。',
  'ccivr.ivr.voice.scene.voicenotification': '语音通知',
  'ccivr.ivr.connectsftp.uploadfile.exception': '连接SFTP失败\,文件上传失败！',
  'ccivr.ivr.voicepop.inputname': '请输入IVR语音名称',
  'ccivr.ivr.voiceupload.file.content': '内容',
  'ccivr.ivr.voiceupload.file.update.content': '更新内容',
  'ccivr.ivr.voiceupload.file.update.time': '更新时间',
  'ccivr.ivr.voiceupload.file.update.status': '审核状态',
  'ccivr.ivr.voiceupload.file.update.comments': '审核意见',
  'ccivr.ivr.voiceupload.file.update.operation': '操作',
  'ccivr.ivr.voiceupload.file.update.delete': '删除更新',
  'ccivr.ivr.voiceupload.file.update.submit': '提交审批',
  'ccivr.ivr.voiceupload.file.update': '更新',
  'ccivr.ivr.error.releaseFailed.tips': '问卷自动发布失败，请配置并正式发布一个异常处理流程后手动发布问卷',
  'ccivr.nms.playservice.chose.survey': '播放内容从已上传并审核过的音频中选择或新增语音',
  'ccivr.nms.playservice.chosetts.survey': '播放内容从已上传并审核过的TTS文本中选择或新增文本，需拥有TTS资源',
  'ccivr.nms.satisfactionservice.buttonNumber.Number': '按键数不超过9个，不少于1个。',
  'ccivr.nms.lightweight.tips': '请在问卷轻量版进行操作',
  'ccivr.conditionService.param.tips': '参数2设置为字符串常量时，需要增加引号才能正常使用',
  'ccivr.nms.conditionService.sample': '请输入条件表达式。可以包含参数名、值和复杂运算。参数引用格式为FLOW(流程变量)/SYS(系统变量)/GLOBAL(全局变量).参数名称。例："SYS.currentNumber==FLOW.RechargeNumber&& (FLOW.AccountBalance - 20)>=FLOW.BillAmount',
  'ccivr.nms.conditionService.tips': '条件表达式可以包含变量，值和复杂的运算。数值或字符串间比较操作符支持 > < >= <= !=。布尔值操作符支持 && {\'|\'}{\'|\'} == !=。例：SYS.currentNumber==FLOW.RechargeNumber && (FLOW.AccountBalance-20)>=FLOW.BillAmount',
  'ccivr.TimeSelectService.branch.wrongtime': '非指定时间段',
  'ccivr.ivr.bussinessinvoke.paramTip': '参数名称，例:GLOBAL.language或FLOW.testid',
  'ccivr.playservice.branch.query': '查询失败',
  'ccivr.businessInvokeService.err': '接口调用失败',
  'ccivr.playservice.branch.ring': '振铃失败',
  'ccivr.playservice.branch.userLevel': '设置失败',
  'ccivr.tansferservice.branch.transferErr': '转移失败',
  'ccivr.playservice.branch.change': '切换失败',
  'ccivr.nms.conditionService.conditionBranch': '条件分支',
  'ccivr.playservice.branch.playerr': '放音失败',
  'ccivr.PlayCollectService.branch.err': '放音收号失败',
  'ccivr.nms.playcollectservice.warntype': '提示类型',
  'ccivr.leaveMessage.maxtime.range': '最大录音时长范围10-180秒',
  'ccivr.ivr.voiceupload.fileName.validate': '文件名称不能包含如下特殊字符：',
  'ccivr.nms.play.method': '方式',
  'ccivr.nms.play.source': '资源',
  'ccivr.ivr.ivrflow.operate.release.referencedflow': '取消发布失败！该流程已经被引用。',
  'ccivr.ivr.voice.scene.2d.avatar': '2D数字人',
  'ccivr.ivr.voice.picturechoose': '图片文件选择',
  'ccivr.ivr.voiceupload.selectpicture': '请选择图片文件',
  'ccivr.ivr.voiceupload.tippictureformat': '目前仅支持jpg、png、jpeg格式的图片文件，文件分辨率不得超过720P，文件大小不得超过2M。',
  'ccivr.ivr.voiceupload.tippicturemodel': '您可以同时设置不同语种的图片文件。数字人合成时将根据用户的语种选择自动切换使用对应的图片文件。',
  'ccivr.ivr.voiceupload.tip2DVideoformat': '目前仅支持mp4格式的视频文件，文件分辨率不得超过720P，文件大小不得超过5M。',
  'ccivr.ivr.voiceupload.tip2Dvideomodel': '您可以同时设置不同语种的视频文件。数字人合成时将根据用户的语种选择自动切换使用对应的视频文件。',
  'ccivr.ivr.voiceupload.vedioMp4formatwrong': '文件格式错误，请导入mp4格式的文件！',
  'ccivr.ivr.voiceupload.tipvideo2Dformat': '目前仅支持mp4格式的视频文件，文件大小不得超过5M。',
  'ccivr.ivr.voicereview.picture': '图片',
  'ccivr.ivr.voicereview.2dVideo': '数字人视频',
  'ccivr.ivr.voiceupload.tipPicture2Dformat': '目前仅支持jpg、png、jpeg格式的图片文件，文件大小不得超过2M。',
  'ccivr.ivr.voiceupload.pictureformatwrong': '文件格式错误，请导入jpg、png、jpeg格式的文件！',
  'ccivr.ivr.voiceupload.file.updateTitle': '更新音视频资源',
  'ccivr.ivr.voiceupload.file.createTitle': '新建音视频资源',
  'ccivr.ivr.voiceupload.tippictureresolution': '图片分辨率不得超过720P',
  'ccivr.ivr.voiceupload.tipvideoresolution': '视频分辨率不得超过720P',
  'oifde.service.invoke.businessVirtualHumanService.name': '数字人应答',
}
