<script>
import {CirclePlus, Delete} from "@element-plus/icons-vue";
import skillQueueDialog from "@/views/sumweb/components/user/skill-queue-dialog.vue";
import SkillGroupDialog from "@/views/sumweb/components/user/skill-group-dialog.vue";
import {useUserInfoState} from "@/views/sumweb/stores/userInfo";

export default {
  name: "skill-queue-card",
  components: {
    SkillGroupDialog,
    CirclePlus,
    Delete,
    skillQueueDialog
  },
  data() {
    return {
      skillKind: 0,
      voiceTableData: [],
      videoTableData: [],
      mediaTableData: [],
      voiceDialTableData: [],
      videoDialTableData: [],
      groupList: [], // 对话框传过来的值
      defaultTableList: [],
      defaultSkillIdList: [],
      defaultGroupList: [],
      state: ''
    }
  },
  methods: {
    addSkill() {
      this.defaultSkillIdList = this.skillShow.filter(skill => skill.isDefaultSkill === 1).map(skill => skill.skillId);
      this.$refs.skillRef.open(this.skillShow);
    },
    getSkills(data) {
      this.voiceTableData = [];
      this.videoTableData = [];
      this.mediaTableData = [];
      this.voiceDialTableData = [];
      this.videoDialTableData = [];
      for (const row of data) {
        let operateTable = this.getCurrentTable(row);
        let newRow = {};
        newRow.id = row.id;
        newRow.mediaTypeId = row.mediaTypeId;
        newRow.skillId = row.skillId;
        newRow.name = row.name;
        newRow.skillWeight = row.skillWeight ? row.skillWeight : 1;
        newRow.agentWeight = row.agentWeight ? row.agentWeight : 1;
        newRow.isDefaultSkill = this.defaultSkillIdList.includes(row.skillId) ? 1 : 0;
        operateTable.push(newRow);
      }
    },
    setSkillGroup(data) {
      this.groupList = data;
      this.defaultTableList = [];
      let result = this.groupList
          .map(group => group.skillList)
          .flat()
          .map(group => {
            return [group.mediaTypeId, group]
          });

      let skillMap = new Map()

      for (let value of result) {
        if (skillMap.has(value[0])) {
          let valueArr = skillMap.get(value[0]);
          let find = valueArr.find(row => row.skillId === value[1].skillId);
          if (!find) {
            valueArr.push(value[1]);
          }
        } else {
          skillMap.set(value[0], [value[1]]);
        }
      }

      skillMap.forEach((value, key) => {
        let item = {
          mediaType: this.getSkillType[key],
          skills: value,
        };
        let skillIds = value.map(row => row.skillId);
        if (this.defaultGroupList.length > 0) {
          let data = this.defaultGroupList.find(row => row.mediaTypeId === key);
          if (data && skillIds.includes(data.skillId)) {
            item.default = data;
          }
        }

        this.defaultTableList.push(item);
      })
    },
    handleFocusSkillGroup() {
      this.defaultGroupList = this.defaultTableList.filter(row => row.default).map(row => row.default);
      this.$refs.skillGroupRef.open(this.groupList);
      this.$refs.skillGroupInputRef.blur();
    },
    handleDelete(row) {
      let operateTable = this.getCurrentTable(row);
      let index = operateTable.findIndex(item => item.id === row.id);
      operateTable.splice(index, 1);
    },
    handleWeight(row) {
      if (row.skillWeight > 100) {
        row.skillWeight = 100
      }
      if (row.agentWeight > 300) {
        row.agentWeight = 300
      }
      if (row.skillWeight <= 0) {
        row.agentWeight = 1
      }
      if (row.agentWeight <= 0) {
        row.agentWeight = 1
      }
    },
    handleRadioChange(row, index) {
      if (this.isView) {
        return;
      }
      let operateTable = this.getCurrentTable(row);
      for (let i = 0; i < operateTable.length; i++) {
        if (i !== index) {
          operateTable[i].isDefaultSkill = 0;
        }
      }
      operateTable[index].isDefaultSkill === 1 ? operateTable[index].isDefaultSkill = 0 : operateTable[index].isDefaultSkill = 1;
    },
    getCurrentTable(row) {
      let operateTable = [];
      if (row.mediaTypeId === 2) {
        operateTable = this.voiceDialTableData;
      } else if (row.mediaTypeId === 5) {
        operateTable = this.voiceTableData;
      } else if (row.mediaTypeId === 18) {
        operateTable = this.videoDialTableData;
      } else if (row.mediaTypeId === 19) {
        operateTable = this.videoTableData;
      } else if (row.mediaTypeId === 53) {
        operateTable = this.mediaTableData;
      }
      return operateTable;
    },
    setTableValue(row) {
      if (row.mediaTypeId === 2) {
        this.voiceDialTableData.push(row);
      } else if (row.mediaTypeId === 5) {
        this.voiceTableData.push(row);
      } else if (row.mediaTypeId === 18) {
        this.videoDialTableData.push(row);
      } else if (row.mediaTypeId === 19) {
        this.videoTableData.push(row);
      } else if (row.mediaTypeId === 53) {
        this.mediaTableData.push(row);
      }
    },
  },
  computed: {
    isView() {
      return this.state === 'view';
    },
    isEdit() {
      return this.state === 'edit';
    },
    isCreate() {
      return this.state === 'create';
    },
    isBatchConfig() {
      return this.state === 'batchConifg';
    },
    Delete() {
      return Delete;
    },
    getSkillType() {
      return {
        "2": $t('ccm.agent.contact.audioclicktodial'),
        "5": $t('ccm.agent.contact.Voice'),
        "18": $t('ccm.agent.contact.videoclicktodial'),
        "19": $t('ccm.agent.contact.vidio'),
        "53": $t('ccm.agent.contact.multimedia'),
        "53-1": $t('ccm.agent.contact.multimedia.web'),
        "53-2": $t('ccm.agent.contact.multimedia.whatsapp'),
        "53-3": $t('ccm.agent.contact.multimedia.line'),
        "53-4": $t('ccm.agent.contact.multimedia.wechat'),
        "53-5": $t('ccm.agent.contact.multimedia.facebook'),
        "53-6": $t('ccm.agent.contact.multimedia.twitter'),
        "53-7": $t('ccm.agent.contact.multimedia.fiveG'),
        "53-8": $t('ccm.agent.contact.multimedia.email'),
        "53-9": $t('ccm.agent.contact.multimedia.sms'),
        "53-11": $t('ccm.agent.contact.multimedia.instagram'),
        "53-12": $t('ccm.agent.contact.multimedia.telegram')
      }
    },
    skillKinds() {
      return [
        {
          "skillKind": 0,
          "skillKindName": $t('cms.fullscreen.field.skillQueue')
        },
        {
          "skillKind": 1,
          "skillKindName": $t('ccm.agent.label.skillgroup')
        }
      ]
    },
    groupNames() {
      return this.groupList.map(group => group.name)
    },
    skillShow() {
      return [
        ...this.voiceTableData,
        ...this.videoTableData,
        ...this.mediaTableData,
        ...this.voiceDialTableData,
        ...this.videoDialTableData,
      ]
    },
    viewSkillKindName() {
      let find = this.skillKinds.find(skill => skill.skillKind === this.skillKind);
      if (find) {
        return find.skillKindName;
      }
    },
  },
  beforeMount() {
    let userInfoState = useUserInfoState();
    this.state = userInfoState.data.state;
  },
  props: {
    skillKindProp: {
      type: Number,
      required: true,
      default: 0
    },
    skillList: {
      type: Array,
      required: false,
      default: []
    },
    skillGroupList: {
      type: Array,
      required: false,
      default: []
    },
    defaultSkillList: {
      type: Array,
      required: false,
      default: []
    }
  },
  watch: {
    skillList(newVal) {
      if (newVal.length > 0) {
        for (const skill of newVal) {
          this.setTableValue(skill);
        }
      }
    },
    skillKindProp(newVal) {
      if (newVal !== -1) {
        this.skillKind = newVal;
      } else if (this.isView) {
        this.skillKind = newVal;
      }
    },
    skillGroupList(newVal) {
      if (newVal.length > 0) {
        let arr = []
        for (let row of newVal) {
          let data = {}
          data.id = row.skillGroupUid;
          data.name = row.skillGroupName;
          data.skillGroupId = row.skillGroupId;
          data.skillList = row.skills;
          arr.push(data);
        }
        this.defaultGroupList = this.defaultSkillList;
        this.setSkillGroup(arr);
      }
    }
  }
}
</script>

<template>
  <el-card>
    <div class="card-title">
      {{ $t('SM.EMPLOYEE.SKILL.INFO') }}
    </div>

    <el-form-item prop="skillKind"
                  :label="$t('ccm.agent.label.skillType')">
      <el-radio-group v-model="skillKind" v-if="!isView">
        <el-radio v-for="item in skillKinds"
                  :key="item.skillKind"
                  :label="item.skillKind">
          {{ item.skillKindName }}
        </el-radio>
      </el-radio-group>
      <span v-if="isView">{{ viewSkillKindName || '-' }}</span>
    </el-form-item>

    <el-form-item prop="skillList"
                  v-if="skillKind === 0"
                  :label="$t('ccm.agent.title.selectskill')">
      <div class="skill-row">
        <div class="skill-table" v-if="voiceTableData.length !== 0">
            <span class="item-form-title">
              {{ $t('ccm.agent.contact.Voice') }}
            </span>

          <el-table border
                    :data="voiceTableData"
                    tooltip-effect="light"
                    height="100%">
            <el-table-column property="name"
                             width="135"
                             :show-overflow-tooltip="true"
                             :label="$t('ccm.note.config.notename')">

            </el-table-column>
            <el-table-column property="skillWeight"
                             :show-overflow-tooltip="true"
                             min-width="200px">
              <template #header>
                <span>{{ $t('ccm.agent.label.skillWeight') }}</span>
                <el-tooltip
                    effect="light"
                    :show-arrow="false"
                    placement="top"
                    :content="$t('ccm.ccagent.skill.weight.tips')"
                >
                  <img class="icon" src="@/assets/img/home/ic_info@3x.png" alt=""/>
                </el-tooltip>
              </template>
              <template #default="scope">
                <el-input v-model="scope.row.skillWeight"
                          v-if="!isView"
                          :placeholder="$t('ccm.agent.message.weight.limit')"
                          @blur="handleWeight(scope.row)"
                          oninput="value=value.replace(/[^0-9]/g,'')"/>
                <span v-if="isView">{{ scope.row.skillWeight }}</span>
              </template>
            </el-table-column>
            <el-table-column property="agentWeight"
                             :show-overflow-tooltip="true"
                             min-width="200px">
              <template #header>
                <span>{{ $t('ccm.agent.label.agentWeight') }}</span>
                <el-tooltip
                    effect="light"
                    :show-arrow="false"
                    placement="top"
                    :content="$t('ccm.ccagent.agent.weight.tips')"
                >
                  <img class="icon" src="@/assets/img/home/ic_info@3x.png" alt=""/>
                </el-tooltip>
              </template>
              <template #default="scope">
                <el-input v-model="scope.row.agentWeight"
                          v-if="!isView"
                          :placeholder="$t('ccm.agent.message.agentweight.limit')"
                          @blur="handleWeight(scope.row)"
                          oninput="value=value.replace(/[^0-9]/g,'')"/>
                <span v-if="isView">{{ scope.row.agentWeight }}</span>
              </template>
            </el-table-column>
            <el-table-column property="isDefaultSkill"
                             :show-overflow-tooltip="true"
                             align="center"
                             :label="$t('ccm.ccagent.management.default.options')">
              <template #default="scope">
                <el-radio-group v-model="scope.row.isDefaultSkill">
                  <el-radio :label="1"
                            @click.native.prevent="handleRadioChange(scope.row, scope.$index)">
                    <span/>
                  </el-radio>
                </el-radio-group>
              </template>
            </el-table-column>
            <el-table-column :label="$t('ccm.agent.label.operate')"
                             width="95"
                             v-if="!isView"
                             align="center">
              <template #default="scope">
                <el-button class="delete-icon" :icon="Delete" type="icon"
                           @click="handleDelete(scope.row)"/>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="skill-table" v-if="videoTableData.length !== 0">
            <span class="item-form-title">
              {{ $t('ccm.agent.contact.vidio') }}
            </span>
          <el-table border
                    :data="videoTableData"
                    tooltip-effect="light"
                    height="100%">
            <el-table-column property="name"
                             width="135"
                             :show-overflow-tooltip="true"
                             :label="$t('ccm.note.config.notename')">

            </el-table-column>
            <el-table-column property="skillWeight"
                             :show-overflow-tooltip="true"
                             min-width="200px">
              <template #header>
                <span>{{ $t('ccm.agent.label.skillWeight') }}</span>
                <el-tooltip
                    effect="light"
                    :show-arrow="false"
                    placement="top"
                    :content="$t('ccm.ccagent.skill.weight.tips')"
                >
                  <img class="icon" src="@/assets/img/home/ic_info@3x.png" alt=""/>
                </el-tooltip>
              </template>
              <template #default="scope">
                <el-input v-model="scope.row.skillWeight"
                          v-if="!isView"
                          :placeholder="$t('ccm.agent.message.weight.limit')"
                          @blur="handleWeight(scope.row)"
                          oninput="value=value.replace(/[^0-9]/g,'')"/>
                <span v-if="isView">{{ scope.row.skillWeight }}</span>
              </template>
            </el-table-column>
            <el-table-column property="agentWeight"
                             :show-overflow-tooltip="true"
                             min-width="200px">
              <template #header>
                <span>{{ $t('ccm.agent.label.agentWeight') }}</span>
                <el-tooltip
                    effect="light"
                    :show-arrow="false"
                    placement="top"
                    :content="$t('ccm.ccagent.agent.weight.tips')"
                >
                  <img class="icon" src="@/assets/img/home/ic_info@3x.png" alt=""/>
                </el-tooltip>
              </template>
              <template #default="scope">
                <el-input v-model="scope.row.agentWeight"
                          v-if="!isView"
                          :placeholder="$t('ccm.agent.message.agentweight.limit')"
                          @blur="handleWeight(scope.row)"
                          oninput="value=value.replace(/[^0-9]/g,'')"/>
                <span v-if="isView">{{ scope.row.agentWeight }}</span>
              </template>
            </el-table-column>
            <el-table-column property="isDefaultSkill"
                             :show-overflow-tooltip="true"
                             align="center"
                             :label="$t('ccm.ccagent.management.default.options')">
              <template #default="scope">
                <el-radio-group v-model="scope.row.isDefaultSkill">
                  <el-radio :label="1"
                            @click.native.prevent="handleRadioChange(scope.row, scope.$index)">
                    <span/>
                  </el-radio>
                </el-radio-group>
              </template>
            </el-table-column>
            <el-table-column :label="$t('ccm.agent.label.operate')"
                             width="95"
                             v-if="!isView"
                             align="center">
              <template #default="scope">
                <el-button class="delete-icon" :icon="Delete" type="icon"
                           @click="handleDelete(scope.row)"/>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="skill-table" v-if="mediaTableData.length !== 0">
            <span class="item-form-title">
              {{ $t('ccm.agent.contact.multimedia') }}
            </span>
          <el-table border
                    :data="mediaTableData"
                    tooltip-effect="light"
                    height="100%">
            <el-table-column property="name"
                             width="135"
                             :show-overflow-tooltip="true"
                             :label="$t('ccm.note.config.notename')">

            </el-table-column>
            <el-table-column property="skillWeight"
                             :show-overflow-tooltip="true"
                             min-width="200px">
              <template #header>
                <span>{{ $t('ccm.agent.label.skillWeight') }}</span>
                <el-tooltip
                    effect="light"
                    :show-arrow="false"
                    placement="top"
                    :content="$t('ccm.ccagent.skill.weight.tips')"
                >
                  <img class="icon" src="@/assets/img/home/ic_info@3x.png" alt=""/>
                </el-tooltip>
              </template>
              <template #default="scope">
                <el-input v-model="scope.row.skillWeight"
                          v-if="!isView"
                          :placeholder="$t('ccm.agent.message.weight.limit')"
                          @blur="handleWeight(scope.row)"
                          oninput="value=value.replace(/[^0-9]/g,'')"/>
                <span v-if="isView">{{ scope.row.skillWeight }}</span>
              </template>
            </el-table-column>
            <el-table-column property="agentWeight"
                             :show-overflow-tooltip="true"
                             min-width="200px">
              <template #header>
                <span>{{ $t('ccm.agent.label.agentWeight') }}</span>
                <el-tooltip
                    effect="light"
                    :show-arrow="false"
                    placement="top"
                    :content="$t('ccm.ccagent.agent.weight.tips')"
                >
                  <img class="icon" src="@/assets/img/home/ic_info@3x.png" alt=""/>
                </el-tooltip>
              </template>
              <template #default="scope">
                <el-input v-model="scope.row.agentWeight"
                          v-if="!isView"
                          :placeholder="$t('ccm.agent.message.agentweight.limit')"
                          @blur="handleWeight(scope.row)"
                          oninput="value=value.replace(/[^0-9]/g,'')"/>
                <span v-if="isView">{{ scope.row.agentWeight }}</span>
              </template>
            </el-table-column>
            <el-table-column property="isDefaultSkill"
                             :show-overflow-tooltip="true"
                             align="center"
                             :label="$t('ccm.ccagent.management.default.options')">
              <template #default="scope">
                <el-radio-group v-model="scope.row.isDefaultSkill">
                  <el-radio :label="1"
                            @click.native.prevent="handleRadioChange(scope.row, scope.$index)">
                    <span/>
                  </el-radio>
                </el-radio-group>
              </template>
            </el-table-column>
            <el-table-column :label="$t('ccm.agent.label.operate')"
                             width="95"
                             v-if="!isView"
                             align="center">
              <template #default="scope">
                <el-button class="delete-icon" :icon="Delete" type="icon"
                           @click="handleDelete(scope.row)"/>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="skill-table" v-if="voiceDialTableData.length !== 0">
            <span class="item-form-title">
              {{ $t('ccm.agent.contact.audioclicktodial') }}
            </span>
          <el-table border
                    :data="voiceDialTableData"
                    tooltip-effect="light"
                    height="100%">
            <el-table-column property="name"
                             width="135"
                             :show-overflow-tooltip="true"
                             :label="$t('ccm.note.config.notename')">

            </el-table-column>
            <el-table-column property="skillWeight"
                             :show-overflow-tooltip="true"
                             min-width="200px">
              <template #header>
                <span>{{ $t('ccm.agent.label.skillWeight') }}</span>
                <el-tooltip
                    effect="light"
                    :show-arrow="false"
                    placement="top"
                    :content="$t('ccm.ccagent.skill.weight.tips')"
                >
                  <img class="icon" src="@/assets/img/home/ic_info@3x.png" alt=""/>
                </el-tooltip>
              </template>
              <template #default="scope">
                <el-input v-model="scope.row.skillWeight"
                          v-if="!isView"
                          :placeholder="$t('ccm.agent.message.weight.limit')"
                          @blur="handleWeight(scope.row)"
                          oninput="value=value.replace(/[^0-9]/g,'')"/>
                <span v-if="isView">{{ scope.row.skillWeight }}</span>
              </template>
            </el-table-column>
            <el-table-column property="agentWeight"
                             :show-overflow-tooltip="true"
                             min-width="200px">
              <template #header>
                <span>{{ $t('ccm.agent.label.agentWeight') }}</span>
                <el-tooltip
                    effect="light"
                    :show-arrow="false"
                    placement="top"
                    :content="$t('ccm.ccagent.agent.weight.tips')"
                >
                  <img class="icon" src="@/assets/img/home/ic_info@3x.png" alt=""/>
                </el-tooltip>
              </template>
              <template #default="scope">
                <el-input v-model="scope.row.agentWeight"
                          v-if="!isView"
                          :placeholder="$t('ccm.agent.message.agentweight.limit')"
                          @blur="handleWeight(scope.row)"
                          oninput="value=value.replace(/[^0-9]/g,'')"/>
                <span v-if="isView">{{ scope.row.agentWeight }}</span>
              </template>
            </el-table-column>
            <el-table-column property="isDefaultSkill"
                             :show-overflow-tooltip="true"
                             align="center"
                             :label="$t('ccm.ccagent.management.default.options')">
              <template #default="scope">
                <el-radio-group v-model="scope.row.isDefaultSkill">
                  <el-radio :label="1"
                            @click.native.prevent="handleRadioChange(scope.row, scope.$index)">
                    <span/>
                  </el-radio>
                </el-radio-group>
              </template>
            </el-table-column>
            <el-table-column :label="$t('ccm.agent.label.operate')"
                             width="95"
                             v-if="!isView"
                             align="center">
              <template #default="scope">
                <el-button class="delete-icon" :icon="Delete" type="icon"
                           @click="handleDelete(scope.row)"/>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="skill-table" v-if="videoDialTableData.length !== 0">
            <span class="item-form-title">
              {{ $t('ccm.agent.contact.videoclicktodial') }}
            </span>
          <el-table border
                    :data="videoDialTableData"
                    tooltip-effect="light"
                    height="100%">
            <el-table-column property="name"
                             :show-overflow-tooltip="true"
                             :label="$t('ccm.note.config.notename')">

            </el-table-column>
            <el-table-column property="skillWeight"
                             :show-overflow-tooltip="true"
                             min-width="200px">
              <template #header>
                <span>{{ $t('ccm.agent.label.skillWeight') }}</span>
                <el-tooltip
                    effect="light"
                    :show-arrow="false"
                    placement="top"
                    :content="$t('ccm.ccagent.skill.weight.tips')"
                >
                  <img class="icon" src="@/assets/img/home/ic_info@3x.png" alt=""/>
                </el-tooltip>
              </template>
              <template #default="scope">
                <el-input v-model="scope.row.skillWeight"
                          v-if="!isView"
                          :placeholder="$t('ccm.agent.message.weight.limit')"
                          @blur="handleWeight(scope.row)"
                          oninput="value=value.replace(/[^0-9]/g,'')"/>
                <span v-if="isView">{{ scope.row.skillWeight }}</span>
              </template>
            </el-table-column>
            <el-table-column property="agentWeight"
                             :show-overflow-tooltip="true"
                             min-width="200px">
              <template #header>
                <span>{{ $t('ccm.agent.label.agentWeight') }}</span>
                <el-tooltip
                    effect="light"
                    :show-arrow="false"
                    placement="top"
                    :content="$t('ccm.ccagent.agent.weight.tips')"
                >
                  <img class="icon" src="@/assets/img/home/ic_info@3x.png" alt=""/>
                </el-tooltip>
              </template>
              <template #default="scope">
                <el-input v-model="scope.row.agentWeight"
                          v-if="!isView"
                          :placeholder="$t('ccm.agent.message.agentweight.limit')"
                          @blur="handleWeight(scope.row)"
                          oninput="value=value.replace(/[^0-9]/g,'')"/>
                <span v-if="isView">{{ scope.row.agentWeight }}</span>
              </template>
            </el-table-column>
            <el-table-column property="isDefaultSkill"
                             :show-overflow-tooltip="true"
                             align="center"
                             :label="$t('ccm.ccagent.management.default.options')">
              <template #default="scope">
                <el-radio-group v-model="scope.row.isDefaultSkill">
                  <el-radio :label="1"
                            @click.native.prevent="handleRadioChange(scope.row, scope.$index)">
                    <span/>
                  </el-radio>
                </el-radio-group>
              </template>
            </el-table-column>
            <el-table-column :label="$t('ccm.agent.label.operate')"
                             v-if="!isView"
                             align="center">
              <template #default="scope">
                <el-button class="delete-icon" :icon="Delete" type="icon"
                           @click="handleDelete(scope.row)"/>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="skill-add" v-if="!isView" @click="addSkill">
          <el-button class="add-icon" style="margin-right: 10px" type="icon">
            <template #icon>
              <CirclePlus/>
            </template>
            <template #default>
              <span class="icon-template">{{ $t('isales.file.title.create') }}</span>
            </template>
          </el-button>
        </div>
      </div>

    </el-form-item>

    <div v-if="skillKind === 1">
      <el-form-item prop="skillGroupList"
                    :label="$t('ccm.agent.label.selectSkillGroup')">
        <el-input v-model="groupNames"
                  v-if="!isView"
                  class="width-400px aicc-input-dialog"
                  ref="skillGroupInputRef"
                  readonly
                  @focus="handleFocusSkillGroup"
                  clearable/>
        <span v-if="isView">{{ groupNames ? groupNames.toString() : '-' }}</span>
      </el-form-item>

      <el-form-item prop="DefaultSkillList"
                    v-if="defaultTableList.length !== 0"
                    :label="$t('ccm.skill.label.defaultskill')">
        <div class="skill-group-row">
          <el-table :data="defaultTableList"
                    border
                    ref="multipleTable"
                    style="margin-top: 10px"
                    tooltip-effect="light">
            <el-table-column property="mediaType"
                             :show-overflow-tooltip="true"
                             :label="$t('ccm.agent.label.usedMode')"/>
            <el-table-column :show-overflow-tooltip="true"
                             :label="$t('ccm.agent.label.skills')">
              <template #default="scope">
                <el-select v-model="scope.row.default"
                           v-if="!isView"
                           clearable
                           value-key="skillId">
                  <el-option
                      v-for="item in scope.row.skills"
                      :key="item.skillId"
                      :label="item.name"
                      :value="item"
                  />
                </el-select>
                <span v-if="isView">{{ scope.row.default ? scope.row.default.name : '-' }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form-item>
    </div>

  </el-card>
  <skill-queue-dialog ref="skillRef"
                      @get-skills="getSkills"/>
  <skill-group-dialog ref="skillGroupRef"
                      @get-skill-group="setSkillGroup"/>
</template>

<style scoped>

.card-title {
  font-weight: bolder;
  margin-bottom: 20px;
}

.skill-row {
  width: 1028px;
  padding: 0 0 16px 0;
  background-color: #FBFBFB;
  border-radius: 8px;
}

.skill-table {
  width: 900px;
  padding: 16px 0 0 20px;
}

.skill-group-row {
  width: 1028px;
  background-color: #FBFBFB;
  padding: 20px;
  border-radius: 8px;
}

.item-form-title {
  color: rgb(119, 119, 119);
}

.skill-add {
  width: 50px;
  padding: 22px 0 6px 20px;
  color: #366BFC;
}

.icon-template {
  font-size: 14px;
  font-weight: bold;
  margin-left: 6px;
}

.add-icon {
  font-size: 18px !important;
  color: #366BFC;
}

.delete-icon {
  font-size: 18px !important;
  color: #383839;
}

.width-400px {
  width: 400px;
  border-radius: 4px;
}

.icon {
  color: lightskyblue;
  height: 14px;
  margin-inline-start: 12px;
  vertical-align: text-bottom;
}

</style>