export default {
  'ccivr.ivrvoice.play.error':'Ressourcenausnahme wird geladen. Bitte überprüfen Sie die zugehörige Konfiguration.',
  'ccivr.ivrvoice.play.voicetimelimit':'Das Vorsingen ist lang. Verringern Sie den Textinhalt und versuchen Sie es erneut.',
  'ccivr.ivr.ivrflow.name':'Name',
  'ccivr.ivr.ivrflow.type':'Typ',
  'ccivr.ivr.ivrflow.updateTime':'Aktualisierungszeit',
  'ccivr.ivr.ivrflow.state':'Status',
  'ccivr.ivr.ivrflow.currentHandler':'Handler',
  'ccivr.ivr.ivrflow.operate':'Operation',
  'ccivr.ivr.ivrflow.operate.edit':'Bearbeiten',
  'ccivr.ivr.ivrflow.operate.delete':'Löschen',
  'ccivr.ivr.ivrflow.operate.release':'Freigabe',
  'ccivr.ivr.ivrflow.operate.ensurerelease':'Freigabe bestätigen',
  'ccivr.ivr.ivrflow.operate.ensurerelease.info':'Sind Sie sicher, dass Sie den Fluss freigeben möchten?',
  'ccivr.ivr.ivrflow.operate.release.success':'Freigabe erfolgreich.',
  'ccivr.ivr.ivrflow.operate.release.fail':'Freigabe fehlgeschlagen.',
  'ccivr.ivr.ivrflow.operate.release.needexecptionhanding':'Bitte konfigurieren Sie einen Ausnahmebehandlungsprozess und geben Sie ihn offiziell frei.',
  'ccivr.ivr.ivrflow.operate.release.cancelexecptionhanding':'Bitte brechen Sie den Freigabestatus anderer Prozesse unter dem Mandanten ab.',
  'ccivr.ivr.ivrflow.operate.cancelrelease':'Freigabe abbrechen',
  'ccivr.ivr.ivrflow.operate.queryInfo':'Ansicht',
  'ccivr.ivr.flowAccessCode.repeat':'Der Zugangscode ist bereits vorhanden!',
  'ccivr.ivr.ivrflow.operate.ensurecancelrelease':'Freigabestornierung bestätigen',
  'ccivr.ivr.ivrflow.operate.ensurecancelrelease.info':'Möchten Sie die Freigabe wirklich abbrechen?',
  'ccivr.ivr.ivrflow.operate.cancelrelease.success':'Abbruch erfolgreich.',
  'ccivr.ivr.ivrflow.operate.cancelrelease.fail':'Freigabe konnte nicht abgebrochen werden.',
  'ccivr.ivrflow.operate.replace':'Ersetzen',
  'ccivr.ivrflow.operate.ensurereplace':'Ersatz bestätigen',
  'ccivr.ivrflow.operate.ensurereplace.info':'Möchten Sie den freigegebenen Flow wirklich ersetzen?',
  'ccivr.ivrflow.operate.replace.success':'Erfolgreich ersetzt.',
  'ccivr.ivrflow.operate.replace.fail':'Austausch fehlgeschlagen.',
  'ccivr.ivrflow.cancelrelease.referenced':'Auf den Flow wurde mit einem Zugangscode verwiesen.',
  'ccivr.ivrflow.cancelrelease.referenced.by.idauth.survey':'Auf den Prozess wurde von einer Authentifizierungskonfiguration verwiesen und die Veröffentlichung kann nicht rückgängig gemacht werden!',
  'ccivr.ivrflow.cancelrelease.referenced.by.satisfaction.survey':'Der Flow wurde von der Konfiguration der Zufriedenheitsumfrage referenziert und kann nicht gelöscht werden!',
  'ccivr.ivr.error.releaseFailed':'Das Skript konnte nicht automatisch veröffentlicht werden',
  'ccivr.ivrflow.cancelrelease.error':'Der Flow wird von einer Skill-Warteschlange referenziert!',
  'ccivr.ivrflow.flowAccessCode':'FlowAccessCode',
  'ccivr.ivrflow.unlock':'Entsperren',
  'ccivr.ivrflow.unlock.confirm':'Entsperrstatus in Bearbeitung bestätigen?',
  'ccivr.ivrflow.unlock.success':'Entsperrter Status erfolgreich',
  'ccivr.ivrflow.unlock.fail':'Entsperrungsstatus fehlgeschlagen',
  'ccivr.ivr.ivrflow.operate.ensureclone':'Flow-Upgrade bestätigen',
  'ccivr.ivr.ivrflow.operate.ensureclone.info':'Möchten Sie den Flow wirklich aktualisieren?',
  'ccivr.ivr.ivrflow.operate.clone.success':'Der Upgrade-Vorgang war erfolgreich.',
  'ccivr.ivr.ivrflow.operate.clone.fail':'Flow konnte nicht aktualisiert werden.',
  'ccivr.ivr.ivrflow.ivrname':'Name',
  'ccivr.ivr.ivrusage.ivrname':'Referenzszenario',
  'ccivr.ivr.ivrusage.skillName':'Skill-Warteschlangenname',
  'ccivr.ivr.ivrflow.placeholder.ivrname':'Geben Sie einen Flow-Namen ein.',
  'ccivr.ivr.ivrflow.ivrtype':'Typ',
  'ccivr.ivr.ivrflow.mainflow':'Hauptfluss',
  'ccivr.ivr.ivrflow.subflow':'Subflow',
  'ccivr.ivr.ivrflow.exceptionHandingFlow':'Ablauf bei der Handhabung von Ausnahmen',
  'ccivr.ivr.ivrflow.new':'Neu',
  'ccivr.ivr.ivrflow.edit':'Bearbeitung',
  'ccivr.ivr.ivrflow.unreleased':'Unveröffentlicht',
  'ccivr.ivr.ivrflow.alreadyreleased':'Offizielle Freigabe',
  'ccivr.ivr.ivrflow.flowquote':'Ist referenziert',
  'ccivr.ivr.ivrflow.yes':'Ja',
  'ccivr.ivr.ivrflow.no':'Nein',
  'ccivr.ivr.ivrflow.selectivrflow':'Wählen Sie einen IVR-Fluss aus.',
  'ccivr.ivr.ivrflow.batchdeleteconfim':'Möchten Sie wirklich alle ausgewählten IVR-Flows löschen? Stellen Sie sicher, dass der IVR-Fluss nicht freigegeben wird.',
  'ccivr.ivr.ivrflow.deleteconfim':'Möchten Sie den IVR-Flow wirklich löschen?',
  'ccivr.ivrflow.error.deleteivrflow.released':'Der ausgewählte IVR-Fluss wurde freigegeben. Brechen Sie die Freigabe ab, bevor Sie sie löschen.',
  'ccivr.ivrflow.error.deleteivrflow.part.released':'Einige der ausgewählten IVR-Flows wurden veröffentlicht. Brechen Sie die Freigabe ab, bevor Sie sie löschen.',
  'ccivr.ivrflow.error.deleteivrflow.association':'Der Flow ist einem anderen Flow zugeordnet und kann nicht gelöscht werden.',
  'ccivr.ivrflow.error.deleteivrflow.fail':'Der Fluss existiert nicht.',
  'ccivr.ivrflow.createivrflow.mainfail':'Der Hauptfluss existiert bereits. Hinzufügen fehlgeschlagen.',
  'ccivr.ivrflow.createivrflow.exceptionfail':'Der Ablauf der Ausnahmebehandlung ist bereits vorhanden. Hinzufügen fehlgeschlagen!',
  'ccivr.ivrflow.createivrflow.sameivrflowname':'Der Flow-Name ist bereits vorhanden!',
  'ccivr.ivrflow.createivrflow.fail':'Hinzufügen fehlgeschlagen.',
  'ccivr.ivr.ivrflow.filter':'Filtern',
  'ccivr.ivr.ivrflow.filtercondition':'Filterkriterien',
  'ccivr.ivrflow.updateivrinfo.success':'Die Attribute des IVR-Flusses wurden erfolgreich geändert.',
  'ccivr.ivrflow.updateivrinfo.fail':'IVR-Flow-Attribute konnten nicht geändert werden.',
  'ccivr.ivrflow.updateivrinfo.editing':'Der Flow wird bearbeitet und kann nicht geändert werden.',
  'ccivr.ivrflow.updateivrinfo.released':'Der Fluss wurde freigegeben. Brechen Sie die Freigabe ab, und ändern Sie die Attribute.',
  'ccivr.ivr.ivrflow.version':'Versionsnummer',
  'ccivr.ivr.ivrflow.property':'Attribut',
  'ccivr.ivr.ivrflow.clone':'Upgrade',
  'ccivr.ivrflow.ivrflowname.specialStrValidateFailed':'Der Wert darf keine Sonderzeichen enthalten und die Länge darf 50 Zeichen nicht überschreiten.',
  'ccivr.ivrflow.ivrflowname.specialStrValidateFailed128':'Der Wert darf keine Sonderzeichen enthalten und die Länge darf 128 Zeichen nicht überschreiten.',
  'ccivr.ivr.ivrflow.operate.create.accesscode.fail':'Der Zugangscode konnte während der Freigabe des IVR-Flusses nicht in ODFS hinzugefügt werden.',
  'ccivr.ivrflow.cancelrelease.accesscode.delete.failed':'Der Zugangscode in ODFS konnte beim Abbruch des freigegebenen IVR-Flusses nicht gelöscht werden.',
  'ccivr.ivrflow.ivrflowtype.validateFailed':'Falscher Flusstyp.',
  'ccivr.ivrflow.release.grayrule':'Gray Release',
  'ccivr.ivrflow.release.formal':'Offizielle Freigabe',
  'ccivr.ivrflow.grayrule.rule':'Graue Regel',
  'ccivr.ivrflow.grayrule.grayinfo':'Wenn eine Zahl mit einer grauen Regel übereinstimmt, wird der Flow dieser Version ausgeführt.<br /> Für Zahlen, die nicht mit den grauen Regeln übereinstimmen, wird der offiziell freigegebene Flow ausgeführt.<br /> Wenn es keinen offiziell freigegebenen Flow gibt, die Hinrichtung scheitert.',
  'ccivr.ivrflow.release.formalinfo':'Nach der Freigabe wird der Fluss dieser Version für alle Nummern ausgeführt. <br />Wenn Sie den Flow ändern müssen, müssen Sie ein Upgrade auf eine neue Version durchführen und die Version ändern.',
  'ccivr.ivrflow.grayrule.rulename':'Regelname',
  'ccivr.ivrflow.grayrule.numrange':'Nummernsegment',
  'ccivr.ivrflow.grayrule.bindingflow':'Gebundener Fluss:',
  'ccivr.ivrflow.grayrule.name':'Regelname',
  'ccivr.ivrflow.grayrule.beginnum':'Startnummer',
  'ccivr.ivrflow.grayrule.endnum':'Endnummer',
  'ccivr.ivrflow.grayrule.inputrulename':'Geben Sie den Regelnamen ein.',
  'ccivr.ivrflow.grayrule.inputbeginnum':'Beispiel: 12366668888',
  'ccivr.ivrflow.grayrule.inputendnum':'Die Endnummer muss größer oder gleich der Startnummer sein.',
  'ccivr.ivrflow.grayrule.inputendlength':'Die Länge der Startnummer muss der Länge der Endnummer entsprechen.',
  'ccivr.ivrflow.grayrule.selectgrayrule':'Wählen Sie eine graue Regel aus.',
  'ccivr.ivrflow.grayrule.existgrayflow':'Die graue Release-Version existiert bereits!',
  'ccivr.ivrflow.grayrule.editsuccess':'Geändert erfolgreich.',
  'ccivr.ivrflow.grayrule.info':'Details',
  'ccivr.ivrflow.variable.settings':'Variable Einstellungen',
  'ccivr.ivrflow.variable.selectvariable':'Wählen Sie einen variablen Parameter aus.',
  'ccivr.ivrflow.variable.typeerror':'Inkonsistente Eingabevariablentypen.',
  'ccivr.ivr.voicereview.choosetenantspace':'Wählen Sie einen Mieter aus.',
  'ccivr.ivr.voicereview.choosevoicetype':'Typ',
  'ccivr.ivr.voicereview.waitreview':'Zur Überprüfung',
  'ccivr.ivr.voicereview.search':'Suche',
  'ccivr.ivr.voicereview.reset':'Zurücksetzen',
  'ccivr.ivr.voicereview.refresh':'Aktualisieren',
  'ccivr.ivr.voicereview.tenantspace':'Wohnungsmieter',
  'ccivr.ivr.voicereview.name':'Name',
  'ccivr.ivr.voicereview.filename':'Dateiname',
  'ccivr.ivr.voicereview.filetype':'Typ',
  'ccivr.ivr.voicereview.language':'Sprache',
  'ccivr.ivr.voicereview.scene':'Nutzungsszenario',
  'ccivr.ivr.voicereview.uploadtime':'Hochladezeit',
  'ccivr.ivr.voicereview.upload':'Hochgeladen',
  'ccivr.ivr.voicereview.reviewstatus':'Status',
  'ccivr.ivr.voicereview.reviewsuggestion':'Kommentare',
  'ccivr.ivr.voicereview.voiceId':'Stimmen-ID',
  'ccivr.ivr.voicereview.operat':'Operation',
  'ccivr.ivr.voicereview.play':'Spielen',
  'ccivr.ivr.voicereview.pass':'Genehmigen',
  'ccivr.ivr.voicereview.filter':'Filtern',
  'ccivr.ivr.voicereview.refuse':'Ablehnen',
  'ccivr.ivr.voicereview.voice':'Stimme',
  'ccivr.ivr.voicereview.text':'Text',
  'ccivr.ivr.voicereview.edit':'Bearbeiten',
  'ccivr.ivr.voicereview.nosubmit':'Nicht eingereicht',
  'ccivr.ivr.voicereview.passed':'Genehmigt',
  'ccivr.ivr.voicereview.notpass':'Abgelehnt',
  'ccivr.ivr.voicereview.all':'Alle',
  'ccivr.ivr.voicereview.vedio':'Video',
  'ccivr.ivr.voicereview.filePath':'Dateipfad:',
  'ccivr.ivr.voicereview.textContent':'Textinhalt',
  'ccivr.ivr.voicereview.chinese':'Chinesisch',
  'ccivr.ivr.voicereview.english':'Englisch',
  'ccivr.ivr.voicereview.thailand':'Thailand',
  'ccivr.ivr.voicereview.spanish':'Spanisch',
  'ccivr.ivr.voicereview.portuguese':'Portugiesisch',
  'ccivr.ivr.voicereview.french':'Französisch',
  'ccivr.ivr.voicereview.arabic':'Arabisch',
  'ccivr.ivr.voicereview.german':'Deutsch',
  'ccivr.ivr.voicereview.language0':'Chinesisch',
  'ccivr.ivr.voicereview.language1':'Englisch',
  'ccivr.ivr.voicereview.language24':'Thailand',
  'ccivr.ivr.voicereview.language17':'Portugiesisch',
  'ccivr.ivr.voicereview.language20':'Spanisch',
  'ccivr.ivr.voicereview.language7':'Französisch',
  'ccivr.ivr.voicereview.language81':'Arabisch',
  'ccivr.ivr.voicereview.language8':'Deutsch',
  'ccivr.ivr.voiceadvice.close':'Schließen',
  'ccivr.ivr.voiceadvice.audition':'Audition',
  'ccivr.ivr.voiceadvice.cancel':'Abbrechen',
  'ccivr.ivr.voiceadvice.confirm':'OK',
  'ccivr.ivr.voiceadvice.currentdefault':'Standard',
  'ccivr.ivr.voiceadvice.setdefault':'Als Standard festlegen',
  'ccivr.ivr.voiceadvice.submit':'Senden',
  'ccivr.ivr.voiceadvice.lengthError':'Die Länge darf 100 Zeichen nicht überschreiten.',
  'ccivr.ivr.variable.int':'Ganzzahl',
  'ccivr.ivr.variable.string':'String',
  'ccivr.ivr.variable.name':'Variable',
  'ccivr.ivr.variable.defaultValue':'Standardwert',
  'ccivr.ivr.variable.management':'Variables Management',
  'ccivr.ivr.variable.addvariable':'Variable hinzufügen',
  'ccivr.ivr.variable.modifyvariable':'Variable ändern',
  'ccivr.ivr.variable.idisnull':'Die ID ist leer.',
  'ccivr.ivr.variable.nameisnull':'Der Name ist leer.',
  'ccivr.ivr.variable.typeisnull':'Der Typ ist leer.',
  'ccivr.ivr.variable.variableisreferenced':'Variablen werden durch Fluss referenziert.',
  'ccivr.ivr.variable.deleteselectvariable':'Variable löschen',
  'ccivr.ivr.variable.willdeleteselectvariable':'Möchten Sie die ausgewählten Variablen wirklich löschen?',
  'ccivr.ivr.variable.descvalueerror':'Die Beschreibung enthält Sonderzeichen. Bitte ändern Sie es.',
  'ccivr.ivr.variable.defaultvalueerror':'Der Standardwert enthält Sonderzeichen. Bitte ändern Sie es.',
  'ccivr.ivr.variable.errorspecial':'Der Typ existiert nicht.',
  'ccivr.ivr.variable.desclimit':'Die Beschreibung darf nicht mehr als 120 Zeichen enthalten.',
  'ccivr.ivr.variable.defaultlimit':'Der Standardwert darf nicht mehr als 120 Zeichen enthalten.',
  'ccivr.ivr.variable.defaulttypeerror':'Der Standardwerttyp stimmt nicht mit dem Variablentyp überein.',
  'ccivr.ivr.variable.textvaluelimit':'Der Text darf nicht mehr als 120 Zeichen enthalten.',
  'ccivr.ivr.variable.namevaluerule':'Der Name enthält Sonderzeichen. Bitte ändern Sie es.',
  'ccivr.ivr.element.namecheck':'Der Name enthält Sonderzeichen. Bitte ändern Sie es.',
  'ccivr.ivr.variable.specialcharacters':'Sonderzeichen oder chinesische Zeichen sind nicht zulässig.',
  'ccivr.ivr.voiceupload.defaultNoDe':'Die Standardsprache kann nicht gelöscht werden.',
  'ccivr.ivr.voiceupload.onlyOneData':'Führen Sie mindestens eine Sprachaufzeichnung.',
  'ccivr.ivr.voiceupload.texttovoice':'Der eingegebene Text wird automatisch in eine Stimme umgewandelt.',
  'ccivr.ivr.voiceupload.selectvoice':'Wählen Sie eine Sprachdatei aus.',
  'ccivr.ivr.voiceupload.selectvideo':'Wählen Sie eine Videodatei aus.',
  'ccivr.ivr.voiceupload.recording':'Aufzeichnung',
  'ccivr.ivr.voiceupload.stoprecording':'Stopp',
  'ccivr.ivr.voiceupload.playrecord':'Spielen',
  'ccivr.ivr.voiceupload.playtype':'Sprachwiedergabemodus',
  'ccivr.ivr.voiceupload.languagemanage':'Sprachenmanagement',
  'ccivr.ivr.voiceupload.new':'Neu',
  'ccivr.ivr.voiceupload.delete':'Löschen',
  'ccivr.ivr.voiceupload.deletefail':'Löschen fehlgeschlagen.',
  'ccivr.ivr.voiceupload.deleteodfsivrfail':'Löschen fehlgeschlagen. Das IVR ist besetzt',
  'ccivr.ivr.voiceupload.deletereferenceivrfail':'Das Löschen der Stimme schlug fehl. Die Stimme wurde referenziert.',
  'ccivr.ivr.voiceupload.submit':'Senden',
  'ccivr.ivr.voiceupload.chinese':'Chinesisch',
  'ccivr.ivr.voiceupload.english':'Englisch',
  'ccivr.ivr.voiceupload.pleaseuploadfile':'Die Datei ist leer. Bitte importieren Sie die Datei.',
  'ccivr.ivr.voiceupload.pleaserecording':'Laden Sie die Datei nach der Aufnahme hoch.',
  'ccivr.ivr.voiceupload.formatwrong':'Falsches Dateiformat. Importieren Sie eine Datei im WAV- oder MP3-Format.',
  'ccivr.ivr.voiceupload.vedioformatwrong':'Das Dateiformat ist falsch. Importieren Sie eine 3GP-Datei.',
  'ccivr.ivr.voiceupload.fileheaderwrong':'Das ursprüngliche Sprachdateiformat ist nicht WAV oder MP3. Laden Sie eine weitere Sprachdatei hoch.',
  'ccivr.ivr.voiceupload.vedioheaderwrong':'Das ursprüngliche Videodateiformat ist nicht 3gp. Laden Sie eine weitere Videodatei hoch.',
  'ccivr.ivr.voiceupload.vedionamewrong':'FileNmae-Überprüfung beim Hochladen der Datei fehlgeschlagen!',
  'ccivr.ivr.voiceupload.sizewrong':'Die Dateigröße überschreitet 2 MB. Importieren Sie die Datei erneut.',
  'ccivr.ivr.voiceupload.isconfdelete':'Möchten Sie es wirklich löschen?',
  'ccivr.ivr.voiceupload.tipformat':'Die Formate MP3 und WAV werden unterstützt, und die Dateigröße darf 12 MB nicht überschreiten.',
  'ccivr.ivr.voiceupload.tipformat1M':'Die Formate MP3 und WAV werden unterstützt, und die Dateigröße darf 1 MB nicht überschreiten.',
  'ccivr.ivr.voiceupload.amountLimit5':'In diesem Szenario können maximal fünf Sprachdateien hinzugefügt werden.',
  'ccivr.ivr.voiceupload.fileformatconverterror':'Bei der Konvertierung des Dateiformats ist ein Fehler aufgetreten, oder die FFmpeg-Konfiguration ist falsch.',
  'ccivr.ivr.voiceupload.exceedLimit':'Die Anzahl der IVR-Dateien überschreitet den Grenzwert.',
  'ccivr.ivr.voiceupload.tipvideoformat':'Derzeit werden nur 3GP-Videodateien unterstützt. Die Dateigröße darf 5 MB nicht überschreiten.',
  'ccivr.ivr.voiceupload.tipvideomodel':'Man kann Sprach- oder Videodateien in verschiedenen Sprachen gleichzeitig einstellen. Während der Videowiedergabe spielt das System automatisch die entsprechende Videodatei auf der Grundlage der vom Benutzer ausgewählten Sprache ab.',
  'ccivr.ivr.voiceupload.tipmodel':'Sie können Sprachdateien oder Text in verschiedenen Sprachen gleichzeitig einstellen (die maximale Länge darf 1024 Bytes nicht überschreiten). Während der Sprachwiedergabe spielt das System automatisch die entsprechende Sprachdatei auf der Grundlage der vom Benutzer gewählten Sprache ab.',
  'ccivr.ivr.voiceupload.VoiceNotificationtipmodel':'Sie können Sprachdateien oder Text in verschiedenen Sprachen gleichzeitig einstellen (die maximale Länge darf 500 Byte nicht überschreiten). Während der Sprachwiedergabe spielt das System automatisch die entsprechende Sprachdatei auf der Grundlage der vom Benutzer gewählten Sprache ab.',
  'ccivr.ivr.voiceupload.VoiceNotificationTextDes':'Der Vorlageninhalt darf nicht mit einer Variablen beginnen. Eine Variable kann maximal 32 Byte enthalten. Wenn eine Variable mehr als 32 Byte enthält, wird empfohlen, sie in mehrere Variablen aufzuteilen.\nDie folgenden Variablen sind supported:\n{\'$\'}{\'{\'}TXT_Digit{\'}\'}: Zeichenfolgen mit der maximalen Länge, die durch eine Zahl angegeben wird, mit der maximalen Länge, die durch eine Zahl angegeben wird. (Für eine Zahl gibt example, {\'$\'}{\'{\'}NUM_6{\'}\'} mit maximal sechs digits.)\n{\'$\'}{\'{\'}DATE{\'}\'}:-Daten in JJJJ/MM/TT format\n{\'$\'}{\'{\'}TIME{\'}\'}:-Zeit in HH:MM oder HH:MM:SS muss format\n{\'$\'}{DATE} und Digit\n{\'$\'}{\'{\'}NUM_Digit{\'}\'}: durch ein Komma getrennt werden (,).',
  'ccivr.ivr.voiceupload.VoiceNotificationTextrule':'Für weitere Sprachbenachrichtigungsvorlagenregeln bewegen Sie den Cursor über das i-Symbol im Textfeld.',
  'ccivr.ivr.voiceupload.auditionTextlimit':'Der Vorschautextinhalt darf maximal 300 Byte enthalten.',
  'ccivr.ivr.voiceupload.audioformat':'Audiodateiformat: ALAW, 8 Bit, 8000 Hz, 64 Kbit/s, mono.',
  'ccivr.ivr.voiceupload.audiotypefailed':'Die Sprachdatei liegt nicht im ALAW-Format vor.',
  'ccivr.ivr.voiceupload.audiochannelfailed':'Die Sprachdatei ist keine Mono-Sprachdatei.',
  'ccivr.ivr.voiceupload.audiosampleratefailed':'Die Abtastrate der Sprachdatei beträgt nicht 8000 Hz.',
  'ccivr.ivr.voiceupload.audiosamplebitfailed':'Die Anzahl der Abtastbits für die Sprachdatei ist nicht 8.',
  'ccivr.ivr.voiceupload.audiofilereadfailed':'Sprachdateiattribute konnten nicht gelesen werden.',
  'ccivr.ivr.voiceupload.authvalidatefailed':'Überprüfung der Berechtigung zum Hochladen der Datei fehlgeschlagen.',
  'ccivr.ivr.voiceupload.fileinjectionerror':'Ausnahme des Dateiinjektionsrisikos überprüfen.',
  'ccivr.ivr.voiceupload.fileioerror':'Datei-E/A-Ausnahme.',
  'ccivr.ivr.voiceupload.tmpformaterror':'Falscher temporärer Dateityp.',
  'ccivr.ivr.voiceupload.checkuploadparmfailed':'Parameterprüfung beim Hochladen der Datei fehlgeschlagen.',
  'ccivr.ivr.voiceupload.checkcallcenterparamfailed':'Callcenter-Parameterprüfung fehlgeschlagen.',
  'ccivr.ivr.voiceupload.uploadfiletosftpfailed':'Hochladen der Datei auf sftp fehlgeschlagen.',
  'ccivr.ivr.voiceupload.opreationdbfailed':'Die IVR-Sprachnachricht der Datenbank konnte nicht aktualisiert werden.',
  'ccivr.ivr.voiceupload.referenceFlow':'Referenzflussinformationen',
  'ccivr.ivr.voiceupload.ivrVoiceUsage':'Referenzinformationen',
  'ccivr.ivr.voiceupload.referenceText':'Textinformationen der Sprachbenachrichtigung',
  'ccivr.ivr.voiceupload.queryReferenceFlow':'Ansicht',
  'ccivr.ivr.voiceupload.query':'Detail',
  'ccivr.ivr.voiceupload.selectLangNull':'Es wurde keine Sprache ausgewählt. Bitte wählen Sie eine Sprache aus.',
  'ccivr.ivr.voiceupload.selectLangRe':'Die aktuelle Sprache wurde ausgewählt. Bitte wählen Sie eine andere Sprache aus.',
  'ccivr.ivr.voiceedit.idisnull':'Die ID ist leer.',
  'ccivr.ivr.voiceedit.deleteoldfilefailed':'Löschen der alten Datei fehlgeschlagen.',
  'ccivr.ivr.voiceedit.statusisnull':'Der Statusparameter ist null.',
  'ccivr.ivr.voiceedit.statuserror':'Der Statusparameter ist fehlerhaft.',
  'ccivr.ivr.voiceedit.filenameisnull':'Dateiname ist null.',
  'ccivr.ivr.voiceedit.filenameformaterror':'Dateinamensformat ist falsch.',
  'ccivr.ivr.voiceedit.filenamenull':'Dateinameninhalt ist null.',
  'ccivr.ivr.voiceedit.filenamecheckfail':'Validierung des Dateinamens fehlgeschlagen.',
  'ccivr.ivr.voiceedit.querycallcenterfailed':'Die Abfrage der SFTP-Informationen des Callcenters ist fehlgeschlagen.',
  'ccivr.ivr.voiceedit.sftpinfoisnull':'Die SFTP-Informationen des Callcenters sind null.',
  'ccivr.ivr.voiceedit.passwdisnull':'Die Abfrage der SFTP-Passwortinformationen des Callcenters ist fehlgeschlagen.',
  'ccivr.ivr.voiceedit.pathisnull':'Die Abfrage der SFTP-Pfadinformationen des Callcenters ist fehlgeschlagen.',
  'ccivr.ivr.voiceedit.pathcheckfailed':'Die Validierung der SFTP-Pfadinformationen ist fehlgeschlagen.',
  'ccivr.ivr.voiceedit.choosefile':'Datei auswählen',
  'ccivr.ivr.voiceedit.cancel':'Abbrechen',
  'ccivr.ivr.voiceedit.import':'Importieren',
  'ccivr.ivr.voiceedit.inputmore':'Der Wert darf nicht überschreiten',
  'ccivr.ivr.voiceedit.addsuccess':'Wird erfolgreich hinzugefügt.',
  'ccivr.ivr.voiceedit.addfail':'Hinzufügen fehlgeschlagen.',
  'ccivr.ivr.voiceedit.deletesuccess':'Erfolgreich gelöscht.',
  'ccivr.ivr.voiceedit.deleteodfsivrfail':'Löschen fehlgeschlagen. Die IVR-Daten sind belegt!',
  'ccivr.ivr.voiceedit.tip':'Aufforderung',
  'ccivr.ivr.voiceedit.editsuccess':'Erfolgreich bearbeitet.',
  'ccivr.ivr.voiceedit.editfail':'Bearbeitung fehlgeschlagen.',
  'ccivr.ivr.voiceedit.updatefail':'Update fehlgeschlagen.',
  'ccivr.ivr.voiceedit.inputtext':'Text eingeben.',
  'ccivr.ivr.message.uploadimport.success':'Wird erfolgreich hinzugefügt.',
  'ccivr.ivr.success.import':'Wird erfolgreich hinzugefügt.',
  'ccivr.ivr.pageurls.errorsave':'Fehler',
  'ccivr.ivr.description.limit':'Die Textbeschreibung darf nicht mehr als 1024 Byte enthalten.',
  'ccivr.ivr.description.voiceNotificationlimit':'Die Textbeschreibung darf nicht mehr als 500 Byte enthalten.',
  'ccivr.ivr.description.variableerror':'Das Format der Vorlagenvariablen ist falsch.',
  'ccivr.ivr.description.variablelimit':'Vorlagenvariablen dürfen 10 nicht überschreiten.',
  'ccivr.ivr.description.containspecialcharacter':'Die Vorlage enthält Sonderzeichen ~',
  'ccivr.ivr.voice.filechoose':'Wählen Sie eine Sprachdatei aus',
  'ccivr.ivr.voice.vediochoose':'Wählen Sie eine Videodatei aus',
  'ccivr.ivr.changtovoice':'TTS',
  'ccivr.ivr.voice.scene':'Nutzungsszenario',
  'ccivr.ivr.voice.speed':'Geschwindigkeit',
  'ccivr.ivr.voice.volume':'Volumen',
  'ccivr.ivr.voice.pitch':'Pitch',
  'ccivr.ivr.voice.voicename':'Timbre',
  'ccivr.ivr.voice.scene.note':'Ton',
  'ccivr.ivr.voice.scene.error':'Fehler',
  'ccivr.ivr.voice.scene.questionnaires':'Fragebogen',
  'ccivr.ivr.voice.scene.beforeReportWorkNo':'vor der Meldung der Mitarbeiter-ID',
  'ccivr.ivr.voice.scene.afterReportWorkNo':'nach Meldung der Mitarbeiter-ID',
  'ccivr.ivr.voice.fileupload':'Datei hochladen',
  'ccivr.ivr.voice.recordupload':'Online-Aufzeichnung',
  'ccivr.ivr.voice.clicktorecord':'Aufzeichnen klicken',
  'ccivr.ivr.voice.clicktostop':'Auf Stopp klicken',
  'ccivr.ivr.voice.recordtimeout.fail':'Aufnahme-Timeout.',
  'ccivr.ivr.voice.recordtimeout.failmes':'Die maximale Aufnahmedauer beträgt 2 Minuten. Erneut aufnehmen.',
  'ccivr.ivr.voice.exception.recordfail':'Aufzeichnungsfehler.',
  'ccivr.ivr.voice.exception.recordfailmessage':'Aufnahmefehler. Überprüfen Sie, ob Sie die Berechtigung zum Mikrofonieren haben.',
  'ccivr.ivr.success':'Erfolg',
  'ccivr.ivr.fail':'Versagen',
  'ccivr.ivr.textcontent.notnull':'Textinformationen dürfen nicht leer sein.',
  'ccivr.ivr.name.errorspecial':'Der Name enthält Sonderzeichen. Bitte ändern Sie es.',
  'ccivr.ivr.name.existence':'Der Name existiert bereits. Bitte geben Sie eine andere ein.',
  'ccivr.ivr.error.sftp':'Die SFTP-Informationen sind falsch.',
  'ccivr.ivr.error.download':'Datei konnte nicht vom SFTP-Server heruntergeladen werden.',
  'ccivr.ivr.error.connect':'Verbindung zum SFTP-Server konnte nicht hergestellt werden.',
  'ccivr.ivr.error.other':'Download fehlgeschlagen.',
  'ccivr.ivr.name.notnull':'Der Name darf nicht leer bleiben.',
  'ccivr.ivr.name.toolong':'Der Name überschreitet die maximal zulässige Länge. Bitte versuchen Sie es erneut.',
  'ccivr.ivr.error.uncommitted':'Der Systemadministrator kann die nicht eingereichte Datei nicht wiedergeben.',
  'ccivr.ivr.error.overpower':'Der Mieter ist nicht autorisiert.',
  'ccivr.ivr.error.advicelimit':'Der Bewertungskommentar darf nicht mehr als 100 Zeichen enthalten.',
  'ccivr.ivr.error.paramlimit':'Die Gesamtlänge des Parameters darf 4000 Byte nicht überschreiten.',
  'ccivr.ivr.error.paramNumlimit':'Die Gesamtzahl der Parameter darf 16 nicht überschreiten.',
  'ccivr.ivr.voiceupload.fileovermax':'Die Anzahl der zu verwaltenden IVR-Stimmen überschreitet das Maximum.',
  'ccivr.ivr.bussinessinvoke.bussinessinvoke':'Schnittstellenkonfiguration',
  'ccivr.ivr.bussinessinvoke.interfacename':'Schnittstellenname',
  'ccivr.ivr.bussinessinvoke.URL':'URL anfordern',
  'ccivr.ivr.bussinessinvoke.interfacedesc':'Schnittstellenbeschreibung',
  'ccivr.ivr.bussinessinvoke.updateinterface':'Schnittstelle bearbeiten',
  'ccivr.ivr.bussinessinvoke.createinterface':'Schnittstelle erstellen',
  'ccivr.ivr.bussinessinvoke.basicconfig':'Basiskonfiguration',
  'ccivr.ivr.bussinessinvoke.interfacetype':'Schnittstellentyp',
  'ccivr.ivr.bussinessinvoke.int':'Ganzzahl',
  'ccivr.ivr.bussinessinvoke.string':'Zeichenkette',
  'ccivr.ivr.bussinessinvoke.savesuccess':'Erfolgreich gespeichert.',
  'ccivr.ivr.bussinessinvoke.saveserror':'Speichern fehlgeschlagen.',
  'ccivr.ivr.bussinessinvoke.validate':'Verifizierungsfehler',
  'ccivr.ivr.bussinessinvoke.validatemessage':'Ein Parameter mit demselben Namen ist bereits vorhanden.',
  'ccivr.ivr.bussinessinvoke.error':'Fehler',
  'ccivr.ivr.bussinessinvoke.errormessage':'Wählen Sie die zu löschende Schnittstelle aus.',
  'ccivr.ivr.bussinessinvoke.save':'Speichern',
  'ccivr.ivr.bussinessinvoke.cancel':'Abbrechen',
  'ccivr.ivr.bussinessinvoke.tip':'Geben Sie einen Wert oder eine Variable ein',
  'ccivr.ivr.tracelog.traceConfig':'Zugriffscode-Trace-Konfiguration',
  'ccivr.ivr.tracelog.accessCode':'Zugangscode für den Fluss',
  'ccivr.ivr.tracelog.addAccessCode':'Zugangscode hinzufügen',
  'ccivr.ivr.tracelog.title':'TraceLog',
  'ccivr.ivr.tracelog.callingNumber':'CallingNumber',
  'ccivr.ivr.tracelog.callingNumberplaceHolder':'Geben Sie die Anrufnummer ein',
  'ccivr.ivr.tracelog.addcallingnumber':'CallingNumber hinzufügen',
  'ccivr.ivr.tracelog.deleteconfirminfo':'Ob die aktuelle Anrufnummer gelöscht werden soll:',
  'ccivr.ivr.tracelog.selecttip':'Wählen Sie die Anrufnummer aus!',
  'ccivr.ivr.tracelog.deleteconfirminfocode':'Ob der aktuelle Zugangscode gelöscht werden soll:',
  'ccivr.ivr.tracelog.selecttipcode':'Wählen Sie den Zugangscode aus!',
  'ccivr.ivr.tracelog.index':'Index',
  'ccivr.ivr.tracelog.callid':'Angerufen',
  'ccivr.ivr.tracelog.calledNumber':'CalledNumber',
  'ccivr.ivr.tracelog.flowName':'FlowName',
  'ccivr.ivr.tracelog.createTime':'Eingabe der Durchflusszeit',
  'ccivr.ivr.tracelog.errorInfo':'Fehlermeldung',
  'ccivr.ivr.tracelog.callInfo':'Anrufinformationen',
  'ccivr.ivr.tracelog.cellType':'Elementtyp',
  'ccivr.ivr.tracelog.nodeName':'Elementname',
  'ccivr.ivr.tracelog.result':'Ergebnisse des Flusses',
  'ccivr.ivr.tracelog.transitionOn':'Exportniederlassung',
  'ccivr.ivr.tracelog.actionParams':'Schlüsselparameter der Zelle',
  'ccivr.ivr.tracelog.errorpop':'Abfrage fehlgeschlagen!',
  'ccivr.ivr.tracelog.addFailed':'Hinzufügen fehlgeschlagen!',
  'ccivr.ivr.tracelog.deleteFailed':'Löschen fehlgeschlagen!',
  'ccivr.ivr.tracelog.addSuccess':'Erfolgreich hinzugefügt!',
  'ccivr.ivr.tracelogprompt':'Dieser Vorgang beeinträchtigt die Systemleistung. Diese Konfiguration wird nach {0} Stunde automatisch gelöscht. Möchten Sie fortfahren?',
  'ccivr.ivr.tracelog.deleteSuccess':'Erfolgreich gelöscht!',
  'ccivr.ivr.tracelog.maxNumberValidate':'Die Anzahl der Anrufnummern überschreitet die Obergrenze!',
  'ccivr.ivr.tracelog.sameNumberValidate':'Dieselbe Anrufnummer existiert bereits!',
  'ccivr.ivr.tracelog.calledNumbererror':'Die Anrufnummer konnte nicht verifiziert werden!',
  'ccivr.ivr.tracelog.result.finshed':'Fertig gestellt',
  'ccivr.ivr.tracelog.result.transsuccess':'Erfolgreich',
  'ccivr.ivr.tracelog.result.transfailed':'Nicht bestanden',
  'ccivr.ivr.tracelog.result.recordend':'Erfolgsaufzeichnung',
  'ccivr.ivr.tracelog.result.playover':'Play-Erfolg',
  'ccivr.ivr.tracelog.result.timeout':'Zeitüberschreitung',
  'ccivr.ivr.tracelog.result.syserr':'Systemfehler',
  'ccivr.ivr.tracelog.result.hangup':'HangUp',
  'ccivr.ivr.tracelog.result.default':'Standard',
  'ccivr.nms.interface.urltip':'Verwenden Sie die URL, die mit HTTP oder HTTPS beginnt.',
  'ccivr.nms.interface.certFile':'Zertifikatsdatei',
  'ccivr.nms.label.selectCert':'Zertifikat auswählen',
  'ccivr.common.warning.message':'Wählen Sie einen Datensatz aus.',
  'ccivr.nms.interface.warn':'Das von der Schnittstelle verwendete Netzwerkprotokoll ist nicht sicher und kann Sicherheitsrisiken bergen.',
  'ccivr.nms.interface.paramvalidate':'Parameter dürfen nur Zahlen, englische Zeichen oder _- enthalten',
  'ccivr.nms.interface.paramKeyValidate':'Verwenden Sie nicht den Parameternamen, der durch die integrierte Variable definiert ist (callId, businessReqId, CallSorId, CurrentTime). Andernfalls wird die Wertzuweisung ungültig.',
  'ccivr.nms.interface.outparamvalidate':'Der Parameterwert darf nur Ziffern, Buchstaben und die folgenden Sonderzeichen enthalten:._-',
  'ccivr.nms.interface.nmsexception':'Kommunikation mit ODFS trifft Ausnahme! Bitte überprüfen Sie die Konfiguration.',
  'ccivr.nms.interface.whitelistcheckfailed':'Die URL (IP-Adresse und Portnummer) ist nicht in der Vertrauensliste enthalten. Die Schnittstelle kann erst aufgerufen werden, nachdem Sie sich an den Systemadministrator gewandt haben, um die URL der Vertrauensliste hinzuzufügen. Fortfahren?',
  'ccivr.nms.interface.whitelistwarn':'Die URL (IP-Adresse und Portnummer) ist nicht in der Vertrauensliste enthalten. Die Schnittstelle kann erst aufgerufen werden, nachdem Sie sich an den Systemadministrator gewandt haben, um die URL der Vertrauensliste hinzuzufügen.',
  'ccivr.nms.varibalesselect.globalvariables':'Globale Variable',
  'ccivr.nms.varibalesselect.processvaribales':'Flussvariable',
  'ccivr.nms.varibalesselect.sysvariables':'Systemvariable',
  'ccivr.nms.varibalesselect.selectvariables':'Variable auswählen',
  'ccivr.nms.playservice.placeholder':'Stimmwiedergabe',
  'ccivr.nms.playservice.contentisnull':'Der Sprachinhalt darf nicht leer sein.',
  'ccivr.nms.playcollectservice.collectTimeout.error':'Ungültiges Timeout-Intervall für Ziffernsammlung (1-120).',
  'ccivr.nms.playcollectservice.enterCollectTimeout':'Geben Sie das Timeout-Intervall für die Ziffernerfassung ein.',
  'ccivr.nms.playservice.inputtitle':'Stimmaufforderung',
  'ccivr.nms.playservice.chose':'Der Wiedergabeinhalt wird aus den hochgeladenen und geprüften Audiodateien ausgewählt.',
  'ccivr.nms.playservice.chosetts':'Der abzuspielende Inhalt wird aus dem hochgeladenen und geprüften TTS-Text ausgewählt. TTS-Ressourcen sind erforderlich.',
  'ccivr.nms.playservice.chosevideo':'Der abzuspielende Inhalt wird aus den Videos ausgewählt, die hochgeladen und überprüft wurden.',
  'ccivr.nms.playservice.playfile':'Stimmendatei',
  'ccivr.nms.brach':'Zweigstelle',
  'ccivr.nms.brach.label':'Bedingung auswählen',
  'ccivr.nms.brach.duplicatebranch':'Der Zweig existiert bereits. Wählen Sie eine andere Datei aus.',
  'ccivr.nms.LanguageService.placeholder':'Wählen Sie die Sprache',
  'ccivr.nms.LanguageService.label':'Sprache',
  'ccivr.nms.ChangeMediaService.placeholder':'Medien ändern',
  'ccivr.nms.ChangeMediaService.label':'Medien',
  'ccivr.nms.userLevelService.placeholder':'Benutzerebene festlegen',
  'ccivr.nms.userLevelService.label':'Benutzerebene',
  'ccivr.nms.userLevelService.example':'Beispiel: 1-14 oder FLOW.variableName',
  'ccivr.nms.answerService.placeholder':'Antworten',
  'ccivr.nms.ringService.placeholder':'Klingeln',
  'ccivr.nms.ringService.videomodel':'Videomodus',
  'ccivr.nms.ringService.sendonly':'Nur senden',
  'ccivr.nms.ringService.recvonly':'Nur Empfangen',
  'ccivr.nms.ringService.sendrecv':'Empfang senden',
  'ccivr.nms.questionnaireService.placeholder':'Fragebogen',
  'ccivr.nms.questionnaireService.surveyname':'Name des Fragebogens',
  'ccivr.nms.questionnaireService.question':'Fragebogenfragen',
  'ccivr.nms.questionnaireService.info':'Fragebogendiagrammelemente werden in automatischen Aufgaben für ausgehende Anrufe verwendet. Der IVR-Fragebogenprozess muss verknüpft werden.',
  'ccivr.nms.process.samenamevalidate':'Doppelter Parametername:',
  'ccivr.nms.process.interfaceerr':'Schnittstellenfehler. Bitte überprüfen Sie das Protokoll.',
  'ccivr.nms.process.saveerror':'Speichern fehlgeschlagen. Den Flussknoten und die Verbindungsleitung überprüfen.',
  'ccivr.nms.process.saveerror.connection':'Speichern fehlgeschlagen. Ungültige Zeilen vorhanden.',
  'ccivr.nms.process.saveerror.noexistend':'Speichern fehlgeschlagen. Es existiert mindestens ein Enddiagrammelement.',
  'ccivr.nms.process.saveerror.noexistconnection':'Speichern fehlgeschlagen. Für Diagrammelemente mit Ausnahme des Endes und der Übergabe an Subflow-Diagrammelemente muss eine Standardlinie verfügbar sein.',
  'ccivr.nms.process.saveerror.unSucctransfer':'Speichern fehlgeschlagen. Das Transferdiagrammelement hat die Transfererfolgslinie.',
  'ccivr.nms.process.saveerror.menukeynoconnection':'Speichern fehlgeschlagen. Überprüfen Sie, ob jede Taste in der Menükonfiguration über eine Zeile verfügt.',
  'ccivr.nms.process.saveerror.noselectType':'Wählen Sie einen Abfragetyp aus.',
  'ccivr.nms.process.saveerror.noresult':'Ist nicht Entität.',
  'ccivr.nms.process.saveerror.select':'Wählen Sie einen Änderungstyp aus.',
  'ccivr.nms.process.saveerror.dataerror':'Diagrammelementdaten konnten nicht verifiziert werden.',
  'ccivr.nms.process.saveerror.existnullentity':'Es existieren leere Datendiagrammelemente.',
  'ccivr.nms.process.saveerror.alreadyreleased':'Der Flow-Status ist anormal. Bitte öffnen Sie die Leinwand erneut.',
  'ccivr.nms.process.saveerror.flownoexist':'Der Fluss existiert nicht.',
  'ccivr.nms.modulename':'Elementname',
  'ccivr.nms.add':'Neu',
  'ccivr.nms.delete':'Löschen',
  'ccivr.nms.businessinvokeservice.title':'Schnittstelle aufrufen',
  'ccivr.nms.businessinvokeservice.selectservice':'Schnittstelle auswählen',
  'ccivr.nms.businessinvokeservice.servicename':'Schnittstellenname',
  'ccivr.nms.businessinvokeservice.overtime':'Zeitüberschreitungsintervall',
  'ccivr.nms.businessinvokeservice.second':'Zweite',
  'ccivr.nms.businessinvokeservice.info1':'Die verfügbaren Schnittstellen müssen auf der Schnittstellenkonfigurationsseite konfiguriert werden.',
  'ccivr.nms.businessinvokeservice.info2':'Wenn das aufrufende Timeout-Intervall überschritten wird, wird das aufrufende Fehlermodul ausgeführt.',
  'ccivr.nms.businessinvokeservice.inpuparam':'Eingabeparameter',
  'ccivr.nms.businessinvokeservice.paramname':'Name',
  'ccivr.nms.businessinvokeservice.value':'Wert',
  'ccivr.nms.businessinvokeservice.opertaion':'Operation',
  'ccivr.nms.businessinvokeservice.outputparam':'Ausgabeparameter',
  'ccivr.nms.businessinvokeservice.inputflowValidate':'Falsche Eingabe, bitte ändern',
  'ccivr.nms.process.isParameterisEncrypt':'Verschlüsselt oder nicht',
  'ccivr.nms.process.parameterisEncrypt':'Verschlüsselung',
  'ccivr.nms.playcollectservice.title':'Stimme abspielen und Ziffer sammeln',
  'ccivr.nms.playcollectservice.voiceprompts':'Stimmaufforderung',
  'ccivr.nms.playcollectservice.voicefile':'Stimmendatei',
  'ccivr.nms.playcollectservice.ttsvoice':'TTS',
  'ccivr.nms.playcollectservice.videofile':'Videodatei',
  'ccivr.nms.playcollectservice.interrupt':'Drücken Sie eine beliebige Taste, um die Wiedergabe zu unterbrechen.',
  'ccivr.nms.playcollectservice.receivednum':'Empfangene Ziffern',
  'ccivr.nms.playcollectservice.tip1':'Wählen Sie einen Parameter aus, um die Eingabe des Benutzers zu speichern.',
  'ccivr.nms.playcollectservice.tip2':'Wählen Sie den Zeichenfolgentypparameter aus, den Sie in den Prozessinformationen hinzugefügt haben.',
  'ccivr.nms.playcollectservice.collectwaittime':'Wartezeit für Ziffernerfassung',
  'ccivr.nms.playcollectservice.entercollectwaittime':'Geben Sie die Wartezeit für die Ziffernerfassung ein.',
  'ccivr.nms.playcollectservice.collectTimeout':'Timeout-Intervall für Ziffernerfassung',
  'ccivr.nms.playcollectservice.maxfailtimes':'Max. Ausfallzeiten',
  'ccivr.nms.playcollectservice.entermaxfailtimes':'Geben Sie die maximale Anzahl von Fehlern ein.',
  'ccivr.nms.playcollectservice.failtitle':'Stimmwiedergabe bei Ziffernsammlungsfehler.',
  'ccivr.nms.playcollectservice.collectnumerror':'Fehler bei der Ziffernerfassung.',
  'ccivr.nms.playcollectservice.collectnumtimeout':'Zeitüberschreitung bei der Ziffernerfassung.',
  'ccivr.nms.playcollectservice.tip3':'Während der Ziffernsammlung gibt der Benutzer die Rautetaste (#) ein, um die Ziffernsammlung zu beenden. Bitte sprechen Sie mit der Stimme. (Sie müssen nicht die Rautetaste (#) drücken, um Ziffern im Menükonfigurationsdiagramm zu sammeln)',
  'ccivr.nms.playcollectservice.tip4':'Wenn die Zeit, zu der der Teilnehmer die Eingabe stoppt, die vorkonfigurierte Wartezeit für die Ziffernsammlung überschreitet, zeichnet das System den Fehler auf. Darüber hinaus führt das System automatisch eine Zeitüberschreitung bei der Ziffernerfassung durch und wartet auf die Ziffernerfassung.',
  'ccivr.nms.playcollectservice.tip5':'Wenn während der Ziffernsammlung ein Fehler auftritt, zeichnet das System einen Fehler auf, führt automatisch einen Ziffernsammlungsfehler aus und wartet weiter auf die Ziffernsammlung.',
  'ccivr.nms.playcollectservice.tip6':'Wenn die Ziffernsammlung fehlschlägt, müssen Sie die Stimme nicht abspielen. Wenn keine Sprachdatei ausgewählt wird, spielt das System die Ansage nach dem Fehlschlag erneut ab.',
  'ccivr.nms.playcollectservice.tip7':'Wenn die kumulierte Anzahl der Ausfallzeiten das Maximum überschreitet, wird der allgemeine Ausfallexit ausgeführt.',
  'ccivr.nms.playcollectservice.choosefile':'Stimme',
  'ccivr.nms.playcollectservice.changtovoice':'TTS',
  'ccivr.nms.playcollectservice.changtovideoO':'Video',
  'ccivr.nms.playcollectservice.onenumber':'Einstellige Sammlung',
  'ccivr.nms.playcollectservice.onenumbers':'Mehrstellige Sammlung',
  'ccivr.nms.playcollectservice.mulen':'Max. Anzahl der empfangenen Ziffern',
  'ccivr.nms.playcollectservice.chooseparam':'Benutzereingaben speichern',
  'ccivr.nms.playcollectservice.advancedconfig':'Erweiterte Einstellungen',
  'ccivr.nms.playcollectservice.close':'Schließen',
  'ccivr.nms.playcollectservice.required':'Pflichtfeld',
  'ccivr.nms.menuname.specialStrValidateFailed':'Die Länge darf 255 Zeichen nicht überschreiten',
  'ccivr.nms.tansferservice.timedPrompt':'Sie haben es schon lange nicht mehr gespeichert. Bitte speichern Sie es rechtzeitig.',
  'ccivr.nms.tansferservice.processLayout':'Prozessorchestrierung',
  'ccivr.nms.tansferservice.callForwarding':'Anrufweiterleitungskonfiguration',
  'ccivr.nms.tansferservice.moduleDescription':'Modulbeschreibung',
  'ccivr.nms.tansferservice.processName':'Geben Sie einen Flow-Namen ein.',
  'ccivr.nms.tansferservice.setTransfer':'Konfiguration übertragen',
  'ccivr.nms.tansferservice.transSkill':'Der Anruf wird an die entsprechende Skill-Queue weitergeleitet. Benutzerdefinierte Parameter werden unterstützt.Example: {\'$\'}{\'{\'}FLOW.Parameter',
  'ccivr.nms.tansferservice.transNumType':'Die Weiterleitungsnummer kann eine Mobiltelefonnummer, eine Festnetznummer oder ein benutzerdefinierter Parameter sein.Example: {\'$\'}{\'{\'}FLOW.Parameter',
  'ccivr.nms.tansferservice.transNum':'Weiterleitungsnummer',
  'ccivr.nms.tansferservice.passingParameters':'Übertragungsparameter',
  'ccivr.nms.tansferservice.parameter':'Wert',
  'ccivr.nms.tansferservice.operat':'Operation',
  'ccivr.nms.tansferservice.add':'Neu',
  'ccivr.nms.tansferservice.delete':'Löschen',
  'ccivr.nms.tansferservice.samenamevalidate':'Doppelter Parametername.',
  'ccivr.nms.tansferservice.transferZNIvrCode':'Weiterleitung zu intelligentem IVR',
  'ccivr.nms.tansferservice.chooseZNIvr':'Intelligentes IVR auswählen',
  'ccivr.nms.tansferservice.transferZNIvr':'Kundenanrufe werden an das entsprechende intelligente IVR weitergeleitet',
  'ccivr.nms.tansferservice.record':'Aufzeichnen',
  'ccivr.nms.tansferservice.selectPath':'Wählen Sie einen Pfad aus',
  'ccivr.nms.tansferservice.transAppointWorkNo':'Ein eingehender Anruf wird an einen bestimmten Agenten weitergeleitet.',
  'ccivr.nms.tansferservice.transferWorkNo':'Weiterleitung an Agent',
  'ccivr.nms.tansferservice.transInputWorkNo':'Geben Sie die ID des Agenten ein, an den ein Anruf weitergeleitet wird.',
  'ccivr.nms.transInputWorkNo.sample':'Beispiel: 28956666 oder {\'$\'}{\'{\'}FLOW.currentWorkNo{\'}\'}',
  'ccivr.nms.tansferservice.transCallingnum':'Anrufernummer',
  'ccivr.nms.tansferservice.sampleForCallingnum':'Beispiel: 660001 oder {\'$\'}{\'{\'}FLOW.accessCode{\'}\'}',
  'ccivr.nms.tansferservice.mustbeaccesscode':'Die Anrufnummer muss ein vom System zugewiesener Zugangscode oder ein benutzerdefinierter Parameter sein.Example: {\'$\'}{\'{\'}FLOW.Parameter',
  'ccivr.nms.tansferservice.skillId':'Skill-ID',
  'ccivr.nms.tansferservice.queueindex':'Warteschlangenposition',
  'ccivr.nms.tansferservice.queuenum':'Anzahl der Warteschlangen',
  'ccivr.nms.tansferservice.onlineagent':'Anzahl der Online-Agenten',
  'ccivr.nms.tansferservice.estimateTime':'EstimateTime',
  'ccivr.nms.queryservice.estimateTime.prompte':'Die erwartete Wartezeit der Warteschlange wird auf der Grundlage des Warteschlangenmodells berechnet, und das Ergebnis weist eine gewisse Abweichung auf. Bitte verwenden Sie es richtig.',
  'ccivr.nms.tansferservice.empty':'Parameter ist leer.',
  'ccivr.nms.tansferservice.transferLastAgent':'Kunden mit Audio- und Videokenntnissen',
  'ccivr.nms.tansferservice.transferLastAgent.transCallingnum':'Rufnummer',
  'ccivr.nms.tansferservice.transferLastAgent.mustbeaccesscode':'Die Anrufnummer ist die eingehende Nummer des Kunden bei example, {\'$\'}{\'{\'}SYS.callingNumber{\'}\'}.',
  'ccivr.nms.tansferservice.transferLastAgent.sampleForCallingnum':'Beispiel: 660001 oder {\'$\'}{\'{\'}SYS.callingNumber{\'}\'}',
  'ccivr.nms.tansferservice.transferLastAgent.type':'Letzter Agentenmodus',
  'ccivr.nms.tansferservice.transferLastAgent.type.time':'Zeitspanne',
  'ccivr.nms.tansferservice.transferLastAgent.type.time.label':'min',
  'ccivr.nms.tansferservice.transferLastAgent.type.time.info':'Der Anruf wird an den Agenten weitergeleitet, der innerhalb eines bestimmten Zeitraums kontaktiert wird.',
  'ccivr.nms.tansferservice.transferLastAgent.type.time.sample':'Beispiel: 100, was bedeutet, dass der Anruf an den Agenten weitergeleitet wird, der innerhalb von 100 Minuten kontaktiert wird.',
  'ccivr.nms.tansferservice.transferLastAgent.type.startTime':'Startzeit',
  'ccivr.nms.tansferservice.transferLastAgent.type.startTime.info':'Der Anruf wird an den Agenten weitergeleitet, der ab der Startzeit kontaktiert wird.',
  'ccivr.nms.tansferservice.transferLastAgent.type.startTime.sample':'Wählen Sie die Startzeit aus.',
  'ccivr.nms.tansferservice.transferLastAgent.type.currentday':'Aktueller Tag',
  'ccivr.nms.tansferservice.transferLastAgent.type.currentday.info':'Der Anruf wird an den Agenten weitergeleitet, der am aktuellen Tag kontaktiert wird.',
  'ccivr.nms.tansferservice.transferLastAgent.type.none':'Standard',
  'ccivr.nms.tansferservice.transferLastAgent.type.none.info':'Der Anruf wird an den Agenten weitergeleitet, der kürzlich kontaktiert wurde.',
  'ccivr.nms.tansferservice.transferThirdParty.recordingSettings':'Aufnahmeeinstellung',
  'ccivr.nms.tansferservice.transferThirdParty.enableRecording':'Aufzeichnung',
  'ccivr.nms.tansferservice.transferThirdParty.recordingPrompt':'Aufzeichnungsaufforderung',
  'ccivr.nms.transferservice.choose.recordfilename.info':'Wenn die Schnittstelle aufgerufen wird, um Aufzeichnungsdateien herunterzuladen, wird die Variable verwendet, um den Wert vorübergehend zu speichern.',
  'ccivr.nms.conditionService.processLayout':'Prozessorchestrierung',
  'ccivr.nms.conditionService.conditionService':'Bedingtes Urteil',
  'ccivr.nms.conditionService.moduleDescription':'Modulbeschreibung',
  'ccivr.nms.conditionService.createCondition':'Bedingung hinzufügen',
  'ccivr.nms.conditionService.conditionalEdit':'Bedingungsbearbeitung',
  'ccivr.nms.conditionService.condition':'Bedingungsname',
  'ccivr.nms.conditionService.Parameter_1':'Parameter 1',
  'ccivr.nms.conditionService.Parameter_2':'Parameter 2',
  'ccivr.nms.conditionService.relationOperator':'Bediener',
  'ccivr.nms.conditionService.logicOperator':'Logisch',
  'ccivr.nms.conditionService.operat':'Operation',
  'ccivr.nms.conditionService.add':'Neu',
  'ccivr.nms.conditionService.delete':'Löschen',
  'ccivr.nms.conditionService.parameterEmpty':'Parameter 1 ist leer.',
  'ccivr.nms.conditionService.maxroute':'Die maximale Anzahl bedingter Zweige beträgt 20.',
  'ccivr.nms.timeselectservice.title':'Zeitauswahl',
  'ccivr.nms.timeselectservice.servicetimeperiod':'Service-Zeitspanne',
  'ccivr.nms.timeselectservice.timeperiod':'Zeitraum',
  'ccivr.nms.timeselectservice.hour':'Stunde',
  'ccivr.nms.timeselectservice.min':'Minute',
  'ccivr.nms.timeselectservice.timeSelectType':'Konfigurationsmodus',
  'ccivr.nms.timeselectservice.customConfig':'Anpassen',
  'ccivr.nms.timeselectservice.workDayType':'Vorlage',
  'ccivr.nms.timeselectservice.workDay':'Arbeitskalender',
  'ccivr.nms.timeselectservice.selectworktime':'Wochentag',
  'ccivr.nms.timeselectservice.selectdate':'Datum',
  'ccivr.nms.timeselectservice.tips1':'Das Datum, das auf den ausgewählten Wochentag trifft, und das Datum ist ein Arbeitstag.',
  'ccivr.nms.timeselectservice.tips2':'Die Endzeit des Dienstes muss nach der Startzeit liegen.',
  'ccivr.nms.timeselectservice.tips3':'Die Servicezeitbereiche müssen in aufsteigender Reihenfolge sein. Jeder Zeitbereich darf sich nicht überschneiden.',
  'ccivr.nms.timeselectservice.monday':'Montag',
  'ccivr.nms.timeselectservice.tuesday':'Dienstag',
  'ccivr.nms.timeselectservice.wednesday':'Mittwoch',
  'ccivr.nms.timeselectservice.thursday':'Donnerstag',
  'ccivr.nms.timeselectservice.friday':'Freitag',
  'ccivr.nms.timeselectservice.saturday':'Samstag',
  'ccivr.nms.timeselectservice.sunday':'Sonntag',
  'ccivr.ivr.ivrflow.operate.gateway.pulishrepeat':'Der Prozessname ist doppelt vorhanden oder es wurde eine Version freigegeben.',
  'ccivr.nms.SubFlowService.Rotorflow':'Weiterleitung an Subflow',
  'ccivr.nms.SubFlowService.processjump':'Springen nach',
  'ccivr.nms.SubFlowService.jumpnode':'Sprungknoten',
  'ccivr.nms.process.processinformation':'Flussinformationen',
  'ccivr.nms.process.basicinformation':'Grundlegende Informationen',
  'ccivr.nms.process.parameterconfiguration':'Parameterkonfiguration',
  'ccivr.nms.process.customparameter':'Flussparameter',
  'ccivr.nms.process.parametertype':'Parametertyp',
  'ccivr.nms.process.initialvalue':'Anfangswert',
  'ccivr.nms.process.description':'Beschreibung',
  'ccivr.nms.process.String':'Zeichenkette',
  'ccivr.nms.process.number':'Ganzzahl',
  'ccivr.nms.process.sysparam':'Systemparameter',
  'ccivr.nms.process.paramname':'Name',
  'ccivr.nms.process.success':'Der Flow wurde erfolgreich gespeichert.',
  'ccivr.nms.process.paramnameNoCN':'Der Paramname ist falsch.',
  'ccivr.nms.playcollectservice.Receivemaxnum':'Max. Anzahl der empfangenen Ziffern',
  'ccivr.nms.playcollectservice.second':'zweite',
  'ccivr.nms.playcollectservice.times':'Zeiten',
  'ccivr.nms.playcollectservice.Failedreceivenum':'Fehler bei der Ziffernerfassung',
  'ccivr.nms.playcollectservice.tip8':'Während der Ziffernsammlung gibt der Benutzer die Rautetaste (#) ein, um die Ziffernsammlung zu beenden. Bitte sprechen Sie mit der Stimme.',
  'ccivr.nms.menuservice.title':'Menükonfiguration',
  'ccivr.nms.menuservice.voicenotice':'Stimmaufforderung',
  'ccivr.nms.menuservice.menubuttonconfig':'Konfiguration der Menütasten',
  'ccivr.nms.menuservice.button':'Schaltflächen',
  'ccivr.nms.menuservice.moveup':'Auf',
  'ccivr.nms.menuservice.movedown':'Unten',
  'ccivr.nms.menuservice.contentvalidate':'Fügen Sie nach Abschluss der Bearbeitung die Schlüsselkonfiguration hinzu.',
  'ccivr.nms.menuservice.samenamevalidate':'Wiederholte Tasten:',
  'ccivr.nms.conditionService.simpleExp':'Einfacher Ausdruck',
  'ccivr.nms.conditionService.complexExp':'Komplexer Ausdruck',
  'ccivr.nms.conditionService.logicAND':'Und',
  'ccivr.nms.conditionService.logicOR':'Oder',
  'ccivr.nms.tansferservice.transferLabor':'Weiterleitung in die Skill-Warteschlange',
  'ccivr.nms.tansferservice.transferThirdParty':'Weitergabe an Dritte',
  'ccivr.nms.tansferservice.chooseSkill':'Skill-Warteschlange auswählen',
  'ccivr.nms.tansferservice.sample':'Beispiel: 28956666 oder {\'$\'}{\'{\'}FLOW.currentUserNum{\'}\'}',
  'ccivr.nms.menuservice.tip':'Timeout oder falscher Schlüssel',
  'ccivr.nms.leavemessageservice.leavemessage':'Nachricht hinterlassen',
  'ccivr.nms.leavemessageservice.recordfile':'Aufnahmedatei',
  'ccivr.nms.leavemessageservice.choose.recordfilename':'Dateinamen speichern',
  'ccivr.nms.leavemessageservice.localtionID':'Orts-ID',
  'ccivr.nms.leavemessageservice.choose.localtionID':'Speichern der localtionID',
  'ccivr.nms.leavemessageservice.max.recordtime':'Max. Aufnahmedauer',
  'ccivr.nms.leavemessageservice.playbi':'Play the Piepton',
  'ccivr.nms.leavemessageservice.recordparam':'Aufzeichnungsparameter',
  'ccivr.nms.leavemessageservice.agent':'Agent für die Verarbeitung angeben',
  'ccivr.nms.leavemessageservice.choose.agent':'Angegebene Agenten-ID',
  'ccivr.nms.leavemessageservice.choose.agent.sample':'Beispiel: 101 oder {\'$\'}{\'{\'}FLOW.workNo{\'}\'}',
  'ccivr.nms.leavemessageservice.choose':'Auswählen',
  'ccivr.nms.leavemessageservice.end':'Standardmäßig wird die Aufnahme mit der Rautetaste beendet (#).',
  'ccivr.nms.leavemessageservice.placeholder':'Nachricht hinterlassen',
  'ccivr.nms.leavemessageservice.saveleavemessage':'Nachrichteninformationen aufzeichnen.',
  'ccivr.nms.leavemessageservice.flownamerepeat':'Der Name der Aufzeichnungsdatei und die LocaltionID können nicht dieselbe Variable verwenden.',
  'ccivr.nms.callendservice.title':'Ende',
  'ccivr.nms.callendservice.checkbox':'Die Schnittstelle muss aufgerufen werden, wenn ein Anruf endet.',
  'ccivr.flowtest.flowname':'Name',
  'ccivr.flowtest.begin.tip':'Geben Sie die Anrufnummer ein, um den Test zu starten.',
  'ccivr.flowtest.callingnumber':'Anrufernummer',
  'ccivr.flowtest.enter':'Geben Sie die Anrufnummer ein.',
  'ccivr.flowtest.begintest':'Starten',
  'ccivr.flowtest.stoptest':'Stopp',
  'ccivr.flowtest.success':'Erfolg',
  'ccivr.flowtest.fail':'Versagen',
  'ccivr.flowtest.presskey':'Schaltflächen',
  'ccivr.flowtest.enter.phone':'Geben Sie die Telefonnummer ein.',
  'ccivr.flowtest.hangup':'Auflegen',
  'ccivr.flowtest.test':'Test',
  'ccivr.flowtest.testoperate':'Operationen:',
  'ccivr.flowtest.client':'Kunde',
  'ccivr.flowtest.platform':'Plattform',
  'ccivr.flowtest.pleasebegintest':'Der Vorgang ist beendet.',
  'ccivr.flowtest.hashangup':'Der Test endet und der Anruf wird freigegeben.',
  'ccivr.flowtest.operatesuccess':'Erfolg',
  'ccivr.flowtest.operatefail':'Versagen',
  'ccivr.flowtest.operatehangup':'Auflegen',
  'ccivr.flowtest.test.enter.callingnumber':'Geben Sie zuerst die Anrufnummer ein!',
  'ccivr.flowtest.flowtest':'Durchflusstest',
  'ccivr.whitelist.whitelistmanage':'Vertrauenslistenverwaltung',
  'ccivr.whitelist.whiteip':'IP der Vertrauensliste',
  'ccivr.whitelist.imordomainname':'IP- oder Domänenname',
  'ccivr.whitelist.whiteport':'Vertrauenslistenport',
  'ccivr.whitelist.port':'Hafen',
  'ccivr.whitelist.desc':'Beschreibung',
  'ccivr.whitelist.descinfo':'Beschreibung',
  'ccivr.whitelist.deletetip':'Bitte markieren Sie die Elemente, die Sie löschen möchten',
  'ccivr.whitelist.urlvalidatefailed':'Geben Sie die IP-Adresse oder den Domänennamen ein',
  'ccivr.nms.assignservice.Dataassignment':'Datenzuweisung',
  'ccivr.nms.assignservice.assignmentParameters':'Zuweisungsparameter vorbereiten',
  'ccivr.nms.assignservice.transferDataAssign':'TransferData abrufen',
  'ccivr.nms.assignservice.othersDataAssign':'OthersData zuweisen',
  'ccivr.nms.assignservice.setTransferData':'TransferData festlegen',
  'ccivr.nms.assignservice.ieDataAssign':'IEData zuweisen',
  'ccivr.nms.assignservice.ietype':'IE-Typ',
  'ccivr.nms.satisfactionservice.type':'Typ der Zufriedenheitsumfrage',
  'ccivr.nms.satisfactionservice.title':'Zufriedenheitskonfiguration',
  'ccivr.nms.singleproblem.title':'Umfrage mit einem einzigen Thema',
  'ccivr.nms.satisfactionservice.menubuttonconfig':'Konfiguration der Zufriedenheitsschaltfläche',
  'ccivr.nms.satisfactionservice.singleproblem.menubuttonconfig':'Konfiguration der Schaltfläche für die Umfrage mit einer Frage',
  'ccivr.nms.satisfactionservice.buttonNumber':'Die Anzahl der Schlüssel für die Zufriedenheitsumfrage beträgt nicht mehr als 9 und nicht weniger als 3.',
  'ccivr.nms.satisfactionservice.tip':'Bitte konfigurieren Sie die Zufriedenheitsstufe im Menü Zufriedenheitskonfiguration. Wenn der Flow freigegeben wurde, können Sie ihn aktualisieren, bearbeiten, speichern und erneut freigeben, um den ursprünglichen Flow zu ersetzen und die aktualisierte Zufriedenheitsstufe zu verwenden.',
  'ccivr.nms.satisfactionservice.descripLength':'Schlüsselbeschreibung für die Zufriedenheitsumfrage überschreitet nicht 200',
  'ccivr.nms.satisfactionservice.isNull':'Zufriedenheitsparameter ist leer',
  'ccivr.grayrule.errorinfo.containspecialcha':'Der Parameterregelname enthält Sonderzeichen',
  'ccivr.ivr.ivrflow.templateName':'Vorlagenname',
  'ccivr.ivr.voiceupload.referenceFlowByCCIVR':'IVR-Flussreferenz',
  'ccivr.ivr.voiceupload.referenceFlowByNMS':'Ressourcenvorlagenreferenz',
  'ccivr.ivr.voiceupload.voiceUsageByTenant':'Referenz des Tenant-Szenarios',
  'ccivr.ivr.voiceupload.voiceUsageBySkill':'Skill-Warteschlangenreferenz',
  'ccivr.nms.queryservice.querySkillMessage':'Informationsabfrage',
  'ccivr.nms.queryservice.assignmentParameters':'Einzustellender Parameter',
  'ccivr.nms.queryservice.getDataAssign':'Abfrageergebnis',
  'ccivr.nms.queryservice.Dataassignment':'Informationsanfrage',
  'ccivr.nms.queryservice.queryType':'Abfragetyp',
  'ccivr.nms.queryservice.queryParam':'Anforderungsparameter',
  'ccivr.nms.queryservice.skillMsg':'Warteschlangeninformationen',
  'ccivr.nms.queryservice.skillInfo':'Warteschlangeninformationen',
  'ccivr.nms.queryservice.type':'Typ auswählen',
  'ccivr.nms.queryservice.paramName':'Anforderungsparameter',
  'ccivr.nms.queryservice.paramValue':'Wert',
  'ccivr.nms.queryservice.info':'Die Warteschlangeninformationen werden mithilfe eines IVR-Flows abgefragt, wenn ein Anruf an eine Fertigkeitswarteschleife weitergeleitet wird.',
  'ccivr.nms.queryservice.queueInfo':'Die Warteschleifeninformationen werden abgefragt, bevor ein Anruf an eine Skill-Warteschleife weitergeleitet wird.',
  'ccivr.nms.queryservice.queueSpecialInfo':'Fragen Sie ab, ob die Nummer des anrufenden Teilnehmers in der Sonderliste aufgeführt ist.',
  'ccivr.nms.queryservice.specialList.flagInfo':'Ob die Teilnehmernummer in der speziellen Liste aufgeführt ist. Die Optionen sind 0 (nein) und 1 (ja).',
  'ccivr.nms.queryservice.audio':'Audio',
  'ccivr.nms.queryservice.video':'Video',
  'ccivr.nms.queryservice.specifiedSkill':'Warteschlangenname',
  'ccivr.nms.queryservice.specifiedSkill.paramValue':'Agenten-ID',
  'ccivr.nms.queryservice.specifiedSkill.paramValue.sample':'Beispiel: 101 oder {\'$\'}{\'{\'}FLOW.workNo{\'}\'}.',
  'ccivr.nms.queryservice.specifiedSkill.result':'Warteschlangenname',
  'ccivr.nms.queryservice.specialListQuery':'Besondere Listeninformationen',
  'ccivr.nms.queryservice.specialList.flag':'Flagge der Aufnahme in die Sonderliste',
  'ccivr.nms.playset.status':'Erweiterte TTS-Einstellungen',
  'ccivr.nms.playset.open':'Aktiviert',
  'ccivr.nms.playset.close':'Deaktiviert',
  'ccivr.nms.playset.sound':'Klangeffekt',
  'ccivr.nms.playset.malevoice':'Männliche Stimme',
  'ccivr.nms.playset.femalevoice':'Weibliche Stimme',
  'ccivr.nms.playset.speed':'Sprechgeschwindigkeit',
  'ccivr.nms.playset.lowspeed':'Niedrig',
  'ccivr.nms.playset.intermediatespeed':'Mittel',
  'ccivr.nms.playset.highspeed':'Hoch',
  'ccivr.nms.playset.extendparam':'erweiterter TTS-Parameter',
  'ccivr.nms.playcollectservice.playset.reset':'Zurücksetzen',
  'ccivr.nms.shortmessageservice.processInformation':'Textnachricht',
  'ccivr.nms.shortmessageservice.sendType':'Nachrichtensendemodus',
  'ccivr.nms.shortmessageservice.normalMessage':'Kurznachricht',
  'ccivr.nms.shortmessageservice.fiveGMessage':'5G-Nachricht',
  'ccivr.nms.shortmessageservice.satisfactionMessage':'Zufriedenheitsumfrage',
  'ccivr.nms.shortmessageservice.smsType':'Kanal',
  'ccivr.nms.shortmessageservice.hwCloudMessage':'Huawei Cloud',
  'ccivr.nms.shortmessageservice.mspMessage':'SMS-Gateway',
  'ccivr.nms.shortmessageservice.selectTemplate':'Vorlage auswählen',
  'ccivr.nms.shortmessageservice.messageTemplateContent':'Vorlage',
  'ccivr.nms.shortmessageservice.setParameter':'Variable festlegen',
  'ccivr.nms.shortmessageservice.countryCode':'Ländercode',
  'ccivr.nms.shortmessageservice.addressee':'Mobiltelefonnummer',
  'ccivr.nms.shortmessageservice.phoneNumber':'Mobiltelefonnummer',
  'ccivr.nms.shortmessageservice.addresseeDesc':'Optional, Einzelne Mobiltelefonnummer. Wenn das Feld leer ist, wird es an den Anrufer gesendet.',
  'ccivr.nms.shortmessageservice.period':'Gültiger Antwortzeitraum (Minuten)',
  'ccivr.nms.playcollectservice.changetomultivoice':'Variable Stimme',
  'ccivr.nms.playservice.chosemultivoice':'Legen Sie den abzuspielenden Inhalt aus den folgenden Optionen fest',
  'ccivr.nms.playservice.multivoice.language':'Sprache',
  'ccivr.nms.playservice.multivoice.language0':'Putonghua',
  'ccivr.nms.playservice.multivoice.language1':'Englisch',
  'ccivr.nms.playservice.multivoice.language2':'Chinesisch (Kantonesisch)',
  'ccivr.nms.playservice.multivoice.language3':'Tschechisch',
  'ccivr.nms.playservice.multivoice.language4':'Dänisch',
  'ccivr.nms.playservice.multivoice.language5':'Niederländisch',
  'ccivr.nms.playservice.multivoice.language6':'Finnisch',
  'ccivr.nms.playservice.multivoice.language7':'Französisch',
  'ccivr.nms.playservice.multivoice.language8':'Deutsch',
  'ccivr.nms.playservice.multivoice.language9':'Griechisch',
  'ccivr.nms.playservice.multivoice.language10':'Ungarisch',
  'ccivr.nms.playservice.multivoice.language11':'Isländisch',
  'ccivr.nms.playservice.multivoice.language12':'Italienisch',
  'ccivr.nms.playservice.multivoice.language13':'japanisch',
  'ccivr.nms.playservice.multivoice.language14':'Koreanisch',
  'ccivr.nms.playservice.multivoice.language15':'Norwegisch',
  'ccivr.nms.playservice.multivoice.language16':'Polnisch',
  'ccivr.nms.playservice.multivoice.language17':'Portugiesisch',
  'ccivr.nms.playservice.multivoice.language18':'Russisch',
  'ccivr.nms.playservice.multivoice.language19':'Slowakisch',
  'ccivr.nms.playservice.multivoice.language20':'Spanisch',
  'ccivr.nms.playservice.multivoice.language21':'Schwedisch',
  'ccivr.nms.playservice.multivoice.language22':'Türkisch',
  'ccivr.nms.playservice.multivoice.language23':'Usbekistan',
  'ccivr.nms.playservice.multivoice.language24':'Thailand',
  'ccivr.nms.playservice.multivoice.language81':'Arabisch',
  'ccivr.nms.playservice.multivoice.type':'Typ',
  'ccivr.nms.playservice.multivoice.type.telnum':'Telefonnummer',
  'ccivr.nms.playservice.multivoice.type.number':'Anzahl',
  'ccivr.nms.playservice.multivoice.type.time':'time(hh:mm:ss)',
  'ccivr.nms.playservice.multivoice.type.date':'Datum(JJJJMMTT)',
  'ccivr.nms.playservice.multivoice.type.price':'Preis',
  'ccivr.nms.playservice.multivoice.content':'Inhalt',
  'ccivr.ivr.languageid.repeat.fail':'Sprachen-ID ist nicht vorhanden.',
  'ccivr.ivr.ivrtextexception.fail':'Der IVR-Text konnte nicht hinzugefügt werden.',
  'ccivr.ivr.illegal.fail':'Ungültiger Parameter.',
  'ccivr.ivr.type.fail':'Der Uploadtyp ist falsch.',
  'ccivr.ivr.delete.fail':'Löschen fehlgeschlagen.',
  'ccivr.ivr.create.exception':'Hinzufügen fehlgeschlagen.',
  'ccivr.whitelist.validator.ip':'Ungültige IP-Adresse. (Geben Sie eine IPv4- oder IPv6-Adresse oder einen Domänennamen ein.)',
  'ccivr.interface.typeerror':'Derzeit werden nur die Typen GET und POST unterstützt.',
  'ccivr.nms.process.parameterencryptips':'Beachten Sie, dass unverschlüsselte Parameter Parameterwerte im Klartext speichern, was ein Sicherheitsrisiko darstellen kann, wenn es verwendet wird.',
  'ccivr.ivrflow.specify.accessCode':'Kanalzugangscode angeben',
  'ccivr.ivr.transferRecord.startTime':'Startzeit',
  'ccivr.ivr.transferRecord.endTime':'Endzeit',
  'ccivr.ivr.transferRecord.callNumber':'Rufnummer',
  'ccivr.ivr.transferRecord.flowAccessCode':'Zugangscode verarbeiten',
  'ccivr.ivr.transferRecord.transNumber':'Weiterleitungsnummer',
  'ccivr.ivr.transferRecord.transResult':'Übertragungsergebnis',
  'ccivr.ivr.transferRecord.recordedFile':'Aufnahmedatei',
  'ccivr.ivr.transferRecord.play':'Spielen',
  'ccivr.ivr.transferRecord.download':'Herunterladen',
  'ccivr.ivr.transferRecord.success':'Erfolg',
  'ccivr.ivr.transferRecord.fail':'Nicht bestanden',
  'ccivr.ivr.transferRecord.noMoreThan90Days':'Der Zeitraum darf 90 Tage nicht überschreiten.',
  'ccivr.transferrecord.download.record':'Aufzeichnung der Flussübertragung herunterladen:',
  'ccivr.transferrecord.play.record':'Aufzeichnungen der Prozessübertragung wiedergeben:',
  'ccivr.transferrecord.play.timeCompare':'Die Startzeit darf nicht nach der Endzeit liegen.',
  'ccivr.ivr.transferRecord.init':'Initialisierung',
  'ccivr.ivr.transferRecord.noright':'Der aktuelle Benutzer ist nicht berechtigt, Aufzeichnungen herunterzuladen.',
  'ccivr.ivr.transferRecord.query.failed':'Übertragungsaufzeichnungen konnten nicht abgefragt werden',
  'ccivr.ivr.transferRecord.connect.uap.failed':'Verbindung zum Dateiserver konnte nicht hergestellt werden',
  'ccivr.ivr.transferRecord.connect.file.notexist':'Aufzeichnungsdatei konnte nicht heruntergeladen werden. Bitte überprüfen Sie, ob die Datei existiert.',
  'ccivr.nms.tansferservice.idleAgentCount':'Anzahl der Agenten im Leerlauf',
  'ccivr.nms.process.saveerror.queuing':'Geben Sie mindestens die Warteschlangenposition, die Anzahl der Online-Agenten und die erwartete Wartezeit ein.',
  'ccivr.nms.process.saveerror.queues':'Geben Sie mindestens eine der folgenden Optionen ein: Warteschleifenposition, Anzahl der Online-Agenten, Anzahl der Agenten im Leerlauf und Erwartete Wartezeit.',
  'ccivr.ivr.voice.scene.voicenotification':'Sprachbenachrichtigung',
  'ccivr.ivr.connectsftp.uploadfile.exception':'Verbindung zum SFTP konnte nicht hergestellt werden. Die Datei kann nicht hochgeladen werden.',
  'ccivr.ivr.voicepop.inputname':'Geben Sie den IVR-Stimmennamen ein.',
  'ccivr.ivr.voicereview.reviewmethod':'Sprachressourcen-Überprüfungsmodus',
  'ccivr.ivr.voicereview.manualreview':'Manuelle Überprüfung',
  'ccivr.ivr.voicereview.autopass':'Autopass',
  'ccivr.ivr.voiceupload.file.content':'Inhalt',
  'ccivr.ivr.voiceupload.file.update.content':'Inhalt aktualisieren',
  'ccivr.ivr.voiceupload.file.update.time':'Aktualisierungszeit',
  'ccivr.ivr.voiceupload.file.update.status':'Status',
  'ccivr.ivr.voiceupload.file.update.comments':'Kommentare',
  'ccivr.ivr.voiceupload.file.update.operation':'Operation',
  'ccivr.ivr.voiceupload.file.update.delete':'Löschen',
  'ccivr.ivr.voiceupload.file.update.submit':'Senden',
  'ccivr.ivr.voiceupload.file.update':'Aktualisierungen',
  'ccivr.ivr.voiceupload.textcontent':'Text',
  'ccivr.ivr.bussinessinvoke.long':'Lange Ganzzahl',
  'ccivr.ivr.voiceupload.fileformatwrong':'Falscher Typ.',
  'ccivr.nms.playservice.TTS':'TTS-Sprachwiedergabe',
  'ccivr.ivr.voiceupload.inputlanguageerror':'Die Sprache existiert nicht!',
  'ccivr.ivr.voiceupload.choosestatus':'Wählen Sie den Bewertungsstatus aus.',
  'ccivr.ivr.bussinessinvoke.float':'Schwimmend',
  'ccivr.nms.playservice.interrupt':'Drücken Sie eine beliebige Taste, um die Wiedergabe zu unterbrechen.',
  'ccivr.flowtest.test.error.tip':'Flow hat keine Konfiguration oder Anwendung',
  'ccivr.nms.label.certType':'Zeugnistyp',
  'ccivr.nms.label.inputCertCode':'Geben Sie einen Zertifikatscode ein.',
  'ccivr.nms.label.certCode':'Zertifizierungscode',
  'ccivr.nms.label.certScene':'Anwendungsszenario',
  'ccivr.nms.playservice.chose.survey':'Wählen Sie eine hochgeladene und genehmigte Sprachdatei aus oder fügen Sie eine neue Sprachdatei hinzu.',
  'ccivr.nms.playservice.chosetts.survey':'Wählen Sie einen hochgeladenen und genehmigten TTS-Text aus oder fügen Sie einen neuen TTS-Text hinzu. TTS-Ressourcen müssen verfügbar sein.',
  'ccivr.ivr.error.releaseFailed.tips':'Der Fragebogen konnte nicht automatisch freigegeben werden. Konfigurieren und Freigeben eines Ausnahmebehandlungsprozesses und manuelle Freigabe des Fragebogens.',
  'ccivr.ivr.ivrflow.operate.add':'Hinzufügen',
  'ccivr.nms.satisfactionservice.buttonNumber.Number':'Die Anzahl der Tasten ist nicht größer als 9 und nicht kleiner als 1.',
  'ccivr.nms.lightweight.tips':'Führen Sie Operationen mit dem leichtgewichtigen Fragebogen durch.',
  'ccivr.conditionService.param.tips':'Wenn Parameter 2 als Zeichenfolgenkonstante festgelegt ist, müssen für die normale Verwendung Anführungszeichen hinzugefügt werden.',
  'ccivr.nms.conditionService.sample':'Geben Sie einen Bedingungsausdruck ein. Der Ausdruck kann den Parameternamen, den Wert und die komplexe Operation enthalten. Auf einen Parameter wird im Format FLOW.Parametername verwiesen. Beispiel: SYS.currentNumber==FLOW.RechargeNumber&& (FLOW.AccountBalance - 20)>=FLOW.BillAmount',
  'ccivr.nms.conditionService.tips':'Bedingte Ausdrücke können Variablen, Werte und komplexe Operationen enthalten. Der Vergleichsoperator zwischen Werten oder Zeichenfolgen unterstützt > <Sein <=!=. Der boolesche Operator unterstützt && {\'|\'}{\'|\'} Beispiel: SYS.currentNumber==FLOW.RechargeNumber && FLOW.Rechnungsbetrag',
  'ccivr.TimeSelectService.branch.wrongtime':'Falsche Zeit',
  'ccivr.playservice.branch.query':'Abfragefehler',
  'ccivr.businessInvokeService.err':'Schnittstellenaufruf fehlgeschlagen',
  'ccivr.playservice.branch.ring':'Ringfehler',
  'ccivr.playservice.branch.userLevel':'Einstellungsfehler',
  'ccivr.tansferservice.branch.transferErr':'Übertragungsfehler',
  'ccivr.nms.transferservice.choose.recordfilename.remark':'Im Callcenter-Pool-Modus verwendet',
  'ccivr.playservice.branch.change':'Änderungsfehler',
  'ccivr.nms.conditionService.conditionBranch':'Bedingungszweig',
  'ccivr.playservice.branch.playerr':'Wiedergabefehler',
  'ccivr.PlayCollectService.branch.err':'PlayCollect-Fehler',
  'ccivr.nms.playcollectservice.warntype':'Aufforderungstyp',
  'ccivr.ivr.bussinessinvoke.paramTip':'Parametername, z. B. GLOBAL.language oder FLOW.testid',
  'ccivr.leaveMessage.maxtime.range':'Die maximale Aufzeichnungsdauer reicht von 10 bis 180 Sekunden.',
  'ccivr.ivr.voiceupload.fileName.validate':'Der Dateiname darf keines der folgenden Sonderzeichen enthalten:',
  'ccivr.nms.play.method':'Methode',
  'ccivr.nms.play.source':'Ressource',
  'ccivr.ivr.ivrflow.operate.release.referencedflow':'Freigabe konnte nicht abgebrochen werden! Der Fluss wurde referenziert.',
  'ccivr.ivr.voice.scene.2d.avatar':'Virtueller Mensch',
  'ccivr.ivr.voice.picturechoose':'Wählen Sie eine Bilddatei aus',
  'ccivr.ivr.voiceupload.selectpicture':'Wählen Sie eine Bilddatei aus',
  'ccivr.ivr.voiceupload.tippictureformat':'Derzeit werden nur JPG-, PNG- und JPEG-Bilddateien unterstützt. Die Auflösung der Datei darf 720p und die Dateigröße 2 MB nicht überschreiten.',
  'ccivr.ivr.voiceupload.tippicturemodel':'Sie können Bilddateien in verschiedenen Sprachen gleichzeitig einrichten. Das System schaltet automatisch auf die entsprechende Bilddatei um, basierend auf der Sprache, die der Benutzer während der virtuellen menschlichen Synthese wählt.',
  'ccivr.ivr.voiceupload.tip2DVideoformat':'Derzeit werden nur MP4-Videodateien unterstützt. Die Dateiauflösung darf 720p und die Dateigröße 5 MB nicht überschreiten.',
  'ccivr.ivr.voiceupload.tip2Dvideomodel':'Sie können Videodateien in verschiedenen Sprachen gleichzeitig einstellen. Während der virtuellen menschlichen Komposition wechselt das System automatisch auf der Grundlage der vom Benutzer gewählten Sprache zur entsprechenden Videodatei.',
  'ccivr.ivr.voiceupload.vedioMp4formatwrong':'Falsches Dateiformat. Bitte importieren Sie eine MP4-Datei.',
  'ccivr.ivr.voiceupload.tipvideo2Dformat':'Derzeit werden nur MP4-Videodateien unterstützt. Die Dateigröße darf 5 MB nicht überschreiten.',
  'ccivr.ivr.voicereview.picture':'Bild',
  'ccivr.ivr.voicereview.2dVideo':'Virtuelles menschliches Video',
  'ccivr.ivr.voiceupload.tipPicture2Dformat':'Derzeit werden nur JPG-, PNG- und JPEG-Bilddateien unterstützt. Die Dateigröße darf 2 MB nicht überschreiten.',
  'ccivr.ivr.voiceupload.pictureformatwrong':'Falsches Dateiformat. Importieren Sie eine JPG-, PNG- oder JPEG-Datei.',
  'ccivr.ivr.voiceupload.file.updateTitle':'Audio- und Videoressourcen bearbeiten',
  'ccivr.ivr.voiceupload.file.createTitle':'Neue Audio- und Videoressource',
  'ccivr.ivr.voiceupload.tippictureresolution':'Die Bildauflösung darf 720p nicht überschreiten.',
  'ccivr.ivr.voiceupload.tipvideoresolution':'Die Videoauflösung darf 720p nicht überschreiten.',

};
