export default {
    'ccivr.nms.leavemessageservice.choose.agent': 'ID de agente especificado',
    'ccivr.ivr.ivrflow.templateName': 'Nombre de la plantilla',
    'ccivr.ivr.ivrusage.skillName': 'Nombre de la cola de habilidades',
    'ccivr.nms.ringService.videomodel': 'Modo de vídeo',
    'ccivr.ivr.tracelog.calledNumbererror': 'Error al verificar el número de llamada\!',
    'ccivr.ivr.voice.scene': 'Escenario de uso',
    'ccivr.nms.process.saveerror.noexistend': 'Error al guardar. Existe al menos un elemento de diagrama final.',
    'ccivr.ivr.error.paramlimit': 'La longitud total del parámetro no puede superar los 4000 bytes.',
    'ccivr.nms.tansferservice.chooseSkill': 'Seleccionar cola de habilidades',
    'ccivr.ivr.description.variableerror': 'el formato de la variable de plantilla es incorrecto.',
    'ccivr.nms.shortmessageservice.countryCode': 'Código de país',
    'ccivr.nms.playset.open': 'Habilitado',
    'ccivr.ivr.flowAccessCode.repeat': 'El código de acceso ya existe!',
    'ccivr.ivr.voiceupload.fileformatconverterror': 'Error al convertir el formato de archivo o la configuración de FFmpeg es incorrecta.',
    'ccivr.ivr.voiceupload.exceedLimit': 'El número de archivos IVR excede el límite.',
    'ccivr.ivrflow.grayrule.beginnum': 'Número de inicio',
    'ccivr.ivr.voiceedit.editfail': 'Error al editar.',
    'ccivr.nms.queryservice.specialListQuery': 'Información especial de la lista',
    'ccivr.nms.playcollectservice.videofile': 'Archivo de vídeo',
    'ccivr.ivr.ivrusage.ivrname': 'Escenario de referencia',
    'ccivr.nms.timeselectservice.monday': 'Lunes',
    'ccivr.ivr.ivrflow.name': 'Nombre',
    'ccivr.ivr.voiceupload.sizewrong': 'El tamaño del archivo supera los 2 MB. Vuelva a importar el archivo.',
    'ccivr.nms.playcollectservice.playset.reset': 'Restablecer',
    'ccivr.ivr.voiceupload.referenceFlow': 'Información de flujo de referencia',
    'ccivr.nms.conditionService.processLayout': 'Orquestación de Procesos',
    'ccivr.ivrflow.unlock.confirm': 'Confirmar el estado de desbloqueo en editar?',
    'ccivr.nms.playservice.multivoice.type.number': 'Número',
    'ccivr.nms.businessinvokeservice.value': 'Valor',
    'ccivr.nms.tansferservice.transferLabor': 'Transferir a la cola de habilidades',
    'ccivr.ivrflow.cancelrelease.referenced': 'El flujo ha sido referenciado por un código de acceso.',
    'ccivr.ivr.bussinessinvoke.interfacename': 'Nombre de la interfaz',
    'ccivr.nms.playservice.multivoice.language24': 'Tailandia',
    'ccivr.nms.playservice.multivoice.language81': 'Español',
    'ccivr.nms.playservice.multivoice.language23': 'Uzbekistán',
    'ccivr.flowtest.hashangup': 'La prueba finaliza y se libera la llamada.',
    'ccivr.nms.playservice.multivoice.language22': 'Turco',
    'ccivr.nms.timeselectservice.saturday': 'Sábado',
    'ccivr.nms.playservice.multivoice.language21': 'Sueco',
    'ccivr.nms.playset.malevoice': 'Voz masculina',
    'ccivr.nms.playservice.multivoice.language20': 'Español',
    'ccivr.ivr.voicereview.thailand': 'Tailandia',
    'ccivr.nms.timeselectservice.selectworktime': 'Día de la semana',
    'ccivr.ivr.voiceupload.deletefail': 'Error en la eliminación.',
    'ccivr.ivr.voiceedit.choosefile': 'Seleccionar archivo',
    'ccivr.nms.tansferservice.transferLastAgent.type.currentday.info': 'La llamada se transfiere al agente con el que se cintacta el dia en curso.',
    'ccivr.nms.tansferservice.transferLastAgent.type.none': 'Predeterminado',
    'ccivr.nms.satisfactionservice.isNull': 'El parámetro de satisfacción está vacío',
    'ccivr.nms.queryservice.specifiedSkill.paramValue.sample': 'Ejemplo\: 101 o {\'$\'}{\'{\'}FLOW.workNo{\'}\'}.',
    'ccivr.ivr.ivrflow.operate.release.fail': 'Fallo de liberación.',
    'ccivr.ivr.voiceupload.languagemanage': 'Gestión de idiomas',
    'ccivr.nms.playservice.multivoice.language19': 'Eslovaco',
    'ccivr.nms.playservice.multivoice.language18': 'Ruso',
    'ccivr.nms.playservice.multivoice.language17': 'Portugués',
    'ccivr.nms.playservice.multivoice.language16': 'Polaco',
    'ccivr.flowtest.client': 'Clientes',
    'ccivr.nms.playservice.multivoice.language15': 'Noruego',
    'ccivr.nms.playservice.multivoice.language14': 'Coreano',
    'ccivr.ivr.voicereview.portuguese': 'Portugués',
    'ccivr.nms.playservice.multivoice.language13': 'Japonés',
    'ccivr.nms.playservice.multivoice.language12': 'Italiano',
    'ccivr.nms.leavemessageservice.playbi': 'Reproducir el tono del pitido',
    'ccivr.nms.playservice.multivoice.language11': 'Islandés',
    'ccivr.nms.tansferservice.transferLastAgent.type.startTime.info': 'La llamada se transfiere al agente que es contactado desde la hora de inicio.',
    'ccivr.nms.playservice.multivoice.language10': 'Húngaro',
    'ccivr.ivr.voiceupload.vedioformatwrong': 'El formato de archivo es incorrecto. Importe un archivo 3GP.',
    'ccivr.ivr.voiceupload.ivrVoiceUsage': 'Información de referencia',
    'ccivr.ivr.voice.voicename': 'timbre',
    'ccivr.ivr.voiceedit.inputtext': 'Escriba el texto.',
    'ccivr.nms.process.saveerror.queuing': 'Introduzca al menos una de las posiciones de cola, el número de agentes en línea y el tiempo de espera esperado.',
    'ccivr.ivr.ivrflow.unreleased': 'Inédito',
    'ccivr.nms.SubFlowService.processjump': 'Saltar a',
    'ccivr.nms.businessinvokeservice.opertaion': 'Operación',
    'ccivr.nms.playcollectservice.voiceprompts': 'Indicador de voz',
    'ccivr.ivr.voiceupload.checkcallcenterparamfailed': 'Error en la comprobación de parámetros del centro de llamadas.',
    'ccivr.ivr.tracelog.errorInfo': 'Mensaje de error',
    'ccivr.ivr.voiceedit.sftpinfoisnull': 'La información SFTP del centro de llamadas es nula.',
    'ccivr.nms.queryservice.queueInfo': 'La información de cola se consulta antes de que una llamada se transfiera a una cola de habilidades.',
    'ccivr.ivr.voiceupload.audioformat': 'Formato de archivo de audio\: ALAW, 8bit, 8000Hz, 64 kbps, mono.',
    'ccivr.nms.interface.urltip': 'Utilice la URL que comienza con HTTP o HTTPS.',
    'ccivr.ivr.voiceupload.selectLangNull': 'No se ha seleccionado ningún idioma. Por favor, seleccione un idioma.',
    'ccivr.ivr.voiceupload.pleaserecording': 'Cargue el archivo después de grabarlo.',
    'ccivr.ivrvoice.play.error': 'Al cargar la excepción de recurso, compruebe la configuración relacionada.',
    'ccivr.ivr.ivrflow.operate.ensurecancelrelease.info': '¿Está seguro de que desea cancelar el lanzamiento?',
    'ccivr.ivr.voiceupload.recording': 'registro',
    'ccivr.nms.playcollectservice.advancedconfig': 'Configuración avanzada',
    'ccivr.ivrflow.operate.ensurereplace.info': '¿Está seguro de que desea reemplazar el flujo liberado?',
    'ccivr.ivr.voiceupload.playrecord': 'Reproducir',
    'ccivr.ivr.voiceupload.playtype': 'Modo de reproducción de voz',
    'ccivr.nms.playcollectservice.collectnumtimeout': 'Se agotó el tiempo de espera de la recopilación de dígitos.',
    'ccivr.ivr.voice.recordupload': 'Grabación en línea',
    'ccivr.ivr.voiceupload.deleteodfsivrfail': 'Error de eliminación. El ivr está ocupado',
    'ccivr.nms.assignservice.assignmentParameters': 'Preparar parámetros de asignación',
    'ccivr.nms.conditionService.conditionService': 'Sentencia condicional',
    'ccivr.ivr.voiceupload.deletereferenceivrfail': 'Error al eliminar la voz. La voz ha sido referenciada.',
    'ccivr.ivr.voiceedit.idisnull': 'El ID está vacío.',
    'ccivr.ivr.tracelog.maxNumberValidate': '¡El número de números de llamadas excede el límite superior\!',
    'ccivr.ivrflow.grayrule.rule': 'Regla gris',
    'ccivr.nms.playset.status': 'Configuración avanzada de TTS',
    'ccivr.nms.timeselectservice.tuesday': 'Martes',
    'ccivr.ivr.tracelog.result.transfailed': 'Error',
    'ccivr.ivr.tracelog.result': 'Resultados del flujo',
    'ccivr.ivr.bussinessinvoke.updateinterface': 'Editar interfaz',
    'ccivr.ivr.voiceedit.statusisnull': 'el parámetro de status es nulo.',
    'ccivr.ivr.tracelog.createTime': 'Introducir el tiempo de flujo',
    'ccivr.nms.leavemessageservice.max.recordtime': 'Duración máx. de grabación',
    'ccivr.ivr.voicereview.text': 'Texto',
    'ccivr.nms.queryservice.type': 'Seleccionar tipo',
    'ccivr.nms.playservice.multivoice.language': 'Idioma',
    'ccivr.nms.menuservice.moveup': 'Arriba',
    'ccivr.ivr.ivrflow.deleteconfim': '¿Está seguro de que desea eliminar el flujo de IVR?',
    'ccivr.nms.tansferservice.callForwarding': 'Configuración de desvío de llamadas',
    'ccivr.nms.assignservice.setTransferData': 'Establecer TransferData',
    'ccivr.ivr.voiceedit.pathisnull': 'Error al consultar la información de ruta SFTP del centro de llamadas.',
    'ccivr.flowtest.hangup': 'Colgar',
    'ccivr.nms.menuservice.tip': 'Tiempo de espera o clave incorrecta',
    'ccivr.ivr.ivrflow.batchdeleteconfim': '¿Está seguro de que desea eliminar todos los flujos de IVR seleccionados? Asegúrese de que el flujo IVR no se libera.',
    'ccivr.ivrflow.updateivrinfo.editing': 'El flujo se está editando y no se puede modificar.',
    'ccivr.nms.process.saveerror.alreadyreleased': 'El estado del flujo es anormal, reabra el lienzo.',
    'ccivr.nms.leavemessageservice.localtionID': 'ID de localización',
    'ccivr.ivr.voiceupload.tipvideoformat': 'Actualmente, solo se admiten archivos de vídeo 3GP. El tamaño del archivo no puede exceder los 5 MB.',
    'ccivr.ivr.voiceupload.audiosampleratefailed': 'La frecuencia de muestreo del archivo de voz no es de 8000 Hz.',
    'ccivr.ivr.voicereview.reviewsuggestion': 'Comentarios',
    'ccivr.ivr.ivrflow.operate.cancelrelease': 'Cancelar liberación',
    'ccivr.nms.process.paramname': 'Nombre',
    'ccivr.nms.playservice.playfile': 'Archivo de voz',
    'ccivr.nms.queryservice.audio': 'Audiencias',
    'ccivr.ivr.voicereview.edit': 'Editar',
    'ccivr.ivr.tracelog.callInfo': 'Información de llamadas',
    'ccivr.nms.playservice.multivoice.type': 'Tipo',
    'ccivr.ivr.success': 'Éxito',
    'ccivr.nms.tansferservice.chooseZNIvr': 'Seleccionar IVR inteligente',
    'ccivr.ivrflow.release.grayrule': 'Lanzamiento gris',
    'ccivr.nms.process.saveerror.dataerror': 'Error al verificar los datos del elemento del diagrama.',
    'ccivr.nms.timeselectservice.min': 'Minutos',
  'ccivr.nms.timeselectservice.timeSelectType':'Modo de configuración',
  'ccivr.nms.timeselectservice.customConfig':'Personalización',
  'ccivr.nms.timeselectservice.workDayType':'Plantilla',
  'ccivr.nms.timeselectservice.workDay':'Calendario de trabajo',
    'ccivr.nms.delete': 'Borrar',
    'ccivr.ivr.variable.errorspecial': 'El tipo no existe.',
    'ccivr.ivr.transferRecord.noMoreThan90Days': 'El rango no puede exceder los 90 días.',
    'ccivr.ivr.tracelog.deleteFailed': '¡No se pudo eliminar\!',
    'ccivr.ivr.voiceupload.tmpformaterror': 'Tipo de archivo temporal incorrecto.',
    'ccivr.nms.queryservice.getDataAssign': 'Resultado de la consulta',
    'ccivr.ivr.voiceupload.checkuploadparmfailed': 'Error en la comprobación de parm de carga de archivos.',
    'ccivr.ivrflow.grayrule.inputrulename': 'Introduzca el nombre de la regla.',
    'ccivr.ivr.tracelog.addFailed': 'Error al agregar\!',
    'ccivr.ivr.variable.addvariable': 'Añadir variable',
    'ccivr.nms.tansferservice.onlineagent': 'Número de agentes en línea',
    'ccivr.ivrflow.updateivrinfo.fail': 'Error al modificar los atributos de flujo de IVR.',
    'ccivr.ivr.voiceedit.addsuccess': 'Agregando correctamente.',
    'ccivr.ivr.variable.defaultlimit': 'El valor predeterminado no puede contener más de 120 caracteres.',
    'ccivr.whitelist.deletetip': 'Marque los elementos que desea eliminar',
    'ccivr.ivr.ivrflow.operate.clone.fail': 'Error al actualizar el flujo.',
    'ccivr.nms.conditionService.maxroute': 'El número máximo de ramas condicionales es 20.',
    'ccivr.nms.businessinvokeservice.servicename': 'Nombre de la interfaz',
    'ccivr.nms.process.saveerror.menukeynoconnection': 'Error al guardar. Compruebe si cada tecla en la configuración del menú tiene una línea.',
    'ccivr.ivr.transferRecord.init': 'Inicialización',
    'ccivr.ivr.voiceedit.deletesuccess': 'Se ha eliminado correctamente.',
    'ccivr.ivr.voicereview.operat': 'Operación',
    'ccivr.nms.playservice.chosetts': 'El contenido que se va a reproducir se selecciona del texto TTS que se ha cargado y revisado. Se requieren recursos de TTS.',
    'ccivr.nms.shortmessageservice.setParameter': 'Establecer variable',
    'ccivr.ivr.voice.scene.note': 'tono',
    'ccivr.ivr.bussinessinvoke.validate': 'Error de verificación',
    'ccivr.flowtest.begin.tip': 'Introduzca el número de llamada para iniciar la prueba.',
    'ccivr.ivr.voiceupload.fileovermax': 'El número de voces IVR a gestionar supera el máximo.',
    'ccivr.nms.process.description': 'Descripcion',
    'ccivr.ivr.voiceedit.filenamecheckfail': 'Error en la validación del nombre de archivo.',
    'ccivr.nms.leavemessageservice.choose.recordfilename': 'Guardar nombre de archivo',
    'ccivr.nms.queryservice.skillInfo': 'Información de cola',
    'ccivr.ivr.ivrflow.ivrname': 'Nombre',
    'ccivr.ivr.voicereview.search': 'Buscar',
    'ccivr.nms.playcollectservice.collectnumerror': 'Error de recopilación de dígitos.',
    'ccivr.nms.businessinvokeservice.title': 'Interfaz de invocación',
    'ccivr.nms.businessinvokeservice.inputflowValidate': 'Entrada incorrecta, por favor modifique',
    'ccivr.ivr.error.sftp': 'La información SFTP es incorrecta.',
    'ccivr.nms.playcollectservice.times': 'veces',
    'ccivr.nms.assignservice.othersDataAssign': 'Asignar OthersData',
    'ccivr.nms.SubFlowService.jumpnode': 'Nodo de salto',
    'ccivr.nms.playcollectservice.maxfailtimes': 'Max. Tiempos de falla',
    'ccivr.nms.tansferservice.selectPath': 'Seleccionar una ruta',
    'ccivr.flowtest.operatefail': 'Fallo',
    'ccivr.ivr.bussinessinvoke.string': 'Cadena de caracteres',
    'ccivr.nms.varibalesselect.sysvariables': 'Variable de sistema',
    'ccivr.ivrflow.grayrule.name': 'Nombre de regla',
    'ccivr.nms.process.saveerror.noselectType': 'Seleccione un tipo de consulta.',
    'ccivr.whitelist.urlvalidatefailed': 'Ingrese IP o nombre de dominio',
    'ccivr.ivrflow.grayrule.bindingflow': 'Flujo enlazado\:',
    'ccivr.ivr.ivrflow.operate.ensurerelease': 'Confirmar la liberación',
    'ccivr.ivrflow.createivrflow.exceptionfail': 'El flujo de control de excepciones ya existe. Error al agregar!',
    'ccivr.ivr.tracelog.result.default': 'Predeterminado',
    'ccivr.ivr.success.import': 'Agregando correctamente.',
    'ccivr.ivrflow.grayrule.inputendnum': 'El número final debe ser mayor o igual que el número inicial.',
    'ccivr.nms.process.saveerror.queues': 'Introduzca al menos una posición de cola, número de agentes en línea, número de agentes inactivos y tiempo de espera esperado.',
    'ccivr.ivr.voicereview.uploadtime': 'Tiempo de carga',
    'ccivr.nms.playservice.placeholder': 'Reproducción de voz',
    'ccivr.nms.playservice.contentisnull': 'El contenido del anuncio no puede estar vacío',
    'ccivr.nms.playcollectservice.collectTimeout.error': 'Intervalo de tiempo de espera de recopilación de dígitos no válido (1–120)',
    'ccivr.nms.playcollectservice.enterCollectTimeout': 'Introduzca el período de tiempo de espera para la recopilación de dígitos.',
    'ccivr.ivr.bussinessinvoke.int': 'Entero',
    'ccivr.ivr.voicereview.language': 'Idioma',
    'ccivr.nms.questionnaireService.info': 'Los elementos del diagrama del cuestionario se utilizan en las tareas automáticas de llamadas salientes. Es necesario asociar el proceso del cuestionario IVR.',
    'ccivr.nms.tansferservice.add': 'Nuevo',
    'ccivr.nms.tansferservice.parameter': 'Valor',
    'ccivr.ivrflow.grayrule.numrange': 'Número Segmento',
    'ccivr.nms.playcollectservice.Failedreceivenum': 'Error de recopilación de dígitos',
    'ccivr.ivr.voiceedit.filenameformaterror': 'El formato de nombre de archivo es incorrecto.',
    'ccivr.ivr.variable.typeisnull': 'El tipo está vacío.',
    'ccivr.nms.playcollectservice.changtovideoO': 'Video',
    'ccivr.ivr.voiceupload.referenceFlowByCCIVR': 'Referencia de flujo IVR',
    'ccivr.nms.playset.speed': 'Velocidad de habla',
    'ccivr.nms.timeselectservice.hour': 'Horario',
    'ccivr.nms.satisfactionservice.menubuttonconfig': 'Configuración del Botón de Satisfacción',
    'ccivr.nms.satisfactionservice.singleproblem.menubuttonconfig': 'Configuración del botón de encuesta de un solo problema',
    'ccivr.ivrflow.grayrule.info': 'Detalles',
  'ccivr.nms.tansferservice.record': 'Grabación',
  'ccivr.nms.conditionService.simpleExp': 'Expresión simple',
  'ccivr.ivrflow.cancelrelease.error': 'Se hace referencia al flujo mediante una cola de habilidades!',
  'ccivr.nms.tansferservice.transferLastAgent.sampleForCallingnum': 'Ejemplo\: 660001 o {\'$\'}{\'{\'}SYS.callingNumber{\'}\'}',
  'ccivr.ivrflow.error.deleteivrflow.fail': 'El flujo no existe.',
  'ccivr.nms.process.saveerror': 'Error al guardar. Compruebe el nodo de flujo y la línea de conexión.',
  'ccivr.nms.tansferservice.transferLastAgent.type.startTime': 'Hora de inicio',
  'ccivr.whitelist.whiteip': 'IP lista de confianza',
  'ccivr.nms.tansferservice.transferLastAgent.type.none.info': 'La llamada se transfiere al agente con el que se ha contactado recientemente.',
  'ccivr.nms.SubFlowService.Rotorflow': 'Transferencia a subflujo',
  'ccivr.nms.conditionService.relationOperator': 'Operador',
  'ccivr.nms.playcollectservice.changtovoice': 'TTS',
  'ccivr.nms.tansferservice.transSkill': 'La llamada se transfiere a la cola de habilidades correspondiente. Parámetros definidos por el usuario. Ejemplo\: {\'$\'}{\'{\'}FLOW.Parameter name{\'}\'}',
  'ccivr.interface.typeerror': 'Actualmente, solo se admiten los tipos GET y POST.',
  'ccivr.nms.process.parameterencryptips': 'Tenga en cuenta que los parámetros no cifrados almacenan valores de parámetros en texto sin formato, lo que puede plantear riesgos de seguridad si se utilizan.',
  'ccivr.nms.conditionService.complexExp': 'Expresión compleja',
  'ccivr.ivr.tracelog.errorpop': 'Error en la consulta\!',
  'ccivr.nms.timeselectservice.tips3': 'Los intervalos de tiempo de servicio deben estar en orden ascendente. Cada intervalo de tiempo no puede superponerse entre sí.',
  'ccivr.nms.timeselectservice.tips2': 'La hora de finalización del servicio debe ser posterior a la hora de inicio.',
  'ccivr.nms.tansferservice.transferZNIvr': 'Las llamadas de los clientes se transferirán al Smart IVR correspondiente',
  'ccivr.nms.timeselectservice.tips1': 'La fecha que cumple con el día y la fecha de la semana seleccionados es un día laborable.',
    'ccivr.ivr.voicereview.name': 'Nombre',
    'ccivr.nms.tansferservice.transferThirdParty.recordingPrompt': 'Mensaje de grabación',
    'ccivr.nms.businessinvokeservice.outputparam': 'Parámetro de salida',
    'ccivr.ivr.languageid.repeat.fail': 'El idioma no existe.',
    'ccivr.ivr.tracelog.flowName': 'FlowName',
    'ccivr.ivr.voiceupload.opreationdbfailed': 'Error al actualizar el mensaje de voz IVR de la base de datos.',
    'ccivr.ivr.voiceupload.pleaseuploadfile': 'El archivo está vacío. Importe el archivo.',
    'ccivr.ivr.voicereview.voice': 'Voz',
    'ccivr.flowtest.callingnumber': 'Número de llamada',
    'ccivr.ivr.ivrflow.alreadyreleased': 'Lanzamiento oficial',
    'ccivr.nms.queryservice.queueSpecialInfo': 'Consultar si el número de suscriptor que llama está en la lista especial.',
    'ccivr.ivr.voicereview.nosubmit': 'No enviado',
    'ccivr.nms.menuservice.menubuttonconfig': 'Configuración de clave de menú',
    'ccivr.ivr.ivrflow.operate.release.success': 'Lanzamiento exitoso.',
    'ccivr.whitelist.port': 'puerto',
    'ccivr.nms.tansferservice.transferLastAgent.type.time.sample': 'Ejemplo\: 100, lo que indica que la llamada se transfiere al agente con el que se contacta en un plazo 100 minutos.',
    'ccivr.ivr.voice.pitch': 'tono',
    'ccivr.nms.shortmessageservice.hwCloudMessage': 'Nube de Huawei',
    'ccivr.ivr.connectsftp.uploadfile.exception': 'Error al conectarse al SFTP. No se puede cargar el archivo.',
    'ccivr.nms.timeselectservice.servicetimeperiod': 'Rango de tiempo de servicio',
    'ccivr.nms.assignservice.transferDataAssign': 'Comprar TransferData',
    'ccivr.nms.userLevelService.placeholder': 'Establecer nivel de usuario',
    'ccivr.nms.tansferservice.setTransfer': 'Configuración de transferencia',
    'ccivr.ivr.ivrflow.no': 'No',
    'ccivr.flowtest.testoperate': 'Operaciones\:',
    'ccivr.ivr.transferRecord.flowAccessCode': 'Código de acceso de proceso',
    'ccivr.ivr.voicereview.choosevoicetype': 'Tipo',
    'ccivr.nms.process.saveerror.flownoexist': 'El flujo no existe.',
    'ccivr.ivr.voicereview.voiceId': 'ID de voz',
    'ccivr.whitelist.descinfo': 'Descripcion',
    'ccivr.ivr.voiceupload.auditionTextlimit': 'El contenido de texto de vista previa puede contener un máximo de 300 bytes.',
    'ccivr.nms.brach.duplicatebranch': 'La rama ya existe. Seleccione otro archivo.',
    'ccivr.ivr.voiceupload.authvalidatefailed': 'Error en la comprobación de permisos de carga de archivos.',
    'ccivr.nms.tansferservice.empty': 'El parámetro está vacío.',
    'ccivr.nms.tansferservice.processName': 'Introduzca un nombre de flujo.',
    'ccivr.ivr.voicereview.reviewstatus': 'Estado',
    'ccivr.ivr.transferRecord.noright': 'El usuario actual no tiene permiso para descargar grabaciones.',
    'ccivr.nms.queryservice.Dataassignment': 'Consulta de información',
    'ccivr.nms.tansferservice.transferThirdParty': 'Transferencia a terceros',
    'ccivr.nms.tansferservice.sample': 'Ejemplo\: 28956666 o {\'$\'}{\'{\'}FLOW.currentUserNum{\'}\'}',
    'ccivr.nms.modulename': 'Nombre del elemento',
    'ccivr.nms.conditionService.logicOR': 'O',
    'ccivr.nms.tansferservice.transferLastAgent.transCallingnum': 'Número de llamada',
    'ccivr.nms.menuservice.samenamevalidate': 'Teclas repetidas\:',
    'ccivr.ivr.ivrflow.updateTime': 'Tiempo de actualización',
    'ccivr.ivr.voiceupload.tipvideomodel': 'Puede configurar archivos de voz o vídeo de diferentes idiomas al mismo tiempo. Durante la reproducción de vídeo, el sistema reproduce automáticamente el archivo de vídeo correspondiente en función del idioma seleccionado por el usuario.',
    'ccivr.ivr.voicereview.pass': 'Aprobar',
    'ccivr.ivr.voiceedit.import': 'importar',
    'ccivr.ivr.ivrflow.exceptionHandingFlow': 'Flujo de manejo de excepciones',
    'ccivr.nms.shortmessageservice.satisfactionMessage': 'Encuesta de satisfacción',
    'ccivr.ivr.voiceadvice.close': 'Cerrar',
    'ccivr.nms.process.parameterisEncrypt': 'Encriptación',
    'ccivr.ivr.ivrflow.currentHandler': 'Controlador',
    'ccivr.nms.satisfactionservice.buttonNumber': 'El número de claves de la encuesta de satisfacción no es superior a 9 y no inferior a 3.',
    'ccivr.ivr.bussinessinvoke.URL': 'URL de solicitud',
    'ccivr.ivr.voiceupload.selectvoice': 'Seleccione un archivo de voz.',
    'ccivr.nms.tansferservice.transferLastAgent.type.startTime.sample': 'Seleccione la hora de inicio.',
    'ccivr.ivr.voiceupload.VoiceNotificationTextrule': 'Para obtener más reglas de plantilla de notificación por voz, mueva el cursor sobre el símbolo i en el cuadro de texto.',
    'ccivr.nms.tansferservice.processLayout': 'Orquestación de Procesos',
    'ccivr.ivr.voice.recordtimeout.fail': 'Se agotó el tiempo de grabación.',
    'ccivr.nms.shortmessageservice.messageTemplateContent': 'Plantilla',
    'ccivr.ivr.bussinessinvoke.errormessage': 'Seleccione la interfaz que desea eliminar.',
    'ccivr.nms.tansferservice.transferLastAgent.type.time': 'Rango de tiempo',
    'ccivr.ivr.ivrflow.mainflow': 'Flujo principal',
    'ccivr.nms.process.samenamevalidate': 'Nombre del parámetro duplicado\:',
    'ccivr.nms.interface.nmsexception': 'comunicación con ODFS cumplir con la excepción\! Por favor, compruebe la configuración.',
    'ccivr.nms.interface.whitelistcheckfailed': 'La URL (dirección IP y número de puerto) no está en la lista de confianza. La interfaz solo se puede invocar después de ponerse en contacto con el administrador del sistema para agregar la URL a la lista de confianza?',
    'ccivr.nms.interface.whitelistwarn': 'La URL (dirección IP y número de puerto) no está en la lista de confianza. La interfaz solo se puede invocar después de ponerse en contacto con el administrador del sistema para agregar la URL a la lista de confianza.',
    'ccivr.ivr.tracelog.result.timeout': 'Tiempo de espera',
    'ccivr.ivrflow.grayrule.rulename': 'Nombre de regla',
    'ccivr.ivr.voice.scene.error': 'error',
    'ccivr.nms.shortmessageservice.fiveGMessage': 'Mensaje 5G',
    'ccivr.nms.conditionService.Parameter_2': 'Parámetro 2',
    'ccivr.ivr.voicereview.vedio': 'Video',
    'ccivr.ivr.voiceedit.filenameisnull': 'El nombre de archivo es nulo.',
    'ccivr.nms.conditionService.Parameter_1': 'Parámetro 1',
    'ccivr.ivr.error.releaseFailed': 'Error al publicar el script automáticamente',
    'ccivr.nms.userLevelService.example': 'Ejemplo\: 1-14 o FLOW.variableName',
    'ccivr.ivr.ivrflow.subflow': 'Subflujo',
    'ccivr.nms.shortmessageservice.addressee': 'Número de teléfono móvil',
  'ccivr.nms.shortmessageservice.phoneNumber': 'Número de teléfono móvil',
    'ccivr.nms.playservice.inputtitle': 'Mensaje de voz',
    'ccivr.ivrflow.createivrflow.sameivrflowname': 'El nombre del flujo ya existe!',
    'ccivr.ivr.voice.scene.questionnaires': 'Cuestionario',
    'ccivr.ivr.voice.scene.beforeReportWorkNo': 'antes de reportar ID de empleado',
    'ccivr.ivr.voice.scene.afterReportWorkNo': 'después de reportar ID de empleado',
    'ccivr.ivr.tracelog.sameNumberValidate': '¡Ya existe el mismo número de llamada\!',
    'ccivr.nms.playcollectservice.close': 'Cerrar',
    'ccivr.nms.process.saveerror.noexistconnection': 'Error al guardar. Una línea predeterminada debe estar disponible para los elementos del diagrama, excepto los elementos del diagrama final y la transferencia a subflujo.',
    'ccivr.nms.queryservice.queryType': 'Tipo de consulta',
    'ccivr.nms.playset.femalevoice': 'Voz femenina',
    'ccivr.flowtest.test': 'Ensayo',
    'ccivr.nms.playservice.multivoice.type.telnum': 'Número de teléfono',
    'ccivr.nms.tansferservice.transNum': 'Número de reenvío',
    'ccivr.ivr.voicereview.filename': 'Nombre de archivo',
    'ccivr.ivr.voicereview.scene': 'Escenario de uso',
    'ccivr.nms.tansferservice.transAppointWorkNo': 'Una llamada entrante se transfiere a un agente especificado.',
    'ccivr.ivr.delete.fail': 'Error en la eliminación.',
    'ccivr.ivr.variable.descvalueerror': 'La descripción contiene caracteres especiales. Por favor, modificarlo.',
    'ccivr.nms.satisfactionservice.type': 'Tipo de encuesta de satisfacción',
    'ccivr.nms.satisfactionservice.title': 'Configuración de Satisfacción',
    'ccivr.nms.singleproblem.title': 'Encuesta de un solo problema',
    'ccivr.ivr.tracelog.title': 'registro de trazas',
    'ccivr.nms.timeselectservice.selectdate': 'Fecha',
    'ccivr.nms.menuservice.contentvalidate': 'Una vez completada la edición, agregue la configuración de clave.',
    'ccivr.nms.process.initialvalue': 'Valor inicial',
    'ccivr.whitelist.imordomainname': 'IP o nombre de dominio',
    'ccivr.ivr.tracelog.addAccessCode': 'Añadir código de acceso',
    'ccivr.nms.playcollectservice.ttsvoice': 'TTS',
    'ccivr.nms.playcollectservice.entercollectwaittime': 'Introduzca el tiempo de espera para la recopilación de dígitos.',
    'ccivr.nms.playcollectservice.collectTimeout': 'Intervalo de tiempo de espera para la recopilación de dígitos.',
    'ccivr.nms.ChangeMediaService.label': 'medios',
    'ccivr.nms.leavemessageservice.agent': 'Especificar el agente para el procesamiento',
    'ccivr.ivr.variable.willdeleteselectvariable': '¿Está seguro de que desea eliminar las variables seleccionadas?',
    'ccivr.ivr.description.variablelimit': 'Las variables de plantilla no pueden exceder de 10.',
    'ccivr.ivr.tracelog.transitionOn': 'Sucursal de exportación',
    'ccivr.ivr.name.notnull': 'El nombre no se puede dejar vacío.',
    'ccivr.nms.callendservice.checkbox': 'La interfaz necesita ser invocada cuando finaliza una llamada.',
    'ccivr.ivr.voiceupload.formatwrong': 'Formato de archivo incorrecto. Importa un archivo en formato WAV o MP3.',
    'ccivr.ivr.voiceupload.fileheaderwrong': 'El archivo de voz original no est� en formato wav o mp3. Cambie el archivo de voz a otro archivo de voz',
    'ccivr.ivr.voiceupload.vedioheaderwrong': 'El archivo de v�deo original no est� en formato 3GP. Reemplace el archivo de v�deo con otro archivo de v�deo',
    'ccivr.nms.process.basicinformation': 'Información básica',
    'ccivr.nms.playcollectservice.choosefile': 'Voz',
    'ccivr.ivr.voice.filechoose': 'Seleccionar un archivo de voz',
    'ccivr.ivr.voiceedit.editsuccess': 'Se ha editado correctamente.',
    'ccivr.ivrflow.specify.accessCode': 'Especificar el código de acceso al canal',
    'ccivr.ivr.voicereview.refresh': 'Refrescar',
    'ccivr.ivr.voiceedit.addfail': 'Error al agregar.',
    'ccivr.nms.tansferservice.estimateTime': 'EstimaciónTiempo',
    'ccivr.ivr.voicereview.spanish': 'Español',
    'ccivr.ivr.tracelogprompt': 'Esta operación afectará al rendimiento del sistema. Esta configuración se eliminará automáticamente después de {0} hora. ¿Quieres continuar?',
    'ccivr.nms.tansferservice.transNumType': 'El número de reenvío puede ser un número de móvil, un número de teléfono de línea fija o un parámetro personalizado. Ejemplo\: {\'$\'}{\'{\'}FLOW.Parameter name{\'}\'}',
    'ccivr.nms.queryservice.paramName': 'Parámetro de solicitud',
    'ccivr.ivr.tracelog.callingNumberplaceHolder': 'Por favor ingrese el número de llamada',
    'ccivr.ivr.tracelog.nodeName': 'Nombre del elemento',
    'ccivr.whitelist.desc': 'Descripción',
    'ccivr.ivr.voice.fileupload': 'Cargar archivo',
    'ccivr.ivr.tracelog.addcallingnumber': 'Agregar número de llamada',
    'ccivr.nms.playcollectservice.mulen': 'Número máximo de dígitos recibidos',
    'ccivr.ivr.voicereview.english': 'Inglés',
    'ccivr.nms.playset.lowspeed': 'Bajo',
    'ccivr.ivr.bussinessinvoke.cancel': 'Cancelar',
    'ccivr.nms.process.parameterconfiguration': 'Configuración de parámetros',
    'ccivr.nms.playcollectservice.onenumbers': 'Colección de varios dígitos',
    'ccivr.ivr.tracelog.selecttip': 'Por favor, seleccione el número de teléfono\!',
    'ccivr.ivr.voicereview.language1': 'Inglés',
    'ccivr.nms.playservice.multivoice.type.time': 'time(hh\:mm\:ss)',
    'ccivr.ivr.voicereview.language0': 'Chino',
    'ccivr.flowtest.stoptest': 'Deténgase',
    'ccivr.ivr.ivrflow.operate.cancelrelease.fail': 'Error al cancelar la versión.',
    'ccivr.ivr.bussinessinvoke.tip': 'Introduzca un valor o una variable',
    'ccivr.ivr.bussinessinvoke.createinterface': 'Crear interfaz',
    'ccivr.ivr.voiceadvice.audition': 'Audición',
    'ccivr.nms.playset.sound': 'Efecto de sonido',
    'ccivr.ivrflow.unlock': 'Desbloquear',
    'ccivr.nms.tansferservice.mustbeaccesscode': 'El número de llamada debe ser un código de acceso asignado por el sistema o un parámetro personalizado. Ejemplo\: {\'$\'}{\'{\'}FLOW.Parameter name{\'}\'}',
    'ccivr.nms.tansferservice.transferLastAgent.type.currentday': 'Día actual',
    'ccivr.flowtest.flowtest': 'Prueba de flujo',
    'ccivr.ivr.voiceupload.stoprecording': 'parar',
    'ccivr.ivrflow.release.formal': 'Lanzamiento oficial',
    'ccivr.nms.assignservice.ietype': 'Tipo de IE',
    'ccivr.ivr.voiceedit.updatefail': 'Error al actualizar.',
    'ccivr.ivr.tracelog.deleteSuccess': '¡Eliminó correctamente\!',
    'ccivr.nms.leavemessageservice.placeholder': 'Dejar mensaje',
    'ccivr.flowtest.success': 'Éxito',
    'ccivr.nms.shortmessageservice.period': 'Período de respuesta válido (Minutos)',
    'ccivr.ivr.ivrflow.operate.ensurerelease.info': '¿Está seguro de que desea liberar el flujo?',
    'ccivr.ivr.ivrflow.type': 'Tipo',
    'ccivr.ivr.voiceedit.tip': 'Indicar',
    'ccivr.ivr.voiceupload.referenceFlowByNMS': 'Referencia de la plantilla de recursos',
    'ccivr.ivr.bussinessinvoke.interfacedesc': 'Descripción de la interfaz',
    'ccivr.ivr.ivrflow.operate.release.cancelexecptionhanding': 'Por favor, cancele el estado de liberación de otros procesos bajo el inquilino.',
    'ccivr.nms.LanguageService.placeholder': 'Seleccionar idioma',
    'ccivr.ivr.description.voiceNotificationlimit': 'La descripción del texto no puede contener más de 500 bytes.',
    'ccivr.nms.menuservice.voicenotice': 'Indicador de voz',
    'ccivr.ivr.voicereview.filePath': 'Ruta del archivo:',
    'ccivr.nms.transferservice.choose.recordfilename.info': 'Cuando se invoca la interfaz para descargar archivos de grabación, la variable se utiliza para almacenar temporalmente el valor.',
    'ccivr.ivr.bussinessinvoke.saveserror': 'Error al guardar.',
    'ccivr.nms.interface.warn': 'El protocolo de red utilizado por la interfaz no es seguro y puede conllevar riesgos de seguridad',
    'ccivr.ivrflow.cancelrelease.referenced.by.satisfaction.survey': 'La configuración de la encuesta de satisfacción ha hecho referencia al flujo y no se puede eliminar!',
    'ccivr.ivrflow.cancelrelease.referenced.by.idauth.survey': 'Se ha hecho referencia al proceso mediante una configuración de autenticación y no se puede anular la publicación!',
    'ccivr.ivr.bussinessinvoke.interfacetype': 'Tipo de interfaz',
    'ccivr.ivr.ivrflow.filtercondition': 'Criterios de filtro',
    'ccivr.ivr.voiceedit.querycallcenterfailed': 'Error al consultar la información SFTP del centro de llamadas.',
    'ccivr.ivrflow.error.deleteivrflow.association': 'El flujo está asociado con otro flujo y no se puede eliminar.',
    'ccivr.ivr.voiceupload.VoiceNotificationTextDes': '"El contenido de la plantilla no puede comenzar con una variable. Una variable puede contener un máximo de 32 bytes. Si una variable contiene más de 32 bytes, se recomienda dividirla en varias variables.',
    'ccivr.ivr.voiceedit.statuserror': 'el parámetro de status es error.',
    'ccivr.ivr.voicereview.notpass': 'Rechazado',
    'ccivr.nms.playset.highspeed': 'Alta',
    'ccivr.nms.playservice.multivoice.content': 'Contenido',
    'ccivr.ivr.voiceupload.referenceText': 'Información de texto de notificación de voz',
    'ccivr.ivr.bussinessinvoke.savesuccess': 'Se ha guardado correctamente.',
    'ccivr.nms.varibalesselect.globalvariables': 'Variable global',
    'ccivr.ivr.transferRecord.endTime': 'Hora de finalización',
    'ccivr.ivrflow.grayrule.inputendlength': 'La longitud del número inicial debe ser la misma que la del número final.',
    'ccivr.ivr.transferRecord.connect.file.notexist': 'Error al descargar el archivo de grabación. Compruebe si el archivo existe.',
    'ccivr.ivr.tracelog.calledNumber': 'Número Llamado',
    'ccivr.nms.timeselectservice.friday': 'Viernes',
    'ccivr.ivr.voicereview.passed': 'Aprobado',
    'ccivr.ivr.voiceupload.audiofilereadfailed': 'Error al leer los atributos del archivo de voz.',
    'ccivr.ivr.voiceupload.fileinjectionerror': 'Compruebe la excepción de riesgo de inyección de archivos.',
    'ccivr.nms.process.customparameter': 'Parámetros de flujo',
    'ccivr.nms.assignservice.ieDataAssign': 'Asignar datos de IE',
    'ccivr.ivr.voicereview.language24': 'Tailandia',
    'ccivr.ivr.voicereview.language20': 'Español',
    'ccivr.ivr.voiceupload.uploadfiletosftpfailed': 'Error al cargar el archivo en sftp.',
    'ccivr.ivrflow.operate.ensurereplace': 'Confirmar Reemplazo',
    'ccivr.nms.shortmessageservice.mspMessage': 'Puerta de enlace SMS',
    'ccivr.ivr.variable.string': 'Cuerda',
    'ccivr.ivr.voiceedit.filenamenull': 'El contenido del nombre de archivo es nulo.',
    'ccivr.nms.playcollectservice.required': 'Campo obligatorio',
    'ccivr.nms.menuname.specialStrValidateFailed': 'La longitud no puede exceder los 255 caracteres',
    'ccivr.nms.tansferservice.transferThirdParty.recordingSettings': 'Configuración de grabación',
    'ccivr.nms.playset.intermediatespeed': 'Mediano',
    'ccivr.ivr.tracelog.deleteconfirminfo': 'Si desea eliminar el número de llamada actual\:',
    'ccivr.ivr.fail': 'Fallo',
    'ccivr.ivr.voiceupload.delete': 'Borrar',
    'ccivr.ivr.ivrflow.filter': 'Filtrar',
    'ccivr.ivr.voicereview.french': 'Francés',
    'ccivr.ivr.voicereview.arabic': 'árabe',
    'ccivr.ivr.voicereview.german': 'Alemán',
    'ccivr.ivr.voicereview.language17': 'Portugués',
    'ccivr.ivr.voicereview.language7': 'Francés',
    'ccivr.ivr.voicereview.language81': 'árabe',
    'ccivr.ivr.voicereview.language8': 'Alemán',
    'ccivr.ivr.transferRecord.callNumber': 'Número de llamada',
    'ccivr.nms.tansferservice.transferLastAgent.type': 'Modo de último agente',
    'ccivr.nms.playcollectservice.failtitle': 'Reproducción de voz para fallo en la recopilación de dígitos.',
    'ccivr.ivr.bussinessinvoke.basicconfig': 'Configuración básica',
    'ccivr.nms.ringService.sendrecv': 'Enviarrecv',
    'ccivr.nms.timeselectservice.wednesday': 'Miércoles',
    'ccivr.ivr.tracelog.result.recordend': 'Grabación de éxito',
    'ccivr.ivrflow.variable.settings': 'Configuración de variables',
    'ccivr.ivr.tracelog.deleteconfirminfocode': 'Si se debe eliminar el código de acceso actual\:',
    'ccivr.nms.tansferservice.transferLastAgent': 'Audio y video',
    'ccivr.flowtest.flowname': 'Nombre',
    'ccivr.ivr.ivrflow.operate.gateway.pulishrepeat': 'El nombre del proceso es duplicado o se ha liberado una versión.',
    'ccivr.ivr.ivrflow.version': 'Número de versión',
    'ccivr.ivr.voiceupload.english': 'Inglés',
    'ccivr.nms.add': 'Nuevo',
    'ccivr.ivr.transferRecord.fail': 'Fallar',
    'ccivr.nms.playservice.chosemultivoice': 'Establecer el contenido que se va a reproducir a partir de las siguientes selecciones',
    'ccivr.ivr.tracelog.addSuccess': '¡Añadido con éxito\!',
    'ccivr.flowtest.presskey': 'Botones',
    'ccivr.ivr.tracelog.selecttipcode': '¡Seleccione el código de acceso\!',
    'ccivr.ivrflow.ivrflowname.specialStrValidateFailed128': 'El valor no puede contener caracteres especiales y la longitud no puede superar los 128 caracteres.',
    'ccivr.nms.queryservice.specifiedSkill': 'Nombre de cola',
    'ccivr.ivr.voice.exception.recordfailmessage': 'Error de grabación. Compruebe si tiene el permiso del micrófono.',
    'ccivr.ivrflow.updateivrinfo.success': 'Modificado los atributos del flujo IVR con éxito.',
    'ccivr.ivr.voiceupload.VoiceNotificationtipmodel': 'Puede configurar archivos de voz o texto de diferentes idiomas al mismo tiempo (la longitud máxima no puede superar los 500 bytes). Durante la reproducción de voz, el sistema reproduce automáticamente el archivo de voz correspondiente según el idioma seleccionado por el usuario.',
    'ccivr.nms.businessinvokeservice.second': 'En segundo lugar',
    'ccivr.ivr.tracelog.index': 'Índice',
    'ccivr.nms.tansferservice.operat': 'Operación',
    'ccivr.ivr.voice.recordtimeout.failmes': 'La duración máxima de grabación es de 2 minutos. Grabar de nuevo.',
    'ccivr.nms.tansferservice.transferZNIvrCode': 'Transferencia a IVR Inteligente',
    'ccivr.nms.process.saveerror.noresult': 'No es Entidad.',
    'ccivr.nms.queryservice.specialList.flag': 'Bandera de estar en la lista especial',
    'ccivr.nms.leavemessageservice.saveleavemessage': 'Registre la información del mensaje.',
    'ccivr.nms.ringService.placeholder': 'Anillos',
    'ccivr.nms.playservice.multivoice.language9': 'Griego',
    'ccivr.flowtest.platform': 'Plataforma',
    'ccivr.ivrflow.grayrule.grayinfo': 'Si un número coincide con una regla gris, se ejecuta el flujo de esta versión.<br /> Para los números que no coinciden con las reglas grises, se ejecuta el flujo liberado oficialmente.<br /> Si no hay ningún flujo liberado oficialmente, la ejecución falla.',
    'ccivr.nms.playservice.multivoice.language8': 'Alemán',
    'ccivr.nms.playservice.multivoice.language7': 'Francés',
    'ccivr.nms.playservice.multivoice.language6': 'Terminado',
    'ccivr.nms.playservice.multivoice.language5': 'Holandés',
    'ccivr.nms.playservice.multivoice.language4': 'Danés',
    'ccivr.nms.playservice.multivoice.language3': 'Checo',
    'ccivr.nms.userLevelService.label': 'Nivel de usuario',
    'ccivr.nms.playservice.multivoice.language2': 'Chino (cantonés)',
    'ccivr.nms.playservice.multivoice.language1': 'Inglés',
    'ccivr.nms.playservice.multivoice.language0': 'Putonghua',
    'ccivr.nms.playcollectservice.interrupt': 'Pulse cualquier tecla para interrumpir la reproducción.',
  'ccivr.ivr.description.limit': 'La descripción del texto no puede contener más de 1024 bytes.',
  'ccivr.ivr.voice.vediochoose': 'Seleccionar un archivo de vídeo',
  'ccivr.ivr.variable.name': 'Variable',
  'ccivr.nms.tansferservice.delete': 'Borrar',
  'ccivr.ivr.changtovoice': 'TTS',
  'ccivr.ivr.voiceupload.isconfdelete': '¿Está seguro de que desea eliminarlo?',
  'ccivr.ivr.ivrflow.clone': 'Actualizacion',
  'ccivr.nms.callendservice.title': 'Finalizar',
  'ccivr.ivr.variable.nameisnull': 'El nombre está vacío.',
  'ccivr.nms.playcollectservice.chooseparam': 'Guardar la entrada del usuario',
  'ccivr.nms.leavemessageservice.choose.agent.sample': 'Ejemplo\: 101 o {\'$\'}{\'{\'}FLOW.workNo{\'}\'}',
  'ccivr.nms.ringService.recvonly': 'Recibir sólo',
  'ccivr.ivr.voiceupload.voiceUsageBySkill': 'Referencia de la cola de habilidades',
  'ccivr.ivr.transferRecord.connect.uap.failed': 'Error al conectarse al servidor de archivos',
  'ccivr.ivr.voice.scene.voicenotification': 'Notificación por voz',
  'ccivr.nms.process.parametertype': 'Tipo de parámetro',
  'ccivr.ivrflow.createivrflow.fail': 'Error al agregar.',
  'ccivr.nms.process.isParameterisEncrypt': 'Cifrado o no',
  'ccivr.nms.playcollectservice.entermaxfailtimes': 'Introduzca el número máximo de fallos.',
  'ccivr.nms.businessinvokeservice.paramname': 'Nombre',
  'ccivr.ivr.tracelog.callingNumber': 'Número de llamadas',
    'ccivr.nms.playset.close': 'Desactivado',
    'ccivr.nms.tansferservice.transferLastAgent.type.time.info': 'La llamada se transfiere al agente que se conecta dentro de un intervalo de tiempo.',
    'ccivr.nms.queryservice.queryParam': 'Parámetro de solicitud',
    'ccivr.nms.varibalesselect.processvaribales': 'Variable de flujo',
    'ccivr.nms.conditionService.moduleDescription': 'Descripción del módulo',
    'ccivr.ivr.voicereview.reset': 'Restablecer',
    'ccivr.ivr.voicereview.choosetenantspace': 'Seleccione un tenant.',
    'ccivr.nms.queryservice.video': 'Video',
    'ccivr.nms.process.String': 'Cadena de caracteres',
    'ccivr.nms.tansferservice.skillId': 'ID de habilidad',
    'ccivr.ivr.textcontent.notnull': 'La información de texto no puede estar vacía.',
    'ccivr.nms.varibalesselect.selectvariables': 'Seleccionar variable',
    'ccivr.ivr.voicereview.textContent': 'Contenido de texto',
    'ccivr.nms.queryservice.querySkillMessage': 'Consulta de información',
    'ccivr.ivr.variable.idisnull': 'El ID está vacío.',
    'ccivr.ivrflow.createivrflow.mainfail': 'El flujo principal ya existe. Error al agregar.',
    'ccivr.ivrflow.variable.selectvariable': 'Seleccione un parámetro variable.',
    'ccivr.ivr.error.paramNumlimit': 'El número total del parámetro no puede exceder de 16.',
    'ccivr.ivr.ivrtextexception.fail': 'Error al agregar el texto IVR.',
    'ccivr.ivr.voiceupload.fileioerror': 'Excepción de E/S de archivo.',
    'ccivr.ivrflow.unlock.success': 'Estado desbloqueado correctamente',
    'ccivr.ivr.voiceedit.deleteodfsivrfail': 'Error al eliminar. ¡Los datos del IVR están ocupados\!',
    'ccivr.nms.playset.extendparam': 'Parámetro extendido TTS',
    'ccivr.nms.tansferservice.transferThirdParty.enableRecording': 'Grabación',
    'ccivr.flowtest.operatehangup': 'Cuelga',
    'ccivr.ivr.ivrflow.new': 'Nuevo',
    'ccivr.ivr.ivrflow.operate.ensurecancelrelease': 'Confirmar cancelación de liberación',
    'ccivr.transferrecord.play.timeCompare': 'La hora de inicio no puede ser posterior a la hora de finalización.',
    'ccivr.nms.answerService.placeholder': 'Respuestas',
    'ccivr.ivr.type.fail': 'El tipo de carga es incorrecto.',
    'ccivr.ivr.voiceupload.selectLangRe': 'Se ha seleccionado el idioma actual. Por favor, seleccione otro idioma.',
    'ccivr.nms.process.saveerror.unSucctransfer': 'Error al guardar. El elemento del diagrama de transferencia tiene la línea de éxito de transferencia.',
    'ccivr.nms.process.interfaceerr': 'Error de interfaz. Compruebe el registro.',
    'ccivr.ivr.voiceupload.query': 'Detalle',
    'ccivr.nms.process.processinformation': 'Información de flujo',
    'ccivr.ivrflow.updateivrinfo.released': 'El flujo ha sido liberado. Cancele la liberación y modifique los atributos.',
    'ccivr.nms.playcollectservice.second': 'En segundo lugar',
    'ccivr.nms.timeselectservice.timeperiod': 'Periodo de tiempo',
    'ccivr.ivrflow.variable.typeerror': 'Tipos de variables de entrada incompatibles.',
    'ccivr.ivr.tracelog.accessCode': 'Código de acceso de flujo',
    'ccivr.nms.tansferservice.idleAgentCount': 'Número de agentes inactivos',
    'ccivr.ivr.variable.defaulttypeerror': 'El tipo de valor predeterminado no coincide con el tipo de variable.',
    'ccivr.nms.conditionService.logicAND': 'Y',
    'ccivr.ivr.tracelog.result.transsuccess': 'Exitoso',
    'ccivr.ivr.ivrflow.property': 'Atributo',
    'ccivr.ivr.tracelog.actionParams': 'Parámetros clave de Cell',
    'ccivr.ivr.ivrflow.ivrtype': 'Tipo',
    'ccivr.nms.queryservice.info': 'La información en cola se consulta mediante un flujo IVR cuando una llamada se transfiere a una cola de habilidades.',
    'ccivr.ivr.error.uncommitted': 'El administrador del sistema no puede reproducir el archivo que no se ha enviado.',
    'ccivr.nms.timeselectservice.sunday': 'Domingo',
    'ccivr.nms.leavemessageservice.end': 'De forma predeterminada, la grabación finaliza con la tecla de almohadilla (\#).',
    'ccivr.ivr.ivrflow.operate.cancelrelease.success': 'Cancelado correctamente.',
    'ccivr.ivr.voiceedit.deleteoldfilefailed': 'Error al eliminar el archivo antiguo.',
    'ccivr.ivr.ivrflow.selectivrflow': 'Seleccione un flujo de IVR.',
    'ccivr.ivr.voice.volume': 'volumen',
    'ccivr.ivr.voice.clicktorecord': 'Haga clic en Grabar',
    'ccivr.nms.tansferservice.transferWorkNo': 'Traslado al Agente',
    'ccivr.ivr.transferRecord.transNumber': 'Número de reenvío',
    'ccivr.nms.shortmessageservice.processInformation': 'SMS',
    'ccivr.ivr.name.existence': 'El nombre ya existe. Por favor, ingrese otro.',
    'ccivr.nms.leavemessageservice.choose': 'Seleccionar',
    'ccivr.ivr.voiceadvice.cancel': 'Cancelar',
    'ccivr.nms.menuservice.title': 'Configuración del menú',
    'ccivr.ivr.ivrflow.operate.release.needexecptionhanding': 'Configure y libere oficialmente un proceso de manejo de excepciones.',
    'ccivr.ivr.tracelog.result.playover': 'Éxito en el juego',
    'ccivr.nms.tansferservice.moduleDescription': 'Descripción del módulo',
    'ccivr.nms.timeselectservice.thursday': 'Jueves',
    'ccivr.nms.conditionService.parameterEmpty': 'El parámetro 1 está vacío.',
    'ccivr.nms.queryservice.paramValue': 'Valor',
    'ccivr.ivr.voice.clicktostop': 'Haga clic en Detener',
    'ccivr.nms.playcollectservice.title': 'Reproducir voz y recoger dígito',
    'ccivr.nms.tansferservice.transCallingnum': 'Número de llamada',
    'ccivr.ivr.ivrflow.state': 'Estado',
    'ccivr.ivr.voiceupload.defaultNoDe': 'No se puede eliminar el idioma predeterminado.',
    'ccivr.ivr.create.exception': 'Error al agregar.',
    'ccivr.ivr.error.other': 'Error en la descarga.',
    'ccivr.ivr.tracelog.cellType': 'Tipo de elemento',
    'ccivr.ivr.voiceupload.tipformat': 'Los formatos MP3 y WAV son compatibles, y el tamaño del archivo no puede exceder los 12 MB.',
    'ccivr.ivr.voiceupload.tipformat1M': 'Los formatos MP3 y WAV son compatibles, y el tamaño del archivo no puede exceder los 1 MB.',
    'ccivr.ivr.voiceupload.amountLimit5': 'En este escenario se pueden agregar un máximo de cinco archivos de voz.',
    'ccivr.ivr.variable.int': 'Entero',
    'ccivr.ivr.voiceupload.new': 'Nuevo',
    'ccivr.ivrflow.grayrule.editsuccess': 'Modificado con éxito.',
    'ccivr.nms.businessinvokeservice.inpuparam': 'Parámetro de entrada',
    'ccivr.ivr.transferRecord.recordedFile': 'Archivo de grabación',
    'ccivr.ivr.bussinessinvoke.bussinessinvoke': 'Configuración de la interfaz',
    'ccivr.ivr.voiceedit.pathcheckfailed': 'Error en la validación de la información de ruta de acceso SFTP.',
    'ccivr.nms.brach.label': 'Seleccionar condición',
    'ccivr.ivr.ivrflow.placeholder.ivrname': 'Introduzca un nombre de flujo.',
    'ccivr.ivrflow.operate.replace.fail': 'Error al reemplazar.',
    'ccivr.flowtest.enter.phone': 'Ingrese el número de teléfono.',
    'ccivr.ivr.voicereview.filter': 'Filtrar',
    'ccivr.flowtest.test.enter.callingnumber': 'Por favor introduzca primero el número de llamada!',
    'ccivr.nms.tansferservice.sampleForCallingnum': 'Ejemplo\: 660001 o {\'$\'}{\'{\'}FLOW.accessCode{\'}\'}',
    'ccivr.ivr.voiceupload.tipmodel': 'Puede configurar archivos de voz o texto de diferentes idiomas al mismo tiempo (la longitud máxima no puede superar los 1024 bytes). Durante la reproducción de voz, el sistema reproduce automáticamente el archivo de voz correspondiente según el idioma seleccionado por el usuario.',
    'ccivr.ivrflow.ivrflowtype.validateFailed': 'Tipo de flujo incorrecto.',
    'ccivr.nms.satisfactionservice.tip': 'Configure el nivel de satisfacción en el menú Configuración de satisfacción. Si se ha liberado el flujo, puede actualizar, editar, guardar y liberar el flujo de nuevo para reemplazar el original y utilizar el nivel de satisfacción actualizado.',
    'ccivr.common.warning.message': 'Seleccione un registro.',
    'ccivr.ivr.voiceedit.passwdisnull': 'Error al consultar la información SFTP passewd del centro de llamadas.',
    'ccivr.nms.conditionService.conditionalEdit': 'Edición de condiciones',
    'ccivr.ivr.ivrflow.operate.ensureclone': 'Confirmar actualización de flujo',
    'ccivr.nms.LanguageService.label': 'Idioma',
    'ccivr.ivrflow.grayrule.existgrayflow': 'La versión de lanzamiento gris ya existe!',
    'ccivr.nms.tansferservice.transferLastAgent.mustbeaccesscode': 'El número de llamada es el número entrante del cliente, por ejemplo, {\'$\'}{\'{\'}SYS.callingNumber{\'}\'}.',
    'ccivr.nms.satisfactionservice.descripLength': 'La descripción de la clave de la encuesta de satisfacción no supera los 200',
    'ccivr.flowtest.operatesuccess': 'Éxito',
    'ccivr.nms.interface.paramKeyValidate': 'No utilice el nombre del parámetro definido por la variable integrada (callId, businessReqId, callSorId, currentTime). De lo contrario, la asignación de valor no será válida.',
    'ccivr.ivrflow.ivrflowname.specialStrValidateFailed': 'El valor no puede contener caracteres especiales y la longitud no puede superar los 50 bytes.',
    'ccivr.nms.interface.outparamvalidate': 'El valor del parámetro solo puede contener dígitos, letras y los siguientes caracteres especiales\:._-',
    'ccivr.nms.interface.paramvalidate': 'Los parámetros solo deben contener números, caracteres en inglés o _-',
    'ccivr.ivr.transferRecord.play': 'Reproducir',
    'ccivr.ivr.voiceupload.texttovoice': 'El texto introducido se convierte automáticamente en voz.',
    'ccivr.ivr.voicereview.upload': 'Cargado',
    'ccivr.nms.shortmessageservice.sendType': 'Modo de envío de mensajes',
    'ccivr.nms.brach': 'Sucursal',
    'ccivr.nms.playcollectservice.receivednum': 'Dígitos recibidos',
    'ccivr.ivr.ivrflow.operate.queryInfo': 'Vistas',
    'ccivr.nms.tansferservice.transInputWorkNo': 'Ingrese el ID del agente al que se transfiere una llamada.',
    'ccivr.ivr.ivrflow.yes': 'Sí',
    'ccivr.transferrecord.play.record': 'Reproducción de grabaciones de transferencia de proceso\:',
    'ccivr.ivr.error.download': 'Error al descargar el archivo desde el servidor SFTP.',
    'ccivr.nms.assignservice.Dataassignment': 'Asignación de datos',
    'ccivr.ivr.voicereview.tenantspace': 'Inicio Inquilino',
    'ccivr.ivr.error.overpower': 'El inquilino no está autorizado.',
    'ccivr.ivr.voiceupload.audiochannelfailed': 'El archivo de voz no es un archivo de voz mono.',
    'ccivr.nms.process.success': 'El flujo se guarda correctamente.',
    'ccivr.ivr.voiceedit.inputmore': 'El valor no puede exceder',
    'ccivr.ivr.voiceadvice.setdefault': 'Establecer como predeterminado',
    'ccivr.whitelist.whiteport': 'Puerto de lista de confianza',
    'ccivr.nms.shortmessageservice.selectTemplate': 'Seleccionar plantilla',
    'ccivr.nms.label.selectCert': 'Seleccione certificado',
    'ccivr.ivr.variable.namevaluerule': 'El nombre contiene caracteres especiales. Por favor, modifíquelo',
    'ccivr.ivr.element.namecheck': 'El nombre contiene caracteres especiales. Por favor, modifíquelo',
    'ccivr.nms.leavemessageservice.recordparam': 'Parámetro de grabación',
    'ccivr.ivrflow.grayrule.selectgrayrule': 'Seleccione una regla gris.',
    'ccivr.nms.leavemessageservice.leavemessage': 'Dejar mensaje',
    'ccivr.nms.tansferservice.passingParameters': 'Parámetros de transferencia',
    'ccivr.ivr.voiceadvice.submit': 'Enviar',
    'ccivr.ivr.transferRecord.startTime': 'Hora de inicio',
    'ccivr.nms.interface.certFile': 'Archivo de certificado',
    'ccivr.ivr.ivrflow.operate': 'Operación',
    'ccivr.ivrflow.grayrule.endnum': 'Número final',
    'ccivr.ivr.transferRecord.success': 'Éxito',
    'ccivr.ivrflow.release.formalinfo': 'Después de la liberación, el flujo de esta versión se ejecuta para todos los números.<br /> Si necesita modificar el flujo, debe actualizar una nueva versión y modificar la versión.',
    'ccivr.ivr.voiceupload.queryReferenceFlow': 'Vistas',
    'ccivr.ivrflow.grayrule.inputbeginnum': 'Ejemplo\: 12366668888',
    'ccivr.nms.conditionService.add': 'Nuevo',
    'ccivr.nms.conditionService.createCondition': 'Añadir condición',
    'ccivr.ivr.ivrflow.operate.clone.success': 'El proceso de actualización es exitoso.',
    'ccivr.nms.shortmessageservice.normalMessage': 'Mensaje corto',
    'ccivr.nms.leavemessageservice.choose.localtionID': 'Guardar el localtionID',
    'ccivr.ivr.voicereview.play': 'Reproducir',
    'ccivr.nms.queryservice.estimateTime.prompte': 'El tiempo de espera esperado de la cola se calcula en función del modelo de cola y el resultado tiene alguna desviación. Por favor, úselo correctamente.',
    'ccivr.flowtest.fail': 'Fallo',
    'ccivr.ivr.description.containspecialcharacter': 'La plantilla contiene caracteres especiales ~',
    'ccivr.ivr.voiceupload.vedionamewrong': 'Error en la comprobación de FileNmae de carga de archivos\!',
    'ccivr.nms.queryservice.specifiedSkill.paramValue': 'ID de agente',
    'ccivr.ivr.variable.management': 'Gestión de variables',
    'ccivr.nms.playcollectservice.Receivemaxnum': 'Número máximo de dígitos recibidos',
    'ccivr.ivr.ivrflow.operate.release': 'Lanzamiento',
    'ccivr.ivr.name.errorspecial': 'El nombre contiene caracteres especiales. Por favor, modificarlo.',
    'ccivr.ivrflow.error.deleteivrflow.part.released': 'Se han liberado alguno de los flujos IVR seleccionados. Cancele la versión antes de eliminarla.',
    'ccivr.ivr.tracelog.result.syserr': 'error del sistema',
    'ccivr.nms.playservice.chose': 'El contenido de reproducción se selecciona de los archivos de audio que se han cargado y revisado.',
    'ccivr.ivr.ivrflow.operate.delete': 'Borrar',
    'ccivr.ivr.bussinessinvoke.save': 'Guardar',
    'ccivr.ivr.variable.variableisreferenced': 'Las variables son referenciadas por el flujo.',
    'ccivr.ivr.transferRecord.transResult': 'Resultado de la transferencia',
    'ccivr.ivr.message.uploadimport.success': 'Agregando correctamente.',
    'ccivr.nms.playcollectservice.tip8': 'Durante la recopilación de dígitos, el usuario introduce la tecla de almohadilla (\#) para finalizar la colección de dígitos. Por favor, pregunte en la voz.',
    'ccivr.nms.playcollectservice.tip7': 'Cuando el número acumulado de tiempos de falla excede el máximo, se ejecuta la salida de fallo general.',
    'ccivr.nms.playcollectservice.tip6': 'Si la colección de dígitos falla, no es necesario reproducir la voz. Si no se selecciona ningún archivo de voz, el sistema vuelve a reproducir el mensaje de voz después del fallo.',
    'ccivr.ivr.variable.textvaluelimit': 'El texto no puede contener más de 120 caracteres.',
    'ccivr.nms.playcollectservice.tip5': 'Si se produce un error durante la recopilación de dígitos, el sistema registra un error, realiza el error de recopilación de dígitos automaticamente y continúa esperando la recopilación de digitos.',
    'ccivr.nms.playcollectservice.tip4': 'Si el tiempo en el que el suscriptor detiene la entrada excede el tiempo de espera de recopilación de dígitos preconfigurado, el sistema registra el error. Además, el sistema realiza automáticamente el tiempo de espera de recopilación de dígitos y espera la recopilación de dígitos.',
    'ccivr.whitelist.whitelistmanage': 'Gestión de listas de confianza',
    'ccivr.nms.playcollectservice.tip3': 'Durante la recopilación de dígitos, el usuario introduce la tecla de almohadilla (\#) para finalizar la colección de dígitos. Por favor, indíquelo en la voz.(no es necesario pulsar la tecla almohadilla (#) para recoger los dígitos en el elemento del diagrama de configuración del menú)',
    'ccivr.nms.playcollectservice.tip2': 'Seleccione el parámetro de tipo de cadena que agregó en la información del proceso.',
    'ccivr.nms.playcollectservice.tip1': 'Seleccione un parámetro para guardar la entrada del usuario.',
    'ccivr.flowtest.begintest': 'Iniciar',
    'ccivr.nms.process.paramnameNoCN': 'El paramname está equivocado.',
    'ccivr.ivr.ivrflow.operate.edit': 'Editar',
    'ccivr.nms.menuservice.button': 'Botones',
  'ccivr.ivr.bussinessinvoke.error': 'Error',
  'ccivr.ivr.voiceupload.submit': 'Enviar',
  'ccivr.ivr.voiceupload.onlyOneData': 'Mantenga al menos un registro de idioma.',
  'ccivr.ivr.voiceupload.audiosamplebitfailed': 'El número de bits de muestra para el archivo de voz no es 8.',
  'ccivr.ivrflow.operate.replace.success': 'Reemplazado correctamente.',
  'ccivr.ivr.name.toolong': 'El nombre excede la longitud máxima permitida. Por favor, inténtalo de nuevo.',
  'ccivr.nms.playservice.multivoice.type.date': 'Fecha (aaaammdd)',
  'ccivr.ivr.voicereview.all': 'Todo',
  'ccivr.ivrvoice.play.voicetimelimit': 'El audio de la audición es largo, reduzca el contenido de texto e inténtalo de nuevo.',
  'ccivr.nms.leavemessageservice.recordfile': 'Archivo de grabación',
  'ccivr.nms.conditionService.logicOperator': 'Lógico',
  'ccivr.nms.tansferservice.queueindex': 'Posición de cola',
  'ccivr.nms.tansferservice.queuenum': 'Número de colas',
  'ccivr.ivr.tracelog.result.hangup': 'HangUp',
  'ccivr.ivr.voicereview.filetype': 'Tipo',
  'ccivr.ivr.tracelog.traceConfig': 'Configuración de seguimiento de código de acceso',
  'ccivr.ivr.voiceupload.voiceUsageByTenant': 'Referencia del escenario del inquilino',
  'ccivr.ivr.variable.deleteselectvariable': 'Eliminar variable',
  'ccivr.ivr.ivrflow.operate.create.accesscode.fail': 'Error al agregar el código de acceso en ODFS al liberar el flujo IVR.',
  'ccivr.nms.playcollectservice.voicefile': 'Archivo de voz',
  'ccivr.nms.conditionService.condition': 'Nombre de la condición',
  'ccivr.nms.questionnaireService.placeholder': 'Cuestionario',
  'ccivr.nms.questionnaireService.surveyname': 'Nombre del cuestionario',
  'ccivr.nms.questionnaireService.question': 'Preguntas del cuestionario',
  'ccivr.ivr.voice.speed': 'velocidad',
  'ccivr.ivr.ivrflow.edit': 'Edicion',
  'ccivr.ivr.voiceupload.chinese': 'Chino',
  'ccivr.ivr.voiceadvice.confirm': 'OK',
  'ccivr.ivr.voiceadvice.lengthError': 'La longitud no puede exceder los 100 caracteres.',
  'ccivr.nms.conditionService.operat': 'Operación',
  'ccivr.nms.process.sysparam': 'Parámetros del sistema',
    'ccivr.grayrule.errorinfo.containspecialcha': 'El parámetro ruleName contiene un carácter especial',
    'ccivr.nms.ChangeMediaService.placeholder': 'Cambio de medios',
    'ccivr.ivrflow.error.deleteivrflow.released': 'Se ha liberado el flujo IVR seleccionado. Cancele la versión antes de eliminarla.',
    'ccivr.ivr.voicereview.refuse': 'Rechazar',
    'ccivr.ivrflow.cancelrelease.accesscode.delete.failed': 'Error al eliminar el código de acceso en ODFS al cancelar el flujo IVR liberado.',
    'ccivr.ivr.bussinessinvoke.validatemessage': 'Ya existe un parámetro con el mismo nombre.',
    'ccivr.nms.leavemessageservice.flownamerepeat': 'El nombre del archivo de grabación y localtionID no pueden utilizar la misma variable.',
    'ccivr.nms.timeselectservice.title': 'Selección de tiempo',
    'ccivr.nms.process.saveerror.connection': 'Error al guardar. Existen líneas no válidas.',
    'ccivr.ivr.voiceupload.selectvideo': 'Seleccione un archivo de vídeo.',
    'ccivr.nms.queryservice.specifiedSkill.result': 'Nombre de cola',
    'ccivr.nms.playcollectservice.collectwaittime': 'Tiempo de espera para la recopilación de dígitos',
    'ccivr.ivr.illegal.fail': 'Parámetro no válido.',
    'ccivr.nms.conditionService.delete': 'Borrar',
    'ccivr.nms.process.saveerror.select': 'Seleccione un tipo de cambio.',
    'ccivr.ivr.voiceadvice.currentdefault': 'Predeterminado',
    'ccivr.ivr.ivrflow.flowquote': 'Es referenciado',
    'ccivr.nms.playservice.chosevideo': 'El contenido que se va a reproducir se selecciona de los vídeos que se han subido y revisado.',
    'ccivr.nms.queryservice.skillMsg': 'Información de cola',
    'ccivr.ivr.voicereview.waitreview': 'Para revisar',
    'ccivr.ivr.variable.specialcharacters': 'No se permiten caracteres especiales o chinos.',
    'ccivr.ivr.voice.exception.recordfail': 'Error de grabación.',
    'ccivr.ivr.error.advicelimit': 'El comentario de revisión no puede contener más de 100 caracteres.',
    'ccivr.nms.businessinvokeservice.selectservice': 'Seleccionar interfaz',
    'ccivr.ivr.voicepop.inputname': 'Ingrese el nombre de voz del IVR.',
    'ccivr.nms.queryservice.assignmentParameters': 'Parámetro a establecer',
    'ccivr.whitelist.validator.ip': 'Dirección IP no válida. (Ingrese una dirección IPv4 o IPv6, o un nombre de dominio.)',
    'ccivr.ivr.voicereview.chinese': 'Chino',
    'ccivr.ivrflow.unlock.fail': 'Error de estado desbloqueado',
    'ccivr.ivr.tracelog.callid': 'Calido',
    'ccivr.ivr.variable.modifyvariable': 'Modificar variable',
    'ccivr.ivr.pageurls.errorsave': 'Error',
    'ccivr.nms.queryservice.specialList.flagInfo': 'Si el número de suscriptor está en la lista especial. Las opciones son 0 (no) y 1 (sí).',
    'ccivr.nms.tansferservice.transferLastAgent.type.time.label': 'min',
    'ccivr.nms.shortmessageservice.smsType': 'Canales',
    'ccivr.ivr.ivrflow.operate.ensureclone.info': '¿Está seguro de que desea actualizar el flujo?',
    'ccivr.nms.transInputWorkNo.sample': 'Ejemplo\: 28956666 o {\'$\'}{\'{\'}FLOW.currentWorkNo{\'}\'}',
    'ccivr.flowtest.enter': 'Ingrese el número de llamada.',
    'ccivr.nms.shortmessageservice.addresseeDesc': 'Opcional, Número de móvil único, si está en blanco, se enviará a la persona que llama',
    'ccivr.nms.businessinvokeservice.overtime': 'Intervalo de tiempo de espera',
    'ccivr.ivr.voiceupload.audiotypefailed': 'El archivo de voz no está en formato ALAW.',
    'ccivr.ivr.transferRecord.query.failed': 'Error al consultar grabaciones de transferencia',
    'ccivr.nms.process.number': 'Entero',
    'ccivr.nms.playcollectservice.changetomultivoice': 'Voz variable',
    'ccivr.ivr.error.connect': 'Error al conectarse al servidor SFTP.',
    'ccivr.nms.tansferservice.timedPrompt': 'No lo has guardado durante mucho tiempo. Por favor, guárdelo a tiempo.',
    'ccivr.nms.ringService.sendonly': 'Enviar sólo',
    'ccivr.ivr.variable.desclimit': 'La descripción no puede contener más de 120 caracteres.',
    'ccivr.flowtest.pleasebegintest': 'El proceso ha terminado.',
    'ccivr.ivr.tracelog.result.finshed': 'Terminado',
    'ccivr.nms.playservice.multivoice.type.price': 'Precios',
    'ccivr.nms.playcollectservice.onenumber': 'Colección de un dígito',
    'ccivr.nms.menuservice.movedown': 'Abajo',
    'ccivr.ivr.variable.defaultValue': 'Valor predeterminado',
    'ccivr.nms.process.saveerror.existnullentity': 'Existen elementos vacíos del diagrama de datos.',
    'ccivr.ivrflow.operate.replace': 'Reemplazar',
    'ccivr.ivrflow.flowAccessCode': 'FlowAccessCode',
    'ccivr.transferrecord.download.record': 'Descargar grabación de transferencia de flujo\:',
    'ccivr.ivr.voiceedit.cancel': 'Cancelar',
    'ccivr.nms.tansferservice.samenamevalidate': 'Nombre duplicado del parámetro.',
    'ccivr.nms.businessinvokeservice.info2': 'Si se excede el intervalo de tiempo de espera de invocación, se ejecuta el módulo de error de invocación.',
    'ccivr.nms.businessinvokeservice.info1': 'Las interfaces disponibles deben configurarse en la página de configuración de la interfaz.',
    'ccivr.ivr.transferRecord.download': 'Descargar',
    'ccivr.ivr.variable.defaultvalueerror': 'El valor predeterminado contiene caracteres especiales. Por favor, modificarlo.',
    'ccivr.ivr.voicereview.reviewmethod': 'Modo de revisión de recursos de voz',
    'ccivr.ivr.voicereview.manualreview': 'Revisión manual',
    'ccivr.ivr.voicereview.autopass': 'Pase automático',
    'ccivr.ivr.voiceupload.file.content': 'Contenido',
    'ccivr.ivr.voiceupload.file.update.content': 'Actualizar contenido',
    'ccivr.ivr.voiceupload.file.update.time': 'Tiempo de actualización',
    'ccivr.ivr.voiceupload.file.update.status': 'Estado',
    'ccivr.ivr.voiceupload.file.update.comments': 'Comentarios',
    'ccivr.ivr.voiceupload.file.update.operation': 'Operación',
    'ccivr.ivr.voiceupload.file.update.delete': 'Borrar',
    'ccivr.ivr.voiceupload.file.update.submit': 'Enviar',
    'ccivr.ivr.voiceupload.file.update': 'Actualización',
  'ccivr.ivr.voiceupload.textcontent': 'Texto',
  'ccivr.ivr.bussinessinvoke.long': 'Entero largo',
  'ccivr.ivr.voiceupload.fileformatwrong': 'Tipo incorrecto.',
  'ccivr.nms.playservice.TTS': 'Reproducción de voz TTS',
  'ccivr.ivr.voiceupload.inputlanguageerror': '¡El lenguaje no existe\!',
  'ccivr.ivr.voiceupload.choosestatus': 'Seleccione el estado de revisión.',
  'ccivr.ivr.bussinessinvoke.float': 'Flotante',
  'ccivr.nms.playservice.interrupt': 'Pulse cualquier tecla para interrumpir la reproducción.',
  'ccivr.flowtest.test.error.tip': 'El flujo no tiene configuración ni aplica',
  'ccivr.nms.label.certType': 'Tipo de certificado',
  'ccivr.nms.label.inputCertCode': 'Introduzca el código del certificado',
  'ccivr.nms.label.certCode': 'Código de certificado',
  'ccivr.nms.label.certScene': 'Escenarios de aplicación de certificados',
  'ccivr.ivr.error.releaseFailed.tips': 'Error al publicar automáticamente el cuestionario. Configure y libere formalmente un proceso de manejo de excepciones y, a continuación, libere manualmente el cuestionario.',
  'ccivr.ivr.ivrflow.operate.add': 'Añadir',
  'ccivr.nms.playservice.chose.survey': 'Reproducir contenido Seleccione o agregue una voz de los archivos de audio cargados y revisados',
  'ccivr.nms.playservice.chosetts.survey': 'Seleccione o agregue un texto del texto TTS cargado y aprobado para el contenido reproducido. El recurso TTS debe estar disponibl',
  'ccivr.nms.satisfactionservice.buttonNumber.Number': 'El número de teclas no debe exceder de 9 y no debe ser inferior a 1.',
  'ccivr.nms.lightweight.tips': 'Realice la operación en la versión ligera del cuestionario',
  'ccivr.conditionService.param.tips': 'Cuando se establece el parámetro 2 como una constante de cadena, es necesario agregar comillas para poder usarlo correctamente.',
  'ccivr.nms.conditionService.sample': 'Introduzca una expresión de condición. La expresión puede contener el nombre del parámetro, el valor y la operación compleja. Se hace referencia a un parámetro en el formato de nombre FLOW.Parameter. Ejemplo\: SYS.currentNumber\=\=FLOW.RechargeNumber&& (FLOW.AccountBalance - 20)>\=FLOW.BillAmount',
  'ccivr.nms.conditionService.tips': 'Las expresiones condicionales pueden contener variables, valores y operaciones complejas. El operador de comparación entre valores o cadenas de caracteres admite > < >= <= !=. El operador booleano admite && {\'|\'} {\'|\'} = Ejemplo: SYS.currentNumber==FLOW.RechargeNumber && (FLOW.AccountBalance-20)>=FLOW.BillAmount',
  'ccivr.TimeSelectService.branch.wrongtime': 'Tiempo equivocado',
  'ccivr.playservice.branch.query': 'Error de consulta',
  'ccivr.businessInvokeService.err': 'Error en la llamada a la interfaz',
  'ccivr.playservice.branch.ring': 'Error de anillo',
  'ccivr.playservice.branch.userLevel': 'Error de configuración',
  'ccivr.tansferservice.branch.transferErr': 'Error de transferencia',
  'ccivr.nms.transferservice.choose.recordfilename.remark': 'utilizado en el modo de grupo del centro de llamadas',
  'ccivr.playservice.branch.change': 'Error de cambio',
  'ccivr.nms.conditionService.conditionBranch': 'rama condicional',
  'ccivr.playservice.branch.playerr': 'Error de reproducción',
  'ccivr.PlayCollectService.branch.err': 'Error de PlayCollect',
  'ccivr.nms.playcollectservice.warntype': 'Tipo de solicitud',
  'ccivr.ivr.bussinessinvoke.paramTip': 'Nombre del parámetro, por ejemplo, GLOBAL.language o FLOW.testid',
  'ccivr.leaveMessage.maxtime.range': 'La duración máxima de grabación oscila entre 10 y 180 segundos.',
  'ccivr.ivr.voiceupload.fileName.validate': 'El nombre de archivo no puede contener los siguientes caracteres especiales:',
  'ccivr.nms.play.method': 'Método',
  'ccivr.nms.play.source': 'Recurso',
  'ccivr.ivr.ivrflow.operate.release.referencedflow': 'Error al cancelar la versión! El flujo ha sido referenciado.',
  'ccivr.ivr.voice.scene.2d.avatar': 'Humano virtual',
  'ccivr.ivr.voice.picturechoose':'Selección de archivo de imagen',
  'ccivr.ivr.voiceupload.selectpicture': 'Seleccionar un archivo de imagen',
  'ccivr.ivr.voiceupload.tippictureformat': 'Actualmente, solo se admiten archivos de imagen JPG, PNG y JPEG. La resolución del archivo no puede exceder los 720p, y el tamaño del archivo no puede exceder los 2 MB.',
  'ccivr.ivr.voiceupload.tippicturemodel': 'Puede configurar archivos de imagen en diferentes idiomas al mismo tiempo. El sistema cambia automáticamente al archivo de imagen correspondiente basándose en el idioma seleccionado por el usuario durante la síntesis digital de personas.',
  'ccivr.ivr.voiceupload.tip2DVideoformat': 'Actualmente, solo se admiten archivos de vídeo MP4. La resolución del archivo no puede exceder los 720p, y el tamaño del archivo no puede exceder los 5 MB.',
  'ccivr.ivr.voiceupload.tip2Dvideomodel': 'Puede configurar archivos de vídeo en diferentes idiomas al mismo tiempo. Durante la composición humana digital, el sistema cambia automáticamente al archivo de vídeo correspondiente basándose en el idioma seleccionado por el usuario.',
  'ccivr.ivr.voiceupload.vedioMp4formatwrong': 'Formato de archivo incorrecto. Por favor, importa un archivo MP4.',
  'ccivr.ivr.voiceupload.tipvideo2Dformat': 'Actualmente, solo se admiten archivos de vídeo MP4. El tamaño del archivo no puede superar los 5 MB.',
  'ccivr.ivr.voicereview.picture': 'Imagen',
  'ccivr.ivr.voicereview.2dVideo': 'Vídeo humano virtual',
  'ccivr.ivr.voiceupload.tipPicture2Dformat': 'Actualmente, solo se admiten archivos de imagen en formato .jpg, .png y .jpeg. El tamaño del archivo no puede superar los 2 MB.',
  'ccivr.ivr.voiceupload.pictureformatwrong': 'El formato de archivo es incorrecto. Importe un archivo .jpg, .png o .jpeg.',
  'ccivr.ivr.voiceupload.file.updateTitle': 'Editar recursos de audio y vídeo',
  'ccivr.ivr.voiceupload.file.createTitle': 'Creación de recursos de audio y vídeo',
  'ccivr.ivr.voiceupload.tippictureresolution': 'La resolución de la imagen no puede superar los 720P',
  'ccivr.ivr.voiceupload.tipvideoresolution': 'La resolución de vídeo no puede superar los 720P',
  'oifde.service.invoke.businessVirtualHumanService.name': 'Respuesta de Persona Virtual',
}
