export default {
  "ccd.agent.button.signIn": "签入",
  "ccd.agent.button.signOut": "签出",
  "ccd.agent.button.sayBusy": "示忙",
  "ccd.agent.button.sayFree": "示闲",
  "ccd.agent.button.rest": "休息",
  "ccd.agent.button.cancelRest": "结束休息",
  "ccd.agent.button.restSkillQueue": "重设技能",
  "ccd.agent.button.queryStatus": "查询状态",
  "ccd.agent.button.transfer": "转接",
  "ccd.agent.button.hold": "保持",
  "ccd.agent.button.getHold": "取保持",
  "ccd.agent.button.callOut": "外呼",
  "ccd.agent.button.threeParty": "三方",
  "ccd.agent.button.innerHelp": "求助",
  "ccd.agent.button.mediaswitch": "音视频切换",
  "ccd.agent.button.mute": "静音",
  "ccd.agent.button.twostage": "拨号盘",
  "ccd.agent.button.endMute": "取消静音",
  "ccd.agent.button.answer": "接听",
  "ccd.agent.button.hangUp": "挂断",
  "ccd.agent.button.screenShare": "桌面共享",
  "ccd.agent.button.notscreenShare": "取消桌面共享",
  "ccd.agent.label.offline": "离线",
  "ccd.agent.label.idle": "空闲",
  "ccd.agent.label.busy": "忙碌",
  "ccd.agent.label.rest": "休息",
  "ccd.agent.label.talk": "通话",
  "ccd.agent.label.adjust": "整理",
  "ccd.agent.label.reply": "回复",
  "ccd.agent.message.AgentIsEmpty": "当前帐号没有绑定座席",
  "ccd.agent.message.agentNoConfSkill": "该座席没有配置技能队列",
  "ccd.agent.message.AgentIsSupend": "当前座席被冻结",
  "ccd.agent.tips.confSuccess": "配置成功",
  "ccd.agent.tips.confFail": "配置失败",
  "ccd.agent.tips.error": "错误",
  "ccd.agent.tips.success": "成功",
  "ccd.agent.tips.info": "提示",
  "ccd.agent.tips.welcome": "欢迎回来，祝工作愉快！",
  "ccd.agent.tips.welcome.agentlogout": "欢迎！",
  "ccd.agent.tips.media.agentType.no.support.callout": "多媒体座席不支持外呼",
  "ccd.agent.webrtc.label.offline": "未注册",
  "ccd.agent.webrtc.label.online": "已注册",
  "ccd.agent.webrtc.label.talk": "通话中",
  "ccd.agent.webrtc.label.connetfailed": "注册失败",
  "ccd.agent.webrtc.label.buildfailed": "建立连接失败",
  "ccd.agent.webrtc.label.connect": "注册",
  "ccd.agent.webrtc.label.sippwd": "软电话密码",
  "ccd.agent.webrtc.label.unsupportbrower": "浏览器不支持",
  "ccd.agent.webrtc.label.missmic": "缺少麦克风",
  "ccd.agent.webrtc.label.misspeaker": "缺少扬声器",
  "ccd.agent.webrtc.label.videopop": "视频窗口",
  "ccd.agent.webrtc.label.sotpsendvideo": "停止发送视频",
  "ccd.agent.webrtc.label.hidelocalvideo": "隐藏本地视频",
  "ccd.agent.webrtc.label.videosize": "视频尺寸",
  "ccd.agent.webrtc.label.large": "大",
  "ccd.agent.webrtc.label.medium": "中",
  "ccd.agent.webrtc.label.small": "小",
  "ccd.agent.webrtc.label.localvideo": "本地视频",
  "ccd.agent.webrtc.label.remotevideo": "对方视频",
  "ccd.agent.webrtc.label.accept": "接听",
  "ccd.agent.webrtc.label.reject": "拒绝",
  "ccd.agent.webrtc.label.logoutregistered": "是否退出注册",
  "ccd.agent.webrtc.label.passwordvalidator": "输入字符长度超出取值范围，长度必须小于等于50。",
  "ccd.agent.webrtc.label.busy.tip": "正在通话使用中",
  "ccd.agent.webrtc.label.busy.info": "提示",
  "ccd.agent.webrtc.label.busy.confirm": "确认",
  "ccd.agent.webrtc.label.button.yes": "是",
  "ccd.agent.webrtc.label.button.no": "否",
  "ccd.agent.webrtc.label.sippwd.placeholder": "请输入",
  "ccd.agent.webrtc.label.remote.name": "对方",
  "ccd.agent.webrtc.label.local.name": "座席",
  "ccd.agent.webrtc.label.changeVoice": "转语音",
  "ccd.agent.agentevent.success": "成功",
  "ccd.agent.agentevent.failed": "失败",
  "ccd.agent.agentevent.warning": "警告",
  "ccd.agent.agentevent.info": "提示",
  "ccd.agent.agentevent.AgentState_Force_SetNotReady": "座席示忙成功",
  "ccd.agent.agentevent.AgentState_CancelNotReady_Success": "座席取消示忙成功",
  "ccd.agent.agentevent.AgentState_Ready": "座席示闲成功",
  "ccd.agent.agentevent.AgentState_CancelWork_Success": "座席退出工作态成功",
  "ccd.agent.agentevent.AgentState_SetRest_Success": "座席休息成功",
  "ccd.agent.agentevent.AgentState_CancelRest_Success": "座席取消休息成功",
  "ccd.agent.agentevent.AgentState_SetWork_Success": "座席进入工作态",
  "ccd.agent.agentevent.AgentState_Work": "座席进入整理态",
  "ccd.agent.agentevent.AgentState_Busy": "座席正在进行工作处理",
  "ccd.agent.agentevent.AgentEvent_Customer_Alerting": "用户振铃",
  "ccd.agent.agentevent.AgentEvent_Ringing": "座席来电",
  "ccd.agent.agentevent.AgentEvent_Hold": "呼叫保持成功",
  "ccd.agent.agentevent.AgentEvent_Hold.desc": "座席已处于保持状态",
  "ccd.agent.agentevent.AgentEvent_Talking": "座席进入Talking状态",
  "ccd.agent.agentevent.AgentEvent_MediaSwith.success": "音视频切换成功",
  "ccd.agent.agentevent.AgentEvent_MediaSwith.failed": "音视频切换失败",
  "ccd.agent.agentevent.AgentMediaEvent_Play": "录音播放开始",
  "ccd.agent.agentevent.AgentMediaEvent_PausePlayDone": "暂停录音播放成功",
  "ccd.agent.agentevent.AgentMediaEvent_ResumePlayDone": "继续录音播放成功",
  "ccd.agent.agentevent.AgentMediaEvent_JumpPlayDone": "录音播放快进快退成功",
  "ccd.agent.agentevent.AgentMediaEvent_StopPlayDone": "录音播放停止",
  "ccd.agent.agentevent.AgentEvent_Conference": "三方通话成功",
  "ccd.agent.agentevent.AgentEvent_Call_Out_Fail": "座席发起外部呼叫失败",
  "ccd.agent.agentevent.AgentEvent_Inside_Call_Fail": "座席发起内部呼叫失败",
  "ccd.agent.agentevent.AgentEvent_Call_Release": "座席退出呼叫",
  "ccd.agent.agentevent.AgentConf_SelfJoinResult": "座席加入会议",
  "ccd.agent.agentevent.AgentEvent_No_Answer": "座席久不应答，呼叫挂断",
  "ccd.agent.agentevent.AgentEvent_Customer_Release": "客户退出呼叫",
  "ccd.agent.agentevent.AgentEvent_Auto_Answer": "座席自动应答",
  "ccd.agent.agentevent.AgentEvent_Connect_Fail": "连接失败",
  "ccd.agent.agentevent.AgentEvent_Consult_Fail": "座席咨询失败",
  "ccd.agent.agentevent.AgentEvent_ReturnFromIvr": "从IVR返回座席成功",
  "ccd.agent.agentevent.AgentEvent_Preview_CallOut": "预览外呼成功",
  "ccd.agent.agentevent.AgentEvent_Preview": "座席接收预览发起成功",
  "ccd.agent.agentevent.AgentEvent_Agent_Occupyed": "座席已被预占",
  "ccd.agent.agentevent.AgentEvent_Release_Preview": "预览呼叫释放成功",
  "ccd.agent.agentevent.AgentOther_ShutdownService": "座席签出成功",
  "ccd.agent.agentevent.AgentOther_InService": "座席签入成功",
  "ccd.agent.agentevent.AgentOther_PhoneAlerting": "座席正在振铃",
  "ccd.agent.agentevent.AgentOther_PhoneOffhook": "座席已摘机",
  "ccd.agent.agentevent.AgentOther_PhoneRelease": "座席已挂机",
  "ccd.agent.agentevent.AgentOther_PhoneUnknow": "座席话机状态未知",
  "ccd.agent.agentevent.AgentOther_All_Agent_Busy": "座席全忙",
  "ccd.agent.agentevent.AgentOther_Restskill_Queue": "技能队列操作成功",
  "ccd.agent.agentevent.callType": "呼叫类型：",
  "ccd.agent.agentevent.callId": "呼叫id：",
  "ccd.agent.agentevent.type.main": "主呼叫",
  "ccd.agent.agentevent.type.assistant": "协助呼叫",
  "ccd.agent.agentevent.otherPhone": "振铃方电话号码/工号：",
  "ccd.agent.agentevent.feature.caller": "主叫号码：",
  "ccd.agent.agentevent.feature.called": "被叫号码：",
  "ccd.agent.agentevent.feature.callin": "普通呼入",
  "ccd.agent.agentevent.feature.callout": "普通外呼",
  "ccd.agent.agentevent.mediaAblity": "媒体类型：",
  "ccd.agent.agentevent.mediaAblity.voice": "音频",
  "ccd.agent.agentevent.mediaAblity.video": "视频",
  "ccd.agent.agentevent.mediaAblity.voiceandvideo": "音视频",
  "ccd.agent.agentevent.mediaAblity.text": "文字",
  "ccd.agent.agentevent.AgentEvent_Conference.partner": "通话方号码：",
  "ccd.agent.agentevent.AgentEvent_No_Answer.number": "挂断方号码：",
  "ccd.agent.agentevent.AgentEvent_Preview_CallOut.controlid": "预览呼出控制块id：",
  "ccd.agent.agentevent.AgentEvent_Preview_CallOut.dialeddigits": "预览呼出被叫号码：",
  "ccd.agent.flash.calling": "来电",
  "ccd.agent.flash.message": "新消息",
  "ccd.agent.openeye.deamonready": "OpenEye已启动",
  "ccd.agent.openeye.deamonready.failinfo": "OpenEye启动失败",
  "ccd.agent.openeye.deamonready.fail": "OpenEye启动失败,请手动注册登录OpenEye,会导致自动接听功能无法使用",
  "ccd.agent.query.sip.fail": "查询SIP数据失败,请重新签入或手动注册登录OpenEye",
  "ccd.agent.openeye.sipconfig.success": "SIP配置成功",
  "ccd.agent.openeye.poolsipconfig.success": "POOL配置成功",
  "ccd.agent.openeye.sipconfig.fail": "SIP配置失败,请重新签入或手动注册登录OpenEye",
  "ccd.agent.openeye.poolsipconfig.fail": "POOL配置失败,请重新签入或手动注册登录OpenEye",
  "ccd.agent.openeye.register": "软电话号码注册成功",
  "ccd.agent.openeye.already.register": "软电话号码已经注册",
  "ccd.agent.openeye.register.fail": "软电话号码已经注册,或其他原因",
  "ccd.agent.openeye.deregister": "软电话号码注销成功",
  "ccd.agent.openeye.deregister.fail": "软电话号码注销失败,请手动退出OpenEye",
  "ccd.agent.openeye.autoanswer": "自动接听成功",
  "ccd.agent.openeye.autoanswer.fail": "自动接听失败",
  "ccd.agent.openeye.videowindowparam": "视频参数初始化成功",
  "ccd.agent.openeye.videowindowparam.fail": "视频参数初始化失败,会导致视频功能异常",
  "ccd.agent.openeye.videolayoutmode.success": "设置视频窗口画面排列模式成功",
  "ccd.agent.openeye.videolayoutmode.fail": "设置视频窗口画面排列模式失败",
  "ccd.agent.openeye.videodisplaymode.success": "设置视频窗口画面裁剪模式成功",
  "ccd.agent.openeye.videodisplaymode.fail": "设置视频窗口画面裁剪模式失败",
  "ccd.agent.frontpage.callreason": "来电原因",
  "ccd.agent.callrelease.end": "通话结束",
  "ccd.agent.resetphonepass.inputcur": "请输入当前帐号密码",
  "ccd.agent.resetphonepass.input": "请输入密码",
  "ccd.agent.resetphonepass.inputagain": "请再次输入密码",
  "ccd.agent.openeye.share": "请选择...",
  "ccd.ccaction.title.shareapp": "选择共享程序",
  "ccd.agent.openeye.switchshare.success": "共享桌面功能开启",
  "ccd.agent.openeye.switchshare.fail": "共享桌面功能开启失败",
  "ccd.agent.openeye.getapplist.fail": "获取已打开的应用列表失败",
  "ccd.agent.openeye.setshareapp.fail": "设置共享窗口失败",
  "ccd.agent.openeye.startshare.fail": "开始共享失败",
  "ccd.agent.openeye.stopshare.fail": "停止共享失败",
  "ccd.agent.button.offlineSignOut": "退出场外手机接听",
  "ccd.agent.button.offlineSignIn": "场外手机接听",
  "ccd.agent.button.ok": "确定",
  "ccd.agent.button.close": "关闭",
  "ccd.agent.message.phone": "接听号码",
  "ccd.agent.login.fail": "注册失败",
  "ccd.agent.login.failinfo": "，当前情况下，内部求助等功能不可用",
  "ccd.agent.login.multimedia.failinfo": ", 当前情况下, 音视频和内部求助等功能不可用",
  "ccd.agent.reset.success": "预约休息成功",
  "ccd.agent.openeye.initsuccess": "OpenEye初始化成功",
  "ccd.agent.pagination.total": "总共：",
  "ccd.agent.title.consult": "咨询",
  "ccd.agent.warning.sharescreen": "共享桌面时需注意个人隐私保护",
  "ccd.agentmonitor.span.listening": "监听中",
  "ccd.agentmonitor.span.insert": "插入中",
  "ccd.agentmonitor.span.whisper": "耳语中",
  "ccd.agentMonitor.label.switchlistenorinsert": "预质检状态下,被耳语的座席不支持点击监听或插入按钮",
  "ccd.agent.agentevent.quality.calloutconnectfail": "正在质检中,无法外呼",
  "ccd.agent.agentevent.calloutconnectfail": "用户当前正忙或者号码不存在",
  "ccd.message.title.audioIncomingCallNotify": "音视频来话提醒",
  "ccd.message.title.mediaIncomingCallNotify": "多媒体来话提醒",
  "ccd.message.title.mediaNewMsgNotify": "多媒体新消息提醒",
  "ccd.agent.tips.maxCountQuery.info": "当前数据量过大，系统仅展示最近的{0}条数据",
  "ccd.media.tips.new.online.customer": "您有一个新的在线客户",
  "ccd.agent.warning.notification.cancel": "取消",
  "ccd.agent.warning.notification.setting": "设置",
  "ccd.agent.warning.notification.allowed": "为了保障会话消息提醒，需要允许网站显示通知！",
  "ccd.agent.msg.notification.allowed": "AICC网站申请允许浏览器显示通知！",
  "ccd.agent.msg.notification.permission.denied": "用户拒绝授权，不能显示通知，请在浏览器设置修改",
  "ccd.agent.msg.queryMsg.specialListQuery": "特殊名单提醒",
  "ccd.agent.message.AgentIsProhibited": "该租间已被禁止使用。",
  "ccd.agent.message.repeatPhonenum": "座席签入使用的电话号码已被其他座席签入使用，请检查配置",
  "ccd.agent.message.destinationAgentFailed": "目的座席占用失败",
  "ccd.agent.message.noAgent": "无业务代表上班",
  "ccd.agent.warning.waserror": "WAS资源错误无法访问或产生内部错误。",
  "ccd.agent.message.eventMethodErr": "获取agent事件的方式不正确",
  "ccd.agent.message.restInvalid": "通过URL传递的参数或地址不符合定义",
  "ccd.agent.message.restNoRight": "无权限调用接口，可能该座席已经被强制签出",
  "ccd.agent.message.invalidWasURL": "服务端配置WAS的链接路径有误",
  "ccd.agent.message.loginInvalidPara": "登录失败，签入参数为空或不合法",
  "ccd.agent.message.userOrPasswdInvalid": "登录失败，帐号或密码错误",
  "ccd.agent.message.logOutInvalidParam": "登出失败，参数不合法",
  "ccd.agent.message.AgentAlreadyLogout": "该座席已经登出",
  "ccd.agent.message.loginInvalidParam": "电话号码不合法或已被其他座席签入",
  "ccd.agent.message.AgentInnerError": "操作失败，可能是发生内部错误或者没有可用资源，或者座席不在线",
  "ccd.agent.message.setStatErr": "设置座席状态失败",
  "ccd.agent.message.logOutStatusErr": "迁出失败，可能由于您有业务还需处理",
  "ccd.agent.message.callOutPhoneErr": "被叫是自己的电话号码",
  "ccd.agent.message.answerNoCall": "应答时没有呼叫",
  "ccd.agent.message.beginMuteNoCall": "静音时没有呼叫",
  "ccd.agent.message.beginMuteStateErr": "静音时呼叫状态错误",
  "ccd.agent.message.connectHoldNoCall": "连接保持时没有呼叫",
  "ccd.agent.message.noHoldCall": "连接保持时没有保持呼叫",
  "ccd.agent.message.endMuteNoCall": "取消静音时没有呼叫",
  "ccd.agent.message.endMuteStateErr": "取消静音时呼叫状态错误",
  "ccd.agent.message.threePartyNoCall": "三方通话时没有呼叫",
  "ccd.agent.message.threePartyNoHoldCall": "三方通话时没有保持呼叫",
  "ccd.agent.message.transferNoCall": "呼叫转移时没有呼叫",
  "ccd.agent.message.holdNoCall": "保持时没有呼叫",
  "ccd.agent.message.holdCallStatusErr": "保持时呼叫状态错误",
  "ccd.agent.message.getHoldNoHoldCall": "取保持时没有保持呼叫",
  "ccd.agent.message.getHoldCallStatusErr": "取保持时呼叫状态错误",
  "ccd.agent.message.releaseNoCall": "挂断时无呼叫",
  "ccd.agent.message.insultNoCall": "内部咨询时无呼叫",
  "ccd.agent.message.releaseNumberNoCall": "释放指定电话号码时没有呼叫",
  "ccd.agent.message.mediaSwitchNoCall": "音视频切换时没有呼叫",
  "ccd.agent.message.mediaSwitchSuccess": "音视频切换成功",
  "ccd.agent.message.mediaSwitchFailed": "音视频切换失败",
  "ccd.agent.message.releaseNumberNoNumber": "释放指定电话号码时不存在此号码呼叫",
  "ccd.agent.message.loginInvalidPhone": "无效电话号码",
  "ccd.agent.message.accoutnLocked": "该帐号目前处于锁定状态，请稍候登录",
  "ccd.agent.message.agentNotLogin": "座席没有登录",
  "ccd.agent.message.monitorSelf": "质检座席不能为本人",
  "ccd.agent.message.hangupnocall": "呼叫无法挂断，如果正在保持，请先取保持",
  "ccd.agent.message.default": "内部错误，请查看日志",
  "ccd.agent.message.wait": "呼叫正在处理，请稍后重试",
  "ccd.agent.message.reset": "呼叫处理失败过多，尝试同步座席状态",
  "ccd.agent.message.InvalidNumber": "无效的号码",
  "ccd.agent.videocall.paramError": "三方视频通话调整展现模式时参数错误",
  "ccd.agent.videocall.noCall": "三方视频通话调整展现模式时没有呼叫错误",
  "ccd.agent.videocall.typeError": "三方视频通话调整展现模式时呼叫类型错误",
  "ccd.agent.videocall.stateError": "三方视频通话调整展现模式时呼叫状态错误",
  "ccd.agent.videocall.displayError": "三方视频通话调整展现模式时产生异常",
  "ccd.agent.videocall.displayWarn": "只有三方都是视频时，才可以调整视频窗口大小",
  "ccd.agent.previewcall.invalParam": "预览外呼参数错误",
  "ccd.agent.videocall.adjustvideo": "调整视频窗口大小",
  "ccd.agent.callout.notrest": "请先取消休息，再进行外呼",
  "ccd.cccbar.label.inputNumber": "请输入号码",
  "ccd.cccbar.label.voice": "音频",
  "ccd.cccbar.label.video": "视频",
  "ccd.cccbar.label.cancel": "取消",
  "ccd.cccbar.label.send": "发送",
  "ccd.cccbar.label.yourself": "自己",
  "ccd.cccbar.label.nocall": "暂无呼叫",
  "ccd.cccbar.label.callout": "呼叫",
  "ccd.cccbar.label.callcenter": "呼叫中心",
  "ccd.cccbar.label.cccbar": "接续条",
  "ccd.cccbar.label.empty": "清空",
  "ccd.cccbar.label.backspace": "退格",
  "ccd.cccbar.label.total": "总计:",
  "ccd.cccbar.message.calltip": "Hi,来电话啦！",
  "ccd.cccbar.message.pleaseAnswer": "请注意接听",
  "ccd.cccbar.message.syserrormessage": "系统错误！",
  "ccd.transfer.label.callTransfer": "呼叫转移",
  "ccd.transfer.label.skill": "技能队列",
  "ccd.transfer.label.transferToSkill": "转移给技能队列",
  "ccd.transfer.label.skillName": "技能队列名称",
  "ccd.transfer.label.queueSize": "当前排队人数",
  "ccd.transfer.label.queueSize.ccbar": "排队",
  "ccd.transfer.label.loggedOnAgents": "登录的座席数",
  "ccd.transfer.label.loggedOnAgents.ccbar": "可用座席",
  "ccd.transfer.label.transferType": "转移类型",
  "ccd.transfer.label.transferTypeColon": "转移类型:",
  "ccd.transfer.label.transferTypeColon.ccbar": "转移类型:",
  "ccd.transfer.label.agent": "座席",
  "ccd.transfer.label.transferToAgent": "转移给座席",
  "ccd.transfer.label.statusUnknow": "状态未知",
  "ccd.transfer.label.externalNumber": "外部号码",
  "ccd.transfer.label.transferToExNumber": "转移给外部号码",
  "ccd.transfer.label.successTransfer": "成功转",
  "ccd.transfer.label.releaseTransfer": "释放转",
  "ccd.transfer.label.talkingTransfer": "通话转",
  "ccd.transfer.label.hangUpTransfer": "挂起转",
  "ccd.transfer.label.threeParthTransfer": "三方转",
  "ccd.transfer.label.ivr": "IVR",
  "ccd.transfer.button.refresh": "刷新",
  "ccd.transfer.button.cancel": "取消",
  "ccd.transfer.button.sure": "确认",
  "ccd.transfer.message.NoAgentInSkill": "当前技能队列下无在线座席",
  "ccd.transfer.message.NoIvrOnline": "当前无可用IVR流程",
  "ccd.transfer.message.inputExNumber": "转移号码",
  "ccd.transfer.message.refreshTab": "请勿连续多次刷新页面",
  "ccd.transfer.message.microsoftTeamsLogin": "登录",
  "ccd.transfer.message.inputTeamsUser": "请输入teams帐号",
  "ccd.transfer.message.inputIvrName": "请输入要转移的IVR名称",
  "ccd.innerhelp.label.help": "求助",
  "ccd.innerhelp.label.innerHelpToSkill": "求助到技能队列",
  "ccd.innerhelp.label.innerHelpToAgent": "求助到座席",
  "ccd.innerhelp.label.twoPartyHelp": "两方求助",
  "ccd.innerhelp.label.threePartyHelp": "三方求助",
  "ccd.innerhelp.label.helpType": "求助类型",
  "ccd.innerhelp.message.inputExNumhelp": "请输入要求助的号码",
  "ccd.callinner.label.callinner": "内部呼叫",
  "ccd.agent.label.recordPlay": "放音",
  "ccd.agent.title.warning": "告警",
  "ccd.agent.message.misconnection": "会话保持连接接口调用失败",
  "ccm.agent.message.selectIvr": "请选择一个IVR流程",
  "ccd.agent.status.occupy": "占用",
  "ccd.agent.status.askAnswer": "请求应答",
  "ccd.agent.status.talk": "通话",
  "ccd.agent.status.hold": "保持",
  "ccd.agent.status.mute": "静音",
  "ccd.agent.status.callout": "外呼中",
  "ccd.agent.status.threeParty": "三方",
  "ccd.agent.status.help": "求助",
  "ccd.agent.status.innerCall": "内部呼叫",
  "ccd.agent.status.innerTwo": "咨询",
  "ccd.agent.status.innerThree": "三方",
  "ccd.agent.message.checkAgentStatus": "请在质检员空闲状态下操作",
  "ccd.agent.message.needLogin": "请签入座席后操作",
  "ccd.agent.tips.restTooLong": "休息超时了",
  "ccd.callout.message.numberFormat": "号码只能包含数字、#、*，且不能超过24位",
  "ccd.quality.message.noProvider": "质检操作时发生NOPROVIDER异常",
  "ccd.quality.message.noAuth": "质检操作时发生没有权限异常",
  "ccd.quality.message.resourceUnable": "质检操作时发生异常,请检查相关日志",
  "ccd.quality.message.noAgentInfo": "质检监视时发生没有座席信息",
  "ccd.quality.message.statusErr": "质检操作时发生状态异常.",
  "ccd.quality.message.inspectInspectorErr": "被质检座席不能为质检员",
  "ccd.record.message.jumpBack": "快退时状态错误",
  "ccd.record.message.jumpForw": "快进时状态错误",
  "ccd.record.message.pausePlay": "暂停放音时状态错误",
  "ccd.record.message.pauseRecord": "暂停录音时状态错误",
  "ccd.record.message.resumePlay": "继续放音时状态错误",
  "ccd.record.message.resumeRecord": "继续录音时状态错误",
  "ccd.record.message.startPlay": "开始放音时状态错误",
  "ccd.record.message.startRecord": "开始录音时状态错误",
  "ccd.record.message.stopPlay": "停止放音时状态错误",
  "ccd.quality.message.qualityTitle": "质检",
  "ccd.quality.message.listen": "监听",
  "ccd.quality.message.stopListen": "停止监听",
  "ccd.quality.message.insert": "插入",
  "ccd.quality.message.stopInsert": "停止插入",
  "ccd.quality.message.switch": "切换",
  "ccd.quality.message.intercept": "拦截",
  "ccd.quality.message.whisper": "耳语",
  "ccd.quality.message.cancelwhisper": "取消耳语",
  "ccd.quality.message.forceexit": "强制退出",
  "ccd.quality.message.forcerest": "强制示闲",
  "ccd.quality.message.forcebusy": "强制示忙",
  "ccd.quality.message.back": "返回",
  "ccd.quality.message.searchWorkno": "请输入要搜索的座席工号",
  "ccd.quality.message.interceptErr": "被质检座席未在通话，不可拦截",
  "ccd.ccaction.message.retCode": "错误！在示忙的时候取消工作。错误编码:",
  "ccd.ccaction.message.retMessage": "。错误信息：",
  "ccd.ccaction.message.errorRetCode": "错误!错误编码:",
  "ccd.ccaction.message.retMessEnd": "。",
  "ccd.ccaction.message.statusError": "不存在呼叫，当前状态不允许静音",
  "ccd.ccaction.message.abilityError": "当前媒体功能不允许静音用户",
  "ccd.ccaction.message.unHold": "不存在呼叫，当前状态不允许保持",
  "ccd.ccaction.message.holdError": "当前呼叫状态不允许保持",
  "ccd.ccaction.message.confJoinErr": "没有保持的会话，不允许三方会话",
  "ccd.ccaction.message.confJoinFail": "座席未签入，三方会话失败",
  "ccd.ccaction.message.recording": "空闲状态才能放音，请调整座席状态",
  "ccd.ccaction.message.recordFail": "座席未签入，放音失败",
  "ccd.ccaction.message.repeateBusy": "座席已示忙，请勿重复示忙",
  "ccd.ccaction.message.repeateRest": "座席已经休息，请勿强制示忙",
  "ccd.ccaction.message.isForceBusy": "座席已经被示忙，请勿重复示忙",
  "ccd.ccaction.message.inputWorkNo": "座席工号为空，请输入座席工号",
  "ccd.ccaction.message.inputIvrName": "IVR流程名为空. 请输入IVR流程名称",
  "ccd.ccaction.message.logoutfailed": "登出失败，请检查座席状态",
  "ccd.ccaction.message.error": "错误",
  "ccd.ccaction.message.resignin": "座席密码失效，请重新签入",
  "ccd.ccaction.message.rest": "休息",
  "ccd.ccaction.message.seconds": "秒",
  "ccd.ccaction.message.minutes": "分钟",
  "ccd.ccaction.message.hour": "小时",
  "ccd.ccaction.message.search.restCauseDesc": "请输入休息原因描述",
  "ccd.callmessage.workbench": "音视频工作台",
  "ccd.callmessage.chatOnlineWorkbench": "在线交谈工作台",
  "ccd.callmessage.whiteNumberExist": "白名单号码中不存在此号码",
  "ccd.agentstate.notTalking": "选择的座席不在通话态",
  "ccd.outgoing.out.workbench": "外呼工作台",
  "ccd.agent.videomode.triangular": "三方等大",
  "ccd.agent.videomode.maximizeUser": "用户最大化",
  "ccd.agent.videomode.maximizeAgent": "座席最大化",
  "ccd.agent.videomode.maximizeThreeparty": "第三方最大化",
  "ccd.agent.mediaswitch.audio": "音频通话",
  "ccd.agent.mediaswitch.video": "视频通话",
  "ccd.agent.mediaswitch.switchaudiofailed": "当前已在音频通话中",
  "ccd.agent.mediaswitch.switchvideofailed": "当前已在视频通话中",
  "ccd.agent.mediaswitch.unsupportedagenttype": "当前座席不支持视频通话",
  "ccd.agent.void.call": "语音外呼",
  "ccd.agent.chat.mutimedia.call": "多媒体外呼",
  "ccd.agent.chat.start": "发起会话",
  "ccd.agent.chat.channle.choose": "渠道选择",
  "ccd.login.message.logoutReason.sessionInvalid": "当前会话已失效，请重新登录",
  "ccd.ccaction.message.registerPhone": "请注册软电话端",
  "ccd.ccaction.message.loginagent": "请签入座席",
  "ccd.contact.message.timeerror": "起始时间不能大于终止时间",
  "ccd.contact.message.timerangeerror": "时间范围不能超过{0}天",
  "ccd.contact.message.timeisempty": "时间区间不能为空",
  "ccd.ccaction.message.registerPhoneFailed": "Webrtc注册失败，当前情况下，内部求助等功能不可用",
  "ccd.agent.message.selectTeams": "请选择teams帐号",
  "ccd.skill.selectskill": "至少选择一个技能队列",
  "ccd.agent.message.qc.restskill": "质检员不接听来话不支持重置技能队列",
  "ccd.quality.message.qualityAgentTitle": "座席监控",
  "ccd.agentMonitor.forceRelease.noAudio": "无音视频呼叫可被释放",
  "ccd.agentMonitor.intercept.noAudio": "无音视频呼叫可被拦截",
  "ccd.transfer.label.transferNumber": "是否为自助服务流程",
  "ccd.agent.forcesignout.confirm": "签出将影响座席正在处理的接续业务，确认强制签出吗？",
  "ccd.ccagent.contract.preview": "合同预览",
  "ccd.transfer.placeholder.agentName": "请输入座席名称",
  "ccd.label.search": "搜索",
  "ccd.label.org.all": "所有可访问组织",
  "ccd.title.call.three": "三方通话",
  'ccd.agent.message.calloutagent.restskill': '外呼座席不接听来话，不支持重置技能队列',
  "ccd.agent.message.videoLimit": "视频座席资源不够，座席签入视频媒体服务器失败",
  "ccd.agent.message.withoutskill": "技能队列缺失",
  "ccdesktop.login.wertc.failed": "webrtc软电话注册失败",
  "ccd.agent.label.skillGroupOrSkill": "技能组/技能队列",
  "ccd.ccaction.message.nosupport": "网页端不支持该操作，请使用 Open Eye 静音",
  "ccd.ccbar.before.signin.tips":"座席使用接续功能前请先单击上方签入",
  "ccd.ccbar.calling.number":"来电号码",
  "ccd.ccbar.mediaSwitch.failed":"媒体切换失败",
  "ccd.ccbar.mediaSwitch.notsupport":"MCU视频模式在内部求助，内部呼叫的场景不允许音视频切换",
  "ccd.webrtc.message.bad":"恶劣",
  "ccd.webrtc.message.poor":"较差",
  "ccd.webrtc.message.good":"良好",
  "ccd.webrtc.message.recive":"接收",
  "ccd.webrtc.message.Packetloss":"丢包",
  "ccd.webrtc.message.delay":"时延",
  "ccd.webrtc.message.jitter":"抖动",
  "ccd.webrtc.message.send":"发送",
  "ccd.webrtc.message.signal":"信号",
  'ccd.webrtc.message.medium':'良',
  'ccd.webrtc.login.success':'软电话登录成功',
  'ccd.webrtc.login.fail':'软电话登录失败',
  'ccd.webrtc.login.fail.noPwd':'当前无初始密码，请重置软电话密码后再重试',
  'ccd.webrtc.login.fail.pop.tips':'软电话自动登录失败，您将无法使用语音功能，是否重新登录软电话?',
  'ccd.webrtc.login.try':'重试',
  'ccd.webrtc.login.ignore':'忽略',
  'ccd.webrtc.login.fail.top.tips':'软电话登录异常，无法使用语音等相关功能',
  "ccd.ccbar.webrtc.network.signal.pool":"当前网络信号差，可能影响通话",
  "ccd.agentMonitor.label.selectAgentOne": "请结束正在处理的座席，然后选择其他座席操作",
  "ccd.agentMonitor.label.switchwhisper": "预质检状态下，被监听或插入的座席不支持点击耳语按钮",
  'ccd.agent.webrtc.register.tip':'是否注册软电话?',
  "ccd.consult.message.two.inputExNumber": "请输入要两方咨询的号码",
  "ccd.consult.message.three.inputExNumber": "请输入要三方咨询的号码",
  "ccd.ccbar.mediaSwitch.sfunotsupport":"当前通话不支持音视频切换",
  "ccd.agent.message.continuously.online":"签入后将保持在线服务，工作结束后请及时签出",
  "ccd.agent.vrpserviceaddress.isempty" : "VRP服务器地址为空，请联系系统管理人员配置",
  "ccd.agent.initializeagentex.success" : "座席注册VRC成功，您的桌面将处于监控状态，您的通话过程会被录屏，请注意保持良好的工作状态",
  "ccd.agent.initializeagentex.passworderror" : "座席工号或者密码错误，注册VRC失败",
  "ccd.agent.startMonitorEx.uninitialize" : "vrc连接已失效，请重新签入当前座席",
  "ccd.agent.startMonitorEx.qcnotexit" : "QC对象不存在，请确认话务员是否连接VRC正常",
  "ccd.agent.startMonitorEx.qctimelimitexceed" : "话务员连接vrc失效，请话务员重新签入",
  "ccd.agent.startMonitorEx.qcunsigned": "话务员未签入，请确认话务员是否已签入或需要重新签入",
  "ccd.agent.startMonitorEx.othererror": "连接服务器异常，请联系维护人员或者稍后重试",
  "ccd.agent.initializeagentex.alreadyregistered" : "座席已经注册VRC",
  "ccd.agent.vrpexe.closed" : "VRC软件处于关闭中"
}