import i18n from '@/lang/index'
import cookieUtils from '@/utils/cookie';

const $t = i18n.global.t

const operationWidth = {
    'zh_CN': '200px',
    'en_US': '305px',
    'fr_FR': '305px',
    'es_ES': '370px',
    'pt_BR': '370px',
    'th_TH': '305px',
}

export const CALL_FLAG_TYPE = {
    // 待执行呼叫
    WAITEXECUTED: '0',
    // 呼叫执行中
    PROGRESS: '1',
    // 呼叫完成
    COMPLETED: '2'
};

export const CALL_FLAG_ENUM = {
    '0': $t('isales.task.field.callFlagExecuted'),
    '1': $t('isales.task.field.callFlagProgress'),
    '2': $t('isales.task.field.callFlagCompletion'),
};

export const EXPORT_RETURN_CODE = {
    SUCCESS: '0',
    REDIS_CHECK_FAIL: "1",
    EXPORT_DATA_FAIL: "2",
    FILE_SUFFIX_IS_NULL: "3",
    EXPORT_NOPREMISSION: "4"
};

export const EXPORT_SERV_TYPE = {
    CALL_RESULT: '1',
    CALL_RECORD: "2",
    AGENT_STATISTIC: "3"
};

export const CALL_RESULT_MAP = {
    '0000': $t('isales.task.field.callSuccess'),
    '0900': $t('isales.task.field.callSuccess'),
    '0101': $t('isales.task.field.callFail'),
    '-1': $t('isales.task.field.callFail'),
    '0601': $t('isales.task.title.callBack'),
};

export const CALL_RESULT_ENUM = [
    {
        "value": "0000,0900",
        "label": $t('isales.taskresult.field.success')
    },
    {
        "value": "0101",
        "label": $t('isales.task.field.callFail')
    },
    {
        "value": "0601",
        "label": $t('isales.task.title.callBack')
    }
];

// 手动外呼列表
export const MANUAL_TASK_COLUMNS = [
    {
        label: $t('isales.management.message.sessionId'),
        prop: 'sessionId',
        'min-width': '200px',
        'show-overflow-tooltip': true,
    },
    {
        label: $t('isales.taskresult.title.calledNo'),
        prop: 'calledNo',
        'min-width': '150px',
        'show-overflow-tooltip': true,
    },
    {
        label: $t('isales.taskresult.title.beginTime'),
        prop: 'beginTime',
        slot: 'beginTime',
        'min-width': '200px',
        'show-overflow-tooltip': true,
    },
    {
        label: $t('isales.taskresult.title.endTime'),
        prop: 'endTime',
        slot: 'endTime',
        'min-width': '200px',
        'show-overflow-tooltip': true,
    },
    {
        label: $t('isales.taskresult.title.userAccount'),
        prop: 'userAccount',
        slot: 'userAccount',
        'min-width': '150px',
        'show-overflow-tooltip': true,
    },
    {
        label: $t('isales.taskresult.title.result'),
        prop: 'result',
        slot: 'result',
        'min-width': '150px',
        'show-overflow-tooltip': true,
    },
    {
        label: $t('isales.taskresult.title.failCode'),
        prop: 'failCode',
        slot: 'failCode',
        'min-width': '200px',
        'show-overflow-tooltip': true,
    },
    {
        label: $t('isales.management.title.busiresult'),
        prop: 'busiResult',
        slot: 'busiResult',
        'min-width': '200px',
        'show-overflow-tooltip': true,
    },
    {
        label: $t('isales.taskresult.title.busisubstatus'),
        prop: 'busiSubStatus',
        slot: 'busiSubStatus',
        'min-width': '200px',
        'show-overflow-tooltip': true,
    },
    {
        label: $t('isales.taskresult.title.busidesc'),
        prop: 'busiDesc',
        slot: 'busiDesc',
        'min-width': '200px',
        'show-overflow-tooltip': true,
    },
    {
        label: $t('isales.common.title.operation'),
        slot: 'action',
        'min-width': operationWidth[cookieUtils.getCookie('u-locale')],
        'show-overflow-tooltip': true,
    fixed: 'right',
    }
];

// 系统自动外呼
export const SYSTEM_TASK_COLUMNS = [
    {
        label: $t('isales.management.message.sessionId'),
        prop: 'sessionId',
        'min-width': '200px',
        'show-overflow-tooltip': true,
    },
    {
        label: $t('isales.taskresult.title.calledNo'),
        prop: 'calledNo',
        'min-width': '150px',
        'show-overflow-tooltip': true,
    },
    {
        label: $t('isales.taskresult.title.beginTime'),
        prop: 'beginTime',
        slot: 'beginTime',
        'min-width': '200px',
        'show-overflow-tooltip': true,
    },
    {
        label: $t('isales.taskresult.title.endTime'),
        prop: 'endTime',
        slot: 'endTime',
        'min-width': '200px',
        'show-overflow-tooltip': true,
    },
    {
        label: $t('isales.taskresult.title.retryTime'),
        prop: 'retryTime',
        'min-width': '100px',
        'show-overflow-tooltip': true,
    },
    {
        label: $t('isales.taskresult.title.result'),
        prop: 'result',
        slot: 'result',
        'min-width': '150px',
        'show-overflow-tooltip': true,
    },
    {
        label: $t('isales.taskresult.title.failCode'),
        prop: 'failCode',
        slot: 'failCode',
        'min-width': '200px',
        'show-overflow-tooltip': true,
    },
    {
        label: $t('isales.management.title.busiresult'),
        prop: 'busiResult',
        slot: 'busiResult',
        'min-width': '200px',
        'show-overflow-tooltip': true,
    },
    {
        label: $t('isales.taskresult.title.busisubstatus'),
        prop: 'busiSubStatus',
        slot: 'busiSubStatus',
        'min-width': '200px',
        'show-overflow-tooltip': true,
    },
    {
        label: $t('isales.taskresult.title.busidesc'),
        prop: 'busiDesc',
        slot: 'busiDesc',
        'min-width': '200px',
        'show-overflow-tooltip': true,
    },
    {
        label: $t('isales.common.title.operation'),
        slot: 'action',
        'min-width': operationWidth[cookieUtils.getCookie('u-locale')],
        'show-overflow-tooltip': true,
    fixed: 'right',
    }
];

// 智能外呼
export const INTELLIGENT_TASK_COLUMNS = [
    {
        label: $t('isales.management.message.sessionId'),
        prop: 'sessionId',
        'min-width': '200px',
        'show-overflow-tooltip': true,
    },
    {
        label: $t('isales.taskresult.title.calledNo'),
        prop: 'calledNo',
        'min-width': '150px',
        'show-overflow-tooltip': true,
    },
    {
        label: $t('isales.taskresult.title.beginTime'),
        prop: 'beginTime',
        slot: 'beginTime',
        'min-width': '200px',
        'show-overflow-tooltip': true,
    },
    {
        label: $t('isales.taskresult.title.endTime'),
        prop: 'endTime',
        slot: 'endTime',
        'min-width': '200px',
        'show-overflow-tooltip': true,
    },
    {
        label: $t('isales.taskresult.title.retryTime'),
        prop: 'retryTime',
        'min-width': '100px',
        'show-overflow-tooltip': true,
    },
    {
        label: $t('isales.taskresult.title.result'),
        prop: 'result',
        slot: 'result',
        'min-width': '200px',
        'show-overflow-tooltip': true,
    },
    {
        label: $t('isales.taskresult.title.failCode'),
        prop: 'failCode',
        slot: 'failCode',
        'min-width': '200px',
        'show-overflow-tooltip': true,
    },
    {
        label: $t('isales.common.title.operation'),
        slot: 'action',
        'min-width': operationWidth[cookieUtils.getCookie('u-locale')],
        'show-overflow-tooltip': true,
    fixed: 'right',
    }
];

// 人工、自动任务详情表头
export const MANUAL_SYSTEM_DETAIL_COLUMNS = [
    {
        label: $t('isales.taskresult.title.calledNo'),
        prop: 'calledNo',
        'min-width': '150px'
    },
    {
        label: $t('isales.taskresult.title.callFlag'),
        prop: 'callFlag',
        slot: 'callFlag',
        'min-width': '120px'
    },
    {
        label: $t('isales.taskresult.title.beginTime'),
        prop: 'beginTime',
        slot: 'beginTime',
        'min-width': '170px'
    },
    {
        label: $t('isales.taskresult.title.endTime'),
        prop: 'endTime',
        slot: 'endTime',
        'min-width': '170px'
    },
    {
        label: $t('isales.taskresult.title.result'),
        prop: 'result',
        slot: 'result',
        'min-width': '150px'
    },
    {
        label: $t('isales.management.title.busiresult'),
        prop: 'busiResult',
        slot: 'busiResult',
        'min-width': '120px'
    },
    {
        label: $t('isales.taskresult.title.busisubstatus'),
        prop: 'busiSubStatus',
        slot: 'busiSubStatus',
        'min-width': '145px'
    },
    {
        label: $t('isales.taskresult.title.busidesc'),
        prop: 'busiDesc',
        slot: 'busiDesc',
        'min-width': '180px'
    },
    {
        label: $t('isales.taskresult.title.failCode'),
        prop: 'failCode',
        slot: 'failCode',
        'min-width': '290px'
    },
    {
        label: $t('isales.common.title.operation'),
        slot: 'action',
        fixed: 'right',
        'min-width':'416px'
    }
];

// 智能外呼详情表头
export const INTELLIGENT_DETAIL_COLUMNS = [
    {
        label: $t('isales.taskresult.title.calledNo'),
        prop: 'calledNo',
        'min-width': '150px'
    },
    {
        label: $t('isales.taskresult.title.callFlag'),
        prop: 'callFlag',
        slot: 'callFlag',
        'min-width': '120px'
    },
    {
        label: $t('isales.taskresult.title.beginTime'),
        prop: 'beginTime',
        slot: 'beginTime',
        'min-width': '170px'
    },
    {
        label: $t('isales.taskresult.title.endTime'),
        prop: 'endTime',
        slot: 'endTime',
        'min-width': '170px'
    },
    {
        label: $t('isales.taskresult.title.result'),
        prop: 'result',
        slot: 'result',
        'min-width': '150px'
    },
    {
        label: $t('isales.taskresult.title.failCode'),
        prop: 'failCode',
        slot: 'failCode',
        'min-width': '290px'
    },
    {
        label: $t('isales.common.title.operation'),
        slot: 'action',
        fixed: 'right',
        'min-width':'300px'
    }
];

// 业务结果日志表头
export const BUSIRESULTLOG_TABLE_COLUMNS = [
    {
        label: $t('isales.taskresult.title.userName'),
        prop: 'userName',
    },
    {
        label: $t('isales.management.title.busiresult'),
        prop: 'busiResult',
    },
    {
        label: $t('isales.taskresult.title.busisubstatus'),
        prop: 'busiSubStatus',
    },
    {
        label: $t('isales.taskresult.title.busidesc'),
        prop: 'busiDesc',
    },
    {
        label: $t('isales.taskresult.title.operationTime'),
        prop: 'updateTime',
        slot: 'updateTime'
    }
];

// 对象深拷贝
export let deepClone = function (obj) {
    let _tmp = JSON.stringify(obj);
    let result = JSON.parse(_tmp);
    return result;
};
