<template>
  <template v-if="isCardType">
    <div class="aicc-full-screen card-type">
      <div class="aicc-full-screen-body" :style="initStyle">
        <div class="aicc-full-screen-header">
          <slot name="header"></slot>
        </div>
        <div class="aicc-full-screen-main" ref="screenMain">
          <slot name="main"></slot>
        </div>
        <div
          :class="
            existConfig.includes('footerAction')
              ? 'aicc-full-screen-footer-action'
              : 'aicc-full-screen-footer'
          "
        >
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="aicc-full-screen" :style="initStyle">
      <div class="aicc-full-screen-header">
        <slot name="header"></slot>
      </div>
      <div class="aicc-full-screen-main" ref="screenMain">
        <slot name="main"></slot>
      </div>
      <div
        :class="
          existConfig.includes('footerAction')
            ? 'aicc-full-screen-footer-action'
            : 'aicc-full-screen-footer'
        "
      >
        <slot name="footer"></slot>
      </div>
    </div>
  </template>
</template>

<script>
export default {
  name: 'aicc-full-screen',
  props: {
    targetSelector: {
      type: String,
      default() {
        return '.el-table'
      }
    },
    existConfig: {
      type: Array,
      default() {
        // backHeader 有返回头
        // tablePagination 使用aicc-table内的分页
        // footerAction 有固定在底部的按钮操作栏
        // checkedInfo aicc-table内展示顶部已选中信息
        return []
      }
    },
    // 是否为卡片形态，上下左右均有一个20px的margin，并带圆角
    isCardType: {
      type: Boolean,
      default() {
        return false
      }
    },
    // 指定卡片内边距需要设置多少px
    cardPadding: {
      type: Number,
      default: 0
    },
    extendHeight: {
        type: Number,
        default: 0
    }
  },
  data() {
    return {
      targetDom: undefined,
      observer: undefined
    }
  },
  computed: {
    initStyle() {
      // 48-头部title；40-tab；72-分页； 56-返回头  32-选中信息
      let subtractHeight = 48 + 40 + 3
      if(this.isApiBuilderDeployment){
        subtractHeight -= 40
      }
      let styleText = ''
      if (this.existConfig.includes('backHeader')) {
        subtractHeight += 59
      }
      if (this.isCardType) {
        // 卡片模式下，给上下左右各16px的margin，高度需多减去32
        subtractHeight += 32
      }
      if (this.extendHeight) {
        subtractHeight += this.extendHeight
      }
      if (this.isCardType && this.cardPadding) {
        styleText = `padding: ${this.cardPadding}px;`
        if (this.existConfig.includes('tablePagination')) {
          styleText += `padding-bottom: 0;`
        }
      }
      return `height: calc(100vh - ${subtractHeight}px);${styleText}`;
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      if (this.targetSelector) {
        if (this.targetSelector[0] === '.') {
          this.targetDom = $(this.$el).find(this.targetSelector)[0]
        } else if (this.targetSelector[0] === '#') {
          this.targetDom = $(this.$el).find(this.targetSelector)[0]
        }
      }

      if (this.targetDom) {
        const screenMain = this.$refs.screenMain
        this.observer = new ResizeObserver(this.handleResize)
        this.observer.observe(screenMain, { box: 'border-box' })
      }
    },
    handleResize(val) {
      if (val[0] && val[0].contentRect && val[0].contentRect.height) {
        let height = val[0].contentRect.height
        if (this.existConfig.includes('tablePagination')) {
          height -= 72
        }
        if (this.existConfig.includes('checkedInfo')) {
          height -= 32
        }
        this.targetDom.style.cssText += `height: ${height}px`
      }
    },
    mainScrollTop() {
      this.$refs.screenMain.scrollTop = 0
    }
  }
}
</script>

<style lang="less" scoped>
.aicc-full-screen {
  display: flex;
  flex-direction: column;

  &.card-type {
    padding: 16px;
    height: unset;
  }

  &-body {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.1);
  }

  &-main {
    flex: 1;
    overflow-y: auto;
  }

  .aicc-full-screen-footer-action {
    display: flex;
    justify-content: flex-end;
    padding: 20px 30px;
    background: #ffffff;
    box-shadow: 0px -1px 3px 0px rgba(0, 0, 0, 0.1);
  }
}

body.RTL .aicc-full-screen .aicc-full-screen-footer-action {
  justify-content: flex-start;
}
</style>
