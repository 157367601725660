export default {
    "message.center.label.delete": "Löschen",
    "message.center.label.messageCenter": "Benachrichtigungszentrale",
    "message.center.label.modify": "Ändern",
    "message.center.label.senderName": "Absender",
    "message.center.label.sendTime": "Gesendet am",
    "message.center.label.bulletinType": "Bulletintyp",
    "message.center.label.expireTime": "Abgelaufen am",
    "message.center.message.selectMessages": "Wählen Sie eine Nachricht aus.",
    "message.center.message.willDeleteMessages": "Möchten Sie die ausgewählte Nachricht wirklich löschen?",
    "message.center.label.reply": "Antworten",
    "message.center.message.delete": "Löschen",
    "message.center.label.isRead": "Nachrichten als gelesen markieren",
    "message.center.label.refresh": "Aktualisieren",
    "message.center.button.query": "Abfrage",
    "message.center.label.search": "Suche",
    "message.center.label.allSelect": "Alle auswählen",
    "message.center.label.select": "Filtern",
    "message.center.label.newMessage": "Benachrichtigung senden",
    "message.center.label.receiverGroup": "Empfängergruppe",
    "message.center.label.receiver": "Empfänger",
    "message.center.label.download": "Herunterladen",
    "message.center.nocitiction.interal": "Internes Bulletin",
    "message.center.nocitiction.system": "Systemmitteilung",
    "message.center.nocitiction.outbox": "Ausgang",
    "message.center.nocitiction.email": "E-Mail an ",
    "message.center.nocitiction.message": "SMS",
    "message.center.nocitiction.ineermessage": "Interne Nachricht",
    "message.center.nocitiction.bulletin": "Bulletin",
    "message.center.nocitiction.inbox": "Posteingang",
    "message.center.nocitiction.callback.record": "Rückrufaufzeichnung",
    "message.center.nocitiction.sending.records": "Datensatz wird gesendet",
    "message.center.nocitiction.draftBox": "Entwurfskasten",
    "message.center.nocitiction.modifyInternalMessage": "Interne Nachricht ändern",
    "message.center.nocitiction.modifyBulletin": "Bulletin ändern",
    "message.center.nocitiction.sending.records.status": "Status",
    "message.center.nocitiction.sender": "Absender",
    "message.center.nocitiction.sending.records.smsType": "SMS-Nachrichtenmodus",
    "message.center.nocitiction.callback.status": "Rückrufstatus",
    "message.center.nocitiction.callback.attachment": "Anhang",
    "message.center.nocitiction.callback.receivetime": "Empfangszeit",
    "message.center.nocitiction.sending.records.sended": "Sofortiges Senden",
    "message.center.nocitiction.sending.records.unsend": "Geplantes Senden",
    "message.template.type.system": "Systembenachrichtigung",
    "message.template.type.internal": "Interne Benachrichtigung",
    "message.template.mediaType.email": "E-Mail-Adresse",
    "message.template.mediaType.internal": "Interne Nachricht",
    "message.center.label.deleteisread": "Alle gelesenen Nachrichten löschen ",
    "message.template.mediaType.shortmessage": "SMS",
    "message.template.message.type": "Benachrichtigungstyp",
    "message.template.message.mediaType": "Nachrichtensendemodus",
    "message.template.message.messageTemplateId": "Vorlagen-ID",
    "message.template.message.searchMessageTemplateName": "Geben Sie einen Vorlagennamen ein.",
    "message.template.message.messageTemplateName": "Vorlagenname",
    "message.template.message.createTime": "Erstellt um",
    "message.template.message.messageTemplateCreate": "Benachrichtigungsvorlage erstellen",
    "message.template.type.out": "Externe Benachrichtigung",
    "message.template.button.view": "Ansicht",
    "message.template.message.operate": "Betrieb",
    "message.template.button.delete": "Löschen",
    "message.template.message.basicInformation": "Grundlegende Informationen",
    "message.template.message.inputTemplateName": "Geben Sie einen Vorlagennamen ein.",
    "message.template.message.notificationContent": "Benachrichtigungsinhalt",
    "message.template.message.recipient": "Empfänger",
    "message.template.message.selectRecipient": "Wählen Sie einen Empfänger aus.",
    "message.template.message.inputRecipient": "Geben Sie einen Empfänger ein.",
    "message.template.message.inputTitle": "Geben Sie einen Titel ein.",
    "message.template.message.cancel": "Abbrechen",
    "message.template.message.generate": "Vorlage generieren",
    "message.template.message.ccOrBccRecipientError": "Die Nachricht wurde erfolgreich gesendet. Es existiert kein Cc- oder Bcc-Empfänger.",
    "message.template.message.recipientError": "Einige Empfänger existieren nicht. Wählen Sie einen anderen Empfänger aus",
    "message.template.message.agentRecipientError": "Einige Empfänger sind nicht an Agenten gebunden. Wählen Sie einen anderen Empfänger aus",
    "message.template.message.nameError": "Der Vorlagenname darf nicht leer sein oder mehr als 200 Zeichen umfassen.",
    "message.template.message.queryagentbebindedfailed": "Der an den Agenten gebundene Benutzer konnte nicht abgefragt werden",
    "message.template.message.titleError": "Der Vorlagentitel darf nicht länger als 200 Zeichen sein.",
    "message.template.message.createSuccess": "Die Benachrichtigungsvorlage wurde erfolgreich erstellt.",
    "message.center.button.clear": "Zurücksetzen",
    "message.template.message.agentbebinded": "Der ausgewählte Empfänger ist nicht an einen Agenten gebunden",
    "message.template.message.titleLengthError": "Der Titel darf nicht länger als 200 Zeichen sein und keine Sonderzeichen enthalten.",
    "message.template.message.typeError": "Falscher Benachrichtigungstyp.",
    "message.template.message.nameLengthError": "Der Name darf nicht leer sein und nicht länger als 200 Zeichen sein oder Sonderzeichen enthalten.",
    "message.template.button.edit": "Bearbeiten",
    "message.template.message.createTemplateFail": "Die Benachrichtigungsvorlage konnte nicht erstellt werden.",
    "message.template.message.contentLengthError": "Der Inhalt darf nicht leer sein und die Länge nicht überschreiten.",
    "message.template.message.descriptionLengthError": "Die Beschreibung überschreitet den Grenzwert.",
    "message.template.message.nameDuplicationError": "Doppelter Vorlagenname.",
    "message.template.message.contentError": "Der Inhalt darf nicht leer sein.",
    "message.template.message.frequently": "Die Anzahl der gesendeten Nachrichten überschreitet den täglichen Schwellenwert.",
    "message.template.message.sendEmailError": "Die E-Mail-Adresse des Absenders ist leer.",
    "message.template.message.messageTemplateModify": "Vorlage ändern",
    "message.template.message.mediaTypeError": "Falscher Sendemodus.",
    "message.template.message.phoneError": "Die Telefonnummer existiert nicht.",
    "message.template.message.deleteSuccess": "Die Benachrichtigungsvorlage wurde erfolgreich gelöscht.",
    "message.template.message.modifySuccess": "Die Benachrichtigungsvorlage wurde erfolgreich geändert.",
    "message.template.message.modifyTemplateFail": "Die Benachrichtigungsvorlage konnte nicht geändert werden.",
    "message.template.message.save": "Speichern",
    "message.template.message.deleteTemplate": "Löschen einer Benachrichtigungsvorlage",
    "message.template.message.willDeleteTemplate": "Das Löschen der Vorlage kann dazu führen, dass die referenzierte Funktion nicht mehr verfügbar ist. Möchten Sie wirklich fortfahren?",
    "message.template.message.noneTemplate": "Die Vorlage existiert nicht.",
    "ccnotification.notifition.sendmessage.sysmessage": "Systembenachrichtigung",
    "ccnotification.notifition.sendmessage.internal": "Interne Nachricht",
    "ccnotification.notifition.sendmessage.internalmessage": "Interne Benachrichtigung",
    "ccnotification.notifition.sendmessage.bulletin": "Bulletin",
    "ccnotification.notifition.sendmessage.bulletinType": "Bulletintyp",
    "ccnotification.notifition.sendmessage.enterBulletinType": "Wählen Sie einen Bulletin-Typ aus.",
    "ccnotification.notifition.sendmessage.priority": "Priorität",
    "ccnotification.notifition.sendmessage.priorityGeneral": "Niedrig",
    "ccnotification.notifition.sendmessage.priorityUrgent": "Mittel",
    "ccnotification.notifition.sendmessage.priorityExtraUrgent": "Hoch",
    "ccnotification.notifition.sendmessage.expireTime": "Abgelaufen am",
    "ccnotification.notifition.sendmessage.priorityFault": "Kritisch",
    "ccnotification.notifition.sendmessage.inputBulletinType": "Geben Sie den Namen des Bulletin-Typs ein.",
    "ccnotification.notifition.sendmessage.bulletinTypeName": "Name des Bulletin-Typs",
    "ccnotification.notifition.sendmessage.recipient": "Empfänger",
    "ccnotification.notifition.sendmessage.invalidSect": "Ablaufzeitsegment",
    "ccnotification.notifition.sendmessage.send": "Senden",
    "ccnotification.notifition.sendmessage.enterrecipient": "Wählen Sie einen Empfänger aus.",
    "ccnotification.notifition.sendmessage.recipientGroup": "Empfängergruppe",
    "ccnotification.notifition.sendmessage.enterRecipientGroup": "Wählen Sie eine Empfängergruppe aus.",
    "ccnotification.notifition.sendmessage.selectGroupOrRecipient": "Wählen Sie entweder den Empfänger oder die Empfängergruppe aus.",
    "message.template.message.deleteTemplateFail": "Die Benachrichtigungsvorlage konnte nicht gelöscht werden.",
    "ccnotification.notifition.sendmessage.enterCCRecipient": "Wählen Sie einen Cc-Empfänger aus.",
    "ccnotification.notifition.sendmessage.bccRecipient": "Bcc",
    "ccnotification.notifition.sendmessage.enterBCCRecipient": "Wählen Sie einen Bcc-Empfänger aus.",
    "ccnotification.notifition.sendmessage.notifitiontitle": "Geben Sie einen Titel ein.",
    "ccnotification.notifition.sendmessage.selecttenant": "Wählen Sie einen Mieter.",
    "ccnotification.notifition.sendmessage.selectuser": "Wählen Sie einen Mitarbeiter aus.",
    "ccnotification.notifition.sendmessage.maxselectcount": "Es können maximal 100 Mitarbeiter ausgewählt werden.",
    "ccnotification.notifition.sendmessage.entertenantname": "Geben Sie den Namen des Mandantenbereichs ein.",
    "ccnotification.notifition.sendmessage.enteraccount": "Geben Sie ein Geschäftskonto ein.",
    "ccnotification.notifition.sendmessage.ccRecipient": "Cc",
    "ccnotification.notifition.sendmessage.selectall": "Alle auswählen",
    "ccnotification.notifition.sendmessage.enterusername": "Geben Sie einen Namen ein.",
    "ccnotification.notifition.sendmessage.cancelSelectall": "Alle abbrechen",
    "ccnotification.notifition.sendmessage.sendsuccess": "Die Nachricht wurde erfolgreich gesendet.",
    "ccnotification.notifition.sendmessage.savesuccess": "Die Nachricht wurde erfolgreich gespeichert.",
    "ccnotification.notifition.sendmessage.paramvalidatefailed": "Param-Validierung fehlgeschlagen.",
    "ccnotification.notifition.sendmessage.receiverPhoneIsNull": "Der Empfänger oder die Empfängernummer ist leer.",
    "ccnotification.notifition.sendmessage.sendTimeFailed": "Die Sendezeit liegt vor der aktuellen Zeit.",
    "ccnotification.notifition.sendmessage.sendfailed": "Senden fehlgeschlagen.",
    "ccnotification.notifition.sendmessage.sendexception": "Fehler beim Senden.",
    "ccnotification.notifition.sendmessage.messagetitle": "Titel",
    "ccnotification.notifition.sendmessage.selectmode": "Auswählen nach",
    "ccnotification.notifition.sendmessage.selectbyworkno": "Mitarbeiter-ID",
    "ccnotification.notifition.sendmessage.delaysend": "Geplante Benachrichtigung",
    "ccnotification.notifition.sendmessage.selectbyorg": "Organisationseinheit",
    "ccnotification.notifition.sendmessage.selectbyskill": "Skill-Warteschlange",
    "ccnotification.notifition.sendmessage.delaynotice": "Diese Benachrichtigung wird am gesendet",
    "ccnotification.notifition.sendmessage.senddate": "Absendedatum",
    "ccnotification.notifition.sendmessage.preview": "Vorschau von ",
    "ccnotification.notifition.sendmessage.sendtime": "Absendezeit",
    "ccnotification.notifition.sendmessage.sendtimebeforenow": "Die geplante Sendezeit muss 5 Minuten nach der aktuellen Zeit liegen.",
    "ccnotification.notifition.sendmessage.limitdate": "Wählen Sie ein Datum vor dem 19.01.2038.",
    "message.center.message.messageQueryResultIsEmpty": "Das Ergebnis der Nachrichtenabfrage ist leer.",
    "ccnotification.center.message.deleteAllRead": "Möchten Sie wirklich alle gelesenen internen Nachrichten löschen?",
    "message.template.message.recipientLengthError": "Die Anzahl der Empfänger überschreitet das Limit.",
    "ccnotification.center.message.toIsRead": "Möchten Sie die ausgewählten Nachrichten wirklich als gelesen markieren?",
    "ccnotification.notifition.receivermessage.all": "Alle",
    "ccnotification.notifition.receivermessage.isread": "Lesen Sie ",
    "ccnotification.notifition.receivermessage.receiverSuccess": "Empfangen",
    "ccnotification.notifition.receivermessage.unread": "Ungelesene ",
    "ccnotification.notifition.sendmessage.isSendall": "Gesendet",
    "ccnotification.notifition.sendmessage.isSendallSuccess": "Gesendet (erfolgreich)",
    "ccnotification.notifition.sendmessage.isSendallFail": "Gesendet (Fehler)",
    "ccnotification.notifition.sendmessage.unSend": "Zu senden",
    "ccnotification.notifition.sendmessage.referenceTemplate": "Referenzvorlage",
    "ccnotification.notifition.sendmessage.templateselect": "Vorlage auswählen",
    "ccnotification.notifition.sendmessage.templateselected": "Dieser Hinweis bezieht sich auf die",
    "ccnotification.notifition.sendmessage.smsconfignull": "Die SMS-Nachrichtenkonfiguration ist leer. Wählen Sie Einstellungen > SMS-Konfiguration aus, um die SMS-Informationen zu konfigurieren.",
    "ccnotification.notifition.variable.searchVariableName": "Geben Sie einen Variablennamen ein.",
    "ccnotification.notifition.variable.searchTempdateName": "Geben Sie einen Vorlagennamen ein.",
    "ccnotification.notifition.variable.variableType": "Wählen Sie einen Variablentyp aus.",
    "ccnotification.notification.variable.variablevalue": "Variabler Wert",
    "ccnotification.notification.variable.templatevariable": "Vorlagenvariable",
    "ccnotification.notification.variable.publicvariable": "Öffentliche Variable",
    "ccnotification.notification.variable.template": "Vorlage",
    "ccnotification.notification.variable.templatetype": "Vorlagentyp",
    "ccnotification.notification.variable.templateselect": "Wählen Sie eine Vorlage aus.",
    "ccnotification.notifition.variable.templatename": "Vorlagenname",
    "ccnotification.notification.variable.nameexist": "Der Variablenname ist bereits vorhanden.",
    "ccnotification.notification.variable.editfailed": "Änderung fehlgeschlagen.",
    "ccnotification.notifition.variable.variablename": "Name der Variablen",
    "ccnotification.notifition.variable.createvariable": "Erstellen",
    "ccnotification.notifition.variable.selectvariable": "Variable auswählen",
    "ccnotification.notifition.variable.insertvariable": "Variable einfügen",
    "ccnotification.notifition.variable.syntax.error": "Syntaxfehler!",
    "ccnotification.notifition.variable.not.exist": "existiert nicht!",
    "ccnotification.notifition.variable.been.used": "wird von einer Vorlage referenziert und kann nicht gelöscht werden.",
    "ccnotification.notifition.variable.variable": "Variable",
    "ccnotification.notifition.variable.close.syntax.error1": "Der Vorlageninhalt enthält Syntaxfehler in der Nähe von",
    "ccnotification.notifition.variable.namerule": "Der Variablenname darf nur die Buchstaben _ und enthalten.",
    "ccnotification.notifition.variable.close.syntax.error2": "Überprüfen Sie, ob ${} geschlossen ist.",
    "ccnotification.notifition.template.label.templatetype": "Vorlagentyp",
    "ccnotification.notifition.template.label.templateid": "Vorlagen-ID",
    "ccnotification.notifition.template.label.hwtemplateid": "HUAWEI CLOUD-Vorlagen-ID",
    "ccnotification.notifition.template.label.signatureName": "Name der Unterschrift",
    "ccnotification.notifition.template.label.varnumber": "Anzahl der Variablen",
    "ccnotification.notifition.template.label.mspcontent": "SMS-Vorlageninhalt",
    "ccnotification.notifition.template.title.setting": "Variable festlegen",
    "ccnotification.notifition.template.button.addvar": "Erstellen",
    "ccnotification.notifition.template.label.index": "Nein.",
    "ccnotification.notifition.template.label.type": "Typ",
    "ccnotification.notifition.template.label.des": "Beschreibung",
    "ccnotification.notifition.template.label.value": "Standardwert",
    "ccnotification.notifition.template.label.operation": "Betrieb",
    "ccnotification.notifition.template.button.up": "Auf",
    "ccnotification.notifition.template.button.down": "Unten",
    "ccnotification.notifition.template.message.money": "- Der Wert kann 1 bis 20 Ziffern enthalten. Es können nur Ziffern und Dezimalstellen übertragen werden, die den Betrag korrekt ausdrücken können.",
    "ccnotification.notifition.template.message.dateTime": "- Datum: JJJJ-MM-TT - Uhrzeit: HH:mm:ss ",
    "ccnotification.notifition.template.message.text": "- Der Wert ist eine Zeichenfolge mit 1 bis 20 Zeichen und darf nicht 000 enthalten",
    "ccnotification.notifition.template.message.charDigit": "- Der Wert ist eine Zeichenfolge mit 1 bis 20 Ziffern und Buchstaben.",
    "ccnotification.notifition.template.message.phone": "- Der Wert ist eine Zeichenfolge mit 1 bis 15 Ziffern.",
    "ccnotification.notifition.template.message.error": "Ungültige Eingabewerte.",
    "ccnotification.notifition.template.placeholder.hwtemplateid": "Geben Sie eine HUAWEI CLOUD-Vorlagen-ID ein",
    "ccnotification.notifition.template.placeholder.signatureName": "Geben Sie einen Signaturnamen ein",
    "ccnotification.notifition.template.placeholder.varnumber": "Geben Sie die Anzahl der Variablen ein",
    "ccnotification.notifition.template.placeholder.des": "Beschreiben Sie die Variable",
    "ccnotification.notifition.template.placeholder.var": "Geben Sie Werte ein, die den Anforderungen entsprechen.",
    "ccnotification.notifition.template.message.hwsms": "SMS-Nachrichten von HUAWEI CLOUD",
    "ccnotification.notifition.template.message.mspsms": "SMS-Gateway",
    "ccnotification.smshuawei.message.fail": "Fehlgeschlagen",
    "ccnotification.smshuawei.message.updatesuccess": "Der SMS-Kanal wurde erfolgreich geändert.",
    "ccnotification.smshuawei.message.updatefail": "Der SMS-Kanal konnte nicht geändert werden.",
    "ccnotification.smshuawei.message.deletesuccess": "Der SMS-Kanal wurde erfolgreich gelöscht.",
    "ccnotification.smshuawei.message.deletefail": "Der SMS-Kanal konnte nicht gelöscht werden.",
    "ccnotification.smshuawei.message.paramisempty": "Leerer Parameter.",
    "ccnotification.smshuawei.message.specialCharacters": "Sonderzeichen sind nicht zulässig.",
    "ccnotification.smshuawei.message.invalidappadress": "Ungültige App-Zugriffsadresse.",
    "ccnotification.smshuawei.label.appKey": "App-Schlüssel",
    "ccnotification.smshuawei.label.certTip.withProxy": "Bitte laden Sie das Proxy-Server-Zertifikat hoch",
    "ccnotification.smshuawei.label.certTip.withoutProxy": "Laden Sie das HUAWEI CLOUD-Dienstzertifikat hoch",
    "ccnotification.smshuawei.label.appSecret": "App-Geheimnis",
    "ccnotification.smshuawei.label.appAdress": "App-Zugriffsadresse",
    "ccnotification.smshuawei.label.signatureChannelId": "Signaturkanal-ID",
    "ccnotification.notifition.template.label.signatureChannelId": "Geben Sie eine Signature-Channel-ID ein",
    "ccnotification.smshuawei.label.signatureChannelId.length": "Die Länge der SignaturChannelId überschreitet den Wertebereich.",
    "ccnotification.smshuawei.label.config.explain": "Die Konfigurationsdaten müssen mit denen der HUAWEI CLOUD SMS übereinstimmen.",
    "ccnotification.smshuawei.label.certVerifyEnabled": "Ob die Zertifikatverifizierung aktiviert werden soll",
    "ccnotification.smshuawei.message.securityInfo": "Wenn die Zertifikatsverifizierung deaktiviert ist, besteht die Gefahr des Identitätsspoofings durch Peers. Es wird empfohlen, die Zertifikatverifizierung zu aktivieren.",
    "ccnotification.smstemplate.message.var": "Der Typ, die Anzahl, die Beschreibung oder der Standardwert der Variablen entspricht nicht den Anforderungen.",
    "ccnotification.smstemplate.message.fail": "Die HUAWEI CLOUD-Vorlage konnte nicht erstellt werden.",
    "ccnotification.smstemplate.message.smstemplate": "Die Informationen zur HUAWEI CLOUD-Vorlage sind leer.",
    "ccnotification.smstemplate.message.smstemplateid": "Die HUAWEI CLOUD-Vorlagen-ID darf nicht leer oder zu lang sein oder nicht den Spezifikationen entsprechen.",
    "ccnotification.smstemplate.message.signatureName": "Der Signaturname darf nicht leer oder zu lang sein oder nicht den Spezifikationen entsprechen.",
    "ccnotification.smstemplate.message.receiverPhone": "Empfänger können nicht dupliziert werden.",
    "ccnotification.smstemplate.message.number": "Die maximale Zahl ist 10.",
    "ccnotification.smstemplate.message.var.number": "Die Anzahl der Eingabevariablen unterscheidet sich von der Anzahl der hinzugefügten Variablen.",
    "ccnotification.common.spec.message": "Sonderzeichen können nicht eingegeben werden.",
    "ccnotification.center.message.var.type": "Typ der Variablen:",
    "ccnotification.center.message.var.value": "Variabler Wert:",
    "ccnotification.center.message.var.des": "Beschreibung der Variablen:",
    "ccnotification.template.message.delete": "Auf die Vorlage wurde verwiesen und sie kann nicht gelöscht werden.",
    "ccnotification.notifition.gatewaycontact.stop": "Verbindung unterbrochen",
    "ccnotification.notifition.gatewaycontact.success": "Verbindung erfolgreich",
    "ccnotification.notifition.gatewaycontact.failed": "Verbindungsfehler",
    "ccnotification.gatewayconfig.label.gatewayname": "Gateway-Name",
    "ccnotification.gatewayconfig.label.inputgatewayname": "Geben Sie einen Gateway-Namen ein",
    "ccnotification.gatewayconfig.label.lastmodifytime": "Letzte Änderung am",
    "ccnotification.gatewayconfig.label.configType": "Konfigurationstyp",
    "ccnotification.gatewayconfig.label.mailHost": "Host",
    "ccnotification.gatewayconfig.label.protocol": "Protokolltyp",
    "ccnotification.gatewayconfig.label.userid": "Benutzer-ID",
    "ccnotification.gatewayconfig.label.mailPassword": "Benutzerkennwort",
    "ccnotification.gatewayconfig.label.defaultemailaddr": "Standard-E-Mail-Adresse",
    "ccnotification.gatewayconfig.label.defaultsendname": "Standardbenutzername",
    "ccnotification.gatewayconfig.label.auth": "Authentifizierungsflag",
    "ccnotification.gatewayconfig.label.code": "Codierungsmodus",
    "ccnotification.gatewayconfig.label.SSLEncryptionMode": "Verschlüsselungsmodus",
    "ccnotification.gatewayconfig.label.mailPort": "Hafen",
    "ccnotification.gatewayconfig.label.certFile": "Zertifikatsdatei",
    "ccnotification.gatewayconfig.label.selectCert": "Zertifikat auswählen",
    "ccnotification.gatewayconfig.label.inputCertCode": "Geben Sie den Zertifikatscode ein.",
    "ccnotification.gatewayconfig.label.certCode": "Zertifikatscode",
    "ccnotification.gatewayconfig.label.certScene": "Anwendungsszenario",
    "ccnotification.gatewayconfig.label.certType": "Zertifikatstyp",
    "ccnotification.common.warning.message": "Wählen Sie einen Datensatz aus.",
    "ccnotification.gatewayconfig.label.serviceType": "Diensttyp",
    "ccnotification.gatewayconfig.label.sourceAddrTon": "Quelladresscodetyp",
    "ccnotification.gatewayconfig.label.sourceAddrNpi": "Quelladresscodeschema",
    "ccnotification.gatewayconfig.label.destAddrTon": "Codetyp der Zieladresse",
    "ccnotification.gatewayconfig.label.esmClass": "Nachrichtentyp",
    "ccnotification.gatewayconfig.label.destAddrNpi": "Zieladresscodeschema",
    "ccnotification.gatewayconfig.label.dataCoding": "Nachrichtenformat",
    "ccnotification.gatewayconfig.label.protocolId": "Protokoll-ID",
    "ccnotification.gatewayconfig.label.registeredDelivery": "Rücksendungsstatus-Bestätigungsbericht",
    "ccnotification.gatewayconfig.label.replaceIfPresentFlag": "Austauschmarkierung",
    "ccnotification.gatewayconfig.label.defaultsendno": "Standardsendenummer",
    "ccnotification.gatewayconfig.label.smDefaultMsgId": "Standardnachrichten-ID",
    "ccnotification.gatewayconfig.label.RcvAndSendMode": "TX/RX-Modus",
    "ccnotification.gatewayconfig.label.defaultreceinfo": "Standard-Empfangs-ID",
    "ccnotification.gatewayconfig.label.smppPduType": "SMPP-PDU-Typ",
    "ccnotification.gatewayconfig.label.recFlowController": "Name des Uplink-Datenverkehrscontrollers",
    "ccnotification.gatewayconfig.label.smHost": "SMG-Adresse",
    "ccnotification.gatewayconfig.label.smPort": "SMG-Anschluss",
    "ccnotification.gatewayconfig.label.systemId": "Anmeldename",
    "ccnotification.gatewayconfig.label.smPassword": "Anmeldekennwort",
    "ccnotification.gatewayconfig.label.interfaceVersion": "Versionsnummer der Anmeldenachricht",
    "ccnotification.gatewayconfig.label.systemType": "ESME-Typ",
    "ccnotification.gatewayconfig.label.localPort": "Clientport",
    "ccnotification.gatewayconfig.label.heartbeatInterval": "Heartbeat-Intervall (ms)",
    "ccnotification.gatewayconfig.label.resendNumber": "Wiederholungszeiten",
    "ccnotification.gatewayconfig.label.resendWaitTime": "Wartezeit (ms) beim erneuten Senden",
    "ccnotification.gatewayconfig.label.heartbeatNoRespCount": "Maximale Verbindungszeiten ohne Heartbeat-Antwort",
    "ccnotification.gatewayconfig.label.readTimeout": "Empfangs-Timeout-Intervall (ms)",
    "ccnotification.gatewayconfig.label.maxRcvError": "Maximale Anzahl der empfangenen fehlerhaften Pakete",
    "ccnotification.gatewayconfig.label.addrTon": "ESME-Adresstypcode",
    "ccnotification.gatewayconfig.label.addrNpi": "ESME-Adresscodierungsmodus",
    "ccnotification.gatewayconfig.label.slipWindowSize": "Schiebefenstergröße",
    "ccnotification.gatewayconfig.label.addressRange": "ESME-Adressbereich",
    "ccnotification.gatewayconfig.label.transactionTimeout": "Transaktions-Timeout-Intervall (ms)",
    "ccnotification.gatewayconfig.label.maxMessageLength": "Maximale Nachrichtenlänge (Byte)",
    "ccnotification.gatewayconfig.label.deviceTypeDescript": "Gerätetypbeschreibung",
    "ccnotification.gatewayconfig.label.serverSocketPort": "Port für den Empfang von Upstream-Nachrichten",
    "ccnotification.gatewayconfig.label.splitType": "SMS-Aufteilungsmodus mit Übergröße",
    "ccnotification.gatewayconfig.label.maxContentLength": "Maximale Inhaltslänge (Byte)",
    "ccnotification.gatewayconfig.label.bindMode": "Empfangs- und Sendemodus",
    "ccnotification.gatewayconfig.label.deviceDescript": "Gerätebeschreibung",
    "ccnotification.gatewayconfig.label.senderPoolMinCount": "Min. sendende Thread-Pools",
    "ccnotification.gatewayconfig.label.senderPoolMaxCount": "Maximale Anzahl sendender Thread-Pools",
    "ccnotification.gatewayconfig.label.receiverPoolMinCount": "Min. Empfangsthreadpools",
    "ccnotification.gatewayconfig.label.senderPoolQueueCapacity": "Länge der Thread-Pool-Warteschlange wird gesendet",
    "ccnotification.gatewayconfig.label.receiverPoolQueueCapacity": "Warteschlangenlänge des Empfangs-Thread-Pools",
    "ccnotification.gatewayconfig.label.receiverPoolMaxCount": "Maximale Anzahl empfangener Thread-Pools",
    "ccnotification.gatewayconfig.label.messageFlowControlMaxCapsForRecv": "Maximale Empfangsrate",
    "ccnotification.gatewayconfig.label.messageFlowControlMaxCapsForSend": "Maximale Senderate",
    "ccnotification.gatewayconfig.label.messageFlowControlMessageDropPolicy": "Verarbeitungsrichtlinie für zusätzlichen Datenverkehr",
    "ccnotification.gatewayconfig.label.splitMsgConcatMode": "Langer Nachrichtenverkettungsmodus",
    "ccnotification.gatewayconfig.label.addgatewayconfig": "Gateway-Konfiguration hinzufügen",
    "ccnotification.gatewayconfig.label.gatewayipaddrconfig": "Gateway-Adresse-Verbindungskonfiguration",
    "ccnotification.gatewayconfig.label.messageprotocol": "Nachrichtenprotokollparameter",
    "ccnotification.gatewayconfig.label.modifygateway": "Gateway ändern",
    "ccnotification.gatewayconfig.label.addgateway": "Gateway erstellen",
    "ccnotification.gatewayconfig.label.viewgateway": "Gateway anzeigen",
    "ccnotification.gatewayconfig.label.mailHosttitle": "Hostadresse\,z. B.\,127.0.0.1 oder www.mail.com",
    "ccnotification.gatewayconfig.label.authtitle": "Authentifizierungsflag\,Die Optionen sind wahr und falsch",
    "ccnotification.gatewayconfig.label.mailPorttitle": "Die Portnummer reicht von 0 bis 65535. Die Standard-SMTP-Portnummer ist 25.",
    "ccnotification.gatewayconfig.label.sourceAddrTontitle": "Der Quelladresscodetyp ist eine Zahl",
    "ccnotification.gatewayconfig.label.SSLEncryptionModetitle": "Verschlüsselungsmodus\, KEINE: Klartextübertragung (Standardport: 25)TLS: verschlüsselte Übertragung mit TLS (Standardport: 465)STARTTLS: verschlüsselte Übertragung mit STARTT LS-Befehl (Standardport: 587)",
    "ccnotification.gatewayconfig.label.sourceAddrNpititle": "Das Quelladresscodeschema ist eine Zahl",
    "ccnotification.gatewayconfig.label.destAddrTontitle": "Der Codetyp der Zieladresse ist eine Zahl",
    "ccnotification.gatewayconfig.label.destAddrNpititle": "Das Zieladresscodeschema ist eine Nummer",
    "ccnotification.gatewayconfig.label.dataCodingtitle": "0: ASCII-Zeichenfolge 4: Binärinformation 8: UCS2-Codierung",
    "ccnotification.gatewayconfig.label.registeredDeliverytitle": "Der Rückgabestatus-Bestätigungsbericht ist eine Nummer",
    "ccnotification.gatewayconfig.label.replaceIfPresentFlagtitle": "0: Ersetzen\, 1: Nicht ersetzen\, 2-255: Reserviert",
    "ccnotification.gatewayconfig.label.defaultsendnotitle": "Präfixregel der Absendernummer. Wenn beispielsweise 130 konfiguriert ist, werden Zahlen, die mit 130 beginnen, abgeglichen. Mehrere Zahlen werden durch Semikolons getrennt (;).",
    "ccnotification.gatewayconfig.label.smppPduTypetitle": "1:submit_sm 2:Daten_sm",
    "ccnotification.gatewayconfig.label.defaultreceinfotitle": "Regel für akzeptiertes Nummernpräfix: Wenn beispielsweise 130 konfiguriert ist, werden Nummern, die mit 130 beginnen, abgeglichen. Mehrere Zahlen werden durch Semikolons getrennt (;).",
    "ccnotification.gatewayconfig.label.recFlowControllertitle": "Name des Traffic Controllers, der die Uplink-Nachrichtenrate steuert.",
    "ccnotification.gatewayconfig.label.smHosttitle": "SMG-Adresse\, zum Beispiel\ 127.0.0.1",
    "ccnotification.gatewayconfig.label.smPorttitle": "SMG-Portnummernbereich: eine Ganzzahl zwischen 0 und 65535",
    "ccnotification.gatewayconfig.label.interfaceVersiontitle": "Die Versionsnummer der Anmeldenachricht muss eine Zahl sein.",
    "ccnotification.gatewayconfig.label.heartbeatIntervaltitle": "Das Heartbeat-Intervall ist eine korrekte Zahl",
    "ccnotification.gatewayconfig.label.heartbeatNoRespCounttitle": "Die maximale Verbindungszeit bei fehlender Heartbeat-Antwort ist eine korrekte Zahl.",
    "ccnotification.gatewayconfig.label.resendNumbertitle": "Die Wiederholungszeiten ist eine richtige Zahl",
    "ccnotification.gatewayconfig.label.resendWaitTimetitle": "Die Wartezeit für das erneute Senden ist eine korrekte Zahl",
    "ccnotification.gatewayconfig.label.readTimeouttitle": "Das Empfangs-Timeout-Intervall ist eine korrekte Zahl\,0: Niemals Timeout",
    "ccnotification.gatewayconfig.label.maxRcvErrortitle": "Die maximale Anzahl der empfangenen fehlerhaften Pakete ist eine korrekte Zahl",
    "ccnotification.gatewayconfig.label.slipWindowSizetitle": "Die Größe des Schiebefensters ist eine korrekte Zahl",
    "ccnotification.gatewayconfig.label.transactionTimeouttitle": "Das Transaktions-Timeout-Intervall ist eine korrekte Zahl",
    "ccnotification.gatewayconfig.label.maxMessageLengthtitle": "Die maximale Nachrichtenlänge (Byte) ist eine Zahl\, die im Protokoll festgelegt ist\, z. B.\ 2048.",
    "ccnotification.gatewayconfig.label.splitTypetitle": "Aufteilungsmodus einer überlangen SMS-Nachricht. Es gibt folgende Optionen: 0: nicht aufgeteilt; 1: nicht aufgeteilt; 2: physisch aufgeteilt; 3: überlange SMS-Nachricht aufgeteilt.",
    "ccnotification.gatewayconfig.label.maxContentLengthtitle": "Die maximale Inhaltslänge (Bytes) ist eine Zahl\, die als passende Aufteilungsbasis verwendet wird\, zum Beispiel\ 140.",
    "ccnotification.gatewayconfig.label.bindModetitle": "Zeigt den TX/RX-Modus an. 1: RX-Modus; 2: TX-Modus; 9: TX/RX-Modus.",
    "ccnotification.gatewayconfig.label.senderPoolMinCounttitle": "Die Mindestanzahl der gesendeten Thread-Pools ist eine Zahl\, z. B.\, 20.",
    "ccnotification.gatewayconfig.label.senderPoolMaxCounttitle": "Die maximale Anzahl sendender Thread-Pools ist eine Zahl\, z. B.\, 200.",
    "ccnotification.gatewayconfig.label.senderPoolQueueCapacitytitle": "Die Länge der sendenden Thread-Pool-Warteschlange ist eine Zahl\, z. B.\ 2000.",
    "ccnotification.gatewayconfig.label.receiverPoolMinCounttitle": "Die Mindestanzahl der Empfangsthread-Pools ist eine Zahl\, z. B.\, 20.",
    "ccnotification.gatewayconfig.label.receiverPoolMaxCounttitle": "Die maximale Anzahl empfangender Thread-Pools ist eine Zahl\, z. B.\, 200.",
    "ccnotification.gatewayconfig.label.receiverPoolQueueCapacitytitle": "Die Länge der Empfangsthread-Pool-Warteschlange ist eine Zahl\, z. B.\, 20.",
    "ccnotification.gatewayconfig.label.messageFlowControlMaxCapsForRecvtitle": "Die maximale Empfangsrate ist eine Zahl",
    "ccnotification.gatewayconfig.label.messageFlowControlMaxCapsForSendtitle": "Die maximale Senderate ist eine Zahl",
    "ccnotification.gatewayconfig.label.splitMsgConcatModetitle": "Kombinationsmodus für lange SMS-Nachrichten. 0: GSM-Benutzerdatenkopf; 1: SMPP-Parameter.",
    "ccnotification.gatewayconfig.label.messageFlowControlMessageDropPolicytitle": "Derzeit\ wird nur Ignorieren unterstützt.",
    "ccnotification.gatewayconfig.label.addgatewaysuccess": "Gatewayconfig erfolgreich hinzugefügt!",
    "ccnotification.gatewayconfig.label.addgatewayfailed": "Hinzufügen von Gatewayconfig fehlgeschlagen!",
    "ccnotification.gatewayconfig.label.modifygatewayfailed": "Die Änderung der Gateway-Konfiguration ist fehlgeschlagen!",
    "ccnotification.gatewayconfig.label.modifygatewaysuccess": "Gatewaykonfiguration wurde erfolgreich geändert!",
    "ccnotification.gatewayconfig.label.deletegatewaysuccess": "Gatewayconfig wurde erfolgreich gelöscht!",
    "ccnotification.gatewayconfig.label.deletegatewayfailed": "Löschen der Gateway-Konfiguration fehlgeschlagen!",
    "ccnotification.gatewayconfig.label.configNameAlreadyExists": "Der Konfigurationsname ist bereits vorhanden",
    "ccnotification.gatewayconfig.label.configNameIsError": "Der Konfigurationsname darf maximal 10 Ziffern oder Buchstaben enthalten",
    "ccnotification.gatewayconfig.label.requestParamIsNull": "Der Anforderungsparameter ist leer",
    "ccnotification.gatewayconfig.label.mailProtocolIsError": "Der E-Mail-Protokolltyp muss SMTP\, POP3 sein.",
    "ccnotification.gatewayconfig.label.configTypeIsError": "Der Konfigurationstyp ist falsch. Der interne Konfigurationstyp des Parameters ist inkonsistent",
    "ccnotification.gatewayconfig.label.paramIsNotNumber": "Der Parameterwert darf nur Ziffern enthalten",
    "ccnotification.gatewayconfig.label.authIsError": "Die Authentifizierungs-ID kann nur wahr oder falsch sein",
    "ccnotification.gatewayconfig.label.paramIsNotInRange": "Der Parameterwertebereich ist falsch",
    "ccnotification.gatewayconfig.label.sslencryptionmodeIsError": "Der E-Mail-Verschlüsselungsmodus kann nur KEINER\, STARTTLS\ oder TLS sein",
    "ccnotification.gatewayconfig.label.hostIsError": "Der E-Mail-Host kann nur die Formatierung 127.0.0.1 oder www.mail.com aufweisen",
    "ccnotification.gatewayconfig.label.defaultsendnoIsError": "Das Format der Standard-E-Mail-Adresse ist falsch",
    "ccnotification.gatewayconfig.label.dataCodingIsError": "Das SMS-Nachrichtenformat muss 0:ASCII-Zeichenfolge\,4:Binärinformationen\,8: UCS2-Code sein",
    "ccnotification.gatewayconfig.label.smppPduTypeIsError": "Der SMS-SMPP-PDU-Typ kann nur 1:submit_sm\,2:data_sm sein",
    "ccnotification.gatewayconfig.label.paramContainsSpecialChar": "Der Parameter enthält Sonderzeichen",
    "ccnotification.gatewayconfig.label.splitTypeIsError": "Der Aufteilungsmodus für lange SMS-Nachrichten kann nur auf 0:deaktiviert\,1:nicht aufgeteilt\,2:physische Aufteilung\,3:ultralange Aufteilung eingestellt werden",
    "ccnotification.gatewayconfig.label.bindModeIsError": "SMS-Sende- und Empfangsmodus 1:Empfangsmodus\,2:Sendemodus\,9:TX/RX-Modus",
    "ccnotification.gatewayconfig.label.splitMsgConcatModeIsError": "Long-Short-Message-Kombinationsmodus 0:GSM-Benutzerdaten-Header\,1:SMPP-Parameter",
    "ccnotification.gatewayconfig.label.paramMaxLengthIsError": "Der Parameterwert darf 500 Zeichen nicht überschreiten",
    "ccnotification.gatewayconfig.label.paramIsNegative": "Die Zahl darf nicht negativ sein",
    "ccnotification.gatewayconfig.label.maxsmGatewayIsError": "Es können maximal 10 SMS-Gateways im selben Mandantenbereich konfiguriert werden.",
    "ccnotification.gatewayconfig.label.maxEmailGatewayIsError": "Für denselben Mandantenbereich können maximal fünf E-Mail-Empfangs- oder -Sende-Gateways konfiguriert werden.",
    "ccnotification.gatewayconfig.label.willDeletegateway": "Möchten Sie die ausgewählte Gateway-Konfiguration wirklich löschen?",
    "ccnotification.gatewayconfig.label.selectdeletegatewayconfig": "Wählen Sie die Gateway-Konfiguration aus, die gelöscht werden soll!",
    "ccnotification.gatewayconfig.label.checkConfigName": "Der Wert ist eine Zeichenfolge mit weniger als 10 Ziffern und Buchstaben",
    "ccnotification.gatewayconfig.label.specialStrValidate": "Nur Großbuchstaben, Kleinbuchstaben und Zahlen sind zulässig.",
    "ccnotification.gatewayconfig.label.positive": "Die Zahl darf nicht negativ sein.",
    "ccnotification.gatewayconfig.label.errorstylenumber": "Falsches Zahlenformat.",
    "ccnotification.sms.huawei": "SMS von Huawei",
    "ccnotification.sms.gateway": "Gateway-SMS",
    "ccnotification.notifition.recmessagerouterconfig.index": "Index",
    "ccnotification.notification.recmessagerouterconfig.inbox": "Im Lieferumfang enthalten",
    "ccnotification.notification.recmessagerouterconfig.satisfaction": "Zufriedenheitsumfrage",
    "ccnotification.notification.recmessagerouterconfig.caseserver": "Dienst wird aufgerufen",
    "ccnotification.notification.recmessagerouterconfig.intelligentOrder": "Intelligente Fallerstellung",
    "ccnotification.notification.recmessagerouterconfig.emailOnlineChat": "Online-Chat über den E-Mail-Kanal",
    "ccnotification.notification.recmessagerouterconfig.mediaType": "Medientyp",
    "ccnotification.notification.recmessagerouterconfig.serviceType": "Implementierungsmethode",
    "ccnotification.notification.recmessagerouterconfig.serviceName": "Dienstname",
    "ccnotification.notification.recmessagerouterconfig.retryTimes": "Wiederholungszeiten",
    "ccnotification.notification.recmessagerouterconfig.receiver": "Empfänger",
    "ccnotification.notification.recmessagerouterconfig.receiveAddress": "Empfängeradresse",
    "ccnotification.notification.recmessagerouterconfig.placeholder.receiver": "Bitte geben Sie die Empfängeradresse ein",
    "ccnotification.notification.recmessagerouterconfig.deletenum.outstripping": "Es können maximal 100 Datensätze gleichzeitig gelöscht werden.",
    "ccnotification.notification.recmessagerouterconfig.willdeleteselectconfig": "Möchten Sie die ausgewählte Konfiguration wirklich löschen?",
    "ccnotification.notification.recmessagerouterconfig.selectconfig": "Wählen Sie eine Konfiguration aus.",
    "ccnotification.notification.recmessagerouterconfig.selectitem": "Bitte wählen Sie einen Datensatz aus.",
    "ccnotification.notification.recmessagerouterconfig.selectgateway": "Gateway auswählen",
    "ccnotification.notification.recmessagerouterconfig.gateway": "Gateway",
    "ccnotification.notification.recmessagerouterconfig.mobilerule": "Bitte geben Sie die Mobiltelefonnummern ein. Trennen Sie mehrere Mobiltelefonnummern durch Semikolons",
    "ccnotification.notification.recmessagerouterconfig.defaultreceiveemail": "Standard-E-Mail empfangen",
    "ccnotification.notification.recmessagerouterconfig.defaultsendemail": "Standard-E-Mail senden",
    "ccnotification.notification.recmessagerouterconfig.gatewaynotexist": "Das ausgewählte Gateway existiert nicht.",
    "ccnotification.notification.recmessagerouterconfig.receiveaddressexist": "Die Empfangsadresse ist bereits vorhanden.",
    "ccnotification.notification.recmessagerouterconfig.receivernotexist": "Der ausgewählte Empfänger existiert nicht.",
    "ccnotification.notification.label.upload": "Hochladen",
    "ccnotification.notification.label.attachment": "Anhang",
    "ccnotification.notification.label.downloadAttachment": "Anhang herunterladen",
    "ccnotification.notification.label.hasUplaodtAttachment": "Hochgeladene Anhänge",
    "ccnotification.notification.label.selectAttachment": "Wählen Sie einen E-Mail-Anhang aus.",
    "ccnotification.notification.label.uploadAttachmentSuccess": "Anhang erfolgreich hochgeladen",
    "ccnotification.notification.label.uploadAttachmentFailed": "Hochladen des Anhangs fehlgeschlagen",
    "ccnotification.notification.fileUploadRiskWarning": "Die hochgeladene Datei weist ein Sicherheitsrisiko auf\. Bitte bestätigen Sie, ob Sie mit dem Hochladen fortfahren möchten?",
    "ccnotification.notification.label.fileUploadFileNameError": "Der Dateiname, den Sie hochgeladen haben, entspricht nicht den Anforderungen",
    "ccnotification.notification.label.fileUploadFileTypeError": "Der Dateityp, den Sie hochgeladen haben, erfüllt nicht die Anforderungen",
    "ccnotification.notification.label.fileUploadFileSizeError": "Die hochgeladene Dateigröße überschreitet den Grenzwert",
    "ccnotification.notification.label.fileUploadFileIOError": "Bei der hochgeladenen Datei ist ein Parsing-Fehler aufgetreten",
    "ccnotification.notification.label.deleteAttachmentSuccess": "Anhang erfolgreich gelöscht",
    "ccnotification.notification.label.deleteAttachmentFailed": "Anhang konnte nicht gelöscht werden",
    "ccnotification.notification.label.isUploadAttachment": "Anhang hochladen",
    "ccnotification.notification.label.isDownloadAttachment": "Wenn die Anlage, die Sie herunterladen, im Office-Dokumentformat vorliegt, ergreifen Sie bitte die erforderlichen Sicherheitsvorkehrungen.",
    "ccnotification.notification.label.isDeleteAttachment": "Anhang löschen",
    "ccnotification.notification.label.attachmentIsEmpty": "Der Anhang ist leer.",
    "ccnotification.notification.label.attachmentExceed10": "Es können maximal 10 Anhänge hochgeladen werden.",
    "ccnotification.notification.label.queryAttachmentFailed": "Anhang konnte nicht abgefragt werden",
    "ccnotification.notification.label.createTemFileFailed": "Die temporäre Datei konnte nicht erstellt werden",
    "ccnotification.notification.label.serverIsNotobs": "Anlagen können nur vom OBS-Dateiserver heruntergeladen werden",
    "ccnotification.notification.label.downloadFromObsFailed": "Dateien konnten nicht von OBS heruntergeladen werden ",
    "ccnotification.notification.label.compressZipFileFailed": "Die ZIP-Datei konnte nicht komprimiert werden",
    "ccnotification.notification.message.noAttachement": "Sie haben keinen Anhang hochgeladen.",
    "ccnotification.notification.label.incorrectAttachmentType": "Falscher E-Mail-Anhangstyp",
    "ccnotification.notification.label.attachmentSizeError": "Der E-Mail-Anhang ist größer als 10 MB",
    "ccnotification.notification.label.nameContainSpecialChar": "Der Name des E-Mail-Anhangs enthält Sonderzeichen",
    "ccnotification.center.message.setIsRead": "Nachrichten als gelesen markieren ",
    "ccnotification.notification.label.uploadPermissionFailed": "Überprüfen der Berechtigung zum Hochladen von E-Mail-Anhängen fehlgeschlagen",
    "ccnotification.notification.label.downPermissionFailed": "Prüfen der Berechtigung zum Herunterladen von E-Mail-Anhängen fehlgeschlagen",
    "ccnotification.notification.label.checkAttachNameLegnth": "Der Name des E-Mail-Anhangs darf nicht länger als 100 Zeichen sein ",
    "ccnotification.notification.systemmonitor.gatewayName": "GatewayName",
    "ccnotification.notification.systemmonitor.mspId": "MspId",
    "ccnotification.notification.systemmonitor.modifyTime": "Geändert am",
    "ccnotification.notification.systemmonitor.status": "Status",
    "ccnotification.notification.systemmonitor.rebuilding": "Wiederherstellung",
    "ccnotification.notification.systemmonitor.disconnected": "Verbindung getrennt",
    "ccnotification.notification.label.rebuildSuccess": "Wiederherstellung erfolgreich",
    "ccnotification.notification.label.rebuildContainsErrorId": "Wiederherstellung fehlgeschlagen\, klicken Sie auf die Abfrageschaltfläche, um die Liste zu aktualisieren, und versuchen Sie es erneut\.",
    "ccnotification.notification.label.rebuildFailed": "Wiederherstellung fehlgeschlagen",
    "ccnotification.notification.label.discounnectedSuccess": "Getrennte Verbindung erfolgreich",
    "ccnotification.gatewayconfig.label.selectrebuildgatewayconfig": "Wählen Sie die Gateway-Konfiguration aus, die neu aufgebaut werden soll",
    "ccnotification.notification.label.discounnectedFailed": "Trennen fehlgeschlagen",
    "ccnotification.gatewayconfig.label.selectredisconnectedgatewayconfig": "Wählen Sie die Gateway-Konfiguration aus, die getrennt werden soll",
    "ccnotification.gatewayconfig.label.statusisnoterror": "Der Status ist nicht ERROR.",
    "ccnotification.gatewayconfig.label.selectredgatewayconfig": "Überwachung des ausgewählten Gateways:",
    "ccnotification.gatewayconfig.label.INIT": "Initialisierung",
    "ccnotification.caseserver.fail.titleAndContentNull": "Leerer Titel und Inhalt",
    "ccnotification.caseserver.fail.duplicateContent": "Doppelter Inhalt",
    "ccnotification.caseserver.fail.categoryNotExist": "Nicht vorhandene Fallkategorie",
    "ccnotification.flowcontrol.getwayset": "Festlegen",
    "ccnotification.flowcontrol.setFlowType": "Datengrenzwerttyp",
    "ccnotification.flowcontrol.setThreshold": "Datenschwellenwert festlegen",
    "ccnotification.flowcontrol.eachThreshold": "Schwellenwert für tägliche Monomedia-Daten",
    "ccnotification.flowcontrol.title": "Benachrichtigungskonfiguration festlegen",
    "ccnotification.flowcontrol.messageThreshold": "Täglicher Datenschwellenwert für SMS-Nachrichten",
    "ccnotification.flowcontrol.emailThreshold": "Täglicher Datenschwellenwert für E-Mails",
    "ccnotification.flowcontrol.totalThreshold": "Schwellenwert für tägliche Gesamtdaten",
    "ccnotification.flowcontrol.modifySuccess": "Die Benachrichtigungskonfiguration wurde erfolgreich geändert.",
    "ccnotification.flowcontrol.modifyFailed": "Die Benachrichtigungskonfiguration konnte nicht geändert werden. Prüfen Sie die Eingangsparameter oder das Netzwerk.",
    "ccnotification.flowcontrol.getFailed": "Die Benachrichtigungskonfiguration konnte nicht abgerufen werden. Überprüfen Sie das Netzwerk.",
    "ccnotification.flowcontrol.countryCode": "Landeskennzahl",
    "ccnotification.notification.label.fileInjectionRiskException": "Ausnahme des Risikos des Einschleusens von E-Mail-Anhängen",
    "ccnotification.flowcontrol.shortMsgCode": "SMS-Nachrichtennummer",
    "ccnotification.notifition.sendmessage.entercompanyName": "Geben Sie einen Firmennamen ein.",
    "ccnotification.flowcontrol.setShortMsgCode": "Legen Sie die Anzahl für SMS-Nachrichten fest.",
    "ccnotification.common.title.warning": "Warnung",
    "ccnotification.title.flowcontrol.receiveSmsFlag": "MO-SMS-Nachrichten empfangen",
    "ccnotification.common.button.ok": "OK",
    "ccnotification.common.title.fail": "Fehlgeschlagen",
    "ccnotification.common.title.success": "Erfolgreich",
    "ccnotification.common.title.tips": "Tipps",
    "ccnotification.agent.contact.downloadFail": "Download fehlgeschlagen",
    "ccnotification.common.title.errorsave": "Fehler",
    "ccnotification.common.title.confirm": "Bestätigen",
    "ccnotification.common.button.delete": "Löschen",
    "ccnotification.common.title.delete": "Löschen",
    "ccnotification.agent.operation.searchworkno": "Bitte geben Sie die Mitarbeiter-ID ein",
    "ccnotification.common.title.confreset": "Zurücksetzen bestätigen",
    "ccnotification.agent.pageurls.isconfreset": "Möchten Sie es wirklich zurücksetzen?",
    "ccnotification.agent.message.updateCtiAndDbFail": "Aktualisieren von CTI und Db fehlgeschlagen.",
    "ccnotification.common.label.name": "Name",
    "ccnotification.agent.label.account": "Konto",
    "ccnotification.common.button.create": "Neu",
    "ccnotification.common.button.new": "Neu",
    "ccnotification.agent.contact.workno": "Mitarbeiter-ID",
    "ccnotification.urlconfig.pageurls.urlvalidate": "Die URLs müssen mit https:// beginnen",
    "ccnotification.common.message.maxLength": "Der Textinhalt darf maximal 4096 Zeichen enthalten.",
    "ccnotification.common.operation.noMoreThan7Days": "Der Zeitraum darf 7 Tage nicht überschreiten.",
    "ccnotification.common.message.emptyContent": "Der Inhalt darf nicht leer sein.",
    "ccnotification.chatserver.fail.assign": "Agentenzuweisung fehlgeschlagen",
    "ccnotification.chatserver.fail.barred": "Blockierte Agentenzuweisung",
    "ccnotification.common.message.maxExpireTime": "Wählen Sie eine Ablaufzeit aus, die nicht später als {0} ist.",
    "ccnotification.bulletinType.title.bulletinTypeName": "Name des Bulletin-Typs",
    "ccnotification.bulletinType.title.bulletintypeInvalidSect": "Standardablaufzeit",
    "ccnotification.bulletinType.title.createBulletinType": "Bulletintyp erstellen",
    "ccnotification.bulletinType.title.editBulletinType": "Bulletintyp ändern",
    "ccnotification.bulletinType.message.searchPlaceHolder": "Name des Bulletin-Typs",
    "ccnotification.bulletinType.title.bulletinTypeDesc": "Beschreibung",
    "ccnotification.bulletinType.message.createBulletinTypeSuccessful": "Der Bulletin-Typ wurde erfolgreich erstellt.",
    "ccnotification.bulletinType.message.deleteBulletinTypeSuccessful": "Der Bulletin-Typ wurde erfolgreich gelöscht.",
    "ccnotification.bulletinType.message.editBulletinTypeSuccessful": "Der Bulletin-Typ wurde erfolgreich geändert.",
    "ccnotification.bulletinType.message.betchDeleteBulletinTypeSuccessful": "Die Bulletin-Typen wurden erfolgreich stapelweise gelöscht.",
    "ccnotification.bulletinType.message.createBulletinTypeFailed": "Der Bulletin-Typ konnte nicht erstellt werden.",
    "ccnotification.common.button.finish": "Abgeschlossen",
    "ccnotification.bulletinType.message.betchDeleteBulletinTypeFailed": "Die Bulletin-Typen konnten nicht stapelweise gelöscht werden.",
    "ccnotification.bulletinType.message.deleteBulletinTypeFailed": "Der Bulletin-Typ konnte nicht gelöscht werden.",
    "ccnotification.bulletinType.message.invalidSectCheck": "Die Standardablaufzeit muss zwischen 1 und 2000 Tagen liegen.",
    "ccnotification.bulletinType.message.nameContainSpecialChar": "Der Name darf keine Sonderzeichen enthalten.",
    "ccnotification.bulletinType.message.decimealsCheck": "Die Standardablaufzeit darf keine Dezimalstelle enthalten.",
    "ccnotification.bulletinType.message.willDelete": "Möchten Sie den ausgewählten Bulletin-Typ wirklich löschen?",
    "ccnotification.bulletinType.message.selectItem": "Wählen Sie einen Bulletin-Typ aus.",
    "ccnotification.bulletinType.message.nameIsNotUnique": "Der Name des Bulletin-Typs ist bereits vorhanden.",
    "ccnotification.bulletinType.message.releaseTime": "Freigabezeit",
    "ccnotification.bulletinType.message.periodicallySend": "regelmäßig senden",
    "ccnotification.bulletinType.message.sendingFrequency": "Sendefrequenz",
    "ccnotification.bulletinType.message.weekly": "wöchentlich",
    "ccnotification.bulletinType.message.everyDay": "Jeden Tag",
    "ccnotification.bulletinType.message.perMonth": "pro Monat",
    "ccnotification.bulletinType.message.Monday": "Montag",
    "ccnotification.bulletinType.message.Tuesday": "Dienstag",
    "ccnotification.bulletinType.message.Thursday": "Donnerstag",
    "ccnotification.bulletinType.message.Wednesday": "Mittwoch",
    "ccnotification.bulletinType.message.Friday": "Freitag",
    "ccnotification.bulletinType.message.Saturday": "Samstag",
    "ccnotification.bulletinType.message.Sunday": "Sonntag",
    "ccnotification.bulletinType.message.daysOfMonth": "Tag 0",
    "ccnotification.bulletinType.message.sendTimeParamsError1": "Die Veröffentlichungszeit darf nicht vor der aktuellen Zeit liegen",
    "ccnotification.bulletinType.message.sendTimeParamsError2": "Freigabezeit darf nicht nach Ablaufzeit liegen",
    "ccnotification.bulletinType.title.Urgent": "Mittel",
    "ccnotification.bulletinType.title.General": "Niedrig",
    "ccnotification.bulletinType.title.Rushse": "Hoch",
    "ccnotification.bulletinType.title.Faults": "Kritisch",
    "ccnotification.notifition.template.message.phoneLabel": "Telefonnummer",
    "ccnotification.notifition.template.message.dateTimeLabel": "Datum und Uhrzeit",
    "ccnotification.notifition.template.message.charDigitLabel": "Ziffer und Buchstabe",
    "ccnotification.notifition.template.message.moneyLabel": "Betrag",
    "ccnotification.notifition.template.message.textLabel": "Text",
    "ccnotification.common.message.sendIntervalShort": "Häufiges Senden. Sendeintervall(e): {n}.",
    "ccnotification.notifition.sendmessage.selectOutbox": "Wählen Sie den Postausgang aus.",
    "ccnotification.notifition.gatewaycontact.connecting": "Verbindung wird hergestellt",
    "ccnotification.gatewayconfig.message.authInfo": "Die Einstellung False birgt Sicherheitsrisiken. Es wird empfohlen, True festzulegen, um die Kennwortauthentifizierung zu aktivieren.",
    "ccnotification.caseserver.fail.serviceException": "Anormaler Service",
    "ccn.smshuawei.message.table.label": "HUAWEI CLOUD SMS",
    "ccn.smsnoconfig.table.header.countrycode": "Ländercode des Absenders",
    "ccn.smsnoconfig.pop.title.createtitle": "SMS-Nummer hinzufügen",
    "ccn.smsnoconfig.pop.title.edittitle": "SMS-Nummer bearbeiten",
    "ccnotification.bulletinType.message.editBulletinTypeFailed": "Der Bulletin-Typ konnte nicht geändert werden.",
    "ccnotification.notifition.sendmessage.entersender": "Wählen Sie eine Absendernummer aus.",
    "ccnotification.notifition.sendmessage.sender": "Absendernummer",
    "ccn.smsnoconfig.table.header.countrycode.desc": "Land",
    "ccnotification.flowcontrol.recipient.countrycode": "Ländercode des Empfängers",
    "ccn.smsnoconfig.pop.check.duplicatecode": "Die Nummer existiert bereits.",
    "ccn.smsnoconfig.pop.check.selectwarning": "Wählen Sie den Datensatz aus, den Sie löschen möchten.",
    "ccn.smsnoconfig.pop.check.deletewarning": "Möchten Sie die ausgewählten Daten wirklich löschen?",
    "ccn.smsnoconfig.pop.check.deletesuccess": "Löschung erfolgreich.",
    "ccn.smsnoconfig.pop.check.deletefailed": "Löschen fehlgeschlagen.",
    "ccnotification.gatewayconfig.message.securityInfo": "KEINE gibt an, dass die Daten im Klartext übertragen werden. Daher können Daten durchsickern. STARTTLS ist ein unsicheres Protokoll. Die Verwendung unsicherer Protokolle stellt ein Sicherheitsrisiko dar. Das TLS-Protokoll wird empfohlen.",
    "ccnotification.notification.emailsignature.placeholder.name": "Geben Sie einen Signaturnamen ein",
    "ccnotification.notification.emailsignature.name": "Name der Unterschrift",
    "ccnotification.notification.emailsignature.description": "Beschreibung der Unterschrift",
    "ccnotification.notification.emailsignature.content": "Unterschrifteninhalt",
    "ccnotification.notification.emailsignature.nameexist": "Der Signaturname ist bereits vorhanden.",
    "ccnotification.notification.emailsignature.noitemselect": "Wählt keine Daten aus.",
    "ccnotification.notification.emailsignature.willdeleteselectitem": "Möchten Sie die ausgewählten Daten wirklich löschen?",
    "ccnotification.notification.emailsignature.reachmaxcount": "Die Anzahl der E-Mail-Signaturen überschreitet das Maximum.",
    "ccnotification.notification.emailsignature.selection.username": "Benutzername",
    "ccnotification.notification.emailsignature.content.length.exceed": "Die Länge des Signaturinhalts darf 4096 nicht überschreiten.",
    "ccnotification.notification.label.uploadAttachmentTips": "E-Mail-Anhänge können folgendes Format haben: doc, docx, xls, xlsx, txt, png, jpg, xml, json, csv, bmp, zip, ppt, pptx, pdf, Die Dateigröße darf 10 MB nicht überschreiten.Die Dateien im komprimierten Paket müssen auch dem oben angegebenen Format entsprechen.",
    "ccnotification.notification.special.charactor.check": "Der eingegebene Inhalt darf nicht ≤> / \\ \ eval und exec enthalten.",
    "ccnotification.notification.recmessagerouterconfig.updatenum.outstripping": "Es können maximal 100 Datensätze gleichzeitig aktualisiert werden.",
    "ccnotification.notification.uploadfail.duplicateFileName": "Doppelter Dateiname.",
    "ccn.smsnoconfig.table.label": "SMS-Nummer",
    "ccnotification.sms.rcvAndSendMode.receiveSms": "SMS-Nachrichten empfangen",
    "ccnotification.sms.rcvAndSendMode.sendSms": "SMS-Nachrichten senden",
    "ccn.smsnoconfig.pop.search.reminder": "Geben Sie eine Zahl ein.",
    "ccnotification.sms.rcvAndSendMode.receiveAndSendSms": "SMS-Nachrichten senden und empfangen",
    "ccnotification.sms.smppPduTye.data.sm": "Daten_sm",
    "ccnotification.sms.gms.user.data.head": "GSM-Benutzerdatenkopfzeile",
    "ccnotification.sms.smppPduTye.submit.sm": "senden_sm",
    "ccnotification.sms.smpp.parameters": "SMPP-Parameter",
    "ccnotification.sms.smpp.replaceIfPresentFlag.replace": "Ersetzen",
    "ccnotification.sms.smpp.replaceIfPresentFlag.not.replace": "Nicht ersetzen",
    "ccnotification.sms.smpp.stow.away": "Ablegen",
    "ccnotification.sms.smpp.ESME.Connection.Configuration": "ESME-Verbindungskonfiguration",
    "ccnotification.gatewayconfig.label.splitType.ultraLong": "ultralange Trennung",
    "ccnotification.gatewayconfig.label.splitType.noSplit": "nicht aufgeteilt",
    "ccnotification.gatewayconfig.label.splitType.disabled": "behindert",
    "ccnotification.gatewayconfig.label.dataCoding.ASCII": "ASCII-Zeichenfolge",
    "ccnotification.gatewayconfig.label.dataCoding.Binary": "Binärinformationen",
    "ccnotification.gatewayconfig.label.splitType.physical": "physische Aufteilung",
    "ccnotification.gatewayconfig.label.basic.configuration": "Basiskonfiguration",
    "ccnotification.notification.recmessagerouterconfig.defaultsendnumber": "Standardsendenummer.",
    "ccnotification.notification.recmessagerouterconfig.defaultreceivenumber": "Standard-Empfangsnummer.",
    "ccnotification.gatewayconfig.label.advancedconfig": "Erweiterte Einstellungen",
    "ccnotification.gatewayconfig.label.dataCoding.UCS2": "UCS2-Codierung",
    "ccnotification.template.message.organizationStructure": "Organisationsstruktur",
    "ccnotification.template.message.alignment": "Die Anzahl der Kartenoptionen darf nicht überschreiten",
    "ccnotification.email.signature.message.uploadImgTypeError": "Es können nur JPG-JPEG- und PNG-Bilder hochgeladen werden.",
    "ccnotification.email.signature.knowledge.message.uploadImgSizeError": "Die Größe des hochzuladenden Bildes darf {}. nicht überschreiten.",
    "ccnotification.email.signature.knowledge.message.uploadError": "Die E-Mail-Signatur enthält Bilder in einer falschen Größe oder einem falschen Format. Löschen Sie sie, und versuchen Sie es erneut.",
    "ccn.flow.grayrule.editsuccess": "Geändert erfolgreich.",
    "ccn.voiceedit.addsuccess": "Wird erfolgreich hinzugefügt.",
    "ccn.voiceedit.addfail": "Hinzufügen fehlgeschlagen.",
    "ccnotification.notification.emailsignature.selection.userEmail": "E-Mail-Adresse des Benutzers",
    "ccnotification.notification.emailsignature.selection.userPosition": "Benutzerposition",
    "ccn.voiceedit.deletesuccess": "Erfolgreich gelöscht.",
    "ccnotification.notifition.template.create": "Erstellen einer Benachrichtigungsvorlage",
    "ccnotification.template.message.channelType": "Kanaltyp",
    "pop.org.window.org": "Heimatorganisation",
    "ccnotification.template.message.add.variable": "Ergänzungen",
    "ccnotification.template.message.content": "Vorlageninhalt",
    "ccnotification.template.message.enterDescription": "Geben Sie eine Beschreibung ein.",
    "ccnotification.notification.systemmonitor.batchrebuild": "Batch-Wiederherstellung",
    "ccnotification.notification.systemmonitor.batchdisconnect": "Stapeltrennung",
    "ccnotification.gatewayconfig.label.send.prefix": "Präfix der Absendernummer",
    "ccnotification.gatewayconfig.label.receive.prefix": "Akzeptiertes Nummernpräfix",
    "ccnotification.signature.add": "E-Mail-Signatur erstellen",
    "ccnotification.signature.update": "E-Mail-Signatur ändern",
    "ccnotification.signature.view": "E-Mail-Signatur anzeigen",
    "ccnotification.gatewayconfig.label.select.sendNumber.title": "Auswahl der Absendernummer",
    "ccnotification.gatewayconfig.label.add.sendNumber.title": "Hinzufügen einer Absendernummer",
    "ccn.whitelist.urlvalidatefailed": "Geben Sie die IP-Adresse oder den Domänennamen ein",
    "ccnotification.notification.notificationRoute.recipientAddress": "Empfängeradresse",
    "ccnotification.notification.notificationRoute.implementationMode": "Implementierungsmodus",
    "ccnotification.notification.notificationRoute.edit": "Bearbeiten",
    "ccnotification.notification.notificationRoute.newNotificationRoute": "Neuer Benachrichtigungsweg",
    "ccnotification.notification.notificationRoute.editNotificationRoute": "Benachrichtigungsweg bearbeiten",
    "ccnotification.notification.notificationRoute.inbox": "Posteingang",
    "ccnotification.notification.notificationRoute.serviceInvoking": "Dienst wird aufgerufen",
    "ccnotification.notification.notificationRoute.selectconfig": "Wählen Sie eine Konfiguration aus.",
    "ccnotification.notification.notificationRoute.willdeleteselectconfig": "Möchten Sie die ausgewählte Konfiguration wirklich löschen?",
    "ccnotification.notification.notificationRoute.deletenum.outstripping": "Es können maximal 100 Datensätze gleichzeitig gelöscht werden.",
    "ccnotification.notification.notificationRoute.delSuccess": "Erfolgreich gelöscht.",
    "ccnotification.notification.notificationRoute.delFail": "Löschen fehlgeschlagen.",
    "ccnotification.notification.notificationRoute.gateway": "Gateway",
    "ccnotification.notification.notificationRoute.gatewaynotexist": "Das ausgewählte Gateway existiert nicht.",
    "ccnotification.notification.notificationRoute.receivernotexist": "Der ausgewählte Empfänger existiert nicht.",
    "ccnotification.notification.notificationRoute.receiveaddressexist": "Die Empfangsadresse ist bereits vorhanden.",
    "ccnotification.notification.notificationRoute.intelligentOrder": "Intelligente Fallerstellung",
    "ccnotification.notification.notificationRoute.mobilerule": "Bitte geben Sie die Mobiltelefonnummern ein. Trennen Sie mehrere Mobiltelefonnummern durch Semikolons",
    "ccnotification.notification.notificationRoute.inputPhoneNum": "Bitte geben Sie eine Mobiltelefonnummer ein",
    "ccnotification.notification.notificationRoute.receiveAddrLen": "Die Länge darf 1024 Zeichen nicht überschreiten",
    "ccnotification.notification.notificationRoute.retryNumLimitRule": "Der Eingabewert liegt außerhalb des Bereichs: 0-3",
    "ccn.variable.willdeleteselectvariable": "Möchten Sie die ausgewählten Variablen wirklich löschen?",
    "ccn.variable.deleteselectvariable": "Variable löschen",
    "ccn.variable.variableisreferenced": "Variablen werden durch Fluss referenziert.",
    "ccn.variable.view.references": "Referenzen anzeigen",
    "ccn.variable.view.references.none": "Auf die Variable wird derzeit nicht verwiesen.",
    "ccn.variable.selectvariable": "Wählen Sie einen variablen Parameter aus.",
    "ccn.variable.field": "Benachrichtigungsvariablen",
    "ccn.unit.bytes": "Bytes",
    'ccn.unit.millisecond': 'Millisekunde',
    "ccn.message.fullscreen": "Maximieren",
    'ccn.sms.config.batch.delete': 'Stapel löschen',
    'ccn.sms.config.receiver.uplinkMess': 'Empfang einer MO-SMS-Nachricht',
    "ccn.sms.config.create.smsConfig": "Erstellen von SMS-Konfigurationen",
    "ccn.sms.config.modify.smsConfig": "SMS-Konfigurationen ändern",
    "ccnotification.smstemplate.message.edit.fail": "Die HUAWEI CLOUD SMS-Vorlage konnte nicht geändert werden.",
    "ccnotification.message.choose": "Wählen Sie Daten aus.",
    "ccnotification.message.add.attachement": "Anhang hinzufügen",
    "ccnotification.message.defaultsenderid.error": "Die Standard-Absendernummer liegt nicht im zulässigen Bereich. Bitte wählen Sie eine korrekte Nummer.",
    "ccnotification.template.message.emailAddressEmpty": "Empfänger-E-Mail nicht konfiguriert:",
    "ccn.countrycode.type": "Ländervorwahl-Präfixtyp",
    "ccn.countrycode.type.with.plus": "Mit dem Präfix +",
    "ccn.countrycode.type.without.plus": "Ohne das Präfix +"
}