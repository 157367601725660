import request from '@/utils/request'

/**
 * 外呼数据导入模板下载url
 * @type {string}
 */
export const downloadTemplateUrl = '/service-cloud/u-route/isales/data/downloadtemplate'
/**
 * 查询文件服务器列表
 * @returns fileServerList
 */
export const queryFileServerList = () => {
    return request({
        url: '/service-cloud/rest/isales/v1/data/queryFileServerList',
        method: 'post',
    })
}
/**
 * 查询导入规则列表
 * @param data
 * @returns mapRuleList
 */
export const queryMapRuleList = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/data/queryMapRuleList',
        method: 'post',
        data
    })
}
/**
 * 查询组网信息
 * @returns cmsEnvironmcent
 */
export const queryCmsEnvironment = () => {
    return request({
        url: '/service-cloud/rest/isales/v1/task/queryCmsEnvironment',
        method: 'post',
    })
}
/**
 * 查询任务信息
 *  @param data
 * @returns taskInfo
 */
export const queryTaskInfo = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/task/queryTaskByCondition',
        method: 'post',
        data
    })
}
/**
 * 根据任务ID查询外呼数据
 * @param data
 * @returns cmsEnvironmcent
 */
export const queryDataByTaskId = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/data/query',
        method: 'post',
        data
    })
}
/**
 * 上传文件
 * @param data
 * @returns 上传结果
 */
export const importTaskData = data => {
    return request({
        url: '/service-cloud/u-route/isales/data/importdata',
        method: 'post',
        data
    })
}
/**
 * 通过文件服务器上传文件
 * @param data
 * @returns 上传结果
 */
export const importServerData = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/data/importServerData',
        method: 'post',
        data
    })
}
/**
 * 查询租户下的所有业务结果
 * @returns 业务结果列表
 */
export const queryBusinessResult = () => {
    return request({
        url: '/service-cloud/rest/isales/v1/task/queryAllCodeAndNameOfTenant',
        method: 'post',
    })
}
/**
 * 通过文件服务器定时上传文件
 * @param data
 * @returns 上传结果
 */
export const timeImport = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/data/timeImportConfig',
        method: 'post',
        data
    })
}

