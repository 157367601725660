export default {
  'sm.assign.employee': 'Atribuir funcionário',
  'SM.USERGROUP.SHOW.LIST': 'Grupos de usuários',
  'common.baseui.LABEL.HEADING_BASEINFO': 'Informações básicas',
  'SM.USERGROUP.LABEL.NAME': 'Nome do Grupo de Usuários',
  'common.bremui.label.description': 'Descrição',
  'SM.SERVLET.EMPLOYEE': 'Empleado',
  'common.statemachineui.button.add': 'Adicionar',
  'sm.add.usergroup': 'Criando um grupo de usuários',
  'sm.edit.usergroup': 'Editar grupo de usuários',
  'SM.ORGSTAFF.BUTTON.COMMON_OK': 'OK',
  'SM.COMMON.LABEL.SYSMENU_SUSPENDED': 'Desativar',
  'SM.ORGSTAFF.MESSAGE.OPERATESUCCESS': 'Operação executada com sucesso.',
  'SM.ORGSTAFF.MESSAGE.STAFF_AUTHCOPY_SOURCENO_CONTBEADMIN_ERR':
    'A conta administrador do sistema não pode ser usada como a conta de origem.',
  'SM.COMMON.BUTTON.BANKCARD_SEARCH': 'Pesquisar',
  'SM.ORGSTAFF.MESSAGE.BE_DELETESUCCESS': 'Excluído com sucesso',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_DELETE_FAILED': 'Falha ao excluir o usuário.',
  'SM.ORGSTAFF.TIPS.INVALID_IP_ADDRESS': 'Endereço IP inválido.',
  'SM.ORGSTAFF.LABEL.ACCESS.ADD_FAIL': 'A adição falhou.',
  'SM.ORGSTAFF.TITLE.CONTACT_DESC': 'Descrição',
  'SM.COMMON.LABEL.AREALIST': 'Lista de Áreas',
  'SM.COMMON.LABEL.CURRENCY_DECIMALSYMBOL': 'Símbolo decimal',
  'SM.COMMON.TIPS.CHARGECODE_CODEINPUT': 'Você só pode inserir letras e números.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_SECONDMENT': 'destacamento',
  'SM.COMMON.LABEL.CURRENCY_STANDARD_MEASUREMENT': 'Sinalizador de unidade padrão',
  'SM.COMMON.LABEL.HOLIDAY_THURSDAY': 'Qui',
  'SM.COMMON.LABEL.SYSMENU_AUTHID': 'ID da permissão do menu',
  'SM.ORGSTAFF.MESSAGE.REGION_CONFIRM_USEPARENT':
    'Se os dados do nó pai forem usados, a configuração do fuso horário atual será limpa. Tem certeza de que deseja continuar?',
  'SM.ORGSTAFF.TITLE.CONTACT_TYPE': 'Tipo de contato',
  'SM.ORGSTAFF.TITLE.BE_PRECISION': 'Precisão da Moeda',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_SET_ROLE': 'Definir função',
  'SM.ORGSTAFF.MESSAGE.EMPLOYEE_SECONDMENT_CONFIRM': 'Tem certeza de que deseja terminar?',
  'SM.ORGSTAFF.LABEL.ORG_BASICINFO': 'Informações básicas',
  'SM.COMMON.LABEL.MEASUREMENT_UNIT_OPERARION': 'Operação',
  'SM.ORGSTAFF.LABEL.BANK_TYPE': 'Tipo Banco',
  'SM.COMMON.BUTTON.SURVEY_DELETE_ANSWER_OPTIONS': 'Excluir opções de resposta',
  'SM.COMMON.MESSAGE.SYSMENU_DEACTIVATED_NOT_SUPPORT':
    'O status do menu desativado não suporta gerenciamento de permissões.',
  'SM.ORGSTAFF.TIPS.RESTRICTIP': 'Insira um endereço IP no formato xx.* ou xx.xx.* ou xx.xx.xx.*.',
  'SM.ORGSTAFF.TITLE.CONTACT_EMAIL': 'Email',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_SECONDMENT_ENDDATE': 'Data de término',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_SEARCH_CRITERIA_BE': 'BE',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_VIEWFILE': 'Exibir',
  'SM.ORGSTAFF.MESSAGE.ORG_PARENT_FORBID': 'A OU pai foi suspensa.',
  'SM.COMMON.LABEL.ATTR_LANGUAGE': 'Idioma',
  'SM.ORGSTAFF.LABEL.TEAM_ASSIGN': 'Um máximo de 2000 funcionários podem ser atribuídos.',
  'SM.COMMON.LABEL.ATTACHMENT_DEL_ACCESSORY': 'Excluir',
  'SM.ORGSTAFF.LABEL.SEARCH_ALL': 'Pesquisar Toda a equipe',
  'SM.ORGSTAFF.LABEL.ADDR_DISPLAY_ORDER': 'Seqüência de exibição de endereço incorreta.',
  'SM.COMMON.LABEL.MEASUREMENT_DESTMEAUNIT': 'Unidade de Medição Alvo',
  'SM.ORGSTAFF.MESSAGE.CHOOSE_SELECTED_OU_FIRST':
    'Selecione um nó na árvore Relações Organizacionais primeiro.',
  'SM.ORGSTAFF.MESSAGE.STAFF_AUTHCOPY_SOURCENO_EQUALTOTARGET_ERROR':
    'A conta de origem não pode ser contida na conta de destino.',
  'SM.ORGSTAFF.TITLE.ROLE_BASIC': 'Função básica',
  'SM.ORGSTAFF.LABEL.STAFF_SESSION_VALIDITY_PERIOD': 'Período de Validade da Sessão (Min)',
  'SM.COMMON.LABEL.CURRENCY_MEASUREMENT': 'Unidade de Moeda',
  'SM.PROJECT.TITLE.EXPORT_CURRENT_ORG_EMPLOYEE': 'Exportar tudo',
  'SM.ORGSTAFF.LABEL.DEPTMACRESTRICT': 'Restrição de endereço MAC',
  'SM.ORGSTAFF.LABEL.REGION_DAYLIGHTSAVINGTIME': 'horário',
  'SM.ORGSTAFF.MESSAGE.BE_CANNOTDELETECURRENCY': 'A moeda padrão não pode ser excluída.',
  'SM.ORGSTAFF.TITLE.CONTACT_FULL_NAME': 'Nome completo',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_VST': 'TIPO DE TIMEOUT DE SESSÃO',
  'SM.COMMON.LABEL.CURRENCY_MEASUREMENT_LIST': 'Unidade de Medição da Moeda',
  'SM.COMMON.LABEL.MEASUREMENT_CONV_LIST': 'Lista de conversão de medida',
  'SM.COMMON.MESSAGE.VALIDATE_MINLENGTHB': 'Insira pelo menos 0 caracteres.',
  'SM.ORGSTAFF.LABEL.REGION_DESCRIPTION': 'Descrição',
  'SM.COMMON.LABEL.BANKCARD_ACCT_LENG_PARAM': 'Comprimento do número do cartão',
  'SM.COMMON.LABEL.SURVEY_CLASS_SORT': 'Seqüência de classificação',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_IP_TYPE': 'Tipo de IP',
  'SM.COMMON.LABEL.CURRENCY_DISPFORMAT_DISPLAYTYPE': 'Tipo de exibição',
  'SM.COMMON.LABEL.SYSMENU_CONFIGURESYSMENU': 'Menu Configurar Sistema',
  'SM.ORGSTAFF.LABEL.TEAM_ORG_EMPLOYEE': 'organizar funcionários',
  'SM.ORGSTAFF.LABEL.REGION_DST_ENDDATE': 'Data Final do DST',
  'SM.ORGSTAFF.LABEL.ORG_STOP_TIPS':
    'Após suspensão, organização ou organização e sua organização subordinada estariam indisponíveis. Confirma-se para suspender?',
  'SM.ORGSTAFF.TITLE.OPPAUTH_TITLE_MUTEXAUTH_INFOLIST': 'Lista de permissões exclusivas',
  'SM.COMMON.LABEL.ATTACHMENT_DELETE_INFO': 'Tem certeza de que deseja excluir o arquivo?',
  'SM.ORGSTAFF.OPTION.STAFF_REMOVE_PERMISSION': 'Remover permissão do operador alvo',
  'SM.COMMON.LABEL.MEASUREMENT_EXPORT_SUCCESS': 'Successo',
  'SM.ORGSTAFF.TITLE.CONTACT_TITLE': 'Título',
  'SM.ORGSTAFF.BUTTON.EMPLOYEE_CANCLE': 'Cancelar',
  'SM.ORGSTAFF.LABEL.REGION_DST_SWITCHINTERVAL': 'Deslocamento DST',
  'SM.COMMON.LABEL.SYSMENU_AFFECTED_MENU': 'Menu afetado',
  'SM.ORGSTAFF.MESSAGE.ENDEARLIERSTART': 'A data inicial não pode ser posterior à data final.',
  'SM.COMMON.LABEL.MEASUREMENT_UPLOAD_FILE': 'Carregar arquivo',
  'SM.COMMON.LABEL.SYSMENU_USEPARENT': 'Usar dados pai',
  'SM.COMMON.LABEL.EDIT_CURRENCY_EXCHANGE_RATE': 'Modificar taxa de câmbio',
  'SM.COMMON.LABEL.HOLIDAY_FRIDAY': 'Sexta-feira',
  'SM.COMMON.LABEL.CURRENCY_TYPE_SYMBOL': 'Símbolo',
  'SM.COMMON.LABEL.SYSMENU_MENUNAME': 'Nome do menu',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_UPLOAD_SIZE_MAX':
    'O arquivo carregado excedeu o tamanho máximo 0 KB.',
  'SM.COMMON.LABEL.SURVEY_ITEM_NAME': 'Nome do item',
  'SM.COMMON.TIPS.POPUP_INFO_TITLE': 'Informações',
  'SM.COMMON.LABEL.CHARGECODE_STATUS': 'Status',
  'SM.COMMOM.MESSAGE.SYSMENU_MENU_STATUS_NOT_CONSISTENT': 'O status do menu não é consistente.',
  'SM.COMMON.MESSAGE.ERATE_NUMBER_INVALID': 'Número inválido',
  'SM.COMMON.LABEL.SURVEY_QUESTIONNAIRE_HEAD': 'Cabeça do questionário',
  'SM.COMMON.LABEL.MODULE_SHORTNAME': 'Nome abreviado',
  'SM.ORGSTAFF.LABEL.REGION_DST_MONTHDAY': 'Month/Day',
  'SM.TENANTSPACE.AUTHTYPE.TITLE': 'Tipo de autenticação',
  'SM.ORGSTAFF.MESSAGE.DSTOFFSET.OUTRANAGE': 'O deslocamento DST não está na faixa de 1 a 86400.',
  'SM.ORGSTAFF.LABEL.WORKGROUP_CONTENTS': 'Conteúdo do caminho do grupo de trabalho',
  'SM.ORGSTAFF.MESSAGE.SELECT_EMPLOYEE': 'Selecione funcionário',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_NEW_PASSWORD': 'Nova senha',
  'SM.COMMON.MESSAGE.EXPORT_VIEW':
    ', vá para o menu "Gerenciamento do sistema > Configuração baseada no projeto > Projeto" para visualizar os resultados exportados.',
  'SM.ORGSTAFF.LABEL.TEAM_TEAMLEVER': 'Nível da equipe',
  'SM.ORGSTAFF.LABEL.ROLE_TYPE_PUB': 'Função pública',
  'SM.ORGSTAFF.TITLE.BE_CURRENCY': 'Moeda',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_QUERY_LIST': 'Lista de funcionários',
  'SM.COMMON.BUTTON.MEASUREMENT_ADD': 'Novo',
  'SM.COMMON.LABEL.SEARCH_NAME': 'Insira o nome',
  'SM.COMMON.LABEL.HOLIDAY_HOLIDAYDAY': 'feriado',
  'SM.ORGSTAFF.MESSAGE.COMMON_TIPS_STAFF_SELECT_NO_PERIOD': 'Selecionar Funcionário',
  'SM.ORGSTAFF.TITLE.ASSIGNED_MANAGER': 'Gerentes atribuídos',
  'SM.TENANTSPACE.LDAP_RDNPREFIX': 'Prefixo RDN',
  'SM.ORGSTAFF.MESSAGE.EXPORT_LIMIT':
    'O número de exportados mais de 10,000 não é recomendado para exportação.',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_BE': 'BE',
  'SM.COMMON.LABEL.OPERLOG_ENDTIME': 'Hora de fim',
  'SM.COMMON.LABEL.CURRENCY_TYPE_CODE': 'Código',
  'SM.ORGSTAFF.BUTTON.DELETE': 'Excluir',
  'SM.COMMON.BUTTON.OK': 'OK',
  'SM.ORGSTAFF.BUTTON.ADDR_ADD': 'Adicionar',
  'SM.COMMON.LABEL.CHARGECODE_CODE': 'Código',
  'SM.ORGSTAFF.BUTTON.ADDR_CREATE': 'Novo',
  'SM.COMMON.LABEL.SURVEY_APPLICATION_SCOPE': 'Escopo do aplicativo',
  'SM.COMMON.TITLE.SYSMENU_ERROR': 'Erro',
  'SM.COMMON.LABEL.SYSMENU_DEACTIVATED': 'Descartar',
  'SM.COMMON.LABEL.SURVEY_OPTION_NAME': 'Nome das opções',
  'SM.ORGSTAFF.LABEL.ORG_SHORT_NAME': 'Nome abreviado OU',
  'SM.ORGSTAFF.BUTTON.EMPLOYEE_CLEAR': 'Limpar',
  'SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE_CREATE': 'Novo questionário',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_DESC': 'Descrição',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ROAM_VALID': 'válido',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_EMAIL_VALIDATOR': 'E-mail inválido',
  'SM.COMMON.LABEL.CHARGECODE_TEST': 'Teste',
  'SM.COMMON.LABEL.SURVEY_CREATED_TIME': 'Criado em',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ACCOUNTRANGE': 'Faixa de conta',
  'SM.ORGSTAFF.LABEL.REGION_DST_MONTHWEEKDAY': 'Mês/Semana/Dia',
  'SM.ORGSTAFF.LABEL.TEAM_STATUS': 'Status',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_UPLOAD_EXT_TITLE':
    'Falha ao carregar. Formatos de arquivo permitidos: 0; Formatos de arquivo não permitidos: {1}.',
  'SM.ORGSTAFF.MESSAGE.ERROR_QUERY': 'Falha ao consultar.',
  'SM.COMMON.LABEL.BANKCARD_BANKTYPE_PARAM': 'Tipo Banco',
  'SM.COMMON.LABEL.CHARGECODE': 'Código de cobrança',
  'SM.ORGSTAFF.TITLE.ORG_ASSIGNED_EMP': 'Funcionários Atribuídos',
  'SM.ORGSTAFF.MESSAGE.SAVE_SUGGESTION': 'Informações',
  'SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE_EDIT': 'Modificar Questionário',
  'SM.COMMON.MESSAGE.CHARGECODE_PERFORM_DELETION': 'Deseja realmente excluir?',
  'SM.COMMON.BUTTON.CHARGECODE_ADD': 'Novo',
  'SM.COMMON.MESSAGE.ERATE_CODE_COMPARE':
    'O código da moeda de origem não pode ser o mesmo que o código da moeda de destino.',
  'SM.ORGSTAFF.LABEL.BANK_LEVEL': 'Nível bancário',
  'SM.ORGSTAFF.LABEL.RESTRICTTYPE': 'Tipo de restrição de login',
  'SM.COMMON.LABEL.HOLIDAY_WEDNESDAY': 'Qua',
  'SM.ORGSTAFF.TITLE.CONTACT_PHONE': 'Número de telefone',
  'SM.ORGSTAFF.LABEL.BE_ADDRESS_FORMAT': 'Modelo de Formato de Endereço',
  'SM.ORGSTAFF.LABEL.ORG_DISPLAYORDER': 'Seqüência de exibição',
  'SM.ORGSTAFF.LABEL.PWD_UNMATCH_RULE': 'A senha não está de acordo com as regras.',
  'SM.COMMON.LABEL.SYSMENU_LABEL': 'Menu Sistema',
  'SM.COMMON.MESSAGE.AREA_DELETE_INFO': 'Deseja realmente excluir?',
  'SM.ORGSTAFF.TITLE.STAFF_ROLE_ASSIGN': 'Atribuir função',
  'SM.ORGSTAFF.OPTION.WEEKDAY_FRI': 'Sexta-feira',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_RESETPWD': 'Redefinir senha',
  'SM.COMMON.LABEL.MODULE_MODULE': 'Módulo',
  'SM.ORGSTAFF.TITLE.STAFF_LOGIN_RESTRICT': 'Restrição de login',
  'SM.COMMON.LABEL.MODULE_ADDRESS': 'URL',
  'SM.ORGSTAFF.TITLE.CURRENCY_DISPLAY_FORMAT': 'Formato de exibição da Moeda',
  'SM.COMMON.LABEL.MEASUREMENT_IMPORT_RESULT': 'Resultado de importação',
  'SM.ORGSTAFF.OPTION.STAFF_IP_SEGMENT': 'Segmento de endereço IP',
  'SM.COMMON.TITLE.ACCESS.SELECT_STAFF': 'Selecionar Funcionários de Acesso',
  'SM.COMMON.MESSAGE.SYSMENU_ACCESS_TIME_ERROR':
    'O tempo de término do acesso ao menu deve ser maior do que o tempo de início.',
  'SM.ORGSTAFF.MESSAGE.CONTAINS_SUB_ORGS': 'A organização contém suborganizações.',
  'SM.ORGSTAFF.TITLE.ORG_ACCESS_EMP': 'Acessar funcionário atribuído',
  'SM.COMMON.LABEL.BANKCARD_ACCT_LENG': 'Comprimento do número do cartão',
  'SM.COMMON.LABEL.SYSMENU_VISITCOUNT': 'Contagem de visitas',
  'SM.ORGSTAFF.BUTTON.OURELATIONSHIP_RELOAD': 'Atualizar',
  'SM.COMMON.LABEL.OPERLOG_ACCOUNT': 'Conta',
  'SM.COMMON.LABEL.BANKCARD_SEARCH_CONDITION': 'Condição de pesquisa',
  'SM.COMMON.LABEL.ATTACHMENT_ADD_ACCESSORY': 'Novo',
  'SM.COMMON.MESSAGE.CHARGECODE_TO_TEST':
    'Tem certeza de que deseja alterá-la para o estado Teste?',
  'SM.COMMON.LABEL.SYSMENU_MENUURL': 'URL do menu',
  'SM.ORGSTAFF.AUTHTYPE.LABEL': 'Tipo de autenticação',
  'SM.COMMON.LABEL.ERATE_TODATE': 'Para',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_TEAMINFO': 'Informações da Equipe',
  'SM.COMMON.TIPS.HOME_PHONE':
    'Insira o número de telefone correto, por exemplo. (+)(0123-)1234567890(-0123)',
  'SM.COMMON.LABEL.CURRENCY_MEASUREMENT_TITLE': 'Unidade de Medição da Moeda',
  'SM.COMMON.LABEL.SURVEY_CLASS_Code': 'Código de classificação',
  'SM.COMMON.LABEL.SEARCHCONDITION': 'Pesquisar critérios',
  'SM.ORGSTAFF.MESSAGE.SAVESUCCESS': 'Operação executada com sucesso.',
  'SM.TENANTSPACE.LDAP_DOMAIN': 'Domínio',
  'SM.ORGSTAFF.LABEL.ORGANIZATION': 'Unidade da Organização',
  'SM.COMMON.BUTTON.ADD_MENU_ACCESS_TIME':
    'Adicionar tempo de acesso ao menu. Se não houver configuração, o menu pode ser acessado a qualquer momento.',
  'SM.AUC.LABEL.BANK_NAME_INPUT': 'Nome do banco de entrada',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_RESET_SUCCESS':
    'A senha do funcionário foi alterada com êxito. O seguinte não pode ser alterado:',
  'SM.ORGSTAFF.LABEL.ORGIMPORT_TIP':
    'Certifique-se de que as informações da operadora foram importadas para o sistema e que o operador atual seja o administrador.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ACCOUNT_VALIDATOR_ADD':
    "O valor pode conter apenas dígitos, letras,  sublinhados (_), hífen, pontos (.), e em sinais ({'@'}).",
  'SM.COMMON.LABEL.MENU_INFO_TITLE': 'Menu Informações Básicas',
  'SM.COMMON.LABEL.HOLIDAY_YEAR': 'Ano',
  'SM.COMMON.MESSAGE.DELETE_CONFIRM': 'Deseja realmente excluir?',
  'SM.COMMON.LABEL.SYSMENU_ACTIVE_A': 'Ativar',
  'SM.COMMON.LABEL.HOLIDAY_SATURDAY': 'Sábado',
  'SM.COMMON.BUTTON.SURVEY_ENABLE': 'Ativado',
  'SM.ORGSTAFF.TITLE.OPERATION': 'Operação',
  'SM.ORGSTAFF.MESSAGE.FORBID_EXSIT_EMPLOYEE':
    'Falha ao desabilitar o departamento permanentemente porque há funcionários sob o departamento.',
  'SM.ORGSTAFF.LABEL.AUTHVIEW_AUTH_BENAME': 'Página inicial da permissão BE',
  'SM.COMMON.TIPS.MENU_REBUILD': 'Informações',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_SUSPEND': 'Suspensar',
  'SM.ORGSTAFF.LABEL.REGION_SHORTNAME': 'Nome abreviado da região',
  'SM.ORGSTAFF.LABEL.STAFF_ADD_ROLE': 'Adicionar função de relacionamento',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_ATTACHMENTID': 'Anexo',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_NOT_ALLOW_4A': 'Permitir login Diretamente na página da Web',
  'SM.COMMON.LABEL.CURRENCY_TYPE_NAME': 'Nome',
  'SM.ORGSTAFF.OPTION.STAFF_RESTRICTIONTYPE_ALLOWED': 'Permitir',
  'SM.COMMON.LABEL.OPERLOG_RESET': 'Redefinir',
  'SM.COMMON.LABEL.CHARGECODE_NAME': 'Nome',
  'SM.ORGSTAFF.LABEL.STAFF_DESC': 'Descrição',
  'SM.ORGSTAFF.LABEL.TEAM_DESCRIPTION': 'Descrição',
  'SM.COMMON.MESSAGE.DATADICT_DELETE_FAILED': 'Falha na exclusão.',
  'SM.COMMON.LABEL.BANKCARD_NAME': 'Nome do cartão',
  'SM.COMMON.BUTTON.CANCEL': 'Cancelar',
  'SM.COMMON.LABEL.ATTACHMENT_DEL_SUCCESS': 'Arquivo acessório excluído com sucesso',
  'SM.ORGSTAFF.LABEL.TO_BE_ASSIGNED_ROLE': 'A ser atribuída função',
  'SM.COMMON.LABEL.SYSMENU_STATUS': 'Status',
  'SM.ORGSTAFF.OPTION.STAFF_RETAIN_PERMISSION': 'Manter permissão do operador alvo',
  'SM.COMMON.LABEL.MEASUREMENT_ENTITY_ID': 'ID',
  'SM.COMMON.LABEL.MEASUREMENT_UNIT_SUNIT': 'Unidade padrão',
  'SM.COMMON.MESSAGE.PROPERTY_SUCCESS_CREATE_INFO': 'Operação executada com sucesso.',
  'SM.ORGSTAFF.BUTTON.TEAM_OK': 'OK',
  'SM.COMMON.MESSAGE.CHARGECODE_DELETE_DATA':
    'Tem certeza de que deseja excluir o código de cobrança?',
  'SM.COMMON.MESSAGE.VALIDATE_RANGELENGTHB': 'Insira um valor de 0 a 1 caracteres.',
  'SM.COMMON.LABEL.OPERLOG_OBJECT_TYPE': 'Tipo de objeto',
  'SM.COMMON.BUTTON.BANKCARD_CANCEL': 'Cancelar',
  'SM.ORGSTAFF.TITLE.BE_ADDRESSSETTING': 'Endereço',
  'SM.ORGSTAFF.LABEL.ORG_EMPLOYEE_QUERY_INFO': 'Pesquisar critérios',
  'SM.COMMON.LABEL.MEASUREMENT_EXPORT_MEA': 'Medida',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_UPLODATPERSON': 'Carregado por',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_SEARCH': 'Pesquisar',
  'SM.COMMON.BUTTON.MENUVSEARCH': 'Recriar Índice de Menu',
  'SM.ORGSTAFF.TITLE.CONTACT_HOME_FAX': 'Fax Inicial',
  'SM.COMMON.LABEL.CREATE_OPER': 'Criado por',
  'SM.COMMON.LABEL.ERATE_DCURRENCY_CODE': 'Código da Moeda de destino',
  'SM.COMMON.LABEL.CURRENCY_DISPLAY_POSTFIX': 'Exibir pós fixo',
  'SM.ORGSTAFF.LABEL.STAFF_ROLE_DESC': 'Descrição',
  'SM.ORGSTAFF.TITLE.ADDRESS_DISPLAY_FORMAT': 'Formato de exibição de endereços',
  'SM.ORGSTAFF.TITLE.ADDR_NAME': 'Nome',
  'SM.COMMON.LABEL.MEASUREMENT_ENTITY_CODE': 'Código da unidade',
  'SM.COMMON.MESSAGE.SYSMENU_DELSUCCESS': 'Excluído com sucesso',
  'SM.ORGSTAFF.LABEL.INPUT_ROLE_NAME': 'Insira o nome da função',
  'SM.ORGSTAFF.TIPS.DESC': 'Não inserir caracteres especiais <>',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD':
    'Falha ao verificar a senha do funcionário.',
  'SM.ORGSTAFF.TITLE.CONTACT_OFFICE_FAX': 'Fax do escritório',
  'SM.COMMON.LABEL.CURRENCY_FORMAT_DISPLAY': 'Formato de visualização',
  'SM.ORGSTAFF.AUTHTYPE.LDAP': 'LDAP',
  'SM.PROJECT.TITLE.IMPORT_ORG_PAR': 'Importar pacote Par',
  'SM.ORGSTAFF.MESSAGE.DSTFLAG_WITHOUT_TIMEZONE':
    'Nenhum fuso horário não pode abrir o horário de verão.',
  'SM.COMMON.LABEL.MEASUREMENT_CONV_EDIT': 'Modificar conversão da unidade de medida',
  'SM.ORGSTAFF.BUTTON.BE_COPYPARENT': 'Copiar dados do nó pai',
  'SM.ORGSTAFF.LABEL.ORG_FORBID': 'Desativar',
  'SM.ORGSTAFF.LABEL.STAFF_ROLE_TYPE': 'Tipo de função',
  'SM.COMMON.LABEL.CURRENCY_MEASUREMENT_ID': 'ID',
  'SM.COMMON.LABEL.OPERLOG_STARTTIME': 'Hora de Início',
  'SM.ORGSTAFF.MESSAGE.REGION_VALIDATE_TIMESAME':
    'A hora inicial e a hora final de um fuso horário não podem ser a mesma.',
  'SM.COMMON.LABEL.HOLIDAY_SUNDAY': 'Sol',
  'SM.COMMON.TIPS.ERATE_ERATE_TIPS':
    'Insira uma cadeia numérica positiva com um máximo de 13 casas integrais e 6 casas decimais e a última não pode ser 0.',
  'SM.ORGSTAFF.LABEL.REGION_CODE': 'Código da região',
  'SM.ORGSTAFF.TITLE.REGION_ADMINREGION': 'Região Administrativa',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_NATIONALITY': 'Nacionalidade',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_VALUE_TYPE': 'Tipo de valor',
  'SM.COMMON.MESSAGE.OPERATESUCCESS': 'Operação executada com sucesso.',
  'SM.ORGSTAFF.TITLE.SUCCESS': 'Informações',
  'SM.COMMON.LABEL.MEASUREMENT_UNIT_RATIO': 'Taxa de unidade de medida',
  'SM.COMMON.TITLE.SURVEY_QUESTION_NAME_INFO': 'Informações sobre nome da pergunta',
  'SM.COMMON.BUTTON.RESET': 'Redefinir',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ACCOUNT_PASSWORD': 'a senha atual',
  'SM.COMMON.BUTTON.ADD_ADD': 'Adicionar',
  'SM.COMMON.LABEL.ATTACHMENT_SAVE_SUCCESS': 'Anexo adicionado com sucesso.',
  'SM.ORGSTAFF.TITLE.TEAM_NAMEEXIST': 'O nome da equipe já existe.',
  'SM.ORGSTAFF.LABEL.TEAM_ACCOUNTRANGE': 'Faixa de conta',
  'SM.COMMON.BUTTON.CHARGECODE_SAVE': 'Salvar',
  'SM.COMMON.LABEL.OPERLOG_EMPLOYEE_NAME_NORMAL': 'Nome do funcionário',
  'SM.COMMON.LABEL.BANKCARD_BANKCODE': 'Código Bancário Emissor de Cartão',
  'SM.COMMON.LABEL.SURVEY_INTERLOCUTION': 'Perguntas e respostas',
  'SM.COMMON.BUTTON.MEASUREMENT_DELETE': 'Excluir',
  'SM.ORGSTAFF.LABEL.ORG_IMPORT': 'Importação',
  'SM.COMMON.MESSAGE.CHARGECODE_TO_RELEASE':
    'Tem certeza de que deseja alterá-la para o estado Liberar?',
  'SM.COMMON.LABEL.CHARGECODE_FORBIDEN_BE': 'BE proibido',
  'SM.COMMON.MESSAGE.OPERATE_FAIL': 'Falha na operação.',
  'SM.COMMON.MESSAGE.CURRENCY_MEASUREMNET_CONFIRMCOVERMSG':
    'O tipo de moeda atual já possui uma unidade monetária padrão ou unidade monetária mínima. Tem certeza de que deseja continuar?',
  'SM.ORGSTAFF.TITLE.RELATION_CONFIRM_RECOVER':
    'Após ter sido retomada, a relação organizacional e sua relação subordinada estarão disponíveis, Você confirmará a retomar?',
  'SM.ORGSTAFF.LABEL.ACCESS.USER_REQUIRED': 'Selecione um funcionário.',
  'SM.ORGSTAFF.TITLE.REGION_IPRANGE': 'Segmento IP',
  'SM.ORGSTAFF.BUTTON.REMOVE_OUT': 'Remover fora',
  'SM.ORGSTAFF.TITLE.ADDR_DESCRIPTION': 'Descrição',
  'SM.ORGSTAFF.TIPS.LOGINID': 'Não introduza caracteres especiais, exceto @.',
  'SM.COMMON.LABEL.SURVEY_CLASS_SORT_DESCRIPTION': 'O intervalo de entrada é de 1 a 100',
  'SM.ORGSTAFF.TITLE.COMMON_QUERYCONDITION_TITLE': 'Pesquisar critérios',
  'SM.ORGSTAFF.LABEL.ORG_SELECTREGION': 'Selecionar região',
  'SM.ORGSTAFF.MESSAGE.ERROR.SAVE': 'Erro',
  'SM.ORGSTAFF.MESSAGE.BE_SELECTREGION': 'Selecionar região',
  'SM.ORGSTAFF.MESSAGE.BE_MAKE_SURE_DELETE': 'Tem certeza de que deseja excluir o membro?',
  'SM.ORGSTAFF.MESSAGE.ADDR_DELETE_CONFIRM': 'Deseja realmente excluir?',
  'SM.ORGSTAFF.MESSAGE.REGION_VALIDATE_IPEXIST': 'O endereço IP é duplicado.',
  'SM.COMMON.TITLE.SUCCESS': 'Informações',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_IS_LOCKED': 'O funcionário foi bloqueado.',
  'SM.ORGSTAFF.TITLE.ASSIGNE_EMPLOYEE': 'Atribuir funcionário',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PWDRULEGROUP': 'Grupo de Regras de Conta e Senha',
  'SM.COMMON.BUTTON.MEASUREMENT_EXPORT': 'Exportar',
  'SM.ORGSTAFF.BUTTON.EMPLOYEE_SELECTORG': 'Selecionar OU',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_ATTACHMENTDESC': 'Descrição',
  'SM.ORGSTAFF.TITLE.IMPORT_MAC_TITLE': 'Importar restrição de login',
  'SM.ORGSTAFF.OPTION.WEEKLIST_FTH': 'Quarto',
  'SM.COMMON.MESSAGE.SAVESUCCESS': 'Operação executada com sucesso.',
  'SM.ORGSTAFF.LABEL.TEAM_EMPLOYEE': 'Funcionário',
  'SM.ORGSTAFF.BUTTON.FORBID_FOREVER': 'Desativar permanentemente',
  'SM.COMMON.LABEL.CURRENCY_GROUPSIZE': 'Tamanho do grupo',
  'SM.COMMON.LABEL.OPERLOG_OBJECT_ID': 'ID do objeto',
  'SM.COMMON.LABEL.OPERLOG_RESULT': 'Resultado da operação',
  'SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE_TEMP': 'Questionário',
  'SM.COMMON.MESSAGE.OPERATEFAIL': 'Falha na operação.',
  'SM.ORGSTAFF.MESSAGE.BE_SUBBE':
    'BEs de nível inferior, OUs, equipes ou funções foram configurados para o BE.',
  'SM.ORGSTAFF.MESSAGE.STAFF_INFOAUTH_CONFIRM':
    'Por favor, confirme que as informações do funcionário inseridas foram autorizadas por mim.',
  'SM.COMMON.BUTTON.SURVEY_DELETE_QUESTION': 'Excluir pergunta',
  'SM.ORGSTAFF.TITLE.RELATION_CONFIRM_SUSPEND':
    'Após suspensão, a relação organizacional e sua relação subordinada não estarão disponíveis, Você confirma suspender?',
  'SM.ORGSTAFF.LABEL.STAFF_LOCALE': 'Default Language',
  'SM.COMMON.LABEL.SURVEY_QUESTIONNAIRE_STATUS': 'Status',
  'SM.ORGSTAFF.MESSAGE.SRC_ACCOUNT': 'A conta de origem não existe.',
  'SM.ORGSTAFF.MESSAGE.CONTACT_ACCOUNT': 'Conta',
  'SM.COMMON.LABEL.ACCESS_RELATION': 'Organização acessível',
  'SM.COMMON.TIPS.SPECIAL_CHAR_AVAILABLE': 'O valor de entrada deve ser dígito, alfa ou _.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_CREATE_FAILD':
    'Falha ao criar o funcionário. Confirme as informações da conta.',
  'SM.ORGSTAFF.OPTION.WEEKDAY_TUE': 'Terça-feira',
  'SM.ORGSTAFF.BUTTON.CANCEL': 'Cancelar',
  'SM.COMMON.BUTTON.BANKCARD_SAVE': 'Salvar',
  'SM.COMMON.TITLE.ERROR': 'Erro',
  'SM.ORGSTAFF.LABEL.REGION_DST_STARTDATE': 'Data Inicial do DST',
  'SM.COMMON.LABEL.AREANAME': 'Nome da área',
  'SM.ORGSTAFF.LABEL.TEAM_TEAMCODE': 'Código da equipe',
  'SM.COMMON.LABEL.MEASUREMENT_ENTITY_NAME': 'Nome da unidade',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ROAM_ORG': 'Organização de roam',
  'SM.COMMON.LABEL.BANKCARD_BANKCODE_PARAM': 'Código Bancário Emissor de Cartão',
  'SM.ORGSTAFF.TITLE.CONTACT_MOBILE_PHONE': 'Número do telemóvel',
  'SM.ORGSTAFF.LABEL.ORG_CHANNEL_CLASS': 'Classificação de Canal',
  'SM.COMMON.BUTTON.TOP': 'Para cima',
  'SM.AUC.LABEL.BANK_LIST': 'Lista Bancária',
  'SM.COMMON.TITLE.MEASUREMENT_STDLIST': 'Lista de sistemas de medição',
  'SM.COMMON.LABEL.ERATE_RANGEDATE': 'Período de validade',
  'SM.COMMON.LABEL.BANKCARD_OPERATION': 'Operação',
  'SM.ORGSTAFF.TITLE.BE_INFORMATION': 'Informações',
  'SM.ORGSTAFF.OPTION.MONTH_12': 'Dezembro',
  'SM.ORGSTAFF.OPTION.MONTH_11': 'Novembro',
  'SM.ORGSTAFF.MESSAGE.TIMEZONE_TIME_ERROR':
    'A hora inicial não pode ser maior ou igual à hora final.',
  'SM.ORGSTAFF.LABEL.REGION_LAYER': 'Camada de região',
  'SM.ORGSTAFF.OPTION.MONTH_10': 'Outubro',
  'SM.ORGSTAFF.OPTION.WEEKDAY_SAT': 'Sábado',
  'SM.ORGSTAFF.LABEL.REGION_NAME': 'Nome da região',
  'SM.COMMON.MESSAGE.SYSMENU_CONFIRM_MODIFY_MENU_STATUS_TO': 'Tem certeza de que deseja:',
  'SM.COMMON.LABEL.BANKCARD_BANKSIGN_LENG_PARAM': 'Comprimento do ID bancário emitente do cartão',
  'SM.COMMON.BUTTON.CHARGECODE_EDIT': 'Modificar',
  'SM.ORGSTAFF.LABEL.BE_ADDRESS_DISPLAYFORMAT': 'Modelo de Formato de Exibição de Endereço',
  'SM.COMMON.LABEL.HOLIDAY': 'feriado',
  'SM.ORGSTAFF.LABEL.ORG_CODE': 'Código OU',
  'SM.ORGSTAFF.LABEL.USER_COUNT': 'Contagem de usuários',
  'SM.COMMON.MESSAGE.MEASUREMENT_STANDARD_ERROR': 'Escolha a medida padrão correta.',
  'SM.ORGSTAFF.TITLE.ADDR_LINE_NUMBER': 'Número da Linha',
  'SM.COMMON.TIPS.DIGITS_ONLY': 'Insira dígitos.',
  'SM.COMMON.LABEL.BANKCARD_BANKNAME': 'Nome do banco emissor de cartão',
  'SM.ORGSTAFF.MESSAGE.ADDR_DELETE_ERROR': 'Erro de exclusão.',
  'SM.ORGSTAFF.TITLE.REGION_TIMEZONE': 'Fuso horário',
  'SM.COMMON.LABEL.OPERLOG_FAILUER': 'Falha',
  'SM.COMMON.LABEL.HIGHRISKLOG': 'Monitoramento de operação de alto risco',
  'SM.COMMON.LABEL.CURRENCY_MEASUREMENT_NAME': 'Unidade',
  'SM.ORGSTAFF.TITLE.CONTACT': 'Contato',
  'SM.ORGSTAFF.LABEL.BE_CODE': 'Código BE',
  'SM.COMMON.LABEL.SURVEY_SINGAL_CHOOSE': 'Opção única',
  'SM.ORGSTAFF.LABEL.BE_SHORT_NAME': 'BE Nome abreviado',
  'SM.ORGSTAFF.TITLE.CONTACT_ID': 'Número de contato',
  'SM.ORGSTAFF.LABEL.TEAM_CODE': 'Código OU',
  'SM.COMMON.MESSAGE.MODULE_MODULEADDRESSNOTMATCH': 'O endereço do módulo não corresponde.',
  'SM.COMMON.LABEL.CHARGECODE_RELEASE': 'release',
  'SM.COMMON.TITLE.SYSMENU_MENUNAME': 'Informações sobre o nome do menu',
  'SM.COMMON.LABEL.SYSMENU_SELECTORGANIZATION': 'Selecionar permissão',
  'SM.ORGSTAFF.LABEL.TEAM_SEARCH': 'Pesquisar',
  'SM.ORGSTAFF.TITLE.CHARGE_CODE': 'Código de cobrança',
  'SM.ORGSTAFF.LABEL.STAFF_ROLE_EFFECTIVE_OU_INPUT': 'Insira um nome OU',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ACCOUNT': 'Conta',
  'SM.PROJECT.TITLE.EXPORT_CURRENT_ORG_AND_SUB': 'Exportar item selecionado',
  'SM.COMMON.BUTTON.SURVEY_NEW_QUESTION_TYPE': 'Novo tipo de pergunta',
  'SM.ORGSTAFF.LABEL.BE_LANGUAGE': 'Idioma',
  'SM.COMMON.LABEL.HEADING_SEARCHCRITERIA': 'Pesquisar critérios',
  'SM.COMMON.LABEL.SYSMENU_SEARCH_RESULT': 'Resultado da pesquisa',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_TIMERANGEERROR':
    'O tempo de fim de sessão deve ser posteriormente, então o início de sessão. ligação do endereço MAC',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_RESET_FAILD': 'Falha na redefinição da senha do funcionário',
  'SM.ORGSTAFF.TITLE.STAFF_INFO_ADD': 'Adicionar funcionário',
  'SM.ORGSTAFF.OPTION.EMPLOYEE_DELETE_ROLE': 'Excluir função',
  'SM.ORGSTAFF.TITLE.ADDRESS_FORMAT': 'Formato do endereço',
  'SM.ORGSTAFF.LABEL.FUNCAUTH_OPPAUTHNAME': 'Permissão mutuamente exclusiva',
  'SM.COMMON.LABEL.CURRENCY_DECIMALPOSITIONS': 'Casas decimais',
  'SM.ORGSTAFF.BUTTON.INCREASE': 'Adicionar',
  'SM.ORGSTAFF.LABEL.TEAM_PARENTORG': 'OU pai',
  'SM.ORGSTAFF.TITLE.NEW_ORG': 'Criando uma organização',
  'SM.ORGSTAFF.TITLE.EDIT_ORG': 'Editar organização',
  'SM.ORGSTAFF.MESSAGE.INPUT_ORG_NAME': 'Insira o nome da organização',
  'SM.ORGSTAFF.TITLE.NEW_ACCESS_USER': 'Adicionar Funcionário de Acesso',
  'SM.ORGSTAFF.TITLE.NEW_MANAGER': 'Adicionar Supervisor',
  'SM.ORGSTAFF.TITLE.USER_ACCOUNT': 'Conta de funcionário',
  'SM.ORGSTAFF.MESSAGE.SEARCH_USERNAME': 'Pesquisar Nomes de Funcionários',
  'SM.ORGSTAFF.TITLE.SELECT_ROLE': 'Selecionar função',
  'SM.ORGSTAFF.MESSAGE.FOLLOW_TENANT': 'Seguir a configuração do locatário',
  'SM.ORGSTAFF.MESSAGE.SEARCH_ROLE_NAME': 'Pesquisar nomes de funções',
  'SM.ORGSTAFF.TIP.ACCESS_USER_DESC':
    'Funcionários que não fazem parte da organização, mas têm permissão para acessar e usar seus recursos',
  'SM.COMMON.BUTTON.CHARGECODE_SUCCESS': 'Informações',
  'SM.ORGSTAFF.LABEL.TEAM_MANAGER': 'Gerente',
  'SM.COMMON.LABEL.MEASUREMENT_EXPORT_COUNT': 'Quantidade de exportação',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_BIRTH': 'Data de nascimento',
  'SM.COMMON.LABEL.CREATE_TIME': 'Criado em',
  'SM.ORGSTAFF.TITLE.COMMON_CONTACT_INFORMATION': 'Informações de contato',
  'SM.ORGSTAFF.MESSAGE.STAFF_AUTHCOPY_PATTERN': 'Modo de cópia',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_IP': 'Endereço IP',
  'SM.COMMON.LABEL.MEASUREMENT_NO': 'Não',
  'SM.COMMON.TITLE.BANKCARD_ADD': 'Novo cartão bancário',
  'SM.ORGSTAFF.MESSAGE.STAFF_RESUME_CONFIRM':
    'Tem certeza de que deseja retomar a conta do funcionário?',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ONLINE_NAME': 'Funcionário',
  'SM.ORGSTAFF.MESSAGE.SELECT_REGION': 'Selecione uma região.',
  'SM.ORGSTAFF.LABEL.DEPTIPRESTRICT': 'Restrição IP',
  'SM.ORGSTAFF.LABEL.INPUT_REGION_NAME': 'Insira o nome da região',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_MIDDLENAME': 'Nome do meio',
  'SM.ORGSTAFF.TITLE.TEAM_OPERSUCCESS': 'Operação executada com sucesso.',
  'SM.COMMON.BUTTON.IMPORT': 'Importação',
  'SM.ORGSTAFF.LABEL.TEAM_TEAMNAME': 'Nome da equipe',
  'SM.ORGSTAFF.MESSAGE.BE_COPYCURRENCY': 'Tem certeza de que deseja copiar o nó pai?',
  'SM.ORGSTAFF.TITLE.REGION_ADDRESS': 'Endereço',
  'SM.ORGSTAFF.LABEL.STAFFAUTH_AUTHSOURCE': 'Fonte de permissão',
  'SM.ORGSTAFF.LABEL.BE_DESCRIPTION': 'Descrição',
  'SM.ORGSTAFF.LABEL.ORG_DELETE_CONFIRM':
    'Após a exclusão de uma organização, a conta correspondente da organização não pode ser restaurada e só pode ser recriada. excluir ou não?',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_NAME_EXIST':
    'Falha ao criar o funcionário porque a conta já existe no sistema.',
  'SM.COMMON.LABEL.BANKCARD_BANKSIGN': 'ID bancário emissor de cartão',
  'SM.COMMON.BUTTON.SURVEY_DELETE_QUESTION_TYPE': 'Excluir tipo de pergunta',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_RESET': 'Redefinir',
  'SM.COMMON.LABEL.NEW_CURRENCY_EXCHANGE_RATE': 'Nova taxa de câmbio',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_TO': 'Para',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_SAVE': 'Salvar',
  'SM.COMMON.LABEL.OPERLOG_SUCCESS': 'Successo',
  'SM.COMMON.MESSAGE.DATADICT_DELETE_SUCCESSFULLY': 'Excluído com sucesso',
  'SM.ORGSTAFF.LABEL.ORG_FORBID_TIPS': 'Tem certeza de que quer desabilitar a organização?',
  'SM.COMMON.TIPS.AREA_DELETE_SUCCESS': 'Excluído com sucesso',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_FIRSTNAME': 'Nome do primeiro',
  'SM.ORGSTAFF.TITLE.ERROR': 'Erro',
  'SM.ORGSTAFF.TITLE.REGION_ENDIP': 'IP final',
  'SM.ORGSTAFF.LABEL.ORG_NAME': 'Nome da OU',
  'SM.ORGSTAFF.LABEL.ORG_IMPORTDATA': 'Importar dados',
  'SM.COMMON.LABEL.SURVEY_DRAFT': 'Rascunho',
  'SM.ORGSTAFF.MESSAGE.REGION_CONFIRM_NOTUSEPARENT':
    'Se os dados do nó pai forem cancelados, a configuração do nó pai não será usada. Continuar?',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ORG': 'Organização Home',
  'SM.ORGSTAFF.LABEL.BANK_CODE': 'Código Bancário',
  'SM.COMMON.BUTTON.ADD': 'Novo',
  'SM.ORGSTAFF.TITLE.CONTACT_OFFICE_PHONE': 'Telefone do escritório',
  'SM.COMMON.LABEL.THE_MENU': '.',
  'SM.ORGSTAFF.TIPS.UNLOCK.USER': 'Insira a senha do operador atual para confirmação:',
  'SM.COMMON.MESSAGE.CURRENCY_DECIMALNOZEROFLAG': 'Remover Últimos Zeros',
  'SM.COMMON.LABEL.ERATE_EFFDATE': 'Tempo Efetivo.',
  'SM.COMMON.LABEL.HOLIDAY_IMPORTDATA': 'Importar dados',
  'SM.ORGSTAFF.LABEL.ONLINEOPERATORNUM': 'Total de Operadores Online',
  'SM.ORGSTAFF.LABEL.ORG_PARENTORG': 'OU pai',
  'SM.COMMON.LABEL.BANKCARD_NAME_PARAM': 'Nome do cartão',
  'SM.COMMON.LABEL.CHARGECODE_DRAFT': 'Rascunho',
  'SM.COMMON.LABEL.OPERLOG_OPER_TYPE': 'Tipo de operação',
  'SM.COMMON.TITLE.MEASUREMENT_INFO': 'Informações básicas',
  'SM.ORGSTAFF.TITLE.REGION_STARTIP': 'Iniciar IP',
  'SM.ORGSTAFF.LABEL.IMPORT_TEMPLATE': 'Importar modelo de arquivo',
  'SM.COMMON.LABEL.SURVEY_TITLE_NAME': 'Nome da pergunta',
  'SM.COMMON.LABEL.HOLIDAY_IMPORT': 'Importar feriado',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_9': 'Setembro',
  'SM.TENANTSPACE.LDAP_BASICDN': 'DN básico',
  'SM.TENANTSPACE.LDAP_TITLE': 'Autenticação LDAP',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_8': 'Agosto',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_7': 'Julho',
  'SM.ORGSTAFF.LABEL.BE_NAME': 'Nome BE',
  'SM.COMMON.LABEL.CHARGECODE_SEARCH_CRITERIA': 'Pesquisar critérios',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_6': 'Junho',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_5': 'Maio',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_4': 'Abril',
  'SM.ORGSTAFF.OPTION.WEEKLIST_SEC': 'Segundo',
  'SM.ORGSTAFF.LABEL.WORKGROUP_NAME': 'Nome do grupo de trabalho',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_3': 'Março',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_2': 'Fevereiro',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_1': 'Janeiro',
  'SM.ORGSTAFF.LABEL.ORG_STOP': 'Suspensar',
  'SM.COMMON.TIPS.CURRENCY_GROUPSIZE_TIPS':
    'Número de dígitos separados por separadores em uma quantidade. O valor 0 indica que a quantia não está separada por separadores.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_STATUS_NORMAL': 'Normal',
  'SM.COMMON.LABEL.BANKCARD_BANKNAME_PARAM': 'Nome do banco emissor de cartão',
  'SM.ORGSTAFF.TITLE.REGION_EX_ATTR': 'Atributos estendidos',
  'SM.COMMON.TITLE.SUCCESS_SUCCESS': 'Operação executada com sucesso.',
  'SM.COMMON.LABEL.MEASUREMENT_CONV_CREATE': 'Nova conversão de medição',
  'SM.ORGSTAFF.LABEL.TEAM_TEAMID': 'ID da equipe',
  'SM.ORGSTAFF.BUTTON.ORG_EDIT': 'Modificar',
  'SM.ORGSTAFF.TITLE.TEAM_ASSIGNEDEMPL':
    'Funcionários Atribuídos (Não pode vincular mais de 2000 funcionários.)',
  'SM.ORGSTAFF.TITLE.STAFF_INFO_EXT': 'Informações estendidas',
  'SM.ORGSTAFF.TITLE.SELECT_ORG': 'Selecionar grupo de trabalho',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_BIRTHPLACE': 'Local de nascimento',
  'SM.ORGSTAFF.MESSAGE.REFRESH_SUCCESS': 'Atualizado com sucesso.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ACCOUNT_VALIDATOR':
    'O valor pode conter apenas dígitos, letras, caracteres chineses, sublinhados (_), hífen, pontos (.), e em sinais (@).',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_IP_ADDR': 'Endereço IP',
  'SM.ORGSTAFF.TITLE.TEAM_DELETEFSUCCESS': 'Excluído com sucesso',
  'SM.ORGSTAFF.LABEL.ORG_INFO': 'Informações OU',
  'SM.COMMON.TIPS.CHARGECODE_NAME': 'O valor de entrada não pode conter caracteres especiais.',
  'SM.COMMON.MESSAGE.SYSMENU_AUTH_ASSIGNED_MUTIL_MENUS':
    'A permissão no menu foi atribuída a vários menus. Se você descartar o menu, todas as permissões serão revogadas. Continuar?',
  'SM.COMMON.LABEL.MEASUREMENT_IGNORE_IMPORT':
    'Durante a importação de arquivos em lote, se um arquivo não conseguir ser importado, a importação de arquivos subsequentes continuará.',
  'SM.COMMON.TIPS.POPUP_LABEL_DELETE': 'Deseja realmente excluir?',
  'SM.ORGSTAFF.AUTHTYPE.DEFAULT': 'Padrão',
  'SM.ORGSTAFF.LABEL.BE_BIZCODE': 'Código de negócios BE',
  'SM.COMMON.LABEL.SYSMENU_DIS_POSTION': 'Posição de exibição',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_STATUS_FORBID': 'Proibir',
  'SM.ORGSTAFF.LABEL.CREATESOURCE': 'Criar origem',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_FORBIDFOREVER': 'Desativar permanentemente',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ONLINE_CLIENT': 'IP do cliente',
  'SM.ORGSTAFF.TIPS.STAFF_LOCALE':
    'Linguagem de login padrão do funcionário que é usada quando o idioma não é selecionado ao fazer login.',
  'SM.COMMON.TITLE.MEASUREMENT_UNIT': 'Tipo de mensuração',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_UPLOAD_SIZE_EXT_TITLE':
    'O tamanho de um arquivo a ser carregado pode variar de {1} KB a 0 KB. {2} arquivos são permitidos, mas {3} arquivos não são.',
  'SM.SM.COMMON.TITLE.NO_CHARGECODE': 'Sem Código de Cobrança.',
  'SM.COMMON.BUTTON.ATTACHMENT_MGR_SEARCH_CRITERIA_QUERY': 'Pesquisar',
  'SM.ORGSTAFF.OPTION.WEEKLIST_FIV': 'Quinto',
  'SM.ORGSTAFF.LABEL.ATTR_ATTRSETTING': 'Atributos estendidos',
  'SM.COMMON.LABEL.HIGHRISKLOG_SERVICENAME': 'Nome do serviço',
  'SM.ORGSTAFF.MESSAGE.AUTHENTICATION': 'Verificar identidade',
  'SM.COMMON.MESSAGE.DATADICT_SURE_DELETE': 'Deseja realmente excluir?',
  'SM.ORGSTAFF.OPTION.WEEKLIST_FIR': 'Primeiro',
  'SM.COMMON.BUTTON.COMMON_ADD': 'Adicionar',
  'SM.COMMON.LABEL.SYS_MEASUREMENT_METRIC': 'Sinalizador do sistema métrico',
  'SM.ORGSTAFF.TIPS.BE_LOGO':
    'Selecione uma imagem PNG, GIF ou JPG cujo tamanho não exceda 50 KB e 145 * 50 pixels.',
  'SM.COMMON.BUTTON.CHARGECODE_ADDMEMBER': 'Adicionar código de cobrança',
  'SM.COMMON.LABEL.SURVEY_QUESTIONNAIRE_TAIL': 'cauda do questionário',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_MATCH_VALUE': 'Valor de correspondência',
  'SM.ORGSTAFF.LABEL.ORG_SUBTYPE': 'Tipo Sub-OU',
  'SM.ORGSTAFF.MESSAGE.SELECT_ORG': 'Selecione uma OU.',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_SEARCH_CRITERIA': 'Pesquisar critérios',
  'SM.COMMON.LABEL.SURVEY_ALLOW_INPUT': 'Permitir entrada manual',
  'SM.ORGSTAFF.LABEL.BE_ID': 'ID do BE',
  'SM.ORGSTAFF.LABEL.ROLE_TYPE_DUTY': 'Função de tarefas',
  'SM.COMMON.LABEL.SURVEY_MODIFIED_ACCOUNT': 'Modificado por',
  'SM.ORGSTAFF.LABEL.TEAM_TDISPLAYORDER': 'Seqüência de exibição',
  'SM.COMMON.LABEL.MENU_ACCESS_TIME': 'Tempo de acesso ao menu',
  'SM.COMMON.LABEL.ERATE_INFO_LIST': 'Lista de Taxas de Câmbio da Moeda',
  'SM.COMMON.LABEL.SYSMENU_LANGUAGE': 'Idioma',
  'SM.ORGSTAFF.BUTTON.IMPORT': 'Importação',
  'SM.COMMON.LABEL.MODULE_ID': 'ID',
  'SM.COMMON.LABEL.OPERLOG_OPER_TIME': 'Tempo de operação',
  'SM.COMMOM.MESSAGE.BESEXCEPTION':
    'A operação atual não pode ser concluída. Contate o administrador.',
  'SM.COMMON.LABEL.SURVEY_PROVINCE': 'Provincial',
  'SM.ORGSTAFF.LABEL.STAFF_ACCOUNT': 'Conta',
  'SM.ORGSTAFF.BUTTON.RESET_TEAM': 'Redefinir',
  'SM.ORGSTAFF.MESSAGE.USER_OWN_DUTY_ROLE_INVALID2':
    'que é maior que o número máximo, o número máximo é:',
  'SM.ORGSTAFF.MESSAGE.USER_OWN_DUTY_ROLE_INVALID1':
    'O número de função de dever próprio funcionário da organização:',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_SEARCH_CRITERIA_ATTACHMENTCODE': 'Tipo de anexo',
  'SM.ORGSTAFF.LABEL.ORG_FORCEDELETE': 'Excluir permanentemente',
  'SM.ORGSTAFF.TITLE.CURRENCY_TYPE': 'Tipo de Moeda',
  'SM.COMMON.LABEL.HIGHRISKLOGList': 'Monitoramento de operação de alto risco',
  'SM.COMMON.LABEL.SURVEY_TITLE_SORT': 'Seqüência de perguntas',
  'SM.ORGSTAFF.LABEL.BANK_NAME': 'Nome do Banco',
  'SM.COMMON.LABEL.CURRENCY_CREATE_TITLE': 'Tipo de Moeda Informações Básicas',
  'SM.ORGSTAFF.TIPS.REGION_DST_SWITCHINTERVAL_TIPS':
    'Unidade: segundo. Valores: 1 a 86400. Se este parâmetro ficar vazio, o valor padrão 3600 será usado.',
  'SM.COMMON.LABEL.SURVEY_MUTI_CHOOSE': 'Múltiplas escolhas',
  'SM.ORGSTAFF.OPTION.WEEKLIST_LST': 'Último',
  'SM.ORGSTAFF.TITLE.CURRENCY_EXCHANGE_RATE': 'Taxa de câmbio da Moeda',
  'SM.COMMON.LABEL.BANKCARD_BANKSIGN_LENG': 'Comprimento do ID bancário emitente do cartão',
  'SM.COMMON.LABEL.CHARGE_CODE_GROUP': 'Grupo de Código de Cobrança',
  'SM.COMMON.LABEL.CHARGECODE_GROUPTYPE': 'Tipo de grupo',
  'SM.COMMON.BUTTON.PROPERTY_CREATE_GROUP': 'Novos grupos de atributos estendidos',
  'SM.COMMON.MESSAGE.DATADICT_SAVE_SUCCESSFULLY': 'Operação executada com sucesso.',
  'SM.ORGSTAFF.TITLE.ADDR_CFG_LIST': 'Formato de exibição de endereços',
  'SM.ORGSTAFF.OPTION.WEEKDAY_THU': 'Quinta-feira',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ONLINE_SIGNTIME': 'Tempo de login',
  'SM.COMMON.LABEL.HOLIDAY_MONDAY': 'Seg',
  'SM.COMMON.LABEL.OPERLOG_MODULE': 'Módulo',
  'SM.COMMON.LABEL.SURVEY_OPTION_TYPE': 'Tipo de opção',
  'SM.ORGSTAFF.LABEL.ADDR_ADDRESS_NAME': 'Nome do endereço',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_RESTRICT_MODE': 'Modo restringir',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_TEAM': 'Equipe de Trabalho',
  'SM.ORGSTAFF.TITLE.CONTACT_INFO': 'Gerente',
  'SM.COMMON.LABEL.OPERLOG_OBJECT_TYP_NAME': 'Nome do tipo de objeto',
  'SM.COMMON.LABEL.SYSMENU_SELECT_MENUS': 'Menu selecionado',
  'SM.COMMON.BUTTON.SURVEY_NEW_QUESTION': 'Nova pergunta',
  'SM.COMMON.LABEL.MENU_ACCESS_END_TIME': 'Hora de fim',
  'SM.ORGSTAFF.LABEL.AFFILIATED_BE': 'BE afiliado',
  'SM.COMMON.LABEL.AREAINFO_MAINTAIN': 'Gerenciamento de código de área',
  'SM.ORGSTAFF.LABEL.REGION_LEVEL': 'Nível da região',
  'SM.ORGSTAFF.MESSAGE.REGION_VALIDATE_IPFORMATERROR': 'Endereço IP inválido',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_GENDER': 'Gênero',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_RESET_ALLSUCCESS':
    'sucesso da reinicialização de senha do funcionário',
  'SM.ORGSTAFF.TIPS.ENTER_ORGNAME': 'Insira o nome do grupo de trabalho.',
  'SM.COMMON.LABEL.SYSMENU_STATUSDATE': 'Data de status',
  'SM.COMMON.LABEL.CURRENCY_MEASUREMENT_RATE': 'Taxa',
  'SM.ORGSTAFF.TITLE.CONFIRM_DEL': 'Confirmar',
  'SM.COMMON.LABEL.COLON': ':',
  'SM.COMMON.BUTTON.ACCESS_RELATION': 'Adicionar organização de acesso',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_MARITALSTATUS': 'Estado conjugal',
  'SM.COMMON.BUTTON.PROPERTY_CREATE': 'Novo',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_STATUS_PAUSE': 'Desativado',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_SECONDMENT_STARTDATE': 'Data Inicial',
  'SM.COMMON.BUTTON.ATTACHMENT_ADDACCESSORY': 'Salvar',
  'SM.COMMON.LABEL.MEASUREMENT_SOUMEASYS': 'Sistema de medição de origem',
  'SM.ORGSTAFF.MESSAGE.DELETE_TITLE': 'Confirmar',
  'SM.COMMON.LABEL.ERATE_EXPDATE': 'Horário de expiração',
  'SM.COMMON.TITLE.PRJ_EXPORT': 'Começaram a exportar',
  'SM.ORGSTAFF.LABEL.STAFF_AUTHCOPY_TARGETNO': 'Conta alvo',
  'SM.ORGSTAFF.TITLE.ERROR_TITLE': 'Erro',
  'SM.COMMON.MESSAGE.ERATE_TIME_COMPARE': 'A hora de fim deve ser posterior à hora inicial.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_RESET': 'redefinição de senha do funcionário',
  'SM.ORGSTAFF.LABEL.ADDR_DISP_DETAIL_LIST': 'Lista de detalhes de formato',
  'SM.ORGSTAFF.MESSAGE.TEAM_BLANK_VIEW': 'Gerencie equipes na árvore OU.',
  'SM.COMMON.LABEL.BANKCARD_CREATETIME': 'Adicionando Tempo',
  'SM.ORGSTAFF.OPTION.EMPLOYEE_ADD_ROLE': 'Adicionar função',
  'SM.ORGSTAFF.LABEL.STAFF_INFO_BASIC': 'Informações básicas',
  'SM.ORGSTAFF.OPTION.STAFF_IP_SINGLE': 'Endereço IP único',
  'SM.COMMON.TITLE.CONFIRM_DEL': 'Confirmar',
  'SM.ORGSTAFF.OPTION.MONTH_9': 'Setembro',
  'SM.ORGSTAFF.OPTION.MONTH_8': 'Agosto',
  'SM.ORGSTAFF.OPTION.MONTH_7': 'Julho',
  'SM.COMMON.TITLE.SURVEY_CLASS_NAME_INFO': 'Informações sobre nome da classificação',
  'SM.COMMON.MESSAGE.SYSMENU_AUTHIDREQUIRE': 'Insira o ID de permissão do menu.',
  'SM.ORGSTAFF.OPTION.MONTH_6': 'Junho',
  'SM.ORGSTAFF.OPTION.MONTH_5': 'Maio',
  'SM.ORGSTAFF.OPTION.MONTH_4': 'Abril',
  'SM.ORGSTAFF.TITLE.MEASUREMENT': 'Unidade de mensuração',
  'SM.ORGSTAFF.MESSAGE.ORG_NOT_EXSIT': 'A OU não existe.',
  'SM.ORGSTAFF.OPTION.MONTH_3': 'Março',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_EMPLOYEENAME': 'Funcionário',
  'SM.ORGSTAFF.OPTION.MONTH_2': 'Fevereiro',
  'SM.COMMON.LABEL.CURRENCY_TYPE_ID': 'ID',
  'SM.ORGSTAFF.OPTION.MONTH_1': 'Janeiro',
  'SM.COMMON.TIPS.BANKCARD_LENGTH': 'Insira um número inteiro contendo não mais do que 2 dígitos.',
  'SM.ORGSTAFF.TITLE.TEAM_SAVESUCCESS': 'Operação executada com sucesso.',
  'SM.ORGSTAFF.LABEL.BE_LOGO': 'Logotipo',
  'SM.ORGSTAFF.MESSAGE.MAX_TREE_LEVEL': 'Você só pode criar até 6 níveis de suborganizações.',
  'SM.PROJECT.TITLE.EXPORT_SELECTED_EMPLOYEE': 'Exportar item selecionado',
  'SM.ORGSTAFF.LABEL.TEAM_INCLUDESUB': 'Incluir sub-OU',
  'SM.ORGSTAFF.LABEL.ORG_LEVEL': 'Nível OU',
  'SM.ORGSTAFF.TITLE.EMPTY': 'Email e ContactPhone não podem estar vazios',
  'SM.ORGSTAFF.TITLE.COMMON_STAFF_INFO': 'Lista de Contas de Funcionário',
  'SM.COMMON.TITLE.ACCESS.SELECT_ALL_STAFF': 'Selecione todos os funcionários da organização',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_SEARCH_CRITERIA_ATTACHMENTNAME': 'Nome do anexo',
  'SM.COMMON.LABEL.SYSMENU_IDEL': 'release',
  'SM.COMMON.LABEL.SYSMENU_MENUID': 'ID do menu',
  'SM.ORGSTAFF.LABEL.ROLETMPL_PERMISSION_NAME': 'Nome da permissão da função',
  'SM.COMMON.LABEL.CURRENCY_TYPE_INPUT_TIPS': 'Introduza o nome do tipo de moeda',
  'SM.ORGSTAFF.LABEL.ADDR_ORDER': 'Seqüência de exibição',
  'SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE_TEMP_LIST': 'Lista de questionários',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_RESTRICTIONTYPE': 'Modo de processamento',
  'SM.COMMON.LABEL.MEASUREMENT_SOUMEAUNIT': 'Unidade de Medição de Origem',
  'SM.ORGSTAFF.MESSAGE.ERROR.SAVEFAILED': 'Falha ao salvar',
  'SM.ORGSTAFF.LABEL.TEAM_ACCESS_EMPLOYEE': 'visite a equipe',
  'SM.ORGSTAFF.LABEL.NO': 'Não',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_TERMINATE_SECONDMENT': 'Rescisão de destacamento',
  'SM.COMMON.LABEL.CHARGECODE_DESC': 'Descrição',
  'SM.COMMON.LABEL.HOLIDAY_TUESDAY': 'Terça-feira',
  'SM.ORGSTAFF.BUTTON.EMPLOYEE_EXPORTALL': 'Exportar',
  'SM.ORGSTAFF.OPTION.QUERYSUBDEPT': 'Consultar departamento de nível inferior',
  'SM.COMMON.LABEL.MEASUREMENT_DESTMEASYS': 'Sistema de medição alvo',
  'SM.ORGSTAFF.MESSAGE.SELECTEDOU_CANNOT_SELECTED_AGAIN':
    'A organização selecionada não pode ser selecionada novamente.',
  'SM.ORGSTAFF.MESSAGE.STAFF_DELETE_CONFIRM':
    'Depois de um funcionário ser excluído, a conta correspondente do funcionário não pode ser restaurada e só pode ser recriada. Excluir ou não?',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ONLINE_BE': 'BE',
  'SM.ORGSTAFF.LABEL.REGION_DST_ENDTIME': 'Hora final do DST',
  'SM.TENANTSPACE.LDAP_SERVERURL': 'URL do servidor',
  'SM.ORGSTAFF.TITLE.BE_CURRENCYCODE': 'Código de Moeda',
  'SM.ORGSTAFF.TITLE.ATTR_ATTRSETTING': 'Atributo estendido',
  'SM.COMMON.LABEL.BANKCARD_TYPE': 'Tipo de cartão',
  'SM.COMMON.MESSAGE.DELETE_SUCCESS': 'Excluído com sucesso.',
  'SM.COMMON.TIPS.NEED_TO_NEW_STD_MIN_CURRENCY_UNIT':
    'Precisa configurar a unidade de moeda padrão e a menor unidade.',
  'SM.COMMON.LABEL.OPERLOG_OPER_LIST': 'Lista de log de operação',
  'SM.ORGSTAFF.LABEL.INH_BY_SUB': 'Permitir herança por Sub-OU',
  'SM.COMMON.LABEL.MEASUREMENT_ERROR_DESCRIPTION': 'Descrição do erro',
  'SM.COMMON.LABEL.SYSMENU_SUSPENDED_S': 'Desativar',
  'SM.COMMON.LABEL.ERATE_DESCRIPTION': 'Descrição',
  'SM.ORGSTAFF.TITLE.CHARGE_CODE_GROUP': 'Grupo de Código de Cobrança',
  'SM.COMMON.TIPS.CURRENCY_DECIMALPOSITIONS_TIPS':
    'Valor – 1 indica que as casas decimais são exibidas com base na precisão de armazenamento da moeda.',
  'SM.COMMON.LABEL.OPERLOG_CONTENT': 'Conteúdo da operação',
  'SM.ORGSTAFF.LABEL.TEAM_MANAGERINFO': 'Informações do Gerente',
  'SM.COMMON.MESSAGE.MODIFY_AREAID_TYPE':
    'A informação já existe. Altere o código de área ou tipo.',
  'SM.ORGSTAFF.OPTION.YES': 'Sim',
  'SM.COMMON.LABEL.SYSMENU_ACTIVE': 'Ativar',
  'SM.ORGSTAFF.LABEL.ORG_REGION': 'Região',
  'SM.ORGSTAFF.BUTTON.ADD_IN': 'Adicionar entrada',
  'SM.COMMON.LABEL.SYS_MEASUREMENT_DEFAULT': 'Medição padrão',
  'SM.ORGSTAFF.LABEL.STAFF_PASSWORD': 'Nova senha',
  'SM.ORGSTAFF.LABEL.TEAM_ORGANIZATION': 'Unidade da Organização',
  'SM.COMMON.MESSAGE.CHARGECODE_TO_RETIRE':
    'Tem certeza de que deseja alterá-la para o estado Cancelado?',
  'SM.ORGSTAFF.LABEL.ACCESS.ORG_REQUIRED': 'Selecione uma organização.',
  'SM.ORGSTAFF.TITLE.REGION_USEPARENT': 'Herdar dados da região superior',
  'SM.ORGSTAFF.TITLE.CONTACT_MIDDLE_NAME': 'Nome do meio',
  'SM.ORGSTAFF.LABEL.STAFF_PASSWORDAGAIN': 'Confirmar senha',
  'SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE': 'Menu',
  'SM.ORGSTAFF.TITLE.ASSIGNE_MANAGER': 'Gerente de Atribuição',
  'SM.COMMON.LABEL.HIGHRISKLOG_PERATOR': 'Operador',
  'SM.COMMON.BUTTON.MEASUREMENT_IMPORT': 'Importação',
  'SM.COMMON.LABEL.ATTACHMENT_ADD_TITLE': 'Novo anexo',
  'SM.ORGSTAFF.MESSAGE.PWD.CHECKMSG': 'As duas senhas são diferentes.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_FORCE_DELETE': 'Forçar a exclusão',
  'SM.ORGSTAFF.BUTTON.TEAM_CANCEL': 'Cancelar',
  'SM.ORGSTAFF.MESSAGE.COMMON_TIPS_STAFF_SELECT': 'Selecione funcionário.',
  'SM.COMMON.LABEL.SYSMENU_DEACTIVATED_D': 'Descartar',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_RESUME': 'Retomar',
  'SM.ORGSTAFF.MESSAGE.STAFF_PWD_RESET_CONFIRM': 'Tem certeza de que deseja redefinir a senha?',
  'SM.COMMON.LABEL.HOLIDAY_HOLIDAYDESCRIPTION': 'Descrição',
  'SM.ORGSTAFF.LABEL.STAFF_ROLE_EFFECTIVE_OU_ADD': 'Selecionar OU',
  'SM.COMMON.LABEL.SYSMENU_CREATEDATE': 'Data de criação',
  'SM.COMMOM.BUTTON.IMPORT': 'Importação',
  'SM.ORGSTAFF.TIPS.ENTER_EMPNAME': 'Insira o nome do funcionário.',
  'SM.ORGSTAFF.LABEL.ORG_ORGID': 'ID OU',
  'SM.ORGSTAFF.MESSAGE.ADDR_USE_DELETE': 'Falha na exclusão porque o endereço foi referenciado.',
  'SM.ORGSTAFF.MESSAGE.BLANK_VIEW': 'Gerencie departamentos na árvore OU.',
  'SM.ORGSTAFF.TITLE.CONTACT_EMPLOYEE_CODE': 'Contato Funcionário',
  'SM.COMMON.MESSAGE.PROJECT_CODE': 'O código do projeto é:',
  'SM.COMMON.LABEL.AREAID': 'ID da área',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_UPLOADTIME': 'Tempo de carregamento',
  'SM.ORGSTAFF.LABEL.CHANNEL_ID': 'ID do canal',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ROAM_INVALID': 'inválido',
  'SM.ORGSTAFF.LABEL.INPUT_SEARCH_KEYWORD': 'Por favor, digite palavra-chave',
  'SM.COMMON.LABEL.MEASUREMENT_EXPORT_FILE_FORMAT': 'Formato de arquivo exportado',
  'SM.COMMON.LABEL.MEASUREMENT_EXPORT_FAILURE': 'Falha',
  'SM.ORGSTAFF.MESSAGE.ORG_ROLE_DELETE':
    'Se excluir esses dados, a relação entre o usuário e a função será excluída automaticamente. Continuar?',
  'SM.COMMON.LABEL.SURVEY_DESC': 'Descrição',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PVT': 'Tipo de validade de senha',
  'SM.COMMON.LABEL.CHARGECODE_OPERATION': 'Operação',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_ATTACHMENTCODE': 'Tipo de anexo',
  'SM.COMMON.LABEL.ERATE_SEARCH_CRITERIA': 'Pesquisar critérios',
  'SM.COMMON.LABEL.MODULE_MODULETYPE': 'Categoria',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ROAM_CREATEDATE': 'Data de criação',
  'SM.COMMON.LABEL.OPERLOG_OBJECT_NAME': 'Nome do objeto',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_LOGIONRESTRICTION': 'Restrição de login',
  'SM.COMMON.LABEL.SYSMENU_PARENT': 'ID do menu pai',
  'SM.COMMON.TIPS.PLZ_SAVE_CURRENCY_MEASUREMENT_UNIT': 'Salve a unidade de medida da moeda.',
  'SM.COMMON.LABEL.CURRENCY_FORMAT_TITLE': 'Formato de exibição da Moeda',
  'SM.COMMON.LABEL.SYSMENU_GRANT_MENU_AUTH': 'Gerenciar permissão de menu',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST': 'Lista de anexos',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_MAC_ADDR': 'Endereço MAC',
  'SM.COMMON.LABEL.MEASUREMENT_YES': 'Sim',
  'SM.ORGSTAFF.LABEL.ADDR_REGION_LAYER': 'Nível Regional administrativo',
  'SM.COMMON.TITLE.ACCESS.FILTER': 'Insira palavras-chave para filtrar',
  'SM.ORGSTAFF.TITLE.TEAM_SUCCESS': 'Informações',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_TITLE': 'Título',
  'SM.ORGSTAFF.OPTION.WEEKLIST_TID': 'Terceiro',
  'SM.ORGSTAFF.LABEL.STAFF_ROLE_EFFECTIVE_OU_DETAIL': 'Detalhes',
  'SM.ORGSTAFF.OPTION.WEEKDAY_WED': 'Quarta-feira',
  'SM.ORGSTAFF.LABEL.ORG_RECOVER': 'Retomar',
  'SM.ORGSTAFF.MESSAGE.DELETE_MSG': 'Tem certeza de que deseja excluir?',
  'SM.ORGSTAFF.TITLE.BE_CURRENCYNAME': 'Nome da Moeda',
  'SM.COMMON.LABEL.CURRENCY_DISPLAY_PREFIX': 'Exibir prefixo',
  'SM.COMMON.LABEL.ADD_EXCHANGE_RATE':
    'Adicionar / Ajustar a taxa de câmbio das moedas associadas.',
  'SM.COMMON.BUTTON.SAVE': 'Salvar',
  'SM.ORGSTAFF.LABEL.TEAM_REFRESH': 'Atualizar',
  'SM.COMMON.TITLE.ERATE_ERROR': 'Erro',
  'SM.ORGSTAFF.LABEL.STAFF_ROLE_NAME': 'Nome da função',
  'SM.ORGSTAFF.LABEL.TEAM_EMPLOYEENAME': 'Funcionário',
  'SM.COMMON.LABEL.SURVEY_TYPE_NAME': 'Nome da categoria',
  'SM.ORGSTAFF.LABEL.REGION_CONFIG_TIMEZONE':
    'Informações sobre o fuso horário da região ausente, configure o fuso horário para região ou região pai.',
  'SM.ORGSTAFF.TITLE.IMPORT_EMPLOYEE_TITLE': 'Importar funcionário',
  'SM.COMMON.LABEL.HIGHRISKLOG_PARAMS': 'Parâmetro',
  'SM.ORGSTAFF.LABEL.TEAM_ACCOUNT': 'Conta',
  'SM.ORGSTAFF.OPTION.STAFF_RESTRICTIONTYPE_DISALLOWED': 'Rejeitar',
  'SM.ORGSTAFF.MESSAGE.BE_SELECT_DEFAULT_CURRENCY': 'Selecione a moeda padrão.',
  'SM.COMMON.BUTTON.CHARGECODE_CONFIRM': 'Informações',
  'SM.ORGSTAFF.TITLE.CONTACT_FIRST_NAME': 'Nome do primeiro',
  'SM.COMMOM.LABEL.SURVEY_INFO': 'Informações do questionário',
  'SM.ORGSTAFF.LABEL.BANK_CHILEBEUSED': 'Permitir que o filho BE use',
  'SM.COMMON.LABEL.HEADING_BASEINFO': 'Informações básicas',
  'SM.ORGSTAFF.LABEL.TEAM_ADD': 'Novo',
  'SM.ORGSTAFF.TITLE.TEAM_AVAILABLEEMPL': 'Funcionários disponíveis',
  'SM.ORGSTAFF.LABEL.STAFF_INFO': 'Informações do funcionário',
  'SM.ORGSTAFF.LABEL.STAFF_ROLE_ID': 'ID da função',
  'SM.COMMON.LABEL.SYSMENU_VERSION': 'Versão',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_LASTNAME': 'Sobrenome',
  'SM.ORGSTAFF.LABEL.TEAM_BUSINESSENTITY': 'Nome BE',
  'SM.COMMON.LABEL.SYSMENU_CURRENCYLANGUAGE': 'Língua atual',
  'SM.ORGSTAFF.TITLE.CONTACT_HOME_PHONE': 'Telefone doméstico',
  'SM.ORGSTAFF.LABEL.CHANNEL_NAME': 'Nome do canal',
  'SM.COMMON.MESSAGE.CHARGECODE_DELETE_GROUP':
    'Tem certeza de que deseja excluir o grupo de código de cobrança?',
  'SM.COMMON.LABEL.AREATYPE': 'Tipo de área',
  'SM.COMMON.LABEL.SYSMENU_IDEL_I': 'release',
  'SM.COMMON.LABEL.MEASUREMENT_ENTITY_TYPE': 'Tipo de unidade',
  'SM.COMMON.MESSAGE.CURRENCY_UNIT_TIPS': 'Por favor, introduza o número.',
  'SM.ORGSTAFF.LABEL.ORG_IMPORTSUCCESS': 'Dados importados com sucesso.',
  'SM.COMMON.TIPS.MENU_REBUILD_INFO':
    'As informações de recriação do índice de menu foram enviadas para a fila de mensagens. Aguarde.',
  'SM.ORGSTAFF.LABEL.SELECTED_ROLE': 'Função selecionada',
  'SM.COMMON.LABEL.MEASUREMENT_CONV_RATIO': 'Taxa de unidade de medida',
  'SM.COMMON.LABEL.OPERLOG_SEARCH': 'Pesquisar',
  'SM.COMMON.LABEL.SYSMENU_GRANT_AUTH': 'Gerenciar permissão',
  'SM.ORGSTAFF.LABEL.BREGION_NAME': 'Região',
  'SM.ORGSTAFF.TITLE.BE_DEFAULT': 'Moeda Padrão',
  'SM.COMMON.TITLE.MEASUREMENT_UNITLIST': 'Lista de unidades de medida',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_BE': 'ID do BE',
  'SM.COMMON.LABEL.HIGHRISKLOG_OPERATIONNAME': 'Nome da operação',
  'SM.COMMON.MESSAGE.DELETE_CURRENCY_STD_MIN_MEASUREMENT':
    'Moeda precisa usar unidade padrão e unidade mínima, tem certeza de que irá excluir?',
  'SM.ORGSTAFF.LABEL.STAFF_ROLE_PATH': 'Caminho',
  'SM.ORGSTAFF.TITLE.MSGBOX_INFO': 'Dicas',
  'SM.ORGSTAFF.OPTION.WEEKDAY_MON': 'Segunda-feira',
  'SM.COMMON.LABEL.CURRENCY_TYPE_FLAG': 'Virtual',
  'SM.COMMON.LABEL.OPERLOG_LOGIN_IP': 'IP de login',
  'SM.COMMON.LABEL.CHARGECODE_GROUPMEMBER': 'Códigos de cobrança',
  'SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE_NAME_LANG': 'Língua do nome do questionário',
  'SM.COMMON.LABEL.CURRENCY_ROUNDMODE': 'Modo de arredondamento',
  'SM.ORGSTAFF.LABEL.ACCESS.ADD_SUCCESS': 'A adição foi bem-sucedida.',
  'SM.COMMON.LABEL.SYSMENU_MENU_AUTH_ROLE_LIST': 'Lista de funções atribuídas',
  'SM.COMMON.TITLE.BANKCARD_CONFIRM': 'Informações',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_12': 'Dezembro',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_11': 'Novembro',
  'SM.COMMON.LABEL.CHARGECODE_DATAGIRD_CATALOG': 'Catálogo',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_10': 'Outubro',
  'SM.COMMON.TIPS.ONLYONE_UNDERLINE_FORBIDDEN':
    'O valor de entrada não pode conter caracteres especiais.',
  'SM.ORGSTAFF.LABEL.ADDR_PLEASE_INPUT': 'Insira o nome',
  'SM.COMMOM.BUTTON.EDIT': 'Modificar',
  'SM.ORGSTAFF.MESSAGE.SUCCESS.SAVE': 'Successo',
  'SM.COMMON.LABEL.BANKCARD_BANKTYPE': 'Tipo Banco',
  'SM.COMMON.LABEL.MEASUREMENT_NOTIFICATION_NAME': 'Nome em Notificação',
  'SM.ORGSTAFF.BUTTON.ADDR_LINE': 'Linha',
  'SM.ORGSTAFF.TITLE.TEAM_DELETE_CONFIRM': 'Confirmar',
  'SM.COMMON.LABEL.CHARGECODE_RETIRE': 'Cancelar',
  'SM.COMMON.TIPS.SPECIAL_CHAR_CANNOT_START_NUMBER': 'Não permitir que comece com número.',
  'SM.COMMON.LABEL.CURRENCY_DISPLAY_FORMATE': 'Formato de exibição',
  'SM.ORGSTAFF.LABEL.TEAM_CONTACTINFO': 'Informações de contato',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_ATTACHMENTNAME': 'Nome do anexo',
  'SM.ORGSTAFF.LABEL.ADDR_ADDERSS': 'Endereço',
  'SM.ORGSTAFF.MESSAGE.SURE_TO_DO': 'Continuar?',
  'SM.ORGSTAFF.LABEL.TEAM_TEAMSHORTNAME': 'Nome abreviado da equipe',
  'SM.ORGSTAFF.TITLE.BANK_TITLE': 'Banco',
  'SM.COMMON.MESSAGE.CHARGECODE_OPERATION_SUCCESS': 'Operação executada com sucesso.',
  'SM.ORGSTAFF.MESSAGE.USERRESTRICT_CONFIRM_SAVE':
    'O Segmento de Tempo de Sign-In só pode manter um registro e o outro Segmento de Tempo de Sign-In será excluído quando for adicionado.',
  'SM.COMMON.LABEL.BANKCARD_BANKSIGN_PARAM': 'ID bancário emissor de cartão',
  'SM.COMMON.BUTTON.SURVEY_NEW_ANSWER_OPTIONS': 'Nova opção de resposta',
  'SM.ORGSTAFF.TIPS.MAC_FORMAT_ERROR': 'Erro de formato de endereço MAC',
  'SM.COMMON.LABEL.MODULE_BASICINFO': 'Informações básicas',
  'SM.ORGSTAFF.LABEL.REGION_DST_MODE': 'Modo DST',
  'SM.COMMON.BUTTON.EDIT': 'Modificar',
  'SM.COMMON.MESSAGE.ERATE_VLUEW_RANGE': 'O valor varia de 0 a {1}.',
  'SM.COMMON.LABEL.PROVINCE': 'Província',
  'SM.ORGSTAFF.LABEL.STAFF_OPRATION': 'Operação',
  'SM.COMMON.LABEL.ATTACHMENT_LIMIT_INFO': 'Nenhuma restrição',
  'SM.COMMON.LABEL.SYSMENU_DESCRIPTION': 'Descrição do Menu',
  'SM.COMMON.MESSAGE.BANKCARD_DELETE_CONFIRM': 'Deseja realmente excluir?',
  'SM.COMMON.MESSAGE.CURRENCY_UNIT_EXCHAGE_TIPS': 'Insira um número inteiro.',
  'SM.ORGSTAFF.MESSAGE.CHOOSE_BE_FIRST': 'Escolha BE primeiro',
  'SM.COMMON.LABEL.MODULE_NAME': 'Nome',
  'SM.ORGSTAFF.TITLE.MEASUREMENT_CONV': 'Conversão de medição',
  'SM.COMMON.LABEL.SURVEY_REMARKS': 'Observações',
  'SM.ORGSTAFF.OPTION.ROLE_OPTION_MODE': 'Modo de operação',
  'SM.ORGSTAFF.LABEL.OU_HIERARCHY': 'Hierarquia OU',
  'SM.COMMON.BUTTON.VIEW': 'Exibir',
  'SM.COMMON.MESSAGE.CHARGECODE_CREATE_DATA':
    'Tem certeza de que deseja adicionar estes códigos de cobrança?',
  'SM.ORGSTAFF.BUTTON.ADD': 'Novo',
  'SM.ORGSTAFF.MESSAGE.STAFF_SUSPEND_CONFIRM':
    'Tem certeza de que deseja suspender a conta do funcionário?',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_SVD': 'Intervalo de Tempo limite da sessão',
  'SM.ORGSTAFF.MESSAGE.BANK_STATUS_ERROR':
    'Falha ao salvar porque as informações bancárias expirou.',
  'SM.COMMON.LABEL.HOLIDAY_BASICINFORMATION': 'Informações básicas',
  'SM.ORGSTAFF.LABEL.ORG_ADDITIONINFO': 'Informações adicionais',
  'SM.ORGSTAFF.TITLE.BE_ADDRESS_CFG': 'Formato do endereço',
  'SM.ORGSTAFF.TITLE.STAFF_INFO_MDY': 'Modificar Funcionário',
  'SM.COMMON.TITLE.ACCESS.SELECT_ORG': 'Selecionar organização de acesso',
  'SM.ORGSTAFF.MESSAGE.STAFF_FORBID_CONFIRM':
    'Depois de um funcionário ser desativado permanentemente, a conta do funcionário só pode ser criada repetidamente depois de ser excluída. Tem certeza de que deseja desativar o funcionário permanentemente?',
  'SM.ORGSTAFF.MESSAGE.ERROR.SAVEACCESSLIMIT':
    'Funcionários acessíveis podem adicionar até 100 por lote',
  'SM.COMMON.LABEL.OU_HIERARCHY_NAME': 'Nome da RelationShip OU',
  'SM.COMMON.LABEL.BANKCARD_LIST': 'Lista de cartões bancários',
  'SM.COMMON.MESSAGE.MEASUREMENT_SAVE_CONFIRM':
    'Quando você salva uma unidade de medida padrão, ela substitui uma unidade padrão existente e modifica as unidades padrão de outras unidades de medida.',
  'SM.COMMON.LABEL.SYSMENU_SEARCH_MENU': 'Pesquisar critérios',
  'SM.ORGSTAFF.TITLE.ADDR_TEMPATE_ID': 'ID do modelo',
  'SM.COMMON.MESSAGE.SYSMENU_MENUNAMEREQUIRE': 'Insira o nome do menu.',
  'SM.ORGSTAFF.TITLE.ADDR_DISP_FORMAT': 'Formato de exibição',
  'SM.ORGSTAFF.MESSAGE.STARTANDTEND_ISNULL':
    'As datas de início e fim não podem estar vazias ao mesmo tempo.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_TIMERANGE': 'Intervalo de tempo',
  'SM.ORGSTAFF.LABEL.SELECT_EXCEL': 'Selecione o arquivo excel',
  'SM.ORGSTAFF.TITLE.EMP_BELONG_DEPT': 'Departamento',
  'SM.ORGSTAFF.LABEL.BUSINESSENTITY_DISPLAY_NO': 'Seqüência de exibição',
  'SM.COMMON.BUTTON.CHARGECODE_CANCEL': 'Cancelar',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_ADDR': 'Endereço',
  'SM.ORGSTAFF.LABEL.TEAM_TEAMTYPE': 'Tipo de equipe',
  'SM.COMMON.LABEL.SYSMENU_MENUCRUMB': 'Caminho',
  'SM.COMMON.LABEL.SURVEY_QUESTIONNAIRE_NAME': 'Nome do questionário',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_NEW_PASSWORD_CONFIRM': 'nova confirmação de senha',
  'SM.COMMON.LABEL.CURRENCY_TYPE': 'Tipo de Moeda',
  'SM.COMMON.BUTTON.ATTACHMENT_MGR_SEARCH_CRITERIA_RESET': 'Redefinir',
  'SM.COMMON.LABEL.MENU_ATTR_TITLE': 'Estender o atributo',
  'SM.ORGSTAFF.LABEL.TEAM_REMOVE': 'Remover',
  'SM.ORGSTAFF.BUTTON.SAVE': 'Salvar',
  'SM.COMMON.LABEL.CHOOSE_OU_ADD': 'Escolher OU a adicionar',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_COPYPERMISSION': 'Permissão de cópia',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD41':
    'Bloqueio devido a falhas de login consecutivas dentro do período especificado, em minutos',
  'SM.ORGSTAFF.MESSAGE.REGION_VALIDATE_IPRANGEERROR':
    'O segmento de endereço IP está configurado incorretamente.',
  'SM.ORGSTAFF.MESSAGE.ERROR.MAXLIMIT':
    'O número de estabelecimentos sob um inquilino não pode exceder 999',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD40': 'Regra personalizada',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ONLINE_ACCOUNT': 'Conta',
  'SM.COMMON.LABEL.CREATE_AREAINFO_MAINTAIN': 'Informações sobre Nova região',
  'SM.COMMON.TITLE.BANKCARD_MDF': 'Modificar cartão bancário',
  'SM.ORGSTAFF.TIPS.RESTRICTMAC': 'Insira o endereço MAC correto.',
  'SM.ORGSTAFF.TITLE.QUERYFAIL_TITLE': 'Falha na pesquisa.',
  'SM.COMMON.BUTTON.CREATE': 'Novo',
  'SM.COMMON.MESSAGE.VALIDATE_MAXLENGTHB': 'Um máximo de 0 caracteres são permitidos.',
  'SM.COMMON.LABEL.HOLIDAY_HOLIDAYTYPE': 'Tipo',
  'SM.COMMON.LABEL.SURVEY_MODIFIED_TIME': 'Tempo de modificação',
  'SM.COMMON.LABEL.OPERLOG_SUCCESS_MEW': 'Successo',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD39': 'A senha pode conter apenas dígitos',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD38':
    'Caracteres que uma senha não pode conter',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD37':
    'A senha não pode ser igual à conta ou a um subconjunto da conta',
  'SM.COMMON.MESSAGE.CHARGECODE_TO_DRAFT':
    'Tem certeza de que deseja alterá-la para o estado rascunho?',
  'SM.COMMON.BUTTON.BANKCARD_RESET': 'Redefinir',
  'SM.COMMON.MESSAGE.SYSMENU_MAX_EDIT_LIMIT': 'Limite máximo de edição.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD36':
    'Lista de formatos de senha proibidos, como ABCABC e ABCDEF',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD35': 'Senha padrão',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD34':
    'Período mínimo de validade da senha, em horas',
  'SM.COMMON.LABEL.MENU_ACCESS_STRART_TIME': 'Hora de Início',
  'SM.COMMON.LABEL.MEASUREMENT_RESULT_LIST': 'Lista de resultados',
  'SM.ORGSTAFF.MESSAGE.ADDR_DELETE_SUCCESS': 'Excluído com sucesso',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD33':
    'A senha não pode ter caracteres ou dígitos consecutivos, como AB, 123, AA e abc',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_UPLOAD_SIZE_MIN':
    'O arquivo carregado é menor que o tamanho mínimo 0 KB.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD32':
    'Número mínimo de caracteres de cada tipo que uma senha pode conter',
  'SM.ORGSTAFF.LABEL.ORG_TYPE': 'Tipo OU',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD31': 'Comprimento mínimo da conta',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD30': 'Comprimento máximo da conta',
  'SM.COMMON.LABEL.OPERLOG_SERRCH_CRITERIA': 'Pesquisar critérios',
  'SM.COMMON.MESSAGE.OPERLOG_TIME_LOG2': 'dia(s).',
  'SM.COMMON.MESSAGE.OPERLOG_TIME_LOG1': 'O intervalo de tempo não pode exceder',
  'SM.COMMON.LABEL.CURRENCY_BASIC_INFO': 'Informações básicas',
  'SM.ORGSTAFF.TITLE.RELATION_CONFIRM_DELETE':
    'Após ter sido desativado, o relacionamento da organização e seu relacionamento subordinado serão excluídos, Você confirma a exclusão?',
  'SM.COMMON.LABEL.ERATE_SCURRENCY_CODE': 'Código da Moeda de Origem',
  'SM.COMMON.LABEL.SELECTED_OUS': 'OUs selecionados',
  'SM.ORGSTAFF.LABEL.REGION_DST_STARTTIME': 'Hora de início do DST',
  'SM.ORGSTAFF.TITLE.POPUP_NEW_PROPERTY': 'Nova propriedade',
  'SM.COMMON.LABEL.SURVEY_TITLE_TYPE': 'Tipo de pergunta',
  'SM.ORGSTAFF.MESSAGE.DELETE_CONFIRM': 'Deseja realmente excluir?',
  'SM.ORGSTAFF.OPTION.NO': 'Não',
  'SM.ORGSTAFF.LABEL.BE_TYPE': 'Tipo BE',
  'SM.COMMON.LABEL.OPERLOG_SESSION_ID': 'ID da sessão',
  'SM.COMMON.MESSAGE.MEASUREMENT_STANDARD_MUSTEXIST':
    'O sistema de medição deve ter uma unidade de medição padrão.',
  'SM.ORGSTAFF.OPTION.WEEKDAY_SUN': 'Domingo',
  'SM.ORGSTAFF.LABEL.STAFF_ROLE_EFFECTIVE_OU': 'OU eficaz',
  'SM.ORGSTAFF.VALIDATOR.AFTER_TODAY': 'O tempo inserido deve ser anterior ou igual a 00:00 hoje.',
  'SM.SM.COMMON.TITLE.GROUP_NAME_EXIST': 'O nome do grupo de usuários existe.',
  'SM.ORGSTAFF.LABEL.ORG_RECOVER_TIPS':
    'Após retomada, organização ou organização e sua organização subordinada estariam disponíveis. Confirma-se a retomar?',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_LOGINTIMERANGE': 'Segmento de Tempo de sessão',
  'SM.COMMON.LABEL.CURRENCY_MINIMUM_MEASUREMENT': 'Sinalizador de unidade mínima',
  'SM.ORGSTAFF.TITLE.BE_ADDRESS_INFO': 'Informações de endereço',
  'SM.ORGSTAFF.TIPS.ENTER_OUNAME': 'Insira o nome da organização.',
  'SM.COMMON.LABEL.SURVEY_SECTION': 'Regional',
  'SM.COMMON.TIPS.SPECIAL_CHAR_FORBIDDEN':
    'O valor de entrada não pode conter caracteres especiais.',
  'SM.COMMON.LABEL.SYS_MEASUREMENT_NAME': 'Nome',
  'SM.COMMON.LABEL.SURVEY_CREATED_ACCOUNT': 'Criado por',
  'SM.SM.COMMON.TITLE.NO_CHINAWORD': 'Caracteres especiais não são permitidos.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD19':
    'Alteração de senha forçada no primeiro login ou após redefinição da senha',
  'SM.COMMON.LABEL.MODULE_MODULELIST': 'Lista de módulos',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD18':
    'Antecedência, em número de dias, com que os usuários são notificados sobre a expiração da senha',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_SECONDMENT_HISTORY': 'Histórico de destacamento',
  'SM.COMMON.TITLE.SURVEY_ITEM_NAME_INFO': 'Informações sobre nome do item',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD15': 'Número máximo de tentativas de senha',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD14':
    'Período máximo para a validade da senha, em dias',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD13': 'A senha não pode conter o nome',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD12':
    'A senha deve ser diferente da conta ou da conta na ordem inversa',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD11': 'Senha inválida',
  'SM.COMMON.LABEL.SURVEY_NOT_INPUT': 'Não permitir entrada manual',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD10': 'Caracteres especiais válidos',
  'SM.COMMON.MESSAGE.SYSMENU_SAVESUCCESS': 'Operação executada com sucesso.',
  'SM.ORGSTAFF.TITLE.CONTACT_LAST_NAME': 'Sobrenome',
  'SM.COMMON.LABEL.MEASUREMENT_CONV': 'Conversão de medição',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_STATUS': 'Status',
  'SM.COMMON.LABEL.MEASUREMENT_UNIT_STANDARD': 'Sinalizador de unidade padrão',
  'SM.ORGSTAFF.LABEL.REGION_DISPLAYORDER': 'Seqüência de exibição',
  'SM.ORGSTAFF.LABEL.TEAM_TO': 'Para',
  'SM.COMMON.LABEL.BANKCARD_TYPE_PARAM': 'Tipo de cartão',
  'SM.COMMON.LABEL.SURVEY_CLASS_NAME': 'Nome da categoria',
  'SM.ORGSTAFF.LABEL.STAFF_AUTHCOPY_SOURCENO': 'Conta de origem',
  'SM.COMMON.LABEL.CURRENCY_GROUPSYMBOL': 'Separador',
  'SM.ORGSTAFF.TITLE.STAFF_ROLE_BASIC': 'Função',
  'SM.ORGSTAFF.TITLE.ADDR_DISP_LIST': 'Lista de formatos de exibição de endereços',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD09':
    'Número máximo de caracteres duplicados consecutivos em uma senha',
  'SM.ORGSTAFF.LABEL.TEAM_EXTENDPRO': 'Atributos estendidos',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD08':
    'A senha deve ser diferente das senhas anteriores',
  'SM.COMMON.LABEL.DATA_OPRATION': 'Operação',
  'SM.COMMON.LABEL.SURVEY_QUESTIONNAIRE_ID': 'ID do questionário',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD07':
    'A senha deve conter caracteres especiais',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD06': 'A senha deve conter letras minúsculas',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD05': 'A senha deve conter letras maiúsculas',
  'SM.COMMON.LABEL.MEASUREMENT_UNIT_CODE': 'Código',
  'SM.ORGSTAFF.MESSAGE.ADDR_LEVELS_HIGH': 'O endereço não é sequencial.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD04': 'A senha deve conter letras',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD03': 'A senha deve conter números',
  'SM.ORGSTAFF.LABEL.PARENT_BE': 'BE pai',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD02': 'Comprimento mínimo da senha',
  'SM.ORGSTAFF.MESSAGE.SELECT_WG_INFO': 'Selecione um grupo de trabalho.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD01': 'Comprimento máximo da senha',
  'SM.COMMON.BUTTON.PROPERTY_CONFIRM': 'Informações',
  'SM.COMMON.LABEL.EDIT_AREAINFO_MAINTAIN': 'Modificar Informações da região',
  'SM.COMMON.TITLE.PROPERTY_SUCCESS': 'Informações',
  'SM.ORGSTAFF.LABEL.ORG_SELECTORG': 'Selecionar OU',
  'SM.COMMON.BUTTON.SEARCH': 'Pesquisar',
  'SM.COMMON.TITLE.ITEM_NOTE': 'Visualizar imagem',
  'SM.COMMON.LABEL.ERATE_EXCHANGE_RATE': 'Taxa de câmbio',
  'SM.COMMON.BUTTON.DELETE': 'Excluir',
  'SM.ORGSTAFF.BUTTON.EMPLOYEE_DELETE': 'Excluir',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_MATCH_MODE': 'Modo de correspondência',
  'SM.ORGSTAFF.TITLE.WORKGROUP': 'Grupo de trabalho',
  'SM.COMMON.LABEL.PROPERTY_CREATE_PROPERTY': 'Novo atributo',
  'SM.ORGSTAFF.MESSAGE.RECOVERORG': 'Retome a OU.',
  'SM.AUC.LABEL.LOGINLOG_EMPLOYEE_NAME': 'Nome do funcionário',
  'SM.AUC.LABEL.LOGINLOG_LOGIN_IP_ADDRESS': 'IP de login',
  'SM.AUC.LABEL.LOGINLOG_OPER_CONTENT': 'Conteúdo da operação',
  'SM.AUC.LABEL.LOGINLOG_OPER_TIME': 'Tempo de operação',
  'common.bremui.title.operation': 'Operação',
  'SM.ORGSTAFF.HINT.PLEASE_INPUT': 'Insira por favor',
  'SM.MENU.SEARCH.INPUT': 'Nome do menu de pesquisa',
  'SM.MENU.ADD.CUSTOM.MENU': 'Adicionar um menu',
  'SM.MENU.ADD.CUSTOM.PERM_REPEAT': 'Nome de Permissão Duplicado',
  'SM.AUC.MESSAGE.PWDR_VALIDATE': 'Regra de verificação',
  'SM.AUC.MESSAGE.PWDR_MANAGE': 'Regra de gerenciamento',
  'SM.AUC.MESSAGE.RULEGROUP': 'Grupo de Regras',
  'SM.AUC.MESSAGE.RULEGROUPNAME': 'Nome do Grupo de Regras',
  'SM.AUC.MESSAGE.RULEGROUPList': 'Lista de regras',
  'SM.AUC.MESSAGE.RULEGROUPListEDIT': 'Editar Grupo de Regras',
  'SM.AUC.MESSAGE.RULEGROUPListADD': 'Adicionar um de Regras',
  'SM.AUC.MESSAGE.LOGIN.IP': 'Endereço IP',
  'SM.AUC.MESSAGE.PWDRULE_ACCOUNT': 'Regra da conta',
  'SM.AUC.LABEL.PWD_PWDRULE': 'Regra de senha',
  'SM.AUC.LABEL.PWD_CUSTOMIZEDRULE': 'Regra personalizada',
  'SM.AUC.LABEL.KIND': 'Classificação',
  'SM.AUC.LABEL.VALUE': 'valor',
  'SM.MENU.DELETE.CUSTOM.ROLE.FAIL':
    'O menu foi excluído com sucesso. Falha ao excluir a permissão associada ao menu. Por favor, verifique se a permissão ainda é referenciada por outros menus ou funções',
  'SM.AUC.ACCOUNT.LOCK.SEARCH.ACCOUNT': 'Pesquisar conta',
  'SM.AUC.ACCOUNT.LOCK.USER.NOT.EXIT': 'O usuário não existe',
  'SM.AUC.LABEL.LOGINSESSION_SESSION': 'Status da sessão',
  'SM.AUC.LABEL.LOGINSESSION_LOGINSTATUS': 'Status de login',
  'SM.AUC.LABEL.USERLOGUI_LOGINIDACCOUNT': 'Conta de login',
  'SM.AUC.MESSAGE.OPERLOG_TIPS_LOGOUT': 'Forçar fora',
  'SM.AUC.LABEL.LOGINSESSION_LOCKSTATUS': 'Status de bloqueio',
  'SM.AUC.LABEL.LOGINSESSION_LOCKTIME': 'Tempo de bloqueio',
  'SM.AUC.LABEL.LOGINSESSION_LOCK': 'Bloquear',
  'SM.AUC.LABEL.LOGINSESSION_UNLOCK': 'Desbloquear',
  'SM.AUC.LABEL.LOGINSESSION_SURE_LOCK': 'Tem certeza de que deseja bloquear este usuário?',
  'SM.AUC.LABEL.LOGINSESSION_LOCKFLAG': 'Sinalizador de bloqueio',
  'SM.AUC.LABEL.LOGINSESSION_LOCKTYPE': 'Tipo de bloqueio',
  'SM.AUC.LABEL.LOGINSESSION_LOCK_EMPLOYEE': 'Bloqueado por',
  'SM.AUC.LABEL.LOGINSESSION_LOCKBYHAND': 'Bloquear manualmente',
  'SM.AUC.LABEL.LOGINSESSION_UNLOCKBYHAND': 'Desbloqueio manualmente',
  'SM.AUC.LABEL.LOGINSESSION_AUTHSTATUS': 'Status da acreditação',
  'SM.AUC.LABEL.LOGINSESSION_NEARESTPAWDAUTHTYPE': 'Status de autenticação da última senha',
  'SM.AUC.LABEL.LOGINSESSION_NEARESTPWDAUTHTIME': 'Tempo de Autenticação da Última Senha',
  'SM.AUC.LABEL.LOGINSESSION_PWDAUTHFAILTIME': 'Tempos de falha de autenticação de senha',
  'SM.AUC.MESSAGE.LOGINSESSION_LOGININ_STATUS': 'Assinado',
  'SM.AUC.MESSAGE.LOGINSESSION_LOGOUT_STATUS': 'Não conectado',
  'SM.AUC.MESSAGE.LOGINSESSION_LOCKSTATUS': 'Bloqueado',
  'SM.AUC.MESSAGE.LOGINSESSION_UNLOCKSTATUS': 'Não bloqueado',
  'SM.AUC.LABEL.LOGINSESSION_PWDAUTHFAIL': 'Falha de autenticação da senha',
  'SM.USERGROUP.DELETEG_GROUP.TITLE': 'Excluir o grupo de usuários selecionado?',
  'SM.USERGROUP.DELETEG_GROUP.CONTENT':
    'Depois que um grupo de usuários é excluído, ele só pode ser recriado.',
  'SM.USERGROUP.DELETEG_EMPLOYEE.TITLE':
    'Excluir os funcionários selecionados do grupo de usuários atual?',
  'SM.USERGROUP.DELETEG_EMPLOYEE.CONTENT':
    'Depois que um funcionário é excluído de um grupo de usuários, você pode adicionar o funcionário ao grupo de usuários novamente se quiser restaurar o funcionário.',
  'SM.AUTH.MESSAGE.FUNCAUTH_MENU': 'Menu',
  'SM.AUTH.LABEL.ROLETMPL_OPERTIME': 'Tempo de operação',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DESC_FIELD_INPUT': 'Nome do Nome do Campo:',
  'SM.AUTH.TITLE.ROLE_FUNCAUTH_FAIL':
    'Falha ao salvar algumas permissões de função. Essas permissões são exclusivas ou não podem ser concedidas a você.',
  'SM.AUTH.MESSAGE.CHANGE_SCOPE_SUGGESTION':
    'Se o nó pai alterar o escopo da função, o escopo do nó filho precisa ser modificado como o mesmo do nó pai.',
  'SM.AUTH.TITLE.ROLE_DISALLOWED_FUNCTION_PERMISSION': 'Permissão de função excluída',
  'SM.AUTH.LABEL.OPPAUTH_SOURCE_TYPE_INPUT': 'Fonte de permissão:',
  'SM.FEDERATION.COMMON.DELETE.FAIL': 'Falha na exclusão.',
  'SM.AUTH.TITLE.ERROR': 'Erro',
  'SM.AUTH.TITLE.SELECT_OPEN_LOGIN': 'Escolher Login Open Menu',
  'SM.AUTH.LABEL.OPERATOR_ACCOUNT_INPUT': 'Conta:',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_OPER_TYPE_LIST_INPUT': 'ID da lista de tipo de operação:',
  'SM.AUTH.LABEL.MODULE_INPUT': 'Módulo:',
  'SM.AUTH.MESSAGE.ROLE_RELATIONSHIP_EXISTS_NOT_BEINHER':
    'Os dois objetos são exclusivos e herança não é permitida.',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_STAUTS_NORMAL': 'Normal',
  'SM.AUTH.LABEL.ROLE_AUTH_DATA': 'Permissão de dados',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_PARENT_NODE_FIELD': 'Nome do Campo PARENT_ID',
  'SM.AUTH.TITLE.ROLE_GRANTABLE_DATA_PERMISSION': 'Permissão de dados concedida',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_OPERATION': 'Nome da operação',
  'SM.AUTH.LABEL.AUTH_CONF_DATA': 'Configurações de permissão de dados',
  'SM.AUTH.LABEL.ROLE_GENERALROLELIST': 'Lista geral de funções',
  'SM.FEDERATION.FEDERATIONAPP.PHONE': 'Número de contato',
  'SM.AUTH.BUTTON.ADD': 'Novo',
  'SM.AUTH.MESSAGE.ROLETMPL_SELECT_ONE_ROLE': 'Selecione pelo menos uma função.',
  'SM.AUTH.MESSAGE.TIME_LOG2': 'dia(s).',
  'SM.AUTH.MESSAGE.TIME_LOG1': 'O intervalo de tempo não pode exceder',
  'SM.AUTH.TITLE.USERAUTHVIEW_USERHAVAEROLES': 'Função do usuário',
  'SM.AUTH.LABEL.ROLE_GRANTED_AUTH_TYPE': 'Tipo concedido',
  'SM.AUTH.TITLE.INPUT_MENU_NAME': 'Inserir um nome de menu',
  'SM.AUTH.LABEL.FUNCAUTH_STATUS': 'Status',
  'SM.AUTH.TITLE.USERAUTHVIEW_MENU_AUTHID': 'ID da permissão',
  'SM.AUTH.MESSAGE.ROLE_RELATIONSHIP_EXISTS': 'A relação mútua-exclusivo já existe.',
  'SM.USERGROUP.LABEL.DELETE_CONFIRM': 'Tem certeza de que deseja excluir o grupo de usuários?',
  'SM.AUTH.TITLE.ROLE_OPPAUTHNAME': 'Permissão mutuamente exclusiva',
  'SM.AUTH.LABEL.ROLETMPL_ROLE_LIST': 'Lista de funções',
  'SM.AUTH.MESSAGE.BIZ_OBJ_POLICY_DUPLICATE': 'Existe uma política com o mesmo papel e âmbito.',
  'SM.FEDERATION.FEDERATIONAPP.CLIENTNAME': 'Sistema de autenticação de terceiros',
  'SM.AUTH.MESSAGE.ROLETMPL_CAN_NOT_DELETE': 'Falha ao excluir o nó porque o nó contém subnós.',
  'SM.AUTH.LABEL.ROLE_NORMALROLE_BENAME': 'Página inicial BE da função comum',
  'SM.AUTH.TITLE.MENU_NAME': 'Nome do menu',
  'SM.AUTH.TIPS.ROLETMPL_HAS_OPP_AUTH':
    'Falha ao salvar porque existe uma permissão mutuamente exclusiva.',
  'SM.AUTH.TIPS.FUNCAUTH_NO': 'Não',
  'SM.AUTH.TITLE.ROLE_LOGIN_MENU_UPON': 'Menu Exibição Automática ao iniciar sessão',
  'SM.AUTH.LABEL.ROLETMPL_ROLETMPL_CONFIGURATE': 'Configurar Modelo de Função',
  'SM.AUTH.LABEL.OPERATOR_ACCOUNT': 'Conta',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_SERVICE_INPUT': 'Nome do serviço:',
  'SM.FEDERATION.IMPORT.FILESIZEVALIDATEFAIL': 'Selecionar um arquivo com menos de 10 MB.',
  'SM.AUTH.LABEL.STAFFROLELOG_OPER_TYPE_INPUT': 'Tipo de operação:',
  'SM.AUTH.LABEL.ROLE_PARENTROLE_NAME': 'Nome geral da função',
  'SM.AUTH.LABEL.ROLETMPL_OPERATOR': 'Operador',
  'SM.AUTH.MESSAGE.ROLE_ERROR_BEEN_EXTENDED':
    'Falha ao excluir a função porque foi herdada por outra função ou herdado.',
  'SM.AUTH.TITLE.USERAUTHVIEW_ATTR_DEPARTMENT': 'Departamento',
  'SM.AUTH.MESSAGE.ROLE_THE_ROLES_PERMISSION_OPPED':
    'Falha ao salvar porque a permissão de função básica tem uma relação mutuamente exclusiva.',
  'SM.AUTH.LABEL.THIRD_DATASOURCE_NAME': 'Nome da Fonte de Dados',
  'SM.AUTH.TITLE.PERMISSION_RECOVERY': 'Reciclagem de permissões',
  'SM.AUTH.BUTTON.EXPORT_CURRENT_ROLE': 'Exportar item selecionado',
  'SM.FEDERATION.COMMON.REFRESH': 'Atualizar',
  'SM.AUTH.LABEL.FUNCAUTH_ROLE_UNAVAILABLE': 'Permissão Indisponível de Função',
  'SM.AUTH.TITLE.USERAUTHVIEW_AUTHVIEW': 'Visualização de permissão',
  'SM.AUTH.LABEL.FUNCAUTH_AUTHID': 'ID da permissão',
  'SM.AUTH.MESSAGE.PERM_RECOVERY_SUCCESS': 'Permissão revogada com sucesso.',
  'SM.AUTH.TITLE.USERAUTHVIEW_FUNCTIONLIST': 'Lista de permissões de função',
  'SM.AUTH.LABEL.ROLE_EMPLOYEE_ACCOUNT': 'Conta',
  'SM.AUTH.MESSAGE.ROLETMPL_INFORMATION': 'Detalhes',
  'SM.AUTH.LABEL.STAFFROLELOG_OPER_TYPE': 'Tipo de operação',
  'SM.AUTH.TITLE.EMPOLYEE_ROLESCOPE': 'Funcionário',
  'SM.AUTH.BUTTON.CLEAR': 'Limpar',
  'SM.AUTH.LABEL.ROLE_CODE_INPUT': 'Código da função:',
  'SM.AUTH.LABEL.BIZ_OBJ_FIELD_PRIVILEGE': 'Privilégio de Campo',
  'SM.AUTH.LABEL.FUNCAUTH_AUTHTYPE': 'Tipo de permissão',
  'SM.FEDERATION.COMMON.DETAIL': 'Detalhes',
  'SM.FEDERATION.COMMON.NEW': 'Criar',
  'SM.AUTH.LABEL.MENU_AUTHID_INPUT': 'ID da permissão:',
  'SM.AUTH.TITLE.USER_PERMISSION_COMPARE': 'Comparação da permissão de pessoal',
  'SM.AUTH.TIPS.DATA_AUTH_PARAM_INVALID':
    "Nome da tabela, nome da coluna só podem conter letras, número e '_' e só podem começar com letras.",
  'SM.AUTH.TITLE.ROLE_GRANTABLE_FUNCTION_PERMISSION': 'Permissão de função concedida',
  'SM.AUTH.TITLE.ROLE_PERMISSION_COMPARE': 'Comparação da permissão de função',
  'common.baseui.MESSAGE.VALIDATE_MAXLENGTHB': 'São permitidos no máximo 0 caracteres.',
  'SM.AUTH.TITLE.ROLE_BUSINESS_ENTITY': 'ID do BE',
  'SM.AUTH.LABEL.ROLE_DERIVE_PARENTROLE': 'Função geral herdadada',
  'SM.AUTH.MESSAGE.ROLE_ADD_SUCCESSFUL': 'Adicionado com sucesso',
  'SM.AUTH.LABEL.ROLE_EMPLOYEE_NAME': 'Funcionário',
  'SM.AUTH.MESSAGE.FUNCAUTH_INTERFACE': 'Interface',
  'SM.AUTH.BUTTON.ROLETMPL_RESET': 'Redefinir',
  'SM.AUTH.TITLE.ROLE_PERMISSION_OBJECT_TYPE': 'Tipo de objeto de permissão',
  'SM.AUTH.TITLE.INPUT_AUTH_OBJ_NAME': 'Inserir um nome de objeto de permissão',
  'SM.FEDERATION.MAPPINGRULE.LOCAL': 'Informações do usuário local',
  'SM.AUTH.LABEL.CLEAR_TIMEOUT_SELECT_MONTH_FRONT': 'Meses (30 Dias) para Reservar',
  'SM.AUTH.LABEL.STAFFROLELOG_ROLE_NAME': 'Nome da função',
  'SM.AUTH.LABEL.ROLE_ID_B_INPUT': 'ID da função (B):',
  'SM.AUTH.LABEL.PERMISSION_CLASS': 'Classificação de permissão',
  'SM.AUTH.BUTTON.OK': 'OK',
  'SM.AUTH.MESSAGE.ROLE_ERROR_HAS_EXCLUDED':
    'Falha ao excluir a função porque exclui outra função.',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DYNA_CONF': 'Sinalizador de configuração dinâmica',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_PERMISSION_FUNCTION': 'Função',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DATA_FILTER_CONDITION': 'Critérios de Filtro de Dados',
  'SM.FEDERATION.COMMON.UPDATE.FAIL': 'Falha na modificação.',
  'SM.FEDERATION.FEDERATIONAPP.VALDATEHTTP': 'Formato de URL inválido',
  'SM.AUTH.LABEL.ROLETMPL_START_TIME': 'Hora de início',
  'SM.AUTH.LABEL.DATA_DICTABLE_ID': 'Código do Dicionário de Dados',
  'SM.AUTH.LABEL.DATAAUTHMGR_TITLE_AUTHNAME_CONF_INPUT': 'Nome da permissão:',
  'SM.AUTH.LABEL.ROLE_EXCLUDINGROLE': 'Excluindo Função',
  'SM.AUTH.MESSAGE.OPERATE_SUCCESS': 'Operação executada com sucesso.',
  'SM.AUTH.LABEL.FUNCAUTH_PARENTAUTH': 'ID de permissão pai',
  'SM.AUTH.LABEL.PERMISSION_INPUT': 'Autoridade:',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_PERMISSION_MENU': 'Menu',
  'SM.AUTH.MESSAGE.ROLETMPL_QUERY_FAILED': 'Falha na pesquisa.',
  'SM.AUTH.LABEL.ROLE_NAME_INPUT': 'Nome da função:',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_PUT_FUNCTION': 'Permissão de função concedida',
  'SM.AUTH.LABEL.ROLE_NORMALROLE': 'Função comum',
  'SM.AUTH.OPTION.DISALLOWED_FUNCTION_PERM': 'Permissão de função excluída',
  'SM.AUTH.MESSAGE.ROLETMPL_ROLETMPL_EXISTS': 'O nome do modelo de função já existe.',
  'SM.AUTH.MESSAGE.DATAPER_NO_PERM': 'Você não tem a permissão de operação.',
  'SM.AUTH.LABEL.ROLE_ROLE_ID': 'ID da função',
  'SM.AUTH.LABEL.OPERATION_TYPE': 'Tipo de operação',
  'SM.AUTH.MESSAGE.FUNCAUTH_UPLOADINFO': 'Selecione Formato de anexo:',
  'SM.AUTH.LABEL.DATA': 'Permissão de dados',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DISPLAYNO_FIELD': 'Campo de classificação',
  'SM.AUTH.TITLE.ORG_SELECTION': 'Seleção da Organização',
  'SM.AUTH.LABEL.ROLE': 'Função',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_STAUTS_PAUSE': 'Suspensar',
  'SM.AUTH.TITLE.USERAUTHVIEW_ROLEVIEW': 'Visualização de função',
  'SM.AUTH.LABEL.FUNCAUTH_ROLE_GRANTABLE': 'Permissão concedida de função',
  'SM.AUTH.LABEL.DATAPROVIDER_TYPE_INPUT': 'Tipo de origem do valor de dados:',
  'SM.AUTH.TITLE.USERAUTHVIEW_ATTR_BE': 'BE',
  'SM.AUTH.TITLE.USERAUTHVIEW_ROLE_TYPE': 'Tipo de função',
  'SM.FEDERATION.IMPORT.USER': 'Importar usuário federado',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DISPLAYNO_FIELD_INPUT': 'Campo de classificação:',
  'SM.AUTH.LABEL.CLEAR_TIMEOUT_SELECT_MONTH_END': 'dados do mês (um mês é 30 dias).',
  'SM.AUTH.LABEL.BIZ_OBJ_PRIVACY_LEVEL_SECRET': 'Segredo',
  'SM.AUTH.TITLE.STAFFROLELOG_LIST': 'Lista de registro de função do funcionário',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_USERNAME_VALIDATOR':
    'O nome de usuário contém caracteres especiais inválidos.',
  'SM.FEDERATION.IMPORT.FAIL': 'Falha',
  'SM.AUTH.MESSAGE.ROLE_ERROR_HAS_USERS':
    'Falha ao excluir a função porque a função foi vinculada a um funcionário.',
  'SM.AUTH.LABEL.ROLE_PARENTROLE_BENAME': 'Home BE da função geral',
  'SM.AUTH.LABEL.FUNCAUTH_STATUS_INPUT': 'Status:',
  'SM.AUTH.LABEL.CLEAR_POPUP_TITLE': 'Excluir dados expirados',
  'SM.AUTH.TITLE.ROLE_HORTCUT_MENU': 'Menu de atalho',
  'SM.AUTH.LABEL.MODULE': 'Módulo',
  'SM.AUTH.LABEL.ROLETMPL_ASSINGED_PERMISSION': 'Conceder permissão para função',
  'SM.AUTH.TITLE.INPUT_ROLE_NAME': 'Nome da função de entrada',
  'SM.AUTH.LABEL.ROLE_NO_INFO': 'Gerenciar funções na árvore de funções à esquerda.',
  'SM.AUTH.LABEL.BIZ_OBJ_LIST': 'Lista de objetos comerciais',
  'SM.AUTH.LABEL.ROLE_SUPERID': 'ID da função pai',
  'SM.AUTH.TITLE.ROLEAUTHVIEW_USERVIEW': 'Visualização do Funcionário',
  'SM.AUTH.LABEL.ROLE_CONFIG_INFO': 'Informações da função',
  'SM.AUTH.LABEL.STAFFROLELOG_OPER_TIME': 'Tempo de operação',
  'SM.AUTH.LABEL.THIRD_DATASOURCE': 'Terceira Fonte de Dados',
  'SM.AUTH.BUTTON.ADDAUTH': 'Adicionar',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_STAUTS_DISCARDED': 'Excluir',
  'SM.AUTH.TIPS.FUNCAUTH_YES': 'Sim',
  'SM.FEDERATION.COMMON.UPDATE.SUCCESS': 'Modificado com sucesso.',
  'SM.AUTH.MESSAGE.FUNCAUTH_UPLOADSIZE': 'Tamanho máximo do anexo (bytes):',
  'SM.AUTH.MESSAGE.DATAPER_OBJ_TREE_ROOT': 'Árvore Objeto de Permissão',
  'SM.AUTH.TITLE.ROLEAUTHVIEW_AUTHVIEW': 'Visualização de permissão',
  'SM.AUTH.LABEL.BIZ_OBJ_PRIVACY_LEVEL_CONFIDENTIAL': 'Confidencial',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DATA_FILTER_CONDITION_INPUT':
    'Critérios de Filtro de Dados:',
  'SM.AUTH.LABEL.ROLEBUSINESS_ENTITY_ID': 'ID BE da função',
  'SM.AUTH.MESSAGE.FUNCAUTH_DISABLE': 'Indisponível',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_OPER_TYPE_LIST': 'ID da lista de tipo de operação',
  'SM.AUTH.BUTTON.IMPORT_ROLE': 'Importar função',
  'SM.FEDERATION.COMMON.RESET': 'Redefinir',
  'SM.FEDERATION.FEDERATIONAPP.MAPPINGRULE': 'Regra de mapeamento de usuário Federado',
  'SM.AUTH.TITLE.USERAUTHVIEW_DATA_OBJECT_TREE': 'Árvore Objeto de Permissão',
  'SM.AUTH.BUTTON.ROLE_COPY_ROLE_AND_CREATE':
    'Permissão de função Copiar & Permissão de Dados e Criar Função',
  'SM.AUTH.LABEL.ROLETMPL_SEARCH_CRITERIA': 'Pesquisar critérios',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_PARENT_NODE_FIELD_INPUT': 'Nome do Campo PARENT_ID:',
  'SM.AUTH.LABEL.ROLE_EXCLUDEDROLE': 'Função excluída',
  'SM.AUTH.TITLE.ROLEAUTHVIEW_PARENT_ROLETYPE': 'Papel geral',
  'SM.FEDERATION.FEDERATIONAPP.EMAIL': 'Email',
  'SM.AUTH.LABEL.ROLE_COPY_MODE_INPUT': 'Modo de cópia:',
  'SM.FEDERATION.COMMON.OPERATION': 'Operação',
  'SM.AUTH.LABEL.ROLEPERMISSION_LOGL_IST': 'Lista de log de permissão de função',
  'SM.AUTH.LABEL.FUNCAUTH_DEPENDENT': 'Dependente da permissão',
  'SM.AUTH.TITLE.ROLE_ASSIGNMENT_TIME': 'Tempo de atribuição',
  'SM.AUTH.LABEL.STAFFROLELOG_ACCOUNT_INPUT': 'Conta:',
  'SM.AUTH.LABEL.FUNCAUTH_LOADMORE': 'carregar mais',
  'SM.FEDERATION.IMPORT.FILENAMEVALIDATEFAIL':
    'Nome do arquivo incorreto. Selecione um arquivo cujo nome contém apenas caracteres chineses, letras, dígitos ou sublinhados (_).',
  'SM.AUTH.TITLE.ROLE_NOT_HOLD': 'Lista de informações não concessíveis',
  'SM.AUTH.TITLE.ROLE_EXTENDED_PROPERTY': 'Atributo estendido',
  'SM.AUTH.TITLE.USER_PERM_COMPARE': 'Comparação da permissão do usuário',
  'SM.AUTH.LABEL.BIZ_OBJ_SCOPE': 'Âmbito',
  'SM.FEDERATION.IMPORT.DOWLOADIMPORTRESULT': 'Baixar',
  'SM.FEDERATION.IMPORT.UPLOADSELECT': 'Selecione um arquivo.',
  'SM.AUTH.TITLE.USERAUTHVIEW_OPERTYPE': 'Tipo de operação',
  'SM.AUTH.LABEL.ROLE_TMPL_LIST': 'Lista de modelos de função',
  'SM.AUTH.MESSAGE.DATAPER_CONFIRM_DEL': 'Deseja realmente excluir?',
  'SM.AUTH.LABEL.FUNCAUTH_AUTHNAME': 'Nome da permissão',
  'SM.AUTH.LABEL.ROLE_COPY_TARGETROLE': 'Função alvo',
  'SM.AUTH.BUTTON.ROLE_DESELECT_ALL': 'Cancelar a seleção de tudo',
  'SM.AUTH.BUTTON.ROLETMPL_SEARCH': 'Pesquisar',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_TYPE_TABLE_INPUT': 'Tabela:',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_BASE_FUNCTION': 'Permissão de Função Básica',
  'SM.AUTH.LABEL.ROLE_STAFF_LIST': 'Lista de Funcionários Atribuídos',
  'SM.AUTH.TITLE.USERAUTHVIEW_ROLE_ID': 'ID da função',
  'SM.AUTH.MESSAGE.ROLETMPL_CANNOT_SELECT_MODULE': 'O módulo não pode ser selecionado.',
  'SM.FEDERATION.IMPORT.IPLODATIP':
    'Importando dados... Exibir detalhes na área Resultado de importação de usuário federado.',
  'SM.AUTH.MESSAGE.ROLETMPL_TIME_CONDITION': 'A hora deve ser posterior ao horário inicial.',
  'SM.AUTH.TITLE.ROLE_PERMISSION_OBJECT_NAME': 'Nome do objeto de permissão',
  'SM.AUTH.TITLE.CONFIRM_PERM_RECOVERY': 'Confirmar',
  'SM.AUTH.TITLE.ROLE_SHORTCUT_MENU_TOOLBAR': 'Menu de atalho',
  'SM.AUTH.TITLE.USERAUTHVIEW_TITLE_AUTHNAME_CONF': 'Nome da permissão',
  'SM.AUTH.LABEL.BIZ_OBJ_FIELD_PRIVACY': 'Nível de Privacidade do Campo',
  'SM.AUTH.TITLE.SELECT_SHORT_CUT': 'Selecionar menu de atalho',
  'SM.FEDERATION.FEDERATIONAPP.EDIT': 'Modificar aplicativo Federado',
  'SM.AUTH.LABEL.ROLE_ID_B': 'ID da função (B)',
  'SM.AUTH.LABEL.ROLE_ID_A': 'ID da função (A)',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_IS_BE_FILTER_INPUT': 'Filtrar por BE:',
  'SM.AUTH.TITLE.USERAUTHVIEW_LOG_OBJ_NAME': 'Nome do objeto',
  'oms.operationparam.msg.unsafeinfo':
    'Observe que o protocolo de rede usado pelo endereço atual não é seguro. Se você decidir usá-lo, podem existir riscos de segurança.',
  'SM.AUTH.LABEL.ROLE_TYPE_INPUT': 'Tipo de função:',
  'SM.AUTH.LABEL.ROLE_COPY_SOURCEROLE_INPUT': 'Função de origem:',
  'SM.AUTH.MESSAGE.PASSWORD_RULE_CONFIRM_MESSAGE_41':
    'Definir este período muito curto afetará o nível de segurança da senha. Você confirma a alteração?',
  'SM.AUTH.LABEL.DATA_DICTABLE_ID_INPUT': 'Código do Dicionário de Dados:',
  'SM.FEDERATION.IMPORT.STARTTIME': 'Hora de início',
  'SM.AUTH.TITLE.USERAUTHVIEW_USER_INFORMATION': 'Informações do funcionário',
  'SM.AUTH.LABEL.FUNCAUTH_DELETE_CHECK2': 'funções também serão eliminadas:',
  'SM.AUTH.TIPS.ROLE_INPUTROLENAME': 'Inserir um nome de função',
  'SM.AUTH.LABEL.FUNCAUTH_DELETE_CHECK1':
    'Se você excluir a permissão, as informações de autorização dos funcionários relacionados e o seguinte',
  'SM.AUTH.MESSAGE.ROLETMPL_CANT_COPY_PERMISSION':
    'Você não pode copiar certa permissão. Continuar?',
  'SM.AUTH.MESSAGE.FUNCAUTH_LANGUAGE': 'Idioma',
  'SM.AUTH.MESSAGE.PASSWORD_LENTH_LESS_THAN_8':
    'Se o comprimento da senha for muito curto, podem ocorrer riscos de segurança. Deseja continuar?',
  'SM.FEDERATION.FEDERATIONAPP.ERROR.INVALIDFIELD': 'Campo inválido.',
  'SM.FEDERATION.FEDERATIONAPP.ERROR.PARSEFAIL':
    'Falha ao analisar informações externas do usuário.',
  'SM.AUTH.LABEL.ROLE_COPY_MODE': 'Modo de cópia',
  'SM.AUTH.LABEL.FUNCAUTH_ROLE_GRANTABLE_DETAIL':
    'Funções que podem conceder a permissão da função a outros',
  'SM.AUTH.LABEL.OPERATION_TYPE_INPUT': 'Tipo de operação:',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_TREE_INFO_CONF_INPUT': 'Configuração adicional da árvore:',
  'SM.AUTH.LABEL.ROLE_TYPE': 'Tipo de função',
  'SM.FEDERATION.IMPORT.FILESUFFIXVALIDATEFAIL':
    'Formato de arquivo incorreto. Selecione um arquivo XLSX.',
  'SM.AUTH.LABEL.ROLETMPL_END_TIME_INPUT': 'Hora de término:',
  'SM.FEDERATION.FEDERATIONAPP.NAME': 'Cuenta de usuario',
  'SM.FEDERATION.COMMON.DELETE.SUCCESS': 'Exclusão com sucesso',
  'SM.AUTH.MESSAGE.PASSWORD_RULE_CONFIRM_MESSAGE_39':
    'Abrir esta regra afetará a segurança da senha, confirme se você continua abrindo?',
  'SM.AUTH.TIPS.ROLETMPL_CREATE_AND_ASSIGN': 'Criar uma função e atribuir permissão',
  'SM.AUTH.TITLE.INPUT_ROLE_TMPL_NAME': 'Nome do modelo de função de entrada',
  'SM.AUTH.LABEL.ROLETMPL_ROLE_ASSOCIATION_LOG': 'Registro de associação de funções',
  'SM.AUTH.TITLE.INPUT_USER_LOGINID': 'Inserir uma conta de usuário',
  'SM.FEDERATION.FEDERATIONAPP.ERROR.EXISTS':
    'O nome do sistema de autenticação de terceiros, URL ou nome da regra de mapeamento já existe.',
  'SM.AUTH.LABEL.EMPLOYEEINCLUDED': 'Incluir Pessoal Sub-OU',
  'SM.AUTH.LABEL.FUNCAUTH_AUTHTYPE_INPUT': 'Tipo de permissão:',
  'SM.AUTH.BUTTON.SAVE': 'Salvar',
  'SM.AUTH.TITLE.PERMISSION_SELECTION': 'Selecionar permissão',
  'SM.AUTH.LABEL.STAFFROLELOG_OPER_ACCOUNT_INPUT': 'Operador:',
  'SM.AUTH.LABEL.ROLETMPL_PERMISSION_NAME_INPUT': 'Nome da permissão da função:',
  'SM.AUTH.LABEL.FUNCAUTH_MODULE_INPUT': 'ID do subsistema:',
  'SM.FEDERATION.IMPORT.FAILCOUNT': 'Registros com falha',
  'SM.AUTH.TITLE.ROLE_PERMISSION_TIPS':
    'Para executar operações em lote na árvore de permissões, use menus de atalho nos nós de permissão.',
  'SM.AUTH.LABEL.ROLE_ID_INPUT': 'ID da função:',
  'SM.AUTH.LABEL.FUNCAUTH_BASICINFO': 'Informações básicas',
  'SM.AUTH.MESSAGE.ROLE_ERROR_BEED_EXCLUDED':
    'Falha ao excluir a função porque exclui outra função.',
  'SM.AUTH.TITLE.USERAUTHVIEW_ROLE_NAME': 'Nome da função',
  'SM.AUTH.TITLE.ROLEAUTHVIEW_DATAAUTHLIST': 'Lista de objetos de permissão',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DESC_FIELD': 'Nome do Nome do Campo',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_KEY_FIELD': 'Nome do ID do Campo',
  'SM.AUTH.OPTION.ROLE_AUTH_REMOVE': 'Excluir',
  'SM.AUTH.TITLE.ROLE_PERMISSION_OBJECT_ID': 'ID do objeto de permissão',
  'SM.AUTH.LABEL.PERMISSION': 'Permissão',
  'SM.AUTH.MESSAGE.ROLE_DELETE_SUCCESSFUL': 'Excluído com sucesso.',
  'SM.AUTH.LABEL.ROLE_PARENTROLE_ID': 'ID de função geral',
  'SM.AUTH.TITLE.USERAUTHVIEW_AUTH': 'Permissão de função',
  'SM.AUTH.LABEL.FUNCAUTH_ATTRIBUTE': 'Estender o atributo',
  'SM.AUTH.BUTTON.USERAUTHVIEW_RESET': 'Redefinir',
  'SM.AUTH.TITLE.ASSIGN_EMPLOYEE': 'Atribuir funcionário',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_TYPE_TABLE': 'Tabela',
  'SM.AUTH.MESSAGE.DATAPER_DEL_SUCCESS': 'Excluído com sucesso.',
  'SM.AUTH.TITLE.USERAUTHVIEW_ACCOUNTLOGIN': 'Conta',
  'SM.FEDERATION.COMMON.CONFIRM': 'Confirmar',
  'SM.AUTH.TIPS.ROLETMPL_ASSIGN_PERMISSON': 'Atribuir permissão',
  'SM.AUTH.TIPS.ROLETMPL_VIEW_DETAIL_PERMISSION':
    'Exibir detalhes sobre permissões que você não pode copiar no modelo de função.',
  'SM.AUTH.TITLE.ROLE_BASIC_FUNCTION_PERMISSION': 'Permissão de Função Básica',
  'SM.AUTH.HEADVALUE.AUTHUSERVIEW_AUTH_DATA': 'Escolha...',
  'SM.AUTH.MESSAGE.OPERATEFAIL': 'Falha ao salvar.',
  'SM.FEDERATION.FEDERATIONAPP.ERROR.PARAMNULL': 'O parâmetro solicitação está vazio.',
  'SM.AUTH.LABEL.ROLETMPL_TEMPLATENAME_INPUT': 'Nome do modelo de função:',
  'SM.AUTH.TITLE.CUSTOMER_ROLESCOPE_PERMISSION':
    'Quando o escopo de uso não é um funcionário, você não pode atribuir funções aos funcionários',
  'SM.FEDERATION.COMMON.SUCCESS': 'Successo',
  'SM.AUTH.MESSAGE.PASSWORD_RULE_CONFIRM_MESSAGE_12':
    'Desabilitar esta regra afetará a segurança da senha. Tem certeza de que deseja desativá-lo?',
  'SM.AUTH.LABEL.OPPAUTH_SOURCE_TYPE': 'Fonte de permissão',
  'SM.AUTH.MESSAGE.PASSWORD_RULE_CONFIRM_MESSAGE_10':
    'Caracteres especiais válidos afetarão a segurança da senha. Tem certeza de que deseja usá-los?',
  'SM.AUTH.LABEL.ROLETMPL_PERMISSION_ID': 'ID da permissão da função',
  'SM.AUTH.LABEL.FUNCAUTH_ROLE_AVAILABLE_DETAIL': 'Funções que podem usar a permissão da função',
  'SM.AUTH.TITLE.ROLE_DELEVER_DATA_PERMISSION': 'Conceder permissão de dados',
  'SM.AUTH.LABEL.ROLE_PARENTS_TITLE': 'Informações sobre herança de função',
  'SM.FEDERATION.IMPORT.UNDO': 'Pendente',
  'SM.FEDERATION.FEDERATIONAPP.ACCOUNT': 'Conta',
  'SM.AUTH.LABEL.ROLE_COPY_TARGETROLE_INPUT': 'Função alvo:',
  'SM.AUTH.MESSAGE.SELECTA_ROLE': 'Selecione uma função.',
  'SM.AUTH.BUTTON.USERAUTHVIEW_SEARCH': 'Pesquisar',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_SERVICE': 'Nome do serviço',
  'SM.AUTH.BUTTON.PERM_RECOVERY': 'Reciclagem',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_IS_BE_FILTER': 'Filtrar por BE',
  'SM.AUTH.MESSAGE.ROLE_SURE_TO_DELETE': 'Deseja realmente excluir?',
  'SM.AUTH.LABEL.ROLE_ID_A_INPUT': 'ID da função (A):',
  'SM.AUTH.LABEL.DATAAUTHMGR_TAB_AUTH_ROLE_VIEW': 'Funções Associadas à Permissão de Dados',
  'SM.AUTH.LABEL.ROLE_DERIVE_NORMALROLE': 'Função comum derivada',
  'SM.AUTH.LABEL.ROLE_ASSIGN_STAFF': 'Funcionários atribuídos',
  'SM.AUTH.TITLE.ROLE_TMPLATE_ID': 'ID do modelo de função',
  'SM.AUTH.LABEL.ATTR_LANGUAGE': 'Idioma',
  'SM.AUTH.BUTTON.EXPORT': 'Exportar',
  'SM.AUTH.LABEL.BIZ_OBJ_FIELD_PRIVILEGE_SETTING': 'Configuração',
  'SM.AUTH.MESSAGE.OPERATE_WITH_ROLES':
    'A permissão foi criada com sucesso e atribuída ao operador atual e às seguintes funções:',
  'SM.AUTH.MESSAGE.ROLETMPL_DELETE_SUCCESS': 'Excluído com sucesso.',
  'SM.AUTH.LABEL.STAFFROLELOG_ROLE_BE_ID_INPUT': 'A função BE:',
  'SM.AUTH.TIPS.FUNCAUTH_STOPUSE': 'Suspensar',
  'SM.AUTH.MESSAGE.OPERATE_WITHOUT_ROLES':
    'A permissão foi criada com sucesso e atribuída ao operador atual.',
  'SM.AUTH.MESSAGE.ROLE_OPP_WITH_PARENT': 'A função selecionada é oposta à função herdada:',
  'SM.AUTH.LABEL.STAFFROLELOG_ROLE_NAME_INPUT': 'Nome da função:',
  'SM.AUTH.TIPS.ENTER_AUTHNAME': 'Introduza o nome da permissão',
  'SM.AUTH.TITLE.TO_CHOOSE_ROLE_TMPL_INPUT': 'Modelo de função a escolher:',
  'SM.AUTH.TITLE.USERAUTHVIEW_AUTH_MODULE': 'Módulo',
  'SM.AUTH.LABEL.LOGINLOG_RESET': 'Redefinir',
  'SM.AUTH.MESSAGE.FUNCAUTH_PAUTHNAME': 'Nome da permissão',
  'SM.AUTH.LABEL.FUNCAUTH_AUTHID_INPUT': 'ID da permissão:',
  'SM.AUTH.TITLE.ROLE_BASIC_DATA_PERMISSION': 'Permissão de dados básicos',
  'SM.AUTH.MESSAGE.ROLE_CAN_NOT_ADD_ITSELF': 'Não pode herdar sua própria função.',
  'SM.FEDERATION.MAPPINGRULE.NAME': 'Regra de mapeamento',
  'SM.AUTH.MESSAGE.SAVE_DEPENDENT_SELF': 'Dependendo da permissão em si, isso não é permitido.',
  'SM.FEDERATION.IMPORT.STATUS': 'Status',
  'SM.AUTH.LABEL.FUNCAUTH_ROLE_UNAVAILABLE_DETAIL':
    'Funções que não podem usar a permissão da função',
  'SM.AUTH.LABEL.ROLETMPL_HOME_ENTITY': 'Página inicial da permissão BE',
  'SM.AUTH.TITLE.USERAUTHVIEW_AUTH_DATA': 'Permissão de dados',
  'SM.AUTH.LABEL.BIZ_OBJ_FIELD_IS_WRITEABLE': 'É Gravável',
  'SM.AUTH.MESSAGE.FUNCAUTH_NORMAL': 'Normal',
  'SM.AUTH.MESSAGE.ROLE_NAME_SAME': 'O nome da função já existe.',
  'SM.AUTH.LABEL.USERAUTHVIEW_SEARCH': 'Consulta',
  'SM.AUTH.LABEL.DATAPROVIDER_TYPE': 'Tipo de origem do valor de dados',
  'SM.AUTH.BUTTON.ROLE_COPY_ROLE':
    'Copiar Informações da Função (Apenas permissão de função de cópia e permissão de dados)',
  'SM.AUTH.TITLE.USERAUTHVIEW_USER_LIST': 'Lista de funcionários',
  'SM.AUTH.LABEL.ROLETMPL_PERMISSION_NAME': 'Nome da permissão da função',
  'SM.AUTH.LABEL.USER_ACCOUNTB': 'Conta (B)',
  'SM.AUTH.LABEL.USER_ACCOUNTA': 'Conta (A)',
  'SM.AUTH.LABEL.ROLE_NAME': 'Nome da função',
  'SM.FEDERATION.COMMON.DOWLOADTEM': 'Baixar modelo',
  'SM.AUTH.LABEL.ROLETMPL_ROLE_NAME': 'Nome da função',
  'SM.AUTH.LABEL.ORGANIZATION_INPUT': 'Unidade da Organização:',
  'SM.AUTH.LABEL.ROLE_SCOPE': 'Escopo da função',
  'SM.AUTH.MESSAGE.ROLE_NOT_EXSIT': 'A função não existe.',
  'SM.AUTH.LABEL.AUTHVIEW_ROLE_BE_ID': 'Home BE ID da função',
  'SM.AUTH.MESSAGE.DATAPER_TREE_ROOT': 'Árvore de permissão de dados',
  'SM.AUTH.BUTTON.AUTH_EDIT': 'Modificar',
  'SM.AUTH.TITLE.SUCCESS': 'Informações',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_OPERATION_INPUT': 'Nome da operação:',
  'SM.AUTH.NO.SELECT': 'Nenhuma permissão selecionada',
  'SM.AUTH.MESSAGE.AUTH_DATA_OBJECT_TREE': 'Árvore Objeto de Permissão',
  'SM.FEDERATION.COMMON.CREATE.FAIL': 'Falha na criação.',
  'SM.FEDERATION.FEDERATIONAPP.NEW': 'Criar aplicativo Federado',
  'SM.AUTH.BUTTON.ROLE_SELECT_ALL': 'Selecionar tudo',
  'SM.AUTH.LABEL.FUNCAUTH_PARENTAUTH_INPUT': 'ID de permissão pai:',
  'SM.AUTH.LABEL.USER_ACCOUNTB_INPUT': 'Conta (B):',
  'SM.FEDERATION.COMMON.QUERY.CONDITION': 'Critérios de pesquisa',
  'SM.AUTH.BUTTON.CANCEL': 'Cancelar',
  'SM.AUTH.TITLE.USERAUTHVIEW_LOG_OBJ_ID': 'ID do objeto',
  'SM.AUTH.TITLE.USERAUTHVIEW_QUERYCONDITION_TITLE': 'Pesquisar critérios',
  'SM.AUTH.LABEL.ROLE_ID': 'ID da função',
  'SM.AUTH.LABEL.DATAAUTHMGR_ATTRIBUTE': 'Estender o atributo',
  'SM.FEDERATION.DOWNLOAD.RESULT': 'Download dos resultados da importação do usuário federado',
  'SM.FEDERATION.FEDERATIONAPP.CLIENTURL': 'URL',
  'SM.AUTH.LABEL.ROLETMPL_END_TIME': 'Hora de término',
  'SM.AUTH.LABEL.STAFFROLELOG_ROLE_BE_ID': 'Função BE',
  'SM.AUTH.TITLE.ASSIGNED_EMPLOYEE': 'Funcionários atribuídos',
  'SM.AUTH.LABEL.ROLETMPL_BASIC_FUNCTION_PERMISSION': 'Permissão de Função Básica',
  'SM.AUTH.LABEL.ROLETMPL_DESCRITPION': 'Descrição',
  'SM.AUTH.TITLE.ADD_ROLE_FROM_ROLE_TMPL': 'Selecionar modelo de função',
  'SM.AUTH.TITLE.USERAUTHVIEW_QUERY_AUTHTYPE': 'Tipo de permissão',
  'SM.AUTH.LABEL.ROLE_CODE': 'Código da função',
  'SM.FEDERATION.IMPORT.SUCCESSCOUNT': 'Registros bem-sucedidos',
  'SM.AUTH.TIPS.ROLETMPL_WARNING': 'Aviso',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_BASE_DAUTH': 'Permissão de dados básicos',
  'SM.AUTH.LABEL.ROLE_DERIVE': 'Herança de função',
  'SM.AUTH.LABEL.STAFFROLELOG_ROLE_ID': 'ID da função',
  'SM.AUTH.LABEL.STAFFROLELOG_ACCOUNT': 'Conta',
  'SM.AUTH.TITLE.CUSTOMER_ROLESCOPE': 'Cliente',
  'SM.AUTH.LABEL.ROLE_COPY_SOURCEROLE': 'Função de origem',
  'SM.AUTH.LABEL.BIZ_OBJ_FULL_NAME': 'Nome completo do objeto',
  'SM.AUTH.MESSAGE.ROLE_SELECT_A_ROLE': 'Selecione uma função.',
  'SM.AUTH.LABEL.DETAIL_INFO':
    'Selecione um nó de permissão na árvore de navegação à esquerda para exibir as informações sobre a permissão.',
  'SM.FEDERATION.FEDERATIONAPP.ERROR.ISUSED':
    'Os dados não podem ser excluídos ou modificados porque foram usados por um Tenant.',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_TREE_INFO_CONF': 'Configuração adicional da árvore',
  'SM.AUTH.LABEL.ROLETMPL_TEMPLATENAME': 'Nome do modelo de função',
  'SM.AUTH.LABEL.FUNCAUTH_ISDY': 'Sinalizador de configuração dinâmica',
  'SM.AUTH.BUTTON.CONFIRM_DELETE': 'Confirmar exclusão',
  'SM.AUTH.TITLE.USERAUTHVIEW_AUTHVIEW_DATAAUTHLIST': 'Lista de objetos de permissão',
  'SM.AUTH.TITLE.TO_CHOOSE_ROLE_TMPL': 'Selecionar modelo de função',
  'SM.AUTH.LABEL.CLEAR_CONFIRM_CLEAR_DATA':
    'Tem certeza de que deseja excluir permissões não usadas recentemente?',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_ID_TREE_INPUT': 'Estrutura da árvore:',
  'SM.AUTH.LABEL.BIZ_OBJ_PRIVACY_LEVEL_PUBLIC': 'Público',
  'SM.AUTH.LABEL.ROLE_AUTH_DATA_INPUT': 'Permissão de dados:',
  'SM.AUTH.LABEL.PERMISSION_CLASS_INPUT': 'Classificação de permissão:',
  'SM.FEDERATION.FEDERATIONAPP.REMOTETIP':
    'O valor é o campo de mapeamento correspondente ao usuário externo.',
  'SM.FEDERATION.MAPPINGRULE.DESC': 'Descrição',
  'SM.AUTH.LABEL.ROLE_SUPERCODE_INPUT': 'Código da função pai:',
  'SM.FEDERATION.IMPORT.RESULT': 'Resultado de importação de usuário federado',
  'SM.AUTH.LABEL.ROLE_SUPERCODE': 'Código da função pai',
  'SM.AUTH.TITLE.ROLEAUTHVIEW_COMMON_ROLETYPE': 'Função comum',
  'SM.AUTH.LABEL.ROLETMPL_ROLE_ID': 'ID da função',
  'SM.AUTH.LABEL.CONFIRM_DELETE_DEPEND_AUTH': 'Confirmar para excluir a permissão dependente?',
  'SM.AUTH.MESSAGE.ROLETMPL_SHOWNAME': 'Modelo de função',
  'SM.AUTH.LABEL.COMPARE_RESULT': 'Resultado da comparação',
  'SM.AUTH.TITLE.ROLE_MUTUALLY_EXCLUSIVE_PERMISSION': 'Lista de permissões mutuamente exclusiva',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_CODE_INPUT': 'Código de permissão:',
  'SM.FEDERATION.FEDERATIONAPP.DELETETIP': 'Tem certeza de que deseja excluir os dados?',
  'SM.AUTH.TITLE.USERAUTHVIEW_AUTH_STATUS': 'Status',
  'SM.AUTH.TITLE.BELONG_ROLE_TMPL': 'Modelo de Função do Proprietário',
  'SM.AUTH.LABEL.FUNCAUTH_ROLE_AVAILABLE': 'Permissão de função disponível',
  'SM.AUTH.LABEL.USER_ACCOUNTA_INPUT': 'Conta (A):',
  'SM.FEDERATION.COMMON.FAIL': 'Erro',
  'SM.FEDERATION.IMPORT.IMPORTRESULT': 'Resultado de importação',
  'SM.AUTH.OPTION.ROLE_REMOVE_INFO_OF_TARGET_ROLE': 'Excluir informações sobre a função de destino',
  'SM.FEDERATION.IMPORT.UPLOADFILE': 'arquivo',
  'SM.AUTH.TITLE.USERAUTHVIEW_AUTHSOURCE': 'Fonte de permissão',
  'SM.AUTH.TITLE.AUTHNAMEINFO': 'Informações sobre nome da permissão',
  'SM.AUTH.LABEL.LOGINLOG_SEARCH': 'Pesquisar',
  'SM.AUTH.TIPS.ROLE_PARENTROLE': 'Nome da função',
  'SM.AUTH.LABEL.ROLE_DESC': 'Descrição',
  'SM.AUTH.LABEL.FUNCAUTH_OPPAUTHNAME': 'Permissão mutuamente exclusiva',
  'SM.FEDERATION.IMPORT.ENDTIME': 'Hora de fim',
  'SM.FEDERATION.COMMON.OPERATION.DELETE': 'Excluir',
  'SM.FEDERATION.FEDERATIONAPP.LIST': 'Aplicativos Federados',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_PUT_DAUTH': 'Permissão de dados concedida',
  'SM.AUTH.LABEL.ROLE_NORMALROLE_ID': 'Código de Função Comum',
  'SM.AUTH.TITLE.ROLE_TMPL_ROLE_LIST': 'Lista de funções',
  'SM.FEDERATION.COMMON.OPERATION.EDIT': 'Editar',
  'SM.AUTH.LABEL.ROLE_ROLE_NAME': 'Nome da função',
  'SM.AUTH.MESSAGE.ROLETMPL_SELECT_NO_ENTITY': 'Selecione pelo menos uma função ou BE.',
  'SM.AUTH.BUTTON.CLEAR_ALL_TIPS': 'Limpar permissão indisponível',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_CODE': 'ID da permissão',
  'SM.AUTH.MESSAGE.OK': 'OK',
  'SM.AUTH.TITLE.CONFIRM_DEL': 'Confirmar',
  'SM.AUTH.LABEL.FUNCAUTH_MODULE': 'ID do subsistema',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_OPP_FUNCTION': 'Permissão de função excluída',
  'SM.AUTH.LABEL.ROLE_INFO': 'Informações da função',
  'SM.AUTH.LABEL.DATAAUTHMGR_TITLE_AUTHNAME_CONF': 'Nome da permissão',
  'SM.AUTH.MESSAGE.ROLETMPL_SELECT_NODE_INFO':
    'Selecione um nó anterior na árvore de navegação à esquerda e gerencie modelos de função na árvore de modelos de função.',
  'SM.AUTH.LABEL.MENU_AUTHID': 'ID da permissão',
  'SM.AUTH.TITLE.INFO': 'Informações',
  'SM.AUTH.MESSAGE.FUNCAUTH_READONLY': 'Nenhuma atribuível',
  'SM.AUTH.LABEL.ROLETMPL_START_TIME_INPUT': 'Hora de início:',
  'SM.FEDERATION.IMPORT.FAILFILE': 'Arquivos com falha',
  'SM.AUTH.LABEL.FUNCAUTH_DESC_INPUT': 'Descrição:',
  'SM.AUTH.LABEL.BIZ_OBJ_NAME': 'Nome',
  'SM.AUTH.LABEL.ORGANIZATION': 'Unidade de Organização',
  'SM.AUTH.BUTTON.DELETE': 'Excluir',
  'SM.AUTH.BUTTON.CLOSE': 'Fechar',
  'SM.AUTH.MESSAGE.PERM_RECOVERY':
    "Se você clicar em 'OK', as permissões de todas as funções a seguir serão revogadas. Se você clicar em 'Cancelar', nenhuma operação será executada.",
  'SM.AUTH.MESSAGE.ROLE_RELATIONSHIP_EXISTS_NOT_BEEXCLUDING':
    'A relação de herança já existe e a função não pode ser excluída.',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_KEY_FIELD_INPUT': 'Nome do ID do campo:',
  'SM.FEDERATION.IMPORT.FAILREASON': 'Causa de falha',
  'SM.AUTH.LABEL.FUNCAUTH_UPLOAD': 'Carregar anexo',
  'SM.AUTH.LABEL.BIZ_OBJ_FIELD': 'Campo',
  'SM.AUTH.BUTTON.ROLE_DELETE_FOREVER': 'Excluir permanentemente',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DYNA_CONF_INPUT': 'Sinalizador de configuração dinâmica:',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DESC_INPUT': 'Descrição:',
  'SM.FEDERATION.COMMON.CREATE.SUCCESS': 'Criado com sucesso',
  'SM.AUTH.LABEL.BE_BASIC_INFORMATION': 'Informações básicas',
  'SM.AUTH.TITLE.USERAUTHVIEW_NAME': 'Funcionário',
  'SM.AUTH.MESSAGE.SAVE_SUCCESS': 'Operação executada com sucesso.',
  'SM.AUTH.LABEL.ROLE_DESC_INPUT': 'Descrição:',
  'SM.AUTH.LABEL.ROLETMPL_ROLETMPL_INFO': 'Informações sobre Modelo de Função',
  'SM.AUTH.TITLE.USERAUTHVIEW_STAFF_BE_NAME': 'Página inicial do funcionário BE',
  'SM.AUTH.TITLE.TO_ASSIGNED_EMPLOYEE': 'Funcionário a Atribuir',
  'SM.AUTH.LABEL.ROLE_NORMALROLE_NAME': 'Nome da função comum',
  'SM.AUTH.LABEL.BIZ_OBJ_ACCESS_RULE': 'Regra de acesso',
  'SM.AUTH.OPTION.GEANTABLE_FUNCTION_PERM': 'Permissão de função concedida',
  'SM.AUTH.LABEL.ROLETMPL_DESCRITPION_INPUT': 'Descrição:',
  'SM.AUTH.TITLE.USERAUTHVIEW_ROLE_PARENTS_TITLE': 'Informações sobre herança de função',
  'SM.AUTH.TIPS.ENTER_ORGNAME': 'Insira o nome da organização',
  'SM.FEDERATION.MAPPINGRULE.REMOTE': 'Informações do usuário externo',
  'SM.AUTH.MESSAGE.FUNCAUTH_FUNCTION': 'Função',
  'SM.FEDERATION.COMMON.QUERY': 'Pesquisar',
  'SM.AUTH.LABEL.ROLETMPL_FUNCTION_PERMISSION': ' Permissão de função',
  'SM.AUTH.LABEL.STAFFROLELOG_OPER_ACCOUNT': 'Operador',
  'SM.AUTH.MESSAGE.ROLE_ERROR_SUBROLES': 'A função não pode ser excluída porque possui subfunções.',
  'SM.AUTH.LABEL.CLEAR_TIMEOUT_SUCCESS_MSG':
    'Permissões excluídas que estão indisponíveis durante o tempo com sucesso.',
  'SM.AUTH.LABEL.AUTHVIEW_ROLE_BE_NAME': 'Home da função BE',
  'SM.AUTH.TITLE.USERAUTHVIEW_USER_LOGINID': 'Conta',
  'SM.AUTH.LABEL.BIZ_OBJ_IS_OWNER': 'É proprietário',
  'SM.AUTH.OPTION.ROLE_RETAIN_INFO_OF_TARGET_ROLE': 'Reter Informações da Função de Alvo',
  'SM.AUTH.LABEL.PERMRECOVERY_ROLE_NAME': 'Função com permissão',
  'SM.AUTH.LABEL.BIZ_OBJ_ACCESS_RULE_ROLE_POLICY': 'Política de acesso à função',
  'SM.AUTH.LABEL.FUNCAUTH_DESC': 'Descrição',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DESC': 'Descrição',
  'SM.AUTH.LABEL.DETAIL': 'Detalhes',
  'SM.AUTH.TITLE.INPUT_AUTHID_AUTHNAME': 'Introduza um ID de permissão ou nome',
  'SM.AUTH.LABEL.TITLE_CLEAR_SUCCESS': 'Solicitar',
  'SM.AUTH.LABEL.STAFFROLELOG_ROLE_ID_INPUT': 'ID da função:',
  'SM.AUTH.LABEL.FUNCAUTH_AUTHNAME_INPUT': 'Nome da permissão:',
  'SM.AUTH.LABEL.ROLE_OPERTYPE': 'Tipo de operação',
  'SM.AUTH.LABEL.ROLETMPL_ROLE_NAME_INPUT': 'Nome da função:',
  'SM.AUTH.TITLE.USERAUTHVIEW_DETAIL_INFO': 'Selecione um nó de permissão com um URL.',
  'SM.FEDERATION.FEDERATIONAPP.VALDATEJSON': 'O item de configuração deve estar no formato JSON.',
  'SM.AUTH.LABEL.FUNCAUTH_INFO': 'Informações básicas',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_ID_TREE': 'Estrutura da árvore',
  'SM.AUTH.TITLE.INPUT_PERMISSION_NAME': 'Inserir um nome de permissão',
  'SM.AUTH.MESSAGE.DELETE_CONFIRM': 'Deseja realmente excluir?',
  'SM.AUTH.TITLE.THIRD_DATASOURCE':
    'Operação executada com sucesso (A terceira fonte de dados está fechada, porque a fonte de dados está vazia.).',
  'SM.AUTH.TITLE.ADD_ROLE_FROM_AUTH_TREE': 'Selecionar permissão de função',
  'SM.FEDERATION.COMMON.CANEL': 'Cancelar',
  'SM.FEDERATION.COMMON.SUBMIT': 'Enviar',
  'SM.AUTH.OPTION.ROLE_AUTH_ADD': 'Adicionar',
  'SM.AUTH.MESSAGE.ROLE_INHERITANCE_RELATIONSHIP_EXISTS': 'A herança já existe.',
  'SM.AUTH.LABEL.ASSIGNEMPLOYEE': 'Atribuição de Funcionários',
  'SM.AUTH.LABEL.ASSIGNRIGHTS': 'Permissões atribuíveis',
  'SM.AUTH.LABEL.OWNEDRIGHTS': 'Autorização própria',
  'SM.ORGSTAFF.LABEL.ALLORG': 'Todas as organizações',
  'SM.FEDERATION.USER.TEMPLATE': 'Fazer download do modelo de importação de usuário federado',
  'SM.GADGET.VALUE.MENUPERM': 'Permissões de menu',
  'SM.GADGET.VALUE.FUNCPERM': 'Permissões de operação',
  'SM.GADGET.VALUE.INTFPERM': 'Permissões da API',
  'SM.ROLE.PARENT.ROLE': 'Papel-pai',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_BATCH_SET_ROLE': 'Adicionar funções em massa',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_BATCH_DELETE_ROLE': 'Exclusão em massa de funções',
  'SM.USERPWDGROUP.RANGE.VALIDATE': 'Intervalo %-%',
  'SM.ROLE.SELECT.BATCH': 'Selecione uma função',
  'SM.ROLE.SELECT.ERRORMSG': 'Descrição da Exceção：',
  'SM.ROLE.SELECT.ERRORCAUSE': 'Causa da anomalia：',
  'SM.ROLE.SELECT.ERRORSLOUTION': 'Sugestões de Processamento：',
  'SM.ROLE.MESSAGE.DELETE_CONFIRM':
    'Depois que uma função for excluída, ela só poderá ser recriada. Excluir?',
  'SM.ORG.DISABLE.FAILED.PARENT':
    'Desative todas as organizações filhas da organização antes de desativar a organização atual.',
  'SM.ORG.DISABLE.FAILED.EMPLOYEE':
    'Exclua todos os funcionários da organização e, em seguida, desative a organização.',
  'SM.EMPLOYEE.SELECTED.EMPLOYEE': 'Funcionários selecionados',
  'SM.EMPLOYEE.ALLOCATE.WORKNO': 'Atribuir IDs de Funcionário',
  'SM.EMPLOYEE.SELECTED.WORKNO.NOT.ENOUGH':
    'O tipo de agente selecionado atualmente não tem IDs de agente atribuíveis suficientes',
  'SM.EMPLOYEE.CHECKED-IN.PASSWORD': 'Senha de check-in',
  'SM.EMPLOYEE.SOFTCONSOLE.GROUP': 'Grupo de chamadas em casa',
  'SM.EMPLOYEE.INSPECTION.GROUP': 'Pertence ao grupo de inspeção de qualidade',
  'SM.EMPLOYEE.PLATFORM.CONFIG': 'Configuração da plataforma',
  'SM.EMPLOYEE.CONFIG.EMPLOYEE': 'Configurar Funcionários',
  'SM.EMPLOYEE.CANNOT.CHOOSE.WORKNO':
    'Não é possível selecionar um funcionário com um ID de funcionário atribuído',
  'SM.EMPLOYEE.OPERATE.MAX.HUNDRED': 'Até 100 funcionários ao mesmo tempo',
  'SM.EMPLOYEE.SKILL.INFO': 'Informações sobre Habilidades',
  'SM.EMPLOYEE.FEATURE.CONFLICT':
    'O reconhecimento inteligente e a gravação de duas faixas não podem ser ativados ao mesmo tempo. Quando ativado, o outro será desmarcado',
  'SM.EMPLOYEE.BATCH.CONFIG': 'Configuração em lote',
  'SM.TENANT.LABEL': 'Inquilino',
  'SM.TENANT.INFO': 'Informações sobre o inquilino',
  'SM.TENANT.VIEW': 'Procurar inquilinos',
  'SM.TENANT.EDIT': 'Editar inquilino',
  'SM.TENANT.CREATE': 'Novo Inquilino',
  'SM.TENANT.TENANTSPACENAME': 'Nome do ambiente do locatário',
  'SM.TENANT.TENANTNAME': 'Nome do inquilino',
  'SM.TENANT.ADMINACCOUNT': 'Conta de administrador',
  'SM.TENANT.PHONE': 'Acessórios',
  'SM.TENANT.CREATETIME': 'Criado em',
  'SM.TENANT.DELETE_CONFIRM': 'O locatário é excluído e não pode ser recuperado. Excluir?',
  'SM.PWDRULE.PLEASE.INPUT': 'Insira o nome do grupo de regras',
  'SM.EMPLOYEE.CANNOT.CHOOSE.STATUS':
    'Não é possível selecionar funcionários com status desativado',
  'SM.EMPLOYEE.CANCEL.CONFIG.CONFIRM': 'Tem certeza de que deseja cancelar a configuração',
  'SM.EMPLOYEE.ROLE.EFFECT':
    'As informações sobre a função do funcionário entrarão em vigor [] minutos depois.',
  'SM.ROLE.AUTH.EFFECT':
    'As informações de permissão da função entrarão em vigor [] minutos depois.',
  'SM.ROLE.TEMPLATE': 'Modelo de função',
  'SM.LABEL.APPCUBE.SWITCH': 'Página Integrada ao AppCube',
  'SM.LABEL.APPCUBE.APP': 'Selecionar aplicação',
  'SM.LABEL.APPCUBE.MENU': 'Selecionar menu',
  'SM.MENU.NAME.SPECIAL.CHAR':
    "Não é possível inserir caracteres especiais: !#{'$'}%&()￥+:\"{'{'}{'}'},\\/'[]=-^<>",
  'SM.COMMON.MESSAGE.DUTY.FORBIDDEN':
    'Os funcionários selecionados contêm funcionários que foram desativados.',
  'SM.LABEL.ACCOUNT': 'Insira uma conta',
  'SM.LABEL.USERNAME': 'Insira um nome de usuário',
  'SM.LABEL.EMAIL': 'Digite seu endereço de e-mail',
  'SM.LABEL.PHONE': 'Insira um número de contato',
  'SM.LABEL.PASSWORD': 'Digite a senha',
  'SM.LABEL.DESCRIPTION': 'Insira uma descrição',
  'SM.ORGSTAFF.BUTTON.SaveAndConfigure': 'Salvar e configurar',
  'SM.ROLE.BUTTON.DELETE': 'Tem certeza de que deseja excluir os funcionários selecionados?',
  'SM.ROLE.ADD': 'Nova função',
  'SM.ROLE.ADD.NAME': 'Insira o nome da função',
  'SM.ROLE.ADD.DECRI': 'Insira um comentário',
  'sumweb.export.confirm.exportall': 'Você não selecionou nenhum registro. Exportar todos os registros? Você também pode clicar em um registro específico para exportar.',
  'sumweb.export.export.select': 'Você selecionou o registro {0}. Tem certeza de que deseja continuar.',
  'sumweb.export.org': 'Exportando Informações da Organização',
  'sumweb.export.employee': 'Exportando Informações do Funcionário',
  'sumweb.view.import.list': 'Lista de importação',
  "sumweb.import.success": "Uma tarefa de importação foi criada. Tem certeza de que deseja exibir a lista de importação?",
  "SM.ROLE.EDIT": "Editar função",
  "SM.AUTH.BUTTON.CONFIRM.CLOSE": "Confirmar Fechar",
  "sumweb.agent.config": "Configurar Assentos",
  "sumweb.employee.export.nodata": "Nenhum dado de funcionário atende aos requisitos.",
  "sumweb.employee.view.config": "Ver Configuração"
}
