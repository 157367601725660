import request from '@/utils/request'

export const loadUserAuths = () => {
  return request({
    url: "/service-cloud/rest/cc-management/v1/thirdparty/getauths",
    method: "get"
  })
}

export const queryJsUrl = () => {
  return request({
    url: "/service-cloud/u-route/ccmanagement/thirdParty/queryJsUrl",
    method: "get"
  })
}

export const initCCAgentInfo = () => {
  return request({
    url: "/service-cloud/rest/cc-management/v1/ccagent/queryCCAgentByUserIdForPortalList",
    method: "post"
  })
}

/**
 * 查询指定坐席的技能队列
 * @param data 请求入参
 * @returns {*} 响应结果
 */
export const querySkillListByAgentFromCCbar = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/skill/querySkillListByAgentFromCCbar',
    method: "POST",
    data: data
  })
}

/**
 * 座席事件
 * @param data 座席事件的body参数
 * @return {*} 调用该请求后的Promise
 */
export const agentEvent = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/agenteventhandle/agentevent',
    method: "POST",
    data: data
  })
}

/**
 * 重设技能队列
 * @param params
 * @returns {*}
 */
export const requestRestSkillQueue = (params) => {
  let $params = params ? params : {};
  let restUrl = $params.$apiUrl ? $params.$apiUrl : "/agentgateway/resource";
  restUrl += "/onlineagent/" + encodeURIComponent(params.agentId) + "/resetskill/" + encodeURIComponent($params.autoflag);
  if (!$params.skillId) {
    $params.skillId = "";
  }
  if (!$params.phonelinkage) {
    $params.phonelinkage = 0;
  }
  restUrl += "?skillid =" + $params.skillid.join(";") + "&phonelinkage=" + encodeURIComponent($params.phonelinkage);
  return request({
    url: restUrl,
    method: "POST",
    data: $params.$entity,
  })
}

/**
 * 查询当前登录用户的业务帐号信息
 * @returns {Promise<*>}
 */
export const queryCCAgentByUserIdForPortal = () => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/ccagent/queryCCAgentByUserIdForPortal',
    method: 'post',
  })
}

/**
 * 查询外呼列表
 */
export const queryExternalNumberList = (data) => {
  const { pageSize, pageNum, name, noOrgFlag, orgIdList, orgFlag } = data
  return request({
    url: `/service-cloud/rest/cc-management/v1/transferextnumber/list/size/${pageSize}/current/${pageNum}`,
    method: 'post',
    data: { name, noOrgFlag, orgIdList, orgFlag }
  })
}