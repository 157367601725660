<template>
  <sweet-form class="talkReasonPanel" id="talkReason" label-position="left" label-width="auto">
    <div v-if="talkReasonNameList?.length > 0">
      <sweet-form-item class="talk-reason-row-style" v-for="(val, index) in talkReasonNameList">
        <template #label>
          <span class="talk-reason-col-name">{{index == 0 ? i18n("ccm.agent.contact.talkReason") : '  '}}</span>
        </template>
        <template #default>
          <div><span class="talk-reason-col-value">{{ val.name }}<span v-if="index + 1 < reasonLength">;</span></span></div>
        </template>
      </sweet-form-item>
    </div>
    <sweet-form-item v-else class="talk-reason-row-style">
      <template #label>
        <span class="talk-reason-col-name">{{ i18n("ccm.agent.contact.talkReason") }}</span>
      </template>
      <template #default>
        <div><span class="talk-reason-col-value">--</span></div>
      </template>
    </sweet-form-item>
    <sweet-form-item class="talk-reason-row-style">
      <template #label>
        <span class="talk-reason-col-name">{{ i18n('ccm.callreason.label.mark') }}</span>
      </template>
      <template #default>
        <div><span class="talk-reason-col-value talk-reason-remark">{{computedTalkRemark}}</span></div>
      </template>
    </sweet-form-item>
    <sweet-form-item label="  " class="talk-reason-row-style">
      <sweet-button v-if="isFromMenu" id="ccma-contactdetail-talkReason--edit" class="edit-button" type="primary" round @click="editTalkReason">
        {{ i18n('ccm.agent.button.edit') }}
      </sweet-button>
    </sweet-form-item>
    <edit-call-reason v-model:show="isShowEditPop" :talk-reason-obj="talkReasonObj" @close="() => isShowEditPop = false"
                      @sendcallreason="handleUpdate"/>
  </sweet-form>
</template>

<script setup lang="ts">
import { computed, getCurrentInstance, inject, onMounted, onUnmounted, Ref, ref, toRefs, watch } from "vue";
import { checkCaseTypes, queryCallSerialNo, queryCallReasonInfo, updateContactExtTalkInfoByCallSerialno } from "@/views/ccmanagement/ccma-api/index.js";
import EditCallReason from "@/views/ccmanagement/components/edit-callreason/EditCallreason.vue";
import AiccElMessage from '@/utils/el-message';

const { appContext: { config: { globalProperties } } } = getCurrentInstance() as any;
const i18n = (param: string, ...args: Array<any>): string => {
  return globalProperties.$t(param, args);
};

const props = defineProps({
  talkReason: String,
  talkRemark: String,
  isFromMenu: Boolean, // 是否来源于菜单页面
});
const talkReason = ref<String>(props.talkReason || '');
const talkRemark = ref<String>(props.talkRemark || '');
const computedTalkRemark = computed(() => {
  return talkRemark?.value || '--';
})
const checkCaseTypeList = ref<Array<any>>([]);
const talkReasonNameList = ref<Array<any>>();
const reasonLength = computed<number>(() => talkReasonNameList.value?.length || 0);
const talkReasonObj = computed(() => {
  return {
    talkReason: talkReason?.value,
    talkRemark: talkRemark?.value,
    talkReasonNameToRootList: talkReasonNameList.value,
  }
});
const callSerialNo = inject<Readonly<Ref>>('callSerialNo');
const provideData = inject<Ref>('resp')
const isShowEditPop = ref<Boolean>(false);

const queryCallReason = () => {
  talkReasonNameList.value = [];
  queryCallReasonInfo().then((resp: Array<any>) => {
    if (!resp || !talkReason?.value) {
      return;
    }
    const talkReasonSet = new Set<String>(talkReason.value.split(","));
    Object.values(resp).forEach((item) => {
      if (talkReasonSet.has(String(item.id))) {
        talkReasonNameList.value!.push({ key: item.id, name: buildTalkReasonNameToRoot(item.id, resp) });
      }
    });
  });
};

const handleUpdate = async (res: any) => {
  if (!res) {
    return;
  }
  let reasonName: string = '';
  let reason: string = '';
  for (let i = 0; i < res.list.length; i++) {
    reason += ',' + res.list[i].key;
    reasonName += ',' + (res.list[i].name.lastIndexOf(' >') > 0 ? res.list[i].name.substring(res.list[i].name.lastIndexOf(' >') + 2) : res.list[i].name);
  }
  if (!callSerialNo?.value) {
    return;
  }
  const param = {
    callSerialno: callSerialNo.value,
    talkReason: reason.substring(1),  // 需要去除首位的逗号
    talkRemark: res.note, // 需要去除首位的逗号
  }
  await updateContactExtTalkInfoByCallSerialno(param).then((data: any) => {
    if (data?.returnCode == '0') {
      AiccElMessage.success(i18n('ccm.agent.callreason.modify.success'));
      talkReasonNameList.value = res.list;
      talkReason.value = param.talkReason;
      talkRemark.value = res.note;
      if (res.caseList?.length > 0 && res.relatedCase == true) {
        checkCaseType(res.caseList);
      }
      return;
    }
    if (data?.returnCode == '2') {
      AiccElMessage.error(i18n('ccm.agent.callreason.modify.exceeds.limit'))
      return;
    }
    AiccElMessage.error(i18n('ccm.agent.callreason.modify.failed'))
  },
    () => AiccElMessage.error(i18n('ccm.agent.callreason.modify.failed')))
}

const checkCaseType = (caseTypeList: Array<any>) => {
  checkCaseTypes(caseTypeList).then((data: any) => {
    if (!data) {
      let missPages =  caseTypeList.map(item => item.typeName).join(',');
      AiccElMessage.error(i18n('ccm.callreason.case.check.error', missPages))
      return;
    }
    let missPages = '';
    missPages = caseTypeList.filter(item=>data.findIndex(obj => obj.caseTypeId === item.caseTypeId) === -1).map(item=>item.typeName).join(',');
    if (missPages !== '') {
      AiccElMessage.error(i18n('ccm.callreason.case.check.error', missPages))
    }
    checkCaseTypeList.value = data;
    const uri = window.location.protocol + "//" + window.location.host;
    let detalUrl = uri + "/besBaas/baas/abc/foundation/index.html#/AICC__caseCreate";
    for (let i = 0; i < checkCaseTypeList.value.length; i++) {
      setTimeout(() => {
        const caseTypeId = checkCaseTypeList.value[i].caseTypeId;
        let detalUrls = "";
        detalUrls = detalUrl + "?callTypeId=" + caseTypeId + "&callSerialno=" + callSerialNo.value + "&remark=" + talkRemark.value;
        top?.window.$BES.$Portal.tabpanel.createTabItemByConf(caseTypeId + i, i18n('casemanagement.casequery.button.create'), detalUrls);
        if (i != 0) {
          top?.window.$BES.$Portal.tabpanel.showTabItem(checkCaseTypeList.value[0].caseTypeId)
        }
      }, i * 2000)
    }
  })
}

const buildTalkReasonNameToRoot = (id: string, data: any): string => {
  const talkReasonNames: Array<String> = [];
  findParentToRoot(id, data, talkReasonNames);
  const joinStr = window.isRTL ? "\u200F >" : " >"
  return talkReasonNames.reverse().join(joinStr)
};

const findParentToRoot = (id: string, data: any, talkReasonNameList: Array<String>) => {
  const currentData = data.find((item: any) => item.id == id);
  if (currentData) {
    talkReasonNameList.push(currentData.name);
    if (currentData.parentId != "0") {
      findParentToRoot(currentData.parentId, data, talkReasonNameList);
    }
  }
};

/**
 * 编辑来电原因，共用音视频/在线聊天工作台的弹窗
 */
const editTalkReason = () => {
  isShowEditPop.value = true;
}

const stopWatch = watch([talkReason, talkRemark], () => queryCallReason())

onMounted(() => {
  queryCallReason();
});

onUnmounted(() => stopWatch())
</script>

<style lang="less" scoped>
.talkReasonPanel {
    width: inherit;
    height: inherit;
    padding: 33px 20px 0 20px;

}

.talkReasonPanel .talk-reason-row-style {
    margin-bottom: 30px;
    :deep(.el-form-item__label) {
      padding-top: 0 !important;
    }
}

.talkReasonPanel .talk-reason-row-style .talk-reason-col-name {
    color: var(--color-text--h2);
    font-weight: 400;
    font-size: var(--14px-to-rem) ;
    line-height: 20px;
    max-width: 144px;
}

.talkReasonPanel .talk-reason-row-style .talk-reason-col-value {
    color: var(--color-text--h1);
    font-weight: 500;
    font-size: var(--14px-to-rem);
    line-height: 20px;
}

.talkReasonPanel .talk-reason-row-style .talk-reason-remark {
    overflow: auto;
    text-overflow: ellipsis;
    word-break: break-all;
}

.talkReasonPanel .talk-reason-row-style .edit-button {
    min-width: 96px;
    max-width: 160px;
}
</style>