export default {
// #now
// #Tue Feb 07 11:45:04 CST 2023
  "ccnotification.notifition.sendmessage.priorityGeneral" : "Faible",
  "ccnotification.bulletinType.message.releaseTime" : "temps de lib\u00E9ration",
  "ccnotification.gatewayconfig.label.slipWindowSizetitle" : "La taille de la fen\u00EAtre coulissante est un nombre appropri\u00E9",
  "ccnotification.notification.label.checkAttachNameLegnth" : "Le nom de la pi\u00E8ce jointe ne peut pas d\u00E9passer 100 caract\u00E8res",
  "message.template.type.system" : "Notification du syst\u00E8me",
  "ccnotification.bulletinType.title.Urgent" : "moyen",
  "ccnotification.gatewayconfig.label.replaceIfPresentFlagtitle" : "0\: Remplacer\, 1\: Non remplacer\, 2-255\: R\u00E9serv\u00E9",
  "ccnotification.notifition.sendmessage.savesuccess" : "Le message est enregistr\u00E9 avec succ\u00E8s.",
  "ccnotification.common.title.confirm" : "Confirmer",
  "ccnotification.gatewayconfig.label.senderPoolQueueCapacitytitle" : "La longueur de file d'attente du pool de fils d'envoi est un nombre\, par exemple\, 2000.",
  "ccnotification.notifition.sendmessage.selectbyorg" : "Unit\u00E9 d'organisation",
  "message.center.message.messageQueryResultIsEmpty" : "Le r\u00E9sultat de la requ\u00EAte de message est vide.",
  "ccnotification.notification.systemmonitor.mspId" : "MspId",
  "message.template.message.agentbebinded" : "Le destinataire s\u00E9lectionn\u00E9 n'est pas li\u00E9 \u00E0 un agent",
  "message.template.message.mediaTypeError" : "Mode d'envoi incorrect.",
  "ccnotification.center.message.toIsRead" : "\u00CAtes-vous s\u00FBr de vouloir marquer les messages s\u00E9lectionn\u00E9s comme lus ?",
  "ccnotification.gatewayconfig.label.viewgateway" : "Voir la passerelle",
  "ccnotification.bulletinType.message.everyDay" : "Tous les jours",
  "message.template.message.titleError" : "Le titre du mod\u00E8le ne peut pas d\u00E9passer 200 caract\u00E8res.",
  "ccnotification.gatewayconfig.label.defaultreceinfotitle" : "Règle de préfixe de nombre accepté : Par exemple, si 130 est configuré, les nombres commençant par 130 sont mis en correspondance. Plusieurs nombres sont séparés par des points-virgules (;).",
  "ccnotification.bulletinType.title.bulletintypeInvalidSect" : "Date d'expiration par défaut",
  "ccnotification.gatewayconfig.label.receiverPoolMaxCounttitle" : "Les pools de fil de r\u00E9ception max est un nombre\, par exemple\, 200.",
  "ccnotification.gatewayconfig.label.addgatewaysuccess" : "Gatewayconfig ajoute avec succ\u00E8s\!",
  "ccnotification.agent.operation.searchworkno" : "Veuillez entrer l'identifiant de l'employ\u00E9",
  "ccnotification.agent.message.updateCtiAndDbFail" : "La mise \u00E0 jour cti et Db a \u00E9chou\u00E9.",
  "ccnotification.gatewayconfig.message.securityInfo" : "NONE indique que les donn\u00E9es sont transmises en clair. Par cons\u00E9quent\, les donn\u00E9es peuvent fuir. STARTTLS est un protocole non s\u00E9curis\u00E9. L'utilisation de protocoles non s\u00E9curis\u00E9s pose des risques pour la s\u00E9curit\u00E9. Le protocole TLS est recommand\u00E9.",
  "ccnotification.common.label.name" : "Nom et pr\u00E9nom",
  "message.template.message.mediaType" : "Mode d'envoi de message",
  "ccnotification.gatewayconfig.label.paramMaxLengthIsError" : "La valeur du param\u00E8tre ne peut pas d\u00E9passer 500 caract\u00E8res",
  "ccnotification.gatewayconfig.label.receiverPoolMinCounttitle" : "Les pools de fils de r\u00E9ception min sont un nombre\, par exemple\, 20.",
  "ccnotification.bulletinType.message.perMonth" : "par mois",
  "ccnotification.caseserver.fail.duplicateContent" : "Dupliquer le contenu",
  "ccnotification.gatewayconfig.label.senderPoolMaxCount" : "Envoi maximum de pools de threads",
  "ccnotification.gatewayconfig.label.positive" : "Le nombre ne peut pas \u00EAtre n\u00E9gatif.",
  "ccnotification.flowcontrol.recipient.countrycode" : "Code du pays du b\u00E9n\u00E9ficiaire",
  "ccnotification.smstemplate.message.smstemplate" : "Les informations du mod\u00E8le HUAWEI CLOUD sont vides.",
  "ccnotification.bulletinType.message.Tuesday" : "Mardi",
  "ccnotification.gatewayconfig.label.receiverPoolQueueCapacity" : "Longueur de file d'attente de pool de fil de r\u00E9ception",
  "message.center.message.willDeleteMessages" : "\u00CAtes-vous s\u00FBr de vouloir supprimer le message s\u00E9lectionn\u00E9 ?",
  "message.center.nocitiction.sending.records.sended" : "Envoi instantan\u00E9",
  "ccnotification.notifition.template.message.phone" : "- La valeur est une cha\u00EEne de 1 \u00E0 15 chiffres.",
  "ccnotification.gatewayconfig.label.splitType" : "Mode de fractionnement SMS surdimensionn\u00E9",
  "ccnotification.gatewayconfig.label.interfaceVersion" : "Num\u00E9ro de version du message de connexion",
  "ccn.smsnoconfig.pop.title.edittitle" : "Modifier le num\u00E9ro de SMS",
  "ccnotification.bulletinType.title.editBulletinType" : "Modifier le type de bulletin",
  "ccnotification.common.title.fail" : "\u00C9chec",
  "ccnotification.common.title.errorsave" : "Erreur",
  "ccnotification.notification.label.compressZipFileFailed" : "Impossible de compresser le fichier.zip",
  "message.template.message.nameDuplicationError" : "Nom du mod\u00E8le en double.",
  "ccnotification.smshuawei.label.certTip.withoutProxy" : "T\u00E9l\u00E9chargez le certificat de service HUAWEI CLOUD",
  "message.template.type.out" : "Notification externe",
  "ccnotification.notifition.receivermessage.isread" : "Lire",
  "ccnotification.notification.label.fileUploadFileTypeError" : "Le type de fichier que vous avez t\u00E9l\u00E9charg\u00E9 ne r\u00E9pond pas aux exigences",
  "ccnotification.center.message.var.type" : "Type de variable\:",
  "ccnotification.notifition.sendmessage.selectGroupOrRecipient" : "S\u00E9lectionnez le destinataire ou le groupe de destinataires.",
  "ccnotification.notification.recmessagerouterconfig.selectitem" : "Veuillez s\u00E9lectionner un enregistrement.",
  "ccnotification.gatewayconfig.label.modifygatewaysuccess" : "Gatewayconfig modifier avec succ\u00E8s\!",
  "ccnotification.bulletinType.message.createBulletinTypeFailed" : "Impossible de cr\u00E9er le type de bulletin.",
  "ccnotification.flowcontrol.getFailed" : "Impossible d'obtenir la configuration des notifications. V\u00E9rifiez le r\u00E9seau.",
  "ccnotification.gatewayconfig.label.localPort" : "Port client",
  "ccnotification.notification.recmessagerouterconfig.receiveAddress" : "Adresse du destinataire",
  "ccnotification.bulletinType.title.bulletinTypeName" : "Nom du type de bulletin",
  "ccnotification.notification.recmessagerouterconfig.caseserver" : "Appel de service",
  "ccnotification.gatewayconfig.label.selectredisconnectedgatewayconfig" : "S\u00E9lectionnez la configuration de la passerelle \u00E0 d\u00E9connecter",
  "ccnotification.notification.message.noAttachement" : "Vous n'avez t\u00E9l\u00E9charg\u00E9 aucune pi\u00E8ce jointe.",
  "ccnotification.notifition.template.label.templateid" : "ID de mod\u00E8le",
  "ccnotification.notifition.sendmessage.entertenantname" : "Saisissez un nom d'espace locataire.",
  "message.template.message.messageTemplateId" : "ID de mod\u00E8le",
  "ccnotification.gatewayconfig.label.defaultsendnotitle" : "Règle de préfixe du numéro de l'expéditeur. Par exemple, si 130 est configuré, les nombres commençant par 130 correspondent. Plusieurs nombres sont séparés par des points-virgules (;).",
  "ccnotification.gatewayconfig.label.mailPassword" : "Mot de passe utilisateur",
  "ccnotification.notifition.sendmessage.selectOutbox" : "S\u00E9lectionnez la bo\u00EEte d'envoi.",
  "message.template.message.inputTitle" : "Entrez un titre.",
  "ccnotification.smstemplate.message.number" : "Le chiffre maximum est 10.",
  "ccnotification.sms.gateway" : "Passerelle SMS",
  "ccnotification.notification.label.deleteAttachmentFailed" : "\u00C9chec de la suppression de la pi\u00E8ce jointe",
  "ccnotification.notification.recmessagerouterconfig.deletenum.outstripping" : "Un maximum de 100 enregistrements peuvent \u00EAtre supprim\u00E9s \u00E0 la fois.",
  "ccnotification.bulletinType.message.deleteBulletinTypeFailed" : "Impossible de supprimer le type de bulletin.",
  "message.template.message.recipientError" : "Certains destinataires n'existent pas. S\u00E9lectionnez un autre destinataire",
  "ccnotification.notifition.variable.templatename" : "Nom du mod\u00E8le",
  "ccnotification.gatewayconfig.label.SSLEncryptionMode" : "Mode de chiffrement",
  "message.template.message.modifyTemplateFail" : "Impossible de modifier le mod\u00E8le de notification.",
  "ccnotification.notifition.sendmessage.enteraccount" : "Entrez un compte d'entreprise.",
  "ccnotification.notifition.template.message.error" : "Valeurs d'entr\u00E9e non valides.",
  "ccnotification.gatewayconfig.label.authtitle" : "Drapeau d'authentification\, Les options sont vraies et fausses",
  "ccnotification.sms.huawei" : "SMS Huawei",
  "ccnotification.gatewayconfig.label.certType" : "Type de certificat",
  "ccnotification.gatewayconfig.label.mailPort" : "Port",
  "message.center.nocitiction.draftBox" : "Bo\u00EEte de brouillon",
  "ccnotification.chatserver.fail.barred" : "Allocation d'agent bloqu\u00E9e",
  "ccnotification.common.button.create" : "Nouveau",
  "ccnotification.flowcontrol.modifyFailed" : "Impossible de modifier la configuration des notifications. V\u00E9rifiez les param\u00E8tres d'entr\u00E9e ou le r\u00E9seau.",
  "ccnotification.gatewayconfig.label.defaultsendname" : "Nom d'utilisateur par d\u00E9faut",
  "ccnotification.notifition.sendmessage.enterrecipient" : "S\u00E9lectionnez un destinataire.",
  "ccnotification.gatewayconfig.label.deviceTypeDescript" : "Description du type d'appareil",
  "ccnotification.notifition.gatewaycontact.success" : "Connexion r\u00E9ussie",
  "ccnotification.notifition.variable.insertvariable" : "Ins\u00E9rer une variable",
  "ccnotification.gatewayconfig.label.smppPduTypetitle" : "1\:submit_sm 2\:data_sm",
  "ccnotification.bulletinType.message.invalidSectCheck" : "Le d\u00E9lai d'expiration par d\u00E9faut doit aller de 1 \u00E0 2000 jours.",
  "ccnotification.bulletinType.message.daysOfMonth" : "Jour 0",
  "ccnotification.flowcontrol.eachThreshold" : "Seuil quotidien de donn\u00E9es monom\u00E9dia",
  "ccnotification.bulletinType.message.nameContainSpecialChar" : "Le nom ne peut pas contenir de caract\u00E8res sp\u00E9ciaux.",
  "ccnotification.notification.label.fileUploadFileIOError" : "Il y avait une erreur d'analyse avec le fichier que vous avez t\u00E9l\u00E9charg\u00E9",
  "ccnotification.notifition.variable.createvariable" : "Cr\u00E9er",
  "message.center.label.receiver" : "Destinataire",
  "ccnotification.notifition.sendmessage.delaysend" : "Notification programm\u00E9e",
  "ccnotification.bulletinType.message.Friday" : "Vendredi",
  "ccnotification.notifition.sendmessage.bulletinTypeName" : "Nom du type de bulletin",
  "message.center.label.messageCenter" : "Centre de notifications",
  "ccn.smsnoconfig.pop.search.reminder" : "Entrez un nombre.",
  "ccnotification.gatewayconfig.label.maxEmailGatewayIsError" : "Un maximum de cinq passerelles de r\u00E9ception ou d'envoi de courriels peuvent \u00EAtre configur\u00E9es pour le m\u00EAme espace locataire",
  "ccnotification.notifition.template.message.dateTimeLabel" : "Date et heure",
  "ccn.smshuawei.message.table.label" : "HUAWEI CLOUD SMS",
  "message.template.message.sendEmailError" : "L'adresse e-mail de l'exp\u00E9diteur est vide.",
  "ccnotification.gatewayconfig.label.messageprotocol" : "Param\u00E8tres du protocole de message",
  "ccnotification.gatewayconfig.label.mailProtocolIsError" : "Le type de protocole de messagerie doit \u00EAtre SMTP\, POP3.",
  "ccnotification.common.title.delete" : "Supprimer",
  "ccnotification.notifition.sendmessage.selectmode" : "S\u00E9lectionner par",
  "ccnotification.notification.label.isUploadAttachment" : "T\u00E9l\u00E9charger la pi\u00E8ce jointe",
  "ccnotification.notification.emailsignature.selection.username" : "Nom d'utilisateur",
  "ccnotification.notification.recmessagerouterconfig.receiver" : "Destinataire",
  "ccnotification.template.message.delete" : "Le mod\u00E8le a \u00E9t\u00E9 r\u00E9f\u00E9renc\u00E9 et ne peut pas \u00EAtre supprim\u00E9.",
  "ccnotification.notifition.template.label.varnumber" : "Nombre de variables",
  "ccnotification.common.title.tips" : "Conseils",
  "ccnotification.notification.systemmonitor.status" : "Statut",
  "ccnotification.notifition.receivermessage.all" : "Tous",
  "ccnotification.notification.label.fileInjectionRiskException" : "Exception de risque d'injection de pi\u00E8ce jointe d'e-mail",
  "message.template.message.createTime" : "Cr\u00E9\u00E9 \u00E0",
  "ccnotification.notifition.sendmessage.internal" : "Message interne",
  "message.center.label.deleteisread" : "Supprimer tous les messages lus",
  "message.center.message.delete" : "Supprimer",
  "message.center.label.sendTime" : "Envoy\u00E9 \u00E0",
  "ccnotification.gatewayconfig.label.deviceDescript" : "Description de l'appareil",
  "ccnotification.gatewayconfig.label.addgatewayfailed" : "L'ajout de Gatewayconfig a \u00E9chou\u00E9 \!",
  "message.center.label.search" : "Rechercher",
  "ccnotification.agent.label.account" : "Compte",
  "message.template.message.queryagentbebindedfailed" : "Impossible d'interroger l'utilisateur li\u00E9 \u00E0 l'agent",
  "ccnotification.flowcontrol.setFlowType" : "Type de limite de donn\u00E9es",
  "ccnotification.gatewayconfig.label.selectredgatewayconfig" : "Surveillance de la passerelle s\u00E9lectionn\u00E9e \:",
  "ccnotification.gatewayconfig.label.addrTon" : "Code de type d'adresse ESME",
  "message.center.label.delete" : "Supprimer",
  "ccnotification.common.title.confreset" : "Confirmer la r\u00E9initialisation",
  "ccnotification.common.button.delete" : "Supprimer",
  "ccn.smsnoconfig.pop.check.deletewarning" : "\u00CAtes-vous s\u00FBr de vouloir supprimer les donn\u00E9es s\u00E9lectionn\u00E9es ?",
  "ccnotification.notifition.template.message.phoneLabel" : "Num\u00E9ro de t\u00E9l\u00E9phone",
  "ccnotification.agent.pageurls.isconfreset" : "\u00CAtes-vous s\u00FBr de vouloir le r\u00E9initialiser ?",
  "ccnotification.notification.emailsignature.reachmaxcount" : "Le nombre de signatures d'e-mails d\u00E9passe le maximum.",
  "ccnotification.gatewayconfig.label.configTypeIsError" : "Le type de configuration est incorrect. Le type de configuration interne du param\u00E8tre est incoh\u00E9rent",
  "ccnotification.smshuawei.message.updatesuccess" : "Le canal SMS est modifi\u00E9 avec succ\u00E8s.",
  "ccnotification.smshuawei.message.specialCharacters" : "Les caract\u00E8res sp\u00E9ciaux ne sont pas autoris\u00E9s.",
  "ccnotification.notifition.sendmessage.notifitiontitle" : "Entrez un titre.",
  "ccnotification.notification.label.rebuildSuccess" : "Reconstruire le succ\u00E8s",
  "ccnotification.notifition.template.placeholder.signatureName" : "Entrez un nom de signature",
  "ccnotification.smstemplate.message.fail" : "Impossible de cr\u00E9er le mod\u00E8le HUAWEI CLOUD.",
  "message.template.message.nameError" : "Le nom du mod\u00E8le ne peut pas \u00EAtre vide ou d\u00E9passer 200 caract\u00E8res.",
  "ccnotification.notifition.variable.searchTempdateName" : "Saisissez un nom de mod\u00E8le.",
  "ccnotification.smshuawei.label.appKey" : "Cl\u00E9 d'application",
  "ccnotification.flowcontrol.totalThreshold" : "Seuil quotidien de donn\u00E9es totales",
  "ccnotification.gatewayconfig.label.senderPoolMinCount" : "Min Envoi de pools de threads",
  "ccnotification.notifition.sendmessage.priorityExtraUrgent" : "\u00C9lev\u00E9",
  "message.template.message.type" : "Type de notification",
  "message.template.message.willDeleteTemplate" : "La suppression du mod\u00E8le peut entra\u00EEner l'indisponibilit\u00E9 de la fonction r\u00E9f\u00E9renc\u00E9e. \u00CAtes-vous s\u00FBr de vouloir continuer ?",
  "ccnotification.notification.label.attachment" : "Pi\u00E8ce jointe",
  "ccnotification.notification.recmessagerouterconfig.serviceName" : "Nom du service",
  "message.template.button.delete" : "Supprimer",
  "ccnotification.notification.label.rebuildFailed" : "La reconstruction a \u00E9chou\u00E9",
  "ccnotification.gatewayconfig.label.certScene" : "Sc\u00E9nario d'application",
  "ccnotification.notifition.sendmessage.priorityUrgent" : "moyen",
  "ccn.smsnoconfig.pop.check.selectwarning" : "S\u00E9lectionnez l'enregistrement que vous souhaitez supprimer.",
  "message.template.message.selectRecipient" : "S\u00E9lectionnez un destinataire.",
  "ccnotification.notifition.sendmessage.recipientGroup" : "Groupe de b\u00E9n\u00E9ficiaires",
  "ccnotification.notifition.sendmessage.receiverPhoneIsNull" : "Le destinataire ou le num\u00E9ro de destinataire est vide.",
  "ccnotification.notification.emailsignature.name" : "Nom de la signature",
  "ccnotification.gatewayconfig.label.specialStrValidate" : "Seules les lettres majuscules\, les lettres minuscules et les chiffres sont autoris\u00E9s.",
  "ccnotification.notifition.sendmessage.priority" : "Priorit\u00E9",
  "ccnotification.notification.label.selectAttachment" : "Veuillez s\u00E9lectionner une pi\u00E8ce jointe \u00E0 un e-mail.",
  "ccnotification.common.warning.message" : "S\u00E9lectionnez un enregistrement.",
  "ccnotification.center.message.deleteAllRead" : "\u00CAtes-vous s\u00FBr de vouloir supprimer tous les messages internes lus?",
  "ccnotification.notifition.sendmessage.cancelSelectall" : "Annuler tout",
  "ccnotification.notification.emailsignature.content" : "Contenu de la signature",
  "message.template.message.searchMessageTemplateName" : "Saisissez un nom de mod\u00E8le.",
  "message.template.message.notificationContent" : "Contenu de la notification",
  "ccnotification.notifition.variable.variablename" : "Nom de la variable",
  "ccnotification.notifition.template.label.des" : "Descriptif",
  "ccnotification.notification.special.charactor.check" : "Le contenu saisi ne peut pas contenir les valeurs \= > / valeur eval et exec.",
  "ccnotification.gatewayconfig.label.configNameIsError" : "Le nom de la configuration doit contenir un maximum de 10 chiffres ou lettres",
  "ccnotification.gatewayconfig.label.serviceType" : "Type de service",
  "ccnotification.notifition.sendmessage.enterBulletinType" : "S\u00E9lectionnez un type de bulletin.",
  "ccnotification.notifition.gatewaycontact.stop" : "Connexion arr\u00EAt\u00E9e",
  "ccnotification.notifition.template.message.moneyLabel" : "Montant",
  "message.center.nocitiction.interal" : "Bulletin interne",
  "ccnotification.common.title.warning" : "Avertissement",
  "ccnotification.notification.label.deleteAttachmentSuccess" : "Succ\u00E8s de suppression de la pi\u00E8ce jointe",
  "ccnotification.gatewayconfig.label.sourceAddrNpititle" : "Le sch\u00E9ma de code d'adresse source est un nombre",
  "ccnotification.notification.label.uploadAttachmentFailed" : "\u00C9chec du t\u00E9l\u00E9chargement de la pi\u00E8ce jointe",
  "ccnotification.gatewayconfig.label.maxContentLengthtitle" : "La longueur maximale du contenu (octets) est un nombre\, qui est utilis\u00E9 comme base de fractionnement correspondant\, par exemple\, 140.",
  "ccnotification.notifition.receivermessage.unread" : "non lu",
  "ccnotification.notification.label.attachmentExceed10" : "Un maximum de 10 pi\u00E8ces jointes peuvent \u00EAtre t\u00E9l\u00E9charg\u00E9es.",
  "message.template.message.contentError" : "Le contenu ne peut pas \u00EAtre vide.",
  "ccnotification.notification.recmessagerouterconfig.defaultsendemail" : "Par d\u00E9faut Envoyer un e-mail",
  "ccnotification.gatewayconfig.label.heartbeatIntervaltitle" : "L'intervalle de battement de coeur est un nombre appropri\u00E9",
  "ccnotification.notifition.sendmessage.entersender" : "S\u00E9lectionnez un num\u00E9ro d'exp\u00E9diteur.",
  "ccnotification.notifition.sendmessage.bulletin" : "Bulletin",
  "ccnotification.notification.variable.templateselect" : "S\u00E9lectionnez un mod\u00E8le.",
  "ccnotification.bulletinType.message.Sunday" : "Dimanche",
  "ccnotification.gatewayconfig.label.selectdeletegatewayconfig" : "Veuillez s\u00E9lectionner la configuration de la passerelle \u00E0 supprimer \!",
  "ccnotification.gatewayconfig.label.heartbeatNoRespCounttitle" : "Les temps de connexion max sur aucune r\u00E9ponse de battement de coeur est un nombre appropri\u00E9",
  "message.template.message.messageTemplateModify" : "Modifier le mod\u00E8le",
  "ccnotification.notification.emailsignature.description" : "Description de la signature",
  "ccnotification.notification.label.attachmentIsEmpty" : "La pi\u00E8ce jointe est vide.",
  "ccnotification.caseserver.fail.titleAndContentNull" : "Titre et contenu vides",
  "ccnotification.smshuawei.message.securityInfo" : "Si la v\u00E9rification du certificat est d\u00E9sactiv\u00E9e\, il y a un risque d'usurpation d'identit\u00E9 entre pairs. Il est recommand\u00E9 d'activer la v\u00E9rification du certificat.",
  "ccnotification.gatewayconfig.label.maxMessageLength" : "Longueur maximale du message (octets)",
  "ccnotification.gatewayconfig.label.gatewayname" : "Nom de la passerelle",
  "ccnotification.smshuawei.message.deletefail" : "Impossible de supprimer le canal SMS.",
  "ccnotification.gatewayconfig.label.splitMsgConcatModeIsError" : "Long mode de combinaison de message court 0 \: En-t\u00EAte de donn\u00E9es d'utilisateur de GSM\, param\u00E8tre 1 \: SMPP",
  "message.template.message.createSuccess" : "Le mod\u00E8le de notification est cr\u00E9\u00E9 avec succ\u00E8s.",
  "ccnotification.notification.label.attachmentSizeError" : "La taille de la pi\u00E8ce jointe de l'e-mail d\u00E9passe 10 Mo",
  "ccnotification.notification.recmessagerouterconfig.intelligentOrder" : "Cr\u00E9ation intelligente de cas",
  "message.center.nocitiction.outbox" : "Bo\u00EEte d'envoi",
  "message.center.nocitiction.modifyBulletin" : "Modifier le bulletin",
  "ccnotification.gatewayconfig.label.addgateway" : "Cr\u00E9er une passerelle",
  "ccnotification.notification.label.rebuildContainsErrorId" : "La reconstruction a \u00E9chou\u00E9\, veuillez cliquer sur le bouton de requ\u00EAte pour mettre \u00E0 jour la liste et r\u00E9essayer.",
  "ccnotification.bulletinType.message.periodicallySend" : "envoyer p\u00E9riodiquement",
  "ccnotification.notification.label.discounnectedFailed" : "Échec de la déconnexion",
  "message.center.nocitiction.sending.records.status" : "Statut",
  "ccnotification.bulletinType.title.Faults" : "Critique",
  "ccnotification.gatewayconfig.label.mailHost" : "H\u00F4te",
  "message.template.button.edit" : "Modifier",
  "message.center.nocitiction.callback.receivetime" : "Temps de r\u00E9ception",
  "ccn.smsnoconfig.pop.check.deletesuccess" : "Suppression r\u00E9ussie.",
  "ccnotification.notifition.sendmessage.selectuser" : "S\u00E9lectionnez un employ\u00E9.",
  "ccnotification.bulletinType.message.betchDeleteBulletinTypeFailed" : "Impossible de supprimer les types de bulletins par lots.",
  "message.template.button.view" : "voir",
  "ccnotification.notifition.template.message.dateTime" : "- Date\: aaaa-MM-jj - Heure\: HH\:mm\:ss",
  "ccnotification.notification.label.fileUploadFileSizeError" : "La taille du fichier que vous avez t\u00E9l\u00E9charg\u00E9 d\u00E9passe la limite",
  "ccnotification.gatewayconfig.label.dataCoding" : "Format du message",
  "ccnotification.bulletinType.message.searchPlaceHolder" : "Nom du type de bulletin",
  "ccnotification.notifition.sendmessage.sendsuccess" : "Le message a \u00E9t\u00E9 envoy\u00E9 avec succ\u00E8s.",
  "ccnotification.smshuawei.message.paramisempty" : "Param\u00E8tre vide.",
  "ccnotification.notification.variable.templatetype" : "Type de mod\u00E8le",
  "ccnotification.center.message.setIsRead" : "Marquer les messages comme lus",
  "ccnotification.notifition.sendmessage.isSendall" : "Envoy\u00E9",
  "ccnotification.notifition.template.message.mspsms" : "Passerelle SMS",
  "ccnotification.smshuawei.label.signatureChannelId" : "Identifiant de canal de signature",
  "ccnotification.gatewayconfig.label.serverSocketPort" : "Port pour recevoir des messages en amont",
  "ccnotification.notifition.sendmessage.send" : "Envoyer",
  "ccnotification.gatewayconfig.label.readTimeout" : "Intervalle de d\u00E9lai de r\u00E9ception (ms)",
  "ccnotification.bulletinType.message.editBulletinTypeSuccessful" : "Le type de bulletin est modifi\u00E9 avec succ\u00E8s.",
  "ccnotification.gatewayconfig.label.selectCert" : "S\u00E9lectionnez Certificat",
  "ccnotification.gatewayconfig.label.dataCodingIsError" : "Le format de message de SMS doit \u00EAtre 0 \: cha\u00EEne ASCII\, information 4 \: binaire\, 8 \: code UCS2",
  "ccnotification.notification.systemmonitor.modifyTime" : "Modifi\u00E9 \u00E0",
  "ccnotification.notifition.template.label.value" : "Valeur par d\u00E9faut",
  "ccnotification.notifition.sendmessage.bccRecipient" : "Bcc",
  "ccnotification.notification.recmessagerouterconfig.emailOnlineChat" : "Chat en ligne via le canal de messagerie",
  "ccnotification.notification.label.uploadPermissionFailed" : "\u00C9chec de la v\u00E9rification de l'autorisation de t\u00E9l\u00E9chargement de pi\u00E8ces jointes d'e-mail",
  "ccnotification.gatewayconfig.label.interfaceVersiontitle" : "Le num\u00E9ro de version du message de connexion doit \u00EAtre un num\u00E9ro.",
  "ccnotification.notification.emailsignature.nameexist" : "Le nom de la signature existe d\u00E9j\u00E0.",
  "ccnotification.gatewayconfig.label.defaultsendnoIsError" : "Le format de l'adresse email par d\u00E9faut est incorrect",
  "ccnotification.gatewayconfig.label.destAddrNpititle" : "Le sch\u00E9ma de code d'adresse de destination est un nombre",
  "ccnotification.notifition.variable.syntax.error" : "Erreur de syntaxe \!",
  "ccnotification.gatewayconfig.label.paramIsNotNumber" : "La valeur du param\u00E8tre ne peut contenir que des chiffres",
  "ccnotification.gatewayconfig.label.smppPduType" : "Type de PDU SMPP",
  "ccnotification.gatewayconfig.label.registeredDelivery" : "Rapport de confirmation du statut de retour",
  "ccnotification.notifition.recmessagerouterconfig.index" : "Index",
  "ccnotification.gatewayconfig.label.receiverPoolMaxCount" : "Piscines de fil de r\u00E9ception maximum",
  "ccnotification.notifition.template.placeholder.hwtemplateid" : "Entrez un ID de mod\u00E8le HUAWEI CLOUD",
  "message.center.label.refresh" : "Actualiser",
  "ccnotification.common.button.ok" : "OK",
  "ccnotification.gatewayconfig.label.sourceAddrNpi" : "Sch\u00E9ma de code d'adresse source",
  "ccnotification.notifition.sendmessage.enterRecipientGroup" : "S\u00E9lectionnez un groupe de destinataires.",
  "ccnotification.bulletinType.message.Wednesday" : "Mercredi",
  "message.template.message.messageTemplateName" : "Nom du mod\u00E8le",
  "ccnotification.notifition.variable.not.exist" : "n'existe pas\!",
  "ccnotification.notifition.template.label.templatetype" : "Type de mod\u00E8le",
  "ccnotification.gatewayconfig.label.readTimeouttitle" : "L'intervalle de temps d'expiration de r\u00E9ception est un nombre appropri\u00E9\, 0\: jamais de temps d'expiration",
  "message.center.nocitiction.email" : "Courriel",
  "ccnotification.notification.variable.nameexist" : "Le nom de la variable existe.",
  "ccnotification.notifition.sendmessage.sendTimeFailed" : "L'heure d'envoi est ant\u00E9rieure \u00E0 l'heure actuelle.",
  "ccnotification.notification.recmessagerouterconfig.retryTimes" : "Temps de r\u00E9essais",
  "ccnotification.notification.label.discounnectedSuccess" : "Déconnecté avec succès",
  "ccnotification.gatewayconfig.label.sslencryptionmodeIsError" : "Le mode de cryptage des e-mails ne peut \u00EAtre que NONE\, STARTTLS ou TLS",
  "ccnotification.gatewayconfig.label.replaceIfPresentFlag" : "Drapeau de remplacement",
  "ccnotification.notification.label.hasUplaodtAttachment" : "Pi\u00E8ces jointes t\u00E9l\u00E9charg\u00E9es",
  "ccnotification.notifition.sendmessage.selectall" : "S\u00E9lectionner tout",
  "ccnotification.notifition.template.label.operation" : "Op\u00E9ration",
  "ccnotification.notification.recmessagerouterconfig.inbox" : "Dans la bo\u00EEte",
  "ccnotification.notifition.sendmessage.smsconfignull" : "La configuration du message SMS est vide. Choisissez Param\u00E8tres > Configuration SMS pour configurer les informations SMS.",
  "ccnotification.notification.emailsignature.placeholder.name" : "Veuillez entrer un nom de signature",
  "ccnotification.notification.variable.variablevalue" : "Valeur variable",
  "ccnotification.flowcontrol.setThreshold" : "D\u00E9finir le seuil de donn\u00E9es",
  "ccnotification.caseserver.fail.categoryNotExist" : "Cat\u00E9gorie de cas inexistante",
  "ccnotification.gatewayconfig.label.inputgatewayname" : "Entrez un nom de passerelle",
  "ccnotification.gatewayconfig.label.lastmodifytime" : "Derni\u00E8re modification le",
  "ccnotification.gatewayconfig.label.checkConfigName" : "La valeur est une cha\u00EEne de moins de 10 chiffres et lettres",
  "ccnotification.common.operation.noMoreThan7Days" : "L'intervalle de temps ne peut exc\u00E9der 7 jours.",
  "ccnotification.gatewayconfig.label.paramContainsSpecialChar" : "Le param\u00E8tre contient des caract\u00E8res sp\u00E9ciaux",
  "message.template.message.frequently" : "Le nombre de messages envoy\u00E9s d\u00E9passe le seuil quotidien.",
  "ccnotification.bulletinType.message.deleteBulletinTypeSuccessful" : "Le type de bulletin est supprim\u00E9 avec succ\u00E8s.",
  "ccnotification.gatewayconfig.label.requestParamIsNull" : "Le param\u00E8tre request est vide",
  "ccn.smsnoconfig.table.header.countrycode.desc" : "Pays",
  "ccnotification.gatewayconfig.label.smDefaultMsgId" : "ID de message par d\u00E9faut",
  "ccnotification.smshuawei.label.certTip.withProxy" : "Veuillez t\u00E9l\u00E9charger le certificat du serveur proxy",
  "ccnotification.notifition.sendmessage.delaynotice" : "Cet avis sera envoy\u00E9 le",
  "message.template.message.noneTemplate" : "Le mod\u00E8le n'existe pas.",
  "ccnotification.notification.fileUploadRiskWarning" : "Le fichier que vous avez t\u00E9l\u00E9charg\u00E9 comporte un risque de s\u00E9curit\u00E9\, veuillez confirmer si vous souhaitez continuer le t\u00E9l\u00E9chargement?",
  "ccnotification.notifition.receivermessage.receiverSuccess" : "Re\u00E7u",
  "ccnotification.notification.emailsignature.content.length.exceed" : "La longueur du contenu de la signature ne peut exc\u00E9der 4096.",
  "ccnotification.notifition.variable.close.syntax.error1" : "Le contenu du mod\u00E8le contient des erreurs de syntaxe pr\u00E8s de",
  "message.template.message.operate" : "Op\u00E9ration",
  "ccnotification.bulletinType.message.createBulletinTypeSuccessful" : "Le type de bulletin est cr\u00E9\u00E9 avec succ\u00E8s.",
  "ccnotification.gatewayconfig.label.resendNumbertitle" : "The Resending Times est un nombre appropri\u00E9",
  "ccnotification.notifition.variable.close.syntax.error2" : "V\u00E9rifiez si ${} est ferm\u00E9.",
  "ccnotification.notification.emailsignature.willdeleteselectitem" : "\u00CAtes-vous s\u00FBr de vouloir supprimer les donn\u00E9es s\u00E9lectionn\u00E9es ?",
  "ccnotification.notification.recmessagerouterconfig.gateway" : "Passerelle",
  "ccnotification.notification.recmessagerouterconfig.selectconfig" : "Veuillez s\u00E9lectionner une configuration.",
  "ccnotification.gatewayconfig.label.mailHosttitle" : "Adresse de l'h\u00F4te\, par exemple 127.0.0.1 ou www.mail.com",
  "ccnotification.gatewayconfig.label.deletegatewayfailed" : "La suppression de Gatewayconfig a \u00E9chou\u00E9 \!",
  "ccnotification.center.message.var.value" : "Valeur de la variable \:",
  "message.center.label.allSelect" : "S\u00E9lectionner tout",
  "ccnotification.gatewayconfig.label.messageFlowControlMaxCapsForRecv" : "Taux de r\u00E9ception maximum",
  "ccnotification.notification.recmessagerouterconfig.gatewaynotexist" : "La passerelle s\u00E9lectionn\u00E9e n'existe pas.",
  "ccnotification.notifition.sendmessage.isSendallFail" : "Envoy\u00E9 (\u00E9chec)",
  "message.template.message.typeError" : "Type de notification incorrect.",
  "ccnotification.common.spec.message" : "Impossible de saisir des caract\u00E8res sp\u00E9ciaux.",
  "ccnotification.gatewayconfig.label.smPort" : "Port SMG",
  "ccnotification.notification.recmessagerouterconfig.selectgateway" : "S\u00E9lectionner une passerelle",
  "ccnotification.gatewayconfig.label.messageFlowControlMaxCapsForRecvtitle" : "Le taux de r\u00E9ception maximum est un nombre",
  "message.center.label.bulletinType" : "Type de bulletin",
  "ccnotification.notifition.template.message.textLabel" : "Texte",
  "ccnotification.gatewayconfig.label.receiverPoolQueueCapacitytitle" : "La longueur de file d'attente du pool de fils de r\u00E9ception est un nombre\, par exemple\, 20.",
  "ccnotification.common.button.finish" : "Compl\u00E8te",
  "message.template.message.recipientLengthError" : "Le nombre de destinataires d\u00E9passe la limite.",
  "ccnotification.gatewayconfig.label.splitTypeIsError" : "Le mode de division du message SMS long ne peut \u00EAtre r\u00E9gl\u00E9 que sur 0\:disabled\,1\:not split\,2\:physical splitting\,3\:ultra-long split",
  "ccnotification.notifition.sendmessage.preview" : "Aper\u00E7u",
  "ccnotification.notifition.sendmessage.sendtime" : "Heure d'envoi",
  "ccnotification.bulletinType.message.willDelete" : "\u00CAtes-vous s\u00FBr de vouloir supprimer le type de bulletin s\u00E9lectionn\u00E9 ?",
  "ccnotification.gatewayconfig.label.systemType" : "Type ESME",
  "ccnotification.bulletinType.message.editBulletinTypeFailed" : "Impossible de modifier le type de bulletin.",
  "ccnotification.notifition.template.button.up" : "Vers le haut",
  "ccnotification.notification.label.incorrectAttachmentType" : "Type de pi\u00E8ce jointe d'e-mail incorrect",
  "message.center.label.newMessage" : "Envoyer une notification",
  "message.template.type.internal" : "Notification interne",
  "message.center.nocitiction.sender" : "Exp\u00E9diteur",
  "message.center.nocitiction.sending.records" : "Envoi d'un enregistrement",
  "ccnotification.bulletinType.title.bulletinTypeDesc" : "Descriptif",
  "ccnotification.notification.recmessagerouterconfig.willdeleteselectconfig" : "\u00CAtes-vous s\u00FBr de vouloir supprimer la configuration s\u00E9lectionn\u00E9e ?",
  "ccnotification.flowcontrol.shortMsgCode" : "Num\u00E9ro de message SMS",
  "ccnotification.notifition.sendmessage.unSend" : "\u00C0 envoyer",
  "ccnotification.notifition.sendmessage.selectbyworkno" : "ID d'employ\u00E9",
  "ccnotification.notifition.sendmessage.entercompanyName" : "Saisissez le nom d'une entreprise.",
  "message.center.nocitiction.modifyInternalMessage" : "Modifier le message interne",
  "ccnotification.notification.variable.publicvariable" : "Variable publique",
  "ccnotification.common.message.emptyContent" : "Le contenu ne peut pas \u00EAtre vide.",
  "ccnotification.gatewayconfig.label.modifygateway" : "Modifier la passerelle",
  "message.template.mediaType.internal" : "Message interne",
  "ccnotification.notifition.template.label.mspcontent" : "Contenu du mod\u00E8le SMS",
  "ccnotification.notifition.sendmessage.paramvalidatefailed" : "La validation des param\u00E8tres a \u00E9chou\u00E9.",
  "ccnotification.notifition.template.button.addvar" : "Cr\u00E9er",
  "ccnotification.gatewayconfig.label.RcvAndSendMode" : "Mode TX/RX",
  "ccnotification.gatewayconfig.label.defaultsendno" : "Num\u00E9ro d'envoi par d\u00E9faut",
  "ccnotification.gatewayconfig.label.smHosttitle" : "Adresse SMG\, par exemple\, 127.0.0.1",
  "ccnotification.gatewayconfig.label.certCode" : "Code de certificat",
  "message.template.message.agentRecipientError" : "Certains destinataires ne sont pas li\u00E9s \u00E0 des agents. S\u00E9lectionnez un autre destinataire",
  "message.template.message.save" : "Enregistrer",
  "message.template.message.createTemplateFail" : "Impossible de cr\u00E9er le mod\u00E8le de notification.",
  "ccnotification.notifition.sendmessage.enterBCCRecipient" : "S\u00E9lectionnez un destinataire Cci.",
  "ccnotification.gatewayconfig.label.selectrebuildgatewayconfig" : "S\u00E9lectionnez la configuration de la passerelle \u00E0 reconstruire",
  "message.template.mediaType.email" : "Courriel",
  "ccnotification.notification.label.upload" : "T\u00E9l\u00E9charger",
  "message.template.message.inputRecipient" : "Saisissez un destinataire.",
  "ccnotification.gatewayconfig.label.destAddrNpi" : "Sch\u00E9ma de code d'adresse de destination",
  "ccnotification.gatewayconfig.label.addgatewayconfig" : "Ajouter une configuration de passerelle",
  "message.template.message.inputTemplateName" : "Saisissez un nom de mod\u00E8le.",
  "ccnotification.notification.variable.editfailed" : "\u00C9chec de la modification.",
  "message.template.message.modifySuccess" : "Le mod\u00E8le de notification est modifi\u00E9 avec succ\u00E8s.",
  "ccnotification.notification.systemmonitor.disconnected" : "D\u00E9connect\u00E9",
  "ccnotification.gatewayconfig.label.addressRange" : "Plage d'adresses ESME",
  "ccnotification.notifition.template.message.hwsms" : "Messages SMS HUAWEI CLOUD",
  "ccnotification.notification.label.nameContainSpecialChar" : "Le nom de la pi\u00E8ce jointe contient des caract\u00E8res sp\u00E9ciaux",
  "ccnotification.gatewayconfig.label.userid" : "Identifiant utilisateur",
  "ccnotification.notifition.template.label.signatureChannelId" : "Entrez un ID de canal de signature",
  "ccnotification.notification.recmessagerouterconfig.mobilerule" : "Veuillez saisir les num\u00E9ros de t\u00E9l\u00E9phone portable. S\u00E9parez plusieurs num\u00E9ros mobiles avec des points-virgules",
  "message.center.nocitiction.sending.records.unsend" : "Envoi planifi\u00E9",
  "ccnotification.gatewayconfig.label.configType" : "Type de configuration",
  "ccnotification.gatewayconfig.label.messageFlowControlMessageDropPolicy" : "Politique de traitement du trafic suppl\u00E9mentaire",
  "message.center.label.modify" : "Modifier",
  "ccnotification.notification.recmessagerouterconfig.placeholder.receiver" : "Veuillez saisir l'adresse du destinataire",
  "ccnotification.gatewayconfig.label.hostIsError" : "L'h\u00F4te de messagerie ne peut \u00EAtre format\u00E9 que 127.0.0.1 ouwww.mail.com",
  "ccnotification.gatewayconfig.label.auth" : "Drapeau d'authentification",
  "ccnotification.gatewayconfig.label.esmClass" : "Type de message",
  "ccnotification.common.title.success" : "Succ\u00E8s",
  "ccnotification.urlconfig.pageurls.urlvalidate" : "Les urls doivent commencer par https\://",
  "message.template.message.cancel" : "Annuler",
  "ccnotification.notifition.template.placeholder.var" : "Entrez les valeurs qui r\u00E9pondent aux exigences.",
  "ccnotification.gatewayconfig.label.systemId" : "Nom de connexion",
  "ccnotification.notifition.sendmessage.selectbyskill" : "File d'attente de comp\u00E9tences",
  "ccnotification.gatewayconfig.label.paramIsNotInRange" : "La plage de valeurs du param\u00E8tre est incorrecte",
  "ccnotification.notifition.template.label.signatureName" : "Nom de la signature",
  "ccnotification.notification.recmessagerouterconfig.satisfaction" : "Sondage de satisfaction",
  "message.template.message.ccOrBccRecipientError" : "Le message est envoy\u00E9 avec succ\u00E8s. Il n'existe pas de destinataire Cc ou Cci.",
  "ccnotification.gatewayconfig.label.maxRcvErrortitle" : "Les paquets d'erreur Max re\u00E7us est un nombre appropri\u00E9",
  "ccnotification.flowcontrol.getwayset" : "D\u00E9finir",
  "ccnotification.gatewayconfig.label.heartbeatInterval" : "Intervalle de battement de coeur (ms)",
  "ccnotification.gatewayconfig.label.resendWaitTime" : "Temps d'attente de renvoi (ms)",
  "ccnotification.notification.variable.templatevariable" : "Variable de mod\u00E8le",
  "message.center.nocitiction.system" : "Bulletin du syst\u00E8me",
  "ccnotification.title.flowcontrol.receiveSmsFlag" : "Recevoir des messages SMS MO",
  "ccnotification.notifition.sendmessage.sendtimebeforenow" : "L'heure d'envoi pr\u00E9vue doit \u00EAtre cinq minutes plus tard que l'heure actuelle.",
  "ccnotification.gatewayconfig.label.receiverPoolMinCount" : "Pools de fil de r\u00E9ception min",
  "ccnotification.bulletinType.message.Thursday" : "Jeudi",
  "ccnotification.flowcontrol.title" : "D\u00E9finir la configuration des notifications",
  "ccnotification.bulletinType.title.createBulletinType" : "Cr\u00E9er un type de bulletin",
  "ccnotification.gatewayconfig.label.splitMsgConcatMode" : "Mode de concat\u00E9nation de message long",
  "ccnotification.notifition.sendmessage.priorityFault" : "Critique",
  "ccnotification.gatewayconfig.label.protocolId" : "ID de protocole",
  "ccnotification.notifition.variable.been.used" : "est r\u00E9f\u00E9renc\u00E9 par un mod\u00E8le et ne peut pas \u00EAtre supprim\u00E9.",
  "ccnotification.bulletinType.message.Monday" : "Lundi",
  "ccnotification.chatserver.fail.assign" : "\u00C9chec de l'allocation d'agent",
  "message.template.message.basicInformation" : "Informations de base",
  "ccnotification.flowcontrol.emailThreshold" : "Seuil de donn\u00E9es quotidien pour les e-mails",
  "ccn.smsnoconfig.pop.title.createtitle" : "Ajouter un num\u00E9ro de SMS",
  "ccnotification.notifition.template.label.hwtemplateid" : "ID de mod\u00E8le HUAWEI CLOUD",
  "message.template.message.phoneError" : "Le num\u00E9ro de t\u00E9l\u00E9phone n'existe pas.",
  "ccnotification.gatewayconfig.label.bindModetitle" : "Indique le mode TX/RX. 1 \: Mode RX ; 2 \: Mode TX ; 9 \: Mode TX/RX.",
  "ccnotification.caseserver.fail.serviceException" : "Service anormal",
  "ccnotification.notifition.variable.searchVariableName" : "Saisissez un nom de variable.",
  "ccnotification.gatewayconfig.label.sourceAddrTontitle" : "Le type de code d'adresse source est un nombre",
  "message.center.label.isRead" : "Marquer les messages comme lus",
  "ccnotification.smshuawei.label.appAdress" : "Adresse d'acc\u00E8s \u00E0 l'application",
  "ccnotification.smshuawei.message.invalidappadress" : "Adresse d'acc\u00E8s \u00E0 l'application non valide.",
  "ccnotification.gatewayconfig.label.sourceAddrTon" : "Type de code d'adresse source",
  "ccnotification.notification.label.downPermissionFailed" : "\u00C9chec de la v\u00E9rification de l'autorisation de t\u00E9l\u00E9chargement des pi\u00E8ces jointes de l'e-mail",
  "ccnotification.flowcontrol.setShortMsgCode" : "D\u00E9finissez le num\u00E9ro des messages SMS.",
  "ccn.smsnoconfig.pop.check.duplicatecode" : "Le num\u00E9ro existe d\u00E9j\u00E0.",
  "ccnotification.notification.label.downloadAttachment" : "T\u00E9l\u00E9charger la pi\u00E8ce jointe",
  "ccnotification.gatewayconfig.label.INIT" : "Initialisation",
  "message.center.nocitiction.inbox" : "Bo\u00EEte de r\u00E9ception",
  "ccnotification.notification.label.isDownloadAttachment": "Si la pièce jointe que vous téléchargez est au format de document Office, veuillez prendre les précautions de sécurité nécessaires.",
  "ccnotification.notifition.sendmessage.sysmessage" : "Notification du syst\u00E8me",
  "ccnotification.gatewayconfig.label.bindMode" : "Mode de r\u00E9ception et d'envoi",
  "ccnotification.gatewayconfig.label.senderPoolQueueCapacity" : "Envoi de la longueur de file d'attente du pool de fils",
  "ccnotification.gatewayconfig.label.smppPduTypeIsError" : "Le type SMS SMPP PDU ne peut \u00EAtre que 1\:submit_sm\,2\:data_sm",
  "ccnotification.gatewayconfig.label.recFlowController" : "Nom du contr\u00F4leur de trafic de liaison montante",
  "ccnotification.gatewayconfig.label.dataCodingtitle" : "0\: cha\u00EEne ASCII 4\: informations binaires 8\: encodage UCS2",
  "message.center.label.reply" : "R\u00E9pondre",
  "ccnotification.gatewayconfig.label.maxRcvError" : "Paquets d'erreur maximum re\u00E7us",
  "ccnotification.gatewayconfig.label.statusisnoterror" : "le statut n'est pas ERREUR.",
  "ccnotification.notification.variable.template" : "Mod\u00E8le",
  "ccnotification.notification.recmessagerouterconfig.serviceType" : "M\u00E9thode de mise en \u0153uvre",
  "ccnotification.notifition.sendmessage.referenceTemplate" : "Mod\u00E8le de r\u00E9f\u00E9rence",
  "ccnotification.gatewayconfig.label.recFlowControllertitle" : "Nom du contr\u00F4leur de trafic qui contr\u00F4le le d\u00E9bit des messages de liaison montante.",
  "ccnotification.notification.label.serverIsNotobs" : "Les pi\u00E8ces jointes ne peuvent \u00EAtre t\u00E9l\u00E9charg\u00E9es qu'\u00E0 partir du serveur de fichiers OBS",
  "ccnotification.smshuawei.label.config.explain" : "Les donn\u00E9es de configuration doivent \u00EAtre les m\u00EAmes que celles de HUAWEI CLOUD SMS.",
  "message.center.label.senderName" : "Exp\u00E9diteur",
  "ccnotification.notifition.sendmessage.templateselected" : "Cet avis a fait r\u00E9f\u00E9rence \u00E0 la",
  "ccnotification.gatewayconfig.label.registeredDeliverytitle" : "Le rapport de confirmation du statut de retour est un nombre",
  "ccnotification.gatewayconfig.label.defaultreceinfo" : "ID de r\u00E9ception par d\u00E9faut",
  "ccnotification.agent.contact.downloadFail" : "\u00C9chec du t\u00E9l\u00E9chargement",
  "ccnotification.notifition.template.placeholder.varnumber" : "Entrez le nombre de variables",
  "ccnotification.notifition.sendmessage.bulletinType" : "Type de bulletin",
  "ccnotification.notification.label.uploadAttachmentTips" : "Les pi\u00E8ces jointes peuvent \u00EAtre dans le format suivant: doc, docx, xls, xlsx, txt, png, jpg, xml, json, csv, bmp, zip, ppt, pptx, pdf, La taille du fichier ne peut pas d\u00E9passer 10 Mo.Les fichiers du paquet compressé doivent également être conformes au format précédent.",
  "ccnotification.notification.uploadfail.duplicateFileName" : "Dupliquer le nom du fichier.",
  "ccnotification.gatewayconfig.label.messageFlowControlMessageDropPolicytitle" : "Actuellement\, seul Ignore est pris en charge.",
  "ccnotification.gatewayconfig.label.smHost" : "Adresse SMG",
  "message.center.nocitiction.callback.record" : "Enregistrement de rappel",
  "message.center.message.selectMessages" : "S\u00E9lectionnez un message.",
  "ccnotification.flowcontrol.modifySuccess" : "La configuration de la notification est modifi\u00E9e avec succ\u00E8s.",
  "ccnotification.notifition.sendmessage.enterusername" : "Entrez un nom.",
  "ccnotification.common.button.new" : "Nouveau",
  "ccnotification.bulletinType.message.betchDeleteBulletinTypeSuccessful" : "Les types de bulletins sont supprim\u00E9s par lots avec succ\u00E8s.",
  "ccnotification.smshuawei.message.updatefail" : "Impossible de modifier le canal SMS.",
  "ccnotification.bulletinType.message.sendingFrequency" : "fr\u00E9quence d'envoi",
  "ccnotification.notifition.template.message.charDigitLabel" : "Chiffre et lettre",
  "ccnotification.bulletinType.message.selectItem" : "S\u00E9lectionnez un type de bulletin.",
  "ccnotification.common.message.sendIntervalShort" : "Envoi fr\u00E9quent. Intervalle (s) d'envoi \: {n}.",
  "ccnotification.gatewayconfig.label.SSLEncryptionModetitle" : "Mode de chiffrement\, AUCUN \: transmission en clair (port par d\u00E9faut \: 25)TLS \: transmission chiffr\u00E9e en utilisant TLS (port par d\u00E9faut \: 465) STARTTLS \: transmission chiffr\u00E9e en utilisant la commande STARTTLS (port par d\u00E9faut \: 587)",
  "ccnotification.notifition.template.label.index" : "Non.",
  "ccnotification.common.message.maxExpireTime" : "S\u00E9lectionnez une date d'expiration au plus tard {0}.",
  "ccnotification.gatewayconfig.label.slipWindowSize" : "Taille de fen\u00EAtre coulissante",
  "ccnotification.notifition.sendmessage.limitdate" : "S\u00E9lectionnez une date ant\u00E9rieure \u00E0 2038-1-19.",
  "ccnotification.gatewayconfig.label.defaultemailaddr" : "Adresse e-mail par d\u00E9faut",
  "ccnotification.notification.recmessagerouterconfig.defaultreceiveemail" : "Par d\u00E9faut Recevoir l'email",
  "ccnotification.gatewayconfig.label.smPassword" : "Login Mot de passe",
  "message.template.message.deleteTemplate" : "Suppression d'un mod\u00E8le de notification",
  "message.template.message.descriptionLengthError" : "La description d\u00E9passe la limite.",
  "message.template.message.deleteSuccess" : "Le mod\u00E8le de notification est supprim\u00E9 avec succ\u00E8s.",
  "ccnotification.flowcontrol.countryCode" : "Code de pays",
  "ccnotification.notifition.template.placeholder.des" : "D\u00E9crire la variable",
  "ccnotification.notification.recmessagerouterconfig.updatenum.outstripping" : "Un maximum de 100 enregistrements peuvent \u00EAtre mis \u00E0 jour \u00E0 la fois.",
  "ccnotification.notification.label.fileUploadFileNameError" : "Le nom du fichier que vous avez t\u00E9l\u00E9charg\u00E9 ne r\u00E9pond pas aux exigences",
  "ccnotification.gatewayconfig.label.maxMessageLengthtitle" : "La longueur maximale du message (octet) est un nombre\, qui est sp\u00E9cifi\u00E9 dans le protocole\, par exemple\, 2048.",
  "ccnotification.notifition.variable.variableType" : "S\u00E9lectionnez un type de variable.",
  "ccnotification.notifition.sendmessage.templateselect" : "S\u00E9lectionner un mod\u00E8le",
  "ccnotification.gatewayconfig.label.transactionTimeout" : "Intervalle de d\u00E9lai de transaction (ms)",
  "ccnotification.notifition.variable.variable" : "Variable",
  "message.center.nocitiction.callback.status" : "Statut de rappel",
  "message.center.nocitiction.callback.attachment" : "Pi\u00E8ce jointe",
  "ccnotification.gatewayconfig.label.inputCertCode" : "Entrez le code du certificat.",
  "ccnotification.notifition.sendmessage.isSendallSuccess" : "Envoy\u00E9(Succ\u00E8s)",
  "ccnotification.notification.label.uploadAttachmentSuccess" : "T\u00E9l\u00E9chargement de la pi\u00E8ce jointe r\u00E9ussi",
  "ccnotification.gatewayconfig.label.willDeletegateway" : "\u00CAtes-vous s\u00FBr de vouloir supprimer la configuration de passerelle s\u00E9lectionn\u00E9e ?",
  "ccnotification.gatewayconfig.label.deletegatewaysuccess" : "Gatewayconfig supprimer avec succ\u00E8s\!",
  "ccnotification.notifition.sendmessage.recipient" : "Destinataire",
  "ccnotification.notifition.sendmessage.maxselectcount" : "Un maximum de 100 employ\u00E9s peuvent \u00EAtre s\u00E9lectionn\u00E9s.",
  "ccnotification.notifition.template.message.charDigit" : "- La valeur est une cha\u00EEne de 1 \u00E0 20 chiffres et lettres.",
  "ccnotification.notification.label.downloadFromObsFailed" : "Impossible de t\u00E9l\u00E9charger des fichiers \u00E0 partir de OBS",
  "ccnotification.bulletinType.message.Saturday" : "Samedi",
  "ccnotification.gatewayconfig.label.splitMsgConcatModetitle" : "Mode de combinaison de messages SMS longs. 0\: En-t\u00EAte de donn\u00E9es utilisateur GSM; 1\: Param\u00E8tre SMPP.",
  "ccnotification.notifition.template.label.type" : "Type",
  "message.center.nocitiction.sending.records.smsType" : "Mode de message SMS",
  "ccnotification.notification.recmessagerouterconfig.receiveaddressexist" : "L'adresse de r\u00E9ception existe d\u00E9j\u00E0.",
  "message.center.label.receiverGroup" : "Groupe de b\u00E9n\u00E9ficiaires",
  "ccnotification.flowcontrol.messageThreshold" : "Seuil de donn\u00E9es quotidien pour les messages SMS",
  "ccnotification.gatewayconfig.label.senderPoolMaxCounttitle" : "Les pools de fils d'envoi max est un nombre\, par exemple\, 200.",
  "ccnotification.notification.emailsignature.noitemselect" : "Ne s\u00E9lectionne aucune donn\u00E9e.",
  "ccnotification.gatewayconfig.label.destAddrTon" : "Type de code d'adresse de destination",
  "message.center.label.select" : "Filtrer",
  "ccnotification.notifition.variable.namerule" : "Le nom de la variable ne peut contenir que les alphabets chiffres _ et.",
  "ccnotification.notifition.sendmessage.selecttenant" : "S\u00E9lectionnez un locataire.",
  "ccnotification.gatewayconfig.label.senderPoolMinCounttitle" : "Les pools de fils d'envoi Min est un nombre\, par exemple\, 20.",
  "ccnotification.notification.label.queryAttachmentFailed" : "Impossible d'interroger la pi\u00E8ce jointe",
  "message.template.mediaType.shortmessage" : "SMS",
  "message.center.nocitiction.ineermessage" : "Message interne",
  "ccnotification.notifition.gatewaycontact.connecting" : "connexion",
  "ccnotification.gatewayconfig.label.resendNumber" : "Temps de renvoi",
  "ccnotification.notifition.sendmessage.internalmessage" : "Notification interne",
  "ccnotification.gatewayconfig.label.maxContentLength" : "Longueur maximum de contenu (octets)",
  "ccnotification.gatewayconfig.label.splitTypetitle" : "Mode de division d'un message SMS trop long. Les options sont les suivantes\: 0\: non fractionn\u00E9; 1\: non fractionn\u00E9; 2\: fractionn\u00E9 physiquement; 3\: fractionn\u00E9 un message SMS trop long.",
  "ccnotification.gatewayconfig.label.heartbeatNoRespCount" : "Temps de connexion max sur aucune r\u00E9ponse de battement de coeur",
  "ccnotification.bulletinType.title.General" : "Faible",
  "ccn.smsnoconfig.pop.check.deletefailed" : "La suppression a \u00E9chou\u00E9.",
  "ccnotification.gatewayconfig.label.mailPorttitle" : "Le num\u00E9ro de port va de 0 \u00E0 65535. Le num\u00E9ro de port SMTP par d\u00E9faut est 25.",
  "message.template.message.generate" : "G\u00E9n\u00E9rer un mod\u00E8le",
  "ccnotification.common.message.maxLength" : "Le contenu du texte peut contenir un maximum de 4096 caract\u00E8res.",
  "ccnotification.notifition.sendmessage.expireTime" : "Expir\u00E9 le",
  "ccnotification.notification.label.isDeleteAttachment" : "Supprimer la pi\u00E8ce jointe",
  "ccnotification.notifition.template.button.down" : "En bas",
  "ccnotification.notification.recmessagerouterconfig.mediaType" : "Type de m\u00E9dia",
  "ccnotification.gatewayconfig.label.code" : "Mode d'encodage",
  "message.template.message.messageTemplateCreate" : "Cr\u00E9er un mod\u00E8le de notification",
  "ccnotification.center.message.var.des" : "Description de la variable \:",
  "message.center.nocitiction.bulletin" : "Bulletin",
  "ccnotification.gatewayconfig.label.transactionTimeouttitle" : "L'intervalle de d\u00E9lai d'expiration de transaction est un nombre appropri\u00E9",
  "ccnotification.gatewayconfig.label.messageFlowControlMaxCapsForSend" : "Taux d'envoi maximum",
  "ccnotification.smshuawei.label.appSecret" : "Secret de l'application",
  "ccnotification.notifition.sendmessage.sendexception" : "Erreur d'envoi.",
  "ccnotification.gatewayconfig.label.gatewayipaddrconfig" : "Configuration d'interconnexion d'adresses de passerelle",
  "ccnotification.smstemplate.message.var.number" : "Le nombre de variables d'entr\u00E9e est diff\u00E9rent du nombre de variables ajout\u00E9es.",
  "ccnotification.notification.systemmonitor.rebuilding" : "Reconstruire",
  "ccnotification.bulletinType.message.sendTimeParamsError2" : "Le temps de lib\u00E9ration ne peut pas \u00EAtre sup\u00E9rieur \u00E0 la date d'expiration",
  "ccnotification.notifition.sendmessage.invalidSect" : "Segment de temps d'expiration",
  "ccnotification.notifition.variable.selectvariable" : "S\u00E9lectionner une variable",
  "ccnotification.bulletinType.message.sendTimeParamsError1" : "L'heure de publication ne peut pas \u00EAtre ant\u00E9rieure \u00E0 l'heure actuelle",
  "ccnotification.gatewayconfig.label.modifygatewayfailed" : "La modification de la configuration de la passerelle a \u00E9chou\u00E9 \!",
  "ccnotification.gatewayconfig.label.bindModeIsError" : "Mode d'envoi et de r\u00E9ception de SMS 1 \: mode de r\u00E9ception\, 2 \: mode de transmission\, 9 \: mode de TX/RX",
  "ccnotification.gatewayconfig.label.authIsError" : "L'identifiant d'authentification ne peut \u00EAtre que vrai ou faux",
  "message.template.message.recipient" : "Destinataire",
  "ccnotification.notifition.sendmessage.enterCCRecipient" : "S\u00E9lectionnez un destinataire Cc.",
  "ccnotification.gatewayconfig.label.configNameAlreadyExists" : "Le nom de configuration existe d\u00E9j\u00E0",
  "ccnotification.notifition.sendmessage.inputBulletinType" : "Saisissez le nom du type de bulletin.",
  "ccnotification.bulletinType.message.nameIsNotUnique" : "Le nom du type de bulletin existe d\u00E9j\u00E0.",
  "message.center.button.query" : "Requ\u00EAte",
  "ccnotification.gatewayconfig.label.errorstylenumber" : "Format de nombre incorrect.",
  "ccnotification.notifition.template.message.money" : "- La valeur peut contenir de 1 \u00E0 20 chiffres. Seuls les chiffres et les d\u00E9cimales qui peuvent exprimer correctement le montant peuvent \u00EAtre transf\u00E9r\u00E9s.",
  "ccnotification.notifition.sendmessage.senddate" : "Date d'envoi",
  "ccn.smsnoconfig.table.label" : "Num\u00E9ro de SMS",
  "ccnotification.agent.contact.workno" : "ID d'employ\u00E9",
  "ccnotification.bulletinType.message.decimealsCheck" : "Le d\u00E9lai d'expiration par d\u00E9faut ne peut pas contenir de d\u00E9cimale.",
  "message.center.button.clear" : "R\u00E9initialiser",
  "message.template.message.titleLengthError" : "Le titre ne peut pas d\u00E9passer 200 caract\u00E8res ou contenir des caract\u00E8res sp\u00E9ciaux.",
  "ccnotification.gatewayconfig.label.paramIsNegative" : "Le nombre ne peut pas \u00EAtre n\u00E9gatif",
  "ccnotification.bulletinType.title.Rushse" : "\u00C9lev\u00E9",
  "ccn.smsnoconfig.table.header.countrycode" : "Code de pays de l'exp\u00E9diteur",
  "ccnotification.gatewayconfig.label.messageFlowControlMaxCapsForSendtitle" : "Le Max Sending Rate est un nombre",
  "ccnotification.notifition.template.title.setting" : "D\u00E9finir la variable",
  "ccnotification.notifition.sendmessage.sendfailed" : "L'envoi a \u00E9chou\u00E9.",
  "ccnotification.gatewayconfig.label.smPorttitle" : "Plage de num\u00E9ros de port SMG \: un nombre entier allant de 0 \u00E0 65535",
  "ccnotification.gatewayconfig.label.resendWaitTimetitle" : "Le Temps d'attente de renvoi est un nombre appropri\u00E9",
  "ccnotification.notifition.sendmessage.sender" : "Num\u00E9ro de l'exp\u00E9diteur",
  "ccnotification.gatewayconfig.label.destAddrTontitle" : "Le type de code d'adresse de destination est un nombre",
  "message.template.message.deleteTemplateFail" : "Impossible de supprimer le mod\u00E8le de notification.",
  "ccnotification.gatewayconfig.label.maxsmGatewayIsError" : "Un maximum de 10 passerelles SMS peuvent \u00EAtre configur\u00E9es dans le m\u00EAme espace locataire",
  "ccnotification.notifition.gatewaycontact.failed" : "Erreur de connexion",
  "ccnotification.notifition.sendmessage.messagetitle" : "Titre",
  "ccnotification.gatewayconfig.label.addrNpi" : "Mode d'encodage d'adresse ESME",
  "ccnotification.smshuawei.label.certVerifyEnabled" : "Activer ou non la v\u00E9rification du certificat",
  "ccnotification.gatewayconfig.label.certFile" : "Fichier de certificat",
  "ccnotification.smshuawei.message.deletesuccess" : "Le canal SMS est supprim\u00E9 avec succ\u00E8s.",
  "ccnotification.smshuawei.message.fail" : "\u00C9chec",
  "message.center.label.download" : "T\u00E9l\u00E9chargement",
  "ccnotification.notifition.sendmessage.ccRecipient" : "Cc",
  "ccnotification.notification.systemmonitor.gatewayName" : "Nom de la passerelle",
  "ccnotification.notification.recmessagerouterconfig.receivernotexist" : "Le destinataire s\u00E9lectionn\u00E9 n'existe pas.",
  "ccnotification.notification.label.createTemFileFailed" : "Impossible de cr\u00E9er le fichier temporaire",
  "message.center.label.expireTime" : "Expir\u00E9 le",
  "ccnotification.gatewayconfig.label.protocol" : "Type de protocole",
  "ccnotification.bulletinType.message.weekly" : "Hebdomadaire",
  "ccnotification.smshuawei.label.signatureChannelId.length" : "La longueur de signatureChannelId d\u00E9passe la plage de valeurs.",
  "message.template.message.nameLengthError" : "Le nom ne peut pas \u00EAtre vide et ne peut pas d\u00E9passer 200 caract\u00E8res ou contenir des caract\u00E8res sp\u00E9ciaux.",
  "message.center.nocitiction.message" : "SMS",
  "ccnotification.smstemplate.message.receiverPhone" : "Les destinataires ne peuvent pas \u00EAtre dupliqu\u00E9s.",
  "message.template.message.contentLengthError" : "Le contenu ne peut pas \u00EAtre vide et ne peut pas d\u00E9passer la longueur.",
  "ccnotification.gatewayconfig.message.authInfo" : "D\u00E9finir faux comporte des risques de s\u00E9curit\u00E9. Il est conseill\u00E9 de d\u00E9finir true pour activer l'authentification par mot de passe.",
  "ccnotification.smstemplate.message.var" : "Le type, le nombre, la description ou la valeur par d\u00E9faut des variables ne r\u00E9pondent pas aux exigences.",
  "ccnotification.smstemplate.message.signatureName" : "le nom de la signature ne peut pas \u00EAtre vide, ne peut pas \u00EAtre trop long ou ne respecte pas les sp\u00E9cifications.",
  "ccnotification.notifition.template.message.text" : "- La valeur est une cha\u00EEne de 1 \u00E0 20 caract\u00E8res et ne peut pas contenir 000",
  "ccnotification.smstemplate.message.smstemplateid" : "L'ID de mod\u00E8le HUAWEI CLOUD ne peut pas \u00EAtre vide, trop long ou ne respecte pas les sp\u00E9cifications.",
  "ccnotification.sms.rcvAndSendMode.sendSms" : "Envoyer des SMS",
  "ccnotification.sms.rcvAndSendMode.receiveSms" : "Recevoir des SMS",
  "ccnotification.sms.smppPduTye.data.sm" : "data_sm",
  "ccnotification.sms.smppPduTye.submit.sm" : "submit_sm",
  "ccnotification.sms.rcvAndSendMode.receiveAndSendSms" : "Envoyer et recevoir des SMS",
  "ccnotification.sms.gms.user.data.head" : "En-t\u00EAte de donn\u00E9es utilisateur GSM",
  "ccnotification.sms.smpp.parameters" : "Param\u00E8tres SMPP",
  "ccnotification.sms.smpp.replaceIfPresentFlag.replace" : "Remplacer",
  "ccnotification.sms.smpp.replaceIfPresentFlag.not.replace" : "Non remplacer",
  "ccnotification.sms.smpp.stow.away" : "ranger loin",
  "ccnotification.sms.smpp.ESME.Connection.Configuration" : "Configuration de la connexion ESME",
  "ccnotification.gatewayconfig.label.splitType.disabled" : "disabled",
  "ccnotification.gatewayconfig.label.splitType.noSplit" : "not split",
  "ccnotification.gatewayconfig.label.splitType.physical" : "physical splitting",
  "ccnotification.gatewayconfig.label.splitType.ultraLong" : "ultra-long split",
  "ccnotification.gatewayconfig.label.dataCoding.Binary" : "informations binaires",
  "ccnotification.gatewayconfig.label.dataCoding.ASCII" : "cha\u00EEne ASCII",
  "ccnotification.gatewayconfig.label.basic.configuration" : "Configuration de base",
  "ccnotification.gatewayconfig.label.advancedconfig" : "Param\u00E8tres avanc\u00E9s",
  "ccnotification.notification.recmessagerouterconfig.defaultsendnumber" : "Num\u00E9ro d'envoi par d\u00E9faut.",
  "ccnotification.notification.recmessagerouterconfig.defaultreceivenumber" : "Num\u00E9ro de r\u00E9ception par d\u00E9faut.",
  "ccnotification.gatewayconfig.label.dataCoding.UCS2" : "encodage UCS2",
  "ccnotification.template.message.organizationStructure" : "Organisme organisateur",
  "ccnotification.template.message.alignment" : "Le nombre d'options de carte ne peut pas dépasser",
  "ccnotification.email.signature.message.uploadImgTypeError" : "Seules les images au format JPG JPEG ou PNG peuvent \u00EAtre t\u00E9l\u00E9charg\u00E9es",
  "ccnotification.email.signature.knowledge.message.uploadImgSizeError" : "La taille de l'image t\u00E9l\u00E9charg\u00E9e ne peut pas d\u00E9passer {}",
  "ccnotification.email.signature.knowledge.message.uploadError" : "Le contenu de la signature de l'e-mail contient des images de taille ou de format incorrect. Supprimez-les et r\u00E9essayez",
  "ccn.flow.grayrule.editsuccess" : "Modifi\u00E9 avec succ\u00E8s.",
  "ccn.voiceedit.addsuccess" : "Ajout r\u00E9ussi.",
  "ccn.voiceedit.addfail" : "L'ajout a \u00E9chou\u00E9.",
  "ccnotification.notification.emailsignature.selection.userEmail" : "Adresse e-mail de l'utilisateur",
  "ccnotification.notification.emailsignature.selection.userPosition" : "Position de l'utilisateur",
  "ccn.voiceedit.deletesuccess" : "Supprim\u00E9 avec succ\u00E8s.",
  "ccnotification.notifition.template.create" : "Création d'un modèle de notification",
  "ccnotification.template.message.channelType" : "Type de canal",
  "pop.org.window.org" : "Accueil Organisation",
  "ccnotification.template.message.add.variable": "Ajouter",
  "ccnotification.template.message.content": "Contenu du modèle",
  "ccnotification.template.message.enterDescription": "Veuillez entrer une description.",
  "ccnotification.notification.systemmonitor.batchrebuild":"Reconstruction par lots",
  "ccnotification.notification.systemmonitor.batchdisconnect":"Déconnexion par lots",
  "ccnotification.gatewayconfig.label.send.prefix":"Préfixe du numéro de l'expéditeur",
  "ccnotification.gatewayconfig.label.receive.prefix":"Préfixe de nombre accepté",
  "ccnotification.signature.add":"Cr\u00E9er une signature d'e-mail",
  "ccnotification.signature.update":"Modifier la signature de l'e-mail",
  "ccnotification.signature.view":"Afficher la signature de l'e-mail",
  "ccnotification.gatewayconfig.label.select.sendNumber.title":"Sélection du numéro de l'expéditeur",
  "ccnotification.gatewayconfig.label.add.sendNumber.title":"Ajout d'un numéro d'expéditeur",
  "ccn.whitelist.urlvalidatefailed": "Veuillez saisir l'adresse IP ou le nom de domaine",
  "ccnotification.notification.notificationRoute.recipientAddress" : "Adresse de réception",
  "ccnotification.notification.notificationRoute.implementationMode" : "Modalités de mise en œuvre",
  "ccnotification.notification.notificationRoute.edit" : "Modifier",
  "ccnotification.notification.notificationRoute.newNotificationRoute" : "Nouvelle route de notification",
  "ccnotification.notification.notificationRoute.editNotificationRoute" : "Modifier l'itinéraire de notification",
  "ccnotification.notification.notificationRoute.inbox" : "Boîte de réception",
  "ccnotification.notification.notificationRoute.serviceInvoking" : "Appel de service",
  "ccnotification.notification.notificationRoute.selectconfig" : "Veuillez s\u00E9lectionner une configuration.",
  "ccnotification.notification.notificationRoute.willdeleteselectconfig" : "\u00CAtes-vous s\u00FBr de vouloir supprimer la configuration s\u00E9lectionn\u00E9e ?",
  "ccnotification.notification.notificationRoute.deletenum.outstripping" : "Un maximum de 100 enregistrements peuvent \u00EAtre supprim\u00E9s \u00E0 la fois.",
  "ccnotification.notification.notificationRoute.delSuccess" : "Supprim\u00E9 avec succ\u00E8s.",
  "ccnotification.notification.notificationRoute.delFail" : "La suppression a échoué.",
  "ccnotification.notification.notificationRoute.gateway" : "Passerelle",
  "ccnotification.notification.notificationRoute.gatewaynotexist" : "La passerelle sélectionnée n'existe pas",
  "ccnotification.notification.notificationRoute.receivernotexist" : "Le destinataire sélectionné n'existe pas",
  "ccnotification.notification.notificationRoute.receiveaddressexist" : "L'adresse de réception existe déjà",
  "ccnotification.notification.notificationRoute.intelligentOrder" : "Création intelligente de cas",
  "ccnotification.notification.notificationRoute.mobilerule" : "Veuillez saisir les numéros de téléphone portable. Séparez plusieurs numéros mobiles avec des points-virgules",
  "ccnotification.notification.notificationRoute.inputPhoneNum" : "Veuillez saisir votre numéro de téléphone",
  "ccnotification.notification.notificationRoute.receiveAddrLen" : "La longueur ne peut pas dépasser 1024",
  "ccnotification.notification.notificationRoute.retryNumLimitRule" : "La valeur d'entrée est hors de la plage:0-3",
  "ccn.variable.willdeleteselectvariable": "Êtes-vous sûr de vouloir supprimer les variables sélectionnées ?",
  "ccn.variable.deleteselectvariable": "Supprimer la variable",
  "ccn.variable.variableisreferenced": "Les variables sont référencées par flux.",
  "ccn.variable.view.references": "Voir les références",
  "ccn.variable.view.references.none": "La variable n'est actuellement pas référencée",
  "ccn.variable.selectvariable": "Veuillez sélectionner un paramètre variable.",
  "ccn.variable.field": "Variables de notification",
  "ccn.unit.bytes": "Octets",
  'ccn.unit.millisecond': 'milliseconde',
  "ccn.message.fullscreen": "Maximiser",
  'ccn.sms.config.batch.delete': 'Suppression par lots',
  'ccn.sms.config.receiver.uplinkMess': 'Recevoir des SMS en amont',
  "ccn.sms.config.create.smsConfig": "Créer une nouvelle configuration de SMS",
  "ccn.sms.config.modify.smsConfig": "Modification des configurations SMS",
  "ccnotification.smstemplate.message.edit.fail": "Impossible de modifier le modèle de SMS HUAWEI CLOUD.",
  "ccnotification.message.choose": "Veuillez sélectionner les données.",
  "ccnotification.message.add.attachement": "Ajouter une pièce jointe",
  "ccnotification.message.defaultsenderid.error": "Le numéro d'expéditeur par défaut n'est pas dans la plage autorisée. Veuillez sélectionner un numéro correct.",
  "ccnotification.template.message.emailAddressEmpty": "Email du destinataire non configuré:",
  "ccn.countrycode.type": "Type de préfixe de code pays",
  "ccn.countrycode.type.with.plus": "Préfixé par +",
  "ccn.countrycode.type.without.plus": "Sans préfixe par +"
}
