import AiccElMessage from '@/utils/el-message'
import i18n from '@/lang'

export const handleSendResult = (result, isSave = false) => {
  const $t = i18n.global.t
  if (result) {
    if (result.returnCode == 0) {
      const hint = isSave ? $t('ccnotification.notifition.sendmessage.savesuccess') : $t('ccnotification.notifition.sendmessage.sendsuccess')
      AiccElMessage.success(hint)
      window.$BES.$Portal.tabpanel.closeTabItem()
    } else if (result.returnCode == 1) {
      // 发送失败
      AiccElMessage.error($t('ccnotification.notifition.sendmessage.sendfailed'))
    } else if (result.returnCode == 2) {
      // 参数校验失败
      AiccElMessage.error($t('ccnotification.notifition.sendmessage.paramvalidatefailed'))
    } else if (result.returnCode == 4) {
      // 发送时间不对
      AiccElMessage.error($t('ccnotification.notifition.sendmessage.sendTimeFailed'))
    } else if (result.returnCode == 5) {
      // 接收号码为空
      AiccElMessage.error($t('ccnotification.notifition.sendmessage.receiverPhoneIsNull'))
    } else if (result.returnCode == 6) {
      // 内容为空
      AiccElMessage.error($t('message.template.message.contentError'))
    } else if (result.returnCode == 7) {
      // 发送邮件为空
      AiccElMessage.error($t('message.template.message.sendEmailError'))
    } else if (result.returnCode == 8) {
      // 短信配置为空
      AiccElMessage.error($t('ccnotification.notifition.sendmessage.smsconfignull'))
    } else if (result.returnCode == 9) {
      // 收件人不存在
      AiccElMessage.error($t('message.template.message.recipientError'))
    } else if (result.returnCode == 10) {
      // 发送量已超过每天阈值
      AiccElMessage.error($t('message.template.message.frequently'))
    } else if (result.returnCode == 11) {
      // 收件人未选择座席
      AiccElMessage.error($t('message.template.message.agentbebinded'))
    } else if (result.returnCode == 12) {
      // 查询已绑定座席的用户失败
      AiccElMessage.error($t('message.template.message.queryagentbebindedfailed'))
    } else if (result.returnCode == 13) {
      // 抄送人或密送人不存在
      AiccElMessage.error($t('message.template.message.ccOrBccRecipientError'))
    } else if (result.returnCode == 14) {
      // 发送频繁，发送时间间隔为{interval}秒
      AiccElMessage.error($t('ccnotification.common.message.sendIntervalShort', {n: result.interval}))
    } else {
      AiccElMessage.error($t('ccnotification.notifition.sendmessage.sendexception'))
    }
  } else {
    AiccElMessage.error($t('ccnotification.notifition.sendmessage.sendexception'))
  }
}