export default {
  "ccd.agent.message.default" : "خطأ داخلي. تحقق من السجل.",
  "ccd.agentstate.notTalking" : "الموظف المحدد ليس في حالة التحدث.",
  "ccd.agent.videocall.displayError" : "يحدث استثناء عند ضبط وضع العرض التقديمي لمكالمة فيديو ثلاثية الأطراف.",
  "ccd.agent.webrtc.label.misspeaker" : "مكبر الصوت غير متوفر.",
  "ccd.agent.openeye.deregister.fail" : "فشل في إلغاء تنشيط رقم الهاتف. قم بتسجيل الخروج يدويًا من OpenEye.",
  "ccd.transfer.message.inputExNumber" : "الرجاء إدخال الرقم المراد تحويله",
  "ccd.agent.agentevent.quality.calloutconnectfail" : "لا يمكن إجراء مكالمة صادرة لأن فحص الجودة قيد التقدم.",
  "ccd.transfer.label.transferNumber" : "تشير إلى ما إذا كانت العملية عملية خدمة ذاتية.",
  "ccd.agent.openeye.sipconfig.fail" : "فشل تكوين SIP. قم بتسجيل الدخول كموظف مرة أخرى أو قم بتسجيل الدخول يدويًا إلى OpenEye.",
  "ccd.agent.message.mediaSwitchNoCall" : "لا يتم إجراء أي مكالمة أثناء تبديل الصوت/الفيديو.",
  "ccd.agent.agentevent.AgentOther_Restskill_Queue" : "نجحت عملية قائمة انتظار المهارات.",
  "ccd.ccagent.contract.preview" : "معاينة",
  "ccd.agent.warning.notification.allowed" : "لضمان تنبيهات رسائل الجلسة ، يجب السماح لموقع الويب بعرض الإشعارات!",
  "ccd.agent.agentevent.AgentState_Busy" : "الوكيل يعمل",
  "ccd.agent.button.endMute" : "إلغاء كتم الصوت",
  "ccd.agent.forcesignout.confirm" : "سيؤثر تسجيل الخروج على خدمة الاتصال التي يقوم الموظف بمعالجتها. هل أنت متأكد من رغبتك في فرض تسجيل الخروج؟",
  "ccd.agent.agentevent.AgentEvent_MediaSwith.success" : "تبديل الصوت/الفيديو بنجاح.",
  "ccd.innerhelp.message.inputExNumhelp" : "أدخل رقمًا للاستشارة",
  "ccd.agent.frontpage.callreason" : "سبب المكالمة",
  "ccd.agent.openeye.switchshare.fail" : "فشل تمكين وظيفة مشاركة سطح المكتب.",
  "ccd.agent.tips.success" : "النجاح",
  "ccd.agent.message.releaseNumberNoNumber" : "لا مكالمة من رقم الهاتف للافراج عنهم.",
  "ccd.agent.mediaswitch.audio" : "مكالمة صوتية",
  "ccd.agent.webrtc.label.button.no" : "لا",
  "ccd.agent.message.eventMethodErr" : "طريقة الحصول على حدث الموظف غير صحيحة.",
  "ccd.record.message.jumpBack" : "يحدث خطأ حالة أثناء اللف.",
  "ccd.agent.message.endMuteNoCall" : "لا توجد مكالمة عند إلغاء كتم الصوت.",
  "ccd.agent.message.AgentAlreadyLogout" : "قام الوكيل بتسجيل الخروج.",
  "ccd.agent.message.logOutStatusErr" : "فشل تسجيل الخروج. قد يكون لديك عمل معلق للتعامل معه.",
  "ccd.agent.agentevent.AgentEvent_Talking" : "قام الموظف بإدخال الحالة Talking.",
  "ccd.agent.message.connectHoldNoCall" : "لا توجد مكالمة للتعليق.",
  "ccd.agent.agentevent.feature.caller" : "رقم المتصل:",
  "ccd.agent.agentevent.AgentState_CancelRest_Success" : "قام الموظف بإلغاء الباقي بنجاح.",
  "ccd.transfer.label.threeParthTransfer" : "تحويل ثلاثي الأطراف",
  "ccd.agent.agentevent.type.assistant" : "مساعدة مكالمة",
  "ccd.agent.query.sip.fail" : "فشل الاستعلام عن بيانات SIP. قم بتسجيل الدخول كموظف مرة أخرى أو قم بتسجيل الدخول يدويًا إلى OpenEye.",
  "ccd.agent.pagination.total" : "المجموع:",
  "ccd.agentmonitor.span.listening" : "الاستماع",
  "ccd.agent.message.AgentIsEmpty" : "الحساب الحالي غير مرتبط بوكيل.",
  "ccd.agent.message.beginMuteNoCall" : "لا داعي للكتم.",
  "ccd.transfer.label.hangUpTransfer" : "نقل قطع الاتصال",
  "ccd.agent.agentevent.feature.called" : "الرقم المطلوب:",
  "ccd.agent.agentevent.calloutconnectfail" : "المشترك مشغول أو الرقم غير موجود.",
  "ccd.ccaction.title.shareapp" : "تحديد برنامج مشترك",
  "ccd.agent.title.consult" : "استشارة",
  "ccd.callmessage.whiteNumberExist" : "الرقم غير موجود في قائمة الثقة.",
  "ccd.agent.message.loginInvalidParam" : "رقم الهاتف غير صالح أو تم تسجيل الدخول بواسطة موظف آخر.",
  "ccd.agent.webrtc.label.offline" : "غير مسجل",
  "ccd.agent.agentevent.AgentEvent_Call_Out_Fail" : "فشل الموظف في بدء مكالمة خارجية.",
  "ccd.quality.message.stopListen" : "توقف استمع",
  "ccd.ccaction.message.repeateRest" : "الوكيل هو الراحة. لا تجبر على إظهار مشغول.",
  "ccd.agent.resetphonepass.input" : "كلمة المرور",
  "ccd.agent.videocall.adjustvideo" : "ضبط حجم الفيديو",
  "ccd.agent.button.signIn" : "تسجيل الدخول",
  "ccd.callmessage.chatOnlineWorkbench" : "منضدة الدردشة عبر الإنترنت",
  "ccd.agent.agentevent.AgentEvent_Call_Release" : "يقوم الموظف بإنهاء المكالمة",
  "ccd.agent.agentevent.AgentConf_SelfJoinResult": "وكيل ينضم إلى مؤتمر",
  "ccd.agent.openeye.autoanswer" : "يتم الرد على المكالمة تلقائيًا.",
  "ccd.agent.openeye.already.register" : "تم تسجيل رقم الهاتف.",
  "ccd.agent.videocall.noCall" : "لا يحدث خطأ في المكالمة عند ضبط وضع العرض لمكالمة فيديو ثلاثية الأطراف.",
  "ccd.transfer.label.transferType" : "نوع النقل",
  "ccd.agent.agentevent.AgentEvent_MediaSwith.failed" : "فشل تبديل الصوت/الفيديو.",
  "ccd.transfer.label.ivr" : "IVR",
  "ccd.agent.agentevent.AgentMediaEvent_JumpPlayDone" : "تم إعادة توجيه التسجيل بسرعة أو ترجيعه بنجاح.",
  "ccd.agent.openeye.videodisplaymode.fail" : "فشل تعيين وضع الاقتصاص الخاص بنافذة الفيديو.",
  "ccd.cccbar.label.inputNumber" : "أدخل رقمًا.",
  "ccd.agent.message.insultNoCall" : "لا داعي للاستشارة.",
  "ccd.agent.button.threeParty" : "ثلاثة أحزاب",
  "ccd.message.title.audioIncomingCallNotify" : "إشعار مكالمة الصوت والفيديو الواردة",
  "ccd.agent.videomode.maximizeAgent" : "تكبير الموظف",
  "ccd.callinner.label.callinner" : "مكالمة داخلية",
  "ccd.agent.message.transferNoCall" : "لا توجد مكالمة للتحويل.",
  "ccd.agent.agentevent.AgentEvent_Conference" : "نجحت المكالمة الثلاثية الأطراف.",
  "ccd.agent.agentevent.warning" : "تحذير",
  "ccd.agent.tips.info" : "نصائح",
  "ccd.agent.openeye.sipconfig.success" : "تم تكوين SIP بنجاح.",
  "ccd.agent.message.videoLimit" : "موارد موظف الفيديو غير كافية، ويفشل الموظف في تسجيل الدخول إلى خادم وسائط الفيديو.",
  "ccm.agent.message.selectIvr" : "حدد تدفق IVR.",
  "ccd.cccbar.label.yourself" : "نفسي",
  "ccd.transfer.label.agent" : "الوكيل",
  "ccd.agent.videocall.typeError" : "يكون نوع الاتصال غير صحيح عند ضبط وضع العرض لمكالمة فيديو ثلاثية الأطراف.",
  "ccd.quality.message.intercept" : "اعتراض",
  "ccd.agent.message.AgentIsSupend" : "تم تجميد الموظف الحالي.",
  "ccd.agent.message.mediaSwitchSuccess" : "نجاح تبديل الصوت/الفيديو.",
  "ccd.agent.message.noAgent" : "لا وكيل على واجب.",
  "ccd.agent.webrtc.label.local.name" : "الوكيل",
  "ccd.quality.message.whisper" : "الهمس",
  "ccd.ccaction.message.hour" : "الساعات",
  "ccd.outgoing.out.workbench" : "منضدة للخارج",
  "ccd.ccaction.message.registerPhoneFailed" : "فشل تسجيل WebRTC. وظائف المساعدة الداخلية غير متوفرة حاليًا.",
  "ccd.agent.message.holdNoCall" : "لا توجد مكالمة للتعليق.",
  "ccd.ccaction.message.logoutfailed" : "فشل تسجيل الخروج. يرجى التحقق من حالة الوكيل.",
  "ccd.agent.webrtc.label.busy.tip" : "تشامادا",
  "ccd.agent.message.wait" : "تتم معالجة المكالمة. يُرجى إعادة المحاولة لاحقًا.",
  "ccd.agent.message.reset": "عدد كبير جدًا من حالات فشل معالجة المكالمات، محاولة مزامنة حالة الموظف",
  "ccd.agent.agentevent.AgentEvent_Preview_CallOut" : "نجحت معاينة المكالمة الصادرة.",
  "ccd.agent.webrtc.label.videopop" : "نافذة الفيديو",
  "ccd.transfer.label.queueSize" : "انتظر في الخط",
  "ccd.transfer.label.queueSize.ccbar" : "انتظر في الخط",
  "ccd.quality.message.forcerest" : "قوة الخمول",
  "ccd.agent.warning.notification.setting" : "الإعدادات",
  "ccd.agent.chat.channle.choose" : "تحديد القناة",
  "ccd.agent.agentevent.AgentEvent_Auto_Answer" : "الرد التلقائي للوكيل",
  "ccd.agent.agentevent.AgentState_SetWork_Success" : "يدخل الموظف حالة العمل.",
  "ccd.record.message.pauseRecord" : "يحدث خطأ حالة عند إيقاف التسجيل مؤقتًا.",
  "ccd.quality.message.insert" : "إدراج",
  "ccd.ccaction.message.recording" : "يمكن تشغيل الصوت فقط عندما يكون الموظف في حالة خامل. يُرجى تغيير حالة الموظف.",
  "ccd.callout.message.numberFormat" : "يمكن أن يحتوي رقم الهاتف على الأرقام فقط وعلامة الرقم (#), والعلامة النجمية (*), ويحتوي على 24 حرفًا بحد أقصى.",
  "ccd.agent.agentevent.AgentEvent_Hold.desc" : "الموظف في وضع الانتظار مسبقاً",
  "ccd.cccbar.label.cccbar" : "شريط الاتصال",
  "ccd.agent.agentevent.AgentMediaEvent_Play" : "بدء تشغيل التسجيل.",
  "ccd.agent.webrtc.label.small" : "صغيرة",
  "ccd.agent.openeye.startshare.fail" : "فشل بدء المشاركة.",
  "ccd.agent.openeye.poolsipconfig.success" : "تم تكوين POOL بنجاح.",
  "ccd.agent.message.needLogin" : "قم بتسجيل الدخول كوكيل وحاول مرة أخرى.",
  "ccd.transfer.label.skillName" : "قائمة انتظار المهارات",
  "ccd.agent.reset.success" : "نجح حجز الراحة.",
  "ccd.ccaction.message.inputIvrName" : "اسم تدفق IVR فارغ. الرجاء إدخال اسم تدفق IVR.",
  "ccd.agent.login.multimedia.failinfo" : ". ونتيجة لذلك، فإن المكالمات الصوتية ومكالمة الفيديو ووظائف المساعدة الداخلية غير متوفرة.",
  "ccd.agent.message.answerNoCall" : "لا مكالمة للرد.",
  "ccd.agent.agentevent.AgentOther_PhoneUnknow" : "حالة هاتف الموظف غير معروفة.",
  "ccd.agent.button.callOut" : "مكالمة خارجية",
  "ccd.transfer.message.NoAgentInSkill" : "لا يوجد موظف متصل في قائمة انتظار المهارات الحالية.",
  "ccd.ccaction.message.confJoinErr" : "لم يتم تعليق أي مكالمة. لا يُسمح بإجراء مكالمة ثلاثية الأطراف.",
  "ccd.ccaction.message.registerPhone" : "تسجيل الهاتف.",
  "ccd.agent.openeye.initsuccess" : "تمت تهيئة OpenEye بنجاح.",
  "ccd.agent.videocall.stateError" : "تكون حالة المكالمة غير صحيحة عند ضبط وضع العرض لمكالمة فيديو ثلاثية الأطراف.",
  "ccd.ccaction.message.unHold" : "لا توجد مكالمة للتعليق في الحالة الحالية.",
  "ccd.quality.message.resourceUnable" : "يحدث استثناء أثناء الفحص. تحقق من السجلات ذات الصلة.",
  "ccd.agent.webrtc.label.sotpsendvideo" : "إيقاف إرسال الفيديو",
  "ccd.innerhelp.label.innerHelpToAgent" : "طلب استشارة من الوكيل",
  "ccd.agent.agentevent.AgentEvent_Inside_Call_Fail" : "فشل الموظف في بدء مكالمة داخلية.",
  "ccd.agent.agentevent.AgentEvent_ReturnFromIvr" : "تم إرجاع الموظف من IVR بنجاح.",
  "ccd.agent.message.restInvalid" : "لا تتوافق المعلمة أو العنوان الذي تم نقله عبر عنوان URL مع التعريف.",
  "ccd.agent.agentevent.AgentEvent_Consult_Fail" : "فشل استشارات الوكيل",
  "ccd.agent.message.calloutagent.restskill" : "لا يرد موظف الاستدعاء على المكالمات الواردة ولا يمكنه إعادة تعيين قوائم انتظار المهارات.",
  "ccd.agent.button.hangUp" : "شنق",
  "ccd.label.search" : "البحث",
  "ccd.transfer.button.cancel" : "إلغاء",
  "ccd.quality.message.cancelwhisper" : "وقف الهمس",
  "ccd.title.call.three" : "الاتصال ثلاثي الاتجاه",
  "ccd.ccaction.message.recordFail" : "لا يقوم الموظف بتسجيل الدخول، ويفشل تشغيل الصوت.",
  "ccd.ccaction.message.isForceBusy" : "تم إظهار الموظف مشغول.لا تقم بإظهاره مشغول بشكل متكرر.",
  "ccd.agent.agentevent.AgentEvent_No_Answer.number" : "رقم حفلة قطع المكالمة:",
  "ccd.record.message.stopPlay" : "يحدث خطأ حالة عند توقف التشغيل.",
  "ccd.agent.callout.notrest" : "قم بإلغاء الفاصل ثم قم بإجراء مكالمة صادرة.",
  "ccd.agent.agentevent.info" : "نصائح",
  "ccd.agent.agentevent.failed" : "فشل",
  "ccd.agent.void.call" : "مكالمة صوتية صادرة",
  "ccd.agent.button.screenShare" : "مشاركة سطح المكتب",
  "ccd.record.message.startPlay" : "يحدث خطأ حالة عند بدء التشغيل.",
  "ccd.agent.label.rest" : "الراحة",
  "ccd.agent.message.agentNotLogin" : "لا يقوم الموظف بتسجيل الدخول.",
  "ccd.ccaction.message.inputWorkNo" : "معرف الموظف فارغ. يرجى إدخال معرف الموظف.",
  "ccd.agent.login.failinfo" : ". ونتيجة لذلك، لا تتوفر وظيفة المساعدة الداخلية.",
  "ccd.cccbar.message.calltip" : "مرحبا، مكالمة جديدة قادمة!",
  "ccd.quality.message.inspectInspectorErr" : "لا يمكن أن يكون الموظف الذي تم فحصه مراقباً.",
  "ccd.agent.agentevent.otherPhone" : "رقم الهاتف/معرّف العمل لطرف الرنين:",
  "ccd.quality.message.qualityTitle" : "فحص الجودة",
  "ccd.transfer.label.transferToSkill" : "التحويل إلى قائمة انتظار المهارات",
  "ccd.agent.openeye.videowindowparam.fail" : "فشل في تهيئة معلمات الفيديو. ونتيجة لذلك، تكون وظيفة مكالمة الفيديو غير طبيعية.",
  "ccd.quality.message.searchWorkno" : "يرجى إدخال معرف الموظف للبحث.",
  "ccd.agent.webrtc.label.connect" : "التسجيل",
  "ccd.agent.agentevent.callId" : "معرف المكالمة:",
  "ccd.agent.label.recordPlay" : "تشغيل الصوت",
  "ccd.agent.flash.message" : "رسالة",
  "ccd.quality.message.statusErr" : "الحالة غير طبيعية أثناء فحص الجودة.",
  "ccd.agent.openeye.deamonready.fail" : "فشل بدء تشغيل OpenEye. قم بتسجيل الدخول يدويًا إلى OpenEye. لا يمكن استخدام وظيفة الرد التلقائي.",
  "ccd.cccbar.label.callcenter" : "مركز الاتصال",
  "ccd.agent.agentevent.mediaAblity.voice" : "الصوت",
  "ccd.agent.button.ok" : "حسناً",
  "ccd.agent.agentevent.AgentState_Ready" : "تم تعيين الموظف خامل بنجاح.",
  "ccd.agent.agentevent.AgentMediaEvent_PausePlayDone" : "تم إيقاف تشغيل التسجيل مؤقتًا بنجاح.",
  "ccd.ccaction.message.rest" : "الراحة",
  "ccd.agent.tips.welcome" : "مرحباً بعودتك، يوماً سعيداً!",
  "ccd.agent.videocall.paramError" : "خطأ في المعلمة عند ضبط وضع العرض لمكالمة فيديو ثلاثية الأطراف.",
  "ccd.agent.message.misconnection" : "فشل استدعاء واجهة استمرار الجلسة.",
  "ccd.quality.message.interceptErr" : "الموظف المطلوب التحقق منه ليس في مكالمة ولا يمكن اعتراضه.",
  "ccd.agent.message.loginInvalidPara" : "فشل تسجيل الدخول. معلمة تسجيل الدخول فارغة أو غير صالحة.",
  "ccd.agent.message.callOutPhoneErr" : "رقم المكالمة الصادرة غير صالح.",
  "ccd.agent.status.mute" : "كتم الصوت",
  "ccd.quality.message.noAuth" : "لا يوجد استثناء في الإذن أثناء فحص الجودة.",
  "ccd.transfer.button.sure" : "حسناً",
  "ccd.agent.agentevent.callType" : "نوع المكالمة:",
  "ccd.agent.message.holdCallStatusErr" : "يحدث خطأ في حالة المكالمة عند تعليق المكالمة.",
  "ccd.agent.button.queryStatus" : "حالة الاستعلام",
  "ccd.agent.message.selectTeams" : "اختر حساب فريق.",
  "ccd.agent.agentevent.AgentState_CancelNotReady_Success" : "قام الموظف بإلغاء مشغول بنجاح.",
  "ccd.agentMonitor.intercept.noAudio" : "لا يمكن اعتراض أي مكالمة صوتية أو مكالمة فيديو.",
  "ccd.agent.tips.error" : "خطأ",
  "ccd.label.org.all" : "جميع المنظمات التي يمكن الوصول إليها",
  "ccd.quality.message.stopInsert" : "إيقاف إدراج",
  "ccd.agent.message.loginInvalidPhone" : "رقم هاتف غير صالح.",
  "ccd.agent.agentevent.AgentEvent_Release_Preview" : "تم تحرير مكالمة المعاينة بنجاح.",
  "ccd.agent.videomode.maximizeUser" : "تكبير المستخدم",
  "ccd.agent.button.signOut" : "تسجيل الخروج",
  "ccd.agent.callrelease.end" : "انتهت المكالمة",
  "ccd.quality.message.switch" : "التبديل",
  "ccd.agent.tips.restTooLong" : "بقية مرات خارج.",
  "ccd.agent.status.hold" : "انتظر",
  "ccd.agent.label.idle" : "الخمول",
  "ccd.agent.mediaswitch.switchaudiofailed" : "بالفعل في مكالمة صوتية.",
  "ccd.agent.webrtc.label.online" : "مسجل",
  "ccd.agent.button.offlineSignOut" : "الخروج من الموبايل",
  "ccd.agent.webrtc.label.sippwd.placeholder" : "أدخل كلمة مرور الهاتف.",
  "ccd.agent.openeye.autoanswer.fail" : "فشل الرد على المكالمة تلقائيًا.",
  "ccd.agent.message.getHoldNoHoldCall" : "لا مكالمة لإلغاء الحجز.",
  "ccd.agent.agentevent.AgentEvent_No_Answer" : "لا يرد الموظف على المكالمة لفترة طويلة.",
  "ccd.agent.agentevent.AgentMediaEvent_ResumePlayDone" : "تم استئناف تشغيل التسجيل بنجاح.",
  "ccd.agent.message.logOutInvalidParam" : "فشل تسجيل الدخول. المعلمة غير صالحة.",
  "ccd.transfer.button.refresh" : "تحديث",
  "ccd.ccaction.message.repeateBusy" : "الوكيل مشغول الآن. لا تقم بتعيين مشغول بشكل متكرر.",
  "ccd.agent.openeye.share" : "من فضلك اختر...",
  "ccd.agent.button.notscreenShare" : "إلغاء مشاركة سطح المكتب",
  "ccd.agent.chat.mutimedia.call" : "مكالمة صادرة متعددة الوسائط",
  "ccd.agent.agentevent.AgentOther_PhoneAlerting" : "يقوم الموظف بالرنين",
  "ccd.ccaction.message.loginagent" : "تسجيل الدخول كوكيل.",
  "ccd.record.message.jumpForw" : "يحدث خطأ حالة أثناء إعادة التوجيه.",
  "ccd.agent.button.answer" : "الجواب",
  "ccd.agent.agentevent.AgentState_SetRest_Success" : "تم استراحة الموظف بنجاح.",
  "ccd.agent.message.checkAgentStatus" : "قم بإجراء هذه العملية عندما يكون المراقب خاملاً.",
  "ccd.agent.openeye.register" : "تم تسجيل رقم الهاتف بنجاح.",
  "ccd.agent.webrtc.label.button.yes" : "نعم",
  "ccd.agent.agentevent.AgentOther_All_Agent_Busy" : "جميع الوكلاء مشغولون.",
  "ccd.agent.resetphonepass.inputcur" : "كلمة مرور الحساب الحالي",
  "ccd.agent.webrtc.label.buildfailed" : "فشل إعداد الاتصال",
  "ccd.agent.message.repeatPhonenum" : "تم استخدام رقم الهاتف الذي استخدمه الموظف لتسجيل الدخول من قبل موظف آخر لتسجيل الدخول. تحقق من التكوين.",
  "ccd.agent.agentevent.AgentEvent_Preview" : "تلقى الموظف طلب المعاينة بنجاح.",
  "ccd.cccbar.label.voice" : "الصوت",
  "ccd.transfer.label.loggedOnAgents" : "الوكيل المتوفر",
  "ccd.transfer.label.loggedOnAgents.ccbar" : "الوكيل المتوفر",
  "ccd.agent.openeye.setshareapp.fail" : "فشل تعيين مشاركة الشاشة.",
  "ccd.transfer.label.transferToExNumber" : "التحويل إلى رقم خارجي",
  "ccd.agent.button.twostage" : "لوحة الاتصال الهاتفي",
  "ccd.agent.webrtc.label.passwordvalidator" : "طول الحرف الذي تم إدخاله يتجاوز نطاق القيمة. يجب أن يكون الطول أقل من أو يساوي 50 حرفًا.",
  "ccd.agent.message.hangupnocall" : "لا يمكن إنهاء المكالمة. إذا كانت المكالمة قيد الانتظار، يُرجى إلغاء الانتظار أولاً.",
  "ccd.agent.button.mediaswitch" : "تبديل الصوت/الفيديو",
  "ccd.agentmonitor.span.insert" : "جارٍ إدراج",
  "ccd.agent.warning.waserror" : "لا يمكن الوصول إلى خطأ في مورد WAS أو يحدث خطأ داخلي.",
  "ccd.agent.webrtc.label.connetfailed" : "فشل",
  "ccd.agent.webrtc.label.remotevideo" : "فيديو عن بعد",
  "ccd.agent.message.agentNoConfSkill" : "لم يتم تكوين الموظف باستخدام قائمة انتظار مهارة.",
  "ccd.agent.webrtc.label.busy.info" : "تلميحات",
  "ccd.transfer.message.inputTeamsUser" : "أدخل حساب الفريق.",
  "ccd.agent.openeye.register.fail" : "تم تسجيل رقم الهاتف بالفعل، أو لأسباب أخرى.",
  "ccd.agent.login.fail" : "فشل التسجيل.",
  "ccd.agent.agentevent.AgentMediaEvent_StopPlayDone" : "تم إيقاف تشغيل التسجيل.",
  "ccd.agent.agentevent.AgentEvent_Preview_CallOut.controlid" : "معاينة معرّف مجموعة التحكم في المكالمات الصادرة:",
  "ccd.agent.status.threeParty" : "ثلاثة أحزاب",
  "ccd.agent.chat.start" : "بدء جلسة",
  "ccd.agent.openeye.videolayoutmode.success" : "نجح في إعداد وضع ترتيب الصور لنافذة الفيديو.",
  "ccd.agentMonitor.label.selectAgentOne" : "الرجاء إنهاء الموظف الذي تتم معالجته وتحديد عملية موظف أخرى.",
  "ccd.agent.webrtc.label.missmic" : "الميكروفون غير متوفر.",
  "ccd.agent.tips.confSuccess" : "التكوين ناجح.",
  "ccd.agent.agentevent.AgentOther_PhoneRelease" : "أغلق الوكيل",
  "ccd.agent.agentevent.AgentEvent_Preview_CallOut.dialeddigits" : "معاينة الرقم المطلوب الصادر:",
  "ccd.agent.title.warning" : "إنذار",
  "ccd.agent.status.callout" : "مكالمة صادرة",
  "ccd.agent.agentevent.mediaAblity" : "نوع الوسائط:",
  "ccd.agent.message.AgentIsProhibited" : "تم حظر الإيجار.",
  "ccd.cccbar.label.backspace" : "مساحة للخلف",
  "ccd.record.message.resumePlay" : "يحدث خطأ حالة عند استمرار التشغيل.",
  "ccd.ccaction.message.holdError" : "لا يمكن تعليق المكالمة الحالية.",
  "ccd.agent.videomode.triangular" : "الثلاثي",
  "ccd.message.title.mediaNewMsgNotify" : "إعلام رسالة وسائط متعددة جديدة",
  "ccd.agent.button.offlineSignIn" : "شيفت موبايل",
  "ccd.quality.message.qualityAgentTitle" : "مراقب الموظف",
  "ccd.agent.agentevent.mediaAblity.video" : "فيديو",
  "ccd.record.message.pausePlay" : "يحدث خطأ حالة عند إيقاف التشغيل مؤقتًا.",
  "ccd.cccbar.label.send" : "إرسال",
  "ccd.agent.message.endMuteStateErr" : "يحدث خطأ في حالة المكالمة عند إلغاء كتم الصوت.",
  "ccd.agent.message.phone" : "الرقم الذي تم الاتصال به",
  "ccd.agent.tips.maxCountQuery.info" : "حجم البيانات الحالي كبير جدًا. يعرض النظام أحدث {0} سجلات بيانات فقط.",
  "ccd.transfer.label.transferTypeColon" : "نقل نوع:",
  "ccd.transfer.label.transferTypeColon.ccbar" : "نقل نوع:",
  "ccd.quality.message.back" : "للخلف",
  "ccd.agent.message.withoutskill" : "قائمة انتظار المهارات مفقودة.",
  "ccd.transfer.label.talkingTransfer" : "تحويل المحادثة",
  "ccd.agent.message.invalidWasURL" : "مسار ارتباط WAS الذي تم تكوينه على الخادم غير صحيح.",
  "ccd.record.message.resumeRecord" : "يحدث خطأ حالة عند استمرار التسجيل.",
  "ccd.agent.agentevent.AgentOther_ShutdownService" : "قام الموظف بتسجيل الخروج بنجاح.",
  "ccd.agent.button.rest" : "الراحة",
  "ccd.transfer.label.successTransfer" : "نجاح التحويل",
  "ccd.agent.message.accoutnLocked" : "تم قفل الحساب. تسجيل الدخول في وقت لاحق.",
  "ccd.agent.agentevent.AgentEvent_Conference.partner" : "رقم المتصل:",
  "ccd.quality.message.forcebusy" : "القوة مشغول",
  "ccd.agent.message.threePartyNoCall" : "لا توجد مكالمة لبدء مكالمة ثلاثية الأطراف.",
  "ccd.transfer.message.inputIvrName" : "أدخل IVR للنقل.",
  "ccd.agent.agentevent.AgentEvent_Customer_Release" : "يقوم العميل بإنهاء المكالمة.",
  "ccd.transfer.label.skill" : "قائمة انتظار المهارات",
  "ccd.agent.agentevent.AgentEvent_Agent_Occupyed" : "تم بالفعل استباق الموظف",
  "ccd.agent.videocall.displayWarn" : "يمكن ضبط حجم نافذة الفيديو فقط عندما تقوم الأطراف الثلاثة بتشغيل مقاطع الفيديو.",
  "ccd.agent.label.reply" : "الرد",
  "ccd.agent.label.skillGroupOrSkill" : "المجموعة المختصة/قائمة انتظار المهارات",
  "ccd.agent.webrtc.label.talk" : "الاتصال",
  "ccd.message.title.mediaIncomingCallNotify" : "إعلام بالمكالمة الواردة عبر الوسائط المتعددة",
  "ccd.agent.agentevent.AgentEvent_Ringing" : "مكالمة الموظف الواردة",
  "ccd.agent.message.mediaSwitchFailed" : "فشل تبديل الصوت/الفيديو.",
  "ccd.agent.message.destinationAgentFailed" : "فشل شغل موظف الوجهة.",
  "ccd.agent.openeye.videolayoutmode.fail" : "فشل تعيين وضع ترتيب الصور لنافذة الفيديو.",
  "ccd.agent.openeye.getapplist.fail" : "فشل الحصول على قائمة التطبيقات المفتوحة.",
  "ccd.agent.warning.sharescreen" : "انتبه للخصوصية الشخصية عند مشاركة سطح المكتب",
  "ccd.agent.agentevent.mediaAblity.text" : "نص",
  "ccd.agent.webrtc.label.logoutregistered" : "سواء لتسجيل الخروج.",
  "ccd.agent.agentevent.feature.callout" : "مكالمة صادرة شائعة",
  "ccd.ccaction.message.retMessEnd" : ".",
  "ccd.agent.warning.notification.cancel" : "إلغاء",
  "ccd.agent.openeye.poolsipconfig.fail" : "فشل تكوين POOL. قم بتسجيل الدخول كموظف مرة أخرى أو قم بتسجيل الدخول يدويًا إلى OpenEye.",
  "ccd.agent.webrtc.label.busy.confirm" : "تأكيد",
  "ccd.cccbar.label.nocall" : "لا مكالمة.",
  "ccd.transfer.message.refreshTab" : "لا تقم بتحديث الصفحة بشكل متكرر.",
  "ccd.callmessage.workbench" : "منضدة الصوت والفيديو",
  "ccd.agent.webrtc.label.accept" : "الجواب",
  "ccd.innerhelp.label.helpType" : "نوع الاستشارة",
  "ccd.agent.message.releaseNumberNoCall" : "لا توجد مكالمة لإصدار رقم هاتف محدد.",
  "ccd.agent.agentevent.success" : "النجاح",
  "ccd.agent.agentevent.AgentOther_InService" : "قام الموظف بتسجيل الدخول بنجاح.",
  "ccd.agent.agentevent.mediaAblity.voiceandvideo" : "الصوت والفيديو",
  "ccd.ccaction.message.minutes" : "دقائق",
  "ccd.agent.status.innerCall" : "مكالمة داخلية",
  "ccd.agent.button.getHold" : "إلغاء الحجز",
  "ccd.agentmonitor.span.whisper" : "الهمس",
  "ccd.agent.tips.confFail" : "فشل التكوين.",
  "ccd.agent.label.busy" : "مشغول",
  "ccd.agent.status.occupy" : "المحتلة",
  "ccd.cccbar.label.video" : "فيديو",
  "ccd.agent.webrtc.label.sippwd" : "كلمة مرور الهاتف الناعم",
  "ccd.cccbar.label.cancel" : "إلغاء",
  "ccd.agentMonitor.forceRelease.noAudio" : "لا يمكن إصدار مكالمة صوتية أو فيديو.",
  "ccd.login.message.logoutReason.sessionInvalid" : "جلسة العمل غير صالحة. تسجيل الدخول مرة أخرى من فضلك.",
  "ccd.agent.videomode.maximizeThreeparty" : "تعظيم ثلاثية الأطراف",
  "ccd.agent.button.mute" : "كتم الصوت",
  "ccd.agent.agentevent.AgentEvent_Connect_Fail" : "فشل الاتصال",
  "ccd.quality.message.forceexit" : "خروج بالقوة",
  "ccd.agent.webrtc.label.large" : "كبيرة",
  "ccd.innerhelp.label.help" : "استشارة",
  "ccd.agent.openeye.videodisplaymode.success" : "نجح إعداد وضع الاقتصاص لنافذة الفيديو.",
  "ccd.agent.webrtc.label.remote.name" : "الطرف الآخر",
  "ccd.agent.previewcall.invalParam" : "معلمات معاينة المكالمة الصادرة غير صحيحة.",
  "ccd.agent.webrtc.label.hidelocalvideo" : "إخفاء الفيديو المحلي",
  "ccd.agent.resetphonepass.inputagain" : "تأكيد كلمة المرور",
  "ccd.agent.tips.media.agentType.no.support.callout" : "يتعذر على وكلاء الوسائط المتعددة إجراء مكالمات صادرة",
  "ccd.agent.message.AgentInnerError" : "فشلت العملية. السبب المحتمل هو حدوث خطأ داخلي، أو عدم توفر موارد، أو أن العامل ليس خاملاً.",
  "ccd.ccaction.message.confJoinFail" : "لا يقوم الموظف بتسجيل الدخول، وتفشل المكالمة ثلاثية الأطراف.",
  "ccd.innerhelp.label.innerHelpToSkill" : "طلب استشارة من قائمة انتظار المهارات",
  "ccd.agent.webrtc.label.reject" : "رفض",
  "ccd.agent.message.monitorSelf" : "لا يمكن أن يكون الموظف الذي تم فحصه والمراقب متطابقين.",
  "ccd.agent.message.getHoldCallStatusErr" : "يحدث خطأ في حالة المكالمة عند إلغاء تعليق المكالمة.",
  "ccd.agent.message.userOrPasswdInvalid" : "فشل تسجيل الدخول. الحساب أو كلمة المرور غير صحيحة.",
  "ccd.agent.message.InvalidNumber" : "رقم غير صالح.",
  "ccd.record.message.startRecord" : "يحدث خطأ حالة عند بدء التسجيل.",
  "ccd.agent.message.releaseNoCall" : "لا مكالمة لتعليق.",
  "ccd.quality.message.noAgentInfo" : "لم يتم العثور على معلومات الموظف أثناء مراقبة فحص الجودة.",
  "ccd.agent.agentevent.AgentState_CancelWork_Success" : "قام الموظف بإنهاء حالة العمل بنجاح.",
  "ccd.agent.button.sayFree" : "تعيين خامل",
  "ccd.agent.message.restNoRight" : "ليس لدى الموظف إذن باستدعاء الواجهة. ربما تم إجبار الوكيل على تسجيل الخروج.",
  "ccd.transfer.message.NoIvrOnline" : "لا يتوفر أي تدفق IVR.",
  "ccd.ccaction.message.error" : "خطأ",
  "ccd.agent.agentevent.type.main" : "المكالمة الرئيسية",
  "ccd.agent.message.threePartyNoHoldCall" : "لم يتم تعليق أي مكالمة لبدء مكالمة ثلاثية الأطراف.",
  "ccd.agent.button.hold" : "انتظر",
  "ccd.agent.button.sayBusy" : "تعيين مشغول",
  "ccd.transfer.label.releaseTransfer" : "نقل الافراج",
  "ccd.agent.msg.notification.allowed" : "طلب موقع AICC يسمح للمتصفح بعرض الإشعارات!",
  "ccd.quality.message.noProvider" : "يحدث خطأ NOPROVIDER أثناء فحص الجودة.",
  "ccdesktop.login.wertc.failed" : "فشل تسجيل Webrtc للهاتف اللين",
  "ccd.agent.webrtc.label.medium" : "متوسطة",
  "ccd.ccaction.message.retCode" : "خطأ إلغاء العمل عند تعيين مشغول. رمز الخطأ:",
  "ccd.agent.label.adjust" : "الالتفاف",
  "ccd.ccaction.message.seconds" : "ثواني",
  "ccd.agent.agentevent.AgentState_Force_SetNotReady" : "تم تعيين الموظف مشغول بنجاح.",
  "ccd.ccaction.message.search.restCauseDesc" : "أدخل وصف سبب الراحة.",
  "ccd.agent.openeye.deamonready" : "تم بدء تشغيل OpenEye.",
  "ccd.agent.button.close" : "إلغاء",
  "ccd.agent.webrtc.label.videosize" : "حجم الفيديو",
  "ccd.agent.mediaswitch.unsupportedagenttype" : "الموظف الحالي لا يدعم مكالمات الفيديو.",
  "ccd.agent.mediaswitch.video" : "مكالمة فيديو",
  "ccd.agent.agentevent.AgentEvent_Hold" : "تم تعليق المكالمة بنجاح.",
  "ccd.contact.message.timerangeerror" : "لا يمكن أن يتجاوز نطاق الوقت {0} أيام.",
  "ccd.ccaction.message.errorRetCode" : "خطأ! رمز الخطأ:",
  "ccd.innerhelp.label.threePartyHelp" : "استشارات ثلاثية الأطراف",
  "ccd.agent.webrtc.label.unsupportbrower" : "المتصفح لا يدعم هذه الوظيفة.",
  "ccd.agent.flash.calling" : "الاتصال",
  "ccd.cccbar.message.syserrormessage" : "خطأ في النظام.",
  "ccd.agent.openeye.deregister" : "تم إلغاء تنشيط رقم الهاتف بنجاح.",
  "ccd.agent.msg.notification.permission.denied" : "يرفض المستخدم التخويل ولا يمكنه عرض الإشعار. يرجى تعديل الإعدادات في المتصفح.",
  "ccd.transfer.label.callTransfer" : "تحويل المكالمة",
  "ccd.agent.openeye.switchshare.success" : "تم تمكين وظيفة مشاركة سطح المكتب بنجاح.",
  "ccd.agent.openeye.videowindowparam" : "تمت تهيئة معلمات الفيديو بنجاح.",
  "ccd.agent.agentevent.AgentOther_PhoneOffhook" : "ذهب وكيل خارج هوك",
  "ccd.agentMonitor.label.switchlistenorinsert" : "في حالة الفحص المسبق، لا يمكن للموظف الذي تم الهمس له النقر فوق الزر استماع أو إدراج.",
  "ccd.cccbar.message.pleaseAnswer" : "أجب على المكالمة.",
  "ccd.transfer.label.statusUnknow" : "غير معروف",
  "ccd.agent.openeye.deamonready.failinfo" : "فشل بدء تشغيل OpenEye.",
  "ccd.agent.mediaswitch.switchvideofailed" : "بالفعل في مكالمة فيديو.",
  "ccd.transfer.label.transferToAgent" : "التحويل إلى الوكيل",
  "ccd.agent.agentevent.AgentEvent_Customer_Alerting" : "الهاتف يرن.",
  "ccd.agent.status.innerTwo" : "استشارة",
  "ccd.ccaction.message.retMessage" : ". رسالة خطأ:",
  "ccd.agent.message.setStatErr" : "فشل تعيين حالة الموظف.",
  "ccd.media.tips.new.online.customer" : "لديك عميل جديد عبر الإنترنت",
  "ccd.agent.status.talk" : "التحدث",
  "ccd.ccaction.message.abilityError" : "لا يمكنك كتم صوت المستخدم في وظيفة الوسائط الحالية.",
  "ccd.agent.status.askAnswer" : "انتظر الرد",
  "ccd.cccbar.label.empty" : "مسح الكل",
  "ccd.agent.agentevent.feature.callin" : "مكالمة واردة شائعة",
  "ccd.ccaction.message.statusError" : "لا توجد مكالمة لكتم الصوت في الحالة الحالية.",
  "ccd.agent.button.restSkillQueue" : "إعادة تعيين المهارات",
  "ccd.contact.message.timeerror" : "يجب أن يكون وقت البدء قبل وقت الانتهاء.",
  "ccd.contact.message.timeisempty" : "لا يمكن أن يكون نطاق الوقت فارغًا.",
  "ccd.quality.message.listen" : "الاستماع",
  "ccd.transfer.label.externalNumber" : "الرقم الخارجي",
  "ccd.agent.agentevent.AgentState_Work" : "يدخل الموظف في حالة التفاف.",
  "ccd.agent.message.noHoldCall" : "لم يتم تعليق المكالمة.",
  "ccd.agent.button.cancelRest" : "إلغاء الراحة",
  "ccd.innerhelp.label.twoPartyHelp" : "استشارة الحزبين",
  "ccd.cccbar.label.callout" : "مكالمة",
  "ccd.agent.openeye.stopshare.fail" : "فشل إيقاف المشاركة.",
  "ccd.agent.message.qc.restskill" : "لا يمكن إعادة تعيين قائمة انتظار المهارة إذا لم يرد المراقب على مكالمة واردة.",
  "ccd.agent.button.transfer" : "نقل",
  "ccd.agent.status.innerThree" : "ثلاثة أحزاب",
  "ccd.agent.button.innerHelp" : "استشارة",
  "ccd.skill.selectskill" : "حدد قائمة انتظار مهارة واحدة على الأقل.",
  "ccd.agent.message.beginMuteStateErr" : "يحدث خطأ في حالة المكالمة عند كتم صوتك.",
  "ccd.transfer.placeholder.agentName" : "أدخل اسم الموظف",
  "ccd.agent.label.offline" : "غير متصل",
  "ccd.agent.msg.queryMsg.specialListQuery" : "تذكير خاص بالقائمة",
  "ccd.cccbar.label.total" : "المجموع:",
  "ccd.agent.label.talk" : "التحدث",
  "ccd.ccaction.message.resignin" : "كلمة مرور المقعد غير صالحة، يُرجى تسجيل الدخول مرة أخرى!",
  "ccd.agent.webrtc.label.changeVoice" : "نقل إلى صوت",
  "ccd.agent.webrtc.label.localvideo" : "فيديو محلي",
  "ccd.agent.status.help" : "استشارة",
  "ccd.ccaction.message.nosupport": "هذه العملية غير معتمدة على صفحة ويب. استخدم العين المفتوحة لكتم الصوت",
  "ccd.ccbar.before.signin.tips":"قبل استخدام وظيفة الاتصال، انقر لتسجيل الدخول.",
  "ccd.ccbar.calling.number":"الرقم المتصل",
  "ccd.ccbar.mediaSwitch.failed":"فشل تبديل الوسائط",
  "ccd.ccbar.mediaSwitch.notsupport":"لا يُسمح بتبديل الصوت والفيديو للمكالمات الداخلية في وضع الفيديو على MCU",
  "ccd.ccbar.webrtc.network.signal.pool":"إشارة الشبكة الحالية ضعيفة، مما قد يؤثر على المكالمة.",
  "ccd.agentMonitor.label.switchwhisper": "لا يمكنك النقر فوق Whisper لموظف في حالة الاستماع المسبق أو لكل إدراج.",
  'ccd.agent.webrtc.register.tip':'تسجيل الهاتف المحمول',
  "ccd.webrtc.message.bad":"سوء",
  "ccd.webrtc.message.poor":"الفقراء",
  "ccd.webrtc.message.good":"جيد",
  "ccd.webrtc.message.recive":"الاستلام",
  "ccd.webrtc.message.Packetloss":"فقد الحزمة",
  "ccd.webrtc.message.delay":"تأخير",
  "ccd.webrtc.message.jitter":"غضب",
  "ccd.webrtc.message.send":"إرسال",
  "ccd.webrtc.message.signal":"إشارة",
  'ccd.webrtc.message.medium':'متوسط',
  'ccd.webrtc.login.success':'تم تسجيل دخول الهاتف بنجاح',
  'ccd.webrtc.login.fail':'فشل تسجيل الدخول إلى الهاتف الناعم',
  'ccd.webrtc.login.fail.noPwd':'لا تتوفر كلمة مرور أولية. أعد تعيين كلمة مرور الهاتف وحاول مرة أخرى.',
  'ccd.webrtc.login.fail.pop.tips':'فشل تسجيل الدخول تلقائيًا إلى الهاتف. لا يمكنك استخدام وظيفة الصوت. تسجيل الدخول إلى الهاتف مرة أخرى؟',
  'ccd.webrtc.login.try':'إعادة المحاولة',
  'ccd.webrtc.login.ignore':'تجاهل',
  'ccd.webrtc.login.fail.top.tips':'تسجيل الدخول إلى الهاتف غير طبيعي، ولا يمكن استخدام الوظائف ذات الصلة مثل الصوت.',
  "ccd.consult.message.two.inputExNumber": "يرجى إدخال الرقم الخاص باستشارة طرفين",
  "ccd.consult.message.three.inputExNumber": "الرجاء إدخال الرقم للاستشارة الثلاثية الأطراف",
  "ccd.ccbar.mediaSwitch.sfunotsupport":"لا تدعم المكالمة الحالية تبديل الوسائط.",
  "ccd.agent.tips.welcome.agentlogout": "ترحب",
  "ccd.transfer.message.microsoftTeamsLogin": "تسجيل الدخول",
  "ccd.agent.message.continuously.online":"سيتم الحفاظ على الخدمة عبر الإنترنت بعد تسجيل الوصول. يُرجى تسجيل المغادرة في الوقت المناسب بعد الانتهاء من العمل.",
  "ccd.agent.vrpserviceaddress.isempty" : "عنوان خادم VRP فارغ. اتصل بمسؤول النظام لتكوينه.",
  "ccd.agent.initializeagentex.success" : "قام الموظف بالتسجيل مع VRC بنجاح. سيتم مراقبة سطح المكتب الخاص بك وسيتم تسجيل مكالمتك على الشاشة. يُرجى الحفاظ على حالة عمل جيدة.",
  "ccd.agent.initializeagentex.passworderror" : "معرف الموظف أو كلمة المرور غير صحيحة. فشل التسجيل مع VRC.",
  "ccd.agent.initializeagentex.alreadyregistered" : "قام الموظف بالتسجيل مع VRC.",
  "ccd.agent.vrpexe.closed" : "يتم الآن إيقاف تشغيل برنامج VRC.",
  "ccd.agent.startMonitorEx.uninitialize" : "اتصال vrc غير صالح. قم بتسجيل الدخول إلى الموظف الحالي مرة أخرى.",
  "ccd.agent.startMonitorEx.qcnotexit" : "كائن QC غير موجود. تحقق مما إذا كان المصاحب متصلاً بشكل صحيح بـ VRC.",
  "ccd.agent.startMonitorEx.qctimelimitexceed" : "فشل الاتصال المصاحب بـ vRC. يرجى تسجيل الدخول مرة أخرى.",
  "ccd.agent.startMonitorEx.qcunsigned": "لم يقم الموظف بتسجيل الدخول. تحقق مما إذا كان الموظف قد قام بتسجيل الدخول أو يحتاج إلى تسجيل الدخول مرة أخرى.",
  "ccd.agent.startMonitorEx.othererror": "فشل الاتصال بالخادم. اتصل بموظفي الصيانة أو أعد المحاولة لاحقًا.",

}