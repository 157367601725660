<!--智能IVR-->
<template>
    <div>
        <sweet-dialog v-model="dialogVisible" :title="$t('isales.calledroute.title.selectivrflow')" width="616" class="aicc-dialog-limit-height" :close-on-click-modal="false">
            <aicc-advanced-search
                    :templateMetaData="templateMetaData"
                    @handleSearch='searchPageFirst'
                    @handleCommand="handleCommand">
            </aicc-advanced-search>
            <aicc-table
                    :tableData="ivrList"
                    :tableColumns="tableColumns"
                    :tableTools="{ showPagination: true, columnConfig: false }"
                    :paginationConfig="paginationConfig"
                    @handlePagination="handlePagination"
                    ref='refAiccTable'
                    :height="430">
            </aicc-table>
            <template #footer>
      <span class="dialog-footer">
        <sweet-button @click="dialogVisible = false">{{ $t('isales.common.title.cancel') }}</sweet-button>
        <sweet-button type="primary" @click="confirm">{{ $t('isales.common.title.confirm') }}</sweet-button>
      </span>
            </template>
        </sweet-dialog>
    </div>
</template>

<script>
    import {queryIvrOdfs} from "@/views/isales/api/systemAutoCallTask";

    export default {
        name: "isalesIntelligentIvrSelect",
        props: {
            show: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                ivrList: [],
                tableColumns: [
                    {
                        type: 'radio',
                        radioKey: 'flowId', // 单选绑定的字段名
                        width: '50px',
                        'show-overflow-tooltip': false
                    },
                    {
                        label: this.$t('isales.taskinfo.title.ivrFlow'),
                        prop: 'flowName',
                    }
                ],
                paginationConfig: {
                    PageSize: 10, // 每页条数
                    total: 0, //总条数
                    layout: "total, prev, pager, next" // 分页组件布局配置
                },
                templateMetaData: {
                    basicConfig: { // 基础搜索配置
                        basicSearch: [
                            {
                                searchType: 'primary', // primary: 有搜索图标的输入框；select: 下拉框; input: 搜索框；date: 日期选择器
                                modelValue: 'flowName', // v-model绑定的key值
                                modelDefault: '', // v-model绑定的默认值
                                placeholderValue: this.$t('isales.management.placeholder.ivrName'), // placeholder绑定的值 国际化之后的值
                                rules: [],
                                colConfig: {
                                    xl: 12,
                                    lg: 12
                                }
                            }
                        ],
                        otherOperation: [ // 右侧其他操作按钮
                            {
                                basicButton: true, // 基础按钮
                                buttonStyle: 'primary', // 按钮风格 primary; success; info; warning; danger
                                buttonName: this.$t('isales.tasktype.field.intelligent.robot.create'), // 按钮名称
                                eventName: 'create' // 按钮事件名称
                            }

                        ]
                    }
                },
                pageSize: 10,
                pageNum: 1,
            }
        },
        computed: {
            dialogVisible: {
                get() {
                    return this.show
                },
                set(val) {
                    this.$emit('update:show', val)
                }
            }
        },
        methods: {
            handleCommand(data) { // 触发事件处理，调用对应方法，方法和eventName同名
                const {eventName, params} = data
                this[eventName](params)
            },
          searchPageFirst(params){
            this.pageNum = 1;
            this.$refs.refAiccTable.setCurrentPage(1);
            this.queryIvrOdfs(params);
          },
            async queryIvrOdfs(params) {
                let queryCondition = {};
                if (params && params.params && params.params.flowName) {
                    queryCondition['flowName'] = params.params.flowName;
                }
                queryCondition['pageSize'] = this.pageSize;
                queryCondition['pageNum'] = this.pageNum;
                queryIvrOdfs(queryCondition).then(res => {
                    if (res) {
                        this.ivrList = res.flowList;
                        this.paginationConfig.total = res.total;
                    }
                })
            },
            handlePagination(params) {
                this.pageSize = params.pageSize;
                this.pageNum = params.pageNum;
                this.queryIvrOdfs(params);
            },
            confirm() {
                this.dialogVisible = false
              if($.isEmptyObject(this.$refs.refAiccTable.getRadioCurrentRow())){
                this.$message({
                  type: 'error',
                  duration: 10000,
                  showClose: true,
                  offset: 56,
                  message: this.$t('isales.agent.calledroute.ivr')
                });
              }
                this.$emit('isalesIntelligentIvrSelectConfirm', this.$refs.refAiccTable.getRadioCurrentRow())
            },
            create(){
                this.dialogVisible = false
                window.showTab({
                  id: '8005002',
                  name: 'oifde',
                  title: this.$t('oifde.headerTitle.flowConfiguration'),
                  query: {
                    redirectRoutePath: '/aiccOifde/robot/accessCode'
                  }
                })
            }
        },
        async created() {
            await this.queryIvrOdfs();
        },
    }
</script>

<style scoped>

</style>