export default {
  "oifde.traceCause.000019" : "لم يتم العثور على المستأجر.",
  "oifde.traceCause.000018" : "لم يتم تكوين التدفق الهدف والعقدة الهدف لعنصر مخطط نقل التدفق.",
  "oifde.traceCause.000017" : "تختلف لغة قالب الرسائل القصيرة عن اللغة التي تم تعيينها في عنصر مخطط إرسال الرسائل القصيرة. {0}",
  "oifde.traceCause.000016" : "لغة قالب الاستجابة غير متناسقة مع اللغة المعينة. {0}",
  "oifde.traceCause.000015" : "لم يتم تكوين قالب SMS لعنصر مخطط إرسال SMS.",
  "oifde.traceCause.000014" : "لم يتم تحديد قالب الاستجابة والمتغير.",
  "oifde.traceCause.000013" : "فشل تنفيذ واجهة الطرف الثالث.",
  "oifde.traceAdvice.000039" : "1. تحقق مما إذا كان عنوان TUC والمنفذ اللذان تم تكوينهما في ODFS طبيعيين. 2. تحقق مما إذا كانت الخدمات على كل عقدة TUC طبيعية.",
  "oifde.traceCause.000012" : "خطأ في تنفيذ التعبير {0}.",
  "oifde.traceAdvice.000038" : "اتصل بمسؤول النظام للتحقق من النظام.",
  "oifde.traceCause.000011" : "نوع عنصر المخطط غير صحيح.",
  "oifde.traceAdvice.000037" : "تحقق مما إذا كان الإدخال auth_token صحيحًا.",
  "oifde.traceCause.000010" : "لا يمكن نقل عنصر مخطط نقل التدفق إلى العقدة التالية. {0}",
  "oifde.traceAdvice.000036" : "تحقق مما إذا كان الإدخال auth_token صحيحًا.",
  "oifde.traceAdvice.000035" : "تحقق مما إذا كان الإدخال auth_token صحيحًا.",
  "oifde.traceAdvice.000034" : "تحقق مما إذا كان عنصر مخطط إنهاء المكالمة قد تم تكوينه بشكل صحيح للتدفق.",
  "oifde.traceAdvice.000033" : "تحقق مما إذا كانت واجهة طلب قطع الاتصال ترسل callid غير صحيح</b>.",
  "oifde.traceAdvice.000032" : "تحقق من معلومات الخطأ لكل عنصر تخطيطي وتصحيح الأخطاء وفقًا لذلك.",
  "oifde.traceAdvice.000031" : "اتصل بمسؤول النظام للتحقق من النظام.",
  "oifde.traceAdvice.000030" : "اتصل بمسؤول النظام للتحقق مما إذا كان تكوين عنصر المخطط صحيحًا.",
  "oifde.traceCause.000009" : "لم يتم العثور على الفرع. {0}",
  "oifde.traceCause.000008" : "خطأ داخلي في النظام.",
  "oifde.traceCause.000007" : "فرع الخروج فارغ.",
  "oifde.traceCause.000006" : "معالجة عناصر المخطط غير طبيعية.",
  "oifde.traceCause.000005" : "تجاوز عدد عمليات إعادة التدوير الداخلية الحد.",
  "oifde.traceCause.000004" : "لم يتم العثور على عقدة البدء.",
  "oifde.traceCause.000003" : "لم يتم العثور على عنصر المخطط المركب.",
  "oifde.traceCause.000002" : "الواجهة غير طبيعية لأن قيمة الإدخال للتقاعس غير صحيحة. {0}",
  "oifde.traceAdvice.000029" : "تحقق مما إذا كان قد تم تكوين خروج عقدة لعنصر المخطط.",
  "oifde.traceCause.000001" : "لم يتم تكوين نوع التحويل لعنصر مخطط تحويل المكالمة.",
  "oifde.traceAdvice.000028" : "تحقق مما إذا كان التدفق طبيعيًا.",
  "oifde.traceAdvice.000026" : "تدفق التنفيذ غير طبيعي. تحتاج إلى طلب استدعاء واجهة التهيئة أولاً.",
  "oifde.traceAdvice.000025" : "اتصل بمسؤول النظام للتحقق من النظام.",
  "oifde.traceAdvice.000024" : "تحقق من معلومات الخطأ لكل عنصر تخطيطي وتصحيح الأخطاء وفقًا لذلك.",
  "oifde.traceAdvice.000023" : "اتصل بمسؤول النظام للتحقق من النظام.",
  "oifde.traceAdvice.000022" : "تحقق مما إذا كان عنصر المخطط المركب طبيعيًا وموجودًا في النظام.",
  "oifde.traceAdvice.000021" : "تحقق مما إذا كان تطبيق الطرف الثالث أو IVR يرسل طلب تهيئة بشكل متكرر بنفس callid</b>.",
  "oifde.traceAdvice.000020" : "تحقق مما إذا كانت حالة المستأجر طبيعية.",
  "oifde.traceAdvice.000019" : "تحقق مما إذا كان رمز الوصول الذي تم نقله بواسطة واجهة الحوار طبيعيًا وموجودًا في النظام.",
  "oifde.traceAdvice.000018" : "تحقق مما إذا كان عنصر مخطط نقل التدفق قد تم تكوينه بشكل صحيح.",
  "oifde.traceAdvice.000017" : "تحقق من تكوين المحتوى واللغة في قالب المورد ، واللغة المضمنة في المتغير العام ، وما إذا كان يتم تعيين قيمة جديدة إلى اللغة في تنسيق التدفق. يجب تكوين اللغة المستخدمة للتشغيل في قالب المورد.",
  "oifde.traceAdvice.000016" : "تحقق من تكوين المحتوى واللغة في قالب المورد ، واللغة المضمنة في المتغير العام ، وما إذا كان يتم تعيين قيمة جديدة إلى اللغة في تنسيق التدفق. يجب تكوين اللغة المستخدمة للتشغيل في قالب المورد.",
  "oifde.traceAdvice.000015" : "تحقق مما إذا كان قد تم تكوين قالب SMS لعنصر مخطط إرسال SMS.",
  "oifde.traceAdvice.000014" : "تحقق من عنصر مخطط الاستجابة للتأكد من أن واحدًا على الأقل من قالب الاستجابة أو متغير الاستجابة له قيمة.",
  "oifde.traceAdvice.000013" : "تحقق مما إذا كانت واجهة الطرف الثالث طبيعية.",
  "oifde.traceAdvice.000012" : "تحقق مما إذا كان بناء الجملة الذي تم تكوينه للتعبير صحيحًا وما إذا كانت المتغيرات في التعبير لها قيم.",
  "oifde.traceAdvice.000011" : "اتصل بمسؤول النظام للتحقق من النظام.",
  "oifde.traceAdvice.000010" : "تحقق مما إذا كان عنصر مخطط نقل التدفق قد تم تكوينه بشكل صحيح.",
  "oifde.traceAdvice.000009" : "تحقق مما إذا كان للخروج من عنصر المخطط الحالي فروع غير معالجة. يوصى بأن تحتوي جميع عناصر المخطط على فرع افتراضي غير مشروط.",
  "oifde.traceAdvice.000008" : "اتصل بمسؤول النظام للتحقق من النظام.",
  "oifde.traceAdvice.000007" : "اتصل بمسؤول النظام للتحقق مما إذا كان منطق عنصر المخطط صحيحًا.",
  "oifde.traceCause.000044" : "عنوان URL (عنوان IP ورقم المنفذ) غير موجود في قائمة الثقة.",
  "oifde.traceAdvice.000006" : "اتصل بمسؤول النظام للتحقق مما إذا كان منطق عنصر المخطط صحيحًا.",
  "oifde.traceAdvice.000005" : "اتصل بمسؤول النظام للتحقق من النظام.",
  "oifde.traceAdvice.000004" : "تحقق مما إذا كان التدفق قد تم تحريره أو ما إذا كان التدفق طبيعيًا.",
  "oifde.traceCause.000041" : "خطأ في تنفيذ الإجراء المخزن. {0}",
  "oifde.traceAdvice.000003" : "تحقق مما إذا كان عنصر المخطط المركب موجودًا في النظام.",
  "oifde.traceCause.000040" : "لم يتم العثور على الخطأ. {0}",
  "oifde.traceAdvice.000002" : "اتصل بمسؤول النظام لتعديل معلمات الإدخال الخاصة بالواجهة.",
  "oifde.traceAdvice.000001" : "قم بتكوين نوع تحويل المكالمة لعنصر مخطط تحويل المكالمة.",
  "oifde.traceCause.000039" : "فشل استدعاء TUC. {0}",
  "oifde.traceCause.000038" : "فشل في معالجة العقدة التالية.",
  "oifde.traceCause.000037" : "حدث خطأ أثناء التحقق من auth_token.",
  "oifde.traceCause.000036" : "حدث خطأ أثناء التحقق من auth_token.",
  "oifde.traceCause.000035" : "حدث خطأ أثناء التحقق من auth_token.",
  "oifde.traceCause.000034" : "عنصر مخطط إنهاء المكالمة غير موجود.",
  "oifde.traceCause.000033" : "معلومات مثيل المكالمة غير موجودة.",
  "oifde.traceCause.000032" : "تنفيذ التدفق الداخلي غير طبيعي.",
  "oifde.traceCause.000031" : "خطأ داخلي في النظام.",
  "oifde.traceCause.000030" : "بيانات عنصر المخطط الذي يكون معرف العقدة الخاص به هو {0} واسم العقدة هو {1} غير طبيعية.",
  "oifde.traceCause.000029" : "لم يتم العثور على خروج في عنصر المخطط الحالي الذي يكون معرف العقدة الخاص به هو {0} واسم العقدة هو {1}.",
  "oifde.traceCause.000028" : "التدفق قد انتهى.",
  "oifde.traceCause.000026" : "لم تتم تهيئة التدفق.",
  "oifde.traceCause.000025" : "يتم تنفيذ قطع الاتصال التلقائي إذا حدثت استثناءات داخلية أكثر من ثلاث مرات.",
  "oifde.traceCause.000024" : "تنفيذ التدفق الداخلي غير طبيعي.",
  "oifde.traceCause.000023" : "خطأ داخلي في النظام.",
  "oifde.traceCause.000022" : "لا يحتوي عنصر المخطط المركب على عقدة بدء.",
  "oifde.traceCause.000021" : "تتم تهيئة الوحدة أكثر من مرة.",
  "oifde.traceCause.000020" : "تم تعليق المستأجر.",
  "oifde.traceAdvice.000044" : "اتصل بمسؤول النظام لإضافته إلى قائمة الثقة.",
  "oifde.traceAdvice.000041" : "تحقق مما إذا كان الإجراء المخزن طبيعيًا.",
  "oifde.traceAdvice.000040" : "تحقق مما إذا كان الإجراء المخزن طبيعيًا.",
}