// 获取调用接口返回错误码
import i18n from "@/lang";
const $t = i18n.global.t
export function getChatPostReturnCode(res) {
    if (res && res.returnCode) {
        return res.returnCode;
    } else {
        return '1';
    }
}
// 获取接口返回成功数据
export function getChatPostData(res) {
    return res.data;
}
export const ERRORCODE = {
    "1": $t('ccmessaging.chat.system.error'),
    "2": $t('ccmessaging.chat.email.channel.paramiserror'),
    "3": $t('ccmessaging.socialPostOperation.tips.approvingDelete'),
    "4": $t('ccmessaging.socialPostOperation.tips.cancelError'),
    "5": $t('ccmessaging.socialPostOperation.tips.approveSet') + ":6005001000028",
    "UPLOAD01": $t('ccmessaging.socialPostOperation.tips.imageNumTip'),
    "UPLOAD02": $t('ccmessaging.chat.agentconsole.uploadfail')
}
// 错误码数据
export function getErrorMessage(returnCode) {
    let messageStr = ERRORCODE[returnCode];
    if (!messageStr) {
        messageStr = 'unknow error';
    }
    return messageStr;
}