import request from "@/utils/request";

/**
 * 查询模板列表
 * @param data
 * @returns {*}
 */
export const queryOutboundCallTemplateListAndCount = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/outboundcalltemplate/queryOutboundCallTemplateListAndCount',
        method: 'post',
        data
    })
}


/**
 * 查询主叫号码列表
 * @param data
 * @returns {*}
 */
export const queryCallerNo = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/callerNo/queryCallerNo',
        method: 'post',
        data
    })
}

/**
 * 查询当前外呼任务下的主叫号码列表
 * @param data
 * @returns {*}
 */
export const queryCallerAccessCode = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/callerNo/queryCallerAccessCode',
        method: 'post',
        data
    })
}

/**
 * 查询技能队列列表
 * @param data
 * @returns {*}
 */
export const querySkillListAndCount = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/skill/query',
        method: 'post',
        data
    })
}

/**
 * 查询问卷列表
 * @param data
 * @returns {*}
 */
export const querySurveyListAndCount = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/task/querySurveyList',
        method: 'post',
        data
    })
}

/**
 * 查询短信提醒模板列表
 * @param data
 * @returns {*}
 */
export const querySmsListAndCount = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/task/querySmsTemplate',
        method: 'post',
        data
    })
}

/**
 * 添加主叫号码
 * @param data
 * @returns {*}
 */
export const addCallers = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/callerNo/addCallers',
        method: 'post',
        data
    })
}

/**
 * 批量删除主叫号码
 * @param data
 * @returns {*}
 */
export const delCaller = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/callerNo/delCaller',
        method: 'post',
        data
    })
}

/**
 * 查询当前cti version
 * @param data
 * @returns {*}
 */
export const queryLocalCtiVersion = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/task/queryLocalCtiVersion',
        method: 'post',
        data
    })
}

/**
 * 查询当前环境变量
 * @param data
 * @returns {*}
 */
export const queryCmsEnvironment = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/task/queryCmsEnvironment',
        method: 'post',
        data
    })
}

/**
 * 查询特殊日期数据
 * @param data
 * @returns {*}
 */
export const queryHoliday = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/specialDay/query',
        method: 'post',
        data
    })
}

/**
 * 查询特殊日期数量
 * @param data
 * @returns {*}
 */
export const queryHolidayCount = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/specialDay/queryCount',
        method: 'post',
        data
    })
}

/**
 * 查询号码类型
 * @param data
 * @returns {*}
 */
export const queryAllCallNoType = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/callNoType/queryAllCallNoType',
        method: 'post',
        data
    })
}
/**
 * 查询父业务结果
 * @param data
 * @returns {*}
 */
export const queryBusiResult = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/businessResult/queryResultCodeAndNameOfTenant',
        method: 'post',
        data
    })
}

/**
 * 查询子业务结果
 * @param data
 * @returns {*}
 */
export const querySubCodeAndNameByCode = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/businessResult/querySubCodeAndNameByCode',
        method: 'post',
        data
    })
}

/**
 * 新建任务
 * @param data
 * @returns {*}
 */
export const createTask = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/task/createTask',
        method: 'post',
        data
    })
}

/**
 * 修改任务
 * @param data
 * @returns {*}
 */
export const updateTask = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/task/updateTask',
        method: 'post',
        data
    })
}

/**
 * 获取策略和外呼时间段
 * @param data
 * @returns {*}
 */
export const getPolicyAndCallTimesByTaskId = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/task/getPolicyAndCallTimesByTaskId',
        method: 'post',
        data
    })
}

/**
 * 获取节假日
 * @param data
 * @returns {*}
 */
export const queryTaskHoliday = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/task/queryTaskHoliday',
        method: 'post',
        data
    })
}

/**
 * 获取重试策略
 * @param data
 * @returns {*}
 */
export const queryRetryPolicy = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/task/queryRetryPolicy',
        method: 'post',
        data
    })
}
/**
 * 获取呼叫策略
 * @param data
 * @returns {*}
 */
export const queryCallPolicy = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/task/queryCallPolicy',
        method: 'post',
        data
    })
}
/**
 * 获取普通IVR
 * @param data
 * @returns {*}
 */
export const queryCommonIvr = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/task/queryCommonIvr',
        method: 'post',
        data
    })
}
/**
 * 获取智能IVR
 * @param data
 * @returns {*}
 */
export const queryIvrOdfs = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/task/queryIvrOdfs',
        method: 'post',
        data
    })
}

/**
 * 获取模板信息
 * @param data
 * @returns {*}
 */
export const getOutboundCallTemplate = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/outboundcalltemplate/getOutboundCallTemplate',
        method: 'post',
        data
    })
}

/**
 * 获取外显结果
 * @param data
 * @returns {*}
 */
export const queryResultCode = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/obResult/queryObResultList',
        method: 'post',
        data
    })
}

/**
 * 获取外呼结果通过结果码
 * @param data
 * @returns {*}
 */
export const queryObResultListByCode = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/obResult/queryObResultListByCode',
        method: 'post',
        data
    })
}

/**
 * 批量修改基本信息
 * @param data
 * @returns {*}
 */
export const batchUpdateTask = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/task/batchUpdateTask',
        method: 'post',
        data
    })
}

/**
 * 批量修改时间
 * @param data
 * @returns {*}
 */
export const batchUpdateTaskCallTime = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/task/batchUpdateTaskCallTime',
        method: 'post',
        data
    })
}

/**
 * 批量修改时间
 * @param data
 * @returns {*}
 */
export const batchUpdateTaskPolicy = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/task/batchUpdateTaskPolicy',
        method: 'post',
        data
    })
}

/**
 * 批量修改时间
 * @param data
 * @returns {*}
 */
export const batchUpdate = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/calllogic/batchUpdate',
        method: 'post',
        data
    })
}

/**
 * 创建外呼模板
 * @param data
 * @returns {*}
 */
export const createAutoOutboundCallTemplate = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/outboundcalltemplate/createAutoOutboundCallTemplate',
        method: 'post',
        data
    })
}

/**
 * 编辑外呼模板
 * @param data
 * @returns {*}
 */
export const updateAutoOutboundCallTemplate = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/outboundcalltemplate/updateAutoOutboundCallTemplate',
        method: 'post',
        data
    })
}

/**
 * 获取批量逻辑
 * @param data
 * @returns {*}
 */
export const queryBatchTaskParams = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/task/queryBatchTaskParams',
        method: 'post',
        data
    })
}