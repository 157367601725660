import { checksession } from '@/views/base/api'
export function initSessionListener() {
  function checkSessionInterval() {
    const lastSessionActiveTime = window.localStorage.getItem('lastSessionActiveTime')
    if (!lastSessionActiveTime) {
      // localStorage中无初始值，设置当前时间戳作为初始值
      window.localStorage.setItem('lastSessionActiveTime', new Date().getTime())
      return
    }
    const curTime = new Date().getTime()
    const timeDifference = curTime - Number(lastSessionActiveTime)
    if (timeDifference < 1000 * 60 * 5) {
      // 判断当前时间距离上次调用时间的时间差，时间差小于5分钟不进行后续接口调用
      return
    }
    checksession().finally(() => {
      // 调用保活接口，并更新上次会话激活时间到localStorage中
      window.localStorage.setItem('lastSessionActiveTime', new Date().getTime())
    })
  }
  window.addEventListener('click', () => {
    checkSessionInterval()
  })
  window.addEventListener('keydown', () => {
    checkSessionInterval()
  })
}
