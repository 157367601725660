<template>
  <sweet-dialog :title="$t('oifde.flow.title.publishFlow')"
                v-model="dialogVisible"
                width="616px"
                class="aicc-dialog-limit-height"
                :close-on-click-modal="false">
    <div v-loading="isLoading">
      <sweet-form ref="dataForm" :rules="rules" :model="publishData" label-position="left" label-width="auto">
        <sweet-form-item :label="$t('oifde.flow.publish.flowName')">
          <span>{{ flowData.flowName }}</span>
        </sweet-form-item>
        <sweet-form-item :label="$t('oifde.flow.publish.flowVersion')">
          <span>{{ flowData.version }}</span>
        </sweet-form-item>
        <!-- ccivr OP 手动输入流程接入码-->
        <sweet-form-item v-if="isShowAccessCode" :label="$t('oifde.accesscode.accessCode')" prop="accessCode">
          <sweet-input v-model="publishData.accessCode" maxlength="20" :disabled="!!this.flowData.accessCode"/>
        </sweet-form-item>
        <sweet-form-item :label="$t('oifde.flow.publish.upgradeMode')" prop="upgradeMode">
          <sweet-radio-group v-model="publishData.upgradeMode">
            <sweet-radio :label="true">
              <div class="aicc-align--absolute-left">
                <div>{{ $t('ccivr.ivrflow.release.formal') }}</div>
                <sweet-tooltip placement="top-start">
                  <template #content><div v-html="$t('ccivr.ivrflow.release.formalinfo')"></div></template>
                  <sweet-icon class="tip-icon"><warning style="transform: rotateX(180deg)" /></sweet-icon>
                </sweet-tooltip>
              </div>
            </sweet-radio>
            <sweet-radio :label="false">
              <div class="aicc-align--absolute-left">
                <div>{{ $t('oifde.flow.publish.gray') }}</div>
                <sweet-tooltip placement="top-start">
                  <template #content><div v-html="$t('ccivr.ivrflow.grayrule.grayinfo')"></div></template>
                  <sweet-icon class="tip-icon"><warning style="transform: rotateX(180deg)" /></sweet-icon>
                </sweet-tooltip>
              </div>
            </sweet-radio>
          </sweet-radio-group>
        </sweet-form-item>
        <sweet-form-item v-if="!publishData.upgradeMode" :label="$t('oifde.flow.publish.ruleName')" prop="ruleId">
          <sweet-input v-model="publishData.ruleName"
                       :placeholder="$t('oifde.flow.publish.selectPlaceholder')"
                       clearable readonly
                       style="width: 400px"
                       @click="chooseRuleVisible = true">
            <template #suffix>
              <img class="more-icon-in-input" src="@/assets/img/more@2x.png" alt="more" @click="chooseRuleVisible = true">
            </template>
          </sweet-input>
        </sweet-form-item>
        <sweet-form-item v-if="!this.isCcivr" :label="$t('oifde.flow.publish.publishMode')" prop="workmode">
          <sweet-radio-group v-model="publishData.workmode">
            <sweet-radio :label="0">{{ $t('oifde.flow.publish.normal') }}</sweet-radio>
            <sweet-radio :label="1">{{ $t('oifde.flow.publish.monitor') }}</sweet-radio>
            <sweet-radio :label="2">{{ $t('oifde.flow.publish.insert') }}</sweet-radio>
          </sweet-radio-group>
        </sweet-form-item>
      </sweet-form>
    </div>
    <template #footer>
      <sweet-button @click="dialogVisible = false">{{ $t('oifde.table.cancel') }}</sweet-button>
      <sweet-button type="primary" @click="publish">{{ $t('oifde.table.confirm') }}</sweet-button>
    </template>
  </sweet-dialog>
  <DialogChooseRule v-if="chooseRuleVisible"
                    v-model:show="chooseRuleVisible"
                    @onConfirm="chooseRuleConfirm"></DialogChooseRule>
</template>

<script>
import DialogChooseRule from './DialogChooseRule.vue'
import {updateFlowStatus} from '@oifde/request/api/flow'
import {ElMessageBox} from "element-plus";
import AiccElMessage from "@/utils/el-message";
import {
  grayReleasedIVRFlow,
  invokingOifde,
  querIVRFlowById,
  releasedIVRFlow,
  replaceIVRFlow
} from "@oifde/request/api/keyPressRobot";
import {GRAYRELEASEDIVRFLOW_ERROR_MAP, RELEASEDIVRFLOW_ERROR_MAP} from "@oifde/views/keyPressRobot/enum";

const ivrStatusGray = 4; //流程灰度发布状态
const ivrStatusFormal = 3; //流程正式发布状态

export default {
  name: 'DialogPublish',
  components: { DialogChooseRule },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    flowData: Object
  },
  data() {
    return {
      publishData: {
        flow: {},
        upgradeMode: true,
        version: undefined,
        ruleId: undefined,
        ruleName: '',
        workmode: 0,
        grayRules: [],
        accessCode: ''
      },
      chooseRuleVisible: false,
      grayFlowTag: false,
      formalFlowTag: false,
      isSaasMode: false,
      ivrFlowEntity: {},
      isLoading: false,
      oldRuleId: '',
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      }
    },
    rules() {
      return {
        ruleId: [
          {
            required: true,
            message: this.$t('oifde.flow.rules.ruleId'),
            trigger: 'change'
          }
        ],
        upgradeMode: [
          {
            required: true,
            message: this.$t('oifde.flow.rules.upgradeMode'),
            trigger: 'change'
          }
        ],
        workmode: [
          {
            required: true,
            message: this.$t('oifde.flow.rules.workMode'),
            trigger: 'change'
          }
        ],
        accessCode: [
          {
            required: true,
            message: this.$t('oifde.accesscode.validate.accessCode'),
            trigger: 'change'
          },
          {
            pattern: /^[a-zA-Z0-9]+$/,
            message: this.$t('oifde.accesscode.validate.accessCodeCharReg'),
            trigger: 'change'
          }
        ],
      }
    },
    isCcivr() {
      return this.flowData.source === 'ccivr'
    },
    isShowAccessCode() {
      return this.isCcivr && !this.isSaasMode && this.flowData.flowquote !== null && this.flowData.flowquote !== 0
    }
  },
  created() {
    this.isSaasMode = localStorage.getItem("isIndependentDeployment") === 'false'
    if (this.isCcivr) {
      this.publishData.accessCode = this.flowData.accessCode
      this.searchIvrFlow()
    }
  },
  methods: {
    chooseRuleConfirm(data) {
      if (data && data.ruleId) {
        this.publishData.ruleId = data.ruleId
        this.publishData.ruleName = data.ruleName
      }
    },
    publish() {
      this.$refs['dataForm'].validate(valid => {
        if (valid) {
          if (this.isLoading) {
            return
          } else {
            this.isLoading = true
          }
          if (this.isCcivr) {
            if ((this.publishData.upgradeMode && this.formalFlowTag) || (!this.publishData.upgradeMode && this.grayFlowTag)) {
              ElMessageBox.confirm(this.$t('ccivr.ivrflow.operate.ensurereplace.info'), this.$t('ccivr.ivrflow.operate.ensurereplace'), {
                confirmButtonText: this.$t('isales.common.title.confirm'),
                cancelButtonText: this.$t('isales.common.title.cancel'),
                type: 'warning'
              }).then(async () => {
                if (this.flowData.isDiagram && this.flowData.isDiagram === true) {
                  this.$emit("saveAndPublish");
                  return;
                } else {
                  this.publishCcivr()
                }
                return;
              }).catch(() => {
                this.isLoading = false
                return;
              })
            } else {
              if (this.flowData.isDiagram && this.flowData.isDiagram === true) {
                this.$emit("saveAndPublish");
                return;
              } else {
                this.publishCcivr()
              }
            }
          } else {
            if (this.flowData.isDiagram && this.flowData.isDiagram === true) {
              this.$emit("saveAndPublish");
              return;
            } else {
              this.publishOifde()
            }
          }
        }
      })
    },
    publishCcivr() {
      console.log("publishCcivr...")
      if (this.publishData.upgradeMode) {
        this.formalReleaseCcivr()
      } else {
        this.grayReleaseCcivr()
      }
    },
    grayReleaseCcivr() {
      // 已存在灰度发布流程，不做操作
      if (this.grayFlowTag) {
        this.replace(this.publishData.ruleId);
        return;
      }

      let item = {
        "flowname": this.ivrFlowEntity.flowname,
        "id": this.ivrFlowEntity.id,
        "ruleId": this.publishData.ruleId,
        "accessCode": this.publishData.accessCode,
        "flowId": this.flowData.flowId
      };
      grayReleasedIVRFlow(item).then(res => {
        this.isLoading = false
        if (res["returnCode"] === "0") {
          AiccElMessage({
            type: 'success',
            duration: 5000,
            offset: 56,
            message: this.$t('ccivr.ivr.ivrflow.operate.release.success')
          });
          this.dialogVisible = false;
          this.$emit("refreshList");
        } else {
          let messageStr = GRAYRELEASEDIVRFLOW_ERROR_MAP[(res["returnCode"])];
          if (!messageStr) {
            messageStr = this.$t('ccivr.ivr.ivrflow.operate.release.fail');
          }
          AiccElMessage({
            type: 'error',
            duration: 10000,
            showClose: true,
            offset: 56,
            message: messageStr
          });
        }
      }).catch(e => {
        this.isLoading = false
      })
    },
    async formalReleaseCcivr() {
      //已存在正式发布流程，则替换
      if (this.formalFlowTag) {
        this.replace(null);
        return;
      }
      // 查询老ruleId
      await this.grayInfo(this.flowData.flowId);
      let item = {
        "flowname": this.ivrFlowEntity.flowname,
        "id": this.ivrFlowEntity.id,
        "accessCode": this.publishData.accessCode,
        "oldRuleId": this.oldRuleId,
        "flowId": this.flowData.flowId
      };
     await releasedIVRFlow(item).then(res => {
        this.isLoading = false
        if (res["returnCode"] === "0") {
          AiccElMessage({
            type: 'success',
            duration: 5000,
            offset: 56,
            message: this.$t('ccivr.ivr.ivrflow.operate.release.success')
          });
          this.dialogVisible = false;
          this.$emit("refreshList");
        } else {
          let messageStr = RELEASEDIVRFLOW_ERROR_MAP[(res["returnCode"])];
          if (!messageStr) {
            messageStr = this.$t('ccivr.ivr.ivrflow.operate.release.fail');
          }
          AiccElMessage({
            type: 'error',
            duration: 10000,
            showClose: true,
            offset: 56,
            message: messageStr
          });
        }
      }).catch(e => {
        this.isLoading = false
      })
    },
    //替换已发布流程
    async replace(ruleId) {
      let item = {"id": this.ivrFlowEntity.id};
      item['flowname'] = this.flowData.flowName;
      item['flowId'] = this.flowData.flowId;
      // 获取老的灰度规则Id
      await this.grayInfo(this.flowData.flowId);
      item['oldRuleId'] = this.oldRuleId;
      if (ruleId) {
        item['ruleId'] = ruleId;
      }
      replaceIVRFlow(item).then(res => {
        this.isLoading = false
        if (res["returnCode"] === "0") {
          AiccElMessage({
            type: 'success',
            duration: 5000,
            offset: 56,
            message: this.$t('ccivr.ivr.ivrflow.operate.release.success')
          });
          this.dialogVisible = false;
          this.$emit("refreshList");
        } else {
          AiccElMessage({
            type: 'error',
            duration: 10000,
            showClose: true,
            offset: 56,
            message: this.$t('ccivr.ivrflow.operate.replace.fail')
          });
        }
      }).catch(e => {
        this.isLoading = false
      });
    },
    async grayInfo(flowId) {
      // 查询灰度规则id
      const reqParam = {
        "odfsRequestType": "000-005",
        "method": "GET",
        "body": {"queryParam": {"flowId": flowId}}
      };
      const queryGrayRuleRes = await invokingOifde(reqParam);
      if (!queryGrayRuleRes || queryGrayRuleRes["retcode"] != 0 || !queryGrayRuleRes["result"]["flowList"] ||
          !queryGrayRuleRes["result"]["flowList"][0] || !queryGrayRuleRes["result"]["flowList"][0]["ruleId"]) {
        return;
      }
      this.oldRuleId = queryGrayRuleRes["result"]["flowList"][0]["ruleId"];
    },

    publishOifde(flowId) {
      console.log("publishOifde...", flowId)
      // 处理画布保存流程后获取的流程ID
      let flowIdTmp = this.flowData.flowId;
      if (!flowIdTmp && flowId) {
        flowIdTmp = flowId;
      }
      const flow = {
        flowId: flowIdTmp,
        flowCode: this.flowData.flowCode,
        workmode: this.publishData.workmode,
        scenarioType: this.flowData.scenarioType,
        status: 'VALID',
        source: 'OIFDE'
      }
      if (this.publishData.upgradeMode) {
        // 直接升级，设置为默认流程
        flow.isDefault = '1'
        flow.ruleId = ''
      } else {
        // 灰度升级,设置为灰度规则
        flow.isDefault = '0'
        flow.ruleId = this.publishData.ruleId
      }

      updateFlowStatus(flow).then(response => {
        this.isLoading = false
        this.$message({
          message: this.$t('oifde.flow.actions.publishOk'),
          duration: 5000,
          offset: 56,
          type: 'success'
        })
        this.dialogVisible = false
        this.$emit('refreshList')
      }).catch(e => {
        this.isLoading = false
      })
    },
    searchIvrFlow() {
      const reqParam = {"id": this.flowData.id};
      querIVRFlowById(reqParam).then(res => {
        if (!res || res.length <= 0) {
          return;
        }
        this.ivrFlowEntity = res[0];
        for (let i = 0; i < res.length; i++) {
          if (res[i].status === ivrStatusGray) {
            this.grayFlowTag = true;
          } else if (res[i].status === ivrStatusFormal) {
            this.formalFlowTag = true;
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.tip-icon {
  margin-left: 5px;
}
.more-icon-in-input {
  cursor: pointer;
}
</style>
