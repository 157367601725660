<template>
  <sweet-dialog
    append-to-body
    destroy-on-close
    v-model="dialogVisible"
    :title="$t('ccnotification.notifition.sendmessage.templateselect')"
    width="896px"
    @open="handleOpen"
    @closed="handleClosed"
    :close-on-click-modal="false"
  >
    <div class="email-template-pop">
      <sweet-form :model="emailTemplateForm" @submit.prevent>
        <sweet-row :gutter="20">
          <sweet-col :span="8">
            <sweet-form-item>
              <sweet-input
                v-model="emailTemplateForm.name"
                :placeholder="$t('ccnotification.notifition.variable.templatename')"
              >
              </sweet-input>
            </sweet-form-item>
          </sweet-col>
          <sweet-col :span="8">
            <sweet-form-item>
              <org-tree-select
                ref="orgRef"
                :data="orgsData"
                multiple
                @onSelectChange="onOrgSelected"
                @onClear="onOrgClear"
              />
            </sweet-form-item>
          </sweet-col>
          <sweet-col :span="8">
            <sweet-form-item>
              <div class="button-wrap">
                <sweet-button type="primary" @click="handleSearch"
                  >{{ $t('message.center.button.query') }}
                </sweet-button>
                <sweet-button @click="handleReset"
                  >{{ $t('ccmessaging.chat.chatconfig.reset') }}
                </sweet-button>
              </div>
            </sweet-form-item>
          </sweet-col>
        </sweet-row>
      </sweet-form>
      <aicc-table
        ref="emailTemplateTableRef"
        :tableData="emailTemplateTableInfo.tableData"
        :tableColumns="emailTemplateTableInfo.tableColumns"
        :tableTools="{ showPagination: true, columnConfig: false }"
        :paginationConfig="emailTemplateTableInfo.paginationConfig"
        @handlePagination="handleQuery"
        @radio-change="handleRadio"
      ></aicc-table>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleCancel">{{ $t('message.template.message.cancel') }}</el-button>
        <el-button type="primary" @click="handleSubmit">{{
          $t('ccmessaging.chat.multimedia.finish')
        }}</el-button>
      </span>
    </template>
  </sweet-dialog>
</template>

<script setup>
import { computed, getCurrentInstance, reactive, ref, toRefs } from 'vue'
import { getUserAccess } from '@/utils/organization'
import { queryMessageTemplate } from '@ccme/api/online-chat-workbench/index.js'
import appConfig from '@/config'

const emit = defineEmits(['update:emailTemplateDialog', 'selectedEmailTemplate'])
const {
  appContext: {
    config: { globalProperties }
  }
} = getCurrentInstance()
const props = defineProps({
  emailTemplateDialog: {
    default: false
  },
  replyType: {
    default: '' // 模板用处：autoReply freeTimeText
  },
  orgId: {
    default: ''
  }
})
const { emailTemplateDialog, replyType, orgId } = toRefs(props)

const orgPlaceholder = ref($t('ccd.label.org.all'))
let accessOrgCache = ref([])
const orgsData = ref([])
const selectedOrgs = ref([])
const dialogVisible = computed({
  get() {
    return emailTemplateDialog.value
  },
  set(val) {
    emit('update:emailTemplateDialog', val)
  }
})
getUserAccess().then((data) => {
  handleOrgsData(data)
  orgsData.value = data
})

// 表格信息
const emailTemplateTableRef = ref(null)
const emailTemplateTableInfo = reactive({
  tableData: [],
  tableColumns: [
    {
      type: 'radio',
      radioKey: 'id',
      width: '60'
    },
    {
      label: globalProperties.$t('ccnotification.notifition.variable.templatename'),
      prop: 'name'
    },
    {
      label: globalProperties.$t('ccnotification.notifition.sendmessage.messagetitle'),
      prop: 'title'
    }
  ],
  paginationConfig: {
    ...appConfig.pagination.dialog,
    layout: appConfig.pagination.LAYOUT.ADVANCED
  }
})

// 模板搜索输入框数据
const emailTemplateForm = reactive({
  name: '',
  orgName: ''
})
const handleOrgsData = (orgs) => {
  for (const org of orgs) {
    // 渠道配置邮件自动回复模板就是可选择所有组织
    if (replyType.value == 'autoReply' || replyType.value == 'freeTimeText') {
      orgPlaceholder.value = $t('ccmessaging.chat.agent.organizational.structure')
      org.disabled = false
    } else {
      // aicc工作台引用模板就是选择可访问组织
      org.disabled = org.orgId !== '0' && !org.isAccess
    }
    if (org.isAccess && org.orgId !== '0' && org.orgId !== '1') {
      accessOrgCache.value.push(org.orgId)
    }
    if (org.children) {
      handleOrgsData(org.children)
    }
  }
}

const onOrgSelected = (result) => {
  selectedOrgs.value = result
  handleSelectedOrg()
}

const onOrgClear = () => {
  selectedOrgs.value = []
  handleSelectedOrg()
}

/**
 * 选择组织
 *
 * @param orgInfo 组织信息
 */
const handleSelectedOrg = () => {
  let noOrgFlag = selectedOrgs.value.includes('0')
  const orgFlag = selectedOrgs.value.includes('1')
  const tempOrgs = []
  for (const orgId of selectedOrgs.value) {
    if ('0' !== orgId && '1' !== orgId) {
      tempOrgs.push(orgId)
    }
  }
  if (tempOrgs.length > 100) {
    ElMessage({
      showClose: true,
      message: globalProperties.$t('ccmessaging.chat.selectorgs.maxlimit.warn'),
      type: 'warning'
    })
    return
  }
  //渠道配置自动回复邮件模板
  if (replyType.value == 'autoReply' || replyType.value == 'freeTimeText') {
    orgParams.orgIdList = tempOrgs
  } else {
    orgParams.orgIdList = tempOrgs.length == 0 ? accessOrgCache.value : tempOrgs
  }
  //只查询无归属（不关心可访问了）
  if (noOrgFlag && !orgFlag && tempOrgs.length == 0) {
    orgParams.orgIdList = []
  }
  //清空时，可访问组织默认的noOrgFlag为true
  if (!(replyType.value == 'autoReply' || replyType.value == 'freeTimeText')) {
    if (!noOrgFlag && !orgFlag && tempOrgs.length === 0) {
      noOrgFlag = true
    }
  }
  orgParams.orgFlag = orgFlag
  orgParams.noOrgFlag = noOrgFlag
}

// 弹窗打开
const handleOpen = () => {
  if (replyType.value == 'autoReply' || replyType.value == 'freeTimeText') {
    if (orgId && orgId.value != '') {
      selectedOrgs.value.push(orgId.value)
      orgParams.orgIdList.push(orgId.value)
    } else {
      //默认查无归属
      selectedOrgs.value.push('0')
      orgParams.noOrgFlag = true
      orgParams.orgFlag = false
    }
  }
  handleSearch()
}

// 弹窗关闭
const handleClosed = () => {
  if (replyType.value == 'autoReply' || replyType.value == 'freeTimeText') {
    orgParams = {
      orgIdList: [],
      noOrgFlag: false,
      orgFlag: false
    }
    selectedOrgs.value = []
  } else {
    orgParams = {
      orgIdList: accessOrgCache.value,
      noOrgFlag: true,
      orgFlag: false
    }
    selectedOrgs.value = []
  }
  emailTemplateForm.name = ''
  emailTemplateForm.orgName = ''
  rowInfo = {}
}

// 搜索
const handleSearch = () => {
  emailTemplateTableRef.value.handleInitChange()
}

// 模板查询
const handleQuery = (pagination) => {
  const { limit, offset } = pagination
  queryMessageTemplate({
    limit,
    offset,
    name: emailTemplateForm.name,
    orgIdList: orgParams.orgIdList,
    type: 1,
    mediaType: 0,
    noOrgFlag: orgParams.noOrgFlag,
    orgFlag: orgParams.orgFlag
  }).then(({ result = [], total = 0 } = {}) => {
    emailTemplateTableInfo.tableData = result
    emailTemplateTableInfo.paginationConfig.total = total
  })
}

// 选中的模板数据处理
let rowInfo = {}
const handleRadio = ({ row }) => {
  rowInfo = row
  rowInfo.replyType = replyType.value
}

// 取消
const handleCancel = () => {
  dialogVisible.value = false
}

// 完成
const handleSubmit = () => {
  if (Object.keys(rowInfo).length) {
    emit('selectedEmailTemplate', rowInfo)
    dialogVisible.value = false
  }
}

// 组织结构弹窗
const orgRef = ref(null)
let orgParams = {
  orgIdList:
    replyType.value == 'autoReply' || replyType.value == 'freeTimeText' ? [] : accessOrgCache.value,
  noOrgFlag: replyType.value == 'autoReply' || replyType.value == 'freeTimeText' ? false : true, //初始值，当存在组织时传true，查询组织和无组织的数据
  orgFlag: false
}
const handleOrgPop = () => {
  orgRef.value.open()
}
// 重置
const handleReset = () => {
  emailTemplateForm.name = ''
  emailTemplateForm.orgName = ''
}
</script>

<style lang="less" scoped>
.email-template-pop {
  .button-wrap {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
