export default {
  'sm.assign.employee': 'Assign Employee',
  'SM.USERGROUP.SHOW.LIST': 'User Groups',
  'common.baseui.LABEL.HEADING_BASEINFO': 'Basic Info',
  'SM.USERGROUP.LABEL.NAME': 'User Group Name',
  'common.bremui.label.description': 'Description',
  'SM.SERVLET.EMPLOYEE': 'Employee',
  'common.statemachineui.button.add': 'Add',
  'sm.add.usergroup': 'Creating a User Group',
  'sm.edit.usergroup': 'Edit User Group',
  'SM.ORGSTAFF.BUTTON.COMMON_OK': 'OK',
  'SM.COMMON.LABEL.SYSMENU_SUSPENDED': 'Deactivate',
  'SM.ORGSTAFF.MESSAGE.OPERATESUCCESS': 'Operation performed successfully.',
  'SM.ORGSTAFF.MESSAGE.STAFF_AUTHCOPY_SOURCENO_CONTBEADMIN_ERR':
    'The system administrator account cannot be used as the source account.',
  'SM.ORGSTAFF.MESSAGE.BE_DELETESUCCESS': 'Deleted successfully.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_DELETE_FAILED': 'Failed to delete the user.',
  'SM.ORGSTAFF.TIPS.INVALID_IP_ADDRESS': 'Invalid IP address.',
  'SM.COMMON.BUTTON.BANKCARD_SEARCH': 'Search',
  'SM.ORGSTAFF.LABEL.ACCESS.ADD_FAIL': 'Adding failed.',
  'SM.ORGSTAFF.TITLE.CONTACT_DESC': 'Description',
  'SM.COMMON.LABEL.AREALIST': 'Area List',
  'SM.COMMON.LABEL.CURRENCY_DECIMALSYMBOL': 'Decimal Symbol',
  'SM.COMMON.TIPS.CHARGECODE_CODEINPUT': 'You can only input letters and numbers.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_SECONDMENT': 'Secondment',
  'SM.COMMON.LABEL.CURRENCY_STANDARD_MEASUREMENT': 'Standard Unit Flag',
  'SM.COMMON.LABEL.HOLIDAY_THURSDAY': 'Thu',
  'SM.COMMON.LABEL.SYSMENU_AUTHID': 'Menu Permission ID',
  'SM.ORGSTAFF.MESSAGE.REGION_CONFIRM_USEPARENT':
    'If the parent node data is used, the current time zone configuration will be cleared. Are you sure you want to continue?',
  'SM.ORGSTAFF.TITLE.CONTACT_TYPE': 'Contact Type',
  'SM.ORGSTAFF.TITLE.BE_PRECISION': 'Currency Precision',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_SET_ROLE': 'Set Role',
  'SM.ORGSTAFF.MESSAGE.EMPLOYEE_SECONDMENT_CONFIRM': 'Are you sure you want to terminate?',
  'SM.ORGSTAFF.LABEL.ORG_BASICINFO': 'Basic Info',
  'SM.COMMON.LABEL.MEASUREMENT_UNIT_OPERARION': 'Operation',
  'SM.ORGSTAFF.LABEL.BANK_TYPE': 'Bank Type',
  'SM.COMMON.BUTTON.SURVEY_DELETE_ANSWER_OPTIONS': 'Delete Answer Options',
  'SM.COMMON.MESSAGE.SYSMENU_DEACTIVATED_NOT_SUPPORT':
    'Status for the deactivated menu does not support permission management.',
  'SM.ORGSTAFF.TIPS.RESTRICTIP': 'Input an IP address in the format xx.* or xx.xx.* or xx.xx.xx.*.',
  'SM.ORGSTAFF.TITLE.CONTACT_EMAIL': 'Email',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_SECONDMENT_ENDDATE': 'End Date',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_SEARCH_CRITERIA_BE': 'BE',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_VIEWFILE': 'View',
  'SM.ORGSTAFF.MESSAGE.ORG_PARENT_FORBID': 'The parent OU has been suspended. ',
  'SM.COMMON.LABEL.ATTR_LANGUAGE': 'Language',
  'SM.ORGSTAFF.LABEL.TEAM_ASSIGN': 'A maximum of 2000 employees can be assigned.',
  'SM.COMMON.LABEL.ATTACHMENT_DEL_ACCESSORY': 'Delete',
  'SM.ORGSTAFF.LABEL.SEARCH_ALL': 'Search All Staff',
  'SM.ORGSTAFF.LABEL.ADDR_DISPLAY_ORDER': 'Incorrect address display sequence.',
  'SM.COMMON.LABEL.MEASUREMENT_DESTMEAUNIT': 'Target Measurement Unit',
  'SM.ORGSTAFF.MESSAGE.CHOOSE_SELECTED_OU_FIRST':
    'Please select a node in the Organizational Relations tree first.',
  'SM.ORGSTAFF.MESSAGE.STAFF_AUTHCOPY_SOURCENO_EQUALTOTARGET_ERROR':
    'The source account cannot be contained in the target account.',
  'SM.ORGSTAFF.TITLE.ROLE_BASIC': 'Basic Role',
  'SM.ORGSTAFF.LABEL.STAFF_SESSION_VALIDITY_PERIOD': 'Session Validity Period(Min)',
  'SM.COMMON.LABEL.CURRENCY_MEASUREMENT': 'Currency Unit',
  'SM.PROJECT.TITLE.EXPORT_CURRENT_ORG_EMPLOYEE': 'Export ALL',
  'SM.ORGSTAFF.LABEL.DEPTMACRESTRICT': 'MAC Address Restriction',
  'SM.ORGSTAFF.LABEL.REGION_DAYLIGHTSAVINGTIME': 'DST',
  'SM.ORGSTAFF.MESSAGE.BE_CANNOTDELETECURRENCY': 'The default currency cannot be deleted.',
  'SM.ORGSTAFF.TITLE.CONTACT_FULL_NAME': 'Full Name',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_VST': 'Session Timeout Type',
  'SM.COMMON.LABEL.CURRENCY_MEASUREMENT_LIST': 'Currency Measurement Unit',
  'SM.COMMON.LABEL.MEASUREMENT_CONV_LIST': 'Measurement Conversion List',
  'SM.COMMON.MESSAGE.VALIDATE_MINLENGTHB': 'Input at least 0 characters.',
  'SM.ORGSTAFF.LABEL.REGION_DESCRIPTION': 'Description',
  'SM.COMMON.LABEL.BANKCARD_ACCT_LENG_PARAM': 'Card Number Length',
  'SM.COMMON.LABEL.SURVEY_CLASS_SORT': 'Classification Sequence',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_IP_TYPE': 'IP Type',
  'SM.COMMON.LABEL.CURRENCY_DISPFORMAT_DISPLAYTYPE': 'Display Type',
  'SM.COMMON.LABEL.SYSMENU_CONFIGURESYSMENU': 'Configure System Menu',
  'SM.ORGSTAFF.LABEL.TEAM_ORG_EMPLOYEE': 'Organize Employees',
  'SM.ORGSTAFF.LABEL.REGION_DST_ENDDATE': 'DST End Date',
  'SM.ORGSTAFF.LABEL.ORG_STOP_TIPS':
    'After been suspended, organization or organization and its subordinate organization would be unavailable. Are you confirm to suspend?',
  'SM.ORGSTAFF.TITLE.OPPAUTH_TITLE_MUTEXAUTH_INFOLIST': 'Exclusive Permission List',
  'SM.COMMON.LABEL.ATTACHMENT_DELETE_INFO': 'Are you sure to delete the file ?',
  'SM.ORGSTAFF.OPTION.STAFF_REMOVE_PERMISSION': 'Remove permission of target operator',
  'SM.COMMON.LABEL.MEASUREMENT_EXPORT_SUCCESS': 'SUCCESS',
  'SM.ORGSTAFF.TITLE.CONTACT_TITLE': 'Title',
  'SM.ORGSTAFF.BUTTON.EMPLOYEE_CANCLE': 'Cancel',
  'SM.ORGSTAFF.LABEL.REGION_DST_SWITCHINTERVAL': 'DST Offset',
  'SM.COMMON.LABEL.SYSMENU_AFFECTED_MENU': 'Affected menu',
  'SM.ORGSTAFF.MESSAGE.ENDEARLIERSTART': 'The start date can not be later than the end date.',
  'SM.COMMON.LABEL.MEASUREMENT_UPLOAD_FILE': 'Upload File',
  'SM.COMMON.LABEL.SYSMENU_USEPARENT': 'Use Parent Data',
  'SM.COMMON.LABEL.HOLIDAY_FRIDAY': 'Fri',
  'SM.COMMON.LABEL.EDIT_CURRENCY_EXCHANGE_RATE': 'Modify Exchange Rate',
  'SM.COMMON.LABEL.CURRENCY_TYPE_SYMBOL': 'Symbol',
  'SM.COMMON.LABEL.SYSMENU_MENUNAME': 'Menu Name',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_UPLOAD_SIZE_MAX':
    'The uploaded file exceeded the maximum size 0 KB. ',
  'SM.COMMON.LABEL.SURVEY_ITEM_NAME': 'Item Name',
  'SM.COMMON.TIPS.POPUP_INFO_TITLE': 'Information',
  'SM.COMMON.MESSAGE.ERATE_NUMBER_INVALID': 'Invalid number',
  'SM.COMMON.LABEL.CHARGECODE_STATUS': 'Status',
  'SM.COMMOM.MESSAGE.SYSMENU_MENU_STATUS_NOT_CONSISTENT': 'Menu status is not consistent.',
  'SM.COMMON.LABEL.SURVEY_QUESTIONNAIRE_HEAD': 'Questionnaire Head',
  'SM.COMMON.LABEL.MODULE_SHORTNAME': 'Short Name',
  'SM.ORGSTAFF.LABEL.REGION_DST_MONTHDAY': 'Month/Day',
  'SM.TENANTSPACE.AUTHTYPE.TITLE': 'Authentication Type',
  'SM.ORGSTAFF.MESSAGE.DSTOFFSET.OUTRANAGE': 'The DST Offset is not in the range from 1 to 86400.',
  'SM.ORGSTAFF.LABEL.WORKGROUP_CONTENTS': 'Workgroup Path Contents',
  'SM.ORGSTAFF.MESSAGE.SELECT_EMPLOYEE': 'Please select employee',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_NEW_PASSWORD': 'new password',
  'SM.COMMON.MESSAGE.EXPORT_VIEW':
    ', please go to the "System management > Project-based Configuration > Project" menu to view the results exported.',
  'SM.ORGSTAFF.LABEL.TEAM_TEAMLEVER': 'Team Level',
  'SM.ORGSTAFF.LABEL.ROLE_TYPE_PUB': 'Public Role',
  'SM.ORGSTAFF.TITLE.BE_CURRENCY': 'Currency',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_QUERY_LIST': 'Employee List',
  'SM.COMMON.BUTTON.MEASUREMENT_ADD': 'New',
  'SM.COMMON.LABEL.SEARCH_NAME': 'Please input name',
  'SM.COMMON.LABEL.HOLIDAY_HOLIDAYDAY': 'Holiday',
  'SM.ORGSTAFF.MESSAGE.COMMON_TIPS_STAFF_SELECT_NO_PERIOD': 'Select Employee',
  'SM.ORGSTAFF.TITLE.ASSIGNED_MANAGER': 'Assigned Managers',
  'SM.TENANTSPACE.LDAP_RDNPREFIX': 'RDN Prefix',
  'SM.ORGSTAFF.MESSAGE.EXPORT_LIMIT':
    'The number of exported more than 10,000 is not recommended for export.',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_BE': 'BE',
  'SM.COMMON.LABEL.OPERLOG_ENDTIME': 'End Time',
  'SM.COMMON.LABEL.CURRENCY_TYPE_CODE': 'Code',
  'SM.ORGSTAFF.BUTTON.DELETE': 'Delete',
  'SM.COMMON.BUTTON.OK': 'OK',
  'SM.ORGSTAFF.BUTTON.ADDR_ADD': 'Add',
  'SM.COMMON.LABEL.CHARGECODE_CODE': 'Code',
  'SM.ORGSTAFF.BUTTON.ADDR_CREATE': 'New',
  'SM.COMMON.TITLE.SYSMENU_ERROR': 'Error',
  'SM.COMMON.LABEL.SYSMENU_DEACTIVATED': 'Discard',
  'SM.COMMON.LABEL.SURVEY_APPLICATION_SCOPE': 'Application Scope',
  'SM.COMMON.LABEL.SURVEY_OPTION_NAME': 'Options Name',
  'SM.ORGSTAFF.LABEL.ORG_SHORT_NAME': 'OU Short Name',
  'SM.ORGSTAFF.BUTTON.EMPLOYEE_CLEAR': 'Clear',
  'SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE_CREATE': 'New Questionnaire',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_DESC': 'Description',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ROAM_VALID': 'Valid',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_EMAIL_VALIDATOR': 'Invalid email',
  'SM.COMMON.LABEL.CHARGECODE_TEST': 'Test',
  'SM.COMMON.LABEL.SURVEY_CREATED_TIME': 'Created At',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ACCOUNTRANGE': 'Account Range',
  'SM.ORGSTAFF.LABEL.REGION_DST_MONTHWEEKDAY': 'Month/Week/Day',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_UPLOAD_EXT_TITLE':
    'Upload failed. Allowed file formats: 0; File formats not allowed: {1}.',
  'SM.ORGSTAFF.LABEL.TEAM_STATUS': 'Status',
  'SM.ORGSTAFF.MESSAGE.ERROR_QUERY': 'Failed to query.',
  'SM.COMMON.LABEL.BANKCARD_BANKTYPE_PARAM': 'Bank Type',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_USERNAME_VALIDATOR':
    'The user name contains invalid special characters.',
  'SM.COMMON.LABEL.CHARGECODE': 'Charge Code',
  'SM.ORGSTAFF.TITLE.ORG_ASSIGNED_EMP': 'Assigned Employees',
  'SM.ORGSTAFF.MESSAGE.SAVE_SUGGESTION': 'Information',
  'SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE_EDIT': 'Modify Questionnaire',
  'SM.COMMON.MESSAGE.CHARGECODE_PERFORM_DELETION': 'Are you sure you want to delete?',
  'SM.COMMON.BUTTON.CHARGECODE_ADD': 'New',
  'SM.COMMON.MESSAGE.ERATE_CODE_COMPARE':
    'The source currency code cannot be the same as the target currency code.',
  'SM.ORGSTAFF.LABEL.BANK_LEVEL': 'Bank Level',
  'SM.ORGSTAFF.LABEL.RESTRICTTYPE': 'Sign-In Restriction Type',
  'SM.COMMON.LABEL.HOLIDAY_WEDNESDAY': 'Wed',
  'SM.ORGSTAFF.TITLE.CONTACT_PHONE': 'Phone Number',
  'SM.ORGSTAFF.LABEL.BE_ADDRESS_FORMAT': 'Address Format Template',
  'SM.ORGSTAFF.LABEL.ORG_DISPLAYORDER': 'Display Sequence',
  'SM.ORGSTAFF.LABEL.PWD_UNMATCH_RULE': 'The password does not comply with the rules.',
  'SM.COMMON.LABEL.SYSMENU_LABEL': 'System Menu',
  'SM.COMMON.MESSAGE.AREA_DELETE_INFO': 'Are you sure you want to delete?',
  'SM.ORGSTAFF.TITLE.STAFF_ROLE_ASSIGN': 'Assign Role',
  'SM.ORGSTAFF.OPTION.WEEKDAY_FRI': 'Friday',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_RESETPWD': 'Reset Password',
  'SM.COMMON.LABEL.MODULE_MODULE': 'Module',
  'SM.ORGSTAFF.TITLE.STAFF_LOGIN_RESTRICT': 'Sign-In Restriction',
  'SM.COMMON.LABEL.MODULE_ADDRESS': 'URL',
  'SM.ORGSTAFF.TITLE.CURRENCY_DISPLAY_FORMAT': 'Currency Display Format',
  'SM.COMMON.LABEL.MEASUREMENT_IMPORT_RESULT': 'Import Result',
  'SM.ORGSTAFF.OPTION.STAFF_IP_SEGMENT': 'IP Address Segment',
  'SM.COMMON.TITLE.ACCESS.SELECT_STAFF': 'Select Access Employees',
  'SM.COMMON.MESSAGE.SYSMENU_ACCESS_TIME_ERROR':
    'Menu access time end time should larger than start time.',
  'SM.ORGSTAFF.MESSAGE.CONTAINS_SUB_ORGS': 'The organization contains sub-organizations.',
  'SM.ORGSTAFF.TITLE.ORG_ACCESS_EMP': 'Access Employee Assigned',
  'SM.COMMON.LABEL.BANKCARD_ACCT_LENG': 'Card Number Length',
  'SM.COMMON.LABEL.SYSMENU_VISITCOUNT': 'Visit Count',
  'SM.ORGSTAFF.BUTTON.OURELATIONSHIP_RELOAD': 'Refresh',
  'SM.COMMON.LABEL.OPERLOG_ACCOUNT': 'Account',
  'SM.COMMON.LABEL.BANKCARD_SEARCH_CONDITION': 'Search Condition',
  'SM.COMMON.LABEL.ATTACHMENT_ADD_ACCESSORY': 'New',
  'SM.COMMON.MESSAGE.CHARGECODE_TO_TEST': 'Are you sure you want to change it to the Test state?',
  'SM.COMMON.LABEL.SYSMENU_MENUURL': 'Menu URL',
  'SM.ORGSTAFF.AUTHTYPE.LABEL': 'Authentication type',
  'SM.COMMON.LABEL.ERATE_TODATE': 'to',
  'SM.COMMON.TIPS.HOME_PHONE': 'Please input correct phone number, eg. (+)(0123-)1234567890(-0123)',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_TEAMINFO': 'Team Info',
  'SM.COMMON.LABEL.CURRENCY_MEASUREMENT_TITLE': 'Currency Measurement Unit',
  'SM.COMMON.LABEL.SURVEY_CLASS_Code': 'Classification Code',
  'SM.COMMON.LABEL.SEARCHCONDITION': 'Search Criteria',
  'SM.ORGSTAFF.MESSAGE.SAVESUCCESS': 'Operation performed successfully.',
  'SM.TENANTSPACE.LDAP_DOMAIN': 'domain',
  'SM.ORGSTAFF.LABEL.ORGANIZATION': 'Organization Unit',
  'SM.COMMON.BUTTON.ADD_MENU_ACCESS_TIME':
    'Add menu access time. If not config, the menu can be accessed any time.',
  'SM.AUC.LABEL.BANK_NAME_INPUT': 'Input bank name',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_RESET_SUCCESS':
    'The employee password is changed successfully. The following fail to be changed:',
  'SM.ORGSTAFF.LABEL.ORGIMPORT_TIP':
    'Ensure that the carrier information has been imported to the system and the current operator is the administrator. ',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ACCOUNT_VALIDATOR_ADD':
    "The value can contain only digits, letters, underscores (_), hyphen (-), periods (.), and at signs ({'@'}).",
  'SM.COMMON.LABEL.MENU_INFO_TITLE': 'Menu Basic Information',
  'SM.COMMON.LABEL.HOLIDAY_YEAR': 'Year',
  'SM.COMMON.MESSAGE.DELETE_CONFIRM': 'Are you sure you want to delete?',
  'SM.COMMON.LABEL.SYSMENU_ACTIVE_A': 'activate',
  'SM.COMMON.LABEL.HOLIDAY_SATURDAY': 'Sat',
  'SM.COMMON.BUTTON.SURVEY_ENABLE': 'Enabled',
  'SM.ORGSTAFF.TITLE.OPERATION': 'Operation',
  'SM.ORGSTAFF.MESSAGE.FORBID_EXSIT_EMPLOYEE':
    'Failed to disable the department permanently because there are employees under the department.',
  'SM.ORGSTAFF.LABEL.AUTHVIEW_AUTH_BENAME': "Permission's Home BE",
  'SM.COMMON.TIPS.MENU_REBUILD': 'Information',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_SUSPEND': 'Suspend',
  'SM.ORGSTAFF.LABEL.REGION_SHORTNAME': 'Region Short Name',
  'SM.ORGSTAFF.LABEL.STAFF_ADD_ROLE': 'Add Relation Role',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_ATTACHMENTID': 'Attachment',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_NOT_ALLOW_4A': 'Allow Web Page Sign-In Directly',
  'SM.COMMON.LABEL.CURRENCY_TYPE_NAME': 'Name',
  'SM.ORGSTAFF.OPTION.STAFF_RESTRICTIONTYPE_ALLOWED': 'Allow',
  'SM.COMMON.LABEL.OPERLOG_RESET': 'Reset',
  'SM.COMMON.LABEL.CHARGECODE_NAME': 'Name',
  'SM.ORGSTAFF.LABEL.STAFF_DESC': 'Description',
  'SM.ORGSTAFF.LABEL.TEAM_DESCRIPTION': 'Description',
  'SM.COMMON.MESSAGE.DATADICT_DELETE_FAILED': 'Deletion failed. ',
  'SM.COMMON.LABEL.BANKCARD_NAME': 'Card Name',
  'SM.COMMON.BUTTON.CANCEL': 'Cancel',
  'SM.COMMON.LABEL.ATTACHMENT_DEL_SUCCESS': 'Accessory file deleted successfully',
  'SM.ORGSTAFF.LABEL.TO_BE_ASSIGNED_ROLE': 'To Be Assigned Role',
  'SM.COMMON.LABEL.SYSMENU_STATUS': 'Status',
  'SM.ORGSTAFF.OPTION.STAFF_RETAIN_PERMISSION': 'Retain permission of target operator',
  'SM.COMMON.LABEL.MEASUREMENT_ENTITY_ID': 'ID',
  'SM.COMMON.LABEL.MEASUREMENT_UNIT_SUNIT': 'Standard Unit',
  'SM.COMMON.MESSAGE.PROPERTY_SUCCESS_CREATE_INFO': 'Operation performed successfully.',
  'SM.ORGSTAFF.BUTTON.TEAM_OK': 'OK',
  'SM.COMMON.MESSAGE.CHARGECODE_DELETE_DATA': 'Are you sure you want to delete the charge code?',
  'SM.COMMON.MESSAGE.VALIDATE_RANGELENGTHB': 'Input a value of 0 to 1 characters.',
  'SM.COMMON.LABEL.OPERLOG_OBJECT_TYPE': 'Object Type',
  'SM.COMMON.BUTTON.BANKCARD_CANCEL': 'Cancel',
  'SM.ORGSTAFF.TITLE.BE_ADDRESSSETTING': 'Address',
  'SM.ORGSTAFF.LABEL.ORG_EMPLOYEE_QUERY_INFO': 'Search Criteria',
  'SM.COMMON.LABEL.MEASUREMENT_EXPORT_MEA': 'Measure',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_UPLODATPERSON': 'Uploaded By',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_SEARCH': 'Search',
  'SM.COMMON.BUTTON.MENUVSEARCH': 'Recreate Menu Index',
  'SM.ORGSTAFF.TITLE.CONTACT_HOME_FAX': 'Home Fax',
  'SM.COMMON.LABEL.ERATE_DCURRENCY_CODE': 'Target Currency Code',
  'SM.COMMON.LABEL.CREATE_OPER': 'Created By',
  'SM.COMMON.LABEL.CURRENCY_DISPLAY_POSTFIX': 'Display Postfix',
  'SM.ORGSTAFF.LABEL.STAFF_ROLE_DESC': 'Description',
  'SM.ORGSTAFF.TITLE.ADDRESS_DISPLAY_FORMAT': 'Address Display Format',
  'SM.ORGSTAFF.TITLE.ADDR_NAME': 'Name',
  'SM.COMMON.MESSAGE.SYSMENU_DELSUCCESS': 'Deleted successfully.',
  'SM.COMMON.LABEL.MEASUREMENT_ENTITY_CODE': 'Unit Code',
  'SM.ORGSTAFF.LABEL.INPUT_ROLE_NAME': 'Please input the role name',
  'SM.ORGSTAFF.TIPS.DESC': 'Do not input special characters <>',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD': 'Failed to verify the employee password.',
  'SM.ORGSTAFF.TITLE.CONTACT_OFFICE_FAX': 'Office Fax',
  'SM.COMMON.LABEL.CURRENCY_FORMAT_DISPLAY': 'Preview Format',
  'SM.ORGSTAFF.AUTHTYPE.LDAP': 'LDAP',
  'SM.PROJECT.TITLE.IMPORT_ORG_PAR': 'Import Par Package',
  'SM.ORGSTAFF.MESSAGE.DSTFLAG_WITHOUT_TIMEZONE': "No time zone can't open daylight saving time.",
  'SM.COMMON.LABEL.MEASUREMENT_CONV_EDIT': 'Modify Measurement Unit Conversion',
  'SM.ORGSTAFF.BUTTON.BE_COPYPARENT': 'Copy Parent Node Data',
  'SM.ORGSTAFF.LABEL.ORG_FORBID': 'Disable',
  'SM.ORGSTAFF.LABEL.STAFF_ROLE_TYPE': 'Role Type',
  'SM.COMMON.LABEL.CURRENCY_MEASUREMENT_ID': 'ID',
  'SM.COMMON.LABEL.OPERLOG_STARTTIME': 'Start Time',
  'SM.ORGSTAFF.MESSAGE.REGION_VALIDATE_TIMESAME':
    'The start time and end time of a time zone cannot be the same.',
  'SM.COMMON.LABEL.HOLIDAY_SUNDAY': 'Sun',
  'SM.COMMON.TIPS.ERATE_ERATE_TIPS':
    'Input a positive numeric string with a maximum of 13 integral places and 6 decimal places and the last cannot be 0.',
  'SM.ORGSTAFF.LABEL.REGION_CODE': 'Region Code',
  'SM.ORGSTAFF.TITLE.REGION_ADMINREGION': 'Administrative Region',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_NATIONALITY': 'Nationality',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_VALUE_TYPE': 'Value Type',
  'SM.COMMON.MESSAGE.OPERATESUCCESS': 'Operation performed successfully.',
  'SM.ORGSTAFF.TITLE.SUCCESS': 'Information',
  'SM.COMMON.LABEL.MEASUREMENT_UNIT_RATIO': 'Measurement Unit Ratio',
  'SM.COMMON.TITLE.SURVEY_QUESTION_NAME_INFO': 'Question Name Info',
  'SM.COMMON.BUTTON.RESET': 'Reset',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ACCOUNT_PASSWORD': 'the current password ',
  'SM.COMMON.BUTTON.ADD_ADD': 'Add',
  'SM.COMMON.LABEL.ATTACHMENT_SAVE_SUCCESS': 'Attachment added successfully. ',
  'SM.ORGSTAFF.TITLE.TEAM_NAMEEXIST': 'The team name already exists.',
  'SM.ORGSTAFF.LABEL.TEAM_ACCOUNTRANGE': 'Account Range',
  'SM.COMMON.BUTTON.CHARGECODE_SAVE': 'Save',
  'SM.COMMON.LABEL.OPERLOG_EMPLOYEE_NAME_NORMAL': 'Employee Name',
  'SM.COMMON.LABEL.BANKCARD_BANKCODE': 'Card Issuing Bank Code',
  'SM.COMMON.LABEL.SURVEY_INTERLOCUTION': 'Q&A',
  'SM.ORGSTAFF.LABEL.ORG_IMPORT': 'Import',
  'SM.COMMON.BUTTON.MEASUREMENT_DELETE': 'Delete',
  'SM.COMMON.MESSAGE.CHARGECODE_TO_RELEASE':
    'Are you sure you want to change it to the Release state?',
  'SM.COMMON.LABEL.CHARGECODE_FORBIDEN_BE': 'Forbidden BE',
  'SM.COMMON.MESSAGE.OPERATE_FAIL': 'Operation failed.',
  'SM.COMMON.MESSAGE.CURRENCY_MEASUREMNET_CONFIRMCOVERMSG':
    'The current currency type already has a standard currency unit or minimum currency unit. Are you sure you want to continue?',
  'SM.ORGSTAFF.TITLE.RELATION_CONFIRM_RECOVER':
    'After been resumed, the organizational relationship and its subordinate relationship will be available，Are you confirm to resume?',
  'SM.ORGSTAFF.LABEL.ACCESS.USER_REQUIRED': 'Please select an employee.',
  'SM.ORGSTAFF.TITLE.REGION_IPRANGE': 'IP Segment',
  'SM.COMMON.LABEL.SURVEY_CLASS_SORT_DESCRIPTION': 'The input range is 1-100',
  'SM.ORGSTAFF.BUTTON.REMOVE_OUT': 'Remove Out',
  'SM.ORGSTAFF.TITLE.ADDR_DESCRIPTION': 'Description',
  'SM.ORGSTAFF.TIPS.LOGINID': 'Do not input special characters except @.',
  'SM.ORGSTAFF.TITLE.COMMON_QUERYCONDITION_TITLE': 'Search Criteria',
  'SM.ORGSTAFF.LABEL.ORG_SELECTREGION': 'Select Region',
  'SM.ORGSTAFF.MESSAGE.ERROR.SAVE': 'Error',
  'SM.ORGSTAFF.MESSAGE.BE_SELECTREGION': 'Select Region',
  'SM.ORGSTAFF.MESSAGE.BE_MAKE_SURE_DELETE': 'Are you sure you want to delete the member?',
  'SM.ORGSTAFF.MESSAGE.ADDR_DELETE_CONFIRM': 'Are you sure you want to delete?',
  'SM.ORGSTAFF.MESSAGE.REGION_VALIDATE_IPEXIST': 'The IP address is duplicate.',
  'SM.COMMON.TITLE.SUCCESS': 'Information',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_IS_LOCKED': 'The employee has been locked.',
  'SM.ORGSTAFF.TITLE.ASSIGNE_EMPLOYEE': 'Assign Employee',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PWDRULEGROUP': 'Account and Password Rule Group',
  'SM.COMMON.BUTTON.MEASUREMENT_EXPORT': 'Export',
  'SM.ORGSTAFF.BUTTON.EMPLOYEE_SELECTORG': 'Select OU',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_ATTACHMENTDESC': 'Description',
  'SM.ORGSTAFF.TITLE.IMPORT_MAC_TITLE': 'Import Sign-In Restriction',
  'SM.ORGSTAFF.OPTION.WEEKLIST_FTH': 'Fourth',
  'SM.COMMON.MESSAGE.SAVESUCCESS': 'Operation performed successfully.',
  'SM.ORGSTAFF.LABEL.TEAM_EMPLOYEE': 'Employee',
  'SM.ORGSTAFF.BUTTON.FORBID_FOREVER': 'Disable Permanently',
  'SM.COMMON.LABEL.CURRENCY_GROUPSIZE': 'Group Size',
  'SM.COMMON.LABEL.OPERLOG_OBJECT_ID': 'Object ID',
  'SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE_TEMP': 'Questionnaire',
  'SM.COMMON.LABEL.OPERLOG_RESULT': 'Operation Result',
  'SM.COMMON.MESSAGE.OPERATEFAIL': 'Operation failed.',
  'SM.ORGSTAFF.MESSAGE.BE_SUBBE':
    'Lower-level BEs, OUs, teams, or roles have been configured for the BE. ',
  'SM.ORGSTAFF.MESSAGE.STAFF_INFOAUTH_CONFIRM':
    'Please confirm that the entered employee information has been authorized by me.',
  'SM.ORGSTAFF.TITLE.RELATION_CONFIRM_SUSPEND':
    'After been suspended, the organizational relationship and its subordinate relationship will not be available, Are you confirm to suspend?',
  'SM.COMMON.BUTTON.SURVEY_DELETE_QUESTION': 'Delete Question',
  'SM.ORGSTAFF.LABEL.STAFF_LOCALE': 'Default Language',
  'SM.COMMON.LABEL.SURVEY_QUESTIONNAIRE_STATUS': 'Status',
  'SM.ORGSTAFF.MESSAGE.SRC_ACCOUNT': 'The source account does not exist.',
  'SM.ORGSTAFF.MESSAGE.CONTACT_ACCOUNT': 'Account',
  'SM.COMMON.LABEL.ACCESS_RELATION': 'Accessable Organizations',
  'SM.COMMON.TIPS.SPECIAL_CHAR_AVAILABLE': 'The input value must be digit, alpha or _.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_CREATE_FAILD':
    'Failed to create the employee. Please confirm the account information.',
  'SM.ORGSTAFF.OPTION.WEEKDAY_TUE': 'Tuesday',
  'SM.ORGSTAFF.BUTTON.CANCEL': 'Cancel',
  'SM.COMMON.BUTTON.BANKCARD_SAVE': 'Save',
  'SM.COMMON.TITLE.ERROR': 'Error',
  'SM.ORGSTAFF.LABEL.REGION_DST_STARTDATE': 'DST Start Date',
  'SM.COMMON.LABEL.AREANAME': 'Area Name',
  'SM.ORGSTAFF.LABEL.TEAM_TEAMCODE': 'Team Code',
  'SM.COMMON.LABEL.MEASUREMENT_ENTITY_NAME': 'Unit Name',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ROAM_ORG': 'Roam Organization',
  'SM.COMMON.LABEL.BANKCARD_BANKCODE_PARAM': 'Card Issuing Bank Code',
  'SM.ORGSTAFF.TITLE.CONTACT_MOBILE_PHONE': 'Mobile Number',
  'SM.ORGSTAFF.LABEL.ORG_CHANNEL_CLASS': 'Channel Classification',
  'SM.COMMON.BUTTON.TOP': 'To Top',
  'SM.AUC.LABEL.BANK_LIST': 'Bank List',
  'SM.COMMON.TITLE.MEASUREMENT_STDLIST': 'Measurement System List',
  'SM.COMMON.LABEL.ERATE_RANGEDATE': 'Validity Period',
  'SM.COMMON.LABEL.BANKCARD_OPERATION': 'Operation',
  'SM.ORGSTAFF.TITLE.BE_INFORMATION': 'Information',
  'SM.ORGSTAFF.OPTION.MONTH_12': 'December',
  'SM.ORGSTAFF.OPTION.MONTH_11': 'November',
  'SM.ORGSTAFF.MESSAGE.TIMEZONE_TIME_ERROR':
    'The starting time can not be greater than or equal to the end time.',
  'SM.ORGSTAFF.LABEL.REGION_LAYER': 'Region Layer',
  'SM.ORGSTAFF.OPTION.MONTH_10': 'October',
  'SM.ORGSTAFF.OPTION.WEEKDAY_SAT': 'Saturday',
  'SM.ORGSTAFF.LABEL.REGION_NAME': 'Region Name',
  'SM.COMMON.MESSAGE.SYSMENU_CONFIRM_MODIFY_MENU_STATUS_TO': 'Are you sure you want to:',
  'SM.COMMON.LABEL.BANKCARD_BANKSIGN_LENG_PARAM': 'Card Issuing Bank ID Length',
  'SM.COMMON.BUTTON.CHARGECODE_EDIT': 'Modify',
  'SM.ORGSTAFF.LABEL.BE_ADDRESS_DISPLAYFORMAT': 'Address Display Format Template',
  'SM.COMMON.LABEL.HOLIDAY': 'Holiday',
  'SM.ORGSTAFF.LABEL.ORG_CODE': 'OU Code',
  'SM.ORGSTAFF.LABEL.USER_COUNT': 'User Count',
  'SM.COMMON.MESSAGE.MEASUREMENT_STANDARD_ERROR': 'Please choose correct standard measurement.',
  'SM.COMMON.TIPS.DIGITS_ONLY': 'Please input digits.',
  'SM.ORGSTAFF.TITLE.ADDR_LINE_NUMBER': 'Line Number',
  'SM.COMMON.LABEL.BANKCARD_BANKNAME': 'Card Issuing Bank Name',
  'SM.ORGSTAFF.MESSAGE.ADDR_DELETE_ERROR': 'Deletion error.',
  'SM.ORGSTAFF.TITLE.REGION_TIMEZONE': 'Time Zone',
  'SM.COMMON.LABEL.OPERLOG_FAILUER': 'Failure',
  'SM.COMMON.LABEL.HIGHRISKLOG': 'High-Risk Operation Monitoring',
  'SM.COMMON.LABEL.CURRENCY_MEASUREMENT_NAME': 'Unit',
  'SM.ORGSTAFF.TITLE.CONTACT': 'Contact',
  'SM.ORGSTAFF.LABEL.BE_CODE': 'BE Code',
  'SM.COMMON.LABEL.SURVEY_SINGAL_CHOOSE': 'Single choice',
  'SM.ORGSTAFF.LABEL.BE_SHORT_NAME': 'BE Short Name',
  'SM.ORGSTAFF.TITLE.CONTACT_ID': 'Contact ID',
  'SM.ORGSTAFF.LABEL.TEAM_CODE': 'OU Code',
  'SM.COMMON.MESSAGE.MODULE_MODULEADDRESSNOTMATCH': 'The module address does not match.',
  'SM.COMMON.LABEL.CHARGECODE_RELEASE': 'Release',
  'SM.COMMON.TITLE.SYSMENU_MENUNAME': 'Menu Name Info',
  'SM.COMMON.LABEL.SYSMENU_SELECTORGANIZATION': 'Select Permission',
  'SM.ORGSTAFF.LABEL.TEAM_SEARCH': 'Search',
  'SM.ORGSTAFF.TITLE.CHARGE_CODE': 'Charge Code',
  'SM.ORGSTAFF.LABEL.STAFF_ROLE_EFFECTIVE_OU_INPUT': 'Please input a OU name',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ACCOUNT': 'Account',
  'SM.PROJECT.TITLE.EXPORT_CURRENT_ORG_AND_SUB': 'Export Selected Item',
  'SM.COMMON.BUTTON.SURVEY_NEW_QUESTION_TYPE': 'New Question Type',
  'SM.ORGSTAFF.LABEL.BE_LANGUAGE': 'Language',
  'SM.COMMON.LABEL.SYSMENU_SEARCH_RESULT': 'Search Result',
  'SM.COMMON.LABEL.HEADING_SEARCHCRITERIA': 'Search Criteria',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_TIMERANGEERROR':
    'Sign-In End Time Must Later Then Sign-In Begin Time.MAC address binding',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_RESET_FAILD': 'employee password reset failed',
  'SM.ORGSTAFF.TITLE.STAFF_INFO_ADD': 'Add Employee',
  'SM.ORGSTAFF.OPTION.EMPLOYEE_DELETE_ROLE': 'Delete Role',
  'SM.ORGSTAFF.TITLE.ADDRESS_FORMAT': 'Address Format',
  'SM.ORGSTAFF.LABEL.FUNCAUTH_OPPAUTHNAME': 'Mutually-Exclusive Permission',
  'SM.COMMON.LABEL.CURRENCY_DECIMALPOSITIONS': 'Decimal Places',
  'SM.ORGSTAFF.BUTTON.INCREASE': 'Add',
  'SM.ORGSTAFF.LABEL.TEAM_PARENTORG': 'Upper-Level OU',
  'SM.ORGSTAFF.TITLE.NEW_ORG': 'Create OU',
  'SM.ORGSTAFF.TITLE.EDIT_ORG': 'Edit OU',
  'SM.ORGSTAFF.MESSAGE.INPUT_ORG_NAME': 'Enter an OU name',
  'SM.ORGSTAFF.TITLE.NEW_ACCESS_USER': 'Add Authorized Employees',
  'SM.ORGSTAFF.TITLE.NEW_MANAGER': 'Add Managers',
  'SM.ORGSTAFF.TITLE.USER_ACCOUNT': 'Employee Account',
  'SM.ORGSTAFF.MESSAGE.SEARCH_USERNAME': 'Enter an employee name',
  'SM.ORGSTAFF.TITLE.SELECT_ROLE': 'Select Roles',
  'SM.ORGSTAFF.MESSAGE.FOLLOW_TENANT': 'Follow the tenant configuration',
  'SM.ORGSTAFF.MESSAGE.SEARCH_ROLE_NAME': 'Enter a role name',
  'SM.ORGSTAFF.TIP.ACCESS_USER_DESC':
    'Employees who do not belong to the OU but are allowed to access and use resources of the OU',
  'SM.COMMON.BUTTON.CHARGECODE_SUCCESS': 'Information',
  'SM.ORGSTAFF.LABEL.TEAM_MANAGER': 'Manager',
  'SM.COMMON.LABEL.MEASUREMENT_EXPORT_COUNT': 'Export Quantity',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_BIRTH': 'Date of Birth',
  'SM.COMMON.LABEL.CREATE_TIME': 'Created At',
  'SM.ORGSTAFF.TITLE.COMMON_CONTACT_INFORMATION': 'Contact Info',
  'SM.ORGSTAFF.MESSAGE.STAFF_AUTHCOPY_PATTERN': 'Copy Mode',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_IP': 'IP Address',
  'SM.COMMON.LABEL.MEASUREMENT_NO': 'No',
  'SM.COMMON.TITLE.BANKCARD_ADD': 'New Bank Card',
  'SM.ORGSTAFF.MESSAGE.STAFF_RESUME_CONFIRM':
    'Are you sure that you want to resume the employee account?',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ONLINE_NAME': 'Employee',
  'SM.ORGSTAFF.MESSAGE.SELECT_REGION': 'Select a region.',
  'SM.ORGSTAFF.LABEL.DEPTIPRESTRICT': 'IP Restriction',
  'SM.ORGSTAFF.LABEL.INPUT_REGION_NAME': 'Please input the region name',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_MIDDLENAME': 'Middle Name',
  'SM.ORGSTAFF.TITLE.TEAM_OPERSUCCESS': 'Operation performed successfully.',
  'SM.COMMON.BUTTON.IMPORT': 'Import',
  'SM.ORGSTAFF.LABEL.TEAM_TEAMNAME': 'Team Name',
  'SM.ORGSTAFF.MESSAGE.BE_COPYCURRENCY': 'Are you sure you want to copy the parent node?',
  'SM.ORGSTAFF.TITLE.REGION_ADDRESS': 'Address',
  'SM.ORGSTAFF.LABEL.STAFFAUTH_AUTHSOURCE': 'Permission Source',
  'SM.ORGSTAFF.LABEL.BE_DESCRIPTION': 'Description',
  'SM.ORGSTAFF.LABEL.ORG_DELETE_CONFIRM':
    'After an organization is deleted, the corresponding account of the organization cannot be restored and can only be re-created. delete or not?',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_NAME_EXIST':
    'Failed to create the employee because the account already exists in the system.',
  'SM.COMMON.LABEL.BANKCARD_BANKSIGN': 'Card Issuing Bank ID',
  'SM.COMMON.BUTTON.SURVEY_DELETE_QUESTION_TYPE': 'Delete Question Type',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_RESET': 'Reset',
  'SM.COMMON.LABEL.NEW_CURRENCY_EXCHANGE_RATE': 'New Exchange Rate',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_TO': 'To',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_SAVE': 'Save',
  'SM.COMMON.LABEL.OPERLOG_SUCCESS': 'Success',
  'SM.COMMON.MESSAGE.DATADICT_DELETE_SUCCESSFULLY': 'Deleted successfully.',
  'SM.ORGSTAFF.LABEL.ORG_FORBID_TIPS': 'Are you sure you want to disable the organization?',
  'SM.COMMON.TIPS.AREA_DELETE_SUCCESS': 'Deleted Successfully.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_FIRSTNAME': 'First Name',
  'SM.ORGSTAFF.TITLE.ERROR': 'Error',
  'SM.ORGSTAFF.TITLE.REGION_ENDIP': 'End IP',
  'SM.ORGSTAFF.LABEL.ORG_NAME': 'OU Name',
  'SM.ORGSTAFF.LABEL.ORG_IMPORTDATA': 'Import Data',
  'SM.COMMON.LABEL.SURVEY_DRAFT': 'Draft',
  'SM.ORGSTAFF.MESSAGE.REGION_CONFIRM_NOTUSEPARENT':
    'If the parent node data is canceled, the configuration of the parent node will not be used. Continue?',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ORG': 'Owning Organization',
  'SM.ORGSTAFF.LABEL.BANK_CODE': 'Bank Code',
  'SM.ORGSTAFF.TITLE.CONTACT_OFFICE_PHONE': 'Office Phone',
  'SM.COMMON.BUTTON.ADD': 'New',
  'SM.COMMON.LABEL.THE_MENU': '?',
  'SM.ORGSTAFF.TIPS.UNLOCK.USER': 'Enter the password of the current operator for confirmation:',
  'SM.COMMON.MESSAGE.CURRENCY_DECIMALNOZEROFLAG': 'Remove Last Zeros',
  'SM.COMMON.LABEL.ERATE_EFFDATE': 'Effective Time',
  'SM.COMMON.LABEL.HOLIDAY_IMPORTDATA': 'Import Data',
  'SM.ORGSTAFF.LABEL.ONLINEOPERATORNUM': 'Total Online Operators',
  'SM.ORGSTAFF.LABEL.ORG_PARENTORG': 'Parent OU',
  'SM.COMMON.LABEL.BANKCARD_NAME_PARAM': 'Card Name',
  'SM.COMMON.LABEL.CHARGECODE_DRAFT': 'Draft',
  'SM.COMMON.LABEL.OPERLOG_OPER_TYPE': 'Operation Type',
  'SM.COMMON.TITLE.MEASUREMENT_INFO': 'Basic Info',
  'SM.ORGSTAFF.TITLE.REGION_STARTIP': 'Start IP',
  'SM.ORGSTAFF.LABEL.IMPORT_TEMPLATE': 'Import File Template',
  'SM.COMMON.LABEL.SURVEY_TITLE_NAME': 'Question Name',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_9': 'September',
  'SM.COMMON.LABEL.HOLIDAY_IMPORT': 'Import Holiday',
  'SM.TENANTSPACE.LDAP_BASICDN': 'Basic DN',
  'SM.TENANTSPACE.LDAP_TITLE': 'LDAP Authentication',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_8': 'August',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_7': 'July',
  'SM.ORGSTAFF.LABEL.BE_NAME': 'BE Name',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_6': 'June',
  'SM.COMMON.LABEL.CHARGECODE_SEARCH_CRITERIA': 'Search Criteria',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_5': 'May',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_4': 'April',
  'SM.ORGSTAFF.OPTION.WEEKLIST_SEC': 'Second',
  'SM.ORGSTAFF.LABEL.WORKGROUP_NAME': 'Workgroup Name',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_3': 'March',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_2': 'February',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_1': 'January',
  'SM.ORGSTAFF.LABEL.ORG_STOP': 'Suspend',
  'SM.COMMON.TIPS.CURRENCY_GROUPSIZE_TIPS':
    'Number of digits separated by separators in an amount. Value 0 indicates that the amount is not separated with separators.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_STATUS_NORMAL': 'Normal',
  'SM.COMMON.LABEL.BANKCARD_BANKNAME_PARAM': 'Card Issuing Bank Name',
  'SM.ORGSTAFF.TITLE.REGION_EX_ATTR': 'Extended Attributes',
  'SM.COMMON.TITLE.SUCCESS_SUCCESS': 'Operation performed successfully.',
  'SM.COMMON.LABEL.MEASUREMENT_CONV_CREATE': 'New Measurement Conversion',
  'SM.ORGSTAFF.LABEL.TEAM_TEAMID': 'Team ID',
  'SM.ORGSTAFF.BUTTON.ORG_EDIT': 'Modify',
  'SM.ORGSTAFF.TITLE.TEAM_ASSIGNEDEMPL':
    'Assigned Employees(Can not bind more than 2000 employees.)',
  'SM.ORGSTAFF.TITLE.STAFF_INFO_EXT': 'Extended Info',
  'SM.ORGSTAFF.TITLE.SELECT_ORG': 'Select Workgroup',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_BIRTHPLACE': 'Birthplace',
  'SM.ORGSTAFF.MESSAGE.REFRESH_SUCCESS': 'Refreshed successfully.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ACCOUNT_VALIDATOR':
    'The value can contain only digits, letters, Chinese characters, underscores (_), hyphen (-), periods (.), and at signs (@).',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_IP_ADDR': 'IP Address',
  'SM.ORGSTAFF.TITLE.TEAM_DELETEFSUCCESS': 'Deleted successfully.',
  'SM.ORGSTAFF.LABEL.ORG_INFO': 'OU Info',
  'SM.COMMON.TIPS.CHARGECODE_NAME': 'The input value cannot contain special characters.',
  'SM.COMMON.MESSAGE.SYSMENU_AUTH_ASSIGNED_MUTIL_MENUS':
    'The permission on the menu has been assigned to multiple menus. If you discard the menu, all the permissions will be revoked. Continue?',
  'SM.COMMON.LABEL.MEASUREMENT_IGNORE_IMPORT':
    'During batch file import, if a file fails to be imported, the import of subsequent files will continue. ',
  'SM.COMMON.TIPS.POPUP_LABEL_DELETE': 'Are you sure you want to delete?',
  'SM.ORGSTAFF.AUTHTYPE.DEFAULT': 'Default',
  'SM.ORGSTAFF.LABEL.BE_BIZCODE': 'BE Business Code',
  'SM.COMMON.LABEL.SYSMENU_DIS_POSTION': 'Display Position',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_STATUS_FORBID': 'Forbid',
  'SM.ORGSTAFF.LABEL.CREATESOURCE': 'Create Source',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_FORBIDFOREVER': 'Disable Permanently',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ONLINE_CLIENT': 'Client IP',
  'SM.COMMON.TITLE.MEASUREMENT_UNIT': 'Measurement Type',
  'SM.ORGSTAFF.TIPS.STAFF_LOCALE':
    'Employee default login language that is used when the language is not selected when logging in.',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_UPLOAD_SIZE_EXT_TITLE':
    'The size of a file to upload can range from {1} KB to 0 KB. {2} files are allowed but {3} files are not. ',
  'SM.SM.COMMON.TITLE.NO_CHARGECODE': 'No ChargeCode.',
  'SM.COMMON.BUTTON.ATTACHMENT_MGR_SEARCH_CRITERIA_QUERY': 'Search',
  'SM.ORGSTAFF.OPTION.WEEKLIST_FIV': 'Fifth',
  'SM.ORGSTAFF.LABEL.ATTR_ATTRSETTING': 'Extended Attributes',
  'SM.COMMON.LABEL.HIGHRISKLOG_SERVICENAME': 'Service Name',
  'SM.ORGSTAFF.MESSAGE.AUTHENTICATION': 'Verify Identity',
  'SM.COMMON.MESSAGE.DATADICT_SURE_DELETE': 'Are you sure you want to delete?',
  'SM.ORGSTAFF.OPTION.WEEKLIST_FIR': 'First',
  'SM.COMMON.BUTTON.COMMON_ADD': 'Add',
  'SM.COMMON.LABEL.SYS_MEASUREMENT_METRIC': 'Metric System Flag',
  'SM.ORGSTAFF.TIPS.BE_LOGO':
    'Select a PNG, GIF, or JPG image whose size does not exceed 50 KB and  145*50 pixels.',
  'SM.COMMON.BUTTON.CHARGECODE_ADDMEMBER': 'Add Charge Code',
  'SM.COMMON.LABEL.SURVEY_QUESTIONNAIRE_TAIL': 'Questionnaire Tail',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_MATCH_VALUE': 'Match Value',
  'SM.ORGSTAFF.LABEL.ORG_SUBTYPE': 'Sub-OU Type',
  'SM.ORGSTAFF.MESSAGE.SELECT_ORG': 'Select an OU.',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_SEARCH_CRITERIA': 'Search Criteria',
  'SM.COMMON.LABEL.SURVEY_ALLOW_INPUT': 'Allow manual input',
  'SM.ORGSTAFF.LABEL.BE_ID': 'BE ID',
  'SM.ORGSTAFF.LABEL.ROLE_TYPE_DUTY': 'Duty Role',
  'SM.COMMON.LABEL.SURVEY_MODIFIED_ACCOUNT': 'Modified By',
  'SM.ORGSTAFF.LABEL.TEAM_TDISPLAYORDER': 'Display Sequence',
  'SM.COMMON.LABEL.SYSMENU_LANGUAGE': 'Language',
  'SM.COMMON.LABEL.MENU_ACCESS_TIME': 'Menu Access Time',
  'SM.COMMON.LABEL.ERATE_INFO_LIST': 'Currency Exchange Rate List',
  'SM.ORGSTAFF.BUTTON.IMPORT': 'Import',
  'SM.COMMON.LABEL.MODULE_ID': 'ID',
  'SM.COMMON.LABEL.OPERLOG_OPER_TIME': 'Operation Time',
  'SM.COMMOM.MESSAGE.BESEXCEPTION':
    'The current operation cannot be completed. Contact the administrator.',
  'SM.COMMON.LABEL.SURVEY_PROVINCE': 'Provincial',
  'SM.ORGSTAFF.LABEL.STAFF_ACCOUNT': 'Account',
  'SM.ORGSTAFF.BUTTON.RESET_TEAM': 'Reset',
  'SM.ORGSTAFF.MESSAGE.USER_OWN_DUTY_ROLE_INVALID2':
    'which  is larger than max number, the max number is:',
  'SM.ORGSTAFF.MESSAGE.USER_OWN_DUTY_ROLE_INVALID1':
    'The number of employee own duty role of organization:',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_SEARCH_CRITERIA_ATTACHMENTCODE': 'Attachment Type',
  'SM.ORGSTAFF.LABEL.ORG_FORCEDELETE': 'Delete Permanently',
  'SM.ORGSTAFF.TITLE.CURRENCY_TYPE': 'Currency Type',
  'SM.COMMON.LABEL.HIGHRISKLOGList': 'High-Risk Operation Monitoring',
  'SM.COMMON.LABEL.SURVEY_TITLE_SORT': 'Question Sequence',
  'SM.ORGSTAFF.LABEL.BANK_NAME': 'Bank Name',
  'SM.COMMON.LABEL.CURRENCY_CREATE_TITLE': 'Currency Type Basic Information',
  'SM.ORGSTAFF.TIPS.REGION_DST_SWITCHINTERVAL_TIPS':
    'Unit: second. Range: 1 to 86400. If this parameter is left empty the default value 3600 is used.',
  'SM.COMMON.LABEL.SURVEY_MUTI_CHOOSE': 'Multiple choices',
  'SM.ORGSTAFF.OPTION.WEEKLIST_LST': 'Last',
  'SM.ORGSTAFF.TITLE.CURRENCY_EXCHANGE_RATE': 'Currency Exchange Rate',
  'SM.COMMON.LABEL.BANKCARD_BANKSIGN_LENG': 'Card Issuing Bank ID Length',
  'SM.COMMON.LABEL.CHARGE_CODE_GROUP': 'Charge Code Group',
  'SM.COMMON.LABEL.CHARGECODE_GROUPTYPE': 'Group Type',
  'SM.COMMON.BUTTON.PROPERTY_CREATE_GROUP': 'New Extended Attribute Groups',
  'SM.COMMON.MESSAGE.DATADICT_SAVE_SUCCESSFULLY': 'Operation performed successfully.',
  'SM.ORGSTAFF.TITLE.ADDR_CFG_LIST': 'Address Display Format',
  'SM.ORGSTAFF.OPTION.WEEKDAY_THU': 'Thursday',
  'SM.COMMON.LABEL.HOLIDAY_MONDAY': 'Mon',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ONLINE_SIGNTIME': 'Sign-In Time',
  'SM.COMMON.LABEL.OPERLOG_MODULE': 'Module',
  'SM.COMMON.LABEL.SURVEY_OPTION_TYPE': 'Option Type',
  'SM.ORGSTAFF.LABEL.ADDR_ADDRESS_NAME': 'Address Name',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_RESTRICT_MODE': 'Restrict Mode',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_TEAM': 'Work Team',
  'SM.ORGSTAFF.TITLE.CONTACT_INFO': 'Manager',
  'SM.COMMON.LABEL.OPERLOG_OBJECT_TYP_NAME': 'Object Type Name',
  'SM.COMMON.LABEL.SYSMENU_SELECT_MENUS': 'Selected Menu',
  'SM.COMMON.BUTTON.SURVEY_NEW_QUESTION': 'New Question',
  'SM.COMMON.LABEL.MENU_ACCESS_END_TIME': 'End Time',
  'SM.ORGSTAFF.LABEL.AFFILIATED_BE': 'Affiliated BE',
  'SM.COMMON.LABEL.AREAINFO_MAINTAIN': 'Area Code Management',
  'SM.ORGSTAFF.LABEL.REGION_LEVEL': 'Region Level',
  'SM.ORGSTAFF.MESSAGE.REGION_VALIDATE_IPFORMATERROR': 'Invalid IP address',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_GENDER': 'Gender',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_RESET_ALLSUCCESS': 'employee password reset success',
  'SM.ORGSTAFF.TIPS.ENTER_ORGNAME': 'Input the workgroup name.',
  'SM.COMMON.LABEL.SYSMENU_STATUSDATE': 'Status Date',
  'SM.COMMON.LABEL.CURRENCY_MEASUREMENT_RATE': 'Rate',
  'SM.ORGSTAFF.TITLE.CONFIRM_DEL': 'Confirm',
  'SM.COMMON.LABEL.COLON': ':',
  'SM.COMMON.BUTTON.ACCESS_RELATION': 'Add Access Organization',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_MARITALSTATUS': 'Marital Status',
  'SM.COMMON.BUTTON.PROPERTY_CREATE': 'New',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_STATUS_PAUSE': 'Deactive',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_SECONDMENT_STARTDATE': 'Start Date',
  'SM.COMMON.BUTTON.ATTACHMENT_ADDACCESSORY': 'Save',
  'SM.COMMON.LABEL.MEASUREMENT_SOUMEASYS': 'Source Measurement System',
  'SM.COMMON.LABEL.ERATE_EXPDATE': 'Expiration Time',
  'SM.ORGSTAFF.MESSAGE.DELETE_TITLE': 'Confirm',
  'SM.COMMON.TITLE.PRJ_EXPORT': 'Have Begun to Export',
  'SM.ORGSTAFF.LABEL.STAFF_AUTHCOPY_TARGETNO': 'Target Account',
  'SM.ORGSTAFF.TITLE.ERROR_TITLE': 'Error',
  'SM.COMMON.MESSAGE.ERATE_TIME_COMPARE': 'The end time must be later than the start time.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_RESET': 'employee password reset',
  'SM.ORGSTAFF.LABEL.ADDR_DISP_DETAIL_LIST': 'Format Details List',
  'SM.ORGSTAFF.MESSAGE.TEAM_BLANK_VIEW': 'Manage teams in the OU tree.',
  'SM.COMMON.LABEL.BANKCARD_CREATETIME': 'Adding Time',
  'SM.ORGSTAFF.OPTION.EMPLOYEE_ADD_ROLE': 'Add Role',
  'SM.ORGSTAFF.LABEL.STAFF_INFO_BASIC': 'Basic Info',
  'SM.ORGSTAFF.OPTION.STAFF_IP_SINGLE': 'Single IP Address',
  'SM.COMMON.TITLE.CONFIRM_DEL': 'Confirm',
  'SM.ORGSTAFF.OPTION.MONTH_9': 'September',
  'SM.ORGSTAFF.OPTION.MONTH_8': 'August',
  'SM.COMMON.TITLE.SURVEY_CLASS_NAME_INFO': 'Classification Name Info',
  'SM.ORGSTAFF.OPTION.MONTH_7': 'July',
  'SM.COMMON.MESSAGE.SYSMENU_AUTHIDREQUIRE': 'Input the menu permission ID. ',
  'SM.ORGSTAFF.OPTION.MONTH_6': 'June',
  'SM.ORGSTAFF.OPTION.MONTH_5': 'May',
  'SM.ORGSTAFF.OPTION.MONTH_4': 'April',
  'SM.ORGSTAFF.TITLE.MEASUREMENT': 'Measurement Unit',
  'SM.ORGSTAFF.MESSAGE.ORG_NOT_EXSIT': 'The OU does not exist.',
  'SM.ORGSTAFF.OPTION.MONTH_3': 'March',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_EMPLOYEENAME': 'Employee',
  'SM.ORGSTAFF.OPTION.MONTH_2': 'February',
  'SM.COMMON.LABEL.CURRENCY_TYPE_ID': 'ID',
  'SM.ORGSTAFF.OPTION.MONTH_1': 'January',
  'SM.COMMON.TIPS.BANKCARD_LENGTH': 'Input an integer containing no more than 2 digits. ',
  'SM.ORGSTAFF.TITLE.TEAM_SAVESUCCESS': 'Operation performed successfully.',
  'SM.ORGSTAFF.LABEL.BE_LOGO': 'Logo',
  'SM.ORGSTAFF.MESSAGE.MAX_TREE_LEVEL': 'You can only create up to 6 levels of sub-organizations.',
  'SM.PROJECT.TITLE.EXPORT_SELECTED_EMPLOYEE': 'Export Selected Item',
  'SM.ORGSTAFF.LABEL.TEAM_INCLUDESUB': 'Include Sub-OU',
  'SM.ORGSTAFF.LABEL.ORG_LEVEL': 'OU Level',
  'SM.ORGSTAFF.TITLE.EMPTY': 'Email and ContactPhone cannot both be empty',
  'SM.ORGSTAFF.TITLE.COMMON_STAFF_INFO': 'Employee Account List',
  'SM.COMMON.TITLE.ACCESS.SELECT_ALL_STAFF': 'Select all employees of the organization',
  'SM.COMMON.LABEL.SYSMENU_IDEL': 'Release',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_SEARCH_CRITERIA_ATTACHMENTNAME': 'Attachment Name',
  'SM.COMMON.LABEL.SYSMENU_MENUID': 'Menu ID',
  'SM.ORGSTAFF.LABEL.ROLETMPL_PERMISSION_NAME': 'Function Permission Name',
  'SM.COMMON.LABEL.CURRENCY_TYPE_INPUT_TIPS': 'Input the currency type name',
  'SM.ORGSTAFF.LABEL.ADDR_ORDER': 'Display Sequence',
  'SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE_TEMP_LIST': 'Questionnaire List',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_RESTRICTIONTYPE': 'Processing Mode',
  'SM.COMMON.LABEL.MEASUREMENT_SOUMEAUNIT': 'Source Measurement Unit ',
  'SM.ORGSTAFF.MESSAGE.ERROR.SAVEFAILED': 'Save Failed',
  'SM.ORGSTAFF.LABEL.TEAM_ACCESS_EMPLOYEE': 'Visit Staff',
  'SM.ORGSTAFF.LABEL.NO': 'No',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_TERMINATE_SECONDMENT': 'Secondment Termination',
  'SM.COMMON.LABEL.HOLIDAY_TUESDAY': 'Tue',
  'SM.COMMON.LABEL.CHARGECODE_DESC': 'Description',
  'SM.ORGSTAFF.BUTTON.EMPLOYEE_EXPORTALL': 'Export',
  'SM.ORGSTAFF.OPTION.QUERYSUBDEPT': 'Query Lower-Level Department',
  'SM.COMMON.LABEL.MEASUREMENT_DESTMEASYS': 'Target Measurement System ',
  'SM.ORGSTAFF.MESSAGE.SELECTEDOU_CANNOT_SELECTED_AGAIN':
    'The selected organization can not be selected again.',
  'SM.ORGSTAFF.MESSAGE.STAFF_DELETE_CONFIRM':
    'After an employee is deleted, the corresponding account of the employee cannot be restored and can only be re-created. delete or not?',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ONLINE_BE': 'BE',
  'SM.ORGSTAFF.LABEL.REGION_DST_ENDTIME': 'DST End Time',
  'SM.TENANTSPACE.LDAP_SERVERURL': 'Server URL',
  'SM.ORGSTAFF.TITLE.BE_CURRENCYCODE': 'Currency Code',
  'SM.ORGSTAFF.TITLE.ATTR_ATTRSETTING': 'Extended Attribute',
  'SM.COMMON.LABEL.BANKCARD_TYPE': 'Card Type',
  'SM.COMMON.MESSAGE.DELETE_SUCCESS': 'Deleted successfully.',
  'SM.COMMON.TIPS.NEED_TO_NEW_STD_MIN_CURRENCY_UNIT':
    'Need to configure the standard unit of currency and the smallest unit.',
  'SM.COMMON.LABEL.OPERLOG_OPER_LIST': 'Operation Log List',
  'SM.ORGSTAFF.LABEL.INH_BY_SUB': 'Allow Inheritance by Sub-OU',
  'SM.COMMON.LABEL.MEASUREMENT_ERROR_DESCRIPTION': 'Error Description',
  'SM.COMMON.LABEL.SYSMENU_SUSPENDED_S': 'deactivate',
  'SM.COMMON.LABEL.ERATE_DESCRIPTION': 'Description',
  'SM.ORGSTAFF.TITLE.CHARGE_CODE_GROUP': 'Charge Code Group',
  'SM.COMMON.TIPS.CURRENCY_DECIMALPOSITIONS_TIPS':
    'Value –1 indicates that decimal places are displayed based on the currency storage precision.',
  'SM.COMMON.LABEL.OPERLOG_CONTENT': 'Operation Content',
  'SM.ORGSTAFF.LABEL.TEAM_MANAGERINFO': 'Manager Info',
  'SM.COMMON.MESSAGE.MODIFY_AREAID_TYPE':
    'The information already exists. Change the area code or type. ',
  'SM.COMMON.LABEL.SYSMENU_ACTIVE': 'Activate',
  'SM.ORGSTAFF.OPTION.YES': 'Yes',
  'SM.ORGSTAFF.LABEL.ORG_REGION': 'Region',
  'SM.ORGSTAFF.BUTTON.ADD_IN': 'Add In',
  'SM.COMMON.LABEL.SYS_MEASUREMENT_DEFAULT': 'Default Measurement',
  'SM.ORGSTAFF.LABEL.STAFF_PASSWORD': 'New password',
  'SM.ORGSTAFF.LABEL.TEAM_ORGANIZATION': 'Organization Unit',
  'SM.COMMON.MESSAGE.CHARGECODE_TO_RETIRE':
    'Are you sure you want to change it to the Canceled state?',
  'SM.ORGSTAFF.LABEL.ACCESS.ORG_REQUIRED': 'Please select an organization.',
  'SM.ORGSTAFF.TITLE.REGION_USEPARENT': 'Inherit Superior Region Data',
  'SM.ORGSTAFF.TITLE.CONTACT_MIDDLE_NAME': 'Middle Name',
  'SM.ORGSTAFF.LABEL.STAFF_PASSWORDAGAIN': 'Confirm Password',
  'SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE': 'Menu',
  'SM.COMMON.LABEL.HIGHRISKLOG_PERATOR': 'Operator',
  'SM.ORGSTAFF.TITLE.ASSIGNE_MANAGER': 'Assign Manager',
  'SM.COMMON.BUTTON.MEASUREMENT_IMPORT': 'Import',
  'SM.COMMON.LABEL.ATTACHMENT_ADD_TITLE': 'New Attachment',
  'SM.ORGSTAFF.MESSAGE.PWD.CHECKMSG': 'The two passwords are different.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_FORCE_DELETE': 'Force Delete',
  'SM.ORGSTAFF.BUTTON.TEAM_CANCEL': 'Cancel',
  'SM.ORGSTAFF.MESSAGE.COMMON_TIPS_STAFF_SELECT': 'Select employee.',
  'SM.COMMON.LABEL.SYSMENU_DEACTIVATED_D': 'discard',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_RESUME': 'Resume',
  'SM.ORGSTAFF.MESSAGE.STAFF_PWD_RESET_CONFIRM': 'Are you sure you want to reset the password?',
  'SM.COMMON.LABEL.HOLIDAY_HOLIDAYDESCRIPTION': 'Description',
  'SM.ORGSTAFF.LABEL.STAFF_ROLE_EFFECTIVE_OU_ADD': 'Select OU',
  'SM.COMMON.LABEL.SYSMENU_CREATEDATE': 'Creation Date',
  'SM.COMMOM.BUTTON.IMPORT': 'Import',
  'SM.ORGSTAFF.TIPS.ENTER_EMPNAME': "Please input the employee's name.",
  'SM.ORGSTAFF.LABEL.ORG_ORGID': 'OU ID',
  'SM.ORGSTAFF.MESSAGE.ADDR_USE_DELETE':
    'Deletion failed because the address has been referenced. ',
  'SM.ORGSTAFF.MESSAGE.BLANK_VIEW': 'Manage departments in the OU tree.',
  'SM.ORGSTAFF.TITLE.CONTACT_EMPLOYEE_CODE': 'Contact Employee',
  'SM.COMMON.MESSAGE.PROJECT_CODE': 'The project code is:',
  'SM.COMMON.LABEL.AREAID': 'Area ID',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_UPLOADTIME': 'Upload Time',
  'SM.ORGSTAFF.LABEL.CHANNEL_ID': 'Channel ID',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ROAM_INVALID': 'Invalid',
  'SM.ORGSTAFF.LABEL.INPUT_SEARCH_KEYWORD': 'Please input keyword',
  'SM.COMMON.LABEL.MEASUREMENT_EXPORT_FILE_FORMAT': 'Exported File Format',
  'SM.COMMON.LABEL.MEASUREMENT_EXPORT_FAILURE': 'Failure',
  'SM.ORGSTAFF.MESSAGE.ORG_ROLE_DELETE':
    'If delete this data, the relationship between user and role will be deleted automatically. Continue?',
  'SM.COMMON.LABEL.SURVEY_DESC': 'Description ',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PVT': 'Password Validity Type',
  'SM.COMMON.LABEL.CHARGECODE_OPERATION': 'Operation',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_ATTACHMENTCODE': 'Attachment Type',
  'SM.COMMON.LABEL.ERATE_SEARCH_CRITERIA': 'Search Criteria',
  'SM.COMMON.LABEL.MODULE_MODULETYPE': 'Category',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ROAM_CREATEDATE': 'Create Date',
  'SM.COMMON.LABEL.OPERLOG_OBJECT_NAME': 'Object Name',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_LOGIONRESTRICTION': 'Sign-In Restriction',
  'SM.COMMON.LABEL.SYSMENU_PARENT': 'Parent Menu ID',
  'SM.COMMON.TIPS.PLZ_SAVE_CURRENCY_MEASUREMENT_UNIT': 'Please save currency measurement unit.',
  'SM.COMMON.LABEL.CURRENCY_FORMAT_TITLE': 'Currency Display Format',
  'SM.COMMON.LABEL.SYSMENU_GRANT_MENU_AUTH': 'Manage Menu Permission',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST': 'Attachment List',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_MAC_ADDR': 'MAC Address',
  'SM.COMMON.LABEL.MEASUREMENT_YES': 'Yes',
  'SM.ORGSTAFF.LABEL.ADDR_REGION_LAYER': 'Administrative Region Level',
  'SM.COMMON.TITLE.ACCESS.FILTER': 'Please enter keywords to filter',
  'SM.ORGSTAFF.TITLE.TEAM_SUCCESS': 'Information',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_TITLE': 'Title',
  'SM.ORGSTAFF.OPTION.WEEKLIST_TID': 'Third',
  'SM.ORGSTAFF.LABEL.STAFF_ROLE_EFFECTIVE_OU_DETAIL': 'Detail',
  'SM.ORGSTAFF.OPTION.WEEKDAY_WED': 'Wednesday',
  'SM.ORGSTAFF.LABEL.ORG_RECOVER': 'Resume',
  'SM.ORGSTAFF.MESSAGE.DELETE_MSG': 'Are you sure you want to delete it?',
  'SM.ORGSTAFF.TITLE.BE_CURRENCYNAME': 'Currency Name',
  'SM.COMMON.LABEL.CURRENCY_DISPLAY_PREFIX': 'Display Prefix',
  'SM.COMMON.LABEL.ADD_EXCHANGE_RATE': 'Add / Adjust exchange rate of associated currencies.',
  'SM.COMMON.BUTTON.SAVE': 'Save',
  'SM.ORGSTAFF.LABEL.TEAM_REFRESH': 'Update',
  'SM.COMMON.TITLE.ERATE_ERROR': 'Error',
  'SM.ORGSTAFF.LABEL.STAFF_ROLE_NAME': 'Role Name',
  'SM.ORGSTAFF.LABEL.TEAM_EMPLOYEENAME': 'Employee',
  'SM.COMMON.LABEL.SURVEY_TYPE_NAME': 'Category Name',
  'SM.ORGSTAFF.LABEL.REGION_CONFIG_TIMEZONE':
    'Region missing time zone information, please config time zone for region or parent region.',
  'SM.ORGSTAFF.TITLE.IMPORT_EMPLOYEE_TITLE': 'Import Employee',
  'SM.COMMON.LABEL.HIGHRISKLOG_PARAMS': 'Parameter',
  'SM.ORGSTAFF.LABEL.TEAM_ACCOUNT': 'Account',
  'SM.ORGSTAFF.OPTION.STAFF_RESTRICTIONTYPE_DISALLOWED': 'Reject',
  'SM.ORGSTAFF.MESSAGE.BE_SELECT_DEFAULT_CURRENCY': 'Select the default currency.',
  'SM.COMMON.BUTTON.CHARGECODE_CONFIRM': 'Information',
  'SM.ORGSTAFF.TITLE.CONTACT_FIRST_NAME': 'First Name',
  'SM.COMMOM.LABEL.SURVEY_INFO': 'Questionnaire Info',
  'SM.ORGSTAFF.LABEL.BANK_CHILEBEUSED': 'Allow Child BE to Use',
  'SM.COMMON.LABEL.HEADING_BASEINFO': 'Basic Info',
  'SM.ORGSTAFF.LABEL.TEAM_ADD': 'New',
  'SM.ORGSTAFF.TITLE.TEAM_AVAILABLEEMPL': 'Available Employees',
  'SM.ORGSTAFF.LABEL.STAFF_INFO': 'Employee Info',
  'SM.ORGSTAFF.LABEL.STAFF_ROLE_ID': 'Role ID',
  'SM.COMMON.LABEL.SYSMENU_VERSION': 'Version',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_LASTNAME': 'Last Name',
  'SM.ORGSTAFF.LABEL.TEAM_BUSINESSENTITY': 'BE Name',
  'SM.COMMON.LABEL.SYSMENU_CURRENCYLANGUAGE': 'Current Language',
  'SM.ORGSTAFF.TITLE.CONTACT_HOME_PHONE': 'Home Phone',
  'SM.ORGSTAFF.LABEL.CHANNEL_NAME': 'Channel Name',
  'SM.COMMON.MESSAGE.CHARGECODE_DELETE_GROUP':
    'Are you sure you want to delete the charge code group?',
  'SM.COMMON.LABEL.AREATYPE': 'Area Type',
  'SM.COMMON.LABEL.SYSMENU_IDEL_I': 'release',
  'SM.COMMON.MESSAGE.CURRENCY_UNIT_TIPS': 'Please input number.',
  'SM.COMMON.LABEL.MEASUREMENT_ENTITY_TYPE': 'Unit Type',
  'SM.ORGSTAFF.LABEL.ORG_IMPORTSUCCESS': 'Data imported successfully. ',
  'SM.COMMON.TIPS.MENU_REBUILD_INFO':
    'The menu index recreating information has been sent to the message queue. Please wait.',
  'SM.ORGSTAFF.LABEL.SELECTED_ROLE': 'Selected Role',
  'SM.COMMON.LABEL.MEASUREMENT_CONV_RATIO': 'Measurement Unit Ratio',
  'SM.COMMON.LABEL.OPERLOG_SEARCH': 'Search',
  'SM.COMMON.LABEL.SYSMENU_GRANT_AUTH': 'Manage Permission',
  'SM.ORGSTAFF.LABEL.BREGION_NAME': 'Region',
  'SM.ORGSTAFF.TITLE.BE_DEFAULT': 'Default Currency',
  'SM.COMMON.TITLE.MEASUREMENT_UNITLIST': 'Measurement Unit List',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_BE': 'BE ID',
  'SM.COMMON.LABEL.HIGHRISKLOG_OPERATIONNAME': 'Operation Name',
  'SM.COMMON.MESSAGE.DELETE_CURRENCY_STD_MIN_MEASUREMENT':
    'Currency needs to use standard unit and minimum unit ,are you sure to delete?',
  'SM.ORGSTAFF.LABEL.STAFF_ROLE_PATH': 'Path',
  'SM.ORGSTAFF.TITLE.MSGBOX_INFO': 'Tips',
  'SM.ORGSTAFF.OPTION.WEEKDAY_MON': 'Monday',
  'SM.COMMON.LABEL.CURRENCY_TYPE_FLAG': 'Virtual',
  'SM.COMMON.LABEL.OPERLOG_LOGIN_IP': 'Sign-In IP',
  'SM.COMMON.LABEL.CHARGECODE_GROUPMEMBER': 'Charge Codes',
  'SM.COMMON.TITLE.SURVEY_QUESTIONNAIRE_NAME_LANG': 'Questionnaire Name Language',
  'SM.COMMON.LABEL.CURRENCY_ROUNDMODE': 'Rounding Mode',
  'SM.ORGSTAFF.LABEL.ACCESS.ADD_SUCCESS': 'Adding succeeded.',
  'SM.COMMON.LABEL.SYSMENU_MENU_AUTH_ROLE_LIST': 'Assigned Role List',
  'SM.COMMON.TITLE.BANKCARD_CONFIRM': 'Information',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_12': 'December',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_11': 'November',
  'SM.COMMON.LABEL.HOLIDAY_MONTH_10': 'October',
  'SM.COMMON.LABEL.CHARGECODE_DATAGIRD_CATALOG': 'Catalog',
  'SM.COMMON.TIPS.ONLYONE_UNDERLINE_FORBIDDEN':
    'The input value can not contain special characters.',
  'SM.ORGSTAFF.LABEL.ADDR_PLEASE_INPUT': 'Please input name',
  'SM.COMMOM.BUTTON.EDIT': 'Modify',
  'SM.ORGSTAFF.MESSAGE.SUCCESS.SAVE': 'Success',
  'SM.COMMON.LABEL.MEASUREMENT_NOTIFICATION_NAME': 'Name in Notification',
  'SM.COMMON.LABEL.BANKCARD_BANKTYPE': 'Bank Type',
  'SM.ORGSTAFF.BUTTON.ADDR_LINE': 'Line',
  'SM.ORGSTAFF.TITLE.TEAM_DELETE_CONFIRM': 'Confirm',
  'SM.COMMON.LABEL.CHARGECODE_RETIRE': 'Cancel',
  'SM.COMMON.TIPS.SPECIAL_CHAR_CANNOT_START_NUMBER': 'Not allow to start with number.',
  'SM.COMMON.LABEL.CURRENCY_DISPLAY_FORMATE': 'Display Format',
  'SM.ORGSTAFF.LABEL.TEAM_CONTACTINFO': 'Contact Info',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_LIST_ATTACHMENTNAME': 'Attachment Name',
  'SM.ORGSTAFF.LABEL.ADDR_ADDERSS': 'Address',
  'SM.ORGSTAFF.MESSAGE.SURE_TO_DO': 'Continue?',
  'SM.ORGSTAFF.LABEL.TEAM_TEAMSHORTNAME': 'Team Short Name',
  'SM.ORGSTAFF.TITLE.BANK_TITLE': 'Bank',
  'SM.COMMON.MESSAGE.CHARGECODE_OPERATION_SUCCESS': 'Operation performed successfully.',
  'SM.ORGSTAFF.MESSAGE.USERRESTRICT_CONFIRM_SAVE':
    'The Sign-In Time Segment can only keep one record, and the other Sign-In Time Segment will be deleted when it is added.',
  'SM.COMMON.LABEL.BANKCARD_BANKSIGN_PARAM': 'Card Issuing Bank ID',
  'SM.COMMON.BUTTON.SURVEY_NEW_ANSWER_OPTIONS': 'New Answer Option',
  'SM.ORGSTAFF.TIPS.MAC_FORMAT_ERROR': 'MAC Address Format Error',
  'SM.COMMON.LABEL.MODULE_BASICINFO': 'Basic Info',
  'SM.ORGSTAFF.LABEL.REGION_DST_MODE': 'DST Mode',
  'SM.COMMON.BUTTON.EDIT': 'Modify',
  'SM.COMMON.MESSAGE.ERATE_VLUEW_RANGE': 'The value ranges from 0 to {1}.',
  'SM.COMMON.LABEL.PROVINCE': 'Province',
  'SM.ORGSTAFF.LABEL.STAFF_OPRATION': 'Operation',
  'SM.COMMON.LABEL.ATTACHMENT_LIMIT_INFO': 'No Restriction',
  'SM.COMMON.MESSAGE.BANKCARD_DELETE_CONFIRM': 'Are you sure you want to delete?',
  'SM.COMMON.LABEL.SYSMENU_DESCRIPTION': 'Menu Description',
  'SM.COMMON.MESSAGE.CURRENCY_UNIT_EXCHAGE_TIPS': 'Please input integer.',
  'SM.ORGSTAFF.MESSAGE.CHOOSE_BE_FIRST': 'Please choose BE first',
  'SM.COMMON.LABEL.MODULE_NAME': 'Name',
  'SM.ORGSTAFF.TITLE.MEASUREMENT_CONV': 'Measurement Conversion',
  'SM.COMMON.LABEL.SURVEY_REMARKS': 'Remarks',
  'SM.ORGSTAFF.OPTION.ROLE_OPTION_MODE': 'Operation Mode',
  'SM.ORGSTAFF.LABEL.OU_HIERARCHY': 'OU Hierarchy',
  'SM.COMMON.BUTTON.VIEW': 'View',
  'SM.COMMON.MESSAGE.CHARGECODE_CREATE_DATA': 'Are you sure you want to add this charge codes?',
  'SM.ORGSTAFF.BUTTON.ADD': 'New',
  'SM.ORGSTAFF.MESSAGE.STAFF_SUSPEND_CONFIRM':
    'Are you sure you want to suspend the employee account?',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_SVD': 'Session Timeout Interval',
  'SM.ORGSTAFF.MESSAGE.BANK_STATUS_ERROR':
    'Saving failed because the bank information has expired. ',
  'SM.COMMON.LABEL.HOLIDAY_BASICINFORMATION': 'Basic Info',
  'SM.ORGSTAFF.LABEL.ORG_ADDITIONINFO': 'Additional Info',
  'SM.ORGSTAFF.TITLE.BE_ADDRESS_CFG': 'Address Format',
  'SM.ORGSTAFF.TITLE.STAFF_INFO_MDY': 'Modify Employee',
  'SM.COMMON.TITLE.ACCESS.SELECT_ORG': 'Select Access Organization',
  'SM.ORGSTAFF.MESSAGE.STAFF_FORBID_CONFIRM':
    "After an employee is disabled permanently, the employee's account can only be created repeatedly after it is deleted. Are you sure you want to disable the employee permanently?",
  'SM.ORGSTAFF.MESSAGE.ERROR.SAVEACCESSLIMIT': 'Accessible employees can add up to 100 per batch',
  'SM.COMMON.LABEL.BANKCARD_LIST': 'Bank Card List',
  'SM.COMMON.LABEL.OU_HIERARCHY_NAME': 'OU RelationShip Name',
  'SM.COMMON.MESSAGE.SYSMENU_MENUNAMEREQUIRE': 'Input the menu name.',
  'SM.COMMON.MESSAGE.MEASUREMENT_SAVE_CONFIRM':
    'When you save a standard unit of measure, it replaces an existing standard unit and modifies the standard units of other units of measure.',
  'SM.COMMON.LABEL.SYSMENU_SEARCH_MENU': 'Search Criteria',
  'SM.ORGSTAFF.TITLE.ADDR_TEMPATE_ID': 'Template ID',
  'SM.ORGSTAFF.TITLE.ADDR_DISP_FORMAT': 'Display Format',
  'SM.ORGSTAFF.MESSAGE.STARTANDTEND_ISNULL':
    'The start and end dates can not be empty at the same time.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_TIMERANGE': 'Time Range',
  'SM.ORGSTAFF.LABEL.SELECT_EXCEL': 'Please select excel file',
  'SM.ORGSTAFF.TITLE.EMP_BELONG_DEPT': 'Department',
  'SM.ORGSTAFF.LABEL.BUSINESSENTITY_DISPLAY_NO': 'Display Sequence',
  'SM.COMMON.BUTTON.CHARGECODE_CANCEL': 'Cancel',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_ADDR': 'Address',
  'SM.ORGSTAFF.LABEL.TEAM_TEAMTYPE': 'Team Type',
  'SM.COMMON.LABEL.SYSMENU_MENUCRUMB': 'Path',
  'SM.COMMON.LABEL.SURVEY_QUESTIONNAIRE_NAME': 'Questionnaire Name',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_NEW_PASSWORD_CONFIRM': 'new password confirm',
  'SM.COMMON.LABEL.CURRENCY_TYPE': 'Currency Type',
  'SM.COMMON.BUTTON.ATTACHMENT_MGR_SEARCH_CRITERIA_RESET': 'Reset',
  'SM.COMMON.LABEL.MENU_ATTR_TITLE': 'Extend Attribute',
  'SM.ORGSTAFF.LABEL.TEAM_REMOVE': 'Remove',
  'SM.ORGSTAFF.BUTTON.SAVE': 'Save',
  'SM.COMMON.LABEL.CHOOSE_OU_ADD': 'Choose OU To Add',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_COPYPERMISSION': 'Copy Permission',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD41':
    'Lockout due to consecutive login failures within the specified period, in minutes',
  'SM.ORGSTAFF.MESSAGE.REGION_VALIDATE_IPRANGEERROR':
    'The IP address segment is incorrectly configured.',
  'SM.ORGSTAFF.MESSAGE.ERROR.MAXLIMIT':
    'The number of establishments under a tenant cannot exceed 999',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD40': 'Customized rule',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_ONLINE_ACCOUNT': 'Account',
  'SM.COMMON.LABEL.CREATE_AREAINFO_MAINTAIN': 'New Region Info',
  'SM.COMMON.TITLE.BANKCARD_MDF': 'Modify Bank Card',
  'SM.ORGSTAFF.TIPS.RESTRICTMAC': 'Please input correct MAC address.',
  'SM.ORGSTAFF.TITLE.QUERYFAIL_TITLE': 'Search failed.',
  'SM.COMMON.BUTTON.CREATE': 'New',
  'SM.COMMON.MESSAGE.VALIDATE_MAXLENGTHB': 'A maximum of 0 characters are allowed.',
  'SM.COMMON.LABEL.HOLIDAY_HOLIDAYTYPE': 'Type',
  'SM.COMMON.LABEL.SURVEY_MODIFIED_TIME': 'Modification Time',
  'SM.COMMON.LABEL.OPERLOG_SUCCESS_MEW': 'Success',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD39': 'The password can contain only digits',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD38':
    'Characters that cannot be contained in a password',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD37':
    'The password cannot be the same as the account or a subset of the account',
  'SM.COMMON.MESSAGE.SYSMENU_MAX_EDIT_LIMIT': 'Maximum edit limit.',
  'SM.COMMON.MESSAGE.CHARGECODE_TO_DRAFT': 'Are you sure you want to change it to the Draft state?',
  'SM.COMMON.BUTTON.BANKCARD_RESET': 'Reset',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD36':
    'List of forbidden password formats, such as ABCABC and ABCDEF',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD35': 'Default password',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD34':
    'Minimum password validity period, in hours',
  'SM.COMMON.LABEL.MENU_ACCESS_STRART_TIME': 'Start Time',
  'SM.COMMON.LABEL.MEASUREMENT_RESULT_LIST': 'Result List',
  'SM.ORGSTAFF.MESSAGE.ADDR_DELETE_SUCCESS': 'Deleted successfully.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD33':
    'The password cannot be consecutive characters or digits such as AB, 123, AA, and abc',
  'SM.COMMON.LABEL.ATTACHMENT_MGR_UPLOAD_SIZE_MIN':
    'The uploaded file is smaller than the minimum size 0 KB. ',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD32':
    'Minimum number of characters of each type that must be contained in a password',
  'SM.ORGSTAFF.LABEL.ORG_TYPE': 'OU Type',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD31': 'Minimum account length',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD30': 'Maximum account length',
  'SM.COMMON.LABEL.OPERLOG_SERRCH_CRITERIA': 'Search Criteria',
  'SM.COMMON.MESSAGE.OPERLOG_TIME_LOG2': 'day(s).',
  'SM.COMMON.MESSAGE.OPERLOG_TIME_LOG1': 'The time range cannot exceed ',
  'SM.COMMON.LABEL.CURRENCY_BASIC_INFO': 'Basic Info',
  'SM.ORGSTAFF.TITLE.RELATION_CONFIRM_DELETE':
    'After been disabled, the organization relationship and its subordinate relationship will be deleted, Are you confirm to delete?',
  'SM.COMMON.LABEL.ERATE_SCURRENCY_CODE': 'Source Currency Code',
  'SM.COMMON.LABEL.SELECTED_OUS': 'Selected OUs',
  'SM.ORGSTAFF.LABEL.REGION_DST_STARTTIME': 'DST Start Time',
  'SM.ORGSTAFF.TITLE.POPUP_NEW_PROPERTY': 'New Property',
  'SM.COMMON.LABEL.SURVEY_TITLE_TYPE': 'Question Type',
  'SM.ORGSTAFF.MESSAGE.DELETE_CONFIRM': 'Are you sure you want to delete?',
  'SM.ORGSTAFF.OPTION.NO': 'No',
  'SM.ORGSTAFF.LABEL.BE_TYPE': 'BE Type',
  'SM.COMMON.LABEL.OPERLOG_SESSION_ID': 'Session ID',
  'SM.COMMON.MESSAGE.MEASUREMENT_STANDARD_MUSTEXIST':
    'Measurement system must have standard measurement unit.',
  'SM.ORGSTAFF.OPTION.WEEKDAY_SUN': 'Sunday',
  'SM.ORGSTAFF.LABEL.STAFF_ROLE_EFFECTIVE_OU': 'Effective OU',
  'SM.ORGSTAFF.VALIDATOR.AFTER_TODAY':
    'The time entered must be earlier than or equal to 00:00 today.',
  'SM.SM.COMMON.TITLE.GROUP_NAME_EXIST': 'The user group name is exsited.',
  'SM.ORGSTAFF.LABEL.ORG_RECOVER_TIPS':
    'After been resumed, organization or organization and its subordinate organization would be available. Are you confirm to resume?',
  'SM.ORGSTAFF.TITLE.EMPLOYEE_LOGINTIMERANGE': 'Sign-In Time Segment',
  'SM.COMMON.LABEL.CURRENCY_MINIMUM_MEASUREMENT': 'Min. Unit Flag',
  'SM.ORGSTAFF.TITLE.BE_ADDRESS_INFO': 'Address Info',
  'SM.ORGSTAFF.TIPS.ENTER_OUNAME': 'Input the organization name.',
  'SM.COMMON.LABEL.SURVEY_SECTION': 'Regional',
  'SM.COMMON.TIPS.SPECIAL_CHAR_FORBIDDEN': 'The input value can not contain special characters.',
  'SM.SM.COMMON.TITLE.NO_CHINAWORD': 'Special characters are not allowed.',
  'SM.COMMON.LABEL.SYS_MEASUREMENT_NAME': 'Name',
  'SM.COMMON.LABEL.SURVEY_CREATED_ACCOUNT': 'Created By',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD19':
    'Forcible password change upon first login or after password resetting',
  'SM.COMMON.LABEL.MODULE_MODULELIST': 'Module List',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD18':
    'Number of days in advance users are notified of password expiration',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_SECONDMENT_HISTORY': 'Secondment History',
  'SM.COMMON.TITLE.SURVEY_ITEM_NAME_INFO': 'Item Name Info',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD15': 'Maximum number of password retries',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD14':
    'Maximum password validity period, in days',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD13': 'The password cannot contain the name',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD12':
    'The password must be different from the account or the account in reverse order',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD11': 'Invalid password',
  'SM.COMMON.LABEL.SURVEY_NOT_INPUT': 'Not allow manual input',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD10': 'Valid special characters',
  'SM.COMMON.MESSAGE.SYSMENU_SAVESUCCESS': 'Operation performed successfully.',
  'SM.ORGSTAFF.TITLE.CONTACT_LAST_NAME': 'Last Name',
  'SM.COMMON.LABEL.MEASUREMENT_CONV': 'Measurement Conversion',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_STATUS': 'Status',
  'SM.COMMON.LABEL.MEASUREMENT_UNIT_STANDARD': 'Standard Unit Flag',
  'SM.ORGSTAFF.LABEL.REGION_DISPLAYORDER': 'Display Sequence',
  'SM.ORGSTAFF.LABEL.TEAM_TO': 'To',
  'SM.COMMON.LABEL.SURVEY_CLASS_NAME': 'Category Name',
  'SM.COMMON.LABEL.BANKCARD_TYPE_PARAM': 'Card Type',
  'SM.ORGSTAFF.LABEL.STAFF_AUTHCOPY_SOURCENO': 'Source Account',
  'SM.COMMON.LABEL.CURRENCY_GROUPSYMBOL': 'Separator',
  'SM.ORGSTAFF.TITLE.STAFF_ROLE_BASIC': 'Role',
  'SM.ORGSTAFF.TITLE.ADDR_DISP_LIST': 'Address Display Format List',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD09':
    'Maximum number of consecutive characters in a password',
  'SM.COMMON.LABEL.DATA_OPRATION': 'Operation',
  'SM.ORGSTAFF.LABEL.TEAM_EXTENDPRO': 'Extended Attributes',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD08':
    'The password must be different from the last passwords',
  'SM.COMMON.LABEL.SURVEY_QUESTIONNAIRE_ID': 'Questionnaire ID',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD07':
    'The password must contain special characters',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD06':
    'The password must contain lowercase letters',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD05':
    'The password must contain uppercase letters',
  'SM.COMMON.LABEL.MEASUREMENT_UNIT_CODE': 'Code',
  'SM.ORGSTAFF.MESSAGE.ADDR_LEVELS_HIGH': 'The address is not sequential.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD04': 'The password must contain letters',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD03': 'The password must contain digits',
  'SM.ORGSTAFF.LABEL.PARENT_BE': 'Parent BE',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD02': 'Minimum password length',
  'SM.ORGSTAFF.MESSAGE.SELECT_WG_INFO': 'Please select a workgroup.',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_PASSWORD_VALIDATE_FAILD01': 'Maximum password length',
  'SM.COMMON.BUTTON.PROPERTY_CONFIRM': 'Information',
  'SM.COMMON.LABEL.EDIT_AREAINFO_MAINTAIN': 'Modify Region Info',
  'SM.COMMON.TITLE.PROPERTY_SUCCESS': 'Information',
  'SM.ORGSTAFF.LABEL.ORG_SELECTORG': 'Select OU',
  'SM.COMMON.TITLE.ITEM_NOTE': 'View Image',
  'SM.COMMON.BUTTON.SEARCH': 'Search',
  'SM.COMMON.LABEL.ERATE_EXCHANGE_RATE': 'Exchange Rate',
  'SM.ORGSTAFF.BUTTON.EMPLOYEE_DELETE': 'Delete',
  'SM.COMMON.BUTTON.DELETE': 'Delete',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_MATCH_MODE': 'Match Mode',
  'SM.ORGSTAFF.TITLE.WORKGROUP': 'Workgroup',
  'SM.COMMON.LABEL.PROPERTY_CREATE_PROPERTY': 'New Attribute',
  'SM.ORGSTAFF.MESSAGE.RECOVERORG': 'Resume the OU.',
  'SM.AUC.LABEL.LOGINLOG_EMPLOYEE_NAME': 'Employee Name',
  'SM.AUC.LABEL.LOGINLOG_LOGIN_IP_ADDRESS': 'Sign-In IP',
  'SM.AUC.LABEL.LOGINLOG_OPER_CONTENT': 'Operation Content',
  'SM.AUC.LABEL.LOGINLOG_OPER_TIME': 'Operation Time',
  'common.bremui.title.operation': 'Operation',
  'SM.ORGSTAFF.HINT.PLEASE_INPUT': 'Please input',
  'SM.MENU.SEARCH.INPUT': 'Search Menu Name',
  'SM.MENU.ADD.CUSTOM.MENU': 'Add Menu',
  'SM.MENU.ADD.CUSTOM.PERM_REPEAT': 'Duplicate permission name',
  'SM.AUC.MESSAGE.PWDR_VALIDATE': 'Verification Rule',
  'SM.AUC.MESSAGE.PWDR_MANAGE': 'Management Rule',
  'SM.AUC.MESSAGE.RULEGROUP': 'Rule group',
  'SM.AUC.MESSAGE.RULEGROUPNAME': 'Rule Group Name',
  'SM.AUC.MESSAGE.RULEGROUPList': 'Rule List',
  'SM.AUC.MESSAGE.RULEGROUPListEDIT': 'Editing a Rule Group',
  'SM.AUC.MESSAGE.RULEGROUPListADD': 'Adding a Rule Group',
  'SM.AUC.MESSAGE.LOGIN.IP': 'Login IP address',
  'SM.AUC.MESSAGE.PWDRULE_ACCOUNT': 'Account Rule',
  'SM.AUC.LABEL.PWD_PWDRULE': 'Password Rule',
  'SM.AUC.LABEL.PWD_CUSTOMIZEDRULE': 'Customized Rule',
  'SM.AUC.LABEL.KIND': 'Classifieds',
  'SM.AUC.LABEL.VALUE': 'Value',
  'SM.MENU.DELETE.CUSTOM.ROLE.FAIL':
    'The menu is deleted successfully. Failed to delete the permission associated with the menu. Check whether the permission is referenced by other menus or roles.',
  'SM.AUC.ACCOUNT.LOCK.SEARCH.ACCOUNT': 'Searching for an account',
  'SM.AUC.ACCOUNT.LOCK.USER.NOT.EXIT': 'The user does not exist',
  'SM.AUC.LABEL.LOGINSESSION_SESSION': 'Session Status',
  'SM.AUC.LABEL.LOGINSESSION_LOGINSTATUS': 'Sign-In Status',
  'SM.AUC.LABEL.USERLOGUI_LOGINIDACCOUNT': 'Sign-In Account',
  'SM.AUC.MESSAGE.OPERLOG_TIPS_LOGOUT': 'Force Out',
  'SM.AUC.LABEL.LOGINSESSION_LOCKSTATUS': 'Lock Status',
  'SM.AUC.LABEL.LOGINSESSION_LOCKTIME': 'Lock Time',
  'SM.AUC.LABEL.LOGINSESSION_LOCK': 'Lock',
  'SM.AUC.LABEL.LOGINSESSION_UNLOCK': 'Unlock',
  'SM.AUC.LABEL.LOGINSESSION_SURE_LOCK': 'Are you sure you want to lock the user?',
  'SM.AUC.LABEL.LOGINSESSION_LOCKFLAG': 'Lock Flag',
  'SM.AUC.LABEL.LOGINSESSION_LOCKTYPE': 'Lock Type',
  'SM.AUC.LABEL.LOGINSESSION_LOCK_EMPLOYEE': 'Locked By',
  'SM.AUC.LABEL.LOGINSESSION_LOCKBYHAND': 'Manually Lock',
  'SM.AUC.LABEL.LOGINSESSION_UNLOCKBYHAND': 'Manually Unlock',
  'SM.AUC.LABEL.LOGINSESSION_AUTHSTATUS': 'Accreditation Status',
  'SM.AUC.LABEL.LOGINSESSION_NEARESTPAWDAUTHTYPE': 'Last Password Authentication Status',
  'SM.AUC.LABEL.LOGINSESSION_NEARESTPWDAUTHTIME': 'Last Password Authentication Time',
  'SM.AUC.LABEL.LOGINSESSION_PWDAUTHFAILTIME': 'Password Authentication Failure Times',
  'SM.AUC.MESSAGE.LOGINSESSION_LOGININ_STATUS': 'Signed in',
  'SM.AUC.MESSAGE.LOGINSESSION_LOGOUT_STATUS': 'Not Signed In',
  'SM.AUC.MESSAGE.LOGINSESSION_LOCKSTATUS': 'Locked',
  'SM.AUC.MESSAGE.LOGINSESSION_UNLOCKSTATUS': 'Not locked',
  'SM.AUC.LABEL.LOGINSESSION_PWDAUTHFAIL': 'Password is authenticate fail',
  'SM.USERGROUP.DELETEG_GROUP.TITLE': 'Delete the selected user group?',
  'SM.USERGROUP.DELETEG_GROUP.CONTENT': 'After a user group is deleted, it can only be re-created.',
  'SM.USERGROUP.DELETEG_EMPLOYEE.TITLE':
    'Delete the selected employees from the current user group?',
  'SM.USERGROUP.DELETEG_EMPLOYEE.CONTENT':
    'After an employee is deleted from a user group, you can add the employee to the user group again if you want to restore the employee.',
  'SM.AUTH.MESSAGE.FUNCAUTH_MENU': 'Menu',
  'SM.AUTH.LABEL.ROLETMPL_OPERTIME': 'Operation Time',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DESC_FIELD_INPUT': 'Name of Field Name:',
  'SM.AUTH.TITLE.ROLE_FUNCAUTH_FAIL':
    'Failed to save some function permissions. These permissions are exclusive or cannot be granted to you.',
  'SM.AUTH.MESSAGE.CHANGE_SCOPE_SUGGESTION':
    "If the parent node changes the scope of the role, the child node's scope need to be modified as the same of parent node.",
  'SM.AUTH.TITLE.ROLE_DISALLOWED_FUNCTION_PERMISSION': 'Excluded Function Permission ',
  'SM.AUTH.LABEL.OPPAUTH_SOURCE_TYPE_INPUT': 'Permission Source:',
  'SM.FEDERATION.COMMON.DELETE.FAIL': 'Deletion failed.',
  'SM.AUTH.TITLE.ERROR': 'Error',
  'SM.AUTH.TITLE.SELECT_OPEN_LOGIN': 'Choose Login Open Menu',
  'SM.AUTH.LABEL.OPERATOR_ACCOUNT_INPUT': 'Account:',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_OPER_TYPE_LIST_INPUT': 'Operation Type List ID:',
  'SM.AUTH.LABEL.MODULE_INPUT': 'Module:',
  'SM.AUTH.MESSAGE.ROLE_RELATIONSHIP_EXISTS_NOT_BEINHER':
    'The two objects are exclusive and inheritance is not allowed.',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_STAUTS_NORMAL': 'Normal',
  'SM.AUTH.LABEL.ROLE_AUTH_DATA': 'Data Permission',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_PARENT_NODE_FIELD': 'Name of Field PARENT_ID',
  'SM.AUTH.TITLE.ROLE_GRANTABLE_DATA_PERMISSION': 'Grantable Data Permission',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_OPERATION': 'Operation Name',
  'SM.AUTH.LABEL.AUTH_CONF_DATA': 'Data Permission Settings',
  'SM.AUTH.LABEL.ROLE_GENERALROLELIST': 'General Role List',
  'SM.FEDERATION.FEDERATIONAPP.PHONE': 'Contact Number',
  'SM.AUTH.BUTTON.ADD': 'New',
  'SM.AUTH.MESSAGE.ROLETMPL_SELECT_ONE_ROLE': 'Select at least one role.',
  'SM.AUTH.MESSAGE.TIME_LOG2': 'day(s).',
  'SM.AUTH.MESSAGE.TIME_LOG1': 'The time range cannot exceed ',
  'SM.AUTH.TITLE.USERAUTHVIEW_USERHAVAEROLES': 'User Role',
  'SM.AUTH.LABEL.ROLE_GRANTED_AUTH_TYPE': 'Granted Type',
  'SM.AUTH.TITLE.INPUT_MENU_NAME': 'Input a menu name',
  'SM.AUTH.LABEL.FUNCAUTH_STATUS': 'Status',
  'SM.AUTH.TITLE.USERAUTHVIEW_MENU_AUTHID': 'Permission ID',
  'SM.AUTH.MESSAGE.ROLE_RELATIONSHIP_EXISTS': 'The mutual-exclusive relationship already exists.',
  'SM.AUTH.TITLE.ROLE_OPPAUTHNAME': 'Mutually-Exclusive Permission',
  'SM.AUTH.LABEL.ROLETMPL_ROLE_LIST': 'Role List',
  'SM.AUTH.MESSAGE.BIZ_OBJ_POLICY_DUPLICATE': 'There exist policy with the same role and scope.',
  'SM.FEDERATION.FEDERATIONAPP.CLIENTNAME': 'Third-Party Authentication System',
  'SM.AUTH.MESSAGE.ROLETMPL_CAN_NOT_DELETE':
    'Failed to delete the node because the node contains subnodes. ',
  'SM.AUTH.LABEL.ROLE_NORMALROLE_BENAME': "Common Role's Home BE",
  'SM.AUTH.TITLE.MENU_NAME': 'Menu Name',
  'SM.AUTH.TIPS.ROLETMPL_HAS_OPP_AUTH':
    'Saving failed because a mutually-exclusive permission exists.',
  'SM.AUTH.TITLE.ROLE_LOGIN_MENU_UPON': 'Auto Display Menu Upon Sign-In',
  'SM.AUTH.TIPS.FUNCAUTH_NO': 'No',
  'SM.AUTH.LABEL.ROLETMPL_ROLETMPL_CONFIGURATE': 'Configure Role Template',
  'SM.AUTH.LABEL.OPERATOR_ACCOUNT': 'Account',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_SERVICE_INPUT': 'Service Name:',
  'SM.FEDERATION.IMPORT.FILESIZEVALIDATEFAIL': 'Select a file less than 10 MB.',
  'SM.AUTH.LABEL.STAFFROLELOG_OPER_TYPE_INPUT': 'Operation Type:',
  'SM.AUTH.LABEL.ROLE_PARENTROLE_NAME': 'General Role Name',
  'SM.AUTH.LABEL.ROLETMPL_OPERATOR': 'Operator',
  'SM.AUTH.MESSAGE.ROLE_ERROR_BEEN_EXTENDED':
    'Failed to delete the role because it has been inherited by or has inherited another role.',
  'SM.AUTH.TITLE.USERAUTHVIEW_ATTR_DEPARTMENT': 'Department',
  'SM.AUTH.MESSAGE.ROLE_THE_ROLES_PERMISSION_OPPED':
    'Saving failed because the basic function permission has a mutually-exclusive relationship.',
  'SM.AUTH.TITLE.PERMISSION_RECOVERY': 'Permission Recycling',
  'SM.AUTH.LABEL.THIRD_DATASOURCE_NAME': 'Data Source Name',
  'SM.AUTH.BUTTON.EXPORT_CURRENT_ROLE': 'Export Selected Item',
  'SM.FEDERATION.COMMON.REFRESH': 'Refresh',
  'SM.AUTH.LABEL.FUNCAUTH_ROLE_UNAVAILABLE': 'Unavailable Permission of Role',
  'SM.AUTH.TITLE.USERAUTHVIEW_FUNCTIONLIST': 'Function Permission List',
  'SM.AUTH.TITLE.USERAUTHVIEW_AUTHVIEW': 'Permission View',
  'SM.AUTH.MESSAGE.PERM_RECOVERY_SUCCESS': 'Permission revoked successfully.',
  'SM.AUTH.LABEL.FUNCAUTH_AUTHID': 'Permission ID',
  'SM.AUTH.LABEL.ROLE_EMPLOYEE_ACCOUNT': 'Account',
  'SM.AUTH.MESSAGE.ROLETMPL_INFORMATION': 'Details',
  'SM.AUTH.LABEL.STAFFROLELOG_OPER_TYPE': 'Operation Type',
  'SM.AUTH.TITLE.EMPOLYEE_ROLESCOPE': 'Employee',
  'SM.AUTH.BUTTON.CLEAR': 'Clear',
  'SM.AUTH.LABEL.ROLE_CODE_INPUT': 'Role Code:',
  'SM.AUTH.LABEL.BIZ_OBJ_FIELD_PRIVILEGE': 'Field Privilege',
  'SM.AUTH.LABEL.FUNCAUTH_AUTHTYPE': 'Permission Type',
  'SM.FEDERATION.COMMON.DETAIL': 'Details',
  'SM.FEDERATION.COMMON.NEW': 'Create',
  'SM.AUTH.LABEL.MENU_AUTHID_INPUT': 'Permission ID:',
  'SM.AUTH.TITLE.USER_PERMISSION_COMPARE': 'Personnel Permission Comparison',
  'SM.AUTH.TIPS.DATA_AUTH_PARAM_INVALID':
    "Table name, column name can only contain letters, number and '_' and can only start with letters.",
  'SM.AUTH.TITLE.ROLE_GRANTABLE_FUNCTION_PERMISSION': 'Grantable Function Permission ',
  'SM.AUTH.TITLE.ROLE_PERMISSION_COMPARE': 'Role Permission Comparison',
  'SM.AUTH.TITLE.ROLE_BUSINESS_ENTITY': 'BE ID',
  'SM.AUTH.LABEL.ROLE_DERIVE_PARENTROLE': 'Inherited General Role',
  'SM.AUTH.MESSAGE.ROLE_ADD_SUCCESSFUL': 'Added successfully.',
  'SM.AUTH.LABEL.ROLE_EMPLOYEE_NAME': 'Employee',
  'SM.AUTH.MESSAGE.FUNCAUTH_INTERFACE': 'Interface',
  'SM.AUTH.BUTTON.ROLETMPL_RESET': 'Reset',
  'SM.AUTH.TITLE.ROLE_PERMISSION_OBJECT_TYPE': 'Permission Object Type',
  'SM.AUTH.TITLE.INPUT_AUTH_OBJ_NAME': 'Input a permission object name',
  'SM.FEDERATION.MAPPINGRULE.LOCAL': 'Local User Information',
  'SM.AUTH.LABEL.CLEAR_TIMEOUT_SELECT_MONTH_FRONT': 'Months (30 Days) to Reserve',
  'SM.AUTH.LABEL.STAFFROLELOG_ROLE_NAME': 'Role Name',
  'SM.AUTH.LABEL.ROLE_ID_B_INPUT': 'Role ID (B):',
  'SM.AUTH.LABEL.PERMISSION_CLASS': 'Permission Classification',
  'SM.AUTH.BUTTON.OK': 'OK',
  'SM.AUTH.MESSAGE.ROLE_ERROR_HAS_EXCLUDED':
    'Failed to delete the role because it excludes another role.',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DYNA_CONF': 'Dynamic Configuration Flag',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_PERMISSION_FUNCTION': 'Function',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DATA_FILTER_CONDITION': 'Data Filter Criteria',
  'SM.FEDERATION.COMMON.UPDATE.FAIL': 'Modification failed.',
  'SM.FEDERATION.FEDERATIONAPP.VALDATEHTTP': 'The URL format is invalid',
  'SM.AUTH.LABEL.ROLETMPL_START_TIME': 'Start Time',
  'SM.AUTH.LABEL.DATA_DICTABLE_ID': 'Data Dictionary Code',
  'SM.AUTH.LABEL.DATAAUTHMGR_TITLE_AUTHNAME_CONF_INPUT': 'Permission Name:',
  'SM.AUTH.LABEL.ROLE_EXCLUDINGROLE': 'Excluding Role',
  'SM.AUTH.MESSAGE.OPERATE_SUCCESS': 'Operation performed successfully.',
  'SM.AUTH.LABEL.FUNCAUTH_PARENTAUTH': 'Parent Permission ID',
  'SM.AUTH.LABEL.PERMISSION_INPUT': 'Authority:',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_PERMISSION_MENU': 'Menu',
  'SM.AUTH.MESSAGE.ROLETMPL_QUERY_FAILED': 'Search failed.',
  'SM.AUTH.LABEL.ROLE_NAME_INPUT': 'Role Name:',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_PUT_FUNCTION': 'Grantable Function Permission',
  'SM.AUTH.LABEL.ROLE_NORMALROLE': 'Common role',
  'SM.AUTH.OPTION.DISALLOWED_FUNCTION_PERM': 'Excluded Function Permission ',
  'SM.AUTH.MESSAGE.ROLETMPL_ROLETMPL_EXISTS': 'The role template name already exists.',
  'SM.AUTH.MESSAGE.DATAPER_NO_PERM': 'You do not have the operation permission. ',
  'SM.AUTH.LABEL.ROLE_ROLE_ID': 'Role ID',
  'SM.AUTH.LABEL.OPERATION_TYPE': 'Operation Type',
  'SM.AUTH.MESSAGE.FUNCAUTH_UPLOADINFO': 'Select Attachment Format:',
  'SM.AUTH.LABEL.DATA': 'Data Permission',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DISPLAYNO_FIELD': 'Sort Field',
  'SM.AUTH.TITLE.ORG_SELECTION': 'Selection of Organization',
  'SM.AUTH.LABEL.ROLE': 'Role',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_STAUTS_PAUSE': 'Suspend',
  'SM.AUTH.TITLE.USERAUTHVIEW_ROLEVIEW': 'Role View',
  'SM.AUTH.LABEL.FUNCAUTH_ROLE_GRANTABLE': 'Grantable Permission of Role',
  'SM.AUTH.LABEL.DATAPROVIDER_TYPE_INPUT': 'Data Value Source Type:',
  'SM.AUTH.TITLE.USERAUTHVIEW_ATTR_BE': 'BE',
  'SM.AUTH.TITLE.USERAUTHVIEW_ROLE_TYPE': 'Role Type',
  'SM.FEDERATION.IMPORT.USER': 'Import Federated User',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DISPLAYNO_FIELD_INPUT': 'Sort Field:',
  'SM.AUTH.LABEL.CLEAR_TIMEOUT_SELECT_MONTH_END': 'month (a month is 30 days) data.',
  'SM.AUTH.TITLE.STAFFROLELOG_LIST': 'Employee Role Log List',
  'SM.AUTH.LABEL.BIZ_OBJ_PRIVACY_LEVEL_SECRET': 'Secret',
  'SM.AUTH.MESSAGE.ROLE_ERROR_HAS_USERS':
    'Failed to delete the role because the role has been bound to an employee. ',
  'SM.FEDERATION.IMPORT.FAIL': 'Failed',
  'SM.AUTH.LABEL.ROLE_PARENTROLE_BENAME': "General Role's Home BE",
  'SM.AUTH.TITLE.ROLE_HORTCUT_MENU': 'Shortcut Menu',
  'SM.AUTH.LABEL.FUNCAUTH_STATUS_INPUT': 'Status:',
  'SM.AUTH.LABEL.CLEAR_POPUP_TITLE': 'Delete Expired Data',
  'SM.AUTH.LABEL.MODULE': 'Module',
  'SM.AUTH.LABEL.ROLETMPL_ASSINGED_PERMISSION': 'Grant Permission to Role',
  'SM.AUTH.TITLE.INPUT_ROLE_NAME': 'Input role name',
  'SM.AUTH.LABEL.ROLE_NO_INFO': 'Manage roles in the role tree on the left.',
  'SM.AUTH.LABEL.BIZ_OBJ_LIST': 'Business Object List',
  'SM.AUTH.TITLE.ROLEAUTHVIEW_USERVIEW': 'Employee View',
  'SM.AUTH.LABEL.ROLE_SUPERID': 'Parent Role ID',
  'SM.AUTH.LABEL.ROLE_CONFIG_INFO': 'Configure Role Info',
  'SM.AUTH.LABEL.THIRD_DATASOURCE': 'Third Data Source',
  'SM.AUTH.LABEL.STAFFROLELOG_OPER_TIME': 'Operation Time',
  'SM.AUTH.BUTTON.ADDAUTH': 'Add',
  'SM.AUTH.TIPS.FUNCAUTH_YES': 'Yes',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_STAUTS_DISCARDED': 'Delete',
  'SM.AUTH.MESSAGE.FUNCAUTH_UPLOADSIZE': 'Max. Attachment Size (bytes):',
  'SM.FEDERATION.COMMON.UPDATE.SUCCESS': 'Modification successful.',
  'SM.AUTH.MESSAGE.DATAPER_OBJ_TREE_ROOT': 'Permission Object Tree',
  'SM.AUTH.TITLE.ROLEAUTHVIEW_AUTHVIEW': 'Permission View',
  'SM.AUTH.LABEL.BIZ_OBJ_PRIVACY_LEVEL_CONFIDENTIAL': 'Confidential',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DATA_FILTER_CONDITION_INPUT': 'Data Filter Criteria:',
  'SM.AUTH.LABEL.ROLEBUSINESS_ENTITY_ID': "Role's BE ID",
  'SM.AUTH.MESSAGE.FUNCAUTH_DISABLE': 'Unavailable',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_OPER_TYPE_LIST': 'Operation Type List ID',
  'SM.AUTH.BUTTON.IMPORT_ROLE': 'Import role',
  'SM.FEDERATION.COMMON.RESET': 'Reset',
  'SM.FEDERATION.FEDERATIONAPP.MAPPINGRULE': 'Federated User Mapping Rule',
  'SM.AUTH.TITLE.USERAUTHVIEW_DATA_OBJECT_TREE': 'Permission Object Tree',
  'SM.AUTH.BUTTON.ROLE_COPY_ROLE_AND_CREATE':
    'Copy Function Permission & Data Permission and Create Role',
  'SM.AUTH.LABEL.ROLETMPL_SEARCH_CRITERIA': 'Search Criteria',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_PARENT_NODE_FIELD_INPUT': 'Name of Field PARENT_ID:',
  'SM.AUTH.LABEL.ROLE_EXCLUDEDROLE': 'Excluded Role',
  'SM.AUTH.TITLE.ROLEAUTHVIEW_PARENT_ROLETYPE': 'General Role',
  'SM.FEDERATION.FEDERATIONAPP.EMAIL': 'Email',
  'SM.AUTH.LABEL.ROLE_COPY_MODE_INPUT': 'Copy Mode:',
  'SM.FEDERATION.COMMON.OPERATION': 'Operation',
  'SM.AUTH.LABEL.ROLEPERMISSION_LOGL_IST': 'Role Permission Log List',
  'SM.AUTH.TITLE.ROLE_ASSIGNMENT_TIME': 'Assignment Time',
  'SM.AUTH.LABEL.FUNCAUTH_DEPENDENT': 'Permission Dependent',
  'SM.AUTH.LABEL.STAFFROLELOG_ACCOUNT_INPUT': 'Account:',
  'SM.AUTH.LABEL.FUNCAUTH_LOADMORE': 'Load More',
  'SM.FEDERATION.IMPORT.FILENAMEVALIDATEFAIL':
    'Incorrect file name. Select a file whose name contains only Chinese characters, letters, digits, or underscores (_).',
  'SM.AUTH.TITLE.ROLE_NOT_HOLD': 'Non-grantable Info List',
  'SM.AUTH.TITLE.ROLE_EXTENDED_PROPERTY': 'Extended Attribute',
  'SM.AUTH.TITLE.USER_PERM_COMPARE': 'User Permission Comparison',
  'SM.AUTH.LABEL.BIZ_OBJ_SCOPE': 'Scope',
  'SM.FEDERATION.IMPORT.DOWLOADIMPORTRESULT': 'Download',
  'SM.FEDERATION.IMPORT.UPLOADSELECT': 'Select a file.',
  'SM.AUTH.TITLE.USERAUTHVIEW_OPERTYPE': 'Operation Type',
  'SM.AUTH.LABEL.ROLE_TMPL_LIST': 'Role Template List',
  'SM.AUTH.MESSAGE.DATAPER_CONFIRM_DEL': 'Are you sure you want to delete?',
  'SM.AUTH.LABEL.FUNCAUTH_AUTHNAME': 'Permission Name',
  'SM.AUTH.LABEL.ROLE_COPY_TARGETROLE': 'Target Role',
  'SM.AUTH.BUTTON.ROLE_DESELECT_ALL': 'Deselect All',
  'SM.AUTH.BUTTON.ROLETMPL_SEARCH': 'Search',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_TYPE_TABLE_INPUT': 'Table:',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_BASE_FUNCTION': 'Basic Function Permission ',
  'SM.AUTH.LABEL.ROLE_STAFF_LIST': 'Assigned Employee List',
  'SM.AUTH.TITLE.USERAUTHVIEW_ROLE_ID': 'Role ID',
  'SM.AUTH.MESSAGE.ROLETMPL_CANNOT_SELECT_MODULE': 'The module cannot be selected.',
  'SM.AUTH.MESSAGE.ROLETMPL_TIME_CONDITION': 'The time must be later than the start time.',
  'SM.FEDERATION.IMPORT.IPLODATIP':
    'Importing data... View details in the Federated User Import Result area.',
  'SM.AUTH.TITLE.ROLE_PERMISSION_OBJECT_NAME': 'Permission Object Name',
  'SM.AUTH.TITLE.CONFIRM_PERM_RECOVERY': 'Confirm',
  'SM.AUTH.TITLE.USERAUTHVIEW_TITLE_AUTHNAME_CONF': 'Permission Name',
  'SM.AUTH.TITLE.SELECT_SHORT_CUT': 'Select Shortcut Menu',
  'SM.AUTH.TITLE.ROLE_SHORTCUT_MENU_TOOLBAR': 'Shortcut Menu',
  'SM.AUTH.LABEL.BIZ_OBJ_FIELD_PRIVACY': 'Field Privacy Level',
  'SM.FEDERATION.FEDERATIONAPP.EDIT': 'Modify Federated App',
  'SM.AUTH.LABEL.ROLE_ID_B': 'Role ID (B)',
  'SM.AUTH.LABEL.ROLE_ID_A': 'Role ID (A)',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_IS_BE_FILTER_INPUT': 'Filter by BE:',
  'SM.AUTH.TITLE.USERAUTHVIEW_LOG_OBJ_NAME': 'Object Name',
  'oms.operationparam.msg.unsafeinfo':
    'Note that the network protocol used by the current address is not secure. If you decide to use it, security risks may exist.',
  'SM.AUTH.LABEL.ROLE_TYPE_INPUT': 'Role Type:',
  'SM.AUTH.LABEL.ROLE_COPY_SOURCEROLE_INPUT': 'Source Role:',
  'SM.AUTH.MESSAGE.PASSWORD_RULE_CONFIRM_MESSAGE_41':
    'If the time range is too short, the password security is affected. Are you sure to change it?',
  'SM.AUTH.LABEL.DATA_DICTABLE_ID_INPUT': 'Data Dictionary Code:',
  'SM.AUTH.TITLE.USERAUTHVIEW_USER_INFORMATION': 'Employee Info',
  'SM.FEDERATION.IMPORT.STARTTIME': 'Start Time',
  'SM.AUTH.LABEL.FUNCAUTH_DELETE_CHECK2': 'roles will also be cleared:',
  'SM.AUTH.TIPS.ROLE_INPUTROLENAME': 'Input a role name',
  'SM.AUTH.MESSAGE.ROLETMPL_CANT_COPY_PERMISSION': 'You cannot copy certain permission. Continue?',
  'SM.AUTH.MESSAGE.FUNCAUTH_LANGUAGE': 'Language',
  'SM.AUTH.LABEL.FUNCAUTH_DELETE_CHECK1':
    'If you delete the permission, the authorization information of related employees and the following',
  'SM.AUTH.MESSAGE.PASSWORD_LENTH_LESS_THAN_8':
    'A short password brings security risks. Are you sure you want to use this new password?',
  'SM.FEDERATION.FEDERATIONAPP.ERROR.INVALIDFIELD': 'The field is invalid.',
  'SM.FEDERATION.FEDERATIONAPP.ERROR.PARSEFAIL': 'Failed to parse external user information.',
  'SM.AUTH.LABEL.ROLE_COPY_MODE': 'Copy Mode',
  'SM.AUTH.LABEL.FUNCAUTH_ROLE_GRANTABLE_DETAIL':
    'Roles which can grant the function permission to others',
  'SM.AUTH.LABEL.OPERATION_TYPE_INPUT': 'Operation Type:',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_TREE_INFO_CONF_INPUT': 'Additional Tree Configuration:',
  'SM.AUTH.LABEL.ROLE_TYPE': 'Role Type',
  'SM.FEDERATION.IMPORT.FILESUFFIXVALIDATEFAIL': 'Incorrect file format. Select a XLSX file.',
  'SM.AUTH.LABEL.ROLETMPL_END_TIME_INPUT': 'End Time:',
  'SM.FEDERATION.FEDERATIONAPP.NAME': 'UserAccount',
  'SM.FEDERATION.COMMON.DELETE.SUCCESS': 'Deletion successful.',
  'SM.AUTH.MESSAGE.PASSWORD_RULE_CONFIRM_MESSAGE_39':
    'Opening this rule will affect the password security, please confirm if you continue to open?',
  'SM.AUTH.TITLE.INPUT_ROLE_TMPL_NAME': 'Input role template name',
  'SM.AUTH.TIPS.ROLETMPL_CREATE_AND_ASSIGN': 'Create a role and assign permission',
  'SM.AUTH.LABEL.ROLETMPL_ROLE_ASSOCIATION_LOG': 'Role Association Log',
  'SM.AUTH.TITLE.INPUT_USER_LOGINID': 'Input a user account',
  'SM.FEDERATION.FEDERATIONAPP.ERROR.EXISTS':
    'The third-party authentication system name, URL, or mapping rule name already exists.',
  'SM.AUTH.LABEL.EMPLOYEEINCLUDED': 'Include Sub-OU Personnel',
  'SM.AUTH.LABEL.FUNCAUTH_AUTHTYPE_INPUT': 'Permission Type:',
  'SM.AUTH.BUTTON.SAVE': 'Save',
  'SM.AUTH.TITLE.PERMISSION_SELECTION': 'Select Permission',
  'SM.AUTH.LABEL.STAFFROLELOG_OPER_ACCOUNT_INPUT': 'Operator:',
  'SM.AUTH.LABEL.ROLETMPL_PERMISSION_NAME_INPUT': 'Function Permission Name:',
  'SM.AUTH.LABEL.FUNCAUTH_MODULE_INPUT': 'Subsystem ID:',
  'SM.AUTH.TITLE.ROLE_PERMISSION_TIPS':
    'To perform batch operations on the permission tree, use shortcut menus on permission nodes.',
  'SM.FEDERATION.IMPORT.FAILCOUNT': 'Failed Records',
  'SM.AUTH.LABEL.ROLE_ID_INPUT': 'Role ID:',
  'SM.AUTH.LABEL.FUNCAUTH_BASICINFO': 'Basic Info',
  'SM.AUTH.MESSAGE.ROLE_ERROR_BEED_EXCLUDED':
    'Failed to delete the role because it excludes another role.',
  'SM.AUTH.TITLE.USERAUTHVIEW_ROLE_NAME': 'Role Name',
  'SM.AUTH.TITLE.ROLEAUTHVIEW_DATAAUTHLIST': 'Permission Object List',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DESC_FIELD': 'Name of Field Name',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_KEY_FIELD': 'Name of Field ID',
  'SM.AUTH.OPTION.ROLE_AUTH_REMOVE': 'Delete',
  'SM.AUTH.TITLE.ROLE_PERMISSION_OBJECT_ID': 'Permission Object ID',
  'SM.AUTH.MESSAGE.ROLE_DELETE_SUCCESSFUL': 'Deleted successfully.',
  'SM.AUTH.LABEL.PERMISSION': 'Permission',
  'SM.AUTH.LABEL.ROLE_PARENTROLE_ID': 'General Role ID',
  'SM.AUTH.TITLE.USERAUTHVIEW_AUTH': 'Function Permission',
  'SM.AUTH.LABEL.FUNCAUTH_ATTRIBUTE': 'Extend Attribute',
  'SM.AUTH.BUTTON.USERAUTHVIEW_RESET': 'Reset',
  'SM.AUTH.TITLE.ASSIGN_EMPLOYEE': 'Assign Employee',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_TYPE_TABLE': 'Table',
  'SM.AUTH.MESSAGE.DATAPER_DEL_SUCCESS': 'Deleted successfully.',
  'SM.AUTH.TITLE.USERAUTHVIEW_ACCOUNTLOGIN': 'Account',
  'SM.FEDERATION.COMMON.CONFIRM': 'Confirm',
  'SM.AUTH.TIPS.ROLETMPL_VIEW_DETAIL_PERMISSION':
    'View details about permissions you cannot copy in the role template. ',
  'SM.AUTH.TIPS.ROLETMPL_ASSIGN_PERMISSON': 'Assign Permission',
  'SM.AUTH.TITLE.ROLE_BASIC_FUNCTION_PERMISSION': 'Basic Function Permission ',
  'SM.AUTH.HEADVALUE.AUTHUSERVIEW_AUTH_DATA': 'Please choose...',
  'SM.AUTH.MESSAGE.OPERATEFAIL': 'Saving failed.',
  'SM.FEDERATION.FEDERATIONAPP.ERROR.PARAMNULL': 'The request parameter is empty.',
  'SM.AUTH.LABEL.ROLETMPL_TEMPLATENAME_INPUT': 'Role Template Name:',
  'SM.AUTH.TITLE.CUSTOMER_ROLESCOPE_PERMISSION':
    'When the scope of use is not an employee, you can not assign roles to employees',
  'SM.FEDERATION.COMMON.SUCCESS': 'Success',
  'SM.AUTH.MESSAGE.PASSWORD_RULE_CONFIRM_MESSAGE_12':
    'Disabling this rule will affect password security. Are you sure you want to disable it?',
  'SM.AUTH.LABEL.OPPAUTH_SOURCE_TYPE': 'Permission Source',
  'SM.AUTH.MESSAGE.PASSWORD_RULE_CONFIRM_MESSAGE_10':
    'Valid special characters will affect password security. Are you sure you want to use them?',
  'SM.AUTH.LABEL.ROLETMPL_PERMISSION_ID': 'Function Permission ID',
  'SM.AUTH.LABEL.FUNCAUTH_ROLE_AVAILABLE_DETAIL': 'Roles which can use the function permission',
  'SM.AUTH.TITLE.ROLE_DELEVER_DATA_PERMISSION': 'Grant Data Permission',
  'SM.FEDERATION.IMPORT.UNDO': 'Pending',
  'SM.AUTH.LABEL.ROLE_PARENTS_TITLE': 'Role Inheritance Info',
  'SM.FEDERATION.FEDERATIONAPP.ACCOUNT': 'Account',
  'SM.AUTH.LABEL.ROLE_COPY_TARGETROLE_INPUT': 'Target Role:',
  'SM.AUTH.MESSAGE.SELECTA_ROLE': 'Select a role.',
  'SM.AUTH.BUTTON.USERAUTHVIEW_SEARCH': 'Search',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_SERVICE': 'Service Name',
  'SM.AUTH.BUTTON.PERM_RECOVERY': 'Recycle',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_IS_BE_FILTER': 'Filter by BE',
  'SM.AUTH.MESSAGE.ROLE_SURE_TO_DELETE': 'Are you sure you want to delete?',
  'SM.AUTH.LABEL.ROLE_ID_A_INPUT': 'Role ID (A):',
  'SM.AUTH.LABEL.DATAAUTHMGR_TAB_AUTH_ROLE_VIEW': 'Roles Associated with Data Permission',
  'SM.AUTH.LABEL.ROLE_DERIVE_NORMALROLE': 'Derived Common Role',
  'SM.AUTH.LABEL.ROLE_ASSIGN_STAFF': 'Assigned Employee',
  'SM.AUTH.TITLE.ROLE_TMPLATE_ID': 'Role Template ID',
  'SM.AUTH.LABEL.ATTR_LANGUAGE': 'Language',
  'SM.AUTH.LABEL.BIZ_OBJ_FIELD_PRIVILEGE_SETTING': 'Setting',
  'SM.AUTH.BUTTON.EXPORT': 'Export',
  'SM.AUTH.MESSAGE.OPERATE_WITH_ROLES':
    'The permission is successfully created, and assigned to the current operator and the following roles:',
  'SM.AUTH.MESSAGE.ROLETMPL_DELETE_SUCCESS': 'Deleted successfully.',
  'SM.AUTH.LABEL.STAFFROLELOG_ROLE_BE_ID_INPUT': "Role's BE:",
  'SM.AUTH.TIPS.FUNCAUTH_STOPUSE': 'Suspend',
  'SM.AUTH.MESSAGE.OPERATE_WITHOUT_ROLES':
    'The permission is successfully created, and  assigned to the current operator.',
  'SM.AUTH.MESSAGE.ROLE_OPP_WITH_PARENT': 'Selected role is opposite with inherited role:',
  'SM.AUTH.LABEL.STAFFROLELOG_ROLE_NAME_INPUT': 'Role Name:',
  'SM.AUTH.TIPS.ENTER_AUTHNAME': 'Input the permission name',
  'SM.AUTH.TITLE.TO_CHOOSE_ROLE_TMPL_INPUT': 'Role template to choose:',
  'SM.AUTH.TITLE.USERAUTHVIEW_AUTH_MODULE': 'Module',
  'SM.AUTH.MESSAGE.FUNCAUTH_PAUTHNAME': 'Permission Name',
  'SM.AUTH.LABEL.LOGINLOG_RESET': 'Reset',
  'SM.AUTH.LABEL.FUNCAUTH_AUTHID_INPUT': 'Permission ID:',
  'SM.AUTH.TITLE.ROLE_BASIC_DATA_PERMISSION': 'Basic Data Permission',
  'SM.AUTH.MESSAGE.ROLE_CAN_NOT_ADD_ITSELF': 'Cannot inherit its own role. ',
  'SM.FEDERATION.MAPPINGRULE.NAME': 'Mapping Rule',
  'SM.AUTH.MESSAGE.SAVE_DEPENDENT_SELF': 'Permission dependent itself, this is not allowed.',
  'SM.FEDERATION.IMPORT.STATUS': 'Status',
  'SM.AUTH.LABEL.FUNCAUTH_ROLE_UNAVAILABLE_DETAIL':
    'Roles which can not use the function permission',
  'SM.AUTH.LABEL.ROLETMPL_HOME_ENTITY': "Permission's Home BE",
  'SM.AUTH.TITLE.USERAUTHVIEW_AUTH_DATA': 'Data Permission',
  'SM.AUTH.LABEL.BIZ_OBJ_FIELD_IS_WRITEABLE': 'Is Writeable',
  'SM.AUTH.MESSAGE.FUNCAUTH_NORMAL': 'Normal',
  'SM.AUTH.MESSAGE.ROLE_NAME_SAME': 'The role name already exists.',
  'SM.AUTH.LABEL.USERAUTHVIEW_SEARCH': 'Query',
  'SM.AUTH.LABEL.DATAPROVIDER_TYPE': 'Data Value Source Type',
  'SM.AUTH.BUTTON.ROLE_COPY_ROLE':
    'Copy Role Info (Only Copy Function Permission & Data Permission)',
  'SM.AUTH.TITLE.USERAUTHVIEW_USER_LIST': 'Employee List',
  'SM.AUTH.LABEL.USER_ACCOUNTB': 'Account (B)',
  'SM.AUTH.LABEL.ROLETMPL_PERMISSION_NAME': 'Function Permission Name',
  'SM.AUTH.LABEL.USER_ACCOUNTA': 'Account (A)',
  'SM.FEDERATION.COMMON.DOWLOADTEM': 'Download Template',
  'SM.AUTH.LABEL.ROLE_NAME': 'Role Name',
  'SM.AUTH.LABEL.ROLETMPL_ROLE_NAME': 'Role Name',
  'SM.AUTH.LABEL.ORGANIZATION_INPUT': 'Organization Unit:',
  'SM.AUTH.LABEL.ROLE_SCOPE': 'Role Scope',
  'SM.AUTH.MESSAGE.ROLE_NOT_EXSIT': 'The role does not exist.',
  'SM.AUTH.LABEL.AUTHVIEW_ROLE_BE_ID': "Role's Home BE ID",
  'SM.AUTH.MESSAGE.DATAPER_TREE_ROOT': 'Data Permission Tree',
  'SM.AUTH.TITLE.SUCCESS': 'Information',
  'SM.AUTH.BUTTON.AUTH_EDIT': 'Modify',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_OPERATION_INPUT': 'Operation Name:',
  'SM.AUTH.NO.SELECT': 'No permission is selected.',
  'SM.AUTH.MESSAGE.AUTH_DATA_OBJECT_TREE': 'Permission Object Tree',
  'SM.FEDERATION.COMMON.CREATE.FAIL': 'Creation failed.',
  'SM.FEDERATION.FEDERATIONAPP.NEW': 'Create Federated App',
  'SM.AUTH.BUTTON.ROLE_SELECT_ALL': 'Select All',
  'SM.AUTH.LABEL.FUNCAUTH_PARENTAUTH_INPUT': 'Parent Permission ID:',
  'SM.AUTH.LABEL.USER_ACCOUNTB_INPUT': 'Account (B):',
  'SM.FEDERATION.COMMON.QUERY.CONDITION': 'Search Criteria',
  'SM.AUTH.BUTTON.CANCEL': 'Cancel',
  'SM.AUTH.TITLE.USERAUTHVIEW_LOG_OBJ_ID': 'Object ID',
  'SM.AUTH.TITLE.USERAUTHVIEW_QUERYCONDITION_TITLE': 'Search Criteria',
  'SM.AUTH.LABEL.ROLE_ID': 'Role ID',
  'SM.AUTH.LABEL.DATAAUTHMGR_ATTRIBUTE': 'Extend Attribute',
  'SM.FEDERATION.DOWNLOAD.RESULT': 'Download Federated User Import Result',
  'SM.FEDERATION.FEDERATIONAPP.CLIENTURL': 'URL',
  'SM.AUTH.LABEL.ROLETMPL_END_TIME': 'End Time',
  'SM.AUTH.LABEL.STAFFROLELOG_ROLE_BE_ID': "Role's BE",
  'SM.AUTH.TITLE.ASSIGNED_EMPLOYEE': 'Assigned Employee',
  'SM.AUTH.LABEL.ROLETMPL_BASIC_FUNCTION_PERMISSION': 'Basic Function Permission ',
  'SM.AUTH.LABEL.ROLETMPL_DESCRITPION': 'Description',
  'SM.AUTH.TITLE.ADD_ROLE_FROM_ROLE_TMPL': 'Select Role Template',
  'SM.AUTH.TITLE.USERAUTHVIEW_QUERY_AUTHTYPE': 'Permission Type',
  'SM.FEDERATION.IMPORT.SUCCESSCOUNT': 'Successful Records',
  'SM.AUTH.LABEL.ROLE_CODE': 'Role Code',
  'SM.AUTH.TIPS.ROLETMPL_WARNING': 'Warning',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_BASE_DAUTH': 'Basic Data Permission',
  'SM.AUTH.TITLE.CUSTOMER_ROLESCOPE': 'Customer',
  'SM.AUTH.LABEL.STAFFROLELOG_ROLE_ID': 'Role ID',
  'SM.AUTH.LABEL.STAFFROLELOG_ACCOUNT': 'Account',
  'SM.AUTH.LABEL.ROLE_DERIVE': 'Role Inheritance',
  'SM.AUTH.LABEL.BIZ_OBJ_FULL_NAME': 'Object Full Name',
  'SM.AUTH.LABEL.ROLE_COPY_SOURCEROLE': 'Source Role',
  'SM.AUTH.MESSAGE.ROLE_SELECT_A_ROLE': 'Select a role.',
  'SM.AUTH.LABEL.DETAIL_INFO':
    'Select a permission node in the navigation tree on the left to view the information about the permission.',
  'SM.FEDERATION.FEDERATIONAPP.ERROR.ISUSED':
    'The data cannot be deleted or modified because it has been used by a tenant.',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_TREE_INFO_CONF': 'Additional Tree Configuration',
  'SM.AUTH.LABEL.FUNCAUTH_ISDY': 'Dynamic Configuration Flag',
  'SM.AUTH.LABEL.ROLETMPL_TEMPLATENAME': 'Role Template Name',
  'SM.AUTH.BUTTON.CONFIRM_DELETE': 'Confirm Delete',
  'SM.AUTH.TITLE.USERAUTHVIEW_AUTHVIEW_DATAAUTHLIST': 'Permission Object List',
  'SM.AUTH.TITLE.TO_CHOOSE_ROLE_TMPL': 'Select Role Template',
  'SM.AUTH.LABEL.CLEAR_CONFIRM_CLEAR_DATA':
    'Are you sure you want to delete permissions not used recently?',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_ID_TREE_INPUT': 'Tree Structure:',
  'SM.AUTH.LABEL.BIZ_OBJ_PRIVACY_LEVEL_PUBLIC': 'Public',
  'SM.FEDERATION.FEDERATIONAPP.REMOTETIP':
    'The value is the mapping field corresponding to the external user.',
  'SM.AUTH.LABEL.ROLE_AUTH_DATA_INPUT': 'Data Permission:',
  'SM.AUTH.LABEL.PERMISSION_CLASS_INPUT': 'Permission Classification:',
  'SM.FEDERATION.MAPPINGRULE.DESC': 'Description',
  'SM.AUTH.LABEL.ROLE_SUPERCODE_INPUT': 'Parent Role Code:',
  'SM.FEDERATION.IMPORT.RESULT': 'Federated User Import Result',
  'SM.AUTH.TITLE.ROLEAUTHVIEW_COMMON_ROLETYPE': 'Common role',
  'SM.AUTH.LABEL.ROLE_SUPERCODE': 'Parent Role Code',
  'SM.AUTH.LABEL.ROLETMPL_ROLE_ID': 'Role ID',
  'SM.AUTH.LABEL.CONFIRM_DELETE_DEPEND_AUTH': 'Confirm to delete depend permission ?',
  'SM.AUTH.MESSAGE.ROLETMPL_SHOWNAME': 'Role Template',
  'SM.AUTH.TITLE.ROLE_MUTUALLY_EXCLUSIVE_PERMISSION': 'Mutually-Exclusive Permission List',
  'SM.AUTH.LABEL.COMPARE_RESULT': 'Comparison Result',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_CODE_INPUT': 'Permission Code:',
  'SM.FEDERATION.FEDERATIONAPP.DELETETIP': 'Are you sure you want to delete the data?',
  'SM.AUTH.TITLE.USERAUTHVIEW_AUTH_STATUS': 'Status',
  'SM.AUTH.TITLE.BELONG_ROLE_TMPL': 'Owner Role Template',
  'SM.AUTH.LABEL.FUNCAUTH_ROLE_AVAILABLE': 'Available Permission of Role',
  'SM.AUTH.LABEL.USER_ACCOUNTA_INPUT': 'Account (A):',
  'SM.FEDERATION.COMMON.FAIL': 'Error',
  'SM.FEDERATION.IMPORT.IMPORTRESULT': 'Import Result',
  'SM.AUTH.OPTION.ROLE_REMOVE_INFO_OF_TARGET_ROLE': 'Delete Target Role Info',
  'SM.FEDERATION.IMPORT.UPLOADFILE': 'File',
  'SM.AUTH.TITLE.USERAUTHVIEW_AUTHSOURCE': 'Permission Source',
  'SM.AUTH.TITLE.AUTHNAMEINFO': 'Permission Name Info',
  'SM.AUTH.LABEL.LOGINLOG_SEARCH': 'Search',
  'SM.AUTH.TIPS.ROLE_PARENTROLE': 'Role Name',
  'SM.AUTH.LABEL.ROLE_DESC': 'Description',
  'SM.FEDERATION.IMPORT.ENDTIME': 'End Time',
  'SM.AUTH.LABEL.FUNCAUTH_OPPAUTHNAME': 'Mutually-Exclusive Permission',
  'SM.FEDERATION.COMMON.OPERATION.DELETE': 'Delete',
  'SM.FEDERATION.FEDERATIONAPP.LIST': 'Federated Apps',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_PUT_DAUTH': 'Grantable Data Permission',
  'SM.AUTH.LABEL.ROLE_NORMALROLE_ID': 'Common Role Code',
  'SM.AUTH.TITLE.ROLE_TMPL_ROLE_LIST': 'Role List',
  'SM.FEDERATION.COMMON.OPERATION.EDIT': 'Edit',
  'SM.AUTH.LABEL.ROLE_ROLE_NAME': 'Role Name',
  'SM.AUTH.MESSAGE.ROLETMPL_SELECT_NO_ENTITY': 'Select at least one role or BE.',
  'SM.AUTH.BUTTON.CLEAR_ALL_TIPS': 'Clear Unavailable Permission',
  'SM.AUTH.MESSAGE.OK': 'OK',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_CODE': 'Permission ID',
  'SM.AUTH.TITLE.CONFIRM_DEL': 'Confirm',
  'SM.AUTH.OPTION.ROLEAUTHVIEW_OPP_FUNCTION': 'Excluded Function Permission ',
  'SM.AUTH.LABEL.FUNCAUTH_MODULE': 'Subsystem ID',
  'SM.AUTH.LABEL.ROLE_INFO': 'Role Info',
  'SM.AUTH.LABEL.DATAAUTHMGR_TITLE_AUTHNAME_CONF': 'Permission Name',
  'SM.AUTH.MESSAGE.ROLETMPL_SELECT_NODE_INFO':
    'Select a parent node in the navigation tree on the left and manage role templates in the role template tree.',
  'SM.AUTH.LABEL.MENU_AUTHID': 'Permission ID',
  'SM.AUTH.TITLE.INFO': 'Information',
  'SM.AUTH.MESSAGE.FUNCAUTH_READONLY': 'Neither-assignable',
  'SM.AUTH.LABEL.ROLETMPL_START_TIME_INPUT': 'Start Time:',
  'SM.FEDERATION.IMPORT.FAILFILE': 'Failed Files',
  'SM.AUTH.LABEL.FUNCAUTH_DESC_INPUT': 'Description:',
  'SM.AUTH.LABEL.BIZ_OBJ_NAME': 'Name',
  'SM.AUTH.LABEL.ORGANIZATION': 'Organization Unit',
  'SM.AUTH.BUTTON.DELETE': 'Delete',
  'SM.AUTH.BUTTON.CLOSE': 'Close',
  'SM.AUTH.MESSAGE.PERM_RECOVERY':
    "If you click 'OK', permissions of all the following roles are revoked. If you click 'Cancel', no operation will be performed.",
  'SM.AUTH.MESSAGE.ROLE_RELATIONSHIP_EXISTS_NOT_BEEXCLUDING':
    'The inheritance relationship already exists and the role cannot be excluded.',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_KEY_FIELD_INPUT': 'Name of Field ID:',
  'SM.FEDERATION.IMPORT.FAILREASON': 'Failure Cause',
  'SM.AUTH.LABEL.FUNCAUTH_UPLOAD': 'Upload Attachment',
  'SM.AUTH.LABEL.BIZ_OBJ_FIELD': 'Field',
  'SM.AUTH.BUTTON.ROLE_DELETE_FOREVER': 'Delete Permanently',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DYNA_CONF_INPUT': 'Dynamic Configuration Flag:',
  'SM.FEDERATION.COMMON.CREATE.SUCCESS': 'Creation successful.',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DESC_INPUT': 'Description:',
  'SM.AUTH.LABEL.BE_BASIC_INFORMATION': 'Basic Info',
  'SM.AUTH.TITLE.USERAUTHVIEW_NAME': 'Employee',
  'SM.AUTH.MESSAGE.SAVE_SUCCESS': 'Operation performed successfully.',
  'SM.AUTH.LABEL.ROLE_DESC_INPUT': 'Description:',
  'SM.AUTH.TITLE.USERAUTHVIEW_STAFF_BE_NAME': "Employee's Home BE",
  'SM.AUTH.LABEL.ROLETMPL_ROLETMPL_INFO': 'Role Template Info',
  'SM.AUTH.TITLE.TO_ASSIGNED_EMPLOYEE': 'To-Assign Employee',
  'SM.AUTH.LABEL.ROLE_NORMALROLE_NAME': 'Common Role Name',
  'SM.AUTH.OPTION.GEANTABLE_FUNCTION_PERM': 'Grantable Function Permission ',
  'SM.AUTH.LABEL.BIZ_OBJ_ACCESS_RULE': 'Access Rule',
  'SM.AUTH.LABEL.ROLETMPL_DESCRITPION_INPUT': 'Description:',
  'SM.AUTH.TITLE.USERAUTHVIEW_ROLE_PARENTS_TITLE': 'Role Inheritance Info',
  'SM.AUTH.TIPS.ENTER_ORGNAME': "Input the organization's name",
  'SM.FEDERATION.MAPPINGRULE.REMOTE': 'External User Information',
  'SM.AUTH.MESSAGE.FUNCAUTH_FUNCTION': 'Function',
  'SM.FEDERATION.COMMON.QUERY': 'Search',
  'SM.AUTH.LABEL.ROLETMPL_FUNCTION_PERMISSION': ' Function Permission',
  'SM.AUTH.LABEL.STAFFROLELOG_OPER_ACCOUNT': 'Operator',
  'SM.AUTH.MESSAGE.ROLE_ERROR_SUBROLES': 'The role cannot be deleted because it has subroles.',
  'SM.AUTH.LABEL.CLEAR_TIMEOUT_SUCCESS_MSG':
    'Deleted permissions that are unavailable during the time successfully. ',
  'SM.AUTH.TITLE.USERAUTHVIEW_USER_LOGINID': 'Account',
  'SM.AUTH.LABEL.AUTHVIEW_ROLE_BE_NAME': "Role's Home BE",
  'SM.AUTH.LABEL.BIZ_OBJ_IS_OWNER': 'Is Owner',
  'SM.AUTH.OPTION.ROLE_RETAIN_INFO_OF_TARGET_ROLE': 'Retain Target Role Info',
  'SM.AUTH.LABEL.BIZ_OBJ_ACCESS_RULE_ROLE_POLICY': 'Role Access Policy',
  'SM.AUTH.LABEL.PERMRECOVERY_ROLE_NAME': 'Role with Permission',
  'SM.AUTH.LABEL.FUNCAUTH_DESC': 'Description',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_DESC': 'Description',
  'SM.AUTH.LABEL.DETAIL': 'Details',
  'SM.AUTH.TITLE.INPUT_AUTHID_AUTHNAME': 'Input a permission ID or name',
  'SM.AUTH.LABEL.TITLE_CLEAR_SUCCESS': 'Prompt',
  'SM.AUTH.LABEL.STAFFROLELOG_ROLE_ID_INPUT': 'Role ID:',
  'SM.AUTH.LABEL.FUNCAUTH_AUTHNAME_INPUT': 'Permission Name:',
  'SM.AUTH.LABEL.ROLE_OPERTYPE': 'Operation Type',
  'SM.AUTH.LABEL.ROLETMPL_ROLE_NAME_INPUT': 'Role Name:',
  'SM.AUTH.TITLE.USERAUTHVIEW_DETAIL_INFO': 'Select a permission node with a URL.',
  'SM.FEDERATION.FEDERATIONAPP.VALDATEJSON': 'The configuration item must be in JSON format.',
  'SM.AUTH.LABEL.FUNCAUTH_INFO': 'Basic Info',
  'SM.AUTH.TITLE.INPUT_PERMISSION_NAME': 'Input a permission name',
  'SM.AUTH.LABEL.DATAAUTHMGR_LABEL_AUTH_ID_TREE': 'Tree Structure',
  'SM.AUTH.MESSAGE.DELETE_CONFIRM': 'Are you sure you want to delete?',
  'SM.AUTH.TITLE.THIRD_DATASOURCE':
    'Operation performed successfully (The third data source is closed, because of the data source is empty.).',
  'SM.AUTH.TITLE.ADD_ROLE_FROM_AUTH_TREE': 'Select Function Permission',
  'SM.FEDERATION.COMMON.CANEL': 'Cancel',
  'SM.FEDERATION.COMMON.SUBMIT': 'Submit',
  'SM.AUTH.OPTION.ROLE_AUTH_ADD': 'Add',
  'SM.AUTH.MESSAGE.ROLE_INHERITANCE_RELATIONSHIP_EXISTS': 'The inheritance already exists.',
  'SM.AUTH.LABEL.ASSIGNEMPLOYEE': 'Employee Assignment',
  'SM.AUTH.LABEL.ASSIGNRIGHTS': 'Assignable Permissions',
  'SM.AUTH.LABEL.OWNEDRIGHTS': 'Own permissions',
  'SM.ORGSTAFF.LABEL.ALLORG': 'All Organizations',
  'SM.FEDERATION.USER.TEMPLATE': 'Download Federated User Import Template',
  'common.baseui.MESSAGE.VALIDATE_MAXLENGTHB': 'A maximum of 0 characters are allowed.',
  'SM.GADGET.VALUE.MENUPERM': 'Menu permissions',
  'SM.GADGET.VALUE.FUNCPERM': 'Operation permissions',
  'SM.GADGET.VALUE.INTFPERM': 'API permissions',
  'SM.ROLE.PARENT.ROLE': 'Upper-Level Role',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_BATCH_SET_ROLE': 'Adding Roles in Batches',
  'SM.ORGSTAFF.LABEL.EMPLOYEE_BATCH_DELETE_ROLE': 'Deleting Roles in Batches',
  'SM.USERPWDGROUP.RANGE.VALIDATE': 'The input range is %-%.',
  'SM.ROLE.SELECT.BATCH': 'Please select a role',
  'SM.ROLE.SELECT.ERRORMSG': 'Exception Description：',
  'SM.ROLE.SELECT.ERRORCAUSE': 'Exception Cause：',
  'SM.ROLE.SELECT.ERRORSLOUTION': 'Handling Suggestions：',
  'SM.ROLE.MESSAGE.DELETE_CONFIRM': 'After a role is deleted, it can only be re-created. Delete?',
  'SM.ORG.DISABLE.FAILED.PARENT':
    'Disable all child organizations of the organization before disabling the current organization.',
  'SM.ORG.DISABLE.FAILED.EMPLOYEE':
    'Delete all employees in the organization, and then disable the organization.',
  'SM.EMPLOYEE.SELECTED.EMPLOYEE': 'Selected Employees',
  'SM.EMPLOYEE.ALLOCATE.WORKNO': 'Assigning an Employee ID',
  'SM.EMPLOYEE.SELECTED.WORKNO.NOT.ENOUGH':
    'The number of agent IDs that can be allocated to the selected agent type is insufficient.',
  'SM.EMPLOYEE.CHECKED-IN.PASSWORD': 'Sign-in Password',
  'SM.EMPLOYEE.SOFTCONSOLE.GROUP': 'Home Traffic Group',
  'SM.EMPLOYEE.INSPECTION.GROUP': 'Home QC group',
  'SM.EMPLOYEE.PLATFORM.CONFIG': 'Platform Configuration',
  'SM.EMPLOYEE.CONFIG.EMPLOYEE': 'Configuring Employees',
  'SM.EMPLOYEE.CANNOT.CHOOSE.WORKNO':
    'You cannot select an employee who has been assigned an employee ID.',
  'SM.EMPLOYEE.OPERATE.MAX.HUNDRED': 'A maximum of 100 employees can be operated at a time.',
  'SM.EMPLOYEE.SKILL.INFO': 'Skill Information',
  'SM.EMPLOYEE.FEATURE.CONFLICT':
    'Intelligent recognition and dual-track recording cannot be enabled at the same time. If they are enabled, the other one will be deselected.',
  'SM.EMPLOYEE.BATCH.CONFIG': 'Batch configuration',
  'SM.TENANT.LABEL': 'Tenant',
  'SM.TENANT.INFO': 'Tenant information',
  'SM.TENANT.VIEW': 'Visit Tenant',
  'SM.TENANT.EDIT': 'Edit Tenant',
  'SM.TENANT.CREATE': 'Add Tenant',
  'SM.TENANT.TENANTSPACENAME': 'TenantSpaceName',
  'SM.TENANT.TENANTNAME': 'TenantName',
  'SM.TENANT.ADMINACCOUNT': 'AdminAccount',
  'SM.TENANT.PHONE': 'Phone',
  'SM.TENANT.CREATETIME': 'Creation time',
  'SM.TENANT.DELETE_CONFIRM':
    'Deleted tenants cannot be restored. Are you sure you want to delete them?',
  'SM.PWDRULE.PLEASE.INPUT': 'Enter a rule group name',
  'SM.EMPLOYEE.CANNOT.CHOOSE.STATUS': 'Employees in disabled state cannot be selected.',
  'SM.EMPLOYEE.CANCEL.CONFIG.CONFIRM': 'Are you sure you want to cancel the configuration?',
  'SM.EMPLOYEE.ROLE.EFFECT': "The employee's role information will take effect [] minute later.",
  'SM.ROLE.AUTH.EFFECT': 'The permission information of the role will take effect [] minute later.',
  'SM.ROLE.TEMPLATE': 'Role Template',
  'SM.LABEL.APPCUBE.SWITCH': 'AppCube-Integrated Page',
  'SM.LABEL.APPCUBE.APP': 'Select Application',
  'SM.LABEL.APPCUBE.MENU': 'Select Menu',
  'SM.MENU.NAME.SPECIAL.CHAR':
    "Special characters are not allowed: !#{'$'}%&()￥+:\"{'{'}{'}'},\\/'[]=-^<>",
  'SM.COMMON.MESSAGE.DUTY.FORBIDDEN':
    'The selected employees contain employees that have been forbidden.',
  'SM.LABEL.ACCOUNT': 'Please enter an account',
  'SM.LABEL.USERNAME': 'Please enter a user name',
  'SM.LABEL.EMAIL': 'Please enter an email address',
  'SM.LABEL.PHONE': 'Please enter the phone number',
  'SM.LABEL.PASSWORD': 'Please enter the password',
  'SM.LABEL.DESCRIPTION': 'Please enter a description',
  'SM.ORGSTAFF.BUTTON.SaveAndConfigure': 'SaveAndConfigure',
  'SM.ROLE.BUTTON.DELETE': 'Are you sure you want to delete the selected employees?',
  'SM.ROLE.ADD': 'Create Role',
  'SM.ROLE.ADD.NAME': 'Please enter a role name',
  'SM.ROLE.ADD.DECRI': 'Please enter remarks',
  'sumweb.export.confirm.exportall': 'You have not selected any records. Export all records? You can also click a specific record to export.',
  'sumweb.export.export.select': 'You have selected {0} record. Are you sure you want to continue.',
  'sumweb.export.org': 'Exporting Organization Information',
  'sumweb.export.employee': 'Exporting Employee Information',
  'sumweb.view.import.list': 'Import List',
  "sumweb.import.success": "An import task has been created. Are you sure you want to view the import list?",
  "SM.ROLE.EDIT": "Edit Role",
  "SM.AUTH.BUTTON.CONFIRM.CLOSE": "Confirm Close",
  "sumweb.agent.config": "Configure Agent",
  "sumweb.employee.export.nodata": "No employee data meets the requirements.",
  "sumweb.employee.view.config": "View Configuration"
}
