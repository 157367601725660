import i18n from '@/lang/index'

const $t = i18n.global.t

export const statusItems = [
    { label: $t('cms.agentmonitor.label.offline'), value: '0' },
    { label: $t('cms.agentmonitor.label.idleState'), value: '1' },
    { label: $t('cms.agentmonitor.label.preoccupationState'), value: '2' },
    { label: $t('cms.agentmonitor.label.answering'), value: '3' },
    { label: $t('cms.agentmonitor.label.occupationState'), value: '4' },
    { label: $t('cms.agentmonitor.label.talkingState'), value: '5' },
    { label: $t('cms.agentmonitor.label.adjust'), value: '6' },
    { label: $t('cms.agentmonitor.label.busyState'), value: '7' },
    { label: $t('cms.agentmonitor.label.restState'), value: '8' },
    { label: $t('cms.agentmonitor.label.learnState'), value: '9' },
    { label: $t('cms.agentmonitor.label.readjustmentState'), value: '10' }
  ];

  export const currentStates = ['0','1','2','3','4','5','6','7','8','9','10']

  export const monitorStateItems = [
    { label: $t('cms.agentmonitor.label.noListenedAndInserted'), value: '0' },
    { label: $t('cms.agentmonitor.label.listenedAndInserted'), value: '1' }
  ]

  export const monitorAlarmItems = [
    { label: $t('cms.agentmonitor.label.noAlarm'), value: '0' },
    { label: $t('cms.agentmonitor.label.haveAlarm'), value: '1' }
  ]

  // 座席类型映射
  export const agentTypeMap = {
    "audio" : 4,
    "video": 11,
    "multiMedia": 5,
    "versatile": 99
  }

  // 座席状态映射
  export const agentStatusMap = {
    "idle": 1,
    "busy": 7,
    "rest": 8,
    "checking": 3,
    "calling": 5,
    "arrange": 6,
    "adjustment": 10,
    "offline":0,
    "occur":4
};
// 通话状态映射
export const talkingStatusMap = {
    "mutilMediaAndAudio": 0,
    "onlyAudio": 1,
    "onlyMutilMedia": 2
}

export const monitorMap = {
  "nomonitor" : 0,
  "monitoring" : 1,
  "mediaTypeMonitoring" : 2
}

export const monitorKind = {
  "listen" : 0,
  "insert" : 1,
  "whisper": 2,
  "none" : 99
}

//状态恢复编码
export const STATUS_RECOVER = {
  LISTEN : 0,
  INSERT: 1,
  WHISPER: 2
}

//接续条状态编码
export const CALLBAR_STATUS = {
    LISTN: 9,
    INSERT: 10,
    WHISPER: 14
}

// 媒体类型编码
export const MediaType = {
  "vocieClick":"2", 
   "voice":"5", 
   "vedioClick" :"18", 
  "video": "19", 
   "mutil" :"53", 
}

export const roleType =  [{
  "value": "1",
  "label": $t('cms.agent.field.admin')
},{
  "value": "2",
  "label": $t('cms.agent.field.commonagent')
},{
  "value":"3",
  "label": $t('cms.agent.field.qualitychecker')
}]

export const mediaTypeList =  [{
      "value": "5",
      "label": $t('cms.voiceinspection.field.ordinaryVoiceCall')
  },{
      "value": "53",
      "label": $t('cms.voiceinspection.field.socialChat')
  },{
      "value":"19",
      "label": $t('cms.report.field.mediatype.vedio')
  },{
      "value":"2",
      "label": $t('cms.voiceinspection.field.clickToCall')
  },{
      "value":"18",
      "label":$t('cms.voiceinspection.field.videoClickToCall')
  }]

  // 媒体类型
  export const mediaTypeMap = {
    0: $t('cms.monitordetail.field.audio')+","+$t('cms.monitordetail.field.media'),
    1: $t('cms.monitordetail.field.audio'),
    2: $t('cms.monitordetail.field.media')
};

// 媒体类型
export const talkingStatusLabel = [
  {"value": "0",
  "label": $t('cms.monitordetail.field.audio')+","+$t('cms.monitordetail.field.media')},
  {"value": "1",
  "label": $t('cms.monitordetail.field.audio')},
  {"value": "2",
  "label": $t('cms.monitordetail.field.media')}
]

export function getForMatTime(value) {
  if (value == '0') {
    return '0s'
  }
  let result = parseInt(value)
  let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
  let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
  let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));
  let res = '';
  if(h !== '00') res += `${h}h`;
  if(m !== '00') res += `${m}m`;
  res += `${s}s`;
  return res;
}
