<template>
  <div class="contact-info-panel">
    <sweet-scrollbar wrap-style="padding: 0 10px" :max-height="styleObject['max-height']" native>
      <sweet-form :label-position="labelPosition" label-width="auto">
        <sweet-form-item>
          <span class="aicc-ccma-contact-info-panel--value"/>
        </sweet-form-item>
        <sweet-form-item>
          <template #label>
            <span class="aicc-ccma-contact-info-panel--column">{{ $t('ccm.agent.contact.callserno') }}</span>
          </template>
          <aicc-tooltip :content="callSerialNoShow" placement="bottom-start" effect="light" overflow-target-selector=".aicc-ccma-contact-info-panel--value">
            <span class="aicc-ccma-contact-info-panel--value">{{ callSerialNoShow }}</span>
          </aicc-tooltip>
        </sweet-form-item>
        <div v-for="(value, key) in columns" :key="value">
          <sweet-form-item v-if="-1 == displayItem.indexOf(contactItems[key])">
            <template #label>
              <span class="aicc-ccma-contact-info-panel--column">{{ value }}</span>
            </template>
            <aicc-tooltip v-if="contactItems[key] == 'mediaAbility'" :content="mediaAbilityMap.get(String(resp[contactItems[key]])) || '--'" placement="bottom-start" effect="light" overflow-target-selector=".aicc-ccma-contact-info-panel--value">
              <span class="aicc-ccma-contact-info-panel--value">{{ mediaAbilityMap.get(String(resp[contactItems[key]])) || '--' }}</span>
            </aicc-tooltip>
            <aicc-tooltip v-else-if="contactItems[key] == 'mediaType'" :content="channelType || '--'" placement="bottom-start" effect="light" overflow-target-selector=".aicc-ccma-contact-info-panel--value">
              <span class="aicc-ccma-contact-info-panel--value">{{ channelType || '--' }}</span>
            </aicc-tooltip>
            <aicc-tooltip v-else-if="contactItems[key] == 'called'" :content="resp[contactItems[key]] || '--'" placement="bottom-start" effect="light" overflow-target-selector=".aicc-ccma-contact-info-panel--value" disabled>
              <div class="aicc-ccma-contact-info-panel--value">
                <agent-name-show-pop :is-show-pop="calledIsWorkNo && resp[contactItems[key]]" :work-number="resp[contactItems[key]] || '--'" />
              </div>
            </aicc-tooltip>
            <aicc-tooltip v-else-if="contactItems[key] == 'caller'" :content="resp[contactItems[key]] || '--'" placement="bottom-start" effect="light" overflow-target-selector=".aicc-ccma-contact-info-panel--value" disabled>
              <div class="aicc-ccma-contact-info-panel--value">
                <agent-name-show-pop :is-show-pop="callerIsWorkNo && resp[contactItems[key]]" :work-number="resp[contactItems[key]] || '--'" />
              </div>
            </aicc-tooltip>
            <aicc-tooltip v-else-if="contactItems[key] == 'acceptNo'" :content="resp[contactItems[key]] || '--'" placement="bottom-start" effect="light" overflow-target-selector=".aicc-ccma-contact-info-panel--value" disabled>
              <div class="aicc-ccma-contact-info-panel--value">
                <agent-name-show-pop :is-show-pop="acceptNoIsWorkNo && resp[contactItems[key]]" :work-number="resp[contactItems[key]] || '--'" />
              </div>
            </aicc-tooltip>
            <aicc-tooltip v-else-if="contactItems[key] == 'callNo'" :content="resp[contactItems[key]] || '--'" placement="bottom-start" effect="light" overflow-target-selector=".aicc-ccma-contact-info-panel--value" disabled>
              <div class="aicc-ccma-contact-info-panel--value">
                <agent-name-show-pop :is-show-pop="callNoIsWorkNo && resp[contactItems[key]]" :work-number="resp[contactItems[key]] || '--'" />
              </div>
            </aicc-tooltip>
            <aicc-tooltip v-else-if="contactItems[key] == 'orgName'" :content="resp[contactItems[key]] || '--'" placement="bottom-start" effect="light" overflow-target-selector=".aicc-ccma-contact-info-panel--value" disabled>
              <div class="aicc-ccma-contact-info-panel--value">
                <orgFullName-show-pop  :is-show-org-full-name = "true" :orgName="resp[contactItems[key]] || '--'" :orgFullName="resp['orgFullName'] || '--'"/>
              </div>
            </aicc-tooltip>
            <aicc-tooltip v-else :content="resp[contactItems[key]] || '--'" placement="bottom-start" effect="light" overflow-target-selector=".aicc-ccma-contact-info-panel--value">
              <span class="aicc-ccma-contact-info-panel--value">{{ resp[contactItems[key]] || "--" }}</span>
            </aicc-tooltip>
          </sweet-form-item>
        </div>
        <sweet-form-item>
          <template #label>
            <span class="aicc-ccma-contact-info-panel--column">{{ $t('ccm.agent.contact.callerPresent') }}</span>
          </template>
          <aicc-tooltip :content='resp["callerPresent"] || "--"' placement="bottom-start" effect="light" overflow-target-selector=".aicc-ccma-contact-info-panel--value">
            <span class="aicc-ccma-contact-info-panel--value">{{ resp["callerPresent"] || "--" }}</span>
          </aicc-tooltip>
        </sweet-form-item>
        <sweet-form-item>
          <template #label>
            <span class="aicc-ccma-contact-info-panel--column">{{ $t('ccm.agent.contact.agentPresent') }}</span>
          </template>
          <aicc-tooltip :content="resp['agentPresent'] || '--'" placement="bottom-start" effect="light" overflow-target-selector=".aicc-ccma-contact-info-panel--value">
            <span class="aicc-ccma-contact-info-panel--value">{{ resp['agentPresent'] || '--' }}</span>
          </aicc-tooltip>
        </sweet-form-item>
        <sweet-form-item>
          <template #label>
            <span class="aicc-ccma-contact-info-panel--column">{{ $t('ccm.agent.contact.reasonCode') }}</span>
          </template>
          <aicc-tooltip :content="reasonCodeTypeMap.get(String(resp['reasonCode'] || '')) || '--'" placement="bottom-start" effect="light" overflow-target-selector=".aicc-ccma-contact-info-panel--value">
            <span class="aicc-ccma-contact-info-panel--value">{{ reasonCodeTypeMap.get(String(resp['reasonCode'] || '')) || '--' }}</span>
          </aicc-tooltip>
        </sweet-form-item>
        <sweet-form-item>
          <template #label>
            <span class="aicc-ccma-contact-info-panel--column">{{ $t('ccm.agent.contact.relCallBackFlag') }}</span>
          </template>
          <aicc-tooltip :content="resp['relCallBackFlag'] == '0' ? $t('ccm.agent.pageurls.success') : resp['relCallBackFlag'] == '1' ? $t('ccm.agent.calledroute.fail') : resp['relCallBackFlag'] == '2' ? $t('ccm.agent.contact.nohaveevent') : '--'" placement="bottom-start" effect="light" overflow-target-selector=".aicc-ccma-contact-info-panel--value">
            <span class="aicc-ccma-contact-info-panel--value">{{ resp['relCallBackFlag'] == '0' ? $t('ccm.agent.pageurls.success') : resp['relCallBackFlag'] == '1' ? $t('ccm.agent.calledroute.fail') : resp['relCallBackFlag'] == '2' ? $t('ccm.agent.contact.nohaveevent') : '--' }}</span>
          </aicc-tooltip>
        </sweet-form-item>
        <sweet-form-item>
          <template #label>
            <span class="aicc-ccma-contact-info-panel--column">{{ $t('ccm.agent.contact.talkCallbackFlag') }}</span>
          </template>
          <aicc-tooltip :content="resp['talkCallbackFlag'] == '0' ? $t('ccm.agent.pageurls.success') : resp['talkCallbackFlag'] == '1' ? $t('ccm.agent.calledroute.fail') : resp['talkCallbackFlag'] == '2' ? $t('ccm.agent.contact.nohaveevent') : '--'" placement="bottom-start" effect="light" overflow-target-selector=".aicc-ccma-contact-info-panel--value">
            <span class="aicc-ccma-contact-info-panel--value">
              {{ resp['talkCallbackFlag'] == '0' ? i18n("ccm.agent.pageurls.success") : resp['talkCallbackFlag'] == '1' ? i18n("ccm.agent.calledroute.fail") : resp['talkCallbackFlag'] == '2' ? i18n("ccm.agent.contact.nohaveevent") : '--'}}
            </span>
          </aicc-tooltip>
        </sweet-form-item>
        <sweet-form-item>
          <template #label>
            <span class="aicc-ccma-contact-info-panel--column">{{ $t('ccm.urlconfig.label.talkurl') }}</span>
          </template>
          <aicc-tooltip :content="talkingNotifyUrl || '--'" placement="bottom-start" effect="light" overflow-target-selector=".aicc-ccma-contact-info-panel--value">
            <span class="aicc-ccma-contact-info-panel--value">{{ talkingNotifyUrl || '--' }}</span>
          </aicc-tooltip>
        </sweet-form-item>
        <sweet-form-item>
          <template #label>
            <span class="aicc-ccma-contact-info-panel--column">{{ $t('ccm.urlconfig.label.relurl') }}</span>
          </template>
          <aicc-tooltip :content="releaseNotifyUrl || '--'" placement="bottom-start" effect="light" overflow-target-selector=".aicc-ccma-contact-info-panel--value">
            <span class="aicc-ccma-contact-info-panel--value">{{ releaseNotifyUrl || '--' }}</span>
          </aicc-tooltip>
        </sweet-form-item>
      </sweet-form>
    </sweet-scrollbar>
  </div>
</template>

<script setup lang="ts">

import { computed, getCurrentInstance, inject, onMounted, reactive, ref, Ref, toRefs, watch } from "vue";
import {
  callTypeMap,
  channelTypeMap,
  mediaAbilityMap,
  reasonCodeTypeMap,
  staffHangupMap
} from "@/views/ccmanagement/common/constants";
import timeUtils from "@/utils/timeUtils";
import AgentNameShowPop from "@/views/ccmanagement/pages/agent-name-show-pop/agent-name-show-pop.vue";
import { querySocialMedia } from "@/views/ccmanagement/externals-api/ccmessage.js";
import { isWorkNo } from "@/views/ccmanagement/common/common";
import AiccTooltip from "@/components/aicc-tooltip/index.vue";
import orgFullNameShowPop from "@/views/ccmanagement/pages/contact-record/orgFullName-show-pop.vue";

const { appContext: { config: { globalProperties } } } = getCurrentInstance() as any;
const i18n = (param: string, ...args: Array<any>): string => {
  return globalProperties.$t(param, args);
};

const props = defineProps({
  columns: Array<String>,  // 列信息
  contactItems: Array<String>, // 条目信息
  ifFromWorkbench: {
    type: Boolean,
    required: true,
    default: false,
  },
  hasContent: {
    type: Boolean,
    required: true,
    default: false,
  },
  audioVideoAreaHeight: Number,
});
/** 表格标签布局 */
const labelPosition = computed<'left'|'right'|'top'>(() => window.isRTL ? "right" : "left")
/** 接触信息样式对象 */
const styleObject = computed(() => {
  // 已被使用高度为： Header.vue高度 + 标签页高度 + aicc-back-header高度 + 音视频内容高度
  const usedHeight = 88 + 56 + 31 + (props.audioVideoAreaHeight || 0);
  return {'max-height': `calc(100vh - ${usedHeight}px`, padding: '0 20px'}
})
const { columns, contactItems, hasContent } = toRefs(props);
const resp = inject<Ref>("resp"); // 后台返回的接触信息
const callSerialNo = inject<Readonly<Ref>>("callSerialNo"); // 呼叫流水号
const callSerialNoShow = computed(() => {
  if (!props.ifFromWorkbench) {
    return callSerialNo?.value;
  }
  return hasContent?.value ? callSerialNo?.value : "--";
})
const talkingNotifyUrl = ref<string>(); // 接通回调URL
const releaseNotifyUrl = ref<string>(); // 挂机回调URL
const callIdIVR = ref<string>(); // 呼叫ivr
const associateCall = ref<string>();
const talkReasonObj = ref<any>();
const contactInfo = ref<any>(); // 接触详情
const isExist = ref<boolean>(false);    // 双呼类型是否有录音
const downloadAuth = ref<boolean>(false); // 是否存在下载权限
const previewAuth = ref<boolean>(false); // 是否存在预览权限
const calledIsWorkNo = ref<boolean>(false); // 被叫是否是工号
const callerIsWorkNo = ref<boolean>(false); // 主叫是否是工号
const acceptNoIsWorkNo = ref<boolean>(false); // 受理号码是否是工号
const callNoIsWorkNo = ref<boolean>(false); // 客户号码是否是工号
const talkReason = ref(); // 来电原因
const talkRemark = ref();
let channelType = ref()
let displayItem = reactive(["recordingfiles", "talkReason", "talkRemark"])

watch(
    [columns, contactItems],
    () => initData()
)
const initData = () => {
  contactInfo.value = resp?.value;
  if (resp?.value.beginTime) {
    contactInfo.value.beginTime = timeUtils.transTime(resp.value.beginTime);
  }
  if (resp?.value.endTime) {
    contactInfo.value.endTime = timeUtils.transTime(resp.value.endTime);
  }
  contactInfo.value.calltype = callTypeMap.get(String(resp?.value.calltype));
  contactInfo.value.staffHangup = staffHangupMap.get(String(resp?.value.staffHangup));
  callIdIVR.value = resp?.value.ivrCallId;
  associateCall.value = resp?.value.associateCall;
  talkingNotifyUrl.value = resp?.value.talkingNotifyUrl;
  releaseNotifyUrl.value = resp?.value.releaseNotifyUrl;
  talkReasonObj.value = {
    talkRemark: resp?.value.talkRemark,
    talkReason: resp?.value.talkReason
  };
  isExist.value = resp?.value.isExist;
  downloadAuth.value = resp?.value.download;
  previewAuth.value = resp?.value.preview;
  contractInfoProcess(resp?.value.contractInfo);

  const mediaAbility: number = resp?.value.mediaAbility;
  const isInnerCall = innerCallCheck(resp?.value.mediaTypeId, mediaAbility);
  calledIsWorkNo.value = isInnerCall && isWorkNo(resp?.value.called);
  callerIsWorkNo.value = isInnerCall && isWorkNo(resp?.value.caller);
  acceptNoIsWorkNo.value = isInnerCall && isWorkNo(resp?.value.acceptNo);
  callNoIsWorkNo.value = isInnerCall && isWorkNo(resp?.value.callNo);
  channelType.value = loadChannelType(resp?.value.channelType)
};


const newSocialMedias = ref<Array<any>>([]);
const queryUsualChannelConfig = (): void => {
  querySocialMedia()
      .then((data: any) => {
        newSocialMedias.value = data?.filter((e: any) => e.accessMode == 1);
        channelType.value = loadChannelType(resp?.value.channelType)
      });
};
const loadChannelType = (key: any) => {
  const arr = newSocialMedias.value?.filter(e => e.socialId == key);
  if (arr && arr.length > 0) {
    return arr[0].socialCode;
  }
  return channelTypeMap.get(String(key));
}

/**
 * 检查是否为内部呼叫
 *
 * @param mediaTypeId 媒体类型
 * @param mediaAbility 媒体渠道
 * @returns { boolean }
 */
const innerCallCheck = (mediaTypeId: number | undefined, mediaAbility: number | undefined): boolean => {
  // 6-内部呼叫 51-内部两方求助 52-内部三方求助 以及 5-多媒体 的工号才需要转
  if (mediaTypeId === 6 || mediaTypeId === 51 || mediaTypeId === 52) {
    return true;
  }
  return mediaAbility === 5;
};

const contractInfoProcess = (contact: any): void => {
  if (!contact) {
    return;
  }
  contactInfo.value = contact;
  for (const item of contactInfo.value) {
    for (const proto in item) {
      if (proto == "crteat_time" || proto == "update_time") {
        item[proto] = timeUtils.transTime(item[proto]);
      }
    }
  }
};


onMounted(() => {
  initData();
  queryUsualChannelConfig()
});
</script>
<style lang="less" scoped>
.contact-info-panel {
  --swt-space-size-20: 25px;
}
.aicc-ccm-contact-info-panel {
  overflow-y: auto;
  padding: 0 20px
}
.aicc-ccma-col-value .el-link{
    font-size: var(--14px-to-rem);
}

.aicc-ccma-contact-info-panel--column {
  font-size: var(--14px-to-rem);
  //min-height: 17.5px; // 必须定义最小高度，否则会出现英语文本jpqyg等字符展示不全的现象
  display: block;
  line-height: var(--20px-to-rem);
  height: 100%;
}

.aicc-ccma-contact-info-panel--value {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: var(--14px-to-rem);
}

.aicc-ccma-contact-info-panel--value :deep(.el-link) {
  font-size: var(--14px-to-rem);
}

@media screen and (min-width: 1920px) {
  .aicc-ccma-contact-info-panel--column {
    font-size: var(--16px-to-rem);
    font-weight: 400;
    line-height: var(--20px-to-rem);
    display: block;
  }

  .aicc-ccma-contact-info-panel--value  {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: var(--16px-to-rem);
    font-weight: 500;
  }

  .aicc-ccma-contact-info-panel--value :deep(.el-link) {
    font-size: var(--16px-to-rem);
  }
}

.aicc-ccma-first-row-style {
  padding-top: 20px;
}

.aicc-ccma-row-style {
  padding-bottom: 22px;

  .aicc-ccma-col-name {
    color: var(--color-text--h2);
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 5px;
  }

  .aicc-ccma-col-value {
    color: var(--color-text--h1);
    font-weight: 500;
    height: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
    margin-left: 35px;
  }
}
.aicc-ccma-contact-info-max-size {
  max-width: 260px;
  max-height: 432px;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  word-break: break-all;
}

.lable-width {
  width: 230px;
}
</style>