export default {
  "ccmessaging.chat.chat.closeDialog": "تاييد کنيد که ببنديد ؟",
  "ccmessaging.chat.silentagent.config.add.error": "خرابی در پیکربندی عامل سکوت گفتار جدید",
  "ccmessaging.chat.silentagent.config.add.success": "پیکربندی عامل سکوت صوتی جدید موفقیت آمیز بود",
  "ccmessaging.chat.fiveGCard.add": "کارت های 5G جدید",
  "ccmessaging.chat.fiveGCard.edit": "ویرایش کارتهای 5G",
  "ccmessaging.chat.fiveGCarouselCard.add": "کارت های 5G چرخ و فلک جدید",
  "ccmessaging.chat.fiveGCarouselCard.edit": "ویرایش کارتهای چرخ و فلک 5G",
  "ccmessaging.chat.webCard.add": "افزودن کارتهای وب",
  "ccmessaging.chat.webCard.edit": "ویرایش کارتهای وب",
  "ccmessaging.chat.coBrowseSite.add": "افزودن نشانی همگام سازی صفحات وب",
  "ccmessaging.chat.coBrowseSite.edit": "ویرایش نشانی وب انجمن",
  "ccmessaging.chat.richTextPage.add": "افزودن متن غنی",
  "ccmessaging.chat.richTextPage.edit": "ویرایش متن غنی",
  "ccmessaging.chat.chatPhrase.add": "افزودن عبارتهای مشترک",
  "ccmessaging.chat.chatPhrase.edit": "ویرایش واژه های مشترک",
  "ccmessaging.chat.audioLibrary.add": "افزودن صدا",
  "ccmessaging.chat.audioLibrary.edit": "ویرایش گفتار",
  "ccmessaging.chat.videoLibrary.add": "افزودن ویدئو",
  "ccmessaging.chat.videoLibrary.edit": "ویرایش ویدئو",
  "ccmessaging.chat.imgLibrary.add": "افزودن تصویر",
  "ccmessaging.chat.imgLibrary.edit": "ویرایش تصویر",
  "ccmessaging.chat.emoLibrary.add": "افزودن شکلک های شخصی",
  "ccmessaging.chat.emoLibrary.edit": "ویرایش چهره های شخصی",
  "ccmessaging.chat.docLibrary.add": "افزودن مستندات",
  "ccmessaging.chat.docLibrary.edit": "ویرایش مستندات",
  "ccmessaging.chat.locate.add": "افزودن نشانی",
  "ccmessaging.chat.locate.edit": "ویرایش نشانی",
  "ccmesaging.chat.agentconsole.commom.label": "روز",
  "ccmesaging.chat.agentconsole.label.callReason": "دلیل تماس",
  "ccmesaging.chat.agentconsole.label.commonreply": "پاسخ های رایج",
  "ccmesaging.chat.agentconsole.label.expression": "شکلک ها",
  "ccmesaging.chat.agentconsole.label.fontstyle": "نوع فونت",
  "ccmesaging.chat.agentconsole.label.historicalcontact": "تماس تاریخی",
  "ccmesaging.chat.agentconsole.label.incallmessage": "پیام تماس ورودی",
  "ccmesaging.chat.agentconsole.label.Realtimetransfer": "ترجمه بلادرنگ",
  "ccmesaging.chat.agentconsole.label.robotChat": "گفتگو با چت بات",
  "ccmesaging.chat.agentconsole.label.screenshot": "اسکرین شات ها",
  "ccmesaging.chat.agentconsole.label.seeMore": "بیشتر ببینید",
  "ccmesaging.chat.agentconsole.label.service": "سرویس",
  "ccmesaging.chat.agentconsole.label.uploadattachment": "آپلود پیوست",
  "ccmesaging.chat.agentconsole.label.uploadimage": "آپلود تصویر",
  "ccmesaging.chat.agentconsole.title.release": "آزاد کردن",
  "ccmesaging.chat.cobrowse.accept": "تایید کنید",
  "ccmesaging.chat.cobrowse.agentMax": "نماینده در حال حاضر در صفحه وب همکاری می کند، لطفاً دوباره امتحان کنید",
  "ccmesaging.chat.cobrowse.isAcceptWeb": "آیا مطمئن هستید که می خواهید دعوت به مرور مشترک را بپذیرید؟",
  "ccmesaging.chat.cobrowse.isAcceptWebMax": "از حداکثر تعداد اتصالات مرور مشترک مجاز توسط محیط کاری tenant فعلی فراتر رفته است.",
  "ccmesaging.chat.cobrowse.noWebSiteAuth": "نماینده مجوز مرور مشترک را ندارد.",
  "ccmesaging.chat.cobrowse.refused": "رد کردن",
  "ccmesaging.chat.cobrowse.title": "دعوت به مرور مشترک",
  "ccmesaging.chat.cobrowse.webSite": "URL مرور مشترک:",
  "ccmesaging.chat.cobrowse.webSiteButton": "مرور مشترک",
  "ccmesaging.chat.cobrowse.webSitePlaceholder": "URL اشتراک گذاری را وارد کنید.",
  "ccmessaging.address.protocol.check": "پروتکل شبکه استفاده شده توسط آدرس امن نیست. استفاده از این پروتکل ممکن است خطرات امنیتی را به همراه داشته باشد.",
  "ccmessaging.channelconfig.algorithm": "الگوریتم رمزگذاری اتصالات",
  "ccmessaging.channelconfig.email.freeTimeText": "پاسخگویی خودکار در ساعات غیر کاری",
  "ccmessaging.channelconfig.email.freeTimeText.tips": "پس از باز شدن در ساعات غیر کاری به ایمیل دریافتی مشتری پاسخ دهید",
  "ccmessaging.channelconfig.email.reply.each.eamil": "به هر ایمیل به صورت خودکار پاسخ دهید",
  "ccmessaging.channelconfig.email.selectAutoReply": "الگوی پاسخ خودکار ایمیل",
  "ccmessaging.channelconfig.email.selectFreeTimeText": "الگوی پاسخ ایمیل در روز غیر کاری",
  "ccmessaging.channelconfig.email.worktime.autoReply": "پاسخگویی خودکار در ساعات کاری",
  "ccmessaging.channelconfig.email.worktime.autoReply.tips": "پاسخ به ایمیل های مشتری در ساعات کاری پس از باز کردن دریافت شد",
  "ccmessaging.channelconfig.sms.autoReply": "پاسخ به طور خودکار",
  "ccmessaging.channelconfig.sms.autoReply.tips": "پاسخ خودکار پس از متصل شدن نماینده با موفقیت ارسال می شود.",
  "ccmessaging.channelconfig.sms.freeTimeText": "پاسخ روز غیر کاری",
  "ccmessaging.channelconfig.sms.selectAutoReply": "الگوی پاسخ خودکار پیامک",
  "ccmessaging.channelconfig.sms.selectFreeTimeText": "الگو پاسخ پیامک غیر کاری روز",
  "ccmessaging.char.agentstatus.after.callends": "وضعیت نماینده پس از پایان تماس: 0: حالت بیکار. 1: حالت را تنظیم کنید.",
  "ccmessaging.char.call.Idle.state": "حالت بیکار",
  "ccmessaging.char.call.work.state": "حالت کار",
  "ccmessaging.char.channelconfig.duplicateCodeName": "کد دسترسی به کانال از قبل وجود دارد.",
  "ccmessaging.chat.agent.organizational.structure": "ساختار سازمانی",
  "ccmessaging.chat.agent.refreshFail": "صفحه را به طور مکرر رفرش نکنید",
  "ccmessaging.chat.agent.transferSessionFail": "جلسه انتقال داده نشد",
  "ccmessaging.chat.agentconsole.accesschannel": "دسترسی به کانال",
  "ccmessaging.chat.agentconsole.agentStatus.error": "تماس را نمی توان آغاز کرد زیرا نماینده آنلاین نیست یا در حال صحبت نیست.",
  "ccmessaging.chat.agentconsole.allResults": "همه نتایج",
  "ccmessaging.chat.agentconsole.asyncTimeBeyond": "ارسال ناموفق بود زیرا فاصله زمانی پاسخ غیرمجاز فراتر رفته است.",
  "ccmessaging.chat.agentconsole.beyondLimitNum": "تعداد پیام های ارسالی از حد بالا بیشتر است.",
  "ccmessaging.chat.agentconsole.browserecord": "تاریخچه مرور",
  "ccmessaging.chat.agentconsole.chat.close": "جلسه جاری به پایان رسید",
  "ccmessaging.chat.agentconsole.chat.userId": "شناسه کاربر",
  "ccmessaging.chat.agentconsole.chat.userSettings": "تنظیمات کاربر",
  "ccmessaging.chat.agentconsole.chatPhrase": "عبارات رایج",
  "ccmessaging.chat.agentconsole.cobrowse": "همکاری در صفحه وب",
  "ccmessaging.chat.agentconsole.contract.pdfUploadMaxSizeTip": "اندازه قرارداد نمی تواند از {n}M بیشتر باشد.",
  "ccmessaging.chat.agentconsole.contract.statisticSigns": "آمار امضا",
  "ccmessaging.chat.agentconsole.contract.statisticTotalSigns": "آمار",
  "ccmessaging.chat.agentconsole.contract.statisticTotalSigns.failTip": "در جمع آوری آمار در مورد موارد امضا در قرارداد ناموفق بود.",
  "ccmessaging.chat.agentconsole.contract.statisticTotalSigns.noSigns.failTip": "نماد مکان امضا پیدا نشد.",
  "ccmessaging.chat.agentconsole.contract.statisticTotalSigns.noStaticSigns.failTip": "لطفا ابتدا قراردادهای دارای امضا را بشمارید.",
  "ccmessaging.chat.agentconsole.contract.upload.failTip": "قرارداد بارگذاری نشد.",
  "ccmessaging.chat.agentconsole.contract.upload.noSelectSigns.failTip": "مورد امضا برای قرارداد انتخاب نشده است.",
  "ccmessaging.chat.agentconsole.contract.upload.sameContract.failTip": "مشترک قراردادی دارد که منقضی نشده است.",
  "ccmessaging.chat.agentconsole.current": "جاری",
  "ccmessaging.chat.agentconsole.customerinformation": "اطلاعات مشتری",
  "ccmessaging.chat.agentconsole.defaultOrgName": "سازمان غیر وابسته",
  "ccmessaging.chat.agentconsole.document": "سند",
  "ccmessaging.chat.agentconsole.emailNotSupport": "کانال ایمیل از پیام های چندرسانه ای پشتیبانی نمی کند.",
  "ccmessaging.chat.agentconsole.enter.total.number": "لطفا تعداد کل امضاها را وارد کنید",
  "ccmessaging.chat.agentconsole.filterByCondition": "معیارهای فیلتر",
  "ccmessaging.chat.agentconsole.fivegNotSupport": "کانال های 5G از پیام چندرسانه ای پشتیبانی نمی کنند",
  "ccmessaging.chat.agentconsole.hisconversation": "گفتگوی تاریخی",
  "ccmessaging.chat.agentconsole.history": "تاریخ",
  "ccmessaging.chat.agentconsole.image": "تصویر",
  "ccmessaging.chat.agentconsole.information": "اطلاعات",
  "ccmessaging.chat.agentconsole.intention": "قصد",
  "ccmessaging.chat.agentconsole.IpAddress": "آدرس IP",
  "ccmessaging.chat.agentconsole.label.defaultwelcome": "به سیستم خدمات مشتریان آنلاین خوش آمدید!",
  "ccmessaging.chat.agentconsole.label.endsession": "آیا برای پایان دادن به جلسه؟",
  "ccmessaging.chat.agentconsole.label.filesFacebookNotsupport": "پیام رسان Facebook در حال حاضر از دریافت اطلاعات مکان پشتیبانی نمی کند",
  "ccmessaging.chat.agentconsole.label.filesTwitterNotsupport": "Twitter صدا، مکان یا ویدیوی بیش از 30 ثانیه را پشتیبانی نمی کند!",
  "ccmessaging.chat.agentconsole.label.filesWechatNotsupport": "WeChat از صدایی که بیش از 60 ثانیه طول می کشد پشتیبانی نمی کند.",
  "ccmessaging.chat.agentconsole.label.messageRejected": "پیام توسط Twitter رد شد زیرا به نظر می رسد spam است، لطفاً از مترادف استفاده کنید یا بعداً دوباره امتحان کنید:",
  "ccmessaging.chat.agentconsole.label.NoAudioDuration": "مدت زمان صدا به دست نیامد. لطفا دوباره آپلود کنید",
  "ccmessaging.chat.agentconsole.label.NoVideoDuration": "مدت زمان ویدیو به دست نیامد، لطفاً دوباره آپلود کنید!",
  "ccmessaging.chat.agentconsole.label.ongoingsession": "در حال حاضر هیچ جلسه جاری وجود ندارد!",
  "ccmessaging.chat.agentconsole.label.ongoingwx": "کانال فعلی نمی تواند اطلاعات مکان را ارسال کند!",
  "ccmessaging.chat.agentconsole.label.skillNoAgent": "نماینده در صف مهارت آفلاین است.",
  "ccmessaging.chat.agentconsole.loading": "بارگذاری بیشتر",
  "ccmessaging.chat.agentconsole.locate": "محل",
  "ccmessaging.chat.agentconsole.mailDraft": "پیش نویس ایمیل",
  "ccmessaging.chat.agentconsole.message.notworktime": "نماینده در زمان کار نیست.",
  "ccmessaging.chat.agentconsole.messageloading": "بارگذاری",
  "ccmessaging.chat.agentconsole.multimedia": "چند رسانه ای",
  "ccmessaging.chat.agentconsole.multimediaid": "شناسه چند رسانه ای",
  "ccmessaging.chat.agentconsole.multimedianame": "نام چند رسانه ای",
  "ccmessaging.chat.agentconsole.myconversation": "گفتگوی من",
  "ccmessaging.chat.agentconsole.nocontact": "در حال حاضر هیچ مخاطبی وجود ندارد",
  "ccmessaging.chat.agentconsole.noHiscontact": "بدون جلسه تاریخی",
  "ccmessaging.chat.agentconsole.notSupport": "کانال از این نوع پیام چندرسانه ای پشتیبانی نمی کند.",
  "ccmessaging.chat.agentconsole.noUserArrive": "در حال حاضر هیچ کاربری در دسترس نیست!",
  "ccmessaging.chat.agentconsole.novoEmail": "ایمیل جدید",
  "ccmessaging.chat.agentconsole.nowconversation": "مکالمه فعلی",
  "ccmessaging.chat.agentconsole.only.pdf.supported": "فقط فایل های PDF پشتیبانی می شوند",
  "ccmessaging.chat.agentconsole.photo": "عکس",
  "ccmessaging.chat.agentconsole.promptConetnt.cannot.rollback": "پیام قابل برداشت نیست",
  "ccmessaging.chat.agentconsole.promptConetnt.ContentLengthExceeds": "محتوای متنی بیشتر از حد مجاز نیست!",
  "ccmessaging.chat.agentconsole.promptConetnt.ContentNull": "EmptyContent مجاز نیست!",
  "ccmessaging.chat.agentconsole.promptConetnt.delectMessageRecord": "شما یک رکورد را حذف کرده اید",
  "ccmessaging.chat.agentconsole.promptConetnt.onlyFiveG": "پیام های کارت 5G فقط از کانال های 5G پشتیبانی می کنند!",
  "ccmessaging.chat.agentconsole.promptConetnt.onlyWebCard": "پیام های کارت وب فقط از کانال های وب پشتیبانی می کنند!",
  "ccmessaging.chat.agentconsole.promptConetnt.operateFail": "انجام عملیات روی پیام هایی که با موفقیت ارسال نشدند انجام نشد",
  "ccmessaging.chat.agentconsole.promptConetnt.operateFailWithChannel": "فقط پیام های وب قابل اجرا هستند",
  "ccmessaging.chat.agentconsole.promptConetnt.operateRollBackFail": "پیام هایی که بیش از دو دقیقه ارسال شده اند قابل برداشت نیستند",
  "ccmessaging.chat.agentconsole.promptConetnt.rollBackMessage": "شما پیامی را پس گرفته اید",
  "ccmessaging.chat.agentconsole.promptConetnt.userAlreadyInSession": "ارسال انجام نشد زیرا مشترک در حال تماس است.",
  "ccmessaging.chat.agentconsole.promptConetnt.userBusy": "تا زمانی که مامور بیکار است ، این کار را انجام دهید",
  "ccmessaging.chat.agentconsole.promptConetnt.userInfoNull": "اطلاعات کاربر درست نیست، جلسه قطع خواهد شد!",
  "ccmessaging.chat.agentconsole.promptConetnt.userIsTalking.saveDraftFailed": "ایمیل ارسال نشد زیرا مشترک در حال تماس است. ایمیل به عنوان پیش نویس ذخیره نمی شود.",
  "ccmessaging.chat.agentconsole.promptConetnt.userIsTalking.saveDraftSuccess": "ایمیل ارسال نشد زیرا مشترک در حال تماس است. ایمیل به عنوان پیش نویس ذخیره می شود.",
  "ccmessaging.chat.agentconsole.promptConetnt.userNoArrive": "هیچ کاربری متصل نیست یا کاربری انتخاب نشده است!",
  "ccmessaging.chat.agentconsole.promptConetnt.userOffline": "کاربر فعلی آفلاین است!",
  "ccmessaging.chat.agentconsole.queuing": "در صف",
  "ccmessaging.chat.agentconsole.quoteReply": "این پاسخ را نقل کنید",
  "ccmessaging.chat.agentconsole.recommendedCaseType": "دسته سفارش کار توصیه شده",
  "ccmessaging.chat.agentconsole.replyEmail": "پاسخ ایمیل",
  "ccmessaging.chat.agentconsole.robotAssistant": "دستیار ربات",
  "ccmessaging.chat.agentconsole.select.contract.uploaded": "لطفاً سندی را که باید آپلود شود انتخاب کنید",
  "ccmessaging.chat.agentconsole.select.mandatory.signature": "لطفاً یک امضای اجباری انتخاب کنید",
  "ccmessaging.chat.agentconsole.selectCaseType": "انتخاب دسته سفارش کار",
  "ccmessaging.chat.agentconsole.send.contract": "ارسال قرارداد",
  "ccmessaging.chat.agentconsole.send.repeat": "مکرر کلیک نکنید!",
  "ccmessaging.chat.agentconsole.sendEmail": "ایمیل بفرست",
  "ccmessaging.chat.agentconsole.sendLocalFile": "ارسال فایل های محلی",
  "ccmessaging.chat.agentconsole.sendmessage": "فرستادن پیام ",
  "ccmessaging.chat.agentconsole.setHandledNumber": "تنظیم شمارۀ بررسی شده",
  "ccmessaging.chat.agentconsole.signature.variable": "امضای ِ ِ ِ",
  "ccmessaging.chat.agentconsole.signInCallNum": "تعداد تماس های ثبت در حال حاضر:",
  "ccmessaging.chat.agentconsole.smsTemplate": "الگو پیام کوتاه",
  "ccmessaging.chat.agentconsole.software": "محیط نرم افزار",
  "ccmessaging.chat.agentconsole.spellcheck.correcting.not.finished": "تمام نوشته هاي اشتباهت تصحيح نشده چک رو تموم کنيم؟",
  "ccmessaging.chat.agentconsole.spellcheck.correcting.cancel.confirmation": "پس از لغو، محتوای اصلاح شده ذخیره نخواهد شد. آیا می‌خواهید بررسی را لغو کنید؟",
  "ccmessaging.chat.agentconsole.spellcheck.correctly.writing": "درست نوشته شده",
  "ccmessaging.chat.agentconsole.spellcheck.failed": "خرابی در بررسی غلط املا.",
  "ccmessaging.chat.agentconsole.spellcheck.language": "زبان",
  "ccmessaging.chat.agentconsole.spellcheck.next": "بعد",
  "ccmessaging.chat.agentconsole.spellcheck.previous": "قبلی",
  "ccmessaging.chat.agentconsole.spellcheck.replace": "جایگزین",
  "ccmessaging.chat.agentconsole.spellcheck.replaceall": "همه را جایگزین کنید",
  "ccmessaging.chat.agentconsole.spellcheck.sentence.length.failed": "طول متنی که باید بررسی شود از 1024 بیشتر است.",
  "ccmessaging.chat.agentconsole.spellcheck.sentence.length.failed.front": "طول متنی که باید بررسی شود از 1024 بیشتر است.",
  "ccmessaging.chat.agentconsole.spellcheck.sentence.no.context": "هیچ متنی در جعبه متن وجود ندارد. شما نیازی به بررسی املا ندارید.",
  "ccmessaging.chat.agentconsole.spellcheck.sentence.no.mistake": "بدون اشتباه تایپی",
  "ccmessaging.chat.agentconsole.spellcheck.title": "تست املا",
  "ccmessaging.chat.agentconsole.spellcheck.wrongly.writing": "اشتباه نوشته شده",
  "ccmessaging.chat.agentconsole.talkavg": "میانگین مدت تماس (های):",
  "ccmessaging.chat.agentconsole.talkNum": "تعداد تماس های امروز:",
  "ccmessaging.chat.agentconsole.thumbnail": "Thumbnail",
  "ccmessaging.chat.agentconsole.total.number.signatures": "تعداد کل امضاها",
  "ccmessaging.chat.agentconsole.upload": "یک فایل آپلود کنید",
  "ccmessaging.chat.agentconsole.uploadfail": "آپلود فایل انجام نشد",
  "ccmessaging.chat.agentconsole.userInputStatus": "پیش نمایش وضعیت ورودی کاربر",
  "ccmessaging.chat.agentconsole.video": "ویدیو",
  "ccmessaging.chat.agentconsole.voice": "صدا",
  "ccmessaging.chat.agentconsole.waittime": "زمان انتظار",
  "ccmessaging.chat.async.asyncMsgNum": "تعداد پیام های آفلاین",
  "ccmessaging.chat.async.asyncMsgTrending": "روند پیام آفلاین",
  "ccmessaging.chat.async.asyncUserNum": "تعداد کاربران آفلاین",
  "ccmessaging.chat.async.asyncUserTrending": "روند کاربر آفلاین",
  "ccmessaging.chat.async.channelSelect": "فیلتر کانال",
  "ccmessaging.chat.async.requestError": "درخواست ناموفق بود.",
  "ccmessaging.chat.async.statistics": "نظارت بر پیام های آفلاین",
  "ccmessaging.chat.async.statistics.asynChannelTotal": "تعداد کل کانال های آفلاین",
  "ccmessaging.chat.async.statistics.asynMsgTotal": "تعداد کل پیام های آفلاین",
  "ccmessaging.chat.async.statistics.asynUserTotal": "تعداد کل کاربران آفلاین",
  "ccmessaging.chat.async.statistics.updateTime": "زمان به روز رسانی داده ها",
  "ccmessaging.chat.call.end.state": "پیکربندی وضعیت نماینده پس از پایان تماس",
  "ccmessaging.chat.card": "کارت",
  "ccmessaging.chat.edit.card": "کارت ویرایش کنید",
  "ccmessaging.chat.multimedia.selectTemplate": "انتخاب کنیدالگو",
  "ccmessaging.chat.card.template": "الگو whatsApp",
  "ccmessaging.chat.channelconfig.accessToken": "Access Token",
  "ccmessaging.chat.channelconfig.accessTokenSecret": "Access Token Secret",
  "ccmessaging.chat.channelconfig.accountKeyRepeat": "accountKey در کانال whatsApp تکرار می شود",
  "ccmessaging.chat.channelconfig.add": "جدید",
  "ccmessaging.chat.channelconfig.addressValidate": "مقدار باید در الگو sip:+Number +@botplatform.rcs.chinamobile.com باشد و باید کمتر از 100 کاراکتر داشته باشد.",
  "ccmessaging.chat.channelconfig.afterhalf": "، می توان برای حذف مرورگر برای رهگیری کلیک کرد)",
  "ccmessaging.chat.channelconfig.agentTimeout": "سفارشی سازی کردن فاصله زمانی برای عدم پاسخ نماینده و درخواست برای تخصیص مجدد نماینده",
  "ccmessaging.chat.channelconfig.agentTimeout.emailTimeOutTips": "سفارشی سازی کردن فاصله زمانی برای عدم پاسخ نماینده",
  "ccmessaging.chat.channelconfig.agentWorkTime": "زمان کار نماینده",
  "ccmessaging.chat.channelconfig.alphavalidateFailed": "کاراکترهای نامعتبر مقدار می تواند فقط شامل حروف، اعداد و زیرخط (_) باشد. اولین کاراکتر باید یک حرف یا خط زیر باشد.",
  "ccmessaging.chat.channelconfig.apifabric.ak": "کلید APP",
  "ccmessaging.chat.channelconfig.apifabric.sk": "APP Secret",
  "ccmessaging.chat.channelconfig.apiKey": "API key",
  "ccmessaging.chat.channelconfig.apiKeySecret": "API Key Secret",
  "ccmessaging.chat.channelconfig.appId": "App Id",
  "ccmessaging.chat.channelconfig.appSecret": "App Secret",
  "ccmessaging.chat.channelconfig.assertionSigningKey": "Assertion Signing Key",
  "ccmessaging.chat.channelconfig.assistantAccessCode": "کد دسترسی دستیار",
  "ccmessaging.chat.channelconfig.assistantSet": "پیکربندی دستیار ربات",
  "ccmessaging.chat.channelconfig.asyncFlag": "پیام آفلاین",
  "ccmessaging.chat.channelconfig.asyncTip": "اگر این تابع غیرفعال شود ، پیامهای برون خط موجود از دست می رفتن.",
  "ccmessaging.chat.channelconfig.asyncTime": "فاصله انتساب مجدد (دقیقه)",
  "ccmessaging.chat.channelconfig.auth.duplicateAuth": "حساب رسمی مجاز شده است و نیازی به پیکربندی مجدد ندارد.",
  "ccmessaging.chat.channelconfig.auth.relation.timeout": "مجوز ممکن است منقضی شده باشد. کد QR WeChat را برای تأیید مجدد اسکن کنید.",
  "ccmessaging.chat.channelconfig.beforhalf": "می‌توانید روی دکمه «امتحان کنید» کلیک کنید تا اثر یکپارچه‌سازی را ببینید. (اگر URL صحیح باشد و یک پرچم رهگیری در گوشه سمت راست بالای مرورگر ظاهر شود",
  "ccmessaging.chat.channelconfig.bsp": "ارائه دهنده راه حل های تجاری",
  "ccmessaging.chat.channelconfig.cert.empty": "هنگامی که آدرس فشار از پروتکل HTTPS استفاده می کند، گواهی نمی تواند خالی باشد.",
  "ccmessaging.chat.channelconfig.Certification": "روش تایید",
  "ccmessaging.chat.channelconfig.changeLogo": "آواتار را تغییر دهید",
  "ccmessaging.chat.channelconfig.channelID": "Channel ID",
  "ccmessaging.chat.channelconfig.channelSecret": "Channel secret",
  "ccmessaging.chat.channelconfig.chatbotAddress": "آدرس چت بات",
  "ccmessaging.chat.channelconfig.checkAgentTimeOutInterval": "فاصله زمانی برای عدم پاسخ نماینده نمی تواند بیشتر از زمانی باشد که بدون پاسخ مشتری باشد.",
  "ccmessaging.chat.channelconfig.checkEncodingAESKey": "طول کلید 43 است و فقط می تواند حاوی A-Z،a-z،0-9 باشد",
  "ccmessaging.chat.channelconfig.checkmessage": 'حاوی کاراکترهای نامعتبر (" < ""> """ / "و بیشتر)".',
  "ccmessaging.chat.channelconfig.checkName": "نام نمی تواند کلمات سیستم و غیره باشد",
  "ccmessaging.chat.channelconfig.checkNumber": "مقدار نامعتبر است.",
  "ccmessaging.chat.channelconfig.checkSocialChatNum": "نمی تواند شامل اعشار باشد",
  "ccmessaging.chat.channelconfig.checkWhatsAppBaseUrl": "URL نامعتبر است!",
  "ccmessaging.chat.channelconfig.checkWhatsAppPhoneNum": "شماره تلفن همراه نامعتبر",
  "ccmessaging.chat.channelconfig.clicktocall.empty": "صف مهارت یا کد دسترسی تلفن گویا نمی تواند خالی باشد!",
  "ccmessaging.chat.channelconfig.clickToCallSkillList": "صف مهارت کلیک برای تماس",
  "ccmessaging.chat.channelconfig.close": "بستن",
  "ccmessaging.chat.channelconfig.codeName": "کد دسترسی به کانال",
  "ccmessaging.chat.channelconfig.defaultReply": "پاسخ پیش فرض",
  "ccmessaging.chat.channelconfig.delete": "حذف",
  "ccmessaging.chat.channelconfig.delete.exist.asyncMsg": "کانال [{n}] حذف نشد. پس از تخصیص پیام‌های آفلاین، دوباره امتحان کنید.",
  "ccmessaging.chat.channelconfig.delete.exist.asyncUser": "کانال [{n}] حذف نشد. بعد از تخصیص کاربران آفلاین دوباره امتحان کنید.",
  "ccmessaging.chat.channelconfig.delete.exist.session": "کانال [{n}] حذف نشد. بعد از پایان جلسه دوباره امتحان کنید.",
  "ccmessaging.chat.channelconfig.edit": "عمل",
  "ccmessaging.chat.channelconfig.email.bccLimit": "حداکثر آدرس های ایمیل Bcc",
  "ccmessaging.chat.channelconfig.email.ccLimit": "حداکثر آدرس های ایمیل Cc",
  "ccmessaging.chat.channelconfig.email.number": "پیکربندی تعداد آدرس ایمیل",
  "ccmessaging.chat.channelconfig.email.personal": "با ایمیل شخصی پاسخ دهید",
  "ccmessaging.chat.channelconfig.email.placeholder": "1 - 100، اختیاری",
  "ccmessaging.chat.channelconfig.email.range": "یک عدد صحیح مثبت از 1 تا 100 وارد کنید",
  "ccmessaging.chat.channelconfig.email.receiverLimit": "حداکثر تعداد آدرس های ایمیل گیرنده",
  "ccmessaging.chat.channelconfig.email.selectSignature": "امضای ایمیل",
  "ccmessaging.chat.channelconfig.email.signature": "امضای ایمیل",
  "ccmessaging.chat.channelconfig.email.signature.desc": "شرح",
  "ccmessaging.chat.channelconfig.email.signature.name": "نام امضای ایمیل",
  "ccmessaging.chat.channelconfig.email.totalEmailNumber": "تعداد کل آدرس‌های ایمیل گیرنده، گیرنده carbon copy و گیرنده مخفی نمی‌تواند از 100 تجاوز کند.",
  "ccmessaging.chat.channelconfig.emailRepeate": "درگاه دریافت ایمیل استفاده شده است. برای مشاهده دروازه دریافت ایمیل به صفحه پیکربندی مسیریابی پیام بروید.",
  "ccmessaging.chat.channelconfig.endchat": "آیا برای پایان دادن به جلسه؟",
  "ccmessaging.chat.channelconfig.endOfSession": "روش پایان گفتگو",
  "ccmessaging.chat.channelconfig.envName": "Dev environment label",
  "ccmessaging.chat.channelconfig.envNameValidate": "مقدار باید رشته ای کمتر یا مساوی 24 کاراکتر باشد و فقط شامل حروف و اعداد باشد.",
  "ccmessaging.chat.channelconfig.facebookBackground": "Facebook",
  "ccmessaging.chat.channelconfig.fiveGBackground": "5G Message CSP",
  "ccmessaging.chat.channelconfig.gender": "جنسیت",
  "ccmessaging.chat.channelconfig.guestTimeout": "زمان وقفه بدون پاسخ مهمان و پاسخ پایان جلسه را سفارشی کنید. اگر فعال نباشد، زمان پیش‌فرض 20 دقیقه است.",
  "ccmessaging.chat.channelconfig.guestTimeout.timeOutTips": "زمان وقفه بدون پاسخ مهمان را سفارشی کنید. اگر فعال نباشد، تایم اوت پیش فرض {n} دقیقه است.",
  "ccmessaging.chat.channelconfig.hostingMethod": "روش هاستینگ",
  "ccmessaging.chat.channelconfig.hour": "ساعت",
  "ccmessaging.chat.channelconfig.identityCertificate": "شناسنامه",
  "ccmessaging.chat.channelconfig.importAvatarSize": "اندازه تصویر آواتار بیش از 6 مگابایت است.",
  "ccmessaging.chat.channelconfig.importAvatartype": "نوع تصویر آواتار نادرست است.",
  "ccmessaging.chat.channelconfig.infobipPassword": "کلمه عبور",
  "ccmessaging.chat.channelconfig.infobipUserName": "نام کاربری",
  "ccmessaging.chat.channelconfig.infoConfiguration": "پیکربندی اطلاعات",
  "ccmessaging.chat.channelconfig.keyEscrow": "کلید",
  "ccmessaging.chat.channelconfig.KeyValidate": "مقدار نمی تواند خالی باشد و فقط می تواند شامل حروف، ارقام و کاراکترهای خاص باشد.",
  "ccmessaging.chat.channelconfig.lastagent.time": "محدوده زمانی (ساعت)",
  "ccmessaging.chat.channelconfig.lastModifyTime": "آخرین تغییر در",
  "ccmessaging.chat.channelconfig.logo": "آواتار",
  "ccmessaging.chat.channelconfig.messagePushUrl.not.configure.trustList": "آدرس در لیست قابل اعتماد نیست. لطفا با مدیر سیستم تماس بگیرید.",
  "ccmessaging.chat.channelconfig.hrefUrl.not.configure.trustList": "نشانی پیوند ابر پیوند در فهرست قابل اطمینان نیست. لطفاً با مدیر سیستم تماس بگیرید.",
  "ccmessaging.chat.channelconfig.minute": "در یک دقیقه",
  "ccmessaging.chat.channelconfig.name": "برای مثال",
  "ccmessaging.chat.channelconfig.nochatonline": "در حال حاضر هیچ جلسه جاری وجود ندارد!",
  "ccmessaging.chat.channelconfig.noLimit": "مشخص نشده است",
  "ccmessaging.chat.channelconfig.NonworkingTimeReminder": "اعلان زمان غیر کاری",
  "ccmessaging.chat.channelconfig.nonWorkTimeDay": "روز غیر کاری",
  "ccmessaging.chat.channelconfig.noReplyIntervalTime": "فاصله زمانی انتظار نماینده (دقیقه)",
  "ccmessaging.chat.channelconfig.noReplyIntervalTimeValidate": "فاصله زمانی عدم پاسخ مشتری نمی تواند بیشتر از فاصله زمانی اعلان عدم پاسخ مشتری باشد.",
  "ccmessaging.chat.channelconfig.noReplyTime": "فاصله زمانی پاسخ (دقیقه)",
  "ccmessaging.chat.channelconfig.offBeginTime": "از زمان شروع تا زمان فعلی",
  "ccmessaging.chat.channelconfig.offLastAgentType": "آخرین نوع نماینده",
  "ccmessaging.chat.channelconfig.offTimeRange": "در محدوده زمانی",
  "ccmessaging.chat.channelconfig.offToday": "در روز جاری",
  "ccmessaging.chat.channelconfig.onlyContainSubType": "هنگامی که یک نماینده یک جلسه را منتقل می کند، فقط صف های مهارت از نوع کانال را نمایش دهید.",
  "ccmessaging.chat.channelconfig.orgConfiguration": "پیکربندی OU",
  "ccmessaging.chat.channelconfig.pageId": "شناسه صفحه",
  "ccmessaging.chat.channelconfig.personalSetting": "تنظیمات شخصی سازی شده",
  "ccmessaging.chat.channelconfig.phoneNumber": "شماره تلفن",
  "ccmessaging.chat.channelconfig.pleaseSelect": "لطفا انتخاب کنید...",
  "ccmessaging.chat.channelconfig.PreferredLastAgent": "آخرین حالت نماینده",
  "ccmessaging.chat.channelconfig.Previous": "قبلی",
  "ccmessaging.chat.channelconfig.privateKeyValidate": "مقدار نمی تواند خالی باشد و باید در الگو JSON باشد، مقدار کلید در JSON فقط می تواند شامل حروف، ارقام و کاراکترهای خاص باشد.",
  "ccmessaging.chat.channelconfig.publicConfiguration": "پیکربندی مشترک",
  "ccmessaging.chat.channelconfig.pushMessageUrlValid": "پیکربندی کانال ذخیره نشد. بررسی کنید که آدرس یا گواهی ارسال پیام معتبر است.",
  "ccmessaging.chat.channelconfig.qryaddressconfigfailed": "جستجو از قانون تأیید chatbotAddress کانال 5G انجام نشد. قانون تأیید پیکربندی نشده است.",
  "ccmessaging.chat.channelconfig.queryType": "حالت انتخاب",
  "ccmessaging.chat.channelconfig.queueReminder": "یادآوری صف",
  "ccmessaging.chat.channelconfig.queueReminderContent": "محتوای یادآور صف",
  "ccmessaging.chat.channelconfig.queueReminderInterval": "فاصله یادآوری صف (ثانیه)",
  "ccmessaging.chat.channelconfig.reCreateScenario": "تولید شده است",
  "ccmessaging.chat.channelconfig.refreshAccessToken": "بازخوانی accessToken انجام نشد.",
  "ccmessaging.chat.channelconfig.robotAccessCode": "کد دسترسی چت بات",
  "ccmessaging.chat.channelconfig.robotApplicationKey": "کلید برنامه چت بات",
  "ccmessaging.chat.channelconfig.robotSet": "پیکربندی چت بات",
  "ccmessaging.chat.channelconfig.routeType": "حالت مسیریابی",
  "ccmessaging.chat.channelconfig.routeTypeSkill": "مسیریابی صف مهارت",
  "ccmessaging.chat.channelconfig.saveAndToNext": "ذخیره کنید و به مرحله بعد بروید",
  "ccmessaging.chat.channelconfig.savechannelconfigfailed": "پیکربندی کانال ذخیره نشد!",
  "ccmessaging.chat.channelconfig.scanCodeHosting": "کد QR",
  "ccmessaging.chat.channelconfig.scenario.status.existent": "کلید سناریو: بله",
  "ccmessaging.chat.channelconfig.scenario.status.noExistent": "کلید سناریو: خیر",
  "ccmessaging.chat.channelconfig.select.algorithm": "یک الگوریتم رمزگذاری را انتخاب کنید",
  "ccmessaging.chat.channelconfig.select.algorithm.configtips": "الگوریتم رمزگذاری پشتیبانی شده را بر اساس الزامات پلتفرم باز کانال پیکربندی کنید.",
  "ccmessaging.chat.channelconfig.select.algorithm.safetips": "این الگوریتم به اندازه کافی قوی نیست. اگر پلتفرم از این الگوریتم پشتیبانی می کند، به شما توصیه می شود از یک الگوریتم رمزگذاری بالاتر استفاده کنید.",
  "ccmessaging.chat.channelconfig.selectAccessChannel": "کانال دسترسی را انتخاب کنید",
  "ccmessaging.chat.channelconfig.selectConfigurationMethod": "روش پیکربندی را انتخاب کنید",
  "ccmessaging.chat.channelconfig.selectRobot": "انتخاب ربات",
  "ccmessaging.chat.channelconfig.selectRobotAssistant": "دستیار ربات را انتخاب کنید",
  "ccmessaging.chat.channelconfig.sessionTransferConfig": "انتقال جلسه",
  "ccmessaging.chat.channelconfig.silentAgentDelete": "آیا مطمئنید که می‌خواهید پیکربندی قطع صدا نماینده انتخاب شده را حذف کنید؟",
  "ccmessaging.chat.channelconfig.silentAgentSelect": "لطفاً پیکربندی قطع صدا نماینده را برای حذف انتخاب کنید.",
  "ccmessaging.chat.channelconfig.silentAgentSkillList": "صف مهارت Silent Agent",
  "ccmessaging.chat.channelconfig.skill": "Skilllist (کد دسترسی + کد خروجی)",
  "ccmessaging.chat.channelconfig.skillList": "صف مهارت",
  "ccmessaging.chat.channelconfig.smsRepeate": "از دروازه پیامک استفاده شده است. برای مشاهده دروازه پیامک به صفحه پیکربندی مسیر پیام بروید.",
  "ccmessaging.chat.channelconfig.startTime": "زمان شروع",
  "ccmessaging.chat.channelconfig.sub": "کد زیر را کپی و در برچسب وب سایت خود قرار دهید، ذخیره و منتشر کنید، می توانید دسترسی سریع Huawei ServiceCloud را تکمیل کنید. پورتال Huawei Service Cloud در گوشه سمت راست پایین وب سایت شما ظاهر می شود. برای رفتن به میز کار خدمات مشتری کلیک کنید. مکالمه در زمان واقعی",
  "ccmessaging.chat.channelconfig.submit": "ارسال",
  "ccmessaging.chat.channelconfig.submitVerification": "دستورالعمل های یکپارچه سازی",
  "ccmessaging.chat.channelconfig.successfulAccess": "دسترسی موفق",
  "ccmessaging.chat.channelconfig.thirdPartySecretKey": "کلید احراز هویت شخص ثالث",
  "ccmessaging.chat.channelconfig.time": "محدوده زمانی (دقیقه)",
  "ccmessaging.chat.channelconfig.timeoutReply": "زمان پاسخ",
  "ccmessaging.chat.channelconfig.timerange": "محدوده زمانی",
  "ccmessaging.chat.channelconfig.timeZone": "منطقه زمانی",
  "ccmessaging.chat.channelconfig.today": "امروز",
  "ccmessaging.chat.channelconfig.triggerText": "اعلان پیام آفلاین",
  "ccmessaging.chat.channelconfig.trustCertificate": "گواهی اعتماد",
  "ccmessaging.chat.channelconfig.trybutton": "تلاش كردن",
  "ccmessaging.chat.channelconfig.turnAI": "به چت بات متصل شوید",
  "ccmessaging.chat.channelconfig.turnArtificial": "کلمه کلیدی برای انتقال به نماینده (با نقطه ویرگول چینی یا انگلیسی جدا شده است)",
  "ccmessaging.chat.channelconfig.turnAssistant": "به Assistant متصل شوید",
  "ccmessaging.chat.channelconfig.turnManualPrompt": "درخواست انتقال به نماینده",
  "ccmessaging.chat.channelconfig.turnArtificial.tip": "جدا شده توسط نیم کولون های چینی یا انگلیسی",
  "ccmessaging.chat.channelconfig.twitterBackground": "سرور Twitter",
  "ccmessaging.chat.channelconfig.twittertips": "می توانید به وب سایت های زیر دسترسی داشته باشید. برای احراز هویت Twitter روی ارسال کلیک کنید.",
  "ccmessaging.chat.channelconfig.update": "به روز رسانی",
  "ccmessaging.chat.channelconfig.updatecontactfailed": "تماس به‌روزرسانی نشد!",
  "ccmessaging.chat.channelconfig.voiceAccess": "Voice IVR Called Route",
  "ccmessaging.chat.channelconfig.voiceAgentSkill": "صف مهارت Silent Agent",
  "ccmessaging.chat.channelconfig.webhookConfirmPassword": "تایید رمز عبور Webhook ",
  "ccmessaging.chat.channelconfig.webHookException": "زمان معتبر برای رمز عبور webHook دریافت نشد",
  "ccmessaging.chat.channelconfig.webhookPassword": "رمز عبور Webhook",
  "ccmessaging.chat.channelconfig.webhookPassword.newPassword": "رمز عبور جدید",
  "ccmessaging.chat.channelconfig.webhookPassword.noValidPassword": "رمز عبور موجود نیست",
  "ccmessaging.chat.channelconfig.webhookPassword.oldPassword": "دوره قفل رمز عبور",
  "ccmessaging.chat.channelconfig.webhookPassword.validPeriod": "اعتبار دارد تا:",
  "ccmessaging.chat.channelconfig.webhookPassword.validPeriod.fifthDayTip": "کمتر از 15 روز اعتبار دارد",
  "ccmessaging.chat.channelconfig.webhookPassword.validPeriod.oneDayTip": "اعتبار کمتر از یک روز لطفا اصلاح کنید",
  "ccmessaging.chat.channelconfig.wechat.encryption.tip": "غیرفعال کردن رمزگذاری پیام ممکن است خطرات امنیتی ایجاد کند. این تنظیم باید با تنظیمات پلتفرم حساب‌های رسمی WeChat مطابقت داشته باشد.",
  "ccmessaging.chat.channelconfig.wechat.qr.label": "برای مجوز، کد QR WeChat را اسکن کنید",
  "ccmessaging.chat.channelconfig.wechat.qr.label2": "مجدداً مجوز دهید",
  "ccmessaging.chat.channelconfig.wechat.qr.label3": "موفقیت آمیز بودن مجوز",
  "ccmessaging.chat.channelconfig.weChatBackground": "سرور WeChat",
  "ccmessaging.chat.channelconfig.weChatMessageEncryptionSwitch": "سوئیچ رمزگذاری پیام WeChat",
  "ccmessaging.chat.channelconfig.welcomeMessage": "با درود",
  "ccmessaging.chat.channelconfig.whatsAppBackground": "سرور WhatsApp",
  "ccmessaging.chat.channelconfig.workTimeDay": "روز کاری",
  "ccmessaging.chat.channelconfig.worktimeerror": "لطفا بازه زمانی عادی را وارد کنید!",
  "ccmessaging.chat.chatcard.audio": "صوتی",
  "ccmessaging.chat.chatcard.button.addtabs": "افزودن گزینه های کارت",
  "ccmessaging.chat.chatcard.cancel": "لغو کنید",
  "ccmessaging.chat.chatcard.cardId": "شناسه الگو",
  "ccmessaging.chat.chatcard.cardName": "نام",
  "ccmessaging.chat.chatcard.cardNamePlaceholder": "نام الگو کارت را وارد کنید",
  "ccmessaging.chat.chatcard.cardTitle": "عنوان",
  "ccmessaging.chat.chatcard.cardTitlePlaceholder": "عنوان کارت را وارد کنید",
  "ccmessaging.chat.chatcard.create": "صرفه جویی",
  "ccmessaging.chat.chatcard.delete": "حذف",
  "ccmessaging.chat.chatcard.deleteFail": "حذف انجام نشد",
  "ccmessaging.chat.chatcard.deleteLimit": "به الگو ارجاع داده شده است و قابل حذف نیست.",
  "ccmessaging.chat.chatcard.deleteSuccess": "حذف موفقیت",
  "ccmessaging.chat.chatcard.deleteTips": "تأیید پارامتر انجام نشد.",
  "ccmessaging.chat.chatcard.deleteWhatsAppTemplate.Fail": "حذف ناموفق بود زیرا الگو قبلاً توسط یک پیام الگوی WhatsApp ارجاع داده شده است.",
  "ccmessaging.chat.chatcard.description": "شرح",
  "ccmessaging.chat.chatcard.descriptionPlaceholder": "توضیحات کارت را وارد کنید",
  "ccmessaging.chat.chatcard.details": "جزئیات کارت",
  "ccmessaging.chat.chatcard.dial": "شماره گیری کنید",
  "ccmessaging.chat.chatcard.down": "حرکت به پایین",
  "ccmessaging.chat.chatcard.edit": "ویرایش کنید",
  "ccmessaging.chat.chatcard.fail": "شکست",
  "ccmessaging.chat.chatcard.failTip": "افزودن ناموفق تعداد گزینه های اضافه شده به حداکثر می رسد.",
  "ccmessaging.chat.chatcard.folded": "تا شده",
  "ccmessaging.chat.chatcard.id": "شناسه الگو را وارد کنید.",
  "ccmessaging.chat.chatcard.image": "تصور کن",
  "ccmessaging.chat.chatcard.input": "نام الگو را وارد کنید",
  "ccmessaging.chat.chatcard.invalidUrl": "URL با http://,https:// شروع نمی شود",
  "ccmessaging.chat.chatcard.linking": "لینک دادن",
  "ccmessaging.chat.chatcard.mediaFileType": "نوع فایل رسانه ای",
  "ccmessaging.chat.chatcard.mediaHeight": "Media height",
  "ccmessaging.chat.chatcard.mediaType": "نوع رسانه",
  "ccmessaging.chat.chatcard.mediumHeight": "Media height",
  "ccmessaging.chat.chatcard.messageTemplateCreate": "ایجاد یک الگو کارت",
  "ccmessaging.chat.chatcard.messageTemplateDelete": "حذف یک الگو",
  "ccmessaging.chat.chatcard.messageTemplateModify": "اصلاح یک الگو",
  "ccmessaging.chat.chatcard.multiMediaId": "منبع رسانه ای",
  "ccmessaging.chat.chatcard.multimediaid": "شناسه چند رسانه ای",
  "ccmessaging.chat.chatcard.multimedianame": "نام فایل چندرسانه ای",
  "ccmessaging.chat.chatcard.name": "نام را وارد کنید.",
  "ccmessaging.chat.chatcard.NoneTitle": "بدون عنوان",
  "ccmessaging.chat.chatcard.operate": "عمل",
  "ccmessaging.chat.chatcard.queryTemplateFail": "الگو جستجو شکست خورد",
  "ccmessaging.chat.chatcard.replay": "بازپخش",
  "ccmessaging.chat.chatcard.saveFail": "ذخیره شکست خورد",
  "ccmessaging.chat.chatcard.saveSuccess": "ذخیره موفق شد",
  "ccmessaging.chat.chatcard.selectMultiMediaId": "منبع چند رسانه ای مربوطه را انتخاب کنید.",
  "ccmessaging.chat.chatcard.selectMultiMediaTips": "لطفا منابع چند رسانه ای را انتخاب کنید.",
  "ccmessaging.chat.chatcard.shortHeight": "قد کوتاه",
  "ccmessaging.chat.chatcard.strcontentTip": "درخواست های HTTP خطرات امنیتی دارند.",
  "ccmessaging.chat.chatcard.success": "موفقیت",
  "ccmessaging.chat.chatcard.tabs": "گزینه های کارت",
  "ccmessaging.chat.chatcard.tabs.displaytext": "متن نمایش کارت",
  "ccmessaging.chat.chatcard.tabs.selectcontent": "محتوا را انتخاب کنید",
  "ccmessaging.chat.chatcard.tabs.selectType": "نوع گزینه",
  "ccmessaging.chat.chatcard.tabsname": "نام گزینه کارت",
  "ccmessaging.chat.chatcard.tallHeight": "قد بلند",
  "ccmessaging.chat.chatcard.up": "حرکت به بالا",
  "ccmessaging.chat.chatcard.video": "ویدیو",
  "ccmessaging.chat.chatcard.viewTips": "الگوی کارت حذف شده است و نمی توان آن را پیش نمایش کرد.",
  "ccmessaging.chat.chatcard.whatsappTemplateCreate": "ایجاد الگو WhatsApp",
  "ccmessaging.chat.chatcard.willdeletechatcard": "آیا مطمئن هستید که می خواهید این کارت را حذف کنید؟",
  "ccmessaging.chat.chatcard.willdeletechatTemplate": "آیا مطمئن هستید که می خواهید الگو WhatsApp را حذف کنید؟",
  "ccmessaging.chat.chatconfig.add": "جدید",
  "ccmessaging.chat.chatconfig.channelAccessCode": "کد دسترسی به کانال",
  "ccmessaging.chat.chatconfig.channelOccupied": "کانال قبلا اشغال شده است.",
  "ccmessaging.chat.chatconfig.channelType": "نوع کانال",
  "ccmessaging.chat.chatconfig.configurationId": "شناسه پیکربندی",
  "ccmessaging.chat.chatconfig.delete": "حذف",
  "ccmessaging.chat.chatconfig.deletechannelAccessCode": "حذف پیکربندی کانال",
  "ccmessaging.chat.chatconfig.deletefail": "حذف نشد.",
  "ccmessaging.chat.chatconfig.deletesuccess": "با موفقیت حذف شد.",
  "ccmessaging.chat.chatconfig.idisnull": "شناسه خالی است",
  "ccmessaging.chat.chatconfig.inquire": "جستجو کردن",
  "ccmessaging.chat.chatconfig.modify": "تغییر",
  "ccmessaging.chat.chatconfig.operate": "عمل",
  "ccmessaging.chat.chatconfig.refresh": "بازخوانی",
  "ccmessaging.chat.chatconfig.reset": "بازنشانی کنید",
  "ccmessaging.chat.chatconfig.rollBack": "پس گرفتن",
  "ccmessaging.chat.chatconfig.selectchannelAccessCode": "پیکربندی کانال را انتخاب کنید.",
  "ccmessaging.chat.chatconfig.skillId": "صف مهارت",
  "ccmessaging.chat.chatconfig.willdeletechannelAccessCode": "آیا مطمئن هستید که می خواهید پیکربندی کانال انتخابی را حذف کنید؟",
  "ccmessaging.chat.chatemail.cancelhandled": "لغو پردازش شد",
  "ccmessaging.chat.chatemail.delete": "حذف",
  "ccmessaging.chat.chatemail.details": "چشم انداز",
  "ccmessaging.chat.chatemail.forwarded": "فوروارد کرد",
  "ccmessaging.chat.chatemail.forwardedTime": "ارسال شده در",
  "ccmessaging.chat.chatemail.handled": "فرآوری شده",
  "ccmessaging.chat.chatemail.maxNumberImages": "تعداد تصاویر محلی آپلود شده به حداکثر 20 رسیده است.",
  "ccmessaging.chat.chatemail.saveDraft": "ذخیره به عنوان پیش نویس",
  "ccmessaging.chat.chatemail.sender": "فرستنده:",
  "ccmessaging.chat.chatholiday.calendar.preview": "پیش نمایش تقویم",
  "ccmessaging.chat.chatholiday.checkHolidaySize": "تعداد ورودی‌های زیادی وجود دارد، لطفاً ابتدا ورودی‌های اضافی را حذف کنید",
  "ccmessaging.chat.chatholiday.date": "تاریخ",
  "ccmessaging.chat.chatholiday.dateConfiguration": "پیکربندی تاریخ",
  "ccmessaging.chat.chatholiday.delete": "حذف",
  "ccmessaging.chat.chatholiday.description": "شرح",
  "ccmessaging.chat.chatholiday.designation.alert": "تاریخ پایان باید دیرتر از تاریخ شروع باشد",
  "ccmessaging.chat.chatholiday.designation.cancel": "لغو کنید",
  "ccmessaging.chat.chatholiday.designation.complete": "کامل",
  "ccmessaging.chat.chatholiday.designation.days": "چند روزه",
  "ccmessaging.chat.chatholiday.designation.end": "تاریخ پایان",
  "ccmessaging.chat.chatholiday.designation.norepeating": "تکرار نشد",
  "ccmessaging.chat.chatholiday.designation.oneday": "1 روز",
  "ccmessaging.chat.chatholiday.designation.repeating": "هر سال تکرار می شود",
  "ccmessaging.chat.chatholiday.designation.selectrule": "تاریخ انتخاب شده نمی تواند با تاریخ انتخاب شده یکسان باشد",
  "ccmessaging.chat.chatholiday.designation.show": "هر سال",
  "ccmessaging.chat.chatholiday.designation.start": "تاریخ شروع",
  "ccmessaging.chat.chatholiday.designation.type": "نوع تاریخ",
  "ccmessaging.chat.chatholiday.friday": "جمعه",
  "ccmessaging.chat.chatholiday.holidays.explanation": "- می توانید روزهای خاصی را به عنوان روزهای غیر کاری مشخص کنید، به عنوان مثال، 13 سپتامبر 2019 (جمعه، جشنواره نیمه پاییز چین).",
  "ccmessaging.chat.chatholiday.isDelete.otherdays": "آیا مطمئنید که می خواهید این تاریخ استثنا را حذف کنید؟",
  "ccmessaging.chat.chatholiday.isDelete.specified": "آیا مطمئن هستید که می خواهید این تاریخ مشخص شده را حذف کنید؟",
  "ccmessaging.chat.chatholiday.monday": "دوشنبه",
  "ccmessaging.chat.chatholiday.new": "جدید",
  "ccmessaging.chat.chatholiday.nonWorkbench": "پیکربندی غیر روز کاری",
  "ccmessaging.chat.chatholiday.noworking": "روز غیر کاری",
  "ccmessaging.chat.chatholiday.operate": "عمل کنند",
  "ccmessaging.chat.chatholiday.other.prompt": "تاریخ انتخاب شده همان تاریخ موجود است",
  "ccmessaging.chat.chatholiday.otherdays.exception": "تاریخ استثنا",
  "ccmessaging.chat.chatholiday.otherdays.explanation": "- می توانید روزهای خاصی را به عنوان روزهای کاری انتخاب کنید. حداکثر بازه زمانی یک سال است. به عنوان مثال: می توانید 13 جولای 2019 (شنبه، اضافه کاری موقت مورد نیاز) را به عنوان روز کاری انتخاب کنید.",
  "ccmessaging.chat.chatholiday.repeat": "تکرار قوانین",
  "ccmessaging.chat.chatholiday.saturday": "شنبه",
  "ccmessaging.chat.chatholiday.savechatholidayfailed": "ذخیره پیکربندی تعطیلات انجام نشد",
  "ccmessaging.chat.chatholiday.specified.date": "تاریخ مشخص شده",
  "ccmessaging.chat.chatholiday.sunday": "یکشنبه",
  "ccmessaging.chat.chatholiday.thursday": "پنج شنبه",
  "ccmessaging.chat.chatholiday.tuesday": "سه شنبه",
  "ccmessaging.chat.chatholiday.wednesday": "چهار شنبه",
  "ccmessaging.chat.chatholiday.weekend.explanation": "می توانید روزهای خاصی را در هفته به عنوان روزهای غیر کاری تعیین کنید، به عنوان مثال، شنبه و یکشنبه.",
  "ccmessaging.chat.chatholiday.weekly": "هفتگی",
  "ccmessaging.chat.chatholiday.workbench": "پیکربندی میز کار",
  "ccmessaging.chat.chatholiday.working.day": "روز کاری",
  "ccmessaging.chat.chatmanage.agentIntervalTime": "فاصله زمانی بدون پاسخ نماینده (دقیقه)",
  "ccmessaging.chat.chatmanage.agentSessionEndText": "درخواست برای تخصیص مجدد نماینده",
  "ccmessaging.chat.chatmanage.autoEndSession": "اگر مهمان در مدت زمان مشخص شده پاسخ ندهد، گفتگو به طور خودکار خاتمه می یابد.",
  "ccmessaging.chat.chatmanage.autoEndSessionMorethan": "شرح فاصله زمانی که مشتری پاسخی ندارد نمی تواند از 100 کاراکتر تجاوز کند.",
  "ccmessaging.chat.chatmanage.autoEndSessionnotnull": "شرح فاصله عدم پاسخ مشتری نمی تواند خالی باشد.",
  "ccmessaging.chat.chatmanage.autoEndSessionNum": "مشتری به اطلاع رسانی پاسخ نمی دهد فاصله زمانی باید یک عدد باشد.",
  "ccmessaging.chat.chatmanage.autoEndSessionRange": "محدوده شماره فاصله یادآوری مشتری بدون پاسخ باید بین 0 تا 60 باشد",
  "ccmessaging.chat.chatmanage.cancel": "لغو کنید",
  "ccmessaging.chat.chatmanage.edit": "ویرایش کنید",
  "ccmessaging.chat.chatmanage.endTime": "زمان پایان",
  "ccmessaging.chat.chatmanage.endTimeNum": "زمان پایان باید یک عدد باشد.",
  "ccmessaging.chat.chatmanage.endTimeRange": "عدد زمان پایان باید از 0 تا 24 باشد.",
  "ccmessaging.chat.chatmanage.fail": "شکست",
  "ccmessaging.chat.chatmanage.freeTimeText": "اعلان زمان غیر کاری",
  "ccmessaging.chat.chatmanage.freeTimeTextMorethan": "شرح اعلان زمان غیر کاری نمی تواند بیش از 100 کاراکتر داشته باشد.",
  "ccmessaging.chat.chatmanage.freeTimeTextnotnull": "شرح یادآوری زمان غیرکاری نمی تواند خالی باشد.",
  "ccmessaging.chat.chatmanage.intervalTime": "فاصله زمانی بدون پاسخ (دقیقه)",
  "ccmessaging.chat.chatmanage.save": "صرفه جویی",
  "ccmessaging.chat.chatmanage.savefailed": "ذخیره نشد.",
  "ccmessaging.chat.chatmanage.savesuccess": "با موفقیت ذخیره شد.",
  "ccmessaging.chat.chatmanage.sessionEndText": "نتیجه",
  "ccmessaging.chat.chatmanage.sessionEndTextMorethan": "شرح نتیجه گفتگو نمی تواند بیش از 100 کاراکتر داشته باشد.",
  "ccmessaging.chat.chatmanage.sessionEndTextnotnull": "شرح پایان جلسه نمی تواند خالی باشد.",
  "ccmessaging.chat.chatmanage.specialCharacters": "مقدار شامل کاراکترهای خاص است. لطفا آن را اصلاح کنید.",
  "ccmessaging.chat.chatmanage.startTime": "زمان شروع",
  "ccmessaging.chat.chatmanage.startTimeNum": "زمان شروع باید یک عدد باشد.",
  "ccmessaging.chat.chatmanage.startTimeRange": "تعداد زمان شروع باید از 0 تا 24 باشد",
  "ccmessaging.chat.chatmanage.success": "موفقیت",
  "ccmessaging.chat.chatmanage.useRobot": "به چت بات متصل شوید",
  "ccmessaging.chat.chatmanage.workTime": "زمان کار نماینده (در الگو 24 ساعته)",
  "ccmessaging.chat.chatmanage.workTimeMorethan": "شرح زمان کار نمی تواند بیش از 100 کاراکتر داشته باشد.",
  "ccmessaging.chat.chatpersonalization.add": "اضافه کردن",
  "ccmessaging.chat.chatpersonalization.avatarerror": "یک تصویر را انتخاب کنید.!",
  "ccmessaging.chat.chatpersonalization.cancel": "لغو کنید",
  "ccmessaging.chat.chatpersonalization.changeAvatar": "آواتار را تغییر دهید",
  "ccmessaging.chat.chatpersonalization.concurrentAgentsNumber": "تعداد مشتریان چندرسانه‌ای که به طور همزمان از نمایندگان خدمات گرفته اند.",
  "ccmessaging.chat.chatpersonalization.concurrentNumberPriority": "اولویت پیکربندی تعداد مشتریان چندرسانه ای که به طور همزمان از نمایندگان خدمات میگیرند به شرح زیر است: سطح نماینده> سطح سازمان> سطح مستاجر. مقدار 0 نشان می دهد که پیکربندی این سطح اثر نمی گذارد و از پیکربندی سطوح دیگر استفاده می شود.",
  "ccmessaging.chat.chatpersonalization.delete": "حذف",
  "ccmessaging.chat.chatpersonalization.edit": "ویرایش کنید",
  "ccmessaging.chat.chatpersonalization.hour": "ساعت",
  "ccmessaging.chat.chatpersonalization.MaximumConcurrent": "حداکثر مشتریان خدمات همزمان",
  "ccmessaging.chat.chatpersonalization.nickname": "نام مستعار",
  "ccmessaging.chat.chatpersonalization.operating": "عمل",
  "ccmessaging.chat.chatpersonalization.prompt": "سریع",
  "ccmessaging.chat.chatpersonalization.save": "ذخیره",
  "ccmessaging.chat.chatpersonalization.savefailed": "ذخیره نشد.",
  "ccmessaging.chat.chatpersonalization.saveSuccess": "با موفقیت ذخیره شد.",
  "ccmessaging.chat.chatpersonalization.specialStrValidateFailed": "مقدار نمی تواند شامل کاراکترهای خاص باشد.",
  "ccmessaging.chat.chatpersonalization.tips1": "پیکربندی ارسال شده تأیید نشد!",
  "ccmessaging.chat.chatpersonalization.welcome": "با درود",
  "ccmessaging.chat.chatpersonalization.welcomeLimit": "حداکثر چهار پیام تبریک شخصی سازی شده را می توان پیکربندی کرد.",
  "ccmessaging.chat.chatpersonalization.welcomeText": "احوال پرسی شخصی سازی شده",
  "ccmessaging.chat.chatpersonalization.welcometime": "محدوده زمانی",
  "ccmessaging.chat.chatpersonalization.welcometimeerror": "یک بخش زمانی معمولی را وارد کنید!",
  "ccmessaging.chat.chatpersonalization.welcometimeerror.nolist": "لطفاً یک تبریک شخصی سازی شده تنظیم کنید!",
  "ccmessaging.chat.chatpersonalization.welcometimeerror.startGreaterThanEnd": "زمان شروع احوال پرسی شخصی سازی شده نمی تواند بیشتر یا برابر با زمان پایان باشد!",
  "ccmessaging.chat.chatpersonalization.welcometimeerror.timeOverlap": "دوره زمانی احوال پرسی شخصی سازی شده نمی تواند همپوشانی داشته باشد!",
  "ccmessaging.chat.chinese.separator": ":",
  "ccmessaging.chat.clickCallClient.denial": "لینک منقضی شده است. در صورت نیاز دوباره با نماینده تماس بگیرید تا لینک را ارسال کنید.",
  "ccmessaging.chat.close": "چند ثانیه پس از بسته شدن خودکار صفحه",
  "ccmessaging.chat.connnect.offlineuser.failed": "برقراری تماس آفلاین به عنوان نماینده انجام نشد.",
  "ccmessaging.chat.connnect.offlineuser.userId.beyondMaxLength": "شناسه کاربری بیش از 128 است",
  "ccmessaging.chat.contact.callin": "تماس ورودی",
  "ccmessaging.chat.contact.callinandcallout": "تماس ورودی و خروجی",
  "ccmessaging.chat.contact.callout": "تماس خروجی",
  "ccmessaging.chat.email.AgentAccountName": "حساب نماینده",
  "ccmessaging.chat.email.agentNumber": "شناسه نماینده",
  "ccmessaging.chat.email.autosave": "ویرایش های شما به طور خودکار در یک پیش نویس ذخیره شده است.",
  "ccmessaging.chat.email.button.advancedSearch": "جستجوی پیشرفته",
  "ccmessaging.chat.email.button.batchAssignment": "تخصیص دسته ای",
  "ccmessaging.chat.email.button.bulkMove": "حرکت انبوه",
  "ccmessaging.chat.email.card.reply": "پاسخ",
  "ccmessaging.chat.email.channel.attendeeError": "تعداد گیرندگان Cc از حد مجاز فراتر رفته است.",
  "ccmessaging.chat.email.channel.BccSenderError": "تعداد گیرندگان BCC از حد مجاز فراتر رفته است.",
  "ccmessaging.chat.email.channel.ccandbcc.containchannelemail": "گیرندگان Cc و BCC نمی‌توانند آدرس ایمیل عمومی شرکای کانال باشند.",
  "ccmessaging.chat.email.channel.confdeletedraft": "آیا مطمئن هستید که می خواهید ایمیل پیش نویس را حذف کنید؟",
  "ccmessaging.chat.email.channel.confdmodifydraft": "آیا مطمئن هستید که ایمیل را پردازش کرده اید؟",
  "ccmessaging.chat.email.channel.deletedraft": "تایید",
  "ccmessaging.chat.email.channel.deletedraftfail": "ایمیل پیش نویس حذف نشد.",
  "ccmessaging.chat.email.channel.deletedraftsuccess": "ایمیل پیش نویس با موفقیت حذف شد.",
  "ccmessaging.chat.email.channel.draftOverMax": "تعداد پیش‌نویس‌های ایمیل از حداکثر بیشتر است.",
  "ccmessaging.chat.email.channel.emailaddressinvalid": "آدرس ایمیل نامعتبر است.",
  "ccmessaging.chat.email.channel.modifydraft": "تایید",
  "ccmessaging.chat.email.channel.modifydraftfail": "اصلاح نشد.",
  "ccmessaging.chat.email.channel.modifydraftsuccess": "اصلاح با موفقیت انجام شد.",
  "ccmessaging.chat.email.channel.paramisempty": "پارامتر نمی تواند خالی باشد.",
  "ccmessaging.chat.email.channel.paramiserror": "پارامتر نامعتبر است.",
  "ccmessaging.chat.email.channel.receiveGateway": "درگاه دریافت ایمیل",
  "ccmessaging.chat.email.channel.receivererror": "گیرنده، کاربر انتخاب شده نیست.",
  "ccmessaging.chat.email.channel.savedraftfail": "ایمیل به عنوان پیش نویس ذخیره نشد.",
  "ccmessaging.chat.email.channel.savedraftsuccess": "ایمیل به عنوان پیش نویس با موفقیت ذخیره شد.",
  "ccmessaging.chat.email.channel.sendGateway": "درگاه ارسال ایمیل",
  "ccmessaging.chat.email.channel.title.reply": "پاسخ:",
  "ccmessaging.chat.email.check.containGatewayEmail": "از ارسال ایمیل به کانال ها و دریافت آدرس ایمیل خودداری کنید.",
  "ccmessaging.chat.email.check.contentOverMax": "اندازه متن پیام بیش از حد مجاز است.",
  "ccmessaging.chat.email.check.localImageNumberOverMax": "تعداد تصاویر محلی آپلود شده در متن ایمیل از حداکثر بیشتر است.",
  "ccmessaging.chat.email.check.localImageOverMax": "اندازه تصاویر محلی آپلود شده در متن ایمیل از حداکثر بیشتر است.",
  "ccmessaging.chat.email.check.localImageType": "فرمت تصویر پشتیبانی نمی شود.",
  "ccmessaging.chat.email.check.uploadATTACHMENTPermissionAndFeature": "هیچ مجوزی برای آپلود پیوست وجود ندارد، یا این عملکرد فعال نیست.",
  "ccmessaging.chat.email.check.uploadImagePermissionAndFeature": "اجازه آپلود تصاویر محلی وجود ندارد، یا این عملکرد فعال نیست.",
  "ccmessaging.chat.email.forward": "Forward",
  "ccmessaging.chat.email.forward.prefix": "Forward:",
  "ccmessaging.chat.email.localImage.confirm": "تایید",
  "ccmessaging.chat.email.localImage.height": "ارتفاع",
  "ccmessaging.chat.email.localImage.lable": "فایل",
  "ccmessaging.chat.email.localImage.width": "عرض",
  "ccmessaging.chat.email.param.empty": "عنوان یا محتوای ایمیل نمی تواند خالی باشد.",
  "ccmessaging.chat.email.reply": "پاسخ",
  "ccmessaging.chat.email.reply.separator": ":",
  "ccmessaging.chat.email.tips.addFailDuplicateName": "اضافه نشد: نام تکراری.",
  "ccmessaging.chat.email.tips.addFailOtherErrors": "افزودن ناموفق بود: خطاهای دیگر.",
  "ccmessaging.chat.email.tips.addMaximumLimit": "حداکثر 20 زیر شاخه در همان سطح.",
  "ccmessaging.chat.email.tips.addSuccess": "افزودن موفقیت آمیز.",
  "ccmessaging.chat.email.tips.amendFailDuplicateName": "اصلاح ناموفق بود: نام تکراری.",
  "ccmessaging.chat.email.tips.amendFailOtherErrors": "اصلاح نشد: خطاهای دیگر.",
  "ccmessaging.chat.email.tips.amendSuccess": "اصلاح با موفقیت انجام شد.",
  "ccmessaging.chat.email.tips.deleteFailExistEmail": "حذف ناموفق بود: نامه ها در دایرکتوری فعلی وجود دارند.",
  "ccmessaging.chat.email.tips.deleteFailExistSub": "حذف ناموفق بود: دایرکتوری فعلی دارای زیر شاخه‌هایی است.",
  "ccmessaging.chat.email.tips.deleteFailOtherErrors": "حذف نشد: خطاهای دیگر.",
  "ccmessaging.chat.email.tips.deleteSuccess": "با موفقیت حذف شد.",
  "ccmessaging.chat.email.tips.isDeleteDirectory": "آیا می خواهید دایرکتوری فعلی را حذف کنید؟",
  "ccmessaging.chat.emailmessage.abandoned": "رها شده است",
  "ccmessaging.chat.emailmessage.agent": "نماینده",
  "ccmessaging.chat.emailmessage.all": "همه",
  "ccmessaging.chat.emailmessage.assigned": "اختصاص داده",
  "ccmessaging.chat.emailmessage.assignEmail.inputAgentName": "نام حساب نماینده را وارد کنید.",
  "ccmessaging.chat.emailmessage.assignEmail.queryAgentName": "یک حساب تجاری وارد کنید.",
  "ccmessaging.chat.emailmessage.assignEmailFailed": "اختصاص دادن ایمیل انجام نشد.",
  "ccmessaging.chat.emailmessage.assignEmailPartialSuccess": "تخصیص برخی از ایمیل ها ناموفق بود.",
  "ccmessaging.chat.emailmessage.assignEmailSuccess": "ایمیل با موفقیت تخصیص داده شد.",
  "ccmessaging.chat.emailmessage.assigning": "اختصاص دهید",
  "ccmessaging.chat.emailmessage.assignOtherEmailFailed": "ایمیل فرستنده قابل تخصیص نیست زیرا در حال رسیدگی است.",
  "ccmessaging.chat.emailmessage.assignStatus": "وضعیت تکلیف",
  "ccmessaging.chat.emailmessage.channel": "پاسخ خودکار کانال",
  "ccmessaging.chat.emailmessage.channelAccessCode": "کد دسترسی به کانال ایمیل",
  "ccmessaging.chat.emailmessage.disAssigned": "اختصاص داده نشده",
  "ccmessaging.chat.emailmessage.fail.assign.AbandonedEmail.assigning.tip": "پیام های حذف شده قابل تخصیص نیستند.",
  "ccmessaging.chat.emailmessage.fail.assign.AbandonedEmail.moving.tip": "پیام های حذف شده قابل انتقال نیستند.",
  "ccmessaging.chat.emailmessage.fail.assign.UnAssignedEmail.tip": "ایمیل های اختصاص نیافته قابل تخصیص نیستند.",
  "ccmessaging.chat.emailmessage.handler": "کنترل کننده",
  "ccmessaging.chat.emailmessage.handlerType": "نوع کنترل کننده",
  "ccmessaging.chat.emailmessage.handleStatus": "وضعیت رسیدگی",
  "ccmessaging.chat.emailmessage.moving": "حرکت",
  "ccmessaging.chat.emailmessage.operation.noMoreThanOneDay": "محدوده زمانی جستجو نمی تواند از یک روز تجاوز کند.",
  "ccmessaging.chat.emailmessage.operation.noMoreThanSevenDay": "محدوده زمانی جستجو نمی تواند بیش از هفت روز باشد.",
  "ccmessaging.chat.emailmessage.receiver": "گیرنده",
  "ccmessaging.chat.emailmessage.robot": "ربات",
  "ccmessaging.chat.emailmessage.sender": "فرستنده",
  "ccmessaging.chat.emailmessage.sendStatus": "ارسال نتایج",
  "ccmessaging.chat.emailmessage.sendTime": "ارسال شد",
  "ccmessaging.chat.emailmessage.skillQueue": "صف مهارت",
  "ccmessaging.chat.emailmessage.title": "عنوان ایمیل",
  "ccmessaging.chat.emailmessage.unassigned": "اختصاص داده شده",
  "ccmessaging.chat.EMOTICONS.beyondFileSize": "تعداد نوع فایل‌های چندرسانه‌ای آپلود شده خطا یا بیشتر از حداکثر است: {n} کیلوبایت!",
  "ccmessaging.chat.facebookpageconfig.add": "صفحه اصلی فیس بوک را اضافه کنید",
  "ccmessaging.chat.facebookpageconfig.config": "پیکربندی",
  "ccmessaging.chat.facebookpageconfig.deleteError": "تنظیمات صفحه اصلی Facebook حذف نشد.",
  "ccmessaging.chat.facebookpageconfig.deleteSuccess": "تنظیمات صفحه اصلی Facebook با موفقیت حذف شد.",
  "ccmessaging.chat.facebookpageconfig.facebookconfig": "تنظیمات صفحه اصلی Facebook",
  "ccmessaging.chat.facebookpageconfig.idNotExist": "داده وجود ندارد.",
  "ccmessaging.chat.facebookpageconfig.isDeleteFacebookPage": "آیا مطمئن هستید که می خواهید تنظیمات صفحه اصلی Facebook را حذف کنید؟",
  "ccmessaging.chat.facebookpageconfig.modify": "ویرایش صفحه آغازۀ Facebook",
  "ccmessaging.chat.facebookpageconfig.modifyError": "تغییر صفحه اصلی Facebook انجام نشد.",
  "ccmessaging.chat.facebookpageconfig.modifySuccess": "صفحه اصلی Facebook با موفقیت اصلاح شد.",
  "ccmessaging.chat.facebookpageconfig.numoversize": "تعداد صفحات اصلی Facebook از حد بالای این کانال بیشتر است.",
  "ccmessaging.chat.facebookpageconfig.pageId": "شناسه صفحه",
  "ccmessaging.chat.facebookpageconfig.pageidexist": "شناسه صفحه اصلی Facebook از قبل وجود دارد.",
  "ccmessaging.chat.facebookpageconfig.saveError": "صفحه اصلی Facebook اضافه نشد.",
  "ccmessaging.chat.facebookpageconfig.saveSuccess": "صفحه اصلی Facebook با موفقیت اضافه شد.",
  "ccmessaging.chat.facebookpageconfig.tokenSecret": "رمز رمز",
  "ccmessaging.chat.fivegCard": "کارت 5G",
  "ccmessaging.chat.ifame.param": "پارامتر",
  "ccmessaging.chat.iframeagentconsole.mail": "ایمیل",
  "ccmessaging.chat.iframeagentconsole.mailCollapse": "جمع شده است",
  "ccmessaging.chat.iframeagentconsole.mailExpand": "جزئیات",
  "ccmessaging.chat.label.accessCode": "کد دسترسی",
  "ccmessaging.chat.label.extCode": "کد پسوند",
  "ccmessaging.chat.label.skill": "صف مهارت",
  "ccmessaging.chat.localFile.authFail": "مجوز ارسال فایل محلی وجود ندارد",
  "ccmessaging.chat.multi.card": "5G Rotating Card",
  "ccmessaging.chat.multi.card.add": "کارت را انتخاب کنید",
  "ccmessaging.chat.multi.card.id": "شناسه کارت",
  "ccmessaging.chat.multi.card.num.error": "۱ تا ۵ تک کارت اضافه کنید.",
  "ccmessaging.chat.multi.chatcard.alignment": "حالت تراز تصویر",
  "ccmessaging.chat.multi.chatcard.alignment.left": "تراز چپ",
  "ccmessaging.chat.multi.chatcard.alignment.right": "تراز راست",
  "ccmessaging.chat.multi.chatcard.choose": "انتخاب کارت",
  "ccmessaging.chat.multi.chatcard.delete": "قالب ارجاع شده است و نمی توان آن را حذف کرد. کارت مرجع NVOD این است: الگو ارجاع داده شده است و قابل حذف نیست. شناسه کارت NVOD ارجاع شده عبارت است از:",
  "ccmessaging.chat.multi.chatcard.sort": "جهت ترتیب کارت",
  "ccmessaging.chat.multi.chatcard.sort.flow": "شناور است",
  "ccmessaging.chat.multi.chatcard.sort.horizontal": "تسطیح",
  "ccmessaging.chat.multi.chatcard.sort.vertical": "عمود بر",
  "ccmessaging.chat.multi.chatcard.width": "عرض کارت",
  "ccmessaging.chat.multi.chatcard.width.medium": "عرض متوسط",
  "ccmessaging.chat.multi.chatcard.width.small": "عرض کوچکتر",
  "ccmessaging.chat.multi.media.resource.invalid": "کارت‌هایی که به منابع رسانه نامعتبر ارجاع می‌دهند. نام کارت:",
  "ccmessaging.chat.multimedia.addCategory": "اضافه کردن دسته",
  "ccmessaging.chat.multimedia.audioManagement": "کتابخانه صوتی",
  "ccmessaging.chat.multimedia.audioUploadTipFormat": "فایل صوتی آپلود شده در حال حاضر فقط از فرمت mp3، aac، m4a پشتیبانی می کند، m4a فقط از LINE پشتیبانی می کند، و LINE فقط از m4a استفاده می کند، و WhatsApp فقط از aac استفاده می کند، و aac پشتیبانی از وب، 5G RCS، WhatsApp، و اندازه فایل نباید از {n} M تجاوز کند.",
  "ccmessaging.chat.multimedia.beyondFileSize": "اندازه فایل چندرسانه ای آپلود شده از حداکثر: {n} مگابایت بیشتر است!",
  "ccmessaging.chat.multimedia.cancel": "لغو کنید",
  "ccmessaging.chat.multimedia.cardManagement": "الگو کارت",
  "ccmessaging.chat.multimedia.categoryName": "نام دسته",
  "ccmessaging.chat.multimedia.chatPhraseManagement": "کتابخانه ChatPhrase",
  "ccmessaging.chat.multimedia.cobrowseSite": "Cobrowse Site",
  "ccmessaging.chat.multimedia.createRichText": "Rich Text بسازید",
  "ccmessaging.chat.multimedia.delete": "حذف",
  "ccmessaging.chat.multimedia.deleteLimit": "منابع توسط قالب کارت {0} ارتباط می‌شود و نمی‌تواند حذف شود.",
  "ccmessaging.chat.multimedia.deleteLimit.usedbyODFS": "این منبع توسط IVR هوشمند ارجاع داده شده است و اجازه حذف آن را نمی دهد",
  "ccmessaging.chat.multimedia.deleteLimit.whatsapp.templatemsg": "منبع را نمی توان حذف کرد زیرا قبلاً توسط یک پیام الگوی WhatsApp ارجاع داده شده است. شناسه پیام الگو:",
  "ccmessaging.chat.multimedia.deleteMultiMediaInfo": "این داده های چند رسانه ای را حذف کنید",
  "ccmessaging.chat.multimedia.deleteMultiMediaInfoError": "داده های چند رسانه ای حذف نشد!",
  "ccmessaging.chat.multimedia.deleteMultiMediaInfoSuccess": "داده های چند رسانه ای با موفقیت حذف شد!",
  "ccmessaging.chat.multimedia.deleteMultiMediaType": "این دسته چند رسانه ای را حذف کنید",
  "ccmessaging.chat.multimedia.deleteMultiMediaTypeError": "دسته بندی کتابخانه چندرسانه ای حذف نشد!",
  "ccmessaging.chat.multimedia.deleteMultiMediaTypeError.usedbyODFS": "خرابی در حذف دسته کتابخانۀ چند رسانه ای! منبعی وجود دارد که توسط IVR هوشمند ارجاع داده شده است!",
  "ccmessaging.chat.multimedia.deleteMultiMediaTypeError.referenced.byCard": "دسته چند رسانه ای حذف نشد زیرا برخی از منابع قبلاً توسط کارت ها ارجاع داده شده اند.",
  "ccmessaging.chat.multimedia.deleteMultiMediaTypeError.referenced.byWhatsAppTemplateMsg": "دسته چند رسانه ای حذف نشد زیرا برخی از منابع قبلاً توسط یک پیام الگوی WhatsApp ارجاع داده شده اند.",
  "ccmessaging.chat.multimedia.deleteMultiMediaTypeSuccess": "دسته چند رسانه ای با موفقیت حذف شد!",
  "ccmessaging.chat.multimedia.description": "شرح",
  "ccmessaging.chat.multimedia.descriptionContainsSpecialCharacters": "توضیحات شامل کاراکترهای خاص است.",
  "ccmessaging.chat.multimedia.descriptionSuperLong": "طول توضیحات بیش از حداکثر است.",
  "ccmessaging.chat.multimedia.documentManagement": "کتابخانه اسناد",
  "ccmessaging.chat.multimedia.documentUploadTipFormat": "فایل ها را می توان در الگو word،pdf،ppt،excel یا zip آپلود کرد. فایل فشرده از فرمت های word،pdf،ppt و excel پشتیبانی می کند. اندازه فایل نمی تواند از {n} مگابایت بیشتر باشد.",
  "ccmessaging.chat.multimedia.download": "دانلود",
  "ccmessaging.chat.multimedia.edit": "ویرایش کنید",
  "ccmessaging.chat.multimedia.emoticonsManagement": "شکلک",
  "ccmessaging.chat.multimedia.emoticonsUploadTipFormat": "فایل شکلک شخصی‌شده آپلود شده می‌تواند در الگوهای JPG، JPEG، PNG یا GIF باشد و اندازه فایل نمی‌تواند از {n} کیلوبایت تجاوز کند.",
  "ccmessaging.chat.multimedia.exceedFileSize": "تعداد نوع فایل‌های چندرسانه‌ای آپلود شده، خطا یا بیشتر از حداکثر است: {n}!",
  "ccmessaging.chat.multimedia.fileName": "نام فایل",
  "ccmessaging.chat.multimedia.fileSize": "حجم فایل",
  "ccmessaging.chat.multiMedia.fileUploadError": "فرمت فایل آپلود شده اشتباه است",
  "ccmessaging.chat.multiMedia.fileUploadInjectionRisk": "این آپلود injection risks دارد. محتوا را بررسی کنید و دوباره امتحان کنید.",
  "ccmessaging.chat.multimedia.fileUploadLabel": "لطفا فایلی را برای آپلود انتخاب کنید",
  "ccmessaging.chat.multiMedia.fileUploadNameError": "الگو نام فایل آپلود شده نادرست است.",
  "ccmessaging.chat.multiMedia.fileUploadTypeError": "فرمت نوع فایل آپلود شده نادرست است.",
  "ccmessaging.chat.multimedia.finish": "پایان",
  "ccmessaging.chat.multimedia.getRichTextInfoError": "داده های rich text به دست نیامد.",
  "ccmessaging.chat.multimedia.id": "شناسه",
  "ccmessaging.chat.multimedia.imageManagement": "کتابخانه تصویر",
  "ccmessaging.chat.multimedia.imageUploadTipFormat": "فایل تصویری آپلود شده در حال حاضر فقط از فرمت های jpg، png، jpeg پشتیبانی می کند و اندازه فایل نباید از {n}M بیشتر باشد.",
  "ccmessaging.chat.multimedia.isDeleteMultiMediaInfo": "آیا مطمئن هستید که می خواهید این داده های چندرسانه ای را حذف کنید؟",
  "ccmessaging.chat.multimedia.isDeleteMultiMediaType": "آیا مطمئن هستید که می خواهید نوع چند رسانه ای و داده های چند رسانه ای آن را حذف کنید؟",
  "ccmessaging.chat.multimedia.latitude": "عرض جغرافیایی",
  "ccmessaging.chat.multimedia.latitudeValidateFailed": "قسمت عدد صحیح عرض جغرافیایی 90-90- و قسمت اعشاری 0 تا 6 رقم است!",
  "ccmessaging.chat.multimedia.locationManagement": "کتابخانه آدرس",
  "ccmessaging.chat.multimedia.longitude": "طول جغرافیایی",
  "ccmessaging.chat.multimedia.longitudeValidateFailed": "قسمت صحیح طول جغرافیایی 180-180- و قسمت اعشاری 0 تا 6 رقم است!",
  "ccmessaging.chat.multimedia.mediaFileType": "نوع فایل رسانه",
  "ccmessaging.chat.multimedia.modifyCategory": "دسته را تغییر دهید",
  "ccmessaging.chat.multimedia.modifyMultiMediaInfo": "تغییر با موفقیت داده های چند رسانه ای!",
  "ccmessaging.chat.multimedia.modifyMultiMediaInfoError": "تغییر داده های چند رسانه ای انجام نشد!",
  "ccmessaging.chat.multimedia.modifyMultiMediaType": "دسته چند رسانه ای با موفقیت تغییر کرد!",
  "ccmessaging.chat.multimedia.modifyMultiMediaTypeError": "تغییر دسته کتابخانه چندرسانه ای انجام نشد!",
  "ccmessaging.chat.multimedia.modifyRichText": "ویرایش Rich Text",
  "ccmessaging.chat.multimedia.multiMediaInfoInputEmpty": "داده های ورودی چند رسانه ای خالی است!",
  "ccmessaging.chat.multimedia.multiMediaInfoInputInvalid": "داده های ورودی چند رسانه ای نامعتبر است!",
  "ccmessaging.chat.multimedia.multiMediaManagement": "تنظیمات کتابخانه چند رسانه ای",
  "ccmessaging.chat.multimedia.multiMediaNameExist": "نام داده های چند رسانه ای از قبل در این دسته وجود دارد!",
  "ccmessaging.chat.multimedia.multiMediaTypeIdNotExist": "دسته کتابخانه چند رسانه ای انتخاب شده وجود ندارد، لطفاً دسته کتابخانه چند رسانه ای را انتخاب کنید!",
  "ccmessaging.chat.multimedia.multiMediaTypeInputEmpty": "داده ورودی دسته چند رسانه ای خالی است!",
  "ccmessaging.chat.multimedia.multiMediaTypeInputInvalid": "داده های ورودی دسته چندرسانه ای نامعتبر است!",
  "ccmessaging.chat.multimedia.multiMediaTypeNameExist": "نام دسته چند رسانه ای از قبل وجود دارد!",
  "ccmessaging.chat.multimedia.name": "نام",
  "ccmessaging.chat.multimedia.nameContainsSpecialCharacters": "نام شامل کاراکترهای خاص است.",
  "ccmessaging.chat.multimedia.nameSuperLong": "طول نام بیش از حداکثر است.",
  "ccmessaging.chat.multimedia.operating": "عملیاتی",
  "ccmessaging.chat.multimedia.play": "بازی",
  "ccmessaging.chat.multimedia.preview": "پیش نمایش",
  "ccmessaging.chat.multimedia.richTextContentEmpty": "محتوای Rich Text خالی است.",
  "ccmessaging.chat.multimedia.richTextContentLongLimit": "محتوای Rich Text می تواند حداکثر 5000 کاراکتر داشته باشد.",
  "ccmessaging.chat.multimedia.richTextContentSuperLong": "Rich Text می تواند حداکثر 5000 کاراکتر داشته باشد.",
  "ccmessaging.chat.multimedia.richTextManagement": "Rich Text",
  "ccmessaging.chat.multimedia.richTextTypeEmpty": "دسته بندی Rich Text وجود ندارد.",
  "ccmessaging.chat.multimedia.categorizeEmpty": "ابتدا یک دسته را انتخاب کنید یا ایجاد کنید.",
  "ccmessaging.chat.multimedia.saveMultiMediaInfo": "داده های چند رسانه ای با موفقیت ذخیره شد!",
  "ccmessaging.chat.multimedia.saveMultiMediaInfoError": "داده های چند رسانه ای ذخیره نشد!",
  "ccmessaging.chat.multimedia.saveMultiMediaType": "دسته چند رسانه ای با موفقیت ذخیره شد!",
  "ccmessaging.chat.multimedia.saveMultiMediaTypeError": "دسته کتابخانه چندرسانه ای ذخیره نشد!",
  "ccmessaging.chat.multimedia.selectFile": "فایل را انتخاب کنید",
  "ccmessaging.chat.multimedia.selectImage": "عکس را انتخاب کنید",
  "ccmessaging.chat.multimedia.upload": "بارگذاری",
  "ccmessaging.chat.multimedia.uploadTime": "زمان آپلود",
  "ccmessaging.chat.multimedia.videoManagement": "کتابخانه ویدیویی",
  "ccmessaging.chat.multimedia.videoUploadTipFormat": "فایل ویدئویی آپلود شده در حال حاضر فقط از فرمت mp4 پشتیبانی می کند و اندازه فایل نباید از {n}M بیشتر باشد.",
  "ccmessaging.chat.multimedia.viewRichText": "مشاهده Rich Text",
  "ccmessaging.chat.multimedia.whatsAppTemplate": "الگو WhatsApp",
  "ccmessaging.chat.multimediaasccociation.approvalstatus": "وضعیت بررسی",
  "ccmessaging.chat.multimediaasccociation.checkmediainfofailed": "داده‌های چندرسانه‌ای برای کانال 5G مرتبط منتشر نشده یا تأیید نشده است!",
  "ccmessaging.chat.multimediaasccociation.deleteassociation": "حذف نتیجه انتشار",
  "ccmessaging.chat.multimediaasccociation.deleteassociationinfo": "آیا مطمئنید که می‌خواهید نتیجه انتشار داده‌های چندرسانه‌ای و کانال 5G مرتبط را حذف کنید؟",
  "ccmessaging.chat.multimediaasccociation.deleteError": "نتیجه انتشار حذف نشد!",
  "ccmessaging.chat.multimediaasccociation.deleteSuccess": "نتیجه انتشار با موفقیت حذف شد!",
  "ccmessaging.chat.multimediaasccociation.examinationfailed": "ناموفق",
  "ccmessaging.chat.multimediaasccociation.examinationpassed": "تایید شده",
  "ccmessaging.chat.multimediaasccociation.expirationtime": "منقضی شده در",
  "ccmessaging.chat.multimediaasccociation.isrelease": "آیا مطمئنید که می‌خواهید داده‌های چندرسانه‌ای را در کانال 5G منتشر کنید؟",
  "ccmessaging.chat.multimediaasccociation.refresh": "بازخوانی",
  "ccmessaging.chat.multimediaasccociation.refreshfailed": "بازخوانی داده ها انجام نشد!",
  "ccmessaging.chat.multimediaasccociation.refreshsuccess": "داده ها با موفقیت به روز شدند!",
  "ccmessaging.chat.multimediaasccociation.release": "آزاد کردن",
  "ccmessaging.chat.multimediaasccociation.releaseerror": "داده های چند رسانه ای منتشر نشد!",
  "ccmessaging.chat.multimediaasccociation.releaseresult": "نتیجه انتشار",
  "ccmessaging.chat.multimediaasccociation.releasesuccess": "داده های چند رسانه ای با موفقیت منتشر شد!",
  "ccmessaging.chat.multimediaasccociation.releasetitle": "انتشار داده های چند رسانه ای",
  "ccmessaging.chat.multimediaasccociation.unpublishconfigempty": "کانال 5G که قرار است داده های چندرسانه ای در آن منتشر شود پیکربندی نشده است.",
  "ccmessaging.chat.multimediaasccociation.unreviewed": "بررسی نشده است",
  "ccmessaging.chat.offlineCall.channelType": "نوع کانال",
  "ccmessaging.chat.offlineCall.select.noChannel": "کانال وجود ندارد",
  "ccmessaging.chat.offlineCall.userId": "شناسه کاربر",
  "ccmessaging.chat.offlineSwitch.closed": "ارسال انجام نشد زیرا عملکرد پیام آفلاین برای کانال غیرفعال است.",
  "ccmessaging.chat.phrase.add": "اضافه کردن",
  "ccmessaging.chat.phrase.addPhraseError": "ایجاد عبارت مشترک شکست خورد",
  "ccmessaging.chat.phrase.addPhraseSuccess": "ایجاد عبارت مشترک با موفقیت",
  "ccmessaging.chat.phrase.addType": "دسته بندی جدید",
  "ccmessaging.chat.phrase.AllCount": "کل کلمات",
  "ccmessaging.chat.phrase.cancel": "لغو کنید",
  "ccmessaging.chat.phrase.currentCount": "تعداد کلمات فعلی",
  "ccmessaging.chat.phrase.delete": "حذف",
  "ccmessaging.chat.phrase.deleteType": "حذف نوع ",
  "ccmessaging.chat.phrase.delPhraseError": "حذف عبارت مشترک شکست خورد",
  "ccmessaging.chat.phrase.delPhraseSucess": "حذف زبان رایج با موفقیت انجام شد",
  "ccmessaging.chat.phrase.delPhraseTypeError": "حذف دسته زبان رایج انجام نشد",
  "ccmessaging.chat.phrase.delPhraseTypeSuccess": "دسته زبان رایج با موفقیت حذف شد",
  "ccmessaging.chat.phrase.edit": "ویرایش کنید",
  "ccmessaging.chat.phrase.editType": "نوع ویرایش",
  "ccmessaging.chat.phrase.enter_keyword": "لطفا کلمه کلیدی را وارد کنید",
  "ccmessaging.chat.phrase.enterPhrase": "لطفا یک زبان رایج وارد کنید",
  "ccmessaging.chat.phrase.enterPhraseType": "لطفاً یک دسته زبان رایج وارد کنید",
  "ccmessaging.chat.phrase.error": "خطا",
  "ccmessaging.chat.phrase.isDeletePhrase": "آیا مطمئن هستید که می خواهید این زبان رایج را حذف کنید؟",
  "ccmessaging.chat.phrase.isDeletePhraseType": "آیا مطمئن هستید که می خواهید نوع عبارت رایج و عبارات رایج آن را حذف کنید؟",
  "ccmessaging.chat.phrase.isSavePhrase": "آیا مطمئن هستید که می خواهید عبارت رایج را ذخیره کنید؟",
  "ccmessaging.chat.phrase.isSavePhraseType": "آیا مطمئن هستید که می خواهید نوع عبارت رایج را ذخیره کنید؟",
  "ccmessaging.chat.phrase.phrase": "عبارت",
  "ccmessaging.chat.phrase.phraseList": "فهرست عبارت",
  "ccmessaging.chat.phrase.phraseRepeat": "نام رایج تکراری",
  "ccmessaging.chat.phrase.phraseType": "نوع عبارت",
  "ccmessaging.chat.phrase.phraseTypeEmpty": "دسته زبان رایج حذف شده وجود ندارد. لطفاً دسته زبان مشترک را انتخاب کنید.",
  "ccmessaging.chat.phrase.phraseTypeRepeat": "نام دسته زبان رایج تکراری",
  "ccmessaging.chat.phrase.prompt": "سریع",
  "ccmessaging.chat.phrase.save": "صرفه جویی",
  "ccmessaging.chat.phrase.saveError": "ذخیره انجام نشد",
  "ccmessaging.chat.phrase.saveType": "نوع را ذخیره کنید",
  "ccmessaging.chat.phrase.saveTypeSuccess": "دسته بندی زبان رایج با موفقیت ذخیره شد",
  "ccmessaging.chat.phrase.shortcut": "میانبر",
  "ccmessaging.chat.phrase.success": "موفقیت",
  "ccmessaging.chat.phrase.updateError": "خطای به روز رسانی",
  "ccmessaging.chat.phrase.updatePhraseError": "به‌روزرسانی زبان رایج انجام نشد",
  "ccmessaging.chat.phrase.updatePhraseSucess": "زبان رایج را با موفقیت به روز کنید",
  "ccmessaging.chat.phrase.updateSuccess": "به روز رسانی با موفقیت",
  "ccmessaging.chat.portraitchange.customize": "سفارشی",
  "ccmessaging.chat.portraitchange.systemAvatar": "آواتار سیستم",
  "ccmessaging.chat.querymessage": "سابقه پیام رسانه را جستجو کنید",
  "ccmessaging.chat.refresh.fail": "بازخوانی انجام نشد",
  "ccmessaging.chat.repeatClick": "مکرر کلیک نکنید!",
  "ccmessaging.chat.richText.add": "ايجاد كردن",
  "ccmessaging.chat.richText.cancel": "لغو کنید",
  "ccmessaging.chat.richText.idInput": "یک شناسه وارد کنید.",
  "ccmessaging.chat.richText.nameInput": "یک نام وارد کنید.",
  "ccmessaging.chat.richText.openInNewWindow": "یک پنجره جدید باز کنید",
  "ccmessaging.chat.richText.save": "ذخیره ",
  "ccmessaging.chat.selectchannel.vaildate1": "کد دسترسی به کانال باید منحصر به فرد باشد.",
  "ccmessaging.chat.sessiontransfer.tips": "فقط نمایندگان بیکار و نمایندگانی که تماس های همزمان آنها به حد بالایی نمی رسد می توانند منتقل شوند.",
  "ccmessaging.chat.silentagent.aceesscode.empty": "کد دسترسی نمی تواند null باشد!",
  "ccmessaging.chat.silentagent.config.accesscode.same": "پیکربندی Silent Agent کد دسترسی صوتی از قبل در اجاره فعلی وجود دارد.",
  "ccmessaging.chat.silentagent.config.del.error": "پیکربندی Silent Agent حذف نشد.",
  "ccmessaging.chat.silentagent.config.del.success": "حذف پیکربندی Silent Agent با موفقیت انجام شد.",
  "ccmessaging.chat.silentagent.config.willdel": "آیا مطمئن هستید که می خواهید پیکربندی Silent Agent انتخابی را حذف کنید؟",
  "ccmessaging.chat.silentagent.DeleteFail": "پیکربندی Silent Agent حذف نشد.",
  "ccmessaging.chat.silentagent.DeleteSuccess": "حذف پیکربندی Silent Agent با موفقیت انجام شد.",
  "ccmessaging.chat.silentagent.sendContent.noOIAPNode": "Silent Agent نمی تواند اطلاعات گره فعلی را دریافت کند!",
  "ccmessaging.chat.silentagent.sendContent.supervise": "Silent Agent نمی تواند پیامی را در حالت کار نظارتی ارسال کند!",
  "ccmessaging.chat.silentagent.silent.skill.empty": "صف مهارت Silent Agent نمی تواند خالی باشد",
  "ccmessaging.chat.silentAgent.workMode.changeWorkMode.fail": "تغییر workMode Silent Agent با شکست مواجه شد!",
  "ccmessaging.chat.silentAgent.workMode.changeWorkMode.prompt": "نکته",
  "ccmessaging.chat.silentAgent.workMode.changeWorkMode.success": "موفقیت silentAgent در تغییر workMode!",
  "ccmessaging.chat.silentAgent.workMode.common": "رایج",
  "ccmessaging.chat.silentAgent.workMode.insert": "درج کنید",
  "ccmessaging.chat.silentAgent.workMode.supervise": "نظارت",
  "ccmessaging.chat.silentAgent.workMode.transfer": "انتقال",
  "ccmessaging.chat.single.media.resource.invalid": "کارت‌هایی که به منابع رسانه نامعتبر ارجاع می‌دهند.",
  "ccmessaging.chat.sms.channel.gateway": "دروازه پیامک",
  "ccmessaging.chat.social.media.enterprise.default.username": "کاربر ناشناس",
  "ccmessaging.chat.social.media.enterprise.manage": "پردازش پیام حساب سازمانی",
  "ccmessaging.chat.social.media.enterprise.post.add.failed": "افزودن ناموفق بود.",
  "ccmessaging.chat.social.media.enterprise.post.add.success": "افزودن با موفقیت انجام شد.",
  "ccmessaging.chat.social.media.enterprise.post.all.reply": "همه پاسخ دهید",
  "ccmessaging.chat.social.media.enterprise.post.batch.reply": "پاسخ دسته ای",
  "ccmessaging.chat.social.media.enterprise.post.cancel": "لغو کنید",
  "ccmessaging.chat.social.media.enterprise.post.comment": "نظر دادن در مورد",
  "ccmessaging.chat.social.media.enterprise.post.comment.tips": "افزودن نظر...",
  "ccmessaging.chat.social.media.enterprise.post.reply": "پاسخ",
  "ccmessaging.chat.social.media.enterprise.post.reply.cancel": "لغو کنید",
  "ccmessaging.chat.social.media.enterprise.post.reply.tips": "پاسخ را اضافه کن...",
  "ccmessaging.chat.social.media.enterprise.post.title": "نام پست",
  "ccmessaging.chat.social.media.enterprise.post.topcomment.cancel": "لغو نظر",
  "ccmessaging.chat.social.media.enterprise.tips.close": "تا کردن",
  "ccmessaging.chat.social.media.enterprise.tips.imgnum": "حداکثر یک تصویر می تواند اضافه شود.",
  "ccmessaging.chat.social.media.enterprise.tips.open": "باز کردن",
  "ccmessaging.chat.social.media.multimedia.manage": "پردازش پیام های چند رسانه ای",
  "ccmessaging.chat.speciallist.channels": "کانال های محدود",
  "ccmessaging.chat.speciallist.channelsType": "نوع کانال محدود",
  "ccmessaging.chat.speciallist.choosechannel": "کانال را انتخاب کنید",
  "ccmessaging.chat.speciallist.confirmdelete": "مطمئنی حذف میکنی؟",
  "ccmessaging.chat.speciallist.delete": "رها شده است",
  "ccmessaging.chat.speciallist.deletelevel": "(رها)",
  "ccmessaging.chat.speciallist.emailchannels": "ایمیل",
  "ccmessaging.chat.speciallist.haverestrictall": "همه کانال های این نوع کانال محدود هستند. نیازی به محدود کردن این کانال نیست.",
  "ccmessaging.chat.speciallist.haverestrictchannel": "این کانال محدود است، نیازی به محدود کردن مجدد آن نیست.",
  "ccmessaging.chat.speciallist.level": "سطح لیست ویژه",
  "ccmessaging.chat.speciallist.leveName": "نام نوع لیست ویژه",
  "ccmessaging.chat.speciallist.msgchoosechannel": "لطفا ابتدا یک نوع کانال را انتخاب کنید.",
  "ccmessaging.chat.speciallist.remark": "شرح",
  "ccmessaging.chat.speciallist.repeatlevel": "سطح فهرست ویژه از قبل وجود دارد. دیگر آن را ایجاد نکنید.",
  "ccmessaging.chat.speciallist.restrictHours": "مدت زمان محدود (ساعت)",
  "ccmessaging.chat.speciallist.restrictMode": "حالت محدودیت",
  "ccmessaging.chat.speciallist.restrictStyle": "حالت برنامه",
  "ccmessaging.chat.speciallist.selectchannel": "کانال تعیین شده",
  "ccmessaging.chat.speciallist.smschannels": "پیامک",
  "ccmessaging.chat.speciallist.unuse": "در دسترس نیست",
  "ccmessaging.chat.speciallist.unuselevel": "(در دسترس نیست)",
  "ccmessaging.chat.speciallist.use": "در دسترس",
  "ccmessaging.chat.successaccess": "دسترسی موفق",
  "ccmessaging.chat.sumbitverification.Appld": "شناسه برنامه",
  "ccmessaging.chat.sumbitverification.AppSecret": "کلید",
  "ccmessaging.chat.sumbitverification.facebook.tips": "برای پیکربندی وب‌ سایت و کد تأیید، به صفحه توسعه‌دهنده Facebook Messenger بروید.",
  "ccmessaging.chat.sumbitverification.fiveG.tips": "لطفاً برای پیکربندی آدرس وب ‌سایت به صفحه پیکربندی بستر پیام 5G بروید.",
  "ccmessaging.chat.sumbitverification.line.tips": "URL Webhook را در مدیریت حساب رسمی خط پیکربندی کنید.",
  "ccmessaging.chat.sumbitverification.tips": "لطفاً برای پیکربندی URL و کد تأیید، به اطلاعات پیکربندی رابط پلتفرم عمومی WeChat بروید",
  "ccmessaging.chat.sumbitverification.tips.qr": "در این حالت مجوز، به پیکربندی بیشتری نیاز نیست. به مرحله بعد برو.",
  "ccmessaging.chat.sumbitverification.Url": "URL",
  "ccmessaging.chat.sumbitverification.Url.infobipMessAgeUrl": "ur اینترنتی برای ارسال پیام به نماینده استفاده می شود",
  "ccmessaging.chat.sumbitverification.Url.infobipReportUrl": "url برای ارسال گزارش به نماینده استفاده می شود",
  "ccmessaging.chat.sumbitverification.VerityCode": "کد تایید",
  "ccmessaging.chat.sumbitverification.whatsApp.fail": "ایجاد شکست",
  "ccmessaging.chat.sumbitverification.whatsApp.prompt": "نکته",
  "ccmessaging.chat.sumbitverification.whatsApp.success": "تولید موفقیت",
  "ccmessaging.chat.sumbitverification.whatsApp.tips": "URL که توسط ارائه دهنده خدمات WhatsApp برای پردازش پیام ها استفاده می شود",
  "ccmessaging.chat.system.error": "سیستم مشغول است. لطفاً بعداً دوباره امتحان کنید.",
  "ccmessaging.chat.template.buttonSend": "ارسال",
  "ccmessaging.chat.template.butttonName": "انتخاب کنید",
  "ccmessaging.chat.template.card": "پیام الگو WhatsApp",
  "ccmessaging.chat.template.Description": "شرح",
  "ccmessaging.chat.template.languageCode": "زبان",
  "ccmessaging.chat.template.name": "نام پیام الگو",
  "ccmessaging.chat.template.namePlaceholder": "نام پیام را وارد کنید",
  "ccmessaging.chat.template.table.button": "دکمه",
  "ccmessaging.chat.template.table.buttonPlaceholder": "پاسخ سریع دکمه",
  "ccmessaging.chat.template.table.no": "خیر",
  "ccmessaging.chat.template.table.parameterContent": "محتوای پارامتر",
  "ccmessaging.chat.template.table.parameterDescription": "توضیحات پارامتر",
  "ccmessaging.chat.template.table.parameterPlaceholder": "یک پارامتر وارد کنید.",
  "ccmessaging.chat.template.whatsAppChannel": "کانال WhatsApp",
  "ccmessaging.chat.template.whatsAppTemplateName": "نام الگو WhatsApp",
  "ccmessaging.chat.templateMsg.chooseMultimedia": "یک منبع چند رسانه ای را انتخاب کنید.",
  "ccmessaging.chat.templateMsg.sendTemplateMsg": "ارسال",
  "ccmessaging.chat.templateMsg.sendTemplateMsgTips": "ارسال پیام الگو",
  "ccmessaging.chat.tinymce.button.clear": "پاک کن",
  "ccmessaging.chat.tinyMCE.FangSong": "FangSong",
  "ccmessaging.chat.tinyMCE.SimHei": "SimHei",
  "ccmessaging.chat.tinyMCE.simsun": " simsun",
  "ccmessaging.chat.tinyMCE.yaHei": " Microsoft YaHei",
  "ccmessaging.chat.uploadLocalFile": "بارگیری فایل محلی",
  "ccmessaging.chat.warning.sharescreen": "هنگام اشتراک گذاری دسکتاپ به حریم خصوصی شخصی توجه کنید",
  "ccmessaging.chat.web.agent.assist": "دستیار نماینده",
  "ccmessaging.chat.web.agent.prohibitedmess": "زبان ممنوع! لطفا به کلمات دقت کنید.",
  "ccmessaging.chat.web.agent.wrogmess": "بیان نامناسب! لطفا در استفاده از کلمات مناسب دقت کنید.",
  "ccmessaging.chat.web.channel.called.config": "CTD Called Party Configuration",
  "ccmessaging.chat.web.channel.clickivr": "برای دریافت کد دسترسی تلفن گویا کلیک کنید.",
  "ccmessaging.chat.webCard": "WebCard",
  "ccmessaging.chat.webCard.copyUrl": "لینک را کپی کنید",
  "ccmessaging.chat.webCard.copyUrl.success": "کپی لینک موفقیت آمیز",
  "ccmessaging.chat.webcard.pop.confirm.outside": "آیا باید به پیوندهای شخص ثالث پرش کرد؟",
  "ccmessaging.chat.webcard.pop.confirm.prefix": "پیام به آدرس خارجی، دامنه وب سایت هدایت می شود",
  "ccmessaging.chat.webcard.pop.confirm.suffix": "آیا مطمئن هستید که میخواهید ادامه دهید؟",
  "ccmessaging.chat.webcard.pop.error.prefix": "دامنه وب سایت",
  "ccmessaging.chat.webcard.pop.error.suffix": "در whitelist آدرس نیست. با پرسنل عملیات تماس بگیرید تا آن را به whitelist اضافه کنید.",
  "ccmessaging.chat.webclient.chatapi.evaluation": "لطفا به این سرویس امتیاز دهید، متشکرم!",
  "ccmessaging.chat.webclient.chatapi.failTransToAgent": "تبدیل به نماینده انجام نشد، لطفاً دوباره امتحان کنید یا با ادمین تماس بگیرید",
  "ccmessaging.chat.webclient.chathtml.customer": "مشتری",
  "ccmessaging.chat.webclient.chatjs.inpuStatus": "طرف مقابل در حال تایپ کردن است.",
  "ccmessaging.chat.whatsapp.body": "بدنه",
  "ccmessaging.chat.whatsapp.buttons": "دکمه ها",
  "ccmessaging.chat.whatsapp.footer": "پاورقی",
  "ccmessaging.chat.whatsapp.header": "سرصفحه",
  "ccmessaging.chat.whatsapp.languageAF": "هلندی، آفریقای جنوبی",
  "ccmessaging.chat.whatsapp.languageAR": "عربی",
  "ccmessaging.chat.whatsapp.languageAZ": "آذربایجانی",
  "ccmessaging.chat.whatsapp.languageBG": "بلغاری",
  "ccmessaging.chat.whatsapp.languageBN": "بنگالی",
  "ccmessaging.chat.whatsapp.languageCA": "کاتالان",
  "ccmessaging.chat.whatsapp.languageCS": "کشور چک",
  "ccmessaging.chat.whatsapp.languageDA": "دانمارکی",
  "ccmessaging.chat.whatsapp.languageDE": "آلمانی",
  "ccmessaging.chat.whatsapp.languageEL": "یونانی",
  "ccmessaging.chat.whatsapp.languageEN": "اسپانیایی",
  "ccmessaging.chat.whatsapp.languageENGB": "انگلیسی (بریتانیا)",
  "ccmessaging.chat.whatsapp.languageES": "انگلیسی",
  "ccmessaging.chat.whatsapp.languageESAR": "اسپانیایی (آرژانتین)",
  "ccmessaging.chat.whatsapp.languageESES": "اسپانیایی (اسپانیا)",
  "ccmessaging.chat.whatsapp.languageESMX": "اسپانیایی (مکزیک)",
  "ccmessaging.chat.whatsapp.languageET": "استونیایی",
  "ccmessaging.chat.whatsapp.languageFA": "فارسی",
  "ccmessaging.chat.whatsapp.languageFI": "فنلاندی",
  "ccmessaging.chat.whatsapp.languageFIL": "فیلیپین",
  "ccmessaging.chat.whatsapp.languageFR": "فرانسوی",
  "ccmessaging.chat.whatsapp.languageGA": "ایرلندی",
  "ccmessaging.chat.whatsapp.languageGU": "گجراتی",
  "ccmessaging.chat.whatsapp.languageHA": "هاوسا",
  "ccmessaging.chat.whatsapp.languageHE": "عبری",
  "ccmessaging.chat.whatsapp.languageHI": "هندی",
  "ccmessaging.chat.whatsapp.languageHR": "کروات",
  "ccmessaging.chat.whatsapp.languageHU": "مجارستانی",
  "ccmessaging.chat.whatsapp.languageID": "اندونزیایی",
  "ccmessaging.chat.whatsapp.languageIT": "ایتالیایی",
  "ccmessaging.chat.whatsapp.languageJA": "ژاپنی",
  "ccmessaging.chat.whatsapp.languageKK": "قزاقی",
  "ccmessaging.chat.whatsapp.languageKN": "کاناداون",
  "ccmessaging.chat.whatsapp.languageKO": "کره ای",
  "ccmessaging.chat.whatsapp.languageLO": "لائوس",
  "ccmessaging.chat.whatsapp.languageLT": "لیتوانیایی",
  "ccmessaging.chat.whatsapp.languageLV": "لتونی",
  "ccmessaging.chat.whatsapp.languageMK": "مقدونیه شمالی",
  "ccmessaging.chat.whatsapp.languageML": "مالایالام",
  "ccmessaging.chat.whatsapp.languageMR": "مراتی",
  "ccmessaging.chat.whatsapp.languageMS": "مالایی",
  "ccmessaging.chat.whatsapp.languageNB": "نروژی",
  "ccmessaging.chat.whatsapp.languageNL": "هلندی",
  "ccmessaging.chat.whatsapp.languagePA": "پنجابی",
  "ccmessaging.chat.whatsapp.languagePL": "لهستانی",
  "ccmessaging.chat.whatsapp.languagePTBR": "پرتغالی (برزیل)",
  "ccmessaging.chat.whatsapp.languagePTPT": "پرتغالی (پرتغال)",
  "ccmessaging.chat.whatsapp.languageRO": "رومانیایی",
  "ccmessaging.chat.whatsapp.languageRU": "روسی",
  "ccmessaging.chat.whatsapp.languageSK": "اسلواکی",
  "ccmessaging.chat.whatsapp.languageSL": "اسلوونیایی",
  "ccmessaging.chat.whatsapp.languageSQ": "آلبانیایی",
  "ccmessaging.chat.whatsapp.languageSR": "صربی",
  "ccmessaging.chat.whatsapp.languageSV": "سوئدی",
  "ccmessaging.chat.whatsapp.languageSW": "سواحیلی",
  "ccmessaging.chat.whatsapp.languageTA": "تامیل",
  "ccmessaging.chat.whatsapp.languageTE": "تلوگو",
  "ccmessaging.chat.whatsapp.languageTH": "تایلندی",
  "ccmessaging.chat.whatsapp.languageTR": "ترکی",
  "ccmessaging.chat.whatsapp.languageUK": "اوکراینی",
  "ccmessaging.chat.whatsapp.languageUR": "اردو",
  "ccmessaging.chat.whatsapp.languageUS": "ایالات متحده انگلیسی)",
  "ccmessaging.chat.whatsapp.languageUZ": "ازبکی",
  "ccmessaging.chat.whatsapp.languageVI": "ویتنامی",
  "ccmessaging.chat.whatsapp.languageZHCN": "چینی ساده شده",
  "ccmessaging.chat.whatsapp.languageZHHK": "چینی سنتی (هنگ کنگ، چین)",
  "ccmessaging.chat.whatsapp.languageZHTW": "چینی سنتی (تایوان، چین)",
  "ccmessaging.chat.whatsapp.languageZU": "زولو",
  "ccmessaging.chat.whatsapp.only.send.template": "هنگامی که یک کاربر جدید WhatsApp یا فاصله تماس از 24 ساعت بیشتر شود، فقط پیام های استاندارد می توانند ارسال شوند.",
  "ccmessaging.chat.whatsapp.selectTemplateOrgPlaceHolder": "سازمان را انتخاب کنید",
  "ccmessaging.chat.whatsapp.template.dailyLimit": "زمان در دسترس بودن الگو در روز",
  "ccmessaging.chat.whatsapp.template.dailyLimitDescription": "لطفاً تعداد دفعاتی که الگو در روز در دسترس است را وارد کنید.",
  "ccmessaging.chat.whatsapp.template.no": "خیر",
  "ccmessaging.chat.whatsapp.template.updateTime": "به روز شد",
  "ccmessaging.chat.whatsapp.templateAddButton": "ايجاد كردن",
  "ccmessaging.chat.whatsapp.templateBodyParamDesc": "توضیحات پارامتر",
  "ccmessaging.chat.whatsapp.templateBodyParamDescPlaceholder": "توضیحات پارامتر را وارد کنید.",
  "ccmessaging.chat.whatsapp.templateBodyText": "محتوای متن بدنه",
  "ccmessaging.chat.whatsapp.templateBodyTextPlaceholder": "محتوای بدنه متن را وارد کنید، محتوای موجود در {'{{}}'} در الگو متن قابل ویرایش است.",
  "ccmessaging.chat.whatsapp.templateButton.clicktoAction": "عمل",
  "ccmessaging.chat.whatsapp.templateButton.none": "N/A",
  "ccmessaging.chat.whatsapp.templateButton.qickReply": "پاسخ سریع",
  "ccmessaging.chat.whatsapp.templateButtonContent": "الگو محتوای دکمه ",
  "ccmessaging.chat.whatsapp.templateButtonContentPlaceholder": "محتوای دکمه را وارد کنید.",
  "ccmessaging.chat.whatsapp.templateButtonName": "الگو نام دکمه ",
  "ccmessaging.chat.whatsapp.templateButtonNamePlaceholder": "نام دکمه را وارد کنید.",
  "ccmessaging.chat.whatsapp.templateButtonType": "الگو نوع دکمه ",
  "ccmessaging.chat.whatsapp.templateChannel": "الگو کانال ",
  "ccmessaging.chat.whatsapp.templateContent": "محتوای الگو",
  "ccmessaging.chat.whatsapp.templateCreate": "ایجاد الگو WhatsApp",
  "ccmessaging.chat.whatsapp.templateDescription": "توضیحات الگو",
  "ccmessaging.chat.whatsapp.templateDescriptionPlaceholder": "توضیحات الگو را وارد کنید.",
  "ccmessaging.chat.whatsapp.templateFooterText": "محتوای متنی پاورقی",
  "ccmessaging.chat.whatsapp.templateFooterTextPlaceholder": "محتوای متن پاورقی را وارد کنید.",
  "ccmessaging.chat.whatsapp.templateHeaderText": "محتوای متن سرصفحه",
  "ccmessaging.chat.whatsapp.templateHeaderTextPlaceholder": "محتوای متن سرصفحه را وارد کنید.",
  "ccmessaging.chat.whatsapp.templateHeaderType": "سرصفحه ",
  "ccmessaging.chat.whatsapp.templateHeaderType.image": "تصویر",
  "ccmessaging.chat.whatsapp.templateHeaderType.locate": "محل",
  "ccmessaging.chat.whatsapp.templateHeaderType.none": "N/A",
  "ccmessaging.chat.whatsapp.templateHeaderType.text": "متن",
  "ccmessaging.chat.whatsapp.templateHeaderType.video": "ویدیو",
  "ccmessaging.chat.whatsapp.templateLanguage": "زبان",
  "ccmessaging.chat.whatsapp.templateName": "نام الگو",
  "ccmessaging.chat.whatsapp.templateNamePlaceholder": "نام الگو را وارد کنید",
  "ccmessaging.chat.whatsAppTemplateMsg.selectWhatsAppTemplateMsg": "پیام مربوط به الگوی WhatsApp را انتخاب کنید.",
  "ccmessaging.chat.whatsAppTemplateMsg.selectWhatsAppTemplateMsgId": "شناسه پیام الگو WhatsApp",
  "ccmessaging.chat.whatsAppTemplateMsg.selectWhatsAppTemplateMsgName": "نام پیام الگو WhatsApp",
  "ccmessaging.chat.workbench.body.iolp": "راهنمای خدمات",
  "ccmessaging.chat.workbench.body.transfer": "انتقال تماس",
  "ccmessaging.chat.workbench.transfer.agent": "نماینده",
  "ccmessaging.chat.workbench.transfer.noagents": "هیچ نماینده آنلاینی در صف مهارت فعلی وجود ندارد.",
  "ccmessaging.chat.workbench.transfer.skill": "صف مهارت",
  "ccmessaging.chat.workbench.transfer.skilllogin": "نماینده موجود",
  "ccmessaging.chat.workbench.transfer.skillname": "نام صف مهارت",
  "ccmessaging.chat.workbench.transfer.skillqueue": "در صف منتظر بمانید",
  "ccmessaging.chat.workbench.transfer.skillsearch": "لطفاً یک نام صف مهارت وارد کنید",
  "ccmessaging.chatbot.button.avatarImport": "بارگذاری",
  "ccmessaging.chatbot.button.avatarImportLimit": "حجم فایلی که باید آپلود شود نمی تواند بیش از 6 مگابایت باشد و فایل می تواند در فرمت JPG یا PNG باشد.",
  "ccmessaging.chatbot.button.cancel": "لغو",
  "ccmessaging.chatbot.button.edit": "ویرایش کنید",
  "ccmessaging.chatbot.label.avatar": "آواتار",
  "ccmessaging.chatbot.label.avatarImport": "آپلود آواتار",
  "ccmessaging.chatbot.label.avatarImportFailed": "آپلود تصویر انجام نشد!",
  "ccmessaging.chatbot.label.avatarImportSuccess": "تصویر با موفقیت آپلود شد!",
  "ccmessaging.chatbot.label.avatarModifing": "تغییر آواتار",
  "ccmessaging.chatbot.label.basicInformation": "اطلاعات اولیه",
  "ccmessaging.chatbot.label.commonLanguageSetting": "پیکربندی عبارت رایج",
  "ccmessaging.chatbot.label.defaultReply": "پاسخ پیش فرض",
  "ccmessaging.chatbot.label.gender": "جنسیت",
  "ccmessaging.chatbot.label.name": "نام مستعار",
  "ccmessaging.chatbot.label.save": "ذخیره",
  "ccmessaging.chatbot.label.timeoutReply": "زمان پاسخ",
  "ccmessaging.chatbot.label.turnManualPrompt": "درخواست انتقال به نماینده",
  "ccmessaging.chatbot.label.welcomeMessage": "احوال پرسی",
  "ccmessaging.chatbot.message.error": "شکست",
  "ccmessaging.chatbot.message.modifyFailure": "اصلاح نشد.",
  "ccmessaging.chatbot.message.modifySuccess": "با موفقیت اصلاح شد.",
  "ccmessaging.chatbot.message.specialStrValidateFailed": "محتوای وارد شده نمی تواند شامل کاراکترهای زیر باشد: & < > /.",
  "ccmessaging.chatbot.message.success": "موفقیت",
  "ccmessaging.chatofflineCall.channelName": "نام کانال",
  "ccmessaging.chatofflineCall.invalidUserIdTip": "شناسه مشترک نامعتبر است.",
  "ccmessaging.chatofflineCall.noSelectChanelTip": "هیچ کانالی انتخاب نشده است.",
  "ccmessaging.chatofflineCall.noSupportText": "این تماس یک تماس غریبه است!",
  "ccmessaging.chatofflineCall.strangeOfflineTip": "هیچ جلسه تاریخی یافت نشد.",
  "ccmessaging.cobrowsesite.protocol.check": "پروتکل های ناامن به کار می روند ، که خطرات امنیتی را شکل می دهد.",
  "ccmessaging.email.attachment.fileType.tip1": "پیوست های ایمیل از فرمت های فایل زیر پشتیبانی می کنند:",
  "ccmessaging.email.attachment.fileType.tip2": "،",
  "ccmessaging.email.attachment.fileType.tip3": "اندازه فایل نمی تواند بیشتر شود",
  "ccmessaging.email.attachment.fileType.tip4": "M.",
  "ccmessaging.emailcatalog.add": "دایرکتوری ایجاد کنید",
  "ccmessaging.emailcatalog.canNotAdd": "حداکثر دو سطح دایرکتوری مجاز است.",
  "ccmessaging.emailcatalog.deleteTopCatalog": "دایرکتوری پیش فرض را نمی توان حذف کرد.",
  "ccmessaging.emailcatalog.duplicateName": "نام از قبل وجود دارد. از نام دیگری استفاده کنید.",
  "ccmessaging.emailcatalog.existEmail": "ایمیل ها در دایرکتوری وجود دارند.",
  "ccmessaging.emailcatalog.existSubCatalog": "دایرکتوری شامل زیر شاخه ها می باشد.",
  "ccmessaging.emailcatalog.modify": "دایرکتوری را تغییر دهید",
  "ccmessaging.emailcatalog.modifyTopCatalog": "دایرکتوری پیش فرض را نمی توان تغییر داد.",
  "ccmessaging.emailcatalog.moveCatalogFailed": "انتقال دایرکتوری انجام نشد.",
  "ccmessaging.emailcatalog.moveCatalogPartialSuccess": "انتقال برخی از فهرست های ایمیل انجام نشد.",
  "ccmessaging.emailcatalog.moveCatalogSuccess": "دایرکتوری با موفقیت منتقل شد.",
  "ccmessaging.emailcatalog.moveEmailQuantityExceedsMaxValue": "تعداد ایمیل های منتقل شده از 30 یا طول پارامتر از حداکثر بیشتر است.",
  "ccmessaging.emailcatalog.moveOtherCatalogFailed": "ایمیل سایر نمایندگان قابل جابجایی نیست.",
  "ccmessaging.emailcatalog.parentName": "نام دایرکتوری والد",
  "ccmessaging.emailcatalog.pleaseSelectCatalog": "ابتدا یک دایرکتوری انتخاب کنید.",
  "ccmessaging.emailcatalog.selectcatalog": "دایرکتوری را انتخاب کنید",
  "ccmessaging.emailMessageManage.forward": "ثبت ارسال",
  "ccmessaging.flowControl.title.modify": "ویرایش کنترل جریان پیام",
  "ccmessaging.flowControl.addFail": "پیکربندی کنترل جریان اضافه نشد.",
  "ccmessaging.flowControl.addSuccess": "پیکربندی کنترل جریان با موفقیت اضافه شد.",
  "ccmessaging.flowControl.alarmInterval": "فاصله هشدار (دقیقه)",
  "ccmessaging.flowControl.alarmThreshold": "آستانه هشدار (%)",
  "ccmessaging.flowControl.channelType": "نوع کانال",
  "ccmessaging.flowControl.count.beyondMaxLimit": "تعداد قوانین کنترل جریان در فضای مستاجر از حد بالایی فراتر می رود.",
  "ccmessaging.flowControl.createTime": "ایجاد شد",
  "ccmessaging.flowControl.deleteFail": "پیکربندی کنترل جریان حذف نشد.",
  "ccmessaging.flowControl.deleteSelect": "آیا مطمئن هستید که می خواهید قانون انتخاب شده را حذف کنید؟",
  "ccmessaging.flowControl.deleteSuccess": "پیکربندی کنترل جریان با موفقیت حذف شد.",
  "ccmessaging.flowControl.description": "شرح",
  "ccmessaging.flowControl.enable": "فعال کردن",
  "ccmessaging.flowControl.limitNum": "حداکثر پیام ها",
  "ccmessaging.flowControl.name": "نام",
  "ccmessaging.flowControl.operate": "عملیات",
  "ccmessaging.flowControl.periodType": "نوع دوره",
  "ccmessaging.flowControl.periodType.day": "روزانه",
  "ccmessaging.flowControl.periodType.month": "ماهانه",
  "ccmessaging.flowControl.periodType.time": "محدوده زمانی",
  "ccmessaging.flowControl.periodType.week": "هفتگی",
  "ccmessaging.flowControl.periodValue": "روز",
  "ccmessaging.flowControl.selectStop": "ابتدا پیکربندی کنترل جریان را غیرفعال کنید.",
  "ccmessaging.flowControl.selectRule": "پیکربندی کنترل جریان را انتخاب کنید.",
  "ccmessaging.flowControl.selectStartTime": "زمان شروع را انتخاب کنید.",
  "ccmessaging.flowcontrol.target.deleted": "شیء کنترل جریان حذف شده است. قانون کنترل جریان مربوطه را حذف کنید.",
  "ccmessaging.flowControl.targetId": "FC Object",
  "ccmessaging.flowControl.tipText": "پیام فوری",
  "ccmessaging.flowControl.tipTextInterval": "فاصله زمانی سریع (دقیقه)",
  "ccmessaging.flowControl.updateFail": "پیکربندی کنترل جریان به روز نشد.",
  "ccmessaging.flowControl.updateSuccess": "پیکربندی کنترل جریان با موفقیت به روز شد.",
  "ccmessaging.flowControl.usage": "استفاده",
  "ccmessaging.webCollaboration.ruleName": "نام قاعده",
  "ccmessaging.webCollaboration.pageMatchType": "نوع تطبیق صفحه",
  "ccmessaging.webCollaboration.pageMatchConfiguration": "پیکربندی تطبیق صفحه",
  "ccmessaging.webCollaboration.pageElementSelector": "گزینندۀ عنصر صفحه",
  "ccmessaging.webCollaboration.createTime": "زمان ایجاد",
  "ccmessaging.webCollaboration.operation": "عملیات",
  "cobrowse.sensitiveinfo.pagematchtype.commonmatch": "تطابق عمومی",
  "cobrowse.sensitiveinfo.pagematchtype.urlmatch": "تطبیق بر اساس مسیر نشانی وب",
  "cobrowse.sensitiveinfo.pagematchtype.pageidmatch": "تطابق بر اساس شناسه صفحه",
  "ccmessaging.message.location.latitude": "عرض جغرافیایی:",
  "ccmessaging.message.location.longitude": "طول جغرافیایی:",
  "ccmessaging.message.selectEmail.count": "حداکثر 30 ایمیل را می توان در یک زمان انتخاب کرد.",
  "ccmessaging.message.selectEmail.null": "یک ایمیل را انتخاب کنید.",
  "ccmessaging.message.sendstatus.deliverable": "ارسال شد",
  "ccmessaging.message.sendstatus.pending": "در انتظار",
  "ccmessaging.message.sendstatus.read": "خوانده شده",
  "ccmessaging.message.sendstatus.undeliverable": "ناموفق",
  "ccmessaging.message.sendstatus.unread": "خوانده نشده",
  "ccmessaging.message.timeerror.laterThanCurTime": "زمان شروع نمی تواند دیرتر از زمان فعلی باشد.",
  "ccmessaging.messagecontroll.timeerror.beyondMaxLimit": "زمان پایان نمی تواند دیرتر از زمان شروع 90 روز باشد!",
  "ccmessaging.multimedialibrary.cobrowsesite.card.target": "مرور هدف:",
  "ccmessaging.multimedialibrary.cobrowsesite.card.title": "نماینده درخواست مرور مشترک با کاربر می کند.",
  "ccmessaging.multimedialibrary.tips": "این آدرس توسط نماینده برای ارسال به کاربر برای همکاری در صفحه وب استفاده می شود. آن را به درستی پیکربندی کنید.",
  "ccmessaging.operlogs.emailAttachment.download": "پیوست های ایمیل را دانلود کنید",
  "ccmessaging.operlogs.multimedialibrary.create.richtext": "اضافه کردن Rich Text",
  "ccmessaging.operlogs.multimedialibrary.modify.richtext": "Rich Text را اصلاح کنید",
  "ccmessaging.operlogs.multimedialibrary.save": "ذخیره پیکربندی داده های کتابخانه چندرسانه ای",
  "ccmessaging.operlogs.multimedialibrary.save.audio": "پیکربندی داده ها را در کتابخانه چندرسانه ای ذخیره کنید و داده های فایل از نوع صوتی را بارگذاری کنید.",
  "ccmessaging.operlogs.multimedialibrary.save.emoticons": "پیکربندی داده‌ها را در کتابخانه چندرسانه‌ای ذخیره کنید و داده‌های فایل از نوع شکلک را آپلود کنید.",
  "ccmessaging.operlogs.multimedialibrary.save.image": "پیکربندی داده ها را در کتابخانه چندرسانه ای ذخیره کنید و داده های فایل از نوع تصویر را آپلود کنید.",
  "ccmessaging.operlogs.multimedialibrary.save.video": "پیکربندی داده را در کتابخانه چندرسانه ای ذخیره کنید و داده های فایل از نوع ویدئو را آپلود کنید.",
  "ccmessaging.operlogs.preview.contract": "پیش نمایش قرارداد، کاربر:",
  "ccmessaging.operlogs.signature.submit": "قرارداد امضا شده ارسال کنید",
  "ccmessaging.operlogs.upload.contract": "آپلود قرارداد ",
  "ccmessaging.pop.org.window.noorg": "بدون OU",
  "ccmessaging.pop.org.window.org": "با یک OU",
  "ccmessaging.ruleconfig.add": "اضافه کردن",
  "ccmessaging.ruleconfig.all": "همه کانال ها",
  "ccmessaging.ruleconfig.applicationChannel": "کانال",
  "ccmessaging.ruleconfig.content": "محتوا",
  "ccmessaging.ruleconfig.contentNotNull": "محتوا نمی تواند خالی باشد.",
  "ccmessaging.ruleconfig.createdTime": "زمان ایجاد",
  "ccmessaging.ruleconfig.dateEnd": "زمان پایان",
  "ccmessaging.ruleconfig.dateStart": "زمان شروع",
  "ccmessaging.ruleconfig.deleteFail": "قانون حذف نشد.",
  "ccmessaging.ruleconfig.deleteSelectRule": "آیا مطمئن هستید که می خواهید قانون انتخاب شده را حذف کنید؟",
  "ccmessaging.ruleconfig.deleteSuccess": "قانون با موفقیت حذف شد.",
  "ccmessaging.ruleconfig.duplicateName": "نام قانون تکراری است.",
  "ccmessaging.ruleconfig.effective": "تاثير گذار",
  "ccmessaging.ruleconfig.emailaddress.error": "آدرس های زیر ارسال ممنوع است:",
  "ccmessaging.ruleconfig.emailAddressExists": "آدرس ایمیل از قبل وجود دارد",
  "ccmessaging.ruleconfig.emailAdress": "آدرس ایمیل",
  "ccmessaging.ruleconfig.emailChannel": "کانال ایمیل",
  "ccmessaging.ruleconfig.ID": "شناسه",
  "ccmessaging.ruleconfig.invalidation": "باطل شدن",
  "ccmessaging.ruleconfig.invalidEmailAddress": "آدرس ایمیل نامعتبر است",
  "ccmessaging.ruleconfig.invalidSMSNumber": "شماره تلفن همراه نامعتبر.",
  "ccmessaging.ruleconfig.maxEmailAddressCount": "حداکثر 10 آدرس ایمیل را می توان در محتوا وارد کرد",
  "ccmessaging.ruleconfig.maxSMSNumberCount": "حداکثر 10 شماره موبایل می تواند اضافه شود.",
  "ccmessaging.ruleconfig.maxValidityPeriod": "مدت اعتبار حداکثری تنظیم شده است.",
  "ccmessaging.ruleconfig.ruleContentKeyword": "کلمه کلیدی محتوای قانون",
  "ccmessaging.ruleconfig.ruleDescribe": "شرح قانون",
  "ccmessaging.ruleconfig.ruleName": "نام قانون",
  "ccmessaging.ruleconfig.selectRule": "لطفا یک قانون انتخاب کنید",
  "ccmessaging.ruleconfig.SMSChannel": "کانال پیامک",
  "ccmessaging.ruleconfig.SMSNumber": "شماره موبایل",
  "ccmessaging.ruleconfig.SMSNumberExists": "شماره موبایل موجود است",
  "ccmessaging.ruleconfig.SMStips1": `«شماره تلفن همراه می‌تواند دارای حروف عام باشد. "\\ *" نشان می دهد که صفر تا چند کاراکتر مطابقت دارند. "\\ ؟" نشان می دهد که صفر یا یک کاراکتر مطابقت دارد.\``,
  "ccmessaging.ruleconfig.SMStips2": `«به عنوان مثال، مقدار "\\*9988" نشان می دهد که پیامک های ارسال شده از همه شماره های تلفن همراه با پایان "9988" رد می شوند.`,
  "ccmessaging.ruleconfig.SMStips3": `به عنوان مثال، مقدار "132\\*" نشان می دهد که پیامک های ارسال شده از همه شماره های تلفن همراه که با "132" شروع می شوند، رد می شوند.`,
  "ccmessaging.ruleconfig.SMStips4": `مقدار "\\*" نشان می دهد که پیامک های ارسال شده از تمام شماره های تلفن همراه مسدود شده اند. هنگام استفاده از این مقدار احتیاط کنید.`,
  "ccmessaging.ruleconfig.timeerror.dateValidateFailed": "لطفاً تاریخی را دیرتر از زمان فعلی و زودتر از 19-1-2038 انتخاب کنید",
  "ccmessaging.ruleconfig.timeerror.empty": "* زمان شروع و زمان پایان نمی تواند خالی باشد.",
  "ccmessaging.ruleconfig.timeerror.endTime": "* زمان پایان نمی تواند زودتر از زمان فعلی باشد.",
  "ccmessaging.ruleconfig.timeerror.laterThanEndTime": "* زمان شروع نمی تواند دیرتر از زمان پایان باشد.",
  "ccmessaging.ruleconfig.timeerror.laterThanEndTime2": "زمان شروع نمی تواند دیرتر از زمان پایان باشد.",
  "ccmessaging.ruleconfig.timeerror.startTime": "* زمان شروع نمی تواند زودتر از زمان فعلی باشد.",
  "ccmessaging.ruleconfig.tips1": "شما می توانید در آدرس ایمیل حروف عام وارد کنید. \"\\*\" نشان می دهد که 0 یا بیشتر کاراکتر مطابقت دارند. \"\\؟\" نشان می دهد که 0 تا 1 کاراکتر مطابقت دارند.`",
  "ccmessaging.ruleconfig.tips2": "«مثلا. \"\\*@huawei.com\" نشان می دهد که تمام ایمیل هایی که نام دامنه آنها \"huawei.com\" است رد می شوند.`",
  "ccmessaging.ruleconfig.tips3": "جعبه متنی محتوا به جز \"\\*\" \"\\؟\" قوانین آدرس ایمیل باید رعایت شود.`",
  "ccmessaging.ruleconfig.tips4": "`\"\\*@\\*.com\"همه نامه های با نام دامنه \".com\" را مسدود می کند. هنگام استفاده از این دستور احتیاط کنید.`",
  "ccmessaging.ruleconfig.validityPeriod": "دوره اعتبار",
  "ccmessaging.ruleconfig.duplicateEmailAddress": "تکرار نشانی رایانامه ای",
  "ccmessaging.ruleconfig.duplicateSmsAddress": "شماره تلفن تکراری",
  "ccmessaging.socialPostOperation.button.approve": "تصویب",
  "ccmessaging.socialPostOperation.button.batchDelete": "حذف دسته ای",
  "ccmessaging.socialPostOperation.button.cancel": "برداشت از حساب",
  "ccmessaging.socialPostOperation.button.channelConfig": "تنظیمات کانال",
  "ccmessaging.socialPostOperation.button.creatPost": "پست",
  "ccmessaging.socialPostOperation.button.edit": "ویرایش کنید",
  "ccmessaging.socialPostOperation.button.delete": "حذف",
  "ccmessaging.socialPostOperation.button.saveDraft": "ذخیره به عنوان پیش نویس",
  "ccmessaging.socialPostOperation.button.upload": "بارگذاری",
  "ccmessaging.socialPostOperation.ceratePageTitle": "عملیات / ارسال حساب",
  "ccmessaging.socialPostOperation.edit.approveInformation": "اطلاعات تایید",
  "ccmessaging.socialPostOperation.edit.basicInformation": "اطلاعات اولیه",
  "ccmessaging.socialPostOperation.edit.must": "ضروری",
  "ccmessaging.socialPostOperation.edit.placeholder": "لطفا وارد شوید",
  "ccmessaging.socialPostOperation.edit.publishInformation": "انتشار اطلاعات ",
  "ccmessaging.socialPostOperation.facebook.publishAt.tips": "باید ظرف 20 دقیقه تا 75 روز پس از ایجاد، منتشر شود.",
  "ccmessaging.socialPostOperation.facebook.tips.emptyPost": "Facebook نمی تواند پست های خالی ارسال کند. لطفاً حداقل یک مورد (محتوا، تصویر یا ویدیو) را انتخاب کنید.",
  "ccmessaging.socialPostOperation.field.applicationType": "نوع برنامه",
  "ccmessaging.socialPostOperation.field.applicationType.create": "پست",
  "ccmessaging.socialPostOperation.field.approveComment": "نظرات تایید",
  "ccmessaging.socialPostOperation.field.approverName": "تصویب کننده",
  "ccmessaging.socialPostOperation.field.approveStatus": "نتیجه تایید",
  "ccmessaging.socialPostOperation.field.approveStatus.pass": "قبولی",
  "ccmessaging.socialPostOperation.field.approveStatus.rejected": "رد کردن",
  "ccmessaging.socialPostOperation.field.channelName": "نام کانال",
  "ccmessaging.socialPostOperation.field.content": "فهرست",
  "ccmessaging.socialPostOperation.field.createUserName": "درخواست کننده",
  "ccmessaging.socialPostOperation.field.image": "تصویر",
  "ccmessaging.socialPostOperation.field.operation": "عمل",
  "ccmessaging.socialPostOperation.field.postConfigInfo": "اطلاعات کانال",
  "ccmessaging.socialPostOperation.field.postType": "کانال انتشار",
  "ccmessaging.socialPostOperation.field.publishAt": "زمان آزاد کردن",
  "ccmessaging.socialPostOperation.field.publishChannel": "یک کانال آزاد کنید",
  "ccmessaging.socialPostOperation.field.publishStatus": "وضعیت آزاد کردن",
  "ccmessaging.socialPostOperation.field.publishStatus.published": "منتشر شده",
  "ccmessaging.socialPostOperation.field.publishStatus.unpublished": "منتشر نشده",
  "ccmessaging.socialPostOperation.field.publishTime": "زمان آزاد کردن تخمینی",
  "ccmessaging.socialPostOperation.field.publishTimeSet": "تنظیمات زمان آزاد کردن",
  "ccmessaging.socialPostOperation.field.publishTyle.now": "آزاد کردن در زمان واقعی",
  "ccmessaging.socialPostOperation.field.publishTyle.time": "آزاد کردن برنامه ریزی شده",
  "ccmessaging.socialPostOperation.field.publishType": "نوع آزاد کردن",
  "ccmessaging.socialPostOperation.field.remark": "ملاحظات",
  "ccmessaging.socialPostOperation.field.status": "وضعیت",
  "ccmessaging.socialPostOperation.field.status.approved": "تکمیل شد",
  "ccmessaging.socialPostOperation.field.status.draft": "پیش نویس",
  "ccmessaging.socialPostOperation.field.status.toApproved": "مورد تایید قرار گیرد",
  "ccmessaging.socialPostOperation.field.title": "موضوع",
  "ccmessaging.socialPostOperation.field.video": "ویدئو",
  "ccmessaging.socialPostOperation.res.recall": "برداشت با موفقیت انجام شد",
  "ccmessaging.socialPostOperation.search.searchTitle": "چهره ها",
  "ccmessaging.socialPostOperation.select.pleaseChoose": "لطفا انتخاب کنید",
  "ccmessaging.socialPostOperation.tab.history": "تاریخچه تایید",
  "ccmessaging.socialPostOperation.tips.approveSet": "لطفاً item پیکربندی تأییدکننده را بررسی کنید.",
  "ccmessaging.socialPostOperation.tips.approveSuccess": "تأیید با موفقیت انجام شد",
  "ccmessaging.socialPostOperation.tips.approvingDelete": "داده های موجود در وضعیت To-Be-Approved را نمی توان حذف کرد",
  "ccmessaging.socialPostOperation.tips.cancelError": "داده های غیر مورد تایید نمی توانند باطل شوند",
  "ccmessaging.socialPostOperation.tips.cannotDel": "پست هایی که باید تایید شوند قابل حذف نیستند.",
  "ccmessaging.socialPostOperation.tips.delConfirm": "آیا مطمئن هستید که می خواهید داده های انتخاب شده را حذف کنید؟",
  "ccmessaging.socialPostOperation.tips.deleteApproveSuccess": "حذف با موفقیت ارسال شد. منتظر پذیرفته شدن.",
  "ccmessaging.socialPostOperation.tips.deleteNoApprove": "تایید کننده خالی است و مستقیماً پس از ارسال حذف خواهد شد. آیا مطمئن هستید که میخواهید ادامه دهید؟",
  "ccmessaging.socialPostOperation.tips.fileTip": "اندازه فایل از حد بالا بیشتر است. حداکثر اندازه {n} مگابایت است.",
  "ccmessaging.socialPostOperation.tips.imageNumTip": "حداکثر 9 تصویر می تواند آپلود شود.",
  "ccmessaging.socialPostOperation.tips.imageType": "فرمت های PNG، JPG، GIF، BMP و TIFF را پشتیبانی می کند.",
  "ccmessaging.socialPostOperation.tips.imgAndVideoChoose": "تصاویر و ویدئوها را نمی توان همزمان آپلود کرد.",
  "ccmessaging.socialPostOperation.tips.postTypesChoose": "حداقل یک کانال انتشار را انتخاب کنید",
  "ccmessaging.socialPostOperation.tips.publishFailed": "منتشر نشد.",
  "ccmessaging.socialPostOperation.tips.saveApproveSuccess": "با موفقیت ثبت شد. منتظر پذیرفته شدن.",
  "ccmessaging.socialPostOperation.tips.submit": "تایید کننده خالی است. پس از ارسال مستقیما منتشر خواهد شد. آیا مطمئن هستید که میخواهید ادامه دهید؟",
  "ccmessaging.socialPostOperation.tips.submitSuccess": "با موفقیت ثبت شد",
  "ccmessaging.socialPostOperation.tips.titleError": "نمی تواند حاوی < و > باشد",
  "ccmessaging.socialPostOperation.tips.uploadVideo": "لطفا یک فایل ویدئویی آپلود کنید",
  "ccmessaging.socialPostOperation.tips.videoType": "فرمت MP4 را پشتیبانی می کند.",
  "ccmessaging.socialPostOperation.tips.youTubeFileChoose": "فقط ویدیوها را می توان از طریق کانال Youtube آپلود کرد.",
  "ccmessaging.socialPostOperation.youTube.addLabel": "برچسب اضافه کنید",
  "ccmessaging.socialPostOperation.youTube.audience": "حضار",
  "ccmessaging.socialPostOperation.youTube.audienceRule": "طبق قانون باید با قانون حفاظت از حریم خصوصی آنلاین کودکان (COPPA) و/یا قوانین دیگر در هر کجا که هستید مطابقت داشته باشد. باید مشخص کنید که آیا ویدیوی شما برای کودکان در نظر گرفته شده است یا خیر.",
  "ccmessaging.socialPostOperation.youTube.category": "دسته بندی",
  "ccmessaging.socialPostOperation.youTube.category.car": "خودرو و وسیله نقلیه",
  "ccmessaging.socialPostOperation.youTube.category.Comedy": "کمدی",
  "ccmessaging.socialPostOperation.youTube.category.education": "تحصیلات",
  "ccmessaging.socialPostOperation.youTube.category.entertainment": "سرگرمی",
  "ccmessaging.socialPostOperation.youTube.category.film": "فیلم و انیمیشن",
  "ccmessaging.socialPostOperation.youTube.category.gaming": "بازی",
  "ccmessaging.socialPostOperation.youTube.category.howto": "Howto & Style",
  "ccmessaging.socialPostOperation.youTube.category.music": "موسیقی",
  "ccmessaging.socialPostOperation.youTube.category.news": "اخبار و سیاست",
  "ccmessaging.socialPostOperation.youTube.category.nonprofits": "Nonprofits & Activism",
  "ccmessaging.socialPostOperation.youTube.category.people": "افراد و وبلاگ ها",
  "ccmessaging.socialPostOperation.youTube.category.pets": "حیوانات خانگی و حیوانات",
  "ccmessaging.socialPostOperation.youTube.category.science": "علم و فناوری",
  "ccmessaging.socialPostOperation.youTube.category.shortMovies": "فیلم های کوتاه",
  "ccmessaging.socialPostOperation.youTube.category.sport": "ورزش ها",
  "ccmessaging.socialPostOperation.youTube.category.travel": "سفر و رویدادها",
  "ccmessaging.socialPostOperation.youTube.categoryDetail": "VOD خود را به یک دسته اضافه کنید تا بیننده بتواند آن را پیدا کند",
  "ccmessaging.socialPostOperation.youTube.disclosureScope": "حوزه عمومی",
  "ccmessaging.socialPostOperation.youTube.disclosureScopeDetail": "انتخاب کنید چه کسی می تواند ویدیوی شما را ببیند",
  "ccmessaging.socialPostOperation.youTube.embedding": "اجازه جاسازی",
  "ccmessaging.socialPostOperation.youTube.embedding.tips": "به دیگران اجازه می دهد تا ویدیوهای شما را در وب سایت خود جاسازی کنند.",
  "ccmessaging.socialPostOperation.youTube.knowledgePermission": "Creative Commons - Attribution",
  "ccmessaging.socialPostOperation.youTube.labels": "برچسب",
  "ccmessaging.socialPostOperation.youTube.labelsDetail": "برچسب‌ها می‌توانند به بینندگان کمک کنند تا ویدیوی شما را پیدا کنند، اگر کلماتی که به راحتی املایی دارند در محتوای ویدیو وجود داشته باشد. به غیر از آن، برچسب ها برای یافتن ویدیوها چندان مفید نیستند.",
  "ccmessaging.socialPostOperation.youTube.noKids": " Not for children",
  "ccmessaging.socialPostOperation.youTube.normalPermission": "مجوز استاندارد YouTube",
  "ccmessaging.socialPostOperation.youTube.permissions": "مجوز",
  "ccmessaging.socialPostOperation.youTube.permissionsKnow": "انواع مجوزها را درک کنید",
  "ccmessaging.socialPostOperation.youTube.scopePrivate": "خصوصی",
  "ccmessaging.socialPostOperation.youTube.scopePublic": "عمومی",
  "ccmessaging.socialPostOperation.youTube.scopeUnlisted": "به صورت عمومی فهرست نشده است",
  "ccmessaging.socialPostOperation.youTube.tags.tips": "بعد از هر برچسب یک کاما وارد کنید",
  "ccmessaging.socialPostOperation.youTube.videoToKids": "آیا این ویدیو برای کودکان است؟",
  "ccmessaging.socialPostOperation.youTube.view.commentNum": "14 نظر",
  "ccmessaging.socialPostOperation.youTube.view.dataResolve": "تحلیل داده ها",
  "ccmessaging.socialPostOperation.youTube.view.editVideo": "ویرایش ویدیو",
  "ccmessaging.socialPostOperation.youTube.view.lookNum": "38 بازدید 2 روز پیش",
  "ccmessaging.socialPostOperation.youTube.view.orderType": "حالت مرتب سازی",
  "ccmessaging.socialPostOperation.youTube.view.share": "اشتراک گذاری",
  "ccmessaging.socialPostOperation.youTube.view.subscriber": "1 مشترکین",
  "ccmessaging.socialPostOperation.youTube.view.unfolding": "بسط دادن",
  "ccmessaging.socialPostOperation.youTube.yesKids": "بله، محتوا برای کودکان است",
  "ccmessaging.socialPostOperation.youTube.publishAtTips": "این تنظیم فقط در صورتی امکان پذیر است که دامنه عمومی روی خصوصی تنظیم شده باشد",
  "ccmessaging.socialPostOperation.facebook.threeDay": "3 روز",
  "ccmessaging.socialPostOperation.facebook.awful": "خیلی بد و ناخوشایند",
  "ccmessaging.socialPostOperation.facebook.comment": "اظهار نظر",
  "ccmessgaing.chat.web.channel.Agent": "نماینده صوتی و تصویری",
  "ccmessgaing.chat.web.channel.ivr": "تلفن گویا",
  "ccmessgaing.chat.web.channel.webrtc.share": "اشتراک گذاری دسکتاپ شامل نمایش حریم خصوصی است. لطفا تایید کنید.",
  "ccmessgaing.chatbot.message.female": "زن",
  "ccmessgaing.chatbot.message.male": "مرد",
  "ccmessgaing.postChannelConfig.accessToken": "accessToken",
  "ccmessgaing.postChannelConfig.add": "افزودن کانال",
  "ccmessgaing.postChannelConfig.apiFabricAK": "ApiFabricAK",
  "ccmessgaing.postChannelConfig.apiFabricSK": "ApiFabricSK",
  "ccmessgaing.postChannelConfig.apiKey": "apiKey",
  "ccmessgaing.postChannelConfig.appId": "شناسه مشتری",
  "ccmessgaing.postChannelConfig.appKey": "appKey",
  "ccmessgaing.postChannelConfig.cancel": "لغو کنید",
  "ccmessgaing.postChannelConfig.channelConfig": "پیکربندی کانال",
  "ccmessgaing.postChannelConfig.channelCreate": "ایجاد کانال",
  "ccmessgaing.postChannelConfig.channelEdit": "ویرایش پیکربندی کانال",
  "ccmessgaing.postChannelConfig.channelType": "پیکربندی کانال",
  "ccmessgaing.postChannelConfig.confirm": "خوب",
  "ccmessgaing.postChannelConfig.addUsers": "اضافه کردن",
  "ccmessgaing.postChannelConfig.createFailed": "اطلاعات پیکربندی کانال چندرسانه‌ای اجتماعی ایجاد نشد.",
  "ccmessgaing.postChannelConfig.createSuccess": "موفق به ایجاد اطلاعات پیکربندی کانال چندرسانه ای اجتماعی شد.",
  "ccmessgaing.postChannelConfig.delete": "حذف",
  "ccmessgaing.postChannelConfig.deleteFailed": "اطلاعات پیکربندی مربوط به کانال چندرسانه ای اجتماعی حذف نشد.",
  "ccmessgaing.postChannelConfig.deleteFailed.db": "حذف نشد. عملیات پایگاه داده ناموفق بود.",
  "ccmessgaing.postChannelConfig.deleteFailed.permission": "حذف نشد. بررسی مجوز انجام نشد.",
  "ccmessgaing.postChannelConfig.deleteFailed.inUse": "حذف نشد. کانال اطلاعات را انتشار داده است.",
  "ccmessgaing.postChannelConfig.deleteSuccess": "موفق به حذف اطلاعات پیکربندی کانال چندرسانه ای اجتماعی شد.",
  "ccmessgaing.postChannelConfig.edit": "ویرایش کنید",
  "ccmessgaing.postChannelConfig.editFailed": "به روز رسانی نا موفق بود. پارامترهای فرم و اتصال شبکه را بررسی کنید.",
  "ccmessgaing.postChannelConfig.editSuccess": "در به روز رسانی اطلاعات پیکربندی کانال چندرسانه ای اجتماعی موفق شد.",
  "ccmessgaing.postChannelConfig.failed": "ناموفق",
  "ccmessgaing.postChannelConfig.maxLength": "طول بیشینه نویسه {n} ها است.",
  "ccmessgaing.postChannelConfig.operation": "عمل",
  "ccmessgaing.postChannelConfig.operation.person": "پرسنل عملیات",
  "ccmessgaing.postChannelConfig.placeSelectChannelType": "نوع کانال را انتخاب کنید",
  "ccmessgaing.postChannelConfig.postChannelName": "نام کانال",
  "ccmessgaing.postChannelConfig.refreshToken": "refreshToken",
  "ccmessgaing.postChannelConfig.users.overSize": "تعداد پرسنل عملیات بیش از 10 نفر است.",
  "ccmessgaing.postChannelConfig.repeatedly": "کانال از قبل وجود دارد.",
  "ccmessgaing.postChannelConfig.selectByChannelName": "نام کانال",
  "ccmessgaing.postChannelConfig.subChannelId": "شناسه کانال",
  "ccmessgaing.postChannelConfig.success": "موفقیت",
  "ccmessgaing.postChannelConfig.unauthorized": "عملیات احراز هویت نشده است.",
  "chat.agent.chatOnlineWorkbench": "میز کار چت آنلاین",
  "chat.agent.label.balance": "درآمد و هزینه",
  "chat.agent.label.billDetail": "جزئیات لایحه",
  "chat.agent.label.chatBot": "چت بات",
  "chat.agent.label.consumption": "مصرف من",
  "chat.agent.label.customerSource": "منبع مشتری: اپلیکیشن موبایل - مرکز شخصی",
  "chat.agent.label.downagainfile": "دانلود مجدد",
  "chat.agent.label.goAway": "دور",
  "chat.agent.label.historicalBill": "صورت حساب تاریخی",
  "chat.agent.label.money": "میزان",
  "chat.agent.label.mybill": "صورت حساب های من",
  "chat.agent.label.offline": "آفلاین",
  "chat.agent.label.online": "آنلاین",
  "chat.agent.label.recivefile": "دريافت كردن",
  "chat.agent.label.refusefile": "امتناع کنید",
  "chat.agent.label.send": "ارسال",
  "chat.agent.label.total": "جمع",
  "chat.agent.label.waiting": "صبر کن",
  "chat.agent.message.changeFailed": "تغییر وضعیت نماینده انجام نشد.",
  "chat.agent.title.changeWorkMode": "تغییر حالت کار",
  "chat.agent.title.close": "بستن",
  "chat.agent.title.createCase": "ایجاد پرونده",
  "chat.agent.title.createSkill": "ایجاد مهارت",
  "chat.agent.title.enterText": "لطفا وارد شوید",
  "chat.agent.title.OfflineCall": "تماس آفلاین",
  "chat.agent.title.transfer": "انتقال به",
  "chat.agent.title.userInformation": "اطلاعات مشتری",
  "chat.agent.title.userMenu": "منوی مشتری",
  "chat.agent.title.workMode": "حالت کار",
  "chat.agentConsole.message.content.audio": "[پیام صوتی]",
  "chat.agentConsole.message.content.connectMessage": "یک پیام جدید!",
  "chat.agentConsole.message.content.image": "[پیام تصویر]",
  "chat.agentConsole.message.content.locate": "[پیام موقعیت مکانی]",
  "chat.agentConsole.message.content.video": "[پیام تصویری]",
  "chat.agentConsole.message.error.emptyMessage": "[پیام حاوی محتوای قابل نمایش نیست.]",
  "chat.agentConsole.message.error.notification": "اعلان پیام جدید ایجاد نمی شود. لطفا تنظیمات مرورگر را بررسی کنید!",
  "chat.client.alert.message.invildtoken": "زمان گفتگو شما تمام شد یا از جای دیگری وارد سیستم شده اید. لطفاً پنجره چت را ببندید و دوباره امتحان کنید. متشکرم!",
  "chat.client.button.submit": "ارسال",
  "chat.client.evaluate.message": "لطفا در مورد خدمت من نظر دهید متشکرم!",
  "chat.client.greeting.afternoon": "عصر بخیر!",
  "chat.client.greeting.dear": "عزیز",
  "chat.client.greeting.evening": "عصر بخیر!",
  "chat.client.greeting.info": "چه کاری می توانم برای شما انجام دهم؟",
  "chat.client.greeting.morning": "صبح بخیر!",
  "chat.client.label.accountNumber": "حساب",
  "chat.client.label.bill": "صورت حساب های من",
  "chat.client.label.chatTitle": "مشتری",
  "chat.client.label.download": "دانلود",
  "chat.client.label.evaluation": "نظرسنجی رضایتمندی",
  "chat.client.label.forgotPassword": "رمز عبور را فراموش کرده اید",
  "chat.client.label.header": "Telco+",
  "chat.client.label.input": "لطفا وارد شوید",
  "chat.client.label.like": "شاید خوشت بیاد",
  "chat.client.label.login": "ورود",
  "chat.client.label.loginTitle": "ورود",
  "chat.client.label.more": "بیشتر",
  "chat.client.label.no": "خیر",
  "chat.client.label.order": "دستورات من",
  "chat.client.label.password": "رمز عبور",
  "chat.client.label.privacyHint": "هشدار برای حفاظت از حریم خصوصی",
  "chat.client.label.privacyHintContent": "برای بهبود کیفیت خدمات، مکالمه شما توسط سیستم ضبط می شود. آیا مطمئن هستید که می خواهید به صحبت ادامه دهید؟",
  "chat.client.label.promotions": "سریع",
  "chat.client.label.rateService": "خدمات امتیاز",
  "chat.client.label.system": "سیستم مشغول است. لطفاً بعداً دوباره امتحان کنید.",
  "chat.client.label.yes": "آره",
  "chat.client.message.evaluationFail": "نظرسنجی رضایت شکست خورده است.",
  "chat.client.message.evaluationSuccess": "نظرسنجی رضایت با موفقیت انجام شد.",
  "chat.client.message.gladtoServe": ". چه کاری می توانم برای شما انجام دهم؟",
  "chat.client.message.hiMyWorkno": "سلام! این هست",
  "chat.client.message.noAgent": "با عرض پوزش، نماینده مشغول است. بعدا دوباره تلاش کنید.",
  "chat.client.transfer.link": "آیا مطمئن هستید که می خواهید تماس را به یک نماینده منتقل کنید؟",
  "chat.common.message.cancel": "لغو کنید",
  "chat.common.message.create": "ايجاد كردن",
  "chat.common.message.dateEnd": "زمان پایان",
  "chat.common.message.dateStart": "زمان شروع",
  "chat.common.message.delete": "حذف",
  "chat.common.message.error": "خطا",
  "chat.common.message.fail": "شکست",
  "chat.common.message.finish": "خوب",
  "chat.common.message.modify": "تغییر",
  "chat.common.message.promotions": "اطلاعات",
  "chat.common.message.success": "موفقیت",
  "chat.common.message.warning": "هشدار",
  "chat.confirm.no": "خیر",
  "chat.confirm.ok": "خوب",
  "chat.confirm.yes": "آره",
  "chat.session.channel.all": "همه",
  "chat.session.channel.facebook": "Facebook",
  "chat.session.channel.mobile": "ترمینال موبایل",
  "chat.session.channel.twitter": "توییتر",
  "chat.session.channel.web": "وب",
  "chat.session.channel.wechat": "WeChat",
  "chat.session.endType.agentEnd": "پایان توسط نماینده",
  "chat.session.endType.customerEnd": "پایان توسط مشتری",
  "chat.session.endType.overtimeEnd": "به دلیل timeout پایان یافت",
  "chat.session.label.alias": "نام مشتری",
  "chat.session.label.allChannel": "همه انواع کانال",
  "chat.session.label.allEndType": "همه انواع پایان",
  "chat.session.label.channel": "کانال دسترسی گفتگو",
  "chat.session.label.created_endTime": "زمان پایان",
  "chat.session.label.created_startTime": "زمان شروع",
  "chat.session.label.createdTime": "زمان ایجاد گفتگو",
  "chat.session.label.endReason": "علت پایان گفتگو",
  "chat.session.label.endTime": "زمان پایان گفتگو",
  "chat.session.label.endType": "نوع پایان گفتگو",
  "chat.session.label.enterQueueTime": "زمان ورود به صف",
  "chat.session.label.evaluation": "ارزیابی گفتگو",
  "chat.session.label.evaluationTime": "زمان ارزیابی گفتگو",
  "chat.session.label.exitQueueTime": "زمان خروج از صف",
  "chat.session.label.remark": "اظهار نظر",
  "chat.session.label.reset": "بازنشانی کنید",
  "chat.session.label.search": "جستجو کردن",
  "chat.session.label.servedAgent": "نمایندگی خدمات",
  "chat.session.label.sessionId": "شناسه گفتگو",
  "chat.session.label.skillQueueId": "شناسه صف مهارت",
  "chat.session.label.skillQueueName": "نام صف مهارت",
  "chat.session.label.subChannel": "کانال دسترسی فرعی",
  "chat.session.title.basic": "اطلاعات پایه گفتگو",
  "chat.session.title.detail": "جزئیات گفتگو",
  "chat.session.title.history": "سوابق چت تاریخی",
  "chat.skill.label.account": "حساب",
  "chat.skill.label.agents": "نماینده",
  "chat.skill.label.cancel": "لغو کنید",
  "chat.skill.label.createSkill": "ايجاد كردن",
  "chat.skill.label.createSkillTab": "مهارت ایجاد کنید",
  "chat.skill.label.delete": "حذف",
  "chat.skill.label.deleteSkills": "حذف",
  "chat.skill.label.description": "شرح",
  "chat.skill.label.edit": "ویرایش کنید",
  "chat.skill.label.inputName": "نام",
  "chat.skill.label.maxQueueNumber": "حداکثر شماره صف",
  "chat.skill.label.maxQueueTime": "حداکثر زمان صف (ها)",
  "chat.skill.label.maxQueueTimeInTable": "حداکثر زمان صف",
  "chat.skill.label.name": "نام کاربری",
  "chat.skill.label.ok": "خوب",
  "chat.skill.label.priority": "اولویت",
  "chat.skill.label.reset": "بازنشانی کنید",
  "chat.skill.label.save": "ذخیره کنید",
  "chat.skill.label.skill": "مهارت-",
  "chat.skill.label.skillDetail": "جزئیات مهارت",
  "chat.skill.label.skillId": "شماره",
  "chat.skill.label.skillName": "نام",
  "chat.skill.label.submit": "ارسال",
  "chat.skill.message.completeInputs": "لطفاً تمام ورودی ها را تکمیل کنید.",
  "chat.skill.message.createFail": "ایجاد مهارت انجام نشد.",
  "chat.skill.message.createSuccess": "مهارت با موفقیت ایجاد شد.",
  "chat.skill.message.delete": "حذف",
  "chat.skill.message.error": "خطا",
  "chat.skill.message.failDeleteSkill": "مهارت حذف نشد.",
  "chat.skill.message.isExisted": "صف مهارت از قبل وجود دارد.",
  "chat.skill.message.priorityLength": "اطمینان حاصل کنید که اولویت کمتر یا برابر با پنج رقم است.",
  "chat.skill.message.saveFail": "مهارت ذخیره نشد.",
  "chat.skill.message.saveSuccess": "مهارت را با موفقیت ذخیره کرد.",
  "chat.skill.message.selectSkills": "یک مهارت را انتخاب کنید.",
  "chat.skill.message.success": "موفقیت",
  "chat.skill.message.warning": "هشدار",
  "chat.skill.message.willDeleteAllSkills": "آیا مطمئن هستید که می خواهید همه مهارت ها را حذف کنید؟",
  "chat.skill.message.willDeleteSkill": "آیا مطمئن هستید که می خواهید مهارت را حذف کنید؟",
  "chat.skill.message.willDeleteSkills": "آیا مطمئن هستید که می خواهید مهارت های انتخاب شده را حذف کنید؟",
  "chat.thirdportal.chatWindow.button.startChat": "چت را شروع کنید",
  "chat.thirdportal.chatWindow.label.userEmail": "ایمیل",
  "chat.thirdportal.chatWindow.label.userName": "نام",
  "chat.thirdportal.chatWindow.label.userPhone": "عدد",
  "chat.thirdportal.title": "پورتال وب مشتری",
  "chat.timerange.label.alldays": "زمان ایجاد همه",
  "chat.timerange.label.confirmSocailChatNum": "حداکثر تعداد مشتریان خدمات همزمان 0 است که از پیکربندی جهانی استفاده خواهند کرد",
  "chat.timerange.label.customizes": "سفارشی سازی شده",
  "chat.timerange.label.ok": "خوب",
  "chat.timerange.label.oneday": "آخرین روز",
  "chat.timerange.label.sevendays": "آخرین 7 روز ",
  "chat.timerange.label.thirtydays": "آخرین 30 روز ",
  "chat.timerange.label.threedays": "آخرین 3 روز ",
  "chat.timerange.message.timeempty": "زمان نمی تواند خالی باشد.",
  "chat.timerange.message.timeerror": "فرمت زمان نادرست است.",
  "chat.usefulexpressions.label.createExpressionTab": "عبارت رایج ایجاد کنید",
  "chat.usefulexpressions.label.expression": "عبارات رایج",
  "chat.usefulexpressions.label.expressionDetail": "جزئیات عبارت رایج",
  "chat.usefulexpressions.message.contentLength": "مطمئن شوید که طول عبارت رایج کمتر یا مساوی 1000 کاراکتر باشد.",
  "chat.usefulexpressions.message.contentNotNull": "عبارت رایج نمی تواند خالی باشد.",
  "chat.usefulexpressions.message.createFail": "عبارت رایج ایجاد نشد.",
  "chat.usefulexpressions.message.createSuccess": "عبارت رایج با موفقیت ایجاد شد.",
  "chat.usefulexpressions.message.failDeleteExpression": "عبارت رایج حذف نشد.",
  "chat.usefulexpressions.message.isExisted": "نام عبارت رایج از قبل وجود دارد.",
  "chat.usefulexpressions.message.saveFail": "عبارت رایج ذخیره نشد.",
  "chat.usefulexpressions.message.saveSuccess": "عبارت رایج با موفقیت ذخیره شد.",
  "chat.usefulexpressions.message.selectExpressions": "یک عبارت رایج را انتخاب کنید.",
  "chat.usefulexpressions.message.willDeleteExpression": "آیا مطمئن هستید که می خواهید عبارت رایج را حذف کنید؟",
  "chat.usefulexpressions.message.willDeleteExpressions": "آیا مطمئن هستید که می خواهید عبارت رایج انتخاب شده را حذف کنید؟",
  "chat.web.evaluation.star1": "خیلی ناراضی",
  "chat.web.evaluation.star2": "ناراضی",
  "chat.web.evaluation.star3": "خوب",
  "chat.web.evaluation.star4": "راضی",
  "chat.web.evaluation.star5": "بسیار راضی",
  "chat.webconfig.button.save": "ذخیره کنید",
  "chat.webconfig.label.bandingskill": "Bind Skill Queue",
  "chat.webconfig.label.basesettings": "پیکربندی پایه",
  "chat.webconfig.label.functionalsettings": "تنظیمات عملکرد",
  "chat.webconfig.label.greeting": "احوال پرسی",
  "chat.webconfig.label.greeting.placeholder": "لطفاً محتوای تبریک را که در پنجره چت مشتری نمایش داده می شود وارد کنید.",
  "chat.webconfig.label.intergratedcode": "کد یکپارچه",
  "chat.webconfig.label.intergratemsg": "چت زنده را در وب سایت خود ادغام کنید",
  "chat.webconfig.label.language": "زبان پیش فرض",
  "chat.webconfig.label.language.en": "انگلیسی",
  "chat.webconfig.label.language.zh": "چینی",
  "chat.webconfig.label.language.pt": "پرتغالی",
  "chat.webconfig.label.transHuman": "انتقال به نماینده",
  "chat.webconfig.label.windowTitle": "عنوان پنجره چت",
  "chat.webconfig.label.windowTitle.placeholder": "لطفا عنوانی را که در پنجره چت برای مشتری نمایش داده می شود وارد کنید.",
  "chat.webconfig.message.configdirective": "کد زیر را کپی کرده و قبل از برچسب </body> وب سایت خود قرار دهید. برای تکمیل دسترسی سریع به Huawei Service Cloud، کد را ذخیره کنید. پس از ذخیره، ورودی Huawei Service Cloud در گوشه سمت راست پایین وب سایت شما نمایش داده می شود. می‌توانید روی آن کلیک کنید و به صورت آنلاین با میز کار خدمات مشتریان صحبت کنید.",
  "chat.webconfig.message.demodirective": "همچنین می توانید آدرس زیر را در پنجره مخفی مرورگر Chrome برای دسترسی به صفحه ارائه ارائه شده توسط سیستم باز کنید:",
  "chat.webconfig.message.saveFail": "پیکربندی ذخیره نشد.",
  "chat.webconfig.message.saveSuccess": "پیکربندی با موفقیت ذخیره شد.",
  "chat.webconfig.title": "کانال وب را فعال کنید",
  "chat.weChatConfig.label.appId": "شناسه توسعه دهنده",
  "chat.weChatConfig.label.AppSecret": "رمز عبور توسعه دهنده",
  "chat.weChatConfig.label.bindSkill": "Bind Skill Queue",
  "chat.weChatConfig.label.codeName": "کد دسترسی به کانال",
  "chat.weChatConfig.label.configId": "شناسه پیکربندی",
  "chat.weChatConfig.label.create": "جدید",
  "chat.weChatConfig.label.delete": "حذف",
  "chat.weChatConfig.label.Domain": "آدرس سرور",
  "chat.weChatConfig.label.facebook": "Facebook",
  "chat.weChatConfig.label.nextStep": "بعد",
  "chat.weChatConfig.label.qcode": "کد QR",
  "chat.weChatConfig.label.save": "ذخیره",
  "chat.weChatConfig.label.search": "جستجو ",
  "chat.weChatConfig.label.secret": "کلید",
  "chat.weChatConfig.label.state": "وضعیت",
  "chat.weChatConfig.label.tenantId": "شناسه محیط کاری tenant",
  "chat.weChatConfig.label.verify": "تایید",
  "chat.weChatConfig.label.verifycode": "Token",
  "chat.weChatConfig.label.verifying": "در حال تأیید",
  "chat.weChatConfig.label.wechat": "WeChat",
  "chat.weChatConfig.link.wechat": "سرور WeChat",
  "chat.weChatConfig.message.appIdExisted": "شناسه توسعه دهنده حساب عمومی وارد شده از قبل وجود دارد.",
  "chat.weChatConfig.message.codeNameExisted": "کد دسترسی به کانال از قبل وجود دارد.",
  "chat.weChatConfig.message.deleteError": "item پیکربندی حذف نشد.",
  "chat.weChatConfig.message.emptyDeleteArray": "item پیکربندی را برای حذف انتخاب کنید.",
  "chat.weChatConfig.message.ensureDelete": "آیا مطمئن هستید که می خواهید item پیکربندی انتخابی را حذف کنید؟",
  "chat.weChatConfig.message.error": "خطا",
  "chat.weChatConfig.message.savesuccess": "با موفقیت دسترسی پیدا کرد.",
  "chat.weChatConfig.message.skillExisted": "صف مهارت انتخاب شده به یک حساب عمومی دیگر محدود شده است.",
  "chat.weChatConfig.message.unknown": "وضعیت تأیید نامشخص است.",
  "chat.weChatConfig.stepname.selectchannel": "Access Channel را انتخاب کنید",
  "chat.weChatConfig.stepname.selectmode": "Configuration Method را انتخاب کنید",
  "chat.weChatConfig.stepname.success": "دسترسی به موفقیت",
  "chat.weChatConfig.stepname.verify": "برای تأیید ثبت کنید",
  "chat.weChatConfig.title": "پیکربندی فعال کردن رسانه های اجتماعی",
  "chat.weChatConfig.title.deleteTitle": "موارد پیکربندی WeChat را حذف کنید",
  "cmessaging.chat.answerUser.error": "ایجاد یک جلسه کاربر ناموفق بود.",
  "cmessaging.chat.answerUser.Tips": "ایجاد یک جلسه کاربر",
  "cmessaging.chat.channelconfig.isPushWebMessage": "Message Push",
  "cmessaging.chat.channelconfig.pushWebMessageKey": "کلید",
  "cmessaging.chat.channelconfig.pushWebMessageUrl": "آدرس Message Push",
  "cmessaging.chat.channelconfig.setAgentTimeout": "Agent Timeout Transfer",
  "cmessaging.chat.multimedia.numOverMaxSum": "مقدار داده های چندرسانه ای که ذخیره کرده اید بیش از حد مجاز است",
  "cmessaging.chat.multimediatype.numOverMaxSum": "تعداد دسته هایی که ذخیره کرده اید فراتر از حد است",
  "ccmessaging.socialPostOperation.search.searchContent": "لطفا مطالب را وارد کنید",
  "ccmessaging.socialPostOperation.link": "به متن اصلی مراجعه کنید",
  "ccmessaging.chat.social.media.enterprise.post.load.failed": "بارگیری پیوست انجام نشد.",
  "ccmessaging.chat.dy.reconnect": "إعادة الاتصال",
  "ccmessaging.chat.dy.uploadLocalFile": ' ملف محلي',
  "ccmessaging.chat.dy.call.end": 'نهاية الجلسة',
  "ccmessaging.chat.richtext.httpTip": "پیوندهای درج شده از قراردادهای نا امن استفاده می کنند ، که منجر به خطرات امنیتی می شود",
  "ccmessaging.chat.riskTip": "هشدار خطر",
  "ccmessaging.chat.chatcard.location": "نشانی",
  "ccmessaging.chat.chatcard.alignment": "سبک نمایش کارت",
  "ccmessaging.chat.chatcard.templateType": "نوع قالب",
  "ccmessaging.chat.chatcard.templateTypeList0": "نوع رایج",
  "ccmessaging.chat.chatcard.templateTypeList1": "گزینه های کارت",
  "ccmessaging.chat.chatcard.tabMediaResource": "ویژگی تصویر",
  "ccmessaging.chat.chatcard.nameExist": "نام از قبل وجود دارد. لطفاً ، آن را تغییر دهید",
  "ccmessaging.chat.voice.record.send": "إرسال صوت",
  "ccmessaging.chat.voice.record.start": "انقر للتسجيل",
  "ccmessaging.chat.voice.record.tip": "مدة التسجيل لا تزيد عن 10 دقائق",
  "ccmessaging.chat.voice.record.stop": "إيقاف التسجيل",
  "ccmessaging.chat.selectorgs.maxlimit.warn": "تعداد سازمان های برگزیده نمی تواند بیش از ۱۰۰ درصد شود.",
  "ccmessaging.chat.chatconfig.deleteFacebookPage": "حذف صفحه اصلی فیس بوک",
  "ccmessaging.chat.search.name": "نام جستجو",
  "ccmessaging.chat.search.phraseName": "جستجوی عبارتهای مشترک",
  "ccmessaging.chat.chatcard.tabnamevalidate": "redirectUrl پیش فرض سیستم است و مجاز به استفاده نیست.",
  "ccmessaging.chat.chatcard.transverse": "افقی",
  "ccmessaging.chat.chatcard.longitudinal": "پرتره",
  "ccmessaging.chat.channelconfig.asyncTime.tip": "در یک سناریوی برون خط، پیام های برون خط ارسال شده توسط مشتری دیگر به مامور هدایت نمی شوند و مستقیماً به عنوان پیام های برون خط ذخیره می شوند.",
  "ccmessaging.chat.channelconfig.noReplyTime.tip": "هنگامی که مشتری آخرین بار با مامور تماس گرفت، مامور اجازه تماس فعال را ندارد.",
  "ccmessaging.chat.channelconfig.noReplyIntervalTime.tip": "اگر مدت زمان پاسخ مشتری فراتر از تنظیم باشد، نشست به طور خودکار پایان می یابد.",
  "ccmessaging.chat.email.attachment.tips.maxOnceUpload": "حداکثر پنج ضمیمه را می توان بارگذاری کرد.",
  "ccmessaging.chat.email.attachment.tips.maxTotalUpload": "تعداد ضمیم های آپلود شده از ۱۰ بیشتر است.",
  "cmessaging.chat.multimedia.agentLogoutHangupResult": "مامور بررسی مجبور شد، جلسه تموم شد، لطفا دستور کاری CRM را بسته",
  "ccmessaging.chat.voice.record": "ضبط صدا",
  "ccmessaging.chat.offline.userIsInService": "يوجد مستخدمون خدمات المقاعد",
  "ccmessaging.chat.channelconfig.search.by.robotNameKeyword": "جستجوی نام ربات یا کد دستیابی",
  "ccmessaging.chat.channelconfig.searchSkillQueueName": "جستجوی نام صف مهارت",
  "ccmessaging.chat.async.echarts.user": "تعداد افراد) زمان (",
  "ccmessaging.chat.async.echarts.message": "پیامها) عدد (",
  "cobrowse.sessionrecord.roomId": "شماره اتاق",
  "cobrowse.sessionrecord.searchroomId": "لطفاً ، شمارۀ اتاق را وارد کن",
  "cobrowse.sessionrecord.workNo": "شماره عامل",
  "cobrowse.sessionrecord.searchworkNo": "لطفاً ، شمارۀ عامل را وارد کن",
  "cobrowse.sessionrecord.caller": "تماس کننده",
  "cobrowse.sessionrecord.searchcaller": "لطفاً ، اطلاعات تماس را وارد کن",
  "cobrowse.sessionrecord.startAt": "زمان آغاز",
  "cobrowse.sessionrecord.endAt": "زمان پایان",
  "cobrowse.sessionrecord.duration": "زمان",
  "cobrowse.sessionrecord.positiveInteger.checkmsg": "یک عدد صحیح مثبت وارد کنید.",
  "cobrowse.sensitiveinfo.pageMatchValue": "پیکربندی صفحه",
  "cobrowse.sensitiveinfo.rulenamecheck": "نام قانون تنها می تواند حاوی حروف، رقم، خط زیر خط های (_), و خط های t (.). باشد که نام قاعده را شامل حداکثر نویسه های 128 می کند",
  "cobrowse.sensitiveinfo.url.check": "مسیر نشانی وب پیکربندی شده برای تطبیق صفحه ، قالب نادرست است",
  "cobrowse.sensitiveinfo.cssselectorcheck": "CSS خطای نحوی انتخابگر",
  "cobrowse.sensitiveinfo.pageIdCheck": "شناسه صفحه تنها می تواند حاوی حروف، رقمها، خط زیرتب های روده های (:), و نقاط ده دهی باشد. شناسه",
  "cobrowse.sensitiveinfo.create": "ایجاد قاعده ی اطلاعات حساس جدید",
  "cobrowse.sensitiveinfo.edit": "ویرایش قاعده اطلاعات حساس جدید",
  "cobrowse.sensitiveinfo.createnameexist": "نام از قبل موجود است",
  "cobrowse.sensitiveinfo.exceedmaxcount": "تا ۱۰۰ قانون حساس می توانند در هر مستاجر پیکربندی شوند",
  "cobrowse.sensitiveinfo.delete": "حذف قاعده اطلاعات حساس",
  "cobrowse.sensitiveinfo.willdeletetips": "مطمئن هستید که می خواهید قاعدۀ اطلاعات حساس برگزیده را حذف کنید ؟",
  "cobrowse.sensitiveinfo.deletenum.outstripping": "بیشینه ۱۰۰ رکورد می تواند در هر زمان حذف شود.",
  "cobrowse.common.tips.selectempty": "لطفاً ، ابتدا دادۀ جهت عمل را برگزینید",
  "cobrowse.sessionrecord.workNo.rules": "بايد تو محدوده ي 1 - 6555 باشه",
  "ccmessaging.chat.chatconfig.batch.delete": "حذف دسته ای",
  "ccmessaging.chat.chatconfig.create": "جدید",
  "ccmessaging.channelconfig.export.datascope": "محدوده داده",
  "ccmessaging.channelconfig.export.allscope": "همه",
  "ccmessaging.channelconfig.export.curPage": "صفحه فعلی",
  "ccmessaging.channelconfig.export.customField": "فیلد سفارشی",
  "ccmessaging.channelconfig.export.selectedField": "زمینه صدور",
  "ccmessaging.channelconfig.export.selectAll": "همه",
  "ccmessaging.channelconfig.export.zipPwd": "رمز عبور فشرده",
  "ccmessaging.channelconfig.export.zipPwdHint": "لطفاً ، اسم رمز فشرده را وارد کنید",
  "ccmessaging.channelconfig.export.pwdMsg": "وارد کردن اسم رمزی که شامل ۸ تا ۱۲ نویسه ، شامل حروف ، ارقام و نویسه های ویژه {'@'}#$%^&*+{'|'}=~!?,.:;-_'(){'{'}{'}'}[]/><. است ، وارد کنید",
  'ccmessaging.channelconfig.export.pwdMsgError': 'مقدار باید حاوی حروف ، ارقام و نویسه های ویژه {\'@\'}#$%^&*+{\'|\'}=~!?,.:;-_\'(){\'{\'}{\'}\'}[]/>< باشد',
  "ccmessaging.channelconfig.export": "صادرات",
  "ccmessaging.channelconfig.viewExportTask": "صدور نمای وظیفه ",
  "ccmessaging.channelconfig.export.title": "صادرات پیکربندی مجرا",
  'ccmessaging.channelconfig.export.exportAll': 'رکوردهای مشخص را انتخاب نکرده اید. همه ی اسناد صادرات بشه؟ همچنین می توانید عملیات را لغو کرده و یک رکورد خاص برای صادرات انتخاب کنید.',
  'ccmessaging.channelconfig.export.exportAllTitle': "صادرات همۀ پیکربندیها",
  "ccmessaging.channelconfig.export.allExportConfirm": "همه صدورها",
  'ccmessaging.channelconfig.export.exportSelect': "شما {0} رکورد را انتخاب کرده اید. مطمئني که مي خواي ادامه بدي؟",
  "ccmessaging.channelconfig.export.exportBeginTime": "زمان شروع صدور",
  "ccmessaging.channelconfig.export.exportEndTime": "زمان پایان صدور",
  "ccmessaging.channelconfig.export.exportTaskStatus": "وضعیت",
  "ccmessaging.channelconfig.export.exporting": "صدور",
  "ccmessaging.channelconfig.export.unprocessed": "پردازش نشده",
  "ccmessaging.channelconfig.export.resultMsg": "فهرست تکلیف صدور ایجاد شده است. می خواهید آن را در فهرست صدور مشاهده کنید ؟",
  "ccmessaging.channelconfig.export.resultSuccess": "با موفقیت ایجاد شد.",
  "ccmessaging.channelconfig.export.exception": "هنگام ایجاد وظیفه صدور خطایی روی داد. لاگ ها را بررسی کنید.",
  "ccmessaging.channelconfig.export.error": "وظیفه صدور جدید انجام نشد\، لطفاً گزارش را بررسی کنید",
  "ccmessaging.channelconfig.export.paramInvalid": "شرایط صدور تأیید نشد.",
  "ccmessaging.channelconfig.export.exceedlimit": "حداکثر {0} سابقه داده را می توان صادر کرد",
  "ccmessaging.channelconfig.export.nodata": "هیچ پیکربندی کانالی شرایط را ملاقات نمی کند.",
  "ccmessaging.chat.holiday.deleteWorkDay": "حذف تاریخ کار مشخص شده",
  "ccmessaging.chat.holiday.deleteAllWorkDay": "حذف همۀ تاریخهای کاری مشخص شده",
  "ccmessaging.chat.holiday.deleteHoliday": "حذف تاریخ استراحت مشخص شده",
  "ccmessaging.chat.holiday.deleteAllHoliday": "حذف همۀ تاریخهای استراحت مشخص شده",
  "ccmessaging.multimedia.leftMenu.category": 'دسته',
  "ccmessaging.multimedia.leftMenu.createCategory": 'ایجاد دسته',
  "ccmessaging.multimedia.leftMenu.editCategory": 'ویرایش دسته',
  "chat.agent.qualityCheckWorkbench.title": "بازرسی کیفیت",
  "chat.agent.qualityCheckWorkbench.currentAgent": "صندلی مراقبت فعلی",
  "chat.agent.qualityCheckWorkbench.subscribeSessionFailed": "نشست مامور ثبت نامه شکست خورد",
  "chat.agent.qualityCheckWorkbench.cancelSubscribeSessionFailed": "نامه‌نوشتن از جلسه مامور شکست خورد",
  "chat.agent.qualityCheckWorkbench.pollMsgFailed": "نامه‌ی صندلی",
  "chat.agent.qualityCheckWorkbench.closeConfirm": "لطفا تایید کنید که چک کیفیت جلسه‌های مجموعه‌رسانه برای صندلی جاری تموم شده است؟",
  "chat.agent.qualityCheckWorkbench.listen": "قطع وارد کردن",
  "chat.agent.qualityCheckWorkbench.insert": "وارد کنید",
  "chat.agent.qualityCheckWorkbench.intercept": "قطع کردن",
  "chat.agent.qualityCheckWorkbench.changeQualityStatus.fail": "تغییر موقعیت بازرسی کیفیت شکست خورد",
  "chat.agent.msg.redis.connection.exception.description": "خطای سیستم جاری ، لطفاً ، منتظر بمانید",
  "ccmessaging.multimedia.download.office.risktips":"قراره اسناد اداره رو دانلود کنی که ممکنه خطر امنیتی باشه توصیه می شود که شما عملیات آنتی ویروس را انجام دهید و تنظیمات امنیتی نرم افزار اداره را فعال کنید.",
  "ccmessaging.chat.multimedia.documentUploadRiskTips":"این سند توسط عاملان برای کاربران ارسال می شود. پرونده هایی که حاوی خطرات امنیتی هستند را بارگذاری نکنید.",
  "ccmessaging.config.names.channels.web": "Web",
  "ccmessaging.config.names.channels.instagram": "Instagram",
  "ccmessaging.config.names.channels.telegram": "Telegram",
  "ccmessaging.config.names.channels.whatsapp": "WhatsApp",
  "ccmessaging.config.names.channels.line": "LINE",
  "ccmessaging.config.names.channels.wechat": "WeChat",
  "ccmessaging.config.names.channels.facebook": "Facebook",
  "ccmessaging.config.names.channels.twitter": "X (Twitter)",
  "ccmessaging.config.names.channels.rcs": "5G RCS",
  "ccmessaging.config.names.channels.email": "Email",
  "ccmessaging.config.names.channels.sms": "SMS",
  "ccmessaging.chat.multimedia.whatsApp.interactiveMsg":"WhatsApp پیامهای تعاملی",
  "ccmessaging.chat.whatsapp.interactiveMsgNamePlaceholder":"لطفاً ، یک نام پیام تعاملی وارد کن",
  "ccmessaging.chat.whatsapp.interactiveMsgName":"نام پیام",
  "ccmessaging.chat.whatsapp.interactiveMsgTitle":"عنوان",
  "ccmessaging.chat.whatsapp.interactiveMsgDescription":"توصیف پیام",
  "ccmessaging.chat.whatsapp.interactiveMsgDescriptionPlaceholder":"لطفاً ، توصیف پیام تعاملی را وارد کن",
  "ccmessaging.chat.whatsapp.interactiveMsgType":"نوع پیام",
  "ccmessaging.chat.whatsapp.orgName":"نام سازمان",
  "ccmessaging.chat.chatcard.queryInteractiveMsg":"خرابی در پرس وجو پیامهای تعاملی",
  "ccmessaging.chat.whatsapp.interactiveType.buttons":"پیام دکمه ای",
  "ccmessaging.chat.whatsapp.interactiveType.list":"پیام فهرست",
  "ccmessaging.chat.whatsapp.delete.interactiveMsg":"حذف پیام های تعاملی",
  "ccmessaging.chat.whatsapp.willdeleteinteractiveMsg":"مطمئنید که می خواهید این پیام تعاملی را حذف کنید ؟",
  "ccmessaging.chat.whatsapp.interactiveTypes":"نوع پیام تعاملی",
  "ccmessaging.chat.whatsapp.interactiveMsgTitlePlaceholder":"لطفاً ، محتوای عنوان تعاملی را وارد کن",
  "ccmessaging.chat.whatsapp.interactiveMsg.bodyContent":"محتویات بدن",
  "ccmessaging.chat.whatsapp.interactiveMsg.bodyContentPlaceholder":"لطفاً ، محتوای بدن را وارد کن.",
  "ccmessaging.chat.whatsapp.interactiveMsg.footerContent":"محتوای پا",
  "ccmessaging.chat.whatsapp.interactiveMsg.footerContentPlaceholder":"لطفاً ، محتوای پا را وارد کن.",
  "ccmessaging.chat.whatsapp.interactiveMsg.pleaseChoose":"لطفاً ، انتخاب کنید",
  "ccmessaging.chat.whatsapp.interactiveMsg.attachments":"پیوستها",
  "ccmessaging.chat.multimedia.whatsApp.interactiveMsg.create":"پیام تعاملی جدید",
  "ccmessaging.chat.multimedia.whatsApp.interactiveMsg.update":"تغییر یک پیام تعاملی",
  "ccmessaging.chat.whatsapp.interactiveType.list.title":"فهرست",
  "ccmessaging.chat.whatsapp.interactiveType.list.titlePlaceholder":"لطفاً ، عنوان فهرست را وارد کنید.",
  "ccmessaging.chat.whatsapp.interactiveMsg.openRow.info":"عنوان گروه خالی است. نمی توان گزینه را تنظیم کرد.",
  "ccmessaging.chat.whatsapp.interactiveType.section.titlePlaceholder":"لطفاً ، عنوان گروه را وارد کن.",
  "ccmessaging.chat.whatsapp.interactiveType.row.titlePlaceholder":"عنوان گزینه را وارد کنید.",
  "ccmessaging.chat.whatsapp.interactiveType.row.descPlaceholder":"یک توصیف گزینه را وارد کنید.",
  "ccmessaging.chat.whatsapp.interactiveMsg.sectionTitle":"لطفاً ، عنوان فهرست را وارد کنید.",
  "ccmessaging.chat.whatsapp.interactiveMsg.queryInteractiveError":"خرابی در پرس وجو پیامهای تعاملی",
  "ccmessaging.chat.whatsapp.interactiveMsg.createInteractiveError":"خرابی در ذخیرۀ پیام تعاملی. پس از این که فهرست گروه بندی می شود ، گزینه های گروه را نمی توان لغو کرد.",
  "ccmessaging.chat.whatsapp.interactiveMsg.saveFail.duplicateName":"خرابی در ذخیرۀ نام پیام تعاملی از قبل وجود دارد.",
  "ccmessaging.chat.whatsapp.interactiveMsg.noHeader":"هیچ کدام",
  "ccmessaging.chat.whatsapp.interactiveMsg.headerContent":"محتویات سرآیند",
  "ccmessaging.chat.whatsapp.interactiveMsg.no.buttons":"خرابی در ذخیرۀ پیام تعاملی. دکمه ی تنظیم وجود ندارد.",
  "ccmessaging.chat.whatsapp.interactiveMsg.no.lists":"خرابی در ذخیرۀ پیام تعاملی ، بدون فهرست تنظیم.",
  "ccmessaging.chat.multimedia.deleteLimit.whatsapp.interactiveMsg":"این منبع توسط پیامهای تعاملی WhatsApp ارجاع شده و نمی تواند حذف شود. نام پیام تعاملی:",
  "ccmessaging.chat.whatsapp.interactiveMsg.saveFail.multiMedia.notExist":"خرابی در ذخیرۀ. منبع چند رسانه ای وجود ندارد.",
  "ccmessaging.chat.whatsapp.interactiveMsg.createInteractiveError.repeateButtonContent":"خرابی در ذخیره. محتوای دکمه باید یکتا باشد.",
  "ccmessaging.chat.case.title":"Case Title",
  "ccmessaging.chat.case.type":"Case Type",
  "ccmessaging.chat.case.callReason":"Call Reason",
  "ccmessaging.chat.case.puzzled":"puzzled",
  "ccmessaging.chat.case.calm":"calm",
  "ccmessaging.chat.case.happy":"happy",
  "ccmessaging.chat.case.upset":"upset",
  "ccmessaging.chat.case.surprise":"surprise",
  "ccmessaging.chat.case.pleasant":"pleasant",
  "ccmessaging.chat.case.angry":"angry",
  "ccmessaging.chat.case.little.angry":"a little anger",
  "ccmessaging.chat.case.very.angry":"very mad",
  "ccmessaging.chat.case.very.knowledgeId":"1228840300949016576",
  "ccmessaging.chat.case.complaint.yes":"yes",
  "ccmessaging.chat.case.complaint.shi":"yes",
  "ccmessaging.chat.degree.view":"مشتري 360درجه",
}
