import request from '@/utils/request'

export function queryNodeOverView(data) {
    return request({
        url: '/oifde/rest/queryNodeOverView',
        method: 'post',
        data
    })
}

export function queryAccessCodes(data) {
    return request({
        url: '/oifde/rest/queryaccesscodes',
        method: 'post',
        data
    })
}

export function queryNodeByAccessCode(data) {
    return request({
        url: '/oifde/rest/queryFlowNodes' ,
        method: 'post',
        data
    })
}

export function querySubFlowNodes(data) {
  return request({
    url: '/oifde/rest/querySubFlowNodes' ,
    method: 'post',
    data
  })
}
export function queryflows(data) {
    return request({
        url: '/oifde/rest/queryflows',
        method: 'post',
        data
    })
}

export function queryNodeOutDetail(data) {
    return request({
        url: '/oifde/rest/queryNodeOutDetail',
        method: 'post',
        data
    })
}

export function queryVersionIndicatorAnalysis(data) {
    return request({
        url: '/oifde/rest/queryVersionIndicatorAnalysis',
        method: 'post',
        data
    })
}

export function queryAllNodeCallsAndIVRRepeatCalls(data) {
    return request({
        url: '/oifde/rest/queryAllNodeCallsAndIVRRepeatCalls',
        method: 'post',
        data
    })
}