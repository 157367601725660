import request from '@/utils/request'

/**
 * 查询预约外呼列表
 * @param data
 * @returns {*}
 */
export const queryAppointList = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/appoint/queryAppointList',
        method: 'post',
        data
    })
}

/**
 * 根据任务ID查询预约外呼列表
 * @param data
 * @returns {*}
 */
export const querytaskappoint = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/appoint/querytaskappoint',
        method: 'post',
        data
    })
}

export const callAppoint = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/appoint/callAppoint',
        method: 'post',
        data
    })
}

export const updateManualCallResult = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/manual/updateManualCallResult',
        method: 'post',
        data
    })
}

export const updateFailedResult = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/appoint/updateFailedResult',
        method: 'post',
        data
    })
}

/**
 * 预约权限
 * @param data
 * @returns {*}
 */
export const getAppointAuth = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/appoint/getAppointAuth',
        method: 'post',
        data
    })
}

/**
 * 删除预约
 * @param data
 * @returns {*}
 */
export const deleteAppoint = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/appoint/deleteAppoint',
        method: 'delete',
        data
    })
}

/**
 * 新增预约
 * @param data
 * @returns {*}
 */
export const createAppoint = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/appoint/createAppoint',
        method: 'post',
        data
    })
}

/**
 * 修改预约
 * @param data
 * @returns {*}
 */
export const updateAppoint = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/appoint/updateAppoint',
        method: 'post',
        data
    })
}

/**
 * 查询座席列表
 * @param data
 * @returns {*}
 */
export const queryAgentAndSkill = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/skill/queryAgentAndSkill',
        method: 'post',
        data
    })
}

/**
 * 调整
 * @param data
 * @returns {*}
 */
export const adjustmentAppoint = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/appoint/adjustmentAppoint',
        method: 'post',
        data
    })
}

/**
 * 查询调整记录
 * @param data
 * @returns {*}
 */
export const queryAppointChgLogByAppointId = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/appoint/queryAppointChgLogByAppointId',
        method: 'post',
        data
    })
}

/**
 * 获取业务结果枚举值
 * @param data
 * @returns {*}
 */
export const queryAllCodeAndNameOfTenant = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/task/queryAllCodeAndNameOfTenant',
        method: 'post',
        data
    })
}

/**
 * 查询座席
 * @param data
 * @returns {*}
 */
export const queryAgentByUserId = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/skill/queryAgentByUserId',
        method: 'post',
        data
    })
}

/**
 * 查询问卷
 * @param data
 * @returns {*}
 */
export const queryScriptAnswerById = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/manual/queryScriptAnswerById',
        method: 'post',
        data
    })
}

/**
 * 更新业务结果
 * @param data
 * @returns {*}
 */
export const recordBusiResult = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/businessResult/recordBusiResult',
        method: 'post',
        data
    })
}






