<template>
    <sweet-dialog v-model="dialogVisible" :title="$t('ccm.callreason.title.callreason')" class="edit-call-reason"  width="616px"  @open="initData()"
                  :close-on-click-modal="false" resize="true">
        <div class="resaonOpt">
            <!-- 所有来电原因-->
            <sweet-col :span="12">
              <div class="resaonOpt-title">{{ $t('ccm.callreason.label.allreason') }}</div>
              <div class="allreason contentBox" :style="{height: contentBoxHeight}"
                     content-class="hlds-is-nesting">
                    <div class="hlds-background--defalt" style="border-radius: 8px;">
                        <aicc-tree :data="reasonTreeLeft" :props="defaultProps" @check="treeItemClick" node-key="id" :default-checked-keys="selectedIdListFromParent"
                                     ref="tree" show-checkbox></aicc-tree>
                    </div>
                </div>
            </sweet-col>
            <!-- 已选来电原因-->
            <sweet-col :span="12">
              <div class="with-must">
                <span class="must">*</span>
                <div class="resaonOpt-title with-must-line">
                  {{ $t('ccm.callreason.label.selectedreason') }}
                </div>
              </div>
                <div class="contentBox" :style="{height: contentBoxHeight}"
                     content-class="hlds-is-nesting" id="mark-min-hight">
                    <ul class="selectReasons">
                        <li v-for="(x, $index) in selectedList">
                            <span>{{ x.name }}</span>
                            <span :title="$t('ccm.agent.pageurls.delete')" size="" variant="default" class="delete">
                                <div class="icon_delete" :id="'reasonId_'+$index" @click="deleteItem(x,$index);"
                                     @mouseover="overDeleteIcon($index)" @mouseout="outDeleteIcon($index)"></div>
                            </span>
                        </li>
                    </ul>
                </div>
            </sweet-col>
        </div>

        <!-- 关联工单-->
        <div content-class="hlds-is-nesting" v-if="relateCases">
            <div class="resaonOpt-title">{{ $t('ccm.callreason.label.createrelatecase') }}</div>
            <div class="releated-case">
                <sweet-checkbox-group v-model="selectedBox" class="caseTypeBox">
                    <sweet-checkbox v-for="curData in checkboxData" :key="curData.value" :label="curData.value">{{ curData.label }}</sweet-checkbox>
                </sweet-checkbox-group>
            </div>
        </div>

        <!-- 备注-->
        <div class="resaonOpt-title">{{ $t('ccm.callreason.label.mark') }}</div>
        <div class="" content-class="hlds-is-nesting">
            <div class="">
              <sweet-form ref="ruleForm" :model="ruleForm" :rules="rules">
                <sweet-form-item prop="note">
                  <sweet-input v-model="ruleForm.note" resize="none" :rows="4" type="textarea" :placeholder="$t('ccm.callreason.label.input')"
                               class="paperview-input-text" />
                </sweet-form-item>
              </sweet-form>
            </div>
        </div>

        <template #footer>
            <span class="dialog-footer">
                <sweet-button @click="cancel('add')">{{$t('agentconsole.common.button.cancel')}}</sweet-button>
                <sweet-button @click="handleSave();" type="primary" >{{$t('ccmessaging.chat.richText.save')}}</sweet-button>
            </span>
        </template>

    </sweet-dialog>
</template>

<script>
import {
  checkCaseTypes, queryCallSerialNo,
  queryUserCallReason,
  queryWorkOrderByCode,
  updateContactExtTalkInfo
} from '@/views/ccmanagement/ccma-api/index'
    import $ from 'jquery';
    import {ElMessageBox} from 'element-plus';
import AiccElMessage from '@/utils/el-message';
import validate from "@/utils/validate";

    window.$ = $;
    window.jQuery = $;

    export default {
        name: 'EditCallReason',
        emits: ['sendcallreason'],
        props: {
            show: {
                type: Boolean,
                default: false
            },
            talkReasonObj: {
                type: String
            },
            callid: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                callSerialNumber: '',
                reasonTreeLeft: [],
                rightTreemap: {},
                resultModel: [],
                showMap: {},
                map: {},
                keyword: '',
                note: '',
                DroplistItems: [],
                selection: [],
                cachesSelection: [],
                selectionleft: [],
                cachesSelectionLeft: [],
                selectedList: [],
                isItemsShow: false,
                reasonTreeright: [],
                talkReasonObjFromParent: {},
                selectedIdListFromParent: [],
                checkboxData: [],
                selectedBox: [],
                caseTypeList: [],
                checkCaseTypeList: [],
                relateCases: false,
                defaultProps: {
                    children: 'children',
                    label: 'label'
                },
                contentBoxHeight: "300px",
                joinStr: window.isRTL ? "\u200F > " : " > ",
                ruleForm: {
                  note: ''
                },
                rules: {
                  note: [
                    {maxLength: 300, validator: validate.maxLengthValidator, trigger: ['blur', 'change']},
                  ],
                },
            }
        },
        computed: {
          validate() {
            return validate
          },
            dialogVisible: {
                get() {
                    return this.show
                },
                set(val) {
                    this.$emit('update:show', val)
                }
            }
        },
        methods: {
            dealData(data) {
                var list = this.resultModel.filter((item) => {
                    return item.reasonLevel == 1;
                });
                for (let i = 0; i < list.length; i++) {
                    this.map[list[i]] = list[i].name.split('</@*!');
                    var children = data.filter((item) => {
                        if (item.parentId == list[i].id) {
                            this.map[item.id] = item.name.split('</@*!');
                            this.dealChild(item);
                        }
                        return item.parentId == list[i].id;
                    });
                    if (children && children.length > 0) {

                    }
                }
            },

            assembly(data, resultModel, lastChildIdList, cachesSelection, selectedIdListFromParent) {
                if (data && resultModel.length > 0) {
                    data.children = resultModel.filter((item) => {
                        return item.parentId == data.id;
                    })
                }
                if (data.children && data.children.length > 0) {
                    for (let j = 0; j < data.children.length; j++) {
                        // 提取下层中已选来电原因
                        if (selectedIdListFromParent.indexOf(data.children[j].id) >= 0) {
                            cachesSelection.push(data.children[j].id);
                        }
                        this.assembly(data.children[j], resultModel, lastChildIdList, cachesSelection, selectedIdListFromParent);
                    }
                } else {
                    delete data.children;
                    lastChildIdList.push(data);
                }
            },

            outDeleteIcon($index) {
                const selectedReasonId = 'reasonId_' + $index;
                document.getElementById(selectedReasonId).className = "icon_delete";
            },

            overDeleteIcon($index) {
                const selectedReasonId = 'reasonId_' + $index;
                document.getElementById(selectedReasonId).className = "icon_delete_blue";
            },

            findParentNames(list, parentId, resultModel) {
                if (parentId && list && resultModel.length > 0) {
                    var result = resultModel.filter((item) => {
                        return item.id == parentId;
                    });

                    if (result && result[0]) {
                        list.push(result[0].name);
                        this.findParentNames(list, result[0].parentId, resultModel);
                    }
                }
            },

            query() {
                var lastChildIdList = [];
                var resultModel = [];
                var that = this;
                // 从父页面传回的已选来电原因（临时变量）用于assembly方法中遍历
                var selectedIdListFromParent = $.extend(true, [], this.selectedIdListFromParent);
                var cachesSelection = [];
                queryUserCallReason().then(data => {
                    if (data && data.length > 0) {
                        resultModel = data;
                        this.resultModel = data;
                        this.reasonTreeLeft = data.filter((item) => {
                            if (item.reasonLevel == 1) {
                                item.children = []
                            }
                            item.text = item.name.toString();
                            item.label = item.name.toString();
                            item.level = item.reasonLevel;
                            this.showMap[item.id] = item.name.split('</@*!');
                            this.findParentNames(this.showMap[item.id], item.parentId, resultModel);
                            return item.reasonLevel == 1;
                        })
                        this.reasonTreeLeft.sort((a, b) => {
                            return a.reasonIndex - b.reasonIndex;
                        })
                    }
                    if (this.reasonTreeLeft && this.reasonTreeLeft.length > 0) {
                        for (var i = 0; i < this.reasonTreeLeft.length; i++) {
                            this.assembly(this.reasonTreeLeft[i], resultModel, lastChildIdList, cachesSelection, selectedIdListFromParent)
                        }
                    }
                    this.selectionleft = [...this.selectedIdListFromParent];
                    this.queryBoxHandle();
                })
            },

            queryBoxHandle() {
                this.checkboxData = [];
                for (let i = 0; i < this.selectedList.length; i++) {
                    for (let j = 0; j < this.resultModel.length; j++) {
                        if (this.selectedList[i].key == this.resultModel[j].id && this.resultModel[j].associatedObjName) {
                            this.checkboxData.push({
                                "value": this.selectedList[i].key,
                                "label": this.resultModel[j].associatedObjName
                            })
                        }
                    }
                }
              // 关联工单去重
              let res = new Map();
              this.checkboxData = this.checkboxData.filter((a) => !res.has(a.label) && res.set(a.label, 1))
            },

            findParentToRoot(id, data, talkReasonNameList) {
                var currentData = data.find((item) => {
                    return item.id == id;
                });
                if (currentData) {
                    talkReasonNameList.push(currentData.name);
                    if (currentData.parentId != "0") {
                        var parentId = currentData.parentId;
                        this.findParentToRoot(parentId, data, talkReasonNameList);
                    }
                }
            },

            isShowCheck(data) {
                var flag = false;
                if (data && data.id) {
                    this.resultModel.filter((item) => {
                        if (item.parentId == data.id) {
                            flag = true;
                        }
                        return item.parentId == data.id;
                    })
                }
                return !flag;
            },

            doSearch(input, map) {
                this.resultModel.filter((item) => {
                    if (item.name.indexOf(input) != -1) {
                        map[item.id] = item.name.split('</@*!');
                        this.findParentName(item, map[item.id]);
                    }
                    return item.name.indexOf(input) != -1;
                })
            },

            findParentName(val, list = []) {
                this.resultModel.filter((item) => {
                    if (val.parentId == item.id) {
                        list.push(item.name);
                        this.findParentName(item, list);
                    }
                    return val.parentId == item.id;
                })
            },

            search(input) {
                this.keyword = input;
                this.isItemsShow = true;
                this.DroplistItems = [];
                if (!$.trim(input)) {
                    return;
                }
                var map = {};
                this.doSearch(input, map);
                for (var key in map) {
                    this.DroplistItems.push({
                        key: key,
                        name: map[key].reverse().join(this.joinStr)
                    })
                }
            },

            clickEvent() {
                const _self = this;
                $(function () {
                    $('body').on("click", function (e) {
                        if (e.target.id != "btn_comSearchAttrSearchButtonsearch") {
                            _self.isItemsShow = false;
                        }
                    })
                });
            },

            handleSave() {
              this.$refs['ruleForm'].validate(valid => {
                if (valid) {
                  this.save()
                }
              })
            },

            async save() {
                if (this.selectedList.length > 5) {
                    AiccElMessage.warning($t('ccm.agent.callreason.exceedmaxnum'));
                    return;
                }
                this.caseTypeList = [];
                if (this.selectedBox.length > 0) {
                    for (let i = 0; i < this.selectedBox.length; i++) {
                        for (let j = 0; j < this.resultModel.length; j++) {
                            if (this.selectedBox[i] == this.resultModel[j].id) {
                                this.caseTypeList.push({
                                    "caseTypeId": this.resultModel[j].associatedObjId,
                                    "caseTypeCode": this.resultModel[j].associatedObjType,
                                    "typeName": this.resultModel[j].associatedObjName
                                })
                            }
                        }
                    }
                }
                // 关联工单去重
                let res = new Map();
                this.caseTypeList = this.caseTypeList.filter((a) => !res.has(a.caseTypeId) && res.set(a.caseTypeId, 1))

                if (this.callid) {
                    await this.queryCallSerialNoMethod(this.callid);
                }
                var data = {
                    note: this.ruleForm.note,
                    list: this.selectedList,
                    caseList: this.caseTypeList,
                    relatedCase: this.relateCases
                };
                if (!data.list || data.list.length < 1) {
                  AiccElMessage.warning($t('ccm.agent.callreason.must'));
                  return ;
                }
                this.$emit('sendcallreason', data);
                if (this.callid) {
                    const result = this.saveReason(data);
                    if (result === 'false'){
                      return;
                    }
                    if (this.caseTypeList.length > 0 && this.relateCases == true) {
                        this.checkCaseType(this.caseTypeList);
                    } else {
                        AiccElMessage.success($t('agentconsole.callreason.tips.saved'))
                    }
                }
                this.dialogVisible = false;
            },

            async queryCallSerialNoMethod(callId) {
                let param = {
                    callId : callId
                };
                await queryCallSerialNo(param).then( data => {
                  if (data && data.returnCode == "0") {
                    this.callSerialNumber = data.returnResult;
                  }
                })
            },

            checkCaseType(caseTypeList) {
                checkCaseTypes(caseTypeList).then(data => {
                    if (data) {
                        this.checkCaseTypeList = data;
                        let missPages = '';
                        missPages = this.caseTypeList.filter(item=>this.checkCaseTypeList.findIndex(obj=>obj.caseTypeId===item.caseTypeId)===-1).map(item=>item.typeName).join(',');
                        if (missPages !== '') {
                            AiccElMessage.error($t('ccm.callreason.case.check.error', [missPages]))
                        }
                        const uri = window.location.protocol + "//" + window.location.host;
                        let detalUrl = uri + "/besBaas/baas/abc/foundation/index.html#/AICC__caseCreate";
                        for (let i = 0; i < this.checkCaseTypeList.length; i++) {
                            setTimeout(() => {
                                let detalUrls = "";
                                detalUrls = detalUrl + "?callTypeId=" + this.checkCaseTypeList[i].caseTypeId + "&callSerialno=" + this.callSerialNumber + "&remark=" + this.ruleForm.note;
                                top.window.$BES.$Portal.tabpanel.createTabItemByConf(this.checkCaseTypeList[i].caseTypeId + i, $t('casemanagement.casequery.button.create'), detalUrls);
                                if (i != 0) {
                                    top.window.$BES.$Portal.tabpanel.showTabItem(this.checkCaseTypeList[0].caseTypeId + 0);
                                }
                            }, i * 2000);
                        }
                    } else {
                        let missPages =  caseTypeList.map(item => item.typeName).join(',');
                        AiccElMessage.error($t('ccm.callreason.case.check.error', [missPages]))
                    }
                })
            },

            saveReason($Data) {
                var reasonName = '';
                var talkReason = '';
                for (var i = 0; i < $Data.list.length; i++) {
                    if (i == 0) {
                        talkReason += $Data.list[0].key;
                        reasonName += $Data.list[0].name.lastIndexOf(' >') > 0 ? $Data.list[0].name.substring($Data.list[0].name.lastIndexOf(' >') + 2) : $Data.list[0].name;
                    } else {
                        talkReason += ',' + $Data.list[i].key;
                        reasonName += ',' + ($Data.list[i].name.lastIndexOf(' >') > 0 ? $Data.list[i].name.substring($Data.list[i].name.lastIndexOf(' >') + 2) : $Data.list[i].name);
                    }

                }

                var req = {
                    callId: this.callid,
                    talkReason: talkReason,
                    talkRemark: $Data.note
                };
                if (!req.callId) {
                    return;
                }
                updateContactExtTalkInfo(req).then(data => {
                    if (data && data.returnCode == "0") {
                        var dataObj = {
                            talkReasonNameToRootList: $Data.list,
                            talkReason: talkReason,
                            talkRemark: $Data.note,
                            associatedCaseType: this.selectedBox
                        };
                        let callreasondata = {};
                        if (JSON.parse(sessionStorage.getItem("callreasondata"))) {
                            callreasondata = JSON.parse(sessionStorage.getItem("callreasondata"))
                        }
                        callreasondata[this.callid] = dataObj
                        sessionStorage.setItem("callreasondata", JSON.stringify(callreasondata));
                    }
                })
            },

            openParentNode(nodeData) {
                if (nodeData.reasonLevel <= 2) {
                    this.openNode(nodeData.id);
                } else {
                    var parentData = this.resultModel.find((item) => {
                        return item.id == nodeData.parentId;
                    });
                    this.openParentNode(parentData);
                }
            },

            clickItem(item, e) {
                this.isItemsShow = false;
                if (window.$("#" + item.key).length > 0) {
                    this.openNode(item.key);
                    return;
                }
                var nodeData;
                this.resultModel.filter((val) => {
                    if (val.id == item.key) {
                        nodeData = val;
                    }
                    return val.id == item.key;
                });
                if (nodeData) {
                    this.openParentNode(nodeData);
                    this.openNode(nodeData.id);
                }
            },

            deleteItem(item, index) {
                var key;
                if (this.selectedList.length > index) {
                    this.selectedList.splice(index, 1);
                    key = item.key;
                    var len = this.cachesSelection.indexOf(item.key);
                    if (len != -1) {
                        this.cachesSelection.splice(len, 1);
                    } else {
                        len = this.cachesSelectionLeft.indexOf(item.key);
                        if (len != -1) {
                            this.cachesSelectionLeft.splice(len, 1)
                        }
                    }

                    var list = this.resultModel.filter((item) => {
                        return item.id == key
                    })
                    //目前原因只支持选择一级不需要处理父级key
                }
                this.selection = $.extend(true, [], this.cachesSelection);
                var len1 = this.selectionleft.indexOf(key);
                if (len1 != -1) {
                    this.selectionleft.splice(len1, 1);
                    this.$refs.tree.setCheckedKeys(this.selectionleft, true);
                }
                var list1 = this.resultModel.filter(($item) => {
                    return $item.id == item.key
                })
                this.deleteCheckboxHandle()
            },

            deleteParentKey(id) {
                const that = this;
                if (id && window.$("#" + id).scope()) {
                    let sublingsItems = window.$("#" + id).scope().treeItem ? window.$("#" + id).scope().treeItem.children : [];
                    let isCheckedItem = sublingsItems.filter(($item) => {
                        return that.selectionleft.indexOf($item.id) != -1;
                    })
                    if (isCheckedItem.length == 0) {
                        var len1 = this.selectionleft.indexOf(id);
                        if (len1 != -1) {
                            this.selectionleft.splice(len1, 1);
                        }
                        if (id == '0') {
                            return
                        }
                        let treeItem = window.$("#" + id).scope().treeItem;
                        that.deleteParentKey(treeItem['parentId'])
                    }
                }
            },


            removeParentKey(key) {
                var list = this.resultModel.filter((item) => {
                    return (item.reasonLevel > 2 && item.id == key);
                });
                if (list && list.length > 0) {
                    var parentKey = list[0].parentId;
                    var len = this.cachesSelection.indexOf(key);
                    if (len > -1) {
                        this.cachesSelection.splice(len, 1);
                    }
                    let len1 = this.selectionleft.indexOf(key);
                    if (len1 != -1) {
                        this.selectionleft.splice(len1, 1);
                    }
                    this.removeParentKey(parentKey);
                }
            },
            //移除子元素的key
            removeChildKey($Data) {
                let that = this;
                for (let i = 0; i < $Data.length; i++) {
                    let len = this.selectionleft.indexOf($Data[i].id)
                    if (len != -1) {
                        this.selectionleft.splice(len, 1);
                    }
                    let child = $Data[i].children;
                    if (child) {
                        that.removeChildKey(child)
                    }
                }
            },

            // 添加父元素的key
            addParentKey(key) {
                let list = this.resultModel.filter((item) => {
                    return item.id == key;
                });
                if (list && list.length > 0) {
                    let parentKey = list[0].parentId;
                    let len1 = this.selectionleft.indexOf(key);
                    if (len1 == -1) {
                        this.selectionleft.push(key);
                    }
                    if (parentKey == '0') {
                        return;
                    }
                    this.addParentKey(parentKey);
                }
            },

            view(e) {
                this.reasonTreeright = this.rightTreemap[e.$Nodedata.id];

                this.selection = $.extend(true, [], this.cachesSelection);
            },

            //树的点击事件
            treeItemClick(data) {
                this.selectionleft = this.$refs.tree.getCheckedKeys(true); // 仅返回被选中的叶子节点的 keys
                let selectionLeftWithParentID = this.$refs.tree.getCheckedKeys(); // 叶子节点+父节点的 keys
                if (selectionLeftWithParentID.indexOf(data.id) !== -1) {
                    // 选中事件
                    this.nodeCheckedLeft(data);
                    this.addCheckboxHandle();
                } else {
                    // 取消选中事件
                    this.nodeUncheck(data);
                    this.deleteCheckboxHandle()
                }
            },

            // 查询工单特性，显示关联工单项
            queryWorkOrder() {
                queryWorkOrderByCode().then(data => {
                    if (data == 1) {
                        this.relateCases = true;
                        $('.popwin_middle').height('651px');
                        $('.popwin').height('700px');
                        this.contentBoxHeight = "180px";
                    }
                })
            },

            nodeChecked(e) {
                var id = e.$Nodedata.id;
                var result = [];
                if (!this.cachesSelection.includes(id)) {
                    this.cachesSelection.push(id);
                }
                this.findChildren(id, this.resultModel, result);

                if (result && result.length > 0) {
                    for (let j = 0; j < result.length; j++) {
                        var len = this.selectedList.filter(((item) => {
                            return item.key == result[j];
                        })).length;
                        if (this.showMap[result[j]] && len < 1) {
                            this.selectedList.push({
                                key: result[j],
                                name: $.extend(true, [], this.showMap[result[j]]).reverse().join(this.joinStr)
                            })
                        }
                    }
                }
            },

            addCheckboxHandle() {
                const cachCheckboxData = this.checkboxData;
                this.checkboxData = []
                for (let i = 0; i < this.selectedList.length; i++) {
                    for (let j = 0; j < this.resultModel.length; j++) {
                        if (this.selectedList[i].key == this.resultModel[j].id && this.resultModel[j].associatedObjName) {
                            this.checkboxData.push({
                                "value": this.selectedList[i].key,
                                "label": this.resultModel[j].associatedObjName
                            })
                        }
                    }
                    let isAdd = false;
                    for (let k = 0; k < cachCheckboxData.length; k++) {
                        if (this.selectedList[i].key == cachCheckboxData[k].value) {
                            isAdd = true;
                        }
                    }
                    if (isAdd) {
                        continue;
                    }
                    for (let n = 0; n < this.resultModel.length; n++) {
                        if (this.selectedList[i].key == this.resultModel[n].id && this.resultModel[n].associatedObjName) {
                            this.selectedBox.push(this.selectedList[i].key);
                        }
                    }
                }
                // 关联工单去重
                let res = new Map();
                this.checkboxData = this.checkboxData.filter((a) => !res.has(a.label) && res.set(a.label, 1))
            },

            deleteCheckboxHandle() {
                for (let k = 0; k < this.checkboxData.length; k++) {
                    let isAdd = false;
                    for (let i = 0; i < this.selectedList.length; i++) {
                        if (this.selectedList[i].key == this.checkboxData[k].value) {
                            isAdd = true;
                        }
                    }
                    if (isAdd) {
                        continue;
                    }
                    const checkboxDataValue = this.checkboxData[k].value;
                    let dataLen = -1;
                    if (this.checkboxData.length > 0 && checkboxDataValue) {
                        this.checkboxData.filter((item, j) => {
                            if (item.value == checkboxDataValue) {
                                dataLen = j;
                            }
                            return item.value == checkboxDataValue;
                        });
                    }
                    if (dataLen != -1) {
                        this.checkboxData.splice(dataLen, 1);
                    }
                    let boxLen = -1;
                    if (this.selectedBox.length > 0 && checkboxDataValue) {
                        this.selectedBox.filter((item, n) => {
                            if (item == checkboxDataValue) {
                                boxLen = n;
                            }
                            return item == checkboxDataValue;
                        });
                    }
                    if (boxLen != -1) {
                        this.selectedBox.splice(boxLen, 1);
                    }
                }
            },

            getCheckInfo(array, list) {
                const that = this;
                array.forEach(function (item) {
                  if (item.children && item.children.length > 0) {
                        that.getCheckInfo(item.children, list)
                    } else {
                        list.push(item.id)
                    }
                })
            },

            nodeCheckedLeft($Nodedata) {
                var result = [];
                const that = this;
                let data1 = $Nodedata;
                if (data1.children) {
                    this.getCheckInfo(data1.children, result);
                }
                if (result && result.length > 0) {
                    for (let j = 0; j < result.length; j++) {
                        let len = this.selectedList.filter(((item) => {
                            return item.key == result[j];
                        })).length;
                        if (len < 1) {
                            that.selectedList.push({
                                key: result[j],
                                name: $.extend(true, [], that.showMap[result[j]]).reverse().join(this.joinStr)
                            })
                        }
                    }
                } else {
                    let len = this.selectedList.filter(((item) => {
                        return item.key == data1.id;
                    })).length;
                    if (len < 1) {
                        this.selectedList.push({
                            key: data1.id,
                            name: $.extend(true, [], that.showMap[data1.id]).reverse().join(this.joinStr)
                        })
                    }
                }
            },

            findChildren(id, list, result) {
                var newList = list.filter((item) => {
                    return id == item.parentId;
                });

                if (newList && newList.length > 0) {
                    for (let i = 0; i < newList.length; i++) {
                        var child = list.filter((item) => {
                            return newList[i].id == item.parentId;
                        });
                        if (child && child.length > 0) {
                            this.findChildren(newList[i].id, list, result);
                        } else {
                            result.push(newList[i].id);
                        }
                    }
                } else {
                    result.push(id);
                }
            },

            nodeUncheck($Nodedata) {
                let id = $Nodedata.id;

                let result = [];
                let data1 = $Nodedata;
                if (data1.children) {
                    this.getCheckInfo(data1.children, result);
                    this.removeChildKey(data1.children);
                }
                if (result && result.length > 0) {
                    for (let j = 0; j < result.length; j++) {
                        var index = -1;
                        this.selectedList.filter((item, k) => {
                            if (item.key == result[j]) {
                                index = k;
                            }
                            return item.key == result[j];
                        });
                        if (index > -1) {
                            this.selectedList.splice(index, 1);
                        }
                    }
                } else {
                    var index = -1;
                    this.selectedList.filter((item, k) => {
                        if (item.key == data1.id) {
                            index = k;
                        }
                        return item.key == data1.id;
                    });
                    if (index > -1) {
                        this.selectedList.splice(index, 1);
                    }
                }
                this.deleteCheckboxHandle();
            },

            cancel() {
                this.dialogVisible = false;
            },

            openNode(id) {
                var timer = setInterval(() => {
                    if (window.$("#" + id).length > 0) {
                        window.$("#" + id).find('.jstree-icon').click();
                        clearInterval(timer);
                    }
                }, 100)
            },

            initData() {
                // 解析从父页面传回的来电原因信息
                if (this.talkReasonObj) {
                    this.talkReasonObjFromParent = this.talkReasonObj;
                    if (this.talkReasonObjFromParent.talkReason) {
                        this.selectedIdListFromParent = this.talkReasonObjFromParent.talkReason.split(",");
                        this.selectionleft = this.talkReasonObjFromParent.talkReason.split(",");
                    }
                    if (this.talkReasonObjFromParent.talkRemark) {
                        this.ruleForm.note = this.talkReasonObjFromParent.talkRemark;
                    }
                    if (this.talkReasonObjFromParent.talkReasonNameToRootList) {
                        this.selectedList = [...this.talkReasonObjFromParent.talkReasonNameToRootList];
                    }
                }
                if (this.callid) {
                    let data = JSON.parse(sessionStorage.getItem("callreasondata"));
                    if (data && data[this.callid]) {
                        this.talkReasonObjFromParent = data[this.callid];
                    }
                    if (this.talkReasonObjFromParent.talkReason) {
                        this.selectedIdListFromParent = this.talkReasonObjFromParent.talkReason.split(",");
                        this.selectionleft = this.talkReasonObjFromParent.talkReason.split(",");
                    }
                    if (this.talkReasonObjFromParent.talkRemark) {
                        this.ruleForm.note = this.talkReasonObjFromParent.talkRemark;
                    }
                    if (this.talkReasonObjFromParent.talkReasonNameToRootList) {
                        this.selectedList = [...this.talkReasonObjFromParent.talkReasonNameToRootList];
                    }
                    if (this.talkReasonObjFromParent.associatedCaseType) {
                        this.selectedBox = [...this.talkReasonObjFromParent.associatedCaseType];
                    }
                }
                this.queryWorkOrder();
                this.query();
            },
        },
        mounted() {
            this.initData();
            // 给textarea框限定输入长度限制
            $("#noteInput").find("textarea").attr("maxlength", "300");
            $("#noteInput").find("textarea").addClass("marktext");
        }
    }
</script>

<style lang="less" scoped>
    .caseTypeBox{

    }
    .el-textarea{
        --el-input-border-color:rgba(221,221,221,1);
    }
    .paperview-input-text {
        -webkit-appearance: none;
        background-color: #fff;
        background-image: none;
        border-radius: 4px;
        border: 0px;
        width: 100%;
    }

    .resaonOpt-title {
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #212121;
        letter-spacing: 0;
        font-weight: 400;
        height: 30px;
        display: table-cell;
        vertical-align: middle;
    }

    .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .edit-callreason {
        box-sizing: border-box;
        padding: 20px;
    }

    .resaonOpt {
        display: flex;
        margin-bottom: 10px;
    }

    .resaonOpt > div {
        flex: 1;
    }

    .allreason {
        margin-inline-end: 10px;
    }

    .marktext {
        margin-inline-start: -2%;
    }

    #viewtreeleft {
        min-height: 10rem;
    }

    #viewtreeright {
        min-height: 10rem;
    }

    #mark-min-hight {
        min-height: 120px !important;
        margin-top: 0px;
    }

    .hlds-border--border {
        border: 1px solid #e1e3e5;
        height: 18rem;
    }

    .select_min_height {
        min-height: 5rem;
    }

    .search_drop_width {
        width: 100%
    }

    .hlds-form-element {
        margin: 0 !important;
    }

    .hlds-drop-items {
        min-height: 3rem;
    }

    .jstree-default .jstree-unchecked > a > .jstree-checkbox {
        background: url("@/views/ccmanagement/assets/image/checkbox.png") left top !important;
    }

    .jstree-default .jstree-checked > a > .jstree-checkbox {
        background: url("@/views/ccmanagement/assets/image/checkbox.png") right top !important;
    }

    .tree_container .jstree-default .jstree-undetermined > a > .jstree-checkbox {
        background: url("@/views/ccmanagement/assets/image/checkbox-part-16.png") no-repeat left top;

    }

    .tree_container .jstree-default .jstree-no-dots .jstree-open > ins {
        background: url("@/views/ccmanagement/assets/image/ic_open.png") -9px -9px !important;
    }

    .tree_container .jstree-default .jstree-no-dots .jstree-closed > ins {
        background: url(@/views/ccmanagement/assets/image/ic_close.png) -10px -9px !important;
    }

    .hlds-border-right {
        border-right: 1px solid #e1e3e5;
        width: 30%;
    }

    .hlds-grid--pull-around {
        margin: 0;
    }

    .display_block {
        display: inline-block;
    }

    .ml_70 {
        width: 70%
    }

    .delete_btn {
        cursor: pointer;
    }

    th.th_head a.hlds-th__action.hlds-text-link--reset {
        height: 0;
    }

    .table_height {
        max-height: 9rem;
    }

    .table_height tr.hlds-hint-parent.hlds-table-tr--level-0 {
        height: 2.125rem;
    }

    .jstree-default .jstree-no-dots .jstree-closed.jstreeleaf0.jstreelazyfalse > ins {
        background-image: none !important;
    }

    .hlds-p-vertical--small {
        padding-top: 0rem !important;
        padding-bottom: 0.5rem !important;
    }

    .icon_delete_blue {
        background: url("@/views/ccmanagement/assets/image/icon_delete_blue.png") no-repeat;
        width: 1rem;
        height: 1rem;
        cursor: pointer;
    }

    .icon_delete {
        background: url("@/views/ccmanagement/assets/image/icon_delete.png") no-repeat;
        width: 1rem;
        height: 1rem;
        cursor: pointer;
    }

    #mark-min-hight .hlds-table--bordered {
        border: 1px solid #e1e3e5;
    }

    .selectReasons li {
        line-height: 24px;
        float: left;
        width: 100%;
    }

    .selectReasons li .delete {
        float: right;
        margin-top: 4px;
    }

    body.RTL .selectReasons li .delete {
        float: left;
        margin-top: 4px;
    }

    .resaonOpt .contentBox {
        border: 1px solid rgba(221, 221, 221, 1) !important;
        border-radius: 8px;
        overflow: auto;
    }

    .resaonOpt .el-card__body {
        padding: 0px;
    }

    .resaonOpt .selectReasons {
        box-sizing: border-box;
        padding: 20px 10px 20px 20px;
    }

    .hlds-self .popwin .popwin_iframe {
        padding: 0 !important;
    }

    .popwin .popwin_iframe_inner .hlds-section__title div > span {
        font-size: 14px;
        color: #212121;
        font-weight: 400;
        height: 20px;
        line-height: 20px;
    }

    .hlds-form-element__control {
        border-radius: 8px;
    }

    .component__button {
        border-radius: 4px;
        height: 32px;
        line-height: 32px;
    }

    .hlds-tree .hlds-button.hlds-icon-common-open {
        background: url(@/views/ccmanagement/assets/image/ic_close.png) -10px -9px !important;
    }

    .hlds-tree .hlds-button.hlds-icon-common-closed {
        background: url("@/views/ccmanagement/assets/image/ic_open.png") -9px -9px !important;
    }

    .hlds-tree .hlds-is-selected {
        background: none;
        border: 0 !important;
    }
    .dialog-footer :deep(.el-button) {
        margin-inline-start: var(--el-dialog-padding-primary);
    }
    .releated-case {
        border: 1px solid rgba(221, 221, 221, 1);
        height: 94px;
        border-radius: 8px;
        overflow-y: auto;
        padding: 0 20px;
        margin-bottom: 10px;
    }
    .dialog-footer {
        display: flex;
        flex: 1;
        justify-content: flex-end;
    }

    .must {
      box-sizing: border-box;
      color: rgb(223, 52, 55);
      height: 30px;
      line-height: 30px;
    }

    .with-must {
      display: flex;
      flex-direction: row;
      align-items: center
    }

    .with-must-line {
      line-height: 30px;
    }
</style>