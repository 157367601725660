import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

/** 技能相关Pinia的初始化函数 */
const skillStatusStoreSetup = () => {
  const updateTime = ref<number | null>(null)
  const refresh = () => (updateTime.value = new Date().getTime()) // 更新技能时间戳信息
  const timestamps = computed(() => updateTime.value)
  return { updateTime, refresh, timestamps }
}

/** 技能队列状态更新Pinia */
export const useSkillQueueStatusStore = defineStore('skillQueueStatusStore', skillStatusStoreSetup)

/** 技能组状态更新Pinia */
export const useSkillGroupStatusStore = defineStore('skillGroupStatusStore', skillStatusStoreSetup)
