<template>
    <sweet-dialog :append-to-body="true"
                  :title="$t('oifde.audioVideoResource.preview')"
                  v-model="previewDialogVisible" width="616px"
                  class="aicc-dialog-limit-height"
                  :close-on-click-modal="false">
        <sweet-tabs v-model="languageTabPane" type="border-card">
            <div v-for="(item, index) in voiceInfo.fileMessage" :key="index">
                <sweet-tab-pane :label="item.langName" :name="item.languageId">
                    <div v-if="voiceInfo.type===0" class="div-audio" style="height:300px">
                        <sweet-form-item :label="$t('ccivr.ivr.voicereview.filePath')" v-if="voiceInfo.status === 2" style="margin-bottom: 5px">
                            <span>{{item.filePathDisplay}}</span>
                        </sweet-form-item>
                        <aicc-audio
                                v-if="ActiveTab == item.tabTitle"
                                ref="aiccAudio"
                                :src="item.src"
                                :canDownLoad="false"
                                :canClose="false"
                                class="audio-width"
                        />
                    </div>
                    <div v-else-if="voiceInfo.type===3" style="height:300px">
                        <sweet-form-item :label="$t('ccivr.ivr.voicereview.filePath')" v-if="voiceInfo.status === 2" style="margin-bottom: 5px">
                            <span>{{item.filePathDisplay}}</span>
                        </sweet-form-item>
                        <img :src="item.src" class="div-image" style="max-width:500px;max-height:280px">
                    </div>
                    <div v-else-if="voiceInfo.type===2" style="height:300px">
                        <span>{{item.textcontent}}</span>
                        <div v-if="voiceInfo.scene === 3" style="margin-top:15px">
                            <sweet-form-item :label="$t('ccivr.ivr.voice.voicename')">
                                <sweet-select :disabled="true" v-model="voiceInfo.voicename" :fit-input-width="true"
                                              :show-resize="false" :placeholder="$t('oifde.common.select')">
                                    <sweet-option v-for="item in this.dataDictList" :key="item.value"
                                                  :label="item.label" :value="item.value"/>
                                </sweet-select>
                            </sweet-form-item>
                            <sweet-form-item :label="$t('ccivr.ivr.voice.speed')">
                                <sweet-input-number :disabled="true" v-model="voiceInfo.speed" :min="-200" :max="500"
                                                    :precision="0" :step="10"/>
                                <span>&nbsp;&nbsp;&nbsp;{{ $t('oifde.audio.speedRange') }}</span>
                            </sweet-form-item>
                            <sweet-form-item :label="$t('ccivr.ivr.voice.volume')">
                                <sweet-input-number :disabled="true" v-model="voiceInfo.volume" :min="0" :max="100"
                                                    :precision="0" :step="5"/>
                                <span>&nbsp;&nbsp;&nbsp;{{ $t('oifde.template.defaultValueValidate.ttsVolumeValueDesc') }}</span>
                            </sweet-form-item>
                        </div>
                    </div>
                    <div v-else-if="voiceInfo.type===1 || voiceInfo.type===4" style="height:300px">
                        <sweet-form-item :label="$t('ccivr.ivr.voicereview.filePath')" v-if="voiceInfo.status === 2" style="margin-bottom: 5px">
                            <span>{{item.filePathDisplay}}</span>
                        </sweet-form-item>
                        <video :src="item.src"
                               v-if="ActiveTab == item.tabTitle"
                               class="vedio"
                               controls="controls"
                               controlslist="nodownload"
                               disablepictureinpicture
                               style="padding-right: 1.5rem;height:300px;width:570px"
                        />
                    </div>
                </sweet-tab-pane>
            </div>
        </sweet-tabs>
    </sweet-dialog>
    <sweet-dialog :title="$t('oifde.audioVideoResource.preview')" v-model="updatePreviewDialogVisible" width="616px" class="aicc-dialog-limit-height"
                  :close-on-click-modal="false">
        <sweet-tabs v-model="showingPreviewTabPane" style="height:480px">
            <sweet-tab-pane :label="$t('oifde.audioVideoResource.updateBefore')" name="first">
                <sweet-tabs v-model="languageTabPane" type="border-card"
                            class="aicc-m-top--medium aicc-background-white">
                    <div v-for="(item, index) in beforeVoiceInfo.fileMessage" :key="index">
                        <sweet-tab-pane :label="item.langName" :name="item.languageId">
                            <div v-if="beforeVoiceInfo.type===0" class="div-audio" style="height:300px">
                                <sweet-form-item :label="$t('ccivr.ivr.voicereview.filePath')" v-if="beforeVoiceInfo.status === 2" style="margin-bottom: 5px">
                                    <span>{{item.filePathDisplay}}</span>
                                </sweet-form-item>
                                <aicc-audio
                                        v-if="ActiveTab == item.tabTitle"
                                        ref="aiccAudio"
                                        :src="item.src"
                                        :canDownLoad="false"
                                        :canClose="false"
                                />
                            </div>
                            <div v-else-if="beforeVoiceInfo.type===3" style="height:300px">
                                <sweet-form-item :label="$t('ccivr.ivr.voicereview.filePath')" v-if="beforeVoiceInfo.status === 2" style="margin-bottom: 5px">
                                    <span>{{item.filePathDisplay}}</span>
                                </sweet-form-item>
                                <img :src="item.src" class="div-image" style="max-width:500px;max-height:280px">
                            </div>
                            <div v-else-if="beforeVoiceInfo.type===2" style="height:300px">
                                <span>{{item.textcontent}}</span>
                            </div>
                            <div v-else-if="beforeVoiceInfo.type===1 || voiceInfo.type===4" style="height:300px">
                                <sweet-form-item :label="$t('ccivr.ivr.voicereview.filePath')" v-if="beforeVoiceInfo.status === 2" style="margin-bottom: 5px">
                                    <span>{{item.filePathDisplay}}</span>
                                </sweet-form-item>
                                <video :src="item.src"
                                       v-if="ActiveTab == item.tabTitle"
                                       class="vedio"
                                       controls="controls"
                                       controlslist="nodownload"
                                       disablepictureinpicture
                                       style="padding-right: 1.5rem;height:300px;width:570px"
                                />
                            </div>
                        </sweet-tab-pane>
                    </div>
                </sweet-tabs>
            </sweet-tab-pane>
            <sweet-tab-pane :label="$t('oifde.audioVideoResource.updateAfter')" name="second" style="height:480px">
                <sweet-tabs v-model="languageTabPane" type="border-card"
                            class="aicc-m-top--medium aicc-background-white">
                    <div v-for="(item, index) in voiceInfo.fileMessage" :key="index">
                        <sweet-tab-pane :label="item.langName" :name="item.languageId">
                            <div v-if="voiceInfo.type===0" class="div-audio" style="height:300px">
                                <sweet-form-item :label="$t('ccivr.ivr.voicereview.filePath')" v-if="voiceInfo.status === 2" style="margin-bottom: 5px">
                                    <span>{{item.filePathDisplay}}</span>
                                </sweet-form-item>
                                <aicc-audio
                                        v-if="ActiveTab == item.tabTitle"
                                        ref="aiccAudio"
                                        :src="item.src"
                                        :canDownLoad="false"
                                        :canClose="false"
                                />
                            </div>
                            <div v-else-if="voiceInfo.type===3" style="height:280px">
                                <sweet-form-item :label="$t('ccivr.ivr.voicereview.filePath')" v-if="voiceInfo.status === 2" style="margin-bottom: 5px">
                                    <span>{{item.filePathDisplay}}</span>
                                </sweet-form-item>
                                <img :src="item.src" class="div-image" style="max-width:500px;max-height:300px">
                            </div>
                            <div v-else-if="voiceInfo.type===2" style="height:300px">
                                <span>{{item.textcontent}}</span>
                            </div>
                            <div v-else-if="voiceInfo.type===1 || voiceInfo.type===4" style="height:300px">
                                <sweet-form-item :label="$t('ccivr.ivr.voicereview.filePath')" v-if="voiceInfo.status === 2" style="margin-bottom: 5px">
                                    <span>{{item.filePathDisplay}}</span>
                                </sweet-form-item>
                                <video :src="item.src"
                                       v-if="ActiveTab == item.tabTitle"
                                       class="vedio"
                                       controls="controls"
                                       controlslist="nodownload"
                                       disablepictureinpicture
                                       style="padding-right: 1.5rem;height:300px;width:570px"
                                />
                            </div>
                        </sweet-tab-pane>
                    </div>
                </sweet-tabs>
            </sweet-tab-pane>
        </sweet-tabs>
    </sweet-dialog>
</template>

<script>
    import i18n from '@/lang'
    import {
        queryDataDict, queryIvrVoiceById,
        queryLanguage, queryUpdateIvrInfo
    } from "@oifde/request/api/audioVideoResource";
    const $t = i18n.global.t
    const languageItem = [{
        "languageName": $t('ccivr.ivr.voicereview.chinese'),
        "languageId": "0"
    }, {
        "languageName": $t('ccivr.ivr.voicereview.english'),
        "languageId": "1"
    }, {
        "languageName": $t('ccivr.ivr.voicereview.thailand'),
        "languageId": "24"
    }, {
        "languageName": $t('ccivr.ivr.voicereview.portuguese'),
        "languageId": "17"
    }, {
        "languageName": $t('ccivr.ivr.voicereview.spanish'),
        "languageId": "20"
    }, {
        "languageName": $t('ccivr.ivr.voicereview.french'),
        "languageId": "7"
    }, {
        "languageName": $t('ccivr.ivr.voicereview.arabic'),
        "languageId": "81"
    }];
    const ivrTypeItem = [{
        "label": $t('ccivr.ivr.voicereview.voice'),
        "value": "0"
    }, {
        "label": $t('ccivr.ivr.voicereview.text'),
        "value": "2"
    }, {
        "label": $t('ccivr.ivr.voicereview.vedio'),
        "value": "1"
    }];
    const sceneItem = [{
        "label": "IVR",
        "value": "0"
    }, {
        "label": $t('ccivr.ivr.voice.scene.note'),
        "value": "1"
    }, {
        "label": $t('ccivr.ivr.voice.scene.error'),
        "value": "2"
    }, {
        "label": $t('ccivr.ivr.voice.scene.voicenotification'),
        "value": "3"
    }, {
        "label": $t('ccivr.ivr.voice.scene.questionnaires'),
        "value": "4"
    }, {
        "label": $t('ccivr.ivr.voice.scene.beforeReportWorkNo'),
        "value": "5"
    }, {
        "label": $t('ccivr.ivr.voice.scene.afterReportWorkNo'),
        "value": "6"
    }];
    const reviewTypeItem = [{
        "label": $t('ccivr.ivr.voicereview.nosubmit'),
        "value": "0"
    }, {
        "label": $t('ccivr.ivr.voicereview.waitreview'),
        "value": "1"
    }, {
        "label": $t('ccivr.ivr.voicereview.passed'),
        "value": "2"
    }, {
        "label": $t('ccivr.ivr.voicereview.notpass'),
        "value": "3"
    }];

    export default {
        name: 'audioVideoPreview',
        props: {
            show: {
                type: Boolean,
                default: false
            },
            updateshow: {
            type: Boolean,
            default: false
            },
            id: String,
        },
        data() {
            return {
                voiceInfo: {},
                beforeVoiceInfo: {},
                searchLang: {},
                langResult: {},
                searchObjectContent: {},
                uploadTextInfo: {},
                confirmResult: {},
                //删除文本记录
                deleteTextResult: {},
                //播放
                fileName: undefined,
                basewebName: undefined,
                audio_path: undefined,
                recordid: undefined,
                languageId: undefined,
                // 防止重复提交
                repeatClick: false,
                // 查询更新文件信息
                queryUpdateIvrInfoResult: {
                    id: undefined,
                    languageId: undefined,
                    uploaddate: undefined,
                    status: 0,
                    voicecontent: undefined,
                    auditcomments: undefined,
                },
                updateId: undefined,
                showingPreviewTabPane: 'first',
                languageTabPane: undefined,
                fileMessage: [],
                dataDictList: [],
            }
        },
        computed: {
            previewDialogVisible: {
                get() {
                    return this.show
                },
                set(val) {
                    this.$emit('update:show', val)
                }
            },
          updatePreviewDialogVisible: {
            get() {
              return this.updateshow
            },
            set(val) {
              this.$emit('update:updateshow', val)
            }
          }
        },
        async created() {
            this.seachLanguage()
            if(this.updatePreviewDialogVisible){
              this.queryIvrVoiceById(true)
            }else{
              this.queryIvrVoiceById(false)
            }
            this.getVoicename()
        },
        methods: {
            async seachLanguage() {
                await queryLanguage(this.searchLang).then(response => {
                    this.langResult = response
                })
            },
            async getVoicename() {
                queryDataDict().then(response => {
                    for (let i = 0; i < response.length; i++) {
                        this.dataDictList.push({
                            'label': response[i].itemName,
                            'value': response[i].DataDict.itemCode
                        });
                    }
                })
            },
            async queryIvrVoiceById(flag) {
                    this.voiceInfo = await queryIvrVoiceById({id: this.id})
                    let voicecontent = this.voiceInfo.voicecontent;
                    if (voicecontent) {
                        let contentData;
                        if (window.$.trim(voicecontent) != "") {
                            if (typeof voicecontent == "string") {
                                contentData = JSON.parse(voicecontent);
                            }
                        }
                        this.voiceInfo.fileMessage = [];
                        for (let j in contentData) {
                            let langId = contentData[j].languageId;
                            contentData[j].langName = this.getlangNameByLangId(langId)
                            this.voiceInfo.fileMessage.push(contentData[j]);
                        }
                        if (this.voiceInfo.fileMessage.length > 0) {
                            this.languageTabPane = this.voiceInfo.fileMessage[0].languageId
                            for (let i = 0; i < this.voiceInfo.fileMessage.length; i++) {
                                let fileName = this.voiceInfo.fileMessage[i].filename;
                                let filePath = this.voiceInfo.fileMessage[i].filepath;
                                this.voiceInfo.fileMessage[i]['filePathDisplay'] = filePath + '/' + fileName
                                if (this.voiceInfo.type === 0) {
                                    this.voiceInfo.fileMessage[i]['src'] = '/oifde/rest/cc-ivr/play/voice?recordId=' + this.voiceInfo.id + '&languageId=' + this.voiceInfo.fileMessage[i].languageId
                                } else if (this.voiceInfo.type === 1 || this.voiceInfo.type === 4) {
                                    this.voiceInfo.fileMessage[i]['src'] = '/oifde/rest/cc-ivr/play/video?recordId=' + this.voiceInfo.id + '&languageId=' + this.voiceInfo.fileMessage[i].languageId
                                } else if (this.voiceInfo.type === 3) {
                                  this.voiceInfo.fileMessage[i]['src'] = '/oifde/rest/cc-ivr/play/picture?recordId=' + this.voiceInfo.id + '&languageId=' + this.voiceInfo.fileMessage[i].languageId
                                }
                            }
                        }
                    }
                  if(flag){
                      this.beforeVoiceInfo = await queryIvrVoiceById({id: this.voiceInfo.relVoiceId})
                      let voicecontent = this.beforeVoiceInfo.voicecontent;
                      if (voicecontent) {
                        let contentData;
                        if (window.$.trim(voicecontent) != "") {
                          if (typeof voicecontent == "string") {
                            contentData = JSON.parse(voicecontent);
                          }
                        }
                        this.beforeVoiceInfo.fileMessage = [];
                        for (let j in contentData) {
                          let langId = contentData[j].languageId;
                          contentData[j].langName = this.getlangNameByLangId(langId)
                          this.beforeVoiceInfo.fileMessage.push(contentData[j]);
                        }
                        if (this.beforeVoiceInfo.fileMessage.length > 0) {
                          this.languageTabPane = this.beforeVoiceInfo.fileMessage[0].languageId
                          for (let i = 0; i < this.beforeVoiceInfo.fileMessage.length; i++) {
                            let fileName = this.beforeVoiceInfo.fileMessage[i].filename;
                            let filePath = this.beforeVoiceInfo.fileMessage[i].filepath;
                            this.beforeVoiceInfo.fileMessage[i]['filePathDisplay'] = filePath + '/' + fileName
                            if (this.beforeVoiceInfo.type === 0) {
                              this.beforeVoiceInfo.fileMessage[i]['src'] = '/oifde/rest/cc-ivr/play/voice?recordId=' + this.beforeVoiceInfo.id + '&languageId=' + this.beforeVoiceInfo.fileMessage[i].languageId
                            } else if (this.beforeVoiceInfo.type === 1 || this.voiceInfo.type === 4) {
                              this.beforeVoiceInfo.fileMessage[i]['src'] = '/oifde/rest/cc-ivr/play/video?recordId=' + this.beforeVoiceInfo.id + '&languageId=' + this.beforeVoiceInfo.fileMessage[i].languageId
                            } else if (this.beforeVoiceInfo.type === 3) {
                              this.beforeVoiceInfo.fileMessage[i]['src'] = '/oifde/rest/cc-ivr/play/picture?recordId=' + this.beforeVoiceInfo.id + '&languageId=' + this.beforeVoiceInfo.fileMessage[i].languageId
                            }
                          }
                        }
                        for (let i = 0; i < this.beforeVoiceInfo.fileMessage.length; i++) {
                          let index = this.voiceInfo.fileMessage.findIndex(item=>item.languageId==this.beforeVoiceInfo.fileMessage[i].languageId)
                          if(index==-1){
                            this.voiceInfo.fileMessage.push(this.beforeVoiceInfo.fileMessage[i])
                          }
                        }
                      }
                  }
            },
            openText(modelData, jEvent) {
                let index = jEvent.$Target.attr("index");
                modelData.fileMessage[index].textModalState = true;
            },

            createGrayRule(modelData, jEvent) {
                let index = jEvent.$Target.attr("index");
                modelData.fileMessage[index].textModalState = false;
            },

            /**
             * 点开表格扩展，查询更新文件信息
             * @param data  参数
             */
            openDataClick(data) {
                if (!data.open) {
                    return;
                }
                data.updateFilemess = JSON.parse(JSON.stringify(data.fileMessage));
                // 补充必需数据
                for (let record in data.updateFilemess) {
                    data.updateFilemess[record].relVoiceId = data.id
                    data.updateFilemess[record].type = data.type
                    data.updateFilemess[record].id = null;
                    data.updateFilemess[record].originStatus = data.status; // 原文件状态
                }
                // 文本类型不支持更新
                if (data.type == 2) {
                    return
                }
                queryUpdateIvrInfo(data.id).then(repsonse => {
                    this.queryUpdateIvrInfoResult = resposne
                    if (!this.queryUpdateIvrInfoResult) {
                        return
                    }
                    data.updateFilemess[0].updateTime = $TimeUtils.transTime(this.queryUpdateIvrInfoResult.uploaddate)
                    data.updateFilemess[0].auditcomments = this.queryUpdateIvrInfoResult.auditcomments
                    data.updateFilemess[0].status = this.queryUpdateIvrInfoResult.status
                    data.updateFilemess[0].ops = true
                    // 获取对应语言的状态名称
                    let status = "" + this.queryUpdateIvrInfoResult.status;
                    let idx = reviewTypeItem.findIndex(function (item) {
                        return item.value == status;
                    });
                    if (-1 != idx) {
                        data.updateFilemess[0].statusName = reviewTypeItem[idx].label;
                    }

                    // 获取语言
                    let voicecontent = this.queryUpdateIvrInfoResult.voicecontent;
                    if (voicecontent) {
                        let contentData;
                        if (window.$.trim(voicecontent) != "") {
                            if (typeof voicecontent == "string") {
                                try {
                                    contentData = JSON.parse(voicecontent);
                                } catch (error) {
                                    console.error(error)
                                }
                            }
                        }
                        for (let j in contentData) {
                            let langId = contentData[j].languageId;
                            let flag = false;

                            for (let record in data.updateFilemess) {
                                // 1. 更新文件的语种在原文件中存在则替换；通过id不为null标识
                                if (langId == data.updateFilemess[record].languageId) {
                                    data.updateFilemess[record].id = this.queryUpdateIvrInfoResult.id
                                    flag = true;
                                    break;
                                }
                            }
                            // 2. 更新文件的语种为新增语种则添加；通过relVoiceId为null标识
                            if (!flag) {
                                let tmpData = contentData[j]
                                tmpData.langName = this.getlangNameByLangId(langId)
                                tmpData.id = this.queryUpdateIvrInfoResult.id
                                tmpData.type = data.type
                                data.updateFilemess.push(tmpData);
                            }
                            // 3. 原文件语种未在更新文件时则保持不变；通过id为null标识
                        }
                        for (let record in data.updateFilemess) {
                            data.updateFilemess[record].showUpdateFile = true;
                            if (data.updateFilemess[record].id == null) {
                                data.updateFilemess[record].id = this.queryUpdateIvrInfoResult.id;
                                data.updateFilemess[record].showUpdateFile = false;
                            }
                        }
                    }
                })
            },

            /**
             * 通过语言ID获取对应名称
             * @param langId
             */
            getlangNameByLangId(langId) {
                if (langId || 0 == langId) {
                    langId = langId + '';
                    let idx = languageItem.findIndex(function (item) {
                        return item.languageId == langId;
                    });
                    if (-1 != idx) {
                        return languageItem[idx].languageName;
                    }
                }
            },
        }
    }
</script>
<style scoped>
    .div-audio {
        margin: 24px 6px 2px 6px;
    }
    .div-image {
        object-fit:contain;
    }
</style>