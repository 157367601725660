export default {
  'oifde.traceCause.000001':'Der Weiterleitungstyp ist nicht für das Element des Anrufweiterleitungsdiagramms konfiguriert.',
  'oifde.traceCause.000002':'Die Schnittstelle ist anormal, da der Eingabewert der Untätigkeit falsch ist. {0}',
    'oifde.traceCause.000003':'Das Element des zusammengesetzten Diagramms wurde nicht gefunden.',
    'oifde.traceCause.000004':'Der Startknoten wurde nicht gefunden.',
    'oifde.traceCause.000005':'Die Anzahl der internen Wiederverwendungen überschreitet den Grenzwert.',
    'oifde.traceCause.000006':'Die Diagrammelementverarbeitung ist anormal.',
    'oifde.traceCause.000007':'Der Ausgangszweig ist leer.',
    'oifde.traceCause.000008':'Interner Systemfehler.',
    'oifde.traceCause.000009':'Der Zweig wurde nicht gefunden. {0}',
    'oifde.traceCause.000010':'Das Element des Flussübertragungsdiagramms kann nicht zum nächsten Knoten übertragen werden. {0}',
    'oifde.traceCause.000011':'Der Diagrammelementtyp ist falsch.',
    'oifde.traceCause.000012':'Ausführungsfehler bei Ausdruck {0}.',
    'oifde.traceCause.000013':'Die Drittanbieter-Schnittstelle konnte nicht ausgeführt werden.',
    'oifde.traceCause.000014':'Die Antwortvorlage und die Variable wurden nicht ausgewählt.',
    'oifde.traceCause.000015':'Die SMS-Vorlage ist nicht für das SMS-Sendediagrammelement konfiguriert.',
    'oifde.traceCause.000016':'Die Sprache der Antwortvorlage stimmt nicht mit der festgelegten Sprache überein. {0}',
    'oifde.traceCause.000017':'Die Sprache der SMS-Vorlage unterscheidet sich von der Sprache, die im SMS-Sendediagrammelement festgelegt wurde. {0}',
    'oifde.traceCause.000018':'Der Ziel-Flow und der Zielknoten sind nicht für das Flussübertragungsdiagrammelement konfiguriert.',
    'oifde.traceCause.000019':'Es wurde kein Mieter gefunden.',
    'oifde.traceCause.000020':'Der Mieter wurde suspendiert.',
    'oifde.traceCause.000021':'Das Modul wurde mehr als einmal initialisiert.',
    'oifde.traceCause.000022':'Das Element des zusammengesetzten Diagramms hat keinen Startknoten.',
    'oifde.traceCause.000023':'Interner Systemfehler.',
    'oifde.traceCause.000024':'Die Ausführung des internen Flusses ist anormal.',
    'oifde.traceCause.000025':'Das automatische Auflegen wird ausgeführt, wenn interne Ausnahmen mehr als dreimal auftreten.',
    'oifde.traceCause.000026':'Der Flow ist nicht initialisiert.',
    'oifde.traceCause.000028':'Der Fluss ist beendet.',
    'oifde.traceCause.000029':'Im aktuellen Diagrammelement mit der Knoten-ID {0} und dem Knotennamen {1} wurde kein Ausgang gefunden.',
    'oifde.traceCause.000030':'Daten des Diagrammelements mit der Knoten-ID {0} und dem Knotennamen {1} sind anormal.',
    'oifde.traceCause.000031':'Interner Systemfehler.',
    'oifde.traceCause.000032':'Die Ausführung des internen Flusses ist anormal.',
    'oifde.traceCause.000033':'Die Anrufinstanzinformationen sind nicht vorhanden.',
    'oifde.traceCause.000034':'Das Anrufende-Diagrammelement existiert nicht.',
    'oifde.traceCause.000035':'Bei der Überprüfung von auth_token ist ein Fehler aufgetreten.',
    'oifde.traceCause.000036':'Bei der Überprüfung von auth_token ist ein Fehler aufgetreten.',
    'oifde.traceCause.000037':'Bei der Überprüfung von auth_token ist ein Fehler aufgetreten.',
    'oifde.traceCause.000038':'Der nächste Knoten konnte nicht verarbeitet werden.',
    'oifde.traceCause.000039':'Es ist nicht gelungen, den TUC anzurufen. {0}',
    'oifde.traceCause.000040':'Der Fehler wurde nicht gefunden. {0}',
    'oifde.traceCause.000041':'Ausführungsfehler der gespeicherten Prozedur. {0}',
    'oifde.traceCause.000044':'Die URL (IP-Adresse und Portnummer) befindet sich nicht in der Vertrauensliste.',
    'oifde.traceAdvice.000001':'Konfigurieren Sie den Anrufweiterleitungstyp für das Element des Anrufweiterleitungsdiagramms.',
    'oifde.traceAdvice.000002':'Wenden Sie sich an den Systemadministrator, um Eingabeparameter der Schnittstelle zu ändern.',
    'oifde.traceAdvice.000003':'Überprüfen Sie, ob das zusammengesetzte Diagrammelement im System vorhanden ist.',
    'oifde.traceAdvice.000004':'Überprüfen Sie, ob der Fluss freigegeben ist oder ob der Fluss normal ist.',
    'oifde.traceAdvice.000005':'Wenden Sie sich an den Systemadministrator, um das System zu überprüfen.',
    'oifde.traceAdvice.000006':'Wenden Sie sich an den Systemadministrator, um zu überprüfen, ob die Logik des Diagrammelements korrekt ist.',
    'oifde.traceAdvice.000007':'Wenden Sie sich an den Systemadministrator, um zu überprüfen, ob die Logik des Diagrammelements korrekt ist.',
    'oifde.traceAdvice.000008':'Wenden Sie sich an den Systemadministrator, um das System zu überprüfen.',
    'oifde.traceAdvice.000009':'Überprüfen Sie, ob der Ausgang des aktuellen Diagrammelements unverarbeitete Zweige aufweist. Es wird empfohlen, dass alle Diagrammelemente einen bedingungslosen Standardzweig haben.',
    'oifde.traceAdvice.000010':'Überprüfen Sie, ob das Element des Flussübertragungsdiagramms richtig konfiguriert ist.',
    'oifde.traceAdvice.000011':'Wenden Sie sich an den Systemadministrator, um das System zu überprüfen.',
    'oifde.traceAdvice.000012':'Überprüfen Sie, ob die für den Ausdruck konfigurierte Syntax korrekt ist und ob die Variablen im Ausdruck Werte haben.',
    'oifde.traceAdvice.000013':'Überprüfen Sie, ob die Drittanbieter-Schnittstelle normal ist.',
    'oifde.traceAdvice.000014':'Überprüfen Sie das Antwortdiagrammelement, um sicherzustellen, dass mindestens eine der Antwortvorlage oder Antwortvariablen einen Wert aufweist.',
    'oifde.traceAdvice.000015':'Überprüfen Sie, ob die SMS-Vorlage für das SMS-Sendediagrammelement konfiguriert ist.',
    'oifde.traceAdvice.000016':'Überprüfen Sie den Inhalt und die Sprachkonfiguration in der Ressourcenvorlage, die integrierte Sprache in der globalen Variablen und ob der Sprache in der Flussorchestrierung ein neuer Wert zugewiesen wird. Die Sprache für die Wiedergabe muss in der Ressourcenvorlage konfiguriert werden.',
    'oifde.traceAdvice.000017':'Überprüfen Sie den Inhalt und die Sprachkonfiguration in der Ressourcenvorlage, die integrierte Sprache in der globalen Variablen und ob der Sprache in der Flussorchestrierung ein neuer Wert zugewiesen wird. Die Sprache für die Wiedergabe muss in der Ressourcenvorlage konfiguriert werden.',
    'oifde.traceAdvice.000018':'Überprüfen Sie, ob das Element des Flussübertragungsdiagramms richtig konfiguriert ist.',
    'oifde.traceAdvice.000019':'Überprüfen Sie, ob der von der Dialogschnittstelle übertragene Zugangscode normal ist und im System vorhanden ist.',
    'oifde.traceAdvice.000020':'Überprüfen Sie, ob der Mieterstatus normal ist.',
    'oifde.traceAdvice.000021':'Überprüfen Sie, ob die Drittanbieter-App oder das IVR wiederholt eine Initialisierungsanfrage mit derselben Anruf-ID sendet</b>.',
    'oifde.traceAdvice.000022':'Überprüfen Sie, ob das zusammengesetzte Diagrammelement normal ist und im System vorhanden ist.',
    'oifde.traceAdvice.000023':'Wenden Sie sich an den Systemadministrator, um das System zu überprüfen.',
    'oifde.traceAdvice.000024':'Überprüfen Sie die Fehlerinformationen jedes Diagrammelements und korrigieren Sie die Fehler entsprechend.',
    'oifde.traceAdvice.000025':'Wenden Sie sich an den Systemadministrator, um das System zu überprüfen.',
    'oifde.traceAdvice.000026':'Der Hinrichtungsfluss ist anormal. Sie müssen zuerst den Aufruf der Initialisierungsschnittstelle anfordern.',
    'oifde.traceAdvice.000028':'Überprüfen Sie, ob der Fluss normal ist.',
    'oifde.traceAdvice.000029':'Überprüfen Sie, ob ein Knotenausgang für das Diagrammelement konfiguriert ist.',
    'oifde.traceAdvice.000030':'Wenden Sie sich an den Systemadministrator, um zu überprüfen, ob die Konfiguration des Diagrammelements korrekt ist.',
    'oifde.traceAdvice.000031':'Wenden Sie sich an den Systemadministrator, um das System zu überprüfen.',
    'oifde.traceAdvice.000032':'Überprüfen Sie die Fehlerinformationen jedes Diagrammelements und korrigieren Sie die Fehler entsprechend.',
    'oifde.traceAdvice.000033':'Überprüfen Sie, ob die Schnittstelle für die Auflegeanforderung die falsche Anruf-ID sendet</b>.',
    'oifde.traceAdvice.000034':'Überprüfen Sie, ob das Anrufende-Diagrammelement für den Flow richtig konfiguriert ist.',
    'oifde.traceAdvice.000035':'Überprüfen Sie, ob das eingegebene auth_token korrekt ist.',
    'oifde.traceAdvice.000036':'Überprüfen Sie, ob das eingegebene auth_token korrekt ist.',
    'oifde.traceAdvice.000037':'Überprüfen Sie, ob das eingegebene auth_token korrekt ist.',
    'oifde.traceAdvice.000038':'Wenden Sie sich an den Systemadministrator, um das System zu überprüfen.',
    'oifde.traceAdvice.000039':'1. Überprüfen Sie, ob die im ODFS konfigurierte TUC-Adresse und der Port normal sind. 2. Prüfen Sie, ob die Dienste auf jedem TUC-Knoten normal sind.',
    'oifde.traceAdvice.000040':'Überprüfen Sie, ob die gespeicherte Prozedur normal ist.',
    'oifde.traceAdvice.000041':'Überprüfen Sie, ob die gespeicherte Prozedur normal ist.',
    'oifde.traceAdvice.000044':'Wenden Sie sich an den Systemadministrator, um es der Vertrauensliste hinzuzufügen.',

}
