<template>
  <sweet-dialog v-model="dialogVisible" width="616px"
                :close-on-click-modal="false" destroy-on-close :modal="!isSink">
    <template #header>
      <div class="aicc-back-header">
        <i class="back-btn" v-if="isSink" @click="dialogVisible = false"></i>
        <span>{{ $t('ccm.calledroute.title.selectskill') }}</span>
      </div>
    </template>
    <aicc-advanced-search ref='skillQueueSearchRef' :templateMetaData="templateMetaData"
                          @handleRefresh="searchSkill" @handleSearch="searchSkill"
                          @orgTreeSelectChange="searchSkill">
    </aicc-advanced-search>
    <aicc-table
      :tableData="showModel"
      :height="378.428"
      :tableColumns="tableColumns"
      :tableTools="{ showPagination: true, columnConfig: false }"
      :paginationConfig="paginationConfig"
      @handlePagination="handlePagination"
      ref='selectskillRef'>
    </aicc-table>
    <template #footer>
          <span class="dialog-footer">
            <sweet-button @click="dialogVisible = false">{{ $t('ccm.calledroute.button.cancel') }}</sweet-button>
            <sweet-button type="primary" @click="save">{{ $t('aicc.ok') }}</sweet-button>
          </span>
    </template>
  </sweet-dialog>
</template>
<script>

import AiccElMessage from '@/utils/el-message';
import $ from 'jquery';
import AiccAdvancedSearch from "@/components/aicc-advancedSearch/index.vue";
import {getUserAccess} from "@/utils/organization";
import {getAllSkillList} from "@/views/ccmanagement/ccma-api";
import {SkillInfo} from "@/views/ccmanagement/entity/SkillInfo.entity";

window.$ = $;
window.jQuery = $;

export default {
  name: 'SkillQueryPop',
  components: {AiccAdvancedSearch},
  props: {
    mediaTypeId: {
      type: String,
      default: ''
    },
    skillid: {
      type: String,
      default: ''
    },
    deviceType: {
      type: String,
      default: ''
    },
    selectedOrgId: {
      type: String,
      default: ''
    },
    selectedOrgName: {
      type: String,
      default: ''
    },
    iscalled: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
      default: false
    },
    isSink: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      }
    },
    templateMetaData (){
      return  {
        basicConfig: {
          basicSearch: [
            {
              searchType: 'primary',
              modelValue: 'searchContent',
              modelDefault: '',
              placeholderValue: this.$t('ccm.skill.label.skillname'),
              rules: [],
              colConfig: {
                lg: 11,
                xl: 11
              },
            },
            {
              searchType: 'orgTreeSelect', // 组织选择树
              modelValue: 'orgIds',
              treeOptions: this.orgTreeList,
              placeholderValue: this.$t("SM.ORGSTAFF.LABEL.ORGANIZATION"),
              multiple: true
            }
          ],
          iconButton: [
            {
              buttonType: 'refresh',
              eventName: 'handleRefresh',
              buttonTitle: $t('ccm.agent.button.refresh')
            }
          ],
          iconAreaColConfig: {
            sm: 2,
            lg: 2,
            xl: 2
          },
        }
      }

    }
  },
  data() {
    const selectedSkill = new SkillInfo()
    return {
      selectedSkill,
      orgTreeList: [],
      tableColumns: [
        {
          type: 'radio',
          radioKey: 'id', // 单选绑定的字段名
          width: '50px',
          'show-overflow-tooltip': false
        },
        {
          label: this.$t('ccm.skill.label.skillname'),
          prop: 'name',
        },
        {
          label: this.$t('ccm.skill.label.skilldesc'),
          prop: 'description',
        },
        {
          label: this.$t('ccm.skill.label.maxwaittime'),
          prop: 'maxWaitTime',
        },
        {
          label: this.$t('ccm.skill.label.maxwaitnum'),
          prop: 'maxWaitNum',
        }
      ],
      paginationConfig: {
        pageSizes: [10, 20, 50, 100], // 每页条数列表
        pageSize: 10, // 每页条数
        total: 0, //总条数
        layout: "sizes, total, prev, pager, next" // 分页组件布局配置
      },
      limit: 10,
      offset: 0,
      resultModel : [],
      searchObjectContent: {},
      showModel : []
    }
  },
  methods: {
    querySkill() {
      let param = {};
      param['mediaTypeId'] = this.mediaTypeId;
      this.getAllSkillListCommon(param);
    },

    getAllSkillListCommon(param) {
      getAllSkillList(param).then(data => {
        this.resultModel = data;
        this.showModel = [];
        this.paginationConfig.total = this.resultModel.length;
        for (var i = 0; i < this.resultModel.length; i++) {
          this.resultModel[i]["index"] = i + 1;
        }
        var count = this.paginationConfig.pageSize;
        if (this.resultModel.length < count) {
          count = this.resultModel.length;
        }
        for (var j = 0; j < count; j++) {
          this.showModel[j] = this.resultModel[j];
        }
      })
    },

    searchSkill() {
      this.searchObjectContent = {};
      let basicSearchParams = this.$refs.skillQueueSearchRef.getFormatParams();
      if (basicSearchParams.searchContent) {
        this.searchObjectContent["name"] = basicSearchParams.searchContent;
      }
      if (this.mediaTypeId != "") {
        this.searchObjectContent["mediaTypeId"] = this.mediaTypeId;
      }
      if (this.skillid != "") {
        this.searchObjectContent["skillId"] = this.skillid;
      }
      let orgIdsSearch = basicSearchParams.orgIds;
      if (orgIdsSearch) {
        // 过滤掉"无组织机构"的数据
        this.searchObjectContent["orgIdList"] = orgIdsSearch.filter(function (item) {
          return item != '0'
        });
        // 标记是否勾选"无组织机构"
        this.searchObjectContent["noOrgFlag"] = (orgIdsSearch.indexOf('0') != -1);
        this.searchObjectContent["orgFlag"] = (orgIdsSearch.indexOf('1') != -1);
      }
      this.getAllSkillListCommon(this.searchObjectContent);
    },


    save() {
      this.selectedSkill = this.$refs.selectskillRef.getRadioCurrentRow();
      if (!this.selectedSkill || !this.selectedSkill.skillId) {
        AiccElMessage.info($t('ccnotification.message.choose'));
        return;
      }
      let popups = $('.popwin');
      let popid = popups.eq(popups.length - 1).data('popid');
      if (null != this.deviceType) {
        let sendParam = {};
        sendParam["deviceType"] = this.deviceType;
        sendParam["skillParam"] = this.selectedSkill;
        this.$emit("selectConfigSkill", sendParam);
      } else {
        this.$emit("selectSkill." + popid, this.selectedSkill);
        this.$emit("selectSkill", this.selectedSkill);
      }
      this.selectedSkill = new SkillInfo();
      this.resultModel = [];
      this.dialogVisible = false;
    },

    /**
     * 分页事件处理函数
     */
    handlePagination(params) {
      this.showModel = [];
      const start = params.offset;
      const end = Math.min(start + params.limit, this.paginationConfig.total);
      for (let i = start; i < end; ++i) {
        this.showModel.push(this.resultModel[i]);
      }
    },

    getOrgInfo() {
      this.noOrgFlag = item.orgCond.noOrgFlag;
      this.orgFlag = item.orgCond.orgFlag;
    },

    reset() {
      this.searchContent = "";
      this.noOrgFlag = "false";
      this.orgFlag = "false"
    },

    queryOrgInfo() {
      getUserAccess().then((data) => {
        this.orgTreeList = data;
      })
    },

    onInit() {
      if (this.selectedOrgId && this.selectedOrgName) {
        this.orgFlag = "true";
      } else if ("true" == this.iscalled) {
        this.noOrgFlag = "false";
      } else {
        // selectedorgId 为空说明是选了无归属组织
        this.noOrgFlag = "true";
      }
      this.querySkill();
    }
  },
  mounted() {
    this.queryOrgInfo();
    this.onInit();
  }
}
</script>
<style lang="less" scoped>
@import "@/views/ccmanagement/css/common.less";

.search-skill {
  margin-bottom: 16px;
}

.input-icon {
  cursor: pointer;
  transition: all 0.2s;

  &:active {
    transform: scale(0.9);
  }
}
</style>