<template>
  <div class="aicc-table" :class="{ 'image-style': isMirrorLanguage }">
    <div v-if="tableToolCheckedInfo" class="aicc-table__checkedInfo aicc-align--absolute-left">
      <!-- 已选择 {{ checkedNum }} 条 -->
      <span>{{ $t('aicc.selectedInfo', { n: checkedNum }) }}</span>
      <!-- 取消 -->
      <sweet-button v-if="checkedNum !== 0" type="text" @click="handleCheckedCancel">{{
        $t('cms.recorddrive.label.button_cacel')
      }}</sweet-button>
    </div>
    <div class="aicc-is-relative" :class="{ 'select-disable': selectDisable }">
      <!-- table-layout='auto' -->
      <sweet-table
        v-bind="$attrs"
        v-if="getTableColumns && getTableColumns?.length"
        :data="tableData"
        :border="tableBorder"
        ref="tableRef"
        tooltip-effect="light"
        highlight-current-row
        @selection-change="handleSelectionChange"
        @select-all="toggleAllSelection"
      >
        <template
          v-for="(item, index) in tableColumnsDisplay"
          :key="item.prop + item.label || index"
        >
          <sweet-table-column
            v-if="item.display"
            :type="item['type']"
            :label="item['label']"
            :prop="item['prop']"
            :column-key="item['column-key']"
            :width="item['width']"
            :min-width="setColumnWidth(item, item['min-width'], index)"
            :fixed="handleFixedFlip(item['fixed'], item['type'])"
            :render-header="item['render-header']"
            :sortable="item['sortable']"
            :sort-method="item['sort-method']"
            :sort-by="item['sort-by']"
            :sort-orders="item['sort-orders']"
            :resizable="item['resizable']"
            :formatter="item['formatter'] || defaultFormatter"
            :show-overflow-tooltip="setColumnToolTip(item)"
            :align="alignDirection"
            :class-name="item['class-name']"
            :label-class-name="item['label-class-name']"
            :selectable="item['selectable']"
            :reserve-selection="item['reserve-selection']"
            :filters="item['filters']"
            :filter-placement="item['filter-placement']"
            :filter-multiple="item['filter-multiple']"
            :filter-method="item['filter-method']"
            :filtered-value="item['filtered-value']"
          >
            <!-- 表头文本 -->
            <template #header>
              <div class="aicc-table-label" :class="{ 'is-required': item.isRequired }">
                {{ item.label }}
              </div>
            </template>
            <!-- 单选 radio -->
            <template #default="scope" v-if="item.type === 'radio'">
              <sweet-radio
                v-model="tableRadio"
                :disabled="scope.row[item.radioDisabled]"
                :label="scope.row[item.radioKey] || scope.$index"
                @click.prevent="
                  handleRadioChange(
                    scope.row,
                    scope.row[item.radioKey],
                    scope.row[item.radioDisabled]
                  )
                "
                >{{ '' }}
              </sweet-radio>
            </template>
            <!-- 插槽列 -->
            <template #default="scope" v-else-if="item.slot">
              <div
                :ref="
                  (refItem) => (slotRefDic[item.slot + '-' + index + '-' + scope.$index] = refItem)
                "
              >
                <slot :name="item.slot" v-bind="scope"></slot>
              </div>
              <div v-if="slotEmptyDic[item.slot + '-' + index + '-' + scope.$index]">-</div>
            </template>
          </sweet-table-column>
        </template>
        <!-- 空数据 插槽  -->
        <template #empty>
          <slot name="empty"></slot>
        </template>
        <!-- 折叠列插槽 -->
        <template #append>
          <slot name="append"></slot>
        </template>
      </sweet-table>
      <!-- 列配置入口 -->
      <div v-if="tableToolColumnConfig" class="aicc-table__tool" @click="handleHeaderFilter" @mouseover="isColumnSetIconHover = true" @mouseleave="isColumnSetIconHover = false">
        <!-- 表头设置 -->
        <sweet-tooltip effect="light" :content="$t('aicc.columnSettings')" placement="top">
          <template v-if="isColumnSetIconHover">
            <img src="@/assets/img/table/operation1.png" />
          </template>
          <template v-else>
            <img src="@/assets/img/table/operation.png" />
          </template>
        </sweet-tooltip>
      </div>
    </div>
    <!-- 分页组件 -->
    <div v-if="tableToolShowPagination" class="aicc-table__pagination">
      <sweet-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="paginationObj.currentPage"
        :page-sizes="paginationPageSizes"
        :pager-count="paginationPagerCount"
        :page-size="paginationObj.pageSize"
        :layout="paginationLayout"
        :total="paginationTotal"
      >
      </sweet-pagination>
    </div>
    <!-- 列配置 弹出窗 -->
    <AiccTableHeaderTools
      :isMirrorLanguage="isMirrorLanguage"
      @handleFilterColumn="handleFilterColumn"
      ref="toolsRef"
    >
    </AiccTableHeaderTools>
  </div>
</template>

<script setup>
import {computed, reactive, ref, toRefs, watch} from 'vue'
import AiccTableHeaderTools from './AiccTableHeaderTools.vue'
import appConfig from '@/config'

const props = defineProps([
  'tableColumns',
  'tableData',
  'data',
  'tableTools',
  'paginationConfig',
  'isNotReverseColumn'
]) // 增加data, 是为了拦截外部直接传data导致内部的tableData不生效
const { tableColumns, tableData, tableTools, paginationConfig, isNotReverseColumn } = toRefs(props)

const emit = defineEmits([
  'handleFilterColumn',
  'handlePagination',
  'radio-change',
  'rowSelectionChange'
])

// 默认格式化内容的方法
const defaultFormatter = (row, column, cellValue, index) => {
  if (
    cellValue === null ||
    cellValue === undefined ||
    cellValue === '' ||
    String(cellValue).trim() === ''
  ) {
    return '-'
  } else {
    return cellValue
  }
}

const slotRefDic = reactive({})
const slotEmptyDic = reactive({})

watch(
  () => slotRefDic,
  (newValue, oldValue) => {
    Object.keys(newValue).forEach((key) => {
      const setEmptyFunc = () => {
        if (slotRefDic[key] && slotRefDic[key].innerHTML.trim() === '') {
          slotEmptyDic[key] = true
        } else {
          slotEmptyDic[key] = false
        }
      }
      setEmptyFunc()
      if (slotRefDic[key]) {
        new MutationObserver((mutationsList, observer) => {
          setEmptyFunc()
        }).observe(slotRefDic[key], { attributes: true, childList: true, subtree: true })
      }
    })
  },
  { deep: true }
)

// 自动计算表格列宽
const setColumnWidth = (column, minWidth, index) => {
  let span = document.createElement('span')
  let placeholderWidth = 30
  if (tableToolColumnConfig.value) {
    if (tableColumnsDisplay.value.length === index + 1 || column.fixed === 'right')
      placeholderWidth = 50
  }
  span.innerText = column.isRequired ? column.label + '* ' : column.label
  let clientWidth = document.body.clientWidth
  document.body.appendChild(span)
  span.style.fontSize = clientWidth >= 1919 ? '14px' : '12px'
  let columnWidth = span.getBoundingClientRect().width + placeholderWidth
  if (column.sortable) {
    columnWidth += 15
  }
  document.body.removeChild(span)
  return Math.max(columnWidth, parseFloat(minWidth) || 0)
}

// 表格展示列
const tableColumnsDisplay = computed(() => {
  let result = []
  getTableColumns.value.map((item) => {
    if (item.display) {
      result.push(item)
    }
  })
  return result
})

// 设置列内容 ToolTip
const setColumnToolTip = (column) => {
  // 默认展示 ToolTip
  let result = true
  // 优先使用传入值
  if (column['show-overflow-tooltip'] !== undefined) {
    result = column['show-overflow-tooltip']
  }
  // checkbox 选择列 不展示ToolTip
  if (column['type'] === 'selection' || column['type'] === 'radio') {
    result = false
  }
  return result
}

const tableRadio = ref()
let radioCurrentRow = {}
// 单选
const handleRadioChange = (row, radioValue, disabled) => {
  if (disabled) return
  radioCurrentRow = row
  tableRadio.value = radioValue
  emit('radio-change', { row, tableRadio: tableRadio.value })
}

// 设置单选回显
const setRadioCurrentValue = (value) => {
  tableRadio.value = value
  let radioKey = getTableColumns.value.find((item) => item.type === 'radio').radioKey
  radioCurrentRow = tableData.value.find((item) => item[radioKey] === value)
}

// 获取单选选中数据
const getRadioCurrentRow = () => {
  const radioKey = getTableColumns.value.find((item) => item.type === 'radio').radioKey
  const res = {}
  res[radioKey] = tableRadio.value
  return radioCurrentRow || res
}

// 表格工具
const tableToolColumnConfig = computed(() => {
  return tableTools.value?.columnConfig === undefined ? true : tableTools.value?.columnConfig
})

const tableToolCheckedInfo = computed(() => {
  return tableTools.value?.checkedInfo || false
})

const tableBorder = computed(() => {
  let borderFlag = tableTools.value?.borderFlag
  return borderFlag === undefined || borderFlag;
})

const tableToolShowPagination = computed(() => {
  return tableTools.value?.showPagination || false
})

// 分页
// 每页条数列表
const paginationPageSizes = computed(() => {
  return paginationConfig.value?.pageSizes || appConfig.pagination.regular.pageSizes
})

const paginationPageSize = computed(() => {
  return paginationConfig.value?.pageSize || 10
})

const paginationPagerCount = computed(() => {
  return paginationConfig.value?.pagerCount || appConfig.pagination.regular.pagerCount
})

const paginationTotal = computed(() => {
  return paginationConfig.value?.total || appConfig.pagination.regular.total
})

const paginationLayout = computed(() => {
  return paginationConfig.value?.layout || appConfig.pagination.regular.layout
})

const paginationObj = reactive({
  currentPage: 1,
  pageSize: paginationPageSize.value
})

// 操作分页 数据回显前 禁止操作选择
const selectDisable = ref(false)
const disabledSelect = () => {
  selectDisable.value = true
}

const handleCurrentChange = (page) => {
  disabledSelect()
  paginationObj.currentPage = page
  emit('handlePagination', handleFormatPage.value)
}

const setCurrentPage = (page) => {
  paginationObj.currentPage = page
}

const handleSizeChange = (size) => {
  disabledSelect()
  paginationObj.pageSize = size
  paginationObj.currentPage = 1
  emit('handlePagination', handleFormatPage.value)
}

const handleInitChange = () => {
  paginationObj.currentPage = 1
  paginationObj.pageSize = paginationPageSize.value
  emit('handlePagination', handleFormatPage.value)
}

const handleFormatPage = computed(() => {
  let limit = paginationObj.pageSize
  let offset = (paginationObj.currentPage - 1) * paginationObj.pageSize
  return { limit, offset, pageSize: paginationObj.pageSize, pageNum: paginationObj.currentPage }
  // limit/pageSize：每页数据量；offset：偏移量；pageNum：第几页
})

// 判断是否为镜像语言
const isMirrorLanguage = computed(() => {
  return window.isRTL
})

// 表头是否已翻转过
const isTableHeaderReversed = ref(false)

const filterHeader = ref([])
// 表头数据处理
const getTableColumns = computed(() => {
  if (filterHeader.value.length) {
    if (isMirrorLanguage.value) {
      handleRecursiveFlip(filterHeader.value)
      return filterHeader.value
    } else {
      return filterHeader.value
    }
  }
  let tableHeader = tableColumns?.value || []
  tableHeader.forEach((item, index) => {
    if (item.type && !item.fixed) {
      tableHeader[index].fixed = true
    }
    tableHeader[index].display =
      tableHeader[index].display === undefined ? true : tableHeader[index].display
  })
  if (isMirrorLanguage.value) {
    handleRecursiveFlip(tableHeader)
    return tableHeader
  } else {
    return tableHeader
  }
})

// 表头翻转
const handleRecursiveFlip = (tableHeader) => {
  if (isTableHeaderReversed.value === true) {
    //  若表头已做过翻转，不再进入
    return
  }
  if (tableHeader?.children) {
    handleRecursiveFlip(tableHeader.children)
  } else {
    if (!isNotReverseColumn.value) {
      tableHeader?.reverse()
    }
  }
  isTableHeaderReversed.value = true
  handleMirrorTable()
}

// 固定列翻转
const handleFixedFlip = (value) => {
  const baseValue = [true, 'left', 'right']
  if (!value) {
    return false
  }
  // 镜像语言需要翻转固定列
  if (isMirrorLanguage.value && baseValue.includes(value) && !isNotReverseColumn.value) {
    let aspect = value === true || value === 'left' ? 'right' : 'left'
    return aspect
  } else {
    return value
  }
}

const tableRef = ref()
const alignDirection = ref('left')
// 镜像处理
const handleMirrorTable = () => {
  if (!isMirrorLanguage.value) {
    return false
  }
  if (isMirrorLanguage.value) {
    alignDirection.value = 'right'
    // 水平滚动条滚动到最右侧
    setTimeout(() => {
      tableRef.value?.setScrollLeft(10000)
    })
  }
}

// 打开列配置弹窗
const toolsRef = ref()
const handleHeaderFilter = () => {
  toolsRef.value?.handleOpen(getTableColumns.value)
}

// 列表配置
const handleFilterColumn = (filterColumn) => {
  filterHeader.value = (filterColumn || []).slice(0)
  emit('handleFilterColumn', filterColumn)
}

// 列配置图标是否hover
const isColumnSetIconHover = ref(false)

// 选中数据信息
const checkedNum = ref(0)
const handleSelectionChange = (selection) => {
  emit('rowSelectionChange', selection)
  checkedNum.value = selection.length
}

// 取消选中
const handleCheckedCancel = () => {
  tableRef.value?.clearSelection()
}

const toggleAllSelection = () => {
  if (selectDisable.value) tableRef.value?.clearSelection()
  emit('toggleAllSelection')
}

watch(
  () => props.tableData,
  (newValue, oldValue) => {
    selectDisable.value = false
  }
)

watch(
  () => props.tableColumns,
  (newValue, oldValue) => {
    if (filterHeader.value.length) {
      filterHeader.value = []
    }
  }
)

// 设置全选状态
const setSelectDisable = (status) => {
  selectDisable.value = status
}

defineExpose({
  table: tableRef,
  handleInitChange,
  getRadioCurrentRow,
  setRadioCurrentValue,
  setSelectDisable,
  setCurrentPage,
  handleSizeChange
})
</script>

<style lang="less" scoped>
.aicc-table {
  // 该组件的镜像单独处理
  direction: ltr !important;
  position: relative;

  // 分页操作过程中 全选框 置灰处理
  .select-disable {
    :deep(th .el-checkbox__input .el-checkbox__inner) {
      opacity: 0.3;

      &:hover {
        border: 1px solid #2e2e2e;
      }
    }
  }

  :deep(.el-table__header-wrapper .el-table__header thead th:last-of-type > .cell) {
    padding-right: 40px;
    box-sizing: content-box;
  }

  :deep(.el-table__inner-wrapper .el-table__header-wrapper .cell) {
    display: flex !important;
    align-items: center !important;
  }

  :deep(.el-table__inner-wrapper .el-table__header-wrapper .cell .caret-wrapper) {
    min-width: 22px;
  }

  &__checkedInfo {
    font-size: 14px;
    height: 32px;

    span {
      padding-right: 10px;
    }
  }

  &__header {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__tool {
    position: absolute;
    right: 1px;
    top: 1px;
    z-index: 999;
    width: 24px;
    height: 30px;
    display: flex;
    background: #f9f9f9;
    align-items: center;
    justify-content: center;

    img {
      height: 24px;
      cursor: pointer;
      transition: all 0.1s linear;
    }
  }

  &.image-style {
    :deep(.el-scrollbar__bar.is-vertical) {
      left: 2px;
    }

    :deep(.el-table__inner-wrapper .cell) {
      direction: rtl !important;
    }

    .aicc-table__checkedInfo {
      direction: rtl !important;

      span {
        padding-right: 0px;
        padding-left: 10px;
      }
    }
  }
}

body.RTL .aicc-table {
  .aicc-table__pagination {
    direction: rtl !important;
  }

  &__tool {
    right: unset;
    left: 1px;
  }

  :deep(.el-table__header-wrapper .el-table__header thead th:last-of-type > .cell) {
    padding-right: var(--swt-space-size-12);
  }
}

.aicc-table-label.is-required::before {
  content: '* ';
  color: red;
}
</style>
