import request from '@/utils/request'


export const recordplayUrl = '/service-cloud/u-route/isales/recordplay?' //放音

/**
 * 查询回铃音列表
 * @param data
 * @returns {*}
 */
export const queryRbtrecognitionList = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/rbtrecognition/query',
        method: 'post',
        data
    })
}

export const createCustomerRbt = data => {
    return request({
        url: '/service-cloud/rest/isales/v1/rbtrecognition/update',
        method: 'post',
        data
    })
}

export const queryRecordInfoUrl = data => {
    return request({
        url: '/service-cloud/u-route/isales/data/queryRecordInfo',
        method: 'post',
        data,
        requestHeaders : [{
            'Content-Type': 'application/json'
        }]
    })
}
