import { ElMessage } from 'element-plus'
import i18n from "@/lang";
import cookieUtils from '@/utils/cookie'

const $t = i18n.global.t

/**
 * 获取静态图片地址
 *
 * @param image image
 * @returns {string} 图片url
 */
export const getCCmeAssetsURL = (image) => {
  return new URL(`../assets/${image}`, import.meta.url).href
}

/**
 * 获取ccme静态图片地址
 *
 * @param image image
 * @returns {string} 图片url
 */
export const getCCmeAssetsImgURL = (image) => {
  return new URL(`../assets/img/${image}`, import.meta.url).href
}

/**
 * 获取uuid
 *
 * @returns {string} uuid
 */
export const getUuid = () => {
  const temp_url = URL.createObjectURL(new Blob());
  const uuid = temp_url.toString();
  URL.revokeObjectURL(temp_url);
  return uuid.slice(uuid.lastIndexOf('/') + 1);
}

/**
 * 防抖函数: 提高页面响应
 *
 * @param callBack 回调函数
 * @param waitTime 等待时间
 * @returns {function(...[*]=)}
 */
export const debounce = (callBack, waitTime) => {
  let timeout = null;
  // 尾调用
  return function () {
    if (timeout !== null) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(callBack, waitTime);
  }
}

/**
 * 截取视频第一帧作为播放前默认图片
 *
 * @param videoUrl
 * @returns {string} 视频封面图片url
 */
export const getVideoShrinkageChart = (videoUrl) => {
  const video = document.createElement("video") // 创建 video 对象
  video.setAttribute('crossOrigin', 'Anonymous') // 解决跨域问题，也就是提示污染资源无法转换视频
  video.setAttribute('width', '320')
  video.setAttribute('height', '240')
  video.setAttribute('controls', 'controls')
  video.src = videoUrl // url地址 url跟 视频流是一样的
  const canvas = document.createElement('canvas') // 创建 canvas 对象
  const ctx = canvas.getContext('2d'); // 绘制2d
  video.currentTime = 1 // 第一帧
  video.oncanplay = () => {
    canvas.width = video.width; // 获取视频宽度
    canvas.height = video.height; //获取视频高度
    // 利用canvas对象方法绘图
    ctx.drawImage(video, 0, 0, video.width, video.height)
    // 转换成base64形式
    const imgSrc = canvas.toDataURL("image/png") // 截取后的视频封面
    const imgBlob = dataURLToBlob(imgSrc)
    return URL.createObjectURL(imgBlob)
  }
}

/**
 * 通过多媒体url链接得到Blob类型
 *
 * @param dataurl 多媒体数据url
 * @returns {module:buffer.Blob} blob类型
 */
export const dataURLToBlob = (dataurl) => {
  var arr = dataurl.split(',');
  var mime = arr[0].match(/:(.*?);/)[1];
  var bstr = atob(arr[1]);
  var n = bstr.length;
  var u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

/**
 * 提示框
 *
 * @param promptContent 提示内容
 * @param messageType 提示类型: 'success' | 'info' | 'warning' | 'error', 默认: warning
 */
export const tipHandler = (promptContent, messageType = 'warning', offsetTop, duration, boolShowClose) => {
  ElMessage({
    message: promptContent,
    type: messageType,
    offset: offsetTop,
    duration: duration,
    showClose: boolShowClose
  })
}

/**
 * 对特殊字符进行编码
 *
 * @param inputStr 输入
 * @returns {*}
 */
export const safeHtmlEncode = (inputStr) => {
  let ret = inputStr;
  ret = ret.replace(/&/g, "&amp;");
  ret = ret.replace(/</g, "&lt;");
  ret = ret.replace(/>/g, "&gt;");
  ret = ret.replace(/\//g, "&#47;");
  ret = ret.replace(/\\/g, "&#92;");
  ret = ret.replace(/\(/g, "&#40;");
  ret = ret.replace(/\)/g, "&#41;");
  return ret;
}

/**
 * 对特殊字符进行解码
 *
 * @param inputStr 输入
 * @returns {*}
 */
export const safeHtmlDecode = (inputStr) => {
  let ret = inputStr;
  ret = ret.replace(/&amp;/g, "&");
  ret = ret.replace(/&lt;/g, "<");
  ret = ret.replace(/&gt;/g, ">");
  ret = ret.replace(/&#47;/g, "/");
  ret = ret.replace(/&#92;/g, "\\");
  ret = ret.replace(/&#40;/g, "(");
  ret = ret.replace(/&#41;/g, ")");
  ret = ret.replace(/&nbsp;/g, " ");
  return ret;
}

/**
 * 用于邮件的标签白名单（在xss的DefaultWhiteList基础上扩展以支持业务）
 */
export const getEmailWhiteList = () => {
  return {
    a: ["href", "rel", "style", "target", "title"],
    abbr: ["title"],
    address: [],
    area: ["alt", "coords", "href", "shape"],
    article: [],
    aside: [],
    audio: ["autoplay", "controls", "crossorigin", "loop", "muted", "preload", "src"],
    b: [],
    bdi: ["dir"],
    bdo: ["dir"],
    big: [],
    blockquote: ["cite"],
    body: ["lang", "link", "style", "vlink"],
    br: [],
    caption: [],
    center: [],
    cite: [],
    code: [],
    col: ["align", "span", "valign", "width"],
    colgroup: ["align", "span", "valign", "width"],
    dd: [],
    del: ["datetime"],
    details: ["open"],
    div: ["class"],
    dl: [],
    dt: [],
    em: [],
    figcaption: [],
    figure: [],
    font: ["color", "face", "size"],
    footer: [],
    h1: [],
    h2: [],
    h3: [],
    h4: [],
    h5: [],
    h6: [],
    head: [],
    header: [],
    hr: [],
    html: ["xmlns", "xmlns:m", "xmlns:o", "xmlns:v", "xmlns:w"],
    i: [],
    img: ["alt", "height", "src", "title", "width"],
    ins: ["datetime"],
    li: [],
    mark: [],
    meta: ["content", "http-equiv", "name"],
    nav: [],
    ol: [],
    p: ["align", "class", "style"],
    pre: [],
    s: [],
    section: [],
    small: [],
    span: ["lang", "style"],
    sub: [],
    summary: [],
    sup: [],
    strong: [],
    strike: [],
    table: ["align", "border", "cellpadding", "cellspacing", "class", "style", "valign", "width"],
    tbody: ["align", "valign"],
    td: ["align", "colspan", "nowrap", "rowspan", "style", "valign", "width"],
    tfoot: ["align", "valign"],
    th: ["align", "colspan", "rowspan", "valign", "width"],
    thead: ["align", "valign"],
    title: [],
    tr: ["align", "rowspan", "style", "valign"],
    tt: [],
    u: [],
    ul: [],
    video: ["autoplay", "controls", "crossorigin", "height", "loop", "muted", "playsinline", "poster", "preload", "src", "width"],
    'o:p': [],
  };
}

/**
 * 用于富文本的标签白名单（与后台保存富文本的白名单保持一致）
 */
export const getRichTextWhiteList = () => {
  return {
    a: ["target", "href", "title", "rel"],
    p: ["style"],
    span: ["style"],
    s: [],
    em: [],
    strong: [],
    br: [],
    ol: [],
    ul: [],
    li: [],
    img: ["src", "width", "height"],
  }
}

/**
 * html编码
 *
 * @param text 输入
 * @returns {string}
 */
export const escapeText = (text) => {
  text = '' + text
  let map = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#x27;',
    '/': '&#x2F;',
    '(': '&#40;',
    ')': '&#41;'
  }
  return text.replace(/[&<>"'()/]/g, (str) => {
    return map[str]
  })
}

/**
 * 特殊字符校验
 *
 * @param str 字符串
 * @returns {boolean}
 */
export const checkSpecialKey = (str) => {
  if (!str) {
    return {
      flag: true,
      specialStr: ''
    }
  }
  let specialKey = `~!#$%&*\\()/=+{};:'",<>?[]`
  for (let i = 0; i < str.length; i++) {
    if (specialKey.includes(str.slice(i, i + 1))) {
      return {
        flag: false,
        specialStr: specialKey
      }
    }
  }
  return {
    flag: true,
    specialStr: ''
  }
}

/**
 * 特殊字符校验
 *
 * @param rule 规则
 * @param value 值
 * @param callback 回调
 */
export const validateInput = (rule, value, callback) => {
  const { flag = true, specialStr = '' } = checkSpecialKey(value)
  if (!flag) {
    callback(new Error(`${$t('ccmessaging.chat.chatpersonalization.specialStrValidateFailed')} ${specialStr}`))
  } else {
    callback()
  }
}

// 名称特殊字符校验
export const validateName = (rule, value, callback) => {
  const regEn = /[;'"\\/<>\\]/gi
  if (regEn.test(value)) {
      callback(new Error($t('ccmessaging.chat.chatpersonalization.specialStrValidateFailed')))
  } else {
      callback()
  }
}

/**
 * 判断是否是JSON格式
 *
 * @param str 字符串
 * @returns {boolean}
 */
export const isJson = (str) => {
  try {
    return typeof JSON.parse(str) == 'object'
  } catch (e) {
    return false
  }
}

/**
 * 获取 cookie 中的区域设置（locale）
 *
 * @returns {string} 区域设置文本（如 zh_CN）
 */
export const getLocale = () => cookieUtils.getCookie('u-locale')

/**
 * 获取并转换 cookie 中的区域设置（locale）为 IETF 标准格式
 *
 * @returns {string} 转换后的 IETF 语言标签（如 zh-CN）
 */
export const getIETFLocale = () => {
  const locale = getLocale()
  return locale.replace('_', '-');
}
