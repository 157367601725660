export default {
// "#Spanish Internationalization",
// "#Thu Aug 04 19:58:04 CST 2022",
  "ccnotification.bulletinType.message.releaseTime" : "tiempo de liberaci\u00F3n",
  "ccnotification.gatewayconfig.label.slipWindowSizetitle" : "El tama\u00F1o de la ventana deslizante es un n\u00FAmero adecuado",
  "ccnotification.common.title.confirm" : "Confirmar",
  "ccnotification.notifition.sendmessage.priorityGeneral" : "Bajo",
  "ccnotification.bulletinType.title.Urgent" : "Mediano",
  "message.template.type.system" : "Notificaci\u00F3n del sistema",
  "ccnotification.notification.label.checkAttachNameLegnth" : "El nombre del archivo adjunto de correo electr\u00F3nico no puede exceder los 100 caracteres",
  "ccnotification.gatewayconfig.label.replaceIfPresentFlagtitle" : "0\: Reemplazar\, 1\: No reemplazar\, 2-255\: Reservado",
  "ccnotification.gatewayconfig.label.senderPoolQueueCapacitytitle" : "La longitud de la cola del grupo de subprocesos de env\u00EDo es un n\u00FAmero\, por ejemplo\, 2000.",
  "ccnotification.gatewayconfig.label.viewgateway" : "Ver puerta de enlace",
  "ccnotification.notifition.sendmessage.savesuccess" : "El mensaje se guarda correctamente.",
  "message.center.message.messageQueryResultIsEmpty" : "El resultado de la consulta de mensaje est\u00E1 vac\u00EDo.",
  "message.template.message.agentbebinded" : "El destinatario seleccionado no est\u00E1 vinculado a un agente",
  "ccnotification.notification.systemmonitor.mspId" : "MspId",
  "message.template.message.mediaTypeError" : "Modo de env\u00EDo incorrecto.",
  "ccnotification.notifition.sendmessage.selectbyorg" : "Unidad de Organizaci\u00F3n",
  "ccnotification.gatewayconfig.label.addgatewaysuccess" : "Gatewayconfig agregar correctamente\!",
  "ccnotification.bulletinType.message.everyDay" : "todos los d\u00EDas",
  "ccnotification.gatewayconfig.label.defaultreceinfotitle" : "Regla de prefijo de número aceptada: Por ejemplo, si se configura 130, los números que comienzan con 130 coinciden. Los números múltiples están separados por punto y coma (;).",
  "message.template.message.titleError" : "El t\u00EDtulo de la plantilla no puede superar los 200 caracteres.",
  "ccnotification.gatewayconfig.label.receiverPoolMaxCounttitle" : "El grupo m\u00E1ximo de subprocesos de recepci\u00F3n es un n\u00FAmero\, por ejemplo\, 200.",
  "ccnotification.center.message.toIsRead" : "\u00BFEst\u00E1 seguro de que desea marcar los mensajes seleccionados como le\u00EDdos?",
  "ccnotification.flowcontrol.recipient.countrycode" : "C\u00F3digo de pa\u00EDs del destinatario",
  "message.center.message.willDeleteMessages" : "\u00BFEst\u00E1 seguro de que desea eliminar el mensaje seleccionado?",
  "ccnotification.bulletinType.title.bulletintypeInvalidSect" : "Tiempo de caducidad predeterminado",
  "ccnotification.gatewayconfig.message.securityInfo" : "NONE indica que los datos se transmiten en texto plano. Por lo tanto\, los datos pueden filtrarse. STARTTLS es un protocolo inseguro. El uso de protocolos inseguros plantea riesgos de seguridad. Se recomienda el protocolo TLS.",
  "ccnotification.common.label.name" : "Nombre",
  "message.template.message.mediaType" : "Modo de env\u00EDo de mensajes",
  "ccnotification.gatewayconfig.label.paramMaxLengthIsError" : "El valor del par\u00E1metro no puede superar los 500 caracteres",
  "ccnotification.gatewayconfig.label.receiverPoolMinCounttitle" : "El grupo de subprocesos de recepci\u00F3n m\u00EDnimo es un n\u00FAmero\, por ejemplo\, 20.",
  "ccnotification.bulletinType.message.perMonth" : "por mes",
  "ccnotification.caseserver.fail.duplicateContent" : "Contenido duplicado",
  "ccnotification.gatewayconfig.label.senderPoolMaxCount" : "M\u00E1ximo env\u00EDo de grupos de hilos",
  "ccnotification.gatewayconfig.label.splitType" : "Modo de divisi\u00F3n de SMS de gran tama\u00F1o",
  "ccnotification.gatewayconfig.label.positive" : "El n\u00FAmero no puede ser negativo.",
  "ccnotification.smstemplate.message.smstemplate" : "La informaci\u00F3n de la plantilla HUAWEI CLOUD est\u00E1 vac\u00EDa.",
  "ccnotification.bulletinType.message.Tuesday" : "Martes",
  "ccnotification.gatewayconfig.label.receiverPoolQueueCapacity" : "Longitud de cola de grupo de hilos de recepci\u00F3n",
  "ccnotification.agent.operation.searchworkno" : "Ingrese el ID de empleado",
  "message.center.nocitiction.sending.records.sended" : "Env\u00EDo instant\u00E1neo",
  "ccnotification.gatewayconfig.label.interfaceVersion" : "N\u00FAmero de versi\u00F3n del mensaje de inicio de sesi\u00F3n",
  "ccnotification.notifition.template.message.phone" : "- El valor es una cadena de 1 a 15 d\u00EDgitos.",
  "ccnotification.gatewayconfig.label.SSLEncryptionMode" : "Modo de cifrado",
  "ccn.smsnoconfig.pop.title.edittitle" : "Editar n\u00FAmero de SMS",
  "ccnotification.agent.message.updateCtiAndDbFail" : "Error al actualizar cti y Db.",
  "ccnotification.bulletinType.title.editBulletinType" : "Modificar el tipo de bolet\u00EDn",
  "message.center.nocitiction.draftBox" : "Cuadro de Borrador",
  "message.template.type.out" : "Notificaci\u00F3n Externa",
  "ccnotification.notification.label.compressZipFileFailed" : "Error al comprimir el archivo.zip",
  "message.template.message.nameDuplicationError" : "Nombre duplicado de la plantilla.",
  "ccnotification.notifition.receivermessage.isread" : "Leer",
  "ccnotification.center.message.var.type" : "Tipo de variable\:",
  "ccnotification.bulletinType.message.createBulletinTypeFailed" : "Error al crear el tipo de bolet\u00EDn.",
  "ccnotification.notifition.sendmessage.selectGroupOrRecipient" : "Seleccione el destinatario o el grupo de destinatarios.",
  "ccnotification.common.title.errorsave" : "Error",
  "ccnotification.notification.recmessagerouterconfig.selectitem" : "Seleccione un registro.",
  "ccnotification.common.title.fail" : "Error",
  "ccnotification.notification.recmessagerouterconfig.receiveAddress" : "Direcci\u00F3n de Destinatario",
  "ccnotification.gatewayconfig.label.modifygatewaysuccess" : "Gatewayconfig se modifica correctamente\!",
  "ccnotification.flowcontrol.getFailed" : "Error al obtener la configuraci\u00F3n de notificaci\u00F3n. Comprueba la red.",
  "ccnotification.gatewayconfig.label.selectredisconnectedgatewayconfig" : "Seleccione la configuraci\u00F3n de la puerta de enlace que se va a desconectar",
  "ccnotification.gatewayconfig.label.localPort" : "Puerto de cliente",
  "ccnotification.notifition.template.label.templateid" : "ID de plantilla",
  "ccnotification.notification.message.noAttachement" : "No has subido ning\u00FAn archivo adjunto.",
  "ccnotification.notifition.sendmessage.entertenantname" : "Introduzca un nombre de espacio de inquilino.",
  "ccnotification.bulletinType.title.bulletinTypeName" : "Nombre del tipo de bolet\u00EDn",
  "message.template.message.messageTemplateId" : "ID de plantilla",
  "ccnotification.notification.recmessagerouterconfig.caseserver" : "Invocaci\u00F3n de servicio",
  "ccnotification.gatewayconfig.label.defaultsendnotitle" : "Regla de prefijo del número de remitente. Por ejemplo, si 130 está configurado, los números que comienzan con 130 coinciden. Los números múltiples están separados por punto y coma (;).",
  "ccnotification.sms.gateway" : "Pasarela SMS",
  "ccnotification.smstemplate.message.number" : "El d\u00EDgito m\u00E1ximo es 10.",
  "ccnotification.notification.label.deleteAttachmentFailed" : "Error al eliminar datos adjuntos",
  "ccnotification.notification.recmessagerouterconfig.deletenum.outstripping" : "Se puede eliminar un m\u00E1ximo de 100 registros a la vez.",
  "ccnotification.notifition.sendmessage.selectOutbox" : "Seleccione la bandeja de salida.",
  "ccnotification.bulletinType.message.deleteBulletinTypeFailed" : "Error al eliminar el tipo de bolet\u00EDn.",
  "message.template.message.modifyTemplateFail" : "Error al modificar la plantilla de notificaci\u00F3n.",
  "message.template.message.recipientError" : "Algunos destinatarios no existen. Seleccione otro destinatario",
  "ccnotification.gatewayconfig.label.mailPassword" : "Contrase\u00F1a de usuario",
  "message.center.message.delete" : "Borrar",
  "message.template.message.inputTitle" : "Introduzca un t\u00EDtulo.",
  "ccnotification.notifition.variable.templatename" : "Nombre de la plantilla",
  "ccnotification.notifition.template.message.error" : "Valores de entrada no v\u00E1lidos.",
  "ccnotification.gatewayconfig.label.authtitle" : "Indicador de autenticaci\u00F3n\, las opciones son verdaderas y falsas",
  "ccnotification.gatewayconfig.label.mailPort" : "El puerto",
  "ccnotification.notifition.sendmessage.enteraccount" : "Ingrese una cuenta de empresa.",
  "ccnotification.sms.huawei" : "SMS de Huawei",
  "ccnotification.chatserver.fail.barred" : "Asignaci\u00F3n de agente bloqueada",
  "ccnotification.gatewayconfig.label.certType" : "Tipo de certificado",
  "ccnotification.flowcontrol.modifyFailed" : "Error al modificar la configuraci\u00F3n de notificaci\u00F3n. Compruebe los par\u00E1metros de entrada o la red.",
  "message.center.label.sendTime" : "Enviado en",
  "ccnotification.common.button.create" : "Nuevo",
  "ccnotification.notifition.sendmessage.enterrecipient" : "Selecciona un destinatario.",
  "ccnotification.gatewayconfig.label.defaultsendname" : "Nombre de usuario predeterminado",
  "ccnotification.notifition.variable.insertvariable" : "Insertar variable",
  "ccnotification.flowcontrol.eachThreshold" : "Umbral diario de datos Monomedia",
  "ccnotification.gatewayconfig.label.smppPduTypetitle" : "1\:submit_sm 2\:data_sm",
  "ccnotification.bulletinType.message.invalidSectCheck" : "El tiempo de caducidad predeterminado debe oscilar entre 1 y 2000 d\u00EDas.",
  "ccnotification.bulletinType.message.daysOfMonth" : "D\u00EDa 0",
  "ccnotification.notifition.variable.createvariable" : "Crear",
  "ccnotification.bulletinType.message.nameContainSpecialChar" : "El nombre no puede contener caracteres especiales.",
  "message.center.label.receiver" : "Destinatario",
  "ccnotification.bulletinType.message.Friday" : "Viernes",
  "ccnotification.gatewayconfig.label.deviceTypeDescript" : "Tipo de dispositivo Descripci\u00F3n",
  "ccnotification.notifition.sendmessage.bulletinTypeName" : "Nombre del tipo de bolet\u00EDn",
  "ccnotification.gatewayconfig.label.maxEmailGatewayIsError" : "Se puede configurar un m\u00E1ximo de cinco puertas de enlace de recepci\u00F3n o env\u00EDo de correo electr\u00F3nico para el mismo espacio de inquilino",
  "ccn.smsnoconfig.pop.search.reminder" : "Introduzca un n\u00FAmero.",
  "ccnotification.notifition.template.message.dateTimeLabel" : "Fecha y hora",
  "ccn.smshuawei.message.table.label" : "HUAWEI CLOUD SMS",
  "ccnotification.notifition.gatewaycontact.success" : "Conexi\u00F3n correcta",
  "message.template.message.sendEmailError" : "La direcci\u00F3n de correo electr\u00F3nico del remitente est\u00E1 vac\u00EDa.",
  "ccnotification.gatewayconfig.label.mailProtocolIsError" : "El tipo de protocolo de correo electr\u00F3nico debe ser SMTP\, POP3.",
  "ccnotification.gatewayconfig.label.messageprotocol" : "Par\u00E1metros del protocolo de mensajes",
  "message.center.label.messageCenter" : "Centro de notificaciones",
  "ccnotification.notifition.sendmessage.delaysend" : "Notificaci\u00F3n programada",
  "ccnotification.common.title.delete" : "Borrar",
  "ccnotification.notifition.sendmessage.selectmode" : "Seleccionar por",
  "ccnotification.notification.emailsignature.selection.username" : "Nombre de usuario",
  "ccnotification.notification.recmessagerouterconfig.receiver" : "Destinatario",
  "ccnotification.template.message.delete" : "Se ha hecho referencia a la plantilla y no se puede eliminar.",
  "ccnotification.notification.label.isUploadAttachment" : "Cargar archivo adjunto",
  "ccnotification.notifition.receivermessage.all" : "Todo",
  "ccnotification.notification.label.fileInjectionRiskException" : "Excepci\u00F3n de riesgo de inyecci\u00F3n de archivos adjuntos de correo electr\u00F3nico",
  "message.template.message.createTime" : "Creado en",
  "ccnotification.notifition.sendmessage.internal" : "Mensaje interno",
  "message.center.label.deleteisread" : "Eliminar todos los mensajes le\u00EDdos",
  "ccnotification.gatewayconfig.label.deviceDescript" : "Descripci\u00F3n del dispositivo",
  "ccnotification.agent.label.account" : "Cuenta",
  "message.template.message.queryagentbebindedfailed" : "Error al consultar al usuario enlazado al agente",
  "ccnotification.common.title.tips" : "Sugerencias",
  "ccnotification.gatewayconfig.label.addgatewayfailed" : "Error al agregar Gatewayconfig\!",
  "message.center.label.delete" : "Borrar",
  "ccnotification.gatewayconfig.label.selectredgatewayconfig" : "Supervisi\u00F3n de la puerta de enlace seleccionada\:",
  "ccnotification.notifition.template.placeholder.signatureName" : "Introduzca un nombre de firma",
  "ccnotification.flowcontrol.setFlowType" : "Tipo de l\u00EDmite de datos",
  "ccnotification.gatewayconfig.label.addrTon" : "C\u00F3digo de tipo de direcci\u00F3n ESME",
  "ccnotification.common.button.delete" : "Borrar",
  "ccnotification.common.title.confreset" : "Confirmar reinicio",
  "ccnotification.notifition.template.label.varnumber" : "N\u00FAmero de variables",
  "ccnotification.notifition.template.message.phoneLabel" : "N\u00FAmero de tel\u00E9fono",
  "ccn.smsnoconfig.pop.check.deletewarning" : "\u00BFEst\u00E1 seguro de que desea eliminar los datos seleccionados?",
  "ccnotification.agent.pageurls.isconfreset" : "\u00BFEst\u00E1 seguro de que desea restablecerlo?",
  "ccnotification.notification.emailsignature.reachmaxcount" : "El n\u00FAmero de firmas de correo electr\u00F3nico excede el m\u00E1ximo.",
  "ccnotification.smshuawei.message.specialCharacters" : "No se permiten caracteres especiales.",
  "ccnotification.gatewayconfig.label.configTypeIsError" : "El tipo de configuraci\u00F3n es incorrecto. El tipo de configuraci\u00F3n interna del par\u00E1metro es inconsistente",
  "ccnotification.smshuawei.message.updatesuccess" : "El canal SMS se modifica con \u00E9xito.",
  "ccnotification.notifition.sendmessage.notifitiontitle" : "Introduzca un t\u00EDtulo.",
  "message.template.button.delete" : "Borrar",
  "ccnotification.notification.label.rebuildSuccess" : "Reconstrucci\u00F3n del \u00E9xito",
  "ccnotification.smstemplate.message.fail" : "Error al crear la plantilla HUAWEI CLOUD.",
  "message.center.label.search" : "Buscar",
  "ccnotification.notification.systemmonitor.status" : "Estado",
  "message.template.message.nameError" : "El nombre de la plantilla no puede estar vac\u00EDo ni exceder los 200 caracteres.",
  "ccnotification.smshuawei.label.appKey" : "Clave de aplicaci\u00F3n",
  "ccnotification.notifition.variable.searchTempdateName" : "Escriba un nombre de plantilla.",
  "ccnotification.smshuawei.label.certTip.withProxy" : "Cargue el certificado del servidor proxy",
  "ccnotification.gatewayconfig.label.senderPoolMinCount" : "Grupos de hilos de env\u00EDo de miner\u00EDa",
  "ccnotification.smshuawei.label.certTip.withoutProxy" : "Cargue el certificado de servicio de HUAWEI CLOUD",
  "message.template.message.type" : "Tipo de notificaci\u00F3n",
  "ccnotification.notifition.sendmessage.priorityExtraUrgent" : "Alta",
  "message.template.message.willDeleteTemplate" : "La eliminaci\u00F3n de la plantilla puede hacer que la funci\u00F3n a la que se hace referencia no est\u00E9 disponible. \u00BFEst\u00E1s seguro de que quieres continuar?",
  "ccnotification.notification.recmessagerouterconfig.serviceName" : "Nombre del servicio",
  "ccnotification.flowcontrol.totalThreshold" : "Umbral de datos total diario",
  "ccnotification.gatewayconfig.label.certScene" : "Escenario de la aplicaci\u00F3n",
  "ccn.smsnoconfig.pop.check.selectwarning" : "Seleccione el registro que desea eliminar.",
  "ccnotification.notification.label.rebuildContainsErrorId" : "La reconstrucci\u00F3n fall\u00F3\, haga clic en el bot\u00F3n de consulta para actualizar la lista y vuelva a intentarlo\.",
  "ccnotification.notification.label.rebuildFailed" : "Error al reconstruir",
  "ccnotification.notification.emailsignature.name" : "Nombre de la firma",
  "ccnotification.notifition.sendmessage.cancelSelectall" : "Cancelar todo",
  "message.template.message.selectRecipient" : "Selecciona un destinatario.",
  "ccnotification.notifition.sendmessage.recipientGroup" : "Grupo de Destinatarios",
  "ccnotification.notification.label.attachment" : "Archivos adjuntos",
  "ccnotification.common.warning.message" : "Seleccione un registro.",
  "ccnotification.notifition.sendmessage.priority" : "Prioridad",
  "ccnotification.notifition.sendmessage.receiverPhoneIsNull" : "El destinatario o n\u00FAmero de destinatario est\u00E1 vac\u00EDo.",
  "ccnotification.center.message.deleteAllRead" : "\u00BFEst\u00E1 seguro de que desea eliminar todos los mensajes internos le\u00EDdos?",
  "ccnotification.notification.label.selectAttachment" : "Seleccione un archivo adjunto de correo electr\u00F3nico.",
  "ccnotification.notifition.sendmessage.priorityUrgent" : "Mediano",
  "ccnotification.notifition.variable.variablename" : "Nombre de variable",
  "ccnotification.notification.emailsignature.content" : "Contenido de firma",
  "message.template.message.searchMessageTemplateName" : "Escriba un nombre de plantilla.",
  "ccnotification.notification.special.charactor.check" : "El contenido introducido no puede contener < \= > /  eval y exec.",
  "ccnotification.notifition.template.label.des" : "Descripcion",
  "message.template.message.notificationContent" : "Contenido de notificaci\u00F3n",
  "ccnotification.gatewayconfig.label.configNameIsError" : "El nombre de configuraci\u00F3n debe contener un m\u00E1ximo de 10 d\u00EDgitos o letras",
  "ccnotification.gatewayconfig.label.heartbeatIntervaltitle" : "El intervalo de latidos del coraz\u00F3n es un n\u00FAmero apropiado",
  "ccnotification.gatewayconfig.label.serviceType" : "Tipo de servicio",
  "ccnotification.notifition.sendmessage.bulletin" : "Bolet\u00EDn de noticias",
  "message.template.message.nameLengthError" : "El nombre no puede estar vac\u00EDo, contener un m\u00E1ximo de 200 caracteres o contener caracteres especiales",
  "ccnotification.notifition.template.message.moneyLabel" : "Importe",
  "ccnotification.notifition.gatewaycontact.stop" : "Conexi\u00F3n detenida",
  "ccnotification.notifition.sendmessage.enterBulletinType" : "Seleccione un tipo de bolet\u00EDn.",
  "ccnotification.notification.label.deleteAttachmentSuccess" : "Eliminar datos adjuntos correctamente",
  "ccnotification.common.title.warning" : "Advertencia",
  "ccnotification.notification.fileUploadRiskWarning" : "El archivo que carg\u00F3 tiene un riesgo de seguridad\, confirme si desea continuar carg\u00E1ndolo.",
  "ccnotification.notification.label.fileUploadFileNameError" : "El nombre del archivo que subiste no cumple con los requisitos",
  "ccnotification.notification.label.uploadAttachmentFailed" : "Error al cargar datos adjuntos",
  "ccnotification.notification.label.fileUploadFileSizeError" : "El tama\u00F1o del archivo que subiste excede el l\u00EDmite",
  "ccnotification.notification.label.fileUploadFileTypeError" : "El tipo de archivo que subiste no cumple con los requisitos",
  "ccnotification.notification.label.fileUploadFileIOError" : "Hubo un error de an\u00E1lisis con el archivo que subiste",
  "ccnotification.gatewayconfig.label.sourceAddrNpititle" : "El esquema de c\u00F3digo de direcci\u00F3n de origen es un n\u00FAmero",
  "message.center.nocitiction.interal" : "Bolet\u00EDn Interno",
  "ccnotification.gatewayconfig.label.maxContentLengthtitle" : "La longitud de contenido m\u00E1xima (bytes) es un n\u00FAmero\, que se usa como la base de divisi\u00F3n coincidente\, por ejemplo\, 140.",
  "ccnotification.notification.recmessagerouterconfig.defaultsendemail" : "Predeterminado Enviar correo electr\u00F3nico",
  "ccnotification.notification.label.attachmentExceed10" : "Se puede cargar un m\u00E1ximo de 10 archivos adjuntos.",
  "ccnotification.notifition.sendmessage.entersender" : "Seleccione un n\u00FAmero de remitente.",
  "message.template.message.contentError" : "El contenido no puede estar vac\u00EDo.",
  "ccnotification.caseserver.fail.titleAndContentNull" : "T\u00EDtulo y contenido vac\u00EDos",
  "ccnotification.notification.emailsignature.description" : "Descripci\u00F3n de la firma",
  "ccnotification.bulletinType.message.Sunday" : "Domingo",
  "ccnotification.gatewayconfig.label.selectdeletegatewayconfig" : "\u00A1Seleccione la configuraci\u00F3n de la puerta de enlace que desea eliminar\!",
  "ccnotification.notification.variable.templateselect" : "Seleccione una plantilla.",
  "ccnotification.gatewayconfig.label.heartbeatNoRespCounttitle" : "Los tiempos m\u00E1ximos de conexi\u00F3n en caso de no respuesta de latidos son un n\u00FAmero adecuado",
  "ccnotification.notification.label.attachmentIsEmpty" : "El archivo adjunto est\u00E1 vac\u00EDo.",
  "message.center.nocitiction.modifyBulletin" : "Modificar Bolet\u00EDn",
  "ccnotification.gatewayconfig.label.maxMessageLength" : "Longitud m\u00E1xima del mensaje (bytes)",
  "ccnotification.gatewayconfig.label.gatewayname" : "Nombre de puerta de enlace",
  "ccnotification.smshuawei.message.deletefail" : "Error al eliminar el canal SMS.",
  "ccnotification.gatewayconfig.label.splitMsgConcatModeIsError" : "Modo de combinaci\u00F3n de mensaje corto largo 0\: cabecera de datos de usuario GSM\, 1\: par\u00E1metro SMPP",
  "ccnotification.notification.label.attachmentSizeError" : "El tama\u00F1o de los archivos adjuntos de correo electr\u00F3nico supera los 10 MB",
  "message.template.message.messageTemplateModify" : "Modificar plantilla",
  "message.template.message.createSuccess" : "La plantilla de notificaci\u00F3n se ha creado correctamente.",
  "ccnotification.notifition.receivermessage.unread" : "No le\u00EDdo",
  "ccnotification.notification.recmessagerouterconfig.intelligentOrder" : "Creaci\u00F3n inteligente de casos",
  "message.center.nocitiction.outbox" : "Bandeja de salida",
  "ccnotification.gatewayconfig.label.addgateway" : "Crear puerta de enlace",
  "ccn.smsnoconfig.pop.check.deletesuccess" : "La eliminaci\u00F3n se realiz\u00F3 correctamente.",
  "ccnotification.bulletinType.message.periodicallySend" : "enviar peri\u00F3dicamente",
  "message.center.nocitiction.callback.receivetime" : "Tiempo de recepci\u00F3n",
  "message.center.nocitiction.sending.records.status" : "Estado",
  "ccnotification.notification.label.discounnectedFailed" : "Fallo de desconexión",
  "message.template.button.edit" : "Editar",
  "ccnotification.bulletinType.title.Faults" : "Cr\u00EDtica",
  "ccnotification.gatewayconfig.label.mailHost" : "Anfitri\u00F3n",
  "ccnotification.bulletinType.message.betchDeleteBulletinTypeFailed" : "Error al eliminar los tipos de bolet\u00EDn en lotes.",
  "ccnotification.notifition.sendmessage.selectuser" : "Selecciona un empleado.",
  "ccnotification.smshuawei.message.paramisempty" : "Par\u00E1metro vac\u00EDo.",
  "ccnotification.notifition.template.message.dateTime" : "- Fecha\: aaaa-MM-dd - Tiempo\: HH\:mm\:ss",
  "ccnotification.notification.variable.templatetype" : "Tipo de plantilla",
  "ccnotification.notifition.sendmessage.sendsuccess" : "El mensaje se env\u00EDa correctamente.",
  "ccnotification.gatewayconfig.label.dataCoding" : "Formato de mensaje",
  "message.template.button.view" : "Vistas",
  "ccnotification.bulletinType.message.searchPlaceHolder" : "Nombre del tipo de bolet\u00EDn",
  "ccnotification.center.message.setIsRead" : "Marcar mensajes como le\u00EDdos",
  "ccnotification.notifition.sendmessage.isSendall" : "Enviado",
  "ccnotification.smshuawei.label.signatureChannelId" : "ID de canal de firma",
  "ccnotification.notifition.sendmessage.send" : "Enviar",
  "ccnotification.gatewayconfig.label.selectCert" : "Seleccionar certificado",
  "ccnotification.gatewayconfig.label.serverSocketPort" : "Puerto para recibir mensajes ascendentes",
  "ccnotification.gatewayconfig.label.readTimeout" : "Intervalo de tiempo de espera de recepci\u00F3n (ms)",
  "ccnotification.notifition.template.message.mspsms" : "Pasarela SMS",
  "ccnotification.bulletinType.message.editBulletinTypeSuccessful" : "El tipo de bolet\u00EDn se modifica correctamente.",
  "ccnotification.gatewayconfig.label.dataCodingIsError" : "El formato de mensaje SMS debe ser 0\: cadena ASCII\,4\:informaci\u00F3n binaria\,8\: c\u00F3digo UCS2",
  "ccnotification.notification.recmessagerouterconfig.emailOnlineChat" : "Chat en l\u00EDnea a trav\u00E9s del canal de correo electr\u00F3nico",
  "ccnotification.notifition.sendmessage.bccRecipient" : "CCO",
  "ccnotification.notification.systemmonitor.modifyTime" : "Modificado en",
  "ccnotification.notifition.template.label.value" : "Valor predeterminado",
  "ccnotification.notification.emailsignature.nameexist" : "El nombre de la firma ya existe.",
  "ccnotification.gatewayconfig.label.interfaceVersiontitle" : "El n\u00FAmero de versi\u00F3n del mensaje de inicio de sesi\u00F3n debe ser un n\u00FAmero.",
  "ccnotification.gatewayconfig.label.paramIsNotNumber" : "El valor del par\u00E1metro puede contener s\u00F3lo d\u00EDgitos",
  "ccnotification.gatewayconfig.label.destAddrNpititle" : "El esquema de c\u00F3digo de direcci\u00F3n de destino es un n\u00FAmero",
  "ccnotification.gatewayconfig.label.defaultsendnoIsError" : "El formato de la direcci\u00F3n de correo electr\u00F3nico predeterminada es incorrecto",
  "ccnotification.notifition.variable.syntax.error" : "\u00A1Error de sintaxis\!",
  "ccnotification.notification.label.uploadPermissionFailed" : "Error al comprobar el permiso de archivo adjunto de correo electr\u00F3nico",
  "ccnotification.gatewayconfig.label.smppPduType" : "Tipo de PDU SMPP",
  "ccnotification.notification.label.discounnectedSuccess" : "Desconexión exitosa",
  "ccnotification.gatewayconfig.label.registeredDelivery" : "Informe de confirmaci\u00F3n de estado de devoluci\u00F3n",
  "ccnotification.gatewayconfig.label.receiverPoolMaxCount" : "Piscinas de hilos de recepci\u00F3n m\u00E1xima",
  "ccnotification.notification.variable.nameexist" : "El nombre de la variable existe.",
  "ccnotification.notifition.template.placeholder.hwtemplateid" : "Ingrese un ID de plantilla de HUAWEI CLOUD",
  "message.center.label.refresh" : "Refrescar",
  "ccnotification.common.button.ok" : "OK",
  "ccnotification.gatewayconfig.label.sourceAddrNpi" : "Esquema de c\u00F3digo de direcci\u00F3n de origen",
  "ccnotification.notifition.sendmessage.enterRecipientGroup" : "Seleccione un grupo de destinatarios.",
  "ccnotification.bulletinType.message.Wednesday" : "Mi\u00E9rcoles",
  "message.template.message.messageTemplateName" : "Nombre de la plantilla",
  "message.center.nocitiction.email" : "Correo Electr\u00F3nico",
  "ccnotification.notifition.variable.not.exist" : "no existe\!",
  "ccnotification.notifition.template.label.templatetype" : "Tipo de plantilla",
  "ccnotification.notification.emailsignature.placeholder.name" : "Introduzca un nombre de firma",
  "ccnotification.gatewayconfig.label.readTimeouttitle" : "El intervalo de tiempo de espera de recepci\u00F3n es un n\u00FAmero apropiado\,0\: nunca tiempo de espera",
  "ccnotification.notifition.recmessagerouterconfig.index" : "Iniciar sesi\u00F3n",
  "ccnotification.notifition.sendmessage.sendTimeFailed" : "La hora de env\u00EDo es anterior a la hora actual.",
  "ccnotification.notification.recmessagerouterconfig.retryTimes" : "Tiempos de reintento",
  "ccnotification.gatewayconfig.label.sslencryptionmodeIsError" : "El modo de cifrado de correo electr\u00F3nico solo puede ser NONE\, STARTTLS o TLS",
  "ccnotification.gatewayconfig.label.replaceIfPresentFlag" : "Bandera de reemplazo",
  "ccnotification.notification.label.hasUplaodtAttachment" : "Archivos adjuntos cargados",
  "ccnotification.notification.recmessagerouterconfig.inbox" : "En caja",
  "ccnotification.notifition.sendmessage.selectall" : "Seleccionar todo",
  "ccnotification.notifition.sendmessage.smsconfignull" : "La configuraci\u00F3n del mensaje SMS est\u00E1 vac\u00EDa. Seleccione Configuraci\u00F3n > Configuraci\u00F3n de SMS para configurar la informaci\u00F3n de SMS.",
  "ccnotification.notifition.template.label.operation" : "Operaci\u00F3n",
  "ccn.smsnoconfig.table.header.countrycode.desc" : "Pa\u00EDs",
  "ccnotification.notification.variable.variablevalue" : "Valor de la variable",
  "ccnotification.gatewayconfig.label.inputgatewayname" : "Introduzca un nombre de puerta de enlace",
  "ccnotification.flowcontrol.setThreshold" : "Establecer el umbral de datos",
  "ccnotification.caseserver.fail.categoryNotExist" : "Categor\u00EDa de casos inexistentes",
  "ccnotification.gatewayconfig.label.checkConfigName" : "El valor es una cadena de menos de 10 d\u00EDgitos y letras",
  "ccnotification.gatewayconfig.label.specialStrValidate" : "Solo se permiten letras may\u00FAsculas, min\u00FAsculas y n\u00FAmeros.",
  "ccnotification.common.operation.noMoreThan7Days" : "El rango de tiempo no puede exceder los 7 d\u00EDas.",
  "ccnotification.gatewayconfig.label.paramContainsSpecialChar" : "El par\u00E1metro contiene caracteres especiales",
  "ccnotification.notifition.sendmessage.delaynotice" : "Este aviso se enviar\u00E1 en",
  "ccnotification.gatewayconfig.label.smDefaultMsgId" : "ID de mensaje predeterminado",
  "ccnotification.bulletinType.message.deleteBulletinTypeSuccessful" : "El tipo de bolet\u00EDn se elimina correctamente.",
  "ccnotification.gatewayconfig.label.requestParamIsNull" : "El par\u00E1metro de solicitud est\u00E1 vac\u00EDo",
  "message.template.message.operate" : "Operaci\u00F3n",
  "message.template.message.frequently" : "El n\u00FAmero de mensajes enviados supera el umbral diario.",
  "ccnotification.notification.recmessagerouterconfig.selectconfig" : "Seleccione una configuraci\u00F3n.",
  "message.template.message.noneTemplate" : "La plantilla no existe.",
  "ccnotification.gatewayconfig.label.lastmodifytime" : "\u00DAltima modificaci\u00F3n en",
  "ccnotification.notifition.variable.close.syntax.error2" : "Compruebe si ${} est\u00E1 cerrado.",
  "ccnotification.notifition.variable.close.syntax.error1" : "El contenido de la plantilla contiene errores de sintaxis cerca",
  "ccnotification.bulletinType.message.createBulletinTypeSuccessful" : "El tipo de bolet\u00EDn se ha creado correctamente.",
  "ccnotification.notification.emailsignature.content.length.exceed" : "La longitud del contenido de la firma no puede exceder de 4096.",
  "ccnotification.gatewayconfig.label.resendNumbertitle" : "Los Tiempos de Reenv\u00EDo es un n\u00FAmero apropiado",
  "ccnotification.notification.emailsignature.willdeleteselectitem" : "\u00BFEst\u00E1 seguro de que desea eliminar los datos seleccionados?",
  "ccnotification.gatewayconfig.label.deletegatewayfailed" : "Error al eliminar Gatewayconfig\!",
  "ccnotification.notifition.receivermessage.receiverSuccess" : "Recibido",
  "ccnotification.notification.recmessagerouterconfig.gateway" : "Pasarela",
  "ccnotification.gatewayconfig.label.mailHosttitle" : "Direcci\u00F3n de host\, por ejemplo\, 127.0.0.1 o www.mail.com",
  "message.center.label.allSelect" : "Seleccionar todo",
  "ccnotification.gatewayconfig.label.messageFlowControlMaxCapsForRecv" : "Tasa de recepci\u00F3n m\u00E1xima",
  "ccnotification.center.message.var.value" : "Valor variable\:",
  "ccnotification.notification.recmessagerouterconfig.gatewaynotexist" : "La puerta de enlace seleccionada no existe.",
  "message.template.message.typeError" : "Tipo de notificaci\u00F3n incorrecto.",
  "ccnotification.notifition.sendmessage.isSendallFail" : "Enviado (fallo)",
  "ccnotification.notifition.template.message.textLabel" : "Texto",
  "ccnotification.common.spec.message" : "No se pueden introducir caracteres especiales.",
  "message.center.label.bulletinType" : "Tipo de bolet\u00EDn",
  "ccnotification.notification.recmessagerouterconfig.selectgateway" : "Seleccionar puerta de enlace",
  "ccnotification.gatewayconfig.label.messageFlowControlMaxCapsForRecvtitle" : "La tasa m\u00E1xima de recepci\u00F3n es un n\u00FAmero",
  "ccnotification.gatewayconfig.label.smPort" : "Puerto SMG",
  "ccnotification.gatewayconfig.label.receiverPoolQueueCapacitytitle" : "La Longitud de cola de grupo de subprocesos de recepci\u00F3n es un n\u00FAmero\, por ejemplo\, 20.",
  "ccnotification.gatewayconfig.label.splitTypeIsError" : "El modo de divisi\u00F3n de mensajes SMS largos solo se puede establecer en 0\:disabled\,1\:no dividido\,2\:divisi\u00F3n f\u00EDsica\,3\:divisi\u00F3n ultralarga",
  "message.template.message.recipientLengthError" : "El n\u00FAmero de destinatarios excede el l\u00EDmite.",
  "ccnotification.common.button.finish" : "Completo",
  "ccnotification.notifition.sendmessage.sendtime" : "Tiempo de env\u00EDo",
  "ccnotification.bulletinType.message.willDelete" : "\u00BFEst\u00E1 seguro de que desea eliminar el tipo de bolet\u00EDn seleccionado?",
  "ccnotification.gatewayconfig.label.systemType" : "Tipo de ESME",
  "message.center.nocitiction.sender" : "Remitente",
  "ccnotification.notifition.sendmessage.preview" : "Vista previa",
  "ccnotification.bulletinType.message.editBulletinTypeFailed" : "Error al modificar el tipo de bolet\u00EDn.",
  "message.center.label.newMessage" : "Enviar notificaci\u00F3n",
  "ccnotification.notification.label.incorrectAttachmentType" : "Tipo de archivo adjunto de correo electr\u00F3nico incorrecto",
  "message.template.type.internal" : "Notificaci\u00F3n interna",
  "message.center.nocitiction.sending.records" : "Env\u00EDo de registro",
  "ccnotification.notifition.template.button.up" : "Arriba",
  "ccnotification.flowcontrol.shortMsgCode" : "N\u00FAmero de mensaje SMS",
  "ccnotification.notification.recmessagerouterconfig.willdeleteselectconfig" : "\u00BFEst\u00E1 seguro de que desea eliminar la configuraci\u00F3n seleccionada?",
  "ccnotification.bulletinType.title.bulletinTypeDesc" : "Descripcion",
  "ccnotification.notifition.sendmessage.selectbyworkno" : "ID de empleado",
  "ccnotification.notifition.sendmessage.entercompanyName" : "Ingrese un nombre de empresa.",
  "message.center.nocitiction.modifyInternalMessage" : "Modificar mensaje interno",
  "ccnotification.notification.variable.publicvariable" : "Variable p\u00FAblica",
  "ccnotification.notifition.sendmessage.unSend" : "Para ser enviado",
  "ccnotification.common.message.emptyContent" : "El contenido no puede estar vac\u00EDo.",
  "message.template.mediaType.internal" : "Mensaje interno",
  "ccnotification.notifition.template.label.mspcontent" : "Contenido de la plantilla SMS",
  "ccnotification.gatewayconfig.label.modifygateway" : "Modificar puerta de enlace",
  "ccnotification.notifition.sendmessage.paramvalidatefailed" : "Error en la validaci\u00F3n del par\u00E1metro.",
  "ccnotification.gatewayconfig.label.certCode" : "C\u00F3digo de certificado",
  "message.template.message.save" : "Guardar",
  "ccnotification.gatewayconfig.label.defaultsendno" : "N\u00FAmero de env\u00EDo predeterminado",
  "message.template.message.agentRecipientError" : "Algunos destinatarios no est\u00E1n vinculados a los agentes. Seleccione otro destinatario",
  "ccnotification.gatewayconfig.label.RcvAndSendMode" : "Modo TX/RX",
  "ccnotification.gatewayconfig.label.smHosttitle" : "Direcci\u00F3n SMG\, por ejemplo\, 127.0.0.1",
  "ccnotification.notification.variable.editfailed" : "Error en la modificaci\u00F3n.",
  "message.template.message.createTemplateFail" : "Error al crear la plantilla de notificaci\u00F3n.",
  "ccnotification.gatewayconfig.label.selectrebuildgatewayconfig" : "Seleccione la configuraci\u00F3n de la puerta de enlace que se va a reconstruir",
  "ccnotification.notifition.sendmessage.enterBCCRecipient" : "Selecciona un destinatario de CCO.",
  "ccnotification.notifition.template.button.addvar" : "Crear",
  "ccnotification.notifition.template.message.hwsms" : "Mensajes SMS de HUAWEI CLOUD",
  "ccnotification.notification.label.upload" : "Cargar",
  "message.template.mediaType.email" : "Correo Electr\u00F3nico",
  "message.template.message.inputRecipient" : "Ingrese un destinatario.",
  "ccnotification.gatewayconfig.label.addgatewayconfig" : "Agregar configuraci\u00F3n de puerta de enlace",
  "ccnotification.gatewayconfig.label.destAddrNpi" : "Esquema de c\u00F3digo de direcci\u00F3n de destino",
  "message.template.message.modifySuccess" : "La plantilla de notificaci\u00F3n se modifica correctamente.",
  "ccnotification.notification.systemmonitor.disconnected" : "Desconectado",
  "message.template.message.inputTemplateName" : "Escriba un nombre de plantilla.",
  "ccnotification.gatewayconfig.label.addressRange" : "Rango de direcciones ESME",
  "ccnotification.gatewayconfig.label.userid" : "Identificaci\u00F3n de usuario",
  "ccnotification.notifition.template.label.signatureChannelId" : "Ingrese un ID de canal de firma",
  "ccnotification.notification.label.nameContainSpecialChar" : "El nombre del adjunto de correo electr\u00F3nico contiene caracteres especiales",
  "ccnotification.flowcontrol.getwayset" : "Conjunto",
  "message.center.nocitiction.sending.records.unsend" : "Env\u00EDo programado",
  "ccnotification.gatewayconfig.label.configType" : "Tipo de configuraci\u00F3n",
  "ccnotification.notification.recmessagerouterconfig.mobilerule" : "Por favor\, introduzca los n\u00FAmeros de m\u00F3vil. Separar varios n\u00FAmeros m\u00F3viles con punto y coma",
  "ccnotification.gatewayconfig.label.messageFlowControlMessageDropPolicy" : "Pol\u00EDtica de procesamiento de tr\u00E1fico adicional",
  "message.center.label.modify" : "Modificar",
  "ccnotification.gatewayconfig.label.auth" : "Indicador de autenticaci\u00F3n",
  "ccnotification.gatewayconfig.label.hostIsError" : "El host de correo electr\u00F3nico solo puede ser formato 127.0.0.1 owww.mail.com",
  "ccnotification.common.title.success" : "Exitoso",
  "message.template.message.cancel" : "Cancelar",
  "ccnotification.urlconfig.pageurls.urlvalidate" : "Las urls deben comenzar con https\://",
  "ccnotification.notification.recmessagerouterconfig.placeholder.receiver" : "Por favor ingrese la direcci\u00F3n del destinatario",
  "ccnotification.gatewayconfig.label.systemId" : "Nombre de inicio de sesi\u00F3n",
  "ccnotification.notifition.template.placeholder.var" : "Introduzca valores que cumplan los requisitos.",
  "ccnotification.notifition.sendmessage.selectbyskill" : "Cola de habilidades",
  "ccnotification.gatewayconfig.label.esmClass" : "Tipo de mensaje",
  "ccnotification.gatewayconfig.label.paramIsNotInRange" : "El rango de valores del par\u00E1metro es incorrecto",
  "message.template.message.ccOrBccRecipientError" : "El mensaje se env\u00EDa correctamente. No existe un destinatario Cc o CCO.",
  "ccnotification.notifition.template.label.signatureName" : "Nombre de la firma",
  "ccnotification.gatewayconfig.label.maxRcvErrortitle" : "El m\u00E1ximo de paquetes de error recibidos es un n\u00FAmero adecuado",
  "ccnotification.gatewayconfig.label.heartbeatInterval" : "Intervalo de latidos del coraz\u00F3n (ms)",
  "ccnotification.notification.recmessagerouterconfig.satisfaction" : "Encuesta de satisfacci\u00F3n",
  "message.center.nocitiction.system" : "Bolet\u00EDn del sistema",
  "message.center.label.isRead" : "Marcar los mensajes como le\u00EDdos",
  "ccnotification.gatewayconfig.label.resendWaitTime" : "Reenviar el tiempo de espera (ms)",
  "ccnotification.notifition.sendmessage.sendtimebeforenow" : "La hora de env\u00EDo programada debe ser 5 minutos m\u00E1s tarde que la hora actual.",
  "ccnotification.title.flowcontrol.receiveSmsFlag" : "Recibir mensajes SMS MO",
  "ccnotification.notification.variable.templatevariable" : "Variable de plantilla",
  "ccnotification.flowcontrol.title" : "Establecer configuraci\u00F3n de notificaci\u00F3n",
  "ccnotification.bulletinType.message.Thursday" : "Jueves",
  "ccnotification.gatewayconfig.label.receiverPoolMinCount" : "Pools de hilos de recepci\u00F3n m\u00EDnima",
  "ccnotification.bulletinType.title.createBulletinType" : "Crear tipo de bolet\u00EDn",
  "ccnotification.gatewayconfig.label.splitMsgConcatMode" : "Modo de concatenaci\u00F3n de mensajes largos",
  "ccnotification.gatewayconfig.label.protocolId" : "ID de protocolo",
  "ccnotification.notifition.sendmessage.priorityFault" : "Cr\u00EDtica",
  "ccnotification.notifition.variable.been.used" : "es referenciada por una plantilla y no se puede eliminar.",
  "ccnotification.chatserver.fail.assign" : "Asignaci\u00F3n de agente fallida",
  "ccnotification.bulletinType.message.Monday" : "Lunes",
  "message.template.message.basicInformation" : "Informaci\u00C33n B\u00C3\u00A1sica",
  "ccnotification.notifition.template.label.hwtemplateid" : "ID de plantilla de HUAWEI CLOUD",
  "ccnotification.flowcontrol.emailThreshold" : "Umbral de datos diarios para correos electr\u00F3nicos",
  "ccn.smsnoconfig.pop.title.createtitle" : "Agregar n\u00FAmero de SMS",
  "message.template.message.phoneError" : "El n\u00FAmero de tel\u00E9fono no existe.",
  "ccnotification.gatewayconfig.label.bindModetitle" : "Indica el modo TX/RX. 1\: modo RX; 2\: modo TX; 9\: modo TX/RX.",
  "ccnotification.gatewayconfig.label.INIT" : "Inicializaci\u00F3n",
  "ccnotification.caseserver.fail.serviceException" : "Servicio anormal",
  "ccnotification.gatewayconfig.label.sourceAddrTontitle" : "El tipo de c\u00F3digo de direcci\u00F3n de origen es un n\u00FAmero",
  "ccnotification.notifition.variable.searchVariableName" : "Introduzca un nombre de variable.",
  "ccnotification.gatewayconfig.label.sourceAddrTon" : "Tipo de c\u00F3digo de direcci\u00F3n de origen",
  "ccnotification.smshuawei.message.invalidappadress" : "Direcci\u00F3n de acceso a la aplicaci\u00F3n no v\u00E1lida.",
  "ccnotification.notification.label.downPermissionFailed" : "Error al comprobar el permiso de descarga de archivos adjuntos de correo electr\u00F3nico",
  "ccnotification.smshuawei.label.appAdress" : "Direcci\u00F3n de acceso a la aplicaci\u00F3n",
  "ccnotification.smstemplate.message.signatureName" : "El nombre de la firma no puede estar vac\u00EDo y no puede ser demasiado largo o no cumple con las especificaciones",
  "ccnotification.notification.label.downloadAttachment" : "Descargar archivo adjunto",
  "ccnotification.smstemplate.message.receiverPhone" : "El receptor no puede duplicarse",
  "message.center.nocitiction.inbox" : "Bandeja de entrada",
  "ccn.smsnoconfig.pop.check.duplicatecode" : "El n\u00FAmero ya existe.",
  "ccnotification.gatewayconfig.label.bindMode" : "Modo de recepci\u00F3n y env\u00EDo",
  "ccnotification.notification.label.isDownloadAttachment": "Si el archivo adjunto que estás descargando está en formato de documento de Office, por favor toma las precauciones de seguridad necesarias.",
  "ccnotification.gatewayconfig.label.senderPoolQueueCapacity" : "Longitud de cola de grupo de subprocesos de env\u00EDo",
  "ccnotification.notifition.sendmessage.sysmessage" : "Notificaci\u00F3n del sistema",
  "ccnotification.gatewayconfig.label.smppPduTypeIsError" : "El tipo de PDU SMS SMPP solo puede ser 1\:submit_sm\,2\:data_sm",
  "ccnotification.notification.variable.template" : "Plantilla",
  "ccnotification.gatewayconfig.label.recFlowController" : "Nombre del controlador de tr\u00E1fico de enlace ascendente",
  "message.center.label.reply" : "Rese\u00F1as",
  "ccnotification.gatewayconfig.label.dataCodingtitle" : "0\: cadena ASCII 4\: informaci\u00F3n binaria 8\: codificaci\u00F3n UCS2",
  "ccnotification.gatewayconfig.label.maxRcvError" : "Paquetes de error m\u00E1ximo recibidos",
  "ccnotification.flowcontrol.setShortMsgCode" : "Establezca el n\u00FAmero para los mensajes SMS.",
  "ccnotification.notification.recmessagerouterconfig.serviceType" : "M\u00E9todo de implementaci\u00F3n",
  "ccnotification.gatewayconfig.label.recFlowControllertitle" : "Nombre del controlador de tr\u00E1fico que controla la tasa de mensajes de enlace ascendente.",
  "ccnotification.gatewayconfig.label.statusisnoterror" : "el estado no es ERROR.",
  "ccnotification.notification.label.serverIsNotobs" : "Los archivos adjuntos solo se pueden descargar desde el servidor de archivos OBS",
  "ccnotification.smshuawei.label.config.explain" : "Los datos de configuraci\u00F3n deben ser los mismos que los de HUAWEI CLOUD SMS.",
  "ccnotification.smshuawei.label.certVerifyEnabled" : "Ya sea para habilitar la verificaci\u00F3n del certificado",
  "ccnotification.smshuawei.message.securityInfo" : "Si la verificaci\u00F3n del certificado est\u00E1 desactivada\, existe el riesgo de suplantaci\u00F3n de identidad de pares\. Se recomienda habilitar la verificaci\u00F3n del certificado.",
  "message.center.nocitiction.callback.record" : "Registro de devoluci\u00F3n de llamada",
  "message.center.label.senderName" : "Remitente",
  "ccnotification.notifition.sendmessage.templateselected" : "Este aviso ha hecho referencia a la",
  "ccnotification.gatewayconfig.label.registeredDeliverytitle" : "El informe de confirmaci\u00F3n de estado de devoluci\u00F3n es un n\u00FAmero",
  "ccnotification.gatewayconfig.label.defaultreceinfo" : "ID de recepci\u00F3n predeterminado",
  "ccnotification.agent.contact.downloadFail" : "Error de descarga",
  "ccnotification.notifition.sendmessage.referenceTemplate" : "Plantilla de referencia",
  "ccnotification.notifition.template.placeholder.varnumber" : "Introduzca el n\u00FAmero de variables",
  "ccnotification.notification.label.uploadAttachmentTips" : "Los archivos adjuntos de correo electr\u00F3nico pueden estar en el siguiente formato: doc, docx, xls, xlsx, txt, png, jpg, xml, json, csv, bmp, zip, ppt, pptx, pdf, El tama\u00F1o del archivo no puede superar los 10 MB.Los archivos del paquete comprimido también deben cumplir con el formato anterior.",
  "ccnotification.smstemplate.message.var" : "El tipo de variable, el n\u00FAmero, la descripci\u00F3n o el valor predeterminado no cumplen los requisitos",
  "ccnotification.notifition.sendmessage.bulletinType" : "Tipo de bolet\u00EDn",
  "ccnotification.notification.uploadfail.duplicateFileName" : "Nombre de archivo duplicado.",
  "ccnotification.smshuawei.label.signatureChannelId.length" : "La longitud del n\u00FAmero de canal de firma excede el rango de valores",
  "ccnotification.gatewayconfig.label.smHost" : "Direcci\u00F3n SMG",
  "ccnotification.gatewayconfig.label.messageFlowControlMessageDropPolicytitle" : "Actualmente\, solo se admite Ignorar.",
  "message.center.message.selectMessages" : "Seleccione un mensaje.",
  "ccnotification.notification.label.uploadAttachmentSuccess" : "Cargar datos adjuntos correctamente",
  "ccnotification.notifition.sendmessage.enterusername" : "Escriba un nombre.",
  "ccnotification.flowcontrol.modifySuccess" : "La configuraci\u00F3n de notificaci\u00F3n se modifica correctamente.",
  "ccnotification.bulletinType.message.selectItem" : "Seleccione un tipo de bolet\u00EDn.",
  "ccnotification.bulletinType.message.betchDeleteBulletinTypeSuccessful" : "Los tipos de bolet\u00EDn se eliminan en lotes correctamente.",
  "ccnotification.common.button.new" : "Nuevo",
  "ccnotification.smshuawei.message.updatefail" : "Error al modificar el canal SMS.",
  "ccnotification.bulletinType.message.sendingFrequency" : "frecuencia de env\u00EDo",
  "ccnotification.gatewayconfig.label.SSLEncryptionModetitle" : "Modo de cifrado\, NONE\: transmisi\u00F3n de texto plano (puerto predeterminado\: 25) TLS\: transmisi\u00F3n cifrada usando TLS (puerto predeterminado\: 465) STARTTLS\: transmisi\u00F3n cifrada usando el comando STARTTLS (puerto predeterminado\: 587)",
  "ccnotification.notifition.template.message.charDigitLabel" : "D\u00EDgito y letra",
  "ccnotification.common.message.maxExpireTime" : "Seleccione un tiempo de caducidad no m\u00E1s tarde de {0}.",
  "ccnotification.common.message.sendIntervalShort" : "Env\u00EDo frecuente. Intervalo (s) de env\u00EDo\: {n}.",
  "ccnotification.notifition.template.label.index" : "No",
  "ccnotification.gatewayconfig.label.slipWindowSize" : "Tama\u00F1o de ventana deslizante",
  "ccnotification.smstemplate.message.smstemplateid" : "El ID de plantilla de HUAWEI CLOUD no puede estar vac\u00EDo y no puede ser demasiado largo o no cumple con las especificaciones",
  "ccnotification.gatewayconfig.label.defaultemailaddr" : "Direcci\u00F3n de correo electr\u00F3nico predeterminada",
  "message.template.message.contentLengthError" : "El contenido no puede estar vac\uFFFDo y no puede ser demasiado largo",
  "ccnotification.notification.recmessagerouterconfig.defaultreceiveemail" : "Correo electr\u00F3nico de recepci\u00F3n predeterminado",
  "message.template.message.deleteTemplate" : "Eliminaci\u00F3n de una plantilla de notificaci\u00F3n",
  "message.template.message.deleteSuccess" : "La plantilla de notificaci\u00F3n se elimina correctamente.",
  "ccnotification.gatewayconfig.label.smPassword" : "Contrase\u00F1a de inicio de sesi\u00F3n",
  "ccnotification.notifition.sendmessage.limitdate" : "Seleccione una fecha anterior a 2038-1-19.",
  "ccnotification.notifition.template.placeholder.des" : "Describir la variable",
  "message.template.message.descriptionLengthError" : "La descripci\u00F3n excede el l\u00EDmite.",
  "ccnotification.notification.recmessagerouterconfig.updatenum.outstripping" : "Se puede actualizar un m\u00E1ximo de 100 registros a la vez.",
  "ccnotification.gatewayconfig.label.maxMessageLengthtitle" : "La longitud m\u00E1xima del mensaje (byte) es un n\u00FAmero\, que se especifica en el protocolo\, por ejemplo\, 2048.",
  "ccnotification.notifition.variable.variableType" : "Seleccione un tipo de variable.",
  "ccnotification.gatewayconfig.label.transactionTimeout" : "Intervalo de tiempo de espera de transacci\u00F3n (ms)",
  "ccnotification.notifition.sendmessage.templateselect" : "Seleccionar plantilla",
  "ccnotification.flowcontrol.countryCode" : "C\u00F3digo de pa\u00EDs",
  "message.center.nocitiction.callback.status" : "Estado de devoluci\u00F3n de llamada",
  "ccnotification.gatewayconfig.label.inputCertCode" : "Introduzca el c\u00F3digo del certificado.",
  "ccnotification.notifition.variable.variable" : "Variable",
  "message.center.nocitiction.callback.attachment" : "Archivos adjuntos",
  "ccnotification.notifition.template.message.charDigit" : "- El valor es una cadena de 1 a 20 d\u00EDgitos y letras.",
  "ccnotification.notifition.sendmessage.isSendallSuccess" : "Enviado (\u00C9xito)",
  "ccnotification.gatewayconfig.label.willDeletegateway" : "\u00BFEst\u00E1 seguro de que desea eliminar la configuraci\u00F3n de puerta de enlace seleccionada?",
  "ccnotification.gatewayconfig.label.deletegatewaysuccess" : "Gatewayconfig eliminar correctamente\!",
  "ccnotification.notifition.sendmessage.maxselectcount" : "Se puede seleccionar un m\u00E1ximo de 100 empleados.",
  "ccnotification.notification.label.downloadFromObsFailed" : "Error al descargar archivos desde OBS",
  "ccnotification.notifition.variable.namerule" : "El nombre de la variable solo puede contener los d\u00EDgitos del alfabeto _ y.",
  "ccnotification.notifition.sendmessage.recipient" : "Destinatario",
  "ccnotification.notification.recmessagerouterconfig.receiveaddressexist" : "La direcci\u00F3n de recepci\u00F3n ya existe.",
  "message.center.nocitiction.sending.records.smsType" : "Modo de mensaje SMS",
  "ccnotification.gatewayconfig.label.splitMsgConcatModetitle" : "Modo de combinaci\u00F3n de mensajes SMS largos. 0\: cabecera de datos de usuario GSM; 1\: par\u00E1metro SMPP.",
  "ccnotification.gatewayconfig.label.senderPoolMaxCounttitle" : "El grupo m\u00E1ximo de hilos de env\u00EDo es un n\u00FAmero\, por ejemplo\, 200.",
  "ccnotification.notifition.template.label.type" : "Tipo",
  "message.center.label.receiverGroup" : "Grupo de destinatarios",
  "ccnotification.flowcontrol.messageThreshold" : "Umbral diario de datos para mensajes SMS",
  "message.center.nocitiction.ineermessage" : "Mensaje interno",
  "ccnotification.bulletinType.message.Saturday" : "S\u00E1bado",
  "ccnotification.gatewayconfig.label.destAddrTon" : "Tipo de c\u00F3digo de direcci\u00F3n de destino",
  "message.center.label.select" : "Filtrar",
  "ccnotification.notification.emailsignature.noitemselect" : "No selecciona ning\u00FAn dato.",
  "ccnotification.gatewayconfig.label.senderPoolMinCounttitle" : "El grupo de hilos de env\u00EDo m\u00EDnimo es un n\u00FAmero\, por ejemplo\, 20.",
  "message.template.mediaType.shortmessage" : "SMS",
  "ccnotification.notifition.sendmessage.selecttenant" : "Seleccione un inquilino.",
  "ccnotification.gatewayconfig.label.splitTypetitle" : "Modo de divisi\u00F3n de un mensaje SMS largo. Las opciones son las siguientes\: 0\: no dividido; 1\: no dividido; 2\: dividir f\u00EDsicamente; 3\: dividir mensaje SMS largo.",
  "ccnotification.notification.label.queryAttachmentFailed" : "Error al consultar los datos adjuntos",
  "ccnotification.gatewayconfig.label.resendNumber" : "Tiempos de reenv\u00EDo",
  "ccnotification.gatewayconfig.label.maxContentLength" : "Longitud m\u00E1xima del contenido (bytes)",
  "ccnotification.notifition.gatewaycontact.connecting" : "Conectar",
  "ccnotification.gatewayconfig.label.heartbeatNoRespCount" : "Tiempos m\u00E1ximos de conexi\u00F3n sin respuesta de latidos del coraz\u00F3n",
  "message.template.message.generate" : "Generar plantilla",
  "ccnotification.gatewayconfig.label.mailPorttitle" : "El n\u00FAmero de puerto oscila entre 0 y 65535. El n\u00FAmero de puerto SMTP predeterminado es 25.",
  "ccnotification.bulletinType.title.General" : "Bajo",
  "ccn.smsnoconfig.pop.check.deletefailed" : "Error en la eliminaci\u00F3n.",
  "ccnotification.common.message.maxLength" : "El contenido de texto puede contener un m\u00E1ximo de 4096 caracteres.",
  "ccnotification.notifition.sendmessage.expireTime" : "Caducado el",
  "ccnotification.notifition.sendmessage.internalmessage" : "Notificaci\u00F3n interna",
  "ccnotification.notification.label.isDeleteAttachment" : "Eliminar datos adjuntos",
  "message.center.nocitiction.bulletin" : "Bolet\u00EDn de noticias",
  "ccnotification.notification.recmessagerouterconfig.mediaType" : "Tipo de medios",
  "message.template.message.messageTemplateCreate" : "Crear plantilla de notificaci\u00F3n",
  "ccnotification.notifition.template.button.down" : "Abajo",
  "ccnotification.gatewayconfig.label.code" : "Modo de codificaci\u00F3n",
  "ccnotification.center.message.var.des" : "Descripci\u00F3n de la variable\:",
  "ccnotification.gatewayconfig.label.transactionTimeouttitle" : "El intervalo de tiempo de espera de transacci\u00F3n es un n\u00FAmero adecuado",
  "ccnotification.gatewayconfig.label.messageFlowControlMaxCapsForSend" : "Tasa de env\u00EDo m\u00E1xima",
  "ccnotification.smshuawei.label.appSecret" : "Secreto de aplicaciones",
  "ccnotification.gatewayconfig.label.gatewayipaddrconfig" : "Configuraci\u00F3n de interconexi\u00F3n de direcciones de puerta de enlace",
  "ccnotification.notification.systemmonitor.rebuilding" : "Reconstrucci\u00F3n",
  "ccnotification.notifition.variable.selectvariable" : "Seleccionar variable",
  "ccnotification.smstemplate.message.var.number" : "El n\u00FAmero de variable de entrada es diferente del n\u00FAmero de variables agregadas.",
  "ccnotification.notifition.sendmessage.invalidSect" : "Segmento de tiempo de caducidad",
  "ccnotification.bulletinType.message.sendTimeParamsError1" : "La hora de publicaci\u00F3n no puede ser anterior a la hora actual",
  "ccnotification.gatewayconfig.label.modifygatewayfailed" : "Error al modificar Gatewayconfig\!",
  "ccnotification.bulletinType.message.sendTimeParamsError2" : "El tiempo de liberaci\u00F3n no puede ser posterior al tiempo de expiraci\u00F3n",
  "ccnotification.gatewayconfig.label.bindModeIsError" : "Modo de env\u00EDo y recepci\u00F3n de SMS 1\: modo de recepci\u00F3n\, 2\: modo de transmisi\u00F3n\, 9\: modo TX / RX",
  "ccnotification.gatewayconfig.label.configNameAlreadyExists" : "El nombre de configuraci\u00F3n ya existe",
  "ccnotification.notifition.sendmessage.sendexception" : "Error al enviar.",
  "ccnotification.gatewayconfig.label.authIsError" : "El ID de autenticaci\u00F3n solo puede ser verdadero o falso",
  "message.template.message.recipient" : "Destinatario",
  "ccnotification.notifition.sendmessage.inputBulletinType" : "Introduzca el nombre del tipo de bolet\u00EDn.",
  "ccnotification.gatewayconfig.label.errorstylenumber" : "Formato de n\u00FAmero incorrecto.",
  "message.center.button.query" : "Consultar",
  "ccn.smsnoconfig.table.label" : "N\u00FAmero de SMS",
  "ccnotification.notifition.sendmessage.enterCCRecipient" : "Selecciona un destinatario de Cc.",
  "ccnotification.bulletinType.message.nameIsNotUnique" : "El nombre del tipo de bolet\u00EDn ya existe.",
  "ccnotification.notifition.template.message.money" : "- El valor puede contener de 1 a 20 d\u00EDgitos. Solo se pueden transferir d\u00EDgitos y decimales que puedan expresar correctamente la cantidad.",
  "ccnotification.notifition.sendmessage.senddate" : "Fecha de env\u00EDo",
  "ccnotification.bulletinType.message.decimealsCheck" : "El tiempo de caducidad predeterminado no puede contener un decimal.",
  "ccnotification.agent.contact.workno" : "ID de empleado",
  "ccnotification.gatewayconfig.label.messageFlowControlMaxCapsForSendtitle" : "La tasa m\u00E1xima de env\u00EDo es un n\u00FAmero",
  "message.template.message.titleLengthError" : "El t\u00EDtulo no puede superar los 200 caracteres ni contener caracteres especiales.",
  "message.center.button.clear" : "Restablecer",
  "ccnotification.notifition.sendmessage.sendfailed" : "Error al enviar.",
  "ccn.smsnoconfig.table.header.countrycode" : "C\u00F3digo de pa\u00EDs del remitente",
  "ccnotification.bulletinType.title.Rushse" : "Alta",
  "ccnotification.gatewayconfig.label.paramIsNegative" : "El n\u00FAmero no puede ser negativo",
  "ccnotification.notifition.template.title.setting" : "Establecer variable",
  "ccnotification.smshuawei.message.deletesuccess" : "El canal SMS se elimina correctamente.",
  "ccnotification.gatewayconfig.label.smPorttitle" : "Rango de n\u00FAmero de puerto SMG\: un entero comprendido entre 0 y 65535",
  "ccnotification.gatewayconfig.label.resendWaitTimetitle" : "El tiempo de espera de reenv\u00EDo es un n\u00FAmero adecuado",
  "ccnotification.notifition.gatewaycontact.failed" : "Error de conexi\u00F3n",
  "ccnotification.gatewayconfig.label.certFile" : "Archivo de certificado",
  "ccnotification.notifition.sendmessage.sender" : "N\u00FAmero de remitente",
  "ccnotification.gatewayconfig.label.maxsmGatewayIsError" : "Se puede configurar un m\u00E1ximo de 10 puertas de enlace SMS en el mismo espacio de inquilino",
  "ccnotification.gatewayconfig.label.destAddrTontitle" : "El tipo de c\u00F3digo de direcci\u00F3n de destino es un n\u00FAmero",
  "message.template.message.deleteTemplateFail" : "Error al eliminar la plantilla de notificaci\u00F3n.",
  "ccnotification.notifition.template.message.text" : "- El valor es una cadena de 1 a 20 caracteres y no puede contener 000",
  "ccnotification.notifition.sendmessage.messagetitle" : "Titulo",
  "ccnotification.gatewayconfig.label.addrNpi" : "Modo de codificaci\u00F3n de direcciones ESME",
  "message.center.label.download" : "Descargar",
  "ccnotification.smshuawei.message.fail" : "Error",
  "ccnotification.notifition.sendmessage.ccRecipient" : "Cc",
  "ccnotification.notification.systemmonitor.gatewayName" : "Nombre de puerta de enlace",
  "ccnotification.notification.recmessagerouterconfig.receivernotexist" : "El destinatario seleccionado no existe.",
  "message.center.label.expireTime" : "Caducado el",
  "ccnotification.notification.label.createTemFileFailed" : "Error al crear el archivo temporal",
  "message.center.nocitiction.message" : "SMS",
  "ccnotification.bulletinType.message.weekly" : "semanales",
  "ccnotification.gatewayconfig.message.authInfo" : "Establecer false tiene riesgos de seguridad. Se recomienda establecer true para habilitar la autenticaci\u00F3n de contrase\u00F1a.",
  "ccnotification.gatewayconfig.label.protocol" : "Tipo de protocolo",
  "ccnotification.sms.rcvAndSendMode.receiveSms" : "Recibir mensajes SMS",
  "ccnotification.sms.rcvAndSendMode.sendSms" : "Enviar mensajes SMS",
  "ccnotification.sms.rcvAndSendMode.receiveAndSendSms" : "Enviar y recibir mensajes SMS",
  "ccnotification.sms.smppPduTye.submit.sm" : "submit_sm",
  "ccnotification.sms.gms.user.data.head" : "Encabezado de datos de usuario GSM",
  "ccnotification.sms.smppPduTye.data.sm" : "data_sm",
  "ccnotification.sms.smpp.replaceIfPresentFlag.replace" : "Reemplazar ",
  "ccnotification.sms.smpp.parameters" : "Par\u00E1metros SMPP",
  "ccnotification.sms.smpp.replaceIfPresentFlag.not.replace" : "No reemplazar",
  "ccnotification.gatewayconfig.label.splitType.noSplit" : "no dividido",
  "ccnotification.gatewayconfig.label.splitType.disabled" : "disabled",
  "ccnotification.sms.smpp.ESME.Connection.Configuration" : "Configuraci\u00F3n de conexi\u00F3n ESME",
  "ccnotification.gatewayconfig.label.splitType.physical" : "divisi\u00F3n f\u00EDsica",
  "ccnotification.gatewayconfig.label.splitType.ultraLong" : "divisi\u00F3n ultralarga",
  "ccnotification.gatewayconfig.label.dataCoding.ASCII" : "cadena ASCII",
  "ccnotification.sms.smpp.stow.away" : "Ret\u00EDrese",
  "ccnotification.gatewayconfig.label.dataCoding.UCS2" : "codificaci\u00F3n UCS2",
  "ccnotification.gatewayconfig.label.basic.configuration" : "Configuraci\u00F3n b\u00E1sica",
  "ccnotification.gatewayconfig.label.dataCoding.Binary" : "informaci\u00F3n binaria",
  "ccnotification.notification.recmessagerouterconfig.defaultsendnumber" : "N\u00FAmero de env\u00EDo predeterminado.",
  "ccnotification.notification.recmessagerouterconfig.defaultreceivenumber" : "N\u00FAmero de recepci\u00F3n predeterminado.",
  "ccnotification.gatewayconfig.label.advancedconfig" : "Configuraci\u00F3n avanzada",
  "ccnotification.template.message.organizationStructure" : "Organización",
  "ccnotification.template.message.alignment" : "El número de opciones de tarjeta no puede exceder de",
  "ccnotification.email.signature.message.uploadImgTypeError" : "Solo se pueden cargar im\u00E1genes en formato .jpg .jpeg o .png",
  "ccnotification.email.signature.knowledge.message.uploadImgSizeError" : "El tama\u00F1o de la imagen cargada no puede exceder {}",
  "ccnotification.email.signature.knowledge.message.uploadError" : "El contenido de la firma del correo electr\u00F3nico contiene im\u00E1genes de tama\u00F1o o formato incorrectos. Elimine las im\u00E1genes antes de realizar la operaci\u00F3n",
  "ccn.flow.grayrule.editsuccess" : "Modificado con \u00E9xito.",
  "ccn.voiceedit.addsuccess" : "Agregando correctamente.",
  "ccn.voiceedit.addfail" : "Error al agregar.",
  "ccnotification.notification.emailsignature.selection.userEmail" : "Correo electr\u00F3nico del usuario",
  "ccnotification.notification.emailsignature.selection.userPosition" : "Posici\u00F3n de usuario",
  "ccn.voiceedit.deletesuccess" : "Se ha eliminado correctamente.",
  "ccnotification.notifition.template.create" : "Creación de una plantilla de notificación",
  "ccnotification.template.message.channelType" : "Tipo de canal",
  "pop.org.window.org" : "Inicio Organización",
  "ccnotification.template.message.add.variable": "Añadir",
  "ccnotification.template.message.content": "Contenido de la plantilla",
  "ccnotification.template.message.enterDescription": "Por favor, introduzca una descripción.",
  "ccnotification.notification.systemmonitor.batchrebuild":"Reconstrucción por lotes",
  "ccnotification.notification.systemmonitor.batchdisconnect":"Desconexión por lotes",
  "ccnotification.gatewayconfig.label.send.prefix":"Prefijo del número de remitente",
  "ccnotification.gatewayconfig.label.receive.prefix":"Prefijo de número aceptado",
  "ccnotification.signature.add":"Crear firma de correo electrónico",
  "ccnotification.signature.update":"Modificar firma de correo electrónico",
  "ccnotification.signature.view":"Ver firma de correo electrónico",
  "ccnotification.gatewayconfig.label.select.sendNumber.title":"Selección del número de remitente",
  "ccnotification.gatewayconfig.label.add.sendNumber.title":"Adición de un número de remitente",
  "ccn.whitelist.urlvalidatefailed":"Ingrese IP o nombre de dominio",
  "ccnotification.notification.notificationRoute.recipientAddress" : "Dirección de envío",
  "ccnotification.notification.notificationRoute.implementationMode" : "Modo de implementación",
  "ccnotification.notification.notificationRoute.edit" : "Editar",
  "ccnotification.notification.notificationRoute.newNotificationRoute" : "Nueva ruta de notificación",
  "ccnotification.notification.notificationRoute.editNotificationRoute" : "Editar ruta de notificación",
  "ccnotification.notification.notificationRoute.inbox" : "Bandeja de entrada",
  "ccnotification.notification.notificationRoute.serviceInvoking" : "Invocación de servicio",
  "ccnotification.notification.notificationRoute.selectconfig" : "Seleccione una configuraci\u00F3n.",
  "ccnotification.notification.notificationRoute.willdeleteselectconfig" : "\u00BFEst\u00E1 seguro de que desea eliminar la configuraci\u00F3n seleccionada?",
  "ccnotification.notification.notificationRoute.deletenum.outstripping" : "Se puede eliminar un m\u00E1ximo de 100 registros a la vez.",
  "ccnotification.notification.notificationRoute.delSuccess" : "Se ha eliminado correctamente.",
  "ccnotification.notification.notificationRoute.delFail" : "Error en la eliminación.",
  "ccnotification.notification.notificationRoute.gateway" : "Puerta de enlace",
  "ccnotification.notification.notificationRoute.gatewaynotexist" : "La puerta de enlace seleccionada no existe",
  "ccnotification.notification.notificationRoute.receivernotexist" : "El destinatario seleccionado no existe",
  "ccnotification.notification.notificationRoute.receiveaddressexist" : "La dirección de recepción ya existe",
  "ccnotification.notification.notificationRoute.intelligentOrder" : "Creación inteligente de casos",
  "ccnotification.notification.notificationRoute.mobilerule" : "Por favor\\, introduzca los números de móvil. Separar varios números móviles con punto y coma",
  "ccnotification.notification.notificationRoute.inputPhoneNum" : "Introduzca un número de teléfono móvil",
  "ccnotification.notification.notificationRoute.receiveAddrLen" : "La longitud no puede superar los 1024 caracteres",
  "ccnotification.notification.notificationRoute.retryNumLimitRule" : "El valor de entrada está fuera del rango: 0-3",
  "ccn.variable.willdeleteselectvariable": "¿Está seguro de que desea eliminar las variables seleccionadas?",
  "ccn.variable.deleteselectvariable": "Eliminar variable",
  "ccn.variable.variableisreferenced": "Las variables son referenciadas por el flujo.",
  "ccn.variable.view.references": "Ver referencias",
  "ccn.variable.view.references.none": "La variable no se hace referencia actualmente",
  "ccn.variable.selectvariable": "Seleccione un parámetro variable.",
  "ccn.variable.field": "Variable de notificación",
  "ccn.unit.bytes": "Octetos",
  'ccn.unit.millisecond': 'milisegundos',
  "ccn.message.fullscreen": "Maximizar",
  'ccn.sms.config.batch.delete': 'Eliminación por lotes',
  'ccn.sms.config.receiver.uplinkMess': 'Recibir mensajes SMS MO',
  "ccn.sms.config.create.smsConfig": "Nueva configuración de SMS",
  "ccn.sms.config.modify.smsConfig": "Modificación de configuraciones de SMS",
  "ccnotification.smstemplate.message.edit.fail": "Error al modificar la plantilla SMS de HUAWEI CLOUD.",
  "ccnotification.message.choose": "Por favor, seleccione los datos.",
  "ccnotification.message.add.attachement": "Añadir adjunto",
  "ccnotification.message.defaultsenderid.error": "El número de remitente predeterminado no está en el rango permitido. Seleccione un número correcto.",
  "ccnotification.template.message.emailAddressEmpty": "Correo del destinatario no configurado:",
  "ccn.countrycode.type": "Tipo de prefijo de código de país",
  "ccn.countrycode.type.with.plus": "Prefijado con +",
  "ccn.countrycode.type.without.plus": "Sin prefijo con +"
}