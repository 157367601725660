<template>
  <sweet-dialog v-model="dialogVisible" :title="$t('ccm.agent.message.addSkill')" resize="false"
                width="616px"
                :close-on-click-modal="false" :close-on-press-escape="false"
                :destroy-on-close="true">
    <div style="margin-bottom: 5px">
      <sweet-input type="text" v-model="searchContent" class="dialog-fixed"  style="margin-bottom: 9px"
                   :placeholder="$t('ccm.agent.label.skillName')" clearable @clear="handleRefresh"
                   @keyup.enter="handleRefresh">
        <template #prefix>
          <sweet-icon class="input-icon" @click="handleRefresh()" :title="$t('chat.weChatConfig.label.search')">
            <search/>
          </sweet-icon>
        </template>
      </sweet-input>
      <div style="display: inline-block;" @click="handleRefresh()" class="new-refresh-icon">
        <img class="img-icon refresh-icon-img" src="@/assets/img/search/search_refresh.png"/>
      </div>
    </div>
    <aicc-table class="aicc-table" ref="queueTable" :tableColumns="tableColumns" :tableData="skillQueueData.value"
                :border="false" v-loading="false"
                @select-all="handleSelectAll" @select="handleSelect" :row-key="getKey"
                :tableTools="{ showPagination: true, columnConfig: false }"
                :paginationConfig="this.paginationConfig" @handlePagination="pageSkillQueue"/>
    <template #footer>
      <span class="dialog-footer">
        <sweet-button @click="closeDialog"
                      id="aicc-ccdesktop-resetSkillQueue-dialog-cancel">{{ $t("ccd.transfer.button.cancel") }}
        </sweet-button>
        <sweet-button type="primary" @click="saveSelectedSkill"
                      id="aicc-ccdesktop-resetSkillQueue-dialog-confirm">{{ $t("aicc.ok") }}
        </sweet-button>
      </span>
    </template>
  </sweet-dialog>
</template>

<script>
import {getAllSkill} from '@/views/ccmanagement/ccma-api/index';
import $ from 'jquery';
import {getCurrentInstance, reactive, ref} from "vue";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import enUs from 'element-plus/dist/locale/en.min.mjs';
import fr from 'element-plus/dist/locale/fr.mjs';
import es from 'element-plus/dist/locale/es.mjs';
import ptBr from "element-plus/dist/locale/pt-br.mjs";
import th from 'element-plus/dist/locale/th.mjs';
import ar from 'element-plus/dist/locale/ar.mjs';
import AiccElMessage from '@/utils/el-message';
import {mediaTypeMap, subMediaTypeMap} from "@/views/ccmanagement/common/constants";
import {querySocialMediaByAccessMode} from "@/views/ccmanagement/externals-api/ccmessage";

window.$ = $;
window.jQuery = $;
const lang = ref();
const langMap = new Map([
  ["zh_CN", zhCn],
  ['en_US', enUs],
  ['fr_FR', fr],
  ['th_TH', th],
  ['pt_BR', ptBr],
  ['es_ES', es],
  ['ar_SA', ar]
]);
const toSelectSkillQueue = ref(new Set());
const selectedSkillQueue = ref(new Set());
const skillInfoList = ref([]);
const hasSelectSkill = ref([]);
const curId = ref('');
const allSkill = ref([]);
const newSocialMedias = ref([])

const getMediaType = (key) => {
  return mediaTypeMap.get(Number(key)) || "";
}

const getSubMediaType = (key) => {
  const arr = newSocialMedias.value.filter(e => e.socialId == key);
  if (arr && arr.length > 0) {
    return arr[0].socialCode;
  }
  return subMediaTypeMap.get(Number(key)) || "";
}

const queryNewSocialMedia = () => {
  querySocialMediaByAccessMode({"accessMode": 1}).then((data) => {
    newSocialMedias.value = data;
    dealNewSocialMedia()
  })
}

const dealNewSocialMedia = () => {
  newSocialMedias.value.forEach(item => {
    if (subMediaTypeMap.has(Number(item.socialId))) {
      item.socialCode = subMediaTypeMap.get(Number(item.socialId));
    }
  })
}

export default {
  name: 'SelectSkill',
  props: {
    show: Boolean,
    hasSelectSkill: {
      type: Array,
      required: false
    },
    hideSkillId: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup() {
    const {
      appContext: {
        config: {globalProperties}
      }
    } = getCurrentInstance()
    return {globalProperties}
  },
  data() {
    return {
      templateMetaData: {
        basicConfig: { // 基础搜索配置
          iconButton: [{buttonType: 'refresh'}], // 图标按钮
          iconAreaColConfig: {xs: 8, sm: 8, lg: 8, xl: 8},
          basicSearch: [
            {
              searchType: 'primary', // primary: 有搜索图标的输入框；select: 下拉框; input: 搜索框；date: 日期选择器
              modelValue: 'searchContent', // v-model绑定的key值
              modelDefault: '', // v-model绑定的默认值
              placeholderValue: this.$t('ccm.skillgroup.label.searchname'), // placeholder绑定的值 国际化之后的值
              rules: [],
              clearEvent: 'resetSearchContent'
            }
          ]
        },
      },
      searchContent: "",
      skillQueueData: ref([]),
      loading: ref(true),
      isFirstQuery: ref(false),
      // 分页配置
      paginationConfig: reactive({
        pageSizes: [10, 20, 50, 100],
        pageSize: 10,
        total: 0,
        layout: "sizes, total, prev, pager, next",
        currentPage: 1
      }),
      result: ref([]),
      // 表头数据
      tableColumns: reactive([
        {
          type: "selection",
          "reserve-selection": true,
          'show-overflow-tooltip': false,
          width: '40px',
          radiokey: "skillId"
        },
        {
          label: $t("ccm.agent.label.skillId"),
          prop: "skillId",
          "show-overflow-tooltip": true,
          display: !this.hideSkillId
        },
        {
          label: $t("ccm.agent.label.skillName"),
          prop: "name",
          "show-overflow-tooltip": true
        },
        {
          label: $t("ccivr.ivr.ivrflow.type"),
          prop: "mediaTypeId",
          width: '300px',
          'min-width': '300px',
          fixed: "right",
          "show-overflow-tooltip": true
        }
      ])
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      }
    }
  },
  methods: {
    handleRefresh() {
      if (!this.loading) {
        this.loading = true;
        this.querySkillInfoList();
      }
    },
    saveSelectedSkill() {
      if (selectedSkillQueue.value.size == 0) {
        AiccElMessage.info($t("ccm.calledconfig.message.noskill"));
        return;
      }
      const selectSkillObject = Array.from(allSkill.value
          .filter(elem => {
            return selectedSkillQueue.value.has(elem.skillId)
          }).map((item) => item));
      this.$emit('selectSkills', selectSkillObject);
      this.closeDialog();
    },
    closeDialog() {
      this.dialogVisible = false;
      this.$refs.queueTable.table.clearSelection();
      selectedSkillQueue.value.clear();
      this.skillQueueData.value = [];
      skillInfoList.value = []
      allSkill.value = []
    },
    /**
     * 查询技能队列信息
     */
    async querySkillInfoList() {
      skillInfoList.value = [];
      allSkill.value = []
      this.skillQueueData.value = [];
      await getAllSkill({}).then((data) => {
        for (const skillInfo of data) {
          let mediaTypeId = ''
          if (skillInfo.mediaTypeId != undefined && skillInfo.mediaTypeId != null) {
            mediaTypeId = getMediaType(skillInfo.mediaTypeId);
            if (skillInfo.mediaTypeId == 53 && skillInfo.subMediaTypeId != undefined && skillInfo.subMediaTypeId != null) {
              mediaTypeId = mediaTypeId + '-' + getSubMediaType(skillInfo.subMediaTypeId)
            }
          }
          allSkill.value.push({
            name: skillInfo.name,
            skillId: skillInfo.skillId,
            mediaTypeId: mediaTypeId
          });
          if (!this.searchContent || skillInfo.name.indexOf(this.searchContent) !== -1) {
            skillInfoList.value?.push({
              name: skillInfo.name,
              skillId: skillInfo.skillId,
              mediaTypeId: mediaTypeId
            });
          }
        }
        const end = Math.min(10, skillInfoList.value.length);
        for (let i = 0; i < end; ++i) {
          this.skillQueueData.value.push(skillInfoList.value[i]);
        }
        this.paginationConfig.total = skillInfoList.value.length;
      }).finally(() => {
        this.initSelected()
        this.loading = false;
      });
    },
    initSelected() {
      if (selectedSkillQueue.value.length > 0) {
        this.$refs.queueTable.table.toggleRowSelection(row, true)
        return;
      }
      skillInfoList.value.forEach(row => {
        if (this.hasSelectSkill.some(item => row.skillId == item)) {
          this.$nextTick(() => {
            selectedSkillQueue.value.add(row.skillId);
            this.$refs.queueTable.table.toggleRowSelection(row, true)
          })
        }
      })
    },
    getKey(row) {
      return row.skillId;
    },
    handleSelectAll(selection) {
      const selectionSet = new Set(Object.values(selection).map(item => this.getKey(item)));
      selectedSkillQueue.value.clear();
      selection.forEach(data => selectedSkillQueue.value.add(data.skillId));
    },
    handleSelect(selection, row) {
      const selectionSet = new Set(Object.values(selection).map(item => this.getKey(item)));
      const skillId = this.getKey(row);
      if (selectionSet.has(skillId)) {
        selectedSkillQueue.value.add(skillId)
      } else {
        selectedSkillQueue.value.delete(skillId);
      }
    },
    // 技能队列分页 因为后端接口不存在分页查询，故只能够在前端进行分页处理
    pageSkillQueue(params) {
      this.skillQueueData.value = [];
      const start = params.offset;
      const end = Math.min(start + params.limit, skillInfoList.value.length);
      for (let i = start; i < end; ++i) {
        this.skillQueueData.value.push(skillInfoList.value[i]);
        const skillId = this.getKey(skillInfoList.value[i]);
        if (selectedSkillQueue.value.has(skillId)) {
          this.$refs.queueTable.table.toggleRowSelection(skillInfoList.value[i], true);
        }
      }
    }
  },
  mounted() {
    this.loading = true;
    this.querySkillInfoList();
  },
  beforeMount() {
    queryNewSocialMedia();
  },
  async created() {
    if (this.$route.query.type == 'modify') {
      curId.value = this.$route.query.id;
    }
  }
}
</script>
<style lang="less" scoped>
.aicc-table {
  width: 576px;
  height: 100%;
  display: flex;
  overflow: auto;
  flex-direction: column;
}

.dialog-fixed {
  width: 240px;
  height: 32px;
  margin-inline-end: 0.5rem;
}

.input-icon {
  cursor: pointer;
  transition: all 0.2s;

  &:active {
    transform: scale(0.9);
  }
}
.new-refresh-icon {
  width: 32px;
  height: 32px;
  background: #FFFFFF;
  border: 1px solid rgb(175, 175, 175);
  border-radius: 8px;
  text-align: center;
  cursor:pointer;
}

.new-refresh-icon:hover{
  border: 1px solid #4d4d4d;
}
.img-icon {
  width: 16px;
  height: 20px;
  padding-block-start: 4px;
  margin-top: 3px;
}
.form-background {
  background-color: rgb(255, 255, 255);
}
</style>