<template>
  <div>
    <sweet-dialog v-model="dialogVisible" :title="$t('oifde.ivrJourney.chooseAccessCode')" width="616px" class="aicc-dialog-limit-height" :close-on-click-modal="false" :close-on-press-escape="false">
      <div style="width:240px; margin-bottom: 20px">
        <sweet-form
            ref="ruleFormRef"
            :model="queryForm">
          <sweet-form-item prop="chatBotName">
            <sweet-input
                v-model="queryForm.chatBotName"
                :placeholder="$t('oifde.chatBotTest.robotName')"
                @keydown.enter="queryResult"
                clearable
            >
              <template #prefix>
                <sweet-icon
                    class="input-icon"
                    @click="queryResult"
                    :title="$t('oifde.accesscode.chatBot.search')">
                  <Search />
                </sweet-icon>
              </template>
            </sweet-input>
          </sweet-form-item>
        </sweet-form>
      </div>
      <aicc-table
          :tableData="showWorkDataList"
          :tableColumns="tableColumns"
          :tableTools="{ showPagination: true, columnConfig: false }"
          :paginationConfig="paginationConfig"
          @handlePagination="handlePagination"
          :height="430"
          ref='refAiccTable'>
      </aicc-table>
      <template #footer>
      <span class="dialog-footer">
        <sweet-button @click="dialogVisible = false">{{ $t('isales.common.title.cancel') }}</sweet-button>
        <sweet-button type="primary" @click="confirm">{{ $t('isales.common.title.confirm') }}</sweet-button>
      </span>
      </template>
    </sweet-dialog>
  </div>
</template>

<script>
import {queryAgentInfoByCond} from "@/views/isales/api/agentWorkStatistics";
import {ElMessage} from "element-plus";
import {Search} from '@element-plus/icons-vue';
import cookieUtils from "@/utils/cookie";
import {queryAccessCodes} from "@/views/oifde/request/api/ivrJourneyNodeDetail";
export default {
  name: 'ivrCallDstIdSelect',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    accessCode: String,
    source: String,
  },
  mounted() {
    this.queryList()
  },
  data() {
    return {
      Search,
      showWorkDataList: [],
      tableColumns: [
        {
          type: 'radio',
          radioKey: 'accessCode', // 单选绑定的字段名
          width: '48px'
        },
        {
          label: this.$t('oifde.accesscode.robotName'),
          prop: 'chatBotName',
        },
        {
          label: this.$t('oifde.accesscode.flowName'),
          prop: 'flowName',
        },
        {
          label: this.$t('oifde.accesscode.accessCode'),
          prop: 'accessCode',
        }
      ],
      paginationConfig: {
        PageSize: 10, // 每页条数
        total: 0, //总条数
        layout: "sizes, total, prev, pager, next" // 分页组件布局配置
      },
      pageSize: 10,
      pageNum: 1,
      queryForm: {
        chatBotName: '',
      },
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      }
    }
  },
  methods: {
    async queryResult() {
      await this.queryList();
    },
    async queryList() {
      let queryParam = {
        pageNum:this.pageNum,
        pageSize:this.pageSize,
        source: this.source || 'ALL',
        tenantId: cookieUtils.getCookie('tenant_space_id')
      }
      if (this.queryForm.chatBotName) {
        queryParam["keyword"] = this.queryForm.chatBotName;
      }
      queryAccessCodes(queryParam).then(res => {
        this.showWorkDataList = res.accessInfoList;
        this.paginationConfig.total = res.total;
        this.$refs.refAiccTable.setRadioCurrentValue(this.accessCode);
      })
    },
    handlePagination(params) {
      this.pageNum = params.pageNum
      this.pageSize = params.pageSize
      this.queryResult();
    },
    confirm() {
      if ((!this.$refs.refAiccTable.getRadioCurrentRow() && !this.accessCode)) {
        ElMessage({
          type: 'warning',
          duration: 10000,
          offset: 56,
          'show-close': true,
          message: this.$t('oifde.ivrJourney.pleaseSelectData'),
        });
        return;
      }
      this.dialogVisible = false;
      this.$emit('ivrCallDstIdSelectConfirm',  this.$refs.refAiccTable.getRadioCurrentRow())
    }
  }
}
</script>

<style scoped lang="less">
.input-with-select .el-input-group__prepend {
  background-color: #ffffff;
}

.input-icon {
  cursor: pointer;
  transition: all 0.2s;

  &:active {
    transform: scale(0.9);
  }
}
</style>