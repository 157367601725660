export default {
  'oifde.traceCause.000001': 'ไม่ได้กำหนดค่าประเภทการโอนสำหรับองค์ประกอบแผนภาพการโอนสาย',
  'oifde.traceCause.000002': 'อินเทอร์เฟซมีความผิดปกติเนื่องจากค่าอินพุตของการอยู่เฉยไม่ถูกต้อง {0}',
  'oifde.traceCause.000003': 'ไม่พบองค์ประกอบแผนภาพคอมโพสิต',
  'oifde.traceCause.000004': 'ไม่พบโหนดเริ่มต้น',
  'oifde.traceCause.000005': 'ระบบมีความผิดปกติเนื่องจากจำนวนครั้งในการรีไซเคิลภายในเกินขีด จำกัด',
  'oifde.traceCause.000006': 'การประมวลผลองค์ประกอบแผนภาพมีความผิดปกติ',
  'oifde.traceCause.000007': 'สาขาทางออกว่างเปล่า',
  'oifde.traceCause.000008': 'ข้อผิดพลาดภายในระบบ',
  'oifde.traceCause.000009': 'ไม่พบสาขา {0}',
  'oifde.traceCause.000010': 'ไม่สามารถโอนองค์ประกอบแผนภาพการโอน Flow ไปยังโหนดถัดไปได้ {0}',
  'oifde.traceCause.000011': 'ประเภทองค์ประกอบแผนภาพไม่ถูกต้อง',
  'oifde.traceCause.000012': 'ข้อผิดพลาดในการดำเนินการนิพจน์ {0}',
  'oifde.traceCause.000013': 'เรียกใช้อินเทอร์เฟซของบุคคลที่สามล้มเหลว',
  'oifde.traceCause.000014': 'ไม่ได้เลือกเทมเพลตการตอบสนองและตัวแปรการตอบสนอง',
  'oifde.traceCause.000015': 'ไม่ได้กำหนดค่าเทมเพลต SMS สำหรับองค์ประกอบแผนภาพการส่ง SMS',
  'oifde.traceCause.000016': 'ภาษาของเทมเพลตการตอบสนองไม่สอดคล้องกับภาษาที่ตั้งไว้ {0}',
  'oifde.traceCause.000017': 'ภาษาของเทมเพลต SMS แตกต่างจากภาษาที่กำหนดในองค์ประกอบแผนภาพการส่ง SMS {0}',
  'oifde.traceCause.000018': 'ไม่ได้กำหนดค่าโฟลว์เป้าหมายและโหนดเป้าหมายสำหรับองค์ประกอบแผนภาพการโอน Flow',
  'oifde.traceCause.000019': 'ไม่พบผู้เช่า',
  'oifde.traceCause.000020': 'ผู้เช่าถูกระงับการใช้งาน',
  'oifde.traceCause.000021': 'โมดูลถูกกำหนดค่าตั้งต้นซ้ำ ๆ',
  'oifde.traceCause.000022': 'องค์ประกอบแผนภาพคอมโพสิตไม่มีโหนดเริ่มต้น',
  'oifde.traceCause.000023': 'ข้อผิดพลาดภายในระบบ',
  'oifde.traceCause.000024': 'การดำเนินการโฟลว์ภายในมีความผิดปกติ',
  'oifde.traceCause.000025': 'การวางสายอัตโนมัติจะดำเนินการหากมีข้อยกเว้นภายในเกิดขึ้นมากกว่าสามครั้ง',
  'oifde.traceCause.000026': 'โฟลว์ไม่ได้ถูกกำหนดค่าตั้งต้น',
  'oifde.traceCause.000028': 'โฟลว์สิ้นสุดลงแล้ว',
  'oifde.traceCause.000029': 'ไม่พบทางออกในองค์ประกอบแผนภาพปัจจุบันซึ่งมี ID โหนดคือ {0} และชื่อโหนดคือ {1}',
  'oifde.traceCause.000030': 'ข้อมูลขององค์ประกอบแผนภาพที่ ID โหนดคือ {0} และชื่อโหนดคือ {1} มีความผิดปกติ',
  'oifde.traceCause.000031': 'ข้อผิดพลาดภายในระบบ',
  'oifde.traceCause.000032': 'การดำเนินการโฟลว์ภายในมีความผิดปกติ',
  'oifde.traceCause.000033': 'ไม่มีข้อมูลอินสแตนซ์การโทร',
  'oifde.traceCause.000034': 'ไม่มีองค์ประกอบแผนภาพการสิ้นสุดการโทร',
  'oifde.traceCause.000035': 'เกิดข้อผิดพลาดเมื่อตรวจสอบ auth_token',
  'oifde.traceCause.000036': 'เกิดข้อผิดพลาดเมื่อตรวจสอบ auth_token',
  'oifde.traceCause.000037': 'เกิดข้อผิดพลาดเมื่อตรวจสอบ auth_token',
  'oifde.traceCause.000038': 'ประมวลผลโหนดถัดไปล้มเหลว',
  'oifde.traceCause.000039': 'เรียกใช้ TUC ล้มเหลว {0}',
  'oifde.traceCause.000040': 'ไม่พบข้อผิดพลาด {0}',
  'oifde.traceCause.000041': 'ข้อผิดพลาดในการดำเนินการตามขั้นตอนที่จัดเก็บ {0}',
  'oifde.traceCause.000044': 'URL (ที่อยู่ IP และหมายเลขพอร์ต) ไม่อยู่ในรายการที่เชื่อถือได้',
  'oifde.traceAdvice.000001': 'กำหนดค่าประเภทการโอนสายสำหรับองค์ประกอบแผนภาพการโอนสาย',
  'oifde.traceAdvice.000002': 'ติดต่อผู้ดูแลระบบเพื่อประมวลผลพารามิเตอร์อินพุตของอินเทอร์เฟซ',
  'oifde.traceAdvice.000003': 'ตรวจสอบว่าองค์ประกอบแผนภาพคอมโพสิตมีอยู่ในระบบหรือไม่',
  'oifde.traceAdvice.000004': 'ตรวจสอบว่าโฟลว์ถูกปล่อยออกมาหรือไม่หรือโฟลว์เป็นปกติ',
  'oifde.traceAdvice.000005': 'ติดต่อผู้ดูแลระบบเพื่อตรวจสอบระบบ',
  'oifde.traceAdvice.000006': 'ติดต่อผู้ดูแลระบบเพื่อตรวจสอบว่าตรรกะองค์ประกอบแผนภาพถูกต้องหรือไม่',
  'oifde.traceAdvice.000007': 'ติดต่อผู้ดูแลระบบเพื่อตรวจสอบว่าตรรกะองค์ประกอบแผนภาพถูกต้องหรือไม่',
  'oifde.traceAdvice.000008': 'ติดต่อผู้ดูแลระบบเพื่อตรวจสอบระบบ',
  'oifde.traceAdvice.000009': 'ตรวจสอบว่าทางออกขององค์ประกอบแผนภาพปัจจุบันมีสาขาที่ยังไม่ได้ประมวลผลหรือไม่ แนะนำให้องค์ประกอบแผนภาพทั้งหมดมีสาขาที่ไม่มีเงื่อนไขเริ่มต้น',
  'oifde.traceAdvice.000010': 'ตรวจสอบว่าองค์ประกอบแผนภาพการถ่ายโอน Flow ได้รับการกำหนดค่าอย่างถูกต้อง',
  'oifde.traceAdvice.000011': 'ติดต่อผู้ดูแลระบบเพื่อตรวจสอบระบบ',
  'oifde.traceAdvice.000012': 'ตรวจสอบว่าไวยากรณ์ที่กำหนดค่าสำหรับนิพจน์นั้นถูกต้องหรือไม่และตัวแปรในนิพจน์มีค่าหรือไม่',
  'oifde.traceAdvice.000013': 'ตรวจสอบว่าอินเทอร์เฟซของบุคคลที่สามเป็นปกติหรือไม่',
  'oifde.traceAdvice.000014': 'ตรวจสอบองค์ประกอบแผนภาพการตอบสนองเพื่อให้แน่ใจว่าเทมเพลตการตอบสนองหรือตัวแปรการตอบสนองอย่างน้อยหนึ่งรายการมีค่า',
  'oifde.traceAdvice.000015': 'ตรวจสอบว่าได้กำหนดค่าเทมเพลต SMS สำหรับองค์ประกอบแผนภาพการส่ง SMS หรือไม่',
  'oifde.traceAdvice.000016': 'ตรวจสอบการปรับแต่งเนื้อหาและภาษาในเทมเพลตทรัพยากร ภาษาในตัวในตัวแปรส่วนกลาง และตรวจสอบว่าได้กำหนดค่าใหม่ให้กับภาษาในการจัดลำดับขั้นตอนหรือไม่ ต้องกำหนดค่าภาษาที่ใช้ในการเล่นซ้ำในเทมเพลตทรัพยากร',
  'oifde.traceAdvice.000017': 'ตรวจสอบการปรับแต่งเนื้อหาและภาษาในเทมเพลตทรัพยากร ภาษาในตัวในตัวแปรส่วนกลาง และตรวจสอบว่าได้กำหนดค่าใหม่ให้กับภาษาในการจัดลำดับขั้นตอนหรือไม่ ต้องกำหนดค่าภาษาที่ใช้ในการเล่นซ้ำในเทมเพลตทรัพยากร',
  'oifde.traceAdvice.000018': 'ตรวจสอบว่าองค์ประกอบแผนภาพการถ่ายโอน Flow ได้รับการกำหนดค่าอย่างถูกต้อง',
  'oifde.traceAdvice.000019': 'ตรวจสอบว่ารหัสการเข้าถึงที่โอนโดยอินเทอร์เฟซการโต้ตอบเป็นปกติและมีอยู่ในระบบหรือไม่',
  'oifde.traceAdvice.000020': 'ตรวจสอบว่าสถานะผู้เช่าเป็นปกติหรือไม่',
  'oifde.traceAdvice.000021': 'ตรวจสอบว่าแอปพลิเคชันของบุคคลที่สามหรือ IVR ส่งคำขอเริ่มต้นด้วยรหัสการโทรเดียวกันซ้ำ ๆ หรือไม่',
  'oifde.traceAdvice.000022': 'ตรวจสอบว่าองค์ประกอบแผนภาพคอมโพสิตเป็นปกติและมีอยู่ในระบบหรือไม่',
  'oifde.traceAdvice.000023': 'ติดต่อผู้ดูแลระบบเพื่อตรวจสอบระบบ',
  'oifde.traceAdvice.000024': 'ตรวจสอบข้อมูลข้อผิดพลาดของแต่ละองค์ประกอบแผนภาพและแก้ไขข้อผิดพลาดตามข้อมูลข้อผิดพลาด',
  'oifde.traceAdvice.000025': 'ติดต่อผู้ดูแลระบบเพื่อตรวจสอบระบบ',
  'oifde.traceAdvice.000026': 'ขั้นตอนการดำเนินการผิดปกติ คุณต้องร้องขอสำหรับการเรียกใช้อินเทอร์เฟซเริ่มต้นก่อน',
  'oifde.traceAdvice.000028': 'ตรวจสอบว่าขั้นตอนเป็นปกติหรือไม่',
  'oifde.traceAdvice.000029': 'ตรวจสอบว่ามีการกำหนดค่าการออกโหนดสำหรับองค์ประกอบแผนภาพหรือไม่',
  'oifde.traceAdvice.000030': 'ติดต่อผู้ดูแลระบบเพื่อตรวจสอบว่าข้อมูลการกำหนดค่าขององค์ประกอบแผนภาพถูกต้องหรือไม่',
  'oifde.traceAdvice.000031': 'ติดต่อผู้ดูแลระบบเพื่อตรวจสอบระบบ',
  'oifde.traceAdvice.000032': 'ตรวจสอบข้อมูลข้อผิดพลาดของแต่ละองค์ประกอบแผนภาพและแก้ไขข้อผิดพลาดตามข้อมูลข้อผิดพลาด',
  'oifde.traceAdvice.000033': 'ตรวจสอบว่าอินเทอร์เฟซสำหรับคำขอวางสายส่ง callid ที่ไม่ถูกต้องหรือไม่',
  'oifde.traceAdvice.000034': 'ตรวจสอบว่าองค์ประกอบแผนภาพการสิ้นสุดการโทรได้รับการกำหนดค่าอย่างถูกต้องสำหรับโฟลว์หรือไม่',
  'oifde.traceAdvice.000035': 'ตรวจสอบว่าอินพุต auth_token ถูกต้องหรือไม่',
  'oifde.traceAdvice.000036': 'ตรวจสอบว่าอินพุต auth_token ถูกต้องหรือไม่',
  'oifde.traceAdvice.000037': 'ตรวจสอบว่าอินพุต auth_token ถูกต้องหรือไม่',
  'oifde.traceAdvice.000038': 'ติดต่อผู้ดูแลระบบเพื่อตรวจสอบระบบ',
  'oifde.traceAdvice.000039': '1. ตรวจสอบว่าที่อยู่ TUC และพอร์ตที่กำหนดค่าใน ODFS เป็นปกติหรือไม่ 2. ตรวจสอบว่าบริการในแต่ละโหนด TUC เป็นปกติหรือไม่',
  'oifde.traceAdvice.000040': 'ตรวจสอบว่ากระบวนงานที่จัดเก็บเป็นปกติหรือไม่',
  'oifde.traceAdvice.000041': 'ตรวจสอบว่ากระบวนงานที่จัดเก็บเป็นปกติหรือไม่',
  'oifde.traceAdvice.000044': 'ติดต่อผู้ดูแลระบบเพื่อเพิ่มลงในรายการที่เชื่อถือได้'
}
