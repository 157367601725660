import request from '@/utils/request'
import {useOifdeUserStore} from "@oifde/stores/user";
import cookieUtils from "@/utils/cookie"

export function queryProcedureInterface(query) {
    const data = {
        tenantId: cookieUtils.getCookie('tenant_space_id'),
        source: useOifdeUserStore().source
    }
    for (let prop in query) {
        if (typeof query[prop] === undefined) {
            continue
        }
        if (typeof query[prop] === 'string' && query[prop] === '') {
            continue
        }
        data[prop] = query[prop]
    }
    return request({
        url: '/oifde/rest/procedures',
        method: 'post',
        data
    })
}

export function createProcedureInterface(procedureInfo) {
    const data = {
        tenantId: cookieUtils.getCookie('tenant_space_id'),
        source: useOifdeUserStore().source
    }
    for (let prop in procedureInfo) {
        if (typeof procedureInfo[prop] === "undefined") {
            continue
        }
        if (typeof procedureInfo[prop] === 'string' && procedureInfo[prop] === '') {
            continue
        }
        data[prop] = procedureInfo[prop]
    }

    return request({
        url: '/oifde/rest/createprocedure',
        method: 'post',
        data
    })
}

export function updateProcedureInterface(procedureInfo) {
    const data = {
        tenantId: cookieUtils.getCookie('tenant_space_id'),
        source: useOifdeUserStore().source
    }
    for (let prop in procedureInfo) {
        if (typeof procedureInfo[prop] === "undefined") {
            continue
        }
        if (typeof procedureInfo[prop] === 'string' && procedureInfo[prop] === '') {
            continue
        }
        data[prop] = procedureInfo[prop]
    }

    return request({
        url: '/oifde/rest/updateprocedure',
        method: 'post',
        data
    })
}

export function deleteProcedureInterface(procedureCodeList) {
    const data = {
        tenantId: cookieUtils.getCookie('tenant_space_id'),
        procedureCodeList: procedureCodeList
    }

    return request({
        url: '/oifde/rest/deleteprocedure',
        method: 'post',
        data
    })
}

export function procedureDB(procedureDBInfo) {
    const data = {
        tenantId: cookieUtils.getCookie('tenant_space_id'),
        source: useOifdeUserStore().source
    }
    for (let prop in procedureDBInfo) {
        if (typeof procedureDBInfo[prop] === undefined) {
            continue
        }
        if (typeof procedureDBInfo[prop] === 'string' && procedureDBInfo[prop] === '') {
            continue
        }
        data[prop] = procedureDBInfo[prop]
    }

    return request({
        url: '/oifde/rest/proceduredb',
        method: 'post',
        data
    })
}

export function queryProcedureDB(queryParam) {
  const data = {
    tenantId: cookieUtils.getCookie('tenant_space_id')
  }
  for (const prop in queryParam) {
    if (!queryParam[prop]) {
      continue
    }
    data[prop] = queryParam[prop]
  }
  return request({
    url: '/oifde/rest/queryproceduredb',
    method: 'post',
    data
  })
}

export function checkWhiteList(url, urlType, dbType) {
    const data = {
        tenantId: cookieUtils.getCookie('tenant_space_id'),
        urlType: urlType,
        url: url,
        dbType: dbType
    }

    return request({
        url: '/oifde/rest/checkWhiteList',
        method: 'post',
        data
    })
}

export function queryProcedureDBLikeName(query) {
  const data = {
    tenantId: cookieUtils.getCookie('tenant_space_id')
  }
  for (const prop in query) {
    if (!query[prop]) {
      continue
    }
    data[prop] = query[prop]
  }
  return request({
    url: '/oifde/rest/queryproceduredblikename',
    method: 'post',
    data
  })
}

export function deleteProcedureDB(dbInfo) {
  const data = {
    tenantId: cookieUtils.getCookie('tenant_space_id'),
    dbId: dbInfo['dbId'],
    dbName: dbInfo['dbName']
  }
  return request({
    url: '/oifde/rest/deleteproceduredb',
    method: 'post',
    data
  })
}

export function queryDefaultProcedureDB() {
  const data = {
    tenantId: cookieUtils.getCookie('tenant_space_id')
  }

  return request({
    url: '/oifde/rest/querydefaultproceduredb',
    method: 'post',
    data
  })
}