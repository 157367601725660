import i18n from "@/lang";

const $t = i18n.global.t
const ccprovisionRoutes = {
  default: [
    {
      path: 'agent-track-config',
      name: '90200001',
      title: $t('provision.agent.track.config.log.config.log.title'),
      component: () => import('@/views/ccprovision/views/log-config/agent-track-config/AgentTrackConfig.vue')
    },
  ],
  pageWithSideNav: [],
  pageWithBackHeader: [],
  pageWithSelfTab: []
}
export default ccprovisionRoutes
