import request from '@/utils/request'

export function queryConfigs(configKeys) {
  const data = {
    configList: configKeys
  }

  return new Promise((resolve, reject) => {
    return request({
      url: '/oifde/rest/queryconfigs',
      method: 'post',
      data
    }).then(response => {
      if (!response) { // 由于mockjs 不支持自定义状态码只能这样hack
        reject('error')
      }
      const data = response

      const retConfig = {}
      data.configValueList.forEach(function(configItem) {
        retConfig[configItem.configName] = configItem.configValue
      }, this)

      resolve(retConfig)
    }).catch(error => {
      reject(error)
    })
  })
}
