import i18n from '@/lang/index'

const $t = i18n.global.t

export function getLeaveOrEnterReason() {
  var codes = [
    {
      value: 0, //技能队列
      label: $t('cms.calllink.field.commonAccessTransfer')
    },
    {
      value: 1, //业务代表
      label: $t('cms.calllink.field.overflowing')
    },
    {
      value: 2, //IVR业务流程
      label: $t('cms.calllink.field.timeoutTransfer')
    },
    {
      value: 3, //呼叫
      label: $t('cms.calllink.field.noOnDutyTransfer')
    },
    {
      value: 4, //路由临时设备
      label: $t('cms.calllink.field.cancelQueuingTransfer')
    },
    {
      value: 5, //虚设备
      label: $t('cms.calllink.field.noAnswerTransfer')
    },
    {
      value: 6, //CDN设备
      label: $t('cms.calllink.field.suspendedAndResumed')
    },
    {
      value: 7, //MELCAS设备
      label: $t('cms.calllink.field.connectionHold')
    },
    {
      value: 8, //PGR
      label: $t('cms.calllink.field.threePartyHelp')
    },
    {
      value: 9, //电话会议
      label: $t('cms.calllink.field.unhold')
    },
    {
      value: 10, //技能队列
      label: $t('cms.calllink.field.threePartyCall')
    },
    {
      value: 11, //业务代表
      label: $t('cms.calllink.field.helperRelease')
    },
    {
      value: 12, //IVR业务流程
      label: $t('cms.calllink.field.intercepts')
    },
    {
      value: 13, //呼叫
      label: $t('cms.calllink.field.pickup')
    },
    {
      value: 14, //路由临时设备
      label: $t('cms.calllink.field.analysisNotConfig')
    },
    {
      value: 15, //虚设备
      label: $t('cms.calllink.field.activeRelease')
    },
    {
      value: 16, //CDN设备
      label: $t('cms.calllink.field.agentRelease')
    },
    {
      value: 17, //MELCAS设备
      label: $t('cms.calllink.field.transferOut')
    },
    {
      value: 18, //PGR
      label: $t('cms.calllink.field.hold')
    },
    {
      value: 19, //电话会议
      label: $t('cms.calllink.field.internalError')
    },
    {
      value: 20, //技能队列
      label: $t('cms.calllink.field.calledBusy')
    },
    {
      value: 22, //IVR业务流程
      label: $t('cms.calllink.field.powerOff')
    },
    {
      value: 23, //呼叫
      label: $t('cms.calllink.field.outOfService')
    },
    {
      value: 24, //路由临时设备
      label: $t('cms.calllink.field.unavailableNumber')
    },
    {
      value: 25, //虚设备
      label: $t('cms.calllink.field.noResources')
    },
    {
      value: 26, //虚设备
      label: $t('cms.calllink.field.doNotAnswer')
    },
    {
      value: 27,
      label: $t('cms.calllink.field.callRejected')
    },
    {
      value: 28,
      label: $t('cms.calllink.field.leaveReason28')
    },
    {
      value: 29,
      label: $t('cms.calllink.field.leaveReason29')
    },
    {
      value: 30,
      label: $t('cms.calllink.field.leaveReason30')
    },
    {
      value: 31,
      label: $t('cms.calllink.field.leaveReason31')
    },
    {
      value: 34,
      label: $t('cms.calllink.field.leaveReason34')
    },
    {
      value: 35,
      label: $t('cms.calllink.field.leaveReason35')
    },
    {
      value: 36,
      label: $t('cms.calllink.field.leaveReason36')
    },
    {
      value: 37,
      label: $t('cms.calllink.field.leaveReason37')
    },
    {
      value: 38,
      label: $t('cms.calllink.field.leaveReason38')
    },
    {
      value: 39,
      label: $t('cms.calllink.field.leaveReason39')
    },
    {
      value: 40,
      label: $t('cms.calllink.field.leaveReason40')
    },
    {
      value: 41,
      label: $t('cms.calllink.field.leaveReason41')
    },
    {
      value: 42,
      label: $t('cms.calllink.field.leaveReason42')
    },
    {
      value: 43,
      label: $t('cms.calllink.field.leaveReason43')
    },
    {
      value: 44,
      label: $t('cms.calllink.field.leaveReason44')
    },
    {
      value: 45,
      label: $t('cms.calllink.field.leaveReason45')
    },
    {
      value: 46,
      label: $t('cms.calllink.field.leaveReason46')
    },
    {
      value: 47,
      label: $t('cms.calllink.field.leaveReason47')
    },
    {
      value: 48,
      label: $t('cms.calllink.field.leaveReason48')
    },
    {
      value: 50,
      label: $t('cms.calllink.field.leaveReason50')
    },
    {
      value: 51,
      label: $t('cms.calllink.field.leaveReason51')
    },
    {
      value: 52,
      label: $t('cms.calllink.field.leaveReason52')
    },
    {
      value: 53,
      label: $t('cms.calllink.field.leaveReason53')
    },
    {
      value: 54,
      label: $t('cms.calllink.field.leaveReason54')
    },
    {
      value: 88,
      label: $t('cms.calllink.field.leaveReason88')
    },
    {
      value: 89,
      label: $t('cms.calllink.field.leaveReason89')
    }
  ]
  return codes
}

export function getCallType() {
  var codes = [
    {
      value: 0, //技能队列
      label: $t('cms.calllink.field.callType33')
    },
    {
      value: 5, //业务代表
      label: $t('cms.calllink.field.callType32')
    },
    {
      value: 6, //IVR业务流程
      label: $t('cms.calllink.field.callType31')
    },
    {
      value: 7, //呼叫
      label: $t('cms.calllink.field.callType30')
    },
    {
      value: 8, //路由临时设备
      label: $t('cms.calllink.field.callType29')
    },
    {
      value: 9, //虚设备
      label: $t('cms.calllink.field.callType28')
    },
    {
      value: 10, //CDN设备
      label: $t('cms.calllink.field.callType27')
    },
    {
      value: 11, //MELCAS设备
      label: $t('cms.calllink.field.callType26')
    },
    {
      value: 12, //PGR
      label: $t('cms.calllink.field.callType0')
    },
    {
      value: 13, //电话会议
      label: $t('cms.calllink.field.callType1')
    },
    {
      value: 14, //技能队列
      label: $t('cms.calllink.field.callType2')
    },
    {
      value: 15, //业务代表
      label: $t('cms.calllink.field.callType3')
    },
    {
      value: 16, //IVR业务流程
      label: $t('cms.calllink.field.callType4')
    },
    {
      value: 17, //呼叫
      label: $t('cms.calllink.field.callType5')
    },
    {
      value: 18, //路由临时设备
      label: $t('cms.calllink.field.callType6')
    },
    {
      value: 20, //虚设备
      label: $t('cms.calllink.field.callType7')
    },
    {
      value: 21, //CDN设备
      label: $t('cms.calllink.field.callType8')
    },
    {
      value: 22, //MELCAS设备
      label: $t('cms.calllink.field.callType9')
    },
    {
      value: 23, //PGR
      label: $t('cms.calllink.field.callType10')
    },
    {
      value: 40, //电话会议
      label: $t('cms.calllink.field.callType11')
    },
    {
      value: 41, //电话会议
      label: $t('cms.calllink.field.callType12')
    },
    {
      value: 42, //电话会议
      label: $t('cms.calllink.field.callType13')
    },
    {
      value: 43, //电话会议
      label: $t('cms.calllink.field.callType14')
    },
    {
      value: 44, //电话会议
      label: $t('cms.calllink.field.callType15')
    },
    {
      value: 45, //电话会议
      label: $t('cms.calllink.field.callType16')
    },
    {
      value: 46, //电话会议
      label: $t('cms.calllink.field.callType17')
    },
    {
      value: 50, //电话会议
      label: $t('cms.calllink.field.callType18')
    },
    {
      value: 51, //电话会议
      label: $t('cms.calllink.field.callType19')
    },
    {
      value: 52, //电话会议
      label: $t('cms.calllink.field.callType20')
    },
    {
      value: 60, //电话会议
      label: $t('cms.calllink.field.callType21')
    },
    {
      value: 61, //电话会议
      label: $t('cms.calllink.field.callType22')
    },
    {
      value: 62, //电话会议
      label: $t('cms.calllink.field.callType23')
    },
    {
      value: 63, //电话会议
      label: $t('cms.calllink.field.callType24')
    },
    {
      value: 64, //电话会议
      label: $t('cms.calllink.field.callType25')
    }
  ]
  return codes
}

export function getMediaType() {
  return [
    {
      value: 1, //技能队列
      label: $t('cms.mediaType1')
    },
    {
      value: 2, //业务代表
      label: $t('cms.mediaType2')
    },
    {
      value: 3, //IVR业务流程
      label: $t('cms.mediaType3')
    },
    {
      value: 4, //呼叫
      label: $t('cms.mediaType4')
    },
    {
      value: 5, //路由临时设备
      label: $t('cms.mediaType5')
    },
    {
      value: 6, //虚设备
      label: $t('cms.mediaType6')
    },
    {
      value: 7, //CDN设备
      label: $t('cms.mediaType7')
    },
    {
      value: 8, //MELCAS设备
      label: $t('cms.mediaType8')
    },
    {
      value: 9, //PGR
      label: $t('cms.mediaType9')
    },
    {
      value: 10, //电话会议
      label: $t('cms.mediaType10')
    },
    {
      value: 11, //技能队列
      label: $t('cms.mediaType11')
    },
    {
      value: 12, //业务代表
      label: $t('cms.mediaType12')
    },
    {
      value: 13, //IVR业务流程
      label: $t('cms.mediaType13')
    },
    {
      value: 14, //呼叫
      label: $t('cms.mediaType14')
    },
    {
      value: 15, //路由临时设备
      label: $t('cms.mediaType15')
    },
    {
      value: 16, //虚设备
      label: $t('cms.mediaType16')
    },
    {
      value: 17, //CDN设备
      label: $t('cms.mediaType17')
    },
    {
      value: 18, //MELCAS设备
      label: $t('cms.mediaType18')
    },
    {
      value: 19, //PGR
      label: $t('cms.mediaType19')
    },
    {
      value: 20, //电话会议
      label: $t('cms.mediaType20')
    },
    {
      value: 53, //多媒体
      label: $t('cms.mediaType53')
    }
  ]
}

export function voicePlaybackCallType() {
  return [
    {
      value: 0, //语音呼入
      label: $t('cms.record-playback.callType0')
    },
    {
      value: 1, //语音呼出
      label: $t('cms.record-playback.callType1')
    },
    {
      value: 2, //IVR呼出
      label: $t('cms.record-playback.callType2')
    },
    {
      value: 3, //OBS外呼
      label: $t('cms.record-playback.callType3')
    }
  ]
}

export function getDeviceType() {
  return [
    {
      value: 1, //技能队列
      label: $t('cms.calllink.field.skillQueue')
    },
    {
      value: 2, //业务代表
      label: $t('cms.calllink.field.businessRepresentative')
    },
    {
      value: 3, //IVR业务流程
      label: $t('cms.calllink.field.ivrServiceProcess')
    },
    {
      value: 5, //呼叫
      label: $t('cms.calllink.field.call')
    },
    {
      value: 7, //路由临时设备
      label: $t('cms.calllink.field.routingTemporaryDevice')
    },
    {
      value: 8, //虚设备
      label: $t('cms.calllink.field.virtualDevice')
    },
    {
      value: 10, //CDN设备
      label: $t('cms.calllink.field.cdnDevice')
    },
    {
      value: 11, //MELCAS设备
      label: $t('cms.calllink.field.MELCASDevice')
    },
    {
      value: 14, //PGR
      label: $t('cms.calllink.field.PGR')
    },
    {
      value: 15, //电话会议
      label: $t('cms.calllink.field.teleconference')
    }
  ]
}

export function getDeviceIn() {
  return [
    {
      value: 'DEVICE_ROUTE',
      label: $t('cms.calllind.field.deviceRoute')
    },
    {
      value: 'DEVICE_QUEUE',
      label: $t('cms.calllind.field.deviceQueue')
    },
    {
      value: 'DEVICE_AGENT',
      label: $t('cms.calllind.field.deviceAgent')
    },
    {
      value: 'DEVICE_VNR',
      label: $t('cms.calllind.field.deviceVnr')
    },
    {
      value: 'DEVICE_UNKNOWN',
      label: $t('cms.calllind.field.deviceUnkown')
    }
  ]
}

export function isEmpty(obj) {
  if (typeof obj == 'undefined') {
    return true
  } else if (typeof obj == 'string' && obj.length === 0) {
    return true
  } else if (typeof obj == 'object' && (null == obj || Object.keys(obj).length === 0)) {
    return true
  }
  return false
}

export function valueToLabel(value, valueList) {
  for (var i = 0; i < valueList.length; i++) {
    if (value && value === valueList[i].value) {
      return valueList[i].label
    }
  }
  return ''
}

export function getNowTime() {
  let nowDate = new Date()
  let year = nowDate.getFullYear() + ''
  let tempMonth = nowDate.getMonth() + 1
  let month = tempMonth < 10 ? '0' + tempMonth : tempMonth + ''
  let tempDate = nowDate.getDate()
  let date = tempDate < 10 ? '0' + tempDate : tempDate + ''
  let hour = nowDate.getHours() < 10 ? '0' + nowDate.getHours() : nowDate.getHours() + ''
  let minute = nowDate.getMinutes() < 10 ? '0' + nowDate.getMinutes() : nowDate.getMinutes() + ''
  let second = nowDate.getSeconds() < 10 ? '0' + nowDate.getSeconds() : nowDate.getSeconds() + ''
  let milliSeconds = nowDate.getMilliseconds() + ''
  return year + month + date + hour + minute + second + milliSeconds
}

// 获取媒体类型列表0
export function getMediaTypeList() {
  let mediaTypeList = [
    {
      value: '5',
      label: $t('cms.voiceinspection.field.ordinaryVoiceCall')
    },
    {
      value: '53',
      label: $t('cms.voiceinspection.field.socialChat')
    },
    {
      value: '19',
      label: $t('cms.report.field.mediatype.vedio')
    },
    {
      value: '2',
      label: $t('cms.voiceinspection.field.clickToCall')
    },
    {
      value: '18',
      label: $t('cms.voiceinspection.field.videoClickToCall')
    }
  ]
  return mediaTypeList
}

// 获取子媒体类型列表
export function getSubMediaTypeList(channelTypeList = "all_channel") {
  let subMediaTypeList = [
    {
      value: '1',
      label: $t('cms.report.field.submediatype.web'),
      key: 'WEB'
    },
    {
      value: '2',
      label: $t('cms.report.field.submediatype.whatsapp'),
      key: 'WHATSAPP'
    },
    {
      value: '3',
      label: $t('cms.report.field.submediatype.line'),
      key: 'LINE'
    },
    {
      value: '4',
      label: $t('cms.report.field.submediatype.wechat'),
      key: 'WECHAT'
    },
    {
      value: '5',
      label: $t('cms.report.field.submediatype.facebook'),
      key: 'FACEBOOK'
    },
    {
      value: '6',
      label: $t('cms.report.field.submediatype.twitter'),
      key: 'TWITTER'
    },
    {
      value: '7',
      label: $t('cms.report.field.submediatype.5gmessage'),
      key: '5G'
    },
    {
      value: '8',
      label: $t('cms.report.field.submediatype.email'),
      key: 'EMAIL'
    },
    {
      value: '9',
      label: $t('cms.report.field.submediatype.sms'),
      key: 'SMS'
    }
  ]
  if (channelTypeList === "all_channel") {
    return subMediaTypeList;
  } else {
    return subMediaTypeList.filter(item => {
      return channelTypeList.includes(item.key)
    })
  }
}

// 指标监控百分比数字格式化
export function formatNumber(data) {
  // 保留两位小数并四舍五入
  let num = parseFloat(data)
  num = parseFloat(num.toFixed(2))
  // 去掉末尾的0
  return num
}
// 时间格式化
export function formatTime(seconds) {
  let num = parseFloat(seconds)
  let y = Math.floor(num / (3600 * 24 * 365))
  let d = Math.floor((num % (3600 * 24 * 365)) / (3600 * 24))
  let h = Math.floor((num % (3600 * 24)) / 3600)
  let m = Math.floor((num % 3600) / 60)
  let s = Math.floor(num % 60)
  let str = ''
  if (y > 0) {
    str += y + 'y'
  }
  if (d > 0) {
    str += d + 'd'
  }
  if (h > 0) {
    str += h + 'h'
  }
  if (m > 0) {
    str += m + 'm'
  }
  if (s > 0 || (h == 0 && m == 0)) {
    str += s + 's'
  }
  return str
}

export default {}
