<template>
    <!-- 聊天语音播放，组件功能：播放，停止，语音时长-->
    <div class="aicc-chat-audio">
        <div class="audio" :class="{ 'audio-dark': effect === 'dark' }" @click="playPauseAudio">
            <!-- 播放暂停 -->
            <div class="audio-img" :class="{ 'audio-play-animation': audioData.playing }">
                <img src="@/assets/img/audio/ic_audio_dark@3x.png" v-if="effect === 'light'" />
                <img src="@/assets/img/audio/ic_audio_light@3x.png" v-else />
            </div>

            <!-- 时间 -->
            <div class="time-wrap">
                <span class="duraTime">{{ transTime(audioData.duration) }}</span>
            </div>


        </div>
        <!-- 真实音频组件 -->
        <audio ref="audioRef" preload="auto" @ended="audioEnded" @loadeddata="audioLoadeddata"></audio>
    </div>
</template>
<script lang="ts">
import {
    defineComponent,
    reactive,
    ref,
    onMounted,
    getCurrentInstance,
    watch
} from 'vue'
import { ElMessage } from 'element-plus'

export default defineComponent({
    name: 'AiccChatAudio',
    props: {
        src: {
            type: String,
            required: true
        },
        autoplay: {
            type: String,
            required: false
        },
        effect: {
            type: String,
            default: 'light'
        }
    },
    setup(props, context) {
        const {
            appContext: {
                config: { globalProperties }
            }
        } = getCurrentInstance()
        const audioRef = ref()
        const audioData = reactive({
            autoplay: props.autoplay, // 如果出现该属性，则音频在就绪后马上播放
            playing: false, //现在是否正在播放中
            duration: 0, // 音频总时长
            currentTime: 0, // 当前播放的位置
        })
        let audioEl: HTMLAudioElement | null = null

        watch(
            () => props.src,
            (newSrc) => {
                // 动态设置音频src
                audioRef.value && audioRef.value.setAttribute('src', newSrc)
            },
            {

            }
        )

        // 添加音频url
        const setAudioSrc = (audioUrl) => {
            audioRef.value && audioRef.value.setAttribute('src', audioUrl)
        }

        // 播放或暂停音频
        const playPauseAudio = (e) => {

            if (!audioEl) {
                // 没有加载到音频，提示 加载资源异常，请检查相关配置
                ElMessage.error(globalProperties.$t('ccm.ivrvoice.play.error'))
            }
            if (e === 'isFirst') {
                audioPlay()
                return
            }
            if (!audioData.playing) {
                audioPlay()
            } else {
                audioPause()
            }
        }

        // 音频播放结束
        const audioEnded = () => {
            audioData.playing = false
        }

        // 当媒体音频第一帧加载完成时
        const audioLoadeddata = (e) => {
            audioEl = e.target
            const duration = e.target.duration
            audioData.duration = duration
            context.emit('getAudioDuration', duration)
            if (audioData.autoplay == 'autoplay') {
                playPauseAudio('isFirst')
            }
        }

        // 播放
        function audioPlay() {
            if (audioEl) {
                audioEl.play()
                audioData.playing = true
            }
        }

        // 暂停播放
        function audioPause() {
            if (audioEl) {
                audioEl.pause()
                audioData.playing = false
                audioEl.currentTime = 0;
            }
        }

        // 格式化时间格式
        const transTime = (time: number) => {
            let minute = parseInt(time / 60)
            let minuteStr = minute.toString()
            let sec = parseInt(time % 60)
            if (minute == 0) {
                return `${sec}"`
            } else {
                return `${minuteStr}'${sec}"`
            }
        }

        onMounted(() => {
            setAudioSrc(props.src)
        })
        return {
            audioRef,
            audioData,
            transTime,
            setAudioSrc,
            audioLoadeddata,
            audioEnded,
            playPauseAudio,
        }
    }
})
</script>
<style lang="less" scoped>
@keyframes audioPlay {
    0% {
        left: 20%;
    }

    100% {
        left: 100%;
    }
}
.aicc-chat-audio {
    .audio {
        display: flex;
        padding: 0 10px;
        gap: 10px;
        align-items: center;
        height: 100%;
        border-radius: 18px;
        width: 100%;
        background: #ffffff;
        color: #000;
        cursor: pointer;

        .audio-img {
            display: flex;
            height: 1rem;
            position: relative;

            &::after {
                position: absolute;
                content: '';
                display: block;
                height: 100%;
                left: 100%;
                right: 0;
                background: #FFF;
            }
        }

        .audio-play-animation.audio-img {
            &::after {
                animation: audioPlay 1.25s infinite
            }
        }

        .time-wrap {
            -moz-user-select: none;
            -webkit-user-select: none;
            user-select: none;
            height: 30px;
            line-height: 30px;
            font-size: var(--14px-to-rem);
            font-weight: 400;
        }
    }

    .audio-dark.audio {
        background: #000;
        color: #ffffff;

        .audio-img {

            &::after {
                background: #000;
            }
        }
    }
}

</style>