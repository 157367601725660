import request from '@/utils/request'
import cookieUtils from '@/utils/cookie'

export const queryAllGroups = (tenantSpaceId) => {
  return request({
    url: `/service-cloud/rest/sum/v2/tenantSpaces/${tenantSpaceId}/groups`,
    method: 'GET'
  })
}

export const queryGroupsById = (tenantSpaceId, id) => {
  return request({
    url: `/service-cloud/rest/sum/v2/tenantSpaces/${tenantSpaceId}/groups/${id}`,
    method: 'GET'
  })
}

export const queryUsersByGroupId = (tenantSpaceId, id, data) => {
  return request({
    url: `/service-cloud/rest/sum/v3/tenantSpaces/${tenantSpaceId}/groups/${id}/users`,
    method: 'GET',
    data
  })
}

export const operateUserFromGroup = (tenantSpaceId, id, data) => {
  return request({
    url: `/service-cloud/rest/sum/v1/tenantSpaces/${tenantSpaceId}/groups/${id}/users`,
    method: 'POST',
    data
  })
}

export const createUserGroup = (data) => {
  return request({
    url: `/service-cloud/u-route/usergroupaction/creategroup`,
    method: 'POST',
    data,
    requestHeaders: [{ 'Content-Type': 'application/json' }]
  })
}

export const deleteUserGroup = (tenantSpaceId, id) => {
  return request({
    url: `/service-cloud/rest/sum/v1/tenantSpaces/${tenantSpaceId}/groups/${id}`,
    method: 'DELETE'
  })
}

export const updateUserGroup = (data) => {
  return request({
    url: `/service-cloud/u-route/usergroupaction/updategroup`,
    data,
    method: 'POST'
  })
}

export const queryAllUsers = (tenantSpaceId, data) => {
  return request({
    url: `/service-cloud/rest/sum/v2/tenantSpaces/${tenantSpaceId}/users`,
    method: 'GET',
    data
  })
}

export const queryEmployeeList = (data) => {
  return request({
    url: ' /service-cloud/u-route/employeeOperation/queryEmployeeList',
    method: 'post',
    data,
    requestHeaders: [
      {
        'content-type': 'application/json'
      }
    ]
  })
}

export const queryDictionary = (data) => {
  return request({
    url: `/service-cloud/u-route/dictionary/queryDataDictionary`,
    method: 'GET',
    data
  })
}
export const queryOrgsById = (tenantSpaceId, id) => {
  return request({
    url: `/service-cloud/rest/sum/v1/tenantSpaces/${tenantSpaceId}/orgs/${id}`,
    method: 'get'
  })
}

export const queryUsersById = (tenantSpaceId, id) => {
  return request({
    url: `/service-cloud/rest/sum/v2/tenantSpaces/${tenantSpaceId}/users/${id}`,
    method: 'get'
  })
}

export const queryDataDictionary = (data) => {
  return request({
    url: `/service-cloud/u-route/dictionary/queryDataDictionary`,
    method: 'get',
    data
  })
}

export const queryDatadictsLocale = () => {
  return request({
    url: `/service-cloud/rest/sum/v1/system/datadicts-locale`,
    method: 'get'
  })
}

export const queryPwduleGroups = (tenantSpaceId) => {
  return request({
    url: `/service-cloud/rest/sum/v1/tenantSpaces/${tenantSpaceId}/pwd-rule-groups`,
    method: 'get'
  })
}

export const queryRoles = (tenantSpaceId) => {
  return request({
    url: `/service-cloud/rest/sum/v1/tenantSpaces/${tenantSpaceId}/roles`,
    method: 'get'
  })
}
// params: {dictkeylist: ["SYS.USER_RESTRICT_MODE"]}
export const queryDictkey = (data) => {
  return request({
    url: '/service-cloud/rest/sum/v1/meta/dictkey',
    method: 'post',
    data,
    requestHeaders: [
      {
        'content-type': 'application/json'
      }
    ]
  })
}

// userIds: ["1690343704359270721", "1690342104099546722", "1690341670078146411", "1685085365391564264",…]
export const getAccessOrgInfoByUserIds = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/accessRelation/getAccessOrgInfoByUserIds',
    method: 'post',
    data,
    requestHeaders: [
      {
        'content-type': 'application/json'
      }
    ]
  })
}

// userId: "1690343704359270721"
export const getListByUserIdForShow = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/accessRelation/getListByUserIdForShow',
    method: 'post',
    data,
    requestHeaders: [
      {
        'content-type': 'application/json'
      }
    ]
  })
}

export const queryEmployeeContacts = (tenantSpaceId, id) => {
  return request({
    url: `/service-cloud/rest/sum/v1/tenantSpaces/${tenantSpaceId}/users/${id}/contacts`,
    method: 'get'
  })
}

export const queryMAX_USER_OWN_DUTY_ROLE_NUM = () => {
  return request({
    url: `/service-cloud/rest/sum/v1/system/params/MAX_USER_OWN_DUTY_ROLE_NUM`,
    method: 'get'
  })
}

export const queryLoginLogs = (tenantSpaceId, data) => {
  return request({
    url: `/service-cloud/rest/sum/v2/tenantSpaces/${tenantSpaceId}/login-logs`,
    method: 'GET',
    data
  })
}

// 获取顶级菜单权限
export const sumTopPerms = (data) => {
  return request({
    url: '/service-cloud/rest/sum/v1/tenantSpaces/' + data.tenantSpaceId + '/topperms',
    method: 'get',
    data: {
      permType: 0
    }
  })
}

// 获取子菜单权限
export const sumSubPerms = (data) => {
  return request({
    url:
      '/service-cloud/rest/sum/v1/tenantSpaces/' + data.tenantSpaceId + '/perms/' + data.permCode,
    method: 'get',
    data: {
      subPerms: true
    }
  })
}

// 删除自定义菜单权限
export const deletePerms = (data) => {
  return request({
    url: '/service-cloud/u-route/permissionaction/deleteperm',
    method: 'post',
    data: {
      param: {
        permCode: data
      }
    },
    requestHeaders: [
      {
        'content-type': 'application/json'
      }
    ]
  })
}

// 获取用户所有权限ID
export const userPerms = (data) => {
  return request({
    url:
      '/service-cloud/rest/sum/v2/tenantSpaces/' +
      data.tenantSpaceId +
      '/users/' +
      data.userId +
      '/perms',
    method: 'get'
  })
}

// 获取用户所有有权限的菜单
export const userMenus = () => {
  return request({
    url: '/service-cloud/rest/sum/v1/user/menus',
    method: 'get'
  })
}

// 获取租户下的所有菜单
export const tenantMenus = (tenantSpaceId) => {
  return request({
    url: `/service-cloud/rest/sum/v1/tenantSpaces/${tenantSpaceId}/menus`,
    method: 'get'
  })
}

// 添加权限
export const addPerm = (data) => {
  return request({
    url: '/service-cloud/u-route/permissionaction/addperm',
    method: 'post',
    requestHeaders: [
      {
        'content-type': 'application/json'
      }
    ],
    data: {
      param: data
    }
  })
}

// 获取权限详情
export const menuAuth = (data) => {
  return request({
    url: '/service-cloud/rest/sum/v1/tenantSpaces/' + data.tenantSpaceId + '/perms/' + data.authId,
    method: 'get',
    data: {
      subPerms: false
    }
  })
}

// 获取所有菜单权限
export const allAuths = () => {
  return request({
    url: '/service-cloud/u-route/authOperation/queryAllAuths',
    method: 'get',
    requestHeaders: [
      {
        'content-type': 'application/json'
      }
    ],
    data: {
      hasReserved: true
    }
  })
}

// 新增菜单
export const addMenu = (data) => {
  return request({
    url: '/service-cloud/u-route/menuaction/addmenu',
    method: 'post',
    requestHeaders: [
      {
        'content-type': 'application/json'
      }
    ],
    data: {
      param: data
    }
  })
}

// 删除指定菜单
export const deleteMenu = (data) => {
  return request({
    url: '/service-cloud/u-route/menuaction/deletemenu',
    method: 'post',
    requestHeaders: [
      {
        'content-type': 'application/json'
      }
    ],
    data: {
      param: {
        menuId: data
      }
    }
  })
}

// 根据 tenant_space_id，orgId查询组织列表
export const queryOrgsInfoFromSum = (topOrgFlag, offset = 0, limit = 100) => {
  let tenantSpaceId = cookieUtils.getCookie('tenant_space_id')
  return request({
    url: `/service-cloud/rest/sum/v1/tenantSpaces/${tenantSpaceId}/orgs`,
    method: 'get',
    data: { topOrgFlag, status: 'all', offset, limit }
  })
}

// 查询组织的socialChatNum和callEndState
export const queryOrgExtendInfo = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/orgextend/queryOrgExtendInfo',
    method: 'post',
    data: data
  })
}

// 新建组织
export const createNewOrg = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/accessRelation/createOrg',
    method: 'post',
    data
  })
}

// 新建组织，独立部署环境
export const createNewOrgIndependent = (data) => {
  return request({
    url: '/service-cloud/u-route/orgOperation/addOrg',
    method: 'post',
    requestHeaders: [
      {
        'content-type': 'application/json'
      }
    ],
    data
  })
}

// 保存新建组织的socialChatNum和callEndState
export const saveOrgExtendInfo = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/orgextend/saveOrgExtendInfo',
    method: 'post',
    data: data
  })
}

// 修改组织
export const updateOrg = (data) => {
  return request({
    url: '/service-cloud/u-route/orgOperation/updateOrg',
    method: 'post',
    requestHeaders: [
      {
        'content-type': 'application/json'
      }
    ],
    data: { param: data }
  })
}

// 修改组织的socialChatNum和callEndState
export const modifyOrgExtendInfo = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/orgextend/modifyOrgExtendInfo',
    method: 'post',
    data
  })
}

// 修改组织状态，1 正常 2 停用 3 禁用
export const updateOrgStatus = (status, orgId) => {
  return request({
    url: '/service-cloud/u-route/orgOperation/updateOrgStatus',
    method: 'post',
    requestHeaders: [
      {
        'content-type': 'application/json'
      }
    ],
    data: {
      param: {
        status,
        orgId
      }
    }
  })
}

// 根据组织id删除组织的可访问员工关系
export const batchRemoveByOrgId = (orgId) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/accessRelation/batchRemoveByOrgId',
    method: 'post',
    data: { orgId }
  })
}

// 删除组织
export const deleteOrgByOrgId = (orgId) => {
  let tenantSpaceId = cookieUtils.getCookie('tenant_space_id')
  return request({
    url: `/service-cloud/rest/sum/v1/tenantSpaces/${tenantSpaceId}/orgs/${orgId}`,
    method: 'delete'
  })
}

// 删除组织ExtendInfo
export const deleteOrgExtendInfo = (orgId) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/orgextend/deleteOrgExtendInfo',
    method: 'post',
    data: { orgId }
  })
}

// 查询组织角色
export const queryOrgRoles = (orgId, data) => {
  let tenantSpaceId = cookieUtils.getCookie('tenant_space_id')
  return request({
    url: `/service-cloud/rest/sum/v1/tenantSpaces/${tenantSpaceId}/orgs/${orgId}/roles`,
    method: 'get',
    data
  })
}

//增加组织角色
export const addOrgRoles = (orgId, roleIds) => {
  let tenantSpaceId = cookieUtils.getCookie('tenant_space_id')
  return request({
    url: `/service-cloud/rest/sum/v1/tenantSpaces/${tenantSpaceId}/orgs/${orgId}/roles`,
    method: 'post',
    data: {
      model: null,
      params: {
        action: 'grant',
        roleIds
      }
    }
  })
}

//删除组织角色
export const delOrgRoles = (orgId, ...roleIds) => {
  let tenantSpaceId = cookieUtils.getCookie('tenant_space_id')
  return request({
    url: `/service-cloud/rest/sum/v1/tenantSpaces/${tenantSpaceId}/orgs/${orgId}/roles`,
    method: 'post',
    data: {
      model: null,
      params: {
        action: 'revoke',
        roleIds
      }
    }
  })
}

// 查询组织员工
export const queryOrgUsers = (orgId, data) => {
  const tenantSpaceId = cookieUtils.getCookie('tenant_space_id')
  return request({
    url: `/service-cloud/rest/sum/v2/tenantSpaces/${tenantSpaceId}/orgs/${orgId}/users`,
    method: 'get',
    data
  })
}

// 在组织下关联或取消关联用户
export const operateOrgUsers = (tenantSpaceId, orgId, data) => {
  return request({
    url: `/service-cloud/rest/sum/v1/tenantSpaces/${tenantSpaceId}/orgs/${orgId}/users`,
    method: 'post',
    data
  })
}

// 添加组织员工
export const addOrgUser = (orgId, userIds) => {
  return request({
    url: '/service-cloud/u-route/orgOperation/updateOrgUser',
    method: 'post',
    data: {
      model: null,
      params: {
        param: {
          action: 'add',
          userIds,
          orgId
        }
      }
    }
  })
}

// 添加主管
export const addManager = (orgId, userId) => {
  const tenantSpaceId = cookieUtils.getCookie('tenant_space_id')
  return request({
    url: `/service-cloud/rest/sum/v1/tenantSpaces/${tenantSpaceId}/orgs/${orgId}/contacts`,
    method: 'post',
    data: {
      userId
    }
  })
}

// 删除主管
export const deleteManager = (orgId, contactId) => {
  const tenantSpaceId = cookieUtils.getCookie('tenant_space_id')
  return request({
    url: `/service-cloud/rest/sum/v1/tenantSpaces/${tenantSpaceId}/orgs/${orgId}/contacts/${contactId}`,
    method: 'delete'
  })
}

// 添加访问员工
export const addOrgAccessRelation = (orgId, userIds) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/accessRelation/batchSave',
    method: 'post',
    data: {
      userIds,
      orgIds: [orgId]
    }
  })
}

// 查询访问员工
export const queryOrgAccessRelation = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/accessRelation/getUserListByOrgId',
    method: 'post',
    data
  })
}

//删除访问员工
export const removeOrgAccessRelation = (...relIds) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/accessRelation/batchRemoveByKeys',
    method: 'post',
    data: { relIds }
  })
}

// 更新员工信息
export const updateEmployee = (data) => {
  return request({
    url: '/service-cloud/u-route/employeeOperation/updateEmployee',
    method: 'post',
    requestHeaders: [
      {
        'content-type': 'application/json'
      }
    ],
    data
  })
}

// 新增员工信息
export const addEmployee = (data) => {
  return request({
    url: '/service-cloud/u-route/employeeOperation/addEmployee',
    method: 'post',
    requestHeaders: [
      {
        'content-type': 'application/json'
      }
    ],
    data
  })
}

//保存可访问组织信息
export const saveAccessOrgForUser = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/accessRelation/saveForUser',
    method: 'post',
    requestHeaders: [
      {
        'content-type': 'application/json'
      }
    ],
    data
  })
}

//新增角色信息
export const saveRolesForUser = (tenantSpaceId, data, id) => {
  return request({
    url: `/service-cloud/rest/sum/v1/tenantSpaces/${tenantSpaceId}/roles/${id}/users`,
    method: 'post',
    requestHeaders: [
      {
        'content-type': 'application/json'
      }
    ],
    data
  })
}

//删除相关的访问组织
export const removeAccessRelationByUserIds = (data) => {
  return request({
    url: `/service-cloud/rest/cc-management/v1/accessRelation/batchRemoveByUserIds`,
    method: 'post',
    requestHeaders: [
      {
        'content-type': 'application/json'
      }
    ],
    data
  })
}

//删除员工
export const deleteEmployee = (data) => {
  return request({
    url: `/service-cloud/u-route/employeeOperation/deleteEmployee`,
    method: 'post',
    requestHeaders: [
      {
        'content-type': 'application/json'
      }
    ],
    data
  })
}

//查组织机构主管
export const queryOrgContacts = (tenantSpaceId, id) => {
  return request({
    url: `/service-cloud/rest/sum/v1/tenantSpaces/${tenantSpaceId}/orgs/${id}/contacts`,
    method: 'get',
    requestHeaders: [
      {
        'content-type': 'application/json'
      }
    ]
  })
}

//查组织机构主管
export const queryOrgContactsByName = (tenantSpaceId, id, data) => {
  return request({
    url: `/service-cloud/rest/sum/v1/tenantSpaces/${tenantSpaceId}/orgs/${id}/contacts`,
    method: 'GET',
    data
  })
}

//修改密码
export const resetPassword = (data) => {
  return request({
    url: `/service-cloud/u-route/pwdOperation/resetPassword`,
    method: 'post',
    requestHeaders: [
      {
        'content-type': 'application/json'
      }
    ],
    data
  })
}

// 查询会话用户
export const queryUserLock = (tenantSpaceId, account) => {
  return request({
    url: `/service-cloud/rest/sum/v1/tenantSpaces/${tenantSpaceId}/login/query`,
    method: 'post',
    data: {
      account: account
    }
  })
}

// 强制退出会话
export const kickoutUser = (tenantSpaceId, account) => {
  return request({
    url: `/service-cloud/rest/sum/v1/tenantSpaces/${tenantSpaceId}/kickout-users`,
    method: 'post',
    data: {
      loginid: account
    }
  })
}

// 锁定用户
export const lockUser = (tenantSpaceId, userId) => {
  return request({
    url: `/service-cloud/rest/sum/v1/tenantSpaces/${tenantSpaceId}/locked-users`,
    method: 'post',
    data: {
      userIds: [userId]
    }
  })
}

// 解锁用户
export const unlockUser = (password, userId) => {
  return request({
    url: `/service-cloud/u-route/accountLockAction/unlock`,
    method: 'post',
    data: {
      param: {
        password: password,
        userId: userId
      }
    },
    requestHeaders: [
      {
        'content-type': 'application/json'
      }
    ]
  })
}

// 联邦用户无密码解锁用户
export const unLockWithoutPwd = userId => {
  return request({
    url: `/service-cloud/u-route/accountLockAction/unlock`,
    method: 'post',
    data: {
      param: {
        userId: userId
      }
    },
    requestHeaders: [
      {
        'content-type': 'application/json'
      }
    ]
  })
}

//校验密码
//param: {password: "xx", pwdRuleGroup: "Default"}
export const createValidatePassword = (data) => {
  return request({
    url: `/service-cloud/u-route/pwdOperation/createValidatePassword`,
    method: 'post',
    requestHeaders: [
      {
        'content-type': 'application/json'
      }
    ],
    data
  })
}

//批量修改访问组织
export const batchSaveAccessRelation = (data) => {
  return request({
    url: `/service-cloud/rest/cc-management/v1/accessRelation/batchSave`,
    method: 'post',
    requestHeaders: [
      {
        'content-type': 'application/json'
      }
    ],
    data
  })
}

//查询角色树信息
export const getEmployeeRoleBetree = () => {
  return request({
    url: `/service-cloud/rest/sum/v1/smbebservice/getemployeerolebetree`,
    method: 'post',
    requestHeaders: [
      {
        'content-type': 'application/json'
      }
    ]
  })
}

//查询单个角色信息
export const queryRoleById = (tenantSpaceId, id) => {
  return request({
    url: `/service-cloud/rest/sum/v3/tenantSpaces/${tenantSpaceId}/roles/${id}`,
    method: 'get'
  })
}

//查询角色权限
export const queryRoleAuth = (tenantSpaceId, data, id) => {
  return request({
    url: `/service-cloud/rest/sum/v3/tenantSpaces/${tenantSpaceId}/roles/${id}`,
    method: 'get',
    data
  })
}

//删除该角色下的某一个权限
export const deleteRoleAuth = (tenantSpaceId, data, id) => {
  return request({
    url: `/service-cloud/rest/sum/v1/tenantSpaces/${tenantSpaceId}/roles/${id}/perms`,
    method: 'post',
    requestHeaders: [
      {
        'content-type': 'application/json'
      }
    ],
    data
  })
}

//查询该角色下的员工
export const queryAuthEmployee = (data) => {
  return request({
    url: `/service-cloud/u-route/roleOperation/queryRoleUsers`,
    method: 'post',
    requestHeaders: [
      {
        'content-type': 'application/json'
      }
    ],
    data
  })
}

// 查询会话
export const querySession = (data) => {
  return request({
    url: '/service-cloud/u-route/sessionmanagement/querySession',
    method: 'post',
    data,
    requestHeaders: [
      {
        'content-type': 'application/json'
      }
    ]
  })
}

// 删除会话
export const deleteSession = (data) => {
  return request({
    url: '/service-cloud/u-route/sessionmanagement/deleteSession',
    method: 'post',
    data,
    requestHeaders: [
      {
        'content-type': 'application/json'
      }
    ]
  })
}

//查询操作日志
export function queryOperaLog(tenantSpaceId, data) {
  const url = `/service-cloud/rest/sum/v1/tenantSpaces/${tenantSpaceId}/operlogs`
  return request({
    url,
    method: 'get',
    data: data
  })
}

//查询操作日志(系统管理员)
export function queryOperaLogForSystem(data) {
  const url = `/service-cloud/rest/sum/v1/operlogs`
  return request({
    url,
    method: 'get',
    data: data
  })
}

//查询操作日志（普通用户）
export function queryOperaLogForCommon(tenantSpaceId, userId, data) {
  const url = `/service-cloud/rest/sum/v1/tenantSpaces/${tenantSpaceId}/users/${userId}/operlogs`
  return request({
    url,
    method: 'get',
    data: data
  })
}

//查询规则组
export function queryGroupRules(tenantSpaceId, data) {
  const url = `/service-cloud/rest/sum/v1/tenantSpaces/${tenantSpaceId}/pwd-rule-groups`
  return request({
    url,
    method: 'get',
    data: data
  })
}

export function queryGroupRuleDetail(tenantSpaceId, groupId, data) {
  const url = `/service-cloud/rest/sum/v1/tenantSpaces/${tenantSpaceId}/pwd-rule-groups/${groupId}/pwd-rules`
  return request({
    url,
    method: 'get',
    data: data
  })
}

export function updateGroupRuleDetail(tenantSpaceId, groupId, data) {
  const url = `/service-cloud/rest/sum/v1/tenantSpaces/${tenantSpaceId}/pwd-rule-groups/${groupId}/pwd-rules`
  return request({
    url,
    method: 'patch',
    data: data
  })
}

// 修改规则组信息
export function updateGroupRuleInfo(tenantSpaceId, data) {
  const url = `/service-cloud/rest/sum/v1/tenantSpaces/${tenantSpaceId}/pwd-rule-groups/update`
  return request({
    url,
    method: 'patch',
    data: data
  })
}

// 新增密码规则组
export function addGroupRuleInfo(data) {
  const url = `/service-cloud/u-route/pwdRuleOperation/addPwdRule`
  return request({
    url,
    method: 'post',
    data: data
  })
}

// 删除密码规则组
export const deleteGroupRuleInfo = (tenantSpaceId, groupId) => {
  return request({
    url: `/service-cloud/rest/sum/v1/tenantSpaces/${tenantSpaceId}/pwd-rule-groups/${groupId}`,
    method: 'DELETE'
  })
}

//删除角色
export const deleteRole = (tenantSpaceId, id) => {
  return request({
    url: `/service-cloud/rest/sum/v1/tenantSpaces/${tenantSpaceId}/roles/${id}`,
    method: 'DELETE'
  })
}

//修改角色
export const editRole = (data) => {
  return request({
    url: `/service-cloud/u-route/roleOperation/modifyRole`,
    method: 'post',
    requestHeaders: [
      {
        'content-type': 'application/json'
      }
    ],
    data
  })
}

//新增角色
//{param: {roleName: "1111111", parentRoleId: "1690698825913586463", description: "1111"}}
export const addRole = (data) => {
  return request({
    url: `/service-cloud/u-route/roleOperation/addRole`,
    method: 'post',
    requestHeaders: [
      {
        'content-type': 'application/json'
      }
    ],
    data
  })
}

//赋予权限
export const grantRoleAuth = (tenantSpaceId, data, id) => {
  return request({
    url: `/service-cloud/rest/sum/v1/tenantSpaces/${tenantSpaceId}/roles/${id}/perms`,
    method: 'post',
    requestHeaders: [
      {
        'content-type': 'application/json'
      }
    ],
    data
  })
}

//检查用户是否有联邦用户权限
export const checkFederation = () => {
  return request({
    url: `/service-cloud/u-route/employeeOperation/checkFederation`,
    method: 'post'
  })
}

// 查询技能队列
export const queryAllSkill = (limit, offset, name) => {
  return request({
    url: `/service-cloud/rest/cc-management/v1/skill/queryAllSkill`,
    method: 'post',
    data: {
      limit: limit,
      offset: offset,
      name: name
    }
  })
}

// 查询技能队列数量
export const queryAllSkillCount = (name) => {
  return request({
    url: `/service-cloud/rest/cc-management/v1/skill/queryAllSkillInfoCountByName`,
    method: 'post',
    data: {
      name: name
    }
  })
}

// 查询技能队列组
export const queryAllSkillGroup = (limit, offset, name) => {
  return request({
    url: `/service-cloud/rest/cc-management/v1/skillgroup/queryCondition`,
    method: 'post',
    data: {
      limit: limit,
      offset: offset,
      searchContent: name
    }
  })
}

// 查询技能队列数量
export const queryAllSkillGroupCount = (name) => {
  return request({
    url: `/service-cloud/rest/cc-management/v1/skillgroup/querySkillGroupCountCondition`,
    method: 'post',
    data: {
      searchContent: name
    }
  })
}

// 查询平台角色
export const queryAgentRoleItems = (locale) => {
  return request({
    url: `/service-cloud/rest/cc-management/v1/ccagent/queryAgentRoleItems`,
    method: 'post',
    data: {
      'u-locale': locale
    }
  })
}

// 获取未绑定业务帐号的工号列表
export const queryUnbindedAgent = (data) => {
  return request({
    url: `/service-cloud/rest/cc-management/v1/ccagent/queryUnbindedAgent`,
    method: 'post',
    data
  })
}

// 查询租户特性
export const queryTenantFeatures = () => {
  return request({
    url: `/service-cloud/rest/cc-management/v1/tenantInfo/queryTenantFeaturesForWeb`,
    method: 'post'
  })
}

// 查询质检组/话务组
export const queryAllGroup = () => {
  return request({
    url: `/service-cloud/rest/cmsapp/v1/qualitycheck/queryallccgroup`,
    method: 'post'
  })
}

// 查询智能识别
export const queryAiRecog = () => {
  return request({
    url: `/service-cloud/rest/cc-management/v1/ccagent/queryAiRecog`,
    method: 'post'
  })
}

// 校验透显标识固话
export const validateTransparentDisplayFlag = (data) => {
  return request({
    url: `/service-cloud/rest/cc-management/v1/ccagent/validateTransparentDisplayFlag`,
    method: 'post',
    data
  })
}

// 保存座席信息
export const updateAgentInfo = (data) => {
  return request({
    url: `/service-cloud/rest/cc-management/v1/ccagent/updateAgentForSum`,
    method: 'post',
    data
  })
}

// 更新座席组织信息
export const updateAgentOrgs = (data) => {
  return request({
    url: `/service-cloud/rest/cc-management/v1/ccagent/updateAgentOrgs`,
    method: 'post',
    data
  })
}

// 批量保存座席信息
export const batchUpdateAgentInfo = (data) => {
  return request({
    url: `/service-cloud/rest/cc-management/v1/ccagent/batchUpdateAccountConf`,
    method: 'post',
    data
  })
}

// 加入质检组/话务组
export const addGroupBatch = (data) => {
  return request({
    url: `/service-cloud/rest/cmsapp/v1/agentgroup/addgroupmemberbatch`,
    method: 'post',
    data
  })
}

// 批量退出质检组/话务组
export const deleteGroupBatch = (data) => {
  return request({
    url: `/service-cloud/rest/cmsapp/v1/agentgroup/deletegroupmemberbatch`,
    method: 'post',
    data
  })
}

// 单个退出质检组/话务组
export const deleteGroup = (id) => {
  return request({
    url: `/service-cloud/rest/cmsapp/v1/agentgroup/${id}`,
    method: 'delete'
  })
}

// 查询用户座席配置信息详情
export const queryAgentInfoByUserId = (userId) => {
  return request({
    url: `/service-cloud/rest/cc-management/v1/ccagent/queryAgentInfoByUserId`,
    method: 'post',
    data: {
      userId: userId
    }
  })
}

// 查询用户座席配置信息列表
export const queryAgentInfoByUserIdList = (userIds) => {
  return request({
    url: `/service-cloud/rest/cc-management/v1/ccagent/queryAgentInfoByUserIdList`,
    method: 'post',
    data: {
      userIds: userIds
    }
  })
}

// 查询用户座席配置信息列表
export const batchClearByUserIds = (userIds) => {
  return request({
    url: `/service-cloud/rest/cc-management/v1/ccagent/batchClearByUserIds`,
    method: 'post',
    data: {
      userIds: userIds
    }
  })
}

// 已加入的用户组信息
export const queryAttendedGroupByWorkNo = (workNo) => {
  return request({
    url: `/service-cloud/rest/cmsapp/v1/agentgroup/queryattendedgroupbyworkno/${workNo}`,
    method: 'get'
  })
}

//下载联邦用户导入模板
export const downloadFederationUserTemp = () => {
  return request({
    url: `/service-cloud/u-route/ccmanagement/federation/usertemplate`,
    method: 'get'
  })
}

//导入联邦用户
export const importFederationUser = (data) => {
  return request({
    url: `/service-cloud/u-route/ccmanagement/federation/users`,
    method: 'post',
    data
  })
}

//查询联邦用户导入结果
//limit: 10 offset: 0
export const queryFederationUserImportTask = (data) => {
  return request({
    url: `/service-cloud/rest/cc-management/v1/federation/queryFederationUserImportTask`,
    method: 'post',
    requestHeaders: [
      {
        'content-type': 'application/json'
      }
    ],
    data
  })
}

// 查询用户群组下的员工列表
export const queryUserListByGroupId = (datas) => {
  return request({
    url: `/service-cloud/u-route/usergroupaction/queryUsersByGroupId`,
    method: 'POST',
    requestHeaders: [
      {
        'content-type': 'application/json'
      }
    ],
    data: { param: datas }
  })
}

//查看租户环境列表
export const getTenatList = (data) => {
  return request({
    url: `/service-cloud/rest/sum/v2/tenantSpaces`,
    method: 'get',
    data
  })
}

//新建租户环境
export const createTenantSpaces = (data) => {
  return request({
    url: `/service-cloud/rest/sum/v5/tenantSpaces`,
    method: 'post',
    requestHeaders: [
      {
        'content-type': 'application/json'
      }
    ],
    data
  })
}

//查询租户环境详情
export const queryTenantSpaces = (tenantSpaceId) => {
  return request({
    url: `/service-cloud/rest/sum/v3/tenantSpaces/${tenantSpaceId}`,
    method: 'get'
  })
}

//更新租户环境
export const updateTenantSpaces = (tenantSpaceId, data) => {
  return request({
    url: `/service-cloud/rest/sum/v3/tenantSpaces/${tenantSpaceId}`,
    method: 'put',
    requestHeaders: [
      {
        'content-type': 'application/json'
      }
    ],
    data
  })
}

//删除租户环境
export const deleteTenantSpaces = (tenantSpaceId) => {
  return request({
    url: `/service-cloud/rest/sum/v1/tenantSpaces/${tenantSpaceId}`,
    method: 'delete'
  })
}

//校验密码
export const validatePassword = (data) => {
  return request({
    url: `/service-cloud/u-route/pwdOperation/validatePassword`,
    method: 'post',
    requestHeaders: [
      {
        'content-type': 'application/json'
      }
    ],
    data
  })
}

// 查询角色模版列表
export function queryRoleTemplates() {
  const url = `/service-cloud/u-route/roleOperation/queryRoleTemplateList`
  return request({
    url,
    method: 'GET'
  })
}

export const loadAppcubeApps = (data) => {
  return request({
    url: `/service-cloud/rest/service-pub/v1/appcube/apps`,
    method: 'post',
    data: data,
  })
}

export const loadAppcubeAppMenus = (appId) => {
  return request({
    url: `/service-cloud/rest/service-pub/v1/appcube/app/${appId}/menus`,
    method: 'get',
  })
}

export const loadAppcubeI18n = () => {
  return request({
    url: `/service-cloud/rest/service-pub/v1/appcube/i18n`,
    method: 'get',
  })
}

export const createDataExportTask = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/organization/createDataExportTask',
    method: 'POST',
    data
  })
}

export const queryDataExportTask = data=> {
  return request({
    url: '/service-cloud/rest/cc-management/v1/organization/queryDataExportTask',
    method: 'POST',
    data
  })
}

export const createUserDataExportTask = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/employee/createDataExportTask',
    method: 'POST',
    data
  })
}

export const queryUserDataExportTask = data=> {
  return request({
    url: '/service-cloud/rest/cc-management/v1/employee/queryDataExportTask',
    method: 'POST',
    data
  })
}