<template>
  <div>
    <sweet-dialog v-model="dialogVisible" :close-on-click-modal="false" :title="$t('isales.taskcalllogic.title.resultSetPolicy')" width="896" class="aicc-dialog-limit-height">
      <sweet-form :label-position="labelPosition" label-width="200px" ref="addCallNoPolicy"  :model="addCallNoPolicy" :rules="addCallNoPolicyRule" class="maxForm">
        <sweet-row>
        <sweet-col :span="24">
          <sweet-form-item label=" " :title="$t('isales.callNo.field.type')" prop="isNewType">
            <sweet-radio-group v-model="this.addCallNoPolicy.isNewType">
              <sweet-radio label="0">{{ $t('isales.tasklogic.field.exist.numbertype')}}</sweet-radio>
              <sweet-radio label="1">{{ $t('isales.tasklogic.field.add.numbertype')}}</sweet-radio>
            </sweet-radio-group>
          </sweet-form-item>
        </sweet-col>
        <sweet-col :span="16" >
          <sweet-form-item :label="$t('isales.callNo.field.type')"  prop="dataTypeExit" v-if="this.addCallNoPolicy.isNewType==='0'">
            <sweet-select v-model="this.addCallNoPolicy.dataTypeExit" :placeholder="$t('isales.taskinfo.placeholder.choose')" :fit-input-width="true" :show-resize="false">
              <sweet-option v-for="(item, index) in this.dataTypes" :key="item.key"
                            :label="item.key"
                            :value="item.key"/>
            </sweet-select>
          </sweet-form-item>
          <sweet-form-item :label="$t('isales.callNo.field.type')"  prop="dataTypeAdd" v-if="this.addCallNoPolicy.isNewType==='1'" :showErrorTips="true">
            <sweet-input v-model="this.addCallNoPolicy.dataTypeAdd" :placeholder="$t('isales.taskinfo.placeholder.input')" maxlength="20" />
          </sweet-form-item>
        </sweet-col>
        <sweet-col :span="24">
          <sweet-form-item :label="$t('isales.taskcalllogic.title.resultSetPolicy')" :title="$t('isales.taskcalllogic.title.resultSetPolicy')" prop="isResultRetry">
            <sweet-switch v-model="this.addCallNoPolicy.isResultRetry" @change="changeIsResultRetry"/>
          </sweet-form-item>
        </sweet-col>
        <sweet-col :span="16" class="isales-background-color isales-background-padding" v-if="this.addCallNoPolicy.isResultRetry === false">
          <sweet-form-item :label="$t('isales.tasklogic.field.retryDay')" :title="$t('isales.tasklogic.field.retryDay')" prop="policyJson.policy.retryDay">
            <sweet-select v-model="this.addCallNoPolicy.policyJson.policy.retryDay" value-key="value" :placeholder="$t('isales.taskinfo.placeholder.choose')" :fit-input-width="true" :show-resize="false">
              <sweet-option v-for="item in retryDayItems" :value="item.value" :label="item.label" ></sweet-option>
            </sweet-select>
          </sweet-form-item>
        </sweet-col>
        <sweet-col :span="8" class="isales-background-color isales-background-padding" v-if="this.addCallNoPolicy.isResultRetry === false"></sweet-col>
        <sweet-col :span="16" class="isales-background-color" v-if="this.addCallNoPolicy.isResultRetry === false">
          <sweet-form-item :label="$t('isales.tasklogic.field.retryTimes')" :title="$t('isales.tasklogic.field.retryTimes')" prop="policyJson.policy.retryTimes">
            <sweet-select v-model="this.addCallNoPolicy.policyJson.policy.retryTimes" value-key="value" :placeholder="$t('isales.taskinfo.placeholder.choose')" :fit-input-width="true" :show-resize="false">
              <sweet-option v-for="item in retryTimeItems" :value="item.value" :label="item.label" ></sweet-option>
            </sweet-select>
          </sweet-form-item>
        </sweet-col>
        <sweet-col :span="8" class="isales-background-color" v-if="this.addCallNoPolicy.isResultRetry === false"></sweet-col>
        <sweet-col :span="24" class="isales-background-color isales-background-padding" v-if="this.addCallNoPolicy.isResultRetry === true">
          <sweet-form-item :title="$t('isales.taskcalllogic.title.resultPolicySet')" required>
            <template #label style="margin-top:20px" ><span>{{ $t('isales.taskcalllogic.title.resultPolicySet') }}</span></template>
            <div style="width: 98%" >
              <aicc-table :tableData="this.addCallNoPolicy.resultPolicyJson" :max-height="180" :tableColumns="tableColumns" :tableTools="{ columnConfig: false }">
                  <template #result="scope">
                    <div class="aicc-m-right--medium aicc-p-bottom--small aicc-p-top--small">
                      <sweet-form-item :prop="`resultPolicyJson.${scope.$index}.result`"
                                       :rules="[{ required: true, message: this.$t('isales.business.result.message.must'), trigger: ['blur', 'change'] }]">
                        <sweet-select v-model="scope.row.result" value-key="value" :placeholder="$t('isales.taskinfo.placeholder.choose')" :fit-input-width="true" :show-resize="false">
                          <sweet-option v-for="item in resultItems" :value="item.resultId"
                                        :label="item.resultName" ></sweet-option>
                        </sweet-select>
                      </sweet-form-item>
                    </div>
                  </template>
                  <template #retryNumber="scope">
                    <div class="aicc-m-right--medium aicc-p-bottom--small aicc-p-top--small">
                      <sweet-form-item  :prop="`resultPolicyJson.${scope.$index}.retryNumber`"
                                        :rules="[{ required: true, message: this.$t('isales.business.result.message.must'), trigger: ['blur', 'change'] }]">
                        <sweet-select v-model="scope.row.retryNumber" value-key="value" :placeholder="$t('isales.taskinfo.placeholder.choose')" :fit-input-width="true" :show-resize="false">
                          <sweet-option v-for="item in retryNumberItems" :value="item.value" :label="item.label" ></sweet-option>
                        </sweet-select>
                      </sweet-form-item>
                    </div>
                  </template>
                  <template #callInterval="scope">
                    <div class=" aicc-m-right--medium aicc-p-bottom--small aicc-p-top--small">
                      <sweet-form-item  :prop="`resultPolicyJson.${scope.$index}.callInterval`"
                                        :rules="[{ required: true, message: this.$t('isales.business.result.message.must'), trigger: ['blur', 'change'] },{validator: this.$Validate.checkDefaultIntervalRang, trigger: ['blur', 'change']}]">
                        <sweet-input v-model="scope.row.callInterval" :placeholder="$t('isales.tasklogic.field.callInterval')" width="200" :disabled="scope.row.retryNumber <=0"/>
                      </sweet-form-item>
                    </div>
                  </template>
                  <template #operate="scope">
                    <sweet-button class="task-icon" :icon="Delete" type="icon" @click="deleteResultPolicyJson(scope)"/>
                  </template>
              </aicc-table>
              <div v-if="!isView" class="isales-task-info-add aicc-calltime-align--absolute-left"
                   @click="addResultPolicy" >
                <sweet-button class="task-icon" style="margin-right: 12px" :icon="CirclePlus" type="icon"/>
                <span>{{ $t('isales.file.title.create')}}</span>
              </div>
            </div>
          </sweet-form-item>
        </sweet-col>
      </sweet-row>
      </sweet-form>
      <template #footer>
        <span class="dialog-footer">
          <sweet-button @click="dialogVisible = false">{{ $t('isales.common.title.cancel') }}</sweet-button>
          <sweet-button type="primary" @click="confirm">{{ $t('isales.common.title.confirm') }}</sweet-button>
        </span>
      </template>
    </sweet-dialog>
  </div>
</template>

<script>
import {
  RESULT_ITEMS_CODES,
  RETRY_DAYS,
  RETRY_NUMBER_ITEMS_CODES,
  RETRY_TIMES
} from "@/views/isales/views/systemAutoCallTask/enum.js";
import {queryAllCallNoType} from "@/views/isales/api/systemAutoCallTask.js";
import {ElMessage} from "element-plus";
import {Delete,CirclePlus} from '@element-plus/icons-vue'

export default {
  name: "iSalesCallTurnSelect",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    showModel: {
      type: Object,
      default() {
        return {}
      },
    }
  },
  data() {
    return {
      labelPosition: window.isRTL ? 'right' : 'left',
      Delete,
      CirclePlus,
      existDataType: [],
      dataTypes: [],
      selectCallNoType: '',
      retryDayItems: RETRY_DAYS,
      retryTimeItems: RETRY_TIMES,
      resultItems: RESULT_ITEMS_CODES,
      retryNumberItems: RETRY_NUMBER_ITEMS_CODES,
      retryDay: '1',
      callLogic: {},
      tableColumns: [
        {
          label: this.$t('isales.tasklogic.field.result'),
          slot: 'result',
          width: '200px',
          'show-overflow-tooltip':false
        },
        {
          label: this.$t('isales.tasklogic.field.retryNumber'),
          slot: 'retryNumber',
          width: '200px',
          'show-overflow-tooltip':false
        },
        {
          label: this.$t('isales.tasklogic.field.callInterval'),
          slot: 'callInterval',
          width: '200px',
          'show-overflow-tooltip':false
        },
        {
          label: this.$t('isales.common.title.operation'), // 操作
          slot: 'operate',
          fixed: 'right'
        }
      ],
      addCallNoPolicy:{
        isNewType: '0',
        dataType: '',
        dataTypeAdd:'',
        dataTypeExit:'',
        isResultRetry: false,
        policyJson: {
          policy: {
            retryDay: '1',
            retryTimes: '1'
          }
        },
        resultPolicyJson: []
      },
      addCallNoPolicyRule:{
        dataTypeExit: [
          {required: true, message: this.$t('isales.business.result.message.must'), trigger: ['change', 'blur']},
          {validator: this.checkDuplicate, trigger: ['blur', 'change']},
        ],
        dataTypeAdd: [
          {required: true, message: this.$t('isales.business.result.message.must'), trigger: ['change', 'blur']},
          {validator: this.$Validate.checkCallNoTypeSpecialChar, trigger: ['change', 'blur']},
          {validator: this.checkDuplicate, trigger: ['blur', 'change']},
          {validator: this.checkDefaultType, trigger: ['blur', 'change']}
        ],
        isResultRetry:[{required: true, message: this.$t('isales.business.result.message.must'), trigger: ['change', 'blur']}],
        policyJson: {
          policy: {
            retryDay: [{required: true, message: this.$t('isales.business.result.message.must'), trigger: ['change', 'blur']}],
            retryTimes: [{required: true, message: this.$t('isales.business.result.message.must'), trigger: ['change', 'blur']
            }],
          }
        }
      }
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      }
    }
  },
  methods: {
    async queryAllCallNoType() {
      let queryCondition = {};
      queryAllCallNoType(queryCondition).then(res => {
        if (res) {
          this.existDataType = res;
          for (var i = 0; i < res.length; i++) {
            var type = { "key": res[i] };
            this.dataTypes.push(type);
          }
          // 将轮次添加的新号码类型并入查询出的号码类型中
          if (this.showModel.newNumberTypeList && this.showModel.newNumberTypeList.length > 0) {
            this.showModel.newNumberTypeList.forEach(element => {
              this.dataTypes.push({ "key": element });
            });
            this.existDataType = this.existDataType.concat(this.showModel.newNumberTypeList);
          }
        }
      })
    },
    async confirm() {
      const valid = await this.$refs.addCallNoPolicy.validate().catch(err => err)
      if (valid !== true) {
        // 会有一个index为-1的rule，原因未知，暂时规避
        if (valid['resultPolicyJson.-1.result']) {
          delete valid['resultPolicyJson.-1.result']
        }
        if (valid['resultPolicyJson.-1.retryNumber']) {
          delete valid['resultPolicyJson.-1.retryNumber']
        }
        if (valid['resultPolicyJson.-1.callInterval']) {
          delete valid['resultPolicyJson.-1.callInterval']
        }
      }
      let keys = Object.keys(valid)
      if (keys.length) {
        return
      }
        //新增号码类型不能和已经有的一致
        if (this.addCallNoPolicy.isNewType === '1') {
          this.addCallNoPolicy.dataType = this.addCallNoPolicy.dataTypeAdd
          if (this.existDataType) {
            let index = this.existDataType.indexOf(this.addCallNoPolicy.dataType)
            if (index >= 0) {
              ElMessage({
                type: 'error',
                duration: 10000,
                offset: 56,
                'show-close': true,
                message: this.$t('isales.taskcalllogic.message.dataTypeExist')
              });
              return false;
            }
          }
        } else {
          this.addCallNoPolicy.dataType = this.addCallNoPolicy.dataTypeExit
        }
        this.assembePolicy();
        //校验结果类型不能有重复的
        let obj = {};
        let tempArray = this.callLogic.policy.reduce(function (item, next) {
          obj[next.result] ? '' : obj[next.result] = true && item.push(next);
          return item;
        }, []);
        if (tempArray.length < this.callLogic.policy.length) {
          ElMessage({
            type: 'error',
            duration: 10000,
            'show-close': true,
            offset: 56,
            message: this.$t('isales.taskcalllogic.message.resultTips')
          });
          return false;
        }
        this.dialogVisible = false
        this.$emit('addCallnoPolicy', [this.callLogic, this.addCallNoPolicy.isNewType]);
    },
    // 组装参数
    assembePolicy() {
      this.callLogic = {}
      //首先判断是不是根据结果来配置
      if (this.addCallNoPolicy.isResultRetry === true) {
        this.callLogic.dataType = this.addCallNoPolicy.dataType;
        this.callLogic.type = "1";
        for (let index = 0; index < this.addCallNoPolicy.resultPolicyJson.length; index++) {
          this.addCallNoPolicy.resultPolicyJson[index]['dataType'] = this.addCallNoPolicy.dataType;
        }
        this.callLogic.policy = this.addCallNoPolicy.resultPolicyJson;
      } else {
        this.callLogic.dataType = this.addCallNoPolicy.dataType;
        this.callLogic.type = "0";
        var policys = []
        policys.push(this.addCallNoPolicy.policyJson.policy)
        this.callLogic.policy = policys;
      }

    },
    deleteResultPolicyJson(scope){
      if (this.addCallNoPolicy.resultPolicyJson.length <= 1) {
        ElMessage({
          type: 'error',
          duration: 10000,
          'show-close': true,
          offset: 56,
          message: this.$t('isales.taskcalllogic.message.alreadyLastPolicy')
        });
      } else {
        const index = scope.$index;
        this.addCallNoPolicy.resultPolicyJson.splice(index, 1);
      }

    },
    addResultPolicy(){
        const tempObj = {
          "result": '',
          "retryNumber": '',
          "callInterval": 600
        };
        this.addCallNoPolicy.resultPolicyJson.push(tempObj);
    },
    changeIsResultRetry(){
      if (this.addCallNoPolicy.isResultRetry && this.addCallNoPolicy.resultPolicyJson.length == 0) {
        const tempObj = {
          "result": '',
          "retryNumber": '',
          "callInterval": 600
        };
        this.addCallNoPolicy.resultPolicyJson.push(tempObj);
      }
    },
    checkDuplicate(rule, value, callback) {
      // 选择时判断已经存在
      const arr = this.showModel.retryPolicy.callNoPolicyList.filter(existCallNoLogic => {
        return existCallNoLogic.dataType == value
      });
      // 新增时判断已经存在
      let exist = -1;
      if (this.addCallNoPolicy.isNewType === '1') {
        exist = this.existDataType.indexOf(value)
      }
      const validateFlag = arr.length > 0 || exist >= 0 ? false : true;
      if (validateFlag) {
        callback()
      } else {
        callback(new Error(this.$t('isales.taskcalllogic.message.dataTypeExist')));
      }
    },
    checkDefaultType(rule, value, callback) {
      if (value === '9D9T9') {
        callback(new Error(this.$t('isales.taskcalllogic.message.dataTypeDefaultNoCreate')));
      } else {
        callback()
      }
    }
  },
  async created() {
    await this.queryAllCallNoType();
  },
}
</script>

<style lang="less" scoped>
.isales-task-info-add {
  padding-top:10px;
  padding-left:20px;
  font-size: 14px;
  color: #366BFC;
  font-weight: bold;
  background-color: #FFFFFF;
}

.aicc-calltime-align--absolute-left {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: flex-start;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%
}

.isales-background-color {
  background-color: #F4F6FA;
}
.isales-background-padding{
  padding-top: 10px;
}

.task-icon {
  font-size: 18px !important;
  color: #366BFC;
}

.maxForm{
  max-height:620px;
  overflow-y: auto;
}
</style>