export default {
  "ccnotification.notifition.sendmessage.priorityGeneral" : "منخفض",
  "ccnotification.bulletinType.message.releaseTime" : "وقت الافراج",
  "ccnotification.gatewayconfig.label.slipWindowSizetitle" : "حجم النافذة المنزلقة هو رقم صحيح",
  "ccnotification.notification.label.checkAttachNameLegnth" : "لا يمكن أن يتجاوز اسم مرفق البريد الإلكتروني 100 حرف",
  "ccnotification.common.title.confirm" : "تأكيد",
  "message.template.type.system" : "إعلام النظام",
  "ccnotification.bulletinType.title.Urgent" : "متوسط",
  "ccnotification.notifition.sendmessage.savesuccess" : "تم حفظ الرسالة بنجاح.",
  "ccnotification.gatewayconfig.label.replaceIfPresentFlagtitle" : "0: استبدال\، 1: لا يحل محل\، 2-255: محجوز",
  "ccnotification.gatewayconfig.label.senderPoolQueueCapacitytitle" : "طول قائمة الانتظار لإرسال الموضوع هو رقم\، على سبيل المثال\، 2000.",
  "ccnotification.gatewayconfig.label.viewgateway" : "عرض العبّارة",
  "ccnotification.center.message.toIsRead" : "هل تريد بالتأكيد وضع علامة مقروءة على الرسائل المحددة؟",
  "ccnotification.notifition.sendmessage.selectbyorg" : "وحدة التنظيم",
  "message.center.message.messageQueryResultIsEmpty" : "نتيجة استعلام الرسالة فارغة.",
  "ccnotification.notification.systemmonitor.mspId" : "MspId",
  "message.template.message.agentbebinded" : "المستلم المحدد غير مرتبط بموظف",
  "message.template.message.mediaTypeError" : "وضع إرسال غير صحيح.",
  "ccnotification.gatewayconfig.label.addgatewaysuccess" : "تمت إضافة Gatewayconfig بنجاح!",
  "ccnotification.flowcontrol.recipient.countrycode" : "رمز البلد المستلم",
  "ccnotification.bulletinType.message.everyDay" : "كل يوم",
  "ccnotification.gatewayconfig.label.defaultreceinfotitle" : "قاعدة بادئة الرقم المقبول: على سبيل المثال، إذا تم تكوين 130، فستتم مطابقة الأرقام التي تبدأ بـ 130. يتم الفصل بين الأعداد المتعددة بفواصل منقوطة (;).",
  "message.template.message.titleError" : "لا يمكن أن يتجاوز عنوان القالب 200 حرف.",
  "ccnotification.bulletinType.title.bulletintypeInvalidSect" : "وقت انتهاء الصلاحية الافتراضي",
  "ccnotification.sms.rcvAndSendMode.sendSms" : "إرسال رسائل SMS",
  "ccnotification.gatewayconfig.label.receiverPoolMaxCounttitle" : "الحد الأقصى لمجموعات مؤشر ترابط الاستلام هو رقم \، على سبيل المثال \، 200.",
  "message.center.message.willDeleteMessages" : "هل تريد بالتأكيد حذف الرسالة المحددة؟",
  "ccnotification.agent.operation.searchworkno" : "الرجاء إدخال معرف الموظف",
  "ccnotification.agent.message.updateCtiAndDbFail" : "فشل تحديث cti وDb.",
  "ccnotification.gatewayconfig.message.securityInfo" : "لا شيء يشير إلى أن البيانات يتم إرسالها في نص عادي. لذلك قد تتسرب البيانات. STARTTLS هو بروتوكول غير آمن. استخدام بروتوكولات غير آمنة يشكل مخاطر أمنية. يوصى باستخدام بروتوكول TLS.",
  "ccnotification.common.label.name" : "الاسم",
  "message.template.message.mediaType" : "وضع إرسال الرسائل",
  "ccnotification.gatewayconfig.label.paramMaxLengthIsError" : "لا يمكن أن تتجاوز قيمة المعلمة 500 حرف",
  "ccnotification.gatewayconfig.label.receiverPoolMinCounttitle" : "الحد الأدنى لاستقبال مجموعات الموضوع هو عدد\، على سبيل المثال\، 20.",
  "ccnotification.bulletinType.message.perMonth" : "في الشهر",
  "ccnotification.caseserver.fail.duplicateContent" : "محتوى مكرر",
  "ccnotification.gatewayconfig.label.senderPoolMaxCount" : "ماكس إرسال برك الموضوع",
  "ccnotification.gatewayconfig.label.splitType" : "وضع تقسيم الرسائل القصيرة SMS المتضخم",
  "ccnotification.gatewayconfig.label.positive" : "لا يمكن أن يكون الرقم سالبًا.",
  "ccnotification.smstemplate.message.smstemplate" : "معلومات قالب HUAWEI CLOUD فارغة.",
  "ccnotification.bulletinType.message.Tuesday" : "الثلاثاء",
  "ccnotification.gatewayconfig.label.receiverPoolQueueCapacity" : "تلقي طول قائمة الانتظار بركة الموضوع",
  "message.center.nocitiction.sending.records.sended" : "إرسال فوري",
  "ccnotification.gatewayconfig.label.SSLEncryptionMode" : "وضع التشفير",
  "ccnotification.notifition.template.message.phone" : "- القيمة عبارة عن سلسلة من 1 إلى 15 رقمًا.",
  "ccnotification.gatewayconfig.label.interfaceVersion" : "رقم إصدار رسالة تسجيل الدخول",
  "ccn.smsnoconfig.pop.title.edittitle" : "تحرير رقم SMS",
  "ccnotification.bulletinType.title.editBulletinType" : "تعديل نوع النشرة",
  "ccnotification.common.title.fail" : "فشل",
  "ccnotification.common.title.errorsave" : "خطأ",
  "message.center.nocitiction.draftBox" : "صندوق مسودات",
  "ccnotification.notification.label.compressZipFileFailed" : "فشل ضغط الملف.zip",
  "message.template.message.nameDuplicationError" : "اسم قالب مكرر.",
  "message.template.type.out" : "الإخطارات الخارجية",
  "ccnotification.smshuawei.label.certTip.withoutProxy" : "تحميل شهادة خدمة HUAWEI CLOUD",
  "ccnotification.notifition.receivermessage.isread" : "اقرأ",
  "ccnotification.gatewayconfig.label.dataCoding.UCS2" : "ترميز UCS2",
  "ccnotification.notification.label.fileUploadFileTypeError" : "نوع الملف الذي قمت بتحميله لا يفي بالمتطلبات",
  "ccnotification.center.message.var.type" : "نوع متغير:",
  "ccnotification.bulletinType.message.createBulletinTypeFailed" : "فشل إنشاء نوع النشرة.",
  "ccnotification.notifition.sendmessage.selectGroupOrRecipient" : "حدد إما المستلم أو مجموعة المستلمين.",
  "ccnotification.notification.recmessagerouterconfig.selectitem" : "يرجى تحديد سجل.",
  "ccnotification.gatewayconfig.label.modifygatewaysuccess" : "تم تعديل Gatewayconfig بنجاح!",
  "ccnotification.flowcontrol.getFailed" : "فشل الحصول على تكوين الإعلام. تحقق من الشبكة.",
  "ccnotification.notification.recmessagerouterconfig.receiveAddress" : "عنوان المستلم",
  "ccnotification.gatewayconfig.label.localPort" : "منفذ العميل",
  "ccnotification.bulletinType.title.bulletinTypeName" : "اسم نوع النشرة",
  "ccnotification.gatewayconfig.label.selectredisconnectedgatewayconfig" : "حدد تكوين البوابة المراد قطع اتصاله",
  "ccnotification.notification.recmessagerouterconfig.caseserver" : "استدعاء الخدمة",
  "ccnotification.notification.message.noAttachement" : "لم تقم بتحميل أي مرفق.",
  "ccnotification.notifition.template.label.templateid" : "معرف القالب",
  "ccnotification.gatewayconfig.label.dataCoding.ASCII" : "سلسلة ASCII",
  "ccnotification.sms.rcvAndSendMode.receiveSms" : "تلقي رسائل SMS",
  "ccnotification.notifition.sendmessage.entertenantname" : "أدخل اسم مساحة المستأجر.",
  "message.template.message.messageTemplateId" : "معرف القالب",
  "ccnotification.gatewayconfig.label.defaultsendnotitle" : "قاعدة بادئة رقم المرسل. على سبيل المثال، إذا تم تكوين 130، فستتم مطابقة الأرقام التي تبدأ بـ 130. يتم الفصل بين الأعداد المتعددة بفواصل منقوطة (;).",
  "ccnotification.gatewayconfig.label.mailPassword" : "كلمة مرور المستخدم",
  "ccnotification.notifition.sendmessage.selectOutbox" : "حدد علبة الصادر.",
  "message.template.message.inputTitle" : "أدخل عنوانًا.",
  "ccnotification.smstemplate.message.number" : "الحد الأقصى للرقم هو 10.",
  "ccnotification.sms.smpp.replaceIfPresentFlag.replace" : "استبدال",
  "ccnotification.sms.gateway" : "رسالة SMS للعبّارة",
  "ccnotification.notification.label.deleteAttachmentFailed" : "فشل حذف المرفق",
  "ccnotification.notification.recmessagerouterconfig.deletenum.outstripping" : "يمكن حذف 100 سجل كحد أقصى في المرة الواحدة.",
  "ccnotification.bulletinType.message.deleteBulletinTypeFailed" : "فشل حذف نوع النشرة.",
  "message.template.message.recipientError" : "بعض المستلمين غير موجودين. حدد مستلم آخر",
  "ccnotification.notifition.variable.templatename" : "اسم القالب",
  "message.template.message.modifyTemplateFail" : "فشل تعديل قالب الإعلام.",
  "message.center.message.delete" : "حذف",
  "ccnotification.sms.huawei" : "Huawei SMS",
  "ccnotification.notifition.template.message.error" : "قيم إدخال غير صالحة.",
  "ccnotification.notifition.sendmessage.enteraccount" : "أدخل حساب نشاط تجاري.",
  "ccnotification.gatewayconfig.label.authtitle" : "علامة المصادقة\,الخيارات صحيحة و خاطئة",
  "ccnotification.gatewayconfig.label.certType" : "نوع الشهادة",
  "ccnotification.gatewayconfig.label.mailPort" : "ميناء",
  "ccnotification.chatserver.fail.barred" : "تخصيص الوكيل المحظور",
  "message.center.label.sendTime" : "أرسلت في",
  "ccnotification.common.button.create" : "جديد",
  "ccnotification.flowcontrol.modifyFailed" : "فشل تعديل تكوين الإعلام. تحقق من معلمات الإدخال أو الشبكة.",
  "ccnotification.gatewayconfig.label.defaultsendname" : "اسم المستخدم الافتراضي",
  "ccnotification.gatewayconfig.label.deviceTypeDescript" : "وصف نوع الجهاز",
  "ccnotification.notifition.sendmessage.enterrecipient" : "حدد مستلمًا.",
  "ccnotification.notifition.gatewaycontact.success" : "تم الاتصال بنجاح",
  "ccnotification.notifition.variable.insertvariable" : "إدراج متغير",
  "ccnotification.gatewayconfig.label.smppPduTypetitle" : "1:submit_sm 2:data_sm",
  "ccnotification.flowcontrol.eachThreshold" : "عتبة بيانات Monomedia اليومية",
  "ccnotification.bulletinType.message.daysOfMonth" : "اليوم 0",
  "ccnotification.bulletinType.message.invalidSectCheck" : "يجب أن يتراوح وقت انتهاء الصلاحية الافتراضي من 1 إلى 2000 يوم.",
  "ccnotification.bulletinType.message.nameContainSpecialChar" : "لا يمكن أن يحتوي الاسم على أحرف خاصة.",
  "ccnotification.notification.label.fileUploadFileIOError" : "حدث خطأ في تحليل الملف الذي قمت بتحميله",
  "ccnotification.notifition.variable.createvariable" : "إنشاء",
  "message.center.label.receiver" : "المتلقي",
  "ccnotification.notifition.sendmessage.delaysend" : "الإخطار المجدول",
  "ccnotification.bulletinType.message.Friday" : "الجمعة",
  "ccnotification.notifition.sendmessage.bulletinTypeName" : "اسم نوع النشرة",
  "ccnotification.gatewayconfig.label.maxEmailGatewayIsError" : "يمكن تكوين خمس بوابات استقبال أو إرسال بريد إلكتروني بحد أقصى لنفس مساحة المستأجر",
  "message.center.label.messageCenter" : "مركز الإشعارات",
  "ccn.smsnoconfig.pop.search.reminder" : "أدخل رقمًا.",
  "ccnotification.notifition.template.message.dateTimeLabel" : "التاريخ والوقت",
  "ccn.smshuawei.message.table.label" : "HUAWEI CLOUD SMS",
  "message.template.message.sendEmailError" : "عنوان البريد الإلكتروني للمرسل فارغ.",
  "ccnotification.gatewayconfig.label.mailProtocolIsError" : "يجب أن يكون نوع بروتوكول البريد الإلكتروني SMTP\، POP3.",
  "ccnotification.gatewayconfig.label.messageprotocol" : "معلمات بروتوكول الرسائل",
  "ccnotification.common.title.delete" : "حذف",
  "ccnotification.notifition.sendmessage.selectmode" : "حدد حسب",
  "ccnotification.notification.label.isUploadAttachment" : "تحميل المرفقات",
  "ccnotification.notification.emailsignature.selection.username" : "اسم المستخدم",
  "ccnotification.notification.recmessagerouterconfig.receiver" : "المتلقي",
  "ccnotification.notifition.template.label.varnumber" : "عدد المتغيرات",
  "ccnotification.template.message.delete" : "تمت الإشارة إلى القالب ولا يمكن حذفه.",
  "ccnotification.common.title.tips" : "نصائح",
  "ccnotification.notification.systemmonitor.status" : "الحالة",
  "ccnotification.notifition.receivermessage.all" : "الكل",
  "ccnotification.notification.label.fileInjectionRiskException" : "استثناء خطر حقن مرفق البريد الإلكتروني",
  "message.template.message.createTime" : "إنشاء في",
  "ccnotification.notifition.sendmessage.internal" : "رسالة داخلية",
  "message.center.label.deleteisread" : "حذف جميع الرسائل المقروءة",
  "ccnotification.gatewayconfig.label.deviceDescript" : "وصف الجهاز",
  "ccnotification.agent.label.account" : "الحساب",
  "ccnotification.gatewayconfig.label.addgatewayfailed" : "فشلت إضافة Gatewayconfig!",
  "message.template.message.queryagentbebindedfailed" : "فشل الاستعلام عن المستخدم المرتبط بالموظف",
  "message.center.label.search" : "البحث",
  "ccnotification.gatewayconfig.label.selectredgatewayconfig" : "مراقبة العبّارة المحددة:",
  "message.center.label.delete" : "حذف",
  "ccnotification.notifition.template.placeholder.signatureName" : "أدخل اسم توقيع",
  "ccnotification.flowcontrol.setFlowType" : "نوع حد البيانات",
  "ccnotification.gatewayconfig.label.addrTon" : "رمز نوع عنوان ESME",
  "ccnotification.common.button.delete" : "حذف",
  "ccnotification.common.title.confreset" : "تأكيد إعادة الضبط",
  "ccnotification.notifition.template.message.phoneLabel" : "رقم الهاتف",
  "ccn.smsnoconfig.pop.check.deletewarning" : "هل تريد بالتأكيد حذف البيانات المحددة؟",
  "ccnotification.agent.pageurls.isconfreset" : "هل تريد بالتأكيد إعادة تعيينه؟",
  "ccnotification.notification.emailsignature.reachmaxcount" : "تجاوز عدد توقيعات البريد الإلكتروني الحد الأقصى.",
  "ccnotification.gatewayconfig.label.configTypeIsError" : "نوع التكوين غير صحيح. نوع التكوين الداخلي للمعلمة غير متناسق",
  "ccnotification.smshuawei.message.specialCharacters" : "لا يُسمح بالرموز الخاصة.",
  "ccnotification.smshuawei.message.updatesuccess" : "تم تعديل قناة SMS بنجاح.",
  "ccnotification.notifition.sendmessage.notifitiontitle" : "أدخل عنوانًا.",
  "ccnotification.notification.label.rebuildSuccess" : "إعادة بناء النجاح",
  "message.template.button.delete" : "حذف",
  "ccnotification.smstemplate.message.fail" : "فشل إنشاء قالب HUAWEI CLOUD.",
  "ccnotification.notifition.variable.searchTempdateName" : "أدخل اسم قالب.",
  "message.template.message.nameError" : "لا يمكن أن يكون اسم القالب فارغًا أو يتجاوز 200 حرف.",
  "ccnotification.smshuawei.label.appKey" : "مفتاح التطبيق",
  "ccnotification.sms.rcvAndSendMode.receiveAndSendSms" : "إرسال واستقبال رسائل SMS",
  "ccnotification.flowcontrol.totalThreshold" : "عتبة إجمالي البيانات اليومية",
  "ccnotification.gatewayconfig.label.senderPoolMinCount" : "دقيقة إرسال حمامات الخيط",
  "message.template.message.type" : "نوع الإشعار",
  "ccnotification.notifition.sendmessage.priorityExtraUrgent" : "عالية",
  "message.template.message.willDeleteTemplate" : "قد يؤدي حذف القالب إلى عدم توفر الوظيفة المشار إليها. هل أنت متأكد من رغبتك في المتابعة؟",
  "ccnotification.notifition.sendmessage.priorityUrgent" : "متوسط",
  "ccnotification.notification.label.attachment" : "المرفقات",
  "ccnotification.notification.recmessagerouterconfig.serviceName" : "اسم الخدمة",
  "ccnotification.sms.smpp.stow.away" : "خزن بعيدا",
  "ccnotification.notification.label.rebuildFailed" : "فشل إعادة البناء",
  "ccnotification.gatewayconfig.label.certScene" : "سيناريو التطبيق",
  "ccnotification.notification.emailsignature.name" : "اسم التوقيع",
  "ccn.smsnoconfig.pop.check.selectwarning" : "حدد السجل الذي تريد حذفه.",
  "ccnotification.sms.smppPduTye.data.sm" : "البيانات_sm",
  "ccnotification.notifition.sendmessage.cancelSelectall" : "إلغاء الكل",
  "message.template.message.selectRecipient" : "حدد مستلمًا.",
  "ccnotification.notifition.sendmessage.recipientGroup" : "مجموعة المستلمين",
  "ccnotification.common.warning.message" : "حدد سجلاً.",
  "ccnotification.notifition.sendmessage.receiverPhoneIsNull" : "رقم المستلم أو المستلم فارغ.",
  "ccnotification.gatewayconfig.label.specialStrValidate" : "يُسمح فقط بالأحرف الكبيرة والحروف الصغيرة والأرقام.",
  "ccnotification.notifition.sendmessage.priority" : "الأولوية",
  "ccnotification.notification.label.selectAttachment" : "الرجاء تحديد مرفق بريد إلكتروني.",
  "ccnotification.center.message.deleteAllRead" : "هل تريد بالتأكيد حذف كافة الرسائل الداخلية المقروءة؟",
  "message.template.message.searchMessageTemplateName" : "أدخل اسم قالب.",
  "ccnotification.notifition.variable.variablename" : "اسم المتغير",
  "ccnotification.notification.emailsignature.content" : "محتوى التوقيع",
  "message.template.message.notificationContent" : "محتوى الإشعار",
  "ccnotification.notifition.template.label.des" : "الوصف",
  "ccnotification.notification.special.charactor.check" : "لا يمكن أن يحتوي المحتوى الذي تم إدخاله على < = > / \\ \ eval وexec.",
  "ccnotification.gatewayconfig.label.configNameIsError" : "يجب أن يحتوي اسم التكوين على 10 أرقام أو أحرف كحد أقصى",
  "ccnotification.gatewayconfig.label.serviceType" : "نوع الخدمة",
  "ccnotification.gatewayconfig.label.heartbeatIntervaltitle" : "The Heartbeat Interval هو رقم صحيح",
  "ccnotification.notifition.sendmessage.enterBulletinType" : "حدد نوع نشرة.",
  "ccnotification.notifition.sendmessage.bulletin" : "النشرة",
  "message.template.message.nameLengthError" : "لا يمكن أن يكون الاسم فارغًا ولا يمكن أن يتجاوز 200 حرف أو يحتوي على أحرف خاصة.",
  "ccnotification.notifition.gatewaycontact.stop" : "تم إيقاف الاتصال",
  "ccnotification.notifition.template.message.moneyLabel" : "المبلغ",
  "ccnotification.common.title.warning" : "تحذير",
  "message.center.nocitiction.interal" : "نشرة داخلية",
  "ccnotification.notification.label.deleteAttachmentSuccess" : "نجح حذف المرفق",
  "ccnotification.notification.label.uploadAttachmentFailed" : "فشل إيداع المرفق",
  "ccnotification.gatewayconfig.label.sourceAddrNpititle" : "نظام رمز عنوان المصدر هو رقم",
  "ccnotification.gatewayconfig.label.maxContentLengthtitle" : "الحد الأقصى لطول المحتوى (بايت) هو رقم ، والذي يستخدم كأساس تقسيم مطابق ، على سبيل المثال ، 140.",
  "ccnotification.notifition.receivermessage.unread" : "غير مقروء",
  "ccnotification.notification.label.attachmentExceed10" : "يمكن تحميل 10 مرفقات كحد أقصى.",
  "ccnotification.notification.recmessagerouterconfig.defaultsendemail" : "افتراضي إرسال بريد إلكتروني",
  "message.template.message.contentError" : "لا يمكن أن يكون المحتوى فارغًا.",
  "ccnotification.caseserver.fail.titleAndContentNull" : "عنوان ومحتوى فارغين",
  "ccnotification.notifition.sendmessage.entersender" : "حدد رقم المرسل.",
  "ccnotification.bulletinType.message.Sunday" : "الأحد",
  "ccnotification.gatewayconfig.label.splitType.physical" : "تقسيم المادية",
  "ccnotification.notification.emailsignature.description" : "وصف التوقيع",
  "ccnotification.notification.variable.templateselect" : "حدد قالبًا.",
  "ccnotification.gatewayconfig.label.selectdeletegatewayconfig" : "الرجاء تحديد تكوين البوابة المراد حذفها!",
  "ccnotification.gatewayconfig.label.heartbeatNoRespCounttitle" : "أقصى مرات الاتصال عند عدم استجابة نبضات القلب هو الرقم الصحيح",
  "message.template.message.messageTemplateModify" : "تعديل قالب",
  "message.center.nocitiction.modifyBulletin" : "تعديل نشرة",
  "ccnotification.notification.label.attachmentIsEmpty" : "المرفق فارغ.",
  "ccnotification.smshuawei.message.securityInfo" : "إذا تم إيقاف التحقق من الشهادة، فهناك خطر من انتحال هوية الأقران. يوصى بتمكين التحقق من الشهادة.",
  "ccnotification.gatewayconfig.label.maxMessageLength" : "أقصى طول للرسالة (بايت)",
  "ccnotification.gatewayconfig.label.gatewayname" : "اسم العبّارة",
  "ccnotification.smshuawei.message.deletefail" : "فشل حذف قناة SMS.",
  "ccnotification.gatewayconfig.label.splitMsgConcatModeIsError" : "وضع تركيبة الرسائل القصيرة الطويلة 0: رأس بيانات مستخدم GSM \، 1: معلمة SMPP",
  "message.template.message.createSuccess" : "تم إنشاء قالب الإعلام بنجاح.",
  "ccnotification.notification.label.attachmentSizeError" : "يتجاوز حجم مرفق البريد الإلكتروني 10 ميغابايت",
  "ccnotification.notification.recmessagerouterconfig.intelligentOrder" : "خلق حالة ذكي",
  "message.center.nocitiction.outbox" : "علبة الصادر",
  "ccnotification.gatewayconfig.label.addgateway" : "إنشاء عبّارة",
  "message.center.nocitiction.sending.records.status" : "الحالة",
  "ccnotification.notification.label.rebuildContainsErrorId" : "فشلت إعادة الإنشاء\، الرجاء النقر فوق زر الاستعلام لتحديث القائمة والمحاولة مرة أخرى\.",
  "ccn.smsnoconfig.pop.check.deletesuccess" : "تم الحذف بنجاح.",
  "message.center.nocitiction.callback.receivetime" : "وقت الاستلام",
  "ccnotification.sms.smppPduTye.submit.sm" : "تقديم_sm",
  "ccnotification.bulletinType.message.periodicallySend" : "إرسال دوري",
  "ccnotification.gatewayconfig.label.dataCoding.Binary" : "معلومات ثنائية",
  "ccnotification.notification.label.discounnectedFailed" : "فشل قطع الاتصال",
  "ccnotification.bulletinType.title.Faults" : "الحرجة",
  "message.template.button.edit" : "تحرير",
  "ccnotification.gatewayconfig.label.mailHost" : "المضيف",
  "ccnotification.sms.gms.user.data.head" : "عنوان بيانات مستخدم GSM",
  "ccnotification.bulletinType.message.betchDeleteBulletinTypeFailed" : "فشل حذف أنواع النشرات على دفعات.",
  "ccnotification.notifition.sendmessage.selectuser" : "اختر موظفًا.",
  "ccnotification.notification.label.fileUploadFileSizeError" : "حجم الملف الذي قمت بتحميله يتجاوز الحد",
  "ccnotification.smshuawei.message.paramisempty" : "معلمة فارغة.",
  "ccnotification.notification.variable.templatetype" : "نوع القالب",
  "message.template.button.view" : "عرض",
  "ccnotification.notifition.template.message.dateTime" : "- التاريخ: yyyy-MM-dd - الوقت: HH:mm: ss",
  "ccnotification.gatewayconfig.label.dataCoding" : "تنسيق الرسالة",
  "ccnotification.notifition.sendmessage.sendsuccess" : "تم إرسال الرسالة بنجاح.",
  "ccnotification.bulletinType.message.searchPlaceHolder" : "اسم نوع النشرة",
  "ccnotification.notifition.sendmessage.isSendall" : "تم الإرسال",
  "ccnotification.center.message.setIsRead" : "وضع علامة على الرسائل كمقروءة",
  "ccnotification.notifition.template.message.mspsms" : "بوابة الرسائل القصيرة",
  "ccnotification.smshuawei.label.signatureChannelId" : "معرف قناة التوقيع",
  "ccnotification.notifition.sendmessage.send" : "إرسال",
  "ccnotification.gatewayconfig.label.selectCert" : "تحديد شهادة",
  "ccnotification.gatewayconfig.label.serverSocketPort" : "منفذ لاستقبال رسائل المنبع",
  "ccnotification.gatewayconfig.label.readTimeout" : "الفاصل الزمني لمهلة الاستلام (مللي ثانية)",
  "ccnotification.bulletinType.message.editBulletinTypeSuccessful" : "تم تعديل نوع النشرة بنجاح.",
  "ccnotification.notifition.template.label.value" : "القيمة الافتراضية",
  "ccnotification.gatewayconfig.label.dataCodingIsError" : "يجب أن يكون تنسيق الرسالة النصية 0:سلسلة ASCII\,4:معلومات ثنائية\,8: رمز UCS2",
  "ccnotification.notification.systemmonitor.modifyTime" : "تم التعديل في",
  "ccnotification.notification.recmessagerouterconfig.emailOnlineChat" : "الدردشة عبر الإنترنت من خلال قناة البريد الإلكتروني",
  "ccnotification.notification.recmessagerouterconfig.defaultsendnumber" : "رقم الإرسال الافتراضي.",
  "ccnotification.notification.emailsignature.nameexist" : "اسم التوقيع موجود بالفعل.",
  "ccnotification.notifition.sendmessage.bccRecipient" : "Bcc",
  "ccnotification.notification.label.uploadPermissionFailed" : "فشل التحقق من إذن مرفق البريد الإلكتروني",
  "ccnotification.gatewayconfig.label.interfaceVersiontitle" : "يجب أن يكون رقم إصدار رسالة تسجيل الدخول رقمًا.",
  "ccnotification.gatewayconfig.label.paramIsNotNumber" : "يمكن أن تحتوي قيمة المعلمة على أرقام فقط",
  "ccnotification.gatewayconfig.label.defaultsendnoIsError" : "تنسيق عنوان البريد الإلكتروني الافتراضي غير صحيح",
  "ccnotification.gatewayconfig.label.destAddrNpititle" : "نظام رمز عنوان الوجهة هو رقم",
  "ccnotification.notifition.variable.syntax.error" : "خطأ في بناء الجملة!",
  "ccnotification.gatewayconfig.label.registeredDelivery" : "تقرير تأكيد حالة الإرجاع",
  "ccnotification.notification.label.discounnectedSuccess" : "تم قطع الاتصال بنجاح",
  "ccnotification.gatewayconfig.label.smppPduType" : "نوع SMPP PDU",
  "ccnotification.gatewayconfig.label.receiverPoolMaxCount" : "ماكس تلقي برك الموضوع",
  "ccnotification.notifition.recmessagerouterconfig.index" : "الفهرس",
  "ccnotification.notification.variable.nameexist" : "اسم المتغير موجود.",
  "ccnotification.notifition.template.placeholder.hwtemplateid" : "أدخل معرف قالب HUAWEI CLOUD",
  "message.center.label.refresh" : "تحديث",
  "ccnotification.common.button.ok" : "حسناً",
  "ccnotification.gatewayconfig.label.sourceAddrNpi" : "مخطط رمز عنوان المصدر",
  "ccnotification.notifition.sendmessage.enterRecipientGroup" : "حدد مجموعة مستلمين.",
  "message.center.nocitiction.email" : "البريد الإلكتروني",
  "ccnotification.bulletinType.message.Wednesday" : "الأربعاء",
  "message.template.message.messageTemplateName" : "اسم القالب",
  "ccnotification.sms.smpp.ESME.Connection.Configuration" : "تكوين اتصال ESME",
  "ccnotification.notifition.variable.not.exist" : "غير موجود!",
  "ccnotification.notifition.template.label.templatetype" : "نوع القالب",
  "ccnotification.gatewayconfig.label.readTimeouttitle" : "الفاصل الزمني لمهلة الاستلام هو رقم مناسب\,0: لا تنتهى المهلة أبدًا",
  "ccnotification.notification.emailsignature.placeholder.name" : "الرجاء إدخال اسم توقيع",
  "ccnotification.notifition.sendmessage.sendTimeFailed" : "وقت الإرسال سابق للوقت الحالي.",
  "ccnotification.notification.recmessagerouterconfig.inbox" : "داخل الصندوق",
  "ccnotification.notification.recmessagerouterconfig.retryTimes" : "مرات إعادة المحاولة",
  "ccnotification.gatewayconfig.label.sslencryptionmodeIsError" : "يمكن أن يكون وضع تشفير البريد الإلكتروني NONE\ أو STARTTLS\ أو TLS فقط",
  "ccnotification.gatewayconfig.label.replaceIfPresentFlag" : "علامة استبدال",
  "ccnotification.notifition.sendmessage.selectall" : "تحديد الكل",
  "ccnotification.notification.label.hasUplaodtAttachment" : "المرفقات التي تم تحميلها",
  "ccnotification.notifition.template.label.operation" : "العملية",
  "ccnotification.notifition.sendmessage.smsconfignull" : "تكوين الرسالة النصية فارغ. اختر الإعدادات > تكوين الرسائل القصيرة لتكوين معلومات الرسائل القصيرة.",
  "ccnotification.notification.variable.variablevalue" : "قيمة متغيرة",
  "ccn.smsnoconfig.table.header.countrycode.desc" : "البلد",
  "ccnotification.flowcontrol.setThreshold" : "تعيين عتبة البيانات",
  "ccnotification.gatewayconfig.label.inputgatewayname" : "أدخل اسم بوابة",
  "ccnotification.caseserver.fail.categoryNotExist" : "فئة قضايا غير موجودة",
  "ccnotification.gatewayconfig.label.lastmodifytime" : "آخر تعديل في",
  "ccnotification.gatewayconfig.label.checkConfigName" : "القيمة عبارة عن سلسلة مؤلفة من أقل من 10 أرقام وحروف",
  "ccnotification.common.operation.noMoreThan7Days" : "لا يمكن أن يتجاوز النطاق الزمني 7 أيام.",
  "ccnotification.gatewayconfig.label.paramContainsSpecialChar" : "تحتوي المعلمة على أحرف خاصة",
  "ccnotification.notifition.sendmessage.delaynotice" : "سيتم إرسال هذا الإشعار في",
  "ccnotification.gatewayconfig.label.smDefaultMsgId" : "معرف الرسالة الافتراضي",
  "ccnotification.bulletinType.message.deleteBulletinTypeSuccessful" : "تم حذف نوع النشرة بنجاح.",
  "ccnotification.gatewayconfig.label.requestParamIsNull" : "معلمة الطلب فارغة",
  "message.template.message.frequently" : "يتجاوز عدد الرسائل المرسلة الحد اليومي.",
  "ccnotification.smshuawei.label.certTip.withProxy" : "يرجى تحميل شهادة الخادم الوكيل",
  "message.template.message.operate" : "العملية",
  "ccnotification.notification.recmessagerouterconfig.selectconfig" : "يرجى تحديد تكوين.",
  "message.template.message.noneTemplate" : "القالب غير موجود.",
  "ccnotification.notifition.receivermessage.receiverSuccess" : "تم الاستلام",
  "ccnotification.notification.fileUploadRiskWarning" : "يحتوي الملف الذي قمت بتحميله على مخاطر أمنية\، يرجى تأكيد ما إذا كنت تريد متابعة التحميل؟",
  "ccnotification.notifition.variable.close.syntax.error2" : "تحقق مما إذا كان ${} مغلقًا.",
  "ccnotification.notifition.variable.close.syntax.error1" : "يحتوي محتوى القالب على أخطاء في بناء الجملة بالقرب من",
  "ccnotification.bulletinType.message.createBulletinTypeSuccessful" : "تم إنشاء نوع النشرة بنجاح.",
  "ccnotification.notification.emailsignature.content.length.exceed" : "لا يمكن أن يتجاوز طول محتوى التوقيع 4096.",
  "ccnotification.notification.emailsignature.willdeleteselectitem" : "هل تريد بالتأكيد حذف البيانات المحددة؟",
  "ccnotification.gatewayconfig.label.resendNumbertitle" : "أوقات إعادة الإرسال هي رقم صحيح",
  "ccnotification.gatewayconfig.label.deletegatewayfailed" : "فشل حذف Gatewayconfig!",
  "ccnotification.notification.recmessagerouterconfig.gateway" : "بوابة",
  "message.center.label.allSelect" : "تحديد الكل",
  "ccnotification.gatewayconfig.label.mailHosttitle" : "عنوان المضيف\، على سبيل المثال\، 127.0.0.1 أو www.mail.com",
  "ccnotification.gatewayconfig.label.messageFlowControlMaxCapsForRecv" : "الحد الأقصى لمعدل الاستلام",
  "ccnotification.center.message.var.value" : "قيمة متغيرة:",
  "ccnotification.notification.recmessagerouterconfig.gatewaynotexist" : "البوابة المحددة غير موجودة.",
  "ccnotification.notifition.sendmessage.isSendallFail" : "أرسلت (فشل)",
  "message.center.label.bulletinType" : "نوع النشرة",
  "message.template.message.typeError" : "نوع الإعلام غير صحيح.",
  "ccnotification.notifition.template.message.textLabel" : "نص",
  "ccnotification.common.spec.message" : "لا يمكن إدخال أحرف خاصة.",
  "ccnotification.notification.recmessagerouterconfig.selectgateway" : "تحديد العبّارة",
  "ccnotification.gatewayconfig.label.smPort" : "منفذ SMG",
  "ccnotification.gatewayconfig.label.messageFlowControlMaxCapsForRecvtitle" : "الحد الأقصى لمعدل الاستلام هو رقم",
  "ccnotification.common.button.finish" : "مكتمل",
  "ccnotification.gatewayconfig.label.receiverPoolQueueCapacitytitle" : "طول قائمة انتظار بركة موضوع الاستلام هو رقم\، على سبيل المثال\، 20.",
  "message.template.message.recipientLengthError" : "تجاوز عدد المستلمين الحد.",
  "ccnotification.gatewayconfig.label.splitTypeIsError" : "يمكن فقط تعيين وضع تقسيم الرسائل النصية الطويلة إلى 0:معطل\,1:غير مقسم\,2:تقسيم مادي\,3:انقسام طويل جدًا",
  "ccnotification.notifition.template.button.up" : "لأعلى",
  "ccnotification.notifition.sendmessage.sendtime" : "وقت الإرسال",
  "ccnotification.bulletinType.message.willDelete" : "هل تريد بالتأكيد حذف نوع النشرة المحدد؟",
  "ccnotification.notifition.sendmessage.preview" : "معاينة",
  "message.center.nocitiction.sender" : "المرسل",
  "ccnotification.gatewayconfig.label.systemType" : "نوع ESME",
  "ccnotification.bulletinType.message.editBulletinTypeFailed" : "فشل تعديل نوع النشرة.",
  "message.center.label.newMessage" : "إرسال إشعار",
  "message.template.type.internal" : "الإخطار الداخلي",
  "ccnotification.notification.label.incorrectAttachmentType" : "نوع مرفق بريد إلكتروني غير صحيح",
  "message.center.nocitiction.sending.records" : "إرسال السجل",
  "ccnotification.bulletinType.title.bulletinTypeDesc" : "الوصف",
  "ccnotification.flowcontrol.shortMsgCode" : "رقم رسالة SMS",
  "ccnotification.notification.recmessagerouterconfig.willdeleteselectconfig" : "هل تريد بالتأكيد حذف التكوين المحدد؟",
  "ccnotification.notifition.sendmessage.selectbyworkno" : "معرف الموظف",
  "ccnotification.notifition.sendmessage.unSend" : "ليتم إرسالها",
  "ccnotification.notifition.sendmessage.entercompanyName" : "أدخل اسم شركة.",
  "ccnotification.notification.variable.publicvariable" : "المتغير العام",
  "message.center.nocitiction.modifyInternalMessage" : "تعديل الرسالة الداخلية",
  "message.template.mediaType.internal" : "رسالة داخلية",
  "ccnotification.common.message.emptyContent" : "لا يمكن أن يكون المحتوى فارغًا.",
  "ccnotification.gatewayconfig.label.modifygateway" : "تعديل العبّارة",
  "ccnotification.notifition.template.label.mspcontent" : "محتوى قالب SMS",
  "ccnotification.notifition.template.button.addvar" : "إنشاء",
  "ccnotification.notifition.sendmessage.paramvalidatefailed" : "فشل التحقق من المعلمة.",
  "ccnotification.gatewayconfig.label.certCode" : "رمز الشهادة",
  "message.template.message.save" : "حفظ",
  "ccnotification.gatewayconfig.label.defaultsendno" : "رقم الإرسال الافتراضي",
  "ccnotification.gatewayconfig.label.smHosttitle" : "عنوان SMG\، على سبيل المثال\، 127.0.0.1",
  "message.template.message.agentRecipientError" : "بعض المستلمين غير مرتبطين بموظفين. حدد مستلم آخر",
  "ccnotification.notification.variable.editfailed" : "فشل التعديل.",
  "message.template.message.createTemplateFail" : "فشل إنشاء قالب الإعلام.",
  "ccnotification.notifition.sendmessage.enterBCCRecipient" : "حدد مستلم Bcc.",
  "ccnotification.notifition.template.message.hwsms" : "رسائل HUAWEI CLOUD SMS",
  "ccnotification.gatewayconfig.label.selectrebuildgatewayconfig" : "حدد تكوين البوابة المراد إعادة بنائها",
  "message.template.mediaType.email" : "البريد الإلكتروني",
  "ccnotification.notification.label.upload" : "تحميل",
  "message.template.message.inputRecipient" : "إدخال مستلم.",
  "ccnotification.gatewayconfig.label.destAddrNpi" : "مخطط رمز عنوان الوجهة",
  "ccnotification.gatewayconfig.label.addgatewayconfig" : "إضافة تكوين العبّارة",
  "message.template.message.inputTemplateName" : "أدخل اسم قالب.",
  "message.template.message.modifySuccess" : "تم تعديل قالب الإعلام بنجاح.",
  "ccnotification.notification.systemmonitor.disconnected" : "تم قطع الاتصال",
  "ccnotification.gatewayconfig.label.addressRange" : "نطاق عنوان ESME",
  "ccnotification.gatewayconfig.label.RcvAndSendMode" : "وضع TX/RX",
  "ccnotification.flowcontrol.getwayset" : "تعيين",
  "ccnotification.gatewayconfig.label.userid" : "معرف المستخدم",
  "ccnotification.notifition.template.label.signatureChannelId" : "أدخل معرّف قناة التوقيع",
  "message.center.nocitiction.sending.records.unsend" : "إرسال مجدول",
  "ccnotification.notification.label.nameContainSpecialChar" : "يحتوي اسم مرفق البريد الإلكتروني على أحرف خاصة",
  "ccnotification.notification.recmessagerouterconfig.mobilerule" : "يُرجى إدخال أرقام الجوال. فصل أرقام هواتف محمولة متعددة باستخدام الفواصل المنقوطة",
  "ccnotification.gatewayconfig.label.configType" : "نوع التكوين",
  "message.center.label.modify" : "تعديل",
  "ccnotification.gatewayconfig.label.messageFlowControlMessageDropPolicy" : "سياسة معالجة حركة المرور الإضافية",
  "ccnotification.notification.recmessagerouterconfig.placeholder.receiver" : "يرجى إدخال عنوان المستلم",
  "ccnotification.gatewayconfig.label.auth" : "علم المصادقة",
  "ccnotification.gatewayconfig.label.hostIsError" : "يمكن أن يكون مضيف البريد الإلكتروني 127.0.0.1 فقط أو تنسيق www.mail.com",
  "ccnotification.common.title.success" : "ناجح",
  "message.template.message.cancel" : "إلغاء",
  "ccnotification.gatewayconfig.label.esmClass" : "نوع الرسالة",
  "ccnotification.urlconfig.pageurls.urlvalidate" : "يجب أن تبدأ عناوين URL بـ https://",
  "ccnotification.notifition.template.placeholder.var" : "أدخل القيم التي تفي بالمتطلبات.",
  "ccnotification.gatewayconfig.label.systemId" : "اسم تسجيل الدخول",
  "ccnotification.notifition.sendmessage.selectbyskill" : "قائمة انتظار المهارات",
  "ccnotification.gatewayconfig.label.paramIsNotInRange" : "نطاق قيمة المعلمة غير صحيح",
  "ccnotification.notifition.template.label.signatureName" : "اسم التوقيع",
  "ccnotification.notification.recmessagerouterconfig.satisfaction" : "استبيان الرضا",
  "ccnotification.gatewayconfig.message.authInfo" : "وضع كاذب ينطوي على مخاطر أمنية. ننصحك بتعيين true لتمكين مصادقة كلمة المرور.",
  "message.template.message.ccOrBccRecipientError" : "تم إرسال الرسالة بنجاح. مستلم نسخة Cc أو Bcc غير موجود.",
  "ccnotification.gatewayconfig.label.advancedconfig" : "إعدادات متقدمة",
  "ccnotification.gatewayconfig.label.maxRcvErrortitle" : "الحد الأقصى للحزم المستلمة للخطأ هو رقم صحيح",
  "ccnotification.gatewayconfig.label.heartbeatInterval" : "الفاصل الزمني نبضات القلب (مللي ثانية)",
  "message.center.nocitiction.system" : "نشرة النظام",
  "ccnotification.gatewayconfig.label.resendWaitTime" : "إعادة إرسال وقت الانتظار (مللي ثانية)",
  "message.center.label.isRead" : "وضع علامة على الرسائل كمقروءة",
  "ccnotification.notification.variable.templatevariable" : "متغير القالب",
  "ccnotification.title.flowcontrol.receiveSmsFlag" : "تلقي رسائل MO SMS",
  "ccnotification.notifition.sendmessage.sendtimebeforenow" : "يجب أن يكون وقت الإرسال المجدول بعد 5 دقائق من الوقت الحالي.",
  "ccnotification.bulletinType.message.Thursday" : "الخميس",
  "ccnotification.gatewayconfig.label.receiverPoolMinCount" : "مين تلقي برك الموضوع",
  "ccnotification.flowcontrol.title" : "تعيين تكوين الإعلام",
  "ccnotification.bulletinType.title.createBulletinType" : "إنشاء نوع نشرة",
  "ccnotification.gatewayconfig.label.splitMsgConcatMode" : "وضع تسلسل الرسائل الطويلة",
  "ccnotification.notifition.sendmessage.priorityFault" : "الحرجة",
  "ccnotification.gatewayconfig.label.protocolId" : "معرف البروتوكول",
  "ccnotification.notifition.variable.been.used" : "تمت الإشارة إليه بواسطة قالب ولا يمكن حذفه.",
  "ccnotification.bulletinType.message.Monday" : "الاثنين",
  "ccnotification.chatserver.fail.assign" : "فشل تخصيص الموظف",
  "message.template.message.basicInformation" : "معلومات أساسية",
  "ccnotification.notifition.template.label.hwtemplateid" : "معرف قالب HUAWEI CLOUD",
  "ccnotification.flowcontrol.emailThreshold" : "عتبة البيانات اليومية لرسائل البريد الإلكتروني",
  "ccn.smsnoconfig.pop.title.createtitle" : "إضافة رقم SMS",
  "message.template.message.phoneError" : "رقم الهاتف غير موجود.",
  "ccnotification.caseserver.fail.serviceException" : "خدمة غير طبيعية",
  "ccnotification.gatewayconfig.label.bindModetitle" : "يشير إلى وضع TX/RX. 1: وضع RX ؛ 2: وضع TX ؛ 9: وضع TX / RX.",
  "ccnotification.gatewayconfig.label.INIT" : "التهيئة",
  "ccnotification.gatewayconfig.label.sourceAddrTontitle" : "نوع رمز عنوان المصدر هو رقم",
  "ccnotification.notifition.variable.searchVariableName" : "أدخل اسم متغير.",
  "ccnotification.gatewayconfig.label.sourceAddrTon" : "نوع رمز عنوان المصدر",
  "ccnotification.smshuawei.label.appAdress" : "عنوان الوصول إلى التطبيق",
  "ccnotification.smshuawei.message.invalidappadress" : "عنوان الوصول إلى التطبيق غير صالح.",
  "ccnotification.notification.label.downPermissionFailed" : "فشل التحقق من تنزيل إذن مرفق البريد الإلكتروني",
  "ccnotification.flowcontrol.setShortMsgCode" : "قم بتعيين الرقم للرسائل النصية.",
  "ccnotification.notification.label.downloadAttachment" : "تنزيل المرفق",
  "ccnotification.smstemplate.message.signatureName" : "لا يمكن أن يكون اسم التوقيع فارغًا أو طويلًا جدًا أو لا يتوافق مع المواصفات.",
  "ccn.smsnoconfig.pop.check.duplicatecode" : "الرقم موجود بالفعل.",
  "message.center.nocitiction.inbox" : "علبة الوارد",
  "ccnotification.notification.label.isDownloadAttachment": "إذا كان المرفق الذي تقوم بتحميله بتنسيق مستند Office، يرجى اتخاذ التدابير الأمنية اللازمة.",
  "ccnotification.gatewayconfig.label.bindMode" : "وضع الاستلام والإرسال",
  "ccnotification.gatewayconfig.label.senderPoolQueueCapacity" : "إرسال طول قائمة الانتظار بركة الموضوع",
  "ccnotification.notification.recmessagerouterconfig.defaultreceivenumber" : "رقم الاستلام الافتراضي.",
  "ccnotification.notifition.sendmessage.sysmessage" : "إعلام النظام",
  "ccnotification.gatewayconfig.label.smppPduTypeIsError" : "لا يمكن أن يكون نوع SMS SMPP PDU إلا 1:submit_sm \، 2:data_sm",
  "ccnotification.notification.variable.template" : "قالب",
  "ccnotification.gatewayconfig.label.basic.configuration" : "التكوين الأساسي",
  "ccnotification.gatewayconfig.label.recFlowController" : "اسم وحدة تحكم حركة المرور للارتباط الصاعد",
  "message.center.label.reply" : "الرد",
  "ccnotification.gatewayconfig.label.dataCodingtitle" : "0: سلسلة ASCII 4: المعلومات الثنائية 8: ترميز UCS2",
  "ccnotification.gatewayconfig.label.maxRcvError" : "الحد الأقصى للحزم المستلمة للخطأ",
  "ccnotification.gatewayconfig.label.statusisnoterror" : "الحالة ليست خطأ.",
  "ccnotification.notification.recmessagerouterconfig.serviceType" : "طريقة التنفيذ",
  "ccnotification.notifition.sendmessage.referenceTemplate" : "قالب مرجعي",
  "ccnotification.gatewayconfig.label.recFlowControllertitle" : "اسم وحدة التحكم في حركة المرور التي تتحكم في معدل رسائل الإرسال.",
  "ccnotification.notification.label.serverIsNotobs" : "يمكن تنزيل المرفقات فقط من خادم ملفات OBS",
  "ccnotification.smshuawei.label.config.explain" : "يجب أن تكون بيانات التكوين مماثلة لبيانات رسائل HUAWEI CLOUD SMS.",
  "message.center.nocitiction.callback.record" : "سجل رد الاتصال",
  "message.center.label.senderName" : "المرسل",
  "ccnotification.notifition.sendmessage.templateselected" : "وقد أشار هذا الإشعار إلى",
  "ccnotification.gatewayconfig.label.defaultreceinfo" : "معرف الاستلام الافتراضي",
  "ccnotification.gatewayconfig.label.registeredDeliverytitle" : "تقرير تأكيد حالة الإرجاع هو رقم",
  "ccnotification.agent.contact.downloadFail" : "فشل التنزيل",
  "ccnotification.notifition.template.placeholder.varnumber" : "أدخل عدد المتغيرات",
  "ccnotification.notification.uploadfail.duplicateFileName" : "اسم ملف مكرر.",
  "ccnotification.notification.label.uploadAttachmentTips" : "يمكن أن تكون مرفقات البريد الإلكتروني بالتنسيق التالي: doc\, docx\, xls\, xlsx\, txt\, png\, jpg\, xml\, json\, csv\, bmp\, zip\, ppt\, pptx\, pdf\, لا يمكن أن يتجاوز حجم الملف 10 ميغابايت.",
  "ccnotification.notifition.sendmessage.bulletinType" : "نوع النشرة",
  "ccnotification.smstemplate.message.var" : "لا يفي النوع أو الرقم أو الوصف أو القيمة الافتراضية للمتغيرات بالمتطلبات.",
  "ccnotification.gatewayconfig.label.messageFlowControlMessageDropPolicytitle" : "حالياً\، يتم دعم \"تجاهل\" فقط.",
  "ccnotification.gatewayconfig.label.smHost" : "عنوان SMG",
  "ccnotification.notification.label.uploadAttachmentSuccess" : "تم تحميل المرفق بنجاح",
  "ccnotification.bulletinType.message.selectItem" : "حدد نوع نشرة.",
  "message.center.message.selectMessages" : "حدد رسالة.",
  "ccnotification.flowcontrol.modifySuccess" : "تم تعديل تكوين الإعلام بنجاح.",
  "ccnotification.notifition.sendmessage.enterusername" : "أدخل اسمًا.",
  "ccnotification.common.button.new" : "جديد",
  "ccnotification.bulletinType.message.betchDeleteBulletinTypeSuccessful" : "تم حذف أنواع النشرات على دفعات بنجاح.",
  "ccnotification.bulletinType.message.sendingFrequency" : "إرسال التردد",
  "ccnotification.smshuawei.message.updatefail" : "فشل تعديل قناة SMS.",
  "ccnotification.notifition.template.message.charDigitLabel" : "الرقم والحرف",
  "ccnotification.gatewayconfig.label.SSLEncryptionModetitle" : "وضع التشفير\، لا شيء: نقل النص العادي (المنفذ الافتراضي: 25)TLS: نقل مشفر باستخدام TLS (المنفذ الافتراضي: 465) STARTTLS: نقل مشفر باستخدام الأمر STARTTLS (def منفذ الاتصال: 587)",
  "ccnotification.template.message.organizationStructure" : "الهيكل التنظيمي",
  "ccnotification.common.message.sendIntervalShort" : "إرسال متكرر. الفاصل الزمني (ث): {n}.",
  "ccnotification.common.message.maxExpireTime" : "حدد وقت انتهاء الصلاحية في موعد لا يتجاوز {0}.",
  "ccnotification.notifition.template.label.index" : "لا ..",
  "ccnotification.gatewayconfig.label.slipWindowSize" : "انزلاق حجم النافذة",
  "ccnotification.smstemplate.message.smstemplateid" : "لا يمكن أن يكون معرف قالب HUAWEI CLOUD فارغًا أو طويلًا جدًا أو لا يتوافق مع المواصفات.",
  "message.template.message.contentLengthError" : "لا يمكن أن يكون المحتوى فارغًا ولا يمكن أن يتجاوز الطول.",
  "ccnotification.gatewayconfig.label.defaultemailaddr" : "عنوان البريد الإلكتروني الافتراضي",
  "ccnotification.notifition.sendmessage.limitdate" : "حدد تاريخًا أقدم من 2038-1-19.",
  "ccnotification.notification.recmessagerouterconfig.defaultreceiveemail" : "تلقي البريد الإلكتروني الافتراضي",
  "message.template.message.deleteTemplate" : "حذف قالب إعلام",
  "message.template.message.deleteSuccess" : "تم حذف قالب الإعلام بنجاح.",
  "ccnotification.gatewayconfig.label.splitType.disabled" : "معطل",
  "ccnotification.gatewayconfig.label.smPassword" : "تسجيل الدخول كلمة المرور",
  "message.template.message.descriptionLengthError" : "يتجاوز الوصف الحد.",
  "ccnotification.flowcontrol.countryCode" : "رمز البلد",
  "ccnotification.notification.recmessagerouterconfig.updatenum.outstripping" : "يمكن تحديث 100 سجل كحد أقصى في المرة الواحدة.",
  "ccnotification.notifition.template.placeholder.des" : "وصف المتغير",
  "ccnotification.notification.label.fileUploadFileNameError" : "اسم الملف الذي قمت بتحميله لا يفي بالمتطلبات",
  "ccnotification.gatewayconfig.label.maxMessageLengthtitle" : "الحد الأقصى لطول الرسالة (البايت) هو رقم\، وهو محدد في البروتوكول\، على سبيل المثال\، 2048.",
  "ccnotification.notifition.sendmessage.templateselect" : "تحديد قالب",
  "ccnotification.notifition.variable.variableType" : "حدد نوع متغير.",
  "ccnotification.gatewayconfig.label.transactionTimeout" : "الفاصل الزمني لمهلة المعاملة (مللي ثانية)",
  "message.center.nocitiction.callback.attachment" : "المرفقات",
  "message.center.nocitiction.callback.status" : "حالة رد الاتصال",
  "ccnotification.notifition.variable.variable" : "متغير",
  "ccnotification.gatewayconfig.label.inputCertCode" : "أدخل رمز الشهادة.",
  "ccnotification.notifition.sendmessage.isSendallSuccess" : "المرسلة(نجاح)",
  "ccnotification.gatewayconfig.label.deletegatewaysuccess" : "تم حذف Gatewayconfig بنجاح!",
  "ccnotification.gatewayconfig.label.willDeletegateway" : "هل تريد بالتأكيد حذف تكوين العبّارة المحدد؟",
  "ccnotification.notifition.sendmessage.recipient" : "المتلقي",
  "ccnotification.notifition.template.message.charDigit" : "- القيمة عبارة عن سلسلة من 1 إلى 20 رقمًا وحروفًا.",
  "ccnotification.notifition.sendmessage.maxselectcount" : "يمكن اختيار 100 موظف كحد أقصى.",
  "ccnotification.bulletinType.message.Saturday" : "السبت",
  "ccnotification.notification.label.downloadFromObsFailed" : "فشل تنزيل الملفات من OBS",
  "ccnotification.notifition.variable.namerule" : "يمكن أن يحتوي اسم المتغير على أحرف وأرقام وشرطات سفلية (_) ونقاط (.) فقط",
  "ccnotification.notifition.template.label.type" : "النوع",
  "ccnotification.notification.recmessagerouterconfig.receiveaddressexist" : "عنوان التلقي موجود بالفعل.",
  "message.center.nocitiction.sending.records.smsType" : "وضع رسالة SMS",
  "ccnotification.gatewayconfig.label.splitMsgConcatModetitle" : "وضع تركيبة الرسائل القصيرة الطويلة. 0: رأس بيانات مستخدم GSM ؛ 1: معلمة SMPP.",
  "ccnotification.gatewayconfig.label.splitType.ultraLong" : "تقسيم طويل للغاية",
  "message.center.label.receiverGroup" : "مجموعة المستلمين",
  "ccnotification.gatewayconfig.label.senderPoolMaxCounttitle" : "الحد الأقصى لمجموعات مؤشرات الترابط المرسلة هو رقم\، على سبيل المثال\، 200.",
  "ccnotification.flowcontrol.messageThreshold" : "عتبة البيانات اليومية لرسائل SMS",
  "message.center.nocitiction.ineermessage" : "رسالة داخلية",
  "ccnotification.gatewayconfig.label.destAddrTon" : "نوع رمز عنوان الوجهة",
  "ccnotification.notification.emailsignature.noitemselect" : "لا يحدد أي بيانات.",
  "message.center.label.select" : "فلتر",
  "ccnotification.gatewayconfig.label.senderPoolMinCounttitle" : "الحد الأدنى لمجموعات موضوع الإرسال هو رقم\، على سبيل المثال\، 20.",
  "message.template.mediaType.shortmessage" : "SMS",
  "ccnotification.notifition.sendmessage.selecttenant" : "اختر مستأجرًا.",
  "ccnotification.notification.label.queryAttachmentFailed" : "فشل الاستعلام عن المرفق",
  "ccnotification.notifition.sendmessage.internalmessage" : "الإخطار الداخلي",
  "ccnotification.notifition.gatewaycontact.connecting" : "جارٍ الاتصال",
  "ccnotification.gatewayconfig.label.splitTypetitle" : "وضع تقسيم رسالة SMS طويلة. الخيارات هي كما يلي: 0: لا تقسيم ؛ 1: لا تقسيم ؛ 2: تقسيم جسديًا ؛ 3: تقسيم رسالة SMS طويلة.",
  "ccnotification.sms.smpp.replaceIfPresentFlag.not.replace" : "لا تحل محل",
  "ccnotification.gatewayconfig.label.resendNumber" : "أوقات إعادة الإرسال",
  "ccnotification.gatewayconfig.label.maxContentLength" : "أقصى طول للمحتوى (بايت)",
  "ccnotification.gatewayconfig.label.heartbeatNoRespCount" : "أقصى أوقات الاتصال عند عدم استجابة نبضات القلب",
  "ccnotification.bulletinType.title.General" : "منخفض",
  "ccn.smsnoconfig.pop.check.deletefailed" : "فشل الحذف.",
  "message.template.message.generate" : "إنشاء قالب",
  "ccnotification.gatewayconfig.label.mailPorttitle" : "يتراوح رقم المنفذ من 0 إلى 65535. رقم منفذ SMTP الافتراضي هو 25.",
  "ccnotification.common.message.maxLength" : "يمكن أن يحتوي محتوى النص على 4096 حرفًا كحد أقصى.",
  "ccnotification.notifition.template.button.down" : "إلى الأسفل",
  "ccnotification.notifition.sendmessage.expireTime" : "انتهت صلاحيتها في",
  "ccnotification.notification.label.isDeleteAttachment" : "حذف مرفق",
  "ccnotification.notification.recmessagerouterconfig.mediaType" : "نوع الوسائط",
  "ccnotification.gatewayconfig.label.code" : "وضع الترميز",
  "message.center.nocitiction.bulletin" : "النشرة",
  "message.template.message.messageTemplateCreate" : "إنشاء قالب إعلام",
  "ccnotification.notifition.sendmessage.sendexception" : "خطأ في الإرسال.",
  "ccnotification.center.message.var.des" : "وصف متغير:",
  "ccnotification.gatewayconfig.label.transactionTimeouttitle" : "الفاصل الزمني لمهلة المعاملة هو رقم صحيح",
  "ccnotification.gatewayconfig.label.messageFlowControlMaxCapsForSend" : "أقصى معدل إرسال",
  "ccnotification.smshuawei.label.signatureChannelId.length" : "طول SignatureChannelId يتجاوز نطاق القيمة.",
  "ccnotification.smshuawei.label.appSecret" : "التطبيق السري",
  "ccnotification.smstemplate.message.var.number" : "يختلف رقم متغير الإدخال عن عدد المتغيرات المضافة.",
  "ccnotification.gatewayconfig.label.gatewayipaddrconfig" : "تكوين الاتصال البيني لعنوان العبّارة",
  "ccnotification.notification.systemmonitor.rebuilding" : "إعادة البناء",
  "ccnotification.notifition.variable.selectvariable" : "تحديد متغير",
  "ccnotification.bulletinType.message.sendTimeParamsError2" : "لا يمكن أن يكون وقت الإصدار بعد وقت انتهاء الصلاحية",
  "ccnotification.bulletinType.message.sendTimeParamsError1" : "لا يمكن أن يكون وقت النشر قبل الوقت الحالي",
  "ccnotification.notifition.sendmessage.invalidSect" : "مقطع وقت انتهاء الصلاحية",
  "ccnotification.gatewayconfig.label.modifygatewayfailed" : "فشل تعديل تكوين العبّارة!",
  "ccnotification.gatewayconfig.label.bindModeIsError" : "وضع إرسال واستقبال الرسائل القصيرة 1: وضع الاستلام\،2: وضع الإرسال\،9: وضع TX/RX",
  "ccnotification.gatewayconfig.label.authIsError" : "يمكن أن يكون معرف المصادقة صحيحًا أو خاطئًا فقط",
  "ccnotification.gatewayconfig.label.configNameAlreadyExists" : "اسم التكوين موجود بالفعل",
  "message.template.message.recipient" : "المتلقي",
  "ccnotification.notifition.sendmessage.enterCCRecipient" : "حدد مستلم نسخة.",
  "message.center.button.query" : "استعلام",
  "ccnotification.notifition.sendmessage.inputBulletinType" : "أدخل اسم نوع النشرة.",
  "ccnotification.gatewayconfig.label.errorstylenumber" : "تنسيق رقم غير صحيح.",
  "ccn.smsnoconfig.table.label" : "رقم SMS",
  "ccnotification.bulletinType.message.nameIsNotUnique" : "اسم نوع النشرة موجود بالفعل.",
  "ccnotification.notifition.template.message.money" : "- يمكن أن تحتوي القيمة على 1 إلى 20 رقمًا. يمكن فقط نقل الأرقام والنقاط العشرية التي يمكن أن تعبر عن المبلغ بشكل صحيح.",
  "ccnotification.notifition.sendmessage.senddate" : "تاريخ الإرسال",
  "ccnotification.agent.contact.workno" : "معرف الموظف",
  "message.center.button.clear" : "إعادة الضبط",
  "ccnotification.bulletinType.message.decimealsCheck" : "لا يمكن أن يحتوي وقت انتهاء الصلاحية الافتراضي على رقم عشري.",
  "ccnotification.gatewayconfig.label.messageFlowControlMaxCapsForSendtitle" : "الحد الأقصى لمعدل الإرسال هو رقم",
  "message.template.message.titleLengthError" : "لا يمكن أن يتجاوز العنوان 200 حرف أو يحتوي على أحرف خاصة.",
  "ccnotification.bulletinType.title.Rushse" : "عالية",
  "ccnotification.gatewayconfig.label.paramIsNegative" : "لا يمكن أن يكون الرقم سالبًا",
  "ccnotification.notifition.sendmessage.sendfailed" : "فشل الإرسال.",
  "ccnotification.gatewayconfig.label.splitType.noSplit" : "غير مقسم",
  "ccn.smsnoconfig.table.header.countrycode" : "رمز البلد المرسل",
  "ccnotification.notifition.template.title.setting" : "تعيين متغير",
  "ccnotification.smshuawei.message.deletesuccess" : "تم حذف قناة SMS بنجاح.",
  "ccnotification.gatewayconfig.label.smPorttitle" : "نطاق رقم منفذ SMG: عدد صحيح يتراوح من 0 إلى 65535",
  "ccnotification.gatewayconfig.label.certFile" : "ملف الشهادة",
  "ccnotification.notifition.gatewaycontact.failed" : "خطأ في الاتصال",
  "ccnotification.gatewayconfig.label.resendWaitTimetitle" : "وقت انتظار إعادة الإرسال هو رقم صحيح",
  "ccnotification.gatewayconfig.label.destAddrTontitle" : "نوع رمز عنوان الوجهة هو رقم",
  "ccnotification.gatewayconfig.label.maxsmGatewayIsError" : "يمكن تكوين 10 بوابات للرسائل النصية كحد أقصى في نفس مساحة المستأجر",
  "ccnotification.notifition.sendmessage.sender" : "رقم المرسل",
  "ccnotification.sms.smpp.parameters" : "معلمات SMPP",
  "message.template.message.deleteTemplateFail" : "فشل حذف قالب الإعلام.",
  "ccnotification.notifition.sendmessage.messagetitle" : "العنوان",
  "ccnotification.notifition.template.message.text" : "- القيمة عبارة عن سلسلة من 1 إلى 20 حرفًا ولا يمكن أن تحتوي على 000",
  "ccnotification.smstemplate.message.receiverPhone" : "لا يمكن تكرار المستلمين.",
  "ccnotification.smshuawei.label.certVerifyEnabled" : "هل سيتم تمكين التحقق من الشهادة",
  "ccnotification.gatewayconfig.label.addrNpi" : "وضع ترميز عنوان ESME",
  "ccnotification.smshuawei.message.fail" : "فشل",
  "message.center.label.download" : "تحميل",
  "ccnotification.notifition.sendmessage.ccRecipient" : "Cc",
  "message.center.label.expireTime" : "انتهت صلاحيتها في",
  "ccnotification.notification.recmessagerouterconfig.receivernotexist" : "المستلم المحدد غير موجود.",
  "ccnotification.notification.systemmonitor.gatewayName" : "اسم البوابة",
  "message.center.nocitiction.message" : "SMS",
  "ccnotification.notification.label.createTemFileFailed" : "فشل إنشاء الملف المؤقت",
  "ccnotification.bulletinType.message.weekly" : "أسبوعياً",
  "ccnotification.gatewayconfig.label.protocol" : "نوع البروتوكول",
  "ccnotification.template.message.alignment" : "لا يمكن أن يتجاوز عدد خيارات البطاقة",
  "ccnotification.email.signature.message.uploadImgTypeError" : "يمكن تحميل صور JPG JPEG و PNG فقط.",
  "ccnotification.email.signature.knowledge.message.uploadImgSizeError" : "لا يمكن أن يتجاوز حجم الصورة المراد تحميلها {}.",
  "ccnotification.email.signature.knowledge.message.uploadError" : "يحتوي توقيع البريد الإلكتروني على صور ذات حجم أو تنسيق غير صحيح. احذفها وحاول مرة أخرى.",
  "ccn.flow.grayrule.editsuccess" : "\u062A\u0645 \u0627\u0644\u062A\u0639\u062F\u064A\u0644 \u0628\u0646\u062C\u0627\u062D.",
  "ccn.voiceedit.addsuccess" : "\u062A\u0645\u062A \u0627\u0644\u0625\u0636\u0627\u0641\u0629 \u0628\u0646\u062C\u0627\u062D.",
  "ccn.voiceedit.addfail" : "\u0641\u0634\u0644\u062A \u0627\u0644\u0625\u0636\u0627\u0641\u0629.",
  "ccnotification.notification.emailsignature.selection.userEmail" : "\u0639\u0646\u0648\u0627\u0646 \u0627\u0644\u0628\u0631\u064A\u062F \u0627\u0644\u0625\u0644\u0643\u062A\u0631\u0648\u0646\u064A \u0644\u0644\u0645\u0633\u062A\u062E\u062F\u0645",
  "ccnotification.notification.emailsignature.selection.userPosition" : "\u0645\u0648\u0642\u0639 \u0627\u0644\u0645\u0633\u062A\u062E\u062F\u0645",
  "ccn.voiceedit.deletesuccess" : "\u062A\u0645 \u0627\u0644\u062D\u0630\u0641 \u0628\u0646\u062C\u0627\u062D.",
  "ccnotification.notifition.template.create" : "إنشاء قالب إعلام",
  "ccnotification.template.message.channelType" : "نوع القناة",
  "pop.org.window.org" : "الصفحة الرئيسية التنظيم",
  "ccnotification.template.message.add.variable": "إضافة",
  "ccnotification.template.message.content": "محتوى القالب",
  "ccnotification.template.message.enterDescription": "الرجاء إدخال وصف.",
  "ccnotification.notification.systemmonitor.batchrebuild":"إعادة بناء دفعة",
  "ccnotification.notification.systemmonitor.batchdisconnect":"قطع الاتصال بالجملة",
  "ccnotification.gatewayconfig.label.send.prefix":"بادئة رقم المرسل",
  "ccnotification.gatewayconfig.label.receive.prefix":"بادئة الرقم المقبولة",
  "ccnotification.signature.add":"إنشاء توقيع البريد الإلكتروني",
  "ccnotification.signature.update":"تعديل توقيع البريد الإلكتروني",
  "ccnotification.signature.view":"عرض توقيع البريد الإلكتروني",
  "ccnotification.gatewayconfig.label.select.sendNumber.title":"تحديد رقم المرسل",
  "ccnotification.gatewayconfig.label.add.sendNumber.title":"إضافة رقم مرسل",
  "ccn.whitelist.urlvalidatefailed":"\u0627\u0644\u0631\u062C\u0627\u0621 \u0625\u062F\u062E\u0627\u0644 \u0639\u0646\u0648\u0627\u0646 IP \u0623\u0648 \u0627\u0633\u0645 \u0627\u0644\u0645\u062C\u0627\u0644",
  "ccnotification.notification.notificationRoute.recipientAddress" : "عنوان الاستلام",
  "ccnotification.notification.notificationRoute.implementationMode" : "طريقة التنفيذ",
  "ccnotification.notification.notificationRoute.edit" : "تحرير",
  "ccnotification.notification.notificationRoute.newNotificationRoute" : "مسار إعلام جديد",
  "ccnotification.notification.notificationRoute.editNotificationRoute" : "تحرير مسار الإشعارات",
  "ccnotification.notification.notificationRoute.inbox" : "علبة الوارد",
  "ccnotification.notification.notificationRoute.serviceInvoking" : "مكالمات الخدمة",
  "ccnotification.notification.notificationRoute.selectconfig" : "يرجى تحديد تكوين.",
  "ccnotification.notification.notificationRoute.willdeleteselectconfig" : "هل تريد بالتأكيد حذف التكوين المحدد؟",
  "ccnotification.notification.notificationRoute.deletenum.outstripping" : "يمكن حذف 100 سجل كحد أقصى في المرة الواحدة.",
  "ccnotification.notification.notificationRoute.delSuccess" : "\u062A\u0645 \u0627\u0644\u062D\u0630\u0641 \u0628\u0646\u062C\u0627\u062D.",
  "ccnotification.notification.notificationRoute.delFail" : "فشل الحذف.",
  "ccnotification.notification.notificationRoute.gateway" : "البوابة",
  "ccnotification.notification.notificationRoute.gatewaynotexist" : "البوابة المحددة غير موجودة",
  "ccnotification.notification.notificationRoute.receivernotexist" : "المستلم المحدد غير موجود",
  "ccnotification.notification.notificationRoute.receiveaddressexist" : "عنوان التلقي موجود بالفعل",
  "ccnotification.notification.notificationRoute.intelligentOrder" : "خلق حالة ذكي",
  "ccnotification.notification.notificationRoute.mobilerule" : "يُرجى إدخال أرقام الجوال. فصل أرقام هواتف محمولة متعددة باستخدام الفواصل المنقوطة",
  "ccnotification.notification.notificationRoute.inputPhoneNum" : "يُرجى إدخال رقم الجوال",
  "ccnotification.notification.notificationRoute.receiveAddrLen" : "لا يمكن أن يتجاوز الطول 1024 حرفًا",
  "ccnotification.notification.notificationRoute.retryNumLimitRule" : "قيمة الإدخال خارج النطاق:0-3",
  "ccn.variable.willdeleteselectvariable" : "هل تريد بالتأكيد حذف المتغيرات المحددة؟",
  "ccn.variable.deleteselectvariable": "حذف المتغير",
  "ccn.variable.variableisreferenced": "تتم الإشارة إلى المتغيرات بواسطة التدفق.",
  "ccn.variable.view.references": "عرض المراجع",
  "ccn.variable.view.references.none": "المتغير غير مُشار إليه حاليًا",
  "ccn.variable.selectvariable": "يرجى تحديد معلمة متغير.",
  "ccn.variable.field": "متغيرات الإشعارات",
  "ccn.unit.bytes": "وحدات البايت",
  'ccn.unit.millisecond': 'مللي ثانية',
  "ccn.message.fullscreen": "تكبير",
  'ccn.sms.config.batch.delete': 'حذف دفعة',
  'ccn.sms.config.receiver.uplinkMess': 'تلقي رسائل SMS للارتباط الصاعد',
  "ccn.sms.config.create.smsConfig": "إنشاء تكوينات SMS",
  "ccn.sms.config.modify.smsConfig": "تعديل تكوينات رسائل SMS",
  "ccnotification.smstemplate.message.edit.fail": "فشل تعديل قالب SMS لسحابة هواوي.",
  "ccnotification.message.choose": "يرجى تحديد البيانات.",
  "ccnotification.message.add.attachement": "إضافة مرفق",
  "ccnotification.message.defaultsenderid.error": "رقم المرسل الافتراضي ليس في النطاق المسموح به. يرجى تحديد رقم صحيح.",
  "ccnotification.template.message.emailAddressEmpty": "يوجد بريد وارد لم يتم تكوينه:",
  "ccn.countrycode.type": "نوع بادئة رمز البلد",
  "ccn.countrycode.type.with.plus": "مسبوقة بـ +",
  "ccn.countrycode.type.without.plus": "غير مسبوقة بـ +"
}