export default {
  "provision.common.title.tipsInfo": "Informa\u00E7\u00E3o",
  "provision.encryptinfo.historical.version": "Vers\u00E3o anterior",

  "provision.callcent.error.checkparam": "O valor n\u00E3o pode conter os seguintes caracteres especiais: <>\\",
  "provision.callcent.error.accesscode.deletefail.all.onccgateway": "Falha ao excluir o c\u00F3digo de acesso na plataforma WAS.",
  "provision.callcenterinstanceslist.vcallcenter.nameDiffListPromt": "H\u00E1 um call center virtual com VccName que \u00E9 inconsistente com VdnName no WAS. O VccId \u00E9",
  "provision.callcent.platformserver.ip": "Endere\u00E7o IP do servi\u00E7o",
  "provision.callcent.success.updateinfo": "Atualizado com sucesso.",
  "chat.usefulexpressions.message.saveSuccess": "A express\u00E3o salva o sucesso!",
  "chat.client.greeting.dear": "Caro;",
  "provision.tenant.chooseAC.first": "Selecione um c\u00F3digo de acesso.",
  "chat.agent.title.enterText": "Inserir texto",
  "promotion.tenant.management.tenantedit.provisionerror": "Falha ao atualizar o espa\u00E7o do Tenant.",
  "chat.usefulexpressions.message.contentLength": "Certifique-se de que o comprimento da express\u00E3o <= 1000!",
  "provision.callcenterinstance.softPhone.length.Inconsistent": "Os comprimentos do n\u00FAmero inicial e do n\u00FAmero final s\u00E3o inconsistentes.",
  "provision.callcenterinstanceslist.label.ttschannelUseRate": "Uso de TTS",
  "provision.softPhone.label.uapaddress.node2": "Endere\u00E7o IP da UAP para o n\u00F3 2",
  "provision.softPhone.label.uapaddress.node1": "Endere\u00E7o IP da UAP para o n\u00F3 1",
  "provision.tenantspace.save.success": "Salvado com sucesso.",
  "provision.tenantspace.table.chooseStatus": "Status do espa\u00E7o do tenant",
  "provision.tenantspace.popuppageurl.whiteListStyle": "Tipo de lista de confian\u00E7a de endere\u00E7os",
  "chat.agent.title.transfer": "Transfer\u00EAncia",
  "provision.callcenterinstanceslist.config.wnTo": "ID do funcion\u00E1rio final",
  "provision.tenantspace.gw.searchName": "Insira o nome do servidor de gateway a ser consultado.",
  "provision.callcent.error.updateaccesscode.fail.ondatabase": ". Falha ao modificar o c\u00F3digo de acesso no banco de dados.",
  "provision.softPhone.table.forcemodifyconfim": "Confirmar",
  "provision.callcenterinstanceslist.ccgateway.maxRegAgentNum": "Agentes simult\u00E2neos",
  "provision.tenant.management.pwd.checkmsg": "As duas senhas s\u00E3o diferentes.",
  "chat.skill.label.inputName": "Nome",
  "chat.session.label.evaluation": "Avalia\u00E7\u00E3o",
  "provision.tenantspace.obsServer.datasource.id": "ID da origem de dados",
  "provision.tenantspace.config.assignedworknonumber": "IDs do agente alocado",
  "provision.callcenterinstance.accesscode.validatebeginaccesscode": "O n\u00FAmero inicial do c\u00F3digo de acesso n\u00E3o pode ser maior que o n\u00FAmero final.",
  "provision.tenantspace.management": "Gerenciamento TenantSpace",
  "provision.callcenter.ivr.alert": "O valor deve ser o mesmo que a capacidade concorrente do IVR implantado.",
  "provision.callcent.error.deletesipserver.part.associationvcallcener": "Alguns servidores SIP selecionados est\u00E3o associados a Tenants e n\u00E3o podem ser exclu\u00EDdos.",
  "ccprovision.recorddrive.config.sftp.identitychecktips": "A conta ou senha do servidor SFTP foi alterada. Para manter a seguran\u00E7a das informa\u00E7\u00F5es\, insira sua conta atual e senha.",
  "provision.tenantspace.feature.outboundCallMarketingSupport.description": "Depois que o recurso de chamada originada inteligente estiver ativado\, o espa\u00E7o do Tenant oferecer\u00E1 suporte \u00E0 fun\u00E7\u00E3o de chamada originada inteligente.",
  "chat.session.label.exitQueueTime": "Tempo da fila de sa\u00EDda",
  "provision.callcenterinstanceslist.table.obssyncconfim": "Confirmar",
  "provision.callcenterinstanceslist.config.adminWnTo": "ID do administrador final",
  "provision.tenantspace.config.isCreateAccount": "Criar conta automaticamente para o call center virtual",
  "chat.session.endType.customerEnd": "Customer End",
  "provision.tenantspace.table.stopTenantSpace.confirm": "Confirmar suspens\u00E3o",
  "provision.encryptinfo.title.encrypted.information": "Informa\u00E7\u00F5es de criptografia",
  "provision.callcenterinstanceslist.softPhone.newpassword": "Nova senha do softphone",
  "chat.agent.label.send": "Enviar",
  "chat.skill.message.success": "Sucesso",
  "chat.skill.message.isExisted": "A fila de habilidades existe.",
  "provision.callcenterinstanceslist.vcallcenterDeatil.passValidateFailed": `A senha deve conter pelo menos tr\u00EAs tipos dos seguintes: letras mai\u00FAsculas\, letras min\u00FAsculas\, d\u00EDgitos e characters: -! {'@'} # $ % ^ & * () - especiais?`,
  "provision.callcenterinstanceslist.softPhone.modifyPassword": "Alterar senha",
  "provision.tenantspace.feature.knowledgeBaseSupport.description": "Depois que o recurso da base de conhecimento \u00E9 ativado\, o espa\u00E7o do Tenant suporta a fun\u00E7\u00E3o da base de conhecimento.",
  "provision.tenantspace.config.voicecall.more.agent.ivr.whithAms": "O n\u00FAmero m\u00E1ximo de chamadas de voz simult\u00E2neas n\u00E3o pode ser menor do que a soma de voz\, v\u00EDdeo\, multim\u00EDdia e agentes vers\u00E1teis\, e canais de \u00E1udio e v\u00EDdeo IVR.",
  "provision.callcenterinstanceslist.vcallcenterDeatil.odfsStrValidateFailed": "Apenas letras e d\u00EDgitos s\u00E3o permitidos.",
  "provision.callcenterinstanceslist.label.maxcallnums": "M\u00E1x. chamadas de voz simult\u00E2neas",
  "chat.thirdportal.chatWindow.label.userName": "Nome",
  "chat.skill.label.skillDetail": "Habilidade Detalhes",
  "provision.tenant.management.adminaccount": "Conta do administrador",
  "provision.tenantspace.feature.moveAgentNum": "N\u00FAmero m\u00E1ximo de agentes",
  "provision.tenant.need.first": "Selecione o call center\, o c\u00F3digo de acesso e o n\u00FAmero de agentes.",
  "provision.tenantspace.save.fail.078add": "Falha ao criar o espa\u00E7o do locat\u00E1rio ao configurar o sistema de autentica\u00E7\u00E3o de terceiros.",
  "provision.tenantspace.config.tenant.webrtc.featurevalue.not.one": "O valor do recurso WebRTC alocado ao tenant n\u00E3o \u00E9 1.",
  "provision.obs.success.sync": "Sincroniza\u00E7\u00E3o bem-sucedida.",
  "ccprovision.recorddrive.config.sftp.serverconfig": "Configura\u00E7\u00E3o do servidor SFTP",
  "ccprovision.vcallecenter.config.popup.tab.configurationinterface.modifypassword": "Modificar senha de configura\u00E7\u00E3o",
  "chat.weChatConfig.message.skillExisted": "A habilidade que voc\u00EA escolheu j\u00E1 foi vinculada a outro AppId.",
  "chat.usefulexpressions.message.selectExpressions": "Selecione express\u00F5es.",
  "provision.tenantspace.config.success": "Espa\u00E7o do tenant configurado com sucesso",
  "chat.agentConsole.message.content.audio": "[Mensagem de [\u00C1udio]",
  "provision.tenantspace.cc.videoAgentUsage": "Uso do Agente de V\u00EDdeo",
  "ccprovision.IVRSprogress.config.button.async": "Sincronizar",
  "provision.callcenterinstanceslist.config.ccNameUpdateError": "N\u00E3o altere o nome do call center ao editar o call center",
  "provision.tenantspace.feature.anonymous": "chamada originada an\u00F4nima",
  "provision.callcent.error.createaccesscode.fail.ondatabase": "Falha ao criar um c\u00F3digo de acesso no banco de dados.",
  "provision.callcenterinstance.accesscode.batchcreate.fail.repeated": "Todos os c\u00F3digos de acesso neste segmento de n\u00FAmero j\u00E1 existem.",
  "provision.callcenterinstanceslist.table.synchronize": "Sincronizar",
  "provision.callcent.error.updateSoftNumPasswordInfo": "Erro de Informa\u00E7\u00E3o da conta",
  "chat.client.label.loginTitle": "login",
  "chat.webconfig.label.greeting": "Sauda\u00E7\u00E3o",
  "provision.callcent.error.deleteccgateway.part.associationvcallcener": "Alguns servidores CC-Gateway selecionados est\u00E3o associados a tenants e n\u00E3o podem ser exclu\u00EDdos.",
  "provision.tenantspace.config.chooseDefaultPhoneNumber": "Selecionar N\u00FAmero de Chamada Padr\u00E3o",
  "provision.tenantspace.config.ttschannelnums": "Quantidade TTS",
  "provision.callcenterinstanceslist.label.videoAgentUseRate": "Uso do Agente de V\u00EDdeo",
  "provision.tenantspace.save.fail.104-011": "Falha ao excluir arquivo de grava\u00E7\u00E3o.",
  "provision.tenantspace.save.fail.104-010": "O par\u00E2metro ID do n\u00F3 de grava\u00E7\u00E3o n\u00E3o est\u00E1 configurado.",
  "chat.skill.label.maxQueueTimeInTable": "MaxQueueTime",
  "provision.callcenterinstanceslist.table.viewing": "visualiza\u00E7\u00E3o",
  "provision.tenantspace.selectStastus.noncommercial": "Uso comercial de ensaio",
  "provision.callcenterccaccesscodeslist.table.singledeleteconfim": "Tem certeza de que quer excluir o c\u00F3digo de acesso selecionado? Certifique-se de que ele n\u00E3o esteja associado a uma central de atendimento virtual.",
  "provision.callcent.error.updatesoftphonenumber.noexist": "N\u00FAmero de telefone soft n\u00E3o existe.",
  "provision.tenantspace.sip.sipServerIP": "IP do servidor SIP",
  "chat.client.label.chatTitle": "factura de bate-papo do cliente",
  "provision.tenantspace.config.videocall.more.videoagent": "O n\u00FAmero m\u00E1ximo de chamadas de v\u00EDdeo simult\u00E2neas \u00E9 maior ou igual ao n\u00FAmero de agentes de v\u00EDdeo.",
  "provision.tenantspace.save.fail.104-009": "Falha na convers\u00E3o de grava\u00E7\u00E3o.",
  "provision.callcenterinstanceslist.vcallcenter.OBSServer": "Servidor de chamadas originadas do OBS",
  "provision.tenantspace.save.fail.104-007": "O formato de arquivo de grava\u00E7\u00E3o n\u00E3o \u00E9 suportado.",
  "provision.callcent.error.saveccgateway.nameinfo": "Falha na cria\u00E7\u00E3o. Certifique-se de que o nome do CC-Gateway ainda n\u00E3o exista.",
  "provision.tenantspace.save.fail.104-008": "Os par\u00E2metros do sistema de formato de grava\u00E7\u00E3o n\u00E3o est\u00E3o configurados.",
  "provision.callcent.error.delete": "Falha de exclus\u00E3o",
  "provision.tenantspace.save.fail.104-006": "Espa\u00E7o livre insuficiente para gravar falha de exclus\u00E3o.",
  "provision.tenantspace.save.fail.104-005": "O arquivo de grava\u00E7\u00E3o que n\u00E3o conseguiu excluir a grava\u00E7\u00E3o n\u00E3o existe.",
  "provision.tenantspace.save.fail.104-004": "Falha na exclus\u00E3o da grava\u00E7\u00E3o sem mapear a letra da unidade.",
  "provision.tenantspace.save.fail.104-003": "O nome do arquivo da exclus\u00E3o de grava\u00E7\u00E3o falhada \u00E9 inv\u00E1lido.",
  "provision.callcenterinstanceslist.softPhone.password": "Senha",
  "provision.tenantspace.save.fail.104-001": "A fun\u00E7\u00E3o de download de grava\u00E7\u00E3o n\u00E3o est\u00E1 ativada.",
  "provision.tenantspace.save.fail.104-002": "O usu\u00E1rio n\u00E3o possui registro de inspe\u00E7\u00E3o de qualidade / permiss\u00E3o de reprodu\u00E7\u00E3o de inspe\u00E7\u00E3o de qualidade.",
  "chat.thirdportal.chatWindow.label.userEmail": "Email",
  "provision.authconfig.description.toolong.error": "A descri\u00E7\u00E3o n\u00E3o pode conter mais de 200 caracteres.",
  "provision.callcent.error.edit": "Falha na edi\u00E7\u00E3o.",
  "provision.callcenterinstanceslist.vcallcenter.vccName": "Nome do VDN",
  "provision.tenant.management.tenantname.beginwithalpha": "A senha deve conter pelo menos tr\u00EAs tipos dos seguintes: letras mai\u00FAsculas\, letras min\u00FAsculas\, d\u00EDgitos e characters: -! {'@'} # $ % ^ & * () - especiais?",
  "provision.callcent.error.save": "Erro",
  "provision.callcenterinstanceslist.sipServer.sipServerIpAdd": "Adicionar",
  "provision.tenantspace.save.fail.120221": "O grupo de par\u00E2metros referenciado pelo IVR n\u00E3o existe.",
  "provision.tenantspace.save.fail.120220": "O IVR \u00E9 referenciado pelo grupo VDN e n\u00E3o pode ser exclu\u00EDdo.",
  "provision.callcenterinstance.softPhone.batchcreate.fail.repeated": "Todos os segmentos de n\u00FAmero inseridos j\u00E1 existem.",
  "provision.callcenterinstanceslist.softPhone.batchdeleteconfim": "Tem certeza de que quer excluir todos os n\u00FAmeros do softphone selecionados? Certifique-se de que os n\u00FAmeros do softphone n\u00E3o estejam associados a nenhuma central de atendimento virtual.",
  "provision.tenant.management.adminname": "Administrador",
  "provision.tenantspace.save.fail.120222": "O n\u00F3 CTI referenciado pelo IVR n\u00E3o existe.",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.availableWorkNo": "Nenhum ID de agente dispon\u00EDvel no segmento ID de agente.",
  "provision.tenantspace.cc.maxVideoCallnums": "M\u00E1x. chamadas de v\u00EDdeo simult\u00E2neas",
  "provision.tenantspace.save.fail.120219": "O IVR \u00E9 referenciado pelo VDN e n\u00E3o pode ser exclu\u00EDdo.",
  "provision.callcent.error.savesipserverdesinfo.regAgentNum": ". O n\u00FAmero de agentes registrados simult\u00E2neos \u00E9 diferente do n\u00FAmero de lugares registrados restantes.",
  "provision.tenantspace.save.fail.120217": "O n\u00FAmero m\u00E1ximo de IVRs para o mesmo endere\u00E7o IP \u00E9 20.",
  "provision.tenantspace.save.fail.120216": "O IVR e o CCIVR n\u00E3o podem ser modificados.",
  "provision.tenantspace.cc.searchName": "Insira o nome do call center a ser consultado.",
  "provision.tenantspace.config.param.error.videoUsage": "Insira o n\u00FAmero de agentes de v\u00EDdeo.",
  "chat.weChatConfig.label.codeName": "Code",
  "chat.weChatConfig.title.deleteTitle": "Excluir Configura\u00E7\u00E3o do WeChat",
  "provision.callcent.error.regaddrformat": "O formato do endere\u00E7o de registro est\u00E1 incorreto.",
  "chat.skill.message.willDeleteSkill": "Tem certeza de que deseja excluir a habilidade?",
  "ccprovision.IVRSprogress.config.button.edit": "Editar",
  "chat.client.message.evaluationFail": "Falha avaliada!",
  "provision.callcenterinstanceslist.obsServer.obsServerIpUpdate": "Modificar",
  "provision.tenantspace.videoagentchannnels.limit": "O n\u00FAmero de canais de agente de v\u00EDdeo deve ser maior ou igual ao dobro do n\u00FAmero de agentes de v\u00EDdeo.",
  "provision.callcenterinstanceslist.vcallcenter.ServerNumber": "N\u00FAmero do servi\u00E7o",
  "provision.callcent.error.accesscode.deletefail.all.associationvcallcener": "O c\u00F3digo de acesso est\u00E1 associado ao tenant e n\u00E3o pode ser exclu\u00EDdo.",
  "provision.recorddrive.label.isfirstletter.no": "N\u00E3o",
  "ccprovision.recorddrive.config.isfirstletter": "Se a primeira letra de disco",
  "provision.recorddrive.label.isfirstletter.yes": "sim",
  "chat.client.label.order": "Meu pedido",
  "provision.recorddrive.error.firstletter.repeated": "A primeira letra de drive j\u00E1 existe",
  "provision.tenantspace.config.ivrchannels": "Quantidade de canal IVR",
  "provision.tenantspace.common.agent.explain": "Agente comum: opera\u00E7\u00F5es b\u00E1sicas\, como atender chamadas recebidas\, controlar chamadas de \u00E1udio e v\u00EDdeo e receber e enviar mensagens multim\u00EDdia.",
  "provision.tenantspace.dataUpload.openResourceDump": "Fornece a fun\u00E7\u00E3o de despejo de recursos para o espa\u00E7o do Tenant. Nota: o servi\u00E7o de armazenamento de destino de despejo de recursos atualmente suporta apenas o servi\u00E7o de armazenamento de objetos huawei cloud OBS. Para usar o recurso de descarga de recursos\, o inquilino precisa comprar este servi\u00E7o.",
  "provision.callcenterinstanceslist.table.nodeid": "ID do n\u00F3",
  "provision.tenantspace.config.chooseAnonymousCallServerTitle": "Selecione o servidor de chamadas an\u00F4nimas.",
  "provision.tenantspace.save.fail.120205": "O c\u00F3digo de acesso do sistema \u00E9 referenciado pelo grupo VDN e n\u00E3o pode ser exclu\u00EDdo.",
  "provision.tenantspace.selectStastus.stop": "Suspenso",
  "provision.tenantspace.save.fail.120204": "O c\u00F3digo de acesso do sistema \u00E9 referenciado pelo VDN e n\u00E3o pode ser exclu\u00EDdo.",
  "chat.weChatConfig.message.ensureDelete": "Tem certeza de que exclua esses itens de configura\u00E7\u00E3o?",
  "provision.tenantspace.save.fail.120203": "C\u00F3digo de acesso ao sistema e c\u00F3digo de acesso \u00E0 chamada de rede NIRC s\u00E3o duplicados.",
  "provision.tenantspace.save.fail.120202": "O c\u00F3digo de acesso do sistema sobrep\u00F5e-se ao c\u00F3digo de acesso de roteamento especial.",
  "provision.tenantspace.table.success": "Sucesso",
  "provision.callcenterinstanceslist.vcallcenter.UCSServer": "Servidor multim\u00EDdia CCUCS",
  "provision.recorddrive.selectrecorddrive.batchdeleteconfim": "Tem certeza de que deseja excluir todas as letras de unidade selecionadas? Certifique-se de que as letras da unidade n\u00E3o estejam ocupadas pelo Tenant antes de continuar.",
  "provision.tenantspace.save.fail.120201": "O c\u00F3digo de acesso ao sistema est\u00E1 duplicado.",
  "provision.tenantspace.sip.maxRegAgentNum": "Quantidade de Agentes Registrados Simult\u00E2neos",
  "provision.callcenterinstanceslist.label.videoAgentCurCapacity": "Quantidade de Agentes de v\u00EDdeo",
  "provision.tenantspace.table.stopTenantSpace": "Tem certeza de que quer suspender o Tenant?",
  "provision.callcenterinstanceslist.softPhone.softPhoneNum.title": "Configura\u00E7\u00E3o de software",
  "provision.callcent.error.updateinfo.maxchnsmall": "O n\u00FAmero m\u00E1ximo de chamadas simult\u00E2neas n\u00E3o pode ser menor que o n\u00FAmero de canais ocupados.",
  "chat.webconfig.label.intergratedcode": "C\u00F3digo de integra\u00E7\u00E3o",
  "provision.tenantspace.save.fail.078delete": "Falha ao excluir o espa\u00E7o do locat\u00E1rio ao configurar o sistema de autentica\u00E7\u00E3o de terceiros.",
  "chat.session.channel.all": "Todos",
  "provision.whiteListAddress.PopMenu": "Tela pop-up",
  "provision.whiteListAddress.outboundcall.obs.fileserver": "Chamada de sa\u00EDda para o servidor de arquivos do OBS",
  "provision.whiteListAddress.outboundcall.sftp.fileserver": "Chamada de sa\u00EDda do servidor de arquivos SFTP",
  "provision.whiteListAddress.ivr.voiceupload.callback": "Retorno de chamada de upload de voz do IVR",
  "provision.tenantspace.feature.webrtc": "Chamadas e v\u00EDdeo incorporados WebRTC para agentes.",
  "provision.ivr.error.sync": "Falha na sincroniza\u00E7\u00E3o",
  "chat.skill.label.reset": "Redefinir",
  "provision.tenantspace.table.fail": "Falha",
  "chat.usefulexpressions.message.contentNotNull": "A express\u00E3o n\u00E3o pode ser nula!",
  "provision.tenantspace.common.case.explain": "Quando o caso for aberto\, o Tenant abrir\u00E1 o recurso de servi\u00E7o de nuvem de servi\u00E7o.",
  "provision.tenantspace.common.newcase.explain": "Ative o recurso Case 2.0 para gerenciar e configurar tickets de servi\u00E7o para os locat\u00E1rios.",
  "provision.encryptinfo.encryptKey.length.error": "Comprimento da chave exigido:",
  "chat.timerange.message.timeerror": "Erro de tempo!",
  "provision.tenantspace.save.fail.120541": "No m\u00E1ximo 60 agentes podem ser processados simultaneamente.",
  "provision.tenantspace.rechoose": "Randomizar",
  "provision.tenantspace.ivr.searchName": "Insira a ID do IVR a ser consultado.",
  "provision.tenantspace.save.fail.120540": "O valor da licen\u00E7a multim\u00EDdia est\u00E1 fora do alcance.",
  "provision.tenantspace.ConversionNoncommercial": "Uso de avalia\u00E7\u00E3o",
  "chat.session.label.allChannel": "Todos os tipos de canais",
  "provision.tenantspace.cc.ttschannelUsagePercent": "Uso de TTS",
  "provision.tenantspace.config.assignedworkno": "ID de trabalho alocado",
  "provision.callcent.error.accesscode.deletefail.all.onccgateway.with.associationvcallcener": "Falha ao excluir todos os c\u00F3digos de acesso porque alguns est\u00E3o associados a Tenants e outros n\u00E3o puderam ser exclu\u00EDdos na plataforma WAS.",
  "provision.tenantspace.table.maxcallnumswithdesc": "M\u00E1x. Chamadas de voz simult\u00E2neas (O valor n\u00E3o pode ser menor do que a soma de agentes e canais IVR de \u00E1udio e v\u00EDdeo.)",
  "provision.tenantspace.cc.current": "Capacidade atual",
  "provision.tenant.management.tenantname": "Espa\u00E7o do tenant",
  "provision.tenantspace.save.fail.120538": "O n\u00FAmero de IDs de agente transfer\u00EDveis \u00E9 insuficiente.",
  "provision.tenantspace.save.fail.120537": "Os VDNs dispon\u00EDveis n\u00E3o podem conter o VDN transferido.",
  "provision.tenantspace.save.fail.120539": "Tipo de licen\u00E7a multim\u00EDdia inv\u00E1lido.",
  "provision.tenantspace.save.fail.120536": "O comprimento do VDN dispon\u00EDvel n\u00E3o pode ser 0.",
  "provision.tenantspace.save.fail.120534": "O grupo de recursos CCS referenciado pelo VDN n\u00E3o existe.",
  "provision.tenantspace.save.fail.120535": "O intervalo de ID do agente est\u00E1 em uso e n\u00E3o pode ser cancelado.",
  "provision.tenantspace.config.worknoassign.filternumber": "Excluir n\u00FAmeros",
  "provision.callcenterinstanceslist.vcallcenterDeatil.save": "Salvar",
  "provision.tenantspace.save.fail.120533": "Faixa de ID do agente VDN duplicada.",
  "provision.tenantspace.save.fail.120532": "O c\u00F3digo de acesso original foi referenciado por um n\u00FAmero chamado.",
  "provision.tenantspace.save.fail.120531": "O c\u00F3digo de acesso contido em informa\u00E7\u00F5es b\u00E1sicas VDN n\u00E3o \u00E9 alocado ao VDN.",
  "provision.callcenterinstanceslist.vcallcenterDeatil.defaultCallerNum": "N\u00FAmero de chamada padr\u00E3o",
  "provision.tenant.management.contacts": "Nome",
  "provision.tenantspace.save.fail.120530": "A unidade referenciada pelo VDN n\u00E3o existe.",
  "provision.callcenterinstanceslist.ccgatewayserver.ccGatewayIp": "Endere\u00E7o IP do CC-Gateway",
  "provision.tenant.config.maxAgents": "Agentes de Voz Max",
  "chat.agent.label.offline": "Desligado",
  "provision.tenantspace.config.fail.savedata.tenant.feature.inDatabase": "O banco de dados falhou ao salvar informa\u00E7\u00F5es sobre recursos de Tenant",
  "provision.tenantspace.config.voicecall.more.agent.ivr.whithoutAms": "O n\u00FAmero m\u00E1ximo de chamadas de voz simult\u00E2neas n\u00E3o pode ser menor do que a soma de agentes de voz\, v\u00EDdeo e vers\u00E1teis e canais de \u00E1udio e v\u00EDdeo IVR.",
  "provision.callcenterinstanceslist.sipServer.description": "Descri\u00E7\u00E3o",
  "provision.tenantspace.save.fail.120529": "A ID do administrador VDN deve estar dentro da faixa de ID do agente configurada.",
  "provision.tenantspace.save.fail.120528": "Quando a transfer\u00EAncia de suspens\u00E3o do agente est\u00E1 ativada\, o tom de prompt \u00E9 reproduzido ap\u00F3s a chamada ser transferida de volta para o agente. O par\u00E2metro tom prompt deve ser definido.",
  "provision.callcenterinstanceslist.sipServer.sipanonymousCall": "Chamada an\u00F4nima SIP",
  "provision.tenantspace.save.fail.120526": "A fonte de dados IVR padr\u00E3o n\u00E3o est\u00E1 contida nas origens de dados VDN ou nas fontes de dados de terceiros alocadas ao VDN.",
  "provision.tenantspace.save.fail.120527": "O VDN \u00E9 referenciado pelo cliente CCP local e n\u00E3o pode ser exclu\u00EDdo.",
  "provision.tenantspace.save.fail.120525": "As fontes de dados IVR n\u00E3o est\u00E3o contidas nas fontes de dados VDN ou nas fontes de dados de terceiros alocadas ao VDN.",
  "provision.tenantspace.save.fail.120524": "O c\u00F3digo de acesso do sistema alocado ao VDN foi alocado a um grupo VDN.",
  "provision.tenantspace.save.fail.120522": "O VDN n\u00E3o suporta o tipo de chamada Web\, mas os c\u00F3digos de acesso ao sistema do tipo de chamada Web s\u00E3o alocados ao VDN.",
  "provision.tenantspace.save.fail.120521": "O VDN n\u00E3o suporta os tipos de chamada de e-mail e fax\, mas os c\u00F3digos de acesso do sistema do tipo de chamada de e-mail ou fax s\u00E3o alocados ao VDN.",
  "provision.tenantspace.save.fail.120523": "O c\u00F3digo de acesso do sistema alocado ao VDN foi alocado a outro VDN.",
  "provision.tenantspace.config.ivrserver.select.confirm": "Confirmar",
  "provision.tenantspace.detail.create.time": "Hor\u00E1rio de cria\u00E7\u00E3o",
  "provision.tenantspace.save.fail.120520": "O c\u00F3digo de acesso ao sistema referenciado pelo VDN n\u00E3o existe.",
  "provision.callcenter.gateway.agentnum.alert": "O valor deve ser o mesmo que a capacidade de configura\u00E7\u00E3o real do cluster CC-Gateway.",
  "provision.tenantspace.statusRecordTime.6Month": "6 meses",
  "chat.weChatConfig.stepname.verify": "Verificar configura\u00E7\u00E3o",
  "provision.tenantspace.agentinfo.message.singledeleteconfim": "Tem certeza de que deseja excluir o agente selecionado?",
  "provision.tenantspace.ivr.ivrprogid": "ID do processo",
  "provision.callcent.error.saveccaccesscodeinfo": ". Certifique-se de que o c\u00F3digo de acesso n\u00E3o existe.",
  "provision.tenantspace.save.fail.120519": "O n\u00F3 distribu\u00EDdo usado para grava\u00E7\u00E3o autom\u00E1tica de voz for\u00E7ada VDN n\u00E3o est\u00E1 configurado para o VDN.",
  "provision.tenantspace.save.fail.120517": "Se nenhum sistema de grava\u00E7\u00E3o de voz for especificado na configura\u00E7\u00E3o b\u00E1sica de informa\u00E7\u00F5es RMS\, somente a grava\u00E7\u00E3o de voz HUAWEI ACD poder\u00E1 ser configurada para o VDN atual.",
  "provision.tenant.management.accesscode": "C\u00F3digo de acesso",
  "provision.tenantspace.whiteNumber": "N\u00FAmeros da lista de permiss\u00F5es",
  "provision.tenantspace.save.fail.120515": "A origem de dados referenciada pelo VDN n\u00E3o existe.",
  "provision.callcent.error.ipValidateFailed": "endere\u00E7o IP validado.",
  "provision.tenantspace.save.fail.120516": "O IVR referenciado pelo VDN n\u00E3o existe.",
  "provision.tenantspace.save.fail.120514": "O recurso distribu\u00EDdo referenciado pelo VDN n\u00E3o existe.",
  "provision.tenantspace.save.fail.120511": "O nome VDN \u00E9 duplicado com o nome do grupo VDN.",
  "provision.tenantspace.save.fail.120512": "Faixa de ID do agente VDN duplicada.",
  "provision.tenantspace.save.fail.120513": "O OBS referenciado pelo VDN n\u00E3o existe.",
  "provision.tenantspace.save.fail.120510": "O nome VDN \u00E9 duplicado com outro nome VDN.",
  "provision.tenantspace.config.chooseGatewayServer": "selecione um servidor de gateway",
  "chat.agent.label.chatBot": "Chat Bot",
  "provision.callcenterinstanceslist.ucsServer.ucsServerPort": "Porta do servidor CCUCS",
  "provision.tenant.management.tenantcreate.failed": "Falha ao criar o espa\u00E7o do Tenant. Certifique-se de que o nome do Tenant ou a conta do administrador seja exclusivo.",
  "provision.callcenterinstanceslist.softPhone.used.pleaseselect": "Selecione o uso",
  "provision.callcenterinstanceslist.vcallcenter.maxAmsCalls": "Chamadas an\u00F4nimas simult\u00E2neas",
  "provision.tenantspace.save.fail.120509": "A restri\u00E7\u00E3o est\u00E1 ativada. Por padr\u00E3o\, o n\u00FAmero chamador come\u00E7a com o prefixo.",
  "chat.weChatConfig.title": "Ativando Canal de M\u00EDdia Social",
  "provision.tenantspace.save.fail.120508": "A restri\u00E7\u00E3o est\u00E1 ativada. O prefixo do n\u00FAmero chamador n\u00E3o pode estar vazio.",
  "provision.tenantspace.save.fail.120506": "O n\u00FAmero m\u00E1ximo de agentes de v\u00EDdeo que podem fazer login simultaneamente no sistema excede o limite superior.",
  "provision.tenantspace.save.fail.120505": "O n\u00FAmero de partidos da confer\u00EAncia excede o limite superior.",
  "provision.tenantspace.save.fail.120507": "O n\u00FAmero chamador n\u00E3o pode conter nenhuma rela\u00E7\u00E3o de inclus\u00E3o.",
  "provision.tenantspace.save.fail.120504": "O n\u00FAmero m\u00E1ximo de chamadas de sa\u00EDda simult\u00E2neas excede o limite superior.",
  "chat.weChatConfig.stepname.success": "Configure Overview",
  "provision.tenantspace.save.fail.120502": "O n\u00FAmero m\u00E1ximo de chamadas de voz simult\u00E2neas excede o limite superior.",
  "provision.tenantspace.save.fail.120501": "O n\u00FAmero m\u00E1ximo de agentes que podem fazer login simultaneamente no sistema excede o limite superior.",
  "provision.tenantspace.agentinfo.message.media": "Multim\u00EDdia",
  "provision.tenantspace.save.fail.120503": "O n\u00FAmero m\u00E1ximo de chamadas Web simult\u00E2neas excede o limite superior.",
  "provision.callcenterinstanceslist.config.odfsvalidate": "Todas as configura\u00E7\u00F5es de servi\u00E7o ODFS devem estar em branco ou preenchidas.",
  "provision.tenantspace.config.configVirtualCallCenter": "Configurar o call center virtual",
  "provision.callcent.error.updatesoftphonenumber.ondatabase": "Falha ao modificar o n\u00FAmero de softphone no banco de dados.",
  "provision.callcenterinstanceslist.vcallcenter.tenantSpaceName": "TenantSpaceName",
  "provision.tenantspace.config.call.more.agent.ivr": "O n\u00FAmero m\u00E1ximo de chamadas de voz simult\u00E2neas deve ser maior ou igual \u00E0 soma do n\u00FAmero de agentes de voz e do n\u00FAmero de canais de \u00E1udio e v\u00EDdeo IVR.",
  "chat.timerange.label.sevendays": "7 dias",
  "chat.thirdportal.chatWindow.title": "Customer Online Service",
  "ccprovision.IVRSprogress.config.number": "N\u00E3o",
  "ccprovision.recorddrive.config.storageLimit": "Limite de armazenamento mais baixo (MB)",
  "provision.callcent.error.accesscode.deletefail.accesscodenoexist": "Falha ao excluir o c\u00F3digo de acesso porque ele n\u00E3o existe.",
  "provision.authconfig.duplicate.description.error": "As descri\u00E7\u00F5es devem ser exclusivas.",
  "provision.tenantspace.config.amsNumber.unequal": "O n\u00FAmero de chamadas an\u00F4nimas simult\u00E2neas deve ser o consistente.",
  "provision.tenantspace.config.intelligent.agentNumber.not.exceed.agentNumber": "O n\u00FAmero de agentes inteligentes alocados ao locat\u00E1rio n\u00E3o pode exceder o n\u00FAmero total de agentes.",
  "provision.callcenterinstanceslist.whitelist.Title": "Endere\u00E7o de chamada de identifica\u00E7\u00E3o inteligente",
  "promotion.tenant.management.tenantedit.registerserver.notavailable": "O servidor de registro n\u00E3o est\u00E1 dispon\u00EDvel.",
  "provision.tenantspace.config.chooseDrive": "Unidade de grava\u00E7\u00E3o de voz",
  "provision.tenantspace.config.chooseworkNo.tip": "Insira o segmento auspicioso de trabalho de acordo com o trabalho ocioso Nenhuma informa\u00E7\u00E3o",
  "provision.tenantspace.cc.currentpercent": "Capacidade atual (%)",
  "provision.callcenterinstanceslist.config.confirm": "Configure informa\u00E7\u00F5es sobre os n\u00F3s relacionados \u00E0 plataforma WAS.",
  "provision.tenantspace.create.tenantName": "Nome do tenant",
  "provision.tenantspace.chooseauthenticationsystem": "Configurar Sistema de Autentica\u00E7\u00E3o de Terceiros",
  "provision.callcenterinstanceslist.config.nextStep": "Pr\u00F3ximo",
  "provision.tenantspace.config.chooseOBSServer": "Selecione um servidor OBS.",
  "provision.encryptinfo.paddingScheme": "Modo de preenchimento",
  "provision.tenantspace.vitualCenterName": "Centro de chamada virtual",
  "chat.weChatConfig.stepname.selectchannel": "Selecionar Canal Social",
  "provision.ivr.success.sync": "Sincroniza\u00E7\u00E3o bem-sucedida",
  "provision.tenantspace.save.fail.101": "Erro de sincroniza\u00E7\u00E3o.",
  "chat.weChatConfig.label.nextStep": "Pr\u00F3xima etapa",
  "provision.callcenterinstanceslist.table.uapId": "ID do UAP",
  "provision.callcenterinstanceslist.whiteAddr.regAddrExample": "exemplo:https://ip:port/service-cloud/rest/cc-ai/v1/asrevent/asrresult",
  "provision.callcenterinstanceslist.config.uapId": "Esse valor deve ser o mesmo que o ID UAP na WAS.",
  "chat.thirdportal.chatWindow.label.userPhone": "Telefone",
  "ccprovision.IVRSprogress.config.button.cacel": "Cancelar",
  "ccprovision.vcallecenter.error.updateinfo.currentacountpasderror": "A senha da conta atual est\u00E1 incorreta.",
  "chat.timerange.message.timeempty": "Tempo vazio!",
  "provision.tenant.management.batchcreatenumber": "Quantidade",
  "provision.tenantspace.config.agent.ivr.more.zero": "N\u00FAmero de agentes de voz\, agente de v\u00EDdeo\, agente multim\u00EDdia\, agente vers\u00E1til\, canais de \u00E1udio IVR e canais de v\u00EDdeo IVR n\u00E3o pode ser 0.",
  "provision.callcenterinstanceslist.config.nmsservicePort": "N\u00FAmero da porta ODFS",
  "provision.callcenterinstanceslist.table.nodemodepool": "modo de cole\u00E7\u00E3o de recursos",
  "provision.tenantspace.moveAgentUsage.iszero": "N\u00FAmero insuficiente de canais de \u00E1udio IVR.",
  "provision.accesscode.table.accessCode": "C\u00F3digo de acesso",
  "provision.callcenterinstanceslist.config.previousStep": "Anterior",
  "provision.tenantspace.config.softphoneassign.softphonescope.extensiverange": "A faixa de valores do n\u00FAmero inicial ao n\u00FAmero final n\u00E3o pode ser maior que 1000.",
  "provision.tenantspace.table.activeTenantSpace": "Tem certeza de que quer ativar o Tenant?",
  "provision.callcent.success.deletesoftphonenumber.processed": "Exclus\u00E3o de dados do segundo plano... Por favor\, verifique mais tarde.",
  "provision.tenantspace.gw.maxRegAgentNum": "Quantidade de Agentes Registrados Simult\u00E2neos",
  "provision.callcent.error.accesscode.deletefail.part.associationvcallcener": "Alguns c\u00F3digos de acesso est\u00E3o associados a tenants e n\u00E3o podem ser exclu\u00EDdos.",
  "provision.whiteListAddress.TenantUnExist": "O Tenant n\u00E3o existe.",
  "provision.authconfig.updateMessage": "Tem certeza de que deseja atualizar a chave secreta?",
  "ccprovision.recorddrive.config.sftp.filestoragepathStrValidateFailed": "O caminho de armazenamento do arquivo n\u00E3o pode conter './'",
  "provision.tenantspace.config.worknoassign.filternumber.explain2": "O banco de n\u00FAmeros ap\u00F3s a triagem n\u00E3o conter\u00E1 os n\u00FAmeros dos n\u00FAmeros acima",
  "provision.callcent.error.deletesoftphonenumber.part.associationvcallcener": "Alguns dos n\u00FAmeros de softphone selecionados est\u00E3o associados ao Tenant e n\u00E3o podem ser exclu\u00EDdos.",
  "provision.callcenterinstanceslist.config.createsuccess": "O call center foi criado com sucesso.",
  "provision.tenantspace.config.worknoassign.filternumber.explain1": `Ao inserir v\u00E1rios n\u00FAmeros\, use "\," para separar. Insira at\u00E9 5 n\u00FAmeros\, por exemplo\, "4\,11\,13"`,
  "provision.tenantspace.config.chooseUCSServer": "Selecione um servidor CCUCS.",
  "provision.tenantspace.tenantSpaceCount": "N\u00FAmero Total de tenants",
  "provision.callcenterinstanceslist.table.updateFault": "Tem certeza de que quer definir o tom de falha da central de atendimento virtual?",
  "provision.tenantspace.table.maxCalls": "M\u00E1x. chamadas de voz simult\u00E2neas",
  "provision.callcenterinstanceslist.sipServer.uapPort": "Porta do UAP",
  "provision.callcenterinstanceslist.whitepageurl.httptip": "(Inseguro)",
  "promotion.tenant.management.tenantedit.vdnsync.failed": "Falha ao sincronizar dados com o call center virtual.",
  "provision.callcenterinstanceslist.config.ttschannelnumsValidate": "O n\u00FAmero de canais TTS \u00E9 menor do que o n\u00FAmero de canais realmente alocados no call center.",
  "chat.skill.message.warning": "Aviso",
  "provision.tenantspace.prompt": "Informa\u00E7\u00F5es",
  "provision.callcenterccaccesscodeslist.table.batchdeleteconfim": "Tem certeza de que deseja excluir todos os c\u00F3digos de acesso selecionados? Certifique-se de que o c\u00F3digo de acesso n\u00E3o est\u00E1 associado a um call center virtual.",
  "chat.session.label.created_startTime": "Hora de in\u00EDcio",
  "provision.callcenterinstanceslist.ccgatewayServer.singledeleteconfim": "Tem certeza de que deseja excluir o servidor CC-Gateway selecionado?",
  "provision.tenantspace.config.softphone.noavailable": "Nenhum n\u00FAmero de softphone dispon\u00EDvel.",
  "provision.tenantspace.deleteWhiteNumber": "Tem certeza de que quer excluir o n\u00FAmero da lista de permiss\u00F5es?",
  "provision.tenantspace.config.selectedsoftphone": "Selecionado {0} N\u00FAmeros {1}",
  "chat.session.label.channel": "Canal",
  "provision.callcenterinstance.softPhone.validateSoftPhoneNumrange": "A faixa de n\u00FAmeros recentemente adicionados n\u00E3o pode exceder 1000",
  "provision.tenantspace.select.start": "Iniciado",
  "provision.tenantspace.config.error.softphone.noagentadd": "Nenhum agente de voz\, v\u00EDdeo\, multim\u00EDdia\, vers\u00E1til ou m\u00F3vel \u00E9 adicionado.",
  "provision.tenantspace.common.ivr": "IVR convencional",
  "provision.tenantspace.cc.max": "Capacidade m\u00E1xima",
  "provision.callcenterinstanceslist.table.videoAgentChannels": "Quantidade do canal do Agente de V\u00EDdeo",
  "provision.tenantspace.popuppageurl.deleteconfim": "Tem certeza de que deseja excluir o endere\u00E7o da lista de permiss\u00F5es?",
  "provision.callcenterinstanceslist.obsServer.obsId": "ID OBS",
  "provision.callcent.label.openFaule": "OpenFault",
  "ccprovision.recorddrive.config.sftp.port": "No da porta",
  "provision.tenantspace.table.videoAgentChannels": "Quantidade de canal do Agente de V\u00EDdeo (> = Duas vezes a Quantidade do Agente de V\u00EDdeo)",
  "provision.callcenterwhitelist.regAddr": "Endere\u00E7o de retorno de chamada",
  "chat.session.label.evaluationTime": "Tempo de avalia\u00E7\u00E3o",
  "provision.callcenterinstanceslist.table.nodename": "Nome do n\u00F3",
  "provision.tenantspace.smartAgentUsage.iszero": "Configure o agente primeiro.",
  "chat.skill.label.skill": "Habilidade",
  "chat.session.channel.facebook": "Facebook",
  "provision.tenant.management.partdbId": "ID da parti\u00E7\u00E3o do banco de dados",
  "chat.agent.label.waiting": "Aguardando:",
  "chat.skill.message.failDeleteSkill": "Falha ao excluir a habilidade.",
  "provision.tenantspace.config.softphone.using": "n\u00FAmero de softphone {0} est\u00E1 em uso.",
  "provision.accesscode.table.mediatypeId": "Tipo de m\u00EDdia",
  "provision.whiteListAddress.CustomerMenu": "Menus personalizados",
  "provision.callcenterinstanceslist.vcallcenter.ctiInfo": "ID do grupo de recursos do CTI",
  "provision.callcent.label.finish": "Conclu\u00EDdo",
  "provision.ccworknos.table.ccworkStartNo": "Iniciar ID do Funcion\u00E1rio",
  "provision.whiteListAddress.mobileagenturl": "Retorno de chamada de atendimento ao cliente m\u00F3vel",
  "provision.whiteListAddress.resourcedump.obs": "Despejo de Recursos para o Servidor OBS",
  "provision.whiteListAddress.resourcedump.obsproxy": "Agente do Servidor do OBS de Despesa de Recursos",
  "provision.authconfig.authdescription": "Insira a descri\u00E7\u00E3o da ID do desenvolvedor.",
  "chat.weChatConfig.message.unknown": "Configure status is UNKNOWN!",
  "provision.ccworknos.table.ccworkEndNo": "ID do funcion\u00E1rio final",
  "chat.webconfig.label.basesettings": "Configura\u00E7\u00F5es b\u00E1sicas",
  "ccprovision.vcallecenter.config.popup.tab.administrator.modifypassword": "Modificar senha do administrador",
  "provision.tenantspace.cc.videoIvrchannels": "Quantidade de Canais IVR de v\u00EDdeo",
  "provision.callcenterinstanceslist.softPhone.softPhoneNum": "N\u00FAmero do Softphone",
  "provision.tenantspace.cc.videoCallnumUsagePercent": "Uso simult\u00E2neo de chamadas de v\u00EDdeo",
  "chat.weChatConfig.label.state": "Estado",
  "provision.callcenterinstanceslist.vcallcenter.faultEnable": "Falaha do prompt de voz",
  "provision.system.error": "O sistema est\u00E1 anormal. Tente novamente mais tarde.",
  "chat.client.evaluate.message": "Por favor\, avalie o servi\u00E7o para mim. Muito obrigado!",
  "ccprovision.IVRSprogress.config.operate": "Opera\u00E7\u00E3o",
  "provision.callcenterinstanceslist.vcallcentersftp.passLengthValidateFailed": "A senha deve conter 8 a 20 caracteres",
  "provision.callcenterinstanceslist.softPhone.passLengthValidateFailed": "A senha deve conter 8 a 16 caracteres.",
  "provision.tenantspace.passwordValidatefaild": "Falha ao verificar a senha.",
  "provision.tenant.management.button.ok": "Conclu\u00EDdo",
  "provision.tenantspace.config.choose.agent.registration.server.type": "Tipo de servidor de registro do agente",
  "provision.callcent.error.updateerrorinfo": "O c\u00F3digo de acesso foi associado a um call center virtual e n\u00E3o pode ser editado.",
  "provision.tenantspace.config.error.chooseworkNo.noagent": "Nenhum recurso de agente selecionado\, n\u00E3o h\u00E1 necessidade de atribuir o n\u00FAmero de trabalho",
  "provision.accesscode.table.accessCode.selecttype": "Selecione um tipo",
  "provision.tenantspace.config.agentusage.vidoeusage.ivr": "Quantidade do Agente de Voz\, Quantidade do Agente de V\u00EDdeo\, Quantidade do Agente Multim\u00EDdia\, Quantidade do Canal de \u00C1udio IVR e Quantidade do Canal de V\u00EDdeo IVR n\u00E3o podem ser zero",
  "provision.tenantspace.error.deleteProvision": "Falha ao excluir o espa\u00E7o do Tenant Provisionar.",
  "provision.callcenterinstance.softPhone.validatebeginSoftPhoneNum": "O n\u00FAmero inicial do softphone n\u00E3o pode ser maior do que o n\u00FAmero final",
  "provision.callcenterinstanceslist.vcallcenter.open": "Abrir",
  "provision.callcent.error.deleteccgateway.associationvcallcener": "O servidor CC-Gateway selecionado est\u00E1 associado ao tenant e n\u00E3o pode ser exclu\u00EDdo.",
  "provision.encryptinfo.update": "Atualizar",
  "provision.encryptinfo.metadata": "Criptografia e descriptografia de metadados",
  "provision.callcenterinstance.accesscode.validateaccesscode": "Insira um segmento de n\u00FAmero v\u00E1lido.",
  "provision.encryptinfo.email": "Criptografia e descriptografia de conte\u00FAdo de e-mail",
  "provision.callcent.error.paramUnique": "O par\u00E2metro exclusivo existe.",
  "provision.callcenterinstanceslist.config.nodeIdCheckError": "Certifique-se de que a ID de informa\u00E7\u00F5es do n\u00F3 CTI inserida\, o uapId e o nome sejam exclusivos.",
  "provision.tenantspace.error.deleteDDS": "Falha ao excluir o espa\u00E7o do Tenant DDS.",
  "provision.tenantspace.config.softphoneassign.chooseworknoscope.paramserror": "As informa\u00E7\u00F5es de n\u00FAmero est\u00E3o incorretas.",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.addmaxlimit": "Cinco grupos de n\u00FAmeros foram adicionados.",
  "provision.callcenterinstanceslist.table.gwIp": "Endere\u00E7o IP do CC-Gateway",
  "provision.callcenterinstanceslist.table.agentMaxCapacity": "Capacidade do sistema do agente",
  "provision.callcenterinstanceslist.table.resourceinfo": "Informa\u00E7\u00F5es sobre recursos",
  "chat.agentConsole.message.error.notification": "!!!N\u00E3o \u00E9 poss\u00EDvel criar notifica\u00E7\u00E3o para nova mensagem\, verifique a configura\u00E7\u00E3o do seu browser Chrome!!!",
  "provision.callcent.error.regaddrlength": "Endere\u00E7o de registro \u00E9 muito longo.",
  "provision.callcent.selectsoftnumber": "Selecione um n\u00FAmero de softphone.",
  "provision.tenantspace.select.status": "Selecione um estado.",
  "provision.callcenterinstanceslist.table.asrchannelnums": "Inst\u00E2ncias do ASR",
  "provision.tenantspace.config.select.record": "Selecione uma unidade de grava\u00E7\u00E3o.",
  "provision.callcent.error.partdeleteinfo": "O conte\u00FAdo associado a um call center virtual n\u00E3o pode ser exclu\u00EDdo.",
  "chat.client.label.promotions": "Promo\u00E7\u00F5es",
  "provision.tenantspace.agentinfo.deletefail.modifyvdn.onwas": "Falha ao conectar-se \u00E0 plataforma WAS para modificar o VDN.",
  "provision.tenantspace.config.maxCalls": "Chamadas de voz simult\u00E2neas",
  "provision.callcenterinstanceslist.vcallcenter.videoAgentChannels": "VideoAgentchannels",
  "chat.session.channel.web": "Web",
  "provision.accesscode.table.acId": "N\u00FAmero do c\u00F3digo de acesso",
  "provision.tenantspace.config.worknoassign.showchoosedworkno.explain": "Nota: Voc\u00EA precisa de um total de {0} n\u00FAmeros\, e {1} n\u00FAmeros foram selecionados. O sistema atribuir\u00E1 n\u00FAmeros a voc\u00EA em sequ\u00EAncia.",
  "provision.tenantspace.config.chooseDriveTime": "Per\u00EDodo de reten\u00E7\u00E3o de grava\u00E7\u00E3o",
  "provision.callcenterinstanceslist.softPhone.usedselect.mobileagent": "Departamento do centro de atendimento ao cliente",
  "provision.tenantspace.refresh": "Atualizar",
  "provision.callcenterinstanceslist.softPhone.batchadd": "Adi\u00E7\u00E3o em lote",
  "provision.tenantspace.feature.intelligent.inspection": "Inspe\u00E7\u00E3o inteligente",

  "provision.tenantspace.feature.speechAnalysis": "An\u00E1lise de texto",
  "provision.tenantspace.feature.speechAnalysis.description": "Ativar a an\u00E1lise de texto. A sala suporta an\u00E1lise de texto e fala.",

  "provision.callcenterinstanceslist.whiteNumber": "N\u00FAmeros na lista de confian\u00E7a",
  "provision.tenantspace.selectStastus.initialize": "Inicializando",
  "provision.callcent.selectobsserver": "Selecione um servidor OBS.",
  "chat.skill.label.name": "Nome",
  "provision.tenantspace.gw.name": "Nome do servidor de gateway",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.workNocontain": "A ID de trabalho foi selecionada. Insira outro.",
  "provision.callcenter.sip.alert": "O valor deve ser o mesmo que a capacidade real suportada pelo UAP e SBC.",
  "provision.tenantspace.cc.ivrchannels": "Quantidade de Canais IVR de \u00E1udio",
  "provision.tenantspace.passValidateFailed": `Deve conter letras mai\u00FAsculas\, letras min\u00FAsculas\, d\u00EDgitos e caracteres especiais -!{'@'}#$%&*()-_=+[{}];:""\,<.>/?`,
  "provision.callcent.label.cancel": "Cancelar",
  "provision.softphone.label.issyncuap.yes": "SIM",
  "chat.agent.message.changeFailed": "Falha ao alterar o status do agente!",
  "provision.recorddrive.selectrecorddrive.singledeleteconfim": "Tem certeza de que deseja excluir a letra de unidade selecionada? Certifique-se de que a letra da unidade n\u00E3o esteja ocupada pelo Tenant antes de continuar.",
  "provision.callcenterinstanceslist.softPhone.usedselect.fixedagent": "N\u00FAmero do agente",
  "chat.session.channel.twitter": "Twitter",
  "chat.agentConsole.message.content.video": "[Mensagem de v\u00EDdeo]",
  "provision.tenantspace.config.select.default.phone.in.selectAccesscodes": "O n\u00FAmero chamador padr\u00E3o deve estar dentro do c\u00F3digo de acesso selecionado.",
  "chat.client.label.input": "Introduza suas palavras",
  "chat.agentConsole.message.content.connectMessage": "Tem uma nova mensagem!",
  "provision.callcenterinstanceslist.table.eMediaAgentCurCapacity": "Quantidade de Agente Multim\u00EDdia",
  "provision.callcenterinstanceslist.table.search": "Consulta",
  "provision.tenantspace.agentusage.plus.videousage": "A soma de agentes de voz e agentes de v\u00EDdeo n\u00E3o pode exceder 10000.",
  "provision.callcent.error.deletesoftphonenumber.associationvcallcener": "O n\u00FAmero de telefone soft selecionado est\u00E1 associado ao Tenant e n\u00E3o pode ser exclu\u00EDdo.",
  "provision.authconfig.updateConfim": "confirma\u00E7\u00E3o de atualiza\u00E7\u00E3o",
  "promotion.tenant.management.tenantedit.exception": "Exce\u00E7\u00E3o no servidor.",
  "provision.callcenterinstanceslist.config.saveAndnextStep": "Salvar e Pr\u00F3ximo",
  "provision.callcenterinstanceslist.config.odfsappsercret": "AppSercret ODFS",
  "provision.callcenterinstanceslist.resource.node.notexist": "Informa\u00E7\u00F5es sobre n\u00F3 n\u00E3o existem.",
  "provision.callcenterinstanceslist.obsServer.progId": "ID do Prog",
  "provision.callcent.error.errorinfo101": "Falha ao atualizar o CCM.",
  "provision.callcent.error.deleteobsserver.part.associationvcallcener": "Alguns dos servidores obs selecionados est\u00E3o associados ao Tenant e n\u00E3o podem ser exclu\u00EDdos.",
  "provision.tenant.management.pool.ctinode.id": "ID do n\u00F3",
  "ccprovision.IVRSprogress.config.logincti.failed": "Falha ao conectar \u00E0 plataforma.",
  "provision.tenantspace.config.tenant.custdata.featurevalue.not.one": "O valor do recurso de personaliza\u00E7\u00E3o de dados alocado ao tenant n\u00E3o \u00E9 1.",
  "provision.tenantspace.ivr.ivrId": "ID IVR",
  "provision.tenant.management.tenantcreate.name.exist": "O nome do tenant j\u00E1 existe",
  "provision.tenant.management.tenantcreate.adminaccount.exist": "A conta de administrador j\u00E1 existe",
  "provision.tenantspace.config.isAutoAssignWorkNo": "Atribuir ID automaticamente ao VCC",
  "provision.callcent.selectucsserver": "Selecione o servidor CCUCS.",
  "provision.tenantspace.create.applyAccount": "Registre um administrador para o locat\u00F3rio.",
  "provision.encryptinfo.updateInterval.error": "O intervalo para atualiza\u00E7\u00E3o da chave n\u00E3o pode ser inferior a 30 dias.",
  "provision.callcent.error.setinfo": "Falha ao definir.",
  "provision.tenantspace.selectCC": "Selecione um call center.",
  "provision.tenantspace.config.applySource": "Aplicar recurso",
  "chat.webconfig.label.intergratemsg": "Para integrar chat ao vivo em seu site",
  "provision.tenantspace.feature.outboundCallMarketingSupport": "chamada originada Inteligente",
  "provision.callcenterinstanceslist.label.ivrchannelUseRate": "Uso do canal de IVR de \u00E1udio",
  "chat.session.label.createdTime": "Created Time",
  "provision.tenantspace.config.chooseAgentRegistrationServer": "Selecione um servidor de registro de agente",
  "provision.tenantspace.agentinfo.message.deletefail.InProgress": "O tenant est\u00E1 processando o neg\u00F3cio atualmente\, tente novamente mais tarde!",
  "chat.session.label.endReason": "Motivo final",
  "provision.callcenterinstances.agentWasPassLengthValidateFailed": "A senha deve conter de 8 a 32 caracteres.",
  "provision.callcenterinstanceslist.table.maxVideoCallnums": "M\u00E1x. chamadas de v\u00EDdeo simult\u00E2neas",
  "provision.callcent.platformserver.ccbms": "Sistema de Manuten\u00E7\u00E3o e Opera\u00E7\u00E3o de Comunica\u00E7\u00E3o em Nuvem (CCBMS)",
  "provision.callcenterinstanceslist.config.serviceIP": "IP Nginx",
  "provision.callcenterinstanceslist.config.createfailed": "Falha ao criar um call center.",
  "provision.authconfig.update": "Atualizar",
  "ccprovision.recorddrive.config.sftp.ipadress": "Endere\u00E7o IP SFTP",
  "ccprovision.vcallecenter.config.popup.enternewpassword": "Digite a nova senha.",
  "provision.callcenterinstanceslist.config.workAlreadyOccupied": "A ID do funcion\u00E1rio est\u00E1 em uso",
  "provision.callcent.success.save": "Sucesso",
  "provision.callcenterinstanceslist.sipServer.uapIp": "IP do UAP",
  "provision.tenantspace.config.param.error.agentCall": "Insira o n\u00FAmero de chamadas de voz simult\u00E2neas.",
  "ccprovision.recorddrive.config.recorddrivenameconfig": "Configura\u00E7\u00E3o do volume",
  "provision.authconfig.appkey": "ID do aplicativo",
  "provision.callcenterinstanceslist.table.ccId": "ID da central de atendimento",
  "provision.callcenterinstanceslist.config.maxVideoCallnumsValidate": "O n\u00FAmero m\u00E1ximo de chamadas de v\u00EDdeo simult\u00E2neas \u00E9 menor do que o n\u00FAmero de chamadas de v\u00EDdeo realmente alocadas pelo centro de chamadas.",
  "provision.callcent.error.updateucsserver.ondatabase": "Falha ao modificar o servidor CCUCS no banco de dados.",
  "provision.tenantspace.config.error.chooseworkNo.noaddagent": "Nenhum recurso de agente adicionado\, nenhuma necessidade de atribuir o n\u00FAmero de trabalho",
  "provision.callcenter.resourceinfo.alert": "As informa\u00E7\u00F5es de recurso a seguir devem ser consistentes com as informa\u00E7\u00F5es de licen\u00E7a de dispositivos como UAP e CTI.",
  "provision.tenantspace.statusRecordTime.24Month": "24 meses",
  "provision.tenantspace.config.error.142": "O APPCube n\u00E3o deve ser ativado ao abrir a tarefa de chamada do APP",
  "provision.tenantspace.status.invalid.fail": "Falha ao revogar.",
  "provision.tenantspace.sip.searchName": "Insira o nome do servidor SBC a ser consultado.",
  "provision.callcenterinstanceslist.softPhone.singledeleteconfim": "Tem certeza de que quer excluir os n\u00FAmeros do softphone selecionados? Certifique-se de que os n\u00FAmeros do softphone n\u00E3o estejam associados a nenhuma central de atendimento virtual.",
  "provision.tenantspace.intelligent.agent": "Agente inteligente",
  "provision.tenant.management.please.select": "Selecionar\.\.\.",
  "provision.tenantspace.config.fail.modify.tenant.auth.use.sumInterface": "Falha ao modificar as permiss\u00F5es do Tenant na chamada para a interface SUM.",
  "provision.callcenterinstanceslist.vcallcenterDeatil.whiteNumValidateLength": "O comprimento do n\u00FAmero da lista de confian\u00E7a varia de 1 a 32.",
  "provision.callcent.error.accesscode.deletefail.part.onccgateway.with.associationvcallcener": "A exclus\u00E3o parcial falhou porque parte do c\u00F3digo de acesso est\u00E1 associada ao Tenant e parte do c\u00F3digo de acesso falhou ao ser exclu\u00EDda na plataforma era.",
  "promotion.tenant.management.tenantedit.softnumber.insufficient": "O n\u00FAmero de softphones \u00E9 insuficiente.",
  "chat.session.label.search": "Pesquisar",
  "chat.session.label.sessionId": "ID da sess\u00E3o",
  "provision.callcenterinstanceslist.sipServerUpdate.failed": "O endere\u00E7o IP ou n\u00FAmero da porta n\u00E3o cumpre a regra.",
  "chat.skill.label.deleteSkills": "Excluir",
  "provision.callcent.label.submit": "Enviar",
  "provision.tenant.management.adminpasswordagain": "Confirmar senha",
  "provision.tenantspace.agentinfo.message.smartagent.iszero": "\, nenhum agente de \u00E1udio est\u00E1 dispon\u00EDvel. Desative o recurso de agente inteligente.",
  "provision.tenantspace.table.createStatus": "Novo estado",
  "provision.callcenterinstanceslist.table.curCapacityProportion": "Propor\u00E7\u00E3o de capacidade atual",
  "provision.grayconfig.paramIsError": "O par\u00E2metro de entrada est\u00E1 vazio ou incorreto",
  "provision.callcenterinstanceslist.config.adminWorkAlreadyOccupied": "A ID do funcion\u00E1rio do administrador est\u00E1 em uso",
  "provision.tenantspace.language.zh": "Chin\u00EAs",
  "chat.session.label.allEndType": "Todos os tipos de extremidade",
  "provision.tenantspace.config.tenant.intelligent.inspection.featurevalue.not.one": "O valor do recurso de inspe\u00E7\u00E3o inteligente alocado ao tenant n\u00E3o \u00E9 1.",
  "provision.callcent.error.repetenter": "O n\u00FAmero foi usado. Insira outro n\u00FAmero novamente.",
  "provision.tenantspace.agentinfo.agentstatus.online": "Online",
  "provision.softPhone.label.uapaddress": "Endere\u00E7o do UAP",
  "provision.callcenterinstanceslist.softPhone.stautus.unoccupied": "N\u00E3o utilizada",
  "provision.callcenterinstanceslist.sipServer.name": "Nome",
  "chat.usefulexpressions.message.saveFail": "Falha no salvamento da express\u00E3o.",
  "provision.callcenterinstanceslist.vcallcenter.videoIvrchannels": "Canais VideoIVR",
  "chat.session.label.reset": "Redefinir",
  "provision.tenantspace.config.worknoassign.chooseworkNo": "Selecionar n\u00FAmero",
  "chat.usefulexpressions.message.failDeleteExpression": "Falha ao excluir a express\u00E3o.",
  "provision.callcenterinstanceslist.vcallcenter.agentNumErrorListPromt": "Existe um call center virtual cujo n\u00FAmero de lugares \u00E9 inconsistente com o VDN na WAS. O VccId \u00E9",
  "provision.tenantspace.agentinfo.label.workNo": "N\u00FAmero do agente",
  "chat.agent.title.createCase": "Criar caso",
  "provision.tenantspace.chooseCommercial": "Status comercial",
  "provision.tenantspace.config.need.wertcsipserver": "Para ativar o recurso WebRTC precisa selecionar um servidor de registro de agente WebRTC.",
  "promotion.tenant.management.tenantedit.accesscode.noavailable": "O c\u00F3digo de acesso n\u00E3o est\u00E1 dispon\u00EDvel.",
  "chat.client.label.login": "Log-in",
  "provision.callcent.success.delete": "Exclus\u00E3o realizada com sucesso.",
  "provision.callcenterinstanceslist.vcallcenter.maxCalls": "M\u00E1ximo de chamadas",
  "provision.callcenterinstanceslist.config.resourceError": "As informa\u00E7\u00F5es de recurso modificadas s\u00E3o menores do que o n\u00FAmero real de call centers.",
  "provision.callcenterinstanceslist.softPhone.stautus.select": "Selecione o status",
  "provision.callcenterinstanceslist.obsServer.heartbeatPort": "Porta de batimento card\u00EDaco",
  "provision.callcent.selectccgatewayservers": "Selecione um servidor CC-Gateway.",
  "provision.callcenterinstanceslist.vcallcenterDeatil.sipServerIpAndPort": "Endere\u00E7o SBC",
  "provision.callcenter.ucs.ip.alert": "Endere\u00E7o de balanceamento de carga do cluster do CCUCS",
  "provision.callcenterinstanceslist.obsServer.obsName": "Nome do OBS",
  "provision.tenantspace.cc.maxcallnums": "M\u00E1x. chamadas de voz simult\u00E2neas",
  "chat.skill.label.priority": "Prioridade",
  "provision.tenantspace.statusRecordTime.3Month": "3 meses",
  "provision.callcent.selectsipserver.inconsistenttype": "Tipo de servidor de registro do agente inconsist\u00EAncia",
  "chat.session.channel.wechat": "WeChat",
  "provision.callcenterinstanceslist.vcallcenter.ttschannelnums": "N\u00FAmeros dos Canais TTS",
  "provision.callcenterinstanceslist.softPhone.stautus.occupied": "Em uso",
  "ccprovision.recorddrive.config.sftp.ipadressport": "Porta do servidor SFTP",
  "provision.tenantspace.create": "Criar",
  "provision.callcent.error.deleteinfo": "Falha na exclus\u00E3o.",
  "provision.tenantspace.table.config": "Configurar",
  "chat.weChatConfig.label.qcode": "Configure by scanning QCode",
  "chat.skill.label.ok": "OK",
  "chat.weChatConfig.label.delete": "Excluir",
  "provision.tenantspace.gw.idleRegAgentNum": "Quantidade de Agente Registrado Concorrente Restante",
  "provision.callcenterinstanceslist.config.softUsage.more": "O n\u00FAmero de softphones \u00E9 insuficiente. Verifique a configura\u00E7\u00E3o.",
  "provision.callcenterinstanceslist.config.videocallnumsError": "O n\u00FAmero m\u00E1ximo de chamadas de v\u00EDdeo simult\u00E2neas deve ser maior ou igual ao n\u00FAmero de assentos de v\u00EDdeo.",
  "provision.ccworknos.table.vCCInstId": "Centro de chamada virtual",
  "provision.callcenterinstanceslist.recorddrive.Title": "Carta da unidade de grava\u00E7\u00E3o",
  "chat.skill.message.delete": "Excluir",
  "provision.tenantspace.chooseobsdatasource.callCenterDbSource": "Fonte de dados da central de atendimento",
  "chat.webconfig.label.windowTitle.placeholder": "Insira palavras que ser\u00E3o exibidas no t\u00EDtulo da janela de bate-papo.",
  "provision.tenantspace.resetpwdfaild.tenant.not.exist": "Falha ao alterar a senha porque o espa\u00E7o do Tenant n\u00E3o existe.",
  "provision.callcent.error.createaccesscode.n400Numbervalidate": "N\u00E3o pode haver uma sobreposi\u00E7\u00E3o parcial entre c\u00F3digos de acesso e n\u00FAmeros de servi\u00E7o.",
  "provision.tenantspace.cc.asrchannelUsagePercent": "Uso de ASR",
  "provision.tenantspace.cc.videoIvrchannelUsagePercent": "Uso do canal de IVR de v\u00EDdeo",
  "provision.authconfig.connected.with.tenantspace": "este ID de APP est\u00E1 vinculado a um espa\u00E7o de tenant\, n\u00E3o pode excluir.",
  "provision.tenantspace.config.ivrserver.select.confirm.message": "Os processos IVR selecionados s\u00E3o implantados no mesmo servidor\, o que pode causar um \u00FAnico ponto de falha. Tem certeza de que deseja continuar?",
  "provision.accesscode.table.n400Number": "N\u00FAmero do servi\u00E7o",
  "provision.tenantspace.feature.smsOrEmailMarketingSupport.sendMaxNumber.placeholder": "O valor varia de 1 a 1000000000. O valor 0 indica que nenhum limite foi especificado.",
  "provision.callcenterinstance.config.maxcallnumsCompareValidate": "O n\u00FAmero m\u00E1ximo de chamadas de voz simult\u00E2neas deve ser maior ou igual \u00E0 soma do agente de voz e dos canais IVR de \u00E1udio e v\u00EDdeo.",
  "chat.webconfig.message.saveFail": "Falha ao salvar a configura\u00E7\u00E3o.",
  "provision.callcent.error.savesipserverdesinfo": ". Certifique-se de que o nome do servidor sbc n\u00E3o existe.",
  "chat.skill.message.createSuccess": "Habilidade cria sucesso.",
  "chat.skill.message.priorityLength": "Certifique-se do comprimento da prioridade <= 5.",
  "provision.tenant.management.updatetenant.failed": "Falha ao modificar a configura\u00E7\u00E3o do espa\u00E7o do Tenant.",
  "provision.tenantspace.error.deleteSUM": "Falha ao excluir o espa\u00E7o do Tenant SUM.",
  "provision.tenantspace.intelligent.ivr": "IVR inteligente",
  "provision.tenant.management.tenantcreate.expiryDate": "Data de expira\u00E7\u00E3o",
  "provision.tenantspace.save.fail.079": "Falha ao analisar informa\u00E7\u00F5es de usu\u00E1rio local ou externo ao configurar o sistema de autentica\u00E7\u00E3o de terceiros.",
  "provision.recorddrive.error.newrecord.repeated": "N\u00E3o \u00E9 poss\u00EDvel adicionar o mesmo nome de letra da unidade sob o mesmo call center",
  "provision.callcenterinstanceslist.vcallcenter.SIPServer": "Servidor SBC",
  "provision.tenantspace.authenticationsystem.enter.clientName": "Informe o nome de um sistema de autentica\u00E7\u00E3o de terceiros.",
  "provision.tenantspace.agentinfo.message.selectagent": "Selecione um agente!",
  "provision.tenantspace.popuppageurl.whiteListAdd": "Adicionar endere\u00E7o da lista de permiss\u00F5es",
  "provision.tenantspace.authenticationsystem.mappingName": "Regra de mapeamento de Federa\u00E7\u00E3o",
  "provision.callcenterinstanceslist.vcallcenter.close": "Fechar",
  "provision.callcenterinstanceslist.table.versatileAgentCurCapacity": "Agentes vers\u00E1teis",
  "provision.callcenterinstanceslist.label.callnumUseRate": "Uso simult\u00E2neo de chamadas de voz",
  "provision.whiteListAddress.AddressExist": "A lista de confian\u00E7a de endere\u00E7os j\u00E1 existe.",
  "provision.callcenterinstanceslist.table.allowedTTSAndASRCallNum": "N\u00FAmero de canais IVR inteligentes",
  "chat.skill.label.skillId": "ID de habilidade",
  "ccprovision.vcallecenter.sucess.updateinfo.adminpassword": "Modificar a senha com sucesso.",
  "provision.callcenterinstanceslist.config.servicePort": "Porta Nginx",
  "provision.callcenterinstanceslist.config.maxcallnumsValidate": "O n\u00FAmero m\u00E1ximo de chamadas de voz simult\u00E2neas \u00E9 menor do que o n\u00FAmero de chamadas de voz realmente atribu\u00EDdas pelo centro de chamadas.",
  "chat.session.endType.agentEnd": "Fim do Agente",
  "provision.tenantspace.authenticationsystem.clientURL": "URL do sistema de autentica\u00E7\u00E3o de terceiros",
  "provision.callcenterinstanceslist.obsServer.standbyObsDirectHeartbeatIp": "Especifica o sinal do endere\u00E7o IP do OBS em standby",
  "provision.encryptinfo.scenario": "Cen\u00E1rio do aplicativo",
  "provision.tenantspace.save.fail.067": "Falha ao validar os dados do sistema de autentica\u00E7\u00E3o de terceiros.",
  "provision.tenantspace.save.fail.062": "O c\u00F3digo de acesso multim\u00EDdia deve ser configurado quando o c\u00F3digo de acesso do click-to-dial estiver configurado.",
  "provision.callcenterinstanceslist.vcallcenter.newvccId": "VccInstId",
  "provision.tenantspace.save.fail.063": "Falha ao excluir o processo IVR.",
  "provision.callcenterinstanceslist.sipServer.maxRegAgentNum": "N\u00FAmero de solicita\u00E7\u00F5es simult\u00E2neas",
  "provision.ccworknos.table.ccworknos": "ID do funcion\u00E1rio",
  "provision.callcenterinstanceslist.ucsServer.singledeleteconfim": "Tem certeza de que deseja excluir o servidor CCUCS selecionado?",
  "provision.tenant.management.email": "Email",
  "provision.callcenterinstanceslist.obsServer.singledeleteconfim": "Tem certeza de que deseja excluir o servidor OBS selecionado?",
  "provision.callcenterinstanceslist.vcallcenter.nodepolicy": "Configura\u00E7\u00E3o da Pol\u00EDt\u00EDca de N\u00F3 de Sele\u00E7\u00E3o de Recurso de Sa\u00EDda",
  "provision.callcenterinstanceslist.vcallcenter.accessCode": "C\u00F3digo de acesso",
  "provision.callcenterinstanceslist.nodepolicy.VNDId": "VDN N\u00FAmeros",
  "provision.callcenterinstanceslist.nodepolicy.tenantspacename": "VDN Nome",
  "provision.nodepolicy.sync.success": "Sincroniza\u00E7\u00E3o bem-sucedida",
  "provision.callcenterinstanceslist.nodepolicy.save": "salvar",
  "provision.nodepolicy.sync.failed": "Falha na sincroniza\u00E7\u00E3o",
  "provision.nodepolicy.save.success": "Gravado com sucesso",
  "provision.callcenterinstanceslist.table.nodepolicyconfirmmessage": "Tem certeza de que deseja sincronizar as configura\u00E7\u00F5es do NODE POLICY?",
  "provision.tenant.management.akinfo": "Informa\u00E7\u00F5es da chave do aplicativo",
  "provision.tenantspace.sip.idleRegAgentNum": "Quantidade de Agente Registrado Concorrente Restante",
  "provision.tenantspace.save.fail.058": "Falha ao salvar os dados.",
  "provision.tenantspace.save.fail.057": "O recurso alocado ao espa\u00E7o do tenant \u00E9 anormal.",
  "provision.nodepolicy.save.failed": "Falha ao salvar",
  "provision.tenantspace.agentinfo.agentstatus": "Status do agente",
  "provision.tenantspace.save.fail.056": "Informa\u00E7\u00F5es b\u00E1sicas incorretas sobre o espa\u00E7o do Tenant.",
  "provision.tenantspace.save.fail.055": "Existem tarefas pendentes no espa\u00E7o do Tenant.",
  "provision.tenantspace.save.fail.054": "Falha ao alocar manualmente recursos de n\u00FAmero de softphone.",
  "provision.tenantspace.save.fail.053": "A fonte de dados OBS n\u00E3o existe.",
  "provision.tenantspace.save.fail.052": "O servidor OBS n\u00E3o existe.",
  "provision.grayconfig.deleteconfim": "Tem certeza de que deseja excluir a configura\u00E7\u00E3o cinza?",
  "provision.tenantspace.save.fail.050": "O n\u00FAmero de IDs de agente selecionados \u00E9 menor do que o n\u00FAmero de novos agentes.",
  "provision.tenantspace.status.active.fail": "Falha na ativa\u00E7\u00E3o.",
  "provision.tenantspace.save.fail.051": "A fonte de dados OBS n\u00E3o est\u00E1 alocada.",
  "provision.callcenterinstanceslist.table.agentCurCapacity": "Quantidade do Agente de Voz",
  "provision.callcent.platformserver.ccbcs": "Servi\u00E7o de Conex\u00E3o de Agente do Centro de Contato (CCBCS)",
  "provision.tenant.management.administratorinfo": "Informa\u00E7\u00F5es do administrador do tenant",
  "ccprovision.IVRSprogress.config.progressNo": "ID do processo",
  "provision.callcenterinstanceslist.resource.associationvcallcener.updatenode.failed": "Os tenants s\u00E3o atribu\u00EDdos\, os n\u00F3s n\u00E3o podem ser modificados.",
  "chat.session.label.enterQueueTime": "Inserir Tempo de Fila",
  "chat.weChatConfig.message.emptyDeleteArray": "Selecione itens de configura\u00E7\u00E3o a serem exclu\u00EDdos!",
  "provision.tenantspace.config.param.error.ttsNumber": "Insira a quantidade TTS.",
  "provision.tenantspace.cc.asrchannelnums": "Inst\u00E2ncias do ASR",
  "chat.skill.label.maxQueueTime": "MaxQueueTime(s)",
  "provision.tenantspace.selectStastus.edit.active": "Ativo",
  "provision.callcenterinstanceslist.IVRSprogress": "Processo IVR",
  "provision.tenantspace.save.fail.048": "O segmento ID do agente alocado manualmente cont\u00E9m as IDs do agente alocadas.",
  "provision.callcenterinstanceslist.config.odfconfigerror": "As informa\u00E7\u00F5es de configura\u00E7\u00E3o do servi\u00E7o ODFS est\u00E3o incorretas.",
  "provision.tenantspace.save.fail.049": "O n\u00FAmero exclu\u00EDdo n\u00E3o atende aos requisitos.",
  "provision.tenantspace.save.fail.046": "Falha ao excluir o agente porque a plataforma WERA est\u00E1 inacess\u00EDvel.",
  "provision.tenantspace.save.fail.045": "Os recursos do processo IVR s\u00E3o insuficientes.",
  "provision.tenantspace.save.fail.047": "O servidor CC-Gateway n\u00E3o tem recursos suficientes.",
  "provision.tenantspace.save.fail.043": "N\u00FAmero de aplica\u00E7\u00F5es para agentes multim\u00EDdia maior que o n\u00FAmero de agentes multim\u00EDdia restantes.",
  "provision.tenantspace.save.fail.041": "A soma dos canais de voz\, v\u00EDdeo\, multim\u00EDdia e agentes vers\u00E1teis e de \u00E1udio e v\u00EDdeo IVR deve ser maior que 0.",
  "provision.tenantspace.save.fail.044": "Configure o c\u00F3digo de acesso para o tipo de \u00E1udio e v\u00EDdeo.",
  "provision.tenantspace.feature.smsMarketingSupport.description": "Esse recurso fornece fun\u00E7\u00F5es de marketing SMS para o espa\u00E7o do tenant.",
  "provision.callcenterinstanceslist.softPhone.newpasswordconf": "Repita nova senha",
  "provision.tenantspace.save.fail.040": "Configure o c\u00F3digo de acesso para o tipo multim\u00EDdia.",
  "provision.tenant.no.more.agentUsage": "O n\u00FAmero de agentes \u00E9 muito grande.",
  "provision.softPhone.label.isSyncUap": "Sincronizar UAP",
  "provision.callcent.error.updatesipserver.noexist": "O servidor SIP n\u00E3o existe.",
  "provision.tenantspace.commercialStatus": "Se um site comercial",
  "provision.callcent.selectvcallcent": "Selecione um call center virtual.",
  "provision.tenantspace.common.ivr.explain": "O IVR convencional fornece fun\u00E7\u00F5es b\u00E1sicas\, como reprodu\u00E7\u00E3o\, recebimento e TTS. O n\u00FAmero de canais TTS deve ser configurado.",
  "provision.callcenterinstanceslist.obsServer.activeObsDirectHeartbeatIp": "Especifica o sinal do endere\u00E7o IP do OBS ativo",
  "provision.callcent.error.saveucsserverdesinfo": "Falha na cria\u00E7\u00E3o. Certifique-se de que o nome do servidor CCUCS ainda n\u00E3o exista.",
  "ccprovision.vcallecenter.config.popup.authentication": "Verificar identidade",
  "provision.tenantspace.table.ivrchannels": "Quantidade de Canais IVR de \u00E1udio",
  "provision.tenantspace.save.fail.032": "O n\u00FAmero de agentes aplicados n\u00E3o pode exceder 10.000.",
  "provision.tenantspace.save.fail.031": "Redu\u00E7\u00E3o do tenant\, por favor operar na p\u00E1gina de informa\u00E7\u00F5es do agente.",
  "chat.session.title.basic": "Informa\u00E7\u00F5es B\u00E1sicas da Sess\u00E3o",
  "provision.tenantspace.save.fail.030": "O n\u00FAmero do softphone n\u00E3o \u00E9 suficiente.",
  "provision.callcenterinstanceslist.config.videoIvrchannelsValidate": "O n\u00FAmero de canais de v\u00EDdeo IVR \u00E9 menor do que o n\u00FAmero de canais realmente alocados no call center.",
  "chat.client.label.yes": "SIM",
  "provision.tenantspace.gw.port": "Porta do servidor de gateway",
  "provision.callcenterinstanceslist.sipServer.batchdeleteconfim": "Tem certeza de que quer excluir todos os servidores de registro selecionados?",
  "provision.callcent.error.accesscode.deletefail.sqlexception": "Falha ao excluir o c\u00F3digo de acesso porque ocorreu uma exce\u00E7\u00E3o ao executar uma instru\u00E7\u00E3o SQL.",
  "provision.callcent.error.createaccesscode.validate.accessCodeContainAccesscode": "O c\u00F3digo de acesso n\u00E3o pode conter uma rela\u00E7\u00E3o de inclus\u00E3o com outros c\u00F3digos de acesso sob o call center.",
  "chat.client.button.submit": "Enviar",
  "provision.ivr.error.cannotdelete.occupyvdn": "O IVR atribu\u00EDdo ao tenant n\u00E3o pode ser exclu\u00EDdo.",
  "ccprovision.IVRSprogress.config.button.add": "Novo",
  "provision.tenantspace.config.param.error.videoCall": "Insira o n\u00FAmero de chamadas de v\u00EDdeo simult\u00E2neas.",
  "chat.usefulexpressions.message.willDeleteExpression": "Are you sure you want to delete the expression?",
  "provision.callcenterinstanceslist.table.refresh": "Atualizar",
  "provision.tenantspace.save.fail.029": "Esquerda do N\u00FAmero ASR n\u00E3o \u00E9 suficiente.",
  "provision.tenantspace.save.fail.028": "Esquerda do N\u00FAmero TTS n\u00E3o \u00E9 suficiente.",
  "provision.callcenterinstanceslist.label.eMediaAgentUseRate": "Uso do Agente Multim\u00EDdia",
  "provision.tenantspace.save.fail.027": "Esquerda do n\u00FAmero IVR n\u00E3o \u00E9 suficiente.",
  "provision.tenantspace.save.fail.026": "O n\u00FAmero restante de chamadas an\u00F4nimas simult\u00E2neas \u00E9 insuficiente.",
  "chat.skill.label.cancel": "Cancelar",
  "provision.tenantspace.save.fail.025": "O n\u00FAmero de chamada \u00E0 esquerda da voz n\u00E3o \u00E9 suficiente.",
  "provision.tenantspace.save.fail.024": "Esquerda do uso de v\u00EDdeo n\u00E3o \u00E9 suficiente.",
  "provision.tenantspace.save.fail.023": "Deixar de usar o agente n\u00E3o \u00E9 suficiente.",
  "provision.tenantspace.save.fail.022": "O n\u00FAmero de agentes de voz e o n\u00FAmero m\u00E1ximo de chamadas de voz simult\u00E2neas n\u00E3o podem ser 0.",
  "provision.callcenterinstanceslist.table.wasip": "Definir IP do servidor",
  "provision.tenantspace.save.fail.021": "Falha ao atualizar os dados do espa\u00E7o do Tenant.",
  "provision.callcenterinstanceslist.table.ivrchannels": "Quantidade de Canais IVR de \u00E1udio",
  "provision.callcent.error.abnormal": "Anormal",
  "provision.tenantspace.save.fail.020": "Falha ao excluir os dados do call center virtuais.",
  "provision.tenantspace.cc.maxAmsCallnums": "M\u00E1x. Chamadas an\u00F4nimas simult\u00E2neas",
  "ccprovision.IVRSprogress.config.button.complete": "Conclu\u00EDdo",
  "provision.tenantspace.ok": "OK",
  "provision.tenantspace.config.tenant.intelligent.ivr.featurevalue.not.one": "O valor de recurso IVR inteligente atribu\u00EDdo ao tenant n\u00E3o \u00E9 1",
  "provision.tenantspace.obsServer.datasource.name": "Nome da Fonte de Dados",
  "provision.callcenterinstanceslist.sipServer.idleRegAgentNum": "Simultaneidade restante",
  "provision.callcenterinstanceslist.whiteList.IP": "Endere\u00E7o da lista de confian\u00E7a",
  "chat.timerange.label.ok": "OK",
  "provision.tenantspace.feature.knowledgeBaseSupport": "Base de conhecimento",
  "provision.tenantspace.config.amsNumber.error": "O n\u00FAmero de chamadas an\u00F4nimas simult\u00E2neas n\u00E3o pode estar vazia ou deve ser 0 ou um inteiro positivo.",
  "provision.tenantspace.save.fail.018": "O call center est\u00E1 vazio.",
  "provision.tenantspace.save.fail.019": "A capacidade restante do call center \u00E9 menor do que a dos agentes rec\u00E9m-alocados.",
  "provision.tenantspace.save.fail.017": "O call center virtual est\u00E1 vazio.",
  "provision.tenantspace.save.fail.016": "Falha ao atualizar os dados do call center.",
  "chat.session.label.endType": "Tipo final",
  "provision.tenantspace.save.fail.015": "Falha ao atualizar os dados do n\u00FAmero de softphone.",
  "chat.skill.message.willDeleteAllSkills": "Tem certeza de que deseja excluir todas as habilidades?",
  "provision.tenantspace.save.fail.013": "Falha ao atualizar os dados do c\u00F3digo de acesso.",
  "provision.tenantspace.save.fail.014": "Falha ao atualizar os dados da ID do funcion\u00E1rio.",
  "provision.tenantspace.save.fail.012": "Falha ao atualizar os dados do call center virtuais.",
  "chat.thirdportal.chatWindow.button.startChat": "Iniciar bate-papo",
  "provision.tenantspace.save.fail.011": "Falha ao salvar os dados do call center virtuais.",
  "provision.tenantspace.save.fail.010": "O n\u00FAmero de segmentos de n\u00FAmero ocupados \u00E9 menor que o n\u00FAmero de agentes aplicados. O valor retornado \u00E9 nulo.",
  "provision.callcenterinstanceslist.label.maxAmsCallNums": "Chamadas an\u00F4nimas simult\u00E2neas",
  "provision.tenant.management.pool.ctinode2": "N\u00F3 CTI 2",
  "provision.tenant.management.pool.ctinode1": "N\u00F3 CTI 1",
  "provision.tenantspace.selectStastus.unallocated": "Pendente",
  "provision.tenantspace.sip.name": "Nome do servidor SBC",
  "provision.tenantspace.config.choosesoftphone": "Selecione o n\u00FAmero de softphone.",
  "provision.callcenterinstanceslist.config.videoAgentChannelsValidate": "O n\u00FAmero de canais de agente de v\u00EDdeo \u00E9 menor do que o n\u00FAmero de canais realmente alocados no call center.",
  "provision.callcenterinstanceslist.table.obssyncConfimMessage": "Tem certeza de que deve sincronizar a configura\u00E7\u00E3o do OBS?",
  "provision.tenantspace.save.fail.009": "O segmento ID do funcion\u00E1rio est\u00E1 vazio.",
  "provision.tenantspace.save.fail.008": "O n\u00FAmero do softphone est\u00E1 vazio.",
  "provision.callcenterinstanceslist.sipServer.sip": "SIP",
  "provision.tenantspace.save.fail.007": "O call center n\u00E3o existe.",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.explain2": "Um m\u00E1ximo de cinco grupos de n\u00FAmeros podem ser adicionados.",
  "provision.tenantspace.save.fail.006": "O n\u00FAmero de agentes sob o locat\u00E1rio n\u00E3o pode ser maior do que o n\u00FAmero de agentes restantes no servidor SIP.",
  "provision.tenantspace.save.fail.005": "O c\u00F3digo de acesso est\u00E1 ocupado.",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.explain1": "Visualiza\u00E7\u00E3o da biblioteca de n\u00FAmeros",
  "chat.weChatConfig.label.secret": "Configure Secret",
  "provision.tenantspace.save.fail.004": "O c\u00F3digo de acesso est\u00E1 vazio.",
  "provision.tenantspace.save.fail.003": "O n\u00FAmero m\u00E1ximo de chamadas de v\u00EDdeo simult\u00E2neas \u00E9 maior ou igual ao n\u00FAmero de agentes de v\u00EDdeo.",
  "provision.tenantspace.save.fail.002": "O n\u00FAmero m\u00E1ximo de chamadas de voz simult\u00E2neas \u00E9 maior ou igual ao n\u00FAmero de agentes e canais IVR.",
  "provision.tenantspace.save.fail.001": "O n\u00FAmero de agentes de voz\, agente de v\u00EDdeo\, agente multim\u00EDdia e canais IVR n\u00E3o pode ser 0.",
  "provision.tenant.management.tenantspaceid": "ID do espa\u00E7o do tenant",
  "provision.callcent.platformserver.cms": "Sistema de Gerenciamento de Chamadas (CMS)",
  "provision.tenantspace.config.amsServer.idleregagentnum.insufficient.error": "N\u00FAmero insuficiente de chamadas simult\u00E2neas restantes no servidor de chamadas an\u00F4nimas.",
  "provision.obs.error.sync": "Falha na sincroniza\u00E7\u00E3o.",
  "ccprovision.vcallecenter.config.popup.modifyinfo": "Modificar senha",
  "provision.callcenterinstanceslist.ccgatewayserver.ccGatewayPort": "Porta do CC-Gateway",
  "chat.agent.label.historicalBill": "Faturamento Hist\u00F3rico",
  "chat.client.label.accountNumber": "N\u00FAmero da conta",
  "provision.tenant.management.tenantcreate.success": "O espa\u00E7o do tenant foi criado com sucesso.",
  "provision.authconfig.deleteconfim": "Tem certeza de que deseja atualizar a chave do aplicativo?",
  "provision.callcenterinstanceslist.softPhone.softPhoneAdd": "Novo n\u00FAmero de software",
  "provision.tenant.management.edit.agentusage.mustinc": "O n\u00FAmero de novos agentes n\u00E3o pode ser menor do que o dos agentes originais.",
  "provision.tenantspace.obs.searchName": "Insira o nome do servidor OBS a ser consultado.",
  "provision.callcenterinstanceslist.sipServer.sipServerIp": "Endere\u00E7o do servidor SBC",
  "provision.callcenterinstance.config.ivrchannelsValidate": "A soma do n\u00FAmero de canais de \u00E1udio e v\u00EDdeo IVR deve ser maior ou igual \u00E0 soma do n\u00FAmero de canais TTS e do n\u00FAmero de canais ASR.",
  "provision.tenantspace.config.select.default.phone": "Selecione o n\u00FAmero de chamada padr\u00E3o.",
  "provision.callcenterinstanceslist.label.videoAgentChannelUseRate": "Uso do canal de agente de v\u00EDdeo",
  "ccprovision.IVRSprogress.config.leftovercurcallNo": "Quantidade restante do canal de chamada simult\u00E2neo",
  "provision.tenantspace.agentinfo": "Informa\u00E7\u00F5es do agente",
  "provision.callcenterinstanceslist.table.delete": "Excluir",
  "provision.callcenterinstanceslist.config.asrchannelnumsValidate": "O n\u00FAmero de canais ASR \u00E9 menor do que o n\u00FAmero de canais realmente alocados no call center.",
  "provision.authconfig.authinfomation": "Informa\u00E7\u00F5es sobre desenvolvedor",
  "provision.callcenterinstanceslist.vcallcenterDeatil.uapIpAndPort": "Endere\u00E7o do UAP",
  "provision.callcent.error.accesscode.deletefail.part.onccgateway": "Parte do c\u00F3digo de acesso falhou ao excluir na plataforma era.",
  "provision.callcenterinstanceslist.table.callcenterinstanceslist": "Lista de Call Center",
  "provision.callcenterinstanceslist.config.sysAdminpassword": "Senha do administrador de CTI",
  "provision.callcenterinstanceslist.table.ttschannelnums": "Quantidade TTS",
  "promotion.tenant.management.tenantedit.vccupdate.failed": "Falha ao atualizar o centro virtual.",
  "chat.client.message.hiMyWorkno": "Ol\u00E1\, meu trabalho n\u00E3o \u00E9",
  "provision.callcenterinstanceslist.config.wnFromValidate": "A soma do segmento de n\u00FAmero inicial e da capacidade real do sistema n\u00E3o pode ser superior a 50000.",
  "chat.session.label.endTime": "Hora de t\u00E9rmino",
  "chat.agentConsole.message.content.locate": "[Localizar Message]",
  "provision.tenantspace.table.expiryDate": "Prazo limite",
  "provision.tenantspace.detail.apply.accout": "Conta",
  "chat.session.title.detail": "Detalhes da sess\u00E3o",
  "chat.agent.label.mybill": "Minha conta",
  "provision.tenant.management.tenantedit": "Editar informa\u00E7\u00F5es sobre Tenant",
  "chat.weChatConfig.label.bindSkill": "Habilidade vinculada",
  "chat.skill.label.submit": "Enviar",
  "provision.tenant.management.tenantcreate.inter.failed": "Falha ao chamar o servi\u00E7o interno para criar o espa\u00E7o do Tenant.",
  "ccprovision.recorddrive.config.sftp.filestoragepath": "Caminho do arquivo",
  "provision.callcenter.gateway.address.alert": "Endere\u00E7o de balanceamento de carga do cluster do CC-Gateway",
  "chat.weChatConfig.label.verify": "Verificar configura\u00E7\u00E3o",
  "provision.tenantspace.table.maxVideoCalls": "M\u00E1x. chamadas de v\u00EDdeo simult\u00E2neas",
  "provision.callcenterinstanceslist.config.save": "Salvar",
  "chat.client.message.evaluationSuccess": "Avaliado com sucesso!",
  "provision.tenantspace.cc.amsCallnumUsagePercent": "Uso de chamadas an\u00F4nimas simult\u00E2neas",
  "provision.tenantspace.config.chooseSIPServer": "Selecione um servidor SBC.",
  "chat.webconfig.message.configdirective": "Copie e cole o seguinte c\u00F3digo em suas p\u00E1ginas da Web antes de TAG </body> salvar e liberar\, ent\u00E3o a entrada do Live Chat aparecer\u00E1 no bot\u00E3o direito da sua p\u00E1gina da Web\, e seus usu\u00E1rios finais clicam na entrada para iniciar conversa com os agentes ou chatbot.",
  "provision.tenant.management.pool.ctinode.name": "Nome do n\u00F3",
  "provision.callcent.error.updateccgateway.ondatabase": "Falha na atualiza\u00E7\u00E3o\, modificado o servidor CC-Gateway no banco de dados falhou.",
  "provision.tenant.management.tenantcreate.companyName": "Empresa",
  "provision.tenantspace.resetpwdsuccess": "A senha foi reinicializada com sucesso.",
  "provision.callcenterinstanceslist.config.workNoCheckError": "O n\u00FAmero inicial da ID de trabalho deve ser menor que o n\u00FAmero final.",
  "provision.tenantspace.config.error.softphone.noaddagent": "Nenhum agente de \u00E1udio\, v\u00EDdeo\, multim\u00EDdia ou m\u00F3vel \u00E9 adicionado.",
  "chat.session.label.remark": "Observa\u00E7\u00E3o",
  "provision.callcenterinstanceslist.config.nmsserviceIP": "Endere\u00E7o IP ODFS",
  "provision.callcenterinstanceslist.table.deleteconfim": "Confirmar exclus\u00E3o",
  "provision.tenantspace.agentinfo.message.searchSipAccount": "Insira o n\u00FAmero de telefone soft",
  "provision.tenantspace.config.softphoneassign.softphonescope.required": "Insira o segmento de n\u00FAmeros de softphone a ser adicionado e clique em OK.",
  "provision.callcenterinstanceslist.config.adminWorkNoError": "O n\u00FAmero inicial da ID do funcion\u00E1rio administrador deve variar de 55001 a 59999.",
  "ccprovision.recorddrive.config.sftp.identitycheck": "Verifica\u00E7\u00E3o de identidade",
  "chat.usefulexpressions.message.createSuccess": "A express\u00E3o cria sucesso.",
  "ccprovision.vcallecenter.config.popup.confirmnewpassword": "Confirmar senha",
  "provision.tenantspace.choose.accesscode": "Selecionar c\u00F3digo de acesso",
  "provision.callcenterinstanceslist.sipServer.sipServerPort": "N\u00FAmero da porta SBC",
  "provision.callcenterinstanceslist.config.eMediaAgentCurCapacityValidate": "O n\u00FAmero de agentes multim\u00EDdia \u00E9 menor do que o n\u00FAmero de lugares multim\u00EDdia realmente alocados no call center.",
  "provision.callcent.label.reset": "Redefinir",
  "provision.tenantspace.feature.smsOrEmailMarketingSupport.sendMaxNumber": "Enviando Limite Superior por Dia",
  "chat.common.message.delete": "Excluir",
  "provision.callcent.success.deleteinfo": "Exclu\u00EDdo com sucesso",
  "chat.agent.title.userInformation": "Informa\u00E7\u00F5es dos usu\u00E1rios",
  "provision.callcenterinstanceslist.table.wasport": "Defina o n\u00FAmero da porta do servidor.",
  "provision.tenantspace.config.select.commercial.status": "Selecione um Estado Comercial.",
  "provision.tenantspace.table.videoivrchannels": "Quantidade de Canais IVR de v\u00EDdeo",
  "provision.callcenterinstanceslist.table.operate": "Opera\u00E7\u00E3o",
  "provision.callcenterinstanceslist.table.baseinfo": "Informa\u00E7\u00F5es b\u00E1sicas",
  "provision.callcenterinstanceslist.table.maxcallnums": "M\u00E1x. chamadas de voz simult\u00E2neas",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.error": "A ID do agente final n\u00E3o pode ser menor que a ID do agente inicial.",
  "provision.callcent.selectcallcent": "Selecione um call center.",
  "provision.ccworknos.table.creataccessCode": "Novo",
  "provision.tenantspace.save.fail.123052": "A fun\u00E7\u00E3o do agente \u00E9 referenciada pelo agente e n\u00E3o pode ser exclu\u00EDda.",
  "provision.tenantspace.save.fail.123053": "A fun\u00E7\u00E3o agente \u00E9 a fun\u00E7\u00E3o padr\u00E3o e n\u00E3o pode ser modificada.",
  "provision.callcenterinstanceslist.table.update": "Editar",
  "provision.tenantspace.save.fail.123051": "Nome da fun\u00E7\u00E3o do agente duplicado.",
  "provision.callcent.pool.select.different.sipserver.error": "O mesmo tipo de servidor de registro de agente deve ser selecionado para n\u00F3s diferentes.",
  "chat.client.greeting.evening": "Boa noite!",
  "provision.accesscode.table.audioAndvideo": "\u00C1udio e v\u00EDdeo",
  "provision.tenantspace.config.chooseAgentRegistrationServerTitle": "Selecione o servidor de registro do agente.",
  "provision.callcenterinstanceslist.vcallcenter.CcgatewayServer": "Servidor CC-Gateway",
  "provision.tenant.management.tenantname.minlength": "O nome do espa\u00E7o do tenant n\u00E3o pode conter menos de 6 caracteres.",
  "provision.callcenterinstanceslist.softPhone.used.select": "Uso",
  "provision.tenantspace.create.onlineUserNumber": "N\u00FAmero de usu\u00E1rio online",
  "provision.tenantspace.table.maxVideoAgents": "Quantidade de Agentes de v\u00EDdeo",
  "provision.tenant.management.admininfo": "Registrar Administrador do locat\u00F3rio",
  "provision.tenantspace.selectStastus.edit.invalid": "inv\u00E1lido",
  "provision.tenant.management.batchcreate.success.createtask": "Adicionada com \u00EAxito uma tarefa para criar espa\u00E7os de tenants em lotes.",
  "provision.callcenterinstanceslist.vcallcenter.vcallcenter": "Centro de chamada virtual",
  "chat.skill.message.saveSuccess": "Habilidade salva o sucesso!",
  "provision.tenant.management.phonenumber": "N\u00FAmero de telefone",
  "chat.weChatConfig.label.create": "Adicionar",
  "provision.callcenterinstanceslist.config": "Central de atendimento",
  "provision.callcent.error.saveccgateway.regAgentNumError": "O n\u00FAmero de lugares registados modificados \u00E9 menor do que o n\u00FAmero real de lugares alocados.",
  "provision.tenantspace.config.chooseDPN": "Selecione um dos c\u00F3digos de acesso como o n\u00FAmero chamador padr\u00E3o.",
  "provision.callcenterinstanceslist.ucsServer.ucsServerIp": "IP do servidor CCUCS",
  "provision.callcent.error.updateaccesscode.fail.syncccmanagement": "\, falha ao sincronizar as informa\u00E7\u00F5es modificadas com o espa\u00E7o do Tenant.",
  "provision.tenantspace.save.fail.107-107": "Uso do configproxy do ipccbase.",
  "chat.webconfig.label.functionalsettings": "Configura\u00E7\u00F5es funcionais",
  "ccprovision.vcallecenter.error.updateinfo.adminpassword": "Erro de modifica\u00E7\u00E3o da senha.",
  "chat.client.label.rateService": "Classifique o servi\u00E7o",
  "provision.tenantspace.save.fail.078modify": "Falha ao modificar o espa\u00E7o do locat\u00E1rio ao configurar o sistema de autentica\u00E7\u00E3o de terceiros.",
  "chat.webconfig.button.save": "Salvar",
  "provision.encryptinfo.strength": "For\u00E7a",
  "provision.tenantspace.obsServer.datasource.databasename": "Nome do banco de dados",
  "chat.skill.label.createSkill": "Criar",
  "provision.tenantspace.common.anonymous.explain": "Ative o recurso de chamada originada an\u00F4nima para o espa\u00E7o do Tenant.",
  "provision.tenantspace.table.tenantSpaceStatus": "Status",
  "provision.callcent.success.deletesoftphonenumber.unfinishtask": "Uma tarefa de exclus\u00E3o est\u00E1 sendo processada. Tente novamente mais tarde.",
  "provision.callcent.error.updatesoftphonenumber.fail.syncccmanagement": "Falha ao sincronizar o softphone para modificar informa\u00E7\u00F5es para a sala de aluguel.",
  "chat.usefulexpressions.message.willDeleteExpressions": "Are you sure you want to delete these expressions?",
  "provision.callcenterinstanceslist.vcallcenterDeatil.DateValidateFailed": "Selecione uma data maior que hoje e menos que 2038-1-19",
  "provision.tenantspace.table.update": "Editar",
  "provision.callcenterinstance.config.videoCallValidate": "O n\u00FAmero m\u00E1ximo de chamadas de v\u00EDdeo simult\u00E2neas deve ser maior ou igual ao n\u00FAmero de assentos de v\u00EDdeo.",
  "provision.tenantspace.config.softphoneassign.softphonescope.contain": "O n\u00FAmero do softphone foi selecionado. Insira outro.",
  "provision.callcent.error.createaccesscode.fail.onccgateway": "Falha ao adicionar o c\u00F3digo de acesso \u00E0 plataforma.",
  "provision.tenantspace.config.worknoassign.filterworkNo": "Banco de n\u00FAmero de triagem",
  "provision.tenantspace.ivr.maxChnNum": "Quantidade de chamadas simult\u00E2neas",
  "provision.callcent.selectcallcentccgateway": "Selecione CC-Gateway.",
  "chat.agent.label.billDetail": "DETALHE DA FATURA",
  "ccprovision.vcallecenter.config.popup.adminworkno": "ID do administrador de configura\u00E7\u00E3o",
  "chat.client.label.bill": "Minha conta",
  "provision.tenantspace.language.cn": "Ingl\u00EAs",
  "chat.session.label.skillQueueId": "ID da fila de habilidades",
  "ccprovision.IVRSprogress.config.IPAddress": "Endere\u00E7o IP",
  "provision.callcenterinstanceslist.table.download": "Baixar",
  "provision.callcenterinstanceslist.vcallcenter.CcgatewayServer.title": "Configura\u00E7\u00E3o CC-Gateway",
  "chat.client.label.like": "Voc\u00EA pode gostar",
  "provision.callcenterinstanceslist.label.maxVideoCallnums": "M\u00E1x. chamadas de v\u00EDdeo simult\u00E2neas",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.paramserror": "As informa\u00E7\u00F5es de n\u00FAmero est\u00E3o incorretas.",
  "provision.tenant.management.tenantid": "ID do tenant",
  "ccprovision.recorddrive.config.sftp.account": "Conta SFTP",
  "ccprovision.recorddrive.config.sftp.accountpassword": "Senha do servidor SFTP",
  "provision.tenantspace.chooseFeature": "Sele\u00E7\u00E3o de recurso",
  "provision.tenantspace.feature.emailMarketingSupport": "Email Marketing",
  "provision.callcent.label.close": "Fechar",
  "chat.webconfig.label.bandingskill": "Habilidade vinculada",
  "provision.callcent.error.updatesipserver.ipandport.alreadyexist": "O IP do servidor SBC e o n\u00FAmero da porta j\u00E1 existem e n\u00E3o podem ser modificados.",
  "chat.agent.label.online": "Online",
  "provision.tenantspace.save.fail.123004": "Os elementos de habilidade s\u00E3o referenciados pela fila de habilidades e o nome n\u00E3o pode ser modificado.",
  "provision.tenantspace.save.fail.123003": "Os elementos de habilidade s\u00E3o referenciados pela fila de habilidades e n\u00E3o podem ser exclu\u00EDdos.",
  "provision.tenantspace.save.fail.123002": "Elemento de habilidade n\u00E3o existe.",
  "provision.tenantspace.save.fail.123001": "Nome duplicado do elemento de habilidade.",
  "provision.tenantspace.intelligent.agent.description": "Agente inteligente: al\u00E9m da comunica\u00E7\u00E3o b\u00E1sica de canal\, an\u00E1lise sem\u00E2ntica de texto e recomenda\u00E7\u00E3o inteligente\, voc\u00EA precisa configurar o n\u00FAmero de agentes inteligentes. Se um agente que apoie sess\u00F5es de voz precisa de ser configurado como um agente inteligente\, voc\u00EA precisa de configurar o n\u00FAmero de recursos de ASR para permitir a fun\u00E7\u00E3o da convers\u00E3o da voz ao texto.",
  "provision.tenantspace.reset": "Redefinir",
  "provision.tenantspace.agentinfo.message.audio": "\u00C1udio",
  "provision.tenantspace.config.select.vitual.ccname": "Insira o nome do call center virtual.",
  "provision.softPhone.table.forcemodifyconfim.message": "Esta opera\u00E7\u00E3o ir\u00E1 reinicializar a senha do softphone. Para alterar a senha\, voc\u00EA deve fazer login no espa\u00E7o do tenant. Tem certeza de que deseja continuar?",
  "chat.timerange.label.customizes": "Customizes",
  "provision.tenantspace.config.select.whiteRepeate": "N\u00FAmero da lista de confian\u00E7a duplicada.",
  "chat.client.greeting.info": "O que posso fazer por voc\u00EA?",
  "provision.callcenter.videoapplicationmodel.mcuAndsfuModel": "V\u00EDdeo MCU + SFU",
  "chat.session.label.subChannelTime": "Canal de tempo do subChannel",
  "provision.tenantspace.create.basicInfo": "Informa\u00E7\u00F5es b\u00E1sicas",
  "provision.tenantspace.create.tenantExpiryDate": "Tempo de expira\u00E7\u00E3o do tenant",
  "provision.tenantspace.table.maxAgents": "Agentes de Voz Max",
  "provision.tenantspace.selectStastus.edit.stop": "Suspensar",
  "provision.tenantspace.config.chooseAnonymousCallServer": "Selecionar um servidor de chamadas an\u00F4nimo",
  "provision.tenantspace.authenticationsystem.selectClientName": "Selecione um sistema de autentica\u00E7\u00E3o de terceiros.",
  "chat.agentConsole.message.error.emptyMessage": "[Mensagem Vazia]",
  "provision.callcenterinstanceslist.sipServer.webRtcanonymousCall": "Chamada an\u00F4nima WebRTC",
  "provision.tenantspace.popuppageurl.tableheadname": "Endere\u00E7o da lista de permiss\u00F5es",
  "promotion.tenant.management.tenantedit.vcccapacity.insufficient": "A capacidade do centro virtual \u00E9 insuficiente.",
  "provision.tenantspace.ExpireTenantSpaceCount": "N\u00FAmero de Tenants que est\u00E3o prestes a expirar dentro de 30 dias",
  "provision.tenantspace.selectStastus.resource.allocation.failed": "Falha ao alocar o recurso",
  "provision.callcenterinstanceslist.config.maxAmsCallNumsValidate": "O n\u00FAmero m\u00E1ximo de chamadas an\u00F4nimas simult\u00E2neas n\u00E3o pode ser menor do que o n\u00FAmero de chamadas an\u00F4nimas alocadas pelo call center.",
  "chat.client.label.header": "Telco+",
  "provision.tenantspace.config.choosedatasource": "Selecione uma fonte de dados OBS.",
  "provision.encryptinfo.mode": "Modo",
  "provision.tenantspace.dataUpload.resourceDump": "Dump de recurso",
  "provision.tenantspace.agentinfo.agentstatus.offline": "Off-line",
  "provision.tenant.management.updatetenant.success": "A configura\u00E7\u00E3o do espa\u00E7o do tenant foi modificada com sucesso.",
  "provision.tenantspace.chooseobsdatasource.error1": "Selecione um servidor OBS.",
  "provision.tenantspace.config.agentusage.ivr": "Quantidade de Agente de Voz\, Quantidade de Agente de V\u00EDdeo\, Quantidade de Agente Multim\u00EDdia\, Quantidade de Agente Vers\u00E1til\, Quantidade de Canal de \u00C1udio IVR e Quantidade de Canal de V\u00EDdeo n\u00E3o podem ser zero",
  "chat.weChatConfig.label.verifycode": "Verificar c\u00F3digo",
  "chat.skill.message.saveFail": "Falha no salvamento de habilidade.",
  "chat.session.channel.mobile": "Celular",
  "chat.weChatConfig.label.facebook": "Facebook",
  "provision.accesscode.table.endNumber": "C\u00F3digo de acesso final",
  "provision.callcenterinstanceslist.sipServer.sipServerType": "Tipo de servidor SBC",
  "provision.tenantspace.config.worknoassign.availableworknocount": "N\u00FAmeros dispon\u00EDveis",
  "provision.callcenterinstanceslist.table.nodemoderesource": "Modo de recurso",
  "ccprovision.IVRSprogress.config.button.delete": "Excluir",
  "provision.tenantspace.config.worknoassign.add": "Adicionar",
  "provision.tenantspace.gw.serverIP": "Endere\u00E7o IP do servidor de gateway",
  "provision.tenantspace.config.datasource": "OBS como fonte de dados",
  "provision.callcent.error.accesscode.deletefail.ondatabase": "Falha ao excluir o c\u00F3digo de acesso no banco de dados",
  "provision.tenantspace.sip.port": "Porta do servidor SIP",
  "provision.tenantspace.config.softphoneassign": "Alocar n\u00FAmero do softphone",
  "provision.tenantspace.create.account": "Informa\u00E7\u00F5es da conta",
  "provision.tenant.management.tenantcreate.mobileillegal": "N\u00FAmero m\u00F3vel inv\u00E1lido.",
  "chat.timerange.label.oneday": "1 dia",
  "provision.tenantspace.chooseCC": "Selecione um call center",
  "provision.tenantspace.selectStastus.config": "A ser configurado",
  "chat.agent.title.close": "Fechar",
  "provision.tenantspace.ivr.ivrServer": "Endere\u00E7o IP",
  "provision.callcenterinstanceslist.label.videoCallnumUseRate": "Uso simult\u00E2neo de chamadas de v\u00EDdeo",
  "provision.tenantspace.feature.multiChannelSupport.description": "Ative o recurso de suporte multicanal. O locat\u00E1rio suporta ajustes tempor\u00E1rios de fila entre tipos de canais de servi\u00E7o para agentes. Observa\u00E7\u00E3o: Se um agente tiver permiss\u00E3o de canal de servi\u00E7o cruzado\, os recursos de voz\, v\u00EDdeo e multim\u00EDdia ser\u00E3o ocupados durante o check-in. Os agentes de um locat\u00E1rio podem fazer o check-in preemp\u00E7\u00E3o de recursos e n\u00E3o podem fazer o check-in para atendimento ao cliente ao mesmo tempo.",
  "provision.callcenterinstanceslist.softPhone.accountpassword": "Senha da conta",
  "provision.callcent.selectsipserver": "Selecione um servidor de registro de agente.",
  "provision.tenantspace.config.maxVideoCalls": "Chamadas de v\u00EDdeo simult\u00E2neas",
  "chat.session.title.history": "Hist\u00F3rico bate-papo",
  "provision.callcent.error.deleteucsserver.part.associationvcallcener": "Alguns servidores CCUCS selecionados est\u00E3o associados a Tenants e n\u00E3o podem ser exclu\u00EDdos.",
  "provision.callcenterinstanceslist.config.updatefailed": "Falha ao modificar o call center.",
  "provision.callcenterinstanceslist.softPhone.tenantname": "Nome do tenant",
  "chat.agent.label.customerSource": "Customer Source: Phone App-Personal Center",
  "provision.tenantspace.table.deleteTenantSpace": "Tem certeza de que deseja excluir o espa\u00E7o do Tenant especificado?",
  "provision.callcenterinstanceslist.vcallcenter.invalidVdnListPromt": "H\u00E1 VDNs no WAS onde o call center virtual est\u00E1 ausente no AICC. O VdnID \u00E9",
  "provision.tenantspace.feature.intelligent.inspection.description": "Ative a funcionalidade de inspe\u00E7\u00E3o inteligente para o espa\u00E7o do Tenant.",
  "provision.tenantspace.step7": "Passo 7",
  "chat.skill.message.createFail": "Habilidade cria falha.",
  "provision.tenantspace.step6": "Passo 6",
  "provision.tenantspace.step3": "passo 3",
  "chat.client.transfer.link": "Quer transferir para um agente?",
  "provision.tenantspace.step5": "passo 5",
  "provision.callcenterinstanceslist.obsServer.obsServerIpSync": "Sincronizar",
  "provision.tenantspace.cc.videoAgentchannels": "Quantidade do canal do Agente de V\u00EDdeo",
  "provision.tenantspace.step4": "passo 4",
  "provision.tenant.management.tenantcreate.contact": "Informa\u00E7\u00F5es de contato",
  "provision.tenantspace.step1": "passo 1",
  "provision.tenant.chooseCC.first": "Selecione um call center.",
  "provision.tenantspace.cc.ttschannelnums": "Quantidade TTS",
  "provision.tenantspace.config.select.default.whiteNumber": "O n\u00FAmero de n\u00FAmeros listados de confian\u00E7a n\u00E3o pode ser maior que 5 por padr\u00E3o.",
  "provision.callcenterinstanceslist.config.updatesuccess": "O call center foi modificado com sucesso.",
  "provision.tenantspace.cc.percent": "Capacidade (%)",
  "provision.tenantspace.step2": "passo 2",
  "provision.callcent.error.saveucsserveripinfo": "Falha na cria\u00E7\u00E3o. Certifique-se de que o endere\u00E7o IP do CCUCS e o n\u00FAmero da porta do servidor ainda n\u00E3o existam.",
  "provision.callcenterinstanceslist.vcallcenterDeatil.passLengthValidateFailed": "A senha deve conter no m\u00EDnimo oito caracteres.",
  "chat.skill.label.agents": "Agentes",
  "provision.tenantspace.deleteWhiteNumber.confirm": "Confirmar exclus\u00E3o",
  "provision.callcenterinstanceslist.obsServer.activeObsServerIp": "IP do servidor ativo",
  "provision.callcenterinstanceslist.table.create": "Novo",
  "provision.callcenterinstanceslist.vcallcenter.InProgress": "Em andamento.",
  "provision.tenantspace.config.gw": "Servidor de gateway",
  "provision.tenantspace.ucs.name": "Nome do servidor CCUCS",
  "provision.tenantspace.detail.tenantspace.status.not.change": "O tenant est\u00E1 em estado inativo e n\u00E3o pode ser modificado.",
  "provision.callcent.error.createaccesscode.validate.servicenumberContainServicenumber": "O n\u00FAmero de servi\u00E7o n\u00E3o pode ter uma rela\u00E7\u00E3o de inclus\u00E3o com outros n\u00FAmeros de servi\u00E7o sob o call center.",
  "provision.tenantspace.table.asrchannelnums": "Inst\u00E2ncias do ASR",
  "provision.callcenterinstanceslist.label.amsCallNumUseRate": "Uso de chamadas an\u00F4nimas simult\u00E2neas",
  "provision.ivr.selectivrprogress.singledeleteconfim": "Tem certeza de que deseja excluir o processo IVR selecionado?",
  "chat.weChatConfig.message.codeNameExisted": "Code has already existed!",
  "provision.callcenterinstanceslist.vcallcenter.UCSServer.title": "Configurar servidor CCUCS",
  "provision.callcenterinstanceslist.vcallcenter.accessCodeConfig": "Configurar c\u00F3digo de acesso",
  "chat.session.label.servedAgent": "Agente servido",
  "provision.callcenterinstanceslist.vcallcenter.vdnName": "Nome do VDN",
  "provision.callcenterinstanceslist.vcallcenter.maxMediaAgents": "MaxMediaAgents",
  "provision.tenantspace.table.maxAmsCalls": "M\u00E1x. Chamadas an\u00F4nimas simult\u00E2neas",
  "provision.callcenterinstanceslist.config.title": "Configura\u00E7\u00E3o da central de atendimento",
  "provision.tenantspace.agentusage.plus.videousage.plus.maxVersatileAgents": "O n\u00FAmero total de agentes de voz\, v\u00EDdeo e vers\u00E1teis n\u00E3o pode exceder 10000.",
  "provision.tenantspace.selectvideomodel.isnull": "Se um locat\u00E1rio solicitar recursos de v\u00EDdeo\, selecione o modo de aplica\u00E7\u00E3o de v\u00EDdeo.",
  "promotion.tenant.management.tenantedit.vcc.notexist": "O call center virtual n\u00E3o existe.",
  "provision.callcent.error.updatesipserver.fail.syncccmanagement": "Falha ao sincronizar o servidor SBC para modificar as informa\u00E7\u00F5es para a sala de aluguel.",
  "provision.tenant.management.agentnum": "Agentes",
  "provision.tenantspace.accesscode.searchName": "Insira o c\u00F3digo de acesso a ser consultado.",
  "provision.callcenterinstanceslist.obsServer.config.explain": "O valor deve ser o mesmo que a configura\u00E7\u00E3o do OBS na plataforma CTI.",
  "chat.skill.label.edit": "Editar",
  "provision.tenantspace.feature.open": "Ativar",
  "provision.callcent.error.savesipserverdesinfo.regAgentNumError": "O n\u00FAmero de agentes registrados concorrentes modificados \u00E9 menor do que o n\u00FAmero real de lugares registrados.",
  "provision.tenantspace.common.moveagent.explain": "O sistema aloca um n\u00FAmero de softphone ao Tenant. O sistema do tenant usa o n\u00FAmero de softphone para registrar-se no servidor do sistema AICC. O sistema do tenant invoca a interface aberta fornecida pelo sistema AICC para implementar a fun\u00E7\u00E3o de retorno de chamada bidirecional. (N\u00FAmero m\u00E1ximo de agentes m\u00F3veis aloc\u00E1veis = quantidade de canal ivr de \u00E1udio / 2\, arredondando para baixo).",
  "chat.skill.label.save": "SALVAR",
  "provision.tenantspace.feature.close": "Desativar",
  "provision.tenantspace.table.invalidTenantSpace.confirm": "Confirmar invalida\u00E7\u00E3o",
  "chat.weChatConfig.stepname.selectmode": "Selecionar m\u00E9todo de configura\u00E7\u00E3o",
  "provision.tenantspace.config.select.record.time": "Selecione um per\u00EDodo de reten\u00E7\u00E3o da grava\u00E7\u00E3o.",
  "provision.callcenterinstanceslist.vcallcenter.maxAgents": "M\u00E1ximo de agentes",
  "provision.callcent.error.updateccgateway.regAgentNumError": "Falha na atualiza\u00E7\u00E3o\, o n\u00FAmero de assentos registrados modificados \u00E9 menor do que o n\u00FAmero real de assentos alocados.",
  "provision.callcenterinstanceslist.table.ccName": "Nome da central de atendimento",
  "provision.tenantspace.config.maxVideoAgents": "Quantidade de Agentes de v\u00EDdeo",
  "provision.tenantspace.save.fail.120005": "Falha ao verificar o item de configura\u00E7\u00E3o.",
  "provision.tenantspace.save.fail.120004": "A opera\u00E7\u00E3o falhou porque o n\u00FAmero de VDNs habilitados atinge o m\u00E1ximo.",
  "provision.tenantspace.save.fail.120002": "Esta configura\u00E7\u00E3o j\u00E1 existe.",
  "provision.tenantspace.save.fail.120003": "Esta configura\u00E7\u00E3o n\u00E3o existe.",
  "provision.tenantspace.save.fail.120000": "Erro de interface.",
  "provision.tenantspace.save.fail.120006": "O n\u00FAmero m\u00E1ximo de threads.",
  "chat.agent.label.money": "\u20AC",
  "provision.tenantspace.save.fail.120001": "O n\u00FAmero m\u00E1ximo de lotes \u00E9 500.",
  "provision.callcenterinstanceslist.sipServer.tenantname": "Nome do tenant",
  "provision.tenantspace.feature.multiChannelSupport": "Suporte a v\u00E1rios canais",
  "provision.callcenterinstanceslist.obsServer.standbyObsServerIp": "IP do Servidor Obs em Standby",
  "provision.tenantspace.error.deleteConfigTenantSpace": "Recursos foram alocados ao espa\u00E7o do Tenant. Voc\u00EA n\u00E3o pode excluir o espa\u00E7o do Tenant.",
  "provision.tenantspace.selectStastus.invalid": "Desabilitado",
  "chat.webconfig.message.saveSuccess": "Configuration saved successfully.",
  "provision.callcenterinstanceslist.config.warning": "Aviso",
  "chat.usefulexpressions.label.createExpressionTab": "Create Expression",
  "provision.tenantspace.agentinfo.message.batchdeleteconfim": "Tem certeza de que deseja excluir todos os agentes selecionados?",
  "chat.weChatConfig.label.configId": "Configuration Id",
  "provision.tenantspace.modifyPassword": "Redefinir senha",
  "provision.tenantspace.obsServer.datasource.type": "Tipo de origem de dados",
  "provision.callcent.error.specialStrValidateFailed": "O nome obs n\u00E3o pode conter caracteres especiais.",
  "provision.tenantspace.feature.emailMarketingSupport.description": "Este recurso fornece fun\u00E7\u00F5es de email marketing para o espa\u00E7o do tenant.",
  "provision.tenantspace.feature.whatsAppMarketingSupport": "Marketing por WhatsApp",
  "provision.ccworknos.table.ccworkQuantity": "Quantidade de n\u00FAmero no Segmento de n\u00FAmeros",
  "provision.tenantspace.feature.whatsAppMarketingSupport.description": "Fornecer recursos de marketing do WhatsApp para espa\u00E7os de locat\u00E1rios.",
  "provision.callcenterinstanceslist.table.gwPort": "Porta do CC-Gateway",
  "provision.tenantspace.config.param.error.ivrNumber": "Insira o n\u00FAmero de canais IVR.",
  "provision.callcent.error.updateccgateway.ccgatewayServernoexist": "Falha de atualiza\u00E7\u00E3o\, o Servidor de Gateway CC n\u00E3o existe.",
  "chat.common.message.warning": "Aviso",
  "chat.skill.label.createSkillTab": "Create Skill",
  "provision.tenant.management.positiveInteger.checkmsg": "inserir um inteiro positivo.",
  "provision.callcenterinstanceslist.table.maxAmsCallNums": "M\u00E1x. Chamadas an\u00F4nimas simult\u00E2neas",
  "provision.tenantspace.worknoValidatefaild": "O WorkNo \u00E9 obrigat\u00F3rio.",
  "provision.tenantspace.search": "Consulta",
  "provision.tenantspace.agentinfo.message.versatile": "Vers\u00E1til",
  "provision.accesscode.table.accessCode.clicktodial": "Clique para discar",
  "provision.callcenterinstanceslist.config.videoAgentCurCapacityValidate": "O n\u00FAmero de assentos de v\u00EDdeo \u00E9 menor que o n\u00FAmero de assentos de v\u00EDdeo realmente alocados no call center.",
  "provision.callcent.error.updatesipserver.ondatabase": "Falha ao modificar o servidor SBC no banco de dados.",
  "provision.callcent.error.accesscode.deletefail": "Falha ao excluir o c\u00F3digo de acesso porque o par\u00E2metro de entrada est\u00E1 vazio.",
  "provision.callcenterinstanceslist.vcallcenter.plateformServer": "Servidor de plataforma",
  "provision.tenantspace.accessCode.choose": "Selecione um grupo de c\u00F3digos de acesso.",
  "chat.skill.message.completeInputs": "Preencha todas as entradas.",
  "provision.accesscode.table.tenantname": "Tenant",
  "provision.tenantspace.cc.videoAgentchannelUsagePercent": "Uso do canal de agente de v\u00EDdeo",
  "provision.callcent.error.deletesipserver.associationvcallcener": "O servidor SIP selecionado est\u00E1 associado ao tenant e n\u00E3o pode ser exclu\u00EDdo.",
  "chat.client.label.privacyHintContent": "Para melhorar a qualidade do servi\u00E7o\, a conversa ser\u00E1 gravada pelo sistema\, voc\u00EA concorda e deseja continuar?",
  "provision.callcenterinstanceslist.obsServer.batchdeleteconfim": "Tem certeza de que deseja excluir todos os servidores OBS selecionados?",
  "chat.agent.title.userMenu": "Menu do usu\u00E1rio",
  "provision.callcenterinstanceslist.config.alreadyinuse": "O segmento ID do funcion\u00E1rio foi usado.",
  "provision.callcent.error.partSetinfo": "Falha na sele\u00E7\u00E3o do conjunto de pe\u00E7as.",
  "chat.client.label.evaluation": "Avalia\u00E7\u00E3o",
  "provision.tenant.management.batchcreate": "Os espa\u00E7os tenant s\u00E3o criados em lotes.",
  "provision.callcenterinstanceslist.sipServer.sipServerIpUpdate": "Configura\u00E7\u00E3o do servidor SIP",
  "provision.tenant.management.select.accesscode": "Selecionar c\u00F3digo de acesso",
  "provision.callcenterinstanceslist.sipServer.webRTC": "WebRTC",
  "provision.callcent.selectamsserver.inconsistenttype": "Tipo de servidor de chamadas an\u00F4nimo n\u00E3o \u00E9 consistente",
  "provision.tenantspace.selectStastus.CommercialUse": "Uso comercial",
  "provision.callcenterinstanceslist.vcallcenter.maxVideoCalls": "MaxVideoCalls",
  "provision.tenantspace.common.agent.number": "Quantidade de Agente Inteligente",
  "provision.callcenterinstanceslist.vcallcenter.maxVideoAgents": "MaxVideoAgents",
  "provision.whitepopuppageurl.protocol.check": "Observe que o protocolo de rede usado pelo endere\u00E7o pop-up n\u00E3o \u00E9 um protocolo seguro. Se voc\u00EA decidir usar este protocolo\, podem existir riscos de seguran\u00E7a.",
  "chat.timerange.label.alldays": "Todos os dias",
  "provision.callcenterinstanceslist.vcallcenterDeatil.kafkaPwdLength": "O comprimento da senha deve estar entre 6 e 32",
  "provision.tenantspace.config.softphone.Validatefaild": "N\u00FAmero de softphone n\u00E3o alocado",
  "provision.callcenterinstanceslist.vcallcenterDeatil.wasPsdLengthRangeValidateFailed": "O comprimento da senha deve estar entre 16 e 32",
  "provision.callcent.error.savesipserveripinfo": ". Certifique-se de que o endere\u00E7o IP e o n\u00FAmero da porta do servidor n\u00E3o existem.",
  "provision.tenantspace.cc.callnumUsagePercent": "Uso simult\u00E2neo de chamadas de voz",
  "provision.callcenter.videoapplicationmodel": "Modo de aplica\u00E7\u00E3o de v\u00EDdeo",
  "provision.tenant.management.tenantcreate": "Novo Tenant",
  "provision.tenantspace.detail.expiry.time": "Hor\u00E1rio de expira\u00E7\u00E3o",
  "provision.callcentaccesscodes.table.callcentaccesscodes": "C\u00F3digo de acesso",
  "provision.tenantspace.table.activeTenantSpace.confirm": "Confirmar ativa\u00E7\u00E3o",
  "provision.tenantspace.resetpwdfaild.accountpassword.error": "A senha da conta atual est\u00E1 incorreta.",
  "provision.callcenterinstanceslist.config.agentCurCapacityValidate": "O n\u00FAmero de agentes de voz \u00E9 menor que o n\u00FAmero de assentos de voz realmente atribu\u00EDdos pelo call center.",
  "provision.tenantspace.tenantid": "ID do espa\u00E7o do Tenant",
  "chat.webconfig.label.transHuman": "Transfer\u00EAncia para Servi\u00E7o Humano",
  "provision.callcenterinstanceslist.vcallcenterDeatil.saveerror": "Falha ao salvar.",
  "provision.callcenterinstanceslist.config.odfsconfig": "Configura\u00E7\u00E3o do servidor ODFS",
  "provision.callcent.error.deleteucsserver.associationvcallcener": "O servidor CCUCS selecionado est\u00E1 associado ao tenant e n\u00E3o pode ser exclu\u00EDdo.",
  "provision.callcent.selectaccesscode": "Selecione um c\u00F3digo de acesso.",
  "provision.tenantspace.save.fail.120663": "A configura\u00E7\u00E3o chamada \u00E9 referenciada pelo agente e n\u00E3o pode ser exclu\u00EDda.",
  "provision.tenantspace.save.fail.120661": "A configura\u00E7\u00E3o chamada \u00E9 referenciada pela fila de habilidade privada padr\u00E3o e n\u00E3o pode ser exclu\u00EDda.",
  "provision.tenantspace.save.fail.120662": "A configura\u00E7\u00E3o chamada \u00E9 referenciada pela fila de habilidades e n\u00E3o pode ser exclu\u00EDda.",
  "provision.tenantspace.save.fail.120660": "O c\u00F3digo de acesso ao sistema configurado pela parte chamada n\u00E3o \u00E9 consistente com o tipo de m\u00EDdia do dispositivo de configura\u00E7\u00E3o chamado.",
  "chat.skill.label.delete": "EXCLUIR",
  "ccprovision.recorddrive.config.sftp.accountconfirmpasd": "Confirmar senha do servidor SFTP",
  "provision.tenantspace.feature.smsOrEmailMarketingSupport.sendMaxNumber.alert": "O n\u00FAmero m\u00E1ximo de mensagens que podem ser enviadas todos os dias varia de 1 a 1000000000. O valor 0 indica que nenhum limite foi especificado.",
  "provision.tenantspace.feature.smsOrEmailMarketingSupport.sendMaxNumber.whatsApp.alert": "O n\u00FAmero m\u00E1ximo de mensagens que podem ser enviadas todos os dias varia de 1 a 100000. O valor 0 indica que nenhum limite foi especificado.",
  "chat.weChatConfig.label.AppSecret": "Segredo do aplicativo",
  "provision.tenantspace.feature.smsOrEmailMarketingSupport.sendMaxNumber.whatsApp.placeholder": "O valor varia de 1 a 100000. O valor 0 indica que nenhum limite foi especificado.",
  "provision.tenantspace.save.fail.120659": "O script de roteamento referenciado pela configura\u00E7\u00E3o chamada n\u00E3o existe.",
  "provision.tenantspace.gw.ServerIP": "IP do Servidor de Gateway",
  "provision.callcenterinstanceslist.vcallcenter.SIPServer.title": "Configura\u00E7\u00E3o do servidor SBC",
  "provision.callcenterinstanceslist.config.ccIdUpdateError": "N\u00E3o altere a ID do call center ao editar o call center",
  "provision.tenantspace.feature.agenttype": "Tipo de Agente",
  "provision.tenantspace.save.fail.120658": "O tipo de dispositivo \u00E9 IVR\, e o tipo de m\u00EDdia do c\u00F3digo de acesso s\u00F3 pode ser clique para chamada\, solicita\u00E7\u00E3o de retorno de chamada e chamada normal.",
  "provision.tenantspace.save.fail.120656": "A fila de habilidades referenciada pela configura\u00E7\u00E3o chamada n\u00E3o existe.",
  "provision.tenantspace.save.fail.120655": "O c\u00F3digo de acesso referenciado pela configura\u00E7\u00E3o chamada n\u00E3o existe.",
  "provision.tenantspace.config.chooseworkNo": "Selecione WorkNo",
  "provision.tenantspace.intelligent.ivr.explain": "IVR inteligente: Al\u00E9m da fun\u00E7\u00E3o IVR tradicional\, navega\u00E7\u00E3o inteligente IVR suportando reconhecimento de fala e compreens\u00E3o sem\u00E2ntica.",
  "provision.encryptinfo.version": "Vers\u00E3o",
  "provision.tenantspace.save.fail.120652": "A combina\u00E7\u00E3o do c\u00F3digo de acesso do sistema e c\u00F3digos de acesso subsequentes configurados pela parte chamada n\u00E3o pode ser repetida.",
  "provision.tenantspace.save.fail.120651": "O c\u00F3digo de acesso ao sistema configurado pela parte chamada n\u00E3o \u00E9 atribu\u00EDdo.",
  "provision.callcenterinstanceslist.table.hasaccesscodeAndivrConfim": "A exclus\u00E3o falhou. H\u00E1 um c\u00F3digo de acesso ou IVR no call center. Voc\u00EA precisa exclu\u00ED-lo manualmente.",
  "chat.session.endType.overtimeEnd": "Fim de horas extras",
  "provision.callcenterinstanceslist.obsServer.obsServerIpAdd": "Adicionar",
  "provision.callcent.error.updateaccesscode.fail.accesscodenoexist": ". Verifique se o c\u00F3digo de acesso existe e est\u00E1 associado a um call center virtual.",
  "chat.skill.label.description": "Descri\u00E7\u00E3o",
  "provision.callcent.error.savesipserverdesinfo.maxRegAgentNumError": ". O n\u00FAmero de agentes concorrentes modificados registrados \u00E9 menor do que o n\u00FAmero real de lugares registrados.",
  "provision.callcenterinstanceslist.callcenter": "Central de atendimento",
  "provision.callcenterinstanceslist.table.ctinodesec": "Informa\u00E7\u00F5es do n\u00F3 CTI 2",
  "provision.grayconfig.tenantHasConfig": "O espa\u00E7o do tenant foi configurado",
  "chat.skill.message.error": "Erro",
  "provision.tenantspace.feature.ivrtype": "Tipo IVR",
  "provision.tenant.management.tenantcreate.provision.failed": "Falha ao criar o espa\u00E7o local do Tenant.",
  "chat.weChatConfig.message.error": "Erro",
  "provision.callcent.error.addcallcenterdesinfo": "Certifique-se de que o call center n\u00E3o existe.",
  "chat.usefulexpressions.label.expression": "Express\u00E3o",
  "provision.tenantspace.Conversioncommercial": "Uso comercial",
  "provision.callcenterinstanceslist.softPhone.passValidateFailed": "Deve conter pelo menos 3 letras mai\u00FAsculas\, letras min\u00FAsculas\, n\u00FAmeros\, caracteres especiais ~{'@'}^*_+.?.",
  "provision.tenantspace.config.giveSource": "Alocar recursos",
  "provision.callcenterinstanceslist.ucsServer.batchdeleteconfim": "Tem certeza de que deseja excluir todos os servidores CCUCS selecionados?",
  "provision.tenantspace.sfuprompt": "Conex\u00E3o de integra\u00E7\u00E3o do sistema n\u00E3o adaptada SFU v\u00EDdeo\, os clientes podem desenvolver e adaptar este modo de v\u00EDdeo",
  "provision.callcenter.videoapplicationmodel.mcumodel": "V\u00EDdeo MCU",
  "ccprovision.recorddrive.config.sftp.identitychecktips.placeholder": "Digite sua conta atual e senha.",
  "provision.callcenter.videoapplicationmodel.sfumodel": "V\u00EDdeo SFU",
  "provision.callcent.error.modifyParamUnique": "O IP do servidor OVS ativo j\u00E1 existe.",
  "provision.tenantspace.feature.customData.description": "Esse recurso adiciona v\u00E1rias op\u00E7\u00F5es de personaliza\u00E7\u00E3o de dados para espa\u00E7os de Tenants.",
  "chat.weChatConfig.message.appIdExisted": "AppId j\u00E1 existiu!",
  "provision.callcent.error.savesipserverdesinfo.sipServernoexist": "\, servidor SBC n\u00E3o existe.",
  "provision.ivr.selectivrprogress.batchdeleteconfim": "Tem certeza de que deseja excluir todos os processos IVR selecionados?",
  "provision.tenantspace.intelligent.agent.explain": "Agente Inteligente: Al\u00E9m da fun\u00E7\u00E3o b\u00E1sica de controle de voz\, ele suporta voz a texto em tempo real e recomenda\u00E7\u00E3o inteligente relacionada. Para configurar agentes inteligentes\, voc\u00EA precisa configurar os agentes de voz e os recursos ASR primeiro.",
  "chat.usefulexpressions.message.isExisted": "O nome da express\u00E3o existe.",
  "chat.client.label.password": "Senha",
  "provision.recorddrive.error.cannotdelete.occupyvdn": "A letra da unidade atribu\u00EDda ao Tenant n\u00E3o pode ser exclu\u00EDda",
  "chat.weChatConfig.label.verifying": "Verificando",
  "provision.tenantspace.common.agent": "Agente comum",
  "provision.tenantspace.table.batchcreate": "Cria\u00E7\u00E3o em lote",
  "provision.tenantspace.save.fail.107-087": "O n\u00FAmero UAP cancelado de registro n\u00E3o est\u00E1 registrado.",
  "provision.tenantspace.save.fail.107-086": "Nenhum prefixo de chamada est\u00E1 configurado para um n\u00FAmero no UAP.",
  "chat.agent.label.balance": "Saldo",
  "provision.tenantspace.save.fail.107-085": "O tipo de produto UAP n\u00E3o pode ser identificado.",
  "provision.tenantspace.save.fail.107-084": "O UAP n\u00E3o consegue obter o tipo de produto.",
  "provision.tenantspace.table.maxVersatileAgents": "Agentes vers\u00E1teis Max",
  "provision.tenantspace.save.fail.107-083": "O UAP falha ao enviar o comando MML.",
  "provision.tenantspace.ucs.searchName": "Insira o nome do servidor CCUCS a ser consultado.",
  "provision.tenantspace.save.fail.107-081": "O UAP n\u00E3o consegue verificar a permiss\u00E3o VDN porque o VDN n\u00E3o cont\u00E9m o n\u00FAmero do usu\u00E1rio.",
  "provision.tenantspace.save.fail.107-082": "Falha ao iniciar sess\u00E3o no UAP ou desconectado da UAP.",
  "ccprovision.vcallecenter.config.popup.curcountpassword": "Senha da conta atual",
  "provision.callcent.platformserver.identitychecktips": "Voc\u00EA alterou a senha do servidor. Para garantir a seguran\u00E7a das informa\u00E7\u00F5es\, introduza sua conta atual e senha.",
  "provision.grayconfig.tenantSpaceId": "Id do espa\u00E7o do tenant",
  "provision.tenantspace.save.fail.107-080": "O n\u00FAmero de n\u00FAmeros UAP excede o limite.",
  "provision.tenantspace.config.choose.agent.registration.server": "Servidor de registro do agente",
  "provision.callcenterinstanceslist.table.ctinodefir": "Informa\u00E7\u00F5es do n\u00F3 CTI",
  "provision.tenantspace.resetpwdfaild.newpassword.not.meet.passwordrule": "A nova senha n\u00E3o atende \u00E0 regra de senha.",
  "provision.tenantspace.resetpwdfaild.newPwd.information.is.wrong": "Falha na modifica\u00E7\u00E3o. As informa\u00E7\u00F5es do novaPassword est\u00E3o erradas",
  "provision.tenantspace.table.delete": "Excluir",
  "chat.client.message.gladtoServe": ". fico feliz em ajudar.",
  "provision.tenantspace.save.fail.120622": "O representante de neg\u00F3cios \u00E9 o l\u00EDder da equipe e n\u00E3o pode executar opera\u00E7\u00F5es de altera\u00E7\u00E3o de grupo.",
  "provision.tenantspace.save.fail.120621": "A senha deve conter pelo menos tr\u00EAs dos quatro caracteres seguintes: letras mai\u00FAsculas inglesas\, letras min\u00FAsculas inglesas\, n\u00FAmeros e caracteres n\u00E3o alfanum\u00E9ricos\, e n\u00E3o podem ser iguais ao n\u00FAmero da conta ou \u00E0 ordem inversa do n\u00FAmero da conta.",
  "provision.tenantspace.save.fail.120624": "A senha de encaixe vdn modificada \u00E9 a mesma que a senha antiga.",
  "provision.tenantspace.save.fail.120620": "O comprimento da senha do agente \u00E9 menor que o m\u00EDnimo exigido para seguran\u00E7a: {0}.",
  "promotion.tenant.management.tenantedit.vdncreate.failed": "Falha ao criar ou modificar as informa\u00E7\u00F5es VDN.",
  "provision.callcent.error.portoutsiderange": "HeartbeatPort est\u00E1 fora da faixa de 1 a 65535.",
  "provision.tenantspace.save.fail.107-079": "O n\u00FAmero final do UAP \u00E9 menor que o n\u00FAmero inicial.",
  "chat.weChatConfig.label.appId": "ID do aplicativo",
  "provision.tenantspace.save.fail.107-078": "O comprimento do n\u00FAmero inicial e do n\u00FAmero final no UAP \u00E9 inconsistente.",
  "provision.tenantspace.save.fail.107-076": "A senha UAP \u00E9 inconsistente com a senha de confirma\u00E7\u00E3o.",
  "provision.tenantspace.save.fail.107-077": "O tipo de endere\u00E7o IP UAP n\u00E3o \u00E9 suportado.",
  "provision.tenantspace.save.fail.107-074": "A senha do UAP \u00E9 nula",
  "provision.tenantspace.save.fail.107-075": "O comprimento da senha UAP est\u00E1 fora do alcance.",
  "provision.tenantspace.save.fail.107-071": "O n\u00FAmero de usu\u00E1rio UAP \u00E9 nulo.",
  "provision.tenantspace.save.fail.107-073": "O endere\u00E7o IP UAP \u00E9 nulo.",
  "provision.tenantspace.save.fail.107-072": "N\u00FAmero de usu\u00E1rio UAP inv\u00E1lido.",
  "provision.tenantspace.save.fail.107-070": "Falha ao cancelar o registro do sistema UAP.",
  "chat.thirdportal.title": "Portal Web do Cliente",
  "provision.tenantspace.save.fail.120619": "O ID do representante de vendas n\u00E3o pode ser repetida.",
  "provision.tenantspace.save.fail.120618": "Os IDs do agente e do l\u00EDder da equipe s\u00E3o os mesmos.",
  "chat.webconfig.title": "Ativar canal Web",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.required": "Insira o segmento de ID do agente a ser adicionado e clique em OK.",
  "chat.agent.label.goAway": "Vai-te embora",
  "provision.tenantspace.save.fail.120617": "A letra da unidade referenciada pelo agente n\u00E3o existe.",
  "chat.weChatConfig.label.tenantId": "ID do tenant",
  "provision.tenantspace.save.fail.120616": "A configura\u00E7\u00E3o chamada referenciada pelo agente n\u00E3o existe.",
  "chat.agent.label.total": "Total",
  "provision.tenantspace.save.fail.120615": "O processo referenciado pelo agente n\u00E3o existe.",
  "provision.tenantspace.save.fail.120614": "O outro representante comercial citado pelo representante comercial n\u00E3o existe.",
  "provision.grayconfig.tenantSpaceName": "TenantSpaceName",
  "provision.tenantspace.config.asrchannelnums": "Inst\u00E2ncias do ASR",
  "provision.tenantspace.save.fail.120612": "O grupo de habilidades referenciado pelo agente n\u00E3o existe.",
  "provision.tenantspace.save.fail.120613": "A fun\u00E7\u00E3o do agente referenciada pelo agente n\u00E3o existe.",
  "provision.tenantspace.feature.moveAgents": "Agente m\u00F3vel",
  "provision.tenantspace.save.fail.120611": "A fila de habilidades referenciada pelo agente n\u00E3o existe.",
  "provision.tenantspace.save.fail.120972": "O tipo de subm\u00E9dia referenciado pela fila de habilidades n\u00E3o existe.",
  "provision.tenantspace.save.fail.120610": "A equipe de aplicativo do agente n\u00E3o existe.",
  "chat.agentConsole.message.content.image": "[Mensagem Imagem]",
  "provision.tenantspace.save.fail.120971": "Refer\u00EAncia do grupo de habilidades da fila de habilidades\, n\u00E3o pode ser exclu\u00EDda.",
  "provision.tenantspace.save.fail.120970": "A fila de habilidades \u00E9 referenciada pelo dispositivo compartilhado e n\u00E3o pode ser exclu\u00EDda.",
  "provision.callcenterinstanceslist.config.error": "Erro",
  "chat.weChatConfig.label.save": "Salvar configura\u00E7\u00E3o",
  "provision.tenantspace.save.fail.107-069": "O UAP falha ao consultar o status do registro.",
  "provision.tenantspace.save.fail.107-067": "O usu\u00E1rio multim\u00EDdia UAP falha ao alterar a senha.",
  "provision.tenantspace.save.fail.107-068": "Falha ao excluir o usu\u00E1rio multim\u00EDdia UAP.",
  "provision.tenantspace.save.fail.107-065": "Falha ao consultar o usu\u00E1rio multim\u00EDdia UAP.",
  "provision.tenantspace.save.fail.107-066": "Exce\u00E7\u00E3o de configura\u00E7\u00E3o do UAP",
  "provision.tenantspace.save.fail.107-064": "Falha ao adicionar o usu\u00E1rio multim\u00EDdia UAP.",
  "chat.client.label.privacyHint": "Dica de privacidade",
  "provision.tenantspace.agentinfo.message.selectagenttype": "Selecione um tipo de agente",
  "provision.callcenterinstanceslist.vcallcenterDeatil.specialStrValidateFailed": "O nome do call center n\u00E3o pode conter caracteres especiais.",
  "provision.tenantspace.agentinfo.message.searchworkno": "Insira o n\u00FAmero do agente",
  "provision.callcenterinstanceslist.config.ivrnumsError": "O n\u00FAmero de canais IVR deve ser maior do que a soma do n\u00FAmero de canais TTS e do n\u00FAmero de canais ASR.",
  "chat.skill.message.selectSkills": "Selecione habilidades.",
  "provision.tenantspace.save.fail.120609": "Falha ao excluir o agente referenciado por outro agente.",
  "provision.tenantspace.save.fail.120608": "O agente \u00E9 referenciado pela fila de habilidades e n\u00E3o pode ser exclu\u00EDdo.",
  "provision.tenantspace.save.fail.120607": "O agente \u00E9 referenciado pela fila de habilidade privada padr\u00E3o e n\u00E3o pode ser exclu\u00EDdo.",
  "provision.tenantspace.save.fail.120969": "A fila de habilidades \u00E9 referenciada pelo ACDDN e n\u00E3o pode ser exclu\u00EDda.",
  "provision.callcenterinstanceslist.vcallcenterDeatil.odfsPasswordKeyLengthValidateFailed": "O comprimento n\u00E3o pode exceder 50 caracteres.",
  "provision.callcenterinstanceslist.config.success": "Sucesso",
  "provision.tenantspace.save.fail.120606": "O agente \u00E9 referenciado pelo processo e n\u00E3o pode ser exclu\u00EDdo.",
  "provision.tenantspace.save.fail.120968": "A taxa de conex\u00E3o m\u00EDnima da fila de habilidades n\u00E3o pode exceder a taxa de conex\u00E3o esperada.",
  "provision.tenantspace.save.fail.120967": "O tipo de m\u00EDdia da fila de habilidades \u00E9 uma chamada de fax e uma caixa de correio deve ser configurada.",
  "provision.tenantspace.save.fail.120605": "O representante de vendas \u00E9 o l\u00EDder da equipe e n\u00E3o pode ser exclu\u00EDdo.",
  "provision.tenantspace.save.fail.120966": "A letra da unidade referenciada pela fila de habilidades n\u00E3o existe.",
  "provision.tenantspace.save.fail.120604": "O agente \u00E9 o administrador VDN padr\u00E3o e n\u00E3o pode ser exclu\u00EDdo.",
  "ccprovision.IVRSprogress.config.curcallNo": "Quantidade de chamadas simult\u00E2neas",
  "provision.tenantspace.save.fail.120603": "O administrador padr\u00E3o da VDN n\u00E3o pode modificar a fun\u00E7\u00E3o.",
  "provision.callcent.error.updateinfo": "Falha na atualiza\u00E7\u00E3o.",
  "provision.tenantspace.save.fail.120965": "A fila de habilidades \u00E9 referenciada pela configura\u00E7\u00E3o chamada e n\u00E3o pode ser exclu\u00EDda.",
  "provision.callcenterinstanceslist.config.voicecallnumsError": "O n\u00FAmero m\u00E1ximo de chamadas de voz simult\u00E2neas deve ser maior ou igual \u00E0 soma do n\u00FAmero de agentes de voz e do n\u00FAmero de canais de \u00E1udio e v\u00EDdeo IVR.",
  "provision.tenantspace.save.fail.120602": "O administrador padr\u00E3o da VDN n\u00E3o pode alterar a senha.",
  "provision.tenantspace.save.fail.120964": "A fila de habilidades \u00E9 referenciada pelo processo e n\u00E3o pode ser exclu\u00EDda.",
  "provision.callcent.platformserver.isqm": "iSQM",
  "provision.callcent.platformserver.isales": "iSales",
  "provision.tenantspace.save.fail.120601": "O administrador padr\u00E3o da VDN n\u00E3o pode modificar o nome.",
  "provision.tenantspace.save.fail.120963": "As filas de habilidades s\u00E3o referenciadas por representantes comerciais e n\u00E3o podem ser exclu\u00EDdas.",
  "chat.session.label.skillQueueName": "Nome da fila de habilidades",
  "provision.tenantspace.save.fail.120962": "A fila de habilidades \u00E9 referenciada pelo grupo de clientes e n\u00E3o pode ser exclu\u00EDda.",
  "chat.timerange.label.thirtydays": "Trinta dias",
  "provision.tenantspace.save.fail.120961": "A fila de habilidades \u00E9 referenciada pelo CDN e n\u00E3o pode ser exclu\u00EDda.",
  "provision.tenantspace.save.fail.120960": "A fila de habilidades \u00E9 referenciada por outras filas de habilidades e n\u00E3o pode ser exclu\u00EDda.",
  "provision.callcent.error.deleteobsserver.associationvcallcener": "O n\u00FAmero do servidor obs selecionado est\u00E1 associado ao tenant e n\u00E3o pode ser exclu\u00EDdo.",
  "provision.tenantspace.config.IVR": "Processo IVR",
  "provision.tenantspace.config.SIP": "Servidor SBC",
  "provision.tenantspace.config.error": "Falha ao configurar o espa\u00E7o do Tenant. Verifique as configura\u00E7\u00F5es relacionadas ou entre em contato com o administrador.",
  "chat.common.message.success": "Sucesso",
  "provision.tenantspace.save.fail.120959": "A fila de habilidades \u00E9 referenciada pela fila de habilidades privadas padr\u00E3o e n\u00E3o pode ser exclu\u00EDda.",
  "provision.tenantspace.save.fail.120958": "A configura\u00E7\u00E3o chamada referenciada pela fila de habilidades n\u00E3o existe.",
  "provision.callcenterinstanceslist.softPhone.endNumber": "N\u00FAmero final",
  "provision.tenantspace.save.fail.120957": "O processo referenciado pela fila de habilidades n\u00E3o existe.",
  "provision.tenantspace.save.fail.120955": "A fila de habilidades referenciada pela fila de habilidades n\u00E3o existe.",
  "provision.tenantspace.save.fail.120954": "O tipo de servi\u00E7o referenciado pela fila de habilidades n\u00E3o existe.",
  "provision.tenantspace.save.fail.120956": "O agente referenciado pela fila de habilidades n\u00E3o existe.",
  "provision.tenantspace.save.fail.120953": "Elementos de habilidade na fila de habilidades s\u00E3o duplicados.",
  "provision.tenantspace.status.invalid.success": "Revoga\u00E7\u00E3o bem-sucedida.",
  "provision.callcenterinstanceslist.config.ccIdError": "O n\u00FAmero do call center foi usado.",
  "provision.callcenterinstanceslist.table.deleteConfimMessage": "Tem certeza de que quer excluir a central de atendimento selecionada e a configura\u00E7\u00E3o relacionada?",
  "provision.callcent.platformserver.ccfs": "Servi\u00E7o de Arquivo da Central de Atendimento (CCFS)",
  "provision.tenantspace.save.fail.107-045": "O VDN do sistema exclui o c\u00F3digo de exce\u00E7\u00E3o onde VDNID e VDNName n\u00E3o correspondem.",
  "provision.encryptinfo.updateTime": "Atualizado em",
  "provision.tenantspace.save.fail.107-044": "Exce\u00E7\u00E3o de falha de exclus\u00E3o VDN do sistema.",
  "provision.tenantspace.save.fail.107-043": "Exce\u00E7\u00E3o de falha de consulta VDN do sistema.",
  "provision.tenantspace.save.fail.107-042": "Exce\u00E7\u00E3o de falha de modifica\u00E7\u00E3o VDN do sistema.",
  "chat.webconfig.label.windowTitle": "Chat Window Title",
  "provision.tenantspace.table.tenantSpaceName": "Espa\u00E7o do tenant",
  "provision.tenantspace.save.fail.107-040": "Exce\u00E7\u00E3o de falha de exclus\u00E3o do processo de servi\u00E7o IVR.",
  "provision.tenantspace.save.fail.107-041": "Falha ao adicionar VDN do sistema.",
  "provision.authconfig.description.empty.error": "a descri\u00E7\u00E3o n\u00E3o pode estar vazia.",
  "provision.tenantspace.config.fail.modifydata.tenant.feature.inDatabase": "Falha ao modificar os recursos do Tenant para o banco de dados.",
  "provision.tenantspace.table.invalidTenantSpace": "Esta opera\u00E7\u00E3o n\u00E3o pode ser cancelada ap\u00F3s a revoga\u00E7\u00E3o\, confirme se o Tenant precisa ser revogado!",
  "provision.callcent.label.closeFaule": "Desativar notifica\u00E7\u00F5es de falha",
  "provision.tenantspace.save.fail.107-039": "Exce\u00E7\u00E3o de falha de consulta de processo de servi\u00E7o IVR.",
  "provision.tenantspace.cc.videoAgentCurCapacity": "Quantidade de Agentes de v\u00EDdeo",
  "provision.tenantspace.save.fail.107-038": "Exce\u00E7\u00E3o de falha de modifica\u00E7\u00E3o do processo de servi\u00E7o IVR.",
  "provision.callcent.platformserver.port": "N\u00FAmero da porta de servi\u00E7o",
  "provision.callcenterinstanceslist.vcallcenterDeatil.whiteNumValidateFailed": "Apenas d\u00EDgitos s\u00E3o permitidos.",
  "provision.tenantspace.save.fail.107-036": "Exce\u00E7\u00E3o de falha de exclus\u00E3o de c\u00F3digo de acesso ao sistema.",
  "provision.callcenterinstanceslist.label.asrchannelUseRate": "Uso de ASR",
  "provision.tenantspace.save.fail.107-037": "Exce\u00E7\u00E3o de falha de aumento do processo de servi\u00E7o IVR.",
  "provision.tenantspace.save.fail.107-034": "Exce\u00E7\u00E3o de falha de modifica\u00E7\u00E3o do c\u00F3digo de acesso ao sistema.",
  "provision.tenantspace.save.fail.107-035": "A consulta do c\u00F3digo de acesso do sistema falha anormalmente.",
  "provision.tenantspace.save.fail.107-033": "Falha de adi\u00E7\u00E3o de c\u00F3digo de acesso ao sistema \u00E9 anormal.",
  "provision.accesscode.table.multimedia": "Multim\u00EDdia",
  "provision.tenantspace.agentUsage.iszero": "Configure primeiro os recursos do agente de voz e do ASR.",
  "provision.tenantspace.save.fail.107-032": "Exce\u00E7\u00E3o de falha de aperto de m\u00E3o de assinatura.",
  "provision.tenantspace.save.fail.107-031": "Exce\u00E7\u00E3o de falha de cancelamento de assinatura.",
  "provision.tenantspace.save.fail.107-030": "Exce\u00E7\u00E3o de assinatura.",
  "provision.callcenterinstanceslist.table.videoAgentCurCapacity": "Quantidade de Agentes de v\u00EDdeo",
  "ccprovision.recorddrive.config.recorddrivename": "Nome da letra do Drive",
  "provision.callcent.error.errorinfo": "Falha na cria\u00E7\u00E3o.",
  "provision.tenantspace.config.softphoneassign.tip": "Insira o segmento de n\u00FAmeros de softphone.",
  "provision.callcenterinstanceslist.vcallcenter.maxVersatileAgents": "Agentes vers\u00E1teis",
  "provision.tenantspace.authenticationsystem.clientName": "Sistema de autentica\u00E7\u00E3o de terceiros",
  "provision.tenantspace.save.fail.107-029": "Exce\u00E7\u00E3o de tempo limite da solicita\u00E7\u00E3o de assinatura.",
  "chat.client.greeting.afternoon": "Boa tarde!",
  "provision.tenantspace.save.fail.107-027": "Exce\u00E7\u00E3o de falha de registro de assinatura.",
  "provision.tenantspace.save.fail.107-028": "Exce\u00E7\u00E3o de falha de solicita\u00E7\u00E3o de assinatura.",
  "provision.tenantspace.save.fail.107-026": "Exce\u00E7\u00E3o de falha de assinatura.",
  "provision.callcenterinstanceslist.vcallcenter.asrchannelnums": "N\u00FAmeros de canais ASR",
  "provision.tenantspace.save.fail.107-025": "O nome do arquivo do processo IVR est\u00E1 vazio.",
  "provision.tenantspace.save.fail.107-024": "Exce\u00E7\u00E3o de falha de consulta de processo IVR.",
  "provision.tenantspace.save.fail.107-022": "Exce\u00E7\u00E3o de falha de modifica\u00E7\u00E3o VDN.",
  "provision.callcent.error.deleteobsserverdesinfo": "Certifique-se de que o nome do servidor obs n\u00E3o existe.",
  "provision.callcenterinstanceslist.table.ctiversion": "Vers\u00E3o CTI",
  "provision.tenantspace.save.fail.107-023": "Exce\u00E7\u00E3o de falha de consulta VDN.",
  "provision.tenantspace.save.fail.107-021": "Falha ao excluir a configura\u00E7\u00E3o da parte chamada.",
  "provision.tenantspace.save.fail.107-020": "A consulta chamada falhou anormalmente.",
  "provision.tenantspace.chooseobsdatasource.thirdPartyDbSource": "Origens de Dados de Terceiros",
  "provision.tenantspace.cc.agentCurCapacity": "Quantidade do Agente Usado",
  "provision.tenantspace.config.amsNumber.positiveInteger": "O n\u00FAmero de chamadas an\u00F4nimas simult\u00E2neas n\u00E3o pode estar vazio e deve ser um inteiro positivo.",
  "provision.tenantspace.agentinfo.message.video": "V\u00EDdeo",
  "provision.tenantspace.save.fail.107-019": "A modifica\u00E7\u00E3o chamada falhou anormalmente.",
  "provision.softphone.label.issyncuap.no": "N\u00E3o",
  "provision.tenantspace.save.fail.107-018": "Falha ao adicionar a configura\u00E7\u00E3o da parte chamada.",
  "provision.callcenterinstanceslist.config.interfacepassword": "Senha da interface de configura\u00E7\u00E3o de CTI",
  "provision.tenantspace.save.fail.107-017": "Exce\u00E7\u00E3o de falha de exclus\u00E3o de fun\u00E7\u00E3o.",
  "provision.tenantspace.save.fail.107-015": "Exce\u00E7\u00E3o de falha de modifica\u00E7\u00E3o de fun\u00E7\u00E3o.",
  "provision.tenantspace.save.fail.107-013": "Falha ao excluir o agente.",
  "provision.tenantspace.save.fail.107-014": "Falha ao aplicar a fun\u00E7\u00E3o.",
  "provision.tenantspace.save.fail.107-016": "Exce\u00E7\u00E3o de falha de consulta de fun\u00E7\u00E3o.",
  "provision.tenantspace.save.fail.107-011": "Uma exce\u00E7\u00E3o ocorre ao modificar um agente ou um lote de agentes.",
  "provision.tenantspace.save.fail.107-012": "Falha ao encontrar o agente.",
  "provision.tenantspace.save.fail.107-010": "Uma exce\u00E7\u00E3o ocorre ao adicionar um agente ou um lote de agentes.",
  "provision.callcenterinstanceslist.vcallcenter.syncVccSuccess": "Sincroniza\u00E7\u00E3o conclu\u00EDda.",
  "provision.tenant.management.batchcreate.error.accesscodenumber": "Um m\u00E1ximo de 100 c\u00F3digos de acesso s\u00E3o permitidos.",
  "chat.agent.label.consumption": "Consumption",
  "provision.tenantspace.status.stop.fail": "Falha ao suspender.",
  "provision.tenantspace.status.active.success": "Ativa\u00E7\u00E3o bem-sucedida.",
  "provision.tenantspace.save.fail.107-007": "Falha na autentica\u00E7\u00E3o antes da configura\u00E7\u00E3o da interface de configura\u00E7\u00E3o.",
  "provision.callcent.success.setinfo": "Definido com sucesso.",
  "provision.tenantspace.save.fail.107-005": "Falha ao se comunicar com a interface de configura\u00E7\u00E3o.",
  "provision.tenantspace.config.vccName": "Nome do VCC",
  "provision.tenantspace.save.fail.107-004": "Conta de administrador de configura\u00E7\u00E3o inv\u00E1lida.",
  "provision.tenantspace.save.fail.107-003": "Senha do administrador de configura\u00E7\u00E3o incorreta\, bloqueio da conta ap\u00F3s tentar novamente muitas vezes.",
  "provision.tenant.management.adminpassword": "Senha",
  "provision.tenantspace.save.fail.107-002": "O par\u00E2metro de entrada \u00E9 inv\u00E1lido\, o campo obrigat\u00F3rio est\u00E1 vazio.",
  "provision.obs.sync.needDelete": "O seguinte OBS foi vinculado a tenants e precisa ser desvinculado manualmente e\, em seguida\, sincronizado:",
  "provision.tenantspace.save.fail.107-000": "As informa\u00E7\u00F5es de login armazenadas em cache s\u00E3o inv\u00E1lidas\, voc\u00EA precisa fazer login novamente.",
  "provision.tenantspace.save.fail.107-001": "Exce\u00E7\u00E3o lan\u00E7ada ao chamar a interface de configura\u00E7\u00E3o.",
  "chat.session.label.created_endTime": "Hora de t\u00E9rmino",
  "provision.accesscode.table.beginNumber": "Iniciar c\u00F3digo de acesso",
  "provision.tenantspace.save.fail.120905": "A letra da unidade referenciada pelo tipo de neg\u00F3cio n\u00E3o existe.",
  "provision.tenantspace.save.fail.120904": "Falha ao excluir o tipo de servi\u00E7o referenciado pelo tempo de trabalho padr\u00E3o.",
  "provision.tenantspace.selectStastus.active": "Ativado",
  "provision.callcenterinstanceslist.config.workNoError": "A ID do funcion\u00E1rio inicial deve estar na faixa de 101 a 50000.",
  "provision.tenantspace.save.fail.120903": "O tipo de neg\u00F3cio \u00E9 referenciado pela fila de habilidades e n\u00E3o pode ser exclu\u00EDdo.",
  "chat.client.greeting.morning": "Bom dia!",
  "provision.tenantspace.save.fail.120902": "O tipo de neg\u00F3cio \u00E9 referenciado pelo processo e n\u00E3o pode ser exclu\u00EDdo.",
  "provision.tenantspace.save.fail.120901": "Descri\u00E7\u00E3o duplicada do tipo de neg\u00F3cio.",
  "chat.common.message.error": "Erro",
  "provision.callcent.success.saveinfo": "Criado com sucesso.",
  "chat.usefulexpressions.message.createFail": "Falha na cria\u00E7\u00E3o da express\u00E3o.",
  "provision.tenant.management.tenantcreate.emailillegal": "Endere\u00E7o de email inv\u00E1lido.",
  "provision.accesscode.error.transfer.queryinterface": "Falha na consulta dos dados da plataforma.",
  "provision.callcenterinstanceslist.sipServer.singledeleteconfim": "Tem certeza de que quer excluir o servidor de registro selecionado?",
  "provision.tenantspace.statusRecordTime.12Month": "12 meses",
  "provision.tenantspace.save.fail": "Falha ao salvar.",
  "provision.tenantspace.config.worknoassign.filternumber.error": "O n\u00FAmero exclu\u00EDdo n\u00E3o atende aos requisitos.",
  "provision.tenantspace.resetpwdfaild.user.is.locked": "Falha ao alterar a senha porque o usu\u00E1rio est\u00E1 bloqueado.",
  "provision.tenant.management.tenantcreate.dds.failed": "Falha ao criar o espa\u00E7o do Tenant no DDS.",
  "provision.callcenterinstanceslist.vcallcenter.ivrchannels": "Canais IVR de \u00E1udio",
  "chat.webconfig.label.greeting.placeholder": "Insira palavras que ser\u00E3o enviadas ao usu\u00E1rio final quando o chat for iniciado.",
  "provision.tenantspace.config.choose.anonymous.call.server.type": "Tipo de servidor de chamadas an\u00F4nimo",
  "provision.tenantspace.table.ttschannelnums": "Quantidade TTS",
  "provision.tenant.management.tenantname.alphavalidateFailed": "Existem caracteres inv\u00E1lidos. Somente letras\, d\u00EDgitos e sublinhados (_) s\u00E3o suportados.",
  "provision.callcenterinstanceslist.vcallcenter.invalidVccListPromt": "Existem call centers virtuais no AICC onde o VDN est\u00E1 ausente no WAS. O VccId \u00E9",
  "chat.weChatConfig.label.search": "Pesquisar",
  "provision.tenantspace.resetpwdfaild.user.not.exist": "Falha ao alterar a senha porque o usu\u00E1rio n\u00E3o existe.",
  "ccprovision.IVRSprogress.config.tenantname": "Tenant",
  "provision.tenantspace.chooseobsdatasource.platformDbSource": "Origens de Dados da Plataforma",
  "provision.callcent.error.paramNotavailable": "O par\u00E2metro de solicita\u00E7\u00E3o est\u00E1 vazio.",
  "provision.tenantspace.config.tenant.featurevalue.not.digits": "As informa\u00E7\u00F5es de recurso atribu\u00EDdas ao tenant s\u00E3o inv\u00E1lidas.",
  "chat.skill.label.skillName": "Fila de habilidades",
  "provision.callcenterinstanceslist.IVRSprogress.Title": "Configura\u00E7\u00E3o do processo IVR",
  "provision.tenant.management.mobile": "Telefone",
  "ccprovision.vcallecenter.config.popup.adminpassword": "Alterar senha do administrador",
  "provision.tenant.management.batchcreate.error.accesscode.inadequacy": "O n\u00FAmero de c\u00F3digos de acesso n\u00E3o pode ser menor do que o de espa\u00E7os tenants.",
  "provision.recorddrive.selectneed.delete.recorddrive": "Selecione a letra da unidade para excluir",
  "provision.callcenterinstanceslist.config.systemAdmin": "ID do administrador de CTI",
  "provision.tenantspace.config.param.error.agentUsage": "Insira o n\u00FAmero de agentes de voz.",
  "provision.callcenterinstanceslist.softPhone.usedselect.isnotoccupied": "N\u00E3o utilizado",
  "provision.tenantspace.config.worknoassign.chooseworknoscope.noempty": "As informa\u00E7\u00F5es de n\u00FAmero s\u00E3o obrigat\u00F3rias.",
  "provision.callcent.error.saveccgateway.ipportinfo": "Falha na cria\u00E7\u00E3o. Certifique-se de que o endere\u00E7o IP e o n\u00FAmero da porta do CC-Gateway ainda n\u00E3o existam.",
  "provision.callcenterinstanceslist.ccgateway.idleRegAgentNum": "Quantidade de Agente dispon\u00EDvel",
  "provision.tenantspace.ivr.idlechnNum": "Quantidade de chamada simult\u00E2nea restante",
  "provision.callcenterinstanceslist.table.hasVcallcenterConfim": "O call center configurado com o call center virtual n\u00E3o pode ser exclu\u00EDdo.",
  "provision.tenantspace.config.agentUsage": "Agentes",
  "provision.callcenterinstanceslist.table.nodemodesingle": "Modo \u00FAnico",
  "provision.tenantspace.input.agentnum": "Defina o n\u00FAmero de agentes a serem solicitados.",
  "provision.tenantspace.config.isAutoAssignSoftPhone": "Se o n\u00FAmero de softphone \u00E9 alocado automaticamente ao VCC",
  "chat.session.label.alias": "Customer Alias",
  "provision.tenantspace.detail.apply.name": "Candidato",
  "provision.tenantspace.config.choose.anonymous.call.server": "Servidor de chamada an\u00F4nima",
  "provision.callcenterinstance.accesscode.batchcreate.fail.processingtask": "Processando a tarefa de cria\u00E7\u00E3o de c\u00F3digos de acesso em lotes",
  "provision.callcent.error.portValidateFailed": "HeartbeatPort n\u00E3o \u00E9 o n\u00FAmero.",
  "provision.callcenterinstanceslist.config.adminWnFrom": "ID do empregado inicial do administrador",
  "provision.callcent.pool.select.different.amsservice.error": "Diferentes n\u00F3s devem selecionar o mesmo tipo de servidor de chamadas an\u00F4nimas.",
  "provision.tenantspace.config.selectedworkNo": "IDs de trabalho {0} selecionados {1}",
  "chat.weChatConfig.message.savesuccess": "Configure successfully\uFF01",
  "chat.weChatConfig.message.deleteError": "Cannot delete these configuration items!",
  "provision.callcent.error.updateccgateway.fail.syncccmanagement": "Falha na atualiza\u00E7\u00E3o\, sincroniza\u00E7\u00E3o do CC-Gateway para modificar as informa\u00E7\u00F5es para a sala de aluguel falhou.",
  "provision.tenantspace.resetpwdfaild": "Falha ao reinicializar a senha.",
  "provision.callcenterinstanceslist.table.nodemodeselect": "Modo Seletor",
  "provision.callcent.error.updateucsserver.ipandport.alreadyexist": "O IP e o n\u00FAmero da porta do servidor CCUCS j\u00E1 existem e n\u00E3o podem ser modificados.",
  "provision.tenantspace.config.softphoneassign.softphonescope.inadequacy": "O n\u00FAmero de softphone dispon\u00EDvel alocado \u00E9 insuficiente.",
  "provision.tenantspace.config.anonymous.call.number": "N\u00FAmero de chamadas an\u00F4nimas simult\u00E2neas",
  "provision.tenantspace.feature.outboundCallMarketingSupport.error": "Desative primeiro os recursos de SMS e email e WhatsApp marketing.",
  "provision.callcent.error.updateccgateway.ipandport.alreadyexist": "Falha na atualiza\u00E7\u00E3o\, confirme se o IP e a porta do CC-Gateway j\u00E1 existem.",
  "provision.callcenterinstanceslist.config.wnFrom": "Iniciar ID do Funcion\u00E1rio",
  "provision.encryptinfo.encryptKey": "Atualizando uma chave",
  "provision.tenantspace.feature.customData": "Personaliza\u00E7\u00E3o de dados",
  "provision.callcenterinstanceslist.label.eMediaAgentCurCapacity": "Quantidade de Agente Multim\u00EDdia",
  "provision.tenantspace.ivr.id": "N\u00E3o",
  "chat.client.label.forgotPassword": "Esqueceu-se da senha",
  "provision.tenantspace.feature.smsMarketingSupport": "Marketing SMS",
  "provision.tenantspace.config.chooseIVR": "Selecione um processo IVR.",
  "provision.tenantspace.cc.ivrchannelUsagePercent": "Uso do canal de IVR de \u00E1udio",
  "provision.callcent.success.oj8k": "Conclu\u00EDdo",
  "chat.weChatConfig.link.wechat": "Platforma WeChat",
  "chat.client.label.more": "Mais",
  "provision.tenantspace.input.searchName": "Insira o nome do call center a ser consultado.",
  "chat.client.alert.message.invildtoken": "A sess\u00E3o atingiu tempo limite ou sua conta logou em outro lugar\, feche a janela de bate-papo e tente novamente. Obrigado!",
  "provision.encryptinfo.validityPeriod": "Per\u00EDodo de validade (M\u00EAs)",
  "chat.skill.message.willDeleteSkills": "Are you sure you want to delete these skills?",
  "provision.ivr.selectivrprogress": "Selecione o processo IVR que deseja excluir.",
  "provision.callcenterinstanceslist.table.videoIvrChannels": "Quantidade de Canais IVR de v\u00EDdeo",
  "provision.callcenterinstanceslist.config.odfsappkey": "AppKey ODFS",
  "provision.callcenterinstanceslist.ucsServer.ucsServerIpUpdate": "Editar",
  "chat.skill.label.account": "Conta",
  "chat.timerange.label.threedays": "3 dias",
  "chat.skill.label.maxQueueNumber": "MaxQueueNumber",
  "provision.callcenterinstanceslist.table.agentUseRate": "Uso do Agente de Voz",
  "promotion.tenant.management.tenantedit.cc.null": "A ID do call center ao qual o call center virtual pertence est\u00E1 vazia.",
  "provision.tenantspace.select.stop": "Parado",
  "chat.weChatConfig.label.Domain": "Dom\u00EDnio",
  "provision.tenant.management.button.cancel": "Cancelar",
  "provision.encryptinfo.algorithm": "Algoritmo de criptografia",
  "chat.agent.title.createSkill": "Create Skill",
  "provision.callcenterinstanceslist.config.passwordConfirm": "Confirmar senha",
  "provision.callcenterinstanceslist.label.videoIvrChannelUseRate": "Uso do canal de IVR de v\u00EDdeo",
  "provision.tenant.management.companyName": "Empresa",
  "chat.client.message.noAgent": "Desculpe! N\u00E3o h\u00E1 nenhum agente dispon\u00EDvel\, tente novamente mais tarde.",
  "chat.webconfig.label.language": "Default Language",
  "provision.callcenterinstanceslist.softPhone.beginNumber": "N\u00FAmero inicial",
  "provision.tenantspace.config.agent.videoagent.ivr.more.zero": "O n\u00FAmero de agentes de voz\, agente de v\u00EDdeo\, agente multim\u00EDdia\, canais de \u00E1udio IVR e canais de v\u00EDdeo IVR n\u00E3o pode ser 0.",
  "provision.tenant.management.tenantcreate.sum.failed": "Falha ao criar o espa\u00E7o do Tenant na SUM.",
  "provision.ccworknos.table.updateaccessCode": "Editar",
  "provision.callcenterinstanceslist.softPhone.passwordconf": "Confirmar senha",
  "provision.callcenterinstanceslist.config.ivrchannelsValidate": "O n\u00FAmero de canais de \u00E1udio IVR \u00E9 menor do que o n\u00FAmero de canais realmente alocados no call center.",
  "provision.callcenterinstanceslist.config.ccNameUsed": "O nome do call center foi usado.",
  "chat.client.label.no": "N\u00E3o",
  "provision.tenantspace.detail": "Detalhes do tenant",
  "provision.callcenterinstance.accesscode.validateaccesscoderange": "Um m\u00E1ximo de 100 c\u00F3digos de acesso podem ser adicionados em lotes.",
  "provision.tenantspace.config.param.error.asrNumber": "Informe a quantidade ASR.",
  "provision.tenant.management.languagetype": "Tipo de idioma",
  "provision.tenantspace.status.stop.success": "Sucesso.",
  "provision.callcenterinstanceslist.ccgatewayServer.batchdeleteconfim": "Tem certeza de que deseja excluir todos os servidores CC-Gateway selecionados?",
  "chat.webconfig.message.demodirective": "Voc\u00EA pode abrir o link a seguir na nova janela Chrome Incognito para acessar as p\u00E1ginas de demonstra\u00E7\u00E3o.",
  "provision.tenantspace.clear": "Limpar",
  "chat.usefulexpressions.label.expressionDetail": "Detalhe da express\u00E3o",

  "chat.weChatConfig.label.wechat": "WeChat",
  "provision.callcent.error.savevccfirst": "O agente n\u00E3o est\u00E1 vinculado ao servidor de registro. Salve o VDN novamente.",
  "provision.tenantspace.modifyTimeZoneVerification": "As informa\u00E7\u00F5es de fuso hor\u00E1rio podem ser modificadas apenas para um espa\u00E7o de Tenant em estado uso comercial.",
  "provision.title.select": "Selecionar",
  "provision.tenantspace.obsServer.backup.datasource.name": "Nome da Origem de Dados Stand-by",
  "provision.tenantspace.feature.dualChannelRecSupport": "Grava\u00E7\u00E3o Dual-Track",

  "provision.tenantspace.basic.information": "Informa\u00E7\u00F5es B\u00E1sicas do Tenant",
  "provision.tenantspace.dstEndDateVerification": "A data de t\u00E9rmino n\u00E3o pode ser anterior \u00E0 data de in\u00EDcio",
  "provision.tenantspace.feature.dualChannelRecSupport.description": "Este recurso fornece fun\u00E7\u00F5es de grava\u00E7\u00E3o Dual-Track para o espa\u00E7o do Tenant.",
  "provision.tenant.management.timezone": "Fuso hor\u00E1rio",
  "provision.tenant.management.daylightSavingTime": "Hor\u00E1rio de Ver\u00E3o",
  "provision.tenant.management.tenantcreate.timezoneInfo": "Informa\u00E7\u00E3o de fuso hor\u00E1rio",
  "provision.tenant.management.DSTOffset": "Deslocamento do fuso hor\u00E1rio (min)",
  "provision.tenant.management.DSTStartDate": "Data in\u00EDcio Hor\u00E1rio de Ver\u00E3o",
  "provision.tenant.management.DSTStartTime": "Hora in\u00EDcio Hor\u00E1rio de Ver\u00E3o",
  "provision.tenant.management.DSTEndDate": "Data t\u00E9rmino Hor\u00E1rio de Ver\u00E3o",
  "provision.tenant.management.DSTEndTime": "Hora t\u00E9rmino Hor\u00E1rio de Ver\u00E3o",
  "provision.tenantspace.option.month_1": "Janeiro",
  "provision.tenant.management.timezoneOffset": "Deslocamento do fuso hor\u00E1rio",
  "provision.tenantspace.option.month_4": "Abril",
  "provision.tenantspace.option.month_2": "Fevereiro",
  "provision.tenantspace.option.month_5": "Maio",
  "provision.tenantspace.option.month_3": "Mar\u00E7o",
  "provision.tenantspace.option.month_8": "Agosto",
  "provision.tenantspace.option.month_6": "Junho",
  "provision.tenantspace.option.month_7": "Julho",
  "provision.tenantspace.option.month_10": "Outubro",
  "provision.tenantspace.option.month_11": "Novembro",
  "provision.tenantspace.option.month_12": "Dezembro",
  "provision.tenantspace.option.weeklist_fir": "Primeiro",
  "provision.tenantspace.option.weeklist_sec": "Segundo",
  "provision.tenantspace.option.weeklist_tid": "Terceiro",
  "provision.tenantspace.option.month_9": "Setembro",
  "provision.tenantspace.option.weeklist_fth": "Quarto",
  "provision.tenantspace.option.weeklist_fthtolast": "Faltando 4",
  "provision.tenantspace.option.weeklist_tidtolast": "Faltando 3",
  "provision.tenantspace.option.weeklist_firtolast": "\u00DAltimo",
  "provision.tenantspace.option.weekday_sun": "Domingo",
  "provision.tenantspace.option.weeklist_sectolast": "Penultimo",
  "provision.tenantspace.option.weekday_tue": "Ter\u00E7a",
  "provision.tenantspace.option.weekday_mon": "Segunda",
  "provision.tenantspace.option.weekday_thu": "Quinta",
  "provision.tenantspace.option.weekday_wed": "Quarta",
  "provision.tenantspace.option.weekday_sat": "S\u00E1bado",
  "provision.tenantspace.timezone": "Fuso hor\u00E1rio padr\u00E3o",
  "provision.tenantspace.timezone.updateconfim": "Confirmar",
  "provision.tenantspace.timezone.updatemessage": "Modificar as informa\u00E7\u00F5es de fuso hor\u00E1rio pode afetar o processamento de neg\u00F3cios durante o per\u00EDodo de transi\u00E7\u00E3o.",
  "provision.tenantspace.option.weekday_fri": "Sexta",
  "provision.tenantspace.verification.fail": "Falha ao validar o formato de hora.",
  "provision.tenantspace.save.oldsuccess": "Informa\u00E7\u00F5es n\u00E3o alteradas",
  "provision.tenantspace.config.agentnum.limit": "O n\u00FAmero de agentes inscritos por vez n\u00E3o pode exceder {0}.",
  "provision.tenantspace.config.amsobjnum.unequal": "Certifique-se de que a configura\u00E7\u00E3o do servidor de chamadas an\u00F4nimas seja a mesma. Selecione ou n\u00E3o selecione nenhum.",
  "provision.tenantspace.dstoffsettime.verification.fail": "O valor de entrada \u00E9 um inteiro que varia de -1440 a 1440.",
  "provision.encryptinfo.modedecrease": "O modo CBC \u00E9 menos seguro do que o modo GCM. Tenha cuidado ao selecionar o modo CBC.",
  "provision.encryptinfo.strengthdecrease": "Diminuir a for\u00E7a diminui a seguran\u00E7a do algoritmo. Tenha cuidado ao selecionar esta op\u00E7\u00E3o.",

  "provision.web.validator.ip": "Endere\u00E7o IP inv\u00E1lido (IPv4 ou IPv6 ou nome de dom\u00EDnio)",
  "provision.systemparam.error.save": "Falha ao salvar os par\u00E2metros",
  "provision.systemparam.success.reset": "Redefinir o par\u00E2metro com sucesso",
  "provision.systemparam.success.save": "Par\u00E2metros salvos com sucesso",
  "provision.systemparam.success.refresh": "Par\u00E2metros atualizados com sucesso",
  "provision.systemparam.error.refresh": "Falha ao atualizar os par\u00E2metros",
  "provision.systemparam.error.reset": "Falha ao redefinir par\u00E2metros",
  "provision.systemparam.error.notmatchrule": "O par\u00E2metro n\u00E3o atende \u00E0 regra de verifica\u00E7\u00E3o",

  "provision.sysparam.config.search": "Pesquisar",
  "provision.systemparam.error.noparamselected": "Selecione um par\u00E2metro primeiro",
  "provision.sysparam.config.refresh": "Atualizar",
  "provision.sysparam.config.itemname": "Nome do Par\u00E2metro",
  "provision.sysparam.config.reset": "Reiniciar",
  "provision.sysparam.config.itemdesc": "descri\u00E7\u00E3o",
  "provision.sysparam.config.value": "Par\u00E2metro Valor",
  "provision.sysparam.config.opterate": "Opera\u00E7\u00E3o",
  "provision.sysparam.config.save": "Salvar",
  "provision.sysparam.config.checkmessage": "Cont\u00E9m caracteres especiais (" < "\, " > "\, " / ",, etc.)",
  "provision.sysparam.config.itemname.tips": "Insira um nome de par\u00E2metro.",
  "provision.sysparam.config.select.prompt": "dicas",
  "provision.sysparam.config.select.null": "Selecione um par\u00E2metro.",
  "provision.sysparam.config.select.count": "Um m\u00E1ximo de 10 par\u00E2metros podem ser selecionados.",
  "provision.sysparam.config.item.detail": "Informa\u00E7\u00E3o do par\u00E2metro",
  "provision.sysparam.config.mod": "editar",
  "provision.sysparam.config.item.catalog": "Tipo de Par\u00E2metro",
  "provision.systemparam.error.validate.inner": "Validar par\u00E2metro falhou",
  "provision.systemparam.error.validate.ruleinvalid": "A regra de verifica\u00E7\u00E3o \u00E9 inv\u00E1lida",
  "provision.systemparam.error.validate.ruleregexinvalid": "Erro de sintaxe de regra de valida\u00E7\u00E3o",
  "provision.sysparam.config.title": "Par\u00E2metros do sistema",
  "provision.systemparam.error.validate.notmatchrule": "O par\u00E2metro de entrada n\u00E3o corresponde \u00E0 regra de verifica\u00E7\u00E3o",
  "provision.systemparam.error.validate.notmatchruleregex": "O par\u00E2metro de entrada n\u00E3o corresponde \u00E0 express\u00E3o regular da regra de verifica\u00E7\u00E3o",
  "provision.systemparam.error.validate.notmatchrulealpha": "Por favor\, insira alfanum\u00E9ricos ou sublinhados\, e apenas comece com letras ou sublinhados",
  "provision.systemparam.error.validate.watermarkinfo.notmatchruleregex": "O par\u00E2metro permite apenas 0 a 20 caracteres contendo letras mai\u00FAsculas e min\u00FAsculas\, n\u00FAmeros ou -_/{'@'}.",
  "provision.systemparam.error.validate.notmatchruleip": "Por favor\, digite um endere\u00E7o IPV4 ou IPV6 v\u00E1lido",
  "provision.systemparam.error.validate.notmatchruledigits": "Por favor\, insira um n\u00FAmero inteiro positivo v\u00E1lido",
  "provision.systemparam.error.validate.notmatchrulemax": "O par\u00E2metro de entrada excede o m\u00E1ximo",
  "provision.systemparam.error.validate.notmatchrulemaxlength": "O par\u00E2metro de entrada excede o comprimento m\u00E1ximo",
  "provision.systemparam.error.validate.notmatchruleminlength": "O par\u00E2metro de entrada \u00E9 menor que o comprimento m\u00EDnimo",
  "provision.systemparam.error.validate.notmatchruleemail": "Por favor\, digite um endere\u00E7o de e-mail v\u00E1lido",
  "provision.systemparam.error.validate.notmatchruleoption": "O par\u00E2metro de entrada n\u00E3o est\u00E1 no intervalo de enumera\u00E7\u00E3o",
  "provision.systemparam.error.validate.notmatchrulemin": "O par\u00E2metro de entrada \u00E9 inferior ao m\u00EDnimo",
  "provision.systemparam.error.validate.notmatchrulerangelength": "O par\u00E2metro de entrada excede o comprimento m\u00E1ximo ou est\u00E1 abaixo do comprimento m\u00EDnimo",
  "provision.systemparam.error.validate.notmatchrulerange": "O par\u00E2metro de entrada excede o valor m\u00E1ximo ou est\u00E1 abaixo do valor m\u00EDnimo",
  "provision.systemparam.error.validate.notmatchrulerequired": "O par\u00E2metro de entrada \u00E9 obrigat\u00F3rio",
  "provision.systemparam.error.validate.notmatchruleurl": "Por favor\, insira um endere\u00E7o URL v\u00E1lido",
  "provision.systemparam.error.validate.notmatchrulenumber": "Por favor\, digite um n\u00FAmero",
  "provision.systemparam.error.validate.userNotExists": "Por favor\, insira uma conta de usu\u00E1rio v\u00E1lida",
  "provision.tenantspace.feature.operation": "Os dados do recurso do locat\u00E1rio est\u00E3o sendo processados no back-end. Visualize o resultado do processamento do recurso na p\u00E1gina atual 2 minutos depois.",
  "provision.log.downloadSK": "Baixar SK",
  "provision.ccp.redis.feature.success": "Os dados do recurso do locat\u00E1rio s\u00E3o executados com \u00EAxito.",
  "provision.ccp.redis.feature.failed": "Falha ao executar dados de recurso de locat\u00E1rio.",
  "provision.tenantspace.feature.agentSendLocalMediaFile": "O agente envia um arquivo multim\u00EDdia local",
  "provision.tenantspace.feature.agentSendLocalMediaFile.description": "Habilite o recurso de envio de arquivos multim\u00EDdia locais do agente para que os agentes possam enviar arquivos multim\u00EDdia locais no espa\u00E7o do locat\u00E1rio.",
  "provision.tenantspace.feature.emailAgentSendLocalAttachment": "Enviando anexos locais por agentes de e-mail",
  "provision.tenantspace.feature.emailAgentSendLocalAttachment.description": "Ative o recurso de envio de anexos locais do agente de e-mail para que o agente de e-mail possa enviar anexos locais.",

  "provision.tenantspace.feature.emailAgentCCAndBcc": "E-mail Agente Cc Bc",
  "pprovision.tenantspace.feature.emailAgentCCAndBcc.description": "Ative o recurso de c\u00F3pia de seguran\u00E7a do agente de e-mail. O espa\u00E7o de aluguel suporta a c\u00F3pia de seguran\u00E7a do agente de e-mail e a fun\u00E7\u00E3o de c\u00F3pia de seguran\u00E7a do e-mail.",
  "provision.tenantspace.feature.emailAgentForwarding": "Agentes encaminham mensagens",
  "pprovision.tenantspace.feature.emailAgentForwarding.description": "Ativar o recurso de encaminhamento de agentes de e-mail. Encaminhamento de e-mails de agentes de e-mail no quarto do locat\u00E1rio",

  "provision.speciallist.tip.userNoLength": "O n\u00FAmero de assinante n\u00E3o pode exceder 100 caracteres",
  "provision.speciallist.tip.reasonLength": "O comprimento do caractere do motivo de adi\u00E7\u00E3o n\u00E3o pode exceder 1024",

  "provision.speciallist.tip.timeVerification": "Selecione uma data maior que hoje e menor que 2038-01-19",
  "provision.tenantspace.feature.cobrowse": "Co-navega\u00E7\u00E3o",
  "provision.callcent.platformserver.cobrowse": "Servi\u00E7o de Co-browsing (Co-Browse)",
  "provision.tenantspace.feature.cobrowse.cobrowseMaxNumber": "N\u00FAmero m\u00E1ximo de conex\u00F5es de co-navega\u00E7\u00E3o",
  "provision.tenantspace.feature.cobrowse.description": "Esse recurso fornece a fun\u00E7\u00E3o de co-navega\u00E7\u00E3o para o espa\u00E7o do inquilino.",
  "provision.tenantspace.feature.cobrowse.needak": "Voc\u00EA precisa configurar as informa\u00E7\u00F5es AK antes de ativar o recurso de co-navega\u00E7\u00E3o.",
  "provision.tenantspace.feature.cobrowse.cobrowseMaxNumber.placeholder": "1 a 50000",
  "provision.tenantspace.feature.cobrowse.selectcobrowsevalue.invalid": "O n\u00FAmero m\u00E1ximo de conex\u00F5es de co-navega\u00E7\u00E3o varia de 1 a 50000.",
  "provision.tenantspace.feature.forceVideoSelfRecord": "Grava\u00E7\u00E3o de tela",
  "provision.tenantspace.feature.forceVideoSelfRecord.forcibleSelfRecord": "Auto-grava\u00E7\u00E3o for\u00E7ada da tela VDN",
  "provision.ccp.redis.feature.inprogress": "Os dados do recurso do inquilino est\u00E3o sendo executados.",
  "provision.tenantspace.feature.forceVideoSelfRecord.description": "Indica se o status da opera\u00E7\u00E3o da tela deve ser registrado automaticamente quando um agente processa uma chamada.",
  "provision.tenantspace.feature.forceVideoSelfRecord.percentage": "Registro por porcentagem",
  "provision.tenantspace.feature.forceVideoSelfRecord.attendant": "Ativado para todos os atendentes",
  "provision.tenantspace.feature.forceVideoSelfRecord.percentageScope": "Valor m\u00EDnimo 0\, valor m\u00E1ximo 100\, padr\u00E3o 100",
  "provision.tenantspace.feature.forceVideoSelfRecord.percentageFactor": "Coeficiente percentual de grava\u00E7\u00E3o de tela (%)",
  "provision.tenantspace.feature.forceVideoSelfRecord.settingError": "Configura\u00E7\u00E3o incorreta dos par\u00E2metros de grava\u00E7\u00E3o de tela",
  "provision.tenant.management.tenantmodify.cobrowse.akempty": "O locat\u00E1rio ativou o recurso de colabora\u00E7\u00E3o da Web. A informa\u00E7\u00E3o AK n\u00E3o pode estar vazia.",

  "provision.tenantspace.configureauthentication": "Configurar autentica\u00E7\u00E3o",
  "provision.spinfo.issuerId": "ID do emissor",
  "provision.spinfo.serviceUrl": "URL do servi\u00E7o",
  "provision.spinfo.logoutUrl": "URL de logout",
  "provision.spinfo.creator": "Criador",
  "provision.spinfo.createTime": "Criado em",
  "provision.spinfo.id": "ID",
  "provision.spinfo.description": "Descri\u00E7\u00E3o",
  "provision.spinfo.issuerId.search": "Insira o ID do Emissor",
  "provision.spinfo.validatehttp": "N\u00E3o corresponde ao formato URL",
  "provision.spinfo.idpfile": "Arquivo IDP",
  "provision.spinfo.certificate": "Conte\u00FAdo do certificado",
  "provision.spinfo.selecdeletetspinfo": "Selecione as informa\u00E7\u00F5es da controladora a serem exclu\u00EDdas!",
  "provision.spinfo.willdeletespinfo": "Tem certeza de que deseja excluir as informa\u00E7\u00F5es da controladora selecionada?",
  "provision.spinfo.modifyspinfosuccess": "As informa\u00E7\u00F5es do SP foram modificadas com sucesso.",
  "provision.spinfo.addspinfofailed": "Falha ao adicionar informa\u00E7\u00F5es da controladora!",
  "provision.spinfo.addspinfosuccess": "As informa\u00E7\u00F5es do SP foram adicionadas com sucesso.",
  "provision.spinfo.modifyspinfofailed": "Falha ao modificar as informa\u00E7\u00F5es do SP!",
  "provision.spinfo.deletespinfofailed": "Falha ao excluir as informa\u00E7\u00F5es da controladora!",
  "provision.spinfo.deletespinfosuccess": "Informa\u00E7\u00F5es do SP exclu\u00EDdas com sucesso!",
  "provision.spinfo.msg.certificateformatinfo": "Os identificadores inicial e final -----BEGIN CERTIFICATION-- e -----END CERTIFICATION--- n\u00E3o podem ser omitidos.",

  "provision.spinfo.beginlettervalidate": "Por favor\, comece com uma letra",
  "provision.accesscode.message.invalid": "Caracteres inv\u00E1lidos.",
  "provision.accesscode.table.callType": "Tipo de chamada",
  "provision.accesscode.table.call": "Chamadas recebidas e efetuadas",
  "provision.accesscode.table.callout": "Chamada de sa\u00EDda",
  "provision.accesscode.table.callin": "Chamada recebida",
  "provision.appversionmanagement.systemType": "Tipo de sistema",

  "provision.appversionmanagement.pleaseSelect": "-Selecione-",
  "provision.appversionmanagement.versionNumber": "N\u00FAmero da vers\u00E3o",
  "provision.appversionmanagement.pleaseEnter": "-Entre-",
  "provision.appversionmanagement.batchDelete": "Elimina\u00E7\u00E3o em lote",
  "provision.appversionmanagement.versionName": "Nome da vers\u00E3o",
  "provision.appversionmanagement.new": "Adicionar",
  "provision.appversionmanagement.forceUpdate": "Atualiza\u00E7\u00E3o for\u00E7ada",
  "provision.appversionmanagement.downloadAddress": "Endere\u00E7o de Download",
  "provision.appversionmanagement.updateContent": "Atualizar conte\u00FAdo",
  "provision.appversionmanagement.appPackage": "Pacote da vers\u00E3o",
  "provision.appversionmanagement.updateTime": "Hora da atualiza\u00E7\u00E3o",
  "provision.appversionmanagement.android": "Andr\u00F3ide",
  "provision.appversionmanagement.harmony": "HarmonyOS",
  "provision.appversionmanagement.iOS": "IOS",
  "provision.appversionmanagement.androidH5": "Android H5",
  "provision.accesscode.table.enterpriseName": "Nome da empresa",
  "provision.appversionmanagement.sureDeleteVersionNumber": "Tem certeza de que deseja excluir as informa\u00E7\u00F5es de vers\u00E3o?",
  "provision.appversionmanagement.historicalVersion": "Vers\u00E3o hist\u00F3rica",
  "provision.appversionmanagement.latestVersion": "\u00DAltima vers\u00E3on",
  "provision.appversionmanagement.iOSH5": "IOS H5",
  "provision.appversionmanagement.sureDeleteData": "Tem certeza de que deseja excluir os dados?",
  "provision.appversionmanagement.selectDataDeleted": "Selecione os dados a serem apagados.",
  "provision.appversionmanagement.queryFailed ": "Erro de pesquisa.",
  "provision.appversionmanagement.cannotEnterNumberSmaller": "O valor deve ser maior que o n\u00FAmero m\u00E1ximo da vers\u00E3o.",
  "provision.appversionmanagement.cannotContainSpecialCharacters": "O valor n\u00E3o pode conter os seguintes caracteres especiais: ~!{'@'}#$%&*()/\_=+{}:'<>?[]",
  "provision.tenantspace.feature.selectAppCubeSupport.description": "Ative o recurso AppCube para que o espa\u00E7o do locat\u00E1rio possa integrar a fun\u00E7\u00E3o AppCube.",
  "provision.tenantspace.feature.appCubeSupport": "AppCube",
  "provision.tenantspace.feature.appcube.close.error": "Antes de desativar o AppCube\, certifique-se de que os recursos {0} est\u00E3o desativados",
  "provision.tenantspace.feature.case.open.confirmmsg": "O recurso AppCube \u00E9 a base para executar o recurso Case e ser\u00E1 ativado ao mesmo tempo.",
  "provision.tenantspace.feature.appcubeIkbs.close.error": "Desabilitar o recurso da base de conhecimento antes de desativar o recurso AppCube.",
  "provision.tenantspace.feature.newcase.open.customcenter.confirmmsg": "Os recursos do Case 2.0 s\u00E3o a base do Case 2.0 e ser\u00E3o ativados ao mesmo tempo",
  "provision.tenantspace.feature.knowledge.open.confirmmsg": "O recurso AppCube \u00E9 a base para executar o recurso da base de conhecimento e ser\u00E1 ativado ao mesmo tempo.",


  "provision.softphone.label.authtype.uap": "Autentica\u00E7\u00E3o UAP",
  "provision.callcenterinstanceslist.softPhone.authtype": "M\u00E9todo de autentica\u00E7\u00E3o",
  "provision.tenantspace.feature.softphonenounifiedauth": "Autentica\u00E7\u00E3o unificada de softphones",

  "provision.softphone.label.authtype.unified": "Certifica\u00E7\u00E3o unificada",
  "provision.tenantspace.feature.softphonenounifiedauth.description": "Ative o recurso de autentica\u00E7\u00E3o unificada do softphone number. O locat\u00E1rio suporta a fun\u00E7\u00E3o de autentica\u00E7\u00E3o unificada do softphone number.",
  "provision.log.downloadIdpMetadtaXml": "Baixar IDP Metadata Xml",
  "provision.systemparam.url.safe.tip": "Protocolos inseguros s\u00E3o usados\, causando riscos de seguran\u00E7a",
  "provision.tenantspace.save.successwithsamevccid": "Salvo com sucesso. Existe um quarto com o mesmo n\u00FAmero de call center virtual que o quarto atual. Entre em contato com o administrador para resolver o problema.",
  "provision.appversion.msg.uploadfile.type.fail": "O tipo de arquivo carregado n\u00E3o atende aos requisitos.",
  "provision.appversion.msg.uploadfile.data.fail": "Os par\u00E2metros para carregar o arquivo est\u00E3o incorretos.",

  "provision.appversion.msg.uploadfile.0.success": "O arquivo carregado foi verificado com sucesso.",
  "provision.appversion.msg.uploadfile.3.fail": "Falha ao verificar o tamanho do arquivo carregado. Verifique o limite de tamanho do pacote de instala\u00E7\u00E3o do aplicativo m\u00F3vel em Configura\u00E7\u00F5es > Configura\u00E7\u00F5es de par\u00E2metro do sistema.",
  "provision.appversion.msg.uploadfile.2.fail": "Falha ao verificar o tipo de arquivo carregado.",
  "provision.appversion.msg.uploadfile.1.fail": "Falha ao verificar o nome do arquivo carregado.",
  "provision.appversion.msg.uploadfile.4.fail": "An\u00E1lise de inje\u00E7\u00E3o de arquivos carregados",
  "provision.appversion.msg.uploadfile.fail": "Falha ao carregar o arquivo. Fa\u00E7a upload do arquivo novamente.",
  "provision.appversion.msg.process.fail": "Falha ao processar as informa\u00E7\u00F5es de vers\u00E3o do aplicativo.",
  "provision.appversion.msg.process.add": "Adicionando informa\u00E7\u00F5es de vers\u00E3o do aplicativo",
  "provision.appversion.msg.process.modify": "Modificando as informa\u00E7\u00F5es de vers\u00E3o do aplicativo",
  "provision.appsitesmanagement.siteName": "Nome do site",

  "provision.appversion.msg.uploadfile.9.fail": "Falha ao verificar o arquivo carregado.",
  "provision.appsitesmanagement.batchDelete": "Exclus\u00E3o em massa",
  "provision.appsitesmanagement.pleaseEnter": "Endere\u00E7o do site",
  "provision.appsitesmanagement.new": "Novo",
  "provision.appsitesmanagement.siteAddr ": "Endere\u00E7o do site",
  "provision.appsitesmanagement.videoAvailable ": "Implanta\u00E7\u00E3o de video",
  "provision.appsitesmanagement.createTime ": "Criado em",
  "provision.appsitesmanagement.appcubeAvailable ": "Integra\u00E7\u00E3o com o AppCube",
  "provision.appsitesmanagement.creator ": "Criador",
  "provision.appsitesmanagement.modifyTime ": "Modificado em",
  "provision.appsitesmanagement.modifier ": "Modificado por",
  "provision.appsitesmanagement.sureSelectDataDeleted": "Selecione os dados a serem exclu\u00EDdos",
  "provision.appsitesmanagement.sureDeleteData": "Excluir este site pode fazer com que os usu\u00E1rios do aplicativo m\u00F3vel n\u00E3o consigam fazer login . Tem certeza de que deseja exclu\u00ED-lo ?",
  "provision.appsitesmanagement.sureBatchDeleteData": "Excluir estes sites pode fazer com que os usu\u00E1rios do aplicativo m\u00F3vel n\u00E3o consigam fazer login . Tem certeza de que deseja exclu\u00ED-los ?",
  "provision.appsitesmanagement.queryFailed ": "Erro de pesquisa",
  "provision.appsitesmanagement.siteDesc ": "Descri\u00E7\u00E3o do site",
  "provision.appsitesmanagement.cannotContainSpecialCharacters": "O valor n\u00E3o pode conter os seguintes caracteres especiais: ~!{'@'}#$%&*()/\_=+{}:'<>?[]",
  "provision.appsitesmanagement.details ": "Exibir",
  "provision.appsitesmanagement.enable": "Sim \, sim",
  "provision.appsitesmanagement.protocol.check": "Observe que o protocolo de rede usado pelo endere\u00E7o do site inserido atualmente n\u00E3o \u00E9 um protocolo de seguran\u00E7a e pode ser um risco de seguran\u00E7a se ele for usado",
  "provision.appversionmanagement.appPackageTips": "Somente o tipo de arquivo apk \u00E9 suportado",
  "provision.appversion.msg.uploadfile.strem.fail": "Falha ao gerar fluxo de arquivos",
  "provision.callcent.packagefile.saveinfo": "O pacote de vers\u00E3o est\u00E1 sendo processado. Espere at\u00E9 que a p\u00E1gina refresque o resultado do processamento do pacote de vers\u00E3o.",
  "provision.callcent.packagefile.updateinfo": "O pacote de vers\u00E3o est\u00E1 sendo processado. Por favor\, aguarde at\u00E9 que a p\u00E1gina refresque o resultado do processamento do pacote de vers\u00E3o.",
  "provision.appversion.msg.throttling.fail": "Crie ou modifique o limite de tr\u00E1fego da interface a cada 2 minutos. Tente novamente mais tarde.",
  "provision.appsitesmanagement.unable": "N\u00E3o",
  "provision.ccp.redis.packagefile.inprogress": "O pacote de vers\u00E3o est\u00E1 sendo carregado",
  "provision.ccp.redis.packagefile.failed": "Falha ao carregar o pacote de vers\u00E3o",
  "provision.ccp.redis.packagefile.success": "Pacote de vers\u00E3o carregado com sucesso",
  "provision.appversionmanagement.regVersion": "O n\u00FAmero da vers\u00E3o consiste em letras\, d\u00EDgitos e pontos. N\u00E3o pode come\u00E7ar ou terminar com um ponto.",
  "provision.appversionmanagement.protocol.check": "Por favor\, note que o protocolo de rede usado para o endere\u00E7o de download inserido n\u00E3o \u00E9 um protocolo de seguran\u00E7a. Se for usado\, pode haver um risco de seguran\u00E7a.",
  "provision.whiteListAddress.channel.push": "Endere\u00E7o de envio de mensagens do canal multim\u00EDdia",
  "provision.whiteListAddress.downlink.message.apifabric.push": "Endere\u00E7o de malha de API de Downstream de mensagem multim\u00EDdia",
  "provision.callcent.platformserver.account": "Conta de servi\u00E7o",
  "provision.callcent.platformserver.kafka": "Middleware de mensagens ( Kafka )",
  "provision.whiteListAddress.callout.push": "Endere\u00E7o de push do resultado da chamada de sa\u00EDda",
  "provision.whiteListAddress.internal.message.notification": "Endere\u00E7o IP e porta para chamar o servi\u00E7o de aplicativo para notifica\u00E7\u00E3o de mensagem interna",

  "provision.callcent.platformserver.kafka.switch.panel": "Se o servi\u00E7o est\u00E1 ativado",

  "provision.callcent.platformserver.certificateChoose": "Selecionar certificado",
  "provision.callcent.platformserver.es": "Servi\u00E7os de pesquisa (ElasticSearch)",
  "provision.tenantspace.feature.OneClickTwoCallSupport": "Chamada dupla com um toque",
  "provision.tenantspace.feature.OneClickTwoCallSupport.description": "Ative o recurso de chamada dupla com um clique . O quarto de aluguel suporta a fun\u00E7\u00E3o de chamada dupla com um clique.",
  "provision.tenantspace.feature.VoiceNotificationSupport.description": "Ative o recurso de notifica\u00E7\u00E3o de voz . O locat\u00E1rio suporta a fun\u00E7\u00E3o de notifica\u00E7\u00E3o de voz.",
  "provision.tenantspace.feature.VoiceNotificationSupport": "Notifica\u00E7\u00E3o de voz",

  "provision.tenantspace.feature.OneClickTwoCallSupport.featureParams.label": "Par\u00E2metros da chamada",
  "provision.tenantspace.feature.OneClickTwoCallSupport.featureParams.placeholder": "Configure de acordo com o n\u00FAmero de simultaneidade de IVR \, n\u00FAmero de simultaneidade de rede principal e n\u00FAmero de linhas simult\u00E2neas configuradas pelo locat\u00E1rio",
  "provision.tenantspace.feature.OneClickTwoCallSupport.featureParams.callNums.label": "N\u00FAmero de dados da chamada(1 a 10)",
  "provision.tenantspace.feature.VoiceNotificationSupport.featureParams.label": "Par\u00E2metros da chamada",
  "provision.tenantspace.feature.OneClickTwoCallSupport.featureParams.callPeriod.label": "Ciclo de chamada(1 a 60 segundos)",
  "provision.tenantspace.feature.VoiceNotificationSupport.featureParams.placeholder": "Configure de acordo com o n\u00FAmero de simultaneidade de IVR \, n\u00FAmero de simultaneidade de rede principal e n\u00FAmero de linhas simult\u00E2neas configuradas pelo locat\u00E1rio",

  "provision.tenantspace.feature.VoiceNotificationSupport.featureParams.callPeriod.label": "Ciclo de chamada(1 a 60 segundos)",
  "provision.tenantspace.feature.VoiceNotificationSupport.featureParams.callNums.label": "N\u00FAmero de dados da chamada(1 a 10)",
  "provision.subscribedetail.button.save": "Salvar",
  "provision.subscribedetail.pageTitle": "One-Click subscri\u00E7\u00E3o detalhes",
  "provision.subscribedetail.button.reset": "Redefinir",
  "provision.subscribedetail.button.search": "Pesquisar",
  "provision.subscribedetail.button.delete": "Apagar",
  "provision.subscribedetail.label.operate": "Opera\u00E7\u00E3o",
  "provision.subscribedetail.label.companyName": "Nome da empresa",
  "provision.subscribedetail.button.update": "Editar",
  "provision.subscribedetail.label.contactName": "Nome do contato",
  "provision.subscribedetail.label.phoneNo": "Telefone do contato",
  "provision.subscribedetail.label.createTime": "Tempo de aplica\u00E7\u00E3o",
  "provision.subscribedetail.label.companySize": "Tamanho da empresa",
  "provision.subscribedetail.label.enableStatus": "Resultados da subscri\u00E7\u00E3o",
  "provision.subscribedetail.label.emailStatus": "Resultado do envio de e-mail",
  "provision.subscribedetail.label.remark": "Coment\u00E1rio",
  "provision.subscribedetail.label.emailAddr": "E-mail do contato",
  "provision.subscribedetail.success.save": "Gravado com sucesso",
  "provision.subscribedetail.success.delete": "Exclu\u00EDdo com sucesso",
  "provision.subscribedetail.success.title": "Sucesso",
  "provision.subscribedetail.delete.title": "Confirma\u00E7\u00E3o de exclus\u00E3o",
  "provision.subscribedetail.error.title": "Falhou",
  "provision.subscribedetail.delete.confirm": "Tem certeza de que deseja excluir este detalhe de assinatura",
  "provision.subscribedetail.error.save": "Falha ao salvar",
  "provision.subscribedetail.error.query": "A consulta falhou",
  "provision.subscribedetail.error.query.maxDay_30": "O intervalo de consulta n\u00E3o pode ser maior que 30 dias",
  "provision.subscribedetail.enableStatus.value_0": "Inscreva-se com sucesso",
  "provision.subscribedetail.enableStatus.value_1": "A subscri\u00E7\u00E3o falhou",
  "provision.subscribedetail.emailStatus.value_0": "Falha ao enviar",
  "provision.subscribedetail.error.delete": "Falha ao excluir",
  "provision.subscribedetail.emailStatus.value_1": "Enviado com sucesso",
  "provision.subscribedetail.validate.beginwithalpha": "Deve come\u00E7ar com uma letra",
  "provision.subscribedetail.validate.laterThanEndTime": "A hora de in\u00EDcio n\u00E3o pode ser maior que a hora de t\u00E9rmino",
  "provision.subscribedetail.validate.alphavalidate": "Existem caracteres inv\u00E1lidos. Somente letras\, d\u00EDgitos e sublinhados (_) s\u00E3o suportados.",
  "provision.subscribedetail.createSource.value_0": "Site oficial HUAWEI CLOUD",

  "provision.subscribedetail.label.createSource": "Cria\u00E7\u00E3o de Origem",
  "provision.subscribedetail.createSource.value_1": "Site oficial Software Cloud",
  "provision.tenantspace.feature.dualCallAPPTransparentDisplaySupport": "Tarefas de Chamada de Aplicativo",
  "provision.tenantspace.feature.onlineCustomerServiceAssets.description": "Depois de ativar o recurso de ativos de atendimento ao cliente on-line\, os inquilinos podem incorporar o cliente de bate-papo on-line js chat desenvolvido pelo appcube em p\u00E1ginas de terceiros para realizar bate-papo on-line.",
  "provision.tenantspace.feature.dualCallAPPTransparentDisplaySupport.description": "Ative o recurso de tarefa de chamada do aplicativo para que os agentes sob o locat\u00E1rio possam processar tarefas de chamada no aplicativo m\u00F3vel .",

  "provision.tenantspace.feature.onlineCustomerServiceAssets": "Recursos de atendimento ao cliente on-line",
  "provision.tenantspace.feature.onlineCustomerServiceAssets.open.confirmmsg": "Os recursos AppCube e Intelligent IVR s\u00E3o a base da opera\u00E7\u00E3o dos recursos de atendimento ao cliente on-line e ser\u00E3o ativados ao mesmo tempo",

  "provision.tenantspace.feature.dualCallAppSupport.open.confirmmsg": "O recurso AppCube \u00E9 a base do recurso de chamada do aplicativo e ser\u00E1 ativado ao mesmo tempo",
  "provision.tenantspace.feature.smartivr.close.error": "Antes de desativar o recurso de IVR inteligente\, por favor\, desative o recurso de ativos de atendimento ao cliente on-line",
  "provision.tenant.management.tenantexport.viewTask": "Consultando Tarefas de Exporta\u00E7\u00E3o",
  "provision.tenant.management.tenantexport.cancel": "Cancela",
  "provision.tenant.management.tenantexport.addTask": "Criando uma Tarefa de Exporta\u00E7\u00E3o",
  "provision.tenant.management.tenantexport.exceedlimit1": " Um m\u00E1ximo de \{0} registros de dados podem ser exportados.",
  "provision.tenant.management.tenantexport.exceedlimit2": "As op\u00E7\u00F5es reduzem o intervalo de tempo de dados ou adicionam condi\u00E7\u00F5es de filtragem de dados.",
  "provision.tenant.management.tenantexport.confirm": "Agradecimento",
  "provision.tenant.management.tenantexport.addTaskSuccess": "A tarefa de exporta\u00E7\u00E3o foi adicionada com sucesso.",
  "provision.tenant.management.tenantexport.addTaskError": "Falha ao adicionar a tarefa de exporta\u00E7\u00E3o. Por favor\, verifique o registro.",
  "provision.label.tenantexport.exception": "Ocorreu uma exce\u00E7\u00E3o durante a gera\u00E7\u00E3o da tarefa de exporta\u00E7\u00E3o. Por favor\, verifique o registro.",
  "provision.label.tenantexport.parainvalid": "Falha ao verificar a condi\u00E7\u00E3o de exporta\u00E7\u00E3o.",
  "provision.label.tenantexport.paracomplexity": "A complexidade da senha n\u00E3o atende aos requisitos",
  "provision.common.message.error": "Erros",
  "provision.common.message.success": "Sucesso",
  "provision.label.tenantexport.parainnull": "Selecione os dados a serem exportados.",
  "provision.label.tenantexport.addExportTask": "Adicionando uma Tarefa de Exporta\u00E7\u00E3o",
  "provision.label.information": "dicas",
  "provision.label.tenantexport.confirm": "Agradecimento",
  "provision.label.tenantexport.viewExportTask": "Exibindo Tarefas de Exporta\u00E7\u00E3o",
  "provision.label.tenantexport.confirmMessage": "Tem certeza de que deseja criar uma tarefa de exporta\u00E7\u00E3o?",
  "provision.label.tenantexport.refresh": "atualizar",
  "provision.label.tenantexport.exportBeginTime": "Hor\u00E1rio de in\u00EDcio da exporta\u00E7\u00E3o",
  "provision.label.tenantexport.exportEndTime": "Hor\u00E1rio de fim da exporta\u00E7\u00E3o",
  "provision.label.tenantexport.conditions": "Crit\u00E9rios de pesquisa",
  "provision.label.tenantexport.exportTaskStatus": "Status da tarefa de exporta\u00E7\u00E3o",
  "provision.label.tenantexport.operation": "Opera\u00E7\u00E3o",
  "provision.label.tenantexport.addExportTaskSuccess": "A tarefa de exporta\u00E7\u00E3o foi adicionada com sucesso.",
  "provision.label.tenantexport.download": "baixar",
  "provision.label.tenantexport.addExportTaskError": "Falha ao adicionar a tarefa de exporta\u00E7\u00E3o. Por favor\, verifique o registro.",
  "provision.label.tenantexport.success": "Sucesso",
  "provision.label.tenantexport.exportiong": "Exporta\u00E7\u00E3o",
  "provision.label.tenantexport.passValidateFailed": "O valor deve conter letras\, d\u00EDgitos\, e caracteres especiais _ {'@'}%.",
  "provision.label.tenantexport.inputzippwd": "Introduza a palavra-passe de compress\u00E3o.",
  "provision.label.tenantexport.failed": "falha",
  "provision.label.tenantexport.zippwd": "Senha compactada",
  "provision.label.tenantexport.importdata": "Importando Dados",
  "provision.label.tenantexport.popwindowmessage": "Digite a senha para gerar arquivos compactados. A senha deve conter letras\, d\u00EDgitos\, e caracteres especiais _ {'@'}%.",
  "provision.label.tenantexport.unprocessed": "N\u00E3o processado",
  "provision.tenantexport.download": "Baixando informa\u00E7\u00F5es do inquilino",
  "provision.tenant.management.tenantcreate.param.content.rule": "Os par\u00E2metros de entrada consistem em sublinhados alfanum\u00E9ricos\, devem come\u00E7ar com uma letra e ter pelo menos 2 caracteres",
  "provision.tenant.management.tenantcreate.param.failed": "Par\u00E2metro de entrada inv\u00E1lido",
  "provision.tenant.management.tenantcreate.param.exceed": "O comprimento do par\u00E2metro de entrada deve ser menor que 30",
  "provision.tenantspace.feature.transfer.onlinecheck.description": "Ative o recurso de consulta on-line de recursos de despejo e aluguel de sala suporta consulta on-line de recursos de despejo",
  "provision.tenantspace.feature.transfer.onlinecheck": "Ver os recursos de despejo online",
  "provision.tenantspace.detail.tenantspace.cannot.open.uploadfeature": "Ativar o despejo de recursos e o recurso de consulta online dos recursos de despejo ao mesmo tempo",
  "provision.tenantspace.open.uploadfeature": "Confirmar a abertura",
  "provision.vcallcenter.message.unknowdesc": "Exce\u00E7\u00E3o desconhecida",
  "provision.whiteListAddress.resourcedump.css": "Despejo de Recursos para o Servidor CSS",
  "provision.vcallcenter.message.unknowsolution": "Entre em contato com os engenheiros da Huawei para an\u00E1lise e tratamento de exce\u00E7\u00F5es",
  "provision.tenantspace.detail.tenantspace.cannot.close.uploadfeature": "Por favor\, desative o recurso de an\u00E1lise on-line de despejo primeiro",
  "provision.vcallcenter.message.unknowcause": "Raz\u00E3o desconhecida",
  "provision.whiteListAddress.resourcedump.cssproxy": "Agente do Servidor do CSS de Despesa de Recursos",
  "provision.systemparam.algorithm.unsafe": "Um algoritmo inseguro \u00E9 usado\, o que apresenta riscos de seguran\u00E7a",

  "provision.systemparam.algorithm.suggest": "Cuidado: seja consistente com UAP",

  "provision.tenantspace.feature.contractdigitalsignature": "Assinatura digital do contrato ",
  "provision.tenantspace.feature.contractdigitalsignature.description": "O recurso de assinatura digital de contrato est\u00E1 ativado. O espa\u00E7o alugado suporta a assinatura digital de contratos eletr\u00F4nicos.",
  "provision.callcenterinstanceslist.vcallcenter.ccdis": "CCDIS Servidores",
  "provision.callcenterinstanceslist.ccdis.ccdisId": "N\u00FAmeros",
  "provision.callcenterinstanceslist.ccdis.ccdisIP": "Endere\u00E7o IP prim\u00E1rio",
  "provision.callcenterinstanceslist.ccdis.ccdisbackupIP": "Endere\u00E7o IP alternativo",
  "provision.callcenterinstanceslist.ccdis.progId": "ProgID",
  "provision.callcenterinstanceslist.ccdis.remark": "Descri\u00E7\u00E3o do uso",
  "provision.callcenterinstanceslist.ccdis.poolNodeId": "N\u00F3 CTI ao qual pertence o CCDIS ativo",
  "provision.callcenterinstanceslist.ccdis.poolNodeId2": "N\u00F3 CTI ao qual pertence o CCDIS em standby",
  "provision.callcenterinstanceslist.ccdis.tenantSpaceName": "Nome do inquilino",

  "provision.callcenterinstanceslist.ccdis.syncconfim.msg": "Tem certeza de que deseja sincronizar as configura\u00E7\u00F5es do CCDIS?",
  "provision.tenantspace.config.chooseCcdis": "Selecione um servidor CCDIS",
  "provision.callcenterinstanceslist.ccdis.ccdisUpdate": "Editar",
  "provision.callcenterinstanceslist.ccdis.ccdisDeleteConfirm": "Tem certeza de que deseja excluir o servidor CCDIS selecionado?",
  "provision.callcenterinstanceslist.ccdis.ccdisNetId": "ID do elemento principal",
  "provision.callcent.error.updateccdis.associationvcallcener": "O servidor CCDIS selecionado est\u00E1 associado ao tenant e n\u00E3o pode ser atualizado.",
  "provision.callcent.error.deleteccdis.associationvcallcener": "O servidor CCDIS selecionado est\u00E1 associado ao tenant e n\u00E3o pode ser exclu\u00EDdo.",
  "provision.tenantspace.list.search.date.error": "A data de expira\u00E7\u00E3o do locat\u00E1rio \u00E9 inv\u00E1lida\, insira o intervalo de datas correto",

  "provision.target.selectDate": "Escolha Data",
  "provision.callcent.error.deleteinfo.from.cms.failed": "Falha do CMS ao excluir informa\u00E7\u00F5es relacionadas",
  "provision.callcent.error.deleteinfo.from.cms.bad.input": "Falha na verifica\u00E7\u00E3o de entrada do CMS",
  "provision.callcent.error.deleteinfo.from.cms.unknown.error": "Ocorreu um erro desconhecido no CMS",
  "provision.tenantspace.feature.ivr.analysis": "an\u00E1lise IVR",

  "provision.tenantspace.feature.ivr.analysis.description": "O recurso de an\u00E1lise IVR est\u00E1 ativado e o inquilino oferece suporte \u00E0 fun\u00E7\u00E3o de an\u00E1lise IVR.",

  "provision.tenantspace.feature.customercenter": "Central de Clientes",
  "provision.tenantspace.feature.customercenter.open.confirmmsg": "O recurso AppCube \u00E9 a base para a opera\u00E7\u00E3o do recurso de centro de clientes e ser\u00E1 ativado simultaneamente",
  "provision.tenantspace.common.customercenter.explain": "Ative o recurso de centro de clientes, forne\u00E7a gerenciamento local de clientes para locat\u00E1rios e registre as informa\u00E7\u00F5es hist\u00F3ricas de diferentes canais de contato para o mesmo cliente",

  "provision.tenantspace.feature.appcube.close.confirmmsg": "Ap\u00F3s o fechamento, os dados comerciais (como dados de ordem de servi\u00E7o, dados de contato, etc.) no AppCube ser\u00E3o apagados e n\u00E3o poder\u00E3o ser restaurados",
  "provision.tenantspace.common.mobileAppH5.explain": "Habilitando os recursos do MobileApp H5, os inquilinos podem instalar automaticamente fun\u00E7\u00F5es relacionadas ao agente m\u00F3vel no appcube, suportando o acesso atrav\u00E9s de telefones celulares",
  "provision.tenantspace.feature.mobileAppH5": "Version de mobileapp H5",
  "provision.tenantspace.feature.mobileAppH5.open.confirmmsg": "O recurso AppCube \u00E9 a base para executar recursos MobileApp e ser\u00E1 ativado simultaneamente",


  "provision.tenantspace.feature.customercenter.close.error ": " Certifique-se de que o recurso Case 2.0 esteja desativado antes de desativar o recurso Customer Center",

  "provision.tenantspace.feature.whatsapp.cloudapi": "Conex\u00E3o com o Whatsapp Cloud Api",
  "provision.tenantspace.common.whatsapp.cloudapi.explain": "Habilitar o recurso\, os inquilinos podem configurar o canal para processar mensagens de texto atrav\u00E9s da conex\u00E3o direta com a API do Whatsapp Cloud. Atualmente\, apenas a demonstra\u00E7\u00E3o \u00E9 suportada\, n\u00E3o comercialmente",
  "provision.tenantspace.vdn.id": "ID da VDN",
  "provision.tenantspace.config.error.148": "O sistema de autentica\u00E7\u00E3o j\u00E1 est\u00E1 associado a um usu\u00E1rio federado e n\u00E3o pode ser modificado",

  "provision.log.config.agent.track.config.title": "Configuração de rastreamento de agente",
  "provision.log.config.agent.track.config.refresh": "Atualizar",
  "provision.log.config.agent.track.config.tenantSpaceName": "Nome do espaço",
  "provision.log.config.agent.track.config.workNo": "ID do agente",
  "provision.log.config.agent.track.config.tenantSpaceName.error.hints": "Só números e letras sublinhados são permitidos",
  "provision.log.config.agent.track.config.tenantSpaceName.length.error.hints": "Digite um nome de locação que comece com uma letra de 8 a 30 caracteres e contenha apenas números e letras e sublinhados",
  "provision.log.config.agent.track.config.logLevel": "Nível de registro",
  "provision.log.config.agent.track.config.createTime": "Criado em",
  "provision.log.config.agent.track.config.endTime": "Tempo final de rastreamento",
  "provision.log.config.agent.track.config.operation": "Operação",
  "provision.log.config.agent.track.config.delete": "Remover",
  "provision.log.config.agent.track.config.batch.delete": "Exclusão em lote",
  "provision.log.config.agent.track.config.add": "Adicionar",
  "provision.log.config.agent.track.config.batchDelete.hints.pleaseSelect": "Selecione as informações de configuração do log de rastreamento do agente",
  "provision.log.config.agent.track.config.batchDelete.hints.exceed.max.operate.num": "Um máximo de 100 itens podem ser excluídos por vez",
  "provision.log.config.agent.track.config.create.title": "Criar configuração de rastreamento de agente",
  "provision.log.config.agent.track.config.create.confirm": "Confirmar",
  "provision.log.config.agent.track.config.create.cancel": "Cancelar",
  "provision.log.config.agent.track.config.create.endTime.placeholder": "Selecione a hora de término do rastreamento",
  "provision.log.config.agent.track.config.create.must": "Preenchimento obrigatório",
  "provision.log.config.agent.track.config.create.tenantSpaceName.min.length": "O nome do espaço não pode ser menor que 8 dígitos",
  "provision.log.config.agent.track.config.create.workNol.check.hints": "Digite um número inteiro entre 101 e 50000",
  "provision.log.config.agent.track.config.create.workNol.check.hints.1": "Digite um número inteiro dentro de 50000",
  "provision.log.config.agent.track.config.create.end.check.hints": "Selecione o período atual até os próximos três dias",
  "provision.log.config.agent.track.config.success": "Sucesso",
  "provision.log.config.agent.track.config.failed": "Falha",
  "provision.log.config.agent.track.config.delete.success": "Excluído com sucesso",
  "provision.log.config.agent.track.config.delete.failed.11 ": " Falha ao excluir",
  "provision.log.config.agent.track.config.delete.failed.10": "Erro de parâmetro de operação de exclusão",
  "provision.log.config.agent.track.config.create.top.hints": "Depois de ativar o rastreamento de log do agente do locatário correspondente, o agente coletará os logs de rastreamento gerados no serviço CCManagement. Esta operação afetará o desempenho do sistema. Por favor, ative-a de acordo com os requisitos reais de rastreamento.",
  "provision.log.config.agent.track.config.create.success": "Criado com sucesso",
  "provision.log.config.agent.track.config.create.failed": "Falha na criação",
  "provision.log.config.agent.track.config.create.failed.1": "O comprimento do nome do espaço é inválido",
  "provision.log.config.agent.track.config.create.failed.2": "O nome do espaço contém caracteres especiais",
  "provision.log.config.agent.track.config.create.failed.3": "Falha na verificação do nome do locatário",
  "provision.log.config.agent.track.config.create.failed.4": "Falha na verificação do ID do agente",
  "provision.log.config.agent.track.config.create.failed.5": "Falha na verificação do nível de log",
  "provision.log.config.agent.track.config.create.failed.6": "Falha na verificação da hora final do rastreamento",
  "provision.log.config.agent.track.config.create.failed.7": "O número máximo configurável foi excedido",
  "provision.log.config.agent.track.config.create.failed.8": "Já existe uma configuração de rastreamento de log para este agente",
  "provision.log.config.agent.track.config.create.failed.9": "Falha na criação",
  "provision.log.config.agent.track.config.max.length": "O comprimento máximo é",

  "provision.tenant.management.tenantname.contact.blocklist.check": "Caracteres inválidos,caracteres especiais não são permitidos: & < > \" ; % / \\ ^ [ ] = + @",
}
