import i18n from "@/lang";
import request from '@/utils/request'
import axios from "axios";
import {ElMessage, ElMessageBox} from 'element-plus'

// create an axios instance
const odfsRequest = axios.create({
  baseURL: '', // api的base_url
  timeout: 15000, // request timeout
  withCredentials: true
})

odfsRequest.interceptors.request = request.interceptors.request;
// response interceptor
odfsRequest.interceptors.response.use(
  (response) => {
    const res = response.data
    //如果返回体中存在traceId，说明遇到了未知错误，需要前台展示
    if (res && res.traceId) {
      const $t = i18n.global.t
      ElMessageBox.confirm($t('aicc.traceId.error').replace("0", res.traceId), $t('SM.AUTH.TIPS.ROLETMPL_WARNING'), {
        confirmButtonText: $t('base.confirm'),
        cancelButtonText: $t('cms.recorddrive.label.button_cacel'),
        type: 'warning'
      })
    }
    if (response['headers'] && response['headers']['content-disposition']) {
      return Promise.resolve(response) // 用于从返回头中获取下载文件名称
    }
    return Promise.resolve(res)
  },
  error => {
    let errorBody
    if (!error.response) {
      errorBody = {
        errorCode: error.code,
        errorMessage: error.message
      }
    } else {
      errorBody = error.response.data
    }
    const $t = i18n.global.t
    if (errorBody.error) {
      // toc的接口格式为error和message。
      const i18nErrorMessage = $t('oifde.toc.statusCode.' + errorBody.error)
      errorBody = {
        errorCode: errorBody.error,
        errorMessage:
          i18nErrorMessage === '' ? errorBody.error.message : i18nErrorMessage
      }
    } else if (errorBody.errorCode) {
      errorBody = {
        errorCode: errorBody.errorCode,
        errorMessage: errorBody.errorMessage || ''
      }
      if (errorBody.errorCode === 'ECONNABORTED' && errorBody.errorMessage.startsWith('timeout of')) {
        errorBody = {
          errorCode: errorBody.errorCode,
          errorMessage: $t('oifde.request.timeout')
        }
      } else if ( errorBody.errorMessage === 'accessCodeSubscribed') {
        errorBody = {
          errorCode: errorBody.errorCode,
          errorMessage: $t('oifde.accesscode.accessCodeSubscribed')
        }
      }
    } else if (errorBody instanceof Blob) {
      errorBody.text().then(res => {
        let resJson = JSON.parse(res);
        ElMessage({
          type: 'error',
          message: resJson.errorMessage,
          duration: 10000,
          offset: 56,
          'show-close': true,
        })
      })
    }else {
      const msg = $t('oifde.error.messageHead') + error.config.url + $t('oifde.error.messageBody') + error.request.status + ' ' + $t('oifde.error.messageTail')
      errorBody = {
        errorCode: '',
        errorMessage: msg
      }
    }
    if(!(errorBody instanceof Blob)){
      ElMessage({
        type: 'error',
        message: errorBody.errorMessage,
        duration: 10000,
        offset: 56,
        'show-close': true,
      })
    }
    return Promise.reject(error)
  }
)

export default odfsRequest
