<template>
  <sweet-dialog v-model="isShow" :title="title" width="896px" :destroy-on-close="true" class="aicc-dialog-limit-height"
                :close-on-click-modal="false">
    <div>
      <div v-if="isShowPlayer">
        <aicc-audio class="aicc-p-bottom--medium audio-player-style"  :src="playerSrc"
                    ref="aiccAudio" :can-down-load="false" :can-close="true" autoplay="autoplay" @closeAudioHandle="closeAudioHandle"
                    @audioPlayingHandle="audioPlayingHandle"/>
      </div>
      <div>
        <aicc-table class="aicc-table" ref="contactFileTable" :tableColumns="tableColumns" :tableData="paginateRecordInfos"
                    :tableTools="{showPagination: true, columnConfig: false}" :paginationConfig="paginationConfig"
                    @handlePagination="handlePagination">
          <template #operation="scope">
            <div v-if="scope.row.recordId || scope.row.isExist" >
              <!-- 视频单独判断是否是当前坐席工号 -->
              <sweet-button text type="primary" v-if="!scope.row.audioPlaying"  @click="handleMediaPlay(scope.row)"
                            :disabled="scope.row.mediaAbility == 2 && !scope.row.isPlayVideoTag">
                {{$t('ccm.agent.contact.play')}}
              </sweet-button>
              <sweet-button text type="primary" v-else @click="handleMediaPlay(scope.row)"
                         :disabled="scope.row.mediaAbility == 2 && !scope.row.isPlayVideoTag">
                {{$t("ccm.transtask.label.pause")}}
              </sweet-button>
              <sweet-button text type="primary" @click="download(scope.row)" :disabled="scope.row.recordInfoDisable">
                {{ $t('ccm.agent.contact.download') }}
              </sweet-button>
            </div>
            <div v-else>
              <sweet-button text type="primary" disabled>
                {{$t('ccm.agent.contact.play')}}
              </sweet-button>
              <sweet-button text type="primary" disabled>
                {{$t('ccm.agent.contact.download')}}
              </sweet-button>
            </div>
          </template>
        </aicc-table>
      </div>
    </div>
    <download-file id="contactRecordDownloadFilePop" ref="downloadFileDialog" :title="downloadFileDialogTitle" :required-pwd="requiredZipPwd"
                   :data-info="downloadFileDialogDataInfo" :data-type="downloadFileDialogDataType" useExtendedValidator
                   @get-download-result="downloadFileResult"/>
    <contact-video-pop ref="contactVideoPopRef" v-if="isShowContactVideoPop"
                       :title="videoPlayTitle" :record-id="videoPlayRecordId" :vrc-record-id="vrcRecordId" :video-type="videoPlayType"
                       @close="() => isShowContactVideoPop = false"/>
  </sweet-dialog>
</template>

<script setup lang="ts">

import { getCurrentInstance, onBeforeMount, reactive, ref, toRefs } from "vue";
import {
  queryExportTenantSysParam,
  queryRecordInfo
} from "@/views/ccmanagement/ccma-api/index.js";
import {mediaAbilityMap, SystemParamEnum} from "@/views/ccmanagement/common/constants";
import { deepClone } from "@vitest/utils";
import AiccAudio from "@/components/aicc-audio/index.vue";
import AiccElMessage from '@/utils/el-message';
import ContactVideoPop from "@/views/ccmanagement/pages/contact-video-pop/contact-video-pop.vue";
import DownloadFile from "@/views/ccmanagement/pages/download-file/download-file.vue";

// 国际化封装
const { appContext: { config: { globalProperties } } } = getCurrentInstance() as any;
const i18n = (param: string, ...args: Array<any>): string => {
  return globalProperties.$t(param, args);
};

const emits = defineEmits(['download', 'playVideo']);
const props = defineProps({
  title: String,
  recordIds: {
    type: String,
    required: true,
  },
  videoDisable: {
    type: [Boolean, String],
    required: true,
  },
  isShow: Boolean,
});
const {title, recordIds, videoDisable, isShow} = toRefs(props);
const recordinfos = ref();
const aiccAudio = ref();
const playerSrc = ref<string>(""); // 播放资源地址
const playingId = ref<string>(); // 播放id
const isShowPlayer = ref<boolean>(false);
const paginateRecordInfos = ref<Array<any>>([]);
const paginationConfig = reactive({
  pageSize: 10,
  pageSizes: [10, 20, 50, 100],
  total: 0,
  layout: "sizes, total, prev, pager, next"
});
const contactFileTable = ref();
const downloadFileDialog = ref(); // 录制内容下载弹窗
const downloadFileDialogTitle = ref<any>(); // 录制内容下载弹窗的标题
const downloadFileDialogDataType = ref<any>(); // 录制内容下载弹窗的 dataType参数
const downloadFileDialogDataInfo = ref<Object>(); // 录制内容下载弹窗的 dataInfo参数
const videoPlayTitle = ref<String>(); // 视频弹窗标题
const videoPlayRecordId = ref<String>(); // 视频的记录id
const vrcRecordId = ref<String>(); // 视频的记录id
const videoPlayType = ref<String>("video/mp4"); // 视频的播放类型
const isShowContactVideoPop = ref<Boolean>(false);
const tableColumns = reactive([
  {
    label: i18n('ccm.agent.contact.callid'),
    'show-overflow-tooltip': true,
    prop: 'callId',
  },
  {
    label: i18n('ccm.agent.contact.mediaType'),
    'show-overflow-tooltip': true,
    prop: 'mediaAbilityName',
  },
  {
    label: i18n('ccm.agent.contact.recordtime'),
    'show-overflow-tooltip': true,
    prop: 'recordTime',
  },
  {
    label: i18n('ccm.agent.calledroute.operation'),
    'show-overflow-tooltip': true,
    prop: 'operation',
    slot: 'operation',
    fixed: 'right',
  },
])

// 分页处理函数
const handlePagination = (params: any) => {
  const limit = params.limit;
  const offset = params.offset;
  const temp = recordinfos.value;
  paginateRecordInfos.value = temp.slice(offset, offset + limit);
}
const requiredZipPwd = ref(true)

const searchRecordInfos = () => {
  const param: any = {recordIds: recordIds?.value?.split(',') || []};
  queryRecordInfo(param).then((resp: Array<any>) => {
    recordinfos.value = resp;
    if (param.recordIds && param.recordIds.length > 0
        && param.recordIds[param.recordIds.length - 1].indexOf("-vrc") > -1) {
      let tempArr = resp;
      tempArr.push({
        recordId: param.recordIds[param.recordIds.length - 1],
        vrcRecordId: param.recordIds[param.recordIds.length - 1].split('-')[0],
        recordfileaddr: "vrc:/vrc",
        callId: resp ? resp[0]["callId"] : '-',
        recordTime: '-'
      });
      recordinfos.value = tempArr;
    }
    if (!recordinfos.value) {
      return;
    }
    getMediaAbilityType();
    paginationConfig.total = recordinfos.value.length;
    paginateRecordInfos.value = resp.slice(0, paginationConfig.pageSize)
  })
}

const getMediaAbilityType = () => {
  for (const index of recordinfos.value) {
    index['mediaAbilityName'] = mediaAbilityMap.get(String(index['mediaAbility'] || 1));
    const onSftpServer = index["recordfileaddr"] && index["recordfileaddr"].indexOf(":/") != -1 ;
    // 视频播放,并且是宇高的展示播放按钮
    if (index["mediaAbility"] == 2 && onSftpServer) {
      index["isPlayVideoTag"] = true;
    }
    if (index['vrcRecordId']){
      index['mediaAbility'] = 2;
      index['mediaAbilityName'] = 'VRC';
      index["isPlayVideoTag"] = true;
      index.recordInfoDisable = false;
    }
    // 视频播放下载需要判断当前座席工号是否是质检员，如果不是，判断当前工号是否和接触记录里的相同。
    if (index.mediaAbility == 2 && videoDisable.value && !onSftpServer) {
      index.recordInfoDisable = true;
    }
  }
}

const handleMediaPlay = (row: any) => {
  // 播放/暂停操作
  if (row.audioPlaying === undefined) {
    if (row.associateCall) {
      playAudioRecord(row, () => {
        isShowPlayer.value = true;
        row.audioPlaying = true;
        playerSrc.value = `${location.protocol}//${location.host}/service-cloud/u-route/ccmanagement/recordplay/recordext?associateCall=${row.associateCall}`;
      });
      return;
    }
    playFile(row);
    return;
  }
  // 以下操作均只涉及到音频，故可以直接根据row.audioPlaying的状态判断
  // 正在播放中，切换至暂停
  if (row.audioPlaying) {
    row.audioPlaying = false;
    aiccAudio.value.playPauseAudio("");
  } else {
    row.audioPlaying = true;
    aiccAudio.value.playPauseAudio("");
  }
};

const download = (row: any) => {
  emits('download', row);
}

const closeDialog = () => {
  isShow.value = false;
  paginateRecordInfos.value = [];
  recordinfos.value = null;
}

const playFile = (row: any) => {
  const mediaAbility = row.mediaAbility || 1;
  // 语音文件
  if (mediaAbility == 1) {
    playAudioRecord(row, () => {
      isShowPlayer.value = true;
      playerSrc.value = `${location.protocol}//${location.host}/service-cloud/u-route/ccmanagement/recordplay/voice?recordId=${row.recordId}`;
      row.audioPlaying = true;
    });
    return;
  }
  //视频文件,打开弹窗播放
  if (mediaAbility == 2) {
    if (row.vrcRecordId) {
      playVideo(row, "vrc"); // vrc场景下还需要播放视频
      return;
    }
    playVideo(row, "video");
  }
};

const playVideo = (recordInfo: any, type: String) => {
  if (recordInfo.recordfileaddr.indexOf(":/") < 0 ) {
    if (!window.top?.agentStatus){
      AiccElMessage.info(i18n("ccm.agent.leavemessage.notLoginIn"));
      return;
    }
    if (window.top?.agentStatus?.isLogOut()){
      AiccElMessage.info(i18n("ccm.agent.leavemessage.notLoginIn"));
      return;
    }
  }
  if (type == "video") {
    videoPlayRecordId.value = recordInfo.recordId;
    vrcRecordId.value = undefined;
  } else {
    videoPlayRecordId.value = undefined;
    vrcRecordId.value = recordInfo.vrcRecordId;
  }
  videoPlayTitle.value = i18n("ccm.agent.contact.vidio");
  isShowContactVideoPop.value = true;
};

/**
 * 播放音频文件
 * @param row 表格列所在数据
 * @param callback 播放音频的回调
 */
function playAudioRecord(row: any, callback: () => void) {
  // 针对非本次的播放/暂停，需要将原状态重置
  if (playingId.value && row.callSerialno != playingId.value) {
    playingId.value = row.recordId;
    resetAudioPlayStatus();
  }
  playingId.value = row.recordId;
  callback();
}

const closeAudioHandle = () => {
  resetAudioPlayStatus();
  isShowPlayer.value = false;
};

/**
 * 重置音频播放状态
 * @param resetAll 是否重置所有，默认为true
 */
const resetAudioPlayStatus = (resetAll: boolean = true): void => {
  if (!recordinfos.value) {
    return;
  }
  for (const data of recordinfos.value) {
    delete data.audioPlaying;
    if (!resetAll) {
      return;
    }
  }
};

const audioPlayingHandle = (status: boolean) => {
  if (!recordinfos.value) {
    return;
  }
  for (const data of recordinfos.value) {
    if (data.audioPlaying !== undefined) {
      data.audioPlaying = status;
    }
  }
};

/**
 * 下载录制文件
 * @param row 对应数据
 */
const downloadRecord = (row: any) => {
  if (row.associateCall) {
    popDownloadFileDialog(row, "mobileRecord");
    return;
  }

  popDownloadFileDialog(row, "contactRecord");
};

/**
 * 弹出文件下载框
 * @param row 对应列的信息
 * @param dataType 下载文件类型
 */
const popDownloadFileDialog = (row: any, dataType: String) => {
  // 检查是否需要设置压缩密码
  queryExportTenantSysParam().then((resp: any) => {
    requiredZipPwd.value = resp?.data?.value != 'false'
  }).finally(() => {
    const msg = row.mediaAbility == 1 ? "ccm.agent.contact.downloadrecord" : "ccm.agent.contact.downloadvideo";
    downloadFileDialog.value.handleDialog(true);
    downloadFileDialogTitle.value = i18n(msg);
    downloadFileDialogDataType.value = dataType;
    downloadFileDialogDataInfo.value = JSON.parse(JSON.stringify(row));
  })
}

const downloadFileResult = (result: boolean | undefined) => {
  downloadFileDialog.value.handleDialog();
};


defineExpose({closeDialog})

onBeforeMount(() => {
  searchRecordInfos();
})
</script>
<style scoped lang="less">

.audio-player-style {
  height: inherit;
  width: 450px;
  float: right;
  position: relative;
  z-index: 4;
}
</style>