import {defineStore} from "pinia";

export const useModelStore = defineStore('model', {
  state: () => {
    return {
      agentInfo: {},
      tenantStatus: '',
      buttonBox: null,
      secendBottunStatus: '',
      holdCall: '',
      callNumber: '',
      errormessageflag: false,
      errormessage: '',
      showvideodesk: false,
      lightCCBarVideoSwitch: false,
      callerList: [],
      releaseName: '',
      releaseNum: '',
      callNumberName:'',
      holdCallNumber: '',
      callSkill: '--',
      hangupInfos: [],
      curSessionId: '',
      showWarnMsg: true,
      webrtcAgentMuteStatus: false,
      skillMap: new Map(),
      showWebrtcError: false,
      curCallId: '',
      cancelCallId: ''
    }
  },
  actions: {
    // 修改座席信息
    changeAgentInfo(info) {
      this.agentInfo = info
    },
    // 修改座席信息
    changeWebRtcMuteStatus(info) {
      this.webrtcAgentMuteStatus = info
    },
    // 修改座席操作权限
    changeButtonBox(buttonBox) {
      this.buttonBox = buttonBox
    },
    // 修改座席状态
    changeTenantStatus(tenantStatus) {
      this.tenantStatus = tenantStatus
    },

    changeSecendBottunStatus(secendBottunStatus) {
      this.secendBottunStatus = secendBottunStatus
    },
    changeHoldCall(holdCall) {
      this.holdCall = holdCall;
    },
    changeCallNUmber(callNumber) {
      this.callNumber = callNumber;
    },
    changeErrormessageflag(errormessageflag) {
      this.errormessageflag = errormessageflag;
    },
    changeErrormessage(errormessage) {
      this.errormessage = errormessage;
    },
    changeshowvideodesk(showvideodesk) {
      this.showvideodesk = showvideodesk
    },
    changeLightCCBarVideoSwitch() {
      this.lightCCBarVideoSwitch = !this.lightCCBarVideoSwitch
    },
    changeCallerList(callerList) {
      this.callerList = callerList;
    },
    changeReleaseName(releaseName) {
      this.releaseName = releaseName
    },
    changeReleaseNum(releaseNum) {
      this.releaseNum = releaseNum;
    },
    changeCallNumberName(callNumberName){
      this.callNumberName = callNumberName
    },
    changeHoldCallNumber(holdCallNumber) {
      this.holdCallNumber = holdCallNumber;
    },
    changeCallSkill(skillMap) {
      this.skillMap = skillMap;
    },
    changeHangupInfos(hangupInfos) {
      this.hangupInfos = hangupInfos;
    },
    changeCurSessionId(curSessionId) {
      this.curSessionId = curSessionId;
    },
    changeShowWarnMsg(showWarnMsg) {
      this.showWarnMsg = showWarnMsg;
    },
    changeWebrtcError(showWebrtcError){
      this.showWebrtcError = showWebrtcError
    },
    changeCurCallId(callId) {
      this.curCallId = callId
    },
    changeCancelCallId(cancelCallId) {
      this.cancelCallId = cancelCallId
    }
  },
  getters: {
    getButtonBox() {
      return this.buttonBox;
    }
  }
})

export const useReceiveObjStore = defineStore('receiveObj', {
  state: () => {
    return {
      receivedObj: {}
    }
  },
  actions: {
    // 修改座席信息
    changeReceivedObj(receivedObj) {
      this.receivedObj = receivedObj
    }
  }
})