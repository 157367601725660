export default {
  mainTitle: 'Customer Service Cloud',
  subTitle: 'Clear UI for Ultimate Service',
  account: 'Conta',
  password: 'Senha',
  signIn: 'Entrar',
  configCenter: 'Centro de Configuração',
  configFirstAgent: 'Configuração do agente',
  ConfigureFirstBot: 'Configuração do robô',
  howToUseTheCallCenter: 'Guia do Usuário',
  welcomeBack: 'Bem-vindo de volta',
  totalNumberOfCalls: 'Total de chamadas',
  numberOfCallLosses: 'Abandonar chamadas',
  totalNumberOfPeopleInLine: 'Chamadas na fila',
  helpCenter: 'Centro de Ajuda',
  knowledgeCenter: 'Centro de Conhecimento',
  forum: 'Fórum',
  virtualCallCenterTrendsOfTheDay: 'Tendências de Call Center Virtual do Dia',
  quickEntry: 'Entrada Rápida',
  myToDo: 'Meu To Do',
  notificationCenter: 'Centro de Notificação',
  more: 'Mais',
  setSkillQueue: 'Definir Fila de Habilidades',
  agentManagement: 'Gestão de Agentes',
  calledSettings: 'Configurações Chamadas',
  workbenchConfiguration: 'Configuração da bancada de trabalho',
  outboundTask: 'Tarefa de saída',
  workOrder: 'Ordem de trabalho',
  message: 'Mensagem',
  name: 'Nome',
  taskProgress: 'Progresso da tarefa',
  province: 'Província',
  urbanArea: 'Área Urbana',
  address: 'Endereço',
  postCode: 'Código Postal',
  homePage: 'Página inicial',
  caseHome: 'Página inicial de Casos',
  robotManagement: 'Gestão de Robôs',
  mailMessageManagement: 'Gerenciamento de mensagens de correio',
  changePassword: 'Alterar senha',
  resetSkillQueue: 'Reset Skill Queue',
  signOut: 'Sair',
  changePasswordSuccess:
    'A nova senha foi definida com sucesso. Por favor, lembre-se da sua nova senha. A página retornará em 3 segundos.',
  passwordInvalid:
    'A senha atual não atende às regras de senha. Por favor, redefina-o de acordo com as regras.',
  passwordIsAboutToExpire: 'A senha está prestes a expirar. Altere a senha.',
  passwordForceChange:
    'A senha atual é a senha inicial. Por motivos de segurança, altere a senha imediatamente.',
  passwordFirstChange:
    'A senha atual é a senha inicial. Para garantir a segurança, altere a senha imediatamente.',
  passwordExpireTitle: 'Notificação de expiração de senha',
  passwordExpireMessage:
    'A senha que você definiu está prestes a expirar. Não é possível efetuar login no sistema após a expiração da senha. Altere a senha agora.',
  passwordFirstChangeTitle: 'Lembrete de senha inicial',
  passwordFirstChangeMessage:
    'A senha atual é a senha inicial, que é insegura. Deseja alterar a senha agora?',
  systemError: 'Erro de sistema',
  continueLogin: 'Continuar login',
  verifyCode: 'Código de verificação',
  pageInvalid: 'A página é inválida. Por favor, recarregue a página.',
  confirm: 'Confirmar',
  verifyCodeInvalid: 'O código de verificação é inválido.',
  passwordHasExpried: 'A senha expirou',
  passWordRuleLength: 'A senha contém caracteres de [1] a [2]',
  passWordRuleMinLength: 'A senha deve conter pelo menos [1] caracteres',
  passWordRuleMaxLength: 'A senha pode conter no máximo [1] caracteres',
  passwordRuleForbidContinueCharacter:
    'O número de caracteres repetidos consecutivos não pode exceder [1]',
  passwordRuleForbidName: 'O nome não pode ser contido',
  passwordRuleForbidAccount: 'A conta não pode estar em ordem positiva ou negativa',
  digit: 'Número',
  letter: 'Carta',
  uppercaseLetters: 'Letras maiúsculas',
  lowercaseLetters: 'Letras minúsculas',
  specialCharacters: 'Carácter especial',
  necessaryContains: 'Obrigatório',
  specialCharacterCandidates: 'Candidato a caracter especial:',
  descriptionOfInvalidandUnqualifiedPasswords: 'Descrição de senha inválida e não qualificada:',
  error: 'Erro',
  copyrightInfomation:
    'Copyright © [1] Huawei Software Technologies Co., Ltd. Todos os direitos reservados.',
  accountEmpty: 'Insira uma conta.',
  oldPasswordEmpty: 'Digite uma senha.',
  newPasswordEmpty: 'Digite uma nova senha.',
  passwordNotSame: 'Senhas são inconsistentes.',
  verifyCodeEmpty: 'Insira um código de verificação.',
  oldPassword: 'Palavra-passe antiga',
  newPassword: 'Nova Senha',
  confirmPassword: 'Confirmar senha',
  ok: 'OK',
  cancel: 'Cancelar',
  LogoutMessage: 'Tem certeza de que deseja sair?',
  keywordFilter: 'Insira a palavra-chave para filtrar',
  noOrganization: 'organização não afiliada',
  createCaseOrder: 'Criar caso',
  configMenuSearchPlaceholder: 'Pesquisar palavras-chave do menu',
  changePasswordSuccessAndLogout: 'Senha alterada com sucesso! O sistema está prestes a sair automaticamente!',
  accountLocked: 'O usuário foi bloqueado porque o número de falhas de alteração de senha excede o limite. A página de login será exibida em 3 segundos.',
  'queueNumbers': 'Número atual de filas:',
  'callsToday': 'Número de chamadas hoje:',
  'averageCallTime': 'Tempo médio de conversação:',
  'entryIsEmpty': 'A entrada do menu de atalho está vazia. Por favor, configure-a.',
  'goToSettings': 'Ir para Configurações',
  'settingShortcutEntry': 'Configurar entradas rápidas',
  'frontDeskWork': 'Trabalho na recepção',
  'selectedShortcutMenu': 'Menu de atalho selecionado',
  'pendingMessages': 'Mensagem pendente ({n})',
  'messageManagement': 'Gestão de mensagens',
  'messageTime': 'Hora da mensagem',
  'useCallFunction': 'Como usar o recurso de chamada',
  'performingOutboundCallTask': 'Como executar uma tarefa de chamada de saída',
  'notificationDetails': 'Detalhes da notificação',
  'sender': 'Remetente',
  'receiveGroup': 'Grupos de recepção',
  'bulletinType': 'Tipo de anúncio',
  'internalMessage': 'Mensagem da estação',
  'announcements': 'Anúncios',
  'systemBulletin': 'Boletim do sistema',
  'internalBulletin': 'Anúncios internos',
  'sendNotification': 'Enviar notificação',
  'notificationReply': 'Resposta de notificação',
  'notificationDeleteConfirm': 'Tem certeza de que deseja excluir esta notificação ?',
  'alarmnote':'Notas de inspeção de qualidade',
  'alarmmessage':'Mensagem de aviso',
  'alarmmessagedetail':'Você tem {0} novos alarmes de QC para ver. Por favor, clique para ver os detalhes',
  'schedule':'WFM',
  'privacystatement':'Declaração de Privacidade da AICC',
  'id':'ID',
  'decs':'Descrição',
  'cause':'Por quê',
  'action':'Ação de correção',
  personalCenter:"Centro Pessoal",
  'noagent':"Esta conta não está vinculada a um agente. Você pode entrar em contato com o administrador para configurá-la",
  'nickName':"Apelido da sessão",
  'socailChatNum':"Clientes com sessão simultânea",
  'avatar':"Falar como",
  'confirmClose':"Confirmar Fechar",
  'importListView': "Exibindo Tarefas de Importação",
  'listView': "Exibindo Tarefas",
  'minorityLanguageTips': 'Desculpe, o sistema WFM suporta apenas chinês e inglês. Por favor, mude para outro idioma.',
  'minorityLanguageTipsAgent': 'Desculpe, o sistema WFM não suporta português e tailandês. Por favor, mude para outro idioma.',
  "sureCloseCurrentPage": "Tem certeza de que deseja fechar a página?",
}
