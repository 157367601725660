export default {
  mainTitle: 'Kundenservice-Cloud',
  subTitle: 'Klare Benutzeroberfläche für ultimativen Service',
  account: 'Konto',
  password: 'Passwort',
  signIn: 'Anmelden',
  configCenter: 'Konfigurationscenter',
  configFirstAgent: 'Agentenkonfiguration',
  ConfigureFirstBot: 'Roboterkonfiguration',
  howToUseTheCallCenter: 'Benutzerhandbuch',
  welcomeBack: 'Welcome back',
  totalNumberOfCalls: 'Gesamtanzahl der Anrufe',
  numberOfCallLosses: 'Abgebrochene Anrufe',
  totalNumberOfPeopleInLine: 'Anrufe in der Warteschlange',
  helpCenter: 'Hilfezentrum',
  knowledgeCenter: 'Wissenszentrum',
  forum: 'Forum',
  virtualCallCenterTrendsOfTheDay: 'Virtuelle Callcenter-Trends des Tages',
  quickEntry: 'Schneller Einstieg',
  myToDo: 'Meine Aufgabe',
  notificationCenter: 'Nachrichtencenter',
  more: 'Mehr',
  addStaff: 'Personal hinzufügen',
  setSkillQueue: 'Fertigkeitswarteschlange festlegen',
  agentManagement: 'Agentenverwaltung',
  calledSettings: 'Genannt Einstellungen',
  workbenchConfiguration: 'Workbench-Konfiguration',
  outboundTask: 'Ausgehende Aufgabe',
  workOrder: 'Work Order',
  message: 'Nachricht',
  name: 'Name',
  taskProgress: 'Aufgabenfortschritt',
  province: 'Provinz',
  urbanArea: 'Stadtgebiet',
  address: 'Adresse',
  postCode: 'Postleitzahl',
  homePage: 'Startseite',
  caseHome: 'Fall Startseite',
  robotManagement: 'Robotermanagement',
  mailMessageManagement: 'E-Mail-Nachrichtenverwaltung',
  changePassword: 'Kennwort ändern',
  resetSkillQueue: 'Fertigkeitswarteschlange zurücksetzen',
  signOut: 'Sign Out',
  changePasswordSuccess:
    'Das neue Passwort wurde erfolgreich festgelegt. Bitte merken Sie sich Ihr neues Passwort. Die Seite kehrt in 3 Sekunden zurück.',
  passwordInvalid:
    'Das aktuelle Passwort entspricht nicht den Passwortregeln. Bitte setzen Sie es gemäß den Regeln zurück.',
  passwordIsAboutToExpire: 'Das Passwort läuft bald ab. Änder das Passwort.',
  passwordForceChange:
    'Das aktuelle Passwort ist das Initialpasswort. Ändern Sie aus Sicherheitsgründen das Passwort sofort.',
  passwordFirstChange:
    'Das aktuelle Passwort ist das Initialpasswort. Um die Sicherheit zu gewährleisten, ändern Sie das Passwort sofort.',
  passwordExpireTitle: 'Benachrichtigung über den Ablauf des Passworts',
  passwordExpireMessage:
    'Das von Ihnen festgelegte Passwort läuft bald ab. Nach Ablauf des Passworts können Sie sich nicht mehr am System anmelden. Ändern Sie jetzt das Passwort.',
  passwordFirstChangeTitle: 'Erste Passwort-Erinnerung',
  passwordFirstChangeMessage:
    'Das aktuelle Passwort ist das anfängliche Passwort, das unsicher ist. Möchten Sie das Passwort jetzt ändern?',
  systemError: 'Systemfehler',
  continueLogin: 'Weiter anmelden',
  verifyCode: 'Bestätigungscode',
  pageInvalid: 'The page is invalid. Please reload the page.',
  confirm: 'Bestätigen',
  verifyCodeInvalid: 'Der Bestätigungscode ist ungültig.',
  passwordHasExpried: 'Das Passwort ist abgelaufen',
  passWordRuleLength: 'Das Passwort besteht aus [1] bis [2] Zeichen',
  passWordRuleMinLength: 'Das Passwort muss mindestens [1] Zeichen enthalten',
  passWordRuleMaxLength: 'Das Passwort kann maximal [1] Zeichen enthalten',
  passwordRuleForbidContinueCharacter:
    'Die Anzahl der aufeinanderfolgenden wiederholten Zeichen darf [1] nicht überschreiten.',
  passwordRuleForbidName: 'Der Name darf nicht enthalten sein',
  passwordRuleForbidAccount: 'Das Konto darf weder in positiver noch in negativer Reihenfolge sein',
  digit: 'Nummer',
  letter: 'Brief',
  uppercaseLetters: 'Großbuchstaben',
  lowercaseLetters: 'Kleinbuchstaben',
  specialCharacters: 'Sonderzeichen',
  necessaryContains: 'Obligatorisch',
  specialCharacterCandidates: 'Kandidat für Sonderzeichen:',
  descriptionOfInvalidandUnqualifiedPasswords: 'Ungültige und nicht qualifizierte Passwortbeschreibung:',
  error: 'Fehler',
  copyrightInfomation:
    'Copyright © [1] Huawei Software Technologies Co., Ltd. Alle Rechte vorbehalten.',
  accountEmpty: 'Geben Sie ein Konto ein.',
  oldPasswordEmpty: 'Geben Sie ein Passwort ein.',
  newPasswordEmpty: 'Geben Sie ein neues Kennwort ein.',
  passwordNotSame: 'Passwörter sind inkonsistent.',
  verifyCodeEmpty: 'Geben Sie einen Bestätigungscode ein.',
  oldPassword: 'Altes Passwort',
  newPassword: 'Neues Kennwort',
  confirmPassword: 'Bestätige das Passwort',
  ok: 'Sicher',
  cancel: 'Stornieren',
  LogoutMessage: 'Sie sind sicher, dass Sie beenden wollen?',
  keywordFilter: 'Geben Sie ein Stichwort ein',
  noOrganization: 'Nicht verbundene Organisation',
  createCaseOrder: 'Fall erstellen',
  configMenuSearchPlaceholder: 'Suchen Sie nach Menüschlüsselwörtern',
  changePasswordSuccessAndLogout: 'Das Passwort wurde erfolgreich geändert! Das System wird bald automatisch beendet!',
  accountLocked: 'Der Benutzer wurde gesperrt, weil die Anzahl der fehlgeschlagenen Passwortänderungen den Schwellenwert überschreitet. Die Anmeldeseite wird in 3 Sekunden angezeigt.',
  'queueNumbers': 'Anzahl der aktuellen Warteschlangen:',
  'callsToday': 'Anrufe heute:',
  'averageCallTime': 'Durchschnittliche Gesprächszeit:',
  'entryIsEmpty': 'Der Kontextmenüeintrag ist leer. Bitte einstellen.',
  'goToSettings': 'Gehe zu den Einstellungen',
  'settingShortcutEntry': 'Verknüpfungseintrag festlegen',
  'frontDeskWork': 'Arbeit an der Rezeption',
  'selectedShortcutMenu': 'Ausgewähltes Kontextmenü',
  'pendingMessages': 'Ausstehende Nachrichten ({n})',
  'messageManagement': 'Nachrichtenverwaltung',
  'messageTime': 'Nachrichtenzeit',
  'useCallFunction': 'So verwenden Sie die Anruffunktion',
  'performingOutboundCallTask': 'Ausführen einer Aufgabe für einen ausgehenden Anruf',
  'notificationDetails': 'Benachrichtigungsdetails',
  'sender': 'Absender',
  'receiveGroup': 'Gruppe empfangen',
  'bulletinType': 'Bulletin-Typ',
  'internalMessage': 'Interne Nachricht',
  'announcements': 'Ankündigungen',
  'systemBulletin': 'Systembulletin',
  'internalBulletin': 'Internes Bulletin',
  'sendNotification': 'Senden Sie eine Benachrichtigung',
  'notificationReply': 'Sind Sie sicher, dass Sie diese Benachrichtigung löschen?',
  'notificationDeleteConfirm': 'Sind Sie sicher, dass Sie diese Benachrichtigung löschen möchten?',
  'alarmnote':'Hinweis zur Qualitätsprüfung',
  'alarmmessage':'Warnmeldung',
  'alarmmessagedetail':'Sie haben {0} neue Inspektionsalarme zur Ansicht. Klicken Sie hier, um Details anzuzeigen',
  'schedule':'WFM',
  'privacystatement':'AICC-Datenschutzerklärung',
  'id':'AUSWEIS',
  'decs':'Description',
  'cause':'Grund',
  'action':'Reparaturaktion',
  personalCenter: "Persönliches Zentrum",
  'noagent':"Das Konto ist an keinen Agenten gebunden. Sie können sich an den Administrator wenden, um das Konto zu konfigurieren",
  'nickName':"Sitzungs-Spitzname",
  'socailChatNum':"Anzahl der Clients, die gleichzeitig von einer Sitzung bedient werden",
  'avatar':"Gesprächskopfporträt",
  'confirmClose':"Bestätigen Sie Schließen",
  'importListView': "Importaufgaben anzeigen",
  'listView': "Aufgaben anzeigen",
  'minorityLanguageTips': 'Leider unterstützt das WFM-System nur Chinesisch und Englisch. Bitte wechseln Sie zu einer anderen Sprache.',
  'minorityLanguageTipsAgent': 'Leider unterstützt das WFM-System kein Portugiesisch und Thailändisch. Bitte wechseln Sie zu einer anderen Sprache.',
  "sureCloseCurrentPage": "Möchten Sie die Seite wirklich schließen?",
  'manualPostEvent': "Manuelle Inspektion nach der Veranstaltung",
  'autoPostEvent': 'Automatische Inspektion nach der Veranstaltung',
  'inspectionTask': 'Qualitätsprüfungsaufgabe',
  'inspectionReview': 'Überprüfungsaufgabe',
  'callId': 'Anruf-ID',
  'channelType': 'Kanal',
  'agentNo': 'Agentennummer',
  'inspector': 'Inspektor',
  'appraiseTime': 'Inspiziert am',
  'inspectionScore': 'Inspektionsbewertung',
  'recheckReason': 'Überprüfungsgrund',
  'manualInspection': 'Manuelle Inspektion',
  'audio': 'Audio',
  'multimedia': 'Multimedia',
  'inspectionRecord': 'Abfrage des zu prüfenden Datensatzes'
}
