export default {
  "ikbs.constableTmp.config" : "Configure",
  "ikbs.constableTmp.status.draft" : "Draft",
  "ikbs.cntTmp.import.rowNumOver" : "A maximum of 10 records can be imported.",
  "ikbs.column.unlock.message.warning" : "Information",
  "ikbs.knowledge.label.abstract" : "Abstract",
  "ikbs.properties.add.information" : "Add Extended Attribute",
  "ikbs.contTmp.exception.maxCountPerTenant" : "A tenant can have a maximum of 500 templates.",
  "ikbs.knowledge.properties.operation.upMove" : "Up",
  "ikbs.column.delete.selectNoColumnKngToDelete" : "No category knowledge is selected.",
  "ikbs.tips.canNotAddColumnKngOnHistoryColumn" : "Knowledge cannot be added to the history category.",
  "ikbs.knowledge.properties.label.inputWay" : "Input Type",
  "ikbs.contentTmp.exception.tmpContentAndEditStandard" : "The template content and editing specifications cannot be empty at the same time",
  "ikbs.knowledge.label.uploadSingleFile" : "Only one attachment can be uploaded at a time.",
  "ikbs.knowledge.tip.operateSuccess" : "Operation successful.",
  "ikbs.constableTmp.operator.lt" : "less than",
  "ikbs.enter.search.condition" : "Please enter search condition",
  "ikbs.search.label.sortByDefault" : "Default Sorting",
  "ikbs.knowledge.recycle.coluKngType" : "Type",
  "ikbs.consult.table" : "Consultation Table",
  "ikbs.knowledge.label.path" : "Path",
  "ikbs.column.release.message.fail" : "Publish failed.",
  "ikbs.column.knowledge.columnInformation" : "Add Category",
  "ikbs.constableTmp.operator.le" : "less than or equal to",
  "ikbs.knowledge.service.common" : "Common",
  "ikbs.knowledge.recycle.select.knowledgeType" : "Knowledge Type",
  "ikbs.tips.propertyDictValueAtLeastOne.warning" : "The extended attribute must have at least one dictionary value.",
  "ikbs.column.displayflag.hide" : "No",
  "ikbs.column.knowledge.button.add" : "New",
  "ikbs.column.knowledge.modify.properties" : "Batch Modify Attributes",
  "ikbs.knowledge.release.public" : "Publish directly",
  "ikbs.knowledge.label.selectProperty" : "Select",
  "ikbs.contentTmp.status.draft" : "Draft",
  "ikbs.knowledge.label.serviceStatus" : "Business Status",
  "ikbs.knowledge.service.update" : "Updated business",
  "ikbs.column.serviceStatus.newService" : "New business",
  "ikbs.knowledge.label.answer" : "Answer",
  "ikbs.constableTmp.reset" : "Reset",
  "ikbs.knowledge.label.modifyTime" : "Last updated on",
  "ikbs.column.type.historyMark" : "History",
  "ikbs.kngCnt.cntTmp.icon.tip" : "Content template",
  "ikbs.knowledge.label.currentVersion" : "Current version",
  "ikbs.columntype.column" : "Category",
  "ikbs.enter.search.keywords" : "Enter a search keyword",
  "ikbs.contentTmp.delete.fail" : "Deletion failed",
  "ikbs.column.label.coluKngMaintaining" : "maintenance group",
  "ikbs.knowledge.label.kngAttachmentDownload" : "Download knowledge category attachment",
  "ikbs.search.label.updateEndTime" : "Update End Time",
  "ikbs.knowledgeaudit.label.pass" : "Approve",
  "ikbs.knowledge.tip.selectColumn" : "Select a category.",
  "ikbs.constableTmp.searchFlag" : "Search Criterion or Not",
  "ikbs.properties.add.cancel" : "Cancel",
  "ikbs.column.needAudit.yes" : "Yes",
  "ikbs.knowledge.properties.operation.enable" : "Enable",
  "ikbs.column.type.normal" : "Normal category",
  "ikbs.knowledge.label.passProcecWay" : "Upon Expiration",
  "ikbs.column.knowledge.MoveType" : "Adjustment Location",
  "ikbs.confirm.delete.by.select" : "Are you sure you want to delete the selected record",
  "ikbs.knowledge.label.relaKngs" : "Knowledge Association",
  "ikbs.constableTmp.fieldName" : "Field",
  "ikbs.column.copy.message.warning" : "Information",
  "ikbs.column.deletePermanently.message.comfirm" : "Confirm",
  "ikbs.column.moveto.front" : "Front of",
  "ikbs.kbshome.button.orderByTime" : "Time",
  "ikbs.constableTmp.moveDown" : "Down",
  "ikbs.tips.canNotAddKngOnRootColumn" : "Knowledge cannot be added to the root category.",
  "ikbs.column.knowledge.button.up" : "Up",
  "ikbs.constableTmp.modify" : "Modify",
  "ikbs.constableTmp.operator.gt" : "greater",
  "ikbs.constableTmp.operator.include" : "include",
  "ikbs.knghisverman.label.revert" : "Restore",
  "ikbs.constableTmp.plsSelectModifyBeginTime" : "Set the modification start time",
  "ikbs.knowledgeaudit.label.submitter" : "Submitted By",
  "ikbs.column.label.kngExtended" : "Extended Attribute",
  "ikbs.constableTmp.operator.ge" : "greater or equal to",
  "ikbs.knowledge.label.listProp" : "Drop-down list",
  "ikbs.knowledgeaudit.title.auditor" : "Reviewed By",
  "ikbs.knowledge.recycle.pleaseSelect" : "-Select-",
  "ikbs.constableTmp.tab.addTitle" : "Adding template",
  "ikbs.knghisverman.tab.title.confirm" : "Confirm",
  "ikbs.column.knowledge.button.move" : "Move",
  "ikbs.favorite.msg.plsSelectDir" : "Select the directory to be deleted.",
  "ikbs.knowledge.tip.fileNameError" : "Failed to verify the file name.",
  "ikbs.knowledge.message.uploadImgSizeError" : "The image size cannot exceed 10 MB.",
  "ikbs.knowledge.display.no" : "No",
  "ikbs.properties.inputway.select" : "Drop-down list",
  "ikbs.favorite.title.afterModifiedKngName" : "After Change",
  "ikbs.contentTmp.status" : "Template status",
  "ikbs.column.topMove.message.warning" : "Information",
  "ikbs.constableManage.fieldOprator" : "Operator",
  "ikbs.constableTmp.modifyEndTime" : "Modification end time",
  "ikbs.cntTmp.import.noData" : "The uploaded file does not contain data to be imported.",
  "ikbs.addtype.label.knowledge" : "Add Knowledge",
  "ikbs.contentTemp.export.tempname" : "Template Name",
  "ikbs.constableTmp.operator.eq" : "equal to",
  "ikbs.knowledge.tip.success" : "Success",
  "ikbs.knowledge.label.radioProp" : "Radio box",
  "ikbs.intelligent.search" : "Intelligent Search",
  "ikbs.contentTmp.contentName" : "Template name",
  "ikbs.kbshome.tab.home" : "Knowledge Base Homepage",
  "ikbs.column.passProcessWay.mark" : "Mark",
  "ikbs.column.modify.modifyValidTimeCheckError" : "The start time of the validity period cannot be later than the end time of the validity period.",
  "ikbs.constableTmp.delete.selectTmp.confirm" : "Are you sure you want to delete?",
  "ikbs.knowledge.recycle.beyondColuPath" : "Directory",
  "ikbs.favorite.msg.canNotSelectRoot" : "The root directory cannot be deleted. Select another one.",
  "ikbs.column.knowledge.button.modify" : "Modify",
  "ikbs.knowledgeaudit.message.plsSelectBeginTime" : "Select the task start time.",
  "ikbs.contentTmp.tab.addTitle" : "Adding template",
  "ikbs.column.clearRecycle.comfirm" : "Are you sure you want to empty the recycle bin?",
  "ikbs.knowledgeaudit.message.plsSelectEndTime" : "Select the task end time.",
  "ikbs.contTmp.importTmp.popup.title" : "Template Import",
  "ikbs.knowledge.label.uploadFileTypeError" : "The attachment can only be in DOC, DOCX, PPT, PPTX, XLS, XLSX, PDF, PNG, JPG, or GIF format.",
  "ikbs.column.modify.selectModifyColumnKngNumError" : "Only one knowledge item can be selected for modification.",
  "ikbs.column.knowledge.create" : "New",
  "ikbs.confirm.delete" : "Are you sure you want to delete this record",
  "ikbs.column.operation.message.warning" : "Information",
  "ikbs.contentTmp.copy" : "Copy",
  "ikbs.contentTmp.modifyEndTime" : "Modified End",
  "ikbs.column.label.passProcessWay" : "Upon Expiration",
  "ikbs.knowledgeaudit.label.auditStatus" : "Status",
  "ikbs.favorite.tab.message.isDeleteFavoriteKng" : "Are you sure you want to delete the knowledge item from favorites?",
  "ikbs.select.consultation.form" : "Please select a consultation Table",
  "ikbs.knowledge.label.sendEmail" : "Send Email",
  "ikbs.tips.operation.success" : "Operation successful.",
  "ikbs.column.knowledge.KngStatus" : "Status",
  "ikbs.contentTmp.add" : "Add",
  "ikbs.properties.edit.information" : "Edit Extended Attribute",
  "ikbs.column.knowledge.title.moveTo" : "Adjust to",
  "ikbs.column.knowledge.button.more" : "More",
  "ikbs.knowledge.properties.operation.downMove" : "Down",
  "ikbs.kbshome.label.kngBase" : "Knowledge Base",
  "ikbs.confirm.delete.by.condition" : "Are you sure you want to delete records based on conditions",
  "ikbs.column.serviceStatus.normal" : "Common",
  "ikbs.constableTmp.comfirm" : "Confirm",
  "ikbs.tips.moveColumnKnowledgeOperation.warning" : "No category is selected.",
  "ikbs.column.serviceStatus.updateService" : "Updated business",
  "ikbs.knowledge.tip.cannotCompare" : "Only two common knowledge items can be selected for comparison",
  "ikbs.cntTmp.import.importSuccess" : "Import Success.",
  "ikbs.column.label.coluType" : "Category Type",
  "ikbs.knowledge.label.submit" : "Submit",
  "ikbs.knowledge.message.uploadFileSizeError" : "The file size cannot exceed 10 MB.",
  "ikbs.knowledge.label.deleteProblem" : "Delete Q&A",
  "ikbs.knowledge.recycle.select.columnType" : "Category Type",
  "ikbs.column.delete.property.comfirm" : "Are you sure you want to delete the attribute?",
  "ikbs.knowledge.placeholder.keyword" : "Enter keywords which are speparated by semicolons (;).",
  "ikbs.kngCnt.cntTmp.popup.title" : "Select Content Template",
  "ikbs.knowledge.properties.operation.delete" : "Delete",
  "ikbs.column.knowledge.compareAdd" : "New",
  "ikbs.knowledgeaudit.label.waitForAudit" : "Pending Review",
  "ikbs.constableTmp.cnstTblTmpDesc" : "Template description",
  "ikbs.column.label.colukngname" : "Category Name",
  "ikbs.knowledgeaudit.label.batchAuditReject" : "Batch Reject",
  "ikbs.constableTmp.display.no" : "no",
  "ikbs.title.tips" : "Information",
  "ikbs.column.delete.selectNoColumn" : "No category is selected.",
  "ikbs.column.knowledge.title.move" : "Move",
  "ikbs.column.isInheritProp.no" : "No",
  "ikbs.column.message.plsSelectModifyEndTime" : "Select an end time.",
  "ikbs.column.label.servicesVaildStartTime" : "Business Validity Period Start",
  "ikbs.knowledge.properties.operation.edit" : "Edit",
  "ikbs.knowledge.pass.mark" : "Mark",
  "ikbs.column.modify.modifyServiceTimeCheckError" : "The start of the business validity period cannot be earlier than its end.",
  "ikbs.column.modify.properties.batch.message.warning" : "Information",
  "ikbs.cntTmp.import.error.oversize" : "The file size cannot exceed 20 MB.",
  "ikbs.knowledgeaudit.label.cancel" : "Cancel",
  "ikbs.search.label.keyword" : "Keyword",
  "ikbs.knghisverman.tab.message.deleteSuccess" : "Deletion successful.",
  "ikbs.search.label.sortByClick" : "Clicks",
  "ikbs.knowledge.tip.fileInjectionError" : "Failed to verify the file content.",
  "ikbs.knowledge.label.correlative" : "Associate",
  "ikbs.column.needAudit.no" : "No",
  "ikbs.column.unlock.message.success" : "Unlock successful.",
  "ikbs.konwledgetype.question" : "Q&A knowledge",
  "ikbs.knowledge.service.timeout" : "Expired",
  "ikbs.column.topMove.message.success" : "Pinned successfully.",
  "ikbs.contentTmp.Modifier" : "Modified By",
  "ikbs.knowledgeaudit.label.error" : "Error",
  "ikbs.knowledge.exception.AnswerNum.error" : "A question can have no more than 10 answers.",
  "ikbs.knowledgeaudit.title.submitTime" : "Submitted On",
  "ikbs.column.label.colukngpath" : "Category Path",
  "ikbs.knowledge.label.saveAndNext" : "Save And NextStep",
  "ikbs.constableTmp.save" : "save",
  "ikbs.knghisverman.label.operation" : "Operation",
  "ikbs.column.label.summaryInfo" : "Summary",
  "ikbs.knowledge.recycle.button.reset" : "Reset",
  "ikbs.knowledge.recycle.button.delete" : "Delete",
  "ikbs.constableTmp.status.invalid" : "Discarded",
  "ikbs.cntTmp.import.contTmpNameEditStandEmpty" : "The template content and editing specifications cannot be empty at the same time.",
  "ikbs.column.knowledge.button.copy" : "Copy",
  "ikbs.knowledge.properties.properties.inputWay" : "Input Type",
  "ikbs.column.knowledge.compareModified" : "Different",
  "ikbs.constableTmp.innerFieldFlag" : "Built-in Field",
  "ikbs.column.passProcessWay.move" : "Move to History",
  "ikbs.knowledge.msg.fileUpload" : "Upload file to knowledge ",
  "ikbs.column.unlock.message.failed" : "Unlock failed.",
  "ikbs.cntTmp.import.editStandardOversize" : "The editing specifications cannot exceed 400 characters.",
  "ikbs.contentTmp.save" : "Save",
  "ikbs.knowledge.check.kngName" : "The knowledge name or column name cannot contain the following special characters: /\\:*?\"<>|",
  "ikbs.contentTmp.tab.copyTitle" : "Duplication",
  "ikbs.column.downMove.message.fail" : "Failed to move down.",
  "ikbs.column.knowledge.compareDel" : "Unavailable",
  "ikbs.knowledge.service.hot" : "Hot",
  "ikbs.contentTmp.modify.fail" : "Modification failed",
  "ikbs.knowledge.label.servicesStartTime" : "Business Validity Period Start",
  "ikbs.constableTmp.modifyTime" : "Modified At",
  "ikbs.knowledge.index.no" : "No",
  "ikbs.kbshome.label.ClickList" : "Most Clicks",
  "ikbs.knowledge.properties.properties.status" : "Status",
  "ikbs.knowledge.properties.dictValue" : "Dictionary Value",
  "ikbs.column.delete.message.warning" : "Information",
  "ikbs.column.delete.message.fail" : "Deletion failed.",
  "ikbs.constableTmp.cnstTblName" : "Consultation Table",
  "ikbs.cntTmp.import.error.importType" : "Select an import mode.",
  "ikbs.contTmp.import.popup.title" : "Import Templates",
  "ikbs.column.title.selectUser" : "Select User",
  "ikbs.column.recycle.noSelectRecycles" : "Select a record.",
  "ikbs.knowledge.label.servicesEndTime" : "Business Validity Period End",
  "ikbs.knghisverman.label.modifier" : "Modified By",
  "ikbs.cntTmp.import.result" : "Importing content templates completed. Total number {0}, successes number {1}, failures number {2}.",
  "ikbs.cntTmp.export.crosspage.info" : "Cross-page export is not supported. Only the selected data on the current page can be exported.",
  "ikbs.column.knowledge.expired" : "Expired",
  "ikbs.knowledgeaudit.title.auditTime" : "Reviewed On",
  "ikbs.knowledge.tip.uploadFailed" : "Upload failed.",
  "ikbs.column.knowledge.CurVersion" : "Source Version",
  "ikbs.content.upload.file.Frequent" : "Too many API requests, please try again later.",
  "ikbs.knowledge.check.keyword" : "The keywords cannot contain special characters.",
  "ikbs.knowledge.tip.fileTypeError" : "Failed to verify the file type.",
  "ikbs.column.upMove.message.warning" : "Information",
  "ikbs.contentTmp.modifyStartTime" : "Modified Start",
  "ikbs.knowledgeaudit.label.taskEndTime" : "Task End Time",
  "ikbs.constableTmp.modifyBeginTime" : "Modification Start Time",
  "ikbs.knowledge.lable.copy" : "Duplicate",
  "ikbs.knowledgeaudit.label.batchAuditPass" : "Batch Approve",
  "ikbs.contentTmp.delete" : "Delete",
  "ikbs.knowledge.tab.title" : "Knowledge Compilation",
  "ikbs.knowledge.recycle.label.knowledgeType" : "Type",
  "ikbs.column.knowledge.Type" : "Type",
  "ikbs.kbshome.button.thirtyDayList" : "30 Days",
  "ikbs.cntTmp.import.queryContTempErr" : "Failed to query the content template.",
  "ikbs.favorite.table.delete" : "Delete",
  "ikbs.column.move.message.warning" : "Information",
  "ikbs.knowledge.title.propTitle" : "Name",
  "ikbs.constableTmp.modify.selectedOne" : "Only one template can be selected",
  "ikbs.knowledge.label.uploadAttachment" : "Upload Attachment",
  "ikbs.knowledge.pass.move" : "Move to History",
  "ikbs.favorite.title.warning" : "Information",
  "ikbs.kbshome.label.favorite" : "Shortcuts",
  "ikbs.knowledge.label.deleteProperty" : "Delete",
  "ikbs.column.knowledge.button.publish" : "Publish",
  "ikbs.column.resumeRecycle.comfirm" : "Are you sure you want to restore it?",
  "ikbs.constableTmp.status.valid" : "Enabled",
  "ikbs.knowledgeaudit.label.reject" : "Reject",
  "ikbs.contentTmp.export" : "Export",
  "ikbs.properties.operation.message.fail" : "Failure",
  "ikbs.knowledge.label.validEndTime" : "Validity Period End",
  "ikbs.knowledge.label.preStep" : "Pre Step",
  "ikbs.properties.add.save" : "OK",
  "ikbs.knowledgeaudit.label.query" : "Query",
  "ikbs.knowledge.tip.keywordCountError" : "A maximum of {count} keywords are supported.",
  "ikbs.favorite.table.edit" : "Edit",
  "ikbs.knowledge.label.addKngPoint" : "Add Question",
  "ikbs.knowledge.label.creatorName" : "Prepared by",
  "ikbs.knowledge.tip.operateFailed" : "Operation failed.",
  "ikbs.knghisverman.tab.message.isDeleteHisVer" : "Are you sure you want to delete this version from history?",
  "ikbs.favorite.title.addDirectory" : "Add Directory",
  "ikbs.knowledge.label.type" : "Type",
  "ikbs.column.Move.message.success" : "Move successful.",
  "ikbs.column.knowledge.update" : "Updated",
  "ikbs.search.label.sortByTime" : "Time",
  "ikbs.knowledge.label.summaryInfo" : "Summary",
  "ikbs.properties.detail.information" : "Extended Attribute Details",
  "ikbs.column.release.message.success" : "Publish successful.",
  "ikbs.knowledge.label.ok" : "OK",
  "ikbs.knowledge.label.upload" : "Upload",
  "ikbs.topMove.selectTopMoveColumnKngNumError" : "No category knowledge is selected.",
  "ikbs.constableTmp.display.yes" : "yes",
  "ikbs.column.modify.properties.batch.message.error" : "No category knowledge is selected.",
  "ikbs.cntTmp.import.failReason" : "Failed to upload record {0}; failure cause: {1}",
  "ikbs.knowledge.properties.properties.title" : "Name",
  "ikbs.knowledgeaudit.label.auditor" : "Reviewed By",
  "ikbs.cntTmp.export.exportNum.blank" : "Select the content templates to be exported.",
  "ikbs.contentTmp.status.valid" : "Valid",
  "ikbs.column.modify.selectModifyColumnKngNumZERO" : "No category knowledge is selected.",
  "ikbs.column.tip.success" : "Information",
  "ikbs.knowledge.tip.relaKngCountError" : "A maximum of {count} associated knowledge items are supported.",
  "ikbs.contentTemp.export.tempcontent" : "Template Content",
  "ikbs.contentTmp.query" : "Query",
  "ikbs.properties.status.draft" : "Draft",
  "ikbs.column.knowledge.ModifyTime" : "Modified On",
  "ikbs.knowledgeaudit.title.status" : "Status",
  "ikbs.column.knowledge.compare" : "Compare",
  "ikbs.knowledge.label.uploadFileSizeError" : "The attachment cannot exceed 10 MB.",
  "ikbs.cntTmp.import.invalidData" : "Invalid Data.",
  "ikbs.column.delete.message.success" : "Deletion successful.",
  "ikbs.favorite.tree.favoriteRootName" : "Favorites",
  "ikbs.column.moveTo.selectColumnKngNumError" : "No category knowledge is selected.",
  "ikbs.cntTmp.export.error.syserr" : "Failed to export the content template file.",
  "ikbs.column.button.confirm" : "Submit",
  "ikbs.constableTmp.delete.noSelectTmp" : "No template is selected",
  "ikbs.content.transfer.html.success" : "Failed to convert the content to HTML.",
  "ikbs.column.modify.modifyValidServiceEndTimeCheckError" : "The end of the business validity period cannot be later than the end of the validity period.",
  "ikbs.knowledge.properties.label.propertiesTitle" : "Name",
  "ikbs.knowledge.label.addAnswer" : "Add Answer",
  "ikbs.column.delete.message.comfirm" : "Confirm",
  "ikbs.column.button.search" : "Query",
  "ikbs.knghisverman.label.modifyTime" : "Modified On",
  "ikbs.select.record.to.delete" : "Please select the record to be deleted",
  "ikbs.knowledgeaudit.label.submitterOrAuditor" : "Submitted By/Reviewed By",
  "ikbs.constableManage.fieldValue" : "Value",
  "ikbs.column.history.down.empty" : "The column does not contain columns or knowledge. The column cannot be opened.",
  "ikbs.constableTmp.name" : "Template name",
  "ikbs.knowledgeaudit.label.pleaseSelect" : "-Select-",
  "ikbs.cntTmp.import.syserr" : "Failed to import the content template.",
  "ikbs.column.copy.selectCopyColumnKngNumError" : "Only one knowledge item can be selected for copying.",
  "ikbs.contentTmp.status.invalid" : "Invalid",
  "ikbs.column.message.ids.max.size" : "The number of items for batch operation cannot exceed 100.",
  "ikbs.knowledge.properties.label.query" : "Query",
  "ikbs.kngCnt.cntTmp.importType.new" : "Create Directly",
  "ikbs.content.transfer.html.fail" : "The content is successfully converted to HTML.",
  "ikbs.column.upMove.message.success" : "Moved up successfully.",
  "ikbs.knghisverman.title.kngHisVerMan" : "Manage Knowledge History",
  "ikbs.favorite.field.directoryName" : "Directory Name",
  "ikbs.column.Move.message.fail" : "Move failed.",
  "ikbs.column.deletePermanently.message.fail" : "Permanent deletion failed.",
  "ikbs.columntype.knowledge" : "Knowledge",
  "ikbs.knowledge.type.common" : "Common knowledge",
  "ikbs.knowledgeaudit.label.ok" : "OK",
  "ikbs.contentTmp.exception.contTmpName" : "Illegal input",
  "ikbs.kngCnt.cntTmp.use.cntReplaceConfirm" : "Are you sure you want to replace the current knowledge content?",
  "ikbs.search.button.search" : "Search",
  "ikbs.constableTmp.delete.success" : "Deletion Success",
  "ikbs.knowledge.recycle.coluKngName" : "Name",
  "ikbs.constableTmp.add.success" : "Addition Success",
  "ikbs.kbshome.button.orderByName" : "By Name",
  "ikbs.knowledge.tip.uploadSuccess" : "Upload successful.",
  "ikbs.constableManage.incorrect" : "Incorrect condition",
  "ikbs.column.modify.properties.batch.message.success" : "The attributes are modified in batches successfully.",
  "ikbs.properties.inputway.text" : "Text input",
  "ikbs.column.move.selectMoveColumnKngNumError" : "No category knowledge is selected.",
  "ikbs.knowledgeaudit.title.auditRemark" : "Remarks",
  "ikbs.knowledge.label.indexNeedUpdate" : "Update Search Index",
  "ikbs.knowledge.recycle.status" : "Status",
  "ikbs.kbshome.button.orderByClicks" : "Clicks",
  "ikbs.favorite.table.name" : "Name",
  "ikbs.column.knowledge.RelVersion" : "Target Version",
  "ikbs.kbshome.button.dayList" : "1 Day",
  "ikbs.column.modify.modifyValidTimeServiceTimeSame" : "Change both the start and end of the validity period and those of the business validity period.",
  "ikbs.column.label.servicesVaildEndTime" : "Business Validity Period End",
  "ikbs.knowledgeaudit.label.submitAudit" : "Pending",
  "ikbs.column.release.selectreleaseColumnKngTypeError" : "Only knowledge can be published.",
  "ikbs.knowledge.type.points" : "Q&A knowledge",
  "ikbs.column.knowledge.modifyStartTime" : "Modified Start",
  "ikbs.favorite.title.preModifiedKngName" : "Before Change",
  "ikbs.column.deleteRecycle.comfirm" : "Are you sure you want to delete it?",
  "ikbs.recycle.title" : "Recycle Bin",
  "ikbs.column.tree.rootName" : "Category Knowledge",
  "ikbs.column.downMove.message.warning" : "Information",
  "ikbs.knghisverman.tab.title.kngBaseInfo" : "Basic Information",
  "ikbs.addtype.label.column" : "Add Category",
  "ikbs.knowledge.msg.updateKngPath" : "Staticize knowledge details",
  "ikbs.kbshome.label.recentViews" : "My History",
  "ikbs.column.knowledge.publish" : "Published",
  "ikbs.contentTemp.export.tempdesc" : "Template Description",
  "ikbs.column..topMove.message.fail" : "Failed to pin to top.",
  "ikbs.knowledge.recycle.button.clearAll" : "Empty Recycle Bin",
  "ikbs.constableTmp.status" : "Template status",
  "ikbs.contentTmp.contentDesc" : "Template description",
  "ikbs.knghisverman.label.version" : "Version",
  "ikbs.column.knowledge.button.unLock" : "Unlock",
  "ikbs.knowledge.error.property" : "Select the value of attribute [{}].",
  "ikbs.knowledgeaudit.label.mySubmitAudit" : "My Application",
  "ikbs.column.label.kngReleaseWay" : "Review and Publish Knowledge in Category",
  "ikbs.contentTmp.add.fail" : "Addition failed",
  "ikbs.constableTmp.moveUp" : "Up",
  "ikbs.constableManage.condition.limit" : "The number of conditions cannot exceed 10",
  "ikbs.column.downMove.selectDowmMoveColumnKngNumError" : "No category knowledge is selected.",
  "ikbs.column.modify.modifyzeropropertieserror" : "No attribute is modified.",
  "ikbs.column.label.isInheritProp" : "Inherit Parent Category Attribute",
  "ikbs.column.knowledge.reject" : "Rejected",
  "ikbs.column.deletePermanently.columnkng" : "No category knowledge is selected.",
  "ikbs.constableManage.modifyConstable" : "Modify Consultation Table Data",
  "ikbs.contTmp.importTmp.title" : "Download Import Template",
  "ikbs.constableTmp.fieldViewName" : "Display name",
  "ikbs.cntTmp.import.contTmpDesc" : "Incorrect template description.",
  "ikbs.constableManage.addConstable" : "Add Consultation Table Data",
  "ikbs.contentTmp.operate" : "Operate",
  "ikbs.column.upMove.message.fail" : "Failed to move up.",
  "ikbs.constableTmp.query" : "Query",
  "ikbs.column.recycle.message.comfirm" : "Confirm",
  "ikbs.kbshome.button.advancedSearch" : "Advanced Search",
  "ikbs.constableTmp.modify.success" : "Modification Success",
  "ikbs.kbshome.tip.refresh" : "Refresh",
  "ikbs.constableTmp.delete.fail" : "Deletion Failure",
  "ikbs.properties.operation.message.success" : "Success",
  "ikbs.column.knowledge.clear" : "Clear",
  "ikbs.knowledge.label.auditor" : "Reviewed By",
  "ikbs.column.tip.error" : "Error",
  "ikbs.column.deletePermanently.columnkng.comfirm" : "Are you sure you want to delete the category knowledge permanently?",
  "ikbs.favorite.tab.message.isDeleteFavoriteDir" : "Are you sure you want to delete the favorites directory and the knowledge in the directory?",
  "ikbs.column.tips.modifyColumnOperation.error" : "Failed to modify the category knowledge.",
  "ikbs.column.button.reset" : "Reset",
  "ikbs.knowledgeaudit.message.auditSuccess" : "The application is approved successfully.",
  "ikbs.column.knowledge.Modifier" : "Modified By",
  "ikbs.cntTmp.import.error.contTmpNameExist" : "The template name exists.",
  "ikbs.cntTmp.export.info.success" : "Export Success.",
  "ikbs.knowledge.label.kngAttachmentUpload" : "Upload knowledge category attachment",
  "ikbs.knowledge.tip.propCountLimited" : "A maximum of 20 attributes can be associated.",
  "ikbs.column.knowledge.button.deletePermanently" : "Permanently Delete",
  "ikbs.knowledge.recycle.label.operateTimeEnd" : "Deletion End Time",
  "ikbs.knowledge.label.display" : "Display on Front Page",
  "ikbs.cntTmp.export.result" : "Exporting content templates completed. Total number {0}, successes number {1}, failures number {2}.",
  "ikbs.knowledge.label.deleteAnswer" : "Delete Answer",
  "ikbs.knowledge.properties.operation.detail" : "Details",
  "ikbs.column.knowledge.modifyEndTime" : "Modified End",
  "ikbs.knowledgeaudit.title.name" : "Name",
  "ikbs.knowledge.label.modifierName" : "Last updated by",
  "ikbs.column.knowledge.button.moveTo" : "Adjust to",
  "ikbs.knowledge.title.knowledgeContent" : "Content",
  "ikbs.knowledge.tip.fileSizeError" : "Failed to verify the file size.",
  "ikbs.constableTmp.delete" : "Delete",
  "ikbs.tips.copy.onlyKngCanCopy" : "Only knowledge can be copied.",
  "ikbs.column.passProcessWay.delete" : "Delete",
  "ikbs.column.label.serviceStatus" : "Business Status",
  "ikbs.column.button.cancel" : "Close",
  "ikbs.knowledge.label.clickNum" : "Views",
  "ikbs.Column.MoveTo.message.success" : "Operation successful.",
  "ikbs.knowledge.index.yes" : "Yes",
  "ikbs.knowledge.tip.fileIOError" : "Failed to upload the file.",
  "ikbs.column.modify.properties.message.warning" : "Information",
  "ikbs.constableTmp.operate" : "Operation",
  "ikbs.knowledgeaudit.title.path" : "Path",
  "ikbs.column.serviceStatus.expired" : "Expired",
  "ikbs.column.knowledge.audit" : "Reviewed",
  "ikbs.contTmp.import.tip" : "1. Only an .xlsx file can be uploaded. 2. The maximum file size is 20 MB. 3. A maximum of 10 records can be imported.",
  "ikbs.knowledge.label.save" : "Save",
  "ikbs.knowledge.title.knowledgeExtended" : "Extended Information",
  "ikbs.column.knowledge.button.modifyPropBatch" : "Modify Attribute",
  "ikbs.column.type.history" : "History",
  "ikbs.cntTmp.import.contTmpNameError" : "Incorrect template name.",
  "ikbs.knowledge.tip.error" : "Error",
  "ikbs.contentTmp.delete.success" : "Deletion Success",
  "ikbs.knowledgeaudit.label.success" : "Success",
  "ikbs.contentTmp.editStandard" : "Editing Specifications",
  "ikbs.favorite.title.editFavoriteKngName" : "Change Name",
  "ikbs.column.knowledge.button.down" : "Down",
  "ikbs.column.modify.message.error" : "Modification failed.",
  "ikbs.column.knowledge.compareKng" : "Compare Knowledge",
  "ikbs.knowledge.check.inputText" : "Special characters are not allowed.",
  "ikbs.column.knowledge.button.delete" : "Delete",
  "ikbs.knowledge.service.new" : "New business",
  "ikbs.knowledge.properties.operation.disable" : "Disable",
  "ikbs.column.serviceStatus.hot" : "Hot",
  "ikbs.contentTmp.add.success" : "Addition Success",
  "ikbs.favorite.msg.modifyNodeNameSuccess" : "Operation successful.",
  "ikbs.column.deletePermanently.message.warning" : "Information",
  "ikbs.contentTmp.modify" : "Modify",
  "ikbs.kbshome.button.sevenDayList" : "7 Days",
  "ikbs.column.knowledge.button.turnToHistory" : "Move to History",
  "ikbs.constableTmp.cancle" : "cancel",
  "ikbs.knowledge.recycle.button.resume" : "Restore",
  "ikbs.knowledgeaudit.label.auditing" : "Under review",
  "ikbs.knowledge.release.audit" : "Review then publish",
  "ikbs.constableTmp.yes" : "Yes",
  "ikbs.column.label.displayFlag" : "Display on Front Page",
  "ikbs.column.moveto.back" : "Next to",
  "ikbs.column.modify.message.warning" : "Information",
  "ikbs.knowledge.label.nextStep" : "Next Step",
  "ikbs.column.moveTo.message.warning" : "Information",
  "ikbs.column.isInheritProp.yes" : "Yes",
  "ikbs.knowledge.title.knowledgeBase" : "Basic Information",
  "ikbs.knowledge.label.keyword" : "Keywords",
  "ikbs.knowledgeaudit.label.reset" : "Reset",
  "ikbs.knowledge.message.uploadImgTypeError" : "The image format is incorrect.",
  "ikbs.knowledge.label.moveUp" : "Up",
  "ikbs.properties.inputway.checkbox" : "Check box",
  "ikbs.knowledgeaudit.label.auditReject" : "Rejected",
  "ikbs.contentTmp.ModifyTime" : "Modified On",
  "ikbs.knowledge.label.checkboxProp" : "Check box",
  "ikbs.knowledge.label.kngMsg" : "Knowledge Information",
  "ikbs.column.knowledge.compareResult" : "Comparison Results",
  "ikbs.cntTmp.import.invalidFile" : "Invalid file.",
  "ikbs.kbshome.button.orderByOrderId" : "By No.",
  "ikbs.column.knowledge.modifyColumnInformation" : "Modify Category",
  "ikbs.knowledgeaudit.placeholder.pleaseSelect" : "-Select-",
  "ikbs.constableTmp.tab.modifyTitle" : "Modifying template",
  "ikbs.knowledge.label.modifyRemark" : "Remarks",
  "ikbs.column.release.selectreleaseColumnKngNumError" : "No knowledge is selected.",
  "ikbs.column.knowledge.button.order" : "Sort",
  "ikbs.contentTmp.import" : "Import",
  "ikbs.column.label.validEndTime" : "Validity Period End",
  "ikbs.properties.status.enable" : "Enable",
  "ikbs.column.modify.modifyValidServiceStartTimeCheckError" : "The start of the business validity period cannot be earlier than the start of the validity period.",
  "ikbs.knowledge.error.contentEmpty" : "The knowledge content cannot be empty.",
  "ikbs.column.upMove.selectUpMoveColumnKngNumError" : "No category knowledge is selected.",
  "ikbs.constableTmp.add" : "Add",
  "ikbs.constableTmp.operator.exclude" : "exclude",
  "ikbs.column.knowledge.LockedFlat" : "Locked By",
  "ikbs.knowledge.label.cancelCollection" : "Remove from Favorites",
  "ikbs.contentTmp.reset" : "Reset",
  "ikbs.knowledge.label.textProp" : "Text input",
  "ikbs.knowledge.title.propType" : "Input Type",
  "ikbs.cntTmp.export.exportNum.oversize" : "A maximum of 10 content templates can be exported.",
  "ikbs.properties.status.disable" : "Disable",
  "ikbs.knowledgeaudit.label.knowledgeName" : "Knowledge Name",
  "ikbs.constableManage.fieldName" : "Column name",
  "ikbs.contentTmp.close" : "Close",
  "ikbs.knowledge.properties.label.add" : "New",
  "ikbs.knowledgeaudit.label.auditPass" : "Approved",
  "ikbs.common.search" : "Common Search",
  "ikbs.contentTmp.modify.success" : "Modification Success",
  "ikbs.column.downMove.message.success" : "Moved down successfully.",
  "ikbs.knowledge.label.draft" : "Draft",
  "ikbs.knowledge.label.creationTime" : "Created on",
  "ikbs.column.modify.properties.batch.message.fail" : "Failed to modify the attributes in batches.",
  "ikbs.column.message.plsSelectModifyStartTime" : "Select a start time.",
  "ikbs.knowledge.label.problem" : "Question",
  "ikbs.constableTmp.displayFlag" : "Displayed or Not",
  "ikbs.Column.MoveTo.message.fail" : "Operation failed.",
  "ikbs.column.knowledge.columnKnowledgeName" : "Category Knowledge Name",
  "ikbs.column.knowledge.button.top" : "Pin",
  "ikbs.tips.addColumnOperation.error" : "Failed to add the category.",
  "ikbs.search.label.sortByName" : "By Name",
  "ikbs.column.delete.selectdeletecolumn.comfirm" : "Are you sure you want to delete the category and all its child categories and knowledge?",
  "ikbs.constableTmp.modifierName" : "Modified by",
  "ikbs.knowledge.label.attachment" : "Attachment",
  "ikbs.column.unlock.selectUnlockColumnKngNumError" : "No category knowledge is selected.",
  "ikbs.contentTmp.tmpContent" : "Template content",
  "ikbs.column.label.coluKngBrowsing" : "browse group",
  "ikbs.kbshome.button.totalList" : "All Time",
  "ikbs.search.label.resultDes" : "No data",
  "ikbs.knowledge.recycle.button.query" : "Query",
  "ikbs.knowledge.properties.label.reset" : "Reset",
  "ikbs.constableTmp.warning" : "Information",
  "ikbs.knowledge.label.kngReleaseWay" : "Publish Mode",
  "ikbs.properties.inputway.radio" : "Radio box",
  "ikbs.knowledge.recycle.operationTime" : "Deleted On",
  "ikbs.constableTmp.plsSelectModifyEndTime" : "Set the modification end time",
  "ikbs.column.knowledge.button.turnToNormal" : "Move to Normal",
  "ikbs.knowledge.label.validStartTime" : "Validity Period Start",
  "ikbs.knowledge.pass.delete" : "Delete",
  "ikbs.column.displayflag.show" : "Yes",
  "ikbs.knowledge.recycle.select.knowledgeStatus" : "Knowledge Status",
  "ikbs.column.deletePermanently.message.success" : "Permanent deletion successful.",
  "ikbs.time.lessThan.currentTime" : "The end cannot be earlier than the current time.",
  "ikbs.knowledge.recycle.label.operateTimeStart" : "Deletion Start Time",
  "ikbs.knowledge.recycle.label.knowledgeName" : "Category Knowledge Name",
  "ikbs.knghisverman.label.delete" : "Delete",
  "ikbs.column.knowledge.Name" : "Name",
  "ikbs.knowledgeaudit.placeholder.pleaseInput" : "Enter a name.",
  "ikbs.column.label.validStartTime" : "Validity Period Start",
  "ikbs.knowledge.display.yes" : "Yes",
  "ikbs.knowledge.label.servicesTime" : "Validity period",
  "ikbs.search.label.updateStartTime" : "Update Start Time",
  "ikbs.favorite.msg.addDirectorySuccess" : "The directory is added successfully.",
  "ikbs.search.label.result" : "Search Results",
  "ikbs.constableTmp.modify.fail" : "Modification Failure",
  "ikbs.constableTmp.add.fail" : "Addition Failure",
  "ikbs.column.knowledge.modifierId" : "Modified By",
  "ikbs.tips.propertyDictValueNumExceedMaxValue.warning" : "The number of attribute dictionary values exceeds the maximum.",
  "ikbs.constableTmp.no" : "No",
  "ikbs.knowledge.label.close" : "Close",
  "ikbs.favorite.table.operate" : "Operation",
  "ikbs.kngCnt.cntTmp.editStandard.title" : "Editing Specifications",
  "ikbs.constableTmp.anonymizationFlag" : "Anonymized or Not",
  "ikbs.knowledge.tip.fileCountError" : "A maximum of {count} attachments are supported.",
  "ikbs.column.copy.selectCopyColumnKngNumZERO" : "No knowledge is selected for copying.",
  "ikbs.constableTmp.delete.byCondition" : "Delete by condition",
  "ikbs.column.delete.selectdeletecolumnkng.comfirm" : "Are you sure you want to delete the categories or knowledge?",
  "ikbs.knowledge.label.name" : "Knowledge Name",
  "ikbs.knowledge.check.textArea" : "The following special characters are not allowed: <>/",
  "ikbs.knowledge.label.addToCollection" : "Add to Favorites",
  "ikbs.knowledge.recycle.operator" : "Operator",
  "ikbs.konwledgetype.knowledge" : "Common knowledge",
  "ikbs.knowledgeaudit.label.taskBeginTime" : "Task Start Time",
  "ikbs.kngCnt.cntTmp.importType.sameNameCover" : "Overwrite Templates with Same Names",
  "ikbs.column.knowledge.Path" : "Path",
  "ikbs.kbshome.label.plsInputKeyWord" : "Enter a keyword.",
  "ikbs.knowledge.label.addProperty" : "Add",
  "ikbs.knowledgeaudit.title.submitter" : "Submitted By",
  "ikbs.knowledge.properties.properties.operation" : "Operation",
  "ikbs.knowledge.label.moveDown" : "Down"
}