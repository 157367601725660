import {emotionCreate} from './emojiFaceCreate.js'
import {emotionconf} from './emojiFaceMap.js'
import {emojiUs, emojiCn} from './emojiInternationalization.js'
import {getCCmeAssetsURL} from "@/views/ccmessaging/utils/index.js";

/**
 * 工作台表情入口文件
 *
 * @param options 选项参数
 * @returns {insertEmotionConf}
 */
export const insertEmotionConf = (options) => {
    if (!isValid(options)) {
        return this;
    }
    let defaults = {
        before: function () {
        },
        after: function () {
        },
        position: 'LEFTBOTTOM',
        length: 7,
        emojis: {
            emoji: {
                path: 'emotion/emoji',
                code: ',',
                name: 'emoji'
            },
            wechat: {
                path: 'emotion/wechat',
                code: ':',
                name: 'wechat'
            }
        },
        showBabar: true
    };
    let opt = $.extend({}, defaults, options);
    let emoji_item = emotionCreate(opt.emojis, opt.emojiMaps, opt.length);
    let container = $("<div class='emoji_container'>");
    let content = $("<div class='emoji_content'>");
    let tabar = $("<div class='emoji_tabar' style='display: " + (opt.showBabar ? '' : 'none') + ";'>");
    let tabarChild = '';
    let childNum = 0;
    $.map(opt.emojis, function (item, index) {
        tabarChild += '<li data-index="' + childNum + '">' + item.name + '</li>';
        childNum++;
    });
    tabar.append('<ul>' + tabarChild + '</ul>');
    container.append(content.append(emoji_item)).append(tabar).appendTo('#div-emoji');

    /**
     * 处理表情点击事件，并插入到输入框控件
     */
    content.on('click', 'li', function () {
        let code = $(this).attr('data-code');
        let title = $(this).attr('title');
        if ($['cookie']('u-locale') === 'en_US') {
            let emojiIndex = emojiUs.indexOf(title);
            title = emojiCn[emojiIndex];
        }
        let str = '';
        switch (code) {
            case ':':
                str = '[' + code + title + ']';
                let opts1 = {
                    content: str,
                    emojis: [
                        {
                            path: 'emotion/emoji',
                            code: ',',
                            type: 'emoji'
                        },
                        {
                            path: 'emotion/wechat',
                            code: ':',
                            type: 'wechat'
                        }
                    ]
                };
                str = emojiParse(opts1);
                tinyMCE.get('richEditor').execCommand('mceInsertContent', false, str);
                break;
            case ',':
                str = '[' + code + title + ']';
                let opts2 = {
                    content: str,
                    emojis: [
                        {
                            path: 'emotion/emoji',
                            code: ',',
                            type: 'emoji'
                        },
                        {
                            path: 'emotion/wechat',
                            code: ':',
                            type: 'wechat'
                        }
                    ]
                };
                str = emojiParse(opts2);
                tinyMCE.get('richEditor').execCommand('mceInsertContent', false, str);
                break;
        }
    });

    /**
     * 处理表情面板选择事件
     */
    tabar.on('click', 'li', function () {
        let dLeft = 355;
        if ($(window).width() < dLeft) {
            dLeft = $(window).width();
        }
        let index = $(this).attr('data-index');
        let left = -dLeft * parseInt(index);
        left = left + 'px';
        content.css({
            marginLeft: left
        });
    });

    // 阻止冒泡
    tabar.on('click', (e) => {
        e.stopPropagation()
    })

    /**
     * 校验options参数
     *
     * @param options options参数
     * @returns {boolean|*}
     */
    function isValid(options) {
        return !options || (options && typeof options === 'object');
    }
}

/**
 * 组装表情
 *
 * @param options options参数
 * @returns {string|emojiParse|*}
 */
export const emojiParse = (options) => {
    if (!isValid(options)) {
        return this;
    }
    let defaults = {
        content: '',
        emojis: [
            {
                path: 'emotion/emoji',
                code: ',',
                type: 'emoji'
            },
            {
                path: 'emotion/wechat',
                code: ':',
                type: 'wechat'
            }
        ]
    };
    let opts = $.extend({}, defaults, options);
    let content = $.trim(opts.content) ? opts.content : '';
    if (!content) {
        return content;
    }

    function isValid(options) {
        return !options || (options && typeof options === 'object');
    }

    for (let i = 0; i < opts.emojis.length; i++) {
        content = parse(content, opts.emojis[i].code, opts.emojis[i].path, opts.emojis[i].type);
    }

    /**
     * 判断是否存在该表情
     *
     * @param str 表情字符
     * @param type 类型
     * @returns {string} 路径
     */
    function getPath(str, type) {
        let path = '';
        $.map(emotionconf[type], function (value, key) {
            if (value === str) {
                path = key;
            }
        });
        return path;
    }

    /**
     * 解析表情，并插入表情图片
     *
     * @param content 内容
     * @param code 编码
     * @param path 路径
     * @param type 类型
     * @returns {*} 内容
     */
    function parse(content, code, path, type) {
        const reg = new RegExp('\\[\\' + code + "[\\u4e00-\\u9fa5]+\\]", 'g');
        const result = content.match(reg);
        if (result) {
            const patt02 = new RegExp('\^\\[\\' + code);
            const patt03 = /]$/;
            for (let i = 0; i < result.length; i++) {
                let tmp = result[i].replace(patt02, '');
                tmp = tmp.replace(patt03, '');
                let name = getPath(tmp, type);
                // 判断是否存在，不存在则结束该次循环
                if (!name) {
                    continue;
                }
                let path1 = getCCmeAssetsURL(path + '/' + name + '.png');
                content = content.replace(result[i], '<img class="ccme_emoji" src="' + path1 + '" alt="" width="25" height="25">')
            }
        }
        return content;
    }

    return content;
}
