<template>
  <sweet-dialog
    append-to-body
    destroy-on-close
    v-model="dialogVisible"
    :title="$t('ccmessaging.chat.templateMsg.chooseMultimedia')"
    width="616px"
    @open="handleOpen"
    @closed="handleClosed"
    :close-on-click-modal="false"
  >
    <div class="whatsApp-msg-mult">
      <div class="whatsApp-msg-mult__search">
        <sweet-input
          class="form-content-width"
          :placeholder="$t('ccmessaging.chat.chatcard.name')"
          v-model="searchInfo.keyword"
          clearable
          @change="handleSearch"
          @keydown.enter="handleSearch"
        >
          <template #prefix>
            <sweet-icon :title="$t('chat.session.label.search')" @click="handleSearch">
              <search />
            </sweet-icon>
          </template>
        </sweet-input>
      </div>
      <aicc-table
        ref="whatsAppMsgMultTableRef"
        :tableData="whatsAppMsgMultTableInfo.tableData"
        :tableColumns="whatsAppMsgMultTableInfo.tableColumns"
        :tableTools="{ showPagination: true, columnConfig: false }"
        :paginationConfig="whatsAppMsgMultTableInfo.paginationConfig"
        @handlePagination="handleQuery"
        @radio-change="handleRadio"
      ></aicc-table>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleCancel">{{ $t('message.template.message.cancel') }}</el-button>
        <el-button type="primary" @click="handleSubmit">{{
          $t('ccmessaging.chat.multimedia.finish')
        }}</el-button>
      </span>
    </template>
  </sweet-dialog>
</template>

<script setup>
import { computed, getCurrentInstance, reactive, ref, toRefs } from 'vue'
import { queryMultiMediaLibraryListByTypeCommon } from '@ccme/api/online-chat-workbench/index.js'
import appConfig from '@/config'

const emit = defineEmits(['update:whatsAppMsgMultDialog'])
const {
  appContext: {
    config: { globalProperties }
  }
} = getCurrentInstance()
const props = defineProps({
  whatsAppMsgMultDialog: {
    default: false
  },
  whatsAppMediaType: {
    default: '' // 多媒体类型
  }
})
const { whatsAppMsgMultDialog, whatsAppMediaType } = toRefs(props)

const dialogVisible = computed({
  get() {
    return whatsAppMsgMultDialog.value
  },
  set(val) {
    emit('update:whatsAppMsgMultDialog', val)
  }
})

// 表格信息
const whatsAppMsgMultTableRef = ref(null)
const whatsAppMsgMultTableInfo = reactive({
  tableData: [],
  tableColumns: [
    {
      type: 'radio',
      radioKey: 'id',
      width: '60'
    },
    {
      label: globalProperties.$t('ccmessaging.chat.chatcard.multimediaid'),
      prop: 'id'
    },
    {
      label: globalProperties.$t('ccmessaging.chat.chatcard.multimedianame'),
      prop: 'name'
    }
  ],
  paginationConfig: {
    ...appConfig.pagination.dialog,
    layout: appConfig.pagination.LAYOUT.ADVANCED
  }
})

const searchInfo = reactive({
  keyword: ''
})

// 弹窗打开
const handleOpen = () => {
  if (!whatsAppMediaType.value) {
    return
  }
  handleSearch()
}

// 弹窗关闭
const handleClosed = () => {
  rowInfo = {}
  searchInfo.keyword = ''
}

// 搜索
const handleSearch = () => {
  whatsAppMsgMultTableRef.value.handleInitChange()
}

const mediaTypeToStr = (str) => {
  let mediaStr
  switch (str) {
    case '0':
      mediaStr = ''
      break
    case '1':
      mediaStr = 'IMAGE'
      break
    case '2':
      mediaStr = 'VIDEO'
      break
    case '3':
      mediaStr = 'LOCATE'
      break
    default:
      mediaStr = 'IMAGE'
  }
  return mediaStr
}

const handleQuery = (pagination) => {
  const { limit, offset } = pagination
  queryMultiMediaLibraryListByTypeCommon({
    limit,
    offset,
    name: searchInfo.keyword,
    mediaType: mediaTypeToStr(whatsAppMediaType.value)
  }).then((result = {}) => {
    const { resultList, total } = result
    whatsAppMsgMultTableInfo.tableData = resultList ?? []
    whatsAppMsgMultTableInfo.paginationConfig.total = total ?? 0
  })
}

// 选中的模板数据处理
let rowInfo = {}
const handleRadio = ({ row }) => {
  rowInfo = row
}

// 取消
const handleCancel = () => {
  rowInfo = {}
  searchInfo.keyword = ''
  dialogVisible.value = false
}

// 确定
const handleSubmit = () => {
  emit('selectSomeMedia', rowInfo)
  handleCancel()
}
</script>

<style lang="less" scoped>
.whatsApp-msg-mult {
  .form-content-width {
    width: 25rem !important;
  }

  .whatsApp-msg-mult__search {
    padding-bottom: 1rem;
    flex-shrink: 0;
  }
}
</style>
