export const getAssetURL = (image) => {
  return new URL(`../assets/img/${image}`, import.meta.url).href
}

// 获取tinymce国际化文件的地址
export const getTinymceLangs = (langs) => {
  return new URL(`../plugin/tinymce/langs/${langs}.js`, import.meta.url).href
}

// 替换开源qs.stringfy方法
export function qsStringify(obj) {
  let str = '';
  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (str !== '') {
        str += '&';
      }
      str += key + '=' + encodeURIComponent(obj[key]);
    }
  }
  return str;
}

// iframe加载老页面地址转换
export function getIframeUrl(destUrl) {
  let targetUrl
  let rootUrl
  if (import.meta.env.MODE === 'development') {
    rootUrl = 'service-cloud'
  } else {
    rootUrl = `https://${window.location.host}/service-cloud`
  }
  targetUrl = destUrl.replace('resource.root', rootUrl)
  if (targetUrl.includes('{domainName}')) {
    targetUrl = targetUrl
      .replace('{domainName}', `https://${window.location.host}`)
      .replace('{tenantName}', top.window.$Model.tenantInfo.adminAccount)
  }
  const redirectSplitArr = targetUrl.split("redirect=")
  if (redirectSplitArr.length > 1) {
    targetUrl = redirectSplitArr[0] + "redirect=" + encodeURIComponent(redirectSplitArr[1])
  }
  if (destUrl === 'apiportal/' || destUrl === 'toolportal/index.html') {
    targetUrl = `https://${window.location.host}/${destUrl}`
  }
  return targetUrl
}

/**
 * 计算树形组件展示区域的高度
 * @param { Number } excludedHeight 需要从浏览器高度中排除掉的高度
 * @returns 
 */
export function getTreeHeight(excludedHeight = 0) {
  return window.document.documentElement.clientHeight - excludedHeight
}