export default {
  'aicc.loading': 'กําลังโหลด',
  'aicc.select': 'โปรดเลือก',
  'aicc.numberOnly': 'เฉพาะตัวเลขเท่านั้น',
  'aicc.unorganized': 'องค์กรไร้องค์กร',
  'aicc.organized': 'องค์กรองค์กร',
  'aicc.columnConfig': 'ปรับแต่งคอลัมน์',
  'aicc.selectAll': 'คอลัมน์ทั้งหมด',
  'aicc.selectedColumns': 'คอลัมน์ที่เลือก',
  'aicc.selectedColumnsTip': '(ลากเพื่อจัดเรียง)',
  'aicc.columnSettings': 'การตั้งค่าหัวตาราง',
  'aicc.selectedInfo': 'เลือก {n} แถบ',
  'aicc.advancedSearch': 'การค้นหาขั้นสูง',
  'aicc.lengthLimit': 'ความยาวต้องไม่เกิน {limit} อักขระ',
  'aicc.ok': 'ตกลง',
  'aicc.error': 'ผิดพลาด',
  'aicc.search': 'ค้นหา',
  'aicc.reset': 'รีเซ็ต',
  'aicc.skip': 'ข้าม',
  'aicc.goto': 'ไปเช็คเอาท์',
  'aicc.more': 'เพิ่มเติม',
  'aicc.intRangeLimit': 'ป้อนจํานวนเต็มตั้งแต่ {min} ถึง {max}',
  'aicc.MaxLength': 'ป้อนสตริงที่ประกอบด้วยอักขระสูงสุด 0 ตัว',
  'aicc.previous': 'ก่อนหน้า',
  'aicc.next': 'ถัดไป',
  'aicc.noData': 'ไม่มีข้อมูลที่ใช้ได้',
  'aicc.lengthMinLimit': 'ต้องมีอักขระอย่างน้อย {0} ตัว',
  'aicc.traceId.error': 'TraceId:0,โปรดระบุผลตอบรับของ TraceId ให้กับผู้ดูแลระบบ',
  'aicc.apibuilder.dev': 'การพัฒนาอินเทอร์เฟซ',
  'aicc.timeout.desc': 'วาระงานหมดอายุ',
  'aicc.timeout.solution': 'การเข้าสู่ระบบของคุณหมดเวลาแล้ว คุณต้องเข้าสู่ระบบอีกครั้ง',
  'aicc.input': 'กรุณากรอก',
}
