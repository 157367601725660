<template>
    <div v-if="currentTab === name" class="tab-content-item">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'aicc-tab-pane',
}
</script>
<script setup>
import { toRefs, inject } from 'vue'
const props = defineProps({
    label: {
        type: String,
        require: true
    },
    name: {
        type: String,
        require: true
    }
})
const { label, name } = toRefs(props)
const currentTab = inject('currentTab')

</script>
<style lang="less" scoped>
.tab-content-item {
    height: 100%;
}
</style>