import request from '@/utils/request'
import { getRestService } from "@/views/ccmessaging/api/online-chat-workbench/utils.js"

// 加入到骚扰名单
export const addBotherSpecial = data => {
  let serviceUrl = getRestService("/cc-management/v1/specialList/bother", null, "/add");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

// 通过objectKey读取文件流
export const agentDownLoadStream = data => {
  return request({
    url: "/service-cloud/rest/ccmessaging/v1/fileservice/agentDownLoadStream",
    method: 'post',
    data
  })
}

// 轮询ccgateway事件接口
export const agentevent = data => {
  return request({
    url: "/service-cloud/rest/cc-management/v1/agenteventhandle/agentevent/" + window?.CCAgent?.agentId,
    method: 'post',
    data
  })
}

// 调用oiap接口，返回静默坐席应答结果
export const agentHelp = data => {
  return request({
    url: "/service-cloud/rest/ccmessaging/v1/silentagent/agentHelp",
    method: 'post',
    data
  })
}

// 调用oiap接口修改静默坐席状态
export const changeSilentWorkMode = data => {
  return request({
    url: "/service-cloud/rest/ccmessaging/v1/silentagent/changeSilentWorkMode",
    method: 'post',
    data
  })
}

// 校验工单类型数据
export const checkCaseTypes = data => {
  return request({
    url: "/service-cloud/rest/cc-management/v1/callreason/checkCaseTypes",
    method: 'post',
    data
  })
}

// 查询是开启WebRTC特性和开启点击通话
export const checkClickToCallSupportVideo = data => {
  let serviceUrl = getRestService("/ccmessaging/v1/clickToCall", null, "/checkClickToCallSupportVideo");
  return request({
    url: serviceUrl,
    method: 'get',
    data
  })
}

// 校验下行邮件是否合法
export const checkDownlinkMessage = data => {
  let serviceUrl = getRestService("/ccmessaging/v1", null, "/emailchannel/checkDownlinkMessage");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

// 座席给离线用户发消息时校验用户状态
export const checkTargetUserStatus = data => {
  if (!data.channelId) {
    return
  }
  let serviceUrl = getRestService("/ccmessaging/v1", null, "/chatmessage/checkTargetUserStatus");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

// 查询本地文件发送权限
export const checkUploadAuth = data => {
  let serviceUrl = getRestService("/ccmessaging/v1/fileservice", null, "/checkUploadAuth");
  return request({
    url: serviceUrl,
    method: 'get',
    data
  })
}

// 创建转移会话session
export const createTransferSession = data => {
  let serviceUrl = getRestService("/ccmessaging/v1/sessionService", null, "/createTransferSession");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

export const uploadVoiceFile = data => {
  return request({
    url: "/service-cloud/u-route/ccmessaging/fileservice/uploadRecordVoice",
    method: 'post',
    data
  });
}

// 生成连接返回
export const createWhatsAppLinks = data => {
  return request({
    url: "/service-cloud/rest/ccmessaging/v1/whatsappVideo/createWhatsAppLinks",
    method: 'post',
    data
  })
}

// 前端会话关闭删除缓存
export const deleteClickCallSession = data => {
  return request({
    url: "/service-cloud/rest/ccmessaging/v1/whatsappVideo/deleteClickCallSession",
    method: 'post',
    data
  })
}

// 删除邮件草稿
export const deleteEmailDraft = data => {
  return request({
    url: "/service-cloud/rest/ccmessaging/v1/emailchannel/deleteEmailDraft",
    method: 'delete',
    data
  })
}

// 删除转移会话session
export const deleteTransferSession = data => {
  let serviceUrl = getRestService("/ccmessaging/v1/sessionService", null, "/deleteTransferSession");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

// 发送下行消息
export const downlinkMessage = data => {
  return request({
    url: "/service-cloud/rest/ccmessaging/v1/emailchannel/downlinkMessage",
    method: 'post',
    data
  })
}

// 查询是否可以结束邮件会话
export const checkAutoCloseEmailSession = data => {
  return request({
    url: "/service-cloud/rest/ccmessaging/v1/emailchannel/checkAutoCloseEmailSession",
    method: 'post',
    data
  })
}

// 通过objectKey读取文件流，座席侧下载自己发送的文档/CCM接触记录使用
export const downloadAgentDocumentFromAgent = data => {
  return request({
    url: "/service-cloud/rest/ccmessaging/v1/fileservice/downloadAgentDocumentFromAgent",
    method: 'post',
    data
  })
}

// 下载邮件附件
export const downloadFileForEmailAttachment = data => {
  return request({
    url: "/service-cloud/u-route/ccnotification/emailattachment/downloadFile",
    method: 'post',
    data
  })
}

// 下载收件箱邮件附件
export const downLoadFileFromFileService = data => {
  return request({
    url: "/service-cloud/u-route/ccmessaging/fileservice/downLoadFile",
    method: 'post',
    data
  })
}

// 查询所有社交媒体配置信息
export const getAuths = data => {
  return request({
    url: "/service-cloud/rest/cc-management/v1/thirdparty/getauths",
    method: 'get',
    data
  })
}

// 获取连接信息
export const getConnectionInfoForAgent = data => {
  return request({
    url: "/service-cloud/rest/ccmessaging/v1/connectionInfo/getConnectionInfoForAgent",
    method: 'post',
    data
  })
}

// 在线工作台查询表情图片配置路径
export const getEmojiPath = data => {
  let serviceUrl = getRestService("/ccmessaging/v1/userInfo", null, "/getEmojiPath");
  return request({
    url: serviceUrl,
    method: 'get',
    data
  })
}

// 根据用户id查询与其相关的访问关系
export const getListByUserId = data => {
  return request({
    url: "/service-cloud/rest/cc-management/v1/accessRelation/getListByUserId",
    method: 'post',
    data
  })
}

// 提供给在线工作台获取配置文件中读取的地图接口的URL
export const getLocationUrl = data => {
  let serviceUrl = getRestService("/ccmessaging/v1/medias", null, "/getLocationUrl");
  return request({
    url: serviceUrl,
    method: 'get',
    data
  })
}

// 获取文件然后以流的方式返回前端servlet类,可以是obs的方式也可以是lss的方式
export const getMultiMediaLibraryFile = data => {
  return request({
    url: "/service-cloud/u-route/ccmessaging/getMultiMediaLibraryFile",
    method: 'get',
    data
  })
}

// 根据渠道ID查询渠道配置
export const getUserInfoBycallId = data => {
  let serviceUrl = getRestService("/ccmessaging/v1/userInfo", null, "/getUserInfoBycallId");
  return request({
    url: serviceUrl,
    method: 'get',
    data
  })
}

// 调用ccma agentevent接口
export const getAgentEvent = data => {
  let serviceUrl = getRestService("/cc-management/v1/agenteventhandle", null, "/agentevent/") + window?.CCAgent?.agentId;
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

// 根据渠道Id查询模板消息
export const getWhatsAppTemplateMsgByChannelId = data => {
  let serviceUrl = getRestService("/ccmessaging/v1/whatsapptemplatemsg", null, "/getWhatsAppTemplateMsgByChannelId");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

// 查询whatsApp的模板消息
export const getWhatsAppTemplateMsgById = data => {
  let serviceUrl = getRestService("/ccmessaging/v1/whatsapptemplatemsg", null, "/getWhatsAppTemplateMsgById");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

// 处理邮件 id:邮件id status:0-已读 1-未读 2-已处理
export const handleEmail = data => {
  return request({
    url: "/service-cloud/rest/ccmessaging/v1/emailchannel/handleEmail",
    method: 'put',
    data
  })
}

// 清除合同缓存
export const invalidateContractCache = data => {
  return request({
    url: "/service-cloud/u-route/ccmessaging/signatureservice/invalidateContractCache",
    method: 'post',
    data
  })
}

// 查询是否展示创建工单按钮
export const isCreateCase = data => {
  return request({
    url: "/service-cloud/rest/cc-management/v1/ccagent/isCreateCase",
    method: 'get',
    data
  })
}

// 修改whatsApp的模板消息
export const modifyWhatsAppTemplateMsgById = data => {
  let serviceUrl = getRestService("/ccmessaging/v1/whatsapptemplatemsg", null, "/modifyWhatsAppTemplateMsgById");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

// 上传附件及本地图片权限是否开启，true开启,false,未开启
export const perms = (tenantSpaceId, userId) => {
  return request({
    url: "/service-cloud/rest/sum/v2/tenantSpaces/" + tenantSpaceId + "/users/" + userId + "/perms",
    method: 'get',
  })
}

// 在线交谈工作台查询个性化信息
export const queryAgentPersonalizationByuserId = data => {
  let serviceUrl = getRestService("/ccmessaging/v1/agentpersonalization", null, "/queryAgentPersonalizationByuserId");
  return request({
    url: serviceUrl,
    method: 'get',
    data
  })
}

// 指派邮件时根据技能队列id查询座席
export const queryAgentsBySkillForEmail = data => {
  let serviceUrl = getRestService("/ccmessaging/v1/emailchannel", null, "/queryAgentsBySkill");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

// 查询所有技能队列
export const queryAgentSkills = data => {
  return request({
    url: "/service-cloud/rest/cc-management/v1/skill/queryAgentSkills",
    method: 'post',
    data
  })
}

// 查询座席通话中状态信息
export const queryAgentStatusInCall = data => {
  return request({
    url: "/service-cloud/rest/cc-management/v1/ccagent/queryAgentStatusInCall",
    method: 'post',
    data
  })
}

// 查询会话消息
export const queryAllChatMessage = data => {
  let serviceUrl = getRestService("/ccmessaging/v1/chatmessage", null, "/queryAllChatMessage");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

// 查询离线消息
export const queryAsyncMessage = data => {
  let serviceUrl = getRestService("/ccmessaging/v1/async", null, "/queryAsyncMessage");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

// 根据callId查询会话消息
export const queryChatMsgByCallId = data => {
  let serviceUrl = getRestService("/ccmessaging/v1/chatmessage", null, "/queryChatMsgByCallId");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

// 更新会话中座席昵称
export const updateAgentNickName = data => {
  let serviceUrl = getRestService("/ccmessaging/v1/sessionService", null, "/updateAgentNickName");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

// 批量查询所有常用语类别
export const queryAllChatPhraseBatch = data => {
  return request({
    url: "/service-cloud/rest/ccmessaging/v1/chatphrase/queryAllChatPhraseBatch",
    method: 'post',
    data
  })
}

// 在线交谈工作台查询个性化信息
export const queryAllChatPhraseType = data => {
  return request({
    url: "/service-cloud/rest/ccmessaging/v1/chatphrasetype/queryAll",
    method: 'post',
    data
  })
}

// 查询邮件草稿
export const queryAllEmailDraft = data => {
  return request({
    url: "/service-cloud/rest/ccmessaging/v1/emailchannel/queryAllEmailDraft",
    method: 'post',
    data
  })
}

// 在线交谈工作台根据匹配的name模糊查询多媒体数据和常用语的全部结果list
export const queryAllMatchingInfo = data => {
  return request({
    url: "/service-cloud/rest/ccmessaging/v1/multiMediaLibrary/queryAllMatchingInfo",
    method: 'post',
    data
  })
}

// 查询所有本地上传支持的文件类型
export const queryAllMediaMimeType = data => {
  let serviceUrl = getRestService("/ccmessaging/v1/mediaMinetype", null, "/queryAllMediaMimeType");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

// 查询所有社交媒体配置信息
export const queryAllSocialMedia = data => {
  let serviceUrl = getRestService("/ccmessaging/v1/socialMedia", null, "/queryAllSocialMedia");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

// 提供给servlet 查询多媒体数据
export const queryById = data => {
  let serviceUrl = "/service-cloud/rest/ccmessaging/v1/multiMediaLibrary/queryById";
  return request({
    url: serviceUrl,
    method: 'get',
    data
  })
}

// 前台人机接口用于判断多媒体消息是否可以发送
export const queryByMediaIdAndConfigId = data => {
  return request({
    url: "/service-cloud/rest/ccmessaging/v1/multiMediaAssociation/queryByMediaIdAndConfigId",
    method: 'post',
    data
  })
}

// 根据类别ID查询多媒体文件信息
export const queryByParentTypeId = data => {
  let isUseCache = true;
  if (data.isUseCache !== undefined) {
    isUseCache = data.isUseCache;
  }

  return request({
    url: "/service-cloud/rest/ccmessaging/v1/multiMediaLibrary/queryByParentTypeId",
    method: 'post',
    data: {...data, isUseCache: isUseCache}
  })
}

// 个性化配置查询个性化信息
export const queryByUserId = data => {
  let serviceUrl = getRestService("/ccmessaging/v1/agentpersonalization", null, "/queryByUserId");
  return request({
    url: serviceUrl,
    method: 'get',
    data
  })
}

// 个性化配置查询个性化信息(需要传参座席工号)
export const queryByInputUserId = data => {
  let serviceUrl = getRestService("/ccmessaging/v1/agentpersonalization", null, "/queryByInputUserId");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

// 工作台查询通话信息
export const queryCallInfoByCallId = data => {
  return request({
    url: "/service-cloud/rest/cc-management/v1/contactextrecord/queryCallInfoByCallId",
    method: 'post',
    data
  })
}


// 查询卡片模板信息列表
export const queryCardInfoList = data => {
  let serviceUrl = getRestService("/ccmessaging/v1/CardInfo", null, "/list");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

// 会话形成，根据渠道ID查询渠道配置，获取回话超时时间
export const queryChannelConfigByid = data => {
  let serviceUrl = getRestService("/ccmessaging/v1/channelconfig", null, "/queryChannelConfigByid");
  return request({
    url: serviceUrl,
    method: 'get',
    data
  })
}

// 查询渠道配置用于发起离线回话
export const queryChannelConfigForOfflineCall = data => {
  let serviceUrl = getRestService("/ccmessaging/v1/channelconfig", null, "/queryChannelConfigForOfflineCall");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

// 查询个性化信息
export const queryChatPhraseTypeByTenantSpaceId = data => {
  let serviceUrl = getRestService("/ccmessaging/v1/chatphrasetype", null, "/queryChatPhraseTypeByTenantSpaceId");
  return request({
    url: serviceUrl,
    method: 'get',
    data
  })
}

// 新增接触记录查询接触信息
export const queryContactInfo = data => {
  let serviceUrl = getRestService("/cc-management/v1/specialList/bother", null, "/queryContactInfo");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

// 查询文档多媒体类型列表
export const queryDocumentMultiMediaLibraryList = data => {
  return request({
    url: "/service-cloud/rest/ccmessaging/v1/multiMediaLibrary/queryDocumentMultiMediaLibraryList",
    method: 'post',
    data
  })
}

// 查询租间下领域
export const queryDomainInfo = data => {
  return request({
    url: "/service-cloud/rest/ccmessaging/v1/silentagent/queryDomainInfo",
    method: 'post',
    data
  })
}

// 查看单个邮件
export const queryEmail = data => {
  return request({
    url: "/service-cloud/rest/ccmessaging/v1/emailchannel/queryEmail",
    method: 'post',
    data
  })
}

// 获取邮件附件支持文件格式及文件大小
export const queryEmailAttachmentInfo = data => {
  return request({
    url: "/service-cloud/u-route/ccnotification/eamilattachment/queryEmailAttachmentInfo",
    method: 'post',
    data
  })
}

// 根据主键查询邮件草稿
export const queryEmailDraftById = data => {
  return request({
    url: "/service-cloud/rest/ccmessaging/v1/emailchannel/queryEmailDraftById",
    method: 'get',
    data
  })
}

// 查询邮件模板
export const queryEmailTemplateById = data => {
  return request({
    url: "/service-cloud/rest/ccmessaging/v1/emailchannel/queryEmailTemplateById",
    method: 'post',
    data
  })
}

// 查询可用弹屏页面
export const queryEnabledPageUrls = data => {
  return request({
    url: "/service-cloud/rest/cc-management/v1/pageurls/queryEnabledPageUrls",
    method: 'post',
    data
  })
}

// 在线交谈工作台-查询历史用户
export const queryHistoricalUsers = data => {
  let serviceUrl = getRestService("/ccmessaging/v1/sessionService", null, "/queryHistoricalUsers");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

// 获取邮件附件支持文件格式及文件大小
export const queryImages = data => {
  return request({
    url: "/service-cloud/rest/ccmessaging/v1/multiMediaLibrary/queryImages",
    method: 'post',
    data
  })
}

// 调用oiap接口获取意图列表
export const queryIntentions = data => {
  return request({
    url: "/service-cloud/rest/ccmessaging/v1/silentagent/queryIntentions",
    method: 'post',
    data
  })
}

// 查询通知模板
export const queryMessageTemplate = data => {
  return request({
    url: "/service-cloud/rest/ccnotification/v1/messageTemplate/queryMessageTemplate",
    method: 'post',
    data
  })
}

// 根据id查模板详情
export const queryMessageTemplateInfoByIdForWeb = data => {
  return request({
    url: "/service-cloud/rest/ccnotification/v1/messageTemplate/queryMessageTemplateInfoByIdForWeb",
    method: 'post',
    data
  })
}

// 查询轮播卡片关联的单卡片集合
export const queryMultiCards = data => {
  return request({
    url: "/service-cloud/rest/ccmessaging/v1/CardInfo/queryMultiCards",
    method: 'post',
    data
  })
}

// 在线交谈工作台根据多媒体类型，limit,offset获取多媒体数据list
export const queryMultiMediaLibraryListByMediaFileType = data => {
  return request({
    url: "/service-cloud/rest/ccmessaging/v1/multiMediaLibrary/queryMultiMediaLibraryListByMediaFileType",
    method: 'post',
    data: { ...data, isUseCache: true }
  })
}

// 前台根据多媒体类型，limit,offset获取多媒体数据list-全渠道通用
export const queryMultiMediaLibraryListByTypeCommon = data => {
  return request({
    url: "/service-cloud/rest/ccmessaging/v1/multiMediaLibrary/queryMultiMediaLibraryListByTypeCommon",
    method: 'post',
    data
  })
}

// 根据sendTime查询下一条消息
export const queryNextMessage = data => {
  let serviceUrl = getRestService("/ccmessaging/v1/chatmessage", null, "/queryNextMessage");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

// 组织结构查询(公共业务组件？)
export const queryOrgList = data => {
  return request({
    url: "/service-cloud/u-route/orgOperation/queryOrgList",
    method: 'post',
    data
  })
}

// 根据组织ID获取组织名称
export const queryorgsbyid = data => {
  return request({
    url: "/service-cloud/u-route/orgs/queryorgsbyid",
    method: 'post',
    data
  })
}

// 随路数据是否展示
export const queryParamIsShowCallData = data => {
  return request({
    url: "/service-cloud/rest/cc-management/v1/sysParameter/queryParamIsShowCallData",
    method: 'post',
    data
  })
}

// 查询当前坐席常用语快捷键
export const queryPhraseUsedShortCut = data => {
  let serviceUrl = getRestService("/ccmessaging/v1/chatphrase", null, "/queryPhraseUsedShortCut");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

// 指派邮件时查询当前vdn下所有技能队列
export const querySkillsForEmail = data => {
  let serviceUrl = getRestService("/ccmessaging/v1/emailchannel", null, "/querySkills");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

// 查询社交媒体列表
export const querySocialMedia = data => {
  let serviceUrl = getRestService("/ccmessaging/v1/channelconfig", null, "/querySocialMedia");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

// 查询所有的新渠道信息
export const querySocialMediaByAccessMode = data => {
  let serviceUrl = getRestService("/ccmessaging/v1/socialMedia", null, "/querySocialMediaByAccessMode");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

// 查询sum特殊名单类型
export const querySpecialListType = data => {
  let serviceUrl = getRestService("/cc-management/v1/specialList", null, "/querySpecialListType");
  return request({
    url: serviceUrl,
    method: 'get',
    data
  })
}

// 查询系统参数接口
export const querySysParamByItemId = data => {
  return request({
    url: "/service-cloud/rest/ccmessaging/v1/sysParameter/querySysParamByItemId",
    method: 'post',
    data
  })
}

// 查询特性信息
export const queryTenantFeatureInfoForUser = data => {
  let serviceUrl = getRestService("/cc-management/v1/tenantInfo", null, "/queryTenantFeatureInfoForUser");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

// 上传附件及本地图片开关是否开启，true开启，false,未开启
export const queryTenantFeaturesForWeb = data => {
  const serviceUrl = getRestService("/cc-management/v1/tenantInfo", null, "/queryTenantFeaturesForWeb");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

// 根据工号list查询座席是否可以转移
export const queryTransferStatusByWorknolist = data => {
  return request({
    url: "/service-cloud/rest/cc-management/v1/ccagent/queryTransferStatusByWorknolist",
    method: 'post',
    data
  })
}

// 在线交谈工作台查询多媒体类别信息接口
export const queryTypeByName = data => {
  return request({
    url: "/service-cloud/rest/ccmessaging/v1/multiMediaLibraryType/queryTypeByName",
    method: 'post',
    data: { ...data, isUseCache: true }
  })
}

// 坐席查询来电原因
export const queryUserCallReason = data => {
  return request({
    url: "/service-cloud/rest/cc-management/v1/callreason/queryUserCallReason",
    method: 'post',
    data
  })
}

// 根据模板id查询使用的变量
export const queryVariableByTemplateId = data => {
  return request({
    url: "/service-cloud/rest/ccnotification/v1/variable/queryVariableByTemplateId",
    method: 'post',
    data
  })
}

// 分页查询whatsapp模板列表
export const queryWhatsAppTemplateList = data => {
  let serviceUrl = getRestService("/ccmessaging/v1/CardInfo", null, "/queryWhatsAppTemplateList");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

// 查询所有whatsapp模板列表
export const queryWhatsAppTemplateWithoutPage = data => {
  let serviceUrl = getRestService("/ccmessaging/v1/CardInfo", null, "/queryWhatsAppTemplateWithoutPage");
  return request({
    url: serviceUrl,
    method: 'get',
    data
  })
}

// 查询工单特性
export const queryWorkOrderByCode = data => {
  let serviceUrl = getRestService("/cc-management", "v1", "/callreason/queryWorkOrderByCode");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

// 离线时，座席侧主动与用户建连
export const routeWorkNoForChannel = data => {
  let serviceUrl = getRestService("/ccmessaging/v1", null, "/routeToAgentService/routeWorkNoForChannel");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

// 离线时，座席侧主动与用户建连-带随路数据
export const routeWorkNoForChannelWithCallData = data => {
  let serviceUrl = getRestService("/ccmessaging/v1", null, "/routeToAgentService/routeWorkNoForChannelWithCallData");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

// 客服个性化信息保存
export const saveAgentPersonalization = data => {
  let serviceUrl = getRestService("/ccmessaging/v1/agentpersonalization", null, "/saveAgentPersonalization");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

// 保存邮件渠道下行消息
export const saveEmailDownlinkmessage = data => {
  return request({
    url: "/service-cloud/rest/ccmessaging/v1/emailchannel/saveEmailDownlinkmessage",
    method: 'post',
    data
  })
}

// 保存邮件草稿
export const saveEmailDraft = data => {
  return request({
    url: "/service-cloud/rest/ccmessaging/v1/emailchannel/saveEmailDraft",
    method: 'post',
    data
  })
}

// 保存静默座席下行消息
export const saveSilentAgentMessage = data => {
  return request({
    url: "/service-cloud/rest/ccmessaging/v1/silentagent/saveSilentAgentMessage",
    method: 'post',
    data
  })
}

// 保存whatsApp的模板消息
export const saveWhatsAppTemplateMsg = data => {
  let serviceUrl = getRestService("/ccmessaging/v1/whatsapptemplatemsg", null, "/saveWhatsAppTemplateMsg");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

// 发送座席消息
export const sendAgentMsg2User = data => {
  return request({
    url: '/service-cloud/rest/ccmessaging/v1/chatmessage/sendAgentMsg2User',
    method: 'post',
    data
  })
}

// 在线交谈工作台拼写检查(纠错)
export const spellCheck = data => {
  return request({
    url: '/service-cloud/rest/ccmessaging/v1/chatfacade/spellCheck',
    method: 'post',
    data
  })
}

// 统计合同中待签名的总数量
export const statisticTotalSigns = data => {
  return request({
    url: "/service-cloud/u-route/ccmessaging/signatureservice/statisticTotalSigns",
    method: 'post',
    data
  })
}

// 文本分类接口
export const textClassification = data => {
  return request({
    url: "/service-cloud/rest/cc-ai/v1/tenantidentification/textClassification",
    method: 'post',
    data
  })
}

// 座席超时重新路由新座席
export const timeOutRouteForChannel = data => {
  let serviceUrl = getRestService("/ccmessaging/v1/routeToAgentService", null, "/timeOutRouteForChannel");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

// 修改受理号码
export const updateContactAcceptNo = data => {
  let serviceUrl = getRestService("/cc-management/v1/contactextrecord", null, "/updateContactAcceptNo");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

// 修改会话原因信息
export const updateContactExtTalkInfo = data => {
  let serviceUrl = getRestService("/cc-management", "v1", "/contactextrecord/updateContactExtTalkInfo");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

// 更新邮件草稿
export const updateEmailDraft = data => {
  return request({
    url: "/service-cloud/rest/ccmessaging/v1/emailchannel/updateEmailDraft",
    method: 'put',
    data
  })
}

// 上传待签名合同
export const uploadContract = data => {
  return request({
    url: "/service-cloud/u-route/ccmessaging/signatureservice/uploadContract",
    method: 'post',
    data
  })
}

// 上传邮件附件
export const uploadFileForEmailAttachment = data => {
  return request({
    url: "/service-cloud/u-route/ccnotification/eamilattachment/uploadFile",
    method: 'post',
    data
  })
}

// 上传邮件附件
export const batchUploadFileForEmailAttachment = data => {
  return request({
    url: "/service-cloud/u-route/ccnotification/eamilattachment/batchUploadFile",
    method: 'post',
    data
  })
}

// 上传文件
export const uploadLocalFile = data => {
  return request({
    url: "/service-cloud/u-route/ccmessaging/localFileService/uploadLocalFile",
    method: 'post',
    data
  })
}

// 校验邮件地址是否符合规则
export const validateEmailAddress = data => {
  return request({
    url: "/service-cloud/rest/ccmessaging/v1/rule/validateEmailAddress",
    method: 'post',
    data
  })
}

// 校验短信号码是否符合规则
export const validateSMSNumber = data => {
  return request({
    url: "/service-cloud/rest/ccmessaging/v1/rule/validateSMSNumber",
    method: 'post',
    data
  })
}

export const queryContactByAcceptNoAndChannelId = data => {
  let serviceUrl = getRestService("/cc-management", "v1", "/contactextrecord/queryContactByAcceptNoAndChannelId");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

// 清除会话redis缓存
export const closeCalloutSession = data => {
  return request({
    url: "/service-cloud/rest/ccmessaging/v1/sessionService/closeCalloutSession",
    method: 'post',
    data
  })
}

export const checkChannelWorkTime = data => {
  let serviceUrl = getRestService("/ccmessaging/v1", null, "/channelconfig/checkChannelWorkTime");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

export const queryUserMessage = data => {
  let serviceUrl = getRestService("/ccmessaging/v1/chatmessage", null, "/queryUserMessage");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

export const cacheAgentMessage = data => {
  let serviceUrl = getRestService("/ccmessaging/v1/chatmessage", null, "/cacheAgentMessage");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

// 质检工作台根据座席工号订阅会话
export const qualityCheckSubscribe = data => {
  let serviceUrl = getRestService("/ccmessaging/v1/agentinfomonitor", null, "/subscribe");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

// 质检工作台根据会话（座席工号、渠道id、用户id）查询消息
export const qualityCheckPollMsg = data => {
  let serviceUrl = getRestService("/ccmessaging/v1/agentinfomonitor", null, "/pollMsg");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

// 质检工作台根据座席工号订阅会话
export const qualityCheckUpdatemonitorstate = data => {
  let serviceUrl = getRestService("/cmsapp/v1/agentinfoqualitycontrol", null, "/updatemonitorstate");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

// 质检工作台插入消息
export const qualityCheckInsertMsg = data => {
  let serviceUrl = getRestService("/ccmessaging/v1/agentinfomonitor", null, "/insertMsg");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

// 质检状态切换
export const qualityCheckChangeStatus = data => {
  let serviceUrl = getRestService("/ccmessaging/v1/agentinfomonitor", null, "/changeQualityStatus");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

// 质检员拦截会话
export const qualityCheckIntercept = data => {
  let serviceUrl = getRestService("/ccmessaging/v1/agentinfomonitor", null, "/intercept");
  return request({
    url: serviceUrl,
    method: 'post',
    data
  })
}

export function refine(data) {
  return request({
    url: '/agent_assistant/refine',
    method: 'post',
    data
  })
}