export default {
    "cms.report.message.avgAnsTimeDesc" : "Duração média da fila de todas as chamadas conectadas em uma fila.",
    "cms.export.message.nocount" : "Não foram encontrados dados que atendam às condições.",
    "cms.reportsubscribe.field.monday" : "Segunda",
    "cms.report.field.ackEndField" : "Hora final da resposta",
    "cms.report.field.inAvgCallTimeField" : "Duração média de chamada",
    "cms.indexmonitor.field.cancel" : "Cancelar",
    "cms.report.message.succWaitAnsRateDesc" : "Duração média da fila de todas as chamadas conectadas em uma fila.",
    "cms.calllink.field.powerOff" : "O telefone está desligado.",
    "cms.fullscreen.message.thresholdLessThan" : "Deve ser maior que o mínimo",
    "cms.indexmonitor.field.agentConnectInThirty" : "Chamadas conectadas manualmente em 30 s",
    "cms.report.message.busyTimeDesc" : "Duração total quando um agente está em estado ocupado.",
    "cms.report.field.call6Rate" : "Taxa de conexão recebida de entrada manual em 30s",
    "cms.calllink.title.callId" : "ID Chamada",
    "cms.indexmonitor.title.selectCcName" : "Selecionar Call Center",
    "cms.report.message.alertingTimeDesc" : "Horário do alerta de chamadas.",
    "cms.callcenterinstanceslist.title.deleteconfim" : "Confirmar exclusão",
    "cms.voiceinspection.video.tolong.info" : "O v��deo atualmente gravado �� muito longo, fa?a o download e reproduza-o",
    "cms.uapmonitor.label.error" : "Erro",
    "cms.report.title.changeTable" : "Alternar para tabela",
    "cms.indexmonitor.message.totalAgentNum" : "Número total de agentes atuais",
    "cms.report.field.billInfo2Field" : "ID exclusivo de chamada dupla",
    "cms.report.field.acwTimeRate" : "Taxa de duração do pós-atendimento",
    "cms.callcenterinstanceslist.field.tenantSpaceName" : "Conta do administrador",
    "cms.recorddrive.field.storageLimit" : "Limite de armazenamento mais baixo (MB)",
    "cms.fullscreen.message.enterMessage" : "Inserir",
    "cms.report.message.callSuccRateDesc" : "Porcentagem do número de solicitações de chamada de entrada manuais conectadas no número de chamadas de entrada manuais que solicitam serviços.",
    "cms.report.message.outAvgCallTimeDesc" : "Duração média da chamada originada entre um agente e clientes.",
    "cms.report.field.call3Rate" : "Taxa de conexão de chamada de entrada manual em 15s",
    "cms.report.field.submediatype.email" : "Email",
    "cms.developerlist.field.description" : "Descrição",
    "cms.callcenterinstanceslist.message.ccNameUpdateError" : "Não altere o nome do call center ao editar o call center",
    "cms.calllink.field.pickup" : "Uma chamada é recebida por outros.",
    "cms.agentMonitor.label.listen_error" : "A escuta falha.",
    "cms.report.message.minAgentInCountDesc" : "Número mínimo de chamadas recebidas simultâneas cujo dispositivo de serviço é um agente.",
    "cms.vcallecenter.title.modifyinfo" : "Modificar senha",
    "cms.datasource.message.oracleUrlTip" : "String de conexão ao banco de dados: &#8220jdbc:oracle:thin:@ip:port:dbservicename&#8220&#10;ip (endereço IP) port (número da porta) dbservicename (nome do banco de dados)",
    "cms.report.message.IVROutSuccNumDesc" : "Número de chamadas de saída IVR conectadas cuja duração de ocupação do dispositivo IVR é maior que 0.",
    "cms.vdnconfiguration.label.configure" : "Configurar",
    "cms.vcallecenter.field.confirmnewpassword" : "Confirmar senha",
    "cms.report.message.inAvgCallTimeDesc" : "Duração média da chamada. Duração média das chamadas de entrada manuais conectadas = Duração das chamadas de entrada manuais conectadas / Número de chamadas de entrada manuais conectadas",
    "cms.indexmonitor.message.agentInboundConnectNum" : "Número de chamadas manuais de entrada que são atendidas pelos agentes",
    "cms.fullscreen.field.oneMin" : "1 Minuto",
    "cms.voiceinspection.field.videoPlay" : "reprodu??o de v��deo",
    "cms.report.field.acwOutTimeField" : "Duração de encerramento da chamada de saída",
    "cms.indexmonitor.message.agentBoundAbandonRate" : "100% – chamadas de entrada manuais conectadas",
    "cms.calllink.field.analysisNotConfig" : "A análise de número chamado não está configurada para uma chamada.",
    "cms.qualitycheck.label.create" : "Criar",
    "cms.monitordetail.field.organization" : "OU",
    "cms.report.field.outOccupyNumField" : "Chamadas de saída",
    "cms.qualitycheck.message.adjustskill" : "Sua fila de habilidades foi temporariamente ajustada para {skillList}. A habilidade original será restaurada depois de iniciar sessão novamente.",
    "cms.report.title.callCenterAccessMonitor" : "Monitorando Estatísticas do Desempenho de VDN por Código de Acesso",
    "cms.datasource.message.deletefailed" : "Falha ao apagar.",
    "cms.sysparam.config.refresh" : "Atualizar",
    "cms.report.message.inWaitAnsNumDesc" : "O Número de chamadas para servi?o manual.",
    "cms.report.field.outCallSuccRate" : "Taxa de conexão de chamada de saída",
    "cms.systemparam.error.validate.notmatchrulerangelength" : "O parâmetro de entrada excede o comprimento máximo ou está abaixo do comprimento mínimo",
    "cms.report.field.agentOutSuccRateField" : "Taxa de conexão de chamada de saída manual",
    "cms.report.field.reportType" : "Tipo de relatório",
    "cms.report.field.waitBeginField" : "Hora de início da espera",
    "cms.systemmonitor.label.noncommercial" : "Uso comercial de ensaio",
    "cms.report.message.IVRCallOutSuccNumDesc" : "Número de chamadas de saída IVR cuja duração da chamada é maior que 0.",
    "cms.indexmonitor.field.totalAgentNum" : "Total de agentes",
    "cms.callcenterinstanceslist.title.success" : "Sucesso",
    "cms.indexmonitor.field.skillAbandonIn5Rate" : "Chamadas abandonadas em 5 s",
    "cms.report.title.agentOperationMonitor" : "Monitoramento dos detalhes da operação de conexão do agente",
    "cms.report.field.acdTimeRate" : "Taxa de Duração de Chamada Manual",
    "cms.report.message.queryErrorInfo" : "Falha na consulta.",
    "cms.qualitycheck.message.saveSuccess" : "Salvar com sucesso.",
    "cms.report.message.avgAgentInCountDesc" : "Número médio de chamadas recebidas simultâneas cujo dispositivo de serviço é um agente.",
    "cms.report.field.manToSkillNumField" : "Transferências de fila manual para habilidade",
    "cms.monitordetail.field.signinskill" : "Fila de login",
    "cms.qcrelationconf.message.queryFailed" : "Falha na consulta",
    "cms.report.field.outCallSuccNumber" : "Chamadas de saída conectadas",
    "cms.callecenter.field.confirmbmspwd" : "Confirmar senha do CCBMS",
    "cms.report.field.avgQueueOutCount" : "Número Médio De Chamadas De Saída Concorrentes Em Uma Fila",
    "cms.datasource.field.remark" : "Descrição do uso",
    "cms.report.field.agentTalkingTimeField" : "Tempo de Conversação do Chamador",
    "cms.agentMonitor.label.insets_success" : "Inserção bem-sucedida.",
    "cms.synchronize.message.incorrectPassword" : "Incorreto",
    "cms.export.message.addExportTaskError" : "Nova tarefa de exportação falhou, verifique o registro",
    "cms.report.message.agentInBoundDesc" : "Número de chamadas de entrada atribuídas a filas de habilidades ou agentes.",
    "cms.developerlist.message.deletesuccess" : "Exclusão realizada com sucesso.",
    "cms.calllink.field.call" : "Chamar",
    "cms.fullscreen.field.fifteenMin" : "15 minutos",
    "cms.report.field.IVROutSuccNumField" : "Chamadas externas de IVR puro conectadas",
    "cms.report.field.succAnsRateField" : "Taxa de conexão de solicitação",
    "cms.qcrelationconf.placeholder.groupType" : "selecione",
    "cms.report.message.alertingDurationDesc" : "Duração(ões) do alerta chamado.",
    "cms.report.field.agentServiceField" : "Chamadas atendidas pelo agente",
    "cms.callcenterinstanceslist.message.updateSuccess" : "O call center foi modificado com sucesso.",
    "cms.report.field.otherTimeRateField" : "Taxa de outra duração",
    "cms.report.message.hangUpModeDesc" : "Hang upmétodo.",
    "cms.indexmonitor.field.skillAgentAbandon" : "Chamadas desligadas por agentes",
    "cms.report.field.outWaitAnsTimeField" : "Duração total do toque de chamadas de saída",
    "cms.report.message.invalidParam" : "Parâmetro inválido.",
    "cms.fullscreen.field.weighted" : "Espessura da fonte",
    "cms.report.message.acwInCountDesc" : "Número de vezes que um agente entra no estado de encerramento após o término das chamadas de entrada.",
    "cms.fullscreen.field.setIndicatorThreshold" : "Definir Limites do Indicador",
    "cms.callcenterinstanceslist.field.ccGatewayIp" : "Endereço IP do CC-Gateway",
    "cms.report.field.leaveReasonField" : "Motivo para sair do dispositivo de chamada",
    "cms.report.message.tooManyExport" : "Muitos {0} exportam em um curto período de tempo.",
    "cms.reportsubscribe.field.thursday" : "Quinta",
    "cms.report.field.agentInSuccRate" : "Taxa de conexão de chamada de entrada manual",
    "cms.fullscreen.field.custom" : "Personalização:",
    "cms.indexmonitor.message.totalAgentCallNumDesc" : "Número total de chamadas feitas por um agente (o período estatístico é o check-in atual)",
    "cms.report.field.outAvgRingTime" : "Duração Média do toque de chamadas originadas manuais",
    "cms.reportsubscribe.field.status" : "Status",
    "cms.report.message.inCallSuccRateDesc" : "Porcentagem de chamadas de entrada conectadas para o total de chamadas de entrada.",
    "cms.indexmonitor.field.totalAnsweringNum" : "Agentes em estado de resposta",
    "cms.calllink.field.intercepts" : "Chamada interceptada.",
    "cms.report.field.outCallTime" : "Duração total da chamada de saída",
    "cms.report.message.voicedataOverError" : "O número de dados exportados excede o máximo de 0. Selecione outra condição de consulta.",
    "cms.report.field.ivrtrafficreport.accesscodlength" : "Insira o AccessCode pelo menos três dígitos.",
    "cms.qualitycheck.label.view" : "Exibir",
    "cms.fullscreen.field.table" : "Tabela",
    "cms.qcrelationconf.field.agentCcRoleName" : "Papel do Partido Inspecionado",
    "cms.report.field.endTime" : "Data de término",
    "cms.indexmonitor.field.callOutIVR" : "Chamadas de saída online do IVR",
    "cms.report.field.outSuccAnsRate" : "Taxa de conexão de chamada de saída",
    "cms.report.message.playVoiceTimeDesc" : "Duração da reprodução de voz.",
    "cms.fullscreen.field.line" : "Gráfico de linhas",
    "cms.recorddrive.label.button_add" : "Novo",
    "cms.agentMonitor.label.forceidle_success_calling" : "Um agente é definido à força para o estado inativo com sucesso. Após o término da chamada, o agente entra no estado inativo.",
    "cms.indexmonitor.title.agentDayTrend" : "Tendência de hoje do agente",
    "cms.report.field.inCallSuccNum" : "Número de chamadas manuais conectadas",
    "cms.systemparam.error.noparamselected" : "Selecione um parâmetro primeiro",
    "cms.callcenterinstanceslist.label.refresh" : "Atualizar",
    "cms.datasource.field.cancel" : "Cancelar",
    "cms.fullscreen.field.fontSize" : "Tamanho",
    "cms.callcenterinstanceslist.label.nextStep" : "Próximo",
    "cms.report.field.outCallNum" : "Chamadas de saída",
    "cms.qcrelationconf.field.delete" : "Excluir",
    "cms.calllink.msg.beginOrEndTimeNull" : "O horário inicial e o horário de término não podem ficar em branco.",
    "cms.indexmonitor.title.agentDayMonitor" : "Estatísticas de hoje sobre indicadores de agentes",
    "cms.report.field.dualCallType" : "Chamada com um clique",
    "cms.report.message.agentInSuccDesc" : "Número de chamadas de entrada manuais conectadas aos agentes, que é o número de chamadas de entrada manuais cuja duração da chamada é maior que 0.",
    "cms.agentMonitor.label.reset" : "Redefinir",
    "cms.report.message.systemInBoundDesc" : "Número total de chamadas de entrada, excluindo chamadas internas de entrada.",
    "cms.fullscreen.message.saveLayouts" : "O layout não foi salvo. Salve primeiro.",
    "cms.indexmonitor.message.agentInboundNum" : "Número de chamadas de entrada alocadas a filas de habilidades ou agentes",
    "cms.agentmonitor.label.occupationState" : "Em Ligação",
    "cms.report.message.locationIdDesc" : "Número do nó distribuído ao qual a chamada pertence",
    "cms.systemmonitor.label.thirtyonedays" : "31 Dias",
    "cms.datasource.message.dmUrlTip" : "String de conexão ao banco de dados: &#8220jdbc:dm://ip:port/dbservicename&#8220&#10;ip (endereço IP) port (número da porta) dbservicename (nome do banco de dados)",
    "cms.agentmonitor.label.restState" : "Pausa",
    "cms.report.field.agentHangNumCti" : "Desligamentos do agente",
    "cms.sysparam.config.checkmessage" : "Contém caracteres especiais (\"<\", \">\", \"/\",, etc.)",
    "cms.report.message.maxQueueInCountDesc" : "Número máximo de chamadas recebidas simultâneas cujo dispositivo de serviço é uma fila de habilidades.",
    "cms.qcrelationconf.message.specialChar" : "Caractere inválido",
    "cms.report.field.onehour" : "1 hora",
    "cms.report.field.acdCallsField" : "Total de chamadas conectadas",
    "cms.report.field.avgAcdTimeField" : "Duração média da chamada manual",
    "cms.qcrelationconf.field.qcAccountName" : "Nome do Inspetor",
    "cms.vdnconfiguration.field.cancel" : "Cancelar",
    "cms.report.field.byWeek" : "Relatório semanal",
    "cms.report.message.avgHandleTimeDesc" : "Duração média de processamento = (duração da chamada manual + duração total de encerramento) / Número de chamadas manuais bem-sucedidas",
    "cms.systemparam.success.reset" : "Redefinir o parâmetro com sucesso",
    "cms.export.message.downloadFailed" : "Falha ao baixar o relatório.",
    "cms.indexmonitor.field.skillQueueDisconnected" : "Colocar chamadas desligadas em fila",
    "cms.agentMonitor.label.forcerelease_success" : "Uma chamada é liberada à força com sucesso.",
    "cms.vcallecenter.field.curcountpassword" : "Senha atual da conta",
    "cms.report.field.otherHangNumCti" : "Desligações do sistema",
    "cms.report.field.startWeek" : "Semana inicial",
    "cms.report.field.locationIdField" : "Número do Nó Distribuído",
    "cms.agentMonitor.label.cancelInsert_error" : "Falha ao cancelar a inserção.",
    "cms.report.field.inCallNumField" : "Chamadas recebidas",
    "cms.reportsubscribe.title.tips" : "Informações",
    "cms.report.field.selectAgent" : "Selecionar agente",
    "cms.report.field.manToSkillNum" : "Transferências de fila manual para habilidade",
    "cms.fullscreen.field.totalCallTime" : "Duração total da chamada",
    "cms.callcenterinstanceslist.message.uapNode1Info" : "Configuração do Nó 1 UAP",
    "cms.voiceinspection.field.textChat" : "Chat de texto",
    "cms.monitordetail.field.holdduration" : "Duração de retenção",
    "cms.report.field.agentName" : "Nome do agente",
    "cms.indexmonitor.field.outboundCallNum" : "chamadas originadas",
    "cms.agentMonitor.label.forcebusy_error" : "Falha ao configurar forçadamente um agente para o estado ocupado.",
    "cms.agentMonitor.label.forcebusy_talking": "O agente está em uma chamada. Por favor, não mostre que está ocupado",
    "cms.agentMonitor.label.forceidle_talking": "O agente está em uma chamada. Por favor, não mostre ocioso",
    "cms.fullscreen.field.idleCountTimeDesc" : "Número de vezes que um agente está no estado ocioso",
    "cms.agentmonitor.label.inspecting" : "Inspeção",
    "cms.monitordetail.field.work" : "Trabalho",
    "cms.indexmonitor.title.vdnDayTrend" : "Tendência de hoje do VDN",
    "cms.calllink.title.deviceDesc" : "Descrição do dispositivo",
    "cms.agentMonitor.label.updateSip_repeat" : "O número foi atribuído.",
    "cms.report.field.week" : "Semana",
    "cms.callcenterinstanceslist.field.backupcc" : "Central de chamadas de backup",
    "cms.report.field.avgAnsTime" : "Duração média da fila de chamadas conectadas",
    "cms.report.field.submediatype.twitter" : "X (Twitter)",
    "cms.report.message.otherCountDesc" : "Número de vezes que um agente entra em outro estado.",
    "cms.indexmonitor.field.duration" : "Duração",
    "cms.report.message.outWaitAnsNumDesc" : "Número de chamadas de saída manuais, incluindo as chamadas de saída iniciadas pelos agentes e aquelas transferidas do IVR para serviços manuais.",
    "cms.fullscreen.field.indexDimension" : "Objeto monitorado",
    "cms.report.field.callLostOtherNumField" : "Outras chamadas perdidas",
    "cms.report.field.ivrtrafficreport.avgivrservice" : "Tempo médio de serviço IVR",
    "cms.report.field.userNoAnswerNum" : "Chamadas perdidas devido ao tempo limite de toque",
    "cms.fullscreen.field.acwCountTimeDesc" : "Número de vezes que um agente está no estado de classificação",
    "cms.report.field.voiceNotification" : "Notificação por voz",
    "cms.fullscreen.field.indexdMode" : "Modo de monitoramento",
    "cms.report.message.stateDesc" : "Estado de conexão, inclui conectado e não conectado.",
    "cms.failurecall.field.search" : "Pesquisar",
    "cms.callcenterinstanceslist.message.passValidateFailed" : "A senha deve conter pelo menos três tipos dos seguintes: letras maiúsculas, minúsculas, dígitos e characters: {'-! @ # $ % ^ & * () -'} especiais?",
    "cms.uapmonitor.labe.eidoverlength" : "O número de segmentos de número de terminais excede o limite superior: 10.",
    "cms.developerlist.message.error" : "Erro",
    "cms.qualitycheck.message.error_errorinfo" : "Falha ao salvar",
    "cms.report.field.outVdnAvgTimeField" : "Duração média da chamada de saída em VDN",
    "cms.callcenterinstanceslist.field.ccStatus" : "Status do Call Center",
    "cms.datasource.field.test" : "Teste a conexão",
    "cms.report.field.deviceNoField" : "Número do dispositivo",
    "cms.monitordetail.field.conferencecount" : "Chamadas de terceiro",
    "cms.report.field.abortQueueWaitNumField" : "Chamadas abandonadas",
    "cms.indexmonitor.message.InCallAgentCount" : "Número de chamadas recebidas atendidas",
    "cms.qcrelationconf.field.groupType" : "Tipo de grupo de usuários",
    "cms.qcrelationconf.field.qualityInspection" : "Verificador da qualidade",
    "cms.report.message.callEndTimeDesc" : "Horário de término da chamada.",
    "cms.indexmonitor.message.skillQueueDisconnectedDesc" : "Número de vezes que as chamadas são desconectadas pela plataforma CTI quando as chamadas estão em fila",
    "cms.indexmonitor.field.totalIdeaNum" : "Agentes em estado disponível",
    "cms.export.field.exportiong" : "Exportando",
    "cms.report.message.skillIdDesc" : "ID da fila de habilidades à qual a chamada pertence",
    "cms.indexmonitor.field.skillAbandonAfter60" : "Chamadas abandonadas após 60 s",
    "cms.callcenterinstanceslist.message.ivrNumsError" : "O número de canais IVR deve ser maior do que a soma do número de canais TTS e do número de canais ASR.",
    "cms.systemparam.error.validate.notmatchrulerequired" : "Preenchimento obrigatório",
    "cms.fullscreen.message.addThemeError" : "O layout não foi salvo. Tem certeza de que deseja adicionar um assunto?",
    "cms.reportsubscribe.field.createNotificationTemplate" : "Criar o modelo de notificação",
    "cms.report.message.minAgentOutCountDesc" : "Número mínimo de chamadas de saída simultâneas cujo dispositivo de serviço é um agente.",
    "cms.indexmonitor.field.skillConnectedIn10Rate" : "Chamadas conectadas em 10 s",
    "cms.indexmonitor.field.skillConnectedIn20Rate" : "Chamadas conectadas em 20 s",
    "cms.report.title.skillAccessReport" : "Relatório de Tráfego de Filas de Conhecimento por Código de Acesso",
    "cms.datasource.field.cmsDatabase" : "Banco de dados de relatórios CMS",
    "cms.report.field.avgIVRCount" : "Número médio de chamadas simultâneas IVR",
    "cms.report.title.abandonRingReport" : "Relatório de abandono de ligações",
    "cms.fullscreen.field.simsun" : "SimSun",
    "cms.agentmonitor.label.qualityInspection" : "Inspeção de qualidade",
    "cms.voiceinspection.message.downloadVoiceRecordFailed" : "Falha ao baixar o arquivo de gravação do servidor SFTP.",
    "cms.report.message.acwRateDesc" : "(Duração total do pós-atendimento / duração total do trabalho) x 100%",
    "cms.report.field.avgAcdTime" : "Duração média de chamada manual",
    "cms.report.field.queryEndTime" : "Tempo final",
    "cms.report.field.inCallSuccNumberField" : "Chamadas de entrada conectadas",
    "cms.report.message.availTimeDesc" : "Duração total quando um agente está em estado disponível.",
    "cms.voiceinspection.field.download" : "Baixar",
    "cms.calllink.msg.callerPlaceHolder" : "Insira o número de chamada",
    "cms.callcenterinstanceslist.field.ccGatewayPort" : "Porta do CC-Gateway",
    "cms.fullscreen.field.saveTheme" : "Salvar layout",
    "cms.systemmonitor.field.systemInSucc" : "Chamadas conectadas ao sistema",
    "cms.voiceinspection.field.colordark" : "Outros",
    "cms.skilladjustment.field.skilltype" : "Tipo de fila de habilidades",
    "cms.report.message.inVdnAvgTimeDesc" : "Duração média da chamada de entrada no VDN. O valor é a duração total da chamada de entrada dividida pelo número de chamadas de entrada.",
    "cms.datasource.label.delete" : "Excluir",
    "cms.report.field.otherTimeRate" : "Taxa de outra duração",
    "cms.indexmonitor.message.agentAbandonIn5Rate" : "Chamadas abandonadas em 5 segundos como porcentagem do total de abandonos",
    "cms.monitordetail.field.noanswercount" : "Chamadas não atendidas por um longo período",
    "cms.fullscreen.message.warningSelectOne" : "Selecione apenas um indicador para a definição de limite.",
    "cms.vcallecenter.field.enterwaspassword" : "Senha",
    "cms.report.message.loginCountDesc" : "Número de vezes de início de sessão.",
    "cms.datasource.message.gaussUrlTip" : "String de conexão com o banco de dados: &#8220jdbc:zenith:@ip:port&#8220&#10;ip (endereço IP) port (número da porta)",
    "cms.callcenterinstanceslist.field.adminCount" : "ID do administrador de CTI",
    "cms.indexmonitor.message.abandonInFive" : "Percentual do número de chamadas abandonadas dentro de 5s no número total de chamadas de abandonadas",
    "cms.fullscreen.field.IVR" : "IVR",
    "cms.report.message.uvidDesc" : "Identificador globalmente exclusivo atribuído pelo CCS a cada usuário que acessa o call center",
    "cms.report.message.IVRServiceRateDesc" : "Percentagem do número de chamadas processadas apenas pela URA no número total de chamadas recebidas. Taxa de sucesso de chamadas de entrada Pure-IVR = Chamadas de entrada de IVR puras bem-sucedidas/ (Chamadas de entrada bem-sucedidas por IVR puro + Número de chamadas atendidas pelo agente) x 100",
    "cms.report.message.userLevelDesc" : "O nível de cliente em que o usuário está no call center",
    "cms.voiceinspection.field.socialChat" : "Multimídia",
    "cms.report.field.outAvgCallTime" : "Duração média da chamada efetuada manual",
    "cms.datasource.message.testfailed" : "Falha na conexão de teste.",
    "cms.report.field.holdCountField" : "Tempos de espera",
    "cms.report.field.outAvgRingTimeField" : "Duração Média do toque de chamadas originadas manuais",
    "cms.indexmonitor.field.skillAbandonAfter10" : "Chamadas abandonadas após 10 s",
    "cms.indexmonitor.field.ivrTotalCallNum" : "Total de chamadas recebidas de IVR",
    "cms.callcenterinstanceslist.field.open" : "Ativar",
    "cms.export.message.exceedNumberLimit" : "Excesso de volume de dados. Restringir o intervalo de tempo de dados ou adicionar critérios de filtro de dados.",
    "cms.report.message.busyCountDesc" : "Número de vezes que um agente entra no estado ocupado.",
    "cms.report.message.acwTimeDesc" : "Duração total quando um agente está em estado de envolvimento.",
    "cms.report.message.timeRangeCheckSaas" : "O intervalo de consulta não pode exceder 5 dias",
    "cms.indexmonitor.message.totalQueueCallNum" : "Número de chamadas enfileirando em filas de habilidades e esperando por serviços de agente",
    "cms.report.message.minAgentCountDesc" : "Número mínimo de chamadas simultâneas cujo dispositivo de serviço é um agente.",
    "cms.qcrelationconf.message.deleteSuccess" : "Exclusão bem-sucedida.",
    "cms.callcenterinstanceslist.message.passValidateContinuityFailed" : "A senha não pode conter três caracteres idênticos consecutivos.",
    "cms.calldetail.message.callNoerror" : "Parâmetro de número de chamada incorreto",
    "cms.datasource.message.passValidateFailed" : "O valor não pode conter \"eval\", \"exec\" e os seguintes caracteres especiais:<=>/\"",
    "cms.fullscreen.message.digitsCheck" : "O valor deve ser um inteiro positivo",
    "cms.report.message.talkingTimeDesc" : "Chamar Tempo de conversação.",
    "cms.sysparam.config.title" : "Parâmetros do sistema",
    "cms.failurecall.field.reset" : "Redefinir",
    "cms.report.field.acdTimeRateField" : "Taxa de duração da chamada manual",
    "cms.report.field.onlyIVRService" : "Chamadas de entrada Pure-IVR bem-sucedidas",
    "cms.callcenterinstanceslist.field.vccId" : "ID do Call Center Virtual",
    "cms.indexmonitor.message.skillTimeoutAgentDesc" : "Número de chamadas transferidas da fila de habilidade original para outros agentes devido ao tempo limite de fila",
    "cms.report.field.ivrtrafficreport.ivrinbound" : "Número de ocupação IVR",
    "cms.agentMonitor.label.forcesignout_error" : "Falha ao fazer sessão como agente.",
    "cms.callcenterinstanceslist.field.uapIp" : "UAP NMU IP",
    "cms.voiceinspection.field.serviceId" : "Tipo de negócio",
    "cms.indexmonitor.message.agentConnectedOut60NDesc" : "Número total de chamadas atendidas com mais de 60 segundos",
    "cms.agentmonitor.label.queueAdjustment" : "Ajustar filas",
    "cms.callcenterinstanceslist.message.kafkaNodeInfo" : "nódulo KAFKA",
    "cms.agentMonitor.label.intercept_error" : "Interceptação falhou. ",
    "cms.indexmonitor.message.skillUserAbandonDesc" : "Número de chamadas abandonadas proativamente pelos usuários durante a fila e toque",
    "cms.report.message.startTimeLessThanEndTime" : "A hora de início não pode ser posterior à hora de término",
    "cms.report.field.acwInCount" : "Tempos de encerramento de chamada recebida",
    "cms.report.title.vdnSystemPerformanceTrend" : "Tendência de desempenho do sistema",
    "cms.calllind.title.deviceDescPGR" : "Descrição do dispositivo (Código de Acesso PGR)",
    "cms.skilladjustment.field.inputskill" : "Insira um nome para a fila de habilidades.",
    "cms.indexmonitor.field.abandonInFive" : "Chamadas abandonadas em 5 s",
    "cms.report.field.IVRCallOutSuccRate" : "Taxa de conexão de chamada de saída IVR",
    "cms.voiceinspection.field.orderbyASC" : "Aumentar",
    "cms.report.field.agentServiceRateField" : "Taxa de sucesso manual no total de chamadas recebidas",
    "cms.systemparam.error.validate.notmatchrulerange" : "O parâmetro de entrada excede o valor máximo ou está abaixo do valor mínimo",
    "cms.report.field.alertingDurationField" : "Duração(ões) do Alerta Chamado",
    "cms.agentMonitor.label.permission_error" : "Você não tem autoridade",
    "cms.recorddrive.label.IPAddress_add" : "Endereço IP",
    "cms.report.message.manTransferOutNumCtiDesc" : "Número de vezes que o agente transfere para o IVR",
    "cms.report.message.currentSkillIdDesc" : "O número da fila de habilidades que está processando a chamada no momento",
    "cms.reportsubscribe.field.prohibit" : "Proibir",
    "cms.voiceinspection.message.playsuccess" : "O anúncio foi reproduzido com sucesso.",
    "cms.report.message.agentOutSuccDesc" : "Número de chamadas de saída manuais conectadas aos usuários, que é o número de chamadas de saída manuais cuja duração da chamada é maior que 0.",
    "cms.report.label.reset" : "Redefinir",
    "cms.report.message.manToIvrNumCtiDesc" : "Número de vezes que o agente suspendeu a transferência para o IVR após a conclusão da chamada",
    "cms.indexmonitor.message.ivrInboundUserAbort" : "Número de chamadas suspensas proativamente por um usuário no IVR",
    "cms.developerlist.field.updateTime" : "Modificado em",
    "cms.report.message.startOrEndTimeNoExist" : "Se selecionar um segmento de tempo para a consulta, a hora de início e a hora de término deverão existir ao mesmo tempo.",
    "cms.fullscreen.field.bar" : "Gráfico de barras",
    "cms.report.field.IVRServiceRateField" : "Taxa de sucesso de chamadas de entrada Pure-IVR",
    "cms.indexmonitor.message.skillAvgAbandonQueueDesc" : "Duração média da fila de todas as chamadas não atendidas em uma fila.",
    "cms.report.field.transferIvrCountField" : "Tempos de transferência manual para IVR",
    "cms.indexmonitor.field.agentInCallCount" : "Número de chamadas de entrada do agente on-line",
    "cms.sysparam.config.select.count" : "Um máximo de 10 parâmetros podem ser selecionados.",
    "cms.calllink.field.calledBusy" : "A parte chamada está ocupada.",
    "cms.report.field.availTimeRate" : "Taxa de ociosidade",
    "cms.report.message.callIdNumDesc" : "Identificador de várias faturas geradas para uma chamada",
    "cms.indexmonitor.field.skillAvgAbandonQueue" : "Duração média da fila de chamadas perdidas",
    "cms.monitordetail.field.information" : "Informações básicas",
    "cms.callcenterinstanceslist.label.save" : "Salvar",
    "cms.report.message.acdTimeDesc" : "Duração total da chamada de um agente, excluindo a duração do chat de texto.",
    "cms.report.field.ivrtrafficreport.calleeno" : "Código de acesso",
    "cms.fullscreen.message.publicTheme" : "(Público)",
    "cms.callcenterinstanceslist.field.disPwdConfirm" : "Confirme a senha da autenticação CCDIS",
    "cms.vcallecenter.message.syncFailed" : "Falha na sincronização",
    "cms.fullscreen.message.selectMessage" : "- Selecionar",
    "cms.synchronize.message.noConfConfPwd" : "A senha não está configurada. Configurar a senha da interface.",
    "cms.uapmonitor.field.callUapIp" : "IP do UAP",
    "cms.indexmonitor.message.skillOverAgentDesc" : "Número de chamadas transferidas da fila de habilidade original para outros agentes devido a excesso",
    "cms.report.message.outInvalidCallDesc" : "Número de chamadas de saída que não podem chegar aos clientes devido a números inválidos ou desligamento.",
    "cms.qualitycheck.label.selectedGroup" : "Grupos de usuários selecionados",
    "cms.report.field.voiceCallType" : "Tipo de chamada de voz",
    "cms.vcallecenter.title.modifybmspassword" : "Modificar senha do BMS",
    "cms.report.field.byDay" : "Relatório diário",
    "cms.fullscreen.field.no" : "Não",
    "cms.systemparam.success.refresh" : "Parâmetros atualizados com sucesso",
    "cms.indexmonitor.field.skillAvgConnectedAck" : "Duração média do toque das chamadas conectadas",
    "cms.systemmonitor.field.vdnName" : "Locatário",
    "cms.report.field.agentLoginNumField" : "Agentes chamados",
    "cms.tenant.message.pwdCheckmsg" : "As duas senhas são diferentes.",
    "cms.indexmonitor.field.skillTimeoutQueue" : "Chamadas de tempo limite para filas",
    "cms.report.title.vdnTrafficPeriodMonitor" : "Monitorização de tráfego VDN baseada em intervalos",
    "cms.indexmonitor.message.durationAvgConnect" : "Duração total da chamada conectada / Número total de chamadas conectadas",
    "cms.fullscreen.field.busyCountTimeDesc" : "Número de vezes que um agente está no estado ocupado",
    "cms.datasource.field.dbUrl" : "Url de Conexão de Banco de Dados",
    "cms.report.message.minQueueCountDesc" : "Número mínimo de chamadas simultâneas cujo dispositivo de serviço é uma fila de habilidades.",
    "cms.report.field.ivrtrafficreport.totalivrservice" : "Tempo total de serviço IVR",
    "cms.voiceinspection.field.video" : "Vídeo",
    "cms.indexmonitor.field.totalAbandonedCallNum" : "Total de chamadas perdidas",
    "cms.systemmonitor.field.time" : "Tempo da Estatística",
    "cms.report.message.holdTimeDesc" : "Duração total quando um agente está em estado de espera de chamada. A duração da chamada de um agente inclui o tempo de espera da chamada.",
    "cms.indexmonitor.field.abandonInThree" : "Chamadas abandonadas em 3 s",
    "cms.reportsubscribe.message.existError" : "o relatório já existe",
    "cms.report.message.avgHoldTimeDesc" : "Duração média de espera da chamada. Duração média de retenção = Duração de retenção / Número de vezes de retenção",
    "cms.report.title.callCenterAccessReport" : "Relatório de tráfego de VDN por Código de Acesso",
    "cms.report.label.search" : "Pesquisar",
    "cms.report.field.maxIVRCountField" : "Número máximo de chamadas IVR simultâneas",
    "cms.report.field.otherCount" : "Tempos de Outros Estados",
    "cms.callcenterinstanceslist.message.passLengthValidateFailed" : "A senha deve conter no mínimo oito caracteres.",
    "cms.report.message.outFailNumDesc" : "Número total de chamadas de saída falhadas.",
    "cms.report.field.calleeNoField" : "Número chamado",
    "cms.indexmonitor.message.agentConnectInThree" : "Percentual do número de chamadas de conectadas manualmente em 3s no número total de chamadas de conectadas manualmente",
    "cms.report.message.outVdnAvgTimeDesc" : "Duração média da chamada de saída no VDN. O valor é a duração total da chamada de saída dividida pelo número de chamadas de saída.",
    "cms.agentmonitor.label.cancelwhisper" : "Cancelar Sussurro",
    "cms.report.field.call3RateField" : "Taxa de conexão recebida de entrada manual em 15s",
    "cms.export.field.download" : "Baixar",
    "cms.callcenterinstanceslist.label.vCallCenter" : "Centro de chamada virtual",
    "cms.report.field.workRate" : "Taxa de duração do trabalho",
    "cms.report.field.endWeek" : "Semana final",
    "cms.indexmonitor.field.totalInCallCount" : "Chamadas recebidas on-line",
    "cms.report.field.skillQueue" : "Fila de habilidades",
    "cms.sendnote.message.checkInput" : "Caracteres inválidos.",
    "cms.indexmonitor.message.skillConnectedRateDesc" : "Porcentagem do número de solicitações de chamada de entrada manuais conectadas no número de solicitações de chamada de entrada manuais",
    "cms.monitordetail.title.statistics" : "Estatísticas",
    "cms.report.field.inAnswerRateField" : "Taxa de conexão de chamada recebida",
    "cms.voiceinspection.field.callerNo" : "Número chamador",
    "cms.callcenterinstanceslist.message.clearUapConfig" : "Limpar informações de configuração do nó UAP",
    "cms.fullscreen.field.fullscreen" : "Tela cheia",
    "cms.record-playback.callType2" : "Chamada de saída IVR",
    "cms.indexmonitor.message.agentAbandonIn60NDesc" : "Número total de abandonos com duração de resposta inferior a 60 segundos inclusive",
    "cms.report.field.ans20sField" : "Taxa de conexão de chamada manual em 20s",
    "cms.calllink.msg.logDateInValid" : "O horário de gravação é inválido.",
    "cms.indexmonitor.message.agentConnectedIn30NDesc" : "Número total de chamadas atendidas com duração inferior a 30 segundos inclusive",
    "cms.calllink.field.cdnDevice" : "Dispositivo CDN",
    "cms.report.message.manToManWaitAnsNumDesc" : "Número de tempos de espera de transferência manual.",
    "cms.systemparam.error.validate.notmatchruledigits" : "Por favor, insira um número inteiro positivo válido",
    "cms.report.field.agentOutSucc" : "Chamadas de saída manuais conectadas",
    "cms.report.field.outAvgAnsTimeField" : "Duração média do toque de chamadas efetuadas",
    "cms.systemmonitor.message.timeerror" : "* A hora de início precisa ser anterior à de término.",
    "cms.report.field.deviceTypeField" : "Tipo de dispositivo",
    "cms.qcrelationconf.title.warning" : "Aviso",
    "cms.indexmonitor.field.totalCallNum" : "Total de chamadas",
    "cms.uapmonitor.labe.mention" : "1. Depois que a função de monitoramento é ativada, os agentes cujo status de registro do terminal é inconsistente com o status de login do agente são desconectados periodicamente,Os agentes que podem ser forçados a fazer check-out estão limitados apenas aos agentes que fizeram check-in apenas na fila de habilidades de voz e vídeo.",
    "cms.report.title.callCenterMonitor" : "Monitoramento de desempenho VDN",
    "cms.calllind.title.deviceDescCDN" : "Descrição do dispositivo (número CDN)",
    "cms.report.message.maxAgentOutCountDesc" : "Número máximo de chamadas simultâneas cujo dispositivo de serviço é um agente.",
    "cms.calllink.field.threePartyCall" : "A chamada é uma chamada de três partes.",
    "cms.voiceinspection.field.rate" : "Velocidade de reprodução",
    "cms.uapmonitor.title.regist" : "Status do Registro do Terminal",
    "cms.tenant.message.adminpasswordagain" : "Confirmar senha",
    "cms.report.message.maxGarpMonth" : "Não é possível consultar dados com mais de 12 meses",
    "cms.report.field.maxQueueCountField" : "Número máximo de chamadas simultâneas em uma fila",
    "cms.indexmonitor.message.totalCallNum" : "Número de chamadas de entrada + Número de chamadas originadas",
    "cms.sysparam.config.select.null" : "Selecione um parâmetro.",
    "cms.report.field.maxIVRCount" : "Número Máximo de Chamadas IVR Simultâneas",
    "cms.indexmonitor.field.skillConnectedIn5" : "Chamadas conectadas em 5 s",
    "cms.monitordetail.field.adjustDuration" : "Duração de Organização",
    "cms.developer.message.success" : "Operação bem-sucedida.",
    "cms.indexmonitor.field.skillConnectedIn3" : "Chamadas conectadas em 3 s",
    "cms.report.message.maxIVRInCountDesc" : "Número máximo de chamadas de entrada simultâneas cujo dispositivo de serviço é o IVR.",
    "cms.systemmonitor.field.uapIP" : "UAP IP",
    "cms.report.field.talkDurationField" : "Conversando Duração(ões)",
    "cms.report.field.awarnessRate" : "Taxa de sucesso recebida de entrada manual",
    "cms.agentMonitor.label.cancelListening_success" : "A escuta foi cancelada com sucesso.",
    "cms.datasource.message.updatesuccess" : "Atualização bem-sucedida.",
    "cms.report.field.agentAlertingDurationField" : "Duração(ões) do Alerta do Chamador",
    "cms.indexmonitor.message.skillAbandonInNRateDesc" : "Percentual do número de solicitações de chamada abandonadas manualmente em Ns no número total de serviços de usuário solicitantes de chamada",
    "cms.report.field.workRateField" : "Taxa de duração do trabalho",
    "cms.report.field.acwTimeRateField" : "Taxa de duração do pós-atendimento",
    "cms.report.title.skillAllReport" : "Sumário de Relatório de Tráfego",
    "cms.indexmonitor.label.callInIVR" : "Número de chamadas de entrada on-line do IVR",
    "cms.qualitycheck.label.editusergroupmember" : "Editar membro do grupo",
    "cms.monitordetail.field.restduration" : "Duração de repouso",
    "cms.agentMonitor.label.qcinspector_error" : "A operação não pode ser executada porque você não é um inspetor.",
    "cms.report.field.avgCallInTime" : "Duração média de chamada",
    "cms.fullscreen.message.numberCheck" : "O valor deve ser 0, um número inteiro positivo, ou um decimal positivo com um máximo de duas casas decimais.",
    "cms.indexmonitor.field.skillConnectedIn30Rate" : "Chamadas conectadas em 30 s",
    "cms.qcrelationconf.field.agentGroupName" : "Equipe inspecionada",
    "cms.indexmonitor.message.skillConnectedInNRate" : "Percentual do número de solicitações de chamada conectadas manualmente em Ns no número total de chamadas que solicitam serviços de usuário",
    "cms.report.message.avgQueueOutCountDesc" : "Número médio de chamadas de saída simultâneas cujo dispositivo de serviço é uma fila de habilidades.",
    "cms.indexmonitor.message.accesscodeCalloutNum" : "Número de chamadas de saída feitas por números de chamada dentro de uma hora",
    "cms.datasource.message.mysql5UrlTip" : "String de conexão ao banco de dados: &#8220jdbc:mysql://ip:port/schema&#8220&#10;ip (endereço IP) port (número da porta) schema (esquema)",
    "cms.report.message.inAvgRingTimeDesc" : "Duração média de toque do terminal de um agente. Duração média do toque de chamadas de entrada manuais = Duração total do toque de chamadas de entrada manuais / Número de resposta",
    "cms.agentMonitor.label.interceptNotice" : "Use este recurso enquanto ouve, barging, ou sussurrando para o agente atual",
    "cms.indexmonitor.field.SkillAbandonRate" : "Solicitações de chamadas perdidas",
    "cms.datasource.label.edit" : "Modificar",
    "cms.indexmonitor.title.skillDayMonitor" : "Estatísticas de hoje sobre indicadores de fila de habilidades",
    "cms.indexmonitor.title.skillQueueStatistics" : "Estatísticas da Fila de Habilidades do Dia",
    "cms.fullscreen.field.nowMonitor" : "Monitoramento em tempo real durante a execução",
    "cms.callcenterinstanceslist.field.ctiIndexMonitor" : "Monitor de Indicadores do Sistema CTI",
    "cms.indexmonitor.label.noncommercial" : "Uso comercial de ensaio",
    "cms.voiceinspection.field.webphoneVideo" : "Clique discar chamada de vídeo",
    "cms.vcallecenter.label.tenantaccount" : "Conta de administrador",
    "cms.systemparam.error.validate.notmatchrulealpha" : "Por favor, insira alfanuméricos ou sublinhados, e apenas comece com letras ou sublinhados",
    "cms.fullscreen.field.hisMonitor" : "Estatísticas históricas dos indicadores",
    "cms.recorddrive.field.configSftpAccount" : "Conta SFTP",
    "cms.agentmonitor.label.agent" : "Agente",
    "cms.qcrelationconf.field.groupInfo" : "Informações Básicas do Grupo de Usuários",
    "cms.voiceinspection.field.calleeNo" : "Número chamado",
    "cms.agentmonitor.label.learnState" : "Aprendizagem",
    "cms.report.field.muteTime" : "Duração total de silêncio",
    "cms.callcenterinstanceslist.field.uapIndexMonitor" : "Monitor de Indicadores do Sistema UAP",
    "cms.callcenterinstanceslist.field.uapPasswordConfirm" : "Confirme a senha do UAP CDE.",
    "cms.indexmonitor.field.skillAvgQueueAck" : "Duração média de espera",
    "cms.callcenter.message.configcallcenter" : "Configure a central de atendimento.",
    "cms.vdnconfiguration.title.error" : "Erro",
    "cms.report.field.startAgentId" : "Iniciar ID do Agente",
    "cms.report.message.talkDurationDesc" : "Duração(ões) de conversação.",
    "cms.indexmonitor.message.queueOutCallCount" : "Número de chamadas de saída em fila em filas de habilidade.(Não contém sessões multimídia)",
    "cms.report.message.custHangNumCtiDesc" : "Número de desligações de clientes.",
    "cms.systemmonitor.label.threehours" : "3 Horas",
    "cms.callcenterinstanceslist.message.nodeIdCheckError" : "Certifique-se de que a ID de informações do nó CTI inserida, o uapId e o nome sejam exclusivos.",
    "cms.indexmonitor.message.skillLostRingDesc" : "Número de chamadas não atendidas após serem alocadas a agentes",
    "cms.report.field.callerNoField" : "Número de Chamada",
    "cms.report.field.maxIVRInCount" : "Número Máximo de Chamadas Recebidas IVR Simultâneas",
    "cms.report.field.maxAgentInCountField" : "Número máximo de chamadas recebidas de agentes simultâneos",
    "cms.calllink.field.leaveReason89" : "Depois que um agente invoca a interface de atendimento, ele não se conecta ao usuário e o usuário desliga.",
    "cms.calllink.field.leaveReason88" : "O usuário desliga depois que o agente se conecta ao usuário.",
    "cms.fullscreen.message.numberFirst" : "O valor máximo é 7680.",
    "cms.report.message.agentServiceDesc" : "Número de chamadas atendidas com sucesso pelos agentes.",
    "cms.report.field.outAnswerRate" : "Taxa de conexão de chamada de saída",
    "cms.report.field.inAvgRingTimeField" : "Duração média do toque de chamadas manuais de entrada",
    "cms.report.field.IVRSuccRate" : "Taxa de conexão de chamada de entrada IVR",
    "cms.skilladjustment.title.tips" : "Informações",
    "cms.indexmonitor.field.all" : "Todos",
    "cms.indexmonitor.field.skillOnlineConnected" : "Número de chamadas atendidas pelos agentes no bloco atual de 5 minutos",
    "cms.agentMonitor.label.qcSipNum" : "Definindo o número de login do usuário atual",
    "cms.report.field.inCallSuccNumField" : "Número de chamadas manuais conectadas",
    "cms.errormessage.inputspecialchar" : "O valor contém caracteres especiais inválidos.",
    "cms.report.field.acwRate" : "Taxa de duração do pós-atendimento",
    "cms.record-playback.voice.playing" : "Reproduzir",
    "cms.record-playback.voice.pause" : "Pausa",
    "cms.reportsubscribe.field.sentTime" : "Hora enviada",
    "cms.report.message.outCallRateDesc" : "Porcentagem do número de chamadas de saída conectadas no número de chamadas de saída.",
    "cms.sysparam.config.itemdesc" : "descrição",
    "cms.callcenterinstanceslist.field.gwPort" : "Porta do CC-Gateway",
    "cms.report.field.holdTime" : "Duração de retenção",
    "cms.report.message.deviceNoDesc" : "Chamar o número do dispositivo de processamento atual",
    "cms.report.message.minIVRCountDesc" : "Número mínimo de chamadas simultâneas cujo dispositivo de serviço é o IVR.",
    "cms.report.title.vdnTrafficPeriodTrend" : "Tendência de tráfego VDN baseada em intervalos",
    "cms.report.field.avgQueueInCountField" : "Número médio de chamadas simultâneas de entrada em uma fila",
    "cms.indexmonitor.label.callOutIVR" : "Número de chamadas de saída on-line do IVR",
    "cms.voiceinspection.title.tips" : "Informações",
    "cms.calllink.field.doNotAnswer" : "O assinante não atende uma chamada.",
    "cms.report.field.startMonth" : "Mês inicial",
    "cms.calllink.field.suspendedAndResumed" : "Uma chamada suspensa é retomada.",
    "cms.calllink.field.leaveReason54" : "A taxa de conclusão da chamada é mais baixa do que o limite.",
    "cms.calllink.field.leaveReason53" : "O participante é removido da conferência.",
    "cms.calllink.msg.parseWaitBeginFaild" : "Falha ao converter a data, parâmetro inválido.",
    "cms.calllink.field.leaveReason52" : "O presidente libera proativamente a conferência, ou a conferência será liberada devido a uma exceção CCS.",
    "cms.calllink.field.leaveReason51" : "Um usuário chama para inserir uma chamada de conferência.",
    "cms.calllink.field.leaveReason50" : "O agente rejeita uma chamada.",
    "cms.report.field.outCallSuccRateField" : "Taxa de conexão de chamada de saída",
    "cms.callcenterinstanceslist.title.chooseFeature" : "Seleção de recurso",
    "cms.report.field.IVROutSuccNum" : "Chamadas de saída Pure-IVR conectadas",
    "cms.monitordetail.field.restoutcount" : "Restos de tempo limite",
    "cms.report.title.skillAllMonitor" : "Monitoramento do resumo da fila de habilidades",
    "cms.agentmonitor.label.offline" : "Off-line",
    "cms.calllink.field.leaveReason48" : "Atendendo a uma chamada da fila principal na fila secundária.",
    "cms.report.message.onlineRateDesc" : "Taxa online = (Duração da chamada manual + Duração total de encerramento + Duração total de outros estados) / (Duração total do trabalho - Duração de repouso) x 100",
    "cms.report.title.skillTrend" : "Tendência de desempenho da fila de habilidades",
    "cms.calllink.field.leaveReason47" : "A chamada está na fila em várias filas.",
    "cms.calllink.field.leaveReason46" : "Nenhum agente pode ser alcançado. Reencaminhar a chamada para o CDN.",
    "cms.indexmonitor.message.inboundAbandonRate" : "A porcentagem do número de chamadas recebidas que não foram conectadas ao número de chamadas recebidas. Se o número de chamadas recebidas for 0, o valor do indicador será 0.",
    "cms.calllink.field.leaveReason45" : "Inserindo PGR da consulta do agente.",
    "cms.calllink.field.leaveReason44" : "Rotear chamadas recebidas para PGR.",
    "cms.reportsubscribe.field.saturday" : "Sábado",
    "cms.calllink.field.leaveReason43" : "O CDN retorna uma rota normal para o PGR.",
    "cms.calllink.field.leaveReason42" : "O timeout da rota CDN e entra no PGR.",
    "cms.calllink.field.leaveReason41" : "A chamada é cancelada pelo agente ou o roteamento falhou durante a consulta.",
    "cms.callcenterinstanceslist.message.alreadyInuse" : "O segmento ID do funcionário foi usado.",
    "cms.sysparam.config.opterate" : "Operação",
    "cms.calllink.field.leaveReason40" : "O tempo de roteamento do CDN atingiu o limite.",
    "cms.report.title.agentOutCallReport" : "Relatório de ligações Outbound de Agentes",
    "cms.report.field.maxQueueWaitTimeField" : "Duração máxima de espera",
    "cms.indexmonitor.message.agentConnectAfterInSixty" : "Percentual do número de chamadas de conectadas manualmente após 60s no número total de chamadas de conectadas manualmente",
    "cms.report.message.logoutCountDesc" : "Número de horas de sessão.",
    "cms.callcenterinstanceslist.title.callcenter" : "Central de atendimento",
    "cms.report.field.busyTimeField" : "Duração(s) total(s) ocupado",
    "cms.calllink.field.leaveReason39" : "O CDN retorna o resultado do roteamento.",
    "cms.calllink.field.leaveReason38" : "Efetuar login no CDN por meio de consulta do agente.",
    "cms.report.field.submediatype.web" : "Web",
    "cms.calllink.field.leaveReason37" : "A chamada é encaminhada para o CDN.",
    "cms.callcenterinstanceslist.message.ccgatewayServerSingledeleteconfim" : "Tem certeza de que deseja excluir o servidor CC-Gateway selecionado?",
    "cms.reportsubscribe.message.deleteConfim" : "Deseja realmente excluir?",
    "cms.calllink.field.leaveReason36" : "Redirecionamento de chamadas em fila.",
    "cms.calllink.field.leaveReason35" : "A chamada de rede é retornada ao call center original e colocada em fila.",
    "cms.calllink.field.leaveReason34" : "Tempo limite/Overflow/ Rede falha de chamada de fila no ocupado.",
    "cms.calllink.field.leaveReason31" : "Tempo limite de encaminhamento de terceiros para fila.",
    "cms.calllink.field.leaveReason30" : "Transferência bem-sucedida para fila de time out.",
    "cms.report.field.holdTimeField" : "Duração de retenção",
    "cms.uapmonitor.field.deregister" : "Tem certeza de que deseja excluir o registro do dispositivo?",
    "cms.report.field.inOccupyNum" : "Solicitações de serviço",
    "cms.fullscreen.message.selectAgent" : "A organização e o agente não podem estar vazios.",
    "cms.qualitycheck.label.editusergroup" : "Editar grupo de usuários",
    "cms.calllink.field.unhold" : "Uma chamada não realizada.",
    "cms.report.message.internalTransferCountDesc" : "Número de tempos de transferência interna.",
    "cms.report.field.createTimeField" : "Hora da criação",
    "cms.indexmonitor.label.reset" : "Redefinir",
    "cms.report.message.ackBeginDesc" : "Hora de início do atendimento de chamadas",
    "cms.voiceinspection.field.operat" : "Operação",
    "cms.calllink.field.leaveReason29" : "Transferência de terceiros para fila.",
    "cms.calllink.field.leaveReason28" : "Transferido com sucesso para a fila.",
    "cms.fullscreen.field.play" : "Reproduzir",
    "cms.calllink.field.unavailableNumber" : "O número não está alocado.",
    "cms.skilladjustment.field.seach" : "Pesquisar",
    "cms.indexmonitor.field.ivrDurationAvgConnect" : "Duração média da chamada IVR",
    "cms.report.field.ringTime" : "Duração total do anel",
    "cms.report.message.outCallTimeDesc" : "Duração total da chamada de saída entre um agente e clientes.",
    "cms.reportsubscribe.field.enable" : "Publicado",
    "cms.report.field.warning" : "Nota:",
    "cms.indexmonitor.field.agentConnectInThree" : "Chamadas conectadas manualmente em 3 s",
    "cms.export.field.viewExportTask" : "Exibição de tarefa de exportação",
    "cms.developerlist.message.refreshfailed" : "Falha ao atualizar a SK.",
    "cms.callcenterinstanceslist.message.updateCtiVersion2" : "Verifique se a migração de dados CTI está concluída. Tenha cuidado ao executar esta operação. (Depois de confirmar a operação, a função de sincronização automática será desativada.)",
    "cms.report.field.workTime" : "Duração total do trabalho",
    "cms.qualitycheck.message.deleteSuccess" : "Excluído com sucesso",
    "cms.indexmonitor.title.callCenterDayTrend" : "Tendência de hoje do Call Center",
    "cms.systemparam.error.refresh" : "Falha ao atualizar os parâmetros",
    "cms.report.title.skillMonitor" : "Monitoramento do desempenho da fila de habilidades",
    "cms.calllink.field.noAnswerTransfer" : "Uma chamada é transferida porque nenhuma resposta é recebida de um agente por muito tempo.",
    "cms.report.field.noAckNum" : "Chamadas não atendidas",
    "cms.qualitycheck.label.edit" : "Editar",
    "cms.fullscreen.field.noanswerCountDesc" : "Número de vezes que um agente não atende uma chamada a tempo depois que uma chamada é alocada a um agente.",
    "cms.report.field.endAgentId" : "ID do agente final",
    "cms.agentMonitor.label.been_inspected" : "O agente atual está sendo inspecionado. Por favor, tente novamente mais tarde.",
    "cms.qcrelationconf.field.role" : "Função",
    "cms.calllind.field.deviceQueue" : "Fila de habilidades",
    "cms.fullscreen.field.delete" : "Excluir",
    "cms.report.field.outCallSuccNum" : "Chamadas manuais efetuadas conectadas",
    "cms.indexmonitor.message.agentAbandonIn60Rate" : "Chamadas abandonadas em 60 segundos como porcentagem do total de abandonos",
    "cms.report.field.endMonth" : "Mês final",
    "cms.report.message.outWaitAnsTimeDesc" : "Duração total do toque das chamadas de saída.",
    "cms.uapmonitor.title.deregis" : "Confirmação de cancelamento de registro",
    "cms.recorddrive.placeholder.deleteRecorddrive" : "Selecione a letra da unidade para excluir",
    "cms.report.field.callNoPlaceholder" : "Chamador ou número chamado",
    "cms.callcenterinstanceslist.field.cmsAuthName" : "conta de autenticação CCDIS",
    "cms.fullscreen.field.holdCountTimeDesc" : "Número de vezes que um agente está no estado de espera",
    "cms.callcenterinstanceslist.message.resourceError" : "As informações de recurso modificadas são menores do que o número real de call centers.",
    "cms.report.field.userBusyFailNumField" : "Chamadas perdidas devido a linhas ocupadas",
    "cms.report.field.IVRInBoundField" : "Chamadas de entrada IVR",
    "cms.report.field.IVRCallOutSuccRateField" : "Taxa de conexão de chamada de saída IVR",
    "cms.systemmonitor.field.busyNums" : "Agentes ocupados",
    "cms.qualitycheck.label.queryQcRelation" : "Relacionamentos de inspeção de consulta",
    "cms.agentmonitor.label.listenedAndInserted" : "Sendo inspecionado",
    "cms.report.field.IVROutSuccRateField" : "Taxa de conexão de chamada de saída Pure-IVR",
    "cms.reportsubscribe.field.sunday" : "Domingo",
    "cms.reportsubscribe.field.tuesday" : "Terça",
    "cms.monitordetail.field.totaltalkingduration" : "Duração total da chamada",
    "cms.agentMonitor.label.switch_error" : "Falha de Handover",
    "cms.agentmonitor.label.cancelsuccess" : "Cancelamento bem-sucedido.",
    "cms.callcenterinstanceslist.message.cleanKafka" : "Limpando Configurações de Nó KAFKA",
    "cms.report.field.otherTime" : "Duração total de outros estados",
    "cms.report.message.timeRangeCheck" : "O intervalo de consulta não pode exceder 1 dia",
    "cms.report.field.month" : "Mês",
    "cms.datasource.message.deletesuccess" : "Excluir bem-sucedido.",
    "cms.report.field.busyTimeRate" : "Taxa de duração de ocupado",
    "cms.report.field.systemInSuccField" : "Chamadas recebidas bem-sucedidas",
    "cms.fullscreen.message.addChart" : "Adicionar um gráfico.",
    "cms.report.field.maxAgentCountField" : "Número máximo de chamadas simultâneas do agente",
    "cms.report.message.userBusyFailNumDesc" : "Número de chamadas que não são atendidas devido a linhas ocupadas.",
    "cms.indexmonitor.message.agentOutboundNum" : "Número de chamadas manuais de saída de",
    "cms.calllink.title.mediaType" : "Tipo de mídia",
    "cms.report.field.auxCount" : "Tempos de repouso",
    "cms.report.title.calldetailoriginal" : "Detalhes do Conteúdo da Chamada",
    "cms.export.field.addExportTask" : "Nova tarefa de exportação",
    "cms.report.field.userNoAnswerNumField" : "Chamadas perdidas devido ao tempo limite do toque",
    "cms.developer.message.modifyDeveloper" : "Atualizar informações do desenvolvedor",
    "cms.datasource.field.yes" : "Sim",
    "cms.report.field.failAvgTime" : "Duração média do toque das chamadas perdidas",
    "cms.monitordetail.field.detail" : "Detalhes do agente",
    "cms.voiceinspection.field.batchDownload" : "Baixar lote",
    "cms.report.message.acdCallDesc" : "Número de chamadas de entrada e saída conectadas.",
    "cms.report.field.otherTimeField" : "Duração total de outros estados",
    "cms.voiceinspection.field.sortField" : "Campo de classificação",
    "cms.monitordetail.field.media" : "Multimídia",
    "cms.indexmonitor.message.outboundConnectNum" : "Número de chamadas originadas manuais conectadas + Número de chamadas originadas IVR conectadas",
    "cms.report.message.internalCallCountDesc" : "Número de chamadas internas.",
    "cms.calllink.field.businessRepresentative" : "Agente",
    "cms.report.field.netEntIdField" : "ID do elemento de rede do servidor de mídia",
    "cms.fullscreen.field.add" : "Criar",
    "cms.callcenter.message.selectcallcent" : "Selecione um call center.",
    "cms.indexmonitor.message.agentConnectIn60Rate" : "Conectividade manual em 60 segundos como porcentagem do total de chamadas",
    "cms.indexmonitor.field.totalQueueCallNum" : "Total de chamadas de fila",
    "cms.report.field.acwTimeField" : "Duração total de encerramento",
    "cms.report.message.avgAgentCountDesc" : "Número médio de chamadas simultâneas cujo dispositivo de serviço é um agente.",
    "cms.vcallecenter.label.resetpwd" : "Redefinindo a Senha de uma Conta Administrador",
    "cms.agentmonitor.label.listening" : "Ouça",
    "cms.qcrelationconf.field.reset" : "Redefinir",
    "cms.fullscreen.message.addVdnMost" : "Um máximo de 10 Tenants podem ser selecionados.",
    "cms.fullscreen.field.themeTypeY" : "Temas públicos",
    "cms.voiceinspection.message.selectVoice" : "Selecione uma gravação.",
    "cms.report.field.inCallRate" : "Duração média de chamada",
    "cms.indexmonitor.message.errorOrgInfo" : "Selecione uma organização primeiro.",
    "cms.fullscreen.field.themeTypeN" : "Temas particulares",
    "cms.report.field.inCallSuccRate" : "Taxa de conexão de chamada recebida",
    "cms.indexmonitor.field.accesscodeCalloutNum" : "Número de chamadas enviadas",
    "cms.uapmonitor.field.callIpSize" : "Tipo de IP",
    "cms.fullscreen.field.avgCallTime" : "Duração média da chamada",
    "cms.fullscreen.field.halfHour" : "30 minutos",
    "cms.report.field.querySkill" : "Fila de habilidades",
    "cms.callcenter.message.deletesuccess" : "Excluído com sucesso",
    "cms.agentMonitor.label.forceidle_repeat" : "O agente está ocioso, por favor, não o faça novamente",
    "cms.fullscreen.field.normalBold" : "Normal",
    "cms.report.field.avgIVRCountField" : "Número médio de chamadas IVR simultâneas",
    "cms.failurecall.field.accessCode" : "Código de acesso",
    "cms.vcallecenter.message.currentacountpasderror" : "A senha da conta atual está incorreta.",
    "cms.report.message.monthLessThan" : "O mês do ano final não pode ser anterior ao mês do ano inicial.",
    "cms.indexmonitor.field.agentBoundAbandonRate" : "Chamadas de entrada manuais abandonadas",
    "cms.synchronize.message.syncStatusThree" : "Falha na sincronização",
    "cms.report.field.logoutCount" : "Horários de sessão",
    "cms.report.field.playVoiceCount" : "Tempos de reprodução de voz",
    "cms.report.field.preDeviceTypeField" : "Último tipo de dispositivo",
    "cms.report.message.occupancyRateDesc" : "Taxa de duração do trabalho = (duração total do trabalho - Duração de repouso) / Duração total do trabalho x 100",
    "cms.vdnconfiguration.title.vdnConfigure" : "Configurar o Call Center onde o VDN é executado",
    "cms.indexmonitor.message.abandonInThirty" : "Percentual do número de chamadas abandonadas dentro de 30s no número total de chamadas de abandonadas",
    "cms.report.label.header" : "Definir cabeçalho da tabela",
    "cms.qualitycheck.label.viewusergroup" : "Visualizar UserGroup",
    "cms.report.field.obsServiceIdField" : "ID da atividade de chamada de saída",
    "cms.fullscreen.field.preview" : "Visualização",
    "cms.datasource.field.passwordConfirm" : "Confirmar senha",
    "cms.systemmonitor.message.timelarge" : "* O intervalo de tempo não pode ser superior a 31 dias.",
    "cms.report.message.agentServiceRateDesc" : "Porcentagem do número de chamadas atendidas pelo agente no número total de chamadas de entrada. Taxa de sucesso manual no total de chamadas recebidas = Número de chamadas atendidas do agente / (Número de chamadas recebidas IVR bem-sucedidas + Número de chamadas atendidas pelo agente) x 100",
    "cms.fullscreen.field.movedown" : "Baixar",
    "cms.report.message.acwTimeRateDesc" : "(Duração total do pós-atendimento / duração total do trabalho) x 100%",
    "cms.fullscreen.field.color" : "Cor",
    "cms.callcenterinstanceslist.field.nodemodesingle" : "Modo único",
    "cms.indexmonitor.field.durationAvgConnect" : "Duração média de chamada",
    "cms.calllink.field.threePartyHelp" : "A chamada é usada para solicitar ajuda de terceiros.",
    "cms.callcenterinstanceslist.message.ccIdUpdateError" : "Não altere a ID do call center ao editar o call center",
    "cms.report.field.maxQueueCount" : "Número Máximo de Chamadas Concorrentes em uma Fila",
    "cms.agentmonitor.label.autoRefresh" : "Atualização automática",
    "cms.report.field.IVRCallOutNum" : "Chamadas de saída IVR",
    "cms.report.field.thirtyminutes" : "30 minutos",
    "cms.report.field.agentAlertingTimeField" : "Horário de alerta do chamador",
    "cms.agentMonitor.label.warning" : "Aviso",
    "cms.record-playback.callType1" : "Chamada de voz de saída",
    "cms.log.message.deletedeveloper" : "Exclua o desenvolvedor cujo ID é {0}",
    "cms.report.field.acwTime" : "Duração total de encerramento",
    "cms.indexmonitor.field.skillAbandonIn60Rate" : "Chamadas abandonadas em 60 s",
    "cms.recorddrive.label.button_complete" : "Concluído",
    "cms.callcenterinstanceslist.message.syncPassValidateFailed" : "O valor deve conter letras maiúsculas, letras minúsculas, dígitos e caracteres especiais `~!@#$%^&*()-_=+|[{}];: ', <. > /? Quaisquer três de",
    "cms.report.field.callerHangUp" : "Chamador desligar",
    "cms.report.message.ans20sDesc" : "Porcentagem do número de chamadas conectadas manualmente em 20s no número total de chamadas conectadas manualmente.",
    "cms.agentmonitor.label.switch" : "comutação",
    "cms.agentmonitor.label.operation" : "Operação",
    "cms.report.message.IVROutCallNumDesc" : "Número de chamadas de saída feitas apenas pelo IVR.",
    "cms.indexmonitor.message.durationAvgVdnInbound" : "Duração total que as chamadas de entrada ocupam o VDN / Número total de chamadas de entrada",
    "cms.tenant.message.adminpassword" : "Senha",
    "cms.export.message.addExportTaskSuccess" : "Nova tarefa de exportação bem-sucedida",
    "cms.uapmonitor.field.socketUninstall" : "desinstalação",
    "cms.fullscreen.message.numberSecond" : "O valor máximo é 4320.",
    "cms.report.field.internalCallCount" : "Chamadas internas",
    "cms.report.title.saveSuccess" : "Sucesso",
    "cms.uapmonitor.field.callSocket" : "Status do soquete",
    "cms.qcrelationconf.message.addSuccess" : "Adicionado com sucesso",
    "cms.sysparam.config.item.catalog" : "Tipo de Parâmetro",
    "cms.indexmonitor.message.skillOnlineConnectedDesc" : "Número de chamadas atendidas pelos agentes no bloco atual de 5 minutos. blocos de 5 minutos funcionam de 00:00 a 00:05, 00:05 a 00:10, e assim por diante.",
    "cms.callcenterinstanceslist.message.passWordLengthValidateFailed" : "A senha deve conter 8 a 20 caracteres.",
    "cms.systemmonitor.title.error" : "Erro",
    "cms.report.message.avgQueueCountDesc" : "Número médio de chamadas simultâneas cujo dispositivo de serviço é uma fila de habilidades.",
    "cms.report.field.skillName" : "Nome da fila de habilidades",
    "cms.agentMonitor.laber.notInTalking" : "O agente não está em uma chamada. Atualize o status e tente novamente",
    "cms.indexmonitor.field.currentSelectedSkill" : "Fila de habilidades selecionada:",
    "cms.fullscreen.field.moveup" : "Mover para cima",
    "cms.systemmonitor.message.addVdnMost" : "Um máximo de 100 Tenants podem ser selecionados.",
    "cms.voiceinspection.field.beginTime" : "Hora de início da gravação",
    "cms.uapmonitor.message.eidvalidate" : "Insira os parâmetros conforme solicitado.",
    "cms.indexmonitor.message.totalIdeaNum" : "Número de agentes que estão em estado disponível e podem processar chamadas imediatamente",
    "cms.export.field.zippwd" : "Senha do arquivo",
    "cms.monitordetail.field.mediatype" : "Tipo de mídia de chamada",
    "cms.report.message.currentDateCheck" : "A hora selecionada deve ser anterior ao dia atual.",
    "cms.report.field.playVoiceTimeField" : "Duração de reprodução de voz",
    "cms.callcenterinstanceslist.field.single" : "Modo único",
    "cms.report.field.occupancyRate" : "Taxa de duração do trabalho",
    "cms.qcrelationconf.message.isDeleteGroupInfo" : "Confirme se deseja excluir o grupo de usuários",
    "cms.indexmonitor.field.durationAvgWait" : "Duração média da espera de chamadas recebidas",
    "cms.indexmonitor.field.skillConnectedIn60" : "Chamadas conectadas em 60 s",
    "cms.indexmonitor.message.totalTalkingNum" : "Número de agentes que estão processando chamadas",
    "cms.indexmonitor.field.skillSystemLost" : "Interrupções da plataforma",
    "cms.report.title.skillAllTrend" : "Tendência do resumo da fila de habilidades",
    "cms.report.field.avgAgentCount" : "Número Médio de Chamadas Concorrentes de Agentes",
    "cms.callcenterinstanceslist.message.updateFailed" : "Falha ao modificar o call center.",
    "cms.report.message.abortQueueWaitNumDesc" : "Número de chamadas abandonadas proativamente pelos assinantes durante a fila e o toque.",
    "cms.monitordetail.field.avgTalkingDuration" : "Duração média de chamada",
    "cms.callcenterinstanceslist.message.maxNumber" : "Um máximo de 10 call centers podem ser adicionados.",
    "cms.qcrelationconf.field.qcGroup" : "Grupo de verificação de qualidade",
    "cms.agentMonitor.label.switchNotice" : "Use esta função quando o agente atual estiver no estado de escuta ou de barragem.",
    "cms.calldetail.msg.timeTooLate" : "A hora selecionada não pode ser posterior a {0}.",
    "cms.agentmonitor.label.showAlarmDetail" : "Ver detalhes do alarme",
    "cms.report.field.outCallTimeField" : "Duração total da chamada de saída",
    "cms.mediaType9" : "Quadro branco eletrônico",
    "cms.mediaType8" : "Chamada de vídeo IP (H.323)",
    "cms.mediaType7" : "Chamada de fax",
    "cms.mediaType6" : "Chamada por e-mail",
    "cms.mediaType5" : "Chamada de voz comum",
    "cms.agentMonitor.label.queryNum" : "Definir o número de sessão",
    "cms.agentMonitor.label.queryNumSuccess": "Definir o sucesso do número de login",
    "cms.agentMonitor.label.queryNumFailed": "Falha ao definir o número de entrada",
    "cms.report.message.callBeginDesc" : "Hora de início da chamada",
    "cms.mediaType4" : "Chamada de solicitação para retorno de chamada",
    "cms.mediaType3" : "Navegação acompanhada e compartilhamento de formulários",
    "cms.mediaType2" : "Clique para discar",
    "cms.mediaType1" : "Chat de texto",
    "cms.fullscreen.field.SimHei" : "SimHei",
    "cms.failurecall.message.person" : "chamadas canceladas durante a fila.",
    "cms.indexmonitor.message.warningFilterMax" : "É possível selecionar no máximo {0} indicadores.",
    "cms.agentmonitor.label.showAlarmTime" : "Tempo",
    "cms.report.message.outCallSuccNumDesc" : "Número de chamadas manuais de saída conectadas.",
    "cms.voiceinspection.field.predictOutbound" : "Saída preditiva",
    "cms.report.field.minIVRCount" : "Número mínimo de chamadas IVR simultâneas",
    "cms.systemmonitor.field.rateNum" : "Chamadas conectadas ao sistema",
    "cms.systemparam.error.validate.notmatchruleemail" : "Por favor, digite um endereço de e-mail válido",
    "cms.report.message.inCallFailNumDesc" : "Número de solicitações de chamadas perdidas = Número de solicitações de serviço - Número de solicitações de chamada conectadas",
    "cms.report.field.outWaitAnsTime" : "Duração total do toque de chamadas de saída",
    "cms.fullscreen.field.fiveMin" : "5 Minutos",
    "cms.report.field.outFailNum" : "Total de chamadas de saída falhadas",
    "cms.export.title.voiceDownload" : "Inspeção de gravação",
    "cms.uapmonitor.field.callOperator" : "Operação",
    "cms.indexmonitor.field.skillConnectedIn30" : "Chamadas conectadas em 30 s",
    "cms.agentMonitor.label.talking_error" : "O agente inspecionado está em estado de fala.",
    "cms.indexmonitor.field.chooseAll" : "Selecionar tudo",
    "cms.report.message.inCallSuccNumDesc" : "Número de chamadas recebidas conectadas pelo agente.",
    "cms.report.title.ivrOutboundTrend" : "Tendência da chamada de saída IVR",
    "cms.report.field.callTypeField" : "Tipo de chamada",
    "cms.report.message.availTimeRateDesc" : "Porcentagem da duração total de um agente em disponível na duração total do trabalho.",
    "cms.calllink.field.helperRelease" : "Uma chamada é desligada pela parte que envia a solicitação de ajuda interna.",
    "cms.agentmonitor.label.release" : "Forçar liberação",
    "cms.voiceinspection.field.callType" : "Tipo de chamada",
    "cms.indexmonitor.field.ivrInboundFlowOut" : "Chamadas recebidas IVR transferidas",
    "cms.indexmonitor.label.callCenterNode" : "Nó da central de atendimento",
    "cms.agentMonitor.label.forcebusy_success" : "Um agente é definido à força para o estado ocupado com sucesso.",
    "cms.export.field.exportTaskStatus" : "Status",
    "cms.export.field.refresh" : "Atualizar",
    "cms.report.field.inWaitAnsNum" : "Número de chamadas manuais",
    "cms.reportsubscribe.field.subscriptionList" : "Lista de assinaturas",
    "cms.reportsubscribe.field.subscribeContent" : "Assinar conteúdo",
    "cms.export.field.selectFileType" : "Selecionar formato de arquivo de exportação",
    "cms.report.field.subMediaTypeField" : "Tipo de submídia",
    "cms.vcallecenter.title.success" : "Sucesso",
    "cms.report.field.reportDate" : "Data do relatório",
    "cms.report.field.outCallSuccNumField" : "Chamadas manuais efetuadas conectadas",
    "cms.fullscreen.message.screenreSolution" : "A resolução da tela não pode estar vazia.",
    "cms.report.field.filetype" : "Formato de arquivo de exportação",
    "cms.monitordetail.field.role" : "Função do agente",
    "cms.report.field.auxTimeRate" : "Taxa de duração de repouso",
    "cms.qualitycheck.field.groupName" : "Grupo de Usuários",
    "cms.report.title.IVRAccessReport" : "Relatório de tráfego de IVR por Código de Acesso",
    "cms.indexmonitor.field.skillAvgConnected" : "Duração média de chamada",
    "cms.voiceinspection.message.invalidParam" : "Parâmetro de entrada inválido.",
    "cms.indexmonitor.field.skillLostRing" : "Chamadas não atendidas",
    "cms.uapmonitor.field.unregister" : "não registrar",
    "cms.uapmonitor.labe.qcworkno" : "ID do empregado do QC",
    "cms.fullscreen.message.chooseTheme" : "O layout não foi salvo. Tem certeza de que deseja mudar o assunto?",
    "cms.systemmonitor.field.confirm" : "Conf.",
    "cms.calllink.field.cancelQueuingTransfer" : "Uma chamada é transferida quando a fila é cancelada.",
    "cms.report.field.inVdnAvgTime" : "Duração média da chamada recebida em VDN",
    "cms.indexmonitor.field.skillConnectedIn10" : "Chamadas conectadas em 10 s",
    "cms.indexmonitor.field.skillConnectedIn20" : "Chamadas conectadas em 20 s",
    "cms.voiceinspection.field.colorshallow" : "<=10S",
    "cms.report.message.createTimeDesc" : "Horário de criação.",
    "cms.agentMonitor.label.beinrest_error" : "O agente inspecionado está em estado de repouso.",
    "cms.agentmonitor.label.inspectionStatus" : "Status da inspeção",
    "cms.report.field.minAgentCount" : "Número mínimo de chamadas simultâneas do agente",
    "cms.report.field.internalCallCountField" : "Chamadas internas",
    "cms.callcenterinstanceslist.field.developer" : "Desenvolvedor associado",
    "cms.indexmonitor.message.outboundCallNum" : "Número de chamadas originadas manuais + Número de chamadas originadas IVR",
    "cms.callcenter.message.sameletterovermaxnum" : "Um máximo de oito registros podem ser adicionados à mesma letra de unidade.",
    "cms.report.field.minQueueCountField" : "Número mínimo de chamadas simultâneas em uma fila",
    "cms.monitordetail.field.skill" : "Fila de habilidades",
    "cms.fullscreen.message.addVdnLeast" : "Selecione pelo menos um tenant.",
    "cms.fullscreen.field.reportType" : "Tipo de relatório",
    "cms.vcallecenter.message.syncSuccess" : "Os call centers virtuais são sincronizados com sucesso.",
    "cms.indexmonitor.field.inboundCallNum" : "Chamadas recebidas",
    "cms.indexmonitor.title.ivrDayMonitor" : "Estatísticas de hoje sobre indicadores IVR",
    "cms.callcenterinstanceslist.field.ccBmsPort" : "Porta LB CCBMS",
    "cms.uapmonitor.field.callSize" : "Tipo de terminal",
    "cms.reportsubscribe.message.modifyStatus" : "Tem certeza de que deseja alterar o status?",
    "cms.calllink.title.agentId" : "ID do agente conectado",
    "cms.report.message.waitAnsRateDesc" : "Duração média de toque do terminal de um agente. Duração média do toque de chamadas de entrada manuais = Duração total do toque de chamadas de entrada manuais / Número de resposta",
    "cms.report.message.failAnsRateDesc" : "Taxa de perda de solicitação = 100 – Taxa de conexão de chamada",
    "cms.recorddrive.field.configSftpAccountConfirmPwd" : "Confirmar senha do servidor SFTP",
    "cms.fullscreen.field.refreshPeriod" : "Período de atualização",
    "cms.report.title.IVRReport" : "Relatório de dados IVR",
    "cms.qualitycheck.placeholder.userGroupName" : "Insira um grupo de usuários.",
    "cms.agentMonitor.label.switch_success" : "O switchover é bem sucedido",
    "cms.calllink.title.deviceType" : "Tipo de dispositivo",
    "cms.report.field.acdCallField" : "Total de chamadas conectadas",
    "cms.calllink.field.MELCASDevice" : "Dispositivo MELCAS",
    "cms.report.title.agentTrend" : "Tendência do resumo de desempenho do agente",
    "cms.indexmonitor.field.skillAbandon" : "Solicitações de Chamada Perdida",
    "cms.report.field.occupancyRateField" : "Taxa de duração do trabalho",
    "cms.systemmonitor.label.CommercialUse" : "Em uso comercial",
    "cms.callcenterinstanceslist.field.ccBcsIp" : "Endereço CCBCS LB IP",
    "cms.vcallcenter.message.unknowsolution" : "Entre em contato com os engenheiros da Huawei para análise e tratamento de exceções",
    "cms.calllink.title.leaveReason" : "Motivo para deixar o dispositivo",
    "cms.report.title.abandonRingMonitor" : "Resumo de monitoramento de chamadas abandonadas durante o toque",
    "cms.indexmonitor.field.queueOutCallCount" : "Número de chamadas de saída em fila on-line",
    "cms.agentmonitor.label.showAlarmCallId" : "ID de chamada",
    "cms.report.field.IVRInSuccField" : "Chamadas de entrada IVR conectadas",
    "cms.callcenterinstanceslist.field.pool" : "Modo de pool",
    "cms.calllink.msg.limitTooLarge" : "O valor do limite não pode exceder 100.",
    "cms.report.title.vdnSystemPerformanceMonitor" : "Monitoramento do desempenho do sistema",
    "cms.voiceinspection.field.orderbyDESC" : "Baixos",
    "cms.synchronize.message.syncStatusZero" : "A ser sincronizado",
    "cms.qualitycheck.label.delete" : "Excluir",
    "cms.report.field.agentOutBound" : "Chamadas de saída manuais",
    "cms.report.field.succWaitAnsRate" : "Duração média da fila de chamadas conectadas",
    "cms.calllind.field.deviceVnr" : "Dispositivo virtual",
    "cms.recorddrive.label.recorddrivename" : "Nome da letra da unidade",
    "cms.agentmonitor.label.whisper" : "Sussurrando",
    "cms.report.message.muteCountDesc" : "Número de vezes de silêncio.",
    "cms.callcenterinstanceslist.message.createFailed" : "Falha ao criar o call center.",
    "cms.calllink.msg.callIdValid" : "O ID da chamada é inválido.",
    "cms.report.message.totalLIVRServiceDesc" : "Duração total da chamada IVR. Se uma chamada estiver conectada ao IVR por várias vezes, a duração do serviço IVR é a duração total da chamada IVR.",
    "cms.callcenterinstanceslist.message.specialStrValidateFailed" : "O nome do call center não pode conter caracteres especiais.",
    "cms.indexmonitor.message.ivrInboundConnectRate" : "Percentual do número de chamadas de entrada IVR conectadas no número de pedidos de chamada de entrada IVR",
    "cms.indexmonitor.field.totalLogoutNum" : "Agentes de logout",
    "cms.sysparam.config.reset" : "Reiniciar",
    "cms.sysparam.config.itemname" : "Nome do Parâmetro",
    "cms.report.field.startYear" : "Ano inicial",
    "cms.indexmonitor.field.inboundAbandonRate" : "Chamadas de entrada abandonadas",
    "cms.report.field.agentOutBoundField" : "Chamadas de saída manuais",
    "cms.reportsubscribe.field.friday" : "Sexta",
    "cms.report.field.ivrtrafficreport.ivrsuccess" : "Número de sucesso da chamada IVR",
    "cms.reportsubscribe.field.basicInfo" : "Informações básicas",
    "cms.systemmonitor.field.talkingNums" : "Agentes Falantes",
    "cms.voiceinspection.message.playfailure" : "Falha ao reproduzir o anúncio.",
    "cms.agentMonitor.label.updateSip_error" : "O número está escrito incorretamente.",
    "cms.report.field.submediatype.wechat" : "WeChat",
    "cms.report.placeholder.workName" : "Insira um nome de agente.",
    "cms.agentmonitor.label.forceSignOut" : "Forçar fora",
    "cms.reportsubscribe.field.selectedReport" : "Lista de relatórios selecionados",
    "cms.report.field.sysSuccRateField" : "Taxa de sucesso de chamada recebida",
    "cms.qcrelationconf.message.queryFailedLimit" : "O número de registros por página deve ser maior que 0 e menor que 101.",
    "cms.indexmonitor.field.skillOnlineWaitCall" : "Chamadas de fila online",
    "cms.report.field.ivrtrafficreport.ivrsuccrate" : "Taxa de sucesso da chamada IVR",
    "cms.report.message.queryFailed" : "Falha na consulta {0}.",
    "cms.callcenterinstanceslist.field.tenantName" : "Nome do tenant",
    "cms.calllink.title.callTrack" : "Função de pesquisar",
    "cms.callcenterinstanceslist.message.deleteConfirmMessage" : "Tem certeza de que quer excluir a central de atendimento selecionada e a configuração relacionada?",
    "cms.callcenterinstanceslist.message.voiceCallNumsError" : "O número máximo de chamadas de voz simultâneas deve ser maior ou igual à soma do número de assentos de voz e do número de canais IVR.",
    "cms.report.field.queryBeginTime" : "Hora de início",
    "cms.indexmonitor.field.skillConnectedRate" : "Solicitações de chamadas conectadas",
    "cms.report.field.year" : "Ano",
    "cms.calllink.title.callTrace" : "Link de chamada",
    "cms.vcallecenter.title.modifyconfigurationinterfacepassword" : "Alterar senha da interface de configuração",
    "cms.qualitycheck.label.qualityInspectionGroup" : "Grupo de Inspeção de Qualidade",
    "cms.callcenterinstanceslist.message.wasPsdLengthRangeValidateFailed" : "O comprimento da senha deve estar entre 16 e 32",
    "cms.indexmonitor.field.skillRequest" : "Solicitações de serviço",
    "cms.datasource.message.masterSalveRelationExist" : "A fonte de dados já é usada como uma fonte de dados de backup.",
    "cms.report.message.muteTimeDesc" : "Duração do silêncio.",
    "cms.vcallecenter.title.resetpwd" : "Redefinir senha",
    "cms.export.field.operation" : "Operar",
    "cms.report.field.outOccupyNumDesc" : "Número de vezes que um agente conecta chamadas de saída.",
    "cms.agentmonitor.label.signOut" : "Off-line",
    "cms.report.message.threePartyCallCountDesc" : "Número de chamadas de três partes.",
    "cms.report.field.userLevel" : "Nível de usuário",
    "cms.report.message.dataOverError" : "Consulte o tamanho dos dados {0} acima de 10000.",
    "cms.report.field.threePartyCallCount" : "Chamadas de terceiro",
    "cms.report.field.unspecifiedSkillId" : "Não especificado",
    "cms.indexmonitor.message.agentConnectInFive" : "Percentual do número de chamadas de conectadas manualmente em 5s no número total de chamadas de conectadas manualmente",
    "cms.systemmonitor.field.ok" : "OK",
    "cms.report.field.fifteenminutes" : "15 minutos",
    "cms.report.field.submediatype.line" : "LINE",
    "cms.report.field.sysSuccRate" : "Taxa de sucesso de chamada recebida",
    "cms.report.message.IVRShortCallsDesc" : "Número de chamadas cuja duração do IVR é maior que 0 e menor ou igual a 3 segundos.",
    "cms.indexmonitor.field.totalAgentConnectCallNum" : "Número de chamadas do agente",
    "cms.agentmonitor.label.showAlarmEmotionType" : "Tipo Emocional",
    "cms.skilladjustment.field.skillid" : "ID da fila de habilidades",
    "cms.report.field.vdn" : "Centro de chamada virtual",
    "cms.indexmonitor.field.agentOutboundConnectNum" : "chamadas originadas manuais conectadas",
    "cms.report.field.acdCall" : "Total de chamadas conectadas",
    "cms.agentMonitor.label.beinidle_error" : "O agente inspecionado está disponível.",
    "cms.report.field.outWaitAnsNumField" : "Chamadas de saída manuais",
    "cms.report.field.manToManWaitAnsNumField" : "Chamadas manuais para agente atendidas",
    "cms.vcallecenter.message.validatefailed" : "Falha ao verificar a senha.",
    "cms.agentMonitor.label.batchSelectAgent" : "Você pode selecionar apenas um agente.",
    "cms.report.field.avgIVRServiceField" : "Tempo médio de serviço IVR",
    "cms.indexmonitor.message.agentConnectInTen" : "Percentual do número de chamadas de conectadas manualmente em 10s no número total de chamadas de conectadas manualmente",
    "cms.developerlist.field.operate" : "Operação",
    "cms.report.field.selectOrg" : "Selecionar OU",
    "cms.mediaType20" : "Chamada não em tempo real",
    "cms.mediaType53": "Multimídia",
    "cms.callcenterinstanceslist.field.kafkaPort" : "Porto KAFKA",
    "cms.report.message.failQueueWaitRateDesc" : "Duração média do toque de todas as chamadas não atendidas.",
    "cms.agentMonitor.label.search" : "Pesquisar",
    "cms.calllink.field.internalError" : "Erro interno.",
    "cms.report.field.muteCountField" : "Tempos de silêncio",
    "cms.report.field.startTimeField" : "Horário de Início da Chamada",
    "cms.report.message.billInfo2Desc" : "ID exclusivo de chamada dupla",
    "cms.report.message.obsServiceIdDesc" : "ID de identificação da atividade de chamada de saída",
    "cms.report.message.outAvgRingTimeDesc" : "Tempo médio que um agente espera que os clientes atendam chamadas efetuadas.",
    "cms.report.field.userRefusedFailNum" : "Chamadas perdidas devido à rejeição de chamadas",
    "cms.mediaType19" : "Videochamada comum",
    "cms.skilladjustment.message.notMatchAgent" : "O tipo de fila de habilidades ajustado não corresponde ao tipo de agente.",
    "cms.mediaType18" : "Clique p/ discar chamada de vídeo",
    "cms.mediaType17" : "Mídia de mensagem",
    "cms.mediaType16" : "Chamada de saída visualizada",
    "cms.calllink.msg.dateTooLate" : "A data selecionada não pode ser posterior a {0}.",
    "cms.mediaType15" : "chamada originada prevista",
    "cms.mediaType14" : "Chamada OPS",
    "cms.mediaType13" : "Chamada de vídeo ISDN 6B+D",
    "cms.callcenter.field.reset" : "Redefinir",
    "cms.mediaType12" : "2B+D ISDN videochamada",
    "cms.mediaType11" : "Transferência de arquivos",
    "cms.indexmonitor.field.confirm" : "confirmar",
    "cms.mediaType10" : "Compartilhamento de aplicação",
    "cms.voiceinspection.message.errorTime" : "A consulta de data não pode cruzar meses.",
    "cms.report.message.avgIVRCountDesc" : "Número médio de chamadas simultâneas cujo dispositivo de serviço é o IVR.",
    "cms.report.field.failAvgTimeField" : "Duração média do toque das chamadas perdidas",
    "cms.indexmonitor.field.ivrCallNum" : "Solicitações de Entrada de IVR",
    "cms.qcrelationconf.field.ok" : "OK",
    "cms.indexmonitor.message.callOutSuccNum" : "Número de conexões de chamada de saída do agente",
    "cms.indexmonitor.message.skillAvgQueueAckDesc" : "Duração média de espera de chamadas, ou seja, duração média de espera (fila e duração de toque) de todas as chamadas.",
    "cms.fullscreen.field.fiveSec" : "5 Segundos",
    "cms.voiceinspection.field.nonRealTimeCall" : "Chamada não em tempo real",
    "cms.report.field.callAbandonedRing" : "Número de perdas de chamadas recebidas manuais",
    "cms.monitordetail.field.busyCount" : "O agente entra no estado ocupado",
    "cms.monitordetail.field.duration" : "Estatísticas sobre duração",
    "cms.report.message.perAgentNumDesc" : "Número de chamadas alocadas aos agentes no segmento de tempo selecionado.",
    "cms.report.message.callAbandonedInRingDesc" : "Número de chamadas abandonadas pelo chamador enquanto toca sem receber resposta.",
    "cms.skilladjustment.field.selectskill" : "Selecionar uma fila",
    "cms.report.message.calleeNoLength" : "Insira o AccessCode pelo menos três dígitos.",
    "cms.qualitycheck.label.configQcInformation" : "Informações de inspeção",
    "cms.indexmonitor.field.outboundConnectNum" : "chamadas originadas conectadas",
    "cms.callcenterinstanceslist.label.callCenterRecordDrive" : "Carta da unidade de gravação",
    "cms.uapmonitor.field.socketNormal" : "normal",
    "cms.report.message.maxQueueWaitTimeDesc" : "Duração máxima de espera (enfileiramento e duração do toque) de todas as chamadas.",
    "cms.report.message.call4RateDesc" : "Porcentagem do número de solicitações de chamada conectadas manualmente em 20 segundos no número total de chamadas que solicitam serviços manuais.",
    "cms.monitordetail.field.outboundcount" : "Chamadas de saída bem-sucedidas",
    "cms.report.title.skillReport" : "Relatório de Tráfego de Filas de Conhecimento",
    "cms.developer.message.failure" : "Falha na operação.",
    "cms.developer.message.addDeveloper" : "Adicionar informações do desenvolvedor",
    "cms.vdnconfiguration.message.updatesuccess" : "Atualizado com sucesso.",
    "cms.systemparam.error.validate.notmatchruleurl" : "Por favor, insira um endereço URL válido",
    "cms.report.field.callAbandonedInRingField" : "Chamadas abandonadas durante o toque",
    "cms.report.message.auxTimeRateDesc" : "Taxa de duração de repouso = (duração total de repouso / duração total do trabalho) x 100",
    "cms.systemparam.error.notmatchrule" : "O parâmetro não atende à regra de verificação",
    "cms.qcrelationconf.field.configAdmin" : "Administrador de configuração",
    "cms.voiceinspection.field.currentSkillId" : "Fila de habilidades",
    "cms.report.field.outVdnAvgTime" : "Duração média da chamada de saída em VDN",
    "cms.report.field.maxQueueWaitTime" : "Duração máxima de espera",
    "cms.report.field.callIdField" : "Ligue para o número de série",
    "cms.callcenterinstanceslist.title.callCenter" : "Central de atendimento",
    "cms.monitordetail.field.calling" : "Chamando",
    "cms.report.field.minAgentOutCount" : "Número Mínimo de Chamadas de Saída de Agentes Concorrentes",
    "cms.indexmonitor.title.skillNowMonitor" : "Estatísticas de monitoramento em tempo real na fila de habilidades durante a execução",
    "cms.report.field.IVROutSuccRate" : "Taxa de conexão de chamada de saída Pure-IVR",
    "cms.calllink.field.overflowing" : "Uma chamada é transferida devido a transbordo.",
    "cms.report.message.minQueueInCountDesc" : "Número mínimo de chamadas recebidas simultâneas cujo dispositivo de serviço é uma fila de habilidades.",
    "cms.report.field.accessNo" : "Código de acesso:",
    "cms.report.message.agentHangNumCtiDesc" : "Número de desligações de agente",
    "cms.agentmonitor.label.showAlarmContent" : "Conteúdo de Conversação",
    "cms.calllink.field.skillQueue" : "Fila de habilidades",
    "cms.report.field.otherCountField" : "Tempos de Outros Estados",
    "cms.title.select" : "Selecionar",
    "cms.callcenterinstanceslist.message.datasourceInfo" : "Conclua a configuração da fonte de dados.",
    "cms.export.message.downloadSucceed" : "O relatório foi baixado com sucesso.",
    "cms.report.message.overNumReason" : "Agentes multimídia e multifuncionais podem lidar com várias chamadas ao mesmo tempo",
    "cms.sysparam.config.value" : "Parâmetro Valor",
    "cms.qcrelationconf.field.deleteGroupMember" : "Apagar",
    "cms.developerlist.label.create" : "Adicionar",
    "cms.monitordetail.field.adjustCount" : "O agente entra no estado de organização",
    "cms.indexmonitor.field.skillMinQueueAck" : "Duração mínima de espera",
    "cms.report.field.minAgentCountField" : "Número mínimo de chamadas simultâneas de agentes",
    "cms.report.message.validateFailed" : "Falha ao verificar a exportação do relatório.",
    "cms.indexmonitor.field.skillOnlineInbondCall" : "Número de chamadas on-line",
    "cms.indexmonitor.message.agentConnectedIn60NDesc" : "Número total de chamadas atendidas com duração inferior a 60 segundos inclusive",
    "cms.indexmonitor.field.agentConnectAfterInSixty" : "Chamadas conectadas manualmente depois de 60 s",
    "cms.report.message.avgPerHNumDesc" : "Número médio de chamadas bem-sucedidas por hora no segmento de tempo útil selecionado.",
    "cms.recorddrive.placeholder.singledeleteconfim" : "Tem certeza de que deseja excluir a letra de unidade selecionada?",
    "cms.report.message.mediaTypeDesc" : "Tipo de mídia de chamada",
    "cms.report.field.inVdnAvgTimeField" : "Duração média da chamada recebida em VDN",
    "cms.report.message.assistTimeDesc" : "Duração dos pedidos de ajuda interna.",
    "cms.report.field.billInfo1Field" : "Tipo de chamada de voz",
    "cms.sendnote.title.tips" : "Informações",
    "cms.indexmonitor.field.ivrInboundUserAbort" : "Chamadas recebidas IVR liberadas",
    "cms.uapmonitor.labe.eidmention" : "É possível inserir até 10 segmentos numéricos separados por vírgulas. Regras para cada segmento de número:* e % correspondem a 0 ou mais caracteres. Por exemplo, 6666 * e 6666%, todas as strings que começam com 6666 são correspondidas.? Corresponde apenas a 1 caractere arbitrário. Por exemplo, 6666? , corresponde apenas a sequências começando com 6666 e com um comprimento de 5.Os caracteres curinga * e? são suportados. Uma combinação de %, por exemplo, %3? , corresponde a todas as strings cujo penúltimo caractere é 3.Observação: Recomenda-se que o resultado correspondente de cada curinga seja menor que 100. Caso contrário, os serviços normais no lado UAP são afetados.",
    "cms.synchronize.message.noConfAdminPwd" : "A senha não está configurada. Configurar a senha do administrador.",
    "cms.indexmonitor.message.outboundConnectRate" : "Porcentagem do número de chamadas de saída abandonadas no número de chamadas de saída.",
    "cms.indexmonitor.message.outboundAbandonRate": "A porcentagem do número de chamadas de saída que não estão conectadas ao número de chamadas de saída. Se o número de chamadas de saída for 0, o valor do indicador será 0.",
    "cms.report.field.vdnField" : "Centro de chamada virtual",
    "cms.indexmonitor.message.skillMinQueueAckDesc" : "Duração mínima de espera (enfileiramento e duração do toque) de todas as chamadas",
    "cms.uapmonitor.labe.uapconfing" : "Configuração de monitoramento e recuperação do status do terminal",
    "cms.indexmonitor.field.description" : "Descrição",
    "cms.sendnote.field.notecontent" : "Conteúdo",
    "cms.agentmonitor.message.header" : "Falha ao salvar. Selecione pelo menos um cabeçalho de tabela.",
    "cms.report.message.outCallSuccRateDesc" : "Porcentagem de chamadas de saída conectadas para o total de chamadas de saída.",
    "cms.datasource.field.backupDbName" : "Origem de Dados de Backup",
    "cms.report.message.outCallNumDesc" : "Número de chamadas de saída manuais e automáticas no VDN. O valor é a soma das chamadas de saída manuais e das chamadas de saída feitas automaticamente usando o IVR.",
    "cms.voiceinspection.message.errorContent" : "Falha ao carregar o recurso. Verifique a configuração.",
    "cms.fullscreen.field.yaHei" : "YaHei da Microsoft",
    "cms.report.field.avgAgentInCount" : "Número Médio de Chamadas Recebidas de Agentes Concorrentes",
    "cms.report.message.auxTimeDesc" : "Duração total de licença de um agente ou duração quando o agente está em estado de repouso.",
    "cms.report.title.vdnSystemPerformanceReport" : "Relatório de Monitoramento de Desempenho do Sistema",
    "cms.agentmonitor.label.sendNotes" : "Enviar notas",
    "cms.report.message.avgQueueInCountDesc" : "Número médio de chamadas recebidas simultâneas cujo dispositivo de serviço é uma fila de habilidades.",
    "cms.report.field.failQueueWaitRate" : "Duração média do toque das chamadas perdidas",
    "cms.agentmonitor.label.qcstate_error" : "Falha ao cancelar, nenhuma operação de controle de qualidade está sendo executada no agente selecionado",
    "cms.indexmonitor.message.skillConnectedInNDesc" : "Número de solicitações de chamada conectadas manualmente em Ns. Atualmente, os Ns podem ser 3s, 5, 10, 20, 30s ou 60s.",
    "cms.report.title.skillAccessTrend" : "Tendência do desempenho da fila de habilidades por código de acesso",
    "cms.indexmonitor.message.abandonInThree" : "Percentual do número de chamadas abandonadas em 3 segundos no número total de chamadas de abandonadas",
    "cms.report.field.availTime" : "Duração Status Disponível",
    "cms.monitordetail.field.count" : "Estatísticas sobre número de vezes",
    "cms.calllink.title.endTime" : "Hora de término da estatística",
    "cms.report.field.maxIVROutCountField" : "Número máximo de chamadas de saída simultâneas IVR",
    "cms.callcenterinstanceslist.field.nodemodepool" : "modo de coleção de recursos",
    "cms.indexmonitor.message.skillTimeoutQueueDesc" : "Número de chamadas transferidas da fila de habilidades original para outras filas de habilidades devido ao tempo limite da fila",
    "cms.report.message.dateCheckError" : "Somente os dados dos últimos sete dias podem ser consultados.",
    "cms.report.field.acwInTimeField" : "Duração de encerramento da chamada recebida",
    "cms.indexmonitor.field.skillTimeoutAgent" : "Chamadas de tempo limite para agentes",
    "cms.report.message.agentReleaseTimeDesc" : "Horário de Liberação do Chamador.",
    "cms.datasource.message.mysql8UrlTip" : "String de conexão ao banco de dados: &#8220jdbc:mysql://ip:port/schema&#8220&#10;ip (endereço IP) port (número da porta) schema (esquema)",
    "cms.report.field.cancel" : "Cancelar",
    "cms.indexmonitor.message.agentAbandonOut60NDesc" : "Número total de abandonos na resposta superior a 60 segundos",
    "cms.report.message.manToSatisNumCtiDesc" : "Número de transferências de pesquisa manual para satisfação.",
    "cms.report.field.threePartyCallCountField" : "Chamadas de terceiro",
    "cms.reportsubscribe.field.sentReport" : "Relatório enviado",
    "cms.voiceinspection.field.queryBeginTime" : "Hora de início",
    "cms.report.field.callEndField" : "Hora de fim da chamada",
    "cms.qcrelationconf.message.isDeleteGroupMember" : "Confirme se deseja excluir o membro do grupo",
    "cms.indexmonitor.title.updateAgentName" : "atualizar tenant",
    "cms.report.field.endYear" : "Ano final",
    "cms.calllink.field.callType33" : "Chamada de entrada comum",
    "cms.calllink.field.callType32" : "Chamada de portagem recebida",
    "cms.calllink.field.callType31" : "Chamada interna",
    "cms.vcallecenter.message.syncLoading" : "Operação em andamento. Tente novamente mais tarde.",
    "cms.calllink.field.callType30" : "chamada originada comum",
    "cms.report.message.acwInTimeDesc" : "Duração de encerramento após o fim das chamadas de entrada.",
    "cms.report.field.avgPerHNum" : "Média de chamadas com sucesso por hora",
    "cms.voiceinspection.field.orderby" : "Ordem de classificação",
    "cms.report.title.saveError" : "Erro",
    "cms.monitordetail.field.intercallcount" : "Chamadas internas",
    "cms.callcenterinstanceslist.field.ccId" : "ID da central de atendimento",
    "cms.failurecall.field.waitTime" : "Duração em espera",
    "cms.calllink.field.agentRelease" : "O agente desliga uma chamada.",
    "cms.report.field.callEndTimeField" : "Hora de término da chamada",
    "cms.calllink.field.callType29" : "chamada originada manual",
    "cms.qcrelationconf.field.agentAccountName" : "Nome da Parte Inspecionada",
    "cms.calllink.field.callType28" : "Tipo de chamada originada de agente",
    "cms.fullscreen.field.chartName" : "Nome do gráfico",
    "cms.calllink.field.callType27" : "chamada originada PRI",
    "cms.indexmonitor.field.callRate" : "Taxa de chamada",
    "cms.calllink.field.callType26" : "chamada originada IVR",
    "cms.calllink.field.callType25" : "Pedido de retorno de chamada PRI",
    "cms.indexmonitor.message.queryCallCenterError" : "Ocorreu uma exceção ao consultar a central de atendimento selecionada. Selecione outra central de atendimento disponível.",
    "cms.vcallcenter.message.unknowdesc" : "Exceção desconhecida",
    "cms.calllink.field.callType24" : "Chamada de saída PRI visualizada",
    "cms.calllink.field.callType23" : "Chamadas de entrada e saída virtuais PRI",
    "cms.calllink.field.callType22" : "Chamada de saída PRI pré-conectada",
    "cms.calllink.field.callType21" : "Chamada de saída PRI ocupada",
    "cms.calllink.field.callType20" : "Chamada de ajuda de terceiros",
    "cms.developerlist.message.checkInput" : "O valor não pode conter caracteres especiais.",
    "cms.report.field.maxAgentOutCount" : "Número máximo de chamadas efetuadas pelo agente simultâneo",
    "cms.monitordetail.field.restoutduration" : "Duração do Tempo limite de repouso",
    "cms.indexmonitor.message.skillOverQueueDesc" : "Número de chamadas transferidas da fila de habilidades original para outras filas de habilidades devido a excesso",
    "cms.report.message.maxIVROutCountDesc" : "Número máximo de chamadas de saída simultâneas cujo dispositivo de serviço é o IVR.",
    "cms.qcrelationconf.field.group" : "Grupo",
    "cms.calllink.field.callType19" : "Chamada de ajuda de duas partes",
    "cms.systemparam.error.validate.notmatchruleminlength" : "O parâmetro de entrada é menor que o comprimento mínimo",
    "cms.calllink.field.callType18" : "Chamada comercial RBT",
    "cms.calllink.field.callType17" : "Chamada internacional de entrada por portagem",
    "cms.report.field.perAgentNumField" : "Chamadas de agente",
    "cms.calllink.field.callType16" : "Solicitação de retorno de chamada",
    "cms.calllink.field.callType15" : "Chamada de saída visualizada",
    "cms.calllink.field.callType14" : "chamada originada prevista",
    "cms.calllink.field.callType13" : "Chamada de saída pré-conectada",
    "cms.calllink.field.callType12" : "Chamada de saída ocupada",
    "cms.callecenter.field.setbmspwd" : "Senha da interface de CCBMS",
    "cms.calllink.field.callType11" : "chamada originada reservada",
    "cms.report.message.agentAlertingTimeDesc" : "Horário de alerta da chamada.",
    "cms.report.message.vdnDesc" : "Centro de chamada virtual.",
    "cms.calllink.field.callType10" : "chamada originada (agente internacional)",
    "cms.vcallecenter.message.modifyadminpasswordsuccess" : "Modificar a senha com sucesso.",
    "cms.qcrelationconf.field.groupMemberInfo" : "Informações sobre Membro do Grupo de Usuários",
    "cms.uapmonitor.field.socketConnecting" : "conectando",
    "cms.qcrelationconf.field.workNo" : "ID do agente",
    "cms.report.field.minAgentInCount" : "Número Mínimo de Chamadas de Entrada de Agentes Concorrentes",
    "cms.agentmonitor.label.agentId" : "ID do agente",
    "cms.report.field.manToSatisNumCti" : "Transferências de pesquisa manual para satisfação",
    "cms.report.field.inCallNum" : "Chamadas de entrada",
    "cms.agentMonitor.label.error" : "Erro",
    "cms.indexmonitor.message.inboundConnectNum" : "Número de chamadas de entrada manuais conectadas + Número de chamadas de entrada IVR conectadas",
    "cms.datasource.message.addfailed" : "Falha no adicionar.",
    "cms.report.field.userLevelField" : "Nível do cliente",
    "cms.interfaceperflog.message.dvSwitch" : "Habilitar relatórios de dados",
    "cms.report.field.avgHoldTime" : "Duração média de espera",
    "cms.report.field.submediatype.facebook" : "Facebook",
    "cms.vcallecenter.title.tips" : "Informações",
    "cms.indexmonitor.field.agentOutboundNum" : "chamadas originadas manuais",
    "cms.callcenter.message.updatefailed" : "Falha na atualização",
    "cms.agentmonitor.label.agentID" : "ID do agente",
    "cms.report.field.agentService" : "Chamadas atendidas pelo agente",
    "cms.fullscreen.field.skillQueue" : "Fila de habilidades",
    "cms.qualitycheck.label.trafficGroup" : "Grupo de tráfego",
    "cms.sysparam.config.mod" : "editar",
    "cms.indexmonitor.title.vdnDayMonitor" : "Estatísticas de hoje sobre indicadores VDN",
    "cms.report.field.acwInTime" : "Duração de encerramento de chamada recebida",
    "cms.qcrelationconf.field.qcWorkNo" : "ID do Inspetor",
    "cms.indexmonitor.field.totalAwcNum" : "Agentes em estado de envolvimento",
    "cms.systemparam.error.validate.ruleregexinvalid" : "Erro de sintaxe de regra de validação",
    "cms.indexmonitor.field.ivrOutboundNum" : "chamadas originadas IVR conectadas",
    "cms.calllink.field.noResources." : "Nenhum recurso disponível no ACD.",
    "cms.recorddrive.field.configSftpPathError" : "O caminho de armazenamento do arquivo não pode conter './'",
    "cms.systemmonitor.label.customizes" : "Personalização",
    "cms.report.field.uapIdField" : "Número do UAP",
    "cms.report.title.error" : "Erro",
    "cms.callcenterinstanceslist.field.uapUserName" : "Nome de usuário UAP CDE",
    "cms.indexmonitor.message.agentAbandonIn10Rate" : "Chamadas abandonadas em 10 segundos como porcentagem do total de abandonos",
    "cms.report.field.maxIVRInCountField" : "Número máximo de chamadas IVR simultâneas",
    "cms.export.message.passValidateFailed" : "8-12 caracteres, que devem conter letras , caracteres especiais , caracteres especiais excluindo |;&$-<>/\\`!#(){} espaços, caracteres de nova linha e tabulações",
    "cms.indexmonitor.title.success" : "Sucesso",
    "cms.export.message.exception" : "Ocorreu um erro ao gerar a tarefa de exportação. Verifique os registos.",
    "cms.report.field.assistTime" : "Duração da solicitação de ajuda interna",
    "cms.datasource.message.dbNameInput" : "Insira um nome de fonte de dados.",
    "cms.fullscreen.field.minThreshold" : "Limite Mínimo",
    "cms.indexmonitor.message.totalAwcNum" : "Número de agentes em estado de encerramento após o manuseamento de negócios",
    "cms.agentmonitor.label.adjust" : "Pós-atendimento",
    "cms.fullscreen.title.editTheme" : "Modificar assunto",
  "cms.fullscreen.style.dark": "Escuro",
  "cms.fullscreen.style.light": "Cor clara",
  "cms.fullscreen.style.title": "Estilo",
  "cms.agentMonitor.label.insets_error" : "Falha na inserção.",
    "cms.calllind.field.deviceRoute" : "Dispositivo de roteamento temporário",
    "cms.report.field.transferOutCount" : "Tempos de transferência manual de saída",
    "cms.report.field.manToManSuccNum" : "Transferências manual para agente bem-sucedidas",
    "cms.callcenter.field.savesuccess" : "Sucesso",
    "cms.report.field.oneClickDualCall" : "Chamada bidirecional com um clique",
    "cms.fullscreen.title.newTheme" : "Criar assunto",
    "cms.indexmonitor.message.skillConnectedDesc" : "Número de chamadas enfileiradas e atendidas por agentes em uma fila de habilidades ou chamadas transferidas e atendidas por agentes em outras filas de habilidades",
    "cms.fullscreen.field.yes" : "Sim",
    "cms.report.message.callInCauseDesc" : "Motivo da chamada para este call center (para chamadas de voz)",
    "cms.sendnote.title.selectAgents" : "Agente selecionado",
    "cms.report.field.avgQueueCountField" : "Número médio de chamadas simultâneas em uma fila",
    "cms.report.field.auxCountField" : "Tempos de repouso",
    "cms.report.field.IVRServiceRate" : "Taxa de sucesso de chamadas de entrada Pure-IVR",
    "cms.indexmonitor.field.callNum" : "Número de chamadas",
    "cms.indexmonitor.field.realTimeCalls": "chamadas em tempo real",
    "cms.indexmonitor.field.callsOfTheDay": "chamadas do dia",
    "cms.agent.message.modifypasswordhintdetail" : "inserir a senha.",
    "cms.report.field.ivrtrafficreport.onlyivrservice" : "Apenas número de serviço IVR",
    "cms.developerlist.title.success" : "Sucesso",
    "cms.vcallecenter.field.confirmwaspassword" : "Confirmar senha",
    "cms.qcrelationconf.field.addGroupMember" : "Adicionar membro do grupo",
    "cms.indexmonitor.field.skillMaxQueueAck" : "Duração máxima de espera",
    "cms.fullscreen.field.avgCallInTime" : "Duração média das chamadas recebidas manuais",
    "cms.fullscreen.field.screenreSolution" : "Resolução",
    "cms.agentmonitor.label.statusDuration" : "Duração do Status do Agente",
    "cms.voiceinspection.field.ordinaryVoiceCall" : "Voz",
    "cms.reportsubscribe.message.searchSubscribeName" : "Insira o nome da assinatura",
    "cms.fullscreen.message.tipData" : "Os dados atuais são dados virtuais.",
    "cms.callcenterinstanceslist.field.cmsIp" : "Endereço CMS LB IP",
    "cms.systemmonitor.field.ccName" : "Nome da central de atendimento",
    "cms.callcenterinstanceslist.message.odfConfigError" : "As informações de configuração do serviço ODFS estão incorretas.",
    "cms.indexmonitor.message.skillAbandonAfterNDesc" : "Número de solicitações de chamada abandonadas manualmente após Ns. Atualmente, os Ns podem ser 10s ou 60s.",
    "cms.indexmonitor.title.agentNowMonitor" : "Estatísticas de monitoramento em tempo real no agente durante a execução",
    "cms.callcenterinstanceslist.field.uapPassword" : "Senha do usuário UAP CDE",
    "cms.report.message.maxIVRCountDesc" : "Número máximo de chamadas simultâneas cujo dispositivo de serviço é o IVR.",
    "cms.report.message.callIdDesc" : "Número de série da chamada, o identificador exclusivo de uma chamada",
    "cms.agentmonitor.label.busyState" : "Ocupado",
    "cms.report.message.callStartTimeDesc" : "Hora de início da chamada.",
    "cms.systemmonitor.field.incallNums" : "Chamadas recebidas do sistema",
    "cms.indexmonitor.message.skillMaxQueueAckDesc" : "Duração máxima de espera (enfileiramento e duração do toque) de todas as chamadas",
    "cms.report.field.avgAcwTimeField" : "Duração média do pós-atendimento",
    "cms.report.field.agentOfferedField" : "Chamadas recebidas manuais",
    "cms.uapmonitor.label.registererror" : "Falha ao fazer logout",
    "cms.indexmonitor.field.skillAbandonIn60" : "Chamadas abandonadas em 60 s",
    "cms.report.message.onlyIVRServiceDesc" : "Número de chamadas recebidas bem-sucedidas processadas apenas pelo IVR, ou seja, número de chamadas recebidas bem-sucedidas que não são processadas por nenhum agente.",
    "cms.indexmonitor.message.agentConnectIn10Rate" : "Conectividade manual em 10 segundos como porcentagem do total de chamadas",
    "cms.voiceinspection.field.search" : "Consulta",
    "cms.report.message.dategt12weeks" : "O intervalo semanal não pode exceder 12 semanas.",
    "cms.fullscreen.message.deletechart" : "Deseja confirmar a exclusão deste gráfico?",
    "cms.qcrelationconf.field.cancel" : "Cancelar",
    "cms.report.field.agentTalkDurationField" : "Chamador Falando Duração",
    "cms.callcenterinstanceslist.message.uapNodeInfo" : "Configuração de Nó UAP",
    "cms.indexmonitor.field.skillAvgAbandonAck" : "Duração média do toque das chamadas perdidas",
    "cms.qcrelationconf.message.selectAgent" : "Selecione o agente a ser excluído",
  "cms.qcrelationconf.message.selectGroup": "Selecione o grupo de usuários a ser excluído",
    "cms.indexmonitor.label.CommercialUse" : "Em uso comercial",
    "cms.report.field.waitCauseField" : "Motivo da espera",
    "cms.recorddrive.placeholder.sftpIdentityChecktips" : "Digite sua conta atual e senha.",
    "cms.report.field.agentInSuccRateField" : "Taxa de conexão de chamada de entrada manual",
    "cms.indexmonitor.field.skillConnected" : "Solicitações de chamadas conectadas",
    "cms.indexmonitor.field.outboundConnectRate" : "chamadas originadas conectadas",
    "cms.indexmonitor.field.outboundAbandonRate": "Chamadas de saída abandonadas",
    "cms.systemmonitor.field.systemCallRate" : "Taxa de Conclusão de Chamadas do Sistema",
    "cms.report.message.acdTimeRateDesc" : "Taxa de duração da chamada manual = duração da chamada manual / duração total do trabalho x 100",
    "cms.callcenter.field.deletesuccess" : "Exclusão realizada com sucesso.",
    "cms.agentmonitor.label.enterID" : "Insira uma ID do agente",
    "cms.qcrelationconf.field.qcGroupName" : "Grupo de Inspetores",
    "cms.report.message.otherTimeRateDesc" : "Taxa de outra duração = (duração total de outros estados / duração total do trabalho) x 100",
    "cms.qualitycheck.title.saveSuccess" : "Sucesso",
    "cms.agentMonitor.label.selectAgent" : "Selecione um agente.",
    "cms.report.message.ringTimeDesc" : "Duração total de toque de um agente, excluindo a duração da chamada.",
    "cms.fullscreen.message.chartDataError" : "Falha ao carregar o gráfico.",
    "cms.report.field.agentNum" : "ID do agente",
    "cms.report.field.avgHandleTime" : "Duração média de processamento",
    "cms.report.field.manToIvrNumCti" : "Número de vezes que o agente suspendeu a transferência para o IVR",
    "cms.report.message.agentInSuccRateDesc" : "Porcentagem de chamadas de entrada manuais conectadas para o total de chamadas de entrada manuais.",
    "cms.callcenter.message.createfailed" : "Falha na criação.",
    "cms.report.field.agentInBound" : "Chamadas de entrada manuais",
    "cms.developerlist.message.refreshConfirmMessage" : "Tem certeza de que deseja atualizar SK do desenvolvedor?",
    "cms.indexmonitor.field.skillAbandonIn30" : "Chamadas abandonadas em 30 s",
    "cms.report.field.transferIvrCount" : "Tempos de transferência manual para IVR",
    "cms.uapmonitor.labe.eid" : "Segmento de número terminal",
    "cms.systemmonitor.message.queryErrorInfo" : "Falha na consulta.",
    "cms.voiceinspection.field.reset" : "Redefinir",
    "cms.indexmonitor.field.outboundConnectNumDesc" : "cms.report.field.outOccupyNumDesc=Número de vezes que um agente conecta chamadas de saída.",
    "cms.report.message.netEntIdDesc" : "ID do servidor de mídia que gera o registro de fatura atual",
    "cms.tenant.message.tenantspaceOk" : "OK",
    "cms.interfaceperflog.message.modifySuccess" : "Modificação bem-sucedida",
    "cms.fullscreen.field.statisticPeriod" : "Período Estatístico",
    "cms.report.message.queryresult.error" : "O resultado da consulta é anormal.",
    "cms.calllink.msg.numberInvalid" : "Formato de número incorreto",
    "cms.systemmonitor.field.agentOnlineCount" : "Clientes soft registrados",
    "cms.datasource.title.modifyDatasource" : "Atualizar Fonte de Dados",
    "cms.indexmonitor.field.agentConnectInFive" : "Chamadas conectadas manualmente em 5 s",
    "cms.agent.title.modifypassword" : "Alterar senha de login",
    "cms.agentmonitor.label.readjustmentState" : "Ajustando",
    "cms.qualitycheck.label.unselectedGroup" : "Grupos de usuários não selecionados",
    "cms.indexmonitor.message.agentConnectIn3Rate" : "Conectividade manual em 3 segundos como porcentagem do total de chamadas",
    "cms.report.message.assistsCountDesc" : "Número de solicitações de ajuda internas.",
    "cms.report.title.IVRAccessTrend" : "Tendência dos Dados IVR por Código de Acesso",
    "cms.monitordetail.field.answerCount" : "Chamadas atendidas",
    "cms.indexmonitor.field.skillAbandonIn10" : "Chamadas abandonadas em 10 s",
    "cms.indexmonitor.field.skillAbandonIn20" : "Chamadas abandonadas em 20 s",
    "cms.report.field.assistsCount" : "Solicitações internas de ajuda",
    "cms.report.field.acwOutCount" : "Tempos de encerramento de chamadas de saída",
    "cms.systemmonitor.field.selectCcName" : "Selecionar Call Center",
    "cms.indexmonitor.field.skillAbandonIn10Rate" : "Chamadas abandonadas em 10 s",
    "cms.indexmonitor.field.skillAbandonIn20Rate" : "Chamadas abandonadas em 20 s",
    "cms.report.field.avgAgentOutCountField" : "Número médio de chamadas de saída de agentes simultâneos",
    "cms.vcallecenter.message.syncagentfail" : "Falha ao sincronizar o agente.",
    "cms.callcenterinstanceslist.message.videoCallNumsError" : "O número máximo de chamadas de vídeo simultâneas deve ser maior ou igual ao número de assentos de vídeo.",
    "cms.report.field.notConnected" : "Desconectado",
    "cms.voiceinspection.field.callbackRequest" : "Solicitação de retorno de chamada",
    "cms.report.field.auxTimeField" : "Duração total de repouso",
    "cms.report.field.availTimeField" : "Duração disponível",
    "cms.datasource.message.ccDsRelationExist" : "A fonte de dados foi referenciada no call center.",
    "cms.report.field.outAboundRingField" : "Chamadas Saídas Não Atendidas",
    "cms.report.field.exceptionHangUp" : "Exceção Desligar",
    "cms.systemparam.success.save" : "Parâmetros salvos com sucesso",
    "cms.report.message.inCallNumDesc" : "Número total de chamadas recebidas no VDN. O valor é a soma de chamadas de entrada manuais e chamadas de entrada feitas automaticamente usando o IVR.",
    "cms.report.field.IVRInSuccRateField" : "Taxa de conexão de chamada de entrada IVR",
    "cms.callcenterinstanceslist.message.cleanUap" : "Limpando Configurações de Nó UAP",
    "cms.fullscreen.field.dayTrend" : "Tendência de hoje",
    "cms.sendnote.field.cancel" : "Cancelar",
    "cms.report.message.preDeviceNoDesc" : "O identificador do último dispositivo que a chamada passou",
    "cms.indexmonitor.message.totalLoginNumDesc" : "Número de agentes que entraram no call center e trataram apenas chamadas de entrada",
    "cms.report.title.agentOutCallTrend" : "Tendência do resumo de chamadas efetuadas do agente",
    "cms.indexmonitor.field.skillConnectedIn3Rate" : "Chamadas conectadas em 3 s",
    "cms.report.message.outSuccAnsRateDesc" : "Porcentagem do número de chamadas de saída conectadas no número de chamadas de saída.",
    "cms.callcenterinstanceslist.message.adminWorkNoError" : "O número inicial da ID do funcionário administrador deve variar de 55001 a 59999.",
    "cms.report.field.maxAgentInCount" : "Número Máximo de Chamadas Recebidas de Agentes Concorrentes",
    "cms.indexmonitor.message.queueInCallCount" : "Número de chamadas de entrada em fila em filas de habilidade.(Não contém sessões multimídia)",
    "cms.calllind.field.deviceAgent" : "Agente",
    "cms.indexmonitor.field.skillAbandonIn5" : "Chamadas abandonadas em 5 s",
    "cms.callcenterinstanceslist.message.workNoError" : "A ID do funcionário inicial deve estar na faixa de 101 a 50000.",
    "cms.reportsubscribe.field.templateName" : "Nome do Modelo de Email",
    "cms.indexmonitor.field.skillAbandonIn3" : "Chamadas abandonadas em 3 s",
    "cms.report.message.avgAgentOutCountDesc" : "Número médio de chamadas de saída simultâneas cujo dispositivo de serviço é um agente.",
    "cms.report.field.busyCount" : "Períodos ocupados",
    "cms.report.message.avgCallInTimeDesc" : "Duração média da chamada. Duração média das chamadas de entrada manuais conectadas = Duração das chamadas de entrada manuais conectadas / Número de chamadas de entrada manuais conectadas",
    "cms.callcenterinstanceslist.title.warning" : "Aviso",
    "cms.report.message.calleeNoDesc" : "Número chamado.",
    "cms.indexmonitor.field.skillUserAbandon" : "Chamadas abandonadas",
    "cms.indexmonitor.message.agentConnectedOut10NDesc" : "Número total de chamadas atendidas com mais de 10 segundos",
    "cms.fullscreen.message.maxThemeNum" : "Um máximo de 20 sujeitos podem ser criados.",
    "cms.indexmonitor.field.agentConnectInTen" : "Chamadas conectadas manualmente em 10 s",
    "cms.report.field.avgPerHNumField" : "Média de chamadas com sucesso por hora",
    "cms.report.field.statInterval" : "Período de Tempo",
    "cms.indexmonitor.message.agentBoundConnectRate" : "Percentagem do número de chamadas de conectadas manualmente no número de chamadas de entrada manuais",
    "cms.qualitycheck.label.userGroupMemberConfig" : "Configurar membros do grupo de usuários",
    "cms.agentMonitor.label.Idle_error" : "O inspetor não está em estado disponível.",
    "cms.callcenterinstanceslist.label.search" : "Consulta",
    "cms.report.field.internalTransferCount" : "Transferências internas",
    "cms.report.message.preDeviceTypeDesc" : "O tipo de dispositivo do último dispositivo que a chamada passou",
    "cms.callcenter.message.deletefailed" : "Falha na exclusão.",
    "cms.callcenterinstanceslist.message.error" : "Erro",
    "cms.report.message.IVRCallOutSuccRateDesc" : "Porcentagem de chamadas de saída de IVR conectadas para o total de solicitações de chamadas de saída de IVR.",
    "cms.agentMonitor.label.forcesignout_success" : "Um agente sai do sistema com sucesso.",
    "cms.report.title.filteringHeader" : "Cabeçalho da Tabela de Filtro",
    "cms.indexmonitor.field.durationAvgVdnInbound" : "Duração média da chamada de entrada",
    "cms.indexmonitor.message.agentOutCallCount" : "Número de chamadas de saída feitas pelo agente.(Não contém sessões multimídia)",
    "cms.report.field.minAgentOutCountField" : "Número mínimo de chamadas de saída de agentes simultâneos",
    "cms.report.field.avgQueueInCount" : "Número médio de chamadas simultâneas recebidas em uma fila",
    "cms.report.title.skillAccessMonitor" : "Monitorando Estatísticas sobre o desempenho da fila de habilidades por Código de Acesso",
    "cms.callcenter.message.createsuccess" : "Criação realizada com sucesso.",
    "cms.report.field.sysLostCalls" : "Chamadas recebidas falhadas",
    "cms.report.field.accessCodeField" : "Código de acesso",
    "cms.report.message.sysLostCallsDesc" : "Número de chamadas recebidas falhadas = Número de chamadas recebidas - Número de chamadas recebidas bem-sucedidas",
    "cms.report.field.acwInCountField" : "Tempos de encerramento de chamada recebida",
    "cms.callcenterinstanceslist.label.create" : "Novo",
    "cms.vcallecenter.field.enternewpassword" : "Digite a nova senha.",
    "cms.report.field.internalTransferCountField" : "Transferências internas",
    "cms.calllink.field.virtualDevice" : "Dispositivo virtual",
    "cms.callcenterinstanceslist.message.updateCtiVersion" : "Verifique se a migração de dados CTI está concluída. Tenha cuidado ao executar esta operação.",
    "cms.indexmonitor.field.abandonInTen" : "Chamadas abandonadas em 10 s",
    "cms.report.message.nomorethanerror" : "A data selecionada não pode ser anterior a {0}.",
    "cms.voiceinspection.message.downloadSucceed" : "A gravação foi baixada com sucesso.",
    "cms.calllink.field.hold" : "Chamada retida.",
    "cms.datasource.field.dbUsername" : "Nome do usuário",
    "cms.fullscreen.field.second" : "(s)",
    "cms.voiceinspection.field.audioPlay" : "Gravando Audição",
    "cms.sendnote.field.ok" : "OK",
    "cms.indexmonitor.message.abandonInTen" : "Percentual do número de chamadas abandonadas dentro de 10s no número total de chamadas de abandonadas",
    "cms.uapmonitor.labe.uapoverload" : "O número de números consultados pelo segmento de número de terminal excede o limite superior:",
    "cms.report.field.callSuccRateField" : "Taxa de conexão de solicitação",
    "cms.report.field.endTimeField" : "Horário de término da chamada",
    "cms.voiceinspection.message.downloadFailed" : "Falha ao baixar a gravação.",
    "cms.qcrelationconf.placeholder.pleaseInput" : "Insira por favor",
    "cms.monitordetail.field.outerforward" : "Chamadas transferidas externamente",
    "cms.fullscreen.field.editRichText" : "Modificar Rich Text",
    "cms.monitordetail.field.busydurationavg" : "Duração média de ocupado",
    "cms.systemmonitor.field.callRate" : "Taxa de Conclusão de Chamadas do Sistema",
    "cms.report.field.manTransferOutNumCti" : "Número de transferências manuais para o IVR",
    "cms.report.title.agentOperationReport" : "Relatório de Operações de Agentes",
    "cms.calllink.field.connectionHold" : "Uma chamada em espera está conectada.",
    "cms.indexmonitor.message.skillAvgConnectedDesc" : "Duração média da chamada. Duração média da chamada de entrada manual = Duração da chamada de entrada manual/Número de chamadas de entrada manuais conectadas",
    "cms.report.field.avgAgentInCountField" : "Número médio de chamadas recebidas de agentes simultâneos",
    "cms.indexmonitor.message.agentAbandonIn30Rate" : "Chamadas abandonadas em 30 segundos como porcentagem do total de abandonos",
    "cms.callcenterselect.title.selectCcName" : "Selecionar Call Center",
    "cms.indexmonitor.title.selectAgentName" : "Selecionar Tenant",
    "cms.callcenterinstanceslist.message.ccNameUsed" : "O nome do call center foi usado.",
    "cms.qcrelationconf.field.operator" : "Agente comum",
    "cms.report.field.avgAgentCountField" : "Número médio de chamadas simultâneas de agentes",
    "cms.sysparam.config.select.prompt" : "dicas",
    "cms.monitordetail.field.holdcount" : "Chamadas em espera",
    "cms.report.field.onlineRateField" : "Taxa online",
    "cms.callcenterinstanceslist.field.kafkaPassword" : "Senha KAFKA",
    "cms.callcenterinstanceslist.label.assignDeveloper" : "Desenvolvedor associado",
    "cms.report.field.userlevel" : "Nível de usuário:",
    "cms.report.field.minQueueOutCount" : "Número mínimo de chamadas de saída simultâneas em uma fila",
    "cms.report.field.agentReleaseTimeField" : "Hora de liberação do chamador",
    "cms.report.message.waitCauseDesc" : "O motivo pelo qual a chamada está esperando no dispositivo atual",
    "cms.fullscreen.field.bold" : "Negrito",
    "cms.indexmonitor.message.durationAvgWait" : "Duração média de espera das chamadas recebidas",
    "cms.agentmonitor.label.terminalStatusRefresh" : "Status de atualização",
    "cms.agentmonitor.label.insets" : "Inserir",
    "cms.systemmonitor.label.onehour" : "1 Hora",
    "cms.indexmonitor.message.skillSystemLostDesc" : "Número de interrupções da plataforma causadas por razões como tempo limite de resposta do agente",
    "cms.report.field.outFailNumField" : "Total de chamadas de saída falhadas",
    "cms.agentMonitor.label.forcerelease_error" : "Falha ao liberar uma chamada à força.",
    "cms.report.field.assistsCountField" : "Solicitações internas de ajuda",
    "cms.report.message.waitBeginDesc" : "Hora de início da chamada em espera",
    "cms.report.field.minIVRInCount" : "Número Mínimo de Chamadas Recebidas IVR Simultâneas",
    "cms.report.field.mediatype.vedio" : "Vídeo",
    "cms.calllink.field.releaseReason9" : "O CCIVR / IVR desliga uma chamada porque o nome da conferência está incorreto (possivelmente longo).",
    "cms.calllink.field.releaseReason8" : "O CCIVR / IVR desliga uma chamada porque o tipo de script MRF está incorreto.",
    "cms.calllink.field.releaseReason7" : "O CCIVR / IVR desliga uma chamada porque os blocos de controle são insuficientes.",
    "cms.fullscreen.field.virtualcallcenter" : "VDN",
    "cms.calllink.field.releaseReason6" : "O CCIVR / IVR desliga uma chamada porque nenhum fluxo pode ser encontrado ou ocorre um erro durante a análise de fluxo.",
    "cms.calllink.field.releaseReason5" : "O CCIVR / IVR desliga uma chamada por causa de falha de conexão.",
    "cms.calllink.field.releaseReason4" : "CCIVR / IVR desconexão de chamada normal.",
    "cms.calllink.field.releaseReason3" : "Quando uma chamada está enfileirada em uma fila de habilidades, a chamada é transferida para o fluxo IVR para reprodução de voz e coleta de dígitos. Se um assinante pressionar a tecla de cancelamento, o fluxo usará este código de erro para solicitar o cancelamento da fila de chamadas.",
    "cms.calllink.field.releaseReason2" : "Uma chamada é desconectada porque o CCACS detecta o tempo limite de pesquisa no lado do agente.",
    "cms.report.message.minIVRInCountDesc" : "Número mínimo de chamadas de entrada simultâneas cujo dispositivo de serviço é o IVR.",
    "cms.calllink.field.releaseReason1" : "Uma chamada é desconectada em um fluxo.",
    "cms.report.field.ackBeginField" : "Hora de início da resposta",
    "cms.fullscreen.field.chartType" : "Tipo do gráfico",
    "cms.developerlist.title.deleteconfim" : "Confirmar",
    "cms.callcenterinstanceslist.field.ctiversion" : "VERSÃO CTI",
    "cms.report.field.internalCallTimeField" : "Duração de chamadas internas",
    "cms.calllink.field.callType9" : "Chamada retransmitida",
    "cms.calllink.field.callType8" : "Chamada recebida (agente internacional)",
    "cms.agentMonitor.label.whisper_error" : "Sussurro falhou     ",
    "cms.calllink.field.callType7" : "Chamada auxiliar",
    "cms.calllink.field.callType6" : "Chamada de rede de saída interna para um agente virtual",
    "cms.calllink.field.callType5" : "Chamada de rede interna de entrada para um agente virtual",
    "cms.calllink.field.callType4" : "Chamada de inspeção recebida do call center de rede",
    "cms.calllink.field.callType3" : "Chamada de saída do call center de rede",
    "cms.calllink.field.callType2" : "Chamada recebida do centro de chamada de rede",
    "cms.indexmonitor.field.callInIVR" : "Chamadas recebidas on-line do IVR",
    "cms.calllink.field.callType1" : "Chamada de saída IVR PRI",
    "cms.calllink.field.callType0" : "Chamada de saída de um agente telefônico",
    "cms.report.message.maxAgentInCountDesc" : "Número máximo de chamadas recebidas simultâneas cujo dispositivo de serviço é um agente.",
    "cms.qcrelationconf.placeholder.accountCode" : "Insira uma conta comercial.",
    "cms.qualitycheck.label.createusergroup" : "Criar grupo de usuários",
    "cms.indexmonitor.field.abandonInSixty" : "Chamadas abandonadas em 60 s",
    "cms.calllink.title.logDate" : "Tempo das estatísticas",
    "cms.report.field.outInvalidCall" : "Chamadas de saída inválidas",
    "cms.qcrelationconf.title.success" : "Sucesso",
    "cms.report.field.ivrtrafficreport.statdate" : "Data",
    "cms.systemmonitor.field.selectAgentName" : "Selecionar Tenant",
    "cms.indexmonitor.message.agentAbandonIn10NDesc" : "Número total de abandonos com duração de resposta inferior a 10 segundos inclusive",
    "cms.report.field.minQueueInCount" : "Número mínimo de chamadas recebidas simultâneas em uma fila",
    "cms.agentMonitor.label.cancelwhisper_success" : "Whisper cancelado com sucesso.",
    "cms.systemmonitor.message.dategt31days" : "O intervalo de datas não pode exceder 31 dias.",
    "cms.fullscreen.field.richtext" : "Rich text",
    "cms.report.field.avgAgentOutCount" : "Número médio de chamadas de saída de agentes simultâneos",
    "cms.agentmonitor.label.header" : "Definir cabeçalho da tabela",
    "cms.report.message.saveSuccess" : "Os dados são salvos com sucesso.",
    "cms.report.title.agentOperationTrend" : "Tendência de detalhes da operação de conexão do agente",
    "cms.monitordetail.field.agentReleaseCount" : "Agente libera chamadas",
    "cms.report.field.utilizationRateField" : "Taxa de duração do estado de trabalho",
    "cms.indexmonitor.message.agentConnectInSixty" : "Percentual do número de chamadas de conectadas manualmente dentro de 60s no número total de chamadas de conectadas manualmente",
    "cms.report.field.inCallRateField" : "Duração média de chamada",
    "cms.reportsubscribe.message.reportNumberError" : "Todos os relatórios já foram adicionados.",
    "cms.uapmonitor.labe.inspectorcheckinpwd" : "Senha de login do inspetor QC",
    "cms.qcrelationconf.placeholder.workName" : "Insira um nome de agente",
    "cms.report.message.manToManWaitAnsTimeDesc" : "Duração de espera de transferência manual.",
    "cms.datasource.field.isBackup" : "Indica se é um banco de dados de backup",
    "cms.indexmonitor.field.skillOverQueue" : "Overflow chamadas para filas",
    "cms.report.field.minIVROutCount" : "Número mínimo de chamadas simultâneas de saída de IVR",
    "cms.indexmonitor.message.ivrTotalCallNum" : "Número de chamadas alocadas ao IVR e servidas pelo IVR completamente",
    "cms.report.field.abortQueueWaitNum" : "Chamadas abandonadas",
    "cms.report.field.callIdNumField" : "Número de sequência da fatura",
    "cms.indexmonitor.message.agentConnectIn30Rate" : "Conectividade manual em 30 segundos como porcentagem do total de chamadas",
    "cms.agentmonitor.label.alarmDetail" : "Detalhes do alarme",
    "cms.agentmonitor.label.mutilMediaMonitor": "Monitoramento de sessão multimídia",
    "cms.agentmonitor.label.stopMutilMediaMonitor": "Parar monitoramento de sessão",
    "cms.datasource.message.dsmodifyconfirm" : "A fonte de dados foi referenciada no call center e só entra em vigor depois que o ambiente é reiniciado. Tem certeza de que deseja modificá-lo?",
    "cms.report.message.manToManSuccNumDesc" : "Número de transferências manuais bem-sucedidas.",
    "cms.report.field.userRefusedFailNumField" : "Chamadas perdidas devido à rejeição de chamada",
    "cms.callcenterinstanceslist.field.sysAdminPassword" : "Senha do administrador de CTI",
    "cms.reportsubscribe.field.sentPeriod" : "Período enviado",
    "cms.report.message.inAnswerRateDesc" : "A porcentagem do Número de chamadas manuais conectadas ao Número de chamadas manuais.",
    "cms.voiceinspection.message.noRecordFound" : "Nenhuma gravação correspondente foi encontrada.",
    "cms.qualitycheck.label.editMember" : "Editar membro do grupo",
    "cms.developerlist.field.createTime" : "Criado em",
    "cms.report.field.call6RateField" : "Taxa de conexão recebida de entrada manual em 30s",
    "cms.calllink.field.commonAccessTransfer" : "A chamada é conectada ou encaminhada.",
    "cms.callcenterinstanceslist.field.developerDesc" : "Descrição",
    "cms.agentmonitor.label.cancelInsert" : "Cancelar inserção",
    "cms.agentmonitor.label.possessingSkills" : "Fila de login",
    "cms.indexmonitor.message.skillAbandonDesc" : "Número de solicitações de chamada de entrada manuais perdidas = Número de solicitações de chamada de entrada manuais - Número de solicitações de chamada de entrada manuais conectadas",
    "cms.report.message.enterReasonDesc" : "Motivo da chamada para o dispositivo",
    "cms.indexmonitor.message.SkillAbandonRateDesc" : "Taxa de chamada perdida = 100 - Taxa de chamada conectada",
    "cms.developerlist.title.refreshsk" : "Atualizar SK",
    "cms.indexmonitor.message.totalAnsweringNum" : "Número de agentes cujos telefones estão tocando",
    "cms.qcrelationconf.field.outgoingAgent" : "Agente de callout",
    "cms.indexmonitor.message.totalCallNumDesc" : "Número total de chamadas feitas por um agente",
    "cms.report.field.manToManWaitAnsNum" : "Chamadas manuais para agente atendidas",
    "cms.indexmonitor.message.skillAvgConnectedQueueDesc" : "Duração média da fila de todas as chamadas conectadas em uma fila.",
    "cms.agent.message.duplicatepwd" : "A nova senha é a mesma que a atual.",
    "cms.qualitycheck.label.qcRelationConfig" : "Relações de Inspeção",
    "cms.sendnote.message.tipsSuccess" : "A nota foi enviada com sucesso.",
    "cms.indexmonitor.message.updateSuccess" : "Atualização de sucesso",
    "cms.agentMonitor.label.forcebusy_stateerror" : "Os agentes estão descansando, por favor, não mostrem que estão ocupados",
    "cms.report.field.mediatype.H5Vedio" : "Vídeo H5",
    "cms.agentmonitor.label.idle" : "Forçar disponível",
    "cms.qcrelationconf.title.error" : "Erro",
    "cms.callcenter.field.cancel" : "Cancelar",
    "cms.calllink.field.teleconference" : "Teleconferência",
    "cms.report.field.IVRCallOutSuccNumField" : "Chamadas de saída de IVR conectadas",
    "cms.callcenterinstanceslist.field.uapCmdPort" : "Porta de comando UAP MML",
    "cms.report.field.skillId" : "ID da fila de habilidades",
    "cms.systemparam.error.validate.notmatchrule" : "O parâmetro de entrada não corresponde à regra de verificação",
    "cms.calllink.field.releaseReason99" : "A desconexão de uma mensagem que sai do timeout da chamada.",
    "cms.calllink.field.releaseReason98" : "Esperando que um agente responda a uma mensagem ao sair da chamada tempo limite.",
    "cms.calllink.field.releaseReason97" : "Aguardando a mensagem na fila da mensagem deixando tempos de espera de chamada.",
    "cms.calllink.field.releaseReason96" : "Aguardando o resultado do roteamento da mensagem que sai de chamada time out.",
    "cms.calllink.field.releaseReason95" : "Falha ao rotear a mensagem de saída da chamada.",
    "cms.calllink.field.releaseReason94" : "Uma chamada é desconectada porque um agente forçou desconexão",
    "cms.calllink.field.releaseReason93" : "Uma chamada é desligada porque um agente não atende a chamada por muito tempo.",
    "cms.calllink.field.releaseReason92" : "O cliente desliga e rejeita uma chamada.",
    "cms.calllink.field.releaseReason91" : "Uma chamada de conferência é desligada porque os participantes são removidos da conferência, o moderador de conferência desliga proativamente a chamada de conferência ou o CCS está anormal.",
    "cms.calllink.field.releaseReason90" : "O roteamento interno falha porque os recursos IVR estão ocupados ou nenhum código de acesso IVR está registrado.",
    "cms.systemmonitor.label.search" : "Pesquisar",
    "cms.datasource.message.testsuccess" : "Teste de conexão bem-sucedido.",
    "cms.report.field.threePartyCallCountDesc" : "Número de chamadas de três partes de um agente.",
    "cms.callcenterinstanceslist.message.createSuccess" : "O call center foi criado com sucesso.",
    "cms.agentmonitor.label.cancellisteningOrInsert" : "Cancelar Escuta ou Inserção",
    "cms.fullscreen.field.percentage" : "(%)",
    "cms.indexmonitor.field.skillAbandonIn30Rate" : "Chamadas abandonadas em 30 s",
    "cms.indexmonitor.field.ivrInboundConnectRate" : "Chamadas de entrada IVR conectadas",
    "cms.report.message.avgIVRServiceDesc" : "Duração média da chamada IVR, que é igual à duração da chamada de entrada IVR dividida pelo número de chamadas de entrada IVR conectadas.",
    "cms.indexmonitor.label.previous" : "Anterior",
    "cms.calllink.field.releaseReason89" : "O ACD desliga uma chamada.",
    "cms.calllink.field.releaseReason88" : "O ACD desliga uma chamada. Motivo 4 da desconexão de chamada quando ocorre um fallback de vídeo: A parte chamada é incompatível.",
    "cms.calllink.field.releaseReason87" : "O ACD desliga uma chamada. Motivo 3 da desconexão da chamada quando ocorre um fallback de vídeo: Não há capacidade de portador disponível no momento.",
    "cms.voiceinspection.field.agentId" : "Agente",
    "cms.calllink.field.releaseReason86" : "O ACD desliga uma chamada. Motivo 2 da desconexão de chamada quando ocorre um fallback de vídeo: A capacidade de portadora não é suportada.",
    "cms.calllink.field.releaseReason85" : "O ACD desliga uma chamada. Motivo 1 da desconexão de chamada quando ocorre um fallback de vídeo: Nenhum recurso disponível.",
    "cms.report.field.avgCallPerHour" : "Média de chamadas manuais recebidas por hora",
    "cms.calllink.field.releaseReason84" : "O ACD desliga uma chamada.",
    "cms.calllink.field.releaseReason83" : "O ACD desliga uma chamada.",
    "cms.report.field.awarnessRateField" : "Taxa de sucesso de chamada recebida manual",
    "cms.calllink.field.releaseReason82" : "O ACD desliga uma chamada porque o bloco de controle Intess está defeituoso.",
    "cms.calllink.field.releaseReason81" : "O ACD desliga uma chamada porque o local da conferência CP está defeituoso.",
    "cms.sysparam.config.search" : "Pesquisar",
    "cms.calllink.field.releaseReason80" : "O ACD desliga uma chamada por causa de operações anormais de recursos UAP.",
    "cms.callcenterinstanceslist.message.needKafkaSSL" : "Ativar SSL",
    "cms.report.field.submediatype.5gmessage" : "5G RCS",
    "cms.report.message.waitEndDesc" : "Tempo de espera final da chamada, que é o tempo de espera final da chamada no dispositivo atual",
    "cms.report.field.maxQueueOutCount" : "Número Máximo de Chamadas de Saída Simultâneas em uma Fila",
    "cms.report.field.busyTime" : "Duração total de ocupado",
    "cms.report.field.outWaitAnsNum" : "Chamadas de saída manuais",
    "cms.report.message.queryTimeCheck" : "O horário não pode ser anterior a {0}.",
    "cms.indexmonitor.message.agentConnectInThirty" : "Percentual do número de chamadas de conectadas manualmente dentro de 30 segundos no número total de chamadas de conectadas manualmente",
    "cms.voiceinspection.field.queryEndTime" : "Tempo final",
    "cms.calllink.field.releaseReason79" : "O ACD desliga uma chamada porque o canal está reinicializado.",
    "cms.calllink.field.releaseReason78" : "O ACD desliga uma chamada porque não são permitidos correios de chamada de entrada.",
    "cms.calllink.field.releaseReason77" : "O ACD desliga uma chamada porque a caixa de correio está cheia ou suspensa.",
    "cms.calllink.field.releaseReason76" : "O ACD desliga uma chamada porque o tempo limite da chamada.",
    "cms.calllink.field.releaseReason75" : "O ACD desliga uma chamada porque a linha está ocupada.",
    "cms.calllink.field.releaseReason74" : "O ACD desliga uma chamada porque a parte chamada rejeita a chamada.",
    "cms.agentMonitor.label.talk_error" : "O agente selecionado não está em estado de fala.",
    "cms.developerlist.field.search" : "Pesquisar",
    "cms.calllink.field.releaseReason73" : "O ACD desliga uma chamada porque a parte chamada não atende a chamada.",
    "cms.calllink.field.releaseReason72" : "O ACD desliga uma chamada porque nenhum recurso está disponível.",
    "cms.calllink.field.releaseReason71" : "O ACD desliga uma chamada porque o chamador desliga antes da chamada ser atendida.",
    "cms.calllink.field.releaseReason70" : "Nível de cliente incorreto.",
    "cms.callcenterinstanceslist.label.batchAdd" : "Adição em lote",
    "cms.agentMonitor.label.talkingerror" : "O agente selecionado não está em uma chamada ou está em uma chamada multimídia",
    "cms.agentMonitor.label.gateway_error" : "Falha ao chamar a interface de gateway.",
    "cms.calllink.title.callEndTime" : "Hora de término da chamada",
    "cms.report.field.call4Rate" : "Taxa de conexão recebida de entrada manual em 20s",
    "cms.indexmonitor.message.skillOnlineInbondCallDesc" : "Número de chamadas que estão sendo processadas + Número de chamadas que estão em fila",
    "cms.report.field.submediatype.whatsapp" : "WhatsApp",
    "cms.report.message.inCallSuccNumberDesc" : "Número de chamadas de entrada conectadas. O valor é a soma das chamadas de entrada manuais conectadas e chamadas de entrada conectadas automaticamente usando o IVR.",
    "cms.report.field.manTransferOutNumCtiField" : "Número de transferências manuais para o IVR",
    "cms.indexmonitor.message.inboundCallNum" : "Número de chamadas manuais de entrada + Número de chamadas de entrada IVR",
    "cms.report.message.avgCallPerHourDesc" : "Número médio de chamadas atendidas por um agente por hora no segmento de tempo de trabalho selecionado.",
    "cms.report.field.succWaitAnsRateField" : "Duração média da fila de chamadas conectadas",
    "cms.calllink.field.releaseReason69" : "O número chamado não pode ser encontrado.",
    "cms.calllink.field.releaseReason68" : "O número de chamadas simultâneas no fluxo excede o máximo.",
    "cms.voiceinspection.field.messageCall" : "Chamada de mensagem",
    "cms.calllink.field.releaseReason67" : "O número total de chamadas automáticas excede o limite.",
    "cms.report.message.awarnessRateDesc" : "Porcentagem do número de chamadas atendidas pelo agente no número de chamadas manuais de entrada.",
    "cms.report.message.minIVROutCountDesc" : "Número mínimo de chamadas de saída simultâneas cujo dispositivo de serviço é o IVR.",
    "cms.calllink.field.releaseReason66" : "O número de chamadas excede o limite.",
    "cms.calllink.field.releaseReason65" : "Nenhum agente está de serviço.",
    "cms.calllink.field.releaseReason64" : "Uma chamada é desconectada devido ao excesso de fila.",
    "cms.calllink.field.releaseReason63" : "Durante uma chamada de rede, o call center de destino time out na espera pela chamada de entrada de rede do ACD.",
    "cms.calllink.field.releaseReason62" : "Durante uma chamada de rede, o centro de chamadas peer está anormal.",
    "cms.calllink.field.releaseReason61" : "Uma chamada é desconectada no ACD devido a outros motivos.",
    "cms.calllink.field.releaseReason60" : "Quando uma chamada de rede é transferida em modo de suspensão, o CCS retorna um erro após o dispositivo de destino processar e desconectar uma chamada.",
    "cms.indexmonitor.field.status" : "Status",
    "cms.fullscreen.field.themeName" : "Nome do assunto",
    "cms.report.field.ans20s" : "Taxa de conexão de chamada manual em 20s",
    "cms.vdnconfiguration.field.defaultCallCenter" : "Call Center onde o VDN é executado",
    "cms.report.message.noData" : "Não há dados disponíveis.",
    "cms.calllink.field.releaseReason59" : "Uma chamada é desconectada no ACD porque a parte chamada está fora da área de serviço.",
    "cms.report.field.ringTimeField" : "Duração total do toque",
    "cms.calllink.field.releaseReason58" : "Uma chamada é desligada no ACD porque o telefone da parte chamada está desligado.",
    "cms.calllink.field.releaseReason57" : "Quando uma chamada é transferida, o resultado de reencaminhamento retornado pelo CCS não é um dispositivo virtual, mas o endereço de indicação de transferência enviado pelo CCS é um dispositivo virtual.",
    "cms.calllink.field.releaseReason56" : "Uma chamada é transferida em modo de suspensão para um dispositivo. Após o dispositivo de destino processar e desconectar a chamada, esperando que o CCS retorne o resultado de desconexão HUNG_RELEASE_ACK time out.",
    "cms.calllink.field.releaseReason55" : "Uma chamada é transferida em modo de suspensão para um dispositivo. Depois que o dispositivo de destino processa a chamada, esperando que o CCS envie RESUME_ACK para notificar o dispositivo de suspensão do tempo limite de reinício da chamada.",
    "cms.calllink.field.releaseReason54" : "Uma chamada é transferida em modo de suspensão para um dispositivo. Após o dispositivo de destino processar e desconectar a chamada, o CCS retorna a mensagem de erro HUNG_RELEASE_ACK.",
    "cms.calllink.field.releaseReason53" : "Uma chamada é transferida em modo de suspensão para um dispositivo. Depois que o dispositivo de destino processa a chamada e a chamada for retornada ao dispositivo de suspensão, o CCS retorna a mensagem de erro RESUME_ACK.",
    "cms.calllink.field.releaseReason52" : "Uma chamada é desligada após a reprodução de voz.",
    "cms.indexmonitor.message.skillAgentAbandonDesc" : "Número de chamadas suspensas proativamente por agentes durante o toque",
    "cms.calllink.field.releaseReason51" : "Uma chamada é desligada após o CCS ser alternado.",
    "cms.calllink.field.releaseReason50" : "Uma chamada é desconectada no ACD porque o número chamado não existe.",
    "cms.fullscreen.field.totalCallTimeDesc" : "Duração total da chamada das chamadas conectadas de um agente.",
    "cms.report.message.accessCodeDesc" : "Código de acesso.",
    "cms.report.message.outOccupyNumDesc" : "Número de chamadas feitas por um agente para clientes.",
    "cms.indexmonitor.message.skillAvgAbandonAckDesc" : "Duração média do toque de todas as chamadas não atendidas.",
    "cms.report.message.userRefusedFailNumDesc" : "Número de chamadas que não são atendidas devido à rejeição de chamadas.",
    "cms.agentmonitor.label.haveAlarm" : "Há um alarme.",
    "cms.indexmonitor.message.errorAgentInfo" : "Selecione pelo menos um agente.",
    "cms.calllink.field.releaseReason49" : "Uma chamada é desconectada no ACD porque a parte chamada está ocupada.",
    "cms.calllink.field.releaseReason48" : "Quando um assinante cancela o enfileiramento, a plataforma desliga uma chamada.",
    "cms.calllink.field.releaseReason47" : "Quando o CTIServer é desconectado de um módulo ACD, todas as chamadas do módulo ACD são desconectadas.",
    "cms.calllink.field.releaseReason46" : "O CCS retorna uma mensagem de falha de roteamento.",
    "cms.systemparam.error.save" : "Falha ao salvar os parâmetros",
    "cms.calllink.field.releaseReason45" : "Quando um fluxo transfere uma chamada para outro fluxo, o fluxo desliga a chamada antes de tocar.",
    "cms.calllink.field.releaseReason44" : "Uma chamada é normalmente desligada no ACD.",
    "cms.report.field.waitAnsRateField" : "Duração média do toque de chamadas manuais de entrada",
    "cms.fullscreen.message.playTheme" : "Este assunto foi reproduzido.",
    "cms.calllink.field.releaseReason43" : "O IVR inicia a combinação de chamadas.",
    "cms.calllink.field.releaseReason42" : "Após um fluxo transferir uma chamada para um agente em modo de suspensão, se o agente desligar e transferir a chamada de volta para o IVR, o IVR retorna uma mensagem de falha.",
    "cms.calllink.field.releaseReason41" : "Durante a visualização de chamada originada, o CCS retorna uma mensagem de falha.",
    "cms.indexmonitor.message.updateFailed" : "Falha na atualização",
    "cms.calllink.field.releaseReason40" : "O CCS retorna uma mensagem de falha de roteamento.",
    "cms.developerlist.title.create" : "Adicionar desenvolvedor",
    "cms.indexmonitor.message.ivrInCallCount" : "Número de chamadas recebidas cujo dispositivo de serviço atual é um IVR.",
    "cms.skilladjustment.message.tipsOutSide" : "Podem ser ajustados no máximo 10 agentes  de cada vez.",
    "cms.calllink.field.outOfService" : "O telefone está fora de serviço.",
    "cms.report.message.noAckNumDesc" : "Número de chamadas não respondidas após serem alocadas aos agentes.",
    "cms.calllink.field.releaseReason39" : "O endereço de destino no resultado de roteamento retornado pelo CCS está incorreto.",
    "cms.calllink.field.releaseReason38" : "Quando a plataforma inicia uma chamada de saída, o CCS retorna uma mensagem de falha de ocupação do dispositivo.",
    "cms.callcenterinstanceslist.field.ccName" : "Nome da central de atendimento",
    "cms.calllink.field.releaseReason37" : "Um fluxo automático inicia o redirecionamento de chamada (uma chamada é desligada e transferida para fora).",
    "cms.calllink.field.releaseReason36" : "Um agente inicia o redirecionamento de chamada (uma chamada é desligada e transferida para fora).",
    "cms.report.message.dategt1year" : "A faixa de mês não pode exceder 12 meses.",
    "cms.calllink.field.releaseReason35" : "Quando uma chamada é transferida em modo de suspensão, o endereço enviado pelo CCS para DO_FORWARD está incorreto.",
    "cms.agentmonitor.label.noListenedAndInserted" : "Não inspecionado",
    "cms.calllink.field.releaseReason34" : "Quando uma chamada é transferida em modo de suspensão, o endereço enviado pelo CCS para DO_FORWARD está incorreto.",
    "cms.calllink.field.releaseReason33" : "O cliente desliga uma chamada.",
    "cms.report.field.calledHangUp" : "Chamado Hang Up",
    "cms.calllink.field.releaseReason32" : "Aguardando o toque ou resposta do dispositivo de destino tempo limite.",
    "cms.fullscreen.field.indicatorData" : "Dados do indicador",
    "cms.report.field.IVROutCallNum" : "Chamadas de saída Pura-IVR",
    "cms.fullscreen.field.reportDate" : "1 dia atrás",
    "cms.calllink.field.releaseReason31" : "Aguardando a resposta de roteamento do CCS time out.",
    "cms.calllink.field.releaseReason30" : "Durante a transferência de chamada, esperando que o dispositivo de destino de transferência responda time out.",
    "cms.indexmonitor.message.agentConnectedIn3NDesc" : "Número total de chamadas atendidas com duração inferior a 3 segundos inclusive",
    "cms.report.field.failAnsRateField" : "Taxa de perda de solicitação",
    "cms.systemparam.error.validate.notmatchrulenumber" : "Por favor, digite um número",
    "cms.report.field.outOccupyNum" : "Chamadas de saída",
    "cms.report.field.avgAcwTime" : "Duração média de pós-atendimento",
    "cms.callcenterinstanceslist.field.passwordConfirm" : "Confirmar senha",
    "cms.report.field.minIVRInCountField" : "Número mínimo de chamadas IVR simultâneas de entrada",
    "cms.report.field.acdCalls" : "Total de chamadas conectadas",
    "cms.voiceinspection.title.error" : "Erro",
    "cms.report.field.manToManSuccNumField" : "Transferências manual para agente bem-sucedidas",
    "cms.monitordetail.field.busyDuration" : "Duração de ocupado",
    "cms.calllink.field.releaseReason29" : "Quando uma chamada é transferida de um agente para um fluxo automático, esperando que o fluxo automático responda time out.",
    "cms.indexmonitor.field.durationAvgVdnOutbound" : "Duração média da chamada originada",
    "cms.report.field.workTimeField" : "Duração total do trabalho",
    "cms.calllink.field.releaseReason28" : "Overflows de fila de chamadas ou tempo limite.",
    "cms.calllink.field.releaseReason27" : "Overflows de fila de chamadas ou tempo limite.",
    "cms.calllink.field.releaseReason26" : "Quando a plataforma inicia uma chamada de saída, esperando o tempo limite da mensagem CCS OCCUPY_ACK.",
    "cms.calllink.field.releaseReason25" : "Quando a plataforma inicia uma chamada de saída, aguardando a mensagem ACD SETUP_ACK expira o tempo limite.",
    "cms.calllink.field.releaseReason24" : "Quando uma chamada interna for transferida com sucesso, esperando o tempo limite do CCS FORWARD_COMIT.",
    "cms.report.field.IVRInSucc" : "Chamadas de entrada IVR conectadas",
    "cms.calllink.field.releaseReason23" : "Quando uma chamada é transferida, esperando o tempo limite da mensagem CCS DO_FORWARD.",
    "cms.calllink.field.releaseReason22" : "Depois que um dispositivo não-agente transfere uma chamada para o IVR no modo de transferência de suspensão, esperando que o CCS retorne o resultado do roteamento time out.",
    "cms.report.message.outAnswerRateDesc" : "Porcentagem do número de chamadas de saída conectadas no número de chamadas de saída.",
    "cms.calllink.field.releaseReason21" : "Depois que um agente transfere uma chamada para o IVR no modo de transferência de suspensão, esperando que o CCS retorne o resultado do roteamento time out.",
    "cms.calllink.field.releaseReason20" : "Quando uma chamada é transferida de volta para o console do agente após o console do agente transferir a chamada para o IVR no modo de transferência de suspensão, o console do agente esgotou-se ao responder à mensagem de reinício da suspensão. Como resultado, a plataforma desliga a chamada. Alternativamente, quando uma chamada é transferida de volta para o IVR depois que o IVR transfere a chamada para o console do agente em modo de transferência de suspensão, o IVR time out ao responder à mensagem de reinício da suspensão. Como resultado, a plataforma desliga a chamada.",
    "cms.report.field.outAboundRing" : "Chamadas Saídas Não Atendidas",
    "cms.report.field.manToIvrNumCtiField" : "Número de vezes que o agente suspendeu a transferência para o IVR",
    "cms.sysparam.config.save" : "Salvar",
    "cms.vcallecenter.message.syncrolefail" : "Falha ao sincronizar a função.",
    "cms.indexmonitor.message.agentAbandonOut10NDesc" : "Número total de abandonos na resposta superior a 10 segundos",
    "cms.report.message.internalCallTimeDesc" : "Duração de chamadas internas",
    "cms.uapmonitor.field.Search" : "Pesquisar",
    "cms.report.message.acwCountDesc" : "Número de vezes que um agente entra no estado de envolturamento.",
    "cms.agentMonitor.label.forceidle_error" : "Falha ao definir forçadamente um agente para o estado disponível.",
    "cms.qualitycheck.field.unselected" : "UnSelected",
    "cms.calllink.field.releaseReason19" : "A plataforma desliga uma chamada porque o handshake entre o bloco de controle da plataforma e o ACD time out após a chamada ser estabelecida entre a plataforma e o ACD.",
    "cms.calllink.field.releaseReason18" : "A plataforma desconecta uma chamada de saída porque esperando o toque ACD ou tempos fora do gancho.",
    "cms.calllink.field.releaseReason17" : "Uma chamada é desconectada porque o resultado do roteamento não é recebido do CCS depois que a chamada acessar a plataforma. Alternativamente, uma chamada é desconectada em um fluxo após a chamada acessar o IVR.",
    "cms.calllink.field.releaseReason16" : "O CCIVR / IVR desliga uma chamada por causa de outros erros. Para obter detalhes, consulte os registros.",
    "cms.calllink.field.releaseReason15" : "O CCIVR / IVR desliga uma chamada porque a duração máxima da conversa é excedida.",
    "cms.calllink.field.releaseReason14" : "O CCIVR / IVR desliga uma chamada porque o IVR não consegue conectar a parte chamada e a parte chamada.",
    "cms.calllink.field.releaseReason13" : "O CCIVR / IVR desconecta uma chamada porque o tempo limite da chamada de saída do CCIVR.",
    "cms.datasource.field.operation" : "Operar",
    "cms.calllink.field.releaseReason12" : "O CCIVR / IVR desconecta uma chamada porque o tempo limite do handshake com o CTIServer.",
    "cms.calllink.field.releaseReason11" : "O CCIVR / IVR desliga uma chamada porque esperando que o módulo de negócios pegue o telefone time out.",
    "cms.calllink.field.releaseReason10" : "O CCIVR / IVR desliga uma chamada porque esperando que a parte chamada pegue o telefone o time out após o CTIServer retornar uma chamada para a parte chamada.",
    "cms.systemmonitor.label.thirtyminutes" : "30 Minutos",
    "cms.report.field.unspecifiedUserLevel" : "Não especificado",
    "cms.report.field.inCallSuccNumber" : "Chamadas de entrada conectadas",
    "cms.indexmonitor.field.skillAbandonIn3Rate" : "Chamadas abandonadas em 3 s",
    "cms.report.message.agentTalkDurationDesc" : "Duração(ões) da conversa da chamada.",
    "cms.report.message.succAnsRateDesc" : "Porcentagem do número de solicitações de chamada de entrada manuais conectadas no número de chamadas de entrada manuais que solicitam serviços.",
    "cms.report.field.mediatype.clickvediocall" : "Clique em Chamada de Vídeo",
    "cms.monitordetail.field.redirectcount" : "Chamadas redirecionadas",
    "cms.report.field.availTimeRateField" : "Taxa de ociosidade",
    "cms.fullscreen.field.addCharts" : "Criar gráfico",
    "cms.report.field.outInvalidCallField" : "Chamadas de saída inválidas",
    "cms.qcrelationconf.message.paramOutOfRange" : "A entrada não pode exceder 99 caracteres",
    "cms.synchronize.message.notConfigured" : "Não configurado",
    "cms.report.title.abandonRingTrend" : "Tendência de chamadas abandonadas durante o toque",
    "cms.report.message.transferOutCountDesc" : "Número de tempos de transferência manual.",
    "cms.export.field.failed" : "Falha",
    "cms.synchronize.message.syncStatus" : "Status da sincronização",
    "cms.calllink.title.skillQueue" : "Fila de habilidades conectadas",
    "cms.callcenterinstanceslist.label.synchronize" : "Sincronizar",
    "cms.report.message.minQueueOutCountDesc" : "Número mínimo de chamadas de saída simultâneas cujo dispositivo de serviço é uma fila de habilidades.",
    "cms.voiceinspection.message.frequentPlay" : "O jogo é muito freqüente. Tente novamente mais tarde.",
    "cms.systemmonitor.message.timeempty" : "* A hora de início ou a hora de término não podem estar em branco.",
    "cms.report.field.mediaTypeField" : "Tipo de mídia",
    "cms.agentmonitor.label.all" : "todos",
    "cms.report.field.systemInBoundField" : "Chamadas recebidas",
    "cms.qcrelationconf.field.agentGroup" : "Grupo de agentes",
    "cms.systemmonitor.label.reset" : "Redefinir",
    "cms.agentMonitor.label.whisper_success" : "Sussurrando sucesso ",
    "cms.report.title.IVRMonitor" : "Monitoramento de dados IVR",
    "cms.report.field.maxAgentOutCountField" : "Número máximo de chamadas de saída de agentes simultâneos",
    "cms.report.field.acdTime" : "Duração da chamada manual",
    "cms.report.label.export" : "Exportar",
    "cms.skilladjustment.message.tipsSelectSkill" : "Selecione uma fila de habilidades.",
    "cms.agentMonitor.label.details" : "Detalhes",
    "cms.report.message.utilizationRateDesc" : "Taxa de duração do estado de trabalho = (duração total da chamada + duração total de encerramento) / (duração total do trabalho - duração de repouso) x 100",
    "cms.report.field.acwCountField" : "Tempos do pós-atendimento",
    "cms.report.field.maxAgentCount" : "Número Máximo de Chamadas Concorrentes do Agente",
    "cms.voiceinspection.message.noSftpFound" : "Falha ao consultar informações de conexão do servidor SFTP.",
    "cms.indexmonitor.field.agentConnectInSixty" : "Chamadas conectadas manualmente em 60 s",
    "cms.monitordetail.field.idle" : "disponível",
    "cms.fullscreen.field.FangSong" : "FangSong",
    "cms.agentmonitor.label.total" : "Total dos agentes",
  "cms.agentmonitor.label.syncScreenMonitor": "Tela do monitor",
    "cms.calllink.field.releaseReason119" : "Uma chamada é desconectada porque o CCUCS detecta timeout do assinante",
    "cms.calllink.field.releaseReason118" : "Uma chamada é desconectada após o timeout da fila.",
    "cms.calllink.field.releaseReason117" : "Uma chamada é desconectada porque o tempo limite do comando CCUCS.",
    "cms.calllink.field.releaseReason116" : "Um agente desliga proativamente uma chamada.",
    "cms.uapmonitor.field.registered" : "registrado",
    "cms.calllink.field.releaseReason115" : "A duração da chamada atinge o máximo.",
    "cms.calllink.field.releaseReason114" : "Desconectando por tempo limite da chamada de conferência.",
    "cms.calllink.field.releaseReason113" : "O número de participantes na conferência é menor do que o esperado.",
    "cms.report.message.systemInSuccDesc" : "Número de chamadas recebidas bem-sucedidas, incluindo as chamadas durante a reprodução de voz IVR, enfileiramento, tocando nos terminais do agente e atendendo o agente (excluindo chamadas internas de entrada).",
    "cms.calllink.field.releaseReason112" : "Tempo limite de detecção de conferência.",
    "cms.calllink.field.releaseReason111" : "Falha ao obter dados do local da conferência.",
    "cms.callcenterinstanceslist.message.selectdeveloper" : "Selecione um desenvolvedor.",
    "cms.calllink.field.releaseReason110" : "Obtendo dados do local de conferência time out.",
    "cms.calllink.msg.callIdNotExist" : "O ID da chamada não pode estar em branco.",
    "cms.datasource.field.dbName" : "Nome da Origem de Dados",
    "cms.failurecall.field.queueName" : "Nome da fila",
    "cms.reportsubscribe.field.suspended" : "Não publicado",
    "cms.agentMonitor.label.cancelListening_error" : "Falha ao cancelar a audição.",
    "cms.qualitycheck.label.groupname" : "Nome Do Grupo",
    "cms.calllink.field.releaseReason109" : "Falha ao consultar informações sobre o local da conferência.",
    "cms.calllink.field.releaseReason108" : "A consulta às informações do site da conferência atinge o tempo limite.",
    "cms.calllink.field.releaseReason107" : "A ID da empresa está vazia.",
    "cms.systemmonitor.field.onlineAgentNums" : "Agentes on-line",
    "cms.calllink.field.releaseReason106" : "Falha ao consultar a ID da empresa.",
    "cms.calllink.field.releaseReason105" : "Consultando o timeout da ID corporativa.",
    "cms.calllink.field.releaseReason104" : "Falha ao juntar-se ao site da conferência.",
    "cms.callcenterinstanceslist.message.dsmodifyconfirm" : "A configuração da fonte de dados foi alterada. Reinicie o ambiente para que a modificação entre em vigor. Tem certeza de que deseja modificá-lo?",
    "cms.indexmonitor.field.pageDataUpdateTime" : "Dados atualizados:",
    "cms.calllink.field.releaseReason103" : "Tempo limite de criação de site de conferência.",
    "cms.calllink.field.releaseReason102" : "Falha ao criar o local da conferência.",
    "cms.calllink.field.releaseReason101" : "O aperto de mão da mensagem deixando o tempo limite da chamada.",
    "cms.qcrelationconf.field.accountName" : "Nome da conta",
    "cms.calllink.field.releaseReason100" : "A mensagem interna que sai da chamada time out.",
    "cms.recorddrive.label.sftpPort" : "Porta do servidor SFTP",
    "cms.monitordetail.field.audio" : "Áudio/vídeo",
    "cms.callcenterinstanceslist.field.vdnName" : "Nome do VDN",
    "cms.vcallecenter.title.failed" : "Erro",
    "cms.agentmonitor.label.pageRefreshTime" : "Dados atualizados",
    "cms.qcrelationconf.field.agentWorkNo" : "ID da Parte Inspecionada",
    "cms.report.field.ivrtrafficreport.ivrservicerate" : "Somente taxa de serviço IVR",
    "cms.callcenterinstanceslist.message.uapNode2Info" : "Configuração do nó 2 UAP",
    "cms.report.field.muteTimeField" : "Duração total do silêncio",
    "cms.report.field.hangUpModeField" : "Método desligar",
    "cms.indexmonitor.field.totalBusyNum" : "Agentes em estado ocupado",
    "cms.systemparam.error.validate.notmatchrulemin" : "O parâmetro de entrada é inferior ao mínimo",
    "cms.report.message.call3RateDesc" : "Porcentagem do número de solicitações de chamada conectadas manualmente em 15 segundos no número total de chamadas que solicitam serviços manuais.",
    "cms.qcrelationconf.message.queryParamNull" : "O ID do agente e o nome do grupo de usuários não podem ficar vazios ao mesmo tempo.",
    "cms.indexmonitor.message.totalBusyNum" : "Número de agentes em estado ocupado",
    "cms.report.field.ivrtrafficreport.ivrshortcalls" : "Número de chamadas curtas IVR (dentro de 3s)",
    "cms.agentmonitor.label.showAlarmAgentNo" : "ID do agente",
    "cms.report.field.callStartTimeField" : "Hora de Início da Chamada",
    "cms.report.field.maxIVROutCount" : "Número máximo de chamadas simultâneas de saída IVR",
    "cms.recorddrive.title.recorddrivenameconfig" : "Configuração do volume",
    "cms.calllink.field.routingTemporaryDevice" : "Dispositivo de roteamento temporário",
    "cms.agentmonitor.label.detail" : "Detalhes de monitoramento do agente",
    "cms.report.title.agentReport" : "Sumário de Relatório de Agentes",
    "cms.agentmonitor.label.idleState" : "Disponível",
    "cms.report.field.waitEndField" : "Hora final da espera",
    "cms.report.field.minQueueInCountField" : "Número mínimo de chamadas simultâneas de entrada em uma fila",
    "cms.report.field.releaseCauseField" : "Motivo da liberação",
    "cms.datasource.message.addsuccess" : "Adicionar com sucesso.",
    "cms.report.message.busyTimeRateDesc" : "(Duração total ocupado / Duração total do trabalho) x 100%",
    "cms.record-playback.callType3" : "Chamada de saída do OBS",
    "cms.systemmonitor.field.cancel" : "Cancelar",
    "cms.agentmonitor.label.answering" : "Atendendo",
    "cms.qcrelationconf.field.operation" : "Ação",
    "cms.report.message.outCallSuccNumberDesc" : "Número de chamadas de saída automáticas e manuais que são atendidas pelas partes chamadas. O valor é a soma das chamadas de saída manuais conectadas e chamadas de saída conectadas automaticamente usando o IVR.",
    "cms.report.message.otherHangNumCtiDesc" : "Número de interrupções do sistema.",
    "cms.report.field.IVRSuccRateField" : "Taxa de conexão de chamada de entrada IVR",
    "cms.report.field.agentInSuccField" : "Chamadas de entrada manuais conectadas",
    "cms.uapmonitor.labe.mentiontitle" : "Nota:",
    "cms.datasource.label.create" : "Adicionar",
    "cms.recorddrive.field.number" : "Não",
    "cms.report.field.connected" : "Conectado",
    "cms.synchronize.message.confPwdStatus" : "Configure a senha de interface",
    "cms.report.title.selectReportTime" : "Definir Hora de Início",
    "cms.qualitycheck.title.deleteSuccess" : "Exclusão realizada com sucesso.",
    "cms.report.message.auxCountDesc" : "Número de vezes que um agente entra no estado de repouso.",
    "cms.indexmonitor.message.durationAvgVdnOutbound" : "Duração total que as chamadas de saída ocupam o VDN / Número total de chamadas de saída",
    "cms.calldetail.label.inputcallid" : "Insira o número de série da chamada",
    "cms.report.message.timeCheckError" : "O intervalo de tempo não pode exceder sete dias",
    "cms.report.field.intelligentOutCall" : "Chamada de saída inteligente",
    "cms.report.field.avgIVROutCount" : "Número médio de chamadas simultâneas de saída IVR",
    "cms.voiceinspection.field.phoneVideo" : "Videochamada comum",
    "cms.report.field.avgInRingTime" : "Duração média da chamada recebida dos terminais",
    "cms.fullscreen.field.auxCountTimeDesc" : "Número de vezes que um agente está no estado de repouso",
    "cms.systemparam.error.validate.notmatchruleip" : "Por favor, digite um endereço IPV4 ou IPV6 válido",
    "cms.report.field.userBusyFailNum" : "Chamadas perdidas devido a linhas ocupadas",
    "cms.report.field.failAnsRate" : "Taxa de Perda de Solicitação",
    "cms.qcrelationconf.field.remark" : "Observação",
    "cms.agentMonitor.label.forceidle_success" : "Um agente é definido à força para o estado inativo com sucesso.",
    "cms.report.message.billInfo1Desc" : "Tipo de chamada de voz",
    "cms.reportsubscribe.field.reportName" : "Nome do relatório",
    "cms.agentMonitor.label.success" : "Sucesso",
    "cms.report.message.outAboundRingDesc" : "Número de chamadas de saída que são rejeitadas pelos clientes ou não atendidas após o toque.",
    "cms.report.message.callEndDesc" : "Hora final da chamada",
    "cms.indexmonitor.field.agentOutCallCount" : "Número de chamadas de saída do agente on-line",
    "cms.indexmonitor.message.totalConnectCallNum" : "Número de chamadas de entrada conectadas + Número de chamadas originadas conectadas",
    "cms.report.field.utilizationRate" : "Taxa de duração do estado de trabalho",
    "cms.report.field.otherHangNumCtiField" : "Desligações do sistema",
    "cms.indexmonitor.message.totalLearningNum" : "Número de agentes em estado de aprendizagem",
    "cms.report.field.succAnsRate" : "Taxa de conexão de solicitação",
    "cms.agentMonitor.label.querynum_error" : "Falha ao atualizar o número de login.",
    "cms.report.message.IVRSuccRateDesc" : "Percentagem do número de chamadas de entrada IVR conectadas no número de solicitações de chamada de entrada IVR.",
    "cms.report.field.avgCallInTimeField" : "Duração média de chamada",
    "cms.report.field.reportPeriod" : "Etapa estatística",
    "cms.monitordetail.field.innerforward" : "Chamadas transferidas internamente",
    "cms.agentmonitor.label.showAlarmSensitive" : "palavras sensíveis",
    "cms.report.field.manToManWaitAnsTime" : "Duração em espera da transferência manual para agente",
    "cms.uapmonitor.field.Logout" : "Sair de logon",
    "cms.agentmonitor.label.terminalStatusFix" : "Status de restauração",
    "cms.systemparam.error.validate.ruleinvalid" : "A regra de verificação é inválida",
    "cms.report.field.avgInRingTimeField" : "Duração média da chamada de entrada dos terminais",
    "cms.qualitycheck.label.save" : "Salvar",
    "cms.report.field.transferOutCountField" : "Tempos de transferência manual de saída",
    "cms.report.message.agentTalkingTimeDesc" : "Tempo de conversação da chamada.",
    "cms.report.message.IVRInSuccDesc" : "Número de chamadas de entrada IVR cuja duração da chamada é maior que 0.",
    "cms.report.field.muteCount" : "Tempos de silêncio",
    "cms.report.field.loginCount" : "Tempos de sessão",
    "cms.calllink.field.activeRelease" : "O assinante desliga uma chamada.",
    "cms.agentMonitor.label.nosignin_error" : "O agente inspecionado não entrou.",
    "cms.systemmonitor.field.systemInBound" : "Chamadas recebidas do sistema",
    "cms.report.message.lessThanError" : "A ID do agente final não pode ser menor que a ID do agente inicial.",
    "cms.voiceinspection.field.endTime" : "Tempo Final da gravação",
    "cms.report.field.agentHangNumCtiField" : "Desligamentos do agente",
    "cms.indexmonitor.field.agentInboundNum" : "Chamadas recebidas manuais",
    "cms.report.field.date" : "Data",
    "cms.calllink.title.beginTime" : "Hora Inicial das Estatísticas",
    "cms.indexmonitor.message.agentAbandonIn3NDesc" : "Número total de abandonos com duração de resposta inferior a 3 segundos inclusive",
    "cms.voiceinspection.field.preViewOutbound" : "Visualização de saída",
    "cms.vcallecenter.label.inputnewpwd" : "Digite a nova senha.",
    "cms.callcenterinstanceslist.title.sourceconfiguration" : "Configuração da Origem de Dados",
    "cms.systemparam.error.validate.notmatchrulemax" : "O parâmetro de entrada excede o máximo",
    "cms.report.field.agentOutSuccField" : "Chamadas de saída manuais conectadas",
    "cms.report.field.acwRateField" : "Taxa de duração do pós-atendimento",
    "cms.report.field.avgCallPerHourField" : "Média de chamadas manuais recebidas por hora",
    "cms.agentmonitor.label.monitorState" : "Status da inspeção",
    "cms.report.message.releaseCauseDesc" : "Motivo de liberação da chamada",
    "cms.indexmonitor.title.filteringIndicator" : "Indicador de filtro",
    "cms.synchronize.message.syncStatusOne" : "Sincronização",
    "cms.skilladjustment.message.tipsError" : "Falha ao ajustar a fila de habilidades.",
    "cms.calllink.title.callBeginTime" : "Hora de início da chamada",
    "cms.indexmonitor.field.abandonInThirty" : "Chamadas abandonadas em 30 s",
    "cms.calllink.field.timeoutTransfer" : "Uma chamada é transferida devido ao tempo limite.",
    "cms.fullscreen.field.reportDates" : "{0} dias atrás",
    "cms.indexmonitor.message.agentAbandonIn3Rate" : "Chamadas abandonadas em 3 segundos como porcentagem do total de abandonos",
    "cms.agentmonitor.label.agentStatus" : "Status do agente",
    "cms.report.field.failQueueWaitRateField" : "Duração média do toque das chamadas perdidas",
    "cms.callcenterinstanceslist.message.workNoCheckError" : "O número inicial da ID de trabalho deve ser menor que o número final.",
    "cms.indexmonitor.field.ccName" : "Nome da central de atendimento",
    "cms.agentmonitor.label.account" : "Conta de serviço",
    "cms.report.field.holdCount" : "Tempos de espera",
    "cms.report.title.callCenterReport" : "Relatório de tráfego de VDN",
    "cms.report.field.inWaitAnsNumField" : "Número de chamadas manuais",
    "cms.systemmonitor.field.queryTime" : "Tempo de consulta",
    "cms.agentmonitor.label.terminalStatus" : "Status do Registro do Terminal",
    "cms.agentMonitor.label.beinbusy_error" : "O agente inspecionado está ocupado.",
    "cms.calllink.field.ivrServiceProcess" : "Fluxo de negócios IVR",
    "cms.indexmonitor.message.skillAbandonInNDesc" : "Número de solicitações de chamada abandonadas manualmente em Ns. Atualmente, os Ns podem ser 3s, 5, 10, 20, 30s ou 60s.",
    "cms.skilladjustment.message.tipsNotMonitor" : "Não um especialista em QA. Falha ao ajustar a fila de habilidades.",
    "cms.report.message.dategt31days" : "O intervalo de datas não pode exceder 31 dias.",
    "cms.recorddrive.field.sftpPort" : "No da porta",
    "cms.indexmonitor.message.agentConnectedIn5NDesc" : "Número total de chamadas atendidas com duração inferior a 5 segundos inclusive",
    "cms.report.message.avgIVRInCountDesc" : "Número médio de chamadas recebidas simultâneas cujo dispositivo de serviço é o IVR.",
    "cms.callcenterinstanceslist.message.ccgatewayServerBatchdeleteconfim" : "Tem certeza de que deseja excluir todos os servidores CC-Gateway selecionados?",
    "cms.qcrelationconf.placeholder.workNo" : "Insira o ID do agente",
    "cms.agentmonitor.label.accountCode" : "Insira uma conta comercial",
    "cms.report.field.agentInSucc" : "Chamadas de entrada manuais conectadas",
    "cms.report.field.callBeginField" : "Hora de início da chamada",
    "cms.report.message.agentOutBoundDesc" : "Número de chamadas de saída manuais feitas pelos agentes.",
    "cms.fullscreen.message.selectAgentMax" : "Um máximo de 5 agentes podem ser selecionados.",
    "cms.report.message.workRateDesc" : "Taxa de duração do trabalho = (duração total do trabalho - Duração de repouso) / Duração total do trabalho x 100",
    "cms.report.message.IVRCallOutNumDesc" : "Número total de chamadas de saída IVR.",
    "cms.calllink.field.PGR" : "PGR (rota CDN padrão controlada pelo SCAI)",
    "cms.callcenterinstanceslist.field.close" : "Desativar",
    "cms.datasource.field.uidbDatabase" : "UIDB",
    "cms.calldetail.msg.callIdInvalid" : "O formato está incorreto",
    "cms.callcenterinstanceslist.field.uapPerfPort" : "Porta de monitoramento de desempenho UAP",
    "cms.report.message.manToSkillNumDesc" : "Número de transferências de fila manual para habilidade.",
    "cms.report.message.maxQueueOutCountDesc" : "Número máximo de chamadas de saída simultâneas cujo dispositivo de serviço é uma fila de habilidades.",
    "cms.callcenterinstanceslist.message.jobSwitch" : "Ativar sincronização automática",
    "cms.systemmonitor.field.ideaAgentNum" : "Agentes ociosos",
    "cms.vcallecenter.message.modifyadminpasswordfail" : "Falha ao alterar a senha.",
    "cms.callcenterinstanceslist.field.systemAdmin" : "ID do administrador de CTI",
    "cms.recorddrive.title.sftpServerConfig" : "Configuração do servidor SFTP",
    "cms.synchronize.message.invalidAdminPwd" : "Senha incorreta. Altere a senha do administrador.",
    "cms.report.field.sysLostCallsField" : "Chamadas recebidas falhadas",
    "cms.developerlist.message.createfailed" : "Falha ao criar o desenvolvedor.",
    "cms.report.field.outAnswerRateField" : "Taxa de conexão de chamada de saída",
    "cms.voiceinspection.message.frequentOperat" : "Operação muito freqüente, por favor, tente novamente mais tarde",
    "cms.voiceinspection.message.frequentDownload" : "O download é muito frequente. Tente novamente mais tarde.",
    "cms.indexmonitor.message.inboundConnectRate" : "Percentual do número de chamadas de entrada conectadas no número de chamadas de entrada",
    "cms.qcrelationconf.message.updateSuccess" : "Atualização de sucesso",
    "cms.report.field.outCallNumField" : "Chamadas externas",
    "cms.datasource.field.ok" : "OK",
    "cms.callcenter.field.deletefailure" : "Falha de exclusão",
    "cms.voiceinspection.message.invalidQcRelation" : "A relação de inspeção não corresponde.",
    "cms.agentmonitor.label.agentName" : "Nome do agente",
    "cms.export.message.exceedlimit2" : ". Reduza o intervalo de tempo de dados ou adicione critérios de filtro de dados.",
    "cms.report.field.inCallFailNum" : "Solicitações de Chamada Perdida",
    "cms.export.message.exceedlimit1" : "No máximo 0 registros de dados podem ser exportados. Reduza o intervalo de tempo de dados ou adicione critérios de filtro de dados.",
    "cms.fullscreen.field.edit" : "Modificar",
    "cms.report.field.description" : "Descrição",
    "cms.vcallcenter.message.unknowcause" : "Razão desconhecida",
    "cms.report.field.byMonth" : "Relatório mensal",
    "cms.calllink.msg.calleePlaceHolder" : "Insira o número chamado",
    "cms.datasource.field.no" : "Não",
    "cms.qcrelationconf.title.confirm" : "Conf.",
    "cms.calllink.field.noOnDutyTransfer" : "Uma chamada é transferida porque ninguém está de serviço.",
    "cms.indexmonitor.field.queueInCallCount" : "Número de chamadas de entrada em fila on-line",
    "cms.report.field.busyCountField" : "Períodos ocupados",
    "cms.report.field.IVRInSuccRate" : "Taxa de conexão de chamada de entrada IVR",
    "cms.qcrelationconf.field.qcCcRoleName" : "Função do Inspetor",
    "cms.indexmonitor.field.agentNum" : "Número de agentes",
    "cms.callcenterinstanceslist.message.clearKafkaConfig" : "Limpar informações de configuração do nó KAFKA",
    "cms.report.field.acdTimeField" : "Duração da chamada manual",
    "cms.report.field.acwCount" : "Tempos do pós-atendimento",
    "cms.callcenterinstanceslist.title.chooseMode" : "Selecionar modo",
    "cms.report.field.manToManWaitAnsTimeField" : "Duração de espera da transferência manual para agente",
    "cms.recorddrive.field.configSftpPath" : "Caminho do arquivo",
    "cms.callcenter.message.selectccgatewayservers" : "Selecione um servidor CC-Gateway.",
    "cms.fullscreen.field.themeType" : "Tipo de tema",
    "cms.developerlist.label.delete" : "Excluir",
    "cms.indexmonitor.field.totalTalkingNum" : "Agentes em chamada",
    "cms.vcallecenter.message.syncskillfail" : "Falha ao sincronizar as habilidades.",
    "cms.report.field.inCallSuccRateField" : "Taxa de conexão de chamada recebida",
    "cms.indexmonitor.message.ivrCallNum" : "Número de chamadas para as quais os serviços automáticos são fornecidos por dispositivos IVR",
    "cms.report.message.workTimeDesc" : "Duração total desde o momento em que um agente faz sessão até o momento em que o agente faz sessão.",
    "cms.agentmonitor.label.preoccupationState" : "preoccupied",
    "cms.report.message.acwOutTimeDesc" : "Duração de encerramento após o fim das chamadas de saída.",
    "cms.report.field.agentOutSuccRate" : "Taxa de conexão de chamada de saída manual",
    "cms.qualitycheck.label.input" : "Insira por favor",
    "cms.qualitycheck.title.saveError" : "Erro",
    "cms.report.message.acdCallsDesc" : "Número de chamadas de entrada e saída conectadas.",
    "cms.report.field.internalCallTime" : "Duração de chamadas internas",
    "cms.report.field.perAgentNum" : "Chamadas de agente",
    "cms.indexmonitor.message.ivrOutboundNum" : "Número de chamadas de saída IVR atendidas pela parte chamada",
    "cms.vcallecenter.title.modifyinfo2" : "Configurar senha",
    "cms.report.message.agentOutSuccRateDesc" : "Porcentagem de chamadas de saída manuais conectadas para o total de chamadas de saída manuais.",
    "cms.report.field.callAbandonedRingField" : "Número de perdas de chamadas recebidas manuais",
    "cms.fullscreen.message.numberSecondMin" : "O valor mínimo é 720",
    "cms.indexmonitor.message.ivrOutCallCount" : "Número de chamadas originadas cujo dispositivo de serviço atual é um IVR.",
    "cms.report.field.avgQueueOutCountField" : "Número médio de chamadas de saída simultâneas em uma fila",
    "cms.report.field.submediatype.sms" : "SMS",
    "cms.report.title.ivrOutboundMonitor" : "Monitoramento de chamadas de saída IVR",
    "cms.report.field.uvidField" : "Identificador de Acesso do Usuário",
    "cms.indexmonitor.message.skillOnlineWaitCallDesc" : "Número de chamadas que estão em fila.",
    "cms.recorddrive.field.sftpIpadress" : "Endereço IP SFTP",
    "cms.agentMonitor.label.querynum_success" : "Número de telefone:",
    "cms.voiceinspection.message.frequentExport" : "Exportação com muita freqüência. Tente novamente mais tarde.",
    "cms.indexmonitor.message.skillConnectedAfterNDesc" : "Número de solicitações de chamada conectadas manualmente após Ns. Atualmente, os Ns podem ser 10s ou 60s.",
    "cms.reportsubscribe.field.publish" : "Publicar",
    "cms.voiceinspection.field.colormedium" : "<=60S",
    "cms.report.title.callCenterTrend" : "Tendência de desempenho do VDN",
    "cms.fullscreen.field.text" : "Texto",
    "cms.uapmonitor.message.intervalvalidate" : "EDigite um número de 300 a 86400",
    "cms.report.field.outCallRateField" : "Taxa de conexão de chamada de saída",
    "cms.report.field.agentOffered" : "Chamadas recebidas manuais",
    "cms.systemparam.error.validate.inner" : "Validar parâmetro falhou",
    "cms.callcenterinstanceslist.field.kafkaUserName" : "Nome de usuário KAFKA",
    "cms.record-playback.callType0" : "Chamada de voz recebida",
    "cms.report.field.avgIVRInCount" : "Número Médio de Chamadas Recebidas IVR Simultâneas",
    "cms.report.field.maxQueueInCountField" : "Número máximo de chamadas de entrada simultâneas em uma fila",
    "cms.voiceinspection.field.callId" : "SN de gravação",
    "cms.callcenter.field.finish" : "Concluído",
    "cms.agentMonitor.label.cancelInsert_success" : "A inserção foi cancelada com sucesso.",
    "cms.report.message.call6RateDesc" : "Porcentagem do número de solicitações de chamada conectadas manualmente em 30 segundos no número total de chamadas que solicitam serviços manuais.",
    "cms.report.field.custHangNumCtiField" : "Desligamentos de clientes",
    "cms.report.message.otherTimeDesc" : "Duração total quando um agente está em outro estado, ou duração de chamada de outras habilidades quando existem múltiplas habilidades.",
    "cms.report.field.avgHoldTimeField" : "Duração média de espera",
    "cms.systemparam.error.validate.notmatchruleoption" : "O parâmetro de entrada não está no intervalo de enumeração",
    "cms.report.field.hourandminute" : "Time",
    "cms.systemparam.error.validate.notmatchruleregex" : "O parâmetro de entrada não corresponde à expressão regular da regra de verificação",
    "cms.report.message.avgIVROutCountDesc" : "Número médio de chamadas de saída simultâneas cujo dispositivo de serviço é o IVR.",
    "cms.report.field.acwOutTime" : "Duração de encerramento de chamadas de saída",
    "cms.developerlist.message.deletefailed" : "Falha na exclusão.",
    "cms.indexmonitor.field.skillConnectedIn60Rate" : "Chamadas conectadas em 60 s",
    "cms.report.field.inAvgRingTime" : "Duração média do toque de chamadas manuais de entrada",
    "cms.uapmonitor.field.callStatus" : "Status do registro",
    "cms.report.message.selectAgentMax" : "Um máximo de 100 IDs de agente pode ser selecionado.",
    "cms.vcallecenter.field.adminworkno" : "ID do administrador",
    "cms.report.field.currentSkillIdField" : "Número da Fila de Habilidades",
    "cms.export.field.exportEndTime" : "Hora final da exportação",
    "cms.indexmonitor.title.ivrNowMonitor" : "Monitoramento de execução de IVR em tempo real",
    "cms.qualitycheck.title.delete" : "Falha de exclusão",
    "cms.uapmonitor.labe.mentionnotice" : "2. Certifique-se de que o call center virtual contenha inspetores e que os inspetores tenham configurado o número do softphone e a senha de login. Somente agentes em estado anormal podem ser desconectados à força.",
    "cms.uapmonitor.labe.interval" : "Período de Monitorização",
    "cms.recorddrive.label.button_edit" : "Editar",
    "cms.report.field.assistTimeField" : "Duração da solicitação de ajuda interna",
    "cms.fullscreen.title.editChart" : "Modificar gráfico",
    "cms.indexmonitor.field.totalConnectCallNum" : "Total de chamadas conectadas",
    "cms.callcenterinstanceslist.label.recordDrive" : "Carta da unidade de gravação",
    "cms.report.message.agentOfferedDesc" : "Número total de chamadas recebidas que solicitam serviços de agente.",
    "cms.fullscreen.title.newChart" : "Criar gráfico",
    "cms.report.message.transferIvrCountDesc" : "Número de tempos de transferência manual para IVR.",
    "cms.report.field.callLostOtherNum" : "Outras chamadas perdidas",
    "cms.callcenterinstanceslist.title.baseInfo" : "Informações básicas",
    "cms.monitordetail.field.idlecount" : "O agente entra no estado inativo",
    "cms.indexmonitor.title.error" : "Erro",
    "cms.report.field.minQueueOutCountField" : "Número mínimo de chamadas de saída simultâneas em uma fila",
    "cms.recorddrive.label.button_cacel" : "Cancelar",
    "cms.fullscreen.message.errorMonitorMethod" : "Selecione um modo de monitoramento.",
    "cms.report.field.busyTimeRateField" : "Taxa de duração de ocupado",
    "cms.developerlist.message.deleteConfirmMessage" : "Tem certeza de que deseja excluir os desenvolvedores selecionados e respectivas configurações?",
    "cms.indexmonitor.field.totalRestNum" : "Agentes em estado de repouso",
    "cms.calllink.title.callType" : "Tipo de chamada",
    "cms.report.field.auxTime" : "Duração total de repouso",
    "cms.callcenter.field.savefailed" : "Erro",
    "cms.report.field.avgIVRInCountField" : "Número médio de chamadas IVR simultâneas",
    "cms.calllink.msg.logDateNull" : "O horário de gravação não pode ficar em branco.",
    "cms.export.message.parainvalid" : "Falha ao verificar as condições de exportação.",
    "cms.calllink.title.caller" : "Número chamador",
    "cms.indexmonitor.message.totalRestNum" : "Número de agentes em estado de repouso",
    "cms.report.field.IVROutCallNumField" : "Pure-IVR chamadas de saída",
    "cms.report.field.minIVROutCountField" : "Número mínimo de chamadas de saída simultâneas IVR",
    "cms.agent.message.modifypasswordfailed" : "Falha ao alterar a senha.",
    "cms.calllink.title.callee" : "Número chamado",
    "cms.datasource.field.dbType" : "Tipo de Origem de Dados",
    "cms.report.message.subMediaTypeDesc" : "Tipo de submídia",
    "cms.report.field.auxTimeRateField" : "Taxa de duração de repouso",
    "cms.reportsubscribe.title.modifyConfim" : "Modificar confirmar",
    "cms.report.field.systemInSucc" : "Chamadas recebidas bem-sucedidas",
    "cms.report.field.inCallFailNumField" : "Solicitações de Chamada Perdida",
    "cms.voiceinspection.field.videoClickToCall" : "Clique em vídeo para discar",
    "cms.indexmonitor.field.agentBoundConnectRate" : "Chamadas de entrada manuais conectadas",
    "cms.recorddrive.placeholder.batchdeleteconfim" : "Tem certeza de que deseja excluir todas as letras de unidade selecionadas?",
    "cms.report.message.IVROutSuccRateDesc" : "Porcentagem de chamadas de saída de IVR pura conectadas para o total de chamadas de saída de Pure-IVR.",
    "cms.callcenterinstanceslist.field.dvDataReport" : "Relatório de dados DV",
    "cms.indexmonitor.message.ivrInboundNum" : "Número de solicitações de chamada de entrada IVR cuja duração da chamada é maior que 0",
    "cms.agentmonitor.label.noAlarm" : "Nenhum alarme é gerado.",
    "cms.report.field.playVoiceCountField" : "Tempos de reprodução de voz",
    "cms.failurecall.field.userPhone" : "Número do serviço",
    "cms.interfaceperflog.message.modifyFail" : "Falha na modificação",
    "cms.report.field.custHangNumCti" : "Desligamentos de clientes",
    "cms.vdnconfiguration.message.updatefailed" : "Falha na atualização.",
    "cms.report.field.avgHandleTimeField" : "Duração média de processamento",
    "cms.report.field.outCallSuccNumberField" : "Chamadas de saída conectadas",
    "cms.qualitycheck.label.cancel" : "Cancelar",
    "cms.indexmonitor.message.errorInfo" : "Selecione pelo menos uma fila de habilidades.",
    "cms.voiceinspection.field.callTime" : "Duração da gravação",
    "cms.callcenterinstanceslist.message.uapPsdLengthRangeValidateFailed" : "O comprimento da senha deve estar entre 6 e 32",
    "cms.sysparam.config.reset.multiple.error" : "Os seguintes parâmetros não podem ser redefinidos:",
    "cms.fullscreen.field.monitorType" : "Tipo de indicador",
    "cms.indexmonitor.message.agentAbandonIn5NDesc" : "Número total de abandonos com duração de resposta inferior a 5 segundos inclusive",
    "cms.report.field.agentInBoundField" : "Chamadas de entrada manuais",
    "cms.synchronize.message.configured" : "Configurado",
    "cms.developerlist.field.ak" : "Chave de autenticação do Desenvolvedor",
    "cms.fullscreen.field.maxThreshold" : "Limite Máximo",
    "cms.fullscreen.title.deleteconfim" : "Conf.",
    "cms.indexmonitor.title.callCenterDayMonitor" : "Estatísticas de hoje sobre indicadores de call center",
    "cms.calllink.msg.beginOrEndTimeInvalid" : "O horário de início ou encerramento é inválido.",
    "cms.vcallecenter.title.modifyadministratorpassword" : "Senha do administrador de CTI",
    "cms.report.field.callInCauseField" : "Motivo de entrada da chamada",
    "cms.failurecall.field.callSequenceNumber" : "Chamada S / N",
    "cms.report.field.confirm" : "OK",
    "cms.callcenterinstanceslist.field.ccBcsPort" : "Porta LB CCBCS",
    "cms.report.field.avgIVROutCountField" : "Número médio de chamadas de saída simultâneas IVR",
    "cms.indexmonitor.message.totalAbandonedCallNum" : "Número total de chamadas – Número total de chamadas conectadas",
    "cms.report.field.minIVRCountField" : "Número mínimo de chamadas IVR simultâneas",
    "cms.callcenterinstanceslist.field.ccBmsIp" : "Endereço CCBMS LB IP",
    "cms.callcenterinstanceslist.title.nodemodeselect" : "Modo Seletor",
    "cms.report.message.outAvgAnsTimeDesc" : "Duração total do toque de chamadas efetuadas / número de tempos de atendimento",
    "cms.monitordetail.field.totaltalkingcount" : "Número total de chamadas",
    "cms.monitordetail.field.rest" : "Pausa",
    "cms.calllink.field.transferOut" : "Uma chamada é transferida para fora.",
    "cms.fullscreen.field.reportTime" : "Período de coleta",
    "cms.failurecall.placeholder.accessCodeInput" : "Insira um código de acesso",
    "cms.agentmonitor.label.talkingState" : "Em Atendimento",
    "cms.report.title.IVRTrend" : "Tendência de dados IVR",
    "cms.monitordetail.field.account" : "Conta",
    "cms.report.message.agentAlertingDurationDesc" : "Duração(ões) do alerta da chamada.",
    "cms.report.field.systemInBound" : "Chamadas recebidas",
    "cms.indexmonitor.field.ivrInboundNum" : "Chamadas de entrada IVR conectadas",
    "cms.report.field.calleeNo" : "Código de acesso",
    "cms.report.field.outSuccAnsRateField" : "Taxa de conexão de chamada de saída",
    "cms.synchronize.message.invalidConfPwd" : "Senha incorreta. Altere a senha da interface de configuração.",
    "cms.indexmonitor.message.agentOutboundConnectNum" : "Número de chamadas manuais de saída de que são atendidas pelas partes chamadas",
    "cms.agentMonitor.label.intercept_success" : "Sucesso na interceptação",
    "cms.indexmonitor.message.agentConnectIn5Rate" : "Conectividade manual em 5 segundos como porcentagem do total de chamadas",
    "cms.report.field.loginCountField" : "Tempos de sessão",
    "cms.systemmonitor.title.tips" : "Informações",
    "cms.report.title.callNo" : "número de chamada",
    "cms.indexmonitor.message.skillRequestDesc" : "Número de chamadas recebidas que solicitam serviços de usuário",
    "cms.report.message.holdCountDesc" : "Número de vezes de espera.",
    "cms.report.field.agentLoginNum" : "Agentes chamados",
    "cms.report.message.playVoiceCountDesc" : "Número de tempos de reprodução de voz.",
    "cms.datasource.message.already" : "O nome da fonte de dados já existe.",
    "cms.reportsubscribe.field.subscribeName" : "Nome da inscrição",
    "cms.indexmonitor.message.agentAbandonIn30NDesc" : "Número total de abandonos com duração de resposta inferior a 30 segundos inclusive",
    "cms.fullscreen.message.themeDuplicate" : "Nome duplicado.",
    "cms.callcenterinstanceslist.field.faultStatus" : "Falha",
    "cms.sysparam.config.itemname.tips" : "Insira um nome de parâmetro.",
    "cms.report.field.indicatorName" : "Indicador",
    "cms.report.field.callSuccRate" : "Taxa de conexão de solicitação",
    "cms.report.field.agentServiceRate" : "Taxa de sucesso manual em Total de chamadas de entrada",
    "cms.report.field.outAvgCallTimeField" : "Duração média da chamada originada manual",
    "cms.report.message.inCallRateDesc" : "Duração média da chamada. Duração média das chamadas de entrada manuais conectadas = Duração das chamadas de entrada manuais conectadas / Número de chamadas de entrada manuais conectadas",
    "cms.indexmonitor.message.warningFiltering" : "Selecione pelo menos um indicador",
    "cms.indexmonitor.message.skillAvgConnectedAckDesc" : "Duração média do toque de todas as chamadas conectadas em uma fila.",
    "cms.sysparam.config.item.detail" : "Informação do parâmetro",
    "cms.report.field.minAgentInCountField" : "Número mínimo de chamadas recebidas de agentes simultâneos",
    "cms.systemparam.error.validate.notmatchrulemaxlength" : "excede o comprimento máximo 0",
    "cms.report.title.agentMonitor" : "Monitoramento do resumo de desempenho do agente",
    "cms.report.message.callTypeDesc" : "Tipo de chamada",
    "cms.agent.message.modifypasswordsuccess" : "A senha foi alterada com sucesso.",
    "cms.report.field.totalLIVRServiceField" : "Tempo total de serviço IVR",
    "cms.indexmonitor.field.skillConnectedIn5Rate" : "Chamadas conectadas em 5 s",
    "cms.indexmonitor.field.inboundConnectNum" : "Chamadas de entrada conectadas",
    "cms.report.field.inAnswerRate" : "Taxa de conexão de chamada recebida",
    "cms.callcenterinstanceslist.field.normalStatus" : "Normal",
    "cms.report.message.callLostOtherNumDesc" : "Número de chamadas que não são atendidas devido a outros motivos que não linhas ocupadas, rejeição de chamadas e tempo limite de toque.",
    "cms.export.field.unprocessed" : "Não processadas",
    "cms.report.field.playVoiceTime" : "Duração da reprodução de voz",
    "cms.qualitycheck.message.deleteinfo" : "Falha ao excluir",
    "cms.skilladjustment.message.tipsSuccess" : "A fila de habilidades foi ajustada com sucesso.",
    "cms.callcenterinstanceslist.field.cmsPort" : "Porta de LB do CMS",
    "cms.export.field.success" : "Sucesso",
    "cms.indexmonitor.field.agentConnectAfterInTen" : "Chamadas conectadas manualmente depois de 10 s",
    "cms.agentmonitor.label.busy" : "Forçar ocupado",
    "cms.report.message.callAbandonedRingDesc" : "Perda de chamadas manuais = Número de chamadas manuais - Número de chamadas manuais conectadas",
    "cms.qcrelationconf.placeholder.groupName" : "Nome do grupo",
    "cms.report.field.IVRCallOutNumField" : "Chamadas de saída do IVR",
    "cms.report.message.selectAgent" : "Selecione pelo menos um ID de agente.",
    "cms.report.field.noAckNumField" : "Chamadas não atendidas",
    "cms.report.message.weekLessThan" : "A semana do ano final não pode ser menor que a semana do ano inicial.",
    "cms.report.field.inAvgCallTime" : "Duração média de chamada",
    "cms.agentMonitor.laber.hasbeenCheck" : "O agente foi inspecionado.",
    "cms.agentMonitor.laber.hasbeenMediaCheck": "O agente foi monitorado por uma sessão multimídia",
    "cms.agentMonitor.laber.stopMediaMonitor": "Monitoramento de sessão multimídia interrompido",
    "cms.reportsubscribe.message.searchTemplateName" : "Insira o nome do modelo",
    "cms.indexmonitor.message.agentConnectedIn10NDesc" : "Número total de chamadas atendidas com duração inferior a 10 segundos inclusive",
    "cms.report.field.manToSatisNumCtiField" : "Transferências de pesquisa manual para satisfação",
    "cms.report.message.maxAgentCountDesc" : "Número máximo de chamadas simultâneas cujo dispositivo de serviço é um agente.",
    "cms.indexmonitor.message.agentInCallCount" : "NNúmero de chamadas recebidas atendidas.(Não contém sessões multimídia)",
    "cms.indexmonitor.label.search" : "Pesquisar",
    "cms.fullscreen.field.pie" : "Gráfico pizza",
    "cms.report.field.preDeviceNoField" : "Identificação do dispositivo do último dispositivo",
    "cms.report.field.skillIdField" : "ID da fila de habilidades à qual a chamada pertence",
    "cms.agentmonitor.label.intercept" : "intercepção",
    "cms.datasource.field.dbPwd" : "Palavra-passe",
    "cms.report.title.changeChart" : "Alternar para gráfico",
    "cms.qcrelationconf.message.selectGroupType" : "Selecione primeiro o tipo de grupo de usuários",
    "cms.report.title.IVRAccessMonitor" : "Monitorando estatísticas sobre dados IVR por Código de Acesso",
    "cms.voiceinspection.field.clickToCall" : "Clique de voz para discar",
    "cms.indexmonitor.field.skillAvgConnectedQueue" : "Duração média da fila de chamadas conectadas",
    "cms.report.message.uapIdDesc" : "Número do UAP que conecta a chamada à plataforma do call center",
    "cms.monitordetail.field.holddurationavg" : "Duração média de espera",
    "cms.calllink.field.callRejected" : "A parte chamada rejeita uma chamada.",
    "cms.recorddrive.title.sftpIdentityCheck" : "Verificação de identidade",
    "cms.report.field.tableHeader" : "Cabeçalho da tabela",
    "cms.qcrelationconf.field.search" : "Consulta",
    "cms.report.title.ivrOutboundReport" : "Relatório de chamada de saída IVR",
    "cms.agentmonitor.label.alarmState" : "Estado do alarme",
    "cms.indexmonitor.message.skillIdMaxSize" : "Um máximo de cinco filas de habilidade podem ser selecionadas.",
    "cms.report.message.failAvgTimeDesc" : "Duração média do toque de todas as chamadas não atendidas.",
    "cms.failurecall.placeholder.userPhoneInput" : "Insira um número de serviço",
    "cms.fullscreen.field.oneHour" : "1 hora",
    "cms.report.message.monthRangeCheck" : "As horas de início e de fim devem ser no mesmo mês",
    "cms.callcenterinstanceslist.field.cmsAuthPassword" : "Senha de autenticação CCDIS",
    "cms.report.field.inOccupyNumField" : "Solicitações de serviço",
    "cms.report.field.avgQueueCount" : "Número Médio De Chamadas Concorrentes Em Uma Fila",
    "cms.callcenterinstanceslist.field.operate" : "Operação",
    "cms.callcenterinstanceslist.field.kafkaPushSwitch" : "KAFKA Dados Empurrando",
    "cms.report.message.leaveReasonDesc" : "Motivo da chamada para sair do dispositivo",
    "cms.recorddrive.message.sftpIdentityChecktips" : "A conta ou senha do servidor SFTP foi alterada. Para manter a segurança das informações, insira sua conta atual e senha.",
    "cms.calllink.field.noResources" : "O ACD não tem recursos disponíveis.",
    "cms.indexmonitor.message.totalLogoutNum" : "Número de agentes que não fizeram login no call center e estão em estado off-line",
    "cms.report.message.IVRInSuccRateDesc" : "Porcentagem de chamadas de entrada de IVR conectadas para o total de chamadas de entrada de IVR.",
    "cms.report.message.exportFailed" : "Falha na exportação.",
    "cms.report.message.avgAcwTimeDesc" : "Duração média quando um agente está em estado de envolvimento. Duração média de encerramento = Duração total de encerramento / Número de tempos de encerramento",
    "cms.indexmonitor.message.mediaTypeMetion" : "O item de filtro de tipo de mídia é válido apenas para KPIs do dia atual e tendência do dia atual.",
    "cms.fullscreen.message.numberFirstMin" : "O valor mínimo é 1280",
    "cms.sendnote.message.tipsError" : "Falha ao enviar uma nota.",
    "cms.qcrelationconf.field.accountCode" : "Conta de serviço",
    "cms.export.message.popwindowmessage" : "Digite a senha usada para gerar o arquivo compactado. A senha compactada deve conter de 8-12 caracteres , conter letras , números , caracteres especiais , caracteres especiais excluindo {'|;&$-<>/'}{'\\\\'}{'`!#(){}'} espaços, caracteres de nova linha e tabulações",
    "cms.failurecall.message.currentFailureCall" : "Há",
    "cms.datasource.title.addDatasource" : "Adicionando uma Fonte de Dados",
    "cms.monitordetail.field.restcount" : "Descanso",
    "cms.report.field.stateField" : "Estado da conexão",
    "cms.indexmonitor.message.totalInCallCount" : "Número total de chamadas recebidas.(Não contém sessões multimídia)",
    "cms.report.field.avgAnsTimeField" : "Duração média da fila de chamadas conectadas",
    "cms.report.field.maxQueueInCount" : "Número Máximo de Chamadas Recebidas Simultâneas em uma Fila",
    "cms.datasource.message.updatefailed" : "Falha na atualização.",
    "cms.indexmonitor.message.abandonInSixty" : "Percentual do número de chamadas abandonadas dentro de 60s no número total de chamadas de abandonadas",
    "cms.callcenterinstanceslist.field.kafkaPasswordConfirm" : "Confirmar palavra-passe KAFKA",
    "cms.systemparam.error.reset" : "Falha ao redefinir parâmetros",
    "cms.agentMonitor.label.listen_success" : "A escuta foi bem-sucedida.",
    "cms.recorddrive.field.configSftpAccountPwd" : "Senha do servidor SFTP",
    "cms.uapmonitor.field.callIpPort" : "IP e porta",
    "cms.synchronize.message.adminPwdStatus" : "Senha do administrador",
    "cms.fullscreen.message.addTheme" : "Adicionar assunto.",
    "cms.agentMonitor.label.forcebusy_repeat" : "O agente está ocupado, por favor, não faça isso novamente",
    "cms.report.message.maxQueueCountDesc" : "Número máximo de chamadas simultâneas cujo dispositivo de serviço é uma fila de habilidade.",
    "cms.report.field.agentId" : "ID do agente",
    "cms.report.message.inOccupyNumDesc" : "Número de chamadas recebidas que solicitam serviços manuais.",
    "cms.report.field.minQueueCount" : "Número mínimo de chamadas simultâneas em uma fila",
    "cms.datasource.message.isDeleteDatasource" : "Tem certeza de que deseja excluir a fonte de dados.",
    "cms.report.message.userNoAnswerNumDesc" : "Número de chamadas que não são atendidas devido ao tempo limite de toque.",
    "cms.report.field.alertingTimeField" : "Horário do Alerta de Chamada",
    "cms.agentmonitor.label.cancellistening" : "Cancelar escuta",
    "cms.synchronize.message.syncStatusTwo" : "Sincronização bem-sucedida.",
    "cms.qualitycheck.field.selected" : "Selecionado",
    "cms.export.field.exportBeginTime" : "Hora de início da exportação",
    "cms.report.message.acwOutCountDesc" : "Número de vezes que um agente entra no estado de encerramento após o término das chamadas de saída.",
    "cms.reportsubscribe.field.wednesday" : "Quarta",
    "cms.report.field.startTime" : "Data Inicial",
    "cms.datasource.field.dbServiceType" : "Tipo de serviço",
    "cms.report.message.callerNoDesc" : "Número de chamada da chamada",
    "cms.indexmonitor.title.ivrDayTrend" : "Tendência de hoje do IVR",
    "cms.qualitycheck.label.userGroup" : "Grupo de Usuários",
    "cms.vcallecenter.message.configurationinterfacenopassword" : "A Senha de Configuração não está configurada.",
    "cms.report.message.avgAcdTimeDesc" : "Duração média da chamada de um agente. Duração média da chamada manual = Duração da chamada manual / Número de chamadas manuais bem-sucedidas",
    "cms.indexmonitor.field.inboundConnectRate" : "Chamadas de entrada conectadas",
    "cms.indexmonitor.message.ivrInboundFlowOut" : "Número de chamadas transferidas para outro dispositivo após o IVR reproduzir um anúncio",
    "cms.indexmonitor.field.indicatorName" : "Indicador",
    "cms.report.field.onlyIVRServiceField" : "Chamadas de entrada Pure-IVR bem-sucedidas",
    "cms.report.message.deviceTypeDesc" : "Tipo de dispositivo que trata a chamada",
    "cms.uapmonitor.labe.inspectorcheckinnum" : "Número de entrada do inspetor do QC",
    "cms.fullscreen.field.dayMonitor" : "Estatísticas do Indicador de hoje",
    "cms.fullscreen.field.fonts" : "Fonte",
    "cms.report.field.talkingTimeField" : "Tempo de conversação da chamada",
    "cms.report.field.IVRCallOutSuccNum" : "Chamadas de saída de IVR conectadas",
    "cms.calllink.title.deviceNo" : "ID do dispositivo",
    "cms.report.message.ackEndDesc" : "Hora final do atendimento da chamada, que é a hora final do atendimento da chamada no dispositivo atual",
    "cms.vcallecenter.title.authentication" : "Verificar identidade",
    "cms.callcenterinstanceslist.label.delete" : "Excluir",
    "cms.qcrelationconf.field.groupName" : "Nome do grupo de usuários",
    "cms.report.field.onlineRate" : "Taxa online",
    "cms.indexmonitor.field.skillOverAgent" : "chamadas overflow para agentes",
    "cms.indexmonitor.message.totalLoginNum" : "Número de agentes que fizeram login no call center",
    "cms.developerlist.message.refreshsuccess" : "A SK foi atualizada com sucesso.",
    "cms.indexmonitor.message.agentConnectAfterInTen" : "Percentual do número de chamadas de conectadas manualmente após 10s no número total de chamadas de conectadas manualmente",
    "cms.report.title.callCenterAccessTrend" : "Tendência do desempenho de VDN por código de acesso",
    "cms.callcenterinstanceslist.field.gwIp" : "Endereço IP do CC-Gateway",
    "cms.report.field.IVRInBound" : "Chamadas de entrada IVR",
    "cms.indexmonitor.field.agentInboundConnectNum" : "Chamadas de entrada manuais conectadas",
    "cms.systemmonitor.field.ofcNo" : "Número recebido",
    "cms.vcallecenter.resetpwd.message.fail" : "Falha ao redefinir a senha.",
    "cms.report.field.outCallRate" : "Taxa de conexão de chamada de saída",
    "cms.report.title.vdnTrafficPeriodReport" : "Relatório de tráfego VDN baseado em intervalos",
    "cms.indexmonitor.label.next" : "Próximo",
    "cms.indexmonitor.field.skillConnectedAfter60" : "Chamadas conectadas após 60 s",
    "cms.vdnconfiguration.title.success" : "Sucesso",
    "cms.fullscreen.message.addLayout" : "Falha ao adicionar a tela porque a tela está cheia.",
    "cms.fullscreen.field.report1" : "Relatório diário (horário fixo)",
    "cms.fullscreen.field.report0" : "Relatório diário (tempo relativo)",
    "cms.report.message.sysSuccRateDesc" : "Porcentagem do número de chamadas recebidas bem-sucedidas no número total de chamadas recebidas.",
    "cms.developerlist.label.refreshsk" : "Atualizar SK",
    "cms.agentmonitor.label.cancelfail" : "Falha no cancelamento.",
    "cms.report.title.dualCallDetail" : "Detalhe da chamada dupla",
    "cms.indexmonitor.message.ivrDurationAvgConnect" : "Duração total de chamadas IVR / Número de chamadas IVR",
    "cms.indexmonitor.message.skillLimit": "Selecione de 1 a 10 filas de habilidades.",
    "cms.report.message.avgInRingTimeDesc" : "Duração média da chamada de entrada através de terminais do agente.",
    "cms.report.field.acwOutCountField" : "Tempos de encerramento de chamadas de saída",
    "cms.report.field.waitAnsRate" : "Duração média do toque de chamadas manuais de entrada",
    "cms.monitordetail.field.busy" : "Ocupado",
    "cms.uapmonitor.field.socketFault" : "falha",
    "cms.report.field.logoutCountField" : "Horários de sessão",
    "cms.skilladjustment.field.skillname" : "Nome da fila de habilidades",
    "cms.report.title.filteringIndicator" : "Indicador de filtro",
    "cms.report.field.maxQueueOutCountField" : "Número máximo de chamadas de saída simultâneas em uma fila",
    "cms.uapmonitor.labe.openmonitor" : "Ativar o monitoramento",
    "cms.callcenterinstanceslist.message.ccIdError" : "O número do call center foi usado.",
    "cms.callcenter.message.updatesuccess" : "Atualizado com sucesso.",
    "cms.fullscreen.field.avgCallTimeDesc" : "Duração média da chamada das chamadas conectadas de um agente.",
    "cms.agentMonitor.message.operaFailed" : "OA operação falhou. O QC ou chamada está em andamento.",
    "cms.report.message.errorInfo" : "Falha ao salvar os dados. Selecione pelo menos um indicador.",
    "cms.report.message.dateCheck.latest30day" : "Somente os dados dos últimos 30 dias podem ser consultados.",
    "cms.agentMonitor.message.operaFailedAgent" : "A operação falhou. A verificar a qualidade do agente",
    "cms.indexmonitor.title.skillDayTrend" : "Tendência de hoje da fila de habilidades",
    "cms.callcenterinstanceslist.field.interfacePassword" : "Configurar senha de interface",
    "cms.agentMonitor.label.forcebusy_success_calling" : "Um agente é definido à força para o estado ocupado com sucesso. Após o término da chamada, o agente entra no estado ocupado.",
    "cms.report.message.exportSucceed" : "Exportação bem-sucedida.",
    "cms.indexmonitor.field.totalLoginNum" : "Agentes de login",
    "cms.fullscreen.message.deleteconfim" : "Deseja confirmar a exclusão deste tópico?",
    "cms.calllind.title.deviceDescIvr" : "Descrição do dispositivo (Código de Acesso ao Processo IVR)",
    "cms.sendnote.message.tipsNoteContent" : "Insira as informações da nota.",
    "cms.uapmonitor.field.callSip" : "Identificador do dispositivo terminal",
    "cms.report.title.agentOutCallMonitor" : "Monitoramento do resumo de chamadas efetuadas do agente",
    "cms.vdnconfiguration.field.ok" : "OK",
    "cms.report.field.outAvgAnsTime" : "Duração Média de toque de chamadas originadas",
    "cms.indexmonitor.field.agentName" : "Nome do tenant",
    "cms.callcenterinstanceslist.field.kafkaIp" : "Endereço IP KAFKA",
    "cms.indexmonitor.title.vdnNowMonitor" : "Estatísticas de monitoramento em tempo real do VDN durante a execução",
    "cms.report.message.agentLoginNumDesc" : "Número de agentes que recebem chamadas de entrada.",
    "cms.agentMonitor.label.cancelwhisper_error" : "Falha ao cancelar o sussurro.",
    "cms.agentmonitor.label.jobState" : "Estado de trabalho",
    "cms.indexmonitor.field.allType" : "Todos os tipos",
    "cms.monitordetail.field.sipNumber" : "Chamada de início de sessão",
    "cms.indexmonitor.field.skillConnectedAfter10" : "Chamadas conectadas após 10 s",
    "cms.report.field.call4RateField" : "Taxa de conexão recebida de entrada manual em 20s",
    "cms.report.message.IVRInBoundDesc" : "Número total de chamadas recebidas feitas usando a IVR.",
    "cms.report.field.enterReasonField" : "Motivo para entrar no dispositivo de chamada",
    "cms.indexmonitor.field.totalLearningNum" : "Agentes em estado de aprendizagem",
    "cms.vcallecenter.label.renewpwd" : "Confirmar senha",
    "cms.report.field.IVRShortCallsField" : "Número de chamadas curtas IVR (dentro de 3s)",
    "cms.report.message.inputZipPassword": "Digite a senha de compactação",
    "cms.report.task.sucess.export": "Uma tarefa de exportação foi criada. Tem certeza de que deseja visualizá-lo na lista de exportação?",
    "cms.report.task.export.records": "Exportando registros de contato",
    "cms.report.field.length.limit": "O valor deve ser um número com no máximo 0 caracteres.",
    "cms.report.field.number": "Número inválido",
    "cms.report.field.view.export.task": "Exibir Tarefas de Exportação",
    "cms.report.field.enter.callin": "Insira o número de série da chamada recebida",
    "cms.agentmonitor.label.agenttotal"  : "Total de assentos",
    "cms.agent.lable.alarm"    : "Alerta",
    "cms.agent.label.agentlist" : "Lista de agentes",
    "cms.agent.label.agentdetailInfo" : "Informações de detalhes do agente",
    "cms.agent.label.enterdetail"  : "Ver detalhes",
    "cms.agent.label.statetime" : "Duração do estado",
    "cms.agent.label.duracount" : "Estatísticas de duração",
    "cms.agent.label.numcount" : "Estatísticas de Tempo",
    "cms.agent.label.agentname" : "Nome do agente",
    "cms.agent.label.logintime"  : "Duração on-line",
    "cms.agent.label.idlenum"  : "Tempos ociosos",
    "cms.agent.label.arrangetime" : "Duração da limpeza",
    "cms.agent.label.logouttime" : "Duração off-line",
    "cms.agent.label.alarmstate" : "Status do Alarme",
    "cms.agent.label.watchdetail" : "Ver detalhes",
    "cms.agent.label.agentDetail" : "Detalhes do agente",
    "cms.input.format.invalid": "Formato de entrada incorreto",
    "cms.normal.text.validate": "Somente números, letras e -._ {'@'} são permitidos",
    "cms.calllind.field.deviceUnkown" :"Dispositivo desconhecido",
    "cms.agent.label.signinphone": "Número do check-in",
    "cms.agent.label.currentagentstate" : "Status atual do agente",
    "cms.agent.label.currentstatetime": "Duração do estado atual",
    "cms.agent.label.choosestatetime": "Selecionar Estado Duração Mínima",
    "cms.agent.label.busyitem":"Ocupado",
    "cms.agent.label.talkitem":"Chamada",
    "cms.agent.label.online":"em linha",
    "cms.agent.label.offline":"Off-line",
    "cms.agent.label.callinrate": "Taxa de Conclusão de Chamadas de Entrada",
    "cms.agent.label.callintime": "Duração da chamada recebida",
    "cms.agent.label.avgcallintime":"Duração média das chamadas recebidas",
    "cms.agent.label.avgwaittime": "Tempo médio de espera para chamadas recebidas",
    "cms.agent.label.maxwaittime":"Tempo máximo de espera para chamadas recebidas",
    "cms.agent.label.calloutsuccrate":"Taxa de Conclusão de Chamadas de Saída",
    "cms.agent.label.callouttime": "Duração da chamada de saída",
    "cms.agent.label.avgcallouttime":"Duração média das chamadas de saída",
    "cms.agent.label.choosesybmediatype":"Selecione o tipo de canal",
    "cms.agent.label.busytime":"Duração do Ocupado",
    "cms.agent.label.avgbusytime":"Comprimento médio",
    "cms.agent.label.acgtalktime":"Duração média da chamada",
    "cms.agent.label.outresttime":"Tempo limite de descanso",
    "cms.agent.label.totaltalktime":"Duração total da chamada",
    "cms.agent.label.redirtnum":"Redirecionamentos",
    "cms.agent.label.totaltalknums":"Total de chamadas",
    "cms.agentmonitor.label.dayStatistic": "Estatísticas de hoje",
    "cms.agentmonitor.label.countAndTime": "Tempos/Duração",
    "cms.agentmonitor.label.Performance": "Desempenho",
    "cms.agentmonitor.label.signInDetail": "Detalhes do check-in",
  "cms.agentmonitor.syncScreenMonitor.getVrpIpError": "O agente inspecionado não está conectado ao cliente VRC",
  "cms.agentmonitor.syncScreenMonitor.agentStatus": "Faça login antes de monitorar",
    "cms.agentmonitor.label.idleStatistic": "Disponível",
    "cms.agent.tips.selectstatetime": "Selecione a duração do status, selecione o status atual",
    "cms.agent.label.selectstatetime":">=Duração do estado(minutos)",
    "cms.agent.tips.sipaccountcheck":"Selecione 1 a 32 dígitos como número de check-in",
    "cms.indexmonitor.field.totalAgentConnectCallTime": "Duração da chamada",
    "cms.indexmonitor.title.agentDate":"Dados do agente",
    "cms.agent.tips.notassined":"Não Atribuído",
    "cms.indexmonitor.set.limitA": "Select",
    "cms.indexmonitor.set.limitB": "indicadores para exibição.",
    "cms.indexmonitor.titile.indicatorSetting": 'Configuração do Indicador',
    "cms.indexmonitor.realTime.indicator": "Métricas em tempo real",
    "cms.indexmonitor.index.multimediaCalls": "Multimídia",
    "cms.indexmonitor.index.callTrends": "Tendências de chamadas",
    "cms.indexmonitor.index.skillQueueServiceLevel": "Visão Geral do Nível de Serviço da Fila de Habilidades",
    "cms.indexmonitor.index.met": "Conseguir",
    "cms.indexmonitor.index.missed": "Não alcançado",
    "cms.incoming.record.not.sameday":"A consulta entre dias não é suportada",
    "cms.requiredcallback.message.allowTimeRange":"Consulta de dados em até 7 dias ",
    "cms.incoming.record.field.releasecause":"Causa da liberação da chamada ",
    "cms.incoming.record.field.releasecause.input":"Por favor, insira o motivo da liberação da chamada ",
    "cms.incoming.record.field.releasecause.id":"Digite o código do motivo da liberação da chamada ",
    "cms.incoming.record.field.releasecause.causecode":"Digite o código de mapeamento do motivo ",
    "cms.incoming.record.field.ifReqManual":"Se a mão-de-obra foi solicitada ",
    "cms.incoming.record.field.isReqManual":"Mão-de-obra solicitada ",
    "cms.incoming.record.field.notReqManual":"Mão-de-obra não solicitada ",
    "cms.incoming.record.field.ifAnswerCall":"Atender manualmente ",
    "cms.incoming.record.field.isAnswerCall":"Atenção manual ",
    "cms.incoming.record.field.notAnswerCall":"O operador não atendeu ",
    "cms.incoming.record.field.releasecause.choose":"Selecione o motivo da liberação da chamada ",
    "cms.incoming.record.field.release.id":"Código do motivo da liberação da chamada ",
    "cms.incoming.record.field.release.causecode":"Código de mapeamento de motivo ",
    "cms.incoming.releasecause.causecode.tip":"Código de mapeamento de motivo fora do intervalo (0 ~ 45) ",
    "cms.incoming.record.report.beginTime":"Hora de início da consulta ",
    "cms.incoming.record.report.endTime":"Hora final da consulta ",
    "cms.requiredcallback.message.timeRangeCheck":"O intervalo de tempo é o mesmo dia ",
    "cms.failurecall.field.waitTime.export":"Duração de espera (s) ",
    "cms.failurecall.field.waitBegin":"Hora de início da chamada em espera ",
    "cms.failurecall.field.ackBegin":"Hora de início do atendimento de chamadas ",
    "cms.failurecall.field.waitAnsNum":"Números de espera por resposta ",
    "cms.failurecall.field.waitAnsTime":"Duração de espera por resposta (segundos) ",
    "cms.failurecall.field.succWaitAnsTime":"Duração de resposta de chamada bem-sucedida (segundos) ",
    "cms.failurecall.field.noAckNum":"Números de chamadas atendidas ",
    "cms.failurecall.field.succQueueWaitTime":"Duração da fila de chamadas bem-sucedidas (segundos) ",
    "cms.failurecall.field.succQueueWaitNum":"Números de chamadas bem-sucedidas na fila ",
    "cms.failurecall.field.failQueueWaitNum":"Duração de espera de perda de chamada (segundos) ",
    "cms.failurecall.field.inOccupyNum":"Número de solicitações manuais ",
    "cms.failurecall.field.inCallSuccNum":"Número de chamadas manuais ",
    "cms.failurecall.field.inCallTime":"Duração da chamada manual (segundos) ",
    "cms.failurecall.field.queOverToIvrNum":"Número de chamadas de fila para IVR ",
    "cms.failurecall.field.queOverToIvrTime":"Tempo total de desligamento da fila para IVR (segundos) ",
    "cms.failurecall.field.hangUpNum":"Números de conversão de IVR ",
    "cms.failurecall.field.hangUpTime":"Tempo total de conversão manual para IVR ",
    "cms.failurecall.field.hangUpReleaseNum":"Número de encerramentos de chamada de IVR ",
    "cms.indexmonitor.index.noTrafficData": "Sem dados de tráfego",
    "cms.indexmonitor.index.SkillQueueTips": "O nível de serviço da fila de habilidades é medido pela taxa de conclusão em 20 segundos. O nível de serviço esperado padrão é de 80% e pode ser modificado pelo administrador do locatário na configuração de parâmetros do locatário: Parâmetros do locatário > Monitoramento do call center > Monitoramento de métricas > Nível de serviço esperado da fila de habilidades.",
    "cms.indexmonitor.index.SkillQueueTips2": "O nível de serviço da fila de habilidades é medido em 20 segundos. O nível de serviço esperado padrão é de 80% e pode ser modificado pelo administrador do sistema em Parâmetros do Sistema > Monitoramento do Call Center > Monitoramento de Métricas > Nível de Serviço Esperado da Fila de Habilidades.",
    "cms.indexmonitor.index.loadFail": "Falha ao carregar",
    "cms.failurecall.queue.abandon":"Enfileirar para abandonar a consulta",
    "cms.failurecall.callback":"Consulta de registro de retorno de chamada",
    "cms.faliurecall.incomingcall":"Consulta de registros de chamadas recebidas",
    "cms.vcallecenter.message.checkpasswordfailed": "Falha na verificação da regra de senha de check-in",
    "cms.agent.field.admin":"Administrador",
    "cms.agent.field.commonagent":"Operador",
    "cms.agent.field.qualitychecker":"Inspetor de qualidade",
    "cms.failure.callerNo.maxLength.error":"São permitidos no máximo 64 caracteres.",
    "cms.report.message.nowTimeCheck": "O tempo excede o tempo atual",
    "cms.indexmonitor.curDay.indicator":"Indicadores do dia",
    "cms.custdata.operlog.popwindowmessage":"Digite a senha usada para gerar o arquivo compactado. A senha compactada deve conter letras, números e caracteres especiais",

  "cms.report.title.submediaType" : "Tipo de multimídia",
  "cms.multimedia.monitor.field.callNum":"Número de chamadas IVR",
  "cms.multimedia.monitor.field.transNum":"Número de chamadas IVR-para-manuais",
  "cms.multimedia.monitor.field.transRate":"Taxa IVR-para-Manual",
  "cms.multimedia.monitor.field.connectedNum":"Número de chamadas IVR conectadas",
  "cms.multimedia.monitor.field.connectedRate":"Taxa de conclusão de chamadas IVR",
  "cms.multimedia.monitor.field.durationAverage":"Duração média da chamada IVR",
  "cms.multimedia.monitor.field.callNum.desc":"Número de chamadas que atualmente são atendidas automaticamente pelo dispositivo IVR",
  "cms.multimedia.monitor.field.transNum.desc":"Número de chamadas IVR-para-manuais",
  "cms.multimedia.monitor.field.transRate.desc":"Percentual de chamadas IVR-para-manuais para chamadas IVR",
  "cms.multimedia.monitor.field.connectedNum.desc":"Número de chamadas IVR cuja duração da chamada é maior que 0",
  "cms.multimedia.monitor.field.connectedRate.desc":"Percentual do número de chamadas IVR conectadas para o número de chamadas IVR",
  "cms.multimedia.monitor.field.durationAverage.desc":"Duração total da chamada IVR/Número de chamadas IVR conectadas",
  "cms.report.field.submediatype.instagram": "Instagram",
  "cms.report.field.submediatype.telegram": "Telegram",
  "cms.qcrelationconf.field.attendantGroupName": "Grupo Monitorado",
  "cms.report.field.selectTrafficGroup": "Selecionar Grupo de Atendentes",
  "cms.report.placeholder.attendantGroupName": "Digite o nome do grupo de monitorado.",
  "cms.qcrelationconf.field.attendantGroup": "Grupo Monitorado",
  "cms.report.message.selectTrafficGroupMax": "Um grupo máximo de 1000 atendentes pode ser selecionado.",
  "cms.agentMonitor.message.operatorChecked": "Falha na operação. O objeto inspecionado é um inspetor e está executando uma operação de inspeção",
  "cms.export.message.required": "Este campo é obrigatório.",
  "cms.field.length.limit": "O comprimento não pode exceder 0",
  "cms.sysparameter.batchreset": "Redefinição em massa",
  "cms.sysparameter.batchrefresh": "Sincronização em lote",
  "cms.report.subscribe.add": "Nova Assinatura de Relatório",
  "cms.report.subscribe.edit": "Editar Assinaturas de Relatório",
  "cms.qcrelationconf.tab.member": "Membros",
  "cms.qcrelationconf.add.attendantGroup": "Adicionar um grupo de atendimento inspecionado",
  "cms.qcrelationconf.message.isDeleteTrafficGroup": "Por favor, confirme se deseja excluir o grupo de atendimento",
  "cms.report.subscribe.checkreportnum": "Selecione pelo menos um relatório",
  "cms.sysparameter.edit.basic.information": "Editar informações básicas",
  "cms.sysparameter.edit.character": "Editar propriedades",
  "cms.sysparameter.edit.validate.ip": "Digite um endereço IP correto.",
  "cms.message.choose": "Selecione os dados.",
  "cms.subscribe.template.isused": "La plantilla de correo electrónico ya está en uso",
  "cms.subscribe.template.statuserror": "El estado de la plantilla de correo electrónico es incorrecto.",
  "cms.qcrelationconf.message.unauthorized": "Agentes inválidos nos dados salvos.",
  "cms.report.subscribe.editreport":"Editar Relatório",
  "cms.report.subscribe.addreport":"Novos Relatórios",
  "cms.fullscreen.agent.auth": "Não tem permissão para visualizar a organização atual. Selecione novamente",
  "cms.agentMonitor.label.changestate_error":"Falha ao alternar o status da inspeção. Erro de operação",
  "cms.agent.label.systempara":"Configuração do parâmetro",
  "cms.indexmonitor.field.skillConnectedQueue": "Duração total da chamada em fila",
  "cms.indexmonitor.field.skillAbandonQueue": "Duração total da fila de perda de chamada",
  "cms.indexmonitor.message.skillConnectedQueue": "Duração total de todas as chamadas atendidas na fila",
  "cms.indexmonitor.message.skillAbandonQueue": "Duração total de todas as chamadas não atendidas na fila",
}