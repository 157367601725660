export default {
  "agentconsole.menu.title": "Audio and Video Workbench",
  "casemanagement.casequery.button.create": "Create Case",
  "agentconsole.callreason.tips.saved": "Call reason saved.",
  "iolp.workflow.title.name": "Business Process",
  "agentconsole.title.onlycustomer": "Customer-only",
  "agentconsole.title.allchat": "All content",
  "agentconsole.title.realtimetransfer": "Real-time transfer",
  "agentconsole.title.intelligentcase": "Intelligent Form Filling",
  'agentconsole.management.message.filterYes': 'Yes',
  'agentconsole.management.message.filterNo': 'No',
  "agentconsole.dashboard.enumeration.aweek": "Last Week",
  "SM.LOGIN.BUTTON.CHANGEPWD_CONFIRM": "OK",
  "agentconsole.Intelligent.details": "Details",
  "agentconsole.workbench.label.intelligentrecommendation": "Agent Assistant",
  "agentconsole.dashboard.title.case": "Case Overview",
  "agentconsole.header.label.searchtask": "Search in Task",
  "SM.LOGIN.TITLE.NOTICE_EXP_TIME": "Expiration Time",
  "SM.LOGIN.LABEL.LOGIN_CONFIRM_SUFFIX": "), confirm:",
  "SM.LOGIN.LABEL.NOTICE_CREATE_TIME_TO": "To",
  "SM.LOGIN.LABEL.OPER_PHONE": "Mobile number:",
  "agentconsole.workbench.label.callinforeasons": "first-level reason / second-level reason / third-level reason / four-level reason",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_NOT_OPEN_ES": "The emergency system is not started. Please access the production menu to perform business operations.",
  "agentconsole.dashboard.title.averageevalution": "Average Satisfaction",
  "agentconsole.header.title.send.sms.notification": "Outgoing SMS notification",
  "agentconsole.index.label.caseconsole": "Case Workbench",
  "agentconsole.login.label.empty": "The user name or password is empty.",
  "agentconsole.authmanage.label.authtype.menu": "Menu Permission",
  "SM.LOGIN.LABEL.FORGETPWD_ACCOUNT_TIPS": "Enter a sign-in account.",
  "SM.LOGIN.LABEL.CHANGEPWD_OLDPWD": "Old Password",
  "agentconsole.callmessage.setHandledNumber": "Set Handled Number",
  "agentconsole.login.label.password": "Password",
  "agentconsole.header.title.send.whatsapp.notification": "Sending WhatsApp Notification Messages",
  "agentconsole.login.label.signin": "Sign In",
  "agentconsole.index.label.chatconsole": "Chat Workbench",
  "SM.ORGSTAFF.LABEL.PWD_UNMATCH_RULE": "The password does not comply with the rules.",
  "agentconsole.menumanage.message.paramvalidatefailed": "Failed to create the menu. Check the parameters.",
  "SM.LOGIN.LABEL.OPER_REGION": "Home area:",
  "SM.LOGIN.TIPS.CHANGEPWD_COMPARE": "The new password must be the same as the confirm password.",
  "SM.LOGIN.TITLE.UPDATE_PWD_VERIFYCODE_EMPTY": "The verification code is empty.",
  "SM.LOGIN.LABEL.NOTICE_INCLUDE_SUB_ORG": "Include Child OU",
  "agentconsole.common.button.modify": "Modify",
  "agentconsole.msg.noData": "No data",
  "SM.LOGIN.TITLE.NOTICE": "Bulletin",
  "SM.LOGIN.TIPS.LOGINSUCCESS": "Sign-in successful.",
  "SM.LOGIN.TITLE.PORTAL_REGION_TITLE": "Region",
  "agentconsole.quickentrance.classname": "Please give the catalog a collation name. Example: Tenant Resource Management",
  "SM.LOGIN.TITLE.PORTAL_TITLE": "BES Portal",
  "SM.LOGIN.MESSAGE.LOGOUT": "Are you sure you want to exit?",
  "SM.LOGIN.LABEL.LOGIN_CONFIRM_SWITCH": "Access Home System",
  "SM.LOGIN.TITLE.UPDATE_PWD_NEWPWD_EMPTY": "The new password is empty.",
  "agentconsole.dashboard.title.taskunassigned": "Unassigned",
  "SM.LOGIN.BUTTON.RESET": "Reset",
  "agentconsole.ccnotification.inputRecipient": "Please enter recipients, multiple recipients are separated by commas",
  "agentconsole.ccnotification.inputOneRecipient": "Please enter recipient",
  "agentconsole.ccnotification.recipient.validate.limit.one": "The number of recipients cannot exceed 1.",
  "agentconsole.callmessage.callerNo": "Caller",
  "SM.LOGIN.LABEL.SUM": "System User Management",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_SWITCH_ES": "The business has been switched to the emergency state. The business can be handled only after the system is recovered.",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_VERIFY_CODE_NOT_EXIST": "The verification code does not exist.",
  "agentconsole.dashboard.evaluation.medium": "3–4 points",
  "agentconsole.header.title.logout": "Log Out",
  "agentconsole.index.label.casedec": "You can create a case for each service request of your customer and track the complete life cycle of the customer service request processing through the case, ensuring that each customer request is closed and improving the customer satisfaction.",
  "agentconsole.common.tips.error": "Error",
  "agentconsole.menumanage.title.selectauth": "Please select a permission",
  "SM.LOGIN.BUTTON.FORGETPWD_CANCEL": "Cancel",
  "agentconsole.Intelligent.noRecord": "The service is not being handled or the query result is null.",
  "agentconsole.dashboard.evaluation.low": "1–2 points",
  "SM.LOGIN.TITLE.UPDATE_PWD_NEWPWD_RULE": "The new password does not meet the password rule.",
  "SM.LOGIN.MESSAGE.SENDSMS_FAILED": "Failed to send the SMS message. Check the backend log.",
  "agentconsole.header.title.notificationcenter": "View Notifications",
  "agentconsole.contacthistory.title": "History contacts",
  "agentconsole.login.indexforipcc.help": "Help",
  "agentconsole.authmanage.field.auth_type": "Permission Type",
  "SM.PORTAL.LABEL.INPUT_OLD_PWD": "Enter the old password.",
  "SM.LOGIN.BUTTON.FORGETPWD_CONFIRM": "OK",
  "SM.LOGIN.LABEL.FORGETPWD_EMAIL_TIPS": "Enter the email address.",
  "SM.AUC.MESSAGE.PWDRULE_NO": "No",
  "agentconsole.callmessage.faq.spread": "Expand",
  "agentconsole.querycustominfo.msg.inputHandleNumber": "Enter a handled number",
  "agentconsole.login.indexforipcc.difbutton": "Different operation control buttons can be displayed according to the customer service personnel's authority to avoid misoperation and unnecessary interference, thereby improving the service efficiency of the customer service personnel.",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_LOGIN_ID_NULL": "The sign-in account cannot be empty.",
  "agentconsole.common.button.ok": "OK",
  "agentconsole.login.indexforipcc.agentcall": "Agent call control, such as answering calls, call forwarding, three-way calling, asking for help, etc.",
  "agentconsole.contacthistory.oneMin": "1 minutes ago",
  "SM.LOGIN.TITLE.PASSWORD_HASEXPIRED": "Your password has expired. Please contact the administrator to reset the password.",
  "SM.LOGIN.LABEL.FRAMEWORK_CALCULATOR": "Calculator",
  "SM.LOGIN.LABEL.NOTICE_GRADE_NORMAL": "Normal",
  "SM.LOGIN.LABEL.CUS_LOGIN": "Customer Sign-In",
  "SM.SERVLET.BATCH.RESET.PASSWORD.EMPLOYEE": "Resetting Rental User Passwords in Batches",
  "agentconsole.sessionmanagement.label.clientIp": "Client Ip",
  "agentconsole.header.label.searchbase": "Search in Knowledge Center",
  "agentconsole.login.resetpwd.empty": "The user name or email address is empty.",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED": "Failed to open the menu.",
  "agentconsole.login.indexforipcc.cccbar": "The seat strip in the agent frame provides the main voice call processing and agent status control functions.",
  "SM.LOGIN.TITLE.LOGIN_TITLE": "BES Portal Sign-in Page",
  "SM.LOGIN.TIPS.UPDATE_PWD": "Change Password",
  "SM.ORGSTAFF.LABEL.TEAM_ACCOUNT": "Account",
  "SM.LOGIN.TITLE.CURRENT_BE": "Current City",
  "agentconsole.callmessage.faq.folded": "Collapse",
  "agentconsole.workbench.queNum": "Number of current queuing customers: ",
  "SM.LOGIN.TITLE.PROJECT_CREATER": "Creator:",
  "SM.LOGIN.MESSAGE.LOGIN.LABEL.EMPTY": "The user name or password is empty.",
  "agentconsole.common.expiry.phone": "Service Hotline",
  "agentconsole.menumanage.message.urlvalidatefailed": "Failed to create the menu. Contact the system administrator to check the IP address trustlist.",
  "SM.LOGIN.TITLE.PASSWORD_INIT_SHOULD": "You are using the initial password. Change the password immediately.",
  "SM.LOGIN.LABEL.RESETPWD.FAIL": "Resetting the password failed.",
  "agentconsole.login.indexforipcc.connectcontrol": "Connection state control",
  "agentconsole.dashboard.title.caseunhandle": "Unprocessed",
  "SM.LOGIN.TIPS.MODIFY_SUCCESS": "Modified successfully.",
  "SM.ORGSTAFF.TIPS.REGION_DST_SWITCHINTERVAL_TIPS": "Unit: second. Range: 1 to 86400. If this parameter is left empty the default value 3600 is used.",
  "agentconsole.dashboard.title.casecreate": "Number of Created Cases",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_LOGINID_NO_BIND_MSISDN": "The user has not bound to any mobile number.",
  "SM.PORTAL.LABEL.INPUT_NEW_PWD": "Enter a new password.",
  "agentconsole.menumanage.message.renamenode": "The menu name already exists.",
  "agentconsole.contacthistory.accept": "Handled Number",
  "SM.LOGIN.LABEL.REMEMBER": "Remember User Name",
  "agentconsole.login.indexforipcc.signinsuccess": "After the agent has successfully checked in, the agent can answer the customer's call and handle the business. The system assigns customer calls based on the agents that are checked in. The management personnel conducts attendance management according to the check-in and check-out time of the agent.    ",
  "agentconsole.login.indexforipcc.usenow": "use immediately",
  "SM.LOGIN.TITLE.UPDATE_PWD_CONFIRMPWD": "Confirm Password:",
  "agentconsole.authmanage.label.authtype.interface": "Interface Permission",
  "agentconsole.login.indexforipcc.callcontrol": "Voice call control",
  "SM.LOGIN.LABEL.RESETPWD": "Click Reset. The new password will be sent to the mailbox.",
  "agentconsole.header.title.send.notification": "Send Notification ",
  "agentconsole.login.indexforipcc.difauth": "Manage the tenant's organization, roles, permissions, and employees. After the customer service personnel log in using the employee account, the call control buttons and page menus that can be viewed are different according to the different rights. Only when the user logs in but not checked in, the call-related operations cannot be performed.",
  "SM.LOGIN.MESSAGE.LOGOUT_REASON.IP_CHECK_FAILED": "IP address verification failed. The current IP address is different from that for first sign-in.",
  "agentconsole.menumanage.message.menuname": "The number of bytes must be less than or equal to 50.",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_OPEN_BE_ES": "The current area has been switched to the emergency state. Sign in to the emergency system to handle the business.",
  "agentconsole.login.indexforipcc.doublecontrol": "The intelligent customer service cloud has two layers of authentication control for the agent login system:",
  "SM.LOGIN.MESSAGE.SUGGESTION": "Prompt",
  "SM.LOGIN.MESSAGE.SEARCH_HISTORY_CLEAR": "Clear History",
  "agentconsole.common.expiry.prefix": "Your tenant will expire on",
  "SM.LOGIN.TITLE.ICPCODE": "Jiangsu ICP Registration Number: {0}-{1}",
  "SM.LOGIN.LABEL.OPER_LOGINID": "Sign-in account:",
  "SM.LOGIN.LABEL.NOTICE_ORG": "OU",
  "agentconsole.quickentrance.classdesc": "Please write a note for this directory. Example: Manage tenant resources. You can view the maintenance of tenant resources here.",
  "SM.LOGIN.LABEL.CHANGEPWD_ACCOUNT": "Account",
  "SM.LOGIN.TIPS.LOGINRESULT": "Sign-in result:",
  "SM.LOGIN.TITLE.CHANGEPWD_ERROR": "Error",
  "SM.LOGIN.LABEL.AUTH_FAILED_ES": "The current city has entered the emergency state. Please sign in to the emergency system.",
  "agentconsole.custinfo.label.amount": "Payment Amount ",
  "agentconsole.sessionmanagement.label.startime": "Begin Time",
  "agentconsole.sessionmanagement.label.accountNumber": "Account",
  "agentconsole.callmessage.setNumerError": "Failed to set the handling number.",
  "agentconsole.callmessage.query.external.custom.info": "Failed to query external custom info. Contact O&M personnel to view logs.",
  "SM.LOGIN.LABEL.FRAMEWORK_ONLINE_HELP": "Online Help",
  "SM.INDEX.LABEL.TASK": "Task",
  "SM.LOGIN.LABEL.VCODE_INVALID": "The verification code is incorrect.",
  "agentconsole.dashboard.evaluation.unit": "Quantity",
  "SM.LOGIN.LABEL.ERRORPAGE_ERRORNODETAIL_CLOSE": "Details>",
  "agentconsole.workbench.talkNum": "Today's call counts: ",
  "agentconsole.authmanage.message.renamenode": "Rename the permission.",
  "SM.LOGIN.LABEL.SMS_RECONFIRM_GETCODE": "Obtain Verification Code",
  "agentconsole.index.label.querycase": "Query Case",
  "agentconsole.custinfo.label.dueDate": "Due Date",
  "agentconsole.login.label.verifycode": "Verification Code",
  "SM.INDEX.LABEL.CHATDEC": "During a multimedia online chat with your customer, you can view the customer's all-round historical contact information to maximize your service efficiency and provide Omnichannel service experience for your customer.",
  "SM.SERVLET.BATCH.RESET.PASSWORD.EMPFAIL": ", failed employees:",
  "SM.LOGIN.BUTTON.LOGIN_CONFIRM_MSG": "The system will exit if you want to access the system of the BE. You need to enter the user name and password to sign in. Are you sure you want to continue?",
  "agentconsole.wordreminder.norecommend": "No recommended script available.",
  "agentconsole.sessionmanagement.table.deleteConfimMessage": "Are you sure you want to delete this session?",
  "agentconsole.login.indexforipcc.agent": "Agent certification",
  "agentconsole.dashboard.title.taskunhandle": "Unprocessed",
  "SM.LOGIN.MESSAGE.CHANGEPWD_FAILED": "Failed to change the password.",
  "agentconsole.login.indexforipcc.transfer": "Call forwarding",
  "agentconsole.custinfo.label.custName": "Customer Name",
  "SM.COMMON.MESSAGE.VALIDATE_MINLENGTHB": "Input at least {0} characters.",
  "agentconsole.index.label.taskconsole": "Task Workbench",
  "agentconsole.authmanage.message.authlimit": "If the exception is caused by insufficient permission or other reasons, contact Huawei technical support.",
  "SM.LOGIN.MESSAGE.SENDMAIL_FAILED": "Failed to send the email.",
  "SM.LOGIN.LABEL.OPER_BE": "BE:",
  "agentconsole.index.label.taskdec": "During case processing, you can dispatch tasks to work with multiple departments and personnel. Multiple tasks can be created for a case.",
  "agentconsole.menumanage.message.notdelnoleafmenu": "Do not allow deletion of the menu with submenus.",
  "agentconsole.dashboard.title.casepriority": "To-Do List",
  "SM.ORGSTAFF.LABEL.ADDR_PLEASE_INPUT": "Please input name",
  "SM.LOGIN.TITLE.PORTAL_SWITCH_PROJECT": "Switch Project",
  "agentconsole.header.title.messageCenter": "Internal Message Management",
  "agentconsole.login.label.email": "Email",
  "SM.LOGIN.BUTTON.SMS_RECONFIRM_CANCEL": "Cancel",
  "SM.LOGIN.LABEL.FORGETPWD_SENDTYPE": "Retrieval Mode",
  "agentconsole.authmanage.message.authtype": "Authority name cannot be entered. Special characters are not empty and the number of bytes must be less than or equal to 50.",
  "SM.LOGIN.TITLE.PROJECT_DESC": "Project description:",
  "agentconsole.dashboard.title.caseperday": "Daily Processing Status",
  "agentconsole.menumanage.message.createsubnodefail": "The menu level cannot exceed 3 levels.",
  "SM.LOGIN.TIPS.WELCOME": "Welcome to use the BES!",
  "SM.LOGIN.TITLE.LOGIN": "Sign In",
  "agentconsole.workbench.signInCallNum": "Number of current sign-in calls:",
  "SM.LOGIN.LABEL.SMS_RECONFIRM_VCODE": "Verification Code",
  "SM.LOGIN.BUTTON.UPDATE_PWD_MODIFY": "Modify",
  "SM.LOGIN.LABEL.OPER_EMAIL": "Email:",
  "SM.LOGIN.MESSAGE.SUM": "Total:",
  "SM.LOGIN.LABEL.VCODE": "Verification Code",
  "agentconsole.login.indexforipcc.recordcontrol": "Recording and playback control",
  "SM.LOGIN.MESSAGE.MODIFY_PWD_SUCCESS": "Password changed successfully.",
  "SM.LOGIN.TITLE.PROJECT_CREATE_TIME": "Creation time:",
  "agentconsole.header.label.searchcase": "Search in Case",
  "SM.LOGIN.TITLE.NOTICE_EFF_TIME": "Effective Time",
  "SM.LOGIN.SSO.IS.INVALID": "The page has expired, click to refresh the page.",
  "agentconsole.history.lastloginfailattempts": "Last failed Login Attempts",
  "SM.LOGIN.LABEL.ERRORPAGE_RETURNBTN": "Return to Sign-In Page",
  "agentconsole.menu.extended.apps": "Extended apps",
  "SM.LOGIN.MESSAGE.SWITCH": "Are you sure you want to switch to the ESOP system?",
  "SM.LOGIN.RESETPWD.FAIL": "Failed to process the password resetting request. Check the sign-in account and email address.",
  "agentconsole.history.loginip": "Login IP",
  "agentconsole.login.indexforipcc.call": "Voice call control",
  "agentconsole.index.label.task": "Task",
  "agentconsole.quickentrance.checkcharacterlength": "Please enter no more than {0} characters.",
  "agentconsole.index.label.entry": "Quick Entry",
  "agentconsole.quickentrance.information": "Information",
  "SM.LOGIN.LABEL.FRAME_SEARCH_EMPLOYEE": "Employee Query",
  "agentconsole.querycustominfo.msg.specialStrValidateFailed": "Only the following special characters are supported: {'@'}.-",
  "agentconsole.modifycustominfo.modifyfailed": "Modified failed.",
  "agentconsole.modifycustominfo.modifyfailed.validate": "Modified failed,Does not conform to the character string rule or length.",
  "agentconsole.modifycustominfo.modifyfailed.validate.num": "Modified failed,Does not meet the number rules or length.",
  "agentconsole.modifycustominfo.modifyfailed.validate.date":"Modified failed,The date format is incorrect.",
  "agentconsole.modifycustominfo.modifyfailed.validate.time":"Modified failed,The date and time format is incorrect.",
  "SM.LOGIN.TITLE.UPDATE_PWD_OLDPWD_EMPTY": "The old password is empty.",
  "SM.LOGIN.LABEL.FORGETPWD_ACCOUNT": "Sign-In Account",
  "provision.menuurl.protocol.check": "Note that the current menu address is using a non-secure network protocol. If you choose to use a network protocol, there may be security risks.",
  "agentconsole.dashboard.title.taskpriority": "To-Do List",
  "agentconsole.authmanage.field.auth_name": "Permission Name",
  "agentconsole.inputplaceholder.enter_keyword": "Enter a keyword.",
  "agentconsole.login.indexforipcc.gateway": "Open gateway",
  "SM.LOGIN.TITLE.UNIFIED_CASHER": "Unified Cashier",
  "SM.LOGIN.TITLE.SHOPPING_CART": "Shopping Cart",
  "SM.LOGIN.LABEL.NOTICE_CREATE_TIME": "Creation Time",
  "SM.LOGIN.RESETPWD.EMPTY": "The sign-in account or email address is empty.",
  "agentconsole.header.label.searchhelp": "Search in Help Center",
  "SM.LOGIN.LABEL.NOTICE_CHAN_TYPE": "Channel Type",
  "agentconsole.login.label.service_cloud_title": "Customer Service Cloud",
  "agentconsole.custinfo.title.custinfo": "Customer Information",
  "SM.LOGIN.TITLE.SEARCH_CONDITION": "Query Condition",
  "SM.LOGIN.TITLE.OPERATOR": "Current Operator",
  "agentconsole.history.passwordremainingdays": "Password Remaining Days",
  "SM.LOGIN.TIPS.REQUIRED": "This parameter is mandatory",
  "SM.LOGIN.LABEL.FORGETPWD_EMAIL": "Email:",
  "agentconsole.menumanage.message.homepagesubnode": "Cannot add submenu to the Home Page.",
  "SM.LOGIN.LABEL.ADD_FAST_MENU": "Add Shortcut Menu",
  "agentconsole.login.restpwd.success": "Password reset successfully.",
  "SM.LOGIN.TITLE.CHOOSE_SELECT_ORG": "Select OU",
  "agentconsole.modifycustominfo.modifyexternalfailed": "Currently, third-party data cannot be modified.",
  "SM.LOGIN.LABEL.HOME": "Home Page",
  "SM.LOGIN.TIPS.MODIFY": "Modify",
  "agentconsole.authmanage.paneltitle.authmanage": "Permission Management",
  "agentconsole.menumanage.label.newmenu": "New Menu",
  "SM.LOGIN.MESSAGE.HAVE_LOCK_USER": "The user has been locked. Unlock the user or wait for the system to automatically unlock the user.",
  "SM.LOGIN.LABEL.RECHARGE": "Top-up",
  "SM.LOGIN.MESSAGE.UNLOCK": "Are you sure you want to lock the system?",
  "agentconsole.querycustominfo.msg.noData": "No data",
  "agentconsole.callmessage.setNumerSuccess": "Setting the handling number succeeded.",
  "agentconsole.workbench.talkavg": "Average call duration (s): ",
  "agentconsole.dashboard.title.taskdone": "Number of Handled Tasks",
  "agentconsole.dashboard.title.web": "Web Page",
  "SM.LOGIN.TITLE.UPDATE_PWD_NEWPWD": "New password:",
  "SM.LOGIN.TIPS.ENTER_PROJECT_NAME": "Enter the project name.",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_VERIFY_CODE_NULL": "The verification code is empty.",
  "agentconsole.index.label.querycasenew": "Query Case Workbench (New)",
  "agentconsole.login.indexforipcc.thirdpartysystem": "Third-party systems can be implemented through an integrated interface:",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_SWITCH_ES_PREFIX": "The current business has been switched to the emergency state. Please visit",
  "SM.LOGIN.TITLE.REGISTERCODE": "Jiangsu Public Security Website Registration Number: {0}",
  "SM.LOGIN.LABEL.LOCK": "Lock Screen",
  "agentconsole.workbench.label.callreason": "Call reason",
  "SM.LOGIN.TITLE.UPDATE_PWD_OLDPWD_NEWPWD": "The new password cannot be the same as the old password.",
  "SM.LOGIN.TITLE.NOTICE_MORE": "More",
  "SM.LOGIN.MESSAGE.CHANGE_PWD_RULE": "Failed to change the password because the new password does not meet the following password rule group:",
  "SM.LOGIN.RESETPWD.SUCCESS": "The password resetting request has been processed successfully. Please check the email.",
  "SM.LOGIN.LABEL.FRAMEWORK_NOTEPAD": "Notepad",
  "agentconsole.menumanage.message.notdelbasemenu": "Do not allow deletion of the public menu.",
  "agentconsole.quickentrance.newpop": "Add Entry",
  "agentconsole.login.label.account": "Account",
  "SM.LOGIN.LABEL.NOTICE_TITLE": "Bulletin Title",
  "SM.LOGIN.TITLE.FORGETPWD_FINDEMAILBYPWD": "Retrieve passwords through emails",
  "agentconsole.login.indexforipcc.advantage": "Product advantages",
  "agentconsole.login.indexforipcc.characteristics": "Business characteristics",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_BE_SWITCH_ES_PREFIX": "The current area has been switched to the emergency state. Sign in to the emergency system and access the",
  "agentconsole.Intelligent.robot": "Intelligent robot",
  "agentconsole.header.label.servicecloud.home": "Case Home",
  "agentconsole.header.title.news": "News",
  "SM.LOGIN.TIPS.FIRSTLOGINSYSTEM": "Signing In to the System for the First Time",
  "agentconsole.sessionmanagement.label.operation": "Operation",
  "SM.LOGIN.MESSAGE.LOGOUT_REASON.SESSION_INVALID": "The current session has expired. Please sign in again.",
  "SM.LOGIN.TITLE.UPDATE_PWD": "Change Password",
  "agentconsole.workbench.label.callsummary": "Summary after the call",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_VERIFY_CODE_EXPIRE": "Failed to verify the verification code.",
  "agentconsole.login.label.remember": "Remember User Name",
  "agentconsole.workbench.label.usermenu": "User menu",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_SEND_SMS_SUCCESS": "The verification code has been sent. Please check.",
  "agentconsole.quickentrance.addentrance": "Add Entry",
  "agentconsole.history.lastloginsuccess": "Last Successful Login",
  "agentconsole.login.indexforipcc.description": "As an important bridge between enterprises and customers, IPCC (IP Contact Center) provides voice call, online customer service and other channels to provide customers with questions, business guidance and complaint handling services. Based on the current high-bandwidth, high-speed network environment, it also supports new features such as video agents and self-service voice navigation. While providing a new service experience to customers, it explores new marketing models based on video and artificial intelligence.",
  "SM.LOGIN.LABEL.OPER_ORG": "OU:",
  "SM.LOGIN.LABEL.USERNAME": "Account",
  "agentconsole.contacthistory.contactRecord": "Contact Record",
  "agentconsole.login.label.resetpwd": "Click Reset. The new password will be sent to the mailbox.",
  "agentconsole.wordreminder.greetings": "Greetings",
  "agentconsole.common.expiry.email": "Email address",
  "agentconsole.modifycustominfo.modifysuccess": "Modified successfully.",
  "SM.LOGIN.LABEL.FORGETPWD_FINDPWD": "Retrieve Password",
  "agentconsole.common.label.delete": "Delete",
  "agentconsole.login.label.verifycode.error": "The verification code is incorrect.",
  "agentconsole.icontitle.delete": "Delete",
  "SM.LOGIN.LABEL.LOGIN_CONFIRM_PREFIX": "The system detects that the current system address is not the home BE system address (",
  "SM.LOGIN.BUTTON.UPDATE_PWD_RULE": "Password Rule",
  "SM.LOGIN.TITLE.NOTICE_NUMBER": "No.",
  "agentconsole.icontitle.create_child": "Add Sub Level",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_NOT_SWITCH_ES": "The system is not switched to the emergency state. Access the production menu to perform business operations.",
  "SM.LOGIN.TITLE.OPERATION_FAILED": "Operation failed",
  "agentconsole.login.label.refresh_verify_code": "Not clear. Change another one.",
  "agentconsole.login.indexforipcc.difstatus": "The agent status refers to various states that the customer service representative may be in after logging in to the agent, including \"idle state\", \"talk state\", \"finishing state\", \"showing busy state\" and \"resting state\". By performing customer service representative to answer the call, leave, the sequence operation shown busy, etc., to achieve the conversion agent states. Based on the status of the agent, the system determines whether it is possible to assign an incoming call to the customer service representative.",
  "SM.LOGIN.TITLE.PORTAL_PROJECT_TITLE": "Select Project",
  "SM.LOGIN.LABEL.FORGETPWD_NUMBER": "Mobile Number",
  "agentconsole.modifycustominfo.nomodifyauth": "The current operator does not have the permission to modify customized data.",
  "SM.LOGIN.MESSAGE.SWITCHERROR": "Switching failed.",
  "SM.LOGIN.LABEL.RESETPWD.SUCCESS": "Password reset successfully.",
  "agentconsole.authmanage.message.createsubnodefail": "The permission level cannot exceed 3 levels.",
  "agentconsole.login.indexforipcc.qualitycheck": "Real-time quality check related functions, such as insertion, interception, monitoring, etc.",
  "agentconsole.authmanage.tab.basic_information": "Basic Information",
  "SM.LOGIN.LABEL.NOTICE_ROLE": "Recipient Role",
  "agentconsole.Intelligent.labelname": "Smart Voice Recording",
  "agentconsole.iolp.labelname": "Business Request",
  "agentconsole.iolp.entrancename": "Service guidance",
  "agentconsole.header.label.changelog": "Change Log",
  "SM.LOGIN.BUTTON.CHANGEPWD_CANCEL": "Cancel",
  "SM.LOGIN.LABEL.LANG": "Language",
  "agentconsole.login.indexforipcc.idle": "The system can only assign incoming calls to agents in the \"idle state\".",
  "SM.LOGIN.LABEL.ACTIVITY": "Marketing Activity",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_VERIFY_FAILED": "Verification failed. Try again later.",
  "SM.LOGIN.LABEL.NOTICE_NOTE_TYPE": "Bulletin Type",
  "SM.LOGIN.TITLE.SELECTBE": "Select BE",
  "agentconsole.dashboard.enumeration.otherday": "Other Date",
  "SM.LOGIN.TITLE.SERACH_MENU": "Enter the menu name.",
  "agentconsole.dashboard.evaluation.high": "5 points",
  "SM.LOGIN.TITLE.CHOOSE_ORG_LIST": "Please select an organization.",
  "agentconsole.login.indexforipcc.mute": "mute",
  "SM.LOGIN.LABEL.CUS_LOGIN_REDIRECT": "Sign in and jump to",
  "SM.LOGIN.TITLE.SELECT_ACCOUNT_TYPE": "Select an account type.",
  "SM.LOGIN.TITLE.PRINT_FREE_FILLING": "Print ACK Slip",
  "agentconsole.sessionmanagement.label.search": "Query",
  "agentconsole.menumanage.message.menu_url": "The menu address cannot be Chinese.",
  "SM.LOGIN.LABEL.NOTICE_CREATOR": "Creator",
  "SM.LOGIN.LABEL.CHANGEPWD_COMFIRMPWD": "Confirm Password",
  "agentconsole.index.label.chat": "Online Customer Service",
  "agentconsole.authmanage.rule.tips": "Please select a permission that you customized. The predefined permission by system can not be selected.",
  "agentconsole.login.indexforipcc.title": "Cloud call center",
  "agentconsole.dashboard.title.casedone": "Number of Handled Cases",
  "SM.SERVLET.BATCH.RESET.PASSWORD.EMPLISTEND": "]",
  "SM.LOGIN.BUTTON.FORGETPWD_EMAIL": "Email",
  "agentconsole.login.indexforipcc.lightweight": "Lightweight",
  "agentconsole.Intelligent.close": "Close",
  "agentconsole.header.label.searchcommunity": "Search in Community",
  "agentconsole.dashboard.title.interactioncount": "Total Contact Quantity on the Current Day:",
  "agentconsole.modifycustominfo.noqueryauth": "The current operator does not have the permission to query customized data.",
  "agentconsole.common.tips.warning": "Warning",
  "SM.LOGIN.TITLE.HELPCENTER": "Help Center",
  "agentconsole.contacthistory.talking": "Voice session",
  "agentconsole.login.indexforipcc.hold": "Call hold",
  "agentconsole.menumanage.tab.basic_information": "Basic Information",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_VERIFY_CODE_MINTIME_ERROR": "The verification code has been generated.",
  "agentconsole.contacthistory.readMore": "See more",
  "agentconsole.authmanage.message.deleteauthfail": "Failed to delete the permission. Please check if the permission has bean referenced by any menu or given any role.",
  "agentconsole.quickentrance.deleteinfo": "Are you sure you want to delete this directory",
  "SM.LOGIN.RESETPWD.EMAILPATTERN_ERROR": "Invalid email address.",
  "SM.LOGIN.TITLE.NONLOCAL_USER_CHANEPWD_ERROR": "A non-local user cannot change the password.",
  "agentconsole.login.indexforipcc.onepoint": "Customer service personnel can check in the platform, answer incoming calls, transfer calls, mute, status switch or help.",
  "agentconsole.login.indexforipcc.loginportal": "Log in to the Customer Service Cloud Portal.",
  "SM.LOGIN.BUTTON.UPDATE_PWD_CLOSE": "Close",
  "agentconsole.common.button.cancel": "Cancel",
  "SM.LOGIN.BUTTON.LOGIN_CONFIRM_CONTINUE": "Continue",
  "SM.LOGIN.TITLE.COPYRIGHT": "Copyright {0} {1} Huawei Software Technologies Co., Ltd. All rights reserved.",
  "agentconsole.menumanage.message.createfailures": "Create menu failed",
  "SM.INDEX.LABEL.CASEDEC": "You can create a case for each service request of your customer and track the complete life cycle of the customer service request processing through the case, ensuring that each customer request is closed and improving the customer satisfaction.",
  "agentconsole.menumanage.message.sysadmincannotaddmenu": "The current system administrator cannot create a menu.",
  "SM.LOGIN.MESSAGE.UNLOCK_FAILED": "Failed to unlock the account. Enter the correct password.",
  "agentconsole.authmanage.label.newauth": "New Permission",
  "agentconsole.sessionmanagement.input.validatorMessage": "Special characters except underscores (_), periods (.), and at signs ({'@'}) are not allowed.",
  "agentconsole.callmessage.calledNo": "Called",
  "agentconsole.login.indexforipcc.difplat": "Easy to integrate into different platforms without taking up the main pages of the portal and workbench",
  "agentconsole.login.indexforipcc.auth": "Access control",
  "agentconsole.index.label.case": "Case",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_BE_SWITCH_ES": "The current area has been switched to the emergency state. The business can be handled only after the system is recovered. Sign in to the emergency system to handle other businesses.",
  "agentconsole.header.title.send": "Send Internal Message",
  "agentconsole.dashboard.priority.medium": "Priority medium",
  "agentconsole.ccnotification.not.currently.on.call": "Not currently on a call",
  "SM.LOGIN.BUTTON.LOGIN_CONFIRM_CANCEL": "Cancel",
  "agentconsole.authmanage.message.selectanauth": "Please select a permission.",
  "SM.LOGIN.BUTTON.SMS_RECONFIRM_CONFIRM": "OK",
  "agentconsole.workbench.label.remarks": "Remarks",
  "SM.LOGIN.TITLE.INFO": "Prompt",
  "SM.LOGIN.LABEL.LOGIN_CONFIRM_CONTINUE": "Continue to Access Current System",
  "agentconsole.sessionmanagement.table.fail.delete": "Deletion failed",
  "SM.LOGIN.BUTTON.CANCEL": "Cancel",
  "SM.LOGIN.TITLE.CHANGEPWD_RULE": "The new password must comply with the following rules:",
  "SM.LOGIN.BUTTON.FORGETPWD_SMS": "SMS",
  "SM.LOGIN.MESSAGE.LOGOUT_REASON.FORCE_CHANGE_PASSWORD": "Change the password after sign-in.",
  "SM.LOGIN.TITLE.CURRENT_SELECTED_PROJECT": "Selected:",
  "SM.LOGIN.LABEL.FRAMEWORK_AD_DEMAND": "Ad On-Demand",
  "SM.LOGIN.LABEL.NOTICE_GRADE_URGENT": "Rush",
  "SM.LOGIN.LABEL.CHANGEPWD_NEWPWD": "New Password",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_BIND_INFO_NULL": "The sign-in account or binding information is empty.",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_SEND_SMS_FAILED": "Failed to send the SMS message. Check the backend log.",
  "SM.LOGIN.TITLE.UPDATE_PWD_NEWPWD_CONFIRMPWD": "The new password must be the same as the confirm password.",
  "SM.LOGIN.MESSAGE.SMS_RECONFIRM_VERIFY_CODE_DATE_INVALID": "Failed to verify the verification code.",
  "SM.LOGIN.MESSAGE.REPEAT_SUBMITED": "The request has been sent. Please wait...",
  "agentconsole.dashboard.title.unassigned": "Unassigned",
  "SM.LOGIN.TIPS.SAVE": "Save",
  "SM.LOGIN.MESSAGE.NONLOCAL_USER_RETRIEVEPASSWORD_FAILED": "Failed to retrieve the password. Non-local users cannot use the password retrieval function.",
  "SM.LOGIN.TITLE.PASSWORD_INIT": "You are using the initial password. Change the password immediately.",
  "SM.LOGIN.BUTTON.UPDATE_PWD_MODIFY_SUCCESS": "Modified successfully.",
  "agentconsole.dashboard.title.interactionperday": "Daily Contact Quantity",
  "SM.PORTAL.LABEL.INPUT_CFM_PWD": "Confirm the new password.",
  "SM.LOGIN.TITLE.CHANGEPWD_SUCCESS": "Prompt",
  "SM.LOGIN.LABEL.COPYRIGHT": "Copyright © Huawei Technologies Co., Ltd. 2018.",
  "SM.LOGIN.TITLE.PASSWORD_WILLEXPIRED": "Your password is about to expire. Please change the password.",
  "agentconsole.dashboard.title.taskcreate": "Number of Created Tasks",
  "agentconsole.dashboard.title.task": "Task Overview",
  "SM.LOGIN.BUTTON.QUERY": "Query",
  "agentconsole.login.restpwd.fail": "Resetting the password failed.",
  "SM.LOGIN.LABEL.AUTH_FAILED": "Incorrect account or password.",
  "agentconsole.login.label.reset": "Reset",
  "SM.LOGIN.TIPS.LASTLOGINTIME": "Last sign-in time:",
  "SM.LOGIN.LABEL.SYSTEM_ERROR": "System error.",
  "agentconsole.dashboard.priority.high": "Priority high",
  "SM.LOGIN.TITLE.NOTICE_LIST": "Bulletin List",
  "agentconsole.login.label.username": "User Name",
  "agentconsole.index.label.chatdec": "During a multimedia online chat with your customer, you can view the customer's all-round historical contact information to maximize your service efficiency and provide Omnichannel service experience for your customer.",
  "agentconsole.ccnotification.has.been.sent": "Has been sent",
  "SM.LOGIN.TITLE.UPDATE_PWD_MODIFY_FAILED": "Modification failed",
  "SM.LOGIN.TITLE.BE_CATALOG": "BE Catalog:",
  "agentconsole.index.label.casedraft": "My Draft Cases",
  "agentconsole.login.indexforipcc.opengateway": "Open gateway",
  "agentconsole.login.label.forgot": "Forget Password?",
  "agentconsole.sessionmanagement.table.success.delete": "Deletion Success",
  "agentconsole.callmessage.title": "Call Message",
  "SM.LOGIN.TITLE.SMS_RECONFIRM": "SMS Re-confirmation",
  "agentconsole.quickentrance.deleteclass": "Delete this directory",
  "agentconsole.login.indexforipcc.thirdparty": "Three-way calling",
  "agentconsole.sessionmanagement.table.deleteconfim": "Confirm Delete",
  "agentconsole.login.indexforipcc.sequence": "Queue information query",
  "SM.LOGIN.TITLE.UPDATE_PWD_LOGINID": "Account:",
  "SM.LOGIN.LABEL.FAST_ENTER": "Quick Entry",
  "agentconsole.menumanage.field.url": "Menu URL",
  "agentconsole.login.indexforipcc.statecontrol": "Connection state control",
  "agentconsole.common.expiry.suffix": "Please pay attention to it. If you want to continue using it, contact the operation personnel.",
  "agentconsole.login.indexforipcc.signin": "Customer service personnel who need to handle voice calls check in to the CTI platform.",
  "agentconsole.login.indexforipcc.answer": "Answer the call",
  "agentconsole.authmanage.message.notdelnoleafauth": "Do not allow deletion of the permission with sub permissions.",
  "agentconsole.quickentrance.managemententrance": "Entrance Management",
  "SM.LOGIN.TITLE.APPEND_MENU_SEARCH": "Search Criteria:",
  "agentconsole.authmanage.label.authtype.operation": "Operation Permission",
  "SM.LOGIN.TITLE.APPEND_MENU_TITLE": "Add Shortcut Menu",
  "agentconsole.dashboard.title.todo": "To-Do List",
  "SM.CHANGELANGAUGE.REFRESH.MESSAGE": "The entire page will be reloaded after you switch the language. Are you sure you want to continue?",
  "agentconsole.sessionmanagement.tip.error": "Error",
  "agentconsole.callmessage.callskill": "Skill Queue",
  "agentconsole.faqwordreminder.norecommend": "No recommended FAQ available",
  "SM.LOGIN.TITLE.UPDATE_PWD_OLDPWD": "Old password:",
  "SM.LOGIN.MESSAGE.SELECT_PROJECT": "Select a project.",
  "agentconsole.icontitle.create_sibling": "Add Peer Level",
  "SM.LOGIN.LABEL.PASSWORD": "Password",
  "agentconsole.callmessage.faq.reminder": "Recommended FAQ",
  "SM.SERVLET.BATCH.RESET.PASSWORD.EMPLIST": "Resetting Rental User Passwords in Batches: [employee list:",
  "SM.LOGIN.MESSAGE.CHANGEPWD_SUCCESS": "The password is changed successfully.",
  "agentconsole.login.indexforipcc.opengatewayde": "The Open Gateway provides a standard RESTful protocol-based interface for the voice call function of the third system integrated intelligent customer service cloud. The third-party system can implement the call center in its own system by purchasing the service without purchasing its own call center equipment. Ability.",
  "SM.LOGIN.LABEL.SETTINGS": "Settings",
  "SM.LOGIN.MESSAGE.MENU_ACCESS_TIME_NOT_MATCH": "The current time is not within the access time allowed by the menu. The access time of the menu is as follows:",
  "agentconsole.menumanage.field.menu_name": "Menu Name",
  "agentconsole.common.button.save": "Save",
  "agentconsole.login.restpwd.errorinfo": "The user name or email address is incorrect.",
  "SM.LOGIN.BUTTON.OK": "OK",
  "SM.LOGIN.TIPS.LOCK": "Lock Screen",
  "SM.LOGIN.LABEL.NOTICE_GRADE_EXTRA_URGENT": "Urgent",
  "agentconsole.common.tips.information": "Tips",
  "SM.INDEX.LABEL.TASKDEC": "During case processing, you can dispatch tasks to work with multiple departments and personnel. Multiple tasks can be created for a case.",
  "agentconsole.dashboard.title.taskperday": "Daily Processing Status",
  "agentconsole.authmanage.message.notdelbaseauth": "Do not allow deletion of the public permission.",
  "agentconsole.ccnotification.not.currently.on.three": "The process cannot be initiated in the current call state",
  "agentconsole.custinfo.label.custId": "Customer ID",
  "agentconsole.header.label.home": "Home Page",
  "agentconsole.menumanage.message.deletemenufail": "Failed to delete the menu.",
  "SM.LOGIN.LABEL.ERRORPAGE_ERRORSTACK": "Error stack:",
  "SM.LOGIN.LABEL.ERRORPAGE_ERRORNODETAIL_OPEN": "Details<",
  "agentconsole.dashboard.enumeration.today": "Today",
  "agentconsole.header.title.search": "Search",
  "agentconsole.wordreminder.recommend": "Recommended Script",
  "agentconsole.wordreminder.ai.recommend": "AI Recommended Script",
  "agentconsole.dashboard.priority.low": "Priority low",
  "agentconsole.quickentrance.complete": "Complete",
  "SM.LOGIN.TIPS.LOGINIP": "IP address:",
  "SM.LOGIN.LABEL.FINISH": "Finish",
  "SM.LOGIN.MESSAGE.GRAFIC.VERIFY.CODE.REFRESH": "Not clear. Change another one.",
  "SM.LOGIN.LABEL.RESET": "Reset",
  "agentconsole.authmanage.message.deleteconfirm": "Are you sure you want to delete the permission?",
  "SM.LOGIN.LABEL.LOGOUT": "Sign Out",
  "agentconsole.contacthistory.oneMinAgo": "Minutes ago",
  "SM.LOGIN.TITLE.UPDATE_PWD_CONFIRMPWD_EMPTY": "The confirm password is empty.",
  "agentconsole.contacthistory.hourAgo": "Hours ago",
  "agentconsole.login.indexforipcc.useage": "The intelligent customer service cloud provides a seat framework that supports multi-tenancy capabilities, enabling agents to be opened, and combining Huawei's CTI and UAP platforms to provide customers with complete cloud voice/video service capabilities.",
  "SM.LOGIN.TITLE.UPDATE_PWD_OLDPWD_ERROR": "The old password is incorrect.",
  "agentconsole.dashboard.title.caseunassigned": "Unassigned",
  "SM.LOGIN.TITLE.PORTAL_SWITCH_CONTEXTDATA": "Switch BE",
  "SM.ORGSTAFF.LABEL.ORG_FORBID_TIPS": "Are you sure you want to disable the organization?",
  "agentconsole.ccnotification.max.recipients": "No more than 5 recipients",
  "SM.LOGIN.LABEL.NOTICE_STATUS": "Status",
  "SM.LOGIN.BUTTON.UPDATE_PWD_CANCEL": "Cancel",
  "SM.ORGSTAFF.TITLE.ASSIGNE_MANAGER": "Assign Manager",
  "agentconsole.login.label.errorinfo": "The user name or password is incorrect.",
  "agentconsole.ccnotification.recipient.cannot.empty": "recipient cannot be empty",
  "agentconsole.dashboard.title.mobile": "Mobile Terminal",
  "SM.LOGIN.LABEL.LOCKED": "Locked",
  "agentconsole.authmanage.message.sysauthsubnode": "Cannot add sub permission to the System Management.",
  "SM.LOGIN.TITLE.SWITCH_ESOP": "One-Click Switch to ESOP",
  "agentconsole.quickentrance.all": "All",
  "agentconsole.workbench.label.userinfo": "User Info",
  "agentconsole.dashboard.enumeration.thressdays": "Last 3 Days",
  "agentconsole.modifycustominfo.nodatamodify": "No data is changed.",
  "agentconsole.login.label.service_cloud_desc": "Clear UI for Ultimate Service",
  "SM.LOGIN.LABEL.CANCEL": "Cancel",
  "SM.AUC.MESSAGE.PWDRULE_YES": "Yes",
  "SM.LOGIN.TITLE.NOTICE_CONTENT": "Content",
  "agentconsole.callmessage.setNumerLengthError": "The handling number cannot exceed 24 characters.",
  "agentconsole.contacthistory.dayAgo": "Days ago",
  "agentconsole.login.indexforipcc.agentstate": "Agent status control, such as agent busy, leisure, rest, etc.",
  "SM.LOGIN.TIPS.LOGOUT": "Signed out of the system.",
  "SM.LOGIN.LABEL.OPER_NAME": "User name:",
  "SM.LOGIN.MESSAGE.CHANGEPWD_OLDNEW": "The new password must be different from the old password.",
  "SM.LOGIN.LABEL.UPDATE_PWD": "Change Password",
  "agentconsole.quickentrance.title.toomanymenus": "The number of shortcut menus is exceeded. Please reduce the number of shortcut menus and save the settings again.",
  "SM.ORGSTAFF.MESSAGE.STAFF_DELETE_CONFIRM": "After an employee is deleted, the corresponding account of the employee cannot be restored and can only be re-created. delete or not?",
  "agentconsole.header.title.case": "Create Case",
  "SM.LOGIN.LABEL.NOTICE_GRADE": "Priority",
  "SM.ORGSTAFF.TITLE.OPERATION": "Operation",
  "SM.LOGIN.MESSAGE.SENDMAIL_SUCCESS": "The new password has been sent to your email address. Please use the new password to sign in.",
  "SM.LOGIN.TIPS.LOGINFAILED": "Sign-in failed.",
  "agentconsole.menumanage.message.deleteconfirm": "Are you sure you want to delete the menu?",
  "agentconsole.dashboard.enumeration.thirtydays": "Last 30 Days",
  "agentconsole.login.indexforipcc.easyuse": "Easy to operate",
  "agentconsole.dashboard.title.total": "Total",
  "SM.LOGIN.TIPS.WELCOME_LABEL": "Greeting:",
  "SM.LOGIN.LABEL.EMERGENCY_NOTICE": "Emergency Notice",
  "agentconsole.login.indexforipcc.deal": "Customer service personnel can handle the customer call as follows",
  "agentconsole.login.label.cancel": "Cancel",
  "agentconsole.menu.config.center": "Configuration Center",
  "SM.LOGIN.MESSAGE.SENDSMS_SUCCESS": "The new password has been sent to your mobile phone. Please use the new password to sign in.",
  "agentconsole.login.restpwd.emailpattern_error": "Invalid email address.",
  "message.center.message.messageManage": "Internal Message Management",
  "agentconsole.index.label.dashboard": "Dashboard",
  "agentconsole.menumanage.field.auth": "Menu Permission",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_SWITCH_ES_SUFFIX": "to continue to handle the business.",
  "agentconsole.login.indexforipcc.release": "release",
  "agentconsole.dashboard.title.chat": "Online Chat Overview",
  "agentconsole.index.label.querytask": "Query Task",
  "agentconsole.sessionmanagement.tip.success": "Success",
  "SM.LOGIN.TITLE.NOTICE_CRUM": "System Management > System Maintenance > Bulletin Query",
  "agentconsole.header.title.help": "Help",
  "agentconsole.header.title.send.email.notification": "Outgoing mail notification message",
  "agentconsole.login.indexforipcc.calldata": "Call data query",
  "agentconsole.dashboard.enumeration.yesterday": "Yesterday",
  "SM.LOGIN.LABEL.FRAMEWORK_CALENDAR": "Calendar",
  "agentconsole.contacthistory.nocontactinfo": "No interaction history",
  "SM.LOGIN.BUTTON.LOGIN": "Sign In",
  "SM.LOGIN.LABEL.FORGOTPASSWORD": "Forgot Password?",
  "SM.LOGIN.LABEL.OPEN_MENU_FAILED_BE_SWITCH_ES_SUFFIX": "menu to handle the business.",
  "SM.LOGIN.MESSAGE.CONFIRM": "Confirm",
  "SM.LOGIN.TITLE.COMMONTOOLS": "Common Tools",
  "SM.LOGIN.MESSAGE.LOGOUT_REASON.NO_LOGIN": "You have not signed in. Please sign in and try again.",
  "SM.LOGIN.LABEL.NOTICE_INCLUDE_SUB_ORG_Y": "Yes",
  "agentconsole.dashboard.title.wechat": "WeChat",
  "agentconsole.login.label.verifycode.empty": "The verification code is empty.",
  "SM.LOGIN.LABEL.VCODE_EMPTY": "The verification code is empty.",
  "SM.LOGIN.TITLE.DROPLIST_MENU": "Menu",
  "agentconsole.contact.customerInfo": "Customer Information",
  "agentconsole.history.lastloginfail": "Last Failed Login",
  "SM.LOGIN.LABEL.NOTICE_INCLUDE_SUB_ORG_N": "No",
  "agentconsole.history.logintime": "Date",
  "agentconsole.menumanage.paneltitle.menumanage": "Menu Management",
  "SM.LOGIN.RESETPWD.ERRORINFO": "The sign-in account or email address is incorrect.",
  "agentconsole.dashboard.title.evaluation": "Customer Satisfaction",
  "agentconsole.must.be.a.number":"must be a number",
  "WEB.VALIDATOR.RANGE":"Enter a value between {0} and {1}.",
  "agentconsole.voicetransfer.user" : "Customer",
  "agentconsole.voicetransfer.agent" : "Agent",
  "agentconsole.voicetransfer.chatbot" : "ChatBot",
  "agentconsole.title.transcription" : "Transcription",
  "agentconsole.title.agent.overview" : "Agent Overview",
  "agentconsole.agent.assistant.recomend.history" : "Recommendation History",
  "agentconsole.agent.assistant.faq.history" : "FAQ History",
  "agentconsole.agent.assistant.auto.create.case" : "Automatically Create Case",
  "agentconsole.agent.assistant.recomend.action" : "Recommended Action",
  "agentconsole.agent.assistant.summary" : "Generate Summary",
  "agentconsole.agent.assistant.case" : "Generate Case",
  "agentconsole.agent.refine.simple" : "Simple",
  "agentconsole.agent.refine.formal" : "Formal",
  "agentconsole.agent.refine.friendly" : "Friendly",
  "agentconsole.agent.refine.humorous" : "Humorous",
  "agentconsole.agent.refine" : "Refine",
  "agentconsole.agent.please.notice" : "Please pay attention to the words",
  "agentconsole.agent.knowledge.recommend" : "Knowledge Recommendation",
  "agentconsole.agent.knowledge.map":"Map",
  "agentconsole.agent.knowledge.product.recommend" : "Recommended Products",
  "agentconsole.agent.knowledge.product.popular" : "Popular Products",
  "agentconsole.agent.knowledge.product.link" : "Discover Offer",
  "agentconsole.agent.complaint.risk" : "Complaint risk",
  "agentconsole.agent.call.context" : "Call Context",
  "agentconsole.agent.customer.info" : "Customer Info",
  "agentconsole.agent.session.monitor" : "Session Monitor",
  "agentconsole.agent.basic.info" : "Basic Information",
  "agentconsole.agent.customer.name" : "Customer Name",
  "agentconsole.agent.phone.number" : "Mobile Number",
  "agentconsole.agent.service.duration" : "Service Duration",
  "agentconsole.agent.customer.brand" : "Brand",
  "agentconsole.agent.customer.status" : "User status",
  "agentconsole.agent.customer.address": " Home Area",
  "agentconsole.agent.bussiness.info": " Service Information",
  "agentconsole.agent.order.status": " Subscription Status",
  "agentconsole.agent.business.usage": " Service Usage",
  "agentconsole.agent.terminal.profile": "Terminal Information",
  "agentconsole.agent.terminal.vendor": " Terminal Vendor",
  "agentconsole.agent.terminal.model": "Terminal model",
  "agentconsole.agent.network.standard": "Network Standard",
  "agentconsole.agent.generate.summary": "Generate Summary",
  "agentconsole.agent.summary": "Summary",
  "agentconsole.agent.submit.success": "Submit Success",
  "agentconsole.agent.intelligent.fill": "Intelligent TT filling",
  "agentconsole.agent.intelligent.click": "One-click ticket filling",
}