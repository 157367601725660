<template>
    <div>
        <sweet-dialog v-model="dialogVisible" :title="$t('isales.data.title.timeImport')" width="616px" class="aicc-dialog-limit-height">
            <sweet-form :model="form" ref="form" label-width="auto" :label-position="labelPosition" :rules="formRules">
                <sweet-form-item :label="$t('isales.management.title.timeImport.execTime')" required>
                  <sweet-time-select v-model="form.execTime" start="00:00" step="00:30" end="23:30" :placeholder="$t('isales.import.calldata.selecttime')" :clearable="false"/>
                </sweet-form-item>
                <sweet-form-item :label="$t('isales.management.message.import.server')" prop="serverIp" required>
                    <sweet-select v-model="form.serverIp" @change="selectServerIp" :fit-input-width="true" :show-resize="false">
                        <sweet-option v-for="item in serverIpList" :key="item.key" :label="item.value"
                                      :value="item.key"/>
                    </sweet-select>
                </sweet-form-item>
                <sweet-form-item v-if="this.selectServerType===2" >
                    <template #label>
                        <sweet-tooltip class="box-item" effect="light" :content="$t('isales.file.message.isDeleteFileTips')" :placement="topPlacement">
                            <div class="aicc-align--absolute-left">
                                <span>{{ $t('isales.file.message.isDeleteFile') }}</span>
                                <sweet-icon><warning style="transform: rotateX(180deg)" /></sweet-icon>
                            </div>
                        </sweet-tooltip>
                    </template>
                    <sweet-select v-model="form.needDelete" :fit-input-width="true" :show-resize="false">
                        <sweet-option v-for="item in NEED_DELETE_ITEMS" :key="item.key" :label="item.value"
                                      :value="item.key"/>
                    </sweet-select>
                </sweet-form-item>
                <sweet-form-item v-if="this.selectServerType!=2" prop="filePath">
                    <template #label>
                        <sweet-tooltip class="box-item" effect="light" :content="$t('isales.file.message.FilePathTips')" :placement="topPlacement">
                            <div class="aicc-align--absolute-left">
                                <span>{{ $t('isales.management.message.import.filePath') }}</span>
                                <sweet-icon><warning style="transform: rotateX(180deg)" /></sweet-icon>
                            </div>
                        </sweet-tooltip>
                    </template>
                    <sweet-input v-model="form.filePath" autocomplete="off" />
                </sweet-form-item>
                <sweet-form-item :label="$t('isales.management.message.import.filePath')" v-if="this.selectServerType==2" prop="filePath" required>
                    <sweet-input v-model="form.filePath" autocomplete="off"/>
                </sweet-form-item>
                <sweet-form-item :label="$t('isales.management.message.import.fileName')" prop="fileName" required>
                    <sweet-input v-model="form.fileName" autocomplete="off"/>
                </sweet-form-item>
                <sweet-form-item :label="$t('isales.management.message.filter')" required>
                    <sweet-select v-model="form.dataFilter" @change="dataFilterChange()" :fit-input-width="true" :show-resize="false">
                        <sweet-option v-for="item in DATA_FILTER" :key="item.key" :label="item.value"
                                      :value="item.key"/>
                    </sweet-select>
                    <sweet-button text type="primary" @click="filterRuleDialogVisible = true"
                                  v-if="this.form.dataFilter===1">{{
                        $t('isales.import.button.configProp') }}
                    </sweet-button>
                </sweet-form-item>
                <sweet-form-item :label="$t('isales.management.message.import.analyseMode')" required>
                    <sweet-radio-group v-model="form.analyseMode">
                        <sweet-radio v-for="item in ANALYSE_MODE" v-bind:key="item.value"
                                     :label=item.value :value="item.value" @change="analyseModeChange()">
                            {{ item.label }}
                        </sweet-radio>
                    </sweet-radio-group>
                </sweet-form-item>
                <sweet-form-item :label="$t('isales.management.message.import.mapRule')" v-if="form.analyseMode===2"
                                 prop="mapRule" required>
                    <sweet-select v-model="form.mapRule" :fit-input-width="true" :show-resize="false">
                        <sweet-option v-for="item in this.mapRuleList" :key="item.key" :label="item.value"
                                      :value="item.key"/>
                    </sweet-select>
                </sweet-form-item>
                <div v-if="form.analyseMode===1" style="background: #FBFBFB;padding: 12px;margin: 1rem 0">
                  <sweet-form-item style="margin-bottom: 0">
                    <template #label>
                      <sweet-tooltip :content="$t('isales.import.message.template.show')" placement="top">
                        <div class="aicc-align--absolute-left">
                          <span style="margin-right: 5px">{{ $t('isales.taskmanagement.title.importData.template') }}</span>
                          <sweet-icon><warning style="transform: rotateX(180deg);" /></sweet-icon>
                        </div>
                      </sweet-tooltip>
                    </template>
                    <div @click="download()">
                      <div class="aicc-align--absolute-center">
                        <i class="sweetui-icon-excel-f" style="font-size: 16px;color:#5ACC9B;margin-right: 5px"></i>
                        <span class="download-template-file-button">{{ $t('isales.management.message.download') }}</span>
                        <i class="sweetui-icon-download-l" style="font-size: 14px;margin-left: 5px"></i>
                      </div>
                    </div>
                  </sweet-form-item>
                </div>
                <sweet-form-item v-if="form.analyseMode===1" label=" " style="margin-top: -10px">
                  <span style="font-size: 12px;color: #707070;">{{ dataNumTips }}</span>
                </sweet-form-item>
            </sweet-form>
            <template #footer>
      <span class="dialog-footer">
        <sweet-button @click="dialogVisible = false">{{ $t('isales.common.title.cancel') }}</sweet-button>
        <sweet-button type="primary" @click="confirm">{{ $t('isales.common.title.confirm') }}</sweet-button>
      </span>
            </template>
        </sweet-dialog>
    </div>
    <filterRule v-if="filterRuleDialogVisible" v-model:show="filterRuleDialogVisible" v-model:filter-rule-string="filterRuleString" @filterRuleConfirm="filterRuleConfirm"></filterRule>
</template>

<script>

    import {ANALYSE_MODE, DATA_FILTER, MINUTE_ITEMS, NEED_DELETE_ITEMS} from "@/views/isales/views/taskManagement/enum";
    import {
      downloadTemplateUrl,
      queryCmsEnvironment, queryDataByTaskId,
      queryFileServerList,
      queryMapRuleList,
      queryTaskInfo, timeImport
    } from '@/views/isales/api/importCallData'
    import filterRule from '@/views/isales/components/OutboundCallData/filterRule.vue'
    import {aiccDownload} from '@/utils/aiccUploadAndDownload'
    import AiccElMessage from "@/utils/el-message";

    export default {
        name: 'ScheduledImportCallData',
        components: {
            filterRule
        },
        props: {
            show: {
                type: Boolean,
                default: false
            },
            marketingMode : {
                type: Number,
                default: 1
            },
            taskId : {
                type: String,
                default: '',
            },
            taskType : {
                type: String,
                default: '',
            },
            policyType : {
                type: String,
                default: '',
            }
        },
        data() {
            return {
              topPlacement: window.isRTL ? 'top-end' : 'top-start',
                labelPosition: window.isRTL ? 'right' : 'left',
                MINUTE_ITEMS,
                ANALYSE_MODE,
                DATA_FILTER,
                NEED_DELETE_ITEMS,
                filterRuleDialogVisible:false,
                hourItems: [],
                form: {
                    dataFilter: 2,
                    analyseMode: 1,
                    mapRule: '',
                    execTime: '00:00',
                },
                dataNumTips: $t('isales.import.message.template.dataNum', [500000]) + $t('isales.import.message.allow.task.statue'),
                mapRuleList:[],
                serverIpList:[],
                cmsEnvironment:'',
                selectServerType:1,
                filterRuleString:'',
                formRules:{
                    serverIp:[{required: true, message: this.$t('isales.business.result.message.must'), trigger: ['blur', 'change']}],
                    filePath:[{validator: this.filePathValidate, trigger: ['blur', 'change']}],
                    fileName:[{required: true, message: this.$t('isales.business.result.message.must'), trigger: ['blur', 'change']},
                        {validator: this.fileNameValidate, trigger: ['blur', 'change']}],
                    mapRule:[{required: true, message: this.$t('isales.business.result.message.must'), trigger: ['blur', 'change']}],
                },
            }
        },
        computed: {
            dialogVisible: {
                get() {
                    return this.show
                },
                set(val) {
                    this.$emit('update:show', val)
                }
            },
        },
        created() {
            console.log("created");
            this.inintHourEnum();
            this.initMapRuleList();
            this.initFileServerList();
            this.initCmsEnvironment();
            if (this.marketingMode === 1) {
                this.initTaskInfo();
            }
        },
        methods: {
            dataFilterChange(){
                console.log("dataFilterChange");
                if(this.form.dataFilter == 2){
                    this.filterRuleString = '';
                }
            },
            analyseModeChange(){
                console.log("analyseModeChange");
                if(this.form.analyseMode==2){
                    if(this.mapRuleList && this.mapRuleList.length>0) {
                        this.form.mapRule = this.mapRuleList[0].key;
                    }
                }else{
                    this.form.mapRule = '';
                }
            },
            fileNameValidate(rule, value, callback){
                var fileName = this.form.fileName;
                var myReg = /^[\u4e00-\u9fa5a-zA-Z0-9_]+$/;
                if (!myReg.test(fileName.substring(0, fileName.lastIndexOf(".")))) {
                    callback(new Error(this.$t('isales.import.message.uploadedfileNameformatvalidate')));
                }
                var fileNameFormat = fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length);
                if (fileNameFormat != "csv") {
                    callback(new Error(this.$t('isales.import.message.uploadedfileformatvalidate')));
                }else{
                    callback();
                }
            },
            filePathValidate(rule, value, callback){
                var myReg =  /^(\/\w+)+$/;
                if (this.form.filePath && !myReg.test(this.form.filePath)) {
                    callback(new Error(this.$t('isales.file.title.rightpath')));
                }else{
                    callback();
                }
            },
            inintHourEnum() {
                for (var i = 0; i < 24; i++) {
                    let hourEnum = {};
                    hourEnum["key"] = i.toString();
                    hourEnum["value"] = i.toString();
                    this.hourItems.push(hourEnum);
                }
                this.form.execTimeHour = this.hourItems[0].key;
                this.form.execTimeMinute = this.MINUTE_ITEMS[0].key;
            },
            selectServerIp(){
                console.log("selectServerIp");
                let selectItem = this.serverIpList.find(item=> item.key==this.form.serverIp);
                this.selectServerType = selectItem.serverType;
                if (this.selectServerType == 2) {
                    this.form.needDelete = 2;
                }
            },
            initMapRuleList() {
                console.log("queryMapRuleList");
                let params = {
                    "marketingMode": this.marketingMode
                }
                queryMapRuleList(params).then(res => {
                    for (let i = 0; i < res.length; i++) {
                        let ruleEnum = {};
                        ruleEnum["key"] = res[i]["ruleId"];
                        ruleEnum["value"] = res[i]["ruleName"];
                        this.mapRuleList.push(ruleEnum);
                    }
                    if(this.mapRuleList && this.mapRuleList.length>0){
                        this.form.mapRule = this.mapRuleList[0].key;
                    }
                });
            },
            initFileServerList() {
                console.log("initFileServerList");
                queryFileServerList().then(result => {
                    for (let i = 0; i < result.length; i++) {
                        let serverEnum = {};
                        serverEnum["key"] = result[i]["serverId"];
                        serverEnum["serverType"] = result[i]["serverType"];
                        if (result[i]["serverType"] == 1) {
                            serverEnum["value"] = result[i]["ip"] + ":" + result[i]["port"];
                        } else {
                            serverEnum["value"] = result[i]["endPoint"] + ":" + result[i]["bucket"];
                        }
                        this.serverIpList.push(serverEnum);
                    }
                    if (this.serverIpList.length > 0) {
                        this.form.serverIp = this.serverIpList[0].key;
                        this.selectServerType = this.serverIpList[0].serverType;
                        if (this.selectServerType == 2) {
                            this.form.needDelete = 2;
                        }
                    }
                })
            },
            filterRuleConfirm(data) {
                if (data) {
                    this.filterRuleString = JSON.stringify(data);
                }
            },
            initCmsEnvironment() {
                console.log("initCmsEnvironment");
                queryCmsEnvironment().then(res => {
                    this.cmsEnvironment = res.cmsEnvironment;
                });
            },
            initTaskInfo() {
                console.log("initTaskInfo");
                let searchObject = {};
                if (this.taskId) {
                    searchObject["taskId"] = this.taskId;
                }
                let params = {
                    "dataObject": searchObject,
                    "limit": 10,
                    "offset": 0,
                }
                queryTaskInfo(params).then(result => {
                    if (result && result.length > 0) {
                        this.extPropEnable = result[0].extPropEnable;
                    }
                });
            },
            async confirm(){
                const arr = this.form.execTime.split(":");
                this.form.execTimeHour = parseInt(arr[0]).toString();
                this.form.execTimeMinute = arr[1];
                console.log("confirm");
                this.$refs['form'].validate(valid => {
                    if (!valid) {
                        return;
                    }
                    if (this.form.analyseMode == 1) {
                        if (this.extPropEnable == 1) {
                            const params = {
                                "limit": 10,
                                "offset": 0,
                                "dataDO": {"taskId": this.taskId}
                            };
                            queryDataByTaskId(params).then(response => {
                                if (response && response.datas && response.datas.length > 0 ){
                                    this.repeatClick = false;
                                    AiccElMessage({
                                        type: 'error',
                                        duration: 10000,
                                        offset: 56,
                                        'show-close': true,
                                        message: this.$t('isales.import.message.ruleExtProp')
                                    });
                                    return;
                                }
                                this.repeatClick = false;
                                AiccElMessage({
                                    type: 'error',
                                    duration: 10000,
                                    offset: 56,
                                    'show-close': true,
                                    message: this.$t('isales.import.message.closeExtProp')
                                });
                                return;
                            });
                            return;
                        }
                        this.form.mapRule = "";
                    }
                    if(this.form.dataFilter && this.form.dataFilter == 1 && !this.filterRuleString){
                        AiccElMessage({
                            type: 'error',
                            offset: 56,
                            duration: 10000,
                            'show-close': true,
                            message: this.$t('isales.import.filter.message.selectRuleTips')
                        });
                        return;
                    }
                    let params = {
                        "taskId": this.taskId,
                        "hour": this.form.execTimeHour,
                        "minute": this.form.execTimeMinute,
                        "serverId": this.form.serverIp,
                        "filePath": this.form.filePath,
                        "fileName": this.form.fileName,
                        "analyseMode": this.form.analyseMode,
                        "mapId": this.form.mapRule,
                        "serverType": this.selectServerType,
                        "needDelete": this.form.needDelete,
                        "isFilter": this.form.dataFilter,
                        "filterRule" : this.filterRuleString,
                        "marketingMode":this.marketingMode
                    }
                    if (this.selectServerType == 2) {
                        params.filePath = this.obsFilePath;
                    }
                    timeImport(params).then(res =>{
                        if (res.returnCode == "0") {
                            AiccElMessage({
                                type: 'success',
                                duration: 5000,
                                offset: 56,
                                message: res.description
                            });
                            this.dialogVisible = false;
                        } else if (res.returnCode == "4") {
                            AiccElMessage({
                                type: 'error',
                                duration: 10000,
                                'show-close': true,
                                offset: 56,
                                message: res.description
                            });
                        } else {
                            AiccElMessage({
                                type: 'error',
                                duration: 10000,
                                'show-close': true,
                                offset: 56,
                                message: res.description
                            });
                        }
                    })
                });
            },
            download() {
                let params = {"marketingMode": this.marketingMode};
                if (this.marketingMode === 1) {
                    params["taskType"] = this.taskType;
                    params["policyType"] = this.policyType;
                } else if (this.marketingMode === 2) {
                    let notificationParams = {
                        "mediaType": this.mediaType,
                        "notificationId": this.taskId
                    }
                    params["notificationParams"] = notificationParams;
                }
                aiccDownload(params, downloadTemplateUrl);
            },
        }
    }
</script>

<style lang="less" scoped>
.download-template-file-button {
  color: var(--text-color-highlighting);
  cursor: pointer;
}
</style>