import request from '@/utils/request'


export const downloadContactRecordUrl = '/service-cloud/u-route/ccmanagement/contactrecord/downloadContactRecord'
export const downloadFromUAPUrl = '/service-cloud/u-route/ccmanagement/fileaction/downloadFromUAP'// 下载接触记录录音
export const recordVoiceUrl = '/service-cloud/u-route/ccmanagement/recordplay/voice?recordId='//播放接触记录录音
export const associateCallRecordVoiceUrl = '/service-cloud/u-route/ccmanagement/recordplay/recordext?associateCall='
export const recordVideoUrl = '/service-cloud/u-route/ccmanagement/recordplay/video?recordId='

/* ==========================
      座席相关接口 Start
   ========================== */
export const queryAgentRoleItems = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/ccagent/queryAgentRoleItems',
    method: 'post',
    data
  })
}

export const queryAllAgent = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/ccagent/queryAllAgent',
    method: 'post',
    data
  })
}

export const queryAllCCAgentCount = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/ccagent/queryAllCCAgentCount',
    method: 'post',
    data
  })
}

export const queryAgentInfoById = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/ccagent/queryAgentInfoById',
    method: 'post',
    data
  })
}

export const queryTenantFeaturesForWeb = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/tenantInfo/queryTenantFeaturesForWeb',
    method: 'post',
    data
  })
}

export const queryCustomerCenterForUser = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/tenantInfo/queryCustomerCenterForUser',
    method: 'post',
    data
  })
}

export const queryTenantSpaceLocale = tenantSpaceId => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/tenantInfo/queryTenantSpaceLocale/' + tenantSpaceId,
    method: 'get'
  })
}

export const queryAiRecog = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/ccagent/queryAiRecog',
    method: 'post',
    data
  })
}

export const validateTransparentDisplayFlag = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/ccagent/validateTransparentDisplayFlag',
    method: 'post',
    data
  })
}

export const saveCCAgent = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/ccagent',
    method: 'put',
    data
  })
}

export const queryAgentBeBindAccount = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/ccagent/queryAgentBeBindAccount',
    method: 'post',
    data
  })
}

export const ccagentClear = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/ccagent/clear',
    method: 'put',
    data
  })
}

export const batchUpdateAgentSegment = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/ccagent/batchUpdateAgentSegment',
    method: "POST",
    data,
  })
}

export const batchUpdateAgentApi = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/ccagent/batchUpdateAgent',
    method: "POST",
    data,
  })
}

export const modifySingleAgentPwdById = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/ccagent/modifySingleAgentPwdById',
    method: "POST",
    data,
  })
}

export const batchModifyPwdByIds = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/ccagent/batchModifyPwdByIds',
    method: "POST",
    data,
  })
}

export const generateExportAgentInfoTask = data => {
  return request({
    url: "/service-cloud/rest/cc-management/v1/ccagentexport/generateExportAgentInfoTask",
    method: "post",
    data
  })
}

export const queryAgentExportTask = data => {
  return request({
    url: "/service-cloud/rest/cc-management/v1/ccagentexport/queryAgentExportTask/page",
    method: "post",
    data
  })
}

/**
 * 查询坐席工作台接触记录
 * @param data 查询参数
 * @returns {Promise<*>}
 */
export const queryAgentContactRecord = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/contactextrecord/queryAgentContactRecord',
    method: "POST",
    data,
  })
}

/**
 * 根据工号集合批量查询坐席信息列表
 * @param data 请求body参数
 * @returns {Promise<*>}
 */
export const queryAccountNameByWorkNos = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/ccagent/queryAccountNameByWorkNos',
    method: "POST",
    data
  })
}

/**
 * 查询所有的操作记录
 * @param data 请求的body参数
 * @return {Promise<*>}
 */
export const queryAllOperationRecord = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/operationrecord/queryAllOperationRecord',
    method: "POST",
    data,
  })
}

/**
 * 查询操作记录总数 - 管理员
 * @param data 请求的body参数
 * @return {Promise<number|*>}
 */
export const queryAllOperationRecordCount = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/operationrecord/queryAllOperationRecordCount',
    method: "POST",
    data,
  })
}

/**
 * 查询操作记录总数 - 普通坐席
 * @param data 请求的body参数
 * @return {Promise<number | any>}
 */
export const queryOperationRecordCount = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/operationrecord/queryOperationRecordCount',
    method: "POST",
    data,
  })
}

/**
 * 查询所有的座席休息记录（会根据权限查询自己个人的，还是查询管理员下所有座席的）
 *
 * @param data 查询条件
 * @return 查询结果列表，同时包含是否管理员权限信息
 */
export const queryAllLeaveRecord = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/leaverecord/queryAllLeaveRecord',
    method: "POST",
    data,
  })
}

/**
 * 查询座席休息记录总数 - 管理员
 *
 * @param data 查询条件
 * @return 座席休息记录总数
 */
export const queryAllLeaveRecordCount = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/leaverecord/queryAllLeaveRecordCount',
    method: "POST",
    data,
  })
}

/**
 * 查询座席休息记录总数 - 普通坐席
 *
 * @param data 查询条件
 * @return 座席休息记录总数
 */
export const queryLeaveRecordCount = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/leaverecord/queryLeaveRecordCount',
    method: "POST",
    data,
  })
}
/* ==========================
      座席相关接口 end
   ========================== */

/* ==========================
      技能队列相关接口 Start
   ========================== */
/**
 * 查询全部技能队列
 * @param data 请求参数
 * @return {Promise<*>}
 */
export const getAllSkillList = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/skill/getAllSkillList',
    method: 'post',
    data,
  })
}

export const createSkill = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/skill/createSkill',
    method: 'post',
    data
  })
}

/**
 * 查询技能队列
 *
 * @param data
 * @returns {*}
 */
export const queryAllSkill = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/skill/queryAllSkill',
    method: 'post',
    data,
  })
}

export const queryAllSkillInfoCount = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/skill/queryAllSkillInfoCount',
    method: 'post',
    data,
  })
}

export const querySkillExportTask = data => {
  return request({
    url: "/service-cloud/rest/cc-management/v1/skill/querySkillExportTask",
    method: "post",
    data
  })
}

export const createSkillExportTask = data => {
  return request({
    url: "/service-cloud/rest/cc-management/v1/skill/createSkillExportTask",
    method: "post",
    data
  })
}

/**
 * 查询技能队列 (用于技能组添加技能队列时)
 *
 * @param data
 * @returns {*}
 */
export const getAllSkill = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/skill/getAllSkill',
    method: 'post',
    data,
  })
}

/**
 * 同步CTI和DB的技能队列
 *
 * @returns {*}
 */
export const updateCtiAndDb = () => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/skill/updateCtiAndDb',
    method: 'post',
  })
}

/**
 * 查询技能队列数量
 *
 * @param data 查询参数
 * @returns {*}
 */
export const queryAllSkillInfoCountByName = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/skill/queryAllSkillInfoCountByName',
    method: 'post',
    data,
  })
}

/**
 * 删除技能队列
 *
 * @param data 待删除id列表
 * @returns {*}
 */
export const deleteSkillByIds = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/skill',
    method: 'delete',
    data,
  })
}

/**
 * 编辑技能队列
 *
 * @param data 待修改信息
 * @returns {*}
 */
export const modifySkillByIds = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/skill',
    method: 'put',
    data,
  })
}

/**
 * 根据skillid查询技能队列
 *
 * @param data 查询参数
 * @returns {*}
 */
export const querySkillInfoBySkillId = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/skill/querySkillInfoBySkillId',
    method: 'post',
    data,
  })
}
/* ==========================
      技能队列相关接口 end
   ========================== */

/* ==========================
      接触记录相关接口 Start
   ========================== */
export const queryAllContactRecord = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/contactextrecord/queryAllContactRecord',
    method: 'post',
    data
  })
}

/**
 * 坐席呼叫明细记录查询
 * @param data 查询参数
 * @returns { Promise<*> }
 */
export const queryContactDetail = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/contactextrecord/queryContactDetail',
    method: 'post',
    data
  });
};

/**
 * 初始化接触记录配置项
 * @param data 查询参数
 * @returns { Promise<*> }
 */
export const initContactItemConfig = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/contactitemcfg/intContactItemConfig',
    method: 'post',
    data
  })
}

/**
 * 查询符合条件的所有接触记录数量
 * @param data
 * @returns {Promise<number> | Promise<AxiosResponse<*>>}
 */
export const queryAllContactCount = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/contactextrecord/queryAllContactCount',
    method: 'post',
    data,
  });
}


/**
 * 查询转写内容
 * @param data 查询参数
 * @returns { Promise<*> }
 */
export const checkIntelligentList = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/contactextrecord/checkIntelligentList',
    method: 'post',
    data
  })
}

/**
 * 查询符合条件的所有接触记录数量
 * @param data
 * @returns {Promise<number> | Promise<AxiosResponse<*>>}
 */
export const queryContactExtNumRecord = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/contactextrecord/queryContactExtNumRecord',
    method: "POST",
    data,
  })
}

/**
 * 校验接触记录的导出权限
 * @returns {Promise<boolean> | Promise<AxiosResponse<*>>}
 */
export const checkContractExportAuth = () => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/contactrecordexport/checkExportAuth',
    method: "GET",
  })
};

/**
 * 查询接触记录详情
 * @param data 查询参数
 * @return {Promise<AxiosResponse<*>>}
 */
export const queryContactExtRecord = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/contactextrecord/queryContactExtRecord',
    method: "POST",
    data,
  });
};

/**
 * 查询智能转写记录数
 * @param data 查询参数
 * @return {Promise<number> | Promise<AxiosResponse<*>>}
 */
export const queryIntelligentIdentifyRecordCount = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/contactextrecord/queryIntelligentIdentifyRecordCount',
    method: "POST",
    data,
  });
};

/**
 * ccmessaging查询所有消息记录
 * @param params 查询参数
 * @return {Promise<*>}
 */
export const queryChatMessageByCallId = (params) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/contactextrecord/queryChatMessageByCallId',
    method: "POST",
    data: params,
  })
}
/* ==========================
      接触记录相关接口 end
   ========================== */


/* ==========================
      呼叫原因相关接口 start
   ========================== */
/**
 * 查询所有的呼叫原因
 * @return {Promise<*>}
 */
export const queryCallReasonInfo = () => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/callreason/queryCallReasonInfo',
    method: 'post',
  })
}
/* ==========================
      呼叫原因相关接口 end
   ========================== */

/**
 * 查询默认满意度配置
 *
 * @return {Promise<Array<any>>}
 */
export const queryDefaultSatisfactionConfig = () => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/satisfactionconfig/queryDefaultConfig',
    method: 'get',
  })
}

export const querySatisfactionTemplateApi = () => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/satisfactionconfig/querySatisfactionTemplate',
    method: 'get',
  })
}

export const querySatisfactionConfigApi = () => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/satisfactionconfig/query',
    method: 'post',
  })
}

export const queryMessageTemplateApi = (data) => {
  return request({
    url: '/service-cloud/rest/ccnotification/v1/messageTemplate/queryMessageTemplate',
    method: 'post',
    data
  })
}

export const updateMediaConfigApi = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/satisfactionconfig/updateMediaConfig',
    method: 'post',
    data
  })
}

export const queryMediaConfigApi = () => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/satisfactionconfig/queryMediaConfig',
    method: 'post',
  })
}

export const createSatisfactionConfigApi = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/satisfactionconfig/create',
    method: 'post',
    data
  })
}

export const deleteStrategyGroupSrv = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/satisfystrategygroupsrv/delete',
    method: 'post',
    data
  })
}

export const resetSatisfyTemplateApi = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/satisfactionconfig/resetSatisfyTemplate',
    method: 'post',
    data
  })
}

export const getPermsApi = (tenantSpaceId, userId) => {
  return request({
    url: `/service-cloud/rest/sum/v2/tenantSpaces/${tenantSpaceId}/users/${userId}/perms`,
    method: 'GET',
  })
}

export const queryLightweightScriptsApi = (data) => {
  return request({
    url: '/service-cloud/rest/ccsurvey/v1/lightweight/queryLightweightScripts',
    method: 'post',
    data
  })
}

export const querySatisfyStrategyDataConf = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/satisfystrategydataconf/query',
    method: 'post',
    data
  })
}

export const querySatisfyStrategyDataConfByPage = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/satisfystrategydataconf/queryByPage',
    method: 'post',
    data
  })
}

export const createSatisfyStrategyDataConf = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/satisfystrategydataconf/create',
    method: 'post',
    data
  })
}

export const updateSatisfyStrategyDataConf = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/satisfystrategydataconf/update',
    method: 'put',
    data
  })
}

export const createSatisfyStrategyGroup = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/satisfystrategygroup/create',
    method: 'post',
    data
  })
}

export const deleteSatisfyStrategyDataConf = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/satisfystrategydataconf/delete',
    method: 'post',
    data
  })
}

export const queryStrategySkillInfosCount = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/satisfystrategydataconf/querySkillInfosCount',
    method: 'post',
    data
  })
}

export const deleteSatisfyStrategyDataConfByIds = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/satisfystrategydataconf/deletebyids',
    method: 'post',
    data
  })
}

export const querySatisfyChannelList = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/satisfactionconfig/queryChannelList',
    method: 'get',
    data
  })
}

export const modifySatisfyStrategyDataConf = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/satisfystrategydataconf/modify',
    method: 'put',
    data
  })
}

export const queryChannelCodeName = (data) => {
  return request({
    url: '/service-cloud/rest/ccmessaging/v1/channelconfig/queryChannelCodeName',
    method: 'post',
    data
  })
}

export const createStrategyGroupSrv = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/satisfystrategygroupsrv/create',
    method: 'post',
    data
  })
}

export const updateStrategyGroupSrv = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/satisfystrategygroupsrv/update',
    method: 'post',
    data
  })
}

/* ==========================
      基础表相关接口 start
   ========================== */
/**
 * 查询当前租间下的满意度级别
 *
 * @return {Promise<Array<any>>}
 */
export const queryCustomTable = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/customTable/queryCustomTables',
    method: 'post',
    data
  })
}

export const queryCustomTableBaseInfo = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/customTable/queryCustomTableBaseInfo',
    method: 'post',
    data
  })
}

export const deleteCustomTableColumn = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/customTable/deleteCustomTableColumn',
    method: 'post',
    data
  })
}

export const queryCustomTableColumnInfo = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/customTable/queryCustomTableColumnInfo',
    method: 'post',
    data
  })
}

export const arrangeCustomTableColumn = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/customTable/arrangeCustomTableColumn',
    method: 'post',
    data
  })
}

export const querySatisfactionLevelConfig = () => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/satisfactionconfig/querySatisfactionLevel',
    method: 'get',
  })
}

export const resetSatisfactionLevelApi = () => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/satisfactionconfig/resetSatisfactionLevel',
    method: 'post',
  })
}

export const initSatisfyTemplate = () => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/satisfactionconfig/initSatisfyTemplate',
    method: 'post',
  })
}

export const updateSatisfactionLevelConfig = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/satisfactionconfig/update',
    method: 'post',
    data
  })
}

export const querySatisfyStrategyGroup = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/satisfystrategygroup/query',
    method: 'post',
    data
  })
}

export const querySatisfyStrategyGroupCount = () => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/satisfystrategygroup/querycount',
    method: 'get',
  })
}

export const querySatisfyStrategyGroupSrv = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/satisfystrategygroupsrv/query',
    method: 'post',
    data
  })
}

export const querySatisfyStrategyGroupSrvCount = () => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/satisfystrategygroupsrv/querycount',
    method: 'get',
  })
}

export const deleteSatisfyStrategyGroup = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/satisfystrategygroup/delete',
    method: 'post',
    data
  })
}

export const updateSatisfactionSurvey = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/satisfactionconfig/updateSatisfactionSurvey',
    method: 'post',
    data
  })
}

export const updateSatisfactionLevel = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/satisfactionconfig/updateSatisfactionLevel',
    method: 'post',
    data
  })
}

export const updateSatisfyTemplate = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/satisfactionconfig/updateSatisfyTemplate',
    method: 'post',
    data
  })
}

export const queryCatalogList = (data) => {
  return request({
    url: '/service-cloud/rest/ipccprovision/v1/sysparamCatalog/querySysparamCatalogByTenant',
    method: 'get',
    data
  })
}

export const queryTenantSysParam = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/sysparam/tenant/querySysparam',
    method: 'post',
    data
  })
}

export const updateTenantSysParam = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/sysparam/tenant/updateSysparam',
    method: 'post',
    data
  })
}

export const resetTenantSysParam = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/sysparam/tenant/resetSysparam',
    method: 'post',
    data
  })
}

export const refreshTenantSysParam = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/sysparam/tenant/refreshSysparam',
    method: 'post',
    data
  })
}

/* ==========================
      录制相关接口 start
   ========================== */
/**
 * 查询录音信息
 * @param data 查询参数
 * @return {Promise<Array<*>> | Promise<*>} 响应结果
 */
export const queryRecordInfo = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/recordinfo/queryRecordInfo',
    method: "POST",
    data,
  })
}
/* ==========================
      录制相关接口 end
   ========================== */

export const ccmImportTemplate = data => {
  return request({
    url: '/service-cloud/u-route/ccmanagement/basicdata/uploadDataFile',
    method: 'post',
    data
  })
}

export const queryUserCallReason = () => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/callreason/queryUserCallReason',
    method: 'post'
  })
}

export const checkCaseTypes = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/callreason/checkCaseTypes',
    method: 'post',
    data
  })
}

/**
 * 根据callid查询呼叫流水号
 *
 * @param data 请求体
 * @return {Promise<*>}
 */
export const queryCallSerialNo = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/contactextrecord/queryCallSerialNo',
    method: 'post',
    data
  })
}

/**
 * 根据呼叫流水号，修改会话原因信息
 * @param data 请求体
 * @returns {Promise<*>}
 */
export const updateContactExtTalkInfoByCallSerialno = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/contactextrecord/updateContactExtTalkInfoByCallSerialno',
    method: 'POST',
    data,
  })
}

export const updateContactExtTalkInfo = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/contactextrecord/updateContactExtTalkInfo',
    method: 'post',
    data
  })
}

export const queryWorkOrderByCode = () => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/callreason/queryWorkOrderByCode',
    method: 'post'
  })
}

export const querySpecialListType = () => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/specialList/querySpecialListType',
    method: 'get'
  })
}

export const queryContactInfo = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/specialList/bother/queryContactInfo',
    method: 'post',
    data
  })
}

export const botherAdd = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/specialList/bother/add',
    method: 'post',
    data
  })
}

/**
 * 查询技能组
 *
 * @param data
 * @returns {*}
 */
export const querySkillGroupCondition = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/skillgroup/queryCondition',
    method: 'post',
    data
  })
}

/**
 * 创建技能组
 *
 * @param data
 * @returns {*}
 */
export const createSkillGroup = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/skillgroup/create',
    method: 'post',
    data
  })
}

/**
 * 查询技能组技能权重等信息
 *
 * @param data
 * @returns {*}
 */
export const queryGroupSkillInfo = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/skillgroup/queryGroupSkillInfo',
    method: 'post',
    data
  })
}

/**
 * 修改技能组
 *
 * @param data
 * @returns {*}
 */
export const updateSkillGroup = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/skillgroup/update',
    method: 'post',
    data
  })
}

/**
 * 删除技能组
 *
 * @param data
 * @returns {*}
 */
export const deleteSkillGroup = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/skillgroup/batchDelete',
    method: 'post',
    data
  })
}

/**
 * 总数技能组
 *
 * @param data
 * @returns {*}
 */
export const countSkillGroup = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/skillgroup/querySkillGroupCountCondition',
    method: 'post',
    data
  })
}

/**
 * 同步
 *
 * @param data
 * @returns {*}
 */
export const refreshSkillGroup = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/skillgroup/updateCtiAndDb',
    method: 'post',
    data
  })
}

/* ============
    转储记录任务 开始
   ============ */
/**
 * 分页查询资源转储任务记录
 * @param data 查询参数
 * @returns {Promise<Number>|Promise<AxiosResponse<*>>}
 */
export const queryTransTaskRecordByPage = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/transtaskrecord/queryTransTaskRecord',
    method: "POST",
    data,
  })
}

/**
 * 重新执行资源转储任务
 * @param data 查询参数
 * @returns {Promise<Number>|Promise<AxiosResponse<*>>}
 */
export const runJobAgain = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/transtaskrecord/runJobAgain',
    method: "delete",
    data,
  })
}

/**
 * 查询除未启用之外的资源转储任务
 * @param data 查询参数
 * @returns {Promise<Number>|Promise<AxiosResponse<*>>}
 */
export const queryTransTaskByTaskRecord = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/transtaskconf/queryTransTaskByTaskRecord',
    method: "post",
    data,
  })
}

/* ============
    骚扰记录管理 开始
   ============ */
/**
 * 分页查询骚扰记录
 * @param data 查询参数
 * @returns {Promise<Number>|Promise<AxiosResponse<*>>}
 */
export const queryBotherRecordByPage = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/specialList/bother/page',
    method: "POST",
    data,
  })
}

/**
 * 查询特殊名单级别名称接口
 * @param data 查询参数
 * @returns {Promise<Number>|Promise<AxiosResponse<*>>}
 */
export const querySpecialLevelNameMap = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/specialLevel/querySpecialLevel',
    method: "GET",
    data: { "typeIds": data },
  })
}

/**
 * 根据骚扰记录id，查询骚扰记录信息
 * @param data 查询参数
 * @returns {Promise<Number>|Promise<AxiosResponse<*>>}
 */
export const queryBotherRecordByRecId = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/specialList/bother/' + data,
    method: "GET",
  })
}

/**
 * 查询特殊名单详情
 * @param data 查询参数
 * @returns {Promise<Number>|Promise<AxiosResponse<*>>}
 */
export const querySpecialRecordDetailById = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/specialList/' + data,
    method: "GET",
  })
}

/**
 * 骚扰记录审核
 * @param data 查询参数
 * @returns {Promise<Number>|Promise<AxiosResponse<*>>}
 */
export const approveBotherRecord = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/specialList/bother/approve',
    method: "put",
    data: data
  })
}

/* ============
    被叫配置 开始
   ============ */
/**
 * 查询租户下的被叫配置下的接入码信息
 * @param data 查询参数
 * @returns {Promise<Number>|Promise<AxiosResponse<*>>}
 */
export const queryCalledRoute = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/calledRoute/queryCalledRoute',
    method: "POST",
    data,
  })
}

/**
 * 查询被叫配置总数
 * @param data 查询参数
 * @returns {Promise<Number>|Promise<AxiosResponse<*>>}
 */
export const queryCalledRouteCount = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/calledRoute/queryCalledRouteCount',
    method: "POST",
    data,
  })
}

/**
 * 刷新被叫配置
 * @param data 查询参数
 * @returns {Promise<Number>|Promise<AxiosResponse<*>>}
 */
export const refreshCalledRoute = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/calledRoute/refreshCalledRoute',
    method: "POST",
    data,
  })
}

/**
 * 删除被叫配置
 * @param data 查询参数
 * @returns {Promise<Number>|Promise<AxiosResponse<*>>}
 */
export const deleteCalledRoute = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/calledRoute',
    method: "DELETE",
    data,
  })
}

/**
 * 根据配置编号查询被叫配置
 * @param data 查询参数
 * @returns {Promise<Number>|Promise<AxiosResponse<*>>}
 */
export const queryCalledRouteByDnId = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/calledRoute/queryCalledRouteByDnId',
    method: "POST",
    data,
  })
}

/**
 * 查询所有接入码（用于被叫配置）
 * @param data 查询参数
 * @returns {Promise<Number>|Promise<AxiosResponse<*>>}
 */
export const getAllAccessCode = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/accessCodeInfo/getAllAccessCode',
    method: "POST",
    data,
  })
}

/**
 * 修改被叫配置
 * @param data 查询参数
 * @returns {Promise<Number>|Promise<AxiosResponse<*>>}
 */
export const modifyCalledRoute = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/calledRoute',
    method: "PUT",
    data,
  })
}

/**
 * 新增被叫配置
 * @param data 查询参数
 * @returns {Promise<Number>|Promise<AxiosResponse<*>>}
 */
export const createCalledRoute = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/calledRoute',
    method: "POST",
    data,
  })
}

/* ============
    转接外部号码配置 开始
   ============ */
/**
 * 查询转接外部号码配置
 * @param data 查询参数
 * @param size
 * @param current
 * @returns {Promise<Number>|Promise<AxiosResponse<*>>}
 */
export const transferextnumber = (data, size, current) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/transferextnumber/page/size/' + size + '/current/' + current,
    method: "POST",
    data,
  })
}

/**
 * 删除转接外部号码配置
 * @param data 查询参数
 * @returns {Promise<Number>|Promise<AxiosResponse<*>>}
 */
export const deleteTransferExtNumber = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/transferextnumber/delete',
    method: "POST",
    data,
  })
}

/**
 * 修改转接外部号码配置
 * @param data 查询参数
 * @returns {Promise<Number>|Promise<AxiosResponse<*>>}
 */
export const modifyTransferExtNumber = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/transferextnumber/modify',
    method: "put",
    data,
  })
}

/**
 * 新增转接外部号码配置
 * @param data 查询参数
 * @returns {Promise<Number>|Promise<AxiosResponse<*>>}
 */
export const addTransferExtNumber = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/transferextnumber/add',
    method: "POST",
    data,
  })
}

/**
 * 查询指定转接外部号码配置
 * @param id 查询参数
 * @returns {Promise<Number>|Promise<AxiosResponse<*>>}
 */
export const getTransferExtNumber = (id) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/transferextnumber/get/' + id,
    method: "POST",
  })
}

/* ============
    接入码 开始
   ============ */
/**
 /**
 * 查询所有接入码信息
 * @param data 查询参数
 * @returns {Promise<Number>|Promise<AxiosResponse<*>>}
 */
export const getAccessCodeList = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/accessCodeInfo/getAccessCodeList',
    method: "POST",
    data,
  })
}

/**
 * 查询接入码总数
 * @param data 查询参数
 * @returns {Promise<Number>|Promise<AxiosResponse<*>>}
 */
export const getAccessCodeListCount = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/accessCodeInfo/getAccessCodeListCount',
    method: "POST",
    data,
  })
}

/**
 * 修改接入码描述
 * @param data 查询参数
 * @returns {Promise<Number>|Promise<AxiosResponse<*>>}
 */
export const modifyAccessCodeDescription = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/accessCodeInfo',
    method: "PUT",
    data,
  })
}

/* ============
    UC集成 开始
   ============ */
/**
 /**
 * 保存UC集成配置
 * @param data 参数
 * @returns {*}
 */
export const openUCIntegratorConfig = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/ucIntegratorConfig/openUCIntegratorConfig',
    method: "POST",
    data,
  })
}

/**
 * 查询id
 * @param data 查询参数
 * @returns {*}
 */
export const queryUCIntegratorConfig = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/ucIntegratorConfig/queryUCIntegratorConfig',
    method: "POST",
    data,
  })
}

/* ============
    提示音 开始
   ============ */
/**
 /**
 * 查询提示音
 * @param data 查询参数
 * @returns {*}
 */
export const queryAllIvrVoice = (data) => {
  return request({
    url: '/oifde/rest/cc-ivr/v1/voicemanageservice/queryAllIvrVoice',
    method: "POST",
    data,
  })
}

/**
 * 查询提示音数量
 * @param data 查询参数
 * @returns {*}
 */
export const queryAllCCIvrCount = (data) => {
  return request({
    url: '/oifde/rest/cc-ivr/v1/voicemanageservice/queryAllCCIvrCount',
    method: "POST",
    data,
  })
}

/**
 * 修改座席回铃音配置
 * @param data 参数
 * @returns {*}
 */
export const modifyNoteConfig = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/agentnote/modifyNoteConfig',
    method: "PUT",
    data,
  })
}

/**
 * 查询座席回铃音配置
 * @param data 参数
 * @returns {*}
 */
export const queryNoteConfig = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/agentnote/queryNoteConfig',
    method: "POST",
    data,
  })
}

/**
 * 查询页面消息提醒配置
 * @param data 参数
 * @returns {*}
 */
export const queryHidePageConfig = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/agentnote/queryHidePageConfig',
    method: "POST",
    data,
  })
}

/**
 * 修改页面消息提醒配置
 * @param data 参数
 * @returns {*}
 */
export const modifyHidePageConfig = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/agentnote/modifyHidePageConfig',
    method: "PUT",
    data,
  })
}

/**
 * 开关关闭时，保存方法
 * @param data 参数
 * @returns {*}
 */
export const closeUCIntegratorConfig = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/ucIntegratorConfig/closeUCIntegratorConfig',
    method: "POST",
    data,
  })
}

/* ============
    来电原因 开始
   ============ */
/**
 * 更新来电原因
 * @param data 参数
 * @returns {*}
 */
export const updateCallReason = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/callreason/updateCallReason',
    method: "POST",
    data,
  })
}

/**
* 上下移来电原因
* @param data 参数
* @returns {*}
*/
export const saveCallReason = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/callreason/saveCallReason',
    method: "POST",
    data,
  })
}

/**
* 删除来电原因
* @param data 参数
* @returns {*}
*/
export const deleteCallReason = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/callreason/deleteCallReason',
    method: "POST",
    data,
  })
}

/**
* 删除来电原因
* @param data 参数
* @returns {*}
*/
export const moveUpOrDownCallReason = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/callreason/moveUpOrDown',
    method: "POST",
    data,
  })
}

/* ============
    租户信息 开始
   ============ */
/**
 * 查询时区信息
 * @param data 参数
 * @returns {*}
 */
export const queryTimeZone = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/tenantTimeZone/queryTimeZone',
    method: "POST",
    data,
  })
}

/**
 * 查询租户所有信息
 * @param data 参数
 * @returns {*}
 */
export const queryAllTenantInfo = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/tenantInfo/queryAllTenantInfo',
    method: "POST",
    data,
  })
}

/**
 * 查询租户标识
 * @param data 参数
 * @returns {*}
 */
export const queryTenantIdentification = (data) => {
  return request({
    url: '/service-cloud/rest/cc-ai/v1/tenantidentification/queryTenantIdentification',
    method: "POST",
    data,
  })
}

/**
 * 查询用户信息
 * @param tenantSpaceId 参数
 * @param currentUserId 参数
 * @returns {*}
 */
export const getUserInfo = (tenantSpaceId, currentUserId) => {
  return request({
    url: `/service-cloud/rest/sum/v2/tenantSpaces/${tenantSpaceId}/users/${currentUserId}`,
    method: "GET",
  })
}

/**
 * 查询机器人信息
 * @param flowAccessCode 参数
 * @returns {*}
 */
export const getChatBotInfo = (flowAccessCode) => {
  return request({
    url: `/service-cloud/rest/cc-management/v1/chatBot/${flowAccessCode}/info`,
    method: "GET",
  })
}

/**
 * 分页查询选择机器人的数据
 * @param data  参数
 * @returns {*}
 */
export const getChatBotPage = (data) => {
  return request({
    url: `/service-cloud/rest/cc-management/v1/chatBot/page`,
    method: "POST",
    data
  })
}

/**
 * 修改呼叫中心值
 * @param data 参数
 * @returns {*}
 */
export const modifyVCallCenterFault = (data) => {
  return request({
    url: `/service-cloud/rest/cc-management/v1/virtualcallcenter/modifyVCallCenterFault`,
    method: "POST",
    data,
  })
}

/**
 * 修改移动客服特性
 * @param data 参数
 * @returns {*}
 */
export const modifyMobileAgentFeature = (data) => {
  return request({
    url: `/service-cloud/rest/cc-management/v1/customerNumer/modifyMobileAgentFeature`,
    method: "POST",
    data,
  })
}

/**
 * 修改语言通知特性
 * @param data 参数
 * @returns {*}
 */
export const modifyVoiceNotificationFeature = (data) => {
  return request({
    url: `/service-cloud/rest/cc-management/v1/customerNumer/modifyVoiceNotificationFeature`,
    method: "POST",
    data,
  })
}

/**
 * 修改匿名外呼特性
 * @param data 参数
 * @returns {*}
 */
export const modifyAnonymousCallFeature = (data) => {
  return request({
    url: `/service-cloud/rest/cc-management/v1/anonymouscall/modifyAnonymousCallFeature`,
    method: "POST",
    data,
  })
}

/**
 * 保存匿名外呼接入码/扩展码
 * @param data 参数
 * @returns {*}
 */
export const saveAnonymousCalledRoute = (data) => {
  return request({
    url: `/service-cloud/rest/cc-management/v1/calledRoute/saveAnonymousCalledRoute`,
    method: "POST",
    data,
  })
}

/**
 * 保存机器人助手
 * @param data 参数
 * @returns {*}
 */
export const saveRealtimeTrans = (data) => {
  return request({
    url: `/service-cloud/rest/cc-ai/v1/realtimetrans/saveRealtimeTrans`,
    method: "POST",
    data,
  })
}

/**
 * 下载sk
 * @param data 参数
 * @returns {*}
 */
export const downloadsk = (data) => {
  return request({
    url: `/service-cloud/u-route/ccmanagement/downloadsk`,
    method: "POST",
    data,
  })
}

/**
 * 重置sk
 * @param data 参数
 * @returns {*}
 */
export const resetAppSecret = (data) => {
  return request({
    url: `/service-cloud/rest/cc-management/v1/tenantInfo/resetAppSecret`,
    method: "POST",
    data,
  })
}

/**
 * 更新策略信息
 * @param data 参数
 * @returns {*}
 */
export const updatePolicyInfo = (data) => {
  return request({
    url: `/service-cloud/rest/cc-management/v1/virtualcallcenter/updatePolicyInfo`,
    method: "POST",
    data,
  })
}

/* ============
    转接记录 开始
   ============ */
/**
 * 查询转接记录
 * @param data 查询参数
 * @returns {Promise<*>}
 */
export const queryAllTransferRecord = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/transferrecord/queryAllTransferRecord',
    method: "POST",
    data,
  })
}

/**
 * 查询转接记录总数
 * @param data 查询参数
 * @returns {Promise<Number>|Promise<AxiosResponse<*>>}
 */
export const queryTransferRecordCount = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/transferrecord/queryTransferRecordCount',
    method: "POST",
    data,
  })
}

export const queryContactItemConfig = data => {
  return request({
    url: "/service-cloud/rest/cc-management/v1/contactitemcfg/queryContactItemConfig",
    method: "post",
    data
  })
}

export const generateExportTask = data => {
  return request({
    url: "/service-cloud/rest/cc-management/v1/contactrecordexport/generateExportTask",
    method: "post",
    data
  })
}

export const dualCallExportTask = data => {
  return request({
    url: "/service-cloud/rest/cc-management/v1/dualCallRecord/generateItaExportTask",
    method: "post",
    data
  })
}

export const queryExportDataTask = data => {
  return request({
    url: "/service-cloud/rest/cc-management/v1/contactrecordexport/queryContactExportTaskByPage",
    method: "post",
    data
  })
}

export const queryDualCallExportDataTask = data => {
  return request({
    url: "/service-cloud/rest/cc-management/v1/dualCallRecord/queryDualCallExportTaskByPage",
    method: "post",
    data
  })
}

export const previewContractInfo = contractId => {
  return request({
    url: "/service-cloud/u-route/ccmessaging/previewContractForWeb?contractId=" + contractId,
    method: "get",
  })
}

/**
 * 获取当前操作员权限位
 * @return {Promise<{permCodes:Array<String>}>|any}
 */
export const checkThirdpartAuths = () => {
  return request({
    url: "/service-cloud/rest/cc-management/v1/thirdparty/getauths",
    method: "get",
  })
}

/* ===================
    登录相关信息记录 开始
   =================== */
/**
 * 查询签入日志
 * @param data 查询参数
 * @return {Promise<*>} 签入日志信息
 */
export const queryAllLoginRecord = (data) => {
  return request({
    url: "/service-cloud/rest/cc-management/v1/loginrecord/queryAllLoginRecord",
    method: "post",
    data
  })
}

/**
 * 查询全部签入日志总数
 * @param data 查询参数
 * @return {Promise<number|*>} 全部签入日志总数
 */
export const queryAllLoginRecordCount = (data) => {
  return request({
    url: "/service-cloud/rest/cc-management/v1/loginrecord/queryAllLoginRecordCount",
    method: "post",
    data
  })
}

/**
 * 查询当前座席签入日志总数
 * @param data 查询参数
 * @return {Promise<number|*>} 座席签入日志总数
 */
export const queryLoginRecordCount = (data) => {
  return request({
    url: "/service-cloud/rest/cc-management/v1/loginrecord/queryLoginRecordCount",
    method: "post",
    data
  })
}

/**
 * 创建签入日志导出任务
 * @param {*} data 查询参数
 * @return {Promise<*>}
 */
export const createDataExportTask = (data) => {
  return request({
    url: "/service-cloud/rest/cc-management/v1/loginrecord/createDataExportTask",
    method: "post",
    data
  })
}

/**
 * 查询签入日志导出任务
 * @param {*} data 查询参数
 * @return {Promise<*>}
 */
export const queryDataExportTask = (data) => {
  return request({
    url: "/service-cloud/rest/cc-management/v1/loginrecord/queryDataExportTask",
    method: "post",
    data
  })
}

/* ===================
    登录相关信息记录 结束
   =================== */

/**
 * 查询技能重置记录
 *
 * @param data
 * @returns {*}
 */
export const queryResetSkillRecord = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/resetskillrecord/queryResetSkillRecord',
    method: 'post',
    data
  })
}

/**
 * 查询技能重置记录 count
 *
 * @param data
 * @returns {*}
 */
export const queryResetSkillRecordCount = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/resetskillrecord/queryResetSkillRecordCount',
    method: 'post',
    data
  })
}

/**
 * 查询双呼记录
 *
 * @param data
 * @returns {*}
 */
export const queryDualCallRecordList = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/mobileAgent/queryDualCallRecordList',
    method: 'post',
    data
  })
}


/**
 * 查询身份验证记录
 *
 * @param data
 * @returns {*}
 */
export const queryVerifyIdentityRecords = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/verifyidentity/queryVerifyIdentityRecords',
    method: 'post',
    data
  })
}

/**
 * 查询留言
 *
 * @param data
 * @returns {*}
 */
export const queryLeaveMessage = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/leavemessage/queryLeaveMessage',
    method: 'post',
    data
  })
}

/**
 * 查询留言内容
 *
 * @param data
 * @returns {*}
 */
export const queryLeaveMessageById = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/leavemessage/queryLeaveMessageById',
    method: 'post',
    data
  })
}

/**
 * 处理留言释放锁
 *
 * @param data
 * @returns {*}
 */
export const outProcessLeaveMessage = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/leavemessage/outProcessLeaveMessage',
    method: 'post',
    data
  })
}

/**
 * 处理留言上锁
 *
 * @param data
 * @returns {*}
 */
export const inProcessLeaveMessage = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/leavemessage/inProcessLeaveMessage',
    method: 'post',
    data
  })
}

/**
 * 提交留言处理
 *
 * @param data
 * @returns {*}
 */
export const processLeaveMessage = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/leavemessage/processLeaveMessage',
    method: 'post',
    data
  })
}

/**
 * 查询座席
 *
 * @param data
 * @returns {*}
 */
export const queryCCAgentByConds = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/ccagent/queryCCAgentByConds',
    method: 'post',
    data
  })
}

/**
 * 分配座席
 *
 * @param data
 * @returns {*}
 */
export const assignLeaveMessage = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/leavemessage/assignLeaveMessage',
    method: 'post',
    data
  })
}

/**
 * 查询身份验证记录 count
 *
 * @param data
 * @returns {*}
 */
export const queryAllVerifyIdentityRecordCount = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/verifyidentity/queryAllVerifyIdentityRecordCount',
    method: 'post',
    data
  })
}

/**
 * 查询数字签名合同记录
 *
 * @param data
 * @returns {*}
 */
export const queryContractSignatureInfo = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/contractsignature/queryContractSignatureInfo',
    method: 'post',
    data
  })
}

/**
 * 失效数字签名合同
 *
 * @param data
 * @returns {*}
 */
export const inactiveContractSignature = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/contractsignature/inactiveContractSignature?id=' + data.id,
    method: 'post',
    data
  })
}

/**
 * 查询所有IVR流程
 *
 * @returns {*}
 */
export const ivrList = () => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/ivrFlow/ivrList',
    method: 'get'
  })
}

/* ======================
    客户接触发送消息 start
 =======================*/

/**
 * 检查是否有外发通知权限
 * @return {Promise<boolean>|any} true-有权限；false-无权限；any-接口调用失败
 */
export const checkSendMsgOutsideAuth = () => {
  return request({
    url: "/service-cloud/rest/cc-management/v1/contactSndMsg/checkSendMsgOutsideAuth",
    method: "get",
  })
}

/**
 * 分页查询客户接触发送消息记录
 * @param data 查询参数
 * @return {Promise<*>|any} 响应内容
 */
export const handleMessageSendRecordPagination = data => {
  return request({
    url: "/service-cloud/rest/cc-management/v1/contactSndMsg/getContactSndMsgList",
    method: "post",
    data
  })
}

/**
 * 查询消息具体内容
 * @param data 查询参数
 * @return {Promise<*>|any} 响应内容
 */
export const queryMessage = data => {
  return request({
    url: "/service-cloud/rest/cc-management/v1/contactSndMsg/queryMsgInfoById",
    method: "post",
    data
  })
}

/**
 * 发送华为云短信
 * @param data 发送短信参数
 * @return {Promise<*>|any} 响应对象信息
 */
export const audioVideoSendHuaSms = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/contactSndMsg/audioVideoSendHuaSms',
    method: 'post',
    data
  })
}

/**
 * 发送短信网关短信
 * @param data 发送短信参数
 * @return {Promise<*>|any} 响应对象信息
 */
export const audioVideoSendMspSms = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/contactSndMsg/audioVideoSendMspSms',
    method: 'post',
    data
  })
}

/**
 * 发送短信会议链接
 * @param data 发送短信参数
 * @return {Promise<*>|any} 响应对象信息
 */
export const audioVideoSendTemplateSms = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/contactSndMsg/applyMeetingSms',
    method: 'post',
    data
  })
}

/**
 * 发送邮件
 * @param data 发送邮件参数
 * @return {Promise<*>} 响应对象信息
 */
export const audioVideoSendMspEmail = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/contactSndMsg/audioVideoSendMspEmail',
    method: 'post',
    data
  })
}

/**
 * 发送WhatsApp消息
 * @param data 查询参数
 * @return {Promise<*>|any} 响应对象信息
 */
export const audioVideoSendWhatsAppMsg = (data) => {
  return request({
    url: "/service-cloud/rest/cc-management/v1/contactSndMsg/audioVideoSendWhatsAppMsg",
    method: "post",
    data
  })
}

/* ======================
    客户接触发送消息 end
 =======================*/


/* ======================
    座席流程可视化 业务引导配置与使用 start
 =======================*/

// 获取所有节点数据（加载左侧树形结构用）
export const getAllServiceClassTreeData = (data) => {
  return request({
    url: "/service-cloud/rest/cc-management/v1/cfgservice/getAllServiceClassTreeData",
    method: "post",
    data,
  })
}

// 根据serviceCode和查询条件，查询指定的子节点列表数据（可分页）和此节点本身的详情
export const getServiceInfoAndChildrenById = (data) => {
  return request({
    url: "/service-cloud/rest/cc-management/v1/cfgservice/getServiceInfoAndChildrenById",
    method: "post",
    data,
  })
}

// 查询特性信息
export const queryTenantFeatureInfoForUser = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/tenantInfo/queryTenantFeatureInfoForUser',
    method: 'post',
    data
  })
}

// 查询工单类型树结构
export const queryCaseTypes = () => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/callreason/queryCaseTypes',
    method: 'post',
  })
}

// 获取CSRF Token
export const queryCSRFToken = () => {
  return request({
    url: '/u-route/baas/sys/v1.0/csrf/get',
    method: 'post',
  })
}

// 查询工单类型2.0树结构
export const queryCaseTypes2 = (token, data) => {
  return request({
    url: '/service/AICC__Case/1.0.0/caseType/list',
    method: 'post',
    requestHeaders: [{
      'csrf-token': token
    }],
    data
  })
}

// 保存节点数据（新建或修改）
export const saveServiceClass = (data) => {
  return request({
    url: "/service-cloud/rest/cc-management/v1/cfgservice/saveServiceClass",
    method: "post",
    data
  })
}

// 删除节点
export const deleteServiceClass = (data) => {
  return request({
    url: "/service-cloud/rest/cc-management/v1/cfgservice/deleteServiceClass",
    method: "post",
    data
  })
}

// 根据业务类型编号查询流程图
export const queryFlowInfo = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/cfgflow/queryFlowInfo',
    method: 'get',
    data
  })
}

// 保存流程图信息
export const saveFlowInfo = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/cfgflow/saveFlowInfo',
    method: 'post',
    data
  })
}

// 通过serviceCode查找服务流程
export const queryServiceByServiceCode = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/cfgservice/queryServiceByServiceCode',
    method: 'post',
    data
  })
}
// 效率分析查找日志日结数据
export const queryFlowLogH = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/cfgflowlogh/queryFlowLogH',
    method: 'post',
    data
  })
}

// 新增流程运行日志记录
export const saveFlowLog = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/cfgflowlog/saveFlowLog',
    method: 'post',
    data
  })
}

// 更新流程运行日志记录
export const updateFlowLog = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/cfgflowlog/updateFlowLog',
    method: 'post',
    data
  })
}


// 查询来电原因
export const queryCallReason = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/callreason/queryCallReason',
    method: 'post',
    data
  })
}

// 通过 flowId 查找服务流程
export const queryFlowInfoById = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/cfgflow/queryFlowInfoById',
    method: 'get',
    data
  })
}

/**
 * 查询所有版本的流程
 *
 * @returns {*}
 */
export const listFlowInfo = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/cfgflow/listFlowInfo',
    method: 'get',
    data
  })
}

/**
 * 发布流程
 *
 * @returns {*}
 */
export const doPublishFlow = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/cfgflow/publishFlow?flowId=' + data,
    method: 'post',
  })
}

/**
 * 取消发布流程
 *
 * @returns {*}
 */
export const doCancelFlow = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/cfgflow/cancelFlow?flowId=' + data,
    method: 'post',
  })
}

/**
 * 复制流程
 *
 * @returns {*}
 */
export const doCopyFlow = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/cfgflow/copyFlow?flowId=' + data,
    method: 'post',
  })
}

/**
 * 删除流程
 *
 * @returns {*}
 */
export const doDeleteFlow = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/cfgflow/deleteFlow?flowId=' + data,
    method: 'post',
  })
}

/**
 * 通过serviceName查找服务流程
 *
 * @returns {*}
 */
export const queryServiceByServiceName = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/cfgservice/queryServiceByServiceName',
    method: 'get',
    data
  })
}

/* ======================
    座席流程可视化 业务引导配置与使用 end
 =======================*/

// 查区号
export const querySysparamInterCode = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/sysParameter/querySysparamInterCode',
    method: 'post',
    data
  })
}

// 查租户系统参数值
export function queryTenantSysParamByItemIds(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/sysParameter/queryTenantSysParamByItemIds',
    method: 'post',
    data
  })
}

/* ======================
    语音通知 配置接口 start
 =======================*/

export const queryUrlConfig = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/urlConfig/queryAllUrlConfig',
    method: 'post',
    data
  })
}

export const updateUrlConfig = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/urlConfig/modifyUrlConfig',
    method: 'post',
    data
  })
}

export const resetUrlConfig = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/urlConfig/resetUrlConfig',
    method: 'post',
    data
  })
}

/* ======================
    语音通知 配置接口 End
 =======================*/

export const enabledVerifyIdentityConfig = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/verifyidentityconfig/enabledVerifyIdentityConfig',
    method: 'post',
    data
  })
}

export const queryVerifyIdentityConfig = () => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/verifyidentityconfig/queryVerifyIdentityConfig',
    method: 'get'
  })
}

export const deleteVerifyIdentityConfig = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/verifyidentityconfig/deleteVerifyIdentityConfig',
    method: 'delete',
    data
  })
}

export const createVerifyIdentityConfig = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/verifyidentityconfig/createVerifyIdentityConfig',
    method: 'post',
    data
  })
}

export const modifyVerifyIdentityConfig = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/verifyidentityconfig/modifyVerifyIdentityConfig',
    method: 'post',
    data
  })
}

export const queryCertificateList = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/certificate/queryCertificateList',
    method: 'post',
    data
  })
}

export const queryContactItemCfgCount = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/contactitemcfg/queryContactItemCfgCount',
    method: 'post',
    data
  })
}

export const queryContactItemCfgPage = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/contactitemcfg/queryContactItemCfgPage',
    method: 'post',
    data
  })
}

export const intContactItemConfig = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/contactitemcfg/intContactItemConfig',
    method: 'post',
    data
  })
}

export const deleteContactItemConfig = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/contactitemcfg/deleteContactItemConfig',
    method: 'delete',
    data
  })
}

export const queryContactExtDataCfg = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/contactextdatacfg/queryContactExtDataCfg',
    method: 'post',
    data
  })
}

export const queryContactExtDataCfgCount = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/contactextdatacfg/queryContactExtDataCfgCount',
    method: 'post',
    data
  })
}

export const deleteContactExtDataCfg = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/contactextdatacfg/deleteContactExtDataCfg',
    method: 'delete',
    data
  })
}

export const queryAllContactExtDataCfg = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/contactextdatacfg/queryAllContactExtDataCfg',
    method: 'post',
    data
  })
}

export const updateContactExtDataCfg = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/contactextdatacfg/updateContactExtDataCfg',
    method: 'post',
    data
  })
}

export const saveContactExtDataCfg = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/contactextdatacfg/saveContactExtDataCfg',
    method: 'post',
    data
  })
}

export const queryAllConfigs = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/contactCustInfoConfig/queryAllConfigs',
    method: 'post',
    data
  })
}

export const queryAllBasicExtData = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/contactitemcfg/queryAllBasicExtData',
    method: 'post',
    data
  })
}

export const queryAllContactItemConfig = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/contactitemcfg/queryAllContactItemConfig',
    method: 'post',
    data
  })
}

export const saveContactItemConfig = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/contactitemcfg/saveContactItemConfig',
    method: 'post',
    data
  })
}

export const updateContactItemConfig = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/contactitemcfg/updateContactItemConfig',
    method: 'post',
    data
  })
}

export const deleteConfig = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/contactCustInfoConfig/deleteConfig',
    method: 'post',
    data
  })
}

export const saveConfig = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/contactCustInfoConfig/saveConfig',
    method: 'post',
    data
  })
}

export const updateConfig = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/contactCustInfoConfig/updateConfig',
    method: 'post',
    data
  })
}

export const queryCustomTablesNameAndId = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/customTable/queryCustomTablesNameAndId',
    method: 'post',
    data
  })
}

export const queryCustomTableDetailInfo = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/customTable/queryCustomTableDetailInfo',
    method: 'post',
    data
  })
}

export const updateStyle = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/contactCustInfoConfig/updateStyle',
    method: 'post',
    data
  })
}

// 查询弹屏配置信息
export function queryAllPageUrlsInfo(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/pageurls/queryAllPageUrlsInfo',
    method: 'post',
    data
  })
}


export function queryPageByRelease(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/page/queryPageByRelease',
    method: 'post',
    data
  })
}


export function moveUpOrDown(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/pageurls/moveUpOrDown',
    method: 'put',
    data
  })
}

export function updatePageEnable(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/pageurls/updatePageEnable',
    method: 'post',
    data
  })
}

export function queryPageConfigMaxnum() {
  return request({
    url: '/service-cloud/rest/cc-management/v1/pageurls/queryPageConfigMaxnum',
    method: 'post',
  })
}

export function queryPageUrlParamMaxCount() {
  return request({
    url: '/service-cloud/rest/cc-management/v1/pageurls/queryPageUrlParamMaxCount',
    method: 'post',
  })
}

export function pageUrlsDeleteApi(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/pageurls/delete',
    method: 'post',
    data
  })
}

export function queryPageUrlsByPageDescNewApi(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/pageurls/queryPageUrlsByPageDescNew',
    method: 'post',
    data
  })
}

export function updatePageUrlsById(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/pageurls/updatePageUrlsById',
    method: 'post',
    data
  })
}
export function saveNewPageUrls(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/pageurls/saveNewPageUrls',
    method: 'post',
    data
  })
}

export function queryUrlParamConfigByCondition(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/pageurls/queryUrlParamConfigByCondition',
    method: 'post',
    data
  })
}

export function deleteUrlParamConfig(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/pageurls/deleteUrlParamConfig',
    method: 'post',
    data
  })
}
export function updateUrlParamConfig(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/pageurls/updateUrlParamConfig',
    method: 'post',
    data
  })
}
export function addUrlParamConfig(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/pageurls/addUrlParamConfig',
    method: 'post',
    data
  })
}

export const querySpecialLevelApi = typeIds => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/specialLevel/querySpecialLevel',
    method: 'get',
    data: {
      typeIds: typeIds
    }
  })
}

export function querySpecialList(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/specialList/querySpecialList',
    method: 'post',
    data
  })
}

export function querySpecialListCount(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/specialList/querySpecialListCount',
    method: 'post',
    data
  })
}

export function transferToHistory(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/specialList/transferToHistory',
    method: 'post',
    data
  })
}

export function deleteSpecialList(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/specialList/deleteSpecialList',
    method: 'post',
    data
  })
}

export function querySpecialListHis(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/specialListHis/querySpecialListHis',
    method: 'post',
    data
  })
}

export function querySpecialListHisCount(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/specialListHis/querySpecialListHisCount',
    method: 'post',
    data
  })
}

export function deleteSpecialListHisById(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/specialListHis/deleteSpecialListHisById',
    method: 'post',
    data
  })
}

export function restoreToList(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/specialListHis/restoreToList',
    method: 'post',
    data
  })
}

export function querySpecialLevelList(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/specialLevel/querySpecialLevelList',
    method: 'post',
    data
  })
}

export function deleteSpecialLevel(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/specialLevel/deleteSpecialLevel',
    method: 'post',
    data
  })
}

export function querySpecialListReminderApi(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/specialListReminder/querySpecialListReminder',
    method: 'post',
    data
  })
}

export function deleteSpecialListReminder(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/specialListReminder/deleteSpecialListReminder',
    method: 'delete',
    data
  })
}

export const querySpecialListReminderCount = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/specialListReminder/querySpecialListReminder/count',
    method: 'get',
    data
  })
}

export function updateStatus(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/specialLevel/updateStatus',
    method: 'post',
    data
  })
}

export const uploadCertificate = (data) => {
  return request({
    url: '/service-cloud/u-route/ccmanagement/certificate/uploadCertificate',
    method: 'post',
    data
  })
}

export const updateCertInfo = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/certificate/updateCertInfo',
    method: 'post',
    data
  })
}

export const uploadCrlFile = (data) => {
  return request({
    url: '/service-cloud/u-route/ccmanagement/certificate/uploadCrlFile',
    method: 'post',
    data
  })
}

export const updateCertificateStatus = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/certificate/updateCertificateStatus',
    method: 'post',
    data
  })
}

export const queryCustomTablesByStatus = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/customTable/queryCustomTablesNameAndId',
    method: 'post',
    data
  })
}

export const queryCustomTablesData = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/customTable/queryCustomTablesData',
    method: 'post',
    data
  })
}

export function queryCssTransServerConf(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/transserverconf/queryCssTransServerConf',
    method: 'POST',
    data
  })
}

export function queryTransServerConf(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/transserverconf/queryTransServerConf',
    method: 'POST',
    data
  })
}

export function queryTransServerCount(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/transserverconf/queryTransServerCount',
    method: 'POST',
    data
  })
}

export function queryTransTaskCount(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/transtaskconf/queryTransTaskCount',
    method: 'POST',
    data
  })
}

export function queryTransTaskConf(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/transtaskconf/queryTransTaskConf',
    method: 'POST',
    data
  })
}

export function saveTransTaskConf(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/transtaskconf/saveTransTaskConf',
    method: 'POST',
    data
  })
}

export function updateTransTaskConf(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/transtaskconf/updateTransTaskConf',
    method: 'POST',
    data
  })
}

export function updateSpecTransTaskConf(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/transtaskconf/updateSpecTransTaskConf',
    method: 'POST',
    data
  })
}

export function queryTransTaskParams(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/transtaskconf/queryTransTaskParams',
    method: 'POST',
    data
  })
}

export function deleteCssServerConf(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/transserverconf/deleteCssServerConf',
    method: 'DELETE',
    data
  })
}

export function deleteTransServerConf(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/transserverconf/deleteTransServerConf',
    method: 'DELETE',
    data
  })
}

export function queryAllTransServerConf(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/transserverconf/queryAllTransServerConf',
    method: 'POST',
    data
  })
}

export function saveTransServerConf(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/transserverconf/saveTransServerConf',
    method: 'POST',
    data
  })
}

export function updateTransServerConf(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/transserverconf/updateTransServerConf',
    method: 'POST',
    data
  })
}

export function queryTransServerParams(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/transserverconf/queryTransServerParams',
    method: 'POST',
    data
  })
}

export function updateCssServerConf(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/transserverconf/updateCssServerConf',
    method: 'POST',
    data
  })
}

export function saveCssServerConf(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/transserverconf/saveCssServerConf',
    method: 'POST',
    data
  })
}

export function deleteTransTaskConf(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/transtaskconf/deleteTransTaskConf',
    method: 'delete',
    data
  })
}

export function updateTransTaskStatus(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/transtaskconf/updateTransTaskStatus',
    method: 'post',
    data
  })
}

export const deleteBasicData = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/basicdata/deleteBasicData',
    method: 'post',
    data
  })
}

export const queryBasicDataWithColumnsById = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/basicdata/queryBasicDataWithColumnsById',
    method: 'post',
    data
  })
}

export const modifyBasicData = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/basicdata/modifyBasicData',
    method: 'POST',
    data
  })
}

export const uploadDataFile = (data) => {
  return request({
    url: '/service-cloud/u-route/ccmanagement/basicdata/uploadDataFile',
    method: 'POST',
    data
  })
}

export const exportDataTask = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/customTable/exportDataTask',
    method: 'POST',
    data
  })
}
export const queryDataImportTask = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/basicdata/queryDataImportTask',
    method: 'POST',
    data
  })
}
export function queryBasicDataExportTaskByPage(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/basicdata/queryBasicDataExportTaskByPage',
    method: 'POST',
    data
  })
}

export function querySatisfactionLevel() {
  return request({
    url: '/service-cloud/rest/cc-management/v1/satisfactionconfig/querySatisfactionLevel',
    method: 'get'
  })
}

export function modifySpecialList(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/specialList/modifySpecialList',
    method: 'put',
    data
  })
}

export function addSpecialListApi(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/specialList',
    method: 'post',
    data
  })
}

export function createSpecialLevel(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/specialLevel/createSpecialLevel',
    method: 'post',
    data
  })
}

export function updateSpecialLevel(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/specialLevel/updateSpecialLevel',
    method: 'post',
    data
  })
}

export function createSpecialListReminder(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/specialListReminder/createSpecialListReminder',
    method: 'post',
    data
  })
}

export function updateSpecialListReminder(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/specialListReminder/updateSpecialListReminder',
    method: 'put',
    data
  })
}

export function importSpecial(data) {
  return request({
    url: '/service-cloud/u-route/ccmanagement/specialList/import',
    method: 'post',
    data
  })
}

export const queryPage = () => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/page/queryPage',
    method: 'POST'
  })
}

export function deletePage(id) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/page/deletePage?id=' + id,
    method: 'POST'
  })
}

export function editPageName(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/page/editPageName',
    method: 'POST',
    data
  })
}

export function addPage(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/page/addPage',
    method: 'POST',
    data
  })
}

export function editPageLayout(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/page/editPageLayout',
    method: 'POST',
    data
  })
}

export function pageRelease(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/page/pageRelease',
    method: 'POST',
    data
  })
}

export function getInterfaceByPageId(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/pageInterface/getInterfaceByPageId',
    method: 'POST',
    data
  })
}

export function queryCustomTables(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/customTable/queryCustomTables',
    method: 'POST',
    data
  })
}

export function queryInterfaceObjectMaxNum() {
  return request({
    url: '/service-cloud/rest/cc-management/v1/pageInterface/queryInterfaceObjectMaxNum',
    method: 'POST'
  })
}

export function addPageInterface(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/pageInterface/addPageInterface',
    method: 'POST',
    data
  })
}

export function updatePageInterface(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/pageInterface/updatePageInterface',
    method: 'POST',
    data
  })
}

export function querySystemParam(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/sysParameter/querySystemParam',
    method: 'POST',
    data
  })
}

export function queryAllCalledConfig(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/calledConfig/queryAllCalledConfig',
    method: 'POST',
    data
  })
}

export function queryCalledConfigCount(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/calledConfig/queryCalledConfigCount',
    method: 'POST',
    data
  })
}

export function calledConfig(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/calledConfig/',
    method: 'DELETE',
    data
  })
}

export function queryMobileAgentPhone(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/customerNumer/queryMobileAgentPhone',
    method: 'POST',
    data
  })
}

export function queryMobileAgentPhoneNum(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/customerNumer/queryMobileAgentPhoneNum',
    method: 'POST',
    data
  })
}

export function modifyMobileAgentPhone(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/customerNumer/modifyMobileAgentPhone',
    method: 'POST',
    data
  })
}

export function queryExportTenantSysParam() {
  return request({
    url: '/service-cloud/rest/cc-management/v1/sysParameter/queryExportTenantSysParam',
    method: 'GET'
  })
}

export function queryCalledCount(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/calledConfig/queryCalledCount',
    method: 'POST',
    data
  })
}

export function queryCalledCondition(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/calledConfig/queryCalledCondition',
    method: 'POST',
    data
  })
}

export function querySkillCondition(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/skill/querySkillCondition',
    method: 'POST',
    data
  })
}

export function querySkillCount(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/skill/querySkillCount',
    method: 'POST',
    data
  })
}

export function addCalledConfig(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/calledConfig/addCalledConfig',
    method: 'POST',
    data
  })
}


export function modifyCalledConfigByCalled(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/calledConfig/modifyCalledConfigByCalled',
    method: 'POST',
    data
  })
}

export function createOperationExportTasks(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/operationrecord/createDataExportTask',
    method: 'POST',
    data
  })
}

export function queryOperationExportTasks(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/operationrecord/queryDataExportTask',
    method: 'POST',
    data
  })
}

// 查询休息原因列表
export function queryAllRestReasonsInfo(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/restreasons/queryAllRestReasonsInfo',
    method: 'POST',
    data
  })
}

// 查询休息原因总数
export function queryAllRestReasonsCount(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/restreasons/queryAllRestReasonsCount',
    method: 'POST',
    data
  })
}

export function refreshRestReason(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/restreasons/refreshRestReason',
    method: 'POST',
    data
  })
}

export function queryCountByRestCode(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/leaverecord/queryCountByRestCode',
    method: 'POST',
    data
  })
}

// 删除休息原因
export const deleteRestReasonApi = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/restreasons/deleteRestReason',
    method: 'delete',
    data,
  })
}

// 修改休息原因配置
export const modifyRestReason = (data) => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/restreasons/modifyRestReason',
    method: 'put',
    data,
  })
}

export function saveNewRestReason(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/restreasons/saveNewRestReason',
    method: 'POST',
    data
  })
}

export function createLeaveExportTasks(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/leaverecord/createDataExportTask',
    method: 'POST',
    data
  })
}

export function queryLeaveExportTasks(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/leaverecord/queryDataExportTask',
    method: 'POST',
    data
  })
}

export function createTransferExportTasks(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/transferrecord/createDataExportTask',
    method: 'POST',
    data
  })
}

export function queryTransferExportTasks(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/transferrecord/queryDataExportTask',
    method: 'POST',
    data
  })
}

