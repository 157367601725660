export default {
  'isales.basicinfo.title.chooseCallNo': 'Select Calling Number',
  'isales.common.title.cancel': 'Cancel',
  'isales.common.title.close': 'Close',
  'isales.common.title.confirm': 'OK',
  'isales.common.title.dataReset': 'Reset',
  'isales.common.title.dataSearch': 'Search',
  'isales.common.title.fail': 'Failed',
  'isales.common.title.save': 'Save',
  'isales.common.title.ready': 'Ready',
  'isales.common.title.view': 'View',
  'isales.common.title.success': 'Successful',
  'isales.common.title.operation': 'Operation',
  'isales.common.title.tipsInfo': 'Information',
  'isales.data.import.task.status.complete': 'Completed',
  'isales.data.import.task.status.error': 'Error',
  'isales.data.import.task.status.executing': 'Importing',
  'isales.data.import.task.status.ready': 'Preparation',
  'isales.data.title.create': 'New',
  'isales.data.title.edit': 'Edit',
  'isales.data.title.undoEdit': 'Undo Edit',
  'isales.data.title.importData': 'Import',
  'isales.data.title.importResult': 'Import Result',
  'isales.exportdata.message.failinfo': 'Failed to export data',
  'isales.exportdata.message.nodata': 'No data that meets the conditions can be exported!',
  'isales.exportdata.message.successinfo': 'Exporting data...View details in Export Result',
  'isales.exportdata.title.exportresult': 'Export Result',
  'isales.exportdata.title.fail': 'Failed',
  'isales.exportdata.title.success': 'Successful',
  'isales.import.message.template.show': 'Add customer data by row and add multiple call numbers by column',
  'isales.import.message.begintime': 'Start Time',
  'isales.import.message.endtime': 'End Time',
  'isales.import.message.fail': 'Failed to import data',
  'isales.import.message.numberTooMany': 'A maximum of 50000 numbers can be uploaded',
  'isales.import.message.failnum': 'Number of Failed Records',
  'isales.import.message.pleaseuploadfile': 'Select a file',
  'isales.import.message.repeatnum': 'Number of Duplicate Records',
  'isales.import.message.result.tip': 'Failed to export the differential selection result',
  'isales.import.message.status': 'Status',
  'isales.import.message.success': 'Importing data...Please click Refresh later to obtain the latest data.',
  'isales.import.message.succnum': 'Number of Successful Records',
  'isales.import.message.taskstatuswrong': 'Cannot import data to the outbound call task because it is not in the draft or suspended state',
  'isales.import.message.uploadedfileformatvalidate': 'Incorrect file format. Select a CSV file',
  'isales.import.message.uploadedfileNameformatvalidate': 'Incorrect file name. Select a file whose name contains only Chinese characters\, lowercase letters\, digits\, or underscores (_)',
  'isales.import.message.uploadedfiletoolarge': 'Select a file less than 20 MB',
  'isales.import.title.result': 'View Import Result',
  'isales.import.title.statusComplete': 'Completed',
  'isales.import.title.statusExecuting': 'Executing',
  'isales.import.title.statusReady': 'To Be Executed',
  'isales.repeatfile.export.repeatline': 'repeatline',
  'isales.repeatfile.export.repeatCalledNo': 'repeatCalledNo',
  'isales.failfile.export.failline': 'failLine',
  'isales.failfile.export.failCalledNo': 'failCalledNo',
  'isales.failfile.export.failReason': 'failReason',
  'isales.input.message.large': 'The input value is too large',
  'isales.input.message.small': 'The input value is too small',
  'isales.management.message.sessionId': 'Session Number',
  'isales.management.message.calledNo': 'Called Number',
  'isales.management.message.calledNoEmpty': 'The called number is empty',
  'isales.management.message.calledNoExists': 'The called number already exists',
  'isales.management.message.calledNoFormatIncorrect': 'The outgoing call number can contain only digits',
  'isales.management.message.sessionIdFormatIncorrect': 'The sessionId can contain only digits',
  'isales.management.message.calledNoLengthError': 'The called number length exceeds the limit',
  'isales.management.message.calledNoOne': 'First Called Number',
  'isales.management.message.calledNoOneCategory': 'First Called Number Category',
  'isales.management.message.calledNoOneInterval': 'First Called Number Interval',
  'isales.management.message.calledNoTwo': 'Second Called Number',
  'isales.management.message.calledNoTwoCategory': 'Second Called Number Category',
  'isales.management.message.calledNoTwoInterval': 'Second Called Number Interval',
  'isales.management.message.createFailed': 'Failed to create outbound call data',
  'isales.management.message.createSuccess': 'The outbound call data is created successfully',
  'isales.management.message.createTaskstatusError': 'Cannot create data for the outbound call task because it is not in the draft or suspended state',
  'isales.management.message.deleteConfirm': 'Are you sure you want to delete the selected outbound call data?',
  'isales.management.message.deleteCount': 'A maximum of 100 outbound call records can be deleted at a time',
  'isales.management.message.deletedataIdsEmpty': 'The flag of the outbound call data to be deleted is empty',
  'isales.management.message.deletedataIdsError': 'Incorrect flag of the outbound call data to be deleted',
  'isales.management.message.deleteFailed': 'Failed to delete the outbound call data',
  'isales.management.message.deleteSuccess': 'The outbound call data is deleted successfully',
  'isales.management.message.deleteTaskstatusError': 'Cannot delete the outbound call task because it is not in the draft state or the pause state',
  'isales.management.message.download': 'Download Outbound Call Data Template',
  'isales.management.message.filter': 'Filter Data',
  'isales.management.message.filterNo': 'No',
  'isales.management.message.filterYes': 'Yes',
  'isales.management.message.import': 'Import Appended Data',
  'isales.management.message.importOrder': 'Import Sequence',
  'isales.management.message.orderReverse': 'Descending Order',
  'isales.management.message.orderSequence': 'Ascending Order',
  'isales.management.message.taskIdEmpty': 'The outbound call task flag is empty',
  'isales.management.message.upload': 'Upload',
  'isales.management.message.uploadSelect': 'Select a file',
  'isales.management.placeholder.calledNo': 'Enter a called number',
  'isales.management.placeholder.ivrName': 'Enter the name of the intelligent robot process',
  'isales.management.placeholder.commonIvrName': 'Enter a common IVR flow name',
  'isales.management.placeholder.commonIvrFlowAccessCode': 'Enter a common IVR flow Access Code',
  'isales.management.placeholder.skillName': 'Enter a skill queue name',
  'isales.management.title.calledNo': 'Customer Number',
  'isales.management.title.createNew': 'Create Outbound Call Data',
  'isales.management.title.deleteInfo': 'Select the outbound call data',
  'isales.management.title.details': 'Details',
  'isales.management.title.editPolicy': 'Modify policy',
  'isales.management.title.edit': 'Modify',
  'isales.management.placeholder.surveyName': 'Enter Questionnaire Name',
  'isales.management.title.extendedField': 'Extended Field',
  'isales.management.title.import': 'Import Outbound Call Data',
  'isales.management.title.warning': 'Warning',
  'isales.management.title.callbot': 'Outbound Call Chatbot',
  'isales.management.title.refresh': 'Refresh',
  'isales.management.title.busiresult': 'Business Result',
  'isales.management.title.toblocklist': 'Add to Blocklist',
  'isales.outboundtime.field.hour': 'Hour',
  'isales.outboundtime.field.minute': 'Minute',
  'isales.outboundtime.message.crossover': 'The outbound call time segments overlap',
  'isales.outboundtime.message.empty': 'No outbound call time is set',
  'isales.outboundtime.title.beginTime': 'Start Time',
  'isales.outboundtime.title.clear': 'Clear',
  'isales.outboundtime.title.endTime': 'End Time',
  'isales.outboundtime.title.period': 'Outbound Call Time Segment',
  'isales.outboundtime.title.set': 'Set Outbound Call Time',
  'isales.outboundtime.title.timePeriod': 'Outbound Call Time Segment',
  'isales.specialday.field.beginDate': 'Start Date',
  'isales.specialday.field.byDate': 'By Date',
  'isales.specialday.field.byWeek': 'By Week',
  'isales.specialday.field.byYear': 'Repeat By Year',
  'isales.specialday.field.endDate': 'End Date',
  'isales.specialday.field.Friday': 'Friday',
  'isales.specialday.field.Monday': 'Monday',
  'isales.specialday.field.Saturday': 'Saturday',
  'isales.specialday.field.Sunday': 'Sunday',
  'isales.specialday.field.Thursday': 'Thursday',
  'isales.specialday.field.Tuesday': 'Tuesday',
  'isales.specialday.field.Wednesday': 'Wednesday',
  'isales.specialday.message.confirmDelete': 'Are you sure you want to delete the data?',
  'isales.specialday.message.createFail': 'Failed to create the special date',
  'isales.specialday.message.createSuccess': 'The special date is created successfully',
  'isales.specialday.message.crossover': 'The time segments of outbound calls on special dates overlap',
  'isales.specialday.message.dateEmpty': 'The special date is empty',
  'isales.specialday.message.dateFormatError': 'Incorrect special date format',
  'isales.specialday.message.dateParseError': 'Error in converting the time type of the special date',
  'isales.specialday.message.deleteFail': 'Failed to delete the special date',
  'isales.specialday.message.deletePartiallyFail': 'Some special dates failed to be deleted',
  'isales.specialday.message.deleteSpecialday': 'Select a special date',
  'isales.specialday.message.deleteSuccess': 'The special date is deleted successfully',
  'isales.specialday.message.deleteTips': 'Cannot delete the special date because it is associated with an outbound call task',
  'isales.specialday.message.idEmpty': 'The special date flag is empty',
  'isales.specialday.message.idError': 'Incorrect special date flag',
  'isales.specialday.message.isEmpty': 'No outbound call time of the special date is set',
  'isales.specialday.message.nameError': 'The special date name is in incorrect format',
  'isales.specialday.message.nameExists': 'The special date name already exists',
  'isales.specialday.message.typeError': 'Incorrect special date type',
  'isales.specialday.message.updateFail': 'Failed to modify the special date',
  'isales.specialday.message.updateSuccess': 'The special date is modified successfully',
  'isales.specialday.message.weeklyEmpty': 'The special date period is empty',
  'isales.specialday.message.weeklyError': 'Incorrect special date period',
  'isales.specialday.message.weeklyFormatError': 'The special date period format is incorrect',
  'isales.specialday.message.weeklyRepeat': 'Duplicate special date period',
  'isales.specialday.placeholder.searchname': 'Enter a special date name',
  'isales.specialday.title.add': 'Add Special Date',
  'isales.specialday.title.addSpecialTime': 'Add Special Date',
  'isales.specialday.title.choose': 'Select Special Date',
  'isales.specialday.title.date': 'Special Date',
  'isales.specialday.title.chooseDate': 'Select Date',
  'isales.specialday.title.create': 'Create',
  'isales.specialday.title.createspecialday': 'Create Special Date',
  'isales.specialday.title.delete': 'Delete',
  'isales.specialday.title.scoreDetail': 'Score Detail',
  'isales.specialday.title.deleteError': 'Error',
  'isales.specialday.title.deleteSuccess': 'Successful',
  'isales.specialday.title.edit': 'Edit',
  'isales.specialday.title.editSpecialday': 'Edit Special Date',
  'isales.specialday.title.holiday': 'Special Date',
  'isales.specialday.title.holidayName': 'Special Date Name',
  'isales.specialday.title.holidayType': 'Special Date Type',
  'isales.specialday.title.reset': 'Reset',
  'isales.specialday.title.search': 'Query',
  'isales.specialday.title.timeSet': 'Set Special Date Outbound Call Time',
  'isales.specialday.title.timePeriod': 'Special Date Outbound Call Time',
  'isales.specialday.title.warning': 'Warning',
  'isales.task.field.callFail': 'Failed',
  'isales.task.field.callFlagCompletion': 'Completed',
  'isales.task.field.callFlagExecuted': 'To Be Executed',
  'isales.task.field.callFlagProgress': 'Executing',
  'isales.task.field.callSuccess': 'Successful',
  'isales.task.message.completionRate': 'Task Completion Rate',
  'isales.task.message.download': 'Download Recording',
  'isales.task.message.numberCalled': 'Called: {0}',
  'isales.task.message.numberFailed': 'Failed Records',
  'isales.task.message.numberNotcalled': 'Failed records: {0}',
  'isales.task.message.numbersTotal': 'Total: {0}',
  'isales.task.message.numberSuccessful': 'Success Records',
  'isales.task.message.pauseFail': 'Failed to suspend the outbound call task',
  'isales.task.message.pauseSuccess': 'The outbound call task is suspended successfully',
  'isales.task.message.play': 'Play',
  'isales.task.message.saveFail': 'Failed to configure the outbound call task',
  'isales.task.message.saveSuccess': 'The outbound call task is configured successfully',
  'isales.taskpolicy.message.saveSuccess': 'The outbound call policy is configured successfully',
  'isales.taskpolicy.message.saveFail': 'Failed to configure the outbound call policy',
  'isales.task.message.specialdayEmpty': 'No special date is selected',
  'isales.task.message.startFail': 'Failed to start the outbound call task',
  'isales.task.message.startSuccess': 'The outbound call task is started successfully',
  'isales.task.message.startWarning': 'Exporting data...Cannot start the outbound call task',
  'isales.task.message.statistics': 'Statistics',
  'isales.task.message.successRate': 'Success Rate',
  'isales.task.message.timeIncompletes': 'The time segment of a single outbound call is not complete',
  'isales.task.title.basicInfo': 'Basic Information',
  'isales.task.title.taskSample': 'Object Management',
  'isales.task.title.callBack': 'Reject',
  'isales.task.title.callpolicy': 'Outbound Call Policy',
  'isales.task.title.callTime': 'Outbound Call Duration',
  'isales.task.title.complete': 'Finish',
  'isales.task.title.nextStep': 'Next',
  'isales.task.title.preStep': 'Previous',
  'isales.taskinfo.field.beginDate': 'Start Time',
  'isales.taskinfo.field.callNo': 'Caller Number',
  'isales.taskinfo.field.endDate': 'End Time',
  'isales.taskinfo.field.flowName': 'IVR Flow',
  'isales.taskinfo.field.skillName': 'Skill Queue',
  'isales.taskinfo.field.taskType': 'Task Type',
  'isales.taskinfo.field.smsTemplate': 'SMS Template for Failed Outbound Call',
  'isales.taskinfo.field.description': 'Task Description',
  'isales.taskinfo.field.surveyAnswerNum': 'Number of survey responses',
  'isales.taskinfo.field.questionNumber': 'Number of question',
  'isales.taskinfo.field.surveyScore': 'Survey Total Score',
  'isales.taskinfo.message.beginTimeError': 'The start time can not be later than the end time',
  'isales.taskinfo.message.endTimeError': 'The end time must be later than the current time',
  'isales.taskinfo.message.input': 'Invalid characters',
  'isales.taskinfo.message.callintervalrange': 'Please enter an integer ranging from 1 to 3600',
  'isales.taskinfo.message.nameExists': 'The outbound call task name must be unique',
  'isales.taskinfo.message.require': 'Set all required fields',
  'isales.taskinfo.placeholder.choose': '--Select--',
  'isales.taskinfo.placeholder.input': '--Enter--',
  'isales.taskinfo.title.chooseIvrFlow': 'Select IVR Flow',
  'isales.taskinfo.title.chooseCommonIvrFlow': 'Select Common IVR',
  'isales.taskinfo.title.chooseSkill': 'Select Skill Queue',
  'isales.taskinfo.title.ivrFlow': 'Intelligent Chatbot',
  'isales.taskinfo.title.commonIvrFlow': 'Common IVR',
  'isales.taskinfo.title.skillName': 'Skill Queue',
  'isales.taskinfo.title.chooseSmsTemplateQuery': 'Select SMS Template',
  'isales.taskinfo.title.smsTemplate': 'SMS Template',
  'isales.taskmanagement.field.intelligence': 'Intelligent Outbound Call',
  'isales.taskmanagement.field.Automatic': 'Automatic Outbound Call',
  'isales.taskmanagement.field.outboundType': 'Call Type',
  'isales.taskmanagement.field.prediction': 'Predicted Outbound Call',
  'isales.taskmanagement.field.taskName': 'Task Name',
  'isales.taskmanagement.field.taskTime': 'Duration',
  'isales.taskinfo.title.chooseSurvey': 'Select Questionnaire',
  'isales.taskmanagement.field.taskStatus': 'Task Status',
  'isales.taskmanagement.message.deleteConfirm': 'Are you sure you want to delete it?',
  'isales.taskmanagement.message.queryDateError': 'Incorrect date. Select another one',
  'isales.taskmanagement.message.queryFailed': 'Query failed',
  'isales.taskmanagement.message.queryLimit': 'The number of records on each page must be a positive integer less than 101',
  'isales.taskinfo.title.surveyName': 'Questionnaire Name',
  'isales.taskinfo.title.versionNo': 'Questionnaire Version',
  'isales.taskmanagement.message.queryTime': 'Enter a complete time',
  'isales.taskmanagement.message.timeerror': 'The start time cannot be later than the end time',
  'isales.taskmanagement.title.addAuto': 'Create System Auto Outbound Call',
  'isales.task.message.startTips': 'The task is Expired. Cannot start the outbound call task',
  'isales.taskmanagement.title.chooseBegintime': 'Start Time',
  'isales.taskmanagement.title.begintime': 'Start Time',
  'isales.taskmanagement.title.chooseEndtime': 'End Time',
  'isales.taskmanagement.title.endtime': 'End Time',
  'isales.taskmanagement.title.configData': 'Call Data',
  'isales.taskmanagement.title.createTask': 'Create',
  'isales.taskmanagement.title.deleteFailed': 'Failed to delete the task',
  'isales.common.message.choose': 'Please select data.',
  'isales.taskmanagement.title.deleteSuccess': 'The task is deleted successfully',
  'isales.taskmanagement.title.edit': 'Edit',
  'isales.taskmanagement.title.editPolicy': 'Edit Policy',
  'isales.taskmanagement.title.inputTaskName': 'Enter a task name',
  'isales.taskmanagement.title.outbound': 'Outbound Call Chatbot',
  'isales.taskmanagement.title.pause': 'Suspend',
  'isales.taskmanagement.title.query': 'Query',
  'isales.taskmanagement.title.queryTimeTip': 'Information',
  'isales.taskmanagement.title.reset': 'Reset',
  'isales.taskmanagement.title.start': 'Start',
  'isales.taskmanagement.title.status': 'Status',
  'isales.taskmanagement.title.viewTaskResult': 'Task Result',
  'isales.taskmanagment.title.viewstatistics': 'View Statistics',
  'isales.taskpolicy.field.connRate': 'Outbound Call Connection Rate',
  'isales.taskpolicy.field.connRateMethod': 'Algorithm for Outbound Call Connection Rate',
  'isales.taskpolicy.field.experienceMethod': 'Algorithm for Empirical Prediction',
  'isales.taskpolicy.field.failInterval': 'Outbound Call Interval',
  'isales.taskpolicy.field.lossRate': 'Min. Call Loss Rate',
  'isales.taskpolicy.field.maxAvgCallTime': 'Max. Average Call Duration',
  'isales.taskpolicy.field.minAvgCallTime': 'Min. Average Call Duration ',
  'isales.taskpolicy.field.minChangeTime': 'Min. Stay Duration',
  'isales.taskpolicy.field.multiSkill': 'Hybrid Skill Queue',
  'isales.taskpolicy.field.notSupportMultiSkill': 'Hybrid agents for inbound and outbound calls are not supported',
  'isales.taskpolicy.field.obCallCount': 'Number of Outbound Calls at a Time',
  'isales.taskpolicy.field.obOverTime': 'Max. Response Duration',
  'isales.taskpolicy.field.pickupRateMethod': 'Algorithm for Offhook Rate',
  'isales.taskpolicy.field.predictMethod': 'Algorithm for Predicted Outbound Calls',
  'isales.taskpolicy.field.supportMultiSkill': 'Hybrid agents for inbound and outbound calls are supported',
  'isales.taskpolicy.field.talkingRate': 'Outbound Call Speed',
  'isales.taskpolicy.field.utilRate': 'Agent Usage',
  'isales.taskpolicy.field.utilRateCalculateMethod': 'Method for Calculating Agent Usage',
  'isales.taskpolicy.field.utilRateCalculateOne': 'Agent Usage = Total Call Duration/Total Sign-in Duration',
  'isales.taskpolicy.field.utilRateCalculateTwo': 'Agent Usage = (Total Call Duration + Working State Duration)/Total Sign-in Duration',
  'isales.taskpolicy.field.utilRateMethod': 'Algorithm for Agent Usage',
  'isales.taskpolicy.field.waitTime': 'Called Party\'s Waiting Time',
  'isales.taskpolicy.message.input.maxAvgCallTime': 'The Max. Average Call Duration cannot be less than the Min. Average Call Duration',
  'isales.taskpolicy.message.input.minAvgCallTime': 'The Min. Average Call Duration cannot be more than the Max. Average Call Duration',
  'isales.taskresult.field.failedtooccupytheagent': 'Failed to occupy an agent',
  'isales.taskresult.field.failedtopause': 'Cannot suspend the task because it is already in the suspended state',
  'isales.taskresult.field.failedtoresume': 'Cannot recover the task because the task is not in the suspended state',
  'isales.taskresult.field.failsAcdtimeout': 'The ACD times out',
  'isales.taskresult.field.failsAgentdevicetimesout': 'The agent occupation times out',
  'isales.taskresult.field.failsAgentfailswithuser': 'Failed to set up a voice channel between an agent and a subscriber',
  'isales.taskresult.field.failsAns1': 'Text Telephone (ANS1)',
  'isales.taskresult.field.failsAns2': 'Text Telephone (ANS2)',
  'isales.taskresult.field.failsAns3': 'Text Telephone (ANS3)',
  'isales.taskresult.field.failsAns4': 'Text Telephone (ANS4)',
  'isales.taskresult.field.failsAns5': 'Text Telephone (ANS5)',
  'isales.taskresult.field.failsAutomaticansweringmachine': 'The called party is an automatic answering machine',
  'isales.taskresult.field.failsAutomaticdevicedetected': 'Automatic devices are detected',
  'isales.taskresult.field.failsBeingchanged': 'The called party is changing and the call is forwarded',
  'isales.taskresult.field.failsBusytone': 'Busy tone',
  'isales.taskresult.field.failsCallattemptfails': 'Failed to invoke the outbound call interface',
  'isales.taskresult.field.failsCalldatatimeout': 'Call data times out',
  'isales.taskresult.field.failsCalledmodem': 'The called party is a modem',
  'isales.taskresult.field.failsCallednumberincorrect': 'Incorrect called number in the preview',
  'isales.taskresult.field.failsCalledpartybusy': 'The called party is busy',
  'isales.taskresult.field.failsCallqueueoverflow': 'A call queue overflows',
  'isales.taskresult.field.failsCallrejected': 'The call is rejected',
  'isales.taskresult.field.failsCallwaiting': 'Call waiting',
  'isales.taskresult.field.failsCpp': 'Called forwarding by hooking',
  'isales.taskresult.field.failsDestinationunreachable': 'The destination is unreachable',
  'isales.taskresult.field.failsDisconnection': 'Call Failure',
  'isales.taskresult.field.failsDnd': 'The call is temporarily rejected',
  'isales.taskresult.field.failsDtmf': 'Text Telephone (DTMF)',
  'isales.taskresult.field.failsEdt': 'Text Telephone (EDT)',
  'isales.taskresult.field.failsEmptyorwrong': 'Unreachable or wrong number',
  'isales.taskresult.field.failsFak': 'The called party is a fax',
  'isales.taskresult.field.failsFaxdetected': 'A fax is detected',
  'isales.taskresult.field.failsFaxormodem': 'The called party is a fax or modem',
  'isales.taskresult.field.failsFsk': 'Text Telephone (FSK)',
  'isales.taskresult.field.failsHangsupbeforeanswering': 'The called party hangs up before the call is answered',
  'isales.taskresult.field.failsHangsupwhencall': 'When an agent answers a call using the agent bar control\, the agent\'s phone is disconnected',
  'isales.taskresult.field.failsInsufficientresources': 'Insufficient resources',
  'isales.taskresult.field.failsInvalidstatus': 'Invalid DTE status',
  'isales.taskresult.field.failsModemdetected': 'A modem is detected',
  'isales.taskresult.field.failsModemorfax': 'A modem or fax is detected',
  'isales.taskresult.field.failsMusicdetected': 'Music is detected',
  'isales.taskresult.field.failsNoacdresource': 'No resource available on the ACD',
  'isales.taskresult.field.failsNoagentavailable': 'When the OBS changes to the standby state\, if no agent is available for secure exit or preoccupied outbound calls\, failed records are canceled',
  'isales.taskresult.field.failsNoanswer': 'The called party does not answer the call',
  'isales.taskresult.field.failsNoreason': 'Unconditional DTE transfer',
  'isales.taskresult.field.failsNoreply': 'The called party does not answer the call',
  'isales.taskresult.field.failsNoresource': 'No resource available',
  'isales.taskresult.field.failsNoresponse': 'The called party does not respond',
  'isales.taskresult.field.failsNotintheservicearea': 'The phone is out of service',
  'isales.taskresult.field.failsNumberchange': 'The number is changed',
  'isales.taskresult.field.failsNumberreacheslimit': 'The number of OBS calls reaches the upper limit',
  'isales.taskresult.field.failsObsexits': 'When the OBS exits\, unanswered ongoing records are canceled and written back',
  'isales.taskresult.field.failsPhonenumberisnull': 'The outbound number is empty',
  'isales.taskresult.field.failsPlayingvoices': 'The signaling detects that the peer switch is playing voices',
  'isales.taskresult.field.failsPoweredoff': 'The phone is powered off',
  'isales.taskresult.field.failsQueuingtimesout': 'The queue times out and the platform releases the call',
  'isales.taskresult.field.failsRecordcanceled': 'When the OBS changes to the standby state\, ongoing records are canceled',
  'isales.taskresult.field.failsRecordswrittenback': 'Failed records are written back when the OBS exits (or a task is deleted)',
  'isales.taskresult.field.failsReleasesbeforecall': 'Before a voice channel is set up\, an agent releases a call using the agent bar control',
  'isales.taskresult.field.failsReleaseswhencall': 'When a voice channel is set up\, an agent releases a call using the agent bar control',
  'isales.taskresult.field.failsResultnotobtained': 'Outbound call data is written back when no result is obtained after the call times out',
  'isales.taskresult.field.failsResulttimesoutafterpicksup': 'The outbound call detection result after ACD offhooking times out',
  'isales.taskresult.field.failsRingbacktone': 'Ringback tone',
  'isales.taskresult.field.failsRingingtimesout': 'The ringing times out and the platform releases the call',
  'isales.taskresult.field.failsSittone': 'Special information tone',
  'isales.taskresult.field.failsSsit': 'Tone for sending dedicated information',
  'isales.taskresult.field.failsSubscriberbusy': 'The called party is busy',
  'isales.taskresult.field.failsSubscriberhangsup': 'The called party hangs up',
  'isales.taskresult.field.failsSwitchingdevicebusy': 'The switch is busy',
  'isales.taskresult.field.failsTasknotexist': 'No outbound call task available',
  'isales.taskresult.field.failsTransferbeingalerted': 'The call is being forwarded during ringing',
  'isales.taskresult.field.failsTransfernoreply': 'The call is being forwarded on no reply',
  'isales.taskresult.field.failsTransferonbusy': 'The call is being forwarded on busy',
  'isales.taskresult.field.failsTransferunconditionally': 'The call is being forwarded unconditionally',
  'isales.taskresult.field.failsUnallocatednumber': 'Unallocated number',
  'isales.taskresult.field.failsUndefinedsignaltone': 'Undefined signal tone',
  'isales.taskresult.field.failsUnreachable': 'Unreachable mobile subscriber',
  'isales.taskresult.field.failsUserhangsupinthequeue': 'All Agents Are Busy\,and the User Proactively Hangs Up',
  'isales.taskresult.field.failsUserrejection': 'The call is rejected',
  'isales.taskresult.field.failsWaitresulttimesout': 'Waiting for outbound call results times out on the platform',
  'isales.taskresult.field.failsWithoutringing': 'The call is being forwarded on immediate response before ringing',
  'isales.taskresult.field.failsCallRouting': 'Failed to route the call',
  'isales.taskresult.field.failsAgentHangsUp': 'The agent hangs up',
  'isales.taskresult.field.failsAgentCancelingCall': 'The agent cancels the call',
  'isales.taskresult.field.failsAgentDisconnection': 'The agent is disconnected',
  'isales.taskresult.field.failsCallTimeout': 'The call times out',
  'isales.taskresult.field.failsResourceOverload': 'Insufficient Resources',
  'isales.taskresult.field.failsUnallocatedOrIncorrectNumber': 'Unreachable or wrong number',
  'isales.taskresult.field.failsTimeout': 'The system times out',
  'isales.taskresult.field.failsInvalidUser': 'Invalid user',
  'isales.taskresult.field.failsInvalidDevice': 'Invalid device',
  'isales.taskresult.field.failsNoAnsweringDeviceAvailable': 'No answering device available',
  'isales.taskresult.field.failsAbnormal': 'Error',
  'isales.taskresult.field.failsInvalidRoutingDevice': 'Invalid routing device',
  'isales.taskresult.field.failsSwitchRestart': 'The switch restarted',
  'isales.taskresult.field.failsUnknownError': 'Unknown error',
  'isales.taskresult.field.failsCallResourcesInsufficient': 'Insufficient call resources',
  'isales.taskresult.field.failsNoValidVDN': 'No valid VDN available',
  'isales.taskresult.field.failsCallFailure': 'The call failed',
  'isales.taskresult.field.failsFailedRouteAgent': 'Failed to route the call to the agent',
  'isales.taskresult.field.failsInvalidSkillQueue': 'Invalid skill queue',
  'isales.taskresult.field.failsFailedRouteSkillQueue': 'Failed to route the skill queue',
  'isales.taskresult.field.failsFailedRouteCallToAttendant': 'Failed to transfer the call to the agent',
  'isales.taskresult.field.failsFailedAgentIsBusy': 'The agent is not in the idle state',
  'isales.taskresult.field.failsCalledOnHook': 'The agent hooks on a call',
  'isales.taskresult.field.success': 'Successful',
  'isales.taskresult.field.no': 'No',
  'isales.taskresult.message.details': 'Details',
  'isales.taskresult.message.queryTime': 'Enter a complete time',
  'isales.taskresult.title.busiresultupdate': 'Append Busiresult',
  'isales.taskresult.title.beginTime': 'Start Time',
  'isales.taskresult.title.chooseBeginTime': 'Please select start time',
  'isales.taskresult.title.calledNo': 'Outbound Number',
  'isales.taskresult.title.callFlag': 'Status',
  'isales.taskresult.title.chooseCallFlag': 'Please select status',
  'isales.taskresult.title.callResult': 'Result',
  'isales.taskresult.title.endTime': 'End Time',
  'isales.taskresult.title.chooseEndTime': 'Please select end time',
  'isales.taskresult.title.export': 'Export',
  'isales.taskresult.title.exportQuestion': 'Export Question',
  'isales.taskresult.title.trainingQuestion': 'Training Test Questions',
  'isales.taskresult.title.exportResult': 'View Export Result',
  'isales.taskresult.title.failCode': 'Cause',
  'isales.taskresult.title.inputCallerNo': 'Enter an outbound number',
  'isales.taskresult.title.queryTime': 'Information',
  'isales.taskresult.title.result': 'Call Result',
  'isales.taskresult.title.busisubstatus': 'Business SubStatus',
  'isales.taskresult.title.busidesc': 'Business Description',
  'isales.taskresult.title.chooseResult': 'Please select call result',
  'isales.taskresult.title.choosebusisubstatus': 'Please select business result before selecting substatus',
  'isales.taskresult.title.choosebusiresult': 'Please select business result',
  'isales.taskresult.title.resultDetail': 'Call Result Details',
  'isales.taskresultexport.message.begintime': 'Start Time',
  'isales.taskresultexport.message.downloadConfirmInfo': 'Are you sure you want to download the file?',
  'isales.taskresultexport.message.downloadExportdatafailinfo': 'Verification failed. Cannot find the exported file data',
  'isales.taskresultexport.message.downloadFail': 'Download Failed',
  'isales.taskresultexport.message.downloadFilesuffixfailinfo': 'Download failed. The exported file information does not contain the file name extension',
  'isales.taskresultexport.message.downloadRedischeckfailinfo': 'A file can be downloaded again only when the previous download is complete',
  'isales.taskresultexport.message.endtime': 'End Time',
  'isales.taskresultexport.message.result': 'Result',
  'isales.taskresultexport.title.download': 'Download',
  'isales.taskresultexport.title.downloadConfirm': 'OK',
  'isales.taskresultexport.title.exported': 'To Be Exported',
  'isales.taskresultexport.title.fail': 'Failed',
  'isales.taskresultexport.title.progress': 'Exporting',
  'isales.taskresultexport.title.success': 'Successful',
  'isales.taskstatistics.field.calling': 'Calling',
  'isales.taskstatistics.field.completedcalls': 'Called',
  'isales.taskstatistics.field.notcompletedcalls': 'Pending',
  'isales.taskstatistics.placeholder.called': 'Called: {0}',
  'isales.taskstatistics.placeholder.calling': 'Calling: {0}',
  'isales.taskstatistics.placeholder.notcalled': 'Pending: {0}',
  'isales.taskstatistics.placeholder.statistics': 'Statistics',
  'isales.taskstatus.field.all': 'All',
  'isales.taskstatus.field.draft': 'Draft',
  'isales.taskstatus.field.execute': 'Executing',
  'isales.taskstatus.field.finish': 'Expired',
  'isales.taskstatus.field.suspend': 'Suspended',
  'isales.tasktype.field.intelligence': 'Intelligent Outbound Call',
  'isales.tasktype.field.prediction': 'Predicted Outbound Call',
  'isales.tasktype.field.automatic': 'Automatic Outbound Call',
  'isales.validate.message.formatError': 'Enter a date in MM/DD format',
  'isales.validate.message.holidayDateComparisonError': 'The end date cannot be earlier than the start date',
  'isales.validate.message.notSpecial': 'The name cannot contain special characters',
  'isales.operlog.message.data.import': 'Import data for task {0}',
  'isales.operlog.message.data.downloadtemplate': 'Download template of data import',
  'isales.operlog.message.data.downloadrepeatfile': 'Download duplicate data file',
  'isales.operlog.message.data.downloadfailfile': 'Download failed data file',
  'isales.operlog.message.data.downloadexportfile': 'Download task {0} export result file',
  'isales.statistics.field.addHis': 'Archived Data Collection',
  'isales.statistics.field.addHisYes': 'Yes',
  'isales.taskresult.title.queryArchivedData': 'Query Archived Data',
  'isales.taskresult.field.yes': 'Yes',
  'isales.taskmanagement.message.afterNow': 'The start time cannot be later than the current time',
  'isales.taskresult.title.failedDetail': 'Call Failure Details',
  'isales.taskmanagement.field.dailyStatistics': 'Daily Statistics',
  'isales.taskmanagement.field.statisticsDate': 'Statistics Collection Date',
  'isales.taskmanagement.field.statisticsType': 'Statistics Type',
  'isales.blacklist.title.calledNo': 'Called Number',
  'isales.blacklist.title.remark': 'Remarks',
  'isales.blacklist.placeholder.searchcalledno': 'Enter the Called Number',
  'isales.blacklist.title.createblacklist': 'Add Number to Blocklist',
  'isales.blacklist.title.name': 'Name',
  'isales.tasklogic.field.retryDay': 'Max. Days for Call Attempts Before Connection',
  'isales.tasklogic.field.retryTimes': 'Max. Number of Daily Call Attempts',
  'isales.tasklogic.title.addPolicy': 'Add Policy',
  'isales.tasklogic.field.result': 'Result',
  'isales.tasklogic.field.retryNumber': 'Retry Times',
  'isales.tasklogic.field.callInterval': 'Call-Out Interval (s)',
  'isales.task.title.calllogic': 'Outbound Call Logic',
  'isales.blacklist.title.restrictTimes': 'Maximum Number of Calls',
  'isales.blacklist.placeholder.restrictTimes': 'Enter the maximum number of calls',
  'isales.blacklist.title.restrictPeriod': 'Restriction Period',
  'isales.blacklist.title.hour': 'Every hour',
  'isales.blacklist.title.day': 'Every day',
  'isales.blacklist.message.Description': 'Maximum number of calls every hour or day within the validity period',
  'isales.blacklist.message.periodAndTime': 'The restriction period and maximum number of calls must be set or left empty at the same time',
  'isales.blacklist.placeholder.searchname': 'Name',
  'isales.blacklist.placeholder.searchremark': 'Remarks',
  'isales.blacklist.message.requestEmpty': 'Failed to validate the parameter for adding the number to the blocklist',
  'isales.blacklist.message.createSuccess': 'The number is successfully added to the blocklist',
  'isales.blacklist.message.createFail': 'Failed to add the number to the blocklist',
  'isales.blacklist.message.error': 'An error occurs when the number is added to the blocklist',
  'isales.blacklist.message.confirmDelete': 'Are you sure you want to delete the selected numbers from the blocklist?',
  'isales.blacklist.message.deleteBlacklist': 'Select the blocklisted number to be deleted',
  'isales.blacklist.title.deleteError': 'Error',
  'isales.blacklist.message.paramEmpty': 'Select a blocklisted number',
  'isales.blacklist.message.deletePartiallyFail': 'Failed to delete some numbers from the blocklist',
  'isales.blacklist.title.deleteSuccess': 'Success',
  'isales.blacklist.message.deleteSuccess': 'The number is successfully deleted from the blocklist',
  'isales.calledNo.message.input': 'Only digits are allowed',
  'isales.blacklist.message.calledno.empty': 'No number is entered',
  'isales.blacklist.message.name.reg.empty': 'The regular rule of the name is empty. Contact the administrator to add it',
  'isales.blacklist.message.calledno.error': 'The number contains invalid characters',
  'isales.blacklist.message.name.error': 'The name contains invalid characters',
  'isales.blacklist.message.remark.error': 'The remarks contain invalid characters',
  'isales.blacklist.message.calledno.exist': 'The number exists in the blocklist',
  'isales.blacklist.message.customer.notincall': 'The customer is not in call',
  'isales.blacklist.message.customer.empty': 'No customer in call',
  'isales.taskcalllogic.message.choosePolicy': 'Please select at least one result processing strategy',
  'isales.taskcalllogic.title.resultPolicySet': 'Results processing strategy',
  'isales.taskcalllogic.title.retryPolicySet': 'Trial call policy',
  'isales.taskcalllogic.title.resultSetPolicy': 'Configure Policy Based On Results',
  'isales.taskcalllogic.message.resultTips': 'There can only be one strategy for one result',
  'isales.taskcalllogic.field.customerbusy': 'Customer busy',
  'isales.taskcalllogic.field.customerMissed': 'Customer missed',
  'isales.taskcalllogic.field.customerRefused': 'Customer refused',
  'isales.taskcalllogic.field.emptyNumber': 'Out-of-service number',
  'isales.taskcalllogic.field.shutdown': 'Customer shutdown',
  'isales.taskcalllogic.field.notServiceArea': 'Customer is not in the service area',
  'isales.taskcalllogic.field.fax': 'Fax\, switchboard\, etc',
  'isales.taskcalllogic.field.callTimeout': 'Call timeout',
  'isales.taskcalllogic.field.callFailed': 'Call failed',
  'isales.taskcalllogic.field.other': 'Other',
  'isales.management.message.blackCalledNo': 'The called number is in the blocklist and cannot be added',
  'isales.taskstatistics.field.failCode': 'Failure Cause',
  'isales.taskstatistics.field.failCount': 'Number of Failed Calls',
  'isales.appointment.title.appointTime': 'Reserved Call Time',
  'isales.appointment.title.task': 'Associate Outbound Call Task',
  'isales.appointment.title.failCode': 'Failure Cause',
  'isales.appointment.title.remark': 'Remarks',
  'isales.appointment.title.call': 'Call',
  'isales.appointment.title.createAppoint': 'Create Reserved Outbound Call',
  'isales.appointment.title.editAppoint': 'Modify Reserved Outbound Call',
  'isales.appointment.placeholder.appointTime': 'Select the reserved call time',
  'isales.appointment.placeholder.remark': 'Please Enter remarks',
  'isales.appointment.message.afterNow': 'The reserved time cannot be earlier than the current time',
  'isales.appointment.message.createSuccess': 'The reserved outbound call is created successfully',
  'isales.appointment.message.updateSuccess': 'The reserved outbound call is modified successfully',
  'isales.appointment.message.createFail': 'Failed to create the reserved outbound call',
  'isales.appointment.message.updateFail': 'Failed to modify the reserved outbound call',
  'isales.manual.message.query.agentstatus.error': 'An error occurred when querying the agent status',
  'isales.manual.message.agentstatus.isnofree': 'The current agent is not in idle state',
  'isales.manual.message.task.isnorunning': 'The current outbound call task is not in the Executing state',
  'isales.manual.message.update.fail': 'Failed to update the outbound call data status',
  'isales.manual.message.data.query.error': 'An error occurred when querying outbound call data',
  'isales.manual.message.data.checklock.error': 'The current outbound call data is not locked or has been locked by another agent',
  'isales.manual.message.data.checklock.inblack': 'The outgoing call number is in the blocklist',
  'isales.manual.message.precall.success': 'An outbound call is being initiated',
  'isales.manual.title.fail.reason': 'Failure Cause',
  'isales.manual.title.result': 'Result',
  'isales.manual.title.status': 'Status',
  'isales.manual.title.calledNo': 'Outbound Number',
  'isales.manual.title.inputfailcode': 'Enter a failure cause',
  'isales.manual.title.inputresult': 'Enter an outbound call result',
  'isales.manual.title.inputstatus': 'Enter the status of an outbound call',
  'isales.manual.field.callflag.inprocess': 'Calling',
  'isales.manual.field.callflag.finish': 'Completed',
  'isales.manual.field.callflag.return': 'Back',
  'isales.manual.field.result.fail': 'Failed',
  'isales.manual.field.result.success': 'Successful',
  'isales.manual.message.data.isalready.called': 'The customer has made a call and cannot make another call',
  'isales.manutask.title.viewdata': 'Outbound Call Data',
  'isales.manutask.title.viewresult': 'Outbound Call Result',
  'isales.manual.message.call.success': 'The outbound call is made successfully',
  'isales.manual.message.not.call': 'No outbound call is made',
  'isales.manual.message.call.failed': 'Failed to make the outbound call',
  'isales.manual.message.call.update.error': 'Failed to update the outbound call result',
  'isales.manual.message.call.error': 'An error occurred when processing the outbound call result',
  'isales.manualdata.title.lock': 'Lock',
  'isales.manualdata.title.release': 'Release',
  'isales.manualdata.title.order': 'Reserve Outbound Call',
  'isales.manualdata.title.call': 'Call',
  'isales.manual.title.lock': 'Lock',
  'isales.manual.message.confirmLockData': 'Are you sure you want to lock the outbound call data?',
  'isales.manual.message.empty': '{0} is empty',
  'isales.manual.message.lock.failed': 'Failed to lock the outbound call data',
  'isales.manual.message.lock.success': 'The outbound call data are locked successfully',
  'isales.manual.message.error': 'An error occurred when updating data',
  'isales.manual.message.confirmReleaseData': 'Are you sure you want to release the outbound call data?',
  'isales.manual.message.release.success': 'The outbound call data are released successfully',
  'isales.manual.message.release.failed': 'Failed to release the outbound call data',
  'isales.manual.message.releaseData': 'Select outbound call data to be released',
  'isales.manual.message.lockData': 'Select outbound call data to be locked',
  'isales.manual.message.taskstatus.error': 'The outbound call is not in the Executing state',
  'isales.appointment.message.callNotAllowed': 'Cannot make a reserved outbound call because the agent is not in idle or busy state',
  'isales.appointment.message.confirmDelete': 'Are you sure you want to delete the reserved outbound calls?',
  'isales.appointment.message.deleteSuccess': 'The reserved outbound calls are deleted successfully',
  'isales.appointment.message.deleteFail': 'Failed to delete the reserved outbound calls',
  'isales.appointment.message.agentNotCalling': 'Cannot add a reserved outbound call because the agent is not in a call',
  'isales.taskmanagement.title.viewAppointList': 'Reservation',
  'isales.taskmanagement.title.viewAppointListDetail': 'Reservation',
  'isales.taskmanagement.title.appoint': 'Reserve Outbound Call',
  'isales.appointment.title.userName': 'Initiated by',
  'isales.appointment.title.callTime': 'Call Time Segment',
  'isales.manualdata.title.updateCalledNo': 'Changing Outbound Number',
  'isales.appointment.message.callSuccess': 'Successful',
  'isales.appointment.message.callFail': 'Failed',
  'isales.appointment.message.timeout': '{0} appointed outbound calls had passed the appointment time\, Please make calls in time',
  'isales.appointment.message.soontimeout': '{0} appointed outbound calls will arrive in the next 5 minutes\, Please prepare to make calls',
  'isales.taskmanagement.field.manual': 'Agent Outbound Call',
  'isales.taskresult.title.userAccount': 'Initiated By',
  'isales.taskresult.title.chooseUserAccount': 'Please select initiated by',
  'isales.taskmanagement.title.addManual': 'Create Agent Outbound Call',
  'isales.taskinfo.field.noSpecifiedAgent': 'Entire Skill Queue',
  'isales.taskinfo.field.specifiedAgent': 'Specified Agent',
  'isales.taskinfo.title.chooseAgent': 'Select Agent',
  'isales.manual.message.afterNow': 'The end time cannot be earlier than the current time',
  'isales.appointment.title.add': 'Add',
  'isales.agent.addblocklist.numbertype': 'Number Type',
  'isales.agent.addblocklist.calldatatype': 'Customer Type',
  'isales.agent.toblocklist.addType': 'Add blockList type',
  'isales.data.message.confirmaddblock.calldatatype': 'The current type is call data. Please confirm again',
  'isales.taskmanagement.title.export': 'Export Call Record',
  'isales.taskmanagement.field.zip.password': 'Compressed package password',
  'isales.taskmanagement.field.zip.input.password': 'Enter at least eight characters and a maximum of twelve characters that contain digits\, letters\, and special characters but not contain /\\-#(){\'{\'}{\'}\'};&{\'|\'}<>{\'$\'}`\\\\!\\n\\s\\t\\r',
  'isales.export.message.input.error': 'The password is not in correct format',
  'isales.taskmanagement.message.period.seven': 'The interval cannot be longer than 7 days',
  'isales.taskmanagement.title.view.callrecord': 'Call Record',
  'isales.taskmanagement.field.calledNo': 'Called Number',
  'isales.taskmanagement.field.calltime': 'Initiated at',
  'isales.taskmanagement.field.usersaying': 'Request Text',
  'isales.taskmanagement.field.answer': 'Response Text',
  'isales.taskmanagement.title.callRecord': 'Outbound Call Record',
  'isales.taskmanagement.title.out.callresult': 'Outbound Call Result',
  'isales.taskmanagement.field.intention': 'Recognized Intention',
  'isales.taskinfo.message.agentsEmpty': 'No agent is selected',
  'isales.appointment.message.queryTimeError': 'The start time must be earlier than the end time',
  'isales.taskinfo.field.audioAgentType': 'Audio',
  'isales.taskinfo.field.videoAgentType': 'Video',
  'isales.taskinfo.field.mediaAgentType': 'Multimedia',
  'isales.taskinfo.field.versatileAgentType': 'Versatile',
  'isales.appointment.message.tasknotrun': 'The current outgoing call task is not started. The call is prohibited',
  'isales.appointment.message.notInWhiteList': 'The number is not in the trustlist and cannot be called',
  'isales.taskresult.field.failsCallednumbernotinwhitelist': 'The number is not in the outbound call trustlist',
  'isales.taskresult.title.retryTime': 'Call Attempts',
  'isales.appointment.message.callTimeTooEarly': 'The call time is earlier than the reserved time. The call is prohibited!',
  'isales.management.message.remarkLengthError': 'A maximum of 1000 characters are allowed',
  'isales.management.message.callNoInBlackList': 'Cannot call the number because it is blocklisted',
  'isales.taskmanagement.field.zip.makesure.password': 'Re-enter the compressed package password',
  'isales.taskmanagement.message.password.not.same': 'The two passwords are inconsistent',
  'isales.taskresultexport.message.type': 'Export type',
  'isales.taskpolicy.message.input.digits': 'Enter an integer',
  'isales.tasktype.field.intelligent': 'Intelligent Robot Outbound Call',
  'isales.tasktype.field.predict': 'Predicted Outbound Call',
  'isales.tasktype.field.auto': 'Common IVR Outbound Call',
  'isales.callNo.field.type': 'Number Type',
  'isales.callNo.field.delayTime': 'Call Failure Delay Time(s)',
  'isales.callNo.field.add': 'Add',
  'isales.callno.message.delayTime': 'Call Failure Delay Time: Interval between the current number and the previous number',
  'isales.data.message.addNumberUpperLimit': 'A maximum of 10 numbers can be added',
  'isales.data.message.duplicateExist': 'Duplicate numbers exist',
  'isales.tasklogic.field.policyType': 'PolicyType',
  'isales.tasklogic.field.addCallnoLogic': 'Add Contact Policy',
  'isales.tasklogic.field.dataType': 'Data Type',
  'isales.tasklogic.field.isResultCallnoLogic': 'Whether to configure based on the result',
  'isales.taskcalllogic.field.globalPolicy': 'Global policy',
  'isales.taskcalllogic.field.callnoPolicy': 'Contact Policy',
  'isales.tasklogic.title.addCallnoPolicy': 'Add Result Policy',
  'isales.tasklogic.field.addDataType': 'Add DataType',
  'isales.tasklogic.field.existingDataType': 'Select an existing datatype',
  'isales.taskcalllogic.message.alreadyLastPolicy': 'Already Last Policy',
  'isales.data.filed.noResult': 'No',
  'isales.data.filed.isResult': 'Yes',
  'isales.taskcalllogic.message.dataTypeExist': 'DataType Already Exist',
  'isales.busi.result.title.result': 'Result',
  'isales.busi.result.title.description': 'Description',
  'isales.busi.result.item.unset': 'Not set',
  'isales.busi.result.item.succ': 'Success',
  'isales.busi.result.item.uncertain': 'Uncertain',
  'isales.busi.result.item.fail': 'Failure',
  'isales.busi.result.message.recordsucc': 'The business result is recorded successfully',
  'isales.busi.result.message.recordfail': 'The business result is recorded failed',
  'isales.busi.result.message.lastrecord': 'Pleanse set the business result of the last outbound first',
  'isales.busi.result.message.lastappointrecord': 'Pleanse set the business result of the last reserved outbound call first',
  'isales.busi.result.message.nodatarecord': 'No call information is found. Please select the outbound call record whose business result needs to be set',
  'isales.data.message.confirmSubmit': 'The current number sequence is the call sequence. Please check the data again',
  'isales.data.title.tableTitle': 'Number list',
  'isales.callNo.title.no': 'No',
  'isales.callpolicy.title.add': 'Add',
  'isales.management.message.updateSuccess': 'The outbound call data is modified successfully',
  'isales.management.message.updateFail': 'Failed to modify the outbound call data',
  'isales.blacklist.title.validityTime': 'Validity Time (Hour)',
  'isales.blacklist.title.expirationTime': 'Expiration Time',
  'isales.blacklist.title.operateAgent': 'Added Agent',
  'isales.management.title.updateData': 'Modify Outgoing Call Data',
  'isales.management.title.detailData': 'Outbound Call Data Details',
  'isales.common.message.checknonnegativeinteger': 'Enter a non-negative integer in the correct format',
  'isales.common.message.checkpositiveinteger': 'Enter a positive integer in the correct format',
  'isales.taskcalllogic.message.mostPolicys': 'A maximum of 10 result policies can be configured for a number',
  'isales.tasklogic.field.callresultpolicy': 'Outbound Call Result Policy',
  'isales.tasklogic.field.busiresultpolicy': 'Business Result Policy',
  'isales.tasklogic.field.enablebusiresultpolicy': 'Enable the business result policy',
  'isales.tasklogic.field.connectsameagent': 'Connect to the same agent',
  'isales.tasklogic.field.addbusiresultpolicy': 'Add Business Result Policy',
  'isales.tasklogic.field.substate': 'Substate',
  'isales.tasklogic.field.policy': 'Policy',
  'isales.tasklogic.field.interval': 'Interval(Second)',
  'isales.common.title.edit': 'Edit',
  'isales.taskcalllogic.busiresultpolicy.stopcall': 'Stop the call',
  'isales.taskcalllogic.busiresultpolicy.callnext': 'Call the next number',
  'isales.taskcalllogic.busiresultpolicy.callcurrent': 'Call the current number',
  'isales.tasklogic.message.busiresultpolicyIncomplete': 'The business result policy information is incomplete',
  'isales.tasklogic.message.busiresultpolicyNoadded': 'No business result policy is added',
  'isales.tasklogic.message.busiresultpolicyNotsaved': 'Some business result policies are not saved',
  'isales.tasklogic.message.busiresultpolicySame': 'The business results and sub-states of multiple business result policies are the same',
  'isales.tasklogic.message.busiresultpolicyExists': 'A business result policy with the same business result and sub-state already exists',
  'isales.business.result.searchname': 'Enter the business result name',
  'isales.business.result.name': 'Business Result Name',
  'isales.business.result.code': 'Business Result Code',
  'isales.business.result.description': 'Business Result Description',
  'isales.business.result.create': 'Create Business Result',
  'isales.business.result.edit': 'Modify Business Result',
  'isales.business.result.view': 'View Business Result',
  'isales.business.result.children': 'Sub-status',
  'isales.business.name': 'Name',
  'isales.business.code': 'Code',
  'isales.business.description': 'Description',
  'isales.business.result.addchildren': 'Create Sub-status',
  'isales.business.result.deletetips': 'Are you sure you want to delete the business result?',
  'isales.business.result.delete.error': 'No business result is selected',
  'isales.business.result.addchildrentips': 'A maximum of 50 sub-business results are allowed in a business result',
  'isales.business.result.message.specialStr': 'Not supported',
  'isales.business.result.message.checkCode': 'Only digits\, letters\, and hyphens (-) are allowed',
  'isales.business.result.delete.data.error': 'A maximum of 100 records can be deleted at a time',
  'isales.tasklogic.message.busiresultpolicyNotSaved': 'Save the existing business result policy first!',
  'isales.taskpolicy.field.previewTimeoutInterval': 'Preview Timeout Interval (s)',
  'isales.tasktype.field.preview': 'Preview Outbound Call',
  'isales.manual.field.previewinformation': 'Preview Information',
  'isales.manual.field.handlednumber': 'Session Number',
  'isales.manual.field.callednumber': 'Called Number',
  'isales.manual.field.callinginformation': 'Call Information',
  'isales.manual.field.callingtask': 'Call Task',
  'isales.taskresult.title.userName': 'User Name',
  'isales.taskresult.title.operateBeginTime': 'beginTime',
  'isales.taskresult.title.operateEndTime': 'endTime',
  'isales.taskresult.title.operationTime': 'Operate Time',
  'isales.taskresult.title.busiresultLog': 'Business Result Log',
  'isales.manual.message.release.DataNotWaitExists': 'Outgoing call data that is being called or completed exists',
  'isales.manual.message.dataId.Empty': 'dataId cannot be empty',
  'isales.busi.result.message.agentstatus.fail': 'Failed to query agent status',
  'isales.busi.result.message.systemparams.fail': 'Failed to query system parameters',
  'isales.business.result.message.add.tips': 'The business result is not defined',
  'isales.business.result.message.must': 'This parameter is mandatory',
  'isales.blacklist.title.modifyRemark': 'Modify Remark',
  'isales.blacklist.message.updateSuccess': 'Modify Remark Success',
  'isales.taskresult.field.userisunleashed': 'The call on the media server to which the agent logs in is released',
  'isales.taskresult.field.invalidCall': 'Invalid call',
  'isales.taskresult.field.notCheckIn': 'The agent has not signed in',
  'isales.manual.title.viewManualData': 'Agent Outbound Call Data-',
  'isales.manual.title.viewManualResult': 'Agent Outbound Call Result-',
  'isales.manual.title.viewPreviewResult': 'Preview Outbound Call Result-',
  'isales.manual.title.viewPredictionResult': 'Predicted Outbound Call Result-',
  'isales.taskresult.field.workNo': 'WorkNo',
  'isales.taskresult.field.skillqueue': 'Skill Queue',
  'isales.taskresult.field.operation': 'Operation',
  'isales.agent.field.skill': 'SkillName',
  'isales.agent.message.pwdRepeat': 'The submitted password is the same as the recently changed password',
  'isales.data.prop.publish': 'Publish',
  'isales.data.prop.status.published': 'Published',
  'isales.taskresult.field.other': 'other',
  'isales.taskmanagement.title.speclistmanage.reason': 'the reason',
  'isales.speclistmanage.title.calledNo': 'Number',
  'isales.speclistmanage.title.reason': 'Reason',
  'isales.speclistmanage.title.operation': 'Operating',
  'isales.specreason.field.all': 'please choose',
  'isales.specialday.title.confirm': 'confirm',
  'isales.tasklogic.field.addOrderPolicy': 'Add Prop',
  'isales.tasklogic.field.prop': 'prop',
  'isales.skillqueue.agentrole.administrator': 'Room manager',
  'isales.skillqueue.agentrole.qc': 'Quality inspector',
  'isales.data.prop.orderType': 'Order Type',
  'isales.data.orderType.asc': 'Ascending',
  'isales.data.orderType.desc': 'Descending',
  'isales.speclist.blacklist.message.moveSuccess': 'Number transferred to blacklist successfully',
  'isales.speclist.blacklist.message.movePartiallyFail': 'Number transfer to blacklist failed',
  'isales.taskinfo.field.callTimes': 'Call Attempts',
  'isales.taskmanagement.title.batchStartTask': 'Batch Start',
  'isales.taskmanagement.title.batchPauseTask': 'Batch Suspend',
  'isales.taskmanagement.title.batchEdit': 'Batch Modify',
  'isales.taskmanagement.title.batchEditTaskInfo': 'Batch Modify Basic Information',
  'isales.taskmanagement.title.batchEditCallTime': 'Batch Change Outbound Call Time',
  'isales.taskmanagement.title.batchEditCallPolicy': 'Batch Modify Outbound Call Policy',
  'isales.taskmanagement.title.batchEditCallLogic': 'Batch Modify Outbound Call Logic',
  'isales.taskmanagement.title.tipsBatchStartTask': 'Select the task to be started',
  'isales.taskmanagement.title.batchStart.finishedStatus': 'Completed tasks cannot be started',
  'isales.taskmanagement.title.batchPause.statusError': 'Tasks in the draft or expired state cannot be suspended',
  'isales.taskmanagement.title.startCount': 'A maximum of 30 outbound call tasks can be started at a time',
  'isales.taskmanagement.title.startConfirm': 'Are you sure you want to start the selected outbound call task?',
  'isales.taskmanagement.title.tipsBatchPauseTask': 'Select the task to be suspended',
  'isales.taskmanagement.title.pauseCount': 'A maximum of 30 outbound call tasks can be suspended at a time',
  'isales.taskmanagement.title.pauseConfirm': 'Are you sure you want to suspend the selected outbound call task?',
  'isales.taskmanagement.title.batchEditTaskInfo.errStatus': 'Select a task in the draft\, suspended\, or executing state',
  'isales.taskmanagement.title.batchEditCallPolicy.sameTaskType': 'Outbound call policies can be modified in batches only for tasks of the same type',
  'isales.taskmanagement.title.batchEditCallTime.manualError': 'The manual outbound call time cannot be changed',
  'isales.taskmanagement.title.batchEditTaskInfo.manual': 'Automatic outgoing call and manual outgoing call cannot be modified at the same time',
  'isales.taskmanagement.title.batchEditCallPolicy.manualError': 'Manual outbound call policies cannot be modified',
  'isales.task.message.NoStartTask': 'No task that meets the conditions is available',
  'isales.task.message.NoPauseTask': 'No task that meets the conditions is available',
  'isales.taskinfo.field.priority': 'Priority',
  'isales.taskmanagement.title.editInfo': 'Change Information',
  'isales.taskmanagement.title.editSample': 'Object Management',
  'isales.taskmanagement.title.editTime': 'Change Time',
  'isales.taskmanagement.title.editLogic': 'Change Logic',
  'isales.taskpolicy.field.calculationFactor': 'Calculation Factor',
  'isales.taskpolicy.field.pickupRate': 'Offhook Rate',
  'isales.taskresult.title.inputSessionId': 'Enter the Session Number',
  'isales.taskstatus.field.waiting': 'Waiting',
  'isales.taskstatus.field.callfinish': 'Completed',
  'isales.taskInfo.message.saveSuccess': 'The information is modified successfully',
  'isales.taskInfo.message.saveFail': 'Failed to modify the information',
  'isales.tasklogic.field.callpolicy': 'Call Policy',
  'isales.tasklogic.field.callmethod': 'Call Mode',
  'isales.tasklogic.field.callbyorder': 'In sequence',
  'isales.tasklogic.field.callbyturn': 'By round',
  'isales.tasklogic.field.callturntype': 'Round Mode',
  'isales.tasklogic.field.callturntype.numbertype': 'By number type',
  'isales.tasklogic.field.callturntype.numberorder': 'By sequence number',
  'isales.tasklogic.field.addturn': 'Add Round',
  'isales.tasklogic.field.turn': 'Round',
  'isales.tasklogic.field.numberorder': 'Sequence Number',
  'isales.tasklogic.field.numbertype': 'Number Type',
  'isales.tasklogic.field.add.numbertype': 'New number type',
  'isales.tasklogic.field.exist.numbertype': 'Existing number type',
  'isales.tasklogic.field.default': 'Default',
  'isales.tasklogic.message.no.numbertype': 'The number sequence number is null.',
  'isales.tasklogic.message.exist.orderNo': 'The number sequence already exists. Enter another one',
  'isales.tasklogic.message.need.turns': 'Add at least one round data',
  'isales.taskpolicy.field.previewAutoCallTime': 'Previewed Outbound Call Duration',
  'isales.taskinfo.message.autoCallTimeValueError': 'The previewed outbound call duration must be shorter than the preview timeout duration',
  'isales.taskinfo.message.batchUpdate.paramsNull': 'Edit the parameter to be modified',
  'isales.agent.contact.Voice': 'Voice',
  'isales.agent.contact.multimedia': 'Multimedia',
  'isales.agent.contact.vidio': 'Video',
  'isales.agent.contact.audioclicktodial': 'Voice click-to-dial',
  'isales.agent.contact.videoclicktodial': 'Video click-to-dial',
  'isales.agent.message.searchSkillName': 'Enter the skill queue name',
  'isales.agent.button.agent.association': 'Configure Agent',
  'isales.agent.title.skillCreate': 'Create Skill Queue',
  'isales.agent.message.updateCtiAndDbSuccess': 'The skill queue is updated successfully',
  'isales.agent.message.deleteSkillAndAgentFail': 'Failed to delete the skill queue association',
  'isales.agent.message.addSkillFail': 'Failed to add the skill queue',
  'isales.agent.message.modifySkillFail': 'Failed to update the skill queue',
  'isales.agent.message.queryCallCenterFail': 'Failed to query the call center data',
  'isales.agent.message.refreshSkillException': 'An error occurred when updating the skill queue',
  'isales.agent.message.deleteSkillConfFail': 'Failed to delete the skill queue information',
  'isales.agent.message.agentMsgException': 'Change the agent initial password and synchronize the password to the agent',
  'isales.agent.label.skillName': 'Skill Queue Name',
  'isales.agent.label.maxWaitTime': 'Max. Queuing Time',
  'isales.agent.label.agentList': 'Agent ID',
  'ccivr.ivr.ivrflow.type': 'Type',
  'isales.agent.label.adjustTime': 'Wrap-up Duration (s)',
  'isales.agent.title.skillModify': 'Modify Skill Queue',
  'isales.agent.message.deleteSkill': 'Delete Skill Queue',
  'isales.agent.message.willDeleteSkills': 'This operation may cause the outbound task call associated with the skill queue to fail. Please operate with caution. Do you need to delete it?',
  'isales.agent.message.deleteSkillConfSuccess': 'The skill queue is deleted successfully',
  'isales.agent.message.idIsEmpty': 'The skill queue ID cannot be empty',
  'isales.agent.label.theAgent': 'Agent',
  'isales.agent.label.useTheSkill': 'Delete the binding relationship between the agent and the skill queue in Configure Agent',
  'isales.agent.label.skillQueue': 'Skill queue:',
  'isales.agent.label.bindTheSkill': 'The called party configuration has been bound. Unbind it first',
  'isales.agent.label.deleteCtiFailed': 'Failed to delete the data',
  'isales.agent.message.skillConfFail': 'Failed to configure the skill queue',
  'isales.agent.message.selectSkills': 'Select a skill queue',
  'isales.agent.label.skills': 'Skill queue',
  'isales.agent.label.release': 'Release',
  'isales.agent.label.transfer': 'Transfer',
  'isales.agent.label.playVoice': 'Play waiting tone',
  'isales.agent.label.maxWaitNum': 'Max. Calls in Queue',
  'isales.agent.label.skillpara': 'Parameter Settings',
  'isales.agent.label.timeOut': 'Queuing Timeout',
  'isales.agent.label.transferType': 'Handling Mode',
  'isales.agent.Transfer.devicetype': 'Target Device Type',
  'isales.agent.label.ivrChoice': 'IVR',
  'isales.agent.label.deviceType': 'Device Type',
  'isales.agent.label.skillChoice': 'Skill Queue',
  'isales.agent.label.noAgents': 'Absence of On-duty Agent',
  'isales.agent.label.waitVoice': 'Waiting Tone',
  'isales.agent.label.parameterType': 'Queuing Mode',
  'isales.agent.label.answerMode': 'Agent Answering Mode',
  'isales.agent.label.busy': 'Busy Queue or Overflow',
  'isales.agent.label.answerType': 'Agent Answering Mode',
  'isales.agent.label.answerType0': 'Report agent ID',
  'isales.agent.label.answerType2': 'Voice not reporting agent ID',
  'isales.agent.message.name.limit': 'The skill queue name exceeds the maximum length',
  'isales.agent.calledroute.require': 'Set all mandatory fields',
  'isales.agent.message.createSkillConfSuccess': 'The skill queue is created successfully',
  'isales.agent.calledroute.gateway.fail': 'Failed to synchronize data to the CTI platform. Check the gateway configuration',
  'isales.skillcreate.maxnumber.failed': 'The number of tenant skill queues cannot exceed the upper limit',
  'isales.agent.message.createSkillConfFail': 'Failed to create the skill queue',
  'isales.agent.accesscode.description.limit': 'The access code description can contain a maximum of 50 characters',
  'isales.agent.message.modifySkillConfSuccess': 'The skill queue is modified successfully',
  'isales.agent.message.modifySkillConfFail': 'Failed to modify the skill queue',
  'isales.agent.label.maxWaitTimeSpan': 'The value ranges from 0 to 60000',
  'isales.agent.label.maxAgentWrapupTime': 'The value ranges from 0 to 3600',
  'isales.agent.contact.mediaType': 'Media Type',
  'isales.calledroute.title.selectivrflow': 'Choose an intelligent robot',
  'isales.management.message.SelectNumber': 'Select a number',
  'isales.taskmanagement.title.tip.batchEditTask': 'Select the task to be modified',
  'isales.taskmanagement.title.editCountOutOfBounds': 'A maximum of 30 outbound call tasks can be modified at a time',
  'isales.taskinfo.message.batchEditCallLogic.differentPolicyTypeExist': 'Outbound call logics cannot be modified in batches for the tasks with different policy types',
  'isales.management.message.batchEditCallLogic.notSupportBusiresult': 'Business result policies cannot be configured for automatic or intelligent outbound calls. Are you sure you want to perform the batch modification?',
  'isales.management.message.batchEditCallLogic.notSupportBusiresultInCMS': 'Business result policies cannot be configured for automatic outbound calls. Are you sure you want to perform the batch modification?',
  'isales.management.message.batchEditCallLogic.statusError': 'Outbound call logics can be modified in batches only for tasks in the draft\, executing\, or suspended state',
  'isales.tasklogic.message.confirmBatchUpdate': 'Batch modification applies only to selected regions. Are you sure you want to continue?',
  'isales.taskinfo.message.batchEditCallLogic.manualExist': 'Manual outbound call logics cannot be modified',
  'isales.taskinfo.message.batchEditCallLogic.selectEmpty': 'The operation is invalid because no region is selected. Select a region',
  'isales.taskinfo.message.batchEditCallLogic.differentCallModeExist': 'Outbound call logics cannot be modified in batches for the tasks with different call modes',
  'isales.taskinfo.message.batchEditCallLogic.differentRoundTypeExist': 'Outbound call logics cannot be modified in batches for the tasks with different call round modes',
  'isales.taskinfo.message.batchEditCallLogic.CallPolicyNotification': 'The existing rounds remain unchanged\, and new rounds will be added next to the existing ones',
  'isales.taskinfo.message.batchEditCallLogic.CallResultNotification': 'Existing policies can be modified\, and new policies will be added next to the existing ones',
  'isales.taskinfo.message.batchEditCallLogic.BusiResultNotification': 'New policies will overwrite the existing ones',
  'isales.taskcalllogic.message.typeConflictWhenDataTypeSame': 'A policy whose number type is the same as that of the existing policy exists\, but different policies are configured based on the result',
  'isales.taskcalllogic.message.callNoPolicyOutOfRangeAfetUpdate': 'After the number of non-default number policies in the outbound call result policy is changed\, the maximum of five is exceeded',
  'isales.taskcalllogic.message.roundPolicyLengthTooLong': 'After the number of rounds in the call policy is changed\, the maximum length is exceeded',
  'isales.taskcalllogic.message.dataTypeDefaultNoCreate': 'Default number type',
  'isales.taskcalllogic.message.callNoPolicyEmpty': 'Add at least one number policy',
  'isales.result.search.displayResult': 'Enter the Outbound Call failure cause',
  'isales.result.resultCode': 'Cause Code',
  'isales.result.displayResult': 'Outbound Call Failure Cause',
  'isales.result.view': 'View Outbound Call Failure Cause',
  'isales.result.edit': 'Modify Outbound Call Failure Cause',
  'isales.result.delete.error': 'Select an outbound call failure cause',
  'isales.result.deletetips': 'Are you sure you want to delete the outbound call failure cause?',
  'isales.result.create': 'Create Outbound Call Failure Cause',
  'isales.result.outgoingCallResult': 'Outbound Call Result',
  'isales.result.addOutgoingCallResult': 'Add Result',
  'isales.result.outgoingCall.failCode': 'Result Code',
  'isales.result.outgoingCall.Description': 'Description',
  'isales.result.addResultMaptips': 'A maximum of 50 outbound call results can be added',
  'isales.result.addAgentTips': 'A maximum of 20000 agents can be added',
  'isales.result.outgoingCall.input.description': 'Enter the Outbound Call result code or description',
  'isales.result.obResultIsNull': 'The outbound call result of the failure cause cannot be empty',
  'isales.calledroute.title.selectskill': 'Select Skill Queue',
  'isales.calledroute.title.selectagent': 'Select Agent',
  'isales.agent.placeholder.agentdesc': 'Enter an agent name',
  'isales.agent.label.workNo': 'Agent ID',
  'isales.agent.label.accountName': 'Agent Name',
  'isales.skillqueue.button.add': 'Add Skill Queue',
  'isales.specialday.message.enterworkno': 'Enter an agent ID',
  'isales.taskresult.field.sipAccount': 'Softphone Number',
  'isales.taskresult.field.agentRole': 'Agent Role Name',
  'isales.taskInfo.message.skillAlreadyExists': 'The skill queue already exists',
  'isales.agent.message.saveSuccess': 'The agent configuration is saved successfully',
  'isales.agent.field.skillWeight': 'Skill Weight',
  'isales.agent.label.agentWeight': 'Agent Weight',
  'isales.agent.message.chooseAnAgent': 'Select an agent',
  'isales.agent.message.batchSaveSuccess': 'The agents are modified in batches successfully',
  'isales.agent.message.batchSaveFail': 'Failed to modify the agents in batches',
  'isales.agent.message.configAgent': 'Configure or synchronize an agent',
  'isales.tasktemplate.title.saveAsTemplate': 'Save as Template',
  'isales.taskmanagement.title.createDirectly': 'Create Directly',
  'isales.taskmanagement.title.newFromTemplate': 'Create Using Template',
  'isales.taskmanagement.title.selectTemplate': 'Select Template',
  'isales.tasktemplate.title.inputTemplateName': 'Enter a template name',
  'isales.tasktemplate.title.addTemplate': 'Create Outbound Call Template',
  'isales.tasktemplate.title.editTemplate': 'Modify Outbound Call Template',
  'isales.tasktemplate.title.viewTemplate': 'View Outbound Call Template',
  'isales.tasktemplate.title.autoTemplate': 'Automatic Outbound Call Template',
  'isales.tasktemplate.title.manualTemplate': 'Manual Outbound Call Template',
  'isales.tasktemplate.title.addAutoTemplate': 'Create Automatic Outbound Call Template',
  'isales.tasktemplate.title.addManualTemplate': 'Create Manual Outbound Call Template',
  'isales.tasktemplate.field.templateName': 'Template Name',
  'isales.tasktemplate.field.release': 'Publish',
  'isales.tasktemplate.field.released': 'Published',
  'isales.tasktemplate.message.deleteConfirm': 'Are you sure you want to delete the selected outbound call template?',
  'isales.tasktemplate.message.releasesuccess': 'Publishing successful',
  'isales.tasktemplate.message.releasefailed': 'Publishing failed',
  'isales.tasktemplate.message.pauseFail': 'Suspension failed',
  'isales.tasktemplate.message.pauseSuccess': 'Suspension successful',
  'isales.tasktemplate.message.saveFail': 'Failed to configure the outbound call template',
  'isales.tasktemplate.message.saveSuccess': 'The outbound call template is configured successfully',
  'isales.tasktemplate.message.nameDuplicated': 'An outbound call template with the same name already exists',
  'isales.data.prop.propName': 'Attribute Name',
  'isales.data.prop.propCode': 'Attribute Code',
  'isales.data.prop.propType': 'Attribute Type',
  'isales.data.prop.isPushAgent': 'Push to Agent',
  'isales.data.prop.mandatory': 'Mandatory',
  'isales.data.prop.anonym': 'Anonymized',
  'isales.data.prop.status': 'Status',
  'isales.data.prop.stringType': 'String',
  'isales.data.prop.numberType': 'Digit',
  'isales.data.prop.anonymType': 'Encryption',
  'isales.data.prop.status.unpublished': 'Not published',
  'isales.data.prop.editDataProp': 'Modify Attribute',
  'isales.data.prop.createDataProp': 'Create Attribute',
  'isales.data.prop.inputPropName': 'Enter an attribute name',
  'isales.data.prop.inputPropCode': 'Enter an attribute code',
  'isales.data.prop.editDataPropSuccess': 'The attribute is modified successfully',
  'isales.data.prop.createDataPropSuccess': 'The attribute is created successfully',
  'isales.data.prop.editDataPropFail': 'Failed to modify the attribute',
  'isales.data.prop.createDataPropFail': 'Failed to create the attribute',
  'isales.data.prop.propParamsError': 'Failed to verify the attribute parameter',
  'isales.data.prop.propCodeExists': 'The attribute code already exists',
  'isales.data.prop.propNameExists': 'The attribute name already exists',
  'isales.data.prop.message.confirmDelete': 'Are you sure you want to delete the selected attribute?',
  'isales.data.prop.stringType.countError': 'A maximum of 30 character string-type attributes can be defined',
  'isales.data.prop.numberType.countError': 'A maximum of 10 digit-type attributes can be defined',
  'isales.data.prop.isPushAgent.countError': 'A maximum of 15 attributes can be defined to be pushed to an agent',
  'isales.data.prop.anonymType.countError': 'A maximum of 10 encryption-type attributes can be defined',
  'isales.data.prop.isPushAgent.anonymTypeError': 'The encryption-type attributes cannot be pushed to an agent',
  'isales.data.propConfig.button.configProp': 'Set Attributes',
  'isales.data.propConfig.title.isPushAgent': 'Push to Agent',
  'isales.data.propConfig.message.isUseProp': 'Enable custom attribute',
  'isales.data.propConfig.title.prop': 'Attribute',
  'isales.file.title.ip': 'IP Address',
  'isales.file.title.port': 'Port',
  'isales.file.title.username': 'Username',
  'isales.file.title.filepath': 'Default Path',
  'isales.file.title.password': 'Password',
  'isales.file.title.ip.placeholder': 'Enter the server IP address',
  'isales.file.title.port.placeholder': 'Enter the server port number',
  'isales.file.title.username.placeholder': 'Please enter user name',
  'isales.file.title.filepath.placeholder': 'Enter a file path\, e.g\, /home',
  'isales.file.title.password.placeholder': 'Please enter password',
  'isales.file.title.addserver': 'Add Server',
  'isales.file.title.server.info': 'The server information is modified successfully',
  'isales.file.title.server.fail': 'Failed to modify the server information',
  'isales.file.title.delete.fail': 'Failed to delete the server information',
  'isales.file.title.editserver': 'Modify Server',
  'isales.file.title.rightpath': 'Enter a valid path, for example, /isales/upload',
  'isales.file.title.rule.basic': 'Basic Information',
  'isales.file.title.rule.name': 'Rule Name',
  'isales.file.title.rule.desc': 'Description',
  'isales.file.title.addrule': 'Add Rule',
  'isales.file.title.editrule': 'Modify Rule',
  'isales.file.title.rule.name.placeHolder': 'Enter a rule name',
  'isales.file.title.rule.desc.placeHolder': 'Enter a rule description',
  'isales.file.title.rule.data': 'Number Data',
  'isales.file.title.rule.number': 'Number Column',
  'isales.file.title.rule.type': 'Type Column',
  'isales.file.title.rule.interval': 'Call Interval Column',
  'isales.file.title.rule.property': 'Service Attribute',
  'isales.file.title.rule.property.row': 'Attribute Column',
  'isales.file.title.rule.validator.row': 'Enter a sequence number from the A to EZ column',
  'isales.file.title.rule.validator.maxdata': 'A maximum of 40 number configuration records can be added',
  'isales.file.title.rule.validator.ruleId': 'The mapping rule does not exist',
  'isales.file.title.rule.validator.taskId': 'The task information does not exist',
  'isales.file.title.import.file.invalid': 'Failed to verify the file',
  'isales.file.title.rule.validator.prop': 'The user-defined attribute of the task is inconsistent with the attribute configured in the rule',
  'isales.file.title.rule.validator.server': 'The server configuration is incorrect',
  'isales.management.message.fileImport': 'Using file',
  'isales.management.message.serverImport': 'Using server',
  'isales.management.message.appendMode': 'Add',
  'isales.management.message.coverMode': 'Overwrite',
  'isales.management.message.templateAnalyse': 'By template',
  'isales.management.message.ruleAnalyse': 'By rule',
  'isales.management.message.importType': 'Import Method',
  'isales.management.message.importMode': 'Import Mode',
  'isales.management.message.import.server': 'Server',
  'isales.management.message.import.filePath': 'File Address',
  'isales.management.message.import.fileName': 'File Name',
  'isales.management.message.import.analyseMode': 'Parsing Mode',
  'isales.management.message.import.mapRule': 'Mapping Rule',
  'isales.data.title.timeImport': 'Scheduled Import',
  'isales.management.title.timeImport': 'Configure Scheduled Import',
  'isales.management.title.timeImport.execTime': 'Executed',
  'isales.timeImport.message.success': 'The scheduled import configuration is added successfully',
  'isales.timeImport.message.fail': 'Failed to add the scheduled import configuration',
  'isales.agent.field.curcountpassword': 'Current Password',
  'isales.agent.button.batchmodify': 'Batch Change',
  'isales.agent.button.restAgentPwd': 'Change Password',
  'isales.agent.title.agentEdit': 'Modify Agent Information',
  'isales.agent.field.newpassword': 'New Password',
  'isales.agent.field.newpasswordconf': 'Confirm Password',
  'isales.agent.message.passValidateFailed': 'The value must contain at least three types of the following characters: uppercase letters\, lowercase letters\, digits\, and special characters (`-!{\'@\'}#{\'$\'}^&*()-_+{\'|\'}[{\'{\'}{\'}\'}]<.>/?)',
  'isales.agent.message.passLengthValidateFailed': 'The password must contain 8 characters',
  'isales.agent.message.modifyPwdSuccess': 'The agent password is changed successfully',
  'isales.agent.message.syncPwdFailed': 'Failed to synchronize the agent password',
  'isales.agent.message.modifyPwdFailed': 'Failed to change the agent password',
  'isales.file.message.rule.dataIsNull': 'The number data cannot be empty',
  'isales.timeImport.message.rulePropsError': 'The service attribute of the scheduled import rule must be consistent with the attribute defined in the current task',
  'isales.import.message.selectServer.tips': 'Define the outbound call file server',
  'isales.import.message.selectRule.tips': 'Define the mapping rule to be imported',
  'isales.taskmanagement.title.speclistmanage.calledno': 'Outbound Number',
  'isales.specialday.title.speclistmanage.moveblacklists': 'Transfer to Blocklist',
  'isales.specialday.title.speclistmanage.misjudgment': 'Misjudge',
  'isales.specreason.field.emptynumber': 'The number is unallocated',
  'isales.specreason.field.systemtone': 'System prompt tone',
  'isales.specist.message.speclistmanage': 'Select the special list number to be operated',
  'isales.blacklist.message.confirmOpt': 'Are you sure you want to perform operations on the selected special list number?',
  'isales.specist.title.misjudgment': 'Misjudge',
  'isales.specist.message.updateFail': 'Modification failed',
  'isales.taskcalllogic.message.maxPolicyNo': 'A maximum of five non-default number types are allowed',
  'isales.import.message.upload.fail': 'Failed to upload the file',
  'isales.taskLogic.message.saveSuccess': 'The outbound call logic is modified successfully',
  'isales.taskLogic.message.saveFail': 'Failed to modify the outbound call logic',
  'isales.taskTime.message.saveSuccess': 'The outbound call time is changed successfully',
  'isales.taskTime.message.saveFail': 'Failed to change the outbound call time',
  'isales.tasklogic.field.callfilter': 'Call Filtering',
  'isales.tasklogic.field.addCondition': 'Add Condition',
  'isales.tasklogic.field.addSubCondition': 'Add Subcondition',
  'isales.tasklogic.field.logic': 'Operator',
  'isales.tasklogic.field.value': 'Value',
  'isales.tasklogic.field.selectProp': 'Add Attribute',
  'isales.management.title.selectProp': 'Select Attribute',
  'isales.data.prop.quantity': 'Quantity',
  'isales.tasklogic.field.and': 'And',
  'isales.tasklogic.field.or': 'Or',
  'isales.tasklogic.field.eq': '=',
  'isales.tasklogic.field.neq': '!=',
  'isales.tasklogic.field.gt': '>',
  'isales.tasklogic.field.egt': '>=',
  'isales.tasklogic.field.lt': '<',
  'isales.tasklogic.field.elt': '<=',
  'isales.tasklogic.field.like': 'Include',
  'isales.tasklogic.field.orderPolicy': 'Call Sorting',
  'isales.tasklogic.field.moveUp': 'Up',
  'isales.tasklogic.field.moveDown': 'Down',
  'isales.tasklogic.message.repeatProp': 'Duplicate attributes cannot be selected',
  'isales.tasklogic.label.primaryCondition': 'Main Condition',
  'isales.tasklogic.label.subCondition': 'Subcondition',
  'isales.agent.label.skillId': 'Skill Queue ID',
  'isales.management.title.editTime': 'Change Time',
  'isales.management.title.editLogic': 'Change Logic',
  'isales.agent.button.search': 'Query',
  'isales.agent.label.information': 'Information',
  'isales.agent.label.description': 'Description',
  'isales.agent.label.operate': 'Operation',
  'isales.agent.label.cti': 'cti',
  'isales.agent.message.warning': 'Warning',
  'isales.agent.button.edit': 'Edit',
  'isales.agent.button.createComplete': 'Finish',
  'isales.agent.button.cancel': 'Cancel',
  'isales.agent.pageurls.errorsave': 'Error',
  'isales.agent.pageurls.success': 'Success',
  'isales.agent.calledroute.fail': 'Failure',
  'isales.agent.label.ivr': 'IVR',
  'isales.skill.label.skillname': 'Skill Queue Name',
  'isales.skill.label.skilldesc': 'Description',
  'isales.skill.label.maxwaittime': 'Max. Queuing Time',
  'isales.skill.label.maxwaitnum': 'Max. Calls in Queue',
  'isales.skill.placeholder.skilldesc': 'Enter the skill queue name',
  'isales.calledroute.button.cancel': 'Cancel',
  'isales.calledroute.button.finish': 'Finish',
  'isales.file.title.create': 'Add',
  'isales.file.title.rule.index': 'Sequence Number',
  'isales.file.title.rule.property.name': 'Attribute Name',
  'isales.agent.label.ccRoleName': 'Role Name',
  'isales.taskstatistics.field.callstatistics': 'Call Statistics',
  'isales.taskstatistics.field.totalNumberOfUsers': 'Total Number Of Users',
  'isales.taskstatistics.field.numberOfConnectedCalls': 'Connected Calls',
  'isales.taskstatistics.field.numberOfUnconnectedCalls': 'Unconnected Calls',
  'isales.taskstatistics.field.touchRate': 'Touch Rate',
  'isales.taskstatistics.field.callCompletionRate': 'Call Completion Rate',
  'isales.taskstatistics.field.abandonRate': 'Abandon Rate',
  'isales.business.result.delete': 'Delete',
  'isales.business.result.search': 'Search',
  'isales.business.result.deleteChildrenTips': 'Are you sure you want to delete the sub-business result?',
  'isales.business.result.searchChildrenName': 'Enter a sub-business result name',
  'isales.taskmanagment.title.callMonitor': 'Call Monitoring',
  'isales.taskstatistics.field.callResultStatistics': 'Call Result Statistics',
  'isales.file.message.deletefiletips': 'Are you sure you want to delete the outbound file server?',
  'isales.file.message.deleteruletips': 'Are you sure you want to delete the import mapping rule?',
  'isales.taskmanagment.callquality.maxConnectedRate': 'Maximum connection rate',
  'isales.taskmanagment.callquality.minConnectedRate': 'Minimum connection rate',
  'isales.work.title.serviceAccount': 'Service Account',
  'isales.work.title.surveyAnswerNum': 'Number of Answers',
  'isales.work.title.reservedCalls': 'Reserved Calls',
  'isales.work.title.totalOutgoingCalls': 'Outbound Calls',
  'isales.work.title.successNum': 'Successful Outbound Calls',
  'isales.work.title.completeNum': 'Completed Surveys',
  'isales.work.title.calledNum': 'Processed Calls',
  'isales.work.title.callTimeTotal': 'Total Call Duration (s)',
  'isales.work.title.callTimeAvg': 'Average Call Duration (s)',
  'isales.work.title.busiSuccess': 'Successful Business',
  'isales.work.title.busiFail': 'Failed Business',
  'isales.work.title.outboundCallTask': 'Outbound Call Task',
  'isales.work.title.multimediaManage': 'Marketing multimédia Management',
  'isales.work.message.dategt7days': 'The time range cannot exceed seven days',
  'isales.work.message.agentOrTaskChange': 'Select at least one call task and agent',
  'isales.import.message.closeExtProp': 'Custom attributes must be disabled for importing by template. Click the Configure Attribute button next to Import to disable and enable custom attributes',
  'isales.file.message.endPoint.placeholder': 'Enter an address',
  'isales.file.title.bucket': 'Bucket',
  'isales.file.message.bucket.placeholder': 'Enter a bucket',
  'isales.file.title.AK': 'AK',
  'isales.file.title.SK': 'SK',
  'isales.file.message.AK.placeholder': 'Enter an AK',
  'isales.file.message.SK.placeholder': 'Enter an SK',
  'isales.file.title.serverInformation': 'Server Information',
  'isales.file.title.userNameOrAK': 'Username/AK',
  'isales.file.message.isDeleteFile': 'Delete Source File',
  'isales.data.message.preview.addNumberUpperLimit': 'A maximum of {count} numbers can be added for previewed outbound calls',
  'isales.management.title.adjustment': 'Adjust',
  'isales.agent.query.error': 'The target agent cannot be the same as the executor of the selected record',
  'isales.management.title.adjustment.log': 'Adjustment Record',
  'isales.management.title.adjustment.changeTime': 'Adjusted On',
  'isales.management.title.adjustment.orgAccountName': 'Old Executor',
  'isales.management.title.adjustment.destAccountName': 'New Executor',
  'isales.management.title.adjustment.modifier': 'Adjusted By',
  'isales.management.title.adjustment.remark': 'Remarks',
  'isales.appointment.message.adjustmentSuccess': 'The reserved outbound call is adjusted successfully',
  'isales.appointment.message.adjustmentFail': 'Failed to adjust the reserved outbound call',
  'isales.appointment.message.adjustmentEmpty': 'No valid reserved outbound call data is selected',
  'isales.tasksample.message.agentEmpty': 'No training object is selected',
  'isales.work.title.selectTask': 'Select Outbound Call Task',
  'isales.import.button.configProp': 'Configure Filter Rule',
  'isales.import.filter.button.lineRepeat': 'Filter out duplicate data in the row',
  'isales.import.filter.button.noRepeat': 'Filter out duplicate data in the batch',
  'isales.import.filter.button.recentCall': 'Filter out data of calls completed lately',
  'isales.import.filter.button.callResult': 'Filter out data of business results',
  'isales.import.filter.button.readyCall': 'Filter out data of calls not initiated',
  'isales.import.filter.button.calling': 'Filter out data of uncompleted calls',
  'isales.import.filter.title.popup': 'Filter Rules',
  'isales.import.filter.message.selectRuleTips': 'Select at least one filter rule',
  'isales.taskmanagment.title.callMonitor.tips': 'Automatically Updated Every 15 Minutes',
  'isales.taskmanagement.field.basicTaskInfo': 'Basic Information',
  'isales.taskmanagement.field.pickUpRule': 'Automatic Extraction Rule',
  'isales.taskmanagement.field.isAutoPickUp': 'Enable Automatic Extraction Rule',
  'isales.taskmanagement.field.pickUpRuleLimit': 'Restrictions',
  'isales.taskmanagement.field.pickUpRuleBusiResult': 'Business Result',
  'isales.taskmanagement.field.quotas': 'Upper Limit',
  'isales.taskmanagement.field.callCompletedNum': 'Completed Calls',
  'isales.taskmanagement.field.pickUpNum': 'Extracted Calls Each Time',
  'isales.taskmanagement.field.pickUpCondition': 'Conditions',
  'isales.manualdata.title.pickUp': 'Extract',
  'isales.manualData.message.pickUpSuccess': 'Extraction successful',
  'isales.manualData.message.reachQuatos': 'No more outbound call data can be extracted because the number of calls in the task has reached the extraction upper limit',
  'isales.manualData.message.reachMax': 'No more outbound call data can be extracted because the maximum number of calls has been reached in the task',
  'isales.manualData.message.pickUpFailed': 'No data to extract',
  'isales.taskstatistics.message.no.busiResult': 'No business result is marked',
  'isales.appointmanagement.field.remark': 'Remarks',
  'isales.appointmanagement.title.inputRemark': 'Enter remarks',
  'isales.agent.query.emptyError': 'No valid agent data is selected',
  'isales.statistics.field.touchRateDescription': 'Reach Rate',
  'isales.statistics.field.callCompletionRateDescription': 'Connection Rate',
  'isales.statistics.field.abandonmentRateDescription': 'Abandon Rate',
  'isales.taskstatistics.message.Unit.Piece': 'Unit: number',
  'isales.skillqueue.agentrole.agent': 'Agent',
  'isales.statistics.field.addHisDetail': 'No: Data of {0} months is collected. Yes: Data of {1} months is collected',
  'isales.import.filter.message.busiResultIsNull': 'The business result cannot be empty',
  'isales.import.filter.message.busiSubStatusSame': 'Sub-business results under the same business result cannot be the same',
  'isales.file.server.message.checkObsPath': 'Only digits\, letters\, hyphens (-)\, and periods (.) are allowed',
  'isales.file.server.message.notChinese': 'Chinese characters are not allowed',
  'isales.import.filter.title.callDay': 'Recent Activity (Days)',
  'isales.manual.title.sendEmail': 'Send Email',
  'isales.manual.button.send': 'Send',
  'isales.manual.title.recipient': 'Recipient',
  'isales.manual.title.title': 'Title',
  'isales.file.message.isDeleteFileTips': 'Delete files from the OBS',
  'isales.file.message.rule.success': 'The rule is modified successfully',
  'isales.file.message.rule.fail': 'Failed to modify the rule',
  'isales.file.message.rule.columnRepeat': 'The outbound call ID column\, number column\, category column\, call interval column\, and attribute column cannot be the same',
  'isales.manual.message.recipient': 'Enter a recipient',
  'isales.manual.message.title': 'Enter a title',
  'isales.operlog.message.downloadAgentWorkExportFile': 'Download agent workload export file',
  'isales.operlog.message.downloadSurveyExportFile': 'Exporting Training Questions',
  'isales.taskinfo.field.chooseOrInputCallNo': 'You can enter or select the callNo at the same time',
  'isales.agent.message.curcountpasswordWrong': 'The current account password is incorrect',
  'isales.taskinfo.callno.repeat': 'Caller Number is Repeat',
  'isales.file.title.password.again': 'Confirm Password',
  'isales.file.title.password.same': 'The two passwords are different',
  'isales.file.title.sk.same': 'The two SKs are different',
  'isales.file.title.SK.again': 'Confirm SK',
  'isales.import.message.template.dataNum': 'A maximum of {0} data records can be imported at a time',
  'isales.import.message.allow.task.statue': ',A task can be imported only when it is in the Draft or Paused state',
  'isales.taskstatistics.field.callRatePie': 'Call completion rate',
  'isales.taskstatistics.field.connectedCallsPie': 'Number of connected calls',
  'isales.taskstatistics.field.Completed': 'Customers Completed Calls',
  'isales.taskstatistics.field.inProgress': 'Customers Calling',
  'isales.taskstatistics.field.incomplete': 'Customers Waiting for Calls',
  'isales.taskresultexport.message.downloadFilenopemission': 'You have no permission to export files',
  'isales.callrecordexport.message.downloadFilenopemission': 'You do not have the right to export intelligent outbound call communication records',
  'isales.taskmanagment.title.callquality.viewstatistics': 'Call Quality Statistics',
  'isales.taskmanagment.message.chartDataError': 'Failed to load the chart',
  'isales.taskmanagment.title.open.agentMonitor': 'Agent Monitoring',
  'isales.taskmanagment.title.open.callMonitor': 'Call Monitoring',
  'isales.callquality.statistics.message.afterNow': 'You cannot select a time earlier than the task start time or later than the current day!',
  'isales.task.promotion.tempalteError': 'The marketing notification template does not match the template type',
  'isales.task.promotion.gatewwayError': 'The gateway configuration does not match the media type',
  'isales.task.promotion.baseInfo': 'Basic Information',
  'isales.appointment.title.timeout': 'The reservation time has passed',
  'isales.appointment.title.soontimeout': 'The reservation time is about to arrive',
  'isales.file.message.rule.receiver.columnRepeat': 'The recipient column cannot be the same as the attribute column',
  'isales.taskinfo.field.mutiCallNo': 'Enter the calling number',
  'isales.taskinfo.callno.length': 'The length of a single calling number exceeds 32 characters',
  'isales.workbench.execution': 'Outbound Call Execution',
  'isales.workbench.task.management': 'Outbound Call Management',
  'isales.workbench.appointment.task': 'Reservation Task',
  'isales.workbench.number.execution': 'Execute',
  'isales.notification.receiver.address': 'Recipient Address',
  'isales.notification.receiver.number': 'Recipient Number',
  'isales.notification.receiver.address.placeholder': 'Enter the recipient address',
  'isales.notification.receiver.number.placeholder': 'Enter the recipient number',
  'isales.notification.configVariable': 'Set Variable',
  'isales.notification.receiver.tips': 'The recipient must be unique',
  'isales.marketing.mode': 'Marketing Mode',
  'isales.marketing.OutgoingCall': 'Telemarketing',
  'isales.marketing.notification': 'Multimedia marketing',
  'isales.notification.receiver': 'Recipient',
  'isales.notification.receiver.info': 'Recipient Information',
  'isales.import.message.errMsg': 'Exception Cause',
  'isales.notification.variable.name': 'Variable Name',
  'isales.notification.propo.name': 'Attribute Name',
  'isales.notification.variable.value': 'Variable Value',
  'isales.notification.import.button': 'Import Data',
  'isales.task.promotion.pauseSuccess': 'The multimedia marketing task is suspended successfully',
  'isales.task.promotion.pauseFaied': 'Failed to suspend the multimedia marketing task',
  'isales.task.promotion.startSuccess': 'The multimedia marketing task is published successfully',
  'isales.task.promotion.startFailed': 'Failed to publish the multimedia marketing task',
  'isales.task.promotion.isExp': 'The multimedia marketing task has expired',
  'isales.task.promotion.saveSuccess': 'The multimedia marketing task is saved successfully',
  'isales.task.promotion.nameRepeat': 'The multimedia marketing task name already exists',
  'isales.task.promotion.saveFailed': 'Failed to save the multimedia marketing task',
  'isales.task.promotion.deleteSuccess': 'The multimedia marketing task is deleted successfully',
  'isales.task.promotion.statusStart': 'The multimedia marketing task is running',
  'isales.task.promotion.deleteFailed': 'Failed to delete the multimedia marketing task',
  'isales.task.promotion.updateSuccess': 'The multimedia marketing task is modified successfully',
  'isales.task.promotion.updateFailed': 'Failed to modify the multimedia marketing task',
  'isales.task.promotion.messageTemplate': 'Notification Template',
  'isales.task.promotion.promotionName': 'Marketing Task',
  'isales.task.promotion.inputPromotionName': 'Please enter a marketing name',
  'isales.task.promotion.basicInfo': 'Basic Information',
  'isales.task.promotion.promotionType': 'Media Type',
  'isales.task.promotion.promotionEffTime': 'Effective Time',
  'isales.task.promotion.promotionExpTime': 'Expiration Time',
  'isales.task.promotion.promotionPolicy': 'Marketing Policy',
  'isales.task.promotion.templateType': 'Template Type',
  'isales.task.promotion.sendTemplate': 'Notification Template',
  'isales.task.promotion.selectSendTemplate': 'Select a template',
  'isales.task.promotion.prefix': 'Country Code',
  'isales.task.promotion.sendGateway': 'Gateway',
  'isales.task.promotion.selectSendGateway': 'Select a gateway',
  'isales.task.promotion.sender': 'Sender',
  'isales.task.promotion.sendLimit': 'Daily Sending Limit',
  'isales.task.promotion.isRetry': 'Retry upon Failure',
  'isales.task.promotion.retryTimes': 'Retry Times',
  'isales.task.promotion.isSendNow': 'Send Immediately',
  'isales.task.promotion.expectedTime': 'Expected Sending Time',
  'isales.task.promotion.dndTime': 'Do-Not-Disturb Period',
  'isales.task.promotion.sms': 'SMS',
  'isales.task.promotion.email': 'Email',
  'isales.task.promotion.hwsms': 'HUAWEI CLOUD SMS',
  'isales.task.promotion.smsgw': 'SMS gateway',
  'isales.task.promotion.promotionStatus': 'Marketing Status',
  'isales.task.promotion.tobesent': 'Messages to Be Sent',
  'isales.task.promotion.invalidation': 'Invalid',
  'isales.task.promotion.all': 'All',
  'isales.task.promotion.create': 'Create Multimedia Marketing Task',
  'isales.task.promotion.edit': 'Modify Multimedia Marketing Task',
  'isales.task.promotion.view': 'View Multimedia Marketing Task',
  'isales.task.promotion.message.variableIsNull': 'The attribute configuration list is empty',
  'isales.task.promotion.message.variableNotConfig': 'Some variables are not configured',
  'isales.task.promotion.message.limitGtTenantLimit': 'The daily sending limit exceeds the maximum allowed for the tenant',
  'isales.task.promotion.message.dndEndTimeltBeginTime': 'The end of the do-not-disturb period is earlier than the start',
  'isales.task.promotion.message.dndTimeltCrossExpectedTime': 'The expected sending time cannot be in the do-not-disturb period',
  'isales.notification.viewResult.button': 'View Marketing Result',
  'isales.task.promotion.message.dndTimeError': 'The do-not-disturb period is incomplete',
  'isales.task.promotion.message.selectPromotionType': 'Select a media type',
  'isales.import.filter.button.notification.noisPush': 'Filter out data not pushed',
  'isales.import.filter.button.notification.isPushCom': 'Filter out data being pushed',
  'isales.import.filter.title.notification.isPushDay': 'Pushed Days',
  'isales.notification.configVariableRel': 'Configure Relationship Between Variable and Attribute',
  'isales.notification.configVariableValue': 'Set Variable Value',
  'isales.notification.prop.tips': 'The attribute must be unique',
  'isales.notification.receiver.delete.error': 'No data is selected',
  'isales.notification.receiver.delete.variable': 'Configure the attribute',
  'isales.import.filter.button.notification.isPush': 'Filter out data pushed lately',
  'isales.task.promotion.templateCode': 'Template Code',
  'isales.task.promotion.gateway': 'Sending Gateway',
  'isales.task.promotion.sendStatus': 'Sending Status',
  'isales.task.promotion.beginTime': 'Start Time',
  'isales.task.promotion.endTime': 'End Time',
  'isales.task.promotion.receiver': 'Recipient',
  'isales.task.promotion.sendTime': 'Sent On',
  'isales.sendStatus.field.prepare': 'Preparing',
  'isales.sendStatus.field.sending': 'Sending',
  'isales.sendStatus.field.success': 'Successful',
  'isales.sendStatus.field.fail': 'Failed',
  'isales.sendStatus.field.all': 'All',
  'isales.task.promotion.message.selectTemplateType': 'Select a template type',
  'isales.task.promotion.remark': 'Failure Cause',
  'isales.task.promotion.createTime': 'Created On',
  'isales.task.promotion.message.monthGtThree': 'Only the results of three months can be queried',
  'isales.notification.configVariableTe': 'Configure Template Variable',
  'isales.notification.config': 'Configuration',
  'isales.notification.receiver.number.tips': 'The value must consist of digits',
  'isales.notification.variable.defvalue': 'Default Value',
  'isales.task.promotion.message.noprop': 'Add an attribute using the Outbound Call Data Attribute Definition menu',
  'isales.task.promotion.message.variableSelected': 'Select an attribute',
  'isales.notification.receiver.deletetips': 'Are you sure you want to delete the selected data?',
  'isales.management.message.notification.download': 'Download Marketing Data Template',
  'isales.management.title.notification.import': 'Import Marketing Data',
  'isales.taskpolicy.message.obOverTime': 'Enter the preview timeout duration first',
  'isales.taskpolicy.message.rangeAutoCallTime': 'The value must range from {0} to {1}',
  'isales.taskpolicy.message.obOverTimeRange': 'The preview timeout duration is out of range: 9 to 120. Please change the preview timeout duration first',
  'isales.taskmanagement.message.beforeNow': 'The start time cannot be earlier than the current time',
  'isales.operation.success': 'Operation succeeded',
  'isales.operation.training.success': 'Operation succeeded. The training takes about several hours. Log in to Lodas to view the training progress',
  'isales.operation.failed': 'Operation failed',
  'isales.taskmanagement.field.taskId': 'Task ID',
  'isales.taskmanagement.title.inputTaskId': 'Enter a task ID',
  'isales.taskinfo.field.callBackUrl': 'Callback Address',
  'isales.taskinfo.message.callBackUrl': 'Enter a callback address',
  'isales.tasktype.field.preemption': 'Preempted Outbound Call',
  'isales.taskmanagement.title.toBeCallCount': 'Pending Calls',
  'isales.manual.field.canBeCallCount': 'Available Calls',
  'isales.manual.field.noDataCanbeCallTips': 'Currently, no calls can be made. Check whether the attribute configuration meets the filter criteria',
  'isales.tasklogic.field.callOrderType.noOrder': 'Unordered call',
  'isales.tasklogic.field.callOrderType.isOrder': 'Ordered call',
  'isales.tasklogic.field.callOrderMethod': 'Order Rule',
  'isales.tasklogic.field.callOrderMethod.byOrderNo': 'By No',
  'isales.tasklogic.field.callOrderMethod.bySelf': 'By custom rule',
  'isales.tasklogic.message.need.orderPolicy': 'Add at least one custom order rule',
  'isales.taskmanagement.title.clicktorefresh': 'Click to Refresh',
  'isales.audio.content.calltype': 'Voice outbound call',
  'isales.audio.message.errortip1': 'Failed to query the recording information. Contact the administrator',
  'isales.audio.title.audiorate': 'Playback Rate',
  'isales.audio.title.callId': 'Recording SN',
  'isales.audio.title.audiotime': 'Duration(HH:mm:ss)',
  'isales.audio.title.starttime': 'Start Time',
  'isales.audio.title.endtime': 'End Time',
  'isales.audio.title.calltype': 'Call Type',
  'isales.audio.title.recording': 'Preview Recording',
  'isales.datasourcemanagement.field.vdnId': 'VDNID',
  'isales.datasourcemanagement.title.inputVdnId': 'Enter a VDNID',
  'isales.datasourcemanagement.field.dbId': 'DataSource Id',
  'isales.datasourcemanagement.title.inputDbId': 'Enter Datasource Id',
  'isales.datasourcemanagement.field.dbschema': 'DataSource Name',
  'isales.datasourcemanagement.title.inputDbschema': 'Enter DataSource Name',
  'isales.datasourcemanagement.field.dbType': 'DataSource Type',
  'isales.datasourcemanagement.field.dbdriver': 'DataSource Driver',
  'isales.datasourcemanagement.field.dsType.all': 'All',
  'isales.datasourcemanagement.field.dsType.mysql': 'MYSQL',
  'isales.datasourcemanagement.field.dsType.oracle': 'ORACLE',
  'isales.datasourcemanagement.field.dsType.gauss': 'GAUSS',
  'isales.datasourcemanagement.field.dsType.dm': 'DM',
  'isales.datasourcemanagement.field.add': 'Add DataSource',
  'isales.datasourcemanagement.field.sync': 'Sync DataSource',
  'isales.datasourcemanagement.field.backDbdriver': 'BackUp DataSource Name',
  'isales.datasourcemanagement.field.addDataSource': 'Add DataSource',
  'isales.datasourcemanagement.field.dbIp': 'Data source IP address or alias',
  'isales.datasourcemanagement.field.dbPort': 'DataSource Port',
  'isales.datasourcemanagement.field.dbUserName': 'DataSource Name',
  'isales.datasourcemanagement.field.dbPass': 'DataSource PassWord',
  'isales.datasourcemanagement.field.dbPass2': 'Confirm DataSource PassWord',
  'isales.datasourcemanagement.field.dbCnUrl': 'DataSource url',
  'isales.data.propConfig.message.isExistBackDb': 'Back DataSource',
  'isales.datasourcemanagement.field.backdbId': 'Back DataSource Id',
  'isales.datasourcemanagement.field.backdbUserName': 'Back DataSource UserName',
  'isales.datasourcemanagement.field.backdbType': 'Back DataSource Type',
  'isales.datasourcemanagement.field.backdbIp': 'Standby database IP or alias',
  'isales.datasourcemanagement.field.backdbport': 'Back DataSource Port',
  'isales.datasourcemanagement.field.backDbCnUrl': 'Back DataSource url',
  'isales.datasourcemanagement.field.backdbpass': 'Back DataSource PassWord',
  'isales.datasourcemanagement.field.backdbpass2': 'Confirm Back DataSource PassWord',
  'isales.datasourcemanagement.field.backdbSchema': 'Back DataSource Schema',
  'isales.datasourcemanagement.field.backdbdriver': 'Back DataSource Driver',
  'isales.datasourcemanagement.field.editDataSource': 'Edit DataSource',
  'isales.datasourcemanagement.message.dbIp': 'The format of the database IP address is incorrect',
  'isales.datasourcemanagement.message.dbpass': 'Please enter the same password',
  'isales.datasourcemanagement.message.addDataSourceFail': 'Create DataSource Failed',
  'isales.datasourcemanagement.message.addDataSourceSuccess': 'Create DataSource Success',
  'isales.datasourcemanagement.message.alreadyExistDs': 'The dataSource of VDN already exists',
  'isales.datasourcemanagement.message.confirDelete': 'Deleting the dataSource is a high-risk operation. Exercise caution when performing this operation',
  'isales.datasourcemanagement.message.deleteDataSourceFail': 'Delete DataSource Failed',
  'isales.datasourcemanagement.message.deleteDataSourceSuccess': 'Delete DataSource Success',
  'isales.datasourcemanagement.field.viewDataSource': 'View DataSource',
  'isales.datasourcemanagement.message.frequentOperation': 'Frequent operations. Please wait',
  'isales.datasourcemanagement.message.noObsINFO': 'The outbound call DataSource is not configured or incorrectly configured',
  'isales.datasourcemanagement.message.passValidateFailed': 'The value must contain at least three types of the following characters: uppercase letters\, lowercase letters\, digits\, and special characters (`-!{\'@\'}#{\'$\'}^&*()-_+{\'|\'}[{\'{\'}{\'}\'}]<.>/?). ',
  'isales.datasourcemanagement.message.syncSuccess': 'sync DataSource Success',
  'isales.tasklogic.field.noRetryLogic': 'Add non-retry result code',
  'isales.management.title.selectResultCode': 'Select Result Code',
  'isales.management.title.setResultCode': 'Please set the result code',
  'isales.manualData.message.noPickCondition': 'Please Set Conditions',
  'isales.management.message.addPropTips': 'Personal data or sensitive data must be encrypted, stored, and masked',
  'isales.enum.workSpace': 'Agent Outbound Call Task ',
  'isales.management.title.black.import': 'Import Blocklist',
  'isales.black.filed.number': 'Number (mandatory)',
  'isales.black.filed.expTime': 'Validity duration (optional, in hours)',
  'isales.black.filed.remark': 'Remarks (optional)',
  'isales.survey.statistic': 'Respondent Statistics',
  'isales.survey.querydetail': 'Survey Result',
  'isales.manual.task.survey.questiontotal': 'Total {0} questions',
  'isales.manual.task.survey.unanswered': 'Unanswered',
  'isales.manual.task.survey.answered': 'Answered',
  'isales.manual.task.survey.currentprogress': 'Current progress',
  'isales.manual.task.survey.previousquestion': 'Previous question',
  'isales.manual.task.survey.nextquestion': 'Next question',
  'isales.manual.task.survey.submit': 'Submit',
  'isales.taskinfo.field.survey': 'Questionnaire',
  'isales.manual.task.survey.noAnswer': 'No answer required',
  'isales.manual.task.survey.answerSuccess': 'Answered successfully!',
  'isales.agent.calledroute.search.devicedesc': 'Please enter a device name',
  'isales.agent.calledroute.create': 'Add',
  'isales.agent.calledroute.device': 'Please select a device',
  'isales.agent.calledroute.devicetype': 'Equipment type',
  'isales.agent.calledroute.extcode': 'spreading code',
  'isales.agent.calledroute.ivr': 'Please select IVR',
  'isales.agent.calledroute.placeholder.ivr': 'Please select IVR',
  'isales.agent.calledroute.skill': 'skill queue',
  'isales.agent.calledroute.placeholder.skill': 'Please select a skill queue',
  'isales.agent.calledroute.accesscode': 'access code',
  'isales.agent.calledroute.placeholder.accesscode': 'Please select an access code',
  'isales.agent.calledroute.placeholder.extcode': 'Please enter extension code',
  'isales.calledroute.title.selectaccesscode': 'Choose an access code',
  'isales.agent.calledroute.extcodewarn': 'Extension codes can only contain numbers',
  'isales.agent.calledroute.extlengthcodewarn': 'The extension code length cannot exceed 12',
  'isales.agent.calledroute.editsuccess': 'Edited successfully',
  'isales.agent.calledroute.exception': 'Please check Redis status or CC-Gateway configuration',
  'isales.agent.calledroute.editfail': 'Local data editing failed',
  'isales.agent.calledroute.accesscodewarn': 'Access codes can only contain numbers',
  'isales.agent.calledroute.createsuccess': 'added successfully',
  'isales.agent.calledroute.createfail': 'Failed to add local data',
  'isales.agent.calledroute.create.accesscode.none': 'The system access code configured by the called party is not assigned',
  'isales.agent.calledroute.create.accesscode.repeat': 'The combination of system access code and extension code configured by the called party cannot be repeated',
  'isales.agent.calledroute.create.accesscode.noexist': 'The access code referenced by the called configuration does not exist',
  'isales.agent.calledroute.create.skill.none': 'The skill queue referenced by the called configuration does not exist',
  'isales.agent.calledroute.create.ivr.limit': 'The device type is IVR, and the media type of the access code can only be click-to-call, callback request, or normal call',
  'isales.agent.calledroute.create.gateway.none': 'The routing script referenced by the called configuration does not exist',
  'isales.agent.calledroute.create.media.match': 'The system access code configured by the called party is inconsistent with the media type of the device configured by the called party',
  'isales.agent.calledroute.recordexist': 'The access code and extension code are already bound, please enter a new extension code',
  'isales.agent.calledroute.nmsdata.error': 'The synchronization of the ODFS fails, and the configuration data of the ODFS called party is inconsistent with the local data of the CCM',
  'isales.agent.calledroute.ccivrdata.error': 'Failed to synchronize ODFS, CC-IVR process data is wrong',
  'isales.agent.calledroute.nmsconnect.fail': 'Failed to connect to ODFS',
  'isales.management.placeholder.accesscodeDesc': 'Please enter an access code description',
  'isales.taskinfo.title.accesscode': 'access code',
  'isales.taskinfo.title.accesscodedesc': 'Access code description',
  'isales.agent.contact.voiceVidio': 'Audio and video',
  'isales.agent.contact.clicktodial': 'click to call',
  'isales.note.config.success': 'success',
  'isales.agent.calledroute.refreshsuccess': 'Refresh succeeded',
  'isales.agent.calledroute.updatecalledroute': 'edit',
  'isales.agent.calledroute.confdelete': 'delete confirmation',
  'isales.agent.calledroute.isconfdelete': 'Are you sure to delete?',
  'isales.agent.calledroute.success': 'success',
  'isales.agent.calledroute.delsuccess': 'successfully deleted',
  'isales.agent.message.redislocknull': 'Failed to acquire Redis lock',
  'isales.calledroute.message.selectcalledroute': 'Please select the called configuration',
  'isales.agent.calledroute.delfail': 'Local data deletion failed',
  'isales.label.appraise.call.quality.agent': 'agent',
  'isales.label.appraise.call.quality.customer': 'client',
  'isales.label.appraise.call.quality.customerInfo': 'Customer Information',
  'isales.data.message.empty': 'Called number cannot be empty!',
  'isales.export.message.noSelectData': 'Please select the data to be exported',
  'isales.export.message.noSelectTrainingData': 'Please select the data to be trained',
  'isales.export.message.selectToomach': 'A maximum of 10 records can be selected',
  'isales.training.scoreDetails': 'Score Details',
  'isales.training.startTraining': 'Start training',
  'isales.training.queryQuestionnaire': 'Questionnaire Query',
  'isales.training.Questionnaire': 'Training Questionnaire',
  'isales.training.MaxAnswerNumber': 'Maximum Number of Questionnaire Answers',
  'isales.training.forwardNumber': 'Forwarding Number',
  'isales.training.AssociatedSurvey': 'Associated Questionnaire',
  'isales.training.resultAnalysis': 'Result analysis',
  'isales.training.surveyDetail': 'Questionnaire Details',
  'isales.training.questionNumber': 'Number of questions',
  'isales.training.surveyScore': 'Total survey Score',
  'isales.training.callTime': 'Call time segment',
  'isales.training.callTimeSegment': 'Call time segment',
  'isales.training.specialday.callTime': 'Special Date Call Time Segment',
  'isales.training.startTraining.exists': 'Training in progress. Please try again after the training is complete',
  'isales.taskstatistics.field.feedBackStatistics': 'Feedback Statistics',
  'isales.task.message.feedBackRate': 'Feedback Rate',
  'isales.task.message.numberEffFeedback': 'Number of valid feedback calls',
  'isales.task.message.numberInvalFeedback': 'Number of invalid feedback calls',
  'isales.survey.details': 'Questionnaire Details',
  'isales.survey.createTrainingTask': 'Creating a Training Task',
  'isales.taskstatistics.field.specialCall': 'Special List',
  'isales.taskmanagment.title.open.obsMonitor': 'OBS Data Monitoring',
  'isales.training.taskdetails': 'Task Details',
  'isales.training.sampleTotal': 'Total sample size',
  'isales.training.completeSamples': 'Completed Sample Size',
  'isales.training.remainingSamples': 'Remaining Quantity',
  'isales.training.trainingRate': 'Training rate',
  'isales.training.surveydetails': 'Questionnaire Statistics Details',
  'isales.training.averageScore': 'Average Score',
  'isales.training.voiceAverageScore': 'Average Voice Score',
  'isales.training.operationAveragecore': 'Average Operation Score',
  'isales.training.questionScoreDetails': 'Issue Score Details',
  'isales.training.answerNumber': 'Number of Answers',
  'isales.training.fullScoreNumber': 'Number of Full Scores',
  'isales.training.averageDuration': 'Average Duration',
  'isales.training.tip1': 'The number of answers has reached the upper limit',
  'isales.training.answeringRecord': 'Answering Record',
  'isales.training.tip2': 'The question is being trained. Do not click frequently',
  'isales.management.message.black.download': 'Downloading a Blocklist Template',
  'isales.taskmanagment.title.obs.viewstatistics': 'Monitoring Data Obtained by OBS',
  'isales.task.statistics.obs.startTime': 'The start time cannot be earlier than seven days ago',
  'isales.task.statistics.obs.endTime': 'The difference between the start time and end time cannot exceed half an hour',
  'isales.warning.data.export': 'The exported data contains personal information. Exercise caution when performing this operation',
  'isales.statistics.business.taskId': 'taskId',
  'isales.statistics.business.reportType': 'Report Type',
  'isales.statistics.business.beginDate': 'Start Date',
  'isales.statistics.business.endDate': 'End Date',
  'isales.statistics.business.startYear': 'Start Year',
  'isales.statistics.business.startWeek': 'Start Week',
  'isales.statistics.business.startMonth': 'Start Month',
  'isales.statistics.business.endYear': 'End Year',
  'isales.statistics.business.endWeek': 'End Week',
  'isales.statistics.business.endMonth': 'End Month',
  'isales.statistics.business.search': 'Search',
  'isales.statistics.business.reset': 'Reset',
  'isales.statistics.business.date': 'Date',
  'isales.statistics.business.week': 'Week',
  'isales.statistics.business.month': 'Month',
  'isales.statistics.business.byDay': 'Daily report',
  'isales.statistics.business.byWeek': 'Weekly report',
  'isales.statistics.business.byMonth': 'Monthly report',
  'isales.statistics.business.title': 'Outbound Call Business Report',
  'isales.statistics.business.trend.title': 'Outbound Call Business Trend Report',
  'isales.statistics.business.nomorethanerror': 'The selected date cannot be earlier than {0}',
  'isales.statistics.business.currentDateCheck': 'The selected date must be earlier than the current day',
  'isales.statistics.business.dategt31days': 'The date range cannot exceed 31 days',
  'isales.statistics.business.dategt12weeks': 'The week range cannot exceed 12 weeks',
  'isales.statistics.business.dategt6month': 'The month range cannot exceed 6 months',
  'isales.statistics.business.weekLessThan': 'The end year week cannot be less than the start year week',
  'isales.statistics.business.monthLessThan': 'The end year month cannot be earlier than the start year month',
  'isales.statistics.business.yearLessThan': 'The end year cannot be earlier than the start year',
  'isales.statistics.business.noData': 'No data available',
  'isales.statistics.business.noMoreThanEight': 'The number of selected items cannot exceed 8',
  'isales.statistics.business.noMoreThanFive': 'The number of selected items cannot exceed 5',
  'isales.statistics.business.busiResult': 'Business results',
  'isales.statistics.business.subBusiResult': 'Sub-business result',
  'isales.manual.title.viewPreemptionResult': 'Preempted Outgoing Call Result-',
  'isales.statistics.business.filteringIndicator': 'Filtering Indicators',
  'isales.statistics.business.indicatorName': 'Indicator Name',
  'isales.statistics.business.description': 'Description',
  'isales.statistics.business.cancel': 'cancel',
  'isales.statistics.business.confirm': 'Acknowledgement',
  'isales.statistics.business.saveError': 'Errors',
  'isales.statistics.business.errorInfo': 'Saving failed. Select at least one indicator',
  'isales.statistics.business.error': 'Errors',
  'isales.statistics.business.warningFilterMax': 'A maximum of {0} indicators can be selected',
  'isales.agent.message.description.limit': 'The skill queue description cannot exceed 50 characters',
  'isales.agent.message.updateCtiAndDbFail': 'Refresh failed',
  'isales.operlog.message.exportSurveyData': 'Exporting Training Questions',
  'isales.taskinfo.message.agent': 'The agent information is abnormal. Check whether there are agents other than the current skill queue',
  'isales.appointment.message.plaintext': 'The user does not have the plaintext permission and cannot initiate calls. Please enable the plaintext permission',
  'isales.language.label.language': 'Please select a language',
  'isales.language.label.zh_CN': 'Chinese',
  'isales.language.label.en_US': 'English',
  'isales.language.label.es_ES': 'Spanish',
  'isales.taskmanagement.title.batchOpt': 'Batch Operation',
  'isales.audio.title.beginTime': 'Recording Start Time',
  'isales.audio.title.endTime': 'Recording End Time',
  'isales.audio.title.asrTransResult': 'Speech Transcription Results',
  'isales.audio.title.confirmedResult': 'Ringback Tone Recognition Results',
  'isales.audio.title.confirmStatus': 'Business Results',
  'isales.taskstatus.field.confirming': 'To be confirmed',
  'isales.taskstatus.field.confirmed': 'Confirmed',
  'isales.rbtresult.field.other': 'other',
  'isales.rbtresult.field.emptynumber': 'Empty number',
  'isales.rbtresult.field.cusclose': 'The called user turns off the phone',
  'isales.rbtresult.field.interbusy': 'network busy',
  'isales.rbtresult.field.callershutdown': 'Caller arrears suspension / one-way suspension',
  'isales.rbtresult.field.calledshutdown': 'Called to stop',
  'isales.rbtresult.field.calledbusy': 'The called party is busy, and the called party has registered the call waiting function',
  'isales.rbtresult.field.calledbusycalling': 'The called party is busy, and the called party has not registered the call waiting function',
  'isales.rbtresult.field.calledoutarea': 'The called party is not in the service area',
  'isales.rbtresult.field.transfer': 'Call forwarding',
  'isales.rbtresult.field.tollcaller': 'International Calling Restrictions',
  'isales.rbtresult.field.longtimemissed': 'long time no call',
  'isales.rbtresult.field.calledrestriction': 'The called user has set incoming call restrictions',
  'isales.rbtresult.field.linefaulty': 'Line failure in the direction of other operators',
  'isales.rbtresult.field.calledunconnected': 'The called party is not available, and the incoming call reminder is enabled',
  'isales.rbtresult.emptyparam.message': 'The start and end time cannot be empty',
  'isales.asrtransresult.field.undefine': 'undefined',
  'isales.taskmanagement.message.crossmonth': 'The start time and end time cannot span months',
  'isales.taskinfo.url.not.whitelist': 'The address is not in the trustlist',
  'isales.whitepopuppageurl.protocol.check': 'Insecure protocols are used\, which poses security risks',
  'isales.import.message.ruleExtProp': 'Custom attributes have been enabled. Configure the import mapping rule first\, and then click Import by Rule',
  'isales.datasourcemanagement.field.load': 'load',
  'isales.datasourcemanagement.message.loadSuccess': 'Data source loaded successfully',
  'isales.datasourcemanagement.message.loadfail': 'Failed to load data source',
  'isales.datasourcemanagement.message.confirLoad': 'Loading data source configuration is a high-risk operation \, please proceed with caution',
  'isales.task.promotion.whatsApp': 'WhatsApp',
  'isales.task.promotion.whatsAppChannel': 'WhatsApp channel',
  'isales.task.promotion.whatsApp.message.template': 'Template Message',
  'isales.task.promotion.whatsApp.message.template.select': 'Select a WhatsApp template message',
  'isales.task.promotion.whatsApp.channel.select': 'Select a WhatsApp channel',
  'isales.task.promotion.whatsApp.message.id': 'Template Message ID',
  'isales.task.promotion.whatsApp.message.name': 'Template Message Name',
  'isales.task.promotion.whatsApp.variable.description': 'Parameter Description',
  'isales.task.promotion.whatsApp.variable.content': 'Parameter Content',
  'isales.management.message.SelectNumberMax': 'Select up to 100 calling numbers at a time',
  'isales.basicinfo.title.addCallNo': 'Add calling number',
  'isales.taskmanagement.title.deleteFailExitOne': 'Configure at least one calling number for the outbound call task',
  'isales.failInterval.check': 'There may be risks when the interval is less than 10s',
  'isales.taskmanagement.title.agentStatistics': 'Agent work statistics',
  'isales.work.message.daterequest': 'The start and end time must be passed at the same time',
  'isales.work.title.selectAgent': 'Select Agent',
  'isales.work.title.agent.workno': 'Agent ID',
  'isales.message.promptForAgent': 'Please enter the agent ID',
  'isales.button.search': 'Inquiries',
  'isales.button.reset': 'Reset',
  'isales.title.agent.workno': 'Agent ID',
  'isales.label.agent.accountcode': 'Service account',
  'isales.agent.work.statistics.AgentName': 'Agent Name',
  'isales.button.cancel': 'Cancel',
  'isales.button.confirm': 'Confirm',
  'isales.file.message.FilePathTips': 'This parameter is optional. If the outgoing call file is stored in the default path\, this parameter is optional. If the outgoing call file is stored in the folder in the default path\, this parameter must be set',
  'isales.blacklist.message.timeserror': 'Incorrect limit times',
  'isales.blacklist.message.perioderror': 'Incorrect restriction period',
  'isales.datasourcemanagement.message.updateDataSourceSuccess': 'The data source is modified successfully',
  'isales.datasourcemanagement.message.updateDataSourceFail': 'Failed to modify the data source',
  'isales.task.status.running': 'In Progress',
  'isales.task.status.pause': 'Pause',
  'isales.task.finished': 'Completed',
  'isales.task.notFinished': 'Incomplete',
  'isales.task.info': 'Task Information',
  'isales.task.benginEndTime': 'Start And End Time',
  'isales.task.detail': 'Outgoing Call Task Details',
  'isales.taskmanagement.startTask': 'Start Task',
  'isales.taskInfo.title.taskTemplate': 'Task Template',
  'isales.taskmanagement.title.taskTime': 'Task Time',
  'isales.taskmanagement.title.setAgent': 'Assigning Agents',
  'isales.taskInfo.title.quotas': 'Result Fulfillment',
  'isales.taskInfo.title.quotasTips': 'When the number of calls marked by the agent as the result of the preceding service exceeds the value of this parameter, the system does not automatically retrieve the calls',
  'isales.taskInfo.title.callCompletedNum': 'Completed Calls',
  'isales.taskInfo.title.callCompletedNumTips': 'When the number of completed calls exceeds the value of this parameter, the system does not automatically retrieve calls',
  'isales.taskInfo.title.pickUpNum': 'Single-time Claimed Quantity',
  'isales.taskInfo.title.pickUpCondition': 'Data Extraction Conditions',
  'isales.step.taskinfo': 'Task Information',
  'isales.step.callpolicy': 'Outbound Strategy',
  'isales.step.outboundcalldata': 'Task Data',
  'isales.calltime.title.default.datetime': 'Regular Date Time Period',
  'isales.calltime.title.spec.date': 'Special Date',
  'isales.calltime.title.add.date': 'add special date',
  'isales.calltime.title.add.time.period': 'add time period',
  'isales.calltime.title.holidayName': 'Special Date Name',
  'isales.calltime.title.holidayType': 'Type',
  'isales.calltime.title.holiday': 'Special Time',
  'isales.calltime.title.spec.datetime.period': 'Special Time Period',
  'isales.calltime.task.template': 'Task Template',
  'isales.calltime.task.template.select.placeholder': 'Option to create outbound tasks from templates',
  'isales.calltime.task.callerno.select.placeholder': 'Please select the calling number',
  'isales.calltime.task.begin.end.date': 'Task Start And End Time',
  'isales.calldata.prop.filter': 'Outbound Call Data Attribute Filtering',
  'isales.taskinfo.header.policy': 'Task Strategy',
  'isales.taskinfo.call.header.policy': 'Call Strategy',
  'isales.taskmanagement.title.sysAuto': 'The System Automatically Calls Out',
  'isales.work.message.dateRangeRequest': 'Please select the query time range',
  'isales.work.message.taskNameLengthLimit': 'The task name cannot exceed 50 characters',
  'isales.work.message.workNoLengthLimit': 'The work number cannot exceed 5 characters',
  'isales.work.message.workNoTypeLimit': 'The value must be 0 or a positive integer',
  'isales.taskInfo.title.filterCondition': 'Condition',
  'isales.label.agent.agentType': 'Agent Type',
  'isales.label.agent.role': 'Role',
  'isales.calleeNo.length.max32': 'The customer number can contain a maximum of 32 characters',
  'isales.label.calldata.order': 'Call Sequence',
  'isales.tasklogic.field.callOrderMethod.byData': 'By data attribute',
  'isales.tasklogic.field.callOrderMethod.orderType': 'sort by',
  'isales.label.multiple.callno.round': 'Multi-number customer call rounds',
  'isales.task.result.export.query': 'Go to View',
  'isales.task.result.export.skip': 'Skip',
  'isales.task.result.export.message': 'An export task has been created. Are you sure you want to view the export list?',
  'isales.rbtrecognition.remark.length': 'The string can contain a maximum of 200 characters',
  'isales.task.field.callFlag0': 'To Be Executed',
  'isales.task.field.callFlag1': 'Executing',
  'isales.task.field.callFlag2': 'Successful',
  'isales.taskinfo.field.agentType4': 'Audio',
  'isales.taskinfo.field.agentType11': 'Video',
  'isales.taskinfo.field.agentType5': 'Multimedia',
  'isales.taskinfo.field.agentType99': 'Versatile',
  'isales.appointment.title.appointState': 'Reservation Status',
  'isales.task.field.appointState1': 'Reserved',
  'isales.task.field.appointState2': 'Ready to call back',
  'isales.task.field.appointState3': 'Callback',
  'isales.task.field.appointState4': 'Completed',
  'isales.taskinfo.title.adjustAgent': 'Adjusting Agents',
  'isales.taskinfo.check.empty': 'parameter cannot be empty',
  'isales.tasktype.field.robot.create': 'New robot outbound call',
  'isales.tasktype.field.auto.info': 'Common IVR',
  'isales.tasktype.field.intelligent.info': 'Smart Robot',
  'isales.taskmanagement.title.batchEditTaskInfo.errTaskType': 'Tasks of different types cannot be modified in batches',
  'isales.taskmanagement.title.pauseTask.askConfirm': 'Are you sure you want to suspend the outbound call task? If the task is forcibly paused, ongoing calls may be affected',
  'isales.task.result.callrecord.conversation': 'Conversation Record',
  'isales.tasktype.field.auto.robot.create': 'Create a new common IVR',
  'isales.tasktype.field.intelligent.robot.create': 'Create a new intelligent robot',
  'isales.management.title.callbot.auto': 'Configure the button to call out the robot',
  'isales.taskmanagement.title.taskDataVolume': 'Task Data Volume',
  'isales.taskmanagement.title.callCompletionRate': 'Call Completion Rate',
  'isales.taskmanagement.title.importData.template': 'Template',
  'isales.taskmanagement.title.importData.upload': 'Upload',
  'isales.taskmanagement.title.importData.selectFile': 'Select File',
  'isales.taskmanagement.pauseTask': 'Pause Task',
  'isales.taskInfo.spec.date.title.quotasTips': 'This configuration indicates the special date and time period that require outbound calls',
  'isales.calltime.title.spec.date.empty.warn': 'Special date not configured!',
  'isales.tasklogic.message.empty.orderNo': 'There is an empty serial number, please re-enter',
  'isales.taskmanagement.exportResult': 'Export task result',
  'isales.outboundBusiness.statistic.di': 'Week',
  'isales.outboundBusiness.statistic.week': ' ',
  'isales.rbtrecognition.remark.tip': 'Cannot contain special characters:',
  'isales.business.callInterval.message.must': 'interval is required',
  'isales.tasktype.field.robot': 'Robot Outbound',
  'isales.taskmanagement.title.sysAuto.template': 'System automatic outbound call template',
  'isales.taskmanagement.title.sysAuto.templateAdd': 'Creating an Automatic Outbound Call Template',
  'isales.tasktype.field.robot.template': 'Robot outbound call template',
  'isales.tasktype.field.robot.templateAdd': 'Creating a robot outgoing call template',
  'isales.template.title.addManual': 'Manual outgoing call template',
  'isales.manual.field.callingRecord': 'Outbound call record',
  'isales.call.count': 'Number of calls',
  'isales.task.promotion.createBeginTime': 'Creation Start Time',
  'isales.task.promotion.createEndTime': 'Creation end time',
  'isales.task.promotion.timeerror': 'The effective time must be earlier than the expiration time',
  'isales.busiresult.description.length': 'The length of the character string exceeds the value range. The length must be less than or equal to 500 characters',
  'isales.import.coverMode.tips': 'Overwrite import will delete all data to be called, which takes a long time. Exercise caution when performing this operation',
  'isales.taskmanagement.title.taskContent': 'Details',
  'isales.taskmanagement.title.saveAndImportData': 'Save&Import Data',
  'isales.task.promotion.numberOnly': 'Only non-negative integers are allowed',
  'isales.data.lock': 'Some outgoing call data is not processed. For example, you are importing, adding, or deleting outgoing call data. Please try again later',
  'isales.survey.type': 'Questionnaire type',
  'ccsurvey.label.scenarioType.manual': 'manual questionnaire',
  'ccsurvey.label.scenarioType.manualIvr': 'Manual vs. IVR Questionnaire',
  'isales.tasktype.field.manualIvr.info': 'Play questionnaire process',
  'ccsurvey.info.tip36': 'The question is required',
  'ccsurvey.transfer.tip36': 'Suspend turn to IVR playback questionnaire',
  'isales.taskinfo.message.callintervalrangeMax': 'Please enter a positive integer between 1-86400.',
  'isales.notification.receiver.whatsapp.placeholder': 'Please enter recipient number with international area code',
  'isales.data.title.more': 'More',
  'isales.manual.exit.preview': 'Executing a Preview Outbound Call',
  'isales.manual.exit.preview.tips': 'If the preview times out or the window is closed, the system will automatically call you.',
  'isales.result.search.displayResultOrCode': 'Please enter code or description',
  'isales.management.message.dbIp': 'IP format is incorrect',
  'isales.agent.message.modifyPwdVdnFailed': 'It is not allowed to modify the configuration administrator password!',
  'isales.taskmanagement.title.addSysAuto': 'Create a new system to automatically call out',
  'isales.business.result.message.checkCodePoint': 'Only numbers, letters, -,. can be entered.',
  'isales.import.calldata.selecttime': 'Select the execution time',
  'isales.statistics.message.chooseCondition': 'Please select query conditions first',
  'isales.taskpolicy.field.time': '(s)',
  'isales.taskInfo.title.filterSubCondition': 'Filter subconditions',
  'isales.agent.work.statistics.tableTips': 'Select the outbound call task or agent you want to view first.',
  'isales.file.message.rule.add.success': 'Rule created successfully.',
  'isales.file.title.server.add.success': 'Succeeded in creating server information.',
  'isales.management.title.selectResult':'Select outgoing call result',
  'isales.specialday.placeholder.year.beginTime': 'The format is MM-DD, for example, 12-01',
  'isales.management.message.appointTime': 'The reserved call time cannot be earlier than the current system time',
  'isales.management.message.intelligence.taskName': 'The length of the task name of the intelligent robot outbound call type cannot exceed 36 bytes. One letter or number occupies one byte, and one Chinese character occupies three bytes',
}
