export default {
  "ccm.agent.label.promptParameters": "提示信息参数配置",
  "ccm.agent.label.queueParameters": "排队处理参数配置",
  "ccm.agent.label.queueTransferType": "转接类型",
  "ccm.agent.label.queueTransferObj": "转接对象",
  "ccm.agent.label.busyRatePrefix": "转接队列的“设备占用率”超出",
  "ccm.agent.label.busyRateSuffix": "系统将不允许呼叫转移到该队列",
  "ccm.agent.label.busyJudging": "排队遇忙或溢出判定条件",
  "ccm.agent.label.deviceUsageThresholdPrefix": "实际“设备占用率”大于或等于",
  "ccm.agent.label.deviceUsageThresholdSuffix": "视为“遇忙”，系统将对呼叫进行转接。",
  "ccm.agent.label.waitTimePrefix": "系统预测的用户等待时长将大于",
  "ccm.agent.label.waitTimeSuffix": "视为“遇忙”，系统将对呼叫进行转接。",
  "ccm.agent.label.queuingCallsMaxNumPrefix": "等待队列服务的呼叫数超出",
  "ccm.agent.label.queuingCallsMaxNumSuffix": "视为“溢出”，系统将对呼叫进行转接。",
  "ccm.agent.label.scaleFactorPrefix": "等待队列服务的呼叫数超出（",
  "ccm.agent.label.scaleFactorSuffix": "*签入座席数 ），视为“溢出”，系统将对呼叫进行转接。",

  "ccm.transfer.number.pop.create.title":"新建转接外部号码",
  "ccm.transfer.number.pop.modify.title":"编辑转接外部号码",
  "ccm.transfer.number.pop.delete.title":"删除",
  "ccm.transfer.number.pop.delete.message":"确定删除所选的外部号码吗？",
  "ccm.transfer.number.search.number":"请输入号码",
  "ccm.transfer.number.search.name":"请输入名称",
  "ccm.transfer.number.label.index":"序号",
  "ccm.transfer.number.label.number":"号码",
  "ccm.transfer.number.label.name":"名称",
  "ccm.transfer.number.label.flag":"用户号码转呼",
  "ccm.transfer.number.label.flag.yes":"是",
  "ccm.transfer.number.label.flag.no":"否",
  "ccm.satisfactiontemplate.message.confirm":"确认是否按满意度等级更新模板内容",
  "ccm.satisfactiontemplate.message.queryfail":"满意度调查模板内容查询失败",
  "ccm.satisfactiontemplate.tipscontent":"Instagram、Telegram、WhatsApp、LINE、WebChat、Facebook、X (Twitter)、5G消息、SMS渠道的满意度调查将使用此满意度调查消息模板内容，向客户发送满意度调查消息",
  "ccm.satisfaction.changetips":"注：满意度等级配置有变动，请同步修改满意度调查模板内容",
  "ccm.satisfactionconfig.message.question":"Instagram、Telegram、WhatsApp、LINE、WebChat、Facebook、X (Twitter)、5G消息、SMS渠道的满意度调查将使用此满意度调查消息模板内容，向客户发送满意度调查消息。",
  "ccm.satisfactionlevel.satislevel.config":"满意度等级配置",
  "ccm.satisfactionlevel.satislevel.button":"按键",
  "ccm.satisfactionlevel.satislevel.description":"描述",
  "ccm.satisfactionlevel.satislevel.operat":"操作",
  "ccm.satisfactionlevel.satislevel.moveup":"上移",
  "ccm.satisfactionlevel.satislevel.movedown":"下移",
  "ccm.agent.button.coloumedit":"字段配置",

  "ccm.transtask.label.friday": "周五",
  "ccm.agent.contact.preview": "预览",
  "ccm.agent.label.waitVoiceSelect": "请选择等待音",
  "ccm.agent.label.noAgents": "无人上班处理",
  "ccm.custdata.operlog.refresh": "刷新",
  "ccm.agent.label.waitTimeRange": "取值 [0-9999]",
  "ccm.agent.contact.isExistTalkReason": "是否设置来电原因",
  "ccm.agent.contact.QUERU_SFTP_FAIL_CODE": "sftp信息为空",
  "ccm.note.config.notename": "名称",
  "ccm.label.question.playmode": "问题放音模式",
  "ccm.ivr.ivrflow.filter": "筛选",
  "ccm.ivr.ivrflow.operate.release.needexecptionhanding": "请配置并正式发布一个异常处理流程",
  "ccm.ivr.ivrflow.type": "类型",
  "ccm.ivr.pageurls.errorsave": "错误",
  "ccm.ivr.voiceadvice.cancel": "取消",
  "ccm.ivr.voiceadvice.submit": "提交",
  "ccm.ivr.voiceedit.addfail": "新增失败",
  "ccm.ivr.voiceedit.addsuccess": "新增成功",
  "ccm.ivr.voicereview.video": "视频",
  "ccm.ivr.voiceupload.new": "新增",
  "ccm.ivrflow.createivrflow.fail": "新增失败",
  "ccm.ivrflow.grayrule.editsuccess": "修改成功",
  "ccm.ivrflow.ivrflowname.specialStrValidateFailed": "不允许包含特殊字符且长度不超过50字符",
  "ccm.nms.playcollectservice.ttsvoice": "文本放音(TTS)",
  "ccm.nms.playservice.chose.survey": "播放内容从已上传并审核过的音频中选择或新增语音",
  "ccm.nms.playservice.chosetts.survey": "播放内容从已上传并审核过的TTS文本中选择或新增文本，需拥有TTS资源",
  "ccm.nms.playservice.playfile": "放音文件",
  "ccm.satisfactionconfig.group.createsuccess": "新增满意度策略组成功",
  "ccm.satisfactionconfig.group.deletesuccess": "删除满意度策略组成功",
  "ccm.satisfactionconfig.strategy.createsuccess": "新增渠道满意度策略成功",
  "ccm.satisfactionconfig.strategy.updateuccess": "修改渠道满意度策略成功",
  "ccm.satisfactionconfig.strategy.deletesuccess": "删除渠道满意度策略成功",
  "ccm.satisfactionconfig.strategy.surveytyperequired": "调查类型不允许为空",
  "ccm.certificate.management.update.certfile": "更新证书文件",
  "ccm.certificate.management.add.title": "新建证书",
  "ccm.certificate.management.update.title": "更新证书",
  "ccm.transtask.label.reportData": "报表数据",
  "ccm.agent.sysparamconfig.emailMaxRecipientsNoCheck": "邮件座席转发的收件人最大数量必须在1到50之间",
  "ccm.urlconfig.label.oauth.callback.alerttip": "当调用创建双呼接口时传递了回调URL，若配置了下面的鉴权信息，则当双呼通话结束回调该URL时使用下面的鉴权信息。否则，回调时将不鉴权。",
  "ccm.certificate.management.certificate.crlfile.certhasexpired": "证书已过期",
  "ccm.ivr.placeholder.ivrdesc": "请输入流程描述",
  "ccm.calledconfig.message.nonumbers": "请选择号码",
  "ccm.callout.lable.default": "默认值",
  "ccm.verifyidentity.fail.USERIDPARSEERROR": "验证号码不能含有特殊字符",
  "ccm.pageinterface.saveSuccess": "保存成功",
  "ccm.speciallist.msg.selectonly": "只能选择一个特殊名单进行修改",
  "ccm.custdata.button.exportTemplate": "导出模板",
  "ccm.chatbot.query.list.fail": "获取机器人列表失败",
  "ccm.ivr.button.traceInfo": "点击查看当前流程轨迹",
  "ccm.verifyidentity.callId": "呼叫流水号",
  "ccm.verifyidentity.verifyName": "验证类型",
  "ccm.contactitemconfig.label.data.item.identification": "字段标识",
  "ccm.chatbot.query.name.fail": "获取机器人名称失败",
  "ccm.agent.message.pageLayoutFail": "页面布局或排列个数未选择",
  "ccm.speciallist.msg.suffix": "导入文件名的后缀不正确",
  "ccm.agent.leavemessage.downloadLeaveMessage": "下载留言",
  "ccm.custdata.operlog.exportEndTime": "导出结束时间",
  "ccm.agent.restrecord.rest.currentstatus": "当前状态",
  "ccm.agent.ccagentinfo.unregister.success": "注销成功",
  "ccm.speciallist.msg.tip": "每次最多导入1000条",
  "ccm.agent.message.createAgentAndSkillFail": "创建座席技能队列关联关系失败",
  "ccm.recognition.label.openBreakDown": "故障提示语",
  "ccm.contact.detail.receiver": "接收者",
  "ccm.agent.button.close": "关闭",
  "ccm.certificate.management.type": "证书类型",
  "ccm.speciallist.msg.confirmdeletehis": "确定删除所选的历史特殊名单吗？",
  "ccm.sysparam.success.save": "成功",
  "ccm.satisfactionconfig.message.accepttime": "受理时间",
  "ccm.agent.label.callDispatchModel.incomingCall": "来话比呼出技能优先分配",
  "ccm.recognition.label.breakDownVoice": "提示音",
  "ccm.custdata.label.status": "状态",
  "ccm.calledroute.title.selectskill": "选择技能队列",
  "ccm.recognition.label.openIvrAgentSuccess": "开启智能座席助手成功",
  "ccmanagement.satisfaction.strategy.accepttime.accepttime": "受理时间",
  "ccm.transtask.label.dataname": "转储服务器名称",
  "ccm.agent.message.longCharacters": "号码不能超过24个字符",
  "ccm.agent.message.longCharacters.form": "号码不能超过24个字符",
  "ccm.speciallist.msg.partexport": "确定导出选中的用户数据？",
  "ccm.sysparam.config.itemname.tips": "请输入参数名称",
  "ccm.accesscode.label.accesscodedesc": "接入码描述",
  "ccm.accesscode.title.accesscodedesc": "编辑接入码描述",
  "ccm.satisfactionconfig.message.chooseivrflow": "选择IVR流程",
  "ccm.agent.calledroute.success": "成功",
  "ccm.custdata.label.endTime": "结束时间",
  "ccm.agent.contact.assistantCallid": "协同呼叫ID",
  "ccm.contact.customer.config.referenced": "已被接触客户信息栏引用",
  "ccm.contact.record.data.referenced": "已被接触记录字段引用",
  "ccm.agent.label.description": "描述",
  "ccm.agent.label.endworkno": "结束工号",
  "ccm.contactitemconfig.label.data.extended.dataset.source.interface": "数据集来源接口",
  "ccm.transtask.label.day": "日",
  "ccm.agent.message.updateCtiAndDbFail": "同步刷新技能队列失败",
  "ccm.transserver.label.obsnotinwhite": "操作失败，请联系系统管理员将OBS服务地址加入资源转储OBS服务器白名单",
  "ccm.agent.recall.param.callid.empty": "当前无可回呼号码",
  "ccm.agent.calledroute.placeholder.accesscode": "请选择接入码 ",
  "ccm.label.contactexport.customfield": "自定义导出字段",
  "ccm.systemparam.success.refresh": "参数刷新成功",
  "ccm.speciallist.msg.errorreason": "失败原因",
  "ccm.contactitemconfig.label.data.item.sequence.number": "排序号",
  "ccm.agent.pageurls.accessaddr": "访问地址",
  "ccm.recognition.label.orderfailpd": "订阅失败，请稍后重试",
  "ccm.custdata.operlog.confirmMessage": "是否确认新建导出任务？",
  "ccm.verifyidentity.NOTRETURN": "未返回",
  "ccm.urlconfig.label.xNumber": "X号码",
  "ccm.satisfactionconfig.message.cancel": "取消",
  "ccm.agent.calledroute.editsuccess": "编辑成功",
  "ccm.speciallist.msg.transfererror": "转历史失败",
  "ccm.satisfactionconfig.message.comfirm": "确认删除该策略组配置？",
  "ccm.agent.leavemessage.status.unprocessed": "未处理",
  "ccm.agent.contact.audio-visual": "音视频",
  "ccm.agent.skill.skillExist": "已经存在，请重新输入！",
  "ccm.transtask.label.taskfail": "新建任务失败，创建的接触记录类型任务数量不能超过{0}",
  "ccm.ivr.label.processid": "流程ID",
  "ccm.agent.label.parameter": "参数1",
  "ccm.agent.title.agentEdit": "座席信息配置",
  "ccm.speciallist.msg.modifyerror": "修改失败",
  "ccm.label.contactexport.selectdatafield": "选择导出字段",
  "ccm.agent.contact.preCallout": "预览式外呼",
  "ccm.agent.pageurls.pagetype": "页面类型",
  "ccm.agent.calledroute.create.accesscode.none": "被叫配置的系统接入码没有分配",
  "ccm.transtask.label.vdnReport": "VDN话务报表",
  "ccm.agent.tips.inputpassword": "请输入当前登陆的帐号密码",
  "ccm.basicdata.msg.nodata": "暂无数据",
  "ccm.contact.customer.config.writeBackFieldError": "该回写接触记录扩展字段已被占用",
  "ccm.contact.customer.config.dataItemCodeError": "输入字段标识错误",
  "ccm.agent.Transfer.theAgent": "座席",
  "ccm.agent.restReason.restCauseTime.formate": "休息时长不满足时分秒格式要求",
  "ccm.transtask.label.updateexsit": "操作失败，服务器名称已存在",
  "ccm.speciallist.msg.endtimeerror": "失效时间应大于当前时间",
  "ccm.custdata.button.moveDown": "下移",
  "ccm.custdata.option.isMaskDisplay.yes": "掩码展示",
  "ccm.contactitemconfig.label.data.extended.dictionary.value": "数据字典值",
  "ccm.verifyidentity.fail.WORKNOPARSEERROR": "座席工号必须为数字",
  "ccm.transtask.label.monday": "周一",
  "ccm.satisfactionconfig.message.validatename": "策略组名称不能为空或重复",
  "ccm.agent.ten.videoIvrchannels": "视频IVR通道数",
  "ccm.satisfactionconfig.message.calltime": "通话时长",
  "ccm.calledconfig.message.savemobileagentsuccess": "移动客服配置保存成功",
  "ccm.agent.contact.callin": "呼入",
  "ccm.custdata.msg.updatedItemNotExist": "此更新字段不存在，请刷新后再重试",
  "ccm.contactitemconfig.label.data.item.contact.list.visibility.no": "否",
  "ccm.agent.contact.vidio": "视频",
  "ccm.agent.contact.callid": "呼叫ID",
  "ccm.satisfactionconfig.message.failed": "失败",
  "ccm.transtask.label.skillAccessCodeReport": "技能接入码话务报表",
  "ccm.agent.contact.contactdetail": "接触详情",
  "ccm.custdata.operlog.exportTaskStatus": "导出任务状态",
  "ccm.verifyidentity.ERROR": "异常",
  "ccm.agent.label.positiveEmotionNumber": "正向",
  "ccm.speciallist.msg.view": "查看",
  "ccm.satisfactionconfig.message.smscloud": "华为云短信",
  "ccm.agent.button.edit": "编辑",
  "ccm.agent.label.waitVoiceCustom": "自定义等待音",
  "ccm.agent.accesscode.description.limit": "接入码描述不能超过50个字符",
  "ccm.agent.calledroute.isconfdelete": "是否确认删除？",
  "ccm.custdata.option.maskStyle.retainLast4": "保留后4位",
  "ccm.custdata.operlog.exportBeginTime": "导出开始时间",
  "ccm.agent.pageurls.updateinfo": "更新成功",
  "ccm.agent.moveup.error": "上移失败",
  "ccm.agent.moveup.success": "上移成功",
  "ccm.agent.movedown.error": "下移失败",
  "ccm.agent.movedown.success": "下移成功",
  "ccm.urlconfig.label.input.appKey": "AppKey",
  "ccm.custdata.label.tableName": "表名称",
  "ccm.custdata.title.edit": "编辑数据",
  "ccm.custdata.title.coloum.add": "新建字段",
  "ccm.custdata.title.coloum.edit": "编辑字段",
  "ccm.custdata.title.coloum.detail": "查看字段详情",
  "ccm.satisfactionconfig.message.numberlimit": "租间满意度调查服务渠道配置不得超过100条",
  "ccm.contactitemconfig.label.data.extended.Type": "数据类型",
  "ccm.pageinterface.interfaceType.externalExplain": "外部接口需要在接口管理中进行配置，请联系系统运营管理员。",
  "ccm.speciallist.button.modify": "修改",
  "ccm.levelMessage.title.modify": "编辑级别管理",
  "ccm.speciallist.title.modify": "编辑特殊名单",
  "ccm.speciallistreminder.button.modify": "编辑特殊名单提醒",
  "ccm.agent.contact.multimedia.web": "多媒体-WEB",
  "ccm.pageurls.select.embedding": "嵌入",
  "ccm.pageinterface.custTableConfig": "基础表配置",
  "ccm.agent.contact.talkcontent": "会话内容",
  "ccm.agent.callreason.modify.failed": "修改来电原因失败",
  "ccm.agent.callreason.delete.confirm": "确认删除来电原因吗?",
  "ccm.agent.callreason.modify.exceeds.limit": "修改来电原因失败,间隔天数超过来电原因配置有效天数",
  "ccm.speciallist.msg.reviewerror": "审批失败",
  "ccm.satisfactiontemplate.message.resetfail": "满意度调查模板内容重置失败",
  "ccm.speciallist.msg.sltype.illegal": "非法特殊名单类型",
  "ccm.agent.contact.play": "播放",
  "ccm.custdata.msg.selectItem": "请先选择一项再进行排序",
  "ccm.speciallevel.msg.deleteyerror": "特殊名单级别删除失败",
  "ccm.speciallist.button.finish": "完成",
  "ccm.agent.message.allExport": "全部导出",
  "ccm.custdata.msg.dictionaryValidateFailed": "数据字典值范围输入格式错误",
  "ccm.agent.updateSoftPhone.modifyLocalPwd": "修改本地软电话密码失败",
  "ccm.contactitemconfig.label.data.item.basic": "基础数据",
  "ccm.contract.management.invalid.confirm": "确认将此文档进行失效处理吗？",
  "ccm.agent.message.deleteSkillAndAgentFail": "删除技能队列关联关系失败",
  "ccm.create.case.confirm": "工单类型查询为空，创建工单时，工单类型需要手动选择，是否继续？",
  "ccm.agent.label.audio": "音频座席",
  "ccm.agent.label.on": "开启",
  "ccm.agent.login.ID": "操作流水号",
  "ccm.satisfactionconfig.web.satisfaction.survey": "多媒体渠道满意度调查",
  "ccm.agent.calledroute.require": "请填写所有必填字段",
  "ccm.agent.label.afterVoiceSelect": "报工号后音",
  "ccm.agent.message.delete": "删除",
  "ccm.agent.message.pageInterface": "页面接口",
  "ccm.agent.contact.endtime": "结束时间",
  "ccm.agent.ten.anonymousSipInfo": "匿名呼叫服务器地址及端口",
  "ccm.recognition.resetSK.failed": "重置SK失败",
  "ccm.custdata.msg.duplicateTableName": "表名重复，请重新命名",
  "ccm.calledconfig.message.callreasontips": "请注意：当前层级关联工单不可用，请选择子集来电原因设置关联工单",
  "ccm.agent.tips.confFail": "配置失败",
  "ccm.agent.pageurls.configpage": "配置页面",
  "ccm.agent.contact.called": "被叫号码",
  "ccm.speciallist.operlog.downloadData": "导出特殊名单列表数据",
  "ccm.satisfactionconfig.message.webchannel": "Web多媒体渠道",
  "ccm.systemparam.error.noparamselected": "请先选择参数",
  "ccm.agent.leavemessage.auditdate": "更新时间",
  "ccm.agent.message.videoagentzero": "视频座席数为0，不能配置视频点击通话的技能队列",
  "ccmanagement.satisfaction.strategy.accepttime.timeEnd": "结束时间",
  "ccm.calledconfig.table.devtype": "目的设备类型",
  "ccm.agent.label.deviceUsageThresholdRange": "取值 [0-100]",
  "ccm.agent.message.cancelconfig": "取消配置",
  "ccm.agent.contact.preemptionCallout": "预占式外呼",
  "ccm.urlconfig.message.urlnotinwhite": "操作失败，请联系系统管理员将地址加入白名单",
  "ccm.agent.title.querysippwd": "查看软电话密码",
  "ccm.policy.label.updateVerificationFailed": "请求参数校验失败",
  "ccm.agent.label.scaleFactorRangeTwo": "取值 [1-10000]",
  "ccm.custdata.operlog.zippwd": "压缩密码",
  "ccm.dual.call.record.talkingTime": "被叫应答时间",
  "ccm.note.config.agent.ring.setUp": "提示信息配置",
  "ccm.dual.call.record.dualCallType": "双呼类型",
  "ccm.agent.leavemessage.processsuccess": "处理留言成功",
  "ccm.agent.contact.caller": "主叫号码",
  "ccm.agent.calledroute.delete.skill.default": "被叫配置被缺省私有技能队列引用，不能删除",
  "ccm.agent.label.transferType": "处理方式",
  "ccm.agent.restrecord.reststatus": "休息状态",
  "ccm.contact.detail.view.message.content": "查看消息内容",
  "ccmanagement.tenantspace.option.weeklist_firtolast": "倒数第1个",
  "ccm.agent.message.updateagentandskillfail": "更新座席及技能队列关联关系失败",
  "ccm.agent.pageurls.delfail": "删除失败",
  "ccm.agent.resetskillrecord.adjusttime": "调整时间",
  "ccm.contact.customer.config.value": "数据展示值",
  "ccm.satisfactionconfig.message.comfirmtitle": "确认删除",
  "ccm.agent.operation.CONFERENCE": "三方会议",
  "ccm.calledroute.button.cancel": "取消",
  "ccm.urlconfig.label.input.domainName": "ITA域名",
  "ccm.agent.label.transfer": "转接",
  "ccm.taskrecord.message.reexecutetranstaskerror": "重新执行失败",
  "ccm.agent.message.allExportAgent": "确定导出全部座席信息吗？",
  "ccm.custdata.operlog.inputzippwd": "输入压缩密码",
  "ccm.satisfactionlevel.message.samedesc": "存在重复的描述，请修改",
  "ccm.ucconfig.message.clientSecret": "应用程序(客户端)密码",
  "ccm.agent.operation.countid": "帐号",
  "ccm.note.config.agent.page.ring.select": "选择铃声",
  "ccm.agent.restReason.updateFailed": "修改失败",
  "ccm.agent.label.qualityChecker": "质检员",
  "ccm.satisfactionconfig.message.switchtip": "开启后，WEB渠道服务结束后，系统将自动弹出满意度调查页面，其余多媒体渠道服务结束后，系统将自动推送满意度模板内容",
  "ccm.agent.contact.playRecording": "播放录音",
  "ccm.agent.label.callDispatchModel.manySkills": "技能多者优先分配",
  "ccm.agent.contact.downloadFail": "下载失败",
  "ccm.custdata.msg.sortNoChange": "排序未发生任何改变。",
  "ccm.policy.label.updateFailed": "更新策略信息失败",
  "casemanagement.user.button.ok": "确认",
  "ccm.agent.button.search": "查询",
  "ccm.agent.label.serviceAccountName": "关联的Service Cloud帐号用户名",
  "ccm.contactitemconfig.label.contact.extended.information.dataset": "接触记录数据集",
  "ccm.agent.export.agentinfo.tips": "导出的数据中包含个人数据，需要正确使用并且提供安全的保护措施",
  "ccm.speciallist.msg.userNum": "用户号码",
  "ccm.transserver.delete.css.success": "删除云搜索服务器配置信息成功",
  "ccm.agent.contact.calledfailed": "外部被叫失败",
  "ccm.agent.updateSoftPhone.updateOpertionSuccess": "提示",
  "ccm.idauth.title.edit": "修改身份验证流程",
  "ccm.urlconfig.message.deletesuccess": "重置url配置成功",
  "ccmanagement.satisfaction.strategy.fail.calltime.inputnumber": "通话时间区间值必须是正整数",
  "ccm.ivr.label.processaccesscode": "流程接入码",
  "ccm.agent.pageurlparam.channelassociateddata": "随路数据",
  "ccm.agent.operation.AllOpType": "所有操作类型",
  "ccm.agent.Transfer.tranfermode": "转移模式",
  "ccm.calledconfig.table.selectnumbers": "选择号码",
  "ccm.agent.protocol.check": "请注意，当前输入的地址所使用的网络协议不是安全协议，如确定使用，可能存在安全风险。",
  "ccm.common.label.success": "成功",
  "ccm.certificate.management.certificatefile.notupload": "请上传证书文件",
  "ccm.speciallist.msg.selectspeciallist": "请选择特殊名单",
  "ccm.speciallist.msg.selectspeciallisthis": "请选择历史特殊名单",
  "ccm.agent.ten.companyName": "公司名称",
  "ccm.agent.label.calcOfMaxCallNum": "最大呼叫排队数据计算方法",
  "ccm.speciallevel.speciallist.level": "级别",
  "ccm.agent.label.serverAddress": "注册服务器地址",
  "ccm.verifyidentity.fail.CALLIDPARSEERROR": "呼叫流水号必须为数字",
  "ccm.agent.pageurls.openMode": "打开方式",
  "ccm.agent.operation.noMoreThan1Days": "范围不能超过1天",
  "ccm.calledconfig.label.selectcalled": "选择被叫号码",
  "ccm.calledconfig.message.deletemobileagentsuccess": "移动客服配置删除成功",
  "ccm.pageinterface.selectedAll": "选择全部",
  "ccm.note.config.agent.page.ring.warn": "建议进行呼叫测试，防止与电话端配置的铃声重叠",
  "ccmanagement.satisfaction.strategy.addsuccess": "保存成功",
  "ccm.transtask.label.vdnAccessCodeReport": "VDN接入码话务报表",
  "ccm.calledroute.title.selectaccesscode": "选择接入码",
  "ccm.calledroute.tip.selectaccesscode": "请先选择接入码",
  "ccm.botherlist.label.contactrecorddetail": "接触明细",
  "ccm.transserver.delete.fail": "删除转储服务器配置信息失败",
  "ccm.message.center.nocitiction.sending.records.status": "发送状态",
  "ccm.agent.label.startworkno": "起始工号",
  "ccm.agent.updateSoftPhone.queryNodeFailed": "查询租户节点信息失败",
  "ccm.agent.message.pageConfSuccess": "操作成功",
  "ccm.dual.call.record.caller": "主叫号码",
  "ccm.agent.password.wrong": "密码校验失败",
  "ccm.satisfactionconfig.message.validtime": "有效回复时间(分钟)",
  "ccm.agent.label.negativeEmotionNumber": "负向",
  "ccm.custdata.option.isMandatory.no": "非必填",
  "ccm.agent.calledroute.ivr": "请选择IVR",
  "ccm.certificate.management.updatetime": "更新时间",
  "ccm.speciallist.button.add": "新增",
  "ccm.levelMessage.title.add": "新建级别管理",
  "ccm.speciallist.title.add": "新建特殊名单",
  "ccm.speciallistreminder.button.add": "新建特殊名单提醒",
  "title.contact.agent.name": "业务帐号名称",
  "ccm.agent.label.cti": "cti",
  "ccm.agent.title.IVRTrace": "流程轨迹",
  "ccm.agent.videoDownload.RSETimeout": "请求RSE服务超时",
  "ccm.custdata.option.columnType.string": "字符串",
  "ccm.speciallist.msg.crateerrorreason": "用户号码已存在",
  "ccm.agent.contact.multimedia.instagram": "多媒体-Instagram",
  "ccm.agent.message.agentConfSuccess": "座席信息配置成功",
  "ccm.speciallevel.tip.stop": "是否确认停用？",
  "ccm.agent.label.skillChoice": "请选择技能队列",
  "ccm.agent.message.agentnumreachmax": "目标类型座席数已达最大",
  "ccm.agent.operation.ANSWER": "应答",
  "ccm.speciallist.msg.overlimit": "提醒消息长度大于256",
  "ccm.transtask.label.abandonedRingReport": "应答放弃呼叫报表",
  "ccm.contact.detail.message.sending.record": "消息发送记录",
  "ccm.chatbot.invalid.empty": "机器人助手不能为空",
  "ccm.idauth.title.code": "验证方式编码",
  "ccm.custdata.msg.reachUpperTableNum": "自定义表数量已达到上限",
  "ccm.agent.page.cancel.release": "未发布",
  "ccm.recognition.label.cancelorderfail": "取消订阅失败",
  "ccm.agent.calledroute.extcode": "扩展码",
  "ccm.agent.pageurls.maxcallingnum": "，请确保配置弹屏数量不超过5条",
  "ccm.callback.url.auth.tip": "请选择回调URL鉴权方式",
  "ccm.sysparam.error.validate.notmatchruleoption": "输入参数不在枚举范围内",
  "ccm.agent.calledroute.device": "请选择设备",
  "ccm.agent.operation.WHISPER": "耳语",
  "ccm.agent.contact.predictiveCall": "预测式外呼",
  "ccm.taskrecord.label.reexecute": "重新执行",
  "ccm.agent.contact.skillhungup": "转技能队列",
  "ccm.agent.contact.others-channels": "其他渠道",
  "ccm.callout.lable.caller": "外呼主叫号码",
  "ccm.agent.sysparamconfig.positiveinteger": "请输入正整数！",
  "ccm.certificate.management.scenename": "场景名称",
  "ccm.agent.restrecord.reststatus.Abnormal.endrest": "异常结束休息",
  "ccm.taskrecord.label.choosetask": "选择任务",
  "ccm.speciallist.msg.restores": "恢复",
  "ccm.satisfactionconfig.message.timelimittip": "若客户回复时间超出有效回复时间，回复将不再生效。",
  "ccm.satisfactionconfig.msg.templateenpty": "请选择短信模板",
  "ccm.satisfactionconfig.msg.timelimitenpty": "有效回复时间为空",
  "ccm.agent.calledroute.devicedesc": "设备名称",
  "ccm.agent.recall.fail": "回呼客户失败",
  "ccm.transtask.label.isOpenCssServer": "是否启用云搜索服务",
  "ccm.agent.mobileagent.label.deletehint": "是否确认删除选择的业务号码?",
  "ccm.agent.label.isaireconition": "是",
  "ccm.calledconfig.message.nullparent": "父节点已不存在",
  "ccm.speciallist.msg.applyTimeTo": "申请结束时间",
  "ccm.ucconfig.message.updatesuccess": "保存UC集成配置成功",
  "ccm.agent.contact.callerPresent": "用户主叫外显号码",
  "ccm.agent.restReason.isusing.warning": "休息原因码已在休息记录中使用，此操作可能造成休息记录中休息原因展示异常",
  "ccm.custdata.label.maskStyle": "掩码样式",
  "ccm.agent.button.restSoftPhonePwdError": "请绑定座席",
  "ccm.speciallist.msg.transferToHistory": "转历史",
  "ccm.dual.call.record.normal.end": "正常结束",
  "ccm.note.config.agent.page.ring.media": "多媒体来话提醒",
  "ccm.speciallist.msg.deleteerror": "特殊名单提醒删除失败",
  "ccmanagement.tenantspace.option.month_9": "9月",
  "ccm.agent.restReason.restCauseDesc.limit": "休息原因描述不能超过100个字符",
  "ccm.agent.restReason.restCauseDesc.limit.form": "休息原因描述不能超过100个字符",
  "ccm.agent.pageurls.successinfo": "创建成功",
  "ccm.agent.pageurls.successinfo.noPoint": "创建成功",
  "ccmanagement.tenantspace.option.month_7": "7月",
  "ccmanagement.tenantspace.option.month_8": "8月",
  "ccmanagement.tenantspace.option.month_5": "5月",
  "ccmanagement.tenantspace.option.month_6": "6月",
  "ccmanagement.tenantspace.option.month_3": "3月",
  "ccmanagement.tenantspace.option.month_4": "4月",
  "ccm.agent.ten.versatileagents": "全能型座席数",
  "ccmanagement.tenantspace.option.month_1": "1月",
  "ccmanagement.tenantspace.option.month_2": "2月",
  "ccm.satisfactionconfig.message.calltypein": "呼入",
  "ccm.contact.customer.config.type": "接触客户信息栏数据",
  "ccm.transtask.label.voiceFile": "语音文件",
  "ccm.anonymous.message.fail.paranull": "接入码入参为空",
  "ccm.certificate.management.certificatefile.certcodeerror": "证书编码有误",
  "ccm.agent.label.maxWaitNum": "最大排队人数",
  "ccm.satisfactionconfig.message.enterflowname": "请输入流程名称",
  "ccm.systemparam.error.refresh": "参数刷新失败",
  "ccm.note.config.agent.page.ring.newMedia": "多媒体新消息提醒",
  "ccm.agent.message.pageConfFail": "操作失败",
  "ccm.transtask.label.certTip.proxyCert": "请选择代理服务器证书",
  "ccm.agent.pageurls.integrationType": "集成方式",
  "ccm.agent.message.sysParamCofig.openVersatileAgentFail": "请先到“座席管理”处修改座席类型使其全能型类型的座席与视频类型座席不能大于租户订购最大视频座席数",
  "ccm.agent.calledroute.delfail": "本地数据删除失败",
  "ccm.recognition.label.updatesucess": "更新租间故障提示音成功",
  "ccm.urlconfig.message.overlimit.appSecret": "ITA双呼配置的secretKey超过512个字符",
  "ccm.custdata.button.importRecord": "导入结果查看",
  "ccm.custdata.msg.confirmWithdraw": "确认撤回？",
  "ccm.satisfactionconfig.message.operate": "操作",
  "ccm.agent.message.skillConfFail": "技能队列信息配置失败。",
  "ccm.agent.message.validatormsg": "密码相关输入应全部为空或全不为空",
  "ccm.recognition.label.sucess": "成功",
  "ccm.agent.calledroute.exception": "请检查Redis状态或CC-Gateway配置",
  "ccm.satisfactionconfig.message.strategygroupmember": "成员策略",
  "ccm.speciallisthis.operlog.downloadData": "导出特殊名单历史",
  "ccm.agent.message.skillHasbeenUsedByAgent": "该技能队列已被座席占用，请先删除座席",
  "ccm.agent.leavemessage.notLoginIn": "座席未签入",
  "ccm.agent.label.workNo": "座席工号",
  "ccm.satisfactionconfig.message.strategy": "满意度调查策略配置",
  "ccm.custdata.label.columnType": "字段类型",
  "ccm.urlconfig.pageurls.urlvalidate": "url需要以'http://'或'https://'开头",
  "ccm.callout.lable.number": "参数值",
  "ccm.agent.sysparamconfig.hasSelectAgentWorkRepeat": "已选择座席号段存在重复",
  "ccm.contact.msg.dataEmpty": "操作的数据在AICC不存在",
  "ccm.contact.msg.dataverification": "请求参数校验失败",
  "ccm.speciallist.msg.enable.fail": "启用失败",
  "ccm.agent.message.updateCtiAndDbSuccess": "同步成功",
  "ccmanagement.satisfaction.strategy.fail.accepttime.inputnumber": "起始时间或结束时间必须是数字",
  "ccm.agent.message.batchExport": "批量导出",
  "ccm.agent.contact.clicktodial": "点击通话",
  "ccm.custdata.label.dataImportRecord": "数据导入记录",
  "ccm.custdata.option.maskStyle.maskFirst4": "掩码前4位",
  "ccm.label.contactexport.exceedlimit2": "，请缩小数据时间范围或增加数据过滤条件\r\n",
  "ccm.transserver.label.edit": "资源转储服务器配置",
  "ccm.transtask.label.exportdata": "导出数据字段:",
  "ccm.agent.accesscode.index": "序号",
  "ccm.certificate.message.tip.warning": "证书的签名算法和密钥长度不符合要求,存在风险,是否继续",
  "ccm.satisfactionconfig.message.save": "保存",
  "ccm.certificate.management.certcode": "证书编码",
  "ccm.agent.message.ctipasswdsimple": "密码不满足平台复杂度要求，请联系运维人员检查",
  "ccm.agent.calledroute.fail": "失败",
  "ccm.agent.message.agentNotFoundFail": "根据ID无法找到座席详情",
  "ccm.custdata.label.isEncrypt": "是否加密",
  "ccm.agent.message.existDualchannelrecAgent": "智能识别和双轨道录音不能同时开启，批量修改的座席中存在已开启双轨道录音的座席",
  "ccm.satisfactionconfig.message.grouppriority": "优先级",
  "ccm.transtask.label.contactchoose": "接触记录自定义导出字段",
  "ccm.agent.label.sipPwd": "软电话密码",
  "ccm.recognition.label.modifymobileagentsuccess": "修改移动座席和一键双呼状态成功",
  "ccm.agent.operation.RECORDPLAY": "录音播放",
  "ccm.label.contactexport.curpage": "当前页",
  "ccm.transserver.label.servertype": "服务器类型",
  "ccm.label.contactexport.exceedlimit1": "最多可以导出{0}条数据",
  "ccm.agent.contact.pictures": "图片",
  "ccm.agent.page.release": "发布",
  "ccm.satisfactionconfig.audio.and.video": "音视频满意度调查",
  "ccm.agent.operation.CANCELINSERT": "取消插入",
  "ccm.agent.label.waitSkillWeight": "请输入技能权值",
  "ccm.agent.pageurls.isconfreset": "是否确认重置",
  "ccm.agent.page.pageParameter": "页面参数",
  "ccm.agent.operation.optype": "操作类型",
  "ccm.agent.label.skillWeight": "技能权值",
  "ccm.case.label.recommendedWorkOrder0": "推荐工单分类1",
  "ccm.botherlist.label.approveStatus.approved": "已同意",
  "ccm.agent.Transfer.devicetype": "转移目的设备类型",
  "ccm.agent.leavemessage.QUERU_LEAVEMESSAGE_FAIL_CODE": "查询留言sftp信息为空",
  "ccm.agent.pageurls.confreset": "重置确认",
  "ccm.agent.operation.noMoreThan3Days": "范围不能超过3天",
  "ccm.case.label.recommendedWorkOrder2": "推荐工单分类3",
  "ccm.agent.sysparamconfig.outboundtimecheck": "自动外呼预览开关时限必须在1s到3600s之间",
  "ccm.case.label.recommendedWorkOrder1": "推荐工单分类2",
  "ccmanagement.satisfaction.strategy.calltime.second": "秒",
  "ccm.agent.resetskillrecord.adjustresult": "调整结果",
  "ccm.speciallist.msg.restoreout": "租间最大只能保存{0}条特殊名单记录",
  "ccm.custdata.option.importDoing": "导入正在处理",
  "ccm.agent.contact.CONNECT_FAIL_CODE": "连接sftp服务器失败",
  "ccm.basicdata.msg.modifysuccess": "修改基础数据成功",
  "ccm.sysparam.error.validate.notmatchruleminlength": "输入参数低于最小长度",
  "ccm.contactitemconfig.message.itemValidate": "首字母必须是字母、下划线（_）或者美元符号（$），其他字母可以是下划线（_）、美元符号（$）、字母或者数字。",
  "ccm.agent.contact.callednook": "外呼被叫无应答",
  "ccm.urlconfig.modify.callnotify.ak.tooshort": "密匙长度不少于16个字符",
  "ccm.recognition.label.appSecret.reset": "重置SK",
  "ccm.recognition.msg.unsubscribeConfirm": "关闭智能识别，也会同时关闭智能坐席助手",
  "ccm.agent.contact.acceptno": "受理号码",
  "ccm.satisfactionconfig.message.updatefailed": "更新满意度调查配置失败",
  "ccm.speciallist.msg.applyTimeFrom": "申请开始时间",
  "ccm.agent.contact.resetAll": "一键重置",
  "ccm.transtask.label.cancel": "取消",
  "ccm.anonymous.message.fail.open": "开启失败，请检查CTI配置",
  "ccm.idauth.create.error.length": "验证方式编码输入超过最大长度",
  "ccm.asr.create.case.chat.info": "请在交谈中或者交谈结束后，点击创建工单按钮。",
  "ccm.agent.label.subtype": "子类型",
  "ccm.agent.message.aiagentnummore": "开启智能座席的数量不能超过租户开通时分配的座席数",
  "ccm.taskrecord.label.executeFail": "执行失败",
  "ccm.agent.message.modifymobileagentsuccess": "移动座席软电话号码信息修改成功",
  "casemanagement.user.title.account": "帐号",
  "ccm.certificate.management.certificatefile.suffixerror.pre": "类型的证书只能上传后缀名为空或为",
  "ccm.transtask.label.task.uniqueness": "报表数据任务，同一转储数据类型，同一转储间隔只能创建一个",
  "ccm.operlog.contactfile.download": "下载文件",
  "ccm.agent.message.agentnumcannotmuchthanmax": "目标类型座席数不能超过最大值",
  "ccm.callout.lable.index": "序号",
  "ccmanagement.satisfaction.strategy.minutevalidate": "配置的分钟数据格式错误",
  "ccm.satisfactionconfig.message.failtogetivrflow": "请开启满意度调查",
  "ccm.agent.restReason.selectRestReasons": "请选择休息原因",
  "ccm.agent.clear.message.agentnotexist": "取消配置失败，座席不存在",
  "ccmanagement.satisfaction.strategy.deletesuccess": "删除成功",
  "ccm.callreason.label.selectedreason": "已选原因",
  "ccm.agent.message.searchAccount": "请输入座席工号",
  "ccm.certificate.management.certificatefile.certtypeerror": "证书类型有误",
  "ccmanagement.satisfaction.strategy.fail.accepttime.inputnull": "起始时间和结束时间不可以为空",
  "ccm.agent.contact.callinandcallout": "呼入和呼出",
  "ccm.speciallist.msg.restoreerror": "恢复特殊名单失败",
  "ccm.urlconfig.message.missing.appKeyOrDomainName": "ITA双呼配置缺少appKey或者域名",
  "ccmanagement.satisfaction.strategy.calltime.lengthlimit": "配置的区间值不能超过12位",
  "ccm.agent.calledroute.create.ivr.limit": "设备类型为IVR，接入码的媒体类型只能为点击通话，回呼请求，普通呼叫",
  "ccm.agent.contact.playcomfirm": "确认播放",
  "ccm.sysparam.error.validate.notmatchrulerangelength": "输入参数超出最大长度或低于最小长度",
  "ccm.contactitemconfig.label.data.extended.dataset.mapping.field": "数据集映射字段",
  "ccm.accesscode.label.index": "序号",
  "ccm.transserverparam.label.proxyUserName": "用户名",
  "ccm.common.ajax.searchErr": "查询错误 ",
  "ccmanagement.satisfaction.strategy.fail.acceptdate.inputnumber": "起始日期和结束日期参数格式错误",
  "ccm.agent.label.weight": "权值",
  "ccm.vdn.label.defaultvoice": "默认",
  "ccm.transtask.label.dumpinterval": "转储间隔",
  "ccm.agent.operation.INSERT": "质检插入",
  "ccm.contact.customer.config.foregroundColor": "前景色",
  "ccm.satisfactionconfig.message.strategychannelid": "渠道策略配置id",
  "ccm.agent.label.batchselectway": "批量选择方式",
  "ccm.calledroute.title.selectivrflow": "选择IVR流程",
  "ccm.agent.Transfer.deviceAddr": "目的设备地址",
  "ccm.agent.label.agentWeightTip": "呼叫优先分配给权值大的座席",
  "ccm.contact.customer.config.datasource.objName": "数据来源对象映射字段",
  "ccm.note.config.choosenote": "选择提示音",
  "ccm.agent.softPhoneStatus.Registered": "已注册",
  "ccm.satisfactionlevel.message.satislevel": "满意度等级",
  "ccm.note.config.agent.ring.back.tone": "座席回铃音配置",
  "ccm.pageinterface.addObject": "添加对象",
  "ccm.transtask.label.discarded": "已废弃",
  "ccm.agent.label.inputstartworkno": "请输入起始工号",
  "ccm.sysparam.error.validate.notmatchruleemail": "请输入合法的邮箱地址",
  "ccm.satisfactionconfig.message.audio": "满意度调查配置",
  "ccm.agent.ten.DSTOffset": "夏令时偏移量（分钟）",
  "ccm.agent.button.export": "导出",
  "ccm.contactitemconfig.label.data.extended.Type.String": "字符串",
  "ccm.contact.msg.inactivesuccess": "失效成功",
  "ccm.agent.contact.calltype": "呼叫类型",
  "ccm.agent.message.createfailed": "创建座席失败",
  "ccm.dual.call.record.noMatchingDualCallRecordExists": "没有符合条件的双呼记录",
  "ccm.urlconfig.message.overlimit.xNumber": "ITA双呼配置的X号码超过20个字符",
  "ccm.agent.calledroute.updatecalledroute": "编辑",
  "ccm.agent.calledroute.title.create": "新建被叫配置",
  "ccm.agent.calledroute.title.updatecalledroute": "编辑被叫配置",
  "ccm.transtask.label.cdrData": "话单数据",
  "ccm.agent.leavemessage.auditcomments": "处理意见",
  "ccmanagement.satisfaction.strategy.operation": "相关操作",
  "ccm.speciallist.msg.typeName": "特殊名单类型",
  "ccm.satisfactionconfig.message.strategychanneltitle": "渠道策略配置",
  "ccm.satisfactionconfig.add.strategychanneltitle": "新建渠道满意度策略",
  "ccm.satisfactionconfig.update.strategychanneltitle": "编辑渠道满意度策略",
  "ccm.transtask.label.contactrcd": "转储接触记录配置",
  "ccm.agent.calledroute.accesscodewarn": "接入码只能包含数字",
  "ccm.whatsapp.template.message.name": "消息名称",
  "ccm.speciallist.msg.delete.fail": "删除失败",
  "ccm.agent.contact.clicktocall": "点击通话",
  "ccm.agent.ten.asrchannelnums": "ASR数",
  "ccm.agent.label.scaleFactor": "比例系数",
  "ccm.custdata.msg.confirmDelete": "确认删除？",
  "ccm.speciallist.msg.errorNum": "失败{limit}条",
  "ccm.certificate.management.passwd": "证书密码",
  "ccmanagement.satisfaction.strategy.fail.calltime.inputnull": "通话时间区间值不可以为空",
  "ccm.transtask.label.dumpintervalInitial": "转储间隔初始日",
  "ccm.agent.message.willDeleteSkills": "确定删除所选的{0}条技能队列吗？",
  "ccm.agent.message.willDeleteSkillGroups": "确定删除所选的技能组吗？",
  "ccm.custdata.operlog.confirm": "确认",
  "ccm.agent.contact.multi-media": "多媒体",
  "ccm.pageinterface.pleaseSelectAttribute": "请选择对象属性",
  "ccm.contactitemconfig.message.itemValidatefirst": "首字母必须是字母、下划线（_）或者美元符号（$）",
  "ccm.urlconfig.message.httpurl.safetip": "（不安全）",
  "ccm.agent.message.agentConfFail": "座席信息配置失败",
  "ccm.satisfactionconfig.message.flowname": "流程名称",
  "ccm.custdata.label.importantTips": "注：个人数据或者敏感数据需要加密存储和掩码展示",
  "ccm.recognition.label.appSecret.download.querySK.error": "查询SK出错",
  "ccm.agent.contact.weChat": "微信",
  "ccm.agent.contact.oircall": "原始被叫",
  "ccm.agent.label.scaleFactorRange": "取值 [0-10000]",
  "ccm.agent.restrecord.reststatus.timeout.Forcible.endrest": "超时强制结束休息",
  "ccm.urlconfig.modify.authbothnull": "授权信息不可以为空",
  "ccmanagement.agent.batchmodify.agentnum": "按工号",
  "ccm.speciallist.msg.necessary": "用户号码必填",
  "ccm.recognition.label.closeanonymoussuccess": "关闭匿名外呼标识成功",
  "ccm.transserver.label.servername": "服务器名称",
  "ccm.agent.message.numInRowFail": "数量填写超过限制，数量值为:2-4",
  "ccm.transtask.label.status": "状态",
  "ccm.agent.label.callDispatchModel": "呼叫分配方式",
  "ccm.agent.contact.QUERU_RECORDINFO_FAIL_CODE": "录音信息为空",
  "ccm.recognition.label.cancelordersucess": "取消订阅成功",
  "ccm.agent.leavemessage.statusFailed": "修改留言记录状态失败",
  "ccm.agent.message.sysParamCofig.closeVersatileAgentFail": "请先到“座席管理”处修改全能型类型的座席不能关联视频或视频点击通话技能队列。",
  "ccm.skillcreate.repeat.fail": "技能队列名称已存在",
  "ccm.speciallist.msg.selectonlyslreminder": "只能选择一个特殊名单提醒进行修改",
  "ccm.skill.label.skillname": "技能队列名称",
  "ccm.agent.title.skillCreate": "新建技能队列",
  "ccm.custdata.option.maskStyle.maskEmail": "邮箱掩码",
  "ccm.agent.contact.QUERY_SERVER_FAIL_CODE": "调用查询服务失败",
  "ccm.satisfactionconfig.message.strategygroupname": "策略组名称",
  "ccm.satisfactionconfig.title.strategygroup": "新建策略组",
  "ccm.sysparam.config.itemdesc": "描述",
  "ccm.agent.label.keepVoiceCustom": "自定义等待音",
  "ccm.agent.label.numInRow": "数量值为:2-4",
  "ccm.transserver.label.view": "查看",
  "ccm.custdata.label.isMaskDisplay": "是否掩码展示",
  "ccm.custdata.label.columnNo": "序号",
  "ccm.urlconfig.label.oauth.oauth2loginurl": "OAuth 2.0鉴权Login URL",
  "ccm.agent.button.batchconfig": "配置",
  "ccm.agent.pageurls.errorsave": "错误",
  "ccm.certificate.management.update.cancel.certfile": "确认要取消更新证书文件吗？",
  "ccm.common.label.error": "错误",
  "ccm.agent.operation.noMoreThan31Days": "范围不能超过31天",
  "ccm.agent.label.validTime": "密码有效期天数",
  "ccm.agent.operation.UNHOLD": "取消保持",
  "ccm.speciallist.msg.endtime": "失效时间不能超过2038年1月19日",
  "ccm.speciallist.label.line": "行",
  "ccm.agent.operation.CANCELREST": "取消休息",
  "ccm.custdata.option.importDone": "导入处理完成",
  "ccm.certificate.management.expiretime": "到期时间",
  "ccm.label.contactexport.exception": "生成导出任务遇到异常，请检查日志",
  "ccm.satisfactionconfig.message.validatemaxnumber": "策略组最多创建100条",
  "ccm.certificate.management.certificatecrlfile": "证书吊销文件",
  "ccm.pageinterface.objectList": "对象列表",
  "ccm.agent.label.information": "提示",
  "ccm.agent.label.selectagent": "已选座席",
  "SM.ORGSTAFF.LABEL.INPUT_SEARCH_KEYWORD": "关键字",
  "ccm.satisfactionconfig.message.surveytype": "调查类型",
  "ccm.verifyidentity.FAIL": "失败",
  "ccm.basicdata.error.datatypenotmatch": "数据与字段定义格式不一致",
  "ccm.agent.ten.timezone": "时区",
  "ccm.agent.recall": "回呼",
  "ccm.urlconfig.message.tip.warning": "请确认已经实际购买了ITA的相关服务，否则会影响实际的呼叫",
  "ccm.pageinterface.interfaceType.external": "外部接口",
  "ccm.dual.call.record.talkDuration": "通话时长(s)",
  "ccm.custdata.label.columnLength": "字段长度",
  "ccm.agent.title.selectskill": "选择技能队列",
  "ccm.pageurls.select.externalInterface": "外部页面",
  "ccm.agent.contact.itacall": "ITA双呼",
  "ccm.agent.message.specialCharacters": "号码存在特殊字符和字母",
  "ccm.custdata.msg.completeSort": "请先完成排序",
  "ccm.transserver.delete.css.confirm": "请确认是否删除云搜索服务器配置!",
  "ccm.agent.restReason.delete": "删除",
  "ccm.agent.contact.accountname": "业务帐号名称",
  "ccm.contact.customer.config.datasource.msgDataset": "信息数据集",
  "ccm.agent.message.ctipasswdrepeat": "新密码不能与最近五次的旧密码相同.",
  "ccm.speciallist.msg.operationerror": "操作失败",
  "ccm.agent.ten.dstOn": "开启",
  "ccmanagement.tenantspace.option.weekday_mon": "星期一",
  "ccm.agent.contact.relCallBackFlag": "释放回调标记",
  "ccm.agent.label.batchselect": "批量选择座席",
  "ccm.ucconfig.message.title": "向Microsoft标识平台注册应用程序",
  "ccm.callreason.label.enable": "启用",
  "ccm.agent.message.agenttypeinvalid": "座席类型不合法。",
  "ccm.agent.message.worknonoexit": "工号不存在.",
  "ccm.contactitemconfig.label.data.extended.set": "来源数据集",
  "ccm.agent.message.worknull": "工号为空.",
  "ccm.satisfactionconfig.message.update": "编辑",
  "ccm.agent.label.busy": "排队遇忙、溢出处理",
  "ccm.satisfactionconfig.message.surveystrategygroup": "调查策略",
  "ccm.speciallist.msg.exists": "所选用户已包含在特殊名单中",
  "ccm.recognition.label.modifymobileagentflowidnull": "流程ID为空",
  "ccm.agent.message.configvalidatefailed": "Gateway配置项校验失败",
  "ccm.agent.label.agentList": "业务帐号",
  "ccm.sysparam.error.validate.notmatchrulerange": "输入参数超出最大值或低于最小值",
  "ccm.calledconfig.message.willdeleteassociation": "确定删除来电原因关联信息吗",
  "ccm.urlconfig.label.numberAllocationMode1": "系统分配",
  "ccm.urlconfig.label.numberAllocationMode2": "接入商指定",
  "ccm.agent.label.ninetydays": "最近90天",
  "ccm.agent.url.params.tips": "请注意，URL GET请求传参的方式会将您的参数暴露在打开的地址中。",
  "ccm.contact.customer.config.visible": "是否可见",
  "ccm.agent.updateSoftPhone.accountPasswordFailed": "帐号密码校验失败",
  "ccm.contact.customer.config.preview": "预览",
  "ccm.agent.message.sysParamCofig": "系统参数配置失败",
  "ccm.custdata.operlog.success": "成功",
  "ccm.agent.leavemessage.inputauditcomments": "请输入处理意见",
  "ccm.agent.mobileagent.label.nodata": "没有对应的业务号码",
  "ccm.agent.operation.SAYBUSY": "示忙",
  "ccm.asr.create.case.text.info": "无文本内容，不支持一键创建工单",
  "ccm.note.config.agent.page.ring": "铃声",
  "ccm.agent.pageurls.moveup": "上移",
  "ccm.agent.leavemessage.notAgent": "帐号未绑定座席",
  "ccm.custdata.operlog.downloadTemplate": "下载基础表模板",
  "ccm.agent.pageurls.confdelete": "删除确认",
  "ccm.agent.password.agentNull": "座席信息为空",
  "ccm.agent.operation.TWOSTAGEDIALING": "二次拨号",
  "ccm.label.contactexport.nocount": "没有符合条件的接触记录",
  "ccm.label.dualcallexport.nocount": "没有符合条件的双呼记录",
  "ccm.recognition.label.processaccesscode": "流程接入码",
  "ccm.transtask.label.saturday": "周六",
  "ccm.custdata.label.columnNum": "字段个数",
  "ccm.agent.leavemessage.workNo": "客服代表",
  "ccm.agent.contact.callinandout": "双呼",
  "ccm.agent.contact.user.recall": "用户已撤回该消息",
  "ccm.agent.mobileagent.label.deletecomplete": "业务号码删除成功",
  "ccm.agent.calledroute.refresh": "刷新",
  "ccm.verifyidentity.fail.TIMEPARAMNULLERROR": "查询时间不能为空",
  "ccm.contactitemconfig.label.data.extended.dataset.name": "数据集名称",
  "ccm.agent.label.adjustTime": "整理态时长(秒)",
  "ccm.agent.contact.gateway.exception": "Gateway内部异常，请检查gateway配置",
  "ccm.speciallist.msg.success": "成功",
  "ccm.contact.detail.message.content": "消息内容",
  "ccm.custdata.msg.fileSizeValidateFail": "请选择一个小于20mb的文件。",
  "ccm.speciallist.label.reason": "加入原因",
  "ccm.callout.message.editfail": "编辑外呼主叫号码失败",
  "ccm.note.config.agent.page.ring.voice": "音视频来话提醒",
  "ccm.agent.restrecord.rest.beginTime": "休息开始时间",
  "ccm.agent.speciallist.validityPeriod": "生效时间段",
  "ccm.agent.speciallist.begintime": "生效时间",
  "ccm.transserverparam.label.bucket": "数据桶名称",
  "ccm.agent.calledroute.editfail": "本地数据编辑失败",
  "ccm.agent.contact.resourcefail": "加载录音出现异常",
  "ccm.satisfactionconfig.message.creategroup": "新增策略组",
  "ccm.agent.contact.calledhangup": "通话结束，被叫挂机",
  "ccm.agent.calledroute.skill": "技能队列",
  "ccm.sysparam.config.checkmessage": "含有特殊字符(\"<\",\">\",\"/\"等)",
  "ccm.contact.customer.config.regular": "常规",
  "ccm.satisfactionconfig.sms.satisfaction.survey": "短信满意度调查",
  "ccm.basicpage.not.sk.download": "没有下载SK权限",
  "ccm.agent.label.restQueueRule.lessRest": "当天休息时间比例少者优先休息",
  "ccm.recognition.label.interconnection.parameters": "对接参数",
  "ccm.agent.label.transparentDisplayFlag": "座席透显标识",
  "ccm.calledconfig.message.numbersinvalid": "低于1个或超过15个号码",
  "ccm.agent.operation.noMoreThan7Days": "范围不能超过7天",
  "ccm.agent.callreason.modify.success": "修改来电原因成功",
  "ccm.speciallist.msg.specialchar": "提醒消息不能包含特殊字符",
  "ccm.speciallevel.speciallist.use": "可用",
  "ccm.urlconfig.message.warning": "请在ITA侧确认已配置好话单推送URL，否则会影响ITA的话单推送到云客服，推送URL示例:",
  "ccm.custdata.option.status.draft": "草稿",
  "ccm.agent.contact.transferRecord": "转接记录",
  "ccm.pageinterface.viewAttribute": "查看对象属性",
  "ccm.urlconfig.message.addfail": "新增url配置失败",
  "ccm.skill.message.skillpara": "不能配置技能队列本身",
  "ccm.agent.label.callDispatchModel.average": "平均分配",
  "ccm.idauth.title.name": "验证方式名称",
  "ccm.agent.label.serviceAccountCode": "业务帐号",
  "ccm.page.label.pageInput": "请输入页面名称",
  "ccm.agent.label.thirtyonedays": "最近31天",
  "ccm.recognition.label.appSecret.download.appId.empty.error": "appId不能为空",
  "ccm.satisfactiontemplate.message.savefail": "满意度调查模板保存失败",
  "ccm.transtask.label.delete": "删除转储任务成功",
  "ccm.satisfactiontemplate.tips": "注：系统将使用租户默认语言生成模板内容；",
  "ccm.certificate.management.certificatefile.certpwderror": "证书密码有误",
  "ccm.agent.label.sensitiveWord": "违禁语！请注意用词。",
  "ccm.idauth.title.create": "新增身份验证流程",
  "ccm.speciallist.msg.typeidempty": "特殊名单类型不能为空",
  "ccm.agent.label.deviceType": "设备类型",
  "ccm.agent.label.ivr": "IVR",
  "ccm.agent.leavemessage.assign": "分配",
  "ccm.agent.leavemessage.title.assign": "分配留言",
  "ccm.custdata.operlog.popwindowmessage": "请输入用于生成压缩文件的密码,压缩密码应包含字母,数字,特殊字符 _ {'@'} {'%'}",
  "ccm.agent.label.bindTheSkill": "已绑定被叫配置，请先解绑被叫配置",
  "ccm.agent.label.groupUseTheSkill": "，请先删除技能组下该技能队列的配置",
  "ccm.agent.label.groupBind": "技能队列已绑定技能组",
  "ccm.transtask.label.month": "月",
  "ccm.agent.contact.gateway.fail": "请求Gateway异常，请检查gateway配置",
  "ccm.recognition.label.mobileagent": "移动座席/一键双呼标识",
  "ccm.urlconfig.label.relmethod": "挂机回调方法",
  "ccm.agent.contact.robothungup": "机器人",
  "ccm.agent.pageurls.maxcalling": "来电弹屏数量最大值为{limit}",
  "ccm.contact.customer.config.underline": "下划线",
  "ccm.contactitemconfig.message.confOnlyDataItemCode": "操作失败，数据项标识已存在",
  "ccm.agent.leavemessage.channel": "渠道",
  "ccm.vdn.label.index": "个月",
  "ccm.agent.message.updateAgentAndSkillFail": "更新座席技能队列关联关系失败",
  "ccm.pageinterface.selectedAttribute": "已选属性",
  "ccm.agent.calledroute.devicetype": "设备类型",
  "ccm.verifyidentity.SUCCESS": "通过",
  "ccm.contact.customer.config.backgroundColor": "背景色",
  "ccm.transserverparam.label.login.cssClusterUser": "集群登录用户名",
  "ccm.agent.label.parameterType": "排队等待方式",
  "ccm.agent.contact.talkRemark": "来电备注",
  "ccm.transtask.label.thurs": "周四",
  "ccm.pageinterface.cancelAll": "取消全部",
  "SM.ORGSTAFF.LABEL.ORGANIZATION": "组织机构",
  "ccm.agent.message.searchSkillName": "请输入技能队列名称",
  "ccm.recognition.label.intellagent": "智能座席助手",
  "ccm.label.pageName": "页面名称",
  "ccm.agent.ccagentinfo.unregister.fail": "注销失败",
  "ccm.agent.operation.CALLOUT": "外呼",
  "ccm.agent.calledroute.nmsfail": "同步NMS失败",
  "ccm.sysparam.error.validate.notmatchruledigits": "请输入合法的正整数",
  "ccm.agent.message.agentCTIFail": "在平台上查询要修改的座席失败，请检查gateway配置",
  "ccm.transtask.label.notEnabled": "未启用",
  "ccm.agent.button.cancel": "取消",
  "ccm.custdata.label.description": "描述",
  "ccm.agent.addbother.msg.emailOrPhoneValidate": "格式不符合要求",
  "ccm.basicdata.error.firstlinenotmatch": "导入文件首行与模板不一致",
  "ccm.agent.ten.dstOff": "关闭",
  "ccm.agent.ten.systemdefault": "系统默认时区",
  "ccm.agent.message.dataIntegrityFail": "页面布局或页面接口集数据配置不完整，操作失败",
  "ccm.contract.management.confirm": "文档包含敏感数据，是否继续操作？",
  "ccm.contract.management.confirmtitle": "确认操作",
  "ccm.speciallist.msg.restoresuccess": "恢复成功",
  "ccm.pageinterface.title": "页面接口",
  "ccm.agent.callreason.need": "请选择至少一个选择来电原因",
  "ccmanagement.satisfaction.strategy.fail.calltime.inputlength": "通话时间区间值长度必须小于12",
  "ccm.satisfactionconfig.message.version": "版本号",
  "ccm.agent.sysparamconfig.resttimenumberwarn": "休息时长必须在1到1440以内(不包括1440)",
  "ccm.agent.pageurls.edit": "编辑",
  "ccm.speciallist.tip.time.max": "请选择小于项目失效（2038-01-19）的时间",
  "ccm.agent.label.singlePhoneFlag": "单电话座席标识",
  "ccm.agent.message.addAIRecogFail": "开启智能座席的数量不能超过租户开通时分配的座席数",
  "ccm.agent.message.searchSipAccount": "请输入SIP软电话号码",
  "ccm.transtask.label.enable": "启用",
  "ccm.agent.restrecord.reststatus.timeout.endrest": "超时结束休息",
  "ccm.agent.operation.FORCEIDEL": "强制示闲",
  "ccm.agent.button.refresh": "刷新",
  "ccm.agent.updateSoftPhone.newPasswordFailed": "新密码校验失败",
  "ccm.agent.message.omsAgentTypeCheck": "在线开通的座席，选择的角色与座席类型不匹配",
  "ccm.agent.restReason.restCauseDesc": "原因描述",
  "ccm.satisfactionconfig.message.smstitle": "短信",
  "ccm.agent.message.videoNotEnough": "视频资源数不足，视频座席和配置相关视频技能队列的座席都占用视频资源数，请合理安排使用视频资源",
  "ccm.agent.ten.resourceinfor": "资源信息",
  "ccm.agent.label.deviceUsage": "设备占用率(%)",
  "ccm.speciallist.msg.warning": "警告",
  "ccm.custdata.option.maskStyle.maskBankAccountNum": "银行帐号掩码",
  "ccm.basicdata.msg.queryfail": "查询基础数据失败。",
  "ccm.agent.message.agenttypebothempty": "当前选中的座席存在未配置座席类型的座席，需配置座席类型",
  "ccm.agent.contact.multimedia": "多媒体",
  "ccm.agent.message.querycenterfailed": "查询呼叫中心智能化座席失败",
  "ccm.ucconfig.message.integration": "Microsoft Teams集成",
  "ccm.ucconfig.message.info.alert": "请复制以下地址到Microsoft进行应用程序注册，用作接受身份认证重定向URI。并将注册结果信息复制填写到下面注册应用信息。",
  "ccm.ucconfig.message.copy": "复制",
  "ccm.ucconfig.message.register": "注册应用信息",
  "ccm.ucconfig.message.required.fields": "必填项",
  "ccm.ucconfig.message.required.limit": "长度不能超过64",
  "ccm.verifyidentity.userId": "验证号码",
  "ccm.agent.pageurlparam.calldata": "呼叫数据",
  "ccm.custdata.option.status.abandon": "废弃",
  "ccm.agent.label.release": "释放",
  "ccm.agent.message.synToCcpFailed": "同步坐席认证方式到cc-provision失败",
  "ccm.satisfactionconfig.message.textmsg": "文本消息",
  "ccm.agent.pageurls.adressval": "访问地址需要以'https://'或'http://'开头",
  "ccm.agent.pageurls.adressval.noPoint": "访问地址需要以'https://'或'http://'开头",
  "ccmanagement.satisfaction.strategy.acceptdate.dateStart": "起始日期",
  "ccm.calledconfig.label.querysoftnum": "查询软电话号码",
  "ccm.agent.ten.tenantName": "租户名称",
  "ccm.callreason.label.createrelatecase": "关联工单",
  "ccmanagement.satisfaction.strategy.fail.accepttime.startafterend": "起始时间不可以大于或者等于结束时间",
  "ccm.agent.updateSoftPhone.updateSuccess": "密码修改成功",
  "ccm.agent.ten.DSTEndTime": "夏令时结束时间",
  "ccm.agent.input.userNumber": "用户号码",
  "ccmanagement.satisfaction.strategy.fail.calltime.startafterend": "通话时间区间下限值必须小于通话时间区间上限值",
  "ccm.skill.placeholder.skilldesc": "请输入技能队列名称",
  "ccm.sysparam.error.validate.notmatchruleip": "请输入合法的IPV4或IPV6地址",
  "ccm.contact.label.signed": "已签名",
  "ccm.ucconfig.message.failed": "失败",
  "ccm.pageurls.select.internalInterface": "内部页面",
  "ccm.agent.calledroute.refreshsuccess": "刷新成功。",
  "ccm.transtask.label.ivrAccessCodeReport": "IVR接入码流量报表",
  "ccm.contact.customer.config.writeBackField": "回写接触记录扩展字段",
  "ccm.verifyidentity.label": "身份验证",
  "ccm.agent.calledroute.ccivrdata.error": "同步NMS失败，CC-IVR流程数据有误",
  "ccm.certificate.management.search.certcode": "请输入证书编码",
  "ccm.systemparam.error.reset": "参数重置失败",
  "ccm.sysparam.config.item.detail": "参数信息",
  "ccm.agent.contact.multimedia.facebook": "多媒体-Facebook ",
  "ccm.certificate.management.certificate.tip": "注：",
  "ccm.agent.pageurls.sernum": "序号",
  "ccm.whatsapp.whatsApp.message.template": "WhatsApp消息模板",
  "ccm.agent.message.aiagentclosed": "租户智能识别特性未开启，请联系系统管理员",
  "ccm.agent.callreason.exceedmaxnum": "来电原因最多允许选择5个",
  "ccm.agent.label.configAdmin": "配置管理员",
  "ccm.satisfactionconfig.message.satisreport": "满意度调查报告",
  "ccm.agent.label.maxTalkTimeRange": "取值 [1-86400]",
  "ccm.certificate.management.certificatefile.typeerror": "文件类型不正确",
  "ccm.agent.button.save": "保存",
  "ccm.agent.restReason.restCauseCode": "原因码",
  "ccm.agent.message.agentandskillnotmatch": "技能队列跟座席类型不匹配",
  "ccm.label.page.delete.confirm": "确认要删除吗?",
  "ccm.custdata.operlog.addExportTaskSuccess": "新增导出任务成功",
  "ccm.calledconfig.message.overmaxnum": "已达到最大数量{0}.",
  "ccm.custdata.option.maskStyle.maskIdCardNum": "身份证号掩码",
  "ccmanagement.tenantspace.option.weeklist_fir": "第1个",
  "ccm.systemparam.success.reset": "参数重置成功",
  "ccm.agent.updateSoftPhone.unFoundInfo": "呼叫中心未同步",
  "ccm.sysparam.error.validate.notmatchrulerequired": "必填项",
  "ccm.custdata.operlog.uploadDataFile": "上传基础表数据文件",
  "ccm.whatsapp.select.whatsApp.channel": "请选择一个whatsapp渠道",
  "ccm.transserver.label.proxynotinwhite": "操作失败，请联系系统管理员将代理服务器地址加入资源转储OBS服务器代理白名单",
  "ccm.agent.leavemessage.cannotBeModified": "当前留言状态不允许修改",
  "ccm.dual.call.record.endTime": "呼叫结束时间",
  "ccm.transtask.label.tasknameexits": "新建任务失败，该任务名称已存在",
  "ccm.recognition.label.anonymous": "匿名外呼标识",
  "ccm.custdata.option.status.release": "发布",
  "ccm.contact.customer.config.key": "数据键值",
  "ccm.custdata.msg.tableExistInstData": "自定义表当前已存在实例数据，不支持撤回再编辑",
  "ccm.transtask.config.pathvalidate": "转储文件路径不能包含'./'",
  "ccm.custdata.operlog.downloadData": "下载基础表数据",
  "ccm.agent.label.queuingCallsMaxNumRangeTwo": "取值 [1-10000]",
  "ccm.speciallist.msg.sltype": "特殊名单类型",
  "ccm.satisfactionlevel.message.resetsuccess": "满意度等级、满意度调查模板重置成功，如满意度等级有修改且已配置满意度流程，请到”流程管理“中重新发布满意度流程，否则满意度结果展示将会有误，新的满意度调查会以这次配置的结果为基础",
  "ccm.satisfactionlevel.title.templateupdate": "按系统模板更新",
  "ccm.speciallist.msg.userno.error": "用户号码输入格式错误",
  "ccm.satisfactionconfig.message.choosestrategy": "*选择满意度调查策略",
  "ccm.systemparam.error.notmatchrule": "参数不满足校验规则",
  "ccm.agent.leavemessage.process": "处理",
  "ccm.agent.label.skillQueue": "技能队列:",
  "ccm.certificate.management.certificatefile.maxrecord": "证书数量最大限制为{0}",
  "ccm.contact.detail.sending.time": "发送时间",
  "ccm.agent.message.searchAgentServiceNo": "请输入业务号码\t",
  "ccm.agent.calledroute.create.gateway.none": "被叫配置引用的路由脚本不存在",
  "ccm.agent.label.sipAccount": "软电话号码",
  "ccm.agent.updateSoftPhone.updateGatewayFailed": "修改gateway侧密码异常",
  "ccm.recognition.label.openVoiceNotificationSuccess": "开启语音通知成功",
  "ccm.agent.sysparamconfig.verifyTemplateIdLength": "站内信模板ID必须是整数，并且不能为负数",
  "ccm.agent.message.validatordigit": "单电话座席设置密码应为纯数字",
  "ccm.agent.leavemessage.messageprocess": "处理留言",
  "ccm.agent.label.agentType": "座席类型",
  "ccm.recognition.label.voiceNotification": "语音通知标识",
  "ccmanagement.tenantspace.option.weeklist_sec": "第2个",
  "ccm.agent.message.deleteSkill": "删除技能队列",
  "ccm.agent.label.notselectcallreason": "请选择来电原因",
  "ccm.recognition.label.orderfailwhilte": "订阅失败，同步白名单失败",
  "ccm.urlconfig.label.relurl": "挂机回调URL",
  "ccm.agent.message.modifySkillFail": "更新技能队列失败",
  "ccm.transtask.label.contactRecord": "接触记录",
  "ccm.transserver.discard.confirm": "确认要废弃该任务吗？",
  "ccm.satisfactionconfig.message.media": "多媒体渠道",
  "ccm.satisfactionconfig.message.groupbatchdelete": "批量删除",
  "ccm.label.page.modifyPage": "修改页面",
  "ccm.speciallist.msg.limit": "导入内容数量超出1000条限制",
  "ccm.custdata.title.modify": "编辑基础表",
  "ccm.satisfactionconfig.web.switch.remark": "服务结束后系统将自动为客户推送满意度调查",
  "ccm.agent.label.authtype.uap": "UAP认证",
  "ccm.idauth.create.error.limit": "身份验证流程只支持创建5个",
  "ccm.whatsapp.template.message.id": "模板消息ID",
  "ccm.agent.contact.talkReason": "来电原因",
  "ccm.satisfactionconfig.message.createsuccess": "新增满意度调查配置成功",
  "ccm.satisfactionservice.evaluationerror": "评价失败",
  "ccmanagement.satisfactionconfig.calltime.timeerror.laterThanEndTime": "通话时长区间下限值必须小于通话时长区间上限值",
  "ccm.transtask.label.restores": "恢复",
  "ccm.pageinterface.inputInterfaceName": "请输入",
  "ccm.agent.contact.startcallfailed": "启动呼叫失败",
  "ccm.agent.message.deleteSkillConfSuccess": "技能队列信息删除成功",
  "ccm.transtask.label.taskname": "任务名称",
  "ccm.custdata.operlog.conditions": "查询条件",
  "ccm.label.contactexport.selectdata": "选择数据",
  "ccm.agent.message.agenttypecannotbenull": "当前已选择技能队列，需选择座席类型",
  "ccm.agent.pageurlparam.relSysParamType": "关联系统数据参数类型",
  "ccm.agent.label.inputendworkno": "请输入结束工号",
  "ccm.calledconfig.message.willdeletemobileagent": "确定删除所选的移动客服配置吗",
  "ccm.agent.message.pageNameValidateFail": "不允许包含特殊字符",
  "ccm.agent.message.itemNameValidateFail": "不允许包含特殊字符&<>\";%/[]=+{'@'}",
  "ccm.label.page.createPage": "新建页面",
  "ccm.agent.sysparamconfig.refreshAllSysParamSuccess": "刷新成功",
  "ccm.recognition.label.openmobileagentsuccess": "开启移动座席和一键双呼成功",
  "ccm.callreasonconfig.message.deletecallreason.failed": "删除失败",
  "ccm.speciallist.msg.dateerror": "需要输入生效时间和失效时间",
  "ccm.agent.contact.assistant": "协同呼叫",
  "ccm.custdata.operlog.authFailed": "鉴权失败",
  "ccm.agent.label.useTheSkill": "技能队列{0}已绑定坐席工号{1}，请先删除坐席下该技能队列的配置",
  "ccm.agent.sysparamconfig.worknowarn": "座席工号请以逗号分隔",
  "ccm.agent.ten.agentSipInfo": "座席注册服务器地址及端口",
  "ccm.agent.title.agentinfo": "座席信息",
  "ccmanagement.satisfaction.strategy.acceptdate.startdate": "起始日期",
  "ccm.satisfactionconfig.message.calltype": "呼叫类型",
  "ccm.dual.call.record.option": "操作",
  "ccm.botherlist.label.comment": "意见",
  "ccm.agent.contact.playVideo": "播放视频",
  "ccm.callout.message.confreset": "重置确认",
  "ccm.custdata.label.isMandatory": "是否必填",
  "ccm.note.config.agent.page.ring.title": "此配置仅适用于当页面被隐藏时，如浏览器最小化，或页面在后台标签页。",
  "ccm.calledconfig.table.skillormumbers": "目的设备",
  "ccm.agent.restrecord.reststatus.normal.endrest": "正常结束休息",
  "ccm.agent.label.playVoice": "播放等待音",
  "ccm.agent.calledroute.confdelete": "删除确认",
  "ccm.contact.label.unsigned": "未签名",
  "ccm.agent.label.createWorkOrdeType": "关联工单类型",
  "ccm.satisfactionlevel.message.savefail": "满意度等级保存失败",
  "ccm.transtask.label.datacondition": "数据过滤条件",
  "ccm.transtask.label.agentOutBoundReport": "座席外呼报表",
  "ccm.agent.message.warning": "警告",
  "ccm.agent.sysparamconfig.multimediawarn": "多媒体并发数必须在1到60之间",
  "ccm.systemparam.success.save": "参数保存成功",
  "ccm.custdata.operlog.exportiong": "导出中",
  "ccm.agent.calledroute.errorsave": "错误",
  "ccm.agent.message.searchsoftphone": "请输入软电话号码",
  "ccm.agent.contact.calltime": "通话时长(s)",
  "ccm.agent.button.downloadvideoterminal": "下载OpenEye",
  "ccm.agent.button.downloadvrc": "下载VRC",
  "ccm.calledconfig.label.numbers": "号码",
  "ccm.custdata.label.isIndex": "是否作为索引",
  "ccm.agent.message.synToUapFailed": "同步座席认证方式到UAP失败",
  "ccm.sysparam.error.validate.notmatchrulemaxlength": "参数最大长度",
  "ccm.urlconfig.label.talkmethod": "接通回调方法",
  "ccm.custdata.label.columnName": "字段名称",
  "ccm.agent.message.nocomments": "暂无备注",
  "ccm.agent.message.willDeleteAgents": "确定删除所选的座席吗？",
  "ccm.transserverparam.label.info": "基本信息",
  "ccm.custdata.msg.columnNoMatch": "表字段与库中不一致，请刷新后再重试",
  "ccm.agent.contact.recordingfiles": "录音文件",
  "ccm.agent.message.selectMaxAgents": "一次性最多只能处理20个",
  "ccmanagement.satisfaction.strategy.fail": "失败",
  "ccm.agent.message.existAIAgent": "智能识别和双轨道录音不能同时开启，批量修改的座席中存在已开启智能识别的座席",
  "ccm.agent.label.fixedValue": "固定值",
  "ccm.satisfactionconfig.message.delete": "删除",
  "ccm.agent.operation.FORCELOGOUT": "强制退出",
  "ccm.agent.mobileagent.notice": "提示",
  "ccm.certificate.management.certificate.crlfile.sizeerror": "证书吊销文件大小不能超过2048K",
  "ccm.agent.operation.optime": "操作时间",
  "ccm.agent.title.specialListReminder": "特殊名单提醒",
  "ccm.recognition.label.fail": "失败",
  "ccm.certificate.management.certificatefile.certsceneerror": "证书场景有误",
  "ccm.agent.calledroute.pagename": "页面名称",
  "ccm.satisfactionconfig.message.strategygroupsrv": "满意度调查策略服务渠道配置",
  "ccm.agent.leavemessage.channelType": "渠道类型",
  "ccm.transserverparam.label.agentport": "代理服务器端口",
  "ccm.transserverparam.label.agentip": "代理服务器地址",
  "ccm.agent.label.dualChannelRecFlag": "双轨道录音",
  "ccm.custdata.operlog.operation": "操作",
  "ccmanagement.satisfaction.strategy.fail.acceptdate.overlimit": "受理日期策略最多只能保存100条",
  "ccm.agent.operation.HOLD": "保持",
  "ccm.satisfactionconfig.message.duplicateconfig": "同渠道下配置优先级不能相同",
  "ccm.custdata.option.columnType.time": "时间",
  "ccm.satisfactionconfig.message.callskill": "技能队列策略",
  "ccm.callreason.label.setcallreason": "来电原因设置",
  "ccm.satisfactionconfig.message.audiofeedbacktype": "IVR语音",
  "ccm.speciallist.msg.level": "特殊名单级别",
  "ccm.callout.placeholder.caller": "请输入外呼主叫号码",
  "ccm.basicdata.error.syserror": "系统异常错误，请联系维护人员",
  "ccm.certificate.management.certificatefile.uploadcertcrlfile": "上传证书吊销文件",
  "ccm.satisfactionconfig.message.acceptagent": "受理座席",
  "ccm.satisfactionconfig.message.channelstrategyconfig": "渠道满意度策略配置",
  "ccm.agent.resetskillrecord.adjustagentworkno": "调整座席工号",
  "ccm.speciallist.msg.alreadyexist": "已存在类型与级别都相同的特殊名单提醒",
  "ccm.contactitemconfig.label.data.item.basicext": "基础扩展数据",
  "ccm.callout.title.success": "成功",
  "ccmanagement.tenantspace.option.weeklist_fth": "第4个",
  "ccm.querycustdata.msg.selectTable": "请选择表名",
  "ccm.custdata.label.template": "_模板",
  "ccm.agent.label.registrationMessage": "注册信息",
  "ccm.transserver.label.serveraddr": "服务器地址",
  "ccm.agent.button.submit": "提交",
  "ccm.agent.calledroute.create.accesscode.noexist": "被叫配置引用的接入码不存在",
  "ccm.custdata.option.isMandatory.yes": "必填",
  "ccm.note.config.filepath": "指定提示音",
  "ccm.agent.contact.multimedia.telegram": "多媒体-Telegram",
  "ccm.verifyidentity.verifyTime": "验证时间",
  "ccm.agent.contact.playLeaveMessage": "播放留言",
  "ccm.datatask.checkNum": "输入值为正整数",
  "ccm.agent.Transfer.Intercept": "拦截",
  "ccm.satisfactionconfig.message.smschannel": "短信发送渠道",
  "ccmanagement.satisfaction.strategy.calltime.maxvalue": "上限值",
  "ccm.agent.conditionOfBusyTransfer.error": "请配置忙转条件参数信息",
  "ccm.agent.resetskillrecord.skillbeforeadjust": "调整前技能队列",
  "ccm.agent.label.mobileagententer": "请输入",
  "ccm.agent.label.expired": "已过期",
  "ccm.agent.pageurls.createcall": "新建来电弹屏",
  "ccm.agent.pageurls.config.screen.pop.up": "配置弹屏页面",
  "ccm.agent.pageurls.unsaved.data.exists": "存在未保存的数据，请先保存",
  "ccm.agent.label.preVoiceSelect": "报工号前音",
  "ccm.accesscode.label.accesscode": "接入码",
  "ccm.speciallevel.speciallist.levelMessage": "级别管理",
  "casemanagement.user.title.name": "用户名",
  "ccm.satisfactionconfig.message.confirm": "确认",
  "ccm.note.config.agent.page.ring.tipTitle": "若配置弹框提醒，在接续被集成时，需座席端在浏览器上手动配置\"允许此网站使用弹出式窗口\"，弹框才有效。",
  "ccm.speciallist.tip.startTime.min": "生效时间必须大于当前时间",
  "ccm.agent.label.usedMode": "类型",
  "ccm.agent.calledroute.operation": "操作",
  "ccm.agent.title.batchagentEdit": "批量座席信息配置",
  "ccm.contactitemconfig.label.data.item.category": "字段类别",
  "ccm.agent.contact.allcalltype": "所有呼叫类型",
  "ccm.basicdata.msg.nodatamodify": "没有数据需要修改",
  "ccm.basicdata.msg.modifyfail": "修改基础数据失败",
  "ccm.speciallist.msg.approve": "审批",
  "ccm.speciallist.label.approve": "审批意见",
  "ccm.speciallist.poptitle.approve": "审批骚扰记录",
  "ccm.speciallist.poptitle.view": "查看骚扰记录",
  "ccm.speciallist.title.approve": "审批信息",
  "ccm.agent.button.clear": "重置",
  "ccm.basicdata.error.databaseerror": "索引数据重复导致批量入库失败",
  "ccm.recognition.label.openanonymoussuccess": "开启匿名外呼标识成功，请选择绑定被叫配置的点击通话接入码",
  "ccm.satisfactionconfig.message.channeltype": "渠道类型",
  "ccm.sysparam.error.validate.notmatchrulemin": "输入参数低于最小值",
  "ccm.satisfactionconfig.param.faile": "参数错误",
  "ccm.agent.updateSoftPhone.updateFailed": "密码修改失败",
  "ccm.agent.calcOfMaxCallNum.error": "请配置最大呼叫排队数目参数信息",
  "ccm.contact.tip.not.get.workname": "未获取到坐席对应帐号名称！",
  "ccm.contactitemconfig.message.DatasetName.inuse": "数据集已在接触记录数据项配置中使用，不能删除",
  "ccm.sysparam.url.safe.tip": "使用不安全协议HTTP，存在安全风险",
  "ccm.agent.label.keepWaitType": "保持等待方式",
  "ccm.transtask.label.tasktype": "任务类型",
  "casemanagement.common.label.nodata": "无数据",
  "ccm.agent.label.Account": "业务帐号",
  "ccm.callout.lable.type": "参数类型",
  "ccm.custdata.option.columnType.date": "日期",
  "ccm.agent.calledroute.create.media.match": "被叫配置的系统接入码同被叫配置设备的媒体类型不一致",
  "ccm.agent.label.noaireconition": "否",
  "ccm.agent.label.maxWaitTime": "最大等待时间(秒)",
  "ccm.transserver.label.cssnotinwhite": "操作失败，请联系系统管理员将CSS服务地址加入资源转储CSS服务器白名单",
  "ccm.chatbot.label.assist": "机器人助手",
  "ccm.verifyidentity.loginId": "业务帐号",
  "ccm.custdata.button.moveTop": "置顶",
  "ccm.agent.message.agentUpdateFail": "更新记录失败。",
  "ccm.transtask.label.task.chatrecordall.uniqueness": "已有渠道类型不可再添加全渠道类型",
  "ccm.satisfactionconfig.message.strategygroupdesc": "策略组描述",
  "ccm.agent.message.clear": "清空",
  "ccm.agent.restrecord.rest.reason": "休息原因",
  "ccm.skill.label.skilldesc": "描述",
  "ccm.calledroute.button.finish": "完成",
  "ccm.pageinterface.interfaceType.internal": "内部接口",
  "ccm.agent.label.operate": "操作",
  "ccm.transtask.label.ivrReport": "IVR流量报表",
  "ccm.agent.contact.contactno": "接触编号",
  "ccm.agent.resetskillrecord.adjustedbusinessaccount": "被调整业务帐号",
  "ccm.agent.label.selfPhoneNotNull": "开通座席透显特性时，座席固话或手机不能为空",
  "ccm.agent.title.agentpassword": "帐号密码",
  "ccmanagement.tenantspace.option.weeklist_sectolast": "倒数第2个",
  "ccm.agent.title.serviceAccount": "关联Service Cloud帐号",
  "ccm.urlconfig.label.oauth.ak": "AK",
  "ccm.agent.button.delete": "删除",
  "ccm.vcallcenter.config.popup.authentication": "身份验证",
  "ccm.note.config.searchnotename": "请输入需要查询的名称",
  "ccm.custdata.operlog.addExportTaskError": "新增导出任务失败,请检查日志",
  "ccm.sysparam.error.validate.notmatchruleurl": "请输入合法的URL地址",
  "ccm.agent.restReason.create": "新增",
  "ccm.agent.softPhoneStatus": "软电话号码状态",
  "ccm.agent.ten.DSTStartDate": "夏令时开始日",
  "ccm.anonymous.message.fail.save": "保存被叫配置失败，请检查CTI配置",
  "ccm.agent.label.video": "视频座席",
  "ccm.sysparam.error.validate.notmatchrulemax": "输入参数超出最大值",
  "ccmanagement.tenantspace.option.weeklist_fthtolast": "倒数第4个",
  "ccm.agent.title.specialListHis": "历史特殊名单",
  "ccm.custdata.operlog.specCharValidate": "不能包含除 _ @ % 以外的特殊字符",
  "ccm.agent.message.selectAgents": "请选择座席",
  "ccm.urlconfig.message.overlimit.appId": "ITA双呼配置的accessKey超过128个字符",
  "ccm.agent.contact.joinreason": "加入原因",
  "ccm.agent.contact.SFTP_DOWNLOAD_FAIL_CODE": "从sftp服务器下载失败",
  "ccm.agent.message.redislocknull": "获取Redis锁失败",
  "ccm.sysparam.error.validate.inner": "校验参数失败",
  "ccm.sysparam.config.reset": "重置",
  "ccm.urlconfig.message.deletefail": "重置url配置失败",
  "ccm.agent.label.waitAgentWeight": "请输入座席权值",
  "ccm.agent.contact.info": "文档信息",
  "ccm.calledroute.message.selectcalledroute": "请选择被叫配置",
  "ccm.agent.login.LOGIN": "签入",
  "ccm.custdata.msg.operationSuccess": "操作成功",
  "ccm.agent.contact.recordInfo": "录音文件详情",
  "ccm.recognition.label.modifyVoiceNotificationClose": "租间尚未开启语音通知特性",
  "ccm.agent.operation.CANCELLISTEN": "取消监听",
  "ccm.agent.mobileagent.success": "操作成功",
  "ccm.agent.contact.SFTP_INCORRECT_FILE_CODE": "sftp服务器信息有误",
  "ccm.agent.pageurls.whitelisturl": "， 请联系系统管理员将url地址加入弹屏白名单",
  "ccm.agent.ten.cobrowseMaxNumber": "最大网页协同连接数",
  "ccm.idauth.title.ivr": "验证流程",
  "ccm.speciallist.msg.selectslreminder": "请选择特殊名单提醒",
  "ccm.agent.contact.email": "邮件",
  "ccm.agent.contact.Email": "邮件",
  "ccm.pageurls.select.webApi": "Web API",
  "ccm.callreason.label.name": "名称",
  "ccm.agent.calledroute.createcalledroute": "新增",
  "ccm.taskrecord.label.executing": "执行中",
  "ccm.agent.label.skillpara": "参数配置",
  "ccm.skill.label.maxwaittime": "最大排队时间",
  "ccm.recognition.label.appSecret.download.SK.not.exist": "SK信息不存在",
  "ccm.contactitemconfig.label.data.item.contact.list.visibility.yes": "是",
  "ccm.agent.contact.subtype.none": "无",
  "ccm.agent.leavemessage.lengthcodewarn": "长度不能超过300",
  "ccm.agent.mobileagent.confirm": "请确认",
  "ccm.recognition.label.save": "保存",
  "ccm.agent.updateSoftPhone.unErrorInfo": "系统异常",
  "ccm.basicdata.msg.error": "错误",
  "ccm.custdata.operlog.passValidateFailed": "需包含字母,数字,特殊字符 _ {'@'} {'%'}",
  "ccm.urlconfig.modify.oauth.ak.tooshort": "AK长度不少于16个字符",
  "ccm.agent.message.createSkillConfSuccess": "创建技能队列信息成功",
  "ccm.dual.call.record.statistics": "统计",
  "ccm.agent.ccagent.qcmodify.fail": "质检员请选择音频座席类型",
  "ccm.agent.contact.norecording": "没有录音",
  "ccm.speciallist.msg.delete": "删除",
  "ccm.satisfactionconfig.message.strategychoosed": "已选成员策略",
  "ccm.pageinterface.selectAttributeTitle": "选择对象属性",
  "ccm.agent.pageurls.movedown": "下移",
  "ccm.agent.label.maxWaitTimeSpan": "取值 [1-60000]",
  "ccm.custdata.msg.dictionaryInputFormat": "输入格式：键值1:值1,键值2:值2",
  "ccm.speciallist.msg.empty": "提醒消息不能为空",
  "ccm.contactitemconfig.message.confOnlyDataItemName": "操作失败，数据项名称已存在",
  "ccm.agent.pageurls.confnaming": "，请确保界面名称是否已存在",
  "ccm.agent.login.FORCELOGIN": "强制签入",
  "ccm.transtask.label.pause": "暂停",
  "ccm.agent.pageurlparam.urlParamName": "弹屏URL传递参数",
  "ccm.agent.accesscode.n400number": "服务号码",
  "ccm.satisfactionconfig.satisfaction.switch": "满意度开关",
  "ccm.custdata.button.withdraw": "撤回",
  "ccm.ucconfig.message.success": "成功",
  "ccm.agent.label.off": "关闭",
  "ccm.calledconfig.message.casetype": "工单类型",
  "ccm.satisfactionconfig.message.configstrategydata": "配置策略数据",
  "ccm.speciallist.label.blocklist": "黑名单",
  "ccm.agent.contact.customername": "客户名称",
  "CCM.AGENT.CONTACT.ORG": "座席组织机构",
  "ccm.agent.contact.agent.recall": "座席已撤回该消息",
  "ccm.agent.calledroute.placeholder.ivr": "请选择IVR",
  "ccmanagement.satisfaction.strategy.fail.acceptdate.inputparse": "起始日期和结束日期参数格式错误",
  "ccm.agent.operation.ID": "操作编号",
  "ccm.agent.operation.HELP": "求助",
  "ccm.speciallist.button.cancel": "取消",
  "ccm.agent.leavemessage.status.inProcess": "处理中",
  "ccm.certificate.management.certificate.crlfile.crlfileinvalid": "证书吊销文件格式错误",
  "ccm.agent.button.ok": "确认",
  "ccmanagement.satisfaction.strategy.success": "成功",
  "ccm.custdata.button.release": "发布",
  "ccm.agent.ten.daylightSavingTime": "夏令时",
  "ccm.agent.message.samePageNameFail": "存在相同的页面名称",
  "ccm.satisfactionlevel.message.savesuccess": "满意度等级、满意度调查模板保存成功，如满意度等级有修改且已配置满意度流程，请到“流程管理”中重新发布满意度流程，否则满意度结果展示将会有误，新的满意度调查会以这次配置的结果为基础",
  "ccm.custdata.option.importFail": "导入处理失败",
  "ccm.satisfactionconfig.message.staistip1": "开启时，在座席挂机后可自动转IVR满意度调查",
  "ccmanagement.tenantspace.option.weekday_wed": "星期三",
  "ccm.agent.mobileagent.fail": "操作失败",
  "ccm.label.contactexport.parainnull": "请选择要导出的数据",
  "ccm.agent.restReason.operation": "操作",
  "ccm.agent.videoDownload.noPermission": "当前座席无权下载",
  "ccm.custdata.option.isEncrypt.no": "不加密",
  "ccm.satisfactionconfig.message.success": "成功",
  "ccm.satisfactionconfig.message.strategygroupmemchoose": "请选择成员策略",
  "ccm.speciallist.operlog.downloadtemplate": "下载模板",
  "ccm.custdata.msg.indexColumnValidateFailed": "若作为索引字段需必填且不能加密",
  "ccm.custdata.operlog.viewExportTask": "导出任务查看",
  "ccm.satisfactionconfig.message.surveyconfig": "调查配置",
  "ccm.agent.message.modifymobileagentfail": "移动座席软电话号码信息修改失败",
  "ccm.ivr.label.processfilename": "文件名",
  "ccm.custdata.msg.tableBeUsed": "自定义表当前被配置页面对象引用，请确认未被引用后再进行此操作",
  "ccm.agent.contact.agenthungup": "座席",
  "ccm.agent.message.typenosame": "技能队列类型必须一致",
  "ccm.agent.contact.contactinfo": "接触信息",
  "ccm.agent.leavemessage.paramisempty": "参数为空",
  "ccm.agent.leavemessage.opinionempty": "处理意见必填",
  "ccm.agent.message.queryctiagentmessage": "查询平台座席信息失败",
  "ccm.agent.calledroute.delsuccess": "删除成功",
  "ccm.satisfactionconfig.message.webfeedbacktype": "Web调查",
  "ccm.agent.operation.SAYFREE": "示闲",
  "ccm.callout.message.choosecaller": "请选择外呼主叫号码",
  "ccm.satisfactionconfig.message.smsgateway": "短信网关",
  "ccm.contactitemconfig.message.init.basicdata.success": "初始化基础数据成功",
  "ccm.agent.ccagent.modify.fail": "智能识别和双轨道录音不能同时打开",
  "ccm.agent.label.mediaagent": "多媒体座席",
  "ccm.anonymous.message.fail.cti": "查询平台匿名外呼流程失败",
  "ccm.custdata.label.relaBaseColumn": "对应基础表字段",
  "ccm.agent.contact.invalid": "失效",
  "casemanagement.user.title.select": "选择",
  "ccm.custdata.msg.notAllowOperation": "不允许此操作，请刷新再重试",
  "ccm.basicdata.error.suffix": "。",
  "ccm.agent.label.paramsConfig": "系统参数配置",
  "ccm.agent.operation.noMoreThan90Days": "范围不能超过90天",
  "ccm.agent.contact.workno": "工号",
  "ccm.agent.label.answerType": "业务代表应答方式",
  "ccm.agent.verify.fail": "校验失败",
  "ccm.note.config": "提示音配置",
  "ccm.note.select": "选择提示音",
  "ccm.agent.restReason.restCauseTime.limit": "休息时长需要大于0秒小于24小时",
  "ccm.skillcreate.maxnumber.failed": "租户技能队列数量不能超过限制：",
  "ccm.taskrecord.label.executeStatus": "任务状态",
  "ccm.pageinterface.interfaceName": "接口名称",
  "ccm.verifyidentity.fail.VERIFYTIMERANGEERROR": "查询的时间范围不正确",
  "ccm.page.label.status": "页面状态",
  "ccm.agent.operation.UNMUTE": "取消静音",
  "ccm.sysparam.error.save": "错误",
  "ccm.ivrflow.placeholder.ivrflowdesc": "请输入流程描述",
  "ccm.agent.message.redisFailed": "获取Redis锁失败",
  "ccm.satisfaction.message.resetconfirm": "确认是否重置满意度等级和满意度调查模板内容？各渠道需要使用重置后的满意度等级和模板内容",
  "ccm.calledconfig.message.deletemobileagentfail": "移动客服配置删除失败",
  "ccm.recognition.label.clicktocallaccesscode": "点击通话接入码",
  "ccm.whatsapp.select.whatsApp.message.template": "请选择一个WhatsApp消息模板",
  "ccmanagement.tenantspace.option.weekday_sat": "星期六",
  "ccm.sysparam.error.validate.notmatchrulenumber": "请输入数字",
  "ccmanagement.satisfaction.strategy.save": "保存",
  "ccm.agent.pageurls.errorinfo": "创建失败",
  "ccm.agent.operation.SWITCH": "质检切换",
  "ccm.label.contactexport.selectrange": "选择范围",
  "ccm.transserver.delete.time.fail": "请在任务废弃后的十分钟后进行任务删除",
  "ccm.transserver.label.css": "CSS服务器",
  "ccm.agent.label.serviceAccountId": "关联的Service Cloud帐号ID",
  "ccm.agent.message.deleteAgentSkillFail": "删除座席技能队列关联关系失败",
  "ccmanagement.satisfaction.strategy.hourformatvalidate": "配置的小时数据格式错误",
  "ccm.agent.message.isornoairecognition": "智能化状态",
  "ccm.agent.ten.phonenumber": "手机号",
  "ccm.page.select.tileLayout": "平铺布局",
  "ccm.agent.label.sensitiveWordNumber": "违禁语",
  "ccm.agent.sysparamconfig.worknorangewarn": "座席工号必须在101到50000之间",
  "ccm.satisfactionconfig.message.strategygroupid": "策略组id",
  "ccm.recognition.label.closeVoiceNotificationSuccess": "关闭语音通知成功",
  "ccm.satisfactionconfig.ivr.voice.satisfaction.survey": "IVR语音满意度调查",
  "ccm.agent.ten.recordtime": "录音保持周期",
  "ccm.operlog.contactexport.download": "下载接触记录",
  "ccm.agent.operation.FORCEDROPCALL": "强制释放",
  "ccm.agent.agentcontact.nosearch": "非业务受理中无法查询",
  "ccm.agent.recall.agent.calltype.invalid": "当前呼叫类型不支持回呼操作",
  "ccm.agent.ten.vdnid": "VDN ID",
  "ccm.agent.sysparamconfig.hasSelectAgentWorkLimit": "已选座席号段不能超过五个",
  "ccm.contactitemconfig.message.itemValidateall": "请输入下划线（_）、美元符号（$）、字母或者数字",
  "ccm.note.config.policy": "座席回铃音",
  "ccmanagement.tenantspace.option.weeklist_tid": "第3个",
  "ccm.speciallist.msg.disable.fail": "停用失败",
  "ccm.agent.label.ordinaryAgent": "普通座席",
  "ccm.agent.label.paramValue": "参数值",
  "ccm.transserverparam.label.cssClusterPass": "密码",
  "ccm.agent.label.agentWeight": "座席权值",
  "ccm.satisfactionlevel.message.resetfail": "满意度等级重置失败",
  "ccm.agent.calledroute.recordexist": "该接入码与扩展码已绑定，请输入新的扩展码",
  "ccm.agent.contact.authHandle": "请联系相关管理人员",
  "ccm.custdata.button.return": "返回",
  "ccm.agent.label.busyRate": "转接队列占用率限制(%)",
  "ccm.urlconfig.message.talkurlnotinwhite": "操作失败，请联系系统管理员将接通回调url加入手机客服回调白名单",
  "ccm.agent.calledroute.edit": "编辑",
  "ccm.satisfactionconfig.message.prioritytip": "*数字越小，优先级越高，优先匹配策略，执行调查",
  "casemanagement.user.button.cancel": "取消",
  "ccm.custdata.option.isEncrypt.yes": "加密",
  "ccm.custdata.label.failNum": "失败记录数",
  "ccm.custdata.label.dualCall.sucNum": "呼叫成功数",
  "ccm.custdata.label.dualCall.failNum": "呼叫失败数",
  "ccm.speciallist.msg.deleteyerror": "特殊名单删除失败",
  "ccm.contact.customer.config.italic": "斜体",
  "ccm.agent.calledroute.delete.skill.use": "被叫配置被技能队列引用，不能删除",
  "ccm.transtask.label.sunday": "周日",
  "ccm.agent.label.waitVoiceDefault": "默认等待音",
  "ccmanagement.satisfaction.strategy.calltime.interval": "通话时长区间",
  "iolp.configur.service.label.none.desc": "暂无业务引导配置，请先创建业务引导分类",
  "ccm.urlconfig.message.relurlnotinwhite": "操作失败，请联系系统管理员将挂机回调url加入手机客服回调白名单",
  "ccm.speciallevel.speciallist.restrictHours": "限制时长（小时）",
  "ccm.agent.contact.multimedia.twitter": "多媒体-X (Twitter)",
  "ccm.satisfactionconfig.select.ivr.process": "请选择满意度调查IVR流程",
  "ccm.urlconfig.label.oauth.sk": "SK",
  "ccm.agent.message.sysparamservicerr": "内部服务出错，请查看日志",
  "ccm.agent.message.download.err": "下载失败，请联系系统运维人员。",
  "ccm.custdata.option.isIndex.no": "非索引",
  "ccm.agent.accesscode.description.containspatial": "接入码描述不能包含特殊字符%_^&*<>$/",
  "ccm.custdata.msg.confirmAbandon": "确认废弃？",
  "ccm.agent.calledroute.delete": "删除",
  "ccm.agent.restReason.restCauseTime": "休息时长",
  "ccmanagement.satisfactionconfig.acceptdata.timeVerification": "请选择大于当前时间并小于2038-01-19的日期",
  "ccm.transserverparam.label.sk": "SK",
  "ccm.urlconfig.message.notNumber.xNumber": "ITA双呼配置的X号码不是数字",
  "ccm.vcallcenter.config.popup.current.user.locked.error": "当前用户被锁定，10分钟后解锁",
  "ccm.certificate.management.certificatefile": "证书文件",
  "ccm.sysparam.config.select.null": "请选择参数!",
  "ccm.note.config.configsuccess": "配置提示音成功",
  "ccm.certificate.management.warn.selectcerttype": "请选择证书类型",
  "ccm.note.config.agent.page.ring.audition": "试听",
  "ccm.transtask.label.reports": "转储报表",
  "ccm.agent.calledroute.nmsconnect.fail": "连接NMS失败",
  "ccm.chatbot.pop.title": "选择机器人",
  "ccm.recognition.label.calledroutesuccess": "被叫配置配置成功",
  "ccm.agent.contact.web": "Web",
  "ccm.agent.message.omsAgentTypeBatchCheck": "工号[{0}]为在线开通座席，选择的角色或座席类型不匹配",
  "ccm.speciallist.label.msgContent": "提醒消息",
  "ccm.agent.message.willExportAgent": "条信息，是否确认导出？",
  "ccm.calledconfig.message.deletemobileagent": "删除移动客服配置",
  "ccm.agent.label.deviceUsageThreshold": "设备占用率阈值(%)",
  "ccm.agent.button.restSoftPhonePwd.tips": "重置软电话密码将影响正在使用的软电话，需座席重新登录软电话，确认重置吗？",
  "ccm.transtask.label.tuesday": "周二",
  "ccm.agent.operation.MUTE": "静音",
  "ccm.custdata.msg.importDataFail": "导入失败。",
  "ccm.agent.mobileagent.label.choosehint": "请先选择需要删除的数据",
  "ccm.skill.label.maxwaitnum": "最大排队人数",
  "ccm.recognition.label.closemobileagentsuccess": "关闭移动座席和一键双呼成功",
  "ccm.anonymous.message.fail.delete": "删除被叫配置失败，请检查CTI配置",
  "ccm.sysparam.config.save": "保存",
  "ccm.transserver.label.css.serveraddr": "CSS服务器地址",
  "ccm.verifyidentity.fail.unknown": "查询失败",
  "ccm.agent.restrecord.rest.duration": "休息时长",
  "ccm.agent.operation.vdnNotEnabled": "VDN未启用",
  "ccm.sysparam.config.opterate": "操作",
  "ccm.callout.title.fail": "失败",
  "ccm.satisfactionconfig.message.surveymethod": "调查方式",
  "ccm.recognition.queryAK.error": "查询APP Key信息出错",
  "ccmanagement.tenantspace.option.weekday_fri": "星期五",
  "ccm.agent.label.theAgent": "座席:",
  "ccm.operlog.dualcallexport.download": "下载双呼记录",
  "ccm.callout.message.nocallers": "没有选择外呼主叫号码",
  "ccm.agent.callacceptno.set": "设置",
  "ccm.agent.leavemessage.status.solved": "已处理",
  "ccm.recognition.queryAK.not.exist": "当前租间没有APP Key",
  "ccm.agent.login.LOGOUT": "签出",
  "ccm.contactitemconfig.message.confOnlyDatasetName": "操作失败，数据集名称已存在",
  "ccm.transserver.delete.success": "删除转储服务器配置信息成功",
  "ccm.agent.addbother.fail": "添加失败",
  "ccm.verifyidentity.workNo": "座席工号",
  "ccm.agent.contact.resourceloading": "资源加载中...",
  "ccm.agent.message.searchTimeRemaining": "密码剩余过期时间",
  "ccm.transtask.label.cantDelete": "已被转储任务引用，不能删除",
  "ccm.agent.contact.reset": "重置",
  "ccm.agent.operation.failCode": "失败原因码",
  "ccm.custdata.option.isIndex.yes": "索引",
  "ccm.agent.label.versatileagent": "全能型座席",
  "ccm.speciallevel.tip.start": "是否确认启用？",
  "ccm.calledconfig.table.addnumbers": "添加号码",
  "ccm.ucconfig.message.urlTitle": "重定向URI，配置到Microsoft平台用于接受身份认证响应：",
  "ccm.agent.ten.maxVideoAgents": "视频座席数",
  "ccm.agent.message.inSpecialStrFail": "页面名称含有特殊字符",
  "ccm.agent.message.ctipasswdless": "密码长度不能小于8",
  "ccmanagement.satisfaction.strategy.fail.calltime.inputnagetive": "通话时间区间值不可以小于0",
  "ccm.chatbot.invalid.bot": "该机器人无效",
  "ccm.urlconfig.label.relurl.voicemsg.alerttip": "若调用创建语音通知接口时未传递回调URL，当语音通知结束时回调此URL，否则回调传入的URL",
  "ccm.recognition.label.appSecret.download.SK.only.once.error": "如果忘记密钥，请先重置密钥再下载",
  "ccm.sysparam.config.mod": "编辑",
  "ccm.speciallist.msg.size": "导入文件大小超过10M",
  "ccm.agent.contact.signaturedate": "签名日期",
  "ccm.recognition.label.closeIvrAgentSuccess": "关闭智能座席助手成功",
  "ccm.transserver.label.css.edit": "云搜索服务器配置",
  "ccm.agent.speciallist.addList": "加入骚扰记录",
  "ccm.speciallist.label.expirationDate": "失效时间",
  "ccm.agent.label.queuingCallsMaxNum": "最大呼叫排队数目",
  "ccm.transtask.label.datapath": "转储文件路径",
  "ccm.transtask.label.reportedir": "转储报表配置",
  "ccm.agent.label.timeOut": "排队超时处理配置",
  "ccm.sysparam.config.item.catalog": "参数类别",
  "ccm.agent.leavemessage.contactNumber": "联系号码",
  "ccm.agent.resetskillrecord.adjustedagentworkno": "被调整座席工号",
  "ccm.agent.operation.searchworkno": "请输入工号",
  "ccm.agent.contact.twitter": "X (Twitter)",
  "ccm.agent.ten.sipInfo": "SIP地址及端口",
  "ccm.certificate.management.certificatefile.parseerror": "证书解析失败，请确认证书格式或密码是否正确",
  "ccm.agent.login.logIP": "登录IP",
  "ccm.contactitemconfig.label.data.item.contact.list.visibility": "接触列表可见性",
  "ccm.transtask.label.task.otheruniquene": "语音文件，智能质检数据或者话单数据类型任务，同一转储数据类型只能创建一个",
  "ccm.botherlist.label.approveComment.agree": "同意",
  "ccmanagement.satisfaction.strategy.acceptdate.dateEnd": "结束日期",
  "ccm.recognition.label.updatefail": "更新租间故障提示音失败",
  "ccm.custdata.msg.confirmRelease": "确认发布？",
  "ccm.agent.contact.audioclicktodial": "语音点击通话",
  "ccm.agent.ten.maxVideoCalls": "视频并发呼叫数",
  "ccm.callout.message.editsuccess": "编辑外呼主叫号码成功",
  "ccm.agent.contact.customizes": "自定义",
  "ccm.satisfactionconfig.message.strategygroup": "满意度调查策略组配置",
  "ccmanagement.satisfaction.strategy.calltime.minvalue": "下限值",
  "ccmanagement.satisfaction.strategy.delete": "删除",
  "ccm.note.config.failed": "配置提示音失败",
  "ccm.taskrecord.label.executeEndTime": "结束执行时间",
  "ccm.agent.contact.status": "文档状态",
  "ccm.speciallevel.speciallist.unuse": "不可用",
  "ccm.custdata.msg.fileNameValidateFail": "文件名只能包含中文、小写字母、数字或下划线",
  "ccm.callreason.label.callreason": "来电原因",
  "ccm.callreason.title.callreason": "关联来电原因",
  "ccm.callreason.label.parentcallreason": "上级原因",
  "ccm.callReason.title.createCallReason": "新建来电原因",
  "ccm.callReason.title.editCallReason": "编辑来电原因",
  "ccm.agent.label.authtype.unified": "统一认证",
  "ccm.agent.calledroute.dnid": "配置编号",
  "ccm.agent.label.answerType4": "自定义报工号",
  "ccm.agent.restReason.partDeleteFailed": "部分删除失败",
  "ccm.agent.contact.videoclicktodial": "视频点击通话",
  "ccm.agent.contact.multimedia.whatsapp": "多媒体-Whatsapp",
  "ccm.satisfactionconfig.message.createfailed": "新增满意度调查配置失败",
  "ccm.agent.label.waitVoiceConfig": "排队等待配置",
  "ccm.agent.operation.opresult": "操作结果",
  "ccm.agent.mobileagent.syserror": "智能识别开启失败，请联系系统管理员检查环境配置。",
  "ccm.agent.pageurlparam.paramIsConfiged": "弹屏URL传递参数或者关联系统数据参数已配置",
  "ccm.agent.label.answerType2": "不报语音",
  "ccm.satisfactionconfig.message.flowaccesscode": "流程接入码",
  "ccm.agent.label.skills": "技能队列",
  "ccm.agent.label.serviceCode": "服务类型",
  "ccm.agent.label.duration": "持续时长",
  "ccm.agent.label.queueTime": "排队时长",
  "ccm.agent.label.availabilityTime": "可用时长",
  "ccm.agent.label.mutetime": "静音时长",
  "ccm.agent.label.calltime": "无静音通话时长",
  "ccm.agent.label.ringingTime": "振铃时长",
  "ccm.agent.label.serviceLevel": "等待时长百分比(%)",
  "ccm.agent.label.transferInformation": "是否转接",
  "ccm.agent.label.transfer": "转接次数",
  "ccm.agent.label.ivrUsage": "转接ivr次数",
  "ccm.agent.label.originId": "转接方工号",
  "ccm.agent.label.queueName": "转接技能队列",
  "ccm.agent.label.wrapUpCode": "整理态",
  "ccm.agent.label.uuiInfo": "uui信息",
  "ccm.agent.label.overallState": "整理状态",
  "ccm.agent.label.answerType0": "报工号",
  "ccm.note.config.agent.page.ring.setUp": "页面消息提醒配置",
  "ccm.agent.ten.maxAgents": "语音座席数",
  "ccm.agent.operation.FORCEBUSY": "强制示忙",
  "ccm.note.config.success": "成功",
  "ccm.agent.message.audioagentzero": "语音座席数为0，不能配置音频点击通话技能队列",
  "ccm.agent.contact.callserialno": "接触流水",
  "ccm.agent.ten.ivrchannels": "音频IVR通道数",
  "ccm.anonymous.message.fail.feature": "匿名外呼特性权限异常",
  "ccm.label.contactexport.selectdatarange": "选择数据范围",
  "ccm.agent.sysparamconfig.paramwarn": "请选择有效参数值",
  "ccm.agent.pageurls.pagename": "页面名称",
  "ccm.policy.label.updatesucess": "更新策略信息成功",
  "ccm.agent.label.notreply": "无法回复",
  "ccm.callreasonconfig.message.deletecallreason.used": "删除失败，此来电原因已被使用",
  "ccmanagement.satisfaction.strategy.fail.acceptdate.startafterend": "起始日期不可以大于或者等于结束日期",
  "ccm.custdata.button.importData": "导入数据",
  "ccm.callreason.input.specialStrValidateFailed": "来电原因的名称不能包含特殊字符",
  "ccm.chatbot.pop.table.botname": "机器人名称",
  "ccm.agent.calledroute.placeholder.extcode": "请输入扩展码",
  "ccm.asr.create.case.info": "请在通话中或者通话结束后，点击创建工单按钮",
  "ccm.agent.speciallist.endtime": "失效时间",
  "ccm.agent.leavemessage.status.allocationFailed": "分配失败",
  "ccm.worknoaftervoice.config.choosenote": "选择报工号后音",
  "ccm.agent.pageurls.create": "新建",
  "ccm.custdata.option.all": "全部",
  "ccm.agent.label.modifymobileagent": "编辑软电话号码",
  "ccm.transserver.label.cssaddr": "CSS服务地址",
  "ccm.agent.calledroute.extcodewarn": "扩展码只能包含数字",
  "ccm.recognition.label.api.fabric.id": "API Fabric 标识",
  "ccm.agent.login.FORCELOGOUT": "强制签出",
  "ccm.agent.label.deletecallreason": "当前原因含有子原因不能被删除",
  "ccm.agent.label.signedinAgentsPercentage": "按签入座席比例",
  "ccm.recognition.label.ordersucess": "订阅成功",
  "ccm.sysparam.config.select.count": "最多选择 10 条参数！",
  "ccmanagement.satisfaction.strategy.hourvalidate": "配置的小时数据大于24",
  "ccm.recognition.resetSK.success": "重置SK成功",
  "ccm.custdata.option.maskStyle.maskAll": "全掩码",
  "ccm.transserver.delete.confirm": "请确认是否删除",
  "ccm.pageinterface.selectedAttributeNum": "已选对象属性数量",
  "ccm.agent.pageurls.typenosupported": "多媒体不支持内部页面配置",
  "ccm.calledconfig.message.nocalled": "请选择被叫号码",
  "ccm.agent.message.idIsEmpty": "技能队列id为空",
  "ccm.agent.pageurlparam.paramCountLimit": "弹屏URL参数配置数量不能超过{limit}个",
  "ccm.agent.message.webRTCVerification": "WebRTC特性开启时,不支持配置该参数",
  "ccm.agent.contact.callserno": "呼叫流水号",
  "ccm.agent.contact.minCallDuration": "通话时长开始",
  "ccm.agent.contact.maxCallDuration": "通话时长结束",
  "ccm.agent.contact.lessThan": "小于",
  "ccm.agent.contact.callDurationError":"通话时长开始小于通话时长结束",
  "ccm.agent.contact.talkDurationError":"通话时长范围不能超过31天",
  "ccm.agent.contact.fuzzyCallNoError":"接触号码(模糊查询)范围不能超过31天",
  "ccm.agent.contact.fuzzyCallNo": "接触号码(模糊查询)",
  "ccm.agent.leavemessage.status": "留言状态",
  "ccm.speciallist.tip.endTime.min": "失效时间必须大于当前时间",
  "ccm.agent.sysparamconfig.resttimewarn": "数值只能为整数",
  "ccm.speciallist.msg.levelName": "级别",
  "ccm.callreason.label.selectedcallreason": "已选来电原因",
  "ccm.custdata.button.moveUp": "上移",
  "ccm.agent.label.querymobileagent": "查询软电话号码",
  "ccm.agent.label.addChildReason": "原因设置不能超过6级",
  "ccm.ucconfig.message.updatefailed": "保存UC集成配置失败",
  "ccm.pageinterface.custTableName": "基础表名称",
  "ccmanagement.satisfaction.strategy.fail.accepttime.overlimit": "受理时间策略最多只能保存100条",
  "ccm.satisfactiontemplate.title": "满意度调查模板内容配置",
  "ccm.agent.pageurls.updatecall": "编辑来电弹屏配置",
  "ccmanagement.satisfaction.strategy.fail.unknown": "保存失败",
  "ccm.agent.ten.DSTStartTime": "夏令时开始时间",
  "ccm.agent.ten.contactInfor": "联系方式",
  "ccm.verifyidentity.fail.LIMITOVERLIMIT": "查询的分页参数超出限制",
  "ccm.agent.contact.otherexception": "其它异常",
  "ccm.speciallist.msg.operationdoing": "其他审核人员正在审核",
  "ccm.callout.message.resetsuccess": "重置参数成功   \t\t\t",
  "ccm.certificate.management.certificatefile.certcodeexist": "证书编码已存在",
  "ccm.certificate.management.search.status": "请选择证书状态",
  "ccm.certificate.management.status": "证书状态",
  "ccm.certificate.management.status.value.inuse": "使用",
  "ccm.certificate.management.status.value.discard": "废弃",
  "ccm.certificate.management.status.oper.reuse": "再利用",
  "ccm.certificate.management.status.oper.discard": "废弃",
  "ccm.certificate.management.status.confirm.reuse": "确认再利用",
  "ccm.certificate.management.status.confirm.discard": "确认废弃",
  "ccm.certificate.management.status.confirmReuse": "请确认是否继续使用此证书？",
  "ccm.certificate.management.status.confirmDiscard": "请确认是否已无业务场景使用此证书并废弃此证书？",
  "ccm.certificate.management.status.update.success": "修改证书状态成功",
  "ccm.certificate.management.status.update.failed": "修改证书状态失败",
  "ccm.certificate.management.certificatefile.status.error": "证书已废弃，不可被修改",
  "ccm.contactitemconfig.label.data.item.name": "字段名称",
  "ccm.satisfactionservice.noevaluation": "未评价",
  "ccm.agent.message.deleteSkillConfFail": "技能队列信息删除失败。",
  "ccm.agent.message.deleteSkillConfFailID": "删除技能队列{0}失败。",
  "ccm.agent.message.modifySkillConfSuccess": "技能队列信息修改成功。",
  "ccm.agent.calledroute.placeholder.skill": "请选择一个技能队列",
  "ccmanagement.tenantspace.option.weekday_sun": "星期日",
  "ccm.pageurls.select.independently": "独立打开",
  "ccm.custdata.label.failreaon": "失败原因",
  "ccm.agent.label.agentTel": "座席手机/固话",
  "ccm.transtask.label.taskedit": "资源转储任务配置",
  "ccm.custdata.msg.confirmReturn": "排序未完成，确认返回到自定义表查看页面？",
  "ccm.agent.calledroute.extlengthcodewarn": "扩展码长度不能超过12",
  "ccm.querycustdata.msg.inputIndexData": "请输入",
  "ccm.agent.contact.numhungup": "转外部号码",
  "ccm.agent.label.restQueueRule": "休息排队策略",
  "ccm.agent.contact.multimedia.fiveG": "多媒体-5G消息",
  "ccm.transtask.label.chatrecordinf": "转储多媒体聊天数据配置",
  "ccm.agent.leavemessage.allStatus": "所有留言状态",
  "ccm.satisfactionlevel.message.nopara": "描述不能为空",
  "ccm.speciallist.label.startDate": "生效时间",
  "ccm.transserver.label.cssproxynotinwhite": "操作失败，请联系系统管理员将代理服务器地址加入资源转储CSS服务器代理白名单",
  "ccm.agent.restReason.pleaseRefresh": "，请同步后重试",
  "ccm.whatsapp.whatsApp.channel": "WhatsApp渠道",
  "ccm.agent.ten.DSTEndDate": "夏令时结束日",
  "ccm.callreason.label.input": "请输入",
  "ccm.transtask.label.saveexit": "新增失败，服务器名称已存在",
  "ccm.custdata.msg.typeColumnNumValidateFailed": "该类型字段数量超过限制：索引字段只允许有1个，非加密字段数量最大值为30，加密字段数量最大值为20",
  "ccm.agent.contact.reasonCode": "释放原因码",
  "ccm.satisfactionconfig.message.groupcreate": "新增",
  "ccm.urlconfig.label.oauth.voicemsg.alerttip": "当调用创建语音通知接口时传递了回调URL，若配置了下面的鉴权信息，则当语音通知结束回调该URL时使用下面的鉴权信息。否则，回调时将不鉴权。",
  "ccmanagement.satisfaction.strategy.accepttime.timeStart": "起始时间",
  "ccm.sysparam.config.search": "查询",
  "ccm.certificate.management.certificatecrlfile.suffixerror": "吊销证书文件的后缀只能为.crl或为空",
  "ccm.custdata.button.abandon": "废弃",
  "ccm.agent.ten.createDate": "创建时间",
  "ccm.label.contactexport.parainvalid": "导出条件校验失败",
  "ccm.agent.restrecord.reststatus.resting": "休息中",
  "ccm.note.config.agent.page.ring.choose": "请选择铃声",
  "ccm.agent.contact.playfail": "播放失败",
  "ccm.transtask.label.start": "启动",
  "ccm.agent.ten.allowedTTSAndASRCallNum": "智能IVR通道数",
  "ccm.agent.contact.sameContactRecord": "同次接触记录",
  "ccm.agent.calledroute.nmsdata.error": "同步NMS失败，NMS被叫配置数据与CCM本地数据不一致",
  "ccm.agent.sysparamconfig.worknonumwarn": "座席工号不能超过十个",
  "ccm.agent.operation.LISTEN": "质检监听",
  "ccm.speciallevel.speciallist.remark": "说明",
  "ccm.speciallist.msg.willdelete": "确定删除{limit}项记录？",
  "ccm.common.msg.willdelete": "是否确认删除您选择的{limit}条数据？",
  "ccm.calledconfig.title.numbersadded": "已添加号码",
  "ccm.ivr.label.processdesc": "流程描述",
  "ccm.custdata.operlog.failed": "失败",
  "ccm.pageurls.select.urlParameter": "URL GET请求传参",
  "ccm.agent.message.willClearAgents": "确定清空所选的座席吗？",
  "ccm.note.config.error": "错误",
  "ccm.agent.operation.mediaswitch": "音视频切换",
  "ccm.sysparam.error.validate.notmatchruleregex": "输入参数与校验规则的正则表达式不匹配",
  "ccm.contact.customer.config.fonts": "字体",
  "ccm.sysparam.config.agent.title": "座席参数",
  "ccm.accesscode.placeholder.accessdesc": "请输入接入码描述",
  "ccm.agent.ten.expiryDate": "租户截止日期",
  "ccm.agent.sysparamconfig.refreshAllSysParamFail": "系统错误，刷新失败",
  "ccm.transserverparam.label.ak": "AK",
  "ccm.agent.softPhoneStatusNode1": "节点1",
  "ccm.agent.softPhoneStatusNode2": "节点2",
  "ccm.speciallevel.speciallist.restrictMode": "限制模式",
  "ccm.agent.contact.nohaveevent": "没有该事件",
  "ccm.agent.label.authType": "认证方式",
  "ccm.common.label.no": "否",
  "ccm.speciallist.msg.modify": "修改",
  "ccm.satisfactionconfig.message.sms": "短信",
  "ccm.taskrecord.message.reexecutetranstasksuccess": "重新执行成功",
  "ccm.agent.leavemessage.processfail": "处理留言失败",
  "ccm.agent.contact.talkCallbackFlag": "通话回调标记",
  "ccm.agent.button.unregister": "注销",
  "ccm.agent.contact.begintime": "开始时间\t",
  "ccm.agent.calledroute.createsuccess": "新增成功",
  "ccm.agent.button.cancelconfig": "取消配置",
  "ccm.agent.pageurls.errorupdate": "更新失败",
  "ccm.custdata.msg.operationFailed": "操作失败",
  "ccm.satisfactiontemplate.icontitle": "按满意度等级更新模板内容",
  "ccm.satisfactionconfig.message.smstemplate": "短信模板",
  "ccm.agent.restReason.createReason": "新建休息原因",
  "ccm.urlconfig.message.modifyfail": "修改url配置失败",
  "ccm.urlconfig.message.overlimit.appKey": "ITA双呼配置的appKey超过128个字符",
  "ccm.agent.leavemessage.assignSuccess": "分配留言成功",
  "ccm.agent.contact.businesshungup": "转座席",
  "ccm.agent.label.param.index": "序号",
  "ccm.callout.message.isconfreset": "是否确认重置",
  "ccm.verifyidentity.fail.VERIFYRESULTPARSEERROR": "验证结果参数不正确",
  "ccm.agent.button.create": "创建",
  "ccm.agent.calledroute.create.skill.none": "被叫配置引用的技能队列不存在.",
  "ccm.transtask.label.certTip.cssCert": "请选择云搜索服务器证书",
  "ccm.agent.label.keepVoiceSelect": "请选择等待音",
  "ccm.transtask.label.wednesday": "周三",
  "ccm.agent.message.pageLayout": "页面布局",
  "ccm.agent.message.name.limit": "技能队列名称超长",
  "ccm.agent.message.name.grouplimit": "技能组名称不能超过64",
  "ccm.certificate.management.certificatefile.certfile.sizeerror": "证书文件大小不能超过20K",
  "ccm.agent.message.agentCreateFail": "在平台创建座席失败",
  "ccm.agent.ten.maxCalls": "语音并发呼叫数",
  "ccm.agent.contact.whatsapp": "WhatsApp",
  "ccm.pageconfig.label.layoutType": "布局类型",
  "ccm.satisfactionlevel.message.leastcalltype": "选择至少一个呼叫类型",
  "ccm.agent.contact.agentPresent": "座席主叫外显号码",
  "ccm.calledconfig.message.duplicatecalled": "重复的被叫号码",
  "ccm.label.chatRecord.channeltypeinnull": "渠道类型未选择",
  "ccm.satisfactionconfig.satisfaction.process": "满意度流程",
  "ccm.calledroute.tip.selectivrexception": "从CTI查询IVR流程失败",
  "ccm.agent.softPhoneStatus.UnRegistered": "未注册",
  "ccm.agent.accesscode.description.notempty": "接入码描述不能为空",
  "ccm.agent.contact.sms": "短信",
  "ccm.agent.contact.Instagram": "Instagram",
  "ccm.agent.contact.Telegram": "Telegram",
  "ccm.satisfactionconfig.message.acceptdate": "受理日期",
  "ccm.transtask.label.chatRecord": "多媒体聊天数据",
  "ccm.transtask.label.intelligentInspection": "智能质检数据",
  "ccm.urlconfig.modify.authtype2": "OAuth 2.0",
  "ccm.urlconfig.modify.authtype3": "不鉴权",
  "ccm.agent.pageurlparam.relSysParamName": "关联系统数据参数",
  "ccm.urlconfig.modify.authtype1": "共享密钥签名",
  "ccm.agent.message.agentnummaxoperation": "一次最多操作100个座席",
  "ccm.agent.label.basicInfo": "基本信息",
  "ccm.speciallist.msg.datecompareerror": "失效时间应大于生效时间",
  "ccm.transtask.label.task.chatrecord.uniqueness": "选择的有些渠道类型任务已经存在",
  "ccm.basicdata.msg.deletefail": "删除基础数据失败。",
  "ccm.satisfactionconfig.audio.and.video.remark": "当IVR语音和短信满意度调查均开启，座席先行挂机时，如果系统已执行IVR语音满意度调查，将不再进行短信满意度调查。",
  "ccm.sysparam.config.value": "参数值",
  "ccm.agent.contact.voiceVidio": "音视频",
  "ccm.custdata.option.maskStyle.maskDate": "日期掩码",
  "ccm.recognition.label.appSecret.download": "下载SK",
  "ccm.agent.operation.REST": "请求休息",
  "ccm.calledconfig.message.savemobileagentfail": "移动客服配置保存失败",
  "ccm.certificate.management.certificatefile.uploadcertfile": "上传证书文件",
  "ccm.contact.customer.config.label": "接触客户信息栏",
  "ccm.verifyidentity.verifyResult": "验证结果",
  "ccm.urlconfig.message.modifysuccess": "修改url配置成功",
  "ccm.callreason.label.mark": "备注",
  "ccm.satisfactionconfig.message.channelused": "策略组被服务渠道引用，不能进行删除",
  "ccm.transtask.label.datapath.tips": "OBS对应路径",
  "ccm.custdata.msg.importDataSuccess": "数据导入中，请通过“导入结果”查看详细信息",
  "ccm.agent.label.keepVoiceDefault": "默认等待音",
  "ccm.agent.label.selectSkill": "选择技能队列",
  "ccm.transserver.label.cssServerPort": "CSS服务端口",
  "ccm.agent.label.keepVoiceConfig": "保持等待配置",
  "ccm.urlconfig.label.noramalOrITA.input": "请输入ITA的参数配置",
  "ccm.transtask.label.agentOperationReport": "座席接续报表",
  "ccm.agent.contact.createbother": "创建骚扰记录",
  "ccm.custdata.option.columnType.dictionary": "数据字典",
  "ccmanagement.satisfaction.strategy.accepttime.minute": "分",
  "ccm.agent.calledroute.search.devicedesc": "请输入设备名称",
  "ccm.agent.pageurls.isconfdelete": "是否确认删除",
  "ccm.agent.contact.updatedate": "更新日期",
  "ccm.agent.log.downloadVideoTerminal": "下载OpenEye视讯终端",
  "ccm.contact.detail.sendmessage": "发送消息",
  "ccm.agent.skill.nameInfo": "该技能队列名称:",
  "ccm.agent.Transfer.CallForwarding": "呼叫转移 ",
  "ccm.satisfactionconfig.message.strategygroupmemconfig": "成员策略配置",
  "ccm.pageurls.select.postParameter": "URL POST请求传参",
  "ccm.agent.message.modifySkillConfFail": "技能队列信息修改失败。",
  "ccm.agent.restrecord.rest.endTime": "休息结束时间",
  "ccm.agent.calledroute.gateway.fail": "同步CTI平台失败，请检查gateway配置",
  "ccm.urlconfig.label.oauth.label": "请输入OAuth 2.0授权信息",
  "ccm.pageinterface.optionalAttribute": "可选属性",
  "ccm.contact.customer.config.datasource.customData": "本地数据",
  "ccmanagement.satisfaction.strategy.deleteselect": "确认要删除选中的策略实例吗",
  "ccm.agent.contact.callerfailed": "外呼主叫失败",
  "ccm.agent.resetskillrecord.skillafteradjust": "调整后技能队列",
  "ccm.agent.message.selectSkills": "请选择技能队列",
  "ccm.sysparam.config.resetAbb": "重置",
  "ccm.agent.label.airecog": "智能识别",
  "ccm.certificate.management.certificatefile.suffixerror": "证书只允许上传格式为jks,cer,crt,pem,pfx,p12的文件",
  "ccm.agent.message.inputWeight": "请输入权值",
  "ccm.agent.leavemessage.assignFailed": "分配留言失败",
  "ccm.agent.restReason.selectRestReasons.limit": "批量删除休息原因数量不能超过10个",
  "ccmanagement.satisfaction.strategy.deletefail": "删除失败",
  "ccm.vcallcenter.config.popup.authentication.failed": "身份验证失败",
  "ccm.satisfactionconfig.message.smsfeedbacktype": "短信",
  "ccm.agent.contact.voiceBot": "语音",
  "ccm.custdata.msg.msgValidateFailed": "不允许包含特殊字符",
  "ccm.agent.label.deleteAgentFailed": "有技能队列已绑定业务帐号，请先删除座席下该技能队列的配置",
  "ccm.transtask.label.discard": "废弃",
  "ccm.agent.Transfer.deviceDesc": "目的设备名称",
  "ccm.basicdata.error.capacitylimit": "数据容量已达上限{0}",
  "ccm.agent.ten.ttschannelnums": "TTS数",
  "ccm.speciallist.button.import": "导入",
  "ccm.speciallist.title.import": "导入特殊名单",
  "ccm.recognition.label.modifymobileagentclose": "租间尚未开启移动座席或一键双呼特性",
  "ccm.nms.satisfactionservice.buttonNumber": "满意度调查按键数不超过5个，不低于3个。",
  "ccm.contact.customer.config.style": "显示风格",
  "ccm.dual.call.record.unexpected.end": "异常结束",
  "ccm.agent.contact.playdefault": "查询录音时长为空，是否使用默认时长播放（60分钟）？",
  "ccm.satisfactionconfig.sms.switch.remark": "开启时，通话结束后将给客户发送满意度调查短信",
  "ccm.satisfactionconfig.satisfaction.sending.channel": "满意度发送渠道",
  "ccm.agent.message.refreshSkillException": "刷新技能队列异常",
  "ccm.recognition.label.policy.information": "策略信息",
  "ccm.recognition.label.editPolicy.information": "编辑策略信息",
  "ccm.agent.leavemessage.status.toBeAssigned": "待分配",
  "ccm.agent.leavemessage.status.toBeProcessed": "待处理",
  "ccm.speciallist.msg.applyTime": "申请时间",
  "ccm.agent.contact.callershutdown": "通话结束，主叫关机",
  "ccm.urlconfig.label.noramalOrITA": "请选择云客服双呼还是ITA双呼",
  "ccm.satisfactionlevel.message.satistip": "请配置的各渠道满意度调查使用的满意度等级一致：如语音满意度调查、短信满意度调查和网页满意度调查",
  "ccm.anonymous.message.fail.close": "关闭失败，请检查CTI配置",
  "ccm.taskrecord.label.executeBeginTime": "开始执行时间",
  "ccm.agent.label.usedMode.sipAccount": "软电话号码",
  "ccm.callback.url.callnotify": "语音通知回调配置",
  "ccm.callreason.label.allreason": "所有原因",
  "ccmanagement.satisfactionconfig.acceptdate.timeerror.laterThanEndTime": "起始日期必须小于结束日期",
  "ccm.agent.ten.mediaagents": "多媒体座席数",
  "ccm.agent.contact.callernook": "外呼主叫无应答",
  "ccm.satisfactionconfig.message.nofeedbacktype": "不调查",
  "ccm.contact.customer.config.datasource.callData": "呼叫随路数据",
  "ccm.custdata.button.detail": "详情",
  "ccm.label.contactexport.currentfield": "当前列表展示字段",
  "ccm.speciallist.msg.confirmdelete": "确定删除所选的特殊名单吗？",
  "ccmanagement.tenantspace.option.month_12": "12月",
  "ccmanagement.tenantspace.option.month_11": "11月",
  "ccmanagement.tenantspace.option.month_10": "10月",
  "ccm.agent.ten.tenantId": "租间ID",
  "ccmanagement.satisfaction.strategy.saveerror": "失败",
  "ccm.custdata.msg.inputTableName": "请输入表名",
  "ccm.agent.label.ifBusyNoAnswerAgent": "座席久不应答呼叫是否示忙",
  "ccm.agent.label.asyncUserDispatchMode": "离线用户分配方式",
  "ccm.agent.label.asyncUserDispatchMode.zero": "按并发数",
  "ccm.agent.label.asyncUserDispatchMode.one": "按并发数+技能队列",
  "ccm.agent.label.asyncUserDispatchMode.two": "按并发数+在线状态",
  "ccm.agent.label.asyncUserDispatchMode.three": "按并发数+技能队列+在线状态",
  "ccm.agent.label.asyncUserDispatchMode.four": "按并发数+座席+技能队列+在线状态",
  "ccm.transserverparam.label.password": "密码",
  "ccm.agent.videoDownload.disabled": "未开启视频下载功能",
  "ccm.common.label.yes": "是",
  "ccm.basicdata.msg.deletesuccess": "删除基础数据成功",
  "ccm.callreason.label.status": "状态",
  "ccm.contact.customer.config.writeBack": "是否回写接触记录",
  "ccm.agent.label.ccRoleId": "平台角色",
  "ccm.case.title.selectWorkOrder": "选择工单分类",
  "ccm.agent.title.skillModify": "编辑技能队列",
  "ccm.pageinterface.pleaseSelectInterfaceType": "请选择接口类型",
  "ccm.speciallist.msg.withoutauth": "没有操作权限",
  "ccm.agent.label.ifReleaseNoAnswerCall": "座席久不应答呼叫是否释放",
  "ccm.speciallist.msg.fail": "失败",
  "ccm.agent.pageurl.httptip": "(不安全)",
  "ccm.agent.label.withinSevenDays": "7天内",
  "ccm.certificate.management.update.cancel.crlfile": "确认要取消更新证书吊销文件吗？",
  "ccm.speciallist.label.operator": "操作人",
  "ccm.agent.label.waitTime": "等待时长(秒)",
  "ccm.agent.contact.userhungup": "用户",
  "ccm.satisfactionconfig.message.memberstrategyconfig": "策略组成员策略配置",
  "ccm.agent.label.ivrChoice": "请选择IVR",
  "ccm.agent.message.noReleaseFail": "未发布的数据才可以进行删除",
  "ccm.calledconfig.label.add": "新增",
  "ccm.sysparam.error.validate.notmatchrule": "输入参数与校验规则不匹配",
  "ccm.custdata.msg.missRequiredColumn": "自定义表至少有一个索引字段和其他信息字段，才可进行发布",
  "ccm.agent.operation.RELEASE": "挂断",
  "ccm.sysparam.error.validate.watermarkinfo.notmatchruleregex": "参数只允许输入包含英文大小写字母、数字或-_/@的0到20位字符",
  "ccm.agent.ten.timezoneOffset": "时区偏移",
  "ccm.systemparam.error.save": "参数保存失败",
  "ccm.dual.call.record.called": "被叫号码",
  "ccm.agent.addbother.success": "添加骚扰记录成功",
  "ccm.callreasonconfig.message.deletecallreason.success": "删除成功",
  "ccm.transserver.label.obsaddr": "OBS服务地址",
  "ccm.agent.operation.CALLINNER": "内部呼叫",
  "ccm.agent.pageurlparam.paramLengthLimit": "参数长度不能超过64",
  "ccmanagement.tenantspace.option.weeklist_tidtolast": "倒数第3个",
  "ccm.agent.contact.multimedia.wechat": "多媒体-微信",
  "ccm.recognition.label.orderfail": "订阅失败!",
  "ccm.transtask.label.savefail": "新增失败，转储服务器数量不能超过{0}",
  "ccm.agent.operation.ADJUSTSKILL": "调整技能队列",
  "ccm.custdata.label.valueRange": "值范围",
  "ccm.agent.leavemessage.view": "查看留言",
  "ccm.speciallist.msg.norecord": "没有记录可以导出",
  "ccm.urlconfig.message.addsuccess": "新增url配置成功",
  "ccm.recognition.label.appSecret.download.SK.io.error": "下载SK出错，IO异常",
  "ccm.contactitemconfig.label.contact.recorddata": "接触记录字段",
  "ccm.speciallist.msg.error": "错误",
  "ccm.agent.pageurls.config": "配置",
  "ccm.custdata.button.moveBottom": "置底",
  "ccm.agent.calledroute.create.accesscode.repeat": "被叫配置的系统接入码和扩展码组合不能重复",
  "ccm.custdata.option.isMaskDisplay.no": "正常展示",
  "ccm.custdata.msg.deletedItemNotExist": "此删除项不存在，请刷新后再重试。",
  "ccm.transtask.label.taskupload": "上传服务器",
  "ccm.transtask.label.expirationDuration": "转储文件保持周期",
  "ccm.agent.calledroute.accesscode": "接入码",
  "ccm.transtask.label.View": "查看",
  "ccm.dual.call.record.startTime": "发起呼叫时间",
  "ccm.contact.tip.no.workname": "未获取到对应坐席！",
  "ccm.ucconfig.message.userId": "目录(租户)ID",
  "ccm.speciallist.msg.transferout": "转历史已达到{0}的限制",
  "ccm.satisfactionconfig.message.channelname": "渠道",
  "ccm.urlconfig.label.input.appSecret": "SecretKey",
  "ccm.agent.updateSoftPhone.reason": "原因",
  "ccm.agent.contact.download.frequently": "下载过于频繁，请稍后再试",
  "ccm.recognition.label.modifymobileagentfailed": "修改移动座席和一键双呼状态失败!",
  "ccmanagement.satisfaction.strategy.fail.calltime.overlimit": "通话时长策略最多只能保存100条",
  "ccm.agent.contact.hungup": "挂机方",
  "ccm.agent.label.skillId": "技能队列ID",
  "ccm.note.config.save": "保存",
  "ccm.agent.pageurlparam.checkSpecialChar": "请输入数字、字母、下划线和横杠，点号只能在中间",
  "ccm.agent.pageurlparam.checkSpecialChar.noPoint": "请输入数字、字母、下划线和横杠，点号只能在中间",
  "ccm.agent.pageurlparam.checkSpecialChar.name": "请输入数字、字母、下划线和横杠",
  "ccm.agent.contact.otherhungup": "其他",
  "ccm.sysparam.error.validate.ruleregexinvalid": "校验规则语法错误",
  "ccm.agent.contact.multimedia.line": "多媒体-Line",
  "ccm.agent.pageurls.confonlynaming": "，请确保界面名称是否唯一",
  "ccm.agent.ten.email": "邮箱",
  "ccm.speciallist.button.history": "转历史",
  "ccm.agent.message.description.limit": "技能队列描述超长",
  "ccm.page.select.blockLayout": "分块布局",
  "ccm.agent.sysparamconfig.resetAllParamsFail": "参数配置文件丢失,请检查",
  "ccm.agent.accesscode.description.require": "接入码描述不能为空",
  "ccm.sysparam.url.unsafe": "（不安全）",
  "ccm.agent.sysparamconfig.checkWebhookValidityDays": "webhook认证有效期必须在7至365天之间",
  "ccm.asr.create.case": "创建工单",
  "ccm.transserver.delete.css.fail": "删除云搜索服务器配置信息失败",
  "ccm.speciallist.msg.besureexport": "确定要导出数据吗？",
  "ccm.speciallevel.msg.selectspecialLevel": "请选择特殊名单级别",
  "ccm.agent.label.skillName": "技能队列名称",
  "ccm.agent.contact.name": "文档名称",
  "ccm.agent.contact.searchName": "文档名称",
  "ccm.agent.agentcontact.error": "查询失败",
  "ccm.agent.label.positiveEmotion": "态度非常棒！请再接再厉哦~",
  "ccm.agent.restReason.edit": "编辑休息原因",
  "ccm.urlconfig.message.missing.appIdOrAppSecret": "ITA双呼配置缺少accessKey或者secretKey",
  "ccm.urlconfig.label.oauth.oauth2loginurl.illegal": "Login URL不合法",
  "ccm.satisfactionconfig.message.memberstrategychoose": "成员策略选择",
  "ccm.agent.label.deleteCtiFailed": "删除数据失败",
  "ccm.worknobeforevoice.config.choosenote": "选择报工号前音",
  "ccm.agent.button.restSoftPhonePwd": "重置软电话密码",
  "ccm.agent.message.export": "导出",
  "ccm.agent.message.export.skill": "导出技能队列",
  "ccm.agent.info.message.export": "导出座席信息",
  "ccm.verifyidentity.noMoreThan30Days": "查询的时间范围不能超过30天",
  "ccm.speciallist.msg.download": "下载特殊名单导入模板",
  "ccm.speciallist.msg.import.template": "特殊名单导入模板",
  "ccm.agent.button.config": "配置",
  "ccm.custdata.operlog.displayMask": "展示掩码",
  "ccm.agent.message.agentnotexist": "座席不存在，请在“座席管理”侧刷新后重试",
  "ccm.certificate.management.search.scenename": "请输入场景名称",
  "ccm.agent.message.createSkillConfFail": "创建技能队列信息失败。",
  "ccm.satisfactionconfig.message.audiochannel": "音视频渠道",
  "ccm.agent.leavemessage.auditAccount": "处理人",
  "ccm.satisfactionconfig.message.calltypeout": "呼出",
  "ccm.agent.contact.ivrhungup": "转IVR",
  "ccm.pageinterface.interfaceType": "接口类型",
  "ccmanagement.tenantspace.option.weekday_thu": "星期四",
  "ccm.agent.operation.CANCELWHISPER": "取消耳语",
  "ccm.contact.customer.config.datasource.objId": "数据来源对象",
  "ccm.recognition.label.intellrecogn": "智能识别",
  "ccm.agent.label.negativeEmotion": "措辞不当！请注意使用适当的话术",
  "ccm.verifyidentity.fail.INPUTNULLERROR": "查询参数不能为空",
  "ccm.contact.label.inactive": "已失效",
  "ccm.sysparam.error.validate.notmatchrulealpha": "请输入字母数字或下划线，且只能以字母或下划线开头",
  "ccm.urlconfig.label.radio.radioType2": "ITA双呼",
  "ccm.agent.label.CommomAgent": "普通话务员",
  "ccm.callout.lable.reset": "重置",
  "ccm.agent.label.timeRemaining": "密码剩余过期时间（天）",
  "ccm.transtask.label.week": "周",
  "ccm.speciallist.msg.maxCountQuery": "当前数据量过大，系统仅展示最近的{0}条数据",
  "ccm.agent.pageurls.delete": "删除",
  "ccm.agent.contact.downloadvideo": "下载视频",
  "ccm.urlconfig.label.radio.radioType1": "云客服双呼",
  "ccm.speciallist.msg.operationsuccess": "操作成功",
  "ccm.recognition.label.characteristics": "特性",
  "ccm.satisfactionconfig.message.updatesuccess": "更新满意度调查配置成功",
  "ccm.botherlist.label.approveStatus.rejected": "已拒绝",
  "ccm.urlconfig.modify.oauth.sk.tooshort": "SK长度不少于16个字符",
  "ccm.certificate.management.certificatefile.suffixerror.suffix": "的文件",
  "ccm.pageinterface.objectName": "对象名称",
  "ccm.agent.sysparamconfig.refreshSuccess": "一键重置成功",
  "ccm.urlconfig.label.talkurl": "接通回调url",
  "ccm.agent.label.fixedLine": "固话/手机号",
  "ccm.agent.operation.ADJUSTVIDEOMODE": "调整视频窗口大小",
  "ccm.agent.operation.SCREENMONITOR": "同屏监控",
  "ccm.calledconfig.message.samename": "已存在相同名称.",
  "ccm.agent.pageurls.independentlynosupported": "独立打开不支持内部页面配置",
  "ccm.satisfactionconfig.message.satis": "满意度调查",
  "ccm.querycustdata.msg.noData": "暂无数据",
  "ccm.agent.message.airecognull": "智能识别标识为空.",
  "ccm.note.config.policy.systemdefault": "系统默认",
  "ccm.custdata.label.sucNum": "成功记录数",
  "ccm.agent.contact.noAuth": "无该权限",
  "ccm.transtask.label.cantsave": "已被转储任务引用，不能修改",
  "ccm.agent.message.addSkillFail": "添加技能队列失败",
  "ccm.agent.message.maxSkillFail": "技能队列策略最多只能包含100条",
  "ccm.agent.message.addSkillSuccess": "添加技能队列成功",
  "ccm.agent.message.addSkill": "添加技能队列",
  "ccm.agent.contact.download": "下载",
  "ccm.agent.ten.tenantInfor": "租户信息",
  "ccm.agent.label.usedMode.phone": "手机号码",
  "ccm.agent.leavemessage.serviceContactNumber": "联系号码",
  "ccm.agent.restReason.confOnlyCauseCode": "操作失败，原因码已存在",
  "ccm.agent.message.skillConfSuccess": "技能队列信息配置成功",
  "ccm.taskrecord.label.executeSuccess": "执行成功",
  "ccm.speciallist.msg.crateerror": "添加失败",
  "ccm.urlconfig.label.relurl.callback.alerttip": "若调用创建双呼接口时未传递回调URL，当双呼通话结束时回调此URL，否则回调传入的URL",
  "ccm.agent.contact.recordtime": "录音时长(s)",
  "ccm.transtask.label.skillReport": "技能话务报表",
  "ccm.custdata.operlog.addExportTask": "新增导出任务",
  "ccm.agent.contact.QUERU_VCCINFO_FAIL_CODE": "虚拟呼叫中心信息为空",
  "ccm.transtask.label.skillSummaryReport": "话务汇总报表",
  "ccm.label.contactexport.selectall": "全选",
  "ccm.speciallist.label.redlist": "红名单",
  "ccm.agent.pageurls.success": "成功",
  "ccm.transserver.label.obs": "OBS服务器",
  "ccm.agent.label.queuingCallsMaxNumRange": "取值 [0-10000]",
  "ccm.note.config.policy.custom": "自定义",
  "ccm.transtask.label.agentReport": "座席汇总报表",
  "ccm.vcallcenter.config.popup.currentaccount.pwd": "当前帐号密码",
  "ccm.transtask.label.contactrcddata": "接触记录列表展示字段",
  "ccm.urlconfig.message.oauth.tip.warning": "回调URL鉴权方式为不鉴权，存在安全风险，是否继续使用该鉴权方式？",
  "ccm.agent.accesscode.description": "接入码描述",
  "ccm.idauth.title.idauthconfig": "身份验证流程配置",
  "ccm.agent.label.callDispatchModel.best": "最佳分配",
  "ccm.agent.label.restQueueRule.applyFirst": "先申请者优先休息",
  "ccm.custdata.msg.duplicateColumnName": "字段名重复，请重新命名",
  "ccm.contactitemconfig.label.data.extended.Type.DataDictionary": "数据字典",
  "ccm.agent.calledroute.create": "新增",
  "ccm.agent.operation.searchcountid": "业务帐号",
  "ccm.callreason.label.disable": "停用",
  "ccm.transserver.label.objobs": "对象存储服务OBS配置",
  "ccm.urlconfig.label.appsecret": "共享密匙",
  "ccm.urlconfig.label.key": "密匙",
  "ccm.agent.resetskillrecord.adjustbusinessaccount": "调整业务帐号",
  "ccm.transtask.label.save": "保存",
  "ccm.callout.message.resetfail": "重置参数失败",
  "ccm.speciallist.msg.successNum": "成功{limit}条",
  "ccm.agent.recall.agent.status.invalid": "座席非空闲或者忙碌状态，无法进行回呼",
  "ccm.agent.message.pageNumFail": "页面配置应不超过{0}个",
  "ccm.callback.url.callback": "双呼回调配置",
  "ccm.transtask.label.taskid": "任务Id",
  "ccm.contact.customer.config.dataItemError": "数据项名称或标识重复",
  "ccm.agent.button.batchmodify": "批量修改",
  "ccm.urlconfig.label.input.appId": "AccessKey",
  "ccm.custdata.operlog.importdata": "导入数据",
  "ccm.agent.button.restores": "恢复",
  "ccm.agent.label.skillWeightTip": "座席签入多个队列，优先处理技能权值大的队列中的呼叫",
  "ccm.agent.restReason.createFailed": "新增失败",
  "ccm.urlconfig.message.overlimit.domainName": "ITA双呼配置的域名超过128个字符",
  "ccm.agent.label.maxTalkTime": "最大通话时长(秒)",
  "ccm.custdata.option.maskStyle.retainFirst4": "保留前4位",
  "ccm.transserverparam.label.isagent": "是否配置代理",
  "ccmanagement.satisfaction.strategy.fail.accepttime.inputlength": "起始时间或结束时间长度超出限制",
  "ccm.agent.tips.confSuccess": "配置成功",
  "ccm.agent.tips.noagent": "当前选择号段不存在座席",
  "ccm.speciallist.label.operTime": "创建时间",
  "ccm.agent.operation.noMoreThan30Days": "范围不能超过30天",
  "ccm.agent.contact.fiveg": "5G消息",
  "ccm.satisfaction.leveltips": "注: 各渠道均使用统一的满意度配置",
  "ccm.sysparam.config.reset.multiple.error": "以下参数不允许被重置：",
  "ccm.agent.message.quotingDataOperationFail": "数据被弹屏页面引用，操作失败",
  "ccm.agent.contact.refreshAll": "刷新",
  "ccm.agent.pageurls.fail": "错误",
  "ccm.custdata.operlog.download": "下载",
  "ccm.recognition.label.cancelorderfailpd": "取消订阅失败，请稍后重试！",
  "ccmanagement.satisfaction.strategy.accepttime.hour": "时",
  "ccm.transserver.label.cloudsearchserver.css": "云搜索服务CSS配置",
  "ccm.agent.message.queryCallCenterFail": "查询呼叫中心数据失败",
  "ccm.speciallevel.speciallist.levelName": "级别名称",
  "ccm.agent.calledroute.createfail": "本地数据新增失败",
  "ccm.speciallist.tip.timeVerification": "请选择大于当前时间并小于2038-01-19的时间",
  "ccm.sysparam.error.validate.ruleinvalid": "校验规则不合法",
  "ccm.sysparam.error.validate.ccme.alarmThreshold": "告警严重程度与告警阈值不一致，低严重程度告警阈值应小于（等于）高严重程度告警阈值",
  "ccm.sysparam.error.validate.ccme.daysAlarmOverSync": "输入参数值应小于离线用户迁移历史表的配置时间(默认7天)",
  "ccm.agent.message.nulagentincti": "当前选中的座席在平台未创建，需选取角色信息",
  "ccm.agent.contact.satiscomment": "满意度评价",
  "ccm.note.config.agent.page.windowTip": "弹框提醒",
  "ccm.agent.label.maxAgentWrapupTime": "取值 [0-3600]",
  "ccmanagement.agent.batchmodify.agentlis": "按号段",
  "ccm.agent.message.deletefailed": "删除座席失败",
  "ccm.sysparam.config.select.prompt": "提示",
  "ccm.agent.contact.Voice": "语音",
  "ccm.agent.accesscode.accesscode": "接入码",
  "ccm.custdata.msg.fileSuffixValidateFail": "文件格式错误，请选择一个csv文件",
  "ccm.agent.message.accRepeatFail": "配置的业务帐号重复。",
  "ccm.dual.call.record.agentTalkingTime": "主叫应答时间",
  "ccm.anonymous.message.fail.notmatch": "开启标识与当前状态不匹配",
  "ccm.transtask.delete.confirm": "请确认是否删除,转储任务以及历史任务记录将都将进行删除!",
  "ccm.sysparam.config.itemname": "参数名称",
  "ccm.agent.label.conditionOfBusyTransfer": "遇忙转接条件",
  "ccm.speciallist.title.specialList": "特殊名单",
  "ccm.transtask.label.chatrecordday": "转储多少天之前的聊天数据",
  "ccm.agent.label.ccRole": "角色",
  "ccm.agent.message.samepassword": "新密码不能和当前密码一样",
  "ccm.agent.label.withinFifteenDays": "15天内",
  "ccm.agent.contact.downloadrecord": "下载录音",
  "ccm.agent.message.willcancelconfig": "取消配置将导致正在使用的座席不可用，确认取消配置吗？",
  "ccm.contact.customer.config.bold": "粗体",
  "ccm.botherlist.label.approveStatus.toApprove": "待审批",
  "ccm.dual.call.record.reasonCode": "释放原因",
  "ccm.speciallist.msg.userNo": "用户号码",
  "ccm.agent.message.selected": "您已选中",
  "ccm.speciallist.msg.maxlimit": "超过{limit}条最大记录限制",
  "ccm.agent.pageurls.isenable": "是否启用",
  "ccm.agent.button.createComplete": "完成",
  "ccm.agent.pageurls.delsuccess": "删除成功",
  "ccm.sysparam.config.refresh": "刷新",
  "ccm.contact.customer.config.datasourceType": "数据来源类型",
  "ccm.transtask.label.datatype": "转储数据类型",
  "ccm.custdata.option.maskStyle.maskLast4": "掩码后4位",
  "ccmanagement.satisfaction.strategy.fail.acceptdate.inputnull": "起始日期和结束日期不可以为空",
  "ccm.certificate.management.certificatefile.injectionerror": "文件存在注入风险",
  "ccm.calledconfig.message.noskill": "请选择技能队列",
  "ccm.pageconfig.label.numInRow": "排列个数",
  "ccm.agent.label.CallOutAgent": "外呼座席",
  "ccm.satisfactionconfig.message.number": "编号",
  "ccm.agent.message.searchAccountName": "请输入业务帐号",
  "ccm.agentparam.message.editsuccess": "编辑成功",
  "ccmanagement.satisfaction.strategy.fail.accepttime.inputparse": "起始时间或结束时间格式错误",
  "ccm.calledconfig.message.selectcasetype": "选择工单类型",
  "ccm.agent.calledroute.delete.work.use": "被叫配置被业务代表引用，不能删除",
  "ccm.contactitemconfig.label.data.extended.writeback.extended.field": "回写扩展字段",
  "ccm.ucconfig.message.clientId": "应用程序(客户端)ID",
  "ccm.agent.speciallist.levelName": "特殊名单级别",
  "ccm.certificate.management.update.crlfile": "更新证书吊销文件",
  "ccm.certificate.management.certificatefile.fileempty": "文件为空",
  "ccm.custdata.title.create": "新建基础表",
  "ccm.agent.contact.multimedia.email": "多媒体-Email",
  "ccmanagement.satisfaction.strategy.acceptdate.enddate": "结束日期",
  "ccm.idauth.create.error.format": "验证方式编码只允许数字、字母、下划线",
  "ccm.custdata.option.columnType.number": "数字",
  "ccm.idauth.create.error.name": "验证方式名称已经存在",
  "ccm.agentparam.message.editfail": "编辑失败",
  "ccm.agent.operation.INTERCEPT": "质检拦截",
  "ccm.agent.label.mobileagentremark": "备注",
  "ccm.agent.message.worknostartbig": "起始工号不能大于结束工号",
  "ccm.agent.contact.faceBook": "Facebook",
  "ccmanagement.tenantspace.option.weekday_tue": "星期二",
  "ccm.agent.label.busyRateRange": "取值 [0-100]",
  "ccm.agent.pageurls.operation": "操作",
  "ccm.contactitemconfig.label.data.item.extended": "扩展数据",
  "ccm.sysparam.config.title": "租户参数",
  "ccm.custdata.label.beginTime": "开始时间",
  "ccm.agent.contact.callout": "呼出",
  "ccm.custdata.msg.uploadSelect": "请选择要上传的文件",
  "ccm.agent.contact.mediaType": "媒体类型",
  "ccm.botherlist.label.approveComment.reject": "拒绝",
  "ccm.ccagent.management.advanced.search": "高级搜索",
  "ccm.ccagent.management.batch.operation": "批量操作",
  "ccm.ccagent.management.additions": "添加",
  "ccm.ccagent.management.default.options": "默认选项",
  "ccm.ccagent.management.cannot.exceed.25": "号码不能超过25个字符",
  "ccm.ivrvoice.play.error":"加载资源异常，请检查相关配置",
  "ccm.contact.details.tab.title.audio":'音频',
  "ccm.contact.details.tab.title.video":'视频',
  "ccm.contact.placeholder.searchCustomerNo": '接触号码',
  "ccm.agent.contact.transfercontent": '转写内容',
  "ccm.agent.label.oneday": '最近1天',
  "ccm.agent.label.threedays": '最近3天',
  "ccm.agent.label.sevendays": '最近7天',
  "ccm.agent.label.thirtydays": '最近30天',
  "ccm.contactitemconfig.message.noBaseData":"无可用基础数据",
  "ccm.label.downloadAttachment.confirm":"确认下载所选附件吗？",
  "ccm.contact.detail.talk.content.seachtips":"搜索结果：{num}条",
  "ccm.contact.detail.talk.content.seach.remark":"仅支持搜索页面已展示的文字交谈内容",
  "ccm.contact.detail.talk.content.seach.remark.emaile":"仅支持搜索页面已展示的消息,搜索完成后仅显示匹配消息",
  "ccm.download.passwd.validator.specialStr": "特殊字符仅允许_ {'@'} {'%'}",
  'ccm.agent.contact.line': "LINE",
  "ccm.contact.detail.talk.content.not.support":"搜索文字中不能含有\"/()",
  "ccm.login.record.noMatchingLoginRecordExists": '没有符合条件的签入日志',
  "ccm.agent.message.searchSkillQueueName": "搜索技能队列名称",
  "ccm.agent.button.skill.synchronize": "同步",
  "ccm.skillgroup.label.groupid": "技能组编号",
  "ccm.skillgroup.label.groupname": "技能组名称",
  "ccm.skillgroup.label.orgname": "组织",
  "ccm.agent.label.skillgroup": "技能组",
  "ccm.skillgroup.label.searchname": "技能组名称",
  "ccm.skillgroup.label.groupskills": "关联技能队列",
  "ccm.skillgroup.title.add": "新建技能组",
  "ccm.skillgroup.title.update": "编辑技能组",
  "ccm.skillgroup.message.groupBind": "技能组已绑定的座席类型不能选择当前技能",
  "ccm.agent.message.skillweight.invalid": "技能权重需要在1-100之间",
  "ccm.agent.message.agentweight.invalid": "座席权重需要在1-300之间",
  "ccm.agent.message.skillweight.outlimit": "权重值需要在1-100之间",
  "ccm.agent.message.weight.limit": "[1-100]",
  "ccm.agent.message.agentweight.limit": "[1-300]",
  "ccm.skillgroup.message.skillgroup": "请输入技能组名称",
  "ccm.skillgroup.message.noskill": "技能组技能列表不能为空",
  "ccm.skillgroup.message.nameValidateFail": "技能组不能包含特殊字符[<>\"()]&=;,",
  "ccm.skillgroup.message.namePrefixSuffix": "技能组不能以空格开头或结尾",
  "ccm.skillgroup.message.nameLimit": "技能组名称超长",
  "ccm.skillgroup.message.nameExistence": "技能组名称已经存在",
  "ccm.skillgroup.msg.deleteerror": "请选择待删除的技能组",
  "ccm.skillgroup.create.fail": "技能组创建失败",
  "ccm.skillgroup.create.failtip": "创建技能组失败，请先同步技能队列、技能组数据",
  "ccm.skillgroup.create.limit": "技能组最多创建2000条",
  "ccm.skillgroup.update.fail": "技能组更新失败",
  "ccm.skillgroup.update.failtip": "更新技能组失败，请先同步技能队列、技能组数据",
  "ccm.skillgroup.query.fail": "获取技能组配置失败",
  "ccm.agent.download.forbidden": "无下载权限",
  "ccm.sendmsg.whatsapp.withoutTemplate": "暂无内容，请先选择模板",
  "ccm.title.org.all": "请选择组织",
  'ccm.title.template.select.email': '选择邮件模板',
  'ccm.title.template.select.sms': '选择短信模板',
  "ccm.skillgroup.msg.deleteTips": "当前技能组已经关联坐席{0}，请移除关联关系后删除",
  "ccm.skillgroup.msg.deleteFailed": "技能组删除失败",
  "ccm.agent.label.skillType": "技能类型",
  "ccm.agent.label.selectSkillGroup": "选择技能组",
  "ccm.skill.label.defaultskill": "默认技能队列",
  "ccm.agent.leavemessage.overtime":"处理超时，请重新打开弹窗处理",
  "ccm.notifition.sendmessage.smsconfignull":"短信配置为空。请先在进入“配置中心-接入配置-短信配置”配置短信信息",
  "ccm.notifition.sendmessage.largerthanlimit":"收件人超过系统配置的最大收件人数量{n}个",
  "ccm.callreason.case.check.error": "工单类型{0}不存在",
  "iolp.label.basicInfo": "基础信息",
  "iolp.label.workflow": "工作流程",
  "iolp.workflow.node.title": "设置节点属性成功",
  "iolp.workflow.line.title": "设置连线属性成功",
  "iolp.workflow.node.label.name": "名称",
  "iolp.workflow.node.label.nodeType": "节点类型",
  "iolp.workflow.node.label.nodeId": "节点id",
  "iolp.workflow.node.label.executeType": "执行类型",
  "iolp.workflow.node.label.autoNodeInfo": "自动节点结果对应编号",
  "iolp.workflow.node.label.executeContent": "执行内容",
  "iolp.workflow.node.label.explain": "业务口径",
  "iolp.workflow.node.label.associateKbs": "关联知识",
  "iolp.workflow.node.label.associateBusiness": "关联业务办理链接",
  "iolp.workflow.node.label.hintTextInfo": "描述信息",
  "iolp.workflow.node.label.sms": "短信模板",
  "iolp.workflow.node.label.associateJs": "相关的js功能",
  "iolp.workflow.nodetype.andsign": "任务节点",
  "iolp.workflow.nodetype.fork": "决策节点",
  "iolp.workflow.nodetype.andjoin": "组合",
  "iolp.workflow.executetype.common": "普通类型",
  "iolp.workflow.executetype.SMS": "短信发送",
  "iolp.workflow.executetype.email": "邮件发送",
  "iolp.workflow.executetype.IVR": "转ivr自动流程",
  "iolp.workflow.executetype.auto": "自动任务",
  "iolp.workflow.executetype.subFlow": "转其他业务子流程",
  "iolp.workflow.executetype.case": "创建工单",
  "iolp.configur.service.label.basicInfo": "基础信息",
  "iolp.configur.service.label.workflow": "引导流程",
  "iolp.configur.service.label.name": "名称",
  "iolp.configur.service.label.workflow.name": "流程名称",
  "iolp.configur.service.label.directory.name": "类别名称",
  "iolp.configur.service.label.serviceCode": "业务编码",
  "iolp.configur.service.label.upperLevelServiceCode": "上级业务编码",
  "iolp.configur.service.label.imageUrl": "图片地址",
  "iolp.configur.service.label.customizedPageUrl": "定制页面地址",
  "iolp.configur.service.label.customizedPageContainerId": "定制页面容器标识",
  "iolp.configur.servicelabel.shortcutButtonPage": "快捷按钮页面地址",
  "iolp.configur.service.label.shortcutButtonContainerId": "快捷按钮页面容器标识",
  "iolp.configur.service.label.search.tips": "请输入名称",
  "iolp.configur.service.add": "新建",
  "iolp.configur.service.operate.edit": "编辑",
  "iolp.configur.service.operate.delete": "删除",
  "iolp.configur.service.tips.customizedPageUrl": "示例：CustomerInfoDemo.html",
  "iolp.configur.service.add.select.title": "选中类别名称",
  "iolp.configur.service.add.parent.title": "父类别名称",
  "iolp.configur.service.dialog.root.directory.title": "无父类别",
  "iolp.configur.service.add.operate.title": "新增类型",
  "iolp.configur.service.dialog.add.directory.title": "新建业务引导类别",
  "iolp.configur.service.dialog.modify.directory.title": "编辑业务引导类别",
  "iolp.configur.service.dialog.add.workflow.title": "新建业务引导流程",
  "iolp.configur.service.dialog.modify.workflow.title": "编辑业务引导流程",
  "iolp.configur.servicelabel.label.serialNumber": "排序序号",
  "iolp.configur.servicelabel.label.status": "状态",
  "iolp.configur.servicelabel.label.description": "描述信息",
  "iolp.configur.servicelabel.button.saveDraft": "存为草稿",
  "iolp.configur.servicelabel.status.draft": "草稿",
  "iolp.configur.servicelabel.button.publish": "已发布",
  "iolp.configur.servicelabel.common.tips.error": "错误",
  "iolp.configur.servicelabel.common.tips.success": "成功",
  "iolp.tree.tips": "您无法在叶节点下添加任何节点。",
  "iolp.configur.servicelabel.save.successful": "保存成功",
  "iolp.configur.servicelabel.release.successful": "发布成功",
  "iolp.configur.servicelabel.save.failed": "保存失败",
  "iolp.configur.servicelabel.release.failed": "发布失败",
  "iolp.configur.servicelabel.contact.administrator": "服务编码生成失败，请联系管理员",
  "iolp.configur.servicelabel.select.node": "请选择一个节点",
  "iolp.configur.servicelabel.confirm.delete": "删除",
  "iolp.configur.servicelabel.confirm.deleteAll": "确认要删除当前节点及其子节点吗？",
  "iolp.configur.servicelabel.confirm.deleteCurrent": "确认要删除当前节点吗？",
  "iolp.configur.servicelabel.delete.successful": "删除成功",
  "iolp.configur.servicelabel.delete.failed": "删除失败",
  "iolp.configur.servicelabel.tips.info": "提示",
  "iolp.workflow.label.graphZoomOut": "缩小",
  "iolp.workflow.label.graphZoomIn": "放大",
  "iolp.workflow.label.previous": "撤销",
  "iolp.workflow.label.next": "恢复",
  "iolp.workflow.label.cleanGraph": "清空画布",
  "iolp.workflow.label.taskElement": "功能图元",
  "iolp.workflow.label.decisionElement": "分支图元",
  "iolp.workflow.label.operation": "操作",
  "iolp.workflow.label.information": "关联信息",
  "iolp.workflow.label.unmatchedFlow": "无法匹配对应的业务",
  "iolp.workflow.button.restart": "重启流程",
  "iolp.workflow.button.hideFlowChart": "隐藏流程图",
  "iolp.workflow.button.showFlowChart": "显示流程图",
  "iolp.workflow.label.currentNodeName": "当前节点名称",
  "iolp.workflow.label.customizeInfo": "定制化信息",
  "iolp.workflow.label.flowInfo": "业务流程信息",
  "iolp.workflow.button.kbs": "知识库",
  "iolp.workflow.button.service": "业务办理",
  "iolp.workflow.title.name": "业务引导",
  "iolp.configur.service.label.service.title": "业务类别",
  "iolp.run.service.label.customizedPage": "定制信息",
  "iolp.run.service.label.overview": "流程全览",
  "iolp.run.service.label.nodeName": "节点名称：",
  "agentconsole.ccnotification.recipient.validate.error": "输入号码每个都只能数字，且最大长度只能为20",
  "ccm.sendwhatsapp.content.too.long": "模板内容过长",
  "ccm.sendwhatsapp.body.too.long": "正文内容过长",
  "ccm.sms.meeting.link": "会议链接短信",
  "ccm.agent.callreason.must": "来电原因必填",
  "iolp.workflow.efficiency.analysis":"效率分析",
  "iolp.configur.servicelabel.label.version": "发布版本",
  "iolp.configur.servicelabel.label.version.number": "版本数",
  "iolp.configur.servicelabel.status.abandon": "失效",
  "iolp.configur.servicelabel.status.publish": "发布",
  "iolp.configur.servicelabel.status.unpublish": "未发布",
  "iolp.configur.service.operate.copy": "复制",
  "iolp.configur.service.operate.publish": "发布",
  "iolp.configur.service.operate.cancel": "取消发布",
  "iolp.configur.servicelabel.publish.successful": "发布成功",
  "iolp.configur.servicelabel.publish.failed": "发布失败",
  "iolp.configur.servicelabel.cancel.successful": "取消发布成功",
  "iolp.configur.servicelabel.cancel.failed": "取消发布失败",
  "iolp.configur.servicelabel.copy.successful": "复制成功",
  "iolp.configur.servicelabel.copy.failed": "复制失败",
  "iolp.configur.servicelabel.confirm.delete.flow": "确认要删除当前版本吗？",
  "iolp.process.utilization.efficiency.analysis":"流程使用效率分析",
  "iolp.flow.number.of.contacts":"接触数（条）",
  "iolp.flow.first.solution.rate":"首解率（%）",
  "iolp.flow.please.select.time.before.today":"请选择今天之前的时间",
  "iolp.flow.the.interval.validate":"时间间隔为1天或者3-30天",
  "iolp.flow.analysis.date.time":"日期/时间",
  "ccm.empty.template": "模板不存在，请检查通知模板",
  "ccm.empty.variables": "模板变量不存在，请检查通知模板",
  "ccm.no.meeting.variables": "模板变量不存在 Meeting_URL 的变量，请检查通知模板",
  "ccm.agent.meeting.failed": "座席创建会议失败",
  "ccm.agent.link.failed": "座席生成短信链接失败",
  "ccm.agent.meeting.receiver.check.error": "收件人不能超过1个",
  "ccm.agent.contact.timeouthungup": "超时关闭",
  "ccm.agent.contact.skilltimeouthungup": "超时转技能队列",
  "ccm.agent.sms.meeting.gateway.commonerror": "创建会议失败，错误码 {n}",
  "ccm.agent.sms.meeting.gateway.error": "创建会议失败，错误码 {n}，请不要在静音，保持，多方通话等状态下创建会议",
  "ccm.contactRecordDetail.notSetTraceLogError": "未查询到流程轨迹，请增加跟踪设置后，再查看对应接触记录的流程轨迹",
  "ccm.label.appcube.switch": "appcube集成页面",
  "ccm.label.appcube.app": "选择应用",
  "ccm.label.appcube.menu": "选择菜单",
  "ccm.contactitemconfig.label.data.item.sequence.number.seq": "字段顺序",
  "ccm.contactitemconfig.label.data.item.add": "新建接触记录扩展字段",
  "ccm.contactitemconfig.label.data.item.edit": "编辑接触记录扩展字段",
  "ccm.contactitemconfig.label.data.item.tips": "数据集来源接口需要在接口管理中进行配置，请联系系统运营管理员。",
  "ccm.contactitemconfig.label.data.item.basic.item": "基础扩展字段",
  "ccm.contactitemconfig.label.data.item.custom.item": "自定义扩展字段",
  "ccm.contactitemconfig.label.dataInfo.item.add": "新建字段",
  "ccm.contactitemconfig.label.dataInfo.item.edit": "编辑字段",
  "ccm.custdata.label.data.table.template": "数据表模板",
  "ccm.custdata.msg.uploadType": "重复数据导入方式",
  "ccm.custdata.msg.uploadTypeInfo": "若有重复数据，选择覆盖原数据则更新数据记录，选择不覆盖原数据则作为失败数据",
  "ccm.custdata.msg.uploadType.cover": "覆盖原有数据",
  "ccm.custdata.msg.uploadType.notcover": "不覆盖原有数据",
  "ccm.pageconfig.label.page": "页面",
  "ccm.pageconfig.label.publishpage": "发布页面",
  "ccm.transtask.label.intelligentInspectionConfig":"转储智能质检数据配置",
  "ccm.validate.portLengthError":"端口范围应为1-65535",
  "ccm.resource.dump.server.add":"新建资源转储服务器",
  "ccm.resource.dump.server.edit":"编辑资源转储服务器",
  "ccm.resource.dump.server.view":"查看资源转储服务器",
  "ccm.resource.dump.css.add":"新建云搜索服务器",
  "ccm.resource.dump.css.edit":"编辑云搜索服务器",
  "ccm.resource.dump.css.view":"查看云搜索服务器",
  "ccm.resource.dump.task.add":"新建资源转储任务",
  "ccm.resource.dump.task.edit":"编辑资源转储任务",
  "ccm.resource.dump.task.view":"查看资源转储任务",
  "ccm.transtask.label.intelligentInspectionDay":"转储几天之前的智能质检数据",
  "ccm.transtask.css.server.num":"云搜索服务器数量不能超过1",
  "ccm.contact.customer.config.num":"客户信息栏数据不能超过10个",
  "ccm.export.password.error.tips": "压缩文件密码8-12位，必须包含字母，数字，特殊字符{'@'}#$%^&*+{'|'}=~!?,.:;-_'\"(){'{'}{'}'}[]/><",
  "ccm.export.password.error.message": "必须包含字母，数字，特殊字符{'@'}#$%^&*+{'|'}=~!?,.:;-_'\"(){'{'}{'}'}[]/><",
  "ccm.agent.ccagentinfo.modifyselfphone.success":"修改固话/手机号成功",
  "ccm.agent.ccagentinfo.modifyselfphone.fail":"修改固话/手机号失败",
  "ccm.export.table.taskTime": "任务时间",
  "ccm.export.table.taskStatus": "任务状态",
  "ccm.agent.contact.multimedia.sms":"\u591A\u5A92\u4F53-SMS",
  "ccm.agent.label.skill":"技能",
  "ccm.agent.button.randomRestSoftPhonePwd":"重置软电话密码",
  "ccm.agent.label.skillGroup":"技能组",
  "ccm.agent.message.searchSkillGroupName":"技能组名称",
  "ccm.skill.label.skillGroupName":"技能组名称",
  "ccm.agent.button.restSoftPhonePwd.remark":"批量将座席软电话密码重置为系统随机密码，需座席重置自身软电话密码后才可使用软电话功能。管理员也可在列表中点击'重置软电话密码'单独为座席手动修改密码。",
  "ccm.agent.management.exporting.agent.information":"导出座席信息",
  "ccm.agent.clear.message.clearcommonerror":"取消配置失败，服务故障",
  "ccm.contactitemconfig.label.dataInfo.item.name.exist": "字段名称已存在",
  "ccm.dual.call.callback.url.config":"回调URL配置",
  "ccm.dual.call.callback.url.auth":"接口调用URL鉴权",
  "ccm.dual.call.callback.url.authType":"回调URL鉴权方式",
  "ccm.dual.call.callback.url.ita":"ITA参数配置",
  "ccm.dual.call.callback.url.xNumberType":"X号码分配模式",
  "ccm.agent.management.select.agent":"按工号选择座席",
  'ccm.password.length.limit.min': '密码过短，请输入{min}-{max}个字符',
  'ccm.password.length.limit.max': '密码过长，请输入{min}-{max}个字符',
  'ccm.mobile.agent.front.add': '新建移动客服',
  'ccm.mobile.agent.front.edit': '编辑移动客服',
  'ccm.mobile.agent.front.checkNum': '请至少选择一个号码',
  'ccm.mobile.agent.front.maxNum': '不能超过15个号码',
  'ccm.contactitemconfig.label.contactData.add': '新建接触记录数据集',
  'ccm.contactitemconfig.label.contactData.edit': '编辑接触记录数据集',
  'ccm.agent.button.downloading':'下载中...',
  'ccm.label.operationexport.nodata': '没有符合条件的操作记录',
  'ccm.label.transferexport.nodata': '没有符合条件的转接记录',
  'ccm.label.leaveexport.nodata': '没有符合条件的休息记录',
  'ccm.label.send.whatsapp': '发送Whatsapp',
  "ccm.callcenterinstanceslist.vcallcenterDeatil.specialStrValidateFailed": "不允许包含特殊字符",
  'ccm.common.validation.numbers': '请输入纯数字',
  'ccm.common.validation.click.number': '请点击数字',
  'ccm.ccagent.agent.weight.tips': '数值越大，优先级越高，当存在多个空闲座席，系统将来话优先分配给权值高的。',
  'ccm.ccagent.skill.weight.tips': '数值越大，优先级越高，当多个队列有来话，系统将权值高的队列的来话分配给该空闲座席。',
  'ccm.ccagent.batch.modify.special.character.verification': '不能包含’',
  'ccm.resource.dump.obs.cert.warn.tip': '请联系运维在证书库中导入obs的信任证书',
  "ccm.audiovideoworkbench.mood" : "客户情绪",
  "ccm.audiovideoworkbench.intention" : "意图",
  "ccm.audiovideoworkbench.complain" : "投诉倾向",
  "ccm.audiovideoworkbench.aiswitch" : "大语言模型",
  "ccm.audiovideoworkbench.airecommendation" : "智能推荐",
  "ccm.audiovideoworkbench.case.list" : "工单列表",
};