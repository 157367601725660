export default {
  "webclient.body.message.bot.recommend": "Devinez que vous voulez demander",
  "webclient.body.message.card.url": "Copier le lien",
  "webclient.body.message.cobrowse.agree": "D'accord",
  "webclient.body.message.cobrowse.cancel": "Annulation",
  "webclient.body.message.cobrowse.target": "Parcourir les objectifs:",
  "webclient.body.message.cobrowse.title": "Demande de siège co - navigation avec vous",
  "webclient.body.message.file.download": "Téléchargement",
  "webclient.body.message.file.download.again": "Télécharger à nouveau",
  "webclient.body.message.locate.latitude": "Latitude",
  "webclient.body.message.locate.longitude": "Longitude",
  "webclient.body.message.recall": "Un message retiré",
  "webclient.body.name.assistant": "Assistant Intelligent",
  "webclient.body.tip.leave.click": "Cliquez ici pour laisser un message",
  "webclient.body.tip.leave.message": "S'il vous plaît",
  "webclient.body.tip.leave.message.finish": "Votre message a été envoyé avec succès, vous pouvez laisser un autre message.",
  "webclient.body.tip.privacy": "Pour améliorer la qualité du service, nous conservons un historique de vos conversations avec les représentants du service clientèle, les bots et nous prenons des mesures de cryptage, de contrôle des droits d'accès, etc. pour assurer la sécurité des données.",
  "webclient.body.tip.queue.cancel": "Annuler la file d'attente",
  "webclient.body.tip.queue.cancel.done": "Annulation de la file d'attente réussie, re - file d'attente veuillez continuer à envoyer le message",
  "webclient.body.tip.queue.cancel.robot": "La mise en file d'attente est réussie, le robot est connecté...",
  "webclient.body.tip.queue.part1": "Vous êtes actuellement en ",
  "webclient.body.tip.queue.part2": " ème position,Attendez encore ",
  "webclient.body.tip.queue.part3": " secondes. ",
  "webclient.body.tip.queue.timeout": "La file d'attente a expiré",
  "webclient.body.tip.session.end": "La session est terminée",
  "webclient.body.tip.session.reconnect": "redémarrer la session",
  "webclient.body.tip.session.timeout": "Vous n'avez pas répondu dans le délai imparti et le système a automatiquement mis fin à la session. Vous pouvez",
  "webclient.body.tip.token.invalid": "Token est invalide ou a atterri ailleurs",
  "webclient.body.title.call.disconnect": "L'appel est déconnecté",
  "webclient.body.title.call.end": "Appel terminé",
  "webclient.body.title.call.fail": "Échec de l'appel",
  "webclient.body.title.call.queue": "Dans la file d'attente",
  "webclient.body.title.call.start": "Appeler",
  "webclient.body.title.call.timeout": "Délai d'attente de l'appel",
  "webclient.body.title.call.transfer": "Transfert d'appel",
  "webclient.box.tip.click.cannot.link": "Impossible de se connecter à la passerelle webrtc",
  "webclient.box.tip.click.check.device.fail": "Vérifiez si le click-to-call échoue",
  "webclient.box.tip.click.no.audio.input": "Périphérique d'entrée audio non disponible",
  "webclient.box.tip.click.no.audio.output": "Périphérique de sortie audio non disponible",
  "webclient.box.tip.click.no.video.input": "Périphérique d'entrée vidéo non disponible",
  "webclient.box.tip.click.not.support.browser": "Webrtc n'est pas supporté par les navigateurs actuels",
  "webclient.box.tip.click.not.support.channel": "Le canal actuel ne prend pas en charge click-to-call",
  "webclient.box.tip.click.not.support.tenant": "WebRTC non pris en charge par le locataire",
  "webclient.box.tip.cobrowse.no.auth": "L'agent n'a pas l'autorisation de co-navigation",
  "webclient.chat.nickname.system": "Système",
  "webclient.dialog.evaluate.placeholder": "Veuillez entrer votre évaluation",
  "webclient.dialog.evaluate.title": "Veuillez évaluer votre service à la clientèle!",
  "webclient.dialog.leave.format.content": "Le contenu du message ne peut pas être vide et ne peut pas contenir de caractères spéciaux",
  "webclient.dialog.leave.format.phone": "Le numéro ne peut pas être vide et ne peut contenir que des chiffres, - ou +",
  "webclient.dialog.leave.phone": "Veuillez entrer un numéro de téléphone portable",
  "webclient.dialog.leave.success": "Message laissé avec succès",
  "webclient.dialog.leave.text": "Veuillez laisser un message",
  "webclient.dialog.leave.title": "Message",
  "webclient.dialog.selectArea.title": "Veuillez sélectionner la zone à bloquer",
  "webclient.dialog.video.default": "Par défaut",
  "webclient.dialog.video.hide": "Cacher les vidéos locales",
  "webclient.dialog.video.large": "Grande taille",
  "webclient.dialog.video.medium": "Taille moyenne",
  "webclient.dialog.video.pause": "Pause vidéo",
  "webclient.dialog.video.share.off": "Arrêter le partage",
  "webclient.dialog.video.share.on": "Partage de bureau",
  "webclient.dialog.video.size": "Petits ajustements vidéo",
  "webclient.dialog.video.small": "Petite taille",
  "webclient.dialog.video.start": "Lancer la vidéo",
  "webclient.footer.label.send.message": "Envoyer un message",
  "webclient.footer.label.share.direct": "Partage direct",
  "webclient.footer.label.share.sensitive": "Sélectionner les zones sensibles",
  "webclient.footer.label.stop.direct": "Arrêter le partage",
  "webclient.footer.placeholder.richtext": "Veuillez entrer la question que vous souhaitez poser",
  "webclient.footer.send.fail.content.big": "Le contenu envoyé dépasse la longueur maximale (500 caractères)",
  "webclient.footer.send.fail.file.format": "Format de fichier non conforme",
  "webclient.footer.send.fail.file.other": "Impossible d'envoyer le fichier",
  "webclient.footer.send.fail.file.screen": "Impossible d'envoyer le fichier de capture d'écran",
  "webclient.footer.send.fail.file.size.audio": "La taille audio ne peut pas dépasser ",
  "webclient.footer.send.fail.file.size.common": "La taille du fichier ne peut pas dépasser ",
  "webclient.footer.send.fail.file.size.video": "La taille de la vidéo ne peut pas dépasser ",
  "webclient.footer.send.fail.no.file": "Veuillez sélectionner un fichier",
  "webclient.footer.send.fail.picture.with.text": "L'envoi de messages avec des images mélangées avec du texte ou des expressions n'est pas pris en charge",
  "webclient.footer.tip.cobrowse.share": "L'action en cours partage la page de votre navigateur, peut contenir vos données personnelles, continuez - vous?",
  "webclient.footer.tip.connect.fail": "La connexion a échoué, réessayez ou contactez l'Administrateur",
  "webclient.footer.tip.download": "Le contenu de chat que vous téléchargez est au format HTML, et le contenu multimédia dans le contenu de chat est sensible au temps. Il est recommandé de le convertir en fichier PDF immédiatement après son téléchargement. Étapes pour convertir HTML en PDF: 1. Ouvrez le fichier HTML que vous souhaitez convertir dans votre navigateur; 2. Appuyez sur Ctrl+P (Windows) ou Commande+P (Mac) pour ouvrir la boîte de dialogue d'impression; 3. Dans la boîte de dialogue Imprimer, sélectionnez Enregistrer sous PDF pour l'imprimante cible; 4. Cliquez sur le bouton Enregistrer pour convertir la page HTML au format PDF et l'enregistrer sur votre ordinateur local.",
  "webclient.footer.tooltip.audio": "L'audio",
  "webclient.footer.tooltip.cobrowse": "Partagez",
  "webclient.footer.tooltip.download": "Télécharger des conversations",
  "webclient.footer.tooltip.emotion": "Émotion",
  "webclient.footer.tooltip.evaluation": "Évaluer",
  "webclient.footer.tooltip.file": "Télécharger des fichiers",
  "webclient.footer.tooltip.more": "Plus",
  "webclient.footer.tooltip.send": "Envoyer",
  "webclient.footer.tooltip.send.way": "Mode d'envoi",
  "webclient.footer.tooltip.video": "Vidéo",
  "webclient.header.title.input": "L'autre personne entre",
  "webclient.header.title.service": "Service à la clientèle",
  "webclient.header.tooltip.close": "Fermer",
  "webclient.header.tooltip.transfer.agent": "Transfert artificiel",
  "webclient.header.tooltip.transfer.robot": "Transfert au robot",
}