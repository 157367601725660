/**
 * 技能队列对象
 */
export class SkillInfo {
    id: string = '';
    skillId: number = NaN;
    name: string = '';
    description: string = '';
    maxWaitTime: number= NaN;
    maxWaitNum: number= NaN;
    mediaTypeId: number= NaN;
    subMediaTypeId: string = '';
    adjustTime: number= NaN;
    skillpara: string = '';
}