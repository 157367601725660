const cmsRouter = {
  default :[
    {
      path: '/record-playback',
      name: '6016002',
      title: '录音回放',
      component: () => import('./components/record-playback/record-playback-main.vue')
    },
    {
      path: '/calldetail-original',
      name: '6016007',
      title: '呼叫明细监控',
      component: () => import('./components/calldetail-original/calldetail-original.vue')
    },
    {
      path: '/dual-call-detail-report',
      name: '6016009',
      title: '双呼详情',
      component: () => import('./components/report/dual-call-detail-report.vue')
    },
    {
      path: '/calllinkmonitor/calllinkMonitor',
      name: '6016006',
      title: '呼叫全链路监控',
      component: () => import('./components/calllinkmonitor/calllinkMonitor.vue'),
    },
    {
      path: '/incoming-call-record-query',
      name: '6016005',
      title: '来话记录查询',
      component: () => import('./components/incoming-call-record-query/index.vue')
    },
    {
      path: "/agent-monitor",
      name: '6016001',
      title: '座席监控',
      component:()=>import('./components/agent-monitor/agent-monitor.vue')
    },
    {
      path: '/indicator-monitor',
      name: "6016003",
      title: '指标监控',
      component:()=>import('./components/indicator-monitor/index.vue')
    },
  ],
  pageWithSideNav: [
    {
      path: '/quality-check',
      name: "8009001",
      title: '监控质检关系',
      component:()=>import('./components/quality-check/quality-check-main.vue')
    },
    {
      path: '/fullscreen-configuration',
      name: "8009002",
      title: '大屏监控',
      component:()=>import('./components/fullscreen-configuration/index.vue')
    },
    {
      path: '/report-subscribe',
      name: "8009003",
      title: '报表订阅',
      component:()=>import('./components/report-subscribe/index.vue')
    },
    {
      path: '/config-center/parameter-configuration',
      name: '8011051',
      title: '参数配置',
      component: () => import('./components/parameter-configuration/index.vue')
    },
    {
      path: '/sys-config',
      name: "8011009",
      title: 'CMS系统配置',
      component:()=>import('./components/sys-config/index.vue')
    }
  ],
  pageWithBackHeader: [
    {
      path: '/calllinkmonitor/callTrace',
      name: 'callTrace',
      title: '通话轨迹',
      component: () => import('./components/calllinkmonitor/callTrace.vue')
    },
    {
      path: '/agent-monitor/agent-monitor-detail',
      name: 'agent-monitor-detail',
      title: '座席监控',
      component: () => import('./components/agent-monitor/agent-monitor-detail.vue')
    },
    {
      path: '/report-subscribe/subscribe-detail',
      name: 'subscribe-edit',
      title: '编辑订阅详情',
      component: () => import('./components/report-subscribe/subscribe-edit.vue')
    },
  ],
  pageWithSelfTab: [
    {
      path: '/report-subscribe/subscribe-add',
      name: 'subscribe-add',
      title: '新增报表订阅',
      component: () => import('./components/report-subscribe/subscribe-add.vue')
    },
  ]
}

export default cmsRouter