<template>
    <sweet-dialog v-model="dialogVisible" :title="title" width="616px" class="aicc-dialog-limit-height"
                  :close-on-click-modal="false">
        <sweet-form :model="showModel" :rules="formRules" ref="isalesOutBoundForm" :disabled="this.mode == 'view'"
                    label-width="auto" :label-position="labelPosition" class="maxForm">
            <sweet-form-item class="padding-left-10px" :label="$t('isales.result.displayResult')" required
                             prop="displayResult">
                <sweet-input v-model="showModel.displayResult" autocomplete="off" style="width: 400px;"/>
            </sweet-form-item>
            <sweet-form-item class="padding-left-10px" :label="$t('isales.result.resultCode')" prop="resultCode">
                <sweet-input :disabled="mode == 'edit'" v-model="showModel.resultCode" autocomplete="off" style="width: 400px;"/>
            </sweet-form-item>
            <sweet-row>
                <sweet-col :span="24">
                    <sweet-form-item class="padding-left-10px">
                        <template #label><span>{{ $t('isales.result.outgoingCallResult') }}</span></template>
                        <div style="width: 450px">
                            <aicc-table ref="aiccTable"
                                        :tableData="tableList"
                                        :tableColumns="tableColumns"
                                        :tableTools="{ showPagination: false, columnConfig: false}"
                                        :paginationConfig="paginationConfig"
                                        :max-height="180"
                                        class="height-auto"
                                        @handlePagination="handlePagination">
                                <!--模版名称-->
                                <template #failCode="scope">
                                    <div style="cursor: pointer">
                                        {{ scope.row.failCode }}
                                    </div>
                                </template>
                                <template #description="scope">
                                    <div style="cursor: pointer">
                                        {{ ALL_REASON_CODE_MAP[scope.row.failCode] }}
                                    </div>
                                </template>
                                <!--操作-->
                                <template #action="scope">
                                    <sweet-button type="primary" text @click="deleteResultMap(scope.row)">{{
                                        $t('isales.specialday.title.delete') }}
                                    </sweet-button>
                                </template>
                            </aicc-table>
                            <div class="addInfo">
                                <sweet-button @click="addResultMap"  class="task-icon button-class" :icon="CirclePlus" type="icon">
                                    <span class="create-button">{{ $t('isales.file.title.create')}}</span>
                                </sweet-button>
                            </div>
                        </div>
                    </sweet-form-item>
                </sweet-col>
            </sweet-row>
        </sweet-form>
        <template #footer>
        <span class="dialog-footer">
            <sweet-button @click="dialogVisible = false">{{ $t('isales.common.title.cancel') }}</sweet-button>
            <sweet-button type="primary" @click="confirm">{{ $t('isales.common.title.confirm') }}</sweet-button>
        </span>
        </template>
    </sweet-dialog>
    <outboundResultMapAdd v-if="addoutboundResultMapVisible" v-model:show="addoutboundResultMapVisible"
                          @confirm="confirmoutboundResultMap"
                          :selectedAttributes=selectedAttributes>
    </outboundResultMapAdd>
</template>

<script>
  import {ALL_REASON_CODE_LIST, ALL_REASON_CODE_MAP} from "@/views/isales/views/outboundresult/enum.js";
  import outboundResultMapAdd from "@/views/isales/views/outboundresult/outboundResultMapAdd.vue";
  import {createObResult, queryObResultDetail, updateObResult} from "@/views/isales/api/outboundResultManage";
  import {deepClone} from "@vitest/utils";
  import {CirclePlus} from '@element-plus/icons-vue'
  export default {
    name: "isalesOutbountResultCreate",
    components: {
      outboundResultMapAdd,
    },
    props: {
      show: {
        type: Boolean,
        default: false
      },
      mode: String,
      title: String,
      outBoundResult: {
        type: Object
      }
    },
    computed: {
      dialogVisible: {
        get() {
          return this.show
        },
        set(val) {
          this.$emit('update:show', val)
        }
      },
    },
    async created() {
      if (this.mode == "view" || this.mode == "edit") {
        await this.initResult();
      }
    },
    data() {
      const checkSpecialChar = (rule, value, callback) => {
        if (this.$Validate.hasSpecialChar(value)) {
          callback($t('isales.business.result.message.specialStr') + "`~!@#$%^&*()+=|{}'\":;\\[\\]/?.~！@#￥%……&*（）——+|{}【】‘；：”“’,、？")
        } else {
          callback()
        }
      }
      return {
        CirclePlus,
        labelPosition: window.isRTL ? 'right' : 'left',
        selectedAttributes: [],
        deleteResultMapIdList: [],
        oldResultMapObject: {},
        addoutboundResultMapVisible: false,
        ALL_REASON_CODE_LIST,
        ALL_REASON_CODE_MAP,
        tableList: [],
        showModel: {
          displayResult: '',
          resultCode: '',
          resultMapList: []
        },
        formRules: {
          displayResult: [
            {
              required: true,
              message: this.$t('isales.business.result.message.must'),
              trigger: ['blur', 'change']
            },
            {maxLength: 200, validator: this.$Validate.maxLengthValidator, trigger: ['blur', 'change']},
            {validator: checkSpecialChar, trigger: ['blur', 'change']}
          ],
          resultCode: [
            {maxLength: 8, validator: this.$Validate.maxLengthValidator, trigger: ['blur', 'change']},
            {validator: this.$Validate.checkRegCheckNumAndAbcNull, trigger: ['blur', 'change']}
          ]
        },
        tableColumns: [
          {
            label: this.$t('isales.result.outgoingCall.failCode'), // 模版名称
            slot: 'failCode',
            'min-width': '5px'
          },
          {
            label: this.$t('isales.result.outgoingCall.Description'), // 任务类型
            slot: 'description',
            'min-width': '250px'
          },
          {
            label: this.$t('isales.common.title.operation'), // 操作
            slot: 'action',
            fixed: 'right',
          }
        ],
        paginationConfig: {
          offset: 0,
          pageSize: 10,
          limit: 10, // 每页条数
          total: 0, //总条数
          layout: "total, prev, pager, next"
        },
      }
    },
    methods: {
      addResultMap() {
        this.addoutboundResultMapVisible = true;
      },
      async getList() {
        const {offset, limit} = this.paginationConfig;
        if (this.showModel.resultMapList.length > 0) {
          let tempInputCallNoList = deepClone(this.showModel.resultMapList);
          this.paginationConfig.total = tempInputCallNoList.length;
          this.tableList = tempInputCallNoList.splice(offset, limit);
        } else {
          this.paginationConfig.total = 0;
          this.tableList = [];
        }
      },
      async handlePagination(params) {
        Object.assign(this.paginationConfig, params)
        await this.getList()
      },
      async deleteResultMap(row) {
        if (row && row.mapId) { // 编辑使用
          this.deleteResultMapIdList.push(row.mapId);
        }
        if (this.showModel.resultMapList && this.showModel.resultMapList.length > 0) {
          let index = this.showModel.resultMapList.findIndex(item => item.failCode == row.failCode);
          this.showModel.resultMapList.splice(index, 1);
        }
        if (this.selectedAttributes && this.selectedAttributes.length > 0) {
          let index = this.selectedAttributes.findIndex(item => item == row.failCode);
          this.selectedAttributes.splice(index, 1);
        }
        this.getList();
      },
      confirmoutboundResultMap(data) {
        data.forEach(item => {
          let tempObj = {
            failCode: item.value,
            description: item.label
          }
          if (this.oldResultMapObject[item.value]) {
            tempObj["mapId"] = this.oldResultMapObject[item.value];
            let index = this.deleteResultMapIdList.indexOf(this.oldResultMapObject[item.value]);
            if (index >= 0) {
              this.deleteResultMapIdList.splice(index, 1);
            }
          }
          this.showModel.resultMapList.push(tempObj);
          this.selectedAttributes.push(item.value);
        });
        this.getList();
      },
      async confirm() {
        if (this.mode == 'view') {
          this.$emit('isalesOutbountResultCreateConfirm');
          this.dialogVisible = false;
          return;
        }
        if (!this.showModel.resultMapList || this.showModel.resultMapList.length <= 0) {
          this.$message({
            type: 'error',
            duration: 10000,
            offset: 56,
            'show-close': true,
            message: this.$t('isales.result.obResultIsNull')
          });
          return;
        }
        let aiccTableResult = await this.$refs.isalesOutBoundForm.validate().catch(err => err);
        if (aiccTableResult !== true) {
          return;
        }
        this.showModel.resultMapList.forEach(item => {
          let temp = {
            "level": 0
          }
          item['__customize__'] = temp;
          delete item['description'];
        });
        if (this.mode == "create") {
          let addResult = await createObResult(this.showModel);
          if (addResult && addResult.resultCode == "0202000") {
            this.$message({
              type: 'success',
              duration: 5000,
              offset: 56,
              message: addResult.resultDesc
            });
          } else {
            this.$message({
              type: 'error',
              duration: 10000,
              offset: 56,
              'show-close': true,
              message: addResult.resultDesc
            });
          }
        } else {
          this.showModel['defId'] = this.outBoundResult.defId;
          this.showModel['deleteResultMapIdList'] = this.deleteResultMapIdList;
          let updateResult = await updateObResult(this.showModel);
          if (updateResult && updateResult.resultCode == "0202000") {
            this.$message({
              type: 'success',
              offset: 56,
              duration: 5000,
              message: updateResult.resultDesc
            });
          } else {
            this.$message({
              type: 'error',
              duration: 10000,
              offset: 56,
              'show-close': true,
              message: updateResult.resultDesc
            });
          }
        }
        this.$emit('isalesOutbountResultCreateConfirm');
        this.dialogVisible = false
      },
      async initResult() {
        this.selectedAttributes = [];
        this.oldResultMapObject = {};
        let params = {
          "defId": this.outBoundResult.defId
        }
        let result = await queryObResultDetail(params);
        if (result && result.resultCode == "0202000") {
          this.showModel.displayResult = result.data.displayResult;
          this.showModel.resultCode = result.data.resultCode;
          this.showModel.resultMapList = result.data.resultMapList;
          for (let tempData of this.showModel.resultMapList || []) {
            this.selectedAttributes.push(tempData.failCode);
            this.oldResultMapObject[tempData.failCode] = tempData.mapId;
          }
          this.getList();
        } else {
          this.$message({
            type: 'error',
            duration: 10000,
            offset: 56,
            'show-close': true,
            message: result.resultDesc
          });
        }
      }
    }
  }
</script>

<style lang="less" scoped>
    .buttonMargin {
        margin-left: auto;
    }

    .padding-left-10px {
        padding-left: 10px;
    }

    .maxForm {
        max-height: 365px;
        overflow-y: auto;
    }
    .addInfo {
        width: 100%;
        height: 45px;
        border: 1px solid rgba(238, 238, 238, 1);
        border-top: 0;
        display: flex;
        padding-inline-start: 1rem;
        font-size: 14px;
        color: #366BFC;
        font-weight: bold;
        align-items: center;
        &-wrap {
             display: flex;
             gap: 10px;
         }
    }
    .task-icon {
        font-size: 16px !important;
        color: #366BFC;
    }
    .button-class{
        margin-right: 12px;
    }
</style>