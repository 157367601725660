<template>
    <!--呼叫顺序-->
    <sweet-form-item :label="$t('isales.label.calldata.order')">
        <sweet-radio-group v-for="(item, index) in callDataOrderTypeItem" :key="item.index" style="margin-left:10px"
                           v-model="showModel.callPolicy.callOrder" :disabled="isBatchEdit">
            <sweet-radio :label="item.value" style="margin-right:32px">{{ $t(item.label)}}</sweet-radio>
        </sweet-radio-group>
    </sweet-form-item>
    <sweet-row>
        <!--按数据属性-->
        <sweet-col :span="24" v-if="showModel.callPolicy.callOrder===callDataOrderTypeItem[2].value" class="isales-background-color">
            <sweet-form-item>
                <template #label><span></span></template>
                <div style="width: 750px">
                    <aicc-table :tableData="showModel.callPolicy.orderPolicy"
                                :tableColumns="conditionTableColumns"
                                :tableTools="{ columnConfig: false }">
                        <template #operate="scope">
                            <sweet-button class="task-icon" :icon="Delete" type="icon"
                                          @click="closeOrderList(scope)"/>
                        </template>
                        <template #orderType="scope">
                            <div class="aicc-m-right--callpolicy">
                                <sweet-select v-model="scope.row.typeId" value-key="value"
                                              :placeholder="$t('isales.taskinfo.placeholder.choose')" :fit-input-width="true" :show-resize="false">
                                    <sweet-option v-for="item in callDataOrderMethodItem" :value="item.typeId"
                                                  :label="item.typeName"></sweet-option>
                                </sweet-select>
                            </div>
                        </template>
                    </aicc-table>
                    <div class="addInfo">
                        <sweet-button class="task-icon button-class" :icon="CirclePlus" type="icon" @click="addChoosePropialogVisible=true">
                            <span class="create-button">{{ $t('isales.file.title.create')}}</span>
                        </sweet-button>
                    </div>
                </div></sweet-form-item>
        </sweet-col>
    </sweet-row>

    <!--多号码客户呼叫方式-->
    <sweet-form-item :label="$t('isales.label.multiple.callno.round')" v-if="this.showModel.taskInfo.taskType!==this.taskTypeItem.PREVIEW">
        <sweet-radio-group v-for="(item, index) in callDataOrderRoundItem" :key="item.index" style="margin-left:10px"
                           @change="clearCallRoundList"
                           v-model="callOrderTurn" :disabled="!isCreate && !isCreateTemplate">
            <sweet-radio :label="item.value" style="margin-right:32px">{{ $t(item.label)}}</sweet-radio>
        </sweet-radio-group>
    </sweet-form-item>
    <sweet-row v-if="this.showModel.taskInfo.taskType!==this.taskTypeItem.PREVIEW">
        <!--按数据属性-->
        <sweet-col :span="24" v-if="callOrderTurn!==callDataOrderRoundItem[0].value" class="isales-background-color">
            <sweet-form-item>
                <template #label><span></span></template>
                <div style="width: 750px">
                    <aicc-table :tableData="showModel.callPolicy.turns" :max-height="320"
                                :tableTools="{ columnConfig: false }"
                                :tableColumns="callOrderTurn===callDataOrderRoundItem[1].value?conditionTableNumberTypeColumns:conditionTableNumberOrderColumns">
                        <template #operate="scope">
                            <i v-if="callOrderTurn===callDataOrderRoundItem[2].value && scope.$index===0"
                               class="sweetui-icon-arrow-up"
                               @click="upTurnList(scope)"
                               style="font-size:20px;color: #91989e;margin-left:20px"></i>
                            <i v-if="callOrderTurn===callDataOrderRoundItem[2].value && scope.$index!==0"
                               class="sweetui-icon-arrow-up"
                               @click="upTurnList(scope)"
                               style="font-size:20px;margin-left:20px"></i>
                            <i v-if="callOrderTurn===callDataOrderRoundItem[2].value && (scope.$index===showModel.callPolicy.turns.length-1)"
                               class="sweetui-icon-arrow-down"
                               @click="downTurnList(scope)"
                               style="font-size:20px;color: #91989e;margin-left:20px"></i>
                            <i v-if="callOrderTurn===callDataOrderRoundItem[2].value && (scope.$index!==showModel.callPolicy.turns.length-1)"
                               class="sweetui-icon-arrow-down"
                               @click="downTurnList(scope)"
                               style="font-size:20px;margin-left:20px"></i>
                            <sweet-button class="task-icon" :icon="Delete" type="icon"
                                          style="cursor: pointer;font-size:20px;margin-left:20px"
                                          @click="closeTurnList(scope)"/>
                        </template>
                        <template #turnOrder="scope">
                            <sweet-form-item :prop="`callPolicy.turns.${scope.$index}.turnOrder`"
                                             :rules="[{ required: true, message: this.$t('isales.business.result.message.must'), trigger: ['blur', 'change'] }
                                     ,{ min: 1, max: 10, validator: this.callTimesValidator, trigger: ['blur', 'change'] }
                                     ,{ validator: this.checkUnRepeat, trigger: ['blur', 'change'] }]">
                                <sweet-input-number class="width-180px-to-rem aicc-m-right--callpolicy"
                                                    v-model="scope.row.turnOrder" controls-position="right"/>
                            </sweet-form-item>
                        </template>
                        <template #turnType="scope">
                            <sweet-input class="aicc-m-right--callpolicy" v-model="scope.row.turnType"/>
                        </template>
                    </aicc-table>
                    <div v-if="this.showModel.callPolicy.turns.length<10" class="addInfo">
                        <sweet-button @click="addChooseCallTurn" class="task-icon" style="margin-right: 12px" :icon="CirclePlus" type="icon">
                            <span class="create-button">{{ $t('isales.file.title.create')}}</span>
                        </sweet-button>
                    </div>
                    <div v-if="this.showModel.callPolicy.turns.length>=10" class="addInfo">
                        <sweet-button @click="addChooseCallTurn" class="task-icon" style="margin-right: 12px;color: #91989e" :icon="CirclePlus" type="icon">
                            <span style="color: #91989e" class="create-button">{{ $t('isales.file.title.create')}}</span>
                        </sweet-button>
                    </div>
                </div></sweet-form-item>
        </sweet-col>
    </sweet-row>

    <!--呼叫结果策略-->
    <sweet-form-item :label="$t('isales.tasklogic.field.callresultpolicy')" prop="retryPolicy.policyType">
        <sweet-radio-group v-for="(item, index) in policyTypeItems" :key="item.index" style="margin-left:10px"
                           @change="changeSelectPolicy"
                           v-model="showModel.retryPolicy.policyType"
                           :disabled="(!isCreate && !isCreateTemplate) || ((isCreate||isCreateTemplate) && callOrderTurn === '1')">
            <sweet-radio :label="item.value" style="margin-right:32px">{{ $t(item.label)}}</sweet-radio>
        </sweet-radio-group>
    </sweet-form-item>
    <!--呼叫结果策略，全局-->
    <sweet-row class="isales-background-color" v-if="showModel.retryPolicy.policyType === 0">
        <sweet-col :span="24">
            <!-- 是否根据结果配置开关 -->
            <sweet-form-item :label="$t('isales.taskcalllogic.title.resultSetPolicy')">
                <sweet-switch v-model="showModel.retryPolicy.isResultRetry" @change="changRetryPolicy"/>
            </sweet-form-item>
        </sweet-col>
        <!--外呼任务:未接通最大试呼天数(必填)-->
        <sweet-col :span="12">
            <sweet-form-item v-if="showModel.retryPolicy.isResultRetry === false" :label="$t('isales.tasklogic.field.retryDay')" prop="retryPolicy.retryDay">
              <div class="width-400px">
                <sweet-select v-model="showModel.retryPolicy.retryDay" value-key="value"
                              :placeholder="$t('isales.taskinfo.placeholder.choose')" :fit-input-width="true" :show-resize="false">
                  <sweet-option v-for="item in retryDayItems" :value="item.value"
                                :label="item.label"></sweet-option>
                </sweet-select>
              </div>
            </sweet-form-item>
        </sweet-col>
        <sweet-col :span="12" v-if="showModel.retryPolicy.isResultRetry === false"/>
        <!--外呼任务:未接通每天最大试呼次数(必填)-->
        <sweet-form-item v-if="showModel.retryPolicy.isResultRetry === false" :label="$t('isales.tasklogic.field.retryTimes')" prop="retryPolicy.retryTimes">
          <div class="width-400px">
            <sweet-select v-model="showModel.retryPolicy.retryTimes" value-key="value"
                          :placeholder="$t('isales.taskinfo.placeholder.choose')" :fit-input-width="true" :show-resize="false">
                <sweet-option v-for="item in retryTimeItems" :value="item.value"
                              :label="item.label"></sweet-option>
            </sweet-select>
          </div>
        </sweet-form-item>
        <sweet-col :span="12" v-if="showModel.retryPolicy.isResultRetry === false"/>
        <!--外呼任务:呼出间隔(必填)-->
        <sweet-form-item v-if="showModel.retryPolicy.isResultRetry === false" :label="$t('isales.tasklogic.field.callInterval')" prop="retryPolicy.callInterval">
            <sweet-input class="width-400px" v-model="showModel.retryPolicy.callInterval">
            </sweet-input>
        </sweet-form-item>
        <sweet-col :span="12" v-if="showModel.retryPolicy.isResultRetry === false"/>
        <!--根据结果配置策略-->
        <sweet-col :span="24" v-if="showModel.retryPolicy.isResultRetry === true">
            <sweet-form-item required>
                <template #label><span>{{ $t('isales.taskcalllogic.title.resultPolicySet') }}</span></template>
                <div style="width: 750px">
                    <aicc-table :tableData="showModel.retryPolicy.globalPolicyList"
                                :tableColumns="globalPolicyTableColumns"
                                :tableTools="{ columnConfig: false }"
                                :isNotReverseColumn="true">
                        <template #result="scope">
                            <div class="aicc-m-right--callpolicy aicc-p-bottom--small aicc-p-top--small">
                                <sweet-form-item :prop="`retryPolicy.globalPolicyList.${scope.$index}.result`"
                                                 :rules="[{ required: true, message: this.$t('isales.business.result.message.must'), trigger: ['blur', 'change'] }]">
                                    <sweet-select v-model="scope.row.result" value-key="value" class="form-content-numberInput-width"
                                                  :placeholder="$t('isales.taskinfo.placeholder.choose')" :fit-input-width="true" :show-resize="false">
                                        <sweet-option v-for="item in resultItems" :value="item.resultId"
                                                      :label="item.resultName"></sweet-option>
                                    </sweet-select>
                                </sweet-form-item>
                            </div>
                        </template>
                        <template #retryNumber="scope">
                            <div class="aicc-m-right--callpolicy aicc-p-bottom--small aicc-p-top--small">
                                <sweet-form-item :prop="`retryPolicy.globalPolicyList.${scope.$index}.retryNumber`"
                                                 :rules="[{ required: true, message: this.$t('isales.business.result.message.must'), trigger: ['blur', 'change'] }]">
                                    <sweet-select v-model="scope.row.retryNumber" value-key="value" class="form-content-numberInput-width"
                                                  :placeholder="$t('isales.taskinfo.placeholder.choose')" :fit-input-width="true" :show-resize="false">
                                        <sweet-option v-for="item in retryNumberItems" :value="item.value"
                                                      :label="item.label"></sweet-option>
                                    </sweet-select>
                                </sweet-form-item>
                            </div>
                        </template>
                        <template #callInterval="scope">
                            <div class="aicc-m-right--callpolicy aicc-p-bottom--small aicc-p-top--small">
                                <sweet-form-item :prop="`retryPolicy.globalPolicyList.${scope.$index}.callInterval`"
                                                 :rules="[{ required: true, message: this.$t('isales.business.result.message.must'), trigger: ['blur', 'change'] },{validator: this.$Validate.checkDefaultIntervalRang, trigger: ['blur', 'change']}]">
                                    <sweet-input v-model="scope.row.callInterval" class="form-content-numberInput-width" :disabled="scope.row.retryNumber <=0"
                                                 :placeholder="$t('isales.tasklogic.field.callInterval')"/>
                                </sweet-form-item>
                            </div>
                        </template>
                        <template #operate="scope">
                            <sweet-button style="margin-top:20px" class="task-icon" :icon="Delete" type="icon"
                                          @click="deleteGlobalPolicy(scope)"/>
                        </template>
                    </aicc-table>
                    <div v-if="!isView && !isViewTemplate" class="addInfo"
                         style="margin-bottom: 10px">
                        <sweet-button  @click="addGlobalPolicy" class="task-icon button-class" :icon="CirclePlus" type="icon">
                            <span class="create-button">{{ $t('isales.file.title.create')}}</span>
                        </sweet-button>
                    </div>
                </div>
            </sweet-form-item>
        </sweet-col>
        <sweet-col :span="10" v-if="showModel.retryPolicy.isResultRetry === true"/>
        <!--新增不重试结果码-->
        <sweet-col :span="24">
            <sweet-form-item>
                <template #label><span>{{ $t('isales.tasklogic.field.noRetryLogic') }}</span></template>
                <div style="width: 750px">
                    <aicc-table :tableData="showModel.retryPolicy.noRetryPolicyList"
                                :tableColumns="noRetryResultPolicyTableColumns"
                                :tableTools="{ columnConfig: false }">
                        <template #resultCode="scope">
                            <div style="cursor: pointer">
                                {{ scope.row.resultCode }}
                            </div>
                        </template>
                        <template #displayResult="scope">
                            <div style="cursor: pointer">
                                {{ scope.row.displayResult }}
                            </div>
                        </template>
                        <template #operate="scope">
                            <sweet-button class="task-icon" :icon="Delete" type="icon"
                                          @click="deleteResultCode(scope)"/>
                        </template>
                    </aicc-table>
                    <div v-if="!isView && !isViewTemplate" class="addInfo">
                        <sweet-button @click="selectResultCodeVisible=true" class="task-icon button-class" :icon="CirclePlus" type="icon">
                            <span class="create-button">{{ $t('isales.file.title.create')}}</span>
                        </sweet-button>
                    </div>
                </div>
            </sweet-form-item>
        </sweet-col>
    </sweet-row>
    <!-- 号码分类匹配策略 -->
    <sweet-row class="isales-background-color" v-if="showModel.retryPolicy.policyType === 1">
        <sweet-col :span="24">
            <sweet-form-item>
                <template #label></template>
                <div style="width: 750px">
                    <aicc-table :tableData="showModel.retryPolicy.callNoPolicyList"
                                :tableColumns="callNoPolicyTableColumns"
                                @expand-change="openDetail"
                                :row-key="getKey"
                                :tableTools="{ columnConfig: false }"
                                :isNotReverseColumn="true">
                        <template #childTable="scope">
                            <div class="isales-child-table-background-color">
                                <!-- 号码分类匹配策略:不根据结果配置 -->
                                <aicc-table :tableData="scope.row.policy"
                                            :tableColumns="notConnectPolicyTableColumns"
                                            v-if="scope.row.type === '0'"
                                            :tableTools="{ columnConfig: false }"
                                            :isNotReverseColumn="true">
                                    <template #retryDay="childScope">
                                        <div class="aicc-m-right--callpolicy">
                                            <sweet-select v-model="childScope.row.retryDay" value-key="value"
                                                          :placeholder="$t('isales.taskinfo.placeholder.choose')" :fit-input-width="true" :show-resize="false">
                                                <sweet-option v-for="item in retryDayItems" :value="item.value"
                                                              :label="item.label"></sweet-option>
                                            </sweet-select>
                                        </div>
                                    </template>
                                    <template #retryTimes="childScope">
                                        <div class="aicc-m-right--callpolicy">
                                            <sweet-select v-model="childScope.row.retryTimes" value-key="value"
                                                          :placeholder="$t('isales.taskinfo.placeholder.choose')" :fit-input-width="true" :show-resize="false">
                                                <sweet-option v-for="item in retryTimeItems" :value="item.value"
                                                              :label="item.label"></sweet-option>
                                            </sweet-select>
                                        </div>
                                    </template>
                                </aicc-table>
                                <!-- 号码分类匹配策略:根据结果配置 -->
                                <aicc-table :tableData="scope.row.policy"
                                            :tableColumns="globalPolicyTableColumns"
                                            v-if="scope.row.type === '1'"
                                            :tableTools="{ columnConfig: false }"
                                            :isNotReverseColumn="true">
                                    <template #result="childScope">
                                        <div class="aicc-m-right--callpolicy aicc-p-bottom--small aicc-p-top--small">
                                            <sweet-form-item
                                                    :prop="`retryPolicy.callNoPolicyList.${scope.$index}.policy.${childScope.$index}.result`"
                                                    :rules="[{ required: true, message: this.$t('isales.business.result.message.must'), trigger: ['blur', 'change'] }]">
                                                <sweet-select v-model="childScope.row.result" value-key="value" class="form-content-numberInput-width"
                                                              :placeholder="$t('isales.taskinfo.placeholder.choose')" :fit-input-width="true" :show-resize="false">
                                                    <sweet-option v-for="item in resultItems" :value="item.resultId"
                                                                  :label="item.resultName"></sweet-option>
                                                </sweet-select>
                                            </sweet-form-item>
                                        </div>
                                    </template>
                                    <template #retryNumber="childScope">
                                        <div class="aicc-m-right--callpolicy aicc-p-bottom--small aicc-p-top--small">
                                            <sweet-form-item
                                                    :prop="`retryPolicy.callNoPolicyList.${scope.$index}.policy.${childScope.$index}.retryNumber`"
                                                    :rules="[{ required: true, message: this.$t('isales.business.result.message.must'), trigger: ['blur', 'change'] }]">
                                                <sweet-select v-model="childScope.row.retryNumber" value-key="value" class="form-content-numberInput-width"
                                                              :placeholder="$t('isales.taskinfo.placeholder.choose')" :fit-input-width="true" :show-resize="false">
                                                    <sweet-option v-for="item in retryNumberItems" :value="item.value"
                                                                  :label="item.label"></sweet-option>
                                                </sweet-select>
                                            </sweet-form-item>
                                        </div>
                                    </template>
                                    <template #callInterval="childScope">
                                        <div class="aicc-m-right--callpolicy aicc-p-bottom--small aicc-p-top--small">
                                            <sweet-form-item
                                                    :prop="`retryPolicy.callNoPolicyList.${scope.$index}.policy.${childScope.$index}.callInterval`"
                                                    :rules="[{ required: true, message: this.$t('isales.business.result.message.must'), trigger: ['blur', 'change'] },{validator: this.$Validate.checkDefaultIntervalRang, trigger: ['blur', 'change']}]">
                                                <sweet-input v-model="childScope.row.callInterval" class="form-content-numberInput-width" :disabled="childScope.row.retryNumber <=0"
                                                             :placeholder="$t('isales.tasklogic.field.callInterval')"/>
                                            </sweet-form-item>
                                        </div>
                                    </template>
                                    <template #operate="childScope">
                                        <sweet-button class="task-icon" :icon="Delete" type="icon"
                                                      @click="deleteResultPolicy(scope,childScope)"/>
                                    </template>
                                </aicc-table>
                                <div v-if="!isView && !isViewTemplate && scope.row.type === '1'" class="addInfo">
                                    <sweet-button @click="addResultPolicy(scope.row)" class="task-icon button-class" :icon="CirclePlus"
                                                  type="icon">
                                        <span class="create-button">{{ $t('isales.file.title.create')}}</span>
                                    </sweet-button>
                                </div>
                            </div>
                        </template>
                        <template #dataType="scope">
                            <div style="cursor: pointer">
                                {{ scope.row.dataType }}
                            </div>
                        </template>
                        <template #type="scope">
                            <div class="width-180px-to-rem aicc-m-right--callpolicy">
                                <sweet-select v-model="scope.row.type" value-key="value" @change="changeResult(scope.row)"
                                              :placeholder="$t('isales.taskinfo.placeholder.choose')" :fit-input-width="true" :show-resize="false">
                                    <sweet-option v-for="item in resultTypeItems" :value="item.value"
                                                  :label="item.label" ></sweet-option>
                                </sweet-select>
                            </div>
                        </template>
                        <template #operate="scope">
                            <sweet-button class="task-icon" :icon="Delete" type="icon" @click="deletePolicyJson(scope)"
                                          v-if="scope.row.dataType != this.defaultDataType"/>
                        </template>
                    </aicc-table>
                    <div v-if="!isView && !isViewTemplate" class="addInfo">
                        <sweet-button @click="callNoPolicyAddVisible = true" class="task-icon button-class" :icon="CirclePlus" type="icon">
                        <span class="create-button">{{ $t('isales.file.title.create')}}</span>
                        </sweet-button>
                    </div>
                </div>
            </sweet-form-item>
        </sweet-col>
    </sweet-row>

    <!--业务结果策略-->
    <div v-if="this.showModel.taskInfo.taskType!==this.taskTypeItem.INTELLIGENT
                        && this.showModel.taskInfo.taskType!==this.taskTypeItem.AUTO">
        <sweet-form-item :label="$t('isales.tasklogic.field.busiresultpolicy')" style="margin-top:20px">
            <sweet-switch v-model="isOpenBusiResultPolicy"/>
        </sweet-form-item>
        <sweet-row class="isales-background-color">
            <sweet-col :span="24" v-if="isOpenBusiResultPolicy">
                <sweet-form-item>
                    <template #label><span></span></template>
                    <div style="width: 750px">
                        <aicc-table :tableData="busiResultPolicyList" maxHeight="320px"
                                    :tableTools="{ columnConfig: false }"
                                    :tableColumns="conditionTableBusiResultPolicyColumns">
                            <template #busiResultCode="scope">
                                <div class="aicc-m-right--callpolicy">
                                    <sweet-select v-model="scope.row.busiResultCode" value-key="value"
                                                  :placeholder="$t('isales.taskinfo.placeholder.choose')" :fit-input-width="true" :show-resize="false">
                                        <sweet-option v-for="item in busiResultItem" :value="item.code"
                                                      :label="item.name"
                                                      @click="querySubCodeAndNameByCode(scope)"></sweet-option>
                                    </sweet-select>
                                </div>
                            </template>
                            <template #busiResultSubCode="scope">
                                <div class="aicc-m-right--callpolicy">
                                    <sweet-select v-model="scope.row.busiResultSubCode" value-key="value"
                                                  :placeholder="$t('isales.taskinfo.placeholder.choose')" :fit-input-width="true" :show-resize="false">
                                        <sweet-option v-for="item in scope.row.busiResultSubCodeItem" :value="item.code"
                                                      :label="item.name"></sweet-option>
                                    </sweet-select>
                                </div>
                            </template>
                            <template #busiResultPolicy="scope">
                                <div class="aicc-m-right--callpolicy" style="width: 160px">
                                    <sweet-select v-model="scope.row.busiResultPolicy" value-key="value"
                                                  :placeholder="$t('isales.taskinfo.placeholder.choose')" :fit-input-width="true" :show-resize="false">
                                        <sweet-option v-for="item in busiResultPolicysItem" :value="item.value"
                                                      :label="item.label"></sweet-option>
                                    </sweet-select>
                                </div>
                            </template>
                            <template #callInterval="scope">
                                <div class="aicc-m-right--callpolicy">
                                    <sweet-form-item
                                            :prop="`retryPolicy.busiResultPolicyList.${scope.$index}.callInterval`"
                                            :rules="[{ required: scope.row.busiResultPolicy!='0', message: this.$t('isales.business.result.message.must'), trigger: ['blur', 'change']  }
                                    ,{validator: this.$Validate.checkDefaultIntervalRangAndEmpty, trigger: ['blur', 'change']}]" :showErrorTips="true">
                                        <sweet-input-number v-model="scope.row.callInterval" controls-position="right" class="form-content-numberInput-width"
                                                            :placeholder="$t('isales.taskinfo.placeholder.input')"
                                                            :disabled="scope.row.busiResultPolicy===busiResultPolicysItem[0].value"/>
                                    </sweet-form-item>
                                </div>
                            </template>
                            <template #operate="scope">
                                <sweet-button class="task-icon" :icon="Delete" type="icon"
                                              @click="closeBusiResultPolicy(scope)"/>
                            </template>
                        </aicc-table>
                        <div v-show="!isView && !isViewTemplate" class="addInfo">
                            <sweet-button @click="addBusiResultPolicy" class="task-icon button-class" :icon="CirclePlus" type="icon">
                                <span class="create-button">{{ $t('isales.file.title.create')}}</span>
                            </sweet-button>
                        </div>
                    </div></sweet-form-item>
            </sweet-col>
        </sweet-row>
    </div>

    <iSalesCallTurnSelect v-model:show="addChooseCallTurnVisible"
                          @confirmChooseCallTurn="confirmChooseCallTurn"
                          :callOrderTurn=callOrderTurn
                          v-model:newNumberTypeList="showModel.newNumberTypeList"></iSalesCallTurnSelect>

    <ChooseProp v-if="addChoosePropialogVisible" v-model:show="addChoosePropialogVisible"
                @confirm="confirmChooseCondition" :mode="mode" :type="dialogType"
                :selectedAttributes=selectedAttributes></ChooseProp>

    <isalesResultCodeSelect v-if="selectResultCodeVisible"
                            v-model:show="selectResultCodeVisible"
                            v-model:selectResultItems=selectResultItems
                            @selectedResultCode="selectedResultCode"></isalesResultCodeSelect>
    <isalesCallNoPolicyAdd v-if="callNoPolicyAddVisible"
                           v-model:show="callNoPolicyAddVisible"
                           :showModel=showModel
                           @addCallnoPolicy="addCallnoPolicy"></isalesCallNoPolicyAdd>
</template>

<script>
    import {
        BUSI_RESULT_POLICYS,
        CALL_DATA_ORDER_METHOD,
        CALL_DATA_ORDER_ROUND,
        CALL_DATA_ORDER_TYPE,
        POLICE_TYPES,
        RESULT_TYPES,
        RETRY_DAYS,
        RETRY_TIMES,
        RETRY_NUMBER_ITEMS_CODES,
        RESULT_ITEMS_CODES
    } from "@/views/isales/views/systemAutoCallTask/enum.js";
    import ChooseProp from "@/views/isales/components/ChooseProp/ChooseProp.vue";
    import iSalesCallTurnSelect from "@/views/isales/views/systemAutoCallTask/iSalesCallTurnSelect.vue";
    import isalesResultCodeSelect from "@/views/isales/views/systemAutoCallTask/isalesResultCodeSelect.vue";
    import isalesCallNoPolicyAdd from "@/views/isales/views/systemAutoCallTask/isalesCallNoPolicyAdd.vue";
    import {
        queryBusiResult,
        queryRetryPolicy,
        querySubCodeAndNameByCode
    } from "@/views/isales/api/systemAutoCallTask.js";
    import {TASK_TYPE} from "@/views/isales/views/taskManagement/enum";
    import {Delete, CirclePlus} from '@element-plus/icons-vue'
    import {ElMessage} from "element-plus";

    export default {
        name: "CallPolicy",
        components: {
            ChooseProp,
            iSalesCallTurnSelect,
            isalesResultCodeSelect,
            isalesCallNoPolicyAdd
        },
        props: {
          showModel: {
                type: Object,
                default() {
                    return {}
                },

            }
        },
        data() {
            return {
              queryType:'',
                Delete,
                CirclePlus,
                mode: 'orderPolicy',
                isOpenBusiResultPolicy: false,
                callDataOrderTypeItem: CALL_DATA_ORDER_TYPE,
                callDataOrderMethodItem: CALL_DATA_ORDER_METHOD,
                callDataOrderRoundItem: CALL_DATA_ORDER_ROUND,
                busiResultPolicysItem: BUSI_RESULT_POLICYS,
                taskTypeItem: TASK_TYPE,
                busiResultItem: [],
                callOrderTurn: '0', // 多号码呼叫顺序
                addChoosePropialogVisible: false,
                addChooseCallTurnVisible: false, // 多号码呼叫顺序
                selectedAttributes: [],
                busiResultPolicyList: [],
                conditionTableColumns: [
                    {
                        label: this.$t('isales.data.prop.propName'),
                        prop: 'propName',
                        width: '180px'
                    },
                    {
                        label: this.$t('isales.tasklogic.field.callOrderMethod.orderType'),
                        slot: 'orderType',
                    },
                    {
                        label: this.$t('isales.common.title.operation'), // 操作
                        slot: 'operate',
                        fixed: 'right',
                        width: '180px'
                    }
                ],
                conditionTableNumberTypeColumns: [
                    {
                        label: this.$t('isales.tasklogic.field.turn'),
                        type: 'index',
                        width: '100px'
                    },
                    {
                        label: this.$t('isales.tasklogic.field.numbertype'),
                        prop: 'turnType',
                    },
                    {
                        label: this.$t('isales.common.title.operation'), // 操作
                        slot: 'operate',
                        fixed: 'right',
                        width: '180px'
                    }
                ],
                conditionTableNumberOrderColumns: [
                    {
                        label: this.$t('isales.tasklogic.field.turn'),
                        type: 'index',
                        width: '100px'
                    },
                    {
                        label: this.$t('isales.tasklogic.field.numberorder'),
                        slot: 'turnOrder',
                    },
                    {
                        label: this.$t('isales.common.title.operation'), // 操作
                        slot: 'operate',
                        fixed: 'right',
                        width: '180px'
                    }
                ],
                conditionTableBusiResultPolicyColumns: [
                    {
                        label: this.$t('isales.taskmanagement.field.pickUpRuleBusiResult'),
                        slot: 'busiResultCode',
                        width: '170px',
                        'show-overflow-tooltip': false
                    },
                    {
                        label: this.$t('isales.taskresult.title.busisubstatus'),
                        slot: 'busiResultSubCode',
                        width: '170px',
                        'show-overflow-tooltip': false
                    },
                    {
                        label: this.$t('isales.tasklogic.field.policy'),
                        slot: 'busiResultPolicy',
                        width: '200px',
                        'show-overflow-tooltip': false
                    },
                    {
                        label: this.$t('isales.tasklogic.field.interval'),
                        slot: 'callInterval',
                        width: '140px',
                        'show-overflow-tooltip': false
                    },
                    {
                        label: this.$t('isales.common.title.operation'), // 操作
                        slot: 'operate',
                        fixed: 'right',
                        'show-overflow-tooltip': false
                    }
                ],
                callNoPolicyTableColumns: window.isRTL?[
                  {
                    label: this.$t('isales.common.title.operation'), // 操作
                    slot: 'operate',
                    fixed: 'right',
                  },
                  {
                    label: this.$t('isales.tasklogic.field.isResultCallnoLogic'),
                    slot: 'type',
                  },
                  {
                    label: this.$t('isales.tasklogic.field.dataType'),
                    slot: 'dataType',
                    width: '180px'
                  },
                  {
                    type: 'expand',
                    slot: 'childTable',
                    width: '30px'
                  },
                ]:[
                    {
                        type: 'expand',
                        slot: 'childTable',
                        width: '30px'
                    },
                    {
                        label: this.$t('isales.tasklogic.field.dataType'),
                        slot: 'dataType',
                        width: '180px'
                    },
                    {
                        label: this.$t('isales.tasklogic.field.isResultCallnoLogic'),
                        slot: 'type',
                    },
                    {
                        label: this.$t('isales.common.title.operation'), // 操作
                        slot: 'operate',
                        fixed: 'right',
                    }
                ],
                noRetryResultPolicyTableColumns: [
                    {
                        label: this.$t('isales.result.resultCode'),
                        slot: 'resultCode',
                    },
                    {
                        label: this.$t('isales.result.displayResult'),
                        slot: 'displayResult',
                    },
                    {
                        label: this.$t('isales.common.title.operation'), // 操作
                        slot: 'operate',
                        fixed: 'right',
                    }
                ],
                globalPolicyTableColumns: window.isRTL?[
                  {
                    label: this.$t('isales.common.title.operation'), // 操作
                    slot: 'operate',
                    fixed: 'right',
                  },
                  {
                    label: this.$t('isales.tasklogic.field.callInterval'),
                    slot: 'callInterval',
                    width: '150px',
                    'show-overflow-tooltip':false
                  },
                  {
                    label: this.$t('isales.tasklogic.field.retryNumber'),
                    slot: 'retryNumber',
                    width: '180px',
                    'show-overflow-tooltip':false
                  },
                  {
                    label: this.$t('isales.tasklogic.field.result'),
                    slot: 'result',
                    'show-overflow-tooltip':false
                  },
                ]:[
                    {
                        label: this.$t('isales.tasklogic.field.result'),
                        slot: 'result',
                        'show-overflow-tooltip':false
                    },
                    {
                        label: this.$t('isales.tasklogic.field.retryNumber'),
                        slot: 'retryNumber',
                        width: '180px',
                        'show-overflow-tooltip':false
                    },
                    {
                        label: this.$t('isales.tasklogic.field.callInterval'),
                        slot: 'callInterval',
                        width: '150px',
                        'show-overflow-tooltip':false
                    },
                    {
                        label: this.$t('isales.common.title.operation'), // 操作
                        slot: 'operate',
                        fixed: 'right',
                    }
                ],
                notConnectPolicyTableColumns:window.isRTL?[
                  {
                    label: this.$t('isales.tasklogic.field.retryTimes'),
                    slot: 'retryTimes',
                    'show-overflow-tooltip':false,
                  },
                  {
                    label: this.$t('isales.tasklogic.field.retryDay'),
                    slot: 'retryDay',
                    width: '220px',
                    'show-overflow-tooltip':false
                  }
                ]: [
                    {
                        label: this.$t('isales.tasklogic.field.retryDay'),
                        slot: 'retryDay',
                        width: '220px',
                       'show-overflow-tooltip':false
                    },
                    {
                        label: this.$t('isales.tasklogic.field.retryTimes'),
                        slot: 'retryTimes',
                        'show-overflow-tooltip':false,
                    }
                ],
                defaultDataType: this.$t('isales.tasklogic.field.default'),
                policyTypeItems: POLICE_TYPES,
                resultTypeItems: RESULT_TYPES,
                retryDayItems: RETRY_DAYS,
                retryTimeItems: RETRY_TIMES,
                retryNumberItems: RETRY_NUMBER_ITEMS_CODES,
                resultItems: RESULT_ITEMS_CODES,
                selectResultCodeVisible: false,
                callNoPolicyAddVisible: false,
                selectResultItems: [],
                dialogType: 'callPolicy',
            }
        },
        methods: {
            callTimesValidator(rule, value, callback) {
                if (Number(value) < rule.min || Number(value) > rule.max) {
                    callback($t('isales.taskpolicy.message.rangeAutoCallTime', [rule.min, rule.max]))
                } else {
                    callback()
                }
            },
            closeOrderList(scope) {
                const index = scope.$index
                this.showModel.callPolicy.orderPolicy.splice(index, 1);
                const selectedAttributeIndex = this.selectedAttributes.findIndex(item => item === scope.row.propName)
                this.selectedAttributes.splice(selectedAttributeIndex, 1)
                for (let i = 0; i < this.showModel.callPolicy.orderPolicy.length; i++) {
                    this.showModel.callPolicy.orderPolicy[i].propNo = i;
                }
            },
            closeTurnList(scope) {
                const index = scope.$index
                const turnType = scope.row.turnType
                const newIndex = this.showModel.newNumberTypeList.findIndex(item => item == turnType);
                this.showModel.newNumberTypeList.splice(newIndex, 1)
                if (newIndex >= 0) {
                  const policyIndex = this.showModel.retryPolicy.callNoPolicyList.findIndex(item => item.dataType == turnType);
                  if (policyIndex >= 0) {
                    this.showModel.retryPolicy.callNoPolicyList.splice(policyIndex, 1)
                  }
                }
                this.showModel.callPolicy.turns.splice(index, 1);
                for (let i = 0; i < this.showModel.callPolicy.turns.length; i++) {
                    this.showModel.callPolicy.turns[i].orderNo = i + 1;
                }
            },
            confirmChooseCondition(data) {
                if (data || data.length <= 0) {
                    data.forEach(item => {
                        let tempObj = {
                            propNo: this.showModel.callPolicy.orderPolicy.length,
                            propId: item.propId,
                            propName: item.propName,
                            typeId: this.callDataOrderMethodItem[0].typeId,
                        }
                        this.showModel.callPolicy.orderPolicy.push(tempObj);
                        this.selectedAttributes.push(item.propName);
                    });
                }
            },
            addChooseCallTurn() {
              if (this.showModel.callPolicy.turns.length >= 10) {
                return;
              }
                if (this.callOrderTurn === this.callDataOrderRoundItem[1].value) {
                    this.addChooseCallTurnVisible = true
                } else {
                    const tempData = {};
                    tempData['orderNo'] = this.showModel.callPolicy.turns.length + 1;
                    this.showModel.callPolicy.turns.push(tempData);
                }
            },
            confirmChooseCallTurn(data) {
                if (!data) {
                    return;
                }
                if (this.showModel.callPolicy.turns && this.showModel.callPolicy.turns.length > 0) {
                    let index = this.showModel.callPolicy.turns.findIndex(item => item.turnType === data);
                    if (index >= 0) {
                        return;
                    }
                }
                const tempData = {};
                tempData['turnType'] = data;
                tempData['orderNo'] = this.showModel.callPolicy.turns.length + 1;
                this.showModel.callPolicy.turns.push(tempData);
            },
            clearCallRoundList(data) {
                if (data === "1" ) {
                  if (this.showModel.retryPolicy.policyType !== 1){
                    this.showModel.retryPolicy.policyType = 1;
                    this.changeSelectPolicy();
                  }
                } else {
                  if (this.showModel.newNumberTypeList.length > 0) {
                    for (const turnType of this.showModel.newNumberTypeList) {
                      const policyIndex = this.showModel.retryPolicy.callNoPolicyList.findIndex(item => item.dataType == turnType);
                      this.showModel.retryPolicy.callNoPolicyList.splice(policyIndex, 1)
                    }
                    this.showModel.newNumberTypeList = [];
                  }
                }
                this.showModel.callPolicy.turns = [];
            },
            upTurnList(scope) {
                const index = scope.$index;
                if (index === 0) {
                    return;
                }
                const oldNex = this.showModel.callPolicy.turns[index];
                const oldPre = this.showModel.callPolicy.turns[index - 1];
                this.showModel.callPolicy.turns[index] = oldPre;
                this.showModel.callPolicy.turns[index - 1] = oldNex;
                let tempOrderNo = this.showModel.callPolicy.turns[index].orderNo;
                this.showModel.callPolicy.turns[index].orderNo = this.showModel.callPolicy.turns[index - 1].orderNo;
                this.showModel.callPolicy.turns[index - 1].orderNo = tempOrderNo;
            },
            downTurnList(scope) {
                const index = scope.$index;
                if (index === this.showModel.callPolicy.turns.length - 1) {
                    return;
                }
                const oldPre = this.showModel.callPolicy.turns[index];
                const oldNex = this.showModel.callPolicy.turns[index + 1];
                this.showModel.callPolicy.turns[index + 1] = oldPre;
                this.showModel.callPolicy.turns[index] = oldNex;
                let tempOrderNo = this.showModel.callPolicy.turns[index + 1].orderNo;
                this.showModel.callPolicy.turns[index + 1].orderNo = this.showModel.callPolicy.turns[index].orderNo;
                this.showModel.callPolicy.turns[index].orderNo = tempOrderNo;
            },
            addBusiResultPolicy() {
                let tempObj = {
                    "busiResultCode": '',
                    "busiResultSubCode": '',
                    "busiResultPolicy": this.busiResultPolicysItem[0].value,
                    "callInterval": ''
                };
                this.busiResultPolicyList.push(tempObj);
            },
            closeBusiResultPolicy(scope) {
                const index = scope.$index;
                this.busiResultPolicyList.splice(index, 1);
            },
            queryBusiResult() {
                let queryCondition = {};
                queryBusiResult(queryCondition).then(res => {
                    if (res) {
                        this.busiResultItem = res;
                    }
                });
            },
            querySubCodeAndNameByCode(scope) {
                let row = scope.row;
                row['busiResultSubCode'] = '';
                row['busiResultPolicy'] = '';
                row['callInterval'] = '';
                let queryCondition = {
                    "code": row['busiResultCode']
                };
                querySubCodeAndNameByCode(queryCondition).then(res => {
                    if (res || res.length <= 0) {
                        row['busiResultSubCodeItem'] = res;
                    } else {
                        row['busiResultSubCodeItem'] = '--';
                    }
                });
            },
            async setRetryPolicy(taskId, taskType) {
                let queryCondition = {"taskType": taskType, "taskId": taskId}
                await queryRetryPolicy(queryCondition).then(res => {
                    if (res && res.retryPolicy) {
                        this.showModel.retryPolicy = res.retryPolicy;
                        if (this.showModel.retryPolicy.busiPolicy && this.showModel.retryPolicy.busiPolicy !== '') {
                            this.isOpenBusiResultPolicy = true;
                            this.busiResultPolicyList = JSON.parse(this.showModel.retryPolicy.busiPolicy);
                            const tempBusiResultCode = res.busiResultCode;
                            this.busiResultPolicyList.forEach(item => {
                                let subCodes = tempBusiResultCode.filter(code => code.code === item.busiResultCode)[0].subCodes;
                                if (subCodes && subCodes.length > 0) {
                                    item['busiResultSubCodeItem'] = subCodes;
                                } else {
                                    item['busiResultSubCodeItem'] = '--';
                                }
                            })
                        }
                    }
                });
            },
            changeSelectPolicy() {
                if (this.isCreate || this.isCreateTemplate) {
                    this.showModel.retryPolicy.callNoPolicyList = [];
                    if (this.showModel.retryPolicy.policyType === 1) {
                        let callnoLogic = {"dataType": this.defaultDataType, "type": "0"};
                        let policy = {"dataType": this.defaultDataType, "retryDay": 1, "retryTimes": 1};
                        callnoLogic['policy'] = []
                        callnoLogic['policy'].push(policy);
                        this.showModel.retryPolicy.callNoPolicyList.push(callnoLogic);
                    }
                }
            },
            deletePolicyJson(scope) {
                const index = scope.$index;
                this.showModel.retryPolicy.callNoPolicyList.splice(index, 1);
            },
            deleteResultCode(scope) {
                const index = scope.$index;
                this.showModel.retryPolicy.noRetryPolicyList.splice(index, 1);
                this.selectResultItems = this.showModel.retryPolicy.noRetryPolicyList;
            },
            selectedResultCode(data) {
                this.selectResultItems = data;
                this.showModel.retryPolicy.noRetryPolicyList = data;
            },
            addGlobalPolicy() {
                const tempObj = {
                    "result": '',
                    "retryNumber": '',
                    "callInterval": 600
                };
                this.showModel.retryPolicy.globalPolicyList.push(tempObj);
            },
            deleteGlobalPolicy(scope) {
                if (this.showModel.retryPolicy.globalPolicyList.length <= 1) {
                    ElMessage({
                      type: 'error',
                      duration: 10000,
                      'show-close': true,
                      offset: 56,
                      message: this.$t('isales.taskcalllogic.message.alreadyLastPolicy')
                    });
                } else {
                    const index = scope.$index;
                    this.showModel.retryPolicy.globalPolicyList.splice(index, 1);
                }
            },
            openDetail(row, expanded) {
                if (row && row.policy && row.policy[0]) {
                  row.policy[0].retryDay = row.policy[0].retryDay === 0 ? '1' : row.policy[0].retryDay;
                  row.policy[0].retryTimes = row.policy[0].retryTimes === 0 ? '1' : row.policy[0].retryTimes;
                }
                if (this.showModel.retryPolicy.policyType === 1 && row["policy"] === undefined) {
                    let temp = row;
                    const child = {
                        "retryDay": '1',
                        "retryTimes": '1'
                    };
                    temp["policy"] = [];
                    temp["policy"].push(child);
                }
            },
            deleteResultPolicy(scope, childScope) {
                if (scope.row.policy.length <= 1) {
                    ElMessage({
                      type: 'error',
                      duration: 10000,
                      offset: 56,
                      'show-close': true,
                      message: this.$t('isales.taskcalllogic.message.alreadyLastPolicy')
                    });
                } else {
                    const index = childScope.$index;
                    scope.row.policy.splice(index, 1);
                }
            },
            addCallnoPolicy(item) {
                let callnoLogic = item[0];
                //此处开始为校验dataType 不能为已经添加过的
                let dataTypeTemp = callnoLogic.dataType;
                let isNewType = item[1];
                let exisDataType = [];
                for (let i = 0; i < this.showModel.retryPolicy.callNoPolicyList.length; i++) {
                    exisDataType.push(this.showModel.retryPolicy.callNoPolicyList[i].dataType);
                }
                let index = exisDataType.indexOf(dataTypeTemp);
                if (index >= 0) {
                    ElMessage({
                      type: 'error',
                      duration: 10000,
                      offset: 56,
                      'show-close': true,
                      message: this.$t('isales.taskcalllogic.message.dataTypeExist')
                    });
                    return false;
                }
                if (isNewType) {
                    callnoLogic['isNewType'] = true;
                } else {
                    callnoLogic['isNewType'] = false;
                }
                this.showModel.retryPolicy.callNoPolicyList.push(callnoLogic);
            },
            addResultPolicy(data) {
                const tempObj = {
                    "result": '',
                    "retryNumber": '',
                    "callInterval": 600
                };
                data["policy"].push(tempObj);
            },
            changeResult(data) {
                data["policy"] = []
                if (data["type"] === "0") {
                  const tempObj = {
                    "retryDay": '1',
                    "retryTimes": '1'
                  };
                    data["policy"].push(tempObj);
                } else {
                    const tempObj = {
                        "result": '',
                        "retryNumber": '',
                        "callInterval": 600
                    };
                    data["policy"].push(tempObj);
                }
            },
            checkUnRepeat(rule, value, callback) {
                let length = this.showModel.callPolicy.turns.filter(item => item.turnOrder === value).length;
                if (length > 1) {
                    callback($t('isales.tasklogic.message.exist.orderNo'))
                } else {
                    callback()
                }
            },
            setCallOrderTurn(callOrderTurn) {
                this.callOrderTurn = callOrderTurn;
            },
            async checkBusinessPolicy() {
                if (!this.isOpenBusiResultPolicy) {
                    return true;
                }
                if (!this.busiResultPolicyList || this.busiResultPolicyList.length === 0) {
                    this.$message({
                      type: 'error',
                      duration: 10000,
                      showClose: true,
                      offset: 56,
                      message: this.$t('isales.tasklogic.message.busiresultpolicyNoadded')
                    });
                    return false;
                }
                let checkParam = {};
                for (let i = 0; i < this.busiResultPolicyList.length; i++) {
                    let item = this.busiResultPolicyList[i];
                    let key = item["busiResultCode"];
                    if (this.isValueEmpty(checkParam[key])) {
                        checkParam[key] = item["busiResultSubCode"];
                    } else {
                        let value = checkParam[key];
                        if (value.indexOf(item["busiResultSubCode"]) >= 0) {
                            this.$message({
                              type: 'error',
                              duration: 10000,
                              showClose: true,
                              offset: 56,
                              message: this.$t('isales.tasklogic.message.busiresultpolicySame')
                            });
                            return false;
                        } else {
                            checkParam[key] = value + "," + item["busiResultSubCode"];
                        }
                    }
                    if (this.busiResultPolicysItem[0].value !== item['busiResultPolicy']) {
                      if (item['callInterval'] === '' || item['callInterval'] === null || item['callInterval'] === undefined) {
                        this.$message({
                          type: 'error',
                          duration: 10000,
                          showClose: true,
                          offset: 56,
                          message: this.$t('isales.business.callInterval.message.must')
                        });
                        return false;
                      }
                        if (parseInt(item['callInterval']) < 1 || parseInt(item['callInterval']) > 3600) {
                          this.$message({
                            type: 'error',
                            duration: 10000,
                            showClose: true,
                            offset: 56,
                            message: this.$t('isales.taskpolicy.message.rangeAutoCallTime', [1, 3600])
                          });
                          return false;
                        }
                    }
                }
                return true;
            },
            isValueEmpty(valueStr) {
                return valueStr === null || valueStr === '' || valueStr === undefined;
            },
            getKey(row) {
                return row.dataType;
            },
            changRetryPolicy(){
                if(this.showModel.retryPolicy.isResultRetry){ // 结果重试
                    this.showModel.retryPolicy.retryDay='1';
                    this.showModel.retryPolicy.retryTimes='1';
                    this.showModel.retryPolicy.callInterval='';
                }else {
                    this.showModel.retryPolicy.resultRetry=''
                    this.showModel.retryPolicy.globalPolicyList=[];
                }
            }
        },
        watch: {
            isOpenBusiResultPolicy(value) {
                if (value) {
                    this.queryBusiResult();
                }
            },
            callOrderTurn(value) {
                // 按顺序
                if (value === this.callDataOrderRoundItem[0].value) {
                    this.showModel.callPolicy.callType = 0;
                    this.showModel.callPolicy.chooseType = 0;
                }
                // 按轮次
                if (value === this.callDataOrderRoundItem[1].value) {
                    this.showModel.callPolicy.callType = 1;
                    this.showModel.callPolicy.chooseType = 0;
                }
                // 按序号
                if (value === this.callDataOrderRoundItem[2].value) {
                    this.showModel.callPolicy.callType = 1;
                    this.showModel.callPolicy.chooseType = 1;
                }
            },
            busiResultPolicyList: {
                handler(newValue, oldValue) {
                    if (!newValue || newValue.length <= 0) {
                        this.showModel.retryPolicy.busiPolicy = '';
                        return;
                    }
                    let temp = [];
                    newValue.forEach(item => {
                        let callInterval = item["busiResultPolicy"] == "0" ? "" : item["callInterval"];
                        let tempObj = {
                            "busiResultCode": item["busiResultCode"],
                            "busiResultSubCode": item["busiResultSubCode"],
                            "busiResultPolicy": item["busiResultPolicy"],
                            "callInterval": callInterval
                        };
                        temp.push(tempObj);
                    });
                    this.showModel.retryPolicy.busiPolicy = JSON.stringify(temp);
                    this.showModel.retryPolicy.busiResultPolicyList = temp;
                },
                deep: true
            }
        },
        computed: {
            isView() {
                return this.queryType === 'view'
            },
            isEdit() {
                return this.queryType === 'edit'
            },
            isCreate() {
                return this.queryType === 'create'
            },
            isBatchEdit() {
                return this.queryType === 'batchEdit'
            },
            isCreateTemplate() {// 新建外呼任务模型
                return this.queryType === 'createTemplate'
            },
            isEditTemplate() {// 编辑外呼任务模型
                return this.queryType === 'editTemplate'
            },
            isViewTemplate(){// 编辑外呼任务模型
                return this.queryType === 'viewTemplate'
            },
        },
        async created() {
            this.queryType = this.$route.query.type;
        }
    }
</script>

<style lang="less" scoped>
    .isales-task-info-add {
        margin-top: 10px;
        font-size: 14px;
        color: #366BFC;
        font-weight: bold;
    }

    .isales-add-ackground-color {
        padding-left: 20px;
        padding-top: 10px;
        font-size: 14px;
        color: #366BFC;
        font-weight: bold;
        background-color: #FFFFFF;
    }

    .aicc-calltime-align--absolute-left {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
        justify-content: flex-start;
        -ms-flex-line-pack: center;
        align-content: center;
        -ms-flex-align: center;
        align-items: center;
        width: 100%
    }

    .width-180px-to-rem {
        width: var(--180px-to-rem) !important;
    }

    .task-icon {
        font-size: 18px !important;
        color: #366BFC;
    }

    .isales-background-color {
        padding: 20px 10px;
        background-color: #FBFBFB;
    }

    .isales-child-table-background-color {
        padding: 10px 20px 10px 40px;
        background-color: #EAEAEA;
    }
    .width-400px {
      width: 400px;
      border-radius: 4px;
    }
    .aicc-m-right--callpolicy {
        margin-right: 20px;
    }
    .button-class{
        margin-right: 12px;
    }
    body.RTL{
        .aicc-m-right--callpolicy{
            margin-left: 20px;
            margin-right: unset;
        }
        .button-class{
            margin-left: 12px;
            margin-right: unset;
        }
    }
    .form-content-numberInput-width {
        width: 200px !important;
    }

    .addInfo {
        width: 100%;
        height: 45px;
        border: 1px solid rgba(238, 238, 238, 1);
        border-top: 0;
        display: flex;
        padding-inline-start: 1rem;
        font-size: 14px;
        color: #366BFC;
        font-weight: bold;
        align-items: center;
        &-wrap {
            display: flex;
            gap: 10px;
        }
    }

    .create-button {
        font-size: 14px;
        font-weight: bold;
    }
</style>