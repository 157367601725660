<template>
  <sweet-form
    class="aicc-advance-search"
    ref="formRef"
    :rules="formRules"
    :model="formData"
    label-width="0px"
    @submit.prevent
  >
    <div class="basic-search-row">
      <!-- 基础搜索 包含 input select date -->
      <div v-for="(basicItem, index) in basicSearch" :key="index">
        <sweet-form-item :prop="basicItem.modelValue" :showErrorTips="true">
          <div :class="formItem240pxClass" v-if="basicItem.searchType === 'primary'">
            <sweet-input
              :class="basicItem.class"
              v-model="formData[basicItem.modelValue]"
              :clearable="isFormItemClearable(basicItem)"
              :placeholder="basicItem.placeholderValue"
              @keydown.enter="handleSearch(formRef)"
              @focus="inputFocus(basicItem.focusEvent)"
              @change="inputChange(basicItem.changeEvent)"
              @clear="inputClear(basicItem.clearEvent)"
              @click="basicSelectClick(basicItem.clickEvent)"
              :maxlength="basicItem.maxlength"
              :minlength="basicItem.minlength"
              :max="basicItem.max"
              :min="basicItem.min"
            >
              <template #prefix>
                <sweet-icon
                  class="input-icon"
                  @click="handleSearch(formRef)"
                  :title="$t('chat.session.label.search')"
                >
                  <search />
                </sweet-icon>
              </template>
            </sweet-input>
          </div>
          <div :class="formItem240pxClass" v-if="basicItem.searchType === 'numberInput'">
            <sweet-input
              :class="basicItem.class"
              v-model="formData[basicItem.modelValue]"
              :clearable="isFormItemClearable(basicItem)"
              v-number-input="basicItem.inputRange"
              :placeholder="basicItem.placeholderValue"
              @keydown.enter="handleSearch(formRef)"
              @focus="inputFocus(basicItem.focusEvent)"
              @change="inputChange(basicItem.changeEvent)"
              @clear="inputClear(basicItem.clearEvent)"
            >
              <template #prefix>
                <sweet-icon
                  class="input-icon"
                  @click="handleSearch(formRef)"
                  :title="$t('chat.session.label.search')"
                >
                  <search />
                </sweet-icon>
              </template>
            </sweet-input>
          </div>
          <div
            :class="isSelfDefinedTime ? formItem380pxClass : formItem240pxClass"
            v-if="basicItem.searchType === 'timeSelect'"
          >
            <sweet-select
              :clearable="isFormItemClearable(basicItem)"
              v-model="formData[basicItem.modelValue]"
              :placeholder="basicItem.placeholderValue"
              :class="basicItem.class"
            >
              <sweet-option
                v-for="item in basicItem?.selectOptions || []"
                :key="item.value"
                :label="item.label"
                @click="
                  timeSelectChange(
                    basicItem.changeEvent,
                    formData[basicItem.modelValue],
                    basicItem.modelValue
                  )
                "
                :value="item.value"
              >
              </sweet-option>
            </sweet-select>
          </div>
          <div
            class="timeSelectDate"
            :class="isSelfDefinedTime ? formItem380pxClass : formItem240pxClass"
            v-if="basicItem.searchType === 'timeSelect'"
          >
            <sweet-date-picker
              :class="basicItem.class"
              :ref="basicItem.modelValue"
              :type="basicItem.datePickerType || 'daterange'"
              unlink-panels
              v-model="formData[basicItem.modelValue + 'Custom']"
              :default-time="[new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 2, 1, 23, 59, 59)]"
              :clearable="false"
              @visible-change="timeSelectDateBlur(basicItem, $event)"
              @change="timeSelectDateChange(basicItem.modelValue, basicItem.datePickerType)"
            >
            </sweet-date-picker>
          </div>
          <div :class="formItem240pxClass" v-if="basicItem.searchType === 'select'">
            <sweet-select
              :class="basicItem.class"
              :clearable="isFormItemClearable(basicItem)"
              :multiple="basicItem['multiple']"
              :collapse-tags="basicItem['collapse-tags']"
              :collapse-tags-tooltip="basicItem['collapse-tags-tooltip']"
              :filterable="basicItem.filterable"
              :fit-input-width="
                basicItem['fit-input-width'] === void 0
                  ? true
                  : Boolean(basicItem['fit-input-width'])
              "
              v-model="formData[basicItem.modelValue]"
              :placeholder="basicItem.placeholderValue"
              @change="selectChange(basicItem.changeEvent)"
              @click="basicSelectClick(basicItem.clickEvent)"
              :popper-class="basicItem.popperClass ? basicItem.popperClass : ''"
            >
              <sweet-option
                v-for="item in basicItem?.selectOptions || []"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </sweet-option>
            </sweet-select>
          </div>
          <div :class="formItem240pxClass" v-if="basicItem.searchType === 'input'">
            <sweet-input
              :class="basicItem.class"
              :clearable="isFormItemClearable(basicItem)"
              v-model="formData[basicItem.modelValue]"
              :placeholder="basicItem.placeholderValue"
              @focus="inputFocus(basicItem.focusEvent)"
              @change="inputChange(basicItem.changeEvent)"
              @clear="inputClear(basicItem.clearEvent)"
              @click="basicSelectClick(basicItem.clickEvent)"
              :ref="basicItem.ref"
              :maxlength="basicItem.maxlength"
              :minlength="basicItem.minlength"
              :max="basicItem.max"
              :min="basicItem.min"
            >
            </sweet-input>
          </div>
          <div :class="formItem380pxClass" v-if="basicItem.searchType === 'date'">
            <sweet-date-picker
              :class="basicItem.class"
              :type="basicItem.datePickerType || 'date'"
              v-model="formData[basicItem.modelValue]"
              :shortcuts="basicItem.shortcuts"
              unlink-panels
              :default-time="[new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 2, 1, 23, 59, 59)]"
              :start-placeholder="basicItem.startPlaceholder"
              :end-placeholder="basicItem.endPlaceholder"
              :placeholder="basicItem.placeholderValue"
              :disabled-date="
                basicItem.disabledDateFun === undefined
                  ? disabledDateFun
                  : basicItem.disabledDateFun
              "
              @change="dateChange(basicItem.changeEvent)"
              :clearable="isFormItemClearable(basicItem)"
            >
            </sweet-date-picker>
          </div>
          <div :class="formItem240pxClass" v-if="basicItem.searchType === 'treeSelect'">
            <sweet-tree-select
              :class="basicItem.class"
              v-model="formData[basicItem.modelValue]"
              :data="basicItem.treeOptions"
              :placeholder="basicItem.placeholderValue"
              :render-after-expand="false"
              @visible-change="treeSelectBlur(basicItem.changeEvent, $event)"
              :show-checkbox="basicItem.showCheckbox === undefined ? true : basicItem.showCheckbox"
              :multiple="basicItem.multiple === undefined ? true : basicItem.multiple"
              :check-strictly="
                basicItem.checkStrictly === undefined ? true : basicItem.checkStrictly
              "
              :collapse-tags="basicItem.collapseTags === undefined ? true : basicItem.collapseTags"
              :clearable="isFormItemClearable(basicItem)"
              :filterable="basicItem.filterable === undefined ? true : basicItem.filterable"
              :collapse-tags-tooltip="
                basicItem.collapseTagSTooltip === undefined ? true : basicItem.collapseTagSTooltip
              "
              :check-on-click-node="
                basicItem.checkOnClickNode === undefined ? true : basicItem.checkOnClickNode
              "
            >
              <template #default="{ data: { label } }">
                <aicc-tooltip overflow-target-selector=".select-tree-node-label" :content="label">
                  <div class="select-tree-node-label">{{ label }}</div>
                </aicc-tooltip>
              </template>
            </sweet-tree-select>
          </div>
          <div
            :class="formItem240pxClass"
            v-if="basicItem.searchType === 'orgTreeSelect'"
            v-show="!(basicItem.isShow === false)"
          >
            <template v-if="basicItem.multiple">
              <org-tree-select
                :class="basicItem.class"
                ref="orgsRef"
                :data="basicItem.treeOptions"
                :placeholder="basicItem.placeholderValue"
                multiple
                @onSelectChange="
                  onOrgsSelected($event, basicItem.modelValue, basicItem.changeEvent)
                "
                :disabled="basicItem.disabled"
                @onClear="onOrgsClear($event, basicItem.modelValue, basicItem.changeEvent)"
              ></org-tree-select>
            </template>
            <template v-else>
              <org-tree-select
                :class="basicItem.class"
                ref="orgRef"
                :data="basicItem.treeOptions"
                @onSelectChange="onOrgSelected($event, basicItem.modelValue, basicItem.changeEvent)"
                :disabled="basicItem.disabled"
                @onClear="onOrgClear($event, basicItem.modelValue, basicItem.changeEvent)"
              ></org-tree-select>
            </template>
          </div>
        </sweet-form-item>
      </div>
      <!-- 高级搜索按钮 icon list -->
      <div class="basic-search-row-center">
        <template
          v-if="!checkEmptyArrObj(iconButton) || !checkEmptyArrObj(searchList) || $slots.iconButton"
        >
          <sweet-form-item>
            <div class="buttonBox--basic">
              <!-- 高级搜索 -->
              <sweet-button
                v-if="!checkEmptyArrObj(searchList)"
                @click="handleAdvancedSearch(!isAdvancedSearch)"
              >
                {{ $t('aicc.more') }}
                <el-icon class="el-icon el-icon--right">
                  <ArrowUp v-show="isAdvancedSearch" />
                  <ArrowDown v-show="!isAdvancedSearch" />
                </el-icon>
              </sweet-button>
              <template v-for="(buttonItem, index) in iconButton" :key="index">
                <sweet-button
                  class="aicc-button-icon"
                  v-if="buttonItem.buttonType === 'refresh'"
                  v-show="buttonItem.show === undefined ? true : buttonItem.show"
                  @click="handleRefresh(buttonItem.eventName)"
                >
                  <sweet-tooltip
                    :open-delay="800"
                    :content="buttonItem.buttonTitle || $t('ccmessaging.chat.chatconfig.refresh')"
                    placement="top"
                  >
                    <img
                      class="sweetui-icon-download-l refresh-icon-img"
                      src="@/assets/img/search/search_refresh.png"
                    />
                  </sweet-tooltip>
                </sweet-button>
                <sweet-button
                  class="aicc-button-icon"
                  v-else-if="buttonItem.buttonType === 'download'"
                  v-show="buttonItem.show === undefined ? true : buttonItem.show"
                  @click="handleDownload(buttonItem.eventName)"
                >
                  <sweet-tooltip
                    :open-delay="800"
                    :content="buttonItem.buttonTitle || $t('chat.client.label.download')"
                    placement="top"
                  >
                    <i class="sweetui-icon-download-l"></i>
                  </sweet-tooltip>
                </sweet-button>
                <sweet-button
                  class="aicc-button-icon"
                  v-else-if="buttonItem.buttonType === 'import'"
                  v-show="buttonItem.show === undefined ? true : buttonItem.show"
                  @click="handleImport(buttonItem.eventName)"
                >
                  <sweet-tooltip
                    :open-delay="800"
                    :content="buttonItem.buttonTitle || $t('ccm.speciallist.button.import')"
                    placement="top"
                  >
                    <img
                      class="sweetui-icon-download-l"
                      src="@/assets/img/search/search_import.png"
                    />
                  </sweet-tooltip>
                </sweet-button>
                <sweet-button
                  class="aicc-button-icon"
                  v-else-if="buttonItem.buttonType === 'export'"
                  v-show="buttonItem.show === undefined ? true : buttonItem.show"
                  @click="handleExport(buttonItem.eventName)"
                >
                  <sweet-tooltip
                    :open-delay="800"
                    :content="buttonItem.buttonTitle || $t('ccm.agent.button.export')"
                    placement="top"
                  >
                    <img
                      class="sweetui-icon-download-l"
                      src="@/assets/img/search/search_export.png"
                    />
                  </sweet-tooltip>
                </sweet-button>
                <sweet-button
                  class="aicc-button-icon"
                  v-else-if="buttonItem.buttonType === 'setting'"
                  v-show="buttonItem.show === undefined ? true : buttonItem.show"
                  @click="handleSetting(buttonItem.eventName)"
                >
                  <sweet-tooltip
                    :open-delay="800"
                    :content="
                      buttonItem.buttonTitle || $t('ccd.agent.warning.notification.setting')
                    "
                    placement="top"
                  >
                    <img
                      class="sweetui-icon-download-l"
                      src="@/assets/img/search/search_setting.png"
                    />
                  </sweet-tooltip>
                </sweet-button>
                <sweet-button
                  class="aicc-button-icon"
                  :class="{ 'transList-dot': buttonItem.dot }"
                  v-else-if="buttonItem.buttonType === 'transList'"
                  v-show="buttonItem.show === undefined ? true : buttonItem.show"
                  @click="handleTransList(buttonItem.eventName)"
                >
                  <sweet-tooltip
                    :open-delay="800"
                    :content="buttonItem.buttonTitle || $t('cms.export.field.viewExportTask')"
                    placement="top"
                  >
                    <img
                      class="sweetui-icon-download-l"
                      src="@/assets/img/search/search_transList.png"
                    />
                  </sweet-tooltip>
                </sweet-button>
              </template>
              <slot name="iconButton" v-bind="handleFormatParams"></slot>
            </div>
          </sweet-form-item>
        </template>
      </div>
      <!-- 右侧 扩展下拉按钮 等 -->
      <div v-if="otherOperation.length || $slots.otherPart">
        <sweet-form-item>
          <div :class="computedBtnClass">
            <template v-for="(otherItem, index) in otherOperation" :key="index">
              <sweet-dropdown v-if="otherItem.dropdown" trigger="click" @command="handleCommand">
                <sweet-button :type="otherItem.buttonStyle">
                  <aicc-tooltip
                    :open-delay="800"
                    :content="otherItem.buttonName"
                    placement="top"
                    overflowTargetSelector=".advance-search-btn-text"
                  >
                    <div class="advance-search-btn-text">{{ otherItem.buttonName }}</div>
                  </aicc-tooltip>
                  <sweet-icon class="el-icon--right">
                    <arrow-down />
                  </sweet-icon>
                </sweet-button>
                <template #dropdown>
                  <sweet-dropdown-menu>
                    <template v-for="(dropdownItem, index) in otherItem.buttonList" :key="index">
                      <sweet-dropdown-item
                        v-if="dropdownItem.show === undefined ? true : dropdownItem.show"
                        :disabled="dropdownItem.disabled"
                        :command="dropdownItem.eventName"
                      >
                        {{ dropdownItem.buttonName }}
                      </sweet-dropdown-item>
                    </template>
                  </sweet-dropdown-menu>
                </template>
              </sweet-dropdown>
              <sweet-button
                v-else-if="
                  otherItem.basicButton && otherItem.show === undefined ? true : otherItem.show
                "
                :type="otherItem.buttonStyle"
                :class="otherItem.className"
                @click="handleCommand(otherItem.eventName)"
              >
                <div>
                  <aicc-tooltip
                    :open-delay="800"
                    :content="otherItem.buttonName"
                    placement="top"
                    overflowTargetSelector=".advance-search-btn-text"
                  >
                    <div class="advance-search-btn-text">{{ otherItem.buttonName }}</div>
                  </aicc-tooltip>
                </div>
              </sweet-button>
              <aicc-audio
                class="common-audio"
                v-else-if="otherItem.audio"
                :src="otherItem.audioSrc"
                :autoplay="otherItem.autoplay ?? false"
                :canDownLoad="otherItem.canDownLoad ?? false"
                :canClose="otherItem.canClose ?? false"
                @closeAudioHandle="otherItem.closeAudioHandle ?? ''"
              >
              </aicc-audio>
            </template>
            <slot name="otherPart" v-bind="handleFormatParams"></slot>
          </div>
        </sweet-form-item>
      </div>
    </div>
    <!-- 高级搜索 细分项 -->
    <div v-show="isAdvancedSearch" class="advanced-search--basic" :style="styleObject">
      <sweet-row :gutter="12">
        <sweet-col
          :xs="searchItem?.colConfig?.xs || 24"
          :sm="searchItem?.colConfig?.sm || 12"
          :md="searchItem?.colConfig?.sm || 8"
          :lg="searchItem?.colConfig?.lg || 6"
          :xl="searchItem?.colConfig?.xl || 4"
          v-for="(searchItem, index) in searchList"
          :key="index"
        >
          <sweet-form-item :prop="searchItem.modelValue" :showErrorTips="true">
            <sweet-input
              :class="searchItem.class"
              v-if="searchItem.searchType === 'input'"
              :clearable="isFormItemClearable(searchItem)"
              :ref="searchItem.ref"
              :disabled="searchItem.disabled"
              @focus="inputFocus(searchItem.focusEvent)"
              @change="inputChange(searchItem.changeEvent)"
              @clear="inputClear(searchItem.clearEvent)"
              @click="selectClick(searchItem.clickEvent)"
              v-model="formData[searchItem.modelValue]"
              :placeholder="searchItem.placeholderValue"
              :maxlength="searchItem['maxlength']"
              :minlength="searchItem.minlength"
              :max="searchItem.max"
              :min="searchItem.min"
            >
            </sweet-input>
            <sweet-input
              v-if="searchItem.searchType === 'popup'"
              :clearable="isFormItemClearable(searchItem)"
              :ref="searchItem.ref"
              :disabled="searchItem.disabled"
              @focus="inputFocus(searchItem.focusEvent)"
              @change="selectChange(searchItem.changeEvent)"
              @click="selectClick(searchItem.clickEvent)"
              @clear="inputClear(searchItem.clearEvent)"
              v-model="formData[searchItem.modelValue]"
              :placeholder="searchItem.placeholderValue"
              :maxlength="searchItem['maxlength']"
              :minlength="searchItem.minlength"
              :max="searchItem.max"
              :min="searchItem.min"
            >
              <template #suffix>
                <img
                  class="suffix-img"
                  src="@/assets/img/table/table_header_setting_larger.png"
                  @click="selectClick(searchItem.clickEvent)"
                />
              </template>
            </sweet-input>
            <sweet-date-picker
              :class="searchItem.class"
              v-else-if="searchItem.searchType === 'date'"
              :ref="searchItem.ref"
              unlink-panels
              :shortcuts="searchItem.shortcuts"
              :default-time="[new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 2, 1, 23, 59, 59)]"
              @change="dateChange(searchItem.changeEvent)"
              :type="searchItem.datePickerType || 'date'"
              v-model="formData[searchItem.modelValue]"
              :start-placeholder="searchItem.startPlaceholder"
              :end-placeholder="searchItem.endPlaceholder"
              :placeholder="searchItem.placeholderValue"
            >
            </sweet-date-picker>
            <sweet-select
              :class="searchItem.class"
              v-else-if="searchItem.searchType === 'select'"
              :clearable="isFormItemClearable(searchItem)"
              :multiple="searchItem['multiple']"
              :collapse-tags="searchItem['collapse-tags']"
              :collapse-tags-tooltip="searchItem['collapse-tags-tooltip']"
              :ref="searchItem.ref"
              :filterable="searchItem.filterable"
              @click="selectClick(searchItem.clickEvent)"
              @change="selectChange(searchItem.changeEvent)"
              v-model="formData[searchItem.modelValue]"
              :popper-class="searchItem.popperClass ? searchItem.popperClass : ''"
              :fit-input-width="
                searchItem['fit-input-width'] === void 0
                  ? true
                  : Boolean(searchItem['fit-input-width'])
              "
              :placeholder="searchItem.placeholderValue"
            >
              <sweet-option
                v-for="item in searchItem?.selectOptions || []"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </sweet-option>
            </sweet-select>
            <sweet-tree-select
              :class="searchItem.class"
              v-else-if="searchItem.searchType === 'treeSelect'"
              v-model="formData[searchItem.modelValue]"
              :data="searchItem.treeOptions"
              :placeholder="searchItem.placeholderValue"
              :render-after-expand="false"
              @visible-change="treeSelectBlur(searchItem.changeEvent, $event)"
              :show-checkbox="
                searchItem.showCheckbox === undefined ? true : searchItem.showCheckbox
              "
              :multiple="searchItem.multiple === undefined ? true : searchItem.multiple"
              :check-strictly="
                searchItem.checkStrictly === undefined ? true : searchItem.checkStrictly
              "
              :collapse-tags="
                searchItem.collapseTags === undefined ? true : searchItem.collapseTags
              "
              :clearable="isFormItemClearable(searchItem)"
              :filterable="searchItem.filterable === undefined ? true : searchItem.filterable"
              :collapse-tags-tooltip="
                searchItem.collapseTagSTooltip === undefined ? true : searchItem.collapseTagSTooltip
              "
              :check-on-click-node="
                searchItem.checkOnClickNode === undefined ? true : searchItem.checkOnClickNode
              "
            >
              <template #default="{ data: { label } }">
                <aicc-tooltip overflow-target-selector=".select-tree-node-label" :content="label">
                  <div class="select-tree-node-label">{{ label }}</div>
                </aicc-tooltip>
              </template>
            </sweet-tree-select>
            <template
              v-else-if="searchItem.searchType === 'orgTreeSelect'"
              v-show="!(searchItem.isShow === false)"
            >
              <template v-if="searchItem.multiple">
                <org-tree-select
                  :class="searchItem.class"
                  ref="orgsRef"
                  :data="searchItem.treeOptions"
                  :placeholder="searchItem.placeholderValue"
                  multiple
                  @onSelectChange="
                    onOrgsSelected($event, searchItem.modelValue, searchItem.changeEvent)
                  "
                  :disabled="searchItem.disabled"
                  @onClear="onOrgsClear($event, searchItem.modelValue, searchItem.changeEvent)"
                ></org-tree-select>
              </template>
              <template v-else>
                <org-tree-select
                  :class="searchItem.class"
                  ref="orgRef"
                  :data="searchItem.treeOptions"
                  @onSelectChange="
                    onOrgSelected($event, searchItem.modelValue, searchItem.changeEvent)
                  "
                  :disabled="searchItem.disabled"
                  @onClear="onOrgClear($event, searchItem.modelValue, searchItem.changeEvent)"
                ></org-tree-select>
              </template>
            </template>
          </sweet-form-item>
        </sweet-col>
      </sweet-row>
      <sweet-row :gutter="12">
        <sweet-col>
          <sweet-form-item>
            <div class="buttonBox--basic">
              <sweet-button @click="handleReset(formRef)">
                {{ $t('aicc.reset') }}
              </sweet-button>
              <sweet-button type="primary" @click="handleSearch(formRef)">
                {{ $t('aicc.search') }}
              </sweet-button>
            </div>
          </sweet-form-item>
        </sweet-col>
      </sweet-row>
    </div>
  </sweet-form>
</template>

<script></script>
<script setup>
import {
  reactive,
  ref,
  computed,
  toRefs,
  onMounted,
  watch,
  getCurrentInstance,
  nextTick
} from 'vue'
import { Search } from '@element-plus/icons-vue'
import timeUtils from '@/utils/timeUtils'
import cookieUtils from '@/utils/cookie'

const props = defineProps(['templateMetaData', 'mountedEvent'])
const emit = defineEmits([
  'handleRefresh',
  'handleDownload',
  'handleImport',
  'handleExport',
  'handleSetting',
  'handleTransList',
  'handleCommand',
  'handleSearch',
  'handleReset',
  'handleClickMore'
])
const { templateMetaData, mountedEvent } = toRefs(props)

const isAdvancedSearch = ref(false)
const handleAdvancedSearch = (flag) => {
  isAdvancedSearch.value = flag
  handleClickMore(flag)
}

// 设置基础搜索每个formItem项的宽度的可选类
const formItem240pxClass = computed(() => {
  const classDic = {}
  return classDic[cookieUtils.getCookie('u-local')] || 'form-item-240-px'
})

// 设置基础搜索每个formItem项的宽度的可选类
const formItem380pxClass = computed(() => {
  const classDic = {}
  return classDic[cookieUtils.getCookie('u-local')] || 'form-item-380-px'
})

// 记录自定义时间选择label的字符串
const customTimeSelectLabel = ref('')

// 是否展示当前自定义选择时间
const isSelfDefinedTime = computed(() => {
  let timeSelectFormDataKey = ''
  basicSearch.value.forEach((item) => {
    if (item.searchType === 'timeSelect') {
      timeSelectFormDataKey = item.modelValue
    }
  })
  if (formData[timeSelectFormDataKey] !== 'custom') {
    return false
  }
  if (customTimeSelectLabel.value.includes('-')) {
    return true
  } else {
    return false
  }
})

// 基础配置
const basicConfig = computed(() => {
  const { basicConfig = {} } = templateMetaData.value
  return basicConfig
})
// 高级搜索
const advancedSearch = computed(() => {
  const { advancedSearch = {} } = templateMetaData.value
  return advancedSearch
})
// 基础搜索
const basicSearch = computed(() => {
  const { basicSearch = [] } = basicConfig.value
  const len = basicSearch.length
  // 基础搜索中搜索框最多三个, 不然样式会有问题
  return len > 4 ? basicSearch.slice(0, 4) : basicSearch
})
// 图标按钮
const iconButton = computed(() => {
  const { iconButton = [] } = basicConfig.value
  return iconButton
})
// 图标按钮区域栅格配置
const iconAreaColConfig = computed(() => {
  const { iconAreaColConfig = {} } = basicConfig.value
  return iconAreaColConfig
})
// 其它操作
const otherOperation = computed(() => {
  const { otherOperation = [] } = basicConfig.value
  return otherOperation
})
// 其它操作区域栅格配置
const otherAreaColConfig = computed(() => {
  const { otherAreaColConfig = {} } = basicConfig.value
  return otherAreaColConfig
})
// 高级搜索：左右向外拓展的宽度 例如 10px; 1rem
const outwardMargin = computed(() => {
  const { outwardMargin = '' } = advancedSearch.value
  return outwardMargin
})
// 高级搜索：搜索框列表
const searchList = computed(() => {
  const { searchList = [] } = advancedSearch.value
  return searchList
})

// 根据基础搜索框的数量动态计算样式
const computedBtnClass = computed(() => {
  let className = `otherArea_lg`
  return className
})

const styleObject = computed(() => {
  return {
    'padding-left': outwardMargin.value,
    'padding-right': outwardMargin.value,
    'margin-left': `-${outwardMargin.value}`,
    'margin-right': `-${outwardMargin.value}`
  }
})

const formData = reactive({})
const formRules = reactive({})
// 表单v-model和rules绑定值处理公共部分函数抽取
const handleFormKeyCommon = (item) => {
  if (item.modelValue) {
    formData[item.modelValue] = item.modelDefault || ''
    if (item.searchType === 'timeSelect' && item.datePickerType === 'datetimerange') {
      formData[item.modelValue + 'Custom'] = [
        new Date(new Date().setHours(0, 0, 0, 0)),
        new Date(new Date().setHours(23, 59, 59, 59))
      ]
    }
    if (item.rules && item.rules.length) {
      formRules[item.modelValue] = item.rules
    }
  }
}

// 表单v-model和rules绑定值处理
const handleFormKey = () => {
  basicSearch.value.forEach((item) => {
    handleFormKeyCommon(item)
  })
  searchList.value.forEach((item) => {
    handleFormKeyCommon(item)
  })
}
handleFormKey()

// 格式化参数
const handleFormatParams = computed(() => {
  const params = {}
  Object.keys(formData).forEach((item) => {
    if (formData[item]) {
      params[item] = formData[item]
    }
  })
  return params
})

// 判断是否是空数组或空对象
const checkEmptyArrObj = (obj) => {
  let flag = true
  if (Array.isArray(obj)) {
    flag = obj.length ? false : true
  } else if (Object.prototype.toString.call(obj) === '[object object]') {
    flag = Object.keys(obj).length ? false : true
  }
  return flag
}

// 刷新按钮事件
const handleRefresh = (data) => {
  let eventName = data || 'handleRefresh'
  emit(eventName, { params: handleFormatParams.value })
}
// 下载按钮事件
const handleDownload = (data) => {
  let eventName = data || 'handleDownload'
  emit(eventName, { params: handleFormatParams.value })
}
// 导入按钮事件
const handleImport = (data) => {
  let eventName = data || 'handleImport'
  emit(eventName, { params: handleFormatParams.value })
}
// 导出按钮事件
const handleExport = (data) => {
  let eventName = data || 'handleExport'
  emit(eventName, { params: handleFormatParams.value })
}
// 设置按钮事件
const handleSetting = (data) => {
  let eventName = data || 'handleSetting'
  emit(eventName, { params: handleFormatParams.value })
}
// 任务列表按钮事件
const handleTransList = (data) => {
  let eventName = data || 'handleTransList'
  emit(eventName, { params: handleFormatParams.value })
}
// 点击更多按钮展开关闭高级搜索事件
const handleClickMore = (data) =>{
  emit('handleClickMore', data)
}
// inputFocus 事件捕获
const inputFocus = (data) => {
  let eventName = data || 'inputFocus'
  emit(eventName, { params: handleFormatParams.value })
}
// inputChange 事件捕获
const inputChange = (data) => {
  let eventName = data || 'inputChange'
  emit(eventName, { params: handleFormatParams.value })
}
// inputClear 事件捕获
const inputClear = (data) => {
  let eventName = data || 'inputClear'
  emit(eventName, { params: handleFormatParams.value })
}
// selectChange 事件捕获
const selectChange = (data) => {
  let eventName = data || 'selectChange'
  emit(eventName, { params: handleFormatParams.value })
}
// select框的 click 事件捕获
const selectClick = (data) => {
  let eventName = data || 'selectClick'
  emit(eventName, { params: handleFormatParams.value })
}
const basicSelectClick = (data) => {
  let eventName = data || 'basicSelectClick'
  emit(eventName, { params: handleFormatParams.value })
}
const currentInstance = getCurrentInstance()
const timeSelectChange = (event, val, modelValue) => {
  if (val === 'custom') {
    currentInstance.refs[modelValue][0].handleOpen()
  } else {
    let eventName = event || 'timeSelectChange'
    emit(eventName, { params: handleFormatParams.value })
  }
}
const timeSelectDateChange = (data, datePickerType) => {
  let selectLabel = ''
  if (handleFormatParams.value[data + 'Custom']) {
    const transDate = datePickerType === 'datetimerange' ? timeUtils.transTime : timeUtils.transDate
    selectLabel = `${transDate(handleFormatParams.value[data + 'Custom'][0])}   -  ${transDate(
      handleFormatParams.value[data + 'Custom'][1]
    )}`
  } else {
    selectLabel = $t('chat.timerange.label.customizes')
  }
  customTimeSelectLabel.value = selectLabel
  basicSearch.value.forEach((pItem) => {
    if (pItem.modelValue === data) {
      pItem.selectOptions.forEach((cItem) => {
        if (cItem.value === 'custom') cItem.label = selectLabel
      })
    }
  })
  formData[data] = ''
  nextTick(() => {
    formData[data] = 'custom'
  })
}
const timeSelectDateBlur = (basicItem, visible) => {
  if (!visible) {
    let eventName = basicItem.changeEvent || 'timeSelectChange'
    timeSelectDateChange(basicItem.modelValue, basicItem.datePickerType)
    emit(eventName, { params: handleFormatParams.value })
  }
}
const treeSelectBlur = (event, visible) => {
  if (!visible) {
    let eventName = event || 'treeSelectChange'
    emit(eventName, { params: handleFormatParams.value })
  }
}

const onOrgSelected = (result, modelValue, changeEvent) => {
  formData[modelValue] = result || ''
  emit(changeEvent || 'orgTreeSelectChange', { params: handleFormatParams.value })
}

const onOrgsSelected = (result, modelValue, changeEvent) => {
  formData[modelValue] = result || []
  emit(changeEvent || 'orgTreeSelectChange', { params: handleFormatParams.value })
}

const onOrgClear = (result, modelValue, changeEvent) => {
  formData[modelValue] = ''
  emit(changeEvent || 'orgTreeSelectChange', { params: handleFormatParams.value })
}

const onOrgsClear = (result, modelValue, changeEvent) => {
  formData[modelValue] = []
  emit(changeEvent || 'orgTreeSelectChange', { params: handleFormatParams.value })
}

// inputChange 事件捕获
const dateChange = (data) => {
  let eventName = data || 'dateChange'
  emit(eventName, { params: handleFormatParams.value })
}

// 其它操作菜单按钮触发事件
const handleCommand = (command) => {
  if (!command) {
    return ''
  }
  emit('handleCommand', { eventName: command, params: handleFormatParams.value })
}

const formRef = ref()
// 搜索
const handleSearch = (formEl) => {
  if (!formEl) return
  emit('handleSearch', { params: handleFormatParams.value })
}

const orgsRef = ref()
// 重置
const handleReset = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
  orgsRef && orgsRef.value && orgsRef.value[0] && orgsRef.value[0].onClear()
  emit('handleReset', { params: handleFormatParams.value })
}

// 获取格式化参数
const getFormatParams = () => {
  return handleFormatParams.value
}

const isFormItemClearable = (item) => {
  let isClearable = String(item.clearable) === 'false' ? false: true
  if (item.rules) {
    try {
      const rulesStr = JSON.stringify(item.rules)
      if (rulesStr.indexOf(`"required":true`) !== -1) {
        isClearable = false
      }
    } catch (error) {
      console.log(error)
    }
  }
  return isClearable
}

onMounted(() => {
  if (!mountedEvent.value) {
    return false
  }
  // 页面挂载后调用发送一次handleSearch事件
  emit('handleSearch', { params: handleFormatParams.value })
})

defineExpose({ form: formRef, getFormatParams, formData, handleAdvancedSearch })

const disabledDateFun = (time) => {
  return false
}
</script>

<style lang="less" scoped>
.suffix-img {
  transform: scale(0.5) rotate(90deg);
  cursor: pointer;
}

.advance-search-btn-text {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

:deep(button.el-button.el-button--primary) {
  color: #ffffff;
}

:deep(button.el-button) {
  color: #1c1c1c;
}

.basic-search-row {
  display: flex;
  align-items: flex-end;

  &-center {
    flex: 1;
  }

  :deep(.el-form-item) {
    position: relative;

    .el-form-item__error {
      position: absolute !important;
      top: unset;
      bottom: -8px;
    }
  }
}

.form-item-240-px {
  width: 240px;
  margin-right: 12px;
}

.form-item-380-px {
  width: 380px;
  margin-right: 12px;
}

body.RTL .form-item-240-px,
body.RTL .form-item-380-px {
  margin-right: unset;
  margin-left: 12px;
}

:deep(.el-dropdown + .el-dropdown) {
  padding-left: 0;
}

:deep(.el-button + .el-button) {
  margin-left: 12px !important;
}

:deep(.el-select .el-input__wrapper) {
  border-radius: 8px !important;
}

:deep(.el-date-editor) {
  border-radius: 8px !important;
}

:deep(.el-input__wrapper) {
  border-radius: 8px !important;
}

:deep(.el-form-item__error) {
  position: relative;
  top: 0;
  line-height: 1.3;
  margin-bottom: -19px;
}

:deep(.el-dropdown-menu__item.is-disabled) {
  background: #eee;
}

:deep(.el-select__input) {
  cursor: pointer;
}

.input-icon {
  cursor: pointer;
  transition: all 0.2s;

  &:active {
    transform: scale(0.9);
  }
}

.advanced-search--basic {
  background-color: #f4f6fa;
  padding-top: 20px;
  margin-bottom: 20px;
}

.buttonBox--basic,
.otherArea_xl,
.otherArea_lg,
.otherArea_md,
.otherArea_sm {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
}

.otherArea_xl,
.otherArea_lg,
.otherArea_md,
.otherArea_sm {
  justify-content: flex-end;
}

.otherArea_xl {
  @media screen and (max-width: 1920px) {
    justify-content: flex-start;
  }
}

.otherArea_lg {
  @media screen and (max-width: 1200px) {
    justify-content: flex-start;
  }
}

.otherArea_md {
  @media screen and (max-width: 992px) {
    justify-content: flex-start;
  }
}

.otherArea_sm {
  @media screen and (max-width: 768px) {
    justify-content: flex-start;
  }
}

.common-audio {
  flex: 1;
  display: flex;
  align-items: center;
  min-width: 19rem;
  height: 32px;

  & + :deep(.el-button) {
    margin-left: 10px;
  }
}

.aicc-button-icon {
  position: relative;

  img {
    width: 16px;
    position: relative;
  }

  &.transList-dot {
    &::before {
      content: '';
      width: 8px;
      height: 8px;
      background: #df3437;
      border-radius: 50%;
      position: absolute;
      right: -3px;
      top: -3px;
    }
  }
}

.timeSelectDate {
  height: 0;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 10px;
}

:deep(.el-button > span > span) {
  overflow: hidden;
  display: inline-block;
  max-width: calc(9rem - 5px);
  white-space: nowrap;
  text-overflow: ellipsis;
}

body.RTL .aicc-advance-search {
  :deep(.el-dropdown + .el-dropdown) {
    padding-left: unset;
    padding-right: 0;
  }

  :deep(.el-button + .el-button) {
    margin-left: unset !important;
    margin-right: 12px !important;
  }

  .aicc-button-icon {
    &.transList-dot {
      &::before {
        right: unset;
        left: -3px;
      }
    }
  }

  .common-audio {
    & + :deep(.el-button) {
      margin-right: 10px;
    }
  }
}
</style>
