import request from '@/utils/request'
import cookie from "@/utils/cookie";
import i18n from "@/lang";

const $t = i18n.global.t

export async function getUserAccess() {
    let userAccess = []
    await queryByUserId().then(res => {
        if (res && res.length > 0) {
            userAccess = res.map(org => org.orgId);
        }
    })
    let orgId = cookie.getCookie('org_id');
    // 用户默认组织
    if (!userAccess.includes(orgId)) {
        userAccess.unshift(orgId)
    }
    return await getOrgList().then(organization => {
        for (let org of organization) {
            org.isAccess = userAccess.includes(org.orgId);
            let children = org.children;
            if (children.length !== 0) {
                doChildren(children, userAccess)
            }
        }
        // 装填下拉组织机构显示
        let result = organization
        result.unshift({
            orgId: '0',
            orgName: $t('aicc.unorganized'),
            isAccess: true
        })
        return result
    });
}

export async function getOrgList() {
    let orgList = []
    await queryOrgList().then(res => {
        if (res && res.orgs && res.orgs.length > 0) {
            const result = res.orgs
            for (const org of result) {
                if (org.parentOrgId === undefined || org.parentOrgId === null || String(org.parentOrgId) === '') {
                    org.parentOrgId = '1'
                }
            }
            let parentOrg = result.filter(org => org.parentOrgId === '1');
            for (const org of parentOrg) {
                org.children = getChildren(org, result);
            }
            orgList = parentOrg
        }
    })
    return orgList
}

// 查询带权限的组织tree
export async function getUserAccessOrgList(currentOrgId) {
    let orgList = []
    // 查询所有的组织tree
    await queryOrgList().then(res => {
        if (res && res.orgs && res.orgs.length > 0) {
            orgList = res.orgs
            for (const org of orgList) {
                if (org.parentOrgId === undefined || org.parentOrgId === null || String(org.parentOrgId) === '') {
                    org.parentOrgId = '1'
                }
            }
        }
    })
    // 拥有的组织机构权限
    let accessOrgIds = [currentOrgId]
    const res = await isContact(currentOrgId)
    if (res) {
        // 主管，可以选择下面所有的
        let tmpAccess = accessOrgIds;
        while (true) {
            tmpAccess = orgList.filter(org => tmpAccess.includes(org.parentOrgId)).map(org => org.orgId)
            if (tmpAccess.length === 0) {
                break
            }
            accessOrgIds = accessOrgIds.concat(tmpAccess);
        }
    }
    for (const org of orgList) {
        org.disabled = !accessOrgIds.includes(org.orgId);
    }

    let parentOrg = orgList.filter(org => org.parentOrgId === '1');
    for (const org of parentOrg) {
        org.children = getChildren(org, orgList);
    }
    return parentOrg;
}

async function isContact(currentOrgId) {
    let isContact = false
    await getContacts(currentOrgId).then(res => {
        if (res && res.orgContacts) {
            let employeeIds = res.orgContacts.map(element => element.employeeId);
            if (employeeIds.includes(cookie.getCookie('user_id'))) {
                isContact =  true
            }
        }
    })
    return isContact
}

// 查询用户权限
export const getUserPermissions = () => {
    const tenantSpaceId = cookie.getCookie('tenant_space_id');
    const userId = cookie.getCookie('user_id');
    return request({
        url: '/service-cloud/rest/sum/v2/tenantSpaces/' + tenantSpaceId + '/users/' + userId + '/perms',
        method: 'get',
    })
}

export function getUserInfo() {
    const url = "/service-cloud/rest/sum/v2/tenantSpaces/" + cookie.getCookie('tenant_space_id') + "/users/" + cookie.getCookie('user_id') + "/information";
    return request({
        url,
        method: 'get'
    })
}

export function getContacts(currentOrgId) {
    const url = "/service-cloud/rest/sum/v1/tenantSpaces/" + cookie.getCookie('tenant_space_id') + "/orgs/" + currentOrgId + "/contacts";
    return request({
        url,
        method: 'get'
    })
}

function queryByUserId() {
    const userId = cookie.getCookie('user_id');
    return request({
        url: '/service-cloud/rest/cc-management/v1/accessRelation/getListByUserId',
        method: 'post',
        data: {
            userId: userId
        }
    })
}

export function queryOrgList() {
    return request({
        url: '/service-cloud/u-route/orgOperation/queryOrgList',
        method: 'post',
        data: {
            param: {}
        },
        requestHeaders: [{
            'content-type': 'application/json'
        }]
    })
}

export const getChildren = (root, list) => {
    let children = list.filter(org => org.parentOrgId === root.orgId)
    for (const org of children) {
        org.children = getChildren(org, list)
    }
    return children
}

const doChildren = (children, userAccess) => {
    for (const org of children) {
        org.isAccess = userAccess.includes(org.orgId);
        if (org.children.length !== 0) {
            doChildren(org.children, userAccess)
        }
    }
}