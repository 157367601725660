<template>
    <!-- 列配置 -->
    <sweet-dialog width="896px" v-model="dialogVisible" :title="$t('aicc.columnConfig')" class="aicc-table-tools" resize="true">
        <div class="aicc-table-tools__body">
            <div class="aicc-table-tools__body-left">
                <!-- 全部列 -->
                <sweet-checkbox class="aicc-table-tools__body-left--header" v-model="checkAll"
                    :indeterminate="isIndeterminate" @change="handleCheckAllChange">{{$t('aicc.selectAll')}}</sweet-checkbox>
                <div class="aicc-table-tools__body-left--body">
                    <template v-for="item in columnsList" :key="item.label + item.prop">
                        <div class="aicc-table-tools__body-left-wrap"> 
                            <sweet-checkbox v-if="!item.type" class="aicc-table-tools__body-left--body-item columns-name"
                                v-model="item.display" :disabled = "item.configFixed" :label="item.prop" @change="handleCheckedColumn(item)">
                                {{ item.label }} 
                            </sweet-checkbox>
                            <sweet-tooltip v-if="item.description" class="box-item"  effect="light" :content="item.description" placement="top">
                                <i class="info-icon sweetui-icon-tip-l"></i>
                            </sweet-tooltip>
                        </div>
                    </template>
                </div>
            </div>
            <div class="aicc-table-tools__body-right">
                <div class="aicc-table-tools__body-right--header">
                    <!-- 已选列（可拖动进行排列） -->
                    <span>{{$t('aicc.selectedColumns')}}</span>
                    &nbsp;&nbsp;   
                    <span style="color: #949494;">{{$t('aicc.selectedColumnsTip')}}</span>   
                </div>
                <div class="aicc-table-tools__body-right--body">
                    <div class="aicc-table-tools__body-right--body-item" :class="{'disabled' : item.configFixed} " v-for="(item, index) in checkedList"
                        :key="item.label + item.prop" :draggable="!item.configFixed" @dragstart="handleDragStart(index)"
                        @drop="handleDrop(index)" @dragend="handleDragEnd" @dragover.prevent @dragenter.prevent>
                        <img :draggable="false" class="move-icon" src="@/assets/img/table/table_tools_move.png" alt="">
                        <span class="display-index">{{ index + 1 }}</span>
                        <span class="columns-name">{{ item.label }}</span>
                        <i class="sweetui-icon-delete-l delete-icon" @click="handleCancelChecked(item)"></i>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <span class="dialog-footer">
                <!-- 取消 -->
                <sweet-button @click="handleCancel">
                    {{$t('cms.recorddrive.label.button_cacel')}}
                </sweet-button>
                <!-- 重置 -->
                <sweet-button @click="handleReset">
                    {{$t('cms.agentMonitor.label.reset')}}
                </sweet-button>
                <!-- 确定 -->
                <sweet-button type="primary" @click="handleSubmit" :disabled="!checkedList.length">
                    {{$t('cms.vdnconfiguration.field.ok')}}
                </sweet-button>
            </span>
        </template>
    </sweet-dialog>
</template>

<script setup>
import { ref, toRefs } from 'vue';
const props = defineProps(['isMirrorLanguage'])
const { isMirrorLanguage } = toRefs(props)
const emit = defineEmits(['handleFilterColumn'])
const dialogVisible = ref(false)

const OriginalColumn = ref([])
const columnsList = ref([])
const checkedList = ref([])
let column =[]
const handleOpen = (list) => {
    dialogVisible.value = true
    OriginalColumn.value = __deepClone(list)
    console.log('columnsList.value', columnsList.value)
    column = OriginalColumn.value.filter(item => item.prop && item.prop == 'logTime')
    columnsList.value = __deepClone(list).filter(item => {
      if (item.prop && item.prop === 'logTime') {
        return false
      }
      return true
    })
    checkedList.value = columnsList.value.filter(item => {
        return !item.type && item.display
    })
    // 镜像
    if (isMirrorLanguage.value) {
        columnsList.value.reverse()
        checkedList.value.reverse()
    }
    handleCheckedChange()
}

// 全选
const checkAll = ref(false)
const isIndeterminate = ref(false)
// configFixed
const handleCheckAllChange = (val) => {
    columnsList.value.forEach(item => {
        if (!item.type && !item.configFixed) {
            item.display = val
        }
    })
    checkedList.value = columnsList.value.filter(item => {
        return !item.type && item.display
    })
    isIndeterminate.value = false
}

const handleCheckedChange = () => {
    // type列不能配置
    const configColumns = columnsList.value.filter(item => !item.type).length
    checkAll.value = configColumns === checkedList.value.length
    isIndeterminate.value = checkedList.value.length > 0 && checkedList.value.length < configColumns
}

const handleCheckedColumn = (column) => {
    if (column.display) {
        checkedList.value.push(column)
    } else {
        checkedList.value = checkedList.value.filter(item => item.prop !== column.prop || item.label !== column.label)
    }
    handleCheckedChange()
}

// 取消选中
const handleCancelChecked = (column) => {
  if (column.configFixed) return
  columnsList.value.forEach(item => {
    if (item.child && column.child) {
      if (item.child[0].prop === column.child[0].prop) {
        item.display = false
        checkedList.value = checkedList.value.filter(item2 => {
          if (!item2.child || !item2.child.length) {
            return true
          }
          return item2.child && (item2.child[0].prop !== column.child[0].prop || item2.child[0].label !== column.child[0].label)
        })
      }
    } else if (item.prop === column.prop) {
      item.display = false
      checkedList.value = checkedList.value.filter(item3 => item3.prop !== column.prop || item3.label !== column.label)
    }
  })
  handleCheckedChange()
}

// 拖拽
let dragIndex = -1

// 开始拖拽
const handleDragStart = (index) => {
    dragIndex = index
}

// 释放
const handleDrop = (index) => {
    if (dragIndex > -1) {
        const dragColumn = checkedList.value.splice(dragIndex, 1)[0]
        checkedList.value.splice(index, 0, dragColumn)
    }
}

// 结束拖拽
const handleDragEnd = () => {
    dragIndex = -1
}

// 取消
const handleCancel = () => {
    dialogVisible.value = false
}

// 重置 
const handleReset = () => {
    columnsList.value = __deepClone(OriginalColumn.value)
    checkedList.value = columnsList.value.filter(item => {
        return !item.type && item.display
    })
    if (isMirrorLanguage.value) {
        columnsList.value.reverse()
        checkedList.value.reverse()
    }
    handleCheckedChange()
}

// 确定
const handleSubmit = () => {
    const columns = []
    let checkedListLen = checkedList.value.length
    for(let i = 0; i < checkedListLen; i++){
        columns.push(checkedList.value[i])
    }

    let columnsListLen = columnsList.value.length
    for(let i = 0; i < columnsListLen; i++){
        if (columnsList.value[i].type) {
            columns.push(columnsList.value[i])
        } else if (!columnsList.value[i].display) {
            columns.push(columnsList.value[i])
        }
     }
    if (column) {
      columns.unshift(column[0])
    }
    if(isMirrorLanguage.value) {
        columns.reverse()
    }
    emit('handleFilterColumn', columns)
    handleCancel()
}

const __deepClone = (arr) => {
  const newArr = arr.constructor === Array ? [] : {};
  Object.keys(arr).forEach((item)=>{
    newArr[item] = arr[item] && typeof arr[item] === 'object' ? __deepClone(arr[item]) : arr[item];
  })
  return newArr;
};

defineExpose({ handleOpen })

</script>

<style lang="less">
.aicc-table-tools {
    /* max-width: 616px !important; */
    height: 500px!important;
    min-width: 616px;
    .aicc-table-tools__body-left--body-item{
        min-height: 32px;
    }

    .el-dialog__body {
        padding-top: 10px !important;
    }

    &__body {
        display: flex;
        flex-wrap: nowrap;
        gap: 20px;

        &-left {
            width: calc((100% - 20px) / 2);

            &--header {
                height: 32px;
                padding: 0 10px 5px 10px;
            }

            &--body {
                display: flex;
                flex-direction: column;
                height: 306px;
                border: 1px solid rgba(221, 221, 221, 1);
                border-radius: 8px;
                overflow-y: auto;
                padding: 10px;
                .info-icon{
                    color: var(--color-text--h2);
                }
                .columns-name {
                        padding-right: 10px;
                        flex: 1;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        max-width: calc(100% - 14px);
                    }

                &-item {
                    display: flex;
                    height: 32px;
                    margin-right: 0 !important;

                    .el-checkbox__label {
                        flex: 1;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }

            &-wrap{
                display: flex;
                align-items: center;
            }
        }

        &-right {
            width: calc((100% - 20px) / 2);

            &--header {
                height: 32px;
                padding: 0 10px 5px 0px;
                display: flex;
                align-items: center;
            }

            &--body {
                height: 306px;
                width: 100%;
                border: 1px solid rgba(221, 221, 221, 1);
                border-radius: 8px;
                overflow: auto;
                padding: 10px;
                &-item {
                    height: 32px;
                    display: flex;
                    align-items: center;

                    .move-icon {
                        height: 14px;
                        width: auto;
                        padding-right: 10px;
                    }

                    .display-index {
                        padding-right: 10px;
                        cursor: none;
                    }

                    .columns-name {
                        padding-right: 10px;
                        flex: 1;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    .delete-icon {
                        margin-left: auto;
                        cursor: pointer;
                    }
                }
                .disabled{
                    color: #a6a6a6;
                }
            }
        }
    }
}

body.RTL {
    .el-checkbox.aicc-table-tools__body-left--header {
        margin-right: 11px !important;
    }
}
</style>