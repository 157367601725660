<template>
  <sweet-dialog v-model="isShow" :title="title" width="616px" class="aicc-dialog-limit-height" :close-on-click-modal="false"  @close="onDialogClose">
    <aicc-advanced-search
      :templateMetaData="templateMetaData" @handleSearch="refreshPageSetting" @handleRefresh="refreshPageSetting" @inputClear="refreshPageSetting" ref="aiccSearch" />
    
    <aicc-table row-key="id" @handlePagination="handlePagination" :tableData="tableData" :tableColumns="tableColumns" :tableTools="{ columnConfig: false, showPagination: true }"
      :paginationConfig="paginationConfig" ref="refAiccTable" height="430" />

    <template #footer>
      <span class="dialog-footer">
        <sweet-button @click="close">{{ $t("message.template.message.cancel") }}</sweet-button>
        <sweet-button type="primary" @click="confirmSelect">{{ $t("ccnotification.common.button.finish") }}</sweet-button>
      </span>
    </template>
  </sweet-dialog>
</template>

<script>
import { queryBulletinTypeByCondition } from '../../../api/index'

export default {
  data() {
    return {
      isShow: true,
      title: $t('ccnotification.notifition.sendmessage.enterBulletinType'),
      tableData: [],
      allData: [],
      searchKey: '',
      limit: 10,
      offset: 0,
      paginationConfig: {
        pageSizes: [10, 20, 50, 100],
        pageSize: 10,
        total: 0,
        layout: "sizes, total, prev, pager, next"
      },
      tableColumns: [
        {
          type: 'radio',
          'reserve-selection': true,
          radioKey: 'id',
          width: '50px'
        },
        {
          label: $t('ccnotification.notifition.sendmessage.bulletinTypeName'),
          prop: 'name'
        },
        {
          label: $t('ccnotification.notifition.sendmessage.invalidSect'),
          prop: 'invalidSect'
        }
      ],
      templateMetaData: {
        // 基础搜索配置
        basicConfig: {
          // 基础搜索
          basicSearch: [{
            searchType: 'primary',
            filterable: false,
            modelValue: 'searchKey',
            clearable: true,
            placeholderValue: this.$t('ccnotification.notifition.sendmessage.bulletinTypeName'),
            colConfig: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12
            },
          }]
        }
      }
    }
  },
  methods: {
    onDialogClose() {
      this.$emit('onClose')
    },
    search() {
      this.offset = 0
      let data = {}
      if (this.searchKey) {
        data.name = this.searchKey
      }
      queryBulletinTypeByCondition(data).then(res => {
        if (res) {
          this.allData = res.result
          this.tableData = this.allData.slice(this.offset, this.offset + this.limit)
          this.paginationConfig.total = res.result.length
        } else {
          this.paginationConfig.total = 0
        }
      })
    },
    refreshPageSetting({
      params
    }) {
      this.searchKey = params.searchKey
      this.search()
    },
    handlePagination(val) {
      this.limit = val.limit
      this.offset = val.offset
      this.tableData = this.allData.slice(this.offset, this.offset + this.limit)
    },
    close() {
      this.isShow = false
    },
    confirmSelect() {
      const currentRow = this.$refs.refAiccTable.getRadioCurrentRow()
      if (currentRow.id) {
        this.$emit('selectBulletinType', currentRow)
        this.close()
      }
    }
  },
  mounted() {
    this.search()
  }
}
</script>