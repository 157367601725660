<template>
    <div>
        <sweet-dialog v-model="dialogVisible" :title="$t('isales.basicinfo.title.chooseCallNo')" width="616" close-on-click-modal="false" class="aicc-dialog-limit-height">
            <!--OP场景 多一个输入框-->
            <div v-if="cmsEnvironment==='cms'">
                <sweet-form label-width="auto" :model="queryForm" :rules="callerRule" ref="queryFormValid">
                    <sweet-row>
                        <sweet-col :span="12" style="text-align: left;font-weight: bold;font-size: large;">
                            <sweet-form-item prop="inputAccessCode">
                                <sweet-input v-model="queryForm.inputAccessCode"
                                             :placeholder="$t('isales.taskinfo.field.mutiCallNo')" width="200"/>
                            </sweet-form-item>
                        </sweet-col>
                        <sweet-col :span="12" style="text-align: right;">
                            <sweet-button @click="addInputAccessCode">{{$t('isales.appointment.title.add')}}
                            </sweet-button>
                        </sweet-col>
                    </sweet-row>
                    <sweet-row></sweet-row>
                </sweet-form>
            </div>
            <aicc-table v-if="cmsEnvironment==='cms'"
                        :tableData="showInputCallNoList"
                        :tableColumns="tableOpInputColumns"
                        :tableTools="{ showPagination: true, columnConfig: false }"
                        :paginationConfig="paginationConfigOp"
                        @handlePagination="handlePaginationOp" style="margin-top:15px"
                        class="height-auto"
                        :max-height="430">
                <template #operate="scope">
                    <sweet-link type="primary" @click="handleDeleteInput(scope.row)">
                        {{$t('isales.specialday.title.delete')}}
                    </sweet-link>
                </template>
                <template #priority="scope">
                    <sweet-select @change="priorityChange(scope.row)" v-model="scope.row.priority" :placeholder="$t('isales.taskinfo.placeholder.choose')" :fit-input-width="true" :show-resize="false">
                        <sweet-option v-for="(item, index) in this.selectTaskStatusItem" :key="item.value"
                                      :label="item.label"
                                      :value="item.value"/>
                    </sweet-select>
                </template>
            </aicc-table>


            <aicc-table
                    :tableData="accessCodes"
                    :tableColumns="tableColumns"
                    :tableTools="{ showPagination: true, columnConfig: false }"
                    :paginationConfig="paginationConfig"
                    @handlePagination="handlePagination"
                    ref='refAiccTable'
                    class="height-auto"
                    :max-height="430">
                <template #priority="scope">
                    <sweet-select v-model="scope.row.priority" :placeholder="$t('isales.taskinfo.placeholder.choose')"
                                  :disabled="!selectable(scope.row)" :fit-input-width="true" :show-resize="false">
                        <sweet-option v-for="(item, index) in this.selectTaskStatusItem" :key="item.value"
                                      :label="item.label"
                                      :value="item.value"/>
                    </sweet-select>
                </template>
            </aicc-table>
            <template #footer>
              <span class="dialog-footer">
                <sweet-button @click="dialogVisible = false">{{ $t('isales.common.title.cancel') }}</sweet-button>
                <sweet-button type="primary" @click="confirm">{{ $t('isales.common.title.confirm') }}</sweet-button>
              </span>
            </template>
        </sweet-dialog>
    </div>
</template>

<script>
    import {TASK_PRIORITY_TYPES_ENUM} from "@/views/isales/views/taskManagement/enum";
    import {addCallers, queryCallerNo} from "@/views/isales/api/systemAutoCallTask.js";
    import {ElMessageBox} from "element-plus";
    import {deepClone} from "@vitest/utils";

    export default {
        name: "isalesCallerNoCreate",
        props: {
            show: {
                type: Boolean,
                default: false
            },
            taskId: '',
            cmsEnvironment: '',
            ctiVersion: 'V3R8'
        },
        data() {
            return {
                queryForm: {
                    inputAccessCode: '',  // OP-新建外呼任务场景下 新增主叫号码
                },
                accessCodes: [],
                inputCallNoList: [],
                showInputCallNoList: [],
                selectTaskStatusItem: TASK_PRIORITY_TYPES_ENUM,
                createCallerNoDialogVisible: false,
                tableColumns: [
                    {
                        type: 'selection',
                        width: '50px',
                        selectable: this.selectable,
                        'show-overflow-tooltip': false
                    },
                    {
                        label: this.$t('isales.taskinfo.field.callNo'),
                        prop: 'accessCode',
                    },
                    {
                        label: this.$t('isales.taskinfo.field.priority'),
                        slot: 'priority',
                        fixed: 'right',
                    }
                ],
                tableOpInputColumns: [
                    {
                        label: this.$t('isales.taskinfo.field.callNo'),
                        prop: 'accessCode',
                    },
                    {
                        label: this.$t('isales.taskinfo.field.priority'),
                        slot: 'priority'
                    },
                    {
                        label: this.$t('isales.agent.label.operate'),
                        slot: 'operate',
                        fixed: 'right',
                    }
                ],
                paginationConfig: {
                    PageSize: 10, // 每页条数
                    total: 0, //总条数
                    layout: "total, prev, pager, next" // 分页组件布局配置
                },
                paginationConfigOp: {
                    PageSize: 10, // 每页条数
                    total: 0, //总条数
                    layout: "total, prev, pager, next" // 分页组件布局配置
                },
                limit: 10,
                offset: 0,
                limitOp: 10,
                offsetOp: 0,
                CTIVERSION_V3R8: 'V3R8',
            }
        },
        computed: {
            dialogVisible: {
                get() {
                    return this.show
                },
                set(val) {
                    this.$emit('update:show', val)
                }
            },
            callerRule() {
                return {
                    inputAccessCode: [
                        {validator: this.$Validate.checkCalledNo, trigger: ['change', 'blur']},
                        {maxLength: 24, validator: this.$Validate.maxLengthValidator, trigger: ['blur', 'change']}
                    ]
                }
            }
        },
        methods: {
          priorityChange(data){
            let index = this.inputCallNoList.findIndex(item => {
              return item.accessCode == data.accessCode
            })
            this.inputCallNoList[index].priority = data.priority
          },
            async queryCallerNo() {
                let queryCondition = {};
                queryCondition['taskId'] = this.taskId;
                queryCondition['limit'] = this.limit;
                queryCondition['offset'] = this.offset;
                queryCallerNo(queryCondition).then(res => {
                    if (res) {
                        this.accessCodes = res.accessCodes;
                        this.accessCodes.forEach(item => {
                            item.priority = "3"
                        })
                        this.paginationConfig.total = res.count;
                    }
                });
            },
            handlePagination(params) {
                this.limit = params.limit
                this.offset = params.offset
                this.queryCallerNo();
            },
            handlePaginationOp(params) {
                this.limitOp = params.limit
                this.offsetOp = params.offset
                this.queryShowInputCallerNums();
            },
            confirm() {
                this.dialogVisible = false
                // 保存选中
                let selectedAllCallerNoObj = [];
                const selectionRows = this.$refs.refAiccTable.table.getSelectionRows();
                if ((!selectionRows || selectionRows.length <= 0) && this.inputCallNoList.length === 0) {
                    this.$message({
                        type: 'error',
                        duration: 10000,
                        offset: 56,
                        'show-close': true,
                        message: this.$t('isales.notification.receiver.delete.error')
                    })
                    return;
                }
                const tempSelectAccessCodes = [];
                if (selectionRows && selectionRows.length > 0) {
                    selectionRows.forEach(item => {
                        tempSelectAccessCodes.push(item.accessCode);
                    });
                }
                if (this.inputCallNoList.length !== 0) {
                    selectionRows.forEach(item => {
                        tempSelectAccessCodes.push(item.accessCode);
                    })
                    let noAddInputs = this.inputCallNoList.filter(item => !tempSelectAccessCodes.includes(item.accessCode));
                    noAddInputs.forEach(item => {
                        selectedAllCallerNoObj.push(item);
                    })
                }
                if (this.tasktype === '4') {
                    let tempTask = {};
                    tempTask["accessCode"] = tempSelectAccessCodes[0];
                    selectedAllCallerNoObj.push(tempTask);
                }
                if (this.tasktype !== '4' && tempSelectAccessCodes !== null && tempSelectAccessCodes.length > 0) {
                    selectionRows.forEach(item => {
                        selectedAllCallerNoObj.push(item);
                    })
                }

                const params = {"taskId": this.taskId, "callerNo": selectedAllCallerNoObj};
                addCallers(params).then(res => {
                    if (res && res.returnCode === '0') {
                        this.$message({
                            type: 'success',
                          duration: 5000,
                          offset: 56,
                          message: this.$t('isales.operation.success')
                        });
                    } else if (res && res.returnCode === '2') {
                        this.$message({
                            type: 'error',
                            duration: 10000,
                            offset: 56,
                            'show-close': true,
                            message: res.description
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            duration: 10000,
                            offset: 56,
                            'show-close': true,
                            message: this.$t('isales.notification.receiver.delete.error')
                        });
                    }
                    this.accessCodes = [];
                    this.inputCallNoList = [];
                    this.showInputCallNoList = [];
                    // 回到主叫号码展示页面 查询
                    this.$emit('isalesCallerNoCreateConfirm');
                });
            },
            selectable(row) {
                return !row.disable;
            },
            async addInputAccessCode() {
                if (this.queryForm.inputAccessCode === null || this.queryForm.inputAccessCode === '') {
                    this.$message({
                        type: 'error',
                      duration: 10000,
                      offset: 56,
                      'show-close': true,
                        message: this.$t('isales.taskinfo.field.mutiCallNo')
                    });
                    return;
                }
                let sysAutoResult = await this.$refs.queryFormValid.validate().catch(err => err);
                if (sysAutoResult !== true) {
                    return;
                }
                const index = this.inputCallNoList.findIndex(item => item.accessCode === this.queryForm.inputAccessCode);
                if (index >= 0) {
                    this.$message({
                        type: 'error',
                        duration: 10000,
                        offset: 56,
                        'show-close': true,
                        message: this.$t('isales.taskinfo.callno.repeat')
                    });
                    return;
                }
                let inputCallerNoObj = {};
                // 业务结果策略数据标识当前最大值
                if (this.ctiVersion === this.CTIVERSION_V3R8 && this.taskType !== '4') {
                    inputCallerNoObj["priority"] = "3";
                }
                inputCallerNoObj["accessCode"] = this.queryForm.inputAccessCode;
                this.queryForm.inputAccessCode = null;
                this.inputCallNoList.push(inputCallerNoObj);
                this.paginationConfigOp.total++;
                if (this.ctiVersion === this.CTIVERSION_V3R8 && this.taskType !== '4') {
                    this.inputCallNoList.sort((a, b) => a.priority - b.priority);
                }
                this.queryShowInputCallerNums();
            },
            queryShowInputCallerNums() {
                if (this.inputCallNoList === null || this.inputCallNoList.length <= 0) {
                    this.showInputCallNoList = [];
                    return;
                }
                let tempInputCallNoList = deepClone(this.inputCallNoList);
                this.paginationConfigOp.total = tempInputCallNoList.length;
                this.showInputCallNoList = tempInputCallNoList.splice(this.offsetOp, this.limitOp);
            },
            async handleDeleteInput(data) { // 删除
                ElMessageBox.confirm(this.$t('isales.taskmanagement.message.deleteConfirm'), this.$t('isales.notification.receiver.deletetips'),{
                  type: 'warning',
                  customClass: 'aicc-message-box-small'
                }).then(async () => {
                    let deleteIndex = this.inputCallNoList.findIndex(function (deleteItem) {
                        return deleteItem.accessCode === data.accessCode;
                    });
                    if (-1 !== deleteIndex) {
                        this.inputCallNoList.splice(deleteIndex, 1);
                    }
                    this.paginationConfigOp.total--;
                    this.queryShowInputCallerNums();
                }).catch(() => {
                })
            },
        },
        async updated() {
            this.inputCallNoList = [];
            this.showInputCallNoList = [];
            this.paginationConfigOp.total = 0;
            await this.queryCallerNo();
        },
    }
</script>

<style scoped>

</style>